import Layout from '@glu/core/src/layout';
import services from 'services';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import ExternalWidgetModel from 'app/administration/models/externalWidgetModel';
import AdminUtil from 'app/administration/common/util';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import detailTmpl from './detail.hbs';
import detailViewTmpl from './detailView.hbs';

export default Layout.extend({
    template: detailTmpl,
    viewTemplate: detailViewTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'full-page',

    regions: {
        pageRegion: '.pageRegion',
        alertRegion: '.alert-region',
    },

    initialize(options) {
        this.state = options.state;
        this.widgetId = options.widgetId;
    },

    ui: {
        $local: '[data-hook="getLocal"]',
        $remote: '[data-hook="getRemote"]',
        $format: '[data-hook="getFormat"]',
        $proxy: '[data-hook="getProxy"]',
        $file: '[data-hook="getFile"]',
        $mfaUrl: '[data-hook="getMfaUrl"]',
    },

    getTemplate() {
        if (this.loadingTemplate && this.hasLoadedRequiredData() === false) {
            return this.loadingTemplate;
        }
        if (this.state !== 'view') {
            return this.template;
        }
        return this.viewTemplate;
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.listenTo(this.model, 'change:widgetType', this.externalWidgetLocationChanged);
            this.externalWidgetLocationChanged();

            this.listenTo(this.model, 'change:externalWidgetType', this.externalWidgetTypeChanged);
            this.externalWidgetTypeChanged();

            this.listenTo(this.model, 'change:iplat', this.externalWidgetIplatChanged);
            this.externalWidgetIplatChanged();
        } else {
            this.loadRequiredData();
        }
    },

    templateHelpers() {
        const self = this;

        return {
            stateDescription() {
                // TODO: Should these be localized?
                switch (self.state) {
                case 'view':
                    return 'View';
                case 'update':
                    return 'Modify';
                case 'add':
                    return 'Add';
                default:
                }
                return undefined;
            },

            editMode() {
                return (self.state === 'update');
            },

            typeDescription() {
                return ((self.model.get('widgetType') === 'R') ? locale.get('administration.remote') : locale.get('administration.local'));
            },

            trustedDescription() {
                return ((self.model.get('trusted') === 'true') ? locale.get('administration.yes') : locale.get('administration.no'));
            },

            widgetSizeDescription() {
                return ((self.model.get('widgetSize') === 'S') ? locale.get('administration.small') : locale.get('administration.large'));
            },

            dataEntitleDescription() {
                return ((self.model.get('dataEntitle') === 'true') ? locale.get('CXP.bankWidget.dataEntitle.true') : locale.get('CXP.bankWidget.dataEntitle.false'));
            },

        };
    },

    loadRequiredData() {
        const self = this;

        if (this.state === 'add') {
            this.model = new ExternalWidgetModel({
                widgetType: 'R',
            });
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            this.model = new ExternalWidgetModel({
                widgetId: this.widgetId,
            });
            this.model.fetch({
                success() {
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },
            });
        }
    },

    save() {
        const self = this;

        this.model.save(
            {},
            {
                success() {
                    if (self.model.get('local') === true) {
                        const [file] = self.ui.$file[0].files;

                        if (file === null) {
                            return;
                        }

                        const reader = new FileReader();

                        reader.onloadend = function (readerEvt) {
                            if (readerEvt.target.readyState === FileReader.DONE) {
                                const content = readerEvt.target.result;

                                const contentString = window.btoa(content);

                                const uploadModel = new Model();
                                uploadModel.set('fileName', file.name);
                                uploadModel.set('fileData', contentString);
                                uploadModel.set('widgetId', self.model.get('widgetId'));

                                http.post(services.generateUrl('/cxp/upload'), uploadModel.toJSON(), (result) => {
                                    if (result) {
                                        // We need to store the real url for the widget
                                        const widgetUrl = services.generateWidgetUrl(`${self.model.get('widgetId')}`);
                                        self.model.set('dataUrl', widgetUrl);
                                        self.model.save();
                                    }
                                });
                            }
                        };

                        if (file !== undefined) {
                            reader.readAsBinaryString(file);
                        }
                    }

                    self.navigateTo('/CXP/externalWidget');
                },

                error(response) {
                    AdminUtil.errorAlert(
                        self.alertRegion,
                        response.error.confirms.confirmResults[0].messages[0],
                    );
                },
            },
        );
    },

    externalWidgetLocationChanged() {
        const val = this.model.get('widgetType');
        if (val === 'R') {
            this.ui.$local.hide();
            this.ui.$remote.show();
        } else if (val === 'L') {
            this.ui.$remote.hide();
            this.ui.$local.show();
        }
    },

    externalWidgetTypeChanged() {
        const val = this.model.get('externalWidgetType');
        if (val === 'MFA') {
            this.ui.$mfaUrl.show();
            this.ui.$format.hide();
        } else {
            this.ui.$mfaUrl.hide();
            this.ui.$format.show();
        }
    },

    externalWidgetIplatChanged() {
        if (this.model.get('iplat')) {
            this.ui.$proxy.hide();
            return;
        }
        this.ui.$proxy.show();
    },

    modify() {
        this.navigateTo(`/CXP/updateExternalWidget/${this.widgetId}`);
    },

    cancel() {
        this.navigateTo('/CXP/externalWidget');
    },
});
