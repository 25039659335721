import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import ComplexTypeGroups from 'app/administration/collection/user/complexTypeGroups';
import LimitLayout from './limits/limitLayout';
import editLimitsDialogTmpl from './editLimitsDialog.hbs';

export default Layout.extend({
    template: editLimitsDialogTmpl,
    modalClass: 'modal-xlg',

    initialize(options) {
        const self = this;

        this.onSave = options.onSave;

        this.collection = new ComplexTypeGroups(options.collection
            .filter(child => self.modifyMode || child.hasEntitledType()));

        this.mode = options.mode;
        this.dialogButtons = [{
            text: locale.get('button.addlimits'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        }];

        this.dialogTitle = locale.get('administration.limit.overall.approval.add');
    },

    onRender() {
        this.approvalLimitsRegion.show(new LimitLayout({
            collection: this.collection,
            mode: this.mode,
            modifyMode: true,
        }));
    },

    cancel() {
        this.appBus.trigger('usercentric:paymentLimits:cancel:reset');
        Dialog.close();
    },

    submit() {
        if (util.isFunction(this.onSave)) {
            this.onSave();
        }

        Dialog.close();
    },
});
