import constants from 'app/balanceAndTransaction/constants';
import TransactionSearch from 'app/balanceAndTransaction/views/tranSearch/transactionSearch';
import systemConfig from 'system/configuration';

const PreviousCurrentDaySearch = TransactionSearch.extend({
    id: 'showTransactionSearchTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showTransactionSearchLink',
    },

    /**
     * Overrides the base definition to define a context for the old previous and
     * current day deposit
     */
    getDepositContext() {
        return {
            functionCode: constants.INST,
            productCode: constants.GIR,
            serviceName: `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}getSearchTransactions`,
            typeCode: constants.GIRTRNSR,
            exportPrintURL: systemConfig.isAdmin()
                ? constants.TRANS_EXPORT_PRINT_URL : constants.ASYNC_TRANS_EXPORT_PRINT_URL,
            inquiryId: 29562,
        };
    },

    /**
     * Overrides the base definition to define a context for the old previous and
     * current day loans
     */
    getLoanContext() {
        return {
            functionCode: constants.INST,
            productCode: constants.GIR,
            serviceName: `${constants.LOAN_ACCTS_SERVICE_PREFIX}getSearchTransactions`,
            typeCode: constants.LONTRNSR,
            exportPrintURL: systemConfig.isAdmin()
                ? constants.LOAN_TRANS_EXPORT_PRINT_URL
                : constants.ASYNC_LOAN_TRANS_EXPORT_PRINT_URL,
            inquiryId: 29561,
        };
    },
});

export default PreviousCurrentDaySearch;
