import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import LimitsUtil from '../limitsUtil';
import template from './itemView.hbs';

export default ItemView.extend({
    tagName: 'div',
    className: 'row',
    template,

    initialize(options) {
        const self = this;

        this.modifyMode = options.modifyMode || false;
        this.mode = options.mode;
        this.originalAttributes = util.clone(this.model.attributes);

        // For modify mode, set up validators for max limits, which really is for CGBO only.
        if (this.modifyMode) {
            util.each(this.model.get('limits'), (limit, limitType) => {
                const maxLimit = limit.maxValue;
                if (!util.isNullOrUndefined(maxLimit)) {
                    this.model.addValidator(`limits.${limitType}.value`, {
                        maxValue: parseInt(maxLimit, 10),
                        description: locale.get(this.model.get('label')),
                    });
                }
            });
        }

        // reset values if cancelled
        this.listenTo(this.appBus, 'usercentric:paymentLimits:cancel:reset', () => {
            self.model.attributes = self.originalAttributes;
        });
    },

    onRender() {
        this.$('input, .form-control-static').inputmask(
            'number',
            LimitsUtil.wholeNumberWithSeparatorFormat(),
        );
    },

    templateHelpers() {
        const { model } = this;

        return {
            cid: model.cid,
            showTransaction: util.has(this.model.get('limits'), 'transaction'),
            showBatch: util.has(this.model.get('limits'), 'batch'),
            showDaily: util.has(this.model.get('limits'), 'daily'),
            isModifyMode: this.options.modifyMode,
        };
    },
});
