import $ from 'jquery';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';

export default {
    // Inquiry Ids
    DEFAULT_INQUIRY_ID: 0,

    REPORTS_INQUIRY_ID: 17050,
    ALERTS_INQUIRY_ID: 17051,
    CHECK_MANAGEMENT_ALERT_LIST_INQUIRY_ID: 20550,

    // need to finalize value for payments alerts list.
    PAYMENTS_ALERT_LIST_INQUIRY_ID: 20560,

    CHECK_PRODUCTION_ALERT_LIST_INQUIRY_ID: 21090,
    INFORMATION_REPORTING_ALERT_LIST_INQUIRY_ID: 22300,
    ADMIN_ALERT_LIST_INQUIRY_ID: 9028,
    ALERT_GROUPS_LIST_INQUIRY_ID: 17047,
    ALERT_TYPES_LIST_INQUIRY_ID: 17046,
    STATE_CODES_LIST_INQUIRY_ID: 17097,
    COUNTRY_CODES_LIST_INQUIRY_ID: 17076,
    BANK_CODES_LIST_INQUIRY_ID: 17089,
    CURRENCY_CODES_LIST_INQUIRY_ID: 17084,
    TRANSFER_FROM_ACCOUNT_INQUIRY_ID: 19497,
    TRANSFER_TO_ACCOUNT_INQUIRY_ID: 19498,
    TRANSFER_PRODUCT_CODE: 'RTGS',
    TRANSFER_FUNCTION_CODE: 'INST',
    TRANSFER_TYPE_CODE: 'TRANSFER',
    DOMESTIC_WIRE_FROM_ACCOUNT_INQUIRY_ID: 19339,
    INTL_WIRE_FROM_ACCOUNT_INQUIRY_ID: 19337,
    ACH_PAYMENT_TYPES_ACCOUNT_INQUIRY_ID: 24574,
    REAL_TIME_PAYMENT_FROM_ACCOUNT_INQUIRY_ID: 19375,

    // Search Types
    DEFAULT_SEARCH_TYPE: 2,

    INQUIRY_CONFIGURED_SEARCH_TYPE: 2,
    ACTION_CONFIGURED_SEARCH_TYPE: 5,

    // Type Codes
    DEFAULT_TYPE_CODE: '',

    ALL_TYPE_CODE: '*',
    ADDRESS_BOOK_TYPE_CODE: 'BENEADBK',
    BANK_HOLIDAY_TYPE_CODE: 'CBANK_TM',
    SECURITY_QUESTIONS_TYPE_CODE: 'USECQ_TM',

    // View Ids
    DEFAULT_VIEW_ID: 0,

    // Column Types
    COLUMN_TYPE_HIDDEN: 6,

    COLUMN_TYPE_STRING: 0,
    COLUMN_TYPE_ACTION_LIST: 32,

    // apparently webseries has two types of action list columns.
    COLUMN_TYPE_ENTITLEMENT_ACTION_LIST: 38,

    COLUMN_TYPE_ACCESSIBILITY_ACTION_LIST: 39,
    COLUMN_TYPE_CHECKBOX: 5,

    // Query Results
    DEFAULT_QUERY_PAGE_SIZE: 0,

    DEFAULT_QUERY_OFFSET: 0,

    // Action Modes
    INSERT_ACTION_MODE: 'INSERT',

    // Function Codes
    ALERT_FUNCTION_CODE: 'ALERT',

    MAINT_FUNCTION_CODE: 'MAINT',

    // Product Codes
    DEFAULT_PRODUCT_CODE: '*',

    ADMIN_PRODUCT_CODE: '_ADMIN',
    SECURITY_PRODUCT_CODE: 'SMAINT',
    UTILITY_PRODUCT_CODE: '_UTIL',

    // Entry Methods
    DEFAULT_ENTRY_METHOD: 0,

    // Map Data
    INCLUDE_MAP_DATA: 1,

    EXCLUDE_MAP_DATA: 0,

    /**
     * Retrieves inquiry data based on custom filter and search parameters.
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     * @param options
     */
    getQueryResults(options) {
        const data = {
            IncludeMapData: options.includeMapData,

            queryCriteria: {
                queryField: options.queryField,
                customFilters: options.customFilters,
                inquiryId: options.inquiryId,
                allowDuplicates: options.allowDuplicates,
                subTypeCode: options.subTypeCode,

                action: {
                    typeCode: options.typeCode,
                    entryMethod: options.entryMethod,
                    productCode: options.productCode,
                    actionMode: options.actionMode,
                    functionCode: options.functionCode,
                },

                fieldName: options.fieldName,
            },

            requestHeader: {
                requestId: options.viewId,

                requestUser: {
                    data: {
                        item: [{
                            name: 'usergroup',
                            value: options.userGroup,
                        }, {
                            name: 'password',
                            value: options.password,
                        }],
                    },

                    userId: options.userId,
                },
            },
        };

        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryQueryResults,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * New and improved version of the getQueryResults function above.
     * Now returns a promise like the entitlements service, etc.
     *
     * Need to support overriding of attributes via options like the original
     * method does.
     *
     * @param options
     * @returns {Promise}
     */
    getInquiryQueryResults(options) {
        return new Promise((resolve, reject) => {
            const data = {
                IncludeMapData: 1,

                queryCriteria: {
                    inquiryId: '9018',

                    action: {
                        typeCode: '*',
                        entryMethod: 0,
                        productCode: options.context.productCode,
                        actionMode: '',
                        functionCode: options.context.functionCode,
                    },
                },

                requestHeader: {
                    queryPagesize: 0,
                    queryOffset: 1,

                    requestUser: {
                        data: {
                            item: [{
                                name: '',
                                value: '',
                            }],
                        },

                        userId: '',
                    },

                    requestId: '',
                },
            };

            http.post(
                services.inquiryQueryResults, data, (response) => {
                    const savedViews = [];
                    util.each(response.queryResponse.QueryData.queryRows, (view) => {
                        const savedView = {};
                        savedView.value = view.name;
                        savedView.text = view.label;
                        savedView.canEdit = 'true';
                        savedView.canDelete = 'true';

                        savedViews.push(savedView);
                    });

                    resolve(savedViews);
                },
                (e) => {
                    reject(e);
                },
            );
        });
    },

    /**
     * Retrieves inquiry data including both the headers and rows based on requested
     * search type and search parameters.
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     */
    getData(options) {
        const data = {
            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 10,

                searchCriteria: {
                    searchType: options.searchType,
                    inquiryId: options.inquiryId,

                    action: {
                        typeCode: options.typeCode,
                        entryMethod: 1,
                        productCode: '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'SELECT',
                    },
                },
            },

            requestHeader: {},
        };

        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Retrieves inquiry headers data based on requested search type and search
     * parameters
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     */
    getHeaderData(options) {
        const data = {
            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 10,

                searchCriteria: {
                    searchType: options.searchType,
                    inquiryId: options.inquiryId,

                    action: {
                        typeCode: options.typeCode,
                        entryMethod: 1,
                        productCode: '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'SELECT',
                    },
                },
            },

            requestHeader: {},
        };

        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryHeaderData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },

    /**
     * Retrieves inquiry rows data based on requested search type and search
     * parameters
     * Ignore hardcoded user information below.  Webseries is in the process of
     * requiring a separate login
     * request before inquiry service requests can be submitted.  The requestUser
     * block will eventually go away.
     *
     */
    getRowsData(options) {
        const data = {
            inquiryRequest: {
                startRow: (options.startRow ? options.startRow : 1),
                rowsPerPage: (options.rowsPerPage ? options.rowsPerPage : 10),

                searchCriteria: {
                    searchType: options.searchType,
                    inquiryId: options.inquiryId,

                    action: {
                        typeCode: options.typeCode,
                        entryMethod: 1,
                        productCode: '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'SELECT',
                    },
                },
            },

            requestHeader: {
                requestId: options.viewId,
            },
        };

        if (options.sortOrder && options.sortKey) {
            data.inquiryRequest.searchCriteria.sortFields = [{
                fieldName: options.sortKey,
                sortOrder: options.sortOrder,
            }];
        }

        if (options.filters) {
            const searchFields = [];
            for (let i = 0; i < options.filters.length; i += 1) {
                searchFields[searchFields.length] = {
                    fieldName: options.filters[i].fieldName,
                    operator: 'CONTAINS',
                    fieldValue: [options.filters[i].fieldValue],
                    dataType: 'text',
                };
            }
            data.inquiryRequest.searchCriteria.searchFields = searchFields;
        }

        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.inquiryRowsData,
            type: 'POST',
            dataType: 'json',
            data: serialized,
        });
    },
};
