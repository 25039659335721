import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import constants from 'common/dynamicPages/api/constants';
import actionFunction from 'app/transfers/api/transfersActionFunction';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import ListView from 'common/dynamicPages/views/workflow/list';
import ContextModel from 'common/dynamicPages/models/context';
import workspaceHelper from 'common/workspaces/api/helper';
import store from 'system/utilities/cache';
import dialog from '@glu/dialog';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import services from 'services';
import TotalsModel from 'common/dataCard/models/paymentSummary';
import TotalsView from 'common/dataCard/views/dataCardCollectionView';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import systemConfig from 'system/configuration';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import AddTransferDialog from './addTransferDialog';
import template from './listTransfers.hbs';

const TransfersList = ListView.extend({
    className: 'payment-management-list',

    regions: {
        totalsSection: '[data-hook="transfer-totals-region"]',
    },

    template,

    ui: {
        $toggleTotals: '.toggleTotals',
        $totalsRegion: '[data-hook="transfer-totals-region"]',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="fileimport-button"]': 'fileimport',
            'click [data-hook="toggle-totals-button"]': 'toggleTotals',
        },
    ),

    initialize(options) {
        const superOptions = {
            menuCategory: 'PMTS',
            serviceName: 'payment/transfer',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_LIST',
            returnRoute: 'PAYMENTS/listTransfers',
            viewId: options.viewId,
            configContext: {
                productCode: 'ADMPAY',
                functionCode: 'INST',
                typeCode: 'TRANSFER',
                subType: '*',
                actionMode: 'SELECT',
            },
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        this.exportListViewPath = systemConfig.isAdmin() ? '/export/listView' : '/paymentExport/listView/corp/exportListView';

        this.shouldShowTotals = serverConfigParams.get('ShowPaymentListViewTotals') === 'true';
        this.showTransferTypeDropdown = true;
    },

    loadViewRequirements() {
        const options = this.viewOptionRequirements();
        options.gridOtpions = { caption: this?.contextDef?.name };
        const gridView = gridApi.createServiceGridView(options);
        const transferEntitlementsPromise = entitlements.getEntitlements(options);

        const optionsFimport = util.clone(options);
        optionsFimport.entryMethod = constants.ENTRY_METHOD_IMPORT;
        const fileImportEntitlementPromise = entitlements.getEntitlements(optionsFimport);

        const optionsTemplate = util.clone(options);
        optionsTemplate.entryMethod = constants.ENTRY_METHOD_TEMPLATE;
        const paymentTemplateEntitlementPromise = entitlements.getEntitlements(optionsTemplate);

        const optionsRepetitive = util.clone(options);
        optionsRepetitive.entryMethod = constants.ENTRY_METHOD_REPETITIVE;
        const paymentRepetitiveEntitlementPromise = entitlements.getEntitlements(optionsRepetitive);

        this.gridView = gridView;
        ListView.prototype.setupGridAvailableListener.call(this);

        // merge promises for transfer payments, file import, repetitive templates and templates
        return Promise.all([
            transferEntitlementsPromise,
            fileImportEntitlementPromise,
            paymentTemplateEntitlementPromise,
            paymentRepetitiveEntitlementPromise,
        ]).then(([
            transferEntitlements,
            fileImportEntitlement,
            paymentTemplateEntitlement,
            paymentRepEntitlement,
        ]) => {
            const entResActions = transferEntitlements.actions;
            const missingActionButtons = ['MODIFY', 'GETRATE', 'APPROVE', 'UNAPPROVE', 'INSERT', 'REJECT', 'DELETE'];
            this.showTransferTypeDropdown = !!transferEntitlements.actions?.INSERT;
            if (fileImportEntitlement.actions && fileImportEntitlement.actions.INSERT) {
                entResActions.IMPORT = true;
                missingActionButtons
                    .filter(btn => btn !== 'INSERT')
                    .forEach((action) => {
                        entResActions[action] = fileImportEntitlement.actions[action];
                    });
            }
            if (!this.showTransferTypeDropdown && paymentTemplateEntitlement?.actions?.INSERT) {
                missingActionButtons.forEach((action) => {
                    entResActions[action] = paymentTemplateEntitlement.actions[action];
                });
            } else if (!this.showTransferTypeDropdown && paymentRepEntitlement?.actions?.INSERT) {
                missingActionButtons.forEach((action) => {
                    entResActions[action] = paymentRepEntitlement.actions[action];
                });
            }
            this.entitlements = entResActions;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.setHasLoadedRequiredData(true);
                this.listenTo(this.gridView.wrapper.rows, 'sync', this.updateFromRender);
                this.listenForGridErrors();
                this.render();
            }
            return entResActions;
        });
    },

    updateFromRender() {
        this.updateSubTotals();
        // update refresh timestamp
        this.updateRefreshTimestamp();
    },

    updateSubTotals() {
        // Do not fetch if ShowPaymentListViewTotals config param is false and totalsModel is empty
        if (this.shouldShowTotals && !systemConfig.isAdmin() && this.totalsModel) {
            this.totalsModel.set('filters', this.gridView.wrapper.generateFiltersDataBlock());
            this.totalsModel.set('viewId', this.gridView.wrapper.viewId);
            this.totalsModel.fetch();
        }
    },

    onRender(...args) {
        if (this.hasLoadedRequiredData()) {
            // Only call the super onRender if the data has been loaded
            ListView.prototype.onRender.call(this, args);
            if (store.has('listTransferMessage')) {
                const alertResponse = store.get('listTransferMessage');
                this.renderMessage(alertResponse.action, alertResponse.response);
                store.unset('listTransferMessage');
            } else if (store.has(`${this.contextKey}-alertMessage`) && store.has(`${this.contextKey}-confirms`)) {
                // for rejects coming from reject dialog
                this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
                store.unset(`${this.contextKey}-alertMessage`);
                store.unset(`${this.contextKey}-confirms`);
            }
        } else {
            this.loadViewRequirements();
        }
    },

    initializeTotals() {
        this.totalsModel = new TotalsModel({
            filters: this.gridView.wrapper.generateFiltersDataBlock(),
        }, {
            service: services.generateUrl('payment/getTransferPaymentsSummary'),

            payGroupConfig: {
                TRANSFERS: {
                    disableDebitTotals: true,
                },
            },
        });
        /*
         * TODO: 30315 - Once the API is updated to handle in Admin,
         * the check for systemConfig.isAdmin() can be removed.
         */
        if (this.shouldShowTotals && !systemConfig.isAdmin()) {
            this.totalsCollection = new Collection();
            this.totalsView = new TotalsView({
                collection: this.totalsCollection,
                isLoading: true,
            });
            this.totalsSection.show(this.totalsView);
            this.listenTo(this.totalsModel, 'change', () => {
                const paymentTotalsData = this.totalsModel.get('paymentTotalsByPaymentGroupList');
                this.totalsView.isLoading = true;
                if (paymentTotalsData) {
                    this.totalsCollection.reset(paymentTotalsData);
                }
            });
        }
    },

    toggleTotals() {
        const isVisible = this.ui.$totalsRegion.height() > 0;
        if (isVisible) {
            this.ui.$totalsRegion.css({
                height: '0',
                visibility: 'hidden',
            });
            this.ui.$toggleTotals.text(locale.get('common.show.totals'));
        } else {
            // Show totals data only when user clicks on "show totals" button for the first time
            if (!this.totalsModel) {
                this.initializeTotals();
                this.updateFromRender();
            }
            this.ui.$totalsRegion.css({
                height: 'auto',
                visibility: 'visible',
            });
            this.ui.$toggleTotals.text(locale.get('common.hide.totals'));
        }
    },

    fileimport() {
        const importContext = {
            serviceName: '/fimport/import',
            actionMode: 'IMPORT',
            typeCode: 'TRANSFER',
            productCode: 'RTGS',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        this.navigateTo(this.contextModel.getImportUrl());
    },

    insert() {
        dialog.open(new AddTransferDialog({
            showTransferTypeDropdown: this.showTransferTypeDropdown,
        }));
        return Promise.resolve();
    },

    gridTemplateFromPayment(options) {
        const contextModel = new ContextModel({
            contextDef: options.model.context,
        });
        actionFunction.makeTemplate(options.model, contextModel.getContextKey());
        this.navigateTo('TEMPLATES/addTransferTemplateFromTemplate');
        return Promise.resolve();
    },

    gridPaymentFromTemplate(options) {
        const contextModel = new ContextModel({
            contextDef: options.model.context,
        });
        actionFunction.makePayment(options.model, contextModel.getContextKey());
        this.navigateTo('PAYMENTS/addTransferPaymentFromTemplate');
        return Promise.resolve();
    },

    gridRowSelect(optsParam) {
        const opts = optsParam;
        /*
         * When rejected from view screen, the context key is needed for reject dialog
         * to properly return the reject message to the list view.
         */
        opts.model.contextKey = this.contextKey;
        store.set('viewTransferModel', opts.model);
        this.navigateTo('PAYMENTS/viewTransfer');
        return Promise.resolve();
    },

    gridRowModify(opts) {
        store.set('modifyTransferModel', opts.model);
        this.navigateTo('PAYMENTS/modifyTransfer');
        return Promise.resolve();
    },

    gridRowRepair(opts) {
        store.set('repairTransferModel', opts.model);
        this.navigateTo('PAYMENTS/repairTransfer');
        return Promise.resolve();
    },

    /**
     * method for delete context menu item which handles warnings
     * @param {object} options -  object passed from list view
     */

    gridRowDelete(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            // adding options.wait = true, will wait for server response before removal
            options.model.destroy({
                wait: 'true',

                success(model, resp) {
                    if (resp.resultType === 'WARNING') {
                        self.listenTo(model, 'modelAction:deleteWithWarning', self.gridRowDeleteWithWarning);
                        self.listenTo(model, 'modelAction:cancelWarning', self.gridView.refreshGridData());
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: constants.ACTION_DELETE,
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        self.renderMessage(options.action, resp);
                    }
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.refreshGridData();
                    self.renderMessage(options.action);
                    reject(e);
                },
            });
        });
    },

    gridRowDeleteWithWarning(rowModel) {
        return new Promise((resolve, reject) => {
            const self = this;
            rowModel.set('_saveWithWarning', 'true');
            rowModel.destroy({
                wait: 'true',

                success(model, resp) {
                    let isWarning = false;
                    if (resp && resp.confirms && resp.confirms.confirmResults[0] && resp.confirms.confirmResults[0].resultType === 'WARNING') {
                        isWarning = true;
                    }
                    if (isWarning) {
                        self.listenTo(model, 'modelAction:deleteWithWarning', self.gridRowDeleteWithWarning);
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'DELETE',
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        self.renderMessage('DELETE', resp);
                    }
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.refreshGridData();
                    self.renderMessage('DELETE');
                    reject(e);
                },
            });
        });
    },

    templateHelpers() {
        const obj = ListView.prototype.templateHelpers.call(this, undefined);
        const self = this;

        return util.extend(
            obj,
            {
                hasFileImportEntitlement() {
                    return self.hasEntitlement(constants.ACTION_IMPORT);
                },

                hasGetRateEntitlement() {
                    return self.hasEntitlement(constants.ACTION_GETRATE);
                },

                showTotals() {
                    const shouldShow = self.shouldShowTotals;
                    return (shouldShow && !systemConfig.isAdmin());
                },

                hasInsertAndNotAdmin() {
                    return (self.hasEntitlement(constants.ACTION_INSERT)
                        && !systemConfig.isAdmin());
                },

                hasRejectEntitlement() {
                    return (self.hasEntitlement(constants.ACTION_REJECT)
                        && !systemConfig.isAdmin());
                },

                hasApproveEntitlement() {
                    return (self.hasEntitlement(constants.ACTION_APPROVE)
                        && !systemConfig.isAdmin());
                },
            },
        );
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: systemConfig.isAdmin()
                ? constants.INQUIRY_ID_20037 : constants.INQUIRY_ID_20027,
            summaryInquiryId: systemConfig.isAdmin() ? constants.INQUIRY_ID_20037 : '',
        };
    },

    /**
     * @method buildExportModel
     * @returns {model} this.exportModel
     * Method to build the export model for the Transfer list view
     */
    buildExportModel() {
        const rows = this.gridView.grid.getSelectedRowModels();
        let selectedTnums = [];

        // get inquiry id if admin
        this.inquiryId = systemConfig.isAdmin() ? constants.INQUIRY_ID_20047 : '';

        ListView.prototype.buildExportModel.call(this, 'CSV');

        // create the searchfields if rows are selected
        if (rows.length) {
            selectedTnums = rows.map(model => model.get('TNUM'));

            this.exportModel.searchFields = [{
                fieldName: 'TNUM',
                operator: 'IN',
                fieldValue: selectedTnums,
                dataType: 'number',
            }];
        }
        return this.exportModel;
    },

    getGetRateViewId() {
        const foundModel = this.gridView.savedViews.models
            .find(model => constants.NEEDSRATEID === model.id);

        return foundModel ? foundModel.get('viewId') : constants.TRANSFERNEEDSRATEVIEWID;
    },
});

let list = TransfersList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        apiMessageContext: 'listTransferMessage',
        insertActions: [
            {
                label: 'payment.transfer_.button_insert',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: [
            {
                label: 'payment.button_approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'payment.button_unapprove',
                entitlement: 'UNAPPROVE',
            },
            {
                label: 'payment.button_reject',
                entitlement: 'REJECT',
            },
            {
                label: 'payment.button_delete',
                entitlement: 'DELETE',
            },
            {
                label: 'payment.button_getrate',
                entitlement: 'GETRATE',
            },
            {
                label: 'payment.button_trade',
                entitlement: 'GETRATE',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'TRANSFERS_LIST',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
