import util from '@glu/core/src/util';
// TODO refactor to make this the base class if possible.
import EntitlementView from '../payments/assignedPermissions/entitlementView';
import entitlementViewTmpl from './entitlementView.hbs';

export default EntitlementView.extend({
    template: entitlementViewTmpl,

    initialize(opts, ...rest) {
        EntitlementView.prototype.initialize.call(this, [opts, ...rest]);
        this.label = this.model.label;
        this.mode = opts.mode;
    },

    templateHelpers() {
        return util.extend(
            EntitlementView.prototype.templateHelpers.call(this),
            {
                hasSignatures: () => // TODO Do we need to check panel approval like payments?
                    util.contains(['REQUEST'], this.model.get('functionCode')) && this.model.isRestrictionApplicable('signature')
                ,
            },
        );
    },
});
