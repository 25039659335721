import Collection from '@glu/core/src/collection';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import http from '@glu/core/src/http';

export default Collection.extend({

    initialize() {
        this.maxIndMessages = 10;
        this.maxTotalMessages = 20;
        this.currentTab = store.get('payment_rtp-currentTab');
        this.getMessagesLimitObj();
    },

    getRFIItems() {
        const items = this.map(model => this.getRFIItem(model));
        return { items };
    },

    /**
     * Update the total number of items in collection as well as individual
     * reason message totals
     */
    getMessagesLimitObj() {
        const missingModels = this.filter(model => model.get('REQUESTFORINFO_REASON_FLAG') === 'M');
        const incorrectModels = this.filter(model => model.get('REQUESTFORINFO_REASON_FLAG') === 'I');

        return {
            incorrectLimitReached: incorrectModels.length >= this.maxIndMessages,
            missingLimitReached: missingModels.length >= this.maxIndMessages,
            messageLimitReached: this.length >= this.maxTotalMessages,
        };
    },

    /**
     * @name collectData
     * @description creates the request for the multi-save service
     * @returns {Object {"items": [{"item": [{"name": "","value": ""}...]}]} }
     */
    collectData() {
        let data;
        if (this.isRequestForInfo) {
            data = this.getRFIItems();
        } else if (this.isPaymentAcknowledgement) {
            data = this.getPAItem();
        } else if (this.isStartedConversation) {
            data = this.getConversationRequestItem();
        }

        return data;
    },

    /**
     * @name save
     * @description performs the collection save (create) operation
     * @param paymentId
     * @param currentTab - use this, if present, to determine the url for the read
     * @returns {promise}
     */
    read(paymentId, currentTab) {
        const rtpType = currentTab || this.currentTab;
        const options = {
            url: services.generateUrl(`${rtpType === 'submitted' ? constants.URL_RTP_SUBMITTED_CONVERSATION : constants.URL_RTP_RECEIVED_CONVERSATION}/read`),
            data: {
                item: [
                    {
                        name: 'PAYMENT_ID',
                        value: paymentId,
                    },
                ],
            },
        };
        return this.sync(options);
    },

    /**
     * @name sync
     * @description collection sync method.  Assembles the post data and then
     * makes a request to the multi-save service
     * @param method
     * @param collection
     * @param options
     */
    sync(options) {
        return this.interactWithApi(options.url, 'post', options.data);
    },

    /**
     * Hit api and get or set data
     * @param  {string} url    URL of api
     * @param  {string} method Method used to hit api
     * @param  {object} data   Object literal containing data API requires for
     * interaction
     */
    interactWithApi(url, method, data) {
        return new Promise((resolve, reject) => {
            http[method](url, data).then(resolve, reject);
        });
    },
});
