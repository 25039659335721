export default ({
    // the workspace as you normally see it in the ui
    'cashflow/default': 'cashFlowWorkspace',

    // the whole workspace without the workspace widget extras
    'cashflow/all': 'defaultView',

    // cashflow forecasing without the extras.
    'cashflow/forecast': 'cashFlowForecastView',

    // comparison url with out workspace extras.
    'cashflow/compare': 'cashFlowCompareView',

    // regular PlanningLayout url with out workspace extras.
    'cashflow/planning': 'cashFlowPlanningView',

    // group planning layout urls without extras.
    'cashflow/groupplan': 'cashFlowGroupPlanningView',

    /* Deeplinks */
    'PLANNING/cashFlowForecastChart': 'defaultView',
});
