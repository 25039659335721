import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import Handlebars from 'handlebars';
import services from 'services';
import { appBus } from '@glu/core';
import alert from '@glu/alerts';
import $ from 'jquery';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import BaseMFAView from 'system/mfa/views/baseMFA';
import helper from 'system/mfa/helper';
import serverConfigParams from 'system/webseries/models/configurationParameters';

const DynamicWidgetContentModel = Model.extend({
    idAttribute: 'widgetId',

    sync(method, collection, options) {
        if (method === 'read') {
            const url = services.generateUrl('/cxp/externalWidget/getCustomContent');

            http.post(url, {}, (result) => {
                if (result.contentList === null || result.contentList.length === 0) {
                    // No MFA widget loaded
                    options.success({
                        noWidgetLoaded: true,
                    });
                } else {
                    // NH-88434 no longer reading the usergroup / user id from the cookies.
                    options.success(result.contentList[0]);
                }
            }, (result) => {
                options.error(result);
            });
        }
    },
});

const WidgetContentModel = Model.extend({
    sync(method, collection, options) {
        if (method === 'read') {
            const url = services.generateWidgetUrl(`${this.get('widgetId')}/index.html`);

            http.get(url, (response) => {
                options.success(response);
            }, (result) => {
                options.error(result);
            });
        }
    },

    parse(response) {
        return {
            content: response,
        };
    },
});

export default BaseMFAView.extend({
    initialize() {
        this.setWidgetListeners();
    },

    template() {
        return '';
    },

    initiateOOB() {
        if (serverConfigParams.get('ExternalMFACallInitiateOutOfBand') !== 'true') {
            return Promise.resolve();
        }
        const service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('initiateOutOfBand')}`);
        const data = {
            challengedAction: this.options.challengedAction,
        };

        return http.post(service, data).then(null, (response) => {
            try {
                this.errorMessage = response.responseJSON.respHeader.message.join(' ');
            } catch (err) {
                // do nothing
            }
            // Passing default App Resource incase response is null
            this.options.error('mfa.error.message', response);
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const resources = util.extend(
                {},
                {
                    resourceUrl: services.generateWidgetUrl(`${this.widgetId}/`),
                },
                this.dynamicWidgetContent.get('htmlKeyValues'),
            );

            appBus.trigger('extChallenge:register', this.dynamicWidgetContent);
            const html = this.getMarkup(resources);

            this.$el.html(html);
        } else {
            this.loadRequiredData();
        }
    },

    setWidgetListeners() {
        this.listenTo(this.appBus, 'bottomline.portal.message', this.handleComplete);
    },

    handleComplete(payload) {
        switch (payload.key) {
        case 'bottomline.external.widget.success':
            this.completion();
            break;
        case 'bottomline.external.widget.fail':
            this.cancel();
            break;
        case 'bottomline.external.widget.hold':
            this.hold();
            break;
        case 'bottomline.external.widget.close':
            this.closeModal();
            break;
        case 'bottomline.external.challenge-complete':
        case 'extChallenge:rsa:challenge:complete':
            this.model = this.createValidationModel(this.options);
            // No callback tokens like in adaptiveAuth
            this.model.set('callbackToken', payload.token ?? payload.errorToken);
            this.closeModal();
            this.model.save({}, {
                success: this.completion.bind(this),
                error: this.cancel,
            });
            break;
        default:
        }
    },

    getMarkup(resources) {
        let markup = Handlebars.compile(this.widgetContent.get('content'))(resources);
        const widgetId = this.widgetContent.get('widgetId');

        markup = markup.replace(
            /\$digital-banking-proxy/g,
            services.generateServletUrl(`ProxyServlet/${widgetId}`),
        );

        return markup.replace(
            /\$digital-content-repository/g,
            services.generateWidgetUrl(widgetId.toString()),
        );
    },

    loadRequiredData() {
        const self = this;

        this.dynamicWidgetContent = new DynamicWidgetContentModel();

        const dynamicWidgetContentPromise = new Promise((resolve, reject) => {
            self.dynamicWidgetContent.fetch({
                success: resolve,
                error: reject,
            });
        });

        Promise.all([dynamicWidgetContentPromise])
            .then((widgetResults) => {
                if (widgetResults[0].attributes.noWidgetLoaded) {
                    self.showAlert();
                } else {
                    self.getWidgetContent({
                        proxify: widgetResults[0].attributes.dataProxify,
                        widgetId: widgetResults[0].attributes.widgetId,
                    });
                }
            });
    },

    showAlert() {
        const self = this;
        const message = alert.negative(
            locale.get('mfa.widget.loading.error'),
            {
                title: locale.get('mfa.title'),
            },
        );

        const button = $('<button type="button" class="btn btn-primary continue"></button>');
        button.text(locale.get('button.cancel'));

        button.click(() => {
            self.cancel();
        });

        this.$el.html(message.render().$el);
        this.$el.append(button);
    },

    getWidgetContent(options) {
        const self = this;

        this.widgetContent = new WidgetContentModel(options);

        const widgetContentPromise = new Promise((resolve, reject) => {
            self.widgetContent.fetch({
                success: resolve,
                error: reject,
            });
        });

        Promise.all([widgetContentPromise])
            .then(() => {
                self.setHasLoadedRequiredData(true);
                self.render();
            });
    },
});
