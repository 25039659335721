var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"parent-item\">\n            <label for=\"mfa-"
    + alias3((lookupProperty(helpers,"parseKey")||(depth0 && lookupProperty(depth0,"parseKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"parseKey","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":44}}}))
    + "\">\n                <input id=\"mfa-"
    + alias3((lookupProperty(helpers,"parseKey")||(depth0 && lookupProperty(depth0,"parseKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"parseKey","hash":{},"data":data,"loc":{"start":{"line":13,"column":31},"end":{"line":13,"column":47}}}))
    + "\" type=\"checkbox\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"allActions") || (depth0 != null ? lookupProperty(depth0,"allActions") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"allActions","hash":{},"data":data,"loc":{"start":{"line":13,"column":72},"end":{"line":13,"column":86}}}) : helper)))
    + "\" name=\""
    + alias3((lookupProperty(helpers,"parseKey")||(depth0 && lookupProperty(depth0,"parseKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"parseKey","hash":{},"data":data,"loc":{"start":{"line":13,"column":94},"end":{"line":13,"column":110}}}))
    + "\" class=\"mfa-category \" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableCategory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":134},"end":{"line":13,"column":172}}})) != null ? stack1 : "")
    + "> <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":13,"column":182},"end":{"line":13,"column":189}}}) : helper)))
    + "</strong>\n            </label>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"actions") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":23,"column":33}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"child-item\">\n                <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"feature") || (depth0 != null ? lookupProperty(depth0,"feature") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"feature","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":39}}}) : helper)))
    + "\">\n                    <input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"feature") || (depth0 != null ? lookupProperty(depth0,"feature") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"feature","hash":{},"data":data,"loc":{"start":{"line":19,"column":31},"end":{"line":19,"column":42}}}) : helper)))
    + "\" type=\"checkbox\" class=\""
    + alias4((lookupProperty(helpers,"parseKey")||(depth0 && lookupProperty(depth0,"parseKey"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"parentKey") : depth0),{"name":"parseKey","hash":{},"data":data,"loc":{"start":{"line":19,"column":67},"end":{"line":19,"column":89}}}))
    + "\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"feature") || (depth0 != null ? lookupProperty(depth0,"feature") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"feature","hash":{},"data":data,"loc":{"start":{"line":19,"column":97},"end":{"line":19,"column":108}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableAction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":110},"end":{"line":19,"column":146}}})) != null ? stack1 : "")
    + "> "
    + alias4(((helper = (helper = lookupProperty(helpers,"localizedString") || (depth0 != null ? lookupProperty(depth0,"localizedString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"localizedString","hash":{},"data":data,"loc":{"start":{"line":19,"column":148},"end":{"line":19,"column":167}}}) : helper)))
    + "\n                </label>\n            </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<hr/>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"field-container\">\n    <div>\n        <label for=\"select-all\">\n            <input type=\"checkbox\" id=\"select-all\" value=\"ALL\" name=\"SELECT\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":77},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + "> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"button.selectall",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":112},"end":{"line":4,"column":141}}}))
    + "\n        </label>\n    </div>\n</div>\n<ul class=\"system-action-container\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"systemActions") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":24,"column":9}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});