import util from '@glu/core/src/util';
import constants from 'app/balanceAndTransaction/constants';
import transform from 'common/util/transform';
import ViewDetailBase from './viewDetailBase';
import template from './viewBalanceAndTransaction.hbs';
import scheduleUtil from '../util';

export default ViewDetailBase.extend({
    template,

    initialize() {
        Promise.all([
            this.getFileFormats(),
            this.getBAICodeDescriptions(),
            this.getSwiftCodeDescriptions(),
            this.getAccountFilterData(),
        ]).then(() => {
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },
    /**
     * Get file formats based on userGroup and user
     * @returns {Promise}
     */
    getFileFormats() {
        return scheduleUtil.getFileFormats(
            this.model.get('USERGROUP'),
            this.model.get('USERID'),
        ).then(this.parseFileFormats)
            .then((fileFormats) => {
                this.fileFormat = fileFormats.find(format => format.TYPECODE === this.model.get('EXPORTTYPECODE'));
            });
    },
    /**
     * Get account filter display data and update the model
     */
    getAccountFilterData() {
        const accountFilters = this.model.getSearchFieldValue('ACCOUNTFILTER');
        return scheduleUtil.getAccountFilterData({
            accountFilters,
            userGroup: this.model.get('USERGROUP'),
            userId: this.model.get('USERID'),
        })
            .then(this.updateAccountFilter.bind(this));
    },

    /**
     * Update the accountFilter value with new data from the response
     * @param {Object} resp
     */
    updateAccountFilter(resp) {
        const labels = resp.rows.map(this.formatAccountLabel);
        this.model.updateSearchFieldValue('ACCOUNTFILTER', labels);
    },

    /**
     * Build a label from all of the column data
     * @param {Object} row
     * @returns {Object}
     */
    formatAccountLabel(row) {
        const d = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
        return `${d.accountnum} - ${util.unescape(d.clientaccountname)} - ${d.bankcode} - ${d.name}`;
    },

    /**
     * Get BAI code descriptions and update the model
     * @returns {Promise}
     */
    getBAICodeDescriptions() {
        const codes = this.model.getSearchFieldValue('BAI_CODE');
        const codeArray = Array.isArray(codes) ? codes : [codes];
        return scheduleUtil.getBAIData(codeArray)
            .then(this.updateFilterDescriptions.bind(this, 'BAI_CODE'));
    },

    /**
     * Get Swift code descriptions and update the model
     * @returns {Promise}
     */
    getSwiftCodeDescriptions() {
        const swiftCode = this.model.get('SWIFT_TYPE_CODE');
        const codeArray = Array.isArray(swiftCode) ? swiftCode : [swiftCode];
        return scheduleUtil.getSwiftData(codeArray)
            .then(this.updateFilterDescriptions.bind(this, 'SWIFT_TYPE_CODE'));
    },

    /**
     * Concatenate the code and description together and update the seach field value
     * @param {String} fieldName - name of the search field to update
     * @param {Object} response - standard lookup response
     */
    updateFilterDescriptions(fieldName, response) {
        const descriptions = response.rows.map((row) => {
            const [code, description] = row.columns;
            return `${code.fieldValue} - ${description.fieldValue}`;
        });
        this.model.updateSearchFieldValue(fieldName, descriptions);
    },

    /**
     * Convert server data to usable hash
     * @return {Array} Array of data that has been converted to a hash
     */
    parseFileFormats(response) {
        return response.inquiryResponse.rows.map(row => transform.pairsToHash(
            row.columns,
            'fieldName',
            'fieldValue',
        ));
    },

    /**
     * Get the locale string based on the output content type
     * @param {string} output - output content type
     * @returns {string}
     */
    getOutputContent(output) {
        switch (output) {
        case 'GIRTRANS':
            return 'GIR.ExportType.TransOnly';
        case 'GIRBALANDTRANS':
            return 'GIR.ExportType.BalAndTran';
        case 'GIRBAL':
            return 'GIR.ExportType.BalOnly';
        default:
            return '';
        }
    },

    /**
     * Based on file format type code, evaluate if should show output content
     * @param {Object} fileFormat - file format with typecode property
     * @returns {boolean}
     */
    showOutputContent(fileFormat = {}) {
        const typeCodes = [
            constants.EXPORT_TYPE.CSVEXP,
            constants.EXPORT_TYPE.TSVEXP,
            constants.EXPORT_TYPE.BAIEXP,
            constants.EXPORT_TYPE.CAMTEXP,
        ];
        return typeCodes.includes(fileFormat.TYPECODE);
    },

    /**
     * Based on export typep code, evaluate if should show exclude header
     * @param {string} exportType
     * @returns {boolean}
     */
    showExcludeHeader(exportType) {
        const visibleTypes = [
            constants.EXPORT_TYPE.CSV,
            constants.EXPORT_TYPE.CSVEXP,
            constants.EXPORT_TYPE.TSV,
            constants.EXPORT_TYPE.TSVEXP,
        ];
        return visibleTypes.includes(exportType);
    },

    templateHelpers() {
        const outputContent = this.model.get('OUTPUTCONTENT');
        const exportTypeCode = this.model.get('EXPORTTYPECODE');
        return {
            ...ViewDetailBase.prototype.templateHelpers.call(this),
            exportTypeCodeDesc: this.fileFormat ? this.fileFormat.DESCRIPTION : '',
            showOutputContent: this.showOutputContent(this.fileFormat),
            outputContent: this.getOutputContent(outputContent),
            showIncrementalExport: this.model.get('CURRENTDAY'),
            showExcludeHeader: this.showExcludeHeader(exportTypeCode),
            showContinuationAccountSummaryRecord: scheduleUtil
                .showContinuationAccountSummaryRecord(outputContent, exportTypeCode),
        };
    },
});
