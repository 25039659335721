import CheckViewer from 'common/checkViewer/checkViewer';
import util from '@glu/core/src/util';

const CheckViewerRisk = CheckViewer.extend({
    loadCheck() {
        const EntryClass = this.options.entryClass;

        this.ui.checkNavDisplay.text(`${this.checkIndex + 1} of ${this.comboArray.length}`);
        this.ui.prevBtn.prop('disabled', this.checkIndex === 0);
        this.ui.nextBtn.prop('disabled', this.checkIndex === this.comboArray.length - 1);

        this.entryView = new EntryClass(util.extend({
            model: this.options.checks[this.checkIndex],
            hideHeader: true,
            bulkCheckView: true,
            checkViewer: this,
        }, this.options.entryCfg));

        this.checkDisplay.show(this.entryView);
    },

    prevCheck() {
        if (this.checkIndex === 0) {
            this.checkIndex = this.options.checks.length - 1;
        } else {
            this.checkIndex -= 1;
        }

        this.ui.serialField.val(this.comboArray[this.checkIndex].id).trigger('change');
    },

    nextCheck() {
        if (this.checkIndex === this.options.checks.length - 1) {
            this.checkIndex = 0;
        } else {
            this.checkIndex += 1;
        }

        this.ui.serialField.val(this.comboArray[this.checkIndex].id).trigger('change');
    },

    cancel() {
        this.navigateTo(this.options.entryCfg.returnRoute);
    },
});

export default CheckViewerRisk;
