import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'app/balanceAndTransaction/constants';
import accountUtil from 'app/smbPayments/util/accountUtil';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import configuration from 'system/configuration';
import store from 'system/utilities/cache';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import TransactionListView from '../transactionListView';

const isClientDeeplink = configuration.isPortal() && configuration.isClient();

const LoanTransactionListView = TransactionListView.extend({
    accountType: 'LOAN',
    hideBackHeader: false,

    initialize(options) {
        this.serviceNamePrefix = options.serviceNamePrefix;
        this.typeCode = options.typeCode;
        this.isLoan = true;
        this.prevDayTodayBalancesEnabled = applicationConfigParams.getValue('LNRPT', 'DISPLAYBALANCESPREVDAY') === '1';
        TransactionListView.prototype.initialize.call(this, options);
    },

    getContext() {
        return {
            functionCode: 'INST',
            menuCatergory: 'reporting',
            menuDescription: 'Balance and Transaction Reporting',
            name: locale.get('balanceTrans.loanAccountTransaction'),
            productCode: 'GIR',
            requestMappings: 'balanceAndTransactionReporting',
            requestParams: '?!_init=true&_productCode=GIR&_functionCode=INST&_typeCode=LOANTRAN&_mode=SELECT',
            serviceName: `${constants.LOAN_ACCTS_SERVICE_PREFIX + (this.serviceNamePrefix || '')}getTransactions`,
            typeCode: this.typeCode || 'LOANTRAN',
            exportPrintURL: configuration.isAdmin()
                ? '/loanAccounts/transactions/print/listView'
                : '/loanreporting/transactions/print/executeListView',
            inquiryId: 22451,
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            TransactionListView.prototype.onRender.call(this);
            if (isClientDeeplink && !store.has('btr:initialTab')) {
                this.ui.$cancelBtn.hide();
            }
        } else {
            const isFromContainer = store.get('btr:fromInitialContainer');
            moveToTopCheck(this.model);
            store.unset('helpPage');
            if (isClientDeeplink && !isFromContainer && !this.hasBackMethod()) {
                this.hideBackHeader = true;
            }
            this.loadViewRequirements();
        }
    },

    onClose() {
        if (!store.get('btr:fromInitialContainerTemp')) {
            store.unset('btr:fromInitialContainer');
        }
    },

    /**
     * Override
     */
    setUpRealTimeUi() {
        /**
         * If DISPLAYBALANCESPREVDAY enabled:
         * A real-time call is made to update balances
         */
        if (!this.isPrevDayTab() || this.prevDayTodayBalancesEnabled) {
            TransactionListView.prototype.setUpRealTimeUi.call(this);
        }
    },

    /**
     * Override
     */
    setupAccountTransactionBalanceDetails() {
        /**
         * If DISPLAYBALANCESPREVDAY enabled:
         * Today's balances for the loan accounts display above the Transactions
         * list view on the Previous Day tab for non-tiered loans
         */
        if (!this.isPrevDayTab()
            || this.prevDayTodayBalancesEnabled
            || this.displayTransactionTotals) {
            TransactionListView.prototype
                .setupAccountTransactionBalanceDetails.call(this);
        }
    },

    /**
     * Helper method to check whether current tab is Prev tab or not
     * @returns {boolean}
     */
    isPrevDayTab() {
        return (this.tabId || store.get('btrTabId')) === constants.ACCOUNT_SUMMARY.TABS.PRIOR;
    },

    /**
     * Helper method to return the current account tab id
     * @returns {string}
     */
    getTabId() {
        return this.model.get('tabId') || store.get('btrTabId');
    },

    /**
     * Override
     */
    buildPrintModel(model, gridView, inquiryId) {
        const exportModel = TransactionListView
            .prototype.buildPrintModel.call(this, model, gridView, inquiryId);
        if (this.model && this.shouldSendTabId()) {
            exportModel.tabId = this.getTabId();
        }
        return exportModel;
    },

    /**
     * Helper method to check whether to send tabId in
     * print payload request or not. Returns true if the account of type
     * loan and from separated tab (Previous Day/Account Groups)
     * @returns {boolean}
     */
    shouldSendTabId() {
        return this.getTabId() === constants.ACCOUNT_SUMMARY.TABS.PRIOR
            && accountUtil.isLoan(this.model.get('accountType'));
    },

    templateHelpers() {
        const obj = TransactionListView.prototype.templateHelpers.call(this, undefined);
        return util.extend(
            obj,
            {
                hideBackHeader: () => this.hideBackHeader,
            },
        );
    },
});

export default LoanTransactionListView;
