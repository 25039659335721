import DataApi from 'common/dynamicPages/api/data';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: '/requestForPayment/requestReceived',
            serviceFunc: null,
            businessType: null,
            context: 'RFP',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    events: {
        'click [data-action="transition"]': 'initiateTransition',
    },

    getJSONData() {
        return {
            item: {
                item: transform.hashToPairs({
                    MESSAGE_ID: this.storedModel.get('MESSAGE_ID'),
                    USERGROUP: this.storedModel.get('USERGROUP'),
                    PAYMENT_PRODUCT: 'RFPILIST',
                    PAYMENT_FUNCTION: this.storedModel.get('FUNCTION'),
                    PAYMENT_TYPE: this.storedModel.get('TYPE'),
                }),
            },
        };
    },
    loadRequiredData() {
        const self = this;

        if (this.mode === 'insert' || this.mode === 'import') {
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            this.contextDef.serviceName = '/requestForPayment/requestReceived';

            /*
             * When coming to this page from the list view the context action information is
             * stored in actionContext instead of actionData. All the downstream processes
             * expect actionData specifically.
             */
            if (!this.contextDef.actionData && this.contextDef.actionContext) {
                this.contextDef.actionData = this.contextDef.actionContext;
            }

            this.localeKey = 'adminPayment_';

            const contextKeyToUse = this.options.subPaymentContextKey || this.contextKey;
            DataApi.model.generateModelFromModel(store.get(`${contextKeyToUse}-actionModel`))
                .then((newModelParam) => {
                    const newModel = newModelParam;
                    newModel.set({
                        MESSAGE_ID: newModel.get('MESSAGE_ID'),
                        USERGROUP: newModel.get('USERGROUP'),
                        FUNCTIONCODE: newModel.get('FUNCTION'),
                        PRODUCTCODE: 'RFPILIST',
                        TYPECODE: newModel.get('TYPE'),
                    });

                    newModel.key = {
                        ...newModel.key,
                        MESSAGE_ID: 'true',
                        FUNCTIONCODE: 'true',
                        PRODUCTCODE: 'true',
                        TYPECODE: 'true',
                        SUBTYPE: 'true',
                        /*
                         * Unassigned requests have no user group whereas assigned requests can
                         * have multiple groups assigned and therefore must be uniquely
                         * identified. User group should be part of the key for assigned
                         * requests when viewing from an admin context.
                         */
                        USERGROUP: !!newModel.get('USERGROUP'),
                    };

                    newModel.convertModelAttributesToKeyJSON = util.bind(
                        self.convertModelAttributesToKeyJSON,
                        newModel,
                    );

                    newModel.fetch({
                        success() {
                            self.storedModel = newModel;
                            self.setEntryDescription(self.storedModel);
                            self.setHasLoadedRequiredData(true);

                            self.render();
                        },

                        error: util.bind(errorHandlers.loading, self),
                    });
                });
        }
    },
    /*
     * We have to override this method to prevent the read request from having a nested
     * item array
     */
    convertModelAttributesToKeyJSON(model) {
        return this.convertModelAttributesToKeyWithOptContextJSON(model);
    },

    onRender() {
        EntryView.prototype.onRender.call(this);
        if (this.hasLoadedRequiredData() && this.transitionTrackingAuditGrid) {
            this.customRegion.show(this.transitionTrackingAuditGrid);
        }
    },
});
