import Layout from '@glu/core/src/layout';
import contentWrapperTmpl from './contentWrapper.hbs';

export default Layout.extend({
    template: contentWrapperTmpl,

    events: {
        'click .payment-management': 'goToPaymentManagement',
    },

    goToPaymentManagement() {
        this.navigateTo('PAYMENTS/smbManagePayments');
    },

    initialize(options) {
        this.contentView = options.contentView;
    },

    onRender() {
        this.contentRegion.show(this.contentView);
    },
});
