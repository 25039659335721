import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';

export default Model.extend({

    initialize(values, options) {
        this.serviceName = options.context.serviceName;
    },

    sync(method, model, options) {
        if (method === 'read') {
            const getURLAcitons = constants.URL_GETENTITLEDACTIONS_ACTION;
            const readService = services.generateUrl(this.serviceName) + getURLAcitons;

            http.post(readService, {}, (response) => {
                const retObj = {};
                for (let i = 0; i < response.actionModes.length; i += 1) {
                    retObj[response.actionModes[i].toUpperCase()] = true;
                }
                options.success(retObj);
            }, () => {});
        }
    },

    isEntitled(action) {
        return this.get(action.toUpperCase());
    },
});
