import 'select2';
import CompositeView from '@glu/core/src/compositeView';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import $ from 'jquery';
import ContextApi from 'common/dynamicPages/api/context';
import Layout from '@glu/core/src/layout';
import loadingTemplate from 'common/templates/loadingGrid.hbs';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import constants from 'app/balanceAndTransaction/constants';
import Formatter from 'system/utilities/format';
import RefreshBalancesButton from 'app/balanceAndTransaction/views/refreshBalancesButton';
import BalanceListView from 'app/balanceAndTransaction/views/balanceListView';
import CompositePoller from 'app/balanceAndTransaction/models/compositePoller';
import Accounts from 'app/accounts/collections/accounts';
import AccountGroups from 'app/accounts/collections/accountGroups';
import ManageAccountsView from 'app/accounts/views/manageAccountsView';
import emptyView from 'app/balanceAndTransaction/views/emptyAccountGroupsView';
import AccountSections from 'app/balanceAndTransaction/collections/accountSections';
import AccountSectionGroups from 'app/balanceAndTransaction/collections/accountSectionGroups';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import mobileUtil from 'mobile/util/mobileUtil';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import Services from 'services';
import locale from '@glu/locale';
import tmpl from './accountGroupSummary.hbs';
import AccountGroupSummaryPreGrid from './accountGroupSummaryPreGrid';

const AccountGroupSummary = CompositeView.extend({

    modelEvents: {
        'change:sectionId': 'updateCollection',
    },

    id: 'showAccountGroupsTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
    },

    template: tmpl,
    itemViewContainer: 'div[data-hook="account-groups"]',

    getEmptyView() {
        if (this.collection.isFetching) {
            return Layout.extend({
                template: loadingTemplate,
            });
        }
        return emptyView;
    },

    ui: {
        $accountView: '#accountView',
        $acctGroupBtnContainer: '.accountGroupToggle',
        $acctGroupBtns: '.accountGroupToggle button',
        $acctTitleBtn: '.accountGroupsTitle, .manage-accounts-button',
        $acctRefreshBtn: '.refresh-time, .refresh-balances-button',
        $panelToggle: '[data-toggle="collapse"]',
    },

    events: {
        'click .manage-accounts-button': 'openManageAccountsDialog',
        'click @ui.$panelToggle': 'onPanelToggle',
    },

    itemEvents: {
        createAccountGroup() {
            this.openManageAccountsDialog();
        },
    },

    totalsWarning: 'balanceTrans.account.totals.notice',

    itemViewOptions() {
        return {
            parentModel: this.model,
        };
    },

    initialize(options) {
        this.tabId = options.tabId;
        this.ariaLabelledBy = options.ariaLabelledBy;
        this.depositDetailPageUrl = options.depositDetailPageUrl;
        this.creditCardDetailPageUrl = options.creditCardDetailPageUrl;
        this.loanDetailPageUrl = options.loanDetailPageUrl;
        this.model = new Model();
        this.collection = new Collection([]);
        this.collection.isFetching = true;
        this.poller = new CompositePoller();
        this.refreshBalancesButton = new RefreshBalancesButton({
            model: this.poller,
        });
        this.$('.accountGroupsTitle, .manage-accounts-button').hide();
        this.showTotalsForAllAccounts = this.getShowTotalsForAllAccountsConfigParam();
        this.loanExportPrintURL = options.loanExportPrintURL;
        this.depositExportPrintURL = options.depositExportPrintURL;
        this.creditCardExportPrintURL = options.creditCardExportPrintURL;
        this.tieredLoanDetailPageUrl = options.tieredLoanDetailPageUrl;
        this.prevDayTodayBalancesEnabled = applicationConfigParams.getValue('LNRPT', 'DISPLAYBALANCESPREVDAY') === '1';
        this.toaExportPrintURL = options.toaExportPrintURL;
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            this.poller.reset();
            this.addRefreshButton();
            this.updateRefreshTimestamp();
            this.trigger('afterRender');
            $(this.$el).attr('aria-labelledby', this.ariaLabelledBy);
        }
    },

    /*
     * @method: loadRequiredData
     * Method to load required data before rendering the view.
     */
    loadRequiredData() {
        this.getAccountSections();
    },

    /*
     *  @method: getAccountSections
     *  Method to get the available and entitled sections to display for
     *  Balance and Reporting tab.
     */
    getAccountSections() {
        this.accountSectionsCollection = new AccountSections({
            tabId: this.tabId,
        });
        this.accountSectionsCollection.fetch({
            success: data => this.ifNotClosed(this.onSuccess)(data),
            error: util.bind(this.onError, this),
        });
    },

    /*
     *  @method: getAccountGroupSections
     *  Method to get the account groups for the selected section
     */
    getAccountGroupSections(sectionModel) {
        const collection = new AccountSectionGroups({
            sectionModel,
        });


        this.collection.isFetching = true;
        /*
         * Reset because it clears our collection and forces it
         * to re-render to use the loading template
         */
        this.collection.reset();

        collection.fetch({
            success: data => this.ifNotClosed(this.onAccountGroupSectionsSuccess)(data),
            error: util.bind(this.onError, this),
        });
    },

    /*
     *  @method: onSuccess
     *  Called on successful retrieveal of the account sections collection.
     */
    onSuccess() {
        if (!this.hasLoadedRequiredData()) {
            this.setHasLoadedRequiredData(true);
            this.model.set(this.accountSectionsCollection.first().attributes);
            this.render();
        }
    },

    /*
     *  @method: updateRefreshTimestamp
     *  Update the timestamp when the grid data was last refreshed.
     */
    updateRefreshTimestamp() {
        // update refresh timestamp
        const $refreshEl = this.$('[data-hook="refresh-time"]');
        const accountGroupView = this.children.length > 0
            ? this.children.findByIndex(0) : false;
        if (util.isEmpty(accountGroupView)) {
            return;
        }
        this.listenToOnce(accountGroupView, 'balanceGridView:child:isReady', (timestamp) => {
            let localTimestamp = timestamp;
            if (accountGroupView && accountGroupView.gridView
                && accountGroupView.gridView.getRows()) {
                localTimestamp = accountGroupView.gridView.getRows().lastRefreshTimestamp;
            }
            if (!util.isNullOrUndefined(localTimestamp)
                && $refreshEl
                && $refreshEl.length > 0
                && localTimestamp !== '') {
                $refreshEl.text(`${Formatter.formatDate(localTimestamp)} ${Formatter.formatTime(localTimestamp)}`);
            }
        });
    },

    onError() {
        // TODO handle errors.
    },

    /*
     *  @method: showAccountGroup
     *  @param {jquery event} e
     *  Displays the appropriate link when an account section link is selected.
     */
    showAccountGroup(e) {
        const $target = this.$(e.currentTarget);
        const section = e.target.dataset.step;

        this.ui.$acctGroupBtns.removeClass('active');
        $target.addClass('active');

        this.model.set(this.accountSectionsCollection.get(section).attributes);
    },

    /*
     *  @method: updateCollection
     *  Updates the account groups collection for the item view.
     */
    updateCollection() {
        this.getAccountGroupSections(this.model);
        this.$('.accountGroupsTitle').text(locale.get(`gir.${this.model.get('id')}.AccountGroups`));
    },

    onAccountGroupSectionsSuccess(accountSectionGroups) {
        this.collection.isFetching = false;
        /**
         * NH-97988 | FX currency totals
         * Account groups response returns groups by currency. We need to
         * render account group sections based on uniq account group and not by currency
         */
        const { models } = accountSectionGroups;
        const filteredModels = models?.filter((model, indx) => models.findIndex(m => m.get('ACCOUNTGROUP') === model.get('ACCOUNTGROUP')) === indx);
        this.collection.reset(filteredModels);
        this.updateRefreshTimestamp();
    },

    onPanelToggle(e) {
        if (mobileUtil.isMobileGridEnabled()) {
            const $btn = $(e.currentTarget);
            // show/hide the panel containing the mobile grid
            const $panel = $(e.currentTarget).closest('.pre-grid-region').next();

            $btn.toggleClass('collapsed');
            $panel.toggleClass('hidden');
        }
    },

    getItemView() {
        return BalanceListView;
    },

    buildItemView(model, ItemViewClass) {
        const itemView = new ItemViewClass(this.getGridOptions(model));
        if (mobileUtil.isMobileGridEnabled()) {
            const title = model.get('ACCOUNTGROUP');
            // Clear any pre grid region
            this.$el.find('.pre-grid-region').remove();
            itemView.preGridContent = new AccountGroupSummaryPreGrid({
                title,
            });
            /**
             * Set suppressSavedViews to true to prevent mobile grid from fetching
             * saved views
             */
            itemView.suppressSavedViews = true;
        }
        this.poller.add(itemView.balancePoller);
        return itemView;
    },

    /*
     * @method: getGridOptions
     * @param {Model} model Contains the account group model
     * Get all information required to render the listview
     */
    getGridOptions(model) {
        const sectionType = this.model.get('sectionType');
        const sectionId = this.model.get('sectionId');
        const { ACCOUNT_SUMMARY } = constants;
        const isDepositAccountType = sectionType === ACCOUNT_SUMMARY.SECTION_TYPES.DEPOSIT;
        const isCreditCardAccountType = sectionId === ACCOUNT_SUMMARY.SECTIONS.CREDIT_CARDS;
        const isToaAccountType = sectionId === ACCOUNT_SUMMARY.SECTIONS.TIMEOPEN;
        const servicePrefix = this.getServicePrefix(sectionId);
        const serviceName = `${this.model.get('route')}/getAcctGroupBalanceSummary`;

        const options = {
            model,
            title: model.get('ACCOUNTGROUP'),
            sectionHasTotals: this.model.get('sectionHasTotals'),
            tabId: this.tabId,
            additonalLVCKey: `${ACCOUNT_SUMMARY.TABS.ACCOUNT_GROUPS}-${model.get('ACCOUNTGROUP')}`,
            sectionId,
            accountType: sectionType,
            showTotalsForAllAccounts: this.showTotalsForAllAccounts,
            totalsWarning: this.totalsWarning,
            currencyBadgesRegion: this[`${sectionType}currencyBadgesRegion`],
            totalsService: `${servicePrefix + this.tabId}/accountSummary/getSingleAcctGroupTotals`,
            requestRealtimeBalancesUrl: Services.generateUrl(`${servicePrefix}accountSummary/requestRealTimeBalances`),
            amountColumn: this.model.get('totalsColumn1'),
            availableColumn: this.model.get('totalsColumn2'),
            field1Label: this.model.get('totalsColumn1Label'),
            field2Label: this.model.get('totalsColumn2Label'),

            /*
             * TODO Must remove once assured balance list view is ref is unnecessary.
             * Used to avoid localization of app resource.
             */
            hasTotalsRefactor: true,

            suppressServicesUrlSuffix: true,

            // staticFilterDependsOn will be used by the grid's filter combos
            staticFilterDependsOn: [{
                filterName: 'Depends',
                filterParam: ['ACCOUNTGROUP', model.get('ACCOUNTGROUP')],
            }],

            // additionalSearchFields is used to filter the list view query
            additionalSearchFields: [{
                fieldName: 'ACCOUNTGROUP',
                fieldValue: [model.get('ACCOUNTGROUP')],
                dataType: 'text',
                operator: '=',
            }],
        };

        if (isCreditCardAccountType) {
            options.detailPageUrl = this.creditCardDetailPageUrl
                || 'BANK_ACC_INFO/creditCardAccountsTransactions';
            options.context = ContextApi.menuContext
                .getContext(constants.DEPOSIT_ACCTS_GRID_CONTEXT);
            options.context.serviceName = serviceName;
            options.exportPrintURL = this.creditCardExportPrintURL;
        } else if (isDepositAccountType) {
            options.detailPageUrl = this.depositDetailPageUrl
                || 'BANK_ACC_INFO/depositAccountsTransactions';
            options.context = ContextApi.menuContext
                .getContext(constants.DEPOSIT_ACCTS_GRID_CONTEXT);
            options.context.serviceName = serviceName;
            if (isToaAccountType) {
                options.exportPrintURL = this.toaExportPrintURL;
            } else {
                options.exportPrintURL = this.depositExportPrintURL;
            }
        } else {
            options.detailPageUrl = this.loanDetailPageUrl
                || 'BANK_ACC_INFO/loanAccountsTransactions';
            options.exportPrintURL = this.loanExportPrintURL;
            options.tieredLoanDetailPageUrl = this.tieredLoanDetailPageUrl
                || 'BANK_ACC_INFO/tieredLoanAccountDetail';

            options.context = {
                actionMode: constants.SELECT,
                functionCode: constants.INST,
                inquiryId: this.model.get('inquiryId'),
                productCode: constants.GIR,
                serviceName,
                typeCode: 'LOANACCT',
            };
        }
        options.context = {
            ...options.context,
            overrideFunctionCode: this.tabId,
            overrideTypeCode: this.model.get('sectionId'),
            overrideTypeCodePrevDay: this.getOverrideTypeCodePrevDay(),
            suppressPrintExportInquiryId: true,
        };
        return options;
    },

    /*
     *  @method: addRefreshButton
     *  Renders the refresh button to the view
     */
    addRefreshButton() {
        this.refreshBalancesButton.setElement(this.$('.refresh-balances-button')).render();
    },

    /*
     * @method: openManageAccountsDialog
     * Get all information required to before rendering
     * dialog.
     */
    openManageAccountsDialog() {
        // TODO This code should be moved into the manageaccountsview.
        const accounts = new Accounts({
            sections: this.accountSectionsCollection.pluck('id'),
        });
        const accountGroups = new AccountGroups();

        accounts.fetch().then(() => {
            accountGroups.fetch({
                tabId: this.tabId,
                accounts,
                sections: this.accountSectionsCollection.pluck('id'),
            }).then(() => {
                // Wait until we have the acounts and groups.
                this.showDialog(accounts, accountGroups);
            });
        });
    },

    /**
     * @param {Collection} accounts available accounts for groups
     * @param {Collection} accountGroups Existing account groups
     * Create the ManageAccountsView dialog now that data is available.
     */
    showDialog(accounts, accountGroups) {
        const manageAccountsView = new ManageAccountsView({
            accounts,
            groups: accountGroups,
            sectionId: this.model.get('sectionId'),
            sections: this.accountSectionsCollection,
            tabId: this.tabId,
        });

        this.listenTo(manageAccountsView, 'resolve', (data) => {
            const confirms = new Confirms({
                confirms: data.confirms,
            });
            if (data.errorCode && data.errorCode > 0) {
                this.$('.alerts').empty().append(alert.danger(
                    data.message[0],
                    {
                        details: confirms,
                    },
                ).render().el);
            } else {
                this.$('.alerts').empty().append(alert.success(data.message[0]).render().el);
                this.updateCollection();
            }
        });

        dialog.open(manageAccountsView);
    },

    /*
     *  @method: getShowTotalsForAllAccountsConfigParam
     *  Retrieves the configparam that determines if totals are shown for all
     * accounts in listview.
     *  If true, the totals aer tallied for all pages.  False displays totals for
     * that page.
     */
    getShowTotalsForAllAccountsConfigParam() {
        return serverConfigParams.get('BTRShowTotalsForAllAccounts') === 'true';
    },

    /*
     * @method: getOverrideTypeCodePrevDay
     * Return type code string for prev day loan/CC accounts
     * @returns {string|null}
     */
    getOverrideTypeCodePrevDay() {
        const sectionId = this.model.get('sectionId');
        const tabId = this.model.get('tabId');
        if (tabId !== constants.ACCOUNT_SUMMARY.TABS.PRIOR
            || this.prevDayTodayBalancesEnabled) {
            return null;
        }
        switch (sectionId) {
        case constants.ACCOUNT_SUMMARY.SECTIONS.LOANS:
            return constants.LOANACCTSPD;
        case constants.ACCOUNT_SUMMARY.SECTIONS.CREDIT_CARDS:
            return constants.CREDITCARDSPD;
        default:
            return null;
        }
    },

    /*
     * @method: getServicePrefix
     * @param {string} sectionId
     * Return service prefix based on account type
     * @returns {string}
     */
    getServicePrefix(sectionId) {
        const { SECTIONS } = constants.ACCOUNT_SUMMARY;
        const isLoanAccountType = sectionId === SECTIONS.LOANS;
        const isCreditCardAccountType = sectionId === SECTIONS.CREDIT_CARDS;
        if (isLoanAccountType) {
            return constants.LOAN_ACCTS_SERVICE_PREFIX;
        }
        if (isCreditCardAccountType) {
            return constants.CREDIT_CARD_ACCTS_SERVICE_PREFIX;
        }
        return constants.DEPOSIT_ACCTS_SERVICE_PREFIX;
    },

    templateHelpers() {
        return {
            accountGroupsTitle: locale.get(`gir.${this.model.get('id')}.AccountGroups`),

            // Return sections for dynamic links.
            sections: this.accountSectionsCollection
                ? this.accountSectionsCollection.toJSON() : null,
        };
    },
});

export default AccountGroupSummary;
