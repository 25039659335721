/* istanbul ignore file */

export const styles = (theme) => {
  const {
    dataComponents: {
      backgroundColorHover,
      border,
      btnTextColor,
      buttonReset,
      errorIconColor,
      textDark,
      textGrey,
      textLight,
      textMid,
      textStyles,
      textTransform,
      whiteBackgroundColor
    }
  } = theme;
  return ({
    arrow: {
      '& svg': {
        fill: textDark
      },
      padding: [0, 0, 2, 5],
      verticalAlign: 'bottom'
    },
    buttonReset,
    defaultText: {
      ...textStyles,
      color: textGrey,
      fontSize: 12,
      textTransform: 'capitalize'
    },
    deleteIcon: {
      '& svg': {
        fill: errorIconColor
      },
      height: 15
    },
    deleteText: {
      fontSize: 14,
      fontStyle: 'italic'
    },
    deleteWrapper: {
      '& > *': {
        padding: '0 8px'
      },
      '& > button': {
        ...buttonReset,
        borderRight: border,
        color: btnTextColor,
        fontSize: 14,
        padding: '0 8px'
      },
      '& button:last-child': {
        borderRight: 'none'
      },
      '& div:first-child': {
        padding: 0
      },
      alignItems: 'baseline',
      display: 'flex'
    },
    divergentHide: {
    },
    divergentIndicator: {
    },
    divergentPlaceholder: {
      '&$divergentHide': {
        borderColor: 'rgba(0, 0, 0, 0)'
      },
      '&$divergentIndicator': {
        borderColor: btnTextColor
      },
      backgroundSize: 'cover',
      border: '2px solid',
      borderRadius: '50%',
      height: 4,
      margin: '8px 3px',
      width: 4
    },
    dropdownHeader: {
      display: 'flex'
    },
    dropdownHeaderEmpty: {
      padding: [3, 7, 3, 13]
    },
    dropdownList: {
      '&.opened': {
        opacity: 1
      },
      backgroundColor: whiteBackgroundColor,
      border: `1px solid ${whiteBackgroundColor}`,
      boxShadow: '1px 1px 5px rgba(0, 0, 0, .1)',
      left: 0,
      'list-style': 'none',
      margin: 0,
      minWidth: 250,
      padding: '5px 0',
      top: 0,
      transition: 'opacity .3s ease-out',
      width: 'auto'
    },
    dropdownListHeader: {
      '&:first-child': {
        borderTop: 'none',
        padding: [5, 7, 0]
      },
      borderTop: `1px solid ${textGrey}`,
      color: textMid,
      margin: [0, -1],
      padding: [10, 7, 0]
    },
    dropdownListItem: {
      '&:hover': {
        background: backgroundColorHover
      },
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '3px 7px 3px 13px',
      position: 'relative',
      transition: 'background-color .3s ease-out'
    },
    dropdownListItemName: {
      ...buttonReset,
      ...textStyles,
      color: textDark,
      lineHeight: '28px',
      padding: 0,
      textAlign: 'left',
      textDecoration: 'none',
      width: '100%'
    },
    dropdownTitle: {
      ...textStyles,
      ...buttonReset,
      color: textDark,
      textTransform
    },
    dropdownWrapper: {
      ...textStyles,
      color: textDark,
      position: 'relative',
      zIndex: 100
    },
    menuActionsWrapper: {
      '& > button': {
        ...textStyles,
        '&:hover': {
          backgroundColor: backgroundColorHover
        },
        color: textDark,
        fontSize: 14,
        lineHeight: '28px',
        padding: [5, 15],
        textAlign: 'left',
        textDecoration: `none solid ${textDark}`,
        transition: 'background-color .3s ease-out',
        width: '100%'
      },
      backgroundColor: whiteBackgroundColor,
      boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
      marginLeft: -113,
      width: 113,
      zIndex: 100
    },
    menuToggle: {
      ...buttonReset,
      '& svg': {
        '&:hover': {
          background: btnTextColor,
          fill: textLight
        },
        borderRadius: '50%',
        position: 'absolute',
        right: 0,
        top: 0
      },
      display: 'inline-block',
      float: 'right',
      height: 16,
      position: 'relative',
      width: 16
    },
    parentPopover: {
      zIndex: 101
    },
    popover: {
      margin: 0,
      padding: 0
    },
    renameWrapper: {
      '& > form': {
        '& > input': {
          maxWidth: 115
        },
        alignItems: 'baseline',
        display: 'flex'
      }
    },
    rightContainer: {
      '& > span': {
        display: 'inline-block',
        paddingRight: 8
      },
      alignItems: 'center',
      display: 'flex',
      maxWidth: 86
    },
    saveCancelButtons: {
      '& > button': {
        ...buttonReset,
        borderRight: border,
        color: btnTextColor,
        fontSize: 14,
        padding: '0 8px'
      },
      '& button:last-child': {
        borderRight: 'none'
      }
    },
    saveNewCancelButton: {
      ...buttonReset,
      borderLeft: border,
      color: btnTextColor,
      fontSize: 14,
      padding: '0 0 0 11px'
    },
    saveNewChanged: {
      padding: '0 11px',
      ...textStyles,
      borderLeft: border,
      color: textGrey,
      fontStyle: 'italic',
      margin: '0 0 0 11px'
    },
    saveNewSaveButton: {
      ...buttonReset,
      color: btnTextColor,
      fontSize: 14,
      margin: '0 10px'
    },
    saveNewToggle: {
      ...buttonReset,
      color: btnTextColor
    },
    saveNewWrapper: {
      '& input': {
        margin: '0 0 0 12px'
      },
      alignItems: 'center',
      display: 'flex'
    }
  });
};

export default styles;
