import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'app/balanceAndTransaction/constants';

const AccountReporting = Model.extend({
    /**
     * @method generateRealTimeAccountInquiry
     * @returns {object} Service Request Param
     * Creates service param data needed to get data on nonRealTimeAccounts count
     */
    generateRealTimeAccountInquiry() {
        return {
            IncludeMapData: 1,

            queryCriteria: {
                inquiryId: constants.INQUIRYID_REALTIMEACT_COUNT,

                action: {
                    typeCode: 'GIRACCT',
                    entryMethod: 0,
                    productCode: 'GIR',
                    actionMode: 'SELECT',
                    functionCode: 'INST',
                },
            },

            requestHeader: {
                queryPagesize: 1,
                queryOffset: 0,
            },
        };
    },

    /**
     * Function to save or get data from server
     * @param  {string} method Action to perform
     * @param  {object} model model object affected
     * @param  {object} options object containing success and error callbacks
     * (parse by default)
     * @returns {Promise|undefined} - jQuery XHR/Promise
     */
    sync(method, model, options) {
        if (method === 'read') {
            const data = this.generateRealTimeAccountInquiry();

            return http.post(services.inquiryQueryResults, data, (response) => {
                options.success(response);
            }, () => {
                options.error();
            });
        }

        // Maybe return a rejected Promise for bad method?
        return undefined;
    },

    /**
     * Default Function to modify data fetched from service
     * @param  {object} response data from service
     * @returns {object} Model attributes
     */
    parse(response) {
        return {
            nonRealTimeAccountsCount: response.queryResponse.QueryData.numRows,
        };
    },
});

export default AccountReporting;
