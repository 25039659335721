import Dialog from '@glu/dialog';
import Formatter from 'system/utilities/format';
import $ from 'jquery';
import ReadOnlyRowView from './readOnlyRowView';
import RowDistributionView from './rowDistributionView';

const MASK_OPTIONS = Formatter.getCurrencyMaskOptions();
// do not show pennies
MASK_OPTIONS.digits = 0;

const RowView = ReadOnlyRowView.extend({
    events: {
        'change td input': 'updateModel',
        'keyup td input': 'updateModel',
        'click .btn': 'openDistributionDialog',
        'focusout input[type="text"]': 'handleFocusout',
        'focusin input[type="text"]': 'handleFocusin',
    },

    behaviors: {
        NumberInputAcceleration: {},
    },

    handleFocusin(e) {
        const $target = this.$(e.currentTarget);
        $target.inputmask('decimal', MASK_OPTIONS);
        $target.val(this.model.get(+$target.data('attr')));
    },

    handleFocusout(e) {
        const $target = this.$(e.currentTarget);
        let val = this.getFormattedNumber(this.model.get(+$target.data('attr')));

        if ($target.val() === '') {
            val = 0;
        }

        $target.inputmask('remove').val(val);
    },

    updateModel(e) {
        // NumberInputAcceleration will handle these
        if (e.type === 'keyup' && (e.keyCode === $.ui.keyCode.UP || e.keyCode === $.ui.keyCode.DOWN)) {
            return;
        }

        const $target = this.$(e.currentTarget);
        const attr = +$target.data('attr');
        const val = +$target.val();

        this.model.set(attr, val);
    },

    openDistributionDialog() {
        Dialog.custom(new RowDistributionView({
            model: this.model,
            flowType: this.flowType,
            viewModel: this.viewModel,
        }));
    },

    templateHelpers() {
        const helpers = ReadOnlyRowView.prototype.templateHelpers.call(this);
        helpers.readOnly = false;
        return helpers;
    },
});

export default RowView;
