// This file has to be removed in nearest future. TODO - reuse file from pcm/components/Headline.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { appBus } from '@glu/core';
import locale from '@glu/locale';
import Icon from 'pcm/common/components/Icon'; // TODO check Headline.jsx
import { isDeepLinked } from '../../common/util/deeplinkUtil';

const BackLink = ({ backLink }) => (backLink ? (
    <div className="page-back pull-left">
        {typeof backLink === 'function' ? (
            <button onClick={backLink} className="back-icon" title="Back">
                <Icon name="arrow-left" />
                <span className="sr-only">{locale.get('back')}</span>
            </button>
        ) : (
            <a href={backLink} className="back-icon" title="Back">
                <Icon name="arrow-left" />
                <span className="sr-only">{locale.get('back')}</span>
            </a>
        )}
    </div>
) : null);

BackLink.propTypes = {
    backLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

BackLink.defaultProps = {
    backLink: '',
};

const HeadlineContent = ({ headlineText, headlineContent }) => (
    <div className="headline-content">
        <h1>{headlineText}</h1>
        {headlineContent || ''}
    </div>
);

HeadlineContent.propTypes = {
    headlineText: PropTypes.string,
    headlineContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

HeadlineContent.defaultProps = {
    headlineText: '',
    headlineContent: '',
};

class Headline extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shouldShow: true,
            headlineContent: null,
            backLink: '',
            headlineText: '',
            headlineClass: '',
        };

        this.handleOptions = this.handleOptions.bind(this);
    }

    componentDidMount() {
        appBus.on('show:page', this.handleOptions);
    }

    componentWillUnmount() {
        appBus.off('show:page', this.handleOptions);
    }

    handleOptions(viewOrComponent, title, options) {
        const {
            headlineClass, backLink, headlineText, headlineContent,
        } = options || {};

        this.setState({
            shouldShow: !viewOrComponent.cid,
            headlineClass,
            backLink,
            headlineText,
            headlineContent,
        });
    }

    render() {
        const {
            headlineClass, shouldShow, backLink, headlineText, headlineContent,
        } = this.state;

        const showheadline = isDeepLinked() ? false : shouldShow && (headlineText || backLink);

        return (showheadline ? (
            <div className={`page-headline row ${headlineClass}`}>
                <BackLink backLink={backLink} />
                <HeadlineContent headlineText={headlineText} headlineContent={headlineContent} />
            </div>
        ) : null);
    }
}

export default Headline;
