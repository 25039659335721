import '../themeDefaults';

export default ({ mobilegrid }) => ({
  rendererRoot: {
    margin: '1rem 1rem 0',
    paddingBottom: 70
  },
  tableMain: {
    width: '100%',
    borderCollapse: 'collapse',
    '& thead th': {
      borderBottom: mobilegrid.headerBorder,
      paddingBottom: '0.5rem'
    },
    '& th': {
      textAlign: 'left'
    }
  },
  bulkActionSvg: {
    '& input[type="checkbox"] + div svg': {
      width: 16,
      height: 16
    }
  },
  noContent: {
    color: mobilegrid.noContentText,
    padding: 20,
    textAlign: 'center'
  }
});
