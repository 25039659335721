import BaseLoanView from 'app/loans/views/baseLoanView';
import locale from '@glu/locale';
import $ from 'jquery';
import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import Collection from '@glu/core/src/collection';
import userInfo from 'etc/userInfo';
import store from 'system/utilities/cache';
import Formatter from 'system/utilities/format';
import scroll from 'common/util/scroll';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import Loan from 'app/loans/models/addPayment';
import loans from 'app/loans/api/common';
import moment from 'moment';
import workspaceHelper from 'common/workspaces/api/helper';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import validatorPatterns from 'system/validatorPatterns';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import payUtil from 'common/util/paymentUtil';
import Constants from 'app/loans/constants';
import paymentTmpl from './createPaymentFromTemplate.hbs';
import drawTmpl from './createLoanDrawFromTemplate.hbs';

export default BaseLoanView.extend({
    initialize(options) {
        this.model = new Loan(options.model);
        this.model.jsonData = options.jsonData;
        this.template = options.typeCode === 'LOANPAY' ? paymentTmpl : drawTmpl;
        this.contextKey = 'payment_listView_corp';
        this.title = options.title || '';
        this.typeCode = options.typeCode;
        this.title = options.title || '';
        this.debitInquiryId = options.debitInquiryId;
        this.creditInquiryId = options.creditInquiryId;
        this.payMethod = options.payMethod;
        this.typeCode = options.typeCode;
        this.productCode = options.productCode;
        this.functionCode = options.functionCode;
        // set properties to render and control payment options
        this.preferences = options.preferences;
        this.paymentOptions = loans.getAvailablePaymentOptions(options.preferences);
        this.savedOptions = this.getSavedOptions(
            options.model,
            options.model.AMOUNTTYPES_SELECTED,
        );
        this.paymentTotal = 0;
        this.savedCollection = new Collection(this.savedOptions);
        this.currentAmountOfPaymentTypes = (this.savedCollection && this.savedCollection.length > 1)
            ? this.savedCollection.length : 1;
        this.enabledOptions = new Collection(this.paymentOptions);
        this.amountOfPaymentOptions = this.enabledOptions.length;
        this.model.set({
            _oldPaymentType: this.typeCode,
            paymentType: this.typeCode,
            PARENTUSERGROUP: userInfo.get('group'),

            // set the default value in the model to match the first option in the comboBox
            SELECTION_0: this.paymentOptions[0].key,
        });
        this.blockedDates = [];
        this.processingDays = [];
        this.daysForward = [37];
        this.cutOffTimes = [];
        this.datePickerParameters = options.parameters;
        this.listenTo(this.model, 'retrieved', this.handleBusinessDays);
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
        this.listenTo(this.model, 'modelAction:saveWithWarning', this.saveWithWarning);
        this.entitlement = (this.typeCode === 'LOANPAY') ? options.entLoanPay : options.entLoanDraw;
        this.showAccountBalance = serverConfigParams.get('DisplayAccountBalanceOnLoanScreens') === 'true';
        this.showInterestRate = serverConfigParams.get('LookupLoanDrawInterestRate') === 'true';
        this.variableRateSubtypes = serverConfigParams.get('LookupLoanDrawInterestRateAcctSubTypes')?.split(',');
    },

    events: util.extend(
        {},
        BaseLoanView.prototype.events,
        {
            'click @ui.$toggleForm': 'toggleForm',
            'click @ui.$toggleRestrict': 'toggleRestrict',
        },
    ),

    ui: util.extend({}, BaseLoanView.prototype.ui, {}),

    regions: {
        alertRegion: '#alertRegion',
        pmtInvoicesRegion: '[data-region="pmtInvoicesRegion"]',
        pmtInvoiceAmountsRegion: '[data-region="pmtInvoiceAmountsRegion"]',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    handleBusinessDays(days) {
        if (days.holidays.length > 0) {
            for (let x = 0; x < days.holidays.length; x += 1) {
                this.blockedDates.push(days.holidays[x]);
            }
        }

        this.daysForward.shift();
        this.daysForward.push(days.maxForwardDays);

        this.processingDays.shift();
        this.processingDays.push(days.businessDays);

        this.cutOffTimes.shift();
        this.cutOffTimes.push(days.cutoff);

        // display cutoff if returned and config allows
        if (days.cutoffDateTimeTz) {
            payUtil.showCutoff(days.cutoffDateTimeTz, this.$('.ui-datepicker-trigger'), this.typeCode);
        }

        this.earliestDay = days.earliestDay;
        const valueDate = moment(days.earliestDay);
        const diffDays = moment(new Date()).startOf('day').diff(valueDate, 'days');

        this.model.set('VALUE_DATE', valueDate.format(userInfo.getDateFormat()));
        this.model.set('TRAN_DATE', days.tranDate);

        this.ui.$datePicker.data('daterangepicker').updateCalendars({
            blockedDates: this.blockedDates,
            daysBack: diffDays,
            daysForward: days.maxForwardDays,
        });
    },

    onRender() {
        const self = this;
        if (!this.hasLoadedRequiredData()) {
            store.unset('helpPage');
            this.loadRequiredData();
        } else {
            this.ui.$datePicker.nhDatePicker({
                blockedDates: this.blockedDates,
                processingDays: this.processingDays,
                cutOffTimes: this.cutOffTimes,
                daysBack: 0,
                showCalendarIcon: true,
                showDropdowns: true,
            });

            this.ui.$datePicker.on('apply.daterangepicker', (e) => {
                self.ui.$valueDate.text(Formatter.formatDateFromUserFormat(e.target.value, 'D MMM YYYY'));
                if (self.ui.$summaryOn.hasClass('hide')) {
                    self.ui.$summaryOn.removeClass('hide');
                }
                self.model.set('TRAN_DATE', e.target.value);
                this.updateSummaryTotal();
            });

            this.ui.$saveAsTemplate.attr('disabled', 'disabled');
            this.model.getBusinessDays(this.datePickerParameters);
            this.getPaymentMessage();
            loans.applyMask(this);
            this.displaySummaryTotal();
            /*
             * When creating a loan payment from template, the loan account input is not
             * initialized as a combobox and thus never triggers the 'change' event when an
             * account is selected that would normally kick off the "onLoanAccountChanged"
             * function. Instead, we get the account details here manually.
             */
            this.getDebitAccountDetails(undefined, true);
            this.getLoanAccountDetails(undefined, undefined, true);

            /**
             * set inputmask to number to prevent user from entering illegal
             * characters or multiple decimal points
             */
            this.ui.$amount.inputmask('number');
        }
    },

    beforeSubmit() {
        const invoicePaymentTypeSelected = this.selectedPaymentType === Constants.TYPE.INVOICE_ONLY;
        const invoiceSelected = !!this.model.get('INVOICENUM');

        if (invoicePaymentTypeSelected) {
            // if no valid invoice is selected, don't do anything here
            if (!invoiceSelected) {
                return;
            }
            for (let i = 0; i < this.amountOfPaymentOptions; i += 1) {
                this.model.removeValidator(`AMOUNT_${i}`);
                this.model.removeValidator(`SELECTION_${i}`);
            }
        } else {
            // manually set the invoice num to empty here to ensure the database is updated
            this.model.set('INVOICENUM', '');

            const enabledOption = this.enabledOptions.at(0);
            let unSelectedOptions = this.enabledOptions.pluck('key');
            unSelectedOptions = unSelectedOptions.length > 1 ? unSelectedOptions : [];
            /*
             * when we only have one unselectedOption, that causes it to get
             * removed from the model
             */
            if (this.preferences[0] && !this.preferences[0].singleOnlyPaymentOptionSupported) {
                /*
                 * the model does not adequately keep track of all selected payment options
                 * during form interactions. Instead, prior to submitting, they're set one by
                 * one below. this function is called here to clear them prior to them being
                 * properly set.
                 */
                this.clearPaymentDetailsOnModel();
            }

            for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
                if (this.typeCode === 'LOANPAY') {
                    if (!$(`.multifield-container-${x}`).hasClass('hide')) {
                        const value = $(`#amount_${x}`).val();
                        this.model.set(`AMOUNT_${x}`, value);
                        const option = $(`#selection_${x}`).val();
                        this.model.set(`SELECTION_${x}`, option);
                        this.model.set(option, value);
                        // remove the chosen option from the array
                        unSelectedOptions = util.without(unSelectedOptions, option);
                    }
                }
                if (this.typeCode === 'LOANDRAW' || this.amountOfPaymentOptions === 1) {
                    this.model.removeValidator(`AMOUNT_${x}`);
                }
            }

            this.model.removeValidator('DEBIT_CURRENCY');
            this.model.removeValidator('CREDIT_CURRENCY');

            if (this.typeCode === 'LOANPAY') {
                if (!this.model.get('CREDIT_AMOUNT') && this.options.preferences.singleOnlyPaymentOptionSupported) {
                    this.model.set('CREDIT_AMOUNT', this.model.get('DEBIT_AMOUNT'));
                }
                if (enabledOption.get('onlyOption')) {
                    this.model.validators[enabledOption.get('key')].exists = true;
                } else {
                    delete this.model.validators[enabledOption.get('key')].exists;
                }
            } else {
                this.model.validators.CREDIT_AMOUNT.exists = true;
                delete this.model.validators[enabledOption.get('key')].exists;
            }
        }

        if (this.ui.$saveAsTemplate.is(':checked')) {
            this.model.set('SAVEASTEMPLATE', '1');
        } else {
            this.model.unset('SAVEASTEMPLATE');
        }

        if (this.earliestDay) {
            const valueDate = Formatter.formatDateFromUserFormat(this.model.get('VALUE_DATE'), 'MM/DD/YYYY');
            const earliestDate = moment(this.earliestDay);
            if (moment(valueDate).isBefore(earliestDate)) {
                this.model.set('VALUE_DATE', earliestDate.format(userInfo.getDateFormat()));
                this.model.set('TRAN_DATE', earliestDate.format(userInfo.getDateFormat()));
            } else {
                this.model.set('TRAN_DATE', moment(valueDate).format(userInfo.getDateFormat()));
            }
        }

        this.model.set('ENTRYMETHOD', '1');
    },

    submit() {
        this.beforeSubmit();

        // validate the form
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }

        this.toggleButtonsEnabled(false);

        this.model.unset('_saveIncomplete');

        const self = this;

        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    store.set(`${self.contextKey}-alertMessage`, 'INSERT');
                    store.set(`${self.contextKey}-confirms`, confirmResponse);
                    workspaceHelper.returnToCurrentWorkspace(self);
                },

                error(response) {
                    self.toggleButtonsEnabled(true);

                    if (response.error.resultType === 'WARNING') {
                        if (response.error.errorCode === 540) {
                            Dialog.custom(new DuplicateDialog({
                                model: response,
                                methodName: 'SAVE',
                            }));
                        } else {
                            Dialog.custom(new WarningDialog({
                                model: response,
                                methodName: 'SAVE',
                                confirms: response.error.confirms,
                            }));
                        }
                    } else {
                        const alertView = loans.getAlertView(response);

                        self.$('#alertRegion').hide();

                        $('html, body').animate({
                            scrollTop: 0,
                        }, 100);

                        self.alertRegion.show(alertView);
                        self.$('#alertRegion').fadeIn(200, () => alertView.$el.focus());
                    }
                },
            },
        );
    },

    saveDraft() {
        this.beforeSubmit();
        // validate the form
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }

        this.toggleButtonsEnabled(false);

        const self = this;
        this.model.set({
            _saveIncomplete: true,
        });

        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    store.set(`${self.contextKey}-alertMessage`, 'INSERT');
                    store.set(`${self.contextKey}-confirms`, confirmResponse);
                    workspaceHelper.returnToCurrentWorkspace(self);
                },

                error(response) {
                    self.toggleButtonsEnabled(true);

                    if (response.error.resultType === 'WARNING') {
                        Dialog.custom(new WarningDialog({
                            model: response,
                            methodName: 'SAVE',
                            confirms: response.error.confirms,
                        }));
                    } else {
                        const alertView = loans.getAlertView(response);

                        self.$('#alertRegion').hide();

                        $('html, body').animate({
                            scrollTop: 0,
                        }, 100);

                        self.alertRegion.show(alertView);
                        self.$('#alertRegion').fadeIn(200, () => alertView.$el.focus());
                    }
                },
            },
        );
    },

    saveWithWarning(model, option) {
        loans.saveWithWarning(this, option);
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    setPaymentAmountToType(value, index) {
        const type = $(`#selection_${index}`).val();
        this.model.set(type, value);
    },

    toggleForm() {
        const $templateCode = this.ui.$templateCodeForm;
        if ($templateCode.hasClass('hide')) {
            $templateCode.removeClass('hide');
            this.ui.$templateDescrForm.removeClass('hide');
            this.ui.$templateRestrictForm.removeClass('hide');

            this.model.validators.TEMPLATE_CODE = {
                description: locale.get('loans.template.name'),
                exists: true,
                matches: validatorPatterns.TEMPLATECODE_PATTERN,
                overrideError: 'isTemplateCode',
            };
            this.model.validators.TEMPLATE_DESCRIPTION = {
                description: locale.get('loans.template.desc'),
                exists: true,
            };
        } else {
            this.model.validators.TEMPLATE_CODE = null;
            this.model.validators.TEMPLATE_DESCRIPTION = null;
            $templateCode.addClass('hide');
            this.ui.$templateDescrForm.addClass('hide');
            this.ui.$templateRestrictForm.addClass('hide');
        }
    },

    toggleRestrict(e) {
        if ($(e.currentTarget).hasClass('restricted')) {
            $(e.currentTarget).removeClass('hide');
            this.model.set('RESTRICTTEMPLATE_FLAG', '1');
        } else {
            $(e.currentTarget).addClass('restricted');
            this.model.set('RESTRICTTEMPLATE_FLAG', '0');
        }
    },

    templateHelpers() {
        const self = this;
        return {
            originLabel() {
                return loans.getOriginLabel(self.typeCode);
            },

            destinationLabel() {
                return loans.getDestinationLabel(self.typeCode);
            },

            paymentOptions() {
                // @TODO When there is only one option, display only the amount
                return self.enabledOptions.toJSON();
            },

            getTitle() {
                self.title = (self.typeCode === 'LOANPAY') ? locale.get('loans.new.payment') : locale.get('loans.new.drawdown');
                return self.title;
            },

            hasMultipleOptions() {
                return (self.typeCode === 'LOANPAY');
            },

            hasInsertTemplateEntitlement() {
                return self.entitlement;
            },

            singleOptionOnly() {
                return self.preferences.singleOnlyPaymentOptionSupported;
            },

            getAmountText: locale.get(`loans.${self.typeCode.toLowerCase()}.amount`),
            displayInterestRate: this.showInterestRate,
        };
    },
});
