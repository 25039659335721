import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import decisionHistorySearchTmpl from 'app/checkManagement/views/decisionHistorySearch.hbs';
import PositivePayDecisionHistoryList from 'app/checkManagement/views/listPositivePayDecisionHistory';
import ReversePositivePayDecisionHistoryList from 'app/checkManagement/views/listReversePositivePayDecisionHistory';
import EPositivePayDecisionHistoryList from 'app/checkManagement/views/listEPositivePayDecisionHistory';
import Constants from 'app/checkManagement/constants';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import filterApi from 'common/dynamicPages/api/filters';
import validationPatterns from 'system/validatorPatterns';

const DecisionHistorySearch = Layout.extend({
    template: decisionHistorySearchTmpl,

    ui: {
        $searchButton: 'div.section-body button[name="search"]',
    },

    events: {
        'click @ui.$searchButton': 'search',
    },

    initialize(options) {
        this.model = options.fraudControlModel;
        this.typeCode = this.model ? this.model.get('TYPE') : null;
        this.productCode = this.typeCode === Constants.ELECTRONIC_POS_PAY_TYPE
            ? Constants.RISK : Constants.CM;
        this.accountFilter = this.model ? this.model.get('ACCOUNTFILTER') : null;

        this.options = util.extend(
            {},
            options,
            {
                menuCategory: 'RISK',
                serviceName: 'dummy',

                contextDef: {
                    serviceName: 'dummy',
                    productCode: this.productCode,
                    functionCode: Constants.BATCH,
                    typeCode: this.typeCode,
                },

                mode: 'insert',
            },
        );
    },

    onRender() {
        // hide search button
        this.ui.$searchButton.hide();
        this.showFilters();
    },

    search() {
        const filterModel = this.filterRegion.currentView.model;
        const convertSearchType = true;
        const serialNumber = filterModel.get('CHECKNUMBER');
        const amount = filterModel.get('ISSUEAMOUNT') || filterModel.get('PAIDAMOUNT') || filterModel.get('AMOUNT');
        const wholeNumber = new RegExp(validationPatterns.NUMERIC_PATTERN);
        const amountPattern = new RegExp(validationPatterns.AMOUNT_PATTERN);
        const additionalSearchFields = filterApi.gatherFilterData(filterModel, convertSearchType);

        if (!util.isEmpty(serialNumber)) {
            if (!wholeNumber.test(serialNumber)) {
                this.alertMessage('imageSearch.ivalid.serialnumber');
                return;
            }
            if (serialNumber === '0') {
                this.alertMessage('CM.Error.CheckNumberZero');
                return;
            }
        }
        if (!util.isEmpty(amount) && !amountPattern.test(amount)) {
            this.alertMessage('CM.Error.invalidAmount');
            return;
        }

        this.alertRegion.close();

        additionalSearchFields.push({
            fieldName: 'AccountFilter',
            fieldValue: [this.accountFilter],
            dataType: 'text',
            operator: '=',
        });

        const options = {
            additionalSearchFields,
            accountFilter: this.accountFilter,
            accountNumber: this.model.get('ACCOUNT_NUMBER'),
            accountTitle: this.model.get('ACCOUNT_TITLE'),
            typeDesc: this.model.get('TYPEDESC'),
        };
        if (this.options && this.options.returnRoute) {
            options.returnRoute = this.options.returnRoute;
        }

        options.batchTnum = this.model.get('BATCHTNUM');
        const decisionHistoryListView = this.getDecisionHistoryList(this.typeCode, options);
        this.decisionHistoryListRegion.show(decisionHistoryListView);
    },

    /**
     * Creates the appropriate Decision History list based on type and returns it.
     * @param {string} typeCode - the type code
     * @param {object} options - an options object.  This is passed along to
     * decision history list view.
     * @return {Layout} a ListView object
     *
     */
    getDecisionHistoryList(typeCode, options) {
        switch (typeCode) {
        case Constants.POS_PAY_TYPE:
            return new PositivePayDecisionHistoryList(options);
        case Constants.REVERSE_POS_PAY_TYPE:
            return new ReversePositivePayDecisionHistoryList(options);
        case Constants.ELECTRONIC_POS_PAY_TYPE:
            return new EPositivePayDecisionHistoryList(options);
        default:
            return null;
        }
    },

    alertMessage(errorMessage) {
        this.alertView = alert.danger(
            locale.get(errorMessage),
            {
                canDismiss: false,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    getCacheName() {
        return `model-${this.filterContextDef.serviceName}-${this.productCode}-${Constants.BATCH}`;
    },

    templateHelpers() {
        return {
            getString(key) {
                return locale.get(key);
            },
        };
    },

    showFilters() {
        const filterContextDef = this.options.contextDef;
        filterContextDef.serviceName = 'advanceFilter';
        this.filterContextDef = filterContextDef;

        const self = this;
        const requestData = {
            filterId: this.getFilterId(this.typeCode, this.options),

            typeInfo: {
                productCode: this.productCode,
                functionCode: Constants.BATCH,
                typeCode: this.typeCode,
            },
        };
        const promiseMe = filterApi.getFilters(requestData, self);

        promiseMe.then(() => {
            self.listenToOnce(self.filterRegion.currentView, 'item:rendered', () => {
                self.$el.find('div[data-region="filterRegion"] fieldset.section.section-container').removeClass('section-container');
            });
            self.ui.$searchButton.removeClass('btn-secondary').addClass('btn-primary').show();
        });
    },

    /**
     * Gets the filter id for the decision history search UI
     * @param {string} typeCode - the type code
     * @param {object} options - an option object
     * @return {number} the filter id
     */
    getFilterId(typeCode, options) {
        return options.filterId;
    },

    back() {
        window.history.back();
    },
});

export default DecisionHistorySearch;
