import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import StyleContext from '../StyleContext/StyleContext';

export default function ColumnsDroppable({ children, primary }) {
  const classes = useContext(StyleContext);
  return (
    <Droppable droppableId={`droppable${primary ? '-primary' : ''}`}>
      {(providedDrop) => (
        <div
          {...providedDrop.droppableProps}
          ref={providedDrop.innerRef}
          className={classes.columnsDroppable}
        >
          {children}
          {providedDrop.placeholder}
        </div>
      )}
    </Droppable>
  );
}

ColumnsDroppable.propTypes = {
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool
};

ColumnsDroppable.defaultProps = {
  primary: false
};
