import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import $ from 'jquery';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import AccountModel from 'app/reports/models/accounts';
import RemitterModel from 'app/reports/models/remitter';
import AccountInfoView from 'app/reports/views/lockbox/modals/remitter/accountInfo';
import RemitterCollection from 'app/reports/collections/lockboxRemitters';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import modifyRemitterTmpl from 'app/reports/views/lockbox/modals/remitter/modifyRemitter.hbs';

export default Layout.extend({
    template: modifyRemitterTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-lg lockbox-notes',

    ui: {
        $remitter: '[name="REMITTER_NAME"]',
        $existing: '[data-hook="existing-remitters"]',
        $name: '#remitter-name',
        $rid: '#remitter-id',
        $addAlert: '.lbxAddAlert',
        $removeAlert: '.lbxRemoveAlert',
    },

    initialize(options) {
        this.model = new RemitterModel();
        this.rid = options.model ? (options.model.get('REMITTER_ID') || false) : false;

        /*
         * this.action = options.action;
         * this.assignExistingRemitters = options.allowExistingRemitters || false;
         */
        this.modalType = options.modalType;
        this.remitterName = options.remitterName || false;
        this.dialogButtons = [{
            text: locale.get('button.save'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];

        if (this.modalType === 'basic') {
            this.account = options.account;
            this.existingRemitters = new RemitterCollection();
            this.listenTo(this.existingRemitters, 'sync', this.render);
            this.dialogTitle = locale.get('LBX.ModifyRemitter');
            this.setRemitterName();
        }

        if (this.modalType === 'advanced') {
            this.dialogTitle = this.remitterName ? locale.get('LBX.ModifyRemitter') : locale.get('LBX.NewRemitter');
            this.setRemitterName();
        }
    },

    setRemitterName() {
        if (this.remitterName) {
            this.remitterName = $('<div/>').html(this.remitterName).text();
            this.model.set({
                REMITTER_NAME: this.remitterName,
            });
            if (this.rid) {
                // set the id to perform an update if this exists
                this.model.set({
                    id: this.rid,
                    REMITTER_ID: this.rid,
                });
            }
            this.dialogTitle += ` [${this.remitterName}]`;
        } else {
            this.dialogTitle += ' [None]';
        }
    },

    setRemitterId() {
        if (!util.isEmpty(this.existingRemitters)) {
            util.forEach(this.existingRemitters.models, (item) => {
                if (item.get('REMITTER_NAME') === this.model.get('REMITTER_NAME')) {
                    const remitterId = item.get('REMITTER_ID');
                    if (remitterId) {
                        this.model.set({
                            id: remitterId,
                            REMITTER_ID: remitterId,
                        });
                        this.rid = remitterId;
                    }
                }
            });
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const options = {};
            this.setChangeEvents();

            // when modfiying a remitter on transaction details screen
            if (this.modalType === 'basic') {
                this.setRemitterId();
                options.model = this.getInitialAccount();
                options.account = this.account;
            }

            if (this.rid) {
                options.rid = this.rid;
            }
            options.modalType = this.modalType;

            this.accounts = new AccountInfoView(options);
            this.listenTo(this.accounts, 'deletingAllAccounts', this.showDeleteAlert);

            this.accountInfoRegion.show(this.accounts);
        } else if (this.modalType === 'basic') {
            this.loadRequiredData();
        } else {
            this.setHasLoadedRequiredData(true);
            this.render();
        }
    },

    showDeleteAlert(accountAlert) {
        this.accountInfoRegion.show(accountAlert);
    },

    getInitialAccount() {
        return new AccountModel({
            ACCOUNTNUMBER: this.account.accountNumber,
            ABA: this.account.aba,
        });
    },

    setChangeEvents() {
        const self = this;
        this.model.on('change:type', (e) => {
            if (e.attributes.type === 'new') {
                self.model.validators.REMITTER_ID = null;
                self.model.validators.REMITTER_NAME = {
                    description: 'Remitter Name',
                    exists: true,
                };
            } else {
                self.model.validators.REMITTER_NAME = null;
                self.model.validators.REMITTER_ID = {
                    description: 'Remitter Name',
                    exists: true,
                };
            }
        });
        this.ui.$existing.comboBox().on('change', () => {
            self.ui.$rid.prop('checked', true);
            self.model.set('type', 'existing');
        });
        this.model.on('change:REMITTER_NAME', () => {
            self.ui.$name.prop('checked', true);
            self.model.set('type', 'new');
        });
    },

    renderAlert(confirmResponse, message) {
        let localMessage = message;
        let confirms;
        let model;
        let alertFunc;
        let options;
        let obj;
        if (!localMessage) {
            model = confirmResponse;
            obj = {
                confirms: model.error.confirms,
            };
            [localMessage] = model.error.message;
            confirms = new Confirms(obj);
            options = {
                details: confirms,
                canDismiss: true,
            };
            alertFunc = alert.danger;
        } else {
            obj = {
                confirms: confirmResponse ? confirmResponse.confirms : null,
            };
            confirms = new Confirms(obj);
            options = {
                details: confirmResponse
                && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                    ? confirms : null,
                canDismiss: true,
            };
            alertFunc = alert.success;
        }

        return alertFunc(localMessage, options);
    },

    saveRemitter() {
        this.listenToOnce(this.model, 'sync', this.getRemitterId);
        const self = this;
        let remitterId;
        if (this.modalType === 'basic') {
            if (this.model.get('REMITTER_NAME') !== this.remitterName) {
                this.model.save(
                    {},
                    {
                        success(model, response) {
                            self.successResp.push(response);
                        },

                        error(response) {
                            self.trigger('remitter:close', self.renderAlert(response));
                        },
                    },
                );
            } else {
                this.trigger('remitter:close');
            }
        } else if (this.modalType === 'advanced') {
            this.model.save(
                {},
                {
                    success(model, response) {
                        self.successResp.push(response);
                    },

                    error(model) {
                        if (model.error.errorCode === 20003) {
                            [, remitterId] = model.error.confirms.confirmResults[0].messages;
                            // set the id to perform an update if this exists
                            self.model.set({
                                id: remitterId,
                                REMITTER_ID: remitterId,
                                type: 'existing',
                            });
                            self.submit();
                        } else {
                            self.trigger('remitter:close', self.renderAlert(model));
                        }
                    },
                },
            );
        }
    },

    getRemitterId(model, response) {
        const results = response.confirms.confirmResults[0].confirmData[0].item;
        const item = util.findWhere(
            results,
            {
                name: 'REMITTER_ID',
            },
        );
        this.REMITTER_ID = item.value;
        this.saveAccounts();
    },

    getAccountPromises() {
        const self = this;
        const promises = [];

        this.accountCollection.each((model, index, list) => {
            if (model.action === 'destroy' && !model.has('REMITTER_ACCT_ID')) {
                return;
            }

            if (model.action === 'save' && model.has('REMITTER_ACCT_ID')) {
                return;
            }

            const promise = new Promise((resolve, reject) => {
                const callbacks = {
                    success(successModel, response) {
                        self.successResp.push(response);
                        resolve(response);
                    },

                    error(response) {
                        self.errorResp.push(response);

                        if (index === list.length - 1) {
                            if (self.errorResp.length > 1) {
                                const confirmArr = [];
                                for (let x = 0; x < self.errorResp.length; x += 1) {
                                    confirmArr.push(self.errorResp[x].error
                                        .confirms.confirmResults[0]);
                                }

                                self.errorResp[0].error.confirms.confirmResults = confirmArr;
                            }

                            self.trigger('remitter:close', self.renderAlert(self.errorResp[0]));
                        } else {
                            self.cancel();
                        }

                        reject(response);
                    },
                };

                if (model.action === 'save') {
                    model.set({
                        REMITTER_ID: self.model.get('REMITTER_ID') || self.REMITTER_ID,
                    });
                    model.save({}, callbacks);
                } else {
                    model.destroy(callbacks);
                }
            });

            promises.push(promise);
        });

        return promises;
    },

    saveAccounts() {
        const self = this;
        const accountPromises = this.getAccountPromises();

        if (!util.isEmpty(accountPromises)) {
            Promise.all(accountPromises).then((results) => {
                const [firstAccount] = results;
                if (self.successResp.length > 1) {
                    const confirmArr = [];
                    for (let x = 0; x < self.successResp.length; x += 1) {
                        confirmArr.push(self.successResp[x].confirms.confirmResults[0]);
                    }
                    firstAccount.confirms.confirmResults = confirmArr;
                }

                if (self.errorResp.length > 0) {
                    self.trigger('remitter:close', self.renderAlert(firstAccount));
                } else {
                    self.hideUnwantedItems(firstAccount);

                    const message = locale.get('LBX.RemitterChangesSaved');
                    self.trigger('remitter:close', self.renderAlert(firstAccount, message));
                }
            });
        } else if (self.errorResp.length > 0) {
            // when only REMITTER_NAME has changed, handle alert for remitter
            this.trigger('remitter:close', this.renderAlert(this.errorResp[0]));
        } else {
            this.hideUnwantedItems(this.successResp[0]);

            const message = locale.get('LBX.RemitterChangesSaved');
            this.trigger('remitter:close', this.renderAlert(this.successResp[0], message));
        }
    },

    /**
     * @method submit
     * Performs an add / update call on the remitter and add/delete
     * calls on its' accounts when appropriate
     */
    submit() {
        this.successResp = [];
        this.errorResp = [];

        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }

        if (this.accounts.hasUnstoredAccountModel) {
            this.accounts.addAccount();
        }
        this.accountCollection = this.accounts.models;

        if (this.model.get('type') === 'existing') {
            this.saveAccounts();
        } else {
            this.saveRemitter();
        }
    },

    hideUnwantedItems(results) {
        const unWantedItems = ['REMITTER_ID', 'Created By', 'USERGROUP'];

        util.each(results.confirms.confirmResults, (confirm) => {
            util.each(confirm.confirmData, (data) => {
                const { item } = data;
                for (let x = 0; x < item.length; x += 1) {
                    if (unWantedItems.indexOf(item[x].name) > -1) {
                        item.splice(x, 1);
                    }
                }
            });
        });
    },

    cancel() {
        this.trigger('remitter:close');
    },

    loadRequiredData() {
        const self = this;
        this.existingRemitters.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
            },
        });
    },

    templateHelpers() {
        const self = this;
        return {
            existing() {
                let remitters = [];
                if (self.existingRemitters) {
                    remitters = self.existingRemitters.toJSON();
                }
                return remitters;
            },

            showAssignExistingRemitters() {
                return self.modalType === 'basic';
            },
        };
    },
});
