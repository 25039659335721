import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useLocale from '../../hooks/useLocale';
import Loader from '../Loader/Loader';
import LoadingPage from '../LoadingPage/LoadingPage';
import ScheduledExportModel from '../../app/scheduledExports/scheduledExportModel';
import constants from '../../app/administration/constants';
import CreateScheduledExport from '../CreateScheduledExport/CreateScheduledExport';
import scheduleUtil from '../../app/scheduledExports/util';
import systemConfig from '../../system/configuration';

const propTypes = {
    returnRoute: PropTypes.string.isRequired,
};

/**
 * When admin, get the user data so that the proper name can
 * be displayed, otherwise, pass the response through
 * @param {object} response
 */
const getUserData = (response) => {
    if (systemConfig.isAdmin()) {
        return scheduleUtil.getUser(response.userGroup, response.userId)
            .then((userData) => {
                const [user] = userData.emailEntitlements;
                return {
                    ...response,
                    userName: user.name,
                };
            });
    }
    return response;
};

const { getLocaleString } = useLocale();

const ModifyScheduledExport = ({
    returnRoute,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [initialData, setInitialData] = useState({});

    useEffect(() => {
        const tempModel = new ScheduledExportModel();
        tempModel.getModel().then(getUserData).then((response) => {
            setInitialData({
                userGroup: [{
                    id: response.userGroup,
                    name: response.userGroup,
                }],
                userId: [{
                    id: response.userId,
                    name: response.userName,
                }],
                name: response.name,
                reportType: [{
                    id: response.reportType,
                }],
                transmissionProfile: response.transmissionProfileIdentifier
                    ? [{
                        id: response.transmissionProfileIdentifier.toString(),
                    }] : [],
            });
            setIsLoading(false);
        });
    }, []); // eslint-disable-line

    return (
        <Loader isLoading={isLoading} LoadingComponent={LoadingPage}>
            <CreateScheduledExport
                returnRoute={returnRoute}
                headerText={getLocaleString('GIR.Modify.Scheduled.Export')}
                mode={constants.MODES.MODIFY}
                initialData={initialData}
            />
        </Loader>
    );
};

ModifyScheduledExport.propTypes = propTypes;

export default ModifyScheduledExport;
