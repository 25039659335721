import React, {
  useCallback, useEffect, useMemo, useReducer
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import {
  createFilterValueFunction, createParseFilterFunction, getFilterComponent, combineFilters
} from '@glu/data-components';
import { styles } from './Filter.styles';

const emptyFilter = {};

const ColumnFilter = ({
  classes,
  clearSelection,
  column,
  data,
  datePickerProps,
  displayName,
  filterHint,
  filterId,
  filterables,
  fixedList,
  getFilterData,
  headerName,
  htmlId,
  htmlInputProps,
  numberFilterTypes,
  records,
  setClearSelection,
  step,
  wrapperClassName
}) => {
  const { filters: dataFilters } = data;
  const existingFilter = filterables.find(
    (localColumn) => localColumn.headerName === displayName
  ) || emptyFilter;
  const [filters, updateFilters] = useReducer(combineFilters, dataFilters || []);
  const filterCurrentValue = filters.filter((filter) => filter.field === existingFilter.field);
  const createFilterValue = useCallback(createFilterValueFunction(existingFilter),
    [existingFilter]);
  const parseFilterValue = useCallback(createParseFilterFunction(existingFilter), [existingFilter]);
  const Component = useMemo(() => getFilterComponent(existingFilter.type), [existingFilter]);

  const handleChange = useCallback((name, filter) => {
    updateFilters({ filter, name });
    setClearSelection(false);
  }, [updateFilters]);

  useEffect(() => {
    if (clearSelection) {
      updateFilters({ name: column.colId });
    }
  }, [clearSelection, dataFilters]);

  useEffect(() => {
    getFilterData({
      errors: {},
      name: 'filters',
      value: filters
    });
  }, [filters]);

  return (
    <Component
      classes={classes}
      className={classes.filterWrapper}
      filters={filters}
      handleChange={handleChange}
      htmlId={htmlId || classes.filterWrapper}
      key={existingFilter?.field}
      records={records}
      value={{
        filters: filterCurrentValue
      }}
      hasFilter={!!filters.find((filter) => filter.field === existingFilter.field)}
      label={existingFilter.headerName}
      fieldId={existingFilter.field}
      onChange={handleChange}
      createFilterValue={createFilterValue}
      parseFilterValue={parseFilterValue}
      enumData={existingFilter.enumData}
      options={existingFilter.options}
      displayFormat={existingFilter.dateFormat}
      dark={false}
      datePickerProps={datePickerProps}
      filterHint={filterHint}
      filterId={filterId}
      fixedList={fixedList}
      headerName={headerName}
      htmlInputProps={htmlInputProps}
      numberFilterTypes={numberFilterTypes}
      step={step}
      wrapperClassName={wrapperClassName}
    />
  );
};

ColumnFilter.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  clearSelection: PropTypes.bool,
  column: PropTypes.shape({
    addEventListener: PropTypes.func, colId: PropTypes.string.isRequired, headerName: PropTypes.string.isRequired, isSortAscending: PropTypes.func.isRequired, isSortDescending: PropTypes.func.isRequired, pinned: PropTypes.oneOf(['left', 'right']), removeEventListener: PropTypes.func, userProvidedColDef: PropTypes.shape({}).isRequired
  }).isRequired,
  data: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({
      criteriaDisplay: PropTypes.string.isRequired, field: PropTypes.string.isRequired, filterData: PropTypes.shape({}).isRequired, id: PropTypes.string.isRequired, nameDisplay: PropTypes.string.isRequired, operator: PropTypes.oneOf(['AND', 'OR'])
    }))
  }).isRequired,
  datePickerProps: PropTypes.shape({}),
  displayName: PropTypes.string,
  filterHint: PropTypes.string,
  filterId: PropTypes.string,
  filterables: PropTypes.arrayOf(PropTypes.shape({
    dateFormat: PropTypes.string, field: PropTypes.string.isRequired, headerName: PropTypes.string.isRequired, type: PropTypes.oneOf(['date', 'enum', 'multiselect', 'number', 'string', 'typeahead', 'range'])
  })),
  fixedList: PropTypes.bool,
  getFilterData: PropTypes.func.isRequired,
  headerName: PropTypes.string,
  htmlId: PropTypes.string,
  htmlInputProps: PropTypes.shape({}),
  numberFilterTypes: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })
  ),
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setClearSelection: PropTypes.func.isRequired,
  step: PropTypes.number,
  wrapperClassName: PropTypes.string
};

ColumnFilter.defaultProps = {
  clearSelection: false,
  datePickerProps: undefined,
  displayName: '',
  filterHint: undefined,
  filterId: undefined,
  filterables: [],
  fixedList: undefined,
  headerName: undefined,
  htmlId: undefined,
  htmlInputProps: undefined,
  numberFilterTypes: undefined,
  step: undefined,
  wrapperClassName: undefined
};

export default withStyles(styles)(ColumnFilter);
