import Model from '@glu/core/src/model';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import client from 'common/notificationClient/client';
import Poller from 'common/notificationClient/voiceCallOneTimePassword/poller';
import { postData } from 'common/util/services';
import services from 'services';
import helper from 'system/mfa/helper';
import BaseMFAView from 'system/mfa/views/baseMFA';
import template from './voiceCallOTP.hbs';

const CONTACT_TYPE_VOICE = 'voice';

export default BaseMFAView.extend({
    template,

    ui: {
        $callButton: '[data-hook="getCallButton"]',
    },

    poller: new Poller(),

    initialize(options) {
        BaseMFAView.prototype.initialize.call(this, options);
        this.model = new Model();
        this.contacts = this.getContacts(options.challengeSettings);
        this.noContacts = !this.contacts.length;
        if (this.noContacts) {
            this.alertData = {
                canDismiss: true,
                type: 'warning',
                message: locale.get('mfa.error.nocontacts.message'),
            };
        } else {
            this.setDefaultContact(this.contacts);
            this.alertData = {
                canDismiss: false,
                type: 'info',
                message: locale.get('mfa.Challenge.Info.message'),
            };
            this.poller.serviceUrl = services.generateUrl(`mfaService/${helper.translateUrlForLogin('validate')}`);
            this.poller.set('postData', { challengedAction: options.challengedAction });
        }
    },

    /**
     * Get the contacts from the challenge settings model
     * @param {Model} challengeSettings - model containing challenge settings
     * @returns {Array} - Array of contacts
     */
    getContacts(challengeSettings) {
        let contacts = challengeSettings.get('contacts') || [];
        contacts = contacts.filter(contact => contact.type === CONTACT_TYPE_VOICE);
        return contacts;
    },

    /**
     * Search through the array to see if any of the contacts are set to default.
     * When a default is found, set it as the model's default. When default isn't found,
     * set the first item in the array as the default
     * @param {Array} contacts - Array of contacts
     * @return {Array} the same contact list
     */
    setDefaultContact(contacts) {
        const defaultContact = contacts.find(contact => contact.default === true);
        if (defaultContact) {
            this.model.set('contact', defaultContact.value);
            return contacts;
        }
        const [firstContact] = contacts;
        firstContact.default = true;
        this.model.set('contact', firstContact.value);
        return contacts;
    },

    /**
     * Call validate with the required data
     * @returns {Promise}
     */
    initiate() {
        this.ui.$callButton.attr('aria-busy', 'true');
        const data = {
            challengedAction: this.options.challengedAction,
            selection: this.model.get('contact'),
            selectionType: 'voice',
        };
        return postData(
            services.generateUrl(`mfaService/${helper.translateUrlForLogin('initiateOutOfBand')}`),
            data,
        ).then(this.receiveInitiateData.bind(this));
    },

    onRender() {
        if (this.alertData) {
            const alertView = alert[this.alertData.type](
                this.alertData.message,
                this.alertData,
            );
            this.alertRegion.show(alertView);
        }
    },

    /**
     * Update the tokenPin on the view, render and create a new poller
     * @param {Object} response - response object
     */
    receiveInitiateData(response) {
        this.tokenPin = response.tokenPin;
        this.alertData = {
            ...this.alertData,
            type: 'positive',
            message: locale.get('mfa.authify.inprogress'),
        };
        this.render();
        this.setupPoller();
    },

    /**
     * Analye the validate response and close the modal if complete,
     * show errors if any, and handle other cases
     * @param {Object} response -response object
     */
    analyzeValidationResponse(response) {
        const { result, errorCode, message } = response.respHeader;
        if (result) {
            this.cleanUpPoller();
            this.completion();
            return;
        }

        let type = 'positive';
        if (errorCode !== 7000) {
            this.tokenPin = undefined;
            this.cleanUpPoller();
            type = 'negative';
        }

        this.alertData = {
            canDismiss: false,
            type,
            message: message.join(' '),
        };
        this.render();
    },

    /**
     * Disable the poller and clean up listeners
     */
    cleanUpPoller() {
        this.poller.disable();
        client.unsubscribe('voiceCallOTP:validate');
        this.stopListening(client, 'voiceCallOTP:validate');
    },

    /**
     * Clean up the poller and then call cancel of the BaseMFAView prototype
     */
    cancel() {
        if (this.poller) {
            this.cleanUpPoller();
        }
        BaseMFAView.prototype.cancel.call(this);
    },

    /**
     * Create a new poller for validate, subscribe to the client event, and
     * and a listener for that event
     */
    setupPoller() {
        client.subscribeTo('voiceCallOTP:validate', true, this);
        // set up the listener for the poller updates
        this.listenTo(client, 'voiceCallOTP:validate', this.analyzeValidationResponse);
    },

    /**
     * Get contact description based on the value stored in the model for contact
     * @returns {String} - contact description
     */
    getContactDescription() {
        const contactObj = this.contacts.find(contact => contact.value === this.model.get('contact'));
        return contactObj ? contactObj.description : '';
    },

    templateHelpers() {
        return {
            tokenPin: this.tokenPin,
            contacts: this.contacts || [],
            contactDescription: this.getContactDescription(),
            hideContactMethodAndBlockConfirm: this.noContacts,
        };
    },
});
