import util from '@glu/core/src/util';
import AccountBalances from 'common/dynamicPages/collections/accountBalances';
import viewHelper from 'common/dynamicPages/api/viewHelper';

export default {
    getAccountNumber(productCode, item, typeCode) {
        let accountObject;
        if (productCode === 'RTGS') {
            if (typeCode && this.isFedTax(typeCode)) {
                return this.getDebitAccount(accountObject, item);
            }
            return item.name;
        }
        if (productCode === 'USACH') {
            if (typeCode && this.isCanadianEFT(typeCode)) {
                return item.name;
            }

            accountObject = util.findWhere(
                item.mapDataList,
                {
                    toField: 'OFFSETACCOUNTNUM',
                },
            );
            if (accountObject) {
                return accountObject.value;
            }
        }
        return null;
    },

    /**
     * getDebitAccount
     * @param {object} accountObject - accountObject.
     * @param {object} item - list of account info.
     * return {object} debitAccount - The debit account value
     */
    getDebitAccount(accountObject, item) {
        let debitAccount = accountObject;
        debitAccount = util.findWhere(
            item.mapDataList,
            {
                toField: 'DEBIT_ACCOUNT_NUMBER',
            },
        );
        if (debitAccount) {
            return debitAccount.value;
        }
        return null;
    },

    isFedTax(typeCode) {
        if (!typeCode) {
            return false;
        }
        if (typeCode === 'FEDTAX') {
            return true;
        }
        return false;
    },

    isCanadianEFT(typeCode) {
        if (!typeCode) {
            return false;
        }
        if (typeCode === 'BDEFTCC' || typeCode === 'BDEFTCD' || typeCode === 'BDEFTCCD' || typeCode === 'BIEFTCC' || typeCode === 'BIEFTCD' || typeCode === 'BIEFTCCD') {
            return true;
        }
        return false;
    },

    /*
     * formView - view from the policy
     * id - id of select2
     * combo - array of combo values includes name, label and mapDataList
     * options - hashmap of named parameters required for the account balance
     * service request:
     * productCode, functionCode, typeCode and
     * bankCode and currency field names to retrieve from the mapDataList
     */
    getAccountBalances(formView, id, comboParam, options) {
        const combo = comboParam;
        const balanceRequests = [];
        let mapDataList;
        let i;
        let accountNumber;
        let bankCodeObject;
        let currencyCodeObject;
        let item;
        let currentText = null;
        let currentIndex = -1;
        const { productCode, typeCode } = options;
        const selector = options.balanceSelector || null;
        if (selector) {
            currentText = formView.$el.find(selector).text();
        } else if (formView.state === 'view') {
            currentText = formView.$el.find(`div[id=${id}] strong`).text();
        }
        if (!util.isEmpty(combo)) {
            for (i = 0; i < combo.length; i += 1) {
                item = combo[i];
                ({ mapDataList } = item);
                accountNumber = this.getAccountNumber(productCode, item, typeCode);
                bankCodeObject = util.findWhere(
                    mapDataList,
                    {
                        toField: options.bankCode,
                    },
                );
                currencyCodeObject = util.findWhere(
                    mapDataList,
                    {
                        toField: options.currency,
                    },
                );
                if (currencyCodeObject) {
                    combo[i].currency = currencyCodeObject.value;
                }
                if ((currentText && currentText === combo[i].label)
                    || (selector && combo[i].label.indexOf(currentText) !== -1)) {
                    currentIndex = i;
                }
                this.addBalanceRequest(
                    combo,
                    i,
                    balanceRequests,
                    bankCodeObject,
                    accountNumber,
                    currencyCodeObject,
                    options,
                );
            }

            const balanceOptions = {
                requestData: balanceRequests,
                combo,
            };
            this.fetchAccountBalances(
                formView,
                id,
                balanceOptions,
                balanceRequests,
                combo,
                currentIndex,
                selector,
                options,
            );
        }
    },

    addBalanceRequest(
        comboParam,
        currentIndex,
        balanceRequests,
        bankCodeObject,
        accountNumber,
        currencyCodeObject,
        options,
    ) {
        const combo = comboParam;
        if (!util.isEmpty(accountNumber)
            && !util.isEmpty(bankCodeObject)
            && !util.isEmpty(currencyCodeObject)) {
            balanceRequests.push({
                productCode: options.productCode,
                functionCode: options.functionCode,
                typeCode: options.typeCode,
                bankCode: bankCodeObject.value,
                accountNumber,
                currency: currencyCodeObject.value,
            });
        } else if (options.productCode === 'USACH' && util.isEmpty(accountNumber)) {
            combo[currentIndex].isMissingOffsetAcct = true;
        }
    },

    // TODO - This is *substantially similar to the above. Refactor?
    getSingleAccountBalance(formView, id, comboParam, options) {
        const combo = comboParam;
        const balanceRequests = [];
        let mapDataList;
        let i;
        let accountNumber;
        let bankCodeObject;
        let currencyCodeObject;
        let item;
        let currentText = null;
        let currentIndex = -1;
        const { productCode, typeCode } = options;
        const selector = options.balanceSelector || null;
        const comboxBoxselection = options.selection || null;
        const updateOrRead = (formView.state === 'view' || formView.state === 'modify');

        if (this.isCanadianEFT(typeCode)) {
            return;
        }
        if (selector) {
            currentText = formView.$el.find(selector).text();
        } else if (formView.state === 'view') {
            currentText = formView.$el.find(`div[id=${id}] strong`).text();
        } else if (formView.state === 'modify') {
            // NH-28039
            if (formView.$el.find(`div#${id}.read-only-group strong`).length > 0) {
                currentText = formView.$el.find(`div#${id}.read-only-group strong`).text();
            } else if (formView.$el.find(`#${id}`).select2('data')) {
                currentText = formView.$el.find(`#${id}`).select2('data').text;
            }
        } else if (comboxBoxselection) {
            currentText = comboxBoxselection.added.text;
        }

        if (!util.isEmpty(combo)) {
            for (i = 0; i < combo.length; i += 1) {
                item = combo[i];
                ({ mapDataList } = item);
                accountNumber = this.getAccountNumber(productCode, item, typeCode);
                bankCodeObject = util.findWhere(
                    mapDataList,
                    {
                        toField: options.bankCode,
                    },
                );
                currencyCodeObject = util.findWhere(
                    mapDataList,
                    {
                        toField: options.currency,
                    },
                );
                if (currencyCodeObject) {
                    combo[i].currency = currencyCodeObject.value;
                }
                if ((currentText && currentText === combo[i].label)
                    || (selector && combo[i].label.indexOf(currentText) !== -1)) {
                    currentIndex = i;
                    if (!util.isEmpty(accountNumber)
                        && !util.isEmpty(bankCodeObject)
                        && !util.isEmpty(currencyCodeObject)
                        && ((combo[i].isPending || updateOrRead))
                        && !combo[i].isLoaded) {
                        balanceRequests.push({
                            productCode: options.productCode,
                            functionCode: options.functionCode,
                            typeCode: options.typeCode,
                            bankCode: bankCodeObject.value,
                            accountNumber,
                            currency: currencyCodeObject.value,
                        });
                    } else if (options.productCode === 'USACH' && util.isEmpty(accountNumber)) {
                        combo[i].isMissingOffsetAcct = true;
                    }
                    break;
                }
            }

            const balanceOptions = {
                requestData: balanceRequests,
                combo,
                index: i,
            };
            this.fetchAccountBalances(
                formView,
                id,
                balanceOptions,
                balanceRequests,
                combo,
                currentIndex,
                selector,
                options,
            );
        }
    },

    fetchAccountBalances(
        formView,
        id,
        balanceOptions,
        balanceRequests,
        combo,
        currentIndex,
        selector,
        options,
    ) {
        let accountBalances;
        const openOnSuccess = options.openOnSuccess || false;

        // retrieve balances for the accounts.
        if (!util.isEmpty(balanceRequests)) {
            accountBalances = new AccountBalances(null, balanceOptions);
            accountBalances.fetch({
                success() {
                    let theCombo;
                    let currentVal;
                    let textElement;
                    let availBalance;
                    if (formView.state === 'view' && !selector) {
                        // for view
                        textElement = formView.$el.find(`div[id=${id}] strong`);
                        if (currentIndex >= 0) {
                            textElement.html(combo[currentIndex].label);
                        }
                    } else if (formView.state === 'modify' && id.indexOf('BENE') > -1) {
                        textElement = formView.$el.find(`div[id=${id}] strong`);
                        if (currentIndex >= 0) {
                            textElement.html(combo[currentIndex].label);
                        }
                    } else if (selector) {
                        textElement = formView.$el.find(selector);
                        if (currentIndex >= 0) {
                            availBalance = combo[currentIndex].label;
                            availBalance = availBalance.substring(availBalance.lastIndexOf(':') + 1);
                            textElement.html(` ${availBalance}`);
                        }
                    } else {
                        // for insert/modify
                        theCombo = formView.$el.find(`select[name=${id}]`);
                        currentVal = theCombo.val() || '';
                        theCombo.html(viewHelper.getOptionsForCombo(combo));
                        if (currentVal) {
                            theCombo.val(currentVal).trigger('change');
                        }

                        if (openOnSuccess) {
                            formView.$(`#${id}`).comboBox('open');
                        }
                    }
                },
            });
        }
    },
};
