import { navigateTo } from 'common/util/navigationUtils'; // eslint-disable-line
import store from './store';

export function getOptions(context, options) {
    return {
        context,
        // default grid action buttons are hidden
        hideButtons: true,
        // instead of executing context actions, grid handles callback
        enableRowContextButtonCallbacks: true,
        enableSavedViews: false,
        enableDateTimeSupport: false,
        additionalSearchFields: [],
        requestParameters: undefined,
        selector: 'none',
        filter: true,
        loadedCallback: () => {},
        viewId: undefined,
        dateFormat: 'DD/MM/YY',
        // If we need to extend or override some values
        ...options,
    };
}

const dataToPost = {
    startRow: 1,
    dataOnly: 0,
    searchFields: [],
    requestParameters: {
        item: [
            {
                name: 'productCode',
                value: '_ADMIN',
            }, {
                name: 'functionCode',
                value: 'MAINT',
            },
        ],
    },
};

export const modifyDataToPost = (itemsToAdd) => {
    const dataCopy = JSON.parse(JSON.stringify(dataToPost));
    dataCopy.requestParameters.item = [...dataCopy.requestParameters.item, ...itemsToAdd];
    return dataCopy;
};

export function gridRowAction(...theArgs) {
    const {
        viewUrl, editUrl, onSuccess, onDelete, onError, gridView, typeCode,
        onView, onEdit, onSuspend, onActivate,
    } = theArgs[0];
    const { action, model } = theArgs[1];
    switch (action) {
    case 'select':
        store.selectedRowModel = model;
        if (onView) {
            onView(model);
        } else {
            navigateTo(`${viewUrl}`);
        }
        break;
    case 'delete':
        if (typeCode === 'BACCT_TM' || typeCode === 'ACHCO_TM') {
            // This is needed to wrap the request body one more time
            model.hasChildren = true;
        }
        model.destroy({
            success(rowModel, response) {
                gridView.refreshGridData();
                const { result, messages } = response.confirms.confirmResults[0];
                if (result) {
                    onSuccess(response.message[0]);
                    onDelete();
                } else {
                    onError(messages[0]);
                }
            },
            error() {
                gridView.refreshGridData();
                onError('Error');
            },
        });
        break;
    case 'modify':
        store.selectedRowModel = model;
        if (onEdit) {
            onEdit(model);
        } else {
            navigateTo(`${editUrl}`);
        }
        break;
    case 'suspend':
        store.selectedRowModel = model;

        // MDF function implemented in dynamicPages/models/meta.js
        // This is PCM approach. Depends on `enableRowContextButtonCallbacks` flag.
        model.suspend({
            wait: true,
            success(rowModel, response) {
                const { result, messages } = response.confirms.confirmResults[0];
                gridView.refreshGridData();
                if (result) {
                    onSuccess(response.message[0]);
                    if (onSuspend) {
                        onSuspend();
                    }
                } else {
                    onError(messages[0]);
                }
            },
            error(error) {
                gridView.refreshGridData();
                const messageObj = JSON.parse(error.message);
                const { message } = messageObj;
                onError(message[0]);
            },
        });
        break;
    case 'activate':
        store.selectedRowModel = model;

        // MDF function implemented in dynamicPages/models/meta.js
        // This is PCM approach. Depends on `enableRowContextButtonCallbacks` flag.
        model.activate({
            success(rowModel, response) {
                const { result, messages } = response.confirms.confirmResults[0];
                gridView.refreshGridData();
                if (result) {
                    onSuccess(response.message[0]);
                    if (onActivate) {
                        onActivate();
                    }
                } else {
                    onError(messages[0]);
                }
            },
            // eslint-disable-next-line max-len
            // TODO - error function same as for model.suspend() - TODO refactor/extract/simplify/etc
            error(error) {
                gridView.refreshGridData();
                const messageObj = JSON.parse(error.message);
                const { message } = messageObj;
                onError(message[0]);
            },
        });
        break;
    default:
        return null;
    }
    return null;
}

export default {
    getOptions,
    modifyDataToPost,
    gridRowAction,
};
