import ItemView from '@glu/core/src/itemView';
import gridFilterViewTmpl from 'app/balanceAndTransaction/views/exportBTRData/gridFilterView.hbs';

export default ItemView.extend({
    template: gridFilterViewTmpl,

    SELECTION_MODE_ALL: 'all',
    SELECTION_MODE_SELECT: 'sel',

    ui: {
        $selectedItems: '[data-hook="getSelectedItems"]',
    },

    modelEvents: {
        'change:selection': 'updateSelectedItemsCount',
    },

    initialize(options) {
        const selection = options.selectedRows
            ? this.SELECTION_MODE_SELECT : this.SELECTION_MODE_ALL;
        this.model.set('selection', selection);
    },

    /**
     * Based on selection mode, return selected or total count
     * @returns {number} - number of selected item or total items
     */
    getSelectedItemsCount() {
        return this.model.get('selection') === this.SELECTION_MODE_ALL ?
            this.options.totalCount : this.options.selectedRows;
    },

    /**
     * Update the element with the selected count
     */
    updateSelectedItemsCount() {
        this.ui.$selectedItems.text(this.getSelectedItemsCount());
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            includeDetails: this.options.includeDetails,
            hasSelectedRows: this.options.hasSelectedRows,
            selectedItemsCount: this.getSelectedItemsCount(),
            hasRowSelector: this.options.hasRowSelector,
        };
    },
});
