import util from '@glu/core/src/util';
import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    // Identifies if there is only one type in a group
    singleType: true,

    /**
     * Return the template data entitlement attribute
     * @param {String} [product] - The product of template.
     * @return {string}
     */
    getEntityName(product) {
        const TEMPLATE_SUFFIX = 'Code';
        const productName = product === 'USACH' ? 'ACH' : product;
        return productName === '*' || util.isUndefined(productName)
            ? CONSTANTS.ENTITY_NAME.TEMPLATE + TEMPLATE_SUFFIX
            : productName + CONSTANTS.ENTITY_NAME.TEMPLATE + TEMPLATE_SUFFIX;
    },

    /**
     * Return the template data inquiry id for the service call
     * @return {string}
     */
    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.TEMPLATE;
    },

    /**
     * Return the template data inquiry id for current/future for the service call
     * @return {string}
     */
    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.TEMPLATE_CF;
    },

    /**
     * Return the template type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.TEMPLATE;
    },

    /**
     * Return the available template types to populate the grid
     * @return {Array}
     */
    getRecords() {
        // Override as empty.  Permissions not based on sections in model.
        return {};
    },

    /**
     * Override the method from checkGrid.
     * Determine if there are any entitlements for this tab.
     * For restricted templates the tab is displayed if there
     * are any payment permissions selected or outgoing
     * payment request in tab 2
     *  @return {Boolean}
     */
    shouldShow() {
        let hasOutboundRequests = false;
        const payRecs = gridDataAccess.paymentSectionRecords(
            this.model,
            CONSTANTS.RESTRICTED_TEMPLATES_EXCLUSIONS,
        );
        const absSection = this.model.get('sections').get('ABS');

        // Outbound payment requests must be included but are not part of PAY.
        if (absSection) {
            hasOutboundRequests = util.chain(absSection.get('groups').at(0).get('types').models)
                .filter(type => type.id === 'REQOUT')
                .some(type => type.isEntitled())
                .value();
        }

        return gridDataAccess.getAllEnabledEntitlements(payRecs).length > 0
            || hasOutboundRequests;
    },
});
