var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"DetailsItem-label DetailsItem-label--name  col-7\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":6,"column":83},"end":{"line":6,"column":91}}}) : helper)))
    + "</span>\n                    <span class=\"DetailsItem-label DetailsItem-label--value col-5\">"
    + alias1(container.lambda(depth0, depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"DetailsItem-label DetailsItem-label--name col-7\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":12,"column":82},"end":{"line":12,"column":90}}}) : helper)))
    + "</span>\n                    <span class=\"DetailsItem-label DetailsItem-label--value col-5\">"
    + alias1(container.lambda(depth0, depth0))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"DetailsItem col-4\">\n                                <span class=\"DetailsItem-label DetailsItem-label--name col-md-12 col-lg-7\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":21,"column":107},"end":{"line":21,"column":115}}}) : helper)))
    + "</span>\n                                <span class=\"DetailsItem-label DetailsItem-label--value col-md-5 col-lg-3\">"
    + alias1(container.lambda(depth0, depth0))
    + "</span>\n                        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"AcctTransBalDetails\">\n    <div class=\"AcctTransBalDetails-header row\">\n        <div class=\"AcctTransBalDetails-asOfDates col-md-6 col-lg-4\">\n            <div class=\"BalanceAsOf DetailsItem row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"asOfTime") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"TransactionAsOf DetailsItem row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"asOfDate") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":14,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n    <ul class=\"AcctTransBalDetails-list row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"columns") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":24,"column":25}}})) != null ? stack1 : "")
    + "        </ul>\n</div>\n";
},"useData":true});