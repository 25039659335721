import NestedModel from '@glu/core/src/nestedModel';
import util from '@glu/core/src/util';
import Entitlements from '../../collection/user2/entitlements';

export default NestedModel.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            applicable: false,
            entitled: false,
            id: '',
            label: '',
            entitlements: new Entitlements(),
        };
    },

    getEntitlementsByFunctionCode(codes, isNotViewMode) {
        return new Entitlements(this.get('entitlements').filter(entitlement => util.contains(codes, entitlement.get('functionCode')) && (isNotViewMode || entitlement.isEntitled())));
    },

    isEntitled() {
        return this.get('entitlements').some(entitlement => entitlement.isEntitled());
    },

    parse(response) {
        response.entitlements = new Entitlements(response.entitlements);
        return response;
    },
});
