import ach from 'common/policies/payments/ach';
import achBatch from 'common/policies/payments/achBatch';
import achBatchOriginator from 'common/policies/payments/achBatchOriginator';
import achNFIChild from 'common/policies/payments/achNFIChild';
import addendaPolicy from 'common/policies/payments/addendaPolicy';
import billPay from 'common/policies/payments/billPay';
import beneAddressMandatoryToggle from 'common/policies/payments/beneAddressMandatoryToggle';
import cashConcentration from 'common/policies/payments/cashConcentration';
import childsupportChild from 'common/policies/payments/childsupportChild';
import corporateVendorPayment from 'common/policies/payments/corporateVendorPayment';
import draft from 'common/policies/payments/draft';
import drawdown from 'common/policies/payments/drawdown';
import eft from 'common/policies/payments/eft';
import eftBatch from 'common/policies/payments/eftBatch';
import fedtax from 'common/policies/payments/fedtax';
import fedwire from 'common/policies/payments/fedwire';
import fixupPaymentFromTemplateMapData from 'common/policies/payments/fixupPaymentFromTemplateMapData';
import paymentsIntl from 'common/policies/payments/paymentsIntl';
import internationalAchTransaction from 'common/policies/payments/internationalAchTransaction';
import internationalAchTransactionBatch from 'common/policies/payments/internationalAchTransactionBatch';
import itrnsfer from 'common/policies/payments/itrnsfer';
import multiBank from 'common/policies/payments/multiBank';
import nReceive from 'common/policies/payments/nReceive';
import payroll from 'common/policies/payments/payroll';
import rfedwire from 'common/policies/payments/rfedwire';
import rfpFooter from 'common/policies/payments/rfpFooter';
import rfpReceived from 'common/policies/payments/rfpReceived';
import rfpSubmit from 'common/policies/payments/rfpSubmit';
import rtgs from 'common/policies/payments/rtgs';
import paymentsRtgsIbanLookup from 'common/policies/payments/paymentsRtgsIbanLookup';
import rtgsSummary from 'common/policies/payments/rtgsSummary';
import rtpMultiAdd from 'common/policies/payments/rtpMultiAdd';
import rtpReceived from 'common/policies/payments/rtpReceived';
import saveAsTemplate from 'common/policies/payments/saveAsTemplate';
import taxPaymentChild from 'common/policies/payments/taxPaymentChild';
import importMap from 'common/policies/setup/importMap';
import clientCompany from 'common/policies/setup/clientCompany';
import clientSettings from 'common/policies/setup/clientSettings';
import showcaseMaintenancePolicy from 'common/policies/showcase/showcaseMaintenancePolicy';
import schedulePayment from 'common/policies/payments/schedulePayment';
import emailRecipients from 'common/policies/setup/emailRecipients';
import emailRecipGrpAssignment from 'common/policies/setup/emailRecipGrpAssignment';
import serviceRequest from 'common/policies/setup/serviceRequest';
import userDefinedCode from 'common/policies/setup/userDefinedCode';
import issueVoids from 'common/policies/checkManagement/issueVoids';
import cancelStop from 'common/policies/checkManagement/cancelStop';
import placeStop from 'common/policies/checkManagement/placeStop';
import viewDecision from 'common/policies/checkManagement/viewDecision';
import userMaintenance from 'common/policies/administration/userMaintenance';
import smbUserMaintenance from 'common/policies/administration/smbUserMaintenance';
import updateUserSettings from 'common/policies/administration/updateUserSettings';
import fimport from 'common/policies/fimport/fimport';
import bankIntegrationCodes from 'common/policies/systemMaintenance/bankIntegrationCodes';
import quickbooksTenant from 'common/policies/systemMaintenance/quickbooksTenant';
import bankCorrFees from 'common/policies/systemMaintenance/bankCorrFees';
import otherFees from 'common/policies/systemMaintenance/otherFees';
import currencyPairs from 'common/policies/systemMaintenance/currencyPairs';
import cnsIntlWireConfig from 'common/policies/systemMaintenance/cnsIntlWireConfig';
import bankFees from 'common/policies/systemMaintenance/bankFees';
import countriesWireSetup from 'common/policies/systemMaintenance/countriesWireSetup';
import segmentation from 'common/policies/administration/segmentation';
import registration from 'common/policies/checkFree/registration';
import rxpSubscriber from 'common/policies/checkFree/rxpSubscriber';
import rxpAccounts from 'common/policies/checkFree/rxpAccounts';
import smbUsers from 'common/policies/checkFree/smbUsers';
import alerts from 'common/policies/alerts/alerts';
import intermediaryBankMapping from 'common/policies/administration/intermediaryBankMapping';
import correspondentBankMapping from 'common/policies/administration/correspondentBankMapping';
import advanceFilter from 'common/policies/alerts/advanceFilter';
import viewReport from 'common/policies/reporting/viewReport';
import rtpReceivedAdmin from 'common/policies/administration/rtpReceived';
import achAuthRules from 'common/policies/checkManagement/achAuthRules/achAuthRules';
import toaLocations from 'common/policies/administration/toaLocations';
import roleMaintenance from 'common/policies/administration/roleMaintenance';
// PCM BEFORE MERGE POLICES
import check from 'common/policies/payments/check';
import checkBatch from 'common/policies/payments/checkBatch';
import euro from 'common/policies/payments/paymentsEuro';
import transfer from 'common/policies/payments/paymentsTransfer';
import transferMany from 'common/policies/payments/transferMany';

const policies = {
    payments: {
        ach,
        achBatch,
        achBatchOriginator,
        achNFIChild,
        addendaPolicy,
        billPay,
        beneAddressMandatoryToggle,
        cashConcentration,
        childSupportChild: childsupportChild,
        corporateVendor: corporateVendorPayment,
        check,
        checkBatch,
        draft,
        drawdown,
        eft,
        eftBatch,
        euro,
        fedtax,
        fedwire,
        fixUp: fixupPaymentFromTemplateMapData,
        intl: paymentsIntl,
        intlAch: internationalAchTransaction,
        intlAchBatch: internationalAchTransactionBatch,
        itransfer: itrnsfer,
        multiBank,
        nReceive,
        payroll,
        rfedwire,
        rfpFooter,
        rfpReceived,
        rfpSubmit,
        rtgs,
        rtgsIbanLookup: paymentsRtgsIbanLookup,
        rtgsSummary,
        rtpMultiAdd,
        rtpReceived,
        saveAsTemplate,
        taxChild: taxPaymentChild,
        transfer,
        transferMany,
    },
};

// Keys are based off the serviceName provided by the context.
// /*/ is /payment/ or /template/
export default {
    '/*/crtran': [
        policies.payments.rtpMultiAdd,
        policies.payments.saveAsTemplate,
    ],

    '/*/billPay': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.billPay,
        policies.payments.rtgsSummary,
    ],

    '/*/fedwire': [
        policies.payments.fixUp,
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.fedwire,
        policies.payments.rtgsSummary,
    ],

    '/*/fedwire-child': [
        policies.payments.rfedwire,
    ],

    '/*/fedtax': [
        policies.payments.fedtax,
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.rtgsSummary,
    ],

    '/*/draft': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.draft,
        policies.payments.rtgsSummary,
    ],

    '/*/drawdown': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.drawdown,
        policies.payments.rtgsSummary,
    ],

    '/*/multiBankRtgs': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.rtgsIbanLookup,
        policies.payments.multiBank,
        policies.payments.rtgsSummary,
    ],

    '/*/Wire-International': [
        policies.payments.rtgs,
        policies.payments.intl,
        policies.payments.rtgsIbanLookup,
        policies.payments.rtgsSummary,
    ],

    '/*/nReceive': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.nReceive,
        policies.payments.rtgsSummary,
    ],

    '/*/itrnsfer': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.itransfer,
        policies.payments.rtgsSummary,
    ],

    '/*/paymode': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.rtgsSummary,
    ],

    '/tableMaintenance/importMap': importMap,
    '/tableMaintenance/clientCompany': clientCompany,
    '/tableMaintenance/clientSetting': clientSettings,
    '/tableMaintenance/showcase': showcaseMaintenancePolicy,
    '/tableMaintenance/scheduledPayments': schedulePayment,
    '/tableMaintenance/emailRecipients': emailRecipients,
    '/tableMaintenance/emailRecipGrpAssignment': emailRecipGrpAssignment,
    '/serviceRequest/freeform': serviceRequest,
    '/tableMaintenance/userDefinedCode': userDefinedCode,
    '/cminst/issueVoids': issueVoids,
    '/cminst/cancelStop': cancelStop,
    '/cminst/placeStop': placeStop,
    '/adminCM/cm/stopCancels': placeStop,
    'cm/listView/positivePayDecision': viewDecision,
    'cm/listView/reversePositivePayDecision': viewDecision,
    '/userMaintenance/user': userMaintenance,
    '/userMaintenance/listView/user': userMaintenance,
    '/userMaintenance/user/updateSetting': updateUserSettings,
    '/roleMaintenance/role': roleMaintenance,
    '/users/userCentric/smb': smbUserMaintenance,

    '/batch/InternationalACHTransaction-NACHA': [
        policies.payments.achBatch,
        policies.payments.intlAchBatch,
        policies.payments.achBatchOriginator,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/InternationalACHTransaction-NACHA': [
        policies.payments.achBatch,
        policies.payments.achBatchOriginator,
        policies.payments.addendaPolicy,
    ],

    '/batch/InternationalACHTransaction-NACHA-child': [
        policies.payments.ach,
        policies.payments.intlAch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/InternationalACHTransaction-NACHA-child': [
        policies.payments.ach,
        policies.payments.intlAch,
        policies.payments.addendaPolicy,
    ],

    '/batch/InternetInitiatedCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/InternetInitiatedCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/Payroll-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/Payroll-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/InternetInitiatedCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/InternetInitiatedCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/TelephoneInitiatedCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/TelephoneInitiatedCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/TaxPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/TaxPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/CashConcentrationDisbursement-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CashConcentrationDisbursement-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/ConsumerCollectionsPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ConsumerCollectionsPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/CashDisbursement-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CashDisbursement-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/CorporateCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CorporateCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/ConsumerPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ConsumerPayments-NACHA': [
        policies.payments.achBatch,
    ],

    '/batch/ConsumerCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ConsumerCollections-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/ChildSupportPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ChildSupportPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/CorporateTradeExchange-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CorporateTradeExchange-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/CorporateVendorPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CorporateVendorPayments-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/DestroyedCheck-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/DestroyedCheck-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/CashConcentration-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CashConcentration-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/Payroll-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
        policies.payments.payroll,
    ],

    // PCM-845, PCM-846, PCM-1103, PCM-983, PCM-984, PCM-985
    '/batch/bapchk': [
        policies.payments.checkBatch,
    ],

    '/batch/bgpchk': [
        policies.payments.checkBatch,
    ],

    '/batch/bprchk': [
        policies.payments.checkBatch,
    ],

    '/batch/bprchk-child': [
        policies.payments.check,
    ],

    '/batch/bapchk-child': [
        policies.payments.check,
    ],

    '/batch/bgpchk-child': [
        policies.payments.check,
    ],
    // End of PCM piece

    '/batchTemplate/Payroll-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
        policies.payments.payroll,
    ],

    '/batch/TelephoneInitiatedCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/TelephoneInitiatedCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/TaxPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.taxChild,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/TaxPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.taxChild,
        policies.payments.addendaPolicy,
    ],

    '/batch/CashConcentrationDisbursement-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CashConcentrationDisbursement-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/ConsumerCollectionsPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ConsumerCollectionsPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/CashDisbursement-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CashDisbursement-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/CorporateCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CorporateCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/ConsumerPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ConsumerPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/ConsumerCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ConsumerCollections-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/ChildSupportPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.childSupportChild,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/ChildSupportPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.childSupportChild,
        policies.payments.addendaPolicy,
    ],

    '/batch/CorporateTradeExchange-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CorporateTradeExchange-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/CorporateVendorPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.corporateVendor,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CorporateVendorPayments-NACHA-child': [
        policies.payments.ach,
        policies.payments.corporateVendor,
        policies.payments.addendaPolicy,
    ],

    '/batch/DestroyedCheck-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/DestroyedCheck-NACHA-child': [
        policies.payments.ach,
        policies.payments.addendaPolicy,
    ],

    '/batch/CashConcentration-NACHA-child': [
        policies.payments.ach,
        policies.payments.cashConcentration,
        policies.payments.addendaPolicy,
    ],

    '/batchTemplate/CashConcentration-NACHA-child': [
        policies.payments.ach,
        policies.payments.cashConcentration,
        policies.payments.addendaPolicy,
    ],

    '/batch/NACHAFileImport-NACHA': [
        policies.payments.achBatch,
        policies.payments.achBatchOriginator,
        policies.payments.addendaPolicy,
    ],

    '/batch/NACHAFileImport-NACHA-child': [
        policies.payments.ach,
        policies.payments.achNFIChild,
        policies.payments.addendaPolicy,
    ],

    '/batch/passthru-NACHA': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/passthru-NACHA-child': [
        policies.payments.ach,
        policies.payments.achNFIChild,
        policies.payments.addendaPolicy,
    ],

    '/batch/passthru-CAEFT': [
        policies.payments.achBatch,
        policies.payments.addendaPolicy,
    ],

    '/batch/passthru-CAEFT-child': [
        policies.payments.ach,
        policies.payments.achNFIChild,
        policies.payments.addendaPolicy,
    ],

    '/batch/eftPreAuthorizedDebit-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batch/eftPreAuthorizedDebit-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batch/eftDirectDeposit-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batch/eftDirectDeposit-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batch/eftDirectDepositPreAuthDebit-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batch/eftDirectDepositPreAuthDebit-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batch/eftPreAuthorizedDebitImport-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batch/eftPreAuthorizedDebitImport-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batch/eftDirectDepositImport-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batch/eftDirectDepositImport-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batch/eftDirectDepositPreAuthDebitImport-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batch/eftDirectDepositPreAuthDebitImport-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batchTemplate/eftPreAuthorizedDebit-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batchTemplate/eftPreAuthorizedDebit-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batchTemplate/eftDirectDeposit-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batchTemplate/eftDirectDeposit-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/batchTemplate/eftDirectDepositPreAuthDebit-CAEFT': [
        policies.payments.achBatch,
        policies.payments.eftBatch,
    ],

    '/batchTemplate/eftDirectDepositPreAuthDebit-CAEFT-child': [
        policies.payments.ach,
        policies.payments.eft,
    ],

    '/fimport/import': fimport,
    '/modifyvfimport/import': fimport,
    '/checkfimport/import': fimport,
    '/systemMaintenance/BankIntegrationCodes': bankIntegrationCodes,
    '/systemMaintenance/quickbooksTenant': quickbooksTenant,
    '/systemMaintenance/BankCorrFees': bankCorrFees,
    '/systemMaintenance/CurrencyPairs': currencyPairs,
    '/systemMaintenance/OthrFees': otherFees,
    '/systemMaintenance/CnsIntlWireConfig': cnsIntlWireConfig,
    '/systemMaintenance/BankFees': bankFees,
    '/systemMaintenance/CountriesWireSetup': countriesWireSetup,
    '/segmentation': segmentation,
    '/checkFree': registration,
    '/checkFreeSmallBusiness': registration,
    '/checkFreeRXPSubscriber': rxpSubscriber,
    '/checkFreeRXPAccounts': rxpAccounts,
    '/checkFreeSMBSubscriber': rxpSubscriber,
    '/checkFreeSMBAccounts': rxpAccounts,
    '/checkFreeSMBUsers': smbUsers,
    '/alerts/detailMaint': alerts,
    '/intermediaryBankMapping': intermediaryBankMapping,
    '/correspondentBankMapping': correspondentBankMapping,
    '/advanceFilter': advanceFilter,
    advanceFilter: viewReport,

    'adminPayment/listView/payments': [
        policies.payments.rtgs,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.intl,
        policies.payments.fedwire,
        policies.payments.rtgsSummary,
        policies.payments.rtgsIbanLookup,
        policies.payments.multiBank,
        policies.payments.rtpMultiAdd,
    ],

    'adminPayment/listView/payments-NACHA': [
        policies.payments.achBatch,
        policies.payments.achBatchOriginator,
        policies.payments.achNFIChild,
        policies.payments.addendaPolicy,
    ],

    'adminPayment/listView/payments-NACHA-child': [
        policies.payments.ach,
        policies.payments.achNFIChild,
        policies.payments.addendaPolicy,
        policies.payments.payroll,
    ],

    'adminTemplate/listView/templates': [
        policies.payments.multiBank,
        policies.payments.rtgs,
        policies.payments.intl,
        policies.payments.beneAddressMandatoryToggle,
        policies.payments.rtgsSummary,
        policies.payments.rtgsIbanLookup,
    ],

    'adminTemplate/listView/templates-NACHA': [
        policies.payments.achBatch,
        policies.payments.achBatchOriginator,
        policies.payments.achNFIChild,
        policies.payments.addendaPolicy,
    ],

    'adminTemplate/listView/templates-NACHA-child': [
        policies.payments.ach,
        policies.payments.achNFIChild,
        policies.payments.addendaPolicy,
        policies.payments.payroll,
    ],

    '/requestForPayment/reqin': [
        policies.payments.rfpFooter,
    ],

    '/requestForPayment/outgoingPaymentRequests': [
        policies.payments.rfpSubmit,
        policies.payments.saveAsTemplate,
    ],

    '/requestTemplate/outgoingPaymentRequestTemplates': [
        policies.payments.rfpSubmit,
    ],

    '/requestForPayment/requestReceived': [
        policies.payments.rfpReceived,
    ],

    'payment/rtp/receivedPmt': [
        policies.payments.rtpReceived,
    ],

    'adminPayment/rtp/incoming': rtpReceivedAdmin,

    'payment/ACHAuthRules': achAuthRules,

    timeOpenAccountLocations: toaLocations,
};
