// built based on http://www.daterangepicker.com/
import { ItemView, date, locale } from '@glu/core';
import GluIcons from '@glu/icons';
import DateRangePicker from 'daterangepicker';
import template from './datePicker2.hbs';
import './localeStrings';

const gluIconsInstance = new GluIcons({});
gluIconsInstance.registerIconHelper();

export default ItemView.extend({
  template, // svg-based
  className: 'datepicker',
  ui: {
    $icon: '.icon-calendar',
    $input: 'input',
    $errorMessage: '.date-error'
  },

  events: {
    'click @ui.$icon': 'focus'
  },

  initialize(options) {
    options = options || {};
    options.config = options.config || {};

    this.className = options.className; // doesn't work. TODO
    this.blockedDatesFormatted = options.blockedDatesFormatted || []; // DGB expects to have this. TODO
    this.required = !!options.required; // added after research old datePicker1. Needed for validation working properly.
    this.useLabel = !!options.labelText;
    this.errorMsgs = options.errorMsgs || {};
    this.eventTarget = options.eventTarget || options.config.eventTarget || this;

    // TODO - suggest as feature. If true, then append svg-based icon right aligned to input element.
    // this.useIcon = options.useIcon;

    this.modify$Element(options);

    // this.serverFormat = options.serverFormat || 'YYYY-MM-DD'; // TODO
    this.config = this.buildConfig(options);
  },

  modify$Element(options) {
    this.$el.css({ // this is to avoid visual re-rendering of input depending on below code.
      visibility: 'hidden'
    });

    this.useBuiltInTarget = false;
    if (options.$target) {
      this.$target = options.$target;
      if (!this.$target.hasClass('form-control')) { // to avoid situation if LOB/consumer's code doesn't provide class form-control
        this.$target.addClass('form-control');
      }
    } else {
      this.useBuiltInTarget = true;
    }

    if (options.config && !options.config.singleDatePicker) { // if date-range-picker
      this.$el.addClass('glu-range-date-picker');
    }
  },

  /**
   * Helper function to aggregate code related to mutation of options.config object - in fact DateRangePicker options.
   * @param options
   * @returns {*|{}}
   */
  buildConfig(options) {
    this.setPredefinedRanges(options);

    const config = options.config || {};
    config.locale = config.locale || {};
    config.locale.format = config.locale.format || 'DD MMM YYYY';

    // fallback code, in case of mistake
    if (this.options.startDate) {
      config.startDate = this.options.startDate;
      this.startDate = this.options.startDate;
    }

    if (this.options.endDate) {
      config.endDate = this.options.endDate;
      this.endDate = this.options.endDate; // must be momentJs object
    }

    if (this.options.parentEl) {
      config.parentEl = this.$el.get(0);
    }

    if (this.options.dateFormat) {
      config.locale.format = this.options.dateFormat;
    }

    // fallback code, in case of mistake

    return config;
  },

  /**
   * Helper function to set predefined ranges for DatePicker instance, if not provided.
   * Function created to meet the same legacy code requirements as was with old datepicker.
   * @param options - object passed by reference !!! and mutated by this function
   */
  setPredefinedRanges(options) {
    // Suggested to use boolean values of "ranges" config field to avoid mismatch with custom ranges as object
    if (options.config && !options.config.singleDatePicker && options.config.ranges === true) {
      options.config.ranges = {}; // change data type, so that it goes as valid object for bootstrap-daterangepicker
      options.config.ranges[locale.get('rangeToday')] = [date(), date()];
      options.config.ranges[locale.get('rangeYesterday')] = [date().subtract(1, 'days'), date().subtract(1, 'days')];
      options.config.ranges[locale.get('rangeLast7Days')] = [date().subtract(6, 'days'), date()];
      options.config.ranges[locale.get('rangeLast30Days')] = [date().subtract(29, 'days'), date()];
      options.config.ranges[locale.get('rangeLast60Days')] = [date().subtract(59, 'days'), date()];
      options.config.ranges[locale.get('rangeLast90Days')] = [date().subtract(89, 'days'), date()];
      options.config.ranges[locale.get('rangeThisMonth')] = [date().startOf('month'), date().endOf('month')];
      options.config.ranges[locale.get('rangeMonthToDate')] = [date().startOf('month'), date()];
      options.config.ranges[locale.get('rangeLastMonth')] = [date().subtract(1, 'month').startOf('month'), date().subtract(1, 'month').endOf('month')];
      options.config.ranges[locale.get('rangeQuarterToDate')] = [date().startOf('quarter'), date()];
      options.config.ranges[locale.get('rangeYearToDate')] = [date().startOf('year'), date()];
      options.config.ranges[locale.get('rangeDateAndTime')] = [date(), date()]; // TODO from DGB;
    } // but if ranges will be already valid object it will go "as it is" to pass custom ranges list to Bootstrap DateRangePicker
  },

  templateHelpers() {
    return {
      inputId: this.options.inputId || `date-input-${this.cid}`, // GLU-1449
      useLabel: this.useLabel,
      labelText: this.options.labelText || '', // GLU-1449
      useBuiltInTarget: this.useBuiltInTarget,
      placeholder: this.options.placeholder || '' // GLU-1449
    };
  },

  onClose() {
    if (this.dateRangePicker) {
      this.dateRangePicker.remove();
    }
  },

  onRender() {
    this.createControl();
    this.addWrapperClass();
  },

  addWrapperClass() {
    this.$el.addClass('datepicker');
    this.$el.css({
      visibility: 'visible'
    });
  },

  focus() {
    this.$('input').focus();
  },

  show() {
    return this.dateRangePicker.show();
  },

  hide() {
    return this.dateRangePicker.hide();
  },

  createControl() {
    if (this.useBuiltInTarget) {
      this.$target = this.$el.find('.date-input');
    }

    this.dateRangePicker = new DateRangePicker(this.$target.get(0), this.config, this.dateRangePickerCallback.bind(this));

    if (this.useLabel) {
      this.$target.data('daterangepicker', this.dateRangePicker).before(this.$el.find('label'));
    } else {
      this.$target.data('daterangepicker', this.dateRangePicker).prependTo(this.$el);
    }

    if (this.startDate) {
      // this.dateRangePicker.setStartDate(date(this.startDate, this.serverFormat)); // TODO - maybe
      this.dateRangePicker.setStartDate(this.startDate); // it's MomentJS object
    }

    if (this.endDate) {
      // this.dateRangePicker.setEndDate(date(this.endDate, this.serverFormat)); // TODO - maybe
      this.dateRangePicker.setEndDate(this.endDate); // it's MomentJS object
    }
  },

  /**
   * By old logic, this function mainly updated external model from datePicker2.
   * And besides, it updated ONLY startDate. Looks like datePicker2.js was written to support ONLY single date picker.
   * RANGE could have been added later on.
   * @param startDate
   * @param endDate
   */
  dateRangePickerCallback(startDate, endDate) {
    this.validateDateInput(startDate, endDate);
    this.showErrors(); // it will show if this.hasError is true.
    this.eventTarget.triggerMethod('datePicked', this.dateRangePicker, startDate, endDate);

    if (!this.hasError) {
      // TODO - need to research/test/etc
      // not sure why we use both: locale.format and serverFormat
      // this.startDate = date(startDate, this.config.locale.format).format(this.serverFormat);
      // this.endDate = date(endDate, this.config.locale.format).format(this.serverFormat);
      // TODO - need to research/test/etc

      this.startDate = startDate; // no need to change format, it's MomentJs => MomentJs
      this.endDate = endDate;

      // This us specific use case, when we provide autoUpdateInput flag as false, but we need update dates manually then. Kinda against what DateRangePicker does.
      // TODO discuss
      if (this.options.config && this.options.config.autoUpdateInput === false) {
        this.manualUpdateElement();
      }
    }
  },

  /**
   * EXPERIMENTAL
   * This function created because if we use autoUpdateInput = false, then DateRangePicker doesn't update UI element with selected date(s)
   * Having such function, gives us ability for proper usage options.placeholder and validation (if we really have it)
   */
  manualUpdateElement() {
    this.dateRangePicker.setStartDate(this.startDate);
    this.dateRangePicker.setEndDate(this.endDate);

    if (!this.dateRangePicker.singleDatePicker) {
      this.dateRangePicker.element.val(this.startDate.format(this.dateRangePicker.locale.format) + this.dateRangePicker.locale.separator + this.endDate.format(this.dateRangePicker.locale.format));
      this.dateRangePicker.element.trigger('change');
    } else {
      this.dateRangePicker.element.val(this.startDate.format(this.dateRangePicker.locale.format));
      this.dateRangePicker.element.trigger('change');
    }
  },

  /**
   * CODE COPIED from datePicker.js => parseDateRange() - partially. Modified a bit to meet datePicker2.js code.
   *
   * Validates the date input for this date picker. Ensures the date:
   * - is a valid date range,
   * - is present if it is required,
   * - does not have a start date that is earlier than the system limit, and
   * - the end date is not before the start date.
   */
  validateDateInput(newStartDate, newEndDate) {
    const period = this.period === undefined ? null : this.period;
    // period came from datepicker1, do we need for datePicker2? TODO discuss
    let startDate;
    let endDate;


    // no date provided at all, or we have less than the absolute minumum to form a parsable date
    // TODO: Not sure we really need this, because by the code of bootstrap-daterangepicker startDate and endDate always have value.
    if (this.required && (!newStartDate || !newEndDate)) {
      this.errMsg = this.errorMsgs.dateRequired || locale.get('dateRequired');
    }

    // TODO: Not sure we really need this, because by the code of bootstrap-daterangepicker startDate and endDate always have VALID value.
    if (!this.errMsg && (!newStartDate || !newEndDate)) {
      this.errMsg = this.errorMsgs.invalidDateFormat || locale.get('invalidDateFormat');
    } else if (!this.errMsg) {
      startDate = newStartDate;
      endDate = newEndDate;

      // Date Format Validation
      // TODO: Not sure we really need this, because by the code of bootstrap-daterangepicker startDate and endDate always have VALID value.
      if (!startDate.isValid() || (endDate && !endDate.isValid())) {
        this.errMsg = this.errorMsgs.invalidDateFormat || locale.get('invalidDateFormat');
      }

      // Non-Ranged Datepicker Validations
      if (this.config && this.config.singleDatePicker) {
        // Payment date specified is after the after allowed future payment date
        // TODO: in fact datePicker2 sets minDate as startDate if selected date is too far, so I'm not sure we need this validation at all
        // if (!this.errMsg && this.minDate && startDate.isBefore(this.minDate)) {
        //     this.errMsg = this.errorMsgs.tooFarPast || locale.get('tooFarPast');
        // }

        // Payment date specified is after the after allowed future payment date
        // TODO: in fact datePicker2 sets maxDate as endDate if selected date is too far, so I'm not sure we need this validation at all
        // if (!this.errMsg && this.maxDate && startDate.isAfter(this.maxDate)) {
        //     this.errMsg = this.errorMsgs.tooFarFuture || locale.get('tooFarFuture');
        // }

        // A blocked date was selected (after cut-off, holiday, weekend, etc)
        // TODO (decide if we need blockedDates. If so - copy another function from datePicker1)
        if (!this.errMsg && !this.validDate(startDate, this.blockedDatesFormatted)) {
          // After cutoff?
          // TODO, decide if we need allowToday
          if (/*! this.allowToday && */ startDate.isSame(date(), 'day')) {
            this.errMsg = this.errorMsgs.afterCutoff || locale.get('afterCutoff');
            // Other blocked date
          } else {
            this.errMsg = this.errorMsgs.blockedDate || locale.get('blockedDate');
          }
        }

        // Ranged Date Validations
      } else {
        // Invalid date combination (end date before start date)
        if (!this.errMsg && (endDate && endDate.isBefore(startDate))) {
          this.errMsg = this.errorMsgs.invalidDateRange || locale.get('invalidDateRange');
        }

        // End date beyond allowed range for special DAILY period
        if (!this.errMsg && endDate) {
          // TODO: considering we may not even have period, then this validation is redundancy
          if (endDate.diff(startDate, 'years', true) > 1 && period === 'DAILY') {
            this.errMsg = this.errorMsgs.dailyLimit || locale.get('dailyLimit');
          }
        }

        // Start date is before allowed range
        // if (!this.errMsg && this.minDate && startDate.isBefore(this.minDate)) {
        //     this.errMsg = this.errorMsgs.historyLimit || locale.get('historyLimit');
        // }

        // End date is after allowed range
        // if (!this.errMsg && endDate && this.maxDate && endDate.isAfter(this.maxDate)) {
        //     this.errMsg = this.errorMsgs.tooFarFuture || locale.get('tooFarFuture');
        // }
      }
    }

    this.hasError = !!this.errMsg;
  },

  /**
   * COPIED from glu/datePicker.js. Reworked and simplified to meet latest needs.
   *
   * Returns true if the specified date does not exist in the specified array of disabled date expressions.
   *
   * Disabled date expressions are first normalized to simplify comparisons.  The following expressions
   * are currently supported:
   *
   * Exact date: '2014-01-01'
   * Relative date string: '4y -3m +2w -1d'
   * Relative number of days: 5
   * Weekends: 'weekends'
   *
   * @param dateValue
   * @param disabledDates
   * @returns {boolean|*}
   */
  validDate(dateValue, disabledDates) {
    const dateMoment = date(dateValue);
    // datestring = dateMoment.format(this.dtPickerFormat.moment);
    const datestring = dateMoment.format(this.options.config.locale.format);


    // see if the date is included in the disabled list.
    if (disabledDates && disabledDates.indexOf(datestring) > -1) {
      return false;
    }

    // Disable dates before minDate and after maxDate
    if ((this.minDate && dateMoment.isBefore(this.minDate)) || (this.maxDate && dateMoment.isAfter(this.maxDate))) {
      return false;
    }

    // disable weekends if specified.
    if (disabledDates && disabledDates.indexOf('weekends') > -1) {
      // TODO - there is no such thing as "noWeekends' in bootstrap-daterangepicker
      // old code: return $.datepicker.noWeekends(dateMoment.toDate())[0];
    }

    return true;
  },

  /**
   * COPIED from glu/datePicker.js. Reworked and simplified to meet latest glu validation markup.
   * Function for removing errors and error classes
   */
  clearErrors() {
    // because this method may be invoked after view is closed and context of DOM elements will be missed
    if (this.isClosed) {
      return;
    }
    this.ui.$errorMessage.empty();
    this.$el.removeClass('has-error');
  },

  /**
   * COPIED from glu/datePicker.js. Reworked and simplified to meet latest glu validation markup.
   * Updates the UI of this date picker to indicate to the user if the current entered date has errors or not.
   * If there are no errors we ensure that all prior error indicators are removed from the UI.
   */
  showErrors() {
    this.clearErrors();

    if (this.hasError) {
      this.$el.addClass('has-error');
      this.ui.$errorMessage.text(this.errMsg ? this.errMsg : '');
    }
  }

});

