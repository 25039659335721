import locale from '@glu/locale';
import util from '@glu/core/src/util';
import { getFlexDropdownFormat } from 'common/util/inquiryQueryResults';
import LegacyBankReportBase from './legacyBankReportBase';
import template from './bankReport.hbs';
import scheduleUtil from '../util';

export default LegacyBankReportBase.extend({
    template,

    ui: {
        $profileNumMessages: '[data-hook="getProfileNumValidationMessages"]',
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            return;
        }
        LegacyBankReportBase.prototype.onRender.call(this);
        this.reportIdRegion.show(this.reportIdDropdown);
        // Show the configuration dropdown always when in modify mode
        if (this.isModify()) {
            this.profileNumberRegion.show(this.profileNumberDropdown);
            this.ui.$profileNumMessages.show();
        } else {
            this.ui.$profileNumMessages.hide();
        }
        if (scheduleUtil.shouldShowEmailRecipients()) {
            this.model.off('change:PROFILE_NUMBER', this.setupEmailRecipients);
            this.model.on('change:PROFILE_NUMBER', this.setupEmailRecipients.bind(this));
            this.setupEmailRecipients();
        }
    },

    /**
     * When model has a value for REPORT_ID, show the view
     */
    setupEmailRecipients() {
        this.showEmailRecipients(util.isEmpty(this.model.get('PROFILE_NUMBER')));
    },

    /**
     * Get entitled email recipients for bank reports
     * @param {string} searchValue
     */
    getEntitledEmailRecipients(searchValue) {
        const data = {
            searchValue,
            profileNum: this.model.get('PROFILE_NUMBER') || '',
            ...this.getEmailRecipientAdminData(),
        };
        return scheduleUtil.getEmailRecipients(
            'export/scheduledExport/getEntitledEmailsBankReport',
            data,
        );
    },

    /**
     * Update the model, fetch saved configuration and then show the view
     * @param {Array} selected - Array of selected objects from flex dropdown change event
     */
    handlReportIdChange(selected) {
        const [selection] = selected;
        this.model.set({
            PROFILE_NUMBER: null,
            REPORT: selection.name,
        });
        const id = this.updateModelWithSelection('REPORT_ID', selected);
        if (id !== null) {
            this.profileNumberRegion.show(this.loadingDropdown);
            this.getProfileData().then(() => {
                this.profileNumberRegion.show(this.profileNumberDropdown);
                this.ui.$profileNumMessages.show();
            });
        }
    },

    /**
     * Fetch all the data required for rendering the view for modify
     */
    getModifyData() {
        return this.model.getModel()
            .then(this.getCreateData.bind(this))
            .then(this.getProfileData.bind(this));
    },

    /**
     * Fetch all the data required for rendering the view for insert
     * @returns {Promise}
     */
    getCreateData() {
        return scheduleUtil.getBankReports(
            this.model.getIdFromDropdownValue('userGroup'),
            this.model.getIdFromDropdownValue('userId'),
        )
            .then(getFlexDropdownFormat)
            .then((flexData) => {
                this.reportIdDropdown = this.createDropdown(
                    {
                        data: flexData,
                        preSelectedIds: this.model.get('REPORT_ID') ? [this.model.get('REPORT_ID')] : [],
                        label: locale.get('GIR.Report'),
                    },
                    this.handlReportIdChange,
                );
            });
    },

    /**
     * Create a dropdown for the file formats and add a change listener
     * @returns {View}
     */
    createFileFormatDropdown() {
        return this.createDropdown(
            {
                data: [{
                    id: 'PDF',
                    name: 'PDF',
                }, {
                    id: 'TXT',
                    name: locale.get('legacy.format.text'),
                }, {
                    id: 'CSV',
                    name: 'CSV',
                }],
                preSelectedIds: this.model.get('FILE_FORMAT') ? [this.model.get('FILE_FORMAT')] : [],
                label: locale.get('GIR.File.Format'),
            },
            this.updateModelWithSelection.bind(this, 'FILE_FORMAT'),
        );
    },

    /**
     * Create profile number dropdown
     * @param {Object} data - array of data for the dropdown
     */
    createProfileNumDropdown(data) {
        this.profileNumberDropdown = this.createDropdown(
            {
                data,
                preSelectedIds: this.model.get('PROFILE_NUMBER') ? [this.model.get('PROFILE_NUMBER')] : [],
                label: locale.get('GIR.Saved.Report.Name'),
            },
            this.handleProfileNumChange,
        );
    },

    /**
     * Update the model with the selected data
     * @param {Array} selected - Array of selected objects from flex dropdown change event
     */
    handleProfileNumChange(selected) {
        const [selection] = selected;
        this.model.set({
            SAVED_REPORT_NAME: selection.name,
        });
        this.updateModelWithSelection('PROFILE_NUMBER', selected);
    },

    /**
     * Fetch saved bank report configurations, format the data
     * and then create a flex dropdown
     */
    getProfileData() {
        return scheduleUtil.getSavedBankReportConfigs(
            this.model.get('REPORT_ID'),
            this.model.getIdFromDropdownValue('userGroup'),
            this.model.getIdFromDropdownValue('userId'),
        )
            .then(getFlexDropdownFormat)
            .then(this.createProfileNumDropdown.bind(this));
    },

    /**
     * Get an array of model attributes
     * @returns {Array} Array of object with fieldName property
     */
    getSearchAttrs() {
        return ['REPORT_ID', 'REPORT', 'PROFILE_NUMBER', 'SAVED_REPORT_NAME', 'FILE_FORMAT'];
    },
});
