import Model from '@glu/core/src/model';
import { appBus } from '@glu/core';

const TrustedIframeWidgetInterfaceModel = Model.extend({
    initialize() {
        this.iframes = [];
        this.dataSources = [];
        this.listenTo(appBus, 'extChallenge:register', this.register);
    },

    register(widget) {
        const dataUrl = widget.get('dataUrl');
        const firstSliceUrl = dataUrl.indexOf('/', dataUrl.indexOf('//') + 2);
        this.trustedWidget = widget.has('trusted') ? widget.get('trusted') : false;
        this.dataSources.push(dataUrl.slice(0, firstSliceUrl));
    },

    parseReceivedMessage(e) {
        if (this.isTrustedIframe(e.origin)) {
            if (e.data.type) {
                switch (e.data.type) {
                case 'register':
                    this.iframes.push({
                        src: e.source,
                        targetOrigin: e.origin,
                    });
                    break;
                case 'message':
                    appBus.trigger(
                        'bottomline.portal.message',
                        {
                            key: `bottomline.external.${e.data.key}`,
                            message: e.data.message,
                        },
                    );
                    break;
                default:
                }
            }
        }
    },

    isTrustedIframe(origin) {
        for (let i = 0; i < this.dataSources.length; i += 1) {
            if (this.dataSources[i] === origin) {
                return true;
            }
        }
        return this.trustedWidget;
    },

    send(dataParam) {
        const data = dataParam;
        try {
            /*
             * copied from webseries documentation
             * https://confluence.us-bottomline.root.bottomline.com/display/WS/JavaScript+API+for+Portals
             */

            // removing leading forward slash that some contexts are adding
            if (data.title && data.title.charAt(0) === '/') {
                data.title = data.title.slice(1);
            }

            let foundDirty = false;
            for (let i = 0; i < this.iframes.length; i += 1) {
                if (this.iframes[i].src.closed) {
                    foundDirty = true;
                } else {
                    this.iframes[i].src.postMessage(data, this.iframes[i].targetOrigin);
                }
            }
            if (foundDirty) {
                this.cleanDirtyIframes();
            }
        } catch (err) {
            // don't do anything right now
        }
    },

    cleanDirtyIframes() {
        let foundDirty = true;
        while (foundDirty) {
            foundDirty = false;
            let index = -1;
            for (let i = 0; i < this.iframes.length; i += 1) {
                if (this.iframes[i].src.closed) {
                    foundDirty = true;
                    index = i;
                    break;
                }
            }
            if (index > -1) {
                this.iframes.splice(index, 1);
            }
        }
    },
});

export default new TrustedIframeWidgetInterfaceModel();
