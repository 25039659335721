import Collection from '@glu/core/src/collection';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import $ from 'jquery';

export default Collection.extend({
    filterVal: '',
    rowsPerPage: 10,
    startRow: 1,
    hasMorePages: false,

    initialize(opts) {
        if (opts) {
            this.type = opts.type;
        }
    },

    setFilter(page, term = '', accountFilter) {
        const localTerm = term;
        if (localTerm !== this.filterVal || page === 1) {
            this.startRow = 1;
        }
        this.filterVal = localTerm;
        this.rowsPerPage = localTerm.length > 2 ? 50 : 10;
        if (accountFilter) {
            this.accountFilter = accountFilter;
        }
    },

    sync(method, model, options = {}) {
        const localOptions = options;
        const dataOptions = localOptions.data || {};

        const data = {
            rowsPerPage: (dataOptions.rowsPerPage) ? dataOptions.rowsPerPage : this.rowsPerPage,
            startRow: (dataOptions.startRow) ? dataOptions.startRow : this.startRow,
            fieldName: 'DEBIT_ACCOUNT_NUMBER',
            typeCode: 'CRTRAN',
            productCode: 'RTP',
            functionCode: 'INST',
            depends: (dataOptions.depends) ? dataOptions.depends : [],
        };

        if (this.filterVal) {
            data.searchFields = [{
                fieldName: 'ACCOUNTNUM',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'CLIENTACCOUNTNAME',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'CURRENCYCODE',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }];
            data.searchOrOperator = true;
        }

        if (this.accountFilter) {
            data.depends.push({
                name: 'DEBIT_ACCOUNT_NUMBER',
                value: this.accountFilter,
            });
        }

        // bullet proof success & error functions
        if (typeof localOptions.success !== 'function') {
            localOptions.success = $.noop;
        }
        if (typeof localOptions.error !== 'function') {
            localOptions.error = $.noop;
        }
        http.post(
            services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION),
            data,
            (result) => {
                this.hasMorePages = (this.startRow + result.rows.length) <= result.totalRows;
                this.startRow += this.rowsPerPage;
                localOptions.success(result.rows);
            }, () => {
                localOptions.error();
            },
        );
    },

    parse(response) {
        return util.map(response, (rowItem) => {
            const obj = transform.pairsToHashUnescape(rowItem.columns, 'fieldName', 'fieldValue');
            obj.mapDataList = transform.pairsToHashUnescape(rowItem.mapDataList, 'toField', 'value');
            obj.id = obj.ACCOUNTNUM;

            obj.text = (!util.isEmpty(obj.ACCOUNTNAME_NUM_BANK))
                ? obj.ACCOUNTNAME_NUM_BANK
                : `${obj.CLIENTACCOUNTNAME} - ${obj.ACCOUNTNUM} - ${obj.BANKCODE} - ${obj.NAME}`;

            return obj;
        });
    },
});
