import util from '@glu/core/src/util';
import SuperView from './panelView';
import draftPaymentTmpl from './draftPayment.hbs';

const DraftPayment = SuperView.extend({
    template: draftPaymentTmpl,
    collapsed: true,
    className: 'draft-payment',

    templateHelpers() {
        const sup = SuperView.prototype.templateHelpers.call(this);

        return util.extend(
            sup,
            {
                isValidCountry: this.isValidCountry(),
                isDraftCompliant: this.model.isDraftCompliant(),
                currency: this.options.currency.toJSON(),
                countries: this.options.countries.toJSON(),
                fieldTypeData: this.fieldTypeData,
            },
        );
    },

    delegateEvents() {
        SuperView.prototype.delegateEvents.call(this);
        this.listenTo(this.options.countries, 'change add reset', this.render);
        this.listenTo(this.options.currency, 'change add reset', this.render);
        this.listenTo(this.model.contactModel, 'change:BENE_COUNTRY', this.render);
    },

    isValidCountry() {
        const contactCountry = this.model.contactModel.get('BENE_COUNTRY');
        if (!contactCountry) {
            return true;
        }
        const countries = this.options.countries.pluck('name');

        return countries.indexOf(contactCountry) > -1;
    },
});

export default DraftPayment;
