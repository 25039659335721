const createGridActionsMap = {
    archive: 'archive',
    assign: 'assign',
    cancel: 'cancel',
    cancelstop: 'cancelstop',
    clone: 'clone',
    comment: 'comment',
    content: 'content',
    copyfromuser: 'copyfromuser',
    copyalert: 'copyalert',
    copyfooter: 'copyfooter',
    copyinst: 'copyinst',
    'create profile': 'profile',
    createnotification: 'nTemplate',
    createtemplate: 'tNotification',
    decision: 'decision',
    deploy: 'deploy',
    disable: 'disable',
    dload: 'download',
    dloadcsv: 'downloadcsv',
    dloadtxt: 'downloadtxt',
    email: 'email',
    enable: 'enable',
    export: 'export',
    extendreq: 'extendreq',
    filter: 'filter',
    found: 'found',
    getrate: 'getrate',
    image: 'image',
    imageDetail: 'image',
    imageSearchDetail: 'imageSearch',
    lost: 'lost',
    match: 'match',
    misplace: 'misplace',
    modifyvfimport: 'modifyvfimport',
    pay: 'pay',
    payissue: 'payissue',
    padjust: 'padjust',
    payrule: 'payrule',
    photo: 'photo',
    qentry: 'qentry',
    remitter: 'manageRmt',
    replace: 'replace',
    resetpw: 'resetpassword',
    restore: 'restore',
    return: 'return',
    reverse: 'reverse',
    simulate: 'simulate',
    stop: 'stop',
    trade: 'trade',
    unassign: 'unassign',
    unlock: 'unlock',
    updset: 'updateprofilerestricted',
    view: 'viewrpt',
    viewsumt: 'select',
};


export default createGridActionsMap;
