import GluModel from '@glu/core/src/model';
import util from '@glu/core/src/util';

let Model = GluModel;

export default function () {
    Model = Model.extend({
        defaults: {
            PARENTTNUM: '',
            CHILD_TNUM: '',
            BENE_NAME: '',
            BENE_ACCOUNTTYPE: '',
            BENE_ACCOUNT_CURRENCY: '',
            BENE_BANK_ID: '',
            BENE_BANK_NAME: '',
            BENE_ACCOUNTNUMBER: '',
            BENE_REFERENCE: '',
            BENE_COUNTRY: '',
            BENE_BANK_STATE: '',
            canEdit: 'true',
            canDelete: 'false',
        },

        validators: {

        },

        isEmpty() {
            let flag = true;
            const self = this;
            util.each(this.defaults, (value, field) => {
                if (!util.isEmpty(self.get(field))) {
                    flag = false;
                }
            });
            return flag;
        },
    });
    return new Model();
}
