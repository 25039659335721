import { postData } from 'common/util/services';
import services from 'services';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';

const PaymentIntiatorGroupList = (searchValue) => {
    const payload = {
        inquiryRequest: {
            startRow: 1,
            searchCriteria: {
                searchType: '5',
                inquiryId: 11998,
                customFilters: [],
                searchFields: [{
                    fieldName: 'PIGID',
                    operator: 'CONTAINS',
                    fieldValue: [searchValue],
                    dataType: 'TEXT',
                }],
            },
            rowsPerPage: 250,
        },
        requestHeader: {
            requestId: '100',
        },
    };
    return postData(services.generateUrl('/inquiry/getRowsData'), payload).then(response => response.inquiryResponse.rows.map((rowItem) => {
        const columns = transform.pairsToHash(rowItem.columns, 'fieldName', 'fieldValue');
        return {
            id: columns.PIGID,
            nickname: columns.PIGIDNICKNAME,
            text: util.unescape(columns.PIGID),
        };
    }));
};
export default PaymentIntiatorGroupList;
