import services from 'services';
import { postData } from 'common/util/services';

export default {
    /**
     * Send user answers to backend for validation
     */
    validateAnswers(data) {
        return postData(services.forgottenPassword, data);
    },

    /**
     * Get challenge questions.
     */
    getChallengeQuestions(data) {
        return postData(services.challengeQuestions, data);
    },
};
