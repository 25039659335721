import '../themeDefaults';

export default ({
  typography
}) => ({
  root: {
    fontFamily: typography.fontFamily,
    height: '100%',
    overflow: 'scroll'
  }
});
