import ListView from 'common/dynamicPages/views/workflow/list';
import dialog from '@glu/dialog';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import util from '@glu/core/src/util';
import workspaceHelper from 'common/workspaces/api/helper';
import http from '@glu/core/src/http';
import services from 'services';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import ReasonCellView from './exceptionReport/reasonCellView';
import template from './recurListView.hbs';

const PaymentExceptionReport = ListView.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: '/tableMaintenance/recPayExceptions',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'RECURPAY_VIEW',
            returnRoute: 'REPORTING/viewRecurPayExceptions',
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.markExceptionsAsAccess();
    },

    loadViewRequirements() {
        const promise = ListView.prototype.loadViewRequirements.call(this);
        const self = this;
        return promise.then(() => {
            self.listenTo(self.gridView.wrapper.columns, 'sync', self.handleColumnSync);
        });
    },

    handleColumnSync(columns) {
        const model = columns?.findWhere({
            field: 'REASON',
        });
        model?.set('type', ReasonCellView);
    },

    markExceptionsAsAccess() {
        const markReadFileImp = services.generateUrl('/notifications/accessRecPayExceptions');
        const postData = {};
        http.post(markReadFileImp, postData);
    },

    export() {
        this.buildExportModel('CSV');
        this.doExport();
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: 'function.listview.reports',
        });
        dialog.custom(printModal);
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = '29500';
    },
});

let list = PaymentExceptionReport;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'PAYMENT_EXCEPTION_REPORT',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
