import ItemView from '@glu/core/src/itemView';
import constants from 'app/administration/constants';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import actionCheckboxesTmpl from './actionCheckboxes.hbs';

export default ItemView.extend({
    template: actionCheckboxesTmpl,
    className: 'entitlement-checkboxes',

    ui: {
        input: 'input',
    },

    events: {
        'change @ui.input': 'checkOption',
    },

    initialize(options) {
        this.actionEntitlements = options.actionEntitlements;
        this.mode = options.mode;
    },

    checkOption(e) {
        const action = e.currentTarget.name;
        this.model.set(
            action,
            e.currentTarget.checked,
            {
                silent: true,
            },
        );

        if (action === 'approveAction') {
            this.model.trigger('change:approveAction');
        }
    },

    templateHelpers() {
        const readOnly = this.mode === constants.MODES.VIEW;

        /*
         * Need to disable if value not true/false,
         * which comes from server if CGBO and not entitled.
         */
        const manageAction = this.model.get('manageAction');
        const approveAction = this.model.get('approveAction');
        const repairAction = this.model.get('repairAction');
        const modifyAction = this.model.get('modifyAction');
        const disableManageCGBO = util.isNullOrUndefined(manageAction)
            || !util.isBoolean(manageAction);
        const disableApproveCGBO = util.isNullOrUndefined(approveAction)
            || !util.isBoolean(approveAction);
        const disableRepairCGBO = util.isNullOrUndefined(repairAction)
            || !util.isBoolean(repairAction);
        const disableModifyCGBO = util.isNullOrUndefined(modifyAction)
            || !util.isBoolean(modifyAction);

        return {
            cid: this.cid,
            manageLabel: locale.get('uce.entitlement.manage'),
            approveLabel: locale.get('uce.entitlement.approve'),
            repairLabel: locale.get('uce.entitlement.repair'),
            modifyLabel: locale.get('uce.entitlement.modify'),
            disableManage: this.actionEntitlements.manage.entitled && !readOnly && !disableManageCGBO ? '' : 'disabled',
            disableApprove: this.actionEntitlements.approve.entitled && !readOnly && !disableApproveCGBO ? '' : 'disabled',
            disableRepair: this.actionEntitlements.repair.entitled && !readOnly && !disableRepairCGBO ? '' : 'disabled',
            disableModify: this.actionEntitlements.modify.entitled && !readOnly && !disableModifyCGBO ? '' : 'disabled',
        };
    },
});
