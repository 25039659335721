import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';

import styles from './NoContent.styles';

const NoContent = ({ classes, primaryColumnLength }) => (
  <tr>
    <td colSpan={primaryColumnLength + 1} className={classes.root}>
      {locale.get('noContentFound')}
    </td>
  </tr>
);

NoContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  primaryColumnLength: PropTypes.number.isRequired
};

export default withStyles(styles)(NoContent);
