import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import ItemView from '@glu/core/src/itemView';
import cashFlowChangeLayoutTmpl from './cashFlowChangeLayout.hbs';
import deleteTmpl from './controls/verifyDelete.hbs';
import CashFlowEntryLayout from './cashFlowEntryLayout';
import itemService from '../service/item';
import CashFlowScenarioPickerView from './cashFlowScenarioPickerView';
import CashFlowImportLayout from './cashFlowImportLayout';

const ConfirmDeleteView = ItemView.extend({
    template: deleteTmpl,

    ui: {
        recurringCheckbox: 'input[data-hook="recurring-checkbox"]',
    },

    events: {
        change: 'handleCheck',
    },

    handleCheck() {
        this.model.set('DELETEFUTUREITEMS', this.ui.recurringCheckbox.is(':checked'));
    },

    templateHelpers() {
        const scenario = this.collection.get(this.model.get('CASHFLOWSCENARIOID'));
        return {
            scenarioName: !util.isUndefined(scenario) ? scenario.get('name') : '',
            inBase: util.isNull(this.model.get('CASHFLOWSCENARIO')) || this.model.get('CASHFLOWSCENARIO') === '',
            isRecurring: this.model.get('RECURRING') === 'RECURRING',
        };
    },

    getButtons() {
        const buttons = [{
            text: 'Delete',
            className: 'btn btn-primary btn-cashflow-save',
        }];

        return buttons;
    },
});

const CashFlowChangeLayout = Layout.extend({
    template: cashFlowChangeLayoutTmpl,
    className: 'modal cashflow-entry-editor',

    events: {
        'click .btn-cashflow-save': 'saveAction',
        'click .btn-cashflow-save-to-a-scenario': 'pickScenario',
    },

    regions: {
        main: '[data-hook="region-main"]',
    },

    initialize(options) {
        this.accountCollection = options.accountCollection;
        this.scenarioCollection = options.scenarioCollection;
        this.transactionCollection = options.transactionCollection;
        this.gridTransactionCollection = options.gridTransactionCollection;
        this.inflowCategories = options.inflowCategories;
        this.outflowCategories = options.outflowCategories;
        this.remoteGridView = options.remoteGridView;

        // TODO whitelist of acceptable actions?
        this.action = options.action;

        this.workingModel = util.contains(['create'], this.action) ? new Model() : options.model.clone();

        this.main.on('show', util.bind(this.regionShown, this));
    },

    templateHelpers() {
        let title = '';
        switch (this.action) {
        case 'create':
            title = locale.get('cashflow.create.entry');
            break;
        case 'update':
            title = locale.get('cashflow.edit', this.model.get('DESCRIPTION'));
            break;
        case 'delete':
            title = locale.get('cashflow.delete', this.model.get('DESCRIPTION'));
            break;
        case 'import':
            title = locale.get('cashflow.import.entries');
            break;
        default:
        }

        return {
            title,
            isEditMode: true,
            isBaseTransaction: true,
        };
    },

    onRender() {
        let view;

        switch (this.action) {
        case 'delete':
            view = new ConfirmDeleteView({
                model: this.workingModel,
                collection: this.scenarioCollection,
            });

            break;

        case 'import':
            view = new CashFlowImportLayout({
                action: this.action,
                model: this.workingModel,
                accountList: this.accountCollection,
            });
            break;

        default:
            view = new CashFlowEntryLayout({
                action: this.action,
                model: this.workingModel,
                accountList: this.accountCollection,
                inflowCategories: this.inflowCategories,
                outflowCategories: this.outflowCategories,
                scenarios: this.scenarioCollection,
                transactions: this.transactionCollection,
                gridTransactions: this.gridTransactionCollection,
            });
        }

        this.main.show(view);
    },

    pickScenario() {
        // TODO save the diff of the user's changes to the selected scenario

        /*
         * attributes for diff:
         * type (inFlow/outFlow)
         * amount
         * date
         * change series?
         *
         * attributes to set on original model (and clone into scenario)
         * account_id
         * category (but this depends on inFlow/outFlow - can we disable changing
         * these in editMode?
         */

        /*
         * TODO this is not DRY
         * onRender should not choose which view to show
         * - should be able to be called multiple times
         */

        // update the fields from the current view before we switch to the new one

        if (!this.workingModel.isValid()) {
            return;
        }

        this.workingModel.trigger('updateFields');
        this.main.show(new CashFlowScenarioPickerView({
            action: this.action,
            model: this.workingModel,
            scenarios: this.scenarioCollection,
            transactions: this.transactionCollection,
            gridTransactions: this.gridTransactionCollection,
        }));
    },

    saveAction() {
        /*
         * this is how we will ask the view to save its changes to this.model
         * TODO why is this evented rather than a direct method call?
         * we should be able to ask the view to determine whether the model is valid
         * (and update the display accordingly)
         */
        this.workingModel.trigger('updateFields');

        if (!this.workingModel.isValid()) {
            return;
        }

        const oldScenarioId = util.isUndefined(this.model) ? null : this.model.get('CASHFLOWSCENARIOID');
        const scenarioId = this.workingModel.get('CASHFLOWSCENARIOID');

        switch (this.action) {
        case 'create':
            itemService.create(this.workingModel);
            break;

        case 'upload':

            itemService.batch(this.workingModel);

            break;

        case 'update':

            /*
             * TODO
             * attributes to update in scenario:
             * type (inFlow/outFlow)
             * amount
             * date
             * change series?
             *
             * attributes to set on original model (and clone into scenario)
             * account_id
             * category (but this depends on inFlow/outFlow - can we disable changing
             * these in editMode?
             */

            if (oldScenarioId === scenarioId) {
                // edit the existing entry

                if (this.workingModel.get('changeFutureEntries')) {
                    /*
                     * TODO this should be done server-side
                     * TODO predicted transactions should be able to be put in scenarios
                     */
                    this.workingModel.unset('changeFutureEntries');
                    this.model.set(this.workingModel.attributes);

                    this.model.trigger('updateFutureEntries', this.model);
                } else {
                    this.model.set(this.workingModel.attributes);
                }

                itemService.update(this.workingModel);
            } else if (oldScenarioId === '0' && !util.isNull(scenarioId)) {
                /*
                 * save changes to a base transaction in a scenario
                 * nullify the original base transaction in this scenario
                 */
                const nullificationEntryCid = this.zeroOutBaseEntry();

                // link the new transaction to the original one
                this.workingModel.set({
                    dependsOn: this.model.cid,
                    linkedTo: nullificationEntryCid,
                    PARENTITEMID: this.model.get('CASHFLOWITEMID'),
                });

                itemService.create(this.workingModel);
                itemService.update(this.model.set('INSCENARIO', true));
            } else {
                /*
                 * update the view model with the model we are working with
                 * and update the item on the server.
                 */
                this.model.set(this.workingModel.attributes);
                itemService.update(this.workingModel);
            }

            break;

        case 'delete':

            /*
             * Delete - either the base or a scenario or remove the scenarios
             * from the base.
             */
            if (oldScenarioId === scenarioId) {
                itemService.delete(this.workingModel);
            } else {
                /*
                 * back end service will attempt to delete items with
                 * a PARENTITEMID = model.CASHFLOWITEMID after setting the
                 * base entry as not being associated with a scenario.
                 */
                itemService.update(this.model.set('INSCENARIO', false));
            }

            break;
        default:
        }

        this.close();
    },

    regionShown(view) {
        const buttons = view.getButtons();
        const btnTpl = util.template('<button type="button" class="<%=className%>"><%=text%></button>');
        this.$('.modal-footer').empty();
        util.each(buttons, (button) => {
            this.$('.modal-footer').append(btnTpl(button));
        });
        this.$('.modal-footer').append(`<button type="button" class="btn btn-link" data-dismiss="modal">${
            locale.get('cashflow.cancel.btn')}</button>`);
    },

    zeroOutBaseEntry() {
        const antiTransaction = this.model.clone();

        antiTransaction.set({
            CASHFLOWSCENARIOID: this.workingModel.get('CASHFLOWSCENARIOID'),
            DESCRIPTION: `Remove entry: ${this.model.get('DESCRIPTION')}`,
            PARENTITEMID: this.model.get('CASHFLOWITEMID'),

            // this is an offset record.
            ISOFFSET: true,

            // use original model's cid, not this.workingModel (clone)
            dependsOn: this.model.cid,

            nullifies: this.model.cid,

            // this will be connected to the new model we insert
            linkedTo: this.workingModel.cid,
        });

        // reverse the cashflow types.
        const inflow = this.model.get('INFLOW');

        const outflow = this.model.get('OUTFLOW');
        const type = (this.model.get('CASHFLOWTYPE') === 'OUTFLOW' ? 'INFLOW' : 'OUTFLOW');

        antiTransaction.set({
            INFLOW: outflow,
            OUTFLOW: inflow,
            CASHFLOWTYPE: type,
        });

        itemService.create(antiTransaction);

        return antiTransaction.cid;
    },
});

export default CashFlowChangeLayout;
