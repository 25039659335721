import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import rangeFieldWidgetTmpl from './rangeFieldWidget.hbs';

const RANGE = true;
const SINGLE = false;

export default BaseWidget.extend({
    template: rangeFieldWidgetTmpl,
    className: 'ui-widget',

    rangeInfo: {
        true: {
            placeholder: '',
            label: locale.get('title.range'),
        },

        false: {
            placeholder: locale.get('title.from'),
            label: locale.get('title.single'),
        },
    },

    ui: {
        $rangeToggle: '[data-hook="getRangeToggle"]',
        $fromField: '[data-hook="getFromField"]',
        $toField: '[data-hook="getToField"]',
        $toFieldValidator: '[data-hook="getToFieldValidationMessage"]',
    },

    events: {
        'click @ui.$rangeToggle': 'handleRangeToggle',
        'blur @ui.$fromField': 'checkRangeValues',
        'blur @ui.$toField': 'checkRangeValues',
    },

    constructor(options) {
        this.config = options.configParams;
        this.parentView = options.parentView;
        BaseWidget.prototype.constructor.call(this, options);
        const colSpan = (this.fieldInfo && this.fieldInfo.columnSpan)
            ? this.fieldInfo.columnSpan : 2;
        this.wideCss = `col-md-${colSpan * 2}`;
        this.narrowCss = `col-md-${colSpan}`;
    },

    initialize() {
        this.disallowRange = this.config.get('CheckRangeEnabled').toLowerCase() === 'false';
        let { relatedProperty } = this.fieldData;
        this.fromField = '';
        this.toField = this.fromField;
        if (!util.isEmpty(relatedProperty)) {
            relatedProperty = relatedProperty.split(',');
            if (relatedProperty.length >= 2) {
                this.fromField = relatedProperty[0].toUpperCase();
                this.toField = relatedProperty[1].trim().toUpperCase();
            }
        }
        this.rangeToggle = RANGE;
        // need to set rangeInfo locale sensitive values here
        this.rangeInfo[RANGE].label = locale.get('title.range');
        this.rangeInfo[RANGE].fromFieldAriaLabel = this.fieldData.fieldLabel;
        this.rangeInfo[RANGE].toggleAriaLabel = locale.get('title.rangetoggle.single.aria.label');
        this.rangeInfo[SINGLE].placeholder = locale.get('title.from');
        this.rangeInfo[SINGLE].label = locale.get('title.single');
        this.rangeInfo[SINGLE].fromFieldAriaLabel = locale.get('title.starting') + this.fieldData.fieldLabel;
        this.rangeInfo[SINGLE].toggleAriaLabel = locale.get('title.rangetoggle.range.aria.label');
    },

    onBeforeRender() {
        // set the field mandatory, if the from field is mandatory
        this.fromFieldMandatory = this.model.fieldData[this.fromField].mandatory;
        // add validator for from field
        this.addFromFieldValidator();
    },

    onRender() {
        // check if the to field is not empty, if so then display the to field
        if (this.model && !util.isEmpty(this.model.get(this.toField))) {
            this.checkRangeValues();
            this.handleRangeToggle();
            this.listenTo(this.parentView, 'ui-loaded', this.toggleCssWidth);
        }
    },

    addFromFieldValidator() {
        if (!this.model.validators) {
            this.model.validators = {};
        }

        this.model.validators[this.fromField] = {
            description: this.fieldData.fieldLabel,
            matches: this.fieldData.fieldType === 'AMOUNT' ? /^\d{1,2}([.,][\d{1,2}])?/ : /^\d+$/,
            isNumeric: true,
            exists: this.fromFieldMandatory,
        };
    },

    addToFieldValidator(fromNum) {
        if (!this.model.validators) {
            this.model.validators = {};
        }
        this.model.validators[this.toField] = {
            description: '',
            minValue: fromNum + 1,
            matches: this.fieldData.fieldType === 'AMOUNT' ? /^\d{1,2}([.,][\d{1,2}])?/ : /^\d+$/,
            isNumeric: true,
        };
    },

    removeFieldValidator(fieldName) {
        if (this.model.validators && this.model.validators[fieldName]) {
            delete this.model.validators[fieldName];
        }
    },

    checkRangeValues() {
        const fromNum = parseInt(this.model.get(this.fromField), 10);
        if (fromNum) {
            this.addToFieldValidator(fromNum);
        }
    },

    handleRangeToggle() {
        // change value of toggle
        this.rangeToggle = !this.rangeToggle;

        // get data about the new configuration
        const toggleInfo = this.rangeInfo[this.rangeToggle];

        this.ui.$rangeToggle.text(toggleInfo.label);
        this.ui.$rangeToggle.attr('aria-pressed', !this.rangeToggle);
        this.ui.$rangeToggle.attr('aria-label', toggleInfo.toggleAriaLabel);

        // add placeholder if range
        this.ui.$fromField.attr('placeholder', toggleInfo.placeholder);

        // update aria labels for inputs
        this.ui.$fromField.attr('aria-label', toggleInfo.fromFieldAriaLabel);

        // toggle to Field
        this.ui.$toField.toggleClass('hide');
        this.ui.$toFieldValidator.toggleClass('hide');

        if (this.rangeToggle === RANGE) {
            // clear out to field
            this.model.set(this.toField, '');
            this.removeFieldValidator(this.toField);
        }

        // update the width to correct for the number of visible fields.
        this.toggleCssWidth();

        // trigger an event to indicate that the range toggle was changed
        this.parentModel.trigger('widget:update', false);
    },

    toggleCssWidth() {
        this.$el.parent()
            .toggleClass(this.narrowCss, (this.rangeToggle === RANGE))
            .toggleClass(this.wideCss, !(this.rangeToggle === RANGE));
    },

    templateHelpers() {
        const { fieldData } = this;

        return {
            cid: this.model.cid,
            disallowRange: this.disallowRange,
            getToField: this.toField,
            getFromField: this.fromField,
            getFieldLabel: fieldData.fieldLabel,
            getToFieldLabel: fieldData.fieldLabel + locale.get('title.to'),
            isMandatory: (this.state === 'VIEW') ? false : this.fromFieldMandatory,
            getBlockClass: fieldData.blockClass,
            isReadOnly: this.state === 'VIEW',
            getRangeToggleLabel: (this.rangeToggle === RANGE) ? locale.get('title.range') : locale.get('title.single'),
            getFromFieldValue: this.model.get(this.fromField),
            getToFieldValue: this.model.get(this.toField),
            getToggleAriaLabel: (this.rangeToggle === RANGE) ? locale.get('title.rangetoggle.single.aria.label') : locale.get('title.rangetoggle.range.aria.label'),
            getToFieldAriaLabel: locale.get('title.ending') + fieldData.fieldLabel,
            getFromFieldAriaLabel: (this.rangeToggle === RANGE) ? fieldData.fieldLabel : locale.get('title.starting') + fieldData.fieldLabel,
        };
    },
});
