import React, {
  useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { FilterChips } from '@glu/data-components';
import { withTheme } from '@glu/theming';
import useInitialData from './useInitialData';
import ControlBar from '../ControlBar/ControlBar';
import BulkActions from '../BulkActions/BulkActions';
import Footer from '../Footer/Footer';
import useStyles from './ComposedView.styles';

function comparisonConvertor(comparison) {
  const { columns, ...rest } = comparison;
  if (!columns) {
    return comparison;
  }
  const update = columns.map(c => ({
    field: c.field,
    width: c.width,
    hide: c.hide
  }));
  const sortedKeys = Object.keys(rest).sort();
  const sorted = sortedKeys.reduce((carry, key) => ({
    ...carry,
    [key]: rest[key]
  }), {});
  return {
    columns: update,
    ...sorted
  };
}

const ComposedView = ({
  bulkActions,
  children,
  disableSessionStorage,
  onChange,
  defaultState,
  onGridReady,
  paginationTotalItems,
  records,
  serverSideFiltering,
  sessionId,
  FooterComponent,
  footerSticky,
  footerProps,
  bulkActionsEnabled,
  bulkActionsShown,
  CustomHeader,
  ControlBarProps,
  ...props
}) => {
  const classes = useStyles(props);
  const [gridApi, setGridApi] = useState(null);
  const [visibleItems, setVisibleItems] = useState(null);
  const [allowBulkActions, setAllowBulkActions] = useState(true);
  const [selections, setSelections] = useState([]);
  // TODO: move to Composed Grid and pass converter function
  const normalizeColumns = columns => columns.map(column => ({
    ...column,
    colId: column.field
  }));

  const initialState = {
    savedViews: {
      active: undefined,
      views: []
    },
    columns: normalizeColumns(props.columnDefs),
    filters: serverSideFiltering ? serverSideFiltering.filters : [],
    sort: [],
    pageSize: 25,
    page: 0,
    ...defaultState
  };

  const [data, setData] = useState(initialState);

  const {
    initialData,
    setSavedViews,
    setSession
  } = useInitialData({
    data: initialState,
    disableSessionStorage,
    showSavedViews: props.showSavedViews,
    storageId: sessionId || props.gridId
  });

  useEffect(() => {
    if (disableSessionStorage || !initialData) {
      return;
    }
    setSession(data);
  }, [data, disableSessionStorage, initialData, setSession]);

  useEffect(() => {
    if (!serverSideFiltering) {
      return;
    }
    const { filters } = serverSideFiltering;
    setData(currentData => ({
      ...currentData,
      filters
    }));
  }, [serverSideFiltering]);

  useEffect(() => {
    if (initialData) {
      setData(currentState => ({
        ...currentState,
        ...initialData
      }));
    }
  }, [initialData]);

  useEffect(() => {
    if (typeof bulkActionsEnabled !== 'function') {
      setAllowBulkActions(bulkActionsEnabled);
      return;
    }
    const compatible = !selections.length ? true : bulkActionsEnabled(selections);
    setAllowBulkActions(compatible);
  }, [selections, bulkActionsEnabled]);

  const getViewChange = value => {
    const activeView = value.views.find(view => view.viewData.id === value.active) || {};
    const { viewData, ...rest } = activeView;
    return rest;
  };

  const handleChange = ({ name, value, errors = {} }) => {
    /* istanbul ignore next */
    if (Object.keys(errors).length) {
      onChange({ name, value, errors });
      return;
    }

    if (name === 'savedViews' && !initialData) {
      setSavedViews(value);
      onChange({ name, value, errors });
      return;
    }

    setData(currentData => ({
      ...currentData,
      [name]: value,
      ...(name === 'savedViews' && getViewChange(value))
    }));

    onChange({ name, value, errors });
  };

  // TODO: Rename to handle display ready or something like that
  const handleGridReady = (params) => {
    setGridApi(params.api);
    onGridReady(params);
  };
  return (
    <div className={`${classes.root} ${footerSticky ? classes.sticky : ''} `}>
      <CustomHeader
        {...props}
        data={data}
        onChange={handleChange}
        gridApi={gridApi}
        initialData={initialState}
        records={records}
        comparisonConvertor={comparisonConvertor}
      />
      <ControlBar
        {...props}
        data={data}
        onChange={handleChange}
        gridApi={gridApi}
        initialData={initialState}
        htmlId={props.gridId}
        records={records}
        comparisonConvertor={comparisonConvertor}
        {...ControlBarProps}
      />
      <FilterChips
        data={data}
        htmlId={props.gridId}
        onChange={handleChange}
      />
      {initialData
        && (
          children({
            onGridReady: handleGridReady,
            onChange: handleChange,
            className: classes.grid,
            data,
            setSelections,
            setVisibleItems,
            records,
            ...props
          })
        )
      }
      {bulkActionsShown
        ? (
          <BulkActions
            bulkActions={bulkActions}
            records={records}
            selections={selections}
            enabled={allowBulkActions}
          />
        ) : null}
      {FooterComponent && (
        <FooterComponent
          className={footerSticky ? classes.footer : ''}
          records={records}
          onChange={handleChange}
          data={data}
          gridId={props.gridId}
          setData={setData}
          visibleItems={visibleItems}
          {...footerProps}
        />
      )}
    </div>
  );
};

ComposedView.propTypes = {
  /** Bulk Actions. Will create a button for each one */
  bulkActions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  })),
  /** Callback function, or boolean, to determine whether or not to enable bulk actions */
  bulkActionsEnabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** Display layer for the records. Typically grid or tiles */
  children: PropTypes.func.isRequired,
  /** Description of the columns. Include filtering information here */
  columnDefs: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string
    })
  ).isRequired,
  /** Default State object. This will mirror the shape of the data object.
     it will be overriden by the default Saved View or session storage if
     enabled. */
  defaultState: PropTypes.shape({
    savedViews: PropTypes.shape({
      active: PropTypes.number,
      views: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string
      })
    ),
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      criteriaDisplay: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      nameDisplay: PropTypes.string.isRequired,
      operator: PropTypes.oneOf(['AND', 'OR']),
      filterData: PropTypes.shape({}).isRequired
    })),
    grid: PropTypes.shape({}),
    sort: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string,
        sort: PropTypes.oneOf(['asc', 'desc'])
      })
    ),
    pageSize: PropTypes.number,
    page: PropTypes.number
  }),
  /** Prop to disable save and application of any changes to the data object */
  disableSessionStorage: PropTypes.bool,
  /** TODO: Move out of composed view */
  /** Ag grid height is a little touchy. Adding a default height of 500 */
  gridHeight: PropTypes.number,
  /** TODO: Move rename to View ID */
  /** Id for the grid used for storage and export title */
  gridId: PropTypes.string.isRequired,
  /** Change event called anytime a component is called. Passes data in the shape of:
    {name: '', value: '', errors: {} }
  */
  onChange: PropTypes.func,
  /** TODO: Rename */
  /** Property to call after initial view is loaded. */
  onGridReady: PropTypes.func,
  /** records data should match column defs, but doesn't have any particular shape */
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Property to disable saved views */
  showSavedViews: PropTypes.bool,
  /** Custom session id. Useful if you want to namespace a grid id.
    Session will use grid Id if this is not defined
  */
  sessionId: PropTypes.string,
  /** Suppress local pagination */
  serverSidePagination: PropTypes.bool,
  /** Can explicitly set pagination count. Will fallback to records length */
  paginationTotalItems: PropTypes.number,
  /** Server side filtering information. */
  serverSideFiltering: PropTypes.shape({
    filters: PropTypes.array
  }),
  FooterComponent: PropTypes.elementType,
  /** Controls whether footer is static on bottom of screen */
  footerSticky: PropTypes.bool,
  /** Additional props for FooterComponent */
  footerProps: PropTypes.shape({}),
  /** Hide or show BulkActions */
  bulkActionsShown: PropTypes.bool,
  /** Optional additional header component */
  CustomHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** Props for control bar */
  ControlBarProps: PropTypes.shape({})
};

ComposedView.defaultProps = {
  bulkActions: [],
  bulkActionsEnabled: true,
  defaultState: {},
  disableSessionStorage: false,
  gridHeight: 500,
  onChange() {},
  onGridReady() {},
  serverSidePagination: false,
  sessionId: null,
  showSavedViews: false,
  paginationTotalItems: null,
  serverSideFiltering: null,
  FooterComponent: Footer,
  footerSticky: true,
  footerProps: {},
  bulkActionsShown: true,
  CustomHeader: () => null,
  ControlBarProps: {}
};

const ThemedComposedView = withTheme(ComposedView);
ThemedComposedView.displayName = 'ComposedView';
export default ThemedComposedView;
