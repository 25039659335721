var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"txt\">\n    <span class=\"icon icon-network\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.entitlement.manage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":78}}}))
    + "\"></span>\n    <span class=\"icon icon-tick\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.entitlement.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":76}}}))
    + "\"></span>\n    <span class=\"icon icon-tools\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.entitlement.repair",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":4,"column":76}}}))
    + "\"></span>\n    <span class=\"icon icon-settings\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.entitlement.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":44},"end":{"line":5,"column":79}}}))
    + "\"></span>\n</span>\n";
},"useData":true});