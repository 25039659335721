import '../../themeDefaults';

const openBorderStyles = {
  border: '1px solid transparent'
};

export default ({
  actionableIcon: {
    hoverBackgroundColor: actionableIconHoverBackgroundColor,
    hoverColor: actionableIconHoverColor
  },
  mobilegrid: {
    actionControl
  }
}) => ({
  root: {
    position: 'relative',
    fontFamily: actionControl.icon.fontFamily
  },
  iconOpen: {
    background: actionableIconHoverBackgroundColor,
    ...openBorderStyles,
    borderRadius: '50%',

    '&:focus': openBorderStyles,

    '&:not([disabled]) > svg': {
      fill: actionableIconHoverColor
    }
  },
  popoverClosed: {
    display: 'none'
  },
  popoverOpen: {
    display: 'block',
    position: 'absolute',
    background: actionControl.popover.backgroundOpen,
    whiteSpace: 'nowrap',
    boxShadow: actionControl.popover.boxShadow,
    padding: '0 0.75rem 0.5rem',
    minWidth: 135,

    '& h2': {
      fontSize: 16,
      margin: ['1rem', 0, 0]
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    '& li': {
      padding: ['0.5rem', 0]
    }
  },
  popoverTop: {
    bottom: 37,
    left: -20
  },
  popoverBottom: {
    top: '100%',
    left: -20
  },
  actionSection: {
    borderTop: `1px solid ${actionControl.borderColor}`,

    '&:first-child': {
      borderTop: 0
    },

    '& a': {
      textDecoration: 'none',
      borderBottom: '1px solid transparent',
      transition: 'all 0.5s ease-in'
    },
    '& a:hover': {
      borderColor: actionControl.borderColorHover
    }
  },
  actionFooter: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    background: actionControl.footerBackground,
    height: 54,

    '& button': {
      borderRadius: 0,
      flexBasis: '50%'
    }
  }
});
