import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import RemitterModalView from 'app/reports/views/lockbox/modals/remitter/modifyRemitter';
import RemitterModel from 'app/reports/models/remitter';
import template from 'app/reports/views/lockbox/lockboxListRemitters.hbs';

export default Layout.extend({
    template,

    onRender() {
        this.gridView = this.createGridView();

        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        if (store.has('importSuccessMessage')) {
            this.alertRegion.show(alert.success(store.get('importSuccessMessage')));
            store.unset('importSuccessMessage');
        }
        this.gridRegion.show(this.gridView);
    },

    createGridView() {
        const options = {
            context: {
                serviceName: '/tableMaintenance/lockboxRemitterView',
                productCode: 'GIR',
                functionCode: 'LOCKBOX',
                typeCode: 'LBX_RPT',
            },

            enabledSavedViews: true,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
        };

        return GridApi.createServiceGridView(options);
    },

    gridRowAction(optionsParam) {
        return new Promise((resolve) => {
            const options = optionsParam;
            const remitterName = options.model.get('REMITTER_NAME');
            if (remitterName.replace(/\s/g, '').length > 0) {
                options.remitterName = remitterName;
            }
            options.modalType = 'advanced';
            this.modifyRemitter(options);
            resolve();
        });
    },

    modifyRemitter(options) {
        const self = this;
        const modifyRemitter = new RemitterModalView(options);

        modifyRemitter.on('remitter:close', (alertView) => {
            if (alertView) {
                self.alertRegion.show(alertView);
                self.gridView.refreshGridData();
            }
            dialog.close();
        });
        dialog.open(modifyRemitter);
    },

    fileImport() {
        this.navigateTo('REPORTING/lockbox/fimport');
    },

    addRemitter() {
        const self = this;

        const addRemitter = new RemitterModalView({
            modalType: 'advanced',
        });

        addRemitter.on('remitter:close', (alertView) => {
            if (alertView) {
                self.alertRegion.show(alertView);
                self.gridView.refreshGridData();
            }
            dialog.close();
        });
        dialog.open(addRemitter);
    },

    removeRemitter() {
        const promises = [];
        const selectedRows = this.gridView.grid.getSelectedRows();
        const selectedModels = [];
        const cidArray = [];
        const self = this;

        if (selectedRows.length > 0) {
            const gridModels = this.gridView.wrapper.rows.models;

            util.each(selectedRows, (row) => {
                cidArray.push(row.cid);
            });

            util.each(gridModels, (model) => {
                if (cidArray.indexOf(model.cid) > -1) {
                    selectedModels.push(model);
                }
            });

            const collection = new Collection(
                selectedModels,
                {
                    model: new RemitterModel(),
                },
            );

            this.successResp = [];
            this.errorResp = [];
            dialog.confirm(locale.get('LBX.RemoveSelectedItem'), locale.get('PAY.Confirm'), (ok) => {
                if (ok) {
                    collection.each((model) => {
                        const remitter = new RemitterModel();
                        remitter.set({
                            ACCOUNTNUMBER: model.get('ACCOUNTNUMBER'),
                            REMITTER_ACCT_ID: model.get('REMITTER_ACCT_ID'),
                            REMITTER_ID: model.get('REMITTER_ID'),
                        });
                        promises.push(self.getRemitterPromise(remitter));
                    });

                    Promise.all(promises).then((results) => {
                        if (self.successResp.length > 1) {
                            for (let x = 1; x < self.successResp.length; x += 1) {
                                results[0].confirms.confirmResults
                                    .push(self.successResp[x].confirms.confirmResults[0]);
                            }
                        }

                        if (self.errorResp.length > 0) {
                            self.renderErrorMessage(results[0]);
                            self.gridView.refreshGridData();
                        } else {
                            self.renderSuccessMessage(results[0], locale.get('LBX.RemitterRemoved'));
                            self.gridView.refreshGridData();
                        }
                    });
                }
            });
        }
    },

    getRemitterPromise(remitter, id) {
        const self = this;
        return new Promise((resolve, reject) => {
            remitter.set('id', remitter.cid);
            remitter.destroy({
                success(model, response) {
                    response.locationId = id;
                    self.successResp.push(response);
                    resolve(response);
                },

                error(response) {
                    self.errorResp.push(response);
                    reject(response);
                },
            });
        });
    },

    renderSuccessMessage(confirmResponse, message) {
        const confirms = new Confirms({
            confirms: confirmResponse ? confirmResponse.confirms : null,
        });

        this.alertView = alert.success(
            message,
            {
                details: confirmResponse
                && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                    ? confirms : null,
                canDismiss: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    renderErrorMessage(model) {
        const confirms = new Confirms({
            confirms: model.error.confirms,
        });

        this.alertView = alert.danger(
            model.error.message[0],
            {
                details: confirms,
                canDismiss: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    /**
     * @method cancel
     * - If accessed from the previous stack, trasaction details,
     * goto the returnRoute, else go to the summary
     */
    cancel() {
        if (this.stack) {
            this.navigateTo(this.options.returnRoute);
        } else {
            this.navigateTo('REPORTING/lockbox');
        }
    },
});
