import ItemView from '@glu/core/src/itemView';
import multiTransferItemViewTmpl from './multiTransferItemView.hbs';

export default ItemView.extend({
    template: multiTransferItemViewTmpl,
    tagName: 'tr',
    className: 'transfer-item',

    ui: {
        $transferAmount: 'input[data-field="transferAmount"]',
    },

    events: {
        'click [data-field="modifyTransfer"]': 'modifyTransfer',
        'click [data-field="removeTransfer"]': 'removeTransfer',
        'blur [data-field="transferAmount"]': 'modifyTransferAmount',
    },

    onRender() {
        this.ui.$transferAmount.inputmask('number');
    },

    templateHelpers() {
        const self = this;
        return {
            getAccountNumber() {
                return self.model.getAccountNumber();
            },

            getAmount() {
                return self.model.getAmount();
            },

            getCurrency() {
                return self.model.getCurrency();
            },
        };
    },

    modifyTransfer() {
        this.appBus.trigger('multiTransfer:modifyTransfer', this.model);
    },

    modifyTransferAmount() {
        this.appBus.trigger('multiTransfer:modifyTransferAmount', this.model, this.ui.$transferAmount);
    },

    removeTransfer() {
        this.model.destroy();
        this.appBus.trigger('multiTransfer:removeTransfer', this.model);
    },
});
