import services from 'services';
import $ from 'jquery';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import constants from 'app/administration/constants';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import gridUtil from 'app/utilities/views/gridUtil';
import template from './auditList.hbs';

const AuditList = ListView.extend({
    template,

    initialize(options) {
        this.menuCategory = options.menuCategory;
        this.viewUrl = options.viewUrl;
        this.returnRoute = options.returnRoute;

        this.contextDef = this.options.contextDef;
        this.mode = 'select';

        const superOptions = {
            menuCategory: this.menuCategory,
            serviceName: options.serviceName || 'audit/listView',
            serviceFunc: null,
            businessType: null,
            hideGridActionButtons: true,
            context: 'AUDIT_INFO',
            enableSavedViews: true,
            lvcAlwaysReset: true,
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender() {
        // call super
        ListView.prototype.onRender.call(this);

        if (this.hasLoadedRequiredData()) {
            const nonSortableColumns = constants.AUDIT_GRID_NON_SORTABLE_COLUMNS;
            this.listenTo(this.gridView, 'gridapi:loaded', gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
            this.appBus.on(`gridapi:showMessages${this.gridView.cid}`, gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
        }
    },

    /**
     * @method buildExportModel
     * @description - populates exportModel object
     * accepts format object
     * is used for print/export audit listview on both admin and client apps
     */
    buildExportModel(format) {
        // Get the print/export options
        const options = {
        //  the format object can be a complex object or contain plain format value
            format: (format.format === undefined) ? format : format.format,
            gridView: this.gridView,
        };

        ListView.prototype.buildExportModel.call(this, options);
        const ind = this.exportModel.columns.indexOf('DETAILS');
        const { productCode } = this.exportModel.actionData;

        if (ind > -1) {
            this.exportModel.columns[ind] = 'DETAILSFULL';
        }
        /**
         * set service inquiry id based on if on client or admin side
         * (needed before making call)
         */
        this.exportModel.inquiryId = (productCode === 'SMAINT') ? constants.AUDIT_INQUIRY_ADMIN_PRINT_EXP : constants.AUDIT_INQUIRY_PRINT_EXP;
        this.exportURL = `${(productCode === 'SMAINT') ? '/adminAudit' : '/audit'}/export/listview`;
        return this.exportModel;
    },

    doExport() {
        if ($('#listViewExportForm').length === 0) {
            const f = `<form id="listViewExportForm" action="${services.generateUrl(this.exportURL)}" method="get" target="_self">
                    <input id="listViewRequest" type="hidden" name="listViewRequest" ></form>`;
            this.$el.append(f);
        }
        $('#listViewExportForm #listViewRequest').val(JSON.stringify(this.exportModel));
        $('#listViewExportForm').submit();
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            exportURL: this.exportURL,
            title: `${this.localeKey}title`,
            asyncReport: false,
        });
        dialog.custom(printModal);
    },
});

let list = AuditList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
