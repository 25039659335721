var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-md-3 col-md-offset-3 emailList\">\n            <label class=\"checkbox-inline\" for=\"allEmails\"><input type=\"checkbox\" data-hook=\"email\" id=\"allEmails\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.allEmails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":115},"end":{"line":10,"column":144}}}))
    + " </label>\n        </div>\n        <div class=\"col-md-2 smsList\">\n            <label class=\"checkbox-inline\" for=\"allSMS\"><input type=\"checkbox\" data-hook=\"sms\" id=\"allSMS\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.allSMS",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":108},"end":{"line":13,"column":134}}}))
    + "</label>\n        </div>\n        <div class=\"col-md-2 phoneList\">\n            <label class=\"checkbox-inline\" for=\"allPhone\"><input type=\"checkbox\" data-hook=\"phone\" id=\"allPhone\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.allPhone",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":113},"end":{"line":16,"column":141}}}))
    + "</label>\n        </div>\n        <div class=\"col-md-2 faxList\">\n            <label class=\"checkbox-inline\" for=\"allFax\"><input type=\"checkbox\" data-hook=\"fax\" id=\"allFax\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.allFax",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":107},"end":{"line":19,"column":133}}}))
    + "</label>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-md-3 col-md-offset-3 emailList\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.emails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":42}}}))
    + "</h4>\n        </div>\n        <div class=\"col-md-2 smsList\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.sms",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":26,"column":39}}}))
    + "</h4>\n        </div>\n        <div class=\"col-md-2 phoneList\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.phone",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":41}}}))
    + "</h4>\n        </div>\n        <div class=\"col-md-2 faxList\">\n           <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.fax",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":15},"end":{"line":32,"column":38}}}))
    + "</h4>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"recipients-container\">\n        <div class=\"row\">\n        <div class=\"col-12\">\n            <h2>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"alerts.contact.methods",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":51}}}))
    + "</h2>\n        </div>\n    </div>\n    <div class=\"row recipient-item recipient-item-header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notViewMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n        <div class=\"recipient-items\"></div>\n</div>\n";
},"useData":true});