import React from 'react';

const LoadingPage = () => (
    <div className="section section-container loading">
        <div className="section-body">
            <div className="loading-indicator-view"><span /></div>
        </div>
    </div>
);

export default LoadingPage;
