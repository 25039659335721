import React from 'react';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import ViewCheckPayment from 'pcm/app/payments/ViewCheckPayment';
import ViewBatchJobSummary from 'pcm/app/payments/ViewBatchJobSummary';


export default {
    pmtViewCheckPayment(batchTnum, tnum, typeCode, activeTab) {
        const checkTransactionInfo = store.get('checkTransactionInfo');
        const entitlementsInfo = store.get('entitlementsInfo');
        const currentAction = store.get('currentAction');
        // use case 1 - when click from grid, and then navigate to /checkPreview tab
        // use case 2, when page refreshed, so need to take from store
        const ptxCheckId = checkTransactionInfo ? checkTransactionInfo.PTX_CHECK_ID : '';
        const checkStatus = checkTransactionInfo ? checkTransactionInfo.CHECKSTATUS : '';
        const status = checkTransactionInfo ? checkTransactionInfo.STATUS : '';
        const reprintEntitlements = entitlementsInfo ? entitlementsInfo.REPRINT : '';
        /* eslint-disable */
        const updateCount = checkTransactionInfo ? checkTransactionInfo.UPDATECOUNT__ : '';
        this.showPage(
            locale.get(`batch.b${typeCode}_title-view`),
            (
                <ViewCheckPayment
                    batchTnum={parseInt(batchTnum, 10)}
                    tnum={parseInt(tnum, 10)}
                    typeCode={typeCode}
                    activeTab={activeTab || 'transactionDetails'}
                    ptxCheckId={ptxCheckId}
                    checkStatus={checkStatus}
                    status={status}
                    updateCount={updateCount}
                    reprintEntitlements={reprintEntitlements}
                    currentAction={currentAction}
                />
            ),
        );
    },
    pmtViewCheckBatchJobSummary(batchId) {
        // const checkTransactionInfo = store.get('checkTransactionInfo');
        // const entitlementsInfo = store.get('entitlementsInfo');
        // // use case 1 - when click from grid, and then navigate to /checkPreview tab
        // // use case 2, when page refreshed, so need to take from store
        // const ptxCheckId = checkTransactionInfo ? checkTransactionInfo.PTX_CHECK_ID : '';
        // const checkStatus = checkTransactionInfo ? checkTransactionInfo.CHECKSTATUS : '';
        // const status = checkTransactionInfo ? checkTransactionInfo.STATUS : '';
        // const reprintEntitlements = entitlementsInfo ? entitlementsInfo.REPRINT : '';

        this.showPage(
            locale.get(`batch.b${2}_title-view`),
            (
                <ViewBatchJobSummary
                    batchId={batchId}
                />
            ),
        );
    },
};
