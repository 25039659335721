import Layout from '@glu/core/src/layout';
import services from 'services';
import http from '@glu/core/src/http';
import GridApi from 'common/dynamicPages/api/grid';
import Dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import ExternalWidgetModel from 'app/administration/models/externalWidgetModel';
import ListView from 'common/dynamicPages/views/workflow/list';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import alert from '@glu/alerts';
import locale from 'system/webseries/api/locale';
import DeploymentLayout from './deployment';
import ContentEditLayout from './contentEdit';
import viewTmpl from './view.hbs';

export default Layout.extend({
    template: viewTmpl,

    events: {
        'click [data-hook="insert-button"]': 'addWidget',
    },

    initialize() {
        const context = {
            serviceName: '/cxp/externalWidget',
            actionMode: 'SELECT',
            productCode: 'CXP',
            functionCode: 'MAINT',
            typeCode: 'EXWIDGET',
            gridId: 30000,
        };
        ListView.prototype.setListViewConfig.call(this, context);
        const gridOptions = {
            context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            selector: 'none',
            lvc: this.lvc,
        };

        this.gridView = GridApi.createServiceGridView(gridOptions);

        this.listenTo(
            this.gridView,
            {
                rowAction: this.handleRowAction,
            },
            this,
        );

        const removeEvent = `grid:row:action:action_remove_${this.gridView.cid}`;
        this.listenTo(this.appBus, removeEvent, this.handleRemoveRowAction, this);
        ListView.prototype.setupGridAvailableListener.call(this);
    },

    onShow() {
        this.gridRegion.show(this.gridView);
    },

    addWidget() {
        this.navigateTo('/CXP/addExternalWidget');
    },

    handleRowAction(data) {
        const self = this;

        if (data.action === 'select') {
            this.navigateTo(`/CXP/viewExternalWidget/${data.model.get('WIDGETID')}`);
        }

        if (data.action === 'content') {
            this.navigateTo(`/CXP/editWidgetContent/${data.model.get('WIDGETID')}`);
        }

        if (data.action === 'deploy') {
            http.post(
                services.generateUrl('/segmentation/getListView'),
                {
                    rowsPerPage: 2147483647,
                    startRow: 0,
                },
                (response) => {
                    const model = new Model();
                    model.set('action', data.action);
                    model.set('segments', response);

                    if (data.action === 'deploy') {
                        Dialog.custom(new DeploymentLayout({
                            model,
                            widgetId: data.model.get('WIDGETID'),
                        }));
                    } else if (data.action === 'content') {
                        Dialog.custom(new ContentEditLayout({
                            model,
                            widgetId: data.model.get('WIDGETID'),
                        }));
                    }
                },
            );
        }

        if (data.action === 'modify') {
            const widgetId = data.model.get('WIDGETID');
            const modifyUrl = `/CXP/updateExternalWidget/${widgetId}`;
            this.navigateTo(modifyUrl);
        }

        if (data.action === 'delete') {
            const tempModel = new ExternalWidgetModel();
            tempModel.set('widgetId', data.model.get('WIDGETID'));
            tempModel.destroy({
                success(model, response) {
                    if (response.actionResponse.result) {
                        self.displayAlert(locale.get('Bank.Widget.deleted.success'));
                    } else {
                        self.displayError(response.actionResponse);
                    }
                    self.gridView.refreshGridData();
                },

                error(model, response) {
                    self.displayError(response.actionResponse);
                    self.gridView.refreshGridData();
                },
            });
        }
    },

    displayAlert(message) {
        // display notification message
        this.alertView = alert.success(
            message,
            {
                animate: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    displayError(errorParam) {
        const error = errorParam;
        if (error === null || error === undefined) {
            return;
        }
        const message = error.message.join(' ');
        error.confirms.confirmResults[0].confirmData = null;

        const confirms = new Confirms({
            confirms: error.confirms,
        });

        // display notification message
        this.alertView = alert.danger(
            message,
            {
                details: confirms,
                canDismiss: true,
                animate: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

});
