import { createUseStyles } from '@glu/theming';

const styles = ({ switchReact, typography }) => ({
  switchLabel: {
    ...typography.label,
    display: props => (props.inline ? 'inline' : 'block'),
    position: 'relative',
    marginRight: 5,
    marginBottom: 5
  },
  switch: {
    display: 'inline-flex',
    flexDirection: 'column',
    padding: 0,
    height: switchReact.content.height.toggleHeight,
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 5,
    ...switchReact.switchWrap,
    backgroundColor: props => (
      props.positive
        ? switchReact.content.backgroundColor.positiveBackgroundColor
        : switchReact.content.backgroundColor.negativeBackgroundColor
    ),
    textAlign: props => (
      props.positive
        ? 'left'
        : 'right'
    ),
    '&:after': {
      backgroundColor: switchReact.content.backgroundColor.defaultBackgroundColor,
      border: '0 solid #ccc',
      content: '" "',
      color: switchReact.content.color.positiveColor,
      position: 'absolute',
      top: 1,
      left: props => (
        props.positive
          ? switchReact.pointerShift
          : 1
      ),
      transition: '.4s',
      padding: 0,
      height: switchReact.content.height.pointerHeight,
      ...switchReact.pointer
    }
  },
  focus: {
    boxShadow: `0 0 0 1px ${switchReact.focusColor}`
  },
  switchState: {
    ...typography.label,
    fontSize: switchReact.content.fontSize,
    fontWeight: switchReact.content.fontWeight,
    minWidth: 42,
    height: switchReact.content.height.toggleHeight,
    lineHeight: switchReact.content.lineHeight,
    float: 'left',
    marginBottom: 0,
    textAlign: 'center',
    color: props => (
      props.positive
        ? switchReact.content.color.positiveColor
        : switchReact.content.color.negativeColor
    ),
    ...switchReact.value
  },
  switchStatePositive: {
    visibility: props => (
      props.positive
        ? 'visible'
        : 'hidden'
    ),
    height: props => (
      props.positive
        ? switchReact.content.height.toggleHeight
        : '0px'
    ),
    paddingLeft: switchReact.positive.paddingLeft,
    paddingRight: switchReact.positive.paddingRight
  },
  switchStateNegative: {
    visibility: props => (
      props.positive
        ? 'hidden'
        : 'visible'
    ),
    height: props => (
      props.positive
        ? '0px'
        : switchReact.content.height.toggleHeight
    ),
    paddingLeft: switchReact.negative.paddingLeft,
    paddingRight: switchReact.negative.paddingRight
  },
  inputCheckbox: {
    opacity: 0,
    position: 'absolute',
    left: '0',
    top: '0'
  }
});

export default createUseStyles(styles);
