import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import WorkspaceModel from 'common/workspaces/models/workspace';

export default Collection.extend({

    sync(method, collection, options) {
        const url = services.generateUrl('/workspaces/getWorkspaces');
        const data = {
            item: [{
                name: '',
                value: '',
            }],
        };

        http.post(url, data, (result) => {
            options.success(result.workspaces);
        }, (result) => {
            options.error(result);
        });
    },

    parse(jsonData) {
        const returnArray = [];
        util.each(jsonData, (workspaceJson) => {
            const workspace = new WorkspaceModel(null, {
                jsonData: workspaceJson,
            });
            workspace.fetch();
            returnArray.push(workspace);
        });
        return returnArray;
    },

});
