/**
 * PCM Application related actions (based on Marionette.Controller)
 */
import achEsendManagementActions from './achEsendManagement/actions';
import accountMaintenanceActions from './accountMaintenance/actions';
import checkManagementActions from './checkManagement/actions';
import ptxConnectActions from './ptx-connect/actions';
import customFieldsActions from './customFields/actions';
import paymentsActions from './payments/actions';
import workflowConfigActions from './workflowConfig/actions';
import workflowLogActions from './workflowLog/actions';
import messageLogActions from './messageLog/actions';
import outsourcePrintProfile from './outsourcePrintProfile/actions';
import ipLogonWhitelist from './ipLogonWhitelist/actions';
import outboundScheduleConfigActions from './outboundScheduleConfig/actions';
import checkProfileSetupActions from './checkProfileSetup/actions';
import eventConfigActions from './eventConfiguration/actions';
import paymentExtractConfigActions from './paymentExtractConfiguration/actions';

export default {
    ...achEsendManagementActions,
    ...accountMaintenanceActions,
    ...checkManagementActions,
    ...ptxConnectActions,
    ...customFieldsActions,
    ...paymentsActions,
    ...workflowConfigActions,
    ...workflowLogActions,
    ...messageLogActions,
    ...outsourcePrintProfile,
    ...ipLogonWhitelist,
    ...outboundScheduleConfigActions,
    ...checkProfileSetupActions,
    ...eventConfigActions,
    ...paymentExtractConfigActions,
};
