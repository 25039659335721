import 'select2';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import { log } from '@glu/core';
import locale from '@glu/locale';
import $ from 'jquery';
import moment from 'moment';
import services from 'services';
import userInfo from 'etc/userInfo';
import amountFormatHelper from 'common/dynamicPages/views/mdf/componentHelpers/amountFormat';
import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import configuration from 'system/configuration';
import constants from 'common/dynamicPages/api/constants';
import dateUtil from 'common/util/dateUtil';
import Decimals from 'common/dynamicPages/api/decimals';
import formatter from 'system/utilities/format';
import FormDialog from 'app/transfers/views/transferRateDialog';
import fxPaymentUtil from 'common/util/fxPaymentUtil';
import PaymentUtil from 'common/util/paymentUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import serviceUtil from 'common/util/services';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import fxFieldValidation from '../util/fxFieldValidation';
import fxPaymentTemplate from './fxPaymentWidget.hbs';
import rtgsHeaderFooter from '../util/rtgsHeaderFooter';

export default BaseWidget.extend({
    template: fxPaymentTemplate,
    className: 'ui-widget fx-widget',

    ui: {
        $creditCurrency: '[data-hook="getCreditCurrency"]',
        $creditDebitEntered: '[data-hook="getCreditDebitEntered"]',
        $addContractID: '[data-hook="contractIDpopUp"]',
        $contractID: '[data-hook="contractID"]',
        $contractIDContainer: '[data-hook="contractIDContainer"]',
        $creditAmount: '[data-amount-type="CREDIT"]',
        $debitAmount: '[data-amount-type="DEBIT"]',
        $exchangeRate: '[data-hook="exchange-rate"]',
        $indicativeRate: '[data-hook="indicative-rate"]',
        $removeContractID: '[data-hook="removeContractID"]',
        $popovers: '[data-toggle="popover"]',

        // for when in view mode
        $contractIdView: '[name="CONTRACTID_VIEW"]',
        $exchangeRateView: '[name="EXCHANGE_RATE_VIEW"]',
        $indicativeRateView: '[name="INDICATIVE_RATE_VIEW"]',
    },

    events: {
        'change @ui.$creditDebitEntered': 'creditDebitEnteredChanged',
        'change @ui.$creditAmount': 'creditAmountChanged',
        'change @ui.$debitAmount': 'debitAmountChanged',
        'click @ui.$addContractID': 'openRateDialog',
        'click @ui.$removeContractID': 'removeContractID',
    },

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName  , etc.
        BaseWidget.prototype.initialize.call(this, options);
        this.comboCollections = options.comboCollections;
        this.processingDates = options.processingDates;
        this.fieldData = this.parentModel.fieldData;
        this.exchangePrecision = +serverConfigParams.get('ExchangeRateScale');

        /*
         * In the event that this configuration is enabled, the available CREDIT_CURRENCIES will be
         * dependent on the DEBIT_BANK_CODE and DEBIT_CURRENCY
         */
        this.restrictedCreditCurrenciesEnabled = appConfigParams.getValue('WIRES', 'SUPPORTDEFINEDCURRENCYPAIRS') === '1';

        this.initialEntry = true;
        const {
            functionCode,
            productCode,
            typeCode,
        } = this.model.jsonData.typeInfo;

        this.model.set({
            FUNCTION: functionCode,
            PRODUCT: productCode,
            TYPE: typeCode,
        });

        /*
         * If the data conditions for retrieval and display of value date for this payment type
         * are not met, unset the default values for the value date and tran date.
         */
        if (!PaymentUtil.isReadyValueDateRetrieval(this.model)) {
            this.model.set('VALUE_DATE', '');
            this.model.set('TRAN_DATE', '');
        }

        // First round model updates
        this.initialSetup();

        if (this.isModifyOrFromTemplate() && this.restrictedCreditCurrenciesEnabled) {
            this.allowedCreditCurrenciesPromise =
            this.createPromiseToRetrieveAllowedCreditCurrencies().then(
                this.updateAllowedCreditCurrenciesOnTheModel.bind(this),
                (err) => { log.error(err); },
            );
        }

        if (this.isPayment()) {
            this.valueDateListPromise = Promise.resolve(true);
            // initialization from rtgs policy file
            this.requestValueDateList();
            this.listenTo(this.parentModel, 'widget:update:value-date', () => {
                this.valueDateChanged();
            });

            /*
             * For view we display the indicative rate from the transaction model. When a rate
             * contract ID exists we display the exchange rate so no lookup is required. When the
             * Credit and Debit currencies are the same no indicative rate is needed. For all other
             * cases we call the rate service on load to assure the most recent valid information.
             */
            if (this.state !== 'VIEW' && this.parentModel.get('EXCHANGE_RATE_CONTRACTID') === '' &&
                this.parentModel.get('CREDIT_CURRENCY') !== this.parentModel.get('DEBIT_CURRENCY')) {
                this.indicativeRatePromise = this.valueDateListPromise
                    .then(() => this.getIndicativeRatePromise());
            }
        }

        this.amountPromise = Promise.resolve(true);

        // TODO - maybe add these to the ui hash & event hash
        this.listenTo(this.parentModel, 'change:CREDIT_CURRENCY', this.creditCurrencyChange.bind(this));
        this.listenTo(this.parentModel, 'change:DEBIT_CURRENCY', this.debitCurrencyChange.bind(this));
        this.listenTo(this.parentModel, 'change:DEBIT_ACCOUNT_NUMBER', this.debitAccountChanged.bind(this));
        this.listenTo(this.parentModel, 'change:BENE_BANK_ID', this.creditAccountNumberChange.bind(this));
        this.listenTo(this.parentModel, 'change:PREADVISEWIRES', this.requestValueDateList.bind(this));
        this.listenTo(this.parentModel, 'change:BENEBANKIDENTRYMETHOD', this.requestValueDateList.bind(this));
        this.listenTo(this.parentModel, 'invalidCreditCurrencySelectedFromDebit', this.invalidCreditCurrencySelectedFromDebit);

        /*
         * When config is enabled, listen for changes to DEBIT_BANK_CODE and DEBIT_CURRENCY to
         * retrieve allowed credit currencies, also listen for a few triggers that signify
         * functionality specific to this configuration
         */
        if (this.restrictedCreditCurrenciesEnabled) {
            /*
             * if there is already an array of allowed credit currencies (like if we're modifying an
             * existing payment/template or if the form re-rendered from a driver field), then don't
             * initialize this to an empty array
             */
            if (!this.model.jsonData.allowedCreditCurrencies) {
                this.model.jsonData.allowedCreditCurrencies = [];
            }
            this.listenTo(this.parentModel, 'change:DEBIT_BANK_CODE change:DEBIT_CURRENCY change:ACCOUNTFILTER', this.updateAllowedCreditCurrenciesFromDependent);
            this.listenTo(this.parentModel, 'invalidBeneBankDefaultCurrencySelected', this.invalidBeneBankDefaultCurrencySelected);
        }

        /*
         * Once the view renders, validate the amount fields. This is to protect against FX
         * scenarios where the correct amounts are lost during the model refresh
         */
        this.listenTo(this.parentView, 'ui-loaded', () => {
            // Only render the form when all page-load calls have resolved
            this.setHasLoadedRequiredData(true);
            this.render();

            this.updateCurrencyFields(this.parentModel);
            this.amountFieldValidation();
        });

        fxPaymentUtil.evaluateUSDOnly(this.model, this.comboCollections);
    },

    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e  Jquery click handler event
     */
    toggleLock(e) {
        if (this.state === 'VIEW') {
            return;
        }
        PaymentUtil.toggleLock(e, this.model);
    },

    /**
     * @param {Boolean} - Whether or not the user is limited to USD ONLY by the fx payment util
     *
     * @return {Boolean} - Whether or not the CREDIT_CURRENCY is set to be USD ONLY
     * OR if the CREDIT_AMOUNT is locked
     */
    shouldDisableCurrency(USD_ONLY) {
        return (
            this.isPayment()
            && (USD_ONLY
                || this.model.fieldData?.CREDIT_AMOUNT?.locked
                || this.model.fieldData?.DEBIT_AMOUNT?.locked)
        );
    },

    /**
     * Toggle the disabled prop of the credit currency field
     * @param {boolean} value
     */
    toggleDisabledCreditCurrency(value) {
        this.ui.$creditCurrency.prop('disabled', value);
    },

    /*
     * Toggle the disabled prop of the credit debit entered field
     * @param {boolean} value
     */
    toggleDisabledCreditDebitEntered(value) {
        this.ui.$creditDebitEntered.prop('disabled', value);
    },

    /**
     * returns the allowed status of a credit currency or true if the config is disabled and
     * all currencies are allowed
     * @param {String} currencyToBeTested - the currency to be tested
     * @return {Boolean} - the validity of the tested currency
     */
    isAllowedCreditCurrency(currencyToBeTested) {
        return !this.restrictedCreditCurrenciesEnabled ||
            this.model.jsonData.allowedCreditCurrencies.some(currency =>
                currency.name === currencyToBeTested);
    },

    invalidCreditCurrencySelectedFromDebit() {
        if (fxPaymentUtil.USD_ONLY) {
            /*
             * In most cases this will be superfluous, and will not unintentionally enable the
             * input when it's locked. But in rare circumstances where the user is limited
             * to USD only, and currency pairs are enforced, this will allow the user to input
             * a credit amount even if an invalid credit currency is enforced
             * by debit account selection
             */
            this.ui.$creditAmount.prop('readonly', false);
            this.makeAmountRequired('CREDIT_AMOUNT', true);
            return;
        }

        // exit early if there is credit currency from bene present
        if (this.model.get('BENE_BANK_CURR_MAP')) {
            return;
        }

        this.model.set('CREDIT_CURRENCY', '');
        this.ui.$creditCurrency.comboBox('val', '');
    },

    invalidBeneBankDefaultCurrencySelected() {
        if (fxPaymentUtil.USD_ONLY) {
            /*
             * In most cases this will be superfluous, and will not unintentionally enable the
             * input when it's locked. But in rare circumstances where the user is limited
             * to USD only, and currency pairs are enforced, this will allow the user to input
             * a credit amount even if they've selected an invalid bene bank
             */
            this.ui.$creditAmount.prop('readonly', false);
            this.makeAmountRequired('CREDIT_AMOUNT', true);
            return;
        }

        // exit early if there is no credit currency present
        if (!this.model.get('CREDIT_CURRENCY')) {
            return;
        }
        this.clearDetailsFromInvalidCreditCurrency();
    },

    onAccountNumberChange(model) {
        if (fxPaymentUtil.USD_ONLY && this.isAllowedCreditCurrency('USD')) {
            model.set('CREDIT_CURRENCY', 'USD');
        }
        this.toggleDisabledCreditCurrency(this.shouldDisableCurrency(fxPaymentUtil.USD_ONLY));
    },

    /**
     * Handle the model change event for debit account attribute
     * @param {Model} model
     * @param {string} value
     */
    debitAccountNumberChange(model, value) {
        /*
         * if the restricted credit currency configuration is enabled, delay these steps until after
         * the updateAllowedCreditCurrenciesFromDependent function to see if USD is allowed
         */
        if (!this.restrictedCreditCurrenciesEnabled) {
            fxPaymentUtil.evaluateUSDOnly(model, this.comboCollections, value);
            this.onAccountNumberChange(model, value);
        }
    },

    /**
     * Handle the model change event for credit account number attribute
     * @param {Model} model
     */
    creditAccountNumberChange(model) {
        this.onAccountNumberChange(model);
        this.requestValueDateList();
    },

    /**
     * @param {string} field - name of the changed field
     * @param {Model} model - the changed model
     * @param {string} value - new value
     */
    currencyChange(field, model, value) {
        if (value === '') {
            return;
        }
        let currencyValue = value;

        /*
         * HACK NH-133696 - Upon initial selection of a beneficiary when creating a payment,
         * the currency may be set by the beneficiary country lookup. Use the CREDIT_CURRENCY_1
         * field attached to the beneficiary to ensure it's forced to be correct. This should
         * only run once however, to allow the currency to be overridden by the user if they
         * choose
         */
        if (model.get('CREDIT_CURRENCY_1') && !this.creditCurrencyOverrideApplied) {
            currencyValue = model.get('CREDIT_CURRENCY_1');
            model.set('CREDIT_CURRENCY', currencyValue);
            this.creditCurrencyOverrideApplied = true;
        }

        this.finalizeCurrencyField(field, model, currencyValue);
    },

    /*
     * Execute relevant methods to update fields and set masking where needed
     * @param {string} field - name of the changed field
     * @param {Model} model - the changed model
     * @param {string} value - new value
     */
    finalizeCurrencyField(field, model, value) {
        this.updateCurrencyFields(model);
        this.setAmountMaskingFromCurrency(field, value);

        /*
         * trigger to get updated conversions/exchange rates from currency changes
         * Note: When currency updates, the amount fields may change as well.
         * We need to make sure that we have updated necessary values before calling
         * the validation as it issues a network request to get values and will return
         * the old value causing the value to not actually be correct.
         */
        this.amountFieldValidation();
        this.showOrHideRateFields();
    },

    /**
     * Handle credit currency change event
     * @param {Model} model - the changed model
     * @param {string} value - new value
     */
    creditCurrencyChange(model, value) {
        this.currencyChange('CREDIT_AMOUNT', model, value);
    },

    /**
     * Handle debit currency change event
     * @param {Model} model - the changed model
     * @param {string} value - new value
     */
    debitCurrencyChange(model, value) {
        /*
         * In the event that the restricted currencies configuration is active, hold off on doing
         * this step until we've retrieved the allowed currencies for the debit bank as they may
         * affect the debit amount
         */
        if (!this.restrictedCreditCurrenciesEnabled) {
            this.currencyChange('DEBIT_AMOUNT', model, value);
        }
    },

    /**
     * Based on the values of credit and debit currencies show/hide and update values
     * @param {Model} model - the changed model
     */
    updateCurrencyFields(model) {
        const creditCurrency = model.get('CREDIT_CURRENCY') || model.fieldData.CREDIT_CURRENCY.value;
        const debitCurrency = model.get('DEBIT_CURRENCY') || model.fieldData.DEBIT_CURRENCY.value;

        this.toggleDisabledCreditCurrency(this.shouldDisableCurrency(fxPaymentUtil.USD_ONLY));

        if (creditCurrency === debitCurrency || fxPaymentUtil.USD_ONLY) {
            this.shouldBeHidden('DEBIT_AMOUNT');
            this.shouldBeHidden('CREDIT_DEBIT_ENTERED');
            model.set('ENTERED_AMOUNT_FLAG', 'C');
        } else {
            if (model.get('TYPE') === 'DRAFT') {
                model.set('Debit_Currency', model.get('DEBIT_CURRENCY'));
            }

            this.shouldBeVisible('CREDIT_DEBIT_ENTERED');
            this.shouldBeVisible('DEBIT_AMOUNT');

            model.set('CREDIT_DEBIT_ENTERED', model.get('ENTERED_AMOUNT_FLAG') === 'D' ? 'Debit' : 'Credit');

            this.setCurrencyState(model);
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            let debitAmt;
            this.afterRender();

            this.ui.$creditDebitEntered.comboBox();

            this.ui.$creditCurrency.comboBox({
                // hide the search box
                minimumResultsForSearch: -1,

                formatSelection(item) {
                    // display only the currency abbreviation
                    return item.id;
                },
            });

            /*
             * if the restricted credit currencies configuration is active, do some stuff
             * Do not validate when view or repair because the currency field cannot be changed
             */
            if (this.restrictedCreditCurrenciesEnabled
                && this.state !== 'VIEW' && this.state !== 'REPAIR') {
                // if credit currencies exist, populate the credit currencies dropdown
                if (this.model.jsonData.allowedCreditCurrencies.length) {
                    const theAllowedCurrencies = this.model.jsonData.allowedCreditCurrencies;
                    this.populateCreditCurrencyDropdownWithAllowedCurrencies(theAllowedCurrencies);
                }
                // if there is a currently selected credit currency, ensure it's valid
                if (this.model.get('CREDIT_CURRENCY')) {
                    // if it's valid, update the selected credit currency
                    if (this.isAllowedCreditCurrency(this.model.get('CREDIT_CURRENCY'))) {
                        this.ui.$creditCurrency.comboBox('val', this.model.get('CREDIT_CURRENCY'));
                    } else { // if it's not valid, clear it among other things
                        // update the data, state, and appearance of the credit currency dropdown
                        this.model.set('CREDIT_CURRENCY', '');
                        this.ui.$creditCurrency.comboBox('val', '');
                        this.model.jsonData.fieldInfoList.find(field => field.name === 'CREDIT_CURRENCY').value = '';

                        // show the debit amount input
                        this.shouldBeVisible('CREDIT_DEBIT_ENTERED');
                        this.shouldBeVisible('DEBIT_AMOUNT');

                        // clear any indicative rates and any values in the debit amount input
                        this.model.set({
                            INDICATIVERATE: '',
                            INDICATIVEAMOUNT: '',
                            DEBIT_AMOUNT: '',
                        });

                        this.ui.$indicativeRate.hide();

                        // inform the user that the credit currency was changed with a modal
                        dialog.alert(locale.get('RTGS.FxCurrencyPair.CurrencyUpdated.text'), locale.get('RTGS.FxCurrencyPair.CurrencyUpdated.title'));

                        // call out that the debit currency changed
                        this.currencyChange('DEBIT_AMOUNT', this.model, this.model.get('DEBIT_CURRENCY'));
                    }
                }
            }

            this.toggleDisabledCreditCurrency(this.shouldDisableCurrency(fxPaymentUtil.USD_ONLY));
            this.ui.$popovers.popover({
                trigger: 'focus hover',
                html: true,
            });
            if (this.isPayment()) {
                let amtBlock = this.$el.find('[data-hook="indicative-rate"]').parent('div');
                if (this.ui.$creditDebitEntered.val() === 'Credit') {
                    amtBlock = this.$el.find('[data-validate="CREDIT_AMOUNT"]');
                }
                PaymentUtil.showTemplateMaxAmtForPayment(this.parentModel, amtBlock, this.parentModel.get('TEMPLATE_MAX_AMOUNT') !== '', true);
            }

            if (this.state === 'VIEW' && this.model.get('TYPE') === 'INTL') {
                if (this.model.get('CMB_DBCR_FLAG') === 'C') {
                    if (util.isEmpty(this.model.get('DEBIT_AMOUNT').trim())) {
                        debitAmt = this.model.get('FUNCTION') === 'TMPL' ? '0.00' : this.model.get('INDICATIVEAMOUNT');
                        this.$('[data-amount-type="DEBIT_VIEW"]').text(`${debitAmt} ${this.model.get('DEBIT_CURRENCY')}`);
                    }
                } else if (this.model.get('CMB_DBCR_FLAG') === 'D') {
                    if (util.isEmpty(this.model.get('DEBIT_AMOUNT').trim())) {
                        this.$('[data-amount-type="DEBIT_VIEW"]').text(`${formatter.formatCurrency(this.model.get('CMB_DEBIT_AMOUNT'))} ${this.model.get('DEBIT_CURRENCY')}`);
                    }
                    if (util.isEmpty(this.model.get('CREDIT_AMOUNT').trim())) {
                        this.$('[data-amount-type="CREDIT_VIEW"]').text(`${formatter.formatCurrency(this.model.get('INDICATIVE_AMOUNT'))} ${this.model.get('CREDIT_CURRENCY')}`);
                    }
                }
            }

            /* FIXME:
             * All 3 of these 'if' statements could be collapsed into one singular conditional.
             * It would likely require the use of some verbose variables, and a unit-testable
             * function or two though to keep it from being messy and unclear.
             */
            if (this.state !== 'VIEW' && this.model.get('TYPE') === 'INTL' && !fxPaymentUtil.USD_ONLY) {
                if (this.model.get('CMB_DBCR_FLAG') === 'C' && this.isPayment() && this.state === 'MODIFY') {
                    if (util.isEmpty(this.model.get('DEBIT_AMOUNT').trim())) {
                        debitAmt = this.model.get('INDICATIVEAMOUNT');
                        this.$('[data-amount-type="DEBIT"]').val(debitAmt);
                    }
                }
            }
        }
    },

    isModifyOrFromTemplate() {
        return (this.state === 'MODIFY' || this.model.get('FROMTEMPLATE') === '1');
    },

    /**
     * overrides baseWidget isReadyToRender
     */
    isReadyToRender() {
        const promiseArray = [];

        if (this.isModifyOrFromTemplate() && this.restrictedCreditCurrenciesEnabled) {
            promiseArray.push(this.allowedCreditCurrenciesPromise);
        }

        // if this is not a payment, do not consider the following promises for the promise array
        if (!this.isPayment()) {
            return Promise.all(promiseArray);
        }

        promiseArray.push(this.valueDateListPromise);
        promiseArray.push(this.indicativeRatePromise);
        promiseArray.push(this.amountPromise);

        /*
         * Here we need to wait on the indicative rate promise on screen render otherwise
         * the amount listeners will be overwritten when re-rendering the fx widget section.
         * This is true except for when creating a new payment from freeform entry. A new
         * (not from template) payment will have insufficient data and the promise will
         * return a failure code thus never satisfying the resolve condition.
         */
        if (this.model.get('FUNCTION') === 'INST' && this.state !== 'VIEW'
            && (this.isModifyOrFromTemplate())) {
            promiseArray.push(this.indicativeRatePromise);
        }

        return Promise.all(promiseArray);
    },

    isReadyToSave() {
        /*
         * need to clear out debit/credit amount if indicative amount is shown
         * indicative amount is copied to the debit/credit amt for display
         */
        if (this.isPayment()) {
            const isCreditSelected = this.parentModel.get('CREDIT_DEBIT_ENTERED') === 'Credit';
            const flags = this.getConfigurationFlags(this.parentModel);
            if (flags.showIndicativeAmt && flags.showIndicativeRate) {
                if (isCreditSelected) {
                    this.parentModel.set('DEBIT_AMOUNT', ' ');
                } else {
                    this.parentModel.set('CREDIT_AMOUNT', ' ');
                }
            }
            // if indicative rate & indicative amount, then clear out contra amount
            return Promise.all([
                this.amountPromise,
                this.valueDateListPromise,
                this.indicativeRatePromise,
            ]);
        }
        return Promise.resolve();
    },

    isPayment() {
        return (this.model.context.actionContext?.functionCode === 'INST'
            || this.model.context.actionData?.functionCode === 'INST'
            || this.model.context?.functionCode === 'INST'
            || this.model.jsonData?.typeInfo?.functionCode === 'INST');
    },

    initialSetup() {
        const model = this.parentModel;
        const { fieldData } = this;
        let lockedFields;

        // setup locked fields
        if (model.has('LOCKED_FIELDS')) {
            lockedFields = model.get('LOCKED_FIELDS');
            if (!lockedFields && fieldData.LOCKED_FIELDS.value !== null) {
                lockedFields = fieldData.LOCKED_FIELDS.value;
            }
            lockedFields = lockedFields.split(',');
            lockedFields.forEach((fieldName) => {
                if (model.has(fieldName)) {
                    fieldData[fieldName].locked = true;
                    if (this.model.context.functionCode !== 'TMPL' && this.model.context.functionCode !== 'BHTMPL') {
                        fieldData[fieldName].protected = true;
                    }
                }
            });
        }

        if (model.get('ENTERED_AMOUNT_FLAG') === 'C' || (fieldData.ENTERED_AMOUNT_FLAG && fieldData.ENTERED_AMOUNT_FLAG.value === 'C')) {
            model.set('CREDIT_DEBIT_ENTERED', 'Credit');
        } else {
            model.set('CREDIT_DEBIT_ENTERED', 'Debit');
        }
    },

    afterRender() {
        const entryMethod = this.model.get('ENTRYMETHOD');
        const creditDebitEntered = this.model.get('CREDIT_DEBIT_ENTERED');
        const isFromTemplate = this.model.get('FROMTEMPLATE') === '1';

        // TODO: can we just render it correctly the first time?
        this.creditDebitEnteredChanged();

        amountFormatHelper.setupInputMaskWithCurrency(this, 'DEBIT_AMOUNT', this.model.get('DEBIT_CURRENCY'));
        amountFormatHelper.setupInputMaskWithCurrency(this, 'CREDIT_AMOUNT', this.model.get('CREDIT_CURRENCY'));

        this.showOrHideRateFields();

        if ((entryMethod === '1' || entryMethod === '0') && this.state !== 'VIEW' && isFromTemplate && this.model.get('TARGETFUNCTION') === 'INST') {
            /*
             * if this payment-from-template or payment-from-payment
             * trigger an amount field validation to get current rate values
             */
            this.amountFieldValidation();
        } else if (entryMethod === '0' && isFromTemplate && this.model.get('TARGETFUNCTION') === 'TMPL') {
            /*
             * if this is a template-from-payment or template-from-template...
             * determine what was entered and clear other amount value
             */
            if (creditDebitEntered === 'Credit') {
                this.model.set('DEBIT_AMOUNT', '');
                this.fieldData.DEBIT_AMOUNT.value = '';
            } else if (creditDebitEntered === 'Debit') {
                this.model.set('CREDIT_AMOUNT', '');
                this.fieldData.CREDIT_AMOUNT.value = '';
            }
        }
        this.validateAmountLocks(this.model);
    },

    getConfigurationFlags(model) {
        const creditAmount = model.get('CREDIT_AMOUNT');
        const creditCurrency = model.get('CREDIT_CURRENCY');
        const debitAmount = model.get('DEBIT_AMOUNT');
        const debitCurrency = model.get('DEBIT_CURRENCY');
        const exchangeRate = model.get('EXCHANGE_RATE');
        const fxRateType = (model.fieldData.FXRATETYPE) ? model.fieldData.FXRATETYPE.value : '0';
        const isCreditSelected = (model.get('CREDIT_DEBIT_ENTERED') === 'Credit');
        let allowContractIdOverride = (model.fieldData.ALLOWCONTRACTRATEOVERRIDE && model.fieldData.ALLOWCONTRACTRATEOVERRIDE.value === '1');
        let exchangeRateReadOnly = true;
        let showContractId = false;
        let showExchangeRate = false;
        let showIndicativeAmt = false;
        let showIndicativeRate = false;

        const crossCurr = (!util.isEmpty(model.get('DEBIT_ACCOUNT_NUMBER')) && (debitCurrency !== creditCurrency));

        if (crossCurr) {
            if (util.isEmpty(model.get('EXCHANGE_RATE'))) {
                if (isCreditSelected) {
                    if (util.isEmpty(creditAmount) === false
                        && util.isEmpty(creditCurrency) === false
                        && util.isEmpty(debitCurrency) === false) {
                        showIndicativeRate = true;
                        showIndicativeAmt = true;
                    }
                } else if (util.isEmpty(debitAmount) === false
                    && util.isEmpty(debitCurrency) === false
                    && util.isEmpty(creditCurrency) === false) {
                    showIndicativeRate = true;
                    showIndicativeAmt = true;
                }
            } else {
                showIndicativeRate = false;
                showIndicativeAmt = false;
                showExchangeRate = !util.isEmpty(exchangeRate);
                showContractId = true;
                this.shouldBeReadOnly('CREDIT_DEBIT_ENTERED', false);
            }
            // exchange rate table
            if (fxRateType === '0') {
                showContractId = true;
                allowContractIdOverride = true;
            } else if (fxRateType === '1' && allowContractIdOverride) {
                showContractId = true;
            }
        }

        if (showContractId && !util.isEmpty(model.get('EXCHANGE_RATE_CONTRACTID')) && userInfo.isSMB) {
            showExchangeRate = !util.isEmpty(exchangeRate);
            exchangeRateReadOnly = false;
            showIndicativeRate = false;
            showIndicativeAmt = false;
        }

        return {
            showExchangeRate,
            exchangeRateReadOnly,
            showIndicativeRate,
            showIndicativeAmt,
            showContractId,
            allowContractIdOverride,
            crossCurr,
            fxRateType,
        };
    },

    validateAmountLocks(model) {
        // update lock icon if needed
        if (this.ui.$creditAmount.length > 0) {
            PaymentUtil.runChangeAmount(this.ui.$creditAmount[0], model);
        }
        if (this.ui.$debitAmount.length > 0) {
            PaymentUtil.runChangeAmount(this.ui.$debitAmount[0], model);
        }
        /*
         * if we are in a payment and there are locked fields and the locked fields
         * are one of the amount fields, then we must also disable debit/credit selector
         */
        const functionCode = fxPaymentUtil.getFunctionCode(model);
        if (functionCode !== 'TMPL'
            && functionCode !== 'BHTMPL'
            && model.fieldData
            && (model.fieldData.CREDIT_AMOUNT.locked === true
            || model.fieldData.DEBIT_AMOUNT.locked === true)) {
            this.toggleDisabledCreditDebitEntered(true);
        }
    },

    valueDateChanged() {
        if (moment(this.parentModel.get('VALUE_DATE')).isValid()) {
            this.showOrHideRateFields();
        }
    },

    showOrHideRateFields() {
        if (this.isPayment()) {
            const flags = this.getConfigurationFlags(this.parentModel);
            if (this.state !== 'VIEW' && flags.showIndicativeRate && flags.showIndicativeAmt && !util.isEmpty(this.parentModel.get('TYPE'))) {
                this.indicativeRatePromise = this.getIndicativeRatePromise();
            }
            if (this.state === 'VIEW') {
                this.shouldBeHidden('CREDIT_DEBIT_ENTERED');
                this.ui.$contractIdView.toggleClass('hide', !(flags.showContractId && !util.isEmpty(this.parentModel.get('EXCHANGE_RATE_CONTRACTID'))));
                this.ui.$exchangeRateView.toggleClass('hide', !flags.showExchangeRate);
                this.ui.$indicativeRateView.toggleClass('hide', !flags.showIndicativeRate);
                // We check for a string here to ensure that the UI elements have rendered
            } else if (typeof this.ui.$addContractID !== 'string') {
                this.ui.$addContractID.toggleClass('hide', !(flags.showContractId && util.isEmpty(this.parentModel.get('EXCHANGE_RATE_CONTRACTID'))));
                this.ui.$removeContractID.toggleClass('hide', !(flags.showContractId && !util.isEmpty(this.parentModel.get('EXCHANGE_RATE_CONTRACTID'))));
                this.ui.$contractIDContainer.toggleClass('hide', !(flags.showContractId && !util.isEmpty(this.parentModel.get('EXCHANGE_RATE_CONTRACTID'))));
                if (flags.showContractId && !util.isEmpty(this.parentModel.get('EXCHANGE_RATE_CONTRACTID'))) {
                    this.ui.$contractID.text(this.parentModel.get('EXCHANGE_RATE_CONTRACTID'));
                }
                this.ui.$indicativeRate.toggleClass('hide', !flags.showIndicativeRate);
                this.ui.$exchangeRate.toggleClass('hide', !flags.showExchangeRate);
                this.shouldBeReadOnly('EXCHANGE_RATE', flags.showExchangeRate && flags.exchangeRateReadOnly);
            }
        } else if (this.state === 'VIEW') {
            this.shouldBeHidden('CREDIT_DEBIT_ENTERED');
        }
    },

    /**
     *
     * @param {Object} oldData
     * @param {Object} newData
     * @param {String} debitSelected
     * @returns {Boolean}
     */
    compareIndicativeData(oldData, newData, debitSelected) {
        const sanitizedOldData = {
            ...oldData,
            [debitSelected === 'D' ? 'creditAmount' : 'debitAmount']: '',
        };
        const sanitizedNewData = {
            ...newData,
            [debitSelected === 'D' ? 'creditAmount' : 'debitAmount']: '',
        };
        return util.isEqual(sanitizedOldData, sanitizedNewData);
    },

    /**
     * Create the indicative rate promise
     * @return {Promise}
     */
    getIndicativeRatePromise() {
        const model = this.parentModel;

        const { state } = this;
        let request;

        return new Promise((resolve) => {
            const url = services.generateUrl(constants.URL_GETINDICATIVERATE_ACTION);
            const isDebitSelected = model.get('CREDIT_DEBIT_ENTERED') === 'Debit';
            const type = model.get('TYPE');
            let enteredAmountFlag;
            if (state === 'VIEW') {
                enteredAmountFlag = model.get('ENTERED_AMOUNT_FLAG');
            } else {
                enteredAmountFlag = isDebitSelected ? 'D' : 'C';
            }

            const data = {
                enteredAmountFlag,
                creditCurrency: model.get('CREDIT_CURRENCY'),
                creditAmount: formatter.unformatNumber(model.get('CREDIT_AMOUNT')) ?? '',
                debitCurrency: model.get('DEBIT_CURRENCY'),
                debitAmount: formatter.unformatNumber(model.get('DEBIT_AMOUNT')) ?? '',
                valueDate: model.get('VALUE_DATE'),
                debitAcctNumber: model.get('DEBIT_ACCOUNT_NUMBER'),
                debitBankCode: model.get('DEBIT_BANK_CODE'),
                status: model.get('STATUS') || 'EN',
                typeCode: type,
                actionMode: state === 'VIEW' ? 'SELECT' : state,
            };

            // No reason to make request if empty or null values
            if (
                data.creditCurrency === ''
                || data.debitCurrency === ''
                || (data.creditAmount === null
                && data.debitAmount === null)) {
                resolve();
                return;
            }

            if (this.compareIndicativeData(this.indicativeRatePromiseData, data, isDebitSelected)) {
                request = this.indicativeRatePromise;
            } else {
                // save on view so that we can check for a stale request
                this.indicativeRatePromiseData = data;
                request = http.post(url, data);
            }

            request.then(
                (result) => {
                    // check for a stale request
                    if (!util.isEqual(this.indicativeRatePromiseData, data)) {
                        /*
                         * Even though this request is stale (and we do not want to update the
                         * model with out of sync data) the promise still needs to resolve such
                         * that isReadyToRender and isReadyToSave will accurately reflect the
                         * ready state.
                         */
                        resolve();
                        return;
                    }
                    const flags = this.getConfigurationFlags(model);

                    model.set('INDICATIVERATE', result.indicativeRate);
                    model.set('INDICATIVEAMOUNT', result.indicativeAmount);

                    if ((typeof this.ui.$indicativeRateView !== 'string') && flags.showIndicativeRate && flags.showIndicativeAmt) {
                        if (isDebitSelected) {
                            if (state === 'VIEW') {
                                this.$('[data-amount-type="CREDIT_VIEW"]').text(`${result.indicativeAmount} ${model.get('CREDIT_CURRENCY')}`);
                            } else {
                                model.set('CREDIT_AMOUNT', result.indicativeAmount);
                            }
                        } else if (state === 'VIEW') {
                            this.$('[data-amount-type="DEBIT_VIEW"]').text(`${result.indicativeAmount} ${model.get('DEBIT_CURRENCY')}`);
                        } else {
                            model.set('DEBIT_AMOUNT', result.indicativeAmount);
                        }
                        // Need to remove the Indicative Rate text if there is no rate.
                        if (this.state === 'VIEW') {
                            this.ui.$indicativeRateView.toggleClass('hide', !flags.showIndicativeRate);
                        } else if (this.$('[data-hook="indicative-rate"]').length) {
                            this.$('[data-hook="indicative-rate"]').toggleClass('hide', (!flags.showIndicativeRate || util.isNullOrUndefined(result.indicativeRate)));
                        }
                    }
                    resolve(result);
                },
                function () {
                    log.error(this);
                    resolve();
                },
            );
        });
    },

    setAmountMaskingFromCurrency(fieldname, currencyCode) {
        const model = this.parentModel;
        const precision = Decimals.getNumberOfDecimalPlaces(currencyCode);
        if (precision === 0) {
            const initialValue = util.isEmpty(model.get(fieldname)) ? '0' : model.get(fieldname);
            const roundedValue = Math.round(parseFloat(initialValue.replace(/[^\d.]/g, '')));
            // countries with 0 decimal fails with trim() function, set value back as string
            model.set(fieldname, `${roundedValue}`);
        }

        amountFormatHelper.setupInputMaskWithCurrency(this, fieldname, currencyCode);
    },

    makeAmountRequired(amountField, required, onlyCurrency = false) {
        let localRequired = required;
        const $field = this.$(`[name="${amountField}"]`);
        const isCredit = (amountField === 'CREDIT_AMOUNT');
        const functionCode = fxPaymentUtil.getFunctionCode(this.model);
        const isNoDefaultCurrTemplate = functionCode === 'TMPL' && isCredit;
        const relatedCurrencyField = (isCredit ? 'CREDIT_CURRENCY' : 'DEBIT_CURRENCY');

        if (!this.isPayment() && !this.model.get('SCHEDULED') && !this.parentModel.get('recur')) {
            // Amount is not required for non-scheduled templates
            localRequired = false;
        }

        $field.prop('required', localRequired);
        $field.closest('.widget-field-container').toggleClass('required', localRequired);

        if (localRequired) {
            if (!onlyCurrency) {
                this.parentModel.addValidator(
                    amountField,
                    {
                        description: this.fieldData[amountField].fieldLabel,
                        exists: true,
                        minValue: 0.01,
                    },
                );
            }
            if (!isNoDefaultCurrTemplate && (this.isPayment() || relatedCurrencyField !== 'CREDIT_CURRENCY')) {
                this.parentModel.addValidator(
                    relatedCurrencyField,
                    {
                        description: this.fieldData[relatedCurrencyField].fieldLabel,
                        exists: true,
                    },
                );
            }
            // make sure there is a help block for the add-on currency field
            const $helpBlockField = $field.closest('.widget-field-container').find('.help-block');
            if ($helpBlockField.length === 1) {
                $helpBlockField.clone().attr({
                    'data-validate': relatedCurrencyField,
                    'data-addonField': true,
                }).insertAfter($helpBlockField);
            }
        } else {
            if (!onlyCurrency) {
                this.parentModel.removeValidator(amountField);
            }
            /*
             * Validation on credit currency should not
             * be removed regardless of currency selected
             * unless default credit currency is off and
             * type is template
             */
            if ((isNoDefaultCurrTemplate) || !isCredit) {
                this.parentModel.removeValidator(relatedCurrencyField);
            }
            /*
             * When the field is no longer mandatory but was previously mandatory and invalid,
             * we want to invoke a change event so that the field validator executes and any
             * standard mandatory field error messages are removed.
             */
            $field.change();
        }
        return this;
    },

    /**
     * @param {jQuery Event} changeEvent - In the event that a user manually updated the amount
     * field, this is the relevant change event
     */
    amountFieldValidation(changeEvent) {
        // exit here if there is already an amount promise being made
        if (this.amountFieldValidationInProgress) {
            return;
        }

        this.amountFieldValidationInProgress = true;
        const { state } = this;
        const model = this.parentModel;

        const {
            functionCode,
            productCode,
            typeCode,
        } = this.model.jsonData.typeInfo;

        const creditCurrency = model.get('CREDIT_CURRENCY');
        const debitCurrency = model.get('DEBIT_CURRENCY');
        const fieldName = this.parentModel.get('CREDIT_DEBIT_ENTERED') === 'Credit' ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT';

        model.set({
            FUNCTION: functionCode,
            PRODUCT: productCode,
            TYPE: typeCode,
        });
        this.amountPromise = new Promise((resolve) => {
            fxFieldValidation.doFieldValidation(this, model, fieldName, state).then(() => {
                this.showOrHideRateFields();

                let applicableCurrency = 'CREDIT_CURRENCY';

                if (fieldName === 'DEBIT_AMOUNT') {
                    applicableCurrency = 'DEBIT_CURRENCY';
                    this.setAmountMaskingFromCurrency('CREDIT_AMOUNT', creditCurrency);
                } else {
                    this.setAmountMaskingFromCurrency('DEBIT_AMOUNT', debitCurrency);
                }

                /*
                 * If this call was made as a direct result of a user manually changing the amount
                 * input, then update header and footer page summaries to reflect the new amounts.
                 * These updates are normally run upon change of the input but in THIS case the
                 * getIndicativeRate call may not have finished in time. So we update them here.
                 */
                if (this.state !== 'VIEW' && changeEvent) {
                    rtgsHeaderFooter.setTotal(this.parentModel, fieldName, applicableCurrency);
                    // trigger the changeamount for lock icon
                    PaymentUtil.changeAmount(changeEvent, this.parentModel);
                    PaymentUtil.updateRTGSSummaryTotal(this.parentModel, this.parentView, fieldName.split('_')[0]);
                }
                this.amountFieldValidationInProgress = false;
                resolve();
            });
        });
    },

    setCurrencyState(model) {
        const creditOrDebit = this.ui.$creditDebitEntered.val();
        const isFromTemplate = this.model.get('FROMTEMPLATE') === '1';

        // NH-66904 User is unable to enter amount when USD currencies are the same and not USD
        const creditReadOnly = this.isCreditAmountReadOnly(model, creditOrDebit);

        // disable either credit or debit amount field based on CREDIT_DEBIT_ENTERED selection
        this.shouldBeReadOnly('CREDIT_AMOUNT', (creditReadOnly || creditOrDebit === 'Debit'));
        this.shouldBeReadOnly('DEBIT_AMOUNT', (creditOrDebit === 'Credit'));

        if (model.get('TYPE') !== 'MULTIBK') {
            if (isFromTemplate && this.model.get('TARGETFUNCTION') === 'INST' && this.model.get('CREDIT_CURRENCY') === '') {
                this.makeAmountRequired('CREDIT_AMOUNT', true, true);
            } else {
                this.makeAmountRequired('CREDIT_AMOUNT', (creditOrDebit === 'Credit'));
            }

            this.makeAmountRequired('DEBIT_AMOUNT', (creditOrDebit === 'Debit'));
        }
    },

    /**
     * Helper method the evaluate creditOrDebit, whether currencies are the same
     * and making sure debit account is not USD only
     * @param {Model} model
     * @param {string} creditOrDebit
     * @returns {boolean}
     */
    isCreditAmountReadOnly(model, creditOrDebit) {
        const functionCode = fxPaymentUtil.getFunctionCode(model);
        return ((creditOrDebit === 'Debit'
            && model.get('CREDIT_CURRENCY') !== model.get('DEBIT_CURRENCY')
            && fxPaymentUtil.USD_ONLY)
            || (model.fieldData.CREDIT_AMOUNT.locked === true && functionCode !== 'TMPL'));
    },

    setInitialCreditDebitValue() {
        this.initialEntry = false;
        return this.parentModel.get('CREDIT_DEBIT_ENTERED');
    },

    creditDebitEnteredChanged() {
        const model = this.parentModel;

        const creditOrDebit = this.initialEntry ? this.setInitialCreditDebitValue()
            : this.ui.$creditDebitEntered.val();

        const creditCurrency = model.get('CREDIT_CURRENCY');
        const debitAccountNumber = model.get('DEBIT_ACCOUNT_NUMBER');
        const debitCurrency = model.get('DEBIT_CURRENCY');

        if (util.isEmpty(debitAccountNumber)
            || (!util.isEmpty(debitAccountNumber) && debitCurrency === creditCurrency)) {
            this.shouldBeHidden('DEBIT_AMOUNT');
            this.shouldBeHidden('CREDIT_DEBIT_ENTERED');
            model.set('ENTERED_AMOUNT_FLAG', 'C');
        } else if (debitCurrency !== creditCurrency) {
            this.shouldBeVisible('CREDIT_DEBIT_ENTERED');
            model.set('ENTERED_AMOUNT_FLAG', creditOrDebit === 'Credit' ? 'C' : 'D');

            /*
             * Clearing amounts is easier than assuming what the amount would be when
             * credit/debit changes.
             */
            const previousAttributes = model.previousAttributes();

            if (!util.isEmpty(previousAttributes.CREDIT_DEBIT_ENTERED)) {
                if (previousAttributes.CREDIT_DEBIT_ENTERED !== creditOrDebit) {
                    model.set({
                        CREDIT_AMOUNT: '',
                        DEBIT_AMOUNT: '',
                        INDICATIVEAMOUNT: '',
                    });
                    this.model.fieldData.CREDIT_AMOUNT.value = '';
                    this.model.fieldData.DEBIT_AMOUNT.value = '';

                    /*
                     * HACK NH-153490 - See comment on ticket for write-up, but basically this
                     * "max amount" component doesn't exist properly (ideally it would be added
                     * on the field by a template within the field helper contingent upon meta
                     * data) on either the credit or debit inputs since it was originally
                     * created to be added via policy file. At the moment, the best we can do
                     * with the time allotted is clean up the previously hacky css rules, and
                     * ensure the jquery run by the showTemplateMaxAmtForPayment function
                     * properly places the component in the DOM.
                     */
                    if (this.isPayment()) {
                        const debitAmtBlock = this.$el.find('[data-hook="indicative-rate"]').parent('div');
                        const creditAmtBlock = this.$el.find('[data-validate="CREDIT_AMOUNT"]');
                        if (creditOrDebit === 'Credit') {
                            PaymentUtil.showTemplateMaxAmtForPayment(
                                this.parentModel,
                                debitAmtBlock, false, true,
                            );
                            PaymentUtil.showTemplateMaxAmtForPayment(this.parentModel, creditAmtBlock, this.parentModel.get('TEMPLATE_MAX_AMOUNT') !== '', true);
                        } else {
                            PaymentUtil.showTemplateMaxAmtForPayment(
                                this.parentModel,
                                creditAmtBlock, false, true,
                            );
                            PaymentUtil.showTemplateMaxAmtForPayment(this.parentModel, debitAmtBlock, this.parentModel.get('TEMPLATE_MAX_AMOUNT') !== '', true);
                        }
                    }
                } else if (!this.isPayment()) {
                    if (creditOrDebit === 'Credit') {
                        model.set({
                            DEBIT_AMOUNT: '',
                        });
                        this.model.fieldData.DEBIT_AMOUNT.value = '';
                    } else {
                        model.set({
                            CREDIT_AMOUNT: '',
                        });
                        this.model.fieldData.CREDIT_AMOUNT.value = '';
                    }
                }
            }
        }
        this.setCurrencyState(model);
        this.validateAmountLocks(model);
    },

    creditAmountChanged(e) {
        if (fxPaymentUtil.USD_ONLY) {
            this.model.set('DEBIT_AMOUNT', e.target.value);
        }
        this.amountChanged('CREDIT_AMOUNT', 'CREDIT_CURRENCY', e);
    },

    debitAmountChanged(e) {
        this.amountChanged('DEBIT_AMOUNT', 'DEBIT_CURRENCY', e);
    },

    amountChanged(amountField, currencyField, e) {
        this.setDefaultAmount(amountField);
        if (this.state !== 'VIEW') {
            // if currencies are missing, don't validate, just update the footer
            if (!this.isPayment() || (this.model.get('CREDIT_CURRENCY') && this.model.get('DEBIT_CURRENCY'))) {
                this.amountFieldValidation(e);
            } else {
                rtgsHeaderFooter.setTotal(this.parentModel, amountField, currencyField);
            }
        } else {
            rtgsHeaderFooter.setTotal(this.parentModel, amountField, currencyField);

            // trigger the changeamount for lock icon
            PaymentUtil.changeAmount(e, this.parentModel);
        }
    },

    debitAccountChanged() {
        const model = this.parentModel;
        this.requestValueDateList();
        this.debitAccountNumberChange(model);
        fxFieldValidation.doFieldValidation(this, model, 'DEBIT_CURRENCY', this.state).then(() => {
            // TODO - what should be happening here? Empty for a reason?
        });
    },

    /*
     * CREDIT_CURRENCY has specific rules for validation in the case of wire intl templates.
     * specifically, whenever a CREDIT_AMOUNT is entered, CREDIT_CURRENCY becomes required
     * @param {Boolean} addValidator - whether or not to add/remove this validator
     */
    toggleCreditCurrencyValidator(addValidator) {
        // return early if this is a payment
        if (this.isPayment()) {
            return;
        }

        // insert the "help block" for the credit currency so we can show validation errors
        const $creditAmountHelpBlock = this.$('[name="CREDIT_AMOUNT"]').closest('.widget-field-container').find('.help-block');
        if ($creditAmountHelpBlock.length === 1) {
            $creditAmountHelpBlock.clone().attr({
                'data-validate': 'CREDIT_CURRENCY',
                'data-addonField': true,
            }).insertAfter($creditAmountHelpBlock);
        }

        if (addValidator) {
            this.model.addValidator(
                'CREDIT_CURRENCY',
                {
                    description: this.fieldData.CREDIT_CURRENCY.fieldLabel,
                    exists: true,
                },
            );
        } else {
            this.model.removeValidator('CREDIT_CURRENCY');
        }
    },

    /**
     * Due to the amount field being contained in the fx widget, this assures that when the
     * amount is updated to an empty or invalid value, that the model and fieldData values
     * are kept in sync with the UI.
     *
     * @param {string} amountField
     */
    setDefaultAmount(amountField) {
        const model = this.parentModel;
        const amount = model.get(amountField);

        if (util.isNaN(amount) || util.isEmpty(amount)) {
            if (amountField === 'CREDIT_AMOUNT') {
                this.toggleCreditCurrencyValidator(false);
            }
            model.fieldData[amountField].value = '';
            model.set(amountField, '');
        } else if (amountField === 'CREDIT_AMOUNT') {
            this.toggleCreditCurrencyValidator(true);
        }
    },

    handleBusinessDaysResponse(result) {
        const model = this.parentModel;
        const { userSetValueDate } = result;
        let $valueDate;
        let date = result.earliestDay;
        let { tranDate } = result;

        date = moment(date).local().format(userInfo.getDateFormat());
        tranDate = moment(tranDate).local().format(userInfo.getDateFormat());

        this.processingDates.daysForward.shift();
        this.processingDates.daysForward.push(result.maxForwardDays);

        this.processingDates.processingDays.shift();
        this.processingDates.processingDays.push(result.businessDays);

        this.processingDates.cutOffTimes.shift();
        this.processingDates.cutOffTimes.push(result.cutoff);

        // display cutoff if returned and config allows
        if (result.cutoffDateTimeTz && PaymentUtil.isReadyValueDateRetrieval(model)) {
            model.set('CUTOFF_INFO', result.cutoffDateTimeTz);
        }

        // remove previous blocked dates
        this.processingDates.blockedDates.splice(0, this.processingDates.blockedDates.length);
        if (result.holidays.length > 0) {
            this.processingDates.blockedDates.push(...result.holidays);
        }

        // find the value date widget in the (parent) view
        $valueDate = $('[data-hook="value-date"]');

        // if the value date widget isn't found, use the input date
        if ($valueDate.length === 0) {
            $valueDate = $('[data-hook="getInputDate"]');
            if ($valueDate.length === 0) {
                return;
            }
        }

        if ($valueDate.data('daterangepicker')) {
            $valueDate.data('daterangepicker').updateCalendars({
                blockedDates: this.processingDates.blockedDates,
                daysBack: date,
                daysForward: this.processingDates.daysForward[0],
                startDate: date,
            });
        }

        if (userSetValueDate) {
            date = moment(userSetValueDate).local().format(userInfo.getDateFormat());
        }

        if (PaymentUtil.isReadyValueDateRetrieval(model)) {
            model.set('VALUE_DATE', date);
            model.set('TRAN_DATE', tranDate);

            // make sure the UI values are updated
            $('[name="VALUE_DATE"]').val(date);
            $('[name="TRAN_DATE"]').val(tranDate);
        }

        /*
         * HACK: NH-63018 - directly update our datepicker inputfield after
         * we get holidays (ui hash isn't avaliable)
         */
        const $dateInputField = $('[name="VALUE_DATE"]');

        if (dateUtil.isDayOff(
            this.processingDates.processingDays[0],
            this.processingDates.blockedDates,
            moment($dateInputField.val()).local(),
        )) {
            $dateInputField.val(moment(result.earliestDay)
                .local().format(userInfo.getDateFormat()));
        }
    },

    populateMissingData(model) {
        const fields = [
            'DEBIT_BANK_CODE',
            'DEBIT_ACCOUNT_NUMBER',
            'DEBIT_COUNTRY',
            'DEBIT_CURRENCY',
            'BENE_BANK_CODE',
            'CREDIT_AMOUNT',
            'DEBIT_AMOUNT',
            'CREDIT_CURRENCY',
        ];
        if (model && model.fieldData) {
            util.each(fields, (field) => {
                if (!model.get(field) && model.fieldData[field]
                    && model.fieldData[field].value) {
                    model.set(
                        field,
                        model.fieldData[field].value,
                        {
                            silent: true,
                        },
                    );
                }
            });
        }
    },

    requestValueDateList() {
        const model = this.parentModel;

        if (model.jsonData.typeInfo.functionCode !== 'INST' || !configuration.isClient() || this.state === 'VIEW') {
            return;
        }

        this.valueDateListPromise = new Promise((resolve) => {
            this.populateMissingData(model);
            const postData = {
                paymentType: model.jsonData.typeInfo.typeCode,
                debitBank: model.get('DEBIT_BANK_CODE'),
                debitCurrency: model.get('DEBIT_CURRENCY'),
                debitBankCountry: model.get('DEBIT_COUNTRY'),
                subType: model.jsonData.subtype === undefined ? '*' : model.jsonData.subtype,
                creditCurrency: model.get('CREDIT_CURRENCY'),
                creditBankCountry: model.get('BENE_BANK_COUNTRY'),
                beneBankId: model.get('BENE_BANK_ID'),
                beneBankType: model.get('BENE_BANK_TYPE'),
                releaseLeadTime: model.get('RELEASELEADTIME'),
                valueDate: model.get('VALUE_DATE'),
                tranDate: model.get('TRAN_DATE'),
                preAdviseWires: model.get('PREADVISEWIRES'),
            };

            const dateService = services.generateUrl(constants.URL_GETDATESLIST);
            http.post(dateService, postData, (result) => {
                this.handleBusinessDaysResponse(result);
                this.parentView.toggleSpinnerOverlay(true);
                fxFieldValidation.doFieldValidation(this, model, 'VALUE_DATE', this.state).then(() => {
                    this.parentView.toggleSpinnerOverlay(false);
                    resolve();
                });
            }, (err) => {
                log.error(err);
            });
        });
    },

    openRateDialog() {
        const contractIDModal = new FormDialog({
            formModel: this.model,
            handler: util.bind(this.useContractAndRate, this),
        });

        dialog.open(contractIDModal);
    },

    useContractAndRate(rate, contractId) {
        const model = this.parentModel;
        model.set({
            EXCHANGE_RATE: rate,
            EXCHANGE_RATE_CONTRACTID: contractId,
        });

        this.amountFieldValidation();

        /*
         * In the event of a user-specified rate/contractID, the debit amount should
         * never be 0 and always calculated using the credit amount and indicative rate.
         * This does not apply to templates
         */
        if (this.isPayment()) {
            this.makeAmountRequired('DEBIT_AMOUNT', true);
        }

        this.ui.$exchangeRate.val(rate).removeClass('hide');
        this.ui.$indicativeRate.addClass('hide');
        this.ui.$addContractID.addClass('hide');
        this.ui.$contractID.text(contractId);
        this.ui.$contractIDContainer.removeClass('hide');
        this.ui.$removeContractID.removeClass('hide');
    },

    removeContractID() {
        this.parentModel.set('EXCHANGE_RATE_CONTRACTID', '');
        this.amountFieldValidation();

        /*
         * In the event of a user-specified rate/contractID, the debit amount should
         * never be 0 and always calculated using the credit amount and indicative rate. This
         * removes that validator when the user removes the user-specified rate/contractID
         * This does not apply to templates
         */
        if (this.isPayment()) {
            this.makeAmountRequired('DEBIT_AMOUNT', false);
        }
    },

    /**
     * builds the request object to retrieve allowed credit currencies
     */
    constructAllowedCreditCurrenciesRequestPayload() {
        const {
            functionCode,
            productCode,
            typeCode,
        } = this.model.jsonData.typeInfo;

        return {
            queryCriteria: {
                action: {
                    functionCode,
                    productCode,
                    typeCode,
                    actionMode: this.model.context.actionMode,
                },
                customFilters: [
                    {
                        filterName: 'Depends',
                        filterParam: [
                            'DEBIT_BANK_CODE',
                            this.model.get('DEBIT_BANK_CODE'),
                        ],
                    },
                    {
                        filterName: 'Depends',
                        filterParam: [
                            'DEBIT_CURRENCY',
                            this.model.get('DEBIT_CURRENCY'),
                        ],
                    },
                ],
                subTypeCode: this.isModifyOrFromTemplate()
                    ? this.model.context?.subType
                    : this.model.context?.actionContext?.subType,
                fieldName: 'CREDIT_CURRENCY',
                entryClass: this.isModifyOrFromTemplate()
                    ? this.model.context?.actionData?.entryClass
                    : this.model.context?.actionContext?.entryClass,
                allowDuplicates: false,
            },
        };
    },

    /**
     * In the event that a selected credit currency is no longer among the valid allowed credit
     * currencies, we remove it and ensure that the rest of the related fields appear as they
     * should
     */
    clearDetailsFromInvalidCreditCurrency() {
        const creditCurrencyExists = !!this.model.get('CREDIT_CURRENCY');

        /*
         * if the default credit currency configuration is active AND the default credit currency
         * for this beneficiary is among the allowed list of credit currencies, set it and inform
         * the user with a modal. Relevant listeners for changing the currency will handle showing
         * proper indicative rates and other field appearance details
         */
        if (this.model.get('DEFAULTCREDITCURRENCYFLAG') === '1' && this.isAllowedCreditCurrency(this.model.jsonData.beneBankCurrMap)) {
            dialog.alert(locale.get('RTGS.FxCurrencyPair.CurrencyUpdated.text'), locale.get('RTGS.FxCurrencyPair.CurrencyUpdated.title'));
            this.model.set('CREDIT_CURRENCY', this.model.jsonData.beneBankCurrMap);
            return;
        }

        // update the data, state, and appearance of the credit currency dropdown
        this.model.set('CREDIT_CURRENCY', '');
        this.ui.$creditCurrency.comboBox('val', '');

        /*
         * In the event that the circumstances leading to this function result in a driver
         * field re-render, AND we want to set the current CREDIT_CURRENCY to an
         * empty value since it's invalid, we must overwrite the stored jsonData for
         * the CREDIT_CURRENCY combobox so it doesn't revert to the most recent non-null currency
         * when the form re-renders
         */
        this.model.jsonData.fieldInfoList.find(field => field.name === 'CREDIT_CURRENCY').value = '';

        // show the debit amount input
        this.shouldBeVisible('CREDIT_DEBIT_ENTERED');
        this.shouldBeVisible('DEBIT_AMOUNT');

        // clear any indicative rates and any values in the debit amount input
        this.model.set({
            INDICATIVERATE: '',
            INDICATIVEAMOUNT: '',
        });

        this.model.set('DEBIT_AMOUNT', '', { silent: true });
        this.ui.$debitAmount.val('');

        if (this.model.get('CREDIT_DEBIT_ENTERED') === 'Debit') {
            this.model.set('CREDIT_AMOUNT', '', { silent: true });
            this.ui.$creditAmount.val('');
        }

        this.ui.$debitAmount.change();

        this.ui.$indicativeRate.hide();

        if (creditCurrencyExists) {
            // inform the user that the credit currency was changed with a modal
            dialog.alert(locale.get('RTGS.FxCurrencyPair.CurrencyUpdated.text'), locale.get('RTGS.FxCurrencyPair.CurrencyUpdated.title'));
        }
    },

    /**
     * @param {Object} dataSet - the set of allowed currencies
     */
    populateCreditCurrencyDropdownWithAllowedCurrencies(dataSet) {
        this.ui.$creditCurrency.empty();

        dataSet.forEach((allowedCurrency) => {
            this.ui.$creditCurrency.append(new Option(
                allowedCurrency.label,
                allowedCurrency.name,
            ));
        });
        this.ui.$creditCurrency.val('');
    },

    /**
     * disable or remove all outstanding flags that were active during the retrieval of the
     * allowed credit currencies
     */
    finishRetrievingAllowedCreditCurrencies() {
        this.allowedCurrencyUpdateInProgress = false;
        this.model.activeFieldRequests = this.model.activeFieldRequests?.filter(item => item !== 'CREDIT_CURRENCY');

        if (!this.model.activeFieldRequests?.length) {
            this.model.trigger('executeDeferredComboDriverField');
        }
    },

    /**
     * @returns {Promise} The promise to get allowed credit currencies
     */
    createPromiseToRetrieveAllowedCreditCurrencies() {
        const queryURL = services.generateUrl(constants.URL_GETQUERYRESULTS_ACTION);
        const queryData = this.constructAllowedCreditCurrenciesRequestPayload();

        return serviceUtil.postData(queryURL, queryData);
    },

    /**
     * Formats the allowed currencies and stores them on the model for later use in policy
     * files like paymentsIntl.js and to be preserved when a driver field re-renders the form
     */
    updateAllowedCreditCurrenciesOnTheModel(result) {
        const { queryRows } = result.queryResponse.QueryData;

        // create an array of allowed currency objects with labels and names from the queryResponse
        const arrayOfAllowedCurrencies = queryRows.map(({ label, name }) => ({ label, name }));
        this.comboCollections.CREDIT_CURRENCY = arrayOfAllowedCurrencies;

        // if this debit account is not restricted to USD apply the allowed currencies normally
        if (!fxPaymentUtil.evaluateUSDOnly(this.model, this.comboCollections, this.model.get('DEBIT_BANK_CODE'))) {
            this.model.jsonData.allowedCreditCurrencies = arrayOfAllowedCurrencies;
            return;
        }

        /*
         * In cases where this debit account can only create USD payments, check if USD is
         * among the allowed credit currencies. If so, treat it like the ONLY allowed
         * currency. If not then act is if there are NO allowed currencies.
         */
        if (arrayOfAllowedCurrencies.some(currency => currency.name === 'USD')) {
            this.model.jsonData.allowedCreditCurrencies = arrayOfAllowedCurrencies.filter(currency => currency.name === 'USD');
        } else {
            this.model.jsonData.allowedCreditCurrencies = [];
        }
    },

    /**
     * Retrieve the allowed credit currencies based off the DEBIT_BANK_CODE and DEBIT_CURRENCY
     * this function should run when either of those attributes are changed
     */
    updateAllowedCreditCurrenciesFromDependent() {
        // safegaurd to prevent multiple calls from the multiple dependent attributes
        if (this.allowedCurrencyUpdateInProgress) {
            return;
        }

        this.allowedCurrencyUpdateInProgress = true;

        // either the DEBIT_CURRENCY or the DEBIT_BANK_CODE is empty
        if (!this.model.get('DEBIT_CURRENCY') || !this.model.get('DEBIT_BANK_CODE')) {
            /*
             * In a situation where there are no allowed credit currencies, but the credit currency
             * is set to USD (like on page load for a USD-Only user), there's no need to clear
             * the allowed/current credit currencies and show a modal notifying the user
             */
            if (this.model.jsonData.allowedCreditCurrencies.length === 0 && this.model.get('CREDIT_CURRENCY') === 'USD' && fxPaymentUtil.USD_ONLY) {
                this.allowedCurrencyUpdateInProgress = false;
                return;
            }

            this.model.jsonData.allowedCreditCurrencies = [];

            // clear the credit currency dropdown
            this.ui.$creditCurrency.empty();

            this.clearDetailsFromInvalidCreditCurrency();

            /*
             * since there is now no credit currency, treat any existing amount as "invalid" and
             * remove it so the header/footer summaries show 0
             */
            this.model.set('CREDIT_AMOUNT', '');

            /*
             * prevent this code from running multiple times when the user changes multiple
             * dependent fields at once
             */
            util.defer(() => {
                this.allowedCurrencyUpdateInProgress = false;
            });
            return;
        }

        /*
         * if a changing dependent kicks off this function and will ultimately result in a driver
         * field re-render. This flag will ensure this call completes before the form re-renders
         */
        if (!this.model.activeFieldRequests) {
            this.model.activeFieldRequests = [];
        }
        if (!this.model.activeFieldRequests.includes('CREDIT_CURRENCY')) {
            this.model.activeFieldRequests.push('CREDIT_CURRENCY');
        }

        this.createPromiseToRetrieveAllowedCreditCurrencies().then((result) => {
            this.updateAllowedCreditCurrenciesOnTheModel(result);

            // allow access to the credit currency dropdown (will disallow further down if needed)
            this.toggleDisabledCreditCurrency(false);

            const theAllowedCurrencies = this.model.jsonData.allowedCreditCurrencies;
            this.populateCreditCurrencyDropdownWithAllowedCurrencies(theAllowedCurrencies);

            // verbose variables for conditionals
            let thereIsACurrentlySelectedCreditCurrency = !!this.model.get('CREDIT_CURRENCY');
            const thereIsACurrentlySelectedBeneBank = !!this.model.get('BENE_BANK_ID');
            const thereAreAllowedCurrencies = !!theAllowedCurrencies.length;

            /*
             * If the user is restricted to USD Only, AND USD is one of the valid currency options
             * set the credit currency to USD and don't allow it to be changed
             */
            if (fxPaymentUtil.USD_ONLY && this.isAllowedCreditCurrency('USD')) {
                this.model.set('CREDIT_CURRENCY', 'USD');
                this.toggleDisabledCreditCurrency(true);
                return;
            }

            /**
             * NH-174293
             * If the DEFAULTCREDITCURRENCYFLAG is set to 'Default to Debit Currency',
             * check and set CREDIT_CURRENCY to DEBIT_CURRENCY of Debit account
             * only if the CREDIT_CURRENCY was not already set to Bene Currency from Contact Center
             * (can be verified by the presence of model.jsonData.beneBankCurrMap value).
             * If the CREDIT_CURRENCY was indeed set by Contact Center and if
             * Currency Pairs are restricted, check if CREDIT_CURRENCY is allowed or not.
             * If its not allowed, show a warning and remove its value.
             */
            if (this.model.get('DEFAULTCREDITCURRENCYFLAG') === '2') {
                this.currencyChange('DEBIT_AMOUNT', this.model, this.model.get('DEBIT_CURRENCY'));

                if (!this.model.jsonData.beneBankCurrMap) {
                    const currency = this.model.get('DEBIT_CURRENCY');
                    if (this.isAllowedCreditCurrency(currency)) {
                        this.model.set('CREDIT_CURRENCY', currency);
                        thereIsACurrentlySelectedCreditCurrency = true;
                    } else {
                        this.invalidCreditCurrencySelectedFromDebit();
                    }
                } else if (!this.isAllowedCreditCurrency(this.model.get('CREDIT_CURRENCY'))) {
                    this.invalidBeneBankDefaultCurrencySelected();
                }
            }

            /*
             * if there is no currently selected credit currency, let the form know that the
             * debit currency has been updated, and check if the default credit currency for the
             * currently selected bene bank is valid.
             */
            if (!thereIsACurrentlySelectedCreditCurrency) {
                // call out that the debit currency changed
                this.currencyChange('DEBIT_AMOUNT', this.model, this.model.get('DEBIT_CURRENCY'));

                /*
                 * If a bene bank has been selected, AND the default credit currency configuration
                 * is active, AND the default currency for this bank is valid, then set it
                 */
                if (thereIsACurrentlySelectedBeneBank && this.model.get('DEFAULTCREDITCURRENCYFLAG') === '1' && this.isAllowedCreditCurrency(this.model.jsonData.beneBankCurrMap)) {
                    this.model.set('CREDIT_CURRENCY', this.model.jsonData.beneBankCurrMap);
                }
                return;
            }

            // if there are NO allowed currencies
            if (!thereAreAllowedCurrencies) {
                this.clearDetailsFromInvalidCreditCurrency();

                // call out that the debit currency changed
                this.currencyChange('DEBIT_AMOUNT', this.model, this.model.get('DEBIT_CURRENCY'));
                return;
            }

            /*
             * We know by now that this debit bank code and debit currency pair has allowed
             * currencies. If the currently selected credit currency is one of them, then we're
             * pretty much finished here.
             */
            if (this.isAllowedCreditCurrency(this.model.get('CREDIT_CURRENCY'))) {
                // call out that the debit currency changed
                this.currencyChange('DEBIT_AMOUNT', this.model, this.model.get('DEBIT_CURRENCY'));
                return;
            }

            /*
             * if we've reached this point then we know there is a list of allowed credit currencies
             * and the currently selected credit currency is invalid
             */
            this.clearDetailsFromInvalidCreditCurrency();

            // call out that the debit currency changed
            this.currencyChange('DEBIT_AMOUNT', this.model, this.model.get('DEBIT_CURRENCY'));
        }, log.error).then(() => {
            this.finishRetrievingAllowedCreditCurrencies();
        });
    },

    templateHelpers() {
        const { fieldData } = this;
        const { model } = this;
        const { exchangePrecision } = this;
        const creditCurrencyCollection = this.comboCollections.CREDIT_CURRENCY;
        const creditCurrencyFormat = Decimals.getCurrencyFormat(model.get('CREDIT_CURRENCY'));
        const debitCurrencyFormat = Decimals.getCurrencyFormat(model.get('DEBIT_CURRENCY'));

        return {
            creditDebitEnteredLabel: fieldData.CREDIT_DEBIT_ENTERED.fieldLabel,
            creditDebitEnteredOptions: fieldData.CREDIT_DEBIT_ENTERED.choices,
            creditViewFormat: creditCurrencyFormat,
            debitViewFormat: debitCurrencyFormat,
            debitAmountLabel: fieldData.DEBIT_AMOUNT.fieldLabel || '',
            creditAmountLabel: fieldData.CREDIT_AMOUNT.fieldLabel || '',
            debitAmountToolTip: fieldData.DEBIT_AMOUNT.fieldUiToolTip || '',
            creditAmountToolTip: fieldData.CREDIT_AMOUNT.fieldUiToolTip || '',
            creditInfo: fieldData.CREDIT_AMOUNT.info,
            debitInfo: fieldData.DEBIT_AMOUNT.info,
            getMaxLen(field) {
                return fieldData[field]?.maxLen;
            },

            getOriginalMaxLen(field) {
                return fieldData[field]?.maxLen;
            },

            isProtected(field) {
                return fieldData[field]?.protected;
            },

            isDisabled(field) {
                return fieldData[field]?.isDisabled;
            },

            isCreditAmountLockable: (fieldData.CREDIT_AMOUNT.lockable
                && !this.isPayment()) || false,
            isCreditAmountLockedByDefault: (fieldData.CREDIT_AMOUNT.lockedByDefault
                && !this.isPayment()) || false,
            isCreditLocked: (fieldData.CREDIT_AMOUNT.locked
                && !this.isPayment()) || false,
            isDebitAmountLockable: (fieldData.DEBIT_AMOUNT.lockable
                && !this.isPayment()) || false,
            isDebitAmountLockedByDefault: (fieldData.DEBIT_AMOUNT.lockedByDefault
                && !this.isPayment()) || false,
            isDebitLocked: (fieldData.DEBIT_AMOUNT.locked
                && !this.isPayment()) || false,

            viewAmount(field) {
                const val = parseFloat(model.get(fieldData[field].name));
                return Number.isNaN(val)
                    ? locale.get('RTGS.*.rateTBD') : val.toFixed(exchangePrecision);
            },

            isPayment: this.isPayment(),
            isView: this.state === 'VIEW',
            crossCurr: this.model.get('CREDIT_CURRENCY') !== this.model.get('DEBIT_CURRENCY'),
            creditCurrencyList: creditCurrencyCollection,
        };
    },
});
