import Glu from '@glu/core/src/glu';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import CollectionView from '@glu/core/src/collectionView';
import FlexDropdown from '@glu/flex-dropdown';
import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import scroll from 'common/util/scroll';
import transform from 'common/util/transform';
import AuditSectionWidget from 'common/uiWidgets/auditSectionWidget/auditSectionWidget';
import services from 'services';
import constants from 'app/administration/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import NotificationModel from 'app/administration/models/notification';
import FeaturesCollection from 'app/administration/collection/notificationFeatures';
import ProductFeaturesGroup from 'app/administration/views/notificationManagement/productFeatureGroups';
import Formatter from 'system/utilities/format';
// import SimpleMDE from 'simplemde';
import markdownConverter from 'common/util/markdownConverter';
import CompanyCollection from 'app/administration/collection/notifications/companiesCollection';
import MarketSegmentCollection from 'app/administration/collection/notifications/marketSegmentCollection';
import template from 'app/administration/views/notificationManagement/notificationEntry.hbs';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';

export default Layout.extend({
    template,

    ui: {
        $allFeatures: '.allProductFeatures',
        $populationChannelLabel: '[data-hook="channel-pop-label"]',
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $shared: '[data-hook="getSharedCheckbox"]',
        $submit: '[data-hook="getSubmitBtn"]',
        $view: '[data-hook="getViewBtn"]',
        $delete: '[data-hook="getDeleteBtn"]',
        $modify: '[data-hook="getModifyBtn"]',
        $archive: '[data-hook="getArchiveBtn"]',
        $createNotification: '[data-hook="getCreateMessageFromTemplateBtn"]',
        $createTemplate: '[data-hook="getCreateTemplateFromMessageBtn"]',
        $copyAsTemplate: '[data-hook="getCopyTemplateFromTemplateBtn"]',
        $popoverBtn: '[data-toggle="popover"]',
        $startDate: '[name="START_DATE"]',
        $endDate: '[name="END_DATE"]',
        $startTime: '[name="START_TIME"]',
        $endTime: '[name="END_TIME"]',
        $timeField: '[data-hook="getTimeField"]',
        $endDateRadio: '[name="NOENDDATE"]',
        $endDateRadioChecked: '[name="NOENDDATE"]:checked',
        $readOnlyEndDate: '[data-hook="getReadOnlyEndDate"]',
        $readOnlyEndTime: '[data-hook="getReadOnlyEndTime"]',
        $companySelect: '[data-hook="companySelect"]',
    },
    isInitialRender: true,

    events: {
        'click @ui.$allFeatures': 'handleSelectAll',
        'click @ui.$submit': 'submit',
        'click @ui.$delete': 'delete',
        'click @ui.$modify': 'modify',
        'click @ui.$view': 'viewHandler',
        'click @ui.$createNotification': 'btnHandler',
        'click @ui.$createTemplate': 'btnHandler',
        'click @ui.$copyAsTemplate': 'btnHandler',
        'click @ui.$archive': 'btnHandler',
        'blur @ui $timeField': 'timeFieldHandler',
        'click @ui.$endDateRadio': 'endDateHandler',
        'change @ui.$companySelect': 'updateComboBox',
    },

    initialize(options) {
        this.returnTab = 'notification';
        this.serviceName = 'bannerNotification';
        this.gridModel = store.get('bannerNotifications:read');

        this.model = new NotificationModel({
            serviceName: this.serviceName,
            gridModel: this.gridModel || false,
            mode: options.mode,
            notificationType: 'notification',
        });

        this.templateModel = new NotificationModel({});
        if (this.options.mode === constants.INSERT) {
            this.model.set('POPULATIONTYPE', 'C');
        }
        this.systemTimezone = moment(new Date()).tz(serverConfigParams.get('SystemTimezone')).format('z');
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
            return;
        }
        createTabsToggleButton(this);
        this.setChannelsVisibility();
        this.initChannelsCombobox(this.model.get('POPULATIONTYPE') === 'S'
            ? this.marketSegmentCollection : this.companyCollection);
        this.setupFlexDropdowns();
        this.setModelListeners();
        this.renderProductFeatures();
        this.setActiveFeatureTab();
        /*
         * convert gridModel.TEXT from markdown to HTML if we are simply viewing the
         * notification
         */
        if (this.options.mode !== constants.SELECT) {
            this.initMarkdownEditor();
        }
        if (this.options.mode !== constants.INSERT
            && this.options.mode !== constants.CREATENOTIFICATION
            && this.options.mode !== constants.CREATETEMPLATE) {
            this.renderAuditSection();
        }
        this.initPopoverTooltip();
        this.setupDurationFields();
        this.isInitialRender = false;
    },

    initMarkdownEditor() {
        // const message = new SimpleMDE({
        //     element: this.$el.find('#notification-text')[0],
        //     promptURLs: true,
        //     forceSync: true,
        //
        // eslint-disable-next-line max-len
        // toolbar: ['heading-1', 'heading-2', 'heading-3', 'bold', 'italic', '|', 'unordered-list', 'ordered-list', '|', 'link', 'image', 'horizontal-rule', 'table', '|', 'guide', 'preview'],
        // });

        // // listen for change in markdown editor and trigger model change event
        // message.codemirror.on('change', () => {
        //     this.model.set('TEXT', message.value());
        // });
    },

    initPopoverTooltip() {
        this.ui.$popoverBtn.popover();
    },

    setModelListeners() {
        this.listenTo(this.model, 'invalid', this.handleModelFormError);
        this.listenTo(this.model, 'change:POPULATIONTYPE', this.updateChannelType);
    },

    handleModelFormError() {
        scroll.scrollToFirstError();
        this.toggleActionBtn(this.ui.$submit, false);
    },

    renderProductFeatures() {
        const branches = this.featureCollection.pluck('branches');
        this.featureCollectionView = new CollectionView({
            collection: new Collection(util.flatten(branches)),
            itemView: ProductFeaturesGroup,

            itemViewOptions: {
                mode: this.options.mode,
                selectAllFeatures: this.model.get('FEATURE_ID') === '*~*~*~*',
            },
        });
        /**
         * Use Number here as the attribute being set in the model is a string
         * Sometimes the attribute is set to a boolean but Number will return
         * 1 for true and 0 for false which will allow the checkbox checked property
         * to be set properly
         */
        this.ui.$shared.prop('checked', Number(this.model.get('SHARED')));

        this.listenTo(this.featureCollectionView.collection, 'change:allFeaturesSelected', (model, allSelected) => {
            if (!allSelected) {
                this.model.set('allFeatures', allSelected);
                this.model.unset('FEATURE_ID');
                this.ui.$allFeatures.prop('checked', allSelected);
            }
        });
        this.featureGroupsRegion.show(this.featureCollectionView);
    },

    /**
     * Setup and render the combobox dropdowns
     * -- Distribution Channel, and Company Dropdowns
     */
    setupFlexDropdowns() {
        this.renderDistChannelTypeDropdown();
        this.renderTemplateDropdown();
        this.updateChannelType();
    },

    /**
     * @method setupDurationFields
     */
    setupDurationFields() {
        if (this.options.mode === constants.SELECT) {
            this.hideReadOnlyEndDate(false, this.model.get('NOENDDATE') === '1');
            return;
        }
        // setup date fields
        const today = moment(new Date()).format(userInfo.getDateFormat());
        const dateOptions = {
            showCalendarIcon: true,
            showDropdowns: true,
            daysBack: 0,
            minDate: today,
            allowWeekends: true,
        };
        const startDateOptions = util.extend(
            dateOptions,
            {
                startDate: this.model.get('START_DATE') || today,
            },
        );
        const endDateOptions = util.extend(
            dateOptions,
            {
                startDate: moment(this.model.get('END_DATE')).isValid() ? this.model.get('END_DATE') : (this.model.get('START_DATE') || today),
            },
        );
        const timeMask = {
            mask: 'h:s t\\m',
            placeholder: '00:00 am',
            alias: 'datetime',
            hourFormat: '12',
        };

        // setup date fields
        this.ui.$startDate.nhDatePicker(startDateOptions);
        this.ui.$endDate.nhDatePicker(endDateOptions);
        // setup time fields
        this.ui.$startTime.inputmask(timeMask);
        this.ui.$endTime.inputmask(timeMask);
        if (this.options.mode === constants.INSERT) {
            this.model.set({
                START_DATE: today,
                END_DATE: today,
                START_TIME: '12:00 am',
                END_TIME: '11:59 pm',
                NOENDDATE: '0',
            });
            this.hideEndDate(false);
        } else if (this.options.mode === constants.MODIFY) {
            if (this.model.get('NOENDDATE') === '1') {
                this.hideEndDate(true);
            } else {
                this.hideEndDate(false);
            }
        } else if (this.options.mode === constants.CREATENOTIFICATION) {
            if (this.model.get('NOENDDATE') === '0') {
                this.hideEndDate(false);
            }
        }
    },

    /**
     * @method timeFieldHandler
     * @descripton sets the time field when the time field is entered
     * @param {object} evt
     */
    timeFieldHandler(evt) {
        this.model.set(evt.target.name, evt.target.value);
    },

    /**
     * @method endDateHandler
     * @description event handler for the no end date radio button
     * @param {object} evt
     */
    endDateHandler(evt) {
        const noEndDate = evt.target.value === '1';
        this.hideEndDate(noEndDate);
        this.updateEndDateValidator(noEndDate, true);
    },

    /**
     * @method hideEndDate
     * @description hides or shows the end date based on the radio button selection
     * @param {boolean} hide
     */
    hideEndDate(hide) {
        // hide/show end date and end time
        this.ui.$endDate.parent().toggleClass('hide', hide);
        this.ui.$endTime.closest('.form-group').toggleClass('hide', hide);
    },

    /**
     * @method hideReadOnlyEndDate
     * @param {boolean} hideDate
     * @param {boolean} hideTime
     */
    hideReadOnlyEndDate(hideDate, hideTime) {
        // hide readOnly end date and end time
        this.ui.$readOnlyEndDate.parent().toggleClass('hide', hideDate);
        this.ui.$readOnlyEndTime.parent().toggleClass('hide', hideTime);
    },

    /**
     * @method updateEndDateValidator
     * @description either removes or adds end date/time validators on the model
     * @param {boolean} noValidation - don't perform end date validation setup
     * @param {boolean} clear - clear end date if true
     */
    updateEndDateValidator(noValidation, clear) {
        if (noValidation) {
            this.model.removeEndDateValidation();
            this.model.set({
                END_DATE: '',
                END_TIME: '',
                NOENDDATE: '1',
            });
        } else if (this.returnTab !== 'template') {
            this.model.addEndDateValidation();
            if (clear) {
                this.model.set({
                    END_DATE: this.model.get('START_DATE'),
                    END_TIME: '11:59 pm',
                });
            }
        }
    },

    /**
     * Set collection data into the dropdown based on the population type
     * - will be triggered when the POPULATIONTYPE of model is changed
     */
    updateChannelType() {
        const populationType = this.model.get('POPULATIONTYPE');
        const populationLabel = (populationType === 'C') ? locale.get('ACH.UserGroup') : locale.get('common.marketSegment');
        const collection = populationType === 'C' ? this.companyCollection : this.marketSegmentCollection;

        // update label for population channel dropdown to match population type value
        this.ui.$populationChannelLabel.text(populationLabel);

        const childArr = util.map(collection.toJSON(), (item) => {
            const id = item.USERGROUP || item.NAME;
            const name = item.USERGROUP ? `${item.USERGROUP} ${item.USERGROUPNAME}` : `${item.NAME} ${item.COMPANIES_COUNT}`;
            return {
                id,
                name,
            };
        });
        this.renderChannelDropdown(childArr);
    },

    /**
     * Renders the Distribution Channel dropdown
     * (involved with tracking POPULATIONTYPE and population data combobox)
     */
    renderDistChannelTypeDropdown() {
        // Assumption that there will only ever be two values for populationTypes
        const flexOptions = {
            data: [{
                id: '0',
                name: '',

                child: [{
                    id: 'C',
                    name: locale.get('ACH.UserGroup'),
                }, {
                    id: 'S',
                    name: locale.get('common.marketSegment'),
                }],
            }],

            showGroups: true,
            disableMultiButton: true,
        };

        // set preselected value if given
        flexOptions.preSelectedIds = [this.model.get('POPULATIONTYPE') || 'C'];

        // render
        this.renderDropDown(
            flexOptions,
            this.distChannelRegion,
            selected => this.setPopulationType(selected),
        );
    },

    /**
     * @method setPopulationType
     * @param selected
     */
    setPopulationType(selected) {
        this.model.set('POPULATIONTYPE', selected[0].id);
        this.ui.$companySelect.comboBox('data', null);
        this.setChannelsVisibility();
    },

    /**
     * Helper to toggle channel visibility
     * between companies and market segments
     */
    setChannelsVisibility() {
        const populationType = this.model.get('POPULATIONTYPE');
        if (populationType === 'C') {
            this.initChannelsCombobox(this.companyCollection);
        } else {
            this.initChannelsCombobox(this.marketSegmentCollection);
        }
    },

    /**
     * updateComboBox function controls the 'All Accounts' selection.
     * If an account is selected, the 'All Accounts' is removed.
     * If 'All Accounts' is selected, all other accounts are removed.
     * @param {object} changeObj   new object selected in accounts combobox
     */
    updateComboBox(changeObj) {
        const current = changeObj.val;
        const addedSelection = (util.isNullOrUndefined(changeObj.added))
            ? [] : changeObj.added.id;
        const allValuesLabel = ['*', this.getAllChannelsLabel()];
        if (current && current.length > 1
            && (util.indexOf(current, '*') >= 0
            || util.indexOf(current, this.getAllChannelsLabel()) >= 0)
        ) {
            if (allValuesLabel.includes(addedSelection)) {
                this.loadAllAccounts(true);
            } else {
                this.ui.$companySelect
                    .comboBox(
                        'val',
                        current.filter(value => !allValuesLabel.includes(value)),
                    );
            }
        }
        this.updateModel(this.ui.$companySelect.val().split(','));
    },

    /**
     * Called to set the combo selection to the "All Accounts" item.
     * @param {boolean} addAllAccounts flag indicating if we need to add the All Accounts
     * options to the Combo or not.
     */
    loadAllAccounts(addAllAccounts) {
        const data = {
            id: '*',
            text: this.getAllChannelsLabel(),
        };
        this.ui.$companySelect.comboBox('data', addAllAccounts ? data : '');
    },

    /**
     * Updates the model with selected values
     * @param {[string]} values
     * options to the Combo or not.
     */
    updateModel(values) {
        this.model.set('POPULATION', values.map(value => ({
            name: 'POPULATION',
            value,
        })));
    },

    /**
     * Renders the Company Channel dropdown (combobox accompanying the distribution
     * channel dropdown)
     * (involved with tracking POPULATION)
     */
    renderChannelDropdown() {
        const { mode } = this.options;
        if (mode !== constants.SELECT
            && ((mode === constants.INSERT && this.model.isCopyExistingTemplate)
                || util.contains([
                    constants.MODIFY,
                    constants.CREATENOTIFICATION,
                    constants.CREATETEMPLATE,
                    constants.MAKETMPL,
                    constants.COPYTMPL,
                ], mode))) {
            this.setAllChannelId();
        }
    },

    /**
     * @method setAllChannelId
     * A helper to push * when All Companies/Segments have been selected in the record
     */
    setAllChannelId() {
        const filterVal = this.model.population;
        const allChannelsSelected = filterVal.includes('*');
        this.ui.$companySelect.comboBox(
            'val',
            allChannelsSelected ? [this.getAllChannelsLabel()] : this.model.population,
        );
    },

    /**
     * @method renderTemplateDropdown
     * Renders the Template dropdown. Only used in the notification workflow. Not
     * Notification Template
     */
    renderTemplateDropdown() {
        let flexOptions = {};

        if (this.options.mode !== constants.SELECT && this.returnTab !== 'template' && !util.isEmpty(this.templateRegion)) {
            flexOptions = {
                data: this.templateModel.get('templates'),
            };

            if (this.selectedTemplate) {
                flexOptions.preSelectedIds = [this.selectedTemplate];
            }

            // render
            this.renderDropDown(
                flexOptions,
                this.templateRegion,
                selected => this.setupTemplateDropdown(selected),
            );
            this.templateModel.hasRendered = true;
        }
    },

    setupTemplateDropdown(selected) {
        this.selectedTemplate = selected[0].id;
        this.model.set('TNUM', this.selectedTemplate);
        this.model.isCopyExistingTemplate = true;
        this.model.unset('FEATURE_ID');
        this.loadExistingFeatures().then(() => {
            this.loadAllFeatures('COPY');
        });
    },

    /**
     * General flex dropdown render method
     * @param {object} flexOptions - flex dropdown config
     * @param {object} viewRegion - region in view to where dropdown
     * will be rendered
     * @param {function} onSelectCallback - callback function called on dropdown
     * select event
     */
    renderDropDown(flexOptions, viewRegion, onSelectCallback) {
        const flexDropdown = new FlexDropdown(flexOptions);
        if (this.options.mode !== constants.SELECT) {
            this.listenTo(flexDropdown, 'selectionChanged', onSelectCallback.bind(this));
            // render
            viewRegion.show(flexDropdown);
        }
    },

    loadExistingFeatures() {
        return new Promise((resolve, reject) => {
            this.model.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    loadRequiredData() {
        const { notification } = this.options;

        if (this.options.mode !== constants.INSERT) {
            this.model.set('TNUM', notification.get('TNUM'));
            this.model.unset('POPULATIONTYPE');
            this.loadEntitlements();
        } else {
            this.loadAllFeatures();
        }
    },

    loadEntitlements() {
        const requestModify = {
            functionCode: 'MAINT',
            actionMode: 'MODIFY',
            productCode: 'CMAINT',
            typeCode: this.options.typeCode,
        };
        const requestDelete = {
            functionCode: 'MAINT',
            actionMode: 'DELETE',
            productCode: 'CMAINT',
            typeCode: this.options.typeCode,
        };
        const url = services.generateUrl('accessService/hasAccess');
        const modifyEntitlementPromise = new Promise((resolve, reject) => {
            http.post(url, requestModify, resolve, reject);
        });
        const deleteEntitlementPromise = new Promise((resolve, reject) => {
            http.post(url, requestDelete, resolve, reject);
        });

        Promise.all([
            modifyEntitlementPromise,
            deleteEntitlementPromise,
        ]).then(([isModEntitled, isDelEntitled]) => {
            this.isModEntitled = isModEntitled;
            this.isDelEntitled = isDelEntitled;
            this.loadExistingFeatures().then(() => this.loadAllFeatures());
        });
    },

    /**
     * @method loadAllFeatures
     * Define the channel and feature collection. Create and execute the promises on each.
     */
    loadAllFeatures(mode) {
        const action = this.gridModel ? this.gridModel.action.toUpperCase() : false;
        const promises = [];

        this.collectionOptions = {
            populationType: 'C',
            existingFeatures: this.model.features,
            mode: mode || this.options.mode,
            allFeaturesSelected: this.model.get('FEATURE_ID') === '*~*~*~*' && this.options.mode === constants.SELECT,
            serviceName: this.gridModel
            && (action === constants.CREATENOTIFICATION
                || action === constants.CREATETEMPLATE)
                ? this.gridModel.serviceName : this.serviceName,
            gridModel: this.gridModel,
        };

        this.companyCollection = new CompanyCollection(this.collectionOptions);
        this.featureCollection = new FeaturesCollection(this.collectionOptions);
        this.collectionOptions.populationType = 'M';
        this.marketSegmentCollection = new MarketSegmentCollection(this.collectionOptions);

        if (this.returnTab !== 'template') {
            promises.push(new Promise((resolve, reject) => {
                this.templateModel.fetchTemplates({
                    success: resolve,
                    error: reject,
                });
            }));
        }
        if (this.options.mode !== constants.SELECT) {
            promises.push(new Promise((resolve, reject) => {
                this.marketSegmentCollection.fetch({
                    success: resolve,
                    error: reject,
                });
            }));
        }
        promises.push(new Promise((resolve, reject) => {
            this.featureCollection.fetch({
                success: resolve,
                error: reject,
            });
        }));

        Promise.all(promises).then((results) => {
            if (this.returnTab !== 'template') {
                this.templateModel.set('templates', util.map(results[0], (row) => {
                    const thisTemplate = transform.pairsToHash(
                        row.columns,
                        'fieldName',
                        'fieldValue',
                    );
                    return {
                        id: thisTemplate.TNUM,
                        name: thisTemplate.TEMPLATEDESC,
                    };
                }));
            }
            this.setHasLoadedRequiredData(true);
            this.render();
        }, util.bind(errorHandlers.loadingModal, this));
    },

    initChannelsCombobox(channelCollection) {
        this.channelSelectComboBox = this.ui.$companySelect.comboBox({
            initSelection: (element, callback) => {
                const id = element.val();
                const filterValue = util.findWhere(this.filters, { id });
                let data = util.map(element.val().split(','), value => ({
                    id: value,
                    text: (filterValue && filterValue.text) || value,
                }));
                if (data.length === 1) {
                    data = util.first(data);
                }
                callback(data);
            },

            dropdownAutoWidth: 'true',
            allowClear: true,
            multiple: true,
            closeOnSelect: false,

            query: util.debounce((query) => {
                channelCollection.setFilter(query.page, query.term);
                channelCollection.fetch({
                    /*
                     * when fetching additional pages, keep previous models in the
                     * collection
                     */
                    remove: query.page === 1,

                    success: (collection, resp) => {
                        const result = resp.rows.map(row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));

                        const data = {
                            results: this.parseChannels(result, collection.startRow === 1),
                            more: collection.hasMorePages,
                        };

                        query.callback(data);
                    },
                });
            }, constants.COMBO_DEBOUNCE),
        });
    },

    /**
     * parseChannels function formats the data to be added to the list.
     * It also adds the "All {Companies | Marget Segments}"
     * selection if it is not already in the list.
     * @param {object} results from the latest read operation where
     * "key - value pairs" where {"key => label" : "value => numeric value" }
     * @param {boolean} addAllChannels true if
     * the "All {Companies | Marget Segments}" item should to be added.
     */
    parseChannels(results, addAllChannels = true) {
        this.filters = util.map(results, item => ({
            id: item.USERGROUP || item.NAME,
            text: item.USERGROUP ? `${item.USERGROUP} ${item.USERGROUPNAME}` : `${item.NAME} ${item.COMPANIES_COUNT}`,
        }));

        if (addAllChannels) {
            const allChannelsLabel = this.getAllChannelsLabel();
            this.filters.unshift({
                id: '*',
                text: allChannelsLabel,
            });
        }
        return this.filters;
    },

    /**
     * @method getAllChannelsLabel
     * @returns {string} all channels label based on population type
     */
    getAllChannelsLabel() {
        return this.model.get('POPULATIONTYPE') === 'C'
            ? locale.get('common.allCompanies')
            : locale.get('common.allMarketSegments');
    },

    setActiveFeatureTab(e) {
        let id;

        this.ui.$navItems.removeClass('is-active');
        this.$el.find('.featureGroup').removeClass('is-active');

        if (e) {
            id = this.$(e.currentTarget).attr('name');
            this.$(`#featureTab-${id}`).toggleClass('is-active');
            this.$(`.${id}`).toggleClass('is-active');
        } else {
            ({ id } = this.featureCollection.at(0));
            this.$(`#featureTab-${id}`).toggleClass('is-active');
            this.$(`.${id}`).toggleClass('is-active');
        }
        this.currentTabText = this.$(`#featureTab-${id} a`).text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

    toggleActionBtn($actionBtn, boolean) {
        $actionBtn.attr('aria-busy', boolean);
    },

    setSelectedFeatures() {
        const data = [];
        const featureIDs = [];

        if (!this.model.get('allFeatures')) {
            this.featureCollectionView.children.each((child) => {
                const modelArr = child.fetchSelections();
                if (modelArr.length > 0) {
                    data.push(modelArr);
                }
            });
            util.chain(data)
                .flatten(data)
                .each((model) => {
                    featureIDs.push({
                        name: 'FEATURE_ID',
                        value: model.id,
                    });
                });

            this.model.set('FEATURES', featureIDs);
        }
    },

    renderMessage(message) {
        this.alertView = alert.danger(
            message,
            {
                canDismiss: true,
                animate: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    /**
     * @method handleSelectAll
     * @param e
     * set the value of allFeatures. Unset the feature_id
     */
    handleSelectAll(e) {
        this.model.set('allFeatures', e.currentTarget.checked);
        // when unchecked, unset the flag used for when all features are selected
        if (!e.currentTarget.checked) {
            this.model.unset('FEATURE_ID');
        }
        this.appBus.trigger('allFeatures', e.currentTarget.checked);
    },

    /**
     * @method setShared
     * explicitly set the value of SHARED based on the boolean property value
     */
    setShared() {
        this.model.set('SHARED', this.ui.$shared.prop('checked') ? '1' : '0');
    },

    cancel() {
        store.set('notification:returnTab', this.returnTab);
        this.navigateTo(this.options.returnRoute);
    },

    modify() {
        if (this.options.mode === constants.SELECT) {
            this.switchToModifyView();
        } else {
            this.submit();
        }
    },

    delete() {
        dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.delete'), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
            if (ok) {
                this.model.destroy({
                    success: (model, result) => {
                        store.set('notification:success', result.message[0]);
                        store.set('notification:returnTab', this.returnTab);
                        this.navigateTo(this.options.returnRoute);
                    },

                    error: (result) => {
                        this.toggleActionBtn(this.ui.$submit, false);
                        this.renderMessage(result.error.message[0]);
                        this.$('html, body').animate({
                            scrollTop: 0,
                        }, 100);
                    },
                });
            }
        });
    },

    /**
     * @method switchToModifyView
     * reload the current route since it is shared by the view & modify workflow.
     */
    switchToModifyView() {
        this.gridModel.action = constants.MODIFY;
        store.set('bannerNotifications:read', this.gridModel);
        Glu.Backbone.history.loadUrl(Glu.Backbone.history.fragment);
    },

    viewHandler() {
        this.setGridModelData(constants.SELECT);
        Glu.Backbone.history.loadUrl(Glu.Backbone.history.fragment);
    },

    /**
     * @method btnHandler
     * @param e
     * Pass the correct action & route to processAction based on the selected button
     */
    btnHandler(e) {
        const button = e.currentTarget.getAttribute('data-hook');
        const map = {
            getCreateTemplateFromMessageBtn: {
                action: constants.CREATETEMPLATE,
                route: constants.templateRoute,
            },

            getCreateMessageFromTemplateBtn: {
                action: constants.CREATENOTIFICATION,
                route: constants.messageRoute,
            },

            getCopyTemplateFromTemplateBtn: {
                action: constants.COPYTMPL,
                route: constants.templateRoute,
            },

            getArchiveBtn: {
                action: constants.ARCHIVE,
            },
        };
        this.processAction(map[button].action, map[button].route);
    },

    /**
     * @method processAction
     * @param action
     * @param route
     * A helper function to execute the actions required before navigating to the
     * next route
     */
    processAction(action, route) {
        if (action === constants.ARCHIVE) {
            this.archive();
        } else if (action === constants.CREATENOTIFICATION && this.returnTab === 'notification') {
            if (this.gridModel) {
                this.gridModel.isCopyFromMessage = true;
            }
            this.reloadHistoryFragment(action);
        } else if (action === constants.COPYTMPL) {
            if (this.gridModel) {
                this.gridModel.isCopyFromTemplate = true;
            }
            this.reloadHistoryFragment(action);
        } else {
            this.setGridModelData(action);
            this.navigateTo(route);
        }
    },

    /**
     * @method reloadHistoryFragment
     * @param action
     * a helper method to reload the current route.
     */
    reloadHistoryFragment(action) {
        this.gridModel.action = action;
        this.gridModel.serviceName = this.serviceName;
        store.set('bannerNotifications:read', this.gridModel);
        Glu.Backbone.history.loadUrl(Glu.Backbone.history.fragment);
    },

    /**
     * @method setGridModelData
     * @param action - Update the gridModel action with the 'string'. model
     */
    setGridModelData(action) {
        this.gridModel.serviceName = this.serviceName;
        this.gridModel.action = action;
        store.set('bannerNotifications:read', this.gridModel);
    },

    /**
     * @method archive
     * Open a modal to confirm the user's action, then archive .
     */
    archive() {
        const notification = new NotificationModel({
            TNUM: this.model.get('TNUM'),
            serviceName: this.options.serviceName,
        });

        dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.archive'), locale.get('tableMaintenance.dialog.confirm.title.archive'), (ok) => {
            if (ok) {
                notification.archive({
                    success: (result) => {
                        store.set('notification:success', result.message ? result.message[0] : notification.message[0]);
                        store.set('notification:returnTab', this.options.returnTab);
                        this.navigateTo(this.options.returnRoute);
                    },

                    error: (result) => {
                        this.toggleActionBtn(this.ui.$archive, false);
                        this.renderMessage(result.error.message[0]);
                        this.$('html, body').animate({
                            scrollTop: 0,
                        }, 100);
                    },
                });
            }
        });
    },

    /**
     * @method getButtons
     * @returns {Array}
     * Collect the buttons defined in the grid model. Reject duplicates of the
     * same workflow.
     */
    getButtons() {
        let buttons = [];
        const { mode } = this.options;
        if (mode !== constants.INSERT) {
            buttons = util.reject(this.gridModel.model.buttons, button => button.action === mode && mode !== 'MODIFY');
        }

        util.map(buttons, (buttonParam) => {
            const button = buttonParam;
            switch (button.action) {
            case constants.SELECT:
                button.action = 'getViewBtn';
                break;
            case constants.DELETE:
                button.action = 'getDeleteBtn';
                break;
            case constants.MODIFY:
                button.action = 'getModifyBtn';
                break;
            case constants.ARCHIVE:
                button.action = 'getArchiveBtn';
                break;
            case 'CREATETEMPLATE':
                button.action = 'getCreateTemplateFromMessageBtn';
                break;
            case 'CREATENOTIFICATION':
                button.action = 'getCreateMessageFromTemplateBtn';
                break;
            case 'COPYTMPL':
                button.action = 'getCopyTemplateFromTemplateBtn';
                break;
            default:
            }
        });
        return buttons;
    },

    /**
     * @method isCreateWorkflow
     * @param workflow
     * @returns boolean
     */
    isCreateWorkflow(workflow) {
        const createWorkflows = [
            constants.CREATENOTIFICATION,
            constants.CREATETEMPLATE,
            constants.MAKETMPL,
            constants.INSERT,
            constants.COPYTMPL,
        ];
        return util.contains(createWorkflows, workflow);
    },

    renderAuditSection() {
        const auditOptions = {
            USERGROUP: this.model.get('USERGROUP'),
            ENTERED_BY: this.model.get('ENTEREDBY'),
            ENTERED_TIMESTAMP: this.model.get('ENTEREDBYTIMESTAMP'),
            ENTERED_BY_NAME: this.model.get('ENTEREDBYNAME'),
        };

        if (this.model.has('MODIFIEDBYTIMESTAMP') && this.model.get('MODIFIEDBYTIMESTAMP') !== '') {
            auditOptions.MODIFIED_TIMESTAMP = this.formatTimeStamp(this.model.get('MODIFIEDBYTIMESTAMP'));
            auditOptions.MODIFIED_BY = this.model.get('MODIFIEDBY');
            auditOptions.MODIFIED_BY_NAME = this.model.get('MODIFIEDBYNAME');
        }

        if (this.model.has('ARCHIVEDBYTIMESTAMP') && this.model.get('ARCHIVEDBYTIMESTAMP') !== '') {
            auditOptions.ARCHIVED_TIMESTAMP = this.formatTimeStamp(this.model.get('ARCHIVEDBYTIMESTAMP'));
            auditOptions.ARCHIVED_BY = this.model.get('ARCHIVEDBY');
            auditOptions.ARCHIVED_BY_NAME = this.model.get('ARCHIVEDBYNAME');
        }

        this.auditSection = new AuditSectionWidget({
            model: new NotificationModel(auditOptions),
            state: this.options.mode,
            suppressedColumns: ['userGroup'],
            displayHistoryLink: false,
        });
        this.auditSectionRegion.show(this.auditSection);
    },

    /**
     * @method formatTimeStamp
     * @param timestamp
     * @returns {string} representation of timestamp
     */
    formatTimeStamp(timestamp) {
        return `${Formatter.formatDate(timestamp)} ${Formatter.formatTime(timestamp)}`;
    },

    /**
     * @method handleEndTimeDateValidators
     * @description checks the selected endDate option and adds/removes the validator
     */
    handleEndTimeDateValidators() {
        // get current value of end date radio button
        const noEndDateSelected = this.$('[name="NOENDDATE"]:checked');
        if (noEndDateSelected.val() === '1') {
            this.updateEndDateValidator(true); // don't validate end date
        } else {
            this.updateEndDateValidator(false);
        }
    },

    submit() {
        this.handleEndTimeDateValidators();
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }

        this.toggleActionBtn(this.ui.$submit, true);
        this.setSelectedFeatures();

        if (this.isCreateWorkflow(this.options.mode)) {
            this.model.unset('TNUM');
        }

        /*
         * for template workflow, if shared checkbox was not selected before submission, set
         * default value to off
         */
        if (this.returnTab === 'template') {
            this.setShared();
        } else {
            this.model.isCopyExistingTemplate = false;
        }

        this.model.save(
            {},
            {
                success: (model) => {
                    store.set('notification:success', model.get('message'));
                    store.set('notification:returnTab', this.returnTab);

                    this.navigateTo(this.options.returnRoute);
                },

                error: (result) => {
                    this.toggleActionBtn(this.ui.$submit, false);
                    this.renderMessage(result.error.message[0]);
                    this.$('html, body').animate({
                        scrollTop: 0,
                    }, 100);
                },
            },
        );
    },

    /**
     * Set notification text based on mode
     * @return {string} either a blank string, Markdown or converted HTML
     */
    getNotificationText(TEXT) {
        const notificationText = '';

        if (this.options.mode === constants.SELECT) {
            // if we are in SELECT mode then we want the text converted to HTML for display
            return markdownConverter.toHTML(TEXT);
        }

        if (this.options.mode === constants.MODIFY) {
            // if we are in MODIFY mode then we don't want the text converted to HTML
            return TEXT;
        }

        /*
         * if we are in INSERT mode we don't want the TEXT set at all because
         * the text editor will be empty and awaiting user's initial notification text
         */
        return notificationText;
    },

    /**
     * Template Helpers
     * Used to populate handlebars template
     */
    templateHelpers() {
        const { mode } = this.options;

        return {
            featureTabs: () => {
                if (this.featureCollection) {
                    return this.featureCollection.toJSON();
                }
                return undefined;
            },

            ModifyDeleteEntitled: this.isModEntitled && this.isDelEntitled,
            readOnly: mode === constants.SELECT,
            isModify: mode === constants.MODIFY,
            isTemplateCodeReadonly: mode === constants.SELECT || mode === constants.MODIFY,
            isInsert: () => this.isCreateWorkflow(mode),

            population: () => {
                if (this.model.get('POPULATION') === '*') {
                    return this.getAllChannelsLabel();
                }
                return this.model.population ? this.model.population.join(', ') : '';
            },

            populationType: () => {
                if (this.model.get('POPULATIONTYPE')) {
                    return this.model.get('POPULATIONTYPE') === 'C' ? locale.get('ACH.UserGroup') : locale.get('common.marketSegment');
                }
                return undefined;
            },

            allFeaturesSelected: this.model.get('FEATURE_ID') === '*~*~*~*',
            title: mode === 'INSERT' ? locale.get('common.addBannerNotification') : locale.get('common.bannerNotification'),
            buttons: this.getButtons(),
            noButtons: this.getButtons().length === 0,

            hasBeenModified: () => !util.isEmpty(this.model.get('MODIFIEDBY')),

            auditTitle: locale.get('common.notificationHistory'),
            isActive: this.model.get('ACTIVE') === '1',
            isCreationFromTemplate: () => (!util.isEmpty(this.model.get('TEMPLATECODE')) && mode === constants.MODIFY) || (this.gridModel && this.gridModel.action.toUpperCase() === constants.CREATENOTIFICATION),
            TEXT: this.getNotificationText(this.model.get('TEXT') || (this.gridModel ? this.gridModel.model.get('TEXT') : '')),
            startDate: moment(this.model.get('START_DATE')).format(userInfo.getDateFormat()),
            endDate: (this.model.get('NOENDDATE') === '1') ? locale.get('common.noEndDate') : moment(this.model.get('END_DATE')).format(userInfo.getDateFormat()),
            startTime: this.model.get('START_TIME'),
            endTime: this.model.get('END_TIME'),
            endDateSelected: this.model.get('NOENDDATE') === '0',
            displayDuration: this.returnTab !== 'template',
            systemTimezone: this.systemTimezone,
            timezoneLabel: locale.get('common.systemTimezone'),
        };
    },
});
