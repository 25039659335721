import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ValidatedInput } from '@glu/form-components';
import Alert from '@glu/alerts-react';
import { Button, PRIMARY, TERTIARY } from '@glu/buttons-react';
import { Dropdown } from '@glu/dropdown-react';
import RequiredLabelText from 'components/RequiredLabelText/RequiredLabelText';
import userInfo from 'etc/userInfo';
import systemConfig from '../../system/configuration';
import useLocale from '../../hooks/useLocale';
import FormField from '../FormField/FormField';
import ActionButtonContainer from '../ActionButtonContainer/ActionButtonContainer';
import UserGroupSelector from '../UserGroupSelector/UserGroupSelector';
import UserSelector from '../UserSelector/UserSelector';
import scheduledUtil from '../../app/scheduledExports/util';

const getUserGroupId = (selectedGroups) => {
    if (!selectedGroups.length) {
        return '';
    }
    const [selected] = selectedGroups;
    return selected.id;
};

const ScheduledExportSummaryForm = ({
    onContinue,
    onInputBlur,
    formData,
    transmissionProfiles,
    reportTypes,
    formIsValid,
    cancel,
    updateForm,
    initialData,
    validate,
}) => {
    const [disableInputs, setDisableInputs] = useState(systemConfig.isAdmin());
    const [disableUser, setDisableUser] = useState(true);
    const [isValidatingName, setValidatingName] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertOptions, setAlertOptions] = useState({
        title: '',
        message: '',
    });
    const { getLocaleString } = useLocale();
    const {
        transmissionProfile,
        reportType,
        userGroup,
        userId,
    } = formData;

    const userGroupSelected = (name, value) => {
        updateForm({
            ...initialData,
            name: formData.name,
            [name]: value,
        });
        setDisableUser(false);
        setDisableInputs(true);
    };
    const userSelected = (name, value) => {
        updateForm({
            ...initialData,
            name: formData.name,
            userGroup: formData.userGroup,
            [name]: value,
        });
        setDisableInputs(false);
    };

    const onFilter = useCallback(
        (searchValue, userGroupId) => scheduledUtil.getAllUsers(searchValue, userGroupId)
            .then(scheduledUtil.formatEmailRecipients),
        [],
    );

    const onSubmit = (event) => {
        event.preventDefault();
        validate().then(({ isValid }) => {
            if (!isValid) {
                return Promise.reject({});
            }
            setValidatingName(true);
            return scheduledUtil.validateScheduleName({
                userGroup: formData.userGroup?.[0]?.id || userInfo.get('group'),
                name: formData.name,
            });
        }).then((resp) => {
            setValidatingName(false);
            if (resp.validName) {
                onContinue();
            } else {
                setAlertOptions({
                    title: resp.title,
                    message: resp.message,
                });
                setShowAlert(true);
            }
        }).catch(() => {
            setValidatingName(false);
        });
    };

    return (
        <>
            {showAlert && (
                <section className="section section-container">
                    <Alert
                        variant="error"
                        title={alertOptions.title}
                        message={alertOptions.message}
                        onHide={() => setShowAlert(false)}
                    />
                </section>
            )}
            <form onSubmit={onSubmit}>
                <section className="section section-container">
                    <fieldset>
                        { systemConfig.isAdmin() && (
                            <div className="row">
                                <FormField
                                    className="col-3"
                                >
                                    <UserGroupSelector
                                        labelText={(
                                            <RequiredLabelText
                                                label={getLocaleString('GIR.User.Group')}
                                            />
                                        )}
                                        name="userGroup"
                                        validators={{
                                            name: getLocaleString('GIR.User.Group'),
                                            arrayNotEmpty: true,
                                        }}
                                        onChange={userGroupSelected}
                                        selectedOptions={userGroup}
                                    />
                                </FormField>
                                <FormField
                                    className="col-3"
                                >
                                    <UserSelector
                                        labelText={(
                                            <RequiredLabelText
                                                label={getLocaleString('GIR.User.ID')}
                                            />
                                        )}
                                        name="userId"
                                        validators={{
                                            name: getLocaleString('GIR.User.ID'),
                                            arrayNotEmpty: true,
                                        }}
                                        onFilter={onFilter}
                                        userGroupId={getUserGroupId(userGroup)}
                                        selectedOptions={userId}
                                        onChange={userSelected}
                                        disabled={disableUser}
                                    />
                                </FormField>
                            </div>
                        )}
                        <div className="row">
                            <FormField
                                className="col-3"
                            >
                                <ValidatedInput
                                    labelText={(
                                        <RequiredLabelText
                                            label={getLocaleString('GIR.Schedule.Name')}
                                        />
                                    )}
                                    name="name"
                                    validators={{
                                        name: getLocaleString('GIR.Schedule.Name'),
                                        required: true,
                                        maxLength: 60,
                                    }}
                                    maxLength="60"
                                    Component={ValidatedInput}
                                    onBlur={onInputBlur}
                                />
                            </FormField>
                            <FormField
                                className="col-3"
                            >
                                <Dropdown
                                    labelText={getLocaleString('GIR.Transmission.Profile')}
                                    name="transmissionProfile"
                                    Component={Dropdown}
                                    selectedOptions={transmissionProfile}
                                    options={transmissionProfiles}
                                    disabled={disableInputs}
                                    onBlur={onInputBlur}
                                />
                            </FormField>
                        </div>
                        <div className="row">
                            <FormField
                                className="col-3"
                            >
                                <Dropdown
                                    labelText={(
                                        <RequiredLabelText
                                            label={getLocaleString('GIR.Report.Type')}
                                        />
                                    )}
                                    name="reportType"
                                    Component={Dropdown}
                                    validators={{
                                        name: getLocaleString('GIR.Report.Type'),
                                        arrayNotEmpty: true,
                                    }}
                                    selectedOptions={reportType}
                                    options={reportTypes}
                                    disabled={disableInputs}
                                    onBlur={onInputBlur}
                                    onChange={onInputBlur}
                                />
                            </FormField>
                        </div>

                    </fieldset>
                    <ActionButtonContainer>
                        <Button
                            variant={PRIMARY}
                            type="submit"
                            text={getLocaleString('button.continue')}
                            disabled={!formIsValid}
                            aria-busy={isValidatingName}
                        />
                        <Button
                            variant={TERTIARY}
                            text={getLocaleString('button.cancel')}
                            onClick={cancel}
                        />
                    </ActionButtonContainer>
                </section>
            </form>
        </>
    );
};

ScheduledExportSummaryForm.propTypes = {
    onContinue: PropTypes.func.isRequired,
    onInputBlur: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        name: PropTypes.string,
        userGroup: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        userId: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        transmissionProfile: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
        reportType: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
    }).isRequired,
    initialData: PropTypes.shape({}).isRequired,
    transmissionProfiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    reportTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    formIsValid: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
};

export default ScheduledExportSummaryForm;
