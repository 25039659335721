var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog add-holiday-modal\">\n    <form class=\"modal-content\" data-submit=\"save\">\n        <div class=\"modal-header\">\n            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"close","text":"Close","name":"close"},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":80}}}))
    + "\n            <h4 class=\"modal-title\" id=\"modal-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"holy.addNewHoliday",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":53},"end":{"line":5,"column":84}}}))
    + "</h4>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"row\">\n                <div class=\"form-group required col-4\">\n                    <div class=\"holiday-type-region\"></div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"form-group required col-6\">\n                    <div class=\"bank-code-region\"></div>\n                </div>\n                <div class=\"form-group required col-6 holiday-date\">\n                    <label for=\"date\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"holy.holidayDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":38},"end":{"line":19,"column":67}}}))
    + "</label>\n                    <input id=\"date\" name=\"holidayDate\" type=\"text\" class=\"input-date form-control\" data-bind=\"model\" autocomplete=\"off\">\n                </div>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.continue.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":58},"end":{"line":25,"column":98}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":79},"end":{"line":26,"column":98}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});