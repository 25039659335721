var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountNumber") || (depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountNumber","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":31}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":42}}}) : helper)))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});