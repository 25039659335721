import locale from '@glu/locale';
import Constants from 'app/balanceAndTransaction/constants';
import configuration from 'system/configuration';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import store from 'system/utilities/cache';
import TransactionListView from 'app/balanceAndTransaction/views/transactionListView';
import util from '@glu/core/src/util';

const isClientDeeplink = configuration.isPortal() && configuration.isClient();

const DepositTransactionListView = TransactionListView.extend({
    accountType: 'DEPOSIT',
    hideBackHeader: false,

    getContext() {
        return {
            functionCode: Constants.INST,
            menuCatergory: 'reporting',
            menuDescription: 'Balance and Transaction Reporting',
            name: locale.get('balanceTrans.depositAccountTransaction'),
            productCode: Constants.GIR,
            requestMappings: 'balanceAndTransactionReporting',
            serviceName: this.getServiceName(),
            typeCode: this.getTypeCode(),
            exportPrintURL: this.getExportPrintURL(),
            inquiryId: 22210,
        };
    },

    /**
     * provides a part of The URL to hit for Deposit Account Summary Transactions
     * @method getServiceName - extend
     * @return {String} A part of the URL to hit for Deposit Account Summary
     * Transactions
     */

    getServiceName() {
        return `${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}getTransactions`;
    },

    /**
     * provides the URL to hit for print/export on Deposit Account Summary Transactions
     * @method getExportPrintURL - extend
     * @return {String} the URL to hit for print/export on Deposit Account Summary
     * Transactions
     */

    getExportPrintURL() {
        return configuration.isAdmin()
            ? Constants.TRANS_EXPORT_PRINT_URL : Constants.ASYNC_TRANS_EXPORT_PRINT_URL;
    },

    /**
     * provides the typeCode for Deposit Account Summary Transactions
     * @method getTypeCode - extend
     * @return {String} provides the typeCode for Deposit Account Summary Transactions
     */

    getTypeCode() {
        return Constants.GIRTRANS;
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            TransactionListView.prototype.onRender.call(this);
            if (isClientDeeplink && !this.hasBackMethod()) {
                this.ui.$cancelBtn.hide();
            }
        } else {
            const isFromContainer = store.get('btr:fromInitialContainer');
            moveToTopCheck(this.model);
            store.unset('helpPage');
            if (isClientDeeplink && !isFromContainer && !this.hasBackMethod()) {
                this.hideBackHeader = true;
            }
            this.loadViewRequirements();
        }
    },

    onClose() {
        if (!store.get('btr:fromInitialContainerTemp')) {
            store.unset('btr:fromInitialContainer');
        }
    },

    templateHelpers() {
        const obj = TransactionListView.prototype.templateHelpers.call(this, undefined);
        return util.extend(
            obj,
            {
                hideBackHeader: () => this.hideBackHeader,
            },
        );
    },

});

export default DepositTransactionListView;
