import AccountInputView from 'mobile/common/views/inputs/accountInput';
import LocationAccountBase from './locationAccountBase';
import accountViewTmpl from './accountView.hbs';

export default LocationAccountBase.extend({
    template: accountViewTmpl,

    initialize(options) {
        this.accounts = options.accounts;
        this.accountInputView = new AccountInputView({
            model: this.model,
            accounts: this.accounts,
        });
        this.getAccounts();
    },

    onRender() {
        this.accountInputRegion.show(this.accountInputView);
    },

    /**
     * Get a list of accounts based on location
     */
    getAccounts() {
        return this.accounts.fetch()
            .then(this.receiveAccounts.bind(this));
    },

    /**
     * When only one location, update the model with the location
     */
    checkForSingleItem() {
        if (this.hasOneItem(this.accounts)) {
            this.updateModelWithFirstItem(this.accounts, this.model, 'ACCOUNTNUM');
        }
    },

    /**
     * Call the toggledDisabled function of the account input
     * @param {boolean} disabled
     */
    toggleDisabled(disabled) {
        this.accountInputView.toggleDisabled(disabled);
    },
});
