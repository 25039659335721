import userInfo from 'etc/userInfo';

export default {
    translateUrlForLogin(url) {
        if (!userInfo.isLoggedIn()) {
            return (`login${url.charAt(0).toUpperCase()}${url.slice(1)}`);
        }
        return url;
    },
};
