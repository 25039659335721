import util from "@glu/core/src/util";

function csvEscape(str) {
    let localStr = str;
    const BAD_START_CHAR = [
        '=',
        '+',
        '-',
        '@',
    ];
    if (typeof localStr === 'string') {
        // CSV injection mitigation
        if (BAD_START_CHAR.indexOf(localStr[0]) > -1) {
            localStr = `\'${localStr.replace(/\|/g, '\\|')}`;
        }
        return localStr.indexOf(',') > -1 ? `"${localStr}"` : localStr;
    }
    return '';
}

/**
 * @param {array} columns
 * @return {object} order config Object (used for sorting)
 * Takes in an array of columns and returns a reference object
 * with key value pairs of the column title and their ordered position
 */
function getColumnOrderReference(columns) {
    return util.reduce(columns, (ref, col, i) => {
        ref[col] = i;
        return ref;
    }, {});
}

/**
 * @param {array} keys
 * @param {array} columns
 * @return {object} order config Object (used for sorting)
 * Takes in an array of keys and columns and returns a sorted array of keys
 * following the column order
 */
function sortKeysByColumnOrder(keys, columns) {
    const orderRef = getColumnOrderReference(columns);
    return util.sortBy(keys, key => orderRef[key]);
}

/**
 *
 * @param data - model data from grid row. may contain keys not present in columns
 * @param columns - metadata from grid column headers
 * @returns string
 */
function collectionToCSV(data, columns) {
    let localColumns = columns;
    if (data.length) {
        let keys = util.chain(data[0]).keys().reject(key => key === 'warning').value();

        if (localColumns) {
            // if first column is the "Actions" column
            if (!util.has(localColumns[0], 'title')) {
                localColumns.shift();
            }
            // displayed columns in view
            localColumns = util.pluck(localColumns, 'title');
            // merge any missing columns names into the keys and then sort them in order
            keys = sortKeysByColumnOrder(util.union(keys, localColumns), localColumns);
        }
        let str = `"${keys.join('","')}"\r\n`;

        for (let i = 0; i < data.length; i += 1) {
            let line = '';
            for (let k = 0; k < keys.length; k += 1) {
                const value = data[i][keys[k]];
                if (line !== '') {
                    line += ',';
                }
                line += csvEscape(value);
            }
            str += `${line}\r\n`;
        }

        return str;
    }
    return false;
}

export default {
    escape(data) {
        return csvEscape(data);
    },

    convert(data, columns) {
        return collectionToCSV(data, columns);
    },

    encode(data, columns) {
        return window.btoa(collectionToCSV(data, columns));
    },

    exportIE(data, fileName) {
        const fn = fileName || 'data.csv';

        if (window.navigator.msSaveBlob) {
            navigator.msSaveBlob(new Blob(
                [data],
                {
                    type: 'text/csv',
                },
            ), fn);
        }

    },
};
