import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import { createStatefulSwitchWrapperView } from 'components/StatefulSwitch/StatefulSwitchWrapper';
import toggleWidgetTmpl from './toggleWidget.hbs';

export default BaseWidget.extend({
    template: toggleWidgetTmpl,
    className: 'ui-widget',

    shouldShow() {
        const { state, model } = this;
        return state === 'VIEW' && model.get('CONFIDENTIALFLAG') !== '1';
    },

    createWidget() {
        const name = this.fieldName;
        const { fieldData } = this;
        const view = createStatefulSwitchWrapperView({
            name,
            htmlId: name,
            targetField: fieldData.relatedProperty,
            labelText: fieldData.fieldLabel,
            show: this.shouldShow(),
            defaultValue: true,
        });
        return view;
    },

    onRender() {
        const StatefulSwitchView = this.createWidget();
        this.toggleWidgetRegion.show(new StatefulSwitchView());
    },

});
