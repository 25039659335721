import InquiryApi from 'system/webseries/api/inquiry';
import InquiryQueryResults from 'system/webseries/collections/inquiryQueryResults';

export default {
    /*
     *   enrollment and maintenance questions
     */
    questionOne() {
        return new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: 26035,
                typeCode: InquiryApi.SECURITY_QUESTIONS_TYPE_CODE,
                productCode: InquiryApi.UTILITY_PRODUCT_CODE,
                functionCode: InquiryApi.MAINT_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    questionTwo() {
        return new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: 26036,
                typeCode: InquiryApi.SECURITY_QUESTIONS_TYPE_CODE,
                productCode: InquiryApi.UTILITY_PRODUCT_CODE,
                functionCode: InquiryApi.MAINT_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    questionThree() {
        return new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: 26037,
                typeCode: InquiryApi.SECURITY_QUESTIONS_TYPE_CODE,
                productCode: InquiryApi.UTILITY_PRODUCT_CODE,
                functionCode: InquiryApi.MAINT_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    questionFour() {
        return new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: 26038,
                typeCode: InquiryApi.SECURITY_QUESTIONS_TYPE_CODE,
                productCode: InquiryApi.UTILITY_PRODUCT_CODE,
                functionCode: InquiryApi.MAINT_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    questionFive() {
        return new Promise((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: 26039,
                typeCode: InquiryApi.SECURITY_QUESTIONS_TYPE_CODE,
                productCode: InquiryApi.UTILITY_PRODUCT_CODE,
                functionCode: InquiryApi.MAINT_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });
    },
};
