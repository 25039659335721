import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import quickLimitsTmpl from './quickLimits.hbs';

export default Layout.extend({
    template: quickLimitsTmpl,

    ui: {
        transactionLimit: 'input[name="transactionLimit"]',
        dailyLimit: 'input[name="dailyLimit"]',
        batchLimit: 'input[name="batchLimit"]',
    },

    initialize(options) {
        this.isACH = options.isACH;
        this.model = new Model({
            transactionLimit: null,
            batchLimit: null,
            dailyLimit: null,
        });

        this.dialogTitle = locale.get('uce.quickLimitsTitle');
        this.modalClass = 'quick-limits-modal';

        this.dialogButtons = [{
            text: locale.get('button.apply'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        }];
    },

    onRender() {
        this.$('input').inputmask({
            mask: '9[9999999999999]',
            placeholder: '',
        });
    },

    cancel() {
        Dialog.close();
    },

    submit() {
        this.trigger('applyQuickLimits', this.model);
        Dialog.close();
    },

    templateHelpers() {
        const self = this;
        return {
            isACH() {
                return self.isACH;
            },
        };
    },
});
