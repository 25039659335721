import Marionette from 'backbone.marionette';
import $ from './$';
import viewMixins from './core/internal/viewMixins';

const { Region } = Marionette;
const Mixin = viewMixins(Marionette.Layout);

export default Marionette.Layout.extend(Mixin).extend({
  bindUIElements(options) {
    Marionette.Layout.prototype.bindUIElements.call(this, options);
    this.setupRegions();
  },

  setupRegions() {
    const self = this;
    const cache = {};

    this.$('[data-region]').each(function processRegionContents() {
      const regionName = $(this).data('region');
      if (typeof self[regionName] === 'undefined') {
        self.addRegion(regionName, `[data-region="${regionName}"]`);
      } else if (!(self[regionName] instanceof Region)) {
        throw new Error(`Cannot use region name "${regionName}". Already defined.`);
      } else if (cache[regionName]) {
        throw new Error(`Defined the region "${regionName}" Twice within the template`);
      }
      cache[regionName] = true;
    });
  }
});

