/**
 *
 * GRID PAGER LAYOUT OVERRIDE
 * =====================
 *
 * All overrides for grid pager layout within glu grids used in application
 *
 * Current overrides:
 *      - NH-116955/NH-123360 Merge Switch to custom mobile template when it is set in config params
 */

import pager from '@glu/grid/src/paginate/pager';
import screenManager from '@glu/screen-manager';
import simpleTpl from '@glu/grid/src/paginate/paginateSimple.hbs';
import advancedTpl from '@glu/grid/src/paginate/paginateAdvanced.hbs';
import intermediateTpl from '@glu/grid/src/paginate/paginateIntermediate.hbs';

import customMobileTpl from './customMobilePaginate.hbs';
import configParams from '../../webseries/models/configurationParameters';

export default pager.extend({
    initialize(options) {
        const useCustomMobilePaginate = configParams.get('useCustomMobilePaginate');
        if (useCustomMobilePaginate && useCustomMobilePaginate === 'true') {
            this.mobileTpl = customMobileTpl;
        } else {
            this.mobileTpl = simpleTpl;
        }
        pager.prototype.initialize.call(this, options);
    },
    getTemplate() {
        // NH-116955: override template settings for small screens
        if (screenManager.isSmall() || screenManager.isExtraSmall()) {
            return this.mobileTpl;
        }

        switch (this.type) {
        case 'simple':
            return this.mobileTpl;
        case 'intermediate':
            return intermediateTpl;
        case 'advanced':
        default:
            this.type = 'advanced';
            return advancedTpl;
        }
    },
});
