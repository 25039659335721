import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import services from 'services';
import Model from '@glu/core/src/model';
import Grid from '@glu/grid';
import InquiryRows from 'system/webseries/collections/inquiryRows';
import InquiryHeaders from 'system/webseries/collections/inquiryHeaders';
import CSV from 'common/csv';
import SavedViews from './savedViews';
import ExportButton from './exportButton';
import baseListViewTmpl from './baseListView.hbs';

export default Layout.extend({
    initialize(opts) {
        this.inquiryId = opts.inquiryId;
        this.searchType = opts.searchType;
        this.typeCode = opts.typeCode;
        this.title = opts.title || '';
        this.enableSavedViews = opts.enableSavedViews || false;
        this.enableExport = opts.enableExport || false;
        this.gridOptions = opts.gridOptions || {};
        this.controls = opts.controls || undefined;
        this.grid = undefined;
        this.savedView = undefined;
        this.exportButton = undefined;

        if (this.enableSavedViews) {
            this.initializeSavedViews();
        }

        if (this.enableExport) {
            this.initializeExport();
        }
    },

    template: baseListViewTmpl,
    className: 'page page-grid',

    /**
     * Initialize the export component, if enabled.
     */
    initializeExport() {
        /*
         * Can we pass the grid in as a reference and let
         * the export button handle its own events?
         */
        this.exportButton = new ExportButton();
        this.listenTo(this.exportButton, 'export', this.handleExport);
    },

    /**
     * Initialize the saved views component, if enabled.
     */
    initializeSavedViews() {
        const savedViewsModel = new Model();

        /*
         * Merge into inquiry API.  For now this service only returns saved
         * views for reports.
         */
        savedViewsModel.fetch({
            url: `${services.inquirySavedViews}/${this.inquiryId}`,
        });

        this.savedViews = new SavedViews({
            model: savedViewsModel,
        });

        // listen to saved view selections.
        this.listenTo(this.savedViews, 'select', this.handleViewSelect);
    },

    onRender() {
        if (this.enableSavedViews) {
            // grid should only render after saved views selection is made
            if (this.grid) {
                this.tableContent.show(this.grid);
            }

            this.savedViewsControls.show(this.savedViews);
        } else {
            this.refreshGrid();
        }

        if (this.enableExport) {
            this.exportControls.show(this.exportButton);
        }

        if (this.controls) {
            this.footerControls.show(this.controls);
        }
    },

    onClose() {
        if (this.grid) {
            this.grid.close();
        }
    },

    handleViewSelect(evt) {
        // use the selected view to re-query the backend.
        this.refreshGrid(evt);
    },

    /**
     * Can this be moved into exportButton.js if we pass the grid in as a reference?
     */
    handleExport() {
        const data = this.grid.pagerCollection
            ? this.grid.pagerCollection.toJSON() : this.grid.gridCollection.toJSON();
        const uri = `data:text/csv;base64,${CSV.encode(data)}`;

        this.exportButton.setUri(uri);
    },

    refreshGrid(view) {
        // Retrieve the metaData from the backend.
        const headerData = new InquiryHeaders({
            inquiryId: this.inquiryId,
            searchType: this.searchType,
            typeCode: this.typeCode,
            viewId: view,
        });
        headerData.fetch();

        // Retrieve the rows data from the inquiry service.
        const rowsData = new InquiryRows({
            inquiryId: this.inquiryId,
            searchType: this.searchType,
            typeCode: this.typeCode,
            viewId: view,
        });
        rowsData.fetch();

        /*
         * At this point we have swapped out the grid's collection and metadata.  It seems
         * the best course of action is to create a new grid from scratch.
         */
        const defaultOptions = {
            collection: rowsData,
            columns: headerData,
            filter: false,
            paginate: true,
            pageSize: 10,
        };

        this.grid = new Grid(util.extend(defaultOptions, this.gridOptions));

        this.refreshControls();

        this.tableContent.show(this.grid);
    },

    /**
     * Refresh the controls with a new reference to the grid.
     */
    refreshControls() {
        if (this.controls) {
            this.controls.refresh({
                grid: this.grid,
            });
        }
    },

    templateHelpers() {
        return {
            title: this.title,
        };
    },
});
