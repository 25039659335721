import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import $ from 'jquery';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';

export default Collection.extend({
    initialize(models, options) {
        this.validQueryTypes = {
            NUMERIC: false,
            STRING: false,
        };
        this.queryTerm = '';
        this.queryPage = 1;
        this.totalRows = 0;
        this.rowsPerPage = 50;
        this.depends = [];
        this.fieldName = options.fieldName;
        this.fieldKey = options.fieldKey;
        this.context = options.context;
        this.influencedAttributes = options.influencedAttributes;
        this.url = (options.overrideUrl) ? services.generateUrl(options.overrideUrl)
            : services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION);
        this.typeInfo = options.typeInfo;

        if (Array.isArray(options.typeAheadFields)) {
            this.typeAheadFields = options.typeAheadFields;
            this.validQueryTypes = util.chain(options.typeAheadFields);
            for (let i = 0; i < this.typeAheadFields.length; i += 1) {
                this.validQueryTypes[this.typeAheadFields[i].type] = true;
            }
        } else {
            this.typeAheadFields = [];
        }
        this.typeAheadFree = options.typeAheadFree;
        this.dependentTypeAheadFields = options.dependentTypeAheadFields || false;
        this.dependentFields = {};
        this.checkDependentFields(options);
    },

    sync(method, model, options) {
        const self = this;
        let queryData;
        let searchFields;

        if (method === 'read') {
            queryData = {
                rowsPerPage: self.rowsPerPage,
                startRow: (self.rowsPerPage * (self.queryPage - 1)) + 1,
                fieldName: self.fieldName,
                typeCode: self.typeInfo.typeCode,
                productCode: self.typeInfo.productCode,
                functionCode: self.typeInfo.functionCode,
                entryClass: self.context.actionData ? self.context.actionData.entryClass : null,
                depends: self.populateDepends(self.depends),
                requestParameters: self.requestParameters,
            };

            if (self.context.subType) {
                queryData.subType = self.context.subType;
            }

            /*
             * searchFields generated differently depending on whether or not
             * preloading the typeahead collection or searching through it
             */
            searchFields = (options.preload)
                ? this.createPreloadSearchCriteria(this.queryTerm)
                : this.createSearchCriteria(this.queryTerm);

            if (searchFields) {
                queryData.searchFields = searchFields;
                queryData.searchFields = this.getAdditionalSearchFields(queryData.searchFields);
            }

            if (searchFields.length > 1) {
                queryData.searchOrOperator = true;
            }

            http.post(this.url, queryData, (response) => {
                self.totalRows = response.totalRows;
                options.success(response.rows);
            }, () => {
                options.error();
            });
        }
    },

    /**
     * @method populateDepends
     * @description - method splits multi value depends to individual depends.
     * @param {Array} depends - Array of name value pairs
     * @returns {Array}
     */

    populateDepends(depends) {
        const nvpArray = [];
        util.each(depends, (nvp) => {
            if (util.isArray(nvp.value)) {
                util.each(nvp.value, (val) => {
                    nvpArray.push({
                        name: nvp.name,
                        value: val,
                    });
                });
            } else {
                nvpArray.push(nvp);
            }
        });
        return nvpArray;
    },

    addFieldValueIfNotNull(fieldObject) {
        let ret = '';
        if (fieldObject) {
            if (fieldObject.fieldValue !== null) {
                ret += ` - ${fieldObject.fieldValue}`;
            }
        }
        return ret;
    },

    checkDependentFields(options) {
        const self = this;

        if (options.view) {
            const { view } = options;

            view.model.on('change', (model) => {
                if (view.dependentTypeAheadFields) {
                    util.each(view.dependentTypeAheadFields, (dependancy) => {
                        const dependentField = `${dependancy.field.toUpperCase()}LOOKUP`;
                        const requiredField = dependancy.dependsOn;
                        const { operator } = dependancy;
                        const { dataType } = dependancy;

                        if (dependentField === self.fieldName) {
                            const value = model.get(requiredField);
                            self.dependentFields[requiredField] = {
                                fieldName: requiredField,
                                operator,
                                fieldValue: model.get(requiredField),
                                dataType: 'text',
                            };
                            if (value.length === 0) {
                                self.dependentFields[requiredField] = {};
                            } else {
                                let fvalue;
                                if ($.isArray(value)) {
                                    fvalue = value;
                                } else {
                                    fvalue = [value];
                                }

                                self.dependentFields[requiredField] = {
                                    fieldName: requiredField,
                                    operator,
                                    fieldValue: fvalue,
                                    dataType,
                                };
                            }
                        }
                    });
                }
            });
        }
    },

    getAdditionalSearchFields(searchFields) {
        this.additionalSearchFields = Object.values(this.dependentFields || {});
        return searchFields.concat(this.additionalSearchFields);
    },

    parse(rows) {
        const self = this;
        const retArr = [];
        util.each(rows, (rowParam) => {
            const row = rowParam;
            /*
             * change text description here to use an HBS template
             * or adjust in select2 format response method, probably the latter
             */

            let value = row.columns[0].fieldValue;
            if (row.columns[1]) {
                if (row.columns[1].fieldName.toUpperCase() === 'CODE') {
                    value = row.columns[1].fieldValue;
                } else if (row.columns[1].fieldName.toUpperCase() === 'BANKSORTCODE') {
                    value = row.columns[1].fieldValue;
                } else if (row.columns[1].fieldName.toUpperCase() === 'ID') {
                    value = row.columns[1].fieldValue;
                }
            }

            // Set the id here to use when getting data to add to combo box
            row.id = value;

            retArr.push({
                text: self.generateText(row.columns).replace('&amp;', '&'),
                id: value,
                mapDataList: row.mapDataList,
            });
        });
        return retArr;
    },

    generateText(column) {
        let ret = '';
        for (let i = 0; i < this.typeAheadFields.length; i += 1) {
            for (let j = 0; j < column.length; j += 1) {
                if (column[j].fieldName.toUpperCase() === this.typeAheadFields[i].field.toUpperCase() && !(column[j].fieldValue === null && typeof column[j].fieldValue === 'object')) {
                    if (!util.isEmpty(column[j].fieldValue)) {
                        ret += ` - ${column[j].fieldValue}`;
                    }
                }
            }
        }
        return ret.slice(3);
    },

    setPageSize(rowsPerPage) {
        this.rowsPerPage = (rowsPerPage > constants.MAX_SERVER_ROWSPERPAGE)
            ? constants.MAX_SERVER_ROWSPERPAGE : rowsPerPage;
    },

    hasMorePages() {
        return ((this.queryPage * this.rowsPerPage) < this.totalRows);
    },

    createSearchCriteria(queryTerm) {
        let queryType = 'STRING';

        if (this.validQueryTypes.NUMERIC === true && !Number.isNaN(Number(this.queryTerm))) {
            queryType = 'NUMERIC';
        }

        return this.typeAheadFields
            .filter(filterField => filterField.type === queryType)
            .map(filterField => ({
                fieldName: filterField.field,
                operator: filterField.operator || 'CONTAINS',
                fieldValue: Array.isArray(queryTerm) ? queryTerm : [queryTerm],
                dataType: 'text',
            }));
    },

    createPreloadSearchCriteria(queryTerm) {
        return [{
            fieldName: this.fieldKey,
            operator: 'IN',
            fieldValue: Array.isArray(queryTerm) ? queryTerm : [queryTerm],
            dataType: 'text',
        }];
    },
});
