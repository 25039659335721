import Layout from '@glu/core/src/layout';
import StepLayout from 'common/stepLayout/stepLayout';
import Collection from '@glu/core/src/collection';
import StepModel from 'common/stepLayout/stepModel';
import locale from '@glu/locale';
import scroll from 'common/util/scroll';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import systemConfig from 'system/configuration';
import userInfo from 'etc/userInfo';
import dialog from '@glu/dialog';
import MethodAccountTemplateStep from './methodAccountTemplateStep';
import ThresholdStep from './thresholdStep';
import PanelApprovalWorkflowModel from '../models/panelApprovalWorkflowModel';
import approvalSequenceWarning from './threshold/approvalSequenceWarning';
import modifyApprovalWorkflowTmpl from './modifyApprovalWorkflow.hbs';

const ApprovalSequenceWarning = approvalSequenceWarning;

export default Layout.extend({
    template: modifyApprovalWorkflowTmpl,

    ui: {
        $backButton: '[data-hook="getPrev"]',
        $nextButton: '[data-hook="getNext"]',
        $saveButton: '[data-hook="getSave"]',
    },

    events: {
        'click @ui.$backButton': 'goToPrevStep',
        'click @ui.$nextButton': 'goToNextStep',
        'click @ui.$saveButton': 'validateFinalStep',
        'click [data-hook="getCancel"]': 'cancel',
    },

    initialize(options) {
        this.contextKey = options.contextKey;
        let actionModel = new PanelApprovalWorkflowModel();
        if (store.has(`${this.contextKey}-actionModel`)) {
            actionModel = store.remove(`${this.contextKey}-actionModel`);
        }
        this.modify = !util.isNullOrUndefined(actionModel.get('PANELPROFILECODE'));
        this.model = new PanelApprovalWorkflowModel({
            userGroup: systemConfig.isAdmin() ? actionModel.get('USERGROUP') : userInfo.get('group'),
            panelProfileCode: this.modify ? actionModel.get('PANELPROFILECODE') : '',
        });

        if (!this.modify) {
            this.setHasLoadedRequiredData(true);
        }
    },

    templateHelpers() {
        return {
            headingText: this.getHeadingText(),
        };
    },

    /**
     * When in modify mode, return the modify heading text otherwise return the
     * new heading text
     */
    getHeadingText() {
        return this.modify ? locale.get('PS.panelApproval.Modify.Approval.Workflow')
            : locale.get('PS.panelApproval.New.Approval.Workflow');
    },

    loadRequiredData() {
        this.model.fetch({
            modify: true,
        }).then(() => {
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    initializeSteps() {
        this.methodAccountTemplateView = new MethodAccountTemplateStep({
            model: this.model,
            modify: this.modify,
        });

        this.thresholdStepView = new ThresholdStep({
            model: this.model,
            modify: this.modify,
        });

        this.stepLayoutView = new StepLayout({
            collection: this.getStepCollection(),
        });
        this.stepLayoutView.on('step:changed', this.evaluateButtons.bind(this));
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.initializeSteps();
            this.stepLayoutRegion.show(this.stepLayoutView);
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * Combine all of the step models and submit them to the server
     */
    save() {
        this.ui.$saveButton.attr('aria-busy', true);
        this.model.save({}, {
            modify: this.modify,
        }).then(this.handleSaveSuccess.bind(this))
            .catch(this.handleSaveError.bind(this));
    },

    /**
     * Navigate to the list view
     */
    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    /**
     * Invoke the goToNextStep function on the stepLayoutView
     */
    goToNextStep() {
        this.stepLayoutView.goToNextStep();
    },

    /**
     * Invoke the goToPrevStep function on the stepLayoutView
     */
    goToPrevStep() {
        this.stepLayoutView.goToPrevStep();
    },

    evaluateButtons(step) {
        const hasNextStep = this.stepLayoutView.hasNextStep(step);
        this.showHideButtons(hasNextStep, this.ui.$nextButton);
        this.showHideButtons(!hasNextStep, this.ui.$saveButton);
        this.showHideButtons(this.stepLayoutView.hasPrevStep(step), this.ui.$backButton);
    },

    showHideButtons(evaluator, button) {
        if (evaluator) {
            button.removeClass('hide');
        } else {
            button.addClass('hide');
        }
    },

    validateFinalStep() {
        if (this.thresholdStepView.isValidStep()) {
            const thresholdData = this.thresholdStepView.getThresholdData();
            this.model.set('thresholds', thresholdData);
            const thresholdGaps = this.getApprovalGaps(thresholdData);
            if (thresholdGaps.length) {
                this.showApprovalGapDialog(thresholdGaps);
                return;
            }
            this.save();
        } else {
            scroll.scrollToFirstError();
        }
    },

    showApprovalGapDialog(thresholdGaps) {
        const warningModal = new ApprovalSequenceWarning({
            thresholdGaps,
            currency: this.model.get('currency'),
        });
        this.listenToOnce(warningModal, 'warning:ok', this.save.bind(this));
        dialog.custom(warningModal);
    },

    handleSaveSuccess(response) {
        store.set(`${this.contextKey}-alertMessage`, 'ADD');
        store.set(`${this.contextKey}-confirms`, response);
        this.cancel();
    },

    handleSaveError(response) {
        this.ui.$saveButton.attr('aria-busy', false);
        const { message } = response.responseJSON;
        this.alertRegion.show(alert.negative(message.join(' ')));
        scroll.scrollToRegion(this.alertRegion);
    },

    /**
     * Get a collection of StepModels
     * @returns {Object} Collection of StepModels
     */
    getStepCollection() {
        return new Collection([
            new StepModel({
                label: locale.get('PS.panelApproval.Payment.Methods.And.Accounts'),
                view: this.methodAccountTemplateView,
                selected: true,
                beforeLeaveStep: this.validateStep(this.model.isValid, this.model),
            }),

            new StepModel({
                label: locale.get('PS.panelApproval.Threshold.Amounts.And.Approvers'),
                view: this.thresholdStepView,
                selected: false,
                beforeLeaveStep: this.validateStep(
                    this.thresholdStepView.isValidStep,
                    this.thresholdStepView,
                ),
            }),
        ]);
    },

    /**
     * Return a new function that validates the model and throws an error
     * when it is invalid
     * @param {function} validationFunction - function that will be used to
     * validate the step
     * @param {object} context - context to invoke the validationFunction
     * @returns {function()}
     */
    validateStep(validationFunction, context) {
        return () => {
            const isValid = validationFunction.call(context);
            if (!isValid) {
                scroll.scrollToFirstError();
                return Promise.reject(new Error('Invalid Step'));
            }
            return isValid;
        };
    },

    onClose() {
        this.stopListening();
    },

    /**
     * Get a list of thresholds, by amount, that have approver gaps in any
     * of the approver sequences
     * @param {Object} thresholds
     */
    getApprovalGaps(thresholds) {
        return thresholds.reduce((accum, threshold) => {
            const sequencesWithGaps = threshold.get('approverSequences')
                .reduce(this.getGapSequences.bind(this), []);
            // When any sequences have gaps, add this threshold to the accum
            if (sequencesWithGaps.length) {
                return [
                    ...accum,
                    {
                        amount: threshold.get('amount'),
                        sequences: sequencesWithGaps,
                    },
                ];
            }
            return accum;
        }, []);
    },

    /**
     * Get all of the sequences that have approver gaps for this set approver
     * sequences
     * @param {Array} accum
     * @param {Object} sequence
     * @param {number} index
     * @returns {*}
     */
    getGapSequences(accum, sequence, index) {
        const hasGaps = sequence.get('approvers').reduce(this.consectutiveApprovers, -1) === false;
        // When gaps have been found, push them to the accumulator
        if (hasGaps) {
            return [...accum, index + 1];
        }
        return accum;
    },

    /**
     * Evaluate an array of approvers and determine if there are any gaps between
     * the approvers
     * @param {number} lastValidIndex - index of the last valid approver
     * @param {Object} approver - current approver
     * @param {number} index - current index
     * @returns {number|boolean}
     */
    consectutiveApprovers(lastValidIndex, approver, index) {
        /*
         * Already encountered a gap in the approvers, just return lastValidIndex as
         * it is false
         */
        if (lastValidIndex === false) {
            return lastValidIndex;
        }
        // When approver doesn't have an id, it is invalid, so return the lastValidIndex
        if (util.isNullOrUndefined(approver.get('id'))) {
            return lastValidIndex;
        }
        /*
         * Made it this far, so approver is valid. Find the different between the index of the
         * current approver and the lastValidIndex. If it is greater than one, there is a gap
         * between the indexes with invalid approvers.
         */
        if (index - lastValidIndex > 1) {
            return false;
        }
        return index;
    },
});
