import ItemView from '@glu/core/src/itemView';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import template from './item.hbs';

export default ItemView.extend({
    tagName: 'div',
    className: 'email-item form-container',
    template,
    loadingTemplate,

    ui: {
        $contact: '.email',
        $defaultBlock: '.default-options',
    },

    delegateEvents(...args) {
        ItemView.prototype.delegateEvents.apply(this, args);
        /*
         * if the editable state is change, rerender the page
         * This is changed when the user clicks the add another phone
         */
        this.listenTo(
            this.model,
            {
                'change:editable': this.render,
                'change:default': this.render,
                'change:emailAddress': this.hideShowDefaultButton,
            },
        );
    },

    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    templateHelpers() {
        return {
            cid: this.cid,
            isModifyMode: this.modifyMode,
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.model.get('emailAddress').length === 0) {
                this.ui.$defaultBlock.hide();
            }
        } else {
            this.loadRequiredData();
        }
    },

    setDefault() {
        this.appBus.trigger('security:contact:default', this.model.cid);
    },

    delete() {
        if (this.model.get('uid') === -1) {
            this.model.destroy();
        } else {
            this.model.set('removeRecord', true);
            this.render();
        }
    },

    hideShowDefaultButton() {
        this.ui.$defaultBlock.toggle(this.ui.$contact.val().length > 0);
    },
});
