var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\"\n            data-hook=\"getAccountSettingsTab\">\n            <a \n                class=\"NavTabs-link btn btn-tertiary\"\n                aria-controls=\"showAccountSettings\"\n                data-action=\"showAccountSettings\"\n                aria-selected=\"true\"\n                tabindex=\"0\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.PPRLESS.Account.Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":15,"column":71}}}))
    + "\"\n                id=\"showAccountSettingsTab\"\n                role=\"tab\"\n            >\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.PPRLESS.Account.Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":59}}}))
    + "\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\" data-hook=\"getPaperlessSettingsTab\">\n            <a \n                class=\"NavTabs-link btn btn-tertiary\"\n                aria-controls=\"showPaperlessSettings\"\n                data-action=\"showPaperlessSettings\"\n                aria-selected=\"false\"\n                tabindex=\"-1\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.PPRLESS.Paperless.Statements",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":31,"column":76}}}))
    + "\"\n                id=\"getPaperlessSettingsTab\"\n                role=\"tab\"\n            >\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.PPRLESS.Paperless.Statements",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":64}}}))
    + "\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"widget.30.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":65}}}))
    + "</h1>\n</div>\n<ul id=\"account-settings-tablist\" class=\"NavTabs nav nav-tabs nav-icons\" role=\"tablist\"\n    data-hook=\"getNavTabs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"accountSettingsEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"paperlessSettingsEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n<div data-region=\"tabContentRegion\"></div>";
},"useData":true});