/**
 * TOA Locations Tab Extension
 */
import services from 'services';
import searchFieldsUtil from 'common/util/searchFieldsUtil';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import CheckGrid from './checkGrid';
import constants from './constants';
import gridDataAccess from './gridDataActions';

const TOALocationsGrid = CheckGrid.extend({
    // Identifies if there is only one type in a group
    singleType: true,

    /**
     * Return the location data entitlement attribute
     * @return {string}
     */
    getEntityName() {
        return constants.ENTITY_NAME.TOA_LOCATIONS;
    },

    /**
     * Return the location data inquiry id for the service call
     * @return {string}
     */
    getEntityID() {
        return constants.REQUEST_ENTITY.TOA_LOCATIONS;
    },

    /**
     * Return the location data inquiry id for current/future for the service call
     * @return {string}
     */
    getCFEntityID() {
        return constants.REQUEST_ENTITY.TOA_LOCATIONS_CF;
    },

    /**
     * Return the location type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return constants.ENTITY_TYPE.TOA_LOCATIONS;
    },

    /**
     * Return the available TOA location types to populate the grid
     * @return {Array}
     */
    getRecords() {
        return gridDataAccess.toaLocationRecords(this.model);
    },

    /**
     * Override the method from checkGrid
     * Determine if there are any entitlements for this tab.
     * Also, the tab should show if the config param for TOA locations is enabled
     * @return {Boolean}
     */
    shouldShow() {
        const TOAEnabled = serverConfigParams.get('TOAEnabled');
        return gridDataAccess.getAllEnabledEntitlements(this.getRecords()).length !== 0
            && this.options.toaRecordsFound
            && TOAEnabled && TOAEnabled.toUpperCase() === 'TRUE';
    },
    /**
     * @method getTOARecords
     * A way to check if there are any TOA records assigned to the user group
     * @returns {Promise}
     */
    checkForTOARecords() {
        const serviceURL = services.generateUrl('/users/userCentric/v2/getDataEntitlements');
        const { REQUEST_ENTITY } = constants;
        const itemData = gridDataAccess.prepInquiryForRequest(REQUEST_ENTITY.TOA_LOCATIONS);
        const request = {
            startRow: 0,
            rowsPerPage: 1,
            requestParameters: {
                item: [
                    ...itemData,
                    {
                        name: 'type',
                        value: constants.ENTITY_TYPE.TOA_LOCATIONS,
                    }],
            },
            searchFields: this.getSearchFields(),
        };
        return new Promise((resolve, reject) => {
            http.post(serviceURL, request, (response) => {
                resolve(response);
            }, (e) => {
                reject(e);
            });
        });
    },

    getSearchFields() {
        const mode = this.getMode();
        const searchFields = [];
        searchFields.push(searchFieldsUtil.createSearchField(
            'Usergroup',
            this.model.userGroupModel.get('id'),
        ));

        searchFields.push(searchFieldsUtil.createSearchField(
            'actionMode',
            mode,
        ));

        if (this.model.roleModel) {
            searchFields.push(searchFieldsUtil.createSearchField(
                'RoleId',
                this.options.copyingRole || this.getRoleId(),
            ));
        } else {
            searchFields.push(searchFieldsUtil.createSearchField(
                'UserID',
                this.getUserId(),
            ));
        }
        return searchFields;
    },

    /**
     * @method getUserId
     * @returns {string} - user id
     */
    getUserId() {
        if (this.model.get('userInfo')) {
            const userId = util.findWhere(
                this.model.get('userInfo').item,
                {
                    name: 'USERID',
                },
            );
            if (userId && userId.value) {
                return userId.value;
            }
            return '';
        }
        return '';
    },
});

export default TOALocationsGrid;
