import Layout from '@glu/core/src/layout';
import Grid from '@glu/grid/src/grid';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import { postData } from 'common/util/services';
import servicesUtil from 'system/servicesList';
import ChipView from 'common/uiWidgets/chipWidget/chipWidget';
import template from './approvalModal.hbs';
import localeKeys from './localeKeys';

const ApprovalModal = Layout.extend({
    template,

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    regions: {
        gridRegion: '[data-region="gridRegion"]',
    },

    events: {
        'click [data-hook="cancel-button"]': 'cancel',
    },

    initialize(options) {
        this.options = options;

        this.grid = new Grid({
            collection: options.changedCollection,
            columns: [
                {
                    field: 'ENABLED',
                    label: locale.get(localeKeys.currentState),
                    cellView: ChipView,
                    chipField: 'ENABLED',
                    width: 130,
                },
                {
                    field: 'STAGED',
                    label: locale.get(localeKeys.requestState),
                    cellView: ChipView,
                    chipField: 'STAGED',
                    width: 130,
                },
                {
                    field: 'NAME',
                    label: locale.get(localeKeys.features),
                },
                {
                    field: 'RELEASE',
                    label: locale.get(localeKeys.release),
                },
            ],
            disableDynamicColumnWidths: true,
        });

        Layout.prototype.initialize.call(this, options);
    },

    onRender() {
        this.gridRegion.show(this.grid);
    },

    /**
     * Get the base request payload for an initialize request
     * @returns Object
     */
    getBaseInitializeRequestObject() {
        return {
            grids: [
                {
                    name: 'FEATURES_GRID',
                    items: [
                        /*
                         * full payload will include something like:
                         * item: [
                         *     {
                         *         name: 'FEATUREID',
                         *         value: 'TCH20
                         *     },
                         *     {
                         *         name: 'STAGED',
                         *         value: 1 // 1 === LIVE, 0 === DARK
                         *     }
                         * ]
                         */
                    ],
                },
            ],
            /*
             * TODO: This will eventually need to change in the future when other
             * features are added in future releases
             */
            item: {
                item: [
                    {
                        name: 'FEATUREHEADERID',
                        value: '1',
                    },
                ],
            },
        };
    },

    /**
     * Get the full request payload for initialize request
     * @returns Object
     */
    getInitializeRequestObject() {
        const baseRequestObject = this.getBaseInitializeRequestObject();
        baseRequestObject.grids[0].items = this.options.changedCollection.reduce((acc, cur) => (
            [...acc, {
                item: [
                    {
                        name: 'FEATUREID',
                        value: cur.get('FEATUREID'),
                    },
                    {
                        name: 'STAGED',
                        value: cur.get('STAGED'),
                    },
                ],
            }]
        ), []);
        return baseRequestObject;
    },

    /**
     * Get approval request object
     * TODO: This will need to be tweaked when more features are added
     * @returns Object
     */
    getApproveRequestObject() {
        return {
            item: {
                item: [
                    {
                        name: 'FEATUREHEADERID',
                        value: '1',
                    },
                ],
            },
        };
    },

    save() {
        const requestUrl = this.options.isApprove ? servicesUtil.generateUrl('featureControl/APPROVE')
            : servicesUtil.generateUrl('featureControl/UPDATE');
        const requestObject = this.options.isApprove ? this.getApproveRequestObject()
            : this.getInitializeRequestObject();
        const refreshName = this.options.isApprove ? 'approve' : 'update';

        postData(requestUrl, requestObject).then((response) => {
            this.options.refreshData(refreshName, {
                requestor: userInfo.get('id'),
                response,
            });
            this.close();
        }).catch((xhr) => {
            this.options.refreshData(refreshName, {
                response: xhr.responseJSON,
            });
            this.close();
        });
    },

    cancel() {
        this.options.refreshData('cancel');
        this.close();
    },

    templateHelpers() {
        return {
            isApprove: this.options.isApprove,
            localeKeys,
        };
    },
});

export default ApprovalModal;
