import http from '@glu/core/src/http';
import services from 'services';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';

export default Collection.extend({
    model: Model,

    initialize(models, options) {
        Collection.prototype.initialize.call(this, options);
        this.userGroup = options.userGroup;
    },

    sync(method, collection, options) {
        let url;
        let xhr;
        if (method === 'read') {
            url = services.generateUrl('panelmaint/READCHILDREN');
            xhr = http.post(url, {
                item: {
                    item: [{
                        name: 'USERGROUP',
                        value: this.userGroup,
                    }],
                },
            }, options.success, options.error);
        }

        if (method === 'save') {
            url = services.generateUrl('panelmaint/update');
            xhr = http.post(url, this.toServerJSON(), options.success, options.error);
        }

        if (!xhr) {
            return false;
        }

        this.trigger('request', collection, xhr, options);
        return xhr;
    },

    save(options) {
        let optionsParam = options;
        /*
         * simplified implementation of model.save for collections
         * does not take any options
         */
        if (!util.isObject(optionsParam)) {
            optionsParam = {};
        }

        const self = this;
        const oldSuccess = optionsParam.success;
        const oldError = optionsParam.error;

        const extendedOptions = util.extend(optionsParam, {
            success(resp) {
                if (oldSuccess) {
                    oldSuccess(resp);
                }
                self.trigger('sync', self, resp, {});
            },
            error(resp) {
                if (oldError) {
                    oldError(resp);
                }
                self.trigger('error', self, resp, {});
            },
        });

        return this.sync('save', this, extendedOptions);
    },

    parse(response) {
        if (response.grids.length && response.grids[0].items) {
            return util.map(response.grids[0].items, (item) => {
                const obj = transform.pairsToHash(item.item);
                return {
                    type: obj.APPROVERTYPE,
                    name: obj.NAME,
                };
            });
        }

        return [];
    },

    toServerJSON() {
        return {
            provisioningAction: 'U',
            userGroup: this.userGroup,
            approvers: this.toJSON(),
        };
    },
});
