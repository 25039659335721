<nav class="glu-menu">
    <div class="toggle" data-action="menuToggle" data-qa="glu-menu-toggle-wrap">
        <button type="button" class="btn btn-tertiary" title="Open/Close menu" data-qa="glu-menu-toggle-btn">
            {{icon "arrow-left"}}
            {{icon "arrow-right"}}
        </button>
    </div>
    <ul class="top" role="menubar" tabindex="0" aria-controls="main">
        {{#each modules}}
            {{#each components}}
                <li class="parent" role="presentation">
                    {{#if actions}}
                        <span id="gmp-{{key}}" class="item-{{key}} has-dd" role="menuitem" aria-haspopup="true" {{#if @../first}}tabindex="0" {{else}}tabindex="-1"{{/if}}>
                            {{#if @root.menuIcon}}{{icon @root.menuIcon}}{{else}}{{icon @root.menuCategory}}{{/if}}
                            {{name}}
                            <span class="caret" role="presentation"></span>
                        </span>
                        <ul class="dropdown" role="menu" aria-hidden="true" aria-labelledby="gmp-{{key}}">
                            {{#each actions}}
                                <li role="presentation">
                                    {{#if key}}
                                        <a class="item-{{key}}" href="/{{../../key}}/{{key}}" role="menuitem" aria-controls="main" tabindex="-1">
                                        	{{name}}
                                        </a>
                                    {{else}}
                                        <span class="item-separator">{{name}}</span>
                                    {{/if}}
                                </li>
                            {{/each}}
                        </ul>
                    {{else}}
                        <a id="gmp-{{key}}" class="item-{{key}}" href="/{{key}}" role="menuitem" aria-controls="main" {{#if @../first}}tabindex="0"{{else}}tabindex="-1"{{/if}}>
                        	{{#if @root.menuIcon}}{{icon @root.menuIcon}}{{/if}}
                        	{{name}}
                        </a>
                    {{/if}}
                </li>
            {{/each}}
        {{/each}}
    </ul>
</nav>
