var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"LoginControls\">\n        <button class=\"btn btn-tertiary\" data-hook=\"getAddItemBtn\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.addlocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":67},"end":{"line":6,"column":95}}}))
    + "</button>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"LocationLoginSection\">\n    <h3 class=\"LocationLoginSection-title\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.locationloginname",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":77}}}))
    + "</h3>\n    <div class=\"LoginCollectionItems\" data-hook=\"getLoginCollectionContainer\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notViewMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "</section>";
},"useData":true});