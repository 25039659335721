import EntryView from 'common/dynamicPages/views/workflow/entry';
import workspaceHelper from 'common/workspaces/api/helper';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import f1 from 'system/utilities/f1';
import store from 'system/utilities/cache';
import MySettingsModel from 'app/administration/models/alerts/mySettingsModel';
import DataApi from 'common/dynamicPages/api/data';
import ViewApi from 'app/administration/common/dynamicPages/api/view';
import errorHandlers from 'system/error/handlers';
import userInfo from 'etc/userInfo';
import AdminUtil from 'app/administration/common/util';
import mySettingsViewTmpl from './mySettingsView.hbs';

/**
 * mySettingsView
 * This view is for the My Settings widget.  It allows a user to change its
 * contact information
 * quickly without the use of the User Maintenenace widget. The view uses the
 * standalone MDF
 * view to render the form.
 */
const MySettingsView = EntryView.extend({
    template: mySettingsViewTmpl,

    /**
     * @param  {object} opts - pass in the return route
     */
    initialize(opts) {
        const options = {
            menuCategory: 'ADMINISTRATION',
            serviceFunc: null,
            serviceName: 'tableMaintenance/mySettings',
            businessType: null,
            mode: 'modify',
            context: {
                serviceName: 'tableMaintenance/mySettings',
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: '_USERSET',
                actionData: {
                    productCode: '_ADMIN',
                    functionCode: 'MAINT',
                    typeCode: '_USERSET',
                },
            },
        };

        this.model = new MySettingsModel(userInfo, options.context);
        if (opts.returnRoute) {
            store.set('tableMaintenance_mySettings-listRoute', opts.returnRoute);
        }
        store.set('tableMaintenance_mySettings-actionModel', this.model);
        EntryView.prototype.initialize.call(this, options);
    },

    onRender() {
        if (this.hasLoadedRequiredData() && serverConfigParams.get('EnableInternationalPhoneNumbers') !== 'true') {
            /**
             * Add an input mask for the phone numbers when international phone numbers are not
             * enabled.
             */
            /**
             * Defer this until all the fields are rendered.
             */
            util.defer(util.bind(this.setInputMasks, this));
        }
        EntryView.prototype.onRender.call(this);
    },

    /**
     * setInputMasks
     * This function sets the phone number mask on the 3 fields that are phone numbers.
     */
    setInputMasks() {
        const form = f1.getForm(this);
        form.field('PHONENUMBER').$el.inputmask(userInfo.getPhoneFormat());
        form.field('MOBILEPHONENUMBER').$el.inputmask(userInfo.getPhoneFormat());
        form.field('FAXNUMBER').$el.inputmask(userInfo.getPhoneFormat());
    },

    loadRequiredData() {
        const self = this;

        if (this.mode !== 'modify') {
            return;
        }

        // Get the MDF model that describes the field layout for this view.
        DataApi.model.generateModelFromModel(this.model).then((newModel) => {
            /**
             * Read the current user data and populate the view.
             */
            newModel.fetch({
                success() {
                    self.storedModel = newModel;
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },
                error: util.bind(errorHandlers.loading, self),
            });
        });
    },

    /**
     * save
     * Update the User's contact information from the form.
     */
    save() {
        const self = this;

        /**
         * Make sure all phone numbers are stripped before the validators are applied.
         * The call to ViewApi.convertPhoneNumbersToNumber() strips all non-numeric
         * characters
         * from the text. If the given value is empty or undefined, it is returned.
         */
        this.model.set({
            EMAILADDRESS: this.storedModel.get('EMAILADDRESS'),
            PHONENUMBER: ViewApi.convertPhoneNumbersToNumber(this.storedModel.get('PHONENUMBER')),
            MOBILEPHONENUMBER: ViewApi.convertPhoneNumbersToNumber(this.storedModel.get('MOBILEPHONENUMBER')),
            FAXNUMBER: ViewApi.convertPhoneNumbersToNumber(this.storedModel.get('FAXNUMBER')),
        });

        /**
         * Call save on this model to update the user contact information.
         */
        this.model.save(null, {
            success(userModel, response) {
                if (response.responseHeader.message && response.responseHeader.message.length) {
                    /**
                     * Display the success message at the top of the view.
                     */
                    const msgs = response.responseHeader.message.join('\n');
                    self.alertRegion.show(alert.success(msgs, {
                        canDismiss: true,
                        animate: true,
                    }));
                }
                window.scrollTo(0, 0);
            },
            error(userModel, response) {
                AdminUtil.errorAlert(self.alertRegion, response.responseJSON);
            },
        });
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'USER_MYSETTINGS',
    view: MySettingsView,
    options: {},
});

export default MySettingsView;
