import moment from 'moment';

const AG_DATE_FORMAT = 'YYYY-MM-DD';
const getConditionName = data => {
  const currentConditions = Object.keys(data).filter(key => /condition/.test(key));
  const hasConditions = currentConditions.length;
  const condition = `condition${hasConditions ? hasConditions + 1 : 2}`;
  return condition;
};

const normalizeFilterData = filterData => {
  if (filterData.type === 'between') {
    return {
      ...filterData,
      filterType: 'number',
      type: 'inRange',
      filter: filterData.filter.start,
      filterTo: filterData.filter.end
    };
  }
  if (filterData.filterType !== 'date') {
    return filterData;
  }
  const {
    dateFrom,
    dateTo,
    format = AG_DATE_FORMAT
  } = filterData;
  return {
    ...filterData,
    dateFrom: format === AG_DATE_FORMAT
      ? dateFrom
      : moment(dateFrom, format).format(AG_DATE_FORMAT),
    dateTo: format === AG_DATE_FORMAT
      ? dateTo
      : moment(dateTo, format).format(AG_DATE_FORMAT)
  };
};

export const convertGlutoAgGrid = (filters = []) => filters.reduce((merged, filter) => {
  const { filterData } = filter;
  if (!merged[filter.field]) {
    return {
      ...merged,
      [filter.field]: {
        ...normalizeFilterData(filterData),
        ...(filter.operator && { operator: filter.operator })
      }
    };
  }

  const { operator, ...data } = merged[filter.field];
  const condition = getConditionName(data);
  return {
    ...merged,
    [filter.field]: {
      ...(condition !== 'condition2' ? data : { condition1: data }),
      [condition]: normalizeFilterData(filterData),
      operator
    }
  };
}, {});

export default convertGlutoAgGrid;
