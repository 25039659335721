/**
 * Get a list of available options for the modal. When coming from
 * native mobile grid, rowData has ACTIONLIST. Convert that attribute to use
 * the same structure as the normal grid
 * @param {Object} options
 * @returns {Array}
 */
const getAvailableActions = (options) => {
    if (options.rowData) {
        const actionList = options.rowData.ACTIONLIST.split('^');
        return actionList.map((action) => {
            const [actionName, actionLabel] = action.split(',');
            return {
                action: actionName,
                label: actionLabel,
            };
        });
    }
    return options.model ? options.model.buttons || [] : [];
};
export default getAvailableActions;
