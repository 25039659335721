import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, legacyTheme } from '@glu/theming';
import { legacyTheme as btiqeLegacyTheme } from '@dbiqe/common';
import DropdownRemoteFilter from 'components/DropdownRemoteFilter/DropdownRemoteFilter';
import useLocale from 'hooks/useLocale';
import FormField from 'components/FormField/FormField';

const propTypes = {
    onFilter: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    })),
    value: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    })),
    fetchImmediately: PropTypes.bool,
};
const defaultProps = {
    options: [],
    value: [],
    fetchImmediately: true,
};

const EmailRecipients = ({
    onFilter,
    options,
    value,
    fetchImmediately,
    onClose: onCloseProp,
    ...props
}) => {
    const { getLocaleString } = useLocale();
    const [possibleOptions] = useState(options);
    const [selection, setSelection] = useState(value);

    const onChange = (name, selected) => {
        setSelection(selected);
    };

    const onClose = () => {
        onCloseProp(selection);
    };

    return (
        <ThemeProvider theme={btiqeLegacyTheme} baseTheme={legacyTheme}>
            <section className="section section-container">
                <FormField>
                    <DropdownRemoteFilter
                        labelText={getLocaleString('GIR.User.Name')}
                        onFilter={onFilter}
                        name="emailRecipients"
                        showSelected={2}
                        options={possibleOptions}
                        multiselect
                        fetchImmediately={fetchImmediately}
                        onClose={onClose}
                        onChange={onChange}
                        value={selection}
                        {...props}
                    />
                </FormField>
            </section>
        </ThemeProvider>
    );
};

EmailRecipients.propTypes = propTypes;
EmailRecipients.defaultProps = defaultProps;

export default EmailRecipients;
