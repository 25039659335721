import $ from 'jquery';
import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import largeCommentTmpl from './largeComment.hbs';

const COMMENTS_MAX_LENGTH = 80;

export default ItemView.extend({
    type: 'info',
    template: largeCommentTmpl,
    title: 'Comment',
    className: 'modal',
    maxLength: 80,

    initialize(options) {
        // Add event listeners
        this.events = {
            'keyup #business-comments': 'showCountCharacters',
            'click :checkbox': 'toggleForm',
        };
        this.model = options.model.clone();
        this.adjustmentReasons = options.adjustmentReasons;
        this.referenceNumberTypes = options.referenceNumberTypes;
    },

    onRender() {
        const reasonSelection = this.ui.$adjustmentReason.comboBox().find(`option[value="${this.model.get('adjustmentReason')}"]`).get(0);
        const typeSelection = this.ui.$referenceNumberType.comboBox().find(`option[value="${this.model.get('referenceNumberType')}"]`).get(0);

        this.ui.$adjustmentReason.comboBox();
        if (reasonSelection) {
            this.ui.$adjustmentReason.comboBox(
                'data',
                {
                    value: reasonSelection.value,
                    text: reasonSelection.text,
                },
            );
        }

        this.ui.$referenceNumberType.comboBox();
        if (typeSelection) {
            this.ui.$referenceNumberType.comboBox(
                'data',
                {
                    value: typeSelection.value,
                    text: typeSelection.text,
                },
            );
        }

        if (this.ui.$remittanceCheckbox.val() === 'true') {
            this.setShowLargeFormProperties();
        } else {
            this.setShowSmallFormProperties();
        }
    },

    showCountCharacters(e) {
        // COMMENTS_MAX_LENGTH can be a Global variable in future
        const $target = $(e.target);
        const $targetTxtAreaVal = $target.val();
        const cmtsTxtAreaReMainLen = COMMENTS_MAX_LENGTH - $targetTxtAreaVal.length;

        $target.parent().find('.char-counter').text(`${locale.get('sbPayments.charactersRemaining')} ${cmtsTxtAreaReMainLen}`);
    },

    ui: {
        $remittanceCheckbox: '[data-hook="usePaymentRemittance"]',
        $fullForm: '#full-remittance',
        $partialForm: '#partial-remittance',
        $adjustmentReason: '[data-hook="adjustmentReason"]',
        $referenceNumberType: '[data-hook="referenceNumberType"]',
    },

    templateHelpers() {
        return {
            title: this.title,
            maxLength: this.maxLength,
            adjustmentReasons: this.adjustmentReasons,
            referenceNumberTypes: this.referenceNumberTypes,
        };
    },

    closeModal() {
        this.close();
    },

    toggleForm(e) {
        if (e.currentTarget.checked) {
            this.setShowLargeFormProperties();
        } else {
            this.setShowSmallFormProperties();
        }
        return true;
    },

    saveComment() {
        if (this.ui.$remittanceCheckbox.attr('checked') === 'checked') {
            this.model.set('usePaymentRemittance', true);
        } else {
            this.model.set('usePaymentRemittance', false);
        }

        this.trigger('save', this.model);
        this.close();
    },

    setShowLargeFormProperties() {
        // show large
        this.ui.$fullForm.attr('aria-hidden', 'false');
        // hide small
        this.ui.$partialForm.attr('aria-hidden', 'true');
        this.ui.$remittanceCheckbox.attr('checked', 'checked');
    },

    setShowSmallFormProperties() {
        // hide large
        this.ui.$fullForm.attr('aria-hidden', 'true');
        // show small
        this.ui.$partialForm.attr('aria-hidden', 'false');
        this.ui.$remittanceCheckbox.removeAttr('checked');
    },
});
