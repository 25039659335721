var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.fromAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":69},"end":{"line":3,"column":104}}}))
    + "</span></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.toAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":65},"end":{"line":12,"column":98}}}))
    + "</span></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.effectiveDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":65},"end":{"line":21,"column":102}}}))
    + "</span></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.comment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":65},"end":{"line":27,"column":96}}}))
    + "</span></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":65},"end":{"line":35,"column":95}}}))
    + "</span></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">&nbsp;</span></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-success btn-left\" data-action=\"add\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.add.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":96},"end":{"line":49,"column":120}}}))
    + "\"><span class=\"icon-plus\"></span></button>\n                <button type=\"button\" class=\"btn btn-success btn-right\" data-action=\"clear\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.clear.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":99},"end":{"line":50,"column":125}}}))
    + "\"><span class=\"icon-cancel\"></span></button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-success btn-left\" data-action=\"save\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.save.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":97},"end":{"line":52,"column":122}}}))
    + "\"><span class=\"icon-tick\"></span></button>\n                <button type=\"button\" class=\"btn btn-success btn-right\" data-action=\"cancel\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":100},"end":{"line":53,"column":127}}}))
    + "\"><span class=\"icon-cancel\"></span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <span data-region=\"fromAccountRegion\" data-hook=\"from-account\"></span>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"fromAccountId\"></span>\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <span data-region=\"toAccountRegion\" data-hook=\"to-account\"></span>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"toAccountId\"></span>\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input payment-date\"></div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <input type=\"button\" id=\"comment\" class=\"btn btn-link\" data-hook=\"commentButton\" data-action=\"popComment\"/>\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <input id=\"amount\" type=\"text\" class=\"form-control\" data-bind=\"model\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"sbPayments.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":85},"end":{"line":38,"column":115}}}))
    + "\" name=\"amount\" maxlength=\"12\"/>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"amount\"></span>\n    </div>\n</td>\n<td class=\"align-center btn-cell\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":4},"end":{"line":45,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <div class=\"btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":54,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</td>\n";
},"useData":true});