import $ from 'jquery';
import services from 'services';
import dialog from '@glu/dialog';
import systemConfig from 'system/configuration';
import helpers from 'components/AsyncReporting/helpers';

export default {
    buildExportData(format, inquiryId, gridView) {
        // debugger;

        const exportData = {};
        exportData.outputFormat = format;
        exportData.pageType = 'LETTER';
        exportData.numberOfRows = gridView.wrapper.rows.totalCount;
        exportData.viewId = gridView.wrapper.viewId;
        if (inquiryId) {
            exportData.inquiryId = inquiryId;
        }

        exportData.actionData = {};
        exportData.actionData.productCode = gridView.context.productCode;
        exportData.actionData.functionCode = gridView.context.functionCode;
        exportData.actionData.typeCode = gridView.context.typeCode;

        // Check for Sort by Column
        if (gridView.wrapper.sortKey) {
            const sortField = {};
            sortField.fieldName = gridView.wrapper.sortKey;
            sortField.sortOrder = gridView.wrapper.sortOrder;
            exportData.sortFields = [];
            exportData.sortFields.push(sortField);
        }

        if (gridView.sortFields) {
            exportData.sortFields = gridView.sortFields;
        }

        // Check for Filtering
        const { filters } = gridView.wrapper;
        if (filters && filters.length > 0) {
            const searchFields = [];
            for (let i = 0; i < filters.length; i += 1) {
                const filterType = filters.models[i].get('type');
                if (filterType === 'string') {
                    searchFields[searchFields.length] = {
                        fieldName: filters.models[i].get('field'),
                        operator: 'CONTAINS',
                        fieldValue: [filters.models[i].get('value')],
                        dataType: 'text',
                    };
                } else if (filterType === 'date' || filterType === 'amount' || filterType === 'number') {
                    const operVal = filters.models[i].get('equality');
                    let fieldVal;
                    if (operVal === 'BETWEEN') {
                        fieldVal = filters.models[i].get('value');
                    } else {
                        fieldVal = [filters.models[i].get('value')];
                    }
                    searchFields[searchFields.length] = {
                        fieldName: filters.models[i].get('field'),
                        operator: operVal,
                        fieldValue: fieldVal,
                        dataType: filterType,
                    };
                }
            }
            exportData.searchFields = searchFields;
        }

        // Get Columns and Order
        if (gridView.wrapper.columns) {
            const columns = [];
            gridView.wrapper.columns.each((model) => {
                if (model.get('condition') !== false) {
                    columns.push(model.get('field'));
                }
            });
            columns.push('NOTES');
            exportData.columns = columns;
        }

        return exportData;
    },

    doExport(view, exportData, ASYNC_LOCKBOX_EXPORT_PRINT_URL) {
        if (!systemConfig.isAdmin()) {
            helpers.handleExportAsyncReport({
                service: ASYNC_LOCKBOX_EXPORT_PRINT_URL || services.runAsyncListViewReport,
                postData: exportData,
            }, dialog);
            return;
        }
        if ($('#listViewExportForm').length === 0) {
            const f = `<form id="listViewExportForm" action="${services.generateUrl('/export/listView')}" method="get" target="_self"><input id="listViewRequest" type="hidden" name="listViewRequest" ></form>`;
            view.$el.append(f);
        }
        $('#listViewExportForm #listViewRequest').val(JSON.stringify(exportData));
        $('#listViewExportForm').submit();
    },
};
