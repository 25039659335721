import $ from 'jquery';
import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import Dialog from '@glu/dialog';
import { getLastPositionData, isDeepLinked } from 'common/util/deeplinkUtil';
import modalUtil from 'common/util/modal';
import tmpl from './rejectDialog.hbs';

const RejectDialog = ItemView.extend({
    template: tmpl,
    grid: null,
    detailPage: null,
    store: null,
    multiSelectReject: null,

    initialize(options) {
        this.model = options.model;
        this.grid = options.grid;
        this.store = options.store;
        this.detailPage = options.detailPage;
        this.list = options.multiSelectReject;
        this.promise = options.promise;
        if (this.model) {
            this.model.isClosing = false;
        }

        if (isDeepLinked()) {
            this.listenTo(this.appBus, 'iframePosition', data => modalUtil.updateModal(data, this));
            this.listenToOnce(this.appBus, ('notification:child:modal:close'), () => {
                this.close();
            });
        }
    },

    onRender() {
        // If in iframe, configure with the current position
        if (isDeepLinked()) {
            const lastPositionData = getLastPositionData();
            if (lastPositionData) {
                modalUtil.updateModal(lastPositionData.data, this);
            }
        }
    },

    ui: {
        // this is needed in certain situations when the dialog doesn't provide a callback
        cancel: '[data-dismiss="modal"]',
        mask: '> .modal-backdrop',
        close: 'button.close',
        dialog: '> div > .modal-dialog',
        body: '> div > div > div > .modal-body',
        header: '> div > div > div > .modal-header',
        footer: '> div > div > div > .modal-footer',
        modal: '> .modal',
        $title: '[data-hook="getTitle"]',
    },

    events: {
        'click @ui.cancel': 'cancel',
    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    title: locale.get('common.rejection.reason'),

    save() {
        const rejectReason = $('#rejection_reason').val();
        const self = this;
        if (!rejectReason) {
            $('#reject_reason_required').css('color', 'red');
            $('#reject_reason_required').css('visibility', 'visible');
            if (this.promise) {
                this.promise.reject?.();
            }
            return;
        }

        const rejectObject = {
            name: 'APPROVER_REJECTION_REASON',
            value: rejectReason,
        };

        if (this.model) {
            this.model.set('APPROVER_REJECTION_REASON', rejectReason);
        }
        if (this.grid) {
            this.grid.trigger(
                'rowAction',
                {
                    action: 'reject',
                    model: this.model,
                    promise: {
                        // provide defaults for these expected options but allow to be overwritten
                        resolve: () => Promise.resolve(),
                        reject: () => Promise.reject(),
                        ...this.promise,
                    },
                },
            );
        } else if (this.list) {
            this.trigger('list:multi:action:action_reject', rejectObject);
        } else if (this.options.triggerEventOnly) {
            this.trigger('reject:dialog:save', rejectObject);
        } else {
            let { contextKey } = self.contextKey !== undefined ? self : self.options.detailPage;
            if (self.options.detailPage.options.subPaymentContextKey) {
                contextKey = self.options.detailPage.options.subPaymentContextKey;
            }
            this.model.reject({
                success(model, resp, options) {
                    options.store.set(`${contextKey}-alertMessage`, 'REJECT');
                    options.store.set(`${contextKey}-confirms`, resp);
                    options.detailPage.cancel();
                },

                error(model, resp, options) {
                    options.store.set(`${contextKey}-alertMessage`, 'REJECT error');
                },

                store: this.store,
                detailPage: this.detailPage,
            });
        }

        // this lets anyone listening know we're closing
        if (this.model) {
            this.model.isClosing = true;
        }
        this.close();
    },

    cancel() {
        Dialog.close();
    },
});
export default RejectDialog;
