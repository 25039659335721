import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import $ from 'jquery';
import 'bootstrapColorSelector';
import lineTmpl from './line.hbs';
import lineReadOnlyTmpl from './lineReadOnly.hbs';

const Line = ItemView.extend({
    initialize(options) {
        this.scenarios = options.scenarios;

        this.listenTo(
            this.model.get('scenarios'),
            {
                'add remove': this.render,
            },
            this,
        );

        this.listenTo(
            this.scenarios,
            {
                'add remove change:name': this.render,
            },
            this,
        );

        this.manageLines = options.manageLines;
        if (!this.manageLines) {
            this.template = lineReadOnlyTmpl;
        } else {
            this.listenTo(
                this.model,
                {
                    saveChangesFromEditMode: this.saveChanges,
                },
                this,
            );
        }

        this.expanded = true;
    },

    template: lineTmpl,

    ui: {
        $removeLine: '.remove',
        $colorselector: '.colorselector',
        $removeScenario: '.remove-scenario',
        $show: '.show-transactions',
        $edit: '.edit',
        $editMode: '.edit-mode',
        $viewMode: '.view-mode',
        $renameInput: '.rename-line',
        $btnShowAddScenario: '.btn-show-add-scenario',
        $addScenarioForm: '.add-scenario',
        $addScenarioSelect: '.add-scenario select',
        $toggleCheckbox: '.toggle-line',
        $toggleScenarioCheckbox: '.toggle-scenario',
    },

    events: {
        drop: 'handleDrop',
        dropover: 'handleDropOver',
        dragstop: 'handleDragStop',
        dragstart: 'handleDragStart',
        'click @ui.$show': 'showTransactions',
        'click @ui.$edit': 'editItem',
        'click @ui.$removeLine': 'removeLine',
        'click @ui.$removeScenario': 'removeScenario',
        'submit @ui.$editMode': 'rename',
        'submit @ui.$addScenarioForm': 'addScenario',
        'change @ui.$toggleCheckbox': 'toggleActive',
        'change @ui.$toggleScenarioCheckbox': 'toggleScenarioActive',
        'click @ui.$btnShowAddScenario': 'showAddScenario',
        'show.bs.collapse': 'updateCollapseState',
        'hide.bs.collapse': 'updateCollapseState',
    },

    updateCollapseState(e) {
        this.expanded = e.type !== 'hide';
    },

    attributes: {
        // 'aria-controls': '#transactions-graph'
    },

    className: 'line-container',

    modelEvents: {
        'change:name change:colorValue': 'render',
    },

    handleDrop(e, ui) {
        this.droppedOnSelf = true;
        const scenarioId = $(ui.draggable).data('scenario-id');
        if (!this.model.get('scenarios').get(scenarioId)) {
            const scenario = this.scenarios.get(scenarioId);
            this.model.get('scenarios').add(scenario);
        }
    },

    handleDropOver() {
        this.$('.collapse').collapse('show');
    },

    handleDragStart() {
        this.droppedOnSelf = false;
    },

    handleDragStop(e, ui) {
        if (!this.droppedOnSelf) {
            const scenarioId = $(ui.helper).data('scenario-id');
            this.model.get('scenarios').remove(scenarioId);
        } else {
            this.render();
        }
        delete this.droppedOnSelf;
    },

    templateHelpers() {
        const usedScenarioIds = this.model.get('scenarios').map(s => s.get('cashFlowScenarioId'));
        const remainingScenarios = this.scenarios.reject(scenario => util.contains(usedScenarioIds, scenario.get('cashFlowScenarioId')));

        const scenarios = this.model.get('scenarios').map(function (scenario) {
            const data = scenario.toJSON();
            data.cid = scenario.get('cashFlowScenarioId');
            data.hidden = util.contains(this.model.get('hiddenScenarios'), scenario.get('cashFlowScenarioId'));
            return data;
        }, this);

        return {
            expanded: this.expanded,
            id: this.cid,
            scenarios,
            hasScenarios: scenarios.length > 0,
            scenariosAvailable: remainingScenarios.length > 0,

            remainingScenarios: util.map(remainingScenarios, scenario => ({
                cid: scenario.get('cashFlowScenarioId'),
                name: scenario.get('name'),
            })),

            lineValue: this.model.get('colorValue'),
            givenColors: this.model.collection.getLineColors(),
        };
    },

    addScenario(e) {
        e.preventDefault();
        const scenario = this.scenarios.get(this.ui.$addScenarioSelect.val());
        this.model.get('scenarios').add(scenario);
    },

    removeLine() {
        this.model.destroy();
    },

    removeScenario(e) {
        const id = this.$(e.target).parents('[data-scenario-id]').data('scenario-id');
        this.model.get('scenarios').remove(id);
    },

    setColor(color) {
        // Colors are assigned in the cashflow.less file.
        this.ui.$colorselector.colorselector('setColor', color);
    },

    setValue(colorValue) {
        this.ui.$colorselector.colorselector('setValue', colorValue);
    },

    saveChanges() {
        const name = this.ui.$renameInput.val().trim();
        if (name !== this.model.get('name') && name.length > 0) {
            this.model.set({
                name,
            });
        } else {
            // reset row to original state
            this.render();
        }

        if (this.model.isNew() || this.model.hasChanged()) {
            this.model.save();
        }
    },

    showTransactions() {
        this.model.collection.trigger('show', this.model);
    },

    toggleActive() {
        this.model.set({
            visible: this.ui.$toggleCheckbox.prop('checked'),
        });
        this.model.save();
    },

    toggleScenarioActive(e) {
        const active = this.$(e.target).prop('checked');
        const scenarioId = this.$(e.target).parents('[data-scenario-id]').data('scenario-id');

        let hiddenScenarios = util.clone(this.model.get('hiddenScenarios'));
        if (active) {
            hiddenScenarios = util.without(hiddenScenarios, scenarioId);
        } else {
            hiddenScenarios.push(scenarioId);
        }

        this.model.set({
            hiddenScenarios,
        });
    },

    showAddScenario() {
        this.ui.$addScenarioForm.removeClass('hidden');
        this.ui.$btnShowAddScenario.addClass('hidden');
    },

    hideAddScenario(e) {
        if (e) {
            e.preventDefault();
        }
        this.ui.$btnShowAddScenario.removeClass('hidden');
        this.ui.$addScenarioForm.addClass('hidden');
    },

    colorChanged(value) {
        // This gets called when the user selects a color and the colorselector closes
        if (value !== undefined) {
            if (value !== this.model.get('colorValue').toString()) {
                const valueInt = parseInt(value, 10);
                this.model.collection.changeColor(this.model, valueInt);
                this.saveChanges();
            } else {
                // NH-20902 - color selector overrides the css changes on swatch selection.
                this.setUpColorSelector(value);
            }
        }
    },

    onRender() {
        this.$el.droppable({});
        this.$('[data-scenario-id]').draggable();

        if (this.manageLines) {
            const colorSelectorCallback = util.bind(this.colorChanged, this);
            this.ui.$colorselector.colorselector({
                callback: colorSelectorCallback,
            });
            const selectedColorValue = this.model.get('colorValue');
            const selectedColorValueStr = selectedColorValue.toString();
            // Set the currently selected color in the dropdown box.
            if (selectedColorValue !== this.model.collection.extraColor.value) {
                this.setValue(selectedColorValueStr);
            }

            this.setUpColorSelector(selectedColorValue);
        }
    },

    setUpColorSelector(selectedColorValue) {
        /*
         * Customize the color selector button and dropdown with css overrides.
         */
        const colorSelectorDropdown = this.ui.$colorselector[0].nextSibling;

        /*
         * Add listener for when dropdown is open.  Color Selector needs to be refreshed with
         * most recent changes.
         */
        const self = this;
        const ddToggle = $(colorSelectorDropdown).find('a.dropdown-toggle');
        $(ddToggle[0]).on('click', () => {
            self.markUsedSwatches(colorSelectorDropdown);
        });
        // Remove colorselector style for dropdown btn.  Will apply colors from css.
        const btnCS = $(colorSelectorDropdown).find('span');
        $(btnCS[0]).removeAttr('style');
        const btnColorClass = `picker-color-class-${selectedColorValue}`;
        $(btnCS[0]).addClass(btnColorClass);
    },

    markUsedSwatches(colorSelectorDropdown) {
        /*
         * Mark the unavailable color swatches in the dropdown selector box. Will
         * apply css to mark available and used swatches.
         */
        const listEl = $(colorSelectorDropdown).find('li');
        util.each(listEl, function (liEl) {
            const child = liEl.firstChild;
            if (child !== undefined) {
                const swatchColorValue = child.attributes.getNamedItem('data-value').value;
                // Mark the used swatch colors
                if (!$(child).hasClass('selected')) {
                    const avail = this.model.collection.isColorValueAvailable(swatchColorValue);
                    $(child).toggleClass('used-swatch', !avail);
                }
                // Remove colorselector style.  Will apply own colors from css classes.
                $(child).removeAttr('style');
            }
        }, this);
    },
});

export default Line;
