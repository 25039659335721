import configParam from 'system/webseries/models/configurationParameters';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import EntitlementView from '../payments/assignedPermissions/entitlementView';
import entitlementViewTmpl from './entitlementView.hbs';

export default EntitlementView.extend({
    template: entitlementViewTmpl,

    initialize(opts, ...rest) {
        EntitlementView.prototype.initialize.call(this, opts, ...rest);
        this.label = this.model.label;
        this.mode = opts.mode;
    },

    templateHelpers() {
        const { model } = this;
        const isUserGroup = this.model.get('typeCode') === '_USER';

        return util.extend(
            EntitlementView.prototype.templateHelpers.call(this),
            {
                localeLabel: this.label,

                hasModifyAndManage() {
                    return model.isActionApplicable('modify') && model.isActionApplicable('manage');
                },

                actionUpdateSettingDisabled: isUserGroup && configParam.get('_USER.actions.updset.disabled') === 'true',
                actionViewDisabled: isUserGroup && configParam.get('_USER.actions.view.disabled') === 'true',
                actionManageDisabled: isUserGroup && configParam.get('_USER.actions.manage.disabled') === 'true',
                actionApproveDisabled: isUserGroup && configParam.get('_USER.actions.approve.disabled') === 'true',
                restrictApprOwnDisabled: isUserGroup && configParam.get('_USER.restrictions.approveown.disabled') === 'true',
                restrictApprAutoDisabled: isUserGroup && configParam.get('_USER.restrictions.autoapprove.disabled') === 'true',
                modifyLabel: locale.get('uce.entitlement.manage'),
            },
        );
    },
});
