import CompositeView from '@glu/core/src/compositeView';
import ItemView from '@glu/core/src/itemView';
import template from './visualFilterContainer.hbs';
import tabTemplate from './visualFilterTab.hbs';

const TabView = ItemView.extend({
    tabTemplate,
    tagName: 'li',

    events: {
        'click button': 'setSelected',
    },

    modelEvents: {
        'change:disabled': 'toggleVisibility',
        'change:selected': 'toggleSelected',
    },

    setSelected() {
        this.model.set({
            selected: true,
        });
    },

    toggleVisibility() {
        /*
         * $.toggle and hide/show apply display: list-item when showing the element,
         * which breaks the layout
         */
        const display = this.model.get('disabled') ? 'none' : '';
        this.$el.css('display', display);
    },

    toggleSelected() {
        this.$el.toggleClass('selected', this.model.get('selected'));
    },

    onRender() {
        this.toggleVisibility();
        this.toggleSelected();
    },
});

export default CompositeView.extend({
    template,
    className: 'visual-filter',
    itemViewContainer: 'ul',
    itemView: TabView,
});
