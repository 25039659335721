import $ from 'jquery';
import util from '@glu/core/src/util';
import CTXAddendaView from 'common/dynamicPages/views/ctxAddenda/ctxAddendaComposite';
import ListView from 'common/dynamicPages/views/workflow/list';

export default {
    setupGrids(viewParam, $alt) {
        const view = viewParam;
        // Support an alternate container element
        const $el = $alt || view.$el;

        $el.find('[data-hook="grid-component"]').each((index, element) => {
            const fieldName = $(element).attr('data-field');

            const depends = [];
            let dependsName;
            let dependsValue;

            const fieldData = view.model.fieldData[fieldName];
            if (fieldData.dependsOn) {
                for (let i = 0; i < fieldData.dependsOn.length; i += 1) {
                    dependsName = fieldData.dependsOn[i];
                    dependsValue = view.model.get(fieldData.dependsOn[i]);
                    if (view.model.childSequenceKey) {
                        if (dependsName === view.model.childSequenceKey.toUpperCase()) {
                            if (!dependsValue) {
                                dependsValue = view.model.childNumber;
                            }
                        }
                    }
                    depends.push({
                        name: dependsName,
                        value: dependsValue,
                    });
                }
            }

            const viewOptions = {
                hideButtons: true,
                componentFieldName: fieldName,
                componentDepends: depends,
                context: view.initializeOptions.context,
                gridViewOptions: view.initializeOptions.gridViewOptions,
                parentState: view.state,
                parentModel: view.model,
                additionalSearchFields: this.setupAdditionalSearchFields(view.model),
            };
            if (view.isCTX) {
                const grandChildMeta = {};
                for (let j = 0; j < view.model.grandChildFields.length; j += 1) {
                    grandChildMeta[view.model.grandChildFields[j]] = view.model
                        .fieldData[view.model.grandChildFields[j]];
                }
                viewOptions.meta = grandChildMeta;
                viewOptions.componentDepends.push({
                    name: 'BATCHSEQNUM',
                    value: view.parentModel.get('BATCHSEQNUM'),
                });
                view.gridComponentViews[fieldName] = new CTXAddendaView(viewOptions);
                view.listenTo(view.appBus, 'meta:ctx:grandchild:new', view.saveGrandChild);
                view.listenTo(view.appBus, 'meta:ctx:grandchild:delete', view.deleteGrandChild);
                view.listenTo(view.appBus, 'meta:ctx:grandchild:update', view.saveGrandChild);
                if (view.regionManager.get(`gridComponent${fieldName}`)) {
                    view.regionManager.get(`gridComponent${fieldName}`).show(view.gridComponentViews[fieldName]);
                } else if (view.beneWidget && view.beneWidget.regionManager.get(`gridComponent${fieldName}`)) {
                    view.beneWidget.regionManager.get(`gridComponent${fieldName}`).show(view.gridComponentViews[fieldName]);
                }
            } else {
                viewOptions.lvc = ListView.prototype
                    .setListViewConfig.call(view, viewOptions.context);
                view.gridComponentViews[fieldName] = view.gridApi
                    .createComponentGridView(viewOptions);
                view.listenTo(
                    view.gridComponentViews[fieldName],
                    'rowAction',
                    view.componentGridRowAction,
                );
                /*
                 * there doesn't appear to be a way to access the regions via hash
                 * so we have to use eval to access a region we don't know
                 * the name of
                 */
                view.regionManager.get(`gridComponent${fieldName}`).show(view.gridComponentViews[fieldName]);
            }
        });
    },

    setupAdditionalSearchFields(model) {
        const searchFields = [];
        if (model && model.key) {
            const pairs = util.pairs(model.key);
            util.forEach(pairs, (item) => {
                if (item[1] === 'true' && item[0]) {
                    const field = util.findWhere(
                        model.jsonData.fieldInfoList,
                        {
                            name: item[0],
                        },
                    );
                    searchFields.push({
                        fieldName: item[0],
                        fieldValue: [model.get(item[0])],
                        dataType: field.dataType,
                        operator: 'EQ',
                    });
                }
            });
        }
        return searchFields;
    },
};
