import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRequest from 'hooks/useRequest';
import useStyles from './AsyncPrintViewModal.styles';
import AsyncPrintViewModalBody from './AsyncPrintViewModalBody';

export const STATUS = {
    PROCESSING: 'processing',
    SUCCESS: 'success',
    ERROR: 'error',
};

const propTypes = {
    postData: PropTypes.shape({
        productCode: PropTypes.string,
        reportId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        viewId: PropTypes.string,
        filterData: PropTypes.array,
        additionalParameters: PropTypes.array,
    }).isRequired,
    onReportComplete: PropTypes.func.isRequired,
    onReportIncomplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    service: PropTypes.string.isRequired,
    type: PropTypes.string,
};

const AsyncPrintViewModal = (props) => {
    const {
        postData,
        onReportComplete,
        onReportIncomplete,
        onCancel,
        service,
        type,
    } = props;

    const [status, setStatus] = useState(STATUS.PROCESSING);
    const [message, setMessage] = useState([]);
    const { post } = useRequest();
    const classes = useStyles();

    /**
     * @method handleContinueWorkingMessage
     * @description splits the message from the response into segments
     * @param {string} asyncMessages
     */
    const handleContinueWorkingMessage = (asyncMessages) => {
        if (!asyncMessages) return;
        const messageLines = asyncMessages.split('\\n');
        setMessage(messageLines);
        setStatus(STATUS.SUCCESS);
    };

    /**
     * @method runReport
     * @description runs async report
     * @return {Promise}
     */
    const runReport = () => post(service, postData);

    useEffect(() => {
        let didUnmount = false;
        runReport().then((result) => {
            if (didUnmount) {
                return;
            }
            const isReportComplete = result.errorCode === 0;
            const reportWillExecuteAsync = result.errorCode === 2208;
            if (isReportComplete) {
                onReportComplete(result);
            } else if (reportWillExecuteAsync) {
                handleContinueWorkingMessage(result.messages[0]);
                onReportIncomplete();
            } else {
                // Handle other cases by simply displaying the message
                setMessage(result.messages);
                setStatus(STATUS.SUCCESS);
            }
        }, (err) => {
            setMessage([err.responseText]);
            setStatus(STATUS.ERROR);
        });
        return function () { didUnmount = true; };
    }, []); //eslint-disable-line

    return (
        <div className={`async-modal ${classes.modal}`}>
            <AsyncPrintViewModalBody
                type={type}
                status={status}
                classes={classes}
                message={message}
                onCancel={onCancel}
            />
        </div>
    );
};

AsyncPrintViewModal.defaultProps = {
    type: 'print',
};

AsyncPrintViewModal.propTypes = propTypes;

export default AsyncPrintViewModal;
