import util from '@glu/core/src/util';
import InquiryQueryResults from 'system/webseries/collections/inquiryQueryResults';

export default InquiryQueryResults.extend({
    /**
     * Map incoming row data.
     */
    parseRow(row) {
        const isFn = util.isFunction(this.labelFormat);

        return {
            id: row.rowId,
            // invoke the label format function if provided.
            text: isFn ? this.labelFormat(row) : row.label,
        };
    },
});
