export const getPillsDisplayArray = (resp) => {
    const { pillGroups, pills: rawPills } = resp;
    return Object.entries(pillGroups).reduce((arr, [key, { pills, label, tooltipSample }]) => {
        if (!pills.length) {
            return arr;
        }
        return [
            ...arr,
            {
                label,
                name: key,
                pills: pills.map(p => ({ ...rawPills[p], name: p })).sort((a, b) => {
                    if (a.label > b.label) {
                        return 1;
                    }
                    if (a.label < b.label) {
                        return -1;
                    }
                    return 0;
                }),
                tooltipSample: !!tooltipSample,
            },
        ];
    }, []);
};

export const getPillMapper = (resp) => {
    const { pillGroups, pills } = resp;
    const pillsToGroups = Object.entries(pillGroups).reduce((map, [key, entry]) => ({
        ...map,
        ...entry.pills.reduce((obj, p) => ({ ...obj, [p]: key }), {}),
    }), {});
    return Object.entries(pills).reduce((map, [key, { label, sampleHtml }]) => ({
        ...map,
        [key]: {
            label,
            pillGroup: pillsToGroups[key],
            sampleHtml,
        },
    }), {});
};

export const replaceParameterTokens = (contentValue, pillMap) => {
    if (!contentValue) {
        return contentValue;
    }
    // split on our tokens and keep them around
    const splitValue = contentValue.split(/({{[A-Za-z_]+}})/);
    splitValue.forEach((item, idx) => {
        const tokenNamePattern = /(?:{{)([A-Za-z_]+)(?:}})/;
        if (tokenNamePattern.test(item)) {
            const [, tokenName] = item.match(tokenNamePattern);
            const sampleHtml = pillMap[tokenName]
                ? pillMap[tokenName].sampleHtml
                : `<strong>Invalid Token: ${tokenName}</strong>`;
            splitValue[idx] = splitValue[idx].replace(tokenNamePattern, sampleHtml);
        }
    });
    return splitValue.join(' ');
};

export default {
    getPillMapper,
    getPillsDisplayArray,
    replaceParameterTokens,
};
