// TODO this file does not yet have test coverage and needs it ASAP
import Collection from '../collection';
import Query from './queryApi';
import util from '../util';

export default Collection.extend({
  initialize(models, options = {}) {
    this.paginate = options.paginate !== false;
    this.listenTo(this, 'destroy', this.updateTotalCounter, this);
  },

  updateTotalCounter() {
    if (!this.totalCount) {
      return;
    }
    this.totalCount -= 1;
  },

  gridPaginate(page, recordsPerPage) {
    this.currentPage = page;
    this.recordsPerPage = recordsPerPage;

    // TODO should be able to do the reset in the fetch callback,
    // instead showing a loading indicator during the fetch.
    this.reset();
    this.fetch();
  },

  gridSort(key, order) {
    // When a new sort condition is applied we jump back to page 1
    this.currentPage = 1;

    this.sortKey = key;
    this.sortAscending = (order === 'asc');

    this.reset();
    this.fetch();
  },

  gridFilter(filters, callback, filterOptions) {
    // When a new filter is applied we jump back to page 1
    this.currentPage = 1;

    this.filters = filters;
    this.filterOptions = filterOptions;

    this.reset();
    this.fetch();
  },

  getSort() {
    if (this.sortKey) {
      return Query.order[this.sortAscending ? 'ascending' : 'descending'](this.sortKey);
    }

    return undefined;
  },

  getFilter() {
    if (!(this.filters && this.filters.length)) {
      return null;
    }

    let filterCriteria;

    const populate = model => {
      const field = model.get('field');
      let value = model.get('value');
      const column = this.filterOptions.findWhere({
        field
      });
      const type = column.get('filterMethod') || 'contains';
      const filterField = filterCriteria ? filterCriteria.and(field) : Query.filter.where(field);

      switch (type) {
        case 'in':
          filterCriteria = filterField.in(value);
          break;
        case 'contains':
          filterCriteria = filterField.contains(value);
          break;
        case 'dateMatch': {
          value = column.get('filterConverter') ? column.get('filterConverter')(value) : value;

          let from = value;
          let to = value;

          // note: this is the temp fix util BSL will be changed
          if (util.isObject(value)) {
            from = `${value.from}T00:00:00.000Z`;
            to = `${value.to}T23:59:59.100Z`;
          } else {
            from = `${value}T00:00:00.000Z`;
            to = `${value}T23:59:59.100Z`;
          }

          filterCriteria = filterField.between(from, to);
          break;
        }
        case 'booleanMatch':
          value = column.get('filterConverter') ? column.get('filterConverter')(value) : value;
          filterCriteria = filterField.is(value);
          break;
        case 'is':
          filterCriteria = filterField.is(value);
          break;
        default:
          // TODO might be nice to do something here, or not ...
      }
    };

    populate(this.filters.models[0]);

    for (let i = 2; i <= this.filters.length; i++) {
      populate(this.filters.models[i - 1]);
    }

    return filterCriteria;
  },

  getQuery() {
    // Base query
    const query = Query.create(this.queryEntity).fields(this.queryResultField);

    // Sorting
    const sort = this.getSort();
    if (sort) {
      query.sortBy(sort);
    }

    // Filtering
    const filter = this.getFilter();
    if (filter) {
      query.filterBy(filter);
    }

    return query;
  },

  fetch() {
    const self = this;

    // trigger 'desync' event in order to display 'Loading...' label in the grid
    this.trigger('desync');

    return new Promise(((resolve, reject) => {
      const pageNumber = self.currentPage || 1;
      const recordsPerPage = self.recordsPerPage || 50;
      const start = (pageNumber - 1) * recordsPerPage;

      let query = self.getQuery();

      if (self.paginate) {
        query.paginate(start, recordsPerPage);
      }

      query = query.build();

      return query.execute((err, response, opts) => {
        if (err) {
          return reject(err);
        }

        const result = [];
        util.each(response, (item) => {
          result.push(item[self.queryResultField]);
        });

        self.totalCount = opts.totalCount || 0;

        self.set(result, {
          parse: true
        });

        self.trigger('sync', self, response);
        resolve(self, response);

        return undefined;
      });
    }));
  }
});

