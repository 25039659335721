import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import Constants from 'app/administration/constants';
import dialog from '@glu/dialog';
import errorHandlers from 'system/error/handlers';
import entitlements from 'common/dynamicPages/api/entitlements';
import FileImportUtil from 'app/administration/views/fileImport/fileImportUtil';
import InquiryRows from 'app/loans/views/bankSummary/inquiryRows';
import Formatter from 'system/utilities/format';
import PrintExportUtil from 'common/util/printExportUtil';
import ImportView from 'app/administration/views/fileImport/importView/importView';
import helpPageUtil from 'common/util/helpPage';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './successfulImportsListView.hbs';

const SuccessfulImportsListView = Layout.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    ui: {
        $totalIssues: '[data-hook="totalIssues"]',
        $totalVoids: '[data-hook="totalVoids"]',
        $totalStops: '[data-hook="totalStops"]',
        $totalCancels: '[data-hook="totalCancels"]',
        $totalItems: '[data-hook="totalItems"]',
        $amountIssues: '[data-hook="amountIssues"]',
        $amountVoids: '[data-hook="amountVoids"]',
        $amountStops: '[data-hook="amountStops"]',
        $amountCancels: '[data-hook="amountCancels"]',
        $amountTotal: '[data-hook="amountTotal"]',
        $companyId: '[data-hook="companyId"]',
        $companyName: '[data-hook="companyName"]',
        $fileName: '[data-hook="fileName"]',
        $importDate: '[data-hook="importDate"]',
        $importMapName: '[data-hook="importMapName"]',
    },

    initialize() {
        this.productCode = store.get('productCode');
        this.functionCode = store.get('functionCode');

        /*
         * For wires there are imports of mixed type, Multi and Wires from Template,
         * and specifying
         * a specific incorrectly filters out other payments from the import history.
         */
        if (this.productCode === 'RTGS' && this.functionCode === 'INST') {
            this.typeCode = '*';
        } else {
            this.typeCode = store.get('typeCode');
        }
        this.importCode = store.get('importCode');
        this.jobId = store.get('jobId');
        this.fileImportId = store.get('fileImportId');
        this.disableFilters = true;

        this.loadGrid();
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData();
        } else {
            this.loadGrid();
        }
    },

    loadRequiredData() {
        let entitlementPromiseSent = false;
        const promiseAccumulator = [];

        promiseAccumulator.push(helpPageUtil.getHelpPagePromise({
            productCode: '_UTIL',
            functionCode: 'HISTORY',
            typeCode: 'IMPORT',
            mode: 'SELECT',
        }));
        if ((!util.isEmpty(this.productCode)
            && !util.isEmpty(this.functionCode)
            && !util.isEmpty(this.typeCode)) || this.importCode === 'CM') {
            promiseAccumulator
                .push(entitlements.getEntitlementsForContext(this.getEntitlementOptions()));
            entitlementPromiseSent = true;
        }

        Promise.all(promiseAccumulator).then(
            (results) => {
            // first set the help page
                if (results[0] && results[0].helpPage) {
                    store.set('helpPage', results[0].helpPage);
                }
                // handle the entitlement promise
                if (entitlementPromiseSent) {
                    if (results[1].actions.SELECT !== true) {
                    // FIXME: No idea what this is doing asynchronously...
                        this.notEntitled = true;
                    }
                }
                this.setHasLoadedRequiredData(true);
                this.render();

                if (this.importCode === 'CM') {
                    this.loadAddlFields();
                }
            },
            util.bind(errorHandlers.loading, this),
        );
    },

    /**
     * @method getEntitlementOptions
     *
     * @returns {object} options for entitlment promise
     */
    getEntitlementOptions() {
        return {
            context: [{
                name: 'productCode',
                value: this.productCode,
            }, {
                name: 'functionCode',
                value: this.functionCode,
            }, {
                name: 'typeCode',
                value: this.typeCode,
            }],

            entryMethod: (this.importCode === 'CM') ? 0 : this.getEntryMethod(),

            overrideContext: {
                serviceName: 'fileImportHistory/listView',
            },
        };
    },

    gridLoadComplete() {
        /*
         * FIXME Using the non-encapsulated jQuery, and injecting all sorts of stuff
         *  into the markup.
         * FIXME: Should most/all of this logic be part of the template
         */
        let alert;
        let successRecs;

        if (this.notEntitled && this.productCode) {
            alert = Constants.SPACE + locale.get('fih.NoPermissions') + Constants.SPACE + locale.get(`Confirm.Multi.${this.productCode}`);
            $('.alert-warning').html(alert);
            $('.alert-warning').removeClass('hide').addClass('icon-warning').addClass('show');
        } else {
            const impCode = this.importCode;
            let failedRecs = store.get('failedRecsCount');
            failedRecs = ((failedRecs === null) || (failedRecs === undefined)) ? 0 : failedRecs;
            successRecs = this.gridView.wrapper.rows.totalCount;
            $('#successCount').html(successRecs);

            if (successRecs === 0) {
                if (failedRecs < 1 || impCode !== 'CM') {
                    const templateCode = store.get('templateCode');

                    if (templateCode && templateCode.length > 0) {
                        alert = `${Constants.SPACE + locale.get('ACH.Template') + Constants.SPACE + templateCode} - ${store.get('templateName')}${Constants.SPACE}${locale.get('ACH.TemplateUpdateImport')}`;
                        $('.alert-warning').html(alert);
                        $('.alert-warning').removeClass('hide').addClass('icon-warning').addClass('show');
                    }
                }
            } else {
                // Hidden initially to avoid flickersZ
                $('#successfulImportsPanel').show();

                if ((this.totalItems > successRecs) && (failedRecs < 1 || impCode !== 'CM')) {
                    alert = Constants.SPACE + locale.get('fih.partialResults');
                    $('.alert-warning')
                        .html(alert);
                    $('.alert-warning')
                        .removeClass('hide')
                        .addClass('icon-warning')
                        .addClass('show');
                }
            }
        }

        // FIXME: This still needs to be passed up when it is all done.
        store.set('successfulImportsGridLoaded', true);
        this.options.parent.trigger('bothGridsLoaded');
        this.options.parent.trigger('recsLoaded', {
            success: {
                loaded: true,
                count: successRecs,
            },
        });
    },

    templateHelpers() {
        return {
            successfulListViewItemized: (this.importCode === 'CM' && this.typeCode !== 'STCAIMPT'),
        };
    },

    getAppResKey() {
        if (this.typeCode === 'BENEADBK') {
            return this.typeCode;
        }
        return this.functionCode;
    },

    getEntryMethod() {
        if (this.typeCode === 'BENEADBK' || this.typeCode === 'LBX_MRN') {
            return '0';
        }
        return '3';
    },

    getInquiryId() {
        const { functionCode } = this;
        const { typeCode } = this;
        const { importCode } = this;
        let inquiryId;
        if (typeCode === 'CIMBTCHC' || importCode === 'CM') {
            inquiryId = 17244;
        } else if (importCode === 'LBX.IRN') {
            inquiryId = 17260;
        } else if (functionCode === 'INST' || functionCode === 'BATCH') {
            inquiryId = 17240;
        } else if (functionCode === 'TMPL' || functionCode === 'BHTMPL') {
            inquiryId = 17241;
        } else if (typeCode === 'BENEADBK') {
            inquiryId = 17242;
        } else {
            // this else block is temporary and will be removed later
            inquiryId = 17240;
        }

        return inquiryId;
    },

    loadGrid() {
        this.inquiryId = this.getInquiryId();

        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: this.typeCode,
                        productCode: this.productCode,
                        functionCode: this.functionCode,
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: this.inquiryId,
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['IMPORTID', store.get('fileImportId')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode: this.functionCode,
            inquiryId: this.inquiryId,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode: this.productCode,
            requestParams: `?!_init=true&_productCode=_UTIL&_functionCode=HISTORY&_typeCode=IMPORT&_mode=SELECT&_gridId=${this.inquiryId}`,
            typeCode: this.typeCode,
        };

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
        };

        // FIXME: Should this return
        this.gridView = GridApi.createInquiryGridView(options);
        if (!mobileUtil.isMobileScreen()) {
        // FIXME: gridapi:loaded triggers a bunch of markup changes right now.
            this.listenTo(this.gridView, 'gridapi:loaded', this.gridLoadComplete);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);

            if (this.tableContent) {
                this.tableContent.show(this.gridView);
            }
        }
    },

    loadAddlFields() {
        const self = this;
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: '',
                        productCode: this.productCode,
                        functionCode: this.functionCode,
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: '17265',
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['IMPORTID', store.get('fileImportId')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;
        const rowsData = this.getRows(data);
        return rowsData.then((results) => {
            self.createCIMImportSummary(results);
        });
    },

    createCIMImportSummary(rows) {
        /*
         * row count can be either 0 or 1. So initialization of values are needed.
         * But inquiry would return only one model from the server side.
         */
        const totals = {
            totalIssues: 0,
            totalVoids: 0,
            totalStops: 0,
            totalCancels: 0,
            totalItems: rows.length,
            amountIssues: 0,
            amountVoids: 0,
            amountStops: 0,
            amountCancels: 0,
            amountTotal: 0,
        };

        // TODO should these be sums?
        util.each(rows.models, (model) => {
            totals.totalIssues = model.get('TotalIssues');
            totals.totalVoids = model.get('TotalVoids');
            totals.totalStops = model.get('TOTALSTOPS');
            totals.totalCancels = model.get('TOTALCANCELS');
            totals.totalItems = model.get('TotalRecordCount');
            totals.amountIssues = model.get('TotalIssueAmount');
            totals.amountVoids = model.get('TOTALVOIDAMOUNT');
            totals.amountStops = model.get('TOTALSTOPAMOUNT');
            totals.amountCancels = model.get('TOTALCANCELAMOUNT');
            totals.amountTotal = model.get('TOTALITEMAMOUNT');
            totals.companyId = model.get('USERGROUP');
            totals.companyName = model.get('USERGROUPNAME');
            totals.fileName = model.get('FILENAME');
            totals.importDate = model.get('ENTERED_TIMESTAMP');
            totals.importMapName = model.get('IMPORTMAPNAME');
        });
        /*
         * FIXME: Apply the totals to the model and remove the direct setting below?
         * FIXME: This is called in a promise chain; should it return the summary data??
         */

        // FIXME: All extracted to the template
        this.ui.$totalIssues.text(totals.totalIssues);
        this.ui.$totalVoids.text(totals.totalVoids);
        this.ui.$totalStops.text(totals.totalStops);
        this.ui.$totalCancels.text(totals.totalCancels);
        this.ui.$totalItems.text(totals.totalItems);
        this.ui.$amountIssues.text(Formatter.formatNumber(totals.amountIssues));
        this.ui.$amountVoids.text(Formatter.formatNumber(totals.amountVoids));
        this.ui.$amountStops.text(Formatter.formatNumber(totals.amountStops));
        this.ui.$amountCancels.text(Formatter.formatNumber(totals.amountCancels));
        this.ui.$amountTotal.text(Formatter.formatNumber(totals.amountTotal));
        this.ui.$companyId.text(totals.companyId);
        this.ui.$companyName.text(totals.companyName);
        this.ui.$fileName.text(totals.fileName);
        this.ui.$importDate.text(totals.importDate);
        this.ui.$importMapName.text(totals.importMapName);
        this.totalItems = totals.totalItems;
    },

    getRows(data) {
        // FIXME: Isn't fetch a promise anyway?
        return new Promise((resolve, reject) => {
            const rowsData = new InquiryRows(data);
            rowsData.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    gridRowAction(options) {
        if (options.action.toUpperCase() === 'SELECT') {
            if (options.model.get('TYPE') === 'STCAIMPT') {
                options.model.set('SERVICENAME', Constants.URL_PLACE_STOP);
            }
            /**
             * NH-178952 HACK Only open the dialog when product and function are set.
             * In other instances, the batchChildGrid will handle the gridRowAction
             * and the modal should not be opened.
             */
            if (options.model.get('PRODUCT') && options.model.get('FUNCTION')) {
                this.importView = new ImportView(options);
                dialog.open(this.importView);
            }
        } else if (options.action.toUpperCase() === 'VIEWSUMT') {
            /*
             * TBD
             * FIXME: Awesome
             */
        }
        return Promise.resolve({ refresh: false });
    },

    print() {
        let title = 'fih.PaymentImportsGrid';

        if (this.typeCode === 'BENEADBK') {
            title = 'fih.BABImportsGrid';
        } else if (this.typeCode === 'CIMBTCHC') {
            title = 'fih.CIMImportsGrid';
        }

        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.inquiryId,
            title,
            format: 'PDF',
            productCode: this.productCode,
            importCode: this.importCode,
            functionCode: this.functionCode,
            fileImportId: this.fileImportId,
            jobId: this.jobId,
        };

        FileImportUtil.printFileImport(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },
});

let list = SuccessfulImportsListView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, { callbackAfterLoad: 'gridLoadComplete' });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
