import Model from '@glu/core/src/model';

export default Model.extend({

    defaults: {
        fieldLabel: '',
        fieldValue: '',
        fieldName: '',
        fieldId: '',
        inputType: '',
        required: false,
    },

    templateHelpers() {
        return {
            showDeleteButton: this.collection.length > 1,
        };
    },
});
