import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';
import FilterChip from './FilterChip';
import { styles } from './FilterChips.styles';

function BaseFilterChips({
  className, classes, groupedFilters, htmlId, onFilterRemove, onFilterSelect, showValues
}) {
  const {
    chipsWrapper, hideToggle, hideToggleWrapper, label, wrapper
  } = classes;
  const [hidden, setHidden] = useState(!showValues);

  const toggle = () => {
    setHidden((current) => !current);
  };

  useEffect(() => {
    setHidden(!showValues);
  }, [showValues]);

  if (!groupedFilters.length) {
    return null;
  }

  return (
    <div className={`${wrapper} ${className}`}>
      <div className={label}>{locale.get('dataComponents.filters')}</div>
      <div className={chipsWrapper}>
        {groupedFilters.map((filter) => (
          <FilterChip
            htmlId={htmlId}
            key={filter[0].id}
            hidden={hidden}
            onFilterRemove={onFilterRemove}
            onFilterSelect={onFilterSelect}
            filterConditions={filter}
          />
        ))}
      </div>
      <div className={hideToggleWrapper}>
        <button className={hideToggle} onClick={toggle} type="button" data-qa="filter-hideToggle">
          {hidden ? locale.get('dataComponents.showValues') : locale.get('dataComponents.hideValues')}
        </button>
      </div>
    </div>
  );
}

BaseFilterChips.propTypes = {
  /** CSS class name */
  className: PropTypes.string,

  /** JSS classes */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * Simplified list of grouped filter items.
   * Each filter item in the group has a unique id
   */
  groupedFilters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  }))).isRequired,
  /** Html id to use on the container element */
  htmlId: PropTypes.string.isRequired,

  /** Callback to call with the id of the filter when the user removes one (X clicked) */
  onFilterRemove: PropTypes.func.isRequired,

  /** Callback to call with the id of the filter when the user selects one (label clicked) */
  onFilterSelect: PropTypes.func.isRequired,

  /**
   * Pass true if values should be expanded by default or new Boolean(true|false) if
   * you need to change visibility programmatically
   */
  showValues: PropTypes.bool
};

BaseFilterChips.defaultProps = {
  className: '',
  showValues: false
};

export default withStyles(styles)(BaseFilterChips);
