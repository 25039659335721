import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    sync(method, model, options) {
        if (method === 'read') {
            const url = services.generateUrl('tableMaintenance/controlDistSum/getListView');

            http.post(url, {}, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },
});
