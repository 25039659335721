var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"type.AuditInformation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":65}}}))
    + "</h1>\n</div>\n<div class=\"page\">\n         <div class=\"section-container\">\n                <div class=\"alert-message\">\n                    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                </div>\n\n                <div data-region=\"filterRegion\" aria-live=\"polite\"></div>\n\n             <div class=\"section-body\">\n                 <button type=\"button\" id=\"checkSearch\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":80},"end":{"line":13,"column":106}}}))
    + "</button>\n             </div>\n\n             <div data-region=\"auditListRegion\" aria-live=\"polite\"/>\n            </div>\n</div>\n";
},"useData":true});