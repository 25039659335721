import $ from 'jquery';
import util from '@glu/core/src/util';
import PortalInterface from 'system/notifications/interfaces/portal';
import { untilTimeOrUserAction } from './userInteraction';

/**
 * Stops the jQuery animate used by scrollToFirstError
 */
export const stopScroll = () => {
    $('html, body').stop();
};

/**
 * Searches for and scrolls to the first error
 */
export const scrollToFirstErrorOnce = () => {
    const $body = $('html, body');
    let $container = $body;
    const $modal = $('.modal');

    /**
     * accounts for the various different types of error notifications we have in
     * different workflows
     */
    const alertRegions = ['.has-error', '.alert-region > .alert', '.alert'];
    const $error = $body.find(util.find(
        alertRegions,
        $selector =>
            $body.find($selector).first().length,
    )).first();
    if ($error.length) {
        // if there is a modal open, scroll the modal rather than the body
        if ($modal.length) {
            $container = $modal;
        }

        /**
         * create a 'message' object to send to the host page in case this DGB page is
         * within an Iframe
         */
        const sentData = {
            type: 'page:scroll',
            offset: $error.offset().top,
        };

        /**
         * communicate the need to scroll to a portal page in case this DGB page is
         * within an Iframe
         */
        PortalInterface.send(sentData);

        $container.animate({
            scrollTop: $error.offset().top,
        }, 250);

        if (!$error.is(':visible')) {
            $error.closest('.panel-collapse').collapse('show');
        }

        if ($error.first().is(':focusable')) {
            $error.first().focus();
        } else {
            $error
                .first()
                .find(':focusable')
                .first()
                .focus();
        }
    }
};

/**
 * Wrap the original scrollToFirstError with a repeated call and user interaction check
 * Optional parameters are testing.
 *
 * @param {number} [interval] Default is 300
 * @param {number} [duration] Default is 2500
 * @param {function} [scrollAction] Default is scrollToFirstError. Exposed for testing only.
 * @param {function} [stopAction] Default is stopScroll. Exposed for testing only.
 */
export const scrollToFirstErrorRepeatedly = (
    interval = 300,
    duration = 2500,
    scrollAction = scrollToFirstErrorOnce,
    stopAction = stopScroll,
) => {
    untilTimeOrUserAction(scrollAction, stopAction, interval, duration);
};

/**
 * A wrapper that won't fail when used as a callback.
 * Passing a non-number to scrollToFirstErrorRepeatedly means it will not set an interval
 */
export const scrollToFirstError = () => scrollToFirstErrorRepeatedly();

/**
 * Scroll to a specific element, typically a page region
 * @param {Element} regionEl
 */
export const scrollToRegion = (regionEl) => {
    const pageY = window.pageYOffset || window.scrollY;
    const regionPos = regionEl.getBoundingClientRect().top;
    const scrollYPos = pageY + regionPos;

    /**
     * create a 'message' object to send to the host page in case this DGB page is
     * within an Iframe
     */
    const sentData = {
        type: 'page:scroll',
        offset: scrollYPos,
    };

    /**
     * communicate the need to scroll to a portal page in case this DGB page is
     * within an Iframe
     */
    PortalInterface.send(sentData);

    window.scroll(0, scrollYPos);
};

export default {
    scrollToFirstError,
    scrollToFirstErrorOnce,
    scrollToFirstErrorRepeatedly,
    scrollToRegion,
    stopScroll,
};
