import CompositeView from '@glu/core/src/compositeView';
import constants from 'app/administration/constants';
import RiskGroupView from './riskGroupView';
import riskManagementTmpl from './riskManagement.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default CompositeView.extend({
    template: riskManagementTmpl,
    itemView: RiskGroupView,
    itemViewContainer: '.group-region',

    ui: {
        $selectAll: '.select-all-risk',
    },

    events: {
        'click @ui.$selectAll': 'selectAll',
    },

    initialize(opts) {
        const groupCollection = this.model.get('groups');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isEntitled()));
        }

        this.collection = groupCollection;

        this.itemViewOptions = {
            userGroup: opts.model.userGroupModel.get('id'),
            mode: opts.mode,
        };
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        if (this.shouldRenderPermissionsInOneColumn()) {
            this.$el.find('.group-region').addClass('one-column');
        }
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el.find('.risk-group input[type="checkbox"]');
    },

    /**
     * Will add class one-column to the group-region div
     * @returns {boolean}
     */
    shouldRenderPermissionsInOneColumn() {
        return this.model.get('groups').length <= 3;
    },

    selectAll(evt) {
        const { checked } = evt.currentTarget;
        // signature restriction requires numerical boolean as opposed to true/false
        const checkedvalue = checked ? 1 : 0;
        this.model.get('groups').each((group) => {
            group.get('types').each((type) => {
                type.set('entitled', checked);
            });
        });

        this.collection.each((group) => {
            group.get('types').each((type) => {
                const entitlement = type.get('entitlements').at(0);

                const actions = Object
                    .keys(entitlement.get('actions'))
                    .reduce((acc, action) => {
                        const accumulator = acc;
                        if (accumulator[action].applicable) {
                            accumulator[action].entitled = checked;
                        }
                        return accumulator;
                    }, JSON.parse(JSON.stringify(entitlement.get('actions'))));

                const restrictions = Object
                    .keys(entitlement.get('restrictions'))
                    .reduce((acc, restriction, name) => {
                        const accumulator = acc;
                        if (accumulator[restriction].applicable) {
                            accumulator[restriction].entitled = checked;
                            accumulator[restriction].value = (name === 'signature') ? checkedvalue : checked;
                        }
                        return accumulator;
                    }, JSON.parse(JSON.stringify(entitlement.get('restrictions'))));

                entitlement.set({
                    actions,
                    restrictions,
                });
            });
        });
        this.onChildrenChange();
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === constants.MODES.VIEW,
        };
    },
});
