import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import ContextModel from 'common/dynamicPages/models/context';
import Dialog from '@glu/dialog';
import Constants from 'app/balanceAndTransaction/constants';
import ViewAdviceDetails from 'app/balanceAndTransaction/views/depositAccounts/transactionAdviceDetails';
import additionalInfoCellViewTmpl from './additionalInfoCellView.hbs';

const AdditionalInfoCellView = ItemView.extend({
    template: additionalInfoCellViewTmpl,

    hasLink() {
        return !util.isEmpty(this.model.get('ADVICE_REF'));
    },

    linkUrl() {
        return `#${this.model.get('ADVICE_REF')}`;
    },

    linkFunc() {
        const { model } = this;
        model.context = {
            serviceName: Constants.TRANSACTION_ADVICEDETAILS_SERVICE,
            keyList: ['ADVICE_REF'],
        };
        model.buttons = [];
        const contextModel = new ContextModel({
            contextDef: {
                menuCategory: this.options.menuCategory,
                serviceName: Constants.DEPOSIT_ACCTS_TRANSACTIONS_SERVICE,
            },
        });
        model.set('ACTION_MESSAGE_ID', model.get('MESSAGE_ID'));
        store.set(`${contextModel.getContextKey()}-actionModel`, model);
        Dialog.close();
        Dialog.open(new ViewAdviceDetails());
    },

    linkText() {
        return this.options.value;
    },

    serializeData() {
        return this;
    },
});

export default AdditionalInfoCellView;
