import $ from 'jquery';
import locale from 'system/webseries/api/locale';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import services from 'services';
import moment from 'moment';
import constants from 'common/dynamicPages/api/constants';
import userInfo from 'etc/userInfo';
import BillerImageView from 'app/billPay/views/billerImage';
import validatorPatterns from 'system/validatorPatterns';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import entitlements from 'common/dynamicPages/api/entitlements';
import util from '@glu/core/src/util';
import PaymentUtil from 'common/util/paymentUtil';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const formState = form.formView.state;
    const biller = form.field('BILLER');
    const beneNameLookup = form.field('BENE_NAME');
    const beneAccount = form.field('BENE_ACCOUNT');
    const beneNameFreeform = form.field('BENE_NAMEFREEFORM');
    const findAccountImage = form.field('FINDACCOUNTIMAGE');
    const addNewBiller = form.field('ADDNEW');
    const addressBookUpdate = form.field('UPDATEADDRESSBOOK');
    const debitAccountNumber = form.field('DEBIT_ACCOUNT_NUMBER');
    const valueDate = form.field('VALUE_DATE');
    const { model } = form.formView;
    const hidecutoff = serverConfigParams.get('hideCutoff');
    const { functionCode } = model.jsonData.typeInfo;
    const entryMethod = model.get('ENTRYMETHOD');

    const enableAddNewBiller = function () {
        addNewBiller.$el.one('click', () => {
            biller.shouldBeVisible().shouldBeRequired(true);
            beneNameLookup.shouldBeHidden().shouldBeOptional();
            beneNameFreeform.shouldBeVisible().shouldBeRequired(true);
            model.set({
                UPDATEADDRESSBOOK: '1',
                BENE_ACCOUNT: '',
                CREDIT_AMOUNT: '',
            });

            model.unset('BENEBOOKCHILD_ID', { silent: true });
            model.unset('BENEBOOKUPDATECOUNT__', { silent: true });
            model.unset('BENEBOOK_ID', { silent: true });

            addressBookUpdate.shouldBeVisible().shouldBeReadOnly(true);
            beneAccount.shouldBeReadOnly(false);
            beneAccount.$el.attr('placeholder', locale.get('RTGS.INST.BPAY.*.BENE_ACCOUNT.placeholder'));
            biller.$el.parent().find('.select2-choice').addClass('select2-default');
            biller.$el.parent().find('.select2-chosen').text(locale.get('bab.billerPlaceholder'));

            const [{ value: formattedAmt }] =
                PaymentUtil.updateRTGSSummaryTotal(model, form.formView);
            $('[data-field="summary-amount"]').text(formattedAmt);
        });
        if (entryMethod !== '1') {
            addNewBiller.shouldBeVisible();
        }
    };

    const showAddNewBillerIfEntitled = function () {
        const beneAddressBookOptions = {
            functionCode: 'MAINT',
            entryMethod: 0,

            context: {
                serviceName: 'beneAddressBook/listView/corp',
            },

            action: 'INSERT',
            productCode: '_ADMIN',
            typeCode: 'BENEADBK',
        };

        const beneAddressBookPromise = entitlements.getEntitlements(beneAddressBookOptions);

        beneAddressBookPromise.then((result) => {
            const { actions } = result;
            if (util.has(actions, 'INSERT')) {
                enableAddNewBiller();
            }
        });
    };

    const doFieldValidation = function (fieldname) {
        const type = model.get('TYPE');
        const validationService = services.generateUrl(constants.URL_DO_FIELD_VALIDATION);

        const inputData = [{
            name: 'REQUESTTYPE',
            value: 'fieldValidation',
        }, {
            name: 'FIELDNAME',
            value: fieldname,
        }, {
            name: 'VALUE_DATE',
            value: valueDate.getValue(),
        }, {
            name: 'ACTION',
            value: formState.toUpperCase(),
        }, {
            name: 'TRAN_DATE',
            value: model.get('TRAN_DATE'),
        }, {
            name: 'PRODUCT',
            value: model.get('PRODUCT'),
        }, {
            name: 'FUNCTION',
            value: functionCode,
        }, {
            name: 'TYPE',
            value: type,
        }, {
            name: 'DEBIT_BANK_CODE',
            value: model.get('DEBIT_BANK_CODE'),
        }, {
            name: 'DEBIT_COUNTRY',
            value: model.get('DEBIT_COUNTRY'),
        }, {
            name: 'DEBIT_CURRENCY',
            value: model.get('DEBIT_CURRENCY'),
        }, {
            name: 'BENE_BANK_CODE',
            value: model.get('BENE_BANK_CODE'),
        }, {
            name: 'BENE_BANK_COUNTRY',
            value: model.get('BENE_BANK_COUNTRY'),
        }, {
            name: 'Credit_Amount',
            value: model.get('CREDIT_AMOUNT'),
        }, {
            name: 'Debit_Amount',
            value: model.get('DEBIT_AMOUNT'),
        }, {
            name: 'CREDIT_CURRENCY',
            value: model.get('CREDIT_CURRENCY'),
        }];

        return http.post(
            validationService,
            {
                item: inputData,
            },
        ).then((result) => {
            const retrievedValues = util.reduce(result.item, (accumulator, nvp) => {
                const acc = accumulator;
                if (nvp.name === 'TRAN_DATE' || nvp.name === 'VALUE_DATE') {
                    acc[nvp.name] = moment(nvp.value, 'YYYY-MM-DD').format(userInfo.getDateFormat());
                } else if (nvp.name === 'CUTOFF_INFO') {
                    acc[nvp.name] = nvp.value;
                    if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL') {
                        PaymentUtil.showCutoff(nvp.value, $('.ui-datepicker-trigger'), type);
                    }
                }
                return accumulator;
            }, {});
            model.set(retrievedValues);
            return result;
        });
    };

    /*
     *  The Bene Name combobox value needs to be set on modify.
     *  It's value is a concatenation of the Bene name, bank name and account.
     */
    const initializeBeneNameCombo = function () {
        if (model.get('BENE_NAME')) {
            const name = `${model.get('BENE_NAME')} / ${model.get('BENE_BANK_NAME')} / ${model.get('BENE_ACCOUNT')}`;
            model.set('BENE_NAME_COMBO', name);
            if (formState === 'view') {
                beneNameLookup.shouldBeVisible();
                $('#BENE_NAME').find('span').first().text(name);
            }
        }
    };

    if (initialState && ['insert', 'modify', 'view', 'restore', 'repair'].includes(formState)) {
        if (['insert', 'modify', 'restore', 'repair'].includes(formState)) {
            addNewBiller.shouldBeHidden();
            showAddNewBillerIfEntitled();
            biller.shouldBeHidden();
            beneNameFreeform.shouldBeHidden();
            addressBookUpdate.shouldBeHidden();
            beneAccount.shouldBeReadOnly(true);

            findAccountImage.$el.on('click', () => {
                const billerImageView = new BillerImageView({
                    billerModel: model,
                });
                dialog.open(billerImageView);
            });

            biller.$el.on('change', () => {
                const accountFormat = model.get('ACCOUNTFORMAT');
                const qMarkCount = (accountFormat.match(new RegExp('\\?', 'g')) || []).length;

                model.addValidator(
                    'BENE_ACCOUNT',
                    {
                        matches: validatorPatterns.getBillPayAccountPattern(accountFormat),
                        maxLength: accountFormat.length,

                        // subtract ?s count as it is optional
                        minLength: accountFormat.length - qMarkCount,

                        exists: true,
                        description: `${locale.get('bab.accountWithBiller')} (${accountFormat})`,
                    },
                );
            });
        }

        if (formState === 'view' || formState === 'modify') {
            initializeBeneNameCombo();
        }

        valueDate.$el.on('change', (e) => {
            if (['modify', 'insert', 'restore', 'repair'].includes(formState)) {
                doFieldValidation('VALUE_DATE');
                e.stopImmediatePropagation();
            }
        });

        if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL') {
            PaymentUtil.showCutoff(model.get('CUTOFF_INFO'), $('.ui-datepicker-trigger'), model.get('TYPE'), formState, model.get('STATUS'));
        }
    }

    findAccountImage.shouldBeVisibleWhen(biller.isNotEmpty());

    /*
     * keep the freeform and bene_name in sync so the correct
     * value is populated in the model.  The freeform is used when adding
     * a new bene address book entry.  Avoid the change event on the model
     * so we don't thrash with all the downstream events.
     */
    if (beneNameFreeform.isVisible()) {
        if (model.get('BENE_NAME') !== model.get('BENE_NAMEFREEFORM')) {
            model.set(
                'BENE_NAME',
                model.get('BENE_NAMEFREEFORM'),
                {
                    silent: true,
                },
            );
        }
    }

    if (entryMethod === '1') {
        beneNameLookup.shouldBeReadOnly(true);
        debitAccountNumber.shouldBeReadOnly(true);
    }

    /*
     * hide cutoff if conditions are true  (EN-Entered, IA-Incomplete Approval, RT-Needs
     * Rate, HV-2nd Approval Required are status defined in database)
     */
    rtgsCommon.hideCutoff(formState, functionCode, hidecutoff, 'BPAY', model.get('STATUS'));
}
