import http from '@glu/core/src/http';
import $ from 'jquery';
import { appBus, util } from '@glu/core';
import services from 'services';

let requestData;

export default {
    login(menucontext, params) {
        requestData = {
            productCode: menucontext.productCode,
            functionCode: menucontext.functionCode,
            typeCode: menucontext.typeCode,
            params,
        };
        return this.createLoginPromise(menucontext.menuContext, requestData);
    },

    loginsmb(productcode, functioncode, typecode, params) {
        requestData = {
            productCode: productcode,
            functionCode: functioncode,
            typeCode: typecode,
            params,
        };
        return this.createLoginPromise('/outboundSSO/login', requestData);
    },

    logout() {
        return this.createLogoutPromise('/outboundSSO/logout', {});
    },

    getEntitledSSOs() {
        return new Promise((resolve, reject) => {
            http.post(services.generateUrl('/outboundSSO/getEntitledServices'), null, resolve, reject);
        });
    },

    createLoginPromise(servicePath, loginRequestData) {
        return new Promise((resolve, reject) => {
            http.post(services.generateUrl(servicePath), loginRequestData, (data) => {
                resolve(data);
            }, (result) => {
                reject({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                });
            });
        });
    },

    createLogoutPromise(servicePath, logoutRequestData) {
        return new Promise((resolve, reject) => {
            http.post(services.generateUrl(servicePath), logoutRequestData, (data) => {
                $('body').append(data.imgLogout);

                if (data.formIds && data.formIds.length > 0) {
                    $('body').append(data.formLogout);

                    data.formIds.forEach((id) => {
                        $(`#${id}`).submit();
                    });
                }

                resolve(data);
            }, (result) => {
                reject({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                });
            });
        });
    },

    openSSOWindow(ssoResponse) {
        let target = ssoResponse.vendorCode;
        if (ssoResponse.targetType === 'MULTI_POPUP') {
            target += target + Math.random();
        }
        const formId = `${target}_form`;
        let windowEl = null;
        let form = '';

        this.throttledKeepAliveRequest = util.throttle(this.updateSession, 60000);

        if (ssoResponse.postRedirect) {
            $(`#${formId}`).remove();
            windowEl = window.open('', target);
            form = `<form id="${formId}" action="${ssoResponse.redirectURL}" method="POST" target="${target}">`;
            if (ssoResponse.parameters) {
                ssoResponse.parameters.forEach((param) => {
                    form += `<input type="hidden" name="${param.first}" value="${param.second}">`;
                });
            }
            form += '</form>';
            $('body').append(form);
            $(`#${formId}`).submit();
            windowEl.focus();
        } else if (ssoResponse.redirectURL) {
            windowEl = window.open(ssoResponse.redirectURL, target);
            windowEl.focus();
        } else {
            windowEl = window.open();
            windowEl.document.open();
            // this is opening in a new window, we should not target ssoFrame at this point
            const noSSOFrameTarget = ssoResponse.htmlContent.replace('target="ssoFrame"', '');
            windowEl.document.write(noSSOFrameTarget);
            // if we've written a form into the blank tab, let's make sure it's submitted
            if (windowEl.document.forms.length) {
                windowEl.document.forms[0].submit();
            }
            windowEl.document.close();
            windowEl.focus();
        }

        this.throttledKeepAliveRequest();
    },

    /**
     * @method processKeepAliveResponse
     * @param shouldKeepSessionAlive
     * A success handler for the keep alive service. initiates a timeout, and if
     * true triggers an event
     */
    processKeepAliveResponse(shouldKeepSessionAlive) {
        if (shouldKeepSessionAlive === 'true' || shouldKeepSessionAlive === true) {
            /*
             * if the poller returns boolean true, trigger the keepSessionAlive event to
             * clear the pre-existing timers and avoid the session inactivity warning modal
             */
            appBus.trigger(
                'keepSessionAlive',
                {
                    data: {
                        type: 'keepSessionAlive',
                    },
                },
            );
        }
        this.throttledKeepAliveRequest();
    },

    updateSession() {
        http.get(services.generateUrl('security/ssoKeepAlivePoller'), this.processKeepAliveResponse.bind(this), () => {});
    },
};
