import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import assignmentServices from 'app/administration/views/panelApproval/assignments/assignmentServices';
import ListBuilder from 'common/listBuilder/listBuilder';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import ListBuilderAccountView from './listBuilderAccountView';
import paymentMethodsTmpl from './paymentMethods.hbs';

export default Layout.extend({
    template: paymentMethodsTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $allPaymentMethodsCheckbox: '[data-hook="getAllPaymentMethodsCheckbox"]',
        $paymentMethodCheckbox: '[data-hook="getPaymentCheckbox"]',
        $allAccountsCheckbox: '[data-hook="getAllAccountsCheckbox"]',
        $entryMethodCheckbox: '[data-hook="getEntryMethodCheckbox"]',
        $popovers: '[data-toggle="popover"]',
    },

    events: {
        'change @ui.$allPaymentMethodsCheckbox': 'toggleAllPaymentMethods',
        'change @ui.$entryMethodCheckbox': 'evaluateEntryMethodPayments',
        'click @ui.$paymentMethodCheckbox': 'evaluateAllPaymentCheckbox',
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.renderListBuilder();
            this.ui.$popovers.popover();
            this.setupPaymentMethods();
            this.setupEntryMethods();

            const paymentMethods = this.model.get('paymentMethods');
            if (paymentMethods && paymentMethods.indexOf('FILE') > -1) {
                this.toggleFileCheckbox(true);
            }
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const requests = [
            assignmentServices.getPaymentMethodsRequest(this.model.get('userGroup')),
            assignmentServices.getEntryOptionsRequest(),
            assignmentServices.getAccountListRequest(this.model.get('userGroup')),
        ];

        Promise.all(requests).then((response) => {
            [this.paymentMethods, this.entryMethods, this.sourceAccounts] = response;
            // convert to collection so that we can remove target models (preselected) easily
            this.sourceAccounts = new Collection(this.sourceAccounts);
            // update model with data needed for saving model to server
            this.model.set({
                entryMethodData: this.entryMethods,
                paymentMethodData: this.paymentMethods,
            });
            this.setHasLoadedRequiredData(true);
        }).then(() => {
            this.render();
        });
    },

    templateHelpers() {
        return {
            paymentMethods: this.paymentMethods,
            entryMethods: this.entryMethods,
            allFutureLocale: this.allFutureLocale,
            fileCheckboxTipLocale: this.fileCheckboxTipLocale,
            isFile: value => value === 'FILE',
        };
    },

    fileCheckboxTipLocale() {
        return locale.get('PS.panelApproval.Add.File.Tip');
    },

    allFutureLocale(typeKey) {
        return locale.get('PS.panelApproval.Will.Apply.All.Future', locale.get(typeKey));
    },

    renderListBuilder() {
        // Remove any selected accounts from the source collection
        this.sourceAccounts.remove(this.options.model.get('accounts').models);

        this.listBuilder = new ListBuilder({
            id: 'uce-add-accounts',
            className: 'list-builder dgb-list-builder',
            sourceCollection: this.sourceAccounts,
            targetCollection: this.options.model.get('accounts'),
            labelView: ListBuilderAccountView,
            showBusyIndicator: true,
            text: {
                moveSelectedItems: locale.get('common.listBuilder.moveSelected'),
                moveAllItems: locale.get('common.listBuilder.moveAll'),
                removeSelectedItems: locale.get('common.listBuilder.removeSelected'),
                removeAllItems: locale.get('common.listBuilder.removeAll'),
                filter: locale.get('common.listBuilder.filter'),
                sourceListHeader: 'uce.nAvailableAccounts',
                targetListHeader: 'uce.nAssignedAccounts',
                title: locale.get('common.listBuilder.add'),
                save: locale.get('common.listBuilder.add'),
                cancel: locale.get('button.cancel'),
            },
        });
        this.listenTo(this.listBuilder, 'targetListUpdated', this.updateAccounts);
        this.listBuilderRegion.show(this.listBuilder);
    },

    updateAccounts(selectedAccounts) {
        const accounts = this.options.model.get('accounts');
        accounts.set(selectedAccounts);
    },

    setupPaymentMethods() {
        if (this.model.get('allPaymentMethods') === '*') {
            this.toggleAllPaymentMethods({
                currentTarget: {
                    checked: true,
                },
            });
        }
    },

    setupEntryMethods() {
        const allEntryMethods = this.model.get('allEntryMethods');
        if (allEntryMethods === '*') {
            this.ui.$entryMethodCheckbox.prop('checked', true);
            this.ui.$entryMethodCheckbox.trigger('change');
        }
    },

    /**
     * If "File" is selected, can't select other payment types.
     * @param {Boolean} isFileSelected
     */
    toggleFileCheckbox(isFileSelected) {
        if (isFileSelected) {
            this.listBuilder.removeAllItems();
            this.listBuilder.close();

            this.ui.$allPaymentMethodsCheckbox
                .prop('disabled', true)
                .prop('checked', false)
                .trigger('change');
            this.ui.$paymentMethodCheckbox
                .filter((index, item) => item.value !== 'FILE')
                .prop('disabled', true)
                .prop('checked', false)
                .trigger('change');
            this.ui.$entryMethodCheckbox
                .prop('disabled', true)
                .prop('checked', false)
                .trigger('change');
            this.ui.$entryMethodCheckbox
                .filter((index, item) => item.value === '3') // IMPORT
                .prop('checked', true)
                .trigger('change');
            this.ui.$allAccountsCheckbox
                .prop('disabled', true)
                .prop('checked', true)
                .trigger('change');
        } else {
            this.renderListBuilder();

            this.ui.$allPaymentMethodsCheckbox
                .prop('disabled', false)
                .trigger('change');
            this.ui.$paymentMethodCheckbox
                .prop('disabled', false)
                .trigger('change');
            this.ui.$entryMethodCheckbox
                .prop('disabled', false)
                .prop('checked', false)
                .trigger('change');
            this.ui.$entryMethodCheckbox
                .filter((index, item) => item.value === '3') // IMPORT
                .prop('disabled', false)
                .trigger('change');
            this.ui.$allAccountsCheckbox
                .prop('disabled', false)
                .prop('checked', false)
                .trigger('change');
        }
    },

    evaluateAllPaymentCheckbox(e) {
        if (!e.currentTarget.selected && this.ui.$allPaymentMethodsCheckbox.prop('checked')) {
            this.ui.$allPaymentMethodsCheckbox.prop('checked', false);
            this.model.set('allPaymentMethods', null);
        }

        if (e.currentTarget.value === 'FILE') {
            this.toggleFileCheckbox(e.currentTarget.checked);
        }
    },

    toggleAllPaymentMethods(e) {
        this.ui.$paymentMethodCheckbox
            .filter((index, item) => item.value !== 'FILE')
            .prop('checked', e.currentTarget.checked);
        this.ui.$paymentMethodCheckbox.trigger('change');
    },
});
