import maskedInputWidget from 'common/uiWidgets/maskedInputWidget/maskedInputWidget';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';

export default maskedInputWidget.extend({
    createWidget() {
        const name = this.fieldName;
        const model = this.parentModel;
        const { fieldData, state } = this;
        const inputClassList = 'form-control';
        const readOnlyInputClassList = 'form-control-static';
        const ToggleMaskedWidgetView = createMaskedInputView({
            initialValue: model.get(name),
            cid: model.cid,
            name,
            dataBind: true,
            inputClassList,
            readOnlyInputClassList,
            fieldLabel: fieldData.fieldLabel,
            isRequired: state !== 'VIEW' && fieldData.mandatory,
            isProtected: fieldData.protected,
            maxLength: fieldData.maxLen,
            isReadOnly: state === 'VIEW',
            dontMask: this.parentView.options.maskToggleState === false,
        });
        return ToggleMaskedWidgetView;
    },
});
