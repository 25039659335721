// Helper, which used by glu-grid module (pagination, pageSize) and glu-scheduler.
// ToDo kill with fire.
// AL: I can't kill it yet, because at least Glu Scheduler uses JQuery specific approach.
// In future, if LOB will/GG will decide to get rid - we will do.
import { $, util } from '@glu/core';
import FlexDropdown from './flexDropdown';

/**
 * instantiates a data-bindable Flex Dropdown on an existing element
 * @param [options] options to pass through to the Flex Dropdown constructor
 * @returns {this}
 */
$.fn.flexDropdown = function jqueryFlexDropdown(options, selectedIds) {
  // TODO can data binding SET the value?
  let $target = this;

  // if called with options object, build new flexdropdown
  if (util.isObject(options)) {
    if (this.data('flexDropdown')) {
      // check for existing flex dropdown and replace with the new one
      $target = this.flexDropdown('destroy');
    }

    const original = $target.clone();
    const flexDropdown = new FlexDropdown(util.extend(options, {
      el: $target
    }));

    $target.data({
      flexDropdown,
      originalElement: original
    });

    $target.addClass(util.result(flexDropdown, 'className'));
    return $target;
  }

  // ensure one has already been created
  if ($target.data('flexDropdown')) {
    switch (options) {
      case 'val':
        if (typeof selectedIds !== 'undefined') {
          // val with a second parameter - set the value(s) by ID
          $target.data('flexDropdown').setSelectedIds(selectedIds);
          break;
        } else {
          // return IDs of selected items
          return $target.data('flexDropdown').value('id');
        }
      case 'data':
        // return objects of selected items
        return $target.data('flexDropdown').value();
      case 'destroy':
      {
        // tear down flexdropdown
        const $original = $target.data('originalElement');
        $original.insertBefore($target);
        $target.data('flexDropdown').close();
        return $original;
      }
      default:
    }
  }

  return $target;
};
