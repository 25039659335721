const Constants = {
    options: {
        menuCategory: 'employee',
        serviceName: 'payroll',
        serviceFunc: null,
        businessType: null,
        context: 'SMB_EMPLOYEE',
    },
};

export default Constants;
