var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.Warn.structured.address",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":56}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <option value=\"\"></option>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"fieldTypeData") : depths[1])) != null ? lookupProperty(stack1,"BENE_COUNTRY") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"ifEqual","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":35,"column":28}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":32,"column":35},"end":{"line":32,"column":45}}}) : helper)))
    + "\" selected>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":32,"column":56},"end":{"line":32,"column":65}}}) : helper)))
    + "</option>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":34,"column":35},"end":{"line":34,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":34,"column":47},"end":{"line":34,"column":56}}}) : helper)))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <div class=\"form-group\">\n            <label for=\"BENE_STATE\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"bab.state",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":53,"column":58}}}))
    + "</label>\n            <select class=\"form-control\" name=\"BENE_STATE\" id=\"BENE_STATE\" data-bind=\"model\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"doesNotHaveDefaultValues") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":57,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"states") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":64,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_STATE\"></span>\n        </div>\n    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <option value=\"\"></option>\n";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"fieldTypeData") : depths[1])) != null ? lookupProperty(stack1,"BENE_STATE") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"ifEqual","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(8, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":63,"column":32}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <div class=\"form-group\">\n            <label for=\"BENE_PROVINCE\">"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.province",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":39},"end":{"line":72,"column":64}}}))
    + "</label>\n            <input class=\"form-control\" type=\"text\" name=\"BENE_PROVINCE\" id=\"BENE_PROVINCE\" value=\""
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_PROVINCE") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\""
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_PROVINCE") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_PROVINCE\"></span>\n        </div>\n    </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.postal.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":83,"column":44}}}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.zip.postal.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":85,"column":48}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.address.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":8},"end":{"line":1,"column":37}}}))
    + " <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"iconClassName") || (depth0 != null ? lookupProperty(depth0,"iconClassName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"iconClassName","hash":{},"data":data,"loc":{"start":{"line":1,"column":51},"end":{"line":1,"column":68}}}) : helper)))
    + "\"></span></legend>\n\n<div>\n    <div class=\"form-group\">\n        <label for=\"BENE_ADDRESS_1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.address.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":62}}}))
    + "</label>\n        <input class=\"form-control\" type=\"text\" name=\"BENE_ADDRESS_1\" id=\"BENE_ADDRESS_1\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_ADDRESS_1") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_ADDRESS_1") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ADDRESS_1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNotStructValidated") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </div>\n</div>\n\n<div class=\"address-line-two\">\n    <div class=\"form-group\">\n        <label for=\"BENE_ADDRESS_2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.address.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":62}}}))
    + "</label>\n        <input class=\"form-control\" type=\"text\" name=\"BENE_ADDRESS_2\" id=\"BENE_ADDRESS_2\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_ADDRESS_2") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_ADDRESS_2") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ADDRESS_2\"></span>\n    </div>\n</div>\n\n<div>\n    <div class=\"form-group\">\n        <label for=\"BENE_COUNTRY\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.country",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":58}}}))
    + "</label>\n        <select class=\"form-control\" name=\"BENE_COUNTRY\" id=\"BENE_COUNTRY\" data-bind=\"model\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"doesNotHaveDefaultValues") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"countries") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":36,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_COUNTRY\"></span>\n    </div>\n</div>\n\n<div>\n    <div class=\"form-group\">\n        <label for=\"BENE_CITY\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.city",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":31},"end":{"line":44,"column":52}}}))
    + "</label>\n        <input class=\"form-control\" type=\"text\" name=\"BENE_CITY\" id=\"BENE_CITY\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_CITY") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_CITY") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_CITY\"></span>\n    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUS") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":50,"column":0},"end":{"line":77,"column":7}}})) != null ? stack1 : "")
    + "\n<div>\n    <div class=\"form-group\">\n        <label for=\"BENE_POSTALCODE\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUS") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":82,"column":12},"end":{"line":86,"column":19}}})) != null ? stack1 : "")
    + "        </label>\n        <input class=\"form-control\" id=\"BENE_POSTALCODE\" type=\"text\" name=\"BENE_POSTALCODE\" value=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_POSTALCODE") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_POSTALCODE") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_POSTALCODE\"></span>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});