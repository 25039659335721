import store from 'system/utilities/cache';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';
import contextApi from 'common/dynamicPages/api/context';
import ContextModel from 'common/dynamicPages/models/context';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import constants from 'app/bab/constants';
import apiconstants from 'common/dynamicPages/api/constants';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import workspaceHelper from 'common/workspaces/api/helper';
import transform from 'common/util/transform';
import ListView from 'common/dynamicPages/views/workflow/list';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import ItemsEffectedWarning from 'common/dynamicPages/views/itemsEffectedWarning';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import lvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import InquiryService from '../service/inquiry';
import tpl from './list.hbs';

const BeneAddressBookView = ListView.extend({
    template: tpl,
    loadingTemplate,

    regions: {
        alertRegion: '.alert-region',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="export-button"]': 'showExportOptionsModal',
            'click .add-contact': 'addContact',
            'click .delete-contacts': 'delete',
            'click [data-hook="fileimport-button"]': 'babfileimport',
            'click .approve-contacts': 'approve',
        },
    ),

    initialize(options) {
        this.options = options;

        if (this.options.returnRoute) {
            store.set('beneAddressBookReturnRoute', this.options.returnRoute);
        }

        this.contextDef = contextApi.menuContext.getContext('BENEADBK_MAINT');
        if (!this.contextDef.serviceName && this.options.serviceName) {
            this.contextDef.serviceName = this.options.serviceName;
        }
        this.contextModel = new ContextModel({
            menuCategory: 'adminstration',
            serviceName: 'manageBeneAddressBook',
            serviceFunc: null,
            businessType: null,
            context: 'BENEADBK_MAINT',
            contextDef: this.contextDef,
        });
        this.gridLoaded = false;
        this.contactTypeService = new InquiryService();
        this.listenTo(this.contactTypeService, 'success', this.handleContactTypeSuccess);
        this.setListViewConfig();
        if (this.options.viewId) {
            this.lvc.reset(true);
            this.lvc.set(lvcConstants.VIEWID, this.options.viewId, true);
        }

        this.loadViewRequirements();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);

            if (store.has('addContactSuccessResponse')) {
                const response = store.get('addContactSuccessResponse');

                if (response.confirms && response.confirms.confirmResults.length > 0) {
                    this.renderMessage('ADD', response);
                } else {
                    this.alertRegion.show(alert.success(response.message[0]));
                }
            } else if (store.has('addContactSuccessMessage')) {
                this.alertRegion.show(alert.success(store.get('addContactSuccessMessage')));
            }

            store.unset('addContactSuccessMessage');
            store.unset('addContactSuccessResponse');
        }
    },

    templateHelpers() {
        const self = this;

        return {
            hasInsertEntitlement() {
                return self.hasEntitlement('INSERT');
            },

            hasApproveEntitlement() {
                return self.hasEntitlement('APPROVE');
            },

            getButtonString(messageCode) {
                return locale.get(messageCode);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement('DELETE');
            },
        };
    },

    loadViewRequirements() {
        const options = {
            context: contextApi.menuContext.getContext(constants.GRID_CONTEXT),

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableSavedViews: true,

            // instead of executing context actions, grid handles callback
            enableRowContextButtonCallbacks: true,

            loadedCallback: () => {
                this.gridLoaded = true;
            },
            lvc: this.lvc,
        };
        options.gridOptions = { caption: options?.context?.name };
        this.gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);
        ListView.prototype.setupGridAvailableListener.call(this);

        return entitlementPromise.then((result) => {
            this.entitlements = result;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.setHasLoadedRequiredData(true);
                this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
                ListView.prototype.listenForGridErrors.call(this);
                this.render();
            }
            return {
                entitlements: result.actions,
                viewInstance: this,
            };
        });
    },

    hasEntitlement(action) {
        return this.entitlements.actions[action];
    },

    gridRowDelete(options) {
        const self = this;
        return new Promise((resolve, reject) => {
            options.model.destroy({
                success(model, resp) {
                    let isWarning = false;

                    if (resp && resp.confirms && resp.confirms.confirmResults[0] && resp.confirms.confirmResults[0].resultType === 'WARNING') {
                        isWarning = true;
                    }

                    if (resp.errorCode === apiconstants.ITEMS_EFFECTED_WARNING_CODE) {
                        const itemsEffectedWarning = new ItemsEffectedWarning({
                            resp,
                            model,
                            actionMode: 'DELETE',
                        });
                        itemsEffectedWarning.once('deleteContact', self.gridRowDelete, self);
                        itemsEffectedWarning.once('cancelDeleteContact', self.gridRowCancelDeleteWithWarning, self);
                        dialog.custom(itemsEffectedWarning);
                    } else if (isWarning) {
                        self.listenTo(model, 'modelAction:deleteWithWarning', self.gridRowDeleteWithWarning);
                        self.listenTo(model, 'modelAction:errorForDetail', self.gridRowCancelDeleteWithWarning);
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'DELETE',
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        self.renderMessage('DELETE', resp);
                    }
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.refreshGridData();
                    self.renderMessage('DELETE');
                    reject(e);
                },
            });
        });
    },

    gridRowCancelDeleteWithWarning() {
        this.gridView.refreshGridData();
    },

    gridRowDeleteWithWarning(rowModel) {
        const self = this;
        return new Promise((resolve, reject) => {
            rowModel.set('_saveWithWarning', 'true');
            rowModel.destroy({
                success(model, resp) {
                    let isWarning = false;
                    if (resp && resp.confirms && resp.confirms.confirmResults[0] && resp.confirms.confirmResults[0].resultType === 'WARNING') {
                        isWarning = true;
                    }
                    if (isWarning) {
                        self.listenTo(model, 'modelAction:deleteWithWarning', self.gridRowDeleteWithWarning);
                        self.listenTo(model, 'modelAction:errorForDetail', self.gridRowCancelDeleteWithWarning);
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'DELETE',
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        self.renderMessage('DELETE', resp);
                    }
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.refreshGridData();
                    self.renderMessage('DELETE');
                    reject(e);
                },
            });
        });
    },

    gridRowApprove(options) {
        const self = this;
        return new Promise((resolve, reject) => {
            options.model.approve({
                success(model, resp) {
                    if (resp.errorCode === apiconstants.ITEMS_EFFECTED_WARNING_CODE) {
                        const itemsEffectedWarning = new ItemsEffectedWarning({
                            resp,
                            actionMode: 'APPROVE',
                            model,
                        });
                        itemsEffectedWarning.once('approveContact', self.gridRowApprove, self);
                        dialog.custom(itemsEffectedWarning);
                    } else if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: 'APPROVE',
                            grid: self.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        self.gridView.refreshGridData();
                        self.renderMessage(options.action, resp);
                        resolve({ model, result: resp });
                    }
                },

                error(e) {
                    self.gridView.refreshGridData();
                    self.renderMessage(options.action);
                    reject(e);
                },
            });
        });
    },

    gridRowAction(options) {
        const opts = options;
        return new Promise((resolve, reject) => {
            if (opts.action.toUpperCase() === 'SELECT') {
                store.set({
                    [constants.STORE_BAB_CONTEXT]: opts.model.context,
                    [constants.STORE_BAB_VIEWMODEL]: opts.model,
                });

                this.handleEntitlements(options.model);
                this.navigateTo('SETUP/viewContact/corp');
                resolve();
            }

            if (opts.action.toUpperCase() === 'DELETE') {
                opts.model.context.serviceName = 'beneAddressBook';
                return this.gridRowDelete(opts).then(resolve, reject);
            }

            if (opts.action.toUpperCase() === 'APPROVE') {
                opts.model.context.serviceName = 'beneAddressBook';
                return this.gridRowApprove(opts).then(resolve, reject);
            }

            if (opts.action.toUpperCase() === 'MODIFY') {
                store.set({
                    [constants.STORE_BAB_CONTEXT]: opts.model.context,
                    [constants.STORE_BAB_VIEWMODEL]: opts.model,
                });
                this.navigateTo('SETUP/modifyContact/corp');
                resolve();
            }
            return resolve();
        });
    },

    handleEntitlements(model) {
        let isTestMode = false;
        const approveAction = util.findWhere(
            model.buttons,
            {
                action: 'APPROVE',
            },
        );
        if (!util.isNullOrUndefined(store.get(constants.STORE_BAB_VIEWMODEL))) {
            isTestMode = store.get(constants.STORE_BAB_VIEWMODEL).get('TESTFILEIMPORT') === '1';
        }

        if (!isTestMode && this.hasEntitlement(constants.ACTION_MODIFY)) {
            store.set('setup:contact:modify:entitlement', true);
        } else {
            store.unset(
                'setup:contact:modify:entitlement',
                {
                    silent: true,
                },
            );
        }

        if (!isTestMode && this.hasEntitlement(constants.ACTION_APPROVE)
            && !util.isNullOrUndefined(approveAction)) {
            store.set('setup:contact:approve:entitlement', true);
        } else {
            store.unset(
                'setup:contact:approve:entitlement',
                {
                    silent: true,
                },
            );
        }

        if (this.hasEntitlement(constants.ACTION_DELETE)) {
            store.set('setup:contact:delete:entitlement', true);
        } else {
            store.unset(
                'setup:contact:delete:entitlement',
                {
                    silent: true,
                },
            );
        }
    },

    addContact() {
        this.contactTypeService.setActionMode('SELECT');
        this.contactTypeService.setFunctionCode('MAINT');
        this.contactTypeService.setProductCode('_ADMIN');
        this.contactTypeService.setInquiryId(17246);
        this.contactTypeService.setTypeCode('BENEADBK');
        this.contactTypeService.send();
        return Promise.resolve();
    },

    handleContactTypeSuccess(resp) {
        if (resp && ((resp.length === 1 && resp[0].name === '_NONE_') || resp.length === 0)) {
            dialog.alert(locale.get('PAY.NoContactAlert'), locale.get('Confirm.Single.ALERT'), () => {});
        } else {
            this.navigateTo('SETUP/addContact/corp');
        }
    },

    approve() {
        this.handleBulkAction(constants.ACTION_APPROVE);
    },

    delete() {
        this.handleBulkAction(constants.ACTION_DELETE, [{
            name: 'DRYRUNONLY',
            value: 'true',
        }]);
    },

    handleBulkAction(methodName, args) {
        let ar;
        let eligibleRows;
        if (this.alertRegion) {
            this.alertRegion.close();
        }
        if (this.gridLoaded) {
            ar = this.gridView.grid.getSelectedRows();
            eligibleRows = ar;
        }
        if (ar && ar.length > 0) {
            let message = '';
            if (methodName === apiconstants.ACTION_APPROVE) {
                ar.forEach((row) => {
                    const model = this.gridView.wrapper.rows.get(row);
                    let approvalFlag = false;
                    model.buttons.forEach((button) => {
                        if (button.action === 'APPROVE') {
                            approvalFlag = true;
                        }
                    });
                    if (!approvalFlag) {
                        eligibleRows = util.without(eligibleRows, row);
                        message = locale.get('bab.not.eligible');
                    }
                });
            }

            const translatedAction = this.translateActionText(methodName);
            const title = locale.get('title.confirmActionTitle', translatedAction);
            const msg = locale.get('title.confirmActionMessage', message, translatedAction);
            dialog.confirm(msg, title, (ok) => {
                if (ok) {
                    this.processMultiActionsAsOne(methodName, eligibleRows, args);
                }
            });
        }
    },

    translateActionText(action) {
        let translatedAction = locale.get(`action.${action}`);
        if (translatedAction.indexOf('??') > -1) {
            translatedAction = action;
        }
        return (translatedAction) ? translatedAction.toLowerCase() : '';
    },

    handleMultipleDeleteWarning(resp, methodName, selectedArray) {
        const warningModelsArray = [];
        if (resp && resp.confirms) {
            util.each(resp.confirms.confirmResults, (confirmResult) => {
                if (confirmResult.resultType === 'WARNING' && confirmResult.confirmData[0]
                    && confirmResult.confirmData[0].item) {
                    const confirmMap = transform.pairsToHash(confirmResult.confirmData[0].item);
                    util.each(selectedArray, (selectedItem) => {
                        const selModel = this.gridView.wrapper.rows.get(selectedItem);
                        if (selModel.get('TNUM') === confirmMap.ID) {
                            warningModelsArray.push(selModel);
                        }
                    });
                }
            });
        }
        this.multipleDeleteWarning(methodName, resp, warningModelsArray);
    },

    handleMultipleEffectedItemsWarning(resp, selectedArray, methodName) {
        this.multipleItemsEffectedWarning(resp, selectedArray, methodName);
    },

    handleMultiItemsResponse(confirmResponse, methodName, selectedArray) {
        /*
         * If the dry run produced no errors or warnings, resubmit the request
         * to execute the change.
         */
        if (confirmResponse.resultType === 'DRYRUNSUCCESS') {
            this.processMultiActionsAsOne(methodName, selectedArray);
        } else {
            this.renderMessage(methodName, confirmResponse);
        }
    },

    processMultiActionsAsOne(methodName, selectedArray, additionalParams) {
        const jsonData = {
            items: [],
        };

        if (selectedArray.length <= 0) {
            return;
        }
        let service = services.generateUrl(this.contextDef.menuContext);
        if (methodName === apiconstants.ACTION_APPROVE || methodName === 'MULTICHANGEWARNING-APPROVE') {
            service += apiconstants.URL_APPROVE_ACTION;
        } else if (methodName === constants.ACTION_DELETE || methodName === 'MULTICHANGEWARNING-DELETE') {
            service += apiconstants.URL_DELETE_ACTION;
        }

        for (let i = 0; i < selectedArray.length; i += 1) {
            let item;
            const model = this.gridView.wrapper.rows.get(selectedArray[i]);
            if (methodName === apiconstants.ACTION_DELETE) {
                item = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
                if (!util.isEmpty(additionalParams)) {
                    item.item = item.item.concat(additionalParams);
                }
            }

            if (methodName === apiconstants.ACTION_APPROVE) {
                item = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
            }

            if (methodName === 'MULTICHANGEWARNING-APPROVE' || methodName === 'MULTICHANGEWARNING-DELETE') {
                model.set(apiconstants.CONTACT_CHANGES_ACCEPTED_INDICATOR, true);
                item = model.convertModelAttributesToKeyWithOptContextJSON(model, true);
            }

            jsonData.items.push(item);
        }

        const options = {
            action: methodName,
        };
        options.success = (resp) => {
            let isWarning = false;

            if (resp && resp.confirms && methodName === apiconstants.ACTION_DELETE) {
                util.each(resp.confirms.confirmResults, (confirmResult) => {
                    if (confirmResult.resultType === 'WARNING') {
                        isWarning = true;
                    }
                });
            }
            if (resp.errorCode === apiconstants.ITEMS_EFFECTED_WARNING_CODE) {
                this.handleMultipleEffectedItemsWarning(resp, selectedArray, methodName);
            } else if (isWarning) {
                this.handleMultipleDeleteWarning(resp, methodName, selectedArray);
            } else {
                this.gridView.refreshGridData();
                this.handleMultiItemsResponse(resp, methodName, selectedArray);
            }
        };
        options.error = (resp) => {
            this.gridView.refreshGridData();
            this.handleMultiItemsResponse(resp, methodName);
        };

        http.post(service, jsonData, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result.responseJSON);
        });
    },

    multipleItemsEffectedWarning(resp, selectedArray, methodName) {
        const itemsEffectedWarning = new ItemsEffectedWarning({
            resp,
            selectedArray,
            actionMode: `MULTICHANGEWARNING-${methodName}`,
        });
        itemsEffectedWarning.once('multiWarning', this.processMultiActionsAsOne, this);
        itemsEffectedWarning.once('cancelDeleteContact', this.gridRowCancelDeleteWithWarning, this);
        dialog.custom(itemsEffectedWarning);
    },

    multipleDeleteWarning(action, confirmResponse, selectedModelArray) {
        const warningDialog = new WarningDialog({
            methodName: action,

            //  multipleApproval: true,
            modelsArray: selectedModelArray,

            confirms: confirmResponse,
        });
        warningDialog.once('list:multi:action:action_deleteWithWarning', this.actionDeleteWithWarning, this);
        warningDialog.once('list:multi:action:action_continueWithResponse', this.actionDeleteWithResponse, this);
        dialog.custom(warningDialog);
    },

    actionDeleteWithWarning(modelsArray, args) {
        this.processMultiActionsAsOne(constants.ACTION_DELETE, modelsArray, args);
    },

    actionDeleteWithResponse() {
        this.gridView.refreshGridData();
    },

    babfileimport() {
        this.navigateTo('SETUP/babimport');
    },

    renderMessage(action, confirmResponse, errorCount) {
        /*
         * create the confirmation view for the alert
         * skip if action is null.  This occurs when first entering the payment workspace
         * because the onRender is called.
         */
        if (util.isNullOrUndefined(action) && util.isNullOrUndefined(confirmResponse)) {
            return;
        }
        const cResp = confirmResponse;
        if (cResp === null || cResp === undefined) {
            if (action.type === 'WARNING' && action.message) {
                this.alertView = alert.warning(action.message);
                this.alertRegion.show(this.alertView);
            }
        } else {
            const confirm = typeof cResp === 'object' ? cResp.confirms : null;
            const successCount = confirm !== null ? confirm.totalSuccess : cResp;
            const failCount = confirm !== null ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            const success = successCount === totalCount;
            const alertFunc = success ? alert.success : alert.danger;
            const chainedMessages = typeof cResp === 'object' ? cResp.message : null;
            const message = typeof cResp === 'object' && cResp.message.length > 0 ? cResp.message[chainedMessages.length - 1] : action;
            let isWarning = false;
            if (cResp && cResp.confirms && cResp.confirms.confirmResults[0] && cResp.confirms.confirmResults[0].resultType === 'WARNING') {
                isWarning = true;
            }
            const confirms = new Confirms({
                confirms: cResp ? cResp.confirms : null,
            });

            if (isWarning) {
                this.alertView = alert.warning(message);
            } else {
                const valid = cResp && cResp.confirms.confirmResults[0].confirmData[0].item;
                // display notification message
                this.alertView = alertFunc(
                    message,
                    {
                        details: valid ? confirms : null,
                        canDismiss: !!cResp,
                        animate: true,
                    },
                );
            }

            if (message) {
                this.alertRegion.show(this.alertView);
            }
        }
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
        });
        dialog.custom(printModal);
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: apiconstants.INQUIRY_ID_20018,
        };
    },

    /**
     * Get export options for the list
     * @returns {Object}
     */
    getExportOptions() {
        return {
            inquiryId: apiconstants.exportInquiry.INQUIRY_ID_72206,
            summaryInquiryId: apiconstants.exportInquiry.INQUIRY_ID_17056,
        };
    },
});

let list = BeneAddressBookView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        apiMessageContext: 'addContactSuccessMessage',
        insertActions: [
            {
                label: 'bab.contact.add',
                entitlement: 'INSERT',
                handlerMethodName: 'addContact',
            },
        ],
        bulkActions: [
            {
                label: 'button.approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'button.delete',
                entitlement: 'DELETE',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'ADDRESS_BOOK',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
