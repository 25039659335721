import AbsoluteView from '@glu/dialog/src/views/modalView';
import modalTmpl from './modal.hbs';

export default AbsoluteView.extend({
    template: modalTmpl,

    initialize(options) {
        AbsoluteView.prototype.initialize.call(this, options);
        this.maskFlag = options.mask !== false;
        this.type = options.type;
        this.message = options.message.attributes;
        this.title = options.title;
        this.icon = options.icon;
        this.buttons = options.buttons;
    },

    templateHelpers() {
        return {
            type: this.type || 'standard',
            title: this.title,
            icon: this.icon,
            buttons: this.buttons,
            cellComment: this.message.comment,
            cellBilledAmount: this.message.billedAmount,
            cellDiscountAmount: this.message.discountAmount,
            cellAdjustmentAmount: this.message.adjustmentAmount,
            cellAdjustmentReason: this.message.adjustmentReason,
            cellAdjustmentReasonDesc: this.message.adjustmentReasonDesc,
            cellReferenceNumber: this.message.referenceNumber,
            cellReferenceNumberType: this.message.referenceNumberType,
            cellReferenceNumberTypeDesc: this.message.referenceNumberTypeDesc,
            cellPaymentDescription: this.message.paymentDescription,
            usePaymentRemittance: this.message.usePaymentRemittance,
        };
    },
});
