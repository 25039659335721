import http from '@glu/core/src/http';
import services from 'services';

let features = {};

http.get(services.generateUrl('featureControl/getFeatureAccess'))
    .then((data) => {
        features = data;
    });

export default {
    get: key => features[key],
};
