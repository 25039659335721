import Collection from '@glu/core/src/collection';
import dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import CONSTANT from 'common/dynamicPages/api/constants';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import scroll from 'common/util/scroll';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import contactUtil from '../util/contactUtil';
import AccountLookup from '../service/bankCodeLookup';
import AccountModel from '../model/accountModel';
import AccountPaymentDetailAPI from '../service/accountPaymentDetail';
import BaseAccountCollectionView from './accountCollectionView';
import BaseView from './baseView';
import CurrencyPaymentDetailAPI from '../service/currencyPaymentDetail';
import DataConverterAPI from '../service/dataConverter';
import IntermediaryBankView from './intermediaryBankWrapper';
import PaymentTypeCollection from '../collections/paymentType';
import SecondaryAccountForm from './secondaryAccountForm';
import tpl from './accountForm.hbs';

/*
 * Defined here because it is used inside AccountFormView
 * and AccountFormView is used inside AccountCollectionView
 */
let AccountCollectionView;

const AccountFormView = BaseView.extend({
    collapsed: true,

    initialize() {
        // CONTACTTYPE = 4 means both employee and individual; 3 means employee
        if (['3', '4'].includes(this.model.get('CONTACTTYPE'))) {
            this.model.set('isEmployee', true);
        } else if (!this.model.has('isEmployee')) {
            this.model.set('isEmployee', false);
        }
        if (this.options.isFirstAccount) {
            this.options.model.set('PRIMARY_ACCOUNT', true);
        }
        if (this.options.model.collection) {
            this.accountList = this.options.model.collection;
        }
        this.accountModel = this.options.data
            ? new AccountModel(this.options.data) : new AccountModel();
        this.mode = this.options.mode || 'create';
        this.isSMB = this.options.isSMB;
        this.isEntryRow = this.options.isEntryRow;
        this.contactModel = this.options.contactModel;
        this.collapsed = (this.options.collapsed === undefined)
            ? this.collapsed : this.options.collapsed;

        this.isFirstModify = this.mode === 'modify'
            && (this.model.has('BENE_BANK_ID') || (this.model.has('BENE_NAME')));

        this.paymentTypeCollection = this.options.paymentTypeCollection
            || new PaymentTypeCollection();
        this.accountCollection = this.options.accountCollection;
        this.countries = this.options.countries;

        this.currencyService = new CurrencyPaymentDetailAPI();
        this.paymentDetailsService = new AccountPaymentDetailAPI();
        this.secondaryBankService = new AccountPaymentDetailAPI();
        this.dataConverterService = new DataConverterAPI();

        /*
         * entry row inherits account collection from addContact.js, subsequent rows
         * must take it from accountList
         */
        if (this.accountCollection.length === 0 && !this.isEntryRow) {
            this.accountCollection = this.accountList;
        }

        /*
         * determine if user is allowed to see freeform
         * dont make this call, however, if BENEBANKIDENTRYMETHOD is already defined
         * this would indicate the call was already made
         */
        if (!this.model.get('BENEBANKIDENTRYMETHOD')) {
            this.model.set('FREE_FORM_ENTITLED', false);
            this.paymentDetailsService.getConfigInfo();
        }

        // if this map comes back at all, then freeforms are allowed
        const ffm = this.model.get('FREEFORM_TYPES_MAP');
        if ((ffm && ffm.FEDWIRE && ffm.FEDWIRE.AllowFreeFormBeneBank === 'true')
            || (ffm && ffm.INTL && ffm.INTL.AllowFreeFormBeneBank === 'true')) {
            this.model.set('FREE_FORM_ENTITLED', true);
        }

        const accountMode = this.model.get('MODE');
        const lookupMode = this.model.get('BENEBANKIDENTRYMETHOD');
        if (!lookupMode) {
            this.model.set('BENEBANKIDENTRYMETHOD', 'LOOKUP');
        }
        // set mode to OTHER by default
        if (!accountMode) {
            this.model.set('MODE', 'OTHER');
            this.model.set('BENEBANKIDENTRYMETHOD', 'LOOKUP');
        }

        if (accountMode === 'IBAN') {
            this.model.set('BENEBANKIDENTRYMETHOD', 'LOOKUP');
        }

        this.setBeneAccountTypeByAccountMode();

        this.accountCodeLookup = new AccountLookup();
        this.secondaryCodeLookup = new AccountLookup();
        this.currencyCollection = this.options.currencyCollection
            ? new Collection(this.options.currencyCollection.toJSON()) : new Collection();
        this.accountCollectionView = new AccountCollectionView({
            mode: this.mode,
            isSMB: this.isSMB,
            collection: this.accountCollection,
            currencyCollection: this.currencyCollection,
            contactModel: this.options.contactModel,
            countries: this.countries,
        });

        this.intermediaryBankView = new IntermediaryBankView({
            mode: this.mode,
            isEntryRow: this.isEntryRow,
            model: this.model,
        });

        this.secondaryAccountForm = new SecondaryAccountForm({
            mode: this.mode,
            model: this.model,
        });

        if (accountMode === 'IBAN' && this.mode === 'modify') {
            this.beneAccountCurrencyHandler({
                target: {
                    value: undefined,
                },
            });
        }

        this.model.set('BENE_BANK_CODE_PRIMARY', '');
        // get the masking properties once
        this.maskingProps = getMaskingProperties();
    },

    template: tpl,

    ui: {
        $accountFormBody: '.account-form-body',
        $accountType: 'form select[name="BENE_ACCOUNTTYPE"]',
        $addAnotherBtn: '> div > div > div > form [data-action=addAnotherAccount]',
        $bankAccountMode: '> div > div > div > form input[name="MODE"]',
        $bankCode: '> div > div > div > form input[name="BENE_BANK_CODE_SORTCODETYPE"]',
        $bankCodeHelp: '.bank-id-help',
        $bankInputMode: '> div > div > div > form input[name="BENEBANKIDENTRYMETHOD"]',
        $beneAccountCurr: '> div > div > div > form select[name="BENE_ACCOUNT_CURRENCY"]',
        $beneAccountNumber: '> div > div > div > form input[name="BENE_ACCOUNTNUMBER"]',
        $beneBankAddress1: '> div > div > div > form input[name="BENE_BANK_ADDRESS_1"]',
        $beneBankAddress2: '> div > div > div > form input[name="BENE_BANK_ADDRESS_2"]',
        $beneBankCountry: '> div > div > div > form select[name="BENE_BANK_COUNTRY"]',
        $beneBankName: '> div > div > div > form input[name="BENE_BANK_NAME"]',
        $benePaymentType: '> div > div > div > form input[name="PAYMENTTYPE"]',
        $closeBtn: '> .panel > .panel-heading .icon-close',
        $container: '> div > div > div > form',
        $intermediaryRegion: '.intermediary-region',
        $makePrimary: '> div > div > div > form input[name="PRIMARY_ACCOUNT"]',
        $popovers: '[data-toggle="popover"]',
        $savingsChecking: '> div > div > div > form select[name="BENE_ACCOUNTTYPE"]',
        $secondaryBankCode: '> div > div > div > form input[name="BENE_BANK_CODE_SECONDARY_SORTCODETYPE"]',
        $singleCheckbox: '.single-checkbox',
        form: 'form',
    },

    events: {
        'change @ui.$bankAccountMode': 'changeAccountModeHandler',
        'change @ui.$bankCode': 'handleBankCodeChange',
        'change @ui.$bankInputMode': 'changeInputModeHandler',
        'change @ui.$beneAccountCurr': 'handleBeneAccountCurrChange',
        'change @ui.$beneBankCountry': 'handleBeneBankCountryChange',
        'change @ui.$benePaymentType': 'changePaymentTypeHandler',
        'change @ui.$savingsChecking': 'changeSavingsCheckingHandler',
        'change @ui.$secondaryBankCode': 'handleSecondaryBankCodeChange',
        'click > div > .panel-heading [data-toggle="collapse"]': 'handleCollapse',
        'click @ui.$addAnotherBtn': 'handleAccountAddAnother',
        'click @ui.$closeBtn': 'handleCancelClick',
        'click @ui.$makePrimary': 'handlePrimaryAccountChange',
        'click input[type=button][data-trigger=back]': 'handleBackwards',
        'focusout @ui.$bankCode': 'focusOutBankCodeHandler',
        'focusout @ui.$beneAccountNumber': 'handleBeneBankAccountNumberChange',
        'focusout @ui.$beneBankAddress1': 'handleBeneBankAddress1Change',
        'focusout @ui.$beneBankAddress2': 'handleBeneBankAddress2Change',
        'focusout @ui.$beneBankName': 'handleBeneBankNameChange',
    },

    regions: {
        intermediaryRegion: '.intermediary-region',
        accountRegion: '.accounts-region',
    },

    delegateEvents() {
        BaseView.prototype.delegateEvents.call(this);
        this.listenTo(this.currencyService, 'success', this.handlePaymentDetailsSuccess);
        this.listenTo(this.paymentDetailsService, 'success', this.handlePaymentDetailsSuccess);
        this.listenTo(this.paymentDetailsService, 'successIBANResponse', this.handleIBANSuccessResponse);
        this.listenTo(this.secondaryBankService, 'success', this.handleSecondaryBankSuccess);
        this.listenTo(this.model, 'change:PAYMENTTYPE', this.handlePaymentTypeChanged);

        if (this.contactModel) {
            this.listenTo(this.contactModel, 'change:CONTACTTYPE', this.contactTypeChangeHandler);
        }
    },

    /**
     * runs when a user enables or disables a payment type for an account. Kicks off some
     * subsequent DOM updates and model changes
     * @param {Model} model
     */
    handlePaymentTypeChanged(model) {
        let achSelected = false;

        let modelPaymentTypes = model.get('PAYMENTTYPE');
        const paymentTypeCollection = this.model.get('PAYMENTTYPE_COLLECTION');

        modelPaymentTypes = util.isArray(modelPaymentTypes)
            ? modelPaymentTypes : [modelPaymentTypes];

        achSelected = modelPaymentTypes.some((paymentType) => {
            const payType = paymentTypeCollection.findWhere({
                value: paymentType,
            });
            return (payType && payType.get('product') === 'USACH');
        });

        this.ui.$accountType.parent().toggleClass('required', achSelected);

        model.set('ACH_SELECTED', achSelected, { silent: true });

        if (achSelected) {
            if (this.model.get('BENE_ACCOUNTTYPE') === 'Other') {
                this.model.set('BENE_ACCOUNTTYPE', '');
            }
        }
    },

    /**
     * Change the Primary account checkbox and display appropriate dialogs
     * @param {event} eventParam - JQuery click event
     */
    handlePrimaryAccountChange(eventParam) {
        const event = eventParam;
        /*
         * the user is attempting to deselect this account as primary
         * but it's the only primary account
         */
        if (!event.currentTarget.checked) {
            // tell the user they can't deselect the primary account
            dialog.alert(locale.get('bab.error.cannot.delete.primary'));
            // re-check this checkbox since the user can't disable it being primary
            event.currentTarget.checked = true;
            return;
        }
        /*
         * the user is attempting to make this account primary,
         * tell the user they're changing primary accounts
         */
        dialog.confirm(locale.get('bab.primary.change.confirm'), (ok) => {
            if (ok) {
                /*
                 * clear all other accounts as being primary
                 * (this also adjusts their checkboxes)
                 */
                this.accountCollection.clearAllPrimary();
                // flag this account as the primary account
                this.model.set('PRIMARY_ACCOUNT', true);
            } else { // the user changed their mind about making this account primary
                event.currentTarget.checked = false;
            }
        });
    },

    /**
     * @method resetEntryForm - Reset account form to default values
     * @param {string} mode - The account mode, OTHER/IBAN. Defaults to OTHER.
     */
    resetEntryForm(mode) {
        const contactType = this.contactModel.get('CONTACTTYPE');
        const beneName = this.model.get('BENE_NAME');

        this.paymentDetailsService.clearHash();
        const freeFormEntitled = this.model.get('FREE_FORM_ENTITLED');
        this.model.clear({
            silent: true,
        });

        const values = {
            BENEBANKIDENTRYMETHOD: 'LOOKUP',
            BENE_BANK_NAME: '',
            CONTACTTYPE: contactType,
            BENE_NAME: beneName,
            BENE_NAME_CHILD: beneName,
            FREE_FORM_ENTITLED: freeFormEntitled,
            PAYMENTTYPE_COLLECTION: new Collection(),
        };

        if (mode) {
            values.MODE = mode;
        } else {
            values.MODE = 'OTHER';
        }

        // set default values
        this.model.set(
            values,
            {
                silent: true,
            },
        );
    },

    handleCancelClick() {
        this.model.destroy();

        // account for the potential removal of payment types with account removals
        this.accountCollection.trigger('specialPaymentTypeChanged');

        /*
         * Hide the deleted section so the user sees it disappear in real time instead of just
         * seeing the result when viewing after submit.
         */
        this.$(`#panel-heading-${this.cid}`).hide();
    },

    handleCollapse() {
        this.collapsed = !this.collapsed;
    },

    shiftAccount() {
        if (this.model.isValid()) {
            // carry this over to the child, we dont want it cleared on form reset
            const configurationObject = {
                FREEFORM_TYPES_MAP: this.model.get('FREEFORM_TYPES_MAP'),
                FREE_FORM_ENTITLED: this.model.get('FREE_FORM_ENTITLED'),
                INTERMEDIARY_FREE_FORM_ENTITLED: this.model.get('INTERMEDIARY_FREE_FORM_ENTITLED'),
                INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED: this.model.get('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED'),
                isEmployee: this.model.get('isEmployee'),
            };
            const { validators } = this.model;
            this.accountCollection.unshift(this.model.clone());
            this.secondaryAccountForm.resetForm();
            this.resetEntryForm();
            this.model.set(configurationObject, { silent: true });
            // set a flag so we dont call out to server for shifting, on every account
            this.accountCollection.each((model) => {
                model.set('INSIDE_ACCOUNT_SHIFT', true);
            });
            // only update the model just entered, with their validators
            this.accountCollection.models[0].validators = validators;
        } else {
            scroll.scrollToFirstError();
        }
    },

    /**
     * @method handleAccountAddAnother
     * - prior to adding another account, update the model validators based on
     * the available data in the model
     */
    handleAccountAddAnother() {
        /*
         * If Account Type is IBAN we need to make sure it is set in accountModel so
         * the validators are set correctly.
         */
        if (this.model.get('BENE_ACCOUNTTYPE') && this.model.get('BENE_ACCOUNTTYPE') === 'IBAN') {
            this.accountModel.set('BENE_ACCOUNTTYPE', this.model.get('BENE_ACCOUNTTYPE'));
        }

        this.accountModel.updateValidators(this.model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM', this.model.get('ACCT_NUM_MANDATORY'), this.model.get('CORRESPONDENT_ID_MANDATORY'), this.model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM', this.model.get('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED') === 'true', 'BENE_BANK_CODE_SORTCODETYPE');
        this.model.validators = this.accountModel.validators;

        if (this.model.isValid()) {
            const existingMode = this.model.get('MODE');

            this.shiftAccount();

            this.setMode(existingMode);

            this.render();
        }
    },

    removeValidator(key) {
        if (this.model.validators !== undefined) {
            delete this.model.validators[key];
        }
    },

    setMode(mode) {
        this.model.set('MODE', mode);
        this.setBeneAccountTypeByAccountMode();
    },

    showDetails() {
        if (this.detailsShown) {
            return false;
        }

        const bankId = this.model.get('BENE_BANK_ID');
        const bankName = this.model.get('BENE_BANK_NAME');
        const bankIdSecondary = this.model.get('BENE_BANK_ID_SECONDARY');
        const beneBankCountry = this.model.get('BENE_BANK_COUNTRY');
        const isDuplicateBankCode = this.model.get('DUPLICATEBANKCODE');
        const bankAccountTxt = (isDuplicateBankCode && isDuplicateBankCode === 'true') ? `${bankId} - ${bankName} - ${beneBankCountry}` : `${bankId} - ${bankName}`;
        const accountMode = this.model.get('MODE');
        const isFreeForm = this.model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM';
        const beneAcc = this.model.get('BENE_ACCOUNTNUMBER');
        const insideAccountShift = this.model.get('INSIDE_ACCOUNT_SHIFT');

        this.ui.$savingsChecking.comboBox(
            'data',
            {
                value: this.model.get('BENE_ACCOUNTTYPE'),
                text: this.model.get('BENE_ACCOUNTTYPE'),
            },
        );

        if (this.mode === 'modify') {
            if (accountMode === 'IBAN') {
                this.model.set('BENE_BANK_CODE', beneAcc);
            } else if (accountMode === 'OTHER') {
                this.model.set('BENE_BANK_CODE', bankId);
            }
        }

        if (!insideAccountShift) {
            if (isFreeForm) {
                this.updateServiceForFreeForm();
                this.paymentDetailsService.send();
            } else {
                this.model.set('BANK_ACCOUNT_TEXT', bankAccountTxt);
                this.ui.$bankCode.trigger(
                    'change',
                    {
                        simulated: true,
                    },
                );
            }
        }
        // remove the flag.. not needed any more
        this.model.unset('INSIDE_ACCOUNT_SHIFT');

        if (bankIdSecondary) {
            this.model.once('update:paymentTypeCollection', this.forceSecondaryBankCodeChange, this);
        }
        this.detailsShown = true;

        return undefined;
    },

    forceSecondaryBankCodeChange() {
        this.isFirstModify = true;
        this.ui.$secondaryBankCode.change();
    },

    updateServiceForFreeForm() {
        this.paymentDetailsService.setAccountType(this.model.get('MODE'));
        this.paymentDetailsService.setContactType(contactUtil.getContactType(this.model));
        this.paymentDetailsService.setBeneBankIdEntryMethod(this.model.get('BENEBANKIDENTRYMETHOD'));
        this.paymentDetailsService.setBeneBankCountry(this.model.get('BENE_BANK_COUNTRY'));
        this.paymentDetailsService.setAccountCode('');
        this.paymentDetailsService.setBeneBankName(this.model.get('BENE_BANK_NAME'));
        this.paymentDetailsService.setBeneAccountNumber(this.model.get('BENE_ACCOUNTNUMBER'));
        this.paymentDetailsService.setParentTnum(this.model.get('PARENTTNUM'));

        if (this.model.get('FREEFORM_TYPES_MAP')) {
            const fftm = this.model.get('FREEFORM_TYPES_MAP');
            const keys = [];
            util.each(fftm, (value, key) => {
                if (value.AllowFreeFormBeneBank === 'true') {
                    keys.push(key);
                }
            });
            this.paymentDetailsService.setFreeFormTypeList(keys.join());
        }
    },

    changeInputModeHandler(e) {
        const selectedVal = e.target.value;
        this.resetAccountForm();
        const paymentType = [];
        const ffm = this.model.get('FREEFORM_TYPES_MAP');
        util.forEach(ffm, (value, key) => {
            if (value.AllowFreeFormBeneBank) {
                paymentType.push(key);
            }
        });
        this.model.set({
            BENEBANKIDENTRYMETHOD: selectedVal,
            PRIMARY_ACCOUNT: this.ui.$makePrimary[0].checked,
            PAYMENTTYPE: paymentType,
        });

        if (selectedVal === 'FREEFORM') {
            // the send will trigger handle payment detail success which does the render call
            this.model.set('MODE', 'OTHER');
            this.updateServiceForFreeForm();
            this.paymentDetailsService.send();
        } else {
            this.render();
        }
    },

    resetAccountForm() {
        // this does not reset the whole form.  We only reset specifics
        this.paymentDetailsService.clearHash();
        this.model.set(
            'PREVIOUS_PAYMENTTYPE_COLLECTION',
            this.model.get('PAYMENTTYPE_COLLECTION'),
            {
                silent: true,
            },
        );
        this.model.set({
            BENE_BANK_COUNTRY: '',
            BANK_ACCOUNT_TEXT: '',
            BENE_BANK_ID: '',
            BENE_BANK_IDTYPE: '',
            BENE_BANK_CODE: '',
            BENE_ACCOUNT: '',
            BENE_BANK_ACCOUNT_NUMBER: '',
            BENE_BANK_NAME: '',
            BENE_BANK_ADDRESS_1: '',
            BENE_BANK_ADDRESS_2: '',
            BENE_ACCOUNT_CURRENCY: '',
            PAYMENTTYPE: [],
            PAYMENTTYPE_COLLECTION: new Collection(),
        }, {
            silent: true,
        });
    },

    changeAccountModeHandler(e) {
        const selectedVal = e.target.value;
        this.resetEntryForm(selectedVal);
        this.model.set({
            PRIMARY_ACCOUNT: this.ui.$makePrimary[0].checked,
        });
        this.setBeneAccountTypeByAccountMode();
        this.render();
    },

    setBeneAccountTypeByAccountMode() {
        if (this.model.get('MODE') === 'IBAN') {
            this.model.set('BENE_ACCOUNTTYPE', 'IBAN');
        }
    },

    getPaymentTypeList(paymentTypeResponseParam) {
        const paymentTypeResponse = paymentTypeResponseParam;
        let paymentTypeList = [];
        /*
         * populate payment type collection
         * Update paymentType list based on SMB vs Corp, SMB should group the payment
         * types to a smaller list
         */
        if (this.isSMB) {
            this.dataConverterService.smbSetPaymentTypeLabels(paymentTypeResponse);
            this.dataConverterService.smbConvertFromServerPaymentTypes(paymentTypeResponse);
            paymentTypeResponse.payTypeCodes = this.dataConverterService
                .smbConvertFromServerPaymentTypeCodes(paymentTypeResponse.payTypeCodes);
        }
        paymentTypeList = util.map(paymentTypeResponse.paymentTypes, this.mapPaymentTypes);
        return paymentTypeList;
    },

    handleSecondaryBankSuccess(response) {
        if (response.errorCode) {
            this.ui.$secondaryBankCode.parent('div').addClass('has-error');
            return false;
        }
        this.ui.$secondaryBankCode.parent('div').removeClass('has-error');

        this.model.set('BENE_BANK_ID_SECONDARY', response.bankSortCode);
        this.model.set('BENE_BANK_NAME_SECONDARY', response.bankName);

        this.recordCurrentTnumAndRecValues(response);

        const payments = this.getPaymentTypeList(response);

        // add payments to existing collection
        const paymentTypeCollection = this.model.get('PAYMENTTYPE_COLLECTION');
        paymentTypeCollection.add(
            payments,
            {
                merge: false,
            },
        );

        this.model.set('PAYMENTTYPE_COLLECTION', paymentTypeCollection);

        this.isFirstModify = false;

        this.render();

        return undefined;
    },

    changeSavingsCheckingHandler(e) {
        const selectedVal = e.target.value;

        if (selectedVal !== 'DD' && selectedVal !== 'SV' && selectedVal !== '') {
            // more conditions needed to fully meet this logic
            this.model.set('BENE_ACCOUNTTYPE', 'OTHER');
        }
    },

    /**
     * - @param {string} contactTypeChanged
     * - Need to refresh payment types list in account form, so we are listening
     * to this contactTypeChanged event in this file accountForm,
     * -  and for that event to be fired we are triggering it in the contactform
     */
    contactTypeChangeHandler() {
        /*
         * contactModel is listening to changes on the CONTACTTYPE but model is queried for that
         * when we make service requests. On creation CONTACTTYPE will remain undefined if we
         * do not set it here.
         */
        this.model.set('CONTACTTYPE', this.contactModel.get('CONTACTTYPE'));
        this.paymentDetailsService.setContactType(contactUtil.getContactType(this.model));
        this.paymentDetailsService.send();
    },

    setIBANNotDerived(response) {
        this.model.set('ISIBANNOTDERIVED', true);
        this.handlePaymentDetailsSuccess(response);
    },

    handleIBANSuccessResponse(response) {
        if (response && response.errorCode === '103') {
            this.model.set('ISIBANNOTDERIVED', true);
        } else {
            this.model.set('ISIBANNOTDERIVED', false);
        }
        this.ui.$container.find('input[id="BENE_ACCOUNTNUMBER"]').parent('div').removeClass('has-error');

        this.handlePaymentDetailsSuccess(response);

        // Display an error message that the entered IBAN is invalid
        if (response && response.errorCode === '102') {
            this.ui.$container.find('input[id="BENE_ACCOUNTNUMBER"]').parent('div').addClass('has-error');
            this.ui.$container.find('span[data-validate="BENE_ACCOUNTNUMBER"]').text(locale.get(response.message));
        }
    },

    handlePaymentDetailsSuccess(response) {
        // Populate currency collection - make sure this is in correct order sent back from server
        const currencies = response.validCurrencies || [];
        const currencyList = currencies.map(currency => ({
            name: currency,
            label: response.validCurrencyDescMap[currency],
        }));

        this.currencyCollection.reset(currencyList);

        /*
         * Set default currency only if its in the response.
         * Default currency will only be set if we change a bank code.
         * If we change the currency drop down itself, it wont be setting default
         * currency in the response.
         */
        if (response.defaultCurrency) {
            this.model.set(
                'BENE_ACCOUNT_CURRENCY',
                response.defaultCurrency,
                {
                    silent: true,
                },
            );
        }

        if (response.beneCountryCode) {
            this.model.set(
                'BENE_BANK_COUNTRY',
                response.beneCountryCode,
                {
                    silent: true,
                },
            );
        }

        this.model.set({
            ACH_ONLY: response.achOnlyFlag,
            WIRE_ONLY: response.rtgsOnlyFlag,
            SECONDARY_CODE: response.secondCodeFieldFlag,
            BENE_BANK_IDTYPE: response.sortCodeType,
        });

        this.contactModel.set('allowBeneEmails', response.allowBeneEmails);

        /*
         * the 'only' in the response flag name is confusing b/c both
         * achOnlyFlag & rtgsOnlyFlag can be true
         * achOnlyFlag = true, mean bene sort code can have ach types;
         * rtgsOnlyFlag = true means bene sort code can have rtgs types
         */
        this.achOnly = response.achOnlyFlag;
        this.rtgsOnly = response.rtgsOnlyFlag;
        // test for both true or both false (this case comes up seldom but it does)
        if ((this.achOnly && this.rtgsOnly) || (!this.achOnly && !this.rtgsOnly)) {
            if (this.model.get('BENE_BANK_IDTYPE') === 'RTP') {
                this.secondaryCodeLookup.setFieldName('wire');
                this.model.set('FIELDNAMELOOKUP', 'wire');
            } else {
                this.secondaryCodeLookup.setFieldName('both');
            }
        } else if (this.achOnly) {
            this.secondaryCodeLookup.setFieldName('ach');
        } else if (this.rtgsOnly) {
            this.secondaryCodeLookup.setFieldName('wire');
        }

        // Set bank information
        if (this.model.get('BENEBANKIDENTRYMETHOD') !== 'FREEFORM') {
            if (response.bankSortCode) {
                this.model.set(
                    'BENE_BANK_ID',
                    response.bankSortCode,
                );
            }
        } else {
            const parentTnum = this.model.get('PARENTNUM');
            if (parentTnum) {
                this.paymentDetailsService.setParentTnum(parentTnum);
            }
        }

        if (response.bankName) {
            this.model.set(
                'BENE_BANK_NAME',
                response.bankName,
                {
                    silent: true,
                },
            );
        }

        /*
         * If the user has selected a bank from the lookup dropdown, populate the model with the
         * information from the response and set the value to an empty string where information
         * on the response is not provided
         */
        if (this.model.get('BENEBANKIDENTRYMETHOD') === 'LOOKUP') {
            this.model.set('BENE_BANK_ADDRESS_1', response.bankAddress1 || '');
            this.model.set('BENE_BANK_ADDRESS_2', response.bankAddress2 || '');
            this.model.set('BENE_BANK_CITY', response.bankCity || '');
            this.model.set('BENE_BANK_STATE', response.bankState || '');
            this.model.set('BENE_BANK_COUNTRY', response.beneCountryCode || '');
        } else {
            /*
             * If the user has entered bene bank information using the freeform inputs,
             * populate the model with the information from the response but leave the
             * existing model values where information on the response is not provided.
             */
            this.model.set('BENE_BANK_ADDRESS_1', response.bankAddress1 || this.model.get('BENE_BANK_ADDRESS_1'));
            this.model.set('BENE_BANK_ADDRESS_2', response.bankAddress2 || this.model.get('BENE_BANK_ADDRESS_2'));
            this.model.set('BENE_BANK_CITY', response.bankCity || this.model.get('BENE_BANK_CITY'));
            this.model.set('BENE_BANK_STATE', response.bankState || this.model.get('BENE_BANK_STATE'));
            this.model.set('BENE_BANK_COUNTRY', response.beneCountryCode || this.model.get('BENE_BANK_COUNTRY'));
        }

        // if this map comes back at all, then freeforms are allowed
        let ffm = this.model.get('FREEFORM_TYPES_MAP');
        // grab response object if available, else default to model
        if (response.freeFormTypesMap
            && (response.freeFormTypesMap.FEDWIRE || response.freeFormTypesMap.INTL)) {
            ffm = response.freeFormTypesMap;
        }
        if (ffm && ((ffm.FEDWIRE && ffm.FEDWIRE.AllowFreeFormBeneBank === 'true')
            || (ffm.INTL && ffm.INTL.AllowFreeFormBeneBank === 'true'))) {
            this.model.set('FREE_FORM_ENTITLED', true);
            this.model.set('FREEFORM_TYPES_MAP', ffm);
        }

        if (ffm && ((ffm.FEDWIRE && ffm.FEDWIRE.CorrespondentIDRequired === 'true')
            || (ffm.INTL && ffm.INTL.CorrespondentIDRequired === 'true'))) {
            this.model.set('CORRESPONDENT_ID_MANDATORY', true);
        }

        if (response.secondIntermediaryfreeFormTypesMap
            && response.secondIntermediaryfreeFormTypesMap.INTL) {
            if (response.secondIntermediaryfreeFormTypesMap.INTL.AllowFreeFormSecondIntBank === 'true') {
                this.model.set('INTERMEDIARY_FREE_FORM_ENTITLED', 'true');
            }
            if (response.secondIntermediaryfreeFormTypesMap.INTL.SecondInterBankAccountNumReq === 'true') {
                this.model.set('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED', 'true');
            }
        }

        if (response.secondIntermediaryfreeFormTypesMap
            && response.secondIntermediaryfreeFormTypesMap.FEDWIRE) {
            if (response.secondIntermediaryfreeFormTypesMap.FEDWIRE.AllowFreeFormSecondIntBank === 'true') {
                this.model.set('INTERMEDIARY_FREE_FORM_ENTITLED', 'true');
            }
            if (response.secondIntermediaryfreeFormTypesMap.FEDWIRE.SecondInterBankAccountNumReq === 'true') {
                this.model.set('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED', 'true');
            }
        }

        if (!this.intermediaryBankView) {
            this.intermediaryBankView = new IntermediaryBankView({
                model: this.model,
                fieldTypeData: this.fieldTypeData,
            });
        }

        this.recordCurrentTnumAndRecValues(response);

        const paymentTypeList = this.getPaymentTypeList(response);

        const paymentTypeCollection = new Collection();
        paymentTypeCollection.reset(paymentTypeList);

        this.model.set('PAYMENTTYPE_COLLECTION', paymentTypeCollection);

        if (this.isSMB) {
            this.model.set('PAYMENTTYPE', this.dataConverterService.smbConvertFromServerPaymentTypeCodes(this.model.get('PAYMENTTYPE')));
        }
        this.model.trigger('update:paymentTypeCollection');

        /*
         * if only a single payment type is returned, then set the checkbox and trigger
         * any required actions for when type is selected.
         */
        if (paymentTypeCollection
            && paymentTypeCollection.size() === 1
            && this.ui.$benePaymentType.length
            && this.ui.$benePaymentType[0].checked !== true) {
            this.ui.$benePaymentType.prop('checked', true);
            this.ui.$benePaymentType.trigger('change');
        }

        this.setMandatoryRequirementsForPaymentTypes();
    },

    /*
     * When we expand the composite SMB types such as PCP to their real types
     * we will need to restore the RECNUMS associated with the currently selected bank
     * in order to avoid losing one of them. We also need to capture the child tnums for
     * currently selected payment types so they can be restored should the user select
     * a different bank that supports those types.  This prevents the creation of
     * spurious payment type deletion conditions that may incorrectly render the
     * the beneficiary invalid for existing payments. Corp doesn't need and
     * won't use the RECNUM part of this.
     * @param {Object} response - response from server with all available payment types
     */
    recordCurrentTnumAndRecValues(response) {
        const currentBankRecNums = this.model.get('CURRENTBANKRECNUMS') || new Model();
        const originalTnums = this.model.get('ORIGINALTNUMS') || new Model();

        response.paymentTypes.forEach((paymentType) => {
            const typeCode = paymentType.id;
            if (!currentBankRecNums.get(typeCode)) {
                currentBankRecNums.set(typeCode, paymentType.recnum);
            }
            if (!originalTnums.get(typeCode)) {
                originalTnums.set(typeCode, paymentType.tnum);
            }
        });

        this.model.set({
            CURRENTBANKRECNUMS: currentBankRecNums,
            ORIGINALTNUMS: originalTnums,
        });
    },

    mapPaymentTypes(obj) {
        return {
            id: obj.id,
            name: obj.id,
            label: obj.label,
            value: obj.id,
            clearingSystem: obj.clearingSystem,
            product: obj.product,
            payTypeLevelSortCodeType: obj.payTypeLevelSortCodeType || '',
            payTypeLevelBankStateCode: obj.payTypeLevelBankStateCode || '',
            payTypeLevelBankCountryCode: obj.payTypeLevelBankCountryCode || '',
            payTypeLevelBankAddress1: obj.payTypeLevelBankAddress1 || '',
            payTypeLevelBankAddress2: obj.payTypeLevelBankAddress2 || '',
            payTypeLevelBankAddress3: obj.payTypeLevelBankAddress3 || '',
            accountType: obj.accountType,
            tnum: obj.tnum,
            recnum: obj.recnum,
            bankCode: obj.bankCode,
        };
    },

    /**
     * @name showMaskedAccount
     * @description display the maskedInput component for the account number
     * @parameter {boolean} isIban flag indicating type of account
     */
    showMaskedAccount(isIban) {
        const fieldTypeData = this.accountCollection && this.accountCollection.fieldTypeData;
        if (fieldTypeData) {
            const fieldType = fieldTypeData.BENE_ACCOUNTNUMBER;
            const fieldLabel = (isIban) ? 'bab.iban' : 'bab.account.number';
            const MaskedInputView = createMaskedInputView({
                initialValue: this.model.get('BENE_ACCOUNTNUMBER') || '',
                name: 'BENE_ACCOUNTNUMBER',
                maxLength: fieldType.maxLen,
                fieldLabel: locale.get(fieldLabel),
                inputClassList: 'form-control',
                dataBind: true,
            });
            if (this.maskedInputRegion) {
                this.maskedInputRegion.show(new MaskedInputView());
            }
        }
    },

    onRender() {
        const self = this;

        infoTooltipHelper.setupInfoTooltips(this);
        const isIban = this.model.get('MODE') === 'IBAN';
        const isFreeForm = this.model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM';
        const isIBANNotDerived = this.model.get('ISIBANNOTDERIVED');
        let rowsPerPage = 1;

        if ((!isIban && !isFreeForm) || (isIban && isIBANNotDerived)) {
            const lookup = this.accountCodeLookup;
            this.ui.bankCodeCombo = this.ui.$bankCode.comboBox({
                dropdownAutoWidth: true,
                triggerChange: true,

                query: util.debounce((query) => {
                    lookup.setStartRow((((query.page - 1) * rowsPerPage) + 1));
                    rowsPerPage = (query.term.length < CONSTANT.COMBO_MIN_CHARS)
                        ? CONSTANT.COMBO_MIN_SIZE : CONSTANT.MAX_SERVER_ROWSPERPAGE;
                    lookup.setPageSize(rowsPerPage);
                    lookup.setSearch(query.term);

                    lookup.setSearch(query.term);
                    lookup.send().then((result) => {
                        query.callback({
                            results: result.data,
                            more: ((query.page * rowsPerPage) < result.totalRows),
                        });
                    });
                }, CONSTANT.COMBO_DEBOUNCE),

                initSelection(element, cb) {
                    const val = element.val();
                    const text = self.model.get('BANK_ACCOUNT_TEXT');
                    if (text) {
                        cb({
                            id: val,
                            text,
                        });
                    } else {
                        cb();
                    }
                },
            });
            this.ui.$secondaryBankCode.comboBox({
                dropdownAutoWidth: true,
                triggerChange: true,

                query: util.debounce((query) => {
                    if (self.model.get('FIELDNAMELOOKUP') === 'wire'
                            && self.secondaryCodeLookup.request.fieldName !== self.secondaryCodeLookup.getFieldName('wire')) {
                        self.secondaryCodeLookup.setFieldName('wire');
                    }
                    self.secondaryCodeLookup
                        .setStartRow((((query.page - 1) * rowsPerPage) + 1));
                    rowsPerPage = (query.term.length < CONSTANT.COMBO_MIN_CHARS)
                        ? CONSTANT.COMBO_MIN_SIZE : CONSTANT.MAX_SERVER_ROWSPERPAGE;
                    self.secondaryCodeLookup.setPageSize(rowsPerPage);
                    self.secondaryCodeLookup.setSearch(query.term);
                    self.secondaryCodeLookup.send().then((result) => {
                        query.callback({
                            results: result.data,
                            more: ((query.page * rowsPerPage) < result.totalRows),
                        });
                    });
                }, CONSTANT.COMBO_DEBOUNCE),

                initSelection(element, cb) {
                    const val = element.val();
                    const text = self.model.get('BANK_ACCOUNT_TEXT_SECONDARY');
                    if (text) {
                        cb({
                            id: val,
                            text,
                        });
                    } else {
                        cb();
                    }
                },
            });
        }

        // create the masked input widget for account number
        this.showMaskedAccount(isIban);

        if (this.model.has('BENE_BANK_ID') || this.model.has('BENE_ACCOUNTNUMBER')) {
            this.showDetails();
        }

        this.secondaryAccountFormRegion.show(this.secondaryAccountForm);

        /**
         * Hide Intermediary Bank Information
         * When enabled, the Intermediary Bank Information section does NOT display when adding
         * or modifying contacts on the Contact Center widget. 1 = Enabled, 0 = Disabled.
         */
        if (applicationConfigParams.get('CCTR_HIDEINTERMEDIARYBANKINFORMATION') !== '1') {
            this.intermediaryRegion.show(this.intermediaryBankView);
        }

        if (this.isEntryRow && this.accountCollection.length) {
            /*
             * because accountForms in the modify screen get re-rendered so many times, it's
             * challenging to pin down their first render cycle with all relevant
             * information. A model variable is set here to be listened for when the model
             * gets all relevant information
             */
            this.accountCollection.models.forEach((model) => {
                model.set('collectionItemFirstRender', true);
            });
            this.accountRegion.show(this.accountCollectionView);
        }

        let achSelected = false;
        let modelPaymentTypes = this.model.get('PAYMENTTYPE');
        const paymentTypeCollection = this.model.get('PAYMENTTYPE_COLLECTION');

        modelPaymentTypes = util.isArray(modelPaymentTypes)
            ? modelPaymentTypes : [modelPaymentTypes];

        /*
         * HACK: NH-159992 - see comments on ticket for further details
         * this form is not properly structured to render when dependencies are resolved. A
         * real fix for this issue would be to ensure it does not render until all needed data
         * is present. But for now, checking for paymentTypeCollection here ensures no error is
         * thrown, and render is called again after PAYMENTTYPE_COLLECTION is populated on the
         * model in handlePaymentDetailsSuccess so this functionality is still hit
         */
        if (paymentTypeCollection) {
            achSelected = modelPaymentTypes.some((paymentType) => {
                const payType = paymentTypeCollection.findWhere({
                    value: paymentType,
                });
                return (payType && payType.get('product') === 'USACH');
            });

            this.ui.$accountType.parent().toggleClass('required', achSelected);
            this.model.set('ACH_SELECTED', achSelected, { silent: true });
        }
    },

    handleBackwards() {
        this.trigger('back', this);
    },

    ibanBankCodeHandler(e) {
        const bankCode = e.target.value;

        if (this.isFirstModify) {
            /*
             * TODO: if bankCode = '', this means the user did not interact
             *  w/ the app to change the value. This means it could be the first time
             *  coming into the screen from Modify mode
             * TODO: test adding a new account, then opening it in the account grid,
             *  does the bankCode = ''?
             */

            const parentTnum = this.model.get('PARENTTNUM');
            const beneAccountNumber = this.model.get('BENE_ACCOUNTNUMBER');

            if (parentTnum) {
                this.paymentDetailsService.setParentTnum(parentTnum);
            }
            if (beneAccountNumber) {
                this.paymentDetailsService.setBeneAccountNumber(beneAccountNumber);
            }
        }

        if (e.simulated === true) {
            this.paymentDetailsService.setBeneAccountCurrency(this.model.get('BENE_ACCOUNT_CURRENCY'));
            this.paymentDetailsService.setChangeSimulation('TRUE');
        }

        // Set Account Number val = IBAN number
        if (bankCode) {
            this.model.set('BENE_ACCOUNTNUMBER', bankCode);
        } else {
            // For cases where bankCode comes undefined
        }
        this.model.set('BENE_BANK_IDTYPE', 'SWIFT');

        this.paymentDetailsService.setAccountType(this.model.get('MODE')); // IBAN
        this.paymentDetailsService.setAccountCode(this.model.get('BENE_ACCOUNTNUMBER'));
        this.paymentDetailsService.setBeneBankIDType(this.model.get('BENE_BANK_IDTYPE'));
        this.paymentDetailsService.setContactType(contactUtil.getContactType(this.model));
        this.paymentDetailsService.sendIBANRequest();
    },

    otherBankCodeHandler(e) {
        const bankCodesortcodetype = e.target.value;
        let bankCode = bankCodesortcodetype.substring(0, bankCodesortcodetype.indexOf(' - '));
        let sortCodeType = bankCodesortcodetype.substring(bankCodesortcodetype.indexOf(' - ') + 4);

        if (this.isFirstModify) {
            /*
             * TODO: if bankCode = '', this means the user did not interact
             *  w/ the app to change the value.
             * This means it could be the first time coming into the screen from Modify mode
             * TODO: test adding a new account, then opening it in the account grid,
             *  does the bankCode = ''?
             */

            const parentTnum = this.model.get('PARENTTNUM');
            const beneAccountNumber = this.model.get('BENE_ACCOUNTNUMBER');

            if (parentTnum) {
                this.paymentDetailsService.setParentTnum(parentTnum);
            }
            if (beneAccountNumber) {
                this.paymentDetailsService.setBeneAccountNumber(beneAccountNumber);
            }
        }

        if (!bankCode) {
            bankCode = this.model.get('BENE_BANK_ID');
        } else {
            this.model.set('BANK_ACCOUNT_TEXT', this.ui.$bankCode.comboBox('data').text);
        }

        if (!sortCodeType) {
            sortCodeType = this.model.get('BENE_BANK_IDTYPE');
        }

        if (e.simulated) {
            this.paymentDetailsService.setBeneAccountCurrency(this.model.get('BENE_ACCOUNT_CURRENCY'));
            this.paymentDetailsService.setChangeSimulation('TRUE');
        }

        /*
         * TODO: Replace paymentDetailsService below to use the lookup service call
         *  for accounttype Other (service call is still being developed)
         * Temporarily use the same service call for accounttype OTHER as IBAN - just
         * so we can get past the ui validation
         * this.model.set('BENE_ACCOUNTNUMBER', bankCode);
         */

        this.model.set('BENE_BANK_IDTYPE', sortCodeType);
        this.paymentDetailsService.setBeneBankIDType(sortCodeType);
        this.paymentDetailsService.setAccountType(this.model.get('MODE'), this.model.get('ISIBANNOTDERIVED'));
        this.paymentDetailsService.setAccountCode(bankCode);
        this.paymentDetailsService.setProduct(null);
        this.paymentDetailsService.setContactType(contactUtil.getContactType(this.model));
        if (this.model.get('ISIBANNOTDERIVED')) {
            this.paymentDetailsService.setIBANNotDerived('1');
        }
        this.paymentDetailsService.send();
    },

    beneAccountCurrencyHandler(e) {
        let beneAccountCurrency = e.target.value;
        const contactType = contactUtil.getContactType(this.model);
        const bankCode = this.model.get('BENE_BANK_ID');

        if (!beneAccountCurrency) {
            beneAccountCurrency = this.model.get('BENE_ACCOUNT_CURRENCY');
        } else {
            this.model.set('BANK_ACCOUNT_TEXT', this.ui.$bankCode.comboBox('data').text);
        }

        const bankProduct = this.model.get('PRODUCT');

        if ((bankProduct) && !((bankProduct === 'USACH') || (bankProduct === 'RTGS'))) {
            // Dont put product in service
        } else {
            this.currencyService.setProduct(bankProduct);
        }

        /*
         * TODO: Replace paymentDetailsService below to use the lookup service call
         *  for accounttype Other (service call is still being developed)
         * Temporarily use the same service call for accounttype OTHER as IBAN - just
         * so we can get past the ui validation
         */

        this.currencyService.setAccountType(this.model.get('MODE'));
        this.currencyService.setAccountCode(bankCode);
        this.currencyService.setBeneBankIDType(this.model.get('BENE_BANK_IDTYPE'));
        this.currencyService.setBeneAccountCurrency(beneAccountCurrency);
        this.currencyService.setContactType(contactType);
        this.currencyService.setParentTnum(this.model.get('PARENTTNUM'));
        this.currencyService.setBeneAccountNumber(this.model.get('BENE_ACCOUNTNUMBER'));
        if ((bankCode) && (contactType)) {
            this.currencyService.send();
        }
    },

    focusOutBankCodeHandler(e, extra) {
        this.trigger('account:change', this);
        e.simulated = !!(extra && extra.simulated === true);
        this.detailsShown = true;
        if (this.model.get('MODE') !== 'IBAN' || (this.model.get('MODE') === 'IBAN' && this.model.get('ISIBANNOTDERIVED'))) {
            this.otherBankCodeHandler(e);
        }
    },

    handleBankCodeChange(e, extra) {
        this.trigger('account:change', this);
        e.simulated = !!(extra && extra.simulated === true);
        this.detailsShown = true;
        if (this.model.get('MODE') === 'IBAN' && !this.model.get('ISIBANNOTDERIVED')) {
            this.ibanBankCodeHandler(e);
        } else {
            this.otherBankCodeHandler(e);
        }
    },

    handleBeneAccountCurrChange(e) {
        this.trigger('account:change', this);
        this.beneAccountCurrencyHandler(e);
    },

    handleBeneBankNameChange(e) {
        const val = e.target.value;
        this.model.set('BENE_BANK_NAME', val);
    },

    handleBeneBankAddress1Change(e) {
        const val = e.target.value;
        this.model.set('BENE_BANK_ADDRESS_1', val);
    },

    handleBeneBankAddress2Change(e) {
        const val = e.target.value;
        this.model.set('BENE_BANK_ADDRESS_2', val);
    },

    handleBeneBankCountryChange(e) {
        this.model.set(
            'BENE_BANK_COUNTRY',
            e.target.value,
            {
                silent: true,
            },
        );
        if (this.model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM') {
            this.updateServiceForFreeForm();
            this.paymentDetailsService.send();
        }
    },

    handleBeneBankAccountNumberChange(e, extra) {
        const val = e.target.value;
        this.model.set('BENE_ACCOUNTNUMBER', val);
        if (this.model.get('MODE') === 'IBAN') {
            this.trigger('account:change', this);
            e.simulated = !!(extra && extra.simulated === true);
            this.detailsShown = true;
            this.ibanBankCodeHandler(e);
        }
    },

    /**
     * When a user changes the payment types associated with an account
     */
    changePaymentTypeHandler(e) {
        // dont want to trigger calls back to server, just for mandatory flag update
        this.detailsShown = true;

        // (Depending on if we only want this freeform, maybe include below)
        /*
         * if the changed payment was of type CRTRAN or INTL then the addContact page needs
         * to be updated to apply specific rules to certain contact form fields
         */
        if (e.target.value === 'CRTRAN' || e.target.value === 'REQOUT' || e.target.value === 'INTL') {
            this.accountCollection.trigger('specialPaymentTypeChanged');
        }

        // only do this if freeform
        if (this.model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM') {
            let arrayOfSelectedPaymentTypes = [];
            // if there are payment types available to select
            if (this.ui.$benePaymentType.length > 0) {
                // get a list of all selected paymentTypes
                arrayOfSelectedPaymentTypes = this.ui.$benePaymentType
                    .filter(type => (type.checked ? type.value : false));
            }
            if (arrayOfSelectedPaymentTypes.length) {
                this.model.set('PAYMENTTYPE', arrayOfSelectedPaymentTypes);
            }
            this.setMandatoryRequirementsForPaymentTypes();
        } else {
            this.model.trigger('update:paymentTypeCollection');
        }
    },

    /**
     * When an accounts payment types have changed, those payment types will need to be
     * checked to see if they require certain attributes to now be mandatory, and the form
     * re-rendered to reflect this
     */
    setMandatoryRequirementsForPaymentTypes() {
        let correspondentIDRequired = false;
        let beneBankAccountNumRequired = false;

        const freeFormTypesMap = this.model.get('FREEFORM_TYPES_MAP');
        const currentPaymentTypes = util.isArray(this.model.get('PAYMENTTYPE')) ? this.model.get('PAYMENTTYPE') : [this.model.get('PAYMENTTYPE')];

        /**
         * Hide Intermediary Bank Information
         * When enabled, the Intermediary Bank Information section does NOT display when adding
         * or modifying contacts on the Contact Center widget. 1 = Enabled, 0 = Disabled.
         */
        if (applicationConfigParams.get('CCTR_HIDEINTERMEDIARYBANKINFORMATION') !== '1' && !this.isEntryRow) {
            if (this.model.get('collectionItemFirstRender')) {
                this.intermediaryBankView.firstRender = true;
            } else {
                this.intermediaryBankView.firstRender = false;
            }
            this.model.set('collectionItemFirstRender', false);
        }

        this.firstRender = false;

        if (!currentPaymentTypes) {
            this.render();
            this.isFirstModify = false;
            return;
        }

        currentPaymentTypes.forEach((paymentType) => {
            if (freeFormTypesMap && freeFormTypesMap[paymentType]) {
                if (!correspondentIDRequired
                    && freeFormTypesMap[paymentType].CorrespondentIDRequired) {
                    correspondentIDRequired = true;
                }
                if (!beneBankAccountNumRequired
                    && freeFormTypesMap[paymentType].BeneBankAccountNumRequired) {
                    beneBankAccountNumRequired = true;
                }
            }
        });
        this.model.set('CORRESPONDENT_ID_MANDATORY', correspondentIDRequired);
        this.model.set('ACCT_NUM_MANDATORY', beneBankAccountNumRequired);
        this.render();
        this.isFirstModify = false;
    },

    /*
     * FIXME: Was this removed from master??
     * employeeCheckboxToggled(e) {
     *     this.secondaryAccountForm.employeeCheckboxToggled(e.target.checked);
     * },
     */

    handleSecondaryBankCodeChange(e) {
        const bankCodesortcodetype = e.target.value || '';
        let bankCode = bankCodesortcodetype.substring(0, bankCodesortcodetype.indexOf(' - '));
        let sortCodeType = bankCodesortcodetype.substring(bankCodesortcodetype.indexOf(' - ') + 4);

        if (this.isFirstModify) {
            const parentTnum = this.model.get('PARENTTNUM');
            const beneAccountNumber = this.model.get('BENE_ACCOUNTNUMBER');

            if (parentTnum) {
                this.secondaryBankService.setParentTnum(parentTnum);
            }
            if (beneAccountNumber) {
                this.secondaryBankService.setBeneAccountNumber(beneAccountNumber);
            }
        }

        if (!bankCode) {
            bankCode = this.model.get('BENE_BANK_ID_SECONDARY');
        } else {
            this.model.set('BANK_ACCOUNT_TEXT_SECONDARY', this.ui.$secondaryBankCode.comboBox('data').text);
        }

        if (!sortCodeType) {
            sortCodeType = this.model.get('BENE_BANK_IDTYPE_SECONDARY');
        }

        if (e.simulated === true) {
            this.secondaryBankService.setChangeSimulation('TRUE');
        } else {
            this.secondaryBankService.setBeneAccountCurrency(this.model.get('BENE_ACCOUNT_CURRENCY'));
        }

        this.secondaryBankService.setAccountType(this.model.get('MODE'));
        this.secondaryBankService.setBeneBankIDType(sortCodeType);
        this.secondaryBankService.setAccountCode(bankCode);

        this.secondaryBankService.setContactType(contactUtil.getContactType(this.model));
        this.secondaryBankService.setProduct(null);
        this.secondaryBankService.send();
    },

    templateHelpers() {
        const self = this;
        return {
            countries: this.options.countries ? this.options.countries.toJSON() : [],
            currencies: this.currencyCollection ? this.currencyCollection.toJSON() : {},
            paymentTypes: this.model.has('PAYMENTTYPE_COLLECTION') ? this.model.get('PAYMENTTYPE_COLLECTION').toJSON() : [],
            isSMB: this.isSMB,

            isIBAN() {
                if (self.model) {
                    return self.model.get('MODE') === 'IBAN';
                }
                return false;
            },

            /**
             * Determines if the secondary beneficiary code should be displayed.
             * @returns {boolean}
             */
            showSecondaryCode() {
                /*
                 * Show secondary code only when flaggedfrom server
                 * and the country in intsortcode table is US.
                 */
                return self.model.get('SECONDARY_CODE') && self.model.get('BENE_BANK_COUNTRY') === 'US';
            },

            isFreeForm() {
                if (self.model) {
                    return self.model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM';
                }
                return false;
            },

            isIBANDerived() {
                if (self.model) {
                    return self.model.get('MODE') === 'IBAN' && !self.model.get('ISIBANNOTDERIVED');
                }
                return false;
            },

            // Enable Bene Bank Info section if its freeform or iban is not valid
            enableBeneBankInfo() {
                if (self.model && self.model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM') {
                    return true;
                }
                if (self.model) {
                    return self.model.get('ISIBANNOTDERIVED');
                }
                return false;
            },

            isAcctNumMandatory() {
                if (self.model) {
                    return self.model.get('ACCT_NUM_MANDATORY');
                }
                return false;
            },

            isCorrespondentIDMandatory() {
                if (self.model) {
                    return self.model.get('CORRESPONDENT_ID_MANDATORY');
                }
                return false;
            },

            hasAddress() {
                if (self.model.get('BENE_BANK_ADDRESS_1') || self.model.get('BENE_BANK_ADDRESS_2')
                    || self.model.get('BENE_BANK_CITY') || self.model.get('BENE_BANK_STATE')
                    || self.model.get('BENE_BANK_COUNTRY')) {
                    return true;
                }
                return false;
            },

            isFreeFormEntitled() {
                if (!self.model) {
                    return false;
                }

                return (self.model.get('FREE_FORM_ENTITLED') && self.model.get('FREE_FORM_ENTITLED') !== 'false');
            },

            isEntryRow: this.isEntryRow,
            cid: this.cid,
            collapsed: this.collapsed,

            getPaymentMessage() {
                const source = (self.isSMB) ? 'smb' : 'corp';
                const achMsg = {
                    smb: 'bab.selected.smb.achpayments.msg',
                    corp: 'bab.selected.achpaments.msg',
                };
                const rtgsMsg = {
                    smb: 'bab.selected.smb.rtgspayments.msg',
                    corp: 'bab.selected.rtgspayments.msg',
                };
                let msg;
                if (self.achOnly && self.rtgsOnly) {
                    msg = locale.get('bab.selected.services.msg');
                } else if (self.achOnly) {
                    msg = locale.get(achMsg[source]);
                } else if (self.rtgsOnly) {
                    msg = locale.get(rtgsMsg[source]);
                }
                return msg;
            },
            fieldTypeData: this.fieldTypeData,
            maskIt: value => maskValue(value, this.maskingProps),
        };
    },
});

AccountCollectionView = BaseAccountCollectionView.extend({
    itemView: AccountFormView,
});

export default AccountFormView;
