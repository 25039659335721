import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import PaymentUtil from 'common/util/paymentUtil';

export default function (form, initialState) {
    const { formView } = form;
    const { model } = formView;
    const { functionCode } = model.jsonData.typeInfo;

    const directPayer = form.field('DIRECTPAYER');
    const zeroDollarLiveFlag = form.field('ZERODOLLARLIVE');
    const prenoteFlag = form.field('PRENOTEFLAG');
    const holdFlag = form.field('HOLDFLAG');
    const firstName = form.field('FIRSTNAME');
    const caseId = form.field('CASEID');
    const payDate = form.field('PAYDATE');
    const amount = form.field('AMOUNT');
    const ssn = form.field('SSN');
    const medSupplyIndicator = form.field('MEDSUPPIND');
    const indIdNum = form.field('RECEIVCOMPID');
    const entryMethod = form.formView.parentModel.context.entrymethod;
    const lastName = form.field('LASTNAME');
    const showDirectPayer = (applicationConfigParams.getValue('ACH', 'ALLOWDIRECTPAYERCS') === '1');

    const toggleDirectPayer = function () {
        if (!showDirectPayer) {
            directPayer.shouldBeHidden(true);
        } else if (initialState) {
            if (model.get('DIRECTPAYER') === '1' || model.get('APPLICATIONID') === 'DP') {
                directPayer.$el.attr('checked', true);
                model.set({
                    DIRECTPAYER: '1',
                    APPLICATIONID: 'DP',
                });
            } else {
                directPayer.$el.attr('checked', false);
                model.set({
                    DIRECTPAYER: '0',
                    APPLICATIONID: 'CS',
                });
            }
        } else if (model.get('DIRECTPAYER') === '1') {
            model.set({
                APPLICATIONID: 'DP',
            });
        } else {
            model.set({
                APPLICATIONID: 'CS',
            });
        }
    };

    if (initialState && entryMethod !== '1') {
        const insertMode = formView.state.toUpperCase() === 'INSERT';

        if (insertMode) {
            medSupplyIndicator.setComboBoxValue('Y');
            // USACH Child Support Payments is always USD
            model.set({
                MEDSUPPIND: 'Y',
                ORIGCURRENCYCODE: 'USD',
                DESTCURRENCYCODE: 'USD',
            });
        }

        model.on('change:AUTHAGENCYNAME', () => {
            model.set({
                FIRSTNAME: '',
                LASTNAME: '',
                SSN: '',
                AMOUNT: '0.00',
                CASEID: '',
                PAYDATE: '',
                FIPSCODE: '',
                EMPTERMIND: '',
                MEDSUPPIND: 'Y',
                ENTRYDESC: '',
                RECEIVCOMPID: '',
                RECEIVCOMPNAME: model.get('RECEIVCOMPNAME_DEFAULT'),
                COMPDISCDATA: '',
            });
            prenoteFlag.$el.attr('checked', false);
            holdFlag.$el.attr('checked', false);
            zeroDollarLiveFlag.$el.attr('checked', false);
        });
    }

    if (initialState && entryMethod === '1') {
        firstName.shouldBeReadOnly(false);
        lastName.shouldBeReadOnly(false);
        caseId.shouldBeReadOnly(false);
        ssn.shouldBeReadOnly(false);
        indIdNum.shouldBeReadOnly(false);
    }

    toggleDirectPayer();

    if (functionCode.indexOf('TMPL') !== -1) {
        amount.shouldBeOptional(true);
        payDate.shouldBeOptional(true);
    }

    if (model.has('LASTNAME') || model.has('FIRSTNAME')) {
        if (lastName.getValue()) {
            model.set(
                'CSPARENTNAME',
                lastName.getValue(),
                {
                    silent: true,
                },
            );
            if (firstName.getValue()) {
                model.set(
                    'CSPARENTNAME',
                    `${model.get('CSPARENTNAME')},${firstName.getValue()}`,
                    {
                        silent: true,
                    },
                );
            }
        } else if (firstName.getValue()) {
            model.set(
                'CSPARENTNAME',
                firstName.getValue(),
                {
                    silent: true,
                },
            );
        }
    }
    PaymentUtil.toggleZdlPrenoteFlags(zeroDollarLiveFlag, prenoteFlag);
}
