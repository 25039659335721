import Base from '../common/base';
import template from './exportCAMTDetail.hbs';

const ExportCAMTDetails = Base.extend({
    template,
    onRender() {
        this.setupCurrentPriorDropdown();
        this.renderOutputContentDropdown();
    },
});

export default ExportCAMTDetails;
