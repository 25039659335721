import { ItemView } from '@glu/core';
import template from './flexDropdownSelectionView.hbs';

export default ItemView.extend({
  noDefaultBehaviors: true,
  noGluEvents: true,
  template,
  tagName: 'li',

  templateHelpers() {
    return {
      showWithCancelIcon: this.options.clearBtn && this.options.showCancelIcon
    };
  }
});
