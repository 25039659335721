var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option disabled hidden value data-hook=\"getSelectPlaceholder\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rdc.Select.Account.for.Deposit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":75},"end":{"line":9,"column":118}}}))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNTNUM") || (depth0 != null ? lookupProperty(depth0,"ACCOUNTNUM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTNUM","hash":{},"data":data,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":41}}}) : helper)))
    + "\"\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasOneAccount")),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":62}}})) != null ? stack1 : "")
    + ">\n                "
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"getAccountDisplay") : depths[1]), depth0))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"CLIENTACCOUNTNAME") || (depth0 != null ? lookupProperty(depth0,"CLIENTACCOUNTNAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CLIENTACCOUNTNAME","hash":{},"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":64}}}) : helper)))
    + "\n            </option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group\">\n    <label for=\"ACCOUNTNUM\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"rdc.Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":52}}}))
    + "</label>\n    <select required name=\"ACCOUNTNUM\"\n            data-bind=\"model\"\n            class=\"form-control\"\n            data-hook=\"getAccountNum\"\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"accountDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":50}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOneAccount") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "    </select>\n    <span class=\"help-block\"\n          data-bind=\"model\"\n          data-validate=\"ACCOUNTNUM\"></span>\n</div>\n";
},"useData":true,"useDepths":true});