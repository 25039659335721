import http from '@glu/core/src/http';
import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import services from 'services';
import template from './downloadModal.hbs';

export default Layout.extend({
    template,
    tagName: 'form',
    className: 'check-export-modal',

    initialize(opts) {
        this.model = new Model();
        this.checks = opts.checks;
        this.totalItems = opts.totalItems;
        this.dialogTitle = locale.getDefault('CM.download.checks', 'Download Checks');

        this.dialogButtons = [{
            text: locale.getDefault('CM.download', 'Download'),
            className: 'btn btn-primary',
            callback: 'download',
        }, {
            text: locale.get('administration.cancel.btn'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        }];
    },

    download() {
        const self = this;
        let serviceName = 'createBulkCheckExport';

        const data = {
            searchFields: this.options.searchFields,
            requestType: this.model.get('dataOrImage'),
        };

        if (this.model.get('dataOrImage') === 'data') {
            Dialog.close();
            this.trigger('exportDataOnly', this.model);
            return;
        }

        if (this.options.isHistory) {
            serviceName = 'createBulkCheckHistExport';
        }

        http.post(services.generateUrl(`/cm/${serviceName}`), data, (response) => {
            self.options.success(response);
        }, (response) => {
            self.options.error(response);
        });
    },

    cancel() {
        Dialog.close();
    },

    templateHelpers() {
        return {
            checksSelectedMessage: locale.get('cm.bulkCheckTotal', this.totalItems),
        };
    },
});
