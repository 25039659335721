var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"MultipleEntry\">\n    <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":2,"column":16},"end":{"line":2,"column":29}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":45}}}) : helper)))
    + "</label>\n    <span class=\"MultipleEntry-inputMessage error\" data-hook=\"getInputStatusMessage\"></span>\n    <div class=\"MultipleEntry-inputs row\" data-hook=\"getSerialInputs\"></div>\n    <div class=\"MultipleEntry-addForm\">\n        <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"CM.action.insert",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":43}}}))
    + "</span>\n        <input class=\"form-control addCountInput\" type=\"number\" data-hook=\"getAddCount\"/>\n        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":28}}}) : helper)))
    + "</span>\n        <button class=\"btn btn-secondary addBtn\" data-hook=\"getAddBtn\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"CM.action.insert",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":71},"end":{"line":9,"column":100}}}))
    + "</button>\n    </div>\n    <span class=\"MultipleEntry-message error\" data-hook=\"getStatusMessage\"></span>\n</div>";
},"useData":true});