import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import Constants from 'common/dynamicPages/api/constants';
import httpError from 'common/util/httpErrorResult';

export default {

    sync(method, model, options) {
        let requestData;
        let requestService;
        switch (method) {
        case 'update':
            requestService = `${services.generateUrl(model.context.serviceName)}${Constants.URL_UPDATE_ACTION}`;
            // intentional fall through for common code
        case 'create':
            requestService = requestService
                    || services.generateUrl(model.context.serviceName) + Constants.URL_ADD_ACTION;
            requestData = this.convertModelAttributesToServerJSON(model, method, true);

            http.post(requestService, requestData, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
            break;
        case 'read':
            // request should contain the context and the group name
            requestData = this.convertModelAttributesToKeyJSON(model);
            requestService = services.generateUrl(model.context.serviceName)
                    + Constants.URL_READCHILDREN_ACTION;
            http.post(requestService, requestData, (response) => {
                const data = this.convertServerJsonToModelAttributes(response);
                options.success(data);
            }, () => {});
            break;
        case 'delete':
            requestData = this.convertModelAttributesToKeyJSON(model);
            requestService = `${services.generateUrl(model.context.serviceName)}${Constants.URL_DELETE_ACTION}`;

            http.post(requestService, requestData, (result) => {
                options.success(result);
            }, (result) => {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                });
            });
            break;
        default:
            break;
        }
    },

    /**
     * @method convertServerJsonToModelAttributes
     * @param serverJson - JSON response from service (READ)
     * @returns {{}}
     */
    convertServerJsonToModelAttributes(serverJson) {
        const data = {};
        let recipients = null;

        util.each(serverJson.item.item, (entry, index, itemArray) => {
            data[entry.name] = this.formatModelAttributes(entry, itemArray);
        });

        // get the recipients
        const recipientList = util.findWhere(serverJson.grids, {
            name: 'EMAILGROUPS',
        });

        if (recipientList) {
            recipients = util.chain(recipientList.items)
                .map(item => util.findWhere(item.item, {
                    name: 'RECIPIENTNAME',
                }))
                .reduce((acc, item) => {
                    let accParam = acc;
                    if (accParam.length) {
                        accParam += ',';
                    }
                    return accParam + item.value;
                }, '')
                .value();
        }
        data.RECIPIENTNAME = recipients;
        return data;
    },

    convertModelAttributesToKeyJSON(model) {
        const jsonData = {};
        jsonData.item = {};
        jsonData.item.item = [{
            name: 'NAME',
            value: model.get('NAME'),
        }, {
            name: 'USERGROUP',
            value: model.get('USERGROUP'),
        }];

        return jsonData;
    },

    convertModelAttributesToServerJSON(model) {
        const retJson = {};
        let modelRecipients = model.get('RECIPIENTNAME');

        const jsonData = [{
            name: 'USERGROUP',
            value: model.get('USERGROUP'),
        }, {
            name: 'NAME',
            value: model.get('NAME'),
        }, {
            name: 'GROUPFLAG',
            value: '1',
        }, {
            name: 'LOCALE',
            value: userInfo.get('locale'),
        }];

        retJson.item = {};
        retJson.item.item = jsonData;

        /*
         * add fine grain recipient data
         * if the recipients from the model is a comma delimited string (modify w/o changing
         * the recipient list) then convert it to an array
         */
        if (!util.isArray(modelRecipients)) {
            modelRecipients = modelRecipients.split(',');
        }
        const recipients = util.map(modelRecipients, recipient => ({
            item: [{
                name: 'RECIPIENTNAME',
                value: recipient,
            }],
        }));

        retJson.grids = [{
            name: 'EMAILGROUPS',
            items: recipients,
        }];

        return retJson;
    },

};
