import util from '@glu/core/src/util';
import Glu from '@glu/core/src/glu';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import Collection from '@glu/core/src/collection';
import React from 'react';
import store from 'system/utilities/cache';
import Formatter from 'system/utilities/format';
import errHandler from 'system/errHandler';
import InquiryApi from 'system/webseries/api/inquiry';
import services from 'services';
import AlertsListView from 'app/administration/views/listAlerts';
import RecipientsListView from 'app/setup/views/listAlertRecipients';
import RecipientGroupAssignList from 'app/administration/views/alerts/recipientGroupsTabView';
import RecipientGroupAssignment from 'app/administration/views/alerts/recipientGroupAssignment';
import AuditView from 'app/administration/views/audit/audit';
import StackView from 'common/stack/views/stackView';
import fraudConstants from 'app/checkManagement/constants';
import babConstants from 'app/bab/constants';
import { appBus } from '@glu/core';
import dialog from '@glu/dialog';
import transform from 'common/util/transform';
import Model from '@glu/core/src/model';
import ConfigurationsView from 'app/administration/views/configurations/configurations';
import ConfigurationsEntryView from 'app/administration/views/configurations/configEntryView';
import AddressBookAPI from 'app/administration/views/fileImportHistory/bab/addressBook';
import ContactView from 'app/administration/views/fileImportHistory/bab/viewContact';
import PasscodeSettingsView from 'app/administration/views/security/passcodeView';
import SegmentationListView from 'app/administration/views/segmentation/market/listView';
import SegmentationProfile from 'app/administration/views/segmentation/profile/add';
import SegmentationViewProfile from 'app/administration/views/segmentation/profile/view';
import SegmentationServices from 'app/administration/views/segmentation/services/add';
import SegmentationViewServices from 'app/administration/views/segmentation/services/view';
import SegmentationPaymentLimits from 'app/administration/views/segmentation/paymentLimits/paymentLimitsView';
import SegmentationPaymentLimitsCollection from 'app/administration/collection/segmentation/paymentLimitsCollection';
import SegmentationBranding from 'app/administration/views/segmentation/branding/add';
import SegmentationExternalWidgets from 'app/administration/views/segmentation/externalWidgets/view';
import SegmentationElearning from 'app/administration/views/segmentation/elearning/view';
import FileHistoryView from 'app/administration/views/fileImport/list';
import FileHistoryDetailsView from 'app/administration/views/fileImport/fiDetail';
import ClientSettingsDetailsView from 'app/administration/views/clientSettings/details';
import WorkspaceView from 'common/workspaces/views/workspace';
import AlertsCenterView from 'app/administration/views/alerts/alertsCenterView';
import DetailAlertView from 'app/administration/views/alerts/details';
import ConfidentialSettingsView from 'app/administration/views/confidentialSettings/confidentialSettingsView';
import IntermediaryBankMappingListView from 'app/administration/views/intermediaryBankMapping/listView';
import CorrespondentBankMappingListView from 'app/administration/views/correspondentBankMapping/listView';
import CorrespondentBankMappingDetail from 'app/administration/views/correspondentBankMapping/detailsView';
import CheckIssueManagement from 'app/administration/views/checkManagement/tabWrapper';
import IssueVoidsListView from 'app/administration/views/checkManagement/listView';
import IssueVoidsDetailsView from 'app/administration/views/checkManagement/detailsView';
import IssueVoidReconciliation from 'app/administration/views/checkManagement/reconciliationReport/summary';
import IssueVoidReconciliationDetail from 'app/administration/views/checkManagement/reconciliationReport/detail';
import PaymentsListView from 'app/administration/views/paymentManagement/listView';
import PaymentsDetailsView from 'app/administration/views/paymentManagement/detailsView';
import TemplatesListView from 'app/administration/views/templateManagement/listView';
import TemplatesDetailsView from 'app/administration/views/templateManagement/detailsView';
import RecurPayExceptListView from 'app/administration/views/exceptions/listView';
import StopCancelsListView from 'app/administration/views/stopPayments/listView';
import StopCancelsDetailsView from 'app/administration/views/stopPayments/detailsView';
import StopCancelCheckView from 'app/administration/views/stopPayments/stopCancelCheckView';
import PaymentAutomationManager from 'app/administration/views/paymentAutomationManager/listView';
import PaymentAutomationManagerDetails from 'app/administration/views/paymentAutomationManager/details';
import AdminReportingView from 'app/administration/views/adminReporting/reporting';
import AdminReportingDetailsView from 'app/administration/views/adminReporting/detailList';
import FraudControl from 'app/administration/views/fraudControl/fraudControl';
import PosPayFraudControl from 'app/administration/views/fraudControl/posPayExtractLogDetail';
import DecisionHistorySearch from 'app/administration/views/fraudControl/history/decisionHistorySearch';
import ReversePositivePayDecisionList from 'app/administration/views/fraudControl/listReversePositivePayDecision';
import EPositivePayDecisionList from 'app/administration/views/fraudControl/listEPositivePayDecision';
import ViewEPositivePayDecision from 'app/administration/views/fraudControl/viewEPositivePayDecision';
import ViewPositivePayDecision from 'app/administration/views/fraudControl/viewPositivePayDecision';
import ViewReversePositivePayDecision from 'app/administration/views/fraudControl/viewReversePositivePayDecision';
import PositivePayDecisionList from 'app/administration/views/fraudControl/listPositivePayDecision';
import ImportResultsList from 'app/administration/views/fraudControl/importResults';
import PassThroughDetailsView from 'app/administration/views/paymentManagement/passThroughDetails';
import PassThroughViewPayment from 'app/administration/views/paymentManagement/passThrough/viewPayment';
import AdminFileHistoryView from 'app/administration/views/fileImportHistory/listView';
import AdminFileHistoryDetailsView from 'app/administration/views/fileImportHistory/fiDetail';
import contextApi from 'common/dynamicPages/api/context';
import ExternalWidgetListView from 'app/administration/views/cxp/externalWidget/view';
import ExternalWidgetView from 'app/administration/views/cxp/externalWidget/detail';
import ExternalWidgetEditContentView from 'app/administration/views/cxp/externalWidget/editContent';
import LiveMaintenanceView from 'app/administration/views/liveMaintenance/liveMaintenanceView';
import LiveMaintenanceMessageCollection from 'app/administration/collection/liveMaintenanceMessageCollection';
import loans from 'app/loans/api/common';
import LoanPaymentDetailsView from 'app/administration/views/paymentManagement/loanDetailsView';
import LoanTemplateDetailsView from 'app/administration/views/templateManagement/loanDetailsView';
import FileLoadAdminExport from 'app/administration/views/fileLoad/fileLoadAdminExport';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import ssoApi from 'app/utilities/sso/ssoApi';
import SSOView from 'app/utilities/sso/ssoView';
import ELearningNavigationView from 'app/administration/views/cxp/elearning/navigation';
import ELearningDetailContentView from 'app/administration/views/cxp/elearning/detailContent';
import ELearningDetailGroupView from 'app/administration/views/cxp/elearning/detailGroup';
import ElearningGroupCollection from 'app/administration/collection/elearningGroup';
import ElearningWidgetGroupCollection from 'app/administration/collection/elearningWidgetGroup';
import FXReporting from 'app/administration/views/fxReporting/fx';
import BannerNotificationTabView from 'app/administration/views/notificationManagement/tabWrapper';
import BannerNotificationDetailsView from 'app/administration/views/notificationManagement/notificationEntry';
import BannerNotificationTemplateDetailsView from 'app/administration/views/notificationManagement/notificationTemplateEntry';
import TransferTemplateList from 'app/transfers/views/listTransferTemplates';
import TransferList from 'app/transfers/views/listTransfers';
import VendorList from 'app/administration/views/sso/vendorListView';
import SSOAdministrationTabWrapper from 'app/administration/views/sso/tabWrapper';
import ImportSSOVendor from 'app/administration/views/sso/importVendor';
import AdminTokenManagement from 'app/administration/views/tokenManagement/adminTokenListView';
import ClientTokenManagement from 'app/administration/views/tokenManagement/clientTokenListView';
import TokenLockedUsers from 'app/administration/views/tokenManagement/tokenLockedUsers';
import ExpiringTokens from 'app/administration/views/tokenManagement/expiringTokens';
import TokensAudit from 'app/administration/views/tokenManagement/tokensAudit';
import MySettingsView from 'app/administration/views/alerts/mySettingsView';
import workspaceHelper from 'common/workspaces/api/helper';
import ListView from 'common/dynamicPages/views/workflow/list';
import RTPPreferencesView from 'app/administration/views/realTimePayments/preferencesView';
import AnalyticsServerSettings from 'app/administration/views/analyticsServer/analyticsServerSettingsView';
import RealTimeIncomingView from 'app/administration/views/realTimePayments/listView';
import RTPIncomingDetailsView from 'app/administration/views/realTimePayments/rtpIncomingDetailsView';
import RFPMangementListView from 'app/payments/views/realTimePayments/rfpMainView';
import PanelApprovalList from 'app/panelApproval/views/panelApprovalList';
import AccountSettingsWidget from 'app/accountSettings/AccountSettingsWidget';
import TOALocationListView from 'app/administration/views/toaLocations/views/toaLocationsList';
import TOALocationEntryView from 'app/administration/views/toaLocations/views/toaLocationEntry';
import TOAConstants from 'app/administration/views/toaLocations/views/constants';
import systemConfig from 'system/configuration';
import CheckfreeRXPSubscriber from 'app/administration/views/checkFree/rxpSubscriber';
import CheckfreeRXPAccounts from 'app/administration/views/checkFree/rxpAccountListView';
import CheckfreeRXPAccountDetails from 'app/administration/views/checkFree/rxpAccountDetailView';
import CheckfreeSmbSubscriber from 'app/administration/views/checkFree/smbSubscriber';
import CheckfreeSMBAccounts from 'app/administration/views/checkFree/smbAccountListView';
import CheckfreeSMBAccountDetails from 'app/administration/views/checkFree/smbAccountDetailView';
import CheckfreeSMBUsers from 'app/administration/views/checkFree/smbUsersListView';
import CheckfreeSMBUserDetails from 'app/administration/views/checkFree/smbUserDetailView';
import { removeViewIdFromRoute } from 'system/utilities/routeHelper';
import AdminListView from 'components/AchTotalAdmin/AdminListView';
import AddNewPaymentGroupId from 'components/AchTotalAdmin/AddNewPaymentGroupId';
// PCM related
import withPCM from 'pcmMdf/app/administration/pcmActions'; //eslint-disable-line

// Workspace widgets that need to be required
import 'app/administration/views/clientSettings/list';
import 'app/administration/views/bankAccountSettings/list';
// Relative imports
import CommandAndControlListView from './views/commandAndControl/list';

/**
 * This controller handles all administration-related screens.
 */
const retObj = {
    adminAlertsWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'ALERTS',
                    returnRoute: 'ADMINSTRATION/alerts',
                }),
            }));
        }
    },

    /**
     * @method genericMdfListView
     *
     * List View Action Handler for generic MDF screens
     *
     */
    genericMdfListView() {
        const contextPath = Glu.Backbone.history.fragment;
        const contextInfo = contextPath.split('/');
        const options = {};

        [options.product] = contextInfo;
        options.context = options.product;
        if (contextInfo[1]) {
            [, options.type] = contextInfo;
            options.context += '_';
            options.context += options.type;
        }
        options.returnRoute = contextPath;

        const context = contextApi.menuContext.getContext(options.context);
        options.menuCategory = context.menuCatergory.toUpperCase();
        options.serviceName = context.serviceName;

        this.showPage('', new ListView(options));
    },

    // This is needed to render the PMX simple account management widget in DeepLinks
    pmxAccountManagementWidget() {
        if (processDeeplink('ADMINSTRATION/pmxAccountManagementWidget', this)) {
            appBus.trigger('router:navigate', 'px/admin/membership/simple-account-management', true);
        }
    },

    // This is needed to render the PMX payer contact options widget in DeepLinks
    pmxPayerContactOptionsWidget() {
        if (processDeeplink('ADMINSTRATION/pmxPayerContactOptionsWidget', this)) {
            appBus.trigger('router:navigate', 'px/admin/membership/contact-options', true);
        }
    },

    adminAlertsCenterView(tab) {
        if (processDeeplink('ADMINSTRATION/alertsCenter', this)) {
            this.showPage('', new AlertsCenterView({
                defaultTab: tab,
                returnRoute: 'ADMINSTRATION/alertsCenter',
            }));
        }
    },

    adminFxReporting() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new FXReporting({
                        id: 'FX_TRANS_LIST_VIEW',
                        returnRoute: 'REPORTING/viewFXTransactions',
                    }),
                }),
            );
        } else if (this.currentView.stack && this.currentView.stack.length) {
            this.currentView.stack[0].view.gridView.refreshGridData();
        }
    },

    adminPreferencesWorkspace() {
        if (!this.popPage() || this.getStackCount() === 0) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'PREFERENCES',
                    returnRoute: 'ADMINSTRATION/preferences',
                }),
            }));
        }
    },

    adminViewClientSettings() {
        this.showPage('', new ClientSettingsDetailsView({
            id: 'CLIENT_SETTINGS',
            mode: 'view',
            returnRoute: 'ADMINSTRATION/preferences',
            lastFragment: this.lastFragment,
        }));
    },

    adminModifyClientSettings() {
        let lastFragment;
        if (this.currentView?.mode === 'view') {
            lastFragment = store.get('current-workspace-route');
        } else {
            ({ lastFragment } = this);
        }
        this.showPage('', new ClientSettingsDetailsView({
            id: 'CLIENT_SETTINGS',
            mode: 'modify',
            returnRoute: 'ADMINSTRATION/preferences',
            lastFragment,
        }));
    },

    auditView() {
        this.showPage('Audit Information', new AuditView({
            menuCategory: 'ADMINSTRATION',
            viewUrl: 'ADMINSTRATION/audit/viewAudit',
            returnRoute: 'ADMINSTRATION/audit',
            context: 'AUDIT_INFO',
            filterId: 9036,

            typeInfo: {
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: 'AUDIT',
            },
        }));
    },

    adminAuditView() {
        this.showPage('Bank Admin Audit Information', new AuditView({
            menuCategory: 'ADMINSTRATION',
            viewUrl: 'ADMINSTRATION/adminAudit/viewAdminAudit',
            returnRoute: 'ADMINSTRATION/adminAudit',
            context: 'ADMAUDIT_INFO',
            filterId: 9046,

            typeInfo: {
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: 'ADMAUDIT',
            },
        }));
    },

    adminViewNotifications() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'COMPANY_BANNER',
                    returnRoute: 'CMAINT/viewNotifications',
                }),
            }));
        }
    },

    adminManageNotifications() {
        const options = {
            returnRoute: store.has('bannerNotifications:returnRoute') ? store.get('bannerNotifications:returnRoute') : 'CMAINT/viewNotifications',
            mode: 'INSERT',
            isTemplateWorkflow: false,
        };

        if (store.get('bannerNotifications:read')) {
            options.notification = store.get('bannerNotifications:read').model;
            options.mode = store.get('bannerNotifications:read').action.toUpperCase();
            options.returnRoute = store.get('bannerNotifications:read').returnRoute;
        }
        this.showPage('', new BannerNotificationDetailsView(options));
    },

    adminManageNotificationTemplate() {
        const options = {
            returnRoute: store.has('bannerNotifications:returnRoute') ? store.get('bannerNotifications:returnRoute') : 'CMAINT/viewNotifications',
            mode: 'INSERT',
            isTemplateWorkflow: true,
        };

        if (store.get('bannerNotifications:read')) {
            options.notification = store.get('bannerNotifications:read').model;
            options.mode = store.get('bannerNotifications:read').action.toUpperCase();
            options.returnRoute = store.get('bannerNotifications:read').returnRoute;
        }
        this.showPage('', new BannerNotificationTemplateDetailsView(options));
    },

    appConfigWorkspace(tab) {
        this.showPage('', new ConfigurationsView({
            id: 'APPCONFIG',
            defaultTab: tab,
            returnRoute: 'SMAINT/appConfig',
        }));
    },

    appConfigEntryView() {
        this.showPage('', new ConfigurationsEntryView());
    },

    auditInfoWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'AUDIT_INFO',
                    returnRoute: 'ADMINSTRATION/audit',
                }),
            }));
        }
    },

    adminAuditInfoWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'ADMAUDIT_INFO',
                    returnRoute: 'ADMINSTRATION/adminAudit',
                }),
            }));
        }
    },

    /**
     * Display the alerts list view.
     */
    listAlerts() {
        if (processDeeplink('ADMINSTRATION/listAlerts', this)) {
            this.showPage(locale.get('alerts.listAlert'), new AlertsListView({
                returnRoute: 'ADMINSTRATION/listAlerts',
            }));
        }
    },

    /**
     * Display the bank account settings widget.
     */
    listBankAccountSettings() {
        const deeplinkRoute = 'SETUP/bankAccountSettings';
        if (processDeeplink(deeplinkRoute, this)) {
            const bankAccountSettingsView = new AccountSettingsWidget({
                returnRoute: deeplinkRoute,
            });
            this.showPage('', bankAccountSettingsView);
        }
    },

    /**
     * @method addAlert
     * This method is invoked when users adds Alert from the Alerts List View.
     */
    addAlert() {
        if (processDeeplink('ADMINSTRATION/alerts/addAlert', this)) {
            this.showPage(locale.get('alerts.createAlert'), new DetailAlertView({
                returnRoute: this.lastFragment,
            }));
        }
    },

    modifyAlert() {
        this.showPage(locale.get('alerts.modifyAlert'), new DetailAlertView({
            state: 'modify',
        }));
    },

    recipientsList() {
        if (processDeeplink('ADMINSTRATION/recipients', this)) {
            this.showPage('', new RecipientsListView({
                returnRoute: 'ADMINSTRATION/recipients',
            }));
        }
    },

    recipientGrpAssignment() {
        if (processDeeplink('ADMINSTRATION/recipientGrpAssignment', this)) {
            this.showPage('', new RecipientGroupAssignList({
                returnRoute: 'ADMINSTRATION/recipientGrpAssignment',
            }));
        }
    },

    recipientGroupAssignment(mode) {
        const useMode = mode || 'insert';
        const options = {
            menuCategory: 'ADMINISTRATION',
            serviceName: 'tableMaintenance/emailRecipGrpAssignment',
            serviceFunc: null,
            businessType: null,
            context: 'EMAIL_RECP_ASIGN',
            mode: useMode,
        };

        if (processDeeplink('ADMINSTRATION/recipientGroupAssignment', this)) {
            this.showPage('', new RecipientGroupAssignment(util.extend(
                options,
                {
                    returnRoute: 'ADMINSTRATION/recipientGrpAssignment',
                    mode: useMode,
                },
            )));
        }
    },

    /**
     * My Settings widget view.  Used for editing contact information.
     */
    mySettings() {
        if (processDeeplink('mySettings', this)) {
            this.showPage('', new MySettingsView());
        }
    },

    adminFileLoadExport() {
        this.showPage('Export', new FileLoadAdminExport());
    },

    viewAlert() {
        this.showPage('Modify Alert', new DetailAlertView({
            state: 'view',
        }));
    },

    /**
     * Security Pascode settings
     */
    passcodeSettings() {
        this.showPage('Passcode Settings', new PasscodeSettingsView());
    },

    /**
     * Display the File Import History list view.
     */
    listFiles(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('ADMINSTRATION/fileImportHistory', this)) {
            this.showPage('File Import History', new FileHistoryView({
                viewId,
            }));
        }
    },

    listFilesWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'FILEIMPORT',
                    returnRoute: 'ADMINSTRATION/manageFileImportHistory',
                }),
            }));
        }
    },

    listFileDetails() {
        this.showPage('File Import History Details', new FileHistoryDetailsView());
    },

    /**
     * Display the Segmentation list view.
     */
    adminSegmentationWorkspace() {
        this.showPage('Market Segmentation List', new SegmentationListView());
    },

    adminSegAddProfile() {
        this.showPage('Segmentation Profile', new SegmentationProfile());
    },

    adminSegViewProfile() {
        this.showPage('Segmentation Profile', new SegmentationViewProfile());
    },

    adminSegAddServices() {
        this.showPage('Segmentation Services', new SegmentationServices());
    },

    adminSegViewServices() {
        this.showPage('Segmentation View Services', new SegmentationViewServices());
    },

    adminSegAddPaymentLimits() {
        this.showPage('Segmentation Payment Limits', new SegmentationPaymentLimits({
            paymentLimitsCollection: new SegmentationPaymentLimitsCollection(),
        }));
    },

    adminSegAddBranding() {
        this.showPage('Segmentation Branding', new SegmentationBranding());
    },

    adminSegExternalWidgets() {
        const self = this;
        self.segmentName = store.get('segmentName');
        http.post(
            services.generateUrl('/workspaces/getAllWorkspaces'),
            {
                marketSegment: self.segmentName,
            },
            (workspaceResponse) => {
                self.workspaces = workspaceResponse.workspaces;
                http.post(
                    services.generateUrl('/cxp/externalWidget/getDeploymentModels'),
                    {
                        marketSegment: self.segmentName,
                    },
                    (deploymentResponse) => {
                        const externalWidgetModels = new Collection(deploymentResponse);
                        externalWidgetModels.each((model) => {
                            model.set('workspaces', self.workspaces);
                        });
                        self.showPage('Segmentation External Widgets', new SegmentationExternalWidgets({
                            externalWidgets: externalWidgetModels,
                        }));
                    },
                );
            },
        );
    },

    adminSegElearning() {
        const self = this;
        self.segmentName = store.get('segmentName');
        const promiseArr = [
            new Promise((resolve, reject) => {
                const options = {
                    marketSegment: self.segmentName,
                };
                new ElearningWidgetGroupCollection(options).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
            new Promise((resolve, reject) => {
                new ElearningGroupCollection().fetch({
                    success: resolve,
                    error: reject,
                });
            }),
        ];

        Promise.all(promiseArr)
            .then(([widgets, groups]) => {
                self.showPage('Segmentation E-Learning', new SegmentationElearning({
                    widgets,
                    groups,
                }));
            })
            .then(null, errHandler);
    },

    /**
     *  Display Check Management list view and details
     */
    adminCheckIssueManagementWorkspace(tab) {
        const issueVoidsListView = new IssueVoidsListView({
            id: 'CM_IV_LIST',
            returnRoute: 'REPORTING/viewCMIssueVoids',
        });
        const issueVoidReconciliation = new IssueVoidReconciliation();
        this.showPage('', new CheckIssueManagement({
            defaultTab: tab,
            individualItems: issueVoidsListView,
            fileExtract: issueVoidReconciliation,
        }));
    },

    adminCheckIssueManagementDetails() {
        this.showPage('Check Management Details', new IssueVoidsDetailsView());
    },

    adminIssueVoidReconciliationDetail() {
        this.showPage('', new IssueVoidReconciliationDetail());
    },

    /**
     *  Display Payment Management list view and details
     */
    adminPaymentsManagementWorkspace() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new PaymentsListView({
                        id: 'PAY_LIST_VIEW',
                        returnRoute: 'REPORTING/viewPayments',
                    }),
                }),
            );
        } else if (this.currentView.stack && this.currentView.stack.length) {
            // If we have a stackView refresh the grid but keep the filters
            this.currentView.stack[0].view.gridView.refreshGridData();
        }
    },

    /**
     * @method adminPaymentsManagementDetails
     *
     * @param {object} isSubPayment - optional parameter defined in the route
     * that indicates a payment within a top level payment (File Payment)
     */
    adminPaymentsManagementDetails(isSubPayment) {
        if (isSubPayment) {
            this.showPage('Payment Management SubPayment Details', new PaymentsDetailsView({ isSubPayment }));
        } else {
            this.pushPage(
                'Payment Management Details',
                new PaymentsDetailsView({}),
            );
        }
    },

    adminPassThroughDetails() {
        this.showPage('Pass-Through Summary', new PassThroughDetailsView());
    },

    showRTPIncomingDetails() {
        this.showPage('', new RTPIncomingDetailsView());
    },

    requestForPaymentMangement() {
        this.showPage('', new RFPMangementListView({
            returnRoute: 'REPORTING/requestForPaymentMangement',
        }));
    },

    getLoanRecord(model, url) {
        const service = services.generateUrl(url);
        const data = {
            item: [{
                name: 'TNUM',
                value: model.TNUM,
            }, {
                name: 'PRODUCTCODE',
                value: model.PRODUCT,
            }, {
                name: 'FUNCTIONCODE',
                value: model.FUNCTION,
            }, {
                name: 'TYPECODE',
                value: model.TYPE,
            }, {
                name: 'ENTRYMETHOD',
                value: model.ENTRYMETHOD,
            }, {
                name: 'SUBTYPE',
                value: '*',
            }, {
                name: 'UPDATECOUNT__',
                // eslint-disable-next-line
                value: model.UPDATECOUNT__,
            }],
        };

        return new Promise((resolve, reject) => {
            http.post(service, data, (response) => {
                resolve(response);
            }, () => {
                reject('Error: There was an error loading the data');
            });
        });
    },

    adminManagementLoanDetailsParent(gridModelName, filePath, TypeOfLoan) {
        const self = this;
        const gridModel = store.get(gridModelName).toJSON();
        let model = null;
        let modelPromise = null;
        const typeCode = gridModel.TYPE;
        let title = null;
        let debitInquiryId = null;
        let creditInquiryId = null;

        if (typeCode === 'LOANPAY' || typeCode === 'LOANDRAW') {
            if (typeCode === 'LOANPAY') {
                title = locale.get('common.loanPayment');
                debitInquiryId = 19533;
                creditInquiryId = 19534;
            } else {
                title = locale.get('common.loanDrawdown');
                debitInquiryId = 19535;
                creditInquiryId = 19536;
            }
            modelPromise = this.getLoanRecord(gridModel, filePath);

            modelPromise.then((results) => {
                model = loans.convertServerJSONToModelAttributes(results.item);
                model.CMB_VALUE_DATE = Formatter.formatDate(model.CMB_VALUE_DATE);

                const loanDetailsView = new TypeOfLoan({
                    title,
                    model: results.item,
                    typeCode,
                    payMethod: 'RTGS',
                    debitInquiryId,
                    creditInquiryId,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    preferences: [],
                });
                self.showPage(loanDetailsView.title, loanDetailsView);
            }).then(null, errHandler);
        }
    },

    adminPaymentsManagementLoanDetails() {
        this.adminManagementLoanDetailsParent('loanModel', '/adminPayment/listView/payments/read', LoanPaymentDetailsView);
    },

    /**
     *  Display Template Management list view and details
     */
    adminTemplatesManagementWorkspace() {
        this.showPage('', new TemplatesListView({
            id: 'TEMP_LIST_VIEW',
            returnRoute: 'REPORTING/viewTemplates',
        }));
    },

    adminTemplatesManagementDetails() {
        this.showPage('Template Management Details', new TemplatesDetailsView());
    },

    adminTemplatesManagementLoanDetails() {
        this.adminManagementLoanDetailsParent('loanTemplateModel', '/adminTemplate/listView/templates/read', LoanTemplateDetailsView);
    },

    /**
     *  Display Fraud Control list view and details
     */
    adminFraudControlWorkspace(tab) {
        this.showPage('Payment Fraud Control', new FraudControl({
            id: 'CM_FRAUD_CTRL',
            defaultTab: tab,
            returnRoute: 'REPORTING/viewPaymentFraudControl',
        }));
    },

    adminPosPayFraudControlWorkspace() {
        this.showPage('', new PosPayFraudControl());
    },

    adminDecisionHistory() {
        const fraudControlModel = store.get('adminCM_fraud_listView_paymentFraudControl-actionModel');

        const options = {
            fraudControlModel,
            filterId: fraudControlModel ? fraudConstants.filterId[fraudControlModel.get('TYPE')] : null,
            returnRoute: 'REPORTING/viewPaymentFraudControl',
            accountFilter: fraudControlModel ? fraudControlModel.get('ACCOUNTFILTER') : null,
        };

        this.showPage(locale.get('cm.decision.history.title'), new DecisionHistorySearch(options));
    },

    adminEPositivePayDecision() {
        const options = {
            returnRoute: 'REPORTING/viewPaymentFraudControl',
            viewAll: store.get('cm_listView_payDecision-viewAll'),
        };
        this.showPage(locale.get('cm.payment.decisioning'), new EPositivePayDecisionList(options));
    },

    adminPositivePayDecision() {
        const options = {
            returnRoute: 'REPORTING/viewPaymentFraudControl',
            viewAll: store.get('cm_listView_payDecision-viewAll'),
        };
        this.showPage(locale.get('cm.payment.decisioning'), new PositivePayDecisionList(options));
    },

    adminReversePositivePayDecision() {
        const options = {
            returnRoute: 'REPORTING/viewPaymentFraudControl',
            viewAll: store.get('cm_listView_payDecision-viewAll'),
        };
        this.showPage(locale.get('cm.payment.decisioning'), new ReversePositivePayDecisionList(options));
    },

    adminImportResults() {
        const options = {
            returnRoute: 'REPORTING/viewPaymentFraudControl',
        };
        this.showPage('Import Results', new ImportResultsList(options));
    },

    adminViewPositivePayDecision() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminCM/fraud/listView/positivePayDecision',
            serviceFunc: null,
            businessType: null,
            context: '',
            mode: 'view',
        };

        if (store.get('cm_listView_payDecision-viewAll')) {
            options.returnRoute = 'REPORTING/viewPaymentFraudControl';
        } else {
            options.returnRoute = 'REPORTING/positivePayDecision';
        }

        this.showPage('', new ViewPositivePayDecision(options));
    },

    adminViewReversePositivePayDecision() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminCM/fraud/listView/reversePositivePayDecision',
            serviceFunc: null,
            businessType: null,
            context: '',
            mode: 'view',
        };

        if (store.get('cm_listView_payDecision-viewAll')) {
            options.returnRoute = 'REPORTING/viewPaymentFraudControl';
        } else {
            options.returnRoute = 'REPORTING/reversePositivePayDecision';
        }

        this.showPage('', new ViewReversePositivePayDecision(options));
    },

    adminViewEPositivePayDecision() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminCM/fraud/listView/ePositivePayDecision',
            serviceFunc: null,
            businessType: null,
            context: '',
            mode: 'view',
        };

        if (store.get('cm_listView_payDecision-viewAll')) {
            options.returnRoute = 'REPORTING/viewPaymentFraudControl';
        } else {
            options.returnRoute = 'REPORTING/ePositivePayDecision';
        }

        this.showPage('', new ViewEPositivePayDecision(options));
    },

    /**
     * Recurring Payment Exception Report list view
     */
    adminRecurPaymentExceptWorkspace() {
        this.showPage('Recurring Payment Exception Report', new RecurPayExceptListView({
            id: 'RECURPAY_VIEW',
            returnRoute: 'REPORTING/viewRecurPayExceptions',
        }));
    },

    /**
     *  Display Stop Payments list view and details
     */
    adminStopCancelsWorkspace() {
        this.showPage('Stop Payments', new StopCancelsListView({
            id: 'PAY_CM_IV_LIST',
            returnRoute: 'REPORTING/viewStopCancels',
        }));
    },

    adminStopCancelsDetails() {
        this.showPage('Stop Cancels Details', new StopCancelsDetailsView());
    },

    adminStopCancelCheckView() {
        const options = {
            context: 'PAY_CM_IV_LIST',
            model: store.get('cm_listView_stopCancels-actionModel'),
            mode: 'view',
        };

        this.showPage('', new StopCancelCheckView(options));
    },

    /**
     * Payment Automation Payment Manager list view
     * File Lifecycle Monitoring
     */
    adminPaymentAutomationManager() {
        if (systemConfig.isAdmin()) {
            this.showPage('', new PaymentAutomationManager({
                id: 'PAYAUTLC',
                returnRoute: 'REPORTING/viewAutoPaymentsSummary',
            }));
        } else if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'PAYMENTAUTOMATION',
                    returnRoute: 'REPORTING/viewAutoPaymentsSummary',
                }),
            }));
        }
    },

    /**
     * Payment Automation Payment Manager details
     * File Lifecycle Monitoring
     */
    adminPaymentAutomationManagerDetails() {
        this.showPage('View File Lifecycle', new PaymentAutomationManagerDetails({
            state: 'view',
        }));
    },

    // Payment Automation Summary Widget deeplink
    adminPaymentAutomationManagerList() {
        if (processDeeplink('REPORTING/viewAutoPaymentsSummaryList', this)) {
            this.showPage('', new PaymentAutomationManager({
                id: 'PAYAUTLC',
                returnRoute: 'REPORTING/viewAutoPaymentsSummary',
            }));
        }
    },

    /*
     * Admin Feature Reports
     */
    adminReportingWorkspace() {
        if (!this.popPage()) {
            this.showPage(
                'Admin Reporting',
                new StackView({
                    initItem: new AdminReportingView(),
                }),
            );
        }
    },

    adminReportingDetails() {
        this.pushPage(
            'Admin Reporting Details',
            new AdminReportingDetailsView({
                detailOptions: store.get('adminReporting_listView-detailOptions'),
                returnRoute: 'REPORTING/adminReporting',
            }),
        );
    },

    /**
     *  Display File History list view and details
     */
    adminFileImportHistoryWorkspace() {
        this.showPage('', new AdminFileHistoryView({
            id: 'FIMPORT_LISTVIEW',
            returnRoute: 'REPORTING/viewFileImportHistory',
        }));
    },

    adminFileImportHistoryDetails() {
        this.showPage('File Import Details', new AdminFileHistoryDetailsView());
    },

    intermediaryBankMapping() {
        this.showPage('', new IntermediaryBankMappingListView({
            id: 'SMAINT_BENINTMP',
        }));
    },

    correspondentBankMapping() {
        this.showPage('', new CorrespondentBankMappingListView({
            id: 'SMAINT_CORBNKMP',
        }));
    },

    correspondentBankMappingDetailModify() {
        this.showPage('', new CorrespondentBankMappingDetail({
            id: 'SMAINT_CORBNKMP',
            mode: 'modify',
        }));
    },

    correspondentBankMappingDetailInsert() {
        this.showPage('', new CorrespondentBankMappingDetail({
            id: 'SMAINT_CORBNKMP',
            mode: 'insert',
        }));
    },

    correspondentBankMappingDetailView() {
        this.showPage('', new CorrespondentBankMappingDetail({
            id: 'SMAINT_CORBNKMP',
            mode: 'view',
        }));
    },

    viewConfidentialImportSettings(userGroup) {
        const options = {
            userGroup,
        };
        this.showPage('Confidential Import Settings', new ConfidentialSettingsView(options));
    },

    // Live Maintenance
    liveMaintenance() {
        this.showPage('Live Maintenance', new LiveMaintenanceView({
            // create new message collection with models fetched from server
            liveMaintenanceMessageCollection: new LiveMaintenanceMessageCollection(),
        }));
    },

    // E-Learning
    adminElearningWorkspace() {
        this.showPage('E-Learning', new ELearningNavigationView({
            lastRoute: this.lastFragment,
        }));
    },

    adminHandleElearningGroup(state, groupId) {
        this.showPage('Elearning Group', new ELearningDetailGroupView({
            groupId,
            state,
        }));
    },

    adminHandleElearningContent(state, contentId) {
        this.showPage('Elearning Content', new ELearningDetailContentView({
            contentId,
            state,
        }));
    },

    adminAddElearningGroup() {
        this.adminHandleElearningGroup('add', null);
    },

    adminUpdateElearningGroup(groupId) {
        this.adminHandleElearningGroup('update', groupId);
    },

    adminViewElearningGroup(groupId) {
        this.adminHandleElearningGroup('view', groupId);
    },

    adminAddElearningContent() {
        this.adminHandleElearningContent('add', null);
    },

    adminUpdateElearningContent(contentId) {
        this.adminHandleElearningContent('update', contentId);
    },

    // E-Learning
    adminViewElearningContent(contentId) {
        this.adminHandleElearningContent('view', contentId);
    },

    /*
     * can't just use
     * CXP/externalWidget/:action/:widgetId
     * as a badly done route in the dynamic pages section captures all 4 and 5 tier routes
     * stupid me, Gino
     */

    adminViewExternalWidget(widgetId) {
        this.adminHandleExternalWidget('view', widgetId);
    },

    adminAddExternalWidget() {
        this.adminHandleExternalWidget('add', null);
    },

    adminUpdateExternalWidget(widgetId) {
        this.adminHandleExternalWidget('update', widgetId);
    },

    adminEditWidgetContent(widgetId) {
        this.showPage('External Widget Content Edit', new ExternalWidgetEditContentView({
            widgetId,
        }));
    },

    adminExternalWidgetWorkspace() {
        this.showPage('External Widget', new ExternalWidgetListView());
    },

    adminHandleExternalWidget(state, widgetId) {
        this.showPage('External Widget', new ExternalWidgetView({
            widgetId,
            state,
        }));
    },

    beneViewBABContact() {
        const model = store.get(babConstants.STORE_BAB_VIEWMODEL);
        const addrAPI = new AddressBookAPI();
        const newModel = new Model(util.pick(model.attributes, 'TNUM', 'PRODUCTCODE', 'FUNCTIONCODE', 'TYPECODE'));
        const self = this;

        if (newModel) {
            addrAPI.read(newModel).then((result) => {
                const data = transform.pairsToHash(result.item);
                const view = new ContactView({
                    mode: 'view',
                    data,
                });

                self.showPage('View Contact', view);
            }, (result) => {
                dialog.alert(result);
                appBus.trigger('router:navigate', 'SETUP/viewContact', true);
            });
        } else {
            appBus.trigger('router:navigate', 'SETUP/viewContact', true);
        }
    },

    passThroughViewPayment() {
        this.showPage('', new PassThroughViewPayment());
    },

    adminviewTransfersList(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('REPORTING/listTransfers', this)) {
            this.showPage('', new TransferList({
                id: 'TRANSFERS_LIST',
                viewId,
                returnRoute: 'REPORTING/listTransfers',
            }));
        }
    },

    viewTransfersList() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'TRANSFERS',
                    returnRoute: 'REPORTING/manageTransfers',
                }),
            }));
        }
    },

    adminviewTransferTemplateList(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('REPORTING/listTransferTemplates', this)) {
            this.showPage('', new TransferTemplateList({
                id: 'TRANSFERS_TEMPLATE_LIST',
                viewId,
                returnRoute: 'REPORTING/listTransferTemplates',
            }));
        }
    },

    viewTransferTemplateWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'TRANSFER_TEMPLATES',
                    returnRoute: 'REPORTING/transferTemplates',
                }),
            }));
        }
    },

    adminManageTokensWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'TOKEN_MGMT',
                    returnRoute: 'ADMINSTRATION/manageTokens',
                }),
            }));
        }
    },

    adminManageCheckfreeRxpWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'CHECKFREE_RXP_MGMT',
                    returnRoute: 'ADMINSTRATION/manageCheckfreeRxp',
                }),
            }));
        }
    },

    showCheckfreeRxpSubscriber() {
        const options = {
            returnRoute: 'ADMINSTRATION/manageCheckfreeRxpSubscriber',
        };
        this.showPage('', new CheckfreeRXPSubscriber(options));
    },

    showCheckfreeRxpAccounts() {
        const options = {
            returnRoute: 'ADMINSTRATION/manageCheckfreeRxpAccounts',
        };
        this.showPage('', new CheckfreeRXPAccounts(options));
    },

    showCheckfreeRxpAccountDetails() {
        this.showPage('', new CheckfreeRXPAccountDetails());
    },

    adminManageCheckfreeSmbWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'CHECKFREE_SMB_MGMT',
                    returnRoute: 'ADMINSTRATION/manageCheckfreeSmb',
                }),
            }));
        }
    },

    showCheckfreeSmbSubscriber() {
        const options = {
            returnRoute: 'ADMINSTRATION/manageCheckfreeSmbSubscriber',
        };
        this.showPage('', new CheckfreeSmbSubscriber(options));
    },

    showCheckfreeSmbAccounts() {
        const options = {
            returnRoute: 'ADMINSTRATION/manageCheckfreeSmbAccounts',
        };
        this.showPage('', new CheckfreeSMBAccounts(options));
    },

    showCheckfreeSmbAccountDetails() {
        this.showPage('', new CheckfreeSMBAccountDetails());
    },

    showCheckfreeSmbUsers() {
        const options = {
            returnRoute: 'ADMINSTRATION/manageCheckfreeSmbUsers',
        };
        this.showPage('', new CheckfreeSMBUsers(options));
    },

    showCheckfreeSmbUserDetails() {
        this.showPage('', new CheckfreeSMBUserDetails());
    },

    /** Panel Approval Functions * */
    panelWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'PANEL',
                    returnRoute: 'ADMINSTRATION/viewPanelApprovalWorkflowList',
                }),
            }));
        }
    },

    listPanelApproval(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('ADMINSTRATION/listPanelApproval', this)) {
            const options = {
                returnRoute: 'ADMINSTRATION/listPanelApproval',
                viewId,
            };
            this.showPage('', new PanelApprovalList(options));
        }
    },

    admSSOOut(menuCode) {
        const menuctx = contextApi.menuContext.getContext(menuCode);
        const params = store.get('ssoout_params');
        store.remove('ssoout_params');
        const promiseMe = ssoApi.login(menuctx, params);
        const self = this;
        promiseMe.then((data) => {
            if (data.inNewWindow) {
                ssoApi.openSSOWindow(data);
            } else {
                self.showPage('Outbound SSO', new SSOView({
                    loginResponse: data,
                    menuctx,
                }));
            }
        });
    },

    ssoListVendors() {
        this.showPage('', new VendorList());
    },

    ssoAdmin() {
        this.showPage('', new SSOAdministrationTabWrapper({
            mode: 'INSERT',
        }));
    },

    ssoImportVendor() {
        this.showPage('', new ImportSSOVendor());
    },

    ssoViewVendor() {
        this.showPage('', new SSOAdministrationTabWrapper({
            mode: 'SELECT',
        }));
    },

    ssoModifyVendor() {
        this.showPage('', new SSOAdministrationTabWrapper({
            mode: 'MODIFY',
        }));
    },

    adminTokenManagement() {
        this.showPage('', new AdminTokenManagement());
    },

    adminExpiringTokensWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'TOKEN_REPORT',
                    returnRoute: 'TKNMGMT/tokenManagement/expiringTokens',
                }),
            }));
        }
    },

    admiTokensAuditWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'TOKEN_AUDIT',
                    returnRoute: 'TKNMGMT/tokenManagement/tokensAudit',
                }),
            }));
        }
    },

    adminExpiringTokensView() {
        this.showPage('', new ExpiringTokens());
    },

    showRTPPreferences() {
        this.showPage('', new RTPPreferencesView());
    },

    showAnalyticsServerSetting() {
        this.showPage('', new AnalyticsServerSettings());
    },

    /**
     *  Display Real Time Incoming Payment Management list view and details
     */
    adminRealTimeIncomingManagement() {
        this.showPage('Real-Time Incoming Payments Management', new RealTimeIncomingView({
            id: 'INCOMING_RTP',
            returnRoute: 'REPORTING/viewRealTimeIncoming',
        }));
    },

    /*
     * Display Command and Control Feature List View
     */
    commandAndControlListView() {
        this.showPage('', new CommandAndControlListView({
            id: 'FEATURECONTROL',
            returnRoute: 'FEATUREDEPLOYCTR/commandControlCenter/list',
        }));
    },

    /**
     * Display the Time Open Account List View (in admin)
     */
    adminListTOALocations(userGroup) {
        this.showPage('', new TOALocationListView({
            userGroup,
            returnRoute: 'CMAINT/listTOALocations',
        }));
    },

    adminAddTOALocation(userGroup) {
        this.showPage('', new TOALocationEntryView({
            userGroup,
            mode: 'insert',
        }));
    },

    adminModifyTOALocation() {
        const contextKey = store.get('listView-contextKey');
        const model = store.get(`${contextKey}-actionModel`);
        model.context = {
            ...model.context,
            serviceName: TOAConstants.TOALOCATION_DETAIL_SERVICENAME,
            typeCode: TOAConstants.TOALOCATION_TYPECODE,
        };
        const options = {
            mode: 'modify',
            serviceName: TOAConstants.TOALOCATION_DETAIL_SERVICENAME,
            model,
        };
        this.showPage('', new TOALocationEntryView(options));
    },

    adminViewTOALocation() {
        const contextKey = store.get('listView-contextKey');
        const model = store.get(`${contextKey}-actionModel`);
        model.context = {
            ...model.context,
            serviceName: TOAConstants.TOALOCATION_DETAIL_SERVICENAME,
            typeCode: TOAConstants.TOALOCATION_TYPECODE,
        };
        const options = {
            mode: 'view',
            serviceName: TOAConstants.TOALOCATION_DETAIL_SERVICENAME,
            model,
        };
        this.showPage('', new TOALocationEntryView(options));
    },
    adminAchControlTotal(userGroup) {
        this.showPage('', <AdminListView userGroup={userGroup} />);
    },
    addNewPaymentGroupID(userGroupParam) {
        const modifyObj = store.get('modify-PaymentInitiatorGroupID');
        const userGroup = modifyObj ? modifyObj.model.get('USERGROUP') : userGroupParam;
        const isSelectedUserGroup = modifyObj?.isUserGroup || userGroupParam;
        this.showPage('', <AddNewPaymentGroupId
            userGroup={userGroup}
            pigId={modifyObj?.model?.get('PIGID')}
            status={modifyObj?.model?.get('STATUS')}
            nickname={modifyObj?.model?.get('PIGIDNICKNAME')}
            isSelectedUserGroup={isSelectedUserGroup}
        />);
    },

};

workspaceHelper.publishedWidgets.add({
    id: 'ACH_COMPANY_OPTIONS',

    view: ListView.extend({
        initialize(options) {
            const superOptions = {
                menuCategory: 'SETUP',
                serviceName: 'tableMaintenance/clientCompany',
                serviceFunc: null,
                businessType: null,
                context: 'ACH_COMP_MAINT',
            };

            ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        },
    }),

    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'RENAME_BANKACCOUNT',
    view: AccountSettingsWidget,
    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'RENAME_BANKACCOUNT2',
    view: ListView.extend({

        initialize(options) {
            const superOptions = {
                menuCategory: 'BANK_ACC_INFO2',
                serviceName: 'tableMaintenance/billingAccount',
                serviceFunc: null,
                businessType: null,
                context: 'CLIENT_ACCT_NAME2',
            };

            ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        },
    }),
    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'ACH_SETTLEMENT_ACCOUNT',

    view: ListView.extend({
        initialize(options) {
            const superOptions = {
                menuCategory: 'SETUP',
                serviceName: 'tableMaintenance/achSettlementAccount',
                serviceFunc: null,
                businessType: null,
                context: 'ACH_STAC_MAINT',
            };

            ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        },
    }),

    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'TKNMGMT',
    view: ClientTokenManagement,
    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'TKNMGMTUSER',
    view: TokenLockedUsers,
    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'EXPIRINGTOKENS',
    view: ExpiringTokens,
    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'TOKENSAUDIT',
    view: TokensAudit,
    options: {},
});

workspaceHelper.publishedWidgets.add({
    id: 'COMAPNYBANNER',
    view: BannerNotificationTabView,
    options: {},
});

export default withPCM(retObj);
