import { createUseStyles } from '@glu/theming';

export default createUseStyles(() => ({
  root: {
    position: 'relative'
  },
  grid: {
    marginBottom: 60
  },
  footer: {
    bottom: -54
  },
  sticky: {
    '& $footer': {
      bottom: 0,
      position: 'fixed',
      left: ({ withSideNav }) => (withSideNav ? 68 : 0),
      width: ({ withSideNav }) => (withSideNav ? 'calc(100% - 68px)' : null)
    }
  }
}));
