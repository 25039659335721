import locale from '@glu/locale';
import util from '@glu/core/src/util';
import errHandler from 'system/errHandler';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import gridApi from 'common/dynamicPages/api/grid';
import ListView from 'common/dynamicPages/views/workflow/list';
import entitlements from 'common/dynamicPages/api/entitlements';
import ContextApi from 'common/dynamicPages/api/context';
import ContextModel from 'common/dynamicPages/models/context';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import workspaceHelper from 'common/workspaces/api/helper';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import { setGridActionData, getAlertMessage, removeAlertMessage, resetFormState } from 'common/util/reduxUtil';
import template from './stopPaymentGrid.hbs';

const serviceNameStopPayment = '/cminst/placeStop';
const serviceNameCancelStopPayment = '/cminst/cancelStop';

const StopPaymentManagementList = ListView.extend({
    template,

    events: {
        'click [data-hook="refresh-button"]': 'refreshData',
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize(options) {
        this.context = this.contextInfo();
        this.contextDef = ContextApi.menuContext.getContext(this.context.menuCode);
        const superOptions = {
            serviceName: 'cm/smbStopCancels',
            serviceFunc: null,
            businessType: null,
            context: 'SMB_CM_STOP_LIST',
            contextDef: this.contextDef,
            additonalLVCKey: 'SMB_CM_STOP_LIST',
            ...options,
        };

        ListView.prototype.initialize.call(this, superOptions);
        this.localeKey = 'cm.stopList_';
    },

    /**
     * @method updateMobileGridInsertActions
     * @description Display Add Cancel Stop Button based on the value of config parameter in mobile
     */
    updateMobileGridInsertActions() {
        // base insertActions for mobile grid

        const insertActionsWithoutCancel = this.listOptions.insertActions.filter(insertActionItem => insertActionItem.handlerMethodName !== 'insertCancel');

        // If config parameter value is false, replace insert actions value
        if (serverConfigParams.get('showCancelButtonOnStopPayments') === 'false') {
            this.listOptions = {
                ...this.listOptions,
                insertActions: insertActionsWithoutCancel,
            };
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            /**
             * right now we are supporting both bb store and react redux state to contain the
             * success/failure message
             */
            let alertMessage = store.get(`${this.contextKey}-alertMessage`);
            let confirms = store.get(`${this.contextKey}-confirms`);

            const alertMsgContext = getAlertMessage(this.contextKey);
            if (alertMsgContext && Object.keys(alertMsgContext).length) {
                ({ alertMessage, confirms } = alertMsgContext);
            }
            this.renderMessage(alertMessage, confirms);
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
            removeAlertMessage(this.contextKey);
            // clear mdf-react state
            resetFormState();
        } else {
            this.loadViewRequirements();
        }
    },

    contextInfo() {
        const context = {
            actionMode: 'SELECT',
            displayOrder: 3,
            filterId: 20686,
            functionCode: 'INST',
            inquiryId: 20686,
            gridId: 20686,
            menuCategory: 'PAYMENTS',
            menuCode: 'SMB_CM_STOP_LIST',
            menuContext: 'cm/listView/smbStopCancels',
            menuDescription: 'SMB Stop Payments',
            nonDashboardDisplay: 0,
            productCode: 'CM',
            requestParams: '?!_init=true&_productCode=CM&_functionCode=INST&_typeCode=*&_mode=SELECT&inquiryID=20686&_gridId=20686&_filterID=20686',
            serviceName: 'cm/listView/smbStopCancels',
            typeCode: '*',
        };

        return context;
    },

    subOptions(context, contextDef) {
        const options = {
            context,
            contextDef,
            serviceName: 'cm/listView/smbStopCancels',

            // default grid action buttons are hidden
            hideButtons: true,

            // instead of executing context actions, grid handles callback
            enableRowContextButtonCallbacks: true,

            enableSavedViews: true,

            customFormatters: [{
                fieldName: 'AMOUNT',

                format(value) {
                    return value ? `${value}` : '';
                },
            }],

            loadedCallback: () => {
                this.gridLoaded = true;
            },

            lvc: this.lvc,
        };
        options.contextDef.serviceName = 'cm/listView/smbStopCancels';
        return options;
    },

    templateHelpers() {
        const self = this;
        const baseHelpers = {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                return locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
            },

            context() {
                return self.contextKey;
            },
        };

        return util.extend(
            {},
            baseHelpers,
            self.getEntitlementHelpers(),
        );
    },

    getEntitlementHelpers() {
        const self = this;

        return {
            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasRejectEntitlement() {
                return self.hasEntitlement(constants.ACTION_REJECT);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasBulkActionEntitlements() {
                // return true if the user is entitled to perform bulk actions on grid rows
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || self.hasEntitlement(constants.ACTION_REJECT)
                    || self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasInsertStopEntitlement() {
                return self.stopEntitlements[constants.ACTION_INSERT];
            },

            hasInsertCancelEntitlement() {
                return serverConfigParams.get('showCancelButtonOnStopPayments') !== 'false' && self.cancelStopEntitlements[constants.ACTION_INSERT];
            },
        };
    },

    loadViewRequirements() {
        const options = this.subOptions(this.context, this.contextDef);
        const entitlementPromise = entitlements.getEntitlements(options);
        const insertStopEntPromise = this.getStopCancelEntitledActions(serviceNameStopPayment);
        const insertCancelEntPromise = this
            .getStopCancelEntitledActions(serviceNameCancelStopPayment);

        this.gridView = gridApi.createServiceGridView(options);
        ListView.prototype.setupGridAvailableListener.call(this);

        return Promise.all([
            insertStopEntPromise,
            insertCancelEntPromise,
            entitlementPromise,
        ]).then((results) => {
            this.entitlements = results[2].actions;
            this.stopEntitlements = results[0].actions;
            this.cancelStopEntitlements = results[1].actions;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
                this.listenForGridErrors();
                this.setHasLoadedRequiredData(true);
                this.render();
            } else {
                const helpers = this.getEntitlementHelpers();
                return {
                    APPROVE: helpers.hasApproveEntitlement(),
                    UNAPPROVE: helpers.hasUnapproveEntitlement(),
                    DELETE: helpers.hasDeleteEntitlement(),
                    CANCEL: helpers.hasInsertCancelEntitlement(),
                    REJECT: helpers.hasInsertStopEntitlement(),
                };
            }
            return this.entitlements;
        }).then(null, errHandler);
    },

    isPlaceStop(model) {
        return model.get('TYPE') === 'STOP';
    },

    insertStop() {
        const overrideContext = util.extend(
            this.contextDef,
            {
                serviceName: '/cminst/placeStop',
            },
        );
        // store context info for action.  action will unset
        store.set('listView-contextKey', this.contextKey);
        store.set('multiAdd-contextKey', `${this.contextKey}-contextOverride`);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);

        setGridActionData({
            ...overrideContext,
            typeCode: 'STOP',
            mode: 'insert',
            contextKey: this.contextKey,
        });

        this.navigateTo('CM/multiAdd/STOP');
        return Promise.resolve();
    },

    insertCancel() {
        const overrideContext = util.extend(
            {},
            this.contextDef,
            {
                serviceName: '/cminst/cancelStop',
            },
        );
        this.insertOverride(overrideContext);
        return Promise.resolve();
    },

    contextDefinition(serviceName) {
        const contextDef = ContextApi.menuContext.getContext(this.context);

        const stopPayContextModel = this.stopPayContextModel(contextDef);

        contextDef.serviceName = serviceName;
        const stopPayOverrideContext = util.extend(
            contextDef,
            {
                serviceName,
            },
        );

        const stopPayContextKey = stopPayContextModel.getContextKey();

        store.set(`${stopPayContextKey}-contextOverride`, stopPayOverrideContext);
        store.set(`${stopPayContextKey}-listRoute`, '/PAYMENTS/smbManagePayments');
        return stopPayOverrideContext;
    },

    stopPayContextModel(stopPayContextDef) {
        const stopPayContextModel = new ContextModel({
            menuCategory: 'PMTS',
            serviceName: 'cm/smbStopCancels',
            serviceFunc: null,
            businessType: null,
            context: 'SMBCM_STOP_LIST',
            contextDef: stopPayContextDef,
            returnRoute: 'PAYMENTS/smbManagePayments',
        });
        return stopPayContextModel;
    },

    getStopCancelEntitledActions(serviceName) {
        const stopCancelEntContext = new ContextModel({});
        stopCancelEntContext.serviceName = serviceName;
        const stopCancelOptions = {
            context: stopCancelEntContext,
        };
        return entitlements.getEntitlements(stopCancelOptions);
    },

    /**
     * @method gridRowCustomAction
     * @param {object} options - containing the action and the model
     * - This method handles that custom grid actions, in this case CANCELSTOP.
     * - This method should work just like insert but the values in the detail
     * page should be pre populated with the values from the grid row.
     * - The use of cache is unavoidable as the MDF expects the model to be available
     * in the cache for using in the detail page.
     * just like all the methods in the parent file list.js
     */

    gridRowCustomAction(options) {
        let action;
        if (options.action) {
            action = options.action.toUpperCase();
        }

        if (action === 'CANCELSTOP') {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.insertCancel();
        }
        return Promise.resolve();
    },

    gridRowModify(opts) {
        const options = {
            ...opts,
            ...(this.isPlaceStop(opts.model) ? {
                mfeComponent: 'StopPaymentsForm',
            } : {}),
        };
        ListView.prototype.gridRowModify.call(this, options);
    },

    gridRowSelect(opts) {
        const options = {
            ...opts,
            ...(this.isPlaceStop(opts.model) ? {
                mfeComponent: 'StopPaymentsForm',
            } : {}),
        };
        if (options.action === 'select' && options.model.get('SERIALNUM_TO') && options.model.get('SERIALNUM_TO') !== '' && ['Bank Confirmed', 'Rejected', 'Partial Success'].indexOf(options.model.get('STATUS_DESCRIPTION')) > -1) {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('payments/smbStopCancelCheckView');
        } else {
            ListView.prototype.gridRowSelect.call(this, options);
        }
        return Promise.resolve();
    },

});

let list = StopPaymentManagementList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'cm.button_insert_stop',
                entitlement: 'REJECT',
                handlerMethodName: 'insertStop',
            },
            {
                label: 'cm.button_insert_cancel',
                entitlement: 'CANCEL',
                handlerMethodName: 'insertCancel',
            },
        ],
        bulkActions: [
            {
                label: 'sbPayments.approve',
                entitlement: constants.ACTION_APPROVE,
            },
            {
                label: 'sbPayments.unApprove',
                entitlement: constants.ACTION_UNAPPROVE,
            },
            {
                label: 'sbPayments.delete',
                entitlement: constants.ACTION_DELETE,
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'SMBSTOPLIST',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
