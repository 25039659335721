var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Front",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":25}}}))
    + "</p>\n<img class=\"CheckImageView-image\"\n     src=\"data:image/png;base64, "
    + alias2(((helper = (helper = lookupProperty(helpers,"frontImage") || (depth0 != null ? lookupProperty(depth0,"frontImage") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"frontImage","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":47}}}) : helper)))
    + "\"\n     alt=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Front",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":32}}}))
    + "\">\n<p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":5,"column":24}}}))
    + "</p>\n<img class=\"CheckImageView-image\"\n     src=\"data:image/png;base64, "
    + alias2(((helper = (helper = lookupProperty(helpers,"backImage") || (depth0 != null ? lookupProperty(depth0,"backImage") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"backImage","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":46}}}) : helper)))
    + "\"\n     alt=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":31}}}))
    + "\">\n";
},"useData":true});