import { Collection, util } from '@glu/core';

export default Collection.extend({

  initialize(models, options) {
    this.defaultSelectMessage = options.defaultSelectMessage || '';
    this.clearBtn = options.clearBtn || false;
    this.showCancelIcon = util.isBoolean(options.showCancelIcon) ? options.showCancelIcon : true;
  },

  /**
   * Captures key presses on the type ahead input field
   *
   * Certain actions are given functionality here, others are sent to filterCollection to apply the type ahead filter
   *
   * @param {object} selectedList - selected items
   * @param {object} options - passed on to prototype
   */
  reset(selectedList, options) {
    selectedList = selectedList || [];
    // If there are no selected items, show the default message
    if (!selectedList.length) {
      selectedList[0] = {
        name: this.defaultSelectMessage,
        id: null,
        selected: true
      };
    }

    Collection.prototype.reset.call(this, selectedList, options);
  },

  showTooltip() {
    let showTooltip = false;
    this.each((model) => {
      // can't use !model.id , because model.id can be equal 0;
      if (util.isNull(model.id) || util.isUndefined(model.id) || model.id === '') {
        return;
      }
      showTooltip = true;
    });

    return showTooltip;
  }
});
