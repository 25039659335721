import { ItemView } from '@glu/core';
import tmpl from './input.hbs';

export default ItemView.extend({
    template: tmpl,
    tagName: 'td',
    className: 'input-cell',

    events: {
        'change input': 'setValueToModel',
        'keydown input': 'setValueToModel',
    },

    initialize() {
        this.field = this.options.column.get('field');
    },

    templateHelpers() {
        return {
            field: this.field,
            cid: this.model.cid,
            value: this.model.get(this.field),
            maxLength: this.options.maxLength || 50,
            isNumeric: this.options.isNumeric === undefined ? true : this.options.isNumeric,
        };
    },

    onBeforeRender() {
    // if we don't have virtualTray selected than we should clear field.
        if (!this.model.getVirtualTray()) {
            this.model.clearField(this.field);
        }
    },

    setValueToModel(e) {
        const val = this.$(e.currentTarget).val();

        this.model
            .set(this.field, val, {
                // to prevent row re-rendering on input change
                silent: true,
            })
            .trigger('update:tray:mappings');
    },
});
