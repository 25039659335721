var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"section section-container\"\n         data-hook=\"getButtonContainer\">\n    <div class=\"section-body\">\n        <button class=\"btn btn-tertiary\"\n                type=\"button\"\n                data-hook=\"getAddNew\">\n            <span class=\"icon icon-add-circle\"></span>\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Add.New.Note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":52}}}))
    + "\n        </button>\n    </div>\n</section>\n<section class=\"section section-container hidden\"\n         data-hook=\"getFormContainer\">\n    <div class=\"section-body\">\n        <div class=\"row\">\n            <div class=\"form-group col-md-4 required\">\n                <div data-region=\"noteInputRegion\"></div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-12\">\n                <button class=\"btn btn-primary\"\n                        type=\"button\"\n                        data-hook=\"getAddNote\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":43}}}))
    + "\n                </button>\n                <button class=\"btn btn-secondary\"\n                        type=\"button\"\n                        data-hook=\"getCancelNote\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":46}}}))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n</section>\n";
},"useData":true});