import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { createStatefulSwitchView } from 'components/StatefulSwitch/StatefulSwitch';

const MaskToggleUtil = ({
    /**
     * @name initMaskToggle
     * @description sets up the initial properties for the account masking toggle
     * @returns {Object}
     * properties:
     * shouldShowMaskToggle - which indicates if the mask account
     * toggle should appear for the listview
     * maskToggle - initial value (true) for the mask toggle switch
     *
     */
    initMaskToggle() {
        const disableClientAccountMasking = serverConfigParams.get('DisableClientAccountMasking');
        const allowUnmasking = serverConfigParams.get('AllowTempUnmasking');
        const showFullAccount = userInfo.get('allowAccountUnmasking');
        const shouldShowMaskToggle = (disableClientAccountMasking?.toLowerCase() === 'false' && allowUnmasking?.toLowerCase() === 'true' &&
            !showFullAccount);
        const maskToggle = true;
        return {
            shouldShowMaskToggle,
            maskToggle,
        };
    },

    /**
     * @name showMaskToggle
     * @description creates the mask toggle switch and renders it in the region
     * **view MUST contain this region
     * @param {Object} view - backbone view that contains the toggle
     * @param {Object} options - properties of the options are used to create the switch
     * It should include a unique name and htmlId, a labelText, onChange handler and a defaultValue
     * the default values for:
     *  defaultValue is the view's maskToggle property or false if that isn't defined
     *  name and htmlId is a unique string
     *  labelText is the resolved message for 'common.maskAcctNum' app resource
     *  onChange is an empty function
     */

    showMaskToggle(view, options) {
        const name = util.uniqueId('maskToggle');
        const settings = {
            name,
            htmlId: name,
            labelOnLeft: true,
            labelText: locale.get('common.maskAcctNum'), // default label
            onChange: () => {},
            defaultValue: view.maskToggle ?? false,
            ...options,
        };
        const ToggleSwitchView = createStatefulSwitchView(settings);
        const regionToShow = options.region || view.toggleSwitchRegion;
        regionToShow?.show(new ToggleSwitchView());
    },

    /**
     * @name getMobileGridActions
     * @description builds the additional actions group for mobile grid for mask toggle
     * @param {Object} options - object including beneAcctfieldName to use to determine visibility
     * @returns {Object} object fitting the mobile grid actions shape
     */
    getMobileGridActions({ beneAcctFieldName = 'CMB_BENE_ACCOUNT' } = {}) {
        return {
            label: '',
            items: [
                {
                    label: ({ viewInstance }) => {
                        if (viewInstance.maskToggle) {
                            return locale.get('common.unmaskAcctNum');
                        }
                        return locale.get('common.maskAcctNum');
                    },
                    handler: 'toggleMaskColumns',
                    isVisible: ({ columns, viewInstance }) => {
                        if (!viewInstance.shouldShowMaskToggle) {
                            return false;
                        }
                        const hasBeneColumn = !!(columns.find(({ fieldName, hide, hidden }) => (
                            fieldName === beneAcctFieldName && !(
                                typeof hide !== 'undefined' ? hide : hidden
                            )
                        )));
                        return hasBeneColumn;
                    },
                },
            ],
        };
    },
});

export default MaskToggleUtil;
