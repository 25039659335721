var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"login-container\">\n    <div class=\"login-header\">\n        <h1>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"logon.paymentAndCashManagement",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":55}}}))
    + "</h1>\n    </div>\n    <div class=\"login-content\">\n        <div class=\"login-marketing left\"></div>\n        <div class=\"login-marketing right\"></div>\n        <div class=\"login-form-container\">\n            <!-- PCM doesn't use Logo here. Do not use code from DGB during next sync (yet) -->\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"sso-login-message\" style=\"display: none\">\n               SSO Login is in progress..\n            </div>\n            <form autocomplete=\"off\" name=\"login\">\n                \n                <fieldset>\n                    <legend class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"logInTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":44},"end":{"line":17,"column":67}}}))
    + "</legend>\n                    <div class=\"form-group\">\n                        <label for=\"a11y-realm\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"logon.prompt.usergroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":48},"end":{"line":19,"column":83}}}))
    + "</label>\n                        <input id=\"a11y-realm\" data-qa=\"a11y-realm\" type=\"text\" class=\"form-control\" data-bind=\"model\" name=\"realm\" aria-required=\"true\" autocorrect=\"off\" autofocus  />\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"realm\"></span>\n                    </div>\n\n                    <div class=\"form-group\">\n                        <label for=\"a11y-username\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"logon.prompt.userid",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":83}}}))
    + "</label>\n                        <input id=\"a11y-username\" data-qa=\"a11y-username\" type=\"text\" class=\"form-control\" data-bind=\"model\" name=\"username\" aria-required=\"true\" autocorrect=\"off\" />\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"username\"></span>\n                    </div>\n\n                    <div class=\"form-group\">\n                        <label for=\"a11y-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"logon.prompt.password",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":51},"end":{"line":31,"column":85}}}))
    + "</label>\n                        <input id=\"a11y-password\" data-qa=\"a11y-password\" data-qa=\"password\" type=\"password\" class=\"form-control\" data-bind=\"model\" name=\"password\" aria-required=\"true\" />\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"password\"></span>\n                    </div>\n\n                    <div class=\"form-group\">\n                        <button type=\"submit\" id=\"login-action-submit\" name=\"login-action-submit\" data-qa=\"login-action-submit\" class=\"btn btn-primary\" data-disable-on-submit=\"true\">\n                            <span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.signIn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":46},"end":{"line":38,"column":72}}}))
    + "</span>\n                        </button>\n                    </div>\n                </fieldset>\n               \n				<div class=\"form-group\">\n				  <button type=\"button\" id=\"btiqService\" data-action=\"getSSOServiceAuthURL\" class=\"btn btn-link\">\n                     Click to Login with Single Sign-On (SSO)\n                  </button>\n               </div>\n            </form>\n             \n        </div>\n    </div>\n    <div class=\"login-footer\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"logon.needAssistance",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":30},"end":{"line":54,"column":63}}}))
    + "</div>\n</div>\n";
},"useData":true});