import Controller from '@glu/core/src/controller';
import util from '@glu/core/src/util';

const ColumnSumController = Controller.extend({
    initialize(options) {
        /*
         * this takes a CategoryPlanModel as target
         * and an array of Collections of CategoryPlanModels as collectionsToSum
         */
        this.target = options.target;
        this.collectionsToSum = options.collectionsToSum;
        this.subtractOutflows = options.subtractOutflows;
        this.plan = options.plan;
        this.type = options.type;

        // TODO check this.collectionsToSum to ensure that it's an array of collections!
        util.each(this.collectionsToSum, function (collection) {
            this.listenTo(
                collection,
                {
                    change: this.updateTarget,
                    reset: util.debounce(this.updateTarget, 100),
                },
                this,
            );
        }, this);
    },

    updateSums() {
        this.collectionsToSum = this.plan.selected().get(this.type);
        if (!util.isArray(this.collectionsToSum)) {
            this.collectionsToSum = [this.collectionsToSum];
        }
        this.updateTarget();
    },

    getMemoObject() {
        return {
            sum: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
        };
    },

    updateTarget() {
        const memo = this.getMemoObject();

        util.each(this.collectionsToSum, function (collection) {
            if (this.subtractOutflows && collection.type === 'outflow') {
                collection.each((model) => {
                    util.each(memo, (value, key) => {
                        memo[key] = value - model.get(key);
                    });
                });
            } else {
                collection.each((model) => {
                    util.each(memo, (value, key) => {
                        memo[key] = value + model.get(key);
                    });
                });
            }
        }, this);

        this.target.set(memo);
    },
});

export default ColumnSumController;
