import Layout from '@glu/core/src/layout';

const BaseView = Layout.extend({
    ui: {
        form: 'form',
    },

    triggers: {
        submit: 'submit',
    },

    onSubmit() {
        return false;
    },

    focus() {
        this.ui.form.find('input, select, textarea').first().focus();
    },
});

export default BaseView;
