import $ from './$';
import appBus from './appBus';
import util from './util';

const $window = $(window);

$window.on('resize.global', util.debounce((event) => {
  const $target = $(event.target);

  // skip triggering resize event on window for 'ui-resizable' elements
  if ($target.hasClass('ui-resizable')) {
    return false;
  }
  appBus.trigger('resize', event);

  return undefined;
}, 100));

export default $window;

