import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import dialog from '@glu/dialog';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';

export default EntryView.extend({
    initialize(opts) {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminPayment/rtp/incoming',
            serviceFunc: null,
            businessType: null,
            context: 'INCOMING_RTP',
            mode: 'view',
        };

        if (opts.isSubPayment) {
            options.subPaymentContextKey = 'adminPayment_File_getListViewDetails';
        }
        EntryView.prototype.initialize.call(this, options);
    },

    getJSONData() {
        return {
            item: {
                item: transform.hashToPairs({
                    PAYMENT_PRODUCT: this.storedModel.get('PRODUCT'),
                    PAYMENT_FUNCTION: this.storedModel.get('FUNCTION'),
                    PAYMENT_TYPE: this.storedModel.get('TYPE'),
                }),
            },
        };
    },

    loadRequiredData() {
        const self = this;

        this.contextDef.serviceName = 'adminPayment/rtp/incoming';
        this.localeKey = 'adminPayment_';

        const contextKeyToUse = this.options.subPaymentContextKey || this.contextKey;
        DataApi.model.generateModelFromModel(store.get(`${contextKeyToUse}-actionModel`))
            .then((newModelParam) => {
                const newModel = newModelParam;
                newModel.set({
                    FUNCTIONCODE: newModel.get('FUNCTION'),
                    PRODUCTCODE: newModel.get('PRODUCT'),
                    TYPECODE: newModel.get('TYPE'),
                });

                newModel.key = {
                    ...newModel.key,
                    FUNCTIONCODE: 'true',
                    PRODUCTCODE: 'true',
                    TYPECODE: 'true',
                    SUBTYPE: 'true',
                };

                newModel.convertModelAttributesToKeyJSON = util.bind(
                    self.convertModelAttributesToKeyJSON,
                    newModel,
                );

                newModel.fetch({
                    success: () => {
                        this.storedModel = newModel;
                        this.setHasLoadedRequiredData(true);

                        this.buttonList.splice(
                            1,
                            0,
                            {
                                action: 'PRINT',
                            },
                        );

                        this.render();
                    },

                    error: util.bind(errorHandlers.loading, this),
                });
            });
    },

    /*
     * We have to override this method to prevent the read request from having a nested
     * item array
     */
    convertModelAttributesToKeyJSON(model) {
        return this.convertModelAttributesToKeyWithOptContextJSON(model);
    },

    print() {
        const searchFields = [
            {
                fieldName: 'UPPER(INSTRUCTION_ID)',
                operator: 'IN',
                fieldValue: [this.storedModel.get('INSTRUCTION_ID')],
                dataType: 'string',
            },
        ];
        const userGroup = this.storedModel.get('USERGROUP');
        if (userGroup) {
            searchFields.push({
                fieldName: 'USERGROUP',
                operator: 'IN',
                fieldValue: [userGroup],
                dataType: 'string',
            });
        }

        const printModal = new PrintViewModal({
            exportModel: {
                outputFormat: 'PDF',
                pageType: 'LETTER',
                expData: 'transaction',
                detailReportId: 50107,

                actionData: {
                    productCode: 'RTP',
                    functionCode: 'INST',
                    typeCode: '*',
                },
                searchFields,
            },
        });

        dialog.custom(printModal);
    },
});
