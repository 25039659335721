var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "modal-dialog glu-dialog";
},"3":function(container,depth0,helpers,partials,data) {
    return "modal-content";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"modal-header\">\n                <button type=\"button\" class=\"close\" data-action=\"close\" aria-hidden=\"true\">×</button>\n                <h2 class=\"modal-title\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.resetPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":73}}}))
    + "</h2>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <fieldset>\n                <legend class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.resetPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":40},"end":{"line":16,"column":73}}}))
    + "</legend>\n                <div class=\"form-group\">\n                    <label for=\"a11y-new-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.newPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":51},"end":{"line":18,"column":82}}}))
    + "</label>\n                    <input id=\"a11y-new-password\" type=\"password\" data-hook=\"new-password\" class=\"form-control\" data-bind=\"model\" name=\"newPassword\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"newPassword\"></span>\n                </div>\n                <div class=\"form-group\">\n                    <label for=\"a11y-confirm-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.confirmPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":55},"end":{"line":23,"column":90}}}))
    + "</label>\n                    <input id=\"a11y-confirm-password\" type=\"password\" data-hook=\"new-password-confirm\"class=\"form-control\" data-bind=\"model\" name=\"confirmPassword\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"confirmPassword\"></span>\n                </div>\n            </fieldset>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.secretAreYouSureReset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":19},"end":{"line":29,"column":60}}}))
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"password-rules row\">\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.passwordCriteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":19},"end":{"line":34,"column":55}}}))
    + "</p>\n                <ul class=\"password-rule-list\">\n                    <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.passwordMinLength",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":36,"column":61}}}))
    + "</li>\n                    <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.passwordNotDictionaryWord",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":69}}}))
    + "</li>\n                    <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.passwordOneNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":38,"column":61}}}))
    + "</li>\n                    <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.passwordOneLowerCase",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":24},"end":{"line":39,"column":64}}}))
    + "</li>\n                    <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.passwordOneSymbol",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":61}}}))
    + "</li>\n                </ul>\n            </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.resetPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":24},"end":{"line":48,"column":57}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":50,"column":53}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":66}}})) != null ? stack1 : "")
    + "\">\n    <form class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":61}}})) != null ? stack1 : "")
    + "\" autocomplete=\"off\" data-submit=\"change\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"modal-body\">\n            <div class=\"validation-group hide\" role=\"alert\">\n                <p class=\"help-block center\" data-validation=\"generic\"></p>\n            </div>\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"emailResetPassword") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"modal-footer\">\n            <div class=\"form-group\">\n                <button type=\"submit\" id=\"change-password-action-submit\" class=\"btn btn-primary\" data-disable-on-submit>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"emailResetPassword") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":47,"column":20},"end":{"line":51,"column":31}}})) != null ? stack1 : "")
    + "                </button>\n                <button type=\"button\" id=\"change-password-action-cancel\" class=\"btn btn-link\" data-action=\"cancel\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":115},"end":{"line":53,"column":141}}}))
    + "</button>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});