import '../../themeDefaults';

export default {
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',

    '@media (max-width: 767px)': {
      justifyContent: 'center'
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: 50
  },

  mobileButtons: {
    borderRadius: 0,
    flexBasis: '50%',
    paddingLeft: 0
  },

  content: {
    margin: '10px 0 10px 10px',
    height: 'calc(100% - 70px)',
    overflow: 'auto'
  },

  contentWrapper: {
    padding: 0
  }
};
