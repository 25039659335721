import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import ItemView from 'no-override!@glu/core/src/itemView';
import Collection from '@glu/core/src/collection';
import template from './typeaheadSearch.hbs';

function filterableColumns(column) {
    return column.get('filterable') !== false;
}

const FieldSearch = ItemView.extend({
    ui: {
        focusField: 'input[type=text]',
        submitBtn: '[type=submit]',
        fieldSelect: '[name="field-name"]',
        fieldValue: '[name="field-value"]',
        enumSelect: '.search-input-wrapper select',
        removeBtn: 'button[data-control="removeFilter"]',
    },

    events: {
        submit: 'handleSubmit',
        'click @ui.removeBtn': 'handleRemoveFilter',
        'change @ui.focusField': 'getSelectValue',
        'keyup @ui.focusField': 'getSelectValue',
    },

    constructor(opts) {
        ItemView.prototype.constructor.call(this, opts);
        if (opts && opts.model) {
            this.setField(opts.model.get('field'));
        }
        this.first = false;
        if (opts) {
            this.first = opts.first || false;
        }
    },

    disable() {
        this.disabled = true;
        this.setDisabledFromProperty();
    },

    enable() {
        this.disabled = false;
        this.setDisabledFromProperty();
    },

    setDisabledFromProperty() {
        this.ui.focusField.prop('disabled', this.disabled);
        this.ui.submitBtn.prop('disabled', this.disabled);
        this.ui.fieldSelect.prop('disabled', this.disabled);
    },

    toggle() {
        return this.isDisabled() ? this.enable() : this.disable();
    },

    isDisabled() {
        return this.disabled === true;
    },

    focus() {
        util.defer(util.bind(function () {
            if (this.isClosed) {
                return;
            }

            this.ui.focusField.focus();
        }, this));
    },

    serializeData() {
        let items;
        if (this.model && this.collection) {
            items = new Collection(this.collection.filter(filterableColumns));
            const obj = this.model.toJSON();
            obj.items = items.toJSON();
            return obj;
        }
        if (this.collection) {
            items = new Collection(this.collection.filter(filterableColumns));
            return {
                items: items.toJSON(),
            };
        }
        return ItemView.prototype.serializeData.call(this);
    },

    filterBy(field, value) {
        if (this.model.get('field') !== field) {
            return undefined;
        }

        const filter = this.buildFilter(field, value);
        try {
            this.trigger('filter', filter);
        } catch (ex) {
            return false;
        }
        return false;
    },

    handleRemoveFilter() {
        this.trigger('filter:remove', this);
    },

    handleSubmit(e) {
        const form = e.target;
        const field = form['field-name'].value;
        const { value } = form['field-value'];
        const filter = this.buildFilter(field, value);
        try {
            this.trigger('filter', filter);
        } catch (ex) {
            return false;
        }
        return false;
    },

    createFilter() {
        const fieldValue = this.ui.focusField.val() || this.ui.enumSelect.val();
        return this.buildFilter(this.ui.fieldSelect.val(), fieldValue);
    },

    buildFilter(field, value) {
        return {
            title: this.model.get('title'),
            value,
            label: value,
            type: this.model.get('type') || 'string',
            field: field || this.field,
            filter: util.bind(this.filter, this, this.field || field, value),
        };
    },

    onRender() {
        if (this.field && this.collection && this.collection.length > 0) {
            const { field } = this;
            const opt = this.ui.fieldSelect.find(`option[value='${field}']`);
            opt.attr('selected', 'selected');
        }
        // ensure submit is enabled/disabled correctly
        this.getSelectValue();
    },

    setField(field) {
        this.field = field;
        this.className = this.className || 'field-set';
    },

    unsetField() {
        delete this.field;
    },

    filter(field, value, model) {
        return model.get(field) === value;
    },

    getSelectValue() {
        const selection = this.ui.fieldValue.select2('val');
        this.handleSubmitButton(selection);
    },

    handleSubmitButton(selection) {
        this.ui.submitBtn.prop('disabled', !selection);
    },
});

const EnumSearch = FieldSearch.extend({
    events: {
        submit: 'handleSubmit',
    },

    ui: util.extend({
        fieldSelect: '[name="field-name"]',
        formEls: 'input, button',
        focusField: '[name="field-value"]',
    }, FieldSearch.prototype.ui),

    initialize() {
        const enumHash = this.model.get('enumHash');

        this.enumData = util.isFunction(enumHash) ? enumHash() : enumHash;

        if (this.model.get('serverSideEnum')) {
            // if the collection already exists and has models, don't fetch again
            if (enumHash.models && enumHash.length) {
                this.enumData = enumHash.toJSON();
            } else {
                this.listenToOnce(enumHash, 'sync', function (enumCollection) {
                    this.enumData = enumCollection.toJSON();
                });
                enumHash.fetch();
            }
        }
    },

    buildFilter(field, value) {
        // For enums, the label isn't the same as the selected value
        let label;

        if (this.model.get('serverSideEnum')) {
            // with server-side enums, enumHash is a collection
            const enumModel = this.model.get('enumHash').findWhere({
                value,
            });
            if (enumModel) {
                label = enumModel.get('label');
            }
        } else {
            // with non-server-side enums, enumData is a name: value object
            label = util.findKey(this.enumData, v => v === value);
        }

        const result = {
            title: this.model.get('title'),
            value,
            label: label || value,
            type: 'typeahead',
            field: field || this.field,
            filter: util.bind(this.filter, this, this.field || field, value),
            enumHash: this.model.get('enumHash'),
            serverSideEnum: this.model.get('serverSideEnum'),

            // this is only applied to the filter for enum types
            format: this.model.get('format'),
        };

        /*
         * if a formatter is defined, run it with the correct arguments
         * this would be a good place to call locale(), if needed
         */
        if (util.isFunction(this.model.get('format'))) {
            const formatFunc = this.model.get('format');
            result.label = this.model.get('serverSideEnum') ? formatFunc(value, this.model) : formatFunc(value);
        }

        return result;
    },

    handleSubmit(e) {
        e.preventDefault();

        const form = e.target;
        const field = form['field-name'].value;
        const { value } = form['field-value'];
        const filter = this.buildFilter(field, value);
        try {
            this.trigger('filter', filter);
        } catch (ex) {
            return false;
        }
        return false;
    },

    templateHelpers() {
        const selectedValue = this.model.get('value');

        return {
            /*
             * enumHash can be in 2 different formats
             * see note in buildFilter
             * handled in template based on value of serverSideEnum
             */
            enumHash: this.enumData,

            name: this.model.get('field'),

            getSelected(value) {
                return (value === selectedValue) ? 'selected' : '';
            },
        };
    },
});

export default EnumSearch.extend({
    template,

    ui: util.extend(
        {},
        EnumSearch.prototype.ui,
        {
            enumSelect: 'input[name="field-value"]',
        },
    ),

    events: util.extend(
        {},
        EnumSearch.prototype.events,
        {
            'change @ui.enumSelect': 'getSelectValue',
        },
    ),

    onRender(...args) {
        const lookup = this.model.get('enumHash');
        let rowsPerPage = 1;
        const self = this;

        this.ui.enumSelect.comboBox({
            width: 300,

            data() {
                const ret = [];
                if (self.model.get('value') && self.model.get('label')) {
                    ret.push({
                        id: self.model.get('value'),
                        text: self.model.get('label'),
                    });
                }
                return ret;
            },

            initSelection(element, callback) {
                let text = '';
                const id = element.val();

                if (self.model.get('enumHash')) {
                    const model = self.model.get('enumHash').findWhere({
                        value: id,
                    });
                    if (model) {
                        text = model.get('label');
                    }
                }
                callback({
                    text,
                    id,
                });
            },

            query: util.debounce((query) => {
                lookup.setStartRow((((query.page - 1) * rowsPerPage) + 1));
                rowsPerPage = (query.term.length < constants.COMBO_MIN_CHARS)
                    ? constants.COMBO_MIN_SIZE : constants.MAX_SERVER_ROWSPERPAGE;
                lookup.setPageSize(rowsPerPage);

                lookup.setSearch(query.term);

                lookup.fetch().then((result) => {
                    query.callback({
                        results: lookup.toJSON(),
                        more: ((query.page * rowsPerPage) < result.inquiryResponse.totalRows),
                    });
                });
            }, constants.COMBO_DEBOUNCE),
        });

        if (this.model.get('value')) {
            this.ui.enumSelect.comboBox('val', this.model.get('value'));
        }

        // handle disabling the submit button
        EnumSearch.prototype.onRender.apply(this, args);
    },
});
