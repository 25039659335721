export default {
    conversationHeader: {
        fontWeight: 'bold',
        marginBottom: '20px',
        paddingBottom: '20px',
        boxShadow: '2px 10px 10px rgba(0,0,0,0.2)',
        position: 'sticky',
        background: '#37435c',
        top: '0px',
        zIndex: '100',
        '& h2': {
            padding: '0 20px',
            color: '#fff',
            textTransform: 'uppercase',
        },
    },
    conversationHeaderRow: {
        paddingBottom: '0px',
        color: '@c-firm-80',
    },

    conversationHeaderColumnLabel: {
        color: '#ccc',
        display: 'inline-block',
        marginBottom: '10px',
        fontSize: '14px',
        fontWeight: 'normal',
        padding: '0',
        paddingLeft: '25px',
        paddingRight: '10px',
        width: '30%',
    },

    conversationHeaderColumnValue: {
        color: '#fff',
        display: 'inline-block',
        margin: '0',
        padding: '0',
        fontSize: '14px',
        fontWeight: 'normal',
        width: '70%',
        wordBreak: 'break-word',
        wordWrap: 'break-word',
    },

    conversationHeaderCurrency: {
        marginLeft: '5px',
    },

    headerButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '55px',
        marginTop: '-5px',
        paddingRight: '20px',
        '& button': {
            color: '#fff',
            fontSize: '20px',
            padding: '0 0 0 5px',
            margin: '0',
            '&:hover': {
                textDecoration: 'none',
                color: '#fff',
            },
        },
    },
};
