var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n        "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"btn btn-tertiary","action":"delete","locale":"cashflow.groupplan.delete.line.item","name":"close"},"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":22,"column":119}}}))
    + "\n</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<td>\n        <input type=\"hidden\" class=\"comboBox\" data-bind=\"model\" name=\"ASSIGNEDTO\" id=\"ASSIGNEDTO_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":97},"end":{"line":3,"column":103}}}) : helper)))
    + "\" data-filter-immediately>\n</td>\n<td>\n        <label for=\"subplan-name-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":39}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.plan.name.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":94}}}))
    + "</label>\n        <input data-bind=\"model\" name=\"subPlanName\" id=\"subplan-name-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":7,"column":69},"end":{"line":7,"column":75}}}) : helper)))
    + "\" class=\"form-control\">\n</td>\n<td>\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":18}}}) : helper)))
    + "\n</td>\n<td>\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"savedOn") || (depth0 != null ? lookupProperty(depth0,"savedOn") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"savedOn","hash":{},"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":15}}}) : helper)))
    + "\n</td>\n<td>\n        <label for=\"note-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":16,"column":25},"end":{"line":16,"column":31}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":75}}}))
    + "</label>\n        <input data-bind=\"model\" name=\"note\" id=\"note-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":54},"end":{"line":17,"column":60}}}) : helper)))
    + "\" class=\"form-control\">\n</td>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":0},"end":{"line":24,"column":11}}})) != null ? stack1 : "");
},"useData":true});