const transformDetails = details => Object.keys(details).map(item => ({
    [item]: details[item],
})).reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    return {
        ...acc,
        [key]: curr[key],
    };
}, {});

function getEntitlementsOptions(typeCode) {
    return {
        typeCode,
        productCode: 'SMAINT',
        functionCode: 'MAINT',
        entryMethod: 0,
        overrideContext: {
            serviceName: '/userMaintenance/user',
        },
    };
}

export const workflowConfigEntitlementsOptions = getEntitlementsOptions('IOPAYCFG');

export const workflowEntitlements =
    [workflowConfigEntitlementsOptions];

export { transformDetails };
