import ViewItem from 'app/smbPayments/views/viewItem';
import constants from 'app/smbPayments/views/employees/api/constants';
import store from 'system/utilities/cache';

export default ViewItem.extend({
    initialize(opts) {
        store.set('current-workspace-route', opts.lastFragment);

        this.context = {
            payContext: 'SMB_EMPLOYEE',
            tempContext: 'SMB_TEMP_EMPLOYEE',
            returnTabStoreName: 'employee_smb-currentTab',
            paymentContextStoreName: 'payment_listView_smbEmployee-contextOverride',
            templateContextStoreName: 'template_listView_smbEmployee-contextOverride',
            modifyURL: 'payments/modifyEmpPayment',
            copyAsPaymentURL: 'templates/copyEmpAsPayment',
            copyPaymentAsPaymentURL: 'payments/copyPaymentFromEmpPayment',
            copyPaymentAsTemplateURL: 'templates/copyEmployeePaymentAsTemplate',
        };

        const options = {
            ...constants.options,
            ...opts,
            mode: 'view',
        };
        ViewItem.prototype.initialize.call(this, options);
    },
});
