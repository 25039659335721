import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import constants from 'common/dynamicPages/api/constants';
import systemConfig from 'system/configuration';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './auditHistoryList.hbs';

const AuditHistoryList = ListView.extend({
    template,

    // add event for print
    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'viewDetailAuditReport',
        },
    ),

    /**
     *
     * @param {object} options - must include functionCode, tnum and type
     */
    initialize(options) {
        this.setup({
            ...options,
            additonalLVCKey: `AUDIT_HISTORY_${options.type}`,
        });
        this.suppressSavedViews = true;
        this.disableFilters = true;
        this.skipMobileGridAlertRegion = true;
    },

    setup(options) {
        let opts = {};
        if (options.optsAlreadyBuilt) {
            ({ opts } = options);
            opts.prebuiltOptions = true;
        } else {
            let searchFields = (options.searchFields)
                ? this.populateSearchFields(options.searchFields, options.detailModel)
                : [
                    {
                        operator: 'EQ', dataType: 'text', fieldName: 'TNUM', fieldValue: [options.tnum],
                    },
                ];

            if (options.functionCode === 'REQUEST' && options.type === 'REQIN') {
                searchFields = [
                    {
                        operator: 'EQ', dataType: 'text', fieldName: 'MESSAGE_ID', fieldValue: [options.detailModel.get('MESSAGE_ID')],
                    },
                ];
                if (systemConfig.isAdmin) {
                    searchFields = [
                        ...searchFields,
                        {
                            operator: 'EQ', dataType: 'text', fieldName: 'USERGROUP', fieldValue: [options.detailModel.get('USERGROUP')],
                        },
                    ];
                }
            }
            let incProductCode;
            let incFunctionCode;
            let incTypeCode;
            let incEntryMethod = options.entryMethod || options.detailModel?.get('ENTRYMETHOD');
            if (options.productCode === 'CLM') {
                incProductCode = options.productCode;
                incFunctionCode = options.functionCode;
                incTypeCode = options.type;
            } else {
                incProductCode = options.productCode;
                incFunctionCode = options.functionCode;
                incTypeCode = options.typeCode || options.type;
                const isAnyEmpty = util.isEmpty(incProductCode) || util.isEmpty(incFunctionCode)
                    || util.isEmpty(incTypeCode);
                if (isAnyEmpty && options.detailModel?.context?.actionContext) {
                    incProductCode = options.detailModel.context.actionContext.productCode;
                    incFunctionCode = options.detailModel.context.actionContext.functionCode;
                    incTypeCode = options.detailModel.context.actionContext.typeCode;
                    incEntryMethod = options.detailModel.context.actionContext.entryMethod;
                    if (incProductCode === 'FEATURES' && incFunctionCode === 'MAINT' && incTypeCode === 'COMMDCHD') {
                        incTypeCode = 'COMMAND';
                    }
                }
            }

            searchFields = [
                ...searchFields,
                {
                    operator: 'EQ', dataType: 'text', fieldName: 'TYPE', fieldValue: [incTypeCode],
                },
                {
                    operator: 'EQ', dataType: 'text', fieldName: 'FUNCTION', fieldValue: [incFunctionCode],
                },
                {
                    operator: 'EQ', dataType: 'text', fieldName: 'PRODUCT', fieldValue: [incProductCode],
                },
            ];
            if (incEntryMethod) {
                searchFields.push({
                    operator: 'EQ', dataType: 'text', fieldName: 'ENTRYMETHOD', fieldValue: [incEntryMethod],
                });
            }

            // PCM RELATED
            const serviceName = this.isCheckPayment(options)
                ? constants.URL_CHECKDETAILAUDITLIST
                : options.serviceName || constants.URL_DETAILAUDITLIST;

            const context = this.isCheckPayment(options)
                ? constants.CHECKDETAILAUDIT_CONTEXT : constants.DETAILAUDIT_CONTEXT;
            // PCM RELATED ABOVE

            opts = {
                serviceName, // PCM
                serviceFunc: null,
                businessType: null,
                hideGridActionButtons: true,
                stickyFooter: options.stickyFooter,
                context, // PCM
                enableSavedViews: false,
                additionalSearchFields: searchFields,
                filter: false,
                selector: 'none',
                additonalLVCKey: options.additonalLVCKey,
                auditExportInquiryId: options.auditExportInquiryId,
                includeDefaultIcons: options.includeDefaultIcons,
            };

            if (options.id) {
                let listOfNameValuePairs = [
                    {
                        name: 'productCode', value: options.productCode,
                    },
                    {
                        name: 'functionCode', value: options.functionCode,
                    },
                    {
                        name: 'typeCode', value: options.type,
                    },
                    {
                        name: 'subType', value: options.subType ? options.subType : '*',
                    },
                    {
                        name: 'fieldName', value: options.id,
                    },
                ];
                if (options.surrogateTnum) {
                    listOfNameValuePairs = [
                        ...listOfNameValuePairs,
                        {
                            name: 'SURROGATETNUM', value: options.surrogateTnum,
                        },
                    ];
                }
                opts.requestParameters = {
                    item: listOfNameValuePairs,
                };
            }
        }

        this.detailModel = options.detailModel;

        ListView.prototype.initialize.call(this, opts);
    },

    populateSearchFields(searchFields, model) {
        const returnArray = [];
        util.each(searchFields, (field) => {
            const searchField = {
                operator: field.operator,
                dataType: field.dataType,
                fieldName: field.fieldName,
                fieldValue: [model.get(field.fieldName)],
            };
            returnArray.push(searchField);
        });
        return returnArray;
    },

    // PCM RELATED
    isCheckPayment(options) {
        const { detailModel } = options;
        const { context } = detailModel;
        if (detailModel && context && context.actionContext) {
            return constants.CHECK_PRODUCT_CODE === context.actionContext.productCode;
        }
        return false;
    },

    determineTnum(options) {
        // This function determines TNUM which is used to get audit data of specific payment,
        // tnum as empty string will retrieve audit data of all check payments that is why it
        // needs to be replaced with -1
        if (this.isCheckPayment(options)) {
            return options.tnum ? options.tnum : '-1';
        }
        return options.tnum;
    },
    // PCM RELATED ABOVE

    /**
     * @description print button event handler
     * This handler will display the audit history report in a modal.  It uses
     * printExportUtil viewAuditHistor
     * (note: list.js which this extends, extends from printExportUtil)
     * @param {object} evt - event object
     */
    viewDetailAuditReport(evt) {
        evt.stopPropagation();
        this.viewAuditHistory(this.detailModel);
    },

    /**
     * @name buildExportModel
     * @description override base class method to typecode, viewId and remove
     * the columns
     * @param {object} options
     * @returns {object} exportModel
     */
    buildExportModel(options) {
        const exportModel = ListView.prototype.buildExportModel.call(this, options);
        // update the typecode and viewId for the detail audit export
        if (this.detailModel.get('TYPE') === 'REQIN') {
            exportModel.actionData.typeCode = constants.DETAILEXTRACT_RFPAUDIT;
            exportModel.viewId = constants.DETAIL_RFPAUDIT_EXPORT_VIEW_ID; // '16307';
        } else {
            exportModel.actionData.typeCode = constants.DETAILEXTRACT_AUDIT;
            exportModel.viewId = constants.DETAIL_AUDIT_EXPORT_VIEW_ID; // '16306';
        }
        const auditSectionField = util.find(this.detailModel.fieldData, fieldData => fieldData.name === 'AUDITSECTION');
        if (auditSectionField) {
            exportModel.inquiryId = auditSectionField.inquiryId;
            if (exportModel.inquiryId === 8807) {
                exportModel.viewId = '-1'; // Override VIEWID since the hardcoded one above doesn't apply to Feature Control Audit list
            }
        }
        /*
         * Sometimes we need to override the inquiryId for the auditHistory list view.
         * This is done by passing auditExportInquiryId when initializing the view
         */
        if (this.options.auditExportInquiryId) {
            exportModel.inquiryId = this.options.auditExportInquiryId;
        }

        // don't send up listview columns. let the server use the columns from the viewId
        delete exportModel.columns;
        return exportModel;
    },

    /**
     * @description get the product code for the report model, default to RTGS
     * @param {object} model
     */
    getProductCode(model) {
        let productCode = '';
        switch (model.get('TYPE')) {
        case 'REQIN':
        case 'REQOUT':
            productCode = 'RTP';
            break;
        default:
            productCode = 'RTGS';
        }
        return productCode;
    },

    /**
     * @description get the report id for the report model, default to 20300
     * @param {object} model
     */
    getReportId(model) {
        const typeCode = model.get('TYPE');

        let reportId = '';

        switch (model.get('FUNCTION')) {
        case 'TMPL':
        case 'BHTMPL':
            reportId = '20310';
            break;
        case 'REQUEST':
            if (typeCode === 'REQIN') {
                reportId = '20320';
            } else if (typeCode === 'REQOUT') {
                reportId = '20301';
            } else {
                reportId = '20300';
            }
            break;
        case 'REQTMPL':
            reportId = '20311';
            break;
        default:
            reportId = '20300';
        }

        return reportId;
    },

    templateHelpers() {
        const defaultIcons = this.options.includeDefaultIcons === undefined
            ? true : this.options.includeDefaultIcons;
        return util.extend(
            {},
            ListView.prototype.templateHelpers.call(this),
            {
                gridUtilityOptions: {
                    hasRefresh: false,
                    includeDefaultIcons: defaultIcons,
                },
            },
        );
    },
});

let list = AuditHistoryList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        mountSelector: '.ui-widget',
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
