var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"checkbox\" name=\"manageAction\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableManage") || (depth0 != null ? lookupProperty(depth0,"disableManage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableManage","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":60}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"manageAction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":61},"end":{"line":1,"column":95}}})) != null ? stack1 : "")
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"manageLabel") || (depth0 != null ? lookupProperty(depth0,"manageLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manageLabel","hash":{},"data":data,"loc":{"start":{"line":1,"column":108},"end":{"line":1,"column":123}}}) : helper)))
    + "\">\n<input type=\"checkbox\" name=\"approveAction\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableApprove") || (depth0 != null ? lookupProperty(depth0,"disableApprove") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableApprove","hash":{},"data":data,"loc":{"start":{"line":2,"column":44},"end":{"line":2,"column":62}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"approveAction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":98}}})) != null ? stack1 : "")
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"approveLabel") || (depth0 != null ? lookupProperty(depth0,"approveLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"approveLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":111},"end":{"line":2,"column":127}}}) : helper)))
    + "\">\n<input type=\"checkbox\" name=\"repairAction\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableRepair") || (depth0 != null ? lookupProperty(depth0,"disableRepair") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableRepair","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":60}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"repairAction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":3,"column":95}}})) != null ? stack1 : "")
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"repairLabel") || (depth0 != null ? lookupProperty(depth0,"repairLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"repairLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":108},"end":{"line":3,"column":123}}}) : helper)))
    + "\">\n<input type=\"checkbox\" name=\"modifyAction\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableModify") || (depth0 != null ? lookupProperty(depth0,"disableModify") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableModify","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":60}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifyAction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":95}}})) != null ? stack1 : "")
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"modifyLabel") || (depth0 != null ? lookupProperty(depth0,"modifyLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modifyLabel","hash":{},"data":data,"loc":{"start":{"line":4,"column":108},"end":{"line":4,"column":123}}}) : helper)))
    + "\">";
},"useData":true});