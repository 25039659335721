var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3><strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"lockbox") || (depth0 != null ? lookupProperty(depth0,"lockbox") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lockbox","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":23}}}) : helper)))
    + " "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.Batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":46}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"batch") || (depth0 != null ? lookupProperty(depth0,"batch") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"batch","hash":{},"data":data,"loc":{"start":{"line":1,"column":47},"end":{"line":1,"column":56}}}) : helper)))
    + "</strong></h3>\n<div class=\"lbxAlert\">\n    <div class=\"lbxAddAlert\"></div>\n    <div class=\"lbxRemoveAlert\"></div>\n</div>\n<div class=\"lbxAdd\">\n    <div class=\"field-container\">\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n                <button type=\"button\" class=\"btn btn-link export lbx-another-note\" data-action=\"toggleForm\"><span class=\"icon-add-circle\"></span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.AddAnotherNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":146},"end":{"line":10,"column":177}}}))
    + "</button>\n            </div>\n        </div>\n        <div class=\"lbxNoteForm\">\n            <div class=\"form-group lockbox-addNote\">\n                <label for=\"NOTE_TEXT\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.TransactionNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":39},"end":{"line":15,"column":71}}}))
    + "</label>\n                <textarea id=\"NOTE_TEXT\" class=\"form-control\" maxlength=\"400\" name=\"NOTE_TEXT\" data-bind=\"model\"></textarea>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"NOTE_TEXT\"></span>\n            </div>\n            <div>\n                <button type=\"button\" class=\"btn btn btn-secondary\" data-action=\"addNote\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.AddNote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":90},"end":{"line":20,"column":114}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});