import Layout from '@glu/core/src/layout';

export default Layout.extend({
    /**
     * Check the length of accounts, set the model if only one, then render the
     * accountInputView
     */
    receiveAccounts() {
        if (this.hasOneItem(this.accounts)) {
            this.updateModelWithFirstItem(this.accounts, this.model, 'ACCOUNTNUM');
        }
        this.accountInputView.render();
    },

    /**
     * Check the collection to determine if it has exactly one item
     * @param {Object} collection - collection to check the length of
     * @returns {boolean} - whether the collection has exactly one item
     */
    hasOneItem(collection) {
        return collection.length === 1;
    },

    /**
     * Set the model attribute with the the first in the collection
     * @param {Collection} collection - collection to evaluate
     * @param {Model} model - model consuming the collection item
     * @param {string} attribute - attribute to set
     */
    updateModelWithFirstItem(collection, model, attribute) {
        const item = collection.at(0);
        model.set(attribute, item.get(attribute));
    },

    /**
     * @abstract
     */
    checkForSingleItem() {},
});
