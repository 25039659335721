import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import gridApi from 'common/dynamicPages/api/grid';
import Model from '@glu/core/src/model';
import errHandler from 'system/errHandler';
import entitlements from 'common/dynamicPages/api/entitlements';
import CombinedPaymentModal from 'app/smbPayments/views/modals/combinedPayment';
import CombinedCollectionModal from 'app/smbPayments/views/modals/combinedCollection';
import PaymentTypeCollection from 'app/smbPayments/collections/paymentTypes';
import ContextApi from 'common/dynamicPages/api/context';
import store from 'system/utilities/cache';
import moment from 'moment';
import constants from 'common/dynamicPages/api/constants';
import smbConstants from 'app/smbPayments/constants';
import ContextModel from 'common/dynamicPages/models/context';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import userInfo from 'etc/userInfo';
import ConversationView from 'app/payments/views/realTimePayments/rtpConversationView';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import { getCurrentRoute } from 'system/utilities/routeHelper';
import AlertDetails from './../alerts/paymentAlertDetails';
import template from './paymentGrid.hbs';

const AddPaymentModel = Model.extend({
    validators: {

    },
});

function filterDate(field, value, model) {
    const d = moment(model.get(field));
    const valObj = moment(value);
    return d.isAfter(valObj);
}

const PaymentManagementList = ListView.extend({
    template,
    loadingTemplate,

    ui: {
        $popovers: '[data-toggle="popover"]',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="collect-button"]': 'collect',
            'click [data-hook="taxInsert-button"]': 'taxInsert',
            'click [data-hook="fileimport-button"]': 'fileImport',
        },
    ),

    appEvents: {
        'payment:newPayment:gridAlert': 'renderPaymentAlertContent',
    },

    initialize(options) {
        this.context = options;
        this.model = new AddPaymentModel();
        this.gridLoaded = false;
        this.isPaymentView = options.isPaymentView;

        const superOptions = {
            ...options,
            menuCategory: 'PMTS',
            serviceName: this.context.serviceName,
            serviceFunc: null,
            businessType: null,
            context: this.context.context,
            returnRoute: getCurrentRoute(),
            viewId: options.viewId,
        };

        // Why is this a different return route when coming from notifications widget
        if (options.viewId) {
            superOptions.returnRoute = 'PAYMENTS/listSmbPayments';
        }

        superOptions.enableSavedViews = true;
        ListView.prototype.initialize.call(this, superOptions);
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        if (this.hasLoadedRequiredData()) {
            this.model.set('selection', 'newPayment');
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
            this.updateRefreshTimestamp();
        } else {
            this.loadViewRequirements();
        }
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                return locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
            },

            context() {
                return self.contextKey;
            },

            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT) && (userInfo.get('mobileInitiation') === 1 || !userInfo.has('mobileAppPlatform')) && self.isPaymentView;
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasRejectEntitlement() {
                return self.hasEntitlement(constants.ACTION_REJECT);
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasBulkActionEntitlements() {
                // return true if the user is entitled to perform bulk actions on grid rows
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || self.hasEntitlement(constants.ACTION_REJECT)
                    || self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasTaxPaymentEntitlement() {
                return self.hasTaxEntitlements() && self.isPaymentView;
            },

            hasInsertCollectionEntitlements() {
                return self.hasCollectionEntitlements() && self.isPaymentView;
            },
        };
    },

    updateMaxDaysMsg() {
        const maxDaysMsg = this.gridView.getResponseParameter('PAYMAXDAYS');
        if (maxDaysMsg) {
            this.$('.maxDaysMessage').text(maxDaysMsg);
            this.$('.icon-info').show();
        } else {
            this.$('.maxDaysMessage').hide();
            this.$('.icon-info').hide();
        }
    },

    loadViewRequirements() {
        const options = this.contextInfo();
        const entitlementPromise = entitlements.getEntitlements(options);
        const paymentTypePromise = this.paymentTypePromise();
        this.gridView = gridApi.createServiceGridView(util.extend(options, { lvc: this.lvc }));
        ListView.prototype.setupGridAvailableListener.call(this);

        return Promise.all([paymentTypePromise, entitlementPromise])
            .then((results) => {
                [this.paymentTypes] = results;
                this.entitlements = results[1].actions;
                this.setHasLoadedRequiredData(true);
                if (!mobileUtil.isMobileGridEnabled()) {
                    this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
                    this.listenForGridErrors();
                    this.render();
                    this.listenTo(this.gridView, 'gridapi:loaded', this.updateMaxDaysMsg);
                } else {
                    this.contextDef = this.context;
                }
                return this.entitlements;
            })
            .then(null, errHandler);
    },

    paymentTypePromise() {
        return new Promise((resolve, reject) => {
            const paymentTypes = new PaymentTypeCollection({
                functionCode: this.isPaymentView ? 'INST' : 'TMPL',
                inquiryId: this.isPaymentView ? 20011 : 20095,
            }, {
                base: this.isPaymentView ? 'payments' : 'paymentTemplates',
            });
            paymentTypes.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    hasTaxEntitlements() {
        let hasPermission = false;
        util.each(this.paymentTypes.models, (model) => {
            if (model.get('typeCode') === smbConstants.TAX) {
                hasPermission = true;
            }
        });
        return hasPermission;
    },

    hasCollectionEntitlements() {
        let hasPermission = false;
        util.each(this.paymentTypes.models, (model) => {
            const paymentTypeCode = model.get('typeCode');
            if (paymentTypeCode === smbConstants.CORPORATECOLLECTION
                || paymentTypeCode === smbConstants.CONSUMERCOLLECTION) {
                hasPermission = true;
            }
        });
        return hasPermission;
    },

    contextInfo() {
        this.contextDef = {
            /*
             * This is a bit confusing. Sometimes the context is set as an object with
             * context, sometimes just as string. Always use the context from the object
             * first unless it is not set.
             */
            ...ContextApi.menuContext.getContext(this.context.context || this.context),
            serviceName: this.context.serviceName,
            actionContext: {},
        };
        const options = {
            context: this.context,
            contextDef: this.contextDef,
            serviceName: this.contextDef.serviceNam,
            enableSavedViews: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            // instead of executing context actions, grid handles callback
            enableRowContextButtonCallbacks: true,
            loadedCallback: () => {
                this.gridLoaded = true;
            },
        };
        if (this.options.viewId) {
            options.viewId = this.options.viewId;
        }
        return options;
    },

    buildExportModel(format) {
        /*
         * Payment Grid  is an Inquiry based list view, so we need to provide this
         * info to export
         */
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = this.isPaymentView
            ? constants.INQUIRY_ID_20104 : constants.INQUIRY_ID_20597;
    },

    /**
     * Pull the key list from the gridView and return an array of search fields
     * based on the row data
     * @returns {[{fieldName: string, dataType: string, fieldValue: string[], operator: string}]}
     */
    addAdditionalSearchFields() {
        return [{
            fieldName: 'TYPE',
            operator: 'IN',
            fieldValue: ['FEDWIRE', 'INTL', 'BDACHCVP', 'BDACHCP', 'BDACHTP', 'BDACHCRC', 'CRTRAN', 'BDACHCEC'],
            dataType: 'text',
        }];
    },

    addDateFilter(days, label, type) {
        const date = moment(new Date()).subtract(days, 'day');
        const dateStr = date.format(userInfo.getDateFormat());

        this.gridView.grid.filterProc.addFilter({
            value: dateStr,
            label,
            field: 'CMB_VALUE_DATE',
            type,
            filter: util.bind(filterDate, this, 'CMB_VALUE_DATE', dateStr),
        });
    },

    hasEntitlement(action) {
        if (this.entitlements[action] && this.entitlements[action] === true) {
            return true;
        }
        return false;
    },

    insert() {
        const modal = new CombinedPaymentModal();
        this.listenToOnce(modal, 'smbPayments:paymentAdded', this.paymentCreated);
        dialog.open(modal);
    },

    collect() {
        const modal = new CombinedCollectionModal();
        this.listenToOnce(modal, 'smbPayments:paymentAdded', this.paymentCreated);
        dialog.open(modal);
    },

    paymentCreated() {
        this.gridView.refreshGridData();
    },

    taxInsert() {
        const context = {
            actionMode: 'SELECT',
            displayOrder: 1,
            filterId: 20104,
            functionCode: 'INST',
            inquiryId: 20104,
            gridId: 20104,
            menuCategory: 'payments',
            menuContext: 'payment/listView/smb',
            menuDescription: 'Bills & Payments',
            nonDashboardDisplay: 0,
            productCode: 'PAY',
            requestMappings: 'smbManagePayments',
            requestParams: '?!_init=true&_productCode=PAY&_functionCode=INST&_typeCode=*&_mode=SELECT&inquiryID=20104&_gridId=20104&_filterID=20104',
            typeCode: '*',
        };

        const paymentsContextDef = ContextApi.menuContext.getContext(context);

        const paymentsContextModel = new ContextModel({
            menuCategory: 'PMTS',
            serviceName: 'payments/smb',
            serviceFunc: null,
            businessType: null,
            context,
            contextDef: paymentsContextDef,
            returnRoute: 'PAYMENTS/smbManagePayments',
        });
        context.serviceName = '/batch/TaxPayments';
        context.subType = 'NACHA';
        context.serviceNameTypeCode = 'BDACHTP';
        context.enableSaveDraft = true;
        this.model.set('context', context);

        const taxPaymentOverrideContext = util.extend(paymentsContextDef, this.model.get('context'));
        const taxPaymentsContextKey = paymentsContextModel.getContextKey();

        store.set(`${taxPaymentsContextKey}-contextOverride`, taxPaymentOverrideContext);
        store.set(`${taxPaymentsContextKey}-listRoute`, '/PAYMENTS/smbManagePayments');
        this.insertOverride(taxPaymentOverrideContext);
    },

    renderPaymentAlertContent(model, message, confirmResponse, options = {}) {
        /*
         * NH-70767 - in chained actions scenarios, the server returns 200 but it if
         * the chained action failed, it should be treated as an error. the confirmResponse
         * will have that info.
         */
        let confirm;
        let successCount;
        let failCount;
        let totalCount;
        let success;
        let chainedAction;
        let alertType = 'success';
        if (!util.isNullOrUndefined(confirmResponse)) {
            confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
            successCount = confirm ? confirm.totalSuccess : 0;
            failCount = confirm ? confirm.totalFail : 0;
            totalCount = failCount + successCount;
            success = successCount === totalCount;
            chainedAction = confirm ? confirm.chainedAction : false;
            alertType = success ? 'success' : 'danger';
            if (!success || chainedAction) {
                if (confirm && confirm.confirmResults && confirm.confirmResults.length > 0) {
                    model.set(
                        'detailMessage',
                        confirm.confirmResults[0].messages[0],
                        {
                            silent: true,
                        },
                    );
                }
            }
        }
        // create the details view for the alert
        const paymentDetails = new AlertDetails({
            model,
        });

        // display notification message
        this.alertView = alert[alertType](
            message,
            {
                details: paymentDetails,
                canDismiss: options.canDismiss ?? false,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    // overriding gridRow methods of ListView
    gridRowSelect(options) {
        // Some types (RTP/Tax Payments) use the standard detail screen and not the SMB modal
        if (this.useStandardDetailScreen(options)) {
            if (options.model.get('PRODUCT') === 'RTP') {
                const conversationStarted = options.model.get('MESSAGE_STATE') > 1;
                if (conversationStarted) {
                    // create the view passing in the model
                    const rtpConversationData = {
                        view: ConversationView,
                        shouldBeOpen: false,
                        showTab: true,
                        viewOptions: {
                            model: options.model,
                            conversationStarted,
                            defaultOpen: false,
                            allowDetail: false,
                            currentTab: 'submitted',
                            keepAlive: true,
                        },
                    };
                    this.appBus.trigger('dgb:drawer:update', rtpConversationData);
                }
            }
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('payments/viewPayment');
        } else {
            const modal = smbConstants.isACHCollection(options.model.get('TYPE')) ? new CombinedCollectionModal(options) : new CombinedPaymentModal(options);
            this.listenToOnce(modal, 'smbPayments:paymentAdded', this.paymentCreated);
            dialog.open(modal);
        }
        return Promise.resolve();
    },

    gridRowModify(options) {
        const { serviceName } = options.model.context;
        if (serviceName.indexOf('TaxPayments') > -1) {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo(this.isPaymentView ? 'payments/modifyPayment' : 'templates/modifyTemplate');
        } else {
            const modal = smbConstants.isACHCollection(options.model.get('TYPE')) ? new CombinedCollectionModal(options) : new CombinedPaymentModal(options);
            this.listenToOnce(modal, 'smbPayments:paymentAdded', this.paymentCreated);
            dialog.open(modal);
        }
        return Promise.resolve();
    },

    /**
     * Handle the Copy As Payment action from the payment grid
     * @param {Object} optionsParam - object containing the options needed to create the payment
     */
    gridPaymentFromTemplate(optionsParam) {
        const options = optionsParam;
        let { serviceName } = options.model.context;
        const isTax = serviceName.indexOf('TaxPayments') !== -1;
        if (isTax) {
            serviceName = optionsParam.model.context.serviceName.replace('/batchTemplate/', '/batch/');
            const contextModel = {
                functionCode: optionsParam.model.get('FUNCTION'),
                serviceName,
                context: 'SMB_PAY_LIST',
                subType: optionsParam.model.get('SUBTYPE'),
                tnum: optionsParam.model.get('TNUM'),
                loadFromPayments: this.isPaymentView,
                updateCount: optionsParam.model.get('UPDATECOUNT__'),
                mode: 'insert',
                templateServiceName: serviceName,
                returnTabStoreName: 'payment_smb-currentTab',
                returnTabStoreValue: 'paymentTab',
            };
            store.set('payment_listView_smb-contextOverride', contextModel);
            store.set(`${this.contextKey}-actionModel`, this.tempModel);
            this.navigateTo('templates/copyAsPayment');
        } else if (options.action.toUpperCase() === 'COPYINST') {
            // add an option indicating this is a copy operation
            options.isCopyPayment = true;
            const modal = smbConstants.isACHCollection(options.model.get('TYPE')) ? new CombinedCollectionModal(options) : new CombinedPaymentModal(options);
            this.listenToOnce(modal, 'smbPayments:paymentAdded', this.paymentCreated);
            dialog.open(modal);
        }
        return Promise.resolve();
    },

    /**
     * Handle the Copy As Template action from the payment grid
     * @param {Object} optionsParam - object containing the options needed to create the payment
     */
    gridTemplateFromPayment(optionsParam) {
        const serviceName = optionsParam.model.context.serviceName.replace('/batch/', '/batchTemplate/');
        const contextModel = {
            functionCode: optionsParam.model.get('FUNCTION'),
            serviceName,
            context: 'SMB_TEMP_LIST',
            subType: optionsParam.model.get('SUBTYPE'),
            tnum: optionsParam.model.get('TNUM'),
            loadFromPayments: true,
            updateCount: optionsParam.model.get('UPDATECOUNT__'),
            mode: 'insert',
            templateServiceName: serviceName,
            returnTabStoreName: 'payment_smb-currentTab',
            returnTabStoreValue: 'templateTab',
        };
        store.set('template_listView_smb-contextOverride', contextModel);
        store.set(`${this.contextKey}-actionModel`, optionsParam.model);
        this.navigateTo('templates/copyPaymentAsTemplate');
        return Promise.resolve();
    },

    /**
     * Handle the restore action from the payment grid
     * @param {Object} optionsParam - object containing the options needed to create the payment
     */
    gridRowRestore(optionsParam) {
        store.set(
            'smb_restore_context',
            {
                serviceName: optionsParam.model.context.serviceName,
                context: 'SMB_TEMP_LIST',
            },
        );
        store.set(`${this.contextKey}-actionModel`, optionsParam.model);
        this.navigateTo('TEMPLATES/restoreTemplate');
        return Promise.resolve();
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        const inquiryID = this.isPaymentView
            ? constants.INQUIRY_ID_20104 : constants.INQUIRY_ID_20597;
        return {
            inquiryId: inquiryID,
            summaryInquiryId: inquiryID,
        };
    },

    /**
     * Returns true if the payment type uses the standard detail screen and not the SMB modal
     * @param {Object} options - contains payment model that includes relevant action data
     * @returns {boolean}
     */
    useStandardDetailScreen(options) {
        return (options.model
                && options.model.context
                && options.model.context.actionData
                && (options.model.context.actionData.typeCode === smbConstants.TAX
                || options.model.context.actionData.typeCode === smbConstants.CRTRAN));
    },
});

let list = PaymentManagementList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'sbPayments.addPayment',
                entitlement: 'INSERT',
            },
            {
                label: 'smbPayments.add.collection',
                handleEntitlementMethodName: 'hasCollectionEntitlements',
                handlerMethodName: 'collect',
            },
            {
                label: 'smbPayments.add.tax.payment',
                handleEntitlementMethodName: 'hasTaxEntitlements',
                handlerMethodName: 'taxInsert',
            },
        ],
        bulkActions: [
            {
                label: 'sbPayments.approve',
                entitlement: constants.ACTION_APPROVE,
            },
            {
                label: 'sbPayments.unApprove',
                entitlement: constants.ACTION_UNAPPROVE,
            },
            {
                label: 'button.reject',
                entitlement: constants.ACTION_REJECT,
            },
            {
                label: 'sbPayments.delete',
                entitlement: constants.ACTION_DELETE,
            },
        ],
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
