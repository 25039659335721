import CompositeView from '@glu/core/src/compositeView';
import lazyLoadListTmpl from './lazyLoadList.hbs';

export default CompositeView.extend({

    template: lazyLoadListTmpl,
    itemViewContainer: '[data-hook="getListRegion"]',

    ui: {
        $itemViewContainer: '[data-hook="getListRegion"]',
        $loader: '[data-hook="getLoader"]',
    },

    itemEvents: {
        // provides a way to pass an item click event up the chain
        'on:item:clicked': 'triggerItemClickEvent',
    },

    initialize() {
        this.originalSync = this.collection.sync;

        this.collection.sync = this.syncOverride.bind(this);

        this.listenTo(this.collection, 'sync', this.hideLoading.bind(this));
        this.listenTo(this.collection, 'error', this.hideLoading.bind(this));
    },

    onRender() {
        // Don't want to show the emptyView on initialLoad as the collection is always empty
        this.$itemViewContainer.hide();
    },

    /**
     * Before calling the original sync method of the collection, execute additional logic
     * @param {string} method - type of request
     * @param {Object} model
     * @param {Object} options
     */
    syncOverride(method, model, options) {
        this.showLoading();
        // need to hide the itemView container when fetching as it contains the empty view
        this.$itemViewContainer.hide();
        return this.originalSync.call(this.collection, method, model, options);
    },

    /**
     * Overriding the base function to return the item view passed in from options
     * @returns {Object}
     */
    getItemView() {
        return this.options.itemView;
    },

    /**
     * Overriding the base function to return the empty view passed in from options
     * @returns {Object}
     */
    getEmptyView() {
        return this.options.emptyView;
    },

    /**
     * Add class to the dom element to show the loader
     */
    showLoading() {
        this.ui.$loader.addClass('loader');
    },

    /**
     * Remove class from the dom element to hide the loader and show the itemViewContainer
     */
    hideLoading() {
        this.ui.$itemViewContainer.show();
        this.ui.$loader.removeClass('loader');
    },

    /**
     * Trigger the item clicked event
     * @param {string} event - event name from item view
     * @param {Object} view - the item view
     * @param {Object} model - model attached to the item view
     */
    triggerItemClickEvent(event, view, model) {
        this.trigger('list:item:clicked', event, view, model);
    },
});
