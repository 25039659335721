import util from '@glu/core/src/util';
import $ from 'jquery';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import PaymentUtil from 'common/util/paymentUtil';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const formState = form.formView.state;
    const hidecutoff = serverConfigParams.get('hideCutoff');
    const debitAccount = ['DEBIT_ACCOUNT_BANK_NAME', 'DEBIT_ACCOUNT_TITLE'];
    const beneficiaryInstitution = ['BENE_NAME', 'BENE_ADDRESS_1', 'BENE_ADDRESS_2', 'BENE_CITY', 'BENE_COUNTRY'];
    const accountWithInstitution = ['BENE_BANK_NAME', 'BENE_BANK_ADDRESS_1', 'BENE_BANK_ADDRESS_2', 'BENE_BANK_CITY', 'BENE_BANK_COUNTRY'];
    const intermediaryBank = ['CORRESPONDENT_NAME', 'CORRESPONDENT_ADDRESS_1', 'CORRESPONDENT_ADDRESS_2', 'CORRESPONDENT_CITY', 'CORRESPONDENT_COUNTRY'];
    const amountAndDates = ['TRAN_DATE'];
    const creditAmount = form.field('CREDIT_AMOUNT');
    const changeableFields = ['VALUE_DATE', 'CREDIT_AMOUNT', 'CUSTOMER_REFERENCE', 'SPECIAL_INSTRUCTIONS', 'OBI_TEXT_1', 'OBI_TEXT_2', 'OBI_TEXT_3', 'OBI_TEXT_4', 'INSTRUCTIONS_TO_BENE_BANK_1', 'INSTRUCTIONS_TO_BENE_BANK_2', 'INSTRUCTIONS_TO_BENE_BANK_3', 'INSTRUCTIONS_TO_BENE_BANK_4', 'INSTRUCTIONS_TO_BENE_BANK_5', 'INSTRUCTIONS_TO_BENE_BANK_6'];
    const { model } = form.formView;
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;
    const { functionCode } = model.jsonData.typeInfo;

    const showHideReadOnly = function (array, controlField) {
        util.each(array, (fieldName) => {
            if (!util.isEmpty(model.get(controlField))) {
                $(`#${fieldName}`).removeClass('hidden');
            } else {
                $(`#${fieldName}`).addClass('hidden');
            }
        });
    };

    const protectFields = function () {
        Object.keys(form.fields || {}).forEach((fieldName) => {
            if (changeableFields.indexOf(fieldName) === -1) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
        });
        $('.lookup').addClass('hidden');
    };

    if (initialState) {
        rtgsCommon.getAccountBalances(form.formView, 'DEBIT_ACCOUNT_NUMBER', 'RTGS', 'ITRNSFER', 'DEBIT_BANK_CODE', 'DEBIT_CURRENCY');
        model.on('change:VALUE_DATE', function () {
            if (form.formView.state.toUpperCase() !== 'VIEW') {
                fxFieldValidation.doFieldValidation(this, model, 'VALUE_DATE', form.formView.state.toUpperCase()).then(() => {
                    if (model.context.functionCode !== 'BHTMPL' && model.context.functionCode !== 'TMPL') {
                        PaymentUtil.showCutoff(model.get('CUTOFF_INFO') || (form.formView.dates ? form.formView.dates.cutoffDateTimeTz : null), $('.ui-datepicker-trigger'), model.get('TYPE'));
                    }
                });
            }
        });

        const amtBlock = form.formView.$('[data-validate="CREDIT_AMOUNT"]');
        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            form.formView,
            model,
            amtBlock,
            functionCode,
        );

        // handle lock icon for amount if template and not view
        if (form.formView.state.toUpperCase() !== 'VIEW' && (functionCode !== 'BATCH')) {
            if (creditAmount.$el && creditAmount.$el.length > 0) {
                PaymentUtil.runChangeAmount(creditAmount.$el[0], model);
            }
        }
    }

    // display cutoff info if configured and available
    if (model.context.functionCode !== 'BHTMPL' && model.context.functionCode !== 'TMPL') {
        PaymentUtil.showCutoff(model.get('CUTOFF_INFO') || (form.formView.dates ? form.formView.dates.cutoffDateTimeTz : null), $('.ui-datepicker-trigger'), model.get('TYPE'), formState, model.get('STATUS'));
    }

    if (functionCode === 'TMPL') {
        if (form.formView.state === 'modify' || form.formView.state === 'restore') {
            form.field('TEMPLATE_CODE').shouldBeReadOnly(true);
        }
    } else {
        showHideReadOnly(amountAndDates, 'VALUE_DATE');
    }
    model.on('change:DEBIT_CURRENCY', () => {
        if (!util.isEmpty(model.get('DEBIT_CURRENCY'))) {
            model.set('CREDIT_CURRENCY', model.get('DEBIT_CURRENCY'));
        }
    });

    if (form.formView.state === 'insert') {
        // Hide the audit section
        const aFieldInAuditSection = form.field('VIEWHISTORY');
        aFieldInAuditSection.$el.closest('.section').hide();
    }

    // Beneficiary Institution - Institution Code
    util.each(beneficiaryInstitution, (fieldName) => {
        form.field(fieldName)
            .shouldBeReadOnly(true);
        /*
         * Temporarily removed until typeahead dropdowns exist
         * .shouldBeReadOnlyWhen(form.field('BENE_INSTITUTION_ID').isNotEmpty());
         */
    });

    // Account with Institution - Bank Code
    util.each(accountWithInstitution, (fieldName) => {
        form.field(fieldName)
            .shouldBeReadOnly(true);
        /*
         * Temporarily removed until typeahead dropdowns exist
         * .shouldBeReadOnlyWhen(form.field('BENE_BANK_ID').isNotEmpty());
         */
    });

    // Intermediary Bank - Bank Code
    util.each(intermediaryBank, (fieldName) => {
        form.field(fieldName)
            .shouldBeReadOnly(true);
        /*
         * Temporarily removed until typeahead dropdowns exist
         * .shouldBeReadOnlyWhen(form.field('CORRESPONDENT_ID').isNotEmpty());
         */
    });

    showHideReadOnly(debitAccount, 'DEBIT_ACCOUNT_NUMBER');
    showHideReadOnly(beneficiaryInstitution, 'BENE_INSTITUTION_ID');
    showHideReadOnly(accountWithInstitution, 'BENE_BANK_ID');
    showHideReadOnly(intermediaryBank, 'CORRESPONDENT_ID');

    if (form.formView.model.get('ENTRYMETHOD') && (form.formView.model.get('ENTRYMETHOD') === '1')) {
        protectFields();
    }

    /*
     * hide cutoff if conditions are true  (EN-Entered, IA-Incomplete Approval, RT-Needs
     * Rate, HV-2nd Approval Required are status defined in database)
     */
    rtgsCommon.hideCutoff(formState, functionCode, hidecutoff, 'ITRNSFER', model.get('STATUS'));
}
