import Controller from '@glu/core/src/controller';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

const CurrenyPaymentDetail = Controller.extend({
    constructor(...args) {
        const [request] = args;
        Controller.prototype.constructor.apply(this, args);
        // this.request = request || this.createDefaultRequest();
        this.hash = request ? transform.pairsToHash(request.item) : this.createDefaultHash();
        this.handleSuccess = util.bind(this.handleSuccess, this);
        this.url = services.beneAddressCurrencyDetail;
        this.handleError = util.bind(this.handleError, this);
    },

    createDefaultHash() {
        return {
            BENE_ACCOUNT_TYPE: '',
            BENE_ACCOUNT: '',
            PRODUCT: '',
            CONTACTTYPE: '',
        };
    },

    createDefaultRequest() {
        return {
            item: [{
                name: 'BENE_ACCOUNT_TYPE',
                value: '',
            }, {
                name: 'BENE_ACCOUNT',
                value: '',
            }, {
                name: 'PRODUCT',
                value: '',
            }, {
                name: 'CONTACTTYPE',
                value: '',
            }],
        };
    },

    setContactType(contactType) {
        this.hash.CONTACTTYPE = contactType;
        return this;
    },

    setParentTnum(parentTnum) {
        this.hash.PARENTTNUM = parentTnum;
        return this;
    },

    setBeneAccountNumber(beneAccountNumber) {
        this.hash.BENE_ACCOUNTNUMBER = beneAccountNumber;
        return this;
    },

    setBeneAccountCurrency(beneAccountCurrency) {
        this.hash.BENE_ACCOUNT_CURRENCY = beneAccountCurrency;
        return this;
    },

    setAccountCode(accountCode) {
        this.hash.BENE_BANK_ID = accountCode;
        this.hash.BENE_ACCOUNT = accountCode;
        return this;
    },

    setBeneBankIDType(beneBankIDType) {
        this.hash.BENE_BANK_IDTYPE = beneBankIDType;
        return this;
    },

    setAccountType(accountType) {
        this.url = services.beneAddressCurrencyDetail;
        this.hash.BENE_ACCOUNT_TYPE = accountType;
        return this;
    },

    setProduct(product) {
        if (product) {
            this.hash.PRODUCT = product;
        } else {
            delete this.hash.PRODUCT;
        }
    },

    /*
     * = ==
     *  setBeneBankIdEntryMethod: function(entryMethod) {
     *   this.hash.BENEBANKIDENTRYMETHOD = entryMethod;
     *   return this;
     *  },
     */

    send() {
        this.request = {
            item: {
                item: transform.hashToPairs(this.hash),
            },
        };

        return http.post(this.url, this.request, this.handleSuccess, this.handleError);
    },

    handleSuccess(response) {
        this.trigger('success', response);
    },

    handleError() {},
});

export default CurrenyPaymentDetail;
