import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import TotalsModel from 'app/reports/models/lockboxAvailabilityTotals';
import template from './lockboxAvailabilityTotals.hbs';

export default Layout.extend({
    template,

    initialize() {
        this.isAccountDetails = this.options.isAccountDetails || false;
        this.model = new TotalsModel({
            isAccountDetails: this.options.isAccountDetails || false,
            searchFields: this.options.searchFields,
            viewId: this.options.viewId,
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        const summary = store.get('lockboxAvailability:account');
        if (this.isAccountDetails) {
            this.options.searchFields.push({
                fieldName: 'ACCOUNTFILTER',
                operator: 'CONTAINS',
                fieldValue: [summary.model.get('ACCOUNTFILTER')],
                dataType: 'text',
            });
            this.model.set('searchFields', this.options.searchFields);
        }
        this.model.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    templateHelpers() {
        return {
            wellItems: this.model.omit('searchFields', 'viewId', 'rowsPerPage', 'startRow'),
        };
    },
});
