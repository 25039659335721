import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import scroll from 'common/util/scroll';
import AddLockboxModel from 'app/lockbox/models/lockbox';
import viewLockboxTmpl from './viewLockbox.hbs';

export default Layout.extend({
    template: viewLockboxTmpl,
    modalClass: 'modal-xlg',

    initialize(options) {
        this.location = options.location;
        this.action = 'SELECT';

        this.model = new AddLockboxModel(options.model);
        this.dialogTitle = `${locale.get('PAY.View')} ${this.model.get('NAME')} (${this.model.get('LOCKBOX_CODE')})`;

        this.viewState = options.state || null;
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
        this.totalAdded = [];
        this.dialogButtons = [{
            text: locale.get('button.close'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
    },

    cancel() {
        if (this.viewState === 'modal') {
            dialog.close();
        }
        this.trigger('lockbox:cancel');
    },

    templateHelpers() {
        const self = this;
        return {
            isModal: (self.viewState === 'modal'),
            getRemitterManageBy: (this.model.get('REMITTERMANAGEBYBANK') === 'Y' ? locale.get('LBX.ByBank') : locale.get('LBX.ByUser')),
        };
    },
});
