import locale from '@glu/locale';
import store from 'system/utilities/cache';
import constants from 'app/administration/constants';
import { appBus } from '@glu/core';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import cookie from 'system/cookie';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import ResetPassword from 'common/admin/user/passwordReset';
import services from 'services';
import { postData } from 'common/util/services';

export default {

    simulateUser(model, errorResponse) {
        let simBasePath = '/ui';
        if (serverConfigParams.get('clientJunctionBasePath')) {
            /*
             * In production, reverse proxy servers usually front the application server
             * in order to provide security related services. The URL can contain a base
             * path (often referred to as 'junction') that directs the request to the
             * reverse proxy server. Although the Bank Admin and the Client App will be on
             * the same domain, the Junctions may differ which is why the relative path may need
             * to be adjusted to reflect the proper junction configured for the Client
             */
            simBasePath = serverConfigParams.get('clientJunctionBasePath') + simBasePath;
        }
        dialog.confirm(locale.get('btGrid.simulate.msg'), locale.get('title.confirm'), (ok) => {
            if (ok) {
                model.genericObjectFunction({
                    success(m, resp) {
                        cookie.set('SIMULATIONSESSIONTOKEN', resp.message[0]);

                        /*
                         * if we using the old admin and opening a new window into the
                         * user-centric UI then load simulate session in old admin window
                         * and close the current user-centric window.
                         */
                        if (window.opener) {
                            window.opener.location.href = simBasePath;
                            window.close();
                        } else {
                            window.location.href = simBasePath;
                        }
                    },
                    errorResponse,
                }, 'simulate');
            }
        });
    },

    addUser(isUce, userGroup, parentUserGroup, copyFromUser) {
        store.set(
            'uce-load',
            {
                userGroup,
                parentUserGroup,
                copyFromUser: copyFromUser || null,
                isUce,
            },
        );
        appBus.trigger('router:navigate', isUce ? constants.ROUTES.ADD_USER : constants.ROUTES.ADD_ROLE_USER, true);
    },

    resetPassword(view, gridModel, success) {
        const passwordReset = new ResetPassword({
            name: gridModel.get('USERID'),
            realm: gridModel.get('USERGROUP'),
            updateCount: gridModel.get('UPDATECOUNT__'),
            isForgottenPassword: true,
            entitlements: view.entitlements,
        });
        dialog.open(passwordReset);

        view.listenTo(passwordReset, 'close', () => {
            dialog.close();
        });

        view.listenTo(view.appBus, 'forgottenPassword:reset:success', success);
    },

    filterActionButtons(buttons, model, mode, isUce) {
        const ACTIONS_TO_IGNORE = [constants.ACTIONS.SELECT];
        if (!isUce) {
            ACTIONS_TO_IGNORE.push(constants.ACTIONS.UPDSET);
        }
        if (buttons && mode === constants.MODES.VIEW) {
            return buttons.filter((buttonParam) => {
                const button = buttonParam;
                button.action = button.action.toLowerCase();
                return ACTIONS_TO_IGNORE.indexOf(button.action) === -1;
            });
        }
        return buttons;
    },

    getUserActionList(model, success) {
        const requestData = model.convertModelAttributesToKeyJSON(model);
        postData(services.generateUrl('userMaintenance/user/getUserActionList'), requestData).then((response) => {
            const buttons = this.parseActionList(response.item[0].value);
            success(buttons);
        });
    },

    parseActionList(value) {
        // parse the list of available actions.
        const actions = value.split('^');
        const buttons = [];
        util.each(actions, (action) => {
            const actionTokens = action.split(',');
            let label = '';
            if (actionTokens.length > 1 && actionTokens[1].length > 0) {
                label = actionTokens[1].replace('&#44', ',');
            }
            if (actionTokens[0] !== 'INSERT') {
                buttons.push({
                    label,
                    action: actionTokens[0],
                });
            }
        });

        return buttons;
    },

};
