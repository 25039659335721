import { appBus } from '@glu/core';

export default {
    /**
     * queue transfer
     * @param {Object} data - request data
     * @returns {Promise}
     */
    transfer(data) {
        return new Promise((resolve, reject) => {
            if (this.isValidData(data)) {
                appBus.trigger('RDC:transfer:image', data);
                resolve(data);
            } else {
                reject(new Error('Missing Required Data Properties'));
            }
        });
    },

    /**
     * Evaluate data structure and determine if it is valide
     * @param {Object} data
     * @returns {boolean}
     */
    isValidData(data) {
        return !!data && !!data.image && !!data.checkSide;
    },
};
