import Layout from '@glu/core/src/layout';
import PageApi from 'common/dynamicPages/api/view';
import constants from 'app/administration/constants';
import ContextModel from 'common/dynamicPages/models/context';
import Model from '@glu/core/src/model';
import DataApi from 'common/dynamicPages/api/data';
import util from '@glu/core/src/util';
import ContextApi from 'common/dynamicPages/api/context';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import systemConfig from 'system/configuration';
import AdminUtil from 'app/administration/common/util';
import scroll from 'common/util/scroll';
import http from '@glu/core/src/http';
import services from 'services';
import alertMessage from 'common/api/alertMessage';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import userProfileRestrictedTmpl from './userProfileRestricted.hbs';

export default Layout.extend({
    template: userProfileRestrictedTmpl,
    loadingTemplate: loadingWidgetTmpl,

    regions: {
        userProfileRegion: '[data-hook="userProfileRegion"]',
    },
    ui: {
        $saveButton: '[data-hook="save"]',
    },

    initialize(options) {
        this.userId = options.userId;
        this.userGroup = options.userGroup;
        this.isAdmin = systemConfig.isAdmin();

        this.initContext();
        this.gridModel = store.get(`${this.contextKey}-actionModel`);

        this.buttons = [];
        const buttons = store.has('uce-load') ? store.get('uce-load').buttons : null;
        if (buttons && this.mode === constants.MODES.VIEW) {
            this.buttons = util.filter(buttons, (buttonParam) => {
                const button = buttonParam;
                button.action = button.action.toLowerCase();
                return button.action !== constants.ACTIONS.SELECT;
            });
        }

        this.mode = this.options.mode || constants.MODES.MODIFY;
        this.viewType = this.options.viewType || 'default';
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.pageContent.show(this.pageView);
        } else {
            const metadrivenModel = new Model();
            metadrivenModel.id = this.userGroup + this.userId;
            metadrivenModel.set('USERGROUP', this.userGroup);
            metadrivenModel.set('USERID', this.userId);
            metadrivenModel.set('id', metadrivenModel.id);
            metadrivenModel.context = this.contextDef;

            this.loadUserProfile(metadrivenModel, (loadedModel) => {
                this.model = loadedModel;
                this.loadUserData(loadedModel);
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        }
    },

    /**
     * @param {Model} model
     * @param {function} _successCallback
     * @return {Promise}
     * Returns a promise that will fetch a user's model information
     * and perform the passed in success callback
     */
    loadUserProfile(model, _successCallback) {
        const self = this;
        return DataApi.model.generateModelFromModel(model).then((loadedModel) => {
            loadedModel.fetch({
                success: _successCallback,
                error: util.bind(errorHandlers.loading, self),
            });
        });
    },

    initContext() {
        this.contextDef = ContextApi.menuContext.getContext(constants.USER_MAINT);
        if (this.isAdmin) {
            this.contextDef.actionContext = {
                actionMode: 'UPDSET',
                entryMethod: 0,
                functionCode: 'USERS',
                productCode: '_ADMIN',
                subType: 'UPDSET',
                typeCode: '_USER',
            };
        } else {
            this.contextDef.actionContext = {
                actionMode: 'UPDSET',
                entryMethod: 0,
                functionCode: 'MAINT',
                productCode: 'CMAINT',
                subType: 'UPDSET',
                typeCode: 'CLUSR_TM',
            };
        }

        this.contextModel = new ContextModel({
            menuCategory: this.contextDef.menuCategory,
            serviceName: this.contextDef.serviceName,
            serviceFunc: this.contextDef.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();

        // set the service name to point to the updateSetting service
        this.contextDef.serviceName = `${this.contextDef.serviceName}/updateSetting`;
    },

    loadUserData(model) {
        const options = {
            context: this.contextDef,
            hideButtons: true,
            state: this.mode,
            model,
        };

        this.pageView = PageApi.page.get(options);
    },

    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    save() {
        const self = this;
        const { model } = this;
        let saveObject = {};

        // Validate form before saving
        if (model.isValid()) {
            // Unset the password if data is in modify mode
            const password = model.get('PASSWORD');
            if (!password) {
                model.unset(
                    'PASSWORD',
                    {
                        silent: true,
                    },
                );
            }

            saveObject = this.createSaveObject();
            /* Ensure update count is always included. */
            saveObject.item.item.push({
                name: 'UPDATECOUNT__',
                value: model.get('UPDATECOUNT__') || '0',
            });
            this.ui.$saveButton.attr('disabled', true);
            /*
             * Save with direct ajax call, model is metadriven and also
             * nested so cannot save via the model
             */
            http.post(
                services.generateUrl('userMaintenance/user/updateSetting/update'), saveObject,
                // Success
                (result) => {
                    if (result.errorCode) {
                        alertMessage.renderMessage(self, 'save', result);

                        util.defer(() => {
                            scroll.scrollToFirstError();
                        });

                        // If this is deeplink than we need to scroll to error
                        if (window.parent !== window) {
                            moveToTopCheck(self.model);
                        }
                        return;
                    }

                    store.set(
                        'addUserCentricSuccessMessage',
                        {
                            action: 'save',
                            response: result,
                        },
                    );
                    self.navigateTo(self.options.returnRoute);
                },
                // Failure
                (userModel, response) => {
                    AdminUtil.errorAlert(self.alertRegion, response.responseJSON);
                    this.ui.$saveButton.attr('disabled', false);
                },
            );
        }
    },

    createSaveObject() {
        return {
            item: this.model.convertModelAttributesToServerJSON(this.model, 'POST'),
        };
    },

    templateHelpers() {
        const self = this;
        return {
            getPageTitle() {
                return `${self.userGroup} - ${self.userId} - ${self.model.get('USERNAME')}`;
            },
        };
    },

});
