import transferTemplateRestore from 'app/payments/views/restoreTemplate';

export default transferTemplateRestore.extend({
    initialize() {
        this.options = {
            menuCategory: 'PMTS',
            serviceName: 'template/transfer',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_TEMPLATE_LIST',
            mode: 'modify',
            overloadedMethodName: 'restore',
        };

        transferTemplateRestore.prototype.init.call(this);
    },
});
