import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import transform from 'common/util/transform';
import constants from 'app/balanceAndTransaction/constants';
import dynamicConstants from 'common/dynamicPages/api/constants';

const AvailableAccounts = Model.extend({
    initialize(options) {
        this.request = this.createDefault();
        this.url = services.generateUrl(dynamicConstants.URL_GETLOOKUPRESULTS_ACTION);
        this.filterVal = '';
        this.tabId = options.tabId;
    },

    /**
     * @method createDefault
     * @return {object} service request object
     * Creates service param data needed to get data on nonRealTimeAccounts count
     */
    createDefault() {
        const requestParameters = {
            INQUIRYID: constants.INQUIRYID_AVAILABLE_ACCOUNTS,
        };

        const data = {
            rowsPerPage: constants.MAX_SERVER_ROWSPERPAGE,
            startRow: 1,
            fieldName: 'ACCOUNTFILTER',
            typeCode: 'GIRACCT',
            functionCode: 'INST',
            productCode: 'GIR',
            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
            depends: [
                {
                    name: 'TABID',
                    value: this.tabId,
                },
            ],
            sortFields: [{
                fieldName: 'ACCOUNTFILTER',
                sortOrder: 'asc',
            }],
        };

        if (this.filterVal) {
            data.searchFields = [{
                fieldName: 'ACCOUNTNUM',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'CLIENTACCOUNTNAME',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'CURRENCYCODE',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }];

            data.searchOrOperator = true;
        }

        return data;
    },

    getRequest() {
        return this.createDefault();
    },

    send() {
        return http.post(this.url, this.getRequest())
            .then(this.parseResponse, this.handleError);
    },

    setStartRow(start) {
        this.request.startRow = start;
    },

    setSearch(str) {
        this.filterVal = str;
    },

    setSearchOperator(str) {
        this.request.searchFields[0].operator = str;
    },

    /**
     * Function to save or get data from server
     * @param  {string} method Action to perform
     * @param  {object} model model object affected
     * @param  {object} options object containing success and error callbacks
     * (parse by default)
     * @returns {Promise} - jQuery XHR Promise
     */
    sync(method, model, options) {
        if (method === 'read') {
            const data = this.createDefault();

            return http.post(
                this.url,
                data,
                options.success,
                options.error,
            );
        }
        // TODO: Maybe return a reject promise for bad method?
        return undefined;
    },

    /**
     * @method createAccountObject
     * @param {array} accountData - account property and value pairs
     * Takes in array with account prop and value pairs and converts it into one
     * account object
     */
    createAccountObject(accountData) {
        const acct = transform.pairsToHash(accountData.mapDataList, 'toField', 'value');

        // parse as boolean values
        acct.ISTIEREDLOAN = parseInt(acct.ISTIEREDLOAN, 10) > 0;
        acct.ISREALTIMEACCOUNT = parseInt(acct.ISREALTIMEACCOUNT, 10) > 0;
        return acct;
    },

    /**
     * Default Function to modify data fetched from service
     * @param  {object} response data from service
     * @returns {Object} parsed data from service
     */
    parse(response) {
        const accounts = util.map(
            response.rows,
            this.createAccountObject,
        );

        return {
            accounts,
        };
    },
});

export default AvailableAccounts;
