var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n        <div class=\"col-md-6\">\n                <div class=\"field-container outputContents\">\n                        <label class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.ExportType.OutputContent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":89}}}))
    + "</label>\n                        <input type=\"hidden\" class=\"form-control\" data-hook=\"getOutputContentDropdown\" name=\"OUTPUTCONTENT\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"OUTPUTCONTENT\"></span>\n                </div>\n        </div>\n        <div class=\"col-md-6\">\n                <div class=\"field-container hide required\" data-hook=\"getExportConditionalField\">\n                        <label for=\"exportCVSDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":51},"end":{"line":11,"column":75}}}))
    + "</label>\n                        <div class=\"search-input-wrapper\">\n                                <label for=\"exportCSVDate\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.searchValue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":75},"end":{"line":13,"column":106}}}))
    + "</label>\n                                <input class=\"form-control input-date\"\n                                                type=\"text\"\n                                                data-hook=\"getDateInput\"\n                                                data-bind=\"model\"\n                                                name=\"DATE\"\n                                                value=\"\"\n                                                id=\"exportCSVDate\"\n                                                aria-required=\"true\"\n                                                required />\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DATE\"></span>\n                        </div>\n                </div>  \n        </div>\n</div>\n<div class=\"row\">\n        <div class=\"col-md-6\">\n                <div class=\"field-container excludeHeaders\">\n                        <div class=\"checkbox\">\n                                <label for=\"EXCLUDEHEADER\">\n                                        <input type=\"checkbox\" id=\"EXCLUDEHEADER\" name=\"EXCLUDEHEADER\" data-bind=\"model\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.ExcludeHeader",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":121},"end":{"line":33,"column":158}}}))
    + "</input>\n                                </label>\n                                <span class=\"help-block\" data-validate=\"EXCLUDEHEADER\"></span>\n                        </div>\n                        <div class=\"checkbox hide\" data-hook=\"getContinuationAccountSummaryRecord\">\n                                <label for=\"split88Record\">\n                                        <input\n                                                type=\"checkbox\"\n                                                id=\"split88Record\"\n                                                name=\"split88Record\"\n                                                data-bind=\"model\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.Separate.Transaction.Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":66},"end":{"line":43,"column":118}}}))
    + "</input>\n                                </label>\n                                <span class=\"help-block\" data-validate=\"split88Record\"></span>\n                        </div>\n                </div>\n        </div>\n</div>\n";
},"useData":true});