import serverConfigParams from 'system/webseries/models/configurationParameters';
import http from '@glu/core/src/http';

export default {
    serverConfigParamsReady() {
        const isEnabled = serverConfigParams.get('enableIdentityGuard') === 'true';

        if (isEnabled) {
            import('@dgb/entrust-identityguard').then(() => {
                const headers = {
                    fingerPrint: window.machineSecret.getDeviceFingerprint(),
                    machineNonce: window.machineSecret.fetchMachineNonce(),
                    sequenceNonce: window.machineSecret.fetchSequenceNonce(),
                };
                http.setRequestHeaders(headers);
            });
        }
    },
};
