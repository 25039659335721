import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import cookie from 'system/cookie';
import util from '@glu/core/src/util';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import store from 'system/utilities/cache';
import errorHandlers from 'system/error/handlers';
import locale from 'system/webseries/api/locale';
import ContextApi from 'common/dynamicPages/api/context';
import DataApi from 'common/dynamicPages/api/data';
import scroll from 'common/util/scroll';
import dialog from '@glu/dialog';
import transform from 'common/util/transform';
import constants from 'app/administration/constants';
import UserModel from 'app/administration/models/user/user';
import ContextModel from 'common/dynamicPages/models/context';
import PaymentsWrapperView from 'app/administration/views/userMaintenance/payments/paymentWrapper';
import ReportingView from 'app/administration/views/userMaintenance/reporting/reporting';
import RiskManagementView from 'app/administration/views/userMaintenance/riskManagement/riskManagement';
import AdminView from 'app/administration/views/userMaintenance/admin/admin';
import AlertsView from 'app/administration/views/userMaintenance/alerts/alerts';
import UserProfileView from 'app/administration/views/userMaintenance/profile/userProfile';
import ABSView from 'app/administration/views/userMaintenance/abs/abs';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import AdminUtil from 'app/administration/common/util';
import alert from '@glu/alerts';
import Collection from '@glu/core/src/collection';
import UserGroupCollection from 'app/administration/collection/userGroups';
import systemConfig from 'system/configuration';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import AssignPaymentTypes from './payments/assignPaymentTypes';
import UserGroupLookup from './userGroupLookup';
import userLayoutTmpl from './userLayout.hbs';

export default Layout.extend({
    template: userLayoutTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        paymentsScreen: '.payments',
        userHeading: '.user-profile-info',
        roleName: '[data-hook="roleName"]',
        roleDesc: '[data-hook="roleDesc"]',
        bankMaintained: '[data-hook="bankMaintained"]',
        saveUser: 'button[data-action="save"]',
    },

    events: {
        'click @ui.$navLinks': 'handleToggleButtonClick',
        'keypress @ui.$navLinks': 'handleKeyPress',
    },

    regions: {
        formRegion: '.formRegion',
        alertRegion: '.alert-region',
    },

    steps: {
        USER: 'userProfile',
        PAYMENTS: 'payments',
        REPORTING: 'reporting',
        RISK: 'riskManagement',
        ADMIN: 'admin',
        ALERTS: 'alerts',
    },

    isInitialRender: true,

    initialize(opts) {
        const self = this;

        this.model = opts.model || new Model();
        this.mode = opts.mode || constants.MODES.VIEW;
        this.userGroup = opts.userGroup;
        this.userId = opts.userId;

        this.isAdmin = systemConfig.isAdmin();

        this.initContext();

        this.gridModel = store.get(`${this.contextKey}-actionModel`);

        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);

        this.buttons = [];
        const buttons = store.has('uce-load') ? store.get('uce-load').buttons : null;
        if (buttons && self.mode === constants.MODES.VIEW) {
            this.buttons = util.filter(buttons, (buttonParam) => {
                const button = buttonParam;
                button.action = button.action.toLowerCase();
                return button.action !== constants.ACTIONS.SELECT;
            });
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            if (store.has('addUserCentricSuccessMessage')) {
                this.alertRegion.show(alert.success(store.get('addUserCentricSuccessMessage')));
                store.unset('addUserCentricSuccessMessage');
            }

            this.initTabs();
            this.toStep(this.steps.USER);
            this.initUserProfile();
            this.isInitialRender = false;
        } else {
            this.load();
        }
    },

    /**
     * Provide a way to build context isolated from the instance
     * @param {object} options
     * @param {boolean} isAdmin
     * @returns {{contextDef, contextModel}}
     */
    buildContext(options, isAdmin) {
        const contextDef = ContextApi.menuContext.getContext(constants.USER_MAINT);

        if (isAdmin) {
            contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'USERS',
                productCode: '_ADMIN',
                subType: '*',
                typeCode: '_USER',
            };
        } else {
            contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'MAINT',
                productCode: 'CMAINT',
                subType: '*',
                typeCode: 'CLUSR_TM',
            };
        }

        const contextModel = new ContextModel({
            menuCategory: options.menuCategory,
            serviceName: options.serviceName,
            serviceFunc: options.serviceFunc,
            businessType: options.businessType,
            context: options.context,
            contextDef,
        });

        return {
            contextDef,
            contextModel,
        };
    },

    /**
     * Build the
     */
    initContext() {
        const contextData = this.buildContext(this.options, this.isAdmin);
        this.contextDef = contextData.contextDef;
        this.contextModel = contextData.contextModel;
        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();
    },

    initTabs() {
        if (!this.skipUser) {
            this.userProfileForm = new UserProfileView({
                contextDef: this.contextDef,
                model: this.userProfileModel,
                mode: this.mode,
                pageTitle: locale.get('administration.user.maint'),
                listViewLabel: locale.get('administration.user.maint.center'),
                returnRoute: constants.ROUTES.USER_LISTVIEW,
            });
        }

        if (this.tabs.PAYMENTS) {
            this.paymentsForm = new PaymentsWrapperView({
                model: this.tabs.PAYMENTS,
                mode: this.mode,
                entity: this.skipUser ? 'role' : 'uce',
            });
        }

        if (this.tabs.REPORTS) {
            this.reportingForm = new ReportingView({
                model: this.tabs.REPORTS,
                mode: this.mode,
                entity: this.skipUser ? 'role' : 'uce',
            });
        }

        if (this.tabs.RISK) {
            this.riskManagementForm = new RiskManagementView({
                model: this.tabs.RISK,
                mode: this.mode,
                entity: this.skipUser ? 'role' : 'uce',
            });
        }

        if (this.tabs.ADMIN) {
            this.adminForm = new AdminView({
                model: this.tabs.ADMIN,
                mode: this.mode,
                entity: this.skipUser ? 'role' : 'uce',
            });
        }

        if (this.tabs.ALERT) {
            this.alertsForm = new AlertsView({
                model: this.tabs.ALERT,
                mode: this.mode,
                entity: this.skipUser ? 'role' : 'uce',
            });
        }

        if (this.tabs.ABS) {
            this.absForm = new ABSView({
                model: this.tabs.ABS,
                mode: this.mode,
                entity: this.skipUser ? 'role' : 'uce',
            });
        }
    },

    initUserProfile() {
        const self = this;
        // listen to user info changes for updating user heading
        this.listenTo(this.userProfileForm.pageView, 'loaded', () => {
            const profileModel = self.userProfileForm.pageView.model;

            // IF insert mode, set the usergroup value in the user profile model
            if (self.mode === constants.MODES.INSERT) {
                self.userProfileForm.pageView.model.set('USERGROUP', self.userProfileModel.get('USERGROUP'));
            }

            self.listenTo(profileModel, 'change', () => {
                let address = '';

                if (profileModel.get('USERNAME') !== '') {
                    self.ui.userHeading.find('.name').text(profileModel.get('USERNAME'));
                    // show our heading now that we have data
                    self.ui.userHeading.show();
                }

                if (profileModel.get('CITY') !== '') {
                    address += profileModel.get('CITY');
                }

                if (profileModel.get('STATE') !== '') {
                    if (address !== '') {
                        address += ', ';
                    }
                    address += profileModel.get('STATE');
                }

                if (address !== '') {
                    self.ui.userHeading.find('.city').text(address);
                }
            });

            // trigger this event so initial load sets user info summary
            profileModel.trigger('change');
        });
    },

    load() {
        const self = this;
        const isNotInsert = self.mode !== constants.MODES.INSERT;
        const metadrivenModel = new Model();

        metadrivenModel.id = this.userGroup + this.userId;
        metadrivenModel.set('USERGROUP', this.userGroup);
        metadrivenModel.set('USERID', this.userId);
        metadrivenModel.set('id', metadrivenModel.id);

        metadrivenModel.context = this.contextDef;

        // fully fetch user
        new UserModel().fetch({
            userGroup: this.userGroup,
            userId: this.userId,

            success(userModel) {
                self.model = userModel;

                // have to make extra calls for metaform data
                if (isNotInsert) {
                    DataApi.model.generateModelFromModel(metadrivenModel).then((newModel) => {
                        newModel.fetch({
                            success() {
                                self.userProfileModel = newModel;

                                // add tab models to this view
                                self.tabs = {};
                                userModel.get('masterTabs').each((masterTabParam) => {
                                    const masterTab = masterTabParam;
                                    masterTab.userModel = newModel;
                                    self.tabs[masterTab.get('masterTabCode')] = masterTab;
                                });

                                // Don't display OFX ID (alternateuserid) if permissions=false
                                let ofxEntitled = false;

                                if (self.tabs.ADMIN) {
                                    /*
                                     * runs through all the models within the `admin` tab,
                                     * and all the `entitlements` within those until it
                                     * finds one that fulfills the nested conditional
                                     * returns false otherwise
                                     */
                                    ofxEntitled = self.tabs.ADMIN.get('simpleTypes').models.some(adminTabModel => adminTabModel.get('entitlementsByType').models.some(model => model.get('typeCode') === 'OFXDIRCN' && model.get('entitled') === true));
                                }

                                if (ofxEntitled === false) {
                                    self.userProfileModel.set('ALTERNATEUSERID', null);
                                }

                                self.setHasLoadedRequiredData(true);
                                self.loadUsergroup();
                            },

                            error: util.bind(errorHandlers.loading, self),
                        });
                    });
                } else {
                    self.userProfileModel = new Model({
                        USERGROUP: self.userGroup,
                        GROUPED_PERMISSIONS_BY: 'PAYMENT_GROUP',
                    });

                    // add tab models to this view
                    self.tabs = {};
                    userModel.get('masterTabs').each((masterTabParam) => {
                        const masterTab = masterTabParam;
                        masterTab.userModel = self.userProfileModel;
                        self.tabs[masterTab.get('masterTabCode')] = masterTab;
                    });

                    self.setHasLoadedRequiredData(true);
                    self.loadUsergroup();
                }
            },

            error: util.bind(errorHandlers.loading, self),
        });
    },

    setMobileMFAInfo() {
        /*
         * Remove 'Register Mobile Device' & 'Revoke Mobile Device' buttons
         * if the user is not configured mobile.
         */
        let mobileUserProperty = util.findWhere(
            this.model.get('userInfo').item,
            {
                name: 'MOBILEMFA_ENABLED',
            },
        );
        this.userProfileModel.userGroupModel.set('isMobileDeviceEnabled', !(util.isNullOrUndefined(mobileUserProperty) || mobileUserProperty.value === '0'));

        mobileUserProperty = util.findWhere(
            this.model.get('userInfo').item,
            {
                name: 'MOBILEDEVICE_REGISTERED',
            },
        );
        this.userProfileModel.userGroupModel.set('isMobileDeviceRegistered', !(util.isNullOrUndefined(mobileUserProperty) || mobileUserProperty.value === '0'));
    },

    // Also renders the grid, because of this function's location in the above load
    loadUsergroup() {
        const self = this;

        const userGroups = new UserGroupCollection({
            isAdmin: self.isAdmin,
        });

        const lookup = new UserGroupLookup({
            isAdmin: self.isAdmin,
        });

        lookup.setSearch(self.userGroup);
        lookup.send().then((result) => {
            const recs = new Collection();
            recs.reset(userGroups.parse(result.data));

            self.model.userGroupModel = recs.get(self.userGroup);

            if (util.isUndefined(self.skipUser) || !self.skipUser) {
                self.userProfileModel.userGroupModel = self.model.userGroupModel;
                self.setMobileMFAInfo();
            }

            // Add reference to each tab for use by sub views
            self.model.get('masterTabs').each((masterTabParam) => {
                const masterTab = masterTabParam;
                masterTab.userGroupModel = self.model.userGroupModel;
            });

            self.render();
        });
    },

    /**
     * change tabs on mouse click of tab
     *
     * @param e
     */
    handleToggleButtonClick(e) {
        const elem = e.currentTarget;
        const step = elem.getAttribute('data-step');
        this.toStep(step);
        this.changeTab(step);
    },

    /**
     * change tabs on enter key after tabbing to tab element
     *
     * @param e
     */
    handleKeyPress(e) {
        // enter key
        if (e.which === 13) {
            const elem = e.currentTarget;
            const step = elem.getAttribute('data-step');
            this.toStep(step);
            this.changeTab(step);
        }
    },

    /**
     * switch tabs
     *
     * @param step - which tab to activate
     */
    toStep(step) {
        // if no payment types selected, open window before switching
        if (step === this.steps.PAYMENTS && !this.tabs.PAYMENTS.hasSelectedComplexType()) {
            this.handlePaymentTypes();
            return;
        }

        this.step = step;

        const activeView = this[`${step}Form`];
        if (!activeView) {
            throw new Error(locale.get('administration.missing.step.error', step));
        } else {
            this.formRegion.show(activeView);
        }

        if (step === this.steps.USER && this.userProfileForm.pageView.model) {
            this.userProfileForm.pageView.model.isValid();
        }

        if (this.isInitialRender) {
            this.currentTabText = locale.get('administration.user.profile');
            setTabButtonText(this, this.currentTabText);
        }
    },

    changeTab(step) {
        this.ui.$navLinks.parent().removeClass('is-active');
        this.ui.$navLinks.filter(`[data-step=${step}]`).parent().addClass('is-active');

        this.currentTabText = this.ui.$navLinks.filter(`[data-step=${step}]`).text();
        toggleTabs(this);
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        }
    },

    /**
     * show payment types modal when tabbing to payments if no payment types set
     */
    handlePaymentTypes() {
        const self = this;

        dialog.open(new AssignPaymentTypes({
            collection: self.tabs.PAYMENTS.get('complexTypes'),
            modifyMode: true,

            onSave() {
                self.toStep(self.steps.PAYMENTS);
            },
        }));
    },

    modify() {
        if (this.mode === constants.MODES.VIEW) {
            if (this.stack) {
                this.stack.pop();
            }
            this.navigateTo(constants.ROUTES.MODIFY_USER);
        } else {
            this.save();
        }
    },

    delete() {
        this.doAction(constants.ACTIONS.DELETE);
    },

    disable() {
        this.doAction(constants.ACTIONS.DISABLE);
    },

    approve() {
        this.doAction(constants.ACTIONS.APPROVE);
    },

    doAction(action) {
        const self = this;
        this.userProfileModel[action]({
            success(model, response) {
                store.set(
                    'addUserCentricSuccessMessage',
                    {
                        action: 'action',
                        response,
                    },
                );
                self.navigateTo(self.options.returnRoute);
            },

            error(model, response) {
                store.set(
                    'addUserCentricSuccessMessage',
                    {
                        action,
                        response,
                    },
                );
                self.navigateTo(self.options.returnRoute);
            },
        });
    },

    /**
     * save the user
     */
    save() {
        const self = this;
        const { model } = this;
        const isModify = this.mode !== constants.MODES.INSERT;
        const userProfileModel = this.userProfileForm.pageView.model;

        // validate form before saving
        if (userProfileModel.isValid()) {
            if (this.isAdmin) {
                userProfileModel.set('PARENTUSERGROUP', model.userGroupModel.get('parentUserGroup'));
            }

            const updateCount = userProfileModel.get('UPDATECOUNT__');
            if (updateCount === '') {
                userProfileModel.set('UPDATECOUNT__', '0');
            }

            const strikes = userProfileModel.get('STRIKES');
            if (strikes === '') {
                userProfileModel.set('STRIKES', '0');
            }

            // unset the password if data is in modify mode
            const password = userProfileModel.get('PASSWORD');
            if (isModify && !password) {
                userProfileModel.unset('PASSWORD', 'silent');
            }

            if (!isModify) {
                userProfileModel.set('GROUPED_PERMISSIONS_BY', this.userProfileModel.get('GROUPED_PERMISSIONS_BY'));
            }

            let userProfilePairs = transform.hashToPairs(userProfileModel.toJSON());

            /*
             * An entry into the checkboxonoffvalues exists for CSQ. converting the data
             * before post to service
             */
            userProfilePairs = util.map(userProfilePairs, (pairParam) => {
                const pair = pairParam;
                if (pair.name === 'CLEARSECURITYQUESTIONS' && pair.value === undefined) {
                    pair.value = '';
                }
                return pair;
            });

            model.get('userInfo').item = userProfilePairs;
            this.ui.saveUser.attr('aria-busy', true);
            model.save(
                null,
                {
                    isModify,

                    success(userModel, response) {
                        store.set(
                            'addUserCentricSuccessMessage',
                            {
                                action: 'save',
                                response,
                            },
                        );
                        self.navigateTo(self.options.returnRoute);
                        self.ui.saveUser.attr('aria-busy', false);
                    },

                    error(userModel, response) {
                        self.ui.saveUser.attr('aria-busy', false);
                        AdminUtil.errorAlert(self.alertRegion, response.responseJSON);
                    },
                },
            );
        } else {
            this.toStep(this.steps.USER);
        }
    },

    back() {
        this.navigateTo(this.options.returnRoute);
    },

    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    /**
     * simulate user functionality
     */
    simulate() {
        const self = this;
        dialog.confirm(locale.get('btGrid.simulate.msg'), locale.get('title.confirm'), (ok) => {
            if (ok) {
                let simModel = self.gridModel;
                let simHref = '/ui';
                if (!simModel) {
                    simModel = store.get(`${self.contextKey}-actionModel`);
                    simHref = store.get('window-location-href-simulation');
                }
                if (serverConfigParams.get('clientJunctionBasePath')) {
                    /*
                     * In production, reverse proxy servers usually front the application server
                     * in order to provide security related services. The URL can contain a base
                     * path (often referred to as 'junction') that directs the request to the
                     * reverse proxy server. Although the Bank Admin and the Client App will be
                     * on the same domain, the Junctions may differ which is why the relative
                     * path may need to be adjusted to reflect the proper junction configured
                     * for the Client
                     */
                    simHref = serverConfigParams.get('clientJunctionBasePath') + simHref;
                }
                simModel.genericObjectFunction({
                    success(m, resp) {
                        cookie.set('SIMULATIONSESSIONTOKEN', resp.message[0]);
                        /*
                         * if we using the old admin and opening a new window into the
                         * user-centric UI then load simulate session in old admin window
                         * and close the current user-centric window.
                         */
                        if (window.opener) {
                            window.opener.location.href = simHref;
                            window.close();
                        } else {
                            window.location.href = simHref;
                        }
                    },

                    error(response) {
                        AdminUtil.errorAlert(this.alertRegion, response);
                    },
                }, 'simulate');
            }
        });
    },

    templateHelpers() {
        const self = this;
        return {
            readOnly: self.mode === constants.MODES.VIEW,
            modify: self.mode === constants.MODES.MODIFY,
            addButtonLabel: locale.get(self.isRole ? 'rolebased.addRole' : 'uce.addUser'),

            getPageTitle() {
                return locale.get('menuitem.SETUP.UserMaint');
            },

            insertModeFirstUserStep() {
                return (self.mode === constants.MODES.INSERT && !self.step);
            },

            isUser: !this.skipUser,

            isPaymentsTabEnabled() {
                return self.tabs.PAYMENTS && (self.mode !== constants.MODES.VIEW
                    || self.tabs.PAYMENTS.hasSelectedComplexType());
            },

            isReportingTabEnabled() {
                return self.tabs.REPORTS && (self.mode !== constants.MODES.VIEW
                    || self.tabs.REPORTS.hasSimpleEntitlement());
            },

            isRiskTabEnabled() {
                return self.tabs.RISK && (self.mode !== constants.MODES.VIEW
                    || self.tabs.RISK.hasSimpleActionEntitlement());
            },

            isAdminTabEnabled() {
                return self.tabs.ADMIN && (self.mode !== constants.MODES.VIEW
                    || self.tabs.ADMIN.hasSimpleActionEntitlement());
            },

            isAlertsTabEnabled() {
                return self.tabs.ALERT && (self.mode !== constants.MODES.VIEW
                    || self.tabs.ALERT.hasSimpleEntitlement());
            },

            isABSTabEnabled() {
                return self.tabs.ABS && (self.mode !== constants.MODES.VIEW
                    || self.tabs.ABS.hasSimpleActionEntitlement()
                    || self.tabs.ABS.hasSelectedComplexType());
            },

            hasSimulateEntitlement() {
                return self.options.hasSimulate;
            },

            isDeepLinked: self.options.deeplinked,
            buttons: self.buttons,
            insert: this.mode === constants.MODES.INSERT,
            isBankMaintained: AdminUtil.isBankMaintainedRolesEnabled() && this.bankMaintained,
        };
    },
});
