/* istanbul ignore file */
import locale from '@glu/locale';

/**
 * Format a list of invalid files. This example shows combining them in one alert
 * message, but creating seperate lines for each file.
 * @param {Object} files - Invalid files to be formatted. Grouped according to error type
 * @param {integer} maxFileSize - used for setting verbiage.
 */
export function defaultInternalErrorFormat(files, maxFileSize) {
  const totalErrors = Object.keys(files)
    .filter(errorType => files[errorType].length)
    .reduce((acc, curr) => acc.concat(files[curr]), []).length;

  const errorMessageTemplate = {
    filesInvalidSize: locale.get('filesInvalidSize', maxFileSize),
    filesFailAllowlist: locale.get('filesFailAllowlist'),
    filesFailDenylist: locale.get('filesFailDenylist')
  };

  const lines = Object.keys(files)
    .map((errorType) => files[errorType]
      .map((file) => file.name)
      .map((filename) => `${filename} ${errorMessageTemplate[errorType]}`))
    .reduce((acc, curr) => acc.concat(curr), []);

  const errorMessage = [{
    title: totalErrors > 1
      ? `${totalErrors} ${locale.get('errorAlertTitlePlural')}`
      : `1 ${locale.get('errorAlertTitle')}`,
    message: lines,
    type: 'error'
  }];

  return totalErrors ? errorMessage : [];
}

export function defaultOnChange() { }
export function defaultSetMessages() { }
