import constants from 'app/administration/constants';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import EntitlementView from '../reporting/reportGroupView';

export default EntitlementView.extend({

    className: 'report-group rtp-messages',

    initialize(opts) {
        this.mode = opts.mode;

        this.entitlements = opts.collection;

        if (this.mode === constants.MODES.VIEW) {
            this.entitlements.reset(this.entitlements.filter((entitlement) => {
                const actions = entitlement.get('actions');
                return actions.view.entitled;
            }));
        }
    },

    templateHelpers() {
        return util.extend(
            EntitlementView.prototype.templateHelpers.call(this),
            {
                reportGroup: () => locale.get('uce.reportGroup.RTP'),
                rows: this.entitlements.chain()
                    .map((rec) => {
                        const actions = rec.get('actions');
                        return {
                            typeCode: rec.get('typeCode'),
                            entitled: actions.view.entitled,
                            cid: rec.cid,
                        };
                    })
                    .groupBy((rec, i) => Math.floor(i / 3))
                    .values()
                    .value(),
            },
        );
    },

});
