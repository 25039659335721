import ContextApi from 'common/dynamicPages/api/context';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import ContextModel from 'common/dynamicPages/models/context';
import UserGroupCollection from 'app/administration/collection/userGroups';
import UserModel from 'app/administration/models/user2/user';
import UserPendingChanges from 'app/administration/views/userMaintenance2/pendingChanges/pendingChanges';
import AdminUtil from 'app/administration/common/util';
import ViewApi from 'app/administration/common/dynamicPages/api/view';
import ResetPassword from 'common/admin/user/passwordReset';
import workspaceHelper from 'common/workspaces/api/helper';
import entitlements from 'common/dynamicPages/api/entitlements';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import cookie from 'system/cookie';
import constants from 'app/administration/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import BaseStepLayout from './baseStepLayout';
import UserGroupLookup from './userGroupLookup';
import Profile from './profile/profile';
import AccountTabs from './accounts/accountTabs';

export default BaseStepLayout.extend({
    STEP_MAP: {
        USER: 0,
        ...BaseStepLayout.prototype.STEP_MAP,
    },

    initialize(opts) {
        this.model = new UserModel();
        this.userId = opts.userId;
        BaseStepLayout.prototype.init(opts, this.model);

        /*
         * Retrieve configparam that determines whether to render User Tab
         * in a portal environment. The value is always set to true in an
         * non-portal environment since the User Profile tab should always
         * be rendered. The value of the configparam is not used in that case.
         */
        this.isProfileTabRequired = this.isDeepLink ? serverConfigParams.get('UCEDisplayUserTabInPortal') === 'true' : true;
        this.firstStep = !this.isProfileTabRequired ? this.STEP_MAP.PERM : this.STEP_MAP.USER;

        /*
         * Set the current step to the user step so we retrieve user info.  It is not
         * displayed for deep link.
         */
        this.currentStep = this.STEP_MAP.USER;
        this.initContext();

        this.gridModel = store.get(`${this.contextKey}-actionModel`);

        // dynamically set all applicable button actions from store for use in the view
        this.buttons = [];
        const buttons = store.has('uce-load') ? store.get('uce-load').buttons : null;
        if (buttons && this.mode === constants.MODES.VIEW) {
            this.buttons = util.filter(buttons, (buttonParam) => {
                const button = buttonParam;
                button.action = button.action.toLowerCase();
                return button.action !== constants.ACTIONS.SELECT;
            });
        }
    },

    additionalRender() {
        this.initUserProfile();
        if (this.mode === constants.MODES.VIEW) {
            this.initPendingChangesSection();
        }
    },

    initContext() {
        // constants.ROLE_MAINT);
        this.contextDef = ContextApi.menuContext.getContext(constants.USER_MAINT);

        if (this.isAdmin) {
            this.contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'USERS',
                productCode: '_ADMIN',
                subType: '*',
                typeCode: '_USER',
            };
        } else {
            this.contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'MAINT',
                productCode: 'CMAINT',
                subType: '*',
                typeCode: 'CLUSR_TM',
            };
        }

        this.contextModel = new ContextModel({
            menuCategory: this.options.menuCategory,
            serviceName: this.options.serviceName,
            serviceFunc: this.options.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();
    },

    initUserProfile() {
        const userFormView = this.steps[this.STEP_MAP.USER].pageView;

        this.listenTo(userFormView, 'loaded', function () {
            // If insert mode, set the usergroup value in the user profile model
            if (this.mode === constants.MODES.INSERT) {
                userFormView.model.set('USERGROUP', this.profileModel.get('USERGROUP'));

                // Default to by group.
                userFormView.model.set(
                    constants.PAYMENT_PERMISSIONS.GROUPING_COLUMN,
                    constants.PAYMENT_PERMISSIONS.BY_GROUP,
                );
            }

            // Add the inputted user model to section models
            this.model.userModel = userFormView.model;
            this.model.get('sections').each((sectionParam) => {
                const section = sectionParam;
                section.userModel = userFormView.model;
            });

            // this.initUserNameDisplay();

            let { userModel } = this.model;
            userModel = this.updateUserModelFieldInfoList(userModel);
            /*
             * When the user changes the COPYEXISTINGUSER attribute, the model should
             * fetch data
             * for the user selected and
             * update with that user's data
             */
            this.listenTo(userModel, 'change:COPYEXISTINGUSER', util.debounce(() => {
                /*
                 * check needed since on every render, the dynamic combolist attached to this
                 * event resets its values, triggering a change
                 */
                if (this.copyUserId !== userModel.get('COPYEXISTINGUSER')) {
                    this.triggerCopyUserData();
                }
            }, 500));

            this.listenTo(userModel, `change:${constants.PAYMENT_PERMISSIONS.GROUPING_COLUMN}`, function (model, permissionsGrouping) {
                this.steps[this.STEP_MAP.ACCT] = new AccountTabs({
                    model: this.model,
                    mode: this.mode,
                    permissionsChangedToByGroup: permissionsGrouping === 'PAYMENT_GROUP',
                });
            }, this);

            const displaySep = this.model.userModel.get('USERID') ? ' | ' : '';
            this.ui.displayInfo.text(`${this.model.userModel.get('USERID')}${displaySep}${this.model.userModel.get('USERNAME')}`);

            /*
             * Once the user info is retrieved, render the real first step, permissions,
             * if deep link.
             */
            if (this.isDeepLink) {
                this.changeStep(this.firstStep);
                if (store.has('addUserCentricSuccessMessage')) {
                    this.renderMessage(store.get('addUserCentricSuccessMessage'));
                    store.unset('addUserCentricSuccessMessage');
                }
            }

            /*
             * If copy from user is selected from context menu, set the userModel to
             * trigger the rendering of the permissions
             */
            if (this.options.copyFromUser) {
                userModel.set('COPYEXISTINGUSER', this.options.copyFromUser);
            }
        }, this);
    },

    initUserNameDisplay() {
        const { userModel } = this.model;

        if (serverConfigParams.get('userMaintSplitUserName') === 'true') {
            this.listenTo(userModel, 'change:USERID change:FIRSTNAME change:LASTNAME', function () {
                this.displayHeader(userModel.get('USERID'), `${userModel.get('FIRSTNAME')} ${userModel.get('LASTNAME')}`);
            }, this);
        } else {
            this.listenTo(userModel, 'change:USERID change:USERNAME', function () {
                this.displayHeader(userModel.get('USERID'), userModel.get('USERNAME'));
            }, this);
        }
    },

    /**
     * @method initPendingChangesSection
     * Creates the panel section where user can preview the pending changes
     * of a user with 'modified' status. If the user has approval and rejection
     * permissions, they will also be given that option in the view
     */
    initPendingChangesSection() {
        /*
         * Extract out Approve and Reject buttons from User Permitted Actions
         * NOTE: reject does not exist yet but will need to be referenced when available in
         * the future
         */
        const userActions = util.reduce(this.buttons, (actionsParam, btn) => {
            const actions = actionsParam;
            if (btn.action.toLowerCase() === constants.ACTIONS.APPROVE) {
                actions.approve = btn;
                actions.approve.callback = this.approve.bind(this);
            }
            return actions;
        }, {});

        // Create View
        this.pendingChangesView = new UserPendingChanges({
            userProfile: this.profileModel,
            actions: userActions,
        });

        this.pendingChangesRegion.show(this.pendingChangesView);
    },

    initStepOne() {
        let groupBy;
        const copyUserHasChanged = this.profileModel.hasChanged('COPYEXISTINGUSER');

        // Update permissions grouping when copying from a user.
        if (this.copyUserId) {
            groupBy = util.findWhere(
                this.model.get('userInfo').item,
                {
                    name: constants.PAYMENT_PERMISSIONS.GROUPING_COLUMN,
                },
            );

            if (this.steps[0].pageView && this.steps[0].pageView.model) {
                this.steps[0].pageView.model.set(
                    constants.PAYMENT_PERMISSIONS.GROUPING_COLUMN,
                    groupBy ? groupBy.value : constants.PAYMENT_PERMISSIONS.BY_GROUP,
                );
            }
        }
        // dont recreate userProfile step, if running after initial render
        const profileStep = copyUserHasChanged
            ? this.steps[0]
            : new Profile({
                contextDef: this.contextDef,
                model: this.profileModel,
                mode: this.mode,
                pageTitle: locale.get('administration.user.maint'),
                listViewLabel: locale.get('administration.user.maint.center'),
                returnRoute: constants.ROUTES.USER_LISTVIEW,
                entitlements: this.entitlements,
            });

        return profileStep;
    },

    /**
     * @override
     * @name load
     * @description load/fetch the userModel
     */
    load() {
        const isNotInsert = this.mode !== constants.MODES.INSERT;
        const metadrivenModel = new Model();

        metadrivenModel.id = this.userGroup + this.userId;
        metadrivenModel.set('USERGROUP', this.userGroup);
        metadrivenModel.set('USERID', this.userId);
        metadrivenModel.set('id', metadrivenModel.id);

        metadrivenModel.context = this.gridModel ? this.gridModel.context : this.contextDef;

        // Fully fetch user
        return new UserModel().fetch({
            userGroup: this.userGroup,
            userId: this.userId,
            copyUserId: this.copyUserId,
            success: (userModel) => {
                /*
                 * Check if Cannot Grant Beyond Own is on.
                 * If model is null it means that the CGBO
                 *  user does not have correct permissions
                 */
                if (this.isCannotGrantBeyondOwnUser) {
                    if (userModel.get('sections').length === 0 && userModel.get('userInfo').item === null) {
                        // Suppress buttons except for cancel
                        const action = locale.get(`action.${this.mode}`);

                        // clear copy user
                        if (this.model && this.model.userModel) {
                            this.copyUserId = '';
                            this.model.userModel.set('COPYEXISTINGUSER', '');
                        }

                        /*
                         * Remove buttons except for cancel unless copy user.
                         * If copy user, allow the user to select another user
                         * or manually enter information.
                         */
                        if (util.isNullOrUndefined(this.copyUserId)) {
                            this.setHasLoadedRequiredData(true);
                            this.render();
                            this.$('button').not((idx, $button) => $button.dataset.action === 'cancel').hide();
                            this.outputCGBOError(action);
                        } else {
                            this.outputCGBOError(action);
                        }

                        return;
                    }
                }
                this.model.set(userModel.toJSON());

                /*
                 * If we are not adding new user OR if we are attempting to copy
                 * existing USER PROFILE data
                 */
                if (isNotInsert || this.copyUserId) {
                    this.loadUserProfile(metadrivenModel, (loadedModel) => {
                        this.setProfile(loadedModel);
                        const entitlementsPromise = entitlements.getEntitlements({
                            context: {
                                serviceName: '/userMaintenance/user',
                            },
                        });

                        Promise.all([entitlementsPromise, this.loadUsergroup()])
                            .then((responseArray) => {
                                this.entitlements = responseArray[0].actions;
                                this.setHasLoadedRequiredData(true);
                                this.render();
                            })
                            .then(this.toggleLoading.bind(this, false));
                    });
                } else {
                    this.setProfile(new Model({
                        USERGROUP: this.userGroup,
                    }));
                    this.loadUsergroup().then(() => {
                        this.setHasLoadedRequiredData(true);
                        this.render();
                        this.toggleLoading(false);
                    });
                }
            },
        });
    },

    /**
     * @description outputs the CannotGrantBeyondOwn error for the action
     * @param {string} action - user's action (view/modify/add)
     */
    outputCGBOError(action) {
        // Indicate finished loading to remove disable, aria-busy.
        this.toggleLoading(false);
        /*
         * Render the alert message to show insufficient
         * permissions to view the user
         */
        AdminUtil.errorAlert(this.alertRegion, locale.get('uce.cgbo.insufficientPermissions', action, this.userId || this.model.userModel.get('USERID')));
    },

    /**
     * @param {model} metadrivenModel
     * @param {function} _successCallback
     * @return {promise}
     * Returns a promise that will fetch a user's model information
     * and perform the passed in success callback
     */
    loadUserProfile(metadrivenModel, _successCallback) {
        const self = this;
        return DataApi.model.generateModelFromModel(metadrivenModel).then((loadedModel) => {
            loadedModel.fetch({
                success: _successCallback,
                error: util.bind(errorHandlers.loading, self),
            });
        });
    },

    /**
     * @param {model} newUserModel
     * Merges the user's current working userModel and merges it with the passed in userModel
     * Used for cases where the user wants to copy another user's data
     */
    setProfile(newUserModel) {
        /*
         * merge our current profile model attributes with the fetched userprofile
         * model (IF WE HAVE ONE)
         */
        if (this.model.userModel) {
            newUserModel.set(this.model.userModel.toJSON());
        }

        this.profileModel = newUserModel;
    },

    /**
     * @method triggerCopyUserData
     * @return {Promise} the fetch user call
     * Callback method called when the COPYEXISTINGUSER prop of the userModel changes
     * will trigger the load call to fetch that userModel info to merge into the
     * current userModel
     * and re-init views to notify that we are starting a copyUser based flow
     */
    triggerCopyUserData() {
        this.copyUserId = this.model.userModel.get('COPYEXISTINGUSER');
        this.toggleLoading();
        return this.load();
    },

    setMobileMFAInfo() {
        /*
         * Remove 'Register Mobile Device' & 'Revoke Mobile Device'
         * buttons if the user is not configured mobile.
         */
        let mobileUserProperty = util.findWhere(
            this.model.get('userInfo').item,
            {
                name: 'MOBILEMFA_ENABLED',
            },
        );
        this.profileModel.userGroupModel.set('isMobileDeviceEnabled', !(util.isNullOrUndefined(mobileUserProperty) || mobileUserProperty.value === '0'));

        mobileUserProperty = util.findWhere(
            this.model.get('userInfo').item,
            {
                name: 'MOBILEDEVICE_REGISTERED',
            },
        );
        this.profileModel.userGroupModel.set('isMobileDeviceRegistered', !(util.isNullOrUndefined(mobileUserProperty) || mobileUserProperty.value === '0'));
    },

    loadUsergroup() {
        const userGroups = new UserGroupCollection({
            isAdmin: this.isAdmin,
        });

        const lookup = new UserGroupLookup({
            isAdmin: this.isAdmin,
        });

        lookup.setSearch(this.userGroup);
        lookup.setSearchOperator('EQ');
        return lookup.send().then((result) => {
            const recs = new Collection();
            recs.reset(userGroups.parse(result.data));

            this.model.userGroupModel = recs.get(this.userGroup);

            this.model.get('sections').each((sectionParam) => {
                const section = sectionParam;
                section.userGroupModel = this.model.userGroupModel;
                section.userModel = this.model.userModel;
            });

            this.profileModel.userGroupModel = this.model.userGroupModel;
            this.setMobileMFAInfo();

            if (this.hasLoadedRequiredData()) {
                this.initSteps();
            }
        });
    },

    /**
     * save the user
     */
    save() {
        const self = this;
        const { model } = this;
        const isModify = this.mode !== constants.MODES.INSERT;
        const profileModel = this.model.usermodel
                || this.steps[this.STEP_MAP.USER].pageView.model;

        // If CGBO validate limits first before saving.
        const limitsValid = !this.isCannotGrantBeyondOwnUser ? true : this.limitsValid();

        // If limits not valid
        if (!limitsValid) {
            // Go to limits tab if not already there
            if (this.currentStep !== this.STEP_MAP.LIMT) {
                this.changeStep(this.STEP_MAP.LIMT);
                this.showGenericLimitsError = true;
            }
            // render generic limits error message
            if (this.showGenericLimitsError) {
                const action = locale.get(this.mode === constants.MODES.INSERT ? 'action.create' : 'action.modify').toLowerCase();
                AdminUtil.errorAlert(this.alertRegion, locale.get('uce.limits.exceeded', action));
                this.showGenericLimitsError = false;
            }
        } else if (profileModel.isValid()) {
            // validate user form before saving
            this.ui.$save.attr('aria-busy', true);
            this.model.userModel.trigger('user:save');
            // Save interim changes prior to saving user.
            this.steps[this.STEP_MAP.PERM].pushEntitlementChanges().then(() => {
                this.beforeSave().then(
                    () => {
                    // Continue with save when all beforeSave promises are successful.
                        model.get('userInfo').item = self.generateUserInfo(profileModel);

                        model.save(
                            null,
                            {
                                isModify,

                                success(userModel, response) {
                                    self.ui.$save.attr('aria-busy', false);
                                    self.doActionResponse.call(self, 'save', response, self.options.returnRoute);
                                },

                                error(userModel, response) {
                                    self.ui.$save.attr('aria-busy', false);
                                    /*
                                     * Set to empty array to avoid dupes
                                     * because the onBeforeSave repopulates.
                                     */
                                    self.model.set('dataEntitlements', []);
                                    AdminUtil.errorAlert(self.alertRegion, response.responseJSON);
                                },
                            },
                        );
                    },
                    () => {
                    // TODO process error if a failure to save interim changes
                    },
                );
            });
        } else {
            if (this.currentStep !== this.firstStep) {
                this.changeStep(this.firstStep);
            }
            // Manually trigger validation
            util.defer(() => {
                self.$('input').trigger('blur');
            });
        }
    },

    beforeSave() {
        const promises = [];
        util.each(this.steps, (step) => {
            if (util.isFunction(step.beforeSave)) {
                promises.push(step.beforeSave());
            }
        });

        return Promise.all(promises);
    },

    generateUserInfo(profileModel) {
        const isModify = this.mode !== constants.MODES.INSERT;

        if (this.isAdmin) {
            /*
             * PARENTUSERGROUP only needs to be set on the Admin app.  In the Client app
             * this value is set by the defaultvalues returned from the getModelInfo call.
             */
            profileModel.set('PARENTUSERGROUP', this.model.userGroupModel.get('parentUserGroup'));
        }

        profileModel.set('PHONENUMBER', (ViewApi.convertPhoneNumbersToNumber(profileModel.get('PHONENUMBER'))));
        profileModel.set('FAXNUMBER', (ViewApi.convertPhoneNumbersToNumber(profileModel.get('FAXNUMBER'))));

        const updateCount = profileModel.get('UPDATECOUNT__');
        if (updateCount === '') {
            profileModel.set('UPDATECOUNT__', '0');
        }

        const strikes = profileModel.get('STRIKES');
        if (strikes === '') {
            profileModel.set('STRIKES', '0');
        }

        // unset the password if data is in modify mode
        const password = profileModel.get('PASSWORD');
        if (isModify && !password) {
            profileModel.unset(
                'PASSWORD',
                {
                    silent: true,
                },
            );
        }

        const activationDate = profileModel.get('ACTIVATIONDATE');
        if (activationDate === '' || activationDate === undefined) {
            profileModel.set('ACTIVATIONDATE', null);
        }
        let userProfilePairs = transform.hashToPairs(profileModel.toJSON());

        /*
         * An entry into the checkboxonoffvalues exists for CSQ. converting the data
         * before post to service
         */
        userProfilePairs = util.map(userProfilePairs, (pairParam) => {
            const pair = pairParam;
            if (pair.name === 'CLEARSECURITYQUESTIONS' && pair.value === undefined) {
                pair.value = '';
            }
            return pair;
        });

        return userProfilePairs;
    },

    cancel() {
        if (util.has(this.model, 'userModel')) {
            this.model.userModel.trigger('user:cancel');
        }
        store.unset('userCompany');
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    modify() {
        if (this.mode === constants.MODES.VIEW) {
            if (this.stack) {
                this.stack.pop();
            }
            this.navigateTo(constants.ROUTES.MODIFY_USER);
        }
    },

    copyfromuser() {
        if (this.stack) {
            this.stack.pop();
        }
        store.set(
            'uce-load',
            {
                userGroup: this.profileModel.get('USERGROUP'),
                copyFromUser: this.profileModel.get('USERID'),
                isUce: true,
            },
        );
        this.navigateTo(constants.ROUTES.ADD_USER);
    },

    /**
     * Next five functions stolen from
     * src/app/administration/views/userMaintenance/userLayout.js
     */

    delete() {
        this.doAction(constants.ACTIONS.DELETE);
    },

    disable() {
        this.doAction(constants.ACTIONS.DISABLE);
    },

    approve() {
        this.doAction(constants.ACTIONS.APPROVE);
    },

    restore() {
        this.doAction(constants.ACTIONS.RESTORE);
    },

    doAction(action) {
        this.profileModel[action]({
            success: (model, response) => {
                this.doActionResponse(action, response);
            },

            error: (model, response) => {
                this.doActionResponse(
                    action,
                    model.message ? JSON.parse(model.message) : response,
                );
            },
        });
    },

    /**
     * @description sets alert response object and navigates to next expected route
     * @param {string} action - action applied
     * @param {object} response - response object
     * @param {string} incReturnRoute - return route for deeplink
     */
    doActionResponse(action, response, incReturnRoute) {
        if (this.isDeepLink) {
            const alertResponse = {
                action,
                response,
            };
            store.set('addUserCentricSuccessMessage', alertResponse);
            if (incReturnRoute) {
                this.navigateTo(incReturnRoute);
            }
        } else {
            store.set(`${this.contextKey}-alertMessage`, action);
            store.set(`${this.contextKey}-confirms`, response);
            const returnRoute = store.get(`${this.contextKey}-listRoute`);
            this.navigateTo(returnRoute);
        }
    },

    /**
     * simulate user functionality
     */
    simulate() {
        const self = this;
        dialog.confirm(locale.get('btGrid.simulate.msg'), locale.get('title.confirm'), (ok) => {
            if (ok) {
                let simModel = self.gridModel;
                let simHref = '/ui';
                if (!simModel) {
                    simModel = store.get(`${self.contextKey}-actionModel`);
                    simHref = store.get('window-location-href-simulation');
                }
                if (serverConfigParams.get('clientJunctionBasePath')) {
                    /*
                     * In production, reverse proxy servers usually front the application server
                     * in order to provide security related services. The URL can contain a base
                     * path (often referred to as 'junction') that directs the request to the
                     * reverse proxy server. Although the Bank Admin and the Client App will be
                     * on the same domain, the Junctions may differ which is why the relative
                     * path may need to be adjusted to reflect the proper junction configured
                     * for the Client
                     */
                    simHref = serverConfigParams.get('clientJunctionBasePath') + simHref;
                }
                simModel.genericObjectFunction({
                    success(m, resp) {
                        cookie.set('SIMULATIONSESSIONTOKEN', resp.message[0]);
                        /*
                         * if we using the old admin and opening a new window into the
                         * user-centric UI then load simulate session in old admin window
                         * and close the current user-centric window.
                         */
                        if (window.opener) {
                            window.opener.location.href = simHref;
                            window.close();
                        } else {
                            window.location.href = simHref;
                        }
                    },

                    error(response) {
                        AdminUtil.errorAlert(this.alertRegion, response);
                    },
                }, 'simulate');
            }
        });
    },

    /*
     * TODO: This is *essentially* duplicated from
     * src/app/administration/views/userMaintenance/list.js
     * There is a certain amount of finger crossing because that depended on an
     * update count.
     */
    resetpw() {
        const userModel = this.steps[this.STEP_MAP.USER].pageView.model;
        let hasResults;
        this.passwordReset = new ResetPassword({
            name: userModel.get('USERID'),
            realm: userModel.get('USERGROUP') || this.userGroup,
            updateCount: userModel.get('UPDATECOUNT__'),
            isForgottenPassword: true,
            entitlements: this.entitlements,
        });

        dialog.open(this.passwordReset);

        this.listenTo(this.passwordReset, 'close', () => {
            dialog.close();
        });

        this.listenTo(this.appBus, 'forgottenPassword:reset:success', (response) => {
            // NH-99449 redirecting the user to the list view
            if (response) {
                response.message = [locale.get('panel.ResetPwd')];
            }

            try {
                hasResults = response.confirms.confirmResults.length > 0;
            } catch (e) {
                hasResults = false;
            }

            // NH-99449 do not need additional messages.
            if (hasResults) {
                response.confirms.confirmResults[0].messages = null;
            }

            if (this.isDeepLink) {
                this.renderMessage(locale.get('panel.ResetPwd'));
            } else {
                this.doActionResponse('reset', response);
            }
        });
    },

    updset() {
        this.navigateTo(constants.ROUTES.UPDATE_USER_SETTING);
    },

    /**
     * @method updateUserModelFieldInfoList
     * @description this method will remove "USERID" from dependsOn of COPYEXISTINGUSER
     * field on insert to prevent the UI from re-rendering the COPYEXISTINGUSER comboBox
     * when user update the USERID field.
     * @param {Model} userModel
     * @returns {Model} userModel
     */
    updateUserModelFieldInfoList(userModel) {
        if (this.options.mode === 'insert') {
            userModel.jsonData.fieldInfoList.map((field) => {
                const updatedField = field;
                if (updatedField.name === 'COPYEXISTINGUSER') {
                    updatedField.dependsOn = updatedField.dependsOn.filter(f => f !== 'USERID');
                }
                return updatedField;
            });
        }
        return userModel;
    },

    templateHelpers() {
        return {
            ...BaseStepLayout.prototype.templateHelpers.call(this),
            addButtonLabel: locale.get('uce.addUser'),
            hasSimulateEntitlement: this.options.hasSimulate,
            isTabOneRequired: this.isProfileTabRequired,
            permissionStep: (this.isProfileTabRequired) ? 2 : 1,
            accountStep: (this.isProfileTabRequired) ? 3 : 2,
            limitStep: (this.isProfileTabRequired) ? 4 : 3,
            step1Label: locale.get('uce.define-user'),
        };
    },
});
