var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4>"
    + alias3(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":19}}}) : helper)))
    + "</h4>\n<p>"
    + alias3(lookupProperty(helpers,"locale").call(alias1,"RTGS.Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":28}}}))
    + " # "
    + alias3((lookupProperty(helpers,"maskIt")||(depth0 && lookupProperty(depth0,"maskIt"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0),{"name":"maskIt","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":55}}}))
    + "</p>";
},"useData":true});