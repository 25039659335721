import moment from 'moment';
import util from 'underscore';
import constants from '../../api/constants';
import userInfo from '../../../../etc/userInfo';
import serverConfigParams from '../../../../system/webseries/models/configurationParameters';
import amountInputTmpl from './componentTemplates/amountInput.hbs';
import buttonLinkTmpl from './componentTemplates/buttonLink.hbs';
import checkboxTmpl from './componentTemplates/checkbox.hbs';
import comboBoxTmpl from './componentTemplates/comboBox.hbs';
import comboBoxFilterTmpl from './componentTemplates/comboBoxFilter.hbs';
import dateFilterTmpl from './componentTemplates/dateFilter.hbs';
import dateTmpl from './componentTemplates/datePicker.hbs';
import fileTmpl from './componentTemplates/file.hbs';
import gridComponentTmpl from './componentTemplates/gridComponent.hbs';
import gridTmpl from './componentTemplates/gridHolder.hbs';
import lookupTmpl from './componentTemplates/lookup.hbs';
import lookupComboBoxTmpl from './componentTemplates/lookupComboBox.hbs';
import lookupMultiTmpl from './componentTemplates/lookupMulti.hbs';
import lookupTextInputTmpl from './componentTemplates/lookupTextInput.hbs';
import lookupTypeAheadTmpl from './componentTemplates/lookupTypeAhead.hbs';
import multiComboBoxFilterTmpl from './componentTemplates/multiComboBoxFilter.hbs';
import multiSelectFilterTmpl from './componentTemplates/multiSelectFilter.hbs';
import multiTypeAheadTmpl from './componentTemplates/multiTypeAhead.hbs';
import numericFilterTmpl from './componentTemplates/numericFilter.hbs';
import passwordInputTmpl from './componentTemplates/passwordInput.hbs';
import radioTmpl from './componentTemplates/radio.hbs';
import rcountTmpl from './componentTemplates/rcount.hbs';
// Templates
import readOnlyTmpl from './componentTemplates/readOnly.hbs';
import selectComboTmpl from './componentTemplates/selectCombo.hbs';
import singleSelectFilterTmpl from './componentTemplates/singleSelectFilter.hbs';
import textareaTmpl from './componentTemplates/textarea.hbs';
import textFilterTmpl from './componentTemplates/textFilter.hbs';
import textInputTmpl from './componentTemplates/textInput.hbs';
import textlineTmpl from './componentTemplates/textline.hbs';
import timeTmpl from './componentTemplates/timeField.hbs';
import typeAheadTmpl from './componentTemplates/typeAhead.hbs';
import uiWidgetTmpl from './componentTemplates/uiWidget.hbs';
import { buildOptions, callTemplate, camelize } from './mdfUtil';
import readOnlyHelpers from './readOnlyHelpers';
import viewHelpers from './viewHelpers.js';

const GROUPING_COUNT = 3;

const typeaheadHelper = function (jsonFieldParam) {
    const jsonField = jsonFieldParam;
    if (jsonField.relatedProperty) {
        jsonField.blockClass += ' lookup-container';
    }

    if (jsonField.fieldUIType === 'TYPEAHEAD_HELPERTEXT') {
        jsonField.cssClass += ' helper-text-container';
    }

    if (jsonField.preferredList) {
        jsonField.cssClass += ' preferred-list';
    }

    /**
     * The comboModel is the context for the typeahead template.  Most of the
     * data in the jsonField comes from the meta-data field definition.
     * The cssInfoClass provides styling for the info text that appears under the
     * typeahead field.
     *
     */
    const comboModel = jsonField;
    comboModel.fieldName = comboModel.name;
    comboModel.cssInfoClass = comboModel.cssInfoClass || '';
    comboModel.blockClass = comboModel.blockClass || '';

    let tmpl = '';

    if (jsonField.multi) {
        tmpl = multiTypeAheadTmpl(comboModel);
    } else if (jsonField.relatedProperty) {
        tmpl += lookupTypeAheadTmpl(comboModel);
    } else {
        tmpl += typeAheadTmpl(comboModel);
    }
    return tmpl;
};

const standardFieldHelpers = {
    // Default Calendar Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    CALENDAR(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';

        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }

        return tmpl + callTemplate(dateTmpl, jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    CALENDAR_ENABLEALL(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';

        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }
        if (jsonField.info) {
            jsonField.cssInfoClass = 'textline-field';
        }

        return tmpl + callTemplate(dateTmpl, jsonField);
    },

    // Default Checkbox Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    CHECKBOX(view, jsonField) {
        const newJsonField = { ...jsonField };
        if (jsonField.name === 'RESTRICTTEMPLATE_FLAG') {
            const classes = newJsonField.blockClass.concat(' restricted-checkbox');
            newJsonField.blockClass = classes;
        }
        return checkboxTmpl(newJsonField);
    },

    // Default Combobox Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    COMBOBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';

        if ((jsonField.protected && view.state !== 'insert') || (jsonField.protected && jsonField.locked)) {
            tmpl += readOnlyHelpers.COMBOBOX(view, jsonField);
            jsonField.blockClass = 'hidden';
        } else if (jsonField.relatedProperty) {
            jsonField.blockClass += ' lookup-container';
        }
        const comboModel = buildOptions(jsonField);
        comboModel.list = view.comboCollections[jsonField.name];
        comboModel.fieldUiToolTip = jsonField.fieldUiToolTip;
        comboModel.protected = jsonField.protected;

        if (jsonField.relatedProperty) {
            tmpl += lookupComboBoxTmpl(comboModel);
        } else {
            // use comboTmpl, need name, fieldLable and list (collection)
            tmpl += comboBoxTmpl(comboModel);
        }

        return tmpl;
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    CHILDGRID(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';
        if (jsonField.protected && view.state !== 'create') {
            tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }
        // use comboTmpl, need name, fieldLable and list (collection)
        return tmpl + callTemplate(gridTmpl, jsonField);
    },

    // Default Combobox Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    COMBOSELECT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';

        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }

        const optionsObject = buildOptions(jsonField);
        optionsObject.list = jsonField.choices;
        optionsObject.fieldUiToolTip = jsonField.fieldUiToolTip;
        optionsObject.isDisabled = jsonField.disabled || jsonField.protected;

        // use comboTmpl, need name, fieldLable and list (collection)
        return tmpl + selectComboTmpl(optionsObject);
    },

    // Default Radio Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    RADIO(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';

        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyHelpers.RADIO(view, jsonField);
            jsonField.blockClass = 'hidden';
        }

        return tmpl + radioTmpl(jsonField);
    },

    // Default Textarea Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TEXTAREA(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';
        let rows = constants.TEXTAREAROWS;
        if (jsonField.relatedProperty) {
            rows = parseInt(jsonField.relatedProperty, 10);
        }

        jsonField.rows = rows;

        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }
        if (jsonField.info) {
            jsonField.cssInfoClass = 'textline-field';
        }

        return tmpl + textareaTmpl(jsonField);
    },

    // mimic behavior of textbox
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    CURRENCY(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';

        jsonField.rows = constants.TEXTAREAROWS;

        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }

        return tmpl + textareaTmpl(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TYPEAHEADFREE(view, jsonField) {
        return typeaheadHelper(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TYPEAHEAD_HELPERTEXT(view, jsonField) {
        return typeaheadHelper(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TYPEAHEAD_PREFERRED(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.preferredList = true;
        return typeaheadHelper(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    MULTITYPEAHEAD(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.multi = true;
        return typeaheadHelper(jsonField);
    },

    /*
     * type ahead control helper
     * TODO: get this new type implemented in the metadata
     * Right now this is the same as TEXTBOX w/ typeahead fields
     * w/ inquiryid and possibly relatedproperty
     */
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TYPEAHEAD(view, jsonField) {
        return typeaheadHelper(jsonField);
    },

    // Default FILE Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    FILE(view, jsonField) {
        let tmpl = '';
        tmpl += fileTmpl(jsonField);
        return tmpl;
    },

    // Default Textbox Helper
    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';
        let relatedPropField;
        jsonField.cid = view.cid;

        // uncomment once we get backend meta data sorted
        if (jsonField.typeAhead && util.isEmpty(jsonField.typeAhead) === false) {
            if (jsonField.protected && view.state !== 'insert') {
                return readOnlyHelpers.TEXTAREA(view, jsonField);
            }
            if (jsonField.info) {
                jsonField.cssInfoClass = 'textline-field';
            }
            return typeaheadHelper(jsonField);
        }

        if ((jsonField.protected && view.state !== 'insert' && view.state !== 'modify') || (jsonField.protected && jsonField.locked)) {
            tmpl += readOnlyHelpers.TEXTBOX(view, jsonField);
        } else {
            if (jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT') {
                /*
                 * If maxlength is specified, compute an actual maxlength which
                 * includes comma separators
                 */
                if (jsonField.maxLen) {
                    // save this in a data- attribute for use in the inputmask
                    jsonField.originalMaxLen = jsonField.maxLen;
                    jsonField.maxLen += Math.floor(jsonField.maxLen / GROUPING_COUNT);
                }
            }
            if (jsonField.info) {
                jsonField.cssInfoClass = 'textline-field';
            }

            if ((jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT') && jsonField.relatedProperty) {
                /*
                 * If the relatedProperty is a comboBox, pass in the collection of
                 * selections.
                 */
                jsonField.creditCurrencyList = (view.comboCollections[jsonField.relatedProperty]);
                jsonField.currencyCombo = (!util.isNullOrUndefined(jsonField.creditCurrencyList));
                relatedPropField = util.findWhere(
                    view.jsonModel,
                    {
                        name: jsonField.relatedProperty,
                    },
                );
                jsonField.currencyLabel = relatedPropField ? relatedPropField.fieldLabel : '';
                tmpl += amountInputTmpl(jsonField);
            } else if ((jsonField.fieldType === 'TEMPLATECODE') && view.state === 'modify'
                && (view.functionCode !== 'INST' && view.functionCode !== 'BATCH' && view.functionCode !== 'REQUEST')) {
                tmpl += readOnlyHelpers.TEXTBOX(view, jsonField);
            } else if (jsonField.protected && view.state === 'modify' && !jsonField.relatedProperty) {
                /*
                 * modify use to not be caught in the outside if
                 * now we catch and allow for lookup use, if related property exists
                 */
                tmpl += readOnlyHelpers.TEXTBOX(view, jsonField);
            } else if (jsonField.relatedProperty && !Object.prototype.hasOwnProperty
                .call(view.model.showHideFields, jsonField.relatedProperty)) {
                jsonField.blockClass += ' lookup-container';
                tmpl += lookupTextInputTmpl(jsonField);
            } else {
                tmpl += textInputTmpl(jsonField);
            }
        }

        return tmpl;
    },

    /**
     * {Object} view
     * {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTBOX_HELPERTEXT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';

        // uncomment once we get backend meta data sorted
        if (jsonField.typeAhead && util.isEmpty(jsonField.typeAhead) === false) {
            if (jsonField.protected && view.state !== 'insert') {
                return readOnlyHelpers.TEXTAREA(view, jsonField);
            }
            return typeaheadHelper(jsonField);
        }

        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyHelpers.TEXTBOX(view, jsonField);
        } else {
            if (jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT') {
                /*
                 * If maxlength is specified, compute an actual maxlength which
                 * includes comma separators
                 */
                if (jsonField.maxLen) {
                    // save this in a data- attribute for use in the inputmask
                    jsonField.originalMaxLen = jsonField.maxLen;
                    jsonField.maxLen += Math.floor(jsonField.maxLen / GROUPING_COUNT);
                }
            }
            if (jsonField.info) {
                jsonField.cssInfoClass = 'textline-field';
            }

            if ((jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT') && jsonField.relatedProperty) {
                tmpl += amountInputTmpl(jsonField);
            } else {
                tmpl += textInputTmpl(jsonField);
            }
        }

        return tmpl;
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TEXTFILTER(view, jsonField) {
        const tmpl = '';

        const optionsObject = buildOptions(jsonField);
        optionsObject.operators = view.operators;
        optionsObject.isOpSelected = view.isOpSelected;

        return tmpl + textFilterTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    SIMPLENUMERICFILTER(view, jsonField) {
        const tmpl = '';

        const optionsObject = buildOptions(jsonField);
        optionsObject.operators = view.simpleOperators;
        optionsObject.isOpSelected = view.isOpSelected;

        return tmpl + numericFilterTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    SIMPLENUMERIC2FILTER(view, jsonField) {
        const tmpl = '';

        const optionsObject = buildOptions(jsonField);
        optionsObject.operators = view.simpleOperatorsExt;
        optionsObject.isOpSelected = view.isOpSelected;

        return tmpl + numericFilterTmpl(optionsObject);
    },


    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    SIMPLENUMERICEXCLUDEIN(view, jsonField) {
        const tmpl = '';

        const optionsObject = buildOptions(jsonField);
        optionsObject.operators = view.operatorsWithoutIn;
        optionsObject.isOpSelected = view.isOpSelected;

        return tmpl + numericFilterTmpl(optionsObject);
    },

    // Second account number widget
    /*
     * param {Object} jsonField
     * @returns {String}
     */
    SECONDACCOUNTNUM(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SECONDACCOUNTNUMWIDGET',
            blockClass: 'col-md-2 col-mdlg-4',
            fieldName: jsonField.name,
            shouldBeProtected: jsonField.protected,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    NUMERICFILTER(view, jsonField) {
        const tmpl = '';
        const optionsObject = buildOptions(jsonField);
        optionsObject.operators = view.operators();
        optionsObject.isOpSelected = view.isOpSelected;

        return tmpl + numericFilterTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    DATEFILTER(view, jsonField) {
        const tmpl = '';

        // TODO readonly
        // if (jsonField.protected && view.state !== 'insert') {
        //     tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
        //     jsonField.blockClass = 'hidden';
        // }

        const optionsObject = buildOptions(jsonField);
        optionsObject.operators = view.operators;
        optionsObject.isOpSelected = view.isOpSelected;

        return tmpl + dateFilterTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TIMEFIELD(view, jsonField) {
        const timeZone = userInfo.get('timezone') || serverConfigParams.get('SystemTimezone');
        const tmpl = '';

        const timeOptions = {
            hasTimeZone: true,
            hasCutOffText: false,
            ...view.time,
        };

        const timeZoneClass = (jsonField.relatedProperty === 'TIMEZONE_BOTTOM')
            ? 'addon-bottom' : 'input-group-addon addon-right';

        const optionsObject = {
            ...buildOptions(jsonField),
            ...timeOptions,
            userTimeZone: moment(new Date())
                .tz(timeZone)
                .format('z'),
            timeZoneClass,
        };

        return tmpl + timeTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    COMBOFILTER(view, jsonField) {
        const tmpl = '';

        const optionsObject = buildOptions(jsonField);
        optionsObject.list = view.comboCollections[jsonField.name];

        return tmpl + comboBoxFilterTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    COMBOFILTERLT(view, jsonField) {
        const tmpl = '';

        const optionsObject = buildOptions(jsonField);
        optionsObject.list = view.comboCollections[jsonField.name];

        return tmpl + comboBoxFilterTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    MULTICOMBOFILTER(view, jsonField) {
        const tmpl = '';
        const optionsObject = buildOptions(jsonField);
        optionsObject.list = view.comboCollections[jsonField.name];

        return tmpl + multiComboBoxFilterTmpl(optionsObject);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    SINGLEGRIDFILTER(view, jsonField) {
        let tmpl = '';
        if (jsonField.protected && view.state !== 'insert') {
            tmpl += readOnlyTmpl(jsonField);
            return tmpl;
        }
        return singleSelectFilterTmpl(buildOptions(jsonField));
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    MULTIGRIDFILTER(view, jsonField) {
        return multiSelectFilterTmpl(buildOptions(jsonField));
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    MULTICHECKFILTER(view, jsonField) {
        return standardFieldHelpers.MULTIGRIDFILTER(view, jsonField);
    },

    // Default password Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    PASSWORD(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';
        if (jsonField.protected && view.state !== 'create') {
            jsonField.blockClass = 'hidden';
            tmpl += readOnlyHelpers.PASSWORD(view, jsonField);
        }
        if (jsonField.info) {
            jsonField.cssInfoClass = 'textline-field';
        }
        tmpl += passwordInputTmpl(jsonField);
        return tmpl;
    },

    // Default Textline Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TEXTLINE(view, jsonField) {
        return textlineTmpl(jsonField);
    },

    // Default Textline Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    RCOUNT(view, jsonField) {
        return rcountTmpl(jsonField);
    },

    // Default Button Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    BUTTON(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        jsonField.buttonClass = 'btn-secondary';
        return buttonLinkTmpl(jsonField);
    },

    // Default Primary Button Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    BUTTONPRIMARY(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        jsonField.buttonClass = 'btn-primary';
        return buttonLinkTmpl(jsonField);
    },

    // Default Small Button Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    BUTTONSMALL(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        jsonField.buttonClass = 'btn-secondary btn-sm';
        return buttonLinkTmpl(jsonField);
    },

    // Default ButtonLink Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    BUTTONLINK(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        jsonField.buttonClass = 'btn-tertiary';
        return buttonLinkTmpl(jsonField);
    },

    // Default ButtonLink Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    BUTTONLINKSMALL(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        jsonField.buttonClass = 'btn-tertiary btn-sm';
        /*
         * This is a bit contrived, but it is only for screens
         * that do not use the benewidget
         * When the screens are converted this can go away.
         */
        if (jsonField.name.toUpperCase() === 'CLEARBENE') {
            jsonField.dataHook = 'clear-bene';
        }
        return buttonLinkTmpl(jsonField);
    },

    // Default ReportLink Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    REPORTLINK(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        return buttonLinkTmpl(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    LOOKUPSINGLE(view, jsonField) {
        return lookupTmpl(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    LOOKUPMULTI(view, jsonField) {
        return lookupMultiTmpl(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    GRID(view, jsonField) {
        return gridComponentTmpl(jsonField);
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    BASEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'BASEWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    VALUEDATEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            blockClass: jsonField.blockClass,
            cssWidgetClass: 'inline-fields',
            fieldName: jsonField.name,
            name: camelize(jsonField.name),
            widgetType: 'VALUEDATEWIDGET',
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    ADDRESSFIELDWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'ADDRESSFIELDWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    AUDITSECTIONWIDGET(view, jsonField) {
        if (view.state !== 'insert') {
            return uiWidgetTmpl({
                name: camelize(jsonField.name),
                widgetType: 'AUDITSECTIONWIDGET',
                fieldName: jsonField.name,
            });
        }
        return ''; // dont return any mark-up in insert mode
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    AUDITHISTORY(view, jsonField) {
        if (view.state !== 'insert') {
            return uiWidgetTmpl({
                name: camelize(jsonField.name),
                widgetType: 'AUDITHISTORYWIDGET',
                fieldName: jsonField.name,
                cssWidgetClass: 'audit-list-view',
            });
        }
        return ''; // dont return any mark-up in insert mode
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    SUMMARYSECTIONWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SUMMARYSECTIONWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TRANSACTIONSUMMARY(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'TRANSACTIONSUMMARYWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TRANSACTIONAUDIT(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'TRANSACTIONAUDITWIDGET',
            fieldName: jsonField.name,
        });
    },

    // Default MultiSelect Combobox Widget Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    MULTICOMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'MULTISELECTCOMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    COMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'COMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    DOUBLECOMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'DOUBLECOMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    FXPAYMENTWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'FXPAYMENTWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    RANGEFIELDWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'RANGEFIELDWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
            blockClass: jsonField.blockClass,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    SCHEDPAYWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SCHEDULEPAYMENTWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    ACHAUTHRULESSCHEDULE(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'ACHAUTHRULESSCHEDULE',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    STATETOGGLE(view, jsonField) {
        if (view.options.itemIndex) {
            return ''; // don't output the toggle for multi-item views
        }
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'TYPETOGGLEWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    PMXREMITTANCE(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'PMXREMITTANCEWIDGET',
            fieldName: jsonField.name,
        });
    },

    PMXCHECKPRINT(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'PMXCHECKPRINTDETAILWIDGET',
            fieldName: jsonField.name,
        });
    },

    // Protected Amount Widget
    /*
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    PROTECTAMOUNTWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            blockClass: jsonField.blockClass,
            cssWidgetClass: 'inline-fields',
            fieldName: jsonField.name,
            name: camelize(jsonField.name),
            widgetType: 'PROTECTAMOUNTWIDGET',
        });
    },

    SIMPLETABLEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SIMPLETABLEWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-12',
        });
    },

    LISTVIEWWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'LISTVIEWWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-12',
        });
    },

    DOCUMENTWIDGET(view, jsonField) {
        const viewParam = view;
        viewParam.useAltGrid = true;
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'DOCUMENTWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
            blockClass: 'col-12',
        });
    },

    CONVERSATIONWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'CONVERSATIONWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-6',
        });
    },

    LOCKTOGGLEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'LOCKTOGGLEWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-6',
        });
    },

    MASKEDINPUTWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            blockClass: jsonField.blockClass,
            cssWidgetClass: 'inline-fields',
            fieldName: jsonField.name,
            name: camelize(jsonField.name),
            widgetType: 'MASKEDINPUTWIDGET',
        });
    },

    /**
     in insert and modify mode, the TOGGLEMASKEDINPUT functions exactly like the MASKEDINPUTWIDGET
     we cannot toggle maskined in these modes, only in view
     */
    TOGGLEMASKEDWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            blockClass: jsonField.blockClass,
            cssWidgetClass: 'inline-fields',
            fieldName: jsonField.name,
            name: camelize(jsonField.name),
            widgetType: 'MASKEDINPUTWIDGET',
        });
    },

    TOGGLEWIDGET() {
        return '';
    },

    RTPTOGGLEWIDGET() {
        return '';
    },

    /*
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    IMAGEVIEWER(...args) {
        return viewHelpers.IMAGEVIEWER(...args);
    },

};

export default standardFieldHelpers;
