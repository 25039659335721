import util from '@glu/core/src/util';
import Handlebars from 'handlebars';
import spinner from './spinner';
import appRoot from './appRoot';
import panel from './panel';
import panelWithTotals from './panelWithTotals';
import imgRoot from './imgRoot';
import buildId from './buildId';
import gridUtilitySection from './gridUtilitySection';
import logoImagePath from './logo';
import fdicLogoImagePath from './fdicLogo';
import { processFooter } from './processTokens';


const helpers = [
    spinner,
    appRoot,
    panel,
    imgRoot,
    buildId,
    gridUtilitySection,
    panelWithTotals,
    logoImagePath,
    fdicLogoImagePath,
    {
        processFooter,
    },
    {
        // NH-170513
        // checking marketing logo link in login
        // screen is the valid or not
        isLoginMarketingLinkValid: link => ((link.includes('https://') || link.includes('http://')) && !link.includes(' ')),

    },
];

util.each(helpers, (helper) => {
    util.each(helper, (fn, name) => {
        Handlebars.registerHelper(name, fn);
    });
});

export default helpers;
