import Collection from '@glu/core/src/collection';
import services from 'services';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({

    initialize(models, options) {
        if (options) {
            this.inquiryId = options.inquiryId;
            this.buildSearchFields(options);
        }
    },

    requestBody() {
        return {
            startRow: 1,
            // Restricted Templates grid is not paginated, so need to return all the records
            dataOnly: 0,
            requestParameters: {
                item: [{
                    name: 'inquiryID',
                    value: this.inquiryId,
                }],
            },
            searchFields: this.searchFields,
        };
    },

    sync(method, collection, options) {
        return http.post(
            services.generateUrl(`/users/userCentric/v2/getDataEntitlements${options.copyingUser ? 'Live' : ''}`),
            this.requestBody(), options.success, options.error,
        );
    },

    parse(response) {
        return util.chain(response.rows)
        // get the map
            .map((row) => {
                const data = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
                data.CHECKED = data.CHECKED === '1';
                return data;
            })
        // sort by contact type because it comes in random order
            .sortBy('CONTACTTYPE')
            .value();
    },

    buildSearchFields(options) {
        this.searchFields = [{
            fieldName: 'Usergroup',
            fieldValue: [
                options.userGroup,
            ],
            dataType: 'text',
            operator: '=',
        }, {
            fieldName: 'actionMode',
            fieldValue: [
                options.mode,
            ],
            dataType: 'text',
            operator: '=',
        }];
        if (options.userId) {
            this.searchFields.push({
                fieldName: 'UserID',
                fieldValue: [
                    options.userId,
                ],
                dataType: 'text',
                operator: '=',
            });
        } else {
            this.searchFields.push({
                fieldName: 'RoleId',
                fieldValue: [
                    options.roleId,
                ],
                dataType: 'text',
                operator: '=',
            });
        }
    },
});
