var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.scenario.viewer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":49}}}))
    + "</h2>\n\n        <div class=\"list-container\">\n        <div class=\"container line-list\"></div>\n        </div>\n\n        <button class=\"btn btn-secondary btn-block btn-edit-lines\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.edit.scenario.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":67},"end":{"line":8,"column":106}}}))
    + "</button>\n";
},"useData":true});