import Population from 'app/challengeManager/collections/population';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';
import UserModel from 'app/challengeManager/models/users';

const Users = Population.extend({
    comparator: 'primaryKey',
    model: UserModel,

    parse(response) {
        return response.rows.map((row) => {
            const user = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            user.primaryKey = `${user.USERGROUP}-${user.USERID}`;
            user.totalRows = response.totalRows;
            user.totalPages = response.totalPages;
            user.type = 'USERS';
            return user;
        });
    },

    sync(method, model, options) {
        const url = services.generateUrl('/challengeManager/user/getListView');

        if (method === 'read') {
            // fieldValue holds IDs to populate target collection during view & modify
            if (this.fieldValue) {
                this.data.searchFields = [{
                    fieldName: 'USERID',
                    operator: 'IN',
                    fieldValue: this.fieldValue,
                    dataType: 'text',
                }];
            }

            const searchValue = this.getSearchCriteria();
            /*
             * There needs to be a way to use an OR operator. Searching against these
             * two for exact
             * matches with both is not ideal. (e.g. UID: ADMIN1, NAME: Bob)
             * Search returns empty in this scenario
             */

            if (searchValue && searchValue.length > 0) {
                this.data.searchFields = [{
                    fieldName: 'USERID_USERNAME',
                    operator: 'CONTAINS',
                    fieldValue: this.getSearchCriteria(),
                    dataType: 'text',
                }];
            }

            http.post(url, this.data, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
    },
});

export default Users;
