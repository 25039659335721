import React from 'react';
import {
    ListWidget,
    AlertWrapper,
    FooterWrapper,
} from 'components/AlertContentManagement/alert-content-management';
import store from 'system/utilities/cache';

export default {
    alertManagementLayout() {
        this.showPage('', <ListWidget />);
    },
    alertManagementAction(action) {
        const model = store.get('alertContentManagement_alerts-actionModel');
        this.showPage('', <AlertWrapper
            returnRoute="/CMAINT/alertContentManagement"
            mode={action}
            modelData={model?.toJSON?.()}
            modelActions={model?.buttons}
        />);
    },
    footerManagementAction(action) {
        const model = store.get('alertContentManagement_footers-actionModel');
        this.showPage('', <FooterWrapper
            returnRoute="/CMAINT/alertContentManagement"
            mode={action}
            modelData={model?.toJSON?.()}
            modelActions={model?.buttons}
        />);
    },
};
