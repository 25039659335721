import { appBus } from '@glu/core';
import backboneStore from 'system/utilities/cache';

const moduleSupport = {
    setup() {
        /*
         * Provide event to allow external modules to retrieve slices of the
         * legacy backbone store. This can be either a full slice of context for stored model
         * or also a particular key on that stored context model (e.g. buttons)
         */
        const getLegacyStoreValue = (key, storeSlice) => {
            const chain = key.split('.');
            return chain.reduce((acc, cur, idx) => {
                const obj = idx === 0 ? storeSlice : acc;
                if (idx === 0) {
                    return obj[cur];
                }
                return typeof acc === 'undefined' ? undefined : acc[cur];
            }, undefined);
        };
        document.addEventListener('dbiqe:legacystore:get', ({ detail: { cb = () => {}, key, storeContext } }) => {
            const storeSlice = backboneStore.get(storeContext);
            if (key) {
                cb(getLegacyStoreValue(key, storeSlice));
                return;
            }
            cb(storeSlice);
        });

        document.addEventListener('dbiqe:legacystore:set', ({ detail: { cb = () => {}, values = [] } }) => {
            values.forEach(pair => backboneStore.set(pair.key, pair.value));
            cb();
        });

        /*
         * Provide event to allow external modules to trigger legacy routing once integrated.
         */
        document.addEventListener('dbiqe:navigate', ({ detail: { route, options = {} } }) => {
            appBus.trigger('router:navigate', route, {
                trigger: true,
                ...options,
            });
        });
    },
};

export default moduleSupport;
