import AdAbsParent from '../AdminAbsParent';
import GroupView from './groupView';
import adminTmpl from './admin.hbs';

export default AdAbsParent.extend({
    template: adminTmpl,

    getGroupView() {
        return GroupView;
    },
});
