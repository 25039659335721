import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import moment from 'moment';

/*
 * if you are near the edges of the slider, these variables
 * come into play.  The tolerance lower tolerance ratio forces
 * the slider to come back into default alignment when you're
 * close to a year from 'today'.  The forward ratio springs
 * the slider wider if you are more than the tolerance ratio -
 * allowing you to keep setting the slider wider.
 */
const YEAR_BOUNDARY_TOLERANCE_RATIO = 0.95;

const YEAR_BOUNDARY_TOLERANCE = 365 * YEAR_BOUNDARY_TOLERANCE_RATIO;
const YEAR_BOUNDARY_FORWARD_RATIO = 1.25;

const ChartViewSettings = Model.extend({
    defaults: {
        daysForward: 7,
        daysBack: -7,
        groupBy: 'day',
        min: -365,
        max: 365,
        plan: null,
    },

    initialize() {
        this.listenTo(
            this,
            {
                'change:daysBack change:daysForward': util.throttle(this.growRange, 200),
            },
            this,
        );
    },

    growRange() {
        /*
         * TODO this should stop when there is no further data to access
         * TODO can this happen while the user is dragging?
         */

        // Handle the left 'minimum' side.
        const gMin = this.get('min');
        if ((this.get('daysBack') > (-1 * YEAR_BOUNDARY_TOLERANCE))) {
            // 95% of -365
            this.set({
                min: -365,
            });
        } else if ((this.get('daysBack') / gMin) > YEAR_BOUNDARY_TOLERANCE_RATIO) {
            this.set({
                min: Math.round(gMin * YEAR_BOUNDARY_FORWARD_RATIO),
            });
        }

        // Handle the right 'maximum' side.
        const gMax = this.get('max');
        if ((this.get('daysForward') < YEAR_BOUNDARY_TOLERANCE)) {
            // 95% of 365
            this.set({
                max: 365,
            });
        } else if ((this.get('daysForward') / gMax) > YEAR_BOUNDARY_TOLERANCE_RATIO) {
            this.set({
                max: Math.round(gMax * YEAR_BOUNDARY_FORWARD_RATIO),
            });
        }
    },

    setRange(startOffset, endOffset) {
        /*
         * this will make sure the range contains full weeks, months, etc when a user
         * sets an arbitrary range
         * round up to the nearest nice dates
         * TODO what if the next full time period we include has no/partial data?
         */

        const today = moment(new Date()).startOf('day');

        let start = today.clone().add(startOffset, 'days');
        let end = today.clone().add(endOffset, 'days');
        let groupBy = 'day';
        const range = moment.duration(end.valueOf() - start.valueOf()).as('days');

        /*
         * groupBy groupings should follow shorthand keys
         * from http://momentjs.com/docs/#/manipulating/start-of/
         */
        if (range > 1460) {
            groupBy = 'year';
        } else if (range > 730) {
            groupBy = 'quarter';
        } else if (range > 364) {
            groupBy = 'month';
        } else if (range > 100) {
            groupBy = 'week';
        }
        // less than 100 days will display as days

        if (groupBy !== 'day') {
            // make sure the period will include full weeks/months/etc
            end = end.endOf(groupBy);
            start = start.startOf(groupBy);
        }

        let newEndOffset = Math.round(moment.duration(end.valueOf() - today.clone().endOf('day').valueOf()).as('days'));
        let newStartOffset = -1 * Math.round(moment.duration(today.valueOf() - start.valueOf()).as('days'));

        /*
         * clip to min and max
         * this will cut off groupBy groups but prevents the slider from leaving its track
         */
        newEndOffset = Math.min(this.get('max'), newEndOffset);
        newStartOffset = Math.max(this.get('min'), newStartOffset);

        this.set({
            daysForward: newEndOffset,
            daysBack: newStartOffset,
            groupBy,
        });
    },
});

export default ChartViewSettings;
