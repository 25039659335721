import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const ContentEditorContext = React.createContext({ editor: null, setEditor: () => null });

export const ContentEditorProvider = ({ children, previewOnly }) => {
    const [editor, setEditor] = useState();
    const [isPreviewOnly] = useState(previewOnly);
    const [isPreview, setIsPreview] = useState(previewOnly);
    const [isDisabled, setIsDisabled] = useState(previewOnly);
    const [isShown, setIsShown] = useState(true);
    const value = useMemo(() => ({
        editor,
        isDisabled,
        isPreview,
        isPreviewOnly,
        isShown,
        setEditor,
        setIsDisabled,
        setIsPreview,
        setIsShown,
    }), [editor, isDisabled, isPreview, isPreviewOnly, isShown]);

    return <ContentEditorContext.Provider value={value}>{children}</ContentEditorContext.Provider>;
};

ContentEditorProvider.propTypes = {
    children: PropTypes.node.isRequired,
    previewOnly: PropTypes.bool,
};

ContentEditorProvider.defaultProps = {
    previewOnly: false,
};

export default ContentEditorContext;
