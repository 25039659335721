var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"section-header text-center\">\n        <ul class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\">\n            <li class=\"NavTabs-item is-active\">\n                <a class=\"NavTabs-link btn btn-tertiary\"\n                    data-step=\"transaction\"\n                    href=\"#\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ach.transactionDetails","Transaction Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":86}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\">\n                <a class=\"NavTabs-link btn btn-tertiary\"\n                    data-step=\"preview\"\n                    href=\"#\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ach.documentPreview","eSend Document Preview",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":29},"end":{"line":15,"column":86}}}))
    + "\n                </a>\n            </li>\n        </ul>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"Beneficiary-note\">\n        <span class=\"help-block\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.lockedfields.note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":67}}}))
    + "</span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-region\" data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"correctTypeForESendPreview") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lockedFieldsEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + "\n<div data-region=\"batchChildRegion\"></div>\n";
},"useData":true});