import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import CollectionView from '@glu/core/src/collectionView';
import groupView from './groupView';
import limitLayoutTmpl from './limitLayout.hbs';

const GroupListView = groupView;

export default Layout.extend({
    template: limitLayoutTmpl,
    itemViewContainer: '.list-container',
    className: 'approval-limits-region',

    ui: {
        infoIcon: '.btn .icon-info',
    },

    onRender() {
        const options = {
            mode: this.options.mode,
            modifyMode: this.options.modifyMode || false,
        };

        /*
         * To be added back when Overall Group Limits are implemented
         * this.groupLimitsRegion.show(new CollectionView({
         *     collection: this.collection,
         *     itemView: GroupLimitsView,
         *     itemViewOptions: options
         * }));
         */

        this.groupCollectionRegion.show(new CollectionView({
            collection: this.collection,
            itemView: GroupListView,
            itemViewOptions: options,
        }));

        if (this.ui.infoIcon) {
            this.ui.infoIcon.popover({
                content: locale.get('administration.overall.limits.explanation'),
                trigger: 'hover focus',
            });
        }
    },

    templateHelpers() {
        return {
            groups: this.collection.toJSON(),
            modifyMode: this.options.modifyMode,
        };
    },
});
