import Layout from '@glu/core/src/layout';
import template from './accountGroupSummaryPreGrid.hbs';

const AccountGroupSummaryPreGrid = Layout.extend({
    template,
    templateHelpers() {
        return {
            title: this.options.title,
        };
    },
});

export default AccountGroupSummaryPreGrid;
