var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"modal-header\">\n                <button type=\"button\" class=\"close\" data-action=\"close\" aria-hidden=\"true\"><span class=\"icon-close\"></span></button>\n                <h2 class=\"modal-title\" id=\"modal-title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":57},"end":{"line":7,"column":113}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":113},"end":{"line":7,"column":124}}}) : helper)))
    + "</h2>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"icon-"
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":7,"column":87},"end":{"line":7,"column":97}}}))
    + "\"></span>";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                    <div class=\"row view-spacing\">\n                        <div class=\"col-lg-6\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.billedAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":85},"end":{"line":16,"column":121}}}))
    + "</span></div>\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"cellBilledAmount") || (depth0 != null ? lookupProperty(depth0,"cellBilledAmount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cellBilledAmount","hash":{},"data":data,"loc":{"start":{"line":17,"column":88},"end":{"line":17,"column":108}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                        <div class=\"col-lg-6\">\n\n                        </div>\n                    </div>\n                    <div class=\"row view-spacing\">\n                        <div class=\"col-lg-6\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.discountAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":85},"end":{"line":25,"column":123}}}))
    + "</span></div>\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"cellDiscountAmount") || (depth0 != null ? lookupProperty(depth0,"cellDiscountAmount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cellDiscountAmount","hash":{},"data":data,"loc":{"start":{"line":26,"column":88},"end":{"line":26,"column":110}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                        <div class=\"col-lg-6\">\n\n                        </div>\n                    </div>\n                    <div class=\"row view-spacing\">\n                        <div class=\"col-lg-6\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.adjustmentAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":85},"end":{"line":34,"column":125}}}))
    + "</span></div>\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"cellAdjustmentAmount") || (depth0 != null ? lookupProperty(depth0,"cellAdjustmentAmount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cellAdjustmentAmount","hash":{},"data":data,"loc":{"start":{"line":35,"column":88},"end":{"line":35,"column":112}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                        <div class=\"col-lg-6\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.adjustmentReason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":85},"end":{"line":38,"column":125}}}))
    + "</span></div>\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"cellAdjustmentReasonDesc") || (depth0 != null ? lookupProperty(depth0,"cellAdjustmentReasonDesc") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cellAdjustmentReasonDesc","hash":{},"data":data,"loc":{"start":{"line":39,"column":88},"end":{"line":39,"column":116}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                    </div>\n                    <div class=\"row view-spacing\">\n                        <div class=\"col-lg-6\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.referenceNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":85},"end":{"line":44,"column":124}}}))
    + "</span></div>\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"cellReferenceNumber") || (depth0 != null ? lookupProperty(depth0,"cellReferenceNumber") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cellReferenceNumber","hash":{},"data":data,"loc":{"start":{"line":45,"column":88},"end":{"line":45,"column":111}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                        <div class=\"col-lg-6\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.referenceNumberType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":85},"end":{"line":48,"column":128}}}))
    + "</span></div>\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"cellReferenceNumberTypeDesc") || (depth0 != null ? lookupProperty(depth0,"cellReferenceNumberTypeDesc") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cellReferenceNumberTypeDesc","hash":{},"data":data,"loc":{"start":{"line":49,"column":88},"end":{"line":49,"column":119}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                    </div>\n                    <div class=\"row view-spacing\">\n                        <div class=\"col-lg-12\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.paymentDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":85},"end":{"line":54,"column":127}}}))
    + "</span></div>\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + alias2(((helper = (helper = lookupProperty(helpers,"cellPaymentDescription") || (depth0 != null ? lookupProperty(depth0,"cellPaymentDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cellPaymentDescription","hash":{},"data":data,"loc":{"start":{"line":55,"column":88},"end":{"line":55,"column":114}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row view-spacing\">\n                        <div class=\"col-lg-12\">\n                            <div class=\"payment-grid-header-label\"><span class=\"txt\"><b>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cellComment") || (depth0 != null ? lookupProperty(depth0,"cellComment") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cellComment","hash":{},"data":data,"loc":{"start":{"line":61,"column":88},"end":{"line":61,"column":103}}}) : helper)))
    + "</b></span></div>\n                        </div>\n                    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":16},"end":{"line":72,"column":25}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"className") || (depth0 != null ? lookupProperty(depth0,"className") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"className","hash":{},"data":data,"loc":{"start":{"line":70,"column":53},"end":{"line":70,"column":68}}}) : helper)))
    + " popbox-btn"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":70,"column":79},"end":{"line":70,"column":89}}}) : helper)))
    + "\"\n                            data-key=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":71,"column":38},"end":{"line":71,"column":48}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":71,"column":50},"end":{"line":71,"column":60}}}) : helper)))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-backdrop in\"></div>\n<div class=\"modal-dialog glu-dialog dialog-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":2,"column":43},"end":{"line":2,"column":53}}}) : helper)))
    + " sb-payments-modal\">\n    <div class=\"modal-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"modal-body\" id=\"modal-body\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"usePaymentRemittance") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":64,"column":23}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"modal-footer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":73,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});