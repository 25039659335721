import Layout from '@glu/core/src/layout';
import { asView } from 'common/util/reactUtil';
import PanelApprovalFirstStep from 'components/PanelApprovalFirstStep/PanelApprovalFirstStep';
import PaymentMethodsView from './paymentMethods';
import TemplatesView from './templates';
import template from './methodAccountTemplateStep.hbs';

export default Layout.extend({
    template,

    initialize(options) {
        this.paymentMethodsView = new PaymentMethodsView(options);
        this.templatesView = new TemplatesView(options);
    },

    templateHelpers() {
        return {
            readOnly: this.options.modify,
        };
    },

    onRender() {
        const TabsView = asView(PanelApprovalFirstStep);
        this.tabRegion.show(new TabsView({
            paymentMethodsView: this.paymentMethodsView,
            templatesView: this.templatesView,
        }));
    },
});
