import Layout from '@glu/core/src/layout';
import Alert from 'app/setup/models/alert';
import InquiryApi from 'system/webseries/api/inquiry';
import InquiryQueryResults from 'system/webseries/collections/inquiryQueryResults';
import Collection from '@glu/core/src/collection';
import workspaceHelper from 'common/workspaces/api/helper';
import CollectionComboBoxView from 'common/collectionComboBox/views/base';
import AdminAlertView from './adminAlert';
import CheckManagementAlertView from './checkManagementAlert';
import CheckProductionAlertView from './checkProductionAlert';
import PaymentsAlertView from './paymentsAlert';
import InformationReportingAlertView from './informationReportingAlert';
import createTmpl from './create.hbs';

export default Layout.extend({
    initialize(opts) {
        this.title = opts.title || '';
        this.alertGroupsCollection = opts.alertGroupsCollection || new Collection();
        this.alertTypesCollection = opts.alertTypesCollection || new Collection();
    },

    model: new Alert(),
    template: createTmpl,
    binding: true,
    className: 'page page-grid',

    ui: {
        alertGroup: '[data-hook="alert-group"]',
        alertType: '[data-hook="alert-type"]',
        alertTimeRegion: '.alertTimeRegion',
        scheduleMinutes: '[data-hook="scheduleMinutes"]',
        scheduleHours: '[data-hook="scheduleHours"]',
        timeZone: '[data-hook="timeZone"]',
        recipient: '[data-hook="recipient"]',
    },

    onRender() {
        // Setup dropdowns.
        this.renderAlertGroupContent();

        this.renderRecipientContent();

        this.renderAlertTypeContent();

        this.renderAlertTimeContent();

        this.renderTimeZoneContent();

        // Setup default alert content.
        this.renderAlertContent();
    },

    /**
     * Render the alert group region.
     */
    renderAlertGroupContent() {
        const self = this;

        const alertGroupComboBoxView = new CollectionComboBoxView({
            collection: self.alertGroupsCollection,
        });

        alertGroupComboBoxView.on('select', (model) => {
            // model will be undefined if the user clears their selection.
            const id = model ? model.get('id') : undefined;

            self.model.set(self.model.ALERT_GROUP, id);

            self.toggleAlertTime(id);
            self.renderAlertTypeContent(id);
            self.renderAlertContent(id);
        });

        this.alertGroupRegion.show(alertGroupComboBoxView);
    },

    /**
     * Render the alert type region.
     */
    renderAlertTypeContent(alertGroup) {
        const self = this;
        if (!alertGroup) {
            this.alertTypesCollection = new Collection();
        } else {
            this.alertTypesCollection = new InquiryQueryResults({
                inquiryId: InquiryApi.ALERT_TYPES_LIST_INQUIRY_ID,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'alertGroup',
                        alertGroup,
                    ],
                }],
            });

            this.alertTypesCollection.fetch();
        }

        const alertTypeComboBoxView = new CollectionComboBoxView({
            collection: this.alertTypesCollection,
        });

        alertTypeComboBoxView.on('select', (model) => {
            // model will be undefined if the user clears their selection.
            const id = model ? model.get('id') : undefined;
            self.toggleAlertTime(id);
        });
        this.alertTypeRegion.show(alertTypeComboBoxView);
    },

    /**
     * Render the recipient region.
     */
    renderRecipientContent() {
        this.ui.recipient.comboBox();
    },

    /**
     * Render the alert time region.
     */
    renderAlertTimeContent() {
        this.ui.scheduleMinutes.comboBox();
        this.ui.scheduleHours.comboBox();
    },

    /**
     * Render the time zone region.
     */
    renderTimeZoneContent() {
        this.ui.timeZone.comboBox();
    },

    /**
     * Change the alertContent region based on the selected alert group.
     */
    renderAlertContent(alertGroup) {
        switch (alertGroup) {
        // global variables somewhere?
        case '_ADMIN':
            this.alertContent.show(new AdminAlertView());
            break;
        case 'CM':
            this.alertContent.show(new CheckManagementAlertView());
            break;
        case 'PAY':
            this.alertContent.show(new PaymentsAlertView());
            break;
        case 'GIR':
            this.alertContent.show(new InformationReportingAlertView());
            break;
        case 'CLM':
            this.alertContent.show(new CheckProductionAlertView());
            break;
        default:
            this.alertContent.show(new AdminAlertView());
        }
    },

    /**
     * Handle changes to the alert group combobox.
     */
    alertGroupChange(e) {
        this.model.set(this.model.ALERT_GROUP, e.target.value);
        this.renderAlertContent();
    },

    /**
     * Handle changes to the alert type combobox.
     */
    alertTypeChange(e) {
        this.model.set(this.model.ALERT_TYPE, e.target.value);
        this.renderAlertContent();
    },

    /**
     * Create the alert and return to the list view.
     */
    save() {
        this.backToListView();
    },

    /**
     * Reset model to its default state.
     */
    reset() {
        this.model.clear().set(this.defaults);
    },

    /**
     * Cancel alert creation and return to list the view.
     */
    cancel() {
        this.backToListView();
    },

    /**
     * Return to the list view.
     *
     * It may be useful to create global variables for these paths.
     */
    backToListView() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    templateHelpers() {
        return {
            title: this.title,
        };
    },

    /**
     * Toggle visibility of alert time Region.
     */
    toggleAlertTime(id) {
        const alertTimeRegion = this.$el.find('[data-region="alertTimeRegion"]');

        if (id === 'PAY' || id === 'CLMALRT') {
            alertTimeRegion.removeClass('hidden');
        } else {
            alertTimeRegion.addClass('hidden');
        }
    },
});
