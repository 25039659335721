import CompositeView from '@glu/core/src/compositeView';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import NotesModel from 'app/reports/models/transactionNote';
import NotesCollection from 'app/reports/collections/transactionNotes';
import EmptyNoteView from 'app/reports/views/lockbox/modals/notes/emptyView';
import Note from 'app/reports/views/lockbox/modals/notes/note';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import manageNotesTmpl from 'app/reports/views/lockbox/modals/notes/manageNotes.hbs';

export default CompositeView.extend({
    template: manageNotesTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-lg lockbox-notes',
    emptyView: EmptyNoteView,
    itemView: Note,

    itemEvents: {
        destroy(event, note) {
            const notesRemoved = util.filter(this.models, model => model.action === 'destroy');

            const string = locale.get('LBX.TransactionNoteRemoved').replace('#', notesRemoved.length);
            const message = alert.warning(string);

            this.collection.remove(note.model);
            this.$('.lbxRemoveAlert').html(message.render().el);

            if (this.collection.isEmpty()) {
                this.ui.$addNote.hide();
            }
        },

        save(event, note) {
            const notesAdded = util.filter(this.models, model => model.action === 'save');

            const string = locale.get('LBX.TransactionNoteAdded').replace('#', notesAdded.length);
            const message = alert.warning(string);

            this.collection.add(note.model);
            this.addAlertRegion.show(message);
            this.toggleForm();
        },
    },

    regions: {
        addAlertRegion: '.lbxAddAlert',
        removeAlertRegion: '.lbxRemoveAlert',
    },

    ui: {
        $addNote: '.lbxAdd',
        $textarea: '.lbxNoteForm',
        $noteText: '[name="NOTE_TEXT"]',
    },

    initialize(options) {
        this.itemViewOptions = {
            parent: this,
        };
        this.eid = options.ENVELOPE_ID;
        this.model = new NotesModel(options);
        this.models = [];
        this.collection = new NotesCollection({
            eid: this.eid,
        });

        this.listenTo(this.collection, 'sync', this.render);

        this.dialogTitle = locale.get('LBX.TransactionNotes');
        this.dialogButtons = [{
            text: locale.get('LBX.SaveChanges'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.toggleForm();
        } else {
            this.loadRequiredData();
        }
    },

    toggleForm(e) {
        if (e) {
            this.model = new NotesModel();
            this.ui.$noteText.val('');
            this.ui.$textarea.toggle();
        } else if (this.collection.isEmpty()) {
            this.ui.$addNote.hide();
        } else {
            this.ui.$addNote.show();
            this.ui.$textarea.hide();
        }
    },

    addNote() {
        this.model.action = 'save';
        this.model.set('ENVELOPE_ID', this.eid);
        this.collection.add(this.model);
        this.models.push(this.model);

        const notesAdded = util.filter(this.models, model => model.action === 'save');

        const string = locale.get('LBX.TransactionNoteAdded').replace('#', notesAdded.length);

        const message = alert.warning(string);
        this.$('.lbxAddAlert').html(message.render().el);
        this.toggleForm();
    },

    submit() {
        this.successResp = [];
        this.errorResp = [];

        const childModel = this.children.findByIndex(0).model;
        /*
         * when collection is empty && all others notes deleted, and textarea is
         * empty, allow user to save changes
         */
        if ((this.collection.isEmpty() && this.models.length > 0) && childModel.get('NOTE_TEXT') === '') {
            this.saveChanges();
        } else if (this.collection.isEmpty()) {
            childModel.action = 'save';
            childModel.set({
                ENVELOPE_ID: this.eid,
            });
            this.models.push(childModel);
            this.saveChanges();
        } else {
            this.saveChanges();
        }
    },

    saveChanges() {
        const self = this;
        const notePromises = this.getNotePromises();

        Promise.all(notePromises).then((results) => {
            const [note1] = results;
            if (self.successResp.length > 1) {
                const confirmArr = [];
                for (let x = 0; x < self.successResp.length; x += 1) {
                    self.successResp[x].confirms.confirmResults[0]
                        .confirmData[0].item = self.renameConfirmLabel(self.successResp[x]);
                    confirmArr.push(self.successResp[x].confirms.confirmResults[0]);
                }
                note1.confirms.confirmResults = confirmArr;
            } else if (self.successResp.length === 1) {
                note1.confirms.confirmResults[0]
                    .confirmData[0].item = self.renameConfirmLabel(results);
            }
            if (self.errorResp.length > 0) {
                self.trigger('grid:refresh', self.getErrorMessage(note1));
            } else {
                const message = locale.get('LBX.TransactionNotesSaved');
                self.trigger('grid:refresh', self.getSuccessMessage(note1, message));
            }
        });
    },

    renameConfirmLabel(resp) {
        let localResp = resp;
        localResp = util.isArray(localResp) ? localResp[0] : localResp;
        let items = localResp.confirms.confirmResults[0].confirmData[0].item;
        const itemDeleted = localResp.message[0].toUpperCase().indexOf('DELETE') > -1;
        items = util.each(items, (itemParam) => {
            const item = itemParam;
            const name = item.name.toUpperCase();

            if (name.indexOf('CREATE') > -1 && itemDeleted) {
                item.name = locale.get('RTGS.*.deleted_by');
            }
            return item;
        });

        return items;
    },

    getSuccessMessage(confirmResponse, message) {
        const confirms = new Confirms({
            confirms: confirmResponse ? confirmResponse.confirms : null,
        });

        this.alertView = alert.success(
            message,
            {
                details: confirmResponse
                && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                    ? confirms : null,
                canDismiss: true,
            },
        );

        return this.alertView;
    },

    getErrorMessage(model) {
        const confirms = new Confirms({
            confirms: model.error.confirms,
        });

        this.alertView = alert.danger(
            model.error.message[0],
            {
                details: confirms,
                canDismiss: true,
            },
        );

        return this.alertView;
    },

    getNotePromises() {
        const self = this;
        const promises = [];

        util.each(this.models, (model) => {
            if (model.action === 'destroy' && !model.get('NOTE_ID')) {
                return;
            }

            const promise = new Promise((resolve, reject) => {
                const callbacks = {
                    success(successModel, response) {
                        self.successResp.push(response);
                        resolve(response);
                    },

                    error(response) {
                        self.errorResp.push(response);
                        reject(response);
                    },
                };

                if (model.action === 'save') {
                    model.save({}, callbacks);
                } else {
                    model.set('id', model.get('NOTE_ID'));
                    model.destroy(callbacks);
                }
            });

            promises.push(promise);
        });

        return promises;
    },

    cancel() {
        this.trigger('grid:refresh');
    },

    loadRequiredData() {
        const self = this;
        this.collection.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
            },
        });
    },
});
