/**
 *
 * GLU ICON HELPER OVERRIDE
 * ========================
 *
 * All overrides for the Glu icon template helper
 *
 * List of changes:
 *      - Customized the GluIcons constructor to recieve iconsData as an option to allow overrides
 *
 *      - Added a new export 'getIconHelper' that return the helper function to be registered.
 *        It receives a 'getIcon' function, 'getIconByAlias' function and 'template' template reference as input.
 *        This provides deeper level of customization to the consumer.
 *
 *      - Added a new export 'getIcon' function which is de-coupled from GluIcons instance construction. It also
 *        has changes in button rendering logic where title attribute is not added to the button and icon span
 *        if it has a span inside it with the same content. (See NH-170509).
 *
 *      - Added a new export 'getIconByAlias' function which is de-coupled from GluIcons instance construction.
 *
 */

import { util, locale } from '@glu/core';
import Handlebars from 'handlebars';

/**
 * @constructor
 * GluIcons - main function/class to create Glu SVG-based Icons.
 *
 * @param iconOptions - object with possible constructor options
 *
 * @param iconOptions.iconsData - iconsData object with pairs in `key: <svg />` format
 *
 * @param iconOptions.mainOptions - object with possible optional fields:
 *
 * @param iconOptions.mainOptions.iconsType
 * value 'font' - use font-based icons. It's former, old approach. v < 1.8.40
 * value 'svg'; - use SVG icons. It's new approach,
 * related to IE fonts files issues. v > 1.9 (2.0.0)
 * Default behaviour/value is 'svg'
 * (if nothing provided from JavaScript file of requireJS config object)
 *
 * @param iconOptions.mainOptions.customIconsData - object, to extend gluIcons.iconsData.
 * Alternative to `customIconsDataCallback`. By default , following Singleton pattern,
 * if no iconData, it will use initial constant value.
 * customIconsData object key/value pair example:
 * "chat": "<svg viewBox=\"0 0 16 16\"><path d=\"M15 11H8.667L5 14v-3H1V1h14m1-1H0v12h4v4l5-4h7V0z\"
 * data-name=\"Layer 2\"/></svg>"
 * If LOB code provide customIconsData object, glu-icons will use it for extension.
 * Yes, by extending you can also override icon.
 *
 * @param iconOptions.mainOptions.customIconsDataCallback - function.
 * Alternative to `customIconsData`. Expected as function callback to be executed
 * if iconName is not found in Glu Icons set.
 * Providing customIconDataCallback also mean, that code will return custom icon code
 * instead of Glu icons code, but it will not override existed Glu iconsData.
 *
 * @param iconOptions.mainOptions.customIconsAttributes - Custom Icon Attributes (noTitle)
 *
 * @param iconOptions.mainOptions.customLegacyAliases - object (not fully verified approach yet.)
 * Useful for LOB to extend/override aliases.
 */
export default function GluIcons({ iconsData, mainOptions }) {
    this.mainOptions = mainOptions || {};
    this.customIconsAttributes = {};
    this.iconsData = iconsData;

    if (this.mainOptions.customIconsData) {
        if (this.mainOptions.customIconsData instanceof Object) {
            util.extend(this.iconsData, this.mainOptions.customIconsData);
        } else {
            throw new Error('Please provide valid Object, where key is real lowerCase iconName and value is and svgCode (SVG tag only).');
        }
    }

    if (this.mainOptions.customIconsAttributes) {
        util.extend(this.customIconsAttributes, this.mainOptions.customIconsAttributes);
    }
}

/**
 * getIconHelper - This function returns the helper function that is registered as template helper.
 * @param helperOptions - object containing getIcon functions and icon template
 * @param helperOptions.getIconFn - Function to get icon code by name
 * @param helperOptions.getIconByAliasFn - Function to get icon code by alias
 * @param helperOptions.template - Template to be used for render
 * @returns Helper function that returns constructed icon element (button or span)
 */
function getIconHelper({
    getIconFn,
    getIconByAliasFn,
    template,
}) {
    /**
     * Returns WCAG-compliant icon markup.
     * This function now is combined, and contains font-based and svg-based icons approaches.
     * Depends on mainOptions.iconsType value we define which iconTemplate to use.
     * And suggested to create instance of GluIcons by providing
     * mainOptions.iconType value = 'font' or 'svg'.If ommited, then 'svg' will be used.
     */
    return (name, text, contxt) => {
        let context = contxt || {};
        if (!context.hash) {
            context.hash = {};
        }
        if (util.isObject(name)) {
            context = util.extend(context, name);
        } else if (util.isObject(text)) {
            context = util.extend(context, text);
            context.hash.name = name;
        } else {
            context.hash.name = name;
            context.hash.text = text;
        }

        if (context.hash.locale) {
            // TODO locale.pairs doesn't have value set from tests.
            context.hash.text = locale.get(context.hash.locale);
        }

        /**
         * by default, if no text provided, we better show at least something
         * (for sr-only and for aria-label). we also need to avoid name value,
         * in case if it's about getting localized value.
         */
        const defaultTextValue =
            context.hash.name && util.isEmpty(context.hash.locale)
                ? context.hash.name
                : '';

        const options = util.extend(
            {
                text: defaultTextValue,
            },
            context.hash,
        );

        // there was discussion to NOT use div, so instead use span
        options.primaryTagName = options.action ? 'button' : 'span';

        const descText = options.title || options.text;
        options.srOnly = context.hash.srOnly === 'false' ? '': Handlebars.Utils.escapeExpression(options.srOnly || descText);
        // LOB specific icon options. Optional.
        options.spanElementOptions = {
            title: options.title,
            customDataAttributes: options.customDataAttributes,
            className: options.className || false,
            style: options.style || false,
        };

        // default value "svg"
        // In future, if all LOB will migrate and use ONLY SVG-based approach,
        // below code can be removed.
        // And then using "svg-based" css class can be also removed.
        options.iconsType = options.iconsType || 'svg';
        if (options.iconsType === 'svg') {
            // this condition needed to avoid auto-casting from
            // undefined to "undefined", in case if no classname at all.
            if (options.className) {
                options.className += ' svg-based';
            } else {
                options.className = 'svg-based';
            }
            options.iconHTML = getIconFn({
                getIconByAliasFn,
                iconName: options.name,
                spanElementOptions: options.spanElementOptions,
            });
        }
        // and if "font", then above condition will be skipped

        return new Handlebars.SafeString(template(options));
    };
}

/**
 * Helper function, to get icon code.
 * No need to inject it manually.
 * Must be bound to GluIcons function instance before calling.
 *
 * @param options - Options object for getIcon function
 * @param options.getIconByAliasFn - Function to get icon code by alias
 * @param options.iconName - string value of icon name
 * @param options.spanElementOptions - optional. object of options to apply for icon span.
 * Mostly needed by LOB specific.
 * @return found icon template or empty string.
 */
function getIcon({ getIconByAliasFn, iconName, spanElementOptions = {} }) {
    // 95% the same function as in gluIconsWindow.js
    if (!iconName) {
        throw new Error('Provide an icon name in helper {{icon}}');
    }

    // 1) By the load flow of dependencies, LOB can provide mainOptions.customIconsData
    // and GluIcons will extend its iconsData object.
    // LOB codebase will have helpful JS/JSON files created by glu-icons npm module.

    // 2) by name of icon, getIcons tries to access to glu-icons data set and return svgCode.
    let svgCode = '';
    const isSVG = this.mainOptions.iconsType === 'svg' || this.mainOptions.iconsType === undefined;

    if (isSVG) {
        svgCode = this.iconsData ? this.iconsData[iconName] : '';

        // 3) If no glu-icons built-in icon with a name,
        // need to consider aliases, which might not exists btw.
        if (!svgCode) {
            svgCode = getIconByAliasFn(iconName);
        }

        // 4) If no iconCode found, but maybe there is LOB custom
        // callback function, after execution of which new iconsData can be extended.
        if (!svgCode && this.mainOptions.customIconsDataCallback && typeof this.mainOptions.customIconsDataCallback === 'function') {
            svgCode = this.mainOptions.customIconsDataCallback(iconName);
        }
    }

    // 5) After all possible ways to get baseline iconsData,
    // we compose icon wrapper - SPAN element.
    // Below lines are borrowed from LSM/PartnerSelect/LegalX,
    // and can be considered as helpful for other LOBs also.
    const title = spanElementOptions.title ? ` title="${spanElementOptions.title}"` : '';
    let dataAttributes = '';
    if (
        spanElementOptions.customDataAttributes &&
            spanElementOptions.customDataAttributes instanceof Array
    ) {
        dataAttributes = spanElementOptions.customDataAttributes.reduce((prev, { key, value }) => (
            key && value ? ` ${prev.trim()} data-${key}="${value}"` : prev
        ), '');
    }
    const style = spanElementOptions.style ? ` style="${spanElementOptions.style}"` : '';

    // 6) Considering, that GluIcons will be used in Glu code,
    // it's expected to have Handlebars helper, which will wrap below code by one more SPAN.
    return `<span class="icon-${iconName}" ${title}${dataAttributes}${style} >${svgCode}</span>`;
}

/**
 * Function to get icon code by alias.
 * Must be bound to GluIcons function instance before calling
 * @param iconName - string value of icon name
 * @returns found icon template or empty string
 */
function getIconByAlias(iconName) {
    // iconName will be used inside of SWITCH
    let iconDataKey;

    switch (iconName) {
    case 'add-bank-next':
        iconDataKey = 'add-bank-next';
        break;
    case 'address-book':
        iconDataKey = 'address-book';
        break;
    case 'time':
    case 'alarm-clock':
        iconDataKey = 'alarm-clock';
        break;
    case 'circle-arrow':
    case 'arrow-circle-left':
        iconDataKey = 'arrow-circle-left';
        break;
    case 'arrow-circles-next':
        iconDataKey = 'arrow-circles-next';
        break;
    case 'undo':
    case 'arrow-curved-left':
        iconDataKey = 'arrow-curved-left';
        break;
    case 'redo':
    case 'arrow-curved-right':
        iconDataKey = 'arrow-curved-right';
        break;
    case 'motion-arrow':
    case 'arrow-dashes':
        iconDataKey = 'arrow-dashes';
        break;
    case 'arrow-down-next':
        iconDataKey = 'arrow-down-next';
        break;
    case 'arrow-thin-down':
    case 'arrow-down':
        iconDataKey = 'arrow-down';
        break;
    case 'arrow-left-next':
        iconDataKey = 'arrow-left-next';
        break;
    case 'arrow-thin-left':
    case 'arrow-left':
        iconDataKey = 'arrow-left';
        break;
    case 'arrow-right-next':
        iconDataKey = 'arrow-right-next';
        break;
    case 'arrow-thin-right':
    case 'arrow-right':
        iconDataKey = 'arrow-right';
        break;
    case 'arrow-up-next':
        iconDataKey = 'arrow-up-next';
        break;
    case 'arrow-thin-up':
    case 'arrow-up':
        iconDataKey = 'arrow-up';
        break;
    case 'refresh':
    case 'arrows-circles':
        iconDataKey = 'arrows-circles';
        break;
    case 'arrows-corners-in-next':
        iconDataKey = 'arrows-corners-in-next';
        break;
    case 'shrink':
    case 'arrows-corners-in':
        iconDataKey = 'arrows-corners-in';
        break;
    case 'arrows-corners-out-next':
        iconDataKey = 'arrows-corners-out-next';
        break;
    case 'enlarge':
    case 'arrows-corners-out':
        iconDataKey = 'arrows-corners-out';
        break;
    case 'arrows-double-next':
        iconDataKey = 'arrows-double-next';
        break;
    case 'check-badge':
    case 'badge-checkmark':
        iconDataKey = 'badge-checkmark';
        break;
    case 'workspace':
    case 'blocks':
        iconDataKey = 'blocks';
        break;
    case 'book-with-mark':
    case 'book':
        iconDataKey = 'book';
        break;
    case 'bookmark':
        iconDataKey = 'bookmark';
        break;
    case 'box-arrow-out-next':
        iconDataKey = 'box-arrow-out-next';
        break;
    case 'new-tab':
    case 'box-arrow-out':
        iconDataKey = 'box-arrow-out';
        break;
    case 'logout':
    case 'box-arrow-right':
        iconDataKey = 'box-arrow-right';
        break;
    case 'boxes-columns-next':
        iconDataKey = 'boxes-columns-next';
        break;
    case 'drag':
    case 'boxes-columns':
        iconDataKey = 'boxes-columns';
        break;
    case 'boxes-rows-next':
        iconDataKey = 'boxes-rows-next';
        break;
    case 'briefcase-next':
        iconDataKey = 'briefcase-next';
        break;
    case 'briefcase-paperclip':
        iconDataKey = 'briefcase-paperclip';
        break;
    case 'briefcase-add':
    case 'briefcase-plus':
        iconDataKey = 'briefcase-plus';
        break;
    case 'briefcase':
        iconDataKey = 'briefcase';
        break;
    case 'courthouse-checkmark':
    case 'building-checkmark':
        iconDataKey = 'building-checkmark';
        break;
    case 'building-next':
        iconDataKey = 'building-next';
        break;
    case 'courthouse-plus':
    case 'batch-case':
    case 'batch_case':
    case 'building-plus':
        iconDataKey = 'building-plus';
        break;
    case 'courthouse':
    case 'cases':
    case 'legal-building':
    case 'building':
        iconDataKey = 'building';
        break;
    case 'buildings-next':
        iconDataKey = 'buildings-next';
        break;
    case 'claim-offices':
    case 'buildings':
        iconDataKey = 'buildings';
        break;
    case 'calendar-next':
        iconDataKey = 'calendar-next';
        break;
    case 'calendar':
        iconDataKey = 'calendar';
        break;
    case 'caret-down-next':
        iconDataKey = 'caret-down-next';
        break;
    case 'down-caret':
    case 'caret-down':
        iconDataKey = 'caret-down';
        break;
    case 'caret-left-next':
        iconDataKey = 'caret-left-next';
        break;
    case 'left-caret':
    case 'caret-left':
        iconDataKey = 'caret-left';
        break;
    case 'first':
    case 'caret-line-left':
        iconDataKey = 'caret-line-left';
        break;
    case 'last':
    case 'caret-line-right':
        iconDataKey = 'caret-line-right';
        break;
    case 'caret-right-next':
        iconDataKey = 'caret-right-next';
        break;
    case 'right-caret':
    case 'caret-right':
        iconDataKey = 'caret-right';
        break;
    case 'caret-up-next':
        iconDataKey = 'caret-up-next';
        break;
    case 'up-caret':
    case 'caret-up':
        iconDataKey = 'caret-up';
        break;
    case 'collect':
    case 'discount-categories':
    case 'money':
    case 'cash-in-hand':
        iconDataKey = 'cash-in-hand';
        break;
    case 'chart-bar-next':
        iconDataKey = 'chart-bar-next';
        break;
    case 'bar-graph':
    case 'bar-graph-border':
    case 'analytics':
    case 'chart-bar':
        iconDataKey = 'chart-bar';
        break;
    case 'chart-circles-next':
        iconDataKey = 'chart-circles-next';
        break;
    case 'scatter-graph':
    case 'chart-circles':
        iconDataKey = 'chart-circles';
        break;
    case 'chart-donut-next':
        iconDataKey = 'chart-donut-next';
        break;
    case 'donut-graph':
    case 'chart-donut':
        iconDataKey = 'chart-donut';
        break;
    case 'chart-line-next':
        iconDataKey = 'chart-line-next';
        break;
    case 'line':
    case 'line-graph':
    case 'line-graph-border':
    case 'chart-line':
        iconDataKey = 'chart-line';
        break;
    case 'chart-pie-next':
        iconDataKey = 'chart-pie-next';
        break;
    case 'pie':
    case 'pie-graph':
    case 'chart-pie':
        iconDataKey = 'chart-pie';
        break;
    case 'chat-next':
        iconDataKey = 'chat-next';
        break;
    case 'chat-bubble':
    case 'chat':
        iconDataKey = 'chat';
        break;
    case 'chats-next':
        iconDataKey = 'chats-next';
        break;
    case 'check-payment-next':
        iconDataKey = 'check-payment-next';
        break;
    case 'check-payment':
        iconDataKey = 'check-payment';
        break;
    case 'checks':
    case 'autorule-setup':
    case 'checkboxes':
        iconDataKey = 'checkboxes';
        break;
    case 'checkbox':
    case 'checkmark-box':
        iconDataKey = 'checkmark-box';
        break;
    case 'checkmark-circle-next':
        iconDataKey = 'checkmark-circle-next';
        break;
    case 'checkmark-circle-solid':
        iconDataKey = 'checkmark-circle-solid';
        break;
    case 'check-circle':
    case 'tick-solid':
    case 'checkmark-circle':
        iconDataKey = 'checkmark-circle';
        break;
    case 'checkmark-square-next':
        iconDataKey = 'checkmark-square-next';
        break;
    case 'check':
    case 'check-mark':
    case 'tick':
    case 'checkmark':
        iconDataKey = 'checkmark';
        break;
    case 'circle-arrow-down-next':
        iconDataKey = 'circle-arrow-down-next';
        break;
    case 'circle-arrow-left-next':
        iconDataKey = 'circle-arrow-left-next';
        break;
    case 'circle-arrow-right-next':
        iconDataKey = 'circle-arrow-right-next';
        break;
    case 'circle-arrow-up-next':
        iconDataKey = 'circle-arrow-up-next';
        break;
    case 'circle-minus-arrow':
        iconDataKey = 'circle-minus-arrow';
        break;
    case 'circle-next':
        iconDataKey = 'circle-next';
        break;
    case 'circle-selected-next':
        iconDataKey = 'circle-selected-next';
        break;
    case 'solid-circle':
    case 'circle-solid':
        iconDataKey = 'circle-solid';
        break;
    case 'circle':
        iconDataKey = 'circle';
        break;
    case 'check-clipboard':
    case 'clipboard-check':
    case 'clipboard-checkmark':
        iconDataKey = 'clipboard-checkmark';
        break;
    case 'clipboard-document':
        iconDataKey = 'clipboard-document';
        break;
    case 'clip-board':
    case 'clipboard':
        iconDataKey = 'clipboard';
        break;
    case 'history':
    case 'deduction-history':
    case 'clock-arrow-left':
        iconDataKey = 'clock-arrow-left';
        break;
    case 'check-clock':
    case 'timekeeper':
    case 'clock-checkmark':
        iconDataKey = 'clock-checkmark';
        break;
    case 'clock-money-next':
        iconDataKey = 'clock-money-next';
        break;
    case 'dollar-time':
    case 'rates':
    case 'clock-money':
        iconDataKey = 'clock-money';
        break;
    case 'clock-next':
        iconDataKey = 'clock-next';
        break;
    case 'diary':
    case 'clock':
        iconDataKey = 'clock';
        break;
    case 'columns-next':
        iconDataKey = 'columns-next';
        break;
    case 'columns-x-next':
        iconDataKey = 'columns-x-next';
        break;
    case 'remove-columns':
    case 'columns-x':
        iconDataKey = 'columns-x';
        break;
    case 'columns':
        iconDataKey = 'columns';
        break;
    case 'contact-card-next':
        iconDataKey = 'contact-card-next';
        break;
    case 'contacts':
    case 'profile-card':
    case 'membership':
    case 'contact-card':
        iconDataKey = 'contact-card';
        break;
    case 'container-arrow-down-next':
        iconDataKey = 'container-arrow-down-next';
        break;
    case 'download':
    case 'container-arrow-down':
        iconDataKey = 'container-arrow-down';
        break;
    case 'container-arrow-up-next':
        iconDataKey = 'container-arrow-up-next';
        break;
    case 'upload':
    case 'container-arrow-up':
        iconDataKey = 'container-arrow-up';
        break;
    case 'credit-card-next':
        iconDataKey = 'credit-card-next';
        break;
    case 'card':
    case 'credit-card':
        iconDataKey = 'credit-card';
        break;
    case 'stack':
    case 'cylinder-stack':
        iconDataKey = 'cylinder-stack';
        break;
    case 'doc-download':
    case 'download-doc':
    case 'document-arrow-down':
        iconDataKey = 'document-arrow-down';
        break;
    case 'document-arrow-next':
        iconDataKey = 'document-arrow-next';
        break;
    case 'export':
    case 'document-arrow':
        iconDataKey = 'document-arrow';
        break;
    case 'doc-check':
    case 'doc-check-solid':
    case 'document-check':
        iconDataKey = 'document-check';
        break;
    case 'document-clock':
        iconDataKey = 'document-clock';
        break;
    case 'document-csv-next':
        iconDataKey = 'document-csv-next';
        break;
    case 'csv':
    case 'document-csv':
        iconDataKey = 'document-csv';
        break;
    case 'doc-dollar':
    case 'invoices':
    case 'document-dollar-sign':
        iconDataKey = 'document-dollar-sign';
        break;
    case 'doc':
    case 'claim-notes':
    case 'doc-solid':
    case 'reporting':
    case 'document-lines':
        iconDataKey = 'document-lines';
        break;
    case 'document-magnify-next':
        iconDataKey = 'document-magnify-next';
        break;
    case 'doc-search':
    case 'search-doc':
    case 'document-magnify':
        iconDataKey = 'document-magnify';
        break;
    case 'document-minus-next':
        iconDataKey = 'document-minus-next';
        break;
    case 'doc-delete':
    case 'delete-doc':
    case 'document-minus':
        iconDataKey = 'document-minus';
        break;
    case 'document-next':
        iconDataKey = 'document-next';
        break;
    case 'document-payment-next':
        iconDataKey = 'document-payment-next';
        break;
    case 'doc-cash':
    case 'invoicing':
    case 'document-payment':
        iconDataKey = 'document-payment';
        break;
    case 'document-pdf-next':
        iconDataKey = 'document-pdf-next';
        break;
    case 'pdf':
    case 'document-pdf':
        iconDataKey = 'document-pdf';
        break;
    case 'doc-edit':
    case 'edit-doc':
    case 'document-pencil':
        iconDataKey = 'document-pencil';
        break;
    case 'document-plus-next':
        iconDataKey = 'document-plus-next';
        break;
    case 'doc-blank-add':
    case 'add-doc':
    case 'doc-add':
    case 'document-plus':
        iconDataKey = 'document-plus';
        break;
    case 'document-po-next':
        iconDataKey = 'document-po-next';
        break;
    case 'doc-question':
    case 'question-doc':
    case 'document-question':
        iconDataKey = 'document-question';
        break;
    case 'document-slash':
        iconDataKey = 'document-slash';
        break;
    case 'document-xls-next':
        iconDataKey = 'document-xls-next';
        break;
    case 'xls':
    case 'document-xls':
        iconDataKey = 'document-xls';
        break;
    case 'document-xml-next':
        iconDataKey = 'document-xml-next';
        break;
    case 'xml':
    case 'document-xml':
        iconDataKey = 'document-xml';
        break;
    case 'doc-blank':
    case 'single-doc':
    case 'document':
        iconDataKey = 'document';
        break;
    case 'documents-next':
        iconDataKey = 'documents-next';
        break;
    case 'docs':
    case 'docs-multiple':
    case 'multiple-docs':
    case 'docs-multiple-solid':
    case 'multiple-docs-solid':
    case 'documents':
        iconDataKey = 'documents';
        break;
    case 'door-arrow-in':
        iconDataKey = 'door-arrow-in';
        break;
    case 'door-arrow-out':
        iconDataKey = 'door-arrow-out';
        break;
    case 'dot-dot-dot-next':
        iconDataKey = 'dot-dot-dot-next';
        break;
    case 'download-drawer':
    case 'drawer-arrow-down':
        iconDataKey = 'drawer-arrow-down';
        break;
    case 'upload-drawer':
    case 'drawer-arrow-up':
        iconDataKey = 'drawer-arrow-up';
        break;
    case 'drawer':
        iconDataKey = 'drawer';
        break;
    case 'envelope-document':
        iconDataKey = 'envelope-document';
        break;
    case 'envelope-next':
        iconDataKey = 'envelope-next';
        break;
    case 'filled-envelope':
    case 'filled-mail':
    case 'mail-open':
    case 'envelope-open':
        iconDataKey = 'envelope-open';
        break;
    case 'mail':
    case 'envelope':
        iconDataKey = 'envelope';
        break;
    case 'exclamation-circle-next':
        iconDataKey = 'exclamation-circle-next';
        break;
    case 'exclamation-solid':
    case 'exclamation-circle-solid':
        iconDataKey = 'exclamation-circle-solid';
        break;
    case 'exclamation-circle':
        iconDataKey = 'exclamation-circle';
        break;
    case 'exclamation-triangle-next':
        iconDataKey = 'exclamation-triangle-next';
        break;
    case 'warning':
    case 'exclamation-triangle-solid':
        iconDataKey = 'exclamation-triangle-solid';
        break;
    case 'exclamation-triangle':
        iconDataKey = 'exclamation-triangle';
        break;
    case 'eye-line-next':
        iconDataKey = 'eye-line-next';
        break;
    case 'hide':
    case 'eye-line':
        iconDataKey = 'eye-line';
        break;
    case 'eye-next':
        iconDataKey = 'eye-next';
        break;
    case 'eyeball':
    case 'eye':
        iconDataKey = 'eye';
        break;
    case 'filter-next':
        iconDataKey = 'filter-next';
        break;
    case 'funnel-graph':
    case 'filter-stacked':
        iconDataKey = 'filter-stacked';
        break;
    case 'filter':
        iconDataKey = 'filter';
        break;
    case 'flag-next':
        iconDataKey = 'flag-next';
        break;
    case 'flag':
        iconDataKey = 'flag';
        break;
    case 'save':
    case 'floppy-disk':
        iconDataKey = 'floppy-disk';
        break;
    case 'lock-folder':
    case 'folder-lock':
        iconDataKey = 'folder-lock';
        break;
    case 'folder-minus':
        iconDataKey = 'folder-minus';
        break;
    case 'open-folder':
    case 'folder-open':
        iconDataKey = 'folder-open';
        break;
    case 'folder-plus':
        iconDataKey = 'folder-plus';
        break;
    case 'file-zip':
    case 'folder-zip':
        iconDataKey = 'folder-zip';
        break;
    case 'folder':
        iconDataKey = 'folder';
        break;
    case 'funnel-next':
        iconDataKey = 'funnel-next';
        break;
    case 'gauge':
        iconDataKey = 'gauge';
        break;
    case 'gavel-next':
        iconDataKey = 'gavel-next';
        break;
    case 'gavel':
        iconDataKey = 'gavel';
        break;
    case 'gear-next':
        iconDataKey = 'gear-next';
        break;
    case 'gear':
        iconDataKey = 'gear';
        break;
    case 'multiple-gears':
    case 'gears':
        iconDataKey = 'gears';
        break;
    case 'global-network':
    case 'globe-gears':
        iconDataKey = 'globe-gears';
        break;
    case 'globe-next':
        iconDataKey = 'globe-next';
        break;
    case 'globe':
        iconDataKey = 'globe';
        break;
    case 'graduation-cap-next':
        iconDataKey = 'graduation-cap-next';
        break;
    case 'grad-cap':
    case 'graduation-cap':
        iconDataKey = 'graduation-cap';
        break;
    case 'hand':
    case 'handout':
        iconDataKey = 'handout';
        break;
    case 'handshake-next':
        iconDataKey = 'handshake-next';
        break;
    case 'home-next':
        iconDataKey = 'home-next';
        break;
    case 'home':
        iconDataKey = 'home';
        break;
    case 'image':
        iconDataKey = 'image';
        break;
    case 'info-circle-next':
        iconDataKey = 'info-circle-next';
        break;
    case 'info-solid':
    case 'info-circle-solid':
        iconDataKey = 'info-circle-solid';
        break;
    case 'info':
    case 'info-circle':
        iconDataKey = 'info-circle';
        break;
    case 'key':
        iconDataKey = 'key';
        break;
    case 'screen-browser':
    case 'layout-browser':
        iconDataKey = 'layout-browser';
        break;
    case 'letters':
        iconDataKey = 'letters';
        break;
    case 'lightbulb':
        iconDataKey = 'lightbulb';
        break;
    case 'lines-arrow-down':
        iconDataKey = 'lines-arrow-down';
        break;
    case 'lines-next':
        iconDataKey = 'lines-next';
        break;
    case 'rows':
    case 'lines':
        iconDataKey = 'lines';
        break;
    case 'list-browser':
        iconDataKey = 'list-browser';
        break;
    case 'list-next':
        iconDataKey = 'list-next';
        break;
    case 'numbers':
    case 'numbered-list':
    case 'list-number':
        iconDataKey = 'list-number';
        break;
    case 'list-outline':
        iconDataKey = 'list-outline';
        break;
    case 'list':
        iconDataKey = 'list';
        break;
    case 'locator':
        iconDataKey = 'locator';
        break;
    case 'lock-next':
        iconDataKey = 'lock-next';
        break;
    case 'padlock':
    case 'lock':
        iconDataKey = 'lock';
        break;
    case 'magnify-circle-next':
        iconDataKey = 'magnify-circle-next';
        break;
    case 'magnify-minus':
        iconDataKey = 'magnify-minus';
        break;
    case 'magnify-next':
        iconDataKey = 'magnify-next';
        break;
    case 'magnify-plus':
        iconDataKey = 'magnify-plus';
        break;
    case 'search':
    case 'magnify':
        iconDataKey = 'magnify';
        break;
    case 'microphone-next':
        iconDataKey = 'microphone-next';
        break;
    case 'microphone-slash-next':
        iconDataKey = 'microphone-slash-next';
        break;
    case 'microphone-slash':
        iconDataKey = 'microphone-slash';
        break;
    case 'microphone':
        iconDataKey = 'microphone';
        break;
    case 'minus-circle-next':
        iconDataKey = 'minus-circle-next';
        break;
    case 'minus-solid':
    case 'error':
    case 'minus-circle-solid':
        iconDataKey = 'minus-circle-solid';
        break;
    case 'minus-circle':
        iconDataKey = 'minus-circle';
        break;
    case 'minus':
        iconDataKey = 'minus';
        break;
    case 'monitor':
        iconDataKey = 'monitor';
        break;
    case 'branch':
    case 'network-branch':
        iconDataKey = 'network-branch';
        break;
    case 'network':
        iconDataKey = 'network';
        break;
    case 'paper-clip-next':
        iconDataKey = 'paper-clip-next';
        break;
    case 'paper_clip':
    case 'paper-clip':
        iconDataKey = 'paper-clip';
        break;
    case 'pause-next':
        iconDataKey = 'pause-next';
        break;
    case 'cash-history':
    case 'budget-history':
    case 'rebate':
    case 'payment-left-arrow':
        iconDataKey = 'payment-left-arrow';
        break;
    case 'payment-next':
        iconDataKey = 'payment-next';
        break;
    case 'cash-quick':
    case 'quick-pay':
    case 'payment-quick':
        iconDataKey = 'payment-quick';
        break;
    case 'cash':
    case 'budget':
    case 'payments':
    case 'currency':
    case 'payment':
        iconDataKey = 'payment';
        break;
    case 'pencil-next':
        iconDataKey = 'pencil-next';
        break;
    case 'pencil':
        iconDataKey = 'pencil';
        break;
    case 'people-next':
        iconDataKey = 'people-next';
        break;
    case 'multiple-person':
    case 'users':
    case 'people':
        iconDataKey = 'people';
        break;
    case 'person-minus-next':
        iconDataKey = 'person-minus-next';
        break;
    case 'user-minus':
    case 'person-minus':
        iconDataKey = 'person-minus';
        break;
    case 'person-network':
        iconDataKey = 'person-network';
        break;
    case 'person-next':
        iconDataKey = 'person-next';
        break;
    case 'person-plus-next':
        iconDataKey = 'person-plus-next';
        break;
    case 'user-plus':
    case 'person-plus':
        iconDataKey = 'person-plus';
        break;
    case 'person-delete':
    case 'user-delete':
    case 'person-x':
        iconDataKey = 'person-x';
        break;
    case 'user':
    case 'person':
        iconDataKey = 'person';
        break;
    case 'mobile-phone':
    case 'phone-mobile':
        iconDataKey = 'phone-mobile';
        break;
    case 'phone-next':
        iconDataKey = 'phone-next';
        break;
    case 'smart-phone':
    case 'phone-touch-screen':
        iconDataKey = 'phone-touch-screen';
        break;
    case 'phone':
        iconDataKey = 'phone';
        break;
    case 'pin-next':
        iconDataKey = 'pin-next';
        break;
    case 'pin':
        iconDataKey = 'pin';
        break;
    case 'play-next':
        iconDataKey = 'play-next';
        break;
    case 'plus-circle-next':
        iconDataKey = 'plus-circle-next';
        break;
    case 'add-solid':
    case 'plus-circle-solid':
        iconDataKey = 'plus-circle-solid';
        break;
    case 'add':
    case 'add-circle':
    case 'plus-circle':
        iconDataKey = 'plus-circle';
        break;
    case 'plus':
        iconDataKey = 'plus';
        break;
    case 'power-button':
    case 'power':
        iconDataKey = 'power';
        break;
    case 'printer-next':
        iconDataKey = 'printer-next';
        break;
    case 'print':
    case 'printer':
        iconDataKey = 'printer';
        break;
    case 'puck':
        iconDataKey = 'puck';
        break;
    case 'question-circle-next':
        iconDataKey = 'question-circle-next';
        break;
    case 'question-solid':
    case 'question-circle-solid':
        iconDataKey = 'question-circle-solid';
        break;
    case 'question-circle':
        iconDataKey = 'question-circle';
        break;
    case 'question-mark':
        iconDataKey = 'question-mark';
        break;
    case 'ribbon':
    case 'rosette':
    case 'ribbon-award':
        iconDataKey = 'ribbon-award';
        break;
    case 'scales-next':
        iconDataKey = 'scales-next';
        break;
    case 'disposition':
    case 'law':
    case 'scales':
        iconDataKey = 'scales';
        break;
    case 'servers':
        iconDataKey = 'servers';
        break;
    case 'settings-solid':
    case 'settings':
        iconDataKey = 'settings';
        break;
    case 'shield-checkmark':
        iconDataKey = 'shield-checkmark';
        break;
    case 'dollar-shield':
    case 'money-shield':
    case 'shield-dollar':
        iconDataKey = 'shield-dollar';
        break;
    case 'shield-next':
        iconDataKey = 'shield-next';
        break;
    case 'shield':
        iconDataKey = 'shield';
        break;
    case 'cancel':
    case 'disabled':
    case 'slash-circle':
        iconDataKey = 'slash-circle';
        break;
    case 'snowflake-next':
        iconDataKey = 'snowflake-next';
        break;
    case 'snowflake':
        iconDataKey = 'snowflake';
        break;
    case 'square-next':
        iconDataKey = 'square-next';
        break;
    case 'squares-four-next':
        iconDataKey = 'squares-four-next';
        break;
    case 'grid-medium':
    case 'medium-grid':
    case 'squares-four':
        iconDataKey = 'squares-four';
        break;
    case 'grid':
    case 'large-grid':
    case 'squares-nine':
        iconDataKey = 'squares-nine';
        break;
    case 'layer':
    case 'squares-stacked':
        iconDataKey = 'squares-stacked';
        break;
    case 'star-next':
        iconDataKey = 'star-next';
        break;
    case 'star':
        iconDataKey = 'star';
        break;
    case 'table-next':
        iconDataKey = 'table-next';
        break;
    case 'pivot-table':
    case 'table-pivot':
        iconDataKey = 'table-pivot';
        break;
    case 'table':
        iconDataKey = 'table';
        break;
    case 'tools':
        iconDataKey = 'tools';
        break;
    case 'transfer-next':
        iconDataKey = 'transfer-next';
        break;
    case 'trash-can-next':
        iconDataKey = 'trash-can-next';
        break;
    case 'trash':
    case 'trash-can':
        iconDataKey = 'trash-can';
        break;
    case 'down-triangle':
    case 'triangle-down':
        iconDataKey = 'triangle-down';
        break;
    case 'left-triangle':
    case 'triangle-left':
        iconDataKey = 'triangle-left';
        break;
    case 'triangle-next':
        iconDataKey = 'triangle-next';
        break;
    case 'right-triangle':
    case 'triangle-right':
        iconDataKey = 'triangle-right';
        break;
    case 'up-triangle':
    case 'triangle-up':
        iconDataKey = 'triangle-up';
        break;
    case 'umbrella-next':
        iconDataKey = 'umbrella-next';
        break;
    case 'coverage':
    case 'umbrella':
        iconDataKey = 'umbrella';
        break;
    case 'unlock-next':
        iconDataKey = 'unlock-next';
        break;
    case 'lock-open':
    case 'padlock-open':
    case 'unlock':
        iconDataKey = 'unlock';
        break;
    case 'use-funds-next':
        iconDataKey = 'use-funds-next';
        break;
    case 'video-camera':
        iconDataKey = 'video-camera';
        break;
    case 'wifi':
        iconDataKey = 'wifi';
        break;
    case 'wrench':
        iconDataKey = 'wrench';
        break;
    case 'x-circle-next':
        iconDataKey = 'x-circle-next';
        break;
    case 'circle-close-solid':
    case 'x-circle-solid':
        iconDataKey = 'x-circle-solid';
        break;
    case 'circle-close':
    case 'x-circle':
        iconDataKey = 'x-circle';
        break;
    case 'close':
    case 'close-solid':
    case 'x':
        iconDataKey = 'x';
        break;
    default:
        iconDataKey = iconName;
        break;
    }

    if (this.iconsData[iconDataKey]) {
        return this.iconsData[iconDataKey];
    }
    if (this.mainOptions.customLegacyAliases) {
        const [foundKey] =
            Object.entries(this.mainOptions.customLegacyAliases)
                .find(([, value]) => value.includes(iconDataKey)) || [];
        return foundKey && this.iconsData[foundKey] ? this.iconsData[foundKey] : '';
    }
    return '';
}

export { getIcon, getIconByAlias, getIconHelper };
