const Constants = {
    CHECK_INQUIRY: {
        TABS: {
            REAL_TIME: 'REALTIME',
            HISTORY: 'HISTORY',
        },

        CHECK_INQUIRY_HISTORY: 20512,
        HISTORY_FILTER: 20598,
        FUNCTIONCODE: 'INQUIRY',

        INQUIRYID: {
            REALTIME: 20503,
            HISTORY: 20598,
        },
        TYPECODE: {
            CHECKINQHISTORY: 'HISTORY',
            CHECKINQUIRYOLD: 'CMINQ',
        },
        URL: {
            PATH: 'PAYMENTS/checkInquiryView',
        },
    },
    RTP: {
        INQUIRYID: {
            RECEIVED: '28600',
            SUBMITTED: '28500',
        },
        UNREAD_VIEWID: {
            RECEIVED: '28605',
            SUBMITTED: '28520',
        },
    },
    DYNAMIC_DATE_OPTION_PAYLOAD: {
        productCode: 'CM',
        functionCode: 'INST',
        typeCode: 'FUTURE',
        actionMode: 'INSERT',
        subTypeCode: '*',
        fieldName: 'FUTUREDURATIONDESC',
        entryClass: '',
        allowDuplicates: false,
    },
    PLACE_STOP_API: '/cm/placeStop',
    STOPPAYMENTS_CONTEXTKEY: 'cm_listView_stopCancelsSearch',
    PLACESTOP_PAGE: 'CM/multiPreFillAdd/STOP',
};

export default Constants;
