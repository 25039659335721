import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

import ActivateTokenModel from 'system/mfa/models/activateToken';
import Constants from 'system/mfa/constants';
import services from 'services';
import transform from 'common/util/transform';

export default ActivateTokenModel.extend({
    sync(method, model, options) {
        let data;
        let service;
        let items;
        let uri;

        if (method === 'create') {
            data = this.convertModelAttributesToServerJSON(method);
            uri = model.get('challengeType') === Constants.ONESPAN_CHALLENGE_TYPE
                ? 'mfaService/loginSoftTokenRegister' : 'mfaService/softTokenRegister';
            service = services.generateUrl(uri);

            return http.post(service, data, (response) => {
                options.success(response);
            }, (response) => {
                // Passing default App Resource incase response is null
                options.error(response, 'mfa.error.message');
            });
        }

        if (method === 'register') {
            data = this.convertModelAttributesToServerJSON(method);
            service = services.generateUrl('mfaService/loginSoftTokenRegister');

            return http.post(service, data, (response) => {
                let localResponse = response;
                if (localResponse.customParameters) {
                    items = localResponse.customParameters.item;

                    if (items.length > 0) {
                        items = transform.pairsToHash(localResponse.customParameters.item);
                        localResponse = util.extend(localResponse, items);
                    }
                }
                options.success(localResponse);
            }, (response) => {
                // Passing default App Resource incase response is null
                options.error('mfa.error.message', response);
            });
        }
        // TODO: Should return rejected Promise for bad method?
        return undefined;
    },

    convertModelAttributesToServerJSON() {
        const challengeAction = util.clone(this.attributes.challengeAction);
        const challengeType = util.clone(this.attributes.challengeType);
        return {
            challengedAction: challengeAction,
            tokenType: challengeType,
            registrationId: this.attributes.registrationId,
            activationCode: this.attributes.activationCode,
            prompts: this.attributes.prompts,
        };
    },
});
