import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import LockboxBatchIDs from 'app/reports/collections/lockboxBatchIDs';
import GridApi from 'common/dynamicPages/api/grid';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from 'app/reports/views/lockbox/lockboxPanel.hbs';

const LockboxPanel = Layout.extend({
    template,

    ui: {
        $panel: '.panel-secondary .panel-heading',
        $viewDetails: '[data-action="viewTransactionDetails"]',
    },

    events: {
        'click @ui.$panel': 'preProcessRenderGrid',
    },

    /**
     * @method preProcessRenderGrid
     * - fetches the batch ids for the selected time range and the lockbox ids.
     */
    preProcessRenderGrid() {
        const lockboxBatchIDs = new LockboxBatchIDs({
            additionalSearchFields: [{
                operator: 'IN',
                fieldValue: [this.model.get('LOCKBOX_ID')],
                dataType: 'NUMBER',
                fieldName: 'LOCKBOX_ID',
            }, {
                operator: 'BETWEEN',

                fieldValue: [
                    this.options.dates.minUnFormatted,
                    this.options.dates.maxUnFormatted,
                ],

                dataType: 'date',
                fieldName: 'DEPOSIT_DATE',
            }],
        });
        this.getPromise(lockboxBatchIDs).then(this.updateBatchNumbers.bind(this));
    },

    /**
     * @method updateBatchNumbers
     * @param {Object} batchIDs - contains the list of batch tnums
     * - Updates the batch numbers in the model and renders the grid.
     */
    updateBatchNumbers(batchIDs) {
        this.model.set('batchNumbers', batchIDs.pluck('BATCH_ID'));
        this.renderLockboxGrid();
    },

    /**
     * @method renderLockboxGrid
     * - Render the lockbox grid into the gridRegion
     */
    renderLockboxGrid() {
        const types = util.chain(this.options.types)
            .uniq(item => item.value).pluck('value').value();

        const reportOptions = {
            context: {
                serviceName: '/lockbox/getGroupSummary',
                productCode: '',
                functionCode: '',
                typeCode: '',
            },

            enableSavedViews: true,
            selector: 'none',
            hideGridActionButtons: true,

            additionalSearchFields: [{
                operator: 'IN',
                fieldValue: [this.model.get('LOCKBOX_ID')],
                dataType: 'NUMBER',
                fieldName: 'LOCKBOX_ID',
            }, {
                operator: 'BETWEEN',

                fieldValue: [
                    this.options.dates.minUnFormatted,
                    this.options.dates.maxUnFormatted,
                ],

                dataType: 'date',
                fieldName: 'DEPOSIT_DATE',
            }, {
                operator: 'IN',
                fieldValue: types,
                dataType: 'TEXT',
                fieldName: 'ITEM_TYPE_CODE',
            }],
        };

        if (this.model.get('REPORT_FLOAT') === 'N') {
            reportOptions.viewId = 29082;
        }
        this.gridView = GridApi.createServiceGridView(reportOptions);
        this.listenTo(this.gridView, 'gridapi:loaded', this.renderTransactionDetails);
        if (mobileUtil.isMobileGridEnabled()) {
            let MobileList = LockboxPanel;
            const mobileList = configureMobileInterface(MobileList, {
                useGridViewFromOptions: true,
                enableSavedViews: true,
                context: reportOptions.context,
                callbackAfterLoad: 'renderTransactionDetails',
            });
            MobileList = MobileList.extend(mobileList);
            this.gridRegion.show(new MobileList({
                ...this.options,
                ...reportOptions,
                prebuiltOptions: true,
                gridView: this.gridView,
            }));
        } else {
            this.gridRegion.show(this.gridView);
        }
    },

    /**
     * @method renderTransactionDetails
     * Populate the View Transaction Details link with its text Detail Reports flag is enabled
     */
    renderTransactionDetails() {
        const gridRows = this.gridView.wrapper.rows.toJSON();
        if (gridRows.length > 0 && gridRows[0].DETAIL_REPORTS === 'Y') {
            const linkText = locale.get('LBX.ViewTransactionDetails');
            if (mobileUtil.isMobileGridEnabled()) {
                this.$el.parent().parent().find('a').text(linkText);
            } else {
                this.ui.$viewDetails.text(linkText);
            }
        }
    },

    /**
     * @method getPromise
     * @param {object} collection
     * @returns new promise
     * - Accepts a collection and returns a new promise
     */
    getPromise(collection) {
        return new Promise((resolve, reject) => {
            collection.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * @method viewTransactionDetails
     * - Navigate to the next page.
     */
    viewTransactionDetails() {
        const reportData = {
            batch: this.model.get('batchNumbers'),
            filter: `${this.model.get('LOCKBOXCODE')} ${this.model.get('LOCATIONCODE')}`,
            range: this.options.dates.value,
            minDate: this.options.dates.minUnFormatted,
            maxDate: this.options.dates.maxUnFormatted,
            types: this.options.types,
            name: this.model.get('NAME'),
            groupView: false,
        };

        store.set('lbxReportData', reportData);
        this.navigateTo('REPORTING/lockbox/reports/details');
    },
});

export default LockboxPanel;
