import React from 'react';
import PropTypes from 'prop-types';

const ColumnSort = ({
  order
}) => (
  <span>
    {order === 'asc' && (<i className="ag-icon ag-icon-asc" />)}
    {order === 'desc' && (<i className="ag-icon ag-icon-desc" />)}
  </span>
);

ColumnSort.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc', 'none'])
};

ColumnSort.defaultProps = {
  order: 'none'
};

export default ColumnSort;
