import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import WireBase from 'app/smbPayments/models/wireBase';

export default WireBase.extend({
    defaults() {
        return {
            ACCOUNTFILTER: '',
            ALLOWCONTRACTRATEOVERRIDE: '1',
            BENE_NAME: '',
            BENE_ACCOUNT: '',
            BENE_ACCOUNT_TYPE: '',
            BENE_BANK_CITY: '',
            BENE_BANK_STATE: '',
            BENE_BANK_ID: '',
            BENE_BANK_TYPE: '',
            BENE_BANK_NAME: '',
            BENE_BANK_COUNTRY: '',
            BENE_COUNTRY: '',
            BILL_ID: '',
            CORRESPONDENT_ADDRESS_1: '',
            CORRESPONDENT_ADDRESS_2: '',
            CORRESPONDENT_ADDRESS_3: '',
            CORRESPONDENT_COUNTRY: '',
            CORRESPONDENT_ID: '',
            CORRESPONDENT_NAME: '',
            CORRESPONDENT_TYPE: '',
            CORRESPONDENT_STATE: '',
            CREDIT_AMOUNT: '',
            CREDIT_CURRENCY: '',
            DEBIT_ACCOUNT_TITLE: '',
            DEBIT_ACCOUNT_NUMBER: '',
            DEBIT_BANK_CODE: '',
            DEBIT_ACCOUNT_BANK_NAME: '',
            DefaultCharge: 'SHA',
            DETAILS_OF_CHARGES: 'SHA',
            DUPLICATEREASON: '',
            EXCHANGERATETBD_FLAG: '',
            ENTRYMETHOD: '0',
            FXRATETYPE: '0',
            INTERBANKIDENTRYMETHOD: '',
            INTER_BANK_ACCOUNT_NUMBER: '',
            INTERMEDIARY_ADDRESS_1: '',
            INTERMEDIARY_ADDRESS_2: '',
            INTERMEDIARY_ADDRESS_3: '',
            INTERMEDIARY_COUNTRY: '',
            INTERMEDIARY_ID: '',
            INTERMEDIARY_NAME: '',
            INTERMEDIARY_IDTYPE: '',
            INTERMEDIARY_STATE: '',
            INDICATIVERATE: '',
            INDICATIVEAMOUNT: '',
            EXCHANGE_RATE: '',
            DEBIT_AMOUNT: '',
            EXCHANGE_RATE_CONTRACTID: '',
            MARKETCONVENTION: 'I',
            ORIGINATOR_NAME: '',
            OBI_TEXT_1: '',
            OBI_TEXT_2: '',
            OBI_TEXT_3: '',
            OBI_TEXT_4: '',
            PARENTUSERGROUP: userInfo.get('group'),
            SPECIAL_INSTRUCTIONS: '',
            TRAN_DATE: '',
            USERGROUP: userInfo.get('group'),
            VALUE_DATE: '',
            _saveIncomplete: '',
            duplicateAccepted: '',
        };
    },

    setAttributes() {
        const { account, attributes, beneAccount } = this.options;

        const beneKeys = beneAccount.pick([
            'BENE_NAME',
            'BENE_ACCOUNT',
            'BENE_BANK_STATE',
            'BENE_BANK_ID',
            'BENE_BANK_NAME',
            'BENE_BANK_COUNTRY',
            'BENE_COUNTRY',
            'CORRESPONDENT_ADDRESS_1',
            'CORRESPONDENT_ADDRESS_2',
            'CORRESPONDENT_ADDRESS_3',
            'CORRESPONDENT_COUNTRY',
            'CORRESPONDENT_ID',
            'CORRESPONDENT_NAME',
            'CORRESPONDENT_STATE',
            'CORRESPONDENT_TYPE',
            'INTERBANKIDENTRYMETHOD',
            'INTER_BANK_ACCOUNT_NUMBER',
            'INTERMEDIARY_ADDRESS_1',
            'INTERMEDIARY_ADDRESS_2',
            'INTERMEDIARY_ADDRESS_3',
            'INTERMEDIARY_COUNTRY',
            'INTERMEDIARY_ID',
            'INTERMEDIARY_IDTYPE',
            'INTERMEDIARY_NAME',
            'INTERMEDIARY_STATE',
        ]);

        const accountAttributeKeys = util.reduce([
            'ACCOUNTFILTER',
            'DEBIT_ACCOUNT_TITLE',
            'DEBIT_ACCOUNT_BANK_NAME',
            'DEBIT_BANK_CODE',
            'ACCOUNT_TYPE',
            'ORIGINATOR_ID',
            'ORIGINATOR_ID_TYPE',
            'ORIGINATOR_NAME',
            'ORIGINATOR_ADDRESS_1',
            'ORIGINATOR_ADDRESS_2',
            'ORIGINATOR_CITY',
            'ORIGINATOR_POSTALCODE',
            'ORIGINATOR_STATE',
        ], (acc, key) => {
            acc[key] = this.getAccountAttribute(account, key);
            return acc;
        }, {});

        const attributeKeys = util.pick(
            attributes,
            [
                'BILL_ID',
                'VALUE_DATE',
                'CREDIT_AMOUNT',
                'DEBIT_CURRENCY',
                'OBI_TEXT_1',
                'OBI_TEXT_2',
                'OBI_TEXT_3',
                'OBI_TEXT_4',
                'SPECIAL_INSTRUCTIONS',
                '_saveIncomplete',
                '_saveWithWarning',
                'DUPLICATEREASON',
                'duplicateAccepted',
                'CONTACTHASBEENUPDATED',
            ],
        );

        // These special keys change names
        const specialKeys = {
            DEBIT_ACCOUNT_NUMBER: attributes.DEBIT_ACCOUNT_NUMBER,
            TRAN_DATE: attributes.TRAN_DATE || attributes.VALUE_DATE,
            CREDIT_CURRENCY: attributes.CREDIT_CURRENCY || beneAccount.get('BENE_ACCOUNT_CURRENCY'),
            BENE_ACCOUNT_TYPE: beneAccount.get('BENE_ACCOUNTTYPE') || 'Other',
            BENE_BANK_CITY: beneAccount.get('BENE_BANK_ADDRESS_3'),
            BENE_BANK_TYPE: beneAccount.get('BENE_BANK_IDTYPE'),
        };

        let attrIDKeys;

        if (attributes.id) {
            attrIDKeys = {
                TNUM: attributes.id,
                // eslint-disable-next-line
                UPDATECOUNT__: attributes.UPDATECOUNT__,
            };
        }

        if (attributes.fxRateType) {
            if (attributes.EXCHANGE_RATE && attributes.contractIdIsChecked) {
                this.set({
                    EXCHANGERATETBD_FLAG: 'N',
                    EXCHANGE_RATE: attributes.EXCHANGE_RATE,
                    DEBIT_AMOUNT: attributes.DEBIT_AMOUNT,
                    INDICATIVERATE: '',
                    INDICATIVEAMOUNT: '',
                });
            } else {
                this.set({
                    INDICATIVERATE: attributes.INDICATIVERATE,
                    INDICATIVEAMOUNT: attributes.INDICATIVEAMOUNT,
                });
            }
        } else {
            if (attributes.EXCHANGE_RATE) {
                this.set({
                    EXCHANGERATETBD_FLAG: 'N',
                    EXCHANGE_RATE: attributes.EXCHANGE_RATE,
                    DEBIT_AMOUNT: attributes.DEBIT_AMOUNT,
                    INDICATIVERATE: '',
                    INDICATIVEAMOUNT: '',
                });
            }

            if (attributes.INDICATIVERATE) {
                this.set({
                    EXCHANGE_RATE: attributes.INDICATIVERATE,
                    DEBIT_AMOUNT: attributes.INDICATIVEAMOUNT,
                    EXCHANGERATETBD_FLAG: 'N',
                });
            }
        }

        if (attributes.ENTERED_AMOUNT_FLAG) {
            this.set({
                ENTERED_AMOUNT_FLAG: attributes.ENTERED_AMOUNT_FLAG,
            });
        }

        if (attributes.contractIdIsChecked) {
            this.set('EXCHANGE_RATE_CONTRACTID', attributes.EXCHANGE_RATE_CONTRACTID);
        }

        this.set(util.extend(
            {},
            beneKeys,
            accountAttributeKeys,
            attributeKeys,
            specialKeys,
            attrIDKeys,
        ));
    },
});
