var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"source-list-controls\">\n            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-link","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"moveSelectedItems") : depth0),"name":"logout","action":"move-selected-items"},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":129}}}))
    + "\n            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-link","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"moveAllItems") : depth0),"name":"logout","action":"move-all-items"},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":119}}}))
    + "\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"source-list-controls\">\n                "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-link","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"removeSelectedItems") : depth0),"name":"cancel","action":"remove-selected-items"},"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":38,"column":137}}}))
    + "\n                "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-link","textVisible":true,"text":(depth0 != null ? lookupProperty(depth0,"removeAllItems") : depth0),"name":"cancel","action":"remove-all-items"},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":127}}}))
    + "\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"mfa-wait-overlay hidden\">\n    <div class=\"loader\"></div>\n    <div class=\"sr-only\">Loading</div>\n</div>\n<section class=\"source-section\">\n\n    <div class=\"source-section-header\">\n        <div class=\"available-account\">\n        <h3 class=\"source-section-header-text\"></h3>\n        <div class=\"search-box\">\n        <label  class=\"sr-only\" for=\"source-list-search-filter-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":11,"column":63},"end":{"line":11,"column":70}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"filter") || (depth0 != null ? lookupProperty(depth0,"filter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filter","hash":{},"data":data,"loc":{"start":{"line":11,"column":72},"end":{"line":11,"column":82}}}) : helper)))
    + "</label>\n        <input type=\"text\" size=\"20\" id=\"source-list-search-filter-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":12,"column":67},"end":{"line":12,"column":74}}}) : helper)))
    + "\" placeholder="
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":88},"end":{"line":12,"column":114}}}))
    + " class=\"source-list-search-filter show-search\">\n\n        </div>\n    </div>\n\n    <div class=\"source-list\" data-region=\"sourceListRegion\"></div>\n\n    </ul>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableControls") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "\n</section>\n\n\n<section class=\"destination-section target-section\">\n    <h3 class=\"target-section-header target-section-header-text\"></h3>\n\n    <div data-region=\"targetListRegion\"></div>\n\n    <div class=\"target-list-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableControls") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":41,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</section>\n\n<h3 class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.manageAccountGroups.actions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":45,"column":64}}}))
    + "</h3>\n";
},"useData":true});