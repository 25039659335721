import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';

export default Model.extend({
    initialize(values, options) {
        const self = this;
        this.url = options.url;
        this.queryData = [];
        if (options.queryData) {
            util.each(options.queryData, (queryData) => {
                self.queryData[queryData.fieldName] = queryData;
                self.on((`change:${queryData.fieldName}`), () => {
                    self.handleMapDataSelection(queryData.fieldName);
                });
            });
        }
    },

    handleMapDataSelection(queryName) {
        const self = this;
        const val = this.get(queryName);
        const queryData = this.queryData[queryName];
        util.each(queryData.get(val).get('mapData'), (mapData) => {
            self.set(
                mapData.toField,
                mapData.value,
                {
                    silent: true,
                },
            );
        });
    },

    sync(method, collection, options) {
        if (method === 'create') {
            const url = services.generateUrl(`${this.url}/add`);
            http.post(
                url,
                this.transformModelAttributesToServerJSON(),
                options.success,
                options.error,
            );
        }
    },

    transformModelAttributesToServerJSON() {
        const obj = {
            item: [],
        };
        util.each(this.attributes, (value, key) => {
            obj.item.push({
                name: key,
                value,
            });
        });
        return obj;
    },
});
