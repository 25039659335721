var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"HOSTNAME") || (depth0 != null ? lookupProperty(depth0,"HOSTNAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"HOSTNAME","hash":{},"data":data,"loc":{"start":{"line":18,"column":27},"end":{"line":18,"column":39}}}) : helper)))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"text\"\n                               class=\"form-control\"\n                               name=\"HOSTNAME\"\n                               data-bind=\"model\"\n                               id=\"HOSTNAME-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":24,"column":44},"end":{"line":24,"column":51}}}) : helper)))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SERVERURL") || (depth0 != null ? lookupProperty(depth0,"SERVERURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SERVERURL","hash":{},"data":data,"loc":{"start":{"line":39,"column":27},"end":{"line":39,"column":40}}}) : helper)))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"text\"\n                               class=\"form-control\"\n                               name=\"SERVERURL\"\n                               data-bind=\"model\"\n                               id=\"SERVERURL-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":45,"column":45},"end":{"line":45,"column":52}}}) : helper)))
    + "\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"CREATELOGURL") || (depth0 != null ? lookupProperty(depth0,"CREATELOGURL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"CREATELOGURL","hash":{},"data":data,"loc":{"start":{"line":60,"column":27},"end":{"line":60,"column":43}}}) : helper)))
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"text\"\n                               class=\"form-control\"\n                               name=\"CREATELOGURL\"\n                               data-bind=\"model\"\n                               id=\"CREATELOGURL-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":66,"column":48},"end":{"line":66,"column":55}}}) : helper)))
    + "\">\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"INTERVAL") || (depth0 != null ? lookupProperty(depth0,"INTERVAL") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"INTERVAL","hash":{},"data":data,"loc":{"start":{"line":81,"column":27},"end":{"line":81,"column":39}}}) : helper)))
    + "</p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"number\"\n                               class=\"form-control text-align-right\"\n                               name=\"INTERVAL\"\n                               data-bind=\"model\"\n                               id=\"INTERVAL-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":87,"column":44},"end":{"line":87,"column":51}}}) : helper)))
    + "\">\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"USERNAME") || (depth0 != null ? lookupProperty(depth0,"USERNAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"USERNAME","hash":{},"data":data,"loc":{"start":{"line":102,"column":27},"end":{"line":102,"column":39}}}) : helper)))
    + "</p>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"text\"\n                               class=\"form-control\"\n                               name=\"USERNAME\"\n                               data-bind=\"model\"\n                               id=\"USERNAME-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":108,"column":44},"end":{"line":108,"column":51}}}) : helper)))
    + "\">\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"col-md-3\">\n                <div class=\"form-group required\">\n                    <label for=\"PASSWORD-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":120,"column":41},"end":{"line":120,"column":48}}}) : helper)))
    + "\">\n                        "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.PASSWORD.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":121,"column":24},"end":{"line":121,"column":60}}}))
    + "</label>\n                    <div class=\"input-group\">\n                        <input type=\"password\"\n                               class=\"form-control\"\n                               name=\"PASSWORD\"\n                               data-bind=\"model\"\n                               id=\"PASSWORD-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":127,"column":44},"end":{"line":127,"column":51}}}) : helper)))
    + "\">\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"PASSWORD\"></span>\n                    <span class=\"textline-field\"> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.PASSWORD.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":130,"column":50},"end":{"line":130,"column":85}}}))
    + " </span>\n                </div>\n            </div>\n        </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"col-md-4\">\n                <button type=\"button\"\n                        class=\"btn btn-primary\"\n                        data-hook=\"getSave\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":151,"column":20},"end":{"line":151,"column":44}}}))
    + "\n                </button>\n                <button type=\"button\"\n                        class=\"btn btn-secondary\"\n                        data-hook=\"getReset\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":156,"column":20},"end":{"line":156,"column":45}}}))
    + "\n                </button>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","hash":{"hideBreadcrumb":"true"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"section section-container\">\n    <div class=\"section-body\">\n\n        <div data-region=\"alertRegion\"></div>\n        <fieldset class=\"section-container\">\n            <div class=\"section-header\">\n                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.analyticsServerConfig",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":65}}}))
    + "</legend>\n            </div>\n        </fieldset>\n        <div class=\"row\">\n            <div class=\"col-md-4\">\n                <div class=\"form-group required\">\n                    <label for=\"HOSTNAME-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":48}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.HOSTNAME.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":60}}}))
    + "</label>\n                    <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":25,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"HOSTNAME\"></span>\n                    <span class=\"textline-field\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.HOSTNAME.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":50},"end":{"line":28,"column":85}}}))
    + " </span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-4\">\n                <div class=\"form-group required\">\n                    <label for=\"SERVERURL-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":35,"column":42},"end":{"line":35,"column":49}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.SERVERURL.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":36,"column":61}}}))
    + "</label>\n                    <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":46,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"SERVERURL\"></span>\n                    <span class=\"textline-field\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.SERVERURL.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":50},"end":{"line":49,"column":86}}}))
    + " </span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-4\">\n                <div class=\"form-group required\">\n                    <label for=\"CREATELOGURL-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":56,"column":45},"end":{"line":56,"column":52}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.CREATELOGURL.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":57,"column":64}}}))
    + "</label>\n                    <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":67,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREATELOGURL\"></span>\n                    <span class=\"textline-field\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.CREATELOGURL.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":70,"column":50},"end":{"line":70,"column":89}}}))
    + " </span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-2\">\n                <div class=\"form-group required\">\n                    <label for=\"INTERVAL-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":77,"column":41},"end":{"line":77,"column":48}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.INTERVAL.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":78,"column":60}}}))
    + "</label>\n                    <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":80,"column":24},"end":{"line":88,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"INTERVAL\"></span>\n                    <span class=\"textline-field\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.INTERVAL.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":50},"end":{"line":91,"column":85}}}))
    + " </span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-3\">\n                <div class=\"form-group required\">\n                    <label for=\"USERNAME-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":98,"column":41},"end":{"line":98,"column":48}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.USERNAME.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":99,"column":24},"end":{"line":99,"column":60}}}))
    + "</label>\n                    <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":101,"column":24},"end":{"line":109,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"USERNAME\"></span>\n                    <span class=\"textline-field\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.USERNAME.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":112,"column":50},"end":{"line":112,"column":85}}}))
    + " </span>\n                </div>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModify") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":8},"end":{"line":134,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"row\">\n            <div class=\"col-md-4 field-container\">\n                <div class=\"form-group required\">\n                    <label class=\"checkbox-inline\" for=\"DATACOLLECTIONENABLED\">\n                        <input type=\"checkbox\" data-hook=\"getDataCollectionEnabled\" id=\"DATACOLLECTIONENABLED\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dataCollectionEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":111},"end":{"line":139,"column":166}}})) != null ? stack1 : "")
    + " > "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ANALYTIC.DATACOLLECTIONENABLED.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":139,"column":169},"end":{"line":139,"column":218}}}))
    + "\n                    </label>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DATACOLLECTIONENABLED\"></span>\n                </div>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModify") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":8},"end":{"line":160,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</section>";
},"usePartial":true,"useData":true});