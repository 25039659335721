import ItemView from '@glu/core/src/itemView';
import userInfo from 'etc/userInfo';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import template from './item.hbs';

export default ItemView.extend({
    tagName: 'div',
    className: 'text-item form-container',
    template,
    loadingTemplate,

    ui: {
        $contact: '.phone',
        $defaultBlock: '.default-options',
    },

    loadRequiredData() {
        const self = this;
        self.setHasLoadedRequiredData(true);
        self.render();
    },

    templateHelpers() {
        const self = this;

        return {
            cid: this.cid,
            isModifyMode() {
                return self.modifyMode;
            },
        };
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            if (serverConfigParams.get('EnableInternationalPhoneNumbers') !== 'true') {
                this.ui.$contact.inputmask('useOnAndroid', userInfo.getPhoneFormat());
            }

            /*
             * if the editable state is change, rerender the page
             * This is changed when the user clicks the add another phone
             */
            self.listenTo(self.model, 'change:editable', self.render);
            self.listenTo(self.model, 'change:default', self.render);
            self.listenTo(self.model, 'change:contact', self.hideShowDefaultButton);
            if (self.model.get('contact').length === 0) {
                self.ui.$defaultBlock.hide();
            }
        } else {
            this.loadRequiredData();
        }
    },

    setDefault() {
        this.appBus.trigger('security:contact:default', this.model.cid);
    },

    delete() {
        const self = this;
        self.model.set('removeRecord', true);
        self.render();
    },

    hideShowDefaultButton() {
        const self = this;

        if (self.ui.$contact.inputmask('isComplete')) {
            self.ui.$defaultBlock.show();
        } else {
            self.ui.$defaultBlock.hide();
        }
    },
});
