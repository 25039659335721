import WorkspaceView from 'common/workspaces/views/workspace';
import DefaultView from 'app/cashflow/views/navigation';
import CashFlowForecastView from 'app/cashflow/chart/cashFlowChartLayout';
import CashFlowPlanningView from 'app/cashflow/planning/planningSurrogateLayout';
import GroupPlanView from 'app/cashflow/groupplan/groupSurrogateLayout';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import CashFlowCompareView from 'app/cashflow/compare/compareSurrogateLayout';
import StackView from 'common/stack/views/stackView';

export default {
    /**
     * Return the default cashflow workspace view.
     */
    cashFlowView() {
        this.showPage('', new DefaultView());
    },

    cashFlowWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'SMBCASHFLOW',
                    returnRoute: 'cashflow/default',
                }),
            }));
        }
    },

    cashFlowGroupPlanningView() {
        this.showPage('Cash Flow Group Planning', new GroupPlanView());
    },

    cashFlowPlanningView() {
        this.showPage('Cash Flow Planning', new CashFlowPlanningView());
    },

    cashFlowCompareView() {
        this.showPage('Cash Flow Plan Comparison', new CashFlowCompareView());
    },

    cashFlowForecastView() {
        this.showPage('Cash Flow Forecast', new CashFlowForecastView());
    },

    defaultView() {
        if (processDeeplink('PLANNING/cashFlowForecastChart', this)) {
            this.showPage('Planning', new DefaultView());
        }
    },
};
