var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <label class=\"static-label\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"scheduler.ends",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":63}}}))
    + "</label>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"static-label\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"scheduler.ends",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":59}}}))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"clearfix recurrence-summary "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"endDateRequired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":83}}})) != null ? stack1 : "")
    + "\">\n    <legend class=\"sr-only\">Ends</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"endDateRequired") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"radio\">\n        <input type=\"radio\" title=\"period-no-end\" id=\"period-infinite-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":70},"end":{"line":9,"column":77}}}) : helper)))
    + "\" value=\"noEnd\" name=\"ends-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":104},"end":{"line":9,"column":111}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":113},"end":{"line":9,"column":144}}})) != null ? stack1 : "")
    + " />\n        <label for=\"period-infinite-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":43}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.uponFurtherNotice",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":45},"end":{"line":10,"column":85}}}))
    + "</label>\n    </div>\n\n    <div class=\"radio has-text-input form-group\">\n        <input type=\"radio\" title=\"period-and-after-occurrences\" id=\"period-end-after-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":14,"column":86},"end":{"line":14,"column":93}}}) : helper)))
    + "\" name=\"ends-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":14,"column":106},"end":{"line":14,"column":113}}}) : helper)))
    + "\" value=\"occurrences\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":135},"end":{"line":14,"column":166}}})) != null ? stack1 : "")
    + " />\n        <label for=\"period-end-after-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":44}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.after",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":46},"end":{"line":15,"column":74}}}))
    + " <input type=\"text\" id=\"period-endafter-input-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":15,"column":120},"end":{"line":15,"column":127}}}) : helper)))
    + "\" class=\"form-control end-after-occurrences js-occurrences-input\" disabled/> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.occurrences",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":204},"end":{"line":15,"column":238}}}))
    + " </label>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"endsNumber\"></span>\n    </div>\n\n    <div class=\"radio js-end-by-selected-date\">\n        <input type=\"radio\" title=\"period-end-by-date\" id=\"period-end-by-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":20,"column":73},"end":{"line":20,"column":80}}}) : helper)))
    + "\" name=\"ends-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":20,"column":93},"end":{"line":20,"column":100}}}) : helper)))
    + "\" value=\"date\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":115},"end":{"line":20,"column":146}}})) != null ? stack1 : "")
    + " />\n        <label for=\"period-end-by-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":21,"column":34},"end":{"line":21,"column":41}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.endBySelectedDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":43},"end":{"line":21,"column":83}}}))
    + "</label>\n        <div class=\"js-region-scheduler-ends-end-by-date-picker\"></div>\n    </div>\n\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"ends\"></span>\n</fieldset>";
},"useData":true});