var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td ><button class=\"btn btn-tertiary\" data-attr=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"monthIndex") || (depth0 != null ? lookupProperty(depth0,"monthIndex") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"monthIndex","hash":{},"data":data,"loc":{"start":{"line":5,"column":49},"end":{"line":5,"column":63}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"formattedValue") || (depth0 != null ? lookupProperty(depth0,"formattedValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formattedValue","hash":{},"data":data,"loc":{"start":{"line":5,"column":65},"end":{"line":5,"column":83}}}) : helper)))
    + "</button></td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<th scope=\"row\">\n        <span title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":21},"end":{"line":2,"column":29}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":39}}}) : helper)))
    + "</span>\n</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"cells") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":6,"column":9}}})) != null ? stack1 : "")
    + "<th scope=\"row\" data-attr=\"sum\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"formattedSum") || (depth0 != null ? lookupProperty(depth0,"formattedSum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formattedSum","hash":{},"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":48}}}) : helper)))
    + "</th>\n";
},"useData":true});