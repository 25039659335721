var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp;\n            <span class=\"breadcrumb-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":84}}}))
    + "</span></a>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--breadcrumbs and title-->\n<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"getPageTitle") || (depth0 != null ? lookupProperty(depth0,"getPageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getPageTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":47}}}) : helper)))
    + "</h1>\n</div>\n\n<!--navigation and alert section-->\n<section class=\"section section-container\">\n    <div class=\"section-body\">\n\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n\n        <!--main section for tab content-->\n        <div data-region=\"pageContent\"></div>\n\n\n        <!--bottom button toolbar-->\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" class=\"btn btn-primary\" data-hook=\"save\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":94},"end":{"line":26,"column":118}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":80},"end":{"line":27,"column":118}}}))
    + "</button>\n        </div>\n    </div>\n</section>\n";
},"useData":true});