import constants from 'app/balanceAndTransaction/constants';
import ReportingView from 'app/balanceAndTransaction/views/reporting';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import services from 'services';
import CombinedAccountsCollectionView from './combinedAccountsCollection';
import AccountDetailsView from './previousDayAccountDetails';

const PreviousDayReporting = ReportingView.extend({
    id: 'showNewCustomReportTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showNewCustomReportLink',
    },

    initialize(options) {
        ReportingView.prototype.initialize.call(this, util.extend(
            options,
            {
                context: {
                    actionMode: constants.SELECT,
                    functionCode: constants.PRO,
                    productCode: constants.GIR,
                    typeCode: constants.BTR_TYPECODES.PREVIOUS.SUMMARY,
                },
                disableRealTime: true,
            },
        ));
    },

    getInquiryIdForAccountGroup() {
        return constants.INQUIRYID_BAL_ACCOUNTGROUP_PD;
    },

    getTotalsInquiryIdForAccountGroup() {
        return constants.TOTALS_INQUIRYID_BAL_ACCOUNTGROUP_PD;
    },

    getInquiryIdForAccount() {
        return constants.INQUIRYID_BAL_ACCOUNT_PD;
    },

    getTotalsInquiryIdForAccount() {
        return constants.TOTALS_INQUIRYID_BAL_ACCOUNT_PD;
    },

    getUngroupedTotalsServiceUrl() {
        return services.generateUrl(`${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedUngroupedAccountSummaryTotals_PD`);
    },

    getUngroupedAccountsServiceUrl() {
        return services.generateUrl(`${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedUngroupedAccounts_PD`);
    },

    getGroupedTotalsServiceUrl() {
        return services.generateUrl(`${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedAccountGroupTotals_PD`);
    },

    getGroupedAccountsServiceUrl() {
        return services.generateUrl(`${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedAccountSummaryForGroup_PD`);
    },

    getAccountTransactionServiceName() {
        return `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX_PD}getCombinedReportingTransactions`;
    },

    /* this is an extended method */
    showAccountTransactionView(accountModel, filterModel) {
        this.stack.push(new AccountDetailsView({
            model: accountModel,
            serviceName: this.getAccountTransactionServiceName(),
            filterModel,
            filterView: this.filterView,
            workspaceStack: this.options.workspaceStack,
        }));
    },

    /**
     * Extended method
     * @param {Collection} totalsAccountCollection
     * Creates a main Account Collection View used by the reporting page
     */
    createAccountsCollectionView(totalsAccountCollection) {
        const entitlements = store.get('btr:entitlements');

        // Move this out of setView Collection
        this.combinedCollectionView = new CombinedAccountsCollectionView({
            collection: totalsAccountCollection,
            filterModel: this.filterModel,
            filterView: this.filterView,
            canViewTransactions: entitlements
                .isEntitled(constants.BTR_TYPECODES.PREVIOUS.TRANSACTIONS),
            accountDetailServiceName: this.getAccountTransactionServiceName(),
            stack: this.options.workspaceStack,
        });
    },
});

export default PreviousDayReporting;
