var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                             <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":52}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":13,"column":67},"end":{"line":13,"column":76}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-message\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n</div>\n<form class=\"SimpleRTPWidget\" action=\"\">\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <div class=\"col-12\">\n                <div class=\"form-group required\">\n                    <label for=\"ACCOUNTFILTER\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.FromAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":47},"end":{"line":9,"column":75}}}))
    + "</label>\n                    <select id=\"ACCOUNTFILTER\" name=\"ACCOUNTFILTER\" class=\"form-control rtp-debit-account\" data-hook=\"fromAccount\" data-bind=\"model\">\n                        <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"debitAccountOptions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":14,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTFILTER\"></span>\n                </div>\n            </div>\n\n            <div class=\"col-12\">\n                <div class=\"form-group required\">\n                    <label for=\"BENE_NAME\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.Beneficiary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":71}}}))
    + "</label>\n                    <input type=\"hidden\" class=\"type-ahead form-control\" id=\"BENE_NAME\" name=\"BENE_NAME\" data-bind=\"model\">\n                    <div class=\"mdf-input-icon-container\">\n                        <button type=\"button\" class=\"icon-search lookup btn-tertiary btn\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.RTGSBEN1BKLOOKUP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":97},"end":{"line":25,"column":132}}}))
    + "\" data-hook=\"lookup-single\" data-field=\"BENE_NAME\"  name=\"BENE_NAME_LOOKUP\"></button>\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_NAME\"></span>\n                </div>\n            </div>\n            <div class=\"col-7\">\n                <button class=\"btn btn-tertiary rtp-remittanceBtn\" type=\"button\" name=\"button\" data-hook=\"getAddRemittanceBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.addRemittance",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":127},"end":{"line":31,"column":157}}}))
    + "</button>\n                <span data-hook=\"getRemittanceCheckMark\" class=\"icon-check hide rtp-remittanceEntered\"></span>\n                <div data-region=\"remittanceRegion\"></div>\n            </div>\n            <div class=\"col-5\">\n                <p class=\"viewDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":36},"end":{"line":36,"column":55}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"valueDate") || (depth0 != null ? lookupProperty(depth0,"valueDate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"valueDate","hash":{},"data":data,"loc":{"start":{"line":36,"column":56},"end":{"line":36,"column":69}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <div class=\"col-6\">\n            <div class=\"form-group amount-holder\">\n                <div class=\"input-group\">\n                    <input class=\"form-control rtp-amount\" type=\"text\" name=\"CREDIT_AMOUNT\" data-bind=\"model\" value=\"\" id=\"CREDIT_AMOUNT\" placeholder=\"0.00\">\n                    <div class=\"input-group-addon\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"creditCurrency") || (depth0 != null ? lookupProperty(depth0,"creditCurrency") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"creditCurrency","hash":{},"data":data,"loc":{"start":{"line":43,"column":51},"end":{"line":43,"column":69}}}) : helper)))
    + "</div>\n                </div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n            </div>\n        </div>\n    </div>\n</form>\n\n<div class=\"footer row right\">\n    <div class=\"col-12\">\n        <button type=\"button\" class=\"btn btn-primary\" data-hook=\"getSubmitButton\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":82},"end":{"line":53,"column":108}}}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});