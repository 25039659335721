var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\" role=\"presentation\">\n                    <a aria-controls=\"importResults\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        id=\"importResultsTab\"\n                        role=\"tab\"\n                        tabindex=\"-1\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.ImportedFiles",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":36},"end":{"line":28,"column":65}}}))
    + "\"\n                        aria-selected=\"false\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.ImportedFiles",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":53}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"menuitem.cm.iv.listView",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":67}}}))
    + "</h1>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <ul id=\"check-issue-mgmt-tablist\" class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            role=\"tablist\"\n            title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"menuitem.cm.iv.listView",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":19},"end":{"line":9,"column":55}}}))
    + "\">\n            <li class=\"NavTabs-item is-active\" role=\"presentation\">\n                <a aria-controls=\"issuesVoidsList\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"issuesVoidsListTab\"\n                    role=\"tab\"\n                    tabindex=\"0\"\n                    aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.CheckIssuesVoids",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":16,"column":64}}}))
    + "\"\n                    aria-selected=\"true\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.CheckIssuesVoids",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":52}}}))
    + "\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasImportResultsEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n        <div class=\"pageRegion checkIssueTabs\"></div>\n    </div>\n</div>\n";
},"useData":true});