import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';

import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import configuration from 'system/configuration';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import PaymentUtil from 'common/util/paymentUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import fxPaymentUtil from 'common/util/fxPaymentUtil';

import { dark } from 'common/util/featureUtil';
import rtgsCommon from './rtgsCommon';
import rtgsBeneInfoCommon from './rtgsBeneInfoCommon';
import paymentsRtgsIbanLookup from './paymentsRtgsIbanLookup';

const isMatch = (fieldOne, fieldTwo) => ((!util.isEmpty(fieldOne) && !util.isEmpty(fieldTwo))
        && (util.isEqual(fieldOne, fieldTwo)));

const isEEACountry = function (country) {
    const EEACountries = ['BE', 'BG', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LI', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'];
    return util.contains(EEACountries, country);
};

const isEEACurrency = function (currency) {
    const EEACurrencies = ['BGL', 'EUR', 'CZK', 'DKK', 'EEK', 'HUF', 'ISK', 'LVL', 'CHF', 'LTL', 'NOK', 'PLN', 'LEU', 'SEK', 'GBP'];
    return util.contains(EEACurrencies, currency);
};

/**
 * Depending on the value of DEFAULTCREDITCURRENCYFLAG,
 * CREDIT_CURRENCY will be defaulted by this listeners setup by this function.
 *
 * Valid values of DEFAULTCREDITCURRENCYFLAG are:
 * '0' -> 'Do not Default',
 * '1' -> 'Default based on beneficiary bank',
 * '2' -> 'Default to debit currency'
 *
 * In all three cases, if BENE_BANK_CURR_MAP changes by selection from contact center,
 * CREDIT_CURRENCY will be set to BENE_BANK_CURR_MAP.
 *
 * Otherwise, for free-form entry, if DEFAULTCREDITCURRENCYFLAG is:
 * '0' -> won't default to any currency.
 * '1' -> would default to BENE_BANK_CURR_MAP based on bene bank code.
 * '2' -> would default to DEBIT_CURRENCY based on debit account.
 *
 * If SUPPORTDEFINEDCURRENCYPAIRS is set to '1' (enabled), those would be validated before
 * setting the CREDIT_CURRENCY. Validation for case when DEFAULTCREDITCURRENCYFLAG is '2' is
 * found in src/common/uiWidgets/fxPaymentWidget/fxPaymentWidget.js file
 * inside updateAllowedCreditCurrenciesFromDependent function as supported currencies
 * need to be fetched based on the debit account changes.
 */
export function setupCreditCurrencyListeners(model, form) {
    let lockedFields = [];
    lockedFields = model.get('LOCKED_FIELDS').split(',');

    model.on('change:BENE_BANK_CURR_MAP', () => {
        const { CREDIT_CURRENCY: allCreditCurrencies } = form.formView.comboCollections;

        /*
         * store the bene bank default currency. Normally we wouldn't need this
         * information beyond this step, but for rare instances involving currency
         * pairs (NH-122886) we may need to reset the CREDIT_CURRENCY to this value
         *
         * BENE_BANK_CURR_MAP is populated from the mapped data defined for either the
         * beneficiary bank country drop down field or the contact center lookup modal.
         * The credit currency should only be defaulted to the mapped value when the UserGroup
         * wire product entitlements for defaulting the credit currency is enabled.
         */
        const creditCurrencyFromModel = model.get('BENE_BANK_CURR_MAP');
        const changedByContactCenter = !!model.get('BENEBOOK_ID');

        if (!changedByContactCenter) {
            switch (model.get('DEFAULTCREDITCURRENCYFLAG')) {
            case '0':
                if (!model.jsonData.beneBankCurrMap) {
                    model.set('CREDIT_CURRENCY', '');
                }
                break;
            case '1':
                if (!creditCurrencyFromModel ||
                    allCreditCurrencies.every(curr => curr.name !== creditCurrencyFromModel)) {
                    model.set('CREDIT_CURRENCY', '');
                    return;
                }
                if (applicationConfigParams.getValue('WIRES', 'SUPPORTDEFINEDCURRENCYPAIRS') === '1') {
                    const { allowedCreditCurrencies } = model.jsonData;
                    if (allowedCreditCurrencies.some(c => c.name === creditCurrencyFromModel)) {
                        model.set('CREDIT_CURRENCY', creditCurrencyFromModel);
                    } else {
                        model.trigger('invalidBeneBankDefaultCurrencySelected');
                    }
                } else {
                    model.set('CREDIT_CURRENCY', creditCurrencyFromModel);
                }
                break;
            case '2': {
                /**
                 * if case 2, while selecting the country
                 * manually credit currency should always depend on originator.
                 */
                const debitCurrencyVal = model.get('DEBIT_CURRENCY');
                if (!creditCurrencyFromModel ||
                    (allCreditCurrencies.length &&
                    allCreditCurrencies.every(curr => curr.name !== debitCurrencyVal))) {
                    model.set('CREDIT_CURRENCY', '');
                    return;
                }
                if (applicationConfigParams.getValue('WIRES', 'SUPPORTDEFINEDCURRENCYPAIRS') === '1') {
                    const { allowedCreditCurrencies } = model.jsonData;
                    if (allowedCreditCurrencies.some(curr => curr.name === debitCurrencyVal)) {
                        model.set('CREDIT_CURRENCY', debitCurrencyVal);
                    } else {
                        model.set('CREDIT_CURRENCY', '');
                    }
                } else {
                    model.set('CREDIT_CURRENCY', debitCurrencyVal);
                }
                break;
            }
            default:
                model.set('CREDIT_CURRENCY', '');
                break;
            }
            // eslint-disable-next-line no-param-reassign
            model.jsonData.beneBankCurrMap = '';
            return;
        }

        // eslint-disable-next-line no-param-reassign
        model.jsonData.beneBankCurrMap = creditCurrencyFromModel;
        /*
         * NH-164158 - When the "currency pairs" configuration is enabled for wire intl
         * payments, this workflow is dependent upon the CREDIT_CURRENCY being amongst the
         * allowed currencies for the specific DEBIT_BANK_CODE and DEBIT_CURRENCY
         * combination
         */
        if (applicationConfigParams.getValue('WIRES', 'SUPPORTDEFINEDCURRENCYPAIRS') === '1') {
            const { allowedCreditCurrencies } = model.jsonData;
            if (allowedCreditCurrencies.some(curr => curr.name === creditCurrencyFromModel)) {
                // the currency is an allowed one, set it like normal
                model.set('CREDIT_CURRENCY', creditCurrencyFromModel);
            } else {
                /*
                 * let the fxPaymentWidget know that a bene bank with an unsupported
                 * default currency was selected
                 */
                model.trigger('invalidBeneBankDefaultCurrencySelected');
            }
        } else {
            model.set('CREDIT_CURRENCY', creditCurrencyFromModel);
        }
    });

    if (model.get('DEFAULTCREDITCURRENCYFLAG') === '2' && !lockedFields.includes('CREDIT_AMOUNT')) {
        model.on('change:ACCOUNTFILTER', () => {
            const { CREDIT_CURRENCY: allCreditCurrencies } = form.formView.comboCollections;

            const debitCurrency = model.get('DEBIT_CURRENCY');
            if (applicationConfigParams.getValue('WIRES', 'SUPPORTDEFINEDCURRENCYPAIRS') !== '1' && !model.jsonData.beneBankCurrMap) {
                model.set('CREDIT_CURRENCY', allCreditCurrencies.some(curr => curr.name === debitCurrency) ? debitCurrency : '');
            }
        });
    }
}

export default function (form, initialState) {
    const { model } = form.formView;
    const { functionCode } = model.jsonData.typeInfo;

    const $beneBankIdLookup = form.formView.$('[name="BENE_BANK_ID_LOOKUP"]');
    const addIntAddress = form.field('INTERMEDIARY_CITY_LINK');
    const addIntBank = form.field('ADDINTBANK');
    const addressAddMoreList = ['INTERMEDIARY_'];
    const allowBeneNotificationEmail = model.get('ALLOWBENENOTIFICATIONSWIRES') || ((model.fieldData.ALLOWBENENOTIFICATIONSWIRES) ? model.fieldData.ALLOWBENENOTIFICATIONSWIRES.value : 0);
    const allowByOrderOf = model.get('ALLOWBYORDEROF');
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;
    const allowPreAdviseWires = model.get('RTGSALLOWPREADVISEWIRES');
    const beneAddress1 = form.field('BENE_ADDRESS_1');
    const beneAddress2 = form.field('BENE_ADDRESS_2');
    const beneBankAcctNumberFreeForm = form.field('BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD');
    const beneBankAddress1FreeForm = form.field('BENE_BANK_ADDRESS_1_VIRTUALFIELD');
    const beneBankCountry = form.field('BENE_BANK_COUNTRY');
    const beneBankIdLookup = form.field('BENE_BANK_ID');
    const beneBankName = form.field('BENE_BANK_NAME');
    const beneBankType = form.field('BENE_BANK_TYPE');
    const beneCity = form.field('BENE_CITY');
    const beneCountry = form.field('BENE_COUNTRY');
    const conIntlLastCancelTime = form.field('CONINTLWIRELASTCANCELTIME');
    const correspondentbank = ['CORRESPONDENT_TYPE', 'CORRESPONDENT_ID'];
    const correspondentId = form.field('CORRESPONDENT_ID');
    const creditAmount = form.field('CREDIT_AMOUNT');
    const creditCurrency = form.field('CREDIT_CURRENCY');
    const customerReference = form.field('CUSTOMER_REFERENCE');
    const debitAmount = form.field('DEBIT_AMOUNT');
    const debitCurrency = form.field('DEBIT_CURRENCY');
    const FREEFORM = 'FREEFORM';
    const hasOrderingPartyLookupConfig = orderingPartyUtil.isEntitled(model.get('ALLOWBYORDEROF'));
    const interBankAccountNumber = form.field('INTER_BANK_ACCOUNT_NUMBER');
    const interBankAddress1 = form.field('INTERMEDIARY_ADDRESS_1');
    const interBankAddress2 = form.field('INTERMEDIARY_ADDRESS_2');
    const interBankAddress3 = form.field('INTERMEDIARY_CITY');
    const interBankCity = form.field('INTERMEDIARY_CITY');
    const interBankName = form.field('INTERMEDIARY_NAME');
    const interBankState = form.field('INTERMEDIARY_STATE');
    const interId = form.field('INTERMEDIARY_ID');
    const interType = form.field('INTERMEDIARY_TYPE');
    const LOOKUP = 'LOOKUP';
    const originatorAddrPrefix = 'ORIGINATOR_';
    const releaseLeadTime = form.field('RELEASELEADTIME');
    const requireCityCountryRule = applicationConfigParams.getValue('WIRES', 'REQUIRECITYCOUNTRYINTL') === '1';
    const structuredAddressRule = applicationConfigParams.getValue('PAYMENTS', 'STRUCTUREDADDRESSVALIDATION') === '1';
    let balanceSelector = null;
    let beneBankAccountNumberValidator;

    /*
     * The BENEBANKIDENTRYMETHOD field will only exist if the Database is configured.  The
     * RTGSTYPEPREFERENCES table must have Allowfreeformbenebank = 1 for RTGS-INTL-*
     * When the BENEBANKIDENTRYMETHOD is not enabled, only Bene Bank LOOKUP is available
     * (not FREEFORM).
     */
    const beneBankIdEntryMethod = form.field('BENEBANKIDENTRYMETHOD');

    const beneBankArr = [
        'BENE_BANK_NAME',
        'BENE_BANK_ADDRESS_1',
        'BENE_BANK_ADDRESS_2',
        'BENE_BANK_CITY',
        'BENE_BANK_STATE',
    ];

    const beneBankReadOnly = [
        'BENE_BANK_NAME',
        'BENE_BANK_ADDRESS_1_VIRTUALFIELD',
        'BENE_BANK_ADDRESS_2_VIRTUALFIELD',
        'BENE_BANK_CITY_VIRTUALFIELD',
    ];

    const changeableFields = [
        'VALUE_DATE',
        'CREDIT_AMOUNT',
        'CREDIT_CURRENCY',
        'CUSTOMER_REFERENCE',
        'SPECIAL_INSTRUCTIONS',
        'OBI_TEXT_1',
        'OBI_TEXT_2',
        'OBI_TEXT_3',
        'OBI_TEXT_4',
        'INSTRUCTIONS_TO_BENE_BANK_1',
        'INSTRUCTIONS_TO_BENE_BANK_2',
        'INSTRUCTIONS_TO_BENE_BANK_3',
        'INSTRUCTIONS_TO_BENE_BANK_4',
        'INSTRUCTIONS_TO_BENE_BANK_5',
        'INSTRUCTIONS_TO_BENE_BANK_6',
        'EXCHANGE_RATE',
        'EXCHANGE_RATE_CONTRACTID',
        'CREDIT_DEBIT_RADIO_BTN',
        'CREDIT_DEBIT_ENTERED',
        'DEBIT_AMOUNT',
        'RELEASELEADTIME',
        'BENE_NOTIFICATION_EMAIL',
        'BENE_NOTIFICATION_EMAIL2',
        'BENE_NOTIFICATION_EMAIL3',
        'BENE_NOTIFICATION_EMAIL4',
        'BENE_NOTIFICATION_EMAIL5',
        'PREADVISEWIRES',
        'BENE_ACCOUNT_MASK_TOGGLE',
    ];

    const debitAcctReadOnly = [
        'DEBIT_ACCOUNT_BANK_NAME',
        'DEBIT_ACCOUNT_TITLE',
        'DEBIT_BANKNAME',
        'DEBIT_BANK_CODE',
    ];

    const intBankAddrArray = [
        'INTERMEDIARY_NAME',
        'INTERMEDIARY_ADDRESS_1',
        'INTERMEDIARY_ADDRESS_2',
        'INTERMEDIARY_CITY',
        'INTERMEDIARY_STATE',
    ];

    const showHideReadOnly = function (array, controlField) {
        util.each(array, (fieldName) => {
            form.field(fieldName)
                .shouldBeReadOnly(true)
                .shouldBeVisibleWhen(form.field(fieldName).isNotEmpty()
                        && form.field(controlField).isNotEmpty());
        });
    };

    const makeFieldsReadOnly = function (array, isReadOnly) {
        util.each(array, (value) => {
            form.field(value).shouldBeReadOnly(isReadOnly);
        });
    };

    const showHideAddMore = function (array) {
        const isInterBankFreeform = model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM';
        util.each(array, (fieldPrefix) => {
            const address2 = `${fieldPrefix}ADDRESS_2`;
            const address2Link = `${fieldPrefix}ADDRESS_2_LINK`;
            const city = `${fieldPrefix}CITY`;
            const cityLink = `${fieldPrefix}CITY_LINK`;
            const isCityEmpty = util.isEmpty(model.get(city));

            form.field(address2)
                .shouldBeVisibleWhen(!util.isEmpty(model.get(address2))
                        || form.field(city).isNotEmpty() || (model.fieldData[address2]
                        && model.fieldData[address2].mandatory));
            form.field(address2Link)
                .shouldBeVisibleWhen(form.field(address2).isNotVisible()
                    && util.isEmpty(model.get(address2)))
                .$el.on('click', (e) => {
                    e.stopImmediatePropagation();
                    form.field(address2).shouldBeVisible();
                    form.field(address2Link).shouldBeHidden();
                    form.field(cityLink).shouldBeVisibleWhen(isCityEmpty);
                });

            form.field(city)
                .shouldBeVisibleWhen((!isCityEmpty
                        && form.field(address2).isVisible()) || (model.fieldData[city]
                        && model.fieldData[city].mandatory));

            form.field(cityLink)
                .shouldBeVisibleWhen(isInterBankFreeform && isCityEmpty
                    && form.field(city).isNotVisible())
                .$el.on('click', (e) => {
                    e.stopImmediatePropagation();
                    form.field(city).shouldBeVisible();
                    form.field(cityLink).shouldBeHidden();
                });
        });
    };

    const getFieldValue = function (fieldName) {
        return util.findWhere(
            model.jsonData.fieldInfoList,
            {
                name: fieldName,
            },
        ).value;
    };

    const setChargesForEEA = function () {
        // EEA Rules
        const debitCountry = getFieldValue('DEBIT_COUNTRY');
        if (isEEACountry(debitCountry)) {
            if (isEEACountry(beneBankCountry.getValue())
                && isEEACurrency(creditCurrency.getValue())) {
                if (isMatch(creditCurrency.getValue(), debitCurrency.getValue())) {
                    model.set('DETAILS_OF_CHARGES', 'SHA');
                    form.field('DETAILS_OF_CHARGES').shouldBeReadOnly(true);
                } else {
                    form.field('DETAILS_OF_CHARGES').shouldBeReadOnly(false);
                }
            } else {
                form.field('DETAILS_OF_CHARGES').shouldBeReadOnly(false);
            }
        }
    };

    /**
     * @method checkWhetherToBlankCreditCurrency
     *
     * The CREDIT_CURRENCY combo has a limited set of Currencies that can be
     * selected. When a different Beneficiary Bank Country is selected, the corresponding
     * currency may not be one of the available currencies in the CREDIT_CURRENCY combo.
     * When this is the case we need to clear the current credit currency that is presented
     * as selected in the Combo as it does not match to what's in the model.
     */
    const checkWhetherToBlankCreditCurrency = function () {
        const $creditCurrency = $('[name="CREDIT_CURRENCY"]');
        const formValue = form.field('CREDIT_CURRENCY').getValue();
        const value = model.get('CREDIT_CURRENCY');
        if (!value || form.field('CREDIT_CURRENCY').isEmpty() || $creditCurrency.length === 0) {
            return;
        }
        if (formValue !== value && $creditCurrency[0].tagName === 'SELECT' && $creditCurrency.find(`option[value="${value}"]`).length === 0) {
            form.field('CREDIT_CURRENCY').setValue('');
        }
    };

    /**
     * @param {Array} lockedFields - an array of all locked fields on this form's model
     *
     * @return {Boolean} - whether or not a specific field is editable
     */
    const isEditableCondition = function (fieldName, lockedFields) {
        if (model?.fieldData?.[fieldName]?.lockedByDefault === false
            && fieldName === 'ACCOUNTFILTER' && !lockedFields.includes(fieldName)
            // ACCOUNTFILTER is always locked for repetitive templates, entry method 2.
            && model.get('ENTRYMETHOD') !== '2') {
            return true;
        }

        return false;
    };

    /**
     * @param {Array} lockedFields - an array of all locked fields on this form's model
     */
    const protectFields = function (lockedFields) {
        Object.keys(form.fields || {}).forEach((fieldName) => {
            /*
             * preferred typeahead list fields have their own field helper where this is handled
             * at preferredListTypeAhead.js
             */
            if (model.fieldData[fieldName].fieldUIType === 'TYPEAHEAD_PREFERRED') {
                return;
            }
            if (changeableFields.indexOf(fieldName) === -1
                && !isEditableCondition(fieldName, lockedFields)) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
            const isFieldReadOnly = form.fields[fieldName].isReadOnly();
            const $lookup = form.fields[fieldName].$el.parent().find('.lookup');
            if (isFieldReadOnly && $lookup) {
                $lookup.addClass('hidden');
            }
        });
    };

    const getBeneficiaryChargesForCAConfigParam = function () {
        return serverConfigParams.get('BeneficiaryChargesForCA') === 'true';
    };

    const updateChargesToBEN = function () {
        const creditCurrencyValue = model.get('CREDIT_CURRENCY');
        const beneBankCountryValue = model.get('BENE_BANK_COUNTRY');
        if (creditCurrencyValue === 'CAD' && beneBankCountryValue === 'CA') {
            model.set('DETAILS_OF_CHARGES', 'BEN');
            form.field('DETAILS_OF_CHARGES').shouldBeReadOnly(true);
        }
    };

    const setDefaultCharges = function () {
        const hideCharges = model.fieldData.HideCharges.value;
        const defaultCharges = model.fieldData.DefaultCharge.value;
        const chargesCombo = form.field('DETAILS_OF_CHARGES');
        if (hideCharges && chargesCombo) {
            if (form.formView.state.toUpperCase() === 'INSERT'
                && util.isEmpty(chargesCombo.value) && util.isEmpty(model.get('DETAILS_OF_CHARGES'))) {
                model.set('DETAILS_OF_CHARGES', defaultCharges);
                if (getBeneficiaryChargesForCAConfigParam()) {
                    updateChargesToBEN();
                }
            }

            if (hideCharges === '1') {
                chargesCombo.shouldBeHidden();
                // Hide the charges field when in view mode also.
                form.formView.$('#DETAILS_OF_CHARGES').hide();
            } else if (hideCharges === '0') {
                chargesCombo.shouldBeVisible();
            }
        }
    };

    const beneBankIdEntryMethodChange = function () {
        const isFreeForm = beneBankIdEntryMethod.$el.length > 0 && model.get('BENEBANKIDENTRYMETHOD') === FREEFORM;
        const beneAccountNumberMandatory = model.fieldData.BENE_BANK_ACCOUNT_NUMBER
            ? (model.fieldData.BENE_BANK_ACCOUNT_NUMBER.mandatory && isFreeForm) : false;
        beneBankIdLookup.shouldBeVisibleWhen(!isFreeForm || form.formView.state === 'view')
            .shouldBeRequiredWhen(!isFreeForm)
            .$el.parent().toggleClass('required', !isFreeForm);
        beneBankAcctNumberFreeForm.shouldBeVisibleWhen(isFreeForm)
            .shouldBeRequiredWhen(beneAccountNumberMandatory)
            .$el.parent().toggleClass('required', beneAccountNumberMandatory);
        rtgsBeneInfoCommon.toggleBeneBankValidator(!isFreeForm, beneBankAccountNumberValidator, model, 'BENE_BANK_ACCOUNT_NUMBER');
        beneBankCountry.shouldBeVisibleWhen(isFreeForm).shouldBeRequiredWhen(isFreeForm).$el.parent().toggleClass('required', isFreeForm);
        beneBankName.shouldBeVisibleWhen(isFreeForm).shouldBeRequiredWhen(isFreeForm).$el.parent().toggleClass('required', isFreeForm);
        beneBankAddress1FreeForm.shouldBeVisibleWhen(isFreeForm).shouldBeRequiredWhen(isFreeForm).$el.parent().toggleClass('required', isFreeForm);
        rtgsBeneInfoCommon.showHideAddr(form, 'BENE_BANK_', isFreeForm, isFreeForm, true);
        if (correspondentId) {
            correspondentId.shouldBeRequiredWhen(model.fieldData.CORRESPONDENT_ID.mandatory && isFreeForm).$el.parent().toggleClass('required', model.fieldData.CORRESPONDENT_ID.mandatory && isFreeForm);
        }
    };

    /*
     * Handles the displaying/hiding of fields when the user changes the entry method for
     * the 2nd intermediary
     */
    const interBankIdEntrymethodChange = function () {
        const isInterBankFreeform = model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM';
        const isSecondaryBankNameAdd1Req = isInterBankFreeform && model.get('secIntBankNameAdd1Req') === '1';

        form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3`).removeClass('hide');

        if (isInterBankFreeform) {
            form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3_Address`).removeClass('hide');
        } else {
            form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3_Address`).addClass('hide');
        }

        if (isInterBankFreeform && !model.get('INTERMEDIARY_CITY') && interBankAddress3.isNotVisible()) {
            addIntAddress.shouldBeVisible();
        } else {
            addIntAddress.shouldBeHidden();
        }

        addIntBank.shouldBeHidden();
        interType.shouldBeVisibleWhen(!isInterBankFreeform);
        interBankState
            .shouldBeRequiredWhen(!isInterBankFreeform)
            .shouldBeVisibleWhen(!isInterBankFreeform)
            .$el.parent().toggleClass('required', !isInterBankFreeform);
        interId.shouldBeVisibleWhen(!isInterBankFreeform);
        interBankAddress1
            .shouldBeVisibleWhen(isInterBankFreeform)
            .shouldBeRequiredWhen(isSecondaryBankNameAdd1Req)
            .$el.parent().toggleClass('required', isSecondaryBankNameAdd1Req);
        interBankAddress2.shouldBeVisibleWhen(isInterBankFreeform
            && !util.isEmpty(model.get('INTERMEDIARY_ADDRESS_2')));
        interBankAddress3.shouldBeVisibleWhen(isInterBankFreeform
            && !util.isEmpty(model.get('INTERMEDIARY_CITY')));
        interBankAccountNumber
            .shouldBeVisibleWhen(isInterBankFreeform)
            .shouldBeRequiredWhen(model.fieldData.INTER_BANK_ACCOUNT_NUMBER
                && model.fieldData.INTER_BANK_ACCOUNT_NUMBER.mandatory
                && isInterBankFreeform);
        interBankName
            .shouldBeVisibleWhen(isInterBankFreeform)
            .shouldBeRequiredWhen(isSecondaryBankNameAdd1Req)
            .$el.parent().toggleClass('required', isSecondaryBankNameAdd1Req);
    };

    const beneAccountTypeOnChange = function () {
        // clean BENE_ACCOUNT value of any spaces
        const bicPlusIban = model.get('BENE_ACCOUNT').replace(/ /g, '');
        model.set('BENE_ACCOUNT', bicPlusIban);

        if (model.get('BENE_ACCOUNT_TYPE') === 'IBAN') {
            model.set('BENE_BANK_TYPE', 'SWIFT');
            model.set('BENEBANKIDENTRYMETHOD', 'LOOKUP');
            /*
             * locking bene bank account details will be dynamically handled by the RTGS
             * IBAN lookup function
             */
            paymentsRtgsIbanLookup(form, false, true);
        } else {
            form.formView.$('[name="BENE_ACCOUNT"]').parent()
                .removeClass('has-error').removeClass('has-warning');
            form.formView.$('[data-validate="BENE_ACCOUNT"]').text('');
            beneBankType.shouldBeReadOnly(false);
            if (beneBankIdEntryMethod) {
                beneBankType.shouldBeReadOnly(false);
                beneBankIdEntryMethod.shouldBeReadOnly(false);
            }
            beneBankIdLookup.shouldBeReadOnly(false);
            $beneBankIdLookup.removeClass('hide');
        }
    };

    /**
     * toggleCityCountryMandatory
     * @param {bool} ruleActive - indicates whether or not the require city/country rule is
     * active
     */
    const toggleCityCountryMandatory = (ruleActive = false) => {
        const hasAddress = ruleActive || !!(model.get('BENE_ADDRESS_1') || model.get('BENE_ADDRESS_2'));
        beneCity.shouldBeRequiredWhen(hasAddress).$el.parent().toggleClass('required', hasAddress);
        beneCountry.shouldBeRequiredWhen(hasAddress).$el.parent().toggleClass('required', hasAddress);
    };

    const checkBeneNameLength = (modelToCheck) => {
        const nameParam = serverConfigParams.get('FormConfigBeneNameAddr2Limit');
        const nameLimit = nameParam === 'true' ? 33 : (Number.parseInt(nameParam, 10) || false);

        const nameLength = modelToCheck.get('BENE_NAME').length;
        if (nameLimit && nameLength > nameLimit) {
            beneAddress2.$el.val('').attr('disabled', true);
            modelToCheck.set('BENE_ADDRESS_2', '');
        } else {
            beneAddress2.$el.attr('disabled', false);
        }
    };

    addIntAddress.$el.on('click', (e) => {
        e.stopImmediatePropagation();
        if (interBankAddress2.isVisible()) {
            interBankCity.shouldBeVisible();
            addIntAddress.shouldBeHidden();
        } else {
            interBankAddress2.shouldBeVisible();
        }
    });

    showHideReadOnly(debitAcctReadOnly, 'DEBIT_ACCOUNT_NUMBER');
    showHideAddMore(addressAddMoreList);

    if (model.get('INTERMEDIARY_MAPPED') === 'Y') {
        makeFieldsReadOnly(correspondentbank, true);
    }

    addIntBank.$el.on('click', (e) => {
        e.stopImmediatePropagation();
        addIntBank.shouldBeHidden();
        interBankAccountNumber.shouldBeHidden();
        interBankName.shouldBeHidden();
        interBankAddress1.shouldBeHidden();
        interBankAddress2.shouldBeHidden();
        interBankCity.shouldBeHidden();
        interId.shouldBeVisible();
        form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3`).removeClass('hide');
    });

    if (model.get('INTER_BANK_ACCOUNT_NUMBER') || model.get('INTERMEDIARY_NAME') || model.get('INTERMEDIARY_ADDRESS_1')) {
        form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3`).removeClass('hide');
        interBankIdEntrymethodChange();
    }

    if (model.get('BENE_NAME')) {
        checkBeneNameLength(model);
    }

    // Cross-Currency Support
    if (initialState) {
        let lockedFields = [];
        lockedFields = model.get('LOCKED_FIELDS').split(',');

        $('#CONINTLWIRELASTCANCELTIME').hide();
        conIntlLastCancelTime.shouldBeHidden();

        if (util.isEmpty(model.get('BENEBANKIDENTRYMETHOD'))) {
            model.set('BENEBANKIDENTRYMETHOD', 'LOOKUP');
        }

        if (util.isEmpty(model.get('INTERBANKIDENTRYMETHOD'))) {
            model.set('INTERBANKIDENTRYMETHOD', 'LOOKUP');
        }

        const hasOrderingPartyLink = PaymentUtil.hasOrderingPartyLink(model);
        if (hasOrderingPartyLink) {
            PaymentUtil.populateOrderingPartyMap(model);
        }

        // only create listeners when contact center link already exists or link could exist
        if (hasOrderingPartyLink || hasOrderingPartyLookupConfig) {
            PaymentUtil.onChangeOrderingPartyFreeForm(model);
        }

        model.on('change:ORDEROF', () => {
            PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');

            if (form.formView.state === 'insert' && model.get('ORDEROF') === '1') {
                $('#ORDEROF_ID_TYPE').val('ACCT').change();
            }
        });

        // set up for ORDEROF_ID_TYPE/ORDEROF_ACCOUNT
        PaymentUtil.orderOfInitialState(form, model);

        model.on('change:ORDEROF_COUNTRY', () => {
            PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');
        });

        model.on('change:ORDEROF_ADDRESS_2', () => {
            PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');
        });

        model.on('change:ORDEROF_STATE', () => {
            model.set(
                'ORDEROF_STATE',
                model.get('ORDEROF_STATE').toUpperCase(),
                {
                    silent: true,
                },
            );
        });

        /*
         * If the feature does not exist or is not enabled we will hide the checkbox
         * in all cases.
         * If pre-advise wires is not enabled or not entitled we will hide the checkbox
         * when it is not-view mode and when it is view mode but not selected.
         */
        if (!dark.exists('PREADVISEWIRES') || dark.isDark('PREADVISEWIRES')
            || (allowPreAdviseWires !== '1'
                && (form.formView.state.toUpperCase() !== 'VIEW' || model.get('PREADVISEWIRES') !== '1'))) {
            model.set('PREADVISEWIRES', '');
            form.field('PREADVISEWIRES').shouldBeHidden().shouldBeReadOnly(true);
        }
        // Assure that the checked status is consistent with the loaded data on modify and copy
        $('#PREADVISEWIRES').prop('checked', model.get('PREADVISEWIRES') === '1');
        // Assure recurrence section is hidden when pre-advise is selected on modify and copy
        if (model.get('PREADVISEWIRES') === '1') {
            form.formView.trigger('schedulePayWidget:hideSchedule', true);
        }

        if (allowBeneNotificationEmail !== '1') {
            model.set({
                BENE_NOTIFICATION_EMAIL: '',
                BENE_NOTIFICATION_EMAIL2: '',
                BENE_NOTIFICATION_EMAIL3: '',
                BENE_NOTIFICATION_EMAIL4: '',
                BENE_NOTIFICATION_EMAIL5: '',
            });
            $('[data-widget-field="BENE_NOTIFICATION_EMAIL"]').hide();
        }

        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            form.formView,
            model,
            null,
            functionCode,
        );

        /*
         * The Payment Cancellation Time should be displayed only for consumer international
         * wire payments in view/modify
         * mode and if the payment status is in AP/RT/TR
         */
        if (model.get('TYPE') === 'INTL') {
            const formState = form.formView.state.toUpperCase();
            const validStatus = ['AP', 'RT', 'TR'];
            const status = model.get('STATUS');
            // modify sets the name on the element
            if ((formState === 'MODIFY' || formState === 'RESTORE') && validStatus.indexOf(status) > -1) {
                conIntlLastCancelTime
                    .shouldBeReadOnly(true)
                    .shouldBeVisibleWhen(conIntlLastCancelTime.isNotEmpty()
                        && validStatus.indexOf(status) > -1);
            }
            // view sets the id on the element
            if (formState === 'VIEW' && validStatus.indexOf(status) > -1
                && ($.trim($('#CONINTLWIRELASTCANCELTIME').html()) !== '')) {
                $('#CONINTLWIRELASTCANCELTIME').prop('readonly', true);
                $('#CONINTLWIRELASTCANCELTIME').show();
            }
        }
        releaseLeadTime.shouldBeHidden();

        model.on('change:maxAdvancedDays', (formModel, maxAdvancedDays) => {
            const showReleaseLeadTime = maxAdvancedDays > 0 && model.get('TYPE') === 'INTL';
            const { choices } = model.fieldData.RELEASELEADTIME;
            const state = form.formView.state.toUpperCase();
            const defaultForwardDays = parseInt(model.get('defaultForwardDays'), 10);
            const copyTmplFromTmpl = model.get('FUNCTION') === 'TMPL' && model.get('TARGETFUNCTION') === 'TMPL';
            const copyPayFromTmpl = model.get('FUNCTION') === 'INST' && model.get('FROMTEMPLATE') === '1';

            /**
             * NH-176216: making sure releaseLeadTime visibility is set
             * before the first return from this function
             */
            releaseLeadTime.shouldBeVisibleWhen(showReleaseLeadTime);

            /*
             * View state enters on change just to set the release
             * lead time to the displayable value.
             */
            if (state === 'VIEW') {
                model.set('RELEASELEADTIME', parseInt(model.get('RELEASELEADTIME'), 10) + defaultForwardDays);
                return;
            }

            if (!copyPayFromTmpl && !copyTmplFromTmpl && ((state !== 'MODIFY' && state !== 'RESTORE') || !initialState)) {
                model.set('RELEASELEADTIME', '');
                releaseLeadTime.$el.select2();
            }

            /*
             * Reset options and repopulate since the options displayed depend on the
             * maxadvanceddays.
             */
            releaseLeadTime.$el.find('option').remove();

            if (showReleaseLeadTime) {
                const displayedReleaseLeadTime = (state === 'INSERT'
                    || state === 'MODIFY'
                    || state === 'RESTORE')
                    && model.get('RELEASELEADTIME') ? model.get('RELEASELEADTIME') : '0';

                util.each(util.reject(util.sortBy(choices, 'name'), choice => choice.name > maxAdvancedDays), (choice) => {
                    const displayChoice = parseInt(choice.name, 10) + defaultForwardDays;
                    releaseLeadTime.$el.append(`<option value="${choice.name}" data-item="">${displayChoice}</option>`);
                });
                releaseLeadTime.$el.select2('val', displayedReleaseLeadTime);

                if (state === 'INSERT') {
                    model.set(
                        'RELEASELEADTIME',
                        displayedReleaseLeadTime,
                        {
                            silent: true,
                        },
                    );
                }
            } else {
                // Get rid of the property when it is not used
                model.unset('RELEASELEADTIME');
            }
        });

        // TEMPORARY Fix: NH-33472
        form.field('UPDATEADDRESSBOOK').shouldBeHidden();
        // end NH-33472

        /*
         * once in this block, dont check if initialState is true/false, due to the
         * triggers that get
         * activated inside this block, when model attributes are set.  The initialState can
         * get updated to false
         * and code in this block may not execute if you are checking.  Check once to
         * get into this block and let the block
         * finish.  Everything in this block should execute on initialState === true.
         */

        // set beneAccountType to default 'Other'
        if (form.field('BENE_ACCOUNT_TYPE') && !(model.get('BENE_ACCOUNT_TYPE'))) {
            form.field('BENE_ACCOUNT_TYPE').setComboBoxValue('Other');
            model.set('BENE_ACCOUNT_TYPE', 'Other');
        }
        if (form.formView.state !== 'insert' && form.formView.state !== 'modify' && form.formView.state !== 'restore') {
            balanceSelector = 'div#DEBIT_ACCOUNT_NUMBER strong';
        }

        rtgsCommon.getAccountBalances(form.formView, 'DEBIT_ACCOUNT_NUMBER', 'RTGS', 'INTL', 'DEBIT_BANK_CODE', 'DEBIT_CURRENCY', balanceSelector);

        if (debitCurrency.getValue() !== creditCurrency.getValue()) {
            if (model.get('ENTERED_AMOUNT_FLAG') === 'C') {
                model.set('CREDIT_DEBIT_ENTERED', 'Credit');
            } else if (model.get('ENTERED_AMOUNT_FLAG') === 'D') {
                model.set('CREDIT_DEBIT_ENTERED', 'Debit');
            }
        }
        if (getBeneficiaryChargesForCAConfigParam() && (form.formView.state === 'modify' || form.formView.state === 'restore')) {
            const creditCurrencyValue = model.get('CREDIT_CURRENCY');
            const beneBankCountryValue = model.get('BENE_BANK_COUNTRY');
            const charges = model.get('DETAILS_OF_CHARGES');
            if (creditCurrencyValue === 'CAD' && beneBankCountryValue === 'CA' && charges === 'BEN') {
                form.field('DETAILS_OF_CHARGES').shouldBeReadOnly(true);
            }
        }
        if (form.formView.state === 'insert') {
            const beneAccountType = form.field('BENE_BANK_TYPE');
            if (util.isEmpty(beneAccountType.getValue())) {
                model.set('BENE_BANK_TYPE', 'SWIFT');
            }
        }

        if (form.formView.state === 'insert' || form.formView.state === 'modify' || form.formView.state === 'restore' || form.formView.state === 'repair') {
            beneBankAccountNumberValidator = model.validators.BENE_BANK_ACCOUNT_NUMBER;

            if (allowByOrderOf === '1' || model.fieldData.ALLOWBYORDEROF.value === '1') {
                /*
                 * In copy-as-template, checkbox is checked
                 * when 'ORDEROF'=0; had to manually set it
                 */
                $('#ORDEROF').prop('checked', model.get('ORDEROF') === '1');
                PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');
            } else {
                $('[class*=INTL_ONBEHALF_ORIGINATOR]').hide();
            }

            if (!util.isUndefined(model.get('BENEBANKIDENTRYMETHOD'))) {
                if (util.isEmpty(model.get('BENEBANKIDENTRYMETHOD'))) {
                    model.set('BENEBANKIDENTRYMETHOD', LOOKUP);
                }
            } else {
                rtgsBeneInfoCommon.toggleBeneBankValidator(true, beneBankAccountNumberValidator, model, 'BENE_BANK_ACCOUNT_NUMBER');
                showHideReadOnly(beneBankReadOnly, 'BENEBANKIDENTRYMETHOD');
            }
            beneBankIdEntryMethodChange();
            if (model.get('ENTRYMETHOD') !== '1' && form.formView.state !== 'repair') {
                beneAccountTypeOnChange();
            }
            if (form.formView.state === 'repair' && model.get('TYPE') === 'INTL' && model.get('FUNCTION') === 'INST') {
                form.formView.$('[name="ACCOUNTFILTER"]').prop('readonly', true).toggleClass('read-only-field', true);
                form.field('CUSTOMER_REFERENCE').setProperty('readonly', true);
            }
        } else if (form.formView.state === 'view') {
            $('#BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD').parent().addClass('hide');
            if (functionCode === 'INST') {
                form.formView.$('.RTGS_INST_INTL_BENEINFO_BENEFICIARY_BANK_ADDRESS_VIRTUALFIELD').parent().addClass('hide');
            } else {
                form.formView.$('.RTGS_TMPL_INTL_BENEINFO_BENEFICIARY_BANK_ADDRESS_VIRTUALFIELD').parent().addClass('hide');
            }
            $('#BENE_BANK_NAME').addClass('hide');
            $('#ORDEROF').text(locale.get('RTGS.screentext.Ordering Party')).css('font-weight', 'bold').css('width', '20%');
            /**
             * NH-44250
             * For FREEFORM, hide the bene bank id, because its value BLANK is used just
             * to leverage the same helptext widget code to display the bene info,
             * also set the value of BENE_BANK_COUNTRY to description to display in the helptext
             */
        }
        /*
         * coming into this form, if it is created from payment (entry method === 1),
         * then fields need to be protected.
         */
        if (model.get('ENTRYMETHOD') && (model.get('ENTRYMETHOD') === '1' || model.get('ENTRYMETHOD') === '2')) {
            protectFields(lockedFields);
        }
        if (configuration.isClient()) {
            setDefaultCharges();
        }

        model.on('change:INTERMEDIARY_ADDRESS_1', () => {
            interBankIdEntrymethodChange();
        });
        model.on('change:INTERMEDIARY_ADDRESS_2', () => {
            interBankIdEntrymethodChange();
        });
        model.on('change:INTERMEDIARY_CITY', () => {
            if (interBankAddress3) {
                addIntAddress.shouldBeHidden();
            }
            interBankIdEntrymethodChange();
        });

        model.on('change:CREDIT_CURRENCY', () => {
            setChargesForEEA();
            if (getBeneficiaryChargesForCAConfigParam()) {
                updateChargesToBEN();
            }
            /**
             * verify if currency derived from selected beneficiary bank country matches
             * one of the currencies in the credit currency combo
             */
            checkWhetherToBlankCreditCurrency();
        });

        model.on('change:BENE_BANK_COUNTRY', () => {
            if (getBeneficiaryChargesForCAConfigParam()) {
                updateChargesToBEN();
            }
            if (!fxPaymentUtil.USD_ONLY) {
                model.set({ CREDIT_AMOUNT: '', DEBIT_AMOUNT: '' });
            }
        });
        model.on('change:DEBIT_ACCOUNT_NUMBER', () => {
            /*
            * NH-172913 - When the user is set to  "Allow FX Payments to be made in USD only"
            * enabled for wire intl payments, the currency should default to USD
            */
            if (!fxPaymentUtil.USD_ONLY && model.jsonData.beneBankCurrMap) {
                model.set('CREDIT_CURRENCY', model.jsonData.beneBankCurrMap);
            }

            // if they're not locked, clear the debit and credit amounts
            if (!lockedFields.includes('DEBIT_AMOUNT') && !lockedFields.includes('CREDIT_AMOUNT')) {
                model.set({ CREDIT_AMOUNT: '', DEBIT_AMOUNT: '' });
            }

            rtgsBeneInfoCommon.showHideAddr(
                form,
                originatorAddrPrefix,
                (model.fieldData.ORIGINATOR_ID_TYPE.fieldUIType !== 'TEXTBOX'),
            );
        });
        setChargesForEEA();
        model.on('change:BENEBANKIDENTRYMETHOD', () => {
            let clearFields = true;
            if (!util.isEmpty(model.get('BENEBOOK_ID'))
                && ((model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM'
                    && (util.isUndefined(model.get('BENE_BANK_ID_MAP'))
                        || model.get('BENE_BANK_ID_MAP') === ''))
                    || (model.get('BENEBANKIDENTRYMETHOD') === 'LOOKUP'
                        && model.get('BENE_BANK_ID_MAP')))) {
                clearFields = false;
            }
            if (clearFields) {
                util.each(beneBankArr, (field) => {
                    model.set(field, '');
                });
                beneBankIdLookup.setValue('');
                model.set({
                    BENE_BANK_ID: '',
                    BENE_BANK_ACCOUNT_NUMBER: '',
                    BENE_BANK_ADDRESS_1_VIRTUALFIELD: '',
                    BENE_BANK_ADDRESS_2_VIRTUALFIELD: '',
                    BENE_BANK_CITY_VIRTUALFIELD: '',
                    BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD: '',
                    BENE_BANK_COUNTRY: '',
                });
                model.unset('BENEBOOK_ID');
                model.unset('BENEBOOKCHILD_ID');
            } else {
                // reset the helper text when the attributes are cleared.
                form.formView.trigger('lookupHelperText:update', 'BENE_BANK_ID');
            }
            beneBankIdEntryMethodChange();
        });

        model.on('change:BENE_BANK_ID', () => {
            if (util.isEmpty(model.get('BENE_BANK_ID'))) {
                // reset the helper text when the Bene Bank is cleared.
                form.formView.trigger('lookupHelperText:clear', 'BENE_BANK_ID');
            }
        });

        model.on('change:BENE_NAME', () => {
            checkBeneNameLength(model);
            model.set({ CREDIT_AMOUNT: '', DEBIT_AMOUNT: '' });
            debitAmount.setValue('');
        });

        model.on('change:INTERBANKIDENTRYMETHOD', () => {
            const clearFields = model.get('INTERBANKIDENTRYMETHOD') !== 'FREEFORM';
            if (clearFields) {
                util.each(intBankAddrArray, (field) => {
                    model.set(field, '');
                });
                interId.setValue('');
                model.set('INTERMEDIARY_ID', '');
                model.set('INTER_BANK_ACCOUNT_NUMBER', '');
                // reset the helper text when the attributes are cleared.
                form.formView.trigger('lookupHelperText:update', 'INTERMEDIARY_ID');
            }
            interBankIdEntrymethodChange();
        });

        if (!model.get('INTER_BANK_ACCOUNT_NUMBER') && !model.get('INTERMEDIARY_NAME') && !model.get('INTERMEDIARY_ADDRESS_1')) {
            form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3`).addClass('hide');
            form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3_Address`).addClass('hide');
            interBankAccountNumber.shouldBeOptional();
        } else {
            form.formView.$(`.RTGS_${functionCode}_INTL_ADDITIONAL_INFORMATION_INTBANKGROUP_3`).removeClass('hide');
            addIntBank.shouldBeHidden();
            interBankIdEntrymethodChange();
        }

        model.on('change:BENE_ACCOUNT_TYPE', () => {
            let isValid = false;
            $('#BENE_ACCOUNT_TYPE option').each((idx, el) => {
                if (el.value === model.get('BENE_ACCOUNT_TYPE')) {
                    isValid = true;
                    return false;
                }
                return undefined;
            });

            if (!util.isEmpty(model.get('BENE_ACCOUNT_TYPE')) && !isValid) {
                /*
                 * The server sometimes sends us an ACH account type, like DD or SV.
                 * For intl wires, these should be 'Other'.
                 */
                model.set({
                    BENE_ACCOUNT_TYPE: 'Other',
                }, {
                    silent: true,
                });
            }
            beneAccountTypeOnChange();
        });

        model.on('change:RELEASELEADTIME', function () {
            if (form.formView.state.toUpperCase() !== 'VIEW'
                && model.get('TYPE') === 'INTL' && model.get('FUNCTION') === 'INST') {
                fxFieldValidation.doFieldValidation(
                    this,
                    model,
                    'RELEASELEADTIME',
                    form.formView.state.toUpperCase(),
                ).then(() => { });
            }
        });

        model.on('change:PREADVISEWIRES', function () {
            if (allowPreAdviseWires === '1' && form.formView.state.toUpperCase() !== 'VIEW'
                && model.get('TYPE') === 'INTL' && configuration.isClient()) {
                if (model.get('FUNCTION') === 'INST') {
                    // Payment value date field validation for pre-advise holiday config
                    fxFieldValidation.doFieldValidation(
                        this,
                        model,
                        'PREADVISEWIRES',
                        form.formView.state.toUpperCase(),
                    ).then(() => { });
                } else if (model.get('FUNCTION') === 'TMPL') {
                    // Template recurrence validation for pre-advise payments
                    if (model.get('PREADVISEWIRES') === '1') {
                        /*
                         * When the recurrence section is selected, we present the user with
                         * the option to delete the schedule and hide the recurrence section
                         * or cancel (unchecking the pre-advise option) and keep the schedule.
                         */
                        if ($('#make-recur') && $('#make-recur').prop('checked')) {
                            dialog.confirm(locale.get('RTGS.PreAdviseWires.ScheduleWarn'), (ok) => {
                                if (ok) {
                                    form.formView.trigger('schedulePayWidget:hideSchedule', true);
                                } else {
                                    model.set('PREADVISEWIRES', '0', { silent: true });
                                    $('#PREADVISEWIRES').prop('checked', false);
                                }
                            });
                        } else {
                            // No template recurrence, hide the section without prompting
                            form.formView.trigger('schedulePayWidget:hideSchedule', true);
                        }
                    } else {
                        // No pre-advise, show the recurrence section
                        form.formView.trigger('schedulePayWidget:hideSchedule', false);
                    }
                }
            }
        });

        model.on('change:BENE_COUNTRY', () => {
            rtgsCommon.toggleStateProvince(
                model, model.get('BENE_COUNTRY'),
                $('input[name="BENE_PROVINCE"]').closest('.field-container'),
                $('select[name="BENE_STATE"]').closest('.field-container'),
            );
        });

        setupCreditCurrencyListeners(model, form);

        /*
         * on initial state, if bene state is populated, show that field, else show
         * province field
         */
        if (model.get('BENE_STATE')) {
            $('input[name="BENE_PROVINCE"]').closest('.field-container').hide();
        } else {
            $('select[name="BENE_STATE"]').closest('.field-container').hide();
        }

        // if create payment from template, trigger releaseLeadTime change
        if (model.get('RELEASELEADTIME') && model.get('FUNCTION') === 'INST'
            && model.get('FROMTEMPLATE') === '1'
            && form.formView.state.toUpperCase() !== 'VIEW'
            && model.get('TYPE') === 'INTL') {
            fxFieldValidation.doFieldValidation(
                form.formView,
                model,
                'RELEASELEADTIME',
                form.formView.state.toUpperCase(),
            ).then(() => { });
        }

        // if create payment from template, hide customer reference lock icon
        if (model.has('CUSTOMER_REFERENCE') && model.get('FUNCTION') === 'INST'
            && (model.get('FROMTEMPLATE') === '1' || model.context?.createdFrom === '1')
            && form.formView.state.toUpperCase() !== 'VIEW'
            && model.get('TYPE') === 'INTL') {
            customerReference.$el.first().closest('.field-container').children('.mdf-input-icon-container').find('[data-action="toggleLock"]')
                .addClass('hidden');
        }

        // if create template from template, remove amount validators
        if (model.get('FROMTEMPLATE') === '1'
            && form.formView.state.toUpperCase() !== 'VIEW'
            && model.get('FUNCTION') === 'TMPL'
            && model.get('TYPE') === 'INTL') {
            model.removeValidator('CREDIT_AMOUNT');
            model.removeValidator('DEBIT_AMOUNT');
        }

        /*
         * Trigger the change so the release lead time can be updated to the display
         * lead time.  The lead time is stored as 0, 1, 2 and is used by date validator
         * to increase the forward days.  The display version must show the total number
         * of forward days, including the release lead time.
         */
        if (model.get('TYPE') === 'INTL'
            && (model.get('FUNCTION') === 'TMPL' || model.get('FUNCTION') === 'INST')) {
            if ((form.formView.state.toUpperCase() === 'MODIFY'
                || form.formView.state.toUpperCase() === 'RESTORE')
                && model.fieldData.maxAdvancedDays
                && (model.get('maxAdvancedDays') !== model.fieldData.maxAdvancedDays.value)) {
                /*
                 * set these, but keep the change to maxAdvancedDays silent, as it will be
                 * triggered next
                 */
                model.set({
                    defaultForwardDays: model.fieldData.defaultForwardDays.value,
                    maxAdvancedDays: model.fieldData.maxAdvancedDays.value,
                }, {
                    silent: true,
                });
            }
        }
        if (model.get('RELEASELEADTIME') || (model.get('maxAdvancedDays') && model.get('maxAdvancedDays') !== '0')) {
            model.trigger('change:maxAdvancedDays', form.formView.model, model.get('maxAdvancedDays'));
        }

        if (form.formView.state === 'view') {
            if (model.get('INSTRUCTIONS_TO_BENE_BANK_1') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_2') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_3') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_4') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_5') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_6') === '') {
                if (functionCode === 'INST') {
                    form.formView.$('.RTGS_INST_INTL_ADDITIONAL_INFORMATION_BANKINSTRUCTIONSGROUP').parent().addClass('hide');
                } else {
                    form.formView.$('.RTGS_TMPL_INTL_ADDITIONAL_INFORMATION_BANKINSTRUCTIONSGROUP').parent().addClass('hide');
                }
            }
            if (model.get('OBI_TEXT_1') === '' && model.get('OBI_TEXT_2') === '' && model.get('OBI_TEXT_3') === '' && model.get('OBI_TEXT_4') === '') {
                if (functionCode === 'INST') {
                    form.formView.$('.RTGS_INST_INTL_ADDITIONAL_INFORMATION_PAYMENTDETAILSGROUP').parent().addClass('hide');
                } else {
                    form.formView.$('.RTGS_TMPL_INTL_ADDITIONAL_INFORMATION_PAYMENTDETAILSGROUP').parent().addClass('hide');
                }
            }
        }
        // make sure credit amounts are protected if they are locked in payment mode
        if (model.has('LOCKED_FIELDS') && form.formView.state !== 'view' && model.get('FUNCTION') === 'INST') {
            if (lockedFields.includes('DEBIT_AMOUNT')) {
                debitAmount.shouldBeReadOnly(true);
            } else if (lockedFields.includes('CREDIT_AMOUNT')) {
                creditAmount.shouldBeReadOnly(true);
            }
        }
        /*
         * if the application config to require city/country is active, then set them as
         * required. Otherwise, listen for changes to the address fields to determine if
         * the city/country fields are required
         */
        if (requireCityCountryRule) {
            toggleCityCountryMandatory(true);
        } else if (structuredAddressRule) {
            beneAddress1.$el.add(beneAddress2.$el).off('blur.toggleCityCountry change.toggleCityCountry')
                .on('blur.toggleCityCountry change.toggleCityCountry', () => toggleCityCountryMandatory());
            /*
             * if the form has address values at page load (modify,create from payment/template)
             * then determine if the city and country fields should be required
             */
            if (model.get('BENE_ADDRESS_1') || model.get('BENE_ADDRESS_2')) {
                toggleCityCountryMandatory();
            }
        }

        if (util.isUndefined(model.get('secIntBankNameAdd1Req')) && (form.formView.state.toUpperCase() === 'INSERT' || form.formView.state.toUpperCase() === 'MODIFY')) {
            rtgsCommon.isSecondaryBankNameAdd1Req(model.get('TYPE')).then((bankAddReq) => {
                model.set('secIntBankNameAdd1Req', bankAddReq);
            });
        }
    }

    if (!util.isUndefined(model.get('BENEBANKIDENTRYMETHOD')) && model.get('BENEBANKIDENTRYMETHOD') !== 'LOOKUP') {
        model.set({
            BENE_BANK_ACCOUNT_NUMBER: model.get('BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD'),
            BENE_BANK_ADDRESS_1: model.get('BENE_BANK_ADDRESS_1_VIRTUALFIELD'),
            BENE_BANK_ADDRESS_2: model.get('BENE_BANK_ADDRESS_2_VIRTUALFIELD'),
            BENE_BANK_CITY: model.get('BENE_BANK_CITY_VIRTUALFIELD'),
        });
        beneBankIdEntryMethodChange();
    }

    rtgsBeneInfoCommon.showHideAddr(form, originatorAddrPrefix, (model.fieldData.ORIGINATOR_ID_TYPE.fieldUIType !== 'TEXTBOX'));
    rtgsBeneInfoCommon.setupEmailFields(form);

    if (form.formView.state !== 'view') {
        if (fxPaymentUtil.USD_ONLY && model.get('ENTRYMETHOD') !== '2') {
            model.set({
                CREDIT_CURRENCY: 'USD',
                DEBIT_CURRENCY: 'USD',
            });
        }
    }
}

// exported for unit testing
export { isMatch, isEEACountry, isEEACurrency };
