import Backbone from 'backbone';
import Glu from './glu';
import util from './util';
import http from './http';
import validators from './core/internal/validators';
import locale from './locale';

Glu.buildModelOfType = function buildModelOfType(ModelType) {
  return ModelType.extend({
    extraAttributes: [],
    skipOnSend: [],
    // TODO the principals stuff (BSL-specific) should be moved out to ./bsl/principalsModel (which extends this model)
    principals: false,

    staticProperties: ['error', 'warning', 'information', 'debug'],

    constructor(options = {}) {
      ModelType.prototype.constructor.apply(this, Array.prototype.slice.call(arguments));

      if (this.principals) {
        this.purpose = options.purpose || 'cpay-auth';
        this.name = options.name || '';
        this.comment = options.comment || '';
        this.extensions = options.extensions || [];
        this.identifier = options.identifier || {
          providerReference: {
            providerKey: '',
            instanceId: ''
          },
          realmKey: '',
          key: ''
        };
      }
    },

    parse(resp) {
      if (util.isNullOrUndefined(resp)) {
        return resp;
      }
      const origin = util.extend({}, resp.model ? resp.model : resp);

      util.each(this.staticProperties, (propName) => {
        if (!resp[propName]) {
          return;
        }
        origin[propName] = resp[propName];
      });

      if (!this.principals) {
        return origin;
      }

      this.name = origin.name;
      this.comment = origin.comment;
      this.identifier = origin.identifier;
      this.extensions = origin.extensions;

      const result = origin.attributes || {};

      if (this[this.idAttribute]) {
        result[this.idAttribute] = this[this.idAttribute];
      } else {
        result.id = this.provideIdentifier(origin);
      }

      // convert boolean values from string to boolean
      util.each(result, (value, key) => {
        if (typeof value === 'string') {
          if (value === 'true') {
            result[key] = true;
          } else if (value === 'false') {
            result[key] = false;
          }
        }
      });

      util.each(this.extraAttributes, (property) => {
        result[property] = origin[property];
      });

      return result;
    },

    save(key, value, options) {
      this.unsetStatic();

      if (!key || typeof key === 'object') {
        options = value;
      }

      options = options || {};

      const { error } = options;

      options.error = (model, xhr) => {
        if (xhr.responseJSON && xhr.responseJSON.error) {
          this.set(this.parse(xhr.responseJSON));
        } else if (xhr.responseText) {
          this.set('error', {
            generic: [xhr.responseText]
          });
        }
        model.trigger('invalid');
        if (error) {
          error(this, xhr, options);
        }
      };

      return ModelType.prototype.save.apply(this, arguments);
    },

    unsetStatic() {
      util.each(this.staticProperties, msg => {
        this.unset(msg, {
          silent: true
        });
      });
    },

    fetch() {
      this.unsetStatic();
      return ModelType.prototype.fetch.apply(this, arguments);
    },

    toJSON() {
      const originJSON = ModelType.prototype.toJSON.apply(this, arguments);
      const origin = util.omit(originJSON, this.staticProperties);
      const result = {
        name: this.name,
        purpose: this.purpose,
        comment: this.comment,
        extensions: this.extensions,
        identifier: this.identifier
      };
      const obtainValue = function (value) {
        return value !== void 0 ? value : null;
      };

      if (!this.principals) {
        return origin;
      }

      util.each(origin, (value, key) => {
        if (typeof value === 'string') {
          if (value) {
            origin[key] = value.trim();
          } else {
            delete origin[key];
          }
        }
      });

      util.each(this.extraAttributes, (property) => {
        if (this.isNew() && property === this.idAttribute) {
          return;
        }

        result[property] = property ? obtainValue(origin[property]) : null;
      });

      result.attributes = util.omit(origin, this.extraAttributes);

      util.each(this.skipOnSend, (property) => {
        result.attributes[property] = null;
      });

      // TODO: JonM - Not sure why this line exists, will check with developer who added it
      delete result.attributes[this.idAttribute];

      return result;
    },

    provideIdentifier(json) {
      return json.name;
    },

    sync(method, model, options = {}) {
      util.defaults(options, {
        statusCode: http.statusCodeHandlers
      });

      return ModelType.prototype.sync.apply(this, arguments);
    },

    getValidationResultsByFieldName(name) {
      const validationResults = {};

      if (util.contains(util.keys(this.validators), name) && !util.result(this.validators[name], 'disabled', false)) {
        const validator = this.validators[name];
        const tests = util.omit(validator, 'description', 'warning', 'disabled');

        util.each(tests, (param, testName) => {
          // avoid: method = validators[testName]; method();
          // runs validator in global scope ('this' undefined)
          if (util.isFunction(param)) {
            param = param();
            validator[testName] = param;
          }

          const valid = (param === true) ? validators[testName](name, this) : validators[testName](name, param, this);
          const message = util.template(locale.get(`validator.${testName}`))(validator);

          if (!valid) {
            // TODO: this format does not work for all messages.
            if (validator.warning) {
              validationResults.warnings = validationResults.warnings || [];
              validationResults.warnings.push(message);
            } else {
              validationResults.errors = validationResults.errors || [];
              validationResults.errors.push(message);
            }
          }
        });
      }

      return validationResults;
    },

    validateField(attribute) {
      if (!this.validators) {
        return;
      }

      const results = this.getValidationResultsByFieldName(attribute);
      const warnings = this.get('warning') || {};
      const errors = this.get('error') || {};

      // remove errors and warnings for this field from the working copy
      delete errors[attribute];
      delete warnings[attribute];


      if (results.warnings) {
        warnings[attribute] = results.warnings;
      }

      if (results.errors) {
        errors[attribute] = results.errors;
      }

      if (util.isEmpty(warnings)) {
        this.unset('warning');
      } else {
        this.set('warning', warnings);
      }

      if (util.isEmpty(errors)) {
        this.unset('error');
      } else {
        this.set('error', errors);
      }

      if (results.errors) {
        this.trigger('invalid', {
          fieldValidation: true
        });
      }
    },

    validate(/* attributes, options */) {
      if (!this.validators) {
        return undefined;
      }

      this.trigger('validate');

      const warnings = {};
      const errors = {};
      let invalid = false;


      util.each(this.validators, (validator, key) => {
        const results = this.getValidationResultsByFieldName(key);

        if (results.warnings) {
          warnings[key] = results.warnings;
        }

        if (results.errors) {
          errors[key] = results.errors;
        }
      });


      if (util.isEmpty(warnings)) {
        this.unset('warning');
      } else {
        this.set('warning', warnings);
      }

      if (util.isEmpty(errors)) {
        this.unset('error');
      } else {
        invalid = true;
        this.set('error', errors);
      }


      return invalid ? 'invalid' : null;
    },
    /**
     * add a validator, or set of validators (if key is an array)
     * @param {(string|Object)} key - validator key or an object of validators
     * @param {string} [value] - value for new validator; optional when key is an array
     */
    addValidator(key, value) {
      if (util.isObject(key)) {
        // in case this.validators is undefined
        this.validators = util.extend({}, this.validators, key);
      } else if (this.validators) {
        this.validators[key] = value;
      } else {
        this.validators = {};
        this.validators[key] = value;
      }
    },

    /**
     * remove a validator, or set of validators (if key is an array)
     * @param
     * @param {(string|Object[])} key - validator key or an array of validator keys
     */
    removeValidator(key) {
      if (Array.isArray(key)) {
        key.map(validatorKey => (delete this.validators[validatorKey]));
      } else {
        delete this.validators[key];
      }
    }
  });
};

Glu.Model = Glu.buildModelOfType(Backbone.Model);

export default Glu.Model;

