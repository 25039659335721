import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import template from './emptyChart.hbs';

export default ItemView.extend({
    initialize(options) {
        if (options) {
            this.message = options.message || locale.get('common.no.data');
        } else {
            this.message = locale.get('common.no.data');
        }
    },
    template,
    templateHelpers() {
        return {
            message: this.message,
        };
    },
});
