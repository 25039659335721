import Model from '@glu/core/src/model';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import services from 'services';

export default Model.extend({
    idAttribute: 'ID',

    initialize(attributes, options) {
        this.validators = {
            NEW_NAME: {
                description: options.description,
                exists: true,
            },
        };
    },

    toServerJSON() {
        return {
            item: {
                item: [{
                    name: '_mode',
                    value: 'INSERT',
                }, {
                    name: '_actionMode',
                    value: 'INSERT',
                }, {
                    name: 'CONTACTTYPE',
                    value: '1',
                }, {
                    name: 'BENE_NAME',
                    value: this.get('NEW_NAME'),
                }, {
                    name: 'CONTACTTYPE_DESC',
                    value: 'Business',
                }, {
                    name: '_productCode',
                    value: '_ADMIN',
                }, {
                    name: '_typeCode',
                    value: 'BENEADBK',
                }, {
                    name: 'type',
                    value: 'BENEADBK',
                }, {
                    name: '_functionCode',
                    value: 'MAINT',
                }, {
                    name: 'functionCode',
                    value: 'MAINT',
                }, {
                    name: 'FUNCTION',
                    value: 'MAINT',
                }],
            },

            grids: [{
                name: 'BENEADDRESSBOOK_Grid',

                items: [

                ],
            }],
        };
    },

    parse(res) {
        // sometimes the success callback will fire but the JSON will be an error message
        let attrs = {};
        if (res && res.result) {
            // should return 'Name' and 'ID' values
            attrs = transform.pairsToHash(res.confirms.confirmResults[0].confirmData[0].item, 'name', 'value');
        } else if (res && !res.result && res.confirms) {
            attrs.error = {
                NEW_NAME: res.confirms.confirmResults[0].messages,
            };
        }

        return attrs;
    },

    sync(method, model, options) {
        let xhr;
        if (method === 'create') {
            xhr = http.post(services.generateUrl('/beneAddressBook/add'), this.toServerJSON(), options.success, options.error);
        }

        this.trigger('request', model, xhr, options);
        return xhr;
    },
});
