import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import { postData } from 'common/util/services';
import constants from 'common/dynamicPages/api/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import EntitlementsModel from 'common/dynamicPages/models/entitlements';
import locale from '@glu/locale';
import http from '@glu/core/src/http';

import Accounts from '../../transfers/collections/accounts';

const QuickTransferWidget = Model.extend({
    initialize() {
        this.debitAcctList = new Accounts({
            type: 'SINGLE',
        });

        this.beneAcctList = new Accounts({
            isToAccounts: true,
            type: 'SINGLE',
        });

        this.requestOptions = {
            ACCOUNTFILTER: {
                bankCodeKey: 'DEBIT_BANK_CODE',
                currencyKey: 'DEBIT_CURRENCY',
                accountNumberKey: 'DEBIT_ACCOUNT_NUMBER',
            },

            BENE_ACCOUNTENTITLEMENT: {
                bankCodeKey: 'BENE_BANK_CODE',
                currencyKey: 'CREDIT_CURRENCY',
                accountNumberKey: 'BENE_ACCOUNT',
            },
        };
    },

    /**
     * @name setIsTOA
     * @description sets the isTOA property which is used when fetching the 'to accounts' list
     * @param {boolean} isTOA
     */
    setIsTOA(isTOA = false) {
        this.isTOA = isTOA;
    },

    /**
     * getDates returns acceptable transfer dates.
     * @param {Object} data
     * @param {function} [successCb] - Success Callback
     * @param {function} [errorCb] - Error Callback
     * @returns {Promise}
     */
    getDates(data, successCb, errorCb) {
        let localData = data;
        localData = localData || {};
        const payload = {
            paymentType: 'TRANSFER',
            debitBank: localData.debitBank,
            creditCurrency: localData.creditCurrency,
            debitCurrency: localData.currency,
            debitBankCountry: localData.debitCountry,
            creditBankCountry: localData.creditBankCountry,
            beneBankId: localData.beneBankId,
            beneBankType: localData.beneBankType,
            tranDate: localData.tranDate,
            valueDate: localData.valueDate,
        };

        return postData(
            services.generateUrl('date/list'),
            payload,
            successCb,
            errorCb,
        );
    },

    /**
     * validate transfer data for the transfer date
     * @param {Object} data - request data
     * @param {function} [successCb] - Success Callback
     * @param {function} [errorCb] - Error Callback
     * @returns {Promise}
     */
    validateTransferData(data, successCb, errorCb) {
        return postData(
            services.generateUrl(constants.URL_DO_FIELD_VALIDATION),
            data,
            successCb,
            errorCb,
        );
    },

    /**
     * get entitlements
     * @param {Object} dataParam - request data
     * @param {function} [successCb] - Success Callback
     * @param {function} [errorCb] - Error Callback
     * @returns {Promise}
     */
    getEntitlements(dataParam = {}, successCb = () => {}, errorCb = () => {}) {
        const data = dataParam;
        data.options = data.options || {};
        data.options.context = data.options.context || {
            serviceName: '/payment/transfer',

            typeInfo: {
                productCode: 'RTGS',
                functionCode: 'INST',
                typeCode: 'TRANSFER',
            },
        };
        data.options.state = data.options.state || 'insert';

        return new Promise((resolve, reject) => {
            const entitlements = new EntitlementsModel({}, data.options);

            entitlements.fetch({
                success(model, response) {
                    const resp = {
                        model,
                        entitlements: response,
                    };
                    resolve(resp);
                    successCb(resp);
                },

                error() {
                    reject(new Error('Error fetching entitlements'));
                    errorCb(new Error('Error fetching entitlements'));
                },
            });
        });
    },

    /**
     * get "from" transfer accoutns
     * @param {Object} data - request data (data.filter = { page, term, accountFilter})
     * @param {function} [successCb] - Success Callback
     * @param {function} [errorCb] - Error Callback
     * @returns {Promise}
     */
    getFromAccounts(data = {}, successCb = () => {}, errorCb = () => {}) {
        return new Promise((resolve, reject) => {
            // make sure we set the appropriate collection filters, if necessary
            if (data.filter) {
                this.debitAcctList.setFilter(
                    data.filter.page,
                    data.filter.term,
                    data.filter.account,
                );
            }
            this.debitAcctList.fetch({
                data,
                remove: data.remove,

                success(collection, response) {
                    const resp = {
                        collection,
                        response,
                    };
                    resolve(resp);
                    successCb(resp);
                },

                error(err) {
                    reject(new Error(err));
                    errorCb(new Error(err));
                },
            });
        });
    },

    /**
     * get "to" transfer accounts ... almost exact to the above function
     * outside of coll used.
     * TODO: combine with above?
     * @param {Object} data - request data
     * @param {function} [successCb] - Success Callback
     * @param {function} [errorCb] - Error Callback
     * @returns {Promise}
     */
    getToAccounts(data = {}, successCb = () => {}, errorCb = () => {}) {
        return new Promise((resolve, reject) => {
            // make sure we set the appropriate collection filters, if necessary
            if (data.filter) {
                this.beneAcctList.setFilter(
                    data.filter.page,
                    data.filter.term,
                    data.filter.account,
                );
            }
            this.beneAcctList.isTOA = this.isTOA;

            this.beneAcctList.fetch({
                data,
                remove: data.remove,

                success(collection, response) {
                    const resp = {
                        collection,
                        response,
                    };
                    resolve(resp);
                    successCb(resp);
                },

                error(err) {
                    reject(new Error(err));
                    errorCb(new Error(err));
                },
            });
        });
    },

    /**
     * queue transfer
     * @param {Object} data - request data
     * @param {function} [successCb] - Success Callback
     * @param {function} [errorCb] - Error Callback
     * @returns {Promise}
     */
    transfer(data = {}, successCb = () => {}, errorCb = () => {}) {
        return new Promise((resolve, reject) => {
            data.model.save(
                {},
                {
                    success: (options, results) => {
                        const resp = {
                            options,
                            results,
                        };
                        successCb(resp);
                        resolve(resp);
                    },

                    error: (err) => {
                        errorCb(err);
                        reject(err);
                    },
                },
            );
        });
    },

    /**
     * @description retrieves the account balances for all the accounts
     * @param {array} accounts
     * @param {string} fieldName
     * @returns {Promise}
     */
    fetchBalancesForTheseAccounts(accounts, fieldName) {
        // post to the service and return array of balances
        const options = this.requestOptions[fieldName];
        const serviceUrl = services.currencyBalances;
        const displayAvailableBalances = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens');

        /**
         * Each item in the request must contain:
         * productCode, functionCode, typeCode, bankCode, accountNumber, currency
         *
         */
        const balanceRequest = accounts.map(item => ({
            productCode: 'RTGS',
            functionCode: 'INST',
            typeCode: 'TRANSFER',
            bankCode: item.mapDataList[options.bankCodeKey],
            accountNumber: item.mapDataList[options.accountNumberKey],
            currency: item.mapDataList[options.currencyKey],
        }));

        const payload = {
            requests: balanceRequest,
        };

        if (displayAvailableBalances) {
            return new Promise((resolve, reject) => {
                http.post(serviceUrl, payload, (data) => {
                    if (data && data.responses) {
                        resolve(data.responses);
                    } else {
                        resolve(accounts);
                    }
                }, () => {
                    reject(locale.get('common.combo.data.error', fieldName));
                });
            });
        }
        return Promise.resolve(accounts);
    },

    /**
     * @description fetches the balance of an account from the server
     * @param balanceRequest account info for which the balance is fetched.
     */
    getBalanceForAccount(balanceRequest) {
        return new Promise((resolve, reject) => {
            http.post(services.balanceAndTransaction, balanceRequest, (data) => {
                if (data && !util.isNullOrUndefined(data.balance)) {
                    resolve(data);
                } else {
                    reject((locale.get('payment.balance.none')));
                }
            }, () => {
                reject((locale.get('payment.balance.none')));
            });
        });
    },
});

export default new QuickTransferWidget();
