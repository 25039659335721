import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';

export default Collection.extend({
    initialize(models, options) {
        this.serviceName = options.context.serviceName;
        this.fieldName = options.fieldName;
        this.typeInfo = options.context.typeInfo;
        this.customFilters = options.customFilters || [];
    },

    sync(method, model, options) {
        if (method === 'read') {
            const readService = services.generateUrl(constants.URL_GETQUERYRESULTS_ACTION);
            const postData = {
                queryCriteria: {
                    fieldName: this.fieldName,

                    action: {
                        typeCode: this.typeInfo.typeCode,
                        productCode: this.typeInfo.productCode,
                        functionCode: this.typeInfo.functionCode,
                    },
                },
            };

            if (this.customFilters.length > 0) {
                postData.queryCriteria.customFilters = [];
                for (let i = 0; i < this.customFilters.length; i += 1) {
                    postData.queryCriteria.customFilters.push({
                        filterName: this.customFilters[i].filterName,
                        filterParam: this.customFilters[i].filterParam,
                    });
                }
            }

            http.post(readService, postData, (response) => {
                options.success(response.queryResponse.QueryData.queryRows);
            }, () => {});
        }
    },
});
