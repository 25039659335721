import Layout from '@glu/core/src/layout';
import Grid from '@glu/grid';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import toolTipCell from 'common/dynamicPages/views/toolTipCellView';
import Transform from 'common/util/transform';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import template from './beneUpdatedApprovalWarning.hbs';

export default Layout.extend({
    template,

    ui: {
        $paymentGridSection: '[data-hook="updated-bene-grid"]',
    },

    initialize(options) {
        this.isMultiApprove = options.isMultiApprove;
        this.modelsArray = options.modelsArray;
        this.grid = options.grid;
        this.serverResponse = options.resp;
        this.context = options.context;
        this.anyOrderingPartyUpdated = false;

        const paymentList = this.serverResponse.confirms.confirmResults.filter(confirmResult => (confirmResult.messages.length > 0 ? confirmResult.messages[0] === 'BENEUPDATEWARNING' : false));
        const orderingPartyMessageField = {
            name: 'ORDERINGPARTYMESSAGE',
            value: locale.get('PAY.orderingparty.contact'),
        };

        // Setup the grid to display payment data from the server response
        if (paymentList.length > 0) {
            const paymentColumns = [{
                label: locale.get('PAY.ID'),
                field: 'USERGROUPSEQUENCENUMBER',
            }, {
                label: locale.get('PAY.Beneficiary'),
                field: 'BENE_NAME',
                cellView: toolTipCell,
                tooltipCol: 'ORDERINGPARTYMESSAGE',
            }, {
                label: locale.get('PAY.PaymentType'),
                field: 'TYPEDESCRIPTION',
            }, {
                label: locale.get('PAY.ValueDate'),
                field: 'VALUE_DATE',
            }, {
                label: locale.get('PAY.lastUpdateTime'),
                field: 'LAST_ACTION_TIME',
            }, {
                label: locale.get('PAY.Status'),
                field: 'STATUS',
            }];

            if (orderingPartyUtil.isConfigured()) {
                paymentList.forEach((paymentItem) => {
                    const isOrderingPartyUpdated = paymentItem.additionalData[0].item.some(field => field.name === 'ORDERINGPARTYCONTACTUPDATED'
                        && (field.value === 'YES' || field.value === 'INVALID'));

                    if (isOrderingPartyUpdated) {
                        this.anyOrderingPartyUpdated = true;
                        paymentItem.additionalData[0].item.push(orderingPartyMessageField);
                    }
                });
            }

            const paymentGridData = new Collection(paymentList
                .map(paymentItem => Transform.pairsToHash(paymentItem.additionalData[0].item)));

            // initialize the payments grid
            this.paymentGrid = new Grid({
                collection: paymentGridData,
                columns: paymentColumns,
            });
        }
    },

    onRender() {
        if (this.paymentGrid) {
            this.ui.$paymentGridSection.append(this.paymentGrid.render().el);
        }
    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
        'data-backdrop': 'static',
    },

    save() {
        this.close();
        if (this.modelsArray) {
            this.modelsArray.forEach((item) => {
                const model = this.grid.wrapper.rows.get(item);
                model.set(constants.BENE_UPDATED_ACCEPTED_INDICATOR, 'true');
            });
            /**
             * For multi-approve we may need to handle other warning conditions
             * before running the actual approval, hence the DRYRUNONLY.
             */
            this.trigger('list:multi:action:action_approveWithWarning', this.modelsArray, {
                name: 'DRYRUNONLY',
                value: 'true',
            });
        } else if (this.grid) {
            this.model.set(constants.BENE_UPDATED_ACCEPTED_INDICATOR, 'true');
            this.grid.trigger(
                'rowAction',
                {
                    action: 'approve',
                    model: this.model,
                },
            );
        } else {
            this.model.trigger('modelAction:approveWithWarning', {
                warningName: constants.BENE_UPDATED_ACCEPTED_INDICATOR,
            });
        }
    },

    cancel() {
        if (this.serverResponse.confirms.chainedAction) {
            // Modify the confirm data to make it more presentable.
            if (this.serverResponse.message && this.serverResponse.message.length > 1) {
                this.serverResponse.message.length = 1;
            }

            // BENEUPDATEWARNING is never meant for the user. It is used internally
            if (this.serverResponse.confirms
                && this.serverResponse.confirms.confirmResults
                && this.serverResponse.confirms.confirmResults.length > 0
                && this.serverResponse.confirms.confirmResults[0].messages
                && this.serverResponse.confirms.confirmResults[0].messages[0] === 'BENEUPDATEWARNING') {
                this.serverResponse.confirms.confirmResults[0].messages = [];
            }

            /*
             * There is some odd handling of the WARNING resultType in
             * entry.setConfirmAndWarningMessage() that will suppress the
             * display of this confirmation data.
             */
            this.serverResponse.resultType = null;

            /*
             * Triggering success brings us back to the listview and displays the
             * confirmation data. The primary action, insert or modify, has succeeded
             * and there is nothing the user can do on the detail page to fix this.
             */
            this.model.trigger('modelAction:success', this.serverResponse);
        }
        this.close();
    },

    getModalTitle() {
        const isACH = this.model && this.model.get('PRODUCT') === 'USACH';
        const isRFP = this.model && this.model.get('PRODUCT') === 'RTP' && this.model.get('TYPE') === 'REQOUT';

        if (this.anyOrderingPartyUpdated) {
            return 'PAY.contactCenterUpdatedTitle';
        }
        if (this.isMultiApprove || isACH) {
            return 'PAY.achApproveBeneUpdatedTitle';
        }
        if (isRFP) {
            return 'PAY.rfpBeneUpdateTitle';
        }
        return 'PAY.rtgsApproveBeneUpdatedTitle';
    },

    getBodyMessage() {
        const isACH = this.model && this.model.get('PRODUCT') === 'USACH';
        const isRFP = this.model && this.model.get('PRODUCT') === 'RTP' && this.model.get('TYPE') === 'REQOUT';

        if (this.anyOrderingPartyUpdated) {
            return 'PAY.contactCenterUpdatedMessage';
        }
        if (this.isMultiApprove) {
            return 'PAY.multiApproveBeneUpdatedMessage';
        }
        if (isACH) {
            return 'PAY.achApproveBeneUpdatedMessage';
        }
        if (isRFP) {
            return 'PAY.rfpBeneDetailsUpdated';
        }
        return 'PAY.rtgsApproveBeneUpdatedMessage';
    },

    templateHelpers() {
        return {
            isACH: this.model && this.model.get('PRODUCT') === 'USACH',
            isRFP: this.model && this.model.get('PRODUCT') === 'RTP' && this.model.get('TYPE') === 'REQOUT',
            isMultiApprove: this.isMultiApprove,
            modalTitle: locale.get(this.getModalTitle()),
            bodyMessage: locale.get(this.getBodyMessage()),
        };
    },
});
