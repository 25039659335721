import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default Model.extend({
    initializeValidators() {
        this.validators = {
            id: {
                description: locale.get('PS.panelApproval.Approver.Name'),
                exists: true,
            },
        };
    },

    defaults: {
        id: null,
    },
});
