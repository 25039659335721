import workspaceHelper from 'common/workspaces/api/helper';
import PaymentAndEmployeeMainView from 'app/smbPayments/views/paymentAndEmployeeMainView';
import EmployeePaymentGrid from 'app/smbPayments/views/employees/grid/employeePaymentGrid';
import EmployeeTemplateGrid from 'app/smbPayments/views/employees/grid/employeeTemplateGrid';
import tmpl from './employeeView.hbs';

const employeeView = PaymentAndEmployeeMainView.extend({
    template: tmpl,

    gridData: {
        paymentOptions: {
            context: 'SMB_EMPLOYEE',
            serviceName: 'payment/listView/smbEmployee',
            isPaymentView: true,
            gridView: EmployeePaymentGrid,
        },
        templateOptions: {
            context: 'SMB_TEMP_EMPLOYEE',
            serviceName: 'template/listView/smbEmployee',
            isPaymentView: false,
            gridView: EmployeeTemplateGrid,
        },
    },

    initialize() {
        const options = {
            paymentTypeList: ['BDACHCSP', 'BDACHP'],
            storeName: 'employee_smb-currentTab',
            paymentBase: 'employees',
            templateBase: 'employeeTemplates',
        };
        PaymentAndEmployeeMainView.prototype.initialize.call(this, options);
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'SMBEMPLIST',
    view: employeeView,
    options: {},
});

export default employeeView;
