import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import GridApi from 'common/dynamicPages/api/grid';
import PrintExportUtil from 'common/util/printExportUtil';
import FileImportUtil from 'app/administration/views/fileImport/fileImportUtil';
import helpPageUtil from 'common/util/helpPage';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './failedRecordsListView.hbs';

const FailedRecordsListView = Layout.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize() {
        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['JOBID', store.get('jobId')],
        }];

        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'IMPORT',
                        productCode: '_UTIL',
                        functionCode: 'HISTORY',
                        actionMode: 'SELECT',
                    },

                    callingAction: {
                        typeCode: 'IMPORT',
                        entryMethod: 0,
                        productCode: '_UTIL',
                        functionCode: 'HISTORY',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 17239,
                },
            },
        };

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode: 'HISTORY',
            inquiryId: 17239,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode: '_UTIL',
            requestParams: '?!_init=true&_productCode=_UTIL&_functionCode=HISTORY&_typeCode=IMPORT&_mode=SELECT&_gridId=17239',
            typeCode: 'IMPORT',
        };

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
        };

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;
        this.disableFilters = true;
        this.gridView = GridApi.createInquiryGridView(options);
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadViewRequirements();
        }
    },

    gridLoadComplete() {
        $('#failedCount').html(this.gridView.wrapper.rows.totalCount);
        store.set('failedRecordsGridLoaded', true);
        const failedRecsCount = this.gridView.wrapper.rows.totalCount;

        if (failedRecsCount === 0) {
            $('#failedRecordsPanel').hide();
        }

        store.set('failedRecsCount', failedRecsCount);

        if (this.gridView.wrapper.rows.models[0]) {
            store.set('accepts', this.gridView.wrapper.rows.models[0].attributes.ACCEPTS);
        } else {
            store.set('accepts', 1);
        }
        this.options.parent.trigger('bothGridsLoaded');
        this.listenToOnce(this, 'showFailedRecords', this.showFailedRecords);
        this.options.parent.trigger('recsLoaded', {
            failed: {
                loaded: true,
                count: failedRecsCount,
            },
        });
    },

    showFailedRecords() {
        $('#failedRecordsPanel').show();
    },

    templateHelpers() {
        return {
            getButtonString(code) {
                return locale.get(code);
            },
        };
    },

    loadViewRequirements() {
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: '_UTIL',
            functionCode: 'HISTORY',
            typeCode: 'IMPORT',
            mode: 'SELECT',
        });

        return helpPagePromise.then((result) => {
            if (result.helpPage) {
                store.set('helpPage', result.helpPage);
            }

            this.listenTo(this.gridView, 'gridapi:loaded', this.gridLoadComplete);

            if (this.failedRecordsGrid) {
                this.failedRecordsGrid.show(this.gridView);
            }

            return {};
        });
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: '17239',
            title: 'fih.FailedRecordsGrid',
            format: 'PDF',
            productCode: store.get('productCode'),
            importCode: store.get('importCode'),
            functionCode: store.get('functionCode'),
            fileImportId: store.get('fileImportId'),
            jobId: store.get('jobId'),
        };

        FileImportUtil.printFileImport(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: '17239',
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },
});

let list = FailedRecordsListView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, { callbackAfterLoad: 'gridLoadComplete' });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
