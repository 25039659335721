import OutOfBandModel from 'system/mfa/models/outOfBand';
import BaseMFAView from 'system/mfa/views/baseMFA';
import Constants from 'system/mfa/constants';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import Model from '@glu/core/src/model';
import selectContactMethodTmpl from './selectContactMethod.hbs';

export default BaseMFAView.extend({
    template: selectContactMethodTmpl,

    ui: {
        $contacts: '[data-hook="getContacts"]',
        sendNoticeBlock: '.send-notice',
        receiveCodeBlock: '.receive-code',
        $submit: '.submit',
    },

    initialize(options) {
        const challengeSettings = options.challengeSettings || new Model();
        this.model = new OutOfBandModel({
            challengedAction: options.challengedAction,
            userRequestingOnBehalfOf: challengeSettings.get('userRequestingOnBehalfOf'),
        });
    },

    onRender() {
        if (this.hasAtLeastOneContact() || this.skipContactCheck()) {
            if (this.hasAtLeastOneContact()) {
                this.ui.receiveCodeBlock.hide();
                this.ui.$contacts.comboBox();
            }
            if (this.skipContactCheck()) {
                this.model.sync(
                    'send',
                    this,
                    {
                        success() {},
                    },
                    {
                        error() {},
                    },
                );
            }
            this.trigger('dialog:buttons:change', this.buttons());
        } else if (this.options.challengeSettings.get('contacts')[0] !== undefined) {
            if (this.options.challengeSettings.get('contacts')[0].value.includes('mfa.error')) {
                this.handleMFAError('mfa.error.server.fail');
            } else {
                this.handleMFAError('mfa.error.nocontacts.message');
            }
        } else {
            this.handleMFAError('mfa.error.nocontacts.message');
        }
    },

    templateHelpers() {
        return {
            contacts: this.options.challengeSettings.get('contacts'),
            hasContacts: this.hasAtLeastOneContact(),
            cid: this.cid,
        };
    },

    buttons() {
        return [{
            text: locale.get('button.cancel'),
            className: 'btn btn-primary',
            callback: util.bind(this.cancel, this),
        }];
    },

    sendNotification() {
        const self = this;
        this.ui.$submit.prop('disabled', true);
        this.ui.sendNoticeBlock.hide();
        this.ui.receiveCodeBlock.show();
        this.alertRegion.close();

        this.model.set('selection', this.ui.$contacts.val());
        this.model.set('selectionType', this.ui.$contacts.find('option:selected').attr('data-type'));
        this.$el.find('input[name=\'oneTimePassword\']').focus();
        this.model.sendNotice({
            success(result) {
                if (result.respHeader.result === false) {
                    // do something with error
                    const message = result.respHeader.message.join(' ');
                    self.alertView = alert.danger(
                        message,
                        {
                            canDismiss: true,
                        },
                    );
                    self.alertRegion.show(self.alertView);
                }
                self.ui.$submit.prop('disabled', false);
            },

            error: util.bind(this.handleMFAError, this),
        });
    },

    submitCode() {
        const self = this;
        this.alertRegion.close();
        this.ui.$submit.prop('disabled', true);
        this.model.save(
            {},
            {
                success(result) {
                    if (result.get('respHeader').result === false) {
                        const resp = result.get('respHeader');
                        // handle error types
                        if (resp.errorCode === Constants.ERROR_NEW_PASSCODE_REQUIRED) {
                            self.ui.sendNoticeBlock.show();
                            self.ui.receiveCodeBlock.hide();
                            self.model.unset('oneTimePassword');
                            self.model.unset('respHeader');
                        }
                        const message = resp.message.join(' ');
                        self.alertView = alert.danger(
                            message,
                            {
                                canDismiss: true,
                            },
                        );
                        self.alertRegion.show(self.alertView);
                        self.ui.$submit.prop('disabled', false);
                    } else {
                        self.completion();
                    }
                },

                error: util.bind(this.cancel, this),
            },
        );
    },

    hasAtLeastOneContact() {
        return (this.options.challengeSettings.has('contacts')
            && this.options.challengeSettings.get('contacts').length > 0
            && !this.options.challengeSettings.get('contacts')[0].value.includes('mfa.error'));
    },

    skipContactCheck() {
        return (this.options.challengeSettings.get('skipContactCheck') === true);
    },
});
