export default ({ palette }) => ({
    addToConversationButtons: {
        borderTop: '1px solid #666',
        padding: '30px 0 20px 10px',
        display: 'flex',
        justifyContent: 'start',

        '& button': {
            background: 'transparent',
            border: `1px solid ${palette.text.light}`,
            borderRadius: '10vh',
            fontSize: '14px',
            minHeight: '30px',
            marginRight: '15px',
            paddingLeft: '15px',
            paddingRight: '15px',
            textTransform: 'uppercase',
            color: palette.text.light,
        },
    },
    spinner: {
        width: '30%',
        '&:before': {
            width: '30px',
            height: '30px',
            position: 'relative',
            color: 'white',
        },
    },
});
