import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import moment from 'moment';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import userInfo from 'etc/userInfo';
import PaymentUtil from 'common/util/paymentUtil';

export default {
    populateMissingData(model) {
        const fields = [
            'DEBIT_BANK_CODE',
            'DEBIT_ACCOUNT_NUMBER',
            'DEBIT_COUNTRY',
            'DEBIT_CURRENCY',
            'BENE_BANK_COUNTRY',
            'CREDIT_CURRENCY',
        ];
        if (model && model.fieldData) {
            util.each(fields, (field) => {
                if (!model.get(field)
                    && model.fieldData[field]
                    && model.fieldData[field].value) {
                    model.set(
                        field,
                        model.fieldData[field].value,
                        {
                            silent: true,
                        },
                    );
                }
            });
        }
    },

    doFieldValidation(viewParam, model, fieldName, state) {
        const view = viewParam;
        let inputData;

        // NH-177502: Use Promise.resolve to push the request setup to next tick.
        return Promise.resolve().then(() => {
            this.populateMissingData(model);
            const { typeInfo } = model.jsonData;
            const validationService = services.generateUrl(constants.URL_DO_FIELD_VALIDATION);

            inputData = [{
                name: 'REQUESTTYPE',
                value: 'fieldValidation',
            }, {
                name: 'FIELDNAME',
                value: fieldName,
            }, {
                name: 'VALUE_DATE',
                value: this.getValueDate(model),
            }, {
                name: 'TRAN_DATE',
                value: model.get('TRAN_DATE'),
            }, {
                name: 'POST_DATE',
                value: model.get('POST_DATE'),
            }, {
                name: 'ACTION',
                value: state === 'VIEW' ? 'SELECT' : state,
            }, {
                name: 'PRODUCT',
                value: typeInfo.productCode,
            }, {
                name: 'FUNCTION',
                value: typeInfo.functionCode,
            }, {
                name: 'TYPE',
                value: typeInfo.typeCode,
            }, {
                name: 'DEBIT_BANK_CODE',
                value: model.get('DEBIT_BANK_CODE'),
            }, {
                name: 'DEBIT_ACCOUNT_NUMBER',
                value: model.get('DEBIT_ACCOUNT_NUMBER'),
            }, {
                name: 'DEBIT_COUNTRY',
                value: model.get('DEBIT_COUNTRY'),
            }, {
                name: 'DEBIT_CURRENCY',
                value: model.get('DEBIT_CURRENCY'),
            }, {
                name: 'BENE_BANK_CODE',
                value: model.get('BENE_BANK_CODE'),
            }, {
                name: 'BENE_BANK_COUNTRY',
                value: model.get('BENE_BANK_COUNTRY'),
            }, {
                name: 'ENTERED_AMOUNT_FLAG',
                value: model.get('ENTERED_AMOUNT_FLAG'),
            }, {
                name: 'CREDIT_AMOUNT',
                value: model.get('CREDIT_AMOUNT'),
            }, {
                name: 'DEBIT_AMOUNT',
                value: model.get('DEBIT_AMOUNT'),
            }, {
                name: 'CREDIT_CURRENCY',
                value: model.get('CREDIT_CURRENCY'),
            }, {
                name: 'EXCHANGE_RATE',
                value: model.get('EXCHANGE_RATE'),
            }, {
                name: 'EXCHANGE_RATE_CONTRACTID',
                value: model.get('EXCHANGE_RATE_CONTRACTID'),
            }, {
                name: 'BENE_BANK_ID',
                value: model.get('BENE_BANK_ID'),
            }, {
                name: 'BENE_BANK_TYPE',
                value: model.get('BENE_BANK_TYPE'),
            }, {
                name: 'ONUS_INDICATOR',
                value: model.get('ONUS_INDICATOR'),
            }, {
                name: 'RELEASELEADTIME',
                value: model.get('RELEASELEADTIME'),
            }, {
                name: 'FXRATETYPE',
                value: model.get('FXRATETYPE'),
            }, {
                name: 'MARKETCONVENTION',
                value: model.get('MARKETCONVENTION'),
            }, {
                name: 'ENTRYMETHOD',
                value: model.get('ENTRYMETHOD'),
            }, {
                name: 'CREATEDFROM',
                value: model.get('CREATEDFROM'),
            }, {
                name: 'PREADVISEWIRES',
                value: model.get('PREADVISEWIRES'),
            }];

            if (view.requestData === undefined) {
                view.requestData = [];
            }
            view.requestData[fieldName] = inputData;

            // NH-177502: Return the post to eliminate nested promises
            return http.post(
                validationService,
                {
                    item: inputData,
                },
            );
        }).then((result) => {
            if (view.requestData && view.requestData[fieldName]) {
                if (!util.isEqual(view.requestData[fieldName], inputData)) {
                    return undefined;
                }
            }
            const retrievedValues = util.reduce(result.item, (acc, nvp) => {
                const accumulator = acc;
                if (nvp.name === 'VALUE_DATE' || nvp.name === 'TRAN_DATE' || nvp.name === 'POST_DATE') {
                    if (PaymentUtil.isReadyValueDateRetrieval(model)) {
                        accumulator[nvp.name] = moment(nvp.value, 'YYYY-MM-DD').format(userInfo.getDateFormat());
                    }
                } else if (nvp.name === 'MARKETCONVENTION' || nvp.name === 'EXCHANGE_RATE') {
                    accumulator[nvp.name] = nvp.value;
                } else if (nvp.name === 'CUTOFF_INFO' || nvp.name === 'ONUS_INDICATOR') {
                    if (PaymentUtil.isReadyValueDateRetrieval(model)) {
                        accumulator[nvp.name] = nvp.value;
                    }
                } else if ((fieldName === 'CREDIT_AMOUNT' || fieldName === 'DEBIT_AMOUNT') && (nvp.name === 'CREDIT_AMOUNT' || nvp.name === 'DEBIT_AMOUNT')) {
                    accumulator[nvp.name] = nvp.value;
                }
                return accumulator;
            }, {});

            model.set(retrievedValues);
            return result;
        });
    },

    getValueDate(model) {
        let valueDate = model.get('VALUE_DATE');
        /*
         * The validation service request will fail without a value date but an international
         * wire may not yet have a value date set, in which case we send the (unused) default.
         */
        if (model.get('PRODUCT') === 'RTGS' && model.get('FUNCTION') === 'INST' && model.get('TYPE') === 'INTL' && !valueDate) {
            valueDate = model.fieldData.VALUE_DATE ? model.fieldData.VALUE_DATE.value : '';
        }

        return valueDate;
    },
};
