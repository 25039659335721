import Glu from '@glu/core/src/glu';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import gridApi from 'common/dynamicPages/api/grid';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import VendorModel from 'app/administration/models/ssoVendor';
import contextApi from 'common/dynamicPages/api/context';
import entitlements from 'common/dynamicPages/api/entitlements';
import constants from 'common/dynamicPages/api/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import errHandler from 'system/errHandler';
import services from 'services';
import alertMessage from 'common/api/alertMessage';
import template from './vendorListView.hbs';

export default ListView.extend({
    template,

    ui: {
        $addVendor: '[data-hook="getAddVendor"]',
        $importVendor: '[data-hook="getImportVendor"]',
        $delete: '[data-hook="getDeleteBtn"]',
    },

    events: {
        'click @ui.$addVendor': 'addVendor',
        'click @ui.$importVendor': 'importVendor',
        'click @ui.$delete': 'gridBulkDelete',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'SSOADMIN',
            serviceName: '/tableMaintenance/ssoVendors',
            serviceFunc: null,
            businessType: null,
            context: contextApi.menuContext.getContext('SSOADMIN'),
        };

        this.returnUrl = Glu.Backbone.history.getFragment();
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const confirmResponse = store.get('sso:action:success');
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            if (confirmResponse) {
                this.renderMessage(confirmResponse);
            }
            store.unset('sso:action:success');
        } else {
            this.loadViewRequirements();
        }
    },

    /**
     * - use the alert message api to render a response alert
     * @method renderMessage
     * @param {object} confirmResponse - a response message object
     */
    renderMessage(confirmResponse) {
        if (typeof confirmResponse === 'object' && confirmResponse.message.length > 0) {
            alertMessage.renderMessage(this, confirmResponse.result ? 'SUCCESS' : 'DANGER', confirmResponse);
        }
    },

    /**
     * - store the return URL on the model
     * - cache the model in global storage utility
     * - navigate to route
     * @method gridRowSelect
     * @param {object} optionsParam - an options object
     */
    gridRowSelect(optionsParam) {
        const options = optionsParam;
        options.model.set('returnUrl', this.returnUrl);
        options.model.entitlements = this.entitlements;
        store.set('SSOADMIN:actionModel', options.model);
        this.navigateTo('SSOADMIN/viewVendor');
        return Promise.resolve();
    },

    /**
     * - store the return URL on the model
     * - cache the model in global storage utility
     * - navigate to route
     * @method gridRowSelect
     * @param {object} optionsParam - an options object
     */
    gridRowModify(optionsParam) {
        const options = optionsParam;
        options.model.set('returnUrl', this.returnUrl);
        options.model.entitlements = this.entitlements;
        store.set('SSOADMIN:actionModel', options.model);
        this.navigateTo('SSOADMIN/modifyVendor');
        return Promise.resolve();
    },

    /**
     * - trigger an export
     * @method gridRowExport
     * @param {object} options - an options object
     */
    gridRowExport(options) {
        this.doExport(options);
        return Promise.resolve();
    },

    /**
     * - manipulate DOM, submit form
     * @method doExport
     * @param {options} options - an options object
     */
    doExport(options) {
        if (this.$('#SSOExportForm').length === 0) {
            const form = '<form id="SSOExportForm"  method="get" target="_self"><input id="TPV_ID" type="hidden" name="TPV_ID" ></form>';
            this.$el.append(form);
        }
        this.$('#SSOExportForm').attr('action', services.generateUrl(`/tableMaintenance/ssoVendors/export/${options.model.get('TPV_ID')}`));
        this.$('#SSOExportForm #TPV_ID').val(JSON.stringify(options.model.get('TPV_ID')));
        this.$('#SSOExportForm').submit();
    },

    /**
     * - delete a grid row, refresh the grid and display a message
     * @method gridRowDelete
     * @param {object} options - an options object
     */
    gridRowDelete(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            const tpvId = options.model.get('TPV_ID');

            const vendor = new VendorModel({
                id: tpvId,
                TPV_ID: tpvId,
            });

            vendor.destroy({
                success(model, confirmResponse) {
                    self.gridView.refreshGridData();
                    self.renderMessage(confirmResponse);
                    resolve({ model, result: confirmResponse });
                },

                error(e) {
                    self.gridView.refreshGridData();
                    reject(e);
                },
            });
        });
    },

    /**
     * - delete multiple grid rows, refresh the grid and display a message
     * @method gridBulkDelete
     */
    gridBulkDelete() {
        const ar = this.gridView.grid.getSelectedRows();
        const self = this;
        let txtKey = 'tableMaintenance.dialog.confirm.item.delete';

        this.deletePromises = [];

        const addPromise = function (vendor) {
            self.deletePromises.push(new Promise((resolve, reject) => {
                vendor.destroy({
                    success(model, confirmResponse) {
                        resolve(confirmResponse);
                    },

                    error: reject,
                });
            }));
        };

        if (ar.length > 0) {
            if (ar.length > 1) {
                txtKey = 'tableMaintenance.dialog.confirm.items.delete';
            }
            Dialog.confirm(locale.get(txtKey), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
                if (ok) {
                    util.each(ar, (item) => {
                        const gridRowModel = self.gridView.wrapper.rows.get(item);

                        const vendor = new VendorModel({
                            id: gridRowModel.id,
                            TPV_ID: gridRowModel.id,
                        });

                        addPromise(vendor);
                    });
                    Promise.all(self.deletePromises).then((results) => {
                        const [confirmResponse] = results;
                        /*
                         * by default the message in the response reads
                         * "1 SSO Vendor Delete". Replace the first two
                         * characters with the proper length
                         * @TODO: Update the REST service to handle bulk deletes gracefully.
                         */
                        confirmResponse.message[0] = confirmResponse.message[0].replace(/^.{2}/g, `${results.length} `);
                        self.gridView.refreshGridData();
                        self.renderMessage(confirmResponse);
                    })
                        .then(null, errHandler);
                }
            });
        }
    },

    /**
     * - navigate to add/update route
     * @method addVendor
     */
    addVendor() {
        this.navigateTo('SSOADMIN/addUpdateVendor');
    },

    /**
     * - navigate to import route
     * @method importVendor
     */
    importVendor() {
        this.navigateTo('SSOADMIN/importVendor');
    },

    /**
     * - ensure data has been loaded
     * - if so, call the render method
     * @method loadRequiredData
     */
    loadViewRequirements() {
        const self = this;
        const options = util.extend(this.viewOptionRequirements(), this.contextModel.get('context'));
        const gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);

        entitlementPromise.then((result) => {
            self.gridView = gridView;
            self.entitlements = result.actions;
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    templateHelpers() {
        const self = this;

        return {
            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },
        };
    },
});
