import AccountSummaryView from 'app/balanceAndTransaction/views/accountSummary';
import constants from 'app/balanceAndTransaction/constants';
import util from '@glu/core/src/util';

const PreviousDaySummary = AccountSummaryView.extend({
    id: 'showPreviousDayTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showPreviousDayLink',
    },
    initialize(options) {
        util.extend(
            options,
            {
                context: {
                    actionMode: constants.SELECT,
                    functionCode: constants.INST,
                    productCode: constants.GIR,
                    typeCode: constants.BTR_TYPECODES.PREVIOUS.SUMMARY,
                },

                totalsService: `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX + constants.PREVIOUS_DAY_PREFIX}accountSummary/getListViewTotals`,
                depositDetailPageUrl: constants.TRANSACTION_PREVDAY_DEPOSIT_DETAIL,
                creditCardDetailPageUrl: constants.TRANSACTION_PREVDAY_CREDIT_CARD_DETAIL,
                loanDetailPageUrl: 'BANK_ACC_INFO/loanPreviousDayAccountsTransactions',
                tieredLoanDetailPageUrl: 'BANK_ACC_INFO/tieredLoanPreviousDayAccountDetail',
            },
        );

        AccountSummaryView.prototype.initialize.call(this, options);
    },
});

export default PreviousDaySummary;
