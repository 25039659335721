var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-6 required\">\n        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":43}}}))
    + "</label>\n        <div class=\"Dropdown Dropdown--fullWidth\"\n            data-region=\"tokenTypeDropdownRegion\">\n        </div>\n        <span class=\"help-block\"\n            data-bind=\"model\"\n            data-validate=\"TOKENTYPE\">\n        </span>\n    </div>\n        <div class=\"col-6 required\" data-hook=\"deviceTypeDropdown\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.device.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":15,"column":54}}}))
    + "</label>\n            <div class=\"Dropdown Dropdown--fullWidth\"\n                 data-region=\"tokenDeviceTypeDropdownRegion\">\n            </div>\n            <span class=\"help-block\"\n                  data-bind=\"model\"\n                  data-validate=\"ID\">\n            </span>\n        </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-6 required\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.tokenSerialNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":19},"end":{"line":29,"column":60}}}))
    + "</label>\n            <div class=\"Dropdown Dropdown--fullWidth\"\n                data-region=\"tokenDropdownRegion\">\n            </div>\n            <span class=\"help-block\"\n                data-bind=\"model\"\n                data-validate=\"TOKENSERIALNUMBER\">\n            </span>\n        </div>\n        <div class=\"col-6 required\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.user",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":19},"end":{"line":39,"column":47}}}))
    + "</label>\n            <div class=\"Dropdown\"\n                data-region=\"userDropdownRegion\">\n            </div>\n            <span class=\"help-block\"\n                data-bind=\"model\"\n                data-validate=\"USERID\">\n            </span>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-6\">\n            <div class=\"form-group\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.tokenSerialNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":23},"end":{"line":52,"column":64}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TOKENSERIALNUMBER") || (depth0 != null ? lookupProperty(depth0,"TOKENSERIALNUMBER") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"TOKENSERIALNUMBER","hash":{},"data":data,"loc":{"start":{"line":53,"column":47},"end":{"line":53,"column":68}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <div class=\"col-6 required\" data-hook=\"deviceTypeDropdown\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.device.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":19},"end":{"line":57,"column":54}}}))
    + "</label>\n            <div class=\"Dropdown Dropdown--fullWidth\"\n                 data-region=\"tokenDeviceTypeDropdownRegion\">\n            </div>\n            <span class=\"help-block\"\n                  data-bind=\"model\"\n                  data-validate=\"ID\">\n            </span>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-6\">\n            <div class=\"row\">\n                <div class=\"col-6 form-group\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.tokenSerialNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":71,"column":27},"end":{"line":71,"column":68}}}))
    + "</label>\n                    <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TOKENSERIALNUMBER") || (depth0 != null ? lookupProperty(depth0,"TOKENSERIALNUMBER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"TOKENSERIALNUMBER","hash":{},"data":data,"loc":{"start":{"line":72,"column":51},"end":{"line":72,"column":72}}}) : helper)))
    + "</p>\n                </div>\n\n                <div class=\"col-6 form-group\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.token.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":27},"end":{"line":76,"column":61}}}))
    + "</label>\n                    <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TOKENTYPE") || (depth0 != null ? lookupProperty(depth0,"TOKENTYPE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"TOKENTYPE","hash":{},"data":data,"loc":{"start":{"line":77,"column":51},"end":{"line":77,"column":64}}}) : helper)))
    + "</p>\n                </div>\n            </div>\n            <div class=\"required\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.replace.token.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":27},"end":{"line":81,"column":66}}}))
    + "</label>\n                    <div class=\"Dropdown Dropdown--fullWidth\"\n                        data-region=\"tokenTypeDropdownRegion\">\n                    </div>\n                    <span class=\"help-block\"\n                        data-bind=\"model\"\n                        data-validate=\"TOKENTYPE\">\n                    </span>\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.tokenSerialNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":89,"column":23},"end":{"line":89,"column":64}}}))
    + "</label>\n                <div class=\"Dropdown Dropdown--fullWidth\"\n                    data-region=\"tokenDropdownRegion\">\n                </div>\n                <span class=\"help-block\"\n                    data-bind=\"model\"\n                    data-validate=\"TOKENSERIALNUMBER\">\n                </span>\n                    <div data-hook=\"deviceTypeDropdown\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tknmgmt.ui.device.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":98,"column":31},"end":{"line":98,"column":66}}}))
    + "</label>\n                        <div class=\"Dropdown Dropdown--fullWidth\"\n                            data-region=\"tokenDeviceTypeDropdownRegion\">\n                        </div>\n                        <span class=\"help-block\"\n                            data-bind=\"model\"\n                            data-validate=\"ID\">\n                        </span>\n                    </div>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-region\" data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldShowTokenTypeDropdown") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAssign") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmail") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":66,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReplaceOrLost") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":4},"end":{"line":109,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});