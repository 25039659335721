var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                      <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog create-group-plan\">\n    <form class=\"modal-content form-container\" data-submit=\"save\">\n        <div class=\"modal-header group-plans\">\n                        "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"close","locale":"cashflow.groupplan.close","name":"close"},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":113}}}))
    + "\n            <h2 class=\"modal-title\" id=\"modal-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.groupplan.create.groupplan",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":53},"end":{"line":5,"column":101}}}))
    + "</h2>\n        </div>\n        <div class=\"modal-body group-plans\">\n            <div class=\"field-container\">\n                <label for=\"group-plan-name\" class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.groupplan.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":62},"end":{"line":9,"column":98}}}))
    + "</label>\n                <input id=\"group-plan-name\" name=\"name\" class=\"form-control\" data-bind=\"model\">\n            </div>\n\n            <div class=\"field-container\">\n                <label for=\"group-plan-year\" class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.groupplan.year",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":62},"end":{"line":14,"column":98}}}))
    + "</label>\n                <select id=\"group-plan-year\" class=\"form-control\" data-bind=\"model\" name=\"fyStart\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"yearsArray") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":18,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </div>\n\n            <div class=\"date-picker required field-container group-plan-date-container\">\n                <label for=\"group-plan-date\" class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.groupplan.dueby",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":62},"end":{"line":23,"column":99}}}))
    + "</label>\n                <input class=\"group-plan-date input-date\" name=\"expectedDate\" id=\"group-plan-date\" type=\"text\" data-bind=\"model\" />\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"group-plan-date\"></span>\n            </div>\n\n            <div class=\"field-container\">\n                <label for=\"group-plan-notes\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.groupplan.notes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":46},"end":{"line":29,"column":83}}}))
    + "</label>\n                <textarea id=\"group-plan-notes\" class=\"form-control\" rows=\"3\" data-bind=\"model\" name=\"notes\"></textarea>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.groupplan.create.plan",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":58},"end":{"line":34,"column":101}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-loader aria-busy=\"false\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":110},"end":{"line":35,"column":142}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});