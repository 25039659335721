import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import http from '@glu/core/src/http';
import services from 'services';
import systemConfig from 'system/configuration';
import FlexDropdown from '@glu/flex-dropdown';
import PermissionsByPaymentType from './permissionsByPaymentType';
import PermissionsByPaymentGroup from './permissionsByPaymentGroup';
import globalSettings from '../global/globalSettings';
import assignedPermissionsTmpl from './assignedPermissions.hbs';
import { onChildrenChange } from '../helpers';

export default Layout.extend({
    template: assignedPermissionsTmpl,

    ui: {
        $popovers: '[data-toggle="popover"]',
        $permissionGroupBy: '.perm-group-by',
        $paymentCheckbox: '[data-hook="paymentsChk"]',
        $templateCheckbox: '[data-hook="templatesChk"]',
        byGroupButton: '[data-hook="byGroupBtn"]',
        byTypeButton: '[data-hook="byTypeBtn"]',
        $selectAll: '[data-hook="globalSelectAllPerm"]',
        $approverDropdown: '[data-hook="approverDropdown"]',
    },

    events: {
        'click @ui.byGroupButton': 'switchToByGroup',
        'click @ui.byTypeButton': 'switchToByType',
        'click @ui.$selectAll': 'selectAllPermissions',
    },

    initialize(options) {
        this.mode = options.mode;
        this.permissionsForUser = this.model.userModel;
        this.infoModel = this.model.roleModel || this.model.userModel;
        this.context = this.infoModel.context;
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this, null, this.paymentType() === 'PAYMENT_GROUP');
    },

    getApproverData() {
        const isAdmin = systemConfig.isAdmin();
        const approverService = services.generateUrl('tableMaintenance/getQueryResults');
        const productCode = this.context.productCode || this.context.actionData?.productCode;
        const functionCode = this.context.functionCode || this.context.actionData?.functionCode;
        const typeCode = this.context.typeCode || this.context.actionData?.typeCode;
        const actionMode = this.context.actionMode || this.context.actionData?.actionMode;
        const subTypeCode = this.context.subType || this.context.actionData?.subType;

        const approverData = {
            queryCriteria: {
                action: {
                    productCode,
                    functionCode,
                    typeCode,
                    actionMode,
                },
                inquiryId: isAdmin ? 17020 : 40008,
                allowDuplicates: false,
                entryClass: '',
                subTypeCode,
            },
        };

        // if in admin vs. client
        if (systemConfig.isAdmin()) {
            approverData.queryCriteria.customFilters = [
                {
                    filterName: 'Depends',
                    filterParam: [
                        'USERGROUP',
                        this.infoModel.get('USERGROUP'),
                    ],
                },
            ];
        }

        return new Promise(((resolve, reject) => {
            http.post(approverService, approverData, (data) => {
                resolve(data);
            }, (error) => {
                reject(error);
            });
        }));
    },

    handleApproverTypeChange(selected) {
        this.infoModel.set('APPROVERTYPE', selected[0].id);
    },

    createApproverDropdown(data) {
        const dropdownData = data.queryResponse.QueryData.queryRows.map(item => ({
            id: item.name,
            name: item.label,
        }));

        this.approverDropdown = new FlexDropdown({
            data: dropdownData,
            label: locale.get('uce.approverType'),
            preSelectedIds: [this.infoModel.get('APPROVERTYPE')],
            disabled: this.mode === 'view',
            disableMultiButton: true,
            clearBtn: true,
            multiSelect: false,
        });
    },

    getPaymentApprovalType() {
        const paymentApprovalType = util.findWhere(this.model.userGroupModel.get('mapDataList'), {
            toField: 'PaymentApprovalType',
        });
        return paymentApprovalType.value;
    },

    setLoaded() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    loadRequiredData() {
        // only get the approvaltype data for uce-based user
        if (this.permissionsForUser && this.getPaymentApprovalType() === '1') {
            this.approverTypePromise = this.getApproverData();
            this.approverTypePromise.then((data) => {
                this.createApproverDropdown(data);
                this.setLoaded();
            });
        } else {
            this.setLoaded();
        }
    },

    renderAssignedPermissions() {
        let permissionsView;
        let value;
        this.allSelected = false;

        switch (this.paymentType()) {
        case 'ALL_PAYMENT_TYPES':
            break;
        case 'PAYMENT_TYPE':
            value = 'byType';
            permissionsView = new PermissionsByPaymentType({
                mode: this.mode,
                model: this.model,
                onTabChange: () => {
                    this.updateSelectAllState(null, false);
                    this.onChildrenChange();
                },
                onChildrenChange: (_, selectAll, groupId) => {
                    this.updateSelectAllState(null, false, selectAll, groupId);
                },
            });
            this.ui.byTypeButton.addClass('active');

            break;
        case 'PAYMENT_GROUP':
        default:
            value = 'byGroup';
            permissionsView = new PermissionsByPaymentGroup({
                mode: this.mode,
                model: this.model,
                onTabChange: () => {
                    this.updateSelectAllState(null, true);
                    this.onChildrenChange();
                },
            });
            this.ui.byGroupButton.addClass('active');

            break;
        }

        if (this.permissionsForUser && this.getPaymentApprovalType() === '1') {
            this.approverTypeRegion.show(this.approverDropdown);
            this.listenTo(this.approverDropdown, 'selectionChanged', this.handleApproverTypeChange);
        }
        this.ui.$permissionGroupBy.comboBox().comboBox('val', value);
        this.permissionsRegion.show(permissionsView);
        this.ui.$popovers.popover();

        //  this.handleApproverDropdownPromise(); // TODO put in loadRequired

        globalSettings.populateBeneAddressBookSettings(
            this.model,
            this.ui.$paymentCheckbox, this.ui.$templateCheckbox,
        );
    },

    paymentType() {
        return this.model.userModel ? this.model.userModel.get('GROUPED_PERMISSIONS_BY') : this.model.roleModel.get('GROUPED_PERMISSIONS_BY');
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.renderAssignedPermissions();
            this.updateSelectAllState(null, true);
            this.onChildrenChange();
        } else {
            this.loadRequiredData();
        }
    },

    updateSelectAllState(_, byGroup, selectAll, groupId) {
        let allSelected;
        const mapEntitlements = entitlements => Object.values({
            ...entitlements.get('actions'),
            ...entitlements.get('restrictions'),
        });
        const hasFullEntitlements = models => models.flat()
            .map(group => group?.get('types')?.models)
            .flat() // now an array of entitlement models
            .map(group => group.get('entitlements'))
            .flat()
            .map(group => group.models)
            .flat()
            .map(mapEntitlements)
            .flat()
            .filter(a => a)
            .filter(a => !a.entitled && a.applicable).length === 0;
        if (byGroup) {
            allSelected = this.model.get('groups')?.models
                .flat() // now an array of group models
                .map(group => group?.get('aggregateModels')?.models)
                .flat() // now an array of entitlement models
                .map(mapEntitlements)
                .flat() // now an array of entitlements
                .filter(a => a)
                .every(action => action.entitled);
        } else {
            allSelected = hasFullEntitlements(this.model.get('groups')?.models);
        }
        this.ui.$selectAll.prop('checked', allSelected);

        // Handle section level select all checkbox
        const selectAllSelected = hasFullEntitlements(this.model.get('groups')?.models.filter(group => group.get('id') === groupId));
        selectAll?.prop?.('checked', selectAllSelected);
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.permissionsRegion?.$el.find('input[type="checkbox"]');
    },

    switchToByGroup() {
        this.allSelected = false;
        // warn about loss of settings
        dialog.confirm(locale.get('uce.switchGroupType'), (ok) => {
            if (ok) {
                if (this.infoModel) {
                    this.infoModel.set('GROUPED_PERMISSIONS_BY', 'PAYMENT_GROUP');
                }

                // Need to reset permissions when going from type to group
                this.selectAllPermissions(false);

                // Reset overall limits
                this.model.get('groups').chain()
                    .filter(group => group.isEntitled())
                    .reduce((obj, group) => {
                        /*
                         * Get the groups that have the entitlements
                         * and push the entitled types.
                         */
                        obj.push(group.getEntitledTypes().models);
                        return obj;
                    }, [])
                    .flatten(true)
                    .filter((type) => {
                        const limits = type.get('limits');

                        // Are there any limits set
                        return util.some(util.keys(limits), key => limits[key].value > 0);
                    })
                    .each((type) => {
                        const limits = type.get('limits');

                        util.each(util.keys(limits), (key) => {
                            limits[key].value = null;
                        });
                    });

                this.permissionsRegion.show(new PermissionsByPaymentGroup({
                    model: this.model,
                    mode: this.mode,
                    onTabChange: () => {
                        this.updateSelectAllState(null, true);
                        this.onChildrenChange();
                    },
                }));

                this.ui.byGroupButton.addClass('active');
                this.ui.byTypeButton.removeClass('active');
                this.updateSelectAllState(null, true);
            } else {
                this.ui.$permissionGroupBy.comboBox('val', 'byType');
            }
        });
    },

    switchToByType() {
        this.allSelected = false;
        this.model.groupToTypes(() => {
            if (this.infoModel) {
                this.infoModel.set('GROUPED_PERMISSIONS_BY', 'PAYMENT_TYPE');
            }
            this.trigger('payments:rerender');
        });
    },

    /**
     * Select all permissions across all payment types or groups.
     * @param {Event/boolean} evt [jQuery mouse event or boolean]
     */
    selectAllPermissions(evt) {
        const checked = util.isBoolean(evt) ? evt : evt.currentTarget.checked;
        this.model.getSelectedGroups().each(function (group) {
            if (this.infoModel.get('GROUPED_PERMISSIONS_BY') === 'PAYMENT_GROUP') {
                group.get('aggregateModels').selectAll(checked);
            } else {
                group.getEntitledTypes().selectAll(checked);
            }
        }, this);

        if (this.permissionsRegion && this.permissionsRegion.currentView) {
            this.permissionsRegion.currentView.render();
        }
        this.allSelected = false;
        this.onChildrenChange();
    },

    templateHelpers() {
        return {
            readOnly: this.mode === constants.MODES.VIEW,
            showAllowFXSelection: this.permissionsForUser && appConfigParams.getValue('WIRES', 'ALLOWFXUSDUSER') === '1',
            allowFXUSERUser: (this.mode === constants.MODES.INSERT && this.infoModel.get('ALLOWFXUSDUSER') === '') || this.infoModel.get('ALLOWFXUSDUSER') !== '0',
            notAllowFXUSERUser: this.infoModel.get('ALLOWFXUSDUSER') === '0',
        };
    },
});
