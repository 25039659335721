var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content\">\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.quickbooks.connect",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":72}}}))
    + "</h1>\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n    <section class=\"\">\n        <div class=\"dashboard-layout\"><div class=\"dashboard-inner\">\n            <div class=\"dashboard-region dashboard-region-large\">\n                <div class=\"widget\">\n                    <div class=\"widget-container\">\n                        <div class=\"widget-header\">\n                            <h2></h2>\n                        </div>\n                        <div class=\"widget-body\">\n                            <div>\n                                                            <span id=\"connImage\"></span>\n                                                            <span id=\"connText\"></span>\n                                </div>\n                                <div>\n                                    <span>\n                                                       <ipp:connectToIntuit></ipp:connectToIntuit>\n                                                        <button type=\"button\" class=\"btn btn-primary btn-refresh-conn\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.refresh.status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":140},"end":{"line":22,"column":173}}}))
    + "</button>\n                                                        </span>\n                                </div>\n                            </div>\n                            <div class=\"widget-footer\">\n                            </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        </div>\n    </section>\n</div>\n";
},"useData":true});