/* istanbul ignore file */
export const styles = () => ({
  filterWrapper: {
    '& ul': {
      maxHeight: 175,
      overflow: 'auto',
      margin: [7, -10, -6, -5],
      padding: [0, 5],
      '& > li:first-child': {
        marginTop: 0
      }
    }
  }
});

export default styles;
