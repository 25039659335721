var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"col-2 required\">\n                <div class=\"Dropdown Dropdown--fullWidth\"\n                     data-region=\"daysDropdownRegion\"\n                     name=\"DAYS\">\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "required";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tokenManagement.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":65}}}))
    + "</h1>\n</div>\n<div class=\"glu-container\">\n    <div class=\"section section-container token-management\">\n        <div class=\"section-header\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tokenManagement.section-header-list",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":64}}}))
    + "</h2>\n        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"expired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"col-4 "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"expired") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":30},"end":{"line":19,"column":68}}})) != null ? stack1 : "")
    + "\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.company",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":23},"end":{"line":20,"column":58}}}))
    + "</label>\n                <input id=\"usergroup\" class=\"form-control usergroup-select\" name=\"userGroup\" data-bind=\"model\">\n            </div>\n            <div class=\"col-2\">\n                <button class=\"btn btn-primary submit\"\n                    data-hook=\"getSubmitBtn\"\n                    type=\"button\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":27,"column":46}}}))
    + "\n                </button>\n            </div>\n        </div>\n        <div class=\"section body\">\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-info-links hide\" data-hook=\"getWidgetLinks\">\n                    <button class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":67},"end":{"line":34,"column":93}}}))
    + "\"\n                        data-hook=\"export-button\"\n                        type=\"button\">\n                        <span class=\"icon-export\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":58},"end":{"line":37,"column":84}}}))
    + "\n                    </button>\n                    <button class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":66},"end":{"line":39,"column":91}}}))
    + "\" \n                        data-hook=\"print-button\"\n                        type=\"button\">\n                        <span class=\"icon-print\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":57},"end":{"line":42,"column":82}}}))
    + "\n                    </button>\n                </div>\n            </div>\n            <div class=\"grid-region hide\" data-region=\"gridRegion\" data-hook=\"getGrid\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});