import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import eStatementTemplate from 'app/reports/views/eStatement/eStatement.hbs';
import EStatementResultsList from 'app/reports/views/eStatement/eStatementResultsList';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import $ from 'jquery';
import scroll from 'common/util/scroll';
import Formatter from 'system/utilities/format';
import contextApi from 'common/dynamicPages/api/context';
import StatementTypeCollection from 'app/reports/collections/statementTypes';
import AccountCollection from 'app/reports/collections/eStatementAcct';
import DateRange from 'common/util/dateRange';
import errorHandlers from 'system/error/handlers';
import alert from '@glu/alerts';
import moment from 'moment';
import transform from 'common/util/transform';
import workspaceHelper from 'common/workspaces/api/helper';
import store from 'system/utilities/cache';
import helpPageUtil from 'common/util/helpPage';
import http from '@glu/core/src/http';
import services from 'services';
import searchFieldsUtil from 'common/util/searchFieldsUtil';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import loadingTemplate from 'common/templates/loadingPage.hbs';

const ALL_ACCOUNTS = 'all';

const EStatmentView = Layout.extend({
    template: eStatementTemplate,
    loadingTemplate,
    includeAllAccounts: true,

    ui: {
        $startDateContainer: '.start-date-container',
        $endDateContainer: '.end-date-container',
        $rangeContainers: '.range-container',
        $rangeSelector: 'select[name="statementDate"]',
        $datepicker: '.datepicker',
        $search: 'button[data-action="search"]',
        $statementType: 'select[data-hook="statement-select"]',
        $accountSelect: 'input[name="accountSelect"]',
    },

    events: {
        'apply.daterangepicker @ui.$datepicker': 'updateModelDates',
        'change @ui.$rangeSelector': 'toggleDateRanges',
        'change @ui.$accountSelect': 'updateComboBox',
    },

    handleWidgetSizeChange() {
        util.defer(() => {
            this.accountGridView.grid.tableView.setWidth();
        });
    },

    initialize() {
        this.contextDef = contextApi.menuContext.getContext('SEARCH_ESTATE');
        this.accountCollection = new AccountCollection();
        this.mode = 'view';
        this.options = {
            mode: this.mode,
            contextDef: this.contextDef,
        };

        this.model = new Model();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.showDatePicker();
            // Create the account combo box.
            this.initSelect2Combobox();
            this.shouldAddAllAccounts();
            this.listenTo(this.model, 'change:statementType', this.typeChange);
        } else {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const statementTypesPromise = this.statementTypePromise();
        const dateRangePromise = this.dateRangePromise();

        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'GIR',
            functionCode: 'STMENT',
            typeCode: 'ESTMENT',
            mode: '*',
        });

        Promise.all([
            statementTypesPromise,
            dateRangePromise,
            helpPagePromise,
        ]).then((results) => {
            /*
             * 3 promises are included in this promise.all,
             *  the first one is for statmentTypes, results[0],
             *  the second is for dataRange, result[1],
             *  the third is for helpPage, result[2]
             */
            [this.statementTypes] = results;
            if (this.statementTypes) {
                // seed the first one in the model because it is not bound initially
                this.model.set('statementType', this.statementTypes.models[0].id);
                this.accountCollection.setECodeFilter(this.statementTypes.models[0].id);
            }

            if (!util.isEmpty(results[1])) {
                // dateRangePromise returns the dateRange model
                this.minDate = results[1].get('minDate');
                this.maxDate = results[1].get('maxDate');
                this.startDate = results[1].get('startDate');
                this.maxDaysPerSearch = results[1].get('maxDaysPerSearch');

                // make date range required
                if (util.isNullOrUndefined(this.model.validators)) {
                    this.model.validators = {};
                }
                this.model.validators.dateRange = {
                    exists: true,
                    description: locale.get('relativeDateRange.dateRange'),
                };
            }
            if (!util.isEmpty(results[2])) {
                store.set('helpPage', results[2].helpPage);
            }
            this.allAccountsThreshhold = util.isUndefined(applicationConfigParams.getValue('ESTMNTSRCH', 'ESTATEMENTSALLACCOUNTSTHRESHHOLD'))
                ? constants.LOOKUP_GRID_ROWS_PER_PAGE
                : parseInt(applicationConfigParams.getValue('ESTMNTSRCH', 'ESTATEMENTSALLACCOUNTSTHRESHHOLD'), 10);
            this.setHasLoadedRequiredData(true);
            this.render();
        }, util.bind(errorHandlers.loadingModal, this));
    },

    statementTypePromise() {
        return new Promise((resolve, reject) => {
            const statementTypes = new StatementTypeCollection();
            statementTypes.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    dateRangePromise() {
        const self = this;
        return new Promise((resolve, reject) => {
            const dateRange = new DateRange(self.options);
            dateRange.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * typeChange function clears the account combobox and sets the new Statement
     * Type in the collection.
     */
    typeChange() {
        this.eStatementResultsRegion.close();
        this.accountCollection.setECodeFilter(this.model.get('statementType'));
        this.accountCollection.reset();
        this.shouldAddAllAccounts();
    },

    invalidSearch() {
        this.alertView = alert.danger(
            locale.get('system.invalidDateRange'),
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
        scroll.scrollToFirstError();
    },

    /**
     * search function takes the selected filter values, makes a server request
     * to get the matching eStatement data, and displays it in a list view.
     */
    search() {
        if (this.alertRegion.$el) {
            // clear the alert region
            this.alertRegion.$el.empty();
        }

        const self = this;
        const accountFilters = [];
        const dateRangeFieldValue = this.$('#dateRange').val();

        const dp = this.ui.$datepicker.data('daterangepicker');

        // set the dateRange attribute in the model and then validate the model
        this.model.set('dateRange', dateRangeFieldValue);
        const isValid = this.model.isValid();

        if (!isValid) {
            this.invalidSearch();
            this.model.unset(
                'dateRange',
                {
                    silent: true,
                },
            );
            return;
        }
        this.model.unset(
            'dateRange',
            {
                silent: true,
            },
        );

        // validate startDate & endDate exist
        if (dp.startDate && dp.endDate) {
            if (dp.isInvalidDay(dp.startDate) || dp.isInvalidDay(dp.endDate)) {
                this.invalidSearch();
                return;
            }
        }

        // validate the day range for search not exceeding allowed number of days
        if (dp.startDate && dp.endDate) {
            const startDate = moment(dp.startDate);
            const endDate = moment(dp.endDate);
            this.updateDatesAttrs(startDate, endDate);
            if (startDate < endDate.subtract(self.maxDaysPerSearch, 'day')) {
                this.alertView = alert.danger(
                    locale.get('PS.Search.DateRange.Exceeded').replace('{0}', self.maxDaysPerSearch),
                    {
                        canDismiss: true,
                    },
                );
                this.alertRegion.show(this.alertView);
                scroll.scrollToFirstError();
                return;
            }
        }

        /**
         * If the 'All Accounts' is selected, the account filter
         * will be sent in the report request as empty.
         */
        const selectedAccounts = this.$('#accountSelect').comboBox('data');
        if (selectedAccounts.length > 0) {
            if (selectedAccounts[0].id !== ALL_ACCOUNTS) {
                util.each(selectedAccounts, (value) => {
                    accountFilters.push(value.id);
                });
                if (accountFilters.length > this.allAccountsThreshhold) {
                    this.alertView = alert.danger(
                        locale.get('GIR.eStatements.AllAccounts.Threshhold', this.allAccountsThreshhold),
                        {
                            canDismiss: false,
                        },
                    );
                    this.alertRegion.show(this.alertView);
                    scroll.scrollToFirstError();
                    return;
                }
            }
        } else {
            this.alertView = alert.danger(
                locale.get('GIR.Account.Not.Selected'),
                {
                    canDismiss: false,
                },
            );
            this.alertRegion.show(this.alertView);
            scroll.scrollToFirstError();
            return;
        }
        this.alertRegion.close();

        const options = {
            requestParameters: {
                item: [{
                    name: 'initialRequest',
                    value: 'true',
                }],
            },

            accountFilters,
            statementType: this.model.get('statementType'),
            startDate: this.model.get('startDate'),
            endDate: this.model.get('endDate'),
        };

        this.ui.$search.attr('aria-busy', true);
        options.dynamicDatePayload = [{
            fieldName: 'STATEMENTDATE',
            type: this.options.contextDef.typeCode,
            field: 'PAST',
        }];
        const eStatementResultsList = new EStatementResultsList(options);

        this.listenTo(eStatementResultsList, 'eStatementList:child:isReady', () => {
            self.ui.$search.attr('aria-busy', false);
        });

        this.eStatementResultsRegion.show(eStatementResultsList);
    },

    /**
     * Update startDate and endDate model attributes
     * @param {moment} startDate
     * @param {moment} endDate
     */
    updateDatesAttrs(startDate, endDate) {
        this.model.set({
            startDate: Formatter.formatDate(startDate),
            endDate: Formatter.formatDate(endDate),
        });
    },

    getAction() {
        const context = this.contextDef;
        return {
            typeCode: context.typeCode,
            entryMethod: 0,
            productCode: context.productCode,
            actionMode: context.actionMode,
            functionCode: context.functionCode,
        };
    },

    updateViewDates() {
        const dp = this.ui.$datepicker.data('daterangepicker');
        // need this to display date
        dp.setStartDate(this.startDate);
        dp.setEndDate(this.endDate);

        // set the model the first time in since it defaults and not selected
        this.updateDatesAttrs(dp.startDate, dp.endDate);
    },

    updateModelDates() {
        const { separator } = this.ui.$datepicker.data('daterangepicker');

        // does the datepicker always use userInfo.getDateFormat format?
        const rangeString = this.ui.$datepicker.val();

        const parts = rangeString.split(separator);

        if (parts.length === 1) {
            this.model.set({
                startDate: parts[0],
                endDate: parts[0],
            });
        } else if (parts.length === 2) {
            this.model.set({
                startDate: parts[0],
                endDate: parts[1],
            });
        }

        this.eStatementResultsRegion.close();
    },

    /**
     * updateComboBox function controls the 'All Accounts' selection.
     * If an account is selected, the 'All Accounts' is removed.
     * If 'All Accounts' is selected, all other accounts are removed.
     * @param {object} changeObj   new object selected in accounts combobox
     */
    updateComboBox(changeObj) {
        const current = changeObj.val;
        const addedSelection = (util.isNullOrUndefined(changeObj.added))
            ? [] : changeObj.added.id;

        if (current && current.length > 1 && util.indexOf(current, ALL_ACCOUNTS) >= 0) {
            if (addedSelection === ALL_ACCOUNTS) {
                this.loadAllAccounts(true);
            } else {
                this.ui.$accountSelect.comboBox('val', util.without(current, ALL_ACCOUNTS));
            }
        }
    },

    /**
     * parseAccounts function formats the data to be added to the list.
     * It also adds the "All Accounts" selection if it is not already in the list.
     * @param {object} results from the latest read operation where
     * "key - value pairs" where {"key => label" : "value => numeric value" }
     * @param {boolean} addAllAccounts   true if the "All Accounts" item should to be added.
     */
    parseAccounts(results, addAllAccounts) {
        const filters = util.map(results, (item) => {
            const id = item.AccountFilter;
            return {
                id,
                text: item.AccountNameNumber,
            };
        });

        if (addAllAccounts && this.includeAllAccounts) {
            filters.unshift({
                id: ALL_ACCOUNTS,
                text: locale.get('listviews.allaccounts'),
            });
        }

        return filters;
    },

    initSelect2Combobox() {
        const self = this;
        this.ui.$accountSelect.comboBox({
            initSelection(element, callback) {
                let data = util.map($(element.val().split(',')), value => ({
                    id: value,
                    text: value,
                }));
                if (data.length === 1) {
                    data = util.first(data);
                }

                callback(data);
            },

            dropdownAutoWidth: 'true',
            allowClear: true,
            multiple: true,
            closeOnSelect: false,

            query: util.debounce((query) => {
                self.accountCollection.setFilter(query.page, query.term);
                self.accountCollection.fetch({
                    /*
                     * when fetching additional pages, keep previous models in the
                     * collection
                     */
                    remove: query.page === 1,

                    success(collection, resp) {
                        const result = resp.rows.map(row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));

                        const data = {
                            results: self.parseAccounts(result, collection.startRow === 1),
                            more: collection.hasMorePages,
                        };

                        query.callback(data);
                    },
                });
            }, constants.COMBO_DEBOUNCE),
        });
    },

    /**
     * Called to set the combo selection to the "All Accounts" item.
     * @param {boolean} addAllAccounts flag indicating if we need to add the All Accounts
     * options to the Combo or not.
     */
    loadAllAccounts(addAllAccounts) {
        const data = {
            id: ALL_ACCOUNTS,
            text: locale.get('listviews.allaccounts'),
        };
        this.ui.$accountSelect.comboBox('data', addAllAccounts ? data : '');
    },

    /**
     * Called to get the list of accounts for the statement type that the user has access for.
     * if the user has access to less than or equal to the threshhold defined by
     * allAccountsThreshhold, then the "All Accounts" option will be available for the user
     * in the Combo Box.
     */
    shouldAddAllAccounts() {
        const self = this;
        this.ui.$search.attr('aria-busy', true);
        const url = services.generateUrl('/inquiry/getData');
        const postData = {
            requestHeader: {},
            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 10,
                searchCriteria: {
                    searchType: '5',
                    callingAction: {
                        productCode: 'GIR',
                        functionCode: 'STMENT',
                        actionMode: 'DLOAD',
                        typeCode: 'ESTMENT',
                    },
                    inquiryId: 29563,
                    searchFields: [
                        searchFieldsUtil.createSearchField('ECODE', this.model.get('statementType')),
                    ],

                },
            },
        };
        const accountPromise = http.post(url, postData);
        accountPromise.then((respData) => {
            self.includeAllAccounts = respData.inquiryResponse
                .totalRows <= self.allAccountsThreshhold;
            self.loadAllAccounts(self.includeAllAccounts);
            self.ui.$search.attr('aria-busy', false);
        });
    },

    toggleDateRanges(e) {
        const self = this;
        const selectedVal = this.$(e.target).find('option:selected').val();
        if (selectedVal === 'range') {
            self.ui.$rangeContainers.show();
        } else {
            self.ui.$rangeContainers.hide();
        }
    },

    showDatePicker() {
        const { minDate } = this;
        const { maxDate } = this;
        const { startDate } = this;

        this.ui.$datepicker.nhDatePicker({
            showCalendarIcon: true,
            rangeDatePicker: true,
            endDate: maxDate,
            startDate,
            minDate,
            maxDate,
            dynamicDateOptions: true,
            payload: {
                ...constants.DYNAMIC_DATE_OPTION_ESTATEMENTS_PAYLOAD,
                productCode: this.contextDef.productCode,
                typeCode: this.contextDef.typeCode,
                functionCode: this.contextDef.functionCode,
                fieldName: 'PASTDURATIONDESC',
                actionMode: 'INSERT',
                subTypeCode: '*',
                entryClass: '',
                allowDuplicates: false,
            },
        });
        this.updateViewDates();
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
        this.ui.$accountSelect.comboBox('destroy');
    },

    templateHelpers() {
        return {
            getString(appResource) {
                return locale.get(appResource);
            },

            statementTypes: (this.statementTypes) ? this.statementTypes.toJSON() : [],
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'ESTATEMENTS_SEARCH',
    view: EStatmentView,
    options: {},
    useMobileCard: true,
});

export default EStatmentView;
