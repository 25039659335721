import { createUseStyles } from '@glu/theming';

const styles = {
  actions: {
    margin: [20, 0],
    '& button': {
      margin: [0, 20, 0, 0]
    }
  }
};

export default createUseStyles(styles);
