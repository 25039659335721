import MetaDrivenForm from 'common/dynamicPages/views/mdf/metaDrivenForm';
import MultiItemView from './multiItemView';
import mdfMultiItemViewTmpl from './mdfMultiItemView.hbs';

/**
 * extend metaDrivenForm and assign a different class name for the container
 *
 */
const MultiMetaDrivenForm = MetaDrivenForm.extend({
    className() {
        return `multi-item-${this.options.itemIndex}`;
    },
});

export default MultiItemView.extend({
    template: mdfMultiItemViewTmpl,

    /**
     * @name initialize
     * @description backbone initialize lifecycle method
     * In here an new meta data driven form view (MDF page) is created using the
     * incoming options
     * @param options
     */
    initialize(options) {
        this.mdfView = new MultiMetaDrivenForm(options);
    },

    /**
     * @name onRender
     * @description backbone render lifecycle method
     * The mdf view created in the initialize method, is displayed in the item view
     */
    onRender() {
        this.itemContent.show(this.mdfView);
    },
});
