var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form autocomplete=\"off\" data-submit=\"save\">\n    <div class=\"validation-group hide\"\n         role=\"alert\">\n        <p class=\"help-block center\"\n           data-validation=\"generic\"></p>\n    </div>\n    <div>\n        <p>\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.numberOfItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":58}}}))
    + "\n            <span data-hook=\"getSelectedItems\"> "
    + alias2(((helper = (helper = lookupProperty(helpers,"selectedItemsCount") || (depth0 != null ? lookupProperty(depth0,"selectedItemsCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"selectedItemsCount","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":70}}}) : helper)))
    + " </span>\n        </p>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.include",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":15,"column":59}}}))
    + "</label>\n            <div class=\"form-group\">\n                <div class=\"radio\">\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\"\n                               value=\"all\"\n                               name=\"selection\"\n                               data-bind=\"model\"\n                               data-hook=\"getSelection\"\n                               "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSelectedRows") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":31},"end":{"line":24,"column":77}}})) != null ? stack1 : "")
    + ">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.allItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":65}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"radio\">\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\"\n                               value=\"sel\"\n                               name=\"selection\"\n                               data-bind=\"model\"\n                               data-hook=\"getSelection\"\n                               "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSelectedRows") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":31},"end":{"line":35,"column":77}}})) != null ? stack1 : "")
    + ">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.selectedItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":36,"column":70}}}))
    + "\n                    </label>\n                    <span class=\"help-block\"\n                          data-bind=\"model\"\n                          data-validate=\"pay-selection\">\n                     </span>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-6\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.exportOptions.Export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":19},"end":{"line":47,"column":59}}}))
    + "</label>\n            <div class=\"form-group\">\n                <div class=\"radio\">\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\"\n                               value=\"summary\"\n                               name=\"expdata\"\n                               data-bind=\"model\" />\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":64}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"radio\">\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\"\n                               value=\"detail\"\n                               name=\"expdata\"\n                               data-bind=\"model\" />\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":64,"column":64}}}))
    + "\n                    </label>\n                </div>\n                <span class=\"help-block\"\n                      data-bind=\"model\"\n                      data-validate=\"pay-expdata\">\n            </span>\n            </div>\n        </div>\n    </div>\n\n</form>\n";
},"useData":true});