// istanbul ignore file
import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';

export const ACTIONS = {
  DELETE: 'DELETE',
  RENAME: 'RENAME',
  SET_DEFAULT: 'SET_DEFAULT'
};
export default function ViewActions(props) {
  const {
    classes,
    closePopover,
    onDefaultChange,
    showDelete,
    showRename,
    showSetDefault,
    toggleDeleteConfirmation,
    toggleRenaming,
    view
  } = props;
  const {
    buttonReset,
    menuActionsWrapper
  } = classes;
  return (
    <div className={menuActionsWrapper}>
      {showSetDefault && (
        <button
          data-qa={`savedViews-setDefault-${view.viewData.id}`}
          className={buttonReset}
          onClick={() => {
            onDefaultChange(view.viewData.id);
            closePopover();
          }}
          type="button"
        >
          <span>{locale.get('dataComponents.setDefault')}</span>
        </button>
      )}
      {showRename && (
        <button
          className={buttonReset}
          data-qa={`rename-${view.viewData.id}`}
          onClick={() => {
            toggleRenaming(true);
          }}
          type="button"
        >
          {locale.get('dataComponents.rename')}
        </button>
      )}
      {showDelete && (
        <button
          className={buttonReset}
          onClick={() => {
            toggleDeleteConfirmation(true);
          }}
          data-qa={`delete-${view.viewData.id}`}
          type="button"
        >
          {locale.get('dataComponents.delete')}
        </button>
      )}
    </div>
  );
}

ViewActions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  closePopover: PropTypes.func,
  onDefaultChange: PropTypes.func.isRequired,
  showDelete: PropTypes.bool.isRequired,
  showRename: PropTypes.bool.isRequired,
  showSetDefault: PropTypes.bool.isRequired,
  toggleDeleteConfirmation: PropTypes.func.isRequired,
  toggleRenaming: PropTypes.func.isRequired,
  view: PropTypes.shape({
    viewData: PropTypes.shape({
      allowedActions: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDefault: PropTypes.bool,
      isStatic: PropTypes.bool
    })
  })
};

ViewActions.defaultProps = {
  classes: {},
  closePopover() {},
  view: {
    id: null
  }
};
