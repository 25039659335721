import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import template from './listBuilderAsset.hbs';

export default ItemView.extend({
    template,

    events: {
        'click input': 'toggleSelected',
    },

    templateHelpers() {
        return {
            label: this.model.get('DESCRIPTION'),
            isEstatement: !util.isEmpty(this.model.get('ECODE')),
            isLegacyReport: !util.isEmpty(this.model.get('REPORTID')),
            isAccount: util.isEmpty(this.model.get('ECODE')) && util.isEmpty(this.model.get('REPORTID')) && !util.isEmpty(this.getAccountNumber()),
            isEverythingElse: util.isEmpty(this.getAccountNumber()) && util.isEmpty(this.model.get('REPORTID')),
            accountname: this.getAccountName(),
            accountnum: this.getAccountNumber(),
            bankcode: this.model.get('BANKCODE'),
            currencycode: this.model.get('CURRENCYCODE'),
            reportid: this.model.get('REPORTID'),
            reportdescription: this.model.get('DESCRIPTION'),
            reportfiletype: this.model.get('REPORTORDOWNLOAD'),
            estatementaccount: this.model.get('EDESCRIPTION'),
        };
    },

    getAccountName() {
        return util.unescape(util.isEmpty(this.model.get('CLIENTACCOUNTNAME')) ? this.model.get('ACCOUNTNAME') : this.model.get('CLIENTACCOUNTNAME'));
    },

    getAccountNumber() {
        if (this.model.get('ACCOUNTNUM')) { return util.isEmpty(this.model.get('ACCOUNTNUM_DISP')) ? this.model.get('ACCOUNTNUM') : this.model.get('ACCOUNTNUM_DISP'); }

        return util.isEmpty(this.model.get('ACCOUNTNUMBER_DISP')) ? this.model.get('ACCOUNTNUMBER') : this.model.get('ACCOUNTNUMBER_DISP');
    },
});
