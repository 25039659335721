import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import CONSTANTS from 'common/dynamicPages/api/constants';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import EditAmountsView from 'common/dynamicPages/views/editableOptions/editAmountsView';
import EditHoldView from 'common/dynamicPages/views/editableOptions/editHoldView';
import ListView from 'common/dynamicPages/views/workflow/list';
import PrintExportUtil from 'common/util/printExportUtil';
import { appBus } from '@glu/core';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import PrintChecksModalView from 'pcmMdf/app/payments/views/printChecks'; // eslint-disable-line
import dialog from '@glu/dialog';
import { navigateTo } from 'common/util/navigationUtils';
import store from 'system/utilities/cache';
import BulkUpdate from '../collections/bulkUpdate';
import template from './batchChildGrid.hbs';

const checkRoutes = {
    viewRoute: '/PAYMENTS/viewCheck',
};

const BatchChildGrid = Layout.extend({
    template,
    loadingTemplate,

    initialize(options) {
        this.isBatchChildGrid = true;
        this.gridApi = options.gridApi;
        this.context = options.context;
        this.gridContext = this.context.actionData || {
            // templates has productcode as 'PAY' in insert mode
            productCode: 'USACH',

            functionCode: (this.context.functionCode === 'INST') ? 'BATCH' : 'BHTMPL',
            typeCode: this.context.serviceNameTypeCode || this.context.typeCode,
            subType: this.context.subType,
        };
        this.enableSavedViews = this.parentState !== 'reverse';
        this.disableSavedViewsSave = true;
        this.disableSavedViewsDefault = true;
        this.parentState = options.state || 'INSERT';
        this.isConfidential = (options.model) ? (options.model.get('NoConfidentialAccess') === 'true') : false;
        this.confidentialMessage = (options.model) ? options.model.get('ConfidentialMessage') : '';
        this.batchSeqNum = options.batchSeqNum;
        // if state is only for viewing
        this.viewMode = (this.parentState === 'view');
        this.parentState = (this.parentState === 'view') ? 'select' : this.parentState;

        this.parentKey = options.parentKey || {};
        this.selector = options.selector || 'rowSelector';
        if (options.overrideChildFunctionCode) {
            this.context.functionCode = options.overrideChildFunctionCode;
        }
        this.lockedFields = options.lockedFields;

        // in import view (file import history) disable drill downs as payment details are
        // presented in a modal
        this.disableDrillDown = options.importView;

        // hide the alert-region in some cases
        this.hideAlertRegion = options.hideAlertRegion;

        // determine if the grid is editable
        this.gridIsEditable = ((this.context.subType === 'NACHA' || this.context.subType === 'CAEFT') && this.parentState.toUpperCase() !== 'SELECT');

        // intial editable states
        this.editState = {
            hold: false,
            amount: false,
        };

        // Init Bulk Update system:
        this.bulkUpdate = new BulkUpdate({});

        // initial errors, set to an empty array initially
        this.errors = [];
        ListView.prototype.setListViewConfig
            .call(this, util.extend({}, this.gridContext, { lvcAlwaysReset: true }));

        if (!this.isConfidential) {
            this.setupBatchGridView();
        } else {
            // since setupBatchGridView sets hasLoaded,
            // we have to set it here for confidential payments
            this.hasLoaded = true;
        }

        /* since there may be multiple payments of the same batch type,
           reset to first page & clear filter
         */
        this.lvc.searchRefresh();
        this.suppressSavedViews = true;

        if (mobileUtil.isMobileGridEnabled() && this.model) {
            this.listenTo(
                this.model,
                'change:REVERSESELECTION',
                this.handleMobileGridReverseModelChange,
            );
        }
    },

    regions: {
        alertRegion: '[data-region-child="alertRegion"]',
        editHoldRegion: '[data-region-child="editHoldRegion"]',
        editAmountsRegion: '[data-region-child="editAmountsRegion"]',
        batchChildRegion: '[data-region-child="batchChildRegion"]',
    },

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
        'click #batch-child-edit-toggle': 'toggleEditMode',
    },

    /**
     * Mobile grid uses a custom SelectorCellDisplay component for row selections
     * in things like Lookup modals and Bene Reverse selection. This handler
     * is leveraged by that React component to make sure necessary child form
     * network calls are made on row selection.
     */
    handleSelectorCellChange({
        checkedAll, fromSelectAll, rowData, isChecked,
    }) {
        let bulkUpdateRequest;

        if (fromSelectAll) {
            bulkUpdateRequest = {
                rows: null,
                action: 'bulkUpdate',
                actionType: 'REVERSE',
                value: checkedAll ? 1 : 0,
            };
            if (!this.bulkUpdateInProgress) {
                this.bulkUpdateInProgress = true;
                return this.makeBulkUpdateRequest(bulkUpdateRequest)
                    .then(() => {
                        this.bulkUpdateInProgress = false;
                    });
            }
        } else {
            const thisRowIndex = this.gridView.wrapper.rows.models.findIndex(row => (
                row.cid === rowData.cid
            ));
            if (thisRowIndex !== -1) {
                const thisModel = this.gridView.wrapper.rows.models[thisRowIndex];
                thisModel.gridRowSelected = isChecked;
                if (!this.individualReverseRequestInProgress) {
                    this.individualReverseRequestInProgress = true;
                    return this.createIndividualReverseRequest(rowData.model.cid)
                        .then(() => {
                            this.individualReverseRequestInProgress = false;
                        });
                }
            }
        }
        return Promise.resolve();
    },

    handleMobileGridReverseModelChange() {
        /*
         * batchChildGrid is instantiated twice with MG because of it using Layout
         * and not being a standard ListView extension. Once by Backbone, and once as MG view
         * Make sure this is a MG instance before dealing with any of this.
         */
        if (this.selectedMobileRows) {
            const shouldSelectAll = !!(this.model.get('REVERSESELECTION') !== 'Individual');
            this.gridView.wrapper.b.selectAndProtectAll = shouldSelectAll;
            this.trigger('mobilegrid:updateSelectorCellHeaderDisplay', { shouldSelectAll });
            this.handleSelectorCellChange({
                fromSelectAll: true,
                checkedAll: shouldSelectAll,
            });
        }
    },

    print() {
        if (this.gridView.context.childActionContext !== null) {
            this.gridView.context.typeCode =
                this.gridView.context.childActionContext.typeCode;
            this.gridView.context.functionCode =
                this.gridView.context.childActionContext.functionCode;
            this.gridView.context.productCode =
                this.gridView.context.childActionContext.productCode;
            this.gridView.context.actionMode = 'SELECT';
        }
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            format: 'PDF',
            serviceName: this.gridView.context.serviceName,
            searchFields: [{
                fieldName: 'BATCHTNUM',
                operator: 'IN',
                fieldValue: [this.model.id],
                dataType: 'text',
            }],
        };

        PrintExportUtil.print(options);
    },

    export() {
        if (this.gridView.context.childActionContext !== null) {
            this.gridView.context.typeCode =
                this.gridView.context.childActionContext.typeCode;
            this.gridView.context.functionCode =
                this.gridView.context.childActionContext.functionCode;
            this.gridView.context.productCode =
                this.gridView.context.childActionContext.productCode;
            this.gridView.context.actionMode = 'SELECT';
        }
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            format: 'CSV',
            serviceName: this.gridView.context.serviceName,
            searchFields: [{
                fieldName: 'BATCHTNUM',
                operator: 'IN',
                fieldValue: [this.model.id],
                dataType: 'text',
            }],
        };

        PrintExportUtil.export(options);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (!this.isConfidential) {
                // We run setupBatchGrid in init, so gridView SHOULD be created already by now
                if (!this.gridView) {
                    this.setupBatchGridView();
                }
                if (mobileUtil.isMobileGridEnabled()) {
                    let MobileList = BatchChildGrid;
                    const mobileList = configureMobileInterface(MobileList, {
                        bulkActions: [
                            {
                                label: 'common.delete',
                                handleEntitlementMethodName: 'hasDeleteChildEntitlement',
                                handlerMethodName: 'deleteChild',
                            },
                        ],
                        showSelector: !!(this.parentState === 'reverse'),
                    });
                    MobileList = MobileList.extend({
                        ...mobileList,
                    });
                    const mobileGridView = new MobileList({
                        ...this.options,
                        ...this.gridViewOptions,
                        renderMobileGridUsingTemplate: false,
                        prebuiltOptions: true,
                        gridView: this.gridView,
                        skipEntitlements: true,
                    });
                    this.gridView.wrapper.refreshGridData = (...args) => {
                        mobileGridView.trigger('mobilegridShouldRefresh', ...args);
                        return Promise.resolve();
                    };

                    if (this.gridViewOptions.selectAndProtectAll) {
                        this.handleSelectorCellChange({
                            fromSelectAll: true,
                            checkedAll: true,
                        }).then(() => {
                            this.batchChildRegion.show(mobileGridView);
                        });
                    } else {
                        this.batchChildRegion.show(mobileGridView);
                    }
                } else {
                    this.batchChildRegion.show(this.gridView);
                }

                if (this.parentState === 'reverse') {
                    this.setupReverseOptions();
                }
            } else {
                const ConfidentialView = Layout.extend({
                    template: util.template(`<strong>${this.confidentialMessage}</strong>`),
                });
                this.batchChildRegion.show(new ConfidentialView({}));
            }
        } else {
            this.listenTo(this.appBus, 'nachaChild.update.error', this.actionError);
            this.listenTo(this.appBus, 'nachaChild:update:success', this.actionSuccess);
        }
    },

    hasDeleteChildEntitlement() {
        return true;
    },

    // PCM-5290
    gridRowActionBefore(options) {
        if (options && options.action === 'image') {
            const { model } = options;
            const batchTnum = model.get('BATCHTNUM');
            const tnum = model.get('TNUM');

            store.set('checkTransactionInfo', options.model.toJSON());
            // PCM
            store.set('entitlementsInfo', this.entitlements);
            navigateTo(`${checkRoutes
                .viewRoute}/${batchTnum}/${tnum}/PRCHK/checkPreview/`);
        }
    },

    /**
     * @method shouldShowBatchControls
     * Determines if the batch edit hold and batch edit amount controls should
     * be present
     * @return {object} a configuration object to indicate whether or not to
     * display batch controls
     */
    shouldShowBatchControls() {
        let functionCode = this.context
            && this.context.actionData
            && this.context.actionData.functionCode;

        if (!functionCode && this.context) {
            ({ functionCode } = this.context);
        }
        // Special conditions for preventing Edit Amount.
        const preventEditAmount = this.context.serviceName?.includes('TaxPayments');
        // Special conditions for preventing Edit Hold:
        const preventEditHold = this.context.actionMode === 'repair';

        // exit function early if this is not a payment
        if (functionCode !== 'INST' && functionCode !== 'BATCH') {
            return {
                showEditAmount: !preventEditAmount,
                showEditHold: !preventEditHold,
            };
        }

        let lockedFields = [];

        if (this.model && this.model.has('LOCKED_FIELDS')) {
            lockedFields = this.model.get('LOCKED_FIELDS').split(',');
        }

        // Validate locked fields against these for Edit Amount
        const amountFields = [
            'AMOUNT',
            'AMOUNT1',
            'AMOUNT2',
            'AMOUNT3',
            'CREDIT_AMOUNT',
            'DEBIT_AMOUNT',
        ];

        return {
            // Show Amount if none of the locked fields are amount fields.
            showEditAmount: !preventEditAmount
                && lockedFields.every(lockedField => !amountFields.includes(lockedField)),
            // Show Hold if HOLDFLAG is not one of the locked fields
            showEditHold: !preventEditHold && !lockedFields.includes('HOLDFLAG'),
        };
    },

    /**
     * Used to control certain beneficiary content.
     * @returns {boolean}
     */
    notViewOrReverse() {
        return !(this.viewMode || (this.context.actionMode && this.context.actionMode === 'reverse'));
    },

    /**
     *  @method templateHelpers
     *  Sets template helpers for the editableGrid
     *  allowEditAmounts: Currently hides the 'Edit Amounts' functionality for Tax Payments
     *  allowEditHolds: Currently hides the 'Edit Holds' functionality when action is repair
     */
    templateHelpers() {
        const self = this;
        return {
            notView: this.notViewOrReverse(),

            notNFIorPassThru() {
                if ((self.context.actionData && (self.context.actionData.typeCode === 'BDACHNFI' || self.context.actionData.typeCode === 'PASSTHRU'))
                    || (self.context.typeCode && (self.context.typeCode === 'BDACHNFI' || self.context.typeCode === 'PASSTHRU'))
                    || ((self.model && self.model.get('TYPE') === 'BDACHNFI') || (self.model && self.model.get('TYPE') === 'PASSTHRU'))) {
                    return false;
                }

                return true;
            },

            isConfidential: this.isConfidential,
            confidentialMessage: this.confidentialMessage,
            isMobileGridEnabled: () => mobileUtil.isMobileGridEnabled(),
            hideAlertRegion: this.hideAlertRegion,
        };
    },

    /**
     * @method setupBatchGridView
     * set the batch grid view for this component early, so that the view can be
     * reattached
     * Needed early so that the metadriven form can apply listeners before data
     * gets retrieved
     * Is a very different use-case (will need more adjustment)
     */
    setupBatchGridView() {
        /**
         * NOTE (will need to further address when given time to assess metadriven
         * grid render approach)
         * "hasLoaded" is a flag used by Glu ViewMixin to decide
         * when to read data values (regions, etc) and cause renders when
         * we load the data needed in them. In this case, we need this view
         * to have its regions ready for when Metadriven forms decides to
         * reattach their respective child views (editable grids)
         */
        this.hasLoaded = true;

        let lockedFields;
        if (this.model && this.model.has('LOCKED_FIELDS')) {
            lockedFields = this.model.get('LOCKED_FIELDS').split(',');
        } else if (this.lockedFields) {
            ({ lockedFields } = this);
        }
        this.gridViewOptions = {
            enableRowContextButtonCallbacks: true,
            enableSavedViews: this.enableSavedViews,
            canSave: false,
            canSetDefault: false,
            hideButtons: true,
            isInquiry: false,
            isChild: true,
            editableChildGrid: this.gridIsEditable,
            draggable: false,
            context: this.context,
            parentState: this.parentState,
            parentKey: this.parentKey,
            selector: this.selector,
            selectAndProtectAll: this.model && this.model.get('REVERSESELECTION') ? this.model.get('REVERSESELECTION') !== 'Individual' : false,
            lockedFields,
            disableDrillDown: this.disableDrillDown,
            lvc: this.lvc,
            batchSeqNum: this.batchSeqNum,
        };
        this.gridView = this.gridApi.createServiceGridView(this.gridViewOptions);

        // load view requirements to create the loadout promise callback needed to
        // display the grid when metadriven form demands
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        if (this.gridIsEditable) {
            this.listenTo(this.gridView, 'grid:available', () => {
                this.listenTo(this.gridView.grid, 'grid:contentRendered', this.renderEditableOptions);
            });
        }
        // Do not setup listeners for mobile grid
        if (!mobileUtil.isMobileGridEnabled()) {
            // listen to grid:available for filter and column width changes
            ListView.prototype.setupGridAvailableListener.call(this);
        }

        // PCM-5290
        if (this.context.actionData && this.context.actionData.typeCode === 'BPRCHK') {
            if (this.gridView) {
                this.listenTo(this.gridView, 'rowAction', this.gridRowActionBefore);
            }
        }
    },

    destroyAndRefresh(childArray) {
        const self = this;
        const data = {
            grids: [{
                name: childArray[0].childGridKey,
                items: [],
            }],
        };

        for (let i = 0; i < childArray.length; i += 1) {
            data.grids[0].items.push({
                item: [{
                    name: childArray[i].childSequenceKey.toUpperCase(),
                    value: childArray[i].childNumber,
                },
                {
                    name: 'BATCHSEQNUM',
                    value: childArray[i].get('BATCHSEQNUM'),
                },
                ],
            });
        }

        const deleteService = services.generateUrl(childArray[0].context.serviceName)
            + CONSTANTS.URL_DELETECHILDREN_ACTION;

        http.post(deleteService, data, () => {
            self.renderMessage(locale.get('common.delete.count.success', childArray.length));
            self.gridView.refreshGridData(false, true);
        }, () => {
            self.renderMessage(locale.get('common.delete.failed'));
            self.gridView.refreshGridData(false, true);
        });
    },

    gridRowAction(options) {
        if (options.action.toUpperCase() === 'SELECT') {
            this.trigger('viewChild', options.model);
        }

        if (options.action.toUpperCase() === 'MODIFY') {
            this.trigger('modifyChild', options.model);
        }

        if (options.action.toUpperCase() === 'DELETE') {
            this.destroyAndRefresh([options.model]);
        }

        if (options.action.toUpperCase() === 'REPRINTCHECK') { // PCM
            this.reprintCheck(options.model);
        }
    },


    addChild() {
        this.trigger('addChild');
    },

    reprintCheck(model) {
        const self = this;
        const { entryMethod } = self.options.context.actionContext;
        const printKeyValuesPairArray = [];
        const keyValuePair = {
            tnum: model.get('TNUM'),
            status: model.get('STATUS'),
            PTXId: model.get('PTX_CHECK_ID'),
            typeCode: model.context.actionData.typeCode,
        };

        printKeyValuesPairArray.push(keyValuePair);

        this.printView = new PrintChecksModalView({
            isModal: true,
            isReprintBatch: false,
            isReprintCheck: true,
            paymentListModel: self,
            isActionTriggeredFromPaymentsList: true,
            checkBatchesData: printKeyValuesPairArray,
            checkModel: model,
            entryMethod,
        });
        dialog.open(self.printView);
    },

    deleteChild() {
        // PCM-1220
        const callback = (ok) => {
            if (ok) {
                this.deleteChildAfterConfirmation();
            }
        };

        dialog.confirm(
            locale.get('tableMaintenance.dialog.confirm.items.delete'),
            locale.get('tableMaintenance.dialog.confirm.title.delete'),
            callback,
        );
        // PCM-1220
    },

    // PCM-5511
    deleteChildAfterConfirmation() {
        const ar = this.gridView.grid.getSelectedRows();

        if (ar.length > 0) {
            const delArray = [];
            for (let i = 0; i < ar.length; i += 1) {
                delArray.push(this.gridView.wrapper.rows.get(ar[i]));
            }
            this.destroyAndRefresh(delArray);
        }
    },

    /**
     * @method renderMessage
     * @param {string} message
     * @param {string} type
     * renders alerts as a result of attempted batch updates to the bene grid.
     */
    renderMessage(message, type) {
        const alertType = type || 'info';
        if (!this.alertRegion) {
            return;
        }
        if (message) {
            this.alertRegion.show(
                alert[alertType](message),
                {
                    canDismiss: true,
                },
            );
        } else {
            this.alertRegion.reset();
        }
    },

    /**
     * Get an actual Boolean from the Bulk Update Amount
     * @return {boolean}
     */
    isBulkUpdateEnabled() {
        return applicationConfigParams.getValue('ACH', 'BULK_AMOUNT_UPDATE') === '1';
    },

    /**
     *  Renders all editable grid dropdowns
     *  Current:
     *      - Amounts
     *      - Hold
     */
    renderEditableOptions() {
        const { showEditAmount, showEditHold } = this.shouldShowBatchControls();
        const editAmountsRegion = this.gridView?.grid?.headerView?.regionManager?.get('editAmountsRegion');
        const editHoldRegion = this.gridView?.grid?.headerView?.regionManager?.get('editHoldRegion');
        if (showEditAmount && editAmountsRegion && this.notViewOrReverse()) {
            // Render Edit Amounts Menu
            const editAmountsOptions = new EditAmountsView({
                bulkUpdateEnabled: this.isBulkUpdateEnabled(),
                editableGridView: this.gridView,
                manualEditToggle: this.enableManualEditAmount.bind(this),
            });
            editAmountsRegion.show(editAmountsOptions);

            // Listen for EditAmounts Menu sending update requests
            this.listenTo(editAmountsOptions, 'bulkUpdateRequest', this.makeBulkUpdateRequest);
        }

        if (showEditHold && editHoldRegion && this.notViewOrReverse()) {
            // Render Edit Hold Menu
            const editHoldView = new EditHoldView({
                editableGridView: this.gridView,
                manualEditToggle: this.enableManualEditHold.bind(this),
            });
            editHoldRegion.show(editHoldView);

            // Listen for EditHold Menu sending update requests
            this.listenTo(editHoldView, 'bulkUpdateRequest', this.makeBulkUpdateRequest);
        }

        // Point to our current grid
        this.bulkUpdate.useContext(this.context);
    },

    /**
     *  @method setupReverseOptions
     *  Sets up the listener to perform the bulk reverse update when needed.
     */
    setupReverseOptions() {
        this.listenTo(this.gridView, 'doBulkReverse', function () {
            let bulkUpdateRequest;
            if (!this.initBulkReverseAlreadyDone) {
                bulkUpdateRequest = {
                    rows: null,
                    action: 'bulkUpdate',
                    actionType: 'REVERSE',
                    value: (this.gridView.wrapper
                        && this.gridView.wrapper.b
                        && this.gridView.wrapper.b.selectAndProtectAll) ? 1 : 0,
                };
                this.makeBulkUpdateRequest(bulkUpdateRequest);
                this.initBulkReverseAlreadyDone = true;
            }
        });
    },

    /**
     *  @method createIndividualReverseRequest
     *  @param {string} cid - Id of row object just selected by the user
     *  Takes in a selected/de-selected row and creates a reverse
     *  update request for it.
     */
    createIndividualReverseRequest(cid) {
        const [row] = this.gridView.wrapper.rows.models.filter(rows => rows.cid === cid);

        let rowNum;
        let bulkUpdateRequest;

        if (row) {
            rowNum = {
                name: 'WORKSEQUENCENUM',
                value: row.get('WORKSEQNUMBER'),
            };

            bulkUpdateRequest = {
                rows: [rowNum],
                action: 'bulkUpdate',
                actionType: 'REVERSE',
                value: row.gridRowSelected === true ? 1 : 0,
            };
            /*
            * NH-91751 Input change events race condition on IE, where row model property
            * (not attribute) 'gridRowSelected' hasn't been set yet
            */
            if (row.gridRowSelected === undefined) {
                appBus.once('grid:selectRow', () => {
                    bulkUpdateRequest.value = row.gridRowSelected === true ? 1 : 0;
                    this.makeBulkUpdateRequest(bulkUpdateRequest);
                });
            } else {
                return this.makeBulkUpdateRequest(bulkUpdateRequest);
            }
        }
        return Promise.resolve();
    },

    /**
     *  @method makeBulkUpdateRequest
     *  @param {object} req - bulk update request object
     *  Send in a request object used to make bulkUpdate
     *  on success: will refresh the grid
     *  on fail: show error message
     */
    makeBulkUpdateRequest(req) {
        let enableEditMode = false;

        if (req.actionType === 'CLEARAMOUNT') {
            enableEditMode = true;
        }

        this.bulkUpdate.useGrid(this.gridView.grid);
        this.bulkUpdate.useBatchSeqNum(this.batchSeqNum);
        this.bulkUpdate.useContext(this.context);
        // if grid collection is empty, lets say due to filtering, then dont make call
        if (
            this.gridView.grid?.collection?.totalCount > 0
            || (mobileUtil.isMobileGridEnabled() && this.gridView.wrapper.rows.length > 0)
        ) {
            return this.bulkUpdate
                .request(req)
                .then(
                    this.finishBulkUpdate.bind(this, enableEditMode),
                    this.reportBulkUpdateError.bind(this),
                );
        }
        return Promise.resolve();
    },

    /**
     * @method finishBulkUpdate
     * @param {boolean} enableEditMode
     * @param {object} response
     * Finalize successful bulk update and refresh the grid.
     */
    finishBulkUpdate(enableEditMode, response) {
        const hasNegativeRow = response.errorCode === CONSTANTS.BULK_UPDATE.CODES.NEGATIVE_AMOUNT;

        // there are no errors present
        if (response.errorCode === 0) {
            this.renderMessage(false);
        }

        // set grid to edit amounts.
        if (enableEditMode || hasNegativeRow) {
            this.enableManualEditAmount(true);
        }

        // the bulk update has created negative amounts, display an alert and sort
        // the grid to show those first
        if (hasNegativeRow) {
            this.reportBulkUpdateError(response);
            this.sortGrid('asc', 'AMOUNT', 1);
        } else if (mobileUtil.isMobileGridEnabled()) {
            this.gridView.refreshGridData();
        } else {
            // even if there are no errors, refresh the grid
            // maintain the current grid view (rowsPerPage) when updating
            this.gridView.refreshGridData(this.gridView.grid.pagerView, true);
        }
    },

    /**
     * @method sortGrid
     * @param {string} sortOrder
     * @param {string} sortKey
     * @param {number} currentPage
     * Sorts the grid according to the passed in parameters
     */
    sortGrid(sortOrder, sortKey, currentPage) {
        this.gridView.wrapper.sortKey = sortKey;
        this.gridView.wrapper.sortOrder = sortOrder;
        this.gridView.wrapper.currentPage = currentPage;

        // maintain the current grid view (rowsPerPage) when updating
        this.gridView.refreshGridData(this.gridView.grid.pagerView, true);
    },

    /**
     * @method reportBulkUpdateError
     * @param {object} errMsg
     * Deal with a failed bulk update
     */
    reportBulkUpdateError(errMsg) {
        const localizedErrors = errMsg.message.map(locale.get.bind(locale));
        this.renderMessage(localizedErrors, 'negative');
    },

    /*
     *  =========================
     *  EDIT AMOUNT MODIFICATIONS
     *  =========================
     */

    /**
     *  @method enableManualEditAmount
     *  @param {boolean} enable
     *  Toggle on showing the inputs for amount cells to allow for manual edit
     */
    enableManualEditAmount(enable) {
        this.editState.amount = enable || !this.editState.amount;
        this.$el.toggleClass('enable-edit-amount', this.editState.amount);
    },

    /*
     *  =========================
     *  EDIT HOLD MODIFICATIONS
     *  =========================
     */

    /**
     *  @method enableManualEditHold
     *  @param {boolean} enable
     *  Toggle on showing the checkboxes for hold cells to allow for manual edit
     */
    enableManualEditHold(enable) {
        this.editState.hold = enable || !this.editState.hold;
        this.$el.toggleClass('enable-edit-hold', this.editState.hold);
    },
});

export default BatchChildGrid;
