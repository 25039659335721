import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import Dialog from '@glu/dialog';
import services from 'services';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import ContextModel from 'common/dynamicPages/models/context';
import transform from 'common/util/transform';
import actionFunction from 'app/transfers/api/transfersActionFunction';
import constants from 'app/transfers/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import AddTransferDialog from './addTransferDialog';
import template from './listTransferTemplates.hbs';

const TransferTemplatesList = ListView.extend({
    template,

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
        },
    ),

    initialize(options) {
        const superOptions = {
            menuCategory: 'PMTS',
            serviceName: 'template/transfer',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_TEMPLATE_LIST',
            returnRoute: 'PAYMENTS/listTransferTemplates',
            viewId: options.viewId,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender(...args) {
        let alertResponse;

        ListView.prototype.onRender.call(this, args);

        if (this.hasLoadedRequiredData()) {
            if (store.has('listTransferTemplateMessage')) {
                alertResponse = store.get('listTransferTemplateMessage');
                this.renderMessage(alertResponse.action, alertResponse.response);
                store.unset('listTransferTemplateMessage');
            } else if (store.has(`${this.contextKey}-alertMessage`) && store.has(`${this.contextKey}-confirms`)) {
                // for rejects coming from reject dialog
                this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
                store.unset(`${this.contextKey}-alertMessage`);
                store.unset(`${this.contextKey}-confirms`);
            }
            this.listenTo(this.gridView.wrapper.rows, 'sync', ListView.prototype.updateRefreshTimestamp);
        }
    },

    insert() {
        Dialog.open(new AddTransferDialog({
            templateMode: true,
        }));
        return Promise.resolve();
    },

    gridRowModify(opts) {
        this.updateModelManageTemplateDetail(opts);
        if (this.isSingleTransferTemplate(opts.model)) {
            store.set('modifyTransferTemplateModel', opts.model);
            this.navigateTo(`TEMPLATES/modifySingleTransferTemplate/${opts.model.get('TNUM')}`);
        } else if (this.isRecurring(opts.model)) {
            this.navigateTo(`PAYMENTS/modifyRecurring/${opts.model.get('TNUM')}`);
        } else if (!this.isTransferSet(opts.model)) {
            this.navigateTo('TEMPLATES/modifyTransferTemplate');
        } else {
            this.navigateTo(`PAYMENTS/modifyTransferSet/${opts.model.get('CMB_TEMPLATE_CODE')}`);
        }
        return Promise.resolve();
    },

    gridRowSelect(optsParam) {
        const opts = optsParam;
        this.updateModelManageTemplateDetail(opts);
        // When rejected from view screen, the context key is needed for reject dialog
        // to properly return the reject message to the list view.
        opts.model.contextKey = this.contextKey;
        if (this.isSingleTransferTemplate(opts.model)) {
            this.navigateTo(`TEMPLATES/viewSingleTransferTemplate/${opts.model.get('TNUM')}`);
        } else if (this.isRecurring(opts.model)) {
            this.navigateTo(`PAYMENTS/viewRecurring/${opts.model.get('TNUM')}`);
        } else if (!this.isTransferSet(opts.model)) {
            this.navigateTo('TEMPLATES/viewTransferTemplate');
        } else {
            store.set('viewTransferTemplateUserGroup', opts.model.get('USERGROUP'));
            this.navigateTo(`PAYMENTS/viewTransferSet/${opts.model.get('CMB_TEMPLATE_CODE')}`);
        }
        return Promise.resolve();
    },

    gridRowApprove(opts) {
        const self = this;
        const { model } = opts;
        let url;
        let data;

        if (!this.isTransferSet(model)) {
            url = 'payment/transfer/APPROVE';
            data = this.getGridRowDataForSingleTemplateSubmit(model);
        } else {
            url = 'template/listView/corp/approve';
            data = this.getGridRowDataForTransferSetSubmit(model);
        }

        return new Promise((resolve, reject) => {
            http.post(services.generateUrl(url), data, (result) => {
                self.gridView.refreshGridData();
                self.renderMessage('approve', result);
                resolve({ result });
            }, (result) => {
                self.renderMessage('approve', result);
                reject({ result });
            });
        });
    },

    gridRowUnapprove(opts) {
        const self = this;
        const { model } = opts;
        let url;
        let data;

        if (!this.isTransferSet(model)) {
            url = 'payment/transfer/UNAPPROVE';
            data = this.getGridRowDataForSingleTemplateSubmit(model);
        } else {
            url = 'template/listView/corp/unapprove';
            data = this.getGridRowDataForTransferSetSubmit(model);
        }

        return new Promise((resolve, reject) => {
            http.post(services.generateUrl(url), data, (result) => {
                self.gridView.refreshGridData();
                self.renderMessage('unapprove', result);
                resolve({ result });
            }, (result) => {
                self.renderMessage('unapprove', result);
                reject({ result });
            });
        });
    },

    gridRowDelete(opts) {
        const self = this;
        const { model } = opts;
        let url;
        let data;

        if (!this.isTransferSet(model)) {
            url = 'payment/transfer/DELETE';
            data = this.getGridRowDataForSingleTemplateSubmit(model);
        } else {
            url = 'template/listView/corp/delete';
            data = this.getGridRowDataForTransferSetSubmit(model);
        }

        return new Promise((resolve, reject) => {
            http.post(services.generateUrl(url), data, (result) => {
                self.gridView.refreshGridData();
                self.renderMessage('delete', result);
                resolve({ result });
            }, (result) => {
                self.renderMessage('delete', result);
                reject({ result });
            });
        });
    },

    /**
     * @method gridRowRestore
     * @param {object} opts -  data from grid row
     * - function to navigate to restore template screen on select of Restore
     * action from context menu
     */

    gridRowRestore(opts) {
        this.updateModelManageTemplateDetail(opts);
        if (this.isSingleTransferTemplate(opts.model)) {
            this.navigateTo(`TEMPLATES/restoreSingleTransferTemplate/${opts.model.get('TNUM')}`);
        } else if (this.isRecurring(opts.model)) {
            this.navigateTo(`PAYMENTS/restoreRecurring/${opts.model.get('TNUM')}`);
        } else if (!this.isTransferSet(opts.model)) {
            this.navigateTo('TEMPLATES/restoreTransferTemplate');
        } else {
            this.navigateTo(`PAYMENTS/restoreTransferSet/${opts.model.get('CMB_TEMPLATE_CODE')}`);
        }
        return Promise.resolve();
    },

    gridTemplateFromPayment(options) {
        if (options.model.get('TRANSFERSET') === '0') {
            this.navigateTo(`TEMPLATES/addSingleTemplateFromTemplate/${options.model.get('TNUM')}`);
        } else {
            const contextModel = new ContextModel({
                contextDef: options.model.context,
            });
            /* payment_transfer */
            actionFunction.makeTemplate(options.model, contextModel.getContextKey());
            this.navigateTo('TEMPLATES/addTransferTemplateFromTemplate');
        }
        return Promise.resolve();
    },

    gridPaymentFromTemplate(options) {
        if (options.model.get('TRANSFERSET') === '0') {
            this.navigateTo(`PAYMENTS/addSingleTransferByTemplate/${options.model.get('TNUM')}`);
        } else {
            const contextModel = new ContextModel({
                contextDef: options.model.context,
            });
            /* payment_transfer */
            actionFunction.makePayment(options.model, contextModel.getContextKey());
            this.navigateTo('PAYMENTS/addTransferPaymentFromTemplate');
        }
        return Promise.resolve();
    },

    gridRowSchedule(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('PAYMENTS/scheduleTransfer');
        return Promise.resolve();
    },

    updateModelManageTemplateDetail(opts) {
        store.set(`${this.contextKey}-actionModel`, opts.model);
    },

    isTransferSet(model) {
        return (model.get(constants.TRANSFERSET) !== '0');
    },

    isSingleTransferTemplate(model) {
        return model.get(constants.TRANSFERTEMPLATETYPE)
            === locale.get(constants.SINGLETRANSFER);
    },

    isRecurring(model) {
        return (model.get('ISRECURRING') === '1');
    },

    getGridRowDataForSingleTemplateSubmit(model) {
        let data = transform.hashToPairs({
            TNUM: model.get('TNUM'),
            ENTRYMETHOD: model.get('ENTRYMETHOD'),
            FUNCTIONCODE: 'TMPL',
            UPDATECOUNT__: model.get('UPDATECOUNT__'),
        });
        data = {
            item: data,
        };
        return data;
    },

    getGridRowDataForTransferSetSubmit(model) {
        const data = {
            items: [{
                item: transform.hashToPairs({
                    FUNCTIONCODE: model.get('FUNCTION'),
                    TYPECODE: model.get('TYPE'),
                    PRODUCTCODE: model.get('PRODUCT'),
                    TNUM: model.get('TNUM'),
                    ENTRYMETHOD: model.get('ENTRYMETHOD'),
                    UPDATECOUNT__: model.get('UPDATECOUNT__'),
                }),
            }],
        };
        this.populateCustomKeys(data.items[0], model);
        return data;
    },

    /**
     * @method buildExportModel
     * @param {string} format
     * @returns {model} this.exportModel
     * Method to build the export model for the Transfer list view
     */
    buildExportModel(format) {
        const rows = this.gridView.grid.getSelectedRowModels();
        let selectedTnums = [];

        ListView.prototype.buildExportModel.call(this, format || 'CSV');

        // create the searchfields if rows are selected
        if (rows.length) {
            selectedTnums = rows.map(model => model.get('TNUM'));

            this.exportModel.searchFields = [{
                fieldName: 'TNUM',
                operator: 'IN',
                fieldValue: selectedTnums,
                dataType: 'number',
            }];
        }
        return this.exportModel;
    },

    populateCustomKeys(item, model) {
        if (model && model.get('TRANSFERSET') === '1') {
            util.each(item.item, (currentItemParam) => {
                const currentItem = currentItemParam;
                if (currentItem.name === 'FUNCTIONCODE') {
                    currentItem.value = 'TMPLSET';
                }
                if (currentItem.name === 'TYPECODE') {
                    currentItem.value = 'TRANSFER';
                }
            });
        }
        return item;
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            hasSummarySelection: false,
        };
    },
});

let list = TransferTemplatesList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        apiMessageContext: [
            'listTransferMessage',
            'listTransferTemplateMessage',
        ],
        insertActions: [
            {
                label: 'template.listView_.button_insert',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: [
            {
                label: 'button.approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'button.unapprove',
                entitlement: 'UNAPPROVE',
            },
            {
                label: 'button.delete',
                entitlement: 'DELETE',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'TRANSFERS_TEMPLATE_LIST',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
