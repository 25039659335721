import Collection from '@glu/core/src/collection';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    parse(jsonData) {
        const LBXMAXDAYS = transform.hashToPairs(jsonData[0]);
        const LBXDAYSAVAIL = transform.hashToPairs(jsonData[1]);
        return {
            LBXMAXDAYS: LBXMAXDAYS[1].value,
            LBXDAYSAVAIL: LBXDAYSAVAIL[1].value,
        };
    },

    sync(method, model, options) {
        const url = services.generateUrl('/lockbox/getLockboxDataRetentionDetails');
        const data = {};

        http.post(url, data, (result) => {
            options.success(result.item);
        }, (result) => {
            options.error(result);
        });
    },
});
