import { Layout } from '@glu/core';
import locale from '@glu/locale';
import FlexDropdown from '@glu/flex-dropdown';
import tmpl from './virtualTraySelection.hbs';

export default Layout.extend({
    template: tmpl,
    tagName: 'td',
    className: 'virtual-tray-cell',

    collectionEvents: {
        sync: 'render',
    },

    regions: {
        regionDropdownTray: '#region-dropdown-tray',
    },

    initialize() {
        this.printerTrays = this.options.printerTrays;
        // this.listenTo(this.printerTrays, 'change:virtualTrayId', this.render());
    },

    onRender() {
        // eslint-disable-next-line max-len
        const data = this.collection.getAvailableData(this.printerTrays.getSelectedTrays(this.model.getVirtualTray()));

        this.dropdown = new FlexDropdown({
            data: data.length ? data : [{ id: '', name: locale.get('none') }],
            defaultSelectMessage: 'test',
            disableMultiButton: true,
            preSelectedIds: [this.model.get('virtualTrayId')],
        });
        this.regionDropdownTray.show(this.dropdown);
        this.listenTo(
            this.dropdown,
            'selectionChanged',
            selection => this.model.set('virtualTrayId', selection[0].id),
        );
    },
});
