import { appBus } from '@glu/core';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import LoanConstants from 'app/balanceAndTransaction/constants';
import SmbTileGridView from 'app/smbPayments/views/payments/billsGrid/tileGridView';
import SmbPaymentsListView from 'app/smbPayments/views/paymentAndTemplateView';
import ManageWorkspaceView from 'app/smbPayments/views/managePayments';
import AccountCenterWorkspaceView from 'app/smbPayments/views/accounts/accountCenter';
import WorkspaceTrnsfrView from 'app/smbPayments/views/accounts/grid/workspace';
import SmbStopPaymentsListView from 'app/smbPayments/views/stopPaymentGrid';
import ModifyPayment from 'app/smbPayments/views/payments/modifyPayment';
import ViewPayment from 'app/smbPayments/views/payments/viewPayment';
import ModifyTemplate from 'app/smbPayments/views/payments/modifyTemplate';
import AccountCenterListView from 'app/smbPayments/views/accounts/balances/transfer/accountsListView';
import EmployeesListView from 'app/smbPayments/views/employees/employeeView';
import ModifyEmpPayment from 'app/smbPayments/views/employees/modifyEmpPayment';
import CopyPaymentFromEmpPayment from 'app/smbPayments/views/employees/copyPaymentFromEmpPayment';
import CopyPaymentFromReimbursement from 'app/smbPayments/views/employees/copyPaymentFromReimbursement';
import CopyTax from 'app/smbPayments/views/payments/copyTax';
import ViewEmpPayment from 'app/smbPayments/views/employees/viewEmpPayment';
import AddReimburseExpenses from 'app/smbPayments/views/employees/addReimburseExpenses';
import ModifyReimburseExpenses from 'app/smbPayments/views/employees/modifyReimburseExpenses';
import ViewReimburseExpenses from 'app/smbPayments/views/employees/viewReimburseExpenses';
import TransfersListView from 'app/smbPayments/views/transfers/grid/transfersGrid';
import DepositAccountsTransactionListView from 'app/smbPayments/views/accounts/balances/depositAccTransListView';
import LoanAccountsTransactionListView from 'app/smbPayments/views/accounts/balances/loanAccTransListView';
import TransactionDetailView from 'app/smbPayments/views/accounts/balances/depositAccTransDetails';
import StopCancelCheckView from 'app/checkManagement/views/stopCancelCheckView';
import TieredLoanAccountDetail from 'app/balanceAndTransaction/views/tieredLoans/tieredLoanAccountDetail';
import Account from 'app/balanceAndTransaction/models/account';
import { getLastFragment } from 'common/util/deeplinkUtil';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import StackView from 'common/stack/views/stackView';
import TemplateFromPaymentView from 'app/payments/views/templateFromPayment';
import PaymentFromTemplateView from 'app/payments/views/paymentFromTemplate';
import WorkspaceView from 'common/workspaces/views/workspace';
import SMBOutboundSSOView from 'app/utilities/sso/smbOutboundSSOView';
import { removeViewIdFromRoute } from 'system/utilities/routeHelper';
import viewFactory from './viewFactory';
import Constants from './constants';
import ContentWrapperView from './views/contentWrapper';

export default {
    // see view factory for relevant documentation

    smbAddPayment() {
        const self = this;

        viewFactory.getView(Constants.ADD_PAYMENT_VIEW).then((result) => {
            const contentWrapper = new ContentWrapperView({
                contentView: result,
            });

            self.showPage('', contentWrapper);
        }, (result) => {
            dialog.alert(result);
            appBus.trigger('router:navigate', 'PAYMENTS/smbManagePayments', true);
        });
    },

    smbViewPayment() {
        this.showPage('', new ViewPayment());
    },

    smbModifyPayment() {
        this.showPage('', new ModifyPayment());
    },

    smbModifyTemplate() {
        this.showPage('', new ModifyTemplate());
    },

    smbCopyPaymentAsTemplate() {
        const contextModel = store.get('template_listView_smb-contextOverride');
        this.showPage('Create Template', new TemplateFromPaymentView(util.extend(
            contextModel,
            {
                mode: 'insert',
            },
        )));
    },

    smbCopyTemplateAsPayment() {
        const contextModel = store.get('payment_listView_smb-contextOverride');
        this.showPage('Create Payment', new PaymentFromTemplateView(util.extend(
            contextModel,
            {
                mode: 'insert',
            },
        )));
    },

    smbListPayments(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('PAYMENTS/listSmbPayments', this)) {
            this.showPage('', new SmbPaymentsListView({
                viewId,
            }));
        }
    },

    smbListStops(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('PAYMENTS/listSmbStops', this)) {
            this.showPage('', new SmbStopPaymentsListView({
                viewId,
                returnRoute: 'PAYMENTS/listSmbStops',
                lastFragment: this.lastFragment,
            }));
        }
    },

    smbManageAccounts() {
        this.showPage('', new WorkspaceTrnsfrView());
    },

    smbTileView() {
        this.showPage('Test Bill Grid View', new SmbTileGridView());
    },

    smbManageHomePage() {
        let account = store.get('balanceAndTransactionAccount');
        if (!account) {
            account = new Account({
                returnRoute: 'PAYMENTS/smbManageHomePage',
            });
        } else {
            account.set({
                returnRoute: 'PAYMENTS/smbManageHomePage',
            });
        }
        store.set('balanceAndTransactionAccount', account);
        this.showPage('', new ManageWorkspaceView({
            id: 'SMBHOME',
            returnRoute: 'PAYMENTS/smbManageHomePage',
        }));
    },

    smbWorkspace() {
        this.showPage('', new ManageWorkspaceView({
            id: 'SMBPAYMENTS',
            returnRoute: 'PAYMENTS/smbManagePayments',
        }));
    },

    smbAccountsCenter() {
        let account = store.get('balanceAndTransactionAccount');
        if (!account) {
            account = new Account({
                returnRoute: 'PAYMENTS/smbAccountsCenter',
            });
        } else {
            account.set({
                returnRoute: 'PAYMENTS/smbAccountsCenter',
            });
        }
        store.set('balanceAndTransactionAccount', account);
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new AccountCenterWorkspaceView({
                    id: 'SMBACCOUNTS',
                    returnRoute: 'PAYMENTS/smbAccountsCenter',
                }),
            }));
        }
    },

    smbAccountsCenterListView() {
        if (processDeeplink('PAYMENTS/smbAccountsCenterListView', this)) {
            this.showPage('', new AccountCenterListView());
        }
    },

    smbListEmployees() {
        if (processDeeplink('PAYMENTS/listSmbEmployees', this)) {
            this.showPage('', new EmployeesListView());
        }
    },

    smbManageEmployees() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'SMBEMPLOYEES',
                    returnRoute: 'PAYMENTS/smbManageEmployees',
                }),
            }));
        }
    },

    smbListTransfers(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('PAYMENTS/listSmbTransfers', this)) {
            this.showPage('', new TransfersListView({
                viewId,
            }));
        }
    },

    smbManageTransfers() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'SMBACCOUNTS',
                    returnRoute: 'PAYMENTS/smbManageTransfers',
                }),
            }));
        }
    },

    smbViewEmpPayment() {
        const options = {
            smbOverride: true,
            reimburse: false,
            lastFragment: this.lastFragment,
        };
        this.showPage('', new ViewEmpPayment(options));
    },

    smbModifyEmpPayment() {
        let lastFragment;
        if (this.currentView?.mode !== 'modify' && this.currentView?.mode !== 'view') {
            store.set('current-workspace-route', this.lastFragment);
            ({ lastFragment } = this);
        } else {
            lastFragment = store.get('current-workspace-route');
        }
        const options = {
            smbOverride: true,
            lastFragment,
        };
        this.showPage('', new ModifyEmpPayment(options));
    },

    smbCopyPaymentFromEmpPayment() {
        const contextTemp = store.get('payment_listView_smbEmployee-contextOverride');
        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'SMB_EMPLOYEE',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
            smbOverride: contextTemp.smbOverride,
            reimburse: contextTemp.reimburse,
        };
        this.showPage(locale.get('smbPayments.payments.create'), new CopyPaymentFromEmpPayment(util.extend(
            options,
            {
                mode: 'insert',
            },
        )));
    },

    smbCopyPaymentFromReimbursement() {
        let contextTemp = store.get('payment_listView_smbEmployee-contextOverride');
        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'SMB_EMPLOYEE',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
            smbOverride: contextTemp.smbOverride,
            reimburse: contextTemp.reimburse,
        };
        contextTemp = util.extend(
            contextTemp,
            {
                smbOverride: true,
                reimburse: true,
            },
        );
        this.showPage('', new CopyPaymentFromReimbursement(util.extend(
            options,
            {
                mode: 'insert',
                smbOverride: true,
                reimburse: true,
            },
        )));
    },

    smbEmployeeCopyPaymentAsTemplate() {
        const contextModel = store.get('template_listView_smbEmployee-contextOverride');
        this.showPage('Create Template', new TemplateFromPaymentView(util.extend(
            contextModel,
            {
                mode: 'insert',
            },
        )));
    },

    smbEmployeeCopyTemplateAsPayment() {
        const contextModel = store.get('payment_listView_smbEmployee-contextOverride');
        this.showPage('Create Payment', new PaymentFromTemplateView(util.extend(
            contextModel,
            {
                mode: 'insert',
            },
        )));
    },

    smbCopyTax() {
        this.showPage('', new CopyTax());
    },

    smbAddReimburseExpenses() {
        const contextTemp = store.get('payments_smbEmployee-contextOverride');
        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'SMBEMP_LIST_VIEW',
            contextDef: contextTemp,
            mode: 'insert',
            smbOverride: true,
            reimburse: true,
            lastFragment: this.lastFragment,
        };
        this.showPage('', new AddReimburseExpenses(options));
    },

    smbViewReimburseExpenses() {
        const options = {
            mode: 'view',
            smbOverride: true,
            reimburse: true,
            lastFragment: this.lastFragment,
        };
        this.showPage('', new ViewReimburseExpenses(options));
    },

    smbModifyReimburseExpenses() {
        this.showPage('', new ModifyReimburseExpenses({
            reimburse: true,
        }));
    },

    chmListViewStopPayment() {
        this.showPage('', new SmbStopPaymentsListView());
    },

    smbDepositAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(title, new DepositAccountsTransactionListView({
                model: account,
                lastFragment: getLastFragment({
                    model: account,
                    controller: this,
                }),
            }));
        } else {
            appBus.trigger('router:navigate', 'PAYMENTS/smbManageTransfers', true);
        }
    },

    smbLoanAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(title, new LoanAccountsTransactionListView({
                model: account,
                lastFragment: getLastFragment({
                    model: account,
                    controller: this,
                }),
            }));
        } else {
            appBus.trigger('router:navigate', 'PAYMENTS/smbManageTransfers', true);
        }
    },

    smbDepositSlipImage() {
        const title = locale.get('gir.transactions');
        const model = store.get('deposit_data');
        const options = {
            pageTitle: title,
            accountFilter: model.get('ACCOUNTFILTER'),
            model,
        };

        this.showPage(title, new TransactionDetailView(options));
    },

    smbTieredLoanAccountDetail() {
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage('', new TieredLoanAccountDetail({
                account,
                totalsService: `${LoanConstants.LOAN_ACCTS_SERVICE_PREFIX}tieredLoanSubAccount/getListViewTotals`,
                returnRoute: account.get('returnRoute'),
            }));
        }
    },

    smbSystemCenter() {
        this.showPage('', new AccountCenterWorkspaceView({
            id: 'SMBSYSTEM',
            returnRoute: 'PAYMENTS/smbSystemCenter',
        }));
    },

    smbStopCancelCheckView() {
        const options = {
            context: 'SMB_CM_STOP_LIST',
            model: store.get('cm_listView_stopCancels-actionModel'),
            mode: 'view',
        };

        this.showPage('', new StopCancelCheckView(options));
    },

    pmtSSOoutSMB(menuCode) {
        this.showPage('', new SMBOutboundSSOView({
            menuCode,
        }));
    },
};
