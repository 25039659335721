import AddressBookGridLayout from 'app/smbContact/views/customers/addressBookGridLayout';
import StackView from 'common/stack/views/stackView';
import WorkspaceView from 'common/workspaces/views/workspace';

export default {
    smbContacts() {
        this.showPage('', new AddressBookGridLayout());
    },

    smbContactWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'SMBCONTACT',
                    returnRoute: 'smbContact/customers',
                }),
            }));
        }
    },
};
