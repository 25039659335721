var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-4\">\n        <div class=\"radio\">\n            <input type=\"radio\" name=\"typeOrTemplate\" id=\"templateRadio\" value=\"template\" data-bind=\"model\">\n            <label for=\"templateRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.select.template","Select a Transfer Template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":39},"end":{"line":26,"column":100}}}))
    + "</label>\n        </div>\n    </div>\n    <div class=\"form-group col-8\">\n        <label for=\"transferTemplate\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.select-company","Select a Company",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":54},"end":{"line":30,"column":110}}}))
    + "</label>\n        <select id=\"transferTemplate\" class=\"form-control\" name=\"transferTemplate\" data-bind=\"model\" data-hook=\"templateCombo\">\n            <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"templates") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":35,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <span class=\"help-block\" data-validate=\"transferTemplate\" data-bind=\"model\"></span>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":34,"column":31},"end":{"line":34,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":34,"column":39},"end":{"line":34,"column":47}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row transfer-type-container\">\n    <div class=\"col-4\">\n        <div class=\"radio\">\n            <input type=\"radio\" name=\"typeOrTemplate\" id=\"typeRadio\" value=\"type\" data-bind=\"model\">\n            <label for=\"typeRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.select.type","Select a Transfer Type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":35},"end":{"line":5,"column":88}}}))
    + "</label>\n        </div>\n    </div>\n    <div class=\"form-group col-8\">\n        <label for=\"transferType\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.select.type","Select a Transfer Type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":50},"end":{"line":9,"column":103}}}))
    + "</label>\n        <select id=\"transferType\" class=\"form-control\" name=\"transferType\" data-bind=\"model\" data-hook=\"typeCombo\">\n            <option value=\"\"></option>\n            <option value=\"SINGLE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.transfertype.single","Single",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":35},"end":{"line":12,"column":80}}}))
    + "</option>\n            <option value=\"ONE2ONE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.transfertype.oneToOne","Multiple",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":85}}}))
    + "</option>\n            <option value=\"ONE2MANY\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.transfertype.oneToMany","One-to-many",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":37},"end":{"line":14,"column":90}}}))
    + "</option>\n            <option value=\"MANY2ONE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.transfertype.manyToOne","Many-to-one",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":90}}}))
    + "</option>\n        </select>\n        <span class=\"help-block\" data-validate=\"transferType\" data-bind=\"model\"></span>\n    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideTemplates") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":0},"end":{"line":40,"column":11}}})) != null ? stack1 : "");
},"useData":true});