import util from '@glu/core/src/util';
import locale from '@glu/locale';
import moment from 'moment';
import Formatter from 'system/utilities/format';
import store from 'system/utilities/cache';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import cmConstants from 'app/checkManagement/constants';
import constants from './constants';

const AchAuthRulesEntry = EntryView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: constants.FRAUDMENUCATEGORY,
            serviceName: constants.ACHAUTHRULES_SERVICENAME,
            context: constants.ACHAUTHRULES_CONTEXT,
            returnRoute: constants.ACHAUTHRULES_RETURNROUTE,
            mode: options.mode,
            contextDef: {
                productCode: cmConstants.RISK,
                functionCode: cmConstants.ACH_AUTH_RULES_FUNCTION,
                typeCode: cmConstants.ACH_AUTH_RULES_TYPE,
                serviceName: constants.ACHAUTHRULES_CONTEXT_SERVICENAME,
            },
            // Hide both export and print btns in ACH auth rules audit list
            additionalPageOptions: { includeDefaultIcons: false },
        };
        EntryView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.prefillFields = store.remove(cmConstants.ELECTRONIC_POS_PAY_RULE_PREFILL);
        if (this.prefillFields) {
            store.set(`${this.contextKey}-preFill`, this.prefillFields);
        } else {
            store.remove(`${this.contextKey}-preFill`);
        }
        this.setupEventListeners();
    },

    setupEventListeners() {
        this.listenTo(this.appBus, 'all', (event) => {
            // Handle ACH auth rules form end date validation
            if (event.indexOf('startDate:change') !== -1
            || event.indexOf('schedModel:change') !== -1) {
                util.defer(() => this.handleEndDate());
            }
            if (event.indexOf('endDate:invalid') !== -1) {
                this.disableButtons();
                util.defer(() => this.handleInlineError(true));
            }
            if (event.indexOf('endDate:valid') !== -1) {
                this.enableButtons();
                util.defer(() => this.handleInlineError(false));
            }
        });
    },

    handleEndDate() {
        const { schedModel } = this.pageView.model;
        // In ACH auth rules, end by date should be a day after start date
        const endDate = schedModel.get('ends');
        const startDate = schedModel.get('starts');
        if (util.isString(endDate) && moment(startDate).isSameOrAfter(moment(endDate))) {
            // Set end date to be the day after start date
            const updatedEndDate = Formatter.formatDate(moment(startDate).add(1, 'days'), 'YYYY-MM-DD');
            schedModel.set('ends', updatedEndDate);
            // Revalidate form if not valid
            this.appBus.trigger('endDate:valid');
        } else if (moment(endDate).isSameOrAfter(moment(startDate))) {
            this.appBus.trigger('endDate:valid');
        }
    },

    handleInlineError(shouldShowError) {
        this.ui.$pageContent.find('.js-region-scheduler-date-end-by-date').toggleClass('has-error', shouldShowError);
        this.ui.$pageContent
            .find('.js-region-scheduler-date-end-by-date')
            .find('.help-block')
            .text(shouldShowError ? locale.get('common.datepicker.invalidDateRange') : '');
    },

    onRender() {
        EntryView.prototype.onRender.call(this);

        if (this.options.mode !== 'insert') {
            this.model.set({
                PRODUCT: cmConstants.RISK,
                TYPE: cmConstants.ACH_AUTH_RULES_TYPE,
                FUNCTION: cmConstants.ACH_AUTH_RULES_FUNCTION,
            });
        }
    },

    /**
     * @name loadRequiredDataInsertImport
     * @description - sets loadedRequiredData and renderMessage
     * this method can be overridden for additional insert
     */
    loadRequiredDataInsertImport() {
        this.setHasLoadedRequiredData(true);
        this.render();
        if (!this.prefillFields) {
            store.remove(cmConstants.ELECTRONIC_POS_PAY_PAY_MESSAGE);
        } else {
            const payMessage = store.get(cmConstants.ELECTRONIC_POS_PAY_PAY_MESSAGE);
            if (payMessage) {
                this.renderMessage(payMessage);
            }
        }
    },
    /**
     * @name modify
     * @description - modify after viewing rule.
     *
     */
    modify() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo(constants.ACHAUTHRULES_MODIFY_ROUTE);
    },

    /**
     * @name save
     * @description - Validates & saves the model to the server.
     *
     */
    save() {
        const startDate = this.pageView.model.schedModel.get('starts');
        this.pageView.model.set('STARTINGEFFECTIVEDATE', startDate);

        /*
         * ends values from schedule widget
         *   boolean = no end date (false)
         *   date = ends on date
         *   number = number of cycles
         */
        const ends = this.pageView.model.schedModel.get('ends');

        this.pageView.model.set({
            ENDCYCLES: null,
            ENDINGEFFECTIVEDATE: null,
        });
        if (util.isBoolean(ends)) {
            this.pageView.model.set('ENDMODE', 'noend');
        } else if (util.isNumber(ends)) {
            this.pageView.model.set('ENDCYCLES', ends);
            this.pageView.model.set('ENDMODE', 'numoccur');
        } else {
            this.pageView.model.set('ENDINGEFFECTIVEDATE', ends);
            this.pageView.model.set('ENDMODE', 'effdate');
        }

        /**
         * Schedule widget end date validation
         * Prevent form from submitting when ENDCYCLES is selected
         * and not a number
         */
        const schedModelErrors = this.pageView.model.schedModel.get('error');
        if (schedModelErrors && Object.keys(schedModelErrors).length) {
            return;
        }

        store.set('current-workspace-route', constants.ACHAUTHRULES_PAYANDCREATE_ROUTE);
        EntryView.prototype.save.call(this);
    },
});

export default AchAuthRulesEntry;
