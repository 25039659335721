import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import services from 'services';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    initialize() {
        this.validators = {
            NAME: {
                description: locale.get('administration.name'),
                exists: true,
            },
        };
    },

    approve(options) {
        this.sync('approve', this, options);
    },

    delete(options) {
        this.sync('delete', this, options);
    },

    sync(method, model, options) {
        const data = this.convertModelAttributesToServerJSON();
        if (method === 'create') {
            http.post(services.generateUrl('/segmentation/services/add'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
        if (method === 'update') {
            http.post(services.generateUrl('/segmentation/services/update'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'approve') {
            http.post(services.generateUrl('/segmentation/approve'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            http.post(services.generateUrl('/segmentation/delete'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    convertModelAttributesToServerJSON() {
        const jsonData = [];

        util.each(this.attributes, (i, val) => {
            if (val !== 'error' && val !== 'success') {
                jsonData.push({
                    name: val,
                    value: i,
                });
            }
        });
        return {
            item: jsonData,
        };
    },
});
