import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import PermissionsByPaymentGroup from './permissionsByPaymentGroup';
import PermissionsByPaymentType from './permissionsByPaymentType';
import assignedPermissionsTmpl from './assignedPermissions.hbs';

export default Layout.extend({
    template: assignedPermissionsTmpl,

    ui: {
        $permissionGroupBy: '.perm-group-by',
        $paymentCheckbox: '#paymentsChk',
        $templateCheckbox: '#templatesChk',
    },

    events: {
        'change @ui.$permissionGroupBy': 'changePermissionGroupByHandler',
    },

    initialize(options) {
        this.mode = options.mode;
        this.model = options.model;
    },

    onRender() {
        let permissionsView;
        let value;

        switch (this.model.userModel ? this.model.userModel.get('GROUPED_PERMISSIONS_BY') : this.model.roleInfo.GROUPED_PERMISSIONS_BY) {
        case 'ALL_PAYMENT_TYPES':
            break;
        case 'PAYMENT_TYPE':
            value = 'byType';
            permissionsView = new PermissionsByPaymentType({
                mode: this.mode,
                model: this.model,
            });

            break;
        case 'PAYMENT_GROUP':
        default:
            value = 'byGroup';
            permissionsView = new PermissionsByPaymentGroup({
                mode: this.mode,
                model: this.model,
            });
            break;
        }
        this.ui.$permissionGroupBy.comboBox().comboBox('val', value);
        this.permissionsRegion.show(permissionsView);
        this.populateBeneAddressBookSettings();
    },

    updateGlobalSettingInput($input, setting) {
        this.model.get('globalSettings')[setting] = true;
        $input.prop('checked', true);
        $input.prop('disabled', true);
        $input.addClass('read-only-field');
    },

    getSettingBooleanValue(setting) {
        const userCompany = this.model.userGroupModel;

        const field = util.findWhere(
            userCompany.get('mapDataList'),
            {
                toField: setting,
            },
        );

        return field && field.value && field.value === '1';
    },

    populateBeneAddressBookSettings() {
        const reqForPayments = this.getSettingBooleanValue('CompanyRequireBeneFromAdressBookForPayments');
        const reqForTemplates = this.getSettingBooleanValue('CompanyRequireBeneFromAdressBookForTemplates');

        if (reqForPayments) {
            this.updateGlobalSettingInput(this.ui.$paymentCheckbox, 'BENEPAY');
        }

        if (reqForTemplates) {
            this.updateGlobalSettingInput(this.ui.$templateCheckbox, 'BENETEMP');
        }
    },

    changePermissionGroupByHandler(e) {
        const self = this;

        if (e.target.value === 'byType') {
            // fetch detailed complexTypeGroups before switching
            this.model.aggregateToTypes(() => {
                if (self.model.userModel) {
                    self.model.userModel.set('GROUPED_PERMISSIONS_BY', 'PAYMENT_TYPE');
                } else {
                    self.model.roleInfo.GROUPED_PERMISSIONS_BY = 'PAYMENT_TYPE';
                }

                self.permissionsRegion.show(new PermissionsByPaymentType({
                    model: self.model,
                    mode: self.mode,
                }));
                self.trigger('payments:rerender');
            });
        } else {
            // warn about loss of settings
            dialog.confirm(locale.get('uce.switchGroupType'), (ok) => {
                if (ok) {
                    if (self.model.userModel) {
                        self.model.userModel.set('GROUPED_PERMISSIONS_BY', 'PAYMENT_GROUP');
                    } else {
                        self.model.roleInfo.GROUPED_PERMISSIONS_BY = 'PAYMENT_GROUP';
                    }

                    self.permissionsRegion.show(new PermissionsByPaymentGroup({
                        model: self.model,
                        mode: self.mode,
                    }));
                } else {
                    self.ui.$permissionGroupBy.comboBox('val', 'byType');
                }
            });
        }
    },

    templateHelpers() {
        return {
            readOnly: this.mode === constants.MODES.VIEW,
        };
    },
});
