import ItemView from '@glu/core/src/itemView';
import editableTableRowTmpl from 'app/bos_common/editableTable/view/editableTableRow.hbs';

const EditableTableRow = ItemView.extend({
    template: editableTableRowTmpl,
    tagName: 'tr',

    initialize(options) {
        this.readOnly = options.readOnly;
    },

    attributes() {
        return {
            'data-model': this.model.cid,
        };
    },

    events: {
        'click button[data-action="delete"]': 'deleteLineItem',
    },

    deleteLineItem() {
        this.model.destroy();
    },

    templateHelpers() {
        return {
            id: this.cid,
            readOnly: this.readOnly,
        };
    },
});

export default EditableTableRow;
