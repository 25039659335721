const Constants = {
    GRID_META_VIEW: 'beneficiary address book grid meta view',
    GRID_LIST_VIEW: 'beneficiary address book grid list view',
    ADD_BAB_VIEW: 'beneficiary address book add view',
    BAB_VIEW_ADD: 'beneficiary address book add',
    BAB_VIEW_VIEW: 'beneficiary address book view',
    BAB_VIEW_MODIFY: 'beneficiary address book modify',
    GRID_CONTEXT: 'BENEADBK_MAINT',
    STORE_BAB_CONTEXT: 'beneficiary address book context',
    STORE_BAB_VIEWMODEL: 'beneficiary address book view model',
    ACTION_MODIFY: 'MODIFY',
    ACTION_APPROVE: 'APPROVE',
    ACTION_DELETE: 'DELETE',
    BUSINESS_CONTACT: '1',
    INDIVIDUAL_CONTACT: '2',
    EMPLOYEE_CONTACT: '3',
    INDIVIDUAL_EMPLOYEE_CONTACT: '4',
};

export default Constants;
