var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"btn-group widget-links\">\n                            <button class=\"btn btn-tertiary\"\n                                    data-hook=\"getAddVendor\"\n                                    type=\"button\">\n                                <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sso.add.vendor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":70},"end":{"line":18,"column":97}}}))
    + "\n                            </button>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-secondary delete\"\n                    type=\"button\"\n                    data-hook=\"getDeleteBtn\">\n                    "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":59,"column":46}}}))
    + "\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.admin.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":59}}}))
    + "</h1>\n</div>\n<div class=\"section section-container sso-admin\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendors",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":36}}}))
    + "</h3>\n    </div>\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    <div class=\"section-body\">\n        <div class=\"glu-container\">\n            <div class=\"row\">\n                <div class=\"btn-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"btn-group widget-links\">\n                        <button class=\"btn btn-tertiary import-vendor\"\n                                data-hook=\"getImportVendor\"\n                                type=\"button\">\n                            <span class=\"icon-add-circle\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.import.vendor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":66},"end":{"line":26,"column":96}}}))
    + "\n                        </button>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"panel panel-tertiary\">\n                    <div class=\"panel-heading\" role=\"tab\" id=\"collapseHeading\">\n                        <h3 class=\"panel-title\">\n                            <a aria-controls=\"collapseAreaID\"\n                                aria-expanded=\"false\"\n                                class=\"collapsed\"\n                                data-toggle=\"collapse\"\n                                href=\"#collapseAreaID\"\n                                role=\"button\">\n                                <span class=\"indicator-icon\"></span> <span class=\"icon-info\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendor.help.info.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":101},"end":{"line":41,"column":140}}}))
    + "\n                            </a>\n                        </h3>\n                    </div>\n                    <div id=\"collapseAreaID\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"collapseHeading\">\n                        <div class=\"panel-body\">\n                            <p><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendor.help.info.label.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":39},"end":{"line":47,"column":80}}}))
    + ":</strong> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendor.help.info.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":91},"end":{"line":47,"column":126}}}))
    + "</p>\n                            <p><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendor.help.info.label.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":39},"end":{"line":48,"column":80}}}))
    + ":</strong> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendor.help.info.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":91},"end":{"line":48,"column":126}}}))
    + "</p>\n                            <p><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendor.help.info.label.3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":39},"end":{"line":49,"column":80}}}))
    + ":</strong> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.vendor.help.info.3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":91},"end":{"line":49,"column":126}}}))
    + "</p>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":61,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});