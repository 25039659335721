var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.recipient",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":3,"column":94}}}))
    + "</span></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.effectiveDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":61},"end":{"line":14,"column":98}}}))
    + "</span></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.comment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":61},"end":{"line":20,"column":92}}}))
    + "</span></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":61},"end":{"line":28,"column":91}}}))
    + "</span></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"payment-grid-header-label\"><span class=\"txt\">&nbsp;</span></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <button type=\"button\" class=\"btn btn-success btn-left\" data-action=\"add\" title=\"Add\"><span class=\"icon-plus\"></span></button>\n                <button type=\"button\" class=\"btn btn-success btn-right\" data-action=\"clear\" title=\"Clear\"><span class=\"icon-cancel\"></span></button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                <button type=\"button\" class=\"btn btn-success btn-left\" data-action=\"save\" title=\"Save\"><span class=\"icon-tick\"></span></button>\n                <button type=\"button\" class=\"btn btn-success btn-right\" data-action=\"cancel\" title=\"Cancel\"><span class=\"icon-cancel\"></span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <span data-region=\"addRecipientRegion\" data-hook=\"BENE_NAME\"></span>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_NAME\"></span>\n        <span data-hook=\"recipient-selected\"></span>\n\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input payment-date\"></div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <input type=\"button\" id=\"comment\" class=\"btn btn-link\" data-hook=\"commentButton\" data-action=\"popComment\"/>\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":29,"column":7}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <input id=\"amount\" type=\"text\" class=\"form-control\" data-bind=\"model\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"sbPayments.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":85},"end":{"line":31,"column":115}}}))
    + "\" name=\"amount\" maxlength=\"12\"/>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"amount\"></span>\n    </div>\n</td>\n<td class=\"align-center btn-cell\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":0},"end":{"line":38,"column":7}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n            <div class=\"btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":47,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</td>\n";
},"useData":true});