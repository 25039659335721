import Collection from '@glu/core/src/collection';
import Field from '../model/fieldModel';

export default Collection.extend({
    model: Field,

    /**
     * Returns a boolean whether the field object exists within the collection
     * @param  {String}  field - Field name
     * @return {Boolean}
     */
    hasField(field) {
        return this.where({
            FIELDNAME: field,
        }).length > 0;
    },

    /**
     * Returns the field model based off of the FIELDNAME property
     * @param  {String} field - Name of the field
     * @return {Field}  - Returns the field object or undefined if not found.
     */
    getField(field) {
        const f = this.where({
            FIELDNAME: field,
        });

        return f.length > 0 ? f[0] : undefined;
    },

    /**
     * Sets the value for that field, convienent function to easily set the value
     * for a field.
     * @param {String} field Field name
     * @param {*} value - Simple type that will be assigned to field objects
     * FIELDVALUE property.
     */
    setFieldValue(field, value) {
        const f = this.getField(field);
        if (!f) {
            throw new Error(`Unable to find field with name (${field}).  Failed to set field value`);
        }
        f.set('FIELDVALUE', value);
        return this;
    },

    /**
     * Gets the field value for the specified field name
     * @param  {string} field - Field Name
     * @return {*}
     */
    getFieldValue(field) {
        const f = this.getField(field);
        return (f ? f.get('FIELDVALUE') : undefined);
    },

    /**
     * Returns a structure that the server expects to consume for UPDATE, DELETE
     * and CREATE
     * services.  Will return a structure like
     * {
     *     items : [
     *         {
     *             item: [
     *                 { fieldName: 'TAX', fieldValue: 0.5}
     *             ]
     *         }
     *    ]
     * }
     * @return {Object}
     */
    toPair() {
        return {
            items: this.map(model => ({
                item: model.toPair(),
            })),
        };
    },
});
