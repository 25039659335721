import $ from 'jquery';
import util from '@glu/core/src/util';
import moment from 'moment';
import constants from 'common/dynamicPages/api/constants';
import userInfo from 'etc/userInfo';

export default {
    setupDatePickers(viewParam, $alt) {
        const view = viewParam;
        // Support an alternate container element
        const $el = $alt || view.$el;
        // eslint-disable-next-line
        view.model._datePickerValues = [];

        $el.find('.input-date').each(function (i, el) {
            let dateOptions;
            // eslint-disable-next-line
            view.model._datePickerValues[$(this).attr('name')] = [];
            if (view.model.fieldData[$(this).attr('name')].fieldUIType === constants.FIELD_UI_TYPE_DATEFILTER) {
                dateOptions = {
                    showCalendarIcon: true,
                    rangeDatePicker: true,
                };
                if (view.model.fieldData[$(this).attr('name')].filterType === constants.FILTER_TYPE_SINGLE_DATE_FILTER && view.model.jsonData.typeInfo.typeCode === '24717') {
                    /*
                     * workaround for the EFT Extraction Summary report till a long term
                     * solution is found to allow you to specify if a date picker filter
                     * will be a range or just a single date
                     */
                    dateOptions.rangeDatePicker = false;
                }
                if (view.processingDates.allowWeekends === true) {
                    dateOptions.allowWeekends = true;
                }

                if (view.processingDates && view.processingDates.earliestDay) {
                    dateOptions.daysBack = moment(new Date()).diff(moment(view.processingDates.earliestDay), 'days');
                }

                if (view.datePickerOptions) {
                    util.extend(dateOptions, view.datePickerOptions);
                }

                if (view.dateRange) {
                    // To check if the date picker should enable the future dates
                    if (view.model?.fieldData[$(this).attr('name')]?.dynamicDateOptions) {
                        dateOptions.dynamicDateOptions = true;
                        dateOptions.allowWeekends = true;

                        dateOptions.payload = view.model.fieldData[$(this).attr('name')].payload;
                        dateOptions = util.extend(
                            dateOptions,
                            {
                                startDate: view.minFutureDate,
                                endDate: view.maxFutureDate,
                                minDate: view.minFutureDate,
                                maxDate: view.maxFutureDate,
                            },
                        );
                    } else {
                        dateOptions = util.extend(
                            dateOptions,
                            {
                                startDate: view.minDate,
                                endDate: view.maxDate,
                                minDate: view.minDate,
                                maxDate: view.maxDate,
                            },
                        );
                    }
                }
            } else {
                /*
                 * hack, need to add locale
                 * ensure that the format of the value is correct
                 */
                if ($(this).val().length > 0) {
                    /*
                     * localization efforts for NH-27739 trump the fix for NH-16942
                     * if for some reason the date is still invalid, then use todays
                     * date as a fallback.
                     */
                    let curVal = moment($(this).val(), userInfo.getDateFormat());
                    if (!curVal.isValid()) {
                        // try to get a valid date without a format.
                        curVal = moment($(this).val());
                        if (!curVal.isValid()) {
                            // if not valid, choose right now as a fall back.
                            curVal = moment(new Date());
                        }
                    }

                    view.model.set($(this).attr('name'), curVal.format(userInfo.getDateFormat()));
                }
                if (view.model.fieldData[$(this).attr('name')].fieldUIType === 'CALENDAR_ENABLEALL') {
                    dateOptions = {
                        processingDays: '1111111',
                        showCalendarIcon: true,
                    };
                } else {
                    if (view.dates) {
                        if (view.dates.processingDays) {
                            view.processingDates.processingDays = view.dates.processingDays;
                        }
                        if (view.dates.blockedDates) {
                            view.processingDates.blockedDates = view.dates.blockedDates;
                        }
                    }
                    dateOptions = {
                        blockedDates: view.processingDates.blockedDates,
                        processingDays: view.processingDates.processingDays,
                        cutOffTimes: view.processingDates.cutOffTimes,
                        showCalendarIcon: true,
                        daysForward: view.processingDates.daysForward[0],
                        daysBack: view.dates && view.dates.minDate && view.dates.minDate > 1 ? view.dates.minDate : moment(new Date()).diff(moment(view.processingDates.earliestDay), 'days'),
                    };
                }
            }
            dateOptions.pastOnly = view.processingDates.pastOnly;
            const key = $(this).attr('name');
            dateOptions.daysForward = (view.processingDates.currentAndPastOnly)
                ? 0 : dateOptions.daysForward;

            $(this).nhDatePicker(dateOptions, (start, end) => {
                // eslint-disable-next-line
                view.model._datePickerValues[key] = [start, end];
            });

            view.once('close', () => {
                const drp = view.$(el).data('daterangepicker');
                if (drp) {
                    drp.remove();
                }
            });
        });
    },
};
