import Collection from '@glu/core/src/collection';
import userInfo from 'etc/userInfo';
import NotificationModel from 'app/notifications/models/notification';

export const notificationHelper = {
    determineRoute(row) {
        const { productCode } = row;
        const { functionCode } = row;
        const { typeCode } = row;
        const { actionMode } = row;
        const paymentURL = (userInfo.isSmbUser()) ? '/PAYMENTS/listSmbPayments/' : '/PAYMENTS/listPayments/';
        const transferURL = (userInfo.isSmbUser()) ? '/PAYMENTS/listSmbTransfers/' : '/PAYMENTS/listTransfers/';
        const templateURL = (userInfo.isSmbUser()) ? '/PAYMENTS/listSmbPayments/' : '/PAYMENTS/listTemplates/';
        const stopPaymentURL = (userInfo.isSmbUser()) ? '/PAYMENTS/listSmbStops/' : '/PAYMENTS/listStops/';

        if (productCode === 'PAY' && functionCode === 'INST' && typeCode === '*' && actionMode === 'APPROVE') {
            return paymentURL;
        }
        if (productCode === 'PAY' && functionCode === 'INST' && typeCode === '*' && actionMode === 'MODIFY') {
            return paymentURL;
        }
        if (productCode === 'PAY' && functionCode === 'TMPL' && typeCode === '*' && actionMode === 'SELECT') {
            return templateURL;
        }
        if (productCode === '_ADMIN' && functionCode === 'USERS' && typeCode === '_USER' && actionMode === 'APPROVE') {
            return '/ADMINSTRATION/listUsers/';
        }
        if (productCode === '_UTIL' && functionCode === 'HISTORY' && typeCode === 'IMPORT' && actionMode === 'SELECT') {
            return '/ADMINSTRATION/fileImportHistory/';
        }
        if (productCode === 'RTGS' && functionCode === 'LISTRPT' && typeCode === 'RECPAYEX' && actionMode === 'SELECT') {
            return '/PAYMENTS/exception/report';
        }
        if (productCode === 'USACH' && functionCode === 'LGBATRPT' && typeCode === 'ACHLGBAT' && actionMode === 'SELECT') {
            return '/BATCH/largeBatch/';
        }
        if (productCode === 'RTP' && functionCode === 'MSGS' && typeCode === '*' && actionMode === 'SELECT') {
            return '/PAYMENTS/realTimePaymentsWidget/';
        }
        if (productCode === 'RFPILIST' && functionCode === 'REQUEST' && typeCode === 'REQIN' && actionMode === 'SELECT') {
            return '/PAYMENTS/receivedRFPNotificationList/';
        }
        if (productCode === 'CM' && functionCode === 'BATCH' && typeCode === 'BMPPDEC' && actionMode === 'SELECT') {
            return '/RISK/paymentFraudControl/';
        }
        if (productCode === 'CM' && functionCode === 'BATCH' && typeCode === 'CMPPDEC' && actionMode === 'SELECT') {
            return '/RISK/paymentFraudControl/';
        } else if (productCode === 'CM' && functionCode === 'INST' && typeCode === 'CIMINSTT' && actionMode === 'SELECT') {
            return '/RISK/issueVoids/';
        } else if (productCode === 'CM' && functionCode === 'INST' && typeCode === '*' && actionMode === 'APPROVE') {
            return stopPaymentURL;
        } else if (productCode === 'RISK' && functionCode === 'BATCH' && typeCode === 'EBMPPDEC' && actionMode === 'SELECT') {
            return '/RISK/paymentFraudControl/';
        } else if (productCode === 'RTGS' && functionCode === 'INST' && typeCode === '*') {
            return transferURL;
        } else if (productCode === 'RTGS' && functionCode === 'TMPL' && typeCode === '*') {
            return '/PAYMENTS/listTransferTemplates/';
        } else if (productCode === 'RISK' && functionCode === 'MAINT' && typeCode === 'ACHCTTYP') {
            return '/RISK/achClientView/';
        }

        return '';
    },
};

const notificationsList = Collection.extend({
    model: NotificationModel,

    initialize() {
        this.data = {
            newOnly: false,
        };
    },
});

export default notificationsList;
