import React, {
    createContext,
    useReducer,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import AlertContextReducer, { TYPE } from './AlertContextReducer';
import { ALERT_DELIVERY_TYPE, MODE } from '../constants';

const AlertContext = createContext({});

export const AlertContextProvider = ({
    children,
    providerState,
}) => {
    const [state, dispatch] = useReducer(AlertContextReducer, {
        alertDeliveryType: ALERT_DELIVERY_TYPE.EMAIL,
        mode: MODE.MODIFY,
        alertGroupOptions: [],
        alertTypeOptions: [],
        localeOptions: [],
        marketSegmentOptions: [],
        alertData: {
            ALERTSUBJECT: '',
            EMAILCONTENT: '',
            TEXTCONTENT: '',
            ALERTGROUP: [],
            ALERTTYPE: [],
            LOCALE: [],
        },
        entitlements: [],
        alertParameters: {},
    });
    const setAlertGroupOptions = useCallback((options) => {
        dispatch({
            type: TYPE.SET_ALERT_GROUP_OPTIONS,
            payload: options,
        });
    }, []);
    const setAlertTypeOptions = useCallback((options) => {
        dispatch({
            type: TYPE.SET_ALERT_TYPE_OPTIONS,
            payload: options,
        });
    }, []);
    const setAlertDeliveryType = useCallback((value) => {
        dispatch({
            type: TYPE.SET_DELIVERY_TYPE,
            payload: value,
        });
    }, []);
    const setLocaleOptions = useCallback((options) => {
        dispatch({
            type: TYPE.SET_LOCALE_OPTIONS,
            payload: options,
        });
    }, []);
    const setAlertData = useCallback((data) => {
        dispatch({
            type: TYPE.SET_ALERT_DATA,
            payload: data,
        });
    }, []);
    const setAlertParameters = useCallback((data) => {
        dispatch({
            type: TYPE.SET_ALERT_PARAMETERS,
            payload: data,
        });
    }, []);
    const setMarketSegmentOptions = useCallback((data) => {
        dispatch({
            type: TYPE.SET_MARKET_SEGMENT_OPTIONS,
            payload: data,
        });
    }, []);

    const setEntitlements = useCallback((data) => {
        dispatch({
            type: TYPE.SET_ENTITLEMENTS,
            payload: data,
        });
    }, []);

    return (
        <AlertContext.Provider value={{
            ...state,
            ...providerState,
            setAlertData,
            setAlertDeliveryType,
            setAlertGroupOptions,
            setAlertParameters,
            setAlertTypeOptions,
            setLocaleOptions,
            setMarketSegmentOptions,
            setEntitlements,
        }}
        >
            {children}
        </AlertContext.Provider>
    );
};

AlertContextProvider.propTypes = {
    providerState: PropTypes.shape({}),
    children: PropTypes.node.isRequired,
};
AlertContextProvider.defaultProps = {
    providerState: {},
};
export default AlertContext;
