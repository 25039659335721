import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize() {
        this.serviceUrl = '/inquiry/getData';
    },

    parse(jsonData) {
        const { rows } = jsonData.inquiryResponse;
        const { rowHeader } = jsonData.inquiryResponse;

        return {
            lockboxTypes: this.convertServerJsonToModelAttributes(rows),
            rowHeader,
        };
    },

    sync(method, model, options) {
        const url = services.generateUrl(this.serviceUrl);
        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 22456,
                },
            },
        };

        http.post(url, postData, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    convertServerJsonToModelAttributes(serverJson) {
        let data;
        let columns = {};
        const allRows = [];
        const lockboxTypes = [];

        // for each row of all lockboxes
        for (let x = 0; x < serverJson.length; x += 1) {
            data = {};
            ({ columns } = serverJson[x]);

            // for each column of a single row of a lockbox
            for (let y = 0; y < columns.length; y += 1) {
                data[columns[y].fieldName] = columns[y].fieldValue;
            }

            allRows.push(data);
        }

        util.each(allRows, (item) => {
            // Only add visible items
            if (item.HIDDEN === 'N') {
                const type = {
                    id: item.ITEM_TYPE_ID,
                    itemTypeCode: item.ITEM_TYPE_CODE,
                    name: item.DISPLAY_NAME,
                };

                lockboxTypes.push(type);
            }
        });

        return lockboxTypes;
    },
});
