/* eslint-disable import/first */
import '../single-spa-root';
// Add featureUtil for initial loading of DarkDeploy status
import services from 'services';
import 'app/appBootstrap';
import cookie from 'system/cookie';
import configuration from 'system/configuration';
import MenuModel from 'system/menuModel';
import MainFrameController from 'common/frame/mainFrameController';
import paymodeIntegration, {
    fetchPrivileges,
    addFullPagePaymodeIntegrationHooks,
} from 'no-paymode!common/paymodeIntegration/paymodeIntegration';
import integrationManager from 'app/integrationManager/integrationManager';
import httpSetup from 'system/httpSetup';
import analyticsClient from 'analytics-client';
import notificationClientStartup from 'common/notificationClient/startup';
import Glu from '@glu/core/src/glu';
import GluHost from 'system/host';
import gluStore from '@glu/store';
import util from '@glu/core/src/util';
import Grid from '@glu/grid';
import http from '@glu/core/src/http';
import gluLocale from '@glu/locale';
import ptxConnectLocale from 'pcm/common/ptx-connect-integration/ptxConnectLocale';
import dialog from '@glu/dialog';
import { appBus, log } from '@glu/core';
import { validators } from '@glu/validation-react';
import {
    customValidators,
    customValidatorMessages,
} from '@dbiqe/common';
import $ from 'jquery';
import store from 'system/utilities/cache';
import requireForNative from 'system/utilities/requireForNative';
import RetrieveUserCredentials from 'app/utilities/models/retrieveUserCredentials';
import dynamiclinks from 'system/dynamiclinks/configuration';
import LoginView from 'system/login/views/base_pcm';
import LiveMaintenanceUserView from 'system/liveMaintenance/views/liveMaintenanceUserView';
import featureList from 'system/webseries/models/featureList';
import locale from 'system/webseries/api/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfiguration from 'system/webseries/models/applicationConfiguration';
import liveMaintenanceCheck from 'system/webseries/models/liveMaintenanceCheck';
import mfaManager from 'system/mfa/manager';
import NavView from 'system/navigation/views/clientLayout';
import messageController from 'system/notifications/messageController';
import DeeplinkModel from 'common/workspaces/models/deeplink';
import workspaceHelper from 'common/workspaces/api/helper';
import listViewConfig from 'common/util/listViewConfig/listViewConfig';
import listViewConfigStore from 'common/util/listViewConfig/listViewConfigStorage';
import userInfo from 'etc/userInfo';
import 'common/util/featureUtil';
import localStore from 'common/util/localStore/localStore';
import dateUtil from 'common/util/dateUtil';
import Controller from './controller';
import Router from './router';
import timers from './utilities/timers';
import { loadLocaleBundle } from '../locale/loader';

if (ptxConnectLocale) {
    ptxConnectLocale.initPtxConnectLocale();
}

const serviceList = services;
// host = Glu.host,
const host = (window.Bottomline.amd) ? Glu.host : new GluHost({
    app: '*',
});

dialog.collisionMode = 'stack';

// Set icon in different locale support
Grid.prototype.setGlobalIconType('font');

// Create Promises that need to be fullfilled before app can start
const promises = [];

// Create promises that should be done after user authentication
const afterAuthPromises = [];

const menuModel = new MenuModel();

const mainFrame = new MainFrameController({
    mutationSelector: 'body',
});

window.Bottomline.extMethods.rsaSubmitToken = (callBackToken) => {
    appBus.trigger('extChallenge:rsa:challenge:complete', callBackToken);
};

mainFrame.register();

// expose a require function for native
requireForNative.setup();

httpSetup.config();

let loadingErrorMessage = gluLocale.get('workspace.banking.load.failure');
if (loadingErrorMessage.indexOf('workspace.banking.load.failure') >= 0) {
    loadingErrorMessage = `The ${window.Bottomline.appTitle || ''} application did not load successfully. Please try again in a few minutes. If you continue to receive this message please contact your finanical institution.`;
}

const getUserLanguageCode = function () {
    return userInfo.getLocale().substring(0, 2);
};

const isExistLoginChallenge = function () {
    const challengeObj = gluStore.get('challengedAction');
    return challengeObj && challengeObj.productCode === '_CORE'
        && challengeObj.functionCode === 'USERS'
        && challengeObj.typeCode === '_USER'
        && challengeObj.actionMode === 'LOGIN';
};

const processDefinedResponseCodesLogoff = function () {
    const definedValues = serverConfigParams.get('forcedLogoffHttpStatusCodes');
    if (definedValues) {
        const respCodesArray = definedValues.split(',');
        gluStore.set('forcedLogoffHttpStatusCodes', respCodesArray);
    }
};

const localizeSelect2 = function () {
    const languageCode = getUserLanguageCode();
    $.fn.select2.locales[languageCode] = {
        formatMatches(matches) {
            if (matches === 1) {
                return gluLocale.get('common.select.one.result');
            }
            return gluLocale.get('common.select.more.results', matches);
        },

        formatNoMatches() {
            return gluLocale.get('common.no.matches');
        },

        formatAjaxError() {
            return gluLocale.get('common.select.loading.failed');
        },

        formatInputTooShort(input, min) {
            const n = min - input.length;
            return gluLocale.get('common.select.enter.more', n);
        },

        formatInputTooLong(input, max) {
            const n = input.length - max;
            return gluLocale.get('common.select.delete.characters', n);
        },

        formatSelectionTooBig(limit) {
            return gluLocale.get('common.select.only', limit);
        },

        formatLoadMore() {
            return gluLocale.get('common.select.loading.more');
        },

        formatSearching() {
            return gluLocale.get('common.select.searching');
        },
    };
    util.extend($.fn.select2.defaults, $.fn.select2.locales[languageCode]);
};

if (!configuration.isPortal()) {
    promises.push(new Promise((resolve, reject) => {
        featureList.fetch({
            success: resolve,

            error() {
                reject(loadingErrorMessage);
            },
        });
    }));
}

const options = {
    promises,
    afterAuthPromises,
    liveMaintenanceUserView: LiveMaintenanceUserView,
    loginView: LoginView,
    navView: NavView,
    brandMode: false,
    appRoot: configuration.appRoot,
    title: window.Bottomline.appTitle,

    serverErrorHandler(xhr) {
        return xhr;
    },
};

/*
 * This method is processed after the user is authenticated and the client
 * side Configuration Parameters are downloaded.
 * If the configuration parameter 'enableServerLoggingClientErrors' is set to true,
 * it will override the clientErrorHandler defined in gluOverride/core/internal/baseLayout.
 * This means that any unexpected client side errors that normally would lead
 * to the end user see this generic message pop up -
 * ('An error occurred while processing your request.
 * Please contact your local administrator') would not occur.
 * The client side error message along with the stack trace would be logged
 * to the server side.
 *
 * In the event that the rest service that logs the client side error fails,
 * the system will fall back to the standard behaviors and
 * and the end user will see the generic error message pop up.
 */
const setupCustomClientErrorHandler = function () {
    const enableServerLoggingClientErrors = serverConfigParams.get('enableServerLoggingClientErrors');

    const displayClientError = function (err) {
        dialog.alert(locale.get('anErrorOccurredMsg'), locale.get('anErrorOccurredTitle'));
        log.debug(err.stack ? err.stack : err);
    };

    const data = {};
    let stackStr;
    if (!util.isEmpty(enableServerLoggingClientErrors) && enableServerLoggingClientErrors.toLowerCase() === 'true') {
        options.clientErrorHandler = function (...args) {
            const [err] = args;
            stackStr = util.find(args, argument => !!argument.stack);

            data.item = [{
                name: 'message',
                value: stackStr.stack || err,
            }, {
                name: 'severity',
                value: 'error',
            }];

            http.post(services.generateUrl('/loggerService/log'), data, null, () => {
                displayClientError();
            });
        };
        /*
         * In non-SSO setups the gluOverride/core/internal/baseLayout is instantiated prior to
         * the user logging in while the REST services that retrieves
         * the client side configuration parameters is called only after a successful login -
         * therefore we need to initialize window.onerror here.
         * The reference options.clientErrorHandler still needs to be set as a refresh on the
         * browser while the user is already logged in will still set
         * window.onerror in the gluOverride/core/internal/baseLayout after this
         * code in the
         * setupCustomClientErrorHandler method has been processed
         * just like it does in SSO enabled environments.
         */
        window.onerror = options.clientErrorHandler;
    }
};

/**
 * The addEvents function
 * @param {HTMLElement | HTMLElement[]} el
 * @param {string} logsEndpoint
 * @param {string} authorization - Auth token
 * @param {string} hostname
 * @param {number} interval
 */
const addEvents = (el, logsEndpoint, authorization, hostname, interval) => {
    if (analyticsClient) {
        analyticsClient.addEvents(logsEndpoint, el, {
            hostname,
            interval,
            authorization,
        });
    }
};

/**
 * Method sends rich UX usage data to a specified service endpoint.
 */
const collectUXUsage = () => {
    http.get(serviceList.createLogURL, (response) => {
        const {
            createLogURL, token, hostName, interval,
        } = response;
        const el = document.body;
        addEvents(el, createLogURL, token, hostName, interval);
    });
};

afterAuthPromises.push(hostResponse => new Promise((resolveAfterAuthPromises, reject) => {
    // TODO: convert userinfo into normal model get
    http.get(`${services.userInfo}?${Math.random()}`, (response) => {
        userInfo.set(response);
        if (response.token) {
            cookie.set('x-csrf', response.token);
            const dataCollectorEnabled = gluStore.get('dataCollectorEnabled');
            if (!util.isEmpty(dataCollectorEnabled) && dataCollectorEnabled.toLowerCase() === 'true') {
                collectUXUsage();
            }
        }
        userInfo.set('brand', false);

        /**
         * if the 'alreadyLoggedInThisTab' indicator is not set which would be the case
         * with SSO deployments, set it now since this flag is also used to determine
         * whether the 'newTab' header should be included in the requests sent to the servers
         */
        if (util.isEmpty(gluStore.get('alreadyLoggedInThisTab'))) {
            gluStore.set('alreadyLoggedInThisTab', 'true');
        }

        const afterUserInfoPromises = [];

        // if entitlements have changed for this user, the flag resetListViewState is set
        if (response.resetListViewState || (hostResponse && hostResponse.resetListViewState)) {
            listViewConfig.getInstance().resetAll();
        }

        afterUserInfoPromises.push(new Promise((resolve, innerReject) => {
            locale.getAllResources({
                success() {
                    gluLocale.set(locale.toJSON());
                    // get the user's menu model after userInfo
                    menuModel.fetch({
                        url: services.profile,
                        cache: false,

                        success(result) {
                            store.set('menuModel', result);
                            resolve();

                            util.extend(
                                NavView,
                                {
                                    options: {
                                        menuModel: result,
                                    },
                                },
                            );

                            // check whether we need to load dynamic links
                            if (gluStore.get('loadDynamicLinks') === 'true') {
                                dynamiclinks.setupDynamicLinks();
                                gluStore.unset('loadDynamicLinks');
                            }

                            appBus.trigger('menuModel:loaded');
                        },

                        error: innerReject,
                    });
                    // Adding glu-validation-react
                    validators.messages = {
                        ...validators.initMessages(),
                        ...customValidatorMessages,
                        ...{ // PCM validators
                            alphaNumeric: '{0} must be alphanumeric',
                            email: '{0} must be a valid email address',
                            enterValid: 'Enter a valid {0}',
                            equalTo: '{0} must be equal to {1}',
                            equalToName: 'Must be equal to {0}',
                            invalid: '{0} is invalid',
                            isCommaSeparatedNumericalList: '{0} can only contain 0-9, or a comma separated list of numbers',
                            isNumeralsOnly: '{0} only supports numeric values',
                            isNumeric: '{0} must be numeric',
                            isWholeNumber: '{0} must be a whole number without a decimal point',
                            maxDigitsAfterDecimalPoint: '{0} must have no more than {1} digits after the decimal point',
                            maxLength: '{0} must be less than {1} characters long',
                            maxValue: '{0} must be less than {1}',
                            maxValueExclusive: '{0} must be less than or equal to {1}',
                            minLength: '{0} must be at least {1} characters long',
                            minValue: '{0} must be greater than or equal to {1}',
                            minValueExclusive: '{0} must be greater than {1}',
                            mustBeChanged: '{0} must be changed',
                            mustBeSelected: '{0} must be selected',
                            phone: '{0} must be a valid 10 digit US phone number',
                            required: '{0} is required',
                        },
                    };
                    validators.functions = {
                        ...validators.functions,
                        ...customValidators,
                    };
                },
            });
        }));


        /* load static language resource files */
        afterUserInfoPromises.push(new Promise((resolve) => {
            const combinedLocale = locale.toJSON();
            // get static locale files
            locale.loadLocale({
                success(result) {
                    util.extend(combinedLocale, result);
                    gluLocale.set(combinedLocale);
                    // localize select2
                    localizeSelect2();
                    const testTitle = gluLocale.get('workspace.digital.banking');
                    if (testTitle.indexOf('workspace.digital.banking') < 0) {
                        options.title = testTitle;
                    }
                    resolve();
                },

                error: reject,
            });
        }));

        /*
         * Load locale bundle for formatting and conversion
         * Moved out of Main to facilitate reuse
         */
        afterUserInfoPromises.push(loadLocaleBundle(userInfo.getLocale()));

        afterUserInfoPromises.push(new Promise((resolve) => {
            serverConfigParams.fetch({
                success() {
                    if (serverConfigParams.get('disableLogoutTimer') !== 'true') {
                        timers.autoLogoutTimer();
                    }
                    processDefinedResponseCodesLogoff();
                    integrationManager.callEvent('serverConfigParamsReady', serverConfigParams);
                    setupCustomClientErrorHandler();
                    resolve();
                },
            });
        }));

        afterUserInfoPromises.push(new Promise((resolve) => {
            applicationConfiguration.fetch({
                success() {
                    resolve();
                },
            });
        }));

        if (paymodeIntegration) {
            afterUserInfoPromises.push(fetchPrivileges());
        }

        afterUserInfoPromises.push(() => notificationClientStartup.setup());

        Promise.all(afterUserInfoPromises)
            .then(resolveAfterAuthPromises)
            .then(() => {
                userInfo.login();
                return userInfo.loginPromise;
            }).then(() => {
                const lastHttp = Date.now() - Number.parseInt(localStore.get('DBIQ_HTTP_TIME') || 0, 10);
                // 20 minutes = 1.2e6 ms. TODO - configure with an appConfigParam?
                const expireLVCTime = 1200000;
                if (lastHttp > expireLVCTime) {
                    window.setTimeout(() => {
                        listViewConfigStore.removeExpired(true);
                    }, 10000);
                }
                // Enable last request tracking for LVC continuity
                http.setLastRequestTimer(true);
            })
            .then(() => {
                integrationManager.callEvent('postAuth', userInfo);
            });
    }, reject);
}));

afterAuthPromises.push(() => new Promise((resolve, reject) => {
    workspaceHelper.list.fetch({
        // clear old workspaces
        reset: true,

        success() {
            resolve();
        },

        error() {
            // TODO - implement error handling
            reject();
        },
    });
}));

afterAuthPromises.push(() => new Promise((resolve, reject) => {
    DeeplinkModel.fetch({
        success(result) {
            store.set('entitledDeepLinks', result);
            resolve();
        },

        error: reject,
    });
}));
/** Validating the date field */
afterAuthPromises.push(() => {
    dateUtil.overrideDateValidator();
});

const isSimulation = !util.isNullOrUndefined(cookie.get('SIMULATIONSESSIONTOKEN'));
const initHost = function (result) {
    let setAuthTest = true;
    const resultDefined = !util.isNullOrUndefined(result);
    const ssoEnabled = (resultDefined && result.get('ssoEnabled') === 'true');
    const token = RetrieveUserCredentials.getToken();
    const resetPswdAppResourcePrefix = ['logon', 'button', 'common', 'prompt', 'passcode', 'mfa', 'workspaces'];

    if (ssoEnabled) {
        // if the application is enabled for SSO store flag in cache
        gluStore.set('ssoEnabled', 'true');
    }
    if (resultDefined) {
        // Initialize collecting UX usage information
        gluStore.set('dataCollectorEnabled', result.get('dataCollectorEnabled'));
    }

    if (!ssoEnabled) {
        options.promises.push(new Promise((resolve, reject) => {
            locale.getLoginResources({
                success() {
                    gluLocale.set(locale.toJSON());
                    resolve();
                },

                error() {
                    reject(loadingErrorMessage);
                },
            });
        }));

        if (token) {
            options.promises.push(new Promise(((resolve, reject) => {
                RetrieveUserCredentials.sync(token)
                    .then(
                        (response) => {
                            /*
                             * Storing temporary token.
                             * The temporary token is removed in passwordReset.js
                             * and submitted when the user submits the new password
                             */
                            store.set('emailResetPasswordToken', token);
                            options.emailResetPassword = response;
                            /*
                             * Get the login app resources based on the user's locale found
                             * through the Reset Password token.
                             */
                            locale.getLocales(resetPswdAppResourcePrefix, {
                                locale: (util.isNullOrUndefined(response) ? null
                                    : response.locale),
                                success() {
                                    locale.clearFetchList();
                                    gluLocale.set(locale.toJSON());
                                    resolve();
                                },
                                error() {
                                    reject(loadingErrorMessage);
                                },
                            });
                        },
                        (error) => {
                            options.emailResetPassword = { errorMessage: 'An unknown error has occurred.' };
                            if (error.status === 404) {
                                options.emailResetPassword = { errorMessage: `${error.status}: ${error.statusText} error` };
                                resolve();
                            } else {
                                reject();
                            }
                        },
                    );
            })));
        }

        if (!configuration.isAdmin()) {
            options.promises.push(new Promise((resolve, reject) => {
                liveMaintenanceCheck.fetch()
                    .then(
                        (res) => {
                            options.liveMaintenance = res.maintenanceMode;
                            resolve();
                        },
                        (xhr) => {
                            if (xhr.status === 404) {
                                /*
                                 * Ignore 404, because the service is up (we got this far)
                                 * and it probably means we're calling a server that doesn't
                                 * support the Live Maintenance Check.
                                 */
                                options.liveMaintenance = 0;
                                resolve();
                            } else {
                                reject(loadingErrorMessage);
                            }
                        },
                    );
            }));
        }
    }

    if (window.parent !== window) {
        messageController.start();
    }

    options.authTest = null;

    // Do not automatically make the rest call for the UserInfo (/user) if -
    //   1. MFA challenge is occurring at login
    //   2. System is not configured for SSO
    // As a last check, determine if there's currently a user session
    // in the browser's session storage. This last check is to ensure that we
    // can refresh the page and continue to be logged in.
    if (isExistLoginChallenge() ||
        (!configuration.isAdmin() && !ssoEnabled && !userInfo.isLoggedIn())) {
        setAuthTest = false;
    }

    // Last check - is this for client user simulation ? if yes we do want to set authTest
    if (isSimulation) {
        setAuthTest = true;
    }

    if (setAuthTest) {
        options.authTest = `${services.userInfo}?${Math.random()}`;
    }

    if (paymodeIntegration && addFullPagePaymodeIntegrationHooks) {
        addFullPagePaymodeIntegrationHooks(Controller, Router);
    }

    if (!userInfo.isLoggedIn()) {
        mfaManager.listen();
    }

    // initialize a new Host.
    host.loadGuest(Router, Controller, options);
};

serverConfigParams.fetch({
    success(result) {
        integrationManager.callEvent('serverConfigParamsReady', serverConfigParams);
        initHost(result);
    },
    error() {
        // proceed to instantiate host, even if error w/ rest (/getPrelogin)
        initHost();
    },
    isPreLoginFetch: true,
});
export default {};
