import CheckReview from 'common/dynamicPages/views/checkReview';
import $ from 'jquery';
import Model from '@glu/core/src/model';

export default CheckReview.extend({
    /**
     * @method getImageJsonData
     * @returns {{images: Array}}
     * Retrieve the json data for all images and return it
     */
    getImageJsonData() {
        const data = {};
        const frontImage = this.$el.find('#check-front-image');
        const backImage = this.$el.find('#check-back-image');
        let printImages = [];
        const imageArr = this.model.get('images');
        let content;

        data.headerItems = this.getHeaderObjectByIndex();
        data.frontSrc = frontImage.prop('src');
        data.frontAlt = frontImage.prop('alt');

        if (backImage.length > 0) {
            data.backSrc = backImage.prop('src');
            data.backAlt = backImage.prop('alt');
        }

        if (this.gridView) {
            content = this.gridView.$('.content').clone();

            // remove the view column, hidden toggle buttons, sr-only stuff
            content.find('th:first-child, tr td:first-child, button.grid-column-menu-trigger, .sr-only').remove();

            // convert button to label for headers
            content.find('button.txt').replaceWith(function () {
                return $(this).html();
            });

            data.grid = content.html();
        }

        printImages = imageArr.map(image => ({
            frontAlt: data.frontSrc,
            grid: data.grid,
            headerItems: data.headerItems,
            frontSrc: `data:image/jpeg;base64,${image.IMAGE}`,
        }));

        return {
            images: printImages,
        };
    },

    /**
     * @method getHeaderDataObject
     * @returns {{headerDataObject: Model}}
     * overriding the checkReview parent implementation
     * to create new model with the header data
     */
    getHeaderDataObject() {
        return new Model(this.model.get('headerData'));
    },
});
