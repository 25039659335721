export default ({ palette }) => ({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  root: {
    '& td': {
      padding: [40, 0],
      textAlign: 'center'
    },
    '& td::before': {
      content: '""',
      width: 30,
      height: 30,
      border: [4, 'solid'],
      borderLeftColor: 'transparent',
      borderRadius: '50%',
      color: palette.primary.normal,
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      display: 'block',
      animationName: '$rotate',
      animationTimingFunction: 'linear',
      margin: [0, 'auto', 10]
    }
  }
});
