import { createUseStyles } from '@glu/theming';

const styles = ({ palette }) => ({
    root: {
        maxWidth: '55vw',
    },
    tooltipHeading: {
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    tooltipDesciption: {
        textAlign: 'center',
    },
    tooltipExampleHeading: {
        color: palette.grey.G600,
        padding: 0,
        marginBottom: 0,
    },
    tooltipExampleContainer: {
        border: `1px dotted ${palette.grey.G400}`,
        padding: 5,
    },
});

export default createUseStyles(styles);
