import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import systemConfig from 'system/configuration';
import scroll from 'common/util/scroll';
import applicationConfiguration from 'system/webseries/models/applicationConfiguration';

export default {

    /**
     * Checks the application configuration flag to see if it is enabled.
     */
    isBankMaintainedRolesEnabled() {
        return applicationConfiguration.getValue('ROLE', 'bankMaintainedRoles') === '1';
    },

    /**
     * Bank maintained roles setting only apply on the client and if the global
     * setting is
     * enabled.
     */
    isBankMaintainedRole(role) {
        if (systemConfig.isAdmin() || !this.isBankMaintainedRolesEnabled()) {
            return false;
        }
        return role.BANKMAINTAINED === '1' || (role.get && role.get('BANKMAINTAINED') === '1');
    },

    // reusable error alert display handler
    errorAlert(alertRegion, errorResponse) {
        let value;

        if (util.isString(errorResponse)) {
            value = errorResponse;
        } else if (errorResponse?.confirms?.confirmResults?.[0]?.messages) {
            value = errorResponse.confirms.confirmResults[0].messages.join(' ');
        }
        if (!value) {
            if (errorResponse && errorResponse.message) {
                value = errorResponse.message.join(' ');
            } else {
                value = locale.get('common.server.error');
            }
        }

        // display notification message
        alertRegion.show(alert.danger(value, {
            canDismiss: true,
            animate: true,
        }));

        util.defer(() => {
            scroll.scrollToFirstError();
        });
    },
};
