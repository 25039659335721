/* istanbul ignore file */
export const styles = (theme) => {
  const {
    palette: {
      primary,
      text
    },
    typography
  } = theme;
  return ({
    buttonContainer: {
    },
    chipCount: {
      color: primary.normal,
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", "sans-serif"',
      fontSize: 12,
      fontWeight: typography.fontWeightHeavy,
      textTransform: 'uppercase'
    },
    chipName: {
      fontSize: 10,
      fontWeight: typography.fontWeightHeavy,
      textTransform: 'uppercase'
    },
    chipWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      margin: [0, 0, 24, 24]
    },
    filterConditions: {
      width: 'auto'
    },
    filterToggle: {
      '& $buttonContainer': {
        '& $removeButton': {
          padding: 4
        },
        '& $toggleButton': {
          padding: [4, 8, 4, 9]
        },
        '& button': {
          color: text.light,
          fontSize: 12
        },
        alignItems: 'center',
        backgroundColor: primary.darkBackground || '#37435c',
        borderRadius: 30,

        display: 'flex',

        fontSize: 12,

        textAlign: 'left'
      },
      display: 'inline-block',
      margin: [8, 8, 0, 0]
    },
    removeButton: {
      '& svg': {
        fill: text.light
      }
    },
    toggleButton: {
    }
  });
};

export default styles;
