import React from 'react';
import PropTypes from 'prop-types';
import BaseFilterChips from './BaseFilterChips';

export function FilterChipsComponent({
  data,
  htmlId,
  onChange,
  showValues
}) {
  const { filters } = data;

  const combinedFilters = filters.reduce((combined, item) => {
    const { field } = item;
    if (combined.has(field)) {
      combined.set(field, [...combined.get(field), item]);
      return combined;
    }
    combined.set(field, [item]);
    return combined;
  }, new Map());

  const handleChange = ({ field, id }) => {
    const update = filters.filter((filter) => filter.id !== id);
    const siblings = update.filter((filter) => filter.field === field);
    if (siblings.length !== 1) {
      onChange({
        errors: {},
        name: 'filters',
        value: update
      });
      return;
    }
    const cleaned = update.map((filter) => {
      if (filter.field !== field) {
        return filter;
      }
      const { operator, ...rest } = filter;
      return { ...rest };
    });
    onChange({
      errors: {},
      name: 'filters',
      value: cleaned
    });
  };

  if (!filters.length) {
    return null;
  }

  return (
    <BaseFilterChips
      showValues={showValues}
      htmlId={htmlId}
      groupedFilters={[...combinedFilters.values()]}
      onFilterRemove={handleChange}
      onFilterSelect={handleChange}
    />
  );
}

FilterChipsComponent.propTypes = {
  data: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({
      criteriaDisplay: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      filterData: PropTypes.shape({}).isRequired,
      id: PropTypes.string.isRequired,
      nameDisplay: PropTypes.string.isRequired,
      operator: PropTypes.oneOf(['AND', 'OR'])
    })).isRequired
  }).isRequired,
  htmlId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  /**
   * Pass true if values should be expanded by default or
   * you need to change visibility programmatically
   */
  showValues: PropTypes.bool
};

FilterChipsComponent.defaultProps = {
  showValues: false
};

export default FilterChipsComponent;
