import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { PinNextIcon } from '@glu/icons-react';
import {
  Button, TERTIARY, SMALL
} from '@glu/buttons-react';
import locale from '@glu/locale';
import { AccessibilityText } from '@glu/utilities-react';
import styles from './PinColumn.styles';

const PinColumn = ({
  classes,
  column,
  onChange,
  pinnedClear
}) => {
  const [pinned, setPinned] = useState();

  useEffect(() => {
    onChange(pinned);
  }, [pinned]);

  useEffect(() => {
    setPinned(undefined);
  }, [pinnedClear]);

  return (
    <div className={classes.header}>

      <div className={classes.menuHeader}>
        <PinNextIcon className={classes.icon} />
        <span className={classes.label}>{locale.get('gridReact.headerCell.pinColumn')}</span>
        {!column.pinned || (column.pinned && column.pinned === 'right') ? (
          <Button
            onClick={() => setPinned('left')}
            className={`${classes.button} ${pinned === 'left' ? classes.pinned : ''}`}
            text={(
              <span>
                <AccessibilityText>
                  {locale.get('gridReact.headerCell.pin')}
                </AccessibilityText>
                <span>
                  {locale.get('gridReact.headerCell.left')}
                </span>
              </span>
            )}
            variant={TERTIARY}
            size={SMALL}
          />
        ) : null}

        {!column.pinned && <span className={classes.separator} />}

        {!column.pinned || (column.pinned && column.pinned === 'left') ? (
          <Button
            onClick={() => setPinned('right')}
            className={`${classes.button} ${pinned === 'right' ? classes.pinned : ''}`}
            text={(
              <span>
                <AccessibilityText>
                  {locale.get('gridReact.headerCell.pin')}
                </AccessibilityText>
                <span>
                  {locale.get('gridReact.headerCell.right')}
                </span>
              </span>
            )}
            variant={TERTIARY}
            size={SMALL}
          />
        ) : null}

        {column.pinned && (
        <>
          <span className={classes.separator} />
          <Button
            onClick={() => setPinned(null)}
            className={`${classes.button} ${pinned === null ? classes.pinned : ''}`}
            text={locale.get('gridReact.headerCell.unpin')}
            variant={TERTIARY}
            size={SMALL}
          />
        </>
        )}
      </div>
    </div>
  );
};

PinColumn.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    colId: PropTypes.string.isRequired,
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
    pinned: PropTypes.oneOf(['left', 'right']),
    removeEventListener: PropTypes.func,
    userProvidedColDef: PropTypes.shape({
      filterable: PropTypes.bool,
      sort: PropTypes.string,
      sortable: PropTypes.bool
    }).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  pinnedClear: PropTypes.bool
};

PinColumn.defaultProps = {
  pinnedClear: false
};

export default withStyles(styles)(PinColumn);
