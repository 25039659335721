import BaseLoanView from 'app/loans/views/baseLoanView';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import $ from 'jquery';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import Loan from 'app/loans/models/modifyTemplate';
import Balance from 'app/loans/models/balance';
import Details from 'app/loans/models/details';
import balanceTmpl from 'app/loans/views/balance.hbs';
import tmpl from 'app/loans/views/template/viewTemplate/view.hbs';
import loans from 'app/loans/api/common';
import Constants from 'app/loans/constants';
import ScheduleWidget from 'app/loans/views/template/scheduleWidgetHelper';
import AuditHistorySetup from 'app/auditHistory/util/auditHistorySetup';
import systemConfig from 'system/configuration';
import { maskValue } from 'common/util/maskingUtil';

export default BaseLoanView.extend({
    initialize(options) {
        this.model = new Loan();
        this.paymentOptions = loans.getAvailablePaymentOptions(options.preferences);
        this.data = this.model.convertServerJSONToModelAttributes(options.model);
        this.title = options.title || '';
        this.isTemplate = true;
        this.debit = options.debitAccountCollection || new Collection();
        this.credit = options.creditAccountCollection || new Collection();
        this.debitInquiryId = options.debitInquiryId;
        this.creditInquiryId = options.creditInquiryId;
        this.payMethod = options.payMethod;
        this.typeCode = options.typeCode;
        this.productCode = options.productCode;
        this.functionCode = options.functionCode;
        this.accountDetails = {
            debit: {},
            credit: {},
        };
        this.model.set({
            _oldPaymentType: this.typeCode,
            paymentType: this.typeCode,
            PARENTUSERGROUP: userInfo.get('group'),
        });
        this.blockedDates = [];
        this.cutoffTimes = {};
        this.model.set(this.data);
        this.savedOptions = this.getSavedOptions(this.data, this.data.AMOUNTTYPES_SELECTED);
        this.savedCollection = new Collection(this.savedOptions);
    },

    template: tmpl,

    events: {
        'click @ui.$viewHistory': 'viewAuditHistory',
    },

    ui: util.extend(
        {},
        BaseLoanView.prototype.ui,
        {
            $accountName: '[name="accountName"]',
            $clientAccountName: '[name="clientAccountName"]',
            $bankName: '[name="bankName"]',
            $currency: '[name="currency"]',
            $creditBalance: '.to-account .balanceTotal',
            $debitBalance: '.from-account .balanceTotal',
            $debitAccount: '#debit-account',
            $creditAccount: '#credit-account',
            $transactionDate: '#credit-transaction-date',
            $appliesTo: '[name="apply-to"]',
            $creditAmount: '[name="CREDIT_AMOUNT"]',
        },
    ),

    regions: {
        schedRegion: '[data-hook="getSchedPayRegion"]',
    },

    onRender() {
        let context;

        if (!this.hasLoadedRequiredData()) {
            moveToTopCheck(this.model);
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData(true);
        } else {
            // needed to retrieve the balances
            this.setDebitDetails(this.model.get('DEBIT_ACCOUNT_NUMBER'));
            this.setCreditDetails(this.model.get('BENE_ACCOUNT'));
            if (this.paymentOptions.length === 1) {
                this.$el.find('#amount-label').text('Amount');
            }

            /**
             * set up schedule section
             */

            if (this.typeCode === 'LOANPAY') {
                if (systemConfig.isAdmin()) {
                    context = {
                        serviceName: Constants.LOAN_PAYMENT_TEMPLATE_ADMIN_SERVICE_NAME,
                    };
                } else {
                    context = {
                        serviceName: Constants.LOAN_PAYMENT_SERVICE_NAME,
                    };
                }
            } else {
                context = {
                    serviceName: Constants.LOAN_DRAW_SERVICE_NAME,
                };
            }

            this.scheduleWidgetObj = new ScheduleWidget({
                state: 'view',
                context,
                model: this.model,
                parentView: this,
            });
            this.schedRegion.show(this.scheduleWidgetObj);
            this.displaySummaryTotal(this.isTemplate);
            // setup for audit history
            AuditHistorySetup.setup(this, this.model);
        }
    },

    setDebitDetails(id) {
        const self = this;
        // get the model from the collection by id
        const accountModel = this.debit.get(id);
        if (accountModel) {
            const currency = this.model.parse(accountModel.get('mapDataList')).Debit_Currency;
            this.details = new Details({
                inquiryId: this.debitInquiryId,
                typeCode: this.typeCode,
                account: accountModel.get('name'),
                filterParam: 'Credit',
                currency,
            });
            this.details.fetch({
                success(model) {
                    const mapDataList = model.get('mapDataList');
                    const account = self.model.parse(mapDataList);

                    self.accountDetails.debit = self.model.parse(mapDataList);
                    self.showBalance(accountModel.get('name'), self.typeCode, account.Debit_Bank_Code, account.Debit_Currency, self.ui.$debitBalance);
                },
            });
        }
    },

    setCreditDetails(id) {
        const self = this;
        // get the model from the collection by id
        const accountModel = this.credit.get(id);
        if (accountModel) {
            const currency = this.model.parse(accountModel.get('mapDataList')).Credit_Currency;
            this.details = new Details({
                inquiryId: this.creditInquiryId,
                typeCode: this.typeCode,
                account: accountModel.get('name'),
                filterParam: 'Debit',
                currency,
            });
            this.details.fetch({
                success(model) {
                    const mapDataList = model.get('mapDataList');
                    const account = self.model.parse(mapDataList);
                    self.accountDetails.credit = self.model.parse(mapDataList);
                    self.showBalance(accountModel.get('name'), self.typeCode, account.Bene_Bank_Code, account.Credit_Currency, self.ui.$creditBalance);
                },
            });
        }
    },

    showBalance(accountNumber, typeCode, bankCode, currency, selector) {
        const prefix = (selector === this.ui.$creditBalance) ? locale.get('common.remainingBalance') : locale.get('common.availableBalance');

        this.balance = new Balance({
            accountNumber,
            typeCode: this.typeCode,
            bankCode: this.bankCode,
            currency,
        });
        this.balance.fetch({
            success(model) {
                if (model.get('balance')) {
                    const jsonTmpl = {
                        text: prefix,
                        balance: model.get('balance'),
                        timestamp: model.get('balanceAsOf'),
                        currency: model.get('currency'),
                    };
                    $(selector).text(balanceTmpl(jsonTmpl));
                } else {
                    $(selector).text('');
                }
            },
        });
    },

    cancel() {
        window.history.back();
    },

    viewAuditHistory(e) {
        e.stopImmediatePropagation();
        loans.openDialog(this);
    },

    templateHelpers() {
        const self = this;
        return {
            getDebitSubaccount() {
                const subaccountNumber = self.model.get('DEBIT_SUBACCOUNT_NUM');
                return util.isEmpty(subaccountNumber) ? '' : ` - ${subaccountNumber}`;
            },

            getBeneSubaccount() {
                const subaccountNumber = self.model.get('BENE_SUBACCOUNT_NUM');
                return util.isEmpty(subaccountNumber) ? '' : ` - ${subaccountNumber}`;
            },

            getAvailableBalance() {
                return '';
            },

            getRemainingBalance() {
                return '';
            },

            originLabel() {
                return loans.getOriginLabel(self.typeCode);
            },

            destinationLabel() {
                return loans.getDestinationLabel(self.typeCode);
            },

            savedOptions() {
                /*
                 * @TODO Add logic to only display the CREDIT_AMOUNT
                 *  when only one option is saved
                 */
                return self.savedCollection.toJSON();
            },

            getType() {
                return self.title;
            },

            hasMultipleOptions() {
                return (self.typeCode === 'LOANPAY');
            },

            approved() {
                return loans.extractApproversFromModel(self.model.toJSON());
            },

            unapproved() {
                return (self.model.get('UNAPPROVED_BY') !== '');
            },

            userRejected() {
                return (self.model.get('APPROVER_REJECTION_BY') !== '');
            },

            getRestricted() {
                return (self.model.get('RESTRICTTEMPLATE_FLAG') === '1') ? locale.get('responsedlg.yes') : locale.get('responsedlg.no');
            },

            hasBeenModified: this.model.get('MODIFIED_BY') !== '',
            restored: this.model.get('RESTORED_BY') !== '',
            getAmountText: locale.get(`loans.${self.typeCode.toLowerCase()}.amount`),
            hasPanelProfileCode: this.model.get('PANELPROFILECODE') !== '',

            maskIt: value => maskValue(value),
        };
    },
});
