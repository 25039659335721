var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noRoles") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAllowFXSelection") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":1},"end":{"line":46,"column":8}}})) != null ? stack1 : "")
    + "	\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"viewModeRecords") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":4},"end":{"line":73,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPanelApproval") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":4},"end":{"line":83,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset class=\"form-inline row\">\n        <legend class=\"sr-only\">Role Info</legend>\n\n        <p class=\"form-control-static\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"role.norolesassigned",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":72}}}))
    + "</p>\n\n    </fieldset>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"radio\">\n	        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.Restriction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":15},"end":{"line":15,"column":46}}}))
    + "</span>\n	        <div class=\"radio-inline\">\n	            <input type=\"radio\"\n	                   name=\"ALLOWFXUSDUSER\"\n	                   value=\"0\"\n	                   data-bind=\"model\"\n	                   id=\"ALLOWFXUSDUSER-0\"\n	                   disabled\n	            <label for=\"allowFXUSDUser0\">\n	                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.USD.Only",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":17},"end":{"line":24,"column":42}}}))
    + "\n	            </label>\n	        </div>\n	        <div class=\"radio-inline\">\n	            <input type=\"radio\"\n	                   name=\"ALLOWFXUSDUSER\"\n	                   value=\"1\"\n	                   data-bind=\"model\"\n	                   id=\"ALLOWFXUSDUSER-1\"\n                       disabled\n	            <label for=\"allowFXUSDUser1\">\n	                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.Allow.FX",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":17},"end":{"line":35,"column":42}}}))
    + "\n	            </label>\n	        </div>\n	        <a data-toggle=\"popover\"\n	           data-trigger=\"hover focus\"\n	           role=\"button\"\n	           data-placement=\"auto\"\n	           data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.user.tooltip",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":26},"end":{"line":42,"column":58}}}))
    + "\">\n	            <span class=\"icon-info\"></span>\n	        </a>\n	    </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <fieldset class=\"form-inline row\">\n            <legend class=\"sr-only\">Role Info</legend>\n\n            <div class=\"form-group col-3\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.rolename",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":23},"end":{"line":53,"column":54}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":54,"column":47},"end":{"line":54,"column":53}}}) : helper)))
    + "</p>\n            </div>\n\n            <div class=\"form-group col-3\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.roledesc",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":23},"end":{"line":58,"column":54}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":59,"column":47},"end":{"line":59,"column":56}}}) : helper)))
    + "</p>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"bankMaintainedRolesEnabled") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":71,"column":19}}})) != null ? stack1 : "")
    + "        </fieldset>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group col-2\">\n                    <label>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"PS.BankMaintained",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":27},"end":{"line":64,"column":57}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BANKMAINTAINED") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":69,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <p class=\"form-control-static\">\n                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"BANKMAINTAINED") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":67,"column":49}}}))
    + "\n                      </p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <fieldset class=\"form-inline row\">\n            <div class=\"form-group col-2\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.approverType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":23},"end":{"line":77,"column":52}}}))
    + "</label>\n                <p class=\"form-control-static\">\n                    "
    + alias2(((helper = (helper = lookupProperty(helpers,"APPROVERTYPE") || (depth0 != null ? lookupProperty(depth0,"APPROVERTYPE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"APPROVERTYPE","hash":{},"data":data,"loc":{"start":{"line":79,"column":20},"end":{"line":79,"column":36}}}) : helper)))
    + "\n                </p>\n            </div>\n        </fieldset>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <h4>\n        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"rolebased.assign-roles-header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":87,"column":8},"end":{"line":87,"column":50}}}))
    + "\n    </h4>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTxClient") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":0},"end":{"line":102,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAllowFXSelection") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":1},"end":{"line":136,"column":8}}})) != null ? stack1 : "")
    + "	\n   <div data-region=\"listBuilderRegion\"></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row\">\n        <div class=\"copy-user-container form-group col-4\">\n            <label for=\"copyUserSelect\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.copy-user",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":93,"column":40},"end":{"line":93,"column":72}}}))
    + "</label>\n            <input type=\"hidden\" id=\"copyUserSelect\" class=\"form-control copy-user-select\" name=\"COPYFROMUSER\" data-bind=\"model\" placeholder="
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":94,"column":141},"end":{"line":94,"column":167}}}))
    + ">\n        </div>\n\n        <div class=\"bank-maintained-roles-note\" data-region=\"bankMaintainedRolesNote\">\n        </div>\n\n        <div class=\"col-4\" data-region=\"approverTypeRegion\"></div>\n    </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"radio\">\n	        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.Restriction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":106,"column":15},"end":{"line":106,"column":46}}}))
    + "</span>\n	        <div class=\"radio-inline\">\n	            <input type=\"radio\"\n	                   name=\"ALLOWFXUSDUSER\"\n	                   value=\"0\"\n	                   data-bind=\"model\"\n	                   id=\"ALLOWFXUSDUSER-0\"\n	            <label for=\"allowFXUSDUser0\">\n	                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.USD.Only",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":114,"column":17},"end":{"line":114,"column":42}}}))
    + "\n	            </label>\n	        </div>\n	        <div class=\"radio-inline\">\n	            <input type=\"radio\"\n	                   name=\"ALLOWFXUSDUSER\"\n	                   value=\"1\"\n	                   data-bind=\"model\"\n	                   id=\"ALLOWFXUSDUSER-1\"\n                       checked\n	            <label for=\"allowFXUSDUser1\">\n	                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.Allow.FX",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":125,"column":17},"end":{"line":125,"column":42}}}))
    + "\n	            </label>\n	        </div>\n	        <a data-toggle=\"popover\"\n	           data-trigger=\"hover focus\"\n	           role=\"button\"\n	           data-placement=\"auto\"\n	           data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.user.tooltip",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":132,"column":26},"end":{"line":132,"column":58}}}))
    + "\">\n	            <span class=\"icon-info\"></span>\n	        </a>\n	    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":139,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});