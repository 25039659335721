import Layout from '@glu/core/src/layout';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import $ from 'jquery';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import template from './batchChildEntry.hbs';
import ESendPreviewView from './eSendPreviewView';

export default Layout.extend({
    template,
    loadingTemplate,
    modalClass: 'modal-2xlg',
    // PCM RELATED
    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },
    events: {
        'click @ui.$navLinks': 'handleToggleButtonClick',
        'keypress @ui.$navLinks': 'handleKeyPress',
    },
    // PCM RELATED END

    initialize(options) {
        const self = this;
        this.batchChildView = options.batchChildView;
        this.state = options.state || 'edit';
        // Sets up Modal Attributes
        this.dialogTitle = this.getTitle(options);
        this.updateModalClass(options);
        this.dialogButtons = [];
        if (this.state === 'edit') {
            this.dialogButtons.push({
                text: locale.get('common.save'),
                className: 'btn btn-primary',
                callback: 'save',
            });
        }

        this.dialogButtons.push({
            text: locale.get('common.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        });

        // handle any locked fields setup for parent and child
        let lockedFields = [];
        const hasModel = this.batchChildView.model;
        const hasParentModel = this.batchChildView.parentModel;
        if (hasParentModel && this.batchChildView.parentModel.has('LOCKED_FIELDS')) {
            lockedFields = this.batchChildView.parentModel.get('LOCKED_FIELDS').split(',');
        }
        if (hasModel && this.batchChildView.model.has('LOCKED_FIELDS')) {
            lockedFields = lockedFields.concat(this.batchChildView.model.get('LOCKED_FIELDS').split(','));
        }
        lockedFields = util.uniq(lockedFields);
        let functionCode;
        if (this.batchChildView.options.context.actionData) {
            ({ functionCode } = this.batchChildView.options.context.actionData);
        } else if (self.batchChildView.options.context.actionContext) {
            ({ functionCode } = this.batchChildView.options.context.actionContext);
        }

        // sync them all together and continue if there are any
        if (util.compact(lockedFields).length > 0) {
            if (hasModel) {
                this.batchChildView.model.lockedFields = lockedFields;
                this.batchChildView.model.set('LOCKED_FIELDS', lockedFields.join(','));
            }
            if (hasParentModel) {
                this.batchChildView.parentModel.lockedFields = lockedFields;
                this.batchChildView.parentModel.set('LOCKED_FIELDS', lockedFields.join(','));
            }
            lockedFields.forEach((fieldName) => {
                if (hasModel && self.batchChildView.model.has(fieldName)) {
                    self.batchChildView.model.fieldData[fieldName].locked = true;
                    if (functionCode !== 'TMPL' && functionCode !== 'BHTMPL') {
                        self.batchChildView.model.fieldData[fieldName].protected = true;
                    }
                }
            });
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            /*
             * When a grandChild save fails, this listern has already been added,
             * so before adding the listener again, remove it, to make sure that we
             * don't have multiple callbacks firing
             */
            this.stopListening(this.batchChildView, 'grandChildSaveSuccess');
            this.listenTo(this.batchChildView, 'childModalSaveSuccess', this.handleChildAdd);
            this.listenTo(this.batchChildView, 'grandChildSaveSuccess', this.handleGrandChildAdd);
            this.listenTo(this.batchChildView, 'grandChildDeleteSuccess', this.handleGrandChildDelete);
            this.listenTo(this.batchChildView, 'modelAction:error', this.actionError);
            this.batchChildRegion.show(this.batchChildView);
        } else {
            this.loadRequiredData();
        }
    },

    updateModalClass(opts) {
        const { subType } = opts.batchChildView.context;

        /*
         * (HACK)
         * Subtype NACHA childviews have a beneficiary grid that requires fluid sizing
         * is a temporary change made until new beneficiary content is added
         */
        if (subType === 'NACHA') {
            this.modalClass += ' fluid-sizing beneficiary-modal';
        }
    },

    /**
     * @method getTitle
     * @param {object} opts - function to get the title to be displayed
     * - Function returns the title to be displayed
     * - Will look for different fields that has value and then return :  action - name
     * - otherwise just return the action
     */
    getTitle(opts) {
        let name = this.state === 'edit' ? locale.get('action.modify') : locale.get(`action.${this.state}`);
        const childModel = opts.batchChildView.model;
        const { subType } = opts.batchChildView.context;
        const dash = ' - ';

        if (opts.batchChildView.state === 'insert') {
            // this is the only way to determine the payment type during the insert mode.
            if (subType === 'NACHA') {
                return locale.get('ACH.AddBene');
            }
            return locale.get('title.addItems');
        }
        if (childModel.get('CSPARENTNAME')) {
            name = name + dash + childModel.get('CSPARENTNAME');
        } else if (childModel.get('TAXNAME_TYPECODE')) {
            name = name + dash + childModel.get('TAXNAME_TYPECODE');
        } else if (childModel.get('RECEIVCOMPNAME')) {
            name = name + dash + childModel.get('RECEIVCOMPNAME');
        } else if (childModel.get('INDVNAME')) {
            name = name + dash + childModel.get('INDVNAME');
        } else if (childModel.get('CHECKNUM')) {
            name = name + dash + locale.get('ACH.CheckNumber') + dash + childModel.get('CHECKNUM');
        } else if (childModel.get('PAYEENAME_1')) { // PCM-848
            name = name + dash + childModel.get('PAYEENAME_1');
        }
        return name;
    },

    isLockedFieldsEnabled() {
        const functionCode = this.batchChildView.parentModel && this.batchChildView.parentModel.get('FUNCTION');
        const isTmpl = functionCode === 'TMPL' || functionCode === 'BHTMPL';
        let isLockedFieldsEnabled = false;
        if (this.batchChildView && this.batchChildView.parentModel
            && this.batchChildView.parentModel.fieldData
            && this.batchChildView.parentModel.fieldData.ACHALLOWLOCKEDFIELDSFROMTMPL) {
            isLockedFieldsEnabled = (this.batchChildView.parentModel.fieldData.ACHALLOWLOCKEDFIELDSFROMTMPL.value === '1');
        }
        return (isTmpl && isLockedFieldsEnabled);
    },
    // PCM RELATED
    isCorrectTypeForESendPreview() {
        if (this.batchChildView.parentModel.get('STATUS') === 'RL' &&
            this.batchChildView.parentModel.get('PRODUCT') === 'USACH'
            && this.batchChildView.model.get('ESEND_STATUS')
        ) {
            return true;
        }
        return false;
    },

    templateHelpers() {
        const self = this;

        return {
            hasSave() {
                return (self.state === 'edit');
            },
            lockedFieldsEnabled() {
                return self.isLockedFieldsEnabled();
            },
            correctTypeForESendPreview() { // PCM RELATED
                return self.isCorrectTypeForESendPreview();
            },
        };
    },

    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    handleChildAdd() {
        this.trigger('childSaved');
    },

    handleGrandChildAdd() {
        const self = this;
        self.batchChildView.handleGrandChildAdd();
        // change the button label back to  insert (on Modify it was changed to Update)
    },

    handleGrandChildDelete() {
        const self = this;
        self.batchChildView.handleGrandChildDelete();
    },

    save() {
        // update parent with all locked fields if they exist
        let lockedFields = [];
        if (this.batchChildView.model && this.batchChildView.model.has('LOCKED_FIELDS')) {
            lockedFields = this.batchChildView.model.get('LOCKED_FIELDS').split(',');
        }
        if (this.batchChildView.parentModel && this.batchChildView.parentModel.has('LOCKED_FIELDS')) {
            lockedFields = lockedFields.concat(this.batchChildView.parentModel.get('LOCKED_FIELDS').split(','));
        }
        lockedFields = util.uniq(lockedFields);
        this.batchChildView.parentModel.set('LOCKED_FIELDS', lockedFields.join(','));
        this.batchChildView.parentModel.lockedFields = lockedFields;

        // NH-46104 - Clear the Status_Description when modified
        if (this.batchChildView.model && this.batchChildView.context.actionMode === 'modify') {
            this.batchChildView.model.set(
                'STATUS_DESCRIPTION',
                '',
                {
                    silent: true,
                },
            );
        }
        /*
         * in a very specific scenario, the RECEIVABA value contains the drop down
         * value which is too long.  It must be the MAP value.
         * This should only execute if it is determined that the RECEIVABA value has
         * the - char in it, which would indicate it is not valid
         */
        if (this.batchChildView.model && this.batchChildView.model.get('RECEIVABA') && this.batchChildView.model.get('RECEIVABA').indexOf('-') > 0) {
            this.batchChildView.model.set(
                'RECEIVABA',
                this.batchChildView.model.get('RECEIVABA_MAP'),
                {
                    silent: true,
                },
            );
        }
        // same as above, but on SECOND_BANKCODE
        if (this.batchChildView.model && this.batchChildView.model.get('SECOND_BANKCODE') && this.batchChildView.model.get('SECOND_BANKCODE').indexOf('-') > 0) {
            const secondBankCode = this.batchChildView.model.get('SECOND_BANKCODE');
            this.batchChildView.model.set('SECOND_BANKCODE', secondBankCode.substring(0, secondBankCode.indexOf('-')), {
                silent: true,
            });
        }
        this.batchChildView.saveModalChild();
        this.batchChildView.parentModel.set('childCanceled', false);
    },

    cancel() {
        this.batchChildView.parentModel.set('childCanceled', true);
        this.trigger('childCanceled');
    },

    actionError(model) {
        this.renderMessage(model.error);
        this.alertRegion.$el.hide();
        $('#modal-body').animate({
            scrollTop: 0,
        }, 300);
        this.alertRegion.$el.fadeIn(600, () => this.alertView.$el.focus());
    },

    renderMessage(errorParam) {
        const error = errorParam;
        // temporarily separate multiple errors with a space
        let message = null;
        if (error === null || error === undefined) {
            return;
        }
        message = error.message.join(' ');
        if (error.confirms && error.confirms.confirmResults) {
            error.confirms.confirmResults[0].confirmData = null;
        }

        const confirms = new Confirms({
            confirms: error.confirms,
        });
        // display notification message
        this.alertView = alert.danger(
            message,
            {
                details: confirms,
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },
    // PCM RELATED
    handleToggleButtonClick(e) {
        this.changeStep(e.currentTarget.getAttribute('data-step'));
    },

    handleKeyPress(e) {
        // enter key
        if (e.which === 13) {
            this.changeStep(e.currentTarget.getAttribute('data-step'));
        }
    },
    changeStep(step) {
        if (step === 'preview') {
            this.eSendPreview = new ESendPreviewView({
                model: this.batchChildView.model,
            });
            this.batchChildRegion.show(this.eSendPreview);
        } else {
            this.batchChildRegion.show(this.batchChildView);
            if ($('#downloadButton').length) {
                $('#downloadButton').remove();
            }
        }
        this.ui.$navLinks.parent().removeClass('is-active');
        this.ui.$navLinks.filter(`[data-step=${step}]`).parent().addClass('is-active');
    },
});
