import constants from 'app/balanceAndTransaction/constants';
import systemConfig from 'system/configuration';
import TransactionListView from './depositTransactionListView';

const PreviousDayDepositTransactionListView = TransactionListView.extend({
    accountType: 'DEPOSIT',

    /**
     * provides a part of The URL to hit for Previous Day Transactions
     * @method getServiceName - extend
     * @return {String} A part of the URL to hit for Previous Day Transactions
     */

    getServiceName() {
        return `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX + constants.PREVIOUS_DAY_PREFIX}getTransactions`;
    },

    /**
     * provides the URL to hit for print/export on Previous Day Transactions
     * @method getExportPrintURL - extend
     * @return {String} the URL to hit for print/export on Previous Day Transactions
     */

    getExportPrintURL() {
        return systemConfig.isAdmin() ? constants.PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL
            : constants.ASYNC_PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL;
    },

    /**
     * provides the typeCode for Previous Day Transactions
     * @method getTypeCode - extend
     * @return {String} provides the typeCode for Previous Day Transactions
     */

    getTypeCode() {
        return constants.BTR_TYPECODES.PREVIOUS.TRANSACTIONS;
    },

    /**
     * provides ability for displayAccountTransactionBalance to be shown or hidden on
     * Transaction drilldown
     * should be hidden for Previous Day Transaction drilldown
     * @method templateHelpers
     * @return {object} provides ability for displayAccountTransactionBalance to
     * be shown or hidden on Transaction drilldown
     */

    templateHelpers() {
        return {
            displayAccountTransactionBalance: this.displayTransactionTotals,

            gridUtilityOptions: {
                hasRefresh: false,
            },

            hideBackHeader: () => this.hideBackHeader,
        };
    },
});

export default PreviousDayDepositTransactionListView;
