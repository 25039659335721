import { ItemView, util } from '@glu/core';

import template from './flexDropdownItemView.hbs';

function castToType(value, type) {
  if (typeof value === type || value === undefined || value === null) { // eslint-disable-line valid-typeof
    return value;
  }

  switch (type) {
    case 'number':
      return Number(value);

    case 'string':
      return String(value);

    case 'boolean':
      return value === 'true';

    default:
      return value;
  }
}

export default ItemView.extend({
  template,
  noDefaultBehaviors: true,
  noGluEvents: true,

  modelEvents: {
    change: 'render'
  },

  events() {
    const events = {
      'change input:checkbox': 'clickParentMultiSelectItem',
      'click a': 'clickItem'
    };

    if (this.options.tableView) {
      return util.extend(events, {
        click: 'clickItem'
      });
    }

    return events;
  },

  attributes() {
    return {
      'data-value': this.model.get('id')
    };
  },

  tagName() {
    const tagName = this.options.showGroups ? 'ul' : 'li';

    return this.options.tableView ? 'tr' : tagName;
  },

  className() {
    let className = '';

    if (this.options.showGroups && !this.options.tableView) {
      return className;
    }

    className += 'selectable';

    if (this.model.get('selected')) {
      className += ' on selected';
    }

    if (this.model.get('child') !== undefined && this.model.get('child').length) {
      className += ' parent';
    }
    if (this.model.get('hide')) {
      className += ' hide';
    }

    if (this.options.tableView) {
      className += ' table-view';
    }

    return className;
  },

  initialize() {
    this.showGroups = this.options.showGroups && !this.options.tableView;
  },

  templateHelpers() {
    return util.extend({}, util.omit(this.options, 'model'), {
      showGroups: this.showGroups,
      cid: this.model.cid
    });
  },

  /**
   * Handles clicks (or enter press) on single items.
   *
   * @param {event} e - DOM event
   */
  clickItem(e) {
    e.preventDefault();
    e.stopPropagation();

    const $target = !this.showGroups ? this.$el : this.$(e.currentTarget);

    this.trigger('select', {
      id: this.showGroups ? castToType($target.data('value'), typeof this.model.get('id')) : this.model.get('id'),
      label: $target.text(),
      isParent: $target.hasClass('parent')
    });
  },
  /**
   * Handles clicks (or enter press) on parent node's checkbox (multi select option enabled).
   *
   * @param {event} e - DOM event
   */
  clickParentMultiSelectItem(e) {
    e.preventDefault();
    e.stopPropagation();

    const isParent = this.$el.hasClass('parent');
    const $target = this.$(e.currentTarget);
    const id = $target.attr('value');

    if (!isParent && !this.showGroups) {
      this.clickItem(e);
      return;
    }

    this.trigger('multi:select', id);
  }
});
