var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"users") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"users") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "    <p class=\"limits-info\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.limits.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":27},"end":{"line":26,"column":55}}}))
    + "</p>\n    <div data-hook=\"limitRegion\"></div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"role-limits\">\n        <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.overall.approval.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":51}}}))
    + "</p>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <ul id=\"limits-tabslist\" class=\"NavTabs nav nav-tabs\" role=\"tablist\"\n        data-hook=\"getNavTabs\">\n        <li class=\"NavTabs-item is-active\"\n           role=\"tab\">\n            <a class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\"OVERALL\"\n                tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.overall.approval.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":29},"end":{"line":14,"column":69}}}))
    + "\n            </a>\n        </li>\n        <li class=\"NavTabs-item\"\n           role=\"tab\">\n            <a class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\"ACCOUNTS\"\n                tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.account.approval.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":29},"end":{"line":21,"column":69}}}))
    + "\n            </a>\n        </li>\n    </ul>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.noPaymentsEntitled",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":43}}}))
    + "</h3>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"paymentsEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "");
},"useData":true});