import locale from '@glu/locale';
import CompositeView from '@glu/core/src/compositeView';
import ItemView from 'app/payments/multiTransfer/views/multiTransferItemView';
import multiTransferCompositeViewTmpl from './multiTransferCompositeView.hbs';

export default CompositeView.extend({
    template: multiTransferCompositeViewTmpl,
    itemView: ItemView,
    tagName: 'table',
    id: 'multiTransferView',
    className: '',

    initialize(options) {
        CompositeView.prototype.initialize.call(this, options);
    },

    itemViewOptions() {
        return this.options;
    },

    appendHtml(collectionView, itemView) {
        collectionView.$('tbody').append(itemView.el);
    },

    templateHelpers() {
        const self = this;
        return {
            columnHeader1() {
                return (self.options.subType === 'MANY2ONE') ? locale.get('payment.FromAccount') : locale.get('payment.ToAccount');
            },

            getString(type) {
                return locale.get(type);
            },
        };
    },
});
