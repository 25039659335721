import Layout from '@glu/core/src/itemView';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import Grid from '@glu/grid';
import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import constants from 'app/administration/constants';
import errorHandlers from 'system/error/handlers';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import DataEntitlementLimits from 'app/administration/collection/user2/dataEntitlementLimits';
import DataEntitlementLimitModel from 'app/administration/models/user2/dataEntitlementLimit';
import configParams from 'system/webseries/models/configurationParameters';
import LimitField from './limitField';
import ActionCheckboxes from './actionCheckboxes';
import CustomHeaderView from './customHeaderView';
import QuickLimitsView from './quickLimits';
import assignedAccountsTmpl from './assignedAccounts.hbs';

export default Layout.extend({
    template: assignedAccountsTmpl,

    ui: {
        $showButton: '[data-hook="showBtn"]',
        $hideButton: '[data-hook="hideBtn"]',
        $quickLimitsButton: '[data-hook="quickLimitsBtn"]',
    },

    events: {
        'click @ui.$showButton': 'showAccountPermissions',
        'click @ui.$hideButton': 'hideAccountPermissions',
        'click @ui.$quickLimitsButton': 'openLimitsDialog',
    },

    loadingTemplate: loadingPageTmpl,

    initialize(options) {
        this.mode = options.mode;
        this.mainModel = this.options.mainModel;
        this.modifyMode = this.options.modifyMode;
        this.limitMap = this.options.limitsModel.get('limitMap');
        this.dataEntitlements = options.entitlements.dataEntitlements;
        this.userId = this.mainModel.userModel.get('USERID');
        this.userGroup = this.mainModel.userModel.get('USERGROUP');
        this.copyingUser = (options.mode === constants.MODES.INSERT) && this.mainModel.userModel.get('COPYEXISTINGUSER');
        this.isCannotGrantBeyondOwnUser = this.options.isCannotGrantBeyondOwnUser;

        /*
         * Used when save or cancel button is pressed.  Do not
         * save the listvew data on save or cancel since it
         * must be orchestrated before the save of the user.
         */
        this.saveOrCancel = false;

        /*
         * Model is actually the type model when permissions by payment type or the
         * group name if by group
         */
        this.typeCode = this.model.id;

        /*
         * Initialize isACH here just in case no data entitlements are available to save a
         * server side call
         * to return an empty row response.  Not great.  Was trying to hardcode and just base
         * isACH based on
         * the existence of batchlimit column returned by server.  The empty dataentitlement
         * object added
         * a little wrinkle to it.
         */
        this.isACH = options.paymentTypeGroup === 'ACH' || options.paymentTypeGroup === 'EFT';

        /*
         * For view/modify, the data entitlements object is not supplied by tab 3.
         * OR if copy user flow is being executed on INSERT and a copy user is passed
         * Therefore set up data entitlements object to retrieve from server.
         */
        if ((util.isEmpty(this.dataEntitlements)
                && options.mode !== constants.MODES.INSERT) || (this.copyingUser)) {
            this.dataEntitlements = this.formatDataEntitlements(this.mainModel.userModel.get('GROUPED_PERMISSIONS_BY'));
        }

        const actions = util.map(['approve', 'modify', 'repair', 'manage'], action => ({
            action,
            entitled: util.some(options.entitlements.actionEntitlements.models, entitlement => util.has(entitlement.get('actions'), action) && (entitlement.get('actions'))[action].entitled === true),
        }));

        this.actionEntitlements = util.indexBy(actions, 'action');
        this.dataEntitlementLimits = new DataEntitlementLimits();

        // We don't need this for view or modify.  Modify should rely on db.
        if (this.mode === constants.MODES.INSERT) {
            this.listenTo(this.dataEntitlementLimits, 'remove', function (model) {
                this.limitMap[this.typeCode + model.id] = this.typeCode + model.id;
            }, this);
        }

        this.permissionsChangedFromStep2 = false;
        if (this.mode === constants.MODES.MODIFY || this.mode === constants.MODES.INSERT) {
            appBus.on(`change:permissions:${this.model.id}`, () => {
                this.permissionsChangedFromStep2 = true;
            });
        }

        /*
         * Set saveorcancel to true so it allows Marionette to clear resources
         * but does not try to update the limits via service.  The limits are
         * updated in bulk below.
         */
        this.listenTo(this.mainModel.userModel, 'user:cancel user:save', function () {
            this.saveOrCancel = true;
        }, this);
    },

    getCheckGridPageSize() {
        /**
         * First validate from config Params
         * else take it from constants
         */
        return configParams.get('DEFAULT_UCE2_GRID_SIZE')
            || configParams.get('DEFAULT_GRID_SIZE')
            || constants.DEFAULT_UCE2_GRID_SIZE;
    },

    onRender() {
        const emptyViewText = this.isACH ? locale.get('uce.emptyGrid.companies') : locale.get('uce.emptyGrid.bankAccounts');

        this.saveOrCancel = false;

        /*
         * If entering the screen, reset the handoff from step 2 since limits are
         * retrieved from server.
         */
        this.permissionsChangedFromStep2 = false;

        if (this.hasLoadedRequiredData()) {
            this.grid = new Grid({
                columns: this.createGridColumns(),
                collection: this.dataEntitlementLimits,
                paginate: true,
                pageSize: this.getCheckGridPageSize(),
                disableDynamicColumnWidths: true,
                emptyViewText,
                el: this.$('.accounts-grid'),

                pageable: {
                    pageSize: this.getCheckGridPageSize(),

                    method: (currentPage, pageSize) => {
                        // Don't update accounts if in view mode
                        if (this.modifyMode) {
                            let validLimits = true;
                            // Need to validate if CGBO
                            if (this.isCannotGrantBeyondOwnUser) {
                                const invalidLimits = this.dataEntitlementLimits
                                    .filter(dataEntitlement => !dataEntitlement.isValid());
                                validLimits = util.isEmpty(invalidLimits);
                            }
                            if (validLimits) {
                                this.updateAccounts()
                                    .then(
                                        () => {
                                            this.getAccounts(currentPage, pageSize)
                                                .then(() => {
                                                    /*
                                                     * Keep track if first time viewing an account
                                                     * to determine if seeding permissions
                                                     */
                                                    this.updateActionPermissions();
                                                }, () => {
                                                    errorHandlers.loading.call(this);
                                                });
                                        },
                                        () => {
                                            /*
                                             * TODO the failure gets
                                             *  stepped on by another render.
                                             */
                                            errorHandlers.loading.call(this);
                                        },
                                    );
                            }
                        } else {
                            this.getAccounts(currentPage, pageSize)
                                .then(() => {
                                    /*
                                     * Keep track if first time viewing an account
                                     * to determine if seeding permissions
                                     */
                                    this.updateActionPermissions();
                                }, () => {
                                    errorHandlers.loading.call(this);
                                });
                        }
                    },
                },
            });

            /*
             * HACK No way to have a glu grid column span 2 colums via configuration and
             * not worth complexity of implementing cellview.
             */
            if (this.dataEntitlements && this.dataEntitlements.currentFuture) {
                this.listenTo(this.grid, 'grid:contentRendered', function () {
                    this.grid.tableBody.$('td').first().attr('colspan', '2');
                }, this);
            }

            this.grid.render();
            // Initial behavior to hide permissions and always hide manage columns button.
            if (this.options.limitsModel.get('showPermissions')) {
                this.showAccountPermissions();
            } else {
                this.hideAccountPermissions();
            }

            this.grid.$('.manage-columns').hide();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        this.dataEntitlementLimits.fetch({
            rowsPerPage: this.getCheckGridPageSize(),
            userId: this.userId,
            copyUserId: this.mainModel.userModel.get('COPYEXISTINGUSER'),
            userGroup: this.userGroup,
            dataEntitlements: this.dataEntitlements,
            mode: this.mode,
            isACH: self.isACH,

            success(limits, resp) {
                self.dataEntitlementLimits.totalCount = resp.totalRows;
                self.setHasLoadedRequiredData(true);
                self.updateActionPermissions();
                self.render();
            },

            error: util.bind(errorHandlers.loading, self),
        });
    },

    updateAccounts() {
        const self = this;

        return new Promise((resolve, reject) => {
            self.dataEntitlementLimits.save({
                byGroup: self.mainModel.userModel.get('GROUPED_PERMISSIONS_BY') === 'PAYMENT_GROUP',
                currentFuture: self.dataEntitlements.currentFuture,
                isACH: this.isACH,
                success: resolve,
                error: reject,
            });
        });
    },

    getAccounts(currentPage, pageSize) {
        const self = this;

        return new Promise((resolve, reject) => {
            self.dataEntitlementLimits.fetch({
                copyUserId: self.mainModel.userModel.get('COPYEXISTINGUSER'),
                userId: self.userId,
                userGroup: self.userGroup,
                currentPage,
                rowsPerPage: pageSize,
                dataEntitlements: self.dataEntitlements,
                mode: self.mode,
                success: resolve,
                error: reject,
            });
        });
    },

    updateActionPermissions() {
        if (this.modifyMode) {
            util.chain(this.dataEntitlementLimits.models)
            /*
             * Exclude the models that are already in the map.   In case the
             * permissions
             * have changed in tab 2 since the model was viewed, include so the
             * permissions
             * can be updated.
             * Modify always uses a new map.
             */
                .reject(function (model) {
                    return util.contains(this.limitMap, this.typeCode + model.id)
                            && util.every(['manage', 'repair', 'approve', 'modify'], action => model.get(`${action}Action`) === this.actionEntitlements[action].entitled);
                }, this)
            // Process each new model.  Modify always refreshes.
                .each(function (model) {
                    const actions = {};
                    util.each(['manage', 'repair', 'approve', 'modify'], (action) => {
                        const { entitled } = this.actionEntitlements[action];
                        if (this.mode === constants.MODES.INSERT) {
                            actions[`${action}Action`] = util.isBoolean(model.get(`${action}Action`)) ? entitled : '';
                        } else {
                            // Take server only if action entitlement is true
                            actions[`${action}Action`] = entitled ? model.get(`${action}Action`) : entitled;
                        }
                    });
                    model.set(
                        actions,
                        {
                            silent: true,
                        },
                    );
                }, this);
        }
    },

    /**
     * Creates a data entitlement object for view and modify mode.
     * Only insert mode relies on tab 3 to populate initially.
     */
    formatDataEntitlements(permissionsGrouping) {
        const byGroup = permissionsGrouping === 'PAYMENT_GROUP';
        const productCode = this.isACH ? 'USACH' : 'RTGS';

        /*
         * For retrieving by group, the group name is required for productCode.
         * The value of typecode is actually the group name when 'by group'.
         * The value of typeCode attribute must be null for 'by group'.
         */
        return {
            productCode: byGroup ? this.typeCode : productCode,
            mixedChangeSet: true,
            exclusiveSet: false,
            currentFuture: false,
            group: byGroup,
            typeCode: byGroup ? '' : this.typeCode,
            dataEntAttr: this.isACH ? 'ACHCompany' : 'BankAccount',
            members: [],
        };
    },

    createGridColumns() {
        return [{
            field: 'accountName',
            label: locale.get('uce.name'),
            className: this.dataEntitlements && this.dataEntitlements.currentFuture ? 'currentFuture' : '',
        }, {
            field: 'accountNumber',
            label: this.isACH ? locale.get('uce.companyId') : locale.get('uce.accountNumber'),
            condition: this.isACH,
        }, {
            field: 'ACCOUNTNUMBER_DISP',
            label: locale.get('uce.accountNumber'),
            condition: !this.isACH,
        }, {
            field: 'offsetAccountNum',
            label: locale.get('uce.offsetAccountNum'),
            condition: this.dataEntitlementLimits.showOffsetAccountNumber && this.isACH,
        }, {
            field: 'tranLimit',
            label: locale.get('uce.transactionLimit'),
            cellView: LimitField,

            cellViewOptions: {
                mode: this.mode,
                modifyMode: this.modifyMode,
            },
        }, {
            field: 'batchLimit',
            label: locale.get('uce.batchLimit'),
            cellView: LimitField,
            condition: this.isACH,

            cellViewOptions: {
                mode: this.mode,
                modifyMode: this.modifyMode,
            },
        }, {
            field: 'dailyLimit',
            label: locale.get('uce.dailyLimit'),
            cellView: LimitField,

            cellViewOptions: {
                mode: this.mode,
                modifyMode: this.modifyMode,
            },
        }, {
            field: 'actions',
            label: 'actions',
            headerView: CustomHeaderView,
            cellView: ActionCheckboxes,

            cellViewOptions: {
                mode: this.mode,
                modifyMode: this.modifyMode,
                actionEntitlements: this.actionEntitlements,
            },

            sortable: false,
        }];
    },

    showAccountPermissions() {
        this.options.limitsModel.set('showPermissions', true);
        this.togglePermissions(this.ui.$showButton, this.ui.$hideButton, true);
    },

    hideAccountPermissions() {
        this.options.limitsModel.set('showPermissions', false);
        this.togglePermissions(this.ui.$hideButton, this.ui.$showButton, false);
    },

    togglePermissions(activate, deactivate, showPermissions) {
        // Use the manage columns facility to hide and show the actions column
        const cols = this.grid.manageColumnsLayout.grid.columns;

        const permissionsCol = cols.findWhere({
            field: 'actions',
        });

        permissionsCol.set(
            'condition',
            showPermissions,
            {
                // to prevent multiple grid re-rendering
                silent: true,
            },
        );
        cols.trigger('change:condition');

        deactivate.removeClass('active');
        activate.addClass('active');
    },

    /*
     * showHideLimitsButton: function(collection) {
     *      if (collection.hasApprove()) {
     *          this.ui.$addLimitsButton.show();
     *      } else {
     *          this.ui.$addLimitsButton.hide();
     *      }
     *  },
     */

    applyQuickLimits(quickLimits) {
        const limits = quickLimits.toJSON();
        const byGroup = this.mainModel.userModel.get('GROUPED_PERMISSIONS_BY') === 'PAYMENT_GROUP';
        const productCode = this.isACH ? 'USACH' : 'RTGS';
        const quickLimitModel = new DataEntitlementLimitModel();
        const self = this;

        util.chain(this.dataEntitlementLimits.models)
            .filter(model => model.get('approveAction'))
            .each((model) => {
                model.set(limits);
            });

        // Persist limits for all accounts, not just what is viewable in listview.
        limits.productCode = byGroup ? this.typeCode : productCode;
        limits.typeCode = byGroup ? '*' : this.typeCode;
        quickLimitModel.save(
            limits,
            {
                byGroup,
                success: util.noop,
                error: util.bind(errorHandlers.loading, self),
            },
        );

        // Validate the limits after they are applied.
        if (this.modifyMode && this.isCannotGrantBeyondOwnUser) {
            this.dataEntitlementLimits
                .each((dataEntitlement) => {
                    dataEntitlement.isValid();
                });
        }
    },

    openLimitsDialog() {
        const quickLimitsView = new QuickLimitsView({
            isACH: this.isACH,
        });

        this.listenTo(quickLimitsView, 'applyQuickLimits', this.applyQuickLimits);
        dialog.open(quickLimitsView);
    },

    templateHelpers() {
        return {
            readOnly: this.mode === constants.MODES.VIEW,
            currentFuture: this.dataEntitlements && this.dataEntitlements.currentFuture,
            isACH: this.isACH,
        };
    },

    saveOnContextSwitch() {
        // Save the limits when leaving the grid
        const self = this;

        /*
         * If in modify and permissions were changed in step 2, but this tab 4 was
         * not revisted,
         * do not update session limits.
         */
        if ((this.mode === constants.MODES.MODIFY || this.mode === constants.MODES.INSERT)
            && this.permissionsChangedFromStep2) {
            return Promise.resolve();
        }

        return this.updateAccounts()
            .then(
                (...args) => {
                    if (Layout.prototype.close) {
                        Layout.prototype.close.apply(this, args);
                    }
                },
                () => {
                    errorHandlers.loading.call(self);
                },
            );
    },

    beforeSave() {
        return this.saveOnContextSwitch();
    },

    getDataEntitlmentLimits() {
        return this.dataEntitlementLimits.models;
    },

    close(...args) {
        /*
         * Need to sync the map of items saved at server in case no pagination
         * cccured prior to close of tab.  This permits the correct mapping of the
         * permissions.
         * which are seeded from tab 2 if not already reviewd in tab 4.
         * Do not do in view mode or when cancel or save is pressed.
         */
        if (this.saveOrCancel) {
            if (Layout.prototype.close) {
                Layout.prototype.close.apply(this, args);
            }
        } else if (this.modifyMode) {
            if (this.mode === constants.MODES.INSERT) {
                this.dataEntitlementLimits.each(function (model) {
                    this.limitMap[this.typeCode + model.id] = this.typeCode + model.id;
                }, this);
            }
            this.saveOnContextSwitch();
        }
    },
});
