import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import moment from 'moment';
import http from '@glu/core/src/http';

export default Model.extend({

    sync(method, model, options) {
        const self = this;
        const context = self.get('context') || self.get('contextDef');
        const dateService = services.generateUrl('/inquiry/getRowsData');
        const postData = {
            requestHeader: {
                requestId: '',
            },
            inquiryRequest: {
                startRow: 0,
                rowsPerPage: 1,
                searchCriteria: {
                    customFilters: [{
                        filterName: 'DEPENDS',
                        filterParam: [
                            'PRODUCTCODE',
                            context.productCode,
                        ],
                    }, {
                        filterName: 'DEPENDS',
                        filterParam: [
                            'FUNCTIONCODE',
                            context.functionCode,
                        ],
                    }, {
                        filterName: 'DEPENDS',
                        filterParam: [
                            'TYPECODE',
                            context.typeCode,
                        ],
                    }],
                    searchType: '5',
                    inquiryId: '29071',
                },
            },
        };

        http.post(dateService, postData, (result) => {
            options.success(result);
        }, () => {
            options.error();
        });
    },

    parse(data) {
        /**
         * NH-33051: The utc related change here is to address this issue.  Based on the
         * investigation of this issue, the cause is related to the day light saving time.
         *
         * The date range submitted by the user would be checked against the minDate and
         * maxDate returned here. The minDate and maxDate returned here will also
         * be used to
         * update the startDate and endDate of the preset date range through the
         * dateRangePicker, the dateRangePicker will always use UTC time. System
         * uses the start
         * date of the day of endDate and maxDate and compares their corresponding millium
         * seconds with timezone offset applied to decide if they are valid
         * (the same or the endDate is before the maxDate).
         *
         * Before this change, the endDate submitted by the user through preset date range
         * (e.g. last 7 days) is utc time (updated by dateRangePicker) and has a
         * utc time zone
         * offset, but the maxDate returned here is not utc time and no time zone offset,
         * when the test date is on Sunday and passed the day light saving, the timezone
         * offset was -5 (est), when start day will be end of previous day with time zone
         * offset -5 (would be -4 before finish day light saving), this would make
         * the adjusted
         * endDate (after application of offset) later then the maxDate allowed and cause the
         * error; in case of using custom date range, the endDate submit is not utc time,
         * no offset, same as the maxDate, so there was no issue.
         *
         * The change here would have the maxDate has the same timezone offset during date
         * range validation when user select the preset date range to submit;
         * when date range submitted through custom date range
         * (endDate is not utc, while the maxDate is utc), because of the reason mentioned
         * above, the endDate would be before the maxDate, so the change still works for
         * custom date range.
         */
        let minDate = moment(new Date()).utc().utcOffset(moment(new Date()).utcOffset());
        const minFutureDate = moment(new Date()).utc()
            .utcOffset(moment(new Date()).utcOffset());
        let maxDate = moment(new Date()).utc().utcOffset(moment(new Date()).utcOffset());
        let startDate = moment(new Date()).utc().utcOffset(moment(new Date()).utcOffset());
        let endDate = moment(new Date()).utc().utcOffset(moment(new Date()).utcOffset());

        let rangeSet = false;

        const { inquiryResponse } = data;
        let rows;
        let row;
        let columns;
        let startDateOffset;
        let numberOfDays;
        let maxDaysPerSearch;
        let holder;
        if (inquiryResponse) {
            ({ rows } = inquiryResponse);
            if (Array.isArray(rows)) {
                // only expecting 1 row back
                [row] = rows;
                ({ columns } = row);
                if (row && Array.isArray(columns)) {
                    holder = util.findWhere(columns, {
                        fieldName: 'STARTDATEOFFSET',
                    });
                    startDateOffset = Number(holder.fieldValue);
                    holder = util.findWhere(columns, {
                        fieldName: 'NUMBEROFDAYS',
                    });
                    numberOfDays = Number(holder.fieldValue);
                    holder = util.findWhere(columns, {
                        fieldName: 'MAXDAYSPERSEARCH',
                    });
                    maxDaysPerSearch = Number(holder.fieldValue);
                    minDate = minDate.add(startDateOffset, 'day');
                    maxDate = maxDate.add(startDateOffset + numberOfDays, 'day');
                    startDate = startDate.add((startDateOffset + numberOfDays) - maxDaysPerSearch, 'day');
                    endDate = endDate.add(startDateOffset + numberOfDays, 'day');
                    rangeSet = true;
                }
            }
        }
        return {
            minDate,
            maxDate,
            startDate,
            endDate,
            minFutureDate,
            maxDaysPerSearch,
            rangeSet,
        };
    },
});
