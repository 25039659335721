import { log } from '@glu/core';

export default {

    getResult(opts, model) {
        const modelParam = model;
        return (result) => {
            try {
                modelParam.error = JSON.parse(result.responseText);
                opts.error(modelParam, {});
            } catch (err) {
                log.error(err);
            }
        };
    },

};
