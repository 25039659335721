import ManageApproversView from 'app/administration/views/panelApproval/types/typeView';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import systemConfig from 'system/configuration';
import PanelApprovalList from './views/panelApprovalList';
import ModifyApprovalWorkflow from './views/modifyApprovalWorkflow';
import ApprovalWorkflowDetail from './views/approvalWorkflowDetail';

const CONTEXT_KEY = 'panelApprovalWorkflow';

/**
 * Get the workspace route stored. If not set,
 * based on whether the user is an admin, return the proper route
 */
const getReturnRoute = () => {
    if (store.has('panelApprovalListReturnRoute')) {
        return store.remove('panelApprovalListReturnRoute');
    }
    if (systemConfig.isAdmin()) {
        return 'CMAINT/panelApprovalWorkflow';
    }
    return 'PANELAPPROVAL/list';
};

export default {
    panelApprovalList() {
        this.showPage('', new PanelApprovalList({
            id: '_ADMIN_PNLPR_TM',
        }));
    },

    modifyPanelApproval() {
        this.showPage('', new ModifyApprovalWorkflow({
            contextKey: CONTEXT_KEY,
            returnRoute: getReturnRoute(),
        }));
    },

    viewPanelApproval() {
        this.showPage('', new ApprovalWorkflowDetail({
            headingText: locale.get('PS.panelApproval.View.Approval.Workflow'),
            contextKey: CONTEXT_KEY,
            returnRoute: getReturnRoute(),
        }));
    },

    manageApprovers() {
        let userGroup = userInfo.get('group');

        if (systemConfig.isAdmin()) {
            const actionModel = store.remove(`${CONTEXT_KEY}-actionModel`);
            userGroup = actionModel.get('USERGROUP');
        }

        this.showPage('', new ManageApproversView({
            groupId: userGroup,
            mode: 'edit',
            contextKey: CONTEXT_KEY,
            returnRoute: getReturnRoute(),
        }));
    },
};
