import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import PlanCollection from '../planning/planCollection';

const Plan = Model.extend({
    defaults: {
        name: '',
        cashFlowPlanId: 0,
        version: 0,
        asNewVersion: false,
    },

    initialize(attributes, options) {
        this.categoryService = options.categoryService;
        this.isDirty = false;
        this.set({
            inflowCollection: new PlanCollection(
                [],
                {
                    type: 'inflow',
                },
            ),

            outflowCollection: new PlanCollection(
                [],
                {
                    type: 'outflow',
                },
            ),
        });

        this.listenTo(this.get('inflowCollection'), 'reset', this.setDirty);
        this.listenTo(this.get('outflowCollection'), 'reset', this.setDirty);
        this.listenTo(this, 'sync', this.setDirty);
    },

    idAttribute: 'cashFlowPlanId',

    setDirty() {
        this.isDirty = true;
        this.trigger('change');
    },

    isChanged() {
        let coll = this.get('inflowCollection');
        let isChanged = coll.some(model => util.keys(model.changed).length > 1);

        if (isChanged === false) {
            coll = this.get('outflowCollection');
            isChanged = coll.some(model => util.keys(model.changed).length > 1);
        }

        return isChanged;
    },

    parse(response) {
        const mapped = {};

        const parsed = {
            cashFlowPlanId: response.cashFlowPlanId,
            version: response.version,
            name: response.name,
            fyStart: response.fyStart,
            fyEnd: response.fyEnd,
            multiYearPlanId: response.multiYearPlanId,
        };

        util.each(response.cashFlowPlan, (row) => {
            const categoryId = row.cashFlowCategoryId;
            const { monthNum } = row;
            const { amount } = row;

            if (util.isUndefined(mapped[categoryId])) {
                mapped[categoryId] = {
                    categoryId,
                };
            }

            mapped[categoryId][monthNum] = amount;
        });

        const inflowCategories = this.categoryService.getByType('INFLOW');
        const outflowCategories = this.categoryService.getByType('OUTFLOW');

        // mapped is an object of categoryId: PlanCollection model object
        this.get('inflowCollection').initializeFromCategories(inflowCategories, mapped);
        this.get('outflowCollection').initializeFromCategories(outflowCategories, mapped);

        return parsed;
    },

    sync(method, model, optionsParam) {
        const options = optionsParam;
        // results won't make sense if categories haven't loaded yet
        const self = this;

        let xhr;

        options.method = method;

        if (method === 'read' && this.categoryService.hasLoaded()) {
            // in the future, this will happen at the collection level
            xhr = http.post(
                services.generateUrl('/cashflowPlan/get'),
                {
                    cashFlowPlanId: self.get('cashFlowPlanId'),
                    version: self.get('version'),
                    name: self.get('name'),
                    fyStart: self.get('fyStart'),
                    fyEnd: self.get('fyEnd'),
                    multiYearPlanId: self.get('multiYearPlanId'),
                },
                (response) => {
                    options.success(response);
                    self.isDirty = false;
                },
                (response) => {
                    options.error(response);
                },
            );
        }

        if (method === 'update' || method === 'create') {
            const json = this.toServerJSON();

            xhr = http.post(
                services.generateUrl('/cashflowPlan/save'),
                json,
                (response) => {
                    options.success(response);
                    self.isDirty = false;
                },
                (response) => {
                    options.error(response);
                },
            );
        }

        this.trigger('request', this, xhr, options);
        return xhr;
    },

    toServerJSON() {
        const rows = [].concat(this.get('inflowCollection').toJSON(), this.get('outflowCollection').toJSON());
        const cells = [];
        const months = util.range(1, 13);

        util.each(rows, (row) => {
            util.each(months, (m) => {
                cells.push({
                    cashFlowCategoryId: row.categoryId,
                    monthNum: m,
                    amount: row[m],
                });
            });
        });

        const json = {
            cashFlowPlanId: this.get('cashFlowPlanId'),
            version: this.get('version'),
            fyStart: this.get('fyStart'),
            fyEnd: this.get('fyEnd'),
            multiYearPlanId: this.get('multiYearPlanId'),
            name: this.get('name'),
            cashFlowPlanName: this.get('name'),
            asNewVersion: this.get('asNewVersion'),
            cashFlowPlan: cells,
        };

        return json;
    },
});

export default Plan;
