import $ from 'jquery';
import alert from '@glu/alerts';
import { appBus } from '@glu/core';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';

import BaseMFAView from 'system/mfa/views/baseMFA';
import Constants from 'system/mfa/constants';
import HardTokenRegisterView from 'system/mfa/views/rsa/modals/hardTokenRegister';
import loadingTemplate from 'common/templates/loadingModal.hbs';
import mfaUtil from 'system/mfa/util';
import RSATokenModel from 'system/mfa/models/rsaToken';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import SoftTokenRegisterView from 'system/mfa/views/rsa/modals/softTokenRegister';

import template from './enterRSAToken.hbs';

export default BaseMFAView.extend({
    template,
    loadingTemplate,

    initialize(options) {
        const challengeSettingsModel = options.challengeSettings;
        this.challengeType = challengeSettingsModel.get('challengeType');
        this.challengeAction = challengeSettingsModel.get('challengedAction');

        this.setModel(options);
    },

    setModel(options) {
        this.model = new RSATokenModel({
            challengedAction: options.challengedAction,
            challengeType: options.challengeType,
        });
    },

    onRender() {
        this.trigger('dialog:buttons:change', this.buttons());
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    getModalPromises() {
        return new Promise((resolve, reject) => {
            serverConfigParams.fetch({
                success: resolve,
                error: reject,
                isPreLoginMfaFetch: true,
            });
        });
    },

    loadRequiredData() {
        this.getModalPromises().then((results) => {
            this.model.set(results.toJSON());
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    /**
     * @method buttons
     * - sets Submit and Cancel buttons in modal dialog box
     */
    buttons() {
        return [
            {
                text: locale.get('button.submit'),
                className: 'btn-primary btn-sm',
                callback: this.submitCode.bind(this),
            },
            {
                text: locale.get('button.cancel'),
                className: 'btn-secondary btn-sm',
                callback: util.bind(this.cancel, this),
            },
        ];
    },

    returnToChallenge() {
        // display success notification
        const message = locale.get('mfa.activation.success');
        this.alertView = alert.success(
            message,
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    disableUser() {
        // display disabled user notification
        const message = locale.get('platformservice.mfa.policy.violation');
        this.alertView = alert.danger(
            message,
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
        mfaUtil.logout();
    },

    submitCode() {
        this.alertRegion.close();
        const $submitBtn = this.$el.closest('.modal-content').find('.btn-primary');
        $submitBtn.prop('disabled', true);
        this.model.save(
            {},
            {
                success: (result) => {
                    if (result.get('respHeader').result === false) {
                        this.model.set({
                            showConfirmNewPin: result.get('showConfirmNewPin'),
                            showNewPin: result.get('showNewPin'),
                            showNextTokenCode: result.get('showNextTokenCode'),
                            showSystemGenerated: result.get('showSystemGenerated'),
                            prompts: result.get('prompts'),
                            hidePasscode: result.get('requiresMoreInforamtion'),
                        });
                        util.each(result.get('prompts'), (prompt) => {
                            if (prompt.promptKey === 'SYSTEM_GENERATED_PIN') {
                                this.model.set({
                                    SystemGeneratedPin: prompt.promptValue,
                                    newTokenPin: prompt.promptValue,
                                });
                            }
                        });
                        this.render();
                        const resp = result.get('respHeader');
                        const challengedAction = result.get('challengedAction');
                        if (resp.message) {
                            // handle error types
                            let message = resp.message.join(' ');
                            if (challengedAction.actionMode !== 'LOGIN' && resp.errorCode === Constants.ERROR_MAX_RETRIES) {
                                message = locale.get('mfa.attempts.exceeded');
                            }
                            this.alertView = alert.danger(
                                message,
                                {
                                    canDismiss: true,
                                },
                            );
                            if (resp.errorCode === Constants.ERROR_MAX_RETRIES) {
                                if (challengedAction.actionMode === 'LOGIN') {
                                    this.closeModal();
                                    appBus.trigger('mfa:challenge:showError', this.alertView);
                                } else {
                                    this.alertRegion.show(this.alertView);
                                    $('.btn-primary').prop('disabled', true);
                                    $('.btn-secondary').prop('disabled', true);
                                    setTimeout(() => mfaUtil.logout(), 3000);
                                }
                            } else {
                                this.alertRegion.show(this.alertView);
                            }
                        }
                        $submitBtn.prop('disabled', false);
                    } else {
                        this.completion();
                    }
                },
            },
        );
    },

    /**
     * Create a modal for registering tokens in an MFA workflow
     * @param {String} typeOfModal - Indicates whether this token is a "Hard" token such as
     * a physical object. Or a "Soft" token like a mobile device
     * @return {View/undefined} - The created view with attached listeners or undefined if no
     * appropriate parameters passed in
     */
    createRegisterModalAndListeners(typeOfModal) {
        // initialize the modal view to undefined and overwrite with the desired
        let TypeOfModalView;
        if (typeOfModal === 'Hard') {
            TypeOfModalView = HardTokenRegisterView;
        } else if (typeOfModal === 'Soft') {
            TypeOfModalView = SoftTokenRegisterView;
        }

        // return early if no modal type passed in
        if (!typeOfModal) {
            return undefined;
        }

        const tokenRegisterView = new TypeOfModalView({
            challengeType: this.challengeType,
            challengeAction: this.challengeAction,
        });
        this.listenTo(tokenRegisterView, 'activationSuccess', this.returnToChallenge);
        this.listenTo(tokenRegisterView, 'activationFailure', this.disableUser);
        return tokenRegisterView;
    },

    /**
     * Opens a modal for the user to input their physical token credentials
     */
    registerHardTokenButtonListener() {
        dialog.open(this.createRegisterModalAndListeners('Hard'));
    },

    /**
     * Opens a modal that will walk a user through a multi-step process to register a mobile
     * device
     */
    registerSoftTokenButtonListener() {
        dialog.open(this.createRegisterModalAndListeners('Soft'));
    },

    templateHelpers() {
        return {
            showTokenRegistration: this.challengeType === Constants.ENTRUST_CHALLENGE_TYPE
                || this.challengeType === Constants.ONESPAN_CHALLENGE_TYPE,
            showPasscode: !this.model.get('hidePasscode'),
            showConfirmNewPin: this.model.get('showConfirmNewPin'),
            showNewPin: this.model.get('showNewPin'),
            showNextTokenCode: this.model.get('showNextTokenCode'),
            showSystemGenerated: this.model.get('showSystemGenerated'),
            showMFAPin: serverConfigParams.get('MFAPINSupport') ? serverConfigParams.get('MFAPINSupport').toUpperCase() === 'TRUE' : false,
        };
    },
});
