import $ from 'jquery';
import alert from '@glu/alerts';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import alertMessage from 'common/api/alertMessage';
import DataAPI from 'common/dynamicPages/api/data';
import gridApi from 'common/dynamicPages/api/grid';
import scroll from 'common/util/scroll';
import transform from 'common/util/transform';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import workspaceHelper from 'common/workspaces/api/helper';
import constants from 'common/dynamicPages/api/constants';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import store from 'system/utilities/cache';
import Formatter from 'system/utilities/format';
import MDFMultiAddCollectionView from './mdfMultiAddCollectionView';
import MDFMultiItemView from './mdfMultiItemView';
import MultiAddLayoutView from './multiAddLayoutView';
import mdfMultiAddLayoutViewTmpl from './mdfMultiAddLayoutView.hbs';

export default MultiAddLayoutView.extend({
    template: mdfMultiAddLayoutViewTmpl,

    ui: util.extend(
        { },
        MultiAddLayoutView.prototype.ui,
        {
            $addInfo: '[data-hook="getAddInfo"]',
            $alertContainer: '.alert-message',
        },
    ),

    events: util.extend(
        {},
        MultiAddLayoutView.prototype.events,
        {
            'focus @ui.$addCount': 'focusAddItem',
        },
    ),

    /**
     * @name getItemView
     * @description (marionette collectionview) method that returns the type of
     * item view it will contain
     */
    getItemView() {
        return MDFMultiItemView;
    },

    /**
     * @name itemViewOptions
     * @description (marionette collectionview) method that returns the options
     * needed by the item view
     * @param model
     * @returns {{context: (*|contextOverride|model.context), model: *, state,
     * hideButtons: boolean, gridApi: *, preFill, smbOverride: null, reimburse: null,
     * itemIndex}}
     */
    itemViewOptions(model) {
        return {
            context: this.contextDef,
            model,
            state: this.mode,
            hideButtons: true,
            gridApi,
            preFill: store.get(`${this.contextKey}-preFill`),
            smbOverride: null,
            reimburse: null,
            itemIndex: this.collection.length,
        };
    },

    initialize(options) {
        MultiAddLayoutView.prototype.initialize.call(this, options);

        this.addButtonLabel = options.addButtonLabel;

        // get context info from store
        this.contextKey = store.get('listView-contextKey');
        const overrideKey = store.get('multiAdd-contextKey');
        this.contextDef = store.get(overrideKey);
        this.contextDef.keyOverride = overrideKey;

        // remove context info from the store
        store.unset('listView-contextKey');
        store.unset('multiAdd-contextKey');
        store.unset(overrideKey);

        /*
         * create an empty collection
         * the collection class is passed in as an option
         */
        this.collection = new (options.collectionClass || Collection)([]);
        const CollectionView = options.collectionView || MDFMultiAddCollectionView;
        const itemView = options.itemView || MDFMultiItemView;

        /*
         * create a mdf multi-add collection view,
         * we need to pass in the context info so that the item
         * view can create an mdf view
         */
        this.collectionView = new CollectionView({
            collection: this.collection,
            itemView,
            parentView: this,
            contextKey: this.contextKey,
            contextDef: this.contextDef,
            mode: this.mode,
        });

        this.listenTo(this, 'multi-item:save', this.saveItems);
    },

    /**
     * @name loadRequiredData
     * @description creates a promise to retrieve the mdf model. When the promise
     * is resolved, render the view
     */
    loadRequiredData() {
        DataAPI.model.generate({
            context: this.contextDef,
            state: 'insert',
            hideButtons: true,
            gridApi: {},
        }).then((model) => {
            this.maxRows = model.jsonData.maxMultiEntryRows;
            this.baseModel = model;

            // add first item to the collection
            this.initialAddToCollection(this.deepCloneModel(model));

            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            // display collection view in region
            this.collectionRegion.show(this.collectionView);
            this.additionalRender();
        }
    },

    additionalRender() {
        // hook for classes that extend this one
    },

    /**
     * @name addToCollection
     * @description adds the input model(s) to the collection
     * @param {object} model - a single model  to be added to the collection
     * @param {Boolean} silent - a boolean flag for whether or not to make this change silently
     */
    initialAddToCollection(model, silent) {
        this.collection.add(model, { silent });
    },

    /**
     * @name objectDeepClone
     * @description returns a deep clone of an object
     * @param obj
     */
    objectDeepClone(obj) {
        return $.extend(true, {}, obj);
    },

    /**
     * @name deepCloneModel
     * @description clones the mdf model so that each item view is associated
     * with a fresh model
     * @param model
     * @returns {* clone of mdf model}
     */
    deepCloneModel(model) {
        return util.extend(
            new Model(model.attributes),
            {
                context: model.context,
                comboList: model.comboList,
                comboListDefaultValue: model.comboListDefaultValue,
                fieldData: model.fieldData,
                isChild: false,
                isBatch: false,
                jsonData: model.jsonData,
                key: model.key,
                lookupHelperText: model.lookupHelperText,
                showHideFields: model.showHideFields,
                // need a deep clone b/c validators can be updated
                validators: this.objectDeepClone(model.validators),
            },
        );
    },

    /**
     * @name disableAddButton
     * @description disables the add item button
     * @param {boolean} state
     */
    disableAddButton(state) {
        if (this.ui.$addItem.prop) {
            this.ui.$addItem.prop('disabled', state);
        }
    },

    /**
     * @name focusAddItem
     * @description handler for when the add item input
     */
    focusAddItem() {
        if (this.maxRows > 0) {
            this.updateAddInfoClasses(false);
            this.ui.$addInfo.text(locale.get(`${this.localeKey}addItem.info`, this.maxRows, this.collection.length, (this.maxRows - this.collection.length)));
        }
    },

    /**
     * @name clearAddInfoMessage
     * @description clears the add item input message
     */
    clearAddInfoMessage() {
        if (this.maxRows > 0) {
            this.updateAddInfoClasses(false);
            this.ui.$addInfo.text('');
        }
    },

    /**
     * @name showAddInfoErrorMessage
     * @description displays the error message when the request number of rows
     * exceeds the limit.  updates the styles to indicate an error
     */
    showAddInfoErrorMessage() {
        this.updateAddInfoClasses(true);
        this.ui.$addInfo.text(locale.get(`${this.localeKey}addItem.error`, this.maxRows));
    },

    /**
     * @name updateAddInfoClasses
     * @description updates the classes/styles for the add item info to reflect
     * either the error state (state = true)
     * or the non-error state (state = false)
     * @param {boolean} state
     */
    updateAddInfoClasses(state) {
        this.ui.$addInfo.toggleClass('help-block', state);
        this.ui.$addInfo.parent().toggleClass('has-error', state);
        this.ui.$addInfo.parent().prev().toggleClass('has-error', state);
    },

    /**
     * @function addModels
     * @description adds 'count' items (models) to an array
     * @param {int} count
     * @returns {Array} an array of 'count' items (models)
     */
    addModels(count) {
        let i;
        const newModels = [];
        for (i = 0; i < count; i += 1) {
            newModels.push(this.deepCloneModel(this.baseModel));
        }
        return newModels;
    },

    /**
     * @name addItemsToCollection
     * @description adds items (models) to the collection
     * the number of items added is determined from an input control
     */
    addItemsToCollection() {
        const numItems = parseInt(this.ui.$addCount.val() || 1, 10);
        // clear message
        this.clearAddInfoMessage();
        if ((numItems + this.collection.length) > this.maxRows) {
            // issue error message
            this.showAddInfoErrorMessage();
        } else {
            this.newFocusItem = this.collection.length;
            this.collection.add(this.addModels(numItems));
        }
    },

    /**
     * @name saveItems
     * @description save event handler.  first validates that the models are
     * eligible for saving.  if so saves the collection
     */
    saveItems() {
        // deliberately not short circuiting because we want validation to run on all models
        const valid = this.collection.map(model => model.isValid()).every(status => status);

        if (valid) {
            this.disableButtons(true);
            const options = {
                success: this.success.bind(this),
                error: this.error.bind(this),
                mode: this.mode,
                saveWithWarning: this.saveWithWarning,
                saveAsTemplate: this.options.saveAsTemplate,
                restrict: this.options.restrict,
            };
            this.collection.save(options);
        }
    },

    /**
     * Callback method handling ok on warning
     */
    saveWithWarning() {
        this.saveWithWarning = true;
        this.saveItems();
    },

    /**
     * @name success
     * @description success handler for save.  Prepares for listview confirmation
     * alert message, enables the buttons and returns to the workspace
     * @param confirmResponse
     */
    success(confirmResponse) {
        // copy over delayed confirmed data
        const response = util.clone(confirmResponse);
        if (this.delayedConfirmData && this.delayedConfirmData.length > 0) {
            const { confirms } = response;
            const numItems = confirms.confirmResults.length + this.delayedConfirmData.length;

            this.delayedConfirmData.forEach((item) => {
                confirms.confirmResults.push(item);
            });
            confirms.totalSuccess = numItems;
            response.message = `${numItems + (numItems > 1 ? ` ${locale.get('payment.payment.plural')}`
                : ` ${locale.get('payment.payment')}`)} ${locale.get('payment.processed')}`;
        }
        store.set(`${this.contextKey}-alertMessage`, 'INSERT');
        store.set(`${this.contextKey}-confirms`, response);
        this.disableButtons(false);
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @name error
     * @description error handler for save.  enables the buttons and displays the
     * error message
     * @param response
     */
    error(response) {
        this.disableButtons(false);
        this.delayedConfirmData = response.responseJSON.confirms.confirmResults
            .filter(confirmResult => confirmResult.result === true);
        // Prevent resubmission of successful payments
        this.clearSuccessfulPayments(this.delayedConfirmData);
        if (response.responseJSON.errorCode === constants.DUPLICATE_ERROR_CODE) {
            /*
             * Get all responses that are duplicates so they can be sent to the
             * duplicate warning dialog
             */
            response.responseJSON.confirms.confirmResults = response.responseJSON.confirms.confirmResults.filter(confirmResults => confirmResults.resultType === 'DUPLICATEWARNING');

            // Update the collection to mark duplicates based on the server response
            this.updateCollectionFromFailedResults(response.responseJSON);
            const duplicateDialog = new DuplicateDialog({
                resp: response.responseJSON,
                modelsArray: this.collection,
                methodName: 'INSERT',
                isMultiAdd: true,
            });
            duplicateDialog.once('saveMultiAddDuplicates', this.saveItems, this);
            dialog.custom(duplicateDialog);
            this.showDelayedConfirmation = true;
        } else if (response.responseJSON.resultType === 'WARNING') {
            const warningDialog = new WarningDialog({
                multiAdd: true,
                methodName: 'SAVE',
                confirms: response.responseJSON.confirms,
            });
            warningDialog.once('saveWithWarning', this.saveWithWarning, this);
            dialog.custom(warningDialog);
            this.showDelayedConfirmation = true;
        } else {
            // Only include failed payments in the error message
            const confirmResponse = util.clone(response.responseJSON);
            const confirmResults = confirmResponse.confirms.confirmResults
                .filter(confirmResult => confirmResult.result === false);
            confirmResponse.confirms.totalSuccess = 0;
            confirmResponse.confirms.confirmResults = confirmResults;
            alertMessage.renderMessage(this, 'save', confirmResponse, 0, true);
            scroll.scrollToFirstError();
        }
        // Display confirmation for successful payments, if any
        const totalSuccess = this.delayedConfirmData ? this.delayedConfirmData.length : 0;
        if (totalSuccess > 0) {
            const successMessage = `${totalSuccess + (totalSuccess > 1 ? ` ${locale.get('payment.payment.plural')}`
                : ` ${locale.get('payment.payment')}`)} ${locale.get('payment.processed')}`;

            const confirmResponse = {
                confirms: {
                    confirmResults: [],
                },
            };

            this.delayedConfirmData.forEach((item) => {
                confirmResponse.confirms.confirmResults.push(item);
            });
            // display notification message
            const successAlertView = alert.success(
                successMessage,
                {
                    details: new Confirms({
                        confirms: confirmResponse.confirms,
                    }),

                    canDismiss: true,
                    animate: true,
                },
            );

            this.ui.$alertContainer.append(successAlertView.render().el);
            if (!this.showDelayedConfirmation) {
                this.delayedConfirmData = [];
            }
        }
    },

    /**
     * Flags the possible duplicates in the pending payment collection so the
     * duplicate dialog can match up duplicate reasons with their models.
     * @param response the JSON response to the initial submit.
     * @returns {undefined}
     */
    updateCollectionFromFailedResults(response) {
        if (response.errorCode === constants.DUPLICATE_ERROR_CODE) {
            util.each(response.confirms.confirmResults, (data) => {
                /**
                 * With a multi-add we may have some warnings for other conditons.
                 *  We don't want flag those payments as duplicates.
                 */
                if (data.resultType === 'DUPLICATEWARNING') {
                    util.each(data.confirmData, (cd) => {
                        const confirmData = transform.pairsToHash(cd.item);
                        this.collection.each((obj) => {
                            const sameDate = moment(obj.get('VALUE_DATE'), userInfo.getDateFormat()).isSame(confirmData.VALUE_DATE);
                            if (sameDate
                                && confirmData.BENE_NAME === obj.get('BENE_NAME')
                                && confirmData.DEBIT_ACCOUNT_NUMBER === obj.get('DEBIT_ACCOUNT_NUMBER')
                                && confirmData.BENE_ACCOUNT === obj.get('BENE_ACCOUNT')
                                && parseFloat(confirmData.CREDIT_AMOUNT) === parseFloat(obj.get('CREDIT_AMOUNT'))) {
                                obj.set('POSSIBLEDUPLICATE', 'true');
                            }
                        });
                    });
                }
            });
        }
    },

    isSuccessItem(confirmData, obj) {
        return `${obj.get('DEBIT_ACCOUNT_TITLE')} ${obj.get('DEBIT_ACCOUNT_NUMBER')}` === confirmData['From:']
        && `${obj.get('BENE_NAME')} ${obj.get('BENE_ACCOUNT')}` === confirmData['To:']
        && parseFloat(obj.get('CREDIT_AMOUNT')) === Formatter.unformatNumber(confirmData['Amount:'])
        && moment(obj.get('VALUE_DATE'), userInfo.getDateFormat()).isSame(confirmData['Value Date:']);
    },

    /**
     * @method clearSuccessfulPayments -  clears payments successfully submitted.
     * @param {object} rows - success payments results
     */
    clearSuccessfulPayments(rows) {
        util.each(rows, (row) => {
            const item = transform.pairsToHash(row.confirmData[0].item);
            const matchedModel = this.collection.find(obj => this.isSuccessItem(item, obj));
            if (matchedModel) {
                this.collection.remove(matchedModel);
            }
        });
    },

    templateHelpers() {
        const helpers = MultiAddLayoutView.prototype.templateHelpers.call(this);
        return util.extend(
            helpers,
            {
                getItemsLabel: locale.get(this.addButtonLabel),
            },
        );
    },
});
