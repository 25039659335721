import Layout from '@glu/core/src/layout';
import { asView } from 'common/util/reactUtil';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import userInfo from 'etc/userInfo';
import systemConfig from 'system/configuration';
import template from './emailRecipients.hbs';
import EmailRecipients from './EmailRecipientsSelector';
import scheduleUtil from '../util';

export default Layout.extend({
    loadingTemplate,
    template,

    initialize() {
        if (systemConfig.isAdmin() || this.model.get('EMAILRECIPIENTS')) {
            this.setHasLoadedRequiredData(true);
            return;
        }
        scheduleUtil.getUser(userInfo.get('group'), userInfo.get('id'))
            .then(({ emailEntitlements }) => {
                this.defaultOptions = emailEntitlements;
                this.setHasLoadedRequiredData(true);
                this.render();
            });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            return;
        }
        const Dropdown = asView(EmailRecipients);
        const options = this.getOptions();
        this.model.set('EMAILRECIPIENTS', options);
        this.dropdownRegion.show(new Dropdown({
            options,
            onFilter: this.options.onFilter,
            disabled: this.options.disabled,
            value: options,
            onClose: this.updateEmailRecipients.bind(this),
        }));
    },

    updateEmailRecipients(selection) {
        this.model.set('EMAILRECIPIENTS', selection);
    },

    /**
     * Get a list selected ids
     * @returns {Array} - array of options
     */
    getSelectedIds(options) {
        return options.map(option => option.id);
    },

    /**
     * Get the default options
     * @returns {Array} - array of options
     */
    getOptions() {
        if (this.model.get('EMAILRECIPIENTS')) {
            return this.model.get('EMAILRECIPIENTS').map((recipient => ({
                id: recipient.id,
                name: recipient.name,
            })));
        }
        if (systemConfig.isAdmin()) {
            return [
                {
                    id: this.model.get('userId')[0].id,
                    name: this.model.get('userId')[0].name,
                },
            ];
        }
        return this.defaultOptions;
    },
});
