/* istanbul ignore file */
import { registerDefaultsForModule } from '@glu/theming';
import legacy from './themes/legacyTheme';
import ash from './themes/ashTheme';

const themes = { ash, legacy };

registerDefaultsForModule(
  'snackbarReact', ({ name, ...restTheme }) => themes[name](restTheme)
);
