import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import Formatter from 'system/utilities/format';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(json) {
        this.requestData = json;
    },

    parse(jsonData) {
        const response = jsonData.inquiryResponse.rows;

        return this.convertServerJsonToModelAttributes(response);
    },

    sync(method, model, options) {
        const url = services.generateUrl('/inquiry/getData');

        http.post(url, this.requestData, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    /**
     * @method convertServerJsonToModelAttributes
     * @param {object} serverJson Rest service response
     */
    convertServerJsonToModelAttributes(serverJson) {
        this.totalViewableRows = 0;
        this.totalRows = serverJson.length;

        let response = util.map(serverJson, item => util.chain(item.columns)
            .map((column) => {
                const key = column.fieldName
                    .replace(/^MAX\(|^MIN\(|^count\(distinct\(|^SUM\(/, '')
                    .replace(/\)*$/, '');

                return [key, column.fieldValue];
            })
            .object()
            .value());

        if (response && response[0]) {
            response = util.map(response, (loc) => {
                const locParam = loc;
                locParam.LOCATIONCODE_ATTR = locParam.LOCATIONCODE
                    && locParam.LOCATIONCODE.replace(/ /g, '');
                return locParam;
            });
        }

        return response;
    },

    /**
     * @method processLockboxData
     * @param {Object[]} allRows - Summarizes Lockbox Data for list view
     */
    processLockboxData(allRows) {
        const self = this;

        const locations = util.groupBy(allRows, lockbox => lockbox.LOCKBOXLOCATIONCODE);

        // sums each value of the array
        const getSum = function (numbers) {
            return util.reduce(numbers, (memo, value) => {
                let number = value;
                if (typeof value === 'string') {
                    number = +value.replace(/[^0-9-.]/g, '');
                }
                return memo + number;
            }, 0);
        };

        util.each(locations, (item, index) => {
            let totalAmount = 0;
            let itemCount = 0;
            const lockboxes = [];

            for (let z = 0; z < locations[index].length; z += 1) {
                totalAmount += +locations[index][z].TOTAL_AMOUNT.replace(/,/g, '');
            }
            const group = util.groupBy(locations[index], lockbox => lockbox.LOCKBOX_ID);

            util.each(group, (lockboxParam) => {
                const lockbox = lockboxParam;
                let lockboxTotal = 0;
                let transactionItemCount = 0;
                const groupedRows = [];
                let batchIDs = [];

                if (lockbox[0].REPORT_FLOAT.toUpperCase() === 'N') {
                    for (let b = 0; b < lockbox.length; b += 1) {
                        delete lockbox[b].IMMED_AMOUNT;
                        delete lockbox[b].ONEDAY_AMOUNT;
                        delete lockbox[b].TWODAY_AMOUNT;
                    }
                }

                // collect all batch IDs of each lockbox transaction
                util.each(lockbox, (transaction) => {
                    batchIDs.push(transaction.BATCH_ID);
                });

                // until service is updated, manually extract the unique batch ids.
                batchIDs = util.uniq(batchIDs);

                // consolidate transaction rows
                const groupRowsBy = function (array, func) {
                    const groups = {};
                    array.forEach((obj) => {
                        const groupId = JSON.stringify(func(obj));
                        groups[groupId] = groups[groupId] || [];
                        groups[groupId].push(obj);
                    });
                    return Object.keys(groups).map(grp => groups[grp]);
                };

                const dataGroup = groupRowsBy(lockbox, lbxItem => [
                    lbxItem.DEPOSIT_DATE,
                    lbxItem.PAYMENT_TYPE,
                    lbxItem.BANK_BATCH_ID,
                    lbxItem.CUSTOMER_BATCH_ID,
                ]);

                util.each(dataGroup, (lbxGroup) => {
                    const totals = util.chain(lbxGroup).groupBy('LOCKBOX').map((value, key) => {
                        const obj = {
                            LOCKBOX: key,
                            TOTAL_AMOUNT: Formatter.formatCurrency(getSum(util.pluck(value, 'TOTAL_AMOUNT'))),
                            NUM_PAYMENTS: getSum(util.pluck(value, 'NUM_PAYMENTS')),
                        };
                        if (lbxGroup.IMMED_AMOUNT) {
                            obj.IMMED_AMOUNT = Formatter.formatCurrency(getSum(util.pluck(value, 'IMMED_AMOUNT')));
                        }
                        if (lbxGroup.ONEDAY_AMOUNT) {
                            obj.ONEDAY_AMOUNT = Formatter.formatCurrency(getSum(util.pluck(value, 'ONEDAY_AMOUNT')));
                        }
                        if (lbxGroup.TWODAY_AMOUNT) {
                            obj.TWODAY_AMOUNT = Formatter.formatCurrency(getSum(util.pluck(value, 'TWODAY_AMOUNT')));
                        }

                        return obj;
                    }).value();

                    util.extend(lbxGroup[0], totals[0]);

                    groupedRows.push(lbxGroup[0]);
                });
                // end consolidation

                // get total amount and number of payments from each grouped row
                util.each(groupedRows, (row) => {
                    const rowTotal = row.TOTAL_AMOUNT;
                    lockboxTotal += +rowTotal.replace(/[^0-9-.]/g, '');
                    transactionItemCount += +row.NUM_PAYMENTS;
                });

                lockboxes.push({
                    lockbox: groupedRows,
                    filter: lockbox[0].LOCKBOX,
                    details: lockbox[0].DETAIL_REPORTS === 'Y',
                    code: lockbox[0].LOCKBOX.replace(/[^A-Z0-9]/ig, ''),
                    totalAmount: Formatter.formatCurrency(lockboxTotal),
                    itemCount: transactionItemCount,
                    batchIDs,
                });

                self.totalViewableRows += groupedRows.length;

                itemCount += transactionItemCount;
            });
            locations[index] = lockboxes;
            locations[index].lockboxCount = util.size(locations[index]);
            // keep count of all rows of each location
            locations[index].itemCount = itemCount;
            locations[index].grandTotal = Formatter.formatCurrency(totalAmount);
            locations[index].stripWhiteSpace = function (key) {
                return key.replace(/\s/g, '');
            };
        });
    },
});
