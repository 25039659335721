var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.enterLimitsMessageACH",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":61}}}))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.enterLimitsMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":71},"end":{"line":2,"column":106}}}))
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"field-container-xs\">\n                <label for=\"batchLimit\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.batchLimit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":40},"end":{"line":12,"column":67}}}))
    + "</label>\n                <input id=\"batchLimit\" type=\"text\" class=\"form-control\" data-bind=\"model\" name=\"batchLimit\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"batchLimit\"></span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   <div class=\"field-container-lg\">\n       <p>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isACH") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":114}}})) != null ? stack1 : "")
    + "</p>\n   </div>\n    <div>\n        <div class=\"field-container-xs\">\n            <label for=\"transactionLimit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.transactionLimit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":75}}}))
    + "</label>\n            <input id=\"transactionLimit\" type=\"text\" class=\"form-control\" data-bind=\"model\" name=\"transactionLimit\" autofocus/>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"transactionLimit\"></span>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isACH") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"field-container-xs\">\n            <label for=\"dailyLimit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.dailyLimit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":36},"end":{"line":18,"column":63}}}))
    + "</label>\n            <input id=\"dailyLimit\" type=\"text\" class=\"form-control\" data-bind=\"model\" name=\"dailyLimit\"/>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"dailyLimit\"></span>\n        </div>\n    </div>\n";
},"useData":true});