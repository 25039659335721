import util from '@glu/core/src/util';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import f1 from 'system/utilities/f1';
import locale from '@glu/locale';
import PaymentUtil from 'common/util/paymentUtil';
import $ from 'jquery';
import Formatter from 'system/utilities/format';

const NOFILTER_ALERT_TYPES = [
    'BDACHCC',
    'BDACHCCD',
    'BDACHCD',
    'BDACHCSP',
    'BDACHCRC',
    'BDACHCCP',
    'BDACHCP',
    'BDACHCEC',
    'BDACHCVP',
    'BDACHIIC',
    'BDACHTP',
];

export default function (form, initialState) {
    const formState = form.formView.state;
    const { beneWidget, parentModel, useBeneWidget } = form.formView;
    const beneForm = useBeneWidget ? f1.getForm(beneWidget) : form;
    const beneFormState = beneForm.formView?.state?.toLowerCase() || '';
    let entryMethod = parentModel.context.entrymethod;
    const startDateControl = beneForm.field('DONTINCLDBEFORE');
    const startDate = beneForm.field('STARTDATE');
    const expirationDateControl = beneForm.field('DONTINCLDAFTER');
    const expirationDate = beneForm.field('EXPIRATIONDATE');
    const usergroupFieldInAuditSection = beneForm.field('USERGROUP');
    const addBene = beneForm.field('ADDBENE');
    const clearBene = beneForm.field('CLEARBENE');
    const bankAba = beneForm.field('RECEIVABA');
    const bankName = beneForm.field('RECEIVBANKNAME');
    const beneLookup = beneForm.field('BENE_NAME_LOOKUP');
    const beneModel = beneForm.formView.model; // PCM-1220
    const currency = beneForm.field('ORIGCURRENCYCODE');
    const beneReverseReason = beneForm.field('BENEREVERSEREASON');
    const amount = beneForm.field('AMOUNT');
    const formattedAddenda = beneForm.field('PAYMENTADDENDA');
    const isZerodollarLiveEnabled = applicationConfigParams.getValue('ACH', 'ALLOWZERODOLLAR') === '1';
    const isNachaWebTelModernized =
        applicationConfigParams.getValue('ACH', 'NACHA_WEB_TEL_PMTTYPECODE_CHANGE') === '1';

    // for when in view mode
    const $startDateField = $('#STARTDATE');

    const $expirationDateField = $('#EXPIRATIONDATE');
    const $corrCodeField = $('#CORRCODE1');
    const $remittanceField1 = $('#REMITTANCE1');
    const $remittanceField2 = $('#REMITTANCE2');
    const $taxNameTypeCode = $('#TAXNAME_TYPECODE');
    const { model } = form.formView;
    const { functionCode } = model.jsonData.typeInfo;
    let accountType;
    const abaFreeform = model.fieldData.ABAFREEFORM;
    let typeCode = '';

    const updateAmountForPrenote = function () {
        if (model.get('PRENOTEFLAG') === '1') {
            /*
             * HACK stop gap fix for NH-45470.  This only fixes the issue in INSERT mode.
             * TODO more work is needed to fix the focus in modify
             */
            model.set({
                ACCOUNT_ALLOCATION: 0,
                AMOUNT: 0,
            });
            beneForm.field('AMOUNT').shouldBeReadOnly(true);
        } else {
            /*
             * This fixes NH-45615 where the PRENOTEFLAG is not saved from the bene form
             * if the checkbox is never selected.
             */
            model.set(
                'PRENOTEFLAG',
                0,
                {
                    silent: true,
                },
            );
            if (model.get('ZERODOLLARLIVE') !== '1') {
                beneForm.field('AMOUNT').shouldBeReadOnly(false);
            }
        }
        // make sure currentTarget has the correct updated amount
        if (amount.$el.length > 0) {
            amount.$el[0].value = model.get('AMOUNT');
            PaymentUtil.runChangeAmount(amount.$el[0], model);
        }
    };

    // PCM-1220
    const cleanMandatoryFieldValidationMessages = () => {
        // This method clears validation msg-s that stays once user fill
        // mandatory fields by resetting validation states on those fields
        const validatorsKeys = Object.keys(beneModel.validators);

        for (let index = 0; index < validatorsKeys.length; index += 1) {
            const validatedField = beneForm.field(validatorsKeys[index]);
            const validator = beneModel.validators[validatorsKeys[index]];

            if (validatedField.isNotEmpty() && validator.exists) {
                // validator exists is used for mandatory fields
                validatedField.resetValidationState();
            }
        }
    };
    // PCM-1220

    const showHideAddBeneButton = () => {
        // if this is a payment created from a template, hide the add and clear bene buttons
        if (parentModel.context?.createdFrom === '1' && parentModel.context?.entryMethod === '1') {
            form.field('ADDBENE').shouldBeHidden();
            form.field('CLEARBENE').shouldBeHidden();
        }
    };

    // Fix: NH-122113
    const styleDateControl = () => {
        startDateControl.$el.parent('.align-checkbox').attr('id', 'DONTINCLDBEFOREPARENT');
        expirationDateControl.$el.parent('.align-checkbox').attr('id', 'DONTINCLDAFTERPARENT');
    };
    // end NH-122113

    // NH-88514 displayed the formatted addenda in view, hide it in insert and modify
    const displayAddenda = () => {
        if (form.formView.state === 'modify' || form.formView.state === 'insert') {
            formattedAddenda.shouldBeHidden();
        } else if (form.formView.state === 'view') {
            form.formView.$('#REFCODE').addClass('hidden');
            form.formView.$('#MESSAGE').addClass('hidden');
        }
    };

    const openOptionalSections = () => {
        if (form.formView.state === 'modify' || form.formView.state === 'restore') {
            if (beneForm.field('STARTDATE').isNotEmpty() || beneForm.field('EXPIRATIONDATE').isNotEmpty()) {
                beneForm.field('STARTDATE').$el.closest('.panel-collapse').collapse('show');

                if (beneForm.field('STARTDATE').isNotEmpty()) {
                    startDateControl.$el.prop('checked', true).change();
                }

                if (beneForm.field('EXPIRATIONDATE').isNotEmpty()) {
                    expirationDateControl.$el.prop('checked', true).change();
                }
            }

            if (beneForm.field('CORRCODE1').isNotEmpty()) {
                beneForm.field('CORRCODE1').$el.closest('.panel-collapse').collapse('show');
            }
            if (beneForm.field('REMITTANCE1').isNotEmpty() || beneForm.field('REMITTANCE2').isNotEmpty()) {
                beneForm.field('REMITTANCE1').$el.closest('.panel-collapse').collapse('show');
            }
        }
        if (form.formView.state === 'view') {
            if ($startDateField.val() === '' || $expirationDateField.val() === '') {
                $startDateField.closest('.panel-collapse').collapse('show');
                $expirationDateField.closest('.panel-collapse').collapse('show');
                $startDateField.find('label').text(locale.get('PAY.STARTDATE.label'));
                $expirationDateField.find('label').text(locale.get('PAY.EXPIRATIONDATE.label'));
            }
            if ($corrCodeField.val() === '') {
                $corrCodeField.closest('.panel-collapse').collapse('show');
            }
            if ($remittanceField1.val() === '' || $remittanceField2.val() === '') {
                $remittanceField1.closest('.panel-collapse').collapse('show');
                $remittanceField2.closest('.panel-collapse').collapse('show');
            }
        }
        if ($taxNameTypeCode.length) {
            $taxNameTypeCode.find('.form-control-static span').text(model.get('TAXTYPECODE'));
        }
    };

    // Reset amount to 0 if Zero Dollar Live Entry is selected
    const setAmountAccess = () => {
        if (isZerodollarLiveEnabled && model.get('ZERODOLLARLIVE') === '1' && typeCode !== 'BDACHTP') {
            beneForm.field('AMOUNT').shouldBeReadOnly(true);
        } else if (!model.get('PRENOTEFLAG')) {
            beneForm.field('AMOUNT').shouldBeReadOnly(false);
        }
    };

    /**
     *  NH-114963 - Disallow (disable) the "Create Prenote" checkbox.
     * Please keep in mind this behavior should NOT be applied for
     * Tax and Child Payments, they must work same as currently - "Create Prenote"
     * checkbox is not disabled when Zero Dollar Live checkbox is enabled
     */
    const setPrenoteAccess = () => {
        if (isZerodollarLiveEnabled && model.get('ZERODOLLARLIVE') === '1' && typeCode !== 'BDACHTP' && typeCode !== 'BDACHCSP') {
            model.set('PRENOTEFLAG', '0');
            beneForm.field('PRENOTEFLAG').shouldBeReadOnly(true);
        } else {
            beneForm.field('PRENOTEFLAG').shouldBeReadOnly(false);
        }
    };

    const overrideValidator = (fieldName, options) => {
        if (model && model.validators && model.validators[fieldName]) {
            const { description } = model.validators[fieldName];

            model.removeValidator(fieldName);
            model.addValidator({
                [fieldName]: {
                    ...{ description: description || fieldName },
                    ...options,
                },
            });
        }
    };

    // Addenda is mandatory for "Corporate Vendor Payments"
    const setAddendaRequired = () => {
        // 0 - all types, 1 - freeForm, 2 - formatted
        const fields = [
            { name: 'ADDENDAFORMAT', type: '0' },
            { name: 'MESSAGE', type: '1' },
            { name: 'TOTALINVOICEAMOUNT', type: '2' },
            { name: 'TERMSDISCOUNT', type: '2' },
            { name: 'AMOUNTOFADJ', type: '2' },
            { name: 'ADJREASONCODE', type: '2' },
            { name: 'REFNUMBER', type: '2' },
            { name: 'REFNUMBERQUALIFIER', type: '2' },
            { name: 'PAYMENTDESCRIPTION', type: '2' },
        ];
        const addendaType = beneForm.field('ADDENDAFORMAT').getValue();

        if (typeCode === 'BDACHCVP') {
            const isRequired = isZerodollarLiveEnabled && model.get('ZERODOLLARLIVE') === '1';

            fields.forEach((field) => {
                PaymentUtil.toggleAmountRequired(
                    $(`[name="${field.name}"]`),
                    field.name, model,
                    isRequired && (field.type === addendaType || field.type === '0'),
                );
            });

            overrideValidator('REFNUMBERQUALIFIER', { exists: true });
            overrideValidator('ADJREASONCODE', { exists: true });
            overrideValidator('MESSAGE', { exists: true });
            overrideValidator('PAYMENTDESCRIPTION', { exists: true });
        }
    };

    const showHideZeroDollarLive = () => {
        if (typeCode === 'BDACHTP') {
            return;
        }
        const zeroDollarField = beneForm.field('ZERODOLLARLIVE');

        if (!isZerodollarLiveEnabled && !zeroDollarField.isChecked()) {
            zeroDollarField.shouldBeHidden();
        }
    };

    const setZeroDollarLive = () => {
        if (!isZerodollarLiveEnabled) {
            const currentAmount = model.get('AMOUNT');
            model.set('AMOUNT', Formatter.unformatNumber(currentAmount) > 0 ? currentAmount : '');
        }
    };

    const setDefaultValues = () => {
        if (parentModel.get('HEALTHCARE') === '1' && formState !== 'view') {
            beneForm.field('ADDENDAFORMAT').shouldBeReadOnly(true);
            $('#ADDENDAFORMAT').append(`<option value="3">${locale.get('PAY.Healthcare')}</option>`);
            model.set('ADDENDAFORMAT', 3);
        } else if (parentModel.get('HEALTHCARE') === '0' && parentModel.get('FROMTEMPLATE') !== '1') {
            model.set({
                ADDENDAFORMAT: '',
                TRACETYPECODE: '',
                REFERENCEID: '',
                ORGCOMPCODE: '',
                ORGCOMPSUPPLEINFO: '',
            });
            beneForm.field('ADDENDAFORMAT').shouldBeReadOnly(false);
            $('#ADDENDAFORMAT option[value="3"]').detach();
        }

        showHideZeroDollarLive();

        if (formState !== 'view') {
            setAmountAccess();
            setPrenoteAccess();
            setAddendaRequired();
            setZeroDollarLive();
        }
    };

    if (initialState) {
        // TEMPORARY Fix: NH-33472
        form.field('UPDATEADDRESSBOOK').shouldBeHidden();
        // end NH-33472
        if (model.jsonData && model.jsonData.typeInfo) {
            ({ typeCode } = model.jsonData.typeInfo);
        }

        // Fix: NH-44368
        showHideAddBeneButton();
        // end NH-44368

        // Fix: NH-122113
        styleDateControl();
        // end NH-122113

        setDefaultValues();

        if (formState !== 'view') {
            beneReverseReason.shouldBeVisibleWhen(false);
        }

        model.on('change:ADDENDAFORMAT', () => {
            setAddendaRequired();
        });

        model.on('change:ZERODOLLARLIVE', () => {
            setAmountAccess();
            setPrenoteAccess();
            setAddendaRequired();

            if (model.get('ZERODOLLARLIVE') === '1') {
                model.set('AMOUNT', '0');
            } else {
                model.set('AMOUNT', '');
            }
        });

        model.on('change:ACCOUNTTYPE', () => {
            if (model.get('ACCOUNTTYPE')) {
                accountType = util.findWhere(
                    model.fieldData.ACCOUNTTYPE.choices,
                    {
                        name: model.get('ACCOUNTTYPE'),
                    },
                );
                if (accountType) {
                    model.set('ACCOUNTTYPEDESC', accountType.value);
                }
            }
            model.set('DESTCURRENCYCODE', parentModel.get('DESTCURRENCYCODE'));

            if (model.jsonData && model.jsonData.typeInfo) {
                ({ typeCode } = model.jsonData.typeInfo);
            }

            if (typeCode === 'BDACHIAT') {
                model.set('ORIGCURRENCYCODE', parentModel.get('ORIGCURRENCYCODE'));
            }
        });

        if (formState === 'insert') {
            // Hide the audit section
            usergroupFieldInAuditSection.$el.closest('.section').hide();
            // hide summary section
            form.formView.$('[data-widget-id="summarysection"]').closest('fieldset').hide();
            /*
             * HACK stop gap fix for NH-45470.  This only fixes the issue in INSERT mode.
             * TODO more work is needed to fix the focus in modify
             */
            model.set('AMOUNT', 0);
        }

        /*
         * NH-77779 for view and modify, check payment status to make sure it's submitted,
         * otherwise, hide the transaction history
         */
        if ((formState === 'view' || formState === 'modify') && model.get('STATUS') === '') {
            // hide summary section
            form.formView.$('[data-widget-id="summarysection"]').closest('fieldset').hide();
        }

        if (!util.isUndefined(parentModel.context.actionData) && parentModel.context.actionData.action === 'REPAIR') {
            Object.keys(form.fields || {}).forEach((daField) => {
                if (daField === 'AMOUNT') {
                    return;
                }
                form.fields[daField].shouldBeReadOnly(true);
            });

            $('.lookup').addClass('hidden');
            $('textarea[name="TRANCOMMENT"]').prop('readonly', true);
        }
        model.on('change:PRENOTEFLAG', () => {
            if (model.get('PRENOTEFLAG') !== '1') {
                model.set('AMOUNT', '');
            }
            updateAmountForPrenote();
        });

        // PCM-1220
        beneModel.on('change', cleanMandatoryFieldValidationMessages);
        // PCM-1220

        if (form.field('PRENOTEFLAG').$el.size() > 0) {
            updateAmountForPrenote();
        }

        model.on('change:HOLDFLAG', () => {
            PaymentUtil.toggleCheckboxLock($('[name="HOLDFLAG"]'));
        });

        Object.keys(form.fields || {}).forEach((mField) => {
            let valueInField = model.get(mField);
            if (!util.isString(valueInField) || valueInField.indexOf('&amp;') === -1) {
                return;
            }
            valueInField = valueInField.replace('&amp;', '&');
            model.set(mField, valueInField);
        });

        Object.keys(model.fieldData || {}).forEach((theField) => {
            const refTheField = $(`#${theField}`);
            if (refTheField && refTheField[0]) {
                if (refTheField[0].innerHTML.indexOf('&amp;') === -1) {
                    return;
                }
                refTheField[0].innerHTML = refTheField[0].innerHTML.replace('&amp;', '&');
            }
        });

        /*
         * NH-88514 for the specified payment types, displayed the formatted addenda
         * in view, hide it in insert and modify
         */
        if (util.contains(NOFILTER_ALERT_TYPES, typeCode)) {
            displayAddenda();
        }

        // This will open the optional sections that are populated in modify mode
        openOptionalSections();

        if (functionCode.indexOf('TMPL') > -1) {
            $('[data-field="summary-date"]').hide();
            $('.summary-date-text').hide();
        }

        if (isZerodollarLiveEnabled && model.get('ZERODOLLARLIVE') === '1' && typeCode !== 'BDACHTP') {
            model.set('AMOUNT', 0);
            beneForm.field('AMOUNT').shouldBeReadOnly(true);
        }

        /*
         * TEMPORARY: NH-163749 - As part of the NACHA Meaningful Modernization on September 17,
         * 2021 we are changing the way the Payment Type Code field behaves for WEB and TEL ACH
         * transactions. The following code specifically overrides the code changes to its pre-
         * modernization functionality when the configuration is disabled. This is necessary
         * because the changes have a specific go-live date and the field needs to continue to have
         * preexisting functionality until that time. This code can be removed at any point after
         * the modernization changes are live and backwards compatibility is no longer required.
         */
        if (!isNachaWebTelModernized && formState !== 'view'
                && (typeCode === 'BDACHIIC' || typeCode === 'BDACHTIC')) {
            if (typeCode === 'BDACHIIC') {
                // Make PaymentTypeCode Mandatory
                beneForm.field('PAYMENTTYPECODE').shouldBeRequired(true);
            }

            // Remove Standing Authorization Option from PaymentTypeCode
            $('#PAYMENTTYPECODE option[value="ST"]').detach();
        }
        /* End of NH-163749 NACHA Meaningful Modernization September 17, 2021 */

        if (typeCode === 'BDACHCVP' && formState !== 'view') {
            if ($('#HEALTHCARE').prop('checked')) {
                $('#ADDENDAFORMAT').append(`<option value="3">${locale.get('PAY.Healthcare')}</option>`);
            } else {
                $('#ADDENDAFORMAT option[value="3"]').detach();
            }
        }

        model.listenTo(model, 'beneWidget:defaults', () => {
            // clears date validation when while adding add benificiaries
            expirationDate.shouldBeOptional(true);
            startDate.shouldBeOptional(true);
            setDefaultValues();
            if (beneFormState === 'modify') {
                util.each([
                    'ACCOUNTTYPE',
                    'ACCOUNT_ALLOCATION',
                    'ALLOCATION_TYPE',
                    'SECOND_ACCOUNTTYPE',
                    'INDVID',
                ], (fieldName) => {
                    const fieldModel = beneForm.formView?.jsonModel?.find((
                        field => field.name === fieldName
                    ));
                    beneForm.field(fieldName)?.$el.closest('.hidden')
                        .addClass(`field-container ${fieldModel?.blockClass || ''}`)
                        .removeClass('hidden');
                });
            }
        });

        // disable 'prenote' and 'zero dollar' if amount field is locked, in payment mode
        if (functionCode === 'BATCH' && ((model && model.has('LOCKED_FIELDS') && model.get('LOCKED_FIELDS').indexOf('AMOUNT') > -1)
            || (parentModel && parentModel.has('LOCKED_FIELDS') && parentModel.get('LOCKED_FIELDS').indexOf('AMOUNT') > -1))) {
            $('[name="PRENOTEFLAG"]').prop('disabled', true);
            $('[name="ZERODOLLARLIVE"]').prop('disabled', true);
        }

        if (model.fieldData.ACCOUNTTYPE && model.fieldData.ACCOUNTTYPE.protected) {
            form.formView.$('#ACCOUNTTYPE >p >span').text(model.get('ACCOUNTTYPEDESC'));
        }

        // if this is template and recurring, make sure amount is required
        if (functionCode === 'BHTMPL' && parentModel.schedModel && parentModel.get('SCHEDULED')) {
            let fieldName = 'AMOUNT';
            if (parentModel.get('TYPE') === 'BDACHTP') {
                fieldName = 'AMOUNT1';
            }
            // filter out amount field that's in the bene grid
            const $field = $(`[name="${fieldName}"]`).not('[data-hook="getNachaAmountInput"]');
            PaymentUtil.toggleAmountRequired($field, fieldName, model, true);
        }
    }

    if (formState !== 'view') {
        startDate.$el.parent().children('label').hide();
        expirationDate.$el.parent().children('label').hide();
    }

    // Hide any panels that are empty in view mode
    if (formState === 'view') {
        form.formView.$('.panel-body').filter(':empty').closest('.row').hide();
    }

    if (initialState && model.childNumber > 1) {
        if (currency.isEmpty()) {
            currency.setValue('USD');
        }
    }
    if (abaFreeform && abaFreeform.value === 'freeform' && initialState) {
        bankAba.shouldBeReadOnly(false);
        bankName.shouldBeReadOnly(false);
    }
    if (entryMethod === undefined && (formState === 'modify' || formState === 'restore')) {
        entryMethod = parentModel.get('ENTRYMETHOD');
    }

    // if bankcode look up is protected, then disable both bankcode lookups
    if (model.fieldData.RECEIVABALOOKUP && model.fieldData.RECEIVABALOOKUP.protected) {
        $('[name="RECEIVABA_LOOKUP"]').prop('disabled', true);
        $('[name="SECOND_BANKCODE_LOOKUP"]').prop('disabled', true);
    }

    // if payment from template
    if (entryMethod === '1') {
        addBene.shouldBeVisibleWhen(false);
        clearBene.shouldBeVisibleWhen(false);
        beneLookup.shouldBeVisibleWhen(false);
        if (form.field('REFCODE')) {
            form.field('REFCODE').shouldBeReadOnly(false);
        }
        bankAba.shouldBeReadOnly(true);
        $('[name="RECEIVABA_LOOKUP"]').prop('disabled', true);
        $('[name="SECOND_BANKCODE_LOOKUP"]').prop('disabled', true);
        $('[name="RECEIVRBANKCODE_LOOKUP"]').prop('disabled', true);
        $('[name="RECEIVCOMPNAME_LOOKUP"]').prop('disabled', true);
        $('[name="INDVNAME_LOOKUP"]').prop('disabled', true);
    }
    const checkDateValidator = () => {
        const beforeChecked = !!model.get('DONTINCLDBEFORE');
        const afterChecked = !!model.get('DONTINCLDAFTER');
        const hasValidators = model.validators?.STARTDATE?.isDateBefore !== undefined;
        if (beforeChecked && afterChecked && !hasValidators) {
            startDate.setValidator(startDate.$el, 'isDateBefore', 'EXPIRATIONDATE');
            expirationDate.setValidator(expirationDate.$el, 'isDateAfter', 'STARTDATE');
        } else if ((!beforeChecked || !afterChecked) && hasValidators) {
            startDate.removeValidator('isDateBefore');
            expirationDate.removeValidator('isDateAfter');
        }
    };
    // reset the start date if unchecked
    startDateControl
        .$el.on('click', () => {
            const startDateCtrl = startDateControl.$el;
            const flag = startDateCtrl.is(':checked');
            startDateCtrl.prop('checked', flag).change();
            if (flag) {
                startDate.shouldBeRequired(true);
            } else {
                model.set('STARTDATE', '');
                startDate.setValue('');
                startDate.shouldBeOptional(true);
            }
        });

    // reset the expiration date if unchecked
    expirationDateControl
        .$el.on('click', () => {
            const expirationDateCtrl = expirationDateControl.$el;
            const flag = expirationDateCtrl.is(':checked');
            expirationDateCtrl.prop('checked', flag).change();
            if (flag) {
                expirationDate.shouldBeRequired(true);
            } else {
                model.set('EXPIRATIONDATE', '');
                expirationDate.setValue('');
                expirationDate.shouldBeOptional(true);
            }
        });

    // check for the user if data is entered
    startDate
        .$el.on('change', () => {
            if (startDate.isNotEmpty()) {
                startDateControl.$el.prop('checked', true).change();
            }
        });

    // check for the user if expiration data is entered
    expirationDate
        .$el.on('change', () => {
            if (expirationDate.isNotEmpty()) {
                expirationDateControl.$el.prop('checked', true).change();
            }
        });

    model.on('change:EXPIRATIONDATE change:STARTDATE change:DONTINCLDAFTER change:DONTINCLDBEFORE', () => {
        checkDateValidator();
    });
}
