import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import services from 'services';
import $ from 'jquery';
import filterApi from 'common/dynamicPages/api/filters';
import Wrapper from 'common/dynamicPages/api/gridWrapper';
import SavedViewsCollection from 'common/dynamicPages/collections/savedViews';
import dateUtil from 'common/util/dateUtil';
import Model from '@glu/core/src/model';
import scrollUtil from 'common/util/scroll';
import constants from 'common/dynamicPages/api/constants';
import FilterParent from 'app/balanceAndTransaction/views/filterParent';
import SavedViewsView from './savedViewsList';
import { filterModelIsValid } from '../../../common/util/advancedFilterUtil';
import filterTmpl from './filter.hbs';

export default FilterParent.extend({
    template: filterTmpl,
    className: 'balance-and-transaction-filter',

    templateHelpers() {
        return {
            id: this.cid,
        };
    },

    modelEvents: {
        'change:accountTransactionsView': 'updateTransactionSummary',
        'change:accountSelectBy change:accountSelect': 'updateAccountSummary',
        'change:accountSelectBy change:accountTransactionsView': 'updateComboBoxes',
        'change:accountSelectBy': 'updateAccountSelect',
        'change:accountSelect': 'onAccountSelectChange',
        'getLookupResults:success': 'updateAccountsFromGetLookupResults',
    },

    regions: {
        savedListViewsRegion: '[data-region="savedListViews"]',
        alertRegion: '.alert-region',
        accountAlertRegion: '[data-region="accountAlertRegion"]',
        reportRegion: '[data-region="reportRegion"]',
    },

    ui: {
        $datepicker: '.datepicker',
        $addCriteriaButton: '.btn[data-action="add-criteria"]',
        $accountSelect: '[name="accountSelect"]',
        $editButton: '[data-action="edit"]',
        $updateButton: '[data-action="update-filter"]',
        $saveButton: '[data-action="saveView"]',
        $resetButton: '[data-action="reset"]',
        $cancelButton: '[data-action="cancel"]',
        $saveForm: '.saveForm',
        $transactionsFormatSelect: 'select[name="accountTransactionsView"]',
        $accountSelectBy: 'select[name="accountSelectBy"]',
        $transactionsFormatSummary: '[data-hook="accountTransactionsViewFormatted"]',
        $accountSelectionSummary: '[data-hook="accountSelectionSummary"]',
        $saveReport: '#SAVEREPORT',
        $reportNameForm: '[data-hook="getSavedReportNameForm"]',
    },

    events: {
        'apply.daterangepicker @ui.$datepicker': 'updateModelDates',
        'click @ui.$addCriteriaButton': 'addCriteria',
        'click @ui.$editButton': 'editLink',
        'click @ui.$updateButton': 'updateFilters',
        'click @ui.$resetButton': 'resetFilters',
        'click @ui.$cancelButton': 'cancelFilters',
        'change @ui.$saveReport': 'updateForSave',
        'shown.bs.collapse': 'updateCollapseState',
    },

    initialize(options) {
        /*
         * SRouse - Add UI Bind so the this.ui.* elements are jQuery objects and not
         * inheriting the strings
         */
        this.bindUIElements();
        this.storedModelForCancel = new Model();
        this.rowsPerPage = options.rowsPerPage;
    },

    getDefaultListView() {
        const self = this;
        return new Promise((resolve) => {
            const data = {
                viewType: 'GIR-PRO-GIRACCT',
            };

            http.post(services.generateUrl('/listViewFilter/getDefault'), data, (response) => {
                self.model.set('viewId', response.viewId);
                resolve();
            });
        });
    },

    /**
     * Handle success callback for a this.model.fetch
     */
    modelFetchSuccess() {
        this.updateViewDates();
        this.ui.$accountSelect.comboBox('val', this.model.get('Accounts'));
        this.updateAccountSummary();
        this.updateTransactionSummary();
        this.setTypeAheadText(this.filterRegion);
        this.updateFilters({ updateModel: true });
    },

    getDefaultSuccess() {
        const self = this;
        const collapseSelector = this.$('.collapse');
        const context = {
            serviceName: 'balanceAndTransaction/depositAccounts/getTransactions',
            typeCode: 'GIRACCT',
            productCode: 'GIR',
            functionCode: 'PRO',
        };

        const options = {
            context,
            viewId: this.model.get('viewId'),
        };

        this.grid = {
            collection: {
                context,
            },
        };

        this.wrapper = new Wrapper(options);
        this.context = options.context;
        this.savedViews = new SavedViewsCollection(
            {},
            {
                context: this.context,
            },
        );

        collapseSelector.collapse(options.viewId === '0' ? 'show' : 'hide');

        this.savedViewsView = new SavedViewsView({
            grid: this.grid,
            wrapper: this.wrapper,
            savedViews: this.savedViews,
            canSave: true,
            defaultText: locale.get('common.select'),
        });
        this.savedListViewsRegion.show(this.savedViewsView);

        if (options.viewId !== '0') {
            this.model.fetch({
                success() {
                    self.ifNotClosed(self.modelFetchSuccess)();
                },
            });
        }

        this.listenTo(this.savedViewsView, 'viewSelected', this.viewSelectionHandler);
        this.listenTo(this.savedViewsView, 'viewSaveSuccess', this.handleSaveSuccess);
        this.listenTo(this.savedViewsView, 'comboShown', this.updateNameToSave);
        this.listenTo(this.savedViewsView, 'comboSave', util.bind(function (arg) {
            this.saveView(arg);
        }, this));
        this.listenTo(this.savedViewsView, 'saveError', this.onSaveReportError);
    },

    updateAccountsFromGetLookupResults() {
        // we only want to set the combo after the getListViewFilterDetails has completed
        if (this.listViewFilterDetailCompleted) {
            this.ui.$accountSelect.comboBox('val', this.model.get('Accounts'));
            this.listViewFilterDetailCompleted = false;
        }
    },

    updateAccountSummary() {
        const selectBy = this.model.get('accountSelectBy');
        const selected = this.model.get('accountSelect');
        // TODO update unit for currency and bankcode
        const unit = (selectBy === 'group') ? 'Group' : 'Account';
        const labelText = FilterParent.prototype.updateAccountSummary.call(this, unit, selected);
        this.ui.$accountSelectionSummary.text(labelText);
    },

    updateTransactionSummary() {
        this.ui.$transactionsFormatSummary.text(this.ui.$transactionsFormatSelect.find('option:selected').text());
    },

    /**
     * @method updateAccountSelect
     * Callback method to update account select typeahead if associated Account
     * Select By Type filter changes
     */
    updateAccountSelect() {
        this.ui.$accountSelect.comboBox('data', []);
        this.setupAccountSelectFilter(this.model.get('accountSelectBy'));
    },

    /**
     * @method onAccountSelectChange
     * Callback to check account select typeahead values on change
     */
    onAccountSelectChange() {
        /**
         * will check for cases with selecting/deselecting "all" option
         * if selecting "all" option, should clear other selections
         * if selecting another option while "all" is selected, should remove "all"
         */
        let values = this.ui.$accountSelect.comboBox('data');

        if (values.length === 1) {
            return;
        }

        const allSelectIndex = values
            .map(item => item.id)
            .indexOf('all');

        if (allSelectIndex === -1) {
            return;
        }
        if (allSelectIndex === values.length - 1) {
            values = [values[allSelectIndex]];
        } else {
            values = values.filter(item => item.id !== 'all');
        }

        this.ui.$accountSelect.comboBox('data', values);
    },

    /**
     * @method setupAccountSelectFilter
     * @param {string} selectByKey
     */
    setupAccountSelectFilter(selectByKey) {
        let rowsPerPage = this.rowsPerPage || 1;

        /*
         * default value of input to savedView's filtered accounts if applicable,
         * if not use 'all'
         */
        let allText;
        switch (selectByKey) {
        case 'group':
            allText = locale.get('listviews.allGroups');
            break;
        case 'number':
            allText = locale.get('listviews.allaccounts');
            break;
        case 'bankCode':
            allText = locale.get('listviews.allBanks');
            break;
        case 'currency':
            allText = locale.get('listviews.allCurrencies');
            break;
        default:
            allText = locale.get('listviews.allaccounts');
        }
        const defaultVal = (this.model.changed.accountSelect) ? this.model.get('accountSelect') : { id: 'all', text: allText };

        this.ui.$accountSelect.comboBox({
            initSelection: (element, callback) => {
                const allOptions = this.model.get('accountList');
                const data = util.map($(element.val().split(',')), value => util.findWhere(allOptions, { id: value }) || {
                    id: value,
                    text: value,
                });
                callback((data.length === 1) ? util.first(data) : data);
            },

            multiple: true,
            closeOnSelect: false,

            query: util.debounce((query) => {
                const startRow = (((query.page - 1) * rowsPerPage) + 1);

                if ((!this.rowsPerPage)) {
                    rowsPerPage = (query.term.length < constants.COMBO_MIN_CHARS)
                        ? constants.COMBO_MIN_SIZE : constants.MAX_SERVER_ROWSPERPAGE;
                }
                const options = {
                    selectBy: selectByKey,
                    startRow,
                    rowsPerPage,
                    queryTerm: query.term,
                };

                this.model.fetchAccountFilterOptions(options).then((results) => {
                    query.callback({
                        results: results.collection,
                        more: ((query.page * rowsPerPage) < results.totalRows),
                    });
                }).catch(() => {
                    this.showError(locale.get('smb.account.load.error'));
                });
            }, constants.COMBO_DEBOUNCE),
        });

        const setValue = (defaultVal.id === 'all') ? 'data' : 'val';
        this.ui.$accountSelect.comboBox(setValue, defaultVal, true);
    },

    updateComboBoxes() {
        this.ui.$transactionsFormatSelect.comboBox('val', this.model.get('accountTransactionsView'));
        this.ui.$accountSelectBy.comboBox('val', this.model.get('accountSelectBy'));
    },

    closePanelAndSetSelect() {
        const localModel = this.model;
        const panel = this.$('.collapse').get(0);
        const savedCriteriaCombo = this.$('[data-region="savedListViews"] [data-hook="selectList"]');

        if (localModel.get('viewId') !== '0' && !localModel.get('selectionChange') && !localModel.get('saved') && panel.classList.contains('collapse') && panel.classList.contains('in')) {
            localModel.set('viewId', '0');
            savedCriteriaCombo.select2('val', null);
            this.$('.btn.makeDefault').hide();
            this.$('.btn.clearDLButton').hide();
        }
    },

    clearFilterModel() {
        const filterModel = this.filterRegion.currentView.model;
        filterModel.clear();
        this.clearCombos();
        filterModel.set('AMOUNT-equality', 'EQ');
    },

    postSubmitOperations() {
        this.updateTransactionSummary();
        FilterParent.prototype.postSubmitOperations.call(this, this.model);
        this.cancelTranFilters('storeFilterModel');
    },

    updateFilters(options = {}) {
        const { updateModel } = options;
        FilterParent.prototype.updateFilters.call(this);
        const filterModel = this.filterRegion?.currentView?.model;
        if (updateModel && filterModel) {
            filterModel.set(this.model.toJSON());
        }
        /*
         * Validate the filter model here because always
         * need to validate even when checkDateRange is false
         */
        const validFilters = filterModelIsValid(this.filterRegion);
        if (this.checkDateRange() && validFilters) {
            this.closePanelAndSetSelect();
            this.$('.collapse').collapse('hide');

            this.model.trigger('update');
            this.postSubmitOperations();
        }
    },

    resetFilters() {
        this.clearFilterModel();
        this.clearTranFilters();
    },

    cancelFilters() {
        this.cancelTranFilters('clear');
        this.$('.collapse').collapse('hide');
    },

    editLink() {
        this.$('.collapse').collapse('show');
        this.updateCollapseState();
    },

    updateCollapseState(state) {
        this.$('.TransactionFilters .panel-collapse').collapse(state || 'toggle');
    },

    updateForSave() {
        const checked = this.ui.$saveReport.prop('checked');
        this.ui.$updateButton.toggle(!checked);
        this.ui.$saveButton.toggle(checked);
        this.ui.$saveForm.toggle(checked);
    },

    rejectSearchField(filter) {
        return (!filter.fieldValue[0] || ((filter.fieldName === 'DR_CR' || filter.fieldName === 'POSTED_FLAG') && filter.fieldValue[0].trim() === ''));
    },

    renderTransactionFilters() {
        this.ui.$saveButton.toggle(false);
        const self = this;
        const context = {
            productCode: 'GIR',
            functionCode: 'INST',
            typeCode: 'GIRTRANS',
        };
        const filterContextDef = util.extend({}, context);
        filterContextDef.serviceName = 'advanceFilter';
        this.filterContextDef = filterContextDef;

        const requestData = {
            filterId: 22411,
            typeInfo: context,
        };

        const promiseMe = filterApi.getFilters(requestData, self);
        promiseMe.then(() => {
            self.listenToOnce(self.filterRegion?.currentView, 'item:rendered', () => {
                const defaultListPromise = self.getDefaultListView();

                Promise.all([defaultListPromise]).then(() => {
                    self.getDefaultSuccess();
                });
            });
        });
    },

    getTransactionFilters() {
        const tranFilters = [];
        if (!this.filterRegion || !this.filterRegion.currentView) {
            return tranFilters;
        }
        const filterModel = this.filterRegion.currentView.model;
        const filterValues = filterApi.gatherFilterData(filterModel);
        if (filterValues) {
            filterValues.forEach((filter) => {
                if (this.rejectSearchField(filter)) {
                    return;
                }
                tranFilters.push(filter);
            });
        }
        return tranFilters;
    },

    getTransactionProfileFilters() {
        return filterApi.gatherFilters(this) || [];
    },

    getCacheName() {
        const retStr = `model-${this.filterContextDef.serviceName}-GIR-INST`;
        return retStr;
    },

    updateViewDates() {
        const dp = this.ui.$datepicker.data('daterangepicker');
        dp.setStartDate(this.model.get('START_DATE'));
        dp.setEndDate(this.model.get('END_DATE'));
    },

    updateModelDates() {
        dateUtil.updateModelDates(this.model, this.ui.$datepicker, true);
    },

    saveView(param) {
        const self = this;

        if (this.savedViewsView.comboBoxView.selectedModel() && !this.model.isValid()) {
            return;
        }
        if (!this.checkDateRange()) {
            return;
        }
        const name = (param && param.value) ? param.value : this.model.get('VIEWNAME');
        this.model.set('tranFilters', this.getTransactionProfileFilters());
        const data = {
            value: name,
            shared: this.model.get('SHARED'),
            filters: this.model.getViewFilters(),

            column: {
                sortColumn: 'null',
                sortDir: 'null',

                cellData: [{
                    name: 'COMBINEDVIEW',
                    field: 'COMBINEDVIEW',
                    hidden: false,
                    width: '98px',
                    displayName: '',
                }],
            },
        };
        if (this.savedViewsView.getSelected() === name) {
            this.model.set('update', true);
        } else {
            this.model.set('update', false);
        }

        self.savedListViewsRegion.currentView.createSavedView(data);
    },

    setNameFields(name) {
        this.model.set({
            VIEWNAME: name,
            id: name,
            originalName: name,
            listName: name,
            text: name,
            system: false,
        });
        this.model.id = name;
    },

    clearTranFilters() {
        this.ui.$datepicker.val('');
        this.updateModelDates();
        this.model.set({
            CUST_REF: '',
            AMOUNT: '',
            BANK_REF: '',
            POSTED_FLAG: '',
            DR_CR: '',
            BAI_CODE: '',
            BAI_GROUP_CODE: '',
            BANK_CODE: '',
            SWIFT_TYPE_CODE: '',
            CURRENCY_CODE: '',
            accountSelect: ['all'],
            accountSelectBy: 'number', // default - indicates select by account number
            accountTransactionsView: 'single',
            update: false,
        });
    },

    cancelTranFilters(param) {
        const self = this;
        const filterModel = this.filterRegion.currentView.model;
        const selector = '.type-ahead';

        if (param === 'clear') {
            filterModel.set({
                START_DATE: this.storedModelForCancel.get('START_DATE') || '',
                END_DATE: this.storedModelForCancel.get('END_DATE') || '',
                CUST_REF: this.storedModelForCancel.get('CUST_REF') || '',
                AMOUNT: this.storedModelForCancel.get('AMOUNT') || '',
                BANK_REF: this.storedModelForCancel.get('BANK_REF') || '',
                POSTED_FLAG: this.storedModelForCancel.get('POSTED_FLAG') || ' ',
                DR_CR: this.storedModelForCancel.get('DR_CR') || ' ',
                BAI_CODE: this.storedModelForCancel.get('BAI_CODE') || '',
                BAI_GROUP_CODE: this.storedModelForCancel.get('BAI_GROUP_CODE') || '',
                BANK_CODE: this.storedModelForCancel.get('BANK_CODE') || '',
                SWIFT_TYPE_CODE: this.storedModelForCancel.get('SWIFT_TYPE_CODE') || '',
                CURRENCY_CODE: this.storedModelForCancel.get('CURRENCY_CODE') || '',
            });

            this.model.set({
                accountSelect: this.storedModelForCancel.get('accountSelect') || ['all'],
                accountSelectBy: this.storedModelForCancel.get('accountSelectBy') || 'number',
                datepicker: this.storedModelForCancel.get('datepicker') || ' ',
            });
            this.filterRegion.$el.find(selector).each(function () {
                const attrName = $(this).attr('name');
                // check if set value is unique & add it as an array
                if (attrName) {
                    const currentVal = util.isArray(filterModel.get(attrName))
                        ? filterModel.get(attrName).join() : filterModel.get(attrName);
                    self.model.set(attrName, currentVal);
                }
            });

            if (this.storedModelForCancel.get('AMOUNTequality')) {
                filterModel.set('AMOUNT-equality', this.storedModelForCancel.get('AMOUNTequality'));
            } else {
                filterModel.set('AMOUNT-equality', 'EQ');
            }
            this.ui.$datepicker.val(this.storedModelForCancel.get('datepicker') || ' ');
            this.ui.$transactionsFormatSelect.comboBox('val', this.storedModelForCancel.get('accountTranView'));
            this.updateModelDates();
            this.setTypeAheadText(this.filterRegion);
        } else {
            this.storedModelForCancel.set({
                START_DATE: filterModel.get('START_DATE') || '',
                END_DATE: filterModel.get('END_DATE') || '',
                CUST_REF: filterModel.get('CUST_REF') || '',
                AMOUNT: filterModel.get('AMOUNT') || '',
                AMOUNTequality: filterModel.get('AMOUNT-equality') || '',
                BANK_REF: filterModel.get('BANK_REF') || '',
                POSTED_FLAG: filterModel.get('POSTED_FLAG') || '',
                DR_CR: filterModel.get('DR_CR') || '',
                BAI_CODE: filterModel.get('BAI_CODE') || '',
                BAI_GROUP_CODE: filterModel.get('BAI_GROUP_CODE') || '',
                BANK_CODE: filterModel.get('BANK_CODE') || '',
                SWIFT_TYPE_CODE: filterModel.get('SWIFT_TYPE_CODE') || '',
                CURRENCY_CODE: filterModel.get('CURRENCY_CODE') || '',
                accountSelect: this.model.get('accountSelect'),
                accountSelectBy: this.ui.$accountSelectBy.val() || 'number',
                datepicker: this.ui.$datepicker.val() || ' ',
                accountTranView: this.ui.$transactionsFormatSelect.val(),
            });
        }
    },

    clearCombos() {
        const self = this;
        this.filterRegion.currentView.$el.find('.form-container .form-select').each((i, el) => {
            self.$(el).select2('val', null);
        });
    },

    viewSelectionHandler(model) {
        if (this.alertRegion) {
            this.alertRegion.close();
        }

        this.clearFilterModel();
        this.clearTranFilters();
        if (model) {
            const viewId = (model) ? model.get('viewId') : '';
            this.clearCombos();
            this.setNameFields(model.id);
            this.model.set('SHARED', model.get('shared'));
            this.updateTransactionSummary();
            this.wrapper.viewId = viewId;
            this.model.set('viewId', viewId);
            this.model.set('selectionChange', true);
            this.model.fetch({
                success: () => {
                    this.listViewFilterDetailCompleted = true;
                    this.updateViewDates();
                    this.updateAccountSummary();
                    this.updateTransactionSummary();
                    this.setTypeAheadText(this.filterRegion);
                    this.updateFilters({ updateModel: true });
                },
            });
        }
    },

    handleSaveSuccess(viewId) {
        this.alertRegion.show(alert.success(
            locale.get('common.reportsaved'),
            {
                canDismiss: true,
            },
        ));
        this.model.set('viewId', viewId);
        this.model.set('saved', true);
        this.updateFilters();
        this.ui.$saveReport.prop('checked', false);
        this.ui.$reportNameForm.removeClass('has-error');
        this.updateForSave();
        const regionLoc = this.$('.alert-region').offset();
        window.scrollTo(regionLoc.left, regionLoc.top);
    },

    updateNameToSave() {
        const selected = this.savedViewsView.getSelected();
        this.model.set('VIEWNAME', selected);
        this.model.set('SHARED', this.savedViewsView.currentSavedView.get('shared'));
    },

    onShow() {
        this.ui.$datepicker.nhDatePicker({
            rangeDatePicker: true,
            allowWeekends: true,
        });

        this.$('select').comboBox({
            minimumResultsForSearch: Infinity,
        });

        this.renderTransactionFilters();
        this.setupAccountSelectFilter(this.model.get('accountSelectBy'));
    },

    getTypeAheadData(view) {
        const self = this;
        const reportType = self.model.get('accountSelectBy');
        let reportTypeField;

        if (reportType === 'number') {
            reportTypeField = 'ACCOUNTFILTER';
        } else if (reportType === 'group') {
            reportTypeField = 'ACCOUNTFILTER2';
        } else if (reportType === 'currency') {
            reportTypeField = 'CURRENCYCODE';
        } else {
            reportTypeField = 'BANK_CODE';
        }
        FilterParent.prototype.getTypeAheadData.call(this, view, reportTypeField);
    },

    setTypeAheadText(view) {
        const self = this;
        const selector = '.type-ahead';
        view.$el.find(selector).each(function (i, el) {
            const $el = self.$(el);
            const attrName = $(this).attr('name');
            let typeAheadFields = [];
            if (attrName) {
                typeAheadFields = self.getTypeAheadFields(attrName, `${attrName}_DATA`);
                $el.comboBox('data', typeAheadFields);
            }
        });
    },

    /**
     * @method onSaveReportError
     * Show error on saving a report
     * cause: A system report with the same name already exists
     */
    onSaveReportError() {
        // Highlight region with report name field
        this.ui.$reportNameForm.addClass('has-error');
        return this.showError(locale.get('GIR.SavedReportNameAlreadyExists'));
    },

    showError(msg) {
        const alertView = alert.negative(
            msg,
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(alertView);
        scrollUtil.scrollToRegion(this.alertRegion.$el[0], true);
    },
});
