import configParam from 'system/webseries/models/configurationParameters';
import appConfig from 'system/webseries/models/applicationConfiguration';
import darkDeployModel from 'system/webseries/models/darkDeploy';

const positiveValues = [
    '1',
    'true',
    'TRUE',
    true,
    1,
];

const negativeValues = [
    '0',
    'false',
    'FALSE',
    false,
    0,
];

const makeBoolean = (val) => {
    if (positiveValues.includes(val)) {
        return true;
    }
    if (negativeValues.includes(val)) {
        return false;
    }
    // provide undefined as the "third" option.
    return undefined;
};

/**
 * Dark Deploy elements
 */

const getDarkDeployKey = key => darkDeployModel.get(key);
const getDarkDeployBoolean = key => makeBoolean(getDarkDeployKey(key));

const darkExists = key => getDarkDeployKey(key) !== undefined;
const isDark = key => getDarkDeployBoolean(key) === false;
const isLive = key => getDarkDeployBoolean(key) === true;

/**
 * Config from configParam.
 */
const getConfigKey = key => configParam.get(key);
const getConfigBoolean = key => makeBoolean(getConfigKey(key));
const configExists = key => getConfigKey(key) !== undefined;
const configActive = key => getConfigBoolean(key) === true;
const configInactive = key => getConfigBoolean(key) === false;

/**
 * Config from appConfig.
 */
const getAppConfigKey = (...args) => appConfig.getValue(...args);
const getAppBoolean = (...args) => makeBoolean(getAppConfigKey(...args));
const appExists = (...args) => getAppConfigKey(...args) !== undefined;
const appActive = (...args) => getAppBoolean(...args) === true;
const appInactive = (...args) => getAppBoolean(...args) === false;

const dark = {
    get: getDarkDeployKey,
    exists: darkExists,
    isDark,
    isLive,
};

const config = {
    get: getConfigKey,
    exists: configExists,
    isActive: configActive,
    isInactive: configInactive,
    isDisabled: configInactive,
    isEnabled: configActive,
};
const app = {
    get: getAppConfigKey,
    exists: appExists,
    isActive: appActive,
    isInactive: appInactive,
    isDisabled: appInactive,
    isEnabled: appActive,
};

export {
    dark,
    config,
    app,
};
