import PanelLayout from './validationPanel';
import businessInfoTmpl from './businessInfoView.hbs';

const BusinessInfoView = PanelLayout.extend({
    template: businessInfoTmpl,
    tagName: 'fieldset',
    rtpSelected: false,
    rendered: false,
});

export default BusinessInfoView;
