import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import systemConfig from 'system/configuration';
import UserCompanyModal from 'app/administration/views/userMaintenance/userCompanyModal';
import UserGroupCollection from 'app/administration/collection/userGroups';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import Glu from '@glu/core/src/glu';
import Model from '@glu/core/src/model';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './panelApprovalList.hbs';

const panelApprovalList = ListView.extend({

    events: util.extend({}, ListView.prototype.events, {
        'click [data-hook="getAddPanelWorkflow"]': 'addPanelWorkflow',
        'click [data-hook="getManageApprovers"]': 'manageApprovers',
        'click [data-hook="print-button"]': 'showPrintOptionsModal',
    }),

    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMINSTRATION',
            serviceName: 'panelApprovalWorkflow',
            serviceFunc: null,
            businessType: null,
            context: systemConfig.isAdmin() ? 'CMAINT_PNLPR_TM' : '_ADMIN_PNLPR_TM',
            returnRoute: 'ADMINSTRATION/viewPanelApprovalWorkflowList',
            selector: 'rowSelector',
        };

        store.set('panelApprovalListReturnRoute', Glu.Backbone.history.getFragment());

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * Navigate to add new planel workflow route
     */
    addPanelWorkflow() {
        if (systemConfig.isAdmin()) {
            this.showCompanySelectModal('/PANELAPPROVAL/modify');
        } else {
            this.navigateTo('/PANELAPPROVAL/modify');
        }
        return Promise.resolve();
    },

    showCompanySelectModal(route) {
        const userGroups = new UserGroupCollection({
            isAdmin: true,
        });

        userGroups.fetch({
            success: (records) => {
                /*
                 * If the collection only returns 1 item, don't show the modal
                 * & just set the usergroup based on that one item
                 */
                if (userGroups.length === 1) {
                    this.continueAddUser(route, records.first());
                } else {
                    /*
                     * Open modal for the admin user to select the company
                     * first before inserting
                     */
                    const companyModal = new UserCompanyModal({
                        state: 'modal',
                        collection: userGroups,
                        pageTitle: locale.get('PS.UserGroup'),
                        returnRoute: 'ADMINSTRATION/viewUsers',
                    });
                    dialog.open(companyModal);

                    // only the modal view needs to listen to the success message
                    this.listenTo(companyModal, 'selectUserCompany:success', this.continueAddUser.bind(this, route));
                }
            },
        });
    },

    continueAddUser(route, selectedCompany) {
        store.set(`${this.contextKey}-actionModel`, new Model({
            PANELPROFILECODE: null,
            USERGROUP: selectedCompany.id,
        }));
        this.navigateTo(route);
    },

    /**
     * Navigate to approver management route
     */
    manageApprovers() {
        if (systemConfig.isAdmin()) {
            this.showCompanySelectModal('/PANELAPPROVAL/manageApprovers');
        } else {
            this.navigateTo('/PANELAPPROVAL/manageApprovers');
        }
        return Promise.resolve();
    },

    /**
     * Handle view action
     * @param  {object} item Model associated with item choosen for modification in view
     */
    gridRowSelect(item) {
        store.set(`${this.contextKey}-actionModel`, item.model);
        this.navigateTo('/PANELAPPROVAL/view');
        return Promise.resolve();
    },

    /**
     * Handle modify action
     * @param  {object} item Model associated with item choosen for modification in view
     */
    gridRowModify(item) {
        store.set(`${this.contextKey}-actionModel`, item.model);
        this.navigateTo('/PANELAPPROVAL/modify');
        return Promise.resolve();
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_17019,
            hasSummarySelection: false,
        };
    },
});

let list = panelApprovalList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'PS.panelApproval.Add.Approval.Workflow',
                entitlement: 'INSERT',
                handlerMethodName: 'addPanelWorkflow',
            },
            {
                label: 'PS.panelApproval.Manage.Approvers',
                entitlement: 'SELECT',
                handlerMethodName: 'manageApprovers',
            },
        ],
        bulkActions: [
            {
                label: 'action.approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'action.delete',
                entitlement: 'DELETE',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'PANEL_APPROVAL_WORKFLOWS',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
