/*
 * Locale keys used throughout Command and Control
 */
export default {
    allFeatures: 'listview.features.all',
    approve: 'common.approve',
    approvePretext: [
        'features.confirm.approve.info1',
        'features.confirm.approve.info2',
    ],
    cancel: 'button.cancel',
    close: 'button.close',
    confirmChangesTitle: 'features.confirm.submit.title',
    currentState: 'features.current_state',
    features: 'features.title',
    listAfterRequestPretext: 'features.pretext.request.after',
    listBeforeRequestPretext: 'features.pretext.request.before',
    listForApproverPretext: 'features.pretext.for_approver',
    listForRequestorPretext: 'features.pretext.for_requestor',
    reject: 'common.reject',
    rejectionReason: 'common.rejection.reason',
    rejectRequest: 'featureControl.button_reject',
    release: 'features.release',
    requestPretext: 'features.confirm.submit.info',
    requestsPending: 'features.tab.requests_pending',
    requestState: 'features.request_state',
    reset: 'common.reset',
    submit: 'button.submit',
    submitForApproval: 'featureControl.button_modify',
};
