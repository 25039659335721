import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import { appBus } from '@glu/core';
import userInfo from 'etc/userInfo';
import GridApi from 'common/dynamicPages/api/grid';
import contextApi from 'common/dynamicPages/api/context';
import UserGroupModel from 'app/setup/models/userGroup';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './manageAccounts.hbs';
import loadingTemplate from './loadingModalCustom.hbs';

const ManageAccountsList = Layout.extend({
    initialize(options) {
        this.userGroup = options.userGroup;
        this.action = options.action;
        this.dialogTitle = options.title;
        this.dialogButtons = [];
        this.dialogButtons.push({
            text: locale.get('setup.accounts.add.btn'),
            className: 'btn btn-primary',
            callback: 'addAccounts',
        });
        this.dialogButtons.push({
            text: locale.get('setup.cancel.btn'),
            className: 'btn btn-primary',
            callback: 'cancel',
        });
        this.loadRequiredData();
    },

    template,
    loadingTemplate,
    modalClass: 'modal-xlg',

    regions: {
        accountsRegion: '#accountsRegion',
    },

    templateHelpers() {
        const self = this;

        return {
            action: self.action,
            userGroup: self.userGroup,
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.loadGrid();
        }
    },

    onClose() {
        if (this.gridView && !mobileUtil.isMobileGridEnabled()) {
            this.gridView.close();
        }
    },

    loadRequiredData() {
        this.context = contextApi.menuContext.getContext('ACCOUNT_FILTER');
        const self = this;

        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: '*',
                        productCode: '_ADMIN',
                        functionCode: 'INQ',
                        actionMode: 'SELECT',
                    },

                    customFilters: [{
                        filterName: 'Depends',

                        filterParam: [
                            'ParentUserGroup',
                            userInfo.get('group'),
                        ],
                    }, {
                        filterName: 'Depends',

                        filterParam: [
                            'UserGroup',
                            this.userGroup,
                        ],
                    }],

                    callingAction: {
                        typeCode: 'ACCTACCS',
                        entryMethod: 0,
                        productCode: '_ADMIN',
                        functionCode: 'ACCTACCS',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 8003,
                },
            },
        };

        const opts = {
            context: this.context,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
        };

        this.gridView = GridApi.createInquiryGridView(opts);
        this.gridView.on('gridapi:loaded', () => {

        });
        self.setHasLoadedRequiredData(true);
        if (!mobileUtil.isMobileGridEnabled()) {
            self.render();
        }
    },

    loadGrid() {
        this.accountsRegion.show(this.gridView);
    },

    groupObject(model) {
        return `ACCOUNTNUM=${model.get('AccountNum')}&BANKCODE=${model.get('BankCode')}|`;
    },

    addAccounts() {
        const selectedRows = !mobileUtil.isMobileGridEnabled()
            ? []
            : this.gridView.grid.getSelectedRows();
        const self = this;
        this.gridView.$el.find('.column-row-selector:checked').each(function () {
            selectedRows.push({
                cid: this.getAttribute('data-model-cid'),
            });
        });

        if (selectedRows.length > 0) {
            let keyValues = '';

            for (let i = 0; i < selectedRows.length; i += 1) {
                const model = !mobileUtil.isMobileGridEnabled()
                    ? self.gridView.grid.gridCollection.get(selectedRows[i])
                    : this.gridView.grid.getSelectedRowModels()[i];
                keyValues += this.groupObject(model);
            }

            const userGroups = new UserGroupModel({
                keyValues,
                parentUserGroup: userInfo.get('group'),
                childUserGroup: this.userGroup,
                action: 'assign',
            });

            userGroups.save(
                null,
                {
                    success() {
                        appBus.trigger('bankAcctFilters:userGroup:add', self.userGroup);
                    },
                },
            );
        }

        dialog.close();
    },

    cancel() {
        dialog.close();
    },
});

let list = ManageAccountsList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, { showSelector: true });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
