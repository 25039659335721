import constants from 'app/smbPayments/constants';
import locale from '@glu/locale';
import InquiryApi from 'system/webseries/api/inquiry';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import { maskValue } from 'common/util/maskingUtil';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import balanceAndTransConstants from 'app/balanceAndTransaction/constants';

export default {
    isDeposit(accountType) {
        return accountType === 'DD' || accountType === 'SV' || accountType === 'GL';
    },

    isLoanPayment(accountType) {
        return accountType === 'CL';
    },

    isLoanDraw(accountType) {
        return accountType === 'LC';
    },

    /**
     * @method isCreditCardAccount
     * @param {string} accountType
     * checks if an account is a credit card account or not
     * @returns {boolean}
     */
    isCreditCardAccount(accountType) {
        return accountType === 'CC';
    },

    isLoan(accountType) {
        return this.isLoanPayment(accountType) || this.isLoanDraw(accountType);
    },

    /**
     * @method isTOA
     * @param {string} accountSubType
     * checks if an account is a time open account or not
     * @returns {boolean}
     */
    isTOA(accountSubType) {
        return accountSubType === 'TO';
    },

    labelFormat(data) {
        return `${data.label}${data.accountBalance
            ? ` - ${data.accountBalance} ${locale.get('smbPayments.available')}` : ''}`;
    },
    labelFormatACH(data) {
        const accountNum = util.findWhere(
            data.mapDataList,
            {
                toField: 'OFFSETACCOUNTNUM',
            },
        );
        const compName = util.unescape(util.findWhere(
            data.mapDataList,
            {
                toField: 'ORIGCOMPNAME',
            },
        ).value);
        return (accountNum && accountNum.value !== '' ? `${maskValue(accountNum.value)} / ` : '')
                + compName + (data.accountBalance ? ` - ${data.accountBalance} ${locale.get('smbPayments.available')}` : '');
    },
    /**
     * Deter whether or not to show transfer buttons/links
     * @param {Object} model
     * @returns {Boolean}
     * NH-161972 According to P.O., there should not be anything
     * different for Credit Card so added same behavior for Credit Card
     * accounts and eliminated the condition for CC
     *
     */
    showTransfer(model) {
        return !!model.get('isTransferLinkEntitled');
    },

    /**
     * Get the options for fetching the from account data based on the current
     * attributes of the model
     * @param {Object} model
     * @returns {Object|undefined} - options for fetching the account data
     */
    getFromAccountOpts(model) {
        const paymentType = model.get('PAYMENTTYPE') || model.get('TYPE');
        const productCode = model.get('PAYMENTPRODUCTCODE');
        const functionCode = model.get('PAYMENTFUNCTIONCODE');
        const subType = model.get('PAYMENTCLEARINGSYSTEM');

        if (paymentType === constants.FEDWIRE) {
            return {
                inquiryId: InquiryApi.DOMESTIC_WIRE_FROM_ACCOUNT_INQUIRY_ID,
                typeCode: paymentType,
                productCode,
                functionCode,

                labelFormat: this.labelFormat,
            };
        }
        if (paymentType === constants.CRTRAN) {
            return {
                inquiryId: InquiryApi.REAL_TIME_PAYMENT_FROM_ACCOUNT_INQUIRY_ID,
                typeCode: paymentType,
                productCode,
                functionCode,

                labelFormat: this.labelFormat,
            };
        }
        if (paymentType === constants.INTL) {
            return {
                inquiryId: InquiryApi.INTL_WIRE_FROM_ACCOUNT_INQUIRY_ID,
                typeCode: paymentType,
                productCode,
                functionCode,

                labelFormat: this.labelFormat,

                currencyCode: 'DEBIT_CURRENCY',
            };
        }
        if (constants.isACH(paymentType)) {
            return {
                inquiryId: InquiryApi.ACH_PAYMENT_TYPES_ACCOUNT_INQUIRY_ID,
                includeMapData: '1',
                typeCode: paymentType,
                productCode,
                functionCode,
                actionMode: 'INSERT',
                queryField: 'acctCompany',

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        '_typeCode',
                        paymentType,
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        '_subTypeCode',
                        subType,
                    ],
                }, {
                    filterName: 'Depends',

                    filterParam: [
                        '__LOCALE_',
                        userInfo.getLocale(),
                    ],
                }],

                subTypeCode: subType,
                fieldName: 'CLIENTCOMP_NAME',
                viewId: '1001',
                bankCode: 'OFFSETBANKCODE',
                currencyCode: 'ORIGCURRENCYCODE',

                labelFormat: this.labelFormatACH,
            };
        }
        return undefined;
    },
    getAvailableBalanceField(accountType) {
        let field;
        if (this.isCreditCardAccount(accountType)) {
            field = applicationConfigParams.getValue('OFXEXP', 'SMB_BALANCE_TYPE_CC');
        } else if (this.isDeposit(accountType)) {
            field = applicationConfigParams.getValue('OFXEXP', 'SMB_BALANCE_TYPE');
        } else if (this.isLoan(accountType)) {
            field = applicationConfigParams.getValue('OFXEXP', 'SMB_BALANCE_TYPE_LOAN');
            field = util.isEmpty(field) ? 'OUTSTANDINGBALANCE' : field;
        }
        if (util.isEmpty(field)) {
            field = 'CURRENTDAYAVAILABLE';
        }
        return field;
    },

    getViewDetailRoute(accountType, isTieredLoan) {
        if (this.isLoan(accountType)) {
            if (isTieredLoan) {
                return balanceAndTransConstants.TRANSACTION_TIERED_LOAN_ACCOUNT_DETAIL;
            }
            return balanceAndTransConstants.TRANSACTION_LOAN_ACCOUNT_DETAIL;
        }
        if (this.isCreditCardAccount(accountType)) {
            return balanceAndTransConstants.TRANSACTION_CREDIT_CARD_DETAIL;
        }
        return balanceAndTransConstants.TRANSACTION_DEPOSIT_DETAIL;
    },
};
