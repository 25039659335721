import util from '@glu/core/src/util';
import { setAppConfigs } from 'common/util/reduxUtil';
import InquiryModel from './inquiry';

const ApplicationConfiguration = InquiryModel.extend({
    /**
     * This is a model for storing values from the ApplicationConfig table.
     * The ApplicationConfig table is used to store general application configuration
     * parameters for the application similar to ConfigParameters but enhanced
     * with additional
     * meta data such as category, description, etc and can be modified live from the
     * admin application and take effect immediately (no server restart required).
     *
     * @constructs
     */
    initialize() {
        this.searchType = 5;
        this.inquiryId = 9039;
        this.searchOrOperator = false;
    },

    parse(data) {
        const self = this;
        const newData = [];
        util.each(data.inquiryResponse.rows, (row) => {
            const key = self.parseValue(row.columns, 'key');
            const categoryKey = self.parseValue(row.columns, 'categorykey');
            const value = self.parseValue(row.columns, 'value');
            newData[`${categoryKey}_${key}`] = value;
        });
        setAppConfigs(newData);
        return newData;
    },

    /**
     * Gets the value stored in the model based on the category key and key combination
     */
    getValue(categoryKey, key) {
        return this.get(`${categoryKey}_${key}`);
    },
});

export default new ApplicationConfiguration();
