import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import services from 'services';
import serviceUtil from 'common/util/services';
import constants from '../constants';
import contentViewTmpl from './contentView.hbs';

const ContentView = ItemView.extend({
    template: contentViewTmpl,
    className: 'ContentView',

    events: {
        'change [data-hook="getExportFormat"]': 'export',
    },

    initialize(options) {
        this.model = options.model;
        this.template = options.template;
        this.disposition = options.disposition;
        this.exportFormat = options.exportFormat;
        this.target = options.target;
        this.type = options.type;
        this.reportId = options.reportId;
        this.formId = `getReport${options.disposition}${options.reportId}${options.exportFormat}`;
    },

    onClose() {
        this.$('iframe[name="pdfContent"]').attr('src', 'about:blank');
        this.$('iframe[name="pdfContent"]').remove();
    },

    templateHelpers() {
        return {
            exportFormats: this.getExportFormats(),
            type: `application/${this.exportFormat}`,
            url: this.generateDownloadUrl(),
        };
    },

    /**
     * Get a list of valid export formats
     * @returns {Array} - list of export formats
     */
    getExportFormats() {
        const formats = [{
            value: constants.EXPORT_FORMAT_PDF,
            label: locale.get('reports.export.as.pdf'),
        }, {
            value: constants.EXPORT_FORMAT_CSV,
            label: locale.get('reports.export.as.csv'),
        }, {
            value: constants.EXPORT_FORMAT_TXT,
            label: locale.get('reports.export.as.txt'),
        }];
        return formats.filter(this.isDisabledFormat.bind(this));
    },

    /**
     * Check is this format is in the list of disabled formats
     * @param {Object} format
     * @returns {boolean}
     */
    isDisabledFormat(format) {
        return !util.contains(this.options.disabledFormats, format.value);
    },

    /**
     * Generate the downloadUrl based on the params or use the defaults
     * @param {Object} params -
     * @returns {string} - download url with params
     */
    generateDownloadUrl(params) {
        const data = params || {
            format: this.exportFormat,
            disposition: this.disposition,
            type: this.type,
            filledReportId: this.reportId,
        };

        return `${services.downloadReport}?${serviceUtil.generateUrlParams(data)}`;
    },

    /**
     * When target has a value, open the downloadUrl in the target iframe
     * @param {Event} e - change event object
     */
    export(e) {
        const data = {
            disposition: 'export',
            filledReportId: this.reportId,
            format: e.target.value,
            type: this.type,
        };

        if (util.isEmpty(e.target.value)) {
            return;
        }

        window.open(this.generateDownloadUrl(data), this.target);
    },
});
export default ContentView;
