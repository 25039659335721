export default function (options, context) {
  // Default the iterator context to `this` if not provided
  const iteratorContext = context || this;

  // Get options
  const opts = Object.assign({}, options);

  const {
    iterator, limit, onLimit, onStop, immediateStart
  } = opts;
  const interval = opts.interval || 5000;

  // The callback the user invokes to stop polling
  const stop = () => {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    if (typeof onStop === 'function') {
      onStop();
    }
  };

  // The callback the user invokes to poll again
  const poll = timeout => {
    // If poll count limit is reach, then either stop silently, or invoke `onLimit` if provided.
    if (limit && this.count >= limit) {
      return (typeof onLimit === 'function') ? onLimit() : undefined;
    }

    // Queue up the next iteration
    this.timeout = window.setTimeout(() => {
      this.count += 1;
      iterator.call(iteratorContext, poll, stop);
    }, (timeout || interval));

    return undefined;
  };

  if (immediateStart) {
    // Set up a poll counter, so that we can check if we've hit our limit
    this.count = 1;
    iterator.call(iteratorContext, poll, stop);
  }

  let self = this;

  return {
    // Starts or re-starts polling, always reseting the poll count back to 1.
    start() {
      // Set up a poll counter, so that we can check if we've hit our limit
      self.count = 1;
      iterator.call(iteratorContext, poll, stop);
    },

    // Alias of start
    restart() {
      // stop wipes the self pointer, repoint it to this
      self = this;
      this.start();
    },

    // Re-starts polling. The poll count remains as it was when the poll was paused.
    resume() {
      if (!self.count) {
        throw new Error('You have not yet started this poll.');
      }
      iterator.call(iteratorContext, poll, stop);
    },

    pause() {
      if (self && self.timeout) {
        window.clearTimeout(self.timeout);
      }
    },

    dispose() {
      stop();
    }
  };
}
