import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import Entitlements from 'app/administration/collection/user2/entitlements';
import EntitlementCollectionView from './entitlementCollectionView';
import permissionsGroupViewTmpl from './permissionsGroupView.hbs';
import { updateSelectAllState, onChildrenChange } from './helpers';

export default Layout.extend({
    template: permissionsGroupViewTmpl,
    // TODO Updaet to generic style class
    className: 'risk-group',

    ui: {
        $selectAll: '[data-hook="selectAllGroupPermissions"]',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPerms',
    },

    initialize(opts) {
        // TODO Change this once server side is fixed for generric permissions?
        this.entitlements = new Entitlements(this.model.get('types').map((type) => {
            const entitlement = type.get('entitlements').at(0);
            entitlement.label = locale.get(type.get('label'));
            return entitlement;
        }));

        if (opts.mode === constants.MODES.VIEW) {
            this.entitlements.reset(this.entitlements.filter(type => type.isEntitled()));
        }
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.entitlementsCollectionView = new EntitlementCollectionView({
            collection: this.entitlements,
            mode: this.options.mode,
            onChildrenChange: () => {
                this.updateSelectAllState();
                this.options.onChildrenChange?.();
            },
        });

        this.permissionsRegion.show(this.entitlementsCollectionView);
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.permissionsRegion?.$el?.find('input[type="checkbox"]');
    },

    selectAllPerms(evt) {
        this.entitlements.selectAll(evt.target.checked);
        this.entitlementsCollectionView.render();
        this.onChildrenChange();
    },

    templateHelpers() {
        const self = this;
        return {
            cid: this.model.cid,
            readOnly: self.options.mode === constants.MODES.VIEW,
            getLabel: locale.get(`uce.${this.model.get('label')}`),
        };
    },
});
