import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';

import OnGoingHeader from '../RtpOnGoingHeader/RtpOnGoingHeader';
import MessageDetail from '../RtpMessageDetail/RtpMessageDetail';
import PairedResponseMessage from '../RtpPairedResponseMessage/RtpPairedResponseMessage';

import styles from '../RtpOnGoingConversation/RtpOnGoingConversation.styles';

const propTypes = {
    itemDetail: PropTypes.shape({
        CONVITEM_ID: PropTypes.string,
        ITEM_TYPE: PropTypes.string,
        REQUESTFORINFO_REASON_TYPE: PropTypes.string,
        REQUESTFORINFO_REASON: PropTypes.string,
        ADDITIONAL_INFO: PropTypes.string,
        USERID: PropTypes.string,
        TIMESTAMP: PropTypes.string,
        NOT_DELIVERED: PropTypes.boolean,
        SEQUENCE_NUM: PropTypes.string,
    }).isRequired,
    updateResponse: PropTypes.func.isRequired,
    currentTab: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const MessageItem = ({
    itemDetail, currentTab, classes, updateResponse,
}) => {
    const convItemClassName = (currentTab === 'submitted') ? `conversationItem ${classes.submitted}`
        : `conversationItem ${classes.received}`;
    const notDelivered = itemDetail.ITEM_TYPE === 'RESPONSE' && itemDetail.NOT_DELIVERED;
    const itemKey = `${itemDetail.CONVITEM_ID}.${itemDetail.SEQUENCE_NUM}`;
    return (
        <div key={itemDetail.CONVITEM_ID} className={convItemClassName}>
            <>
                <OnGoingHeader
                    messageType={itemDetail.ITEM_TYPE}
                    rfiType={itemDetail.REQUESTFORINFO_REASON_TYPE}
                    rfiReason={itemDetail.REQUESTFORINFO_REASON}
                />
                {!notDelivered
                    && (
                        <MessageDetail
                            currentTab={currentTab}
                            itemType={itemDetail.ITEM_TYPE}
                            additionalInfo={itemDetail.ADDITIONAL_INFO}
                            userId={itemDetail.USERID}
                            timestamp={itemDetail.TIMESTAMP}
                        />
                    )}
                {notDelivered
                    && (
                        <PairedResponseMessage
                            data={itemDetail}
                            key={itemKey}
                            updateResponse={updateResponse}
                            errorIndicator={notDelivered}
                        />
                    )}
            </>
        </div>
    );
};

MessageItem.propTypes = propTypes;

export default withStyles(styles)(MessageItem);
