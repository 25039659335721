import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import workspaceHelper from 'common/workspaces/api/helper';
import { PaymentNextIcon } from '@glu/icons-react';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import rtpSimpleModel from 'app/payments/models/realTimePayments/rtpSimpleEntryModel';
import QuickTransferWidgetView from './quickTransferWidgetView';
import SuccessView from './simpleEntryWidgetSuccessView';
import RtpTransferWidgetView from './realTimePayments/rtpWidgetView';
import quickTransferService from '../services/quickTransferWidget';
import template from './simpleEntryWidget.hbs';

const SimpleEntryWidget = Layout.extend({
    className: 'TransferWidget',
    template,

    regions: {
        transferRegion: '[data-region="transferRegion"]',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $transferRegion: '[data-region="transferRegion"]',
    },

    events: {
        'click @ui.$navLinks': 'switchTab',
    },

    initialize() {
        this.hasTransfer = true;
        this.hasRTP = true;
        this.listenTo(this.appBus, 'rtp:quickPaymentSuccess', this.handleSubmitSuccess);
        this.listenTo(this.appBus, 'rtp:handleConfirmationDone', this.handleConfirmationDone);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            this.currentTabText = locale.get('payment.simple_entry_widget.transfer');
            setTabButtonText(this, this.currentTabText);
            if (this.hasTransfer) {
                this.transferRegion.show(new QuickTransferWidgetView());
            } else if (this.hasRTP) {
                this.transferRegion.show(new RtpTransferWidgetView());
            }
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const transferOptions = this.getTransferEntitlementOptions();
        const rtpOptions = this.getRTPEntitlementOptions();

        // based on entitlements set hasTransfer and hasRTP
        const transferPromise = quickTransferService.getEntitlements({
            transferOptions,
        });
        const rtpPromise = rtpSimpleModel.getEntitlements({
            rtpOptions,
        });

        Promise.all([transferPromise, rtpPromise]).then((results) => {
            this.hasTransfer = results[0].model.isEntitled('insert');
            this.hasRTP = results[1].model.isEntitled('insert');
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    /**
     *
     * @returns {object} transfer entitlement request object
     */
    getTransferEntitlementOptions() {
        return {
            context: {
                serviceName: '/payment/transfer',

                typeInfo: {
                    productCode: 'RTGS',
                    functionCode: 'INST',
                    typeCode: 'TRANSFER',
                },
            },
            state: 'insert',
        };
    },

    /**
     *
     * @returns {object} rtp entitlement request object
     */
    getRTPEntitlementOptions() {
        return {
            context: {
                serviceName: '/payment/crtran',
                typeInfo: {
                    productCode: 'RTP',
                    functionCode: 'INST',
                    typeCode: 'CRTRAN',
                },
            },
            state: 'insert',
        };
    },

    createView(viewType) {
        let view;

        if (viewType === 'transfer') {
            view = new QuickTransferWidgetView();
        } else {
            view = new RtpTransferWidgetView();
        }
        return view;
    },

    handleSubmitSuccess(model, response) {
        const options = {
            isTransfer: model.get('ENTRY_TYPE') === 'transfer',
            model,
            response,
        };
        // create view for success page
        this.successRegion.show(new SuccessView(options));
        this.ui.$navTabs.addClass('hidden');
        this.ui.$transferRegion.addClass('hidden');
    },
    handleConfirmationDone() {
        this.ui.$navTabs.removeClass('hidden');
        this.ui.$transferRegion.removeClass('hidden');
    },

    /**
     * Switch tabs
     * @param {object} e - the mouse event
     */
    switchTab(e) {
        e.preventDefault();
        const $el = this.$(e.currentTarget);
        // active tab has been clicked so no need to proceed
        if ($el.parent('li').hasClass('is-active')) {
            return;
        }
        $el.parent('li').addClass('is-active').siblings()
            .removeClass('is-active');

        // get active tab id
        const tabId = $el.prop('id');
        const view = this.createView(tabId);
        this.transferRegion.show(view);
        this.currentTabText = $el.text();
        toggleTabs(this);
    },

    templateHelpers() {
        return {
            hasTransfer: this.hasTransfer,
            hasRTP: this.hasRTP,
        };
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'QUICK_TRANSFER',
    view: SimpleEntryWidget,
    options: {},
    useMobileCard: true,
    mobileCardProps: {
        icon: PaymentNextIcon,
    },
});

export default SimpleEntryWidget;
