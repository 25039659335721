var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-md-6\">\n        <div class=\"field-container baiTypes\">\n            <label class=\"required\" for=\"BAITYPE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"type.BAIEXP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":50},"end":{"line":4,"column":74}}}))
    + "</label>\n            <input type=\"hidden\" class=\"form-control\" data-hook=\"getCurrentPriorDropdown\" name=\"BAITYPE\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"BAITYPE\"></span>\n        </div>\n    </div>\n    <div class=\"col-md-6\">\n        <div class=\"field-container hide date-content required\" data-hook=\"getExportConditionalField\">\n            <label for=\"exportBAIDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":60}}}))
    + "</label>\n            <div class=\"search-input-wrapper\">\n                <label for=\"exportBAIDate\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.searchValue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":59},"end":{"line":13,"column":90}}}))
    + "</label>\n                <input class=\"form-control input-date\"\n                    id=\"exportBAIDate\"\n                    type=\"text\"\n                    data-hook=\"getDateInput\"\n                    data-bind=\"model\"\n                    name=\"DATE\"\n                    value=\"\"\n                    aria-required=\"true\"\n                                    required >\n                </input>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DATE\"></span>\n            </div>\n        </div>\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-md-6\">\n        <div class=\"field-container output-content\">\n            <label class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.ExportType.OutputContent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":32,"column":77}}}))
    + "</label>\n            <input type=\"hidden\" class=\"form-control\" data-hook=\"getOutputContentDropdown\" name=\"OUTPUTCONTENT\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"OUTPUTCONTENT\"></span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});