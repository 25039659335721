import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import configParam from 'system/webseries/models/configurationParameters';
import Entitlement from '../../models/user2/entitlement';

export default Collection.extend({
    model: Entitlement,

    selectAll(flag) {
        // Determine if anything can be changed
        let anyEnabled = false;

        this.each((model) => {
            const myModel = model;

            util.each(myModel.get('actions'), (obj, key) => {
                const myObj = obj;

                if (myObj && myObj.applicable && this.getEnabledState(myModel, 'actions', key)) {
                    myObj.entitled = flag;
                    anyEnabled = true;
                }
            });
            util.each(myModel.get('restrictions'), (obj, key) => {
                const myObj = obj;

                if (myObj && myObj.applicable && this.getEnabledState(model, 'restrictions', key)) {
                    myObj.entitled = flag;
                    myObj.value = flag;
                    anyEnabled = true;
                }
            });
            // handle signatures
            if (myModel.get('restrictions.signature') && myModel.get('restrictions.signature').applicable) {
                myModel.get('restrictions.signature').value = flag ? 1 : 0;
            }
        });
        return !anyEnabled;
    },

    getEnabledState(model, type, key) {
        if (model.get('typeCode') !== '_USER') {
            return true;
        }

        const config = {
            actionViewDisabled: configParam.get('_USER.actions.view.disabled') === 'true',
            actionManageDisabled: configParam.get('_USER.actions.manage.disabled') === 'true',
            actionApproveDisabled: configParam.get('_USER.actions.approve.disabled') === 'true',
            actionUpdateSettingDisabled: configParam.get('_USER.actions.updset.disabled') === 'true',
            restrictApprOwnDisabled: configParam.get('_USER.restrictions.approveown.disabled') === 'true',
            restrictApprAutoDisabled: configParam.get('_USER.restrictions.autoapprove.disabled') === 'true',
        };

        const disabled = {
            'actions-view': config.actionViewDisabled,
            'actions-manage': config.actionViewDisabled || config.actionManageDisabled,
            'actions-approve': config.actionViewDisabled || config.actionApproveDisabled,
            'actions-updset': config.actionViewDisabled || config.actionUpdateSettingDisabled,
            'restrictions-approveown': config.actionApproveDisabled || config.restrictApprOwnDisabled,
            'restrictions-autoapprove': config.actionApproveDisabled || config.restrictApprAutoDisabled,
        };

        return !disabled[`${type}-${key}`];
    },
});
