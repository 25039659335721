import React, { useRef, useState } from 'react';
import { CaretDownNextIcon, DotDotDotNextIcon } from '@glu/icons-react';
import locale from '@glu/locale';
import { withStyles, withTheme } from '@glu/theming';
import PropTypes from 'prop-types';
import Popover from '../Popover/Popover';
import styles from './Actions.styles';
import Menu from './Menu';
import actionsHelper from './actions_helper';

export const ELLIPSIS = 'ellipsis';
export const FIRST_ACTION = 'first';

const Actions = (props) => {
  const {
    classes,
    colDef: columnDefinition,
    gridId,
    gridWrapperRef,
    rowIndex,
    type
  } = props;
  const { actions } = columnDefinition;
  const actionsRef = useRef(null);
  const [open, setOpen] = useState(false);

  const visibleActions = actions.reduce((result, action) => {
    if (action.condition !== undefined) {
      if (
        typeof action.condition === 'function'
          ? action.condition(props)
          : action.condition
      ) {
        result.push(action);
      }
    } else {
      result.push(action);
    }
    return result;
  }, []);

  const firstAction = visibleActions[0] || {};
  const restActions = visibleActions.slice(1, visibleActions.length);
  const { href, onClick, variant } = actionsHelper.getProps(firstAction, props);
  const Action = actionsHelper.getComponent(firstAction);

  return (
    <div
      className={classes.wrapper}
      ref={actionsRef}
      data-qa="action-menu-wrapper"
    >
      {type === FIRST_ACTION && (
        <Action
          key={firstAction.label}
          className={classes.actionButton}
          type="button"
          data-qa={`${gridId}-${firstAction.label}-${rowIndex}`}
          href={href}
          variant={variant}
          onClick={onClick}
        >
          {firstAction.label}
        </Action>
      )}

      {(restActions.length || type === ELLIPSIS) && (
        <Popover
          leftOffset={-32}
          topOffset={0}
          gridWrapperRef={gridWrapperRef}
          minWidth={100}
          open={open}
          setOpen={setOpen}
          content={(
            <Menu
              setOpen={setOpen}
              actionsRef={actionsRef.current}
              gridId={gridId}
              restActions={type === FIRST_ACTION ? restActions : visibleActions}
              rowIndex={rowIndex}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        >
          <div className={classes.buttonWrapper}>
            <div
              className={classes.button}
              data-qa={`${gridId}-actions-${rowIndex}`}
              type="button"
              alt={locale.get('gridReact.caretDown')}
              title={locale.get('gridReact.expandActionsControl')}
            >
              {type === FIRST_ACTION
                ? <CaretDownNextIcon focusable noIconWrapper />
                : <DotDotDotNextIcon className={classes.dot} title={locale.get('gridReact.actions')} />}
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

Actions.propTypes = {
  /** Ag grid grid api */
  api: PropTypes.shape({}).isRequired,

  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** href will generate a link. handler will generate a button */
  colDef: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        handler: PropTypes.func,
        href: PropTypes.string,
        label: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  /** String to determine qa attributes */
  gridId: PropTypes.string.isRequired,
  /** ref of the wrapping div of the grid */
  gridWrapperRef: PropTypes.shape({}).isRequired,
  /** Number generated by Ag Grid */
  rowIndex: PropTypes.number.isRequired,
  /** Options for @popperjs/core see docs for details */
  type: PropTypes.oneOf([ELLIPSIS, FIRST_ACTION])
};

Actions.defaultProps = {
  type: FIRST_ACTION
};

export const ActionsComponent = Actions;
export default withTheme(withStyles(styles)(Actions));
