import Model from '@glu/core/src/model';
import serviceList from 'services';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import transform from 'common/util/transform';
import { postData } from 'common/util/services';
import constants from '../../constants';

export default Model.extend({

    defaults() {
        return {
            id: 1,
            CRDTTRNSFUTUREDAYS: '',
            EXPIRATION: '',
            CRDTTRNSMAXTRNSAMT: '',
            OPERATING_CURRENCYCODES: '',
            ONUSMAXAMOUNT: '',
        };
    },

    initialize(context) {
        this.context = context;

        this.validators = {
            CRDTTRNSFUTUREDAYS: {
                exists: true,
                description: locale.get('RTPPREFS.CRDTTRNSFUTUREDAYS.label'),
                matches: validatorPatterns.NUMERIC_PATTERN,
                minValue: 0,
                maxValue: 90,
            },
            EXPIRATION: {
                exists: true,
                description: locale.get('RTPPREFS.EXPIRATION.label'),
                matches: validatorPatterns.NUMERIC_PATTERN,
                minValue: 1,
            },
            CRDTTRNSMAXTRNSAMT: {
                exists: true,
                description: locale.get('RTPPREFS.CRDTTRNSMAXTRNSAMT.label'),
                matches: validatorPatterns.AMOUNT_PATTERN,
                minValue: constants.RTP_LIMITS.MIN_AMOUNT,
                maxValue: constants.RTP_LIMITS.MAX_AMOUNT,
            },
            ONUSMAXAMOUNT: {
                exists: true,
                description: locale.get('RTPPREFS.ONUSMAXAMOUNT.label'),
                matches: validatorPatterns.AMOUNT_PATTERN,
                minValue: constants.RTP_LIMITS.MIN_AMOUNT,
                maxValue: constants.RTP_LIMITS.MAX_AMOUNT,
            },
            OPERATING_CURRENCYCODES: {
                exists: true,
                description: locale.get('RTPPREFS.OPERATING_CURRENCYCODES.label'),
            },
        };
    },

    /**
     * Save updates to the server
     * @param  {string} method - The sync method
     * @param  {Model} model - this Model
     * @return {Promise} - A jQuery Promise
     */
    sync(method, model) {
        switch (method) {
        case 'update':
            return postData(
                `${serviceList.realTimePaymentsPreferences}/update`,
                this.getSaveData(model),
            );
        case 'read':
            return postData(
                `${serviceList.realTimePaymentsPreferences}/read`,
                this.getReadData(),
            ).then((response) => {
                model.set(this.parse(response));
            });
        default:
            return undefined;
        }
    },

    /**
     * Convert the response object to attribute hash
     * @param {Object} response - standard DGB response
     * @returns {Object} newly transform object which can be use to set the model
     */
    parse(response) {
        return transform.pairsToHash(response.item, 'name', 'value');
    },

    /**
     * Get the required data for the read request
     * @returns {Object}
     */
    getReadData() {
        return {
            item: [
                {
                    name: 'ID',
                    value: 1,
                },
            ],
        };
    },

    /**
     * Generate the data object for update (save)
     * @param  {Model} model - this Model
     * @return {object} - The data object to save
     */
    getSaveData(model) {
        return {
            item: transform.hashToPairs(model.toJSON(), 'name', 'value'),
        };
    },
});
