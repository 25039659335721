import EntryView from 'common/dynamicPages/views/workflow/entry';
import locale from '@glu/locale';
import Dialog from '@glu/dialog';
import Constants from 'app/balanceAndTransaction/constants';

const TransactionAdviceDetails = EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: Constants.DEPOSIT_ACCTS_TRANSACTIONS_SERVICE,
            serviceFunc: null,
            businessType: null,
            mode: 'view',
            state: 'view',
            viewType: 'modal',
        };

        EntryView.prototype.initialize.call(this, options);
        this.dialogButtons = [{
            text: 'Cancel',
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
    },

    cancel() {
        Dialog.close();
    },

    getHeader() {
        return locale.get('gir.adviceDetails');
    },
});

export default TransactionAdviceDetails;
