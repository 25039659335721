/**
 * main view for a report group
 *
 * @model - simpleTypeGroup
 */
import util from '@glu/core/src/util';
import Layout from '@glu/core/src/layout';
import Grid from '@glu/grid';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import constants from 'app/administration/constants';
import CollectionView from '@glu/core/src/collectionView';
import ListBuilder from 'common/listBuilder/listBuilder';
import DataEntitlements from 'app/administration/collection/user/dataEntitlements';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import ListBuilderAssetView from 'app/administration/views/userMaintenance/listBuilderAsset';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import LimitSetCollectionView from './limitSetCollectionView';
import PermCheckView from '../permCheckView';
import reportGroupViewTmpl from './reportGroupView.hbs';

export default Layout.extend({
    template: reportGroupViewTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'assigned-accounts',

    ui: {
        $popovers: '[data-toggle="popover"]',
        selectAllBtn: '.select-all-reports',
        $assignAllChk: '[data-hook="assign-all-checkbox"]',
        $gridSection: '[data-hook="grid-section"]',
        $assignAllMsg: '.assign-all-msg',
        $selectAll: '.select-all-perms',
    },

    events: {
        'click @ui.selectAllBtn': 'selectAllReports',
        'click @ui.$assignAllChk': 'changeAssignAll',
        'click @ui.$selectAll': 'selectAllPerms',
    },

    initialize(opts) {
        this.mode = opts.mode;
        this.entity = opts.entity;
        this.isPA = this.model && this.model.attributes && this.model.attributes.typeGroupId && this.model.attributes.typeGroupId.toUpperCase() === 'PAYMENTAUTOMATION';

        if (this.isPA) {
            this.permissions = this.model.get('entitlementsByType');
            if (opts.mode === constants.MODES.VIEW) {
                this.permissions.reset(this.permissions.filter(perm => perm.isEntitled()));
            }
            this.permissions.comparator = function (model) {
                return locale.get(`type.${model.get('typeGroupLabel')}`);
            };
            this.permissions.sort();
        } else {
            const checklistCollection = this.model.get('entitlementsByType');

            if (this.mode === constants.MODES.VIEW) {
                checklistCollection.reset(checklistCollection.filter(entitlement => entitlement.get('entitled')));
            }

            this.checklistView = new CollectionView({
                itemView: PermCheckView,
                collection: checklistCollection,

                itemViewOptions: {
                    tagName: 'div',
                    className: 'checkbox',
                    mode: this.mode,
                },
            });
        }
        if (this.model.availableAccounts) {
            this.gridColumns = this.model.availableAccounts.columns;
            this.setHasLoadedRequiredData(true);
        }

        this.dataEntitlementsConfig = this.model.get('simpleDataEntitlements');
        this.selectedAccounts = this.dataEntitlementsConfig.get('dataEntitlements');
        this.model.availableAccounts = this.model.availableAccounts || new DataEntitlements(
            [],
            {
                inquiryId: this.dataEntitlementsConfig.get('inquiryId'),
                keyCol: this.dataEntitlementsConfig.get('keyCol'),
                configuredDataType: this.dataEntitlementsConfig.get('configuredDataType'),
                userGroup: this.options.userGroup,
            },
        );
        this.hasGrid = this.dataEntitlementsConfig.get('inquiryId') !== 0;
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        if (this.hasLoadedRequiredData()) {
            if (this.isPA) {
                this.permissionsView = new LimitSetCollectionView({
                    collection: this.permissions,
                    mode: this.options.mode,
                });

                this.permissionsRegion.show(this.permissionsView);
            } else {
                this.entitlementsRegion.show(this.checklistView);

                if (this.hasGrid) {
                    this.gridView = new Grid({
                        columns: this.gridColumns,
                        collection: this.selectedAccounts,
                        emptyViewText: locale.get('uce.emptyGrid.bankAccounts'),
                    }).render();

                    this.gridRegion.show(this.gridView);

                    const checked = this.dataEntitlementsConfig.get('assignAll');

                    this.ui.$assignAllChk.prop('checked', checked);
                    this.ui.$gridSection.toggle(!checked);
                    this.ui.$assignAllMsg.toggle(checked);
                } else {
                    this.ui.$gridSection.toggle(false);
                    this.ui.$assignAllMsg.toggle(false);
                }
            }
        } else {
            this.loadRequiredData();
        }
    },

    unescapeAttributes(accounts) {
        util.each(accounts.models, (modelParam) => {
            const model = modelParam;
            model.id = util.unescape(model.id);
            util.each(model.attributes, (attr, attrName) => {
                model.set(attrName, util.unescape(attr));
            });
        });
    },

    selectAllPerms(evt) {
        this.permissions.selectAll(evt.target.checked);
        this.permissionsView.render();
    },

    loadRequiredData() {
        const self = this;

        if (this.hasGrid) {
            this.model.availableAccounts.fetch({
                success(accounts) {
                    // unescape the attributes (until server does not send down escaped values)
                    self.unescapeAttributes(accounts);
                    const toRemove = [];

                    accounts.each((rec) => {
                        const selectedRec = self.selectedAccounts.matchRecord(rec, accounts.configuredDataType === 'ESTATEMENTID' ? 'idBankCode' : accounts.keyCol);

                        if (selectedRec) {
                            toRemove.push(rec);
                            selectedRec.mergeData(rec.attributes);
                        }
                    });

                    // Remove the selected accounts that are not entitled anymore
                    const accountsNotEntitled = self.selectedAccounts
                        .accountsNotEntitled(self.model.availableAccounts);
                    self.selectedAccounts.remove(accountsNotEntitled);

                    self.model.availableAccounts.remove(toRemove);
                    self.gridColumns = accounts.columns;

                    self.setHasLoadedRequiredData(true);
                    self.render();
                },
            });
        } else {
            self.setHasLoadedRequiredData(true);
            self.render();
        }
    },

    openEditItemsDialog() {
        const localeId = this.model.get('typeGroupId');
        const self = this;

        dialog.open(new ListBuilder({
            id: `uce-${localeId}`,
            className: 'list-builder dgb-list-builder',
            sourceCollection: this.model.availableAccounts,
            targetCollection: this.selectedAccounts,
            labelView: ListBuilderAssetView,
            showBusyIndicator: true,

            localeKeys: {
                moveSelectedItems: `uce.${localeId}moveSelected`,
                moveAllItems: `uce.${localeId}moveAll`,
                removeSelectedItems: `uce.${localeId}removeSelected`,
                removeAllItems: `uce.${localeId}removeAll`,
                filter: `uce.${localeId}filter`,
                sourceListHeader: `uce.${localeId}nAvailable`,
                targetListHeader: `uce.${localeId}nAssigned`,
                title: `uce.${localeId}add`,
                save: `uce.${localeId}add`,
                cancel: 'button.cancel',
            },

            saveHandler() {
                // NH-30954 - Grid Views are not updated on dialog save
                if (self.gridView) {
                    self.gridView.render();
                }
            },
        }));
    },

    selectAllReports(evt) {
        this.model.get('entitlementsByType').each((report) => {
            report.set('entitled', evt.currentTarget.checked);
        });
    },

    onClose() {
        if (this.isPA) {
            this.permissionsView.close();
        } else {
            this.checklistView.close();
        }
        if (this.gridView) {
            this.gridView.close();
        }
    },

    changeAssignAll(e) {
        const { checked } = e.target;

        this.dataEntitlementsConfig.set('assignAll', checked);

        this.ui.$gridSection.toggle(!checked);
        this.ui.$assignAllMsg.toggle(checked);
    },

    templateHelpers() {
        const self = this;
        return {
            reportGroup() {
                return locale.get(`uce.reportGroup.${self.model.get('typeGroupId')}`);
            },

            lookupText() {
                return locale.get(`uce.lookupText.${self.model.get('typeGroupId')}`);
            },

            assignAllCheckbox() {
                return locale.get(`role.assignAllCheckbox.${self.model.get('typeGroupId')}`);
            },

            assignAllMsg() {
                return locale.get(`role.assignAllMsg.${self.model.get('typeGroupId')}`);
            },

            assignAllTooltip() {
                return locale.get(`${self.entity}.assignAllTooltip.${self.model.get('typeGroupId')}`);
            },

            readOnly: this.mode === constants.MODES.VIEW,
            hasGrid: self.dataEntitlementsConfig.get('inquiryId') !== 0,
            cid: self.model.cid,
            isPA: self.isPA,
        };
    },
});
