import store from 'system/utilities/cache';
import EntryView from 'common/dynamicPages/views/workflow/entry';

export default EntryView.extend({
    initialize(opts) {
        const options = {
            menuCategory: 'ADMINSTRATION',
            serviceName: 'correspondentBankMapping',
            serviceFunc: null,
            businessType: null,
            mode: opts.mode,

            contextDef: {
                serviceName: '/correspondentBankMapping',
                contextKey: 'correspondentBankMapping',
            },
        };

        EntryView.prototype.initialize.call(this, options);
    },

    loadRequiredData() {
        EntryView.prototype.loadRequiredData.call(this, this.options);
    },

    modify() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('ADMINSTRATION/correspondentBankMappingDetailModify');
    },
});
