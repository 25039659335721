import $ from 'jquery';

(function monkeyPatchInputMask() {
    const $Param = $;
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = !(/windows phone/i).test(ua) && (/android/i).test(ua);
    const originalMask = $Param.fn.inputmask;

    // Only for Android
    if (!isAndroid) {
        return;
    }

    // eslint-disable-next-line
    $Param.fn.inputmask = function (action, mask) {
        // For chaining
        if (undefined === action || typeof action === 'object') {
            return this;
        }

        // Just in case. Probably not needed.
        if (action === 'unmaskedvalue') {
            // this is a jQuery object
            return this.val();
        }

        // Able to remove this, just in case.
        if (action === 'removePatch') {
            $Param.fn.inputmask = originalMask;
            return this;
        }

        // Use the jQuery mask on Android devices just in case
        if (action === 'useOnAndroid') {
            this.inputmask = originalMask;
            this.inputmask(mask);

            return this;
        }

        // All other actions return false, for safety.
        return false;
    };
}());
