var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":44}}}) : helper)))
    + "</h1>\n    </div>\n\n    <div class=\"section section-container\">\n    <div class=\"section-body\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":41}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":24,"column":56},"end":{"line":24,"column":65}}}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <button type=\"button\" class=\"btn btn-primary continue\" data-action=\"continue\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":62,"column":94},"end":{"line":62,"column":122}}}))
    + "</button>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "    </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "")
    + "\n    <div class=\"select-template\">\n    <form autocomplete=\"off\" data-submit=\"save\">\n\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n\n        <div class=\"form-group\" id=\"templateType\">\n            <label for=\"type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.template.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":30},"end":{"line":20,"column":64}}}))
    + "</label>\n            <select class=\"form-control\" data-bind=\"model\" name=\"type\" data-hook=\"template-select\" data-placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"template.template.type.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":117},"end":{"line":21,"column":159}}}))
    + "\">\n                <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"templateTypes") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":25,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"type\"></span>\n        </div>\n\n\n        <div class=\"form-group\" id=\"templateSubType\">\n            <label for=\"type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.template.subtype",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":30},"end":{"line":32,"column":67}}}))
    + "</label>\n            <select class=\"form-control\" data-bind=\"model\" name=\"subType\" data-hook=\"template-sub-select\">\n                <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"paymentSubTypes") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":37,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"subType\"></span>\n        </div>\n\n        <div id=\"templateInfo\">\n            <div class=\"form-group\" id=\"templateName\">\n                <label for=\"Template_Code\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":43},"end":{"line":44,"column":72}}}))
    + "</label>\n                <input id=\"Template_Code\" type=\"text\" class=\"form-control\" data-bind=\"model\" maxlength=\"35\" name=\"Template_Code\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"Template_Code\"></span>\n            </div>\n\n            <div class=\"form-group\" id=\"templateDescription\">\n                <label for=\"Template-Description\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":50},"end":{"line":50,"column":86}}}))
    + "</label>\n                <input id=\"Template-Description\" type=\"text\" class=\"form-control\" data-bind=\"model\" name=\"Template_Description\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"Template_Description\"></span>\n            </div>\n\n            <div class=\"form-group single-checkbox\">\n                <input type=\"checkbox\" name=\"RestrictTemplate_Flag\" id=\"RestrictTemplate_Flag\" data-bind=\"model\">\n                <label for=\"RestrictTemplate_Flag\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.screentext.Restrict",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":51},"end":{"line":57,"column":87}}}))
    + "</label>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":8},"end":{"line":64,"column":15}}})) != null ? stack1 : "")
    + "    </form>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notModal") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":0},"end":{"line":71,"column":7}}})) != null ? stack1 : "");
},"useData":true});