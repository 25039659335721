var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset>\n    <legend>Actions</legend>\n\n    <fieldset class=\"radio-group\">\n        <ul>\n            <li class=\"radio\"><input type=\"radio\" name=\"filter-options\" value=\"0\" id=\"all-items\" checked><label for=\"all-items\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":128},"end":{"line":6,"column":149}}}))
    + "</label></li>\n            <li class=\"radio\"><input type=\"radio\" name=\"filter-options\" value=\"1\" id=\"include-items\"><label for=\"include-items\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"includeItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":128},"end":{"line":7,"column":153}}}))
    + "</label></li>\n            <li class=\"radio\"><input type=\"radio\" name=\"filter-options\" value=\"2\" id=\"exclude-items\"><label for=\"exclude-items\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"excludeItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":128},"end":{"line":8,"column":153}}}))
    + "</label></li>\n        </ul>\n    </fieldset>\n\n    <div class=\"page-content\" data-region=\"tableContent\"></div>\n</fieldset>\n";
},"useData":true});