export default {
    // Slide IN animations
    '@keyframes slideIn-fromTop': {
        from: { opacity: '0', top: '-10vh' },
        to: { opacity: '1', top: '0' },
    },
    '@keyframes slideIn-fromBottom': {
        from: { opacity: '0', bottom: '-10vh' },
        to: { opacity: '1', bottom: '0' },
    },
    '@keyframes slideIn-fromLeft': {
        from: { opacity: '0', left: '-10vw' },
        to: { opacity: '1', left: '0' },
    },
    '@keyframes slideIn-fromRight': {
        from: { opacity: '0', right: '-10vw' },
        to: { opacity: '1', right: '0' },
    },

    // Slide OUT animations
    '@keyframes slideOut-fromBottom': {
        from: { opacity: '1', bottom: '0' },
        to: { opacity: '0', bottom: '10vh' },
    },
    '@keyframes slideOut-fromTop': {
        from: { opacity: '1', top: '0' },
        to: { opacity: '0', top: '10vh' },
    },
    '@keyframes slideOut-fromLeft': {
        from: { opacity: '1', left: '0' },
        to: { opacity: '0', left: '10vw' },
    },
    '@keyframes slideOut-fromRight': {
        from: { opacity: '1', right: '0' },
        to: { opacity: '0', right: '10vw' },
    },

    // Expand Details Animation
    '@keyframes expandDown': {
        from: { opacity: '0', 'max-height': '0' },
        to: { opacity: '1', 'max-height': '35vh' },
    },

    popupAlertOverlay: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100vw',
        height: '100vh',
        zIndex: '999999',
        backgroundColor: '#ffffff',
        padding: '1em',
        color: '#333333',
    },
    popupAlertContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center',
        height: 'auto',
        width: '90%',
    },
    popupAlertCloseButton: {
        position: 'absolute',
        top: 20,
        fontSize: '16px',
        right: 20,
        color: '#4c4c4c',
        '& svg': {
            height: 'auto !important',
        },
    },
    popupAlertPrimaryButton: {
        borderRadius: '2em',
        fontSize: '18px',
        marginTop: '25px',
    },
    popupAlertDetails: {
        width: '100%',
        maxHeight: 350,
        overflow: 'scroll',
        '& .alert-result': {
            animationName: '$expandDown',
            animationDuration: '0.5s',
            textAlign: 'right',
            fontWeight: 'bold',
            maxHeight: '35vh',
            padding: '10px 0',
            overflow: 'scroll',
            '& > div:not(.alert-result)': {
                display: 'flex',
                justifyContent: 'flex-end',
                fontSize: '12px',
                textAlign: 'initial',
            },
            '& > div + *': {
                marginTop: '10px',
            },
            '& .confirm-data-items': {
                display: 'flex',
                fontSize: '12px',
                lineHeight: '12px',
                justifyContent: 'space-between',
            },
            '& span': {
                textAlign: 'right',
                paddingRight: '8px',
            },
            '& span + span': {
                textAlign: 'left',
                fontWeight: 'normal',
                paddingLeft: '8px',
                width: '50%',
            },
        },
    },
    popupAlertIcon: {
        color: '#CCCCCC',
        marginBottom: '22px',
        '& svg': {
            width: '48px !important',
            height: '48px !important',
        },
    },
    popupAlertMessage: {
        wordBreak: 'normal',
        width: '100%',
    },
    popupAnimateOut: {
        animation: ({ animateOut, animateTiming }) => `$${animateOut} ${animateTiming}`,
    },
    popupAnimateIn: {
        animation: ({ animateIn, animateTiming }) => `$${animateIn} ${animateTiming}`,
    },
};
