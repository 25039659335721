import ItemView from '@glu/core/src/itemView';
import emptyAccountGroupsViewTmpl from './emptyAccountGroupsView.hbs';

const EmptyAccountGroupsView = ItemView.extend({
    template: emptyAccountGroupsViewTmpl,
    className: 'empty-account-groups',

    events: {
        'click .btn-secondary': 'createAccountGroup',
    },

    initialize() {
        this.isDeposit = this.options.accountType === 'DEPOSIT';
    },

    createAccountGroup() {
        this.trigger('createAccountGroup', this.options.accountType);
    },
});

export default EmptyAccountGroupsView;
