import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import $ from 'jquery';
import Constants from 'common/dynamicPages/api/constants';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import store from 'system/utilities/cache';
import MetaDrivenForm from 'common/dynamicPages/views/mdf/metaDrivenForm';
import BaseModel from 'no-override!@glu/core/src/model';
import transform from 'common/util/transform';
import domUtil from 'common/util/domUtil';

export default MetaDrivenForm.extend({

    submitActions: [
        Constants.BUTTON_SUBMIT,
        Constants.BUTTON_AUTHORIZE,
    ],

    className() {
        return `${MetaDrivenForm.prototype.className.call(this)} mdf-footer-form`;
    },

    disableFooterButtons(state) {
        domUtil.setDisabled($('div.mdf-footer-form button'), state);
    },

    /**
     * Occurs any time a user attempts to do anything other than "Cancel" a footer form
     * checks the validity of the form
     */
    submitFooterForm() {
        this.disableFooterButtons(true);

        // Map isReadyToSave calls at save time, or the readyState may be stale.
        const widgetReadyPromises = util.map(this.allWidgets, widget => widget.isReadyToSave());

        Promise.all(widgetReadyPromises)
            .then(() => {
                if (this.model.isValid()) {
                    this.processSubmission();
                } else {
                    this.model.trigger('invalid');
                    this.disableFooterButtons(false);
                }
            }, this.rejectSave.bind(this));
    },

    /**
     * Once a form has been determined to be valid, submit it
     */
    processSubmission() {
        let submissionType = this.context.actionContext.subType.toLowerCase();
        /*
         * In future we may need to handle other warning actions but for the
         * present the 'pay' and 'approve' actions will suffice.
         */
        if (this.model.warningAction === 'APPROVE') {
            submissionType = this.model.warningAction.toLowerCase();
        }

        // gather all values from populated inputs within this form
        const formData = Object.keys(this.model.fieldData)
            .filter(key => this.model.get(key) !== '')
            .reduce((acc, key) => ({
                ...acc,
                [key]: this.model.get(key),
            }), {});

        /*
         * These values are not typically defined as attributes of the model
         * and the loop through the keys above will filter them out of the formData.
         * We must restore them in order for warning conditions to be handled
         * correctly.
         */
        formData._saveWithWarning = this.model.get('_saveWithWarning') || ''; // eslint-disable-line no-underscore-dangle
        formData.DUPLICATEREASON = this.model.get('DUPLICATEREASON') || '';
        formData.duplicateAccepted = this.model.get('duplicateAccepted') || '';

        // properly format the data before submitting
        const formattedSubmissionData = {
            item: transform.hashToPairs(formData),
        };

        this.model.sync(
            submissionType,
            this.model,
            {
                success: (response) => {
                    this.disableFooterButtons(false);
                    if (response.errorCode) {
                        if (response.resultType === 'WARNING') {
                            this.handleWarning(response);
                        } else if (response.confirms.chainedAction) {
                            /*
                             * If the chained action has failed we want to go back
                             * to the listview. The primary action has succeeded so
                             * there is nothing else the user can do here.
                             */
                            this.trigger('modelAction:success', response);
                        } else {
                            this.model.error = response;
                            this.trigger('modelAction:error', this.model);
                        }
                    } else {
                        this.trigger('modelAction:success', response);
                    }
                },
                error: (errResponse) => {
                    this.disableFooterButtons(false);
                    this.model.error = errResponse.errorMessage;
                    this.trigger('modelAction:error', this.model);
                },
                submissionData: formattedSubmissionData,
            },
        );
    },

    /**
     * Selects the appropriate dialog in which to present warnings that require
     * input form the user.
     * @param {type} response - the response to the attempted action.
     */
    handleWarning(response) {
        const self = this;
        this.model.warningAction = response.confirms.confirmResults[0].warningAction;
        if (response.errorCode === Constants.DUPLICATE_ERROR_CODE) {
            dialog.custom(new DuplicateDialog({
                model: self.model,
                resp: response,
                isFromFooter: true,
                methodName: response.confirms.confirmResults[0].warningAction,
            }));
        } else {
            dialog.custom(new WarningDialog({
                model: this.model,
                methodName: response.confirms.confirmResults[0].warningAction,
                store,
                detailPage: this,
                confirms: response.confirms,
                isFromFooter: true,
                footerForm: this,
                resp: response,
            }));
        }
    },

    handleDialogCancel(response) {
        const self = this;
        /*
         * If the user cancels out of a dialog for a chained action,
         * navigate back to the listview.
         */
        if (response.confirms.chainedAction) {
            /*
             * A little editing to make the confirm data more presentable in
             * the listview.
             */
            response.resultType = null;
            if (response.message && response.message.length > 1) {
                response.message.length = 1;
            }
            self.trigger('modelAction:success', response);
        } else {
            // Not a chained action. Display message on detail page.
            self.trigger('modelAction:error', self.model);
        }
    },

    /**
     * Sets up the basic form components, in this case we add button click event listeners for
     * relevant buttons since the template helper lacks data-action
     * @param {jQuery} [$container] - Optional replacement container for special
     */
    setupFormComponents($container) {
        this.$('.btn').each((index, buttonElement) => {
            /*
             * We have to define the actions because the selector is generic and could pick up
             * buttons that we do not want perform actions for. The selector is generic because
             * there is nothing in the mdf generated code that would allow us to select only the
             * row of buttons that we want.
             */
            if (this.submitActions.includes(buttonElement.name)) {
                $(buttonElement).click(this.submitFooterForm.bind(this));
            } else if (buttonElement.name === Constants.BUTTON_CANCEL) {
                $(buttonElement).click(() => {
                    this.trigger('cancelFooterForm');
                });
            }
        });

        MetaDrivenForm.prototype.setupFormComponents.call(this, $container);
    },

    /**
     * runs when a meta driven footer form is opened/closed
     * when closing - clear out any old values from if the form was previously already opened
     * @param firstTime {Boolean} - indicates the first time the footer form has been opened
     */
    reset(firstTime) {
        // state manager to track when the meta driven footer form is being shown
        this.isVisible = !this.isVisible;

        /*
         * if this is the first time the form has been opened, get a snapshot of the model
         * to save for the future, and quit
         */
        if (firstTime) {
            this.modelSnapshot = new BaseModel(this.model.attributes);
            return;
        }

        // if the form is being hidden, just quit.
        if (!this.isVisible) {
            return;
        }

        // if the form is being opened, apply the model snapshot, and apply the policies
        this.model.set(this.modelSnapshot.attributes);

        /*
         * invoke policy files here for any form-specific behavior Will not run on form
         * load since metaDrivenForm handles that, just on subsequent form re-openings
         */
        if (this.pageForm) {
            this.loadPolicies();
        }
    },
});
