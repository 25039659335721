import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(models, options) {
        this.base = options.base;
        if (this.base === 'payments') {
            this.serviceUrl = '/payment/listView/smb/getQueryResults';
        } else if (this.base === 'employees') {
            this.serviceUrl = '/payment/listView/smbEmployee/getQueryResults';
        } else if (this.base === 'transfers') {
            this.serviceUrl = '/payment/listView/smbTransfer/getQueryResults';
        } else if (this.base === 'paymentTemplates') {
            this.serviceUrl = '/template/listView/smb/getQueryResults';
        } else if (this.base === 'employeeTemplates') {
            this.serviceUrl = '/template/listView/smbEmployee/getQueryResults';
        }
    },

    parse(jsonData) {
        const returnArray = [];
        util.each(jsonData, (rowItem) => {
            const contextObj = {};
            let typeCode = '';

            if (rowItem.mapDataList) {
                for (let i = 0; i < rowItem.mapDataList.length; i += 1) {
                    if (rowItem.mapDataList[i].toField.toUpperCase() === 'SERVICENAME') {
                        contextObj.serviceName = rowItem.mapDataList[i].value;
                    }
                    if (rowItem.mapDataList[i].toField.toUpperCase() === 'TYPECODE') {
                        typeCode = rowItem.mapDataList[i].value;
                    }
                }
            }

            const obj = {
                id: rowItem.name,
                name: rowItem.label,
                label: rowItem.label,
                typeCode,
                context: contextObj,
            };

            returnArray.push(obj);
        });

        return util.sortBy(returnArray, 'label');
    },

    sync(method, collection, options) {
        const model = collection.models[0];
        const url = services.generateUrl(this.serviceUrl);
        const postData = {
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,

                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },

                    userId: '',
                },

                channelId: '',
                requestId: '',
            },

            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    actionMode: 'INSERT',
                    productCode: 'PAY',
                    functionCode: model.get('functionCode') || 'INST',
                },

                fieldName: 'SMB_DESCRIPTION',
                inquiryId: model.get('inquiryId') || 20011,
                queryType: 'PaymentTypes',

                customFilters: [{
                    filterName: '',

                    filterParam: [
                        '',
                    ],
                }],
            },

            formatterType: 'PaymentTypes',
        };
        http.post(url, postData, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, (result) => {
            options.error(result);
        });
    },
});
