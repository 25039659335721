/* eslint eqeqeq:0 */
import util from '../../util';
import numeral from '../../number';
import moment from '../../date';

// Email RegEx
const html5Spec = '^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*';
const tldCheck = '\\.[a-zA-Z0-9-]{0,61}$';
const fullEmailRegEx = new RegExp(html5Spec + tldCheck);

export default {

  exists(key, model) {
    const value = model.get(key);

    if (value === void 0 || value === null) {
      return false;
    }

    if (typeof value === 'string') {
      return value.replace(/^\s+|\s+$/gm, '').length > 0;
    }
    return true;
  },

  minLength(key, min, model) {
    let value = model.get(key);
    if (util.isNumber(value)) {
      value = `${value}`;
    }
    return value && (value.length >= (min || 0));
  },

  maxLength(key, max, model) {
    let value = model.get(key);
    if (util.isNumber(value)) {
      value = `${value}`;
    }
    return !value || (value.length <= (max || 0));
  },

  rangeLength(key, range, model) {
    return (this.minLength(key, range[0], model) &&
      this.maxLength(key, range[1], model));
  },

  exactLength(key, exactLength, model) {
    let value = model.get(key);
    if (util.isNumber(value)) {
      value = `${value}`;
    }
    return value && (value.length === (exactLength || 0));
  },

  minValue(key, min, model) {
    // unformat the key because minValue does not work on some locale formats
    const value = numeral().unformat(model.get(key));
    return value && (value >= (min || 0));
  },

  maxValue(key, min, model) {
    const value = model.get(key);
    return value && (value <= (min || 0));
  },

  range(key, range, model) {
    return (this.minValue(key, range[0], model) &&
      this.maxValue(key, range[1], model));
  },

  oneOf(key, searchValues, model) {
    return util.contains(searchValues, model.get(key));
  },

  equalTo(key, test, model) {
    return model.get(key) == test;
  },

  contains(key, searchPattern, model) {
    const value = model.get(key);
    return value && (value.indexOf(searchPattern) > -1);
  },

  beginsWith(key, searchPattern, model) {
    const value = model.get(key);
    return value && (value.indexOf(searchPattern) === 0);
  },

  endsWith(key, searchPattern, model) {
    const value = model.get(key);
    return value && (value.indexOf(searchPattern, value.length - searchPattern.length) > -1);
  },

  matches(key, pattern, model) {
    const value = model.get(key);
    const regEx = new RegExp(pattern);

    if (util.isEmpty(value)) {
      return true;
    }
    return regEx.test(value);
  },

  matchesAlphaNumeric(key, pattern, model) {
    const value = model.get(key);
    // ignore any pattern sent and keep to alphanumeric purpose
    const regEx = new RegExp('^[a-zA-Z0-9]*$');

    if (util.isEmpty(value)) {
      return true;
    }
    return regEx.test(value);
  },

  isNumeric(key, model) {
    let value = model.get(key);
    if (util.isString(value) && value !== '') {
      value = +value;
    }
    return !isNaN(value) && util.isNumber(value);
  },

  // Compares the value of one field with another.
  // Useful for confirm password validations.
  sameValue(key, key2, model) {
    // only run if both values have been provided.
    // assumes the exists validation will handle
    // the case where values are not provided.
    if (model.get(key) && model.get(key2)) {
      return model.get(key) === model.get(key2);
    }

    return true;
  },

  // Compares the value of one field with another.
  notSameValue(key, key2, model) {
    // only run if both values have been provided.
    // assumes the exists validation will handle
    // the case where values are not provided.
    if (model.get(key) && model.get(key2)) {
      return model.get(key) !== model.get(key2);
    }

    return true;
  },

  // Compares the value of one field against all others
  isUnique(key, model) {
    /*
     *   returns false when an identical value is found
     *   skips checking against itself
     *   confirms a value has been provided
     *   breaks out of the loop on the first match
     */
    let isUnique = true;
    let attribute;
    if (model.get(key)) {
      for (attribute in model.attributes) {
        if (key !== attribute) {
          if (model.get(key) === model.get(attribute)) {
            isUnique = false;
            break;
          }
        }
      }
    }
    return isUnique;
  },

  isEmailAddress(key, model) {
    const value = model.get(key);
    return !!(value && fullEmailRegEx.test(value));
  },

  isGreaterThan(key, comparator, model) {
    const value = +model.get(key);

    if (!util.isNumber(comparator) && !util.isNaN(comparator)) {
      comparator = model.get(comparator);
    }

    return value && (value > +comparator);
  },

  isLessThan(key, comparator, model) {
    const value = +model.get(key);

    if (!util.isNumber(comparator) && !util.isNaN(comparator)) {
      comparator = model.get(comparator);
    }

    return value && (value < +comparator);
  },

  matchesDatePattern(key, pattern, model) {
    if (util.isEmpty(model.get(key))) {
      return true;
    }
    if (util.isFunction(pattern)) {
      pattern = pattern().toUpperCase();
    } else if (util.isString(pattern)) {
      pattern = pattern.toUpperCase();
    } else {
      pattern = 'MM/DD/YYYY';
    }

    // added true flag to force isValid to validate pattern exactly and not ignore separators.
    const d = moment(model.get(key), pattern, true);
    return d.isValid();
  }
};

