var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-container security-passcode\">\n            <div>\n                <div class=\"field-container-lg\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa:challenge1_description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":60}}}))
    + "\n                </div>\n            </div>\n            <div>\n                <div>\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.challenge.sendTo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":27},"end":{"line":19,"column":65}}}))
    + "</label>\n                    <select class=\"form-control\"\n                        data-hook=\"getContacts\"\n                        name=\"textNumber\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"contacts") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":25,"column":37}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n\n                <div class=\"field-container-xs send-notice button-align-bottom\">\n                    <button type=\"button\" class=\"btn btn-primary btn-sm\"  data-action=\"sendNotification\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.challenge.sendCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":105},"end":{"line":31,"column":145}}}))
    + "</button>\n                </div>\n\n                <div class=\"field-container-xs receive-code button-align-bottom text-right\">\n                    <span class=\"loader icon-wrapper\"></span>\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.sendingCode.text",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":59}}}))
    + "\n                </div>\n\n                <div class=\"field-container-sm receive-code\">\n                    <label for=\"oneTimePassword-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":40,"column":48},"end":{"line":40,"column":55}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.enterCode.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":57},"end":{"line":40,"column":94}}}))
    + "</label>\n                    <input id=\"oneTimePassword-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":41,"column":47},"end":{"line":41,"column":54}}}) : helper)))
    + "\" type=\"text\" name=\"oneTimePassword\" class=\"form-control\" data-bind=\"model\" />\n                    <button type=\"submit\" class=\"btn btn-primary submit btn-sm\"  data-action=\"submitCode\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":106},"end":{"line":42,"column":132}}}))
    + "</button>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"oneTimePassword\"></span>\n                     <div>\n                        <span class=\"help-block activation-notice\"></span>\n                        <button type=\"button\" class=\"btn btn-link\" data-action=\"sendNotification\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.resendCode.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":98},"end":{"line":46,"column":136}}}))
    + "</button>\n                    </div>\n                 </div>\n\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":24,"column":47},"end":{"line":24,"column":56}}}) : helper)))
    + "*"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":24,"column":57},"end":{"line":24,"column":65}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"default") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":67},"end":{"line":24,"column":99}}})) != null ? stack1 : "")
    + " data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":24,"column":111},"end":{"line":24,"column":119}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":24,"column":121},"end":{"line":24,"column":130}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":24,"column":132},"end":{"line":24,"column":140}}}) : helper)))
    + ")</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form autocomplete=\"off\" data-submit=\"save\">\n\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasContacts") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":52,"column":15}}})) != null ? stack1 : "")
    + "    </form>\n</div>\n";
},"useData":true});