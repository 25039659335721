import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import ComplexTypes from 'app/administration/collection/user/complexTypes';
import util from '@glu/core/src/util';
import paymentGroupTmpl from './paymentGroup.hbs';

export default ItemView.extend({
    tagName: 'div',
    template: paymentGroupTmpl,

    ui: {
        $selectAllTypes: '.select-all-types',
        selectType: '[data-hook="type-checkbox"]',
        selectAll: '[data-hook="type-selectAll"]',
    },

    events: {
        'click @ui.$selectAllTypes': 'selectAllTypes',
        'click @ui.selectType': 'selectType',
    },

    initialize(options) {
        const self = this;

        this.mode = options.mode;
        this.modifyMode = options.modifyMode || false;

        this.collection = this.model.get('typesInGroup');

        if (!this.modifyMode) {
            this.collection = new ComplexTypes(this.collection.filter(rec => rec.isEntitled()));
        }

        // backup current settings
        this.collection.each((modelParam) => {
            const model = modelParam;
            model.originalAttributes = util.clone(model.attributes);
        });

        // reset values if cancelled
        this.listenTo(this.appBus, 'usercentric:paymentTypes:cancel:reset', () => {
            self.collection.each((modelParam) => {
                const model = modelParam;
                model.attributes = model.originalAttributes;
            });
        });
    },

    onRender() {
        if (this.allTypesSelected()) {
            this.ui.selectAll.prop('checked', true);
        }
    },

    allTypesSelected() {
        let allSelected = true;

        util.each(this.$('[data-hook="type-checkbox"]'), function (input) {
            if (this.$(input).prop('checked') === false) {
                allSelected = false;
            }
        }, this);
        return allSelected;
    },

    // manually data bind
    selectType(evt) {
        this.collection.get(this.$(evt.target).data('id')).set('entitled', evt.target.checked);
    },

    selectAllTypes(evt) {
        const self = this;
        const { checked } = evt.currentTarget;

        self.ui.selectType.prop('checked', checked);

        this.model.get('typesInGroup').each((rec) => {
            rec.set('entitled', checked);
        });
    },

    templateHelpers() {
        const self = this;
        const { model } = this;
        return {
            cid: model.cid,
            isModifyMode: self.modifyMode,

            rows: this.collection.chain().map(rec => util.extend(
                rec.toJSON(),
                {
                    cid: rec.cid,
                },
            )).groupBy((rec, i) => Math.floor(i / 3)).values()
                .value(),

            showAtAll() {
                return self.modifyMode || model.hasEntitledType();
            },

            label: locale.get(`administration.${this.model.get('typeGroupLabel')}`),
        };
    },
});
