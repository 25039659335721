import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';
import Handlebars from 'handlebars';
import templateHelpers from '@glu/core/src/templateHelpers';
import loadingMessages from './loadingMessages';

const { default: baseHelpers } = templateHelpers;

const helpers = util.extend(
    {},
    baseHelpers,
    {
        // this override provides for userInfo.getDatFormat
        date(value) {
            return Formatter.formatDate(value);
        },

        // this override uses glu's amount formatter vs. default number
        number(value) {
            return Formatter.formatNumber(value);
        },

        /*
         * this override returns a number wheras glu default always returns string
         * (on purpose)
         */
        abbrNumber(count) {
            let localCount = count;
            localCount = +localCount || 0;

            if (localCount > 999) {
                // return number(count).format('0.0a');
                return Formatter.formatNumber(localCount, '0.0a');
            }

            return localCount;
        },

        documentRoot() {
            return window.Bottomline.documentRoot;
        },

        loadingMessages() {
            const l = loadingMessages.length;
            return loadingMessages[Math.floor(Math.random() * l)];
        },

        ifAnd(v1, v2, options) {
            return !!v1 && !!v2 ? options.fn(this) : options.inverse(this);
        },

        ifEqual(v1, v2, options) {
            return v1 === v2 ? options.fn(this) : options.inverse(this);
        },
    },
);

// Legacy
helpers.dateFormat = helpers.date;

// Bind HBS helpers
util.each(helpers, (fn, name) => {
    Handlebars.registerHelper(name, fn);
});

export default helpers;
