import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({
    sync(method, model, options) {
        const data = {
            IncludeMapData: 1,

            queryCriteria: {
                inquiryId: '22418',

                action: {
                    typeCode: 'ESTMENT',
                    entryMethod: 0,
                    productCode: 'GIR',
                    actionMode: 'SEARCH',
                    functionCode: 'STMENT',
                },

                queryType: 'SearchQuery',
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 1,

                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },

                    userId: '',
                },

                requestId: '',
            },
        };

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const returnArray = [];
        util.each(response, (rowItem) => {
            const obj = {
                id: rowItem.name,
                label: rowItem.label,
                mapDataList: rowItem.mapDataList,
            };
            returnArray.push(obj);
        });
        return returnArray;
    },
});
