var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-hook=\"getCancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":145},"end":{"line":3,"column":180}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.wireDomesticQuickEntryTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":76}}}))
    + "</h1>\n    <div class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.paymentTotal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":69}}}))
    + "</p>\n        <div class=\"amount-value-text payment-total\"><span data-hook=\"getSummaryAmount\">0.00</span> <span data-hook=\"getSummaryCurrency\"></span></div>\n    </div>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"glu-container\" data-region=\"rapidEntryRegion\"></div>\n        <div class=\"row rapid-wire-entry-container\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"rapidAddAdditional"),depth0,{"name":"rapidAddAdditional","hash":{"label":"common.payments"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n<div class=\"section section-summary\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"rapidSummarySection"),depth0,{"name":"rapidSummarySection","hash":{"appliedItemType":"ACH.Payments"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n            <button class=\"btn btn-primary\"\n                    data-hook=\"getSubmit\"\n                    type=\"button\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":43}}}))
    + "\n            </button>\n            <button class=\"btn btn-secondary\"\n                    data-hook=\"getCancel\"\n                    type=\"button\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":54}}}))
    + "\n            </button>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});