import util from '@glu/core/src/util';
import jquery from 'jquery';

export default function (form, initialState) {
    const $ = jquery;
    const { model } = form.formView;
    const view = form.formView;
    const formState = view.state;
    const originatorCountryCode = form.field('ORIGCOUNTRY');
    const originatorState = form.field('ORIGSTATE');
    const originatorProvince = form.field('ORIGPROVINCE');
    const originatorBankCodeType = form.field('ORIGINATOR_BANK_CODE_TYPE');
    const paymentFromTemplateProtectedFields = [
        'ORIGINATOR_FULL_NAME',
        'ORIGADDRESS',
        'ORIGCOUNTRY',
        'ORIGSTATE',
        'ORIGPROVINCE',
        'ORIGCITY',
        'ORIGPOSTALCODE',
        'ORIGINATOR_BANK_CODE_TYPE',
        'ORIGABA',
    ];

    const protectFields = function (array) {
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnly(true);
        });
    };

    const hideFields = function (fields) {
        fields.forEach((fieldName) => {
            form.field(fieldName).shouldBeVisibleWhen(false).shouldBeRequiredWhen(false);
        });
    };

    if (initialState) {
        if (form.formView.model.context.entrymethod && form.formView.model.context.entrymethod === '1'
            && form.formView.model.jsonData.typeInfo.functionCode !== 'TMPL') {
            protectFields(paymentFromTemplateProtectedFields);
            form.field('ORIGABA_LOOKUP').$el.prop('disabled', true);
        }

        view.$('[name="ORIGSTATE"]').parent().addClass('required');
        view.$('[name="ORIGPROVINCE"]').parent().addClass('required');

        // Add the handler for originator ID change
        model.on('change:COMPIDNAME', (changedModel) => {
            if (changedModel.changed.ORIGABA || changedModel.changed.ORIGABA === '') {
                view.$('#ORIGABA').val(changedModel.changed.ORIGABA).trigger('change', true);
            }
        });

        // Add the handler for originator country change
        originatorCountryCode.$el.on('change', function () {
            /*
             * for country US, clear the province value previously entered,otherwise, clear the
             * state value previously entered
             */
            if (this.value === 'US') {
                model.set('ORIGPROVINCE', '');
                $('[name="ORIGPROVINCE"]').val('');
            } else {
                model.set('ORIGSTATE', '');
            }
        });

        /*
         * When the ORIGINATOR_BANK_CODE_TYPE is changed, all the information regarding
         * the bank code should be cleared.
         */
        originatorBankCodeType.$el.on('change', (event) => {
            const origBankQualifierValue = event.currentTarget.value === 'SWIFT' ? '02' : '01';
            model.set('ORIGBANKQUALIFIER', origBankQualifierValue);
            if (event && (event.added || event.removed)) {
                model.set('ORIGABA', null);
                view.trigger('lookupHelperText:clear', 'ORIGABA');
            }
        });
    }

    /*
     * Originator state/province display depends on the country user selected,
     * for US, display the state drop down, otherwise, display province text box
     */
    if (formState !== 'insert'
        && form.formView.model.context.actionData.entryClass
        && form.formView.model.context.actionData.entryClass !== 'IAT') {
        hideFields(paymentFromTemplateProtectedFields);
    } else if (formState !== 'view') {
        if (originatorCountryCode.getValue() === 'US') {
            originatorState.shouldBeVisibleWhen(true).shouldBeRequiredWhen(true);
            originatorProvince.shouldBeVisibleWhen(false).shouldBeRequiredWhen(false);
        } else {
            originatorState.shouldBeVisibleWhen(false).shouldBeRequiredWhen(false);
            originatorProvince.shouldBeVisibleWhen(true).shouldBeRequiredWhen(true);
        }
    } else {
        const showState = model.get('ORIGCOUNTRY') === 'US';
        originatorState.shouldBeVisibleWhen(showState).shouldBeRequiredWhen(showState);
        originatorProvince.shouldBeVisibleWhen(!showState).shouldBeRequiredWhen(!showState);
    }
}
