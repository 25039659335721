import { withStyles } from '@glu/theming';
import { Dropdown } from '@glu/dropdown-react';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './TypeaheadFilter.styles';

const TypeaheadFilter = ({
  TypeaheadFilterProps,
  classes,
  createFilterValue,
  fieldId,
  filterId,
  fixedList,
  onChange,
  options: rawOptions,
  parseFilterValue,
  value: rawValue,
  ...rest
}) => {
  const handleChange = (name, value) => {
    onChange(fieldId, createFilterValue(filterId, fieldId, value[0]));
  };
  const options = rawOptions.map((option) => ({ id: option.value, name: option.name }));

  const shapedValue = rawValue?.filters?.length > 0
    ? [{
      id: rawValue.filters[0].id,
      name: rawValue.filters[0].criteriaDisplay
    }]
    : undefined;

  return (
    <div className={classes.inputWrapper}>
      <Dropdown
        onChange={handleChange}
        options={options}
        dataQa="type-ahead"
        disableInputAPI
        {...rest}
        {...TypeaheadFilterProps}
        key={shapedValue}
        value={shapedValue}
      />
    </div>
  );
};

TypeaheadFilter.propTypes = {

  /** additional props for the dropdown */
  TypeaheadFilterProps: PropTypes.shape({}),

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** Function to convert data from the input into something usable by callback */
  createFilterValue: PropTypes.func.isRequired,

  /** Field id to use for generating filter metadata */
  fieldId: PropTypes.string.isRequired,

  /** Filter id to use for generating filter metadata */
  filterId: PropTypes.string,

  /** The list of options is always open */
  fixedList: PropTypes.bool,

  /** Called whenever onChange happens on the option */
  onChange: PropTypes.func.isRequired,

  /** Options array of data to filter */
  options: PropTypes
    .arrayOf(PropTypes.shape({ id: PropTypes.id, name: PropTypes.string })).isRequired,

  /** Function to convert stored data into a form usable by the component */
  parseFilterValue: PropTypes.func.isRequired,

  /** Value for validated input */
  value: PropTypes.shape({ name: PropTypes.string }).isRequired
};

TypeaheadFilter.defaultProps = {
  TypeaheadFilterProps: undefined,
  filterId: undefined,
  fixedList: false
};

export default withStyles(styles)(TypeaheadFilter);
