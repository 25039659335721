var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ViewFilter label label-filter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"overridden") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":97}}})) != null ? stack1 : "")
    + "\">\n            <span class=\"ViewFilter-title filter-title-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":70}}}) : helper)))
    + "</span>\n            <span class=\"filter-value-text "
    + alias4(((helper = (helper = lookupProperty(helpers,"labelClass") || (depth0 != null ? lookupProperty(depth0,"labelClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelClass","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":57}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":68}}}) : helper)))
    + "</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"allSavedViewFilters")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "ViewFilter--overridden";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"remove-filter "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"savedViewFilter") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":79}}})) != null ? stack1 : "")
    + "\" data-filter-id=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"fieldCode") || (depth0 != null ? lookupProperty(depth0,"fieldCode") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"fieldCode","hash":{},"data":data,"loc":{"start":{"line":8,"column":97},"end":{"line":8,"column":110}}}) : helper)))
    + "\">\n                    "
    + alias3((lookupProperty(helpers,"customizedIcon")||(depth0 && lookupProperty(depth0,"customizedIcon"))||alias2).call(alias1,{"name":"customizedIcon","hash":{"locale":"clearFilter","action":"clearSavedGridFilter","name":"circle-close-solid"},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":115}}}))
    + "\n                </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"viewFilters") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-hook=\"clearAllFilters\"\n                class=\"btn-tertiary\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":53}}}))
    + "\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":41}}}))
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"SavedViewFilters\" data-hook=\"getSavedViewFilters\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"viewFilters") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":13,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"allSavedViewFilters")),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});