import React from 'react';
import PropTypes from 'prop-types';
import { ViewWrapper } from '@glu/react-wrappers';
import gridApi from 'common/dynamicPages/api/grid';
import { prepareGridOptions } from '../checkManagement/checkManagementUtils';
// TODO - extract to common

const constants = {
    serviceName: '/audit/clmDetailAuditListView',
    productCode: 'CLM',
    functionCode: 'BATCH',
    selector: 'none',
    filter: false,
    enableSavedViews: false,
};

const MdfGridWrapper = ({ batchTnum, typeCode }) => {
    const additionalSearchFields = [{
        operator: 'EQ',
        dataType: 'text',
        fieldName: 'TYPE',
        fieldValue: [`B${typeCode.toUpperCase()}`],
    }, {
        operator: 'EQ',
        dataType: 'text',
        fieldName: 'TNUM',
        fieldValue: [batchTnum.toString()],
    }, {
        operator: 'EQ',
        dataType: 'text',
        fieldName: 'FUNCTION',
        fieldValue: [constants.functionCode],
    }];

    const serviceGridOptions = prepareGridOptions({
        ...constants,
        additionalSearchFields,
        typeCode,
    });

    const mdfGridView = gridApi.createServiceGridView(serviceGridOptions);

    // TODO
    // this.exportListViewPath = '/paymentExport/listView/corp/exportListView';
    // Also look to auditHistoryList.js - maybe use instance of it.

    return <ViewWrapper view={mdfGridView} />;
};

MdfGridWrapper.propTypes = {
    batchTnum: PropTypes.number.isRequired,
    typeCode: PropTypes.string.isRequired,
};

export default MdfGridWrapper;
