import Constants from 'app/checkManagement/constants';
import DecisionHistory from 'app/checkManagement/views/decisionHistorySearch';
import PositivePayDecisionHistoryList from './listPositivePayDecisionHistory';
import ReversePositivePayDecisionHistoryList from './listReversePositivePayDecisionHistory';
import EPositivePayDecisionHistoryList from './listEPositivePayDecisionHistory';

export default DecisionHistory.extend({
    /** @override */
    getFilterId(typeCode) {
        return Constants.ADMIN_ADVANCED_SEARCH_FILTER_IDS_BY_TYPE[typeCode];
    },

    /** @override */
    getDecisionHistoryList(typeCode, options) {
        switch (typeCode) {
        case Constants.POS_PAY_TYPE:
            return new PositivePayDecisionHistoryList(options);
        case Constants.REVERSE_POS_PAY_TYPE:
            return new ReversePositivePayDecisionHistoryList(options);
        case Constants.ELECTRONIC_POS_PAY_TYPE:
            return new EPositivePayDecisionHistoryList(options);
        default:
            return null;
        }
    },
});
