import ItemView from '@glu/core/src/itemView';
import constants from 'app/administration/constants';
import locale from '@glu/locale';
import contactTypeViewTmpl from './contactTypeView.hbs';

export default ItemView.extend({
    template: contactTypeViewTmpl,
    className: 'checkbox-inline',

    ui: {
        checkbox: '[data-hook="contact-chk"]',
    },

    events: {
        'click @ui.checkbox': 'changeValue',
    },

    changeValue(e) {
        this.model.set('CHECKED', e.target.checked);
        this.options.onChange?.();
    },

    initialize() {
        this.listenTo(this.model, 'selectAll', function (checked) {
            this.model.set('CHECKED', checked);
            this.render();
        }, this);
    },

    templateHelpers() {
        return {
            isChecked: this.model.get('CHECKED') ? 'checked' : '',
            cid: this.model.cid,
            readOnly: this.options.mode === constants.MODES.VIEW,
            getDescription: locale.get(`uce.${this.model.get('DESCRIPTION')}`),
        };
    },
});
