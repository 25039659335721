import locale from '@glu/locale';
import util from '@glu/core/src/util';
import { getFlexDropdownFormat } from 'common/util/inquiryQueryResults';
import LegacyBankReportBase from './legacyBankReportBase';
import template from './legacyReport.hbs';
import scheduleUtil from '../util';

export default LegacyBankReportBase.extend({
    template,

    ui: {
        $reportDescDropdown: '[data-hook="getReportDescDropdown"]',
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            return;
        }
        LegacyBankReportBase.prototype.onRender.call(this);
        this.reportIdRegion.show(this.reportIdDropdown);
        this.ui.$reportDescDropdown.comboBox()
            .on('change', this.updateReportDesc.bind(this))
            .val(this.model.get('REPORTDESC') ? this.model.get('REPORTDESC') : [])
            .trigger('change');

        if (scheduleUtil.shouldShowEmailRecipients()) {
            this.model.off('change:REPORT_ID', this.setupEmailRecipients);
            this.model.on('change:REPORT_ID', this.setupEmailRecipients.bind(this));
            this.setupEmailRecipients();
        }
    },

    /**
     * When model has a value for REPORT_ID, show the view
     */
    setupEmailRecipients() {
        this.showEmailRecipients(util.isEmpty(this.model.get('REPORT_ID')));
    },

    /**
     * Get entitled email recipients for Legacy reports
     * @param {string} searchValue
     */
    getEntitledEmailRecipients(searchValue) {
        const data = {
            searchValue,
            legacyReportId: this.model.get('REPORT_ID'),
            ...this.getEmailRecipientAdminData(),
        };
        return scheduleUtil.getEmailRecipients(
            'export/scheduledExport/getEntitledEmailsLegacyReport',
            data,
        );
    },

    /**
     * Set the model attribute when event has a value
     * @param {Object} e - jquery event object from select2
     */
    updateReportDesc(e) {
        if (e.val) {
            this.model.set('REPORTDESC', e.val);
        }
    },

    /**
     * Fetch all the data required for rendering the view for insert
     */
    getCreateData() {
        return Promise.all([
            this.getReportIds(),
            this.getReportTypes(),
        ]);
    },

    /**
     * Create a dropdown for the export types and add a change listener
     */
    createFileFormatDropdown() {
        return this.createDropdown(
            {
                data: [{
                    id: 'legacy.report.type',
                    name: locale.get('GIR.Report'),
                }, {
                    id: 'legacy.file.type',
                    name: locale.get('GIR.Data.File'),
                }],
                preSelectedIds: this.model.get('REPORTORDOWNLOAD') ? [this.model.get('REPORTORDOWNLOAD')] : [],
                label: locale.get('GIR.File.Type'),
            },
            this.updateModelWithSelection.bind(this, 'REPORTORDOWNLOAD'),
        );
    },

    /**
     * Fetch all the data required for rendering the view for modify
     */
    getModifyData() {
        return this.model.getModel()
            .then(this.getCreateData.bind(this));
    },

    /**
     * Make a request to the server with the specified inquiry id
     * @param {string} inquiryId - inquiry Id to use for the request
     * @returns {Promise}
     */
    getInquiryData(inquiryId) {
        return scheduleUtil.getInquiryData({
            customFilters: [
                {
                    filterName: 'DEPENDS',
                    filterParam: [
                        'USERGROUP',
                        this.model.getIdFromDropdownValue('userGroup'),
                    ],
                },
                {
                    filterName: 'DEPENDS',
                    filterParam: [
                        'USER',
                        this.model.getIdFromDropdownValue('userId'),
                    ],
                },
            ],
            inquiryId,
        }).then(getFlexDropdownFormat);
    },

    /**
     * Get a list of reports and then create a dropdown with
     * the response data
     * @returns {Promise}
     */
    getReportIds() {
        return this.getInquiryData(17269).then((flexData) => {
            this.reportIdDropdown = this.createDropdown(
                {
                    data: flexData,
                    preSelectedIds: this.model.get('REPORT_ID') ? [this.model.get('REPORT_ID')] : [],
                    label: locale.get('GIR.Report.ID'),
                },
                this.updateModelWithSelection.bind(this, 'REPORT_ID'),
            );
        });
    },

    /**
     * Get report types with the inquiry id
     * @returns {Promise}
     */
    getReportTypes() {
        return this.getInquiryData(17270).then((flexData) => {
            this.reportDescriptions = flexData;
        });
    },

    /**
     * Get an array of the attributes that should be use as search
     * filters
     * @returns {Array}
     */
    getSearchAttrs() {
        return ['REPORT_ID', 'REPORTDESC', 'REPORTORDOWNLOAD'];
    },

    templateHelpers() {
        return {
            ...LegacyBankReportBase.prototype.templateHelpers.call(this),
            reportDescriptions: this.reportDescriptions,
        };
    },
});
