import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import store from 'system/utilities/cache';
import ConversationView from 'app/payments/views/realTimePayments/rtpConversationView';

const IncomingRTPList = ListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: '/adminPayment/rtp/incoming',
            serviceFunc: null,
            busnessType: null,
            enableSavedViews: true,
            context: options.id,
            returnRoute: options.returnRoute,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        // reset the drawer state for tab and open state on init
        this.appBus.trigger('dgb:drawer:update', {
            showTab: false,
            showTabOnClose: false,
            shouldBeOpen: false,
            viewOptions: {
                allowDetail: true,
                keepAlive: false,
            },
        });
    },
    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('REPORTING/viewIncomingDetails');
        const messageStatus = options.model.get('MESSAGE_STATUS');
        if (messageStatus && messageStatus !== '-') {
            if (options.fromReact) {
                // Drawer is already present, called from detail click in drawer
                this.appBus.trigger('dgb:drawer:update', {
                    shouldBeOpen: false,
                    showTab: true,
                    showTabOnClose: true,
                    viewOptions: {
                        allowDetail: false,
                        keepAlive: true,
                    },
                });
            } else {
                // called from view click in grid
                const rtpConversationData = {
                    view: ConversationView,
                    showTab: true,
                    shouldBeOpen: false,
                    viewOptions: {
                        model: options.model,
                        conversationStarted: true,
                        defaultOpen: false,
                        allowDetail: false,
                        currentTab: 'received',
                        keepAlive: true,
                    },
                };
                this.appBus.trigger('dgb:drawer:update', rtpConversationData);
            }
        }
        return Promise.resolve();
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'INCOMING_RTP',
    view: IncomingRTPList,
    options: {},
});

export default IncomingRTPList;
