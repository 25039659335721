/**
 * Dialog for displaying bank response on TOA
 */
import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import store from 'system/utilities/cache';
import alert from '@glu/alerts';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import services from 'services';
import userInfo from 'etc/userInfo';
import { maskValue } from 'common/util/maskingUtil';
import constants from 'app/balanceAndTransaction/constants';
import dynamicPagesConstants from 'common/dynamicPages/api/constants';
import loadingTmpl from 'common/templates/loadingPage.hbs';
import Formatter from 'system/utilities/format';
import closeTOAServiceModalTmpl from './closeToaModalTmpl.hbs';

const CloseToaModal = Layout.extend({
    template: closeTOAServiceModalTmpl,
    loadingTemplate: loadingTmpl,
    modalClass: 'modal-xlg',

    initialize(options) {
        this.mode = options.mode;
        this.collection = new Collection([]);
        this.dialogButtons = [{
            text: locale.get('toa.CloseAccount'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        }];

        this.dialogTitle = `${locale.get('toa.CloseTOAAccount')} -
                ${this.model.get('clientAccountName')} -
                ${this.model.get('accountNumber')}`;

        this.model.set({
            closeToaResponseError: false,
            closeAccountInfo: undefined,
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
            this.modalButtonsState('disableAll', '.modal button');
        }
    },

    loadRequiredData() {
        this.getCloseTOAInfo();
    },

    /**
     * @method updateModalBody
     * Update the modal body with the account info returned from
     * a success FCB's back-office system response
     */
    updateModalBody() {
        const {
            toaAccountNumber,
            toaAccountName,
            optAccountNumber,
            optAccountName,
            optCurrencyCode,
            todayDate,
            closingBalance,
        } = this.fcbResponse;
        this.model.set('closeAccountInfo', {
            [locale.get('toa.FromAccount')]: `${toaAccountName} -
                    ${maskValue(toaAccountNumber)}`,
            [locale.get('toa.ToAccount')]: `${optAccountName} -
                    ${maskValue(optAccountNumber)}`,
            [locale.get('toa.Date')]: todayDate,
            [locale.get('toa.ClosingBalance')]:
                `${Formatter.formatCurrency(closingBalance)} ${optCurrencyCode}`,
        });
    },

    /**
     * @method getCloseTOAInfoPayload
     * This will return the payload needed to send the request to
     * FCB's back-office system to request the account to be closed.
     * @returns {object}
     */
    getCloseTOAInfoPayload() {
        const accountFilterParts = this.model.get('accountFilter').split(/-(.+)/);
        return {
            item: [
                {
                    name: 'BANKCODE',
                    value: this.model.get('bankCode'),
                },
                {
                    name: 'ABA',
                    value: this.model.get('aba'),
                },
                {
                    name: 'ACCOUNTFILTER',
                    value: this.model.get('accountFilter'),
                },
                {
                    name: 'ACCOUNTNUMBER',
                    value: accountFilterParts && accountFilterParts[1],
                },
                {
                    name: 'ACCOUNTTYPE',
                    value: this.model.get('accountType'),
                },
                {
                    name: 'ACCOUNTSUBTYPE',
                    value: this.model.get('accountSubType'),
                },
                {
                    name: 'TOALOCATIONNUMBER',
                    value: this.getLocationNumber(),
                },
                {
                    name: 'COMPANYID',
                    value: userInfo.get('group'),
                },
                {
                    name: 'USERID',
                    value: userInfo.get('id'),
                },
            ],
        };
    },

    /**
     * @method getCreateTransferPayload
     * @param  {object} [warningParam]
     * @description This will return the payload needed to the payment/transfer/multi/ADD endpoint
     * @returns {object} - payload
     */
    getCreateTransferPayload(warningParam) {
        const {
            toaAccountNumber,
            optAccountNumber,
            optAccountName,
            optAccountType,
            optCurrencyCode,
            optBankCode,
            todayDate,
            closingBalance,
        } = this.fcbResponse;
        return {
            functionCode: 'INST',
            setEntryMode: 'SINGLE',
            ...warningParam,
            items: [
                {
                    item: [
                        {
                            name: 'ACCOUNTFILTER',
                            value: this.model.get('accountFilter'),
                        },
                        {
                            name: 'ACCOUNT_TYPE',
                            value: this.model.get('accountType'),
                        },
                        {
                            name: 'TRAN_DATE',
                            value: todayDate,
                        },
                        {
                            name: 'ENTERED_AMOUNT',
                            value: closingBalance,
                        },
                        {
                            name: 'TYPE',
                            value: 'TRANSFER',
                        },
                        {
                            name: 'CURRENCYCODE',
                            value: optCurrencyCode,
                        },
                        {
                            name: 'TRANSACTION_AMOUNT',
                            value: closingBalance,
                        },
                        {
                            name: 'CREDIT_AMOUNT',
                            value: closingBalance,
                        },
                        {
                            name: 'DEBIT_AMOUNT',
                            value: closingBalance,
                        },
                        {
                            name: 'VALUE_DATE',
                            value: todayDate,
                        },
                        {
                            name: 'CREDIT_CURRENCY',
                            value: optCurrencyCode,
                        },
                        {
                            name: 'DEBIT_ACCOUNT_NUMBER',
                            value: toaAccountNumber,
                        },
                        {
                            name: 'DEBIT_BANK_CODE',
                            value: this.model.get('bankCode'),
                        },
                        {
                            name: 'BENE_BANK_CODE',
                            value: optBankCode,
                        },
                        {
                            name: 'BENE_ACCOUNT',
                            value: optAccountNumber,
                        },
                        {
                            name: 'BENE_ACCOUNT_TYPE',
                            value: optAccountType,
                        },
                        {
                            name: 'BENE_NAME',
                            value: optAccountName,
                        },
                        {
                            name: 'CMB_BENE_NAME',
                            value: optAccountName,
                        },
                        {
                            name: 'SPECIAL_INSTRUCTIONS',
                            value: 'CLOSETOA',
                        },
                        {
                            name: 'CUSTOMER_REFERENCE',
                            value: this.getLocationNumber(),
                        },
                    ],
                },
            ],
        };
    },

    /**
     * @method getLocationNumber
     * Will retrieve location number either from the model or the store when user navigates
     * from 'view another account' dropdown in transaction list view page
     * @return {String} - location number
     */
    getLocationNumber() {
        let locationNumber = this.model.get('toaLocationNumber');
        if (!locationNumber) {
            const location = store.get('btr:toaLocation');
            locationNumber = location.LOCATIONNUMBER;
        }
        return locationNumber;
    },

    /**
     * @method getCloseTOAInfo
     * Request to FCB's back-office system to request the account to be closed.
     * This will return the info to be displayed to the end user about the account to be closed
     */
    getCloseTOAInfo() {
        http.post(
            services.generateUrl(constants.CLOSE_TOA_SERVICE),
            this.getCloseTOAInfoPayload(),
            this.handleResponse.bind(this),
            this.handleError.bind(this),
        );
    },

    /**
     * @method handleResponse
     * @param {object} response - response of close TOA endpoint
     * Handle the success response from FCB's back-office system
     * Will render error message in case of false response status
     */
    handleResponse(response) {
        this.fcbResponse = response;
        const successResponse = this.fcbResponse.status === true;
        if (successResponse) {
            this.updateModalBody();
            this.modalButtonsState('enableAll', '.modal button');
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            this.handleTOAResponseError();
        }
    },

    /**
     * @method handleError
     * @param {object} err
     * @description Handles generic error
     */
    handleError(err) {
        const res = err.responseJSON;
        if (!res || (res && !res.confirms)) {
            this.handleTOAResponseError();
            return;
        }
        if (res.errorCode === dynamicPagesConstants.DUPLICATE_ERROR_CODE) {
            this.handleDuplicateWarning(res);
        } else if (res.resultType === 'WARNING') {
            this.handleGenericWarning(res);
        } else {
            this.displayAlertMessage(res);
        }
    },

    /**
     * @method displayAlertMessage
     * @description Helper method to display regular error messages
     * @param {object} res
     */
    displayAlertMessage(res) {
        const alertView = alert.danger(
            res.confirms.confirmResults[0].messages,
            {
                canDismiss: true,
                animate: true,
            },
        );
        this.alertsRegion.show(alertView);
        // Disable the submit btn and user should be able to cancel only
        this.resetAndDisableSubmitBtn();
    },

    /**
     * @method handleDuplicateWarning
     * @description Helper method to handle duplicate warning in the response
     * @param {object} res
     */
    handleDuplicateWarning(res) {
        /*
         * Get all responses that are duplicates so they can be
         * sent to the duplicate warning dialog
         */
        const model = new Model({});
        this.getCreateTransferPayload()
            .items[0].item.forEach((item) => {
                model.set(item.name, item.value);
            });
        this.collection.add(model);
        res.confirms.confirmResults = res.confirms.confirmResults.filter(confirmResults => confirmResults.resultType === 'DUPLICATEWARNING');
        if (!this.tempModel && this.model) {
            this.tempModel = this.model;
            this.tempModel.set('items', this.collection);
            Dialog.close();
        }
        const duplicateDialog = new DuplicateDialog({
            resp: res,
            modelsArray: this.tempModel,
            isTransfer: true,
            methodName: 'INSERT',
        });
        duplicateDialog.once(
            'saveTransfers',
            () => this.modifyWithWarning(),
        );
        duplicateDialog.once('cancelWarning', this.cancelWarning, this);
        Dialog.close();
        Dialog.custom(duplicateDialog);
    },

    /**
     * @method handleGenericWarning
     * @description Helper method to handle general warning messages in the response
     * @param {object} res
     */
    handleGenericWarning(res) {
        if (!this.tempModel && this.model) {
            this.tempModel = this.model;
            Dialog.close();
            this.listenTo(
                this.tempModel, 'modelAction:modifyWithWarning',
                () => this.modifyWithWarning({ saveWithWarning: true }),
            );
        }
        Dialog.custom(new WarningDialog({
            model: this.tempModel,
            methodName: 'MODIFY',
            store,
            detailPage: this,
            confirms: res.confirms,
        }));
    },

    /**
     * @method handleTOAResponseError
     * @description Updates the modal on close TOA response error.
     * This method will disable the submit btn, update the modal title, and update the view model to
     * display the error message
     */
    handleTOAResponseError() {
        this.resetAndDisableSubmitBtn();
        this.trigger('dialog:title:change', locale.get('toa.UnableCloseAccount'));
        this.model.set('closeToaResponseError', true);
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * @method resetAndDisableSubmitBtn
     * @description Resets the state of the modal btns and disable the submit btn only
     */
    resetAndDisableSubmitBtn() {
        this.modalButtonsState('enableAll', '.modal button');
        this.modalButtonsState('disableAll', '.modal-footer button.btn-primary');
    },

    /**
     * @method handleTransferSuccess
     * @param {object} response - response of a success TOA transfer
     * On transfer creation success, close the modal and display success message
     * above the transaction list view page
     */
    handleTransferSuccess(response) {
        Dialog.close();
        this.options.onToaTransferSuccess({
            action: 'save',
            response,
        });
    },

    /**
     * @method createSingleTransfer
     * @param  {object} [warningParam]
     * @description Creates single transfer from the info returned from the FCB's back-office system
     */
    createSingleTransfer(warningParam) {
        http.post(
            services.generateUrl('payment/transfer/multi/ADD'),
            this.getCreateTransferPayload(warningParam),
            this.handleTransferSuccess.bind(this),
            this.handleError.bind(this),
        );
    },

    /**
     * @method modifyWithWarning
     * @param {object} [warningParam]
     * @description force creating transfer after displaying warning message
     */
    modifyWithWarning(warningParam) {
        this.createSingleTransfer(warningParam);
    },

    /**
     * @method modalButtonsState
     * @param {'disableAll'|'enableAll'} status
     * @param {string} selector
     * @description Updates the modal buttons state
     */
    modalButtonsState(status, selector) {
        this.trigger(`dialog:buttons:${status}`, selector);
    },

    cancel() {
        Dialog.close();
    },

    submit() {
        this.modalButtonsState('disableAll', '.modal button');
        this.createSingleTransfer();
    },
});

export default CloseToaModal;
