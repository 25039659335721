import util from '@glu/core/src/util';
import store from 'system/utilities/cache';

const BTRUtil = {

    /*
     *  @method: saveTieredLoansTotalsColumns
     *  Stores the totals columns for the tiered loans details, since that view
     *  shows totals.  Navigating from one type of account to another
     *  in the account dropdown requires this context.
     */
    saveTieredLoansTotalsColumns(accountSectionsCollection, sectionId) {
        const loansSection = accountSectionsCollection.get(sectionId);
        if (util.isNullOrUndefined(loansSection)) {
            return;
        }

        const amountColumn = loansSection.get('totalsColumn1');
        const availableColumn = loansSection.get('totalsColumn2');
        const field1Label = loansSection.get('totalsColumn1Label');
        const field2Label = loansSection.get('totalsColumn2Label');
        const totals = {};

        if (!util.isNullOrUndefined(amountColumn)) {
            totals.field1 = {
                amountColumn,
                amountLabel: field1Label,
            };
        }

        if (!util.isNullOrUndefined(availableColumn)) {
            totals.field2 = {
                amountColumn: availableColumn,
                amountLabel: field2Label,
            };
        }
        store.set('tieredLoanTotals', totals);
    },
};
export default BTRUtil;
