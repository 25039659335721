var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"textVisible":true,"className":"btn btn-tertiary","action":"account-transfer","locale":"cashflow.account.transfers","name":"paginate-next"},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":157}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.account.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":48}}}))
    + "</h2>\n\n        <div class=\"list-container\">\n                <table class=\"table table-condensed\">\n                        <thead>\n                                <tr>\n                                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.applied.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":56},"end":{"line":8,"column":93}}}))
    + "</th>\n                                        <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.threshold",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":56},"end":{"line":9,"column":87}}}))
    + "</th>\n                                </tr>\n                        </thead>\n                        <tbody>\n                        </tbody>\n                </table>\n        </div>\n\n        <div class=\"controls view-mode\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTransferEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "                "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"textVisible":true,"className":"btn btn-tertiary","action":"edit-thresholds","locale":"cashflow.thresholds.edit","name":"pencil"},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":143}}}))
    + "\n        </div>\n\n        <div class=\"controls edit-mode\">\n                <button data-action=\"cancel-edit\" class=\"btn btn-tertiary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":75},"end":{"line":25,"column":107}}}))
    + "</button>\n                <button data-action=\"save-edit\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.save.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":72},"end":{"line":26,"column":102}}}))
    + "</button>\n\n        </div>\n";
},"useData":true});