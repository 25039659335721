/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import { withStyles } from '@glu/theming';
import { Dropdown } from '@glu/dropdown-react';
import { rowDataToDropdownOptions2, responseToScreenData } from 'pcm/common/util/responseJSONParser';
import requestContract from './requestContract';
import styles from './UserGroupDropdown.styles';

const constants = {
    dropdownUrl: '/admin-services/api/tableMaintenance/getLookupResults',
};

const getPayloadBankName = payload => ({
    ...payload,
});

const UserGroupDropdown = props => {
    const [dropdownList, setDropdownList] = useState([]);
    const [preSelected, setPreselected] = useState([]);

    const onDropdownDataLoaded = useCallback(data => {
        const allCheckRangeNumberData = responseToScreenData(data);
        const newDropdownList = rowDataToDropdownOptions2({
            data: allCheckRangeNumberData,
            id: 'USERGROUP',
            name: ['USERGROUP'],
        });
        const { onLoad } = props;

        setDropdownList(newDropdownList);
        onLoad(allCheckRangeNumberData, newDropdownList);
    }, [props]);

    function getDropDownData(searchCriteria) {
        const payload = {
            ...getPayloadBankName(requestContract.getUserGroup),
            searchFields: [
                {
                    fieldValue: [
                        searchCriteria,
                    ],
                    fieldName: 'USERGROUP',
                    operator: 'LIKE',
                    dataType: '',
                },
            ],
        };

        http.post(constants.dropdownUrl, payload).then(response => {
            onDropdownDataLoaded(response);
        });
    }

    useEffect(() => {
        getDropDownData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFilter = filter => {
        getDropDownData(filter);
        return filter;
    };


    const getDropdownOptions = () => ({
        placeholder: props.placeholder || locale.get('checkManagement.Select'),
        options: dropdownList || [],
        defaultValue: props.selected,
    });


    const onChange = (fieldName, value) => {
        setPreselected(value.map(item => item.id));
        if (value && value.length) {
            props.onChange(value);
        }
    };

    const {
        name, label, required, validators, disabled,
    } = props;

    return (
        <React.Fragment>
            {dropdownList && (
                <Dropdown
                    {...getDropdownOptions()}
                    validators={validators}

                    name={name}
                    labelText={label}
                    required={required}
                    onChange={onChange}
                    preSelectedIds={preSelected}
                    onFilter={onFilter}
                    disabled={disabled}
                    className={required ? `${props.classes.userGroupDropdownRequired} pcm-usergroup` : `${props.classes.userGroupDropdown} pcm-usergroup`}
                />)}
        </React.Fragment>
    );
};

UserGroupDropdown.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    validators: PropTypes.shape({}),
    showDetails: PropTypes.bool,
    onLoad: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    placeholder: PropTypes.string,
};

UserGroupDropdown.defaultProps = {
    name: 'USERGROUP',
    label: '',
    required: false,
    validators: {
        name: '',
        required: false,
    },
    showDetails: true,
    disabled: false,
    onLoad: () => {},
    onChange: () => {},
    selected: undefined,
    placeholder: undefined,
};

export default withStyles(styles)(UserGroupDropdown);
