import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@glu/popover-react';
import { InfoCircleIcon } from '@glu/icons-react';
import useStyles from './LabelTextPopover.styles';

const propTypes = {
    labelText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    popoverContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
};

const LabelTextPopover = ({
    labelText,
    popoverContent,
}) => {
    const classes = useStyles();
    return (
        <span className={classes.wrapper}>
            {labelText}
            <span
                className={classes.popoverWrapper}
            >
                <Popover
                    content={popoverContent}
                    openOnHover
                >
                    <span
                        data-qa="popoverIcon"
                    >
                        <InfoCircleIcon />
                    </span>
                </Popover>
            </span>
        </span>
    );
};
LabelTextPopover.propTypes = propTypes;
export default LabelTextPopover;
