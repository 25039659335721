import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
// import Promise from 'glu/promise'; // TODO: how are we handling this now?
import userInfo from 'etc/userInfo';
import { getComponentByIdAsView, getUtilById } from 'no-paymode!common/paymodeIntegration/paymodeIntegration';
import tpl from './paymodePayerDividendsView.hbs';

const PaymodePayerDividendsView = Layout.extend({

    tabTitle: 'widget.pmx-reports.payer-dividend.description',

    template: tpl,

    className: 'ui-widget field-container pmx-field-container',

    initialize() {
        this.model = new Model();
    },

    onRender() {
        Promise.all([
            getComponentByIdAsView('reports.payerDividends'),
            getUtilById('reports.payerDividendsStyles'),
        ]).then(this.handlePayerDividendsComponent.bind(this));
    },

    /**
     * handlePayerDividendsComponent
     *
     * @results array - results from Promise.all call
     */
    handlePayerDividendsComponent(results) {
        const PayerDividendsReportView = results[0];
        const payerDividendsView = new PayerDividendsReportView({
            companyId: userInfo.getMembershipID(),
        });

        this.regionManager.addRegion('pmxpayerDividendsRegion', '.pmx-payer-dividends');

        this.pmxpayerDividendsRegion.show(payerDividendsView);
    },

});

export default PaymodePayerDividendsView;
