import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import { postData } from 'common/util/services';

export default Model.extend({
    /**
     * @param {string} method
     * @param {Object} model
     * @param {Object} options
     * @returns {Promise}
     */
    sync(method, model, options) {
        const data = this.getRequestData(model, options);

        return postData(this.getServiceURL(), data);
    },

    /**
     * @abstract
     */
    getServiceURL() {
        throw new Error('getServiceURL not implemented');
    },

    /**
     * @abstract
     */
    getRequestData() {
        throw new Error('getRequestData not implemented');
    },

    /**
     * Iterate over every attribute and check for validation error to
     * determine if the model is ready for submission
     */
    isReadyForSubmission() {
        return util.every(this.attributes, this.hasValidationErrors.bind(this));
    },

    /**
     * Check the attribute to see if there are any validation errors
     * @param {*} value
     * @param {string} fieldName
     * @returns {boolean} Whether or not this attribute has validation errors
     */
    hasValidationErrors(value, fieldName) {
        const results = this.getValidationResultsByFieldName(fieldName);
        return results.errors === undefined;
    },
});
