export default {
    messageId: {
        paddingBottom: '5px',
        '& span': {
            fontSize: '10px',
            paddingLeft: '5px',
        },
    },

    messageBody: {
        marginLeft: '50px',
        padding: '4px 8px 0 8px',
        marginRight: '20px',
        marginBottom: '30px',

        '& p': {
            whiteSpace: 'pre-wrap',
            paddingTop: '4px',
            marginBottom: '0',
        },
    },

    messageBodyRight: {
        backgroundColor: '#336699',
        borderRadius: '15px 15px 0 15px',
        color: '#fff',
        '& p:nth-child(2)': {
            textAlign: 'right',
        },
    },

    messageBodyLeft: {
        backgroundColor: '#fff',
        borderRadius: '15px 15px 15px 0',
        color: '#000',
        marginLeft: '20px',

        '& p:nth-child(2)': {
            textAlign: 'left',
        },
    },
};
