var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":35,"column":35},"end":{"line":35,"column":47}}}) : helper)))
    + "\"\n                    data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":36,"column":33},"end":{"line":36,"column":46}}}) : helper)))
    + "\"\n                    name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":37,"column":26},"end":{"line":37,"column":39}}}) : helper)))
    + "\"\n                    type=\"button\">\n                    <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":39,"column":38},"end":{"line":39,"column":49}}}) : helper)))
    + "</span>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.import",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":54}}}))
    + "</h1>\n</div>\n<div class=\"alert-message\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n</div>\n<form name=\"importform\" id=\"importform\" enctype=\"multipart/form-data\" method=\"POST\" target=\"_blank\">\n\n          <section class=\"section section-container\">\n\n        <div class=\"row\">\n        <div class=\"form-column col-md-12\"><h4 class=\"form-group-label \" role=\"presentation\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.file.section",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":93},"end":{"line":12,"column":122}}}))
    + "</h4>\n        <div class=\"checkbox field-container-lg field-container\">\n                    <input type=\"checkbox\" name=\"TESTMODE\" id=\"TESTMODE\" data-bind=\"model\">\n                            <label for=\"TESTMODE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.testMode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":50},"end":{"line":15,"column":75}}}))
    + "</label>\n        </div>\n    <div class=\"required field-container-lg field-container\">\n            <label for=\"FILESELECTION\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.select.importfile",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":39},"end":{"line":18,"column":73}}}))
    + "</label>\n            <input class=\"form-control \" type=\"file\" maxlength=\"255\" data-bind=\"model\" id=\"FILESELECTION\" name=\"FILESELECTION\" value=\"\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"FILESELECTION\"></span>\n        <div class=\"progress hide\">\n            <div class=\"progress-bar progress-bar-striped active\" role=\"progressbar\" aria-valuenow=\"0\" aria-valuemin=\"0\" aria-valuemax=\"100\" style=\"width: 0%\">\n                0%\n            </div>\n        </div>\n        </div></div></div>\n\n        </section>\n</form>\n\n<div class=\"section section-summary\">\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":41,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});