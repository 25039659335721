import Model from '@glu/core/src/model';

export default Model.extend({
    defaults: {
        rows: [],
        action: 'bulkUpdate',
        actionType: null,
        value: null,
    },
});
