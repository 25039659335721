import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import gluStore from '@glu/store';
import { FormContextProvider } from '@glu/validation-react';
import PtxUserGroupsDropdown from 'pcm/common/components/UserGroupDropdown';

const propTypes = {
    validate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    callback: PropTypes.func,
};

const defaultProps = {
    callback: () => {},
};

const StepUserWrapper = ({
    validate,
    callback,
    onChange,
}) => {
    const onCancel = useCallback(() => 'onCancel', []);
    const onSubmit = useCallback((event) => {
        if (event) {
            event.preventDefault();
        }

        return validate().then(({ isValid, data }) => {
            const { USER_GROUP } = data;
            if (isValid && USER_GROUP && USER_GROUP[0]) {
                gluStore.set('ptxUserGroup', USER_GROUP[0].id);
                callback(USER_GROUP[0].id);
            }
        });
    }, [callback, validate]);

    return (
        <div>
            <form className="form" onSubmit={onSubmit}>
                <fieldset>
                    <legend className="sr-only" />

                    <div className="row">
                        <div className="col-4 padding-right-20">

                            <PtxUserGroupsDropdown
                                name="USER_GROUP"
                                label={locale.get('ptxAdmin.userGroup')}
                                onChange={(data) => {
                                    onChange('USER_GROUP', data[0].id);
                                }}
                                validators={{
                                    name: locale.get('ptxAdmin.userGroup'),
                                    required: true,
                                }}
                            />
                        </div>
                    </div>

                    <div className="row mt20">
                        <div className="actions col-12">
                            <button type="submit" className="btn btn-primary save">{locale.get('button.continue')}</button>
                            <button type="button" className="btn btn-secondary cancel" onClick={onCancel}>{locale.get('cancel')}</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

StepUserWrapper.propTypes = propTypes;
StepUserWrapper.defaultProps = defaultProps;

const StepUserWrapperRoot = props => (
    <FormContextProvider>
        {({ validate, onChange }) => <StepUserWrapper {...props} validate={validate} onChange={onChange} />}
    </FormContextProvider>
);

export default StepUserWrapperRoot;
