import Layout from '@glu/core/src/layout';
import Grid from '@glu/grid';
import InquiryApi from 'system/webseries/api/inquiry';
import InquiryRows from 'system/webseries/collections/inquiryRows';
import InquiryHeaders from 'system/webseries/collections/inquiryHeaders';
import adminAlertTmpl from './adminAlert.hbs';

export default Layout.extend({
    initialize(opts) {
        this.searchType = opts.searchType || InquiryApi.ACTION_CONFIGURED_SEARCH_TYPE;
        this.viewId = opts.viewId || InquiryApi.DEFAULT_VIEW_ID;
    },

    template: adminAlertTmpl,
    grid: undefined,

    onRender() {
        this.refreshGrid();
    },

    onClose() {
        if (this.grid) {
            this.grid.close();
        }
    },

    refreshGrid() {
        // Retrieve the metaData from the backend.
        const headerData = new InquiryHeaders({
            inquiryId: this.inquiryId,
            searchType: this.searchType,
            viewId: this.view,
        });
        headerData.fetch();

        // Retrieve the rows data from the inquiry service.
        const rowsData = new InquiryRows({
            inquiryId: this.inquiryId,
            searchType: this.searchType,
            viewId: this.view,
        });
        rowsData.fetch();

        // We are going to have issues selecting rows across multiple pages.
        const defaultOptions = {
            collection: rowsData,
            columns: headerData,
            filter: false,
            paginate: true,
            pageSize: 10,
        };

        this.grid = new Grid(defaultOptions);

        this.tableContent.show(this.grid);
    },
});
