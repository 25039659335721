import Layout from '@glu/core/src/layout';
import 'jui/slider';
import toggleButtonsTmpl from './toggleButtons.hbs';

const ToggleButtons = Layout.extend({
    initialize() {

    },

    template: toggleButtonsTmpl,
    className: 'settings-buttons',

    collectionEvents: {
        'change:forecastAlert reset sync': 'render',
    },

    templateHelpers() {
        const alertAccounts = this.collection.filter(account => account.has('minimumBalanceDate') && account.get('minimumBalanceDate') !== null);

        return {
            forecastAlerts: alertAccounts.length,
        };
    },
});

export default ToggleButtons;
