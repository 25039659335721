var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-warning\">\n    <form class=\"modal-content\" data-submit=\"save\">\n        <div class=\"modal-header\">\n            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"close","text":"Close","name":"close"},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":80}}}))
    + "\n            <h2 class=\"modal-title\">\n                <span class=\"icon-exclamation-solid\"></span>\n                <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":31}}}) : helper)))
    + "</span>\n            </h2>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"form-group\">\n               <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":12,"column":18},"end":{"line":12,"column":29}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"okLabel") || (depth0 != null ? lookupProperty(depth0,"okLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"okLabel","hash":{},"data":data,"loc":{"start":{"line":16,"column":58},"end":{"line":16,"column":69}}}) : helper)))
    + "</button>\n            <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":79},"end":{"line":17,"column":105}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});