import CompositeView from '@glu/core/src/compositeView';
import PlanView from './planItem';
import planSelectTmpl from './planSelect.hbs';

const PlanSelect = CompositeView.extend({
    template: planSelectTmpl,
    itemViewContainer: 'tbody',
    itemView: PlanView,

    attributes: {
        'aria-controls': '#plan-graph',
    },

    className: 'show-view-mode',

    ui: {
        $editButton: '.btn[data-action="edit-plans"]',
        $editPlanLink: '.edit-plan-link',
    },

    events: {
        'click @ui.$editButton': 'toggleEditMode',
        'click @ui.$editPlanLink': 'editCashFlowPlans',
    },

    collectionEvents: {
        reset: 'render',
    },

    initialize() {
        this.editMode = false;
    },

    toggleEditMode() {
        // place holder for handling edit as a button rather than a link.
    },

    editCashFlowPlans() {
        this.navigateTo('default#plan');
    },
});

export default PlanSelect;
