import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@glu/indicators-react';
import useCache from 'hooks/useCache';
import useNavigation from 'hooks/useNavigation';
import AlertContext from '../AlertWrapper/AlertContext';
import {
    getAlertGroups,
    getAlert,
    getLocales,
    getMarketSegments,
    getParameters,
    insertAlert,
    updateAlert,
} from '../services';
import { MODE } from '../constants';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const STEP = {
    SUMMARY: 'SUMMARY',
    DETAIL: 'DETAIL',
};

const Alert = ({
    ...props
}) => {
    const {
        mode,
        setAlertGroupOptions,
        setLocaleOptions,
        setAlertData,
        setAlertParameters,
        setMarketSegmentOptions,
        modelData,
    } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(mode === MODE.INSERT ? STEP.SUMMARY : STEP.DETAIL);
    const StepComponent = step === STEP.SUMMARY ? StepOne : StepTwo;
    const nextStep = (alertType, locale) => {
        Promise.all([
            getAlert({
                ALERTTYPEID: alertType,
                LOCALEID: locale,
            }),
            getParameters({
                ALERTTYPEID: alertType,
                LOCALEID: locale,
            }),
        ]).then(([alertResp, parametersResp]) => {
            setAlertData(alertResp);
            setAlertParameters(parametersResp);
            setStep(STEP.DETAIL);
        });
    };
    const { navigateTo } = useNavigation(props.returnRoute);
    const { set: setAlertmessage } = useCache('alertContentManagement_alerts-alertMessage');
    const { set: setConfirmResponse } = useCache('alertContentManagement_alerts-confirms');
    const save = useCallback((data) => {
        const saveAlert = mode === MODE.MODIFY ? updateAlert : insertAlert;
        saveAlert(data).then((resp) => {
            setAlertmessage('insert');
            setConfirmResponse(resp);
            navigateTo(props.returnRoute);
        });
    }, [mode, navigateTo, props.returnRoute, setAlertmessage, setConfirmResponse]);

    useEffect(() => {
        // Each mode requires a different set of initial data, fetch what is appropriate
        switch (mode) {
        case MODE.INSERT:
            Promise.all([
                getAlertGroups().then(setAlertGroupOptions),
                getLocales().then(setLocaleOptions),
            ]).then(() => {
                setIsLoading(false);
            });
            break;
        case MODE.MODIFY:
        case MODE.COPY:
            Promise.all([
                getAlert(modelData),
                getMarketSegments({
                    ...modelData,
                    mode,
                }),
                getParameters({
                    ALERTTYPEID: modelData.ALERTTYPEID,
                    LOCALEID: modelData.LOCALEID,
                }),
            ]).then(([alertResp, marksegmentResp, parametersResp]) => {
                setMarketSegmentOptions(marksegmentResp);
                setAlertData({
                    ...alertResp,
                    // Don't preselect marketsegements in COPY mode
                    MARKETSEGMENTS: mode === MODE.MODIFY ? alertResp.MARKETSEGMENTS : undefined,
                    CONTENTTYPE_DESC: modelData.CONTENTTYPE,
                    ALERTGROUP_DESC: modelData.ALERTGROUPDESCRIPTION,
                    ALERTTYPE_DESC: modelData.ALERTTYPE,
                    LOCALE_DESC: modelData.LOCALE,
                    STATUS: modelData.STATUS,
                });
                setAlertParameters(parametersResp);
                setIsLoading(false);
            });
            break;
        default:
            setIsLoading(false);
            break;
        }
    }, [
        mode,
        modelData,
        setAlertData,
        setAlertGroupOptions,
        setAlertParameters,
        setLocaleOptions,
        setMarketSegmentOptions,
    ]);
    return (
        <Loading isLoading={isLoading}>
            <StepComponent
                nextStep={nextStep}
                onSave={save}
                {...props}
            />
        </Loading>
    );
};

Alert.propTypes = {
    returnRoute: PropTypes.string.isRequired,
};

export default Alert;
