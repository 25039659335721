import util from '@glu/core/src/util';
import AssignedAccountsView from '../../payments/assignedAccounts/assignedAccounts';

export default AssignedAccountsView.extend({
    initialize(options) {
        const superOptions = {
            entitledIndex: 0,
            mode: this.options.mode,
            model: this.model,
            paymentTypeGroup: this.options.paymentTypeGroup,
            userGroup: this.options.userGroup,
            entitledTypes: this.options.entitledTypes,
            isRole: this.options.isRole,
        };
        AssignedAccountsView.prototype.initialize.call(this, util.extend(
            {},
            superOptions, options,
        ));
    },

    /**
     * Overrides the assignedAccounts method.
     * Copies data entitlements from REQOUT or REQIN
     * to the other.  Data entitlements are common
     * between both types.
     */
    updateAccountsForPermissions() {
        // Need to find the type that does not match the existing type.  There are only 2 types.
        const typeCode = this.model.get('typeCode');
        const model = this.model.collection.find(type => type.get('typeCode') !== typeCode);
        if (model) {
            const deModels = this.model.get('typeDataEntitlements').at(0).get('dataEntitlementsWithLimits').models;

            // Copy the data entitlements without raising any trigger events.
            model.get('typeDataEntitlements').at(0).get('dataEntitlementsWithLimits').reset(deModels, { silent: true });
        }
    },

    changeAssignAll(e) {
        AssignedAccountsView.prototype.changeAssignAll.call(this, e);
        const { checked } = e.target;
        const typeCode = this.model.get('typeCode');
        const model = this.model.collection.find(type => type.get('typeCode') !== typeCode);
        if (model) {
            model.get('typeDataEntitlements').at(0).set('assignAll', checked);
        }
    },
});
