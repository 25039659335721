import Layout from '@glu/core/src/layout';
import Grid from '@glu/grid';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import locale from '@glu/locale';
import contactUtil from 'app/bab/util/contactUtil';
import toolTipCell from 'common/dynamicPages/views/toolTipCellView';
import Transform from 'common/util/transform';
import userInfo from 'etc/userInfo';
import template from './itemsEffectedWarning.hbs';

export default Layout.extend({
    template,

    ui: {
        $paymentGridSection: '[data-hook="payments-effected-grid"]',
        $templateGridSection: '[data-hook="templates-effected-grid"]',
    },

    initialize(options) {
        this.model = options.model;
        this.actionMode = options.actionMode;
        this.selectedArray = options.selectedArray;
        this.isSMB = userInfo.isSmbUser();
        const result = options.resp;
        const affectedPayments = [];
        const affectedTemplates = [];
        const orderingPartyMessage = locale.get('PAY.orderingparty.contact');

        this.warningLabels = {
            paymentsOnly: {
                title: 'PS.effectedItemWarningTitle.paymentsOnly',
                paymentText: 'PS.effectedPayments',
                beneLabel: 'PAY.Beneficiary',
                dateLabel: 'PAY.ValueDate',
            },
            paymentRequestOnly: {
                title: 'PS.effectedItemWarningTitle.paymentRequestOnly',
                paymentText: 'PS.effectedPayments.paymentRequestOnly',
                beneLabel: 'PAY.Payer',
                dateLabel: 'PAY.DueOnDate',
            },
            paymentRequestsAndTemplate: {
                title: 'PS.effectedItemWarningTitle.paymentRequestsAndTemplate',
                paymentText: 'PS.effectedPayments.paymentRequestOnly',
                beneLabel: 'PAY.Payer',
                dateLabel: 'PAY.DueOnDate',
            },
            paymentAndPaymentRequests: {
                title: 'PS.effectedItemWarningTitle.paymentAndPaymentRequests',
                paymentText: 'PS.effectedPayments.paymentAndPaymentRequests',
                beneLabel: 'PAY.BeneficiaryPayer',
                dateLabel: 'PAY.ValueDueOnDate',
            },
            paymentsAndTemplates: {
                title: 'PS.effectedItemWarningTitle.paymentsAndTemplates',
                paymentText: 'PS.effectedPayments',
                beneLabel: 'PAY.Beneficiary',
                dateLabel: 'PAY.ValueDate',
            },
            paymentsAndPaymentRequestsAndTemplates: {
                title: 'PS.effectedItemWarningTitle.all',
                paymentText: 'PS.effectedPayments.paymentAndPaymentRequests',
                beneLabel: 'PAY.BeneficiaryPayer',
                dateLabel: 'PAY.ValueDueOnDate',
            },
            templatesOnly: {
                title: 'PS.effectedItemWarningTitle.templatesOnly',
                paymentText: 'PS.effectedTemplates',
                beneLabel: 'PAY.Beneficiary',
                dateLabel: 'PAY.ValueDate',
            },
        };

        /*
         * Build out the list of payments and templates that we need to display in
         * the grid(s)
         */
        result.confirms.confirmResults.forEach((confirmResult) => {
            let payments;
            let templates;
            if (confirmResult.additionalData) {
                const {
                    payments: paymentsResult,
                    templates: templatesResult,
                } = confirmResult.additionalData.reduce((acc, cur) => {
                    const paymentCodes = ['INST', 'REQUEST', 'BATCH', 'PAY_BCAA'];
                    const templateCodes = ['TMPL', 'REQTMPL', 'BHTMPL', 'TEMP_BCAB'];
                    const functionCode = cur.item.find(item => item.name === 'FUNCTIONCODE')?.value;
                    const nextState = {
                        payments: paymentCodes.includes(functionCode)
                            ? [...acc.payments, cur] : acc.payments,
                        templates: templateCodes.includes(functionCode)
                            ? [...acc.templates, cur] : acc.templates,
                    };
                    return nextState;
                }, { payments: [], templates: [] });
                payments = paymentsResult;
                templates = templatesResult;
                this.buildItemList(payments, affectedPayments);
                this.buildItemList(templates, affectedTemplates);
            }

            const itemTypes = this.getItemTypes(payments, templates);
            this.warningType = contactUtil.getRelatedItemTypes(itemTypes);
        });

        // If we have effected payments we need to build a grid to display them
        if (affectedPayments.length > 0) {
            const paymentColumns = [{
                label: locale.get('PAY.ID'),
                field: 'USERGROUPSEQUENCENUMBER',
            }, {
                label: locale.get(this.warningLabels[this.warningType].beneLabel),
                field: 'BENE_NAME',
                cellView: toolTipCell,
                tooltipCol: 'ORDERINGPARTYMESSAGE',
            }, {
                label: locale.get('PAY.PaymentType'),
                field: 'TYPEDESCRIPTION',
            }, {
                label: locale.get(this.warningLabels[this.warningType].dateLabel),
                field: 'VALUE_DATE',
            }, {
                label: locale.get('PAY.lastUpdateTime'),
                field: 'LAST_ACTION_TIME',
            }, {
                label: locale.get('PAY.Status'),
                field: 'STATUS',
            }];

            const paymentGridData = new Collection(affectedPayments
                .map(entry => Transform.pairsToHash(entry.item)));

            util.each(paymentGridData.models, (model) => {
                if (model.get('ORDERINGPARTYUPDATED') === 'TRUE') {
                    model.set('ORDERINGPARTYMESSAGE', orderingPartyMessage);
                }
            });

            // initialize the payments grid
            this.paymentGrid = new Grid({
                collection: paymentGridData,
                columns: paymentColumns,
            });
        }

        // If we have effected templates we need to build a grid to display them
        if (affectedTemplates.length > 0) {
            const templateColumns = [{
                label: locale.get('PAY.TemplateCode'),
                field: 'TEMPLATE_CODE',
            }, {
                label: locale.get('PAY.TemplateDescription'),
                field: 'TEMPLATE_DESCRIPTION',
            }, {
                label: locale.get('PAY.Beneficiary'),
                field: 'BENE_NAME',
                cellView: toolTipCell,
                tooltipCol: 'ORDERINGPARTYMESSAGE',
            }, {
                label: locale.get('PAY.PaymentType'),
                field: 'TYPEDESCRIPTION',
            }, {
                label: locale.get('PAY.lastUpdateTime'),
                field: 'LAST_ACTION_TIME',
            }, {
                label: locale.get('PAY.Status'),
                field: 'STATUS',
            }];

            const templateGridData = new Collection(affectedTemplates
                .map(entry => Transform.pairsToHash(entry.item)));

            util.each(templateGridData.models, (model) => {
                if (model.get('ORDERINGPARTYUPDATED') === 'TRUE') {
                    model.set('ORDERINGPARTYMESSAGE', orderingPartyMessage);
                }
            });

            // initialize the templates grid
            this.templateGrid = new Grid({
                collection: templateGridData,
                columns: templateColumns,
            });
        }
    },

    /**
     * @param {Array} elements (Array of objects that contain payment or template data)
     * Use the payment type code to find the correct type description that an SMB
     * user would expect to see.
     */
    resolvePaymentTypeForSMB(elements) {
        let typeDescription;
        const data = {
            BDACHCVP: 'SMBPAY.StdPaymentsACH',
            FEDWIRE: 'SMBPAY.ExpFedWire.NoFee',
            INTL: 'SMBPAY.ExpIntlWire.NoFee',
            BDACHCRC: 'SMBPAY.StdCollectionsACH',
            BDACHCEC: 'SMBPAY.StdCollectionsACH',
            BDACHCP: 'SMBPAY.StdCollectionsACH',
        };

        elements.forEach((elementParam) => {
            const element = elementParam;
            const typeIndex = element.item.findIndex(item => item.name === 'TYPE');
            const typeDescIndex = element.item.findIndex(item => item.name === 'TYPEDESCRIPTION');

            if (element.item[typeIndex]) {
                typeDescription = data[element.item[typeIndex].value];
            }
            element.item[typeDescIndex].value = typeDescription
                ? locale.get(typeDescription) : element.item[typeDescIndex].value;
        });
    },

    /**
     * @param {Array} items (List of payments or templates received from a service result)
     * @param {Array} itemList (Array containing all of the payments or templates
     * from every service result)
     * Make any necessary changes to the values returned from the server and
     * collect them into a single array.
     */
    buildItemList(items, itemList) {
        if (items) {
            if (this.isSMB) {
                this.resolvePaymentTypeForSMB(items);
            }
            itemList.push(...items);
        }
    },

    onRender() {
        if (this.paymentGrid) {
            this.ui.$paymentGridSection.append(this.paymentGrid.render().el);
        }

        if (this.templateGrid) {
            this.ui.$templateGridSection.append(this.templateGrid.render().el);
        }
    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
        'data-backdrop': 'static',
    },

    save() {
        this.close();
        if (this.actionMode === 'MODIFY') {
            this.trigger(
                'saveContact',
                {
                    warningName: constants.CONTACT_CHANGES_ACCEPTED_INDICATOR,
                },
            );
        } else if (this.actionMode === 'APPROVE') {
            this.model.set(constants.CONTACT_CHANGES_ACCEPTED_INDICATOR, 'true');
            this.trigger(
                'approveContact',
                {
                    model: this.model,
                },
            );
        } else if (this.actionMode === 'MULTICHANGEWARNING-DELETE' || this.actionMode === 'MULTICHANGEWARNING-APPROVE') {
            this.trigger(
                'multiWarning',
                this.actionMode,
                this.selectedArray,
                {
                    name: constants.CONTACT_CHANGES_ACCEPTED_INDICATOR,
                    value: 'true',
                },
            );
        } else if (this.actionMode === 'DELETE') {
            this.model.set(constants.CONTACT_CHANGES_ACCEPTED_INDICATOR, 'true');
            this.trigger(
                'deleteContact',
                {
                    model: this.model,
                },
            );
        }
    },

    cancel() {
        if (this.actionMode === 'DELETE' || this.actionMode === 'MULTICHANGEWARNING-DELETE') {
            this.trigger('cancelDeleteContact');
        }

        this.close();
    },

    isEntitledTo(dataSet = [], entitlementsToCheck = []) {
        return dataSet.some((cur) => {
            const functionCode = cur.item.find(item => item.name === 'FUNCTIONCODE')?.value;
            return entitlementsToCheck.includes(functionCode);
        });
    },

    getItemTypes(payments, templates) {
        const templateEntitlements = ['TMPL', 'BHTMPL'];
        const paymentEntitlements = ['INST', 'BATCH'];
        const rfpTemplateEntitlements = ['REQTMPL'];
        const rfpEntitlements = ['REQUEST'];

        return {
            isCompanyEntitledToTemplates: this.isEntitledTo(templates, templateEntitlements),
            isCompanyEntitledToPayments: this.isEntitledTo(payments, paymentEntitlements),
            isCompanyEntitledToRFPTemplates: this.isEntitledTo(templates, rfpTemplateEntitlements),
            isCompanyEntitledToRFP: this.isEntitledTo(payments, rfpEntitlements),
        };
    },

    templateHelpers() {
        return {
            hasPaymentGrid: this.paymentGrid,
            hasTemplateGrid: this.templateGrid,
            hasBothGrids: this.paymentGrid && this.templateGrid,
            isApprove: this.actionMode === 'APPROVE' || this.actionMode === 'MULTIAPPROVECHANGEWARNING',
            warningTitle: locale.get(this.warningLabels[this.warningType].title),
            paymentsText: locale.get(this.warningLabels[this.warningType].paymentText),
        };
    },
});
