import React from 'react';
import PropTypes from 'prop-types';

export default function PageSelectorStartEnd({
  className, classes, enableEllipsis, onChange, page
}) {
  const { pageEllipsis, paginationButton } = classes;
  const testId = page === 0 ? 'pagination-startIndicator' : 'pagination-endIndicator';
  const isLast = page !== 0;

  return (
    <span data-qa={testId} className={className}>
      {isLast && enableEllipsis && <span className={pageEllipsis}>&hellip;</span>}
      <button
        data-qa={`pagination-${isLast ? 'last' : 'first'}-page`}
        className={paginationButton}
        type="button"
        onClick={onChange(page, true)}
      >
        {page + 1}
      </button>
      {!isLast && enableEllipsis && <span className={pageEllipsis}>&hellip;</span>}
    </span>
  );
}

PageSelectorStartEnd.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  enableEllipsis: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
};

PageSelectorStartEnd.defaultProps = {
  className: ''
};
