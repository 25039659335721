/* istanbul ignore file */
export default function ashTheme({ palette, typography, transitions }) {
  return {
    headerBorder: `3px solid ${palette.grey.G300}`,
    cellPadding: '1em 0',
    noContentText: palette.grey.G600,
    footerBackground: palette.grey.G200,
    footerPadding: [10, 0],
    footerBoxShadow: '0px 0px 15px -5px rgb(0,0,0,0.5)',
    footerBottom: 0,
    footerZIndex: 2,
    actionControl: {
      icon: {
        fontFamily: typography.fontFamily
      },
      popover: {
        background: palette.background,
        backgroundOpen: palette.background,
        boxShadow: `${palette.form.border} 0 2px 10px`
      },
      borderColor: palette.form.border,
      borderColorHover: palette.primary.normal,
      footerBackground: palette.grey.G200,
      transition: `all ${transitions.duration.standard}ms ${transitions.easing.easeInOut}`
    },
    datarow: {
      expandWidth: 32,
      contentTransition: `transform ${transitions.duration.complex}ms ${transitions.easing.easeInOut}`,
      subCellsPadding: '1em 0',
      expandIconFill: palette.primary.normal
    },
    drawerPanel: {
      background: palette.background,
      fontFamily: typography.fontFamily,
      titleBackground: '#F7F7F7',
      titleBoxShadow: `${palette.form.border} 0px 1px 4px`,
      titleHeight: 35,
      footerHeight: 50,
      titleBoxSizing: 'content-box',
      titleAlignItems: 'center',
      titlePadding: '0 15px',
      marginBottom: 0
    },
    inputRadio: {
      fontFamily: typography.fontFamily,
      fontWeight: typography.fontWeightRegular,
      checkmarkBackground: palette.background,
      checkmarkBorder: palette.grey.G600,
      checkmarkBackgroundChecked: '#2196F3'
    },
    loadMore: {
      buttonTextColor: palette.primary.normal,
      buttonTextSize: 18,
      spanTextColor: palette.grey.G500,
      spanTextSize: 12,
      borderTop: `1px solid ${palette.form.border}`
    },
    primaryData: {
      fontWeight: typography.fontWeightRegular,
      fontSize: 16
    },
    focusColor: palette.focus.normal,
    savedViews: {
      position: 'relative',
      zIndex: 1
    }
  };
}
