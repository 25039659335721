import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useValidation } from '@glu/validation-react';
import Layout from '@glu/layout-react';
import useLocale from 'hooks/useLocale';
import { Button, PRIMARY, TERTIARY } from '@glu/buttons-react';
import { Dropdown } from '@glu/dropdown-react';
import Alert from '@glu/alerts-react';
import ActionButtonContainer from 'components/ActionButtonContainer/ActionButtonContainer';
import useNavigation from 'hooks/useNavigation';
import FormField from 'components/FormField/FormField';
import RequiredLabelText from 'components/RequiredLabelText/RequiredLabelText';
import FooterContext from '../FooterWrapper/FooterContext';
import { getFooterMarketSegments } from '../services';

const propTypes = {
    nextStep: PropTypes.func.isRequired,
    returnRoute: PropTypes.string.isRequired,
};

const StepOne = ({
    nextStep,
    returnRoute,
}) => {
    const { cancel } = useNavigation({
        returnRoute,
    });
    const { getLocaleString } = useLocale();
    const {
        localeOptions,
        footerData,
        setFooterData,
        setMarketSegmentOptions,
        mode,
    } = useContext(FooterContext);
    const [
        ValidationProvider,
        validationValue,
        { validate, data },
    ] = useValidation({
        initialData: footerData,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showVaildationAlert, setShowValidationAlert] = useState(false);

    const onContinue = () => {
        const locale = data.LOCALE[0]?.id;
        validate().then(({ isValid }) => {
            if (!isValid) {
                return Promise.reject();
            }
            setIsLoading(true);
            return getFooterMarketSegments({
                LOCALEID: locale,
                mode,
            });
        }).then((marksegmentResp) => {
            if (marksegmentResp.length) {
                setMarketSegmentOptions(marksegmentResp);
                return Promise.resolve();
            }
            return Promise.reject(true);
        }).then(() => {
            setFooterData({
                ...data,
                LOCALE_DESC: data.LOCALE?.[0]?.name,
            });
            nextStep(locale);
        })
            .catch((showAlert) => {
                setShowValidationAlert(showAlert);
                setIsLoading(false);
            });
    };
    return (
        <ValidationProvider value={validationValue}>
            {showVaildationAlert && (
                <section className="section section-container">
                    <Alert
                        variant="error"
                        message={getLocaleString('CMAINT.no.footer.segments')}
                        onHide={() => setShowValidationAlert(false)}
                    />
                </section>
            )}
            <div className="section section-container">
                <form>
                    <Layout container>
                        <Layout col={3}>
                            <FormField>
                                <Dropdown
                                    labelText={(
                                        <RequiredLabelText
                                            label={getLocaleString('CMAINT.Locale')}
                                        />
                                    )}
                                    name="LOCALE"
                                    validators={{
                                        name: getLocaleString('CMAINT.Locale'),
                                        required: true,
                                    }}
                                    options={localeOptions}
                                    htmlId="LOCALE"
                                    preselectFirstOption={localeOptions.length === 1}
                                />
                            </FormField>
                        </Layout>
                    </Layout>
                    <Layout container>
                        <ActionButtonContainer>
                            <Button
                                className="btn btn-primary"
                                variant={PRIMARY}
                                text={getLocaleString('button.continue')}
                                onClick={onContinue}
                                aria-busy={isLoading}
                                data-qa="continueButton"
                            />
                            <Button
                                variant={TERTIARY}
                                text={getLocaleString('button.cancel')}
                                onClick={cancel}
                                data-qa="cancelButton"
                            />
                        </ActionButtonContainer>
                    </Layout>
                </form>
            </div>
        </ValidationProvider>
    );
};
StepOne.propTypes = propTypes;
export default StepOne;
