import Layout from '@glu/core/src/layout';
import TotalsView from 'app/administration/common/totalsHeader/totalsView';

export default Layout.extend({
    /**
     * Create and loads the totals view
     */
    loadTotals() {
        this.totalsView = new TotalsView({
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: 'CIMINSTT',
            inquiryId: this.totalsInquiryId,
            filters: this.gridView.wrapper.generateFiltersDataBlock(),
            viewId: this.gridView.wrapper.viewId,
        });
        this.totalsSection.show(this.totalsView);
        this.refreshTotals();
    },

    /**
     * Refreshes the totals view on this page.
     */
    refreshTotals() {
        if (this.totalsView) {
            this.totalsView.refresh(
                this.gridView.wrapper.generateFiltersDataBlock(),
                this.gridView.wrapper.viewId,
            );
        }
    },
});
