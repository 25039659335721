/* istanbul ignore file */
export default ({ palette, typography }) => ({
    container: {
        marginBottom: 20,
    },
    label: {
        ...typography.label,
        color: palette.text.midGray,
        margin: '0 0 5px',
        paddingRight: 5,
    },
    checkboxLabel: {
        ...typography.text,
        fontSize: 14,
        marginLeft: 5,
        color: palette.text.dark,
        display: 'inline',
    },
    input: {
        backgroundColor: palette.background,
        border: `1px solid ${palette.form.border}`,
        borderRadius: 2,
        color: palette.text.dark,
        fontSize: 14,
        height: 34,
        padding: [6, 10],
        darkColor: palette.text.light,
        fontFamily: typography.fontFamily,
        fontWeight: typography.fontWeight,
        textareaHeight: 60,
        darkFocusColor: palette.focus.dark,
        focusColor: palette.focus.normal,

        dark: {
            backgroundColor: 'inherit',
        },
    },
    numberInput: {
        borderRadius: [2, 0, 0, 2],
    },
    numberFirstButton: {
        borderRadius: [0, 2, 0, 0],
    },
    numberLastButton: {
        borderRadius: [0, 0, 2, 0],
    },
    dark: {
        color: palette.text.light,
        error: {
            color: palette.colors.monaLisa,
            borderColor: palette.colors.monaLisa,
        },
        warning: {
            color: palette.colors.pumpkin,
            borderColor: palette.colors.pumpkin,
        },
    },
    checkbox: {
        checkColor: palette.text.light,
        checkBackground: palette.text.light,
        selectedBackground: palette.colors.tundora,
        shadowColor: palette.grey.G400,
        disabledColor: palette.text.midGray,
        fill: palette.grey.G800,
        selectedFill: palette.text.light,
        darkFocusColor: palette.focus.dark,
        focusColor: palette.focus.normal,

        dark: {
            checkBackground: 'inherit',
            selectedBackground: 'inherit',
            checkBorder: palette.background,
            fill: palette.text.light,
            selectedFill: palette.text.light,
        },
    },
    radio: {
        radioBackground: 'inherit',
        fill: palette.grey.G800,

        dark: {
            radioBackground: 'inherit',
            fill: palette.text.light,
        },
    },
    // SelectBase
    select: {
        caretRight: 10,
        caretTop: 9,
        darkIconFill: palette.text.light,
        darkOptionColor: palette.text.light,
        darkOptionBackground: palette.primary.darkBackground,
        darkFocusColor: palette.focus.dark,
        focusColor: palette.focus.normal,
        optionBackground: palette.background,
        iconFill: palette.text.dark,
        optionColor: palette.text.dark,
        padding: [9, 30, 9, 10],
        caretIcon: 'block',
        caret: {
            display: 'none',
        },
    },
    // CheckboxRadio
    hiddenCheckbox: {
        position: 'absolute',
        height: 1,
        width: 1,
        overflow: 'hidden',
        clip: 'rect(1px, 1px, 1px, 1px)',
    },
    checkboxRadioSvgDisplay: 'inline-block',
    disabled: {},
    optionalSpanFontFamily: typography.fontFamily,
    optionalSpanFontSize: 12,
});
