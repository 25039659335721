import data from 'common/datePicker/api/futureDatePicker';
import FieldSearch from 'no-override!@glu/grid/src/filter/fieldSearch';
import util from '@glu/core/src/util';
import DatePicker from 'system/gridOverride/views/datePicker';
import operatorUtil from 'system/gridOverride/utilities/operators';
import locale from '@glu/locale';
import moment from 'moment';
import dateUtil from 'common/util/dateUtil';
import userInfo from 'etc/userInfo';
import dateRangeTmpl from './dateRange.hbs';

const { getDateRanges } = data;

export default FieldSearch.extend({
    template: dateRangeTmpl,
    className: 'date-range-search',

    ui: {
        $datepickerContainer: '.date-picker-container',
        $submitBtn: 'button[type=submit]',
        $clearBtn: '.btn-clear-filter',
    },

    events: {
        submit: 'handleSubmit',
        'change input': 'handleButtonAvailability',
        'keyup input': 'handleButtonAvailability',
        'click @ui.$clearBtn': 'handleClearFilter',
    },

    onRender() {
        const options = {
            dateFormat: userInfo.getDateFormat().toUpperCase(),

            startDate: moment(new Date()).toDate(),

            endDate: moment(new Date()).toDate(),

            errorMsgs: {
                afterCutoff: locale.get('sbPayments.datePicker.errorMsgs.afterCutoff'),
                blockedDate: locale.get('sbPayments.datePicker.errorMsgs.blockedDate'),
                dailyLimit: locale.get('common.datepicker.dailyLimit'),
                historyLimit: locale.get('common.datepicker.historyLimit'),
                invalidDateFormat: locale.get('sbPayments.datePicker.errorMsgs.invalidDateFormat'),
                invalidDateRange: locale.get('common.datepicker.invalidDateRange'),
                required: locale.get('sbPayments.datePicker.errorMsgs.required'),
                tooFarPast: locale.get('sbPayments.datePicker.errorMsgs.tooFarPast'),
                tooFarFuture: locale.get('sbPayments.datePicker.errorMsgs.tooFarFuture'),
            },
        };

        this.$datepicker = new DatePicker(options);
        const prevBusinessDate = dateUtil.getPriorDate();
        const today = moment(new Date());
        const beginningOfTheYear = moment(new Date()).toDate();
        beginningOfTheYear.setMonth(0);
        beginningOfTheYear.setDate(1);

        const myDateRangePickerSettings = {
            ranges: {},

            presets: {
                specificDate: locale.get('common.datepicker.specificDate'),
                dateRange: locale.get('common.datepicker.dateRange'),
            },

            labelText: '',
            doneButtonText: locale.get('common.datepicker.done'),
            rangeSplitter: locale.get('common.datepicker.to'),
            rangeStartTitle: locale.get('common.datepicker.startDate'),
            rangeEndTitle: locale.get('common.datepicker.endDate'),

            datepickerOptions: {
                closeText: locale.get('common.datepicker.close'),
                currentText: locale.get('common.datepicker.today'),
                dayNamesMin: moment.weekdaysMin(),
                monthNamesShort: moment.monthsShort(),
                monthNames: moment.monthsShort(),
                nextText: '',
                prevText: '',
            },
        };

        // To check if the date picker should enable the future dates
        if (this.model.get('popupId')) {
            const now = moment(new Date());
            const endDay = moment(now);
            const startDay = moment(now);
            getDateRanges(this.model.get('payload')).then((results) => {
                this.dateRange = results;
                this.dateRange.forEach((range) => {
                    if (range.operation === 'add') {
                        myDateRangePickerSettings.ranges[range.label] = [
                            endDay,
                            moment(startDay)[range.operation](range.duration, range.unit),
                        ];
                    } else {
                        myDateRangePickerSettings.ranges[range.label] = [
                            moment(startDay)[range.operation](range.duration, range.unit),
                            endDay,
                        ];
                    }
                });
                this.callCommonConfig(myDateRangePickerSettings);
            });
        } else {
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.today')] = [
                today,
                today,
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.priorDay')] = [
                prevBusinessDate.toDate(),
                prevBusinessDate.toDate(),
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.lastMonth')] = [
                moment(new Date()).subtract(1, 'months').startOf('month').toDate(),
                moment(new Date()).subtract(1, 'months').endOf('month').toDate(),
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.last7Days')] = [
                moment(new Date()).subtract(7, 'days').toDate(),
                today,
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.last30Days')] = [
                moment(new Date()).subtract(30, 'days').toDate(),
                today,
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.last60Days')] = [
                moment(new Date()).subtract(60, 'days').toDate(),
                today,
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.last90Days')] = [
                moment(new Date()).subtract(90, 'days').toDate(),
                today,
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.monthToDate')] = [
                moment(new Date()).startOf('month').toDate(),
                today,
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.quarterToDate')] = [
                moment(new Date()).startOf('quarter').toDate(),
                today,
            ];
            myDateRangePickerSettings.ranges[locale.get('common.datepicker.yearToDate')] = [
                beginningOfTheYear,
                today,
            ];
            this.callCommonConfig(myDateRangePickerSettings);
        }
    },
    callCommonConfig(myDateRangePickerSettings) {
        util.extend(this.$datepicker.dateRangePickerSettings, myDateRangePickerSettings);
        this.ui.$datepickerContainer.html(this.$datepicker.render().$el);
        this.ui.$datepickerContainer.find('.datepicker-label').addClass('sr-only');

        this.setValue();

        this.handleButtonAvailability();

        this.listenTo(
            this.$datepicker,
            {
                datePicked: this.handleButtonAvailability,
            },
        );
    },

    handleButtonAvailability(...args) {
        if (!this.$datepicker.dateElem) {
            return;
        }
        const selection = FieldSearch.prototype.handleSubmitButton.apply(this, args);

        if (selection) {
            this.$datepicker.processDateInput();
        }
        this.ui.$submitBtn.prop('disabled', this.$datepicker.hasError);
        this.ui.$clearBtn.toggleClass('hidden', this.$datepicker.hasError);
    },

    setValue() {
        if (!this.model.has('value')) {
            return;
        }

        let value = this.model.get('value');
        let value2 = value;

        if (util.isArray(value)) {
            [value, value2] = this.model.get('value');
        }

        const startDate = dateUtil.convertCashFlowCodesToDates(value);
        const endDate = dateUtil.convertCashFlowCodesToDates(value2);

        this.$datepicker.updateDates(
            moment(startDate, userInfo.getDateFormat()),
            moment(endDate, userInfo.getDateFormat()),
        );
    },

    updateModelDates() {
        let dateCode = this.getDateCode();
        const dateFormat = userInfo.getDateFormat();

        if (dateCode === 'range' && util.isEmpty(this.$datepicker.endDate)) {
            dateCode = 'specific';
        }
        if (dateCode === 'LM') {
            dateCode = 'range';
        }

        let startDate;
        let endDate;

        switch (dateCode) {
        case 'specific':
            this.model.set({
                START_DATE: this.$datepicker.startDate ? this.$datepicker.startDate.format(dateFormat) : '',
                END_DATE: '',
            });
            break;
        case 'range':
            ({ startDate } = this.$datepicker);
            ({ endDate } = this.$datepicker);
            this.model.set({
                START_DATE: startDate.format(dateFormat),
                END_DATE: endDate.format(dateFormat),
            });
            break;
        case 'PBD':
            this.model.set({
                START_DATE: dateCode,
                END_DATE: dateCode,
            });
            break;
        default:
            this.model.set({
                START_DATE: dateCode,
                END_DATE: 'CD',
            });
        }
        return dateCode;
    },

    /**
     * Find the active li item and figure out the date code from the css class
     */
    getDateCode() {
        // this doesn't work if the date is set programatically
        const $activeDate = this.ui.$datepickerContainer.find('li.ui-state-active');
        if ($activeDate.length > 0) {
            const dateCodeReturn = util.find(
                Object.keys(this.$datepicker.dateRangePickerSettings.ranges),
                (preset) => {
                    const codeClass = `ui-daterangepicker-${preset.replace(/ /g, '')}`;
                    return $activeDate.hasClass(codeClass);
                },
            );
            if (!dateCodeReturn) {
                return 'range';
            }
            return dateCodeReturn.code;
        }
        return 'range';
    },

    handleSubmit() {
        try {
            this.trigger('filter', this.createFilter());
        } catch (ex) {
            return false;
        }
        return false;
    },

    createFilter() {
        let dateCode = this.updateModelDates();
        let startDate = this.model.get('START_DATE');
        let endDate = this.model.get('END_DATE');
        let equality = util.contains(['CD', 'PBD', 'specific'], dateCode) ? 'EQ' : 'BETWEEN';

        // the value was set programatically in setValue
        if (util.isNull(dateCode)) {
            // fall back to the existing values on the model
            if (util.isArray(this.model.get('value'))) {
                [startDate, endDate] = this.model.get('value');
            } else {
                startDate = this.model.get('value');
                endDate = '';
            }

            equality = this.model.get('equality');
            dateCode = this.model.get('dateCode');
        }
        return this
            .buildFilter(startDate, equality, endDate, dateCode, this.getDateFieldType(this.model));
    },

    /**
     * @method getDateFieldType
     * @param {Model} model - filter model
     * @returns {string} fieldType
     * @description set dataType of date filter
     * NH-164600: In request for RTP, due on and expiration date
     * are both date & time type so we need to send fieldType
     * of `gmtdate` (current column fieldType) instead of `date`
     *
     * amendment to comment above, as long as the fieldtype is in the model,
     * we should send that up.  for dates, this will either be 'data', 'gmtdate' or 'gmtdatetime'
     */
    getDateFieldType(model) {
        let type = model?.get('fieldType');
        if (type === 'undefined type') {
            type = 'date';
        }
        return type || 'date';
    },

    buildFilter(value, equality, value2, dateCode, type) {
        const filterOutput = {
            label: `${operatorUtil.convert4Label('EQ')} ${dateUtil.getDateCodes()[dateCode] || value}`,
            title: this.model.get('title'),
            type,
            field: this.field,
            equality,

            // preserve dateCode when filter is re-saved
            dateCode: dateCode || this.model.get('dateCode'),
        };

        if (equality === 'BETWEEN') {
            if (dateCode === 'range') {
                filterOutput.label = `${operatorUtil.convert4Label(equality)} ${value} - ${value2}`;
            }
            filterOutput.value = [value, value2];
            filterOutput.filter = util.bind(this.filter, this, this.field, [value, value2]);
        } else {
            filterOutput.value = value;
            filterOutput.filter = util.bind(this.filter, this, this.field, value);
        }
        return filterOutput;
    },
});
