var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>\n    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.bankmaintainednote.prefix",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":52}}}))
    + " \n    <button type=\"button\" class=\"btn btn-tertiary bankmaintained-note-button\" \n        data-trigger=\"hover\" data-container=\"body\" \n        data-toggle=\"popover\" data-placement=\"bottom\" \n        data-title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.bankmaintainednote.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":68}}}))
    + "\"\n        data-content=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"CONTENT") || (depth0 != null ? lookupProperty(depth0,"CONTENT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CONTENT","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":33}}}) : helper)))
    + "\">\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"COUNT") || (depth0 != null ? lookupProperty(depth0,"COUNT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"COUNT","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":17}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rolebased.bankmaintainednote.suffix",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":18},"end":{"line":8,"column":67}}}))
    + "\n    </button>  \n</span>\n";
},"useData":true});