import $ from 'jquery';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import number from 'numeral';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import format from 'system/utilities/format';
import PaymentUtil from 'common/util/paymentUtil';
import rtgsCommon from './rtgsCommon';

export default function (formParam, initialState) {
    const form = formParam;
    let totalAmount = 0;
    let multiplyFactor = 1;
    const Formatter = format;
    const decimalPlaces = userInfo.getDecimalPlaces();
    const { model } = form.formView;

    // amountFields = ['AMOUNT1', 'AMOUNT2', 'AMOUNT3'],
    const taxTypeCode = form.field('TAXTYPECODE');

    const hidecutoff = serverConfigParams.get('hideCutoff');
    const { functionCode } = model.jsonData.typeInfo;
    let amount1value;
    let amount2value;
    let amount3value;
    const totalFormat = model.get('TOTALFIELD') ? model.get('TOTALFIELD').toUpperCase() : 'AUTOTOTAL';
    const formState = form.formView.state;
    const insertOrModifyMode = formState.toUpperCase() === 'INSERT' || formState.toUpperCase() === 'MODIFY';
    const changeableFields = ['SPECIAL_INSTRUCTIONS', 'YEAR', 'MONTH', 'VALUE_DATE', 'AMOUNT1', 'AMOUNTTYPE1', 'AMOUNT2', 'AMOUNTTYPE2', 'AMOUNT3', 'AMOUNTTYPE3'];
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;
    const amountType1 = form.field('AMOUNTTYPE1');
    const amountType2 = form.field('AMOUNTTYPE2');
    const amountType3 = form.field('AMOUNTTYPE3');
    const amount1 = form.field('AMOUNT1');
    const amount2 = form.field('AMOUNT2');
    const amount3 = form.field('AMOUNT3');

    const getAmount = (amountFieldName) => {
        let amount = '';
        // If the amountFieldName isn't visible it shouldn't be counted in total
        if (!form.formView.$el.find(`[name="${amountFieldName}"]`).length) {
            return amount;
        }
        amount = model.get(amountFieldName);
        if (amount !== '' && multiplyFactor) {
            amount = number(amount) * multiplyFactor;
        }
        return amount;
    };

    const amountFieldChanged = () => {
        multiplyFactor = 1;

        for (let j = 0; j < decimalPlaces; j += 1) {
            multiplyFactor *= 10;
        }
        amount1value = getAmount('AMOUNT1');
        amount2value = getAmount('AMOUNT2');
        amount3value = getAmount('AMOUNT3');

        if (totalFormat === 'AMOUNT1') {
            totalAmount = (amount1value !== '' ? Number(amount1value) : '');
        } else if (totalFormat === 'AMOUNT2') {
            totalAmount = (amount2value !== '' ? Number(amount2value) : '');
        } else if (totalFormat === 'AMOUNT3') {
            totalAmount = (amount3value !== '' ? Number(amount3value) : '');
        } else if (totalFormat === 'AUTOTOTAL') {
            if (amount1value === '' && amount2value === '' && amount3value === '') {
                totalAmount = '';
            } else {
                totalAmount = Number(amount1value)
                    + Number(amount2value) + Number(amount3value);
            }
        }

        if (totalAmount !== '') {
            totalAmount /= multiplyFactor;
            totalAmount = Formatter.formatCurrency(totalAmount || 0);
            totalAmount = totalAmount.toString();
        }

        model.set('TOTALAMOUNT', totalAmount);
        model.set('CREDIT_AMOUNT', totalAmount);
        model.set('DEBIT_AMOUNT', totalAmount);
        PaymentUtil.updateRTGSSummaryTotal(model, form.formView);

        if (amountType1.getValue()) {
            amount1.shouldBeRequired(true);
        } else {
            amount1.shouldBeRequired(false).removeValidator('exists').$el.parent().removeClass('required');
        }

        if (amountType2.getValue()) {
            amount2.shouldBeRequired(true);
        } else {
            amount2.shouldBeRequired(false).removeValidator('exists').$el.parent().removeClass('required');
        }

        if (amountType3.getValue()) {
            amount3.shouldBeRequired(true);
        } else {
            amount3.shouldBeRequired(false).removeValidator('exists').$el.parent().removeClass('required');
        }

        /*
         * To prevent infinite loop when selecting TAXTYPECODE when doing Copy As
         * Template from a template.  NH-21281 and NH-22857.
         */
        if (form.simulated) {
            form.simulated = false;
            return true;
        }
        form.simulated = true;

        return undefined;
    };

    const protectFields = function () {
        let lockedFields = [];
        if (model.has('LOCKED_FIELDS')) {
            lockedFields = model.get('LOCKED_FIELDS').split(',');
        }
        Object.keys(form.fields).forEach((fieldName) => {
            if (changeableFields.indexOf(fieldName) === -1) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
            if (lockedFields.includes(fieldName)) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
        });
        $('.lookup').addClass('hidden');
    };

    if (initialState) {
        rtgsCommon.getAccountBalances(form.formView, 'ACCOUNTFILTER', 'RTGS', 'FEDTAX', 'DEBIT_BANK_CODE', 'CREDIT_CURRENCY');

        taxTypeCode
            .$el.on('change', () => {
                // clear amount fields for insert and modify
                if (insertOrModifyMode) {
                    model.set({
                        TAXIDNUMBER: '',
                        AMOUNT1: '',
                        AMOUNT2: '',
                        AMOUNT3: '',
                        AMOUNTTYPE1: '',
                        AMOUNTTYPE2: '',
                        AMOUNTTYPE3: '',
                        TOTALAMOUNT: '',
                        TOTALAMOUNT_FORMAT: '',
                        CREDIT_AMOUNT: '',
                        DEBIT_AMOUNT: '',
                    }, {
                        silent: true,
                    });
                    amountType1.setValue('');
                    amountType2.setValue('');
                    amountType3.setValue('');
                    amount1.setValue('');
                    amount2.setValue('');
                    amount3.setValue('');
                }
            });

        model.on('change:VALUE_DATE', function () {
            if (form.formView.state.toUpperCase() !== 'VIEW') {
                fxFieldValidation.doFieldValidation(this, model, 'VALUE_DATE', form.formView.state.toUpperCase()).then(() => {
                    if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL') {
                        PaymentUtil.showCutoff(model.get('CUTOFF_INFO') || form.formView.dates.cutoffDateTimeTz, $('.ui-datepicker-trigger'), model.get('TYPE'));
                    }
                });
            }
        });
        model.on('change:DEBIT_ACCOUNT_NUMBER', () => {
            const subType = '*';

            model.set('DEBIT_CURRENCY', model.get('CREDIT_CURRENCY'));
            PaymentUtil.updateCutoff(
                model.get('PRODUCT'),
                model.get('FUNCTION'),
                model.get('TYPE'),
                subType,
                model.context.actionMode, model, $('.ui-datepicker-trigger'),
            );
        });

        if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL') {
            PaymentUtil.showCutoff(model.get('CUTOFF_INFO'), $('.ui-datepicker-trigger'), model.get('TYPE'), formState, model.get('STATUS'));
        }

        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            form.formView,
            model,
            null,
            functionCode,
        );

        // handle lock icon for amount if template and not view
        if (formState.toUpperCase() !== 'VIEW' && (functionCode !== 'INST')) {
            if (amount1.$el && amount1.$el.length > 0) {
                PaymentUtil.runChangeAmount(amount1.$el[0], model);
            }
            if (amount2.$el && amount2.$el.length > 0) {
                PaymentUtil.runChangeAmount(amount2.$el[0], model);
            }
            if (amount3.$el && amount3.$el.length > 0) {
                PaymentUtil.runChangeAmount(amount3.$el[0], model);
            }
            if (model.has('LOCKED_FIELDS')) {
                const lockedFields = model.get('LOCKED_FIELDS').split(',');
                if ((lockedFields.includes('AMOUNT1') && PaymentUtil.verifyValidAmount(model.get('AMOUNT1')))
                                || (lockedFields.includes('AMOUNT2') && PaymentUtil.verifyValidAmount(model.get('AMOUNT2')))
                                || (lockedFields.includes('AMOUNT3') && PaymentUtil.verifyValidAmount(model.get('AMOUNT3')))) {
                    model.set('TEMPLATE_MAX_AMOUNT', '');
                    $('[name="TEMPLATE_MAX_AMOUNT"]').prop('readonly', true);
                }
            }
        }
    }
    /*
     * metadriven form is setting the previous and current attributes to the same values, so
     * we cant compare to determine if there were changes.  Quick just to add up values, any
     * time there are changes.
     */
    amountFieldChanged();

    form.field('DEBIT_ACCOUNT_BANK_NAME').shouldBeHidden().shouldBeVisibleWhen(form.field('DEBIT_ACCOUNT_NUMBER').isNotEmpty());
    form.field('DEBIT_ACCOUNT_TITLE').shouldBeHidden().shouldBeVisibleWhen(form.field('DEBIT_ACCOUNT_NUMBER').isNotEmpty());
    if (form.formView.model.get('ENTRYMETHOD') && (form.formView.model.get('ENTRYMETHOD') === '1' || form.formView.model.get('ENTRYMETHOD') === '2')) {
        protectFields();
    }

    /*
     * hide cutoff if conditions are true  (EN-Entered, IA-Incomplete Approval, RT-Needs
     * Rate, HV-2nd Approval Required are status defined in database)
     */
    rtgsCommon.hideCutoff(formState, functionCode, hidecutoff, 'FEDTAX', model.get('STATUS'));
}
