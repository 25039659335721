var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"#\" data-action=\"viewTransactionDetails\">\n    <strong>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.ViewTransactionDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":51}}}))
    + "</strong>\n</a>\n";
},"useData":true});