import Layout from '@glu/core/src/layout';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import scroll from 'common/util/scroll';
import BaseInsert from './baseInsert';

const LoadingDropdown = Layout.extend({
    template: loadingTemplate,
});

export default BaseInsert.extend({
    loadingTemplate,

    initialize(options) {
        BaseInsert.prototype.initialize.call(this, options);
        this.loadingDropdown = new LoadingDropdown();
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            return;
        }
        this.multiFieldView = this.createRunTimeFields(this.model);
        this.fileFormatDropdown = this.createFileFormatDropdown();
        this.fileFormatRegion.show(this.fileFormatDropdown);
        this.multifieldRegion.show(this.multiFieldView);
    },

    /**
     * @abstract
     * @returns {Array} Array of attributes used for search fields when saving
     * the report
     */
    getSearchAttrs() { return []; },

    /**
     * Sets the atrributes of the model, checks validation, and then
     * returns a resolved promise with a JSON object containing model data
     * @return {Promise}
     */
    prepareModelData() {
        const searchFields = this.model.createSearchFields(this.getSearchAttrs());
        this.model.set({
            SEARCHFIELDS: searchFields,
            TIMEFREQUENCIES: this.getRunTimes(this.multiFieldView),
        });

        if (!this.model.isValid()) {
            scroll.scrollToFirstError();
            return Promise.reject(new Error('invalid model'));
        }
        return Promise.resolve(this.model.getSaveData());
    },
});
