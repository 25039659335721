var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row\">\n        <label class=\"checkbox-inline\" for=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":65},"end":{"line":5,"column":72}}}) : helper)))
    + "\">\n            <input type=\"checkbox\" id=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":6,"column":60},"end":{"line":6,"column":67}}}) : helper)))
    + "\" class=\"select-all-perms\">\n            "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignAll",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":38}}}))
    + "\n        </label>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"checkbox assign-all-checkbox\">\n                        <input id=\"assignAllChk-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":27,"column":48},"end":{"line":27,"column":55}}}) : helper)))
    + "\" type=\"checkbox\" data-hook=\"assign-all-checkbox\">\n                        <label for=\"assignAllChk-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":28,"column":49},"end":{"line":28,"column":56}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllCheckbox") || (depth0 != null ? lookupProperty(depth0,"assignAllCheckbox") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllCheckbox","hash":{},"data":data,"loc":{"start":{"line":28,"column":58},"end":{"line":28,"column":79}}}) : helper)))
    + "</label>\n                            <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllTooltip") || (depth0 != null ? lookupProperty(depth0,"assignAllTooltip") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllTooltip","hash":{},"data":data,"loc":{"start":{"line":29,"column":139},"end":{"line":29,"column":159}}}) : helper)))
    + "\">\n                                <span class=\"icon-info\"></span>\n                            </a>\n                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-tertiary edit-items-button right\"><span class=\"icon-pencil\"></span> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"buttonLabel") || (depth0 != null ? lookupProperty(depth0,"buttonLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"buttonLabel","hash":{},"data":data,"loc":{"start":{"line":41,"column":125},"end":{"line":41,"column":140}}}) : helper)))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":13}}}) : helper)))
    + "</h4>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "\n<div data-region=\"permissionsRegion\"></div>\n\n<div class=\"accounts-div hidden\">\n    <div class=\"panel-heading\" role=\"tab\" id=\"collapseHeading\">\n        <h2 class=\"panel-title\">\n            <a role=\"button\" class=\"collapsed\" data-toggle=\"collapse\" href=\"#collapseAreaID-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":17,"column":92},"end":{"line":17,"column":99}}}) : helper)))
    + "\" aria-expanded=\"true\" aria-controls=\"collapseAreaID-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":17,"column":152},"end":{"line":17,"column":159}}}) : helper)))
    + "\">\n                <span class=\"indicator-icon\"></span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignedAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":53},"end":{"line":18,"column":86}}}))
    + "\n            </a>\n        </h2>\n    </div>\n\n    <div id=\"collapseAreaID-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":23,"column":35}}}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\" >\n        <div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":33,"column":35}}})) != null ? stack1 : "")
    + "            <div class=\"assign-all-msg\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllMsg") || (depth0 != null ? lookupProperty(depth0,"assignAllMsg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllMsg","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":32}}}) : helper)))
    + "\n            </div>\n\n            <div data-hook=\"grid-section\">\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":42,"column":27}}})) != null ? stack1 : "")
    + "\n                <div data-region=\"gridRegion\"></div>\n\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});