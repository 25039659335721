import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import $ from 'jquery';
import AuditHistoryList from 'app/auditHistory/views/auditHistoryList';
import auditHistoryWidgetTmpl from './auditHistoryWidget.hbs';

export default BaseWidget.extend({
    template: auditHistoryWidgetTmpl,
    className: 'ui-widget field-container',

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);
        const widgetInitOptions = options.widgetInitOptions || {};
        // When additionalPageOptions is undefined use an empty object
        const additionalPageOptions = (options.parentInitializeOptions
            && options.parentInitializeOptions.additionalPageOptions) || {};

        /*
         * get the widget's fieldGroup and find the collapsible element for that group
         * then get the href id to set up the 'show.bs.collapse' event handler
         */
        const container = options.fieldInfo.fieldGroup;
        const collapsibleId = $(`[data-container="${container}"]`)[0].href.split('#')[1];
        $(`#${collapsibleId}`).one('show.bs.collapse', () => {
            const auditHistoryList = new AuditHistoryList({
                detailModel: this.model,
                functionCode: this.model.get('FUNCTION'),
                tnum: this.model.get('TNUM'),
                type: this.model.get('TYPE'),
                additionalLVCKey: 'DETAILAUDIT',
                serviceName: widgetInitOptions.serviceName,
                searchFields: widgetInitOptions.searchFields,
                productCode: this.model.get('PRODUCT'),
                surrogateTnum: this.model.get('SURROGATETNUM'),
                id: options ? options.fieldName : null,
                auditExportInquiryId: this.options.parentInitializeOptions
                    ? this.options.parentInitializeOptions.auditExportInquiryId : null,
                includeDefaultIcons: widgetInitOptions.includeDefaultIcons
                    || additionalPageOptions.includeDefaultIcons,
                // disable the stickyFooter when in a modal
                stickyFooter: !this.$el.closest('.modal').length,
            });
            this.auditListRegion.show(auditHistoryList);
        });
    },
});
