var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"PendingChanges panel panel-primary\">\n    <div class=\"PendingChanges-heading panel-heading\">\n        <h3 class=\"panel-title\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"collapseNeeded") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":8,"column":23}}})) != null ? stack1 : "")
    + "                <p class=\"PendingChanges-title\">\n                    <span class=\"icon icon-exclamation-solid\"></span>\n                    <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.Users.ChangesAwaitingApproval",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":26},"end":{"line":11,"column":71}}}))
    + "</span>\n                </p>\n                    </a>\n            </h3>\n    </div>\n    <div class=\"PendingChanges-panel "
    + alias2(((helper = (helper = lookupProperty(helpers,"panelClasses") || (depth0 != null ? lookupProperty(depth0,"panelClasses") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"panelClasses","hash":{},"data":data,"loc":{"start":{"line":16,"column":37},"end":{"line":16,"column":53}}}) : helper)))
    + "\" id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"collapseId") || (depth0 != null ? lookupProperty(depth0,"collapseId") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"collapseId","hash":{},"data":data,"loc":{"start":{"line":16,"column":59},"end":{"line":16,"column":73}}}) : helper)))
    + "\" role=\"tabpanel\">\n        <ul class=\"PendingChanges-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"columns") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":33,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasActions") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a class=\"collapsed\" data-toggle=\"collapse\" href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"collapseId") || (depth0 != null ? lookupProperty(depth0,"collapseId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"collapseId","hash":{},"data":data,"loc":{"start":{"line":6,"column":71},"end":{"line":6,"column":85}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"collapseId") || (depth0 != null ? lookupProperty(depth0,"collapseId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"collapseId","hash":{},"data":data,"loc":{"start":{"line":6,"column":124},"end":{"line":6,"column":138}}}) : helper)))
    + "\" role=\"button\">\n                            <span class=\"indicator-icon\"></span>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"ListColumn row\">\n                    <div class=\"actionByUser ListColumn-item col-md-4 col-lg-4\">\n                        <label class=\"item-label\">"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"headers") : depths[1])) != null ? lookupProperty(stack1,"ACTIONEDBYUSERID") : stack1), depth0))
    + "</label>\n                        <p class=\"item-value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"ACTIONBYUSERGROUP") || (depth0 != null ? lookupProperty(depth0,"ACTIONBYUSERGROUP") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"ACTIONBYUSERGROUP","hash":{},"data":data,"loc":{"start":{"line":22,"column":46},"end":{"line":22,"column":67}}}) : helper)))
    + " | "
    + alias2(((helper = (helper = lookupProperty(helpers,"ACTIONEDBYUSERID") || (depth0 != null ? lookupProperty(depth0,"ACTIONEDBYUSERID") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"ACTIONEDBYUSERID","hash":{},"data":data,"loc":{"start":{"line":22,"column":70},"end":{"line":22,"column":90}}}) : helper)))
    + "</p>\n                    </div>\n                    <div class=\"dateChanged ListColumn-item col-md-4 col-lg-4\">\n                        <label class=\"item-label\">"
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"headers") : depths[1])) != null ? lookupProperty(stack1,"LOGTIME") : stack1), depth0))
    + "</label>\n                        <p class=\"item-value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"LOGTIME") || (depth0 != null ? lookupProperty(depth0,"LOGTIME") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"LOGTIME","hash":{},"data":data,"loc":{"start":{"line":26,"column":46},"end":{"line":26,"column":57}}}) : helper)))
    + "</p>\n                    </div>\n                    <div class=\"info ListColumn-item ListColumn-item--modified col-12\">\n                        <label class=\"item-laber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias3,"PS.Users.PendingChanges",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":50},"end":{"line":29,"column":86}}}))
    + "</label>\n                        <p class=\"item-value\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"INFO") || (depth0 != null ? lookupProperty(depth0,"INFO") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"INFO","hash":{},"data":data,"loc":{"start":{"line":30,"column":46},"end":{"line":30,"column":56}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                    </div>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"PendingChanges-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"approvalAction") : depth0),{"name":"with","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":39,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableAction") : depth0),{"name":"with","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":12},"end":{"line":42,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"btn btn-primary approve\" data-hook=\"getApproveBtn\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":38,"column":92},"end":{"line":38,"column":101}}}) : helper)))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"btn btn-primary disable\" data-hook=\"getDisableBtn\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":41,"column":92},"end":{"line":41,"column":101}}}) : helper)))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasPendingChanges") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":47,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});