var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset>\n        <legend class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"passcode.emailmessage.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":72}}}))
    + "</legend>\n        <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"field-container-xs \">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"default") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                <div class=\"field-container-md\">\n                    <label for=\"emailId_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":47}}}) : helper)))
    + "\" class=\"main-label\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.emailmessage.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":68},"end":{"line":8,"column":108}}}))
    + "</label>\n                    <input id=\"emailId_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":39},"end":{"line":9,"column":46}}}) : helper)))
    + "\" type=\"email\" name=\"emailAddress\" class=\"email form-control\" data-bind=\"model\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"emailAddress\"></span>\n                </div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\" contact-readonly field-container-sm\">\n                    <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"emailAddress") || (depth0 != null ? lookupProperty(depth0,"emailAddress") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"emailAddress","hash":{},"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":15,"column":44}}}) : helper)))
    + "</strong>\n                    <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"delete\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.removefrom",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":88},"end":{"line":16,"column":118}}}))
    + "</button>\n                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"textVisible":true,"className":"default-contact","locale":"PS.email.message.default","name":"tick-solid"},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":126}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"btn-link contact-option","action":"setDefault","textVisible":true,"locale":"PS.email.message.make.default","name":"tick"},"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":153}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"removeRecord") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":11}}})) != null ? stack1 : "");
},"useData":true});