import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import FlexDropdown from '@glu/flex-dropdown';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Approvers from './approvers';
import approverSequenceTmpl from './approverSequence.hbs';

export default Layout.extend({
    template: approverSequenceTmpl,
    className: 'Panel',

    ui: {
        $deleteButton: '[data-hook="getDeleteButton"]',
        $addApproverButton: '[data-hook="getAddApproverButton"]',
    },

    events: {
        'click @ui.$deleteButton': 'removeSequence',
        'click @ui.$addApproverButton': 'addApprover',
    },

    initialize(options) {
        this.approversCollection = options.model.get('approvers');
        this.approvers = new Approvers({
            collection: this.approversCollection,
            approverTypes: options.approverTypes,
        });

        this.initializeSequenceType(options);
    },

    onRender() {
        this.sequenceTypeRegion.show(this.sequenceType);
        this.approversRegion.show(this.approvers);

        this.$el.find('.PanelApproval-list-item').last().append(this.ui.$addApproverButton);
    },

    initializeSequenceType() {
        // Check for null or undefined because 0 is falsey
        const preSelectedIds = !util.isNullOrUndefined(this.model.get('sequence'))
            ? [this.model.get('sequence')] : [];
        this.sequenceType = new FlexDropdown({
            data: this.options.sequenceTypes,
            preSelectedIds,
            defaultSelectMessage: locale.get('common.select'),
            label: `${locale.get('PS.panelApproval.Approval.Sequence')} ${this.options.index + 1}`,
            disableMultiButton: true,
            clearBtn: true,
            multiSelect: false,
        });
        this.listenTo(this.sequenceType, 'selectionChanged', this.handleSequenceChange);
    },

    handleSequenceChange(selected) {
        this.model.set('sequence', selected[0].id);
    },

    addApprover() {
        this.approversCollection.add(new Model());
        this.$el.find('.PanelApproval-list-item').last().append(this.ui.$addApproverButton);
        if (this.approversCollection.length === 6) {
            this.ui.$addApproverButton.hide();
        }
    },

    removeSequence() {
        this.trigger('remove:sequence');
    },

    templateHelpers() {
        return {
            hasDelete: this.options.index !== 0,
        };
    },
});
