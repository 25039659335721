import CollectionView from '@glu/core/src/collectionView';
import ItemListView from 'app/smbPayments/views/accounts/balances/listView';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';

const emptyView = Layout.extend({
    className: 'SMBAccounts-emptyView',

    template() {
        return locale.get('smbPayments.no.accounts');
    },
});
/**
 * Represents a list of view types on the list layout
 *
 * @class ItemListView
 */
const ListItemsView = CollectionView.extend({
    tagName: 'div',
    className: 'list-view table',
    itemView: ItemListView,
    emptyView,

    itemViewOptions() {
        return {
            loansPrefs: this.options.loansPrefs,
        };
    },
});

export default ListItemsView;
