var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":13},"end":{"line":5,"column":33}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":47}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payee",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":43}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"formattedPayee") || (depth0 != null ? lookupProperty(depth0,"formattedPayee") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"formattedPayee","hash":{},"data":data,"loc":{"start":{"line":6,"column":51},"end":{"line":6,"column":69}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payment.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":50}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"VALUE_DATE") || (depth0 != null ? lookupProperty(depth0,"VALUE_DATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"VALUE_DATE","hash":{},"data":data,"loc":{"start":{"line":7,"column":58},"end":{"line":7,"column":72}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payment.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":13},"end":{"line":8,"column":51}}}))
    + ": <span>"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"CREDIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":8,"column":59},"end":{"line":8,"column":83}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"CREDIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"CREDIT_CURRENCY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CREDIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":8,"column":84},"end":{"line":8,"column":103}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.from.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":13},"end":{"line":9,"column":50}}}))
    + ": <span>"
    + alias2((lookupProperty(helpers,"maskAccount")||(depth0 && lookupProperty(depth0,"maskAccount"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_NUMBER") : depth0),{"name":"maskAccount","hash":{},"data":data,"loc":{"start":{"line":9,"column":58},"end":{"line":9,"column":94}}}))
    + "</span></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"BENE_SUBACCOUNT_NUM") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.NoteNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":17},"end":{"line":11,"column":43}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"BENE_SUBACCOUNT_NUM") || (depth0 != null ? lookupProperty(depth0,"BENE_SUBACCOUNT_NUM") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"BENE_SUBACCOUNT_NUM","hash":{},"data":data,"loc":{"start":{"line":11,"column":51},"end":{"line":11,"column":74}}}) : helper)))
    + "</span></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cmb-alert-details\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isError") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});