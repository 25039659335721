import util from '@glu/core/src/util';
import services from 'services';
import { postData } from 'common/util/services';
import interfaceUtil from './util';

const checkInquiryAttributes = {
    AMOUNT: 'amount',
    PAYEE: 'payee',
    VALUE_DATE: 'valueDate',
    ACCOUNT_NUMBER: 'accountNumber',
    SERIALNUMBER: 'serialNumber',
    STATUS_DESCRIPTION: 'statusDescription',
    ACCOUNTNAME: 'accountName',
};

const accountAttributes = {
    ACCOUNT_NUMBER_UNMASKED: 'accountNumber',
    BankCode: 'bankCode',
    CLIENTACCOUNTNAME: 'accountName',
    AccountFilter: 'accountFilter',
};

export default {
    // productCode~functionCode~typeCode
    entitlementKey: 'CM~INST~CMINQ',

    /**
     * Get a list of check based on the options passed
     * @param {Object} options
     * @param {string} options.accountFilter - account filter (bankCode and accountNumber
     * concatenated together, ie. BONY-1122334455667)
     * @param {number} options.serialNumber - serial number of the check
     * @param {number} [options.startRow] - starting row for records
     * @return {Promise} Promise object
     */
    checkInquiry(options) {
        if (util.isNullOrUndefined(options.serialNumber)
            || util.isNullOrUndefined(options.accountFilter)) {
            return Promise.reject(new Error('Required options parameter missing'));
        }

        const data = {
            startRow: options.startRow || 1,
            viewId: '20504',
            disableDrillDown: false,
            dataOnly: 0,
            searchFields: [
                {
                    operator: 'EQ',
                    dataType: 'text',
                    fieldName: 'ACCOUNTFILTER',
                    fieldValue: [
                        options.accountFilter,
                    ],
                },
                {
                    operator: 'EQ',
                    dataType: 'amount',
                    fieldName: 'SERIALNUMBER',
                    fieldValue: [
                        options.serialNumber,
                    ],
                },
                {
                    operator: 'EQ',
                    dataType: 'text',
                    fieldName: 'CHECKSTATUSCODE',
                    fieldValue: [
                        'PAID',
                    ],
                },
            ],
            requestParameters: {
                item: [
                    {
                        name: 'initialRequest',
                        value: 'true',
                    },
                ],
            },
        };
        return postData(services.checkInquiryListView, data);
    },

    /**
     * Make request for a check paid
     * @param {Object} options - options object
     * @param {number} options.accountFilter - account number
     * @param {number} options.serialNumber - serial number of the check
     * @returns {Promise} Promise object
     */
    checkPaidInquiry(options) {
        if (util.isNullOrUndefined(options.serialNumber)
            || util.isNullOrUndefined(options.accountFilter)) {
            return Promise.reject(new Error('Required options parameter missing'));
        }
        return this.checkInquiry(options)
            .then(this.parseResponse)
            .then(this.formatCheckInquiryResponse.bind(this))
            .then(accounts => JSON.stringify(accounts))
            .catch(error => Promise.reject(JSON.stringify(error)));
    },

    /**
     *
     * @param {Object} response - server response
     * @returns {Promise|Object}
     */
    parseResponse(response) {
        return response.respHeader.result ? response
            : Promise.reject(interfaceUtil.convertToErrorResponse(response.respHeader));
    },

    /**
     * Format the response for native application
     * @param {Object} response
     * @param {Object} attributes
     * @returns {Array}
     */
    formatResponse(response, attributes) {
        return response.rows.map(row => interfaceUtil.extractData(
            row.columns,
            attributes,
        ));
    },

    /**
     * Convert the standard response object to data structure that native expects
     * @param {Object} response
     * @returns {Object}
     */
    formatCheckInquiryResponse(response) {
        return this.formatResponse(response, checkInquiryAttributes);
    },

    /**
     * Convert the standard response object to data structure that native expects
     * @param {Object} response
     * @returns {Object}
     */
    formatAccountResponse(response) {
        return this.formatResponse(response, accountAttributes);
    },

    /**
     * Get accounts that are entitled to check inquiry
     * @returns {Promise}
     */
    getAccounts() {
        return interfaceUtil.getAccounts({
            typeCode: 'CMINQ',
            productCode: 'CM',
            functionCode: 'INST',
            fieldName: 'ACCOUNTFILTERLOOKUP',
            requestParameters: {
                item: [
                    {
                        name: 'PRODUCTCODE',
                        value: 'CM',
                    },
                    {
                        name: 'FUNCTIONCODE',
                        value: 'INST',
                    },
                    {
                        name: 'TYPECODE',
                        value: 'CMINQ',
                    },
                    {
                        name: 'INQUIRYID',
                        value: 20506,
                    },
                ],
            },
        }).then(response => ({
            accounts: this.formatAccountResponse(response),
            entitlement: this.entitlementKey,
        }));
    },
};
