import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';
import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import ComboboxWidget from 'common/uiWidgets/comboboxWidget/comboboxWidget';
import MultiSelectComboboxWidget from 'common/uiWidgets/multiSelectComboboxWidget/multiSelectComboboxWidget';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';
import doubleComboboxWidgetTmpl from './doubleComboboxWidget.hbs';

export default BaseWidget.extend({
    template: doubleComboboxWidgetTmpl,

    ui: {
        $doubleComboboxWidgetContainer: '[data-hook="doubleComboboxWidgetContainer"]',
        $secondaryComboboxAdditionalInfo: '[data-hook="secondaryComboboxAdditionalInfo"]',
        $secondaryComboboxContainer: '[data-hook="secondaryComboboxContainer"]',
        $secondaryCombobox: '[data-hook="secondaryCombobox"]',
    },

    events: {
        'change @ui.$secondaryCombobox': 'onSecondaryComboboxChange',
    },

    className() {
        let cssClass = 'ui-widget double-combobox-widget field-container';
        const { fieldInfo } = this.options;

        if (fieldInfo.blockClass) {
            cssClass += (` ${fieldInfo.blockClass}`);
        }

        return cssClass;
    },

    initialize(options) {
        BaseWidget.prototype.initialize.call(this, options);

        this.secondaryComboboxData = {};

        /*
         * This is hardcoded for now but should be dynamic if we adapt this to be fully
         * abstract and usable for more applications than this
         */
        this.primaryComboboxWidgetFieldName = 'ACCOUNTFILTER';
        this.secondaryComboboxFieldName = 'ORIGINATOR';

        /*
         * in specific circumstances we'd like to use a multi-select combobox as the primary
         * combobox rather than a regular one. Either way, create it as a "sub-widget" to keep
         * it from applying certain sizing classes.
         */
        if ((applicationConfigParams.getValue('WIRES', 'ALLOWRESTRICTTMPLDEBITACC') === '1') && ComboboxWidget.prototype.isTemplate(this.model)) {
            this.primaryComboboxWidget = new
            MultiSelectComboboxWidget({ ...options, isSubWidget: true });
        } else {
            this.primaryComboboxWidget = new ComboboxWidget({ ...options, isSubWidget: true });
        }

        // determine whether or not to show and check for the secondary combobox
        this.showSecondaryCombobox = (applicationConfigParams.getValue('WIRES', 'SELECTSENDERFORWIRES') === '1');

        this.primaryComboboxWidgetIsReadOnly = this.primaryComboboxWidget
            .isReadOnly(this.primaryComboboxWidgetFieldName, this.model);

        /*
         * On page-load (or a re-render from driver field interaction), if the user has a
         * selected item in the secondaryCombobox, we check it to ensure it's still a valid
         * option for the selected primaryCombobox item. We don't want to perform this
         * validation every time a change happens within the primaryCombobox, just on page load
         */
        this.selectedSecondaryHasBeenEvaluated = false;

        if (this.state !== 'VIEW' && !this.primaryComboboxWidgetIsReadOnly) {
            // add listener for primaryComboboxWidget changes
            this.listenTo(this.primaryComboboxWidget, 'selectionChanged', this.onPrimaryComboboxWidgetSelectionChange);
        }
    },

    /**
     * In the event of a change to the primary combobox, use that newly selected data to attempt
     * to retrieve additional data to populate the secondary combobox
     * @param {Object} currentSelection - the currently selected item from the primary combobox
     * @return {boolean} - Whether or not a valid selection has been picked
     */
    onPrimaryComboboxWidgetSelectionChange(currentSelection) {
        // hide the secondary combobox until we're sure we need it
        this.ui.$secondaryComboboxContainer.hide();

        // remove the validator for the secondary combobox until we're sure it should exist
        this.model.removeValidator(`SECONDARY_${this.secondaryComboboxFieldName}`);

        // the user has cleared the primary combobox so exit here
        if (!currentSelection) {
            return false;
        }

        /*
         * the user has selected an item from the primary combobox and we'd like to show and
         * populate the secondary combobox
         */
        if (this.showSecondaryCombobox) {
            // get data for the secondary combobox
            this.loadSecondaryComboboxData().then((data) => {
                if (typeof this.ui.$doubleComboboxWidgetContainer === 'string') {
                    return;
                }
                this.onSecondaryComboboxLoad(data);
                this.showAndPopulateSecondaryCombobox();
            }, () => {
                this.showNoSecondaryItemsError();
            });
        }

        return true;
    },

    /**
     * Determine whether or not to show a state or province for this item
     * @param {Object} item - The item containing state or privince data
     * @return {String} - The correct state or privince to be displayed
     */
    determineStateOrProvince(item) {
        return (item.senderCountry === 'US'
            ? item.senderState : item.senderProvince) || '';
    },

    /**
     * Determine how to display the items address based on values present
     * @param {Object} item - the item containing address information
     * @return {String} - The correct address to be displayed
     */
    configureAddressToDisplay(item) {
        return item.senderAddress2 ? `${item.senderAddress1} ${item.senderAddress2}`
            : item.senderAddress1;
    },

    /**
     * Depending on the type of data returned for the secondary combobox, format that data
     * to display as needed. At the moment there's only one use of this widget so this function
     * is really more to account for future potential uses
     * @param {Object} item - the item being added to the secondary combobox
     */
    populateSecondaryCombobox(item) {
        // squish together multiple addresses if need be
        const displayAddress = this.configureAddressToDisplay(item);
        // show state if in the US, province otherwise
        const stateOrProvince = this.determineStateOrProvince(item);

        // add the new items to the combobox
        this.ui.$secondaryCombobox.append(new Option(`${item.senderName} ${displayAddress} ${item.senderCity}, ${stateOrProvince}`, item.senderName, false, false));
    },

    /**
     * When a user selects an item from the secondary combobox, update various bits of
     * information on the model using either the selected item within the primaryCombobox OR
     * the selected item in the secondaryCombobox
     * @param {Event} e - The change event from a selection change
     * @param {Object} manualChange - An object being set onto the secondaryCombobox manually
     */
    onSecondaryComboboxChange(e, manualChange) {
        let modelSetObject = {};

        /*
         * there are either no valid secondaryCombobox values, or the user has cleared a
         * previous selection from the secondaryCombobox. Either way, update the model with the
         * values from the currently selected item in the primary combobox widget
         */
        if (!manualChange && (!e || !e.val)) {
            /*
             * DO NOT update the model with the values from the currently selected item in the
             * primary combobox widget if there is a valid selection in the secondary combobox
             * the secondary selection will be manually set shortly after this and clearing the
             * model would rob it of its needed information
             */
            if (this.selectedSecondaryIsValid) {
                return;
            }
            const currentPrimarySelectionData = transform.pairsToHash(this.primaryComboboxWidget.currentSelection.mapDataList, 'toField', 'value');

            modelSetObject = {
                ORIGINATOR_NAME: currentPrimarySelectionData.ORIGINATOR_NAME,
                ORIGINATOR_ADDRESS_1: currentPrimarySelectionData.ORIGINATOR_ADDRESS_1,
                ORIGINATOR_ADDRESS_2: currentPrimarySelectionData.ORIGINATOR_ADDRESS_2,
                ORIGINATOR_CITY: currentPrimarySelectionData.ORIGINATOR_CITY,
                ORIGINATOR_STATE: currentPrimarySelectionData.ORIGINATOR_STATE,
                ORIGINATOR_PROVINCE: currentPrimarySelectionData.ORIGINATOR_PROVINCE,
                ORIGINATOR_COUNTRY: currentPrimarySelectionData.ORIGINATOR_COUNTRY,
                ORIGINATOR_POSTALCODE: currentPrimarySelectionData.ORIGINATOR_POSTALCODE,
            };

            // clear any text beneath the secondary combobox
            this.ui.$secondaryComboboxAdditionalInfo.text('');

            // clear any secondaryCombobox value on the model
            this.model.set(`SECONDARY_${this.secondaryComboboxFieldName}`, '');
        } else {
            /*
             * the value of the secondaryCombobox is being manually set, or the user has
             * selected a value from the dropdown
             */
            const selectedItemData = manualChange
                ? this.secondaryComboboxData.find(item => item.senderName === manualChange.val)
                : this.secondaryComboboxData.find(item => item.senderName === e.val);

            /*
             * Exit the function if there's no selectedItemData. This occurs in rare situations
             * like if a user has selected an item from the secondaryCombobox, then changed the
             * selected primaryCombobox item, and the item they had selected from the
             * secondaryCombobox exists within the list of viable items for the new
             * primaryCombobox item.
             */
            if (!selectedItemData) {
                return;
            }

            modelSetObject = {
                ORIGINATOR_NAME: selectedItemData.senderName,
                ORIGINATOR_ADDRESS_1: selectedItemData.senderAddress1,
                ORIGINATOR_ADDRESS_2: selectedItemData.senderAddress2,
                ORIGINATOR_CITY: selectedItemData.senderCity,
                ORIGINATOR_STATE: selectedItemData.senderState,
                ORIGINATOR_PROVINCE: selectedItemData.senderProvince,
                ORIGINATOR_COUNTRY: selectedItemData.senderCountry,
                ORIGINATOR_POSTALCODE: selectedItemData.senderPostalCode,
            };

            /*
             * update the displayed text of the selected item to be just the name
             * can't grab this UI element at page load since the combobox won't exist then
             */
            this.ui.$secondaryComboboxContainer.find('.select2-chosen').text(selectedItemData.senderName);

            // squish together multiple addresses if need be
            const displayAddress = this.configureAddressToDisplay(selectedItemData);
            // show state if in the US, province otherwise
            const stateOrProvince = this.determineStateOrProvince(selectedItemData);

            // display additional item information beneath the secondaryCombobox
            this.ui.$secondaryComboboxAdditionalInfo.text(`${displayAddress}\n${selectedItemData.senderCity}, ${stateOrProvince} ${selectedItemData.senderPostalCode}`);

            /*
             * tell the model that an item from the secondary combobox has been selected, this
             * must be manually done since viewbinding won't apply to a field like this that
             * sets multiple model values from one selection
             */
            this.model.set(`SECONDARY_${this.secondaryComboboxFieldName}`, selectedItemData.senderName);
        }
        // update the model
        this.model.set(modelSetObject);
    },

    /**
     * Load the secondaryCombobox data for a selected primaryCombobox item and return that data
     * to be displayed.
     * *NOTE* If in the future, we need to add more types of secondaryComboboxes like this for
     * something OTHER than senders, this function will need to have parts of it made more
     * ambiguous or perhaps abstracted to a separate file like accountBalanceHelpTextLoader was
     * @return {Promise} - the request that will be completed when data is retrieved
     */
    loadSecondaryComboboxData() {
        const serviceUrl = services.generateUrl('/inquiry/getCrossProfileSender');

        const currentSelectionData = transform.pairsToHash(this.primaryComboboxWidget.currentSelection.mapDataList, 'toField', 'value');

        const postData = {
            requestHeader: {
                requestId: '5',
                channelId: '11',
                requestUser: {
                    data: {
                        item: [
                            {
                                name: 'USERGROUP',
                                value: userInfo.get('group'),
                            },
                        ],
                    },
                    userId: userInfo.get('id'),
                },
            },
            requestInfo: {
                accountInfo: {
                    bankId: currentSelectionData.DEBIT_BANK_CODE,
                    accountName: currentSelectionData.DEBIT_ACCOUNT_TITLE,
                    accountType: currentSelectionData.ACCOUNT_TYPE,
                    accountNumber: currentSelectionData.DEBIT_ACCOUNT_NUMBER,
                },
            },
        };

        if (!this.selectedSecondaryHasBeenEvaluated) {
            postData.requestInfo.selectedAddress = {
                addressInfo: {
                    senderName: this.model.get('ORIGINATOR_NAME'),
                    senderAddress1: this.model.get('ORIGINATOR_ADDRESS_1'),
                    senderAddress2: this.model.get('ORIGINATOR_ADDRESS_2'),
                    senderCity: this.model.get('ORIGINATOR_CITY'),
                    senderState: this.model.get('ORIGINATOR_STATE'),
                    senderProvince: this.model.get('ORIGINATOR_PROVINCE'),
                    senderCountry: this.model.get('ORIGINATOR_COUNTRY'),
                    senderPostalCode: this.model.get('ORIGINATOR_POSTALCODE'),
                },
                isValid: false, // always send false by default since we don't know
            };
            this.selectedSecondaryHasBeenEvaluated = true;
        }

        return new Promise((resolve, reject) => {
            /*
             * disable the primary combobox and secondary combobox, grey everything out
             * while stuff loads
             */
            this.ui.$doubleComboboxWidgetContainer.addClass('secondary-combobox-loading');
            http.post(serviceUrl, postData, (data) => {
                /*
                 * In rare instances, interaction with the primaryCombobox may act as a driver
                 * field and re-render an MDF. This results in the call to populate the
                 * secondaryCombobox completing on a now irrelevant/outdated view. To account
                 * for that, check here for matching UI elements for this view, and if they're
                 * not present then this view is no longer relevant so stop attempting to
                 * load/display data. We do this again in onPrimaryComboboxWidgetSelectionChange
                 * since this first one only stops us from removing the loading class
                 */
                if (typeof this.ui.$doubleComboboxWidgetContainer !== 'string') {
                    this.ui.$doubleComboboxWidgetContainer.removeClass('secondary-combobox-loading');
                }
                resolve(data);
            }, () => {
                this.ui.$doubleComboboxWidgetContainer.removeClass('secondary-combobox-loading');
                reject();
            });
        });
    },

    /**
     * Once the secondary combobox data has been loaded, store the resultant data
     * @param {Object} data - the data for the secondary combobox
     */
    onSecondaryComboboxLoad(data) {
        /*
         * if the form is loaded with an already selected secondary combobox value, check if
         * it's still valid, and update the model if so
         */
        if (data.responseInfo.selectedAddress && data.responseInfo.selectedAddress.isValid) {
            this.selectedSecondaryIsValid = true;
            this.model.set(`SECONDARY_${this.secondaryComboboxFieldName}`, this.model.get(`${this.secondaryComboboxFieldName}_NAME`), { silent: true });
        } else {
            /*
             * If there is not a valid secondary combobox value selected, then trigger an empty
             * change on the secondaryCombobox to update the model with the the appropriate
             * values from the item within the primaryCombobox
             */
            this.onSecondaryComboboxChange();
        }

        // store the retrieved secondaryCombobox data
        this.secondaryComboboxData = data.responseInfo.addressList
            ? data.responseInfo.addressList.addressInfo : {};
    },

    /**
     * Show/populate the secondaryCombobox and add any applicable validators
     */
    showAndPopulateSecondaryCombobox() {
        const allItems = this.secondaryComboboxData;

        /*
         * destroy and recreate the secondary combobox to remove any currently
         * selected value and allow it to be populated with new ones
         */
        this.ui.$secondaryCombobox.comboBox('destroy');
        this.ui.$secondaryCombobox.empty();
        this.ui.$secondaryCombobox.comboBox({
            dropdownAutoWidth: 'true',
        }).trigger('change:select2');

        // clear the text beneath the secondaryCombobox
        this.ui.$secondaryComboboxAdditionalInfo.text('');

        // enable the secondary combobox
        this.ui.$secondaryCombobox.prop('readonly', false).removeClass('read-only-field');

        // show the secondary combobox
        this.ui.$secondaryComboboxContainer.show();

        // if there were actual items returned when getting secondaryCombobox data
        if (allItems && allItems.length) {
            // remove any error states from the secondaryCombobox
            this.clearNoSecondaryItemsError();

            this.ui.$secondaryCombobox.append(new Option('', '', false, false));

            // add the newly retrieved items to the secondary combobox
            allItems.forEach((item) => {
                this.populateSecondaryCombobox(item);
            });

            if (allItems.length > 1) {
                /*
                 * If there is more than one item for the secondary combobox returned,
                 * Add a validator to ensure the user selects an item
                 */
                this.model.addValidator(`SECONDARY_${this.secondaryComboboxFieldName}`, {
                    exists: true,
                    description: locale.get(`RTGS.INST.INTL.*.SECONDARY_${this.secondaryComboboxFieldName}.description`),
                });

                this.ui.$secondaryComboboxContainer.addClass('required');

                /*
                 * if the currently selected secondary combobox item is valid, reselect it for
                 * the user
                 */
                if (this.selectedSecondaryIsValid) {
                    this.onSecondaryComboboxChange(undefined, { val: this.model.get(`SECONDARY_${this.secondaryComboboxFieldName}`) });
                }
            } else {
                /*
                 * If there is only one item returned for the secondary combobox: select it,
                 * protect the field, and remove its "required" status
                 */
                this.ui.$secondaryCombobox.val(allItems[0].senderName).trigger('change', { val: allItems[0].senderName });
                this.ui.$secondaryCombobox.prop('readonly', true).addClass('read-only-field');
                this.ui.$secondaryComboboxContainer.removeClass('required');
            }
        } else {
            this.showNoSecondaryItemsError();
        }
    },

    /**
     * If there are no valid items for the secondaryCombobox returned, add a validator
     * and validate the attribute so that we may display an error
     */
    showNoSecondaryItemsError() {
        // show/clear any currently selected info
        this.ui.$secondaryComboboxContainer.show();
        this.ui.$secondaryComboboxContainer.find('.select2-chosen').text('');
        this.ui.$secondaryComboboxAdditionalInfo.text('');
        this.model.set(`SECONDARY_${this.secondaryComboboxFieldName}`, '', { silent: true });

        this.model.addValidator(`SECONDARY_${this.secondaryComboboxFieldName}`, {
            exists: true,
            description: `${locale.get('RTGS.error.NoCrossProfileSenderFound')} ${locale.get(`RTGS.INST.INTL.*.SECONDARY_${this.secondaryComboboxFieldName}.description`)}`,
        });

        this.model.validateField(`SECONDARY_${this.secondaryComboboxFieldName}`);
    },

    /**
     * Kinda a hacky way to rid the secondaryCombobox of an error state by setting a temporary
     * value on the model to the corresponding attribute, then removing it right after. An
     * example of when this would be necessary is when switching from a primaryCombobox item
     * that has no valid secondaryCombobox items (thus prompting an error), to a primaryCombobox
     * item that does have valid secondaryCombobox items.
     */
    clearNoSecondaryItemsError() {
        /*
         * if the model already has a value (like when modifying an existing payment/template),
         * then there won't be a "no secondary items" error and we shouldn't overwrite the model
         */
        if (this.model.get(`SECONDARY_${this.secondaryComboboxFieldName}`)) {
            return;
        }

        // trigger a model change event that will clear any validation errors
        this.model.set(`SECONDARY_${this.secondaryComboboxFieldName}`, 'placeholder');
        this.model.set(`SECONDARY_${this.secondaryComboboxFieldName}`, '');
    },

    onBeforeRender() {
        // cleanup any previous comboboxes from prior renders
        if (this.ui.$secondaryCombobox && this.ui.$secondaryCombobox.combobox) {
            this.ui.$secondaryCombobox.comboBox('destroy');
        }
    },

    onRender() {
        // render the primary combobox
        this.primaryComboboxWidgetContainer.show(this.primaryComboboxWidget);

        if (this.state === 'VIEW' || this.primaryComboboxWidgetIsReadOnly) {
            // show the secondary combobox
            this.ui.$secondaryComboboxContainer.show();
        } else {
            // hide the secondary combobox until we're sure we need it
            this.ui.$secondaryComboboxContainer.hide();

            // initialize the secondaryCombobox's combobox
            if (this.showSecondaryCombobox) {
                this.ui.$secondaryCombobox.comboBox({
                    dropdownAutoWidth: 'true',
                });
            }
        }
    },

    /**
     * indicate whether or not the widget is ready to render
     */
    isReadyToRender() {
        return this.primaryComboboxWidget.isReadyToRender();
    },

    serializeData() {
        let secondaryValueToDisplay = '';

        if (this.state === 'VIEW' || this.primaryComboboxWidgetIsReadOnly) {
            const displayAddress = this.configureAddressToDisplay({
                senderAddress1: this.model.get('ORIGINATOR_ADDRESS_1'),
                senderAddress2: this.model.get('ORIGINATOR_ADDRESS_2'),
            });

            // show state if in the US, province otherwise
            const stateOrProvince = this.determineStateOrProvince({
                senderCountry: this.model.get('ORIGINATOR_COUNTRY'),
                senderState: this.model.get('ORIGINATOR_STATE'),
                senderProvince: this.model.get('ORIGINATOR_PROVINCE'),
            });

            secondaryValueToDisplay = `${this.model.get('ORIGINATOR_NAME')} ${displayAddress} ${this.model.get('ORIGINATOR_CITY')}, ${stateOrProvince} ${this.model.get('ORIGINATOR_POSTALCODE')}`;
        }

        return {
            secondaryCombobox: {
                name: this.secondaryComboboxFieldName,
                label: locale.get(`RTGS.INST.INTL.*.SECONDARY_${this.secondaryComboboxFieldName}.label`),
                value: secondaryValueToDisplay,
            },
            showSecondaryCombobox: this.showSecondaryCombobox,
            isReadOnly: this.primaryComboboxWidgetIsReadOnly,
        };
    },
});
