var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n                <div class=\"col-md-12\">\n                        <p><strong>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cashflow.credit.noedit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":70}}}))
    + "</strong></p>\n                </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n                <div class=\"col-md-12\">\n                        <p><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activity.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":11,"column":70}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"CASHFLOWSCENARIO") || (depth0 != null ? lookupProperty(depth0,"CASHFLOWSCENARIO") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"CASHFLOWSCENARIO","hash":{},"data":data,"loc":{"start":{"line":11,"column":71},"end":{"line":11,"column":91}}}) : helper)))
    + "</strong></p>\n                </div>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":30,"column":55},"end":{"line":30,"column":68}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":30,"column":70},"end":{"line":30,"column":85}}}) : helper)))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":46,"column":63},"end":{"line":46,"column":73}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":46,"column":75},"end":{"line":46,"column":84}}}) : helper)))
    + "</option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"widget-form-group required\" data-cashflow-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":67,"column":76},"end":{"line":67,"column":86}}}) : helper)))
    + "\">\n                        <label for=\"cashflow-amount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":68,"column":52},"end":{"line":68,"column":62}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":64},"end":{"line":68,"column":92}}}))
    + "</label>\n                        <input class=\"form-control\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":69,"column":58},"end":{"line":69,"column":66}}}) : helper)))
    + "\" id=\"cashflow-amount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":69,"column":88},"end":{"line":69,"column":98}}}) : helper)))
    + "\" aria-required=\"true\" required data-bind=\"model\" />\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":70,"column":82},"end":{"line":70,"column":90}}}) : helper)))
    + "\"></span>\n                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- we only know that this is a recurring item, nothing to do about it right now -->\n<div class=\"row cashflow-recurring-details\" style=\"margin-bottom:5px;\">\n        <div class=\"col-md-6\">\n                <span class=\"icon-info-circle\"></span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.recurring.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":54},"end":{"line":90,"column":89}}}))
    + "\n        </div>\n        <div class=\"col-md-6\">\n                <label for=\"increase-future-items-by\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.increase.future.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":93,"column":54},"end":{"line":93,"column":95}}}))
    + "</label>\n        </div>\n</div>\n\n<div class=\"row\">\n        <div class=\"col-md-6 checkbox\">\n                <input type=\"checkbox\" name=\"UPDATEFUTUREITEMS\" id=\"update-future-items\" aria-required=\"true\" required data-bind=\"model\" />\n                <label for=\"update-future-items\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.apply.to.future",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":101,"column":16},"end":{"line":101,"column":53}}}))
    + "\n                </label>\n        </div>\n        <div class=\"col-md-6\">\n                <div class=\"row\">\n                <div class=\"col-md-7\">\n                <input class=\"form-control\" name=\"INCREASEBY\" id=\"increase-future-items-by\" aria-required=\"true\" required data-bind=\"model\" value=\"0\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"INCREASEBY\"></span>\n        </div>\n        <div class=\"col-md-5\">\n                <select class=\"form-control\" name=\"amount-type\" id=\"cashflow-amount-type\">\n                        <option value=\"percent\">%</option>\n                        <option value=\"currency\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"currencySymbolString") || (depth0 != null ? lookupProperty(depth0,"currencySymbolString") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currencySymbolString","hash":{},"data":data,"loc":{"start":{"line":113,"column":49},"end":{"line":113,"column":73}}}) : helper)))
    + "</option>\n                </select>\n        </div>\n</div>\n        </div>\n</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- this should only show up when creating a 'new' cash flow item. -->\n        <div class=\"row cashflow-recurring-details\">\n                <div class=\"col-md-6\">\n                        <div class=\"widget-form-group\">\n                                <label for=\"cashflow-recurring-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.recurring",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":126,"column":69},"end":{"line":126,"column":100}}}))
    + "</label>\n                                <select class=\"form-control\" name=\"recurring-type\" id=\"cashflow-recurring-type\">\n                                        <option value=\"never\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.never",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":128,"column":62},"end":{"line":128,"column":89}}}))
    + "</option>\n                                        <option value=\"weekly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.weekly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":129,"column":63},"end":{"line":129,"column":91}}}))
    + "</option>\n                                        <option value=\"monthly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.monthly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":130,"column":64},"end":{"line":130,"column":93}}}))
    + "</option>\n                                        <option value=\"yearly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.yearly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":63},"end":{"line":131,"column":91}}}))
    + "</option>\n                                </select>\n                        </div>\n                </div>\n                <div class=\"col-md-6\">\n                        <div class=\"recurring-until-form-group widget-form-group\">\n                                <label for=\"cashflow-recurring-until\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.until",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":137,"column":70},"end":{"line":137,"column":97}}}))
    + "</label>\n                                <input class=\"form-control\" name=\"RECURRENCEENDDATE\" id=\"cashflow-recurring-until\" aria-required=\"true\" required data-bind=\"model\" disabled/>\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"RECURRENCEENDDATE\"></span>\n                        </div>\n                </div>\n        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n                <div class=\"col-md-6\">\n                        <div class=\"future-entries-form-group\">\n                                <label for=\"cashflow-change-future-entries\">\n                                        <input type=\"checkbox\" name=\"UPDATEFUTUREITEMS\" id=\"cashflow-change-future-entries\">\n                                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.change.future.amt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":151,"column":40},"end":{"line":151,"column":79}}}))
    + "\n                                </label>\n                        </div>\n                </div>\n                <div class=\"col-md-6\"></div>\n        </div>\n\n        <div class=\"row cashflow-future-adjustment-settings\">\n                <div class=\"col-md-6\">\n                        <div class=\"adjustment-type-form-group\">\n                                <label for=\"cashflow-adjustment-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.adjust.more",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":161,"column":70},"end":{"line":161,"column":103}}}))
    + "</label>\n                                <select class=\"form-control\" name=\"adjustment-type\" id=\"cashflow-adjustment-type\">\n                                        <option value=\"\"></option>\n                                        <option value=\"steadyIncrease\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.steady.increase",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":164,"column":71},"end":{"line":164,"column":108}}}))
    + "</option>\n                                        <option value=\"steadyDecrease\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.steady.decrease",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":165,"column":71},"end":{"line":165,"column":108}}}))
    + "</option>\n                                        <option value=\"seasonal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.seasonal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":166,"column":65},"end":{"line":166,"column":95}}}))
    + "</option>\n                                </select>\n                        </div>\n                </div>\n                <div class=\"col-md-6\">\n                </div>\n        </div>\n\n        <div class=\"row cashflow-future-adjustment-settings cashflow-future-adjustment-amounts\">\n                <div class=\"col-md-3\">\n                        <div class=\"adjustment-input-form-group\">\n                                <label for=\"adjustment-amount\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":177,"column":63},"end":{"line":177,"column":91}}}))
    + "</label>\n                                <input class=\"form-control\" name=\"adjustment-amount\"  id=\"cashflow-adjustment-amount\" />\n                        </div>\n                </div>\n                <div class=\"col-md-3\">\n                        <div class=\"adjustment-percent-form-group\">\n                                <label for=\"adjustment-percent\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.percent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":183,"column":64},"end":{"line":183,"column":93}}}))
    + "</label>\n                                <input class=\"form-control\" name=\"adjustment-percent\"  id=\"cashflow-adjustment-percent\" />\n                        </div>\n                </div>\n                <div class=\"col-md-6\">\n                        <div class=\"frequency-type-form-group\">\n                                <label for=\"cashflow-frequency-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.frequency",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":189,"column":69},"end":{"line":189,"column":100}}}))
    + "</label>\n                                <select class=\"form-control\" name=\"frequency-type\" id=\"cashflow-frequency-type\">\n                                        <option value=\"monthly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.monthly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":191,"column":64},"end":{"line":191,"column":93}}}))
    + "</option>\n                                        <option value=\"yearly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.yearly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":192,"column":63},"end":{"line":192,"column":91}}}))
    + "</option>\n                                        <option value=\"weekly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.weekly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":193,"column":63},"end":{"line":193,"column":91}}}))
    + "</option>\n                                        <option value=\"bi-weekly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.biweekly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":194,"column":66},"end":{"line":194,"column":96}}}))
    + "</option>\n                                        <option value=\"bi-monthly\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.bimonthly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":195,"column":67},"end":{"line":195,"column":98}}}))
    + "</option>\n                                </select>\n                        </div>\n                </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCategoryChangeOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"partOfActivity") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n        <div class=\"col-md-6\">\n                <div class=\"widget-form-group required\">\n                        <label for=\"cashflow-description\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":58},"end":{"line":19,"column":91}}}))
    + "</label>\n                        <input class=\"form-control cashflow-description\" name=\"DESCRIPTION\" data-bind=\"model\" id=\"cashflow-description\" aria-required=\"true\" required />\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"DESCRIPTION\"></span>\n                </div>\n        </div>\n\n        <div class=\"col-md-6\">\n                <div class=\"widget-form-group required\">\n                        <label for=\"cashflow-account\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":54},"end":{"line":27,"column":83}}}))
    + "</label>\n                        <select class=\"form-control\" name=\"ACCOUNTID\" id=\"cashflow-account\" data-bind=\"model\"  aria-required=\"true\" required>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":31,"column":41}}})) != null ? stack1 : "")
    + "                        </select>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTID\"></span>\n\n                </div>\n        </div>\n</div>\n\n<div class=\"row\">\n        <div class=\"col-md-6\">\n                <div class=\"widget-form-group required\">\n                        <label for=\"cashflow-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.entry.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":51},"end":{"line":42,"column":83}}}))
    + "</label>\n                        <select class=\"form-control\" name=\"cashflow-type\" id=\"cashflow-type\"  aria-required=\"true\" required>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"types") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":47,"column":41}}})) != null ? stack1 : "")
    + "\n                        </select>\n                </div>\n        </div>\n        <div class=\"col-md-6\">\n                <div class=\"widget-form-group required\">\n                        <label for=\"cashflow-category\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.category",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":55},"end":{"line":54,"column":85}}}))
    + "</label>\n                        <select class=\"form-control cash-flow-category\" name=\"CASHFLOWCATEGORYID\" id=\"cashflow-category\"  aria-required=\"true\" required data-bind=\"model\">\n                        </select>\n\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"CASHFLOWCATEGORYID\"></span>\n                </div>\n        </div>\n</div>\n\n<div class=\"row\">\n        <div class=\"col-md-6\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"types") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":72,"column":25}}})) != null ? stack1 : "")
    + "\n        </div>\n\n        <div class=\"col-md-6\">\n                <div class=\"widget-form-group required\">\n                        <label for=\"cashflow-datepicker\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.entry.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":57},"end":{"line":78,"column":89}}}))
    + "</label>\n                        <input class=\"form-control\" name=\"EXPECTEDDATE\" id=\"cashflow-datepicker\" aria-required=\"true\" required data-bind=\"model\" />\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"EXPECTEDDATE\"></span>\n                </div>\n\n        </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRecurring") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":0},"end":{"line":119,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRecurring") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":0},"end":{"line":143,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPredicted") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":0},"end":{"line":200,"column":7}}})) != null ? stack1 : "");
},"useData":true});