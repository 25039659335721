import CompositeView from '@glu/core/src/compositeView';
import Model from '@glu/core/src/model';
import QuickEntryCollection from 'app/payments/collections/quickEntry';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import scroll from 'common/util/scroll';
import moment from 'moment';
import http from '@glu/core/src/http';
import constants from 'common/dynamicPages/api/constants';
import { log } from '@glu/core';
import services from 'services';
import format from '@glu/core/src/templateHelpers';
import number from 'numeral';
import store from 'system/utilities/cache';
import AlertDetails from 'app/payments/views/quickEntry/alerts/quickEntryAlertDetails';
import PaymentTemplatesList from 'app/payments/collections/quickEntryGroupList';
import workspaceHelper from 'common/workspaces/api/helper';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import helpPageUtil from 'common/util/helpPage';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import dialog from '@glu/dialog';
import domUtil from 'common/util/domUtil';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import ItemListView from './listView';
import template from './listControlView.hbs';

export default CompositeView.extend({
    template,
    className: 'list-controls',
    itemViewContainer: '.quick-entry-list',
    itemView: ItemListView,

    ui: {
        $alertContainer: '.alert-message',
    },

    initialize() {
        this.model = new Model();
        this.collection = new QuickEntryCollection([{}]);

        this.CONFIRM_FIELDS = [{
            apiKey: 'usergroupsequencenumber',
            displayText: `${locale.get('PAY.ID')}: `,
            type: 'text',
        }, {
            apiKey: 'accountNumber',
            displayText: `${locale.get('payment.account.number')}: `,
            type: 'text',
        }, {
            apiKey: 'amount',
            displayText: `${locale.get('payment.amount')}: `,
            type: 'amount',
        }, {
            apiKey: 'beneficiaryName',
            displayText: `${locale.get('payment.beneficiary')}: `,
            type: 'text',
        }, {
            apiKey: 'currency',
            displayText: `${locale.get('payment.currency')}: `,
            type: 'text',
        }, {
            apiKey: 'type',
            displayText: `${locale.get('payment.type')}: `,
            type: 'text',
        }, {
            apiKey: 'valueDate',
            displayText: `${locale.get('payment.value.date')}: `,
            type: 'date',
            format: userInfo.getDateFormat(),
        }, {
            apiKey: 'statusMessage',
            displayText: `${locale.get('payment.result')}: `,
            type: 'text',
        }];

        this.listenTo(
            this.collection,
            {
                'change:TEMPLATE_ID': this.handleCollectionChange,
                'change:AMOUNT': this.handleIndiRateAmount,
                'change:ENTRY_DATE': this.handleIndiRateDate,
                sync: this.handleSaveSuccess,
                error: this.handleSaveError,
                alert: this.handleValidationError,
            },
        );

        // get the workspace help file for quick entry
        store.unset('helpPage');
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'PAY',
            functionCode: 'INST',
            typeCode: '*',
            mode: 'QUICK',
        });
        helpPagePromise.then((results) => {
            store.set('helpPage', results.helpPage);
        });
    },

    handleCollectionChange(model) {
        if (model.get('TEMPLATE').get('isTemplateGroup')) {
            const name = model.get('TEMPLATE_ID');
            const paymentTemplates = new PaymentTemplatesList(
                [],
                {
                    name,
                },
            );

            paymentTemplates.fetch({
                success: util.bind(this.addTemplatesFromGroup, this, model),
            });
        } else {
            // eslint-disable-next-line
            this.showTemplateMax(model.get('TEMPLATE'), this.children._indexByModel[model.cid]);
        }
    },

    addTemplatesFromGroup(groupModel, templateCollection) {
        groupModel.destroy();
        templateCollection.each(function (thisTemplate) {
            this.collection.add(thisTemplate);
            // eslint-disable-next-line
            this.showTemplateMax(
                thisTemplate.get('TEMPLATE'),
                this.children._indexByModel[thisTemplate.cid], // eslint-disable-line
            );
        }, this);
    },

    addQuickEntry() {
        this.collection.add({});
    },

    handleIndiRateAmount(model) {
        if (!model.get('AMOUNT') || !model.get('ENTRY_DATE')) {
            return;
        }
        this.getIndicativeRate(model);
    },

    handleIndiRateDate(model) {
        if (!model.get('ENTRY_DATE')) {
            return;
        }
        this.getIndicativeRate(model);
    },

    getIndicativeRate(model) {
        const url = services.generateUrl(constants.URL_GETINDICATIVERATE_ACTION);
        const templateModel = model.get('TEMPLATE');
        if (templateModel) {
            const type = templateModel.get('typeCode');
            const actionMode = 'INSERT';
            const enteredAmountFlag = templateModel.get('creditDebit').includes('debit') ? 'D' : 'C';
            const creditCurrency = templateModel.get('creditCurrency');
            const debitCurrency = templateModel.get('debitCurrency');
            const status = templateModel.get('STATUS') || 'EN';
            const valueDate = model.get('ENTRY_DATE');

            if (!util.contains(['TRANSFER', 'INTL', 'DRAFT'], type)) {
                return;
            }

            let debitAmount;
            let creditAmount;
            if (enteredAmountFlag === 'C') {
                if (creditCurrency === debitCurrency) {
                    creditAmount = model.get('AMOUNT');
                    debitAmount = model.get('AMOUNT');
                } else {
                    creditAmount = model.get('AMOUNT');
                    debitAmount = ' ';
                }
            } else {
                debitAmount = model.get('AMOUNT');
                creditAmount = ' ';
            }

            const data = {
                enteredAmountFlag,
                creditCurrency,
                debitCurrency,
                debitAmount,
                creditAmount,
                valueDate,
                status,
                typeCode: type,
                actionMode,
            };

            const request = http.post(url, data);
            request.then(
                (result) => {
                    model.set('INDICATIVERATE', result.indicativeRate);
                    model.set('INDICATIVEAMOUNT', result.indicativeAmount);
                },
                function () {
                    log.error(this);
                },
            );
        }
    },

    /*
     * @method showTemplateMax
     * @param {template model} template - the template model
     * @param {string} cid - the view id that the template sits in
     * To show template max for quick entry, if available
     */
    showTemplateMax(templateModel, cid) {
        if (templateModel !== null) {
            const helperblock = this.$(`#QUICKENTRY_TMPL_MAX_AMT_${cid}`);
            if (templateModel.has('TEMPLATE_MAX_AMOUNT') && templateModel.get('TEMPLATE_MAX_AMOUNT') !== '') {
                helperblock.text(`${locale.get('PAY.paymentmaximum')} ${number(templateModel.get('TEMPLATE_MAX_AMOUNT') || 0).format(userInfo.getNumberFormat())} ${templateModel.get('TEMPLATE_MAX_CURRENCY')}`);
            } else {
                helperblock.empty();
            }
        }
    },

    /**
     * save - attempt to save a payment, display error if form is invalid
     */
    save() {
        const self = this;

        if (this.collection.getRequestJSON().quickEntry.length) {
            this.disableButtons(true);
            this.collection.save().then(() => {
                self.disableButtons(false);
            });
        } else {
            this.handleValidationError();
        }
    },

    saveAllowWarning() {
        const self = this;

        if (this.collection.getRequestJSON().quickEntry.length) {
            this.disableButtons(true);
            this.collection.saveAllowWarning().then(() => {
                self.disableButtons(false);
            });
        } else {
            this.handleValidationError();
        }
    },

    handleSaveSuccess(collection, result) {
        this.disableButtons(false);
        this.updateCollectionFromFailedResults(result);
        this.displayUserMessaging(result, collection);
        this.handleScreenDisplay(result);

        if (result && result.status === true) {
            this.collection.reset();
            this.addQuickEntry();
        }
    },

    handleSaveError() {
        this.disableButtons(false);
        const errorAlertView = alert.danger(
            locale.get('anErrorOccurredMsg'),
            {
                canDismiss: true,
                animate: true,
            },
        );

        this.ui.$alertContainer.html(errorAlertView.render().el);
        scroll.scrollToFirstError();
    },

    handleValidationError() {
        this.disableButtons(false);
        const errorAlertView = alert.danger(
            'You must specify a valid template to add an entry.',
            {
                canDismiss: true,
                animate: true,
            },
        );

        this.ui.$alertContainer.html(errorAlertView.render().el);
        scroll.scrollToFirstError();
    },

    /*
     * Loop through the current collection with the intention of updating values
     * based on the failed service results.
     * @param {object} result (response received from server)
     */
    updateCollectionFromFailedResults(result) {
        if (result.totalFail > 0) {
            util.each(result.failed, (obj) => {
                this.collection.forEach((item) => {
                    const sameAmount = parseFloat(item.get('AMOUNT'), 10) === parseFloat(obj.amount, 10);
                    const sameValueDate = moment(item.get('ENTRY_DATE'), userInfo.getDateFormat()).isSame(obj.valueDate);
                    const sameTemplateID = item.get('TEMPLATE_ID') === obj.templateId;

                    if (sameAmount && sameValueDate
                        && sameTemplateID && obj.possibleDuplicate === true) {
                        /*
                         * update collection to say it might be duplicate. The duplicate
                         * screen needs this value.
                         */
                        item.set('POSSIBLEDUPLICATE', true);
                    }
                });
            });
        }
    },

    isSameItem(result, item) {
        // Do we have a success with the same values as the model that model from the screen.
        const sameAmount = parseFloat(item.get('AMOUNT'), 10) === parseFloat(result.amount, 10);
        const sameValueDate = moment(item.get('ENTRY_DATE'), userInfo.getDateFormat()).isSame(result.valueDate);
        return sameAmount && sameValueDate;
    },

    handleScreenDisplay(result) {
        if (result.totalSuccess > 0) {
            util.each(result.successful, (obj) => {
                const matchedModel = this.collection.find(item => this.isSameItem(obj, item));
                if (matchedModel) {
                    this.collection.remove(matchedModel);
                }
            });

            if (this.collection.length === 0) {
                this.addQuickEntry();
            }
        }
    },

    disableButtons(state) {
        // TODO: could this be in the ui hash ?
        domUtil.setDisabled(this.$('button[data-action="save"]'), state);
    },

    /**
     * Display success and/or error message
     * @param {object} resultParam - JSON response from the quickEntry endpoint
     * @param {object} collection
     */
    displayUserMessaging(resultParam, collection) {
        const result = resultParam;
        /*
         * If the user just accepted a duplicate warning
         * we do not want to clear any previous alerts.
         * There could be successes from the previous submit that the user still cares about.
         */
        if (!result.duplicateAccepted) {
            this.ui.$alertContainer.empty();
        }

        // errorCode 540 means at least one of the results is a duplicate warning message.
        if (result.errorCode === 540) {
            result.confirms.confirmResults = result.confirms.confirmResults.filter(confirmResults => confirmResults.resultType === 'DUPLICATEWARNING');
            const duplicateDialog = new DuplicateDialog({
                resp: result,
                quickEntryCollection: collection,
                methodName: 'INSERT',
                isQE: true,
            });
            duplicateDialog.once('saveQE', this.save, this);
            dialog.custom(duplicateDialog);
        // errorCode 550 means at least one of the results is a general warning message.
        } else if (result.errorCode === 550) {
            const warningDialog = new WarningDialog({
                isQE: true,
                quickEntryCollection: collection,
                methodName: 'SAVE',
                confirms: result.confirms,
            });
            warningDialog.once('saveQEWithWarning', this.saveAllowWarning, this);
            dialog.custom(warningDialog);
        } else if (result.totalFail > 0) {
            const errorMessage = `${result.totalFail} ${result.totalFail > 1 ? locale.get('payment.payment.plural') : locale.get('payment.payment')} ${locale.get('payment.failed')}`;

            // display notification message
            const errorAlertView = alert.danger(
                errorMessage,
                {
                    details: new AlertDetails({
                        errors: result.failed,
                    }),

                    canDismiss: true,
                    animate: true,
                },
            );

            this.ui.$alertContainer.append(errorAlertView.render().el);
        }

        if (result.totalSuccess > 0) {
            const successMessage = `${result.totalSuccess + (result.totalSuccess > 1 ? ` ${locale.get('payment.payment.plural')}` : ` ${locale.get('payment.payment')}`)} ${locale.get('payment.processed')}`;

            const confirmResponse = {
                confirms: {
                    confirmResults: [],
                },
            };

            util.each(result.successful, function (row) {
                const items = [];

                util.each(this.CONFIRM_FIELDS, (field) => {
                    const { apiKey } = field;
                    if (row[apiKey]) {
                        let v = row[apiKey];
                        const f = field.format;

                        if (format[field.type]) {
                            v = format[field.type](v, f);
                        }

                        items.push({
                            name: field.displayText,
                            value: v,
                        });
                    }
                }, this);

                confirmResponse.confirms.confirmResults.push({
                    confirmData: [{
                        item: items,
                    }],
                });
            }, this);

            // display notification message
            const successAlertView = alert.success(
                successMessage,
                {
                    details: new Confirms({
                        confirms: confirmResponse.confirms,
                    }),

                    canDismiss: true,
                    animate: true,
                },
            );

            this.ui.$alertContainer.append(successAlertView.render().el);
        }
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },
});
