import Controller from './controller';
import util from './util';

export default Controller.extend({
  mutationDelay: 1000,
  mutationDecay: 200,
  mutationMaxDelay: 10000,
  mutationMinDelay: 500,
  disconnected: false,

  initialize(options) {
    this.mutationDelay = options.mutationDelay || this.mutationDelay;
    this.mutationDecay = options.mutationDecay || this.mutationDecay;
    this.mutationMaxDelay = options.mutationMaxDelay || this.mutationMaxDelay;

    this.options = util.extend({
      subtree: true,
      childList: true
    }, options);

    this.mutationSupport = !(typeof window.MutationObserver === 'undefined');
  },

  observe(el) {
    this.el = el;
    if (this.mutationSupport) {
      this.bindMutationObserver();
    } else {
      this.mutationSnapshot = el.innerHTML;
      this.startMutationPoller();
    }
    this.disconnected = false;
  },

  disconnect() {
    if (this.disconnected) {
      return true;
    } else if (this.mutationSupport) {
      this.mutationObserver.disconnect();
    } else {
      clearTimeout(this.mutationTimer);
    }
    this.disconnected = true;

    return undefined;
  },

  bindMutationObserver() {
    const self = this;
    this.mutationObserver = new window.MutationObserver(((mutations) => {
      self.trigger('change', self.el, mutations);
    }));

    this.mutationObserver.observe(this.el, this.options);
  },

  startMutationPoller() {
    clearTimeout(this.mutationTimer);
    this.mutationTimer = setTimeout(() => {
      const html = this.el.innerHTML;
      if (this.disconnected) {
        return true;
      } else if (this.mutationSnapshot !== html) {
        this.trigger('change', this.el);
        this.mutationDelay -= this.mutationDecay;
        if (this.mutationDelay < this.mutationMinDelay) {
          this.mutationDelay = this.mutationMinDelay;
        }
      } else {
        this.mutationDelay += this.mutationDecay;
        if (this.mutationDelay > this.mutationMaxDelay) {
          this.mutationDelay = this.mutationMaxDelay;
        }
      }
      this.mutationSnapshot = html;
      this.startMutationPoller();

      return undefined;
    }, this.mutationDelay);
  }

});

