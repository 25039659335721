const htmlDecode = (input) => {
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

const transformDetails = details => Object.keys(details).map(item => ({
    [item]: htmlDecode(details[item]),
})).reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    return {
        ...acc,
        [key]: curr[key],
    };
}, {});

function getEntitlementsOptions(typeCode) {
    return {
        typeCode,
        productCode: 'CFD',
        functionCode: 'MAINT',
        entryMethod: 0,
        overrideContext: {
            serviceName: '/userMaintenance/user',
        },
    };
}

export const outsourcePrintEntitlementsOptions = getEntitlementsOptions('CFDPRFOS');

export const outsourcePrintEntitlements =
    [outsourcePrintEntitlementsOptions];

export { transformDetails };
