import util from '@glu/core/src/util';
import $ from 'jquery';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import locale from '@glu/locale';
import PaymentUtil from 'common/util/paymentUtil';
import fxPaymentUtil from 'common/util/fxPaymentUtil';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const formState = form.formView.state;
    const { model } = form.formView;
    const { functionCode } = model.jsonData.typeInfo;
    const beneCountry = form.field('BENE_COUNTRY');
    const hidecutoff = serverConfigParams.get('hideCutoff');
    const originatorIdType = form.field('ORIGINATOR_ID_TYPE');
    const originatorId = form.field('ORIGINATOR_ID');
    const creditAmount = form.field('CREDIT_AMOUNT');
    const debitAmount = form.field('DEBIT_AMOUNT');
    const readonlyFieldArr = ['DEBIT_ACCOUNT_BANK_NAME', 'DEBIT_ACCOUNT_TITLE', 'ORIGINATOR_NAME', 'ORIGINATOR_ADDRESS_1', 'ORIGINATOR_ADDRESS_2', 'ORIGINATOR_CITY', 'ORIGINATOR_COUNTRY'];
    const changeableFields = ['VALUE_DATE', 'CREDIT_AMOUNT', 'DEBIT_AMOUNT', 'CUSTOMER_REFERENCE', 'SPECIAL_INSTRUCTIONS', 'OBI_TEXT_1', 'OBI_TEXT_2', 'OBI_TEXT_3', 'OBI_TEXT_4', 'CREDIT_CURRENCY', 'CREDIT_DEBIT_RADIO_BTN'];
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;
    const creditCurrency = form.field('CREDIT_CURRENCY');
    const showHideReadOnly = (array, controlField) => {
        util.each(array, (fieldName) => {
            if (form.field(controlField).isNotEmpty()) {
                $(`#${fieldName}`).removeClass('hidden');
            } else {
                $(`#${fieldName}`).addClass('hidden');
            }
        });
    };
    const protectFields = () => {
        Object.keys(form.fields || {}).forEach((fieldName) => {
            if (changeableFields.indexOf(fieldName) === -1) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
        });
        $('.lookup').addClass('hidden');
    };
    /**
     * When USD only, empty other options and append USD
     */
    const evaluateUSDOnly = () => {
        if (form.formView.state !== 'view') {
            const $creditCurrency = creditCurrency.$el;
            if (fxPaymentUtil.evaluateUSDOnly(model, form.formView.comboCollections)) {
                const newOption = new Option(locale.get('PAY.USD'), 'USD', true, true);
                // remove previous options and append only USD
                $creditCurrency.empty().append(newOption).trigger('change');
                $creditCurrency.prop('disabled', true);
            } else {
                $creditCurrency.prop('disabled', false);
            }
        }
    };

    if (initialState) {
        // TEMPORARY Fix: NH-33472
        form.field('UPDATEADDRESSBOOK').shouldBeHidden();
        // end NH-33472
        rtgsCommon.getAccountBalances(
            form.formView, 'DEBIT_ACCOUNT_NUMBER', 'RTGS', 'DRAFT',
            'DEBIT_BANK_CODE', 'DEBIT_CURRENCY',
        );

        model.on('change:CREDIT_CURRENCY', evaluateUSDOnly);

        if (functionCode === 'BHTMPL' || functionCode === 'TMPL') {
            if (form.formView.state.toUpperCase() === 'INSERT') {
                /*
                 * first time in.. initial state.. TMPL.. lock icon should be disabled for no
                 * amount value
                 */
                const iconContainer = $('#CREDIT_AMOUNT').closest('.field-container').children('.mdf-input-icon-container').get(0);
                if (iconContainer && iconContainer.children[0]) {
                    iconContainer.children[0].classList.add('disabled');
                }
            }
        }

        const amountField = model.get('ENTERED_AMOUNT_FLAG') === 'C'
            ? 'CREDIT_AMOUNT' : 'DEBIT_AMOUNT';
        const amtBlock = form.formView.$(`[data-validate="${amountField}"]`);
        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            form.formView,
            model,
            amtBlock,
            functionCode,
        );

        evaluateUSDOnly();
    }
    if (form.formView.state === 'insert') {
        // Hide the audit section
        const aFieldInAuditSection = form.field('ENTERED_TIMESTAMP');
        aFieldInAuditSection.$el.closest('.section').hide();
    }

    if (formState !== 'view') {
        showHideReadOnly(readonlyFieldArr, 'DEBIT_ACCOUNT_NUMBER');
    }

    /*
     * hide cutoff if conditions are true  (EN-Entered, IA-Incomplete Approval, RT-Needs
     * Rate, HV-2nd Approval Required are status defined in database)
     */
    rtgsCommon.hideCutoff(formState, functionCode, hidecutoff, 'DRAFT', form.formView.model.get('STATUS'));

    if (functionCode === 'TMPL' && (formState === 'modify' || formState === 'restore')) {
        form.field('TEMPLATE_CODE').shouldBeReadOnly(true);
        model.set('Debit_Currency', model.get('DEBIT_CURRENCY'));
    }

    originatorId
        .shouldBeRequiredWhen(originatorIdType.isNotEmpty());

    if (model.get('ENTRYMETHOD') && (model.get('ENTRYMETHOD') === '1' || model.get('ENTRYMETHOD') === '2')) {
        protectFields();
    }

    /*
     * if country is US or not defined, then show state drop down,
     * if not, then show province/State textbox
     */
    if (!beneCountry.getValue() || beneCountry.getValue() === 'US') {
        $('input[name="BENE_PROVINCE"]').closest('.field-container').hide();
        $('select[name="BENE_STATE"]').closest('.field-container').show();
    } else {
        $('input[name="BENE_PROVINCE"]').closest('.field-container').show();
        $('select[name="BENE_STATE"]').closest('.field-container').hide();
    }

    // update lock icon if needed
    if (creditAmount.$el && creditAmount.$el.length > 0) {
        PaymentUtil.runChangeAmount(creditAmount.$el[0], model);
    }
    if (debitAmount.$el && debitAmount.$el.length > 0) {
        PaymentUtil.runChangeAmount(debitAmount.$el[0], model);
    }

    rtgsCommon.toggleStateProvince(
        model, model.get('BENE_COUNTRY'),
        $('input[name="BENE_PROVINCE"]').closest('.field-container'),
        $('select[name="BENE_STATE"]').closest('.field-container'),
    );
}
