import EntryView from 'common/dynamicPages/views/workflow/entry';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import PassThroughDetailView from 'app/payments/views/passthroughDetail';
import ContextApi from 'common/dynamicPages/api/context';
import PageApi from 'common/dynamicPages/api/view';
import gridApi from 'common/dynamicPages/api/grid';
import DataApi from 'common/dynamicPages/api/data';
import Constants from 'app/administration/constants';
import store from 'system/utilities/cache';
import BABConstants from 'app/bab/constants';
import AddressBookAPI from 'app/bab/service/addressBook';
import ContactView from 'app/bab/views/viewContact';
import transform from 'common/util/transform';
import { appBus } from '@glu/core';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import MaskToggleUtil from 'common/util/maskToggleUtil';
import importViewTmpl from './importView.hbs';

export default EntryView.extend({
    template: importViewTmpl,

    initialize(options) {
        this.contextDef = options.contextDef
            || ContextApi.menuContext.getContext(options.context);
        this.dialogTitle = this.getTitle();

        this.dialogButtons = [{
            text: locale.get('administration.close.btn'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
        this.shouldShowMaskToggle = false;
        if (options.action === 'select') {
            ({ shouldShowMaskToggle: this.shouldShowMaskToggle }
                = MaskToggleUtil.initMaskToggle());
        }
    },

    onRender() {
        this.loadPage();
        /* In view mode and unmasking is allowed, then listen for the
           toggle change event
        */
        if (this.shouldShowMaskToggle) {
            this.listenTo(appBus, 'toggleSwitchChanged', this.toggleAccountMasking);
        }
    },

    loadPage() {
        const self = this;
        if (this.hasLoadedRequiredData()) {
            if (this.model.get('TYPE') === 'BENEADBK') {
                self.model.set('id', self.model.get('TNUM'));
                store.set(BABConstants.STORE_BAB_CONTEXT, self.model.context);
                store.set(BABConstants.STORE_BAB_VIEWMODEL, self.model);
                /*
                 * self.handleEntitlements();
                 * self.navigateTo('SETUP/viewContact');
                 */
                this.beneViewContact();
            } else if (this.model.get('TYPE') === 'PASSTHRU' || this.model.get('TYPE') === 'BEFTPASS') {
                self.model.buttons = [];
                store.set('SURROGATETNUM', self.model.get('TNUM'));
                store.set('passthru_view_payment_model', self.storedModel);
                this.pageView = new PassThroughDetailView({
                    isModal: true,
                    inquiryId: Constants.IMPORTVIEW_INQUIRYID,
                });
                this.pageContent.show(this.pageView);
            } else {
                // Overide Visible setting if transfer time is disabled
                if (applicationConfigParams.getValue('TRANSFER', 'ENABLETRANSFERTIME') !== '1' && self.model.get('TYPE') === 'TRANSFER') {
                    self.storedModel.fieldData.TRANSFER_TIME.visible = false;
                }

                this.contextDef.serviceName = this.model.get('SERVICENAME');
                this.pageView = PageApi.page.get({
                    context: self.model.context,
                    model: self.storedModel,
                    state: 'view',
                    hideButtons: true,
                    importView: true,
                    inModal: true,
                    gridApi,
                });

                this.pageContent.show(this.pageView);
                this.getPaymentMessage();
            }
        } else {
            this.loadRequiredData();
        }
    },

    beneViewContact() {
        const model = store.get(BABConstants.STORE_BAB_VIEWMODEL);
        const addrAPI = new AddressBookAPI();
        const self = this;

        if (model) {
            addrAPI.read(model.get('id')).then((result) => {
                const data = transform.pairsToHash(result.item);

                self.pageView = new ContactView({
                    mode: 'view',
                    data,
                    state: 'modal',
                });
                self.pageContent.show(self.pageView);
            }, (result) => {
                dialog.alert(result);
                appBus.trigger('router:navigate', 'SETUP/viewContact', true);
            });
        } else {
            appBus.trigger('router:navigate', 'SETUP/viewContact', true);
        }
    },

    loadRequiredData() {
        const self = this;
        const { model } = this;

        const actData = {
            actionMode: 'SELECT',
            productCode: model.get('PRODUCT'),
            functionCode: model.get('FUNCTION'),
            typeCode: model.get('TYPE'),
            subType: model.get('SUBTYPE'),
            entryMethod: model.get('ENTRYMETHOD'),
            entryClass: model.get('ENTRYCLASS'),
        };
        const keyListGlobal = ['TNUM', 'UPDATECOUNT__'];
        const contextData = {
            serviceName: model.get('SERVICENAME'),
            actionData: actData,
            keyList: keyListGlobal,
        };

        model.context = contextData;

        if (model.get('TYPE') !== 'BENEADBK') {
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    success() {
                        self.storedModel = newModel;
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },
                });
            });
        } else {
            this.hasLoaded = true;
            self.render();
        }
    },

    // TODO:does this ever get used
    dialogTitle: locale.get('administration.import.details'),

    modalClass: 'modal-xlg',

    getTitle() {
        const { model } = this;
        const typeDesc = model.get('TYPEDESC');
        const type = model.get('TYPE');
        const beneficiary = model.get('BENEFICIARY');
        let str1 = beneficiary;

        if (type === 'BENEADBK') {
            return beneficiary;
        }
        if (type === 'CIMINSTT' || type === 'STCAIMPT') {
            return locale.get(typeDesc);
        }
        if (beneficiary === 'MULTI') {
            str1 = locale.get('fih.MULTI');
        }
        return typeDesc + Constants.SPACE + locale.get('fih.TO') + Constants.SPACE + str1;
    },

    /**
     * Close the modal
     * @method cancel
     */
    cancel() {
        if (dialog.activeModal) {
            dialog.close();
        } else {
            /*
             * - The dialog in this view contains a false activeModal value
             *  when returning from a 2nd modal (probably need to fix that UX pattern)
             * - Rather than depending on this view's interaction with other modal views
             *  (and potential for unintentionally overriding this modal's properties),
             *  we trigger a click on what we know is the close button for this modal
             */
            this.$el.closest('.modal-content').find('.modal-header .close').trigger('click');
        }
    },
});
