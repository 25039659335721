import GluModel from '@glu/core/src/model';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';
import sbPaymentsApi from 'app/smb/api';
import dateUtil from 'common/util/dateUtil';
import CommentModel from './largeComment';

let Model = GluModel;

export default function () {
    Model = Model.extend({
        defaults: {
            fromLoanId: '',
            toAccountId: '',
            amount: '',
            paymentDate: '',
            comment: new CommentModel(),

            paymentDisplayDate() {
                /*
                 * Is the default date format ok to use here?
                 * Do we need DD MMM YYYY specifically?
                 */
                return Formatter.formatDate(
                    this.paymentDate,
                    dateUtil.PAYMENT_SUMMARY_DATE_FORMAT,
                );
            },

            displayAmount() {
                const amount = this.amount ? this.amount : 0;
                return Formatter.formatCurrency(amount);
            },
        },

        initialize() {
            this.validators = {
                fromLoanId: {
                    description: locale.get('sbPayments.fromLoan'),
                    exists: true,
                },

                toAccountId: {
                    description: locale.get('sbPayments.toAccount'),
                    exists: true,
                    otherDescription: locale.get('sbPayments.fromLoan'),
                    notSameValue: 'fromLoanId',
                },

                amount: {
                    description: locale.get('sbPayments.amount'),
                    matches: sbPaymentsApi.AMOUNT_PATTERN,
                },

                paymentDate: {
                    description: locale.get('sbPayments.effectiveDate'),
                    exists: true,
                    matches: sbPaymentsApi.DATE_PATTERN,
                },
            };
        },

        displayBalance(balance) {
            if (balance !== locale.get('sbPayments.notAvailable')) {
                return Formatter.formatCurrency(balance);
            }
            return balance;
        },

        reset(...args) {
            Model.prototype.clear.apply(this, args);
            this.set(util.clone(this.defaults));
        },
    });
    return new Model();
}
