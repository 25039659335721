import constants from 'app/balanceAndTransaction/constants';
import transactionListModalViewTmpl from 'app/balanceAndTransaction/views/depositAccounts/transactionListModalView.hbs';
import TransactionListView from './depositTransactionListView';

const CustomReportDepositTransactionListView = TransactionListView.extend({
    template: transactionListModalViewTmpl,
    className: 'transactionDetails',

    initialize(options) {
        TransactionListView.prototype.initialize.call(this, options);
        this.serviceName = options.serviceName;
    },

    /**
     * provides a part of The URL to hit for Custom Reporting Deposit Transactions
     * @method getServiceName - extend
     * @return {String} A part of the URL to hit for Custom Reporting Deposit
     * Transactions
     */

    getServiceName() {
        return this.serviceName || `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}getCombinedReportingTransactions`;
    },
});

export default CustomReportDepositTransactionListView;
