import locale from '@glu/locale';
import moment from 'moment';
import DateRangeFilter from '../DateRangeFilter/DateRangeFilter';
import EnumFilter from '../EnumFilter/EnumFilter';
import FuzzyFilter from '../FuzzyFilter/FuzzyFilter';
import NumberFilter from '../NumberFilter/NumberFilter';
import MultiSelectFilter from '../MultiSelectFilter/MultiSelectFilter';
import TypeaheadFilter from '../TypeaheadFilter/TypeaheadFilter';
import RangeFilter from '../RangeFilter/RangeFilter';

export const DISPLAY_DATE_FORMAT = 'YYYY-MM-DD';

export const createFilterValueFunction = (filterable) => (...args) => {
  if (filterable.type === 'typeahead') {
    const [, field, input] = args;
    return {
      criteriaDisplay: input.name,
      field,
      filterData: {
        filter: input.name,
        filterType: 'text',
        type: 'contains'
      },
      id: `${field.toLowerCase()}-typeahead-${input.name.trim().split(' ').join('-')}`,
      nameDisplay: filterable.headerName
    };
  }

  if (filterable.type === 'multiselect') {
    const [, field, values] = args;
    return values.map((value) => ({
      criteriaDisplay: value.name,
      field,
      filterData: {
        filter: value.value,
        filterType: 'text',
        type: 'contains'
      },
      id: `${field}-multi-${value.name}`,
      nameDisplay: filterable.headerName,
      operator: 'OR'
    }));
  }

  if (filterable.type === 'date') {
    const [, field, format, dates] = args;
    const [dateFrom, dateTo] = dates;
    const datesAreSame = dateFrom && dateTo && dateFrom.isSame(dateTo, 'day');

    const criteriaDisplay = !datesAreSame
      ? `${dateFrom.format(format)} ${locale.get('dataComponents.to')} ${dateTo.format(format)}`
      : dateFrom
        ? `${dateFrom.format(format)}`
        : undefined;

    return {
      criteriaDisplay,
      field,
      filterData: {
        dateFrom: dateFrom?.format(DISPLAY_DATE_FORMAT),
        dateTo: dateTo?.format(DISPLAY_DATE_FORMAT),
        filterType: 'date',
        format: DISPLAY_DATE_FORMAT,
        inRangeInclusive: 'true',
        type: 'inRange'
      },
      id: `${field}-inRange${criteriaDisplay ? `-${criteriaDisplay}` : ''}`,
      nameDisplay: filterable.headerName
    };
  }

  if (filterable.type === 'number') {
    const [, field, values] = args;
    const {
      numberType,
      value
    } = values;
    const criteriaDisplay = numberType === 'between'
      ? locale.get('dataComponents.betweenCriteria', value.start, value.end)
      : `${locale.get(`dataComponents.${numberType}`)} ${value}`;
    if (!value || !numberType) {
      // Will be removed from active filters
      return null;
    }
    return {
      criteriaDisplay,
      field,
      filterData: {
        filter: value,
        filterType: 'number',
        type: numberType
      },
      id: `${field}-contains-${criteriaDisplay}`,
      nameDisplay: filterable.headerName
    };
  }

  if (filterable.type === 'range') {
    const [, field, values] = args;
    let type = 'inRange';
    const {
      end,
      start
    } = values;
    const criteriaDisplay = `${start} ${locale.get('dataComponents.to')} ${end}`;
    const isRange = end && start;
    if (!start) {
      type = 'lessThanOrEqual';
    }

    if (!end) {
      type = 'greaterThanOrEqual';
    }

    if (!start && !end) {
      return null;
    }

    return {
      criteriaDisplay,
      field,
      filterData: {
        filter: start || end,
        type,
        ...(isRange && { filterTo: end, inRangeInclusive: 'true' }),
        filterType: 'number'
      },
      id: `${field}-inRange-${criteriaDisplay}`,
      nameDisplay: filterable.headerName
    };
  }

  if (filterable.type === 'enum') {
    const [, field, selection] = args;
    const { name, value } = selection;
    const criteriaDisplay = name;
    if (!value) {
      // Will be removed from active filters
      return null;
    }
    return {
      criteriaDisplay,
      field,
      filterData: {
        filter: value,
        filterType: 'text',
        type: 'equals'
      },
      id: `${field}-enum-${criteriaDisplay}`,
      nameDisplay: filterable.headerName
    };
  }

  const [, field, value] = args;
  const criteriaDisplay = `${locale.get('dataComponents.contains')} ${value}`;
  if (!value) {
    // Will be removed from active filters
    return null;
  }
  return {
    criteriaDisplay,
    field,
    filterData: {
      filter: value,
      filterType: 'text',
      type: 'contains'
    },
    id: `${field}-contains-${criteriaDisplay}`,
    nameDisplay: filterable.headerName
  };
};

export const createParseFilterFunction = (filterable) => ({ filters }) => {
  if (filterable.type === 'multiselect') {
    return filters.map((filter) => filter.filterData.filter);
  }
  const { filterData } = filters[0] || {};

  if (filterable.type === 'date') {
    return filterData
      ? [
        moment(filterData.dateFrom, DISPLAY_DATE_FORMAT),
        moment(filterData.dateTo, DISPLAY_DATE_FORMAT)
      ]
      : [];
  }

  if (filterable.type === 'range') {
    if (!filterData) {
      return ({ end: '', start: '' }); // first render we don't have filterDara
    }
    const noStart = filterData.type === 'lessThanOrEqual'; // we have to distinguish when we have only start or end value
    return ({
      end: noStart ? filterData.filter : filterData.filterTo || '',
      start: noStart ? '' : filterData.filter
    });
  }
  if (!filterData) {
    return '';
  }
  if (filterable.type === 'number') {
    return filterData;
  }

  return filterData.filter;
};

export const getFilterComponent = (type) => {
  switch (type) {
    case 'date':
      return DateRangeFilter;
    case 'enum':
      return EnumFilter;
    case 'multiselect':
      return MultiSelectFilter;
    case 'number':
      return NumberFilter;
    case 'typeahead':
      return TypeaheadFilter;
    case 'range':
      return RangeFilter;
    case 'string':
    default:
      return FuzzyFilter;
  }
};

export const combineFilters = (state, { filter, name, reset }) => {
  if (reset) {
    return reset;
  }
  // remove current filter from list
  const clearedState = state.filter((item) => item.field !== name);

  if (!filter) {
    return clearedState;
  }

  return [...clearedState, filter].flat();
};
