import { createUseStyles } from '@glu/theming';

const styles = ({ grid: { search } }) => ({
  button: {
    '& svg': {
      fill: search.buttonColor,
      height: 18,
      left: 5,
      position: 'absolute',
      top: 5,
      width: 18
    },
    '&:hover, &:focus': {
      '& svg': {
        fill: 'white'
      },
      background: search.buttonColor
    },
    background: 'transparent',
    border: 0,
    borderRadius: 100,
    cursor: 'pointer',
    height: 28,
    position: 'absolute',
    right: 4,
    top: 6,
    width: 28
  },
  clear: {
    '& svg': {
      fill: search.clearButtonIcon,
      height: 8,
      left: 4,
      position: 'absolute',
      top: 4,
      width: 8
    },
    '&:hover, &:focus': {
      '& svg': {
        fill: 'white'
      },
      background: search.clearButtonBackgroundHover
    },
    background: search.clearButtonBackground,
    border: 0,
    borderRadius: 100,
    cursor: 'pointer',
    height: 16,
    position: 'absolute',
    right: 38,
    top: 12,
    width: 16
  },
  root: {
    '& input': {
      margin: 0,
      paddingRight: 60
    },
    '& label, & p': {
      margin: 0
    },
    position: 'relative'
  }
});

export default createUseStyles(styles);
