import Layout from '@glu/core/src/layout';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import FlexDropdown from '@glu/flex-dropdown';
import services from 'services';
import store from 'system/utilities/cache';
import alert from '@glu/alerts';
import constants from 'common/dynamicPages/api/constants';
import RTPMessageModel from 'app/payments/models/realTimePayments/rtpMessageModel';
import rtpAddToConversationTmpl from './rtpAddToConversation.hbs';

export default Layout.extend({
    className: 'Add-To-Conversation-View',

    template: rtpAddToConversationTmpl,

    regions: {
        reasonDropdownRegion: '[data-region="reasonDropdownRegion"]',
    },

    ui: {
        $RFIBtn: '[data-hook="getRFIBtn"]',
        $PABtn: '[data-hook="getPABtn"]',
        $cancelBtn: '[data-hook="getCancelBtn"]',
        $sendMessageBtn: '[data-hook="getSendMessageBtn"]',
        $requestFlagRadioBtn: '[data-hook="getRequestFlagRadioBtn"]',
        $characterCount: '[data-hook="getCountField"]',
        $characterDisplay: '[data-hook="getCountDisplay"]',
        $validationLabel: '[data-hook="getValidationLabel"]',
        $RFIPAInput: '[data-hook="getRFIPAinput"]',
        $RFIPABtns: '[data-hook="getRFIPABtns"]',
    },

    events: {
        'click @ui.$requestFlagRadioBtn': 'handleReasonClick',
        'keyup @ui.$characterCount': 'updateCharacterCount',
        'input @ui.$characterCount': 'updateCharacterCount',
        'click @ui.$RFIBtn': 'showInputs',
        'click @ui.$PABtn': 'showInputs',
        'click @ui.$cancelBtn': 'resetInputs',
        'click @ui.$sendMessageBtn': 'sendMessage',
    },

    initialize(options) {
        this.options = options;
        this.conversationStarted = options.conversationStarted;
        this.canSendRFI = this.model.get('RTPREQ') === '1' && this.model.get('RFIPARTICIPANT') === '1';
        this.canSendACK = this.model.get('RTPACK') === '1' && this.model.get('PAYMENTACKPARTICIPANT') === '1';
        this.canSendRFIResponse = this.model.get('RFIRESPPARTICIPANT') === '1';
        const rtpTab = store.get('payment_rtp-currentTab');
        if (rtpTab) {
            this.currentTab = rtpTab.toLowerCase();
        } else {
            this.currentTab = (this.model.get('TYPE') === 'CRTRAN') ? 'submitted' : 'received';
            if (this.model.get('TYPE') === 'GIRTRANS' || this.model.get('TYPE') === 'GIRTRNSR' || this.model.get('TYPE') === 'GIRTRSR2') {
                this.currentTab = (this.model.get('BAI_CODE') === '458') ? 'submitted' : 'received';
            }
        }
        this.model = this.resetModel();
        this.maxMessageCharacters = 140;
        this.maxMessagesByTypeCount = 10;
        this.maxMessagesCount = 20;
    },

    // Don't run all of the good stuff if we need to re-render immediately
    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.checkForAlerts();
            this.reasonDropdownRegion.show(this.reasonDropdown);
            this.windowHeight = window.innerHeight;
            this.elemHeight = this.$el.outerHeight();
            this.updateCharacterCount();
        } else {
            this.loadRequiredData();
        }
    },

    resetModel() {
        const model = new RTPMessageModel(this.options.model.attributes);
        /**
         * we want input mode to be true immediately if we are in
         * the submitted tab because there are no buttons to push to
         * determine if we are sending an RFI or PA
         */
        model.set({
            INPUT_MODE: this.currentTab === 'submitted',
            RESPONSE_MODE: this.currentTab === 'submitted',
        });
        this.listenTo(this.model, 'change', this.checkForAlerts);
        return model;
    },

    checkForAlerts() {
        if (this.model.get('MESSAGE_REQUIRED') && !this.model.get('RESPONSE_MODE')) {
            this.showAlert({
                type: 'warning',
                message: locale.get('rtp.otherErrorMessage'),
            });
        }
    },

    updateCharacterCount() {
        if (this.ui.$characterCount.length < 1) {
            return;
        }

        const count = this.maxMessageCharacters - (this.ui.$characterCount.val().length);
        this.ui.$characterDisplay.text(count);
        this.ui.$characterDisplay.parent().toggleClass('over-limit', count < 0);
    },

    handleReasonClick/* istanbul ignore next */(e) {
        e.stopPropagation();
        const { value } = e.currentTarget;

        this.model.set({
            REQUESTFORINFO_REASON_FLAG: value,
            REQUESTFORINFO_REASON_INFO_CODE: '',
            REQUESTFORINFO_REASON: '',
        });

        this.setHasLoadedRequiredData(false);

        this.render();
    },

    setReasonFlag() {
        const currentReasonFlag = this.model.get('REQUESTFORINFO_REASON_FLAG');
        let newReasonFlag;

        // if neither are at limit then return
        if (this.msgData.incorrectInfoCount < this.maxMessagesByTypeCount
            && this.msgData.missingInfoCount < this.maxMessagesByTypeCount) {
            return;
        }
        if (currentReasonFlag === 'I' && this.msgData.incorrectInfoCount >= this.maxMessagesByTypeCount) {
            // if current reason flag is I and incorrect has reached limit switch it to M
            newReasonFlag = 'M';
        } else if (currentReasonFlag === 'M' && this.msgData.missingInfoCount >= this.maxMessagesByTypeCount) {
            newReasonFlag = 'I';
        }
        this.model.set('REQUESTFORINFO_REASON_FLAG', newReasonFlag);
    },

    /**
     * Hit API to retrieve data for dropdown
     */
    loadRequiredData/* istanbul ignore next */() {
        // get message allotment data
        const msgAllotmentCheckRequestObj = this.getMessageAllotmentData();
        const msgAllotmentPromise = this.retrieveApiData(msgAllotmentCheckRequestObj);

        msgAllotmentPromise.then((msgData) => {
            this.msgData = msgData;
            this.setReasonFlag();
            this.startDropdown();
        })
            .catch(error => this.displayServiceError(error));
    },

    getMessageAllotmentData() {
        return {
            url: services.generateUrl(this.currentTab === 'received' ? constants.URL_RTP_RECEIVED_MESSAGE_ALLOTMENT_CHECK : constants.URL_RTP_SUBMITTED_MESSAGE_ALLOTMENT_CHECK),
            method: 'post',
            data: {
                item: [
                    {
                        name: 'PAYMENT_ID',
                        value: this.model.get('INSTRUCTION_ID') || this.model.get('TNUM') || this.model.get('PAYMENT_ID'),
                    },
                ],
            },
        };
    },

    startDropdown() {
        // hit service and return data for dropdown
        const dropdownDataRequestObj = this.getDropDownData();
        const dropdownDataPromise = this.retrieveApiData(dropdownDataRequestObj);
        dropdownDataPromise.then((dropdownData) => {
            this.createDropdown(dropdownData);

            this.setHasLoadedRequiredData(true);
            this.render();
        })
            .catch(error => this.displayServiceError(error));
    },

    /**
     * Format data and create Flex Dropdown
     * @param  {object} dropdownData unformatted dropdown data
     */
    createDropdown(dropdownData) {
        const data = this.formatDataForDropdown(dropdownData);

        const options = {
            data,
            preSelectedIds: [
                this.getPreselectedId(data),
            ],
            showTooltip: false,
            disableMultiButton: true,
        };

        /*
         * set the initial reason info code -
         * If model has a value for info code use it
         * if not then use the id of the first item in the data
         */
        this.model.set({
            REQUESTFORINFO_REASON_INFO_CODE: this.model.get('REQUESTFORINFO_REASON_INFO_CODE') || data[0].id,
            REQUESTFORINFO_REASON: this.model.get('REQUESTFORINFO_REASON') || data[0].name,
        });

        /*
         * running this code here because it needs the reason info code to work
         */
        this.model.setMessageValidators();

        this.reasonDropdown = new FlexDropdown(options);

        // change from optional to required or back if necessary
        this.setValidationLabel();

        this.listenTo(this.reasonDropdown, 'selectionChanged', (selected) => {
            this.model.set({
                REQUESTFORINFO_REASON_INFO_CODE: selected[0].id,
                REQUESTFORINFO_REASON: selected[0].name,
            });
            this.model.setMessageValidators();
            this.render();
        });
    },

    getPreselectedId(data) {
        // ar.some(({id}) => id === 'MM20')
        let preselectedId = '';
        const flag = this.model.get('REQUESTFORINFO_REASON_INFO_CODE');
        const inData = data.map(item => util.contains(item, flag));

        // first find out if model has flag and if flag is part of data
        if (flag && util.contains(inData, true)) {
            preselectedId = flag;
        } else {
            preselectedId = data[0].id;
        }

        return preselectedId;
    },

    /**
     * Create request obj for API dropdown data retrieval
     * @param  {string} reason string describing which radio button selected
     * @return {obj} request object for API call
     */
    getDropDownData() {
        const requestObj = {
            queryCriteria: {
                action: {
                    productCode: 'RTP',
                    functionCode: 'MSGS',
                    typeCode: 'RTPREQ',
                    actionMode: 'SELECT',
                },
                subTypeCode: '*',
                fieldName: this.model.get('REQUESTFORINFO_REASON_FLAG') === 'I' ? 'INCORRECT_INFO_CODE' : 'MISSING_INFO_CODE',
                allowDuplicates: false,
            },
        };

        return {
            url: services.generateUrl('/tableMaintenance/getQueryResults'),
            method: 'post',
            data: requestObj,
        };
    },

    /**
     * Format returned data for dropdown
     * @param  {object} data Returned data from API call
     * @return {[type]}      [description]
     */
    formatDataForDropdown(data) {
        return util.map(data.queryResponse.QueryData.queryRows, reason => ({
            id: reason.name,
            name: reason.label,
        }));
    },

    displayServiceError(error) {
        this.appBus.trigger('rtp:requestReasonError', error);
    },

    retrieveApiData/* istanbul ignore next */(requestObj) {
        return new Promise((resolve, reject) => {
            http[requestObj.method](requestObj.url, requestObj.data).then((response) => {
                resolve(response);
            }, (error) => {
                reject(error);
            });
        });
    },

    /**
     * Set model attribute to determine whether to show optional or required
     * @param {[type]} id [description]
     */
    setValidationLabel() {
        if (this.model.get('REQUESTFORINFO_REASON_INFO_CODE') === 'NARR') {
            // hide optional and show required
            this.ui.$validationLabel.eq(0).removeClass('active');
            this.ui.$validationLabel.eq(1).addClass('active');
        } else {
            // do the opposite
            this.ui.$validationLabel.eq(0).addClass('active');
            this.ui.$validationLabel.eq(1).removeClass('active');
        }
    },

    showInputs(e) {
        e.stopPropagation();
        this.msgType = $(e.currentTarget).data('msgtype');
        // set model attributes then re-render
        this.model.set({
            INPUT_MODE: true,
            RFI_MODE: this.msgType === 'rfi',
            PA_MODE: this.msgType === 'pa',
        });
        this.render();
    },

    resetInputs(e) {
        if (e) {
            e.stopPropagation();
        }
        // set model attributes then re-render
        this.model = this.resetModel();
        this.setHasLoadedRequiredData(false);
        this.render();
    },

    sendMessage(e) {
        e.stopPropagation();
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }
        this.model.save()
            .then(response => this.handleSuccess(response))
            .catch(err => this.handleError(err.responseJSON));
    },

    handleSuccess(response) {
        if (response.actionResponse.confirms.totalFail > 0) {
            this.handleError(response);
            return;
        }
        this.appBus.trigger('rtp:newMessageSuccess');
        this.conversationStarted = true;
        this.resetInputs();
    },

    handleError(err) {
        let errorMessages = err.actionResponse;
        errorMessages = util.chain(errorMessages.confirms.confirmResults)
            .map(confirmResult => confirmResult.messages)
            .map(message => message)
            .value();

        const alertObj = {
            type: 'danger',
            message: [...errorMessages],
        };

        this.showAlert(alertObj);
    },

    showAlert(alertObj) {
        this.alertRegion.show(alert[alertObj.type](alertObj.message, { icon: true }));
    },

    getPlaceholderText() {
        let placeholderText;

        if (this.model.get('RFI_MODE')) {
            placeholderText = locale.get('rtp.placeholderText.enterRequest');
        } else if (this.model.get('PA_MODE')) {
            placeholderText = locale.get('rtp.placeholderText.enterAcknowledgement');
        } else {
            placeholderText = locale.get('rtp.placeholderText.enterRequiredResponse');
        }

        return placeholderText;
    },

    templateHelpers() {
        return {
            conversationStarted: this.conversationStarted,
            isRequest: this.model.get('ITEM_TYPE') === 'REQUEST',
            isRequestForInfo: this.isRequestForInfo,
            isPaymentAcknowledgement: this.isPaymentAcknowledgement,
            placeholderText: this.getPlaceholderText(),
            onlyOneInCollection: this.model.collection && this.model.collection.length < 2,
            maxMessageCharacters: this.maxMessageCharacters,
            reasonIncorrect: this.model.get('REQUESTFORINFO_REASON_FLAG') === 'I' || true,
            reasonMissing: this.model.get('REQUESTFORINFO_REASON_FLAG') === 'M',
            disableIncorrect: this.model.get('disableIncorrect'),
            disableMissing: this.model.get('disableMissing'),
            cid: this.model.cid,
            allowIncorrect: this.msgData
                && this.msgData.incorrectInfoCount < this.maxMessagesByTypeCount,
            allowMissing: this.msgData
                && this.msgData.missingInfoCount < this.maxMessagesByTypeCount,
            allowPA: this.msgData
                && this.msgData.paymentAckCount < 1
                && this.canSendACK,
            allowRFI: this.msgData
                && this.msgData.requestForInfoCount < this.maxMessagesCount
                && this.canSendRFI,
            allowResponse: (this.currentTab === 'received' || (this.currentTab === 'submitted' && (this.msgData && this.msgData.requestForInfoCount > 0))) && (this.canSendRFIResponse || this.canSendRFI || this.canSendACK),
        };
    },
});
