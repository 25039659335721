import services from 'services';
import BaseRDCModel from './baseRDCModel';

export default BaseRDCModel.extend({

    /**
     * Get the service URL for this collection
     * @returns {string}
     */
    getServiceURL() {
        return services.rdcDepositItemImages;
    },

    /**
     * Insert model attributes into the data structure expected by the REST endpoint
     * @param model
     * @returns {object}
     */
    getRequestData(model) {
        const json = model.toJSON();
        return {
            transactionID: json.transactionID,
            locationName: json.LOCATIONNAME,
            itemNumber: json.itemNumber,
            receiptNumber: json.receiptNumber,
            sessionStateId: json.sessionStateId,
            sessionToken: json.sessionToken,
            formattedCheckAmount: json.formattedCheckAmount,
            checkDate: json.checkDate,
            checkNumber: json.checkNumber,
        };
    },
});
