import ItemView from '@glu/core/src/itemView';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import printTransViewTmpl from './printTransView.hbs';

const PrintTransView = ItemView.extend({
    template: printTransViewTmpl,
    modalClass: 'modal-lg',

    initialize(options) {
        this.model = new Model(options);
        this.isCreditAdviceReport = options.isCreditAdviceReport || false;
        this.model.set('gridView', options.gridView);
        this.model.set('exportURL', options.exportURL);
        this.model.set('format', options.format);
        // set context data if passed in view has a model (is situational)
        if (options.view.model) {
            this.model.set(
                'contextData',
                {
                    AccountNumber: options.view.model.get('accountNumber'),
                    AccountName: options.view.model.get('accountName'),
                },
            );
        }
        this.dialogTitle = locale.get('button.print');
        this.dialogButtons = [{
            text: locale.get('button.print'),
            className: 'btn btn-primary',
            callback: 'print',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    print() {
        dialog.close();
        this.trigger('printTransaction', this.model.toJSON());
    },

    cancel() {
        dialog.close();
    },

    templateHelpers() {
        const self = this;
        return {
            getLabel() {
                return self.isCreditAdviceReport ? locale.get('gir.OneItemPerPage') : locale.get('export.includeTransactionDetails');
            },
        };
    },
});

export default PrintTransView;
