import withPCM from 'pcmMdf/app/administration/pcmRoutes'; // eslint-disable-line

export default withPCM({
    'ADMINSTRATION/listAlerts': 'listAlerts',
    'SETUP/bankAccountSettings': 'listBankAccountSettings',
    'ADMINSTRATION/fileImportHistory(/)(:viewId)': 'listFiles',
    'ADMINSTRATION/fileImportDetails': 'listFileDetails',
    'ADMINSTRATION/manageFileImportHistory': 'listFilesWorkspace',
    'SMAINT/appConfig(/:tab)': 'appConfigWorkspace',
    'SMAINT/appConfigEntryView': 'appConfigEntryView',
    'SMAINT/appConfigEntryModify': 'appConfigEntryView',
    'ADMINSTRATION/audit': 'auditInfoWorkspace',
    'ADMINSTRATION/adminAudit': 'adminAuditInfoWorkspace',
    'CMAINT/audit': 'auditView',
    'CMAINT/liveMaintenance': 'liveMaintenance',
    'CMAINT/viewNotifications': 'adminViewNotifications',
    'CMAINT/manageNotifications': 'adminManageNotifications',
    'CMAINT/manageNotificationTemplates': 'adminManageNotificationTemplate',
    'CMAINT/panelApprovalWorkflow': 'panelWorkspace',
    'SMAINT/adminAudit': 'adminAuditView',
    'REPORTING/viewFXTransactions': 'adminFxReporting',
    'ADMINSTRATION/preferences': 'adminPreferencesWorkspace',
    'ADMINSTRATION/manageTokens': 'adminManageTokensWorkspace',
    'TKNMGMT/tokenManagement': 'adminTokenManagement',
    'TKNMGMT/tokenManagement/expiringTokens': 'adminExpiringTokensWorkspace',
    'TKNMGMT/tokenManagement/expiringTokensView': 'adminExpiringTokensView',
    'TKNMGMT/tokenManagement/tokensAudit': 'admiTokensAuditWorkspace',
    'ADMINSTRATION/manageCheckfreeRxp': 'adminManageCheckfreeRxpWorkspace',
    'ADMINSTRATION/manageCheckfreeSmb': 'adminManageCheckfreeSmbWorkspace',
    'ADMINSTRATION/viewCheckfreeRxpAccountDetails': 'showCheckfreeRxpAccountDetails',
    'ADMINSTRATION/viewCheckfreeSmbAccountDetails': 'showCheckfreeSmbAccountDetails',
    'ADMINSTRATION/viewCheckfreeSmbUserDetails': 'showCheckfreeSmbUserDetails',
    'ADMINSTRATION/viewClientSettings': 'adminViewClientSettings',
    'ADMINSTRATION/modifyClientSettings': 'adminModifyClientSettings',
    'ADMINSTRATION/alerts': 'adminAlertsWorkspace',
    'ADMINSTRATION/alertsCenter(/:tab)': 'adminAlertsCenterView',
    'ADMINSTRATION/viewPanelApprovalWorkflowList': 'panelWorkspace',
    'ADMINSTRATION/listPanelApproval(/)(:viewId)': 'listPanelApproval',
    'SEGMENTATION/viewSegments': 'adminSegmentationWorkspace',
    'SEGMENTATION/addProfile': 'adminSegAddProfile',
    'SEGMENTATION/viewProfile': 'adminSegViewProfile',
    'SEGMENTATION/addServices': 'adminSegAddServices',
    'SEGMENTATION/viewServices': 'adminSegViewServices',
    'SEGMENTATION/addPaymentLimits': 'adminSegAddPaymentLimits',
    'SEGMENTATION/addBranding': 'adminSegAddBranding',
    'SEGMENTATION/externalWidgets': 'adminSegExternalWidgets',
    'SEGMENTATION/elearning': 'adminSegElearning',
    'ADMINSTRATION/alerts/addAlert(/:workspace)': 'addAlert',
    'ADMINSTRATION/alerts/modifyAlert': 'modifyAlert',
    'ADMINSTRATION/alerts/viewAlert': 'viewAlert',
    'ADMINSTRATION/recipients': 'recipientsList',
    'ADMINSTRATION/recipientGrpAssignment': 'recipientGrpAssignment',
    'ADMINSTRATION/recipientGroupAssignment/(:mode)': 'recipientGroupAssignment',
    mySettings: 'mySettings',
    'ADMINSTRATION/security/settings': 'passcodeSettings',
    'ADMINSTRATION/intermediaryBankMapping': 'intermediaryBankMapping',
    'ADMINSTRATION/correspondentBankMapping': 'correspondentBankMapping',
    'ADMINSTRATION/correspondentBankMappingDetailInsert': 'correspondentBankMappingDetailInsert',
    'ADMINSTRATION/correspondentBankMappingDetailView': 'correspondentBankMappingDetailView',
    'ADMINSTRATION/correspondentBankMappingDetailModify': 'correspondentBankMappingDetailModify',
    'REPORTING/viewIssueVoidReconciliationDetail': 'adminIssueVoidReconciliationDetail',
    'REPORTING/viewCMIssueVoids(/:tab)': 'adminCheckIssueManagementWorkspace',
    'REPORTING/viewCMIssueVoidsDetails': 'adminCheckIssueManagementDetails',
    'REPORTING/viewPayments': 'adminPaymentsManagementWorkspace',
    'REPORTING/viewRealTimeIncoming': 'adminRealTimeIncomingManagement',
    'REPORTING/adminManageTransfers': 'pmtTransfersWorkspace',
    'REPORTING/viewPaymentsDetails(/)(:isSubPayment)': 'adminPaymentsManagementDetails',
    'REPORTING/viewLoanPaymentDetails': 'adminPaymentsManagementLoanDetails',
    'REPORTING/viewTemplates': 'adminTemplatesManagementWorkspace',
    'REPORTING/viewTemplatesDetails': 'adminTemplatesManagementDetails',
    'REPORTING/viewLoanTemplateDetails': 'adminTemplatesManagementLoanDetails',
    'REPORTING/viewStopCancels': 'adminStopCancelsWorkspace',
    'REPORTING/viewStopCancelsDetails': 'adminStopCancelsDetails',
    'REPORTING/stopCancelCheckView': 'adminStopCancelCheckView',
    'REPORTING/viewAutoPaymentsSummary': 'adminPaymentAutomationManager',
    'REPORTING/viewAutoPaymentsSummary/details': 'adminPaymentAutomationManagerDetails',
    'REPORTING/viewAutoPaymentsSummaryList': 'adminPaymentAutomationManagerList',
    'REPORTING/adminReporting': 'adminReportingWorkspace',
    'REPORTING/adminReportingDetails': 'adminReportingDetails',
    'REPORTING/viewPaymentFraudControl(/:tab)': 'adminFraudControlWorkspace',
    'REPORTING/viewPosPayExtractDetail': 'adminPosPayFraudControlWorkspace',
    'REPORTING/positivePayDecision': 'adminPositivePayDecision',
    'REPORTING/reversePositivePayDecision': 'adminReversePositivePayDecision',
    'REPORTING/ePositivePayDecision': 'adminEPositivePayDecision',
    'REPORTING/viewPositivePayDecision': 'adminViewPositivePayDecision',
    'REPORTING/viewReversePositivePayDecision': 'adminViewReversePositivePayDecision',
    'REPORTING/viewEPositivePayDecision': 'adminViewEPositivePayDecision',
    'REPORTING/decisionHistory': 'adminDecisionHistory',
    'REPORTING/passThroughDetails': 'adminPassThroughDetails',
    'REPORTING/viewFileImportHistory': 'adminFileImportHistoryWorkspace',
    'REPORTING/viewFileImportHistoryDetails': 'adminFileImportHistoryDetails',
    'REPORTING/viewImportResults': 'adminImportResults',
    'REPORTING/viewRecurPayExcept': 'adminRecurPaymentExceptWorkspace',
    'REPORTING/manageTransfers': 'viewTransfersList',
    'REPORTING/transferTemplates': 'viewTransferTemplateWorkspace',
    'REPORTING/listTransfers(/)(:viewId)': 'adminviewTransfersList',
    'REPORTING/listTransferTemplates(/)(:viewId)': 'adminviewTransferTemplateList',
    'REPORTING/requestForPaymentMangement': 'requestForPaymentMangement',
    'ADMINSTRATION/confidentialImportSettings(/:userGroup)': 'viewConfidentialImportSettings',
    'CXP/externalWidget': 'adminExternalWidgetWorkspace',
    'CXP/viewExternalWidget/:widgetId': 'adminViewExternalWidget',
    'CXP/addExternalWidget': 'adminAddExternalWidget',
    'CXP/updateExternalWidget/:widgetId': 'adminUpdateExternalWidget',
    'CXP/editWidgetContent/:widgetId': 'adminEditWidgetContent',
    'SETUP/viewBABContact': 'beneViewBABContact',
    'PAYMENTS/passThroughViewPayment': 'passThroughViewPayment',
    'SMAINT/fileLoadAdminExport': 'adminFileLoadExport',
    'FRAUD/ssoout/(/)(:context)': {
        action: 'admSSOOut',
        allowRefresh: fragment => fragment.startsWith('FRAUD/ssoout'),
    },
    'CXP/elearning': 'adminElearningWorkspace',
    'CXP/addElearningGroup': 'adminAddElearningGroup',
    'CXP/updateElearningGroup/:groupId': 'adminUpdateElearningGroup',
    'CXP/viewElearningGroup/:groupId': 'adminViewElearningGroup',
    'CXP/addElearningContent': 'adminAddElearningContent',
    'CXP/updateElearningContent/:contentId': 'adminUpdateElearningContent',
    'CXP/viewElearningContent/:contentId': 'adminViewElearningContent',
    'SSOADMIN/ssoAdministration': 'ssoListVendors',
    'SSOADMIN/addUpdateVendor': 'ssoAdmin',
    'SSOADMIN/importVendor': 'ssoImportVendor',
    'SSOADMIN/viewVendor': 'ssoViewVendor',
    'SSOADMIN/modifyVendor': 'ssoModifyVendor',
    'RTP/preferences': 'showRTPPreferences',
    'REPORTING/viewIncomingDetails': 'showRTPIncomingDetails',
    'SMAINT/analyticsServerSettings': 'showAnalyticsServerSetting',
    'CMAINT/listTOALocations(/)(:userGroup)': 'adminListTOALocations',
    'CMAINT/toaLocationAdd(/)(:userGroup)': 'adminAddTOALocation',
    'CMAINT/toaLocationModify/': 'adminModifyTOALocation',
    'CMAINT/toaLocationView/': 'adminViewTOALocation',
    'CMAINT/totalListViewAch': 'adminAchControlTotal',
    'CMAINT/totalListViewAch(/)(:userGroup)': 'adminAchControlTotal',
    'CMAINT/addNewPigId': 'addNewPaymentGroupID',
    'CMAINT/addNewPigId(/)(:userGroup)': 'addNewPaymentGroupID',

    /*
     *
     * generic list view Action for MDF setups for Bank Admin
     * Use same handler for each route that is configured
     * Example route given below is for the configuration represented
     * by the Product 'SMAINT' and the Type 'CXPREPO'
     */
    'SMAINT/CXPREPO': 'genericMdfListView',
    'SMAINT/JWTJKS': 'genericMdfListView',

    // Dark Deploy
    'COMMCTLCENTER/commandControlCenter/list': 'commandAndControlListView',

    // DEEPLINKS
    'ADMINSTRATION/pmxAccountManagementWidget': 'pmxAccountManagementWidget',
    'ADMINSTRATION/pmxPayerContactOptionsWidget': 'pmxPayerContactOptionsWidget',

});
