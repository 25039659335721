import Decimals from 'common/dynamicPages/api/decimals';
import number from 'numeral';
import Formatter from 'system/utilities/format';
import $ from 'jquery';
import locale from '@glu/locale';
import dateUtil from 'common/util/dateUtil';

export default function (form, initialState) {
    /**
     * Sets up the summary section for non-insert action modes
     * Example: 4.00 USD to 1 Beneficiary on 29 Jan 2019
     */
    const setupSummarySection = function () {
        const { model } = form.formView;
        const $paymentSummaryContainer = $('[data-section="payment-summary"]');
        const $amountFields = $paymentSummaryContainer.find('[data-field="summary-amount"]');
        const $currencyFields = $paymentSummaryContainer.find('[data-field="summary-currency"]');
        const $beneficiariesFields = $paymentSummaryContainer.find('[data-field="summary-beneficiaries"]');
        const $summaryDate = $paymentSummaryContainer.find('[data-field="summary-date"]');
        const creditAmount = model.get('TOTAL_INTERBANK_SETTLE_AMT');
        const creditCurrency = model.get('TOTAL_INTERBANK_SETTLE_CCY');
        const beneficiaryName = model.get('DEBTOR_NAME');
        const valueDate = model.get('INTERBANK_SETTLE_DATE');

        // Set the transaction descripition field on the screen which just field on the screen
        $('[name="TRANSACTION_DESCRIPTION"]').text(locale.get('RTP.INST.DBTRAN.*.TRANSACTION_DESCRIPTION.text'));

        // Update the summary sections visibility
        $paymentSummaryContainer.removeClass('hidden');
        $paymentSummaryContainer.filter('.section-header').children().first().addClass('hidden');

        // Set the currency code
        $currencyFields.text(creditCurrency);

        // Format and set the amount
        const paymentTotalFormatted = number(creditAmount || 0)
            .format(Decimals.getCurrencyFormat(creditCurrency));
        $amountFields.text(paymentTotalFormatted);

        // Set the beneficiary
        $beneficiariesFields.text(beneficiaryName);

        // Set the date
        $summaryDate.text(Formatter
            .formatDateFromUserFormat(valueDate, dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));
    };

    if (initialState) {
        setupSummarySection();
    }
}
