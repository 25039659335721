import CompositeView from '@glu/core/src/compositeView';
import Collection from '@glu/core/src/collection';
import $ from 'jquery';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import workspaceHelper from 'common/workspaces/api/helper';
import userInfo from 'etc/userInfo';
import HeaderModel from 'app/administration/models/confidentialSettingsHeader';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import domUtil from 'common/util/domUtil';
import TypeView from './confidentialSettingTypeView';
import confidentialSettingsViewTmpl from './confidentialSettingsView.hbs';

const ConfidentialSettingsView = CompositeView.extend({

    template: confidentialSettingsViewTmpl,
    tagName: 'div',
    itemView: TypeView,
    itemViewContainer: '.types-list',
    className: 'confidentialSettingsForm',
    itemViewOptions() {
        return {
            canModify: this.model.get('canModify'),
        };
    },

    ui: {
        $saveButton: 'div.section-body button[data-action="save"]',
        $approveButton: 'div.section-body button[data-action="approve"]',
    },

    initialize(options) {
        this.model = new HeaderModel();
        const userGroup = options.userGroup || userInfo.get('group');
        this.model.set('USERGROUP', userGroup);
        this.collection = new Collection([]);
        this.model.fetch().then(() => {
            this.collection.reset(this.model.get('types'));
            this.render();
            this.updateView();
        });
        this.setNotificationData({
            title: 'confidentialSetting',
        });
    },

    save() {
        this.model.save('modify', this.collection).then(util.bind(this.processResponse, this), util.bind(this.processResponse, this));
        this.disableButtons();
    },

    approve() {
        this.model.save('approve').then(util.bind(this.processResponse, this), util.bind(this.processResponse, this));
        this.disableButtons();
    },

    updateView() {
        if (!this.model.get('STATUS')) {
            $('#status-desc').empty().append(locale.get('CMAINT.no.ug.entitlements'));
        } else {
            $('#status-desc').empty().append(locale.get(this.model.get('STATUSDESC')));
            if (this.ui.$approveButton) {
                if ((this.model.get('canApprove') === true) && (this.model.get('STATUS') !== 'AP')) {
                    this.ui.$approveButton.attr('style', 'display: inline !important');
                } else {
                    this.ui.$approveButton.attr('style', 'display: none !important');
                }
            }
        }
    },

    renderAlertMessage(message, confirms) {
        $('.alerts').empty().append(alert.danger(message.join('\n'), {
            details: confirms,
        }).render().el);
    },

    processResponse(data) {
        let confirms;
        if (data.errorCode && data.errorCode > 0) {
            confirms = new Confirms({
                confirms: data.confirms,
            });
            this.renderAlertMessage(data.message, confirms);
        } else if (data?.responseJSON?.errorCode === 20003) {
            confirms = new Confirms({
                confirms: data.responseJSON.confirms,
            });
            this.renderAlertMessage(data.responseJSON.message, confirms);
        } else {
            $('.alerts').empty().append(alert.success(data.message.join('\n')).render().el);
            this.model.fetch().then(() => {
                this.updateView();
            });
        }
        this.enableButtons();
    },

    processError(data) {
        this.enableButtons();
        throw new Error(data.message.concat('\n'));
    },

    disableButtons() {
        domUtil.setDisabled([this.ui.$saveButton, this.ui.$approveButton], true);
    },

    enableButtons() {
        domUtil.setDisabled([this.ui.$saveButton, this.ui.$approveButton], false);
    },

    templateHelpers() {
        return {
            title: locale.get('type.confidentialSettingsHeader', this.model.get('USERGROUP')),
            showModifyButton: (this.model.get('canModify') === true),
            showApproveButton: (this.model.get('canApprove') === true),
            statusClass: this.model.get('STATUSDESC') === 'Approved' ? 'label-positive' : 'label-warning',
        };
    },

    onRender() {
        // eslint-disable-next-line
            this._notificationsInterface.loaded(this._notificationData);
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'CONFIDENTIAL_IMPORT_SETTINGS',
    view: ConfidentialSettingsView,
    options: {},
});

export default ConfidentialSettingsView;
