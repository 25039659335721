import ReversePositivePayDecisionHistoryList from 'app/checkManagement/views/listReversePositivePayDecisionHistory';
import store from 'system/utilities/cache';

export default ReversePositivePayDecisionHistoryList.extend({
    /** @override */
    getInitializeOptions(options) {
        const additionalSearchFields = options && options.additionalSearchFields
            ? options.additionalSearchFields : null;
        this.accountNumber = options.accountNumber;
        this.accountTitle = options.accountTitle;
        this.typeDesc = options.typeDesc;

        return {
            serviceName: 'adminCM/fraud/listView/reversePositivePayDecisionHistory',
            serviceFunc: null,
            businessType: null,
            selector: 'none',
            returnRoute: this.options.returnRoute,
            enableSavedViews: false,
            additionalSearchFields,
        };
    },

    gridRowSelect(options) {
        const { model } = options;
        model.set('ACCOUNT_NUMBER', this.accountNumber);
        model.set('ACCOUNT_TITLE', this.accountTitle);
        store.set(`${this.contextKey}-actionModel`, model);
        this.navigateTo('REPORTING/viewReversePositivePayDecision');
        return Promise.resolve();
    },

    /** @override */
    getInquiryId() {
        return '20551';
    },
});
