import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@glu/accordion-react';
import MdfGridWrapper from './MdfGridWrapper';

const HistoryDetailsMDF = ({
    sectionTitle,
    batchTnum,
    typeCode,
}) => (
    <Accordion>
        <Accordion.Panel panelId={sectionTitle.toLowerCase().replace(/\s/gi, '_')}>
            <Accordion.Header>
                {sectionTitle}
            </Accordion.Header>

            <Accordion.Content>
                <div className="form-column">
                    <MdfGridWrapper
                        batchTnum={batchTnum}
                        typeCode={typeCode}
                    />
                </div>
            </Accordion.Content>
        </Accordion.Panel>
    </Accordion>
);

HistoryDetailsMDF.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    batchTnum: PropTypes.number.isRequired,
    typeCode: PropTypes.string.isRequired,
};

export default HistoryDetailsMDF;
