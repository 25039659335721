// PCM CHANGES ON HOLE FILE - BE AWARE ON DGB MERGE
// import Collection from '@glu/core/src/collection';
// import UserGroupCollection from 'app/administration/collection/userGroups';
import UsersListView from 'app/administration/views/userMaintenance/list';
import RolesListView from 'app/administration/views/roleMaintenance/list';
import StackView from 'common/stack/views/stackView';
import WorkspaceView from 'common/workspaces/views/workspace';
import entitlements from 'common/dynamicPages/api/entitlements';
import UserLayout from 'app/administration/views/userMaintenance2/userStepLayout';
import UserPendingChangesLayout from 'app/administration/views/userMaintenance2/userPendingChangesLayout';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import { appBus } from '@glu/core';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import userSettingInfo from 'etc/userInfo';
import configuration from 'system/configuration';
import mobileUtil from 'mobile/util/mobileUtil';
import UserProfileRestricted from './views/userMaintenance/profile/userProfileRestricted';
import UserByRoleLayout from './views/userMaintenance/userByRoleLayout';
// import UserGroupLookup from './views/userMaintenance2/userGroupLookup';
import RoleLayout from './views/roleMaintenance/roleLayout'; // PCM

export default {
    adminListUsers(viewId) {
        if (this.processDeeplink('ADMINSTRATION/listUsers')) {
            this.showPage('', new UsersListView({
                viewId,
            }));
        }
    },

    adminListAllUsers(viewId) {
        if (this.processDeeplink('CMAINT/listUsers')) {
            store.set('ADMIN_DEEPLINK', true);
            this.showPage('', new UsersListView({
                viewId,
                returnRoute: 'CMAINT/listUsers',
            }));
        }
    },

    adminUserRoleMaintenance(userGroup) {
        let route = 'ADMINSTRATION/viewRoles';
        let fromMenu = false;
        if (userGroup === ':MENU') {
            fromMenu = true;
        } else if (userGroup) {
            route = `${route}/${userGroup}`;
        }
        store.set('current-workspace-route', route);

        this.showPage('', new RolesListView({
            id: 'USER_ENTITLEMENT',
            returnRoute: route,
            fromMenu,
        }));
    },

    /**
     * Setup the user workspace widget
     * @method adminViewUsersWorkspace
     * @param {string} [userGroup]
     */
    adminViewUsersWorkspace(userGroup) {
        let route = 'ADMINSTRATION/viewUsers';
        let fromMenu = false;
        if (userGroup === ':MENU') {
            fromMenu = true;
        } else if (userGroup) {
            route = `${route}/${userGroup}`;
        }
        store.remove('ADMIN_DEEPLINK');
        // NH-30777 reset grid filters
        this.showPage(
            '',
            new StackView({
                initItem: new WorkspaceView({
                    id: 'USER_MAINT',
                    returnRoute: route,
                }),

                fromMenu,
            }),
        );
    },

    modifyUserDeeplinkClientMain() {
        this.modifyUserDeeplinkClient('ADMINSTRATION/modifyUserClientSecondary');
    },

    modifyUserDeeplinkClientSecondary() {
        this.modifyUserDeeplinkClient('ADMINSTRATION/modifyUserClient');
    },

    modifyUserDeeplinkAdminMain() {
        this.modifyUserDeeplinkAdmin('ADMINSTRATION/modifyUserAdminSecondary');
    },

    modifyUserDeeplinkAdminSecondary() {
        this.modifyUserDeeplinkAdmin('ADMINSTRATION/modifyUserAdmin');
    },

    // This method mimics what ever is done when we modify the user from the grid. except
    // that the user id is retrieved from the deeplink context.
    modifyUserDeeplinkClient(successURL) {
        if (this.processDeeplink('ADMINSTRATION/modifyUserClient')) {
            const options = {
                context: {
                    productCode: '_ADMIN',
                    functionCode: 'USERS',
                },
            };
            const key = store.makeKeyFromContext('userDetails', options.context);
            if (store.has(key)) {
                store.set('uce-load', store.remove('userDetails', options));
            }

            this.performAction('modify', true, successURL);
        }
    },


    addUserDeeplinkClient() {
        const deeplinkAddUserRoleUrl = 'ADMINISTRATION/addRoleUser';
        if (this.processDeeplink(deeplinkAddUserRoleUrl)) {
            const options = {
                context: {
                    productCode: '_ADMIN',
                    functionCode: 'USERS',
                },
            };
            const key = store.makeKeyFromContext('userDetails', options.context);
            if (store.has(key)) {
                store.set('uce-load', store.remove('userDetails', options));
            }

            this.performAction('insert', true, deeplinkAddUserRoleUrl);
        }
    },

    modifyUserDeeplinkAdmin(successURL) {
        if (this.processDeeplink('ADMINSTRATION/modifyUserAdmin')) {
            const options = {
                context: {
                    productCode: 'CMAINT',
                    functionCode: 'MAINT',
                },
            };
            const key = store.makeKeyFromContext('userDetails', options.context);
            if (store.has(key)) {
                store.set('uce-load', store.remove('userDetails', options));
            }

            this.performAction('modify', true, successURL);
        }
    },

    viewUserDeeplinkClient() {
        if (this.processDeeplink('ADMINSTRATION/viewUserClient')) {
            store.set('uce-load', store.remove(
                'userDetails',
                {
                    context: {
                        productCode: '_ADMIN',
                        functionCode: 'USERS',
                    },
                },
            ));

            this.performAction('view', true);
        }
    },

    viewUserDeeplinkAdmin() {
        if (this.processDeeplink('ADMINSTRATION/viewUserAdmin')) {
            const self = this;

            const options = {
                context: {
                    serviceName: '/userMaintenance/user',
                },
            };

            store.set('uce-load', store.remove(
                'userDetails',
                {
                    context: {
                        productCode: 'CMAINT',
                        functionCode: 'MAINT',
                    },
                },
            ));

            entitlements.getEntitlements(options).then((result) => {
                self.entitlements = result.actions;
                if (self.entitlements.SIMULATE) {
                    store.set('window-location-href-simulation', '/ui');
                }
                self.performAction('view', true);
            }, util.bind(errorHandlers.loading, self));
        }
    },

    adminViewUser() {
        this.performAction('view');
    },

    adminAddUser() {
        this.performAction('insert');
    },

    adminModifyUser() {
        this.performAction('modify');
    },

    adminViewPendingChanges() {
        this.performAction('pending');
    },

    updateUserSetting() {
        this.performAction('updateSetting');
    },

    performAction(mode, deeplinked, returnURL) {
        let localReturnURL = returnURL;
        // Because admin is always portal whether it is deeplink or not we cannot
        // use isPortal.
        const isAdminDeeplink = configuration.isAdmin() && store.get('ADMIN_DEEPLINK');

        const isClientDeeplink = configuration.isPortal() && configuration.isClient();
        const userInfo = store.get('uce-load');
        let hasSimulate = userInfo ? userInfo.hasSimulate : false;

        store.remove('ADMIN_DEEPLINK');

        if (!hasSimulate && this.entitlements) {
            hasSimulate = this.entitlements.SIMULATE;
        }

        if (!localReturnURL) {
            if (isAdminDeeplink) {
                localReturnURL = 'CMAINT/listUsers';
            } else if (configuration.isAdmin() && !store.get('ADMIN_DEEPLINK')) {
                localReturnURL = 'CMAINT/viewUsers';
            } else if (isClientDeeplink) {
                localReturnURL = 'ADMINSTRATION/listUsers';
            } else if (userSettingInfo.isSmbUser()) {
                localReturnURL = store.get('current-workspace-route');
            } else {
                localReturnURL = mobileUtil.isMobileGridEnabled() ?
                    store.get('current-workspace-route') : 'ADMINSTRATION/viewUsers';
            }
        }

        // must have been a hard copy of url, just send them back to where they came
        if (!userInfo) {
            appBus.trigger('router:navigate', localReturnURL, true);
            return;
        }
        if (userSettingInfo.isUce()) {
            if (mode === 'pending') {
                this.pushPage(
                    '',
                    new UserPendingChangesLayout({
                        mode,
                        pageTitle: 'User Maintenance',
                        listViewLabel: 'User Maintenance Center',
                        returnRoute: localReturnURL,
                        userGroup: userInfo.userGroup,
                        userId: userInfo.userId,
                        hasSimulate,
                        deeplinked,
                    }),
                );
            } else if (mode === 'updateSetting') {
                this.pushPage(
                    '',
                    new UserProfileRestricted({
                        mode,
                        pageTitle: 'User Maintenance',
                        listViewLabel: 'User Maintenance Center',
                        returnRoute: localReturnURL,
                        userGroup: userInfo.userGroup,
                        userId: userInfo.userId,
                        hasSimulate,
                        deeplinked,
                    }),
                );
            } else {
                this.pushPage(
                    '',
                    new UserLayout({
                        mode,
                        pageTitle: 'User Maintenance',
                        listViewLabel: 'User Maintenance Center',
                        returnRoute: localReturnURL,
                        userGroup: userInfo.userGroup,
                        userId: userInfo.userId,
                        copyFromUser: userInfo.copyFromUser || null,
                        hasSimulate,
                        deeplinked,
                        isCannotGrantBeyondOwnUser:
                            userSettingInfo.isCannotGrantBeyondOwnUser(),
                    }),
                );
            }
        } else {
            this.pushPage(
                '',
                new UserByRoleLayout({
                    mode,
                    userGroup: userInfo.userGroup,
                    userId: userInfo.userId,
                    buttons: userInfo.buttons,
                    returnRoute: localReturnURL,
                    deeplinked,
                    userInfo,
                }),
            );
        }
    },

    addRoleUser() {
        const userInfo = store.get('uce-load');
        this.showPage(new UserByRoleLayout({
            mode: constants.MODES.INSERT,
            userGroup: userInfo.userGroup,
            copyFromUser: userInfo.copyFromUser || null,
            parentUserGroup: userInfo.parentUserGroup,
            returnRoute: 'ADMINSTRATION/viewUsers',
        }));
    },

    modifyRoleUser() {
        const userInfo = store.get('uce-load');

        this.showPage(new UserByRoleLayout({
            mode: constants.MODES.MODIFY,
            userGroup: userInfo.userGroup,
            userId: userInfo.userId,
            buttons: userInfo.buttons,
            returnRoute: 'ADMINSTRATION/viewUsers',
        }));
    },

    viewRoleUser() {
        const userInfo = store.get('uce-load');

        this.showPage(new UserByRoleLayout({
            mode: constants.MODES.VIEW,
            userGroup: userInfo.userGroup,
            userId: userInfo.userId,
            buttons: userInfo.buttons,
            returnRoute: 'ADMINSTRATION/viewUsers',
        }));
    },

    addRole() {
        const roleInfo = store.get('rolebased-load');

        this.showPage(new RoleLayout({
            userGroup: roleInfo.userGroup,
            roleName: roleInfo.roleName,
            roleDesc: roleInfo.roleDesc,
            mode: constants.MODES.INSERT,
            bankMaintained: roleInfo.bankMaintained,
            returnRoute: store.get('current-workspace-route') || 'ADMINSTRATION/viewRoles',
        }));
    },

    viewRole(roleName, userGroup) {
        const roleInfo = store.get('rolebased-select');
        this.showPage(new RoleLayout({
            roleName,
            mode: constants.MODES.VIEW,
            userGroup,
            gridModel: roleInfo.model,
            buttons: roleInfo.buttons,
            returnRoute: store.get('current-workspace-route') || 'ADMINSTRATION/viewRoles',
        }));
    },

    modifyRole(roleName, userGroup) {
        this.showPage(new RoleLayout({
            roleName,
            mode: constants.MODES.MODIFY,
            userGroup,
            returnRoute: store.get('current-workspace-route') || 'ADMINSTRATION/viewRoles',
        }));
    },

    cloneRole() {
        const roleInfo = store.get('rolebased-load');

        this.showPage(new RoleLayout({
            userGroup: roleInfo.userGroup,
            roleName: roleInfo.roleName,
            roleDesc: roleInfo.roleDesc,
            cloneId: roleInfo.cloneId,
            mode: constants.MODES.INSERT,
            bankMaintained: roleInfo.bankMaintained,
            returnRoute: store.get('current-workspace-route') || 'ADMINSTRATION/viewRoles',
        }));
    },
};
