var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"checkbox-inline\">\n                            <input type=\"checkbox\" name=\"CONTACTTYPE\" id=\"businessCheckbox\" value=\"1\" data-bind=\"model\" data-nullable>\n                            <span class=\"checkbox-icon\"></span>\n                            <label for=\"businessCheckbox\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.business",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":58},"end":{"line":11,"column":83}}}))
    + "</label>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"checkbox-inline\">\n                            <input type=\"checkbox\" name=\"CONTACTTYPE\" id=\"individualCheckbox\" value=\"2\" data-bind=\"model\" data-nullable>\n                            <span class=\"checkbox-icon\"></span>\n                            <label for=\"individualCheckbox\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.individual",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":60},"end":{"line":19,"column":87}}}))
    + "</label>\n                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"checkbox-inline\">\n                            <input type=\"checkbox\" name=\"CONTACTTYPE\" id=\"employeeCheckbox\" value=\"3\" data-bind=\"model\" data-nullable>\n                            <span class=\"checkbox-icon\"></span>\n                            <label for=\"employeeCheckbox\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bab.employee",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":58},"end":{"line":27,"column":83}}}))
    + "</label>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form>\n    <div class=\"row\">\n        <div class=\"col-12\">\n            <fieldset>\n                <legend class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.contact.types",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":41},"end":{"line":5,"column":71}}}))
    + "</legend>\n                <div class=\"checkbox\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBusiness") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":13,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showIndividual") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEmployee") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":29,"column":27}}})) != null ? stack1 : "")
    + "                 </div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"CONTACTTYPE\"></span>\n            </fieldset>\n        </div>\n    </div>\n\n    <div class=\"orderingparty-container\"></div>\n\n    <div class=\"row\">\n        <div class=\"col-4\">\n            <fieldset>\n                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.contact.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":53}}}))
    + "</legend>\n                <div class=\"form-group required\">\n                    <label for=\"BENE_NAME\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.contact.name.group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":43},"end":{"line":43,"column":78}}}))
    + "</label>\n                    <input type=\"text\" id=\"BENE_NAME\" name=\"BENE_NAME\" class=\"form-control\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_NAME") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_NAME\"></span>\n                </div>\n                <div class=\"form-group required\"> \n                    <label for=\"BENE_REFERENCE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.contact.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":48},"end":{"line":48,"column":75}}}))
    + "</label>\n                    <input type=\"text\" id=\"BENE_REFERENCE\" name=\"BENE_REFERENCE\" class=\"form-control\" maxlength=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_REFERENCE") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\"\n                           data-bind=\"model\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_REFERENCE\"></span>\n                </div>\n            </fieldset>\n        </div>\n    </div>\n\n    <div class=\"row\">\n\n        <div class=\"col-4 address-container\"></div>\n        <div class=\"col-4 col-md-offset-0 person-container\"></div>\n        <div class=\"col-4 col-md-offset-0 personalinfo-container\"></div>\n        <div class=\"col-4 col-md-offset-0 businessinfo-container\"></div>\n    </div>\n</form>\n";
},"useData":true});