var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"balanceUnchanged") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":26,"column":27}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"balanceTrajectory amountTrajectory "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"balanceUp") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":23,"column":65},"end":{"line":23,"column":103}}})) != null ? stack1 : "")
    + "\">\n                        "
    + container.escapeExpression(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"amountTrajectory") : depth0),(depth0 != null ? lookupProperty(depth0,"preferredFormat") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":24,"column":67}}}))
    + "\n                    </p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "up";
},"5":function(container,depth0,helpers,partials,data) {
    return "down";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-action=\"addTransfer\" class=\"btn btn-tertiary\">\n                <span class=\"icon-add-circle\"></span>\n                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbPayments.transfer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":49}}}))
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"list-column md-hidden accountField\">\n        <button data-action=\"handleViewDetails\" class=\"btn btn-tertiary viewAccount-button\">\n            <div class=\"accountField-type\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":51}}}) : helper)))
    + "</div>\n            <div class=\"xs-light\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"secureAccountNumber") || (depth0 != null ? lookupProperty(depth0,"secureAccountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secureAccountNumber","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":39}}}) : helper)))
    + "\n            </div>\n        </button>\n    </li>\n    <li class=\"list-column xs-hidden accountField\">\n        <button data-action=\"handleViewDetails\" class=\"btn btn-tertiary\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":22}}}) : helper))) != null ? stack1 : "")
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"secureAccountNumber") || (depth0 != null ? lookupProperty(depth0,"secureAccountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secureAccountNumber","hash":{},"data":data,"loc":{"start":{"line":13,"column":25},"end":{"line":13,"column":48}}}) : helper)))
    + "\n        </button>\n    </li>\n	\n    <li class=\"list-column totalField\">\n        <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"balanceLabel") || (depth0 != null ? lookupProperty(depth0,"balanceLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"balanceLabel","hash":{},"data":data,"loc":{"start":{"line":18,"column":15},"end":{"line":18,"column":31}}}) : helper)))
    + "</label>\n        <div class=\"totalField-amount-wrapper\">\n            <p title=\""
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"amountAvailable") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":20,"column":22},"end":{"line":20,"column":48}}}))
    + "\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"amountAvailable") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":76}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBalance") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </li>\n\n    <li class=\"list-column menuField\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n";
},"useData":true});