/* istanbul ignore next */
const styles = ({ snackbarReact }) => ({
  snack: {
    '&:last-child': {
      marginBottom: 0
    },
    alignItems: 'center',
    background: snackbarReact.backgroundColor,
    borderColor: ({ error }) => (error
      ? snackbarReact.borderColorError
      : snackbarReact.borderColor),
    borderLeft: '4px solid',
    boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: ({ long, withShowToggle }) => ((long || withShowToggle) ? 'wrap' : undefined),
    fontFamily: snackbarReact.font,
    marginBottom: 20,
    maxWidth: 550,
    minWidth: 344,
    padding: 16,

    zIndex: 100,
    '& p, & button': {
      fontFamily: snackbarReact.font,
      fontSize: 16,
      lineHeight: '24px'
    }
  },
  message: {
    color: snackbarReact.messageColor,
    margin: 0
  },
  toggle: {
    margin: 0,
    width: '100%',
    '& p': {
      whiteSpace: 'nowrap',
      color: snackbarReact.messageColor,
      top: -3,
      position: 'relative'
    }
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    flexBasis: ({ long, withShowToggle }) => ((long || withShowToggle) ? '100%' : undefined),
    marginLeft: ({ long, withShowToggle }) => ((long || withShowToggle) ? undefined : 32),
    marginTop: ({ long, withShowToggle }) => ((long || withShowToggle) ? 16 : undefined)
  },
  actionButton: {
    color: snackbarReact.actionColor,
    textAlign: 'right',
    marginLeft: 20,
    flexBasis: 0,
    whiteSpace: 'nowrap'
  }
});

export default styles;
