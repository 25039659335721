import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';

export default Collection.extend({

    initialize(requestData) {
        this.data = requestData || {};
    },

    sync(method, collection, options) {
        const url = services.generateUrl('/cxp/elearning/getAllElearningGroups');

        http.post(url, this.data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    parse(response) {
        const returnArray = [];
        util.each(response, (rowItem) => {
            const obj = {
                id: rowItem.groupId,
                groupId: rowItem.groupId,
                name: rowItem.name,
                locale: rowItem.locale,
                title: rowItem.name,
            };
            returnArray.push(obj);
        });
        return returnArray;
    },

});
