export default function (form, initialState) {
    const { formView } = form;
    const formState = formView.state;

    if (initialState) {
        if (formState !== 'view') {
            // hide the second group (status & account count)
            formView.$('div.row:last-of-type').hide();
            /*
             * make usergroup readonly in insert and modify mode, programmatically
             * dont want to make usergroup protected via protected fields b/c it appears differently
             * in insert and modify mode
             */
            form.fields.USERGROUP.shouldBeReadOnly(true);
            // in modify mode protect LOCATIONNUMBER
            if (formState === 'modify') {
                form.fields.LOCATIONNUMBER.shouldBeReadOnly(true);
            }
        }
    }
}
