import CompositeView from '@glu/core/src/compositeView';
import constants from 'app/administration/constants';
import util from '@glu/core/src/util';
import ReportGroupView from './reportGroupView';
import reportingTmpl from './reporting.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default CompositeView.extend({
    template: reportingTmpl,
    itemView: ReportGroupView,
    itemViewContainer: '.item-view-container',

    ui: {
        $selectAll: '.select-all-reports',
    },

    events: {
        'click @ui.$selectAll': 'selectAll',
    },

    initialize(opts) {
        const groupCollection = this.model.get('groups');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isEntitled()));
        }

        this.collection = groupCollection;
        this.itemViewOptions = {
            userGroup: opts.model.userGroupModel.get('id'),
            mode: opts.mode,
        };
        this.boundUpdateSelectAllState = () => util.defer(() => this.updateSelectAllState());
    },

    onRender() {
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el.find('.report-group input[type="checkbox"]');
    },

    selectAll(evt) {
        const { checked } = evt.currentTarget;
        this.collection.each((group) => {
            group.get('types').at(0).get('entitlements').each((entitlement) => {
                if (entitlement && entitlement.get('functionCode') === 'AUTOMTN') {
                    entitlement.set('actions.DLOAD.entitled', checked);
                    entitlement.set('actions.SELECT.entitled', checked);
                } else {
                    entitlement.set('actions.view.entitled', checked);
                    entitlement.set('actions.manage.entitled', checked);
                }
                this.$(`#report-entitlement-${entitlement.cid}`).prop('checked', checked);
            });
        });
        this.$el.find('.select-all-reports-group').prop('checked', checked);
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === constants.MODES.VIEW,
        };
    },
});
