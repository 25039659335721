import CollectionView from '@glu/core/src/collectionView';
import ThresholdPanel from './thresholdPanel';

export default CollectionView.extend({
    itemView: ThresholdPanel,

    itemEvents: {
        'remove:threshold': 'removeThreshold',
    },

    itemViewOptions(model, index) {
        return {
            index,
            currency: this.options.currency,
            sequenceTypes: this.options.sequenceTypes,
            approverTypes: this.options.approverTypes,
        };
    },

    /**
     * Remove the model attached to the view parameter from the collection
     * then check if the collection is empty. If it is, trigger an event
     * @param {string} eventName
     * @param {Object} view
     */
    removeThreshold(eventName, view) {
        this.collection.remove(view.model);
        if (this.collection.length === 0) {
            this.trigger('last:thresholdPanel:removed');
        }
    },
});
