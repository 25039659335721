var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-primary\">\n    <div class=\"panel-heading\"\n         role=\"tab\"\n         id=\"thresholdPanel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":37}}}) : helper)))
    + "\">\n        <h3 class=\"panel-title\">\n            <a class=\"collapsed\"\n               role=\"button\"\n               data-toggle=\"collapse\"\n               href=\"#thresholdCollapse-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":9,"column":40},"end":{"line":9,"column":49}}}) : helper)))
    + "\"\n               aria-expanded=\"false\"\n               aria-controls=\"thresholdCollapse-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":57}}}) : helper)))
    + "\">\n                <span class=\"indicator-icon\"></span>\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"getAmount") || (depth0 != null ? lookupProperty(depth0,"getAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getAmount","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":29}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"currency") || (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":42}}}) : helper)))
    + " -\n                <span data-hook=\"getSequenceCount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"approvalSequences") || (depth0 != null ? lookupProperty(depth0,"approvalSequences") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"approvalSequences","hash":{},"data":data,"loc":{"start":{"line":14,"column":51},"end":{"line":14,"column":72}}}) : helper)))
    + "</span>\n                "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Approval.Sequences",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":64}}}))
    + "\n            </a>\n            <a class=\"Panel-region--close\"\n               role=\"button\"\n               data-hook=\"getRemoveThresholdButton\">\n                <span class=\"icon-close\"></span>\n            </a>\n        </h3>\n    </div>\n    <div id=\"thresholdCollapse-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":24,"column":31},"end":{"line":24,"column":40}}}) : helper)))
    + "\"\n         class=\"panel-collapse collapse\"\n         role=\"tabpanel\"\n         aria-labelledby=\"thresholdPanel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":27,"column":41},"end":{"line":27,"column":50}}}) : helper)))
    + "\"\n         aria-expanded=\"false\">\n        <div class=\"panel-body\">\n            <div data-region=\"thresholdRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});