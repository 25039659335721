import ItemView from '@glu/core/src/itemView';
import accountHeaderTmpl from './accountHeader.hbs';

export default ItemView.extend({
    template: accountHeaderTmpl,

    modelEvents: {
        change: 'render',
    },
});
