import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import FailedRecordsGrid from 'app/administration/views/fileImport/failedRecords/failedRecordsListView';
import SuccessfulImportsGrid from 'app/administration/views/fileImport/successfulImports/successfulImportsListView';
import workspaceHelper from 'common/workspaces/api/helper';
import Model from '@glu/core/src/model';
import fiDetailTmpl from './fiDetail.hbs';

export default Layout.extend({
    template: fiDetailTmpl,

    records: {
        success: {
            loaded: false,
            count: 0,
        },
        failed: {
            loaded: false,
            count: 0,
        },
    },

    initialize(options) {
        this.model = options.model || new Model();

        const params = {
            parent: this,
        };

        this.failedRecordsGrid = new FailedRecordsGrid(params);
        this.successfulImportsGrid = new SuccessfulImportsGrid(params);

        this.listenTo(this, 'recsLoaded', this.recsLoaded);
    },

    recsLoaded(data) {
        this.records = Object.assign(this.records, data);
        if (this.records.success.loaded && this.records.failed.loaded
            && !this.records.success.count && !this.records.failed.count) {
            this.failedRecordsGrid.trigger('showFailedRecords');
        }
    },

    handleBack() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    templateHelpers() {
        return {
            id: this.cid,

            getString(code) {
                return locale.get(code);
            },

            getFileName() {
                return store.get('fileOriginalName');
            },
        };
    },

    allGridsLoadComplete() {
        if (store.get('failedRecordsGridLoaded') === true && store.get('successfulImportsGridLoaded') === true) {
            store.set('failedRecordsGridLoaded', false);
            store.set('successfulImportsGridLoaded', false);
        }
    },

    onRender() {
        this.listenTo(this, 'bothGridsLoaded', this.allGridsLoadComplete);

        const options = {
            parent: this,
        };

        this.successfulImportsGrid = new SuccessfulImportsGrid(options);
        this.failedRecords.show(new FailedRecordsGrid(options));
        this.successfulImports.show(this.successfulImportsGrid);
    },
});
