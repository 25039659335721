var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <button type=\"button\" class=\"btn btn-tertiary print\" data-hook=\"print-button\"><span class=\"icon-print\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":143},"end":{"line":17,"column":168}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">\n                                <span class=\"account-number\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":3,"column":94}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"ACCOUNT_NUM") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_NUM") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"ACCOUNT_NUM","hash":{},"data":data,"loc":{"start":{"line":3,"column":95},"end":{"line":3,"column":110}}}) : helper)))
    + "</span>\n                                <span class=\"account-name\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":4,"column":59},"end":{"line":4,"column":74}}}) : helper)))
    + "</span>\n                                <span class=\"account-currency\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"CURRENCY_CODE") || (depth0 != null ? lookupProperty(depth0,"CURRENCY_CODE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CURRENCY_CODE","hash":{},"data":data,"loc":{"start":{"line":5,"column":63},"end":{"line":5,"column":80}}}) : helper)))
    + "</span>\n                                <span class=\"account-date-range\">\n                                        "
    + alias2(((helper = (helper = lookupProperty(helpers,"filterStartDate") || (depth0 != null ? lookupProperty(depth0,"filterStartDate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"filterStartDate","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":59}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.hyphen",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":60},"end":{"line":7,"column":83}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"filterEndDate") || (depth0 != null ? lookupProperty(depth0,"filterEndDate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"filterEndDate","hash":{},"data":data,"loc":{"start":{"line":7,"column":84},"end":{"line":7,"column":101}}}) : helper)))
    + "\n                                </span>\n        </h1>\n</div>\n\n<div class=\"page\">\n        <div class=\"section section-container\">\n\n                <div class=\"widget-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAccountPrint") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "                </div>\n\n                <div class=\"section-body\">\n                        <div data-hook=\"account-summary-region\"></div>\n                        <div data-hook=\"summary-body-region\"></div>\n                        <div data-hook=\"transactions-list-region\"></div>\n                        <div data-hook=\"split-transactions-list-region\"></div>\n                </div>\n\n                <div class=\"section-footer\">\n            <button type=\"button\" class=\"btn btn-primary\" data-hook=\"back-button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":82},"end":{"line":29,"column":118}}}))
    + "</button>\n                </div>\n        </div>\n</div>\n";
},"useData":true});