import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import importDetailTmpl from './importDetail.hbs';

const ImportDetail = ItemView.extend({
    template: importDetailTmpl,

    templateHelpers() {
        return {
            numItems: this.model.length,
            isPlural: (this.model.length !== 1),

            text() {
                if (this.model.length !== 1) {
                    return locale.get('cashflow.imported.success.plural', this.model.length);
                }
                return locale.get('cashflow.imported.success');
            },
        };
    },
});

export default ImportDetail;
