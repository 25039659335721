import Model from '@glu/core/src/model';
import DataEntitlements from '../../collection/user/dataEntitlements';

export default Model.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            configuredDataType: '',
            dataEntitlements: new DataEntitlements(),
            functionGroup: '',
            inquiryId: null,
            keyCol: '',
            type: '',
        };
    },

    parse(response) {
        response.dataEntitlements = new DataEntitlements(response.dataEntitlements, {
            inquiryId: response.inquiryId,
            keyCol: response.keyCol,
            configuredDataType: response.configuredDataType,
        });
        return response;
    },
});
