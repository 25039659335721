var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog modal-lg glu-dialog custom-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button aria-hidden=\"false\" data-dismiss=\"modal\" class=\"close\" type=\"button\"><span class=\"icon-close\"></span></button>\n                        <h2>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":37}}}) : helper)))
    + "</h2>\n        </div>\n\n        <div class=\"modal-body\" data-hook=\"region-main\">\n        </div>\n\n        <div class=\"modal-footer\">\n        </div>\n\n    </div>\n</div>\n";
},"useData":true});