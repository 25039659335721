import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import LimitsUtil from '../limitsUtil';
import quickLimitsTmpl from './quickLimits.hbs';

const LimitsModel = Model.extend({
    defaults: {
        tranLimit: null,
        dailyLimit: null,
        batchLimit: null,
    },
});

const QuickLimits = Layout.extend({
    template: quickLimitsTmpl,

    ui: {
        transactionLimit: 'input[name="transactionLimit"]',
        dailyLimit: 'input[name="dailyLimit"]',
        batchLimit: 'input[name="batchLimit"]',
    },

    initialize(options) {
        this.isACH = options.isACH;

        // eslint-disable-next-line
        this.model = new LimitsModel();

        // include only if ach.
        if (!this.isACH) {
            this.model.unset('batchLimit');
        }

        this.dialogTitle = locale.get('uce.quickLimitsTitle');
        this.modalClass = 'quick-limits-modal';

        this.dialogButtons = [{
            text: locale.get('button.apply'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        }];
    },

    onRender() {
        this.$('input').inputmask(
            'number',
            LimitsUtil.wholeNumberWithSeparatorFormat(),
        );
    },

    cancel() {
        Dialog.close();
    },

    submit() {
        this.trigger('applyQuickLimits', this.model);
        Dialog.close();
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            isACH: this.isACH,
        };
    },
});

export default QuickLimits;
