import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import saveAsTmpl from './saveAs.hbs';

const SaveAs = ItemView.extend({
    template: saveAsTmpl,

    ui: {
        $text: 'input[data-hook="plan-name"]',
        $snapshot: 'button[data-hook="save-as-snapshot"]',
        $saveNew: 'button[data-hook="save-as-new"]',
        $hint: 'span[data-hook="name-hint"]',
    },

    events: {
        'click @ui.$snapshot': 'saveAsSnapshot',
        'click @ui.$saveNew': 'saveAsNew',
        'keyup @ui.$text': 'updateForm',
        'change @ui.$text': 'updateForm',
    },

    // Necessary when placing the view with `dialog.custom`
    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    initialize(options) {
        this.plans = options.plans;
        this.listenTo(
            this.model,
            {
                request: this.handleRequest,
                sync: this.handleSync,
            },
        );
    },

    handleRequest() {
        // TODO once glu is updated, remove data-loader from DOM and 'loading' class here
        this.$('button').addClass('loading').attr('aria-busy', true);
    },

    handleSync() {
        this.$('button').removeClass('loading').attr('aria-busy', false);
        this.model.set({
            asNewVersion: false,
        });
        this.close();
    },

    saveAsSnapshot() {
        this.model.set('asNewVersion', true);
        this.model.save();
    },

    getNameHints() {
        const hints = [];

        if (this.ui.$text.val().trim().length === 0) {
            hints.push(locale.get('cashflow.plan.name.required'));
        }

        if (!this.isNameUnique()) {
            hints.push(locale.get('cashflow.name.exists.error'));
        }

        return hints;
    },

    isNameUnique() {
        const otherNames = util.reject(this.plans.pluck('name'), function (name) {
            return name === this.model.get('name');
        }, this);

        return !util.contains(otherNames, this.ui.$text.val());
    },

    updateForm() {
        this.updateHint();
        this.updateButtons();
    },

    updateButtons() {
        const nameMatches = (this.ui.$text.val() === this.model.get('name'));

        this.ui.$saveNew.toggle(!nameMatches);
        this.ui.$snapshot.toggle(nameMatches);
    },

    updateHint() {
        const hints = this.getNameHints();
        this.ui.$hint.parents('.form-group').toggleClass('has-error', hints.length > 0);
        this.ui.$hint.text(hints.join(' '));
    },

    saveAsNew() {
        if (this.getNameHints().length > 0) {
            // problems with the name; don't save
            this.updateHint();
            return;
        }

        this.model.set({
            name: this.ui.$text.val(),
            cashFlowPlanId: null,
            multiYearPlanId: null,
            version: 0,
            asNewVersion: false,
        });
        this.model.save();
    },

    onRender() {
        this.updateButtons();
    },
});

export default SaveAs;
