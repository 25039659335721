import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import locale from '@glu/locale';
import ColumnItem from './ColumnItem';
import StyleContext from '../StyleContext/StyleContext';
import ColumnsDroppable from './ColumnsDroppable';
import ColumnsDraggable from './ColumnsDraggable';

export default function ColumnDragAndDropper({
  disableDrag,
  handleColumnPinning,
  locked,
  maxPrimary,
  onOrderChange,
  otherColumns,
  primaryColumns,
  ...props
}) {
  const classes = useContext(StyleContext);
  function renderDraggable(column, index) {
    return (
      <ColumnsDraggable
        key={column.field}
        index={index}
        columnField={column.field}
        isDragDisabled={disableDrag}
      >
        <ColumnItem
          {...props}
          column={column}
          handleColumnPinning={handleColumnPinning}
        />
      </ColumnsDraggable>
    );
  }

  function renderLockedAndPrimary() {
    const lockedItems = locked.map((column) => (
      <ColumnItem
        {...props}
        key={column.field}
        column={column}
        handleColumnPinning={handleColumnPinning}
      />
    ));

    if (!maxPrimary) return lockedItems;

    /*
    * If the locked position happens to be after the primary columns,
    * ensure the styling is still accurate
    */
    if (locked.length > maxPrimary) {
      const lockedAndPrimary = lockedItems.slice(0, maxPrimary);
      const lockedNonPrimary = lockedItems.slice(maxPrimary);

      return (
        <>
          <div className={classes.primaryColumns}>
            {lockedAndPrimary}
          </div>
          <div className={classes.nonPrimaryColumns}>
            {lockedNonPrimary}
          </div>
        </>
      );
    }

    return (
      <div className={classes.primaryColumns}>
        {lockedItems}
        <ColumnsDroppable primary>
          {primaryColumns.map(renderDraggable)}
        </ColumnsDroppable>
      </div>
    );
  }

  function renderNonPrimary() {
    const droppable = (
      <ColumnsDroppable>
        {otherColumns.map(renderDraggable)}
      </ColumnsDroppable>
    );

    if (!maxPrimary) return droppable;

    return (
      <div className={classes.nonPrimaryColumns}>
        {droppable}
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onOrderChange}>
      {!!maxPrimary && (
        <p className={classes.maxPrimaryLabel}>{`${locale.get('dataComponents.maxPrimary')} ${maxPrimary}`}</p>
      )}
      {
        /*
        * Right now there are 2 Droppables so that the border between them can move dynamically
        * when doing drag-and-drop between the primary and non-primary sections
        */
      }
      {renderLockedAndPrimary()}
      {renderNonPrimary()}
      {locked.length === 0 && otherColumns.length === 0 && (
        <span className={classes.noResults}>{locale.get('dataComponents.column.none')}</span>
      )}
    </DragDropContext>
  );
}

const columnShape = PropTypes.shape({
  field: PropTypes.string.isRequired,
  headerName: PropTypes.string.isRequired,
  hide: PropTypes.bool
});

ColumnDragAndDropper.propTypes = {
  /** Flag to disable drag/drop for entire module */
  disableDrag: PropTypes.bool,

  handleColumnPinning: PropTypes.func,

  locked: PropTypes.arrayOf(columnShape),

  maxPrimary: PropTypes.number,

  onOrderChange: PropTypes.func.isRequired,

  otherColumns: PropTypes.arrayOf(columnShape),

  primaryColumns: PropTypes.arrayOf(columnShape)
};

ColumnDragAndDropper.defaultProps = {
  disableDrag: false,
  handleColumnPinning: () => { },
  locked: [],
  maxPrimary: 0,
  otherColumns: [],
  primaryColumns: []
};
