import { $, util, core } from '@glu/core';
import template from './screenManager.hbs';

const { LayerView } = core; // yes, this is really must be so.

function findVisible(element) {
  return $(element).css('display') !== 'none';
}

const ScreenManager = LayerView.extend({
  template,

  isRendered: false,

  size: false,

  throttleThreshold: 50,

  className: 'glu-screen-manager',

  ui: {
    elements: '> div'
  },

  initialize() {
    this.handler = util.throttle(util.bind(this.handleResize, this), this.throttleThreshold);
    this.listenToResize();
  },

  setSize(size) {
    if (this.size !== size) {
      this.size = size;
      this.trigger('change', size, this);
      this.trigger(`change:${size}`, this);
    }
  },

  getSize() {
    if (!this.size) {
      this.refresh();
    }
    return this.size;
  },

  refresh() {
    if (!this.isRendered) {
      this.render();
    }
    const element = this.findVisible();
    const size = element.getAttribute('data-size');
    this.setSize(size);
  },

  handleResize() {
    this.refresh();
  },

  findVisible() {
    return util.find(this.ui.elements, findVisible);
  },

  render() {
    LayerView.prototype.render.call(this);
    this.isRendered = true;
  },

  remove() {
    LayerView.prototype.remove.call(this);
    $(window).off('resize', this.handler);
  },
  // allows a controller to reattach the handler in case the view was removed.
  // Mainly for testing flexibility.
  listenToResize() {
    $(window).resize(this.handler);
  },

  isLarge() {
    return this.isSize('large');
  },

  isMedium() {
    return this.isSize('medium');
  },

  isSmall() {
    return this.isSize('small');
  },

  isExtraSmall() {
    return this.isSize('extra-small');
  },

  isSize(size) {
    return this.getSize() === size;
  },

  // Copied from Modernizr
  isTouch() {
    return ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch));
  }

});

const sm = new ScreenManager();
// return single instance
export default sm;

