import ItemView from '@glu/core/src/itemView';
import ChildRow from 'common/grid/expandRow/expandRowChildRowView';
import expandRowCellViewTmpl from './expandRowCellView.hbs';

/**
 * Intended to be used to render a cell within a grid that allows for an injection
 * of a view.  This will render the view as a child row when the user has
 * clicked it.  It will not persist
 * the child row along the render cycle, so if the user sorts or filters, the
 * child row is disposed of until
 * the user takes action again.
 *
 */
export default ItemView.extend({
    template: expandRowCellViewTmpl,

    events: {
        'click a': 'handleClick',
    },

    initialize(options) {
        this.column = options.column;
        this.model = options.model;
        this.onInitialize(options);
    },

    onClose() {
        if (this.isExpanded()) {
            this.collapse();
        }
    },

    handleClick(e) {
        e.stopPropagation();
        this.handleChildRow();
        this.render();
        this.onClick();
    },

    handleChildRow() {
        if (this.isExpanded()) {
            this.collapse();
        } else {
            this.expand();
        }
    },

    expand() {
        this.collapse();
        const parent = this.$el.parent('tr');
        this.childRow = new ChildRow({
            colspan: this.getColumnSpan(),
        });
        this.childRow.render();
        this.childRow.$el.insertAfter(parent);
        this.model.expanded = true;
        this.render();
    },

    collapse() {
        if (this.childRow) {
            this.childRow.close();
        }
        this.model.expanded = false;
        this.render();
    },

    isExpanded() {
        return this.model.expanded;
    },

    templateHelpers() {
        const self = this;
        return {
            expanded: self.model.expanded,

            getLabel() {
                return self.getLabel();
            },
        };
    },

    showView(view) {
        this.childRow.cell.show(view);
    },

    /*
     * Subclasses can override to provide their own column span.
     * By default it returns a colspan equal to the length of all the columns.
     *
     * @example
     * getColumnSpan: function(){}
     *
     * @return the column span for this column.
     */
    getColumnSpan() {
        return this.column.collection.length;
    },

    /*
     * Subclasses can override to provide their own label.
     * By default it returns the value for the field name associated with this column.
     *
     * @example
     * getLabel: function(){}
     *
     * @return the text to display next to the up/down arrow icon.
     */
    getLabel() {
        return this.model.get(this.column.get('fieldName'));
    },

    /*
     * Subclasses can override to handle additional logic for a click on the
     * up/down arrow.
     *
     * @example
     * onClick: function(){}
     */
    onClick() {
        // override as needed in child class
    },

    /*
     * Subclass can be overriden to handle additional logic on initialize.
     *
     * @example
     * onInitialize: function(options){}
     */
    onInitialize() {
        // override as needed in child class
    },
});
