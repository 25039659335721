var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp;\n            <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":84}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\"></h1>\n\n</div>\n\n<section class=\"section section-container\">\n\n    <div class=\"section-body\">\n    <div class=\"alert-region\"><div data-hook=\"alertRegion\"></div></div>\n\n    <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"tableMaintenance.scheduledPayments_title-spaymt","Schedule Payment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":87}}}))
    + "</h2>\n\n    <div class=\"row\">\n            <div class=\"form-group col-3\">\n                <label for=\"TYPEDESC\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.PaymentType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":38},"end":{"line":20,"column":66}}}))
    + ":</label>\n                <p id=\"TYPEDESC\" class=\"form-control-static\" data-text=\"TYPEDESC\" data-bind=\"model\">\n            </div>\n            <div class=\"form-group col-3\">\n                <label for=\"TEMPLATEDESC\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":78}}}))
    + ":</label>\n                <p id=\"TEMPLATEDESC\" class=\"form-control-static\" data-text=\"TEMPLATEDESC\" data-bind=\"model\">\n            </div>\n            <div class=\"form-group col-3\">\n                <label for=\"TEMPLATECODE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Template Code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":42},"end":{"line":28,"column":72}}}))
    + ":</label>\n                <p id=\"TEMPLATECODE\" class=\"form-control-static\" data-text=\"TEMPLATECODE\" data-bind=\"model\">\n            </div>\n    </div>\n\n    <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"schedpay.section.rec","Recurrence Pattern",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":62}}}))
    + "</h2>\n\n        <fieldset>\n                <div class=\"row\"><div data-hook=\"schedulerRegion\" class=\"col-4\"></div></div>\n\n                <form class=\"form-inline\">\n                    <fieldset>\n                        <legend class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.NON_BIZ_DAY_MESSAGE","If Transfer Date falls on a non-business day, transfer funds on:",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":49},"end":{"line":40,"column":162}}}))
    + "</legend>\n\n                        <div class=\"radio\">\n                            <div class=\"radio-inline\">\n                                <input id=\"prevRadio\" name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"prev\" data-bind=\"model\">\n                                <label for=\"prevRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.PREV_BUSINESS_DAY","Previous business day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":55},"end":{"line":45,"column":123}}}))
    + "</label>\n                            </div>\n                            <div class=\"radio-inline\">\n                                <input id=\"nextRadio\" name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"next\" data-bind=\"model\">\n                                <label for=\"nextRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.NEXT_BUSINESS_DAY","Next business day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":55},"end":{"line":49,"column":119}}}))
    + "</label>\n                            </div>\n                            <div class=\"radio-inline\">\n                                <input id=\"voidRadio\" name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"none\" data-bind=\"model\">\n                                <label for=\"voidRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.DO_NOT_TRANSFER_FUNDS","Do not transfer funds",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":55},"end":{"line":53,"column":127}}}))
    + "</label>\n                            </div>\n                        </div>\n                    </fieldset>\n                </form>\n         </fieldset>\n    </div>\n\n</section>\n  \n<div class=\"section section-summary\">\n    \n    <div class=\"section-body\">\n        <div data-hook=\"schedulePayWarning\"></div>\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" class=\"btn btn-primary\" data-hook=\"submit-button\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":85},"end":{"line":68,"column":109}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"cancel-button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":69,"column":86},"end":{"line":69,"column":112}}}))
    + "</button>\n        </div>\n    </div>\n</div>";
},"useData":true});