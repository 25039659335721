import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import ControlDisbursementCollection from 'app/reports/collections/controlDistSum';
import AccountSummaryTotalsView from 'app/reports/views/cd/accountSummary';
import userInfo from 'etc/userInfo';
import ControlDisbursementModel from 'app/reports/models/cdReport';
import CDAccountsCollection from 'app/reports/collections/controlDistAcct';
import LockboxReportApi from 'app/reports/api/lockboxReports';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import PrintReportView from 'app/reports/views/cd/printReport';
import EmptyReportView from 'app/reports/views/cd/emptyReport';
import dialog from '@glu/dialog';
import CollectionView from '@glu/core/src/collectionView';
import locale from '@glu/locale';
import errHandler from 'system/errHandler';
import util from '@glu/core/src/util';
import moment from 'moment';
import Formatter from 'system/utilities/format';
import helpPageUtil from 'common/util/helpPage';
import cdReportTmpl from 'app/reports/views/cd/cdReport.hbs';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import workspaceHelper from 'common/workspaces/api/helper';

const CtrlDisbReportLayout = Layout.extend({
    template: cdReportTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $datePicker: '.datepicker',
        $multiple: '[multiple="multiple"]',
        $print: '.print',
        $export: '.export',
    },

    initialize() {
        this.model = new ControlDisbursementModel();
        this.setSearchRequest();
    },

    modelEvents: {
        'change:cdMultiSelect': 'onSelectionChange',
    },

    onSelectionChange() {
        const ALL_ACCOUNTS_VALUE = 'all';
        const selectedOptions = this.ui.$multiple.val();
        if (selectedOptions) {
            const allAccountsOptionSelected = selectedOptions.indexOf(ALL_ACCOUNTS_VALUE) > -1;
            if (selectedOptions.length === 2 && allAccountsOptionSelected) {
                const filteredSelected = selectedOptions
                    .filter(option => option !== ALL_ACCOUNTS_VALUE);
                this.ui.$multiple.comboBox('val', filteredSelected);
            } else if (allAccountsOptionSelected) {
                this.ui.$multiple.comboBox('val', ALL_ACCOUNTS_VALUE);
            }
            this.model.set('cdMultiSelect', this.ui.$multiple.val());
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.initDatePicker();
            this.initComboBox();
            this.createFormattedReportCollection();
            this.renderReport();
        } else {
            this.setAllPromises();
            this.loadRequiredData();
        }
    },

    initDatePicker() {
        this.ui.$datePicker.nhDatePicker({
            startDate: moment(new Date()).format(userInfo.getDateFormat()),
            showCalendarIcon: true,
            showDropdowns: true,
            rangeDatePicker: true,
            allowPreviousYearInRange: true,
            iconClass: 'cdFloatNone',
        });
    },

    maskedInputMatcher(params, data) {
        // if no params, no search, display the item
        if (!params) {
            return data;
        }
        const thisItem = this.accountCollection.find((item) => {
            const itemInfo = `${item.get('AccountNum')} - ${item.get('CLIENTACCOUNTNAME')}`;
            return itemInfo === data;
        });
        const matchesParams = thisItem?.get('AccountFilter')?.indexOf(params) !== -1;
        return matchesParams ? data : null;
    },

    initComboBox() {
        this.ui.$multiple.comboBox({
            matcher: this.maskedInputMatcher.bind(this),
        });
        this.ui.$multiple.val('all').trigger('change');
    },

    renderReport() {
        const self = this;
        this.$('.cdReport').empty();
        this.totalTransactionCount = 0;
        this.accounts = [];
        this.reportSummaryCollectionView = new CollectionView({
            el: '.cdReport',
            collection: this.reportSummaryCollection,
            itemView: AccountSummaryTotalsView,

            itemViewOptions: {
                searchRequest: this.searchRequest,
            },

            emptyView: EmptyReportView,
        });

        this.reportSummaryCollection.each((model) => {
            self.totalTransactionCount += model.get('TOTALITEMSREPORTED');
            const collection = model.get('accountCollection');
            collection.each((accountModel) => {
                self.accounts.push(accountModel.get('account'));
            });
        });
        this.reportSummaryCollectionView.render();
        this.$('.first-panel').click();
    },

    createFormattedReportCollection() {
        let parentData = [];

        const groupByDate = this.collection.groupBy(grpModel => grpModel.get('ASOFDATE'));

        Object.keys(groupByDate || {}).forEach((date) => {
            const childData = [];
            const groupByAccount = util.groupBy(groupByDate[date], child => child.get('ACCOUNTFILTER'));

            let parentSpStatus = locale.get('gir.final');

            Object.keys(groupByAccount || {}).forEach((account) => {
                let fpAmountReported = 0;
                let fpTotalTransactions = 0;
                let spAmountReported = 0;
                let spTotalTransactions = 0;

                const accountModels = util.map(groupByAccount[account], model => model.attributes);

                const firstPresentmentArray = util.where(
                    accountModels,
                    {
                        PRESENTMENT: '1',
                    },
                );
                const secondPresentmentArray = util.where(
                    accountModels,
                    {
                        PRESENTMENT: '2',
                    },
                );

                const showSecondPresentment = secondPresentmentArray.length > 0;

                fpAmountReported = this.summarize(util.pluck(firstPresentmentArray, 'AMOUNTREPORTED'));
                fpTotalTransactions = this.summarize(util.pluck(firstPresentmentArray, 'ITEMSREPORTED'));

                if (showSecondPresentment) {
                    spAmountReported = this.summarize(util.pluck(secondPresentmentArray, 'AMOUNTREPORTED'));
                    spTotalTransactions = this.summarize(util.pluck(secondPresentmentArray, 'ITEMSREPORTED'));
                } else {
                    spAmountReported = false;
                    spTotalTransactions = false;
                }

                const childAccount = {
                    account,
                    accountNum: accountModels[0].ACCOUNT_NUM,
                    date,
                    name: groupByAccount[account][0].get('CLIENTACCOUNTNAME'),
                    fpAmount: Formatter.formatCurrency(fpAmountReported),
                    spAmount: spTotalTransactions === false ? '' : Formatter.formatCurrency(spAmountReported),
                    fpTransactions: fpTotalTransactions,
                    spTransactions: spTotalTransactions === false ? '' : spTotalTransactions,
                    showSecondPresentment,
                    fpStatus: locale.get('gir.final'),
                    spStatus: spTotalTransactions === false ? '' : locale.get('gir.final'),
                };

                childData.push(childAccount);
            });

            const dateCollection = util.where(
                this.collection.toJSON(),
                {
                    ASOFDATE: date,
                },
            );

            const amountsReported = this.summarize(util.pluck(dateCollection, 'AMOUNTREPORTED'));
            const totalTransactions = this.summarize(util.pluck(dateCollection, 'ITEMSREPORTED'));

            parentData = parentData.sort((a, b) => a.ACCOUNT_NUM - b.ACCOUNT_NUM);

            const emptySpTransactionsFound = util.findWhere(
                childData,
                {
                    spTransactions: '',
                },
            );

            parentSpStatus = emptySpTransactionsFound ? '' : locale.get('gir.final');

            parentData.push({
                ASOFDATE: date,
                accountCollection: new ControlDisbursementCollection(childData),
                epoch: moment(date).unix(),
                count: util.size(groupByAccount),
                TOTALAMOUNTREPORTED: Formatter.formatCurrency(amountsReported),
                TOTALITEMSREPORTED: totalTransactions,
                first: locale.get('gir.final'),
                second: parentSpStatus,
            });
        });

        parentData = parentData.sort((a, b) => b.epoch - a.epoch);

        if (parentData.length > 0) {
            parentData[0].isFirst = true;
            this.toggleActionButtons();
        }

        this.reportSummaryCollection = new ControlDisbursementCollection(parentData);
    },

    summarize(numbers) {
        return util.reduce(numbers, (memo, value) => {
            let number = value;
            if (typeof value === 'string') {
                number = +value.replace(/[^0-9-.]/g, '');
            }
            return memo + number;
        }, 0);
    },

    search() {
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }
        const self = this;
        const criteria = {
            searchCriteria: {
                date: {
                    range: this.model.get('dateRange'),
                    min: this.model.getMinDate(),
                    max: this.model.getMaxDate(),
                },

                minimum: this.model.get('minimum'),
                maximum: this.model.get('maximum'),
                accounts: this.model.get('cdMultiSelect'),
            },
        };

        this.collection = new ControlDisbursementCollection(criteria);
        this.searchRequest = this.collection.getSearchFields();
        this.model.set('searchRequest', this.searchRequest);
        store.set('CTRLDISB:Model', this.model.toJSON());

        this.collection.fetch({
            success() {
                self.createFormattedReportCollection();
                self.renderReport();
            },
        });
    },

    setAllPromises() {
        this.cdPromises = [];
        this.setHelpPagePromise();
        this.setAccountPromise();
        this.setSummaryPromise();
    },

    setAccountPromise() {
        const self = this;
        this.accountCollection = new CDAccountsCollection();
        this.cdPromises.push(new Promise((resolve, reject) => {
            self.accountCollection.fetch({
                success: resolve,
                error: reject,
            });
        }));
    },

    setSummaryPromise() {
        const self = this;

        // the default date should be limited to today
        const defaultCriteria = {
            defaultCriteria: {
                date: {
                    range: this.model.get('dateRange'),
                    min: this.model.getMinDate(),
                    max: this.model.getMaxDate(),
                },
            },
        };
        this.collection = new ControlDisbursementCollection(defaultCriteria);
        this.cdPromises.push(new Promise((resolve, reject) => {
            self.collection.fetch({
                success: resolve,
                error: reject,
            });
        }));
    },

    setHelpPagePromise() {
        // get the workspace help file for quick entry
        store.unset('helpPage');
        this.cdPromises.push(helpPageUtil.getHelpPagePromise({
            productCode: 'GIR',
            functionCode: 'CD',
            typeCode: 'CTRLDISB',
            mode: 'SELECT',
        }));
    },

    /*
     * makes print/export buttons look active if there is data to be shown
     * gets called when there's parentData
     */
    toggleActionButtons() {
        this.ui.$print.add(this.ui.$export)
            .addClass('btn-tertiary');
    },

    /*
     * checks for the btn tertiary class, which
     * gets added when there's parentData
     * see above method (toggleActionButtons)
     */
    export() {
        if (this.ui.$print.hasClass('btn-tertiary') && this.ui.$export.hasClass('btn-tertiary')) {
            const context = {
                productCode: 'GIR',
                functionCode: 'CD',
                typeCode: 'CTRLDET',
            };
            const exportData = this.buildExportData('CSV', context);
            LockboxReportApi.doExport(this, exportData);
        }
    },

    print() {
        if (this.ui.$print.hasClass('btn-tertiary') && this.ui.$export.hasClass('btn-tertiary')) {
            this.printReportView = new PrintReportView();
            this.listenTo(this.printReportView, 'printReport', this.printReport);
            dialog.open(this.printReportView);
        }
    },

    printReport(model) {
        const exportData = this.buildExportData('PDF', model.get('context'));
        const printModal = new PrintViewModal({
            exportModel: exportData,
        });
        dialog.custom(printModal);
    },

    buildExportData(format, context) {
        const gridView = {};
        gridView.wrapper = {};
        gridView.wrapper.rows = {};
        gridView.wrapper.rows.totalCount = this.totalTransactionCount;
        gridView.sortFields = [{
            fieldName: 'ASOFDATE',
            sortOrder: 'DESC',
        }, {
            fieldName: 'ACCOUNTFILTER',
            sortOrder: 'DESC',
        }];
        gridView.wrapper.sortOrder = 'DESC';
        gridView.context = context;

        const exportData = LockboxReportApi.buildExportData(format, null, gridView);
        exportData.numberOfRows = 0;

        if (!exportData.searchFields) {
            exportData.searchFields = [];
        }

        const searchField = {};
        searchField.operator = 'IN';
        searchField.fieldValue = this.accounts;
        searchField.dataType = 'TEXT';
        searchField.fieldName = 'ACCOUNTFILTER';
        exportData.searchFields.push(searchField);

        // The fieldName for amounts has changed from AMOUNTREPORTED to AMOUNT
        const amountField = this.searchRequest.find(field => field.fieldName === 'AMOUNTREPORTED');
        if (amountField) {
            amountField.fieldName = 'AMOUNT';
        }

        exportData.searchFields = exportData.searchFields.concat(this.searchRequest);

        return exportData;
    },

    loadRequiredData() {
        Promise.all(this.cdPromises)
            .then((result) => {
                // set helppage
                if (result[0].helpPage) {
                    store.set('helpPage', result[0].helpPage);
                }
                this.setHasLoadedRequiredData(true);
                this.render();
            })
            .then(null, errHandler);
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    /**
     * @method setSearchRequest
     * @description Sets searchRequest value
     */
    setSearchRequest() {
        const storedModel = store.get('CTRLDISB:Model');
        if (storedModel) {
            this.model.set(storedModel);
            this.searchRequest = this.model.get('searchRequest');
        } else {
            const defaultCriteria = {
                searchCriteria: {
                    date: {
                        range: this.model.get('dateRange'),
                        min: this.model.getMinDate(),
                        max: this.model.getMaxDate(),
                    },
                    minimum: this.model.get('minimum'),
                    maximum: this.model.get('maximum'),
                    accounts: ['all'],
                },
            };
            const defaultCollection = new ControlDisbursementCollection(defaultCriteria);
            this.searchRequest = defaultCollection.getSearchFields();
        }
    },

    templateHelpers() {
        const self = this;
        return {
            accounts() {
                const accountData = [];
                self.accountCollection.each((model) => {
                    accountData.push({
                        account: model.get('AccountFilter'),
                        label: `${model.get('CLIENTACCOUNTNAME')} - ${model.get('AccountNum')}`,
                    });
                });
                return accountData;
            },
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CTRL_DISB_REPORT',
    view: CtrlDisbReportLayout,
    options: {},
});

export default CtrlDisbReportLayout;
