var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"glu-container\">\n        <div class=\"row\">\n                <div class=\"col-3\" data-region=\"plan-select-primary\"></div>\n                <div class=\"col-3\" data-region=\"plan-select-secondary\"></div>\n                <div class=\"col-6\">\n                        <div class=\"btn-group pull-right\" data-toggle=\"buttons\">\n\n                          <label class=\"btn btn-secondary active\">\n                                <input type=\"radio\" name=\"view-toggle\" autocomplete=\"off\" checked value=\"graph\"> "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"text":"Graph","name":"bar-graph"},"data":data,"loc":{"start":{"line":9,"column":113},"end":{"line":9,"column":152}}}))
    + "\n                          </label>\n\n                          <label class=\"btn btn-secondary\">\n                                <input type=\"radio\" name=\"view-toggle\" autocomplete=\"off\" value=\"table\"> "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"text":"Table","name":"table"},"data":data,"loc":{"start":{"line":13,"column":105},"end":{"line":13,"column":140}}}))
    + "\n                          </label>\n                        </div>\n                </div>\n        </div>\n\n        <div class=\"row\">\n                <div class=\"col-12\" data-region=\"visualization\"></div>\n        </div>\n</div>\n";
},"useData":true});