import ItemView from '@glu/core/src/itemView';
import configuration from 'system/configuration';
import tmpl from './logo.hbs';

export default ItemView.extend({
    template: tmpl,
    className: 'logo-container',

    attributes: {
        'data-qa': 'logo-container',
    },

    templateHelpers() {
        return {
            appRoot() {
                return configuration.appRoot;
            },
        };
    },
});
