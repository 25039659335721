import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import adminConstants from 'app/administration/constants';
import constants from './constants';
import toaLocationTmpl from './toaLocationEntry.hbs';
import AssociatedAccountsList from './associatedAccountsList';

export default EntryView.extend({
    template: toaLocationTmpl,

    initialize(options) {
        const superOptions = {
            serviceName: constants.TOALOCATION_DETAIL_SERVICENAMEE,
            context: constants.TOALOCATION_CONTEXT,
            returnRoute: constants.TOALOCATION_RETURNROUTE,
            mode: options.mode,
            contextDef: {
                productCode: constants.TOALOCATION_PRODUCTCODE,
                functionCode: constants.TOALOCATION_FUNCTIONCODE,
                typeCode: constants.TOALOCATION_TYPECODE,
                serviceName: constants.TOALOCATION_DETAIL_SERVICENAME,
            },
        };
        EntryView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.contextKey = store.get('listView-contextKey');
        this.prefillFields = store.remove(constants.TOALOCATION_PREFILL);
        if (this.prefillFields) {
            store.set(`${this.contextKey}-preFill`, this.prefillFields);
            store.set('current-workspace-route', `${constants.TOALOCATION_RETURNROUTE}/${this.prefillFields.USERGROUP}`);
        } else {
            store.remove(`${this.contextKey}-preFill`);
        }
        this.associatedAccountsListView = null;
    },

    /**
     * @name setupListeners
     * @description sets up listeners for the pageView (entry view)
     */
    setupListeners() {
        EntryView.prototype.setupListeners.call(this);
        this.listenTo(this.pageView, 'loaded', this.showAssociatedAccounts);
    },

    getModifyUrl() {
        return constants.TOALOCATION_MODIFY_ROUTE;
    },

    /**
     * @name showAssociatedAccounts
     * @description in 'view' mode, we need to display a grid of all associated accounts
     * for the location
     */
    showAssociatedAccounts() {
        if (this.mode === adminConstants.MODES.VIEW) {
            this.associatedAccountsListView = new AssociatedAccountsList({ model: this.model });
            if (this.customRegion) {
                this.customRegion.show(this.associatedAccountsListView);
            }
        }
    },
});
