var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"account") || (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"account","hash":{},"data":data,"loc":{"start":{"line":33,"column":55},"end":{"line":33,"column":66}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":33,"column":68},"end":{"line":33,"column":77}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.controlDisbursementReport",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":73}}}))
    + "</h1>\n</div>\n<div class=\"page control-disbursement balance-and-transaction-reporting\">\n    <div class=\"section section-container\">\n        <button type=\"button\" class=\"btn  export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":83}}}))
    + "\" data-action=\"export\"><span class=\"icon-export\"></span></button>\n        <button type=\"button\" class=\"btn  print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":56},"end":{"line":7,"column":81}}}))
    + "\" data-action=\"print\"><span class=\"icon-print\"></span></button>\n        <div class=\"balance-and-transaction-filter\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.btr.filter.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":64},"end":{"line":8,"column":104}}}))
    + "\" role=\"search\">\n            <div class=\"panel\" role=\"tablist\">\n                <div aria-expanded=\"true\" class=\"panel-heading\" role=\"tab\" id=\"panel-heading-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":93},"end":{"line":10,"column":99}}}) : helper)))
    + "\" data-toggle=\"collapse\" data-target=\"#panel-body-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":149},"end":{"line":10,"column":155}}}) : helper)))
    + "\">\n                    <h2 class=\"panel-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.searchCriteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":44},"end":{"line":11,"column":75}}}))
    + "</h2>\n                    <span class=\"caret-indicator\" role=\"presentation\"></span>\n                </div>\n\n                <div aria-expanded=\"true\" id=\"panel-body-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":63}}}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"panel-heading-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":147},"end":{"line":15,"column":153}}}) : helper)))
    + "\">\n\n                    <div class=\"form-inline\" role=\"form\">\n\n                        <div class=\"form-row\">\n                            <label for=\"dateRange\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.dateRange",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":90}}}))
    + "</label>\n                            <div class=\"field-container\">\n                                <input type=\"text\" class=\"datepicker form-control\" data-bind=\"model\" name=\"dateRange\" id=\"dateRange\">\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"dateRange\"></span>\n                            </div>\n                        </div>\n\n                        <div class=\"form-row\">\n                            <label for=\"cdMultiSelect\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":55},"end":{"line":28,"column":80}}}))
    + "</label>\n                            <div class=\"field-container\">\n                                <select class=\"form-control\" multiple=\"multiple\" name=\"cdMultiSelect\" id=\"cdMultiSelect\" data-bind=\"model\">\n                                    <option value=\"all\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.AllAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":56},"end":{"line":31,"column":84}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":34,"column":45}}})) != null ? stack1 : "")
    + "                                </select>\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"cdMultiSelect\"></span>\n                            </div>\n                        </div>\n\n                        <div class=\"form-row amount-range\">\n                            <div>\n                                <label for=\"minimum\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.minimumAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":53},"end":{"line":42,"column":83}}}))
    + "</label>\n                                <div class=\"field-container\">\n                                    <input type=\"text\" class=\"minimum-amount form-control\" data-bind=\"model\" name=\"minimum\" id=\"minimum\">\n                                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"minimum\"></span>\n                                </div>\n                            </div>\n                            <div>\n                                <label for=\"maximum\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.MaximumAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":53},"end":{"line":49,"column":83}}}))
    + "</label>\n                                <div class=\"field-container\">\n                                    <input type=\"text\" class=\"maximum-amount form-control\" data-bind=\"model\" name=\"maximum\" id=\"maximum\">\n                                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"maximum\"></span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class=\"actions\">\n                        <button class=\"btn btn-secondary\" data-action=\"search\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.button.update",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":79},"end":{"line":59,"column":109}}}))
    + "</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"cdReport\" data-region=\"cdReportRegion\" aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.cdReport.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":71},"end":{"line":64,"column":109}}}))
    + " \" role=\"tablist\"></div>\n    </div>\n</div>\n";
},"useData":true});