var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value="
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":15,"column":38},"end":{"line":15,"column":46}}}) : helper)))
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":55}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog modal-lg glu-dialog custom-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button aria-hidden=\"false\" data-dismiss=\"modal\" class=\"close\" type=\"button\"><span class=\"icon-close\"></span></button>\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.editContent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":56}}}))
    + "</h2>\n        </div>\n\n        <div class=\"modal-body\" data-hook=\"region-main\">\n            <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.selectMarketSegment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":64}}}))
    + " </h3>\n\n                <div>\n                    <label for=\"external-widget-segment required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.segment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":66},"end":{"line":12,"column":101}}}))
    + "</label>\n                    <select class=\"form-control\" name=\"marketSegment\"  id=\"external-widget-segment\" class=\"external-widget-segment\" aria-required=\"true\" required>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"segments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":16,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n        </div>\n\n        <!-- tinyMCE goes here -->\n\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"btn btn-primary submit save-content-edit\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":102},"end":{"line":24,"column":131}}}))
    + "</button>\n            &nbsp;\n            <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":81},"end":{"line":26,"column":112}}}))
    + "</button>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true});