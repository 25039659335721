import EntryView from 'common/dynamicPages/views/workflow/entry';
import util from '@glu/core/src/util';
import constants from 'app/smbPayments/views/employees/api/constants';

export default EntryView.extend({
    initialize(opts) {
        const options = util.extend(
            constants.options,
            {
                mode: 'modify',
                smbOverride: opts.smbOverride,
                reimburse: false,
            },
        );

        EntryView.prototype.initialize.call(this, options);
    },

    getModifyButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
        this.buttonList.push({
            action: 'SAVEDRAFT',
        });
    },

    savedraft() {
        this.disableButtons();
        this.pageView.model.set({
            _saveIncomplete: 'true',
        });
        this.pageView.save();
    },
});
