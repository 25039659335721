const styles = (theme) => {
  const {
    palette: {
      primary
    }
  } = theme;
  return {
    applyButtonWrapper: {
      alignItems: 'center',
      background: primary.darkBackground,
      boxShadow: '0px -1px 15px rgba(0,0,0,0.2)',
      boxSizing: 'border-box',
      display: 'flex',
      padding: [15, 20],
      width: '100%'
    },
    cancelButton: {
      color: '#fff',
      marginLeft: 20
    }
  };
};

export default styles;
