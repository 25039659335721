import http from '@glu/core/src/http';

/**
 * @param {string} type - Request type
 * @returns {function}
 */
function createRequest(type) {
    const acceptTypes = ['get', 'post'];
    const defaultType = 'post';
    const useType = acceptTypes.indexOf(type) !== -1 ? type : defaultType;
    return (url, data, successCb, errorCb) => {
        const dataParam = data || {};
        const successCbParam = successCb || (() => {});
        const errorCbParam = errorCb || (() => {});
        return new Promise((resolve, reject) => {
            if (!url) {
                reject(new Error('Missing service request URL'));
                return;
            }

            const params = [
                url,
                useType === 'post' ? dataParam : undefined,
                (response) => {
                    successCbParam(response);
                    resolve(response);
                },
                (err) => {
                    errorCbParam(err);
                    reject(err);
                },
            ].filter(entry => entry !== undefined);
            http[useType](...params);
        });
    };
}

/**
 * Send a POST request to a URL
 * @param {string} url - Url that will be used to send the request.
 * @param {Object} data - Data object that is sent to service
 * @param {function} [successCb] - Success Callback
 * @param {function} [errorCb] - Error Callback
 * @returns {Promise}
 */
export const postData = createRequest('post');

/**
 * Send a GET request to a URL
 * @param {string} url - Url that will be used to send the request.
 * @param {function} [successCb] - Success Callback
 * @param {function} [errorCb] - Error Callback
 * @returns {Promise}
 */
export const getData = createRequest('get');

export default {
    postData,

    getData,

    /**
     *
     * @param {Object} params - object with values to convert to url params
     * @returns {string} -
     */
    generateUrlParams(params) {
        return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    },
};
