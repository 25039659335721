import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';
import { CaretLeftNextIcon } from '@glu/icons-react';
import GridContext from '../Grid/GridContext';

const styles = {
  root: {
    display: 'inline-block'
  },

  icon: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    left: -8,
    padding: 4,
    position: 'relative',
    top: 4,

    '&:focus': {
      outline: 'auto'
    },

    '& svg': {
      transition: 'all 0.2s'
    },

    '&.expanded svg': {
      transform: 'rotate(90deg)'
    }
  }
};

const SubRowControl = ({ classes, SubRow, ...props }) => {
  const { node, api } = props;
  const rowId = node.id;
  const { expandedRows, toggleExpandOnRow } = useContext(GridContext);
  const expanded = expandedRows.includes(rowId);
  const rowApi = api.getRowNode(rowId);
  const containerRef = useRef();

  useEffect(() => {
    setTimeout(() => { // timeout as in some cases the container isn't read when useEffect fires
      if (containerRef.current && rowApi && SubRow) {
        const { height } = containerRef.current.getBoundingClientRect();
        if (height) {
          rowApi.setRowHeight(height > 50 ? height : node.height);
          api.onRowHeightChanged();
        }
      }
    }, 0);
  }, [SubRow, api, expanded, node.height, rowApi, rowId]);

  return (
    <div ref={containerRef} className={classes.root}>
      <button
        type="button"
        onClick={() => {
          toggleExpandOnRow(rowId);
        }}
        alt={locale.get(expanded ? 'caretDown' : 'caretRight')}
        aria-label={locale.get(expanded ? 'collapse' : 'expand')}
        className={`${classes.icon}${expanded ? ' expanded' : ''}`}
        title={locale.get('subRowControlTitle')}
      >
        <CaretLeftNextIcon focusable={false} noIconWrapper />
      </button>
      {expanded && SubRow ? <SubRow {...props} /> : null}
    </div>
  );
};

SubRowControl.propTypes = {
  api: PropTypes.shape({
    onRowHeightChanged: PropTypes.func.isRequired,
    getRowNode: PropTypes.func.isRequired
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  node: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Not defined on initial render */
    height: PropTypes.number
  }).isRequired,
  SubRow: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

SubRowControl.defaultProps = {
  SubRow: null
};

export const SubRowControlComponent = SubRowControl;

export default withStyles(styles)(SubRowControl);
