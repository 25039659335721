import Layout from '@glu/core/src/layout';
import inputsUtil from 'common/util/inputs';
import mobileAmountInputTmpl from './mobileAmountInput.hbs';

export default Layout.extend({
    template: mobileAmountInputTmpl,

    ui: {
        $amountInput: '[data-hook="getAmount"]',
    },

    events: {
        'keyup @ui.$amountInput': 'onKeyUp',
        'click @ui.$amountInput': 'moveCursor',
    },

    initialize() {
        this.on('resetAmount', this.resetAmount, this);
    },

    templateHelpers() {
        return {
            label: this.options.label,
            attributeName: this.options.attribute,
            attributeValue: this.model.get(this.options.attribute),
        };
    },

    /**
     * Format the value from the input, then update the input and model values
     *
     * @param {Event} e - Probably a KeyUp event
     */
    onKeyUp(e) {
        const value = this.formatAmount(e.currentTarget.value);

        this.ui.$amountInput.val(value);
        // remove the commas from the value
        this.model.set(this.options.attribute, this.removeChars(value, /[,]/g));
    },

    /**
     * When dom element is clicked, move the cursor all the way to the end
     * @param {Event} e - DOM event
     */
    moveCursor(e) {
        inputsUtil.moveCursor(e);
    },

    /**
     * Reset the input value to the model default value
     */
    resetAmount() {
        this.ui.$amountInput.val(this.model.defaults[this.options.attribute]);
    },

    /**
     * Format the amount properly, with two decimals, and removing leading zeros
     * unless it is the last zero before the decimal
     *
     * @param {string} value - original string
     * @returns {string}
     */
    formatAmount(value) {
        const nonFormatted = this.removeChars(value, /[^0-9]/g);
        const lastTwo = this.lastTwo(nonFormatted);
        const leadingNums = this.leadingNumbers(nonFormatted);

        if (!leadingNums) {
            return `0.${lastTwo}`;
        }
        const nonLeadingZero = this.replaceLeadingZeros(leadingNums);
        const formatted = this.addCommaEveryThird(nonLeadingZero);

        return `${formatted}.${lastTwo}`;
    },

    /**
     * Return the last two characters of the string
     *
     * @param {string} value - original string
     * @returns {string}
     */
    lastTwo(value) {
        return value.substring(value.length - 2);
    },

    /**
     * Return the string, without the last two characters
     *
     * @param {string} value - original string
     * @returns {string}
     */
    leadingNumbers(value) {
        return value.substring(0, value.length - 2);
    },

    /**
     * Replace a value in a string based on the provided regex
     *
     * @param {string} value - original string
     * @param {RegExp} regex
     * @param {string} replacement - desired replacement
     * @returns {string} - new string with replacements
     */
    replaceByRegex(value, regex, replacement) {
        return value.replace(regex, replacement);
    },

    /**
     * Remove characters based on the regex
     *
     * @param {string} value - original string
     * @param {RegExp} regex
     * @returns {string}
     */
    removeChars(value, regex) {
        return this.replaceByRegex(value, regex, '');
    },

    /**
     * Remove all leading zeros, except for the zero in front of a decimal
     * ie. 0.15
     *
     * @param {string} value - original string
     * @returns {string}
     */
    replaceLeadingZeros(value) {
        return this.removeChars(value, /^0*(?=0\.)|^0*(?=\d)/);
    },

    /**
     * Return the string with a comma inserted after every third character,
     * starting from the end
     *
     * @param {string} value - original string
     * @returns {string}
     */
    addCommaEveryThird(value) {
        return this.replaceByRegex(value, /\B(?=(?:\d{3})+(?!\d))/g, ',');
    },
});
