import integrationManager from 'app/integrationManager/integrationManager';
import util from '@glu/core/src/util';
import Glu from '@glu/core/src/glu';
import locale from '@glu/locale';
import errHandler from 'system/errHandler';
import BaseRouter from 'system/baseRouter';
import ELearning from 'app/elearning/routes';
import OTPSetup from 'app/administration/views/security/passcodeView';
import RemoteDepositCapture from 'mobile/remoteDepositCapture/routes';
import SecurityQuestions from 'system/login/views/security/questions';
import NewPassword from 'system/login/views/security/newPassword';
import InactiveChallenge from 'system/login/views/inactiveChallenge';
import questions from 'system/login/views/question/promises';
import FeatureList from 'system/webseries/models/featureList';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import mfaUtil from 'system/mfa/util';
import userInfo from 'etc/userInfo';
import DynamicPages from 'common/dynamicPages/routes';
import { updateRoutes } from 'common/util/reduxUtil';
import pcmAppRoutes from 'pcm/app/router';
import Reports from './reports/routes';
import Setup from './setup/routes';
import smbContact from './smbContact/routes';
import BeneficiaryAddressBook from './bab/routes';
import Dashboard from './workspace/routes';
import SMB from './smb/routes';
import Utilities from './utilities/routes';
import Loans from './loans/routes';
import Payments from './payments/routes';
import Transfers from './transfers/routes';
import Administration from './administration/routes';
import UserMaint from './administration/userRoutes';
import CheckManagement from './checkManagement/routes';
import BTR from './balanceAndTransaction/routes';
import Lockbox from './lockbox/routes';
import Bills from './smbBillManagement/routes';
import CashFlow from './cashflow/routes';
import Notifications from './notifications/routes';
import ChallengeManager from './challengeManager/routes';
import Deeplinks from './deeplinks/routes';
import PanelApproval from './panelApproval/routes';
import SmbPayments from './smbPayments/routes';
import ServiceRequest from './serviceRequest/routes';
import MultiAdd from './multiAdd/routes';
import ScheduledExports from './scheduledExports/routes';
import SMBAdmin from './smbAdmin/routes';
import Invoice from './smbInvoice/routes';
import Showcase from './showcase/routes';
import Templates from './templates/routes';
import AlertContentManagement from './alertContentManagement/routes';
// PCM related
// import Holidays from './administration/userRoutes';

const Alerts = Administration;
const dgbAppRoutes = util.extend(
    {
        'px/*path': 'loadPaymodeAndReroute',
    },
    Dashboard,
    Reports,
    Setup,
    BeneficiaryAddressBook,
    SMB,
    Administration,
    UserMaint,
    AlertContentManagement,
    DynamicPages,
    Showcase,
    Utilities,
    smbContact,
    Loans,
    SmbPayments,
    Payments,
    Transfers,
    Templates,
    CheckManagement,
    Alerts,
    // Holidays,
    BTR,
    Lockbox,
    Bills,
    Invoice,
    CashFlow,
    Notifications,
    ChallengeManager,
    Deeplinks,
    SMBAdmin,
    ELearning,
    RemoteDepositCapture,
    PanelApproval,
    ServiceRequest,
    MultiAdd,
    ScheduledExports,
);

export default BaseRouter.extend({
    appRoutes: util.extend(pcmAppRoutes, dgbAppRoutes),

    allRouteRegex: /.*[^logout]/i,

    constructor(...args) {
        const routes = this.buildRoutes();
        this.appRoutes = routes.appRoutes;
        /*
         * This list is used when attempted to click the same route again,
         * which doesn't actually change the route. We can still invoke
         * the route action on routes in this list.
         */
        this.allowRefresh = routes.allowRefresh;
        const [options] = args;
        this.controller = options.controller;
        this.lastRoute = options.controller.lastFragment;

        BaseRouter.prototype.constructor.apply(this, args);

        this.on('route', () => {
            if (window.lpTag !== undefined && typeof window.lpTag.newPage === 'function') {
                window.lpTag.newPage(window.location.href);
            }
            integrationManager.callEvent('routeChange', window.location.pathname);
            // Logout user if they are trying to escape RSA
            if (userInfo.get('challengedRequired') === true) {
                mfaUtil.logout();
            }
            updateRoutes(this.controller.lastFragment);
        });

        if (!userInfo.isLoggedIn()) {
            // after user information is loaded from the backend.
            userInfo.loginPromise.then(() => {
                this.evaluatePostAuthActions();
            });
        } else {
            this.evaluatePostAuthActions();
        }
    },

    /**
     * Get all of the possible actions and then if any actions need
     * to be taken, execute the first one
     */
    evaluatePostAuthActions() {
        this.postAuthActions = this.getPostAuthActions();
        if (this.postAuthActions.length) {
            this.executeFirstAction(this.postAuthActions);
        }
    },

    /**
     * When the array has functions in it, pass it along to override
     * all of the existing route actions
     * @param {Array} postAuthActions - array of functions
     */
    executeFirstAction(postAuthActions) {
        if (postAuthActions.length) {
            const [firstAction] = postAuthActions;
            this.overrideAllRoutes('postAuthAction', firstAction);
        } else {
            this.clearSecurityRoute();
            window.location.reload();
        }
    },

    /**
     * Remove the first item from postAuthActions and then pass it
     * along to be invoked
     */
    executeNextAction() {
        // Reassign this.postAuthActions by removing the first element if it exists
        if (this.postAuthActions.length) {
            [, ...this.postAuthActions] = this.postAuthActions;
        }
        this.executeFirstAction(this.postAuthActions);
    },

    /**
     * There are a variety of actions that a user might be forced to take based
     * on configurations. Check each configuration and add the appropriate function
     * to the array for execution. NOTE that order matters
     * @returns {Array} - array of functions
     */
    getPostAuthActions() {
        const forgotPasswordFeatureOn = FeatureList.get('isForgottenPasswordFeatureON');
        const hasSecurityQuestions = userInfo.get('hasUserEstablishedSecurityQuestions');
        const passwordExpired = userInfo.get('passwordExpired');
        const exceededInactivityLimit = userInfo.get('exceededInactivityLimit');

        // Order in the array matters as we will invoke the first item...well, first
        let actions = [];
        if (forgotPasswordFeatureOn && !hasSecurityQuestions) {
            actions = [...actions, this.setQuestions];
        }
        if (exceededInactivityLimit) {
            actions = [...actions, this.showChallengeQuestions];
        }
        if (passwordExpired) {
            actions = [...actions, this.setNewPassword];
        }
        if (!userInfo.isOTPSetup()) {
            actions = [...actions, this.setOTPContacts];
        }
        return actions;
    },

    /**
     * Iterate over all of the keys in the appRoutes object and build a
     * new appRoutes object with only route/action key/pair. Also parse
     * custom route objects and build allowRefresh function array, used
     * to identify if a route action can be triggered again if the route
     * hasn't changed.
     * @returns {Object}
     */
    buildRoutes() {
        return Object.keys(this.appRoutes).reduce((accum, value) => {
            const accumlator = accum;
            let action = '';
            if (this.appRoutes[value].action) {
                ({ action } = this.appRoutes[value]);
                // When there is an allowRefresh function, add it to the list
                if (this.appRoutes[value].allowRefresh) {
                    accumlator.allowRefresh = [
                        ...accumlator.allowRefresh,
                        this.appRoutes[value].allowRefresh,
                    ];
                }
            } else {
                action = this.appRoutes[value];
            }
            accumlator.appRoutes[value] = action;
            return accum;
        }, {
            appRoutes: {},
            allowRefresh: [],
        });
    },

    /**
     * Set the last route in the history to use the passed function as
     * the route action. Also use the regex to update all routes to use
     * the passed function as their route action. This prevents the user
     * from navigating away to another page to avoid the action.
     * @param {String} routeName - name of route
     * @param {Function} routeAction - function for route action
     */
    overrideAllRoutes(routeName, routeAction) {
        this.appRoute[this.lastRoute] = routeAction.call(this);
        this.route(this.allRouteRegex, routeName, routeAction);
    },

    setQuestions() {
        const self = this;

        Promise.all([
            questions.questionOne(),
            questions.questionTwo(),
            questions.questionThree(),
            questions.questionFour(),
            questions.questionFive(),
        ]).then((results) => {
            const view = new SecurityQuestions({
                collection: results,
                title: locale.get('common.securityTitle'),
                isEnrollmentPage: true,
                el: '#main',
            });
            view.render();
            view.on('submit', self.executeNextAction, self);
        }).then(null, errHandler);
    },

    showChallengeQuestions() {
        const view = new InactiveChallenge({
            el: '#main',
            userId: userInfo.get('id'),
            userGroup: userInfo.get('group'),
        });
        view.render();
        view.on('challenge:success', this.executeNextAction, this);
    },

    setNewPassword() {
        const view = new NewPassword({
            el: '#main',
        });
        view.render();
        view.on('expiredPassword:reset:success', this.executeNextAction, this);
    },

    setOTPContacts() {
        const view = new OTPSetup({
            el: '#main',
            initialSetup: true,
        });
        if (this.isLoaded()) {
            view.render();
        }
        view.on('otp:setup:success', this.executeNextAction, this);
    },

    isLoaded() {
        return (serverConfigParams.get('passcodeAllowEmail') !== undefined
            || serverConfigParams.get('passcodeAllowSMS') !== undefined
            || serverConfigParams.get('passcodeAllowVoice') !== undefined);
    },

    clearSecurityRoute() {
        const regex = this.allRouteRegex;
        Glu.history.handlers = util.reject(Glu.history.handlers, handle => handle.route === regex);
        Glu.history.loadUrl();
    },
});
