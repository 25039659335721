import CompositeView from '@glu/core/src/compositeView';
import constants from 'app/administration/constants';
import GroupView from './groupView';
import alertsTmpl from './alerts.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default CompositeView.extend({
    template: alertsTmpl,
    itemView: GroupView,
    itemViewContainer: '.item-view-container',

    ui: {
        $selectAll: '.select-all-alerts',
    },

    events: {
        'click @ui.$selectAll': 'selectAll',
    },

    initialize(opts) {
        const groupCollection = this.model.get('groups');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isSimpleEntitled()));
        }

        this.collection = groupCollection;

        this.itemViewOptions = {
            mode: opts.mode,
        };
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el?.find('.alert-group input[type="checkbox"]');
    },

    selectAll(evt) {
        const self = this;
        const { checked } = evt.currentTarget;
        this.collection.each((group) => {
            group.get('types').each((type) => {
                const entitlement = type.get('entitlements').at(0);

                entitlement.set({
                    'actions.view.entitled': checked,
                    'actions.manage.entitled': checked,
                });
                self.$(`#alert-entitlement-${entitlement.cid}`).prop('checked', checked);
            });
        });
        this.$el.find('.select-all-perms').prop('checked', checked);
        this.onChildrenChange();
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === constants.MODES.VIEW,
        };
    },
});
