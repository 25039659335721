import CONSTANTS from 'common/dynamicPages/api/constants';
import BaseGridView from 'common/dynamicPages/views/grid';
import MobileGridView from 'common/dynamicPages/views/gridMobileInterface';
import mobileUtil from 'mobile/util/mobileUtil';
import LvcConstants from 'common/util/listViewConfig/listViewConfigConstants';

export default {
    createInquiryGridView(optionsParam) {
        const options = optionsParam;
        options.isInquiry = true;
        let rowsPerPage = CONSTANTS.GRID_ROWS_PER_PAGE;
        if (options.lvc && options.lvc.get(LvcConstants.ROWSPERPAGE)) {
            rowsPerPage = options.lvc.get(LvcConstants.ROWSPERPAGE);
        }
        options.rowsPerPage = rowsPerPage;
        let View = BaseGridView;
        if (mobileUtil.isMobileGridEnabled()) {
            View = MobileGridView;
        }
        return new View(options);
    },

    createServiceGridView(optionsParam) {
        const options = optionsParam;
        options.isService = true;
        if (!options.gridOptions || !options.gridOptions.caption) {
            options.gridOptions = { caption: options?.context?.name };
        }
        let View = BaseGridView;
        if (mobileUtil.isMobileGridEnabled()) {
            View = MobileGridView;
        }
        return new View(options);
    },

    createLookupGridView(optionsParam) {
        const options = optionsParam;
        options.isLookup = true;
        return new BaseGridView(options);
    },

    createComponentGridView(optionsParam) {
        const options = optionsParam;
        options.isComponent = true;
        options.enableRowContextButtonCallbacks = true;
        options.paginate = false;
        options.filter = false;
        options.sortable = false;
        options.disableAuxControl = true;
        options.disableDeleteConfirm = true;
        if (options.gridViewOptions) {
            Object.keys(options.gridViewOptions || {}).forEach((property) => {
                options[property] = options.gridViewOptions[property];
            });
        }
        return new BaseGridView(options);
    },
};
