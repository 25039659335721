var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div>\n                                <h3 class=\"panel-title\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"filterTitle") || (depth0 != null ? lookupProperty(depth0,"filterTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"filterTitle","hash":{},"data":data,"loc":{"start":{"line":16,"column":56},"end":{"line":16,"column":71}}}) : helper)))
    + "</h3>\n                            </div>\n                            <div class=\"row\">\n                                <div data-hook=\"infoRegion\" class=\"col-md-12\"></div>\n                            </div>\n                            <div data-region=\"filterRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSave") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":29},"end":{"line":30,"column":35}}})) != null ? stack1 : "")
    + "                            <div class=\"row\">\n                                <button type=\"button\" class=\"btn btn-primary\" data-action=\"run\" aria-label=\"Run\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.run",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":113},"end":{"line":32,"column":136}}}))
    + "</button>\n                                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":100},"end":{"line":33,"column":126}}}))
    + "</button>\n                            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"form-group\">\n                                    <div class=\"checkbox\">\n                                        <input id=\"save-report\" type=\"checkbox\" value=\"\">\n                                        <label for=\"save-report\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.savethisreport",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":65},"end":{"line":26,"column":99}}}))
    + "</label>\n                                    </div>\n                                    <div class=\"col-md-4\" data-region=\"saveProfileConfigRegion\"></div>\n                                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"row\">\n                                    <button type=\"button\" class=\"btn btn-primary\" data-action=\"cancel\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":103},"end":{"line":37,"column":129}}}))
    + "</button>\n                                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"corp-reports-page\">\n    <div class=\"dashboard-layout\">\n        <div class=\"dashboard-inner\">\n            <div class=\"dashboard-region dashboard-region-large\">\n                <div class=\"widget\">\n                    <div class=\"widget-container\">\n                        <div class=\"widget-header\">\n                            <h3>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"DISPLAYNAME") || (depth0 != null ? lookupProperty(depth0,"DISPLAYNAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DISPLAYNAME","hash":{},"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":47}}}) : helper)))
    + "</h3>\n                        </div>\n                        <div class=\"widget-body\">\n                            <div class=\"row\">\n                                <div data-hook=\"alertRegion\" class=\"col-md-12\"></div>\n                            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFilters") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":39,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                        <div data-region=\"content\" class=\"report\" aria-live=\"polite\"></div>\n                    </div>\n                    <div>\n                        <div data-region=\"export\" aria-live=\"polite\"></div>\n                    </div>\n                </div>\n                <div class=\"widget-footer\"></div>\n            </div>\n        </div>\n    </div>\n</section>\n";
},"useData":true});