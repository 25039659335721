import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@glu/form-components';
import { createUseStyles } from '@glu/theming';
import useEditAwareCellRenderer from './useEditAwareCellRenderer';

const useStyles = createUseStyles({
  root: {
    lineHeight: 1,
    padding: [6, 0],
    whiteSpace: 'normal'
  }
});

const TextInputCellRenderer = ({
  value: cellValue, node, data, colDef, api, eGridCell, ...rest
}) => {
  const classes = useStyles({});
  const {
    rowId, field, labelText, editedValue, setEditedValue, errors
  } = useEditAwareCellRenderer({
    cellValue, node, data, colDef, api, eGridCell, ...rest
  });
  const name = `${rowId}-${field}-input`;

  const onChange = useCallback(({ target: { value: rawInput } }) => {
    setEditedValue(rawInput, false);
  }, [setEditedValue]);

  return (
    <div className={classes.root}>
      <Input
        name={name}
        htmlId={name}
        labelText={labelText}
        value={editedValue}
        error={errors && errors.length ? errors[0] : undefined}
        screenReaderLabel
        onChange={onChange}
        onBlur={() => setEditedValue(editedValue, true)}
      />
    </div>
  );
};


TextInputCellRenderer.propTypes = {
  /** Ag grid node, basically the current row */
  node: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,

  /** Ag grid data hash for the current row */
  data: PropTypes.shape({}).isRequired,

  /** Ag grid colDef for the current column */
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired
  }).isRequired,

  /** Ag grid's value for the this row/column */
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types

  /** Ag grid grid api */
  api: PropTypes.shape({}).isRequired,

  /** Ag grid html cell container that this component renders into */
  eGridCell: PropTypes.instanceOf(Element).isRequired
};

TextInputCellRenderer.defaultProps = {
  value: undefined
};

export default TextInputCellRenderer;
