import util from '@glu/core/src/util';
import AdminMetaModel from 'app/administration/common/dynamicPages/models/meta';
import DataApi from 'common/dynamicPages/api/data';

const adminModel = util.extend({}, DataApi.model, {
    generateFromKeyList(options) {
        const model = new AdminMetaModel({}, {
            context: options.context,
        });
        model.key = {};
        util.each(options.keyList, (key) => {
            model.key[key] = 'true';
        });
        return model;
    },

    generateFromPreload(options) {
        const modelData = this.getModelInfo(options);
        const model = new AdminMetaModel({}, {
            context: options.context,
            jsonData: modelData,
        });
        model.setupFromData();
        return model;
    },
});

const AdminData = {
    model: adminModel,
};

export default AdminData;
