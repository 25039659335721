import TransactionListView from 'app/balanceAndTransaction/views/transactionListView';
import constants from 'app/balanceAndTransaction/constants';
import template from './tieredLoanTransactionDetail.hbs';

export default TransactionListView.extend({
    template,
    accountType: 'LOAN',

    initialize(optionsParam) {
        const options = optionsParam;
        options.paginate = true;
        options.tieredLoanDetails = true;
        let tabPrefix = '';
        const tabId = this.model.get('tabId');
        if (tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR) {
            tabPrefix = 'previousDay/';
        } else if (tabId === constants.ACCOUNT_SUMMARY.TABS.CURRENT) {
            tabPrefix = 'currentDay/';
        }
        this.serviceName = `${constants.LOAN_ACCTS_SERVICE_PREFIX + tabPrefix}getTransactions`;
        this.options.additionalSearchFields = [{
            fieldName: 'AccountFilter',
            fieldValue: [this.model.get('accountFilter')],
            dataType: 'text',
            operator: '=',
        }, {
            fieldName: 'NOTENUMBER',
            fieldValue: [this.model.get('noteNumber')],
            dataType: 'text',
            operator: '=',
        }, {
            fieldName: 'SUBACCOUNT_TYPE',
            fieldValue: ['NOTE', 'LOC'],
            dataType: 'text',
            operator: 'IN',
        }];
        TransactionListView.prototype.initialize.call(this, options);
    },

    getContext() {
        return {
            productCode: 'GIR',
            functionCode: 'INST',
            typeCode: 'LOANTRAN',
            serviceName: this.serviceName,
        };
    },

    templateHelpers() {
        const self = this;
        return {
            getNoteNumber() {
                return self.model.get('noteNumber');
            },
        };
    },
});
