import React from 'react';
import PropTypes from 'prop-types';
import { Button, TERTIARY, EXTRA_SMALL } from '@glu/buttons-react';
import { withStyles } from '@glu/theming';
import styles from './ToggleAllControl.styles';

const propTypes = {
    onToggleAll: PropTypes.func.isRequired,
    toggleControl: PropTypes.func.isRequired,
    positiveLabel: PropTypes.string.isRequired,
    negativeLabel: PropTypes.string.isRequired,
    helperText: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    helperText: undefined,
};

const ToggleAllControl = ({
    onToggleAll,
    toggleControl,
    positiveLabel,
    negativeLabel,
    helperText,
    classes,
}) => {
    const onToggle = (value) => {
        toggleControl(false);
        onToggleAll(value);
    };
    return (
        <div className={classes.wrapper}>
            {helperText
                && <p className={classes.helpText}>{helperText}</p>}
            <Button
                className={classes.button}
                variant={TERTIARY}
                text={positiveLabel}
                size={EXTRA_SMALL}
                onClick={() => onToggle(true)}
            />
            <Button
                className={classes.button}
                variant={TERTIARY}
                size={EXTRA_SMALL}
                text={negativeLabel}
                onClick={() => onToggle(false)}
            />
        </div>
    );
};
ToggleAllControl.propTypes = propTypes;
ToggleAllControl.defaultProps = defaultProps;
export default withStyles(styles)(ToggleAllControl);
