var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\"\n                    class=\"Panel--close btn btn-tertiary\"\n                    data-hook=\"getDeleteButton\">\n                <span class=\"remove icon-close\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Remove.Sequence",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":100}}}))
    + "\"></span>\n                <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Remove.Sequence",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":38},"end":{"line":18,"column":83}}}))
    + "</span>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-2 required\">\n        <div data-region=\"sequenceTypeRegion\"></div>\n        <span data-bind=\"model\"\n              data-validate=\"sequence\"\n              class=\"field-info help-block pull-left\"></span>\n    </div>\n    <div class=\"col-6 PanelApproval-list\">\n        <div data-region=\"approversRegion\"></div>\n    </div>\n\n    <div class=\"col-4\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDelete") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n<button type=\"button\"\n        class=\"btn btn-tertiary PanelApproval-list-item--add btn-sm\"\n        data-hook=\"getAddApproverButton\">\n    "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Add.Approver",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":26,"column":46}}}))
    + "\n</button>\n\n\n";
},"useData":true});