var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<label for=\"new-column-sum-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":33}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.new.sum",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":51},"end":{"line":2,"column":80}}}))
    + "</label>\n<input id=\"new-column-sum-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":32}}}) : helper)))
    + "\" step=\"1\" min=\"0\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sum") || (depth0 != null ? lookupProperty(depth0,"sum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sum","hash":{},"data":data,"loc":{"start":{"line":3,"column":58},"end":{"line":3,"column":65}}}) : helper)))
    + "\" class=\"form-control\" data-number-accelerate type=\"text\">\n\n<div class=\"control-bubble\">\n        <p>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.distribute.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":11},"end":{"line":6,"column":49}}}))
    + "</p>\n        <button class=\"btn btn-primary btn-xs\" data-distribution=\"even\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.evenly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":72},"end":{"line":7,"column":100}}}))
    + "</button>\n        <button class=\"btn btn-primary btn-xs\" data-distribution=\"proportional\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.by.allocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":80},"end":{"line":8,"column":115}}}))
    + "</button>\n</div>\n\n";
},"useData":true});