import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import AccountsCollectionView from './assignedAccounts';
import accountsByPaymentGroupTmpl from './accountsByPaymentGroup.hbs';

export default Layout.extend({
    template: accountsByPaymentGroupTmpl,

    ui: {
        $paymentGroupBtn: '.btn-link-group .btn-link',
    },

    initialize(options) {
        this.mode = options.mode;
        this.actionEntitlements = this.model.getEntitlementsByFunctionCode(['INST', 'BATCH', 'TMPL', 'BHTMPL']);

        /*
         * Get the first item where the prodcut matches the model used for the group, except
         * for transfers and EFT.
         * Transfers are an exception because the from and to accounts were split.  Only the
         * from accounts are used for limits.
         * EFT is another exception because only EFT ACHCompany are used for limits.
         */
        this.dataEntitlements = util.find(
            options.mainModel.assignAccountsDataEntitlements,
            (dataEntitlement) => {
                const productCode = this.model.id.toUpperCase();
                const isTransferFrom = productCode === constants.PAYMENT_GROUPS.TRANSFERS
                        && dataEntitlement.productCode === constants.PAYMENT_GROUPS.TRANSFERS
                        && dataEntitlement.dataEntAttr === 'BankAccount';
                const isEFTOriginator = productCode === constants.PAYMENT_GROUPS.EFT
                        && dataEntitlement.productCode === constants.PAYMENT_GROUPS.EFT
                        && dataEntitlement.dataEntAttr === 'ACHCompany';
                return (productCode !== constants.PAYMENT_GROUPS.TRANSFERS
                            && productCode !== constants.PAYMENT_GROUPS.EFT
                        && dataEntitlement.productCode === productCode) || isTransferFrom
                        || isEFTOriginator;
            },
        );
    },

    onRender() {
        this.assignedAccountsView = new AccountsCollectionView({
            mode: this.mode,
            modifyMode: this.options.modifyMode,
            model: this.model,
            limitsModel: this.options.limitsModel,
            mainModel: this.options.mainModel,
            paymentTypeGroup: this.model.id,

            entitlements: {
                actionEntitlements: this.actionEntitlements,
                dataEntitlements: this.dataEntitlements,
            },
            isCannotGrantBeyondOwnUser: this.options.isCannotGrantBeyondOwnUser,
        });

        this.assignedAccountsRegion.show(this.assignedAccountsView);
    },

    /**
     * Validates the limits of a CGBO by group user against
     * the logged in user.
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateLimits() {
        // No need to validate if view is unavailable.
        if (util.isNullOrUndefined(this.assignedAccountsView)) {
            return true;
        }
        const invalidLimits = this.assignedAccountsView
            .getDataEntitlmentLimits()
        // Need to filter so all models are validated.
            .filter((dataEntitlementLimit) => {
                const isValid = dataEntitlementLimit.isValid();
                return !isValid;
            });
        return util.isEmpty(invalidLimits);
    },

    beforeSave() {
        return this.assignedAccountsView.beforeSave();
    },
});
