import './themeDefaults';
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './Table.styles.js';

const Table = ({
  className,
  columnHeaders,
  children,
  ...rest
}) => {
  const classes = useStyles({ rest });
  return (
    <div className={className}>
      <table className={classes.root} cellPadding="0" cellSpacing="0">
        {columnHeaders.length > 0
          && (
          <thead>
            <tr>
              {columnHeaders.map(name => (<td key={name}>{name}</td>))}
            </tr>
          </thead>
          )
        }
        {children}
      </table>
    </div>
  );
};

Table.propTypes = {
  /** Class passed to parent element for custom styling */
  className: PropTypes.string,

  /** Titles for head of columns */
  columnHeaders: PropTypes.arrayOf(PropTypes.string),

  children: PropTypes.element.isRequired
};

Table.defaultProps = {
  className: '',
  columnHeaders: []
};
export default Table;
