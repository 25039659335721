import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';

const TokenAuditList = ListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'TKNMGMT',
            serviceName: 'tokenManagement/tokensAudit',
            serviceFunc: null,
            businessType: null,
            context: 'TKNMGMTAUDIT',
            selector: 'none',
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },
});

let list = TokenAuditList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
