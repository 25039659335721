import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import $ from 'jquery';
import c3 from 'c3';
import d3 from 'd3';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import compareGraphTmpl from './compareGraph.hbs';

const CompareGraph = ItemView.extend({
    initialize(options) {
        this.selectControls = options.selectControls;
        this.listenTo(
            this.collection,
            {
                compareDataReady: this.updateGraph,
            },
        );
    },

    template: compareGraphTmpl,

    onShow() {
        this.drawGraph();
    },

    getGraphData() {
        const data = [];
        const chartData = [];

        util.each(this.selectControls, (view) => {
            if (view.model) {
                data.push({
                    name: view.name,
                    offset: view.getFiscalYearOffset(),
                    compareArray: view.model.get('compareArray'),
                });
            }
        });

        const chartDataLoopFunc = (i, monthParam) => {
            const month = monthParam;
            util.each(data, (plan) => {
                const m = i + plan.offset;

                month[`${plan.name} Inflow`] = plan.compareArray[m] ? plan.compareArray[m].inflow : 0;
                month[`${plan.name} Outflow`] = plan.compareArray[m] ? plan.compareArray[m].outflow : 0;
            });
        };

        if (data.length) {
            for (let i = 0; i < 12; i += 1) {
                const month = {};
                month.month = moment(new Date()).month(data[0].compareArray[i].month - 1).format('MMM');

                chartDataLoopFunc(i, month);

                chartData.push(month);
            }
        }

        return chartData;
    },

    getGraphKeys() {
        const keys = [];
        util.each(this.selectControls, (view) => {
            if (view.model) {
                keys.push(`${view.name} Inflow`);
                keys.push(`${view.name} Outflow`);
            }
        });

        return keys;
    },

    getGraphColors() {
        // pull in colors from CSS so we can brand it later
        const colors = {};
        const $test = $('<div></div>').appendTo('body').hide();

        util.each(this.selectControls, (view, i) => {
            if (view.model) {
                const inflowColor = $test.addClass(`compare-graph-color-${i} inflow`).css('background-color');
                $test.removeClass();
                const outflowColor = $test.addClass(`compare-graph-color-${i} outflow`).css('background-color');
                $test.removeClass();

                colors[`${view.name} Inflow`] = inflowColor;
                colors[`${view.name} Outflow`] = outflowColor;
            }
        });

        $test.remove();
        return colors;
    },

    getGraphClasses() {
        /*
         * TODO
         * colors will apply the same background color per-plan
         * but inflow should be solid, outflow should have a pattern overlay
         * http://stackoverflow.com/questions/3796025/fill-svg-path-element-with-a-background-image
         */
        const classes = {};
        util.each(this.selectControls, (view) => {
            if (view.model) {
                classes[`${view.name} Inflow`] = 'inflow';
                classes[`${view.name} Outflow`] = 'outflow';
            }
        });

        return classes;
    },

    updateGraph() {
        this.graph.load({
            unload: true,
            json: this.getGraphData(),
            classes: this.getGraphClasses(),

            keys: {
                x: 'month',
                value: this.getGraphKeys(),
            },

            colors: this.getGraphColors(),
        });
    },

    drawGraph() {
        this.graph = c3.generate({
            bindto: this.$('div').get(0),

            axis: {
                x: {
                    type: 'category',
                    show: true,
                },

                y: {
                    tick: {
                        format: d3.format('s'),
                    },
                },
            },

            legend: {
                show: true,
            },

            data: {
                type: 'bar',
                json: [],
            },

            tooltip: {
                format: {
                    value: d3.format(`${userInfo.getCurrencySymbol()},.2f`),
                },

                position(data, width, height, element) {
                    const svgLeft = this.getSvgLeft(true);
                    let tooltipLeft = svgLeft + this.getCurrentPaddingLeft(true)
                        + this.x(data[0].x) + 20;
                    const chartRight = (svgLeft + this.currentWidth)
                        - this.getCurrentPaddingRight();
                    let tooltipTop = this.d3.mouse(element)[1] + 15;

                    if (tooltipLeft + width > chartRight) {
                        tooltipLeft = (this.x(data[0].x) - width) + 40;
                    }

                    if (tooltipTop + height > this.currentHeight) {
                        tooltipTop -= height + 30;
                    }

                    if (tooltipTop < 0) {
                        tooltipTop = 0;
                    }

                    return {
                        top: tooltipTop,
                        left: tooltipLeft,
                    };
                },
            },
        });

        this.updateGraph();
    },
});

export default CompareGraph;
