export default ({ palette }) => ({
    maskedInput: {
        color: palette.text.dark,
        height: '32px',
        maxWidth: 'none',
        lineHeight: '1.17 !important',
        display: 'block',
        padding: '6px 12px',
        fontSize: '14px',
        backgroundColor: palette.background,
        backgroundImage: 'none',
        border: `1px solid ${palette.form.border}`,
        borderRadius: '2px',
        '&:read-only': {
            backgroundColor: '#eee',
            cursor: 'not-allowed',
        },
    },

    required: {
        '&::before': {
            content: '"* "',
            color: palette.form.error,
        },
        marginBottom: '0',
    },
    helpBlock: {
        color: palette.text.light,
        margin: '0',
        '&:empty': {
            display: 'none',
        },
    },
    readOnly: {
        display: 'block',
    },

});
