var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alerts\" data-region=\"alertRegion\"></div>\n<!-- General Export Type Section -->\n<div class=\"row\" data-region=\"exportGeneralInfoRegion\">\n    <div class=\"col-md-6\">\n        <div class=\"field-container\">\n            <label class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"export.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":60}}}))
    + "</label>\n            <input type=\"hidden\" class=\"form-control\" data-hook=\"getExportTypeCode\" name=\"EXPTYPECODE\">\n            <div data-region=\"exportTypeOptionsRegion\"></div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"EXPTYPECODE\"></span>\n        </div>\n    </div>\n    <div class=\"col-md-6\">\n        <div class=\"field-container\" data-hook=\"getExportFileName\">\n            <label class=\"required\" for=\"exportFileName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"export.filename",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":57},"end":{"line":14,"column":85}}}))
    + "</label>\n            <input class=\"form-control\" type=\"text\" size=\"80\" maxlength=\"255\" name=\"EXPTFILENAME\" id=\"exportFileName\" value=\"\" data-bind=\"model\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"EXPTFILENAME\"></span>\n        </div>\n    </div>\n</div>\n<!-- Region to load Type Specific Filters -->\n<div data-region=\"typeBasedFilterRegion\"></div>\n    <!-- General Conditional Date Field -->\n<div class=\"row\">\n    <div class=\"col-md-6\">\n        <div class=\"field-container hide required\" data-hook=\"getExportConditionalField\">\n            <label for=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":31}}}) : helper)))
    + "_conditionalDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":49},"end":{"line":26,"column":70}}}))
    + "</label>\n            <div class=\"form-column\">\n                <div class=\"search-input-wrapper\">\n                    <label for=\"field-value\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.searchValue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":61},"end":{"line":29,"column":92}}}))
    + "</label>\n                    <input id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":30,"column":31},"end":{"line":30,"column":38}}}) : helper)))
    + "_conditionalDate\"\n                        class=\"form-control input-date\"\n                        type=\"text\"\n                        data-bind=\"model\"\n                        name=\"DATE\"\n                        value=\"\"\n                        aria-required=\"true\" \n                                        required >\n                    </input>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DATE\"></span>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n<!-- Region Used to load in dynamic Filters View (from mdf) -->\n<div class=\"panel panel-tertiary\" data-region=\"optionalFieldsRegion\">\n    <div class=\"panel-heading\" role=\"tab\">\n        <h3 class=\"panel-title\">\n            <a role=\"button\"\n                data-toggle=\"collapse\"\n                href=\"#exportOptionalFields\"\n                aria-expanded=\"false\"\n                aria-controls=\"exportOptionalFields\"\n                class=\"collapsed\">\n                <span class=\"indicator-icon\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.optionalFields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":53},"end":{"line":55,"column":87}}}))
    + "\n            </a>\n        </h3>\n    </div>\n    <div id=\"exportOptionalFields\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"exportOptionalFieldsHeading\">\n        <div class=\"panel-body\">\n            <div data-region=\"filterRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});