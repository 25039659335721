import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { Loading } from '@glu/indicators-react';
import styles from './RtpAddToConversationMessageButtons.styles';
import useLocale from '../../hooks/useLocale';

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const AddToConversationMessageButtons = ({
    isLoading, handleCancel, handleSend, classes,
}) => {
    const { getLocaleString } = useLocale();

    return (
        <div className={classes.msgButtons}>
            <Loading isLoading={isLoading} className={classes.spinner}>
                <button onClick={handleSend}>{getLocaleString('rtp.conversationDrawer.send')}</button>
            </Loading>
            <button onClick={handleCancel}>{getLocaleString('rtp.conversationDrawer.cancel')}</button>
        </div>
    );
};

AddToConversationMessageButtons.propTypes = propTypes;
export default withStyles(styles)(AddToConversationMessageButtons);
