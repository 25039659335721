import ItemView from '@glu/core/src/itemView';
import constants from 'app/administration/constants';
import util from '@glu/core/src/util';
import limitFieldTmpl from './limitField.hbs';

export default ItemView.extend({
    template: limitFieldTmpl,

    events: {
        'change input': 'updateModel',
    },

    initialize() {
        const self = this;

        this.mode = this.options.column.get('mode');

        this.listenTo(this.model, 'approvalRightsChanged:account', function () {
            const fieldName = this.options.column.get('field');
            const hasApproval = this.hasApproval();
            if (!hasApproval) {
                this.model.set(fieldName, '');
            }
            this.$('input').prop('disabled', hasApproval && self.mode !== constants.MODES.VIEW ? '' : 'disabled');
        });
    },

    onRender() {
        this.$('input').inputmask({
            mask: '9[9999999999999]',
            placeholder: '',
        });
    },

    updateModel() {
        const fieldName = this.options.column.get('field');
        const limitName = fieldName.replace('AssignedLimit', '');
        let limitObj;

        this.model.set(
            fieldName,
            this.$('input').val(),
            {
                silent: true,
            },
        );

        // Also update the limitObject
        limitObj = this.model.get(limitName);

        if (!limitObj) {
            limitObj = {
                applicable: true,
            };
        }

        limitObj.assignedLimit = this.$('input').val();

        this.model.set(
            limitName,
            limitObj,
            {
                silent: true,
            },
        );
    },

    templateHelpers() {
        const self = this;
        const readOnly = self.mode === constants.MODES.VIEW;

        return {
            value() {
                const obj = self.model.get(self.options.column.get('field').replace('AssignedLimit', ''));
                const value = obj ? obj.assignedLimit : '';
                return util.escape(value || '');
            },

            disableCheck() {
                return !self.hasApproval() || readOnly;
            },
        };
    },

    hasApproval() {
        return this.model.get('approve.entitled');
    },
});
