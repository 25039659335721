import { Layout } from '@glu/core';
import FormBehavior from 'ptx/system/behaviors/form';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import StickyBar from 'ptx/system/behaviors/stickyBar';
import { isDeepLinked, postMessagetoTX } from 'common/util/deeplinkUtil';
import '@glu/core/src/core/internal/masking';
import tmpl from './top.hbs';
import TraysGrid from './components/grid/grid';
import PrinterDetails from './components/printerDetails';
import Printer from '../../models/printer';


export default Layout.extend({
    template: tmpl,

    behaviors: {
        Form: {
            behaviorClass: FormBehavior,
            confirm: true,
        },
        StickyBar: {
            behaviorClass: StickyBar,
            isTop: false,
        },
    },

    regions: {
        traysGrid: '#region-tray-configuration-grid',
        alert: '#region-alert',
        printerDetails: '#region-printer-details',
    },

    ui: {
        $numericInput: '.input-numeric',
    },

    initialize() {
        this.model = new Printer({
            id: this.options.printerId,
        });

        this.model.fetch();
    },

    onRender() {
        this.showTraysGrid();
        this.showPrinterDetails();
        this.ui.$numericInput.maskNumeric();
    },

    onBeforeClose() {
        this.ui.$numericInput.unmask();
    },

    showTraysGrid() {
        this.traysGrid.show(new TraysGrid({
            model: this.model,
        }));
    },

    showPrinterDetails() {
        this.printerDetails.show(new PrinterDetails({
            model: this.model,
        }));
    },

    save(e) {
        if (e) {
            e.preventDefault();
        }
        this.closeAlert();

        this.model.save(null, {
            success: () => {
                if (this.isClosed) {
                    // eslint-disable-next-line no-useless-return
                    return;
                }
                this.showAlert();
            },
        });
    },

    showAlert() {
        const message = alert.success(locale.get('printerUpdatedMessage', this.model.get('name')));


        if (isDeepLinked()) {
            postMessagetoTX(locale.get('printerUpdatedMessage', this.model.get('name')), true);
        } else {
            this.alert.show(message);
        }
    },

    closeAlert() {
        this.alert.close();
    },
});
