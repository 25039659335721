import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import $ from 'jquery';

/**
 * filterAuthLevel based on Users Checkfree Auth Level
 * @param {object} authLevel - authLevel of the user.
 * @param {object} comboData - the unfiltered options list.
 * return {object} options - Filtered list of options
 */
const filterAuthLevel = function (authLevel, comboData) {
    let newOptionsStr = '';

    comboData.forEach((option) => {
        if (authLevel === 'Level1' && option.value === 'Level1') {
            return;
        } else if (authLevel === 'Level2' && (option.value === 'Level1' || option.value === 'Level2')) {
            return;
        }
        newOptionsStr += `<option value="${util.escape(option.value)}" data-item="">${util.escape(option.label)}</option>`;
    });
    return newOptionsStr;
};

export default function (form, initialState) {
    const formState = form.formView.state;
    const authLevel = userInfo.getCheckfreeAuthLevel();

    if (initialState) {
        // hide the fields not needed(not defined in visiblefields but they still showing)
        if (formState === 'insert') {
            form.field('BILLPAYENROLLMENTUSERID').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            form.field('STATUS').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            form.field('ADDEDDATE').shouldBeVisibleWhen(false)
                .shouldBeRequiredWhen(false);
            const authLevelCombo = form.field('AUTHORIZATIONLEVEL').$el;
            authLevelCombo.html(filterAuthLevel(authLevel, authLevelCombo.context.options));
        } else if (formState === 'view' && authLevel === 'Level3') {
            $('[data-hook="footerElements"]').parent().hide();
        }
    }
}

export { filterAuthLevel };
