import CollectionView from '@glu/core/src/collectionView';
import EntitlementView from './entitlementView';

export default CollectionView.extend({
    tagName: 'div',
    className: 'limit-set-list',
    itemView: EntitlementView,

    initialize(options) {
        this.itemViewOptions = {
            mode: options.mode,
            hasPanelApproval: options.hasPanelApproval,
        };
    },
});
