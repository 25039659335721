import $ from 'jquery';
import ItemView from '@glu/core/src/itemView';
import tileTmpl from './tile.hbs';

export default ItemView.extend({
    template: tileTmpl,

    initialize(optionsParam) {
        const options = optionsParam;
        $(window).resize(this.handleResize);
        // TODO: Why this assignment to options?
        options.model = this.model;
    },

    ui: {
        $navbar: 'nav.glu-menu',
    },

    attributes: {
        'data-qa': 'tile-container',
    },

    events: {
        'blur @ui.$all': 'handleBlur',
    },

    templateHelpers() {
        return this.model.getTrajectories();
    },

    hasLoadedRequiredData() {
        return (this.hasLoaded ? this.hasLoaded : false);
    },

    setHasLoadedRequiredData(bool) {
        this.hasLoaded = bool;
    },
});
