var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"text-danger\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "s";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"DUE_DATE") || (depth0 != null ? lookupProperty(depth0,"DUE_DATE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DUE_DATE","hash":{},"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":17}}}) : helper)))
    + "</div>\n<div>(<span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNegative") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":58}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"difference") || (depth0 != null ? lookupProperty(depth0,"difference") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"difference","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":73}}}) : helper)))
    + " Day"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingular") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":77},"end":{"line":2,"column":111}}})) != null ? stack1 : "")
    + "</span>)</div>\n";
},"useData":true});