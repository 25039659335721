import Marionette from 'backbone.marionette';
import viewMixins from '@glu/core/src/core/internal/viewMixins';
import { appBus } from '@glu/core';

const Mixin = viewMixins(Marionette.Layout);

const backboneNavigateTo = (route) => {
    appBus.trigger('router:navigate:before');
    Mixin.navigateTo(route);
};

const useNavigation = (options = {}) => {
    const { returnRoute } = options;

    function cancel() {
        if (!returnRoute) {
            throw (new Error('Return route was not passed as an option to the useNavigation hook'));
        }
        backboneNavigateTo(returnRoute);
    }

    function navigateTo(route) {
        backboneNavigateTo(route);
    }
    return {
        cancel,
        navigateTo,
    };
};

export default useNavigation;
