import ItemView from '@glu/core/src/itemView';
import CollectionView from '@glu/core/src/collectionView';
import newPaymentTypeTmpl from './newPaymentType.hbs';

const NewPaymentType = ItemView.extend({
    template: newPaymentTypeTmpl,
    binding: true,
});

const listNewPaymentType = CollectionView.extend({
    itemView: NewPaymentType,

    getChildView() {
        return NewPaymentType;
    },
});

export default listNewPaymentType;
