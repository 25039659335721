import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Model.extend({

    defaults: {
        typeCode: '*',
        accountName: '',
        accountNumber: '',
        offsetAccountNum: '',
        clearingSystem: '',
        bankCode: '',
        dataEntFilterAttribute: '',
        tranLimit: '',
        dailyLimit: '',
        manageAction: '',
        approveAction: '',
        repairAction: '',
        modifyAction: '',
    },

    set(...args) {
        /*
         * Unless silent is present, just add silent when a change is made to the limits
         * or checkbox input fields.  In most cases, when a change is made to them
         * 3 arguments are passed: field, value, and options.
         */
        if (args.length > 2 && !util.has(args[2], 'silent')) {
            util.extend(args[2], {
                silent: true,
            });
        }

        /*
         * Need to return this so the backbone impl does not return false and fails
         * downstream.
         */
        return Model.prototype.set.apply(this, args);
    },

    sync(method, model, options) {
        http.post(services.generateUrl('/users/userCentric/v2/updateInterimDataEntitlementLimits'), {
            limits: [this.toJSON()],
            group: options.byGroup,
        }, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});
