import $ from 'jquery';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import ViewPayment from 'app/payments/views/viewPayment';
import constants from 'common/dynamicPages/api/constants';
import RefreshSummaryModel from 'app/payments/models/refreshSummaryModel';
import PrintChecksModalView from './printChecks';


export default ViewPayment.extend({
    initialize(opts) {
        const options = {
            menuCategory: 'PMTS',
            serviceName: 'payments/corp',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            mode: 'view',
        };

        ViewPayment.prototype.initialize.call(this, options, opts);
        this.entitlements = (this.tempModel && this.tempModel.get('entitlements')) || {};
        if (this.tempModel) {
            this.tempModel.unset('entitlements');
        }
        if (this.addPrintBatchButtonToDetailPage()) {
            this.buttonList.splice(
                0,
                1,
                {
                    action: 'PRINTBATCH',
                },
            );
        }

        if (this.addReprintBatchButtonToDetailPage()) {
            this.buttonList.splice(
                0,
                1,
                {
                    action: 'REPRINTBATCH',
                },
                {
                    action: 'REPRINTSELECTED',
                },
            );
        }
        if (this.useBackInsteadOfCancel()) {
            const indexOfCancel = this.getCancelIndex();
            if (indexOfCancel > -1) {
                this.buttonList.splice(indexOfCancel, 1);
            }
            this.buttonList.push({ action: 'BACK' });
        }

        // go ahead and set the refreshSummaryModel
        this.refreshSummaryModel = new RefreshSummaryModel();
        //   this.storedModel.unset('entitlements');
        // when the alert view is shown from the list view, update the summary section
        this.listenTo(this.appBus, 'listview:alert:show', (alertData) => {
            this.refreshSummaryModel.fetchNewSummary(alertData);
        });
    },

    handleActionButtonsRefresh(buttonList = []) {
        // To override default handleActionButtons from entry.js by introducing BACK button
        const buttons = buttonList;
        let hasCancel = false;
        buttons.forEach((button) => {
            if (button.action === 'CANCEL' || button.action === 'BACK') {
                hasCancel = true;
            }
        });
        if (this.viewType !== 'modal' && !hasCancel) {
            buttons.push({
                action: 'CANCEL',
            });
        }
        this.storedModel.set('BUTTON_ACTIONS', buttons);
        /*
            * rather than rendering on the model set, simply hide the buttons via the DOM, this
            * is to prevent re-rendering the entire entry view (and the state that goes with it)
            * when just the buttons need to update
            */
        const buttonActionValues = util.pluck(buttons, 'action');
        const $widgetButtons = this.ui.$widgetActionBtnGroup.find('button');
        let showButtonsSelectorString = '';
        let hideButtonsSelectorString = '';
        $widgetButtons.each((idx, el) => {
            // build a selector string to show/hide all at once rather than one at a time.
            const btnText = $(el).text().toLowerCase().trim();
            const selectorText = `[name="${btnText}"],`;
            if (buttonActionValues.indexOf(btnText.toUpperCase()) === -1) {
                hideButtonsSelectorString += selectorText;
            } else {
                showButtonsSelectorString += selectorText;
            }
        });
        if (showButtonsSelectorString) {
            this.ui.$widgetActionBtnGroup.find(showButtonsSelectorString.replace(/,$/, '')).show();
        }
        if (hideButtonsSelectorString) {
            this.ui.$widgetActionBtnGroup.find(hideButtonsSelectorString.replace(/,$/, '')).hide();
        }
    },

    performCheckBatchPrintOrReprintAction(
        keyValuesPairArray,
        isReprintBatchAction,
        isReprintCheckAction,
        entryMethod,
        singleCheckModel,
    ) {
        const self = this;
        this.printView = new PrintChecksModalView({
            isModal: true,
            isReprintBatch: isReprintBatchAction,
            isReprintCheck: isReprintCheckAction,
            paymentListModel: self,
            isActionTriggeredFromPaymentsList: false,
            checkBatchesData: keyValuesPairArray,
            checkModel: singleCheckModel,
            entryMethod,
        });
        dialog.open(self.printView);
    },

    createKeyValuePair(model, typeCode, ptxId) {
        return {
            tnum: model.get('TNUM'),
            status: model.get('STATUS'),
            checkStatus: model.get('CHECKSTATUS'),
            printStatus: model.get('PRINTSTATUS'),
            PTXId: ptxId,
            typeCode,
            updateCount: model.get('UPDATECOUNT__TEMP'),
        };
    },

    printbatch() {
        const typeCode = this.getTypeCode();
        const entryMethod = this.getEntryMethod();
        const printKeyValuesPairArray = [];
        const { model } = this.pageView;
        const isReprintBatchAction = false;
        const isReprintCheckAction = false;
        const ptxId = model.get('PTX_BATCH_ID');
        const keyValuePair = this.createKeyValuePair(model, typeCode, ptxId);
        printKeyValuesPairArray.push(keyValuePair);
        this.performCheckBatchPrintOrReprintAction(
            printKeyValuesPairArray,
            isReprintBatchAction,
            isReprintCheckAction,
            entryMethod,
        );
    },

    reprintbatch() {
        const typeCode = this.getTypeCode();
        const entryMethod = this.getEntryMethod();
        const reprintKeyValuesPairArray = [];
        const { model } = this.pageView;
        const isReprintBatchAction = true;
        const isReprintCheckAction = false;
        const ptxId = model.get('PTX_BATCH_ID');
        const keyValuePair = this.createKeyValuePair(model, typeCode, ptxId);
        reprintKeyValuesPairArray.push(keyValuePair);
        this.performCheckBatchPrintOrReprintAction(
            reprintKeyValuesPairArray,
            isReprintBatchAction,
            isReprintCheckAction,
            entryMethod,
        );
    },

    performReprintCheck(reprintKeyValuesArray, entryMethod, selectedCheckModel) {
        const isReprintBatchAction = false;
        const isReprintCheckAction = true;
        if (selectedCheckModel) {
            this.performCheckBatchPrintOrReprintAction(
                reprintKeyValuesArray,
                isReprintBatchAction,
                isReprintCheckAction,
                entryMethod,
                selectedCheckModel,
            );
        } else {
            this.performCheckBatchPrintOrReprintAction(
                reprintKeyValuesArray,
                isReprintBatchAction,
                isReprintCheckAction,
                entryMethod,
            );
        }
    },

    reprintselected() {
        const typeCode = this.getTypeCode();
        const entryMethod = this.getEntryMethod();
        const childGrid = this.pageView.batchChildGridView.gridView.grid;
        const childGridWrapper = this.pageView.batchChildGridView.gridView.wrapper;
        const selectedChecks = childGrid.getSelectedRows();
        const releasedToPrinterStatus = 'RP';
        const checkOpenStatus = 'OPEN';
        const reprintKeyValuesArray = [];
        let illegibleChecksCount = 0;
        let selectedCheckModel;
        if (selectedChecks.length > 0) {
            for (let index = 0; index < selectedChecks.length; index += 1) {
                selectedCheckModel =
                    childGridWrapper.rows.get(selectedChecks[index]);
                const selectedCheckBatchStatus = selectedCheckModel.get('STATUS');
                const selectedCheckTransactionCheckStatus = selectedCheckModel.get('CHECKSTATUS');
                if (selectedCheckBatchStatus === releasedToPrinterStatus &&
                        checkOpenStatus === selectedCheckTransactionCheckStatus) {
                    const ptxId = selectedCheckModel.get('PTX_CHECK_ID');
                    const keyValuePair = this.createKeyValuePair(
                        selectedCheckModel,
                        typeCode,
                        ptxId,
                    );
                    reprintKeyValuesArray.push(keyValuePair);
                } else {
                    illegibleChecksCount += 1;
                }
            }
            const singleCheckSelected = reprintKeyValuesArray.length === 1;
            if (illegibleChecksCount > 0) {
                const message = locale.get('clm.reprintcheck.eligible.error');
                const translatedAction = locale.get('clm.reprintChecks');
                dialog.confirm(
                    message,
                    `${locale.get('title.confirm')} ${translatedAction}`,
                    (ok) => {
                        if (ok && illegibleChecksCount < selectedChecks.length) {
                            if (singleCheckSelected) {
                                this.performReprintCheck(
                                    reprintKeyValuesArray,
                                    entryMethod,
                                    selectedCheckModel,

                                );
                            } else {
                                this.performReprintCheck(reprintKeyValuesArray, entryMethod);
                            }
                        }
                    },
                );
            } else if (singleCheckSelected) {
                this.performReprintCheck(
                    reprintKeyValuesArray,
                    entryMethod,
                    selectedCheckModel,
                );
            } else {
                this.performReprintCheck(reprintKeyValuesArray, entryMethod);
            }
        }
    },

    back() {
        // This is to overwrite default label for cancel
        this.cancel();
    },

    getTypeCode() {
        const { contextModel, contextDef } = this;
        if (contextModel && contextModel.get('contextDef') && contextModel.get('contextDef').actionData) {
            return contextModel.get('contextDef').actionData.typeCode;
        } else if (contextDef.actionData) {
            return contextDef.actionData.typeCode;
        }
        return '';
    },

    getEntryMethod() {
        const { contextModel, contextDef } = this;
        if (contextModel && contextModel.get('contextDef') && contextModel.get('contextDef').actionData) {
            return contextModel.get('contextDef').actionData.entryMethod;
        } else if (contextDef.actionData) {
            return contextDef.actionData.entryMethod;
        }
        return '';
    },

    /**
     * @method addPrintBatchButtonToDetailPage
     *
     * @returns boolean
     *
     * Method determines for the given payment type code, whether the PRINT batch button
     * should be made available at the detail page level
     */
    addPrintBatchButtonToDetailPage() {
        const typeCode = this.getTypeCode();
        const readyToPrintStatus = 'PR';
        const printEntitlements = this.entitlements ? this.entitlements.PRINT : false;
        return constants.PAYMENT_TYPES_PRINT_BATCH_BUTTON_DETAIL_PAGE.indexOf(typeCode) !== -1
            && this.tempModel && this.tempModel.get('STATUS') === readyToPrintStatus
            && printEntitlements && this.tempModel.get('CHKPRINTSOURCE') !== 'O';
    },

    verifyBatchPrintStatus(modelPrintStatus) {
        const donePtxPrintStatus = 'DN';
        const errorPtxPrintStatus = 'ER';
        return modelPrintStatus === donePtxPrintStatus
            || modelPrintStatus === errorPtxPrintStatus;
    },

    verifyStatusForBatchReprint(modelStatus, modelPrintStatus) {
        const printedStatus = 'RP';
        const ptxPrintedIncompleteStatus = 'PX';
        return modelStatus === ptxPrintedIncompleteStatus ||
            (modelStatus === printedStatus && this.verifyBatchPrintStatus(modelPrintStatus));
    },

    /**
     * @method addReprintBatchButtonToDetailPage
     *
     * @returns boolean
     *
     * Method determines for the given payment type code, whether the REPRINT batch button
     * should be made available at the detail page level
     */
    addReprintBatchButtonToDetailPage() {
        const typeCode = this.getTypeCode();
        if (this.tempModel) {
            const modelStatus = this.tempModel.get('STATUS');
            const modelPrintStatus = this.tempModel.get('PRINTSTATUS');
            const reprintEntitlements = this.entitlements ? this.entitlements.REPRINT : false;
            const indexOfPrintBatchButton =
                constants.PAYMENT_TYPES_PRINT_BATCH_BUTTON_DETAIL_PAGE.indexOf(typeCode);
            return indexOfPrintBatchButton !== -1 &&
                reprintEntitlements && this.tempModel.get('CHKPRINTSOURCE') !== 'O' &&
                this.verifyStatusForBatchReprint(
                    modelStatus,
                    modelPrintStatus,
                );
        }
        return false;
    },

    useBackInsteadOfCancel() {
        const typeCode = this.getTypeCode();
        return constants.PAYMENT_TYPES_USING_BACK_BUTTON.indexOf(typeCode) !== -1;
    },

    getCancelIndex() {
        let indexOfCancel = this.buttonList.indexOf('CANCEL');
        if (indexOfCancel === -1) {
            indexOfCancel = this.buttonList.map(element => element.action).indexOf('CANCEL');
        }
        return indexOfCancel;
    },
});
