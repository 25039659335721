import http from '@glu/core/src/http';
import services from 'services';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import configuration from 'system/configuration';

export default {
    /**
     * @method getHelpPagePromise
     * @description this function will return a Promise that should resolve to
     * the context
     * sensitive help page for the current view
     * Available for non-MDF views
     * @param {object} options - options hash, containing parameters needed for
     * the request
     * @returns {Promise}
     */
    getHelpPagePromise(options) {
        const checkForRequiredData = (opt) => {
            if (util.isEmpty(opt.productCode)
                || util.isEmpty(opt.functionCode)
                || util.isEmpty(opt.mode)) {
                return false;
            }
            return true;
        }; // validate that we have the required data

        const helpService = services.generateUrl(constants.URL_GETHELPPAGE);
        const postData = {};
        const validOptions = checkForRequiredData(options);

        if (validOptions) {
            postData.productCode = options.productCode;
            postData.functionCode = options.functionCode;
            postData.typeCode = options.typeCode || '*';
            postData.actionMode = options.mode.toUpperCase();
            return new Promise((resolve, reject) => {
                http.post(helpService, postData, (data) => {
                    resolve(data);
                }, () => {
                    reject(locale.get('common.helppage.data.error', options.name));
                });
            });
        }
        const defaultPage = (configuration.isClient()) ? 'DB_Client_Help.htm' : 'DB_Administrator_Help_CSH.htm';

        return Promise.resolve({
            helpPage: defaultPage,
        });
    },
};
