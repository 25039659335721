import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import Collection from '@glu/core/src/collection';
import template from './templates.hbs';

export default ListView.extend({

    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'PANELAPPROVAL',
            serviceName: 'panelTemplateService',
            serviceFunc: null,
            businessType: null,
            returnRoute: 'PANELAPPROVAL/list',
            selector: 'rowSelector',
            additionalSearchFields: [{
                fieldName: 'USERGROUP',
                fieldValue: [this.model.get('userGroup')],
                dataType: 'text',
                operator: '=',
            }],
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        this.collection = new Collection();

        this.boundHandleSelect = this.handleSelect.bind(this);
        this.boundHandleSelectAll = this.handleSelectAll.bind(this);
        this.boundSelectTemplates = this.selectTemplates.bind(this);
    },

    onRender() {
        ListView.prototype.onRender.apply(this);
        this.setupListeners();
    },

    /**
     * Always want to stopListening to previous events so that we don't add multiple listeners.
     * Stop listening on the specified object, with the event, and callback
     */
    setupListeners() {
        // First unbind specific listeners, with the instance of the function
        this.stopListening(this.appBus, 'grid:selectRow', this.boundHandleSelect);
        this.stopListening(this.appBus, 'grid:selectAllRows', this.boundHandleSelectAll);

        // Now rebind listeners
        this.listenTo(this.appBus, 'grid:selectRow', this.handleSelect);
        this.listenTo(this.appBus, 'grid:selectAllRows', this.handleSelectAll);

        if (this.gridView) {
            if (!this.boundGridAvailable) {
                this.boundGridAvailable = () => {
                    this.stopListening(this.gridView.grid, 'grid:displayed', this.boundSelectTemplates);
                    this.listenToOnce(this.gridView.grid, 'grid:displayed', this.boundSelectTemplates);
                };
            }
            /*
             * NH-94463 Checkboxes where no longer being selected. In the future,
             * when we switch to more robust grid, this solution will change completely
             * On the first render, gridView.grid should be undefined, the complex listeners
             * are required. On consecutive renders, where gridView.grid is defined, only
             * need to list for grid:contentRendered event
             */
            if (!util.isNullOrUndefined(this.gridView.grid)) {
                this.stopListening(this.gridView.grid, 'grid:contentRendered', this.boundSelectTemplates);
                this.listenToOnce(this.gridView.grid, 'grid:contentRendered', this.boundSelectTemplates);
            } else {
                this.stopListening(this.gridView, 'grid:available', this.boundGridAvailable);
                this.listenToOnce(this.gridView, 'grid:available', this.boundGridAvailable);
            }
        }
    },

    /**
     * Iterate over the selected templates from the model and find the corresponding row
     * in the data grid based on PRODUCT, TYPE, and CMB_TEMPLATE_CODE. Then set the 'checked'
     * property of selection column in the row.
     */
    selectTemplates() {
        const selectedTemplates = this.model.get('templates');
        const rows = this.gridView.getRows();
        selectedTemplates.forEach((thisTemplate) => {
            const selectedRow = rows.find(row => this.findRow(thisTemplate, row));

            // When a row is found, check the select column
            if (selectedRow !== undefined) {
                this.gridView.$el.find(`[data-model-cid="${selectedRow.cid}"]`).prop('checked', true);
                this.collection.add(selectedRow);
                this.model.set('templates', this.collection);
            }
        });
    },

    /**
     * Find the row based on TYPE and CMD_TEMPLATE_CODE
     * NH-94463 - on consecutive renders of the list, the template param should
     * be a previously
     * stored row therefore TYPE and CMB_TEMPLATE_CODE attributes are also possible matches
     * @param {Object} templateObj
     * @param {Object} row
     */
    findRow(templateObj, row) {
        const rowType = row.get('TYPE');
        const rowCode = row.get('CMB_TEMPLATE_CODE');
        return (templateObj.get('paymentType') === rowType && templateObj.get('templateCode') === rowCode)
            || (templateObj.get('TYPE') === rowType && templateObj.get('CMB_TEMPLATE_CODE') === rowCode);
    },

    handleSelect(view, added) {
        if (added) {
            this.collection.add(view.model);
        } else {
            this.collection.remove(view.model);
        }
        this.model.set('templates', this.collection);
    },

    handleSelectAll(added) {
        let templates = [];
        if (added) {
            templates = this.gridView.grid.getSelectedRowModels();
        }
        this.collection.reset(templates);
        this.model.set('templates', this.collection);
    },

    onClose() {
        this.stopListening();
    },

    /**
     * Override the prototype, almost the exact same functionality, except the
     * isMobileGridEnabled is never evaluated for this grid
     * @param {Array} result
     */
    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        this.setHasLoadedRequiredData(true);
        this.listenTo(this.gridView, 'gridapi:loaded', this.updateRefreshTimestamp);
        this.listenForGridErrors();
        this.render();
        return result.actions;
    },
});
