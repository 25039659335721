import Layout from '@glu/core/src/layout';
import validatorPatterns from 'system/validatorPatterns';
import FlexDropdown from '@glu/flex-dropdown';
import locationLoginItemViewTmpl from './locationLoginItemView.hbs';

export default Layout.extend({
    template: locationLoginItemViewTmpl,
    ui: {
        $removeBtn: '[data-hook="getRemoveBtn"]',
        $loginInput: 'input[name="LOGIN"]',
    },

    events: {
        'click @ui.$removeBtn': 'onRemoveItem',
        'keyup @ui.$loginInput': 'onLoginNameInput',
    },

    initialize(options) {
        this.parentView = options.parentView;

        // Callbacks to alert CollectionView of when selection has been made
        this.listenTo(this.model, 'change:ACCOUNTFILTER', this.options.locationSelectCallback);

        /*
         * when other items make location selections we need to
         * update the dropdown with available options
         */
        this.listenTo(this.parentView.model, 'change:selected', this.setupLocationDropdown);

        // set validator
        this.model.validators = {
            LOGIN: {
                description: 'Login',
                matches: validatorPatterns.ALPHANUMERIC_PATTERN,
            },
            ACCOUNTFILTER: {
                description: 'Location',
                exists: true,
            },
        };
    },

    onRender() {
        if (this.options.mode !== 'view') {
            this.setupLocationDropdown();
        }
    },

    /**
     * Create location options dropdown
     */
    setupLocationDropdown() {
        const locationDropdown = new FlexDropdown({
            data: this.options.locationOptions,
            disableMultiButton: true,
            preSelectedIds: this.model.get('ACCOUNTFILTER') ? [this.model.get('ACCOUNTFILTER')] : [],
            customParse: (data) => {
                // only show options not selected yet elsewhere besides here
                const selectedVals = this.parentView.model.get('selected');
                const currentVal = this.model.get('ACCOUNTFILTER');
                return data.filter(location => (selectedVals.indexOf(location.id) === -1)
                    || (location.id === currentVal));
            },
        });

        this.locationOptionsRegion.show(locationDropdown);
        this.listenTo(locationDropdown, 'selectionChanged:id', this.onLocationSelect.bind(this));
    },

    onLocationSelect(id) {
        this.model.set('ACCOUNTFILTER', id[0]);
    },

    /**
     * @method onRemoveItem
     * callback to trigger removal of this item from the parent collection
     */
    onRemoveItem() {
        this.parentView.collection.remove(this.model);
        this.options.locationSelectCallback();
    },

    onLoginNameInput() {
        this.model.validateField('LOGIN');
    },

    templateHelpers() {
        return {
            notViewMode: this.options.mode !== 'view',
        };
    },
});
