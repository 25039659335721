import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { ChatIcon } from '@glu/icons-react';
import useLocale from '../../hooks/useLocale';

import styles from './RtpStartConversation.styles';

const propTypes = {
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const StartConversation = ({ classes }) => {
    const { getLocaleString } = useLocale();

    return (
        <div className={classes.startConversation}>
            <h2>
                <ChatIcon className={classes.iconChatBubble} title={getLocaleString('rtp.startConversationTitle')} />
                <span className={classes.startConversationText}>{getLocaleString('rtp.startAConversation')}</span>
            </h2>
        </div>
    );
};

StartConversation.propTypes = propTypes;

export default withStyles(styles)(StartConversation);
