import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import $ from 'jquery';
import cashFlowImportLayoutTmpl from './cashFlowImportLayout.hbs';
import itemService from '../service/item';

const CashFlowImportLayout = Layout.extend({
    template: cashFlowImportLayoutTmpl,

    ui: {
        // containers of controls for future adjustments
        $futureAdjustmentSettings: '.cashflow-future-adjustment-settings',

        $futureAdjustmentAmounts: '.cashflow-future-adjustment-amounts',
        $accountSelect: '#cashflow-account',
        $cashFlowFile: '#cash-flow-import',
        $cashFlowFilename: '#cash-flow-filename',
    },

    events: {
        'change @ui.$cashFlowFile': 'updateSelectedFile',
    },

    initialize(options) {
        this.listenTo(
            this.model,
            {
                updateFields: this.save,
            },
            this,
        );

        this.model.validators = util.extend(
            this.model.validators || {},
            {
                ACCOUNTID: {
                    description: locale.get('cashflow.account'),
                    exists: true,
                },
            },
        );

        this.action = options.action;
        this.accountList = options.accountList;
        this.selectedFile = locale.get('cashflow.click.select.file');
    },

    templateHelpers() {
        return {
            isPredicted: this.isPredicted === true,
            isCategoryChangeOnly: this.isCategoryChangeOnly,
            accounts: this.accountList.toJSON(),
            cashFlowFilename: this.selectedFile,
        };
    },

    onRender() {
        this.updateAccountValue();
    },

    updateSelectedFile(e) {
        [this.selectedFile] = e.currentTarget.files;
        $('#cash-flow-filename').html(this.selectedFile.name);
    },

    updateAccountValue() {
        let selected = this.model.get('ACCOUNTID');

        if (this.accountList.length > 0) {
            // make sure the selected account is actually in the list
            const accountInList = this.accountList.where({
                accountId: +selected,
            });

            if (accountInList.length === 0) {
                // if it isn't, select the first one available
                selected = this.accountList.at(0).get('accountId');
            }
        }

        this.ui.$accountSelect.val(selected).trigger('change');
    },

    save() {
        const file = this.selectedFile;
        const reader = new FileReader();
        const accountId = this.ui.$accountSelect.val();

        /*
         * this is a quick csv parser implementation
         * for more complex parsing, we should use a library
         * like jquery-csv or papaparse
         */
        reader.onload = () => {
            const contents = reader.result;
            const lines = contents.split('\n');
            if (lines === undefined) {
                return;
            }
            const importCollection = new Collection();
            for (let i = 0; i < lines.length; i += 1) {
                const model = new Model();
                const line = lines[i].split(',');
                if (line[0].length > 0) {
                    /*
                     * this is just a subset of the valid fields.
                     */
                    model.set('ACCOUNTID', accountId);
                    model.set('EXPECTEDDATE', line[0]);
                    model.set('DESCRIPTION', line[1]);
                    const type = line[2];
                    model.set('CASHFLOWTYPE', type);
                    if (type === 'INFLOW' || type === 'CREDIT') {
                        model.set('INFLOW', parseFloat(line[3]));
                        model.set('OUTFLOW', null);
                    } else if (type === 'OUTFLOW' || type === 'DEBIT') {
                        model.set('OUTFLOW', parseFloat(line[3]));
                        model.set('INFLOW', null);
                    }
                    importCollection.add(model);
                }
            }
            itemService.batch(importCollection);
        };
        reader.readAsText(file);
    },

    getButtons() {
        return [{
            text: locale.get('cashflow.import'),
            className: 'btn btn-primary btn-cashflow-save',
        }];
    },
});

export default CashFlowImportLayout;
