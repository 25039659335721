import React from 'react';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import WorkspaceView from 'common/workspaces/views/workspace';
import workspaceHelper from 'common/workspaces/api/helper';
import CreateScheduledExport from 'components/CreateScheduledExport/CreateScheduledExport';
import ModifyScheduledExport from 'components/ModifyScheduledExport/ModifyScheduledExport';
import CreateTransmissionProfile from 'components/CreateTransmissionProfile/CreateTransmissionProfile';
import systemConfig from 'system/configuration';
import ScheduledExportsWidget from './views/scheduledExportsWidget';
import ViewScheduledExport from './views/viewScheduledExport';
import ViewTransmissionProfile from './views/viewTransmissionProfile';

workspaceHelper.publishedWidgets.add({
    id: 'SCHEXP_REPORTING',
    view: ScheduledExportsWidget,
    options: {},
});

export default {
    scheduledExportsWidget() {
        if (processDeeplink('REPORTING/scheduledExportsWidget', this)) {
            this.showPage('', new ScheduledExportsWidget());
        }
    },
    scheduledExports() {
        let view;
        if (systemConfig.isAdmin()) {
            view = new ScheduledExportsWidget();
        } else {
            view = new WorkspaceView({
                id: 'SCHEXP_REPORTING',
                returnRoute: 'REPORTING/scheduledExports',
            });
        }
        this.showPage('', view);
    },

    addScheduledExport() {
        this.showPage('Add Scheduled Export', <CreateScheduledExport returnRoute="REPORTING/scheduledExports" />);
    },

    modifyScheduledExport() {
        this.showPage('Modify Scheduled Export', <ModifyScheduledExport returnRoute="REPORTING/scheduledExports" />);
    },

    viewScheduledExport() {
        this.showPage('View Scheduled Export', new ViewScheduledExport({
            returnRoute: 'REPORTING/scheduledExports',
            insertRoute: 'REPORTING/scheduledExports/insert',
            modifyRoute: 'REPORTING/scheduledExports/modify',
        }));
    },
    viewTransmissionProfile() {
        this.showPage('', new ViewTransmissionProfile({
            returnRoute: 'REPORTING/scheduledExports',
        }));
    },

    addTransmissionProfile() {
        this.showPage('', <CreateTransmissionProfile returnRoute="REPORTING/scheduledExports" />);
    },
};
