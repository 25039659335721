var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"getAmount") || (depth0 != null ? lookupProperty(depth0,"getAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getAmount","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":17}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"currency") || (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":30}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"approvalSequences") || (depth0 != null ? lookupProperty(depth0,"approvalSequences") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"approvalSequences","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":54}}}) : helper)))
    + " "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Approval.Sequences",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":55},"end":{"line":1,"column":103}}}))
    + "</h4>\n<div data-region=\"listRegion\"></div>\n";
},"useData":true});