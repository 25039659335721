import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import systemConfig from 'system/configuration';
import pmxConst from 'no-paymode!common/paymodeIntegration/constants';
import {
    getComponentByIdAsView,
    getUtilById,
} from 'no-paymode!common/paymodeIntegration/paymodeIntegration';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import pmxCheckPrintDetailWidgetTmpl from './pmxCheckPrintDetailWidget.hbs';

const buildCheckPrintURL = function (id) {
    const paymodeCheckPrintDetailPath = serverConfigParams.get('pmxPaymodeCheckPrintDetailRestEndpoint');
    return `${paymodeCheckPrintDetailPath}/${encodeURIComponent(id)}/checks`;
};

export default BaseWidget.extend({
    template: pmxCheckPrintDetailWidgetTmpl,
    className: 'ui-widget field-container pmx-field-container',
    exceptions: [],
    pmxRemittanceRows: [],

    initialize(options) {
        /*
         * call base to init model, parentModel, readyState, fieldName, etc (note
         * from other widget)
         */
        BaseWidget.prototype.initialize.call(this, options);
        this.model = new Model();
    },

    onRender() {
        Promise.all([
            getComponentByIdAsView('paymentDetail.checkPrintDetailSection'),
            getUtilById('paymentDetail.styles'),
        ]).then(this.handleCheckPrintComponent.bind(this));
    },

    /**
     * handleRemittanceComponent
     *
     * @param {[View, Object]} results - Promise.all results
     */
    handleCheckPrintComponent([CheckPrintDetailView, styles]) {
        // paymentscommon.customer_reference is the paymentID (DPA)
        let checkPrintView;
        const paymentId = this.parentModel.get('PAYMENT_EXTERNAL_ID');
        const self = this;
        let url;

        if (systemConfig.isAdmin()) {
            url = services.generateAdminServletUrl(pmxConst.PMX_PROXY_SERVLET
                + buildCheckPrintURL(paymentId));
        } else {
            url = services.generateServletUrl(pmxConst.PMX_PROXY_SERVLET
                + buildCheckPrintURL(paymentId));
        }

        /*
         * Add the header pmx to the request to indicate we will making a call to the
         * PMX server using the proxyServlet
         */
        const requestOptions = util.extend(
            {},
            {
                beforeSend(request) {
                    request.setRequestHeader('pmx', true);
                },
            },
        );

        const pmxCheckPrintWidgetPromise = new Promise((resolve, reject) => {
            http.get(url, (result) => {
                resolve(result);
            }, (status) => {
                reject(`${status.status} ${status.statusText}`);
            }, requestOptions);
        });

        pmxCheckPrintWidgetPromise.then(
            (data) => {
                checkPrintView = new CheckPrintDetailView({
                    checkDetail: data,
                });

                self.regionManager.addRegion('pmxCheckPrintDetailRegion', '.pmx-check-print-detail');

                self.$('.pmx-check-print-detail').addClass(styles.paymentDetail);
                self.pmxCheckPrintDetailRegion.show(checkPrintView);
            },
            () => {
            // do nothing - just recieve exception
            },
        );
    },
});
