var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"alert-region\" data-region-child=\"alertRegion\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <button type=\"button\" data-action=\"addChild\" class=\"btn btn-link\" name=\"addChild\"><span class=\"icon-addbene\"></span> <span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.beneficiary.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":151},"end":{"line":18,"column":186}}}))
    + "</span></button>\n                <button type=\"button\" data-action=\"deleteChild\" class=\"btn btn-link"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMobileGridEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":83},"end":{"line":19,"column":124}}})) != null ? stack1 : "")
    + "\" name=\"deleteChild\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":163},"end":{"line":19,"column":189}}}))
    + "</span></button>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideAlertRegion") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":5,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"batch-controls-container\">\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-info-links\">\n                    <button type=\"button\" class=\"btn btn-tertiary print\" data-hook=\"print-button\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":105},"end":{"line":9,"column":150}}}))
    + "\"><span class=\"icon-print\"></span><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":206},"end":{"line":9,"column":251}}}))
    + "</span></button>\n                    <button type=\"button\" class=\"btn btn-tertiary export\" data-hook=\"export-button\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.exportOptions.Export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":107},"end":{"line":10,"column":147}}}))
    + "\"><span class=\"icon-export\"></span><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.exportOptions.Export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":204},"end":{"line":10,"column":244}}}))
    + "</span></button>\n                    <button type=\"button\" class=\"btn btn-tertiary refresh\" data-hook=\"refresh-button\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":109},"end":{"line":11,"column":136}}}))
    + "\"><span class=\"icon-refresh\"></span><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":194},"end":{"line":11,"column":221}}}))
    + "</span><span class=\"refresh-time\" data-hook=\"refresh-time\"></span></button>\n                </div>\n        </div>\n            <div class=\"grid-region batch-child-grid\" data-region-child=\"batchChildRegion\"></div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notView") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});