import React, { Fragment } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import useLocale from '../../hooks/useLocale';

const propTypes = {
    localeKey: PropTypes.string.isRequired,
    params: arrayOf(PropTypes.shape({})),
};

const defaultProps = {
    params: [],
};

const delimiter = '||';
const innerDelimiter = '~~';

export const transformString = (inStr) => {
    if (inStr.indexOf(delimiter) === -1) {
        return (
            <>
                {inStr}
            </>
        );
    }
    const parts = inStr.split(delimiter);
    const retValue = parts.map((value, index) => {
        const transform = value.indexOf(innerDelimiter) !== -1;
        let val;
        let cName;
        let Tag;
        if (transform) {
            const temp = value.split(innerDelimiter);
            [val, cName, Tag] = temp;
        }
        const key = `tl-${index}`;
        return [
            (transform)
                ? <Tag key={key} className={cName}>{val}</Tag>
                : <Fragment key={key}>{value}</Fragment>,
        ];
    });
    return retValue;
};

export const markParameters = params => params.map((param) => {
    if (param.className || param.tag) {
        return `${delimiter}${param.value}${innerDelimiter}${param.className ? param.className : ''}${innerDelimiter}${param.tag ? param.tag : 'span'}${delimiter}`;
    }
    return param.value;
});

/**
 * @param {string} localeKey - app resource key
 * @param {arrayOf Objects} params - array of parameter objects {value, className, tag}
 * value is required.  it is the parameter's value
 * className is optional.  it is the className that will be assigned to the parameter's markup
 * tag is optional.  it is the HTML tag (defaults to SPAN) that will wrap the transformed parameter
 */
const TransformLocale = ({
    localeKey, params,
}) => {
    const { getLocaleString } = useLocale();
    const paramValues = markParameters(params);
    const resolvedString = getLocaleString(localeKey, ...paramValues);
    return (
        <>{transformString(resolvedString)}</>
    );
};

TransformLocale.propTypes = propTypes;
TransformLocale.defaultProps = defaultProps;

export default TransformLocale;
