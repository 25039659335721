import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import template from './chipWidget.hbs';

const ChipWidget = ItemView.extend({
    template,
    className: 'chip-widget',

    initialize(options) {
        const fieldName = (options.column && options.column.get('chipField'))
            ? options.column.get('chipField') : 'ENABLED';
        this.chipCurrentState = this.model.get(fieldName) !== null ? +this.model.get(fieldName) : 0;
        this.chipPositiveLabel = locale.get('features.chip.label.positive');
        this.chipNegativeLabel = locale.get('features.chip.label.negative');
    },

    templateHelpers() {
        return {
            chipClass: this.chipCurrentState === 1 ? 'positive' : 'negative',
            chipLabel: this.chipCurrentState === 1
                ? this.chipPositiveLabel : this.chipNegativeLabel,
        };
    },

});

export default ChipWidget;
