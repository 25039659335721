import util from '@glu/core/src/util';
import constants from '../constants';
import TypeEntitledModel from '../model/typeEntitled';

export default {
    /**
     * Get the value from the CONTACTTYPE attribute of the model
     * @param {Object} model
     * @return {string|number|undefined}
     */
    getContactType(model) {
        const contactType = model.get('CONTACTTYPE');

        /*
         * if contactType is not an array, then return the value,
         * undefined is permitted on 'create'
         */
        if (typeof contactType === 'string' || typeof contactType === 'number' || contactType === undefined || contactType === null) {
            return contactType;
        }
        if (util.isArray(contactType)) {
            return this.parseContactTypeArray(contactType);
        }
        throw new Error('contactType is expected to be a string, number, or array');
    },

    /**
     * Evaluate the array and return the proper contact type value
     * @param {Array} contactType
     */
    parseContactTypeArray(contactType) {
        // when contactType is an array, determine the type
        if (util.contains(contactType, constants.BUSINESS_CONTACT)) {
            /*
             * if array contains BUSINESS_CONTACT, then it is business contact type.. no
             * other types
             */
            return constants.BUSINESS_CONTACT;
        }
        if (util.contains(contactType, constants.INDIVIDUAL_CONTACT)
            && util.contains(contactType, constants.EMPLOYEE_CONTACT)) {
            /*
             * if array contains types of INDIVIDUAL and EMPLOYEE, both are selected,
             * change to a single value to indicate both
             */
            return constants.INDIVIDUAL_EMPLOYEE_CONTACT;
        }
        if (util.contains(contactType, constants.INDIVIDUAL_CONTACT)) {
            return constants.INDIVIDUAL_CONTACT;
        }
        if (util.contains(contactType, constants.EMPLOYEE_CONTACT)) {
            return constants.EMPLOYEE_CONTACT;
        }
        return '';
    },

    entitlementPromise(typeCode) {
        return new Promise((resolve, reject) => {
            const typeEntitled = new TypeEntitledModel({
                typeCode,
            });
            typeEntitled.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * Returns true if the passed in list of company entitlements includes any template
     * entitlements.
     * @param {{item:Object[]}} companyEntitlements - Array of company entitlement objects.
     * @return {boolean}
     */
    companyIsEntitledToTemplates(companyEntitlements) {
        if (!companyEntitlements || !companyEntitlements.item) {
            return false;
        }
        const templateEntitlements = companyEntitlements.item.filter(entitlement => (['TMPL', 'BHTMPL'].includes(entitlement.functionCode)));
        return templateEntitlements.length > 0;
    },

    /**
     * Returns true if the passed in list of company entitlements includes any template
     * entitlements.
     * @param {{item:Object[]}} companyEntitlements - Array of company entitlement objects.
     * @return {boolean}
     */
    companyIsEntitledToPayments(companyEntitlements) {
        if (!companyEntitlements || !companyEntitlements.item) {
            return false;
        }
        const templateEntitlements = companyEntitlements.item.filter(entitlement => (['INST', 'BATCH'].includes(entitlement.functionCode)
            && ['RTP', 'RTGS', 'USACH'].includes(entitlement.productCode)));
        return templateEntitlements.length > 0;
    },

    /**
     * Returns true if the passed in list of company entitlements includes any RFP template
     * entitlements.
     * @param {{item:Object[]}} companyEntitlements - Array of company entitlement objects.
     * @return {boolean}
     */
    companyIsEntitledToRFPTemplates(companyEntitlements) {
        if (!companyEntitlements || !companyEntitlements.item) {
            return false;
        }
        const templateEntitlements = companyEntitlements.item.filter(entitlement => (['REQTMPL'].includes(entitlement.functionCode)
            && ['RTP'].includes(entitlement.productCode)));
        return templateEntitlements.length > 0;
    },

    /**
     * Returns true if the passed in list of company entitlements includes RFP
     * entitlements.
     * @param {{item:Object[]}} companyEntitlements - Array of company entitlement objects.
     * @return {boolean}
     */
    companyIsEntitledToRFP(companyEntitlements) {
        if (!companyEntitlements || !companyEntitlements.item) {
            return false;
        }
        const templateEntitlements = companyEntitlements.item.filter(entitlement => (['REQUEST'].includes(entitlement.functionCode)
            && ['RTP'].includes(entitlement.productCode)));
        return templateEntitlements.length > 0;
    },

    /**
     * Returns a map of sets of sectionLabel and paymentSectionHeader,
     * for different types of relatedItems
     * @return {map}
     */
    getRelatedItemLabels() {
        return {
            paymentsOnly: {
                sectionLabel: 'PAY.paymentsUsedInContact',
                paymentSectionHeader: 'PAY.paymentsHeader.paymentsOnly',
            },
            paymentRequestOnly: {
                sectionLabel: 'PAY.paymentRequestsUsedInContact',
                paymentSectionHeader: 'PAY.paymentsHeader.paymentRequestOnly',
            },
            paymentRequestsAndTemplate: {
                sectionLabel: 'PAY.paymentRequestsAndTemplatesUsedInContact',
                paymentSectionHeader: 'PAY.paymentsHeader.paymentRequestsAndTemplate',
            },
            paymentAndPaymentRequests: {
                sectionLabel: 'PAY.paymentAndPaymentRequestsUsedInContact',
                paymentSectionHeader: 'PAY.paymentsHeader.paymentAndPaymentRequests',
            },
            paymentsAndTemplates: {
                sectionLabel: 'PAY.paymentsAndTemplatesUsedInContact',
                paymentSectionHeader: 'PAY.paymentsHeader.paymentsAndTemplates',
            },
            paymentsAndPaymentRequestsAndTemplates: {
                sectionLabel: 'PAY.paymentsAndPaymentRequestsAndTemplatesUsedInContact',
                paymentSectionHeader: 'PAY.paymentsHeader.paymentsAndPaymentRequestsAndTemplates',
            },
            templatesOnly: {
                sectionLabel: 'PAY.templatesUsedInContact',
                paymentSectionHeader: '',
            },
        };
    },

    /**
     * Returns the type of related items entitled to the company.
     * @param {View} view - the view.
     * @return {string} the type of related items entitled to the company
     */
    getRelatedItemTypes(view) {
        if (view.isCompanyEntitledToPayments && !view.isCompanyEntitledToTemplates
            && !view.isCompanyEntitledToRFP && !view.isCompanyEntitledToRFPTemplates) {
            return 'paymentsOnly';
        }
        if (!view.isCompanyEntitledToPayments && !view.isCompanyEntitledToTemplates
            && view.isCompanyEntitledToRFP && !view.isCompanyEntitledToRFPTemplates) {
            return 'paymentRequestOnly';
        }
        if (!view.isCompanyEntitledToPayments && view.isCompanyEntitledToRFP
            && (view.isCompanyEntitledToRFPTemplates || view.isCompanyEntitledToTemplates)) {
            return 'paymentRequestsAndTemplate';
        }
        if (view.isCompanyEntitledToPayments && !view.isCompanyEntitledToTemplates
            && view.isCompanyEntitledToRFP && !view.isCompanyEntitledToRFPTemplates) {
            return 'paymentAndPaymentRequests';
        }
        if (view.isCompanyEntitledToPayments && view.isCompanyEntitledToRFP
            && (view.isCompanyEntitledToTemplates || view.isCompanyEntitledToRFPTemplates)) {
            return 'paymentsAndPaymentRequestsAndTemplates';
        }
        if (!view.isCompanyEntitledToPayments && !view.isCompanyEntitledToRFP
            && (view.isCompanyEntitledToTemplates || view.isCompanyEntitledToRFPTemplates)) {
            return 'templatesOnly';
        }
        return 'paymentsAndTemplates';
    },

};
