import ItemView from '@glu/core/src/itemView';
import $ from 'jquery';
import Handlebars from 'handlebars';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import template from './base.hbs';

export default ItemView.extend({
    template,

    initialize(options) {
        // collection to be displayed in select2 drop down
        this.collection = options.collection || new Collection();

        // default select, should be a model found in the collection
        this.selected = options.selected;

        // pass in true if the user has permission to add to collection
        this.canAdd = options.canAdd || false;

        // pass in true if the user should edit the collection
        this.canEdit = options.canEdit;

        // pass in true to disable dropdown
        this.isDisabled = options.isDisabled || false;

        // pass in true to allow multiple select
        this.isMultiple = options.isMultiple || false;

        // an array of model attribute names (strings)
        this.searchFields = options.searchFields || [];

        // handlebar template to format selection
        this.selectedStache = options.selectedStache || '{{{text}}}';

        // handlebar template to format each dropdown display line
        this.displayStache = options.displayStache || '{{{text}}}';

        // text to display when nothing is selected
        this.noSelectionText = options.noSelectionText;

        // text to display when no models match search
        this.emptyText = options.emptyText || locale.get('common.no.matches');

        // text to display in add button
        this.addButtonText = options.addButtonText || locale.get('common.add');

        // text to display for delete button
        this.deleteButtonText = options.deleteButtonText || locale.get('common.delete');

        // text to display for edit button
        this.editButtonText = options.editButtonText || locale.get('common.edit');

        // Number of characters that need to be entered before showing options
        this.minimumInputLength = options.minimumInputLength || -1;
    },

    ui: {
        $selectList: '[data-hook="selectList"]',
    },

    onRender() {
        this.initializeList();
        this.setCollectionListeners();
        this.setListListeners();

        if (this.selected) {
            const selectedValues = [];

            /*
             * Check if selected is a model or collection and set the selected values of
             * the comboBox
             */
            if (this.selected instanceof Collection) {
                util.each(this.selected.models, (value) => {
                    selectedValues.push(value.cid);
                });
            } else {
                selectedValues.push(this.selected.cid);
            }
            this.ui.$selectList.comboBox('val', selectedValues);
        }
    },

    templateHelpers() {
        const self = this;

        return {
            list() {
                return self.collection.map(model => ({
                    cid: model.cid,
                }));
            },

            displayField(cid) {
                const currModel = util.find(self.collection.models, model => model.cid === cid);
                return Handlebars.compile(self.displayStache)(currModel.toJSON());
            },

            selected() {
                if (self.selected) {
                    return Handlebars.compile(self.selectedStache)(self.selected.toJSON());
                }
                return Handlebars.compile(self.defaultStache)();
            },

            canAdd: self.canAdd,
            isDisabled: self.isDisabled,
            isMultiple: self.isMultiple,
        };
    },

    setCollectionListeners() {
        // collection listener events
        this.collection.on('remove', this.modelRemoved, this);
        this.collection.on('change', this.modelChanged, this);
        this.collection.on('add', this.modelAdded, this);
    },

    onClose() {
        this.ui.$selectList.comboBox('destroy');
    },

    initializeList() {
        const self = this;

        // select2 documentation can be found at http://ivaynberg.github.io/select2/
        this.ui.$selectList.comboBox({
            placeholder: self.noSelectionText,
            minimumInputLength: self.minimumInputLength,
            allowClear: true,

            formatResult(listItem) {
                if (listItem.id === 'addOption') {
                    return `<button type="button" class="btn btn-primary btn-add" title="${self.addButtonText}">${self.addButtonText}</button>`;
                }

                const model = self.collection.get(listItem.id);
                let returnString = Handlebars.compile(self.displayStache)(model.toJSON());

                if (model.get('canEdit') === 'true' || model.get('canDelete') === 'true') {
                    returnString += '<span class="button-wrap">';

                    if (model.get('canEdit') === 'true' && self.canEdit === 'true') {
                        returnString += `<button type="button" class="btn btn-link deluxeComboBoxModifyItemButton" data-item="${model.cid}" title="${self.editButtonText}"><span class="icon-pencil"></span> </button>`;
                    }

                    if (model.get('canDelete') === 'true') {
                        returnString += `<button type="button" class="btn btn-link deluxeComboBoxDeleteItemButton" data-item="${model.cid}" title="${self.deleteButtonText}"> <span class="icon-trash"></span> </button>`;
                    }
                    returnString += '</span>';
                }

                return returnString;
            },

            formatNoMatches() {
                return self.emptyText;
            },

            matcher(term, text, opt) {
                let localTerm = term;
                if (localTerm.length === 0) {
                    return true;
                }

                if (opt.val() === 'addOption') {
                    return false;
                }

                const model = self.collection.get(opt.val());
                localTerm = localTerm.toLowerCase();

                if (localTerm.length === 0 && opt.val() === 'addOption') {
                    return true;
                }

                let searchText = '';
                if (self.searchFields.length === 0) {
                    searchText = Handlebars
                        .compile(self.displayStache)(model.toJSON()).toLowerCase();
                    if (searchText.indexOf(localTerm) > -1) {
                        return true;
                    }
                } else {
                    for (let i = 0; i < self.searchFields.length; i += 1) {
                        const searchField = model.get(self.searchFields[i]);
                        if (searchField) {
                            searchText = model.get(self.searchFields[i]).toLowerCase();
                            if (searchText.indexOf(localTerm) > -1) {
                                return true;
                            }
                        }
                    }
                }

                return false;
            },
        });
    },

    setListListeners() {
        const self = this;

        // change event for selection
        this.ui.$selectList.on('change', (e) => {
            if (e.val) {
                self.trigger('select', self.collection.get(e.val));
            }
        });

        // change event for clearing selection
        this.ui.$selectList.on('select2-clearing', () => {
            self.trigger('select');
        });

        /*
         * select2 rebuilds the dropdown list each time it's opened
         * apply customizations to the list
         */
        this.ui.$selectList.on('select2-open', () => {
            if (self.canAdd) {
                // style the add button
                $('.select2-results').find('.btn-add').closest('li').addClass('opt-add');
            }

            // add event for the remove button
            $('.select2-results .deluxeComboBoxDeleteItemButton').on('mouseup', (e) => {
                e.stopPropagation();
                self.trigger('remove', self.collection.get($(e.currentTarget).attr('data-item')));
            });

            // add delegate event for the modify button
            $('ul.select2-results').off().on('mouseup', 'button.deluxeComboBoxModifyItemButton', (e) => {
                e.stopPropagation();
                self.ui.$selectList.comboBox('close');
                self.trigger('modify', self.collection.get($(e.currentTarget).attr('data-item')));
            });
        });

        // check if the add button was clicked
        this.ui.$selectList.on('select2-selecting', (e) => {
            if (e.val === 'addOption') {
                e.preventDefault();
                self.ui.$selectList.comboBox('close');
                self.trigger('add');
            }
        });
    },

    modelRemoved(model) {
        if (this.ui.$selectList.comboBox('val') === model.cid) {
            this.ui.$selectList.comboBox('val', '');
        }
        this.$el.find(`option[value="${model.cid}"]`).remove();
        this.ui.$selectList.comboBox('close');
    },

    modelChanged(model) {
        // Need to revisit
        this.bindUIElements();
        this.$el.find(`option[value="${model.cid}"]`).text(Handlebars.compile(this.displayStache)(model.toJSON()));
        if (this.ui.$selectList.comboBox('val') === model.cid) {
            this.ui.$selectList.val(model.cid).trigger('change');
        }
    },

    modelAdded(model) {
        this.ui.$selectList.append(`<option value="${model.cid}">${Handlebars.compile(this.displayStache)(model.toJSON())}</option>`);
        // add edit and delete capabilities for a newly created entity
        if (util.isEmpty(model.get('canEdit'))) {
            model.set('canEdit', 'true');
        }
        if (this.canEdit === false) {
            model.set('canEdit', 'false');
        }
    },

    // returns the model matching the selection
    selectedModel() {
        return this.collection.get(this.ui.$selectList.comboBox('val'));
    },

    selectedCollection() {
        const selectedItems = this.ui.$selectList.comboBox('val');
        const selectedCollection = new Collection();
        const self = this;

        if (util.isArray(selectedItems)) {
            util.each(selectedItems, (item) => {
                selectedCollection.add(self.collection.get(item));
            });
        } else {
            selectedCollection.add(this.collection.get(selectedItems));
        }

        return selectedCollection;
    },

    /*
     * returns a pointer to the select2 object, refer to
     * documentation at http://ivaynberg.github.io/select2/
     */
    selectList() {
        return this.ui.$selectList;
    },

    // unsets the selection
    clearSelection() {
        this.ui.$selectList.comboBox('data');
        this.ui.$selectList.comboBox('val', this.noSelectionText);
    },
});
