var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-2\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":85}}}))
    + "</div>";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-2\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":79}}}))
    + "</div>";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-2\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":79}}}))
    + "</div>";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row apply-quick-row form-group\">\n        <div class=\"col-3\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.quicklyApplyLimits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":27},"end":{"line":12,"column":62}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTrans") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBatch") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDaily") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"col-1\">\n            <button class=\"btn btn-primary\" data-action=\"applyQuick\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":69},"end":{"line":32,"column":87}}}))
    + "</button>\n        </div>\n    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-2 field-container\">\n                <label for=\"limitTrans\" class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":56},"end":{"line":15,"column":101}}}))
    + "</label>\n                <input type=\"text\" class=\"limit-field\" data-value=\"transaction\" id=\"limitTrans\">\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-2 field-container\">\n                <label for=\"limitBatch\" class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":56},"end":{"line":21,"column":95}}}))
    + "</label>\n                <input type=\"text\" class=\"limit-field\" data-value=\"batch\" id=\"limitBatch\">\n            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-2 field-container\">\n                <label for=\"limitDaily\" class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":56},"end":{"line":27,"column":95}}}))
    + "</label>\n                <input type=\"text\" class=\"limit-field\" data-value=\"daily\" id=\"limitDaily\">\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"row\">\n    <div class=\"col-3\"><label>"
    + alias2(((helper = (helper = lookupProperty(helpers,"getLimitLabel") || (depth0 != null ? lookupProperty(depth0,"getLimitLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getLimitLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":47}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":82}}}))
    + "</label></div>\n    \n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTrans") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":98}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBatch") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":92}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDaily") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":92}}})) != null ? stack1 : "")
    + "\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"list-container\"></div>";
},"useData":true});