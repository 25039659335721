import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useValidation } from '@glu/validation-react';
import store from 'system/utilities/cache';

import useNavigation from '../../hooks/useNavigation';
import useRequest from '../../hooks/useRequest';
import useLocale from '../../hooks/useLocale';
import useCache from '../../hooks/useCache';
import PageHeader from '../PageHeader/PageHeader';
import ScheduledExportSummaryForm from '../ScheduledExportSummaryForm/ScheduledExportSummaryForm';
import ScheduledExportDetailForm from '../ScheduledExportDetailForm/ScheduledExportDetailForm';
import Loader from '../Loader/Loader';
import LoadingPage from '../LoadingPage/LoadingPage';
import constants from '../../app/administration/constants';
import scheduleUtil from '../../app/scheduledExports/util';

const propTypes = {
    returnRoute: PropTypes.string.isRequired,
    mode: PropTypes.string,
    headerText: PropTypes.string,
    initialData: PropTypes.shape({}),
};

const defaultProps = {
    mode: constants.MODES.INSERT,
    headerText: '',
    initialData: {
        userGroup: [],
        userId: [],
        name: '',
        transmissionProfile: [],
        reportType: [],
    },
};

const STEP = {
    SUMMARY: 'SUMMARY',
    DETAILS: 'DETAILS',
};

const REPORT_TYPES_ENDPOINT = 'export/scheduledExport/getEntitledReportTypes';
const INSERT_ENDPOINT = 'export/scheduledExport/add';
const MODIFY_ENDPOINT = 'export/scheduledExport/update';

const { getLocaleString } = useLocale();

const CreateScheduledExport = ({
    returnRoute,
    mode,
    headerText,
    initialData,
}) => {
    const [defaultFormValues, setDefaultFormValues] = useState(initialData);
    const [ValidationProvider, validationValue, { data, validate }] = useValidation({
        data: defaultFormValues,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [formStep, setFormStep] = useState(mode === constants.MODES.INSERT
        ? STEP.SUMMARY : STEP.DETAILS);
    const [formIsValid, setFormIsValid] = useState(false);
    const [reportTypeOptions, setReportTypeOptions] = useState([]);
    const [transProfileOptions, setTransProfileOptions] = useState([]);

    const { cancel } = useNavigation({
        returnRoute: store.get('ScheduledExportsWidget:returnRoute') || returnRoute,
    });
    const { post } = useRequest();
    const { set: setAlertMessage } = useCache('export_scheduledExport-confirms');
    const { set: setAlertAction } = useCache('export_scheduledExport-alertMessage');
    const userGroup = data.userGroup.length ? data.userGroup[0] : {};
    const userId = data.userId.length ? data.userId[0] : {};

    useEffect(() => {
        const getReportTypes = post(REPORT_TYPES_ENDPOINT, {
            userGroup: userGroup.id,
            userId: userId.id,
        });
        const getTransProfs = scheduleUtil.getTransmissionProfiles(userGroup.id);
        Promise.all([
            getReportTypes,
            getTransProfs,
        ]).then(([reportTypes, transProfiles]) => {
            const flexData = reportTypes.reportTypeEntitlements.map(type => ({
                id: type.reportType,
                name: type.reportDescription,
            }));
            setReportTypeOptions(flexData);
            setTransProfileOptions(transProfiles);
            setIsLoading(false);
        });
    }, [data.userId]); // eslint-disable-line

    const onContinue = () => {
        setFormStep(STEP.DETAILS);
    };

    const validateForm = () => {
        validate(null, true).then(({ isValid }) => {
            setFormIsValid(isValid);
        });
    };

    const addSchedule = (modelData) => {
        setIsSaving(true);
        const postData = {
            ...modelData,
            reportType: data.reportType[0].id,
            transmissionProfileIdentifier: data.transmissionProfile.length
                ? data.transmissionProfile[0].id : null,
            userGroup: userGroup.id ? userGroup.id : '',
            userId: userId.id ? userId.id : '',

        };
        const url = (mode === constants.MODES.INSERT) ? INSERT_ENDPOINT : MODIFY_ENDPOINT;
        post(url, postData).then((response) => {
            setAlertMessage(response);
            setAlertAction('insert');
            cancel();
        });
    };
    return (
        <ValidationProvider value={validationValue}>
            <PageHeader headingText={headerText || getLocaleString('GIR.Create.Scheduled.Export')} />
            <Loader isLoading={isLoading} LoadingComponent={LoadingPage}>
                { formStep === STEP.SUMMARY ? (
                    <ScheduledExportSummaryForm
                        onContinue={onContinue}
                        validate={validate}
                        onInputBlur={validateForm}
                        formData={data}
                        transmissionProfiles={transProfileOptions}
                        reportTypes={reportTypeOptions}
                        formIsValid={formIsValid}
                        cancel={cancel}
                        updateForm={setDefaultFormValues}
                        initialData={initialData}
                    />
                ) : (
                    <ScheduledExportDetailForm
                        addSchedule={addSchedule}
                        formData={data}
                        cancel={cancel}
                        mode={mode}
                        reportTypes={reportTypeOptions}
                        transmissionProfiles={transProfileOptions}
                        isSaving={isSaving}
                    />
                )}
            </Loader>
        </ValidationProvider>
    );
};

CreateScheduledExport.propTypes = propTypes;
CreateScheduledExport.defaultProps = defaultProps;

export default CreateScheduledExport;
