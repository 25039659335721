import serverConfigParams from 'system/webseries/models/configurationParameters';
import MDFMultiAddLayoutView from 'app/multiAdd/views/mdfMultiAddLayoutView';
import dialog from '@glu/dialog';
import { log } from '@glu/core';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import DataAPI from 'common/dynamicPages/api/data';
import typeToggleConstants from 'common/uiWidgets/typeToggleWidget/typeToggleConstants';
import viewHelper from 'common/dynamicPages/api/viewHelper';
import RadioGroup from 'common/util/a11y/radioGroup';
import TypeToggleView from 'common/util/typeToggle/typeToggleView';
import store from 'system/utilities/cache';
import StopPaymentsMultiAddCollectionView from './stopPaymentsMultiAddCollectionView';
import OkCancelWarning from './okCancelWarning';
import stopPaymentsMultiAddViewTmpl from './stopPaymentsMultiAddView.hbs';

const StopPaymentsMultiAddView = MDFMultiAddLayoutView.extend({
    template: stopPaymentsMultiAddViewTmpl,

    regions: {
        typeToggleRegion: '.type-toggle-region',
    },

    initialize(options) {
        store.set('current-workspace-route', this.options.lastFragment);
        MDFMultiAddLayoutView.prototype.initialize.call(
            this,
            {
                ...options,
                collectionView: StopPaymentsMultiAddCollectionView,
            },
        );
    },
    isInitialRender: true,
    /**
     * @name loadRequiredData
     * @description creates a promise to retrieve the mdf model and the stoptype
     * toggle data.
     * When the promise is resolved, render the view
     */
    loadRequiredData() {
        DataAPI.model.generate({
            context: this.contextDef,
            state: 'insert',
            hideButtons: true,
            gridApi: {},
        }).then((model) => {
            // get the max number of items that can be added
            this.maxRows = model.jsonData.multiEntryMaxRows;

            this.baseModel = model;
            if (this.baseModel?.validators?.AMOUNT?.exists) {
                this.baseModel.addValidator('AMOUNT', {
                    ...this.baseModel.validators.AMOUNT,
                    isGreaterThan: 0,
                });
            }

            // add first item to the collection
            this.initialAddToCollection(this.deepCloneModel(model));

            // find the toggle field
            this.toggleFieldData = util.findWhere(
                model.fieldData,
                {
                    fieldUIType: 'STATETOGGLE',
                },
            );
            if (this.toggleFieldData) {
                // fetch stop type toggle data
                viewHelper.getPromisesForStateToggle(model).then((result) => {
                    if (result.queryResponse) {
                        this.stopTypeToggle = result.queryResponse.QueryData;
                    }
                    this.setHasLoadedRequiredData(true);
                    this.render();
                });
            } else {
                this.setHasLoadedRequiredData(true);
                this.render();
            }
        })
            .catch((err) => {
                log.error(err);
            });
    },

    /**
     * Get icon class name based on data from server
     * @param  {string} iconData Value to map to icon class
     * @return {string} Mapped icon class name
     */
    getIcon(iconData) {
        return typeToggleConstants.iconMap[iconData.trim().toUpperCase()];
    },

    /**
     * @name additionalRender
     * @description override of mdfMultiAddLayoutView function.  show the toggle view
     */
    additionalRender() {
        /*
         * there are cases when there is only 1 stop type
         * need to verify the toggle2 is valid
         */
        if (this.stopTypeToggle) {
            const icons = this.toggleFieldData.relatedProperty.split(',');
            const [toggle1, toggle2] = this.stopTypeToggle.queryRows;
            this.toggleValue1 = toggle1.mapDataList[0].value;
            this.toggleValue2 = (toggle2) ? toggle2.mapDataList[0].value : '';
            this.toggleLabel1 = toggle1.label;
            this.toggleLabel2 = (toggle2) ? toggle2.label : '';
            const buttons = util.map(this.stopTypeToggle.queryRows, (
                val,
                index,
            ) => ({
                toggleIcon: this.getIcon(icons[index]),
                toggleText: val.label,
                toggleValue: val.mapDataList[0].value,
                active: index === 0,
            }));
            this.activeView = new TypeToggleView({
                toggleDescription: this.toggleFieldData.fieldLabel,
                separatorText: locale.get('multiStop.toggle.separatorText'),
                toggleBtns: buttons,
            });
            this.typeToggleRegion.show(this.activeView);
            this.RadioGroup = new RadioGroup(document.getElementById('typeToggleRadioGroup'), this.activeView.startToggle, this.activeView);
        }

        // update current model with the selected type
        this.updateCurrentModelsWithStopType();

        this.listenTo(this.appBus, 'event:stateToggle', this.stopTypeHandler.bind(this));
    },

    cancel() {
        this.navigateTo(store.get('current-workspace-route'));
    },

    updateCurrentModelsWithStopType() {
        this.updateStopTypeInModel(
            this.collection.at(0),
            {
                value: this.toggleValue1,
                description: this.toggleLabel1,
            },
        );
        this.updateStopTypeInModel(
            this.baseModel,
            {
                value: this.toggleValue1,
                description: this.toggleLabel1,
            },
        );
    },

    /**
     * @name deepCloneModel
     * @description clones the mdf model so that each item view is associated
     * with a fresh model
     * removes the serialnum_to validator b/c that is specific for each model
     * @param model
     * @returns {* clone of mdf model}
     */
    deepCloneModel(model) {
        const newModel = MDFMultiAddLayoutView.prototype.deepCloneModel.call(this, model);

        /*
         * since the validator for serialnum_to changes, remove serialnum_to validator for
         * this new model
         */
        delete newModel.validators.SERIALNUM_TO;

        return newModel;
    },

    /**
     * @name getOtherStopType
     * @description returns the 'other' stop type for the stop type toggle.
     * for check the other stop type is pre-authorized debit; for pre-authorized
     * debit the other type is check
     * @param stopType
     * @returns {*}
     */
    getOtherStopType(stopType) {
        return (stopType === this.toggleValue1) ? this.toggleValue2 : this.toggleValue1;
    },

    /**
     * @name updateStopTypeInModel
     * @description updates the STOPTYPE and STOPTYPE_DESCRIPTION in the in coming model
     * @param model
     * @param stopInfo
     * @param silent
     */
    updateStopTypeInModel(model, stopInfo, silent) {
        const setInfo = {
            STOPTYPE: stopInfo.value,
            STOPTYPE_DESCRIPTION: stopInfo.description,
        };

        model.set(
            setInfo,
            {
                silent,
            },
        );
    },

    /**
     * @name stopTypeHandler
     * @description event handler called when the stop payment type toggle changed
     * @param {Object} newStop
     */
    stopTypeHandler(newStop) {
        const type = newStop.value;
        if (this.collection.models.length === 1 && this.modelIsEmpty(this.collection.at(0))) {
            this.changeToType(newStop);
            return;
        }

        const okCancelDialog = new OkCancelWarning({
            message: locale.get('cm.changeStopTypeWarning'),
            title: locale.get('cm.changeStopTypeTitle'),
            okLabel: locale.get('button.continue'),
        });

        // issue a warning message
        okCancelDialog.once('warning:ok', this.switchTypes.bind(this, newStop, okCancelDialog));
        okCancelDialog.once('warning:cancel', this.revertType.bind(this, type, okCancelDialog));
        dialog.custom(okCancelDialog);
    },

    /**
     * @name stopListeningOnDialog
     * @description remove listeners from okCancelDialog
     * @param {object} okCancelDialog object
     */
    stopListeningOnDialog(okCancelDialog) {
        okCancelDialog.off('warning:ok');
        okCancelDialog.off('warning:cancel');
    },

    /**
     * @name modelIsEmpty
     * @description checks in the model is empty (all attributes are blank)
     * @param {Model} model - itemView model to test
     * @returns {boolean}
     */
    modelIsEmpty(model) {
        return !(util.some(model.attributes, (attribute, key) => {
            if (key === 'STOPTYPE' || key === 'STOPTYPE_DESCRIPTION') {
                return false;
            }
            return !util.isEmpty(attribute);
        }));
    },

    /**
     * @name changeToType
     * @description switches STOPTYPE and STOPTYPE_DESCRIPTION in the base model
     * and in the first (and only) model in the
     * collection.  Triggers an event for the policy file to run
     * @param newStop
     */
    changeToType(newStop) {
        const firstModel = this.collection.at(0);
        this.updateStopTypeInModel(firstModel, newStop);
        this.updateStopTypeInModel(this.baseModel, newStop);
        this.updateUIForStopType(newStop.value);
        firstModel.trigger('widget:update', this);
    },

    /**
     * @name updateUIForStopType
     * @description updates the UI (preauthorized debit note) based on the stop type
     * @param {string} type
     */
    updateUIForStopType(type) {
        this.collectionView.children.first().$('.textline-field').toggleClass('hide', type === 'CHECK');
    },

    /**
     * @name switchTypes
     * @description switches types as per the new type from the toggle
     * clears the models in the collections and sets the base model and the first
     * (and only) model to contain the new type
     * @param {string} newStop
     * @param {View} okCancel - dialog view
     */
    switchTypes(newStop, okCancel) {
        this.clearAllModels();
        this.changeToType(newStop);
        this.stopListeningOnDialog(okCancel);
    },

    /**
     * @name clearAllModels
     * @description clears the first model and removes subsequent models from the
     * collection
     */
    clearAllModels() {
        // save off STOPTYPE & STOPTYPE_DESCRIPTION
        const firstModel = this.collection.at(0);
        firstModel.clear();
        this.collection.reset([firstModel]);
    },

    templateHelpers() {
        return {
            // Off by default, only turned on for certain customers in IDT
            enableStopPayNote: serverConfigParams.get('EnableStopPaymentNote') === 'true',
            title: locale.get(this.title),
            getTotalLabel: locale.get(this.totalLabel),
            getItemsLabel: locale.get('title.items'),
            buttons: () => this.buttonList.map((button, i) => {
                const lowerCaseAction = button.action.toLowerCase();
                return {
                    btnText: locale.get(`${this.localeKey.split('.')[0]}.button_${lowerCaseAction}`),
                    btnClass: ((i === 0) ? 'btn-primary' : 'btn-secondary'),
                    btnAction: lowerCaseAction,
                };
            }),
        };
    },

    /**
     * @name revertType
     * @description reverts back to the previous type if the user choose to cancel
     * the toggle
     * @param {string} type
     * @param {View} okCancel - dialog view
     */
    revertType(type, okCancel) {
        this.appBus.trigger(
            'event:updateStateToggle',
            {
                newType: this.getOtherStopType(type),
                stopPropagation: true,
                radioGroup: this.RadioGroup,
            },
        );
        this.stopListeningOnDialog(okCancel);
    },
});

export default StopPaymentsMultiAddView;
