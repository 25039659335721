var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"form-group col-4\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.User.Group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":50}}}))
    + "</label>\n                <p data-bind=\"model\" data-text=\"userGroup\"></p>\n            </div>\n            <div class=\"form-group col-4\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.User",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":44}}}))
    + "</label>\n                <p data-bind=\"model\" data-text=\"userId\"></p>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<fieldset class=\"section section-container\">\n    <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":36}}}))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"row\">\n        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Profile.Name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":19},"end":{"line":18,"column":48}}}))
    + "</label>\n            <p data-bind=\"model\" data-text=\"name\"></p>\n        </div>\n        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":19},"end":{"line":22,"column":47}}}))
    + "</label>\n            <p data-bind=\"model\" data-text=\"description\"></p>\n        </div>\n    </div>\n</fieldset>\n<fieldset class=\"section section-container\">\n    <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.SFTP.Login.Credentials",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":51}}}))
    + "</legend>\n    <div class=\"row\">\n        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.User.ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":19},"end":{"line":31,"column":43}}}))
    + "</label>\n            <p data-bind=\"model\" data-text=\"siteUserId\"></p>\n        </div>\n        <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Link",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":19},"end":{"line":35,"column":40}}}))
    + "</label>\n            <p data-bind=\"model\" data-text=\"siteUrl\"></p>\n        </div>\n    </div>\n    <div class=\"row\">\n         <div class=\"form-group col-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Port",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":19},"end":{"line":41,"column":40}}}))
    + "</label>\n            <p data-bind=\"model\" data-text=\"port\"></p>\n        </div>\n    </div>\n    <div class=\"row\">\n         <div class=\"form-group col-3\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Temporary.Password",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":19},"end":{"line":47,"column":54}}}))
    + "</label>\n            <input type=\"password\"\n                class=\"form-control\" \n                data-bind=\"model\"\n                name=\"sitePassword\"\n                aria-live=\"polite\"\n                data-hook=\"getPassword\"/>\n        </div>\n        <div class=\"form-group col-3\">\n            <input type=\"checkbox\"\n                class=\"ScheduledExports-password-toggle\"\n                id=\"pwToggle\"\n                name=\"pwToggle\"\n                data-hook=\"getPwToggle\"/>\n		    <label for=\"pwToggle\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Show.Password",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":61,"column":59}}}))
    + "</label>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-group col-6\">\n            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Temporary.Password.Information",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":66,"column":15},"end":{"line":66,"column":63}}}))
    + "</p>\n        </div>\n    </div>\n</fieldset>\n<fieldset class=\"section section-container\">\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <button class=\"btn btn-secondary\" type=\"button\" data-action=\"print\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":80},"end":{"line":73,"column":105}}}))
    + "</button>\n            <button class=\"btn btn-secondary\" type=\"button\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":81},"end":{"line":74,"column":105}}}))
    + "</button>\n        </div>\n    </div>\n</fieldset>\n";
},"usePartial":true,"useData":true});