import accountManagementPrivilegesMap from './accountManagementPrivilegesController';
import contactOptionsPrivilegesMap from './contactOptionsPrivilegesController';

const { view: canView, manage: canManage } = accountManagementPrivilegesMap;
const {
    view: canViewContactOptions,
    manage: canManageContactOptions,
} = contactOptionsPrivilegesMap;

const UNUSED_FALSE = false;
const ALWAYS_TRUE = true;

export const bankAccountDocumentManager = {
    unmask: ALWAYS_TRUE,
};

export const paymentDetails = {
    actionPayments: true,

    releaseFiles: {
        onAccount: true,
    },

    viewApprovalHistory: true,
    viewCheckPrintDetails: true,
    viewFeeDetails: true,
    viewFXDetails: true,
    viewPaymentAuthorization: true,
    viewPaymentDetailPanel: true,
    viewPaymentSummary: true,
    viewRemittanceInfo: true,
    viewRemittanceDetails: true,
};

// the privileges that has false/true as value should stay since they are rather not relevant
// for DGB use or will break the flow with opposite value
export const accountManagement = {
    bankAccounts: {
        authenticate: UNUSED_FALSE,
        create: canManage,
        delete: canManage,
        edit: canManage,
        manageDocuments: canManage,
        unmask: canManage,
        viewAuditHistory: UNUSED_FALSE,
        viewAuthentication: canView,
        viewCheckPrint: canView,
        viewDocumentUpload: ALWAYS_TRUE,
        viewInternalFields: UNUSED_FALSE,
        viewBankAccountMethod: canManage,
        viewReport: canView,
    },

    paymodeAccounts: {
        addBankAccount: {
            onAccount: canManage,
        },

        addUser: {
            onAccount: canManage,
        },

        create: canManage,
        createMultiple: canManage,

        delete: {
            onAccount: canManage,
        },

        edit: canManage,
        editIntegration: canManage,

        editPrivileges: {
            onAccount: UNUSED_FALSE,
        },

        editSiteLimits: canManage,
        manageFavorites: UNUSED_FALSE,
        managePricingAndFees: canManage,
        searchCompanies: canManage,

        // suspend action should not appear in dgb
        suspend: UNUSED_FALSE,

        undelete: UNUSED_FALSE,
        viewActions: canView,
        viewInternalFields: UNUSED_FALSE,
        viewInternalLinks: UNUSED_FALSE,
        viewNetworkFeeInfo: canView,
        viewPaymentType: canView,
        viewTranslationCodes: canView,
    },

    simplifiedAccounts: {
        create: canManage,
        edit: canManage,
        editPrivileges: () => UNUSED_FALSE,
        viewUsers: UNUSED_FALSE,
        delete: canManage,
        undelete: UNUSED_FALSE,

        // suspend action should not appear in DGB
        suspend: UNUSED_FALSE,

        isPayer: ALWAYS_TRUE,
    },

    users: {
        delete: canManage,
        edit: canManage,
        viewNotifications: UNUSED_FALSE,
        viewAnotherUserData: canView,
        isSameUser: ALWAYS_TRUE,
    },

    viewMemberServicesLinks: canView,
    viewMembershipType: canView,
    viewQuickLinks: UNUSED_FALSE,
    viewPropertyManagement: canView,
    newDocumentUpload: ALWAYS_TRUE,
};

export const contactOptions = {
    canViewContactOptions,
    canEditContactOptions: canManageContactOptions,
};

export const reports = {
    bankAccountReconciliation: {
        // Yes, privileges definition for BankAccount is reused for
        // Reconciliation Report for masking icon.
        // TODO - rework using typeCode PMXRPT
        unmask: canManage,
    },
};

export default {
    bankAccountDocumentManager,
    paymentDetails,
    accountManagement,
    contactOptions,
    reports,
};
