import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import addProfileTmpl from './addProfile.hbs';

const AddProfileModalView = ItemView.extend({
    template: addProfileTmpl,

    initialize(options) {
        this.model = options.model;
    },

    getHeader() {
        return locale.get('RPT.addProfileTitle');
    },

    getButtons() {
        const self = this;
        return [{
            text: locale.get('button.save'),
            className: 'btn btn-primary',

            callback() {
                self.trigger('report:profile:save');
                dialog.close();
            },
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',

            callback() {
                dialog.close();
            },
        }];
    },

    templateHelpers() {
        return {
            isAdmin: this.options.isAdmin,
        };
    },
});

export default AddProfileModalView;
