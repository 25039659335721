var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"badge\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"forecastAlerts") || (depth0 != null ? lookupProperty(depth0,"forecastAlerts") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"forecastAlerts","hash":{},"data":data,"loc":{"start":{"line":10,"column":44},"end":{"line":10,"column":62}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\" class=\"btn btn-secondary btn-line-settings\">\n                <span class=\"icon-settings\"></span>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.scenario.plural",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":53}}}))
    + "\n        </button>\n\n        <button type=\"button\" class=\"btn btn-secondary btn-chart-settings\">\n                <span class=\"icon-settings\"></span>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.account.plural",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":52}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"forecastAlerts") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "        </button>\n\n        <button type=\"button\" class=\"btn btn-secondary btn-plan-settings\">\n                <span class=\"icon-settings\"></span>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.plans",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":43}}}))
    + "\n        </button>\n";
},"useData":true});