import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import GroupPlanItemModel from '../groupplan/groupPlanItemModel';

const SubPlanTable = Collection.extend({
    initialize() {},
    model: GroupPlanItemModel,

    parse(res) {
        if (util.isUndefined(res)) {
            return [];
        }

        return res.items;
    },

    toServerJSON() {
        const json = {
            cashFlowGroupPlanLines: this,
        };

        return json;
    },
});

export default SubPlanTable;
