import basicLayout from 'no-override!@glu/scheduler/src/recurrence/monthly/monthlyChildViews/basic';
import template from './basic.hbs';

export default basicLayout.extend({
    template,
    modelEvents: {
        'change:recurrence.intervalType': 'changeRecurrenceSelector',
        'change:intervalType': 'changeRecurrenceSelector',
    },

    /**
     * Show the date or interval dropdown based on user choice of specific DAY
     * or generic weekday
     */
    changeRecurrenceSelector() {
        if (this.model.get('intervalType') !== 'DAY') {
            this.showWorkDaySelector();
        } else {
            this.showDaySelector();
        }

    },

    templateHelpers: function() {
        return {
            //reads showWeekends option from scheduler model
            showWeekends: this.model.get('showWeekends')
        };
    },
});
