import Layout from '@glu/core/src/layout';

const PanelView = Layout.extend({
    collapsed: true,

    initialize(options) {
        Layout.prototype.initialize.call(this, options);
        this.collapsed = options.collapsed || this.collapsed;
    },

    events: {
        'click .panel-title [data-toggle]': 'handleTriggerClick',
    },

    handleTriggerClick() {
        this.collapsed = !this.collapsed;
    },

    templateHelpers() {
        return {
            collapsed: this.collapsed,
            cid: this.cid,
            fieldTypeData: this.fieldTypeData,
        };
    },
});

export default PanelView;
