import Layout from '@glu/core/src/layout';
import PageApi from 'common/dynamicPages/api/view';
import Constants from 'common/dynamicPages/api/constants';
import ContextApi from 'common/dynamicPages/api/context';
import showCaseTmpl from './showCase.hbs';

export default Layout.extend({
    template: showCaseTmpl,

    onRender() {
        const context = ContextApi.menuContext.getContext(Constants.SHOWCASE_MENU_ITEM);

        const options = {
            context,
        };

        this.content.show(PageApi.page.get(options));
    },
});
