import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import helper from 'system/mfa/helper';

export default Model.extend({
    initialize(options) {
        this.targetId = options.targetId;
    },

    sync(method, model, options) {
        const service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('checkMobileMFAChallengeState')}`);

        const data = {
            claimCheck: this.claimCheck,
        };

        http.post(service, data, (response) => {
            options.success(response);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const obj = {
            result: false,
        };
        if (response.respHeader) {
            if (response.respHeader.message && response.respHeader.message.length > 0) {
                [obj.message] = response.respHeader.message;
            }
            obj.errorCode = response.respHeader.errorCode;
            obj.result = response.respHeader.result;
        }
        return obj;
    },
});
