import LayoutView from '@glu/core/src/layout';
import CollectionView from '@glu/core/src/collectionView';
import alert from '@glu/alerts';
import { appBus } from '@glu/core';
import $ from 'jquery';
import LiveMaintenanceUserMessageCollection from 'system/liveMaintenance/collections/liveMaintenanceUserMessageCollection';
import LiveMaintenanceUserMessageView from './liveMaintenanceUserMessageView';
import LiveMaintenanceUserMessageNavView from './liveMaintenanceUserMessageNavView';
import liveMaintenanceUserViewTmpl from './liveMaintenanceUserView.hbs';

export default LayoutView.extend({
    template: liveMaintenanceUserViewTmpl,

    regions: {
        messagesRegion: '.messages-region',
        messagesNavRegion: '.messages-nav-region',
        alertRegion: '.alert-region',
    },

    ui: {
        $messageSections: '[data-hook="getMessageSection"]',
    },

    events: {
        'click [data-hook="getLanguageBtn"]': 'setLanguageView',
    },

    appEvents: {
        'liveMaintenance.getDataError': 'getDataError',
        'liveMaintenance.toggleMessageView': 'setActiveMode',
    },

    initialize() {
        this.setupCollection();
    },

    onRender() {
        // remove class of login screen to eliminate the top padding
        $('body').removeClass('login-screen');

        this.messagesRegion.show(this.liveMaintenanceUserMessageCollectionView);
        this.messagesNavRegion.show(this.liveMaintenanceUserMessageNavCollectionView);
    },

    setupCollection() {
        // set collection in this for easy access in other functions
        this.liveMaintenanceUserMessageCollection = new LiveMaintenanceUserMessageCollection();

        this.liveMaintenanceUserMessageCollection.sync('read');

        this.createCollectionViews();
    },

    createCollectionViews() {
        // Create collection view
        this.liveMaintenanceUserMessageCollectionView = new CollectionView({
            collection: this.liveMaintenanceUserMessageCollection,
            itemView: LiveMaintenanceUserMessageView,
        });

        this.liveMaintenanceUserMessageNavCollectionView = new CollectionView({
            collection: this.liveMaintenanceUserMessageCollection,
            itemView: LiveMaintenanceUserMessageNavView,
        });
    },

    /**
     * Show error message on failure of service to retrieve data from backend
     */
    getDataError() {
        this.alertRegion.show(alert.negative('There was a problem loading your data. Refreshing the page may solve this problem.'));
    },

    /**
     * Triggers event for model views
     * @param  {string} cid
     */
    setActiveMode(cid) {
        appBus.trigger('liveMaintenance.setActiveMode', cid);
    },
});
