import Layout from '@glu/core/src/layout';
import viewTOALocationsDropdownTmpl from 'app/balanceAndTransaction/views/viewTOALocationsDropdown.hbs';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import AvailableTOALocationsModel from 'app/balanceAndTransaction/models/availableTOALocations';

const ViewTOALocationsDropdown = Layout.extend({
    template: viewTOALocationsDropdownTmpl,

    ui: {
        $locationSelect: '[data-hook="location-select"]',
    },

    events: {
        'change @ui.$locationSelect': 'onViewLocationSelection',
    },

    initialize(options) {
        // Passed in options that will interact with the affected Editable Grid
        this.locationSelectCallback = options.locationSelectCallback;
        this.initialTOALocation = options.initialTOALocation;

        // Setup up model to collect other available accounts to show on this page
        this.availableLocationsModel = new AvailableTOALocationsModel();
        this.listenTo(this.availableLocationsModel, 'change', this.setupViewAccountDropdown);
        this.locations = [];
    },

    onRender() {
        if (!this.dropDownSetup) {
            this.availableLocationsModel.fetch();
        } else {
            this.setupViewAccountDropdown();
        }
    },

    // Essential setup for dropdown
    setupViewAccountDropdown() {
        const locationsData = this.availableLocationsModel.get('locations');
        // only create dropdown if account data contains more than 1 account
        if (locationsData.length) {
            let currentAcctIndex;
            if (locationsData.length === 1) {
                currentAcctIndex = 0;
            } else {
                currentAcctIndex = this.findIndexOfCurrentTOALocation(locationsData);
            }
            const availableLocations = this.aggregateViewLocationsData(locationsData);
            const lookup = this.availableLocationsModel;

            const accountOptions = {
                dropdownAutoWidth: true,
                triggerChange: true,

                query: util.debounce((query) => {
                    if (query.page === 1) {
                        this.locations = [];
                    }
                    const rowsPerPage = constants.COMBO_MIN_SIZE;
                    const startRow = (((query.page - 1) * rowsPerPage) + 1);
                    lookup.setSearch(query.term);
                    lookup.startRow = startRow;
                    lookup.send().then((result) => {
                        this.dropDownSetup = true;
                        const rowHashes = this.availableLocationsModel.parse(result);
                        this.lookupLocations = rowHashes.locations;
                        const results = this.aggregateViewLocationsData(this.lookupLocations);
                        this.locations = this.locations.concat(rowHashes.locations);
                        query.callback({
                            results,
                            more: ((query.page * rowsPerPage) < result.totalRows),
                        });
                    });
                }, constants.COMBO_DEBOUNCE),

                initSelection(element, cb) {
                    const { text } = availableLocations[currentAcctIndex];
                    if (text) {
                        cb({
                            id: element.val(),
                            text,
                        });
                    } else {
                        cb();
                    }
                },
            };
            this.ui.$locationSelect.comboBox(accountOptions);
            this.locationSelectCallback(locationsData[currentAcctIndex]);
        }
    },

    /**
     * @method findIndexOfCurrentTOALocation
     * @param {Object[]} locations
     * @return {number} index
     */
    findIndexOfCurrentTOALocation(locations) {
        const currentTOALocation = this.initialTOALocation || null;
        if (currentTOALocation) {
            return util.indexOf(locations, util.findWhere(
                locations,
                {
                    LOCATIONNUMBER: currentTOALocation.LOCATIONNUMBER,
                },
            ));
        }
        // Just select the first TOA location from the list
        return 0;
    },

    /**
     * @method aggregateViewLocationsData
     * @param {array} locations
     * @return {array} formatted dropdown data array containing dropdown options
     * with labels
     */
    aggregateViewLocationsData(locations) {
        return util.map(locations, (
            item,
            i,
        ) => ({
            id: this.locations.length + i,
            text: item.LOCATION,
        }));
    },

    /**
     * @method onViewLocationSelection
     * @param {Event} e - change event
     * @return {*} result of the account callback
     * Will get account selected and call parent's account selection callback with the account.
     */
    onViewLocationSelection(e) {
        this.acctSelected = this.locations[e.val];
        return this.locationSelectCallback(this.acctSelected);
    },
});

export default ViewTOALocationsDropdown;
