var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog\">\n    <form class=\"modal-content\" data-submit=\"save\">\n        <div class=\"modal-header\">\n            <h2 class=\"modal-title\">\n                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Request.Additional.Information",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":80}}}))
    + "</span>\n            </h2>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"form-group required\">\n                <div data-region=\"noteInputRegion\"></div>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\"\n                    data-hook=\"getAddButton\"\n                    class=\"btn btn-primary\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":39}}}))
    + "\n            </button>\n            <button type=\"button\"\n                    class=\"btn btn-secondary\"\n                    data-hook=\"getCancelButton\"\n                    data-dismiss=\"modal\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":42}}}))
    + "\n            </button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});