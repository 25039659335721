import Layout from '@glu/core/src/layout';
import GridApi from 'common/dynamicPages/api/grid';
import workspaceHelper from 'common/workspaces/api/helper';
import ListView from 'common/dynamicPages/views/workflow/list';
import PrintExportUtil from 'common/util/printExportUtil';
import store from 'system/utilities/cache';
import Totals from 'app/reports/views/lockbox/lockboxAvailabilityTotals';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './lockboxAvailability.hbs';

const LockboxAvailability = Layout.extend({
    template,

    ui: {
        $cancel: '[data-hook="cancel"]',
        $print: '[data-hook="print-button"]',
        $export: '[data-hook="export-button"]',
    },

    events: {
        'click @ui.$cancel': 'cancel',
        'click @ui.$print': 'print',
        'click @ui.$export': 'export',
    },

    initialize() {
        this.isAccountDetails = this.options.isAccountDetails || false;
    },

    onRender() {
        const grid = store.get('lockboxAvailability:account');

        const options = {
            context: {
                serviceName: `/lockboxAvailability/${this.isAccountDetails ? 'getDetails' : 'getSummary'}`,
                productCode: 'GIR',
                functionCode: 'LBXAVAIL',
                typeCode: (this.isAccountDetails ? 'LBXADTLS' : 'LBX_AVL'),
            },

            enableSavedViews: true,
            selector: 'none',
            hideGridActionButtons: true,
            suppressServicesUrlSuffix: true,
            enableRowContextButtonCallbacks: true,
        };

        if (this.isAccountDetails) {
            options.additionalSearchFields = [{
                dataType: 'text',
                fieldName: 'ACCOUNTFILTER',
                fieldValue: [grid.model.get('ACCOUNTFILTER')],
                operator: 'CONTAINS',
            }];
            options.loadedCallback = () => { this.addPostDateFilter(); };
        }

        this.gridModelFromStore = grid;
        this.gridView = GridApi.createServiceGridView(options);
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        this.listenTo(this.gridView.getRows(), 'sync', this.renderGridTotals);
        this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
        if (mobileUtil.isMobileGridEnabled()) {
            let MobileList = ListView;
            /**
             * NH-165324
             * Attaching this view as parentView in options to the MobileList view.
             */
            const mobileOptions = {
                ...(this.mobileListViewOptions ? this.mobileListViewOptions : {}),
                parentView: this,
            };
            const mobileList = configureMobileInterface(
                MobileList,
                mobileOptions,
            );
            MobileList = MobileList.extend(mobileList);
            this.gridRegion.show(new MobileList({
                ...this.options,
                ...options,
                renderMobileGridUsingTemplate: false,
                prebuiltOptions: true,
                gridView: this.gridView,
                skipEntitlements: true,
                enableSavedViews: false,
            }));
        } else {
            this.gridRegion.show(this.gridView);
        }
    },

    /**
     * add the post date filter as a UI element the user can see/delete/modify
     * has to be called after this.gridView.grid exists
     */
    addPostDateFilter() {
        const matchesPostDate = elemnt => elemnt.fieldName && elemnt.fieldName === 'POST_DATE';
        const postDateLabel = this.gridView.grid.columns.jsonData.rowHeader
            .find(matchesPostDate)
            .displayName;
        const selectedPostDate = this.gridModelFromStore.model.get('POST_DATE');

        this.gridView.grid.filterProc.addFilter({
            type: 'date',
            field: 'POST_DATE',
            value: selectedPostDate,
            label: `${postDateLabel} = ${selectedPostDate}`,
        });
    },
    renderGridTotals() {
        this.totalsView = new Totals({
            isAccountDetails: this.options.isAccountDetails || false,
            searchFields: this.gridView.wrapper.generateFiltersDataBlock(),
            viewId: this.gridView.wrapper.viewId,
        });
        this.lbxTotalsRegion.show(this.totalsView);
    },

    gridRowAction(options) {
        store.set('lockboxAvailability:account', options);
        this.navigateTo('REPORTING/lockboxAvailability/accountDetails');
        return Promise.resolve();
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.isAccountDetails ? 22498 : 22497,
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.isAccountDetails ? 22498 : 22497,
            format: 'CSV',
        };
        PrintExportUtil.export(options);
    },

    buildExportModel(options) {
        return ListView.prototype.buildExportModel.call(this, options);
    },

    cancel() {
        window.history.back();
    },

    templateHelpers() {
        return {
            showBackButton: this.isAccountDetails,

            title() {
                const summary = store.get('lockboxAvailability:account');
                return `${summary.model.get('ACCOUNT_NUM')} - ${summary.model.get('ACCOUNT_NAME')}`;
            },

            gridUtilityOptions: {
                hasRefresh: false,
            },
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'LBX_AVAILABLE_WIDGET',
    view: LockboxAvailability,
    options: {},
});

export default LockboxAvailability;
