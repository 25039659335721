import locale from '@glu/locale';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';

export default ({
    /**
     * @name createReasonRequestObj
     * @description creates the payload for getting the rfi reasons
     * for either Incorrect Reasons or missing reasons
     * @param {string} reason - reason code, either 'I' or 'M'
     */
    createReasonRequestObj(reason) {
        const requestObj = {
            queryCriteria: {
                action: {
                    productCode: 'RTP',
                    functionCode: 'MSGS',
                    typeCode: 'RTPREQ',
                    actionMode: 'SELECT',
                },
                subTypeCode: '*',
                fieldName: reason === 'I' ? 'INCORRECT_INFO_CODE' : 'MISSING_INFO_CODE',
                allowDuplicates: false,
            },
        };

        return requestObj;
    },

    /**
     * @name getDetailInfo
     * @description extracts out the payments details for display
     * @param {object} data - payment data
     * @param {string} currentTab - either 'submitted' or 'received'
     */
    getDetailInfo(data, currentTab) {
        const submitted = currentTab === 'submitted';

        return {
            label1: (submitted) ? 'RTP.INST.CRTRAN.*.BENE_NAME.label' : 'Payer',
            value1: (submitted) ? data.CMB_BENE_NAME : data.DEBTOR_NAME,
            date: (submitted) ? data.CMB_VALUE_DATE : data.INTERBANK_SETTLE_DATE,
            amount: (submitted) ? data.CMB_TRANSACTION_AMOUNT : data.TOTAL_INTERBANK_SETTLE_AMT,
            currency: (submitted) ? data.CMB_TRANSACTION_CURRENCY : data.TOTAL_INTERBANK_SETTLE_CCY,
        };
    },

    /**
     * @name getRFIItem
     * @description returns (part of the) payload to save a request for info
     * @param {object} param0 - data used to create the payload
     * @param {string} param0.reasonType - 'I' for incorrect; 'M' for missing
     * @param {string} param0.reasonCode - reason code for the request
     * @param {string} param0.message - request message
     * @param {string} param0.paymentId - payment id
     */
    getRFIItem({
        reasonType, reasonCode, message, paymentId,
    }) {
        const item = [{
            name: 'INCORRECT_MISSING_FLAG',
            value: reasonType,
        }, {
            name: 'INFO_CODE',
            value: reasonCode,
        }, {
            name: 'ADDITIONAL_INFO',
            value: message,
        }, {
            name: 'PAYMENT_ID',
            value: paymentId,
        }];

        return { item };
    },

    /**
     * @name getPAItem
     * @description returns the payload to save an RTP acknowledgement
     * @param {object} param0 - data used to create the payload
     * @param {string} param0.paymentId - payment id
     * @param {string} param0.message - acknowledgement message
     */
    getPAItem({ paymentId, message }) {
        return {
            item: [
                {
                    name: 'PAYMENT_ID',
                    value: paymentId,
                }, {
                    name: 'ADDITIONAL_INFO',
                    value: message,
                },
            ],
        };
    },

    /**
     * @name getResponseItem
     * @description returns the payload to save an RTP Response
     * @param {object} param0 - data used to create the payload
     * @param {string} param0.paymentId - payment id
     * @param {string} param0.message - response message
     */
    getResponseItem({ paymentId, message }) {
        const items = [{
            item: [
                {
                    name: 'PAYMENT_ID',
                    value: paymentId,
                },
                {
                    name: 'REMITTANCE_INFO',
                    value: message,
                },
            ],
        }];
        return {
            items,
        };
    },

    /**
     * @name getResponseItems
     * @description returns the payload to save one or more response to rfi
     * @param {object} responses - list of response objects
     * which contain the paymentId, message and sequence number
     */
    getResponseItems(responses) {
        const items = responses.map(resp => ({
            item: [{
                name: 'PAYMENT_ID',
                value: resp.paymentId,
            }, {
                name: 'REMITTANCE_INFO',
                value: resp.internalMessage,
            }, {
                name: 'SEQUENCE_NUM',
                value: resp.seqno,
            }, {
                name: 'CONVITEM_ID',
                value: resp.CONVITEM_ID,
            },
            ],
        }));
        return {
            items,
        };
    },

    /**
     * @name getRFIReason
     * @description returns the 'displayable' rfi reason based on the type and code
     * @param {string} code - reason code
     * @param {string} type - reason type ('I' or 'M')
     * @param {array} incorrectReasons - list of incorrect reasons
     * @param {array} missingReasons - list of missing reasons
     */
    getRFIReason(code, type, incorrectReasons, missingReasons) {
        let theReason;
        if (type === 'I') {
            // find reason in incorrectReasons
            theReason = incorrectReasons.find(reason => reason.name === code);
        } else {
            // find reason in missingReasons
            theReason = missingReasons.find(reason => reason.name === code);
        }
        return theReason.label;
    },

    /**
     * @name createConversationItems
     * @description transforms the list of current conversation items into a list for display
     * @param {array} conversationItems array of current conversation items
     * @param {array} incorrectReasons list of incorrect rfi reasons
     * @param {array} missingReasons list of missing rfi reasons
     */
    createConversationItems(conversationItems, incorrectReasons, missingReasons) {
        const models = conversationItems.map((item) => {
            const obj = transform.pairsToHash(item.item);
            const reasonType = locale.get(`rtp.requestForInfo.${obj.INCORRECT_MISSING_FLAG === 'I'
                ? 'incorrect' : 'missing'}`);
            const reason = (obj.INCORRECT_MISSING_FLAG)
                ? this.getRFIReason(
                    obj.INFO_CODE, obj.INCORRECT_MISSING_FLAG,
                    incorrectReasons, missingReasons,
                )
                : '';
            const defaultMessage = (obj.ITEM_TYPE === 'RESPONSE')
                ? locale.get('rtp.noRESPONSEMessageEntered')
                : locale.get('rtp.noREQUESTMessageEntered');
            const additionalInfo = util.unescape(obj.ADDITIONAL_INFO)
                                   || util.unescape(obj.REMITTANCE_INFO) || defaultMessage;

            const notDelivered = obj.MESSAGE_STATUS === 'type.RTPRFI.N'
                || obj.MESSAGE_STATUS === 'type.RTPRFI.RFI.N'
                || obj.MESSAGE_STATUS === 'type.RTPRFI.ACK.N';

            return {
                REQUESTFORINFO_REASON_FLAG: obj.INCORRECT_MISSING_FLAG,
                REQUESTFORINFO_REASON_TYPE: reasonType,
                REQUESTFORINFO_REASON_INFO_CODE: obj.INFO_CODE,
                REQUESTFORINFO_REASON: reason,
                USERGROUP: obj.USERGROUP || obj.PARENTUSERGROUP,
                USERID: obj.USERID,
                CONVITEM_ID: obj.CONVITEM_ID,
                CASE_ID: obj.CASE_ID,
                ASSIGNMENT_ID: obj.ASSIGNMENT_ID,
                TIMESTAMP: obj.ENTERED_TIMESTAMP,
                ITEM_TYPE: obj.ITEM_TYPE,
                IS_REQUEST: obj.ITEM_TYPE === 'REQUEST',
                IS_RESPONSE: obj.ITEM_TYPE === 'RESPONSE',
                IS_ACKNOWLEDGEMENT: obj.ITEM_TYPE === 'ACK',
                PAYMENT_ID: obj.PAYMENT_ID,
                ADDITIONAL_INFO: additionalInfo,
                SEQUENCE_NUM: obj.SEQUENCE_NUM, // TODO darkdeploy?
                NOT_DELIVERED: notDelivered,
            };
        });
        return models;
    },
});
