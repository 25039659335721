const Constants = {
    PAY_AUT_HIST: 'PAY_AUT_HIST',
    SUMMARY_URL: 'REPORTING/viewAutoPaymentsSummary',
    DETAIL_URL: 'REPORTING/viewAutoPaymentsSummary/details',
    SUMMARY_SERV: 'paymentAutomation/summary',
    DETAIL_SERV: 'paymentAutomation/detail',
    DNLD_SERV: 'paymentAutomation/download',
};

export default Constants;
