import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import $ from 'jquery';
import util from '@glu/core/src/util';
import services from 'services';
import GridApi from 'common/dynamicPages/api/grid';
import ListView from 'common/dynamicPages/views/workflow/list';
import entitlements from 'common/dynamicPages/api/entitlements';
import constants from 'common/dynamicPages/api/constants';
import helpPageUtil from 'common/util/helpPage';
import store from 'system/utilities/cache';
import TemplateGroupModel from 'app/payments/models/templateGroup';
import EditTemplateGroupView from 'app/payments/views/editTemplateGroup';
import ViewTemplateGroupView from 'app/payments/views/viewTemplateGroup';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import workspaceHelper from 'common/workspaces/api/helper';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import helpers from 'components/AsyncReporting/helpers';
import systemConfig from 'system/configuration';
import template from './manageTemplateGroups.hbs';

const ManageTemplateGroups = Layout.extend({
    template,
    loadingTemplate,

    regions: {
        gridRegion: 'div[data-region="gridRegion"]',
    },

    events: {
        'click [data-action="export-button"]': 'export',
        'click [data-action="print-button"]': 'print',
    },

    gridRowAction(options) {
        const action = options.action.toUpperCase();

        if (action === constants.ACTION_DELETE) {
            // do something here to delete
            this.deleteTemplateGroup(options.model);
        } else if (action === constants.ACTION_MODIFY) {
            this.editTemplateGroup(options.model);
        } else if (action === constants.ACTION_SELECT) {
            this.viewTemplateGroup(options.model);
        }
        return Promise.resolve();
    },

    templateHelpers() {
        return {
            hasInsertEntitlement: () => this.entitlements.INSERT,
            isMobileGridEnabled: () => mobileUtil.isMobileGridEnabled(),
        };
    },

    loadViewRequirements() {
        const context = {
            serviceName: '/tableMaintenance/PaymentTemplateGroups',
            actionMode: 'SELECT',
            productCode: 'PAY',
            functionCode: 'TMPLSET',
            typeCode: 'TMPLSET',
            gridId: 20495,
        };
        ListView.prototype.setListViewConfig.call(this, context);

        const options = {
            context,

            // default grid action buttons are hidden
            selector: 'none',

            // instead of executing context actions, grid handles callback
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,

            loadedCallback: () => {
                this.gridLoaded = true;
            },

            lvc: this.lvc,
        };
        const gridView = GridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'PAY',
            functionCode: 'TMPLSET',
            typeCode: 'TMPLSET',
            mode: 'SELECT',
        });

        Promise.all([entitlementPromise, helpPagePromise]).then((results) => {
            this.entitlements = results[0].actions;
            this.gridViewOptions = options;
            this.gridView = gridView;
            if (!util.isEmpty(results[1])) {
                store.set('helpPage', results[1].helpPage);
            }
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    addTemplateGroup() {
        const modalView = new EditTemplateGroupView({});
        this.openEditModal(modalView);
    },

    editTemplateGroup(model) {
        const modalView = new EditTemplateGroupView({
            name: model.get('NAME'),
            description: model.get('DESCRIPTION'),
        });
        this.openEditModal(modalView);
    },

    viewTemplateGroup(model) {
        const modalView = new ViewTemplateGroupView({
            name: model.get('NAME'),
            description: model.get('DESCRIPTION'),
            userGroup: model.get('USERGROUP'),
        });
        this.openViewModal(modalView);
    },

    deleteTemplateGroup(model) {
        const templateGroupModel = new TemplateGroupModel({
            // so isNew will be false
            id: model.get('NAME'),

            name: model.get('NAME'),
            description: model.get('DESCRIPTION'),
        });

        this.listenTo(
            templateGroupModel,
            {
                sync() {
                    this.stopListening(templateGroupModel);
                    this.refreshGrid();
                },
            },
            this,
        );

        /*
         * TODO should this prompt first?
         * TODO display message on success?
         */
        templateGroupModel.destroy();
    },

    export() {
        this.buildExportModel('CSV');
        this.doExport();
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
    },

    doExport() {
        if (!systemConfig.isAdmin()) {
            helpers.handleExportAsyncReport({
                service: services.runAsyncListViewReport,
                postData: this.exportModel,
            }, dialog);
            return;
        }
        if ($('#listViewExportForm').length === 0) {
            let f = `<form id="listViewExportForm" action="${services.generateUrl('/export/listView')}" method="get" target="_self">`;
            f += '<input id="listViewRequest" type="hidden" name="listViewRequest" ></form>';

            this.$el.append(f);
        }
        $('#listViewExportForm #listViewRequest').val(JSON.stringify(this.exportModel));
        $('#listViewExportForm').submit();
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
        });
        dialog.custom(printModal);
    },

    openViewModal(modalView) {
        this.listenTo(
            modalView,
            {
                templateGroupSaved: this.refreshGrid,

                close() {
                    this.stopListening(modalView);
                },
            },
            this,
        );
        dialog.open(modalView);
    },

    openEditModal(modalView) {
        this.listenTo(
            modalView,
            {
                templateGroupSaved: this.refreshGrid,

                close() {
                    this.stopListening(modalView);
                },
            },
            this,
        );
        dialog.open(modalView);
    },

    refreshGrid() {
        this.gridView.refreshGridData();
    },

    onRender() {
        moveToTopCheck(this.model);
        if (this.hasLoadedRequiredData()) {
            if (mobileUtil.isMobileGridEnabled()) {
                let MobileList = ManageTemplateGroups;
                const mobileList = configureMobileInterface(
                    MobileList,
                    {
                        insertActions: [
                            {
                                label: 'payment.templategroups.add',
                                entitlement: 'INSERT',
                                handlerMethodName: 'addTemplateGroup',
                            },
                        ],
                        useGridViewFromOptions: true,
                        entitlements: this.entitlements,
                    },
                );
                MobileList = MobileList.extend(mobileList);
                this.gridRegion.show(new MobileList({
                    ...this.options,
                    ...this.gridViewOptions,
                    prebuiltOptions: true,
                    gridView: this.gridView,
                }));
            } else {
                this.gridRegion.show(this.gridView);
                this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            }
        } else {
            this.loadViewRequirements();
        }
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },
});

export default ManageTemplateGroups;
