import locale from '@glu/locale';
import util from '@glu/core/src/util';
import services from 'services';
import { postData } from 'common/util/services';
import BaseRDCModel from './baseRDCModel';

export default BaseRDCModel.extend({

    defaults: {
        LOCATIONNAME: null,
        ACCOUNTNUM: null,
        AMOUNT: '0.00',
        MEMO: '',
        FRONT_IMAGE: null,
        BACK_IMAGE: null,
        BANKCODE: null,
    },

    initialize() {
        this.validators = {
            ACCOUNTNUM: {
                description: locale.get('loans.account'),
                exists: true,
            },
            AMOUNT: {
                description: locale.get('loans.amount'),
                exists: true,
                matches: '^(?!0([\\.,]0+)?$)[\\d,\\. ]+$', // Never allow 0.00
            },
            FRONT_IMAGE: {
                description: '',
                exists: true,
            },
            BACK_IMAGE: {
                description: '',
                exists: true,
            },
        };
    },

    /**
     * Add validation for LOCATIONNAME when using location workflow
     * @param {boolean} useLocationWorkflow
     */
    addLocationValidation(useLocationWorkflow) {
        if (useLocationWorkflow) {
            this.validators = util.extend(this.validators, {
                LOCATIONNAME: {
                    description: locale.get('rdc.user.location'),
                    exists: true,
                },
            });
        }
    },

    /**
     * Get the service URL for this collection
     * @returns {string}
     */
    getServiceURL() {
        return services.rdcSubmitDepositCheck;
    },

    /**
     * Insert model attributes into the data structure the expected by the REST endpoint
     * @param model
     * @returns {object}
     */
    getRequestData(model) {
        return {
            imageInfo: {
                imageAmount: model.get('AMOUNT'),
                imageAccount: {
                    accountNumber: model.get('ACCOUNTNUM'),
                },
                bankCode: model.get('BANKCODE'),
                imageDescription: model.get('MEMO'),
                locationName: model.get('LOCATIONNAME'),
            },
            frontImage: {
                value: model.get('FRONT_IMAGE'),
                contentType: 'image/jpeg',
            },
            backImage: {
                value: model.get('BACK_IMAGE'),
                contentType: 'image/jpeg',
            },
            batchStatus: model.get('batchStatus'),
            RdcSessionData: model.get('rdcSessionData'),
        };
    },

    /**
     * Check to see if the amount is not the default amount
     * @returns {boolean}
     */
    hasAmount() {
        return this.get('AMOUNT') !== this.defaults.AMOUNT;
    },

    /**
     * Evaluate the model attributes to determine if all of the data is
     * set for a check analysis
     * @param {boolean} useLocationWorkflow - is the appliation using location workflow
     * @returns {boolean}
     */
    hasRequiredAnalysisData(useLocationWorkflow) {
        let required = ['ACCOUNTNUM', 'FRONT_IMAGE', 'BACK_IMAGE'];
        if (useLocationWorkflow) {
            required = [
                ...required,
                'LOCATIONNAME',
            ];
        }
        return required.every(field => !util.isEmpty(this.get(field)));
    },

    postData(url) {
        return postData(
            services.generateUrl(url),
            this.getRequestData(this),
        );
    },

    /**
     * Post all check data to the submit deposit check endpoint
     * @returns {Promise}
     */
    save() {
        return this.postData('imaging/remoteDepositCapture/submitDepositCheck');
    },

    /**
     * Post all check data to the confirm deposit check endpoint
     * @returns {Promise}
     */
    confirm() {
        return this.postData('imaging/remoteDepositCapture/confirmDepositCheck');
    },

    /**
     * Post all check data to the update deposit check endpoint
     * @returns
     */
    update() {
        return this.postData('imaging/remoteDepositCapture/updateDepositCheck');
    },
});
