import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import recipientTmpl from './recipient.hbs';

export default ItemView.extend({
    initialize(options) {
        // Add event listeners
        this.events = {
            'click #recipientContinueButton': 'applyValidator',
        };

        this.isEditMode = options.isEditMode;

        if (this.isEditMode) {
            this.model = options.model;
        } else {
            this.model = options.model.clone();
        }

        this.originalModel = options.originalModel;
        this.country = options.country;
        this.accountType = options.accountType;
        this.recipientTitleKey = options.recipientTitleKey || 'sbPayments.recipientTitle';
        this.recipientNameKey = options.recipientNameKey || 'sbPayments.recipientName';
        this.recipientIdKey = options.recipientIdKey || 'sbPayments.recipientId';
        this.recipientBankInformationKey = options.recipientBankInformationKey || 'sbPayments.recipientBankInformation';
        this.saveRecipientKey = options.saveRecipientKey || 'sbPayments.saveRecipient';
    },

    type: 'info',
    template: recipientTmpl,
    className: 'modal',
    maxLength: 80,

    templateHelpers() {
        // var self = this;
        return {
            recipientName: locale.get(this.recipientNameKey),
            recipientId: locale.get(this.recipientIdKey),
            title: locale.get(this.recipientTitleKey),
            recipientBankInformation: locale.get(this.recipientBankInformationKey),
            saveRecipient: locale.get(this.saveRecipientKey),
            maxLength: this.maxLength,
            country: this.country,
            accountType: this.accountType,
        };
    },

    onRender() {
        const accountType = this.getAccountType();
        const country = this.getCountry();

        this.ui.$country.comboBox();

        if (!util.isEmpty(country)) {
            this.ui.$country.comboBox(
                'data',
                {
                    value: country.id,
                    text: country.description,
                },
            );
        }

        this.ui.$accountType.comboBox();

        if (!util.isEmpty(accountType)) {
            this.ui.$accountType.comboBox(
                'data',
                {
                    value: accountType.id,
                    text: accountType.description,
                },
            );
        }
    },

    ui: {
        $country: '[data-hook="country"]',
        $accountType: '[data-hook="accountType"]',
    },

    getCountry() {
        return util.findWhere(
            this.country,
            {
                id: this.model.get('country'),
            },
        );
    },

    getAccountType() {
        return util.findWhere(
            this.accountType,
            {
                id: this.model.get('accountType'),
            },
        );
    },

    save(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.model.isValid()) {
            this.trigger('save', this.model);
            this.close();
        }
    },

    exit(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.isEditMode) {
            this.model.set(this.originalModel.attributes);
            this.close();
        }
    },
});
