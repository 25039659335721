import Model from '@glu/core/src/nestedModel';
import util from '@glu/core/src/util';

export default Model.extend({

    defaults() {
        return {};
    },

    // merge grid data from full records
    mergeData(fullAttributes) {
        this.set(util.defaults(this.attributes, fullAttributes));
    },

    entitleAll() {
        util.each(this.attributes, (value) => {
            const valueParam = value;
            if (valueParam && Object.prototype.hasOwnProperty.call(valueParam, 'action') && valueParam.applicable) {
                valueParam.entitled = true;
            }
        });
    },

    enableActionsFromPermissions(applicableActionsArr) {
        let allActions = ['modify', 'manage', 'repair', 'approve'];

        util.each(applicableActionsArr, (action) => {
            this.set({
                [`${action}.applicable`]: true,
                [`${action}.entitled`]: true,
            });
            allActions = util.without(allActions, action);
        });

        util.each(allActions, (action) => {
            if (this.get(`${action}.entitled`)) {
                this.set({
                    [`${action}.entitled`]: false,
                    [`${action}.applicable`]: false,
                });
            }
        });
    },

    clearLimits() {
        const self = this;
        util.each(this.availableLimits, (limitName) => {
            self.set(limitName, null);
            self.set(`${limitName}AssignedLimit`, null);
        });
    },

    updateLimits(model) {
        const self = this;
        const limitsModel = model;

        const limitObj = {
            applicable: true,
        };

        util.each(this.collection.availableLimits, (limitName) => {
            const attributeName = `${limitName}Limit`;
            const currentLimitObj = self.get(attributeName);

            if (util.isEmpty(currentLimitObj)) {
                limitObj.assignedLimit = limitsModel.get(attributeName);
                self.set(attributeName, limitObj);
            } else {
                currentLimitObj.assignedLimit = limitsModel.get(attributeName);
            }

            self.set(`${attributeName}AssignedLimit`, limitsModel.get(attributeName));
        });
    },

    toJSON() {
        // temporary for incomplete server side code
        if (!this.collection.keyCol) {
            return this.attributes;
        }

        const keyVal = this.get(this.collection.keyCol.toUpperCase());

        // data not merged yet, just return attributes
        if (!keyVal) {
            return this.attributes;
        }

        let result;
        // hack for ach
        if (this.collection.keyCol === 'CLIENTCOMP_GROUPNUM') {
            result = {
                dataEntitlementAttPart1: this.get('COMPNAME'),
                dataEntitlementAttPart2: this.get('COMPANYID'),
                dataEntitlementAttPart3: this.get('CLEARINGSYSTEM'),
                dataEntitlementType: 'ACHCOMP',
            };
        } else {
            const { keyCol } = this.collection;
            // Account numbers may contain multiple hyphens. Split only the first
            const separator = (keyCol.toUpperCase().indexOf('ACCOUNTFILTER') > -1 || keyCol.toUpperCase().indexOf('BENE_ACCOUNTENTITLEMENT') > -1) ? /-(.+)/ : '-';
            const parts = keyVal.split(separator);

            if (keyCol.toUpperCase() === 'ACCOUNTFILTER' || keyCol.toUpperCase() === 'BENE_ACCOUNTENTITLEMENT') {
                // the split creates an unwanted empty string at the end of the array.
                parts.pop();
            }
            result = {
                dataEntitlementAttPart1: parts[0],
                dataEntitlementAttPart2: parts.length > 1 ? parts[1] : null,
                dataEntitlementAttPart3: parts.length > 2 ? parts[2] : null,
                dataEntitlementType: this.collection.configuredDataType,
            };
        }

        util.each(this.attributes, (value, key) => {
            if (value && Object.prototype.hasOwnProperty.call(value, 'applicable')) {
                result[key] = value;
            }
        });

        return result;
    },

    name() {
        return this.get('TEMPLATECODEENTITLEMENT');
    },
    description() {
        return this.get('CMB_TEMPLATE_DESCRIPTION');
    },
    paymentType() {
        return this.get('CMB_TYPE_DESCRIPTION');
    },
});
