var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"help-block\" id=\"error-message\" style=\"display: none; padding: 5px; border-style: solid; border-color: red; border-width: 2px;\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"pageBlocks") || (depth0 != null ? lookupProperty(depth0,"pageBlocks") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pageBlocks","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"pageFields") || (depth0 != null ? lookupProperty(depth0,"pageFields") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pageFields","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  \n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttonList") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"section section-container\">\n                <div class=\"section-header\">\n                    <h3>Actions</h3>\n                </div>\n                <div class=\"section-body\">\n                    <div class=\"row\">\n                        <div class=\"col-md-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSave") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":22,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDelete") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":56},"end":{"line":25,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApprove") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":28,"column":63}}})) != null ? stack1 : "")
    + "                            <button type=\"button\" data-action=\"cancel\" class=\"btn btn-secondary\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"administration.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":115},"end":{"line":29,"column":153}}}))
    + "</span></button>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <button type=\"button\" data-action=\"save\" class=\"btn btn-primary\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.save.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":115},"end":{"line":21,"column":151}}}))
    + "</span></button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <button type=\"button\" data-action=\"destroy\" class=\"btn btn-primary\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.delete.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":122},"end":{"line":24,"column":160}}}))
    + "</span></button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                <button type=\"button\" data-action=\"approve\" class=\"btn btn-primary\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.approve.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":150},"end":{"line":27,"column":189}}}))
    + "</span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBlocks") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":8,"column":8}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "")
    + "<div data-region=\"layover\" class=\"layover\"></div>\n";
},"useData":true});