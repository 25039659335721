var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset>\n        <legend class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"passcode.mobilephone.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":71}}}))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":58,"column":7}}})) != null ? stack1 : "")
    + "</fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"field-container-md\">\n        <label for=\"phone_number_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":40}}}) : helper)))
    + "\" class=\"main-label\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.mobilephone.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":61},"end":{"line":7,"column":100}}}))
    + "</label>\n        <span class=\"icon-info\"  data-toggle=\"popover\" data-trigger=\"hover\" data-placement=\"right\"\n              data-title=\"SMS\" data-content=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.textmessage.optout.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":45},"end":{"line":9,"column":89}}}))
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.textmessage.optout.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":89},"end":{"line":9,"column":133}}}))
    + "\"></span>\n\n        <input id=\"phone_number_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":39}}}) : helper)))
    + "\" type=\"tel\" name=\"contact\" class=\"phone form-control\" data-bind=\"model\"/>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"contact\"></span>\n    </div>\n\n    <div class=\"field-container-md send-notice button-align-bottom\">\n        <button type=\"button\" class=\"btn btn-primary btn-sm\"  data-action=\"sendNotification\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.sendCode.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":93},"end":{"line":16,"column":129}}}))
    + "</button>\n        <div class=\"addon-below button-align-bottom\">\n            "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.activate.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":50}}}))
    + "\n        </div>\n    </div>\n\n    <div class=\"field-container-xs receive-code button-align-bottom\">\n        <span class=\"loader icon-wrapper pull-left\"></span>\n        "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.textmessage.sendingCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":54}}}))
    + "\n    </div>\n    <div class=\"field-container-md receive-code\">\n        <label for=\"passcode_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":27,"column":29},"end":{"line":27,"column":36}}}) : helper)))
    + "\" >"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.enterCode.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":39},"end":{"line":27,"column":76}}}))
    + "</label>\n        <input id=\"passcode_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":28,"column":28},"end":{"line":28,"column":35}}}) : helper)))
    + "\" type=\"text\" name=\"passcode\" class=\"form-control \" data-bind=\"model\" />\n        <button type=\"button\" class=\"btn btn-primary submit btn-sm\"  data-action=\"activate\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.activate.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":92},"end":{"line":29,"column":128}}}))
    + "</button>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"passcode\"></span>\n        <div>\n            <span class=\"help-block activation-notice\"></span>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"resendCode\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.resendCode.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":84},"end":{"line":33,"column":122}}}))
    + "</button>\n        </div>\n    </div>\n\n</div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <div class=\"field-container-xs contact-readonly\">\n            <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"contact") || (depth0 != null ? lookupProperty(depth0,"contact") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"contact","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":31}}}) : helper)))
    + "</strong>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"delete\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.removefrom",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":80},"end":{"line":43,"column":110}}}))
    + "</button>\n        </div>\n        <div class=\"field-container-xs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"default") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div>\n            <span class=\"help-block activation-notice\"></span>\n        </div>\n    </div>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"textVisible":true,"className":"default-contact","locale":"PS.text.message.default","name":"tick-solid"},"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":47,"column":121}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"btn-tertiary contact-option","action":"setDefault","textVisible":true,"locale":"PS.text.message.make.default","name":"tick"},"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":49,"column":152}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"removeRecord") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":60,"column":11}}})) != null ? stack1 : "");
},"useData":true});