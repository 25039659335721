import ListView from 'common/dynamicPages/views/workflow/list';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import CombinedPaymentModal from 'app/smbPayments/views/modals/combinedPayment';
import actionFunction from 'app/payments/api/common';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import MaskToggleUtil from 'common/util/maskToggleUtil';
import ConversationView from './rtpConversationView';
import template from './rtpGridView.hbs';

const RtpGridView = ListView.extend({
    className: 'realTimePaymentsGrid',

    template,

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            // custom events for RTP grid view go here
        },
    ),

    initialize(options) {
        // Dynamic import to resolve circular references in MDF
        this.gridImportPromise = import('common/dynamicPages/api/grid').then(({ default: gridApi }) => {
            this.gridApi = gridApi;
        });

        this.options = options;
        this.isSmbUser = userInfo.isSmbUser();
        this.isSubmittedGrid = this.options.context === 'RTP_SUB_VIEW';

        const gridOptions = {
            startRow: 1,
            disableDrillDown: false,
            dataOnly: 0,
            searchFields: [],
        };

        ListView.prototype.initialize.call(this, util.extend({}, this.options, gridOptions));
        this.contextKey = this.isSmbUser ? 'payment_listView_smb' : 'payment_listView_corp';

        this.setupListeners();

        if (this.isSubmittedGrid) {
            this.shouldShowTotals = serverConfigParams.get('ShowPaymentListViewTotals') === 'true';
            ({ shouldShowMaskToggle: this.shouldShowMaskToggle, maskToggle: this.maskToggle }
                = MaskToggleUtil.initMaskToggle());
        }

        this.appBus.trigger('dgb:drawer:update', {
            showTab: false,
            showTabOnClose: false,
            shouldBeOpen: false,
            viewOptions: {
                allowDetail: true,
                keepAlive: false,
            },
        });
    },

    setupListeners() {
        // TODO are these 2 still needed??
        this.listenTo(this.appBus, 'rtp:startConversation', this.createConversationView);
        this.listenTo(this.appBus, 'rtp:continueConversation', this.createConversationView);

        // These listeners are only applicable to the submitted grid.
        if (this.isSubmittedGrid) {
            this.appBus.off('rtp:renderMessage');
            this.appBus.off('rtp:insertPayment');
            this.listenTo(this.appBus, 'rtp:renderMessage', this.renderAlertMessage);
            this.listenTo(this.appBus, 'rtp:insertPayment', this.insert);
        }

        this.listenTo(this.appBus, 'rtp:viewPayment', this.gridRowSelect);
        this.listenTo(this.appBus, 'rtp:conversationUpdated', this.updateGrid);
    },

    /**
     * Override listview onRender
     * We only need the submitted listview to display the confirmation messages
     */
    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.gridView.wrapper.entitlements = this.entitlements;
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            if (this.isSubmittedGrid) {
                this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
                store.set(`${this.contextKey}-alertMessage`, null);
                store.set(`${this.contextKey}-confirms`, null);
                if (this.shouldShowMaskToggle) {
                    MaskToggleUtil.showMaskToggle(this, {
                        name: 'maskTogglePayments',
                        htmlId: 'maskTogglePayments',
                        labelText: locale.get('common.maskBeneAcctNum'),
                        onChange: this.toggleMaskColumns.bind(this),
                        region: this.toggleSwitchOuterRegion,
                    });
                }
            }
        } else {
            if (this.options.context === 'RTP_SUB_VIEW') {
                this.options.gridOptions = { caption: locale.get('rtp.paymentsSubmitted') };
            } else if (this.options.context === 'RTP_REC_VIEW') {
                this.options.gridOptions = { caption: locale.get('rtp.paymentsReceived') };
            }
            this.loadViewRequirements();
        }
    },

    /**
     * handles select action from the RTP Workspace
     * @param {Object} options - Holds options for the payment being selected
     */
    gridRowSelect(options) {
        // check if the message_state to see if the drawer should show on the detail page
        if (options.model.get('MESSAGE_STATE') > 0) {
            let rtpConversationData = {
                shouldBeOpen: false,
                showTab: true,
                showTabOnClose: true,
                viewOptions: {
                    allowDetail: false,
                    keepAlive: true,
                },
            };
            if (!options.fromReact) {
                const currentTab = options.model.context.serviceName.indexOf('crtran') !== -1 ? 'submitted' : 'received';
                rtpConversationData = {
                    ...rtpConversationData,
                    view: ConversationView,
                    viewOptions: {
                        model: options.model,
                        conversationStarted: options.model.get('MESSAGE_STATE') > 1,
                        defaultOpen: false,
                        allowDetail: false,
                        currentTab,
                        keepAlive: true,
                    },
                };
            }
            this.appBus.trigger('dgb:drawer:update', rtpConversationData);
        }

        // The view page may need to communicate this to any pages launched by its buttons.
        const optionsParam = options;
        optionsParam.model.fromRTPWorkspace = true;

        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(this.isSmbUser ? 'payments/viewPayment' : 'PAYMENTS/viewPayment');
        return Promise.resolve();
    },

    /**
     * handles modify action from the RTP Workspace
     * @param {Object} options - Holds options for the payment being modified
     */
    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        // SMB RTP payments use a modal instead of the full corporate screen
        if (this.isSmbUser) {
            const paymentOptions = {
                model: options.model,
                fromRTPWorkspace: true,
                contextKey: this.contextKey,
                action: 'modify',
            };
            dialog.open(new CombinedPaymentModal(paymentOptions));
        } else {
            this.navigateTo('PAYMENTS/modifyPayment');
        }
        return Promise.resolve();
    },

    /**
     * handles repair action from the RTP Workspace
     * @param {Object} options - Holds options for the payment being repaired
     */
    gridRowRepair(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        // SMB RTP payments use a modal instead of the full corporate screen
        if (this.isSmbUser) {
            const paymentOptions = {
                model: options.model,
                fromRTPWorkspace: true,
                contextKey: this.contextKey,
                action: 'repair',
            };
            dialog.open(new CombinedPaymentModal(paymentOptions));
        } else {
            this.navigateTo('PAYMENTS/repairPayment');
        }
        return Promise.resolve();
    },

    /**
     * handles Copy as Payment action from the RTP Workspace
     * @param {Object} optionsParam - Holds options for the payment being copied
     */
    gridPaymentFromTemplate(optionsParam) {
        const options = optionsParam;

        // SMB RTP payments use a modal instead of the full corporate screen
        if (this.isSmbUser) {
            const paymentOptions = {
                action: 'copyinst',
                model: options.model,
                contextKey: this.contextKey,
                isCopyPayment: true,
                fromRTPWorkspace: true,
            };
            dialog.open(new CombinedPaymentModal(paymentOptions));
        } else {
            options.model.loadFromPayments = true;
            actionFunction.makePayment(options.model, this.contextKey);
            this.navigateTo('PAYMENTS/addPaymentFromTemplate');
        }
        return Promise.resolve();
    },
    /**
     * handles Copy as Template action from the RTP Workspace
     * @param {Object} optionsParam - Holds options for the payment being copied
     */
    gridTemplateFromPayment(optionsParam) {
        const options = optionsParam;
        options.model.fromRTPWorkspace = true;
        actionFunction.makeTemplate(options.model, this.contextKey);
        this.navigateTo('TEMPLATES/addTemplateFromPayment');
        return Promise.resolve();
    },

    createConversationView(options) {
        const conversationView = new ConversationView(options);
        dialog.custom(conversationView);
    },

    startConversation(options) {
        const conversationView = new ConversationView(options);
        dialog.custom(conversationView);
    },

    /**
     * handles insert action from the RTP Workspace
     */
    insert() {
        const context = {
            serviceName: '/payment/crtran',
            enableSaveDraft: true,
            isRtpMultiAdd: true,
            productCode: 'RTP',
        };
        const overrideContext = util.extend(this.contextDef, context);
        store.set('listView-contextKey', this.contextKey);
        store.set('multiAdd-contextKey', `${this.contextKey}-contextOverride`);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);

        // SMB uses a modal instead of the full corporate screen
        if (this.isSmbUser) {
            const options = {
                fromRTPWorkspace: true,
                contextKey: this.contextKey,
            };
            dialog.open(new CombinedPaymentModal(options));
        } else {
            this.navigateTo('PAYMENTS/rtpMultiAdd');
        }
    },

    /**
     * Renders a message above the grid within the RTP workspace.
     * @param {String} action - the action being performed (insert, modify etc..)
     * @param {Object} confirmResponse - response from the server containing action results
     */
    renderAlertMessage(action, confirmResponse) {
        this.gridView.refreshGridData();
        this.renderMessage(action, confirmResponse);
    },

    getButtons(parentHelpers) {
        return Object.entries({
            approve: 'hasApproveEntitlement',
            unapprove: 'hasUnapproveEntitlement',
            reject: 'hasRejectEntitlement',
            delete: 'hasDeleteEntitlement',
            restore: 'hasRestoreEntitlement',
        }).filter(([, value]) => parentHelpers[value])
            .map(([key]) => ({
                class: key,
                localeString: parentHelpers.getButtonString(key),
            }));
    },

    /**
     * @name updateGrid
     * @description refresh the grid so that it reflects any new message data
     */
    updateGrid(gridToUpdate) {
        if ((gridToUpdate === 'submitted' && this.isSubmittedGrid)
            || (gridToUpdate === 'received' && !this.isSubmittedGrid)) {
            this.refreshData();
        }
    },

    /**
     * @name buildExportModel
     * @description build export model for Print/Export of grid
     */
    buildExportModel(format) {
        // Get the print/export options
        const options = {
            //  the format object can be a complex object or contain plain format value
            format: format.format || format,
            gridView: this.gridView,
        };

        ListView.prototype.buildExportModel.call(this, options);
        this.exportModel.inquiryId = this.options.inquiryId;
        this.exportModel.columns = this.exportModel.columns.filter(column => (column !== 'MESSAGE_STATE'));
        return this.exportModel;
    },

    templateHelpers() {
        const parentHelpers = ListView.prototype.templateHelpers.call(this);
        return {
            ...parentHelpers,
            entitlements: this.getButtons(parentHelpers),
        };
    },
});

let list = RtpGridView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'rtp.insert_payment',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: [
            {
                label: 'payment.button_approve',
                entitlement: 'APPROVE',
            },
            {
                label: 'payment.button_unapprove',
                entitlement: 'UNAPPROVE',
            },
            {
                label: 'payment.button_reject',
                entitlement: 'REJECT',
            },
            {
                label: 'payment.button_delete',
                entitlement: 'DELETE',
            },
        ],
        additionalActions: [
            MaskToggleUtil.getMobileGridActions(),
        ],
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
