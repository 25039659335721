import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import template from './noteModal.hbs';
import NoteModel from '../models/noteModel';
import CharsRemainingInput from './charactersRemainingInput';
import constants from '../constants';

const NoteModalView = Layout.extend({
    template,

    attributes: {
        role: 'dialog',
        'aria-hidden': 'false',
        class: 'modal',
    },

    ui: {
        $addButton: '[data-hook="getAddButton"]',
    },

    events: {
        'click @ui.$addButton': 'addNote',
        'click [data-hook="getCancelButton"]': 'cancel',
    },

    initialize() {
        this.model = new NoteModel();
    },

    onRender() {
        this.noteInputRegion.show(new CharsRemainingInput({
            model: this.model,
            label: locale.get('serviceRequest.Note'),
            inputType: constants.type.textArea,
            name: 'note',
            maxLength: 300,
        }));
    },

    /**
     * When the model is valid, save the note
     */
    addNote() {
        if (this.model.isValid()) {
            this.ui.$addButton.attr('aria-busy', true);
            this.model.save({}, {
                tnum: this.options.tnum,
            }).then(this.saveSuccess.bind(this))
                .catch(this.saveError.bind(this));
        }
    },

    /**
     * Close the modal and trigger the note added event
     */
    saveSuccess() {
        this.close();
        this.trigger('note:added');
    },

    /**
     * Show a dialog with the error
     * @param {Object} error
     */
    saveError(error) {
        this.ui.$addButton.attr('aria-busy', false);
        dialog.error(error.responseJSON.message.join(' '));
    },

    /**
     * @name cancel
     * @description 'cancel' handler for warning dialog.
     */
    cancel() {
        this.close();
    },
});

export default NoteModalView;
