import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import LiveMaintenanceLanguageService from 'app/administration/service/liveMaintenanceLanguageService';

export default Model.extend({
    defaults: {
        LANGUAGE: '',
        MESSAGEHEADER: '',
        DETAILS: '',
        LANGUAGELABEL: '',
        editState: false,
        valid: true,
    },

    settings: {
        languages: '',
    },

    initialize() {
        LiveMaintenanceLanguageService.getLanguages()
            .then(this.setupLanguages.bind(this))
            .catch(this.handleLanguageServiceError.bind(this));
    },

    /**
     * Set model edit state whether being modified or not
     * @param  {boolean} editState Edit state passed from view
     */
    setEditState(editState) {
        this.set('editState', editState);
    },

    /**
     * Deletes the model linked to this view instance
     */
    deleteModel() {
        this.destroy();
    },

    /**
     * Clears the model field linked to this view instance
     */
    clearModel() {
        this.clear();
    },

    setupLanguages(languages) {
        this.settings.languages = languages;

        // handle success
        this.setLanguageLabel();
    },

    /**
     * Polls all languages to find and set language label
     */
    setLanguageLabel() {
        const currentLanguage = this.get('LANGUAGE');

        const matchingLanguage = util.find(
            this.settings.languages,
            language => language.name === currentLanguage,
        );

        if (matchingLanguage) {
            this.set('LANGUAGELABEL', matchingLanguage.label);
        }
    },

    handleLanguageServiceError() {
        appBus.trigger('liveMaintenance.languageServiceError');
    },

    /**
     * Tests if model has a value for three key elements as they are all required
     */
    validate() {
        // if any fields are empty then set valid to false
        this.set({
            valid: !!(this.get('LANGUAGE') && this.get('MESSAGEHEADER') && this.get('DETAILS')),
        });
    },
});
