import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import numeral from 'numeral';
import Formatter from 'system/utilities/format';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import accountUtil from 'app/smbPayments/util/accountUtil';
import balanceAndTransConstants from 'app/balanceAndTransaction/constants';

const getFormattedAmount = function (value) {
    return Formatter.formatCurrency(value);
};

const getFieldValue = function (arrData, fieldName) {
    const field = util.findWhere(
        arrData,
        {
            fieldName,
        },
    );
    return field ? field.fieldValue : '';
};

export default Model.extend({
    initialize(attributes, options) {
        const { columns } = options.account;
        const accountType = getFieldValue(columns, 'ACCOUNTTYPE');
        const subAccountType = getFieldValue(columns, 'SUBACCOUNT_TYPE')
            || getFieldValue(columns, 'ACCOUNTSUBTYPE');
        const availableBalanceField = accountUtil.getAvailableBalanceField(accountType);
        const availableBalance = getFieldValue(columns, availableBalanceField);
        const accountNumber = getFieldValue(columns, 'ACCOUNTNUMBER');
        const clientAccountName = getFieldValue(columns, 'CLIENTACCOUNTNAME');

        this.set({
            accountType,
            accountFilter: getFieldValue(columns, 'ACCOUNTFILTER'),
            accountNumber,
            accountName: getFieldValue(columns, 'ACCOUNTNAME'),
            clientAccountName,
            bankName: getFieldValue(columns, 'BANKNAME'),
            bankCode: getFieldValue(columns, 'BANKCODE'),
            currency: getFieldValue(columns, 'CURRENCYCODE'),
            type: clientAccountName,
            secureAccountNumber: accountNumber,
            amountAvailable: availableBalance,
            openingLedger: getFieldValue(columns, 'OPENINGLEDGER'),
            isRealTimeAccount: getFieldValue(columns, 'ISREALTIMEACCOUNT') === '1',
            isTieredLoan: getFieldValue(columns, 'NOTENUMBER') > 0,
            isTransferLinkEntitled: getFieldValue(columns, 'ACCT_ENTLD_TRANSFER') > 0 || getFieldValue(columns, 'ACCT_ENTLD_LOANDRAW') > 0 || getFieldValue(columns, 'ACCT_ENTLD_LOANPAY') > 0,
            tranType: getFieldValue(columns, 'TRANTYPE'),
            canTransfer: options.canTransfer && getFieldValue(columns, 'ACCT_ENTLD_TRANSFER') > 0,
            canLoanPay: options.canLoanPay && getFieldValue(columns, 'ACCT_ENTLD_LOANPAY') > 0,
            canLoanDraw: options.canLoanDraw && getFieldValue(columns, 'ACCT_ENTLD_LOANDRAW') > 0,
            sectionId: this.getSectionId(accountType),
            ...(this.shouldSendSubAccountType(accountType, options.updateMasterOnSubAccountEnabled)
                && { subAccountType }),
        });
    },

    /**
     * Will check whether we need to send SUBACCOUNT_TYPE in getlistview of transactions list view
     * @param {string} accountType
     * @param {boolean} updateMasterOnSubAccountEnabled
     * @return {boolean}
     */
    shouldSendSubAccountType(accountType, updateMasterOnSubAccountEnabled) {
        return (!accountUtil.isLoan(accountType)
            || (accountUtil.isLoan(accountType) && updateMasterOnSubAccountEnabled));
    },

    /**
     * Return section Id based on account type
     * @param {string} accountType
     * @return {string}
     */
    getSectionId(accountType) {
        if (accountUtil.isLoan(accountType)) {
            return balanceAndTransConstants.ACCOUNT_SUMMARY.SECTIONS.LOANS;
        }
        if (accountUtil.isCreditCardAccount(accountType)) {
            return balanceAndTransConstants.ACCOUNT_SUMMARY.SECTIONS.CREDIT_CARDS;
        }
        return balanceAndTransConstants.ACCOUNT_SUMMARY.SECTIONS.DEPOSITS;
    },

    getTrajectories() {
        let trajectoryAmount = 0;
        let balanceUnchanged = true;
        let showBalance = true;
        let balanceUp = false;

        // loan accounts do not have a trajectory
        if (accountUtil.isDeposit(this.get('accountType'))) {
            /*
             * If we have a negative balance get the absolute value to remove the minus
             * for display purposes we want to prepend the value w/ styling after, just as we do
             * with the + (tiles.less)
             */
            const smbDisplayBalance = util.isEmpty(applicationConfigParams.getValue('OFXEXP', 'DISPLAY_BALANCE')) ? '1' : applicationConfigParams.getValue('OFXEXP', 'DISPLAY_BALANCE');

            const amountAvailable = numeral().unformat(this.get('amountAvailable'));
            const openingLedger = numeral().unformat(this.get('openingLedger'));
            trajectoryAmount = getFormattedAmount(Math.abs(amountAvailable - openingLedger));
            balanceUnchanged = openingLedger === amountAvailable;
            balanceUp = openingLedger < amountAvailable;
            if (smbDisplayBalance === '0') {
                showBalance = false;
            }
        }

        return {
            trajectoryAmount,
            balanceUnchanged,
            balanceUp,
            showBalance,
        };
    },
});
