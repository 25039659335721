import util from '@glu/core/src/util';
import locale from '@glu/locale';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import PanelLayout from './validationPanel';
import personalInfoViewTmpl from './personalInfoView.hbs';

const PersonalInfoView = PanelLayout.extend({
    template: personalInfoViewTmpl,
    tagName: 'fieldset',
    rtpSelected: false,
    rendered: false,

    getWhiteList() {
        return ['BENE_BIRTH_COUNTRY', 'BENE_BIRTH_CITY', 'BENE_BIRTH_DATE'];
    },

    ui: {
        $beneBirthCountry: 'select[name="BENE_BIRTH_COUNTRY"]',
        $beneBirthCity: 'input[name="BENE_BIRTH_CITY"]',
        $beneBirthDate: 'input[name="BENE_BIRTH_DATE"]',
        completeIcon: '.complete-icon',
    },

    events: {
        'change @ui.$beneBirthCountry': 'toggleMandatoryFields',
        'change @ui.$beneBirthCity': 'toggleMandatoryFields',
        'change @ui.$beneBirthDate': 'toggleMandatoryFields',
    },

    onRender() {
        // Set the input mask for the Date Of Birth field. Preserve back slashes
        this.ui.$beneBirthDate.inputmask({
            mask: userInfo.getDateFormat().replace(/[^-, /]/g, '9'),
            placeholder: userInfo.getDateFormat(),
            onUnMask: (maskedValue) => {
                if (maskedValue !== userInfo.getDateFormat()) {
                    return maskedValue;
                }
                return '';
            },
        });

        if (this.model.get('BENE_BIRTH_DATE')) {
            const localdate = moment(this.model.get('BENE_BIRTH_DATE')).format(userInfo.getDateFormat());
            this.model.set('BENE_BIRTH_DATE', localdate);
        }

        this.toggleMandatoryFields();
    },

    toggleMandatoryFields() {
        const hasPersonalInfo = !!this.model.get('BENE_BIRTH_COUNTRY') || !!this.model.get('BENE_BIRTH_CITY') || !!this.model.get('BENE_BIRTH_DATE');

        this.ui.$beneBirthCountry.closest('.form-group').toggleClass('required', hasPersonalInfo);
        this.ui.$beneBirthCity.closest('.form-group').toggleClass('required', hasPersonalInfo);
        this.ui.$beneBirthDate.closest('.form-group').toggleClass('required', hasPersonalInfo);
        if (hasPersonalInfo) {
            this.model.addValidator(
                'BENE_BIRTH_COUNTRY',
                {
                    exists: true,
                    description: locale.get('bab.birthcountry'),
                },
            );
            this.model.addValidator(
                'BENE_BIRTH_CITY',
                {
                    exists: true,
                    description: locale.get('bab.birthcity'),
                },
                this.model.addValidator(
                    'BENE_BIRTH_DATE',
                    {
                        exists: true,
                        description: locale.get('bab.birthdate'),
                        matchesDatePattern: userInfo.getDateFormat(),
                    },
                ),
            );
        } else {
            this.model.removeValidator('BENE_BIRTH_COUNTRY');
            this.model.removeValidator('BENE_BIRTH_CITY');
            this.model.removeValidator('BENE_BIRTH_DATE');
        }
    },

    templateHelpers() {
        const helpers = PanelLayout.prototype.templateHelpers.call(this);
        return util.extend(
            helpers,
            {
                countries: this.options.countries.toJSON(),
                fieldTypeData: this.fieldTypeData,
            },
        );
    },
});

export default PersonalInfoView;
