var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row panel-workflow\">\n    <div class=\"col-2\">\n        <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":70}}})) != null ? stack1 : "")
    + "\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Workflow.Code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":19},"end":{"line":4,"column":62}}}))
    + "</label>\n            <input class=\"form-control\"\n                   data-bind=\"model\"\n                   name=\"panelProfileCode\"\n                   maxlength=\"8\"\n                   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":19},"end":{"line":9,"column":50}}})) != null ? stack1 : "")
    + "/>\n            <span class=\"help-block\"\n                data-bind=\"model\"\n                data-validate=\"panelProfileCode\"></span>\n        </div>\n    </div>\n    <div class=\"col-2\">\n        <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":31},"end":{"line":16,"column":70}}})) != null ? stack1 : "")
    + "\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":19},"end":{"line":17,"column":60}}}))
    + "</label>\n            <input class=\"form-control\"\n                   data-bind=\"model\"\n                   name=\"description\"\n                   maxlength=\"35\"\n                   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":19},"end":{"line":22,"column":50}}})) != null ? stack1 : "")
    + "/>\n            <span class=\"help-block\"\n              data-bind=\"model\"\n              data-validate=\"description\"></span>\n        </div>\n    </div>\n</div>\n<div data-region=\"tabRegion\"></div>\n";
},"useData":true});