import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import Constants from 'app/balanceAndTransaction/constants';
import { appBus } from '@glu/core';

// TODO: Move these to a constants file
const POLLING_INTERVAL = 5000;

const RealTimeTransactionPoller = Model.extend({
    defaults: {
        isAvailable: true,
        isRunning: false,
        hasError: false,
        accountFilter: null,
        requestId: null,
    },

    initialize(options) {
        const accountType = options.accountType.toUpperCase();
        this.startUrl = services.generateUrl(Constants
            .REAL_TIME_TRANSACTION_POLLER_SERVICES[accountType].START);
        this.statusUrl = services.generateUrl(Constants
            .REAL_TIME_TRANSACTION_POLLER_SERVICES[accountType].STATUS);

        util.bindAll(
            this,
            'ping',
            'reset',
            'allowRetryAfter',
            'onStatusCheckSuccess',
            'onStatusCheckError',
            'onResponseReceived',
            'isAvailable',
            'start',
        );
    },

    ping() {
        if (!this.get('requestId')) {
            return;
        }

        const self = this;
        const xhr = http.post(
            this.statusUrl,
            {
                requestId: this.get('requestId'),
            },
            this.onStatusCheckSuccess,
            this.onStatusCheckError,
        );

        xhr.always(() => {
            self.allowRetryAfter(+xhr.getResponseHeader('Retry-After'));
        });
    },

    allowRetryAfter(seconds) {
        clearTimeout(this.becomesAvailableTimeout);
        this.becomesAvailableTimeout = setTimeout(this.reset, seconds * 1000);
    },

    reset(hasError) {
        this.set({
            isAvailable: true,
            isRunning: false,
            hasError: !!hasError,
        });
    },

    onStatusCheckError() {
        this.set({
            hasError: true,
            isRunning: false,
        });
    },

    onResponseReceived() {
        this.set({
            isRunning: false,
            hasError: false,
        });
        this.trigger('responseReceived');
        appBus.trigger('realTimeTransactions:responseReceived');
    },

    onStatusCheckSuccess(data) {
        const status = (data.status || 'error').toLowerCase();

        if (status === 'error' || status === 'requesttimedout') {
            this.onStatusCheckError();
            return;
        }

        if (status === 'inprocess') {
            setTimeout(this.ping, POLLING_INTERVAL);
        }

        if (status === 'responsereceived') {
            this.onResponseReceived();
        }
    },

    isAvailable() {
        return this.get('isAvailable');
    },

    isRunning() {
        return this.get('isRunning');
    },

    hasError() {
        return this.get('hasError');
    },

    start() {
        if (!this.isAvailable()) {
            return;
        }

        this.set({
            isAvailable: false,
            isRunning: true,
            hasError: false,
        });

        const searchFields = [];
        searchFields.push({
            fieldName: 'ACCOUNTFILTER',
            fieldValue: [this.get('accountFilter')],
            dataType: 'text',
            operator: '=',
        });

        const subAccountNumbers = this.get('subAccountNumber');
        if (subAccountNumbers) {
            searchFields.push({
                fieldName: 'SUBACCOUNT_NUM',
                fieldValue: [subAccountNumbers],
                dataType: 'text',
                operator: '=',
            });
        }

        const listViewRequestData = {
            requestParameters: {
                item: [{
                    name: 'realTimeAccount',
                    value: '1',
                }],
            },

            searchFields,
            startRow: 0,
        };

        const self = this;

        const xhr = http.post(this.startUrl, listViewRequestData, (data) => {
            self.set('requestId', data.requestId);
            self.ping();
        }, () => {
            self.onStatusCheckError();
        });

        xhr.always(() => {
            self.set('isAvailable', false);
            self.allowRetryAfter(+xhr.getResponseHeader('Retry-After'));
        });
    },
});

export default RealTimeTransactionPoller;
