import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import gluStore from '@glu/store';
import SavedViewModel from 'common/dynamicPages/models/savedView';
import SavedViewFilterUtil from 'common/util/savedViewFilterUtil';
import CollectionComboBoxView from 'common/dynamicPages/views/savedViews/combobox';
import $ from 'jquery';
import appConfigParams from 'system/webseries/models/configurationParameters';
import mobileUtil from 'mobile/util/mobileUtil';
import loadingTemplate from 'common/templates/loadingEmpty.hbs';
import template from './savedViewsList.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    ui: {
        gridSavedList: '.gridSavedList',
        $defaultButton: '[data-hook="getDefaultButton"]',
        $toggleViewFilters: '[data-hook="getToggleViewFilters"]',
        $filterViewIcon: '[data-hook="getToggleViewFilters"] > span',
    },

    events: {
        'click [data-hook="getDefaultButton"]': 'makeDefault',
        'click @ui.$toggleViewFilters': 'onToggleFilterClick',
    },

    initialize(opts) {
        this.savedFiltersOff = appConfigParams.get('savedFiltersOff') === 'true';
        /*
         * temporary disabled saved grid filters in admin
         * Saved View Grid Filters are hidden by default
         */
        this.showSavedGridFilters = !this.savedFiltersOff;

        this.grid = opts.grid;
        this.wrapper = opts.wrapper;
        this.savedViews = opts.savedViews;
        this.canSave = opts.canSave;
        this.canSetDefault = util.isUndefined(opts.canSetDefault) ? true : opts.canSetDefault;
        this.defaultText = opts.defaultText;
        this.currentSavedView = null;
        this.defaultSavedView = null;
        this.comboBoxView = null;
        this.gridView = opts.gridView;
        this.filterable = this.gridView.filter;
        this.appBus.trigger('savedViews:canSetDefault', this.canSetDefault);
    },

    onRender() {
        const self = this;

        if (!this.savedFiltersOff) {
            // Toggle Saved Grid Filters based Region
            if (this.grid.filterBadgeHeaderView && // PCM
                this.grid.filterBadgeHeaderView.savedViewFiltersRegion) {
                $(this.grid.filterBadgeHeaderView.savedViewFiltersRegion.el).toggleClass('show', this.showSavedGridFilters);
            }
        }

        // temporary disabled saved grid filters in admin
        if (this.hasLoadedRequiredData()) {
            if (this.savedFiltersOff) {
                self.initializeComboBox1();
            } else {
                this.setInitialSavedView();
                this.initializeComboBox1();
                this.fetchSavedViewFilters(this.currentSavedView);
            }
        } else {
            this.savedViews.fetch({
                success() {
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },
            });
        }
    },

    /*
     * NH-178965 It is now possible to have savedViews without showing the filters. They are
     * tightly coupled together in GLU, where filters are required for savedViews both
     * to render and save savedViews. Hide the badge toggle when saved views should be
     * visible but the filters shouldn't.
     */
    hasSavedGridFilters() {
        return this.filterable && !this.savedFiltersOff
            && !this.grid.options.savedViewsWithoutFilters;
    },

    templateHelpers() {
        return {
            savedViews: this.savedViews.toJSON(),
            canSetDefault: this.canSetDefault,
            savedGridFilters: this.hasSavedGridFilters(),
            isVisibleIcon: !this.showSavedGridFilters,
        };
    },

    /**
     * @method setInitialSavedView
     * If an initial viewId is passed, will search for the corresponding SavedView
     * and set as current and default view
     */
    setInitialSavedView() {
        // get the initial savedView
        if (!this.wrapper.viewId) {
            return;
        }

        const initialView = this.savedViews.find(view => view.get('viewId') === this.wrapper.viewId);

        if (!initialView) {
            return;
        }

        this.currentSavedView = initialView;
        this.defaultSavedView = initialView;
        this.activateDefaultButton();
    },

    /**
     * @method initializeComboBox1
     * Method used to initialize the saved views input
     */
    initializeComboBox1() {
        const self = this;
        if (util.isEmpty(this.wrapper.viewId) === false) {
            /**
             * This is a temp fix - NH-153358
             * Use overrideSavedViews to override the saved views list
             */
            if (this.options.overrideSavedViews) {
                this.savedViews = this.options.overrideSavedViews(this.savedViews);
            }
            // End of temp fix - NH-153358
            const selectedSavedView = this.savedViews.find(view => view.get('viewId') === self.wrapper.viewId);
            if (selectedSavedView) {
                this.currentSavedView = selectedSavedView;
                this.defaultSavedView = selectedSavedView;
                this.activateDefaultButton();
            } else {
                let selectedDefaultView;
                const defaultView = gluStore.get(`defaultSavedViews${this.options?.sectionId}`);
                const defaultViewId = defaultView?.id;
                if (defaultViewId) {
                    selectedDefaultView = this.savedViews.find(view => view.get('id') === defaultViewId);
                }
                this.selectFirstSavedView(this.savedViews, selectedDefaultView);
            }
        }

        this.comboBoxView = new CollectionComboBoxView({
            collection: this.savedViews,
            noSelectionText: util.isUndefined(this.defaultText) ? '' : this.defaultText,
            addButtonText: locale.get('common.save.view'),
            selected: this.currentSavedView,
            canAdd: util.isUndefined(this.canSave) ? true : this.canSave,
            canEdit: 'true',
        });

        this.listenTo(this.comboBoxView, 'create', this.createSavedView);
        this.listenTo(this.comboBoxView, 'remove', this.itemDestroyed);
        this.listenTo(this.comboBoxView, 'cleared', this.selectionCleared);
        this.listenTo(this.comboBoxView, 'select', this.itemSelected);
        this.dropdownRegion.show(this.comboBoxView);
        if (this.comboBoxView.selected !== null) {
            this.trigger('comboShown', this.comboBoxView.selected.id);
        }
    },

    /**
     * @method selectFirstSavedView
     * Helper method to select the first saved view from the saved views dropdown
     * @param {Collection} savedViews
     * @param {Model} selectedDefaultView
     */
    selectFirstSavedView(savedViews, selectedDefaultView) {
        const savedView = selectedDefaultView || savedViews.at(0);
        if (savedView) {
            this.itemSelected(savedView);
            this.currentSavedView = savedView;
            this.defaultSavedView = savedView;
            this.activateDefaultButton();
        }
    },

    intersectFilterLists(gridList, modelList) {
        util.each(modelList, (modelFilter) => {
            const [modelFilterName] = modelFilter.paramValue.split('^');
            let foundIt = false;
            util.each(gridList, (gridFilter) => {
                const [gridFilterName] = gridFilter.paramValue.split('^');
                if (modelFilterName === gridFilterName) {
                    foundIt = true;
                }
            });
            if (foundIt === false) {
                gridList.push(modelFilter);
            }
        });
        return gridList;
    },

    /**
     * On-click callback for Filter Toggle Button
     */
    onToggleFilterClick() {
        $(this.grid.filterBadgeHeaderView.savedViewFiltersRegion.el).toggleClass('show', this.showSavedGridFilters);

        /*
         * TODO: the icon class method is messy, need to fix
         * This is just a quick hack because template wasn't working
         */
        const toggleIconClass = this.showSavedGridFilters ? 'icon-eye' : 'icon-eye-line';
        this.showSavedGridFilters = !this.showSavedGridFilters;
        this.ui.$filterViewIcon.removeClass('icon-eye').removeClass('icon-eye-line').addClass(toggleIconClass);
        // adding aria-expanded to filter icon for accessibility
        this.ui.$toggleViewFilters.attr('aria-expanded', !this.showSavedGridFilters);
        const findAll = $('div.GridFilterHeader-toggle button span');
        // if the grid has the show class (all filters r showing)
        if ($(this.grid.filterBadgeHeaderView.savedViewFiltersRegion.el).hasClass('show')) {
            // lol wow
            $(this.grid.headerView.el.children[0].children[1].children).find(findAll);
            for (let i = 0; i < findAll.length; i += 1) {
                $(findAll[i]).removeClass('icon-eye').removeClass('icon-eye-line').addClass('icon-eye');
            }
        } else {
            for (let i = 0; i < findAll.length; i += 1) {
                $(findAll[i]).removeClass('icon-eye').removeClass('icon-eye-line').addClass('icon-eye-line');
            }
        }
        // Update store value
        this.setSavedGridFilterDisplay();
    },

    /*
     * Set Saved Grid Filter Choice in cache
     * TODO ?not store in cache but pass them or store them as unique values per grid
     */
    setSavedGridFilterDisplay() {
        // showSavedGridFilters is the next state of the filter icon
        gluStore.set('showSavedGridFilters', this.showSavedGridFilters);
        // currentShowGridFiltersState is the current state
        gluStore.set('currentShowGridFiltersState', !this.showSavedGridFilters);
    },

    /**
     * @param {Model} currentSavedView
     * @return {array} Filter Params
     * Generates the filter params needed to be sent to the service using
     * the currently applied filters
     * and the SavedViewFilterUtil to create param strings for each
     * current filter applied
     * Will also merge in the filter param data of the current
     * saved view with the currently applied filters
     */
    generateFilterParams(currentSavedView) {
        let filterArray = [];
        const currentFilterCollection = this.wrapper.filters;

        if (currentFilterCollection) {
            /*
             * use SavedView Filter to Create Filter Strings needed
             * to send to the service out of our Filter Data
             */
            filterArray = SavedViewFilterUtil.createFilterParams(currentFilterCollection.models);
        }

        // merge in current saved view's filters with currently applied filters
        if (currentSavedView && currentSavedView.currentState) {
            filterArray = this.intersectFilterLists(
                filterArray,
                currentSavedView.currentState.filters,
            );
        }

        return filterArray;
    },

    /**
     * @param {Model} savedViewModel
     * Fetches the applied filter data on passed in saved View
     * and triggers action to display them
     */
    fetchSavedViewFilters(savedViewModel) {
        // return if saved views for applied grid are not filterable
        if (!this.filterable || !savedViewModel) {
            // no saved view
            return;
        }

        savedViewModel.fetch({
            success: function (viewModel) {
                const changeThis = viewModel.get('filters').map(filter => ({
                    ...filter,
                    paramValue: filter.paramValue.replace(/&amp;/g, '&'),
                }));
                viewModel.set('filters', changeThis);

                this.showSavedViewFilters(viewModel);
                this.grid.filterBadgeHeaderView.appliedSavedViewFilters = viewModel;
            }.bind(this),
        });
    },

    /**
     * @param {Model} viewModel
     * Fetches the applied filter data on passed in saved
     * View and triggers action to display them
     */
    showSavedViewFilters(viewModel) {
        // Parse fetched filter data to be easily displayed by the grid's header
        const filterFieldModels = this.wrapper.columns.models;
        const vm = viewModel ? viewModel.get('filters') : [];
        const functionCode = this.grid.collection.context.overrideFunctionCode
            || this.grid.collection.context.functionCode
            || this.grid.collection.context.actionContext.functionCode;
        SavedViewFilterUtil.processFilterDisplayFromParams(vm, filterFieldModels, functionCode)
            .then((parsedFilterData) => {
                // trigger badge view to show filter applied on passed in saved view
                const { filterBadgeHeaderView } = this.grid;
                if (filterBadgeHeaderView) {
                    filterBadgeHeaderView.trigger(
                        'filter:showSavedFilters',
                        parsedFilterData,
                        this.currentShowGridFiltersState,
                        this.onFilterBadgeHeaderViewUpdate.bind(this),
                    );
                }
            });
    },

    /**
     * Set dropdown style to indicate changed to saved list view
     * Will italic filter dropdown on filter change
     * @param {boolean} updated - if a filter was updated or not
     */
    onFilterBadgeHeaderViewUpdate(updated) {
        this.$el.find('.select2-chosen').css({
            'font-style': updated ? 'italic' : 'normal',
        });
        this.$el.find('.select2-results').addClass('saved-views-results');
    },

    constructParamsForView(data, model) {
        let viewModel;
        const findModel = this.savedViews.get(data.value);
        if (typeof (findModel) !== 'undefined') {
            // cannot over write a system view/report
            if (findModel.get('system')) {
                // Show the error
                if (this.gridView) {
                    this.gridView.trigger('gridError', locale.get('listview.savesystemviewinvalid', data.value));
                    if (!mobileUtil.isMobileGridEnabled()) {
                        this.comboBoxView.applyError();

                        if (this.savedViews.getModelByViewId(this.wrapper.viewId)) {
                            this.comboBoxView.ui.$selectList.comboBox('val', this.savedViews.getModelByViewId(this.wrapper.viewId).cid);
                            this.comboBoxView.ui.$selectList.comboBox('open');
                        }
                    }
                } else {
                    this.trigger('saveError', data.value);
                }
                return false;
            }

            viewModel = findModel;
            viewModel.set({
                listName: data.value,
                productCode: this.grid.collection.context.productCode
                    || this.grid.collection.context.actionContext.productCode,
                functionCode: this.grid.collection.context.overrideFunctionCode
                    || this.grid.collection.context.functionCode
                    || this.grid.collection.context.actionContext.functionCode,
                typeCode: this.grid.collection.context.overrideTypeCode
                    || this.grid.collection.context.typeCode
                    || this.grid.collection.context.actionContext.typeCode,
                mode: '',
                rowsPerPage: this.wrapper.rowsPerPage,
                shared: util.isUndefined(data.shared) ? false : data.shared,
                referenceViewId: model.currentState.viewId,
            });
        } else {
            viewModel = new SavedViewModel();
            viewModel.set({
                listName: data.value,
                productCode: this.grid.collection.context.productCode
                    || this.grid.collection.context.actionContext.productCode,
                functionCode: this.grid.collection.context.overrideFunctionCode
                    || this.grid.collection.context.functionCode
                    || this.grid.collection.context.actionContext.functionCode,
                typeCode: this.grid.collection.context.overrideTypeCode
                    || this.grid.collection.context.typeCode
                    || this.grid.collection.context.actionContext.typeCode,
                mode: '',
                rowsPerPage: this.wrapper.rowsPerPage,
                update: 'false',
                shared: util.isUndefined(data.shared) ? false : data.shared,
                referenceViewId: model?.currentState?.viewId,
            });
        }

        let filterArray = [];
        if (util.isUndefined(data.filters)) {
            if (this.wrapper.filters !== null) {
                this.wrapper.filters.each((fm) => {
                    // NH-178966 When the filter is static, do not include it in the savedView
                    if (fm.get('staticFilter') === true) {
                        return;
                    }
                    let paramValue;
                    let filterFieldName = fm.get('field');
                    if (filterFieldName.endsWith('_DISP')) {
                        filterFieldName = filterFieldName.substring(0, filterFieldName.indexOf('_DISP'));
                    }
                    util.each(this.wrapper.columns.jsonData.filterFields, (field) => {
                        if (filterFieldName === field.fieldName) {
                            if (field.codeField) {
                                paramValue = `${field.codeField}^${field.codeField}`;
                            } else {
                                paramValue = `${filterFieldName}^${filterFieldName}`;
                            }
                        }
                    });
                    /**
                     * HACK: wrapper FilterFields does not have the passed in filter field
                     * in the list, default to field passed in if not found in the list.
                     */
                    if (!paramValue) {
                        paramValue = `${filterFieldName}^${filterFieldName}`;
                    }
                    let value = fm.get('value');
                    const type = fm.get('type');
                    if (type === 'string' || type === 'enum' || type === 'typeahead' || type === 'masked') {
                        paramValue += '^1^';
                    } else if (type === 'amount' || type === 'number') {
                        paramValue += '^0^';
                    } else if (type === 'date' || type === 'gmtdate') {
                        paramValue += '^4^';
                    }

                    if (type === 'string' || type === 'enum' || type === 'typeahead' || type === 'masked') {
                        paramValue += 'CONTAINS';
                    } else {
                        paramValue += (fm.get('equality')) ? fm.get('equality') : 'EQ';
                    }
                    if (util.isArray(value)) {
                        value = value.join('^');
                    }

                    paramValue += `^${value}`;
                    paramValue.replace(/&amp;/g, '&');

                    filterArray.push({
                        paramName: 'Filters',
                        paramValue,
                    });
                });
            }
            if (model?.currentState) {
                filterArray = this.intersectFilterLists(
                    filterArray,
                    model.currentState.filters,
                );
            }
        } else {
            filterArray = data.filters;
        }

        viewModel.set('filters', filterArray);

        let ci = {};
        if (util.isUndefined(data.column)) {
            if (!util.isEmpty(this.wrapper.sortKey) && !util.isEmpty(this.wrapper.sortOrder)) {
                ci = {
                    sortColumn: this.wrapper.sortKey,
                    sortDir: this.wrapper.sortOrder,
                };
            } else if (model && model.currentState?.columnInfo) {
                ci = {
                    sortColumn: model.currentState.columnInfo.sortColumn,
                    sortDir: model.currentState.columnInfo.sortDir,
                };
            }
            viewModel.columnJSON = this.wrapper.columns.toJSON();
        } else {
            ci = data.column;
            viewModel.columnJSON = [];
        }
        viewModel.set({
            columnInfo: ci,
        });

        return viewModel;
    },

    saveView(data, model) {
        const viewModel = this.constructParamsForView(data, model);
        viewModel?.save?.({}, {
            success: (successModel) => {
                // check if model already exists in collection
                if (!this.savedViews.get(successModel.id)) {
                    this.savedViews.add(viewModel);
                }

                this.wrapper.viewId = successModel.get('viewId');
                this.comboBoxView.ui.$selectList.comboBox('val', successModel.cid);
                this.comboBoxView.ui.$selectList.comboBox('close');
                this.currentSavedView = successModel;
                if (this.defaultSavedView && successModel.get('viewId') === this.defaultSavedView.get('viewId')) {
                    this.activateDefaultButton();
                } else {
                    this.deactivateDefaultButton();
                }
                const successModelViewId = successModel.get('viewId');
                this.trigger('viewSaveSuccess', successModelViewId);
                this.setCurrentSavedView(successModel);
                this.fetchSavedViewFilters(this.currentSavedView);
            },
            error: (errorModel, response) => {
                const msg = response.responseText
                    ? JSON.parse(response.responseText).message : response.statusText;
                this.gridView.trigger('gridError', msg);
                this.comboBoxView.applyError();
                this.comboBoxView.ui.$selectList.comboBox('val', this.savedViews.getModelByViewId(this.wrapper.viewId).cid);
                this.comboBoxView.ui.$selectList.comboBox('open');
            },
        });
        return undefined;
    },

    createSavedView(data) {
        const self = this;
        const selectedModel = this.comboBoxView.selectedModel();

        if (selectedModel) {
            selectedModel.fetch({
                success() {
                    self.saveView(data, selectedModel);
                },
            });
        } else {
            this.saveView(data, selectedModel);
        }
    },

    itemDestroyed(saveData) {
        const viewModel = this.savedViews.get(saveData.cid);
        viewModel.destroy();
    },

    /**
     * @method selectionCleared
     * Callback applied when the savedview input is cleared
     */
    selectionCleared() {
        this.trigger('viewSelected', '');

        // temporary disabled saved grid filters in admin
        if (!this.savedFiltersOff) {
            this.activateDefaultButton();
            if (this.grid.filterBadgeHeaderView) {
                this.grid.filterBadgeHeaderView.trigger('filter:clearSavedFilters');
            }
        }
    },

    getSelected() {
        return !util.isEmpty(this.currentSavedView) ? this.currentSavedView.id : '';
    },

    itemSelected(model) {
        if (this.canSetDefault) {
            if (model) {
                this.ui.$defaultButton.show();
                if (this.defaultSavedView && model.get('viewId') === this.defaultSavedView.get('viewId')) {
                    this.activateDefaultButton();
                } else {
                    this.deactivateDefaultButton();
                }
            } else {
                this.ui.$defaultButton.hide();
            }
        }
        this.currentSavedView = model;

        if (!this.savedFiltersOff) {
            this.fetchSavedViewFilters(this.currentSavedView);
        }

        /*
         * Saved View is used on the non grid views as well
         * check for null check for the gridView
         */
        this.trigger('viewSelected', model, util.isNullOrUndefined(this.gridView) ? null : this.gridView.grid.pagerView);
    },

    selectFirstItem() {
        this.ui.gridSavedList.find('option:first-child').attr('selected', 'selected');
    },

    selectByViewId(viewId, action) {
        const model = this.savedViews.getModelByViewId(viewId);
        let actionVerbiage;
        if (model) {
            this.comboBoxView.ui.$selectList.comboBox('val', model.cid);
        } else {
            // this would indicate the selected list view isnt available
            if (action) {
                actionVerbiage = locale.get(action);
            }
            dialog.warning(locale.get('listview.notsupported', actionVerbiage));
        }
    },

    refresh(opts) {
        this.grid = opts.grid;
    },

    activateDefaultButton() {
        if (this.canSetDefault) {
            this.ui.$defaultButton.attr('style', 'display: none !important');
            this.appBus.trigger('savedViews:canSetDefault', false);
        }
    },

    deactivateDefaultButton() {
        if (this.canSetDefault) {
            this.ui.$defaultButton.attr('style', 'display: inline-block !important; vertical-align:top;');
            this.appBus.trigger('savedViews:canSetDefault', true);
        }
    },

    /**
     * @method setCurrentSavedView
     * @description keeps track of default saved view on section level in BTR
     * @param {Model} savedView
     */
    setCurrentSavedView(savedView) {
        gluStore.set(`defaultSavedViews${this.options?.sectionId}`, savedView);
    },

    makeDefault() {
        return new Promise((resolve, reject) => {
            const self = this;
            if (this.currentSavedView) {
                this.currentSavedView.default({
                    productCode: this.grid.collection.context.productCode
                    || this.grid.collection.context.actionContext.productCode,
                    functionCode: this.grid.collection.context.overrideFunctionCode
                    || this.grid.collection.context.functionCode
                    || this.grid.collection.context.actionContext.functionCode,
                    typeCode: this.grid.collection.context.overrideTypeCode
                    || this.grid.collection.context.typeCode
                    || this.grid.collection.context.actionContext.typeCode,
                    success() {
                        if (!mobileUtil.isMobileGridEnabled()) {
                            self.activateDefaultButton();
                            self.defaultSavedView = self.currentSavedView;
                        }
                        self.setCurrentSavedView(self.currentSavedView);
                        resolve();
                    },
                    error() {
                    /*
                     * don't do anything really
                     * i mean, it's not a critical function
                     */
                        reject();
                    },
                });
            }
            resolve();
        });
    },
});
