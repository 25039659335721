import AccountSummary from 'app/balanceAndTransaction/views/balanceRptContainer';
import StackView from 'common/stack/views/stackView';
import AccountGroupSummary from 'app/balanceAndTransaction/views/accountGroupSummary';
import PreviousDaySummary from 'app/balanceAndTransaction/views/previousDaySummary';
import CashPositionSummary from 'app/balanceAndTransaction/views/cashPositionSummary';
import ReportingView from 'app/balanceAndTransaction/views/reporting';
import PreviousDayReportingView from 'app/balanceAndTransaction/views/previousDayReporting';
import Constants from 'app/balanceAndTransaction/constants';
import DepositAccountsTransactionListView from 'app/balanceAndTransaction/views/depositAccounts/depositTransactionListView';
import PreviousDayDepositAccountsTransactionListView from 'app/balanceAndTransaction/views/depositAccounts/previousDayDepositTransactionListView';
import CurrentDayDepositAccountsTransactionListView from 'app/balanceAndTransaction/views/depositAccounts/currentDayDepositTransactionListView';
import CashPositionDepositAccountsTransactionListView from 'app/balanceAndTransaction/views/depositAccounts/cashPositionDepositTransactionListView';
import LoanAccountsTransactionListView from 'app/balanceAndTransaction/views/loanAccounts/loanTransactionListView';
import TieredLoanAccountDetail from 'app/balanceAndTransaction/views/tieredLoans/tieredLoanAccountDetail';
import WireReportListView from 'app/balanceAndTransaction/views/wireReport/wireReportListView';
import TransactionDetailView from 'app/balanceAndTransaction/views/depositAccounts/transactionDetails';
import TransactionSearchView from 'app/balanceAndTransaction/views/tranSearch/previousCurrentDaySearch';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import WorkspaceView from 'common/workspaces/views/workspace';
import { appBus } from '@glu/core';
import { getErrorView, getLastFragment } from 'common/util/deeplinkUtil';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import BTRDeepLinkUtil from 'app/balanceAndTransaction/btrDeeplinkUtil';
import BTRGeneralUtil from 'app/balanceAndTransaction/btrUtil';
import util from '@glu/core/src/util';
import AccountSections from 'app/balanceAndTransaction/collections/accountSections';
import ErrorView from 'system/error/views/errorMessage';
import CreditCardAccountsTransactionListView from 'app/balanceAndTransaction/views/creditCardAccounts/creditCardTransactionListView';
import CurrentDayCreditCardAccountsTransactionListView from 'app/balanceAndTransaction/views/creditCardAccounts/currentDayCreditCardTransactionListView';
import PreviousDayCreditCardsAccountsTransactionListView from 'app/balanceAndTransaction/views/creditCardAccounts/previousDayCreditCardTransactionListView';

const navigateTo = (url) => {
    appBus.trigger('router:navigate', url, true);
};

const actions = {
    girShowAccountSummary() {
        if (processDeeplink('BANK_ACC_INFO/girAccountSummaryInquiry', this)) {
            const title = locale.get('gir.title');
            const options = {
                pageTitle: title,
            };

            this.showPage(
                title,
                new StackView({
                    initItem: new AccountSummary(options),
                }),
            );
        }
    },

    girShowAccountGroupSummary() {
        const title = locale.get('gir.title');
        const options = {
            // title
            pageTitle: locale.get('balanceTrans.account.group.summary'),
        };
        this.showPage(title, new AccountGroupSummary(options));
    },

    girShowCashPositionSummary() {
        const title = locale.get('gir.title');
        const options = {
            pageTitle: title,
        };
        this.showPage(title, new CashPositionSummary(options));
    },

    girShowPreviousDaySummary() {
        const title = locale.get('gir.title');
        const options = {
            pageTitle: title,
        };
        this.showPage(title, new PreviousDaySummary(options));
    },

    showReportingView() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new ReportingView(),
                }),
            );
        }
    },

    showPreviousDayReportingView() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new PreviousDayReportingView(),
                }),
            );
        }
    },

    showDepositAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        const view = new DepositAccountsTransactionListView({
            model: account,
            lastFragment: getLastFragment({
                model: account,
                controller: this,
            }),
        });
        if (account) {
            this.pushPage(
                title,
                view,
            );
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showCreditCardAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(
                title,
                new CreditCardAccountsTransactionListView({
                    model: account,
                }),
            );
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showPreviousDayDepositAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.pushPage(
                title,
                new PreviousDayDepositAccountsTransactionListView({
                    model: account,
                    returnRoute: store.get('balanceAndTransactionReturnRoute'),
                }),
            );
            store.unset('balanceAndTransactionReturnRoute');
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showPreviousDayCreditCardAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(
                title,
                new PreviousDayCreditCardsAccountsTransactionListView({
                    model: account,
                    returnRoute: store.get('balanceAndTransactionReturnRoute'),
                }),
            );
            store.unset('balanceAndTransactionReturnRoute');
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showCurrentDayDepositAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.pushPage(
                title,
                new CurrentDayDepositAccountsTransactionListView({
                    model: account,
                    returnRoute: store.get('balanceAndTransactionReturnRoute'),
                }),
            );
            store.unset('balanceAndTransactionReturnRoute');
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showCurrentDayCreditCardAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(
                title,
                new CurrentDayCreditCardAccountsTransactionListView({
                    model: account,
                    returnRoute: store.get('balanceAndTransactionReturnRoute'),
                }),
            );
            store.unset('balanceAndTransactionReturnRoute');
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showCashPositionDepositAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.pushPage(
                title,
                new CashPositionDepositAccountsTransactionListView({
                    model: account,
                    accountFilters: store.get('cashPositionSummary:accountFilters'),
                }),
            );
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showDepositTransactionsFromSearch() {
        /*
         * options from the cache store from image search contains the additionalSearchFields
         * and the account filter.
         */
        const title = locale.get('gir.transactions');
        const options = store.get('image_search_depositAccountsTransactions_options');
        options.pageTitle = title;
        store.set('image_search_depositAccountsTransactions_options', null);
        this.showPage(title, new DepositAccountsTransactionListView(options));
    },

    showLoanAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(title, new LoanAccountsTransactionListView({
                model: account,
                lastFragment: getLastFragment({
                    model: account,
                    controller: this,
                }),
            }));
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showPreviousDayLoanAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(title, new LoanAccountsTransactionListView({
                model: account,
                serviceNamePrefix: Constants.PREVIOUS_DAY_PREFIX,
                typeCode: Constants.GIRTRA_P,
                lastFragment: getLastFragment({
                    model: account,
                    controller: this,
                }),
            }));
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showCurrentDayLoanAccountTransactions() {
        const title = locale.get('gir.transactions');
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage(title, new LoanAccountsTransactionListView({
                model: account,
                serviceNamePrefix: Constants.CURRENT_DAY_PREFIX,
                typeCode: Constants.GIRTRA_I,
                lastFragment: getLastFragment({
                    model: account,
                    controller: this,
                }),
            }));
        } else {
            navigateTo(Constants.ROUTE_BALANCE_AND_TRANSACTION);
        }
    },

    showTieredLoanAccountDetail() {
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage('', new TieredLoanAccountDetail({
                account,
                detailService: `${Constants.LOAN_ACCTS_SERVICE_PREFIX}getTransactions`,
                totalsService: `${Constants.LOAN_ACCTS_SERVICE_PREFIX}tieredLoanSubAccount/getListViewTotals`,
                returnRoute: Constants.ROUTE_BALANCE_AND_TRANSACTION,
            }));
        }
    },

    showPreviousDayTieredLoanAccountDetail() {
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage('', new TieredLoanAccountDetail({
                account,
                detailService: `${Constants.LOAN_ACCTS_SERVICE_PREFIX + Constants.PREVIOUS_DAY_PREFIX}getTransactions`,
                totalsService: `${Constants.LOAN_ACCTS_SERVICE_PREFIX}tieredLoanSubAccount/getListViewTotals`,
                returnRoute: Constants.ROUTE_BALANCE_AND_TRANSACTION,
            }));
        }
    },

    showCurrentDayTieredLoanAccountDetail() {
        const account = store.get('balanceAndTransactionAccount');
        if (account) {
            this.showPage('', new TieredLoanAccountDetail({
                account,
                detailService: `${Constants.LOAN_ACCTS_SERVICE_PREFIX + Constants.CURRENT_DAY_PREFIX}getTransactions`,
                totalsService: `${Constants.LOAN_ACCTS_SERVICE_PREFIX}tieredLoanSubAccount/getListViewTotals`,
                returnRoute: Constants.ROUTE_BALANCE_AND_TRANSACTION,
            }));
        }
    },

    showDepositSlipImage() {
        const title = locale.get('gir.transactions');
        const model = store.get('deposit_data');
        const options = {
            pageTitle: title,
            accountFilter: model.get('ACCOUNTFILTER'),
            model,
        };

        this.showPage(title, new TransactionDetailView(options));
    },

    showWireReportSummary(viewId) {
        const title = locale.get('gir.wirrpt.wiretransactionreport');
        const options = {
            pageTitle: title,
            viewId,
            accountFilter: '*',
        };
        if (processDeeplink('REPORTING/wireReportSummaryWidget', this)) {
            this.showPage(title, new WireReportListView(options));
        }
    },

    showWireReportSummaryWorksapce() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'WIREREPORT',
                    returnRoute: 'REPORTING/wireReportSummary',
                }),
            }));
        }
    },

    showTransactionSearch() {
        this.showPage('', new TransactionSearchView({
            id: 'TRANSEARCH',
            returnRoute: 'REPORTING/transactionSearch',
        }));
    },

    /**
     * This method handles the contextual deep link for BTR Transactions combined
     * Current Day/Previous Day - Deposits
     */
    showDepositAccountTransactionsDeepLink() {
        this.showGirTransactionsDeepLink(
            Constants.TRANSACTION_DEPOSIT_DETAIL,
            Constants.TRANSACTION_DEPOSIT_DETAIL_DEEPLINK,
            Constants.ACCOUNT_SUMMARY.TABS.ALL,
        );
    },

    /**
     * This method handles the contextual deep link for BTR Transactions Current Day - Deposits
     */
    showCurrentDayDepositAccountTransactionsDeepLink() {
        this.showGirTransactionsDeepLink(
            Constants.TRANSACTION_CURRDAY_DEPOSIT_DETAIL,
            Constants.TRANSACTION_CURRDAY_DEPOSIT_DETAIL_DEEPLINK,
            Constants.ACCOUNT_SUMMARY.TABS.CURRENT,
        );
    },

    /**
     * This method handles the contextual deep link for BTR Transactions Previous Day - Deposits
     */
    showPreviousDayDepositAccountTransactionsDeepLink() {
        this.showGirTransactionsDeepLink(
            Constants.TRANSACTION_PREVDAY_DEPOSIT_DETAIL,
            Constants.TRANSACTION_PREVDAY_DEPOSIT_DETAIL_DEEPLINK,
            Constants.ACCOUNT_SUMMARY.TABS.PRIOR,
        );
    },

    /**
     * This method handles the contextual deep link for BTR (Non-tiered)
     * Combined Loan Transactions
     */
    showLoanAccountTransactionsDeepLink() {
        this.showGirTransactionsDeepLink(
            Constants.TRANSACTION_LOAN_DETAIL,
            Constants.TRANSACTION_LOAN_DETAIL_DEEPLINK,
        );
    },

    /**
     * This method handles the contextual deep link for BTR (Non-tiered)
     * Previous Day Loan Transactions
     */
    showPreviousDayLoanAccountTransactionsDeepLink() {
        this.showGirTransactionsDeepLink(
            Constants.TRANSACTION_PREVDAY_LOAN_DETAIL,
            Constants.TRANSACTION_PREVDAY_LOAN_DETAIL_DEEPLINK,
            Constants.ACCOUNT_SUMMARY.TABS.PRIOR,
        );
    },

    /**
     * This method handles the contextual deep link for BTR (Non-tiered)
     * Current Day Loan Transactions
     */
    showCurrentDayLoanAccountTransactionsDeepLink() {
        this.showGirTransactionsDeepLink(
            Constants.TRANSACTION_CURRDAY_LOAN_DETAIL,
            Constants.TRANSACTION_CURRDAY_LOAN_DETAIL_DEEPLINK,
            Constants.ACCOUNT_SUMMARY.TABS.CURRENT,
        );
    },

    /**
     * This method handles the contextual deep links for tiered loans.
     * It also stores the defined total columns for the tiered loan details.
     */
    processTieredLoansDeepLink(panel, detailType, deepLinkType) {
        const accountSectionsCollection = new AccountSections({
            tabId: panel,
        });

        const onSuccess = function () {
            BTRGeneralUtil.saveTieredLoansTotalsColumns(
                accountSectionsCollection,
                Constants.ACCOUNT_SUMMARY.SECTIONS.LOANS,
            );
            this.showGirTransactionsDeepLink(
                detailType,
                deepLinkType,
                panel,
            );
        };

        const onError = function () {
            const errorPage = new ErrorView({
                key: 'notAuthorized',
                noContainer: true,
            });
            this.showPage('', errorPage);
        };

        accountSectionsCollection.fetch({
            success: util.bind(onSuccess, this),
            error: util.bind(onError, this),
        });
    },

    /**
     * This method handles the contextual deep link for BTR Tiered Loan Sub Account
     * Combined Current Day/Previous Day Reporting - Tiered Loans
     */
    showTieredLoanAccountDetailDeepLink() {
        this.processTieredLoansDeepLink(
            'ALLACCOUNTS',
            Constants.TRANSACTION_TIERED_LOAN_DETAIL,
            Constants.TRANSACTION_TIERED_LOAN_DETAIL_DEEPLINK,
        );
    },

    /**
     * This method handles the contextual deep link for BTR Tiered Loan Sub Account
     * Previous Day Reporting - Tiered Loans
     */
    showPreviousDayTieredLoanAccountDetailDeepLink() {
        this.processTieredLoansDeepLink(
            'PRIORDAY',
            Constants.TRANSACTION_PREVDAY_TIERED_LOAN_DETAIL,
            Constants.TRANSACTION_PREVDAY_TIERED_LOAN_DETAIL_DEEPLINK,
        );
    },

    /**
     * This method handles the contextual deep link for BTR Tiered Loan Sub Account
     * Current Day Reporting - Tiered Loans
     */
    showCurrentDayTieredLoanAccountDetailDeepLink() {
        this.processTieredLoansDeepLink(
            'CURRDAY',
            Constants.TRANSACTION_CURRDAY_TIERED_LOAN_DETAIL,
            Constants.TRANSACTION_CURRDAY_TIERED_LOAN_DETAIL_DEEPLINK,
        );
    },

    /**
     * Generic Method to handle the GIR Transactions deep links
     *
     * @param {String} detailType - Regular route
     * @param {String} deepLinkType - DeepLink route
     * @param {String} tabId - optional, indicates whether PRIORDAY or CURRDAY
     *
     */
    showGirTransactionsDeepLink(detailType, deepLinkType, tabId) {
        // Verify whether the user is authorized to access this deep link
        if (!processDeeplink(deepLinkType, this)) {
            return;
        }

        // Retrieve the contextual data for the contextual deep link
        const contextData = BTRDeepLinkUtil.retrieveContextData(Constants.BTR_CONTEXTUAL_DATA);
        if (!contextData) {
            this.showPage('', getErrorView('noDataFoundForOperation'));
            return;
        }

        if (tabId) {
            contextData.tabId = tabId;
        }

        // Store the account
        store.set('balanceAndTransactionAccount', BTRDeepLinkUtil.createAccountModel(contextData));

        /**
         * Remove the 'btr:initialTab' if it exists, this contextual deep link goes
         * directly into the detail page as there's no need to show the back button
         */
        store.remove('btr:initialTab');

        navigateTo(detailType);
    },
};

export default actions;
