import React from 'react';
import PropTypes from 'prop-types';
import { asView } from 'common/util/reactUtil';
import { appBus } from '@glu/core';
import StatefulSwitch from './StatefulSwitch';

const propTypes = {
    name: PropTypes.string.isRequired,
    htmlId: PropTypes.string,
    targetField: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.bool,
    show: PropTypes.bool.isRequired,
};

const defaultProps = {
    labelText: '',
    onChange: () => {},
    defaultValue: false,
    htmlId: '',
};

const StatefulSwitchWrapper = ({
    name,
    htmlId,
    targetField,
    labelText,
    onChange,
    defaultValue,
    show,
    ...props
}) => {
    const onToggleSwitchChange = (switchName, value) => {
        onChange(switchName, value);
        appBus.trigger('toggleSwitchChanged', targetField, value);
    };

    return (
        <StatefulSwitch
            onChange={onToggleSwitchChange}
            name={name}
            htmlId={htmlId}
            labelText={labelText}
            defaultValue={defaultValue}
            show={show}
            {...props}
        />
    );
};

StatefulSwitchWrapper.propTypes = propTypes;
StatefulSwitchWrapper.defaultProps = defaultProps;


/**
 * Provide a utility function for leveraging this in a Backbone context
 * @param {Object} options - Options/props to be sent along to wrapped component
 */
export const createStatefulSwitchWrapperView = options => asView(StatefulSwitchWrapper, options);

export default StatefulSwitchWrapper;
