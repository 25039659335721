import gluStore from '@glu/store';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import { getAccessTokenPromise } from 'pcm/common/ptx-connect-integration/ptxConnectUtils';
import http from 'system/gluOverride/http';

const constants = {
    isDisplayPrinterHostName: '/banking-services/api/inquiry/getQueryResults',
    DisplayPrinterPayload: {
        IncludeMapData: 1,
        queryCriteria: {
            inquiryId: 50151,
            action: {
                typeCode: '*',
                entryMethod: 0,
                productCode: 'CLM',
                actionMode: 'SELECT',
                functionCode: 'BATCH',
            },
        },
    },
};

export default Collection.extend({
    initialize() {
        this.serviceUrl = '/printers';
        this.displayPrinterHostNameExist = false;
    },

    checkDisplayPrinterHostName(jsonResponse) {
        if (jsonResponse.queryResponse.QueryData.numRows !== 0) {
            if (jsonResponse.queryResponse.QueryData.queryRows[0].mapDataList[0].value === 'on') {
                this.displayPrinterHostNameExist = true;
                return;
            }
        }
        this.displayPrinterHostNameExist = false;
    },

    parse(jsonData) {
        const arrayOfPrinters = [];
        for (let index = 0; index < jsonData.length; index += 1) {
            const printer = jsonData[index];
            let printerName = printer.name;
            const hostName = printer.host;
            if (this.displayPrinterHostNameExist) {
                printerName = printerName.concat(`/${hostName}`);
            }
            const obj = {
                id: printer.id,
                label: printerName,
            };
            arrayOfPrinters.push(obj);
        }
        return util.sortBy(arrayOfPrinters, 'label');
    },

    sync(method, model, options) {
        // TO DO: This is temporary workaround for issue PCM-1802
        // in which get request for PTX printers done from PCM backend
        // do not work
        const url = services.generateUrl(this.serviceUrl).replace('/banking-services', '');
        http.post(constants.isDisplayPrinterHostName, constants.DisplayPrinterPayload)
        .then((response) => {
            this.checkDisplayPrinterHostName(response);
        }, () => {
        });
        getAccessTokenPromise().then(() => {
            http.get(
                `${url}`, null, null,
                {
                    headers: {
                        Authorization: `Bearer ${gluStore.get('ptxAccessToken')}`,
                        Accept: 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                },
            ).then((result) => {
                options.success(result.printers);
            }, () => {
                options.error({ isPtxError: true });
            });
        }, () => {
            options.error({ isPtxError: true });
        });
    },
});
