import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from '@glu/utilities-react';
import { Button, TERTIARY, EXTRA_SMALL } from '@glu/buttons-react';
import { withStyles } from '@glu/theming';
import { TriangleDownIcon } from '@glu/icons-react';
import styles from './BulkActionGridControl.styles';
import constants from './constants';

const propTypes = {
    controlLabel: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    childPlacement: PropTypes.string,
    GridControl: PropTypes.elementType.isRequired,
};

const defaultProps = {
    childPlacement: constants.PLACEMENT.LEFT,
};

const BulkActionGridControl = ({
    controlLabel,
    GridControl,
    childPlacement,
    classes,
    ...props
}) => {
    const [visible, setVisible] = useState(false);
    const onClickOutside = () => {
        setVisible(false);
    };
    const toggleChildren = () => {
        setVisible(previous => !previous);
    };
    const bulkActionGridControl = useRef();
    useClickOutside(bulkActionGridControl, onClickOutside);
    return (
        <div ref={bulkActionGridControl}>
            <Button
                className={classes.button}
                variant={TERTIARY}
                onClick={toggleChildren}
                size={EXTRA_SMALL}
            >
                <span>{controlLabel}</span>
                <TriangleDownIcon className={classes.triangleDownIcon} title={controlLabel} />
            </Button>
            {visible
                && (
                    <div
                        className={classes.childrenContainer}
                    >
                        <GridControl
                            toggleControl={setVisible}
                            {...props}
                        />
                    </div>
                )}
        </div>
    );
};
BulkActionGridControl.propTypes = propTypes;
BulkActionGridControl.defaultProps = defaultProps;
export default withStyles(styles)(BulkActionGridControl);
