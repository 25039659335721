var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"menuCategory") || (depth0 != null ? lookupProperty(depth0,"menuCategory") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"menuCategory","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":47}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":11,"column":49},"end":{"line":11,"column":64}}}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":35},"end":{"line":20,"column":41}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":49}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":20,"column":52},"end":{"line":20,"column":67}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page page-grid page-payments page-payments-collect-from-business\">\n        <div class=\"page-header\">\n                <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.collectFromBusinessTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":68}}}))
    + "</h2>\n                <h3 class=\"total-text\" data-hook=\"total-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.totalPaymentsLabel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":62},"end":{"line":4,"column":104}}}))
    + "</h3>\n        </div>\n    <div class=\"wrap\">\n        <div class=\"input-wrap i-want-to\">\n            <label for=\"payments-list\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.iwantto",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":39},"end":{"line":8,"column":70}}}))
    + "</label>\n            <select id=\"payments-list\" class=\"form-control\" name=\"paymentsList\" data-hook=\"payments-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"paymentTypes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":12,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-wrap from-account\">\n            <label for=\"fromAccount\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.towhichaccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":37},"end":{"line":16,"column":75}}}))
    + "</label>\n            <select id=\"fromAccount\" class=\"form-control\" data-bind=\"model\" name=\"fromAccount\" data-hook=\"from-account\">\n                <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromAccounts") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":21,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"fromAccount\"></span>\n            <span data-hook=\"fromAccSelected\"></span>\n        </div>\n    </div>\n    <div class=\"wrap\">\n                <div class=\"payment-grid-single-transaction\" data-region=\"content\" aria-live=\"polite\"></div>\n    </div>\n    <div class=\"action-bar\">\n        <button type=\"button\" class=\"btn btn-primary\" data-hook=\"submit-payments\" data-action=\"submitPayments\" data-disable-on-submit>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.collectPaymentButton",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":134},"end":{"line":31,"column":178}}}))
    + "</button>\n        <button type=\"button\" class=\"btn btn-primary\" data-hook=\"save-draft\" data-action=\"saveDraft\" data-disable-on-submit>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.saveDraftButton",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":124},"end":{"line":32,"column":163}}}))
    + "</button>\n        <button type=\"button\" class=\"btn btn-link\" data-action=\"cancelPayments\" data-disable-on-submit>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.cancelButton",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":103},"end":{"line":33,"column":139}}}))
    + "</button>\n        <h3 class=\"total-text\" data-hook=\"total-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.totalPaymentsLabel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":54},"end":{"line":34,"column":96}}}))
    + "</h3>\n    </div>\n</div>\n";
},"useData":true});