import { asView } from 'common/util/reactUtil';
import AddAnotherPaymentOptionLink from 'components/AddAnotherPaymentOptionLink/AddAnotherPaymentOptionLink';

export default {
    /**
     * singleOnlyPaymentOptionSupported = true, hide another payment option link
     * singleOnlyPaymentOptionSupported = false, display another payment option link
     * @returns {boolean}
     */
    shouldDisplaySingleOptionOnly(preferences) {
        return Array.isArray(preferences) ?
            preferences[0]?.singleOnlyPaymentOptionSupported :
            preferences?.singleOnlyPaymentOptionSupported;
    },
    showAddAnotherPaymentOptionLinkRegion(view) {
        if (!this.shouldDisplaySingleOptionOnly(view.preferences)) {
            const AddAnotherPaymentOptionLinkView = asView(AddAnotherPaymentOptionLink);
            view.addAnotherPaymentOptionLinkRegion?.show(new AddAnotherPaymentOptionLinkView({
                onClick: view.addOption.bind(view),
            }));
        } else {
            view.addAnotherPaymentOptionLinkRegion?.close();
        }
    },
};
