import $ from 'jquery';
import util from '@glu/core/src/util';
import widgetFinder from 'common/uiWidgets/util/widgetFinder';
import serverConfigParams from 'system/webseries/models/configurationParameters';

/**
 * returns the fieldInfo object for the input field
 * @param view
 * @param fieldName
 * @returns {object} fieldInfo
 */
function getFieldInfo(view, fieldName) {
    const fieldInfo = view.model.jsonData.fieldInfoList.filter(item => item.name === fieldName);

    return fieldInfo.length > 0 ? fieldInfo[0] : {};
}

/**
 * @method evaluateWidgetOptionsVisibilityCondition
 *
 * @param {object}[model] - the current model for the payment
 * @param {object}[conditions] - If defined, this is the list of conditions
 * that need to be met in order to determine whether the widget is visible
 */
function evaluateWidgetOptionsVisibilityCondition(model, conditions) {
    let res = true;
    if (conditions) {
        const failedCondition = util.find(conditions, condition => (condition.operator === 'EQ'
            && model.get(condition.fieldName) !== condition.value)
            || (condition.operator === 'NOTEQ' && model.get(condition.fieldName) === condition.value));
        if (failedCondition) {
            res = false;
        }
    }
    return res;
}

export default {
    setupWidgets(viewParam, selector, $alt) {
        const view = viewParam;
        let localSelector = selector;
        // Support an alternate container element
        const $el = $alt || view.$el;

        view.allWidgets = [];
        if (!localSelector) {
            localSelector = '[data-type="ui-widget"]';
        }

        $el.find(localSelector).each((index, element) => {
            const dataAttrs = $(element).data();
            const regionName = dataAttrs.region;
            const { widgetType } = dataAttrs;
            const widgetID = dataAttrs.widgetId;
            const WidgetClass = widgetFinder.getWidgetView(widgetType);
            const fieldName = dataAttrs.widgetField;
            const shouldBeProtected = dataAttrs.widgetShouldBeProtected;
            const fieldInfo = getFieldInfo(view, fieldName);
            let widgetView;
            let fieldsInWidget;
            let widgetInitOptions;
            const fieldData = view.model.fieldData[fieldName];
            let relevantRegion = view[regionName];
            const options = {
                comboCollections: view.comboCollections,
                configParams: serverConfigParams,
                context: view.contextDef,
                fieldData,
                fieldInfo,
                fieldName,
                model: view.model,
                parentInitializeOptions: view.initializeOptions,
                parentView: view,
                processingDates: view.processingDates,
                state: view.state,
                stateToggleData: view.stateToggleData,
                widgetID,

                /*
                 * in import view (file import history) disable drill downs as
                 * payment details are presented in a modal
                 */
                shouldBeProtected,

                disableDrillDown: view.options.importView,
                ...view.options.additionalPageOptions,
            };

            /*
             * update widget initialization options to include other option criteria from
             * configured MDF meta-data
             */
            if (view.model.jsonData.widgetsList) {
                fieldsInWidget = util.chain(view.model.jsonData.widgetsList)
                    .filter(widget => widget.name === fieldData.fieldUIType)
                    .map(widget => widget.fields)
                    .value();
                if (fieldsInWidget.length && fieldsInWidget.length > 0) {
                    options.widgetFields = fieldsInWidget;
                }

                widgetInitOptions = util.chain(view.model.jsonData.widgetsList)
                    .filter(widget => widget.name
                        === view.model.fieldData[fieldName].fieldUIType)
                    .map(widget => widget)
                    .value();

                if (widgetInitOptions.length && widgetInitOptions.length === 1) {
                    [options.widgetInitOptions] = widgetInitOptions;
                } else if (widgetInitOptions.length) {
                    options.widgetInitOptions = widgetInitOptions;
                }
            }

            // check whether any visibility conditions is configured for this widget
            const isWidgetVisible = evaluateWidgetOptionsVisibilityCondition(
                view.model,
                options.widgetInitOptions
                    ? options.widgetInitOptions.widgetVisibilityCondition : null,
            );

            if (isWidgetVisible) {
                widgetView = new WidgetClass(options);

                // collect all the widgets for this page
                view.allWidgets.push(widgetView);

                // account for nested widget in beneficiary view
                if (relevantRegion === undefined && view.beneWidget) {
                    relevantRegion = view.beneWidget[regionName];
                }

                if (relevantRegion) {
                    // load the widget region
                    relevantRegion.show(widgetView);
                }
            }
        });

        if (view.beneWidget) {
            view.allWidgets.push(view.beneWidget);
        }
    },
};
