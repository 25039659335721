import { Layout } from '@glu/core';
import DefaultLabelView from './defaultLabelView';
import template from './listBuilderItemView.hbs';

export default Layout.extend({
  template,
  tagName: 'li',
  className: 'list-builder-item',
  events: {
    'change input': 'toggleSelected'
  },
  modelEvents: {
    'change:listBuilder:isFilteredOut': 'refreshStatesAndClasses'
  },
  regions: {
    itemContent: '.list-builder-item-content'
  },

  initialize(options) {
    this.LabelView = options.labelView || DefaultLabelView;
  },

  onRender() {
    this.itemContent.show(new this.LabelView({
      model: this.model
    }));
  },

  attributes() {
    return {
      'data-item-id': this.model.id
    };
  },

  templateHelpers() {
    return {
      isChecked: this.model.get('listBuilder:isSelected'),
      cid: this.cid
    };
  },

  toggleSelected() {
    this.model.set('listBuilder:isSelected', this.$('input[type=checkbox]').prop('checked'));
    this.options.layout.toggleControls();
  },

  refreshStatesAndClasses() {
    this.$el.toggleClass('is-filtered-out', this.model.get('listBuilder:isFilteredOut'));
    this.toggleSelected();
  }
});
