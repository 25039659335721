export default ({
    getRestoreButtons() {
        this.buttonList.push({
            action: 'SAVE',
        });
        this.buttonList.push({
            action: 'SAVEDRAFT',
        });
    },

    save() {
        this.disableButtons();
        this.pageView.model.overloadedMethodName = 'restore';
        this.pageView.save();
    },

    savedraft() {
        this.disableButtons();
        this.pageView.model.set({
            _saveIncomplete: 'true',
        });
        this.pageView.model.overloadedMethodName = 'restore';
        this.pageView.save();
    },
});
