import locale from '@glu/locale';
import InquiryUtil from './bankInquiryUtil';

const Inquiry = InquiryUtil.extend({

    initialize() {
        this.err = locale.get('bab.inquiry.error');
    },

    setCustomFilter(name, value, filterName) {
        this.request.queryCriteria.customFilters = [];
        this.addCustomFilter(name, value, filterName);
    },

    addCustomFilter(name, value, filterName) {
        let localFilterName = filterName;
        localFilterName = localFilterName || 'Depends';
        this.request.queryCriteria.customFilters = this.request.queryCriteria.customFilters || [];
        this.request.queryCriteria.customFilters.push({
            filterName: localFilterName,
            filterParam: [name, value],
        });
        return this;
    },
});

export default Inquiry;
