var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":52}}}))
    + "</h1>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideHeader") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "<div class=\"section-container\">\n    <div class=\"section-body\">\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n        <legend>\n        <h2 class=\"col-md-12\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"alerts.mySettings.contactTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":30},"end":{"line":12,"column":73}}}))
    + "</h2>\n        </legend>\n        <div data-region=\"pageContent\" class=\"my-settings-content\"></div>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" class=\"btn btn-primary\" data-action=\"save\" aria-label=\"Save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":95},"end":{"line":18,"column":119}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n\n";
},"useData":true});