var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div>\n    <div class=\"widget\">\n        <div class=\"widget-container\">\n            <div class=\"widget-header\">\n                <h2>"
    + alias2(((helper = (helper = lookupProperty(helpers,"DISPLAYNAME") || (depth0 != null ? lookupProperty(depth0,"DISPLAYNAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DISPLAYNAME","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":35}}}) : helper)))
    + "</h2>\n            </div>\n            <div class=\"widget-body\">\n                <div class=\"row\">\n                    <div id=\"alertRegion\" class=\"col-md-12\"></div>\n                </div>\n            </div>\n            <div data-region=\"content\" class=\"report\" aria-live=\"polite\"></div>\n        </div>\n        <div>\n            <div data-region=\"export\" aria-live=\"polite\"></div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-group col-md-12\">\n            <div id=\"widget-action-btn-group\">\n                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":85},"end":{"line":22,"column":116}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});