var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"account-details\">\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-links\">\n                    <!-- <button type=\"button\" class=\"btn btn-link sync-quickbooks\"><span class=\"icon-refresh\"></span> <span class=\"txt-label\">Sync with Quickbooks</span></button> -->\n            <span class=\"\" style=\"overflow: hidden; position: relative;\">\n                <button type=\"button\" class=\"btn btn-link export cash-flow-import\"><span class=\"icon-add-circle\"></span> <span class=\"txt-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.import.future.entries",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":145},"end":{"line":8,"column":188}}}))
    + "</span></button>\n                <!-- <input id=\"cash-flow-import\" class=\"btn btn-link  pull-right cash-flow-import\" type=\"file\" style=\"position: absolute; top: 0; left: 0; width: 200%; height: 200%; line-height: 4; opacity: 0;\"> -->\n            </span>\n                </div>\n            </div>\n\n        </div> <!-- /.account-details -->\n\n        <div>\n\n            <div data-hook=\"settingsDrawer\">\n            </div>\n\n            <div class=\"row\">\n                <div class=\"settings-buttons-region col-md-6\"></div>\n\n                <div class=\"chart-button-group col-md-6\">\n                    <a type=\"button\" class=\"btn btn-link btn-invoice-view active\"><span class=\"icon-indicator\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.inflow.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":119},"end":{"line":25,"column":151}}}))
    + "</a>\n                    <a type=\"button\" class=\"btn btn-link btn-bill-view active\"><span class=\"icon-indicator\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.outflow.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":116},"end":{"line":26,"column":149}}}))
    + "</a>\n                    <a type=\"button\" class=\"btn btn-link btn-remaining-view active\"><span class=\"icon-indicator\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.balance.available.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":121},"end":{"line":27,"column":164}}}))
    + "</a>\n                </div>\n\n            </div> <!-- /.row -->\n\n            <div class=\"chart-wrapper\">\n                <div class=\"c3-transactions-graph\" id=\"transactions-graph\"></div>\n\n                <div class=\"slider-control-region\"></div>\n\n                <div class=\"account-settings-drawer\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.chart.account.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":38,"column":62}}}))
    + "\n                </div>\n\n                <div class=\"line-settings-drawer\">\n\n                </div>\n\n                <div class=\"plan-settings-drawer\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.plans.list",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":20},"end":{"line":46,"column":52}}}))
    + "\n                </div>\n\n                <div class=\"chart-date-overlay\" id=\"range-slider-date-values\"></div>\n                <div class=\"drop-overlay\">\n                    <div class=\"col-xs-3\" data-length=\"1\" data-unit=\"week\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.week.plus1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":75},"end":{"line":51,"column":107}}}))
    + "</div>\n                    <div class=\"col-xs-3\" data-length=\"2\" data-unit=\"week\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.week.plus2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":75},"end":{"line":52,"column":107}}}))
    + "</div>\n                    <div class=\"col-xs-3\" data-length=\"1\" data-unit=\"month\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.month.plus1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":76},"end":{"line":53,"column":109}}}))
    + "</div>\n                    <div class=\"col-xs-3\" data-length=\"3\" data-unit=\"month\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.month.plus3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":76},"end":{"line":54,"column":109}}}))
    + "</div>\n                </div>\n            </div> <!-- /.chart-wrapper -->\n\n\n            <div data-region=\"alert-region\"></div>\n\n            <div class=\"row\">\n                <div class=\"col-md-12\">\n                    <button type=\"button\" class=\"btn btn-primary add-cashflow pull-right\">\n                        <span class=\"icon-add-circle\"></span>\n                        <span class=\"txt-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.entry.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":48},"end":{"line":65,"column":79}}}))
    + "</span>\n                    </button>\n                </div>\n            </div> <!-- /.row -->\n             <div class=\"grid-wrapper\">\n                <div class=\"btn-wrapper\">\n                    <div class=\"btn-group widget-info-links\">\n                        <button type=\"button\" class=\"btn btn-tertiary refresh\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":86},"end":{"line":72,"column":113}}}))
    + "\" data-hook=\"refresh-button\"><span class=\"icon-refresh\"></span><span class=\"refresh-time\" data-hook=\"refresh-time\"></span></button>\n                    </div>\n                </div>\n                <div class=\"chart-grid\"></div>\n\n                <div class=\"row\">\n                    <div class=\"widget-action-btn-group\">\n                        <!-- @TODO: We are not using button groups-->\n\n                    </div>\n                </div><!-- /.row -->\n            </div> <!-- /.grid-wrapper -->\n        </div>\n    </div>\n</div>\n\n\n";
},"useData":true});