import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';

const LiveMaintenanceCheck = Model.extend({
    sync() {
        const url = services.generateUrl('/liveMaintenance/isMaintenanceMode');

        /*
         * Add as a request header the parameter name 'bypassLiveMaintKey'
         * that is in the window.url - if it exists
         */
        http.addRequestHeaderFromUrlParamName('bypassLiveMaintKey');

        return http.get(url);
    },
});

export default new LiveMaintenanceCheck();
