import Controller from '@glu/core/src/controller';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import services from 'services';

export default Controller.extend({
    initialize() {
        this.request = this.createDefault();
    },

    getFieldMap(fieldName) {
        switch (fieldName) {
        case 'VENDOR_ID':
            return {
                fieldName: 'VENDOR_ID',
                onLoadValue: 'VENDOR_NAME',
                searchFieldName: 'BENE_NAME_HEADER',
                idAttribute: 'HEADER_TNUM',
                textAttribute: 'BENE_NAME_HEADER',
            };

        case 'CUSTOMER_ID':
            return {
                fieldName: 'CUSTOMER_ID',
                onLoadValue: 'CUSTOMER_NAME',
                searchFieldName: 'BENE_NAME_HEADER',
                idAttribute: 'HEADER_TNUM',
                textAttribute: 'BENE_NAME_HEADER',
            };

        case 'TERM_CONDITION_ID':
            return {
                fieldName: 'TERM_CONDITION_ID',
                searchFieldName: 'DESCRIPTION',
                idAttribute: 'TERM_CONDITION_ID',
                textAttribute: 'DESCRIPTION',
            };

        case 'VALIDATOR_REQUIRED':
            return {
                fieldName: 'VALIDATOR_REQUIRED',
                searchFieldName: 'USERNAME',
                idAttribute: 'USERID',
                textAttribute: 'USERNAME',
            };

        case 'APPROVER_REQUIRED':
            return {
                fieldName: 'APPROVER_REQUIRED',
                searchFieldName: 'USERNAME',
                idAttribute: 'USERID',
                textAttribute: 'USERNAME',
            };

        case 'IMPORTANCE':
            return {
                fieldName: 'IMPORTANCE',
                searchFieldName: 'DESCRIPTION',
                idAttribute: 'VALUE',
                textAttribute: 'DESCRIPTION',
            };

        case 'LOCATION':
            return {
                fieldName: 'LOCATION',
                searchFieldName: 'DESCRIPTION',
                idAttribute: 'DESCRIPTION',
                textAttribute: 'DESCRIPTION',
                valueFormatter: 'UPPER',
            };

        case 'DEPARTMENT':
            return {
                fieldName: 'DEPARTMENT',
                searchFieldName: 'DESCRIPTION',
                idAttribute: 'DESCRIPTION',
                textAttribute: 'DESCRIPTION',
                valueFormatter: 'UPPER',
            };

        default:
            return false;
        }
    },

    setTextSearchFromValue(name, value) {
        let localValue = value;
        const fieldMap = this.getFieldMap(name);
        if (fieldMap === false) {
            return false;
        }

        localValue = this.valueFormatter(localValue, fieldMap.valueFormatter);

        this.request.fieldName = fieldMap.fieldName;
        this.request.searchFields[0].fieldName = fieldMap.idAttribute;
        this.request.searchFields[0].fieldValue = [localValue];
        this.request.searchFields[0].operator = 'EQ';
        return true;
    },

    valueFormatter(value, valueFormat) {
        if (valueFormat === 'UPPER') {
            return value.toUpperCase();
        }

        return value;
    },

    setSearch(name, str) {
        const fieldMap = this.getFieldMap(name);
        if (fieldMap === false) {
            return false;
        }

        this.request.fieldName = fieldMap.fieldName;
        this.request.searchFields[0].fieldName = fieldMap.searchFieldName;
        this.request.searchFields[0].fieldValue = [str];
        return true;
    },

    createDefault() {
        return {
            rowsPerPage: 50,
            startRow: 1,
            fieldName: 'VENDOR_ID',
            depends: [],
            subType: '*',

            searchFields: [{
                fieldName: 'BENE_NAME_HEADER',
                operator: 'CONTAINS',
                fieldValue: [],
                dataType: 'text',
            }],

            requestParameters: {
                item: [{
                    name: 'PRODUCTCODE',
                    value: 'BOS',
                }, {
                    name: 'TYPECODE',
                    value: 'BILL',
                }, {
                    name: 'FUNCTIONCODE',
                    value: 'MAINT',
                }],
            },
        };
    },

    getRequest() {
        return this.request;
    },

    send(name) {
        const parseFunction = util.bind(this.parseResponse, this, name);

        return http.post(services.advancedLookup, this.getRequest())
            .then(parseFunction, this.handleError);
    },

    parseResponse(name, response) {
        const fieldMap = this.getFieldMap(name);
        return util.map(response.rows, (row) => {
            // adds select2 properties
            const hash = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            return {
                text: hash[fieldMap.textAttribute],
                id: hash[fieldMap.idAttribute],
            };
        });
    },

    handleError() {

    },
});
