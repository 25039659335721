import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from '@glu/theming';
import styles from './FormFieldStaticContainer.styles';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = createUseStyles(styles);
const FormFieldStaticContainer = ({
    children,
}) => {
    const classes = useStyles();
    return (
        <fieldset className={classes.fieldset}>
            {children}
        </fieldset>
    );
};
FormFieldStaticContainer.propTypes = propTypes;
export default FormFieldStaticContainer;
