var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n    .theme-block{\n        display: inline-block;\n        border: 1px solid #37A5CF;\n        padding: 0.5em;\n        margin:0.5em;\n        min-width:12em;\n    }\n    .theme-block.selected{\n        border:3px solid #757474;\n    }\n    .theme-block .title{\n        display:block;\n    }\n\n</style>\n<div>\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">Market Segmentation</h1>\n\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n\n    <section class=\"section section-container\">\n        <div class=\"progress-container\">\n            <div class=\"dotted-bar\"></div>\n            <label>Steps</label>\n            <div class=\"step\">\n                <span class=\"stepCounter\" data-step=\"1\">1</span>\n                <label>Select <strong>Services</strong></label>\n            </div>\n            <div class=\"step\">\n                <span class=\"stepCounter\" data-step=\"2\">2</span>\n                <label>Add <strong>Defaults</strong></label>\n            </div>\n            <div class=\"step\">\n                <span class=\"stepCounter\" data-step=\"3\">3</span>\n                <label>Configure <strong>Workspaces</strong></label>\n            </div>\n            <div class=\"step active step-last\">\n                <span class=\"stepCounter\" data-step=\"4\">4</span>\n                <label>Apply <strong>Styles</strong></label>\n            </div>\n\n        </div>\n    </section>\n    <section class=\"section section-container\">\n        <div class=\"section-header\">\n            <h3>Select a Visual Theme</h3>\n        </div>\n\n        <div class=\"form-container section-body\">\n            <div>\n                <div class=\"field-container-md\">\n                    <div>\n                        <div class=\"theme-block selected\">\n                            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":57,"column":38},"end":{"line":57,"column":49}}}) : helper)))
    + "/theme-1-thumb.png\" width=\"150px\">\n                            <span class=\"title\">Theme-1-name</span>\n                        </div>\n                        <div class=\"theme-block\">\n                            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":61,"column":38},"end":{"line":61,"column":49}}}) : helper)))
    + "/theme-2-thumb.png\" width=\"150px\">\n                            <span class=\"title\">Theme-2-name</span>\n                        </div>\n                        <div class=\"theme-block\">\n                            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":65,"column":38},"end":{"line":65,"column":49}}}) : helper)))
    + "/theme-3-thumb.png\" width=\"150px\">\n                            <span class=\"title\">Theme-3-name</span>\n                        </div>\n                        <div class=\"theme-block\">\n                            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":69,"column":38},"end":{"line":69,"column":49}}}) : helper)))
    + "/theme-4-thumb.png\" width=\"150px\">\n                            <span class=\"title\">Theme-4-name</span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"field-container-md theme-preview\">\n                    <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":75,"column":30},"end":{"line":75,"column":41}}}) : helper)))
    + "/theme-1.png\">\n                    <h4 class=\"theme-name\">Theme 1 Name</h4>\n                </div>\n            </div>\n        </div>\n\n\n        <div class=\"section-header\">\n            <h3>Select a Menu Layout</h3>\n        </div>\n\n        <div class=\"form-container section-body\">\n            <div>\n                <div class=\"field-container-md\">\n                    <label  class=\"main-label\"><input type=\"radio\" name=\"topMenu\" value=\"top\" data-bind=\"model\"/>\n                        <span>Top Level Navigation</span>\n                    </label>\n                    <br/>\n                    <label  class=\"main-label\"><input type=\"radio\" name=\"topMenu\" value=\"left\" data-bind=\"model\"/>\n                        <span>Left-hand Navigation</span>\n                    </label>\n                </div>\n                <div class=\"field-container-md\">\n                    <img class=\"nav-preview\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":98,"column":50},"end":{"line":98,"column":61}}}) : helper)))
    + "/top-nav.png\" style=\"max-width:100%\">\n                </div>\n            </div>\n        </div>\n\n\n\n        <div>\n            <div>\n                <button type=\"button\" class=\"btn btn-primary submit\" data-action=\"segmentationList\">Save</button>\n                <button type=\"button\" class=\"btn btn-secondary \" data-action=\"back\">Back</button>\n                <button type=\"button\" class=\"btn btn-link\">Cancel</button>\n            </div>\n        </div>\n\n\n    </section>\n</div>\n";
},"useData":true});