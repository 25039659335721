import GridApi from 'common/dynamicPages/api/grid';
import constants from 'app/smbBillManagement/constants';
import ContextApi from 'common/dynamicPages/api/context';
import GridLayout from './gridLayout';
import approveBillGridTmpl from './approveBillGrid.hbs';

export default GridLayout.extend({
    name: 'Approve',
    template: approveBillGridTmpl,

    initialize(options) {
        const gridOptions = {
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            columnsToBeHidden: constants.COMMON_COLUMNSTOHIDE,
            context: ContextApi.menuContext.getContext('BOS_BILLMGMT'),
        };

        this.gridView = GridApi.createServiceGridView(gridOptions);
        this.entitlements = options.entitlements;
        this.alertRegion = options.alertRegion;
    },

    onShow(...args) {
        this.approveBillGrid.show(this.gridView);
        GridLayout.prototype.onShow.apply(this, args);
    },

    removeActions() {
        this.gridView.grid.collection.each((rowParam) => {
            const row = rowParam;
            let VIEW = null;
            let MODIFY = null;
            let APPROVE = null;
            let UNAPPROV = null;
            let REJECT = null;
            const { STATUS } = row.attributes;
            for (let i = 0; i < row.buttons.length; i += 1) {
                if (row.buttons[i].action === 'SELECT') {
                    VIEW = row.buttons[i];
                } else if (row.buttons[i].action === 'MODIFY') {
                    MODIFY = row.buttons[i];
                } else if (row.buttons[i].action === 'APPROVE') {
                    APPROVE = row.buttons[i];
                } else if (row.buttons[i].action === 'UNAPPROV') {
                    UNAPPROV = row.buttons[i];
                } else if (row.buttons[i].action === 'REJECT') {
                    REJECT = row.buttons[i];
                }
            }
            if (STATUS === 'EN') {
                row.buttons = [];
                if (APPROVE) {
                    row.buttons.push(APPROVE);
                }
                row.buttons.push(VIEW);
                if (MODIFY) {
                    row.buttons.push(MODIFY);
                }
                if (REJECT) {
                    row.buttons.push(REJECT);
                }
            } else if (STATUS === 'AP') {
                row.buttons = [];
                if (UNAPPROV) {
                    row.buttons.push(UNAPPROV);
                }
                row.buttons.push(VIEW);
            }
        });
    },
});
