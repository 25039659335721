import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';

import styles from './MobileGridFooter.styles';

const MobileGridFooter = ({ classes, children }) => (
  <div className={classes.root} data-qa="mobilegrid-footer">
    <div className={classes.iconWrap}>
      {children}
    </div>
  </div>
);

MobileGridFooter.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(MobileGridFooter);
