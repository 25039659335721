import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import GluDrawer from '@glu/side-nav/lib/Drawer';
import { withStyles } from '@glu/theming';
import { Columns, Filters } from '@glu/data-components';
import {
  XCircleNextIcon
} from '@glu/icons-react';
import { Tabs, Tab } from '@glu/tabs-react';

import { styles } from './Drawer.styles';

export function DrawerComponent({
  activeTab,
  classes,
  data,
  hideColumns,
  menuOpen,
  onChange,
  onDrawerToggle,
  onTabClick,
  ...props
}) {
  const handleTabChange = (_, value) => {
    onTabClick(value);
  };

  return (
    <GluDrawer
      open={menuOpen}
      anchorTo="right"
      startingSize={0}
      size={450}
    >
      <div
        className={classes.background}
      >
        <button
          data-qa="drawerClose"
          className={classes.closeButton}
          onClick={onDrawerToggle}
          aria-label={locale.get('close')}
          type="button"
        >
          <XCircleNextIcon />
        </button>
        {/* Replace with tabs component */}
        <div
          className={classes.headerWrapper}
        >
          {menuOpen
          && (
          <Tabs value={activeTab} className={classes.tabs}>
            <Tab value="filters" onChange={handleTabChange} className={classes.tab}>
              {locale.get('filters')}
            </Tab>
            {!hideColumns
              && (
              <Tab value="columns" onChange={handleTabChange} className={classes.tab}>
                {locale.get('columns')}
              </Tab>
              )
            }
          </Tabs>
          )
          }
        </div>
        {activeTab === 'columns' && (
          <Columns
            {...props}
            data={data}
            onChange={(...args) => {
              onChange(...args);
              onDrawerToggle();
            }}
            dark
          />
        )}
        {activeTab === 'filters'
            && (
              <div
                className={classes.content}
              >
                <Filters
                  {...props}
                  onChange={(...args) => {
                    onChange(...args);
                    onDrawerToggle();
                  }}
                  datePickerProps={{
                    forcePositionY: true
                  }}
                  data={data}
                  dark
                  filterables={data.columns.filter(col => col.filterable !== false)}
                />
              </div>
            )
        }
      </div>
    </GluDrawer>
  );
}

DrawerComponent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        colId: PropTypes.string
      })
    )
  }).isRequired,
  hideColumns: PropTypes.bool,
  onDrawerToggle: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onTabClick: PropTypes.func.isRequired
};

DrawerComponent.defaultProps = {
  hideColumns: false
};

export default withStyles(styles)(DrawerComponent);
