export default {
    startConversation: {
        textAlign: 'center',
        margin: '30px 0',
    },
    startConversationText: {
        color: '#fff',
        fontSize: '18px',
        marginRight: '5px',
        marginLeft: '5px',
        verticalAlign: 'middle',
        paddingLeft: '10px',
    },

    iconChatBubble: {
        top: '5px',
        position: 'relative',
        '& svg': {
            fill: '#fff',
            width: '20px',
            height: '20px',
        },
    },
};
