import userInfo from 'etc/userInfo';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import { log } from '@glu/core';
import services from 'services';
import FieldCollection from './fieldCollection';
import CheckFreeField from '../model/checkFreeFieldModel';
import InquirySearch from '../service/inquirySearch';

export default FieldCollection.extend({
    model: CheckFreeField,
    isNew: false,
    url: services.ssoCrudStub,

    initialize(options) {
        // Fetches user data assigned to the CheckFree fields.
        this.inquiry = new InquirySearch();
        this.inquiry.addSearch('VENDCODE', options.vendorCode);
        this.inquiry.addSearch('USER_ID', userInfo.get('id'));
        this.inquiry.addSearch('USERGROUP', userInfo.get('group'));
        this.inquiry.setTypeCode('SSOTPV');
        this.inquiry.setProductCode('SSOOUT');
        this.inquiry.setInquiryId(29550);

        /*
         * If the user data query returns a null set, this query will return
         * the two fields associated with CheckFree but with no FIELDVALUE property.
         * This is necessary to send the create request because we need the TPF_FIELD_ID.
         */
        this.fieldMetaInquiry = new InquirySearch();
        this.fieldMetaInquiry.addSearch('VENDCODE', options.vendorCode);
        this.fieldMetaInquiry.setTypeCode('SSOTPV');
        this.fieldMetaInquiry.setProductCode('SSOOUT');
        this.fieldMetaInquiry.setInquiryId(29552);
    },

    /**
     * Main function for the collection to interface with the server
     * to handle transportation of data from client to server.
     * @param  {String} method [Values such as create, read, update and delete]
     * @return {Promise|undefined} Returns a promise
     */
    sync(method) {
        if (method === 'read') {
            return this.inquiry.send()
                .then(util.bind(this.metaInjection, this))
                .then(util.bind(this.parse, this), util.bind(this.error, this))
                .then(util.bind(this.reset, this), util.bind(this.error, this));
        }
        if (method === 'create') {
            return http.post(
                `${this.url}ADD`,
                this.toPair(),
                util.bind(this.handleSuccess, this),
                util.bind(this.error, this),
            );
        }
        if (method === 'update') {
            return http.post(
                `${this.url}UPDATE`,
                this.toPair(),
                util.bind(this.handleSuccess, this),
                util.bind(this.error, this),
            );
        }
        if (method === 'delete') {
            return http.post(
                `${this.url}DELETE`,
                this.toPair(),
                util.bind(this.handleSuccess, this),
                util.bind(this.error, this),
            );
        }
        return undefined;
    },

    /**
     * Maps the response from the read request to a more manageable
     * data structure for the UI.
     * @param  {Object} response [description]
     * @return {Object}          [description]
     */
    parse(response) {
        return util.map(response.inquiryResponse.rows, row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));
    },

    /**
     * Inspects the server's response.  If there are no records listed it will
     * send off the second request.  This won't resolve this link in the promise chain until
     * the deferred object returned from fieldMetaInquiry has satisified.  Essentially
     * hijacking the
     * promise chain with a new one that will return valuable data for the UI.
     *
     * @param  {Object} response [Data coming back from the server]
     */
    metaInjection(response) {
        if (response.inquiryResponse.rows.length > 0) {
            return response;
        }
        this.isNew = true;
        return this.fieldMetaInquiry.send();
    },

    /**
     * Generic error handler for the web service requests.
     * @param  {Object} response [description]
     */
    error(response) {
        log.error('Error with web service: ', response);
        return response;
    },

    /**
     * Saves the list of fields being managed by the collection.
     * @return {Promise}
     */
    save() {
        if (this.isNew) {
            return this.sync('create', this);
        }
        return this.sync('update', this);
    },

    /**
     * Sends delete request to the server.
     * Removes event listeners and resets the collection.
     * @return {Collection}
     */
    destroy() {
        this.stopListening();
        this.sync('delete', this);
        this.reset([]);
        return this;
    },

    /**
     * Generic success handler.
     */
    handleSuccess(response) {
        log.info('Successful request', response);
        return response;
    },
});
