import './themeDefaults';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import locale from '@glu/locale';
import {
  Button, PRIMARY, SECONDARY, SMALL
} from '@glu/buttons-react';
import FileUpload from './FileUpload';
import Table from './Table';
import FileRow from './FileRow';
import useStyles from './UploadTable.styles.js';

const UploadTable = ({
  categories,
  className,
  dataQa,
  dateFormat,
  enableNotes,
  notePlaceholder,
  fileStatus,
  onClearAll,
  onUpload,
  permissions,
  preSelectedFiles,
  showProgress,
  progressFormatter,
  disableButtons,
  onRemoveSingle,
  ...rest
}) => {
  const fileCount = useRef(0);
  const enrichFiles = (files) => files.map(file => {
    const richFile = {
      file,
      filename: file.name,
      id: `${file.lastModified}${file.size}${fileCount.current += 1}`
    };
    if (categories.length) {
      richFile.category = categories[0].value;
    }
    if (permissions.length) {
      richFile.permission = permissions[0].value;
    }
    if (dateFormat) {
      richFile.date = new Date();
    }
    return richFile;
  });

  const [richFiles, setRichFiles] = useState(enrichFiles(preSelectedFiles));
  const classes = useStyles({ rest });

  const columnHeaders = [
    locale.get('headerFileName'),
    categories.length && locale.get('headerCategory'),
    dateFormat && locale.get('headerDate'),
    permissions.length && locale.get('headerPermission'),
    enableNotes && locale.get('headerNotes'),
    showProgress && locale.get('headerProgress'),
    locale.get('headerActions')
  ].filter(Boolean);

  const incompleteFiles = richFiles.reduce((acc, richFile) => {
    const status = fileStatus.find(({ id }) => id === richFile.id);
    if (!status || !status.complete) return [...acc, richFile];
    return acc;
  }, []);

  const handleFileSelect = (name, value) => {
    setRichFiles(prev => prev.concat(enrichFiles(value)));
  };

  const handleUploadAll = () => {
    onUpload(incompleteFiles);
  };

  const handleUploadSingle = (richFile) => {
    onUpload([richFile]);
  };

  const handleClearAll = () => {
    setRichFiles([]);
    onClearAll();
  };

  const handleClearSingle = (id) => {
    const status = fileStatus.find((file) => id === file.id);
    if (status && status.inProgress) {
      onRemoveSingle(id);
    }
    setRichFiles((prev) => prev.filter((file) => file.id !== id));
  };


  return (
    <div className={className} data-qa={`upload-table${dataQa ? `-${dataQa}` : ''}`}>
      <FileUpload
        dataQa={dataQa}
        onChange={handleFileSelect}
        {...rest}
      />
      {incompleteFiles.length > 0 && (
      <>
        <Table
          columnHeaders={columnHeaders}
        >
          <tbody data-qa="upload-table">
            {incompleteFiles.map((richFile) => (
              <FileRow
                categories={categories}
                dateFormat={dateFormat}
                enableNotes={enableNotes}
                notePlaceholder={notePlaceholder}
                fileStatus={fileStatus.find(file => file.id === richFile.id)}
                key={richFile.id}
                onRemove={() => { handleClearSingle(richFile.id); }}
                onUpload={handleUploadSingle}
                permissions={permissions}
                richFile={richFile}
                setRichFiles={setRichFiles}
                showProgress={showProgress}
                progressFormatter={progressFormatter}
              />
            ))}
          </tbody>
        </Table>
        <div className={classes.actions}>
          <Button
            disabled={disableButtons}
            data-qa="button-done"
            size={SMALL}
            text={locale.get('buttonUploadAll')}
            type="button"
            onClick={handleUploadAll}
            variant={PRIMARY}
          />
          <Button
            disabled={disableButtons}
            data-qa="button-done"
            size={SMALL}
            text={locale.get('buttonClearAll')}
            onClick={handleClearAll}
            type="button"
            variant={SECONDARY}
          />
        </div>
      </>
      )}
    </div>
  );
};


UploadTable.propTypes = {
  /** Options to display as selectable categories */
  categories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })),

  /** Class passed to parent element for custom styling */
  className: PropTypes.string,

  /** String given to parent elements data-qa html attribute for easy targeting */
  dataQa: PropTypes.string,

  /** Toggle to disable buttons during the upload  */
  disableButtons: PropTypes.bool,

  /** Whether to show a notes field for text to attach to file */
  enableNotes: PropTypes.bool,

  /** Placeholder for note */
  notePlaceholder: PropTypes.string,

  /** The condition of each of the files that have been sent to the server last */
  fileStatus: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    inProgress: PropTypes.bool
  })),

  /** String passed to moment to assign as uploaded date */
  dateFormat: PropTypes.string,

  /** Options to display as selectable permissions */
  permissions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })),

  /** Array of Files to preselect without user input */
  preSelectedFiles: PropTypes.arrayOf(PropTypes.shape({})),

  /** Toggle display and UX of progress column */
  showProgress: PropTypes.bool,

  /** function to custom format the progress value (.toPrecision(4) by default)
   * gets the progress as number and is expected to return number */
  progressFormatter: PropTypes.func,

  onRemoveSingle: PropTypes.func,
  onClearAll: PropTypes.func,
  onUpload: PropTypes.func
};

UploadTable.defaultProps = {
  categories: [],
  className: '',
  dataQa: undefined,
  dateFormat: undefined,
  enableNotes: false,
  notePlaceholder: '',
  disableButtons: false,
  fileStatus: [],
  onRemoveSingle: /* istanbul ignore next */ () => {},
  onClearAll: /* istanbul ignore next */ () => {},
  onUpload: /* istanbul ignore next */ () => {},
  permissions: [],
  preSelectedFiles: [],
  showProgress: true,
  progressFormatter: undefined
};

export default UploadTable;
