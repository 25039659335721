import { Layout, util } from '@glu/core';
import template from './expiresOnTheDay.hbs';
import locale from '@glu/locale';
import { clearError } from '../../schedulerUtils';

export default Layout.extend({

    template,

    ui: {
        $expiresOnRadio: '[data-hook="expires-date-on-radio"]',
        $expiresWithinRadio: '[data-hook="expires-date-within-radio"]',
        $expiresOnTheDateSelect: '[data-hook="expires-date-on-select"]',
        $expiresOnTheDateTime: '[data-hook="expires-on-date-time"]',
        $expiresWithinTheDays: '[data-hook="expires-within-days"]',
        $expiresWithinTheTime: '[data-hook="expires-within-date-time"]',
    },

    events: {
        'change @ui.$expiresOnRadio': 'setExpiresOnDay',
        'change @ui.$expiresWithinRadio': 'setExpiresOnDay',
        'click @ui.$expiresOnRadio': 'clickRadioButtonsForExpiresOn',
        'click @ui.$expiresWithinRadio': 'clickRadioButtonsForExpiresWithin',
    },

    initialize(options) {
        this.model = options.model;

        this.daysInMonth = util.range(1, 32).map((value) => {
            const label = `scheduler.days.${value}${this.ending(value)}`;

            return {
                value,
                label,
            };
        });

        if (util.isUndefined(this.model.get('expiresDateInputMode'))) {
            this.model.set('expiresDateInputMode', (this.model.get('type') === 'WEEKLY') ? 'EXPIRES_WITHIN' : 'EXPIRES_ON');
        }

        if (this.model.get('expiresDateInputMode') === 'EXPIRES_ON') {
            if (util.isUndefined(this.model.get('expiresOnTheDay'))) {
                this.model.set({
                    expiresOnTheDay: [1],
                });
            }
        }

        this.listenTo(this.model, 'change:type', this.processRecurrencePeriodChange);
        this.listenTo(this.appBus, 'dueOnDate:click', this.processDueOnDateClick);
        this.listenTo(this.appBus, 'dueWithinMonthly:click', this.processDueWithinClick);
        this.listenTo(this.appBus, 'expiresOnDueDate:check', this.processExpiresOnDueDateCheck);
    },

    templateHelpers() {
        return {
            daysInMonth: this.daysInMonth,
            cid: this.model.cid,
            userTimeZone: this.model.get('userTimeZone'),
        };
    },

    setRadioState() {
        if (this.model.get('type') === 'WEEKLY') {
            this.$(this.ui.$expiresOnRadio).prop('disabled', true);
        } else {
            this.$(this.ui.$expiresOnRadio).prop('disabled', false);
        }
    },

    onShow() {
        const value = this.model.get('expiresDateInputMode');
        this.setRadioState();
        this.commonExpireScreenFields(value);
        // A temp fix for NH-152296 where views don't update
        this.ui.$expiresOnTheDateSelect.val(this.model.get('expiresOnTheDay'));
    },

    processDueOnDateClick() {
        this.ui.$expiresOnRadio.prop('disabled', false);
    },

    processDueWithinClick() {
        this.ui.$expiresOnRadio.prop('disabled', true);
        this.model.set('expiresDateInputMode', 'EXPIRES_WITHIN');
        this.commonExpireScreenFields(this.model.get('expiresDatreInputMode'));
    },

    setExpiresOnDay(e) {
        const value = this.$(e.currentTarget).val();
        this.commonExpireScreenFields(value);
    },

    /**
     * If user selects a expires on date, clear any client
     * side error(s) on the specific day of the month fields
     *
     * this code is needed because the code in viewBinding does not get
     * invoked when the validateField function for the fields 'expiresOnTheDay'
     * and 'expiresOnDateTime' are invoked after their validators are removed
     * TODO - Clean up to rely on the validation in the models
     *
     */
    clickRadioButtonsForExpiresOn() {
        const error = this.model.get('error');
        if (error) {
            if (error.expiresOnTheDay) {
                delete error.expiresOnTheDay;
            }
            this.ui.$expiresOnTheDateSelect.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="expiresOnTheDay"]').text('').closest('.has-error').removeClass('has-error');
            if (error.expiresOnDateTime) {
                delete error.expiresOnDateTime;
            }
            this.ui.$expiresOnTheDateTime.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="expiresOnDateTime"]').text('').closest('.has-error').removeClass('has-error');
            this.model.set('error', error);
        }
    },


    /**
     * If user selects a expires within days, clear any client
     * side error(s) on the specific day of the month fields
     *
     * this code is needed because the code in viewBinding does not get
     * invoked when the validateField function for the fields 'expiresWithin'
     * and 'expiresWithinDateTime' are invoked after their validators are removed
     * TODO - Clean up to rely on the validation in the models
     *
     */
    clickRadioButtonsForExpiresWithin() {
        const error = this.model.get('error');
        if (error) {
            if (error.expiresWithin) {
                delete error.expiresWithin;
            }
            this.ui.$expiresWithinTheDays.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="expiresWithin"]').text('').closest('.has-error').removeClass('has-error');
            if (error.expiresWithinDateTime) {
                delete error.expiresWithinDateTime;
            }
            this.ui.$expiresWithinTheTime.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="expiresWithinDateTime"]').text('').closest('.has-error').removeClass('has-error');
            this.model.set('error', error);
        }
    },

    commonExpireScreenFields(value) {
        const maskOptions = {
            alias: 'datetime',
            mask: 'h:s t\\m',
            hourFormat: '12',
            placeholder: '00:00 am',
        };
        this.ui.$expiresOnTheDateTime.inputmask(maskOptions);
        this.ui.$expiresWithinTheTime.inputmask(maskOptions);
        const expiresOnDueDate = this.model.get('expiresOnDueDate') === '1';
        if (value === 'EXPIRES_WITHIN') {
            if (!expiresOnDueDate) {
                this.$(this.ui.$expiresOnRadio).prop('checked', false);
                this.$(this.ui.$expiresWithinRadio).prop('checked', true);
                this.$(this.ui.$expiresOnRadio).parent().parent().removeClass('required');
            } else {
                this.expiresOnDueDateSettings()
            }
            this.$(this.ui.$expiresOnTheDateSelect).prop('disabled', true);
            this.$(this.ui.$expiresOnTheDateTime).prop('disabled', true);
            this.$(this.ui.$expiresWithinTheDays).prop('disabled', expiresOnDueDate);
            this.$(this.ui.$expiresWithinTheTime).prop('disabled', expiresOnDueDate);
            if (this.model.validators) {
                this.model.removeValidator('expiresOnTheDay', 'exists');
                this.model.removeValidator('expiresOnDateTime', 'exists');
                // remove any errors
                clearError(this.$('[data-validate="expiresOnTheDay"]'));
                clearError(this.$('[data-validate="expiresOnDateTime"]'));
            }
            if (!expiresOnDueDate) {
                this.$(this.ui.$expiresWithinRadio).parent().parent().addClass('required');
                this.model.addValidator('expiresWithin', { exists: true, description: locale.get('RFP.scheduler.expires.within.days') });
                this.model.addValidator('expiresWithinDateTime', { exists: true, description: locale.get('RFP.scheduler.expires.within.time') });
            }
            const expiresWithinInputMaskOptions = {
                mask: '99',
                placeholder: '',
                showMaskOnHover: false,
                showMaskOnFocus: false,
                clearMaskOnLostFocus: true,
            };
            const expirationMaxValueAllowed = this.model.get('expirationMaxValue');
            if (!Number.isNaN(expirationMaxValueAllowed) && expirationMaxValueAllowed) {
                expiresWithinInputMaskOptions.max = expirationMaxValueAllowed;
            }
            this.ui.$expiresWithinTheDays.inputmask('integer', expiresWithinInputMaskOptions);
        }
        if (value === 'EXPIRES_ON') {
            if (!expiresOnDueDate) {
                this.$(this.ui.$expiresOnRadio).prop('checked', true);
                this.$(this.ui.$expiresWithinRadio).prop('checked', false);
                this.$(this.ui.$expiresWithinRadio).parent().parent().removeClass('required');
            } else {
                this.expiresOnDueDateSettings()
            }
            this.$(this.ui.$expiresOnTheDateSelect).prop('disabled', expiresOnDueDate);
            this.$(this.ui.$expiresOnTheDateTime).prop('disabled', expiresOnDueDate);
            this.$(this.ui.$expiresWithinTheDays).prop('disabled', true);
            this.$(this.ui.$expiresWithinTheTime).prop('disabled', true);
            if (this.model.validators) {
                this.model.removeValidator('expiresWithin', 'exists');
                this.model.removeValidator('expiresWithinDateTime', 'exists');
                // remove any errors
                clearError(this.$('[data-validate="expiresWithin"]'));
                clearError(this.$('[data-validate="expiresWithinDateTime"]'));
            }
            if (!expiresOnDueDate) {
                this.$(this.ui.$expiresOnRadio).parent().parent().addClass('required');
                this.model.addValidator('expiresOnTheDay', { exists: true, description: locale.get('RFP.scheduler.expires.on.day') });
                this.model.addValidator('expiresOnDateTime', { exists: true, description: locale.get('RFP.scheduler.expires.on.time') });
            }
            if (this.model.get('expiresOnTheDay') === undefined) {
            	this.model.set('expiresOnTheDay', '1');
            }
        }
    },

    /**
     * @name expiresOnDueDateSettings
     * @description when expiresOnDueDate is set, we need to disable the expires on related fields
     */
    expiresOnDueDateSettings() {
        this.$(this.ui.$expiresOnRadio).prop('checked', false);
        this.$(this.ui.$expiresWithinRadio).prop('checked', false);
        this.$(this.ui.$expiresOnRadio).prop('disabled', true);
        this.$(this.ui.$expiresOnRadio).parent().parent().removeClass('required');
        this.$(this.ui.$expiresWithinRadio).parent().parent().removeClass('required');
        this.$(this.ui.$expiresWithinRadio).prop('disabled', true);
    },

    /**
     * @method processRecurrencePeriodChange
     * Resets values when recurrence period is changed or when
     * the expires on due date checkbox is set to off
     * @param {boolean} [expiresDueDate] - expires on due date checkbox
     * when called from processExpiresOnDueDateCheck - always false from there,
     * otherwise it is not passed.
     */
    processRecurrencePeriodChange(expiresDueDate) {
        if (this.model.get('type') === 'WEEKLY') {
            this.model.set('expiresDateInputMode', 'EXPIRES_WITHIN');
            this.$(this.ui.$expiresOnRadio).prop('disabled', true);
        } else {
            this.model.set('expiresDateInputMode', 'EXPIRES_ON');
            this.$(this.ui.$expiresOnRadio).prop('disabled', false);
        }

        // Need to enable regardless of weekly/monthly
        if (!util.isNullOrUndefined(expiresDueDate) && !expiresDueDate) {
            this.ui.$expiresWithinRadio.prop('disabled', false);
        }

        if (this.model.get('type') === 'MONTHLY' && this.model.get('dueDateInputMode') === 'DUE_WITHIN') {
            this.model.set('expiresDateInputMode', 'EXPIRES_WITHIN');
            this.$(this.ui.$expiresOnRadio).prop('disabled', true);
        }
        this.commonExpireScreenFields(this.model.get('expiresDateInputMode'));
    },

    processExpiresOnDueDateCheck(parameter) {
        if (parameter) {
            this.ui.$expiresOnRadio.prop('checked', !parameter);
            this.ui.$expiresWithinRadio.prop('checked', !parameter);
            this.ui.$expiresOnRadio.prop('disabled', parameter);
            this.ui.$expiresWithinRadio.prop('disabled', parameter);
            this.ui.$expiresOnTheDateSelect.prop('disabled', parameter);
            this.ui.$expiresOnTheDateTime.prop('disabled', parameter);
            this.ui.$expiresWithinTheDays.prop('disabled', parameter);
            this.ui.$expiresWithinTheTime.prop('disabled', parameter);
            this.$(this.ui.$expiresWithinRadio).parent().parent().removeClass('required');

            if (this.model.validators) {
                this.model.removeValidator('expiresWithin', 'exists');
                this.model.removeValidator('expiresWithinDateTime', 'exists');
                // remove any errors
                clearError(this.$('[data-validate="expiresWithin"]'));
                clearError(this.$('[data-validate="expiresWithinDateTime"]'));
            }

            const error = this.model.get('error');
            if (error) {
                if (error.expiresOnTheDay) {
                    delete error.expiresOnTheDay;
                }
                if (error.expiresOnDateTime) {
                    delete error.expiresOnDateTime;
                }
                if (error.expiresWithin) {
                    delete error.expiresWithin;
                }
                if (error.expiresWithinDateTime) {
                    delete error.expiresWithinDateTime;
                }
                this.ui.$expiresOnTheDateSelect.closest('.form-group').find('.help-block').empty();
                this.ui.$expiresOnTheDateTime.closest('.form-group').find('.help-block').empty();
                this.ui.$expiresWithinTheDays.closest('.form-group').find('.help-block').empty();
                this.ui.$expiresWithinTheTime.closest('.form-group').find('.help-block').empty();
                this.$('[data-validate="expiresWithin"]').text('').closest('.has-error').removeClass('has-error');
                this.$('[data-validate="expiresWithinDateTime"]').text('').closest('.has-error').removeClass('has-error');
                this.$('[data-validate="expiresOnTheDay"]').text('').closest('.has-error').removeClass('has-error');
                this.$('[data-validate="expiresOnDateTime"]').text('').closest('.has-error').removeClass('has-error');
                this.model.set('error', error);
            }
        } else {
            this.processRecurrencePeriodChange(parameter);
        }
    },

    ending(day) {
        switch (day) {
        case 1:
        case 21:
        case 31:
            return 'st'; // first
        case 2:
        case 22:
            return 'nd'; // second
        case 3:
        case 23:
            return 'rd'; // third
        default:
            return 'th'; // fourth and everything else
        }
    },

});
