import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import template from './switchWidget.hbs';

const SwitchWidget = ItemView.extend({
    template,
    className: 'switch-widget',

    ui: {
        $checkbox: 'input[type="checkbox"]',
    },

    events: {
        'click @ui.$checkbox': 'handleChange',
    },

    initialize(options) {
        this.switchFieldName = options.column.get('fieldName');
        this.switchCurrentState = this.model.get(this.switchFieldName) !== null
            ? +this.model.get(this.switchFieldName) : 0;
        this.switchPositiveLabel = locale.get('features.switch.label.positive');
        this.switchNegativeLabel = locale.get('features.switch.label.negative');
    },

    handleChange() {
        const updatedState = this.switchCurrentState ? 0 : 1;
        this.switchCurrentState = updatedState;
        this.model.set({
            STAGED: `${updatedState}`,
        });
        this.render();
    },

    templateHelpers() {
        return {
            isDisabled: !!this.options.grid.collection.find(model => (
                model.get('STATUS') === 'MD' || model.get('STATUS') === 'AP'
            )),
            switchId: this.model.cid,
            switchName: this.switchFieldName,
            switchActivated: !!this.switchCurrentState,
            switchClass: this.switchCurrentState === 1 ? 'positive' : 'negative',
            switchLabel: this.switchCurrentState === 1
                ? this.switchPositiveLabel : this.switchNegativeLabel,
        };
    },

});

export default SwitchWidget;
