import util from '@glu/core/src/util';
import BaseController from 'system/baseController';
import DynamicPages from 'common/dynamicPages/actions';
import ELearning from 'app/elearning/actions';
import RemoteDepositCapture from 'mobile/remoteDepositCapture/actions';
import Dashboard from './workspace/actions';
import Reports from './reports/actions';
import Setup from './setup/actions';
import BeneficiaryAddressBook from './bab/actions';
import SMB from './smb/actions';
import Utilities from './utilities/actions';
import SmbContact from './smbContact/actions';
import Loans from './loans/actions';
import Payments from './payments/actions';
import Transfers from './transfers/actions';
import CheckManagement from './checkManagement/actions';
import BTR from './balanceAndTransaction/actions';
import Administration from './administration/actions';
import UserMaint from './administration/userActions';
import Lockbox from './lockbox/actions';
import Bills from './smbBillManagement/actions';
import CashFlow from './cashflow/actions';
import Notifications from './notifications/actions';
import ChallengeManager from './challengeManager/actions';
import Deeplinks from './deeplinks/actions';
import PanelApproval from './panelApproval/actions';
import SmbPayments from './smbPayments/actions';
import ServiceRequest from './serviceRequest/actions';
import SMBAdmin from './smbAdmin/actions';
import Invoice from './smbInvoice/actions';
import Showcase from './showcase/actions';
import MultiAdd from './multiAdd/actions';
import ScheduledExports from './scheduledExports/actions';
import AlertContentManagement from './alertContentManagement/actions';
import pcmActions from '../pcm/app/actions';

const Alerts = Administration;

const dgbActions = Object.assign(
    Dashboard,
    Setup,
    BeneficiaryAddressBook,
    Reports,
    SMB,
    DynamicPages,
    Showcase,
    Utilities,
    SmbContact,
    Loans,
    SmbPayments,
    Payments,
    Transfers,
    CheckManagement,
    Alerts,
    BTR,
    Alerts,
    Administration,
    UserMaint,
    Lockbox,
    Bills,
    Invoice,
    CashFlow,
    Notifications,
    ChallengeManager,
    Deeplinks,
    SMBAdmin,
    ELearning,
    RemoteDepositCapture,
    PanelApproval,
    MultiAdd,
    ServiceRequest,
    MultiAdd,
    ScheduledExports,
    AlertContentManagement,
);

const actions = util.extend(dgbActions, pcmActions);

export default BaseController.extend(actions);
