/* eslint backbone/no-constructor:0 */
import c3 from 'c3';
import ItemView from './itemView';
import util from './util';

export default ItemView.extend({
  ui: {
    $chart: '.glu-chart'
  },

  template() {
    return '<div class="glu-chart"></div>';
  },

  constructor(options = {}) {
    this.c3Options = options.c3;

    if (util.isEmpty(this.c3Options)) {
      throw new Error('You must provide a set of c3 options.');
    }

    ItemView.prototype.constructor.apply(this, arguments);
  },

  onRender() {
    this.setDefaults();
    util.delay(this.buildChart.bind(this), 50);
  },

  onClose() {
    if (this.c3) {
      this.c3.destroy();
    }
  },

  setDefaults() {
    util.defaults(this.c3Options, {
      bindto: this.ui.$chart[0],
      color: {
        pattern: this.getColorPalette()
      }
    });
  },

  buildChart() {
    this.c3 = c3.generate(this.c3Options);

    // Per third-party audit; SVG charts are extremely hard to interpret for screen-readers, so they are "hidden" by default by Glu.
    this.$('.glu-chart svg').attr('aria-hidden', true);
  },

  getColorPalette() {
    return [
      '#5591B1',
      '#659941',
      '#7F9FB4',
      '#45A9A4',
      '#8C4755',
      '#A0C1E0',
      '#408068',
      '#AC6D9B',
      '#326598',
      '#A3B43B'
    ];
  }
});

