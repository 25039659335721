import ItemView from '@glu/core/src/itemView';

export default ItemView.extend({
    template() {
        return '';
    },

    className: 'dynamic-full-page',

    initialize(options) {
        this.menuctx = options.menuctx;
    },

    onShow() {
        this.navigateTo(`PAYMENTS/ssoout/${this.menuctx.typeCode}`);
    },
});
