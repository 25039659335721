import transferTemplateModify from 'app/payments/views/modifyTemplate';

export default transferTemplateModify.extend({
    initialize() {
        this.options = {
            menuCategory: 'PMTS',
            serviceName: 'template/transfer',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSFERS_TEMPLATE_LIST',
            mode: 'modify',
        };

        transferTemplateModify.prototype.init.call(this);
    },
});
