import ItemView from '@glu/core/src/itemView';
import addNewAddressEntryTmpl from './addNewAddressEntry.hbs';

export default ItemView.extend({
    template: addNewAddressEntryTmpl,
    className: 'add-new-address-entry field-container field-container-lg required',

    ui: {
        $saveBtn: 'button[data-hook="save"]',
        $cancelBtn: 'button[data-hook="cancel"]',
    },

    events: {
        'click @ui.$saveBtn': 'save',
        'click @ui.$cancelBtn': 'cancel',
    },

    initialize(options) {
        this.fieldLabel = options.fieldLabel;

        this.listenTo(
            this.model,
            {
                sync: this.handleSync,
                destroy: this.remove,
            },
        );
    },

    handleSync() {
        if (this.model.has('error')) {
            // trigger built-in error message binding
            this.model.trigger('invalid');
        }
    },

    save() {
        if (!this.model.isValid()) {
            return;
        }

        this.model.save();
    },

    cancel() {
        this.model.destroy();
    },

    templateHelpers() {
        return {
            id: this.cid,
            fieldLabel: this.fieldLabel,
        };
    },
});
