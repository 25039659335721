var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog modal-xlg glu-dialog custom-dialog\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <button aria-hidden=\"false\" data-dismiss=\"modal\" class=\"close\" type=\"button\"><span class=\"icon-close\"></span></button>\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.forecast",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":46}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"categoryName") || (depth0 != null ? lookupProperty(depth0,"categoryName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"categoryName","hash":{},"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":63}}}) : helper)))
    + "</h2>\n        </div>\n\n        <div class=\"modal-body\">\n\n            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.distribution.type.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":61}}}))
    + "</p>\n\n            <div data-region=\"display-factor\"></div>\n\n              <ul id=\"row-distribution-tablist\" class=\"NavTabs nav nav-tabs\"\n                data-hook=\"getNavTabs\"\n                role=\"tablist\">\n                <li class=\"NavTabs-item is-active\"\n                    role=\"presentation\">\n                    <a aria-controls=\"flat\"\n                        class=\"NavTabs-link\"\n                        data-toggle=\"tab\"\n                        href=\"#flat\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.flat",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":35},"end":{"line":23,"column":61}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                    role=\"presentation\">\n                    <a aria-controls=\"percentage\"\n                        class=\"NavTabs-link\"\n                        data-toggle=\"tab\"\n                        href=\"#percentage\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.percentage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":35},"end":{"line":32,"column":67}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                    role=\"presentation\">\n                    <a aria-controls=\"trend-line\"\n                        class=\"NavTabs-link\"\n                        data-toggle=\"tab\"\n                        href=\"#trend-line\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.trend.line",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":35},"end":{"line":41,"column":67}}}))
    + "\n                    </a>\n                </li>\n              </ul>\n\n              <!-- Tab panes -->\n              <div class=\"tab-content\">\n                <div role=\"tabpanel\" class=\"tab-pane active\" id=\"flat\">\n\n                    <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.yearly.change.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":23},"end":{"line":50,"column":62}}}))
    + "</p>\n\n                    <div class=\"form-inline\">\n\n                    <div class=\"form-group\">\n                        <label for=\"create-distribution-start-flat\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.montly.value",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":68},"end":{"line":55,"column":102}}}))
    + "</label>\n                        <input type=\"text\" min=\"0\" step=\"100\" name=\"create-distribution-start\" id=\"create-distribution-start-flat\" class=\"form-control\" data-number-accelerate>\n                    </div>\n\n                    </div> <!-- /.form-inline -->\n\n                </div> <!-- /.tab-pane -->\n\n\n                <div role=\"tabpanel\" class=\"tab-pane\" id=\"percentage\">\n                    <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.percent.increase.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":23},"end":{"line":65,"column":65}}}))
    + "</p>\n\n\n                    <div class=\"form-inline\">\n                        <div class=\"form-group\">\n                            <label for=\"create-distribution-start-percentage\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.first.month.value",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":70,"column":78},"end":{"line":70,"column":117}}}))
    + "</label>\n                            <input type=\"text\" min=\"0\" step=\"100\" name=\"create-distribution-start\" id=\"create-distribution-start-percentage\" class=\"form-control\" data-number-accelerate>\n                        </div>\n\n                        <div class=\"percent-controls\">\n\n                            <div class=\"form-group\">\n                                <label for=\"distribution-percent-change\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.percent.change",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":89},"end":{"line":77,"column":125}}}))
    + "</label>\n                                <div class=\"input-group\">\n                                    <input type=\"number\" value=\"0\" step=\".1\" id=\"distribution-percent-change\" name=\"distribution-percent\" class=\"form-control\" aria-describedby=\"distribution-percent-addon\" data-number-accelerate>\n                                    <span class=\"input-group-addon\" id=\"distribution-percent-addon\">%</span>\n\n                                </div>\n                            </div>\n\n                            <span class=\"joining-word\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.per",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":55},"end":{"line":85,"column":80}}}))
    + "</span>\n\n                            <div class=\"form-group\">\n                                <label for=\"distribution-period\" class=\"sr-only\"></label>\n                                <select id=\"distribution-period\" name=\"distribution-period\" class=\"form-control\">\n                                    <option value=\"month\" selected=\"selected\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.month",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":78},"end":{"line":90,"column":105}}}))
    + "</option>\n                                    <option value=\"year\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.year",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":57},"end":{"line":91,"column":83}}}))
    + "</option>\n                                </select>\n                            </div>\n                        </div> <!-- /.percent-controls -->\n                    </div> <!-- /.form-inline -->\n\n                </div> <!-- /.tab-pane -->\n\n\n                <div role=\"tabpanel\" class=\"tab-pane\" id=\"trend-line\">\n                    <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.trend.line.select.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":101,"column":23},"end":{"line":101,"column":66}}}))
    + "</p>\n\n                    <div class=\"form-inline\">\n                        <div class=\"form-group\">\n                            <label for=\"create-distribution-start-trend-line\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.first.month.value",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":78},"end":{"line":105,"column":117}}}))
    + "</label>\n                            <input type=\"text\" min=\"0\" step=\"100\" name=\"create-distribution-start\" id=\"create-distribution-start-trend-line\" class=\"form-control\" data-number-accelerate>\n                        </div>\n\n                        <div class=\"form-group\">\n                            <label for=\"create-distribution-end\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.last.month.value",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":110,"column":65},"end":{"line":110,"column":103}}}))
    + "</label>\n                            <input type=\"text\" min=\"0\" step=\"100\" name=\"create-distribution-end\" id=\"create-distribution-end\" class=\"form-control\" value=\"0\" data-number-accelerate>\n                        </div>\n                    </div>\n\n                    <div class=\"line-shape-buttons\" data-toggle=\"buttons\">\n                        <label class=\"btn active\" data-power=\"1\">\n                            <input type=\"radio\" name=\"line-shape\" autocomplete=\"off\" checked>\n                            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.line.growth.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":118,"column":50},"end":{"line":118,"column":87}}}))
    + "</span>\n                            <span class=\"graph\"></span>\n                        </label>\n\n\n                        <label class=\"btn\" data-power=\"2\">\n                            <input type=\"radio\" name=\"line-shape\" autocomplete=\"off\">\n                            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.line.growth.msg2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":125,"column":50},"end":{"line":125,"column":88}}}))
    + "</span>\n                            <span class=\"graph\"></span>\n                        </label>\n\n                        <label class=\"btn\" data-power=\"3\">\n                            <input type=\"radio\" name=\"line-shape\" autocomplete=\"off\">\n                            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.line.growth.msg3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":50},"end":{"line":131,"column":88}}}))
    + "</span>\n                            <span class=\"graph\"></span>\n                        </label>\n\n                        <label class=\"btn\" data-power=\"5\">\n                            <input type=\"radio\" name=\"line-shape\" autocomplete=\"off\">\n                            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.line.growth.msg4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":137,"column":50},"end":{"line":137,"column":88}}}))
    + "</span>\n                            <span class=\"graph\"></span>\n                        </label>\n\n                        <label class=\"btn\" data-power=\"2\" data-start-steep=\"true\">\n                            <input type=\"radio\" name=\"line-shape\" autocomplete=\"off\">\n                            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.line.growth.msg5",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":143,"column":50},"end":{"line":143,"column":88}}}))
    + " </span>\n                            <span class=\"graph\"></span>\n                        </label>\n\n                        <label class=\"btn\" data-power=\"3\" data-start-steep=\"true\">\n                            <input type=\"radio\" name=\"line-shape\" autocomplete=\"off\">\n                            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.line.growth.msg6",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":149,"column":50},"end":{"line":149,"column":88}}}))
    + "</span>\n                            <span class=\"graph\"></span>\n                        </label>\n\n                        <label class=\"btn\" data-power=\"5\" data-start-steep=\"true\">\n                            <input type=\"radio\" name=\"line-shape\" autocomplete=\"off\">\n                            <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.line.growth.msg7",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":155,"column":50},"end":{"line":155,"column":88}}}))
    + "</span>\n                            <span class=\"graph\"></span>\n                        </label>\n\n                    </div>\n\n                </div> <!-- /.tab-pane -->\n\n              </div>\n\n              <div class=\"table-container\">\n                  <table class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"flowTypeClassName") || (depth0 != null ? lookupProperty(depth0,"flowTypeClassName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"flowTypeClassName","hash":{},"data":data,"loc":{"start":{"line":166,"column":32},"end":{"line":166,"column":53}}}) : helper)))
    + "\">\n                    <!-- TODO create a dateRowView and a data row here -->\n                    <thead></thead>\n                    <tbody></tbody>\n                </table>\n              </div>\n\n        </div>\n\n        <div class=\"modal-footer\">\n            <button class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.save.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":176,"column":44},"end":{"line":176,"column":74}}}))
    + "</button>\n            <button data-dismiss=\"modal\" class=\"btn btn-link\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":177,"column":62},"end":{"line":177,"column":94}}}))
    + "</button>\n        </div>\n\n    </div>\n</div>\n";
},"useData":true});