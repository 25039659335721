import Controller from '@glu/core/src/controller';

export default Controller.extend({
    initialize() {
        window.addEventListener('message', this.handleMessage.bind(this));
    },

    /**
     * Listener for window's message event.
     * @param  {[MessageEvent]} evt [description]
     * @return {[void]}
     */
    handleMessage(evt) {
        switch (evt.data.type) {
        case 'redirect':
            this.trigger('redirect', evt);
            break;
        default:
            this.trigger(evt.data.type, evt);
            break;
        }
    },
});
