import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { $ } from '@glu/core';
import { withStyles } from '@glu/theming';
import NoDataOrLoading from 'pcm/common/components/NoDataOrLoading';
import ErrorMsgPtx from 'pcm/common/components/ErrorMsgPtx';
import { getCheckPreviewImage, parseCheckPreview, getCheckPreviewDownloadPdf } from './dataUtils';
import styles from './CheckPreview.styles';

class CheckPreview extends React.Component {
    state = {
        error: false,
        isLoading: true,
        checkData: [],
    };

    downloadPdf = () => {
        const { ptxCheckId } = this.props;

        getCheckPreviewDownloadPdf(ptxCheckId).then(
            data =>
            // eslint-disable-next-line no-debugger
                data
            , (err) => {
                const msg = (err instanceof Error) ? err.message : err.statusText;
                this.setState({
                    error: msg,
                    isLoading: false,
                });
            },
        );
    }

    componentDidMount() {
        const { ptxCheckId } = this.props;

        getCheckPreviewImage(ptxCheckId).then((data) => {
            const checkData = parseCheckPreview(data);
            this.setState({
                isLoading: false,
                checkData,
            }, () => {
                $('[data-toggle="popover"]').popover();
            });
        }, (err) => {
            const msg = (err instanceof Error) ? err.message : err.statusText;
            this.setState({
                error: msg,
                isLoading: false,
            });
        });
    }

    render() {
        const { error, isLoading, checkData } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.chekPreviwContainer}>
                {error && <ErrorMsgPtx title={locale.get('ptx.error.prefixPreviewCheck')} />}
                {
                    checkData.length > 0 ?
                        <React.Fragment>
                            <div className="document-buttons row no-padding">
                                <div className="pull-left" />
                                <div
                                    className="pull-right"
                                    data-trigger="hover"
                                    data-container="body"
                                    data-toggle="popover"
                                    data-placement="top"
                                    data-content={locale.get('ptx.check.download')}
                                >
                                    <button onClick={this.downloadPdf} type="button" className="span-popover-trigger btn btn-tertiary js-btn-download"><span className="icon-download" /></button>
                                </div>
                            </div>
                            {checkData.map((imageUrl, index) => (
                                <img key={`check-preview-${index}`} alt="" src={imageUrl} />
                            ))}
                        </React.Fragment> : (
                            <div className="text-center">
                                <NoDataOrLoading
                                    isLoading={isLoading}
                                />
                            </div>
                        )
                }
            </div>
        );
    }
}

CheckPreview.propTypes = {
    ptxCheckId: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(CheckPreview);
