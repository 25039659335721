import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import StopPaymentsMultiAddView from './stopPaymentsMultiAddView';

export default StopPaymentsMultiAddView.extend({

    initialize(options) {
        StopPaymentsMultiAddView.prototype.initialize.call(this, options);

        // get the models from cache
        this.placeStopModels = options.placeStopModels;
    },

    /**
     * @name additionalRender
     * @description override of stopPaymentsMultiAddView function.  show the
     * toggle view
     */
    additionalRender() {
        StopPaymentsMultiAddView.prototype.additionalRender.call(this);
        // update the 'clear'/'X' buttons
        this.collectionView.updateButtonLabel();
        // enable/disable the 'Add' button
        this.setAddButtonState();
    },

    /**
     * @name addToCollection
     * @description adds the input model(s) to the collection
     * @param  {[object]} model - new model to be added to the collection
     * For check inquiry place stop, the initial add to the collection will be
     * the model(s) selected from the listview
     */
    initialAddToCollection(model) {
        let newModels;
        if (this.placeStopModels) {
            newModels = this.placeStopModels.map((placeStop) => {
                // map the attributes from the placeStop model to the baseModel
                const newPlaceStop = this.deepCloneModel(this.baseModel);
                const placeStopNVP = util.reduce(newPlaceStop.keys(), (acc, key) => {
                    acc[key] = placeStop.get(key) || '';
                    return acc;
                }, {});
                newPlaceStop.set(
                    placeStopNVP,
                    {
                        silent: true,
                    },
                );
                return newPlaceStop;
            });
            this.collection.add(newModels);
        } else {
            this.collection.add(model);
        }
    },

    updateCurrentModelsWithStopType() {
        // update stop type in all the models from check inquiry
        this.collection.forEach((model) => {
            this.updateStopTypeInModel(
                model,
                {
                    value: this.toggleValue1,
                    description: this.toggleLabel1,
                },
            );
        });
        this.updateStopTypeInModel(
            this.baseModel,
            {
                value: this.toggleValue1,
                description: this.toggleLabel1,
            },
        );
    },

    /**
     * @method setAddButtonState
     * @description enables or disables the add button based on the number of
     * items added to the collection and the
     * tenant configuration maximum number rows that can be added in a multi-entry page
     *
     */
    setAddButtonState() {
        this.disableAddButton(this.collection.length >= this.maxRows);
    },

    /**
     * @method cancel
     * @description cancel button handler
     * return to caller workspace
     * @param {event} evt
     */
    cancel(evt) {
        store.unset('check_inq_AFFECTEDCONTEXT');

        this.disableButtons();
        evt.stopPropagation();
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method saveItems
     * @description save button handler.
     * Saves the stop payments and stores the context key for the confirmation
     * message (for the check inquiry page)
     */
    saveItems() {
        StopPaymentsMultiAddView.prototype.saveItems.call(this);
        store.set('check_inq_AFFECTEDCONTEXT', this.contextKey);
    },
});
