import services from 'services';
import { postData } from 'common/util/services';

export default {
    panelApprovalEnabled: false,
    /**
     * Send a request to the server to determine if the company has panel approvals
     * configured
     * @returns {Promise}
     */
    getPanelApprovalConfig(mode) {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    actionMode: mode,
                    productCode: 'PAY',
                    functionCode: 'TMPL',
                },
                inquiryId: '41234',
                allowDuplicates: true,
                queryType: 'Query',
            },
            formatterType: 'Query',
        };

        return postData(services.inquiryQueryResults, data)
            .then(this.setPanelApprovalValue.bind(this));
    },

    /**
     * Dig into the response and pull out the value for panel approval configuration
     * and set the panelApprovalEnabled variable
     * @param {Object} response - standard response
     * @returns {string}
     */
    setPanelApprovalValue(response) {
        try {
            const [row] = response.queryResponse.QueryData.queryRows;
            const [item] = row.mapDataList;
            this.panelApprovalEnabled = item.value === '1';
        } catch (error) {
            this.panelApprovalEnabled = false;
        }
    },
};
