import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    sync(method, model, options) {
        const url = services.generateUrl('/tableMaintenance/lockboxRemitter/getListView');

        http.post(url, {}, (result) => {
            const remitters = [];
            util.each(result.rows, (data) => {
                const remitter = {};
                for (let x = 0; x < data.columns.length; x += 1) {
                    remitter[data.columns[x].fieldName] = data.columns[x].fieldValue;
                }
                remitters.push(remitter);
            });
            options.success(remitters);
        }, (result) => {
            options.error(result);
        });
    },
});
