import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    defaults: {
        LOCKBOX_CODE: '',
        NAME: '',
        IMAGE_VIEWING: false,
        DETAIL_REPORTS: false,
        REPORT_FLOAT: false,
        REMITTERMANAGEBYBANK: 'Y',
    },

    initialize() {
        this.validators = {
            NAME: {
                description: locale.get('LBX.LocationName'),
                exists: true,
            },

            LOCKBOX_CODE: {
                description: locale.get('LBX.LocationCode'),
                exists: true,
            },
        };
    },

    sync(method, model, options) {
        let url;
        let data;
        if (method === 'create') {
            data = this.convertModelAttributesToServerJSON(model);
            url = services.generateUrl('lockbox/add');

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'update') {
            url = services.generateUrl('lockbox/update');

            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            data = this.convertModelAttributesToServerJSON(model);
            url = services.generateUrl('lockbox/delete');

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    convertModelAttributesToServerJSON(model) {
        const jsonData = [];

        util.each(model.toJSON(), (value, key) => {
            let localValue = value;
            if (['IMAGE_VIEWING', 'DETAIL_REPORTS', 'REPORT_FLOAT'].indexOf(key) > -1) {
                localValue = localValue ? 'Y' : 'N';
            }
            if (['hasChildren', 'driverFields', 'childNumber', 'fieldData', 'isBatch', 'isChild'].indexOf(key) === -1) {
                jsonData.push({
                    name: key,
                    value: localValue,
                });
            }
        });

        return {
            item: jsonData,
        };
    },
});
