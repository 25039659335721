const POS_PAY_FILTERID = 20533;
const REVERSE_POS_PAY_FILTERID = 20543;
const ELECTRONIC_POS_PAY_FILTERID = 20523;

const Constants = {
    RISK: 'RISK',
    CM: 'CM',
    BATCH: 'BATCH',
    PRODUCTCODE: 'PRODUCTCODE',
    TYPECODE: 'TYPECODE',
    FUNCTIONCODE: 'FUNCTIONCODE',
    TRUE: 'true',
    INSERT: 'insert',
    IMPORT: 'import',
    ACTIONMODEL: '-actionModel',
    POS_PAY_FILTERID: 20533,
    REVERSE_POS_PAY_FILTERID: 20543,
    ELECTRONIC_POS_PAY_FILTERID: 20523,
    POS_PAY_TYPE: 'BMPPDEC',
    REVERSE_POS_PAY_TYPE: 'CMPPDEC',
    ELECTRONIC_POS_PAY_TYPE: 'EBMPPDEC',
    ACH_AUTH_RULES_TYPE: 'EBMPPRUL',
    ACH_AUTH_RULES_FUNCTION: 'INST',
    HISTORY: 'history',
    DEFAULT_ITEM_PER_ROW: 20,
    filterId: {
        BMPPDEC: POS_PAY_FILTERID,
        CMPPDEC: REVERSE_POS_PAY_FILTERID,
        EBMPPDEC: ELECTRONIC_POS_PAY_FILTERID,
    },

    ADMIN_ADVANCED_SEARCH_FILTER_IDS_BY_TYPE: {
        BMPPDEC: 20550,
        CMPPDEC: 20551,
        EBMPPDEC: 20549,
    },

    FILE_EXTRACTION_DETAIL_INQ_BMPP: 20583,
    FILE_EXTRACTION_DETAIL_INQ_CMPP: 20586,
    FILE_EXTRACTION_DETAIL_INQ_EBMPP: 20589,
    CHKISSUE_STOPCANCE_IMPOERTRESULT_INQ: 20579,
    CHKISSUE_INQUIRYID: 20569,
    CHKISSUE_STOPCANCEL_INQUIRYID: 20587,
    CHKISSUE_STOPCANCEL_INQUIRYID_CLIENT: 20586,
    POS_PAY_NEEDS_DECISION_VIEWID: '20532',
    ELECTRONIC_POS_PAY_NEEDS_DECISION_VIEWID: '20522',
    REVERSE_POS_PAY_NEEDS_DECISION_VIEWID: '20542',
    NEXTDAY_POS_PAY_NEEDS_DECISION_VIEWID: '20535',
    STANDARD_POS_PAY_NEEDS_DECISION_VIEWID: '20534',
    ELECTRONIC_POS_PAY_PREFILL_FIELDS: ['ACCOUNT_NUMBER', 'COMPANYID',
        'COMPANYNAME', 'AMOUNT', 'CREDITDEBITCODE', 'BANKCODE', 'ACCOUNT'],
    ELECTRONIC_POS_PAY_PAY_MESSAGE: 'RISK-EBMPPDEC-PAY-MESSAGE',
    ELECTRONIC_POS_PAY_RULE_PREFILL: 'RISK-EBMPPRUL-INSERT-PREFILL',
};

export default Constants;
