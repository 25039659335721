var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <span class=\"name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BOS.PendingPaidAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":3,"column":57}}}))
    + ": </span><span class=\"value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"PENDING_PAID_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"PENDING_PAID_AMOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"PENDING_PAID_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":3,"column":86},"end":{"line":3,"column":109}}}) : helper)))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"AMOUNT_DUE") || (depth0 != null ? lookupProperty(depth0,"AMOUNT_DUE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"AMOUNT_DUE","hash":{},"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":19}}}) : helper)))
    + "</div>\n<div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"exists") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":5},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});