var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " image-search-field-container ";
},"3":function(container,depth0,helpers,partials,data) {
    return " field-container-sm ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"TYPE") || (depth0 != null ? lookupProperty(depth0,"TYPE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TYPE","hash":{},"data":data,"loc":{"start":{"line":21,"column":35},"end":{"line":21,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TEXT") || (depth0 != null ? lookupProperty(depth0,"TEXT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TEXT","hash":{},"data":data,"loc":{"start":{"line":21,"column":47},"end":{"line":21,"column":57}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"menuitem.SETUP.imageSearch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":70}}}))
    + "</h1>\n</div>\n\n<div class=\"section-container image-search\">\n\n    <div class=\"alert-message\">\n        <div class=\"alert-region\" data-region=\"imageSearchAlertRegion\"></div>\n    </div>\n\n    <div class=\"section-body form-container\">\n        <div class=\"required field-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMobileScreen") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":132}}})) != null ? stack1 : "")
    + "\">\n            <label for=\"imageType\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(alias1,"imageSearch.imageType",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":72}}}))
    + "</label>\n            <select class=\"form-control form-select\" data-bind=\"model\" id=\"imageType\" name=\"imageType\">\n                <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"imageTypes") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n\n    <div class=\"section-body\">\n        <div data-region=\"filterRegion\" aria-live=\"polite\"></div>\n        <button type=\"button\" name=\"search\" class=\"btn btn-primary\" style=\"display:none\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":89},"end":{"line":29,"column":115}}}))
    + "</button>\n    </div>\n\n    <div data-region=\"imageSearchResultsRegion\" aria-live=\"polite\"></div>\n\n</div>\n";
},"useData":true});