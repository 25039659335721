import ItemView from '@glu/core/src/itemView';
import Grid from '@glu/grid';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import dialog from '@glu/dialog';
import ListBuilder from 'common/listBuilder/listBuilder';
import DataEntitlements from 'app/administration/collection/user/dataEntitlements';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import TemplateView from './templateView';
import restrictedTemplatesTmpl from './restrictedTemplates.hbs';

export default ItemView.extend({
    template: restrictedTemplatesTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        grid: '.grid',
        editItemsButton: '.edit-items-button',
        $popovers: '[data-toggle="popover"]',
        $assignAllChk: '[data-hook="assign-all-checkbox"]',
        $gridSection: '[data-hook="grid-section"]',
        $assignAllMsg: '.assign-all-msg',
    },

    events: {
        'click @ui.editItemsButton': 'openEditItemsDialog',
        'click @ui.$assignAllChk': 'changeAssignAll',
    },

    initialize(options) {
        this.mode = options.mode;
        this.entity = options.entity;
        const self = this;

        if (self.model.availableTemplates) {
            this.setHasLoadedRequiredData(true);
        }

        self.selectedTemplates = self.model.get('dataEntitlements');
        self.model.availableTemplates = self.model.availableTemplates || new DataEntitlements(
            [],
            {
                userGroup: options.userGroup,
                inquiryId: self.model.get('inquiryId'),
                keyCol: self.model.get('keyCol'),
                configuredDataType: self.model.get('configuredDataType'),
            },
        );
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        if (this.hasLoadedRequiredData()) {
            this.gridView = new Grid({
                columns: [{
                    field: 'CMB_TEMPLATE_DESCRIPTION',
                    label: locale.get('RTGS.gridcolumns.description'),
                }, {
                    field: 'TEMPLATECODEENTITLEMENT',
                    label: locale.get('RTGS.*.template_code'),
                }, {
                    field: 'CMB_TYPE_DESCRIPTION',
                    label: locale.get('PAY.PaymentType'),
                }],

                collection: this.selectedTemplates,
                emptyViewText: locale.get('uce.emptyGrid.restrictedTemplates'),
                el: this.ui.grid,
            }).render();

            const checked = this.model.get('assignAll');

            this.ui.$assignAllChk.prop('checked', checked);
            this.ui.$gridSection.toggle(!checked);
            this.ui.$assignAllMsg.toggle(checked);
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;

        /*
         * FIX: NH-38079 - similarly to the fix for NH-30592 in smbUser.js
         * Filters out the _ALL_ NV pair from selected templates.
         */
        self.selectedTemplates.each((model) => {
            if (model.get('dataEntitlementAttPart1') === '_ALL_') {
                model.collection.remove(model);
            }
        });

        this.model.availableTemplates.fetch({
            success(accounts) {
                const toRemove = [];

                accounts.each((rec) => {
                    const selectedRec = self.selectedTemplates.matchRecord(rec, accounts.keyCol);

                    if (selectedRec) {
                        toRemove.push(rec);
                        selectedRec.mergeData(rec.attributes);
                    }
                });

                self.model.availableTemplates.remove(toRemove);

                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    openEditItemsDialog() {
        dialog.open(new ListBuilder({
            id: `uce-${this.model.id}`,
            sourceCollection: this.model.availableTemplates,
            className: 'dgb-list-builder list-builder',
            targetCollection: this.selectedTemplates,
            labelView: TemplateView,

            localeKeys: {
                moveSelectedItems: 'uce.moveSelectedTemplates',
                moveAllItems: 'uce.moveAllTemplates',
                removeSelectedItems: 'uce.removeSelectedTemplates',
                removeAllItems: 'uce.removeAllTemplates',
                filter: 'uce.filterTemplates',
                sourceListHeader: 'uce.nAvailableTemplates',
                targetListHeader: 'uce.nAssignedTemplates',
                title: 'uce.addBankTemplates',
                save: 'uce.addTemplates',
                cancel: 'button.cancel',
            },
        }));
    },

    changeAssignAll(e) {
        const { checked } = e.target;

        this.model.set('assignAll', checked);

        this.ui.$gridSection.toggle(!checked);
        this.ui.$assignAllMsg.toggle(checked);
    },

    templateHelpers() {
        const self = this;

        return {
            readOnly: this.mode === constants.MODES.VIEW,

            assignAllTooltip() {
                return locale.get(`${self.entity}.assign-all-tooltip-templates`);
            },
        };
    },
});
