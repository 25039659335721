import { useEffect } from 'react';

const useModelListener = (model, eventName, callback) => {
    useEffect(() => {
        const cleanUp = () => {
            model.off(eventName);
        };
        model.on(eventName, callback);
        return cleanUp;
    }, [model, callback, eventName]);
};

export default useModelListener;
