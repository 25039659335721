var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"icon-info\" data-toggle=\"popover\" data-trigger=\"hover focus\" data-content=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.note.payment.totals.rep.iAmt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":95},"end":{"line":4,"column":140}}}))
    + "\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"dataCard__total\">\n        <p class=\"dataCard__total__value\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":64}}}))
    + " "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"currency") : depth0), depth0))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"dataCard__total__subtype\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dataCard__header\">\n    <p class=\"dataCard__title\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subGroupName") : depth0), depth0))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isIndicative") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "    <p class=\"dataCard__count\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"transactionCount") : depth0), depth0))
    + "</p>\n</div>\n<ul class=\"dataCard__list\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"totals") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "\n</ul>\n";
},"useData":true});