import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import helper from 'system/mfa/helper';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import validators from 'system/gluOverride/core/internal/validators';

export default Model.extend({
    initialize() {
        /*
         * Workaround for issue where validator.sameValue is not updated to
         * value in gluOverrides
         */
        const action = this.get('challengedAction');
        if (action && action.actionMode === 'LOGIN' && action.functionCode === 'USERS'
            && action.productCode === '_CORE' && action.typeCode === '_USER') {
            locale.set('validator.sameValue', validators.validationMessages.sameValue);
        }
        this.validators = {
            token: {
                description: locale.get('mfa.rsa.authorization.code'),
                exists: true,
            },

            newTokenPin: {
                description: locale.get('mfa.rsa.newpin.label'),
                otherDescription: locale.get('mfa.rsa.newpin.confirm.label'),
                sameValue: 'confirmNewTokenPin',
                overrideError: true,
            },

            confirmNewTokenPin: {
                description: locale.get('mfa.rsa.newpin.confirm.label'),
                otherDescription: locale.get('mfa.rsa.newpin.label'),
                sameValue: 'newTokenPin',
            },
        };
    },

    /**
     * @method addSerialNumberValidator
     * Helper method to add validator for Serial Number
     */
    addSerialNumberValidator() {
        this.validators = {
            tokenString: {
                exists: true,
                description: locale.get('mfa.rsa.token.serial'),
            },
        };
    },

    addNextTokenCodeValidator() {
        this.validators = {
            nextToken: {
                exists: true,
                description: locale.get('mfa.rsa.next.code.label'),
            },
        };
    },

    addNewPinValidator() {
        this.validators = {
            newTokenPin: {
                exists: true,
                description: locale.get('mfa.rsa.enter.pin'),
                otherDescription: locale.get('mfa.rsa.reenter.pin'),
                sameValue: 'confirmNewTokenPin',
            },
            confirmNewTokenPin: {
                exists: true,
                description: locale.get('mfa.rsa.reenter.pin'),
                otherDescription: locale.get('mfa.rsa.enter.pin'),
                sameValue: 'newTokenPin',
            },
        };
    },

    sync(method, model, options) {
        if (method === 'create') {
            let data = null;
            let service = null;
            if (model.get('showForgotPin')) {
                data = this.convertForgotPinModelAttributesToServerJSON();
                service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('resetPin')}`);
            } else {
                data = this.convertModelAttributesToServerJSON();
                service = services.generateUrl(`mfaService/${helper.translateUrlForLogin('validate')}`);
            }
            http.post(service, data, (response) => {
                options.success(response);
            }, (response) => {
                // Passing default App Resource incase response is null
                if (model.get('showForgotPin')) {
                    options.error(response);
                } else {
                    options.error('mfa.error.message', response);
                }
            });
        }
    },

    convertForgotPinModelAttributesToServerJSON() {
        const challengeAction = new Model(this.get('challengedAction'));
        const jsonData = {
            challengedAction: challengeAction.toJSON(),
            challengeType: this.get('challengeType'),
            tokenString: this.get('tokenString').trim(),
        };

        return jsonData;
    },

    convertModelAttributesToServerJSON() {
        const challengeAction = new Model(this.get('challengedAction'));
        const jsonData = {
            challengedAction: challengeAction.toJSON(),
            tokenPassCode: this.get('token'),
            tokenPin: this.get('tokenPin'),
            prompts: this.get('prompts'),
        };

        const updatedPrompts = util.map(jsonData.prompts, (prompt) => {
            const data = {};
            data.promptKey = prompt.promptKey;
            data.editable = prompt.editable;

            if (prompt.promptKey === 'NEW_PIN') {
                data.promptValue = this.get('newTokenPin');
            } else if (prompt.promptKey === 'NEW_PIN_CONFIRM') {
                data.promptValue = this.get('confirmNewTokenPin');
            } else if (prompt.promptKey === 'NEXT_TOKEN_CODE') {
                data.promptValue = this.get('nextToken');
            } else {
                data.promptValue = prompt.promptValue;
            }
            return data;
        });

        jsonData.prompts = updatedPrompts;

        if (window.machineSecret) {
            jsonData.customParameters = {};
            jsonData.customParameters.item = [{
                name: 'MachineNounce',
                value: window.machineSecret.fetchMachineNonce(),
            }, {
                name: 'MachineSequenceNounce',
                value: window.machineSecret.fetchSequenceNonce(),
            }];
        }

        return jsonData;
    },
});
