import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import QueryCollection from 'common/api/queriesCollection';
import TemplateModel from 'common/api/templateModel';

export default Collection.extend({
    model: TemplateModel,

    processQueries(options) {
        return Promise.all(util.map(
            options.fieldNames,
            fieldName => new Promise((resolve, reject) => {
                const queryCollection = new QueryCollection(
                    null,
                    {
                        url: options.url,
                        fieldName,
                        context: options.context,
                    },
                );
                queryCollection.fetch({
                    success() {
                        resolve(queryCollection);
                    },
                    error: reject,
                });
            }),
        ));
    },
});
