import ItemView from '@glu/core/src/itemView';
import templateViewTmpl from './templateView.hbs';

export default ItemView.extend({
    template: templateViewTmpl,

    events: {
        'click input': 'toggleSelected',
    },

    templateHelpers() {
        return {
            hx: 'h4',
            name: this.model.name(),
            description: this.model.description(),
            paymentType: this.model.paymentType(),
        };
    },
});
