import appConfigParams from 'system/webseries/models/applicationConfiguration';
import configuration from 'system/configuration';
import locale from '@glu/locale';

const setStatus = (formView) => {
    const { state: formState, model } = formView;
    // the status comes down as the code.  we need to display the description
    const statusDescription = locale.get(model.get('STATUS'));
    if (formState === 'view') {
        formView.$('[name="STATUS"]').text(statusDescription);
    } else {
        model.set('STATUS', statusDescription);
    }
};

export default function (form, initialState) {
    const formState = form.formView.state;
    const { model } = form.formView;
    const status = form.field('STATUS');
    const bankDefined = form.field('BANKMAINTAINED');
    const usergroup = form.field('USERGROUP');
    const roleId = form.field('ROLEID');

    if (initialState) {
        usergroup.shouldBeReadOnly(true); // make usergroup protected
        const bankMaintained = appConfigParams.getValue('ROLE', 'bankMaintainedRoles') === '1';
        if (formState === 'insert') {
            status.shouldBeHidden();
        } else {
            roleId.shouldBeReadOnly(true);
            status.shouldBeReadOnly(true);

            setStatus(form.formView);
        }

        if (bankMaintained && configuration.isAdmin()) {
            form.formView.$('#BANKMAINTAINED').prop('checked', model.get('BANKMAINTAINED') === '1');
            bankDefined.shouldBeVisible(true);
        } else {
            bankDefined.shouldBeHidden();
        }
    }
}

export { setStatus };
