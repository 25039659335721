import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'app/administration/constants';
import workspaceHelper from 'common/workspaces/api/helper';
import setupAccessibleTabs, { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import AlertsView from './alertsTabView';
import RecipientGroupsView from './recipientGroupsTabView';
import CustomRecipientsView from './customRecipientsTabView';
import MySettingsView from './mySettingsTabView';
import template from './alertsCenter.hbs';
import { isDeepLinked } from '../../../../common/util/deeplinkUtil';

const AlertsCenterView = Layout.extend({
    template,
    defaultTab: 'alertsList',
    validTabs: ['alertsList', 'recipientGroups', 'recipients', 'mySettings'],

    regions: {
        alertsRegion: '.alerts-region',
        recipientGroupsRegion: '.recipient-groups-region',
        customRecipientsRegion: '.custom-recipients-region',
        mySettingsRegion: '.my-settings-region',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $tabSections: '.tab-section',
    },
    isInitialRender: true,
    events: {
        'click @ui.$navItems': 'changeTab',
    },

    /**
     * Options can pass in the defaultTab as the tab to navigate to.
     */
    initialize(options) {
        this.options = options;
        if (options.defaultTab && util.indexOf(this.validTabs, options.defaultTab) >= 0) {
            this.defaultTab = options.defaultTab;
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            this.setInitialTab();
            this.isInitialRender = false;
        } else {
            this.loadRequiredData();
        }
        if (isDeepLinked()) {
            this.$el.find('.page-header-wrapper').first().hide();
        }
    },

    /**
     * check entitlements for access to recipient, recipient group and
     * user settings widget for the tab control
     */
    loadRequiredData() {
        const alertReq = this.hasAccessPromise(constants.ALERTREQUESTCONTEXT);
        const recipientReq = this.hasAccessPromise(constants.RECIPIENTREQUESTCONTEXT);
        const recipientGroupReq = this.hasAccessPromise(constants.RECIPIENTGROUPREQUESTCONTEXT);
        const userSettingReq = this.hasAccessPromise(constants.USERSETTINGSREQUESTCONTEXT);

        Promise.all([alertReq, recipientGroupReq, recipientReq, userSettingReq]).then((res) => {
            [this.canShowAlertTab,
                this.canShowRecipientGroupTab,
                this.canShowRecipientTab,
                this.canShowUserSettingsTab] = res;
            // set the default tab to the first available tab
            const tabIndex = [
                this.canShowAlertTab,
                this.canShowRecipientGroupTab,
                this.canShowRecipientTab,
                this.canShowUserSettingsTab,
            ].findIndex(tab => tab);
            this.defaultTab = this.validTabs[tabIndex];
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    /**
     *
     * @param {object} request object containing productCode, functionCode,
     *                 typeCode and actionMode
     * @returns {Promise}
     */
    hasAccessPromise(request) {
        return new Promise(((resolve, reject) => {
            const url = services.generateUrl('accessService/hasAccess');
            http.post(url, request, (response) => {
                resolve(response);
            }, (e) => {
                reject(e);
            });
        }));
    },

    /**
     * Set the defaultTab as tab to display.
     */
    setInitialTab() {
        const curTab = store.get('alertCenter-currentTab');
        /*
         * NH-94975 defaultTab can't have priority if we desire to
         * show previous tab on returning to the alertCenter list
         */
        const gotoTab = curTab || this.defaultTab;
        this.setCurrentTab(this.ui.$navItems.find(`[data-tab="${gotoTab}"]`).parent());

        this.setTabView();
    },

    /**
     * Start change of tab
     * @param  {Event} e - object from clicked tab
     */
    changeTab(e) {
        const $el = $(e.currentTarget).closest('.NavTabs-item');

        // fail fast and do nothing if clicking on an active tab
        if ($el.hasClass('is-active')) {
            toggleTabs(this);
            return;
        }

        this.setCurrentTab($el);

        this.setTabView();
    },

    /**
     * Remove active class from current tab and tab content
     */
    resetTabView() {
        // remove active class from currentTab
        this.ui.$navItems.filter('.is-active').removeClass('is-active').find('a').attr('aria-selected', 'false');

        // remove active class from currently active tab section
        this.ui.$tabSections.filter('.active').removeClass('active').attr('aria-hidden', 'true');
    },

    /**
     * Sets currentTab object for use
     * @param  {object} $el clicked tab
     */
    setCurrentTab($el) {
        this.currentTabText = $el.text();
        // cache currently active tab
        this.currentTab = {
            $tab: $el,
            $tabSection: this.ui.$tabSections.filter(`[data-tabcontent="${$el.find('a').data('tab')}"]`),
            tabValue: $el.find('a').data('tab'),
        };
        // store the tabValue so we can reset there when user returns to the alert center
        store.set('alertCenter-currentTab', this.currentTab.tabValue);
    },

    /**
     * Add active class to current tab and tab content which makes them visible
     */
    setTabView() {
        this.resetTabView();

        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }

        // determine if view has been instatiated or not
        if (this.isViewPopulated()) {
            this.instantiateView();
        }

        this.currentTab.$tab.addClass('is-active').find('a').attr('aria-selected', 'true');
        this.currentTab.$tabSection.addClass('active').attr('aria-hidden', 'false');
    },

    /**
     * Determines if tab content is empty or not
     * @return {boolean}
     */
    isViewPopulated() {
        return this.currentTab.$tabSection.is(':empty');
    },

    /**
     * Creates new view for tab content as needed
     */
    instantiateView() {
        const returnRouteVal = (this.options.returnRoute) ? this.options.returnRoute : 'ADMINSTRATION/alerts';
        switch (this.currentTab.tabValue) {
        case 'alertsList':
            this.activeView = new AlertsView({
                returnRoute: returnRouteVal,
            });
            this.alertsRegion.show(this.activeView);
            break;
        case 'recipientGroups':
            this.activeView = new RecipientGroupsView({
                returnRoute: returnRouteVal,
            });
            this.recipientGroupsRegion.show(this.activeView);
            break;
        case 'recipients':
            this.activeView = new CustomRecipientsView({
                returnRoute: returnRouteVal,
            });
            this.customRecipientsRegion.show(this.activeView);
            break;
        case 'mySettings':
            this.activeView = new MySettingsView({
                returnRoute: returnRouteVal,
            });
            this.mySettingsRegion.show(this.activeView);
            break;
        default:
            break;
        }
        if (this.isInitialRender) {
            this.listenToOnce(this.activeView, 'afterRender', () => {
                setupAccessibleTabs(this, this.changeTab);
            });
        }
    },

    /**
     * Control the display of each tab based on user entitlements and product settings.
     */
    templateHelpers() {
        return {
            alertTabEntitled: this.canShowAlertTab,
            recipGroupTabEntitled: this.canShowRecipientGroupTab,
            recipientsTabEntitled: this.canShowRecipientTab,
            /*
             * The My Settings widget will only be available in the Alert Center
             * if the Alert Center or User Menu Product Setting is enabled in the company.
             */
            mySettingsTabEntitled: this.canShowUserSettingsTab,
            alertsTitle: locale.get('alerts.listView_title'),
            recipientGroupsTitle: locale.get('title.recipientGroups'),
            recipientsTitle: locale.get('title.recipients'),
            mySettingsTitle: locale.get('title.mySettings'),
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'ALERTS_CENTER_WIDGET',
    view: AlertsCenterView,
    options: {},
    useMobileCard: true,
});

export default AlertsCenterView;
