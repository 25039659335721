import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';

export default function SaveOrUpdateView({
  classes,
  hasActive,
  isStatic,
  save,
  shouldAllowCreation,
  suggestedName,
  update
}) {
  const {
    saveNewCancelButton,
    saveNewChanged,
    saveNewSaveButton,
    saveNewWrapper
  } = classes;

  const [open, toggleOpen] = useState(!hasActive);
  const [saveName, updateSaveName] = useState(suggestedName);

  const handleNewName = (e) => {
    const { target: { value } } = e;
    updateSaveName(value);
  };

  useEffect(() => {
    updateSaveName(suggestedName);
  }, [suggestedName]);

  if (!open) {
    return (
      <div className={saveNewWrapper}>
        <div
          data-qa="savedViews-diverged"
          className={saveNewChanged}
        >
          {locale.get('dataComponents.changed')}
        </div>
        {!isStatic
          && (
          <button
            data-qa="savedViews-save"
            className={saveNewSaveButton}
            onClick={update}
            type="button"
          >
            {locale.get('dataComponents.save')}
          </button>
          )}
        {shouldAllowCreation && (
          <button
            data-qa="savedViews-saveAsToggle"
            className={saveNewCancelButton}
            onClick={() => toggleOpen(true)}
            type="button"
          >
            {locale.get('dataComponents.saveAs')}
          </button>
        )}
      </div>
    );
  }

  // Remove rename
  return (
    <div className={saveNewWrapper}>
      <form onSubmit={(e) => {
        e.preventDefault();
        save(saveName);
        toggleOpen(false);
      }}
      >
        <input
          aria-label={locale.get('dataComponents.viewName')}
          maxLength={25}
          value={saveName}
          onChange={handleNewName}
        />
        <button
          data-qa="savedViews-saveAsSave"
          className={saveNewSaveButton}
          type="submit"
          disabled={!saveName.length}
        >
          {locale.get('dataComponents.save')}
        </button>
        {hasActive && (
        <button
          data-qa="savedViews-saveAsCancel"
          className={saveNewCancelButton}
          onClick={() => {
            updateSaveName(suggestedName);
            toggleOpen(false);
          }}
          type="button"
        >
          {locale.get('dataComponents.cancel')}
        </button>
        )}
      </form>
    </div>
  );
}

SaveOrUpdateView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  hasActive: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool,
  save: PropTypes.func.isRequired,
  shouldAllowCreation: PropTypes.bool.isRequired,
  suggestedName: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired
};

SaveOrUpdateView.defaultProps = {
  classes: {},
  isStatic: false
};
