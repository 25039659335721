
import moment from 'moment';
import gluValidators from 'no-override!@glu/core/src/core/internal/validators';
import numeral from 'numeral';
import util from 'underscore';


export default util.extend(
    {},
    gluValidators,
    {
        /**
         * @method exists
         * @param {string} key
         * @param model
         * @param param
         * @returns {boolean}
         * The method is copied from the core glu, and differs only in the type check of model
         * Our current gluOverrided Model puts model as the third argument when param is false
         * within getValidationResultsByFieldName method
         * example: validators[testName](name, param, this)
         */
        exists(key, model, param) {
            const useModel = typeof model === 'boolean' ? param : model;
            const value = useModel.get(key);

            if (value === void 0 || value === null) {
                return false;
            }

            if (typeof value === 'string') {
                return value.replace(/^\s+|\s+$/gm, '').length > 0;
            } else if (util.isArray(value)) {
                return !util.isEmpty(value);
            } else {
                return true;
            }
        },

        /* handle localized date validation - move this to glu to support localization */
        date: gluValidators.matchesDatePattern,

        uniqueInCollection(key, model) {
            try {
                const firstModel = model.collection.findWhere({
                    [key]: model.get(key),
                });
                return model === firstModel;
            } catch (error) {
                throw new Error('Model is expected to be part of a collection')
            }
        },

        minValue(key, min, model) {
            // unformat the key because minValue does not work on some locale formats
            const value = numeral().unformat(model.get(key));
            return (value !== undefined) && (value >= (min || 0));
        },

        /**
         * @override
         * Override the glu validator to consider a possible zero value
         * @param {string} key - name of attribute
         * @param {number|string} comparator - name of attribute to compare or value
         * @param {Object} model - model containing the values and attributes
         */
        isLessThan(key, comparator, model) {
            const value = +model.get(key);
            let comparatorVal = comparator;
            if (!util.isNumber(comparator) && !util.isNaN(comparator)) {
                comparatorVal = model.get(comparator);
            }
            return (value || typeof value === 'number') && (value < +comparatorVal);
        },

        isNumeric(key, model) {
            let value = model.get(key);
            // get number representation of string
            if (util.isString(value)) {
                value = +value;
            }
            // Converted to a Number just above, so Number.isNaN is ok.
            return !Number.isNaN(value) && util.isNumber(value);
        },

        /**
         * Check if the number is decimal number
         * @param {string} key - name of attribute
         * @param {Object} model - model containing the values and attributes
         */
        integerOnly(key, model){
            let value = model.get(key);
            if(value % 1 !== 0){
                return false
            }
            return true;
        },

        serialNumberLimit(key,model){
            let value = model.get(key);
            // Check if the length is greater than the maxLength limit received from filterFields;
            if(value.length > model?.serialNumberLimit){
                return false
            }
            return true;

        },

        /*
         * NH-166096
         * This introduces a new validator that uses the same validation logic as the 'oneOf'
         * validator, but allows for a custom message to be passed in
         */
        customOneOf(key, searchValues, model){
            return gluValidators.oneOf(key, searchValues, model);
        },
        /*
         * NH-160488
         * This introduces a new validator that will compare two date
         */
        isDateAfter(key, comparator, model) {
            const value = moment(model.get(key));
        
            if (model.has(comparator)) {
                comparator = moment(model.get(comparator));
            } else {
                comparator = moment(comparator);
            }
        
            return value.isAfter(comparator);
          },
        isDateBefore(key, comparator, model) {
            const value = moment(model.get(key));

            if (model.has(comparator)) {
                comparator = moment(model.get(comparator));
            } else {
                comparator = moment(comparator);
            }

            return value.isBefore(comparator);
        },

        validationMessages: {
            //messages for validators defined in glu validators, names must match the
            // names of the glu validators EXACTLY
            //for many of these you will want to use the msg property on the validator to
            // provide a more specific message
            exists: '<%= description %> is required',

            minLength: '<%= description %> must be at least <%= minLength %> characters',
            maxLength: '<%= description %> must be less than <%= maxLength %> characters long',
            exactLength: '<%= description %> must be exactly <%= exactLength %> characters long',
            rangeLength: '<%= description %> must be between <%= rangeLength[0] %> and <%= rangeLength[1] %> characters long',
            minValue: '<%= description %> must be at least <%= minValue  %>',
            maxValue: '<%= description %> must be no more than <%= maxValue  %>',
            range: '<%= description %> must be between <%= range[0] %> and <%= range[1] %>',

            //this may be complex if anything other than a straight array dump is needed
            oneOf: '<%= description %> must be one of <%= oneOf %>',

            equalTo: '<%= description %> must be equal to <%= equalTo %>',
            contains: '<%= description %> must contain <%= contains %>',
            beginsWith: '<%= description %> must start with <%= beginsWith %>',
            endsWith: '<%= description %> must end with <%= endsWith %>',
            matchesAlphaNumeric: '<%= description %> can contain only letters and numbers',

            // this one is fairly pointless as its a regex
            // so not much we can do generically as a msg!
            matches: '<%= description %> as entered is invalid',

            isNumeric: '<%= description %> must be a number',
            isEmailAddress: '<%= description %> must be a valid email address',
            isLessThan: '<%= description %> must be less than <%= typeof otherDescription !== "undefined" ? otherDescription : isLessThan %>',
            isGreaterThan: '<%= description %> must be greater than <% typeof otherDescription !== "undefined" ? otherDescription : isGreaterThan %>',
            sameValue: '<%= description %> does not match <%= otherDescription %>',
            notSameValue: '<%= description %> should not match <%= otherDescription %>',
            isUnique: '<%= description %> must have a unique value',
            isSwift: '<%= description %> cannot contain special characters',
            isFedwire: '<%= description %> cannot contain special characters',
            isTemplateCode: '<%= description %> cannot include spaces',

            //ccm messages, must match the function names below EXACTLY
            usZipCode: '<%= description %> must be a valid zip or zip+4 code',

            us10DigitPhone: '<%= description %> must be a valid 10 digit US phone number',
            'legacy.emailAddress': '<%= description %> must be a valid email address',
            emailAddressList: '<%= description %> must be a valid email address or a list of valid email addresses separated with semicolon',
            phoneNumer: '<%= description %> must be a valid phone number in any of these patterns: (222)222-2222 | 2222222222 | 222-222-2222',
            phoneNumberPlusOne: '<%= description %> must be a valid phone number in any of these patterns: +1- (222)222-2222 | 2222222222 | 222-222-2222',            
            internationalPhoneNumber: '<%= description %> must be a valid phone number in any of these patterns: (222)222-2222 | 2222222222 | 222-222-2222 | 1234567890123456-123',
            date: '<%= description %> must be a valid date',

            // need this because we override the validation messages
            matchesDatePattern: '<%= description %> must be a valid date',
            integerOnly: '<%= description %> must not have decimals',

            //To restrict serial number from being greater than 19 digits
            serialNumberLimit:'<%= description %> must be less than <%= limitValue %> characters',

            customOneOf: '<%= customString %>',

            uniqueInCollection: '<%= description %> must be unique',
            noSpaces: '<%= description %> cannot include spaces',
            isDateAfter: '<%= description %> After Date should be greater than Before Date',
            isDateBefore: '<%= description %> Before Date should be lesser than After date'
        },
    },
);
