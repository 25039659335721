import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import moment from 'moment';
import compareTableTmpl from './compareTable.hbs';

const CompareTable = ItemView.extend({
    getTemplate() {
        /*
         * TODO this should show a 'pick plans to compare' message
         *  when there is nothing selected
         */
        return compareTableTmpl;
    },

    tagName: 'table',

    // glu's styles hang on .table
    className: 'table',

    initialize(options) {
        this.selectControls = options.selectControls;
        this.listenTo(
            this.collection,
            {
                compareDataReady: this.render,
            },
        );
    },

    templateHelpers() {
        let helpers = {};
        const data = [];

        util.each(this.selectControls, (view) => {
            if (view.model) {
                data.push({
                    name: view.name,
                    offset: view.getFiscalYearOffset(),
                    compareArray: view.model.get('compareArray'),
                });
            }
        });

        if (data.length) {
            const months = [];
            const labels = [];
            const inflows = [];
            const outflows = [];
            const totals = [];
            const diffs = [];

            const helperConstructionLoopFunc = (i) => {
                util.each(data, (plan, j) => {
                    labels.push(plan.name);
                    const month = i + plan.offset;
                    const inflow = plan.compareArray[month]
                        ? plan.compareArray[month].inflow : 0;
                    const outflow = plan.compareArray[month]
                        ? plan.compareArray[month].outflow : 0;

                    inflows.push(inflow);
                    outflows.push(outflow);
                    const total = inflow - outflow;
                    totals.push(total);

                    let diff = 0;
                    if (j !== 0) {
                        const oOffset = data[0].offset;
                        const oTotal = data[0].compareArray[oOffset + i]
                            ? data[0].compareArray[oOffset + i].inflow
                            - data[0].compareArray[oOffset + i].outflow : 0;
                        diff = total - oTotal;
                    }

                    diffs.push(diff);
                });
            };

            for (let i = 0; i < 12; i += 1) {
                months.push(moment(new Date()).month(data[0].compareArray[i].month - 1).format('MMM'));

                helperConstructionLoopFunc(i);
            }

            helpers = {
                colspan: data.length || 1,
                months,
                labels,
                inflows,
                outflows,
                totals,
                diffs,
            };
        }

        return helpers;
    },
});

export default CompareTable;
