import React, {
  useCallback, useEffect, useMemo, useReducer
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import {
  createFilterValueFunction, createParseFilterFunction, getFilterComponent, combineFilters
} from '@glu/data-components';
import { styles } from './ColumnFilter.styles';

const ColumnFilter = ({
  classes,
  data,
  htmlId,
  filterables,
  displayName,
  getFilterData,
  columnApi,
  column,
  records,
  clearSelection,
  setClearSelection,
  ...props
}) => {
  const { filters: dataFilters } = data;
  const existingFilter = filterables.find(
    localColumn => localColumn.headerName === displayName
  );
  const [filters, updateFilters] = useReducer(combineFilters, dataFilters || []);
  const filterCurrentValue = filters.filter(filter => filter.field === existingFilter.field);
  const createFilterValue = useCallback(createFilterValueFunction(existingFilter),
    [existingFilter]);
  const parseFilterValue = useCallback(createParseFilterFunction(existingFilter), [existingFilter]);
  const Component = useMemo(() => getFilterComponent(existingFilter.type), [existingFilter]);

  const handleChange = useCallback((name, filter) => {
    updateFilters({ name, filter });
    setClearSelection(false);
  }, [updateFilters]);

  useEffect(() => {
    if (clearSelection) {
      updateFilters({ name: column.colId });
    }
  }, [clearSelection, dataFilters]);

  useEffect(() => {
    getFilterData({
      name: 'filters',
      value: filters,
      errors: {}
    });
  }, [filters]);

  return (
    <Component
      classes={classes}
      className={classes.filterWrapper}
      filters={filters}
      handleChange={handleChange}
      htmlId={htmlId || classes.filterWrapper}
      key={existingFilter ? existingFilter.field : null}
      records={records}
      value={{
        filters: filterCurrentValue
      }}
      hasFilter={!!filters.find(filter => filter.field === existingFilter.field)}
      label={existingFilter.headerName}
      fieldId={existingFilter.field}
      onChange={handleChange}
      createFilterValue={createFilterValue}
      parseFilterValue={parseFilterValue}
      enumData={existingFilter.enumData}
      options={existingFilter.options}
      displayFormat={existingFilter.dateFormat}
      dark={false}
      {...props}
    />
  );
};

ColumnFilter.propTypes = {
  /** Css classes provided by theme */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  /** id used for qa attributes and label ids */
  htmlId: PropTypes.string,
  /** Data object describing filters */
  data: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.shape({
      field: PropTypes.string.isRequired,
      criteriaDisplay: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      nameDisplay: PropTypes.string.isRequired,
      operator: PropTypes.oneOf(['AND', 'OR']),
      filterData: PropTypes.shape({}).isRequired
    }))
  }).isRequired,
  /** records data should match column defs, but doesn't have any particular shape */
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Array of filter types. Determines what filter is shown.
   * Types include:
   * data
   * enum
   * multiselect
   * string (default)
  */
  filterables: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    dateFormat: PropTypes.string,
    type: PropTypes.oneOf(['date', 'enum', 'multiselect', 'number', 'string', 'typeahead', 'range'])
  })),
  displayName: PropTypes.string,
  getFilterData: PropTypes.func.isRequired,
  columnApi: PropTypes.objectOf(PropTypes.string).isRequired,
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
    userProvidedColDef: PropTypes.object.isRequired,
    colId: PropTypes.string.isRequired,
    pinned: PropTypes.oneOf(['left', 'right']),
    headerName: PropTypes.string.isRequired
  }).isRequired,
  clearSelection: PropTypes.bool,
  setClearSelection: PropTypes.func.isRequired
};

ColumnFilter.defaultProps = {
  filterables: [],
  htmlId: undefined,
  displayName: '',
  clearSelection: false
};

export default withStyles(styles)(ColumnFilter);
