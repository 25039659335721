var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                    role=\"presentation\">\n                    <a aria-controls=\"pmtFraudCtrlDecisionHistory\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-id=\"decision-history\"\n                        id=\"pmtFraudCtrlDecisionHistoryTab\"\n                        href=\"#\"\n                        role=\"tab\"\n                        tabindex=\"-1\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.decision-history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":36},"end":{"line":35,"column":68}}}))
    + "\"\n                        aria-selected=\"false\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.decision-history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":56}}}))
    + "\n                    </a>\n                </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                    role=\"presentation\">\n                    <a aria-controls=\"pmtFraudCtrlElecItmsDecisionHistory\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-id=\"edecision-history\"\n                        id=\"pmtFraudCtrlElecItmsDecisionHistoryTab\"\n                        href=\"#\"\n                        role=\"tab\"\n                        tabindex=\"-1\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.edecision-history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":36},"end":{"line":51,"column":69}}}))
    + "\"\n                        aria-selected=\"false\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.edecision-history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":53,"column":57}}}))
    + "\n                    </a>\n                </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-tertiary\" data-id=\"posPay-items\" id=\"posPay-items\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"CM.positivePayItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":62,"column":90},"end":{"line":62,"column":122}}}))
    + "</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-tertiary\" data-id=\"revPosPay-items\" id=\"revPosPay-items\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"CM.reversePositivePayItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":96},"end":{"line":65,"column":135}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-tertiary\" data-id=\"ePay-items\" id=\"ePay-items\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"CM.electronicItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":86},"end":{"line":68,"column":117}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fraud.listView_title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":65}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <ul id=\"client-fraud-control-tablist\" class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            role=\"tablist\"\n            title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fraud.listView_title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":19},"end":{"line":11,"column":53}}}))
    + "\">\n            <li class=\"NavTabs-item is-active\" role=\"presentation\">\n                <a aria-controls=\"pmtFraudCtrlTodaysDecisions\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    data-id=\"view\"\n                    id=\"pmtFraudCtrlTodaysDecisionsTab\"\n                    href=\"#\"\n                    role=\"tab\"\n                    tabindex=\"0\"\n                    aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.todays-decisions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":32},"end":{"line":20,"column":64}}}))
    + "\"\n                    aria-selected=\"true\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.todays-decisions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":52}}}))
    + "\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDecisionHistoryEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasEBMPPDECEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":56,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n        <div class=\"accountGroupToggle right\">\n            <strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.viewAllColon",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":59,"column":48}}}))
    + "</strong>\n            <button class=\"btn btn-tertiary active\" data-id=\"accs-by-type\" id=\"accs-by-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.accountsByType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":60,"column":93},"end":{"line":60,"column":123}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBMPPDECEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCMPPDECEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":66,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasEBMPPDECEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":69,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div id=\"pageRegion\" class=\"pageRegion remove-section-styles\"></div>\n    </div>\n</div>\n";
},"useData":true});