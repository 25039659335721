import Layout from '@glu/core/src/layout';
import moment from 'moment';
import Format from '@glu/core/src/templateHelpers';
import userInfo from 'etc/userInfo';
import userCentricAuditTmpl from './userCentricAudit.hbs';

export default Layout.extend({
    template: userCentricAuditTmpl,

    templateHelpers() {
        return {
            /**
             * Takes a key and returns a date formatted string
             * @param {string} timestamp
             * @returns {string}
             */
            formatTimeStamp(timestamp) {
                if (!timestamp) {
                    return undefined;
                }

                const auditDate = moment.tz(timestamp, 'GMT');
                return Format.date(auditDate.tz(userInfo.get('timezone')), `${userInfo.getDateFormat()} ${userInfo.getTimeFormat()}`);
            },
        };
    },
});
