import CompositeView from '@glu/core/src/compositeView';
import Model from '@glu/core/src/model';
import { util } from '@glu/core';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import CombinedAccountView from './combinedAccount';
import tmpl from './accountGroup.hbs';
import dateRangeUtil from '../api/dateRangeUtil';
import PartialAccountSummaryView from './partialAccountSummary';
import CurrencyFlag from './currencyFlag';

export default CompositeView.extend({
    className: 'AccountsGroupItem',
    template: tmpl,
    itemViewContainer: '.CombinedAccountItems',
    itemView: CombinedAccountView,

    ui: {
        $showTotalsBtn: '[data-hook="getShowTotalsBtn"]',
        $accountCount: '[data-hook="account-count"]',
        $loadingState: '[data-hook="getLoadingState"]',
        $totalsHeader: '[data-hook="getTotalsHeader"]',
        $accountGroup: '[data-hook="account-group"]',
    },

    events: {
        'click @ui.$showTotalsBtn': 'onShowTotals',
    },

    initialize() {
        this.collection = this.model.get('accountCollection');
        this.accountTotals = this.model.get('accountTotals');
        const groupName = this.model.get('ACCOUNT_GROUP_NAME');
        if (groupName) {
            this.accountTotals =
                this.accountTotals?.filter(group => group.ACCOUNT_GROUP_NAME === groupName);
        }
        // replace with account check to trigger totals fetch
        /**
         * After accounts have been loaded, we need to trigger a realtime check
         * for every account loaded, and then trigger a totals call
         */
        this.listenTo(this.model, 'realTimeInformation:loading', this.toggleLoadingState.bind(this));
        this.listenTo(this.model, 'accountsPolling:complete', this.onAccountsDataLoaded.bind(this));
    },

    itemViewOptions() {
        return {
            filterModel: this.options.filterModel,
            filterView: this.options.filterView,
            stack: this.options.stack,
            canViewTransactions: this.options.canViewTransactions,
            accountDetailServiceName: this.options.accountDetailServiceName,
        };
    },

    /**
     * @method onAccountsDataLoaded]
     * Callback when collection has loaded and finished polling realtime data for accounts
     * and should now update the collective totals data
     */
    onAccountsDataLoaded() {
        this.model.collection.fetch({
            updateTotal: true,
            groupName: this.model.get('ACCOUNT_GROUP_NAME'),
            remove: false,
        });
    },

    toggleLoadingState(loading) {
        if (loading) {
            this.ui.$totalsHeader.fadeOut('fast');
            this.ui.$loadingState.show();
        } else {
            this.ui.$loadingState.hide();
            this.ui.$totalsHeader.fadeIn('fast');
        }
    },

    getAccountCountString(group) {
        const currency = group.CURRENCY_CODE;
        const count = group.BASECURRACCOUNT;
        const accountsString = (count === 1) ? 'Account' : 'Accounts';
        return `${count} ${currency} ${accountsString}`;
    },

    onShow() {
        this.toggleLoadingState(false);
        const hideFlag = serverConfigParams.get('BTRDisplayFlagIconWithTotals')?.toLowerCase() === 'false';
        this.accountTotals.forEach((group) => {
            this.$el.find(`[data-hook='insert-account-summary-${group.CURRENCY_CODE}']`).html(new PartialAccountSummaryView({
                model: new Model(group),
            }).render().el);
            if (!hideFlag) {
                this.$el.find(`[data-hook='insert-currency-flag-${group.CURRENCY_CODE}']`).html(new CurrencyFlag({
                    name: group.CURRENCY_CODE,
                }).render().el);
            }
        });
    },

    /**
     * @param {string} group
     * @return {object} locales ref obj
     * Returns the correct totals toggle label based on the type of
     * account filter used on search
     */
    getToggleLabelByFilter(group) {
        return {
            show: (group) ? locale.get('GIR.btr.showGroupTotals') : locale.get('GIR.btr.showAccountTotals'),
            hide: (group) ? locale.get('GIR.btr.hideGroupTotals') : locale.get('GIR.btr.hideAccountTotals'),
        };
    },

    /**
     * @param {Jquery element} $button
     * @param {boolean} onShow
     * Toggles the text of show/hide buttons
     */
    toggleButtonLabel($button, onShow) {
        const labels = this.getToggleLabelByFilter(this.model.get('ACCOUNT_GROUP_NAME'));
        $button.text((onShow) ? labels.hide : labels.show);
    },

    /**
     * @method onShowTotals
     * Callback when toggle the show Totals section panel for view
     * Will need to dynamically poll and load totals content
     */
    onShowTotals() {
        // TODO: get locales for text and polling totals functionality
        util.defer(() => {
            const showTotals = this.ui.$showTotalsBtn.attr('aria-expanded') === 'true';
            this.toggleButtonLabel(this.ui.$showTotalsBtn, showTotals);
        });
    },

    formatAccountTotals() {
        return this.accountTotals.map((group) => {
            const g = group;
            g.accountCount = this.getAccountCountString(group);
            return g;
        });
    },

    templateHelpers() {
        return {
            id: this.cid,
            filterStartDate: dateRangeUtil.prettyPrintDate(this.options.filterModel.get('START_DATE')),
            filterEndDate: dateRangeUtil.prettyPrintDate(this.options.filterModel.get('END_DATE')),
            accountTotals: this.formatAccountTotals(),
        };
    },
});
