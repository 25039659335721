import Model from '@glu/core/src/model';
import { postData } from 'common/util/services';
import services from 'services';
import locale from '@glu/locale';

const NoteModel = Model.extend({

    defaults: {
        note: '',
    },

    initialize() {
        this.validators = {
            note: {
                description: locale.get('serviceRequest.Note'),
                exists: true,
            },
        };
    },

    sync(method, model, options) {
        if (method === 'create') {
            return postData(`${services.serviceRequest}/notes/add`, this.getSaveData(model, options));
        }

        return Promise.reject(new Error('method event not supported'));
    },

    /**
     * Convert the model into server ready JSON
     * @param {Model} model
     * @param {Object} options
     * @returns {Object} serialized version of the object
     */
    getSaveData(model, options) {
        return {
            serviceRequestTnum: options.tnum,
            note: model.get('note'),
        };
    },
});

export default NoteModel;
