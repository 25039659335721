import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { Columns } from '@glu/data-components';
import {
  Button, PRIMARY, SECONDARY
} from '@glu/buttons-react';

import PanelWrapper from '../PanelWrapper/PanelWrapper';

const ColumnPanel = ({
  columns,
  closeDrawer,
  onSave,
  maxPrimaryColumns
}) => {
  const [columnState, setColumnState] = useState(columns);

  useEffect(() => {
    setColumnState(columns);
  }, [columns]);

  const handleColumnsChange = useCallback((newColumns) => {
    setColumnState(newColumns.value);
  }, [setColumnState]);

  const handleColumnsSave = useCallback(() => {
    onSave(columnState);
  }, [columnState, onSave]);

  const handleClose = useCallback(() => {
    setColumnState(columns);
    closeDrawer();
  }, [columns, closeDrawer]);

  return (
    <PanelWrapper
      title={locale.get('columnsTitle')}
      footer={(
        <>
          <Button
            variant={PRIMARY}
            type="button"
            text={locale.get('apply')}
            onClick={handleColumnsSave}
          />
          <Button
            variant={SECONDARY}
            type="button"
            text={locale.get('cancel')}
            onClick={handleClose}
          />
        </>
    )}
    >
      <Columns
        autoApplyChanges
        data={{ columns: columnState }}
        onChange={handleColumnsChange}
        darkMode={false}
        alwaysShowDragTrigger
        maxPrimary={maxPrimaryColumns}
        disablePinning
      />
    </PanelWrapper>
  );
};

ColumnPanel.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    hide: PropTypes.bool,
    primary: PropTypes.bool,
    headerName: PropTypes.string,
    field: PropTypes.string
  })).isRequired,
  maxPrimaryColumns: PropTypes.number,
  closeDrawer: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
ColumnPanel.defaultProps = {
  maxPrimaryColumns: 3
};

export default ColumnPanel;
