import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import contextApi from 'common/dynamicPages/api/context';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import constants from 'common/dynamicPages/api/constants';
import locale from 'system/webseries/api/locale';
import util from '@glu/core/src/util';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import $ from 'jquery';
import store from 'system/utilities/cache';
import ListView from 'common/dynamicPages/views/workflow/list';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import template from './listView.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    regions: {
        gridRegion: 'div[data-region="segmentationGrid"]',
        alertRegion: '.alert-region',
    },

    initialize() {
        this.loadViewRequirements();
        this.gridLoaded = false;
        store.unset(
            'segmentInsert',
            {
                silent: true,
            },
        );
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);

            if (store.get('admin:segmentation:add')) {
                this.displayAlert(store.get('admin:segmentation:add'));

                store.unset(
                    'admin:segmentation:add',
                    {
                        silent: true,
                    },
                );
            } else if (store.get('admin:segmentation:approve')) {
                this.displayAlert(store.get('admin:segmentation:approve'));

                store.unset(
                    'admin:segmentation:approve',
                    {
                        silent: true,
                    },
                );
            } else if (store.get('admin:segmentation:delete')) {
                this.displayAlert(store.get('admin:segmentation:delete'));

                store.unset(
                    'admin:segmentation:delete',
                    {
                        silent: true,
                    },
                );
            }
        }
    },

    handleAddNewSegment() {
        store.set('segmentInsert', true);
        this.addSegment();
    },

    addSegment() {
        this.navigateTo('/SEGMENTATION/addServices');
    },

    viewSegment() {
        this.navigateTo('/SEGMENTATION/viewServices');
    },

    loadViewRequirements() {
        const context = contextApi.menuContext.getContext('SEGMNT_MAINT');
        ListView.prototype.setListViewConfig.call(this, context);

        const options = {
            context,

            // default grid action buttons are hidden
            selector: 'none',

            // instead of executing context actions, grid handles callback
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            enableSavedViews: false,
            lvc: this.lvc,

            loadedCallback: () => {
                this.gridLoaded = true;
            },
        };
        const entitlementPromise = entitlements.getEntitlements(options);
        this.gridView = gridApi.createServiceGridView(options);
        ListView.prototype.setupGridAvailableListener.call(this);

        entitlementPromise.then((result) => {
            this.entitlements = result;
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    gridRowAction(options) {
        const self = this;
        const action = options.action.toUpperCase();

        return new Promise((resolve, reject) => {
            if (action === constants.ACTION_MODIFY) {
                // set our id to load segment data
                store.set('segmentName', options.model.get('id'));
                store.set('segmentModify', true);
                store.set('segment:sophistication', options.model.get('SOPHISTICATION'));
                self.addSegment();
                resolve();
            } else if (action === 'SELECT') {
                // view
                store.set('segmentName', options.model.get('id'));
                store.set('segment:sophistication', options.model.get('SOPHISTICATION'));

                if (self.hasApproveEntitlement(options.model.buttons)) {
                    store.set('admin:segmentation:approve:entitlement', true);
                } else {
                    store.unset(
                        'admin:segmentation:approve:entitlement',
                        {
                            silent: true,
                        },
                    );
                }
                if (self.hasDeleteEntitlement(options.model.buttons)) {
                    store.set('admin:segmentation:delete:entitlement', true);
                } else {
                    store.unset(
                        'admin:segmentation:delete:entitlement',
                        {
                            silent: true,
                        },
                    );
                }

                store.unset(
                    'segmentModify',
                    {
                        silent: true,
                    },
                );

                if (self.hasEntitlement(constants.ACTION_MODIFY)) {
                    store.set('admin:segmentation:modify:entitlement', true);
                } else {
                    store.unset(
                        'admin:segmentation:modify:entitlement',
                        {
                            silent: true,
                        },
                    );
                }
                store.set('segmentModify', false);
                self.viewSegment();
                resolve();
            } else if (action === constants.ACTION_DELETE) {
                // do something here to delete
                return self.gridRowDelete(options).then(resolve, reject);
            } else if (action === constants.ACTION_APPROVE) {
                return self.gridRowApprove(options).then(resolve, reject);
            }
            return resolve();
        });
    },

    gridRowDelete(options) {
        const self = this;
        return new Promise((resolve, reject) => {
            options.model.destroy({
                success(model) {
                    self.gridView.refreshGridData();
                    self.displayAlert(`${model.get('NAME')} ${locale.get('segment.delete.success')}`);
                    resolve({ model });
                },

                error(model, response) {
                    self.displayError($.parseJSON(response.message));
                    self.gridView.refreshGridData();
                    reject(new Error(response.message));
                },
            });
        });
    },

    gridRowApprove(options) {
        const self = this;
        return new Promise((resolve, reject) => {
            options.model.approve({
                success(model) {
                    self.gridView.refreshGridData();
                    self.displayAlert(`${model.get('NAME')} ${locale.get('segment.approve.success')}`);
                    resolve({ model });
                },

                error(model, response) {
                    self.displayError($.parseJSON(response.message));
                    self.renderMessage(options.action);
                    reject(new Error(response.message));
                },
            });
        });
    },

    displayError(errorParam) {
        const error = errorParam;
        if (error === null || error === undefined) {
            return;
        }
        const message = error.message.join(' ');
        error.confirms.confirmResults[0].confirmData = null;

        const confirms = new Confirms({
            confirms: error.confirms,
        });

        // display notification message
        this.alertView = alert.danger(
            message,
            {
                details: confirms,
                canDismiss: true,
                animate: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    displayAlert(message) {
        // display notification message
        this.alertView = alert.success(
            message,
            {
                animate: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    templateHelpers() {
        const self = this;
        return {
            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT);
            },
        };
    },

    hasEntitlement(action) {
        return !!(this.entitlements.actions && this.entitlements.actions[action] === true);
    },

    hasApproveEntitlement(buttons) {
        let retVal = true;

        const approveButton = util.findWhere(
            buttons,
            {
                action: constants.ACTION_APPROVE,
            },
        );

        if (util.isUndefined(approveButton)) {
            retVal = false;
        }

        return retVal;
    },

    hasDeleteEntitlement(buttons) {
        let retVal = true;

        const deleteButton = util.findWhere(
            buttons,
            {
                action: constants.ACTION_DELETE,
            },
        );

        if (util.isUndefined(deleteButton)) {
            retVal = false;
        }

        return retVal;
    },
});
