import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import Constants from 'app/checkManagement/constants';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import template from './fraudControlListView.hbs';

export default ListView.extend({
    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',

            // FIXME: The only of 20 fraud serviceNames to start with a slash. Is this correct?
            serviceName: '/adminCM/fraud/listView/paymentFraudControl',

            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            mode: 'view',
            context: 'CM_FRAUD_CTRL',
            returnRoute: 'REPORTING/viewPaymentFraudControl',
            viewId: options.viewId,
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    export() {
        this.buildExportModel('CSV');
        this.doExport();
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = '20578';
    },

    gridRowAction(options) {
        this.gridRowSelect(options);
        return Promise.resolve();
    },

    gridRowSelect(optionsParam) {
        const options = optionsParam;
        options.model.context = this.contextDef;
        const type = options.model.get('TYPE');
        store.set(`${this.contextKey}-actionModel`, options.model);
        store.set('cm_listView_payDecision-viewAll', false);
        if (type === Constants.POS_PAY_TYPE) {
            this.navigateTo('REPORTING/positivePayDecision');
        } else if (type === Constants.REVERSE_POS_PAY_TYPE) {
            this.navigateTo('REPORTING/reversePositivePayDecision');
        } else if (type === Constants.ELECTRONIC_POS_PAY_TYPE) {
            this.navigateTo('REPORTING/ePositivePayDecision');
        }
        return Promise.resolve();
    },

    gridRowCustomAction(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        store.set(`${this.contextKey}-returnRoute`, this.options.returnRoute);
        this.navigateTo('REPORTING/decisionHistory');
        return Promise.resolve();
    },
});
