import DatePickerView from '@glu/datepicker';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Region } from 'backbone.marionette';
import { validatedComponentPropTypes } from '@glu/validation-react';
import withNewValidation from './withNewValidation';

import './datePicker.css';

// Originally was named EvilDatePickerView :)
// the extension should probably go into glu-datepicker
const ExtendedDatePickerView = DatePickerView.extend({
  parseDateRange() {
    DatePickerView.prototype.parseDateRange.call(this);

    if (!this.errMsg && this.view.options.customValidator) {
      const startDate = this.startDate && this.startDate.clone();
      const endDate = this.endDate && this.endDate.clone();
      const errorMessage = this.view.options.customValidator(startDate, endDate);

      if (errorMessage) {
        this.errMsg = errorMessage;
        this.hasError = !!errorMessage;
      }
    }
  }
});

class DatePicker extends PureComponent {
  constructor(props) {
    super(props);

    this.datePickerChanged = this.datePickerChanged.bind(this);
    this.assignDatePickerContainer = this.assignDatePickerContainer.bind(this);

    this.initDatePicker(props);

    if (props.registerInternalValidators) {
      props.registerInternalValidators({
        fn: () => this.datePickerView.errMsg
      });
    }
  }

  componentDidMount() {
    this.datePickerView.on('datePicked', this.datePickerChanged);

    this.datePickerRegion = new Region({ el: this.datePickerContainer });

    this.datePickerRegion.show(this.datePickerView.render());
  }

  componentDidUpdate(prevProps) {
    Object.keys(this.props).forEach(key => {
      if (this.props[key] !== prevProps[key]) {
        this.updateDatePickerView(key, this.props);
      }
    });
  }

  componentWillUnmount() {
    this.datePickerView.off('datePicked', this.datePickerChanged);

    this.datePickerRegion.reset();
    this.datePickerRegion.close();
  }

  /**
   * Creates a date picker view and pushes props into it
   *
   * Prevents certain props from reaching the datePicker to prevent problems:
   *  eventName, eventTarget - we don't want these available because they aren't react-appropriate
   *  labelText - use label instead
   * @param props
   */
  initDatePicker(props) {
    const excludedProps = ['eventName', 'eventTarget', 'labelText'];
    const defaultProps = { labelText: props.label };

    if (props.defaultValue) {
      [defaultProps.startDate, defaultProps.endDate] = props.defaultValue;
    }

    const datePickerProps = Object.keys(props).filter(key => (
      excludedProps.indexOf(key) === -1
    )).reduce((memo, key) => {
      if (key === 'value' && props[key]) {
        const [startDate, endDate] = props[key];

        return Object.assign({}, memo, { startDate, endDate });
      }

      return Object.assign({}, memo, { [key]: props[key] });
    }, defaultProps);

    const datePickerView = new ExtendedDatePickerView(datePickerProps);
    this.datePickerView = datePickerView;

    datePickerView.showErrors = () => {
      if (datePickerView.hasError) {
        this.props.onChange(this.props.name);
        this.props.onBlur(this.props.name);
      }
    };
  }

  datePickerChanged(datePicker, startDate, endDate) {
    let hasChange = true;
    if (this.props.value) {
      hasChange = startDate !== this.props.value[0] || endDate !== this.props.value[1];
    }
    if (hasChange) {
      this.props.onChange(this.props.name, startDate ? [startDate, endDate] : null);
      this.props.onBlur(this.props.name, [startDate, endDate]);
    }
  }

  assignDatePickerContainer(element) {
    this.datePickerContainer = element;
  }

  updateDatePickerView(key, nextProps) {
    switch (key) {
      case 'value':
        this.datePickerView.updateDates(...Array.isArray(nextProps[key]) ?
          nextProps[key] :
          [nextProps[key]]);
        break;

      /*
       * These go into the datePicker view
       * it looks like updating these will be difficult.
       */
      case 'label':
      case 'minDate':
      case 'maxDate':
      case 'autocomplete':
      case 'rangePicker':
      case 'dateFormat':
        throw new Error(`Invalid prop changed: \`${key}\``);

      default:
    }
  }

  render() {
    const { error, className } = this.props;
    return (
      <div className={`form-group ${error ? 'has-error' : ''} ${className}`}>
        <div className="date-picker" ref={this.assignDatePickerContainer} />
        {error ? <span className="help-block">{error}</span> : ''}
      </div>
    );
  }
}

DatePicker.propTypes = Object.assign({
  label: PropTypes.string.isRequired,

  defaultValue: PropTypes.array,
  value: PropTypes.array,

  className: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  autocomplete: PropTypes.bool,
  rangePicker: PropTypes.bool,
  dateFormat: PropTypes.oneOf(['DD-MMM-YYYY', 'MM/DD/YYYY', 'DD MMM YYYY', 'YYYY-MM-DD'])
}, validatedComponentPropTypes);

DatePicker.defaultProps = {
  rangePicker: true,
  onChange() {},
  onBlur() {},
  injectValidation: true
};

export const ValidatedDatePicker = withNewValidation(DatePicker);

export default DatePicker;
