import $ from 'jquery';
import util from 'underscore';
import moment from 'moment';
import { appBus } from '@glu/core';
import viewBinding from 'system/gluOverride/core/internal/viewBinding';
import keepAliveUtil from 'system/keepAlive/util';
import config from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';

// Would this message ever need to change?
const KEEP_ALIVE_MESSAGE = 'inputKeepAlive';
const isDeeplink = () => window.parent !== window;

const keepAlive = {
    /**
     * The initial run of the function replaces itself
     * with a reusable function, while moving almost all logic
     * out of the end function
     */
    ping() {
        const keepAliveUrl = config.get('keepAliveURL');
        if (util.isEmpty(keepAliveUrl)) {
            return;
        }
        const separator = keepAliveUrl.indexOf('?') === -1 ? '?' : '&';

        keepAlive.ping = util.throttle(
            () => {
                const img = new Image();
                img.src = `${keepAliveUrl}${separator}userGroup=${userInfo.get('group')}&userid=${userInfo.get('id')}&cachebust=${moment(new Date())}`;
            },
            30000,
            {
                trailing: false,
            },
        );

        // On this first execution we need to call the function after replacing it.
        keepAlive.ping();
    },

    /**
     * Ensures the keepalive isn't called against
     * requests which should not fire keepAlive
     * @param {XMLHttpRequest} xhr
     */
    stayAlive(xhr) {
        if (!keepAliveUtil.isIgnoreList(xhr.url)) {
            this.ping();
        }
    },
};

/**
 * Input Keepalive Monitoring
 * No point in using the appBus if we can call directly (non-deeplink)
 */
const getKeepAliveAction = keepAliveMessage => (isDeeplink()
    ? () => appBus.trigger('keepalive', keepAliveMessage)
    : keepAlive.ping);

/**
 * Checks the server configuration parameters to see if we are monitoring user inputs
 * and sets up the callback to postMessage to the parent frame if so.
 *
 * @param {string} keepAliveMessage
 * @param {number|undefined} inputThrottleWaitParam - seconds
 * - 0 (or undefined) is off, -1 triggers on every input
 * - all other values are throttled
 */
const setupInputMonitoring = (keepAliveMessage, inputThrottleWaitParam) => {
    const inputThrottleWait = parseInt(inputThrottleWaitParam, 10);

    if (!inputThrottleWait) {
        return;
    }

    let action = getKeepAliveAction(keepAliveMessage);

    if (inputThrottleWait > 0) {
        action = util.throttle(action, inputThrottleWait * 1000);
    }

    viewBinding.configInputNotification(action);
};

const setupInputKeepAliveEvents = () => {
    // This code runs before the config is populated.
    config.on('change:inputKeepAliveFrequency', () => {
        const inputThrottleWaitParam = config.get('inputKeepAliveFrequency');
        if (inputThrottleWaitParam !== undefined) {
            setupInputMonitoring(KEEP_ALIVE_MESSAGE, inputThrottleWaitParam);
        }
    });
};

/**
 * Startup
 * Multiple prefilters can be loaded, so the CSRF one doesn't interfere.
 */
$.ajaxPrefilter((options) => {
    keepAlive.stayAlive(options);
});

setupInputKeepAliveEvents();

export default keepAlive;
