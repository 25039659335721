import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import errHandler from 'system/errHandler';
import PaymentTypeCollection from 'app/smbPayments/collections/paymentTypes';

const paymentAndEmployeeMainView = Layout.extend({

    ui: {
        $tabLinks: '[data-hook="getNavTabs"] li',
    },

    events: {
        'click @ui.$tabLinks': 'handleTabChange',
    },

    initialize(options) {
        this.paymentEntitled = false;
        this.templateEntitled = false;
        this.paymentTypeList = options.paymentTypeList;
        this.storeName = options.storeName;
        this.paymentBase = options.paymentBase;
        this.templateBase = options.templateBase;
        this.GridView = options.gridView;
        this.currentTab = store.get(this.storeName) || 'paymentTab';
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.updateTabFocus();
            this.showGrid(this.getCurrentGridOptions(this.currentTab));
        } else {
            this.loadViewRequirements();
        }
    },

    loadViewRequirements() {
        const paymentTypePromise = this.typePromise('payment');
        const templateTypePromise = this.typePromise('template');

        /*
         * Wait to render the view until both type promises are returned. Which tabs are visible
         * is dependant on what types the user is entitled to
         */
        Promise.all([paymentTypePromise, templateTypePromise])
            .then((results) => {
                this.paymentEntitled = results[0].length > 0;
                this.templateEntitled = Object.values(results[1].models).some(model => this.paymentTypeList.includes(model.get('typeCode')));
                this.setHasLoadedRequiredData(true);
                this.render();
            })
            .then(null, errHandler);
    },

    /**
     * Handle the tab change between Payments and Payment Templates
     * @param {Event} e - event triggered  by user selecting a tab
     */
    handleTabChange(e) {
        e.preventDefault();

        const $clickedTab = this.$(e.currentTarget);
        this.currentTab = $clickedTab.prop('id');

        // The tab clicked is already active so no need to do anything
        if ($clickedTab.hasClass('active')) {
            return;
        }

        // Change focus to the current tab and remove it from the previous
        this.updateTabFocus();

        // Render the new grid
        const gridOptions = this.getCurrentGridOptions(this.currentTab);
        this.showGrid(gridOptions);

        // Store a record of what the current tab is
        store.set(this.storeName, this.currentTab);
    },

    /**
     * Generate a promise to retrieve the payment types that the user is entitled to
     * @param {String} paymentOrTemplate - Indicates if we are dealing with payments or templates
     * @returns {Promise}
     */
    typePromise(paymentOrTemplate) {
        const isPaymentView = paymentOrTemplate === 'payment';
        return new Promise((resolve, reject) => {
            const paymentTypes = new PaymentTypeCollection({
                functionCode: isPaymentView ? 'INST' : 'TMPL',
                inquiryId: isPaymentView ? 20011 : 20095,
            }, {
                base: isPaymentView ? this.paymentBase : this.templateBase,
            });
            paymentTypes.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * Retrieve the current grid options for either a payment or template grid
     * @param {String} currentTab - name of the current tab (paymentTab OR templateTab)
     * @returns {Object} Object containing the necessary data to load the current grid view
     */
    getCurrentGridOptions(currentTab) {
        return currentTab === 'paymentTab' ? this.gridData.paymentOptions : this.gridData.templateOptions;
    },

    /**
     * Render a GridView based on the passed in options and show it in the gridRegion
     * @param {Object} options - data needed to render gridView
     */
    showGrid(options) {
        const GridView = options.gridView;
        this.gridRegion.show(new GridView(options));
    },

    /**
     * Update the tab elements to acuratly reflect the currently selected tab.
     */
    updateTabFocus() {
        this.ui.$tabLinks.removeClass('active').filter(`#${this.currentTab}`).addClass('active');
    },

    templateHelpers() {
        return {
            employeeConfig: {
                showPaymentTab: this.paymentEntitled,
                showTemplateTab: this.templateEntitled,
            },
        };
    },
});

export default paymentAndEmployeeMainView;
