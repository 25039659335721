import util from '@glu/core/src/util';
import services from 'services';
import { postData } from 'common/util/services';

export default {
    /**
     * Extract attributes, and map the property name. Similar to pluck except
     * with an array
     * of objects instead
     * @param {Array <Object>} array of objects from which data will be extracted
     * @param {Object} [attributes] where the property name is the value
     * found in the array parameter and value is the desired name of desired
     * output property
     * @param {string} [keyName]
     * @param {string} [valueName]
     * @returns {Array}
     */
    extractData(array, attributes = {}, keyName = 'fieldName', valueName = 'fieldValue') {
        return array.reduce((accum, item) => {
            if (Object.keys(attributes).includes(item[keyName])) {
                return util.extend(accum, {
                    [attributes[item[keyName]]]: item[valueName],
                });
            }
            return accum;
        }, {});
    },

    /**
     * make sure data object has required properties
     * @param {Object} data
     * @param {array} propsRequired - array of property key strings
     * @returns {Promise}
     */
    dataHasProps(propsRequired, data) {
        if (util.every(propsRequired, util.has.bind(util, data))) {
            return Promise.resolve(data);
        }
        return Promise.reject(new Error('Missing Required Data Property'));
    },

    /**
     * Use table maintenance lookup to fetch a set of accounts based on the
     * data in the post body
     * @param {Object} [customData]
     * @returns {Promise}
     */
    getAccounts(customData = {}) {
        const defaultData = {
            rowsPerPage: 500,
            startRow: 1,
            fieldName: 'DEBIT_ACCOUNT_NUMBER',
            typeCode: 'TRANSFER',
            productCode: 'RTGS',
            functionCode: 'INST',
            depends: [],
        };
        const data = util.extend(defaultData, customData);
        return postData(services.tableMaintLookup, data);
    },

    /**
     * Convert the response to an object that native expects
     * @param {Object} respHeader
     * @returns {Object}
     */
    convertToErrorResponse(respHeader = {}) {
        return {
            result: false,
            errorCode: respHeader.errorCode,
            message: [
                ...respHeader.message,
            ],
        };
    },
};
