import Model from '@glu/core/src/model';
import Constants from 'app/balanceAndTransaction/constants';
import services from 'services';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';

const CombinedAccount = Model.extend({
    service: services.generateUrl(`${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedUngroupedAccounts`),

    getRequestObject(filterView, filterModel) {
        const req = {
            rowsPerPage: 1000,
            searchFields: filterView.getTransactionFilters(),
            startRow: 0,
        };

        req.searchFields.push({
            fieldName: 'AccountFilter',
            fieldValue: [this.get('ACCOUNTFILTER')],
            dataType: 'text',
            operator: '=',
        }, {
            fieldName: 'START_DATE',
            fieldValue: [filterModel.get('START_DATE')],
            dataType: 'date',
            operator: '>=',
        }, {
            fieldName: 'END_DATE',
            fieldValue: [filterModel.get('END_DATE')],
            dataType: 'date',
            operator: '<=',
        });

        return req;
    },

    refresh(filterView, filterModel, options) {
        const self = this;
        const data = this.getRequestObject(filterView, filterModel);

        return http.post(options.serviceUrl || this.service, data, (response) => {
            if (response && response.rows && response.rows.length > 0) {
                self.set(transform.pairsToHash(response.rows[0].columns, 'fieldName', 'fieldValue'));
            }
            options.success();
        }, () => {
            options.error();
        });
    },
});

export default CombinedAccount;
