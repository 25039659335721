var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-info-links\">\n                <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":63}}}))
    + "\"\n                        class=\"btn btn-tertiary refresh\"\n                        data-hook=\"refresh-button\"\n                        title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":58}}}))
    + "\"\n                        type=\"button\">\n                    <span class=\"icon-refresh\"></span>\n                    <span class=\"refresh-time\"\n                          data-hook=\"refresh-time\"></span>\n                </button>\n            </div>\n        </div>\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n\n";
},"useData":true});