import React from 'react';
import { ArrowsDoubleAshIcon } from '@glu/icons-react';
import locale from '@glu/locale';
import PropTypes from 'prop-types';

const SortIcon = ({ onClick }) => (
  <ArrowsDoubleAshIcon actionable title={locale.get('sort')} onClick={onClick} />
);

SortIcon.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SortIcon;
