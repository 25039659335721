import util from '@glu/core/src/util';
import $ from 'jquery';
import constants from 'common/dynamicPages/api/constants';

export default {
    setupTimeFields(viewParam, $alt) {
        const view = viewParam;
        // Support an alternate container element
        const $el = $alt || view.$el;

        $el.find('.input-time').each(function () {
            let maskOptions;

            if (view.model.fieldData[$(this).attr('name')].fieldUIType === constants.FIELD_UI_TYPE_TIMEFIELD) {
                maskOptions = {
                    alias: 'datetime',
                    mask: 'h:s t\\m',
                    insertMode: false,
                    hourFormat: (view.time) ? view.time.hourFormat : true,
                    autoUnmask: false,
                };
            }

            if (!view.model.fieldData[$(this).attr('placeholder')]) {
                maskOptions = util.extend(
                    maskOptions,
                    {
                        placeholder: '00:00 am',
                    },
                );
            }

            $(this).inputmask(maskOptions);
        });
    },
};
