var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-column col-md-12\">\n    <div class=\"section section-container\">\n        <div class=\"section-header \"><h2>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"toa.accountcount.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":76}}}))
    + "</h2></div>\n        <div class=\"section-body\">\n         <div class=\"btn-wrapper\">\n            "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":55}}})) != null ? stack1 : "")
    + "\n        </div>\n            <div>\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                <div class=\"toa-accounts-region\" data-region=\"gridContent\"></div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});