import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import printFileImportTmpl from './printFileImport.hbs';

const PrintFileImportModel = Model.extend({

});

export default ItemView.extend({
    template: printFileImportTmpl,
    modalClass: 'modal-lg',

    initialize() {
        this.model = new PrintFileImportModel({
            expdata: 'summary',
        });

        this.dialogTitle = locale.get('fileImportHistory.print.FileImportHistory');

        this.dialogButtons = [{
            text: locale.get('payment.print'),
            className: 'btn btn-primary',
            callback: 'print',
        }, {
            text: locale.get('payment.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    templateHelpers() {
        return {
            getString(type) {
                return locale.get(type);
            },
        };
    },

    print() {
        dialog.close();
        this.trigger('printFileImportHistory', this.model);
    },

    cancel() {
        dialog.close();
    },
});
