import Model from '@glu/core/src/model';
import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import constants from 'app/smbBillManagement/constants';
import GridApi from 'common/dynamicPages/api/grid';
import ContextApi from 'common/dynamicPages/api/context';
import Collection from '@glu/core/src/collection';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import GridLayout from './gridLayout';
import AmountDetailCellView from './cell/amountDetail';
import BillDetailCellTemplate from './payBillGridBillDetailCell.hbs';
import PaymentDateCellView from './cell/paymentDate';
import PaymentAmountCellView from './cell/paymentAmount';
import PayeeAccountCellView from './cell/payeeAccount';
import DueDateCellView from './cell/dueDate';
import PaymentAccountCollection from '../collections/paymentAccounts';
import PayFromAccountTemplate from './payFromAccount.hbs';
import PaymentAccountModel from '../models/paymentAccount';
import ConfirmPaymentModal from './confirmPaymentModal';
import payBillGridTemplate from './payBillGrid.hbs';

const BillDetailCellView = ItemView.extend({
    className: 'billdetail-cell-condensed',
    template: BillDetailCellTemplate,
});

const PayFromAccountView = ItemView.extend({
    template: PayFromAccountTemplate,

    collectionEvents: {
        sync: 'initComboBox',
    },

    events: {
        'change .comboBox': 'updateModel',
    },

    updateModel() {
        const id = this.$('.comboBox').comboBox('val');
        const account = this.collection.get(id);

        /*
         * set this.model to have those attributes (so the parent layout can watch
         * for changes)
         */
        this.model.set(account.attributes);
    },

    onShow() {
        // set up comboBox if collection is ready
        if (this.collection.length > 0) {
            this.initComboBox();
        }
    },

    initComboBox() {
        this.$('.comboBox').comboBox({
            placeholder: locale.get('smbBillMgmt.select.option'),
            dropdownAutoWidth: true,
            minimumInputLength: 0,
            maximumSelectionSize: 1,
            triggerChange: true,
            data: this.collection.getComboBoxArray(),
        });
    },

    templateHelpers() {
        return {
            id: this.cid,
        };
    },
});

export default GridLayout.extend({
    name: 'Pay',
    template: payBillGridTemplate,

    regions: {
        payBillGrid: 'div[data-region="pay-bill-grid"]',
        payFromAccount: 'div[data-region="pay-from-account"]',
    },

    events() {
        return util.extend(
            {},
            GridLayout.prototype.events,
            {
                'update tr': this.updateRowDisabled,
            },
        );
    },

    updateRowDisabled(e, accountSelected) {
        const $row = this.$(e.currentTarget);
        $row.find('input[type="checkbox"], button[data-action^="action_pay"], input[name="PAYMENTDATE"], input[name="PAYMENTAMOUNT"]').prop('disabled', !accountSelected);
    },

    initialize() {
        // this model is where the payee inquiry will get 'type'
        this.paymentAccount = new PaymentAccountModel();
        this.paymentAccounts = new PaymentAccountCollection();
        this.paymentAccounts.fetch();

        this.context = ContextApi.menuContext.getContext('BOS_BILLMGMT');
        this.context.serviceName = 'bill';

        const options = {
            context: this.context,
            selector: 'rowSelector',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            columnsToBeHidden: constants.PAY_COLUMNSTOHIDE,

            cellViews: {
                AMOUNT_DETAILS: AmountDetailCellView,
                BILL_DETAILS: BillDetailCellView,
                DUE_DATE: DueDateCellView,
                PAYMENTDATE: PaymentDateCellView,
                PAYMENTAMOUNT: PaymentAmountCellView,

                PAYEEACCOUNT: PayeeAccountCellView.extend({
                    paymentAccount: this.paymentAccount,
                }),
            },
        };
        this.gridView = GridApi.createServiceGridView(options);
    },

    onShow(...args) {
        this.payBillGrid.show(this.gridView);
        this.payFromAccount.show(new PayFromAccountView({
            collection: this.paymentAccounts,
            model: this.paymentAccount,
        }));

        GridLayout.prototype.onShow.apply(this, args);
    },

    setupGridEvents() {
        this.listenTo(
            this.gridView.grid.collection,
            {
                sync: this.refreshData,
            },
            this,
        );

        this.listenTo(
            this.gridView,
            {
                rowAction: this.gridRowAction,
            },
        );

        this.gridView.refreshGridData();
        this.removeActions();
    },

    gridRowAction(options) {
        if (options.action === 'pay') {
            this.pay(options.model);
        }
        return Promise.resolve();
    },

    pay(model) {
        const modelArray = model instanceof Model
            ? [model] : this.gridView.grid.getSelectedRowModels();

        let isValid = true;
        const models = util.map(modelArray, function (m) {
            const $el = this.$(`tr[data-model-cid=${m.cid}]`);
            const accountModel = $el.find('input[name="PAYEEACCOUNT"]').data('accountModel');

            util.each($el.find('.has-validator'), function (cell) {
                const view = this.$(cell).data('view');
                if (!view.isValid()) {
                    isValid = false;
                }
            }, this);

            /*
             * can't data bind these because row model changes
             * cause the entire grid to re-render
             */
            util.extend(
                m.attributes,
                {
                    PAYMENTAMOUNT: $el.find('input[name="PAYMENTAMOUNT"]').val(),
                    PAYEEACCOUNT: accountModel,
                    PAYMENTDATE: $el.find('input[name="PAYMENTDATE"]').val(),
                },
            );

            return m;
        }, this);

        if (!isValid) {
            // TODO error message?
            return;
        }

        dialog.custom(new ConfirmPaymentModal({
            collection: new Collection(models),
            model: this.paymentAccount,
        }));
    },

    removeActions() {
        this.gridView.grid.collection.each((rowParam) => {
            const row = rowParam;
            let VIEW = null;
            let PAY = null;
            const { STATUS } = row.attributes;
            for (let i = 0; i < row.buttons.length; i += 1) {
                if (row.buttons[i].action === 'SELECT') {
                    VIEW = row.buttons[i];
                } else if (row.buttons[i].action === 'PAY') {
                    PAY = row.buttons[i];
                }
            }
            if (STATUS === 'AP') {
                row.buttons = [];
                if (PAY) {
                    row.buttons.push(PAY);
                }
                row.buttons.push(VIEW);
            }
        });
    },
});
