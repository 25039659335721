import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import errHandler from 'system/errHandler';
import PopulationModel from 'app/challengeManager/models/population';
import SegmentsCollection from 'app/challengeManager/collections/segments';
import UsersCollection from 'app/challengeManager/collections/users';
import UsersInquiryCollection from 'app/challengeManager/collections/usersInquiry';
import CompaniesCollection from 'app/challengeManager/collections/companies';
import populationTmpl from 'app/challengeManager/views/population.hbs';
import ListBuilder from './listBuilder';
import UserView from './userView';
import SegmentsView from './segmentsView';
import CompaniesView from './companiesView';

const PopulationView = Layout.extend({
    template: populationTmpl,
    className: 'mfa-population',

    ui: {
        $population: 'input[name="populationType"]:checked',
    },

    events: {
        'click @ui.$population': 'showPopulation',
    },

    initialize() {
        this.model = new PopulationModel();
        this.actionModel = store.get('mfa:actionModel');
        this.systemActionsCollection = this.options.systemActionsCollection;
        this.loadExistingModels = (this.options.action === 'select' || this.options.action === 'modify');
    },

    onRender() {
        this.setSelectionMethod();
        this.loadDefaultPopulation();
    },

    setSelectionMethod() {
        if (this.loadExistingModels) {
            const model = this.systemActionsCollection.at(0);
            this.model.set('selectionMethod', model.get('selectionMethod'));
            store.set('challenge:population', model.get('populationType'));
        }
    },

    loadDefaultPopulation() {
        if (this.loadExistingModels) {
            this.model.set('populationType', this.systemActionsCollection.at(0).get('populationType'));
        }

        const defaultOptions = this.populationOptions(this.model.get('populationType'));
        this.showControlPanel(defaultOptions);
    },

    showPopulation(e) {
        const population = e ? $(e.currentTarget).val() : '';
        store.set('challenge:population', population);
        let panelOptions = {};
        const self = this;

        dialog.confirm(
            locale.get('mfa.PopulationWarning'), locale.get('title.warning'),
            (ok) => {
                if (ok) {
                    panelOptions = self.populationOptions(population);
                    self.showControlPanel(panelOptions);
                }
            },
        );
    },

    populationOptions(populationType) {
        let options = {};

        switch (populationType) {
        case 'USERS':
            options = {
                targetListHeader: 'mfa.UsersAdded',
                population: populationType,
                Collection: UsersCollection,
                TargetCollection: UsersInquiryCollection,
                view: UserView,
                id: 'USERID',
            };
            break;
        case 'COMPANIES':
            options = {
                targetListHeader: 'mfa.CompaniesAdded',
                population: populationType,
                Collection: CompaniesCollection,
                view: CompaniesView,
                id: 'USERGROUP',
            };
            break;
        case 'SEGMENTS':
        default:
            options = {
                targetListHeader: 'mfa.SegmentsAdded',
                population: populationType,
                Collection: SegmentsCollection,
                view: SegmentsView,
                id: 'NAME',
            };
            this.model.set('populationType', populationType);
        }

        return options;
    },

    setSourcePromise() {
        const self = this;
        this.populationPromises.push(new Promise((resolve, reject) => {
            self.source.fetch({
                success: (result) => {
                    resolve(result);
                    this.source.totalRows = result.totalRows;
                },
                error: reject,
            });
        }));
    },

    setTargetPromise() {
        const self = this;
        this.populationPromises.push(new Promise((resolve, reject) => {
            self.target.cname = self.systemActionsCollection.criteria;
            self.target.fetch({
                success: resolve,
                error: reject,
            });
        }));
    },

    showControlPanel(options) {
        const self = this;
        const targetOptions = {};
        let disableDragAndDrop = false;
        let loadedPopulation = false;
        const pArr = [];

        this.populationPromises = [];
        this.source = new options.Collection();
        this.target = this.loadExistingModels && options.id === 'USERID' ? new options.TargetCollection() : new options.Collection();

        const getPopulationId = function (pType, item) {
            let id;

            if (pType === 'SEGMENTS') {
                id = item.segmentId;
            }
            if (pType === 'COMPANIES') {
                id = item.companyId;
            }
            if (pType === 'USERS') {
                id = item.userId;
            }

            return id;
        };

        if (this.loadExistingModels) {
            loadedPopulation = this.systemActionsCollection.at(0).get('population');
            this.target.population = loadedPopulation;

            util.each(loadedPopulation, (item) => {
                const pid = getPopulationId(self.model.get('populationType'), item);
                if (pid !== null && pid !== undefined) {
                    pArr.push(pid);
                }
            }, this);

            if (pArr.length > 0) {
                targetOptions.fieldValue = pArr;
            }
        }

        this.setSourcePromise();

        // A challenge record should always have one or more saved population items
        if (this.loadExistingModels && pArr.length > 0) {
            this.target.fieldValue = pArr;
            this.setTargetPromise();
            if (this.options.action === 'select') {
                disableDragAndDrop = true;
            }
        }

        Promise.all(this.populationPromises)
            .then(() => {
                self.controlPanelRegion.show(new ListBuilder({
                    id: options.id,
                    population: options.population,
                    sourceCollection: self.source,
                    targetCollection: self.target,
                    labelView: options.view,

                    localeKeys: {
                        moveSelectedItems: 'mfa.moveSelected',
                        moveAllItems: 'mfa.moveAll',
                        removeSelectedItems: 'mfa.removeSelected',
                        removeAllItems: 'mfa.removeAll',
                        filter: 'uce.filterAccounts',
                        sourceListHeader: 'uce.nAvailableAccounts',
                        targetListHeader: options.targetListHeader,
                        title: 'uce.addBankAccounts',
                        save: 'mfa.saveChallenge',
                        cancel: 'button.cancel',
                    },

                    disableDragAndDrop,
                    savedPopulationItem: loadedPopulation,
                }));
            })
            .then(null, errHandler);

        this.listenTo(this.appBus, 'population:change', this.handlePopulationChange);

        this.$(`input[value="${options.population}"]`).prop('checked', true);
    },

    handlePopulationChange(collection) {
        // When a user adds or removes an item in the target panel, update the model
        this.model.set('population', collection.toJSON());
    },

    templateHelpers() {
        return {
            disableControls: this.options.action === 'select',
        };
    },
});

export default PopulationView;
