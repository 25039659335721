import Layout from '@glu/core/src/layout';
import localStore from 'common/util/localStore/localStore';

const AccordionBaseLayout = Layout.extend({
    ui: {
        $panelToggle: '.collapse',
    },

    initialize(options) {
        this.storeName = options.storeName;
        this.singleAccordionOpen = options.singleAccordionOpen || false;
        this.accordionStates = localStore.get(this.storeName) || {};
    },

    setupToggle() {
        this.ui.$panelToggle.on('show.bs.collapse', this.handleOpen.bind(this));
        this.ui.$panelToggle.on('hide.bs.collapse', this.handleClose.bind(this));
    },

    /**
     * Determine which accordions should be open
     */
    openSetAccordions() {
        Object.keys(this.accordionStates).forEach((key) => {
            if (this.accordionStates[key] === true) {
                this.$(`[data-accordionid = '${key}']`).collapse('show');
            }
        });
    },

    /**
     * @description Event handler for accordions
     * base class - needs to be over written
     * @param {object} e - the mouse event
     *
     */
    handleOpen/* istanbul ignore next */(e) {
        const $el = this.$(e.currentTarget);
        /**
         * if singleAccordionOpen is true then close all accordions but this one
         */
        if (this.singleAccordionOpen) {
            this.ui.$panelToggle.not($el).collapse('hide');
        }
        // get active tab id
        const accordionId = $el.data('accordionid');
        this.storeAccordionStates(accordionId, true);
    },

    /**
     * Handle close event related functions
     */
    handleClose(e) {
        const $el = this.$(e.currentTarget);

        // get active tab id
        const accordionId = $el.data('accordionid');
        this.storeAccordionStates(accordionId, false);
    },

    /**
     * @description Store open or closed accordion state
     * @param  {string} accordionId Current tab identifier
     * @param {boolean} state
     */
    storeAccordionStates(accordionId, state) {
        /**
         * if only one accordion allowed open at a time then clear out
         * the accordion states object so that only the currently open
         * accordion will be stored
         */
        if (this.singleAccordionOpen) {
            this.accordionStates = {};
        }

        this.accordionStates[accordionId] = state;
        localStore.set(this.storeName, this.accordionStates);
    },

});

export default AccordionBaseLayout;
