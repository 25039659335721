import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import FlexDropdown from '@glu/flex-dropdown';
import approverTmpl from './approver.hbs';

export default Layout.extend({
    template: approverTmpl,
    className() {
        return `PanelApproval-list-item ${this.options.index === 0 ? 'required' : ''}`;
    },

    initialize(options) {
        if (options.index === 0) {
            options.model.initializeValidators();
        }
        this.flexDropdown = new FlexDropdown({
            data: options.approverTypes,
            preSelectedIds: options.model.get('type') ? [options.model.get('type')] : [],
            defaultSelectMessage: locale.get('common.select'),
            label: `${locale.get('PS.panelApproval.Approver.Name')} ${options.index + 1}`,
            disableMultiButton: true,
            clearBtn: true,
            multiSelect: false,
        });
        this.listenTo(this.flexDropdown, 'selectionChanged', this.handleApproverChange);
    },

    onRender() {
        this.approverRegion.show(this.flexDropdown);
    },

    templateHelpers() {
        return {
            required: this.options.index === 0,
        };
    },

    /**
     * Set the model attribute with the selected value
     * @param {Object} selected
     */
    handleApproverChange(selected) {
        this.model.set({
            id: selected[0].id,
            name: selected[0].name,
            order: this.options.index + 1,
            type: selected[0].type,
        });
    },
});
