import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import RTPBase from 'app/smbPayments/models/rtpBase';

export default RTPBase.extend({
    defaults() {
        return {
            ACCOUNTFILTER: '',
            BENE_NAME: '',
            BENE_ACCOUNT: '',
            BENE_ACCOUNT_TYPE: '',
            BENE_BANK_CITY: '',
            BENE_BANK_STATE: '',
            BENE_BANK_ID: '',
            BENE_BANK_NAME: '',
            BENE_COUNTRY: '',
            CREDIT_AMOUNT: '',
            CREDIT_CURRENCY: '',
            DEBIT_ACCOUNT_TITLE: '',
            DEBIT_ACCOUNT_NUMBER: '',
            DEBIT_BANK_CODE: '',
            DEBIT_ACCOUNT_BANK_NAME: '',
            DUPLICATEREASON: '',
            ENTRYMETHOD: '0',
            PARENTUSERGROUP: userInfo.get('group'),
            SPECIAL_INSTRUCTIONS: '',
            TRAN_DATE: '',
            USERGROUP: userInfo.get('group'),
            VALUE_DATE: '',
            _saveIncomplete: '',
            duplicateAccepted: '',
            DISCOUNT_AMOUNT: '',
            FULL_AMOUNT: '',
        };
    },

    setAttributes() {
        const { account, attributes, beneAccount } = this.options;

        const beneKeys = beneAccount.pick([
            'BENE_NAME',
            'BENE_ACCOUNT',
            'BENE_BANK_STATE',
            'BENE_BANK_ID',
            'BENE_BANK_NAME',
            'BENE_COUNTRY',
            'BENE_STATE',
            'BENE_PROVINCE',
            'BENE_POSTALCODE',
            'BENE_BIRTH_COUNTRY',
            'BENE_BIRTH_CITY',
            'BENE_BIRTH_DATE',
        ]);

        const accountAttributeKeys = util.reduce([
            'ACCOUNTFILTER',
            'DEBIT_ACCOUNT_TITLE',
            'DEBIT_ACCOUNT_BANK_NAME',
            'DEBIT_BANK_CODE',
            'DEBIT_BANKNAME',
            'DEBIT_COUNTRY',
            'DEBITACCOUNTEXTERNALFLAG',
            'ORIGINATOR_COUNTRY',
            'ORIGINATOR_ID',
            'ORIGINATOR_ID_TYPE',
            'ORIGINATOR_NAME',
            'ORIGINATOR_ADDRESS_1',
            'ORIGINATOR_ADDRESS_2',
            'ORIGINATOR_CITY',
            'ORIGINATOR_POSTALCODE',
            'ORIGINATOR_STATE',
            'ACCOUNT_TYPE',
        ], (acc, key) => {
            acc[key] = this.getAccountAttribute(account, key);
            return acc;
        }, {});

        const attributeKeys = util.pick(
            attributes,
            [
                'CREDIT_AMOUNT',
                'VALUE_DATE',
                'SPECIAL_INSTRUCTIONS',
                '_saveIncomplete',
                '_saveWithWarning',
                'DUPLICATEREASON',
                'duplicateAccepted',
                'CONTACTHASBEENUPDATED',
                'REMITTANCE_INFO',
                'REMITTANCE_METHOD',
                'REMITTANCE_ADDRESS',
                'BENE_ADDRESS',
                'BENE_CITY',
                'DISCOUNT_AMOUNT',
                'FULL_AMOUNT',
            ],
        );

        // These special keys change names
        const specialKeys = {
            BENE_ACCOUNT_TYPE: beneAccount.get('BENE_ACCOUNTTYPE') || 'Other',
            BENE_BANK_CITY: beneAccount.get('BENE_BANK_ADDRESS_3') || beneAccount.get('BENE_BANK_CITY'),
            CREDIT_CURRENCY: beneAccount.get('BENE_ACCOUNT_CURRENCY') || this.getAccountAttribute(account, 'CREDIT_CURRENCY'),
            DEBIT_ACCOUNT_NUMBER: attributes.DEBIT_ACCOUNT_NUMBER,
            TRAN_DATE: attributes.VALUE_DATE,
        };

        let attrIDKeys;

        if (attributes.id) {
            attrIDKeys = {
                TNUM: attributes.id,
                // eslint-disable-next-line
                UPDATECOUNT__: attributes.UPDATECOUNT__,
            };
        }

        this.set(util.extend(
            {},
            beneKeys,
            accountAttributeKeys,
            attributeKeys,
            specialKeys,
            attrIDKeys,
        ));
    },
});
