import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import Constants from 'common/dynamicPages/api/constants';
import locale from '@glu/locale';
import httpError from 'common/util/httpErrorResult';

export default {

    sync(method, model, options) {
        if (method === 'delete') {
            const deleteData = this.convertModelAttributesToKeyJSON(model);

            const deleteService = `${services.generateUrl(model.context.serviceName)}${Constants.URL_DELETE_ACTION}`;

            http.post(deleteService, deleteData, (result) => {
                options.success(result);
            }, (result) => {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                });
            });
        } else
        if (method === 'create') {
            model.set('USERGROUP', userInfo.get('group'), {
                silent: true,
            });

            const addData = this.convertModelAttributesToServerJSON(model, method, true);
            addData.searchFields = model.get('filters');

            const addService = `${services.generateUrl(model.context.serviceName)}${Constants.URL_ADD_ACTION}`;

            http.post(addService, addData, (result) => {
                model.generateIdValue();
                options.success(result);
            }, httpError.getResult(options, model));
        } else
        if (method === 'update') {
            model.set('USERGROUP', userInfo.get('group'), {
                silent: true,
            });

            const updateData = this.convertModelAttributesToServerJSON(model, method, true);
            const updateService = `${services.generateUrl(model.context.serviceName)}${Constants.URL_UPDATE_ACTION}`;
            updateData.searchFields = model.get('filters');

            http.post(updateService, updateData, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        } else
        if (method === 'read') {
            const readData = this.convertModelAttributesToKeyJSON(model);
            const readService = `${services.generateUrl(model.context.serviceName)}${Constants.URL_READ_ACTION}`;
            http.post(
                readService, readData, (response) => {
                    const data = this.convertServerJsonToModelAttributes(response);

                    if (model.context.actionMode === 'view') {
                        data.ALERTGROUP = locale.get(`ALERT.groupDescription.${data.ALERTGROUP}`);
                        data.ALERTTYPE = locale.get(`ALERT.typeDescription.${data.ALERTTYPE}`);
                    }

                    options.success(data);
                },
                () => {},
            );
        }
    },

    convertServerJsonToModelAttributes(serverJson) {
        const data = {};
        util.each(serverJson.item.item, (entry, index, itemArray) => {
            data[entry.name] = this.formatModelAttributes(entry, itemArray);
        });

        // store the filters values
        const filter = util.findWhere(serverJson.grids, {
            name: 'ALERTVAL',
        });
        this.alertFilters = filter.items;
        return data;
    },

    convertModelAttributesToKeyJSON(model) {
        const jsonData = {};
        jsonData.context = [];
        jsonData.context.push({
            name: 'PRODUCTCODE',
            value: model.get('PRODUCT'),
        });
        jsonData.context.push({
            name: 'FUNCTIONCODE',
            value: model.get('FUNCTION'),
        });
        jsonData.context.push({
            name: 'TYPECODE',
            value: model.get('TYPE'),
        });
        jsonData.item = {};
        jsonData.item.item = [];
        jsonData.item.item.push({
            name: 'ALERTID',
            value: model.get('ALERTID'),
        });

        return jsonData;
    },

    convertModelAttributesToServerJSON(model, method, addContext) {
        const getSearchFields = (filters) => {
            const formatFilters = (filter) => {
                const temp1 = filter.toString().split(',');
                const temp2 = temp1.join('\',\'');
                const newFilter = `'${temp2}'`;
                return newFilter;
            };
            const items = [];

            items.push({
                name: 'FIELDNAME',
                value: filters.fieldName,
            });
            items.push({
                name: 'OPERATOR',
                value: filters.operator,
            });
            items.push({
                name: 'VALUE',
                value: formatFilters(filters.fieldValue),
            });
            return items;
        };

        const retJson = {};
        const jsonData = this.getNameValuePairAttributes(method);

        if (method === 'update') {
            jsonData.push({
                name: 'ALERTID',
                value: model.get('ALERTID'),
            });
        }
        jsonData.push({
            name: ' ALERTCLASS',
            value: model.get('_ALERTCLASS'),
        });
        jsonData.push({
            name: ' PRODUCT',
            value: model.get('ALERTGROUP'),
        });
        jsonData.push({
            name: ' TYPE',
            value: model.get('ALERTTYPE'),
        });
        jsonData.push({
            name: ' USERGROUP',
            value: model.get('USERGROUP'),
        });
        jsonData.push({
            name: ' USERID',
            value: userInfo.get('id'),
        });
        jsonData.push({
            name: 'GROUPFLAG',
            value: (model.get('GROUPFLAG') || -1),
        });

        const filters = model.get('filters');
        retJson.item = {};
        retJson.item.item = jsonData;

        // add fine grain recipient data
        const recipients = this.getRelevantRecipientJSONData();
        retJson.recipients = recipients;

        retJson.grids = [];
        retJson.grids.push({
            name: 'ALERTVAL',
            items: [],
        });

        util.each(filters, (searchItem) => {
            const itm = getSearchFields(searchItem);
            retJson.grids[0].items.push({
                item: itm,
            });
        });

        if (addContext) {
            retJson.context = [];
            retJson.context.push({
                name: 'PRODUCTCODE',
                value: model.get('PRODUCT'),
            });
            retJson.context.push({
                name: 'FUNCTIONCODE',
                value: model.get('FUNCTION'),
            });
            retJson.context.push({
                name: 'TYPECODE',
                value: model.get('TYPE'),
            });
        }

        return retJson;
    },

    getRelevantRecipientJSONData() {
        const recipientList = this.get('recipients');
        let jsonRecipientList;
        const allRecipients = [];
        const filterContact = contact => contact.selected;
        if (recipientList) {
            jsonRecipientList = recipientList.toJSON();
            // filter out the non-selected methods
            util.each(jsonRecipientList, (jsonRecipient) => {
                const rdata = {};
                rdata.name = jsonRecipient.name;
                rdata.emails = util.filter(jsonRecipient.email, filterContact);
                rdata.emails = util.pluck(rdata.emails, 'value');
                rdata.sms = util.filter(jsonRecipient.sms, filterContact);
                rdata.sms = util.pluck(rdata.sms, 'value');
                rdata.phonenumbers = util.filter(jsonRecipient.phone, filterContact);
                rdata.phonenumbers = util.map(rdata.phonenumbers, phonedata => ({
                    extension: phonedata.extension || '',
                    pauseIndicator: phonedata.pause || '',
                    phone: phonedata.value,
                }));
                rdata.fax = util.filter(jsonRecipient.fax, filterContact);
                rdata.fax = util.pluck(rdata.fax, 'value');
                allRecipients.push(rdata);
            });
        }
        return allRecipients;
    },

};
