import ItemView from '@glu/core/src/itemView';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import Model from 'app/payments/models/photocopy';
import methodTmpl from './method.hbs';

export default ItemView.extend({
    className: 'col-4',
    template: methodTmpl,

    ui: {
        $fax: '.phone',
    },

    initialize(options) {
        this.method = options.method;
        this.model = new Model({
            DELIVERYMETHOD: options.method,
        });
        if (options.email) {
            this.model.set('EMAILADDRESS', options.email);
        }
        this.model.set(options.checkModel.toJSON());
    },

    onRender() {
        this.ui.$fax.inputmask(userInfo.getPhoneFormat());
    },

    submit() {
        const self = this;
        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    self.trigger('request:success', confirmResponse);
                    store.unset('cm_inq_searchFields');
                    window.history.back();
                },

                error(model) {
                    self.trigger('request:failed', model);
                },
            },
        );
    },

    cancel() {
        window.history.back();
    },

    templateHelpers() {
        return {
            isEmailMethod: this.method === 'EMAIL',
            isFaxMethod: this.method === 'FAX',
        };
    },
});
