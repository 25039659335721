import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import store from 'system/utilities/cache';
import errHandler from 'system/errHandler';
import mobileUtil from 'mobile/util/mobileUtil';
import constants from 'common/dynamicPages/api/constants';
import { createActionList, checkAction, formatConfirm } from 'common/util/achActionUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import AchCollection from 'app/administration/collection/achCollection';
import alertMessage from 'common/api/alertMessage';
import ListView from 'common/dynamicPages/views/workflow/list';
import entitlements from 'common/dynamicPages/api/entitlements';
import workspaceHelper from 'common/workspaces/api/helper';
import AchModelView from './achModelView';
import template from './achTotalListView.hbs';

const AdminAchControl = ListView.extend({
    template,
    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
        },
    ),
    initialize(options) {
        const superOptions = {
            menuCategory: 'RISK',
            serviceName: '/achControlTotalMaintenance',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'ACH_CONTROL_TOTAL_MAINTENANCE',
        };
        if (options?.userGroup) {
            superOptions.additionalSearchFields = [{
                fieldName: 'UserGroup',
                fieldValue: [
                    options.userGroup,
                ],
                dataType: 'text',
                operator: '=',
            }];
        }
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.makeApiCall = new AchCollection({});
        entitlements.getEntitlements({
            context: {
                serviceName: '/achControlTotalMaintenance',
            },
        }).then((result) => {
            this.actionEntitlements = result.actions;
        }).catch(this.handleError.bind(this));
        this.exportListViewPath = '/export/listView';
    },
    gridRowSelect(options) {
        const self = this;
        const attributes = options.model.toJSON();
        const reqOptions = this.makeReqBody(options.model);
        const lock = new AchModelView({
            state: 'modal',
            model: attributes,
        });
        this.makeApiCall.fetch({
            success: () => {
                dialog.open(lock);
            },
            error: self.handleError.bind(self),
            reqOptions,
        });
    },
    gridRowRestore(options) {
        const self = this;
        const reqOptions = this.makeReqBody(options.model);
        this.makeApiCall.restore({
            success: self.handleSuccess.bind(self),
            error: self.handleError.bind(self),
            reqOptions,
        });
    },
    gridRowDelete(options) {
        const self = this;
        const reqOptions = this.makeReqBody(options.model);
        this.makeApiCall.delete({
            success: self.handleSuccess.bind(self),
            error: self.handleError.bind(self),
            reqOptions,
        });
    },
    gridRowModify(options) {
        const modifiedOption = options;
        store.remove('modify-PaymentInitiatorGroupID');
        modifiedOption.isUserGroup = !!this.options.userGroup;
        store.set('modify-PaymentInitiatorGroupID', modifiedOption);
        this.navigateTo('CMAINT/addNewPigId');
    },
    handleSuccess(response) {
        this.renderMessage(response);
        this.gridView.refreshGridData();
    },
    /**
     * Get the error message from the response and show the alert
     * @param {Object} response - error response
     */
    handleError(response) {
        const { message } = response.responseJSON;
        // index 0 is technical garbage not for user, so get 1 index
        const alertMessages = message[1] || message[0];
        this.alertRegion.show(alert.negative(alertMessages));
    },

    addNewPIGID() {
        store.remove('modify-PaymentInitiatorGroupID');
        if (this.options.userGroup) {
            this.navigateTo(`CMAINT/addNewPigId/${this.options.userGroup}`);
            return;
        }
        this.navigateTo('CMAINT/addNewPigId');
    },
    // restore Payment Initiator Group ID
    restorePIGID() {
        const ar = this.gridView.grid.getSelectedRows();
        const self = this;
        const restorePromises = [];
        let txtKey = locale.get('RISK.dialog.confirm.item.restore');
        const addPromise = function (reqOptions) {
            restorePromises.push(new Promise((resolve, reject) => {
                self.makeApiCall.restore({
                    success: resolve,
                    error: reject,
                    reqOptions,
                });
            }));
        };
        if (ar.length) {
            if (ar.length > 1) {
                const unDeletedRow = util.find(ar, ob => createActionList(self.gridView?.wrapper?.rows?.get(ob)?.buttons, 'RESTORE'));
                txtKey = unDeletedRow ? `${locale.get('PAY.multi.restore.warning')} ${locale.get('RISK.dialog.confirm.items.restore')}` : locale.get('RISK.dialog.confirm.items.restore');
            }
            if (util.find(ar, ob => checkAction(self.gridView?.wrapper?.rows?.get(ob)?.buttons, 'RESTORE'))) {
                dialog.confirm(txtKey, locale.get('RISK.confirm.restore'), (ok) => {
                    if (ok) {
                        util.each(ar, (item) => {
                            const selectedRow = self.gridView.wrapper.rows.get(item);
                            if (selectedRow.buttons.find(button =>
                                button.action === constants.ACTION_RESTORE)) {
                                const options = self.makeReqBody(selectedRow);
                                addPromise(options);
                            }
                        });
                        Promise.all(restorePromises).then((results) => {
                            this.handleBulkResponse(results);
                        }).then(null, errHandler);
                    }
                });
            }
        }
    },
    handleBulkResponse(results) {
        const updatedConfirmResponse = formatConfirm(results);
        this.gridView.refreshGridData();
        this.renderMessage(updatedConfirmResponse);
    },

    renderMessage(confirmResponse) {
        const regionLoc = this.$('div[data-region="alertRegion"]').offset();
        if (confirmResponse) {
            alertMessage.renderMessage(this, confirmResponse.result ? 'SUCCESS' : 'DANGER', confirmResponse);
            if (regionLoc) window.scrollTo(regionLoc.left, regionLoc.top);
        }
    },
    makeReqBody(selectedRow) {
        return {
            item: [{
                name: 'USERGROUP',
                value: selectedRow.get('USERGROUP'),
            }, {
                name: 'PIGID',
                value: selectedRow.get('PIGID'),
            },
            ],
        };
    },
    // delete Payment Initiator Group ID
    deletePIGID() {
        const ar = this.gridView.grid.getSelectedRows();
        const self = this;
        const deletePromises = [];
        let txtKey = locale.get('tableMaintenance.dialog.confirm.item.delete');
        const addPromise = function (reqOptions) {
            deletePromises.push(new Promise((resolve, reject) => {
                self.makeApiCall.delete({
                    success: resolve,
                    error: reject,
                    reqOptions,
                });
            }));
        };
        if (ar.length) {
            if (ar.length > 1) {
                const deletedRow = util.find(ar, ob => createActionList(self.gridView?.wrapper?.rows?.get(ob)?.buttons, 'DELETE'));
                txtKey = deletedRow ? `${locale.get('PAY.multi.delete.warning')} ${locale.get('administration.delete.warning.plural')}` : locale.get('administration.delete.warning.plural');
            }
            if (util.find(ar, ob => checkAction(self.gridView?.wrapper?.rows?.get(ob)?.buttons, 'DELETE'))) {
                dialog.confirm(txtKey, locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
                    if (ok) {
                        util.each(ar, (item) => {
                            const selectedRow = self.gridView.wrapper.rows.get(item);
                            if (selectedRow.buttons.find(button =>
                                button.action === constants.ACTION_DELETE)) {
                                const options = self.makeReqBody(selectedRow);
                                addPromise(options);
                            }
                        });
                        Promise.all(deletePromises).then((results) => {
                            this.handleBulkResponse(results);
                        }).then(null, errHandler);
                    }
                });
            }
        }
    },

    getSearchFieldsByKeyList(gridView) {
        const searchFields = ListView.prototype.getSearchFieldsByKeyList.call(this, gridView);
        // changing the data types to text
        if (searchFields?.[1]) {
            searchFields[0].dataType = 'text';
            searchFields[1].dataType = 'text';
        }
        return searchFields;
    },
    export() {
        ListView.prototype.export.call(this, this.getPrintOptions('CSV'));
    },
    getPrintOptions(format = 'PDF') {
        return {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            hasSummarySelection: false,
            searchFields: this.getSearchFieldsByKeyList(this.gridView),
            format,
        };
    },
    templateHelpers() {
        const self = this;
        return {
            showDelete: self.actionEntitlements?.DELETE,
            showRestore: self.actionEntitlements?.RESTORE,
            showAdd: self.actionEntitlements?.INSERT,
            showSelect: self.actionEntitlements?.SELECT,
        };
    },
    onRender() {
        ListView.prototype.onRender.call(this);
        const message = store.get(`${this.contextKey}-alertMessage`);
        this.renderMessage(message);
    },
});
// eslint-disable-next-line import/no-mutable-exports
let list = AdminAchControl;
if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'risk.ach.pigid.addnew',
                entitlement: 'INSERT',
                handlerMethodName: 'addNewPIGID',
            },
        ],
        bulkActions: [
            {
                label: 'button.restore',
                entitlement: constants.ACTION_RESTORE,
                handlerMethodName: 'restorePIGID',
            },
            {
                label: 'button.delete',
                entitlement: constants.ACTION_DELETE,
                handlerMethodName: 'deletePIGID',
            },
        ],
    });
    list = list.extend(mobileList);
}
const exportedList = list;
workspaceHelper.publishedWidgets.add({
    id: 'ACH_CONTROL_TOTAL_MAINTENANCE',
    view: exportedList,
    options: {},
});
export default exportedList;
