import AssetCollection from '../assets';
import DataEntitlement from '../../models/user/dataEntitlement';

export default AssetCollection.extend({
    model: DataEntitlement,

    initialize(models, options) {
        if (options) {
            this.inquiryId = options.inquiryId;
            this.keyCol = options.keyCol ? options.keyCol.toUpperCase() : null;
            this.configuredDataType = options.configuredDataType;
            this.buildCustomFilters(options);
        }
    },

    requestBody() {
        return {
            requestHeader: {},
            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: this.inquiryId,
                        entryMethod: 0,
                        actionMode: 'SELECT',
                        functionCode: 'INQ',
                        productCode: '_POPUP',
                    },
                    customFilters: this.customFilters,
                    inquiryId: this.inquiryId,
                    searchType: '5',
                },
            },
        };
    },

    buildCustomFilters(options) {
        this.customFilters = [];

        if (options && options.userGroup) {
            this.customFilters.push({
                filterName: 'Depends',
                filterParam: ['UserGroup', options.userGroup],
            });
        }
    },

    // TODO The function below can be removed once the code is correctd on the server.
    /**
     *
     * A temporary method to ensure that the selected account is actually still entitled.
     * Because of a server side bug, when an account is removed from a company,
     * the deletion
     * is not propagated to the data entitlements that already were selected.
     * Because of this,
     * accounts appear to still be entitled/selected even though the company
     * does not have
     * entitlements to the account.
     */

    accountsNotEntitled(availableAccounts) {
        const self = this;
        return this.filter((selected) => {
            const id = selected.get(self.keyCol);
            return availableAccounts.every(available => available.id !== id);
        });
    },

    /**
     * this is necessary to match up the uce read format of accounts, and the
     * inquiry format
     */
    matchRecord(rec, keyCol) {
        // Account numbers may contain multiple hyphens. Split only the first
        const upperCaseKeyCol = keyCol.toUpperCase();
        const key = rec.get(keyCol) || rec.get(upperCaseKeyCol);
        const separator = upperCaseKeyCol === 'ACCOUNTFILTER' ? /-(.+)/ : '-';
        let parts = this.configuredDataType === 'LEGACYSYSTEMID' ? [key] : key.split(separator);

        if (upperCaseKeyCol === 'ACCOUNTFILTER') {
            // the split creates an unwanted empty string at the end of the array.
            parts.pop();
        }

        if (keyCol === 'idBankCode') {
            parts = [];
            parts[0] = rec.get('ECODE');
            parts[1] = rec.get('ACCOUNTNUM');
            parts[2] = rec.get('BANKCODE');
        }

        if (upperCaseKeyCol === 'PRODUCTTEMPLATECODEENTITLEMENT') {
            parts = [rec.name(), rec.get('PRODUCT'), rec.get('TYPE')];
        }

        return this.findWhere({
            dataEntitlementAttPart1: parts[0],
            dataEntitlementAttPart2: parts.length > 1 ? parts[1] : null,
            dataEntitlementAttPart3: parts.length > 2 ? parts[2] : null,
        });
    },
});
