var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-item=\"\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"view-bill-text-spacer\"><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.vendor.invoice.number",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":68},"end":{"line":26,"column":114}}}))
    + ": "
    + alias2(((helper = (helper = lookupProperty(helpers,"vendorInvoceNumber") || (depth0 != null ? lookupProperty(depth0,"vendorInvoceNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"vendorInvoceNumber","hash":{},"data":data,"loc":{"start":{"line":26,"column":116},"end":{"line":26,"column":138}}}) : helper)))
    + "</strong></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-item=\"\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + " , "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"column-2-container\">\n<div class=\"add-bill col-md-8\">\n    <form autocomplete=\"off\" data-submit=\"save\">\n\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n\n        <div class=\"form-group vendor-group\">\n            <label for=\"payee\" class=\"main-label\"><span class=\"bill-edit-only\">1.</span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":89},"end":{"line":10,"column":132}}}))
    + "</label>\n            <div class=\"multifield-container\">\n\n                    <div class=\"bill-edit-only\">\n                    <select class=\"form-control\" data-bind=\"model\" name=\"vendorId\" data-hook=\"select-vendor\">\n                        <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"vendors") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":18,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <input type=\"text\" name=\"reference\" value=\"\" data-bind=\"model\" id=\"invoiceNumber\" class=\"form-control\" placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.vendor.enter.invoice.number",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":136},"end":{"line":20,"column":188}}}))
    + "\"/>\n                    </div>\n\n                    <div class=\"bill-view-only\">\n                        <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"vendor") || (depth0 != null ? lookupProperty(depth0,"vendor") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"vendor","hash":{},"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":24,"column":42}}}) : helper)))
    + "</strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"vendorInvoceNumber") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":23},"end":{"line":27,"column":30}}})) != null ? stack1 : "")
    + "                    </div>\n\n\n            </div>\n        </div>\n\n        <div class=\"form-group billing-amount-group multifield-container\">\n            <label for=\"billAmount\" class=\"main-label\"><span class=\"bill-edit-only\">2.</span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":94},"end":{"line":35,"column":137}}}))
    + "</label>\n            <div class=\"field-container\">\n                    <input type=\"text\" class=\"form-control bill-edit-only\" name=\"total\" value=\"\" data-bind=\"model\" id=\"billAmount\" placeholder=\"\" />\n                    <div class=\"bill-view-only\"><strong>"
    + alias2(lookupProperty(helpers,"money").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalAmount") : depth0),(depth0 != null ? lookupProperty(depth0,"preferredCurrencyFormat") : depth0),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":38,"column":56},"end":{"line":38,"column":101}}}))
    + "</strong></div>\n            </div>\n        </div>\n\n        <div class=\"form-group billing-date-group \">\n            <label for=\"billingDate\" class=\"main-label\"><span class=\"bill-edit-only\">3.</span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":95},"end":{"line":43,"column":138}}}))
    + "</label>\n            <div class=\"multifield-container\">\n\n                    <div class=\"billing-date-container bill-edit-only control-group no-range-select\">\n                        <input type=\"text\" name=\"DUEDATE\" id=\"dateRange\" class=\"dateRange\" data-bind=\"model\"/>\n                    </div>\n                    <select class=\"form-control terms-select bill-edit-only\" id=\"terms\" name=\"terms\" data-hook=\"billing-terms\">\n                        <option value=\"Terms: Net 0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.on.receipt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":53},"end":{"line":50,"column":92}}}))
    + "</option>\n                        <option value=\"Terms: Net 10\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net10",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":54},"end":{"line":51,"column":88}}}))
    + "</option>\n                        <option value=\"Terms: Net 15\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net15",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":54},"end":{"line":52,"column":88}}}))
    + "</option>\n                        <option value=\"Terms: Net 30\" selected=\"true\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net30",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":70},"end":{"line":53,"column":104}}}))
    + "</option>\n                        <option value=\"Terms: Net 60\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net60",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":54},"end":{"line":54,"column":88}}}))
    + "</option>\n                    </select>\n\n                    <div class=\"bill-view-only\">\n                        <strong>"
    + alias2(lookupProperty(helpers,"date").call(alias1,(depth0 != null ? lookupProperty(depth0,"dueDate") : depth0),(depth0 != null ? lookupProperty(depth0,"dateFormat") : depth0),{"name":"date","hash":{},"data":data,"loc":{"start":{"line":58,"column":32},"end":{"line":58,"column":59}}}))
    + "</strong>\n                        <span class=\"view-bill-text-spacer\"><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"dueTerm") || (depth0 != null ? lookupProperty(depth0,"dueTerm") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"dueTerm","hash":{},"data":data,"loc":{"start":{"line":59,"column":68},"end":{"line":59,"column":79}}}) : helper)))
    + "</strong></span>\n                    </div>\n            </div>\n        </div>\n\n        <div class=\"form-group bill-description-group\">\n            <label for=\"billDescription\" class=\"main-label\"><span class=\"bill-edit-only\">4.</span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":99},"end":{"line":65,"column":142}}}))
    + "</label>\n            <div class=\"field-container\">\n               <textarea rows=\"2\" class=\"form-control bill-edit-only\" type=\"text\" maxlength=\"80\" name=\"description\"  id=\"billDescription\" data-bind=\"model\"></textarea>\n               <div class=\"bill-view-only\"><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":68,"column":51},"end":{"line":68,"column":66}}}) : helper)))
    + "</strong></div>\n            </div>\n        </div>\n\n        <div class=\"form-group approver-group bill-edit-only\">\n            <label for=\"approver\" class=\"main-label\"><span class=\"bill-edit-only\">5.</span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question5",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":92},"end":{"line":73,"column":135}}}))
    + "</label>\n            <div class=\"field-container\">\n                <select class=\"form-control\" data-bind=\"model\" name=\"approverId\" data-hook=\"select-approver\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"approvers") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":20},"end":{"line":79,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </div>\n\n    </form>\n\n    <div class=\"payment-total-container\">\n        <h2><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.bill.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":87,"column":20},"end":{"line":87,"column":55}}}))
    + ":</strong> <span class=\"bill-total\">"
    + alias2(lookupProperty(helpers,"money").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalAmount") : depth0),(depth0 != null ? lookupProperty(depth0,"preferredCurrencyFormat") : depth0),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":87,"column":91},"end":{"line":87,"column":136}}}))
    + "</span></h2>\n    </div>\n</div>\n\n        <div class=\"col-md-4 history\">\n    <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.bill.history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":8},"end":{"line":92,"column":45}}}))
    + "</h3>\n\n        <div class=\"bill-view-only\">\n          <strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":95,"column":18},"end":{"line":95,"column":49}}}))
    + ": "
    + alias2(((helper = (helper = lookupProperty(helpers,"billStatus") || (depth0 != null ? lookupProperty(depth0,"billStatus") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"billStatus","hash":{},"data":data,"loc":{"start":{"line":95,"column":51},"end":{"line":95,"column":65}}}) : helper)))
    + "</strong>\n          <div>\n          <img src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"imgBase") || (depth0 != null ? lookupProperty(depth0,"imgBase") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"imgBase","hash":{},"data":data,"loc":{"start":{"line":97,"column":20},"end":{"line":97,"column":31}}}) : helper)))
    + "/billThumbnail.png\" />\n          </div>\n        </div>\n\n    <ul class=\"history-list\">\n        <!-- History will be implemented in a future release -->\n        <!--<li>\n            <strong>Today<br/>\n                Submitted for Approval\n            </strong>\n            <br/>\n            By (you) \n        </li>\n\n        <li>\n            <strong>Today<br/>\n                Edited\n            </strong>\n            By (you)\n        </li> -->\n    </ul>\n\n</div>\n\n</div>\n";
},"useData":true});