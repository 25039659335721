import ItemView from '@glu/core/src/itemView';
import CollectionView from '@glu/core/src/collectionView';
import moveAccountsOptionTmpl from './moveAccountsOption.hbs';
import moveAccountsControlTmpl from './moveAccountsControl.hbs';

const MoveAccountsControl = ItemView.extend({

    initialize(o) {
        this.options = o;
        const { options } = this;
        this.groupListView = new CollectionView({
            collection: options.groups,
            itemView: ItemView.extend({
                template: moveAccountsOptionTmpl,
                tagName: 'option',
                initialize() {
                    this.listenTo(this.model, 'change', this.render);
                },
                attributes() {
                    return {
                        value: this.model.cid,
                        disabled: this.model.isMarkedForDeletion()
                                || this.model.sectionId() !== options.sectionId,
                    };
                },
                templateHelpers() {
                    return {
                        accountGroupName: this.model.getName(),
                    };
                },
            }),
        });

        this.groupListView.listenTo(options.groups, 'change', this.groupListView.render);
    },

    events: {
        'click [data-action=move-selected-accounts]': 'moveSelectedAccounts',
    },

    template: moveAccountsControlTmpl,

    onRender() {
        const $select = this.$('select');
        // Otherwise silly select2 doesn't recognize the placeholder.
        $select.prepend('<option></option>');
        $select.comboBox();
        this.groupListView.setElement($select[0]).render();
    },

    moveSelectedAccounts() {
        const group = this.options.groups.get(this.$('select').val());

        if (group) {
            this.options.accounts.where({
                isSelected: true,
            }).forEach((account) => {
                group.add(account.clone());
            });
        }
    },
});

export default MoveAccountsControl;
