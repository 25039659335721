import userInfo from 'etc/userInfo';
import { appBus } from '@glu/core';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import notificationManager from 'system/notifications/manager';
import StackView from 'common/stack/views/stackView';
import WorkspaceView from 'common/workspaces/views/workspace';

export default {
    coreWorkspace() {
        if (!userInfo.isSmbUser()) {
            if (!this.popPage() || this.getStackCount() === 0) {
                this.showPage('', new StackView({
                    initItem: new WorkspaceView({
                        id: 'HOME',
                        returnRoute: 'workspace',
                    }),
                }));
            }
        } else {
            appBus.trigger('router:navigate', 'PAYMENTS/smbManageHomePage', true);
        }
    },

    initDeepLinkEnv() {
        const InitDeepLinkEnv = Layout.extend({
            template: util.template('<html></html>'),
        });
        notificationManager.notify(
            'init',
            {
                key: 'bottomline.deeplink.ready',
                message: {
                    info: 'initialization completed',
                },
            },
        );
        this.showPage('', new InitDeepLinkEnv());
    },
};
