import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import CollectionView from '@glu/core/src/collectionView';
import constants from 'app/administration/constants';
import SimpleEntitlements from 'app/administration/collection/user/simpleEntitlements';
import LimitSetCollectionView from './limitSetCollectionView';
import AssignedAccountsView from '../assignedAccounts/assignedAccounts';
import paymentTypeTmpl from './paymentType.hbs';

// pass in typeDataEntitlements as collection
const AccountsCollectionView = CollectionView.extend({
    itemView: AssignedAccountsView,

    itemViewOptions(model, index) {
        return {
            entitledIndex: index,
            mode: this.options.mode,
            model: this.model,
            paymentTypeGroup: this.options.paymentTypeGroup,
            userGroup: this.options.userGroup,
            entitledTypes: this.options.entitledTypes,
            isRole: this.options.isRole,
        };
    },

    addItemView(model, ...rest) {
        if (model.get('inquiryId') !== 0) {
            CollectionView.prototype.addItemView.apply(this, [model, ...rest]);
        }
    },

    updateAccountsForPermissions(types) {
        this.children.each((view) => {
            view.updateAccountsForPermissions(types);
        });
    },
});

export default Layout.extend({
    tagName: 'div',
    className: 'panel permission-group',
    template: paymentTypeTmpl,

    ui: {
        $heading: '.panel-heading',
        $expandPanel: '.collapse',
        $selectAllPayments: '.select-all-payments',
        $selectAllTemplates: '.select-all-templates',
        $selectAllChecks: '.select-all-checks', // PCM RELATED
    },

    events: {
        'click @ui.$heading': 'togglePanel',
        'click @ui.$selectAllPayments': 'selectAllPaymentPerms',
        'click @ui.$selectAllTemplates': 'selectAllTemplatePerms',
        'click @ui.$selectAllChecks': 'selectAllCheckPerms', // PCM RELATED
    },

    initialize(options) {
        this.aggregateTypes = options.aggregateTypes;
        this.entitledTypes = options.entitledTypes;
        this.model = this.model || this.aggregateTypes;
        this.mode = options.mode;
        this.userGroup = options.userGroup;
        this.hasPanelApproval = options.hasPanelApproval;

        if (!this.aggregateTypes) {
            this.listenTo(this.model, 'expand-payment-types', function (expand) {
                this.ui.$expandPanel.collapse(expand ? 'show' : 'hide');
                this.togglePanel();
            });
        }

        this.paymentPermissions = new SimpleEntitlements(this.model.get('detailedTypeEntitlements').filter(model => util.contains(['instruction', 'batch'], model.get('functionCode')) && (options.mode !== constants.MODES.VIEW || model.isEntitled())));

        this.templatePermissions = new SimpleEntitlements(this.model.get('detailedTypeEntitlements').filter(model => util.contains(['template', 'batchTemplate'], model.get('functionCode')) && (options.mode !== constants.MODES.VIEW || model.isEntitled())));

        this.checkPermissions = new SimpleEntitlements(this.model.get('detailedTypeEntitlements').filter(model => util.contains(['checkManagement'], model.get('functionCode')) && (options.mode !== constants.MODES.VIEW || model.isEntitled())));
    },

    onRender() {
        this.paymentPermissionsView = new LimitSetCollectionView({
            mode: this.mode,
            hasPanelApproval: this.hasPanelApproval,
            collection: this.paymentPermissions,
        });

        this.templatePermissionsView = new LimitSetCollectionView({
            mode: this.mode,
            collection: this.templatePermissions,
        });
        // PCM RELATED
        this.checkPermissionsView = new LimitSetCollectionView({
            mode: this.mode,
            hasPanelApproval: this.hasPanelApproval,
            collection: this.checkPermissions,
        });

        this.assignedAccountsView = new AccountsCollectionView({
            collection: this.model.get('typeDataEntitlements'),
            mode: this.mode,

            /*
             * overwrite the model from the collection with the model containing the
             * collection
             * itemViewOptions will pull the right model again
             */
            model: this.model,

            paymentTypeGroup: this.options.paymentTypeGroup,
            userGroup: this.userGroup,
            entitledTypes: this.entitledTypes,
            isRole: this.options.isRole,
        });

        if (this.aggregateTypes) {
            this.togglePanel();
        }
    },

    togglePanel() {
        if (!this.panelLoaded) {
            if (this.paymentPermRegion) {
                this.paymentPermRegion.show(this.paymentPermissionsView);
            }
            if (this.templatePermRegion) {
                this.templatePermRegion.show(this.templatePermissionsView);
            }
            if (this.checkPermRegion) {
                this.checkPermRegion.show(this.checkPermissionsView);
            }
            this.accountsRegion.show(this.assignedAccountsView);

            this.panelLoaded = true;
        } else {
            this.assignedAccountsView.render();
        }
    },

    selectAllPaymentPerms(evt) {
        const { model } = this;

        this.paymentPermissions.selectAll(evt.currentTarget.checked);
        this.paymentPermissionsView.render();
        if (this.assignedAccountsView) {
            this.assignedAccountsView.children.each((childView) => {
                childView.updateAccountsForPermissions(model.get('detailedTypeEntitlements').getEntitledActions());
            });
        }
    },
    // PCM RELATED
    selectAllCheckPerms(evt) {
        const { model } = this;

        this.checkPermissions.selectAll(evt.currentTarget.checked);
        this.checkPermissionsView.render();
        if (this.assignedAccountsView) {
            this.assignedAccountsView.children.each((childView) => {
                childView.updateAccountsForPermissions(model.get('detailedTypeEntitlements').getEntitledActions());
            });
        }
    },
    selectAllTemplatePerms(evt) {
        const { model } = this;

        this.templatePermissions.selectAll(evt.currentTarget.checked);
        this.templatePermissionsView.render();
        if (this.assignedAccountsView) {
            this.assignedAccountsView.children.each((childView) => {
                childView.updateAccountsForPermissions(model.get('detailedTypeEntitlements').getEntitledActions());
            });
        }
    },

    templateHelpers() {
        const self = this;
        return {
            cid: this.model.cid,
            hideHeader: self.aggregateTypes,
            readOnly: this.mode === constants.MODES.VIEW,
            isAggregate: !!this.aggregateTypes,
            label: this.aggregateTypes ? '' : locale.get(this.model.get('typeCodeLabel')),

            hasPaymentPermissions() {
                return self.paymentPermissions && self.paymentPermissions.length > 0
                    && (self.mode !== constants.MODES.VIEW
                        || self.paymentPermissions.hasAnyEntitledAction());
            },
            // PCM RELATED
            hasCheckPermRegion() {
                return self.checkPermissions && self.checkPermissions.length > 0 &&
                 (self.mode !== constants.MODES.VIEW ||
                     self.checkPermissions.hasAnyEntitledAction());
            },

            hasTemplatePermissions() {
                return self.templatePermissions && self.templatePermissions.length > 0
                    && (self.mode !== constants.MODES.VIEW
                        || self.templatePermissions.hasAnyEntitledAction());
            },
        };
    },
});
