import Model from 'ptx/system/models/baseModel';
import urls from 'ptx/system/urls';
import { appBus } from '@glu/core';
import PrinterTrays from '../collections/printerTrays';

export default Model.extend({
    defaults: {
        pageOffsetX: 0,
        pageOffsetY: 0,
    },

    urlRoot() {
        return urls.printers;
    },

    initialize() {
        if (!this.collection) {
            this.printerTrays = new PrinterTrays();
            this.listenTo(this, 'sync', this.populatePrinterTrays);
            this.listenTo(this.printerTrays, 'change update:tray:mappings', this.updateTrayMappings);
            return;
        }

        this.listenTo(this.collection, {
            'printJob:search': this.printJobSearch,
            'printJob:filter:status': this.filterByStatus,
        });
    },

    parse(...args) {
        const resp = Model.prototype.parse.apply(this, args);

        resp.host = resp.host ? resp.host.replace(/\\*/, '') : resp.host;

        return resp;
    },

    populatePrinterTrays() {
        if (!this.printerTrays) {
            return;
        }

        this.printerTrays.reset(this.get('trayMappings') || []);
    },

    updateTrayMappings() {
        if (!this.printerTrays) {
            return;
        }

        this.set('trayMappings', this.printerTrays.toJSON());
    },

    printJobSearch(searchString) {
        this.trigger('do:printJob:search', searchString);
    },

    filterByStatus(status) {
        this.trigger('do:printJob:filter:status', status);
    },

    getJobCount() {
        return this.get('jobCount');
    },

    pausePrinter() {
        this.save({
            paused: !this.isPaused(),
        }, {
            patch: true,
            success: this.printerActionedCallback.bind(this),
        });
    },

    printerActionedCallback() {
        if (this.defaultUrl) {
            this.url = this.defaultUrl;
        }

        this.trigger('printer:actioned', this.id);
        appBus.trigger('refresh:printJobs');
    },

    isPaused() {
        return this.get('paused');
    },

    cancelAllJobs() {
        this.defaultUrl = this.url;
        this.url = urls.cancelAllJobs.params({
            printer: this.id,
        });

        this.save({
            cancel: true,
        }, {
            patch: true,
            success: this.printerActionedCallback.bind(this),
        });
    },

    retryAllJobs() {
        this.defaultUrl = this.url;
        this.url = urls.retryAllJobs.params({
            printer: this.id,
        });

        this.save({
            retry: true,
        }, {
            patch: true,
            success: this.printerActionedCallback.bind(this),
        });
    },

    redirectAllDocuments(printerId, callback) {
        this.defaultUrl = this.url;
        this.url = urls.redirectAllDocuments
            .params({
                printer: this.id,
            })
            .query({
                toPrinterId: printerId,
            });

        this.save(null, {
            patch: true,
            success: () => {
                if (this.isClosed) {
                    return;
                }

                this.printerActionedCallback();
                this.trigger('printer:actioned', printerId);

                if (typeof callback === 'function') {
                    callback();
                }
            },
        });
    },

    fetch(options, ...args) {
        if (options?.updatePrinterJobs) {
            this.trigger('update:printer:jobs');
        }

        return Model.prototype.fetch.apply(this, [options, ...args]);
    },
});
