import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, ashTheme } from '@glu/theming';
import { legacyTheme as btiqeLegacyTheme } from '@dbiqe/common';
import dialog from '@glu/dialog';
import $ from 'jquery';
import userInfo from 'etc/userInfo';
import Collection from '@glu/core/src/collection';
import { appBus } from '@glu/core';
import locale from '@glu/locale';
import GridApi from 'common/dynamicPages/api/grid';
import contextApi from 'common/dynamicPages/api/context';
import ListView from 'common/dynamicPages/views/workflow/list';
import ManageAccountsModal from 'app/setup/views/bankAcctFilter/modals/manageAccounts';
import ClientUserGroupsCollection from 'app/setup/collections/clientUserGroups';
import workspaceHelper from 'common/workspaces/api/helper';
import UserGroupModel from 'app/setup/models/userGroup';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import MobileViewContainer from 'components/MobileViewContainer/MobileViewContainer';
import template from './list.hbs';

const BankAccountFilter = ListView.extend({
    initialize(options) {
        this.options = options;
        this.gridView = options.gridView;
        this.collection = new Collection();
        this.title = locale.get('widget.31.description');
        this.listenTo(this.appBus, 'bankAcctFilters:userGroup:remove', this.gridRefresh);
        this.listenTo(this.appBus, 'bankAcctFilters:userGroup:add', this.gridRefresh);
        // set up listViewConfig with the context
        const context = contextApi.menuContext.getContext('ACCOUNT_FILTER');
        ListView.prototype.setListViewConfig.call(this, context);
    },

    template,

    ui: {
        $panel: '.panel-collapse',
        $panelContainer: '.panel.panel-primary',
    },

    events: {
        'shown.bs.collapse @ui.$panel': 'loadGridOnShow',
        'click [data-hook="refresh-button"]': 'refreshData',
        'click @ui.$panelContainer': 'handleMobilePanelClick',
    },

    loadRequiredData() {
        const clientUserGroupsPromise = this.clientUserGroups();

        return clientUserGroupsPromise.then((results) => {
            this.collection = results;
            if (results.at(0)) {
                this.defaultGroup = results.at(0).get('id');
            } else {
                this.defaultGroup = userInfo.get('group');
            }
            this.setHasLoadedRequiredData(true);
            this.render();
            return results;
        });
    },

    clientUserGroups() {
        return new Promise((resolve, reject) => {
            const clientUserGroups = new ClientUserGroupsCollection();
            clientUserGroups.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (!mobileUtil.isMobileGridEnabled()) {
                this.loadGrid(this.defaultGroup);
            }
        } else {
            this.loadRequiredData();
        }
    },

    loadGridOnShow(e) {
        const id = this.$(e.target).data('hook');
        if (!mobileUtil.isMobileGridEnabled()) {
            this.loadGrid(id);
        }
    },

    loadGrid(group) {
        const gridId = `grid_${group}`;

        const additionalSearchFields = [{
            fieldName: 'ParentUserGroup',
            fieldValue: [userInfo.get('group')],
            dataType: 'text',
            operator: '=',
        }, {
            fieldName: 'ChildUserGroup',
            fieldValue: [group],
            dataType: 'text',
            operator: '=',
        }];

        const accountFilter = contextApi.menuContext.getContext('ACCOUNT_FILTER');
        this.gridViewOptions = {
            context: accountFilter,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            additionalSearchFields,
            lvc: this.lvc,
            skipEntitlements: true,
        };
        this.gridView = GridApi.createServiceGridView(this.gridViewOptions);
        if (!mobileUtil.isMobileGridEnabled()) {
            this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
            ListView.prototype.setupGridAvailableListener.call(this);
            if (this[gridId]) {
                this[gridId].show(this.gridView);
            }
        }

        return this.gridView;
    },

    addAccounts(e) {
        this.showAccountsModal({
            userGroup: $(e.target).closest('.panel-collapse').data('hook'),
            name: $(e.target).closest('.panel-collapse').data('name'),
            noOfAccts: this.gridView.wrapper.rows.totalCount,
        });
    },

    removeAccounts(e) {
        const ar = this.gridView.grid.getSelectedRows();

        if (ar.length > 0) {
            const self = this;
            let keyValues = '';
            const childUserGroup = $(e.target).closest('.panel-collapse').data('hook');

            for (let i = 0; i < ar.length; i += 1) {
                const model = self.gridView.wrapper.rows.get(ar[i]);
                keyValues += this.groupObject(model);
            }

            const userGroups = new UserGroupModel({
                keyValues,
                parentUserGroup: userInfo.get('group'),
                childUserGroup,
                action: 'unassign',
            });

            userGroups.save(
                null,
                {
                    success() {
                        appBus.trigger('bankAcctFilters:userGroup:remove', childUserGroup);
                    },
                },
            );
        }
    },

    groupObject(model) {
        return `ACCOUNTNUM=${model.get('ACCOUNTNUM')}&BANKCODE=${model.get('BANKCODE')}|`;
    },

    showAccountsModal(optionsParams) {
        const options = optionsParams;
        options.title = `${locale.get('button.addAccts')}: ${options.userGroup} - ${options.name} (${options.noOfAccts} Accounts)`;
        const manageAccounts = new ManageAccountsModal(options);
        dialog.open(manageAccounts);
    },

    gridRefresh(group) {
        this.loadGrid(group);
    },

    templateHelpers() {
        return {
            clientUserGrps: this.collection.toJSON(),
            id: this.cid,
            isDesktop: !mobileUtil.isMobileGridEnabled(),
        };
    },

    onBeforeClose() {
        const mobileDrawerPanel = this.el.querySelector('.mobileDrawerPanel');
        if (mobileUtil.isMobileGridEnabled() && mobileDrawerPanel) {
            ReactDOM.unmountComponentAtNode(mobileDrawerPanel);
        }
    },

    grantAccess() {
        return true;
    },

    handleMobilePanelClick(e) {
        if (mobileUtil.isMobileGridEnabled()) {
            const sectionEl = e.currentTarget.querySelector('div[data-hook]');
            const section = sectionEl.dataset.hook;
            const mobileList = configureMobileInterface(BankAccountFilter, {
                insertActions: [
                    {
                        label: 'button.addAccts',
                        entitlement: 'INSERT',
                        handlerMethodName: 'addAccounts',
                        handleEntitlementMethodName: 'grantAccess',
                    },
                ],
                bulkActions: [
                    {
                        label: 'button.removeAccts',
                        entitlement: 'REMOVE',
                        handlerMethodName: 'removeAccounts',
                        handleEntitlementMethodName: 'grantAccess',
                    },
                ],
            });
            const List = BankAccountFilter.extend(mobileList);
            const gridView = this.loadGrid(section);
            const mobileView = new List({
                ...this.options,
                ...this.gridViewOptions,
                prebuiltOptions: true,
                gridView,
            });
            ReactDOM.render(
                // eslint-disable-next-line
                <ThemeProvider theme={btiqeLegacyTheme} baseTheme={ashTheme}>
                    <MobileViewContainer
                        isOpen
                        view={mobileView}
                        onClose={() => {
                            this.onBeforeClose();
                        }}
                    />
                </ThemeProvider>,
                this.el.querySelector('.mobileDrawerPanel'),
            );
        }
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'BANKACCOUNT_FILTERS',
    view: BankAccountFilter,
    options: {},
});

export default BankAccountFilter;
