import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import errorHandlers from 'system/error/handlers';
import workspaceHelper from 'common/workspaces/api/helper';
import listViewTmpl from './listView.hbs';

const IssuesVoidsList = ListView.extend({
    template: listViewTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMINSTRATION',
            serviceName: 'intermediaryBankMapping',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: false,
            context: 'SMAINT_BENINTMP',
            returnRoute: '/ADMINSTRATION/intermediaryBankMapping',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    loadViewRequirements() {
        const options = this.viewOptionRequirements();
        const gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);

        Promise.all([entitlementPromise]).then((results) => {
            const entResActions = results[0].actions;
            this.gridView = gridView;
            this.entitlements = entResActions;
            this.setHasLoadedRequiredData(true);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            ListView.prototype.setupGridAvailableListener.call(this);
            this.render();
        }, util.bind(errorHandlers.loading, this));
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = 20150;
        return this.exportModel;
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'SMAINT_BENINTMP',
    view: IssuesVoidsList,
    options: {},
});

export default IssuesVoidsList;
