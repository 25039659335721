var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form>\n    <fieldset>\n        <legend class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RDC.Please.Select.Location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":71}}}))
    + "</legend>\n        <div class=\"form-group\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RDC.SSO.Location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":19},"end":{"line":5,"column":48}}}))
    + "</label>\n            <div name=\"fieldValue\" data-region=\"locationRegion\"></div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"fieldValue\"></span>\n        </div>\n    </fieldset>\n</form>\n";
},"useData":true});