import React from 'react';
import PropTypes from 'prop-types';
import Backbone from 'backbone';
import $ from '@glu/core/src/$';
import { appBus } from '@glu/core';
import glu from '@glu/core/src/glu';

function isGlu(url) {
    return url && url.indexOf(Backbone.history.root) > -1;
}

const handleSinglePageHrefClicks = (event) => {
    const { target } = event;
    let $el = $(target);

    // solve for case of the target being an element inside the anchor tag, for example a span
    if (!$el.is('a')) {
        $el = $el.closest('a');
    }

    const href = $el.attr('href');

    if (isGlu(href)) {
        const passThrough = $el.data('enable-href');
        const urlRootRegEx = new RegExp(`^${glu.history.root}`);

        // Allow shift+click for new tabs, etc.
        if (!passThrough && !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();

            // Remove root from URL
            const url = href.replace(urlRootRegEx, '');

            // Instruct Backbone to trigger routing events
            if ($el.parents('.main').length > 0) {
                appBus.trigger('router:navigate', url, true);
            } else {
                appBus.trigger('router:navigate', url, { trigger: true, newStack: true });
            }

            // Trigger dropdown close event
            $(document).trigger('click.bs.dropdown');
        }
    }
};

/* eslint-disable */
// disable is for onClick requiring a key listener as well
const HrefHandler = props => (
    <div onClick={handleSinglePageHrefClicks} style={props.style}>
        {props.children}
    </div>
);
/* eslint-enable */

HrefHandler.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object.isRequired, // eslint-disable-line
};

export default HrefHandler;
