import React, {
    useState,
    useReducer,
    useEffect,
    useCallback,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import useLocale from 'hooks/useLocale';
import Layout from '@glu/layout-react';
import { Loading } from '@glu/indicators-react';
import FormFieldStatic from 'components/FormFieldStatic/FormFieldStatic';
import AuditSectionSimple from 'components/AuditSectionSimple/AuditSectionSimple';
import { Button, PRIMARY, SECONDARY } from '@glu/buttons-react';
import FormFieldStaticContainer from 'components/FormFieldStaticContainer/FormFieldStaticContainer';
import ActionButtonContainer from 'components/ActionButtonContainer/ActionButtonContainer';
import PrintModal from 'components/PrintModal/PrintModal';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useNavigation from 'hooks/useNavigation';
import useCache from 'hooks/useCache';
import entitledActions from 'common/util/entitledActions';
import { ContentEditorProvider } from 'components/ContentEditor/ContentEditorContext';
import ContentEditorContainer from 'components/ContentEditor/ContentEditorContainer';

import {
    getFooter,
    getFooterEntitlements,
    footerAction,
} from '../services';
import {
    MODE,
    CONTENT_TYPE_ID,
    ACTION,
} from '../constants';
import FooterContext from '../FooterWrapper/FooterContext';

const isBankDefined = contentType => contentType === CONTENT_TYPE_ID.BANK_DEFINED;
const modelHasAction = (action, buttons) => buttons.some(button => button.action === action);

const FooterReadOnly = ({
    returnRoute,
}) => {
    const { cancel, navigateTo } = useNavigation({
        returnRoute,
    });
    const {
        footerData,
        setFooterData,
        setEntitlements,
        modelData,
        modelActions,
    } = useContext(FooterContext);
    useEffect(() => {
        // When there isn't an action model, return to the list view
        if (!modelData.ALERTFOOTERID) {
            cancel();
        }
    }, [modelData, cancel]);
    const { getLocaleString } = useLocale();

    const onModify = useCallback(() => {
        navigateTo(`/CMAINT/alertContentManagement/footer/${MODE.MODIFY}`);
    }, [navigateTo]);
    const onCopy = useCallback(() => {
        navigateTo(`/CMAINT/alertContentManagement/alert/${MODE.COPY}`);
    }, [navigateTo]);

    const [isLoading, setIsLoading] = useState(true);
    const [printOptions] = useState({
        exportModel: {
            expData: 'transaction',
            actionData: {
                productCode: 'CMAINT',
            },
            searchFields: [
                {
                    fieldName: 'ALERTFOOTERID',
                    operator: 'IN',
                    fieldValue: [
                        modelData.ALERTFOOTERID,
                    ],
                    dataType: 'number',
                },
            ],
            detailReportId: 60018,
        },
    });
    const [showPrint, toggleShowPrint] = useReducer(state => !state, false);
    const [showConfirm, toggleShowConfirm] = useReducer(state => !state, false);

    const { set: setFooterMessage } = useCache('alertContentManagement_footers-alertMessage');
    const { set: setConfirmResponse } = useCache('alertContentManagement_footers-confirms');
    const onButtonAction = action => () => {
        footerAction(action, modelData).then((resp) => {
            setFooterMessage(action);
            setConfirmResponse(resp);
            navigateTo(returnRoute);
        });
    };
    const onApprove = onButtonAction(ACTION.APPROVE);
    const onDelete = onButtonAction(ACTION.DELETE);
    const onConfirm = (okay) => {
        if (okay) {
            onDelete();
        }
        toggleShowConfirm();
    };
    useEffect(() => {
        Promise.all([
            getFooter(modelData),
            getFooterEntitlements(),
        ]).then(([alertResp, entitledActionResp]) => {
            setFooterData({
                ...alertResp,
                CONTENTTYPE: modelData.CONTENTTYPE,
                LOCALE: modelData.LOCALE,
                STATUS: modelData.STATUS,
            });
            setEntitlements(entitledActionResp);
            setIsLoading(false);
        });
    }, [modelData, setFooterData, setEntitlements]);
    return (
        <Loading isLoading={isLoading}>
            <PrintModal
                show={showPrint}
                onHide={toggleShowPrint}
                options={printOptions}
            />
            <ConfirmModal
                title={getLocaleString('tableMaintenance.dialog.confirm.title.delete')}
                message={getLocaleString('tableMaintenance.dialog.confirm.item.delete')}
                show={showConfirm}
                onButtonAction={onConfirm}
            />
            <FormFieldStaticContainer>
                <Layout container>
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Locale')}
                        name="LOCALE"
                        value={footerData?.LOCALE}
                    />
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Content')}
                        name="contentType"
                        value={footerData?.CONTENTTYPE}
                    />
                </Layout>
                { isBankDefined(modelData.CONTENTTYPEID) && (
                    <Layout container>
                        <FormFieldStatic
                            col={3}
                            label={getLocaleString('CMAINT.Market.Segments')}
                            name="marketSegments"
                            value={footerData?.MARKETSEGMENTS?.join(', ')}
                        />
                        <FormFieldStatic
                            col={3}
                            label={getLocaleString('CMAINT.Status')}
                            name="status"
                            value={footerData?.STATUS}
                        />
                    </Layout>
                )}
            </FormFieldStaticContainer>
            <div className="section section-container">
                <ContentEditorProvider
                    previewOnly
                >
                    <ContentEditorContainer
                        content={footerData?.FOOTERCONTENT || ''}
                        hasPills={false}
                    />
                </ContentEditorProvider>
                {isBankDefined(modelData.CONTENTTYPEID) && (
                    <section>
                        <AuditSectionSimple
                            data={footerData}
                        />
                    </section>
                )}
                <ActionButtonContainer>
                    {isBankDefined(modelData.CONTENTTYPEID) && (
                        <>
                            { modelHasAction(entitledActions.ACTIONS.modify, modelActions)
                                && (
                                    <Button
                                        className="btn btn-primary"
                                        variant={PRIMARY}
                                        text={getLocaleString('button.modify')}
                                        onClick={onModify}
                                    />
                                )}
                            { modelHasAction(entitledActions.ACTIONS.approve, modelActions)
                                && (
                                    <Button
                                        className="btn btn-secondary"
                                        variant={SECONDARY}
                                        text={getLocaleString('button.approve')}
                                        onClick={onApprove}
                                    />
                                )}
                            { modelHasAction(entitledActions.ACTIONS.delete, modelActions)
                                && (
                                    <Button
                                        className="btn btn-secondary"
                                        variant={SECONDARY}
                                        text={getLocaleString('button.delete')}
                                        onClick={toggleShowConfirm}
                                    />
                                )}
                            { modelHasAction(entitledActions.ACTIONS.copyfooter, modelActions)
                                && (
                                    <Button
                                        className="btn btn-secondary"
                                        variant={SECONDARY}
                                        text={getLocaleString('CMAINT.CopyAlert')}
                                        onClick={onCopy}
                                    />
                                )}
                        </>
                    )}
                    <Button
                        className="btn btn-secondary"
                        variant={SECONDARY}
                        text={getLocaleString('button.print')}
                        onClick={toggleShowPrint}
                    />
                    <Button
                        className="btn btn-secondary"
                        variant={SECONDARY}
                        text={getLocaleString('button.cancel')}
                        onClick={cancel}
                    />
                </ActionButtonContainer>
            </div>
        </Loading>
    );
};

FooterReadOnly.propTypes = {
    returnRoute: PropTypes.string.isRequired,
};

export default FooterReadOnly;
