import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(options) {
        this.buildCustomFilters(options);
        this.isAdmin = (window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal');
        this.typeCode = this.isAdmin ? 'CLUSR_TM' : '_USER';
        this.functionCode = this.isAdmin ? 'MAINT' : 'USERS';
        this.productCode = this.isAdmin ? 'CMAINT' : '_ADMIN';
    },

    sync(method, model, options) {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {

                inquiryId: '17276',
                action: {
                    typeCode: this.typeCode,
                    entryMethod: 0,
                    actionMode: 'SELECT',
                    functionCode: this.functionCode,
                    productCode: this.productCode,
                },
                customFilters: this.customFilters,
                queryType: 'SearchQuery',
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 1,
                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },
                    userId: '',
                },
                requestId: '',
            },
        };

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        return response.map(rowItem => ({
            id: rowItem.name,
            label: rowItem.label,
            mapDataList: rowItem.mapDataList,
        }));
    },

    buildCustomFilters(options) {
        this.customFilters = [];

        if (options && options.userGroup) {
            this.customFilters.push({
                filterName: 'Depends',
                filterParam: ['UserGroup', options.userGroup],
            });
        }
    },

});
