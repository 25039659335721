var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " required ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFieldName") || (depth0 != null ? lookupProperty(depth0,"getFieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFieldName","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":36}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"getFieldLabel") || (depth0 != null ? lookupProperty(depth0,"getFieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFieldLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":55}}}) : helper)))
    + "</label>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"form-control-static\">\n            <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"getFieldValue") || (depth0 != null ? lookupProperty(depth0,"getFieldValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"getFieldValue","hash":{},"data":data,"loc":{"start":{"line":7,"column":18},"end":{"line":7,"column":35}}}) : helper)))
    + "</span>\n        </p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input class=\"input-date\" data-hook=\"value-date\" type=\"text\" data-bind=\"model\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFieldName") || (depth0 != null ? lookupProperty(depth0,"getFieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFieldName","hash":{},"data":data,"loc":{"start":{"line":10,"column":93},"end":{"line":10,"column":109}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"getProtected") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":111},"end":{"line":10,"column":162}}})) != null ? stack1 : "")
    + " value=\"\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFieldName") || (depth0 != null ? lookupProperty(depth0,"getFieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFieldName","hash":{},"data":data,"loc":{"start":{"line":10,"column":176},"end":{"line":10,"column":192}}}) : helper)))
    + "\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"getFieldName") || (depth0 != null ? lookupProperty(depth0,"getFieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFieldName","hash":{},"data":data,"loc":{"start":{"line":11,"column":66},"end":{"line":11,"column":82}}}) : helper)))
    + "\"></span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " readonly tabIndex=\"-1\" ";
},"10":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none;\"";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"icon-info\" data-toggle=\"popover\" title=\"\" data-placement=\"auto\" data-content=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"getTranDateToolTip") || (depth0 != null ? lookupProperty(depth0,"getTranDateToolTip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"getTranDateToolTip","hash":{},"data":data,"loc":{"start":{"line":22,"column":107},"end":{"line":22,"column":129}}}) : helper)))
    + "\"></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none;\" ";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"date-picker-button-item\">\n                <input type=\"radio\" name=\"optionalRadio\" data-type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">\n                <label for=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</label>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"date-picker "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMandatory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":60}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"getFieldLabel") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReadOnly") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "    <div data-section=\"cutoff-date-section\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldDisplayCutoffDate") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":111}}})) != null ? stack1 : "")
    + ">\n        <span class=\"textline-field\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.PaymentMustBeApprovedBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":37},"end":{"line":14,"column":77}}}))
    + "</span>\n        <p class=\"form-control-static\">\n            <span class=\"textline-field\" data-bind=\"model\" data-text=\"CUTOFF_INFO\"></span>\n        </p>\n    </div>\n    <div data-section=\"tran-date-section\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldDisplayTranDate") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":107}}})) != null ? stack1 : "")
    + ">\n        <label class=\"helper-text\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"tranDateFieldLabel") || (depth0 != null ? lookupProperty(depth0,"tranDateFieldLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"tranDateFieldLabel","hash":{},"data":data,"loc":{"start":{"line":20,"column":35},"end":{"line":20,"column":57}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"getTranDateToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </label>\n        <p class=\"form-control-static\">\n            <input class=\"helper-text\" data-bind=\"model\" name=\"TRAN_DATE\" readonly id=\"TRAN_DATE\" />\n        </p>\n    </div>\n    <div data-section=\"post-date-section\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldDisplayPostDate") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":42},"end":{"line":29,"column":108}}})) != null ? stack1 : "")
    + ">\n        <label class=\"helper-text\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"postDateFieldLabel") || (depth0 != null ? lookupProperty(depth0,"postDateFieldLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"postDateFieldLabel","hash":{},"data":data,"loc":{"start":{"line":30,"column":35},"end":{"line":30,"column":57}}}) : helper)))
    + "</label>\n        <p class=\"form-control-static\">\n           <input class=\"helper-text\" data-bind=\"model\" name=\"POST_DATE\" readonly />\n        </p>\n    </div>\n    <ul class=\"date-picker-button-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"optionalButtons") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":41,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});