import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import $ from 'jquery';
import PaymentTypeCollectionView from './assignedPermissions/paymentTypeCollectionView';
import permissionsByPaymentTypeTmpl from './permissionsByPaymentType.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default Layout.extend({
    template: permissionsByPaymentTypeTmpl,

    ui: {
        $paymentGroupBtn: '.btn-link-group .btn-link',
        $expandBtn: '.expand-all-btn',
        $collapseBtn: '.collapse-all-btn',
        $selectAll: '[data-hook="selectAllTypePerm"]',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPermissions',
    },

    initialize(options) {
        this.mode = options.mode;
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.ui.$collapseBtn.hide();

        if (this.ui.$paymentGroupBtn.length > 0) {
            this.ui.$paymentGroupBtn.first().addClass('active');
            this.loadPermissionsByPaymentGroup(this.ui.$paymentGroupBtn.data().value);
        }
        this.onChildrenChange();
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
        this.onChildrenChange();
    },

    getCheckboxList() {
        return this.paymentTypesRegion?.$el?.find('input[type="checkbox"]');
    },

    showPermissionsByPaymentGroup(e) {
        const $target = $(e.currentTarget);
        const selectedGroupId = e.target.dataset.value;

        this.ui.$paymentGroupBtn.removeClass('active');
        $target.addClass('active');

        this.ui.$expandBtn.toggle(true);
        this.ui.$collapseBtn.toggle(false);
        this.ui.$selectAll.prop('checked', false);

        this.loadPermissionsByPaymentGroup(selectedGroupId);
    },

    loadPermissionsByPaymentGroup(groupId) {
        this.paymentTypesView = new PaymentTypeCollectionView({
            collection: this.model.getEntitledTypesForSpecificGroup(groupId),
            mode: this.mode,
            isRole: Boolean(this.model.roleInfo),
            paymentTypeGroup: groupId,
            hasPanelApproval: this.model.userGroupModel.get('hasPanelApproval'),
            userGroup: this.model.userGroupModel.get('id'),
            allSelected: (allSelected) => {
                this.options.onChildrenChange?.(allSelected, this.ui.$selectAll, groupId);
            },
        });

        this.paymentTypesRegion.show(this.paymentTypesView);
    },

    expandCollapseAll(e) {
        const $el = this.$(e.currentTarget);
        const expand = Boolean($el.find('.icon-plus').length);

        this.ui.$expandBtn.toggle(!expand);
        this.ui.$collapseBtn.toggle(expand);

        this.paymentTypesView.collection.each((model) => {
            model.trigger('expand-payment-types', expand);
        });
    },

    // select all permissions across all types for the group.
    selectAllPermissions(evt) {
        this.paymentTypesView.collection.selectAll(evt.currentTarget.checked);
        this.paymentTypesView.render();
    },

    templateHelpers() {
        const self = this;

        return {
            cid: this.cid,

            paymentGroupIds() {
                const paymentGroupTypes = [];
                self.model.getSelectedGroups().each((type) => {
                    paymentGroupTypes.push({
                        id: type.get('id'),
                        label: locale.get(`administration.${type.get('label')}`),
                    });
                });
                return paymentGroupTypes;
            },
        };
    },
});
