import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import Model from '@glu/core/src/model';
import transform from 'common/util/transform';
import locale from '@glu/locale';
import SummaryList from 'app/administration/views/adminReporting/summaryList';
import workspaceHelper from 'common/workspaces/api/helper';
import filterApi from 'common/dynamicPages/api/filters';
import FlexDropdown from '@glu/flex-dropdown';
import constants from 'app/administration/constants';
import ReportTypesCollection from 'app/administration/collection/adminReporting/reportTypes';
import ReportSubTypeCollection from 'app/administration/collection/adminReporting/reportSubTypes';
import reportingTmpl from './reporting.hbs';

const AdminReporting = Layout.extend({
    template: reportingTmpl,

    ui: {
        $searchButton: '[data-hook="getReportSearch"]',
    },

    events: {
        'click @ui.$searchButton': 'search',
    },

    initialize(options) {
        this.model = options.model || new Model();

        this.options = util.extend({}, {
            context: 'ADMIN_REPORT',
            typeInfo: {
                productCode: 'SMAINT',
                functionCode: 'MAINT',
                typeCode: 'STATSRPT',
            },
        }, options);

        this.model.validators = this.setValidators();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.initReportTypeDropdown();
            this.initReportSubTypeDropdown([]);
        } else {
            this.loadRequiredData();
        }
    },

    setValidators() {
        return {
            reportType: {
                description: locale.get('stats.reportTypeDropdows'),
                exists: true,
            },
            reportSubType: {
                description: locale.get('stats.reportSubTypeDropdows'),
                exists: true,
            },
        };
    },

    loadRequiredData() {
        const self = this;

        const options = {
            inquiryId: constants.STATS_REPORT_TYPE_DROPDOWN_INQ,
        };

        this.reportTypeCollectionFetch(ReportTypesCollection, options)
            .then((results) => {
                self.reportTypeDataCollection = results;
                self.setHasLoadedRequiredData(true);
                self.render();
            }).catch(this.handleFetchError.bind(this, locale.get('stats.reportTypeDropdows')));
    },

    handleFetchError(key) {
        const message = locale.get('common.combo.data.error').replace('{0}', key);
        this.alertRegion.show(alert.negative(message), {
            canDismiss: true,
        });
    },

    /**
     * @param {Collection} CollectionType
     * @param {object}  options
     * @return {Promise}
     * Takes in options and returns a promise to get Report Sub Type Content
     */
    reportTypeCollectionFetch(CollectionType, options) {
        return new Promise((resolve, reject) => {
            new CollectionType(options).fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    /**
     * @param {array} selected
     * Report Type dropdown selection callback
     * Will fetch and load the subtype dropdown based on selection chosen
     */
    reportTypeChange(selected) {
        const self = this;
        const [selectedId] = selected;

        const options = {
            inquiryId: constants.STATS_REPORT_SUB_TYPE_DROPDOWN_INQ,
            REPORTTYPECODE: selectedId,
        };

        if (selectedId) {
            // set selectedSubType
            this.model.set('reportType', selectedId);

            // clear other sections
            this.filterRegion.reset();

            // fetch report subtype data
            this.reportTypeCollectionFetch(ReportSubTypeCollection, options)
                .then((results) => {
                    const reportSubTypeData = self.mapDropdownData(results);
                    self.reportSubTypeDataCollection = results;
                    self.initReportSubTypeDropdown(reportSubTypeData);
                }).catch(this.handleFetchError.bind(this, locale.get('stats.reportSubTypeDropdows')));
        }
        this.summaryListRegion.close();
    },

    /**
     * @param {array} selected (flexdropdown selected return)
     * Report SUB Type dropdown selection callback
     * Upon selection, will fetch and load the additonal filters sections
     */
    reportSubTypeChange(selected) {
        const [selectedId] = selected;
        // guarantee not a blank selection
        if (selectedId) {
            const selectedSubType = this.reportSubTypeDataCollection.find(model => model.get('CODE') === selectedId);

            if (selectedSubType.get('FILTERID')) {
                this.showAdditionalFilters(selectedSubType.get('FILTERID'));
            }

            // set selectedSubType
            this.model.set('reportSubType', selectedSubType);
        }
        this.summaryListRegion.close();
    },

    /**
     * @method initReportTypeDropdown
     * Creates the initial Report Type dropdown
     */
    initReportTypeDropdown() {
        const reportTypeDropdownOptions = {
            data: this.mapDropdownData(this.reportTypeDataCollection),
            defaultSelectMessage: locale.get('stats.select.reporttype'),
        };

        this.reportTypeDropdown = this.createDropdown(
            this.reportTypeDropdownRegion,
            reportTypeDropdownOptions,
        );
        this.listenTo(this.reportTypeDropdown, 'selectionChanged:id', this.reportTypeChange);
    },

    /**
     * @method initReportSubTypeDropdown
     * @param {array} subTypeData
     * Takes in subtype data to create and populate a report sub type dropdown to
     * accompany the
     * primary type dropdown
     */
    initReportSubTypeDropdown(subTypeData) {
        const reportSubTypeDropdownOptions = {
            data: subTypeData,
            defaultSelectMessage: locale.get('stats.select.reportvolume'),
            preSelectFirstItem: subTypeData.length === 1,
        };

        this.reportSubTypeDropdown = this.createDropdown(
            this.reportSubTypeDropdownRegion,
            reportSubTypeDropdownOptions,
        );
        this.listenTo(this.reportSubTypeDropdown, 'selectionChanged:id', this.reportSubTypeChange);

        // preselect subtype if there is only one option
        if (subTypeData.length === 1) {
            this.reportSubTypeChange(this.reportSubTypeDropdown.value('id'));
        }
    },

    /**
     * @method createDropdown
     * @param {Region} region to place dropdown
     * @param {object} options - flexdropdown options
     * Method to create flex dropdowns
     */
    createDropdown(region, options) {
        const optionsParam = options;
        optionsParam.multiSelect = false;
        optionsParam.disableMultiButton = true;
        optionsParam.showTooltip = false;
        optionsParam.filter = true;

        const dropdown = new FlexDropdown(optionsParam);

        region.show(dropdown);

        return dropdown;
    },

    /**
     * @param {Collection} data
     * @return {array}
     * Map collection models into a format that can be used to populate
     * flex dropdowns
     */
    mapDropdownData(data) {
        return util.map(data.models, (rowItem) => {
            const obj = {
                id: rowItem.get('CODE'),
                name: rowItem.get('DESCRIPTION'),
            };

            return obj;
        });
    },

    /*
     * Needed for reference by FilterApi
     */
    getCacheName() {
        const retStr = `model-${this.filterContextDef.serviceName}-${this.filterContextDef.productCode}-${this.filterContextDef.functionCode}`;
        return retStr;
    },

    /**
     * @param {string} id
     * Fetches and shows the additional filters section using the filterApi
     */
    showAdditionalFilters(id) {
        this.filterContextDef = util.extend({
            serviceName: '/advanceFilter',
        }, this.options.typeInfo);
        const self = this;
        const requestData = {
            filterId: id,
            typeInfo: this.options.typeInfo,

        };

        filterApi.getFilters(requestData, self);
    },

    /**
     * Search callback to submit all filters and input data.
     * Will load a grid with returned data
     */
    search() {
        // validate
        if (!this.model.isValid()) {
            return;
        }

        const selectedSubType = this.model.get('reportSubType');
        const filterModel = this.filterRegion.currentView
            ? this.filterRegion.currentView.model : null;

        if (filterModel && !filterModel.isValid()) {
            return;
        }

        const additionalSearchFields = filterModel
            ? filterApi.gatherFilterData(filterModel) : (this.filterData || []);

        const requestParameters = {
            reportTypeCode: selectedSubType.get('REPORTTYPECODE'),
            reportSubTypeCode: selectedSubType.get('CODE'),
        };

        const options = {
            enableSavedViews: false,
            filter: false,
            selector: 'none',
            serviceName: this.options.serviceName,
            context: this.options.context,
            reportSubTypeCode: selectedSubType.get('CODE'),
            additionalSearchFields,
            summaryListRegion: this.summaryListRegion,
            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
        };

        this.alertRegion.close();
        this.summaryListRegion.show(new SummaryList(options));
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'ADMIN_REPORT',
    view: AdminReporting,
    options: {},
});

export default AdminReporting;
