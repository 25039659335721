import moment from 'moment';
import userInfo from 'etc/userInfo';

function getPreviousDay() {
    switch (moment(new Date()).day()) {
    /*
     * If it is Monday (1),Saturday(6), or Sunday (0), Get the previous Friday (5)
     * and ensure we are on the previous week
     */
    case 0:
    case 1:
    case 6:
        return moment(new Date()).subtract(6, 'day').day(5);

    default:
        // If it any other weekend, just return the previous day
        return moment(new Date()).subtract(1, 'day');
    }
}

/**
 *
 * @param {String} code - Date Code
 * @param {moment|*} [dateToday] - The current Date or a Moment-wrapped date,
 * for unit testing.
 * @return {*}
 */
function getDateFromCode(code, dateToday) {
    const today = moment(dateToday || new Date());

    switch (code) {
    // Last 7 days (start of)
    case 'L7D':
        return today.subtract(6, 'day');

        // Last 30 Days (start of);
    case 'L30D':
        return today.subtract(29, 'day');

        // Last 60 Days (start of)
    case 'L60D':
        return today.subtract(59, 'day');

        // Last 90 Days (start of)
    case 'L90D':
        return today.subtract(89, 'day');

        // Previous Two Weeks?
    case 'PTW':
        return today.subtract(2, 'week');

        // Previous Week Start?
    case 'PWS':
        return today.subtract(1, 'week').startOf('week');

        /*
         * Current Week Start?
         * Week to Date?
         */
    case 'CWS':
    case 'WTD':
        return today.startOf('week');

        // Previous Week End?
    case 'PWE':
        return today.subtract(1, 'week').endOf('week');

        // Previous Month Start?
    case 'PMS':
        return today.startOf('month').subtract(1, 'month');

        // Previous Month End?
    case 'PME':
        return today.subtract(1, 'month').endOf('month');

        /*
         * Current Month Start?
         * Month to Date (start of)
         */
    case 'CMS':
    case 'MTD':
        return today.startOf('month');

        // Quarter to Date (start of)
    case 'QTD':
        return today.startOf('quarter');

        // Previous Year Start?
    case 'PYS':
        return today.subtract(1, 'year').startOf('year');

        // Previous Year End?
    case 'PYE':
        return today.subtract(1, 'year').endOf('year');

        /*
         * Current Year Start?
         * Year to Date (start of)
         */
    case 'CYS':
    case 'YTD':
        return today.startOf('year');

        // Previous Day
    case 'PD':
        return getPreviousDay();

        // Current Day
    case 'CD':
        return today;

        // Next Day
    case 'ND':
        return today.add(1, 'day');

        // Most recent??
    case 'MR':
    default:
        return false;
    }
}

export default {
    convertCodesToDates(startDate) {
        const convertedDate = getDateFromCode(startDate);

        if (convertedDate) {
            // Format for the user settings
            return convertedDate.format(userInfo.getDateFormat());
        }
        // TODO: What can we do here?
        return undefined;
    },

    prettyPrintDate(dateStr) {
        if (dateStr) {
            // Try to convert the date string to actual momemt date
            const date = this.convertCodesToDates(dateStr);
            if (date) {
                return date;
            }
            // At this point we cannot convert the date string, so just return it.
            return dateStr;
        }

        // Return today's date
        return this.convertCodesToDates('CD');
    },

    getDates(start, end) {
        const startDate = this.prettyPrintDate(start);
        if (start === 'PD') {
            return {
                startDate,
                endDate: startDate,
            };
        }
        return {
            startDate,
            endDate: this.prettyPrintDate(end),
        };
    },
};
