import dialog from '@glu/dialog';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import moment from 'moment';
import userInfo from 'etc/userInfo';

import onUsWireDatePromptTemplate from './onUsWireDatePromptTemplate.hbs';

export default Layout.extend({
    template: onUsWireDatePromptTemplate,
    modalClass: 'modal-xlg dialog-warning',
    dialogTitle: ' ',

    initialize(options) {
        this.businessDaysResponse = options.businessDaysResponse;
        this.model = options.model;
        this.$dateInput = options.$dateInput;
    },

    /**
     * Programatically returns the buttons for the modal so they're in the proper place on the DOM
     * in the modals footer and can be affected by existing listeners
     */
    buttons() {
        return [{
            text: locale.get('common.yes'),
            className: 'btn btn-primary btn-sm',
            callback: util.bind(() => {
                this.model.set('VALUE_DATE', this.businessDaysResponse.earliestDay);
                this.$dateInput.blur();
                dialog.close();
            }, this),
        }, {
            text: locale.get('common.no'),
            className: 'btn btn-secondary btn-sm',
            callback: util.bind(() => {
                dialog.close();
            }, this),
        }];
    },

    /* display the warning icon in the header of the modal */
    getHeaderIcon() {
        return 'warning';
    },

    onRender() {
        // update the title and buttons of the modal
        this.trigger('dialog:title:change', locale.get('RTGS.wire.valueDateModal'));
        this.trigger('dialog:buttons:change', this.buttons());
    },

    templateHelpers() {
        const earliestDay = moment(this.businessDaysResponse.earliestDay)
            .format(userInfo.getDateFormat());
        this.businessDaysResponse.earliestDay = earliestDay;
        this.businessDaysResponse.defaultDay = moment(this.businessDaysResponse.defaultDay)
            .format(userInfo.getDateFormat());
        return {
            newDateInfo: locale.get('RTGS.wire.altCutoffAvailable', earliestDay),
            newDatePrompt: locale.get('RTGS.wire.altCutoffDecision', earliestDay),
        };
    },
});
