import { ItemView } from '@glu/core';
import tmpl from './offsetInput.hbs';

export default ItemView.extend({
    template: tmpl,
    className: 'form-group',

    templateHelpers() {
        return {
            propertyName: this.options.propertyName,
        };
    },
});
