/* istanbul ignore file */
export default {
  maxPrimary: 'Max of',
  findColumnByName: 'Find Column By Name',
  columns: 'Columns',
  showAll: 'Show All',
  hideAll: 'Hide All',
  unpinAllColumns: 'Unpin all columns',
  pin: 'Pin',
  left: 'Left',
  right: 'Right',
  unpin: 'Unpin',
  xHidden: '{0} Hidden',
  xVisible: '{0} Visible'
};
