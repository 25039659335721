import errHandler from 'system/errHandler';
import baseApi from 'app/smb/views/baseApi';

export default {
    updateRecipients(id, recipients) {
        const recipientList = baseApi.beneficiaryList.get(id);
        Promise.all([recipientList])
            .then((results) => {
                recipients.set(results[0].models);
            })
            .then(null, errHandler);
    },
};
