import { createUseStyles } from '@glu/theming';

const styles = ({ buttons }) => ({
    root: {
        position: 'relative',

        '& .tox-toolbar-overlord.tox-tbtn--disabled .tox-toolbar__group': {
            visibility: 'hidden',
        },
        '& .tox-toolbar__primary': {
            width: 'calc(100% - 50px) !important',
        },
    },

    hidden: {
        display: 'none',
    },
    previewButton: {
        background: 'none',
        border: 0,
        borderBottom: '1px solid #909090',
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: 50,
        paddingBottom: 12,
        position: 'absolute',
        right: 0,
        top: 2,
        zIndex: 2,

        '&:hover:not([disabled])': {
            background: 'none',
            border: 0,
            borderBottom: '1px solid #909090',
            borderRadius: 0,
        },

        '& svg path': {
            fill: buttons.primary.backgroundColor,
        },

        '&:hover:not([disabled]) svg path': {
            fill: buttons.primary.hoverColor,
        },
    },
});
export default createUseStyles(styles);
