import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import ContentView from 'app/reports/views/contentView';
import constants from 'app/reports/constants';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import AsyncReportContentView from 'app/reports/views/asyncReportContentView';
import legacyReportViewTmpl from './legacyReportView.hbs';
import contentViewTmpl from './contentView.hbs';

const ReportView = Layout.extend({
    template: legacyReportViewTmpl,

    initialize(options) {
        this.model = options.model;
    },

    onRender() {
        moveToTopCheck(this.model);
        this.renderReport(this.model);
    },

    renderReport(result) {
        const exportId = result?.exportId || result?.get?.('exportId');
        if (exportId) {
            this.content.show(new AsyncReportContentView({
                hasDetailReport: true,
                exportId,
                disabledFormats: [constants.EXPORT_FORMAT_CSV],
                ...result,
            }));
        } else {
            this.content.show(new ContentView({
                model: result,
                template: contentViewTmpl,
                reportId: result.get('REPORTUID'),
                target: 'pdfContent',
                disposition: 'view',
                exportFormat: 'PDF',
                type: 'legacy',
                formId: 'viewPDF',
                disabledFormats: [constants.EXPORT_FORMAT_CSV],
            }));
        }
    },

    showError(result) {
        const message = alert.danger(result);
        this.alertRegion.show(message);
    },

    cancel() {
        window.history.back();
    },
});

export default ReportView;
