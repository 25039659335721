import Layout from '@glu/core/src/layout';
import GroupPlanCollection from '../collection/groupPlan';
import GroupPlanLayout from './groupPlanLayout';
import groupSurrogateLayoutTmpl from './groupSurrogateLayout.hbs';

const GroupSurrogateLayout = Layout.extend({
    className: 'widget-group-plan-view widget',
    template: groupSurrogateLayoutTmpl,

    regions: {
        pageRegion: '#region',
    },

    initialize() {
        this.plans = null;
        this.getGroupPlans().then(plans => this.initViews(plans));
    },

    getGroupPlans() {
        return new Promise((resolve) => {
            const groupPlans = new GroupPlanCollection();
            groupPlans.once('sync', resolve);
            groupPlans.fetch();
        });
    },

    initViews(plans) {
        this.plans = plans;
        this.show();
    },

    show() {
        if (!this.plans) {
            return;
        }
        const view = new GroupPlanLayout({
            groupPlans: this.plans,
        });
        this.pageRegion.show(view);
    },
});

export default GroupSurrogateLayout;
