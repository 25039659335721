import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import dialog from '@glu/dialog';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import $ from 'jquery';
import Model from '@glu/core/src/model';
import deploymentTmpl from './deployment.hbs';

export default Layout.extend({
    template: deploymentTmpl,
    className: 'modal deployment-modal',

    initialize(options) {
        const { rows } = this.model.get('segments');
        this.segments = new Collection();
        for (let i = 0; i < rows.length; i += 1) {
            const { columns } = new Array(rows[i])[0];
            const columnModel = new Model();
            for (let j = 0; j < columns.length; j += 1) {
                columnModel.set(columns[j].fieldName, columns[j].fieldValue);
            }
            this.segments.add(columnModel);
        }

        this.externalWidgetModel = new Model();
        this.externalWidgetModel.set('widgetId', parseFloat(options.widgetId));
        http.post(
            services.generateUrl('/cxp/externalWidget/getDeploymentModel'),
            {
                externalWidgetModel: this.externalWidgetModel.toJSON(),
            },
            (response) => {
                for (let k = 0; k < response.segments.length; k += 1) {
                    const checkbox = `input[name=${response.segments[k]}]`;
                    $(checkbox).attr('checked', true);
                }
            },
        );
    },

    save() {
        const pushSegments = [];

        util.each(this.segments.models, (model) => {
            const checkbox = `input[name=${model.get('NAME')}]`;
            if ($(checkbox).is(':checked')) {
                pushSegments.push(model.get('NAME'));
            }
        });
        http.post(
            services.generateUrl('/cxp/externalWidget/deploy'),
            {
                externalWidgetModel: this.externalWidgetModel.toJSON(),
                segments: pushSegments,
            },
            () => {
                dialog.close();
            },
        );
    },

    cancel() {
        dialog.close();
    },

    templateHelpers() {
        return {
            segments: this.segments.toJSON(),
        };
    },
});
