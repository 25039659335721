import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import RecipientModel from 'app/smb/models/map/beneficiary';
import CollectionComboBoxView from 'common/collectionComboBox/views/base';
import CommentView from '../modals/comment';
import RecipientView from '../modals/recipient';
import addRowTmpl from './addRow.hbs';

export default Layout.extend({
    template: addRowTmpl,
    tagName: 'tr',
    className: 'add-payment',
    binding: true,
    editMode: false,

    initialize(options) {
        if (options.model.editRow) {
            this.editMode = true;
            this.model = options.model.clone();
            this.originalModel = options.model;
        }

        this.model = options.model.clone();
        this.recipients = options.editOptions.recipients;
        this.toAccounts = options.editOptions.toAccounts;
        this.countries = options.editOptions.country;
        this.accountTypes = options.editOptions.accountType;
    },

    onRender() {
        const self = this;

        this.employeeComboBoxView = new CollectionComboBoxView({
            collection: this.recipients,
            displayStache: '{{{BENE_NAME}}}',
            selectedStache: '{{{BENE_NAME}}}',
            noSelectionText: locale.get('smb.employee.select'),
            emptyText: locale.get('smb.employee.none.found'),
            addButtonText: locale.get('mb.employee.add'),
            canAdd: true,
        });

        this.employeeComboBoxView.on('remove', (model) => {
            self.employeeComboBoxView.collection.remove(model);
            if (model.cid === self.model.cid) {
                self.ui.$selectedRecipient.empty();
            }
        });
        this.employeeComboBoxView.on('select', (model) => {
            self.resetValidationMessages('[data-validate="BENE_NAME"]');
            self.selectRecipient(model);
        });
        this.employeeComboBoxView.on('modify', (model) => {
            self.popRecipient(model, true);
        });
        this.employeeComboBoxView.on('add', () => {
            self.popRecipient(new RecipientModel(), false);
        });

        this.addEmployeeRegion.show(this.employeeComboBoxView);
        this.updateCommentButtonText();
    },

    ui: {
        $recipientName: '[data-hook="BENE_NAME"]',
        $commentLink: '[data-hook="commentButton"]',
        $selectedRecipient: '[data-hook="recipient-selected"]',
        $selectList: '[data-hook="selectList"]',
        $saveButton: '[data-action="save"]',
        $cancelButton: '[data-action="delete"]',
        $amount: 'input#amount',
    },

    templateHelpers() {
        return {
            toAccounts: this.toAccounts,
            recipient: this.recipients,
            notEditMode: !this.editMode,
            modelId: this.model.cid,
        };
    },

    onShow() {
        if (this.editMode) {
            const recipient = this.model.get('recipient');
            this.selectRecipient(recipient);

            this.employeeComboBoxView.selectList().comboBox(
                'data',
                {
                    value: recipient.get('BENE_ACCOUNTNUMBER'),
                    text: recipient.get('BENE_NAME'),
                },
            );
            this.ui.$saveButton.find('span').removeClass('icon-plus').addClass('icon-tick');
            this.ui.$cancelButton.attr({
                title: locale.get('smb.cancel'),
            });
        }
    },

    // add mode (add row) function; fired in baseAddGrid
    add(e) {
        const self = this;

        if (e) {
            e.preventDefault();
        }

        const recipient = this.model.get('recipient');
        this.model.set('BENE_NAME', recipient.get('BENE_NAME'));

        const isValid = this.model.isValid();

        // store off a copy
        this.originalModel = this.model;
        if (isValid) {
            this.trigger('save', this.model, (result) => {
                if (result) {
                    self.clear();
                }
            });
        }
    },

    // add mode (add row) function; fired in baseAddGrid
    clear() {
        const self = this;
        this.trigger('clear', this.model, (result) => {
            if (result) {
                self.resetElements();
            }
        });
    },

    // edit mode function
    save(e) {
        if (e) {
            e.preventDefault();
        }

        const recipient = this.model.get('recipient');
        this.model.set('BENE_NAME', recipient.get('BENE_NAME'));

        const isValid = this.model.isValid();

        if (isValid) {
            this.copyPropertiesDuringSave();
            this.model = this.originalModel;
            this.trigger('save');
            this.appBus.trigger('sbPayments:paymentListUpdated');
        }
    },

    // edit mode function
    cancel(e) {
        if (e) {
            e.preventDefault();
        }

        this.model = this.originalModel;
        this.trigger('save');
        this.appBus.trigger('sbPayments:paymentListUpdated');
    },

    resetElements() {
        this.ui.$selectedRecipient.empty();
        this.employeeComboBoxView.clearSelection();
        this.resetValidationMessages(null);
        this.updateCommentButtonText();
    },

    resetValidationMessages(message) {
        if (!message) {
            util.each(this.$el.find('[class="help-block"],.date-error'), (el) => {
                this.$(el).empty();
            });
        } else {
            this.$el.find(message).empty();
        }
    },

    copyPropertiesDuringSave() {
        this.originalModel.set(this.model.attributes);
    },

    updateCommentButtonText() {
        this.ui.$commentLink.val(this.model.get('comment').isEmpty() ? locale.get('sbPayments.commentAdd') : locale.get('sbPayments.commentEdit'));
    },

    popComment() {
        const self = this;
        const commentModal = new CommentView({
            model: this.model.get('comment'),
        });

        commentModal.on('save', (model) => {
            self.model.set('comment', model);
            self.updateCommentButtonText();
        }, this);

        dialog.custom(commentModal);
    },

    popRecipient(model, isEditMode) {
        const self = this;

        const recipientModal = new RecipientView({
            model,
            originalModel: model.clone(),
            country: this.countries,
            accountType: this.accountTypes,
            isEditMode,
        });

        recipientModal.on('save', (saveModel) => {
            if (isEditMode) {
                self.appBus.trigger('sbPayments:recipientListUpdated', saveModel);
                self.appBus.trigger(
                    'sbPayments:paymentCollectionUpdated',
                    {
                        model: saveModel,
                        originalModel: self.originalModel,
                    },
                );
            } else {
                self.recipients.add(saveModel);
            }

            self.model.set('recipient', saveModel);

            const recipient = self.model.get('recipient');

            self.employeeComboBoxView.selectList().comboBox(
                'data',
                {
                    value: recipient.get('BENE_ACCOUNTNUMBER'),
                    text: recipient.get('BENE_NAME'),
                },
            );

            const html = `${locale.get('sbPayments.routingNumber')}: <b>${recipient.get('BENE_BANK_ID')}</b><br/>${locale.get('sbPayments.accountNumber')}: <b>${recipient.get('BENE_ACCOUNTNUMBER')}</b>`;

            this.ui.$selectedRecipient.html(html);
            self.appBus.trigger('sbPayments:recipientAddRowUpdated', html);
        }, this);

        dialog.custom(recipientModal);
    },

    selectRecipient(selected) {
        let html = '';

        if (selected) {
            // set selected item to parent model
            this.model.set('recipient', selected);

            const recipient = this.model.get('recipient');

            // set selected info on payment card
            html = `${locale.get('sbPayments.routingNumber')}: <b>${recipient.get('BENE_BANK_ID')}</b><br/>${locale.get('sbPayments.accountNumber')}: <b>${recipient.get('BENE_ACCOUNTNUMBER')}</b>`;
        } else if (!this.editMode) {
            // payment card clear
            this.model.reset();
        } else {
            // recipient clear in edit mode
            this.model.set('recipient', new RecipientModel());
            this.model.set('BENE_NAME', '');
        }

        this.ui.$selectedRecipient.html(html);
    },
});
