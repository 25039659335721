import InformationalMessageView from './informationalMessage/informationalMessageView';

export default {
    /**
     *  Create new informational message
     * @param  {string} message message to show
     * @return {object} new informational message
     */
    showMessage(message) {
        return new InformationalMessageView({
            message,
        });
    },

};
