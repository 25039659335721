import Dialog from '@glu/dialog';
import $ from 'jquery';
import util from '@glu/core/src/util';
import LookupGridView from 'common/dynamicPages/views/lookupGrid';
import { triggerMaskedInputChanged } from 'common/dynamicPages/views/mdf/mdfUtil';

/**
 * Performs the map data for the lookup, i.e. updates additional fields in
 * the model based on the lookup selection
 * @param model - lookup item model
 * @param view - parent view of the lookup
 *
 */
const mapLookupSelect = function (model, view) {
    const { lookupHelperText } = view.model;
    const lhtFields = lookupHelperText ? Object.keys(lookupHelperText) : [];
    const lookupFields = [];

    if (!model.mapDataList) {
        return;
    }

    let modelAttrs = {};
    util.each(model.mapDataList, (mapData) => {
        const toField = mapData.toField.toUpperCase();
        if (util.contains(lhtFields, toField)) {
            lookupFields.push(toField);
        }
        modelAttrs = {
            ...modelAttrs,
            [toField]: mapData.value,
        };
        /*
         * a change event does not need to be triggered here to update the field onscreen
         * viewbinding handles this within the setDomValue function
         */
    });

    /*
     * CREDIT_CURRENCY_1 is used for a hack in fxPaymentWidget.js, in instances where it is
     * changed, it is also appropriate to kick off a change of CREDIT_CURRENCY
     */
    if (modelAttrs.CREDIT_CURRENCY_1) {
        modelAttrs.CREDIT_CURRENCY = modelAttrs.CREDIT_CURRENCY_1;
    }

    /**
     * NH-176279: Wire Intl
     * Set BENEBOOK_ID to empty string if the lookup was performed for a beneficiary and
     * did not occur from the Contact Center
     */
    if (modelAttrs.BENE_BANK_ID && !modelAttrs.BENEBOOK_ID) {
        modelAttrs.BENEBOOK_ID = '';
    }

    // Set all the attributes at once so change callbacks have all updated data
    view.model.set(modelAttrs);
    // trigger event for masked inputs
    triggerMaskedInputChanged(view.model);

    // find the lookup helper field and trigger lookup:selected on the view's model, view.model
    lookupFields.forEach((lookupField) => {
        view.trigger('lookupHelperText:update', lookupField);
    });
};

export default {
    setupSingleLookups(view, $alt) {
        // Support an alternate container element
        const $el = $alt || view.$el;

        $el.find('[data-hook="lookup-single"]').each(function () {
            const fieldName = $(this).attr('data-field');
            $(this).bind('click', (e) => {
                const depends = [];
                let dependsValue;
                const fieldData = view.model.fieldData[fieldName];
                if (fieldData.dependsOn !== null) {
                    for (let i = 0; i < fieldData.dependsOn.length; i += 1) {
                        dependsValue = view.model.get(fieldData.dependsOn[i]);
                        if (util.isEmpty(dependsValue) && view.model.isChild) {
                            dependsValue = view.parentModel.get(fieldData.dependsOn[i]);
                        }
                        depends.push({
                            name: fieldData.dependsOn[i],
                            value: dependsValue,
                        });
                    }
                }
                const lookupOptions = {
                    hideButtons: true,
                    fieldName,

                    context: {
                        serviceName: view.model.context.serviceName,
                        reimburse: view.model.context.reimburse,
                    },

                    typeInfo: {
                        typeCode: view.model.jsonData.typeInfo.typeCode,
                        productCode: view.model.jsonData.typeInfo.productCode,
                        functionCode: view.model.jsonData.typeInfo.functionCode,
                    },
                };
                if (view.model.context.subType) {
                    lookupOptions.subType = view.model.context.subType;
                }
                if (depends) {
                    lookupOptions.depends = depends;
                }
                const lookupViewOptions = {
                    gridOptions: lookupOptions,
                    gridApi: view.initializeOptions.gridApi,
                    isSingle: true,
                };

                let lookupView;
                if (view.initializeOptions.isModal) {
                    lookupViewOptions.isModal = false;
                    lookupView = new LookupGridView(lookupViewOptions);
                    view.listenTo(lookupView, 'lookup:mainWinScroll', (modalTop) => {
                        const $modal = $('[data-region="pageContent"]').find('.layover');
                        $modal.css({
                            top: `${modalTop}px`,
                        });
                    });
                    view.listenTo(lookupView, 'lookup:cancel', () => {
                        view.trigger(
                            'lookup:layover',
                            {
                                view: null,
                            },
                        );
                    });
                    view.listenTo(lookupView, 'lookup:selected', (model) => {
                        mapLookupSelect(model, view);
                        view.trigger(
                            'lookup:layover',
                            {
                                view: null,
                            },
                        );
                    });
                    view.trigger(
                        'lookup:layover',
                        {
                            view: lookupView,
                            event: e,
                        },
                    );
                } else {
                    // close any other dialog
                    lookupViewOptions.isModal = true;
                    lookupView = new LookupGridView(lookupViewOptions);
                    Dialog.close();
                    view.listenTo(lookupView, 'lookup:cancel', () => {
                        Dialog.close();
                    });

                    view.listenTo(lookupView, 'lookup:selected', (model) => {
                        mapLookupSelect(model, view);
                        Dialog.close();
                    });
                    Dialog.open(lookupView);
                }
            });
        });
    },
};
