import GluModel from '@glu/core/src/model';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import Formatter from 'system/utilities/format';
import sbPaymentsApi from 'app/smb/api';
import services from 'services';
import userInfo from 'etc/userInfo';
import CommentModel from './comment';

let Model = GluModel;

export default function () {
    Model = GluModel.extend({
        urlRoot: '',

        defaults: {
            fromAccountId: '',
            toAccountId: '',
            fromAccountName: '',
            toAccountName: '',
            fromAccountAttributes: {},
            toAccountAttributes: {},
            amount: '',
            paymentDate: '',
            comment: new CommentModel(),
            BENE_NAME: '',
            BENE_BANK_CODE: '',
            DEBIT_BANK_CODE: '',
            CREDIT_CURRENCY: '',
            ACCOUNT_FILTER: '',

            paymentDisplayDate() {
                /*
                 * Is the default date format ok to use here?
                 * Do we need DD MMM YYYY specifically?
                 */
                return Formatter.formatDate(this.paymentDate, 'DD MMM YYYY');
            },

            displayAmount() {
                const amount = this.amount ? this.amount : 0;
                return Formatter.formatCurrency(amount);
            },
        },

        initialize() {
            this.validators = {
                fromAccountId: {
                    description: locale.get('sbPayments.fromAccount'),
                    exists: true,
                },

                toAccountId: {
                    description: locale.get('sbPayments.toAccount'),
                    exists: true,
                    otherDescription: locale.get('sbPayments.fromAccount'),
                    notSameValue: 'fromAccountId',
                },

                amount: {
                    description: locale.get('sbPayments.amount'),
                    matches: sbPaymentsApi.AMOUNT_PATTERN,
                },

                paymentDate: {
                    description: locale.get('sbPayments.effectiveDate'),
                    exists: true,
                    matches: sbPaymentsApi.DATE_PATTERN,
                },
            };
        },

        displayBalance(balance) {
            if (balance !== locale.get('sbPayments.notAvailable')) {
                return Formatter.formatCurrency(balance);
            }
            return balance;
        },

        reset(...args) {
            Model.prototype.clear.apply(this, args);
            this.set(util.clone(this.defaults));
        },

        convertModelAttributesToServerJSON(model) {
            const jsonData = [];

            jsonData.push({
                name: 'CREDIT_AMOUNT',
                value: model.get('amount'),
            });

            jsonData.push({
                name: 'VALUE_DATE',
                value: model.get('paymentDate'),
            });

            jsonData.push({
                name: 'TRAN_DATE',
                value: model.get('paymentDate'),
            });

            const comment = model.get('comment');
            jsonData.push({
                name: 'SPECIAL_INSTRUCTIONS',
                value: comment.get('comment'),
            });

            jsonData.push({
                name: 'BENE_NAME',
                value: model.get('BENE_NAME'),
            });

            jsonData.push({
                name: 'BENE_ACCOUNT',
                value: model.get('toAccountId'),
            });

            jsonData.push({
                name: 'BENE_BANK_CODE',
                value: model.get('BENE_BANK_CODE'),
            });

            jsonData.push({
                name: 'DEBIT_BANK_CODE',
                value: model.get('DEBIT_BANK_CODE'),
            });

            jsonData.push({
                name: 'DEBIT_ACCOUNT_NUMBER',
                value: model.get('fromAccountId'),
            });

            jsonData.push({
                name: 'CREDIT_CURRENCY',
                value: model.get('CREDIT_CURRENCY'),
            });

            jsonData.push({
                name: 'ACCOUNTFILTER',
                value: model.get('ACCOUNTFILTER'),
            });

            jsonData.push({
                name: 'PARENTUSERGROUP',
                value: userInfo.get('group'),
            });

            jsonData.push({
                name: 'USERGROUP',
                value: userInfo.get('group'),
            });

            return {
                item: jsonData,
            };
        },

        sync(method, model, options) {
            if (method === 'create') {
                const addData = this.convertModelAttributesToServerJSON(model);

                http.post(services.paymentTransferAdd, addData, (result) => {
                    options.success(result);
                }, (result) => {
                    options.error({
                        errorCode: result.status,
                        errorMessage: result.statusText,
                        message: result.responseText,
                    });
                });
            }
        },
    });
    return new Model();
}
