/* istanbul ignore file */

/**
 * Should have all locale keys used in this project with translations matching the language-region
 * code of this file.
 */
export default {
  buttonClearAll: 'Clear All',
  buttonRemove: 'Remove',
  buttonRestart: 'Restart',
  buttonUpload: 'Upload',
  buttonUploadAll: 'Upload All',
  close: 'Close',
  errorAlertTitle: 'File was not uploaded',
  errorAlertTitlePlural: 'Files were not uploaded',
  errorAlertTitleFromContext: 'Error',
  fileSelectLabel: 'Select Files',
  filesFailDenylist: 'file format is incorrect',
  filesFailAllowlist: 'file format is incorrect',
  filesInvalidSize: 'exceeds the {0}MB limit', // '?' is replaced with the maxFileSize prop
  headerActions: 'Actions',
  headerCategory: 'Category',
  headerDate: 'Date',
  headerFileName: 'File Name',
  headerNotes: 'Notes',
  headerPermission: 'Permission',
  headerProgress: 'Progress',
  labelCategorySelect: 'Categories',
  labelFilenameInput: 'Filename',
  labelNotesInput: 'Notes',
  labelPermissionSelect: 'Permissions',
  progressErrorMessage: 'Error uploading file',
  progressStalledMessage: 'Upload stalled',
  progressUnit: 'MB',
  uploadPromptButtonPlural: 'select files',
  uploadPromptButtonSingular: 'select file',
  uploadPromptPart1Plural: 'Drag files here or',
  uploadPromptPart1Singular: 'Drag file here or',
  uploadPromptPart2: 'from your computer',
  validationNotEnoughFiles: 'Not enough files selected',
  validationTooManyFiles: 'Too many files selected'
};
