// Custom filter built using this: https://www.ag-grid.com/javascript-grid-filter-component/
export default class MultiFilter {
    model = null;

    init({ colDef }) {
      this.field = colDef.field;
      this.filterValueGetter = colDef.filterValueGetter;
    }


    isFilterActive() {
      return this.model;
    }

    doesFilterPass(params) {
      if (!this.model) {
        return false;
      }

      const { data } = params;
      const condition = this.model.filter;
      if (condition) {
        const field = this.filterValueGetter ? this.filterValueGetter(params) : data[this.field];
        return field === condition;
      }
      const conditions = Object.entries(this.model)
        .filter(([name]) => /condition/.test(name))
        .map(([, value]) => value.filter);
      const field = this.filterValueGetter ? this.filterValueGetter(params) : data[this.field];
      return conditions.some(filter => filter === field);
    }

    // We never set the model with AG Grid GUI, so preserve model and
    // Return when requested.
    getModel() {
      return this.model;
    }

    setModel(model) {
      this.model = model;
      return model;
    }

    // Mandatory method. Must return an HTML string. We never access.
    // Only shows up in AG Grid GUI.
    /* eslint-disable-next-line class-methods-use-this */
    getGui() {
      return '<div></div>';
    }
}
