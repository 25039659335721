/* eslint backbone/no-constructor:0 */
import Backbone from 'backbone';
import util from './util';
import http from './http';

export default Backbone.Collection.extend({
  constructor() {
    this.initialEvents();
    Backbone.Collection.prototype.constructor.apply(this, arguments);
  },

  requestingAttributes: false,

  initialEvents() {
    this.listenTo(this, 'request', () => {
      this.requestingAttributes = true;
    });

    this.listenTo(this, 'sync', () => {
      this.requestingAttributes = false;
    });
  },

  sync(method, model, options = {}) {
    util.defaults(options, {
      statusCode: http.statusCodeHandlers
    });

    return Backbone.Collection.prototype.sync.apply(this, arguments);
  },

  isLoading() {
    return this.requestingAttributes;
  }
});

