import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { asView } from 'common/util/reactUtil';
import { appBus } from '@glu/core';
import util from '@glu/core/src/util';
import MaskedInput from './MaskedInput';

const propTypes = {
    /**
     * initial value of the input
     */
    initialValue: PropTypes.string,
    /**
     * name of input, this prop is required
     */
    name: PropTypes.string.isRequired,
    /**
     * space separated list of class names to be assigned to the input's container
     */
    classList: PropTypes.string,
    /**
     * cid of input model
     */
    cid: PropTypes.string,
    /**
     * label text for the input field
     */
    fieldLabel: PropTypes.string,
    /**
     * boolean flag to indicate if view binding is required (true) or not (false - default)
     */
    dataBind: PropTypes.bool,
    /**
     * placeholder for the input
     */
    placeholder: PropTypes.string,
    /**
     * flag to indicate if the input is required, defaults to false
     */
    isRequired: PropTypes.bool,
    /**
     * if isReadOnly is true, the component will render a <span> element containing the value
     */
    isReadOnly: PropTypes.bool,
    /**
     * if isProtected is true, an <input> element is rendered and set to readOnly
     */
    isProtected: PropTypes.bool,
    /**
     * flag to override the masking configuration
     */
    dontMask: PropTypes.bool,
};

const defaultProps = {
    initialValue: '',
    classList: '',
    cid: undefined,
    fieldLabel: '',
    dataBind: false,
    placeholder: '',
    isRequired: false,
    isReadOnly: false,
    isProtected: false,
    dontMask: false,
};

const MaskedInputWrapper = (props) => {
    const [updatedValue, setUpdatedValue] = useState(undefined);
    const [disableMasking, setDisableMasking] = useState(props.dontMask);
    const [newRequiredState, setNewRequiredState] = useState(props.isRequired);
    /*
     * we need a unique updatedCount, since the external source could
     * update the value to the same value, e.g. '', and we need a prop
     * to change so that the maskedInput will re-render.
     */
    const [updatedCount, setUpdatedCount] = useState(0);
    const childProps = util.omit(props, 'dontMask');

    const updateRequired = useCallback((state) => {
        setNewRequiredState(state);
    }, []);

    useEffect(() => {
        /*
         * a lookup mapData or any external update
         * will trigger the changed event
         */
        const changedEventName = (props.cid) ? `${props.name}-${props.cid}` : props.name;
        const changedEvent = `mapData:changed:${changedEventName}`;
        const disableMaskEvent = `disableMasking:${props.name}`;
        const changeRequiredEvent = `maskedInput:changeRequired:${props.name}`;
        const updateMaskedInput = (newValue) => {
            setUpdatedValue(newValue);
            // need to use an update function b/c we are in a closure
            setUpdatedCount(newCount => newCount + 1);
        };
        const updateMaskDisabling = (disableFlag) => {
            setDisableMasking(disableFlag);
        };

        appBus.on(changedEvent, updateMaskedInput);
        appBus.on(disableMaskEvent, updateMaskDisabling);
        appBus.on(changeRequiredEvent, updateRequired);

        return (() => {
            appBus.off(changedEvent, updateMaskedInput);
            appBus.off(disableMaskEvent, updateMaskDisabling);
            appBus.off(changeRequiredEvent, updateRequired);
        });
    }, []);  // eslint-disable-line

    return (
        <MaskedInput
            {...childProps}
            updatedValue={updatedValue}
            disableMasking={disableMasking}
            updatedCount={updatedCount}
            isRequired={newRequiredState}
        />
    );
};

MaskedInputWrapper.propTypes = propTypes;
MaskedInputWrapper.defaultProps = defaultProps;

/**
 * Provide a utility function for leveraging this in a Backbone context
 * @param {Object} options - Options/props to be sent along to wrapped component
 */
export const createMaskedInputView = options => asView(MaskedInputWrapper, options);

export default MaskedInputWrapper;
