var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"checksSelectedMessage") || (depth0 != null ? lookupProperty(depth0,"checksSelectedMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"checksSelectedMessage","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":28}}}) : helper)))
    + "</p>\n\n<fieldset>\n    <legend class=\"inline-legend\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.include",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":57}}}))
    + "</legend>\n\n    <div class=\"radio\">\n        <div class=\"radio-inline\">\n            <input id=\"checkBoth\" name=\"dataOrImage\" type=\"radio\" value=\"both\" data-bind=\"model\">\n            <label for=\"checkBoth\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.checkdataimage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":65}}}))
    + "</label>\n        </div>\n        <div class=\"radio-inline\">\n            <input id=\"checkImage\" name=\"dataOrImage\" type=\"radio\" value=\"image\" data-bind=\"model\">\n            <label for=\"checkImage\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.checkimage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":62}}}))
    + "</label>\n        </div>\n        <div class=\"radio-inline\">\n            <input id=\"checkData\" name=\"dataOrImage\" type=\"radio\" value=\"data\" data-bind=\"model\">\n            <label for=\"checkData\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.checkdata",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":60}}}))
    + "</label>\n        </div>\n    </div>\n</fieldset>";
},"useData":true});