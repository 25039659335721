import userInfo from 'etc/userInfo';
import Dialog from '@glu/dialog';
import Formatter from 'system/utilities/format';
import nhUtil from 'system/utilities/accessors';
import ViewBillModal from 'app/smbPayments/views/payments/viewBill/viewBill';
import PayNow from 'app/smbPayments/views/payments/models/payNow';
import SmbPayNowDialogView from 'app/smbPayments/views/payments/payNow/payNowDialogLayout';
import ItemView from '@glu/core/src/itemView';
import listViewTmpl from './listView.hbs';

export default ItemView.extend({
    tagName: 'ul',
    className: 'list-row',
    template: listViewTmpl,

    initialize(options) {
        this.options = options;
        this.model = options.model;
    },

    ui: {
        viewBillLink: '.totalField span.icon-arrow-right',
    },

    events: {
        'click @ui.viewBillLink': 'viewBill',
        'click .payNowLay': 'payNow',
        'click .viewLay': 'viewBill',
    },

    viewBill(e) {
        e.preventDefault();

        // Display the current bill details in a modal window
        this.ViewBillLayout = new ViewBillModal({
            model: this.model,
        });

        Dialog.open(this.ViewBillLayout);
    },

    payNow(e) {
        e.preventDefault();

        /*
         * Views created with the viewFactory will have a reference to the factory itself.
         * No need to require ItemViewFactory here once refactoring is complete.
         */
        const payNow = new PayNow({
            columns: this.model.get('columns'),
        });

        const smbPayNowDialogView = new SmbPayNowDialogView({
            model: payNow,
        });

        smbPayNowDialogView.render();
    },

    templateHelpers() {
        return {
            billId: nhUtil.getFieldValue(this.model, 'BILLID'),
            totalAmount: Formatter.formatCurrency(nhUtil.getFieldValue(this.model, 'TOTALAMOUNT')),
            vendorName: nhUtil.getFieldValue(this.model, 'VENDORNAME'),
            dueDate: nhUtil.getFieldValue(this.model, 'DUEDATE'),
            dateFormat: userInfo.getDateFormat(),
        };
    },
});
