import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import util from '@glu/core/src/util';
import constants from 'app/loans/constants';
import transform from 'common/util/transform';
import ModelParameterParent from './loanModifyParent';

export default ModelParameterParent.extend({
    defaults: constants.DEFAULTS,

    initialize() {
        this.set(this.parse(this.parameters));

        this.validators = util.extend(
            {},
            constants.validators(),
            {
                SPECIAL_INSTRUCTIONS: {
                    description: locale.get('ACH.Comments'),
                    maxLength: 140,
                    matches: validatorPatterns.NOBADCHARS_PATTERN,
                },
                CUSTOMER_REFERENCE: {
                    description: locale.get('loans.customerref'),
                    maxLength: 16,
                    matches: validatorPatterns.NOBADCHARS_PATTERN,
                },
            },
        );
    },

    parse(mapDataList) {
        const parsedData = {};
        if (mapDataList?.length) {
            return mapDataList.reduce((acc, field) => ({
                ...acc,
                [field.toField.toUpperCase()]: field.value,
            }), {});
        }
        return parsedData;
    },

    sync(method, model, options) {
        const service = this.getServiceName(model, method);
        let loanData;

        if (method === 'update' || method === 'create') {
            loanData = this.convertModelAttributesToServerJSON(model);

            return this.interactWithModifyLoanAPI(service, loanData, options, model);
        }
        if (method === 'reject') {
            return this.handleReject(method, model, options).bind(this);
        }
        return this.handleDefault(method, model, options).bind(this);
    },

    /**
     * Handle response from api on successful update or create
     * @param  {object} response Response from server
     * @param  {object} options  Options object passed from action
     */
    handleSuccess(options, response) {
        options.success(response);
    },

    /**
     * Handle response from api on unsuccessful update or create
     * @param  {object} options  Options object passed from action
     * @param  {object} modelParam The current model
     * @param  {object} error Response from server
     */
    handleError(options, modelParam, error) {
        const model = modelParam;
        model.error = JSON.parse(error.responseText);
        options.error(model, {});
    },

    /**
     * Handle error if error is thrown after JSON.parse from inital error handler fails
     * @param  {object} options  Options object passed from action
     * @param  {object} error Response from error handler
     */
    handleGenericError(options, error) {
        options.error(error);
    },

    /**
     * Handle loan payment rejection function
     * @param  {string} method type of action to perform
     * @param  {object} model the current model
     * @param  {object} optionsParam object passed from action
     */
    handleReject(method, model, optionsParam) {
        const options = optionsParam;
        options.rejectReasonItem = {
            name: 'APPROVER_REJECTION_REASON',
            value: model.get('APPROVER_REJECTION_REASON'),
        };
        return this.processAction(method, model, options);
    },

    /**
     * Handle loan payment default action
     * @param  {string} method type of action to perform
     * @param  {object} model the current model
     * @param  {object} options object passed from action
     */
    handleDefault(method, model, options) {
        this.processAction(method, model, options);
    },

    /**
     * General function to hit apis and retrieve data
     * @param  {string} service API endpoint
     * @param  {data} data data for api endpoint
     * @return {promise} Data returned from API
     */
    interactWithAPI(service, data) {
        return new Promise((resolve, reject) => {
            http.post(service, data).then(resolve, reject);
        });
    },

    /**
     * function to hit apis and retrieve data on modify loans post
     * @param {string} service API endpoint
     * @param {Object} data data for api endpoint
     * @param {Object} options
     * @param {Model} model
     * @return {promise} Data returned from API
     */
    interactWithModifyLoanAPI(service, data, options, model) {
        return new Promise((resolve, reject) => {
            http.post(service, data, (response) => {
                this.handleSuccess(options, response);
            }, (response) => {
                this.handleError(options, model, response);
            }).then(resolve, reject);
        });
    },

    unapprove(options) {
        return this.sync('unapprove', this, options);
    },

    approve(options) {
        return this.sync('approve', this, options);
    },

    reject(options) {
        return this.sync('reject', this, options);
    },

    delete(options) {
        return this.sync('delete', this, options);
    },

    processAction(method, model, options) {
        let data;
        const pType = this.get('paymentType');
        const pTypeUrl = pType.toUpperCase().indexOf('PAY') > -1 ? 'payment/loanPayment/' : 'payment/loanDraw/';
        const url = options.templateMode ? 'template/listView/corp/' : pTypeUrl + method;

        if (options.templateMode) {
            data = {
                items: [{
                    item: transform.hashToPairs({
                        FUNCTIONCODE: 'TMPLSET',
                        TYPECODE: 'TRANSFER',
                        PRODUCTCODE: 'RTGS',
                        TNUM: model.get('TNUM'),
                        ENTRYMETHOD: model.get('ENTRYMETHOD'),
                        UPDATECOUNT__: model.get('UPDATECOUNT__'),
                    }),
                }],
            };
        } else {
            data = {
                item: transform.hashToPairs({
                    FUNCTIONCODE: model.get('FUNCTION'),
                    TNUM: model.get('TNUM'),
                    ENTRYMETHOD: model.get('ENTRYMETHOD'),
                    UPDATECOUNT__: model.get('UPDATECOUNT__'),
                    _saveWithWarning: model.get('_saveWithWarning'),
                }),
            };
            if (method === 'reject') {
                data.item.push(options.rejectReasonItem);
            }
        }

        return this.interactWithAPI(services.generateUrl(url), data)
            .then(response => this.handleProcessActionSuccess(model, response, options))
            .catch(error => this.handleProcessActionError(error));
    },

    /**
     * Handle successful response from api on reject or delete
     * @param  {object} model    The current model
     * @param  {object} response Response from server
     * @param  {object} options  Options object passed from action
     */
    handleProcessActionSuccess(model, response, options) {
        options.success(model, response, options);
    },

    /**
     * Handle unsuccessful response from api on reject or delete
     * @param  {object} model    The current model
     * @param  {object} error Response from server
     * @param  {object} options  Options object passed from action
     */
    handleProcessActionError(model, error, options) {
        options.error({
            errorCode: error.status,
            errorMessage: error.statusText,
            message: error.responseText,
        });
    },

    getServiceName(model, method) {
        const type = model.get('paymentType');
        let url;

        if (type === 'LOANDRAW') {
            url = method === 'update' ? services.loanDrawUpdate : services.loanDrawAdd;
        }
        if (type === 'LOANPAY') {
            url = method === 'update' ? services.loanPaymentUpdate : services.loanPaymentAdd;
        }
        return url;
    },

    convertModelAttributesToServerJSON(model) {
        let jsonData = [];
        const paymentType = model.get('paymentType');
        const attributes = model.toJSON();

        // convert CREDIT_AMOUNT to DEBIT_AMOUNT for loandraw
        if (paymentType === 'LOANDRAW') {
            attributes.DEBIT_AMOUNT = model.get('CREDIT_AMOUNT');
        }

        jsonData = transform.hashToPairs(attributes, 'name', 'value');

        return {
            item: jsonData,
        };
    },

    getBusinessDays(data) {
        if (this.get('BENE_ACCOUNTENTITLEMENT') && this.get('ACCOUNTFILTER')) {
            http.post(services.sbDates, data, (response) => {
                this.trigger('retrieved', response);
            }, () => {
            // error
            });
        }
    },

    parameters: ModelParameterParent.prototype.createParameters(),
});
