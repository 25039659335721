import { init as initApm } from '@elastic/apm-rum';

const APMInit = ({
    environment,
    serverUrl,
    serviceName = 'dgb-ui-js',
}) => {
    if (!environment || !serverUrl) {
        return;
    }

    initApm({
        environment,
        serverUrl,
        serviceName,
    });
};

const APMRumAgent = {
    serverConfigParamsReady(serverConfigParams) {
        const config = {
            environment: serverConfigParams.get('ElasticAPMEnvironment'),
            serverUrl: serverConfigParams.get('ElasticAPMServerUrl'),
            serviceName: serverConfigParams.get('ElasticAPMServiceName'),
        };

        // Make sure all config items have a value, this is the requirement to consider "enabled"
        const hasAll = Object.values(config).every(i => !!i);

        if (hasAll) {
            APMInit(config);
        }
    },
};

export default APMRumAgent;
