var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"prev-page-breadcrumb\">\n            <a class=\"return-to-workspace\" data-hook=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":137},"end":{"line":4,"column":174}}}))
    + "</span></a>\n        </div>\n        <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":44}}}) : helper)))
    + "</h1>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"page-header-wrapper\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"showBackButton") || (depth0 != null ? lookupProperty(depth0,"showBackButton") : depth0)) != null ? helper : alias2),(options={"name":"showBackButton","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":7,"column":23}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"showBackButton")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h3></h3>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"lbxTotalsRegion\" data-region=\"lbxTotalsRegion\"></div>\n        <div class=\"btn-wrapper\">\n            "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":55}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n\n";
},"useData":true});