var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"section section-container\">\n    <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"transition.pagetitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":41}}}))
    + "</h3>\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-12\">\n            <div class=\"text-group field-container-lg field-container\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"transition.paymentstatus.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":66}}}))
    + "</label>\n                <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"STATUS_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"STATUS_DESCRIPTION") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"STATUS_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":46}}}) : helper)))
    + "</strong>\n            </div>\n            <div class=\"required text-group field-container-lg field-container\">\n                <label for=\"paymentTransitions\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"transition.transitionList.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":48},"end":{"line":11,"column":92}}}))
    + "</label>\n                <div data-region=\"paymentTransitionsDropdown\"></div>\n            </div>\n            <div class=\"text-group field-container-lg field-container\" data-hook=\"bankCommentContainer\">\n                <label for=\"TRANSITION_REASON\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"transition.transitionreason.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":93}}}))
    + "</label>\n                <div>\n                    <input type=\"text\" size=\"80\" name=\"TRANSITION_REASON\" id=\"TRANSITION_REASON\" data-bind=\"model\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"TRANSITION_REASON\"></span>\n                </div>\n            </div>\n                <div class=\"text-group field-container-lg field-container\" data-hook=\"bankConfirmedContainer\">\n                    <label for=\"CONFIRMATION_NUMBER\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"transition.confirmationnumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":53},"end":{"line":22,"column":95}}}))
    + "</label>\n                    <div>\n                        <input type=\"text\" size=\"80\" name=\"CONFIRMATION_NUMBER\" id=\"CONFIRMATION_NUMBER\" data-bind=\"model\"/>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"CONFIRMATION_NUMBER\"></span>\n                    </div>\n                </div>\n                <div class=\"required text-group field-container-lg field-container\" data-hook=\"bankRejectedContainer\">\n                    <label for=\"REJECT_REASON\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"transition.rejectreason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":47},"end":{"line":29,"column":83}}}))
    + "</label>\n                    <div>\n                        <input type=\"text\" size=\"80\" name=\"REJECT_REASON\" id=\"REJECT_REASON\" data-bind=\"model\"/>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"REJECT_REASON\"></span>\n                    </div>\n                </div>\n        </div>\n    </div>\n    <div class=\"details-region\" data-region=\"detailsRegion\"></div>\n</fieldset>\n\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" data-action=\"save\" class=\"btn btn-primary\" name=\"save\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.save.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":107},"end":{"line":43,"column":143}}}))
    + "</span></button>\n            <button type=\"button\" data-action=\"cancel\" class=\"btn btn-secondary\" name=\"cancel\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":113},"end":{"line":44,"column":151}}}))
    + "</span></button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});