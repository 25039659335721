import Layout from '@glu/core/src/layout';
import GridApi from 'common/dynamicPages/api/grid';
import ELearningGroupModel from 'app/administration/models/elearningModel';
import ListView from 'common/dynamicPages/views/workflow/list';
import viewGroupTabTmpl from './viewGroupTab.hbs';

export default Layout.extend({
    template: viewGroupTabTmpl,

    events: {
        'click [data-hook="insert-button"]': 'addElearningGroup',
    },

    initialize() {
        const context = {
            serviceName: '/cxp/elearning',
            actionMode: 'SELECT',
            productCode: 'CXP',
            functionCode: 'MAINT',
            typeCode: 'ELEARN',
            gridId: 30001,
        };
        ListView.prototype.setListViewConfig.call(this, context);
        const gridOptions = {
            context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            selector: 'none',
            lvc: this.lvc,
        };

        this.gridView = GridApi.createServiceGridView(gridOptions);

        this.listenTo(
            this.gridView,
            {
                rowAction: this.handleRowAction,
            },
            this,
        );
        ListView.prototype.setupGridAvailableListener.call(this);
    },

    onShow() {
        this.gridRegion.show(this.gridView);
    },

    addElearningGroup() {
        this.navigateTo('/CXP/addElearningGroup');
    },

    handleRowAction(data) {
        const self = this;

        const groupId = data.model.get('GROUPID');

        if (data.action === 'select') {
            this.navigateTo(`/CXP/viewElearningGroup/${groupId}`);
        } else if (data.action === 'modify') {
            this.navigateTo(`/CXP/updateElearningGroup/${groupId}`);
        } else if (data.action === 'delete') {
            const tempModel = new ELearningGroupModel();
            tempModel.set('groupId', groupId);
            tempModel.destroy({
                success() {
                    self.gridView.refreshGridData();
                },

                error() {
                    self.gridView.refreshGridData();
                },
            });
        }
    },
});
