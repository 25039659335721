import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import errHandler from 'system/errHandler';
import FlexDropdown from '@glu/flex-dropdown';
import http from '@glu/core/src/http';
import InquiryApi from 'system/webseries/api/inquiry';
import InquiryQueryResults from 'system/webseries/collections/inquiryQueryResults';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import services from 'services';
import tmpl from './addHolidayDialog.hbs';

export default Layout.extend({
    template: tmpl,

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    ui: {
        dateField: '[name="holidayDate"]',
    },

    regions: {
        bankCodeRegion: '.bank-code-region',
        holidayTypeRegion: '.holiday-type-region',
    },

    initialize() {
        this.model = new Model();
        this.model.set('holidayDate', moment().format(userInfo.getDateFormat()));
        this.holidayTypeDropDown = new FlexDropdown({
            data: [{
                id: '2',
                name: locale.get('holy.BankHoliday'),
            }, {
                id: '3',
                name: locale.get('holy.CurrencyHoliday'),
            }, {
                id: '1',
                name: locale.get('holy.CountryHoliday'),
            }],
            disableMultiButton: true,
            label: locale.get('holy.HolidayType'),
            preSelectedIds: ['2'],
        });

        this.listenTo(this.holidayTypeDropDown, 'selectionChanged', this.handleHolidayTypeChange);

        this.bankCodesCollection = [];
        this.countryCodesCollection = [];
        this.currencyCodesCollection = [];

        this.initCodes();
    },

    initCodes() {
        // get country codes
        const countryCodesPromise = new Promise(((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: InquiryApi.COUNTRY_CODES_LIST_INQUIRY_ID,
                typeCode: InquiryApi.ADDRESS_BOOK_TYPE_CODE,
                productCode: InquiryApi.ADMIN_PRODUCT_CODE,
                functionCode: InquiryApi.FUNCTION_CODE_MAINT,
                customFilters: [{
                    filterName: 'Depends',
                    filterParam: [
                        'label', 'Description',
                    ],
                }],
            }).fetch({
                success: resolve,
                error: reject,
            });
        }));

        // get bank codes
        const bankCodesPromise = new Promise(((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: 50000,
                typeCode: 'BKHOL_TM',
                productCode: '_ADMIN',
                functionCode: 'MAINT',
            }).fetch({
                success: resolve,
                error: reject,
            });
        }));

        // get currency codes
        const currencyCodesPromise = new Promise(((resolve, reject) => {
            new InquiryQueryResults({
                inquiryId: InquiryApi.CURRENCY_CODES_LIST_INQUIRY_ID,
                typeCode: InquiryApi.ADDRESS_BOOK_TYPE_CODE,
                productCode: InquiryApi.ADMIN_PRODUCT_CODE,
                functionCode: InquiryApi.FUNCTION_CODE_MAINT,
                customFilters: [{
                    filterName: 'Depends',
                    filterParam: [
                        'paymentType', 'BDACHCC',
                    ],
                }, {
                    filterName: 'Depends',
                    filterParam: [
                        'clearingSystem', 'NACHA',
                    ],
                }, {
                    filterName: 'Depends',
                    filterParam: [
                        'value', 'Code',
                    ],
                }],
            }).fetch({
                success: resolve,
                error: reject,
            });
        }));

        Promise.all([
            countryCodesPromise,
            bankCodesPromise,
            currencyCodesPromise,
        ]).then(([
            countryCodes,
            bankCodes,
            currencyCodes,
        ]) => {
            const dataToDropdownOptions = data => data.reduce((result, dataSet) => {
                result.push({ id: dataSet.id, name: dataSet.label });
                return result;
            }, []);

            this.countryCodesCollection = dataToDropdownOptions(countryCodes.toJSON());
            this.bankCodesCollection = dataToDropdownOptions(bankCodes.toJSON());
            this.currencyCodesCollection = dataToDropdownOptions(currencyCodes.toJSON());
            this.handleHolidayTypeChange();
            this.handleHolidayCodeChange();
        }).then(null, errHandler);
    },

    onRender() {
        this.holidayTypeRegion.show(this.holidayTypeDropDown);

        const dateOptions = {
            allowWeekends: true,
            format: userInfo.getDateFormat(),
            showCalendarIcon: true,
            showDropdowns: true,
            startDate: this.model.get('holidayDate'),
        };

        this.ui.dateField.nhDatePicker(dateOptions);
    },

    handleHolidayTypeChange() {
        let options = {};
        const holidayType = this.holidayTypeDropDown.value('id')[0];
        this.model.set('holidayType', holidayType);

        switch (holidayType) {
        case '3':
            options = {
                data: this.currencyCodesCollection,
                preSelectFirstItem: true,
                disableMultiButton: true,
                label: locale.get('holy.CurrencyCode'),
            };
            break;
        case '1':
            options = {
                data: this.countryCodesCollection,
                preSelectFirstItem: true,
                disableMultiButton: true,
                label: locale.get('holy.CountryCode'),
            };
            break;
        default:
            options = {
                data: this.bankCodesCollection,
                preSelectFirstItem: true,
                disableMultiButton: true,
                label: locale.get('holy.BankCode'),
            };
            break;
        }

        this.codeDropDown = new FlexDropdown(options);
        this.model.set('holidayCode', this.codeDropDown.value('id')[0]);

        this.listenTo(this.codeDropDown, 'selectionChanged', this.handleHolidayCodeChange);

        this.bankCodeRegion.reset();
        this.bankCodeRegion.show(this.codeDropDown);
    },

    handleHolidayCodeChange() {
        this.model.set('holidayCode', this.codeDropDown.value('id')[0]);
    },

    toServerJSON() {
        return {
            item: [{
                name: '_mode',
                value: 'INSERT',
            }, {
                name: '_actionMode',
                value: 'INSERT',
            }, {
                name: 'HOLIDAYCODE',
                value: this.model.get('holidayCode'),
            }, {
                name: 'HOLIDAYDATE',
                value: this.model.get('holidayDate'),
            }, {
                name: 'HOLIDAYTYPE',
                value: this.model.get('holidayType'),
            }, {
                name: '_productCode',
                value: '_ADMIN',
            }, {
                name: '_typeCode',
                value: 'BENEADBK',
            }, {
                name: 'type',
                value: 'BENEADBK',
            }, {
                name: '_functionCode',
                value: 'MAINT',
            }, {
                name: 'functionCode',
                value: 'MAINT',
            }, {
                name: 'FUNCTION',
                value: 'MAINT',
            }],

        };
    },

    save() {
        this.model.validate();
        http.post(services.generateUrl('/bankHoliday/add/'), this.toServerJSON(), () => {
            this.options.success();
            dialog.close();
        }, (response) => {
            const responseResults = response.responseJSON.confirms.confirmResults;

            if (responseResults.length && responseResults[0].messages.length) {
                this.alertRegion.show(alert.negative(responseResults[0].messages[0], {
                    canDismiss: true,
                }));
            } else {
                this.alertRegion.show(alert.negative(locale.get('error.systemError'), {
                    canDismiss: true,
                }));
            }
        });
    },
});
