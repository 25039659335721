var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-inline\">\n    <div data-region=\"maskedInputRegion\" class=\"search-input-wrapper maskedInputFilter\" /> \n    <div class=\"maskedInputFilterButtons\">\n        <button data-hook=\"getSubmitButton\" type=\"submit\" name=\"submit\" class=\"btn btn-sm btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":103},"end":{"line":4,"column":121}}}))
    + "</button>\n        <button data-hook=\"getClearButton\" type=\"button\" name=\"clear\" class=\"btn btm-sm btn-tertiary btn-clear-filter\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":119},"end":{"line":5,"column":137}}}))
    + "</button>\n    </div>\n</form>";
},"useData":true});