var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"checkbox\">\n        <div class=\"global-select-all-perms\">\n            <label class=\"checkbox-inline\" for=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":69},"end":{"line":4,"column":76}}}) : helper)))
    + "\">\n                <input type=\"checkbox\" id=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":71}}}) : helper)))
    + "\" data-hook=\"selectAllPermissions\">\n                "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.selectall",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":45}}}))
    + "\n            </label>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.ABS",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":28}}}))
    + "</h2>\n    <div class=\"checkbox\">\n        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.abs.selectBene",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":39}}}))
    + "\n\n        <div class=\"checkbox-inline\">\n            <input id=\"paymentsChk-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":18,"column":35},"end":{"line":18,"column":42}}}) : helper)))
    + "\" type=\"checkbox\" data-bind=\"model\" data-hook=\"paymentsChk\" name=\"globalSettings.BENEPAY.value\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":138},"end":{"line":18,"column":169}}})) != null ? stack1 : "")
    + ">\n            <label for=\"paymentsChk-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":19,"column":36},"end":{"line":19,"column":43}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payments.rfp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":45},"end":{"line":19,"column":85}}}))
    + "</label>\n        </div>\n        <div class=\"checkbox-inline\">\n            <input id=\"templatesChk-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":22,"column":36},"end":{"line":22,"column":43}}}) : helper)))
    + "\" type=\"checkbox\" data-bind=\"model\" data-hook=\"templatesChk\" name=\"globalSettings.BENETEMP.value\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":141},"end":{"line":22,"column":172}}})) != null ? stack1 : "")
    + ">\n            <label for=\"templatesChk-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":23,"column":37},"end":{"line":23,"column":44}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.templates.rfp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":46},"end":{"line":23,"column":87}}}))
    + "</label>\n        </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div data-hook=\"inboundRequestsRegion\" class=\"assigned-permissions-region\"></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <div data-hook=\"requestForPaymentsRegion\" class=\"assigned-permissions-region\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div data-hook=\"rtpMessagesRegion\" class=\"rtp-messages-region\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRFP") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInbound") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOutbound") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":33,"column":7}}})) != null ? stack1 : "")
    + "<div data-hook=\"restrictedTemplatesRegion\" class=\"restricted-templates-region\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMessages") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":0},"end":{"line":37,"column":7}}})) != null ? stack1 : "");
},"useData":true});