import CompositeView from '@glu/core/src/compositeView';
import EditableTableRow from 'app/bos_common/editableTable/view/editableTableRow';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import editableTableTmpl from 'app/bos_common/editableTable/view/editableTable.hbs';
/**
 * options:
 * collection - the items representing the rows in the table.
 * readOnly - true if the rows are not editable.  Defaults to false.
 * columnHeaders - an array of strings with text for the column headers.
 * ie. columnHeaders = [{header: 'myFirstColumn'}]
 * itemView - view object that defines the row collection. Default itemView
 * only defines the delete column when table is !readOnly.
 * addAnotherText - (optional) Add Another button text
 */
const EditableTable = CompositeView.extend({
    initialize(options) {
        this.readOnly = false;
        this.addAnotherText = locale.get('common.add.another');
        if (options) {
            this.readOnly = options.readOnly;
            if (!util.isUndefined(options.columnHeaders)) {
                this.columnHeaders = options.columnHeaders;
            }
            if (!util.isUndefined(options.itemView)) {
                this.itemView = options.itemView;
            }
            if (!util.isUndefined(options.addAnotherText)) {
                this.addAnotherText = options.addAnotherText;
            }
        }
    },

    className: 'editable-table',
    template: editableTableTmpl,
    itemView: EditableTableRow,
    itemViewContainer: 'tbody',

    itemViewOptions() {
        return {
            readOnly: this.readOnly,
        };
    },

    templateHelpers() {
        return {
            readOnly: this.readOnly,
            columnHeaders: this.columnHeaders,
            addAnotherText: this.addAnotherText,
        };
    },

    events: {
        'click button[data-hook="add-line-item"]': 'addLineItem',
        sortstart: 'setWidths',
        sortstop: 'unsetWidths',
        sortupdate: 'handleSort',
    },

    addLineItem() {
        if (this.collection.length < 25) {
            this.collection.add({});
        }
    },

    setWidths(e, ui) {
        const $siblingCells = this.$(ui.item).siblings().not('.ui-sortable-placeholder').eq(0)
            .find('td');
        if ($siblingCells.length) {
            util.each(this.$(ui.item).find('td'), function (el, i) {
                this.$(el).width($siblingCells.eq(i).width());
            }, this);
        }
    },

    unsetWidths(e, ui) {
        this.$(ui.item).find('td').css({
            width: 'auto',
        });
    },

    onRender() {
        this.$('tbody').sortable({
            axis: 'y',
            forcePlaceholderSize: true,
            containment: this.$el,
        });

        this.handleSort();
    },

    handleSort() {
        util.each(this.$('tr[data-model]'), function (el, i) {
            const $el = this.$(el);
            this.collection.get($el.data('model')).set({
                order: i,
            });
        }, this);
    },
});

export default EditableTable;
