import util from '@glu/core/src/util';
import $ from 'jquery';
import services from 'services';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import ExportDataView from 'app/reports/views/exportBTRData/exportBTRData';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import ListView from 'common/dynamicPages/views/workflow/list';
import { ContainerArrowDownIcon } from '@glu/icons-react';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import helpers from 'components/AsyncReporting/helpers';
import systemConfig from 'system/configuration';
import errorHandlers from 'system/error/handlers';
import template from './exportBTRListView.hbs';

const BtrExportListView = ListView.extend({
    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: 'baiExport/downloadExport',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'RPT_DOWNLOAD',
            selector: 'none',
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('REPORTING/viewExportDetail');
        return Promise.resolve();
    },

    gridDownload(options) {
        if (!systemConfig.isAdmin() && (options.model.get('PRODUCTCODE') === '_UTIL')) {
            return this.handleDownloadFile(options);
        }
        if (this.$('#downloadForm').length === 0) {
            const f = `<form id="downloadForm" action="${services.generateUrl('/export/download ')}" method="get" target="_blank"><input id="exportId" type="hidden" name="exportId"></form>`;
            this.$el.append(f);
        }
        this.$('#downloadForm #exportId').val(options.model.get('EXPORTID'));
        this.$('#downloadForm').submit();
        return Promise.resolve();
    },

    /**
     * @method handleDownloadFile
     * @description Handles downloading the file when grid cell dropdown option is clicked. It
     * supports different formats PDF, TXT, and CSV
     * @param options
     */
    handleDownloadFile(options) {
        const { action, model, format } = options;
        return helpers.handleAsyncReport(
            {
                exportId: model.get('EXPORTID'),
                disposition: 'export',
                format: action === 'download' ? model.get('OUTPUTCONTENT') : format,
            },
            () => Promise.resolve(),
            () => errorHandlers.loading.call(this),
        );
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
        this.setHasLoadedRequiredData(true);
        if (!mobileUtil.isMobileGridEnabled()) {
            this.listenForGridErrors();
            this.render();
        }
        return result.actions;
    },

    export() {
        this.exportDataView = new ExportDataView();
        dialog.open(this.exportDataView);
        return new Promise((resolve, reject) => {
            this.listenTo(this.exportDataView, 'doExport', () => {
                // Clear any alert errors
                this.alertRegion.reset();
                this.gridView.refreshGridData();
                resolve();
            });
            this.listenTo(this.exportDataView, 'doExportFailed', () => {
                const alertView = alert.danger(locale.get('payment.export.error.message'));
                this.alertRegion.show(alertView);
                $('html, body').animate({
                    scrollTop: 0,
                }, 100);
                reject();
            });
        });
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(this);

        return util.extend(
            obj,
            {
                isEntitled() {
                    return self.entitlements.SELECT;
                },
            },
        );
    },
});

let list = BtrExportListView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'button.export',
                entitlement: 'SELECT',
                handlerMethodName: 'export',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'DOWNLOAD',
    options: {},
    useMobileCard: true,
    view: list,
    mobileCardProps: {
        icon: ContainerArrowDownIcon,
    },
});

const exportedList = list;

export default exportedList;
