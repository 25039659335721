import TableHeader from 'no-override!@glu/grid/src/header/tableHeader';

export default TableHeader.extend({
    initReorder() {
        const draggableOptions = {
        /*
         * items to skip
         * note: have to remove span from "cancel", otherwise drag event doesn't work for icons
         */
            cancel: 'a:not(.draggable), .grid-row-selector, .grid-column-resize-marker',
            axis: 'x',
            helper: 'clone',
            revert: true,
            cursor: 'move',
            placeholder: 'grid-column-order-placeholder',
            // items to handle
            handle: '.title-value',
            start: this.handleDragStart.bind(this),
            update: this.handleStop.bind(this),
        };

        // need to destroy previous reorder
        this.destroyReorder();

        this.order = null;

        this.orderActiveClassName = 'grid-column-order-active';

        this.$el.sortable(draggableOptions).disableSelection();
    },

    onRender() {
        // prevent the cells from shrinking when the headers are rendered on column switching
        const $table = this.$el.closest('table');
        if ($table.length && !this.options.disableDynamicColumnWidths) {
            $table.css({
                width: 'auto',
            });
        }
        TableHeader.prototype.onRender.call(this);
    },
});
