export default function (
    form,
    initialState,
) {
    const formState = form.formView.state;
    const viewDisclosure = form.field('VIEWPREVIEWDISCLOSURE');

    if (initialState && formState === 'view') {
        viewDisclosure.shouldBeHidden();
    }
}
