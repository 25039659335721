import Layout from '@glu/core/src/layout';
import constants from 'app/administration/constants';
import CollectionView from '@glu/core/src/collectionView';

export default Layout.extend({

    getGroupView() {
        throw new Error('failed to retrieve groupView file');
    },

    initialize(opts) {
        const groupCollection = this.model.get('simpleTypes');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isEntitled()));
        }

        this.groupsCollectionView = new CollectionView({
            collection: groupCollection,
            itemView: this.getGroupView(),
        });

        let userGroup = this.model.userModel && this.model.userModel.get('USERGROUP');
        if (!userGroup) {
            userGroup = this.model.userGroupModel && this.model.userGroupModel.get('parentUserGroup');
        }

        this.groupsCollectionView.itemViewOptions = {
            userGroup,
            mode: opts.mode,
            roleInfo: this.model.roleInfo,
            entity: opts.entity,
        };
    },

    onRender() {
        this.groupRegion.show(this.groupsCollectionView);
    },
});
