import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import achModelView from './achModelView.hbs';

export default Layout.extend({
    template: achModelView,
    modalClass: 'modal-xlg',

    initialize(options) {
        this.action = 'SELECT';
        this.model = new Model({
            options,
        });
        this.dialogTitle = locale.get('risk.pigid.view');
        this.viewState = options.state || null;
        this.dialogButtons = [{
            text: locale.get('button.close'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
    },
    cancel() {
        dialog.close();
    },
    templateHelpers() {
        const self = this;
        return {
            PIGID: self.options?.model?.PIGID,
            USERGROUP: self.options?.model?.USERGROUP,
            STATUS: self.options?.model?.STATUS,
            PIGIDNICKNAME: self.options?.model?.PIGIDNICKNAME,
        };
    },
});
