/**
 * View for user grid
 */
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import mobileGridDefaultBulkActions from 'common/dynamicPages/api/mobileGridDefaultBulkActions';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import userInfo from 'etc/userInfo';
import dialog from '@glu/dialog';
import SmbUserMaintenanceModel from 'app/administration/models/user/smbUser';
import AdminUtil from 'app/administration/common/util';
import UserGroupCollection from 'app/administration/collection/userGroups';
import alert from '@glu/alerts';
import dynamicPagesConstants from 'common/dynamicPages/api/constants';
import lvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import PendingChangesCellView from 'app/administration/views/userMaintenance2/pendingChanges/pendingChangesCellView';
import SmbUserPendingChanges from 'app/administration/views/userMaintenance/smb/viewUserCentricPendingChanges';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import smbConstants from 'app/smb/constants';
import getAvailableActions from 'app/smb/common/actionUtil';
import AddUserModalLayout from './smb/userCentricModal';
import ViewUserModalLayout from './smb/viewUserCentric';
import UserCompanyModal from './userCompanyModal';
import UserGroupLookup from './userGroupLookup';
import actionUtils from './actionUtils';

const UserManagementList = ListView.extend({
    initiatedModalWorkflow: false,
    regions: {
        alertRegion: '.alert-region',
    },

    initialize(options) {
        this.addUserGroupFilter(options);

        const superOptions = {
            menuCategory: 'ADMIN',
            serviceName: '/userMaintenance/user',
            serviceFunc: null,
            businessType: null,
            context: 'USER_MAINT',
            returnRoute: mobileUtil.isMobileGridEnabled()
                ? 'ADMINSTRATION/viewUsers?widget=USER_MAINT' : 'ADMINSTRATION/listUsers',
        };

        this.isSMB = userInfo.isSmbUser();
        this.isAdmin = (window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal');

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        /*
         * when coming from a user group admin page or from the user menu item,
         * clear out grid state.  dont clear if from drill down or for client app
         */
        if (this.isAdmin && (this.usergroup
                    || (options.stack && options.stack.options.fromMenu))) {
            this.lvc.reset();
        }
        /*
         * if there are additional search fields for server side filters,
         * then store in listView config (grid state)
         */
        if (options.additionalSearchFields) {
            this.lvc.set(lvcConstants.ADDITIONALSEARCH, options.additionalSearchFields);
        }
        this.listenTo(this.appBus, 'stackView:pop', this.displayAlertMessage);
    },

    /*
     * TODO refactor so that this returns a new options object
     * rather than update the input options
     */
    addUserGroupFilter(optionsParam) {
        const options = optionsParam;
        if (options.returnRoute && options.returnRoute.indexOf('ADMINSTRATION/viewUsers/') !== -1) {
            this.usergroup = options.returnRoute.substring(options.returnRoute.lastIndexOf('/') + 1, options.returnRoute.length);
            options.returnRoute = 'ADMINSTRATION/viewUsers';
            options.additionalSearchFields = [{
                fieldName: 'USERGROUP',
                operator: 'EQ',
                fieldValue: [this.usergroup],
                dataType: 'text',
            }];
        }
    },

    displayAlertMessage() {
        let alertResponse;
        if (store.has('addUserCentricSuccessMessage')) {
            alertResponse = store.get('addUserCentricSuccessMessage');
            this.renderMessage(alertResponse.action, alertResponse.response);
            store.unset('addUserCentricSuccessMessage');
        }
    },

    onRender(...args) {
        // add cellview for pending changes
        const [newArgs] = args;
        newArgs.options = {
            ...args[0].options,
            cellViews: {
                PENDINGCHANGES: PendingChangesCellView,
            },
        };
        ListView.prototype.onRender.call(this, newArgs);

        if (this.hasLoadedRequiredData()) {
            this.listenToOnce(this.gridView, 'gridapi:loaded', this.displayAlertMessage());
            this.appBus.on('user:viewPendingChanges', this.gridRowPendingChanges.bind(this));
        } else {
            this.updateRefreshTimestamp();
        }
    },

    buildExportModel(format) {
        // Set the inquiryId for export and print in the client user maintenance grid
        ListView.prototype.buildExportModel.call(this, format);
        if (this.contextModel.attributes.contextDef.typeCode === 'CLUSR_TM') {
            this.exportModel.inquiryId = 9030;
        }
        this.exportModel.columns = this.fixExportPrintColumns(this.exportModel.columns);
        return this.exportModel;
    },

    fixExportPrintColumns(columns) {
        return columns.filter(column => (column !== 'PENDINGCHANGES'));
    },

    insert() {
        if (this.initiatedModalWorkflow) {
            return Promise.resolve();
        }
        this.initiatedModalWorkflow = true;
        let userGroups;

        if (this.isSMB) {
            return new Promise((resolve) => {
                if (!store.has('userCompany')) {
                    userGroups = new UserGroupCollection({
                        isAdmin: this.isAdmin,
                    });

                    userGroups.fetch({
                        success: (records) => {
                            const company = records.get(userInfo.get('group'));
                            store.set('userCompany', company);
                            this.smbInsert({
                                company,
                                onClose: resolve,
                            });
                        },
                        error: () => {
                            this.onModalClose();
                        },
                    });
                } else {
                    this.smbInsert({
                        company: store.get('userCompany'),
                        onClose: resolve,
                    });
                }
            });
        }

        // If UCE Or role based
        const userGroup = store.get('userCompany') || null;
        if (userGroup) {
            store.unset('userCompany');
        }
        return new Promise((resolve) => {
            userGroups = new UserGroupCollection({
                isAdmin: this.isAdmin,
                userGroup,
            });

            userGroups.fetch({
                success: (records) => {
                    /*
                     * If the collection only returns 1 item, don't show the modal
                     * & just set the usergroup based on that one item
                     */
                    if (userGroups.length === 1) {
                        this.continueAddUser(records.first());
                    } else {
                        /*
                         * Open modal for the admin user to select
                         * the company first before inserting
                         */
                        this.companyModal = new UserCompanyModal({
                            state: 'modal',
                            collection: userGroups,
                            pageTitle: 'User Maintenance',
                            listViewLabel: 'User Maintenance Center',
                            returnRoute: 'ADMINSTRATION/viewUsers',
                        });
                        dialog.open(this.companyModal);
                        this.companyModal.once('close', this.onModalClose.bind(this));
                        // only the modal view needs to listen to the success message
                        this.listenTo(
                            this.companyModal,
                            'selectUserCompany:success',
                            this.continueAddUser,
                        );
                    }
                    resolve();
                },
                error: () => {
                    this.onModalClose();
                },
            });
        });
    },

    /**
     * If an smb company, get the model and render the modal.
     * user to copy them to the new user.
     * @param  {Model} company  -the id of the company
     * @param  {string} [copyFromUser] - optional userId to copy from.
     * @param {function} onClose
     */
    smbInsert({ company, copyFromUser, onClose }) {
        this.initiatedModalWorkflow = true;
        const insertModel = new SmbUserMaintenanceModel();

        // retrieve modal using read service
        insertModel.fetch({
            userGroup: company ? company.get('id') : null,

            success: (model, userCentricModel, options) => {
                this.insertUser = new AddUserModalLayout({
                    state: 'modal',
                    mode: 'insert',
                    model: insertModel,
                    pageTitle: locale.get('administration.user.maint'),
                    listViewLabel: locale.get('administration.user.maint.center'),
                    returnRoute: 'ADMINSTRATION/viewUsers',
                    userGroup: options.userGroup,
                    company,
                    copyFromUser,
                    entitlements: this.entitlements,
                    onClose,
                });
                dialog.open(this.insertUser);
                this.insertUser.once('close', this.onModalClose.bind(this));

                // only the modal view needs to listen to the success message
                this.listenTo(this.insertUser, 'userAction:success', (response, action) => {
                    this.userActionSuccess(response, action);
                });
            },
            error: () => {
                this.onModalClose();
            },
        });
    },

    /*
     * Temporary fix for NH-27635 - reject is inherited entitlement
     * and should never be an option
     * @param {string}
     */
    hasEntitlement(type) {
        return (this.entitlements[type] && type !== 'REJECT');
    },

    /*
     * NH-93751 - Reverting the changes made to NH-84404 and
     * implementing the functionality on the server side
     */

    /**
     * Navigates to the correct add user route passing the selected company.
     * An optional param copyFromUser is used when Copy From User is selected
     * from the context menu.  It is passed to insert screen to copy those
     * permissions for the new user
     *
     * @param {Model} selectedCompany UserGroup Model
     * @param {string} [copyFromUser] param of userId for copy from user
     * @param {function} [onClose] param of userId for copy from user
     */
    continueAddUser(selectedCompany, copyFromUser, onClose) {
        store.set('userCompany', selectedCompany);
        if (selectedCompany.get('isSMB')) {
            this.smbInsert({
                company: selectedCompany,
                copyFromUser,
                onClose,
            });
        } else {
            let isUce = true;
            const userPermissionSettings = selectedCompany.get('mapDataList').find(md => md.toField === 'USERPERMISSIONSETTINGS');
            if (userPermissionSettings) {
                isUce = userPermissionSettings.value === constants.UCE_BASED;
            }
            actionUtils.addUser(isUce, selectedCompany.get('id'), selectedCompany.get('parentUserGroup'), copyFromUser);
        }
    },

    addUserSuccess() {
        if (store.has('addUserCentricSuccessMessage')) {
            this.alertRegion.show(alert.success(store.get('addUserCentricSuccessMessage')));
            this.gridView.refreshGridData();
            store.unset('addUserCentricSuccessMessage');
        }

        dialog.close();
    },

    userActionSuccess(response, action) {
        this.gridView.refreshGridData();
        this.renderMessage(action, response);
    },

    switchToModify() {
        const { options } = this.viewUser;
        options.mode = constants.MODES.MODIFY;
        this.gridRowModify(options);
    },

    gridRowPendingChanges(opts) {
        const options = {
            mode: constants.MODES.VIEW,
            action: constants.ACTIONS.SELECT,
            model: opts.model,
            pendingChanges: true,
        };
        this.gridRowModify(options);
    },

    gridRowSelect(optionsParam) {
        const options = optionsParam;
        options.mode = constants.MODES.VIEW;
        return this.gridRowModify(options);
    },

    gridRowModify(options) {
        const gridModel = options.model;
        store.set(`${this.contextKey}-actionModel`, gridModel);
        this.getUsergroupModel(gridModel.get('USERGROUP')).then((userGroups) => {
            if (this.isSMB || userGroups.get(gridModel.get('USERGROUP')).get('isSMB')) {
                return this.modifyUserSMB(options, gridModel, userGroups);
            }
            return this.modifyUserUceOrRole(options, gridModel);
        });
    },

    /**
     * @description view/modify an smb user
     * @param { object } options - options
     * @param { model } gridModel - gridModel of selected row
     * @param { collection } userGroups - usergroup collection
     */
    modifyUserSMB(options, gridModel, userGroups) {
        if (this.initiatedModalWorkflow) {
            return Promise.resolve();
        }
        this.initiatedModalWorkflow = true;
        return new Promise((resolve) => {
            // retrieve modal using read service
            const model = new SmbUserMaintenanceModel();
            const actionButtons = getAvailableActions(options)
                .filter(buttons => buttons.action !== smbConstants.INSERT_ACTION_MODE);
            model.fetch({
                userId: gridModel.get('USERID'),
                userGroup: gridModel.get('USERGROUP'),

                success: () => {
                    if (options.pendingChanges) {
                        this.viewUser = new SmbUserPendingChanges({
                            mode: constants.MODES.VIEW,
                            model,
                            pageTitle: locale.get('administration.user.maint'),
                            listViewLabel: locale.get('administration.user.maint.center'),
                            userGroup: userInfo.userGroup,
                            userId: userInfo.userId,
                            buttons: util.filter(actionButtons, buttonObj => (buttonObj.action === 'APPROVE')),
                            returnRoute: constants.ROUTES.USER_LISTVIEW,
                            context: options.model.context,
                            entitlements: this.entitlements,
                            onClose: resolve,
                        });
                        dialog.open(this.viewUser);
                        this.viewUser.once('close', this.onModalClose.bind(this));
                        this.listenTo(this.viewUser, 'userAction:success', (response, action) => {
                            this.userActionSuccess(response, action);
                        });
                    } else if (options.mode === constants.MODES.VIEW) {
                        this.viewUser = new ViewUserModalLayout({
                            state: 'modal',
                            mode: constants.MODES.VIEW,
                            model,
                            pageTitle: locale.get('administration.user.maint'),
                            listViewLabel: locale.get('administration.user.maint.center'),
                            /*
                             * NH-105191 filter the reset password button.
                             * This should be disabled in the view smb screen.
                             */
                            buttons: util.filter(actionButtons, buttonObj => (buttonObj.action !== 'RESETPW')),
                            returnRoute: constants.ROUTES.USER_LISTVIEW,
                            company: userGroups.at(0),
                            context: options.model.context,
                            entitlements: this.entitlements,
                            onClose: resolve,
                        });
                        dialog.open(this.viewUser);
                        this.viewUser.once('close', this.onModalClose.bind(this));
                        this.listenTo(this.viewUser, 'userAction:success', (response, action) => {
                            this.userActionSuccess(response, action);
                        });
                        this.listenTo(this.viewUser, 'userAction:switchToModify', this.switchToModify);

                        this.listenTo(this.viewUser, 'userAction:simulateUser', () => {
                            this.simulateUser(this.viewUser.options, false);
                        });

                        this.listenTo(this.viewUser, 'userAction:copyFromUser', () => {
                            this.copyFromUser(this.viewUser.options.model);
                        });

                        this.listenTo(this.viewUser, 'userAction:updateUserProfile', () => {
                            this.updateUserProfile(this.viewUser.options.model);
                        });
                    } else {
                        this.modifyUser = new AddUserModalLayout({
                            state: 'modal',
                            mode: constants.MODES.MODIFY,
                            model,
                            pageTitle: locale.get('administration.user.maint'),
                            listViewLabel: locale.get('administration.user.maint.center'),
                            returnRoute: constants.ROUTES.USER_LISTVIEW,
                            company: userGroups.models[0],
                            context: options.model.context,
                            entitlements: this.entitlements,
                            onClose: resolve,
                        });
                        dialog.open(this.modifyUser);
                        this.modifyUser.once('close', this.onModalClose.bind(this));
                        // only the modal view needs to listen to the success message
                        this.listenTo(this.modifyUser, 'userAction:success', (response, action) => {
                            this.userActionSuccess(response, action);
                        });
                    }
                },
                error: () => {
                    this.onModalClose();
                },
            });
        });
    },

    gridRowCustomAction(options) {
        const action = options.action.toUpperCase();
        const gridModel = options.model;

        return new Promise((resolve) => {
            if (action === 'SIMULATE') {
                this.simulateUser(options, true);
            } else if (action === 'COPYFROMUSER') {
                this.copyFromUser(gridModel, resolve);
            } else if (action === 'UPDATEPROFILERESTRICTED') {
                this.updateUserProfile(gridModel);
                resolve();
            }
        });
    },

    simulateUser(options, fromGrid) {
        const model = fromGrid ? options.model : store.get(`${this.contextKey}-actionModel`);
        actionUtils.simulateUser(model, (response) => {
            if (fromGrid) {
                this.gridView.refreshGridData();
                this.renderMessage(options.action.toUpperCase());
            } else {
                AdminUtil.errorAlert(this.alertRegion, response);
            }
        });
    },

    /**
     * Goes to insert of user, seeding the new user with 'from user' permissions
     *
     * @param {model} modelParam of grid row that was select for copy from user
     * @param {function} [onClose]
     */
    copyFromUser(modelParam, onClose) {
        const self = this;
        const model = modelParam || store.get(`${this.contextKey}-actionModel`);

        const lookup = new UserGroupLookup({
            isAdmin: this.isAdmin,
        });

        lookup.setSearch(model.get('USERGROUP'));
        lookup.setSearchOperator('EQ');
        lookup.send().then((result) => {
            const userGroups = new UserGroupCollection({
                isAdmin: self.isAdmin,
            });

            const recs = new Collection(userGroups.parse(result.data));
            const company = recs.at(0);

            // For client only, since isSMB is not returned from company view, use this.isSMB.
            if (!self.isAdmin) {
                company.set('isSMB', self.isSMB);
            }
            // Send userId to indicate copy from user.
            self.continueAddUser(company, model.get('USERID'), onClose);
        });
    },

    updateUserProfile(gridModelParam) {
        const gridModel = gridModelParam || store.get(`${this.contextKey}-actionModel`);
        store.set(`${this.contextKey}-actionModel`, gridModel);
        store.set('uce-load', {
            userGroup: gridModel.get('USERGROUP'),
            parentUserGroup: gridModel.get('PARENTUSERGROUP'),
            userId: gridModel.get('USERID'),
            buttons: gridModel.buttons,
            entitlement: this.entitlements,
            isUce: true,
        });

        this.navigateTo(constants.ROUTES.UPDATE_USER_SETTING);
    },

    gridRowResetPassword(options) {
        return new Promise((resolve) => {
            actionUtils.resetPassword(this, options.model, () => {
                this.gridView.refreshGridData();
                this.alertRegion.show(alert.success(locale.get('panel.ResetPwd')));
                resolve();
            });
        });
    },

    /**
     * @description Retrieve UserGroup to determine role or uce based
     * @param { string } userGroup - client usergroup
     * @return a promise that can be checked upon completion
     */
    getUsergroupModel(userGroup) {
        return new Promise((resolve) => {
            const userGroups = new UserGroupCollection({
                isAdmin: this.isAdmin,
            });
            const lookup = new UserGroupLookup({
                isAdmin: this.isAdmin,
            });

            lookup.setSearch(userGroup);
            lookup.setSearchOperator('EQ');
            lookup.send().then((result) => {
                const recs = new Collection();
                recs.reset(userGroups.parse(result.data));
                resolve(recs);
            });
        });
    },

    /**
     * @description navigate to view/modify a role based or UCE user
     * @param { object } options - options
     * @param { model } gridModel - gridModel of selected row
     */
    modifyUserUceOrRole(options, gridModel) {
        return new Promise((resolve) => {
            const isUce = this.isAdmin ? gridModel.get('USERPERMISSIONSETTINGS') === constants.UCE_BASED : userInfo.isUce();
            store.set('uce-load', {
                userGroup: gridModel.get('USERGROUP'),
                parentUserGroup: gridModel.get('PARENTUSERGROUP'),
                userId: gridModel.get('USERID'),
                buttons: gridModel.buttons,
                entitlement: this.entitlements,
                hasSimulate: gridModel && util.contains(util.pluck(gridModel.buttons, 'action'), dynamicPagesConstants.ACTION_SIMULATE),
                isUce,
            });

            const modifyURL = isUce
                ? constants.ROUTES.MODIFY_USER : constants.ROUTES.MODIFY_ROLE_USER;
            let viewURL = isUce
                ? constants.ROUTES.VIEW_USER : constants.ROUTES.VIEW_ROLE_USER;
            if (options.pendingChanges) {
                viewURL = constants.ROUTES.PENDING_CHANGES;
            }

            this.navigateTo(options.mode === constants.MODES.VIEW ? viewURL : modifyURL);
            resolve();
        });
    },

    isAlertMessageWarning(confirmResponse) {
        try {
            const confirmsMessages = confirmResponse.confirms.confirmResults[0].messages;
            const { message } = confirmResponse;

            return ((confirmsMessages
                    && confirmsMessages.indexOf(locale.get('multitssrvc.vip.activation.error')) > -1)
                    || message.indexOf(locale.get('multitssrvc.vip.activation.error')) > -1);
        } catch (e) {
            return false;
        }
    },

    /**
     * @method showAlertMessage
     * @param {string} message
     * @param {string} type - glu alert types associated to an alert creation
     * method (success, warning, danger, etc)
     * @param {object} props - object containing extra properties for glu alert
     * construction
     * Shows an alert message in the ListView. The status passed in is associated
     * to the type of
     * alert that is shown.
     * Overrides the base method
     */
    showAlertMessage(message, type, props) {
        let localType = type;
        let localProps = props;
        localType = alert[localType] ? localType : 'success';
        localProps = localProps || {
            canDismiss: true,
        };

        if (this.isAlertMessageWarning(localProps.confirmResponse)) {
            localType = 'warning';
        }

        // create alert message using associated alert status type
        this.alertView = alert[localType](message, localProps);

        this.alertRegion.show(this.alertView);
    },

    hasBulkActionEntitlements() {
        // Override base class function for CGBO user
        return ListView.prototype.hasBulkActionEntitlements.call(this)
                && !userInfo.isCannotGrantBeyondOwnUser();
    },

    onModalClose() {
        this.initiatedModalWorkflow = false;
    },
});

let list = UserManagementList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        apiMessageContext: 'addUserCentricSuccessMessage',
        insertActions: [
            {
                label: 'uce.addUser',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: mobileGridDefaultBulkActions,
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'USER_MAINT',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
