import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import { withStyles } from '@glu/theming';
import { Button, PRIMARY, TERTIARY } from '@glu/buttons-react';
import Layout from '@glu/layout-react';
import FormFieldStaticContainer from 'components/FormFieldStaticContainer/FormFieldStaticContainer';
import systemConfig from '../../system/configuration';
import useLocale from '../../hooks/useLocale';
import FormFieldStatic from '../FormFieldStatic/FormFieldStatic';
import ActionButtonContainer from '../ActionButtonContainer/ActionButtonContainer';
import styles from './ScheduledExportDetailForm.styles';
import BalanceAndTransaction from '../../app/scheduledExports/views/balanceAndTransaction';
import BankReport from '../../app/scheduledExports/views/bankReport';
import LegacyReport from '../../app/scheduledExports/views/legacyReport';
import LegacyReportModel from '../../app/scheduledExports/LegacyReportModel';
import ScheduledExportModel from '../../app/scheduledExports/scheduledExportModel';
import BankReportModel from '../../app/scheduledExports/BankReportModel';

const propTypes = {
    addSchedule: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        userGroup: PropTypes.arrayOf(PropTypes.shape({})),
        userId: PropTypes.arrayOf(PropTypes.shape({})),
        name: PropTypes.string,
        transmissionProfile: PropTypes.arrayOf(PropTypes.shape({})),
        reportType: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    cancel: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    reportTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
    })).isRequired,
    transmissionProfiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
    })).isRequired,
    isSaving: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
};

const getSelectedName = (selection) => {
    const [first] = selection;
    return first ? first.name : selection;
};

const getFlexNameById = (getLocaleString, selection = [], data = []) => {
    const [selected] = selection;
    const id = selected ? selected.id || selected : '';
    const dataItem = data.find(item => item.id === id);
    if (dataItem) {
        return dataItem.name;
    }
    return `${id} - ${getLocaleString('GIR.No.Longer.Available')}`;
};

const getExportDetailView = (reportType = [], mode, setModel, formData) => {
    const [selectedReportType] = reportType;
    let DetailView;
    let model;
    switch (selectedReportType.id) {
    case 'BALANCE_AND_TRANSACTION':
        DetailView = BalanceAndTransaction;
        model = new ScheduledExportModel(formData);
        break;
    case 'LEGACY_REPORTS':
        DetailView = LegacyReport;
        model = new LegacyReportModel(formData);
        break;
    default:
        DetailView = BankReport;
        model = new BankReportModel(formData);
    }
    setModel(model);
    return new DetailView({
        mode,
        model,
    });
};

const ScheduledExportDetailForm = ({
    addSchedule,
    formData,
    cancel,
    classes,
    reportTypes,
    transmissionProfiles,
    isSaving,
    mode,
}) => {
    const { getLocaleString } = useLocale();
    const {
        userGroup,
        userId,
        name,
        transmissionProfile,
        reportType,
    } = formData;
    const [model, setModel] = useState(new ScheduledExportModel());
    const [detailView, setDetailView] = useState();
    useEffect(() => {
        const view = getExportDetailView(
            reportType,
            mode,
            setModel,
            formData,
        );
        setDetailView(view);
    }, []);// eslint-disable-line
    useEffect(() => {
        let attrs = {
            NAME: name,
        };
        if (model.get('FILENAME') === '') {
            attrs = {
                ...attrs,
                FILENAME: name,
            };
        }
        model.set(attrs);
    }, [model, name, userId, userGroup]);

    // TDOD When exportDetailViews are no longer backbone views, remove and use glu-validation-react
    const prepareModelData = () => {
        detailView.prepareModelData().then(modelData => addSchedule(modelData))
            .catch(e => window.console.log(e));
    };

    return (
        <div className="section section-container">
            <FormFieldStaticContainer>
                {systemConfig.isAdmin() ? (
                    <Layout container>
                        <FormFieldStatic
                            col={3}
                            label={getLocaleString('GIR.User.Group')}
                            name="userGroup"
                            value={getSelectedName(userGroup)}
                        />
                        <FormFieldStatic
                            col={3}
                            label={getLocaleString('GIR.User')}
                            name="userId"
                            value={getSelectedName(userId)}
                        />
                    </Layout>
                ) : null}
                <Layout container>
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('GIR.Schedule.Name')}
                        name="name"
                        value={name}
                    />
                    {transmissionProfile.length ? (
                        <FormFieldStatic
                            col={3}
                            label={getLocaleString('GIR.Transmission.Profile')}
                            name="transmissionProfile"
                            value={getFlexNameById(
                                getLocaleString,
                                transmissionProfile,
                                transmissionProfiles,
                            )}
                        />
                    ) : null}
                </Layout>
                <Layout container>
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('GIR.Report.Type')}
                        name="reportType"
                        value={getFlexNameById(getLocaleString, reportType, reportTypes)}
                    />
                </Layout>
            </FormFieldStaticContainer>
            {detailView ? (
                <div className={classes.detailView}>
                    <ViewWrapper view={detailView} />
                </div>
            ) : null}
            <ActionButtonContainer>
                <Button
                    className="btn btn-primary"
                    variant={PRIMARY}
                    text={getLocaleString('button.save')}
                    onClick={prepareModelData}
                    aria-busy={isSaving}
                />
                <Button
                    variant={TERTIARY}
                    text={getLocaleString('button.cancel')}
                    onClick={cancel}
                />
            </ActionButtonContainer>
        </div>
    );
};

ScheduledExportDetailForm.propTypes = propTypes;

export default withStyles(styles)(ScheduledExportDetailForm);
