var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n        <span class=\"name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.vendor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":58}}}))
    + ":</span>\n        <span class=\"value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"VENDOR_NAME") || (depth0 != null ? lookupProperty(depth0,"VENDOR_NAME") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"VENDOR_NAME","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":43}}}) : helper)))
    + "</span>\n</div>\n<div>\n        <span class=\"name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.bill.number.symbol",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":27},"end":{"line":6,"column":70}}}))
    + ":</span>\n        <span class=\"value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"BILL_NUMBER") || (depth0 != null ? lookupProperty(depth0,"BILL_NUMBER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"BILL_NUMBER","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":43}}}) : helper)))
    + "</span>\n</div>\n<div>\n        <span class=\"name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbBillMgmt.issue.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":27},"end":{"line":10,"column":62}}}))
    + ":</span>\n        <span class=\"value\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"ISSUE_DATE") || (depth0 != null ? lookupProperty(depth0,"ISSUE_DATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"ISSUE_DATE","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":42}}}) : helper)))
    + "</span>\n</div>\n";
},"useData":true});