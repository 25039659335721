import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import template from './confirmationDialog.hbs';

export default Layout.extend({
    template,
    modalClass: 'RDC-confirmation-dialog',

    initialize(options) {
        this.response = options.response;
        this.dialogTitle = locale.get('rdc.Confirm.Modal.Title');
        this.dialogButtons = [
            {
                text: locale.get('rdc.Deposit.Check'),
                className: 'btn btn-primary',
                callback: 'confirm',
            },
            {
                text: locale.get('rdc.Modify.Deposit'),
                className: 'btn btn-secondary',
                callback: 'modify',
            },
        ];
    },

    /**
     * Call the onConfirm function and close the dialog
     * Make sure the callback is invoked before closing
     * the dialog so the view can handle the close event
     * in the proper order.
     */
    confirm() {
        this.options.onConfirm(this.response.rdcSessionData);
        dialog.close();
    },
    /**
     * Call the onModify function and close the dialog
     * Make sure the callback is invoked before closing
     * the dialog so the view can handle the close event
     * in the proper order.
     */
    modify() {
        this.options.onModify(this.response.rdcSessionData);
        dialog.close();
    },

    templateHelpers() {
        return {
            message: this.response.message,
        };
    },
});
