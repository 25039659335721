import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import ScheduledExportModel from 'app/scheduledExports/scheduledExportModel';
import filterApi from 'common/dynamicPages/api/filters';
import { postData } from 'common/util/services';
import constants from 'common/dynamicPages/api/constants';
import printExportUtil from 'common/util/printExportUtil';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import userInfo from 'etc/userInfo';
import services from 'services';
import store from 'system/utilities/cache';
import format from 'system/utilities/format';
import configuration from 'system/configuration';
import operatorUtil from 'system/gridOverride/utilities/operators';
import readScheduledExportTmpl from './readScheduledExport.hbs';
import scheduleUtil from '../util';

export default Layout.extend(util.extend(
    {},
    printExportUtil,
    {
        template: readScheduledExportTmpl,
        ui: {
            $modifyBtn: '[data-hook="getModifyBtn"]',
            $cancelBtn: '[data-hook="getCancelBtn"]',
            $printBtn: '[data-hook="getPrintBtn"]',
        },

        events: {
            'click @ui.$modifyBtn': 'modify',
            'click @ui.$cancelBtn': 'cancel',
            'click @ui.$printBtn': 'print',
        },

        initialize() {
            this.model = new ScheduledExportModel();

            this.userPermissions = {
                MODIFY: false,
                INSERT: false,
                DELETE: false,
                SELECT: false,
            };

            this.getModelData();
        },

        /**
         * Get all the data required for the model to show in the view
         */
        getModelData() {
            const filterData = this.getFilterData();
            const modelData = this.model.getModel();
            const modifyAction = this.getEntitledActions();

            return Promise.all([filterData, modelData, modifyAction])
                .then(this.setupModel.bind(this));
        },

        /**
         * Merge info returned from server and set model attributes
         * @param  {array} result data returned from server
         */
        setupModel(result) {
            const [filterObj, , modifyAction] = result;
            const actionTypes = modifyAction.actionModes;
            // merge searchFields and modelData together
            let searchFields = this.mapSearchFields(this.model, filterObj);
            searchFields = this.formatAmountFilter(searchFields, this.model);
            this.model.set('SEARCHFIELDS', searchFields);

            this.userPermissions = this.setUserPermissions(actionTypes);

            scheduleUtil.getAccountGroupData(
                this.model.get('USERGROUP'),
                this.model.get('USERID'),
                this.model.getSearchFieldValue('ACCOUNTFILTER2'),
            )
                .then(this.getAccountGroupNameArray)
                .then(this.updateAccountGroupNames.bind(this))
                .then(this.render.bind(this))
                .catch(this.render.bind(this));
        },

        /**
         * Based on standard response array of objects with fieldName and fieldValue
         * properties,
         * return just the AccountGroupName values
         * @param {Object} response
         * @returns {Array} Array of account group names
         */
        getAccountGroupNameArray(response) {
            return util.map(response.rows, (row) => {
                const accountGroupName = util.findWhere(row.columns, {
                    fieldName: 'AccountGroupName',
                });
                return accountGroupName ? util.unescape(accountGroupName.fieldValue)
                    : undefined;
            });
        },

        /**
         * Update the AMOUNT search field with all of the relevant read only data
         * @param {Array} searchFields
         * @param {Model} model
         */
        formatAmountFilter(searchFields, model) {
            const newFields = searchFields.map((field) => {
                if (field.fieldName === 'AMOUNT') {
                    const currency = userInfo.getCurrencySymbol();
                    const amount = format.formatCurrency(model.get('AMOUNT'));
                    const amount2 = model.get('AMOUNT2');
                    let newAmount = `${operatorUtil.convertToHumanReadable(model.get('AMOUNT-equality'))} ${currency}${amount}`;
                    if (amount2) {
                        const formattedAmount2 = format.formatCurrency(amount2);
                        newAmount = `${newAmount} - ${currency}${formattedAmount2}`;
                    }
                    return {
                        ...field,
                        fieldValue: [newAmount],
                    };
                }
                return field;
            });
            return newFields;
        },

        /**
         * Update the searchFields value of the model with account group names
         * @param {Array} nameArray - array of account group names
         */
        updateAccountGroupNames(nameArray) {
            const searchFields = this.model.get('SEARCHFIELDS');
            const newFields = this.updateAccountNameSearchField(searchFields, nameArray);
            this.model.set('SEARCHFIELDS', newFields);
        },

        /**
         * Update the ACOUNTFILTER2 (ie account group) field with the acount
         * group names
         * @param {Array} searchFields - search field array from server response
         * @param {Array} nameArray - array of account group names
         */
        updateAccountNameSearchField(searchFields, nameArray) {
            return searchFields.map((fieldParam) => {
                // only when searchField is ACCOUNTFILTER2, update the fieldValue
                if (fieldParam.fieldName === 'ACCOUNTFILTER2') {
                    return {
                        ...fieldParam,
                        fieldValue: nameArray,
                    };
                }
                return fieldParam;
            });
        },

        /**
         * Set user permissions
         * @param  {array} actionTypes
         * @return {object} key/value pairs of permissions
         */
        setUserPermissions(actionTypes) {
            return util.reduce(actionTypes, (acc, value) => {
                const accumulator = acc;
                accumulator[value] = true;
                return accumulator;
            }, {});
        },

        /**
         * loop through searchFields in modal data and get fieldName
         * @param  {object} model  Model object
         * @param  {object} filterObj Filter attributes
         * @return {object} Filter object with fieldLabel added
         */
        mapSearchFields(model, filterObj) {
            const searchFields = model.get('SEARCHFIELDS');
            if (!searchFields) {
                return [];
            }
            return searchFields.reduce((accum, searchField) => {
                if (['USERGROUP', 'USERID'].includes(searchField.fieldName)) {
                    return accum;
                }
                // find object in filterData that matches
                const mappedField = util.reduce(filterObj.fieldInfoList, (acc, value) => {
                    const accumulator = acc;
                    if (value.name === searchField.fieldName) {
                        accumulator.fieldName = searchField.fieldName;
                        accumulator.fieldValue = searchField.fieldValue;
                        accumulator.fieldLabel = value.fieldLabel;
                    }
                    return accumulator;
                }, {});
                return [
                    ...accum,
                    mappedField,
                ];
            }, []);
        },

        /**
         * Get data for filters (we only need the fieldLabel but must retrieve it all)
         * @return {Promise} [description]
         */
        getFilterData() {
            const filterParams = {
                filterId: '40122',

                typeInfo: {
                    productCode: 'GIR',
                    functionCode: 'MAINT',
                    typeCode: 'SCHEXP',
                },
            };

            return new Promise((resolve, reject) => {
                filterApi.getFilterData(filterParams, 'model-/advanceFilter-GIR-MAINT').then(resolve, reject);
            });
        },

        /**
         * Get locale key for OUTPUTHEADER model attribute value
         * @param  {string} output OUTPUTHEADER model attribute value
         * @return {string} locale key
         */
        getOutputContent(output) {
            switch (output) {
            case 'GIRTRANS':
                return 'GIR.ExportType.TransOnly';
            case 'GIRBALANDTRANS':
                return 'GIR.ExportType.BalAndTran';
            case 'GIRBAL':
                return 'GIR.ExportType.BalOnly';
            default:
                return '';
            }
        },

        /**
         * Run modify action by setting current mode and navigating to add/update page
         */
        modify() {
            this.navigateTo(this.options.modifyRoute);
        },

        /**
         * Run cancel action by navigating back to grid page
         */
        cancel() {
            const returnRoute = store.get('ScheduledExportsWidget:returnRoute');
            this.navigateTo(returnRoute || this.options.returnRoute);
        },

        /**
         * Show a custom PrintVieModal with the options specific to
         * Scheduled exports
         */
        print() {
            const options = this.getPrintOptions(this.model);
            dialog.custom(new PrintViewModal(options));
        },

        /**
         * Get the print options for the this view, from the model
         * @param {Model} model - model attached to the view
         * @returns {Object} - print options
         */
        getPrintOptions(model) {
            return {
                exportModel: {
                    expData: 'transaction',
                    actionData: {
                        productCode: 'GIR',
                    },
                    detailReportId: configuration.isAdmin() ? 60006 : 60001,
                    searchFields: [{
                        fieldName: 'ID',
                        operator: 'IN',
                        fieldValue: [
                            model.get('IDENTIFIER'),
                        ],
                        dataType: 'string',
                    }],
                },
            };
        },

        /**
         * Build print model for scheduled export
         */
        buildPrintModel() {
            return {
                outputFormat: 'PDF',
                pageType: 'LETTER',
                expData: 'transaction',
                detailReportId: 0,
                inquiryId: constants.INQUIRY_ID_49070,

                actionData: {
                    productCode: 'GIR',
                    functionCode: 'MAINT',
                    typeCode: 'SCHEXP',
                },

                searchFields: [{
                    fieldName: 'ID',
                    operator: 'IN',
                    fieldValue: [this.model.get('IDENTIFIER')],
                    dataType: 'number',
                }],
            };
        },

        /**
         * Hit service to determine actions user is entitled to
         * @return {Promise} entitled actions object returned from server
         */
        getEntitledActions() {
            return postData(services.generateUrl('export/scheduledExport/getEntitledActions'));
        },

        templateHelpers() {
            return {
                timeFrequencies: this.model.get('TIMEFREQUENCIES'),
                searchFields: this.model.get('SEARCHFIELDS'),
                showExportCriteriaHeading: this.model.get('SEARCHFIELDS'),
                hasModifyEntitlement: this.userPermissions.MODIFY,
                hasInsertEntitlement: this.userPermissions.INSERT,
                hasDeleteEntitlement: this.userPermissions.DELETE,
                hasSelectEntitlement: this.userPermissions.SELECT,
                outputContentLocale: this.getOutputContent(this.model.get('OUTPUTCONTENT')),
            };
        },
    },
));
