import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default Model.extend({
    idAttribute: 'DISPLAYFIELD',

    getAccountType() {
        if (!this.has('EntryClass')) {
            // generic until set
            return 'Account';
        }

        return (this.get('EntryClass') === '') ? 'Wires' : 'ACH';
    },

    parse(res) {
        res.DISPLAYFIELD = `${res.DISPLAYFIELD} - ${locale.get(res.BATCHTYPEDESC)}`;
        return res;
    },
});
