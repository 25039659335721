import $ from 'jquery';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';

import checkFreeModelListenerUtil from 'common/util/checkFreeModelListenerUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default function (form, initialState) {
    const { formView } = form;
    const { model } = formView;
    const password = form.field('REGUSERPASS');
    const confirmPassword = form.field('REGUSERPASSX');
    const email = form.field('BUSEMAIL');
    const confirmEmail = form.field('BUSEMAILX');
    const termsAndCondts = locale.get('SSOOUT.TersAndConditions');
    const termsAndConURL = serverConfigParams.get('CheckFreeTermsURL');
    const $saveButton = formView.$('#AGREETERM').parents('.section-container').find('.btn-primary');

    if (initialState) {
        // hide the fields not needed(not defined in visiblefields but they still showing)
        form.field('SPONSORID').shouldBeHidden();
        form.field('EXTERNALSUBSCRIBERID').shouldBeHidden();
        form.field('CLIENTAPPTEXT').shouldBeHidden();
        form.field('CLIENTAPPVERSION').shouldBeHidden();
        form.field('USER_ID').shouldBeHidden();
        form.field('DRIVERLIC').shouldBeHidden();
        form.field('LICSTATE').shouldBeHidden();
        $('#BILLINGCLASS').closest('fieldset').addClass('hidden');
        // default the values for the adaptor
        model.set('EXTERNALSUBSCRIBERID', userInfo.get('group') + userInfo.get('id'));
        model.set('SPONSORID', 'adaptor config');
        model.set('CLIENTAPPTEXT', 'adaptor config');
        model.set('CLIENTAPPVERSION', 'adaptor config');
        model.set('COUNTRYCODE', 'USA');
        model.set('BILLINGCLASS', 'BASIC');
        form.field('POSTALCODE').shouldBeVisibleWhen(false)
            .shouldBeRequiredWhen(false);
        form.field('PROVINCENAME').shouldBeVisibleWhen(false)
            .shouldBeRequiredWhen(false);

        checkFreeModelListenerUtil.setModelListeners(model, form, 'BUSCITY', 'BUSSTATE', 'BUSZIPCODE');

        confirmPassword.shouldMatchField(password);
        confirmEmail.shouldMatchField(email);

        /*
         * Add the terms and condition hyper link to open a configured url in a new
         * window. and disable the submit button until the I agree check boxes are checked.
         */
        if (!util.isNullOrUndefined(termsAndConURL)) {
            formView.$('#AGREETERM').next('.checkbox-inline').append(` <a target='_blank' href='${termsAndConURL}' class='btn btn-tertiary'>${termsAndCondts}</a>`);
        }

        if ($saveButton.length) {
            $saveButton.prop('disabled', true);

            if (formView.context.typeCode === 'CFSMBFM') {
                model.on('change:AGREETERM change:AGREEVERIFY change:LEVEL1AGREE', () => {
                    if (model.get('AGREETERM') === '1' && model.get('AGREEVERIFY') === '1' && model.get('LEVEL1AGREE') === '1') {
                        $saveButton.prop('disabled', false);
                    } else {
                        $saveButton.prop('disabled', true);
                    }
                });
            } else {
                model.on('change:AGREETERM change:AGREEVERIFY', () => {
                    if (model.get('AGREETERM') === '1' && model.get('AGREEVERIFY') === '1') {
                        $saveButton.prop('disabled', false);
                    } else {
                        $saveButton.prop('disabled', true);
                    }
                });
            }
        }
    }
}
