var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-inline\">\n        <div class=\"search-input-wrapper date-picker-container\"></div>\n        <button type=\"submit\" name=\"submit\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.datepicker.apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":68},"end":{"line":3,"column":104}}}))
    + "</button>\n        <button type=\"button\" name=\"clear\" class=\"btn btn-tertiary btn-clear-filter hidden\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":92},"end":{"line":4,"column":110}}}))
    + "</button>\n</form>\n";
},"useData":true});