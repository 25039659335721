import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import AddressBook from 'app/administration/views/fileImportHistory/bab/addressBook';
import Collection from '@glu/core/src/collection';
import ContactModel from 'app/bab/model/contactModel';
import transform from 'common/util/transform';
import ContactView from 'app/bab/views/viewContact';
import viewContactTmpl from './viewContact.hbs';

export default ContactView.extend({
    template: viewContactTmpl,

    ui: {
        alertRegion: '.alert-region',
    },

    regions: {
        alertRegion: '.alert-region',
    },

    initialize(options) {
        this.options = util.extend({}, options);
        this.contactModel = this.options.data
            ? new ContactModel(this.options.data) : new ContactModel();
        this.mode = this.options.mode || 'create';
        this.isModal = (options.state === 'modal');
        this.addrAPI = new AddressBook();
        this.model = new Model({});
        this.accountsCollection = new Collection([]);

        this.dialogTitle = locale.get('administration.contact.view');

        this.dialogButtons = [{
            text: locale.get('administration.cancel.btn'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    loadViewRequirements() {
        const self = this;
        return this.addrAPI.read(this.contactModel).then((result) => {
            let records = util.map(
                result.detailRecords,
                record => self.forceArray(transform.pairsToHash(record)),
            );

            records = util.reduce(records, (memoParam, record) => {
                const memo = memoParam;
                // skip a draft record and avoid from listing as part of the account.
                if (record.PAYMENTTYPE[0].search(/draft/gi) > -1) {
                    return memo;
                }
                const account = record.BENE_ACCOUNTNUMBER[0];

                if (memo[account]) {
                    memo[account] = self.mergeStructure(memo[account], record);
                } else {
                    memo[account] = record;
                }

                return memo;
            }, {});
            return records;
        }).then((records) => {
            self.setHasLoadedRequiredData(true);

            const tmp = [];

            Object.keys(records || {}).forEach((i) => {
                tmp.push(records[i]);
            });

            self.accountsCollection.reset(tmp);
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadViewRequirements();
        }
    },

    cancel() {
        if (this.isModal) {
            dialog.close();
        } else {
            window.history.back();
        }
    },

    templateHelpers() {
        const self = this;

        return {
            contact: self.contactModel.toJSON(),
            accounts: this.accountsCollection.toJSON(),

            contactType() {
                let type = self.contactModel.get('CONTACTTYPE');

                switch (type) {
                case '1':
                    type = locale.get('administration.contact.business');
                    break;
                case '2':
                    type = locale.get('administration.contact.individual');
                    break;
                case '3':
                    type = locale.get('administration.contact.employee');
                    break;
                case '4':
                    type = `${locale.get('administration.contact.individual')}, ${locale.get('administration.contact.employee')}`;
                    break;
                default:
                    type = locale.get('administration.contact.na');
                    break;
                }

                return type;
            },

            contactAddr() {
                let addr = '';

                if (self.contactModel.get('BENE_ADDRESS_1')) {
                    addr = `${self.contactModel.get('BENE_ADDRESS_1')}<br/>`;
                }

                if (self.contactModel.get('BENE_ADDRESS_2') && self.contactModel.get('BENE_ADDRESS_2') !== '') {
                    addr += `${self.contactModel.get('BENE_ADDRESS_2')}<br/>`;
                }

                if (self.contactModel.get('BENE_ADDRESS_3')) {
                    addr += `${self.contactModel.get('BENE_ADDRESS_3')}, `;
                }
                if (self.contactModel.get('BENE_STATE')) {
                    addr += `${self.contactModel.get('BENE_STATE')}, `;
                }

                if (self.contactModel.get('BENE_PROVINCE') && self.contactModel.get('BENE_PROVINCE') !== '') {
                    addr += `${self.contactModel.get('BENE_PROVINCE')}, `;
                }

                if (self.contactModel.get('BENE_POSTALCODE')) {
                    addr += `${self.contactModel.get('BENE_POSTALCODE')}<br/>`;
                }
                addr += self.contactModel.get('BENE_COUNTRY');

                return addr;
            },

            pageTitle() {
                return self.contactModel.get('BENE_NAME');
            },

            hasContactPersonInfo: () => util.chain(this.model.keys()).filter(key =>
                // Index 0 means "starts with"
                key.indexOf('BENE_') === 0).some(key => this.model.get(key) !== '').value(),

            hasDeleteEntitlement() {
                return store.get('setup:contact:delete:entitlement');
            },

            hasModifyEntitlement() {
                return store.get('setup:contact:modify:entitlement');
            },

            hasApproveEntitlement() {
                return store.get('setup:contact:approve:entitlement');
            },

            isModal: self.isModal,
        };
    },
});
