import 'common/api/templateCollection';
import 'mobile/common/interfaces/accounts';
import 'mobile/common/interfaces/quickTransfer';
import 'mobile/common/interfaces/stopPayments';
import 'mobile/common/interfaces/payments';
import 'mobile/common/interfaces/checkInquiry';
import 'mobile/common/interfaces/balanceAndTransaction';
import 'mobile/remoteDepositCapture/interfaces/miSnapInterface';
import 'common/api/queriesCollection';
import 'common/api/templateModel';
