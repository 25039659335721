import PropTypes from 'prop-types';
import React from 'react';

const BuildInfo = ({ buildInfo }) => (buildInfo ? (
    <div>
        <span className="moreInfoText version">{buildInfo}</span>
    </div>
) : null);

BuildInfo.propTypes = { buildInfo: PropTypes.string };
BuildInfo.defaultProps = { buildInfo: null };

export default BuildInfo;
