import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import constants from './constants';
import toaLocationsListTmpl from './toaLocationsList.hbs';

const toaLocationsList = ListView.extend({
    className: 'toaLocationsList',

    template: toaLocationsListTmpl,

    initialize(options) {
        this.gridImportPromise = import('common/dynamicPages/api/grid').then(({ default: gridApi }) => {
            this.gridApi = gridApi;
        });
        this.requestParameters = {
            item: [{
                name: 'initialRequest',
                value: 'true',
            }],
        };

        const superOptions = {
            serviceName: constants.TOALOCATION_SERVICENAME,
            context: constants.TOALOCATION_CONTEXT,
            returnRoute: constants.TOALOCATION_RETURNROUTE,
            viewId: options.viewId,
            configContext: {
                productCode: constants.TOALOCATION_PRODUCTCODE,
                functionCode: constants.TOALOCATION_FUNCTIONCODE,
                typeCode: constants.TOALOCATION_TYPECODE,
                subType: '*',
                actionMode: 'SELECT',
            },
            requestParameters: this.requestParameters,
            additionalSearchFields: [{
                fieldName: 'UserGroup',
                fieldValue: [
                    options.userGroup,
                ],
                dataType: 'text',
                operator: '=',
            }],
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    getButtons(parentHelpers) {
        return Object.entries({
            delete: 'hasDeleteEntitlement',
            restore: 'hasRestoreEntitlement',
        }).filter(([, value]) => parentHelpers[value])
            .map(([key]) => ({
                class: key,
                localeString: parentHelpers.getButtonString(key),
            }));
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        ListView.prototype.onRender.call(this);
        if (this.gridView) {
            this.listenTo(this.gridView, 'gridapi:loaded', this.clearGridRequestParams);
            this.listenTo(this.gridView, 'gridapi:loaded:failed', this.clearGridRequestParams);

            const gridViewCid = this.gridView.cid;
            this.listenTo(this.appBus, (`gridapi:showMessages${gridViewCid}`), this.clearGridRequestParams);
            this.listenTo(this.appBus, (`gridapi:showWarningMessage${gridViewCid}`), this.clearGridRequestParams);
            this.listenTo(this.appBus, (`gridapi:showErrorMessages${gridViewCid}`), this.clearGridRequestParams);

            this.startGridRespAlertListeners(this.gridView.cid);
        }
    },

    /**
     * @description refreshes the grid data in response to the refresh icon
     * pass in true to send rowsperpage
     */
    refreshData() {
        this.setGridRequestParams(this.requestParameters);
        ListView.prototype.refreshData.call(this);
    },

    insert() {
        this.setupPrefill();
        store.set('listView-contextKey', this.contextKey);
        this.navigateTo(constants.TOALOCATION_INSERT_ROUTE);
    },

    /**
     * handles select action from the time open account location
     * @param {Object} optionsParam - Holds options for the location being selected
     */
    gridRowSelect(optionsParam) {
        const options = optionsParam;
        this.setupPrefill();
        options.model.context = this.contextDef;
        store.set('listView-contextKey', this.contextKey);
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(constants.TOALOCATION_VIEW_ROUTE);
    },

    /**
     * handles modify action from the time open account location
     * @param {Object} optionsParam - Holds options for the location being selected
     */
    gridRowModify(optionsParam) {
        // NH-154844
        const options = optionsParam;
        this.setupPrefill();
        options.model.context = this.contextDef;
        store.set('listView-contextKey', this.contextKey);
        store.set(`${this.contextKey}-actionModel`, options.model);

        this.navigateTo(constants.TOALOCATION_MODIFY_ROUTE);
    },

    /**
     * @name gridRowRestore
     * @param {object} optionsParam
     */
    gridRowRestore(optionsParam) {
        const options = optionsParam;
        options.model.context = this.contextDef;
        store.set('listView-contextKey', this.contextKey);
        store.set(`${this.contextKey}-actionModel`, options.model);
        ListView.prototype.gridRowRestore.call(this, options);
    },

    /**
     * @name setupPrefill
     * @description sets up the prefill store for the detail page
     */
    setupPrefill() {
        // store the usergroup for the detail screen
        if (this.options.userGroup) {
            const prefill = { USERGROUP: this.options.userGroup };

            store.set(constants.TOALOCATION_PREFILL, prefill);
        }
    },

    templateHelpers() {
        const parentHelpers = ListView.prototype.templateHelpers.call(this);
        return {
            ...parentHelpers,
            entitlements: this.getButtons(parentHelpers),
            insertButtonLabel: locale.get('CMAINT.addTOALocation'),
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'TOALOC_LIST',
    view: toaLocationsList,
    options: {
        title: locale.get('CMAINT.TOALocationTitle'),
    },
});

export default toaLocationsList;
