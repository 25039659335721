import FieldSearch from 'no-override!@glu/grid/src/filter/fieldSearch';
import util from '@glu/core/src/util';
import $ from 'jquery';
import DatePicker from '@glu/datepicker';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import dateRangeTmpl from './dateRange2.hbs';

export default FieldSearch.extend({
    template: dateRangeTmpl,
    className: 'date-range-search',
    startDate: null,
    endDate: null,

    ui: {
        $datePickerInput: '.date-picker-input',
        $datePickerWrapper: '.date-picker-wrapper',
        $submitBtn: 'button[type=submit]',
        $clearBtn: '.btn-clear-filter',
    },

    events: {
        'click @ui.$submitBtn': 'handleApplyFilter',
        'click @ui.$clearBtn': 'handleClearFilter',
    },

    onRender() {
        const dpLocale = {};
        let ranges;

        /* Test the 4 different 'modes' here: date, date-time, date-range, date-time-range */
        if (this.model.get('type') === 'date') {
            this.model.set('type', 'date-range');
        } else {
            this.model.set('type', 'date-time-range');
        }

        this.isTime = this.model.get('type') === 'date-time' || this.model.get('type') === 'date-time-range';
        this.isRange = this.model.get('type') === 'date-range' || this.model.get('type') === 'date-time-range';

        const startDate = this.startDate ? this.startDate : moment(new Date()).startOf('day');
        const endDate = this.endDate ? this.endDate : moment(new Date()).endOf('day');

        if (this.isTime) {
            this.dpFormat = 'MM/DD/YYYY HH:mm:ss';
        } else {
            this.dpFormat = 'MM/DD/YYYY';
        }
        dpLocale.format = this.dpFormat;

        dpLocale.applyLabel = locale.get('PAY.Done');
        dpLocale.customRangeLabel = locale.get('PAY.dateTimeRange');

        if (this.isRange) {
            ranges = this.getRanges();
        }

        const config = this.generateConfig({
            startDate,
            endDate,
            singleDatePicker: !this.isRange,
            timePicker: this.isTime,
            ranges,
            locale: dpLocale,
        });

        this.renderDatePicker(config);

        /* Should now always be valid, therefore enabled */
        this.ui.$submitBtn.prop('disabled', false);
    },

    generateConfig(options) {
        return util.extend(
            options,
            {
                showDropdowns: true,
                showCustomRangeLabel: true,
                alwaysShowCalendars: false,
                linkedCalendars: false,
                opens: 'right',
                autoApply: true,
                timePickerIncrement: 5,
                autoUpdateInput: false,
            },
        );
    },

    renderDatePicker(config) {
        const self = this;

        this.$datepicker = new DatePicker({
            $target: this.ui.$datePickerInput,
            config,
        });

        const $renderedEl = this.$datepicker.render().$el;

        if (this.isTime) {
            $('div.daterangepicker').addClass('timepicker');
        }

        this.ui.$datePickerWrapper.html($renderedEl);

        this.$datepicker.$el.on('apply.daterangepicker', (ev, picker) => {
            $('.date-picker-input').val(`${picker.startDate.format(self.dpFormat)} - ${picker.endDate.format(self.dpFormat)}`);
        });

        this.$datepicker.$el.on('cancel.daterangepicker', () => {
            $('.date-picker-input').val('');
        });
    },

    getRanges() {
        return {
            Today: [
                moment(new Date()).startOf('day'),
                moment(new Date()).endOf('day'),
            ],

            'Last 7 Days': [
                moment(new Date()).subtract(7, 'day').startOf('day'),
                moment(new Date()).endOf('day'),
            ],

            'Last 30 Days': [
                moment(new Date()).subtract(30, 'day').startOf('day'),
                moment(new Date()).endOf('day'),
            ],

            'Last 60 Days': [
                moment(new Date()).subtract(60, 'day').startOf('day'),
                moment(new Date()).endOf('day'),
            ],

            'Last 90 Days': [
                moment(new Date()).subtract(90, 'day').startOf('day'),
                moment(new Date()).endOf('day'),
            ],

            'Month to Date': [
                moment(new Date()).startOf('month'),
                moment(new Date()).endOf('day'),
            ],

            'Quarter to Date': [
                moment(new Date()).startOf('quarter'),
                moment(new Date()).endOf('day'),
            ],

            'Year to Date': [
                moment(new Date()).startOf('year'),
                moment(new Date()).endOf('day'),
            ],
        };
    },

    handleApplyFilter() {
        try {
            this.trigger('filter', this.createFilter(this.$datepicker.dateRangePicker));
        } catch (ex) {
            return false;
        }
        return false;
    },

    createFilter(datePicker) {
        const { startDate, endDate } = this.$datepicker.dateRangePicker;
        this.model.set({
            START_DATE: startDate.format(),
            END_DATE: endDate.format(),
        });

        const equality = this.isRange ? 'BETWEEN' : 'EQ';
        return this.buildFilter(
            this.startDate || startDate,
            equality,
            this.endDate || endDate,
            datePicker,
        );
    },

    buildFilter(value, equality, value2) {
        let format;

        const dateFormat = userInfo.getDateFormat() ? userInfo.getDateFormat() : 'MM/DD/YYYY';
        const timeFormat = 'HH:mm:ss';

        /* Add time to format? */
        if (this.isTime) {
            format = `${dateFormat} ${timeFormat}`;
        } else {
            format = dateFormat;
        }

        const filterOutput = {
            label: !this.isRange ? value.format(format) : `is between ${value.format(format)} and ${value2.format(format)}`,
            title: this.model.get('title'),
            type: this.model.get('fieldType'),
            field: this.field,
            equality,
        };

        if (equality === 'BETWEEN') {
            filterOutput.value = [value.format(format), value2.format(format)];
            filterOutput.filter = util.bind(
                this.filter,
                this,
                this.field,
                [value.format(), value2.format()],
            );
        } else {
            filterOutput.value = value.format();
            filterOutput.filter = util.bind(this.filter, this, this.field, value.format());
        }

        return filterOutput;
    },
});
