import http from '@glu/core/src/http';
import number from '@glu/core/src/number';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import Format from '@glu/core/src/templateHelpers';
import Formatter from 'system/utilities/format';
import moment from 'moment';
import services from 'services';
import Constants from 'common/dynamicPages/api/constants';
import Decimals from 'common/dynamicPages/api/decimals';
import productHelper from 'common/dynamicPages/api/productHelper';
import validatorUtil from 'common/util/validatorUtil';
import transform from 'common/util/transform';
import httpError from 'common/util/httpErrorResult';
import userInfo from 'etc/userInfo';

export default Model.extend({

    defaults: {
        isBatch: false,
        isChild: false,
        driverFields: [],
        fieldData: {},
        childNumber: 0,
        hasChildren: false,
        batchSeqNumber: -1,
    },

    // hard coded, will fix in next checkin
    initialize(attributes, options) {
        this.context = options.context;
        if (options.jsonData) {
            this.setupJsonData(options);
        }
    },


    /**
     * Extract locked fields from the jsonData
     * @param {Array} [fieldInfoList] - The list of fields
     * @return {Array} - Set of locked fields as an array of strings
     */
    extractLockedFields(fieldInfoList = []) {
        const lockedFields = util.find(fieldInfoList, field => field.name === 'LOCKED_FIELDS');
        if (lockedFields === undefined) {
            return [];
        }
        const { value = '' } = lockedFields;
        return util.isEmpty(value) ? [] : value.split(',');
    },

    setupJsonData(options) {
        this.jsonData = options.jsonData;

        this.lockedFields = options.lockedFields
            || this.extractLockedFields(options.jsonData.fieldInfoList);

        /**
         * Removes TEXTLINE (not sure why) fields,
         * then makes a fieldInfo hash with fieldInfo.name as the key.
         */
        this.fieldData = util.chain(this.jsonData.fieldInfoList).filter(fieldInfo => fieldInfo.fieldUIType !== 'TEXTLINE').reduce((fieldData, fieldInfo) => {
            const fieldDataParam = fieldData;
            fieldDataParam[fieldInfo.name] = fieldInfo;
            return fieldDataParam;
        }, {}).value();

        this.isBatch = this.jsonData.isBatch;
        this.isChild = this.jsonData.isChild;
        this.childSequenceKey = this.jsonData.childSequenceKey;
        this.childGridKey = this.jsonData.childGridKey;
        this.driverFields = this.jsonData.driverFields;
        this.grandChildFields = this.jsonData.grandChildFields;
        this.grandChildName = this.jsonData.grandChildName;
        this.childNumber = this.jsonData.childNumber;
        this.batchSeqNumber = this.jsonData.batchSeqNumber;

        // added new field in the model info
        this.hasChildren = this.jsonData.hasChildren;
        this.key = {};
        this.setValidators();
    },

    setValidators() {
        const self = this;

        self.validators = {};

        util.each(this.fieldData, (fieldData, key) => {
            if (fieldData.mandatory === true
                && (fieldData.typeAhead !== null || fieldData.protected !== true)) {
                self.validators[key] = {
                    exists: true,
                    description: fieldData.fieldLabel,
                };
            }
        });

        // setup fieldtype validators

        const fieldtypes = util.where(this.jsonData.fieldInfoList, {
            visible: true,
            protected: false,
        });

        fieldtypes.forEach((field) => {
            validatorUtil.setValidator(field, self);
        });
    },

    update(options) {
        return this.genericObjectFunction(options, 'update');
    },
    repair(options) {
        return this.genericObjectFunction(options, 'repair');
    },
    getrate(options) {
        return this.genericObjectFunction(options, 'getrate');
    },
    trade(options) {
        return this.genericObjectFunction(options, 'trade');
    },
    approve(options) {
        return this.genericObjectFunction(options, 'approve');
    },
    unapprove(options) {
        return this.genericObjectFunction(options, 'unapprove');
    },
    extend(options) {
        return this.genericObjectFunction(options, Constants.ACTION_EXTEND);
    },
    validateAction(options) {
        return this.genericObjectFunction(options, 'validate');
    },
    unvalidate(options) {
        return this.genericObjectFunction(options, 'unvalidate');
    },
    paymentIssued(options) {
        return this.genericObjectFunction(options, 'paymentissued');
    },
    delete(options) {
        return this.genericObjectFunction(options, 'delete');
    },
    suspend(options) {
        return this.genericObjectFunction(options, 'suspend');
    },
    activate(options) {
        return this.genericObjectFunction(options, 'activate');
    },
    reject(options) {
        return this.genericObjectFunction(options, 'reject');
    },
    needinfo(options) {
        return this.genericObjectFunction(options, 'needinfo');
    },
    disable(options) {
        return this.genericObjectFunction(options, 'disable');
    },
    restore(options) {
        return this.genericObjectFunction(options, 'restore');
    },
    reverse(options) {
        return this.genericObjectFunction(options, 'reverse');
    },
    pay(options) {
        return this.genericObjectFunction(options, Constants.ACTION_PAY);
    },
    return(options) {
        return this.genericObjectFunction(options, Constants.ACTION_RETURN);
    },
    payissue(options) {
        return this.genericObjectFunction(options, Constants.ACTION_PAYISSUE);
    },

    genericObjectFunction(options, methodName) {
        const optionsParam = options ? util.clone(options) : {};
        const self = this;
        const model = self;
        const { success } = optionsParam;

        const unapprove = () => {
            model.stopListening();
            model.trigger(methodName, model, model.collection, optionsParam);
        };

        optionsParam.success = (resp) => {
            if (options.wait || model.isNew()) {
                unapprove();
            }
            if (success) {
                success(model, resp, optionsParam);
            }
            if (!model.isNew()) {
                model.trigger('sync', model, resp, optionsParam);
            }
        };

        if (this.isNew()) {
            optionsParam.success();
            return false;
        }

        const xhr = this.sync(methodName, this, optionsParam);
        if (!optionsParam.wait) {
            unapprove();
        }
        return xhr;
    },

    methodAction(model, options, urlAction, passedData, passedActions) {
        const requestData = passedData || this.convertModelAttributesToKeyJSON(model);

        const defaultActions = {
            success(result) {
                options.success(result);
            },
            error(result) {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                    responseJSON: result.responseJSON,
                });
            },
        };

        const actions = util.extend(defaultActions, passedActions);

        const genServiceNameURL = services.generateUrl(model.context.serviceName);
        const methodService = (urlAction === Constants.URL_GETRATE_ACTION
            || urlAction === Constants.URL_TRADE_ACTION)
            ? services.generateUrl(urlAction) : genServiceNameURL + urlAction;

        return http.post(methodService, requestData, actions.success, actions.error);
    },

    sync(method, model, options) {
        const self = this;
        const optionsParam = options;
        const modelParam = model;

        if (method === 'delete') {
            return this.methodAction(modelParam, optionsParam, Constants.URL_DELETE_ACTION);
        }
        if (method === 'approve') {
            return this.methodAction(
                modelParam,
                optionsParam,
                Constants.URL_APPROVE_ACTION,
                optionsParam.submissionData,
            );
        }
        if (method === 'disable') {
            return this.methodAction(modelParam, optionsParam, Constants.URL_DISABLE_ACTION);
        } else if (method === 'suspend') { // PCM related
            return this.methodAction(modelParam, optionsParam, Constants.URL_SUSPEND_ACTION);
        } else if (method === 'activate') { // PCM related
            return this.methodAction(modelParam, optionsParam, Constants.URL_ACTIVATE_ACTION);
        } else if (method === 'restore') {
            return this.methodAction(modelParam, optionsParam, Constants.URL_RESTORE_ACTION);
        }
        if (method === 'unapprove') {
            return this.methodAction(modelParam, optionsParam, Constants.URL_UNAPPROVE_ACTION);
        }
        if (method === 'validate') {
            return this.methodAction(modelParam, optionsParam, Constants.URL_VALIDATE_ACTION);
        }
        if (method === 'unvalidate') {
            return this.methodAction(modelParam, optionsParam, Constants.URL_UNVALIDATE_ACTION);
        }
        if (method === 'authrize') {
            return this.methodAction(
                modelParam,
                optionsParam,
                Constants.URL_AUTHORIZE_ACTION,
                optionsParam.submissionData,
            );
        }
        if (method === 'rejectfm') {
            return this.methodAction(
                modelParam,
                optionsParam,
                Constants.URL_REJECTFM_ACTION,
                optionsParam.submissionData,
            );
        }
        if (method === 'pay') {
            return this.methodAction(
                modelParam,
                optionsParam,
                Constants.URL_PAY_ACTION,
                optionsParam.submissionData,
            );
        } else if (method === 'padjust') {
            return this.methodAction(
                modelParam,
                optionsParam,
                Constants.URL_PAYANDADJUST_ACTION,
                optionsParam.submissionData,
            );
        }
        if (method === 'canclreq') {
            return this.methodAction(
                modelParam,
                optionsParam,
                Constants.URL_CANCLREQ_ACTION, // Constants.URL_UPDATE_ACTION,
                optionsParam.submissionData,
            );
        } else if (method === Constants.ACTION_EXTEND) {
            return this.methodAction(
                modelParam,
                optionsParam,
                Constants.URL_EXTEND_ACTION,
                modelParam.submissionData,
            );
        } else if (method === 'paymentissued') {
            const URLPaymentIssued = Constants.URL_PAYMENTISSUED_ACTION;
            return this.methodAction(modelParam, optionsParam, URLPaymentIssued);
        } else if (method === 'getrate' || method === 'trade') {
            // Only difference between getrate and trade is the action
            const action = method === 'getrate' ? Constants.URL_GETRATE_ACTION : Constants.URL_TRADE_ACTION;

            const jsonData = {
                items: [{
                    data: [{
                        name: 'TNUM',
                        value: modelParam.get('TNUM'),
                    }, {
                        name: 'TYPECODE',
                        value: modelParam.get('TYPE'),
                    }, {
                        name: 'UPDATECOUNT__',
                        value: modelParam.get('UPDATECOUNT__'),
                    }],
                }],
            };

            return this.methodAction(model, options, action, jsonData);
        } else if (method === 'needinfo') {
            const needInfoData = this.convertModelAttributesToKeyJSON(model);
            const needInfoReasonItem = {
                name: 'NEEDS_MORE_INFO_REASON',
                value: model.get('NEEDS_MORE_INFO_REASON'),
            };
            needInfoData.item.push(needInfoReasonItem);

            return this.methodAction(
                model,
                options,
                Constants.URL_NEED_INFO_ACTION,
                needInfoData,
            );
        } else if (method === 'reject') {
            const rejectData = this.convertModelAttributesToKeyJSON(modelParam);
            const rejectReasonItem = {
                name: 'APPROVER_REJECTION_REASON',
                value: modelParam.get('APPROVER_REJECTION_REASON'),
            };
            if (modelParam.get('REJECTED_REASON')) {
                rejectData.item.push({
                    name: 'REJECTED_REASON',
                    value: modelParam.get('REJECTED_REASON'),
                });
            }
            const isBatch = (this.isBatch || (modelParam.get('FUNCTION') === 'BATCH') || (modelParam.get('FUNCTION') === 'BHTMPL') || rejectData.item.item);
            if (isBatch) {
                rejectData.item.item.push(rejectReasonItem);
            } else {
                rejectData.item.push(rejectReasonItem);
            }
            const URLReject = Constants.URL_REJECT_ACTION;
            return this.methodAction(modelParam, optionsParam, URLReject, rejectData);
        } else if (method === 'create' || (this.isChild === true && method === 'update') || (modelParam.get('DISCLOSURE_ACTION_MODE') === 'INSERT')) {
            /*
             * NH-136975
             *  if the model already has USERGROUP, don't set it to the logged in user
             *  on the admin it may be set to the client user group
             */
            if (!modelParam.get('USERGROUP')) {
                modelParam.set('USERGROUP', userInfo.get('group'), {
                    silent: true,
                });
            }
            const addData = this.convertModelAttributesToServerJSON(modelParam, method);

            const actions = {
                success(result) {
                    if (self.isChild !== true) {
                        modelParam.generateIdValue();
                    }
                    optionsParam.success(result);
                },
                error(result) {
                    modelParam.error = JSON.parse(result.responseText);
                    optionsParam.error(modelParam, {});
                },
            };


            return this.methodAction(modelParam, optionsParam, this.isChild ?
                Constants.URL_ADDCHILDREN_ACTION : Constants.URL_ADD_ACTION, addData, actions);
        } else if (method === 'update' && this.isChild === false) {
            // NH-154844 only populate USERGROUP if not yet populated
            if (!modelParam.get('USERGROUP')) {
                modelParam.set('USERGROUP', userInfo.get('group'), {
                    silent: true,
                });
            }

            if (modelParam.context.typeCode === 'ACHPTS') {
                modelParam.set('USERGROUP', '_Default');
            }

            /*
             * FIXME: There is an issue in metaDrivenForm where the subType is not being
             * correctly updated in a modify state when the model is reloaded due to changes
             * in the beneficiary bank information. The issue only exists in modify state.
             * This is a temporary fix until the core of the issue can be addressed.
             */
            if (modelParam.fieldData && modelParam.fieldData.SUBTYPE &&
                modelParam.fieldData.SUBTYPE.value &&
                modelParam.fieldData.SUBTYPE.value !== modelParam.get('SUBTYPE')) {
                modelParam.set('SUBTYPE', modelParam.fieldData.SUBTYPE.value);
            }
            const updateData = model.overloadedMethodName === 'pAdjust' ? { items: [this.convertModelAttributesToServerJSON(modelParam, method)] }
                : this.convertModelAttributesToServerJSON(modelParam, method);
            let updateService = services.generateUrl(modelParam.context.serviceName);
            if (modelParam.overloadedMethodName === 'restore') {
                updateService += Constants.URL_RESTORE_ACTION;
            } else if (modelParam.overloadedMethodName === 'reverse') {
                updateService += Constants.URL_REVERSE_ACTION;
            } else if (model.overloadedMethodName === 'needinfo') {
                updateService += Constants.URL_NEED_INFO_ACTION;
            } else if (model.overloadedMethodName === 'pAdjust') {
                updateService += Constants.URL_PAYANDADJUST;
            } else {
                updateService += Constants.URL_UPDATE_ACTION;
            }


            return http.post(updateService, updateData, (result) => {
                optionsParam.success(result);
            }, httpError.getResult(optionsParam, modelParam));
        } else if (method === 'repair') {
            model.set('USERGROUP', userInfo.get('group'), {
                silent: true,
            });

            const repairData = this.convertModelAttributesToServerJSON(modelParam, method);
            const genServiceName = services.generateUrl(modelParam.context.serviceName);
            const repairService = genServiceName + Constants.URL_REPAIR_ACTION;

            return http.post(repairService, repairData, (result) => {
                optionsParam.success(result);
            }, httpError.getResult(optionsParam, modelParam));
        } else if (method === 'read') {
            const readData = this.convertModelAttributesToKeyJSON(modelParam);
            const genServiceName = services.generateUrl(modelParam.context.serviceName);
            const readService = genServiceName + Constants.URL_READ_ACTION;
            return http.post(readService, readData, (response) => {
                const data = self.convertServerJsonToModelAttributes(response);

                self.lockedFields = self.extractLockedFields(response.item);

                if (model.get('TYPE') === 'ACH_SCHE') {
                    data.FUNCTION = 'MAINT';
                    data.TYPE = 'ACH_SCHE';
                }

                /**
                 * In situations where more than 'items' is passed back,
                 * this allows adding other stuff to data/model
                 */
                if (util.isFunction(optionsParam.extraParsing)) {
                    optionsParam.extraParsing(data, response);
                }

                if (response.tables) {
                    data.TABLES = response.tables;
                }

                optionsParam.success(data);
            }, (result) => {
                optionsParam.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                });
            });
        }
        // Default
        return this.methodAction(modelParam, optionsParam, `/${method}`);
    },

    /**
     * Handle required changes to the data before it is set on the model
     */
    formatModelAttributes(entry, itemArray) {
        const entryParam = entry;
        const fieldDataObj = this.fieldData
            ? this.fieldData[entryParam.name] : this.attributes.fieldData[entryParam.name];
        let date;
        entryParam.value = util.unescape(entryParam.value);
        if (fieldDataObj) {
            switch (fieldDataObj.fieldUIType) {
            case 'CHECKBOX':
                if (entryParam.value === fieldDataObj.checkboxOffValue
                && !util.contains(['SHOW_WHEN_FALSE'], fieldDataObj.relatedProperty)) {
                    entryParam.value = '';
                }
                break;
            case 'CALENDAR_ENABLEALL':
            case 'TEXTBOX':
            case 'TEXTBOX_ERRORICON': {
                const amountFieldTypes = ['AMOUNT', 'PAMOUNT', 'CURRENCY'];
                if (entryParam.value) {
                    if (fieldDataObj.fieldType === 'DATE') {
                        date = moment(entryParam.value);
                        entryParam.value = date.format(userInfo.getDateFormat());
                    } else if (fieldDataObj.fieldType === 'DATETIME') {
                        entryParam.value = Format.date(entryParam.value, `${userInfo.getDateFormat()} ${userInfo.getTimeFormat()}`);
                    }
                }

                if (amountFieldTypes.indexOf(fieldDataObj.fieldType) > -1 && entryParam.value) {
                    if (fieldDataObj.relatedProperty) {
                        const currencyCode = util.findWhere(itemArray, {
                            name: fieldDataObj.relatedProperty,
                        });
                        if (currencyCode) {
                            entryParam.value = number(entryParam.value)
                                .format(Decimals.getCurrencyFormat(currencyCode.value));
                        }
                    } else {
                        // format currency values
                        entryParam.value = Formatter.formatCurrency(entryParam.value);
                    }
                }
                break;
            }
            default:
            }
        }

        return entryParam.value;
    },

    saveGrandChild(options) {
        const self = this;
        const grandChildColumns = self.grandChildFields;
        const gridName = self.grandChildName;
        const { model } = options;
        const nvp = [];
        let value;

        util.each(grandChildColumns, (columnName) => {
            if (model.has(columnName)) {
                value = model.get(columnName);

                if (columnName === model.childSequenceKey.toUpperCase()) {
                    if (value === '') {
                        value = self.childNumber;
                    }
                }
                nvp.push({
                    name: columnName,
                    value,
                });
            }
        });
        value = model.get(self.childSequenceKey.toUpperCase());
        if (value === '') {
            value = self.childNumber;
        }
        nvp.push({
            name: self.childSequenceKey.toUpperCase(),
            value,
        });
        value = model.get('BATCHSEQNUM');
        nvp.push({
            name: 'BATCHSEQNUM',
            value,
        });
        const addData = {
            grids: [{
                name: gridName,
                items: [{
                    item: nvp,
                }],
            }],
        };
        const genServiceName = services.generateUrl(self.context.serviceName);
        const addService = genServiceName + Constants.URL_ADDGRANDCHILDREN_ACTION;
        http.post(addService, addData, (result) => {
            options.success(result);
        }, httpError.getResult(options, model));
    },
    deleteGrandChild(options) {
        const self = this;
        const gridName = self.grandChildName;
        const { model } = options;
        const keyColumns = model.key;
        const nvp = [];

        util.each(keyColumns, (columnValue, columnName) => {
            nvp.push({
                name: columnName,
                value: model.get(columnName),
            });
        });
        const deleteData = {
            grids: [{
                name: gridName,
                items: [{
                    item: nvp,
                }],
            }],
        };
        const genServiceName = services.generateUrl(self.context.serviceName);
        const deleteService = genServiceName + Constants.URL_DELETEGRANDCHILDREN_ACTION;
        http.post(deleteService, deleteData, (result) => {
            options.success(result);
        }, (result) => {
            // model.error = JSON.parse(result.responseText);
            options.error(JSON.parse(result.responseText));
        });
    },
    convertModelAttributesToKeyWithOptContextJSON(model, addContext) {
        const jsonData = util.map(this.key, (value, key) => ({
            name: key,
            value: model.get(key),
        }));

        let confDate = model.get('CONFIRMED_TIMESTAMP');

        if (!confDate || confDate.length === 0) {
            confDate = '';
        } else {
            confDate = new Date(confDate).toUTCString().slice(5, 16);
            confDate = confDate.slice(7, 11).concat('-', moment().month(confDate.slice(3, 6)).format('MM'), '-', confDate.slice(0, 2));
        }

        jsonData.push(
            {
                name: Constants.ENTRYMETHOD,
                value: model.get('ENTRYMETHOD'),
            }, {
                name: 'TEMPLATEENTRYMETHOD',
                value: model.get('TEMPLATEENTRYMETHOD'),
            }, {
                name: 'FUNCTIONCODE',
                value: model.get('FUNCTION'),
            }, {
                name: 'UPDATECOUNT__',
                value: model.get('UPDATECOUNT__'),
            }, {
                name: '_saveWithWarning',
                value: model.get('_saveWithWarning'),
            },
            // Below data elements are needed for positive, electronic and reverse positive pay
            {
                name: 'ACTIONCODE',
                value: model.get('ACTIONCODE'),
            }, {
                name: 'CMDECISION',
                value: model.get('CMDECISION'),
            }, {
                name: 'CLIENTRETURNREASONCODE',
                value: model.get('CLIENTRETURNREASONCODE'),
            }, {
                name: 'CLIENTRETURNREASONDESC',
                value: model.get('CLIENTRETURNREASONDESC'),
            }, {
                name: 'PAYISSUE_FLAG',
                value: model.get('PAYISSUE_FLAG'),
            },
            // Below data elements are needed for cancel stop payments
            {
                name: 'CONFIRMED_TIMESTAMP',
                value: confDate,
            }, {
                name: 'STOP_DURATION',
                value: model.get('STOP_DURATION'),
            },
            // below data is needed to determine if disclosure is enabled or not
            {
                name: 'CONINTLWIREFLAG',
                value: model.get('CONINTLWIREFLAG'),
            }, {
                name: 'CONSUMER_DISCLOSURE_USER_ACCEPTED',
                value: model.get('CONSUMER_DISCLOSURE_USER_ACCEPTED'),
            }, {
                name: 'CONSUMER_DISCLOSURE_USER_REJECTED',
                value: model.get('CONSUMER_DISCLOSURE_USER_REJECTED'),
            },
            /**
             * Needed to distinguish acceptance of duplicate challenge
             * from a general _saveWithWarning
             */
            {
                name: Constants.DUPLICATE_ACCEPTED_INDICATOR,
                value: model.get(Constants.DUPLICATE_ACCEPTED_INDICATOR),
            },
            {
                name: Constants.CONTACT_CHANGES_ACCEPTED_INDICATOR,
                value: model.get(Constants.CONTACT_CHANGES_ACCEPTED_INDICATOR),
            },
            {
                name: Constants.BENE_UPDATED_ACCEPTED_INDICATOR,
                value: model.get(Constants.BENE_UPDATED_ACCEPTED_INDICATOR),
            },

        );

        // TODO code clean up viewAction needs to be removed later
        if (model.get('viewAction')) {
            jsonData.push({
                name: 'viewAction',
                value: model.get('viewAction'),
            });
        }

        if (model.get('_mode')) {
            jsonData.push({
                name: '_mode',
                value: model.get('_mode'),
            });
        }
        if (addContext) {
            jsonData.push({
                name: 'PRODUCTCODE',
                value: model.get('PRODUCT'),
            }, {
                name: 'TYPECODE',
                value: model.get('TYPE'),
            });
        }

        return {
            item: jsonData,
        };
    },

    convertModelAttributesToKeyJSON(model) {
        const ctx = this.context;
        const prodHelpChild = productHelper
            .hasChildren(ctx.productCode, ctx.functionCode, ctx.typeCode);
        const isInst = (model.get('FUNCTION') === 'INST');
        const isBatch = (this.isBatch
            || (model.get('FUNCTION') === 'BATCH') || (model.get('FUNCTION') === 'BHTMPL')
            || (this.hasChildren === true) || (ctx && prodHelpChild));
        const jsonData = this.convertModelAttributesToKeyWithOptContextJSON(model);

        if (isBatch && !isInst) {
            // for batch or tableMaint with child grid
            return {
                item: jsonData,
            };
        }
        return jsonData;
    },

    convertServerJsonToModelAttributes(serverJson) {
        const self = this;
        const data = {};

        util.each(serverJson.item, (entry, index, itemArray) => {
            data[entry.name] = self.formatModelAttributes(entry, itemArray);
        });

        return data;
    },

    convertModelAttributesToServerJSON(model, method) {
        let jsonData = this.getNameValuePairAttributes(method);
        const componentGridJson = this.getNameValuePairAttributesForGridComponent();

        if (this.isChild) {
            return {
                grids: [{
                    name: this.childGridKey,
                    items: [{
                        item: jsonData,
                    }],
                }],
            };
        }

        const { functionCode } = this.jsonData.typeInfo;
        const isInst = (functionCode === 'INST');

        // for any template function, get the schedule model, if available
        if (functionCode.includes('TMPL') && (model.schedModel)) {
            jsonData = jsonData.concat(transform.convertScheduleModelToNVP(model.schedModel));
        }

        if (this.isBatch && !isInst) {
            return {
                item: {
                    item: jsonData,
                },
            };
        }

        const ctx = this.context;
        const prodHelpChild = productHelper
            .hasChildren(ctx.productCode, ctx.functionCode, ctx.typeCode);

        if (((this.hasChildren === true) || (ctx && prodHelpChild)) && !isInst) {
            // Page with component grids
            const retObj = {
                grids: componentGridJson,
                item: {
                    item: jsonData,
                },
            };
            if (this.get('gridsOverride')) {
                retObj.grids = this.get('gridsOverride');
            }
            return retObj;
        }

        jsonData.push({
            name: 'FUNCTIONCODE',
            value: model.get('FUNCTION') || functionCode,
        }, { // below data is needed to determine if disclosure is enabled or not
            name: 'CONSUMER_DISCLOSURE_USER_ACCEPTED',
            value: model.get('CONSUMER_DISCLOSURE_USER_ACCEPTED'),
        });

        return {
            item: jsonData,
        };
    },

    getNameValuePairAttributesForGridComponent() {
        const self = this;
        let gridName;
        let gridRows;
        let items = [];
        const grids = [];

        util.each(self.componentGridCollections, (value, key) => {
            gridName = key;
            gridRows = value;

            util.each(gridRows, (gridRow) => {
                items.push({
                    item: transform.hashToPairs(gridRow.attributes),
                });
            });

            grids.push({
                name: gridName,
                items,
            });
            items = [];
        });

        return grids;
    },

    getNameValuePairAttributes(method) {
        const self = this;
        const nvp = [];

        util.each(self.attributes, (value, key) => {
            let valueParam = value;
            const fieldDataObj = self.fieldData[key];
            let addField = true;

            // NH-6772 do not send the virtual fields back to the server.
            valueParam = util.unescape(valueParam);

            if (fieldDataObj && fieldDataObj.fieldUIType === 'CHECKBOX' && util.isEmpty(valueParam)) {
                valueParam = fieldDataObj.checkboxOffValue;
            }

            /**
             * for create do not send blank to the server because it is not
             * respecting the default values. instead do not send the field
             * at all.
             */
            if (method === 'create') {
                if (util.isEmpty(valueParam)) {
                    addField = false;
                }
            }

            /**
             * addField to be set to true only if itself has been determined
             * to be true and fieldDataObj is a valid reference
             */
            addField = (fieldDataObj && addField);

            /**
             * last check - is this the save incomplete indicator ? if yes,
             * then addField should be true
             */
            if (key === Constants.SAVE_INCOMPLETE_INDICATOR) {
                addField = true;
            }
            if (key === Constants.SAVE_WITHWARNING_INDICATOR) {
                addField = true;
            }
            if (key === Constants.CANCEL_FROM_WARNING_INDICATOR) {
                addField = true;
            }
            if (key === Constants.DUPLICATE_ACCEPTED_INDICATOR) {
                addField = true;
            }

            if (addField) {
                nvp.push({
                    name: key,
                    value: valueParam,
                });
            }
        });

        if (this.isChild) {
            nvp.push({
                name: this.childSequenceKey.toUpperCase(),
                value: this.childNumber,
            });
        }
        if (this.isBatch || this.isChild) {
            nvp.push({
                name: 'BATCHSEQNUM',
                value: this.get('BATCHSEQNUM'),
            });
        }

        return nvp;
    },

    setupFromData() {
        const self = this;
        const fieldInfoData = self.jsonData.fieldInfoList;
        self.comboList = [];
        self.comboListDefaultValue = [];
        self.comboFilterList = [];
        self.lookupHelperText = {};
        self.showHideFields = {};

        this.clear({
            silent: true,
        });

        // Create blank data and set it all at once
        const setData = util.reduce(fieldInfoData, (acc, item) => {
            acc[item.name] = '';
            return acc;
        }, {});

        self.set(setData, {
            silent: true,
        });

        util.each(fieldInfoData, (item) => {
            if (item.key) {
                self.key[item.name] = 'true';
            }

            /**
             * TODO: When driver fields no longer replace the model,
             *  we may not need to add COMBOBOXWIDGET entries to the comboList
             */
            if (item.fieldUIType === 'COMBOBOX' || item.fieldUIType === 'COMBOBOXWIDGET' || item.fieldUIType === 'DOUBLECOMBOBOXWIDGET') {
                self.comboList.push(item.name);
                self.comboListDefaultValue.push({
                    name: item.name,
                    defaultValue: item.value,
                });
            }

            if (item.fieldUIType === 'COMBOFILTER' || item.fieldUIType === 'COMBOFILTERLT' || item.fieldUIType === 'MULTICOMBOFILTER') {
                self.comboList.push({
                    name: item.name,
                    inquiryId: item.popupId,
                });
            }

            if (!util.isEmpty(item.helperText)) {
                self.lookupHelperText[item.name] = item.helperText
                    .map(helperText => helperText.toUpperCase());
            }

            if (!util.isEmpty(item.showHideFields)) {
                self.showHideFields[item.name] = item.showHideFields
                    .map(field => field.toUpperCase());
            }
        });
    },

    getKeyData() {
        const keyData = [];
        const self = this;
        util.each(this.key, (value, key) => {
            keyData.push({
                name: key,
                value: self.get(key),
            });
        });
        return {
            item: keyData,
        };
    },

    generateIdValue() {
        let idVal = '';
        const self = this;
        util.each(this.key, (value, key) => {
            idVal += self.get(key);
        });
        this.set({
            id: idVal,
        });
    },

});
