import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import ListView from 'common/dynamicPages/views/workflow/list';
import Grid from '@glu/grid';
import transform from 'common/util/transform';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './simpleTableWidget.hbs';
import tableTemplate from './simpleTableWidget-table.hbs';

export default BaseWidget.extend({
    template,
    className: 'ui-widget field-container',
    gridViews: [],

    onRender() {
        const tables = this.model.get('tables');
        const isGrid = this.model.get('isGrid');

        if (isGrid) {
            tables.forEach((table, idx) => {
                if (mobileUtil.isMobileGridEnabled()) {
                    let MobileList = ListView;
                    const mobileList = configureMobileInterface(MobileList, {
                        useGridViewFromOptions: true,
                    });
                    const gridView = {
                        wrapper: {
                            rows: table.collection,
                            columns: new Collection(table.headers),
                            refreshGridData: () => {},
                        },
                        loadPromise: Promise.resolve({}),
                    };
                    MobileList = MobileList.extend(mobileList);
                    this[`gridRegion-${idx}`].show(new MobileList({
                        renderMobileGridUsingTemplate: false,
                        prebuiltOptions: true,
                        gridView,
                        skipEntitlements: true,
                        disableFilters: true,
                        enableSavedViews: false,
                        disableSort: true,
                    }));
                } else {
                    this[`gridRegion-${idx}`].show(table.gridView);
                }
            });
        }
    },

    initialize(options = {}) {
        BaseWidget.prototype.initialize.call(this, options);
        this.model = new Model();

        this.options = options;

        this.listenTo(this.parentModel, 'change:TABLES', () => {
            this.handleTables();
            this.render();
        });

        this.handleTables();
    },

    handleTables() {
        if (this.options.widgetInitOptions && this.options.widgetInitOptions.isGrid) {
            this.template = tableTemplate;
            this.handleNativeTables();
        } else {
            this.handleDivTables();
        }
    },

    handleNativeTables() {
        const tables = (this.parentModel.get('TABLES') || []).map((table) => {
            const models = table.items.map(item => transform.pairsToHash(item.item));
            const collection = new Collection(models);
            const columnsToAlignRight = [
                'TOTALAMT',
                'TOTAL_PROCESSED',
                'ACCEPTS',
                'ERROR_COUNT',
                'REJECTS',
                'MODIFIED',
                'DELETED',
            ];
            const headers = table.headers.map(header => ({
                field: header.name,
                label: header.value,
                title: header.value,
                className: (columnsToAlignRight.indexOf(header.name) !== -1) ? 'text-right' : 'text-left',
            }));

            return {
                collection,
                headers,

                gridView: new Grid({
                    collection,
                    columns: headers,
                }),
            };
        });

        this.model.set({
            tables,
            isGrid: true,
        });
    },

    handleDivTables() {
        // TODO: Can we handle this as regular maps, possibly a flatten?
        const tables = (this.parentModel.get('TABLES') || []).forEach((table) => {
            const thisTable = [];
            // add the initial arrays for columns with the headers
            table.headers.forEach((header) => {
                thisTable.push([header]);
            });
            // add in the rows for the particular columns, each 'item' is a row
            table.items.forEach((item) => {
                item.item.forEach((column, idx) => thisTable[idx].push(column));
            });
            // finally add our table data to the set
            tables.push(thisTable);
        });
        this.model.set('tables', tables);
    },
});
