var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-region=\"successRegionQuick\"></div>\n<div class=\"alert-message\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n</div>\n<div class=\"sr-only\" role=\"alert\" data-hook=\"formErrors\"></div>\n<div data-hook=\"transferRegionQuick\">\n    <form>\n        <div class=\"row\">\n            <div class=\"col-6\">\n                <div class=\"form-group\">\n                    <label for=\"ACCOUNTFILTER-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":11,"column":46},"end":{"line":11,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.transfer.from",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":55},"end":{"line":11,"column":89}}}))
    + "</label>\n                    <input type=\"hidden\" class=\"form-control\" id=\"ACCOUNTFILTER-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":12,"column":80},"end":{"line":12,"column":87}}}) : helper)))
    + "\" name=\"ACCOUNTFILTER\"\n                        data-bind=\"model\" data-hook=\"fromCombo\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTFILTER\"></span>\n                </div>\n            </div>\n\n            <div class=\"col-6\">\n                <div class=\"form-group\">\n                    <label for=\"BENE_ACCOUNTENTITLEMENT-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":20,"column":56},"end":{"line":20,"column":63}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.transfer.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":65},"end":{"line":20,"column":97}}}))
    + "</label>\n                    <input type=\"hidden\" class=\"form-control\" id=\"BENE_ACCOUNTENTITLEMENT-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":21,"column":90},"end":{"line":21,"column":97}}}) : helper)))
    + "\"\n                        name=\"BENE_ACCOUNTFILTER\" data-bind=\"model\" data-hook=\"toCombo\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ACCOUNTENTITLEMENT\"></span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"date-picker col-6\">\n                <div class=\"form-group\">\n                    <label for=\"VALUE_DATE-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":30,"column":43},"end":{"line":30,"column":50}}}) : helper)))
    + "\" class=\"main-label\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.transfer.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":71},"end":{"line":30,"column":105}}}))
    + "</label>\n                    <input class=\"form-control input-date active\" type=\"text\" size=\"20\" data-bind=\"model\"\n                        name=\"VALUE_DATE\" value=\"\" id=\"VALUE_DATE-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":32,"column":66},"end":{"line":32,"column":73}}}) : helper)))
    + "\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALUE_DATE\"></span>\n                </div>\n            </div>\n            <div class=\"col-6\">\n                <div class=\"form-group\">\n                    <label for=\"transfer-amount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":38,"column":48},"end":{"line":38,"column":55}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.transfer.credit.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":57},"end":{"line":38,"column":100}}}))
    + "</label>\n                    <input class=\"form-control\" type=\"text\" name=\"CREDIT_AMOUNT\" data-bind=\"model\"\n                        id=\"transfer-amount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":40,"column":44},"end":{"line":40,"column":51}}}) : helper)))
    + "\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n                </div>\n            </div>\n            <div class=\"footer row right\">\n                <div class=\"col-12\">\n                    <button type=\"button\" class=\"btn btn-primary queue-add\" data-dismiss=\"modal\"\n                        data-hook=\"getQueueTransferBtn\" id=\"queueTransfer-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":47,"column":74},"end":{"line":47,"column":81}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.transfer.queue.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":83},"end":{"line":48,"column":54}}}))
    + "</button>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});