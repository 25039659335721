import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

export default function ColumnsDraggable({
  children, columnField, index, isDragDisabled
}) {
  if (!columnField) {
    return null;
  }
  return (
    <Draggable
      key={columnField}
      draggableId={columnField}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            userSelect: 'none',
            ...provided.draggableProps.style
          }}
          key={columnField}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}

ColumnsDraggable.propTypes = {
  children: PropTypes.node.isRequired,
  columnField: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool
};

ColumnsDraggable.defaultProps = {
  isDragDisabled: false
};
