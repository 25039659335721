import CollectionView from '@glu/core/src/collectionView';
import PaymentGrouping from 'common/dataCard/views/dataCardCompositeView';
import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import loadingTemplate from 'common/templates/loadingGrid.hbs';

const emptyView = ItemView.extend({
    template() {
        return locale.get('thereIsNoContent');
    },
});

const loadingView = ItemView.extend({
    template() {
        return loadingTemplate;
    },
});

export default CollectionView.extend({
    tagName: 'ul',
    className: 'payment-totals',
    itemView: PaymentGrouping,
    emptyView,

    loadingView,
    initialize(options) {
        this.isLoading = options.isLoading;
        this.listenTo(this.collection, 'reset', () => { this.isLoading = false; });
        CollectionView.prototype.initialize(options);
    },

    /**
     * If data is still loading show the spinner
     * else
     * If there is no data to show at all,
     * show "There is no content message"
     */
    getEmptyView() {
        if (this.isLoading) {
            return loadingView;
        }
        return emptyView;
    },
});
