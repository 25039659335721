var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"btn btn-tertiary toggleTotals\" data-hook=\"toggle-totals-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.show.totals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":127},"end":{"line":12,"column":158}}}))
    + "</button>";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div data-hook=\"payment-totals-region\" class=\"totals-region\" aria-live=\"polite\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(((helper = (helper = lookupProperty(helpers,"getSectionTitle") || (depth0 != null ? lookupProperty(depth0,"getSectionTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getSectionTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":31}}}) : helper)))
    + "</h3>\n    </div>\n    <div class=\"section-body\">\n\n        <div data-region=\"alert\"></div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-info-links\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTotals") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":174}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTotals") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-info-links\">\n                <button type=\"button\" class=\"btn btn-tertiary print\" data-hook=\"print-button\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":101},"end":{"line":22,"column":146}}}))
    + "\"><span class=\"icon-print\"></span><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":202},"end":{"line":22,"column":247}}}))
    + "</span></button>\n                <button type=\"button\" class=\"btn btn-tertiary export\" data-hook=\"export-button\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.exportOptions.Export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":103},"end":{"line":23,"column":143}}}))
    + "\"><span class=\"icon-export\"></span><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.exportOptions.Export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":200},"end":{"line":23,"column":240}}}))
    + "</span></button>\n                <button type=\"button\" class=\"btn btn-tertiary refresh\" data-hook=\"refresh-button\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":105},"end":{"line":24,"column":132}}}))
    + "\"><span class=\"icon-refresh\"></span><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":190},"end":{"line":24,"column":217}}}))
    + "</span><span class=\"refresh-time\" data-hook=\"refresh-time\"></span></button>\n            </div>\n        </div>\n\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n";
},"useData":true});