var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-3\"><label>"
    + alias2(((helper = (helper = lookupProperty(helpers,"groupLabel") || (depth0 != null ? lookupProperty(depth0,"groupLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"groupLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":48},"end":{"line":3,"column":62}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":63},"end":{"line":3,"column":97}}}))
    + "</label></div>\n    ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-3\"><label>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"groupLabel") || (depth0 != null ? lookupProperty(depth0,"groupLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"groupLabel","hash":{},"data":data,"loc":{"start":{"line":4,"column":38},"end":{"line":4,"column":52}}}) : helper)))
    + "</label></div>";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-2\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":79}}}))
    + "</div>";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-2\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":85}}}))
    + "</div>";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-2\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":79}}}))
    + "</div>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row apply-quick-row form-group\">\n        <div class=\"col-3 form-group\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.quicklyApplyLimits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":73}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDaily") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTrans") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBatch") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"col-1\">\n            <button class=\"btn btn-primary\" data-action=\"applyQuick\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":69},"end":{"line":33,"column":87}}}))
    + "</button>\n        </div>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-2 field-container\">\n                <label for=\"limitDaily\" class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":56},"end":{"line":16,"column":95}}}))
    + "</label>\n                <input type=\"text\" class=\"limit-field\" data-value=\"Daily\" id=\"limitDaily\">\n            </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-2 field-container\">\n                <label for=\"limitTrans\" class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":56},"end":{"line":22,"column":101}}}))
    + "</label>\n                <input type=\"text\" class=\"limit-field\" data-value=\"Transaction\" id=\"limitTrans\">\n            </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-2 field-container\">\n                <label for=\"limitBatch\" class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.limit.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":56},"end":{"line":28,"column":95}}}))
    + "</label>\n                <input type=\"text\" class=\"limit-field\" data-value=\"Batch\" id=\"limitBatch\">\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"row\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":4,"column":73}}})) != null ? stack1 : "")
    + "\n    \n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDaily") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":92}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTrans") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":98}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBatch") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":92}}})) != null ? stack1 : "")
    + "\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":36,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"list-container\"></div>";
},"useData":true});