import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import services from 'services';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import DataAPI from 'common/dynamicPages/api/data';
import SmbAPI from 'app/smb/api';
import scroll from 'common/util/scroll';
import payUtil from 'common/util/paymentUtil';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import constants from 'common/dynamicPages/api/constants';
import { log } from '@glu/core';
import $ from 'jquery';
import alertMessage from 'common/api/alertMessage';
import domUtil from 'common/util/domUtil';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import validatorPatterns from 'system/validatorPatterns';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import Formatter from 'system/utilities/format';
import Decimals from 'common/dynamicPages/api/decimals';
import Accounts from '../../transfers/collections/accounts';
import SuccessView from './simpleEntryWidgetSuccessView';
import quickTransferWidgetViewTmpl from './quickTransferWidgetView.hbs';
import quickTransferService from '../services/quickTransferWidget';

const DateModel = Model.extend({
    /**
     * make a call to the server to grab specific dates, based on specific user details
     * like bank country, currency, and other settings
     * @param {Object} qtWidget - Quick Transfer Widget instance
     * @param {Object} optionsParam - Options object
     */
    getDates(qtWidget, optionsParam) {
        const options = optionsParam;
        const { valueDate } = options;
        const currentValueDate = valueDate ? moment(valueDate, 'MM/DD/YYYY') : null;
        const isValidValueDate = currentValueDate ? currentValueDate.isValid() : false;
        const isValidDatePattern = valueDate ?
            new RegExp(validatorPatterns.DATE_PATTERN).test(valueDate) : false;
        quickTransferService
            .getDates({
                ...options,
                valueDate: (isValidValueDate && isValidDatePattern) ? valueDate : '',
            })
            .then((response) => {
                options.success = 'true';

                qtWidget.dates.set({
                    ...response,
                    CUTOFF_INFO: response.cutoffDateTimeTz,
                    emptyDateFieldBeforeInitialize: !!(!isValidValueDate || !isValidDatePattern),
                });
                // update UI with values received
                qtWidget.updateDateField();
            })
            .catch(() => {
                // update UI with default, no values retrieved from db
                options.success = 'false';
                qtWidget.updateDateField();
            });
    },
});

const QuickTransferWidget = Layout.extend({
    template: quickTransferWidgetViewTmpl,
    loadingTemplate: loadingWidgetTmpl,

    ui: {
        $creditAmount: '[name="CREDIT_AMOUNT"]',
        $debitAcctList: '[name="ACCOUNTFILTER"]',
        $beneAcctList: '[name="BENE_ACCOUNTFILTER"]',
        $dateField: '[name="VALUE_DATE"]',
        $queueTransferBtn: '[data-hook="getQueueTransferBtn"]',
        $transferRegionQuick: '[data-hook="transferRegionQuick"]',
    },

    events: {
        'click @ui.$queueTransferBtn': 'getIndicativeRate',
        'change @ui.$dateField': 'clearDateWarningMessage',
    },

    behaviors() {
        return {
            LookupHelperText: {
                getTemplateInput: this.getHelperTextTemplateInput.bind(this),
            },
        };
    },

    initialize() {
        this.context = {
            serviceName: '/payment/transfer',

            typeInfo: {
                productCode: 'RTGS',
                functionCode: 'INST',
                typeCode: 'TRANSFER',
            },
        };

        this.debitAcctList = new Accounts({
            type: 'SINGLE',
        });

        this.beneAcctList = new Accounts({
            isToAccounts: true,
            type: 'SINGLE',
        });

        this.dates = new DateModel();

        this.setNotificationData({
            title: 'QuickTransferWidget',
        });

        this.DEBITACCOUNTNUMBER = 'DEBIT_ACCOUNT_NUMBER';
        this.BENEACCOUNT = 'BENE_ACCOUNT';

        this.requestOptions = {
            ACCOUNTFILTER: {
                bankCodeKey: 'DEBIT_BANK_CODE',
                currencyKey: 'DEBIT_CURRENCY',
                accountNumberKey: this.DEBITACCOUNTNUMBER,
            },

            BENE_ACCOUNTENTITLEMENT: {
                bankCodeKey: 'BENE_BANK_CODE',
                currencyKey: 'CREDIT_CURRENCY',
                accountNumberKey: this.BENEACCOUNT,
            },
        };

        this.fxRateType = false;
        this.lookupHelperText = [];
        this.displayAvailableBalances = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') === 'true';
        this.listenTo(this.appBus, 'quick:confirmationDone', this.handleConfirmationDone);
    },

    /**
     * get datepicker position
     * @returns {string} - 'up' or 'down' based on element offset OR whether
     * widget is in an iframe
     */
    getDatePickerPosition() {
        // if widget is in an iframe return 'down'
        if (window.self !== window.top) {
            return 'down';
        }
        return $('.glu').height() - this.$el.offset().top < 300 ? 'up' : 'down';
    },

    /**
     * @method updateDateField()
     *
     * After a change to either account we make a call to the date list service. This method takes
     * the results from that service call and formats the on-screen date, date picker, cutoff text
     * and warning text.
     */
    updateDateField() {
        const {
            earliestDay,
            emptyDateFieldBeforeInitialize,
            tranDate,
            userSetDateInvalid,
            userSetValueDate,
        } = this.dates.attributes;

        /*
         * NH-170151 if a user inputs invalid data before the dates are initialized, it causes the
         * datepicker to display NaN in every single day field making it unusable
         * This adds a check to empty the field on bad initial data caught in the getDates call
         */
        if (emptyDateFieldBeforeInitialize) {
            this.ui.$dateField.val('');
        }

        /*
         * The date service checks that the user provided date is valid according to the settings
         * for the transaction type but for some reason is not checking to see if the provided
         * value date is earlier than the earliest valid date.
         */
        const isBeforeEarliest = moment(userSetValueDate, 'MM/DD/YYYY')
            .isBefore(moment(earliestDay, 'MM/DD/YYYY'));

        this.ui.$dateField.nhDatePicker({
            blockedDates: this.dates.get('holidays'),
            daysBack: 0,
            daysForward: this.dates.get('maxForwardDays'),
            showDropdowns: true,
            showCalendarIcon: true,
            processingDays: this.dates.get('businessDays'),
            drops: this.getDatePickerPosition(),
        });

        // Update the calandar so the user cannot select invalid dates.
        this.ui.$dateField.data('daterangepicker').updateCalendars({
            earliestDay: Formatter.formatDate(earliestDay),
            daysForward: this.dates.get('maxForwardDays'),
            daysBack: 0,
        });

        payUtil.invalidDateUpdatedWarning(
            this.ui.$dateField,
            userSetDateInvalid || isBeforeEarliest,
            false,
        );

        if (!userSetValueDate || userSetDateInvalid || isBeforeEarliest) {
            this.model.set({
                VALUE_DATE: Formatter.formatDate(earliestDay),
                TRAN_DATE: Formatter.formatDate(tranDate),
            });
        }

        // show cutoff, if it exists and configured to do so
        payUtil.showCutoff(this.dates.get('CUTOFF_INFO'), this.$('.ui-datepicker-trigger'), this.context.typeInfo.typeCode);

        this.setNotificationData({
            title: this.context.serviceName,
        });
    },

    /**
     * Clear out any warning messages displayed beneath the value date field.
     */
    clearDateWarningMessage() {
        this.ui.$dateField.closest('.form-group').find('.help-block').empty();
    },

    updateTranDate() {
        this.model.set({
            PRODUCT: 'RTGS',
            FUNCTION: 'INST',
            TYPE: 'TRANSFER',
        });

        const addlNVPlist = [{
            name: 'REQUESTTYPE',
            value: 'fieldValidation',
        }, {
            name: 'FIELDNAME',
            value: 'VALUE_DATE',
        }, {
            name: 'ACTION',
            value: 'INSERT',
        }];

        const item = this.model.getNameValuePairAttributes();

        const inputData = {
            item: util.union(addlNVPlist, item),
        };

        quickTransferService
            .validateTransferData(inputData)
            .then((response) => {
                const fieldsToMap = ['TRAN_DATE', 'EXCHANGE_RATE', 'MARKETCONVENTION', 'DEBIT_AMOUNT', 'CUTOFF_INFO'];
                const dataset = response.item.reduce((acc, cur) => {
                    if (fieldsToMap.includes(cur.name)) {
                        if (cur.name === 'CUTOFF_INFO' && cur.value) {
                            payUtil.showCutoff(cur.value, this.$('.ui-datepicker-trigger'), this.context.typeInfo.typeCode);
                        }

                        return {
                            ...acc,
                            [cur.name]: (cur.name === 'TRAN_DATE') ? moment(cur.value).format(userInfo.getDateFormat()) : cur.value,
                        };
                    }

                    return acc;
                }, {});

                if (Object.keys(dataset).length) {
                    this.model.set(dataset);
                }
            })
            .catch((err) => {
                log.error(err);
            });
    },

    /**
     * build data for account requests
     * @param {Object} account - model
     * @param {Object} query - query argument that comes from getFrom/ToAccounts
     */
    buildDataForAccounts(account, query = {}) {
        return {
            remove: query.page === 1,

            filter: {
                page: query.page,
                term: query.term,
                account,
            },
        };
    },

    /**
     * Handle response data for account dropdowns
     * @param {Object} query - query argument that comes from getFrom/ToAccounts
     * @param {string} collectionProp - instance prop where accounts are stored
     * @param {string} fieldName - field name to compare for match
     * @param {string} modelProp - Property of the model to reference
     * @param {Object} response - response object from request
     */
    handleDataForAccounts(query, collectionProp, fieldName, modelProp, response) {
        if (!collectionProp || !fieldName) {
            throw new Error('Missing Collection property or Field name for accounts');
        }

        this[collectionProp] = response.collection;
        const result = util.filter(response.collection.toJSON(), collItem => util.find(
            response.response,
            newItem => transform.pairsToHash(newItem.mapDataList, 'toField', 'value')[fieldName] === collItem.mapDataList[fieldName],
        ));

        // Go get the Balances for the fromAccounts
        quickTransferService.fetchBalancesForTheseAccounts(result, modelProp)
            .then((balances) => {
                // setup helper text
                this.processBalances(balances, result, modelProp);
                // merge the balances onto the lookup results
                return this.mergeBalancesOnAccounts(result, balances);
            })
            .then((theResult) => {
                query.callback({
                    results: theResult,
                    more: response.collection.hasMorePages,
                });
            })
            .catch((err) => {
                log.error(err);
            });
    },

    /**
     * get accounts based on type
     * @param {string} type - 'from' or 'to', default to 'from'
     * @param {Object} query
     */
    getAccounts(type = 'from', query = {}) {
        const isFrom = type === 'from';
        const toField = this.isTOA ? 'BENE_ACCOUNTENTITLEMENT_TOA' : 'BENE_ACCOUNTENTITLEMENT';
        const modelProp = isFrom ? 'ACCOUNTFILTER' : toField;
        const excludeProp = isFrom ? toField : 'ACCOUNTFILTER';
        const collectionProp = isFrom ? 'debitAcctList' : 'beneAcctList';
        const fieldName = isFrom ? 'ACCOUNTFILTER' : toField;
        const serviceMethod = isFrom ? 'getFromAccounts' : 'getToAccounts';

        quickTransferService[serviceMethod](this.buildDataForAccounts(
            this.model.get(excludeProp),
            query,
        )).then(this.handleDataForAccounts.bind(
            this,
            query,
            collectionProp,
            fieldName,
            modelProp,
        )).catch((err) => {
            log.error(err);
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.model.addValidator({
                VALUE_DATE: {
                    description: locale.get('payment.transfer.date'),
                    matches: this.determineValidationPattern(userInfo.getDateFormat()),
                },
            });
            this.ui.$debitAcctList.comboBox({
                query: util.debounce(this.getAccounts.bind(this, 'from'), constants.COMBO_DEBOUNCE),
            });

            this.ui.$beneAcctList.comboBox({
                query: util.debounce(this.getAccounts.bind(this, 'to'), constants.COMBO_DEBOUNCE),
            });

            // set the input mask on the amount field
            this.ui.$creditAmount.inputmask('number');
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * @description this method sets up the helper text for either all the items
     * in the account combo or for the
     *              current account (in view & modify)
     * @param balances
     * @param accounts
     */
    setupHelperText(balances, accounts) {
        let helperText;
        if (Array.isArray(balances)) {
            helperText = balances.map((balance, index) => ({
                balance: balance.balance,
                currency: accounts[index].CURRENCYCODE,
                hasBalance: balance.status === 'success',
                account: accounts[index].ACCOUNTNUM,
            }));
        } else {
            helperText = {
                balance: balances.balance,
                hasBalance: balances.status === 'success',
                currency: accounts.currency,
                account: accounts.accountNum,
            };
        }

        return helperText;
    },

    /**
     * @description Determine the validation pattern
     * based on user date format
     * @param  {string} userDateFormat - date format
     */
    determineValidationPattern(userDateFormat) {
        if (userDateFormat === 'YYYY/MM/DD') {
            return validatorPatterns.YYYY_MM_DD_ISO_8601_SLASHES_PATTERN;
        }
        return userDateFormat === 'YYYY-MM-DD' ? validatorPatterns.YYYY_MM_DD_PATTERN : validatorPatterns.DATE_PATTERN;
    },

    /**
     * Override for lookupHelperText getHelperTextTemplateInput function
     * @param  {string} id Identifier for field
     * @return {object} Helper text in object
     */
    getHelperTextTemplateInput(id) {
        let helperText = this.lookupHelperText[id];
        const helperTextObj = {};
        let fetchBalance = false;

        const accountNum = this.model.get((id === 'ACCOUNTFILTER') ? 'DEBIT_ACCOUNT_NUMBER' : 'BENE_ACCOUNT');

        if (this.displayAvailableBalances) {
            if (!util.isEmpty(helperText)) {
                if (Array.isArray(helperText)) {
                    helperText = util.findWhere(
                        helperText,
                        {
                            account: accountNum,
                        },
                    );
                }
                if (helperText) {
                    util.each(helperText, (value, prop) => {
                        helperTextObj[prop] = value;
                    });
                } else {
                    fetchBalance = true;
                }
            } else {
                fetchBalance = true;
            }
        } else {
            helperTextObj.noDisplay = true;
        }

        this.fetchBalance(fetchBalance, accountNum, id);

        return helperTextObj;
    },

    fetchBalance(fetchBalance, accountNum, id) {
        let balanceRequest;
        const options = this.requestOptions[id];

        // fetch the balance of the selected account if it is not already available
        if (this.displayAvailableBalances && fetchBalance) {
            balanceRequest = {
                productCode: 'RTGS',
                functionCode: 'INST',
                typeCode: 'TRANSFER',
                bankCode: this.model.get(options.bankCodeKey),
                accountNumber: accountNum,
                currency: this.model.get(options.currencyKey),
            };

            quickTransferService.getBalanceForAccount(balanceRequest).then((balanceData) => {
                this.processBalances(
                    balanceData,
                    {
                        currency: balanceRequest.currency,
                        accountNum,
                    },
                    id,
                );
                this.updateSelectedAccountBalance(
                    balanceData.balance,
                    `${balanceRequest.bankCode}-${balanceRequest.accountNumber}`,
                    id === 'ACCOUNTFILTER' ? this.ui.$debitAcctList : this.ui.$beneAcctList,
                    id === 'ACCOUNTFILTER' ? this.debitAcctList.models : this.beneAcctList.models,
                );
                this.trigger('lookupHelperText:update', id);
            });
        }
    },

    updateSelectedAccountBalance(balance, id, listElement, listModels) {
        const selectedModel = util.find(listModels, listModel => listModel.id === id);
        selectedModel.balance = balance;
        const data = {
            ...listElement.comboBox('data'),
            text: `${listElement.comboBox('data').text} - ${balance} ${locale.get('smbPayments.available')}`,
        };
        listElement.comboBox('data', data);
        listElement.trigger('change');
    },

    /**
     * @description merges the account balances into the label property for each account
     * @param accounts
     * @param balances
     */
    mergeBalancesOnAccounts(accounts, balances) {
        return accounts.map((itemParam, index) => {
            const item = itemParam;
            let label = item.text;

            if (balances && balances[index] && balances[index].status === 'success') {
                label = `${label} : ${balances[index].balance} ${item.CURRENCYCODE} ${locale.get('common.available')}`;
            }
            item.text = label;
            return item;
        });
    },

    processDateUpdate() {
        // grab all information that will provide query data for date options
        let acctFilter;
        let builtAcctFilter;

        if (this.debitAcctList && this.debitAcctList.models) {
            for (let i = 0, l = this.debitAcctList.models.length; i < l; i += 1) {
                const mapData = this.debitAcctList.models[i].get('mapDataList');
                if (!util.isEmpty(mapData)) {
                    this.options.currency = mapData.DEBIT_CURRENCY;
                    this.options.debitCountry = mapData.DEBIT_COUNTRY;
                    this.options.debitBank = mapData.DEBIT_BANK_CODE;
                    builtAcctFilter = `${this.options.debitBankId}`;
                    acctFilter = mapData.ACCOUNTFILTER;
                    if (acctFilter === builtAcctFilter) {
                        break;
                    }
                }
            }
        }

        // populate the bene(credit) information if available
        this.options.creditCurrency = this.model.get('CREDIT_CURRENCY');
        this.options.creditBankCountry = this.model.get('BENE_BANK_COUNTRY');
        this.options.beneBankId = this.model.get('BENE_ACCOUNT');
        this.options.beneBankType = this.model.get('BENE_BANK_TYPE');
        this.options.beneBankType = this.model.get('BENE_BANK_TYPE');
        this.options.tranDate = this.model.get('TRAN_DATE');
        this.options.valueDate = this.model.get('VALUE_DATE');

        if (this.model.get('ACCOUNTFILTER') && this.model.get('BENE_ACCOUNTENTITLEMENT')) {
            this.dates.getDates(this, this.options);
        }
    },

    /**
     * This method updates the format for the credit amount based on the credit currency. This is
     * important for foreign currencies with decimal places other than two. Here we start with a
     * standard options object for number format and overwrite the decimal property. This method is
     * called whenever the To Account is changed affecting the credit currency.
     */
    processAmountFormat() {
        const format = {
            ...Formatter.getCurrencyMaskOptions(true, true),
            digits: Decimals.getNumberOfDecimalPlaces(this.model.get('CREDIT_CURRENCY')),
        };

        this.ui.$creditAmount.inputmask('number', format);
    },

    onFormErrorsChange() {
        [
            'ACCOUNTFILTER',
            'BENE_ACCOUNTENTITLEMENT',
            'CREDIT_AMOUNT',
            'VALUE_DATE',
        ].forEach((fieldName) => {
            const $targetParent = this.$(`[data-validate=${fieldName}]`).parent();
            let $targetField = $targetParent.find(`input[name=${fieldName}]`);
            const isSelect2 = fieldName === 'BENE_ACCOUNTENTITLEMENT'
                || $targetField.attr('type') === 'hidden';

            if (isSelect2) {
                $targetField = $targetParent.find('input.select2-focusser');
            }
            const hasFieldError = Object.keys(this.model.get('error') || {}).includes(fieldName);
            $targetField.attr('aria-invalid', hasFieldError ? 'true' : 'false');
        });
    },

    loadRequiredData() {
        const options = {
            context: this.context,
            state: 'insert',
        };

        const fxRateTypePromise = this.getCompanyRTGSPreferences();
        fxRateTypePromise.then((result) => {
            this.fxRateType = result.fxRateType;
        });

        quickTransferService
            .getEntitlements({
                options,
            })
            .then((response) => {
                if (response.model.isEntitled('insert')) {
                    DataAPI.model.generate(options, false).then((results) => {
                        this.model = results;
                        this.listenTo(this.model, 'change:ACCOUNTFILTER', this.debitAccountNumberChange);
                        this.listenTo(this.model, 'change:BENE_ACCOUNTFILTER', this.beneAccountNumberChange);
                        this.listenTo(this.model, 'change:VALUE_DATE', this.updateTranDate);
                        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
                        this.listenTo(this.model, 'change:error', this.onFormErrorsChange);

                        this.model.validators = {
                            ACCOUNTFILTER: {
                                description: locale.get('payment.transfer.from'),
                                exists: true,
                            },

                            BENE_ACCOUNTENTITLEMENT: {
                                description: locale.get('payment.transfer.to'),
                                exists: true,
                            },

                            CREDIT_AMOUNT: {
                                description: locale.get('payment.amount'),
                                exists: true,
                                matches: SmbAPI.AMOUNT_PATTERN,
                            },

                            VALUE_DATE: {
                                description: locale.get('payment.transfer.date'),
                                exists: true,
                            },
                        };

                        this.setHasLoadedRequiredData(true);
                        this.render();
                        this.notifyPageLoaded();
                    });
                } else {
                    this.displayNoTransferEntitlements();
                }
            })
            .catch((err) => {
                log.error(err);
            });
    },

    displayNoTransferEntitlements() {
        this.$el.text(locale.get('payment.transfer.permission.error'));
    },

    /**
     * @name convertModelAttributesToJSON
     * @description transform the model attributes to an array of name values pairs
     * @param model
     * @returns {{item: array of NameValuePairs}}
     */
    convertModelAttributesToServerJSON(model) {
        const modelAttributes = model.toJSON();
        let item = transform.hashToPairs(modelAttributes, 'name', 'value', true);
        item = item.filter(i => !util.isEmpty(i.value));
        return {
            item,
        };
    },

    /**
     * @returns {object} request object for the save
     */
    collectData() {
        const saveWithWarning = this.model.get('_saveWithWarning') || false;
        return {
            functionCode: 'INST',
            setEntryMode: 'SINGLE',
            items: [this.convertModelAttributesToServerJSON(this.model)],
            saveWithWarning,
        };
    },

    submitSuccess(response) {
        this.options.serverResults = response;
        DataAPI.model.generate({
            context: this.context,
            state: 'insert',
        }, false).then(() => {
            const TRANSACTION_ID = this.options.serverResults
                .confirms.confirmResults[0].confirmData[0].item[0].value;
            this.model.set({
                ENTRY_TYPE: 'transfer',
                TRANSACTION_ID,
            });
            if ($('.TransferWidget').length === 1) {
                this.appBus.trigger('rtp:quickPaymentSuccess', this.model, response);
            } else {
                this.handleQuickSubmitSuccess(this.model, response);
            }
            this.model.clear();
        });
        this.ui.$debitAcctList.comboBox('data', null);
        this.ui.$beneAcctList.comboBox('data', null);
        this.enableButtons();
        this.resetAccountDropdowns();
        this.ui.$dateField.siblings('[data-hook="cutoff"]').find('span').empty();
        payUtil.invalidDateUpdatedWarning(this.ui.$dateField, false, false);
    },

    submitError(response) {
        let errors;
        if (response.responseJSON.errorCode === constants.DUPLICATE_ERROR_CODE) {
            const duplicateDialog = new DuplicateDialog({
                resp: response.responseJSON,
                model: this.model,
                methodName: 'INSERT',
                isQuickTransfer: true,
            });
            duplicateDialog.once('saveQuickTransferWithWarning', this.saveQuickTransferWithWarning, this);
            dialog.custom(duplicateDialog);
        } else if (response.responseJSON.resultType === 'WARNING') {
            const warningDialog = new WarningDialog({
                models: response,
                methodName: 'SAVE',
                confirms: response.responseJSON.confirms,
                isQuickTransfer: true,
            });
            warningDialog.once('saveWithWarning', this.saveQuickTransferWithWarning, this);
            dialog.custom(warningDialog);
        } else {
            errors = response.responseJSON.confirms
            && response.responseJSON.confirms.confirmResults.length > 0
                ? response.responseJSON.confirms.confirmResults[0].messages
                : response.responseJSON.message;
            this.alertRegion.show(alert.danger(
                errors.join(' '),
                {
                    canDismiss: true,
                    title: response.responseJSON.message.join(' '),
                },
            ));
        }
        this.enableButtons();
    },

    /**
     * This is a promise function that will call InquiryService to get the FXRateType
     * for the company .
     * The FXRAteType is used to determine if indicative rate should be retrieved
     * based on usergroup rate entitlements
     */
    getCompanyRTGSPreferences() {
        const url = services.generateUrl('/inquiry/getData');
        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40011,
                },
            },
        };

        //
        return new Promise((resolve) => {
            http.post(url, postData, (data) => {
                let fxRateType = false;

                if (data.inquiryResponse.rows[0].columns[0].fieldName === 'FXRATETYPE'
                    && data.inquiryResponse.rows[0].columns[0].fieldValue === '1') {
                    fxRateType = true;
                }
                resolve({
                    fxRateType,
                });
            }, () => {
                // handle errors
                alertMessage.renderMessage(this, locale.get('common.fxRateType.data.error'), locale.get('common.fxRateType.data.error'));
            });
        });
    },

    getExchangeRateData() {
        this.model.set({
            PRODUCT: 'RTGS',
            FUNCTION: 'INST',
            TYPE: 'TRANSFER',
            ENTERED_AMOUNT_FLAG: this.model.get('DBCR_FLAG') || 'C',
        });

        const addlNVPlist = [{
            name: 'REQUESTTYPE',
            value: 'fieldValidation',
        }, {
            name: 'FIELDNAME',
            value: 'CREDIT_AMOUNT',
        }, {
            name: 'ACTION',
            value: 'INSERT',
        }];

        const item = this.model.getNameValuePairAttributes();

        const inputData = {
            item: util.union(addlNVPlist, item),
        };

        return inputData;
    },

    /**
     * Indicative rate and amount promise
     * @return {Promise}
     */
    getIndicativeRatePromise() {
        const amt = this.model.get('CREDIT_AMOUNT');
        const url = services.generateUrl(constants.URL_GETINDICATIVERATE_ACTION);
        const type = this.model.get('TYPE') || 'TRANSFER';
        const actionMode = 'INSERT';
        const enteredAmountFlag = this.model.get('DBCR_FLAG') || 'C';
        const creditCurrency = this.model.get('CREDIT_CURRENCY') || ' ';
        const debitCurrency = this.model.get('DEBIT_CURRENCY') || ' ';
        const debitAmount = amt || 1;
        const creditAmount = amt || 1;
        const status = this.model.get('STATUS') || 'EN';
        const valueDate = this.model.get('VALUE_DATE');

        const data = {
            enteredAmountFlag,
            creditCurrency,
            debitCurrency,
            debitAmount,
            creditAmount,
            valueDate,
            status,
            typeCode: type,
            actionMode,
        };

        return new Promise((resolve) => {
            http.post(url, data, (result) => {
                this.model.set({
                    INDICATIVERATE: result.indicativeRate,
                    INDICATIVEAMOUNT: result.indicativeAmount,
                });
                resolve({
                    result,
                });
            });
        });
    },

    /**
     * Retrieve the indicative rate and amount
     * For transfer payment
     */
    getIndicativeRate() {
        if (this.model.get('CREDIT_CURRENCY') === this.model.get('DEBIT_CURRENCY')
            || !this.model.isValid()) {
            this.saveTransfer();
            return;
        }

        this.disableButtons(true);

        if (!this.fxRateType) {
            // Exchange Rate - Table Rate
            quickTransferService
                .validateTransferData(this.getExchangeRateData())
                .then((response) => {
                    const fieldsToMap = ['EXCHANGE_RATE', 'MARKETCONVENTION', 'DEBIT_AMOUNT'];
                    const dataset = response.item.reduce((acc, cur) => {
                        if (fieldsToMap.includes(cur.name)) {
                            return {
                                ...acc,
                                [cur.name]: cur.value,
                            };
                        }
                        return acc;
                    }, {});

                    if (Object.keys(dataset).length) {
                        this.model.set(dataset);
                    }

                    this.saveTransfer();
                })
                .catch((err) => {
                    this.alertRegion.show(alert.negative(locale.get('pay.error.NoExchangeRate')), {
                        canDismiss: true,
                    });
                    log.error(err);
                });
        } else {
            // Indicative Rate - Real Time Rate
            const indicativeRatePromise = this.getIndicativeRatePromise();

            indicativeRatePromise.then(() => {
                this.saveTransfer();
            }).catch((err) => {
                this.alertRegion.show(alert.negative(locale.get('pay.error.NoIndicativeRate')), {
                    canDismiss: true,
                });
                log.error(err);
            });
        }
    },

    saveTransfer() {
        if (this.model.isValid()) {
            this.disableButtons(true);

            const data = this.collectData();
            http.post(
                services.generateUrl(`${constants.URL_QUICKTRANSFER_SAVE}/ADD`),
                data,
                (response) => {
                    this.submitSuccess(response);
                },
                (response) => {
                    this.submitError(response);
                },
            );
        }
    },

    /*
     * @method saveQuickTransferWithWarning
     * Adds the warning accepted values to the model and then initiates a save.
     * @param {boolean} duplicateAccepted - indicates if the user has just accepted a
     * duplicate warning, or a regular warning.
     */
    saveQuickTransferWithWarning(duplicateAccepted) {
        if (duplicateAccepted) {
            this.model.set('duplicateAccepted', 'true');
            this.model.validators.DUPLICATEREASON = null;
        } else {
            this.model.set('_saveWithWarning', true);
        }
        this.saveTransfer();
    },

    disableButtons() {
        // TODO: should we really be disabled all buttons or pass specific buttons??
        domUtil.setDisabled($('.btn'), true);
    },

    enableButtons() {
        // TODO: should we really be disabled all buttons or pass specific buttons??
        domUtil.setDisabled($('.btn'), false);
    },

    resetAccountDropdowns() {
        this.ui.$debitAcctList.comboBox('data', null);
        this.ui.$beneAcctList.comboBox('data', null);
    },

    /**
     *
     * @param {Object} model
     * @param {string} newValue
     */
    debitAccountNumberChange(model, newValue) {
        this.setAccountMapDataList(newValue, this.debitAcctList, 'ACCOUNTFILTER');
        /*
         * set the isTOA property for this view and for the quickTransferService model
         * the isTOA property is used to determine which query field is used to retrieve
         * the 'to accounts' list
         */
        this.isTOA = this.model.get('ACCOUNTSUBTYPE') === 'TO';
        quickTransferService.setIsTOA(this.isTOA);
        this.processDateUpdate();
    },

    /**
     *
     * @param {Object} model
     * @param {string} newValue
     */
    beneAccountNumberChange(model, newValue) {
        this.setAccountMapDataList(newValue, this.beneAcctList, 'BENE_ACCOUNTENTITLEMENT');
        this.processDateUpdate();
        this.processAmountFormat();
    },

    /**
     * @description processes the account balances and stores in the array eliminating
     * the duplicates
     * @param balances current balances
     * @param result  new balances
     * @param fieldName Debit account number or bene account number
     */
    processBalances(balances, result, fieldName) {
        const tempHelperText = this.setupHelperText(balances, result);
        if (!util.isEmpty(tempHelperText)) {
            if (util.isNullOrUndefined(this.lookupHelperText[fieldName])) {
                this.lookupHelperText[fieldName] = [];
            }

            if (Array.isArray(tempHelperText)) {
                this.lookupHelperText[fieldName] = util.union(
                    this.lookupHelperText[fieldName],
                    tempHelperText,
                );
            } else {
                this.lookupHelperText[fieldName].push(tempHelperText);
            }
            this.lookupHelperText[fieldName] = util.uniq(
                this.lookupHelperText[fieldName],
                false,
                p => p.account,
            );
        }
    },

    /**
     *
     * @param {string} newValue
     * @param {Object} acctList
     * @param {string} fieldName
     */
    setAccountMapDataList(newValue, acctList, fieldName) {
        let localNewValue = newValue;
        const accountType = fieldName === 'ACCOUNTFILTER'
            ? this.ui.$debitAcctList : this.ui.$beneAcctList;
        const triggerType = util.isEmpty(accountType.comboBox('data'))
            ? 'clear' : 'update';
        let override = false;
        if (localNewValue === '') {
            localNewValue = this.model.previous(fieldName);
            override = true;
        }
        acctList.each((acctModel) => {
            if (acctModel.get('ACCOUNTFILTER') === localNewValue) {
                const setObj = util.extend({}, acctModel.get('mapDataList'));
                util.each(setObj, (value, key, objParam) => {
                    const obj = objParam;
                    obj[key] = override ? '' : value;
                });
                this.model.set(setObj);
            }
        });
        if (fieldName === 'ACCOUNTFILTER') {
            this.options.debitBankId = localNewValue;
        }

        this.trigger(`lookupHelperText:${triggerType}`, fieldName);
    },

    templateHelpers() {
        return {
            cid: this.model && this.model.cid ? this.model.cid : '',
            debitAcctList: () => this.debitAcctList.toJSON(),
            beneAcctList: () => this.beneAcctList.toJSON(),
        };
    },
    handleQuickSubmitSuccess(model, response) {
        const options = {
            isTransfer: true,
            model,
            response,
        };
        this.ui.$transferRegionQuick.addClass('hidden');
        // create view for success page
        this.successRegionQuick.show(new SuccessView(options));
    },
    handleConfirmationDone() {
        this.ui.$transferRegionQuick.removeClass('hidden');
        this.appBus.trigger('rtp:handleConfirmationDone');
    },
});

export default QuickTransferWidget;
