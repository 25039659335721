import Layout from 'no-override!@glu/core/src/layout';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import FieldFuzzySearch from '@glu/grid/src/filter/fieldFuzzySearch';
import GridFuzzySearch from '@glu/grid/src/filter/gridFuzzySearch';
import EnumSearch from '@glu/grid/src/filter/enumSearch';
import NumericSearch from '@glu/grid/src/filter/numericSearch';
import AmountSearch from 'system/gridOverride/filter/amountSearch';
import NumberRangeSearch from '@glu/grid/src/filter/numberRangeSearch';
import DateRangeSearch from '@glu/grid/src/filter/dateRangeSearch';
import MultiSelectSearch from '@glu/grid/src/filter/multiSelectSearch';
import Model from '@glu/core/src/model';
import ItemView from '@glu/core/src/itemView';
import tpl from './fieldTypeSearch2.hbs';
import selectFilterTpl from './selectFilterField.hbs';

const FilterFieldModel = Model.extend({
    defaults: {
        name: 'fieldname',
        type: '',
    },
});

const SelectFilterItemView = ItemView.extend({
    template: selectFilterTpl,
});

function filterableColumns(column) {
    return !(column.has('filterable') && !column.get('filterable'));
}

function getVisibleColumn(column) {
    return !(column.has('condition') && !column.get('condition'));
}

export default Layout.extend({
    template: tpl,
    className: 'grid-filter-selection',

    events: {
        'click @ui.$removeBtn': 'handleRemoveFilter',
        'change @ui.$checkbox': 'handleCheckbox',
    },

    regions: {
        searchContainer: '[data-control=searchContainer]',
        select: '.region-grid-select-filter',
    },

    collectionEvents: {
        'reset remove add change:condition': 'hideSearchView render',
    },

    ui: {
        $removeBtn: 'button[data-control="removeFilter"]',
        $checkbox: 'input[data-control="filter-checkbox"]',
    },

    initialize() {
        this.filtersCollection = this.options.filtersCollection;
        if (this.options.model) {
            this.searchView = this.getSearchViewByType(this.options.model.get('type'), this.options.model);
        }

        this.first = this.options.first || false;

        if (this.options.masterCollection) {
            this.listenTo(this.options.masterCollection, 'grid:externalFilter', function () {
                this.hideSearchView();
            });
        }

        if (!this.filtersCollection) {
            return;
        }

        const filterFieldModel = new FilterFieldModel({
            name: this.getTitle(this.options.id),
            type: 'string',
        });
        this.selectFilterItemView = new SelectFilterItemView({
            model: filterFieldModel,
        });
        this.listenTo(this.filtersCollection, 'grid:reopen:filter', this.reopenFilter);
        this.listenTo(this, 'filter:clear', this.handleClearFilter);
    },

    getTitle(id) {
        const title = this.getModelByField(id).get('title') || '-';
        return title;
    },

    getDropDownData() {
        const itemsCollection = new Collection(this.collection.filter(filterableColumns));
        const visibleColumns = itemsCollection.filter(getVisibleColumn);
        const data = [];

        const hiddenColumns = itemsCollection.where({
            condition: false,
        });

        const mapper = function (item) {
            const id = item.get('field') || '';
            return {
                id,
                name: item.get('title') || id,
            };
        };

        if (this.options.gridSearch) {
            data.unshift({
                id: 'table',
                name: locale.get('entireTable'),
            });
        }

        data.push({
            id: 'visibleColumns',
            name: '',
            child: util.map(visibleColumns, mapper),
        });

        if (!hiddenColumns.length) {
            return data;
        }

        data.push({
            id: 'hiddenColumns',
            name: locale.get('notCurrentlyDisplayed'),
            child: util.map(hiddenColumns, mapper),
        });

        return data;
    },

    reopenFilter(filter) {
        this.handleSelectType(filter.get('field'));
    },

    /**
     * React to changes in the filter dropdown.
     *
     * @param ids type Array
     * @returns {*}
     */
    handleSelectType(ids) {
        let model;
        let grid;
        const [field] = ids;

        if (!field) {
            // user selected the default value (ie, 'Select fields') or click clear button.
            this.hideSearchView();
        } else if (field === 'table') {
            // user selected the 'entire table' option.
            this.selectSearchByType('table', {});
        } else {
            model = this.getModelByField(field);
            ({ grid } = this.options);

            this.selectSearchByType(model.get('type'), model, grid);
        }

        return field;
    },

    getModelByField(field) {
        const model = this.collection.find(column => field === column.get('field'));

        const filterModel = this.filtersCollection.find(filter => field === filter.get('field'));

        if (!filterModel) {
            return model.clone();
        }

        /*
         * need to merge filter model with columns model as columns model has additional filter
         * parameters
         */
        filterModel.set(
            util.extend(model.toJSON(), filterModel.toJSON()),
            {
                // to avoid re-rendering
                silent: true,
            },
        );

        return filterModel.clone();
    },

    selectSearchByType(type, model, grid) {
        let localType = type;
        const view = this.getSearchViewByType(localType, model, grid);
        localType = localType || 'string';
        this.setSearchView(view);
        this.searchContainer.show(view);
        this.$(this.ui.$removeBtn.selector).hide();
    },

    setSearchView(view) {
        const viewEl = this.$(this.searchContainer.el);
        if (this.searchView) {
            viewEl.removeClass(this.searchView.className);
        }

        this.searchView = view;
        viewEl.addClass(this.searchView.className);

        this.listenTo(this.searchView, 'filter', this.handleSearchViewFilter);
    },

    disable() {
        this.disabled = true;
        this.$('.flex-dropdown .selection').addClass('disabled');
        if (this.searchView) {
            this.searchView.disable();
        }
    },

    enable() {
        this.disabled = false;
        this.$('.flex-dropdown .selection').removeClass('disabled');
        if (this.searchView) {
            this.searchView.enable();
        }
    },

    toggle() {
        return this.isDisabled() ? this.enable() : this.disable();
    },

    isDisabled() {
        return this.disabled === true;
    },

    handleRemoveFilter() {
        this.trigger('filter:remove', this);
    },

    handleSearchViewFilter(filter) {
        this.trigger('filter', filter);
    },

    onBeforeRender() {},

    onRender() {
        this.showSelectFilterItemView();
        const $checkbox = this.$('[data-control="filter-checkbox"]');

        if (this.searchView && this.searchView.filterValue === undefined) {
            this.searchContainer.show(this.searchView);
            $checkbox.prop('checked', true);
            this.showSearchView();
        }
    },

    initializeDropdown() {
        if (this.dropdown) {
            this.dropdown.close();
        }
        this.dropdown = this.createDropdown();
    },

    showFilterSelection() {

    },

    showSelectFilterItemView() {
        this.select.show(this.selectFilterItemView);
    },

    getSearchViewByType(type, model, grid) {
        let view;

        switch (type) {
        case 'table':
            view = new GridFuzzySearch();
            break;
        case 'date':
            view = new DateRangeSearch({
                model,
                dateFormat: model.get('dateFormat'),
            });
            view.setField(model.get('field'));
            break;
        case 'enum':
            view = new EnumSearch({
                model,
            });
            break;
        case 'number':
            view = new NumericSearch({
                model,
            });
            break;
        case 'numberRange':
            view = new NumberRangeSearch({
                model,
            });
            break;
        case 'amount':
            view = new AmountSearch({
                model,
            });
            break;
        case 'multiselect':
            view = new MultiSelectSearch({
                model,
                grid,
            });
            break;
        case 'string':
        default:
            view = new FieldFuzzySearch({
                model,
                collection: this.collection,
            });
            break;
        }

        this.listenTo(
            view,
            {
                filter: this.hideSearchView,
                'filter:clear': this.clearFilter,
                'filter:remove': this.handleRemoveFilter,
            },
        );

        return view;
    },

    // does the manual checkbox clear
    handleClearFilter() {
        const $checkbox = this.$('input[data-control="filter-checkbox"]');
        $checkbox.prop('checked', false); // triggerHandler('click');
        this.handleCheckbox();
    },

    /**
     *  if checkbox is selected show searchview else  searchview.
     */

    handleCheckbox() {
        const $checkbox = this.$('input[data-control="filter-checkbox"]');

        if ($checkbox.prop('checked')) {
            this.showSearchView();
        } else {
            this.hideSearchView();
        }
        this.trigger('filter:updateCounter');
    },

    showSearchView() {
        const ids = [this.options.id];
        this.handleSelectType(ids);
    },

    /**
     * Remove the existing search view (if present), leaving only the filter
     * dropdown visible.
     */
    hideSearchView() {
        if (this.isClosed || !this.searchView) {
            return;
        }

        this.searchView.close();
        this.searchView = null;
        if (this.dropdown) {
            this.dropdown.setSelectedIds('');
        }
    },

    clearFilter(view) {
        if (view.model) {
            const field = view.model.get('field');

            if (this.options.masterCollection) {
                this.options.masterCollection.trigger(`grid:clear:filter:${field}`, this);
            }

            this.options.filterProc.removeFilters(field);
        }
        this.hideSearchView();
    },

    onClose() {
        if (!this.dropdown) {
            return;
        }

        this.dropdown.close();
    },
});
