var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel panel-secondary\">\n    <div class=\"panel-heading\" role=\"tab\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOXCODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOXCODE","hash":{},"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":2,"column":61}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":82}}}) : helper)))
    + "\">\n        <h4 class=\"panel-title\">\n            <a role=\"button\" class=\"collapsed\" data-toggle=\"collapse\" href=\"#collapseSummary-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOXCODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOXCODE","hash":{},"data":data,"loc":{"start":{"line":4,"column":93},"end":{"line":4,"column":108}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":4,"column":108},"end":{"line":4,"column":129}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"collapseSummary-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOXCODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOXCODE","hash":{},"data":data,"loc":{"start":{"line":4,"column":184},"end":{"line":4,"column":199}}}) : helper)))
    + "\">\n                <span class=\"indicator-icon\"></span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.Lockbox",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":76}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOXCODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOXCODE","hash":{},"data":data,"loc":{"start":{"line":5,"column":77},"end":{"line":5,"column":92}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE","hash":{},"data":data,"loc":{"start":{"line":5,"column":94},"end":{"line":5,"column":110}}}) : helper)))
    + ") "
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":5,"column":112},"end":{"line":5,"column":120}}}) : helper)))
    + "\n            </a>\n        </h3>\n\n        <div class=\"panel-totals\">\n            <div class=\"totals-wrapper\">\n                <h5>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.TotalItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":47}}}))
    + "</h5>\n                <span data-hook=\"summary-num-payments\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NUM_PAYMENTS") || (depth0 != null ? lookupProperty(depth0,"NUM_PAYMENTS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NUM_PAYMENTS","hash":{},"data":data,"loc":{"start":{"line":12,"column":55},"end":{"line":12,"column":71}}}) : helper)))
    + "</span>\n            </div>\n            <div class=\"totals-wrapper\">\n                <h5>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.TotalAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":48}}}))
    + "</h5>\n                <span data-hook=\"summary-total-amount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"TOTAL_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":16,"column":55},"end":{"line":16,"column":71}}}) : helper)))
    + "</span>\n            </div>\n        </div>\n    </div>\n    <div id=\"collapseSummary-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOXCODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOXCODE","hash":{},"data":data,"loc":{"start":{"line":20,"column":29},"end":{"line":20,"column":44}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":20,"column":44},"end":{"line":20,"column":65}}}) : helper)))
    + "\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-expanded=\"false\" aria-labelledby=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOXCODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXCODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOXCODE","hash":{},"data":data,"loc":{"start":{"line":20,"column":154},"end":{"line":20,"column":169}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":20,"column":169},"end":{"line":20,"column":190}}}) : helper)))
    + "\">\n        <div class=\"panel-body\">\n            <div data-region=\"gridRegion\"></div>\n            <a href=\"#\" data-action=\"viewTransactionDetails\" class=\"btn btn-tertiary\"></a>\n        </div>\n    </div>\n</div>\n";
},"useData":true});