export function combineColumnData(columnDefs, columns) {
  const columnMap = columnDefs.reduce((map, columnDef) => {
    map.set(columnDef.field, columnDef);
    return map;
  }, new Map());

  return columns.map(column => ({
    ...columnMap.get(column.colId),
    ...column
  }));
}

export default combineColumnData;
