var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":24}}}) : helper)))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.backToPreviousPage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":191}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"stateDescription") || (depth0 != null ? lookupProperty(depth0,"stateDescription") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"stateDescription","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":51}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.E-Learning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":80}}}))
    + "</h1>\n</div>\n\n<fieldset class=\"section section-container\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataWidgetId\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.elearning.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":42},"end":{"line":13,"column":74}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":55}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.elearning.language",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":19,"column":76}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"language") || (depth0 != null ? lookupProperty(depth0,"language") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"language","hash":{},"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":20,"column":59}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<fieldset class=\"section section-container\">\n    <div class=\"section-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.elearning.content",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":32},"end":{"line":27,"column":67}}}))
    + "</div>\n    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"elearningContentModels") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":31,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n</fieldset>\n";
},"useData":true});