import moment from 'moment';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import DataAPI from 'common/dynamicPages/api/data';
import { log } from '@glu/core';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import scroll from 'common/util/scroll';
import { createTransformLocaleView } from 'components/TransformLocale/TransformLocaleWrapper';
import { getMaskingConfiguration, maskValue } from 'common/util/maskingUtil';
import MDFMultiAddLayoutView from 'app/multiAdd/views/mdfMultiAddLayoutView';
import RtpMultiAddCollectionView from './rtpMultiAddCollectionView';
import RtpMultiItemView from './rtpMultiItemView';
import rtpMultiAddViewTmpl from './rtpMultiAddView.hbs';

export default MDFMultiAddLayoutView.extend({
    template: rtpMultiAddViewTmpl,

    regions: {
        typeToggleRegion: '.type-toggle-region',
    },

    ui: util.extend(
        {},
        MDFMultiAddLayoutView.prototype.ui,
        {
            $saveAsTemplate: '[data-hook="saveAsTemplate"]',
            $summaryAmountTotal: '[data-hook="getSummaryAmountTotal"]',
            $currency: '[data-hook="getCurrency"]',
        },
    ),

    events: util.extend(
        {},
        MDFMultiAddLayoutView.prototype.events,
        {
            'click [name="SAVEASTEMPLATE"]': 'toggleTemplateFields',
        },
    ),

    initialize(options) {
        this.maskingConfig = getMaskingConfiguration();

        MDFMultiAddLayoutView.prototype.initialize.call(
            this,
            {
                ...options,
                collectionView: RtpMultiAddCollectionView,
                itemView: RtpMultiItemView,
            },
        );

        this.listenTo(this.collection, 'amountUpdated', this.updateSummaryTotal);
        this.listenTo(this.collection, 'currencyUpdated', this.updateSummaryTotal);
        this.listenTo(this.collection, 'legalEntityError', this.toggleLegalEntityError);
        this.listenTo(this.collection, 'paymentRemoved add', this.amountOfPaymentsModified);
    },

    additionalRender() {
        this.displaySummaryTotal();
    },

    /**
     * @name saveItems
     * @description builds off of the already existing 'saveItems' function within
     * mdfMultiAddLayoutView by adding local form values to the collection when relevant
     */
    saveItems() {
        // the form can be saved as a template if only one payment is present
        if (this.collection.length === 1) {
            this.options.saveAsTemplate = this.collection.at(0).get('SAVEASTEMPLATE');
            this.options.restrict = this.collection.at(0).get('RESTRICTTEMPLATE_FLAG') === '1';
        } else {
            this.options.saveAsTemplate = '0';
            this.options.restrict = '';
        }
        MDFMultiAddLayoutView.prototype.saveItems.call(this);
    },

    /**
     * @name saveDraft
     * @description initiates a save with the incomplete flag. Saved items will be in the
     * 'Incomplete' (IC) status. Also clears out any template details that may have been entered
     */
    saveDraft() {
        // clear out template data
        this.collection.at(0).set({
            SAVEASTEMPLATE: '0',
            RESTRICTTEMPLATE_FLAG: '0',
            TEMPLATE_CODE: '',
            TEMPLATE_DESCRIPTION: '',

        }, { silent: true });

        // remove associated template data validators to keep the form from not submitting
        this.collection.at(0).removeValidator(['TEMPLATE_CODE', 'TEMPLATE_DESCRIPTION']);

        MDFMultiAddLayoutView.prototype.saveDraft.call(this);
    },

    /**
     * sets the initial state for the form after all data is loaded
     * @param model {object} - the forms first model
     */
    afterRequiredDataIsLoaded(model) {
        // get the max number of items that can be added
        this.maxRows = model.jsonData.multiEntryMaxRows;

        // store the default date in case the user clears the form
        this.defaultDate = model.jsonData.fieldInfoList.filter(property => property.name === 'VALUE_DATE')[0].value;

        this.baseModel = model;

        // add first item to the collection
        this.initialAddToCollection(this.deepCloneModel(model), true);

        this.setHasLoadedRequiredData(true);

        this.render();
    },

    /**
     * @name loadRequiredData
     * @description creates a promise to retrieve the mdf model and the stoptype
     * toggle data.
     * When the promise is resolved, render the view
     */
    loadRequiredData() {
        DataAPI.model.generate({
            context: this.contextDef,
            state: 'insert',
            hideButtons: true,
            gridApi: {},
        }).then(model => this.afterRequiredDataIsLoaded(model)).catch((err) => {
            log.error(err);
        });
    },

    /**
     * @name deepCloneModel
     * @description clones the mdf model so that each item view is associated
     * with a fresh model
     * removes the serialnum_to validator b/c that is specific for each model
     * @param model
     * @returns {* clone of mdf model}
     */
    deepCloneModel(model) {
        const newModel = MDFMultiAddLayoutView.prototype.deepCloneModel.call(this, model);

        /*
         * since the validator for serialnum_to changes, remove serialnum_to validator for
         * this new model
         */
        delete newModel.validators.SERIALNUM_TO;

        return newModel;
    },

    /**
     * @name modelIsEmpty
     * @description checks in the model is empty (all attributes are blank)
     * @param {Model} model - itemView model to test
     * @returns {boolean}
     */
    modelIsEmpty(model) {
        return util.every(model.attributes, util.isEmpty);
    },

    /**
     * @name amountOfPaymentsModified
     * @description toggles the display of the 'save as template' checkbox depending on how many
     * payments are present within the view
     */
    amountOfPaymentsModified() {
        this.ui.$saveAsTemplate.toggle(this.collection.length === 1);

        if (this.collection.length !== 1) {
            this.ui.$saveAsTemplate.find('[name="SAVEASTEMPLATE"]')[0].checked = false;
            this.collection.at(0).set({
                TEMPLATE_CODE: '',
                TEMPLATE_DESCRIPTION: '',
                RESTRICTTEMPLATE_FLAG: false,
                SAVEASTEMPLATE: false,
            });
            this.collection.at(0).removeValidator(['TEMPLATE_CODE', 'TEMPLATE_DESCRIPTION']);
        }
    },

    /**
     * @name getCurrency
     * @decription returns the currency for the RTPs.  Right now we assume USD
     */
    getCurrency() {
        /*
         * TODO NH-97880
         * In the future this form will need to accommodate multiple types of currencies
         * for now, we're only supporting USD and merely taking the first currency we can find
         * or defaulting to USD if there is none.
         */
        let currency;
        if (this.collection.length !== 0) {
            const modelWithCurrency = this.collection.findWhere(model => model.get('CREDIT_CURRENCY'));
            currency = modelWithCurrency ? modelWithCurrency.get('CREDIT_CURRENCY') : 'USD';
        }
        return currency;
    },

    /**
     * @name displaySummaryTotal
     * @description displays the summary total line in the summary section using
     * the transformLocaleWrapper component
     */
    displaySummaryTotal() {
        const currency = this.getCurrency();
        const params = [{ value: '1', className: 'summary-large-text summary-count-text' }, { value: '0.00', className: 'summary-large-text' }, { value: currency }];
        const localeKey = 'rtp.multi.summary.total';
        this.summaryLocaleKey = localeKey;
        const SummaryTotalView = createTransformLocaleView({
            localeKey,
            params,
            tagName: 'span',
            tagClass: 'summaryTotal',
        });
        if (this.summaryTotalRegion) {
            this.summaryTotalRegion.show(new SummaryTotalView());
        }
    },

    /**
     * @name updateSummayTotal
     * @description updated the summary total line whenever the amount or
     * number of payments change.
     */
    updateSummaryTotal() {
        // update.... w/ total count and total amount
        const currency = this.getCurrency();
        let runningTotal = 0;
        this.collection.forEach((payment) => {
            runningTotal += Formatter.unformatNumber(payment.get('CREDIT_AMOUNT') || 0);
        });
        runningTotal = Formatter.formatCurrency(runningTotal);
        this.ui.$summaryAmountTotal.text(runningTotal);
        this.ui.$currency.text(currency);
        const params = [{ value: this.collection.length, className: 'summary-large-text summary-count-text' }, { value: runningTotal, className: 'summary-large-text' }, { value: currency }];
        this.appBus.trigger(`update:localeMessage:${this.summaryLocaleKey}`, params);
    },

    /**
     * @name toggleLegalEntityError
     * @description will add or remove a page-level error based on the legal entity ID and
     * bene personal info being mutually exclusive.
     * @param {boolean} errorBeingAdded - whether to add or remove the error
     */
    toggleLegalEntityError(errorBeingAdded) {
        this.alertRegion.reset();
        if (errorBeingAdded) {
            this.alertRegion.show(alert.negative(
                locale.get('RTP.error.LEIPersonalInfoExclusive'),
                {
                    canDismiss: false,
                },
            ));
            scroll.scrollToFirstError();
        }
    },

    /**
     * Used to match a succesfully submitted RTP response to a collection item so it may be removed
     * from the form
     * @param {Object} successItem - The returned item from back-end
     * @param {Object} collectionItem - An individual entry in the collection
     */
    isSuccessItem(successItem, collectionItem) {
        /*
         * if masked accounts are enabled, the returned masked account number in the successItem
         * won't match against a collection item in the standard 'isSuccessItem' function
         */
        if (this.maskingConfig.disableMask) {
            return MDFMultiAddLayoutView.prototype.isSuccessItem.call(
                this,
                successItem,
                collectionItem,
            );
        }
        /*
         * HACK NH-164301 - When account masking is active, use the masked account number, as well
         * as other payment details, to match the successfully submitted item with an item in the
         * collection to remove it from the form. A more ideal solution application-wide would be
         * to use a separate unique identifier that is independent of the account number to keep
         * track of individual accounts. This improvement is described further in NH-172751
         */
        return `${collectionItem.get('DEBIT_ACCOUNT_TITLE')} ${maskValue(collectionItem.get('DEBIT_ACCOUNT_NUMBER'))}` === successItem['From:']
                && `${collectionItem.get('BENE_NAME')} ${maskValue(collectionItem.get('BENE_ACCOUNT'))}` === successItem['To:']
                && parseFloat(collectionItem.get('CREDIT_AMOUNT')) === Formatter.unformatNumber(successItem['Amount:'])
                && moment(collectionItem.get('VALUE_DATE'), userInfo.getDateFormat()).isSame(successItem['Value Date:']);
    },
});
