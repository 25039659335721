var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row modify-language\" data-hook=\"getModifyLanguageSection\">\n  <div class=\"col-3 required\">\n    <div class=\"form-group\">\n        <label for=\"language\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.language",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":68}}}))
    + "</label>\n        <div class=\"select-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":67}}})) != null ? stack1 : "")
    + "\">\n        </div>\n        <p class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"editState") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":56}}})) != null ? stack1 : "")
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"LANGUAGELABEL") || (depth0 != null ? lookupProperty(depth0,"LANGUAGELABEL") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"LANGUAGELABEL","hash":{},"data":data,"loc":{"start":{"line":9,"column":58},"end":{"line":9,"column":75}}}) : helper)))
    + "</p>\n    </div>\n  </div>\n\n  <div class=\"col-4 required\">\n    <div class=\"form-group\">\n        <label for=\"messageHeader\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.message.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":35},"end":{"line":16,"column":79}}}))
    + "</label>\n        <input type=\"text\" data-hook=\"getMessageHeader\" id=\"messageHeader\" class=\"message-header LanguageSection-info form-control "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":131},"end":{"line":17,"column":161}}})) != null ? stack1 : "")
    + "\" name=\"messageHeader\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"MESSAGEHEADER") || (depth0 != null ? lookupProperty(depth0,"MESSAGEHEADER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"MESSAGEHEADER","hash":{},"data":data,"loc":{"start":{"line":17,"column":191},"end":{"line":17,"column":208}}}) : helper)))
    + "\"/>\n        <p class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"editState") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":18},"end":{"line":18,"column":56}}})) != null ? stack1 : "")
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"MESSAGEHEADER") || (depth0 != null ? lookupProperty(depth0,"MESSAGEHEADER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"MESSAGEHEADER","hash":{},"data":data,"loc":{"start":{"line":18,"column":58},"end":{"line":18,"column":75}}}) : helper)))
    + "</p>\n    </div>\n  </div>\n\n  <div class=\"col-4 required\">\n    <div class=\"form-group\">\n        <label for=\"details\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"live.maintenance.details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":29},"end":{"line":25,"column":66}}}))
    + "</label>\n        <textarea type=\"text\" data-hook=\"getDetails\" rows=\"1\" id=\"details\" class=\"details LanguageSection-info form-control "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":124},"end":{"line":26,"column":154}}})) != null ? stack1 : "")
    + "\" name=\"details\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"DETAILS") || (depth0 != null ? lookupProperty(depth0,"DETAILS") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DETAILS","hash":{},"data":data,"loc":{"start":{"line":26,"column":171},"end":{"line":26,"column":182}}}) : helper)))
    + "</textarea>\n        <p class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"editState") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":18},"end":{"line":27,"column":56}}})) != null ? stack1 : "")
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"DETAILS") || (depth0 != null ? lookupProperty(depth0,"DETAILS") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DETAILS","hash":{},"data":data,"loc":{"start":{"line":27,"column":58},"end":{"line":27,"column":69}}}) : helper)))
    + "</p>\n    </div>\n  </div>\n\n  <div class=\"col-1 modify-message "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editState") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":35},"end":{"line":32,"column":65}}})) != null ? stack1 : "")
    + "\">\n      <button type=\"button\" data-hook=\"getClearModel\" class=\"btn btn-tertiary clear-language-btn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":98},"end":{"line":33,"column":123}}}))
    + "</button>\n      <button type=\"button\" data-hook=\"getDeleteModel\" class=\"btn btn-tertiary delete-language-btn\">\n        <span class=\"icon-close\"></span>\n        <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":30},"end":{"line":36,"column":56}}}))
    + "</span>\n      </button>\n  </div>\n</div>\n";
},"useData":true});