import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import LimitsUtil from '../limitsUtil';
import limitFieldTmpl from './limitField.hbs';

export default ItemView.extend({
    template: limitFieldTmpl,

    initialize(options) {
        this.column = options.column;
        this.fieldName = this.column.get('field');
        this.mode = options.mode;
        this.modifyMode = options.modifyMode;

        // the max field patttern is max + MyFieldName.
        if (this.model.get('approveAction') && this.modifyMode) {
            const maxFieldName = `max${this.fieldName.charAt(0).toUpperCase()}${this.fieldName.substring(1)}`;
            if (!util.isEmpty(this.model.get(maxFieldName))) {
                this.model.addValidator(this.fieldName, {
                    maxValue: parseInt(this.model.get(maxFieldName), 10),
                    description: this.column.get('label'),
                });
            }
        }

        /*
         * Pass true if reacting to a checkbox change to
         * send a trigger when the amount gets wiped out.
         */
        this.listenTo(this.model, 'change:approveAction', function () {
            this.formatLimit(true);
        });

        // Need to check if permission changed for modify prior to render
        this.formatLimit();
    },

    onRender() {
        this.$('input').inputmask(
            'number',
            LimitsUtil.wholeNumberWithSeparatorFormat(),
        );
    },

    templateHelpers() {
        const readOnly = this.mode === constants.MODES.VIEW;

        return {
            cid: this.cid,
            limitName: this.column.get('field'),
            value: this.model.get(this.column.get('field')),
            disableCheck: !this.hasApproval() || readOnly,
            modifyMode: !readOnly,
        };
    },

    formatLimit(fromCheckboxChange) {
        const fieldName = this.column.get('field');
        const hasApproval = this.hasApproval();

        /*
         * Trigger the change only when toggled by checkbox.  Otherwise
         * the glu grid tried to rneder and create additional limit cells.
         */
        if (!hasApproval) {
            this.model.set(
                fieldName,
                '',
                {
                    silent: !fromCheckboxChange,
                },
            );
        }

        this.$('input').prop('disabled', hasApproval && this.mode !== constants.MODES.VIEW ? '' : 'disabled');
    },

    validateLimit(e) {
        const fieldName = e.currentTarget.name;
        if (this.model.validators && this.model.validators[fieldName]) {
            this.model.validateField(fieldName);
        }
    },
    hasApproval() {
        return this.model.get('approveAction');
    },
});
