import locale from '@glu/locale';
import ItemView from '@glu/core/src/itemView';
import markdownConverter from 'common/util/markdownConverter';
import configuration from 'system/configuration';
import notificationItemTmpl from './notificationItem.hbs';

const notification = ItemView.extend({
    tagName: 'li',
    className: '',
    template: notificationItemTmpl,

    initialize(options) {
        this.mode = options.mode;
    },

    ui: {
        $moreInfoToggle: '.more-info',
        $warningToggleText: '.toggle-text',
        $warningInfo: '.warning-info',
        $notificationInnerSpanIconCaret: '.icon-caret-inner-span',
    },

    events: {
        'click @ui.$moreInfoToggle': 'toggleMoreLess',
    },

    toggleMoreLess() {
        if (this.ui.$warningInfo.is(':visible')) {
            this.ui.$warningInfo.slideUp(200);
            this.ui.$warningToggleText.html(locale.get('PS.Notifications.More'));
        } else {
            this.ui.$warningInfo.slideDown(200);
            this.ui.$warningToggleText.html(locale.get('PS.Notifications.Less'));
        }

        this.ui.$notificationInnerSpanIconCaret.toggleClass('icon-caret-down icon-caret-up');
    },

    onRender() {
        // hide the warning info by default
        this.ui.$warningInfo.hide();
        this.ui.$warningToggleText.html(locale.get('PS.Notifications.More'));

        // add warning row class to the element if we are a warning
        if (this.model.get('warning')) {
            this.$el.addClass('notification-warning-row');
        }
    },

    templateHelpers() {
        return {
            banner: this.model.get('notificationType') === 'GLOBAL_BANNER',
            warning: this.model.get('warning'),
            id: this.cid,
            subject: markdownConverter.toHTML(this.model.get('subject') || ''),
            /*
            * NH-170532 - Backend returns the subject value as a string with HTML tags
            * Removing the HTML tags for the screenreader to read just the text.
            * eg : <b>ACH Payments</b>await your approval.
            */
            filteredSubject: this.model.get('subject').replace(/(<([^>]+)>)/ig, ''),
            message: markdownConverter.toHTML(this.model.get('message') || '', true),
            appRoot: configuration.appRoot,
            showCount: !this.model.get('hideCount'),
        };
    },
});

export default notification;
