import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    parse(jsonData) {
        const returnArray = [];
        util.each(jsonData, (rowItem) => {
            const row = rowItem.mapDataList;
            const contextObj = {};
            contextObj.serviceName = 'legacyReports';

            const obj = {
                id: row[2].value,
                name: row[3].value,
                label: row[4].value,
                systemid: row[5].value,
                context: contextObj,
            };

            returnArray.push(obj);
        });

        // group by system id then again by name
        const groupArray = [];
        const groupOfIDs = util.groupBy(returnArray, report => report.systemid);
        Object.keys(groupOfIDs || {}).forEach((systemid) => {
            const groupOfNames = util.groupBy(groupOfIDs[systemid], system => system.name);

            Object.keys(groupOfNames || {}).forEach((name) => {
                /*
                 * simply copy first object, then add array of IDs and count of
                 * items (labels)
                 */
                const [systemReport] = groupOfNames[name];

                let count = 0;
                const sysArr = [];

                for (let s = 0; s < groupOfNames[name].length; s += 1) {
                    sysArr.push(groupOfNames[name][s].id);
                    count += +groupOfNames[name][s].label;
                }
                systemReport.id = sysArr;
                systemReport.label = count;
                groupArray.push(systemReport);
            });
        });
        return groupArray;
    },

    sync(method, model, options) {
        const url = services.generateUrl('/tableMaintenance/getQueryResults');
        const postData = {
            IncludeMapData: 1,
            queryCriteria: {
                action: {
                    typeCode: '*',
                    entryMethod: '0',
                    productCode: '_ADMIN',
                    actionMode: 'SELECT',
                    functionCode: '*',
                },
                inquiryId: 17237,
                allowDuplicates: null,
            },
            formatterType: 'GridQuery',
            requestHeader: {
                queryPagesize: 200,
            },
        };
        http.post(url, postData, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, (result) => {
            options.error(result);
        });
    },
});
