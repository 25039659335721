import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import AdminUtil from 'app/administration/common/util';

export default Collection.extend({

    initialize() {
        const userInfo = store.get('uce-load');
        const depends = [{
            name: 'UserGroup',
            value: userInfo.userGroup,
        },

        ];
        this.data = {
            rowsPerPage: 0,
            startRow: 1,
            fieldName: 'ROLEIDLOOKUP',
            typeCode: '_USER',
            productCode: '_ADMIN',
            functionCode: 'USERS',
            depends,
            searchFields: [{
                fieldName: 'USERID',
                operator: 'EQUALS',
                fieldValue: [
                    '',
                ],
                dataType: 'text',
            }],
            searchOrOperator: true,
        };
    },

    sync(method, model, options) {
        // for users and roles, options.userid is currently always populated
        if (options.userid) {
            this.data.searchFields[0].fieldValue[0] = options.userid;
        }
        // rowsPerPage 0 indicates the server will determine page size
        http.post(services.tableMaintLookup, this.data, (result) => {
            options.success(result.rows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const data = {};

        util.each(response, (rowItem) => {
            const obj = transform.pairsToHash(rowItem.columns, 'fieldName', 'fieldValue');
            const key = `${obj.UserGroup}:${obj.UserId}`;

            if (!data[key]) {
                data[key] = {
                    id: obj.UserId,
                    text: obj.UserId,
                    userGroup: obj.UserGroup,
                    userName: obj.UserName,
                    roles: [],
                };
            }

            const role = transform.pairsToHash(rowItem.mapDataList, 'toField', 'value');
            if (!AdminUtil.isBankMaintainedRole(role)) {
                data[key].roles.push(role);
            }
        });

        return util.values(data);
    },

});
