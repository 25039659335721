import Layout from '@glu/core/src/layout';
import model from '@glu/core/src/model';
import CollectionView from '@glu/core/src/collectionView';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import validation from 'validation';
import store from 'system/utilities/cache';
import userSelfService from 'system/webseries/api/userSelfService';
import PasswordRulesCollection from 'system/password/collections/rules';
import systemConfig from 'system/configuration';
import PasswordRuleView from 'common/passwordRules/rule';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import mobileUtil from 'mobile/util/mobileUtil';
import { updateBiometricData } from 'mobile/util/biometricUtil';
import tmpl from './passwordReset.hbs';

const Model = model.extend({
    validators: {},
});

export default Layout.extend({
    model: new Model(),
    template: tmpl,
    binding: true,
    className: 'reset-password modal',

    initialize(options) {
        this.userId = options.name;
        this.userGroup = options.realm;
        this.notModal = options.notModal;

        // if emailResetPassword is true then don't use the normal password reset process
        this.emailResetPassword = serverConfigParams.get('EmailResetPassword') && serverConfigParams.get('EmailResetPassword').toLowerCase() === 'true';
        this.isForgottenPassword = options.isForgottenPassword || false;
        this.model = new Model();
        this.createValidators();

        if (this.notModal) {
            this.$el.removeClass('modal');
        }

        // Only want to return to the login if the Password Reset is not launched from a grid.
        if (options.fromLogin) {
            this.listenTo(this, 'cancel', this.goToLoginScreen);
        }
    },

    createValidators() {
        this.model.validators = {
            newPassword: {
                description: locale.get('prompt.newPassword'),
                exists: true,
                otherDescription: locale.get('prompt.confirmPassword'),
                sameValue: 'confirmPassword',
                maxLength: 16,
            },

            confirmPassword: {
                description: locale.get('prompt.confirmPassword'),
                exists: true,
                otherDescription: locale.get('prompt.newPassword'),
                sameValue: 'newPassword',
                maxLength: 16,
            },
        };
    },

    onRender() {
        // remove once passwordRules service becomes available to unauthenticated users
        if (!this.isForgottenPassword) {
            if (this.hasLoadedRequiredData()) {
                this.passwordCollectionView = new CollectionView({
                    el: '.password-rules',
                    collection: this.passwordRules,
                    itemView: PasswordRuleView,
                });
                this.passwordCollectionView.render();
            } else {
                this.loadRequiredData();
            }
        }
    },

    success(data) {
        // Only native app has biometric
        if (mobileUtil.isNativeApp()) {
            updateBiometricData({
                userGroup: this.userGroup,
                userId: this.userId,
                password: this.model.get('newPassword'),
            });
        }
        if (!this.notModal) {
            this.close();
        }
        this.appBus.trigger('forgottenPassword:reset:success', data);
    },

    failure(data) {
        // display any errors from the backend that may have occurred during the update.
        const errorMessage = JSON.parse(data.responseText);
        // show errors from the backend.
        if (errorMessage.responseHeader.result === false) {
            validation.show({
                generic: errorMessage.responseHeader.message[0],
            }, this);
        }
    },

    cancel() {
        this.trigger('cancel');
        this.close();
    },

    change() {
        // validate the form
        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }
        // call change password service
        const self = this;

        let tokenVal;
        if (store.get('emailResetPasswordToken')) {
            tokenVal = store.get('emailResetPasswordToken');
            // Remove token from store
            store.unset('emailResetPasswordToken');
        } else {
            tokenVal = this.model.get('token');
        }
        const req = {
            requestHeader: {
                requestId: '1',

                requestUser: {
                    userId: self.userId,

                    data: {
                        item: [{
                            name: 'USERGROUP',
                            value: self.userGroup,
                        }, {
                            name: 'resetForgottenPassword',
                            value: 'true',
                        }],
                    },
                },

                channelId: '',
            },

            oldPassword: '',
            newPassword: this.model.get('newPassword'),
            token: tokenVal,
        };

        userSelfService.resetPassword(req).then((data) => {
            self.success(data);
        }, (data) => {
            self.failure(data);
        });
    },

    sendResetPasswordEmail(resetData) {
        const url = '/testurl'; // TODO: get url for service
        const response = this.retrieveApiData(url, resetData, 'POST');

        response.then(this.handleUserResetSuccess)
            .catch(this.handleUserResetError);
    },

    handleUserResetSuccess() {
        const alertMessage = alert.success(locale.get('common.passwordReset.successMessage'));
        this.alertRegion.show(alertMessage);
    },

    handleUserResetError() {
        const alertMessage = alert.error(locale.get('common.passwordReset.errorMessage'));
        this.alertRegion.show(alertMessage);
    },

    /**
     * General function to hit apis and retrieve data
     * @param  {string} url    API endpoint
     * @param  {string} method API method
     * @param  {object} data to pass to endpoint
     * @return {promise} Data returned from API
     */
    retrieveApiData(url, method, data) {
        return new Promise(((resolve, reject) => {
            http[method](url, data).then((response) => {
                resolve(response);
            }, (error) => {
                reject(error);
            });
        }));
    },

    loadRequiredData() {
        const self = this;
        this.passwordRules = new PasswordRulesCollection();
        this.passwordRules.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    goToLoginScreen() {
        /**
         * using replace for security reasons as we don't
         * want the user to be able to go back to the
         * security questions or reset password screens
         */
        document.location.replace(window.Bottomline.appRoot);
    },

    templateHelpers() {
        return {
            // NH-109035 'hybrid' implementation until reset password is done only by email
            emailResetPassword: systemConfig.isAdmin()
                && this.emailResetPassword && !this.options.entitlements.MODIFY,
            notModal: this.notModal,
        };
    },

});
