import $ from 'jquery';
import util from '@glu/core/src/util';
import EntryView from 'app/checkManagement/views/viewBasePayDecision';
import locale from '@glu/locale';
import workspaceHelper from 'common/workspaces/api/helper';
import alertMessage from 'common/api/alertMessage';
import store from 'system/utilities/cache';
import cmConstants from 'app/checkManagement/constants';

const ViewEPositivePayDecision = EntryView.extend({
    cachedCreditDebitCode: null,

    initialize(options) {
        this.setModel();
        this.checkViewer = options.checkViewer;
        EntryView.prototype.initialize.call(this, options);
    },

    onRender() {
        EntryView.prototype.onRender.call(this);

        if (store.has(`${this.contextKey}-alertMessageCV`) && this.alertRegion) {
            alertMessage.renderMessage(this, store.get(`${this.contextKey}-alertMessageCV`), store.get(`${this.contextKey}-confirmsCV`), 0);
            store.set(`${this.contextKey}-alertMessageCV`, null);
            store.set(`${this.contextKey}-confirmsCV`, null);
        }
    },

    setModel() {
        this.model = store.get('risk_listView_ePositivePayDecision-actionModel');
    },

    setHasLoadedRequiredData(bool) {
        if (bool) {
            const accountNumber = this.model.get('ACCOUNT_NUMBER');
            this.storedModel.set('ACCOUNT_NUMBER', accountNumber);

            const accountName = this.model.get('ACCOUNT_TITLE');
            this.storedModel.set('ACCOUNT_TITLE', accountName);

            const status = $.trim(this.model.get('STATUS'));
            this.storedModel.set('STATUS', status);

            const decision = $.trim(this.model.get('ACTIONCODE'));
            this.storedModel.set('ACTIONCODE', decision);

            const cd = $.trim(this.model.get('CREDITDEBITCODE'));
            this.cachedCreditDebitCode = this.storedModel.get('CREDITDEBITCODE');
            this.storedModel.set('CREDITDEBITCODE', cd);
        }

        EntryView.prototype.setHasLoadedRequiredData.call(this, bool);
    },

    getListUrl() {
        return this.options.returnRoute;
    },

    getFieldDataToSendToServer() {
        return {
            PPEBMPPSUSPECTITEMID: {
                name: 'PPEBMPPSUSPECTITEMID',
                virtualField: false,
            },
            ACTIONCODE: {
                name: 'ACTIONCODE',
                virtualField: false,
            },
            // eslint-disable-next-line
            UPDATECOUNT__: {
                name: 'UPDATECOUNT__',
                virtualField: false,
            },
        };
    },

    pay(evt, createRule) {
        // only send down fields necessary to make a decision.
        this.pageView.model.fieldData = this.getFieldDataToSendToServer();

        this.pageView.model.set({
            ACTIONCODE: 'P',
            CREDITDEBITCODE: this.model.get('CREDITDEBITCODE'),
        });
        this.pageView.model.pay({
            success: (model, confirmResponse) => {
                if (createRule) {
                    store.set(cmConstants.ELECTRONIC_POS_PAY_PAY_MESSAGE, confirmResponse);

                    const achRuleModel = this.getAchRuleFields(model);
                    store.set(cmConstants.ELECTRONIC_POS_PAY_RULE_PREFILL, achRuleModel);

                    this.navigateTo('RISK/achAuthRulesAdd');
                } else {
                    store.set(`${this.contextKey}-alertMessage`, this.mode);
                    store.set(`${this.contextKey}-confirms`, confirmResponse);
                    this.navigateTo(store.get('current-workspace-route'));
                }
            },
        });
    },

    getAchRuleFields(model) {
        const obj = util.pick(model.toJSON(), cmConstants.ELECTRONIC_POS_PAY_PREFILL_FIELDS);
        // ACH Auth Rules uses ACCOUNTNUMBER
        obj.ACCOUNTNUMBER = obj.ACCOUNT;
        obj.ACCOUNTFILTER = `${obj.BANKCODE}-${obj.ACCOUNTNUMBER}`;
        return obj;
    },

    payrule(evt) {
        this.pay(evt, true); // indicate that if pay is successful, then create a rule
    },

    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    return() {
        const self = this;
        // only send down fields necessary to make a decision.
        this.pageView.model.fieldData = this.getFieldDataToSendToServer();

        this.pageView.model.set({
            ACTIONCODE: 'R',
            CMDECISION: 'R',
            PAYISSUE_FLAG: '',
            CREDITDEBITCODE: this.model.get('CREDITDEBITCODE'),
        });
        this.pageView.model.return({
            success(model, confirmResponse) {
                store.set(`${self.contextKey}-alertMessage`, self.mode);
                store.set(`${self.contextKey}-confirms`, confirmResponse);
                self.navigateTo(store.get('current-workspace-route'));
            },
        });
    },

    getDecisionLocale() {
        if (!this.pageView.model.get('CLIENTRETURNREASONCODE')) {
            const payFlag = this.pageView.model.get('PAYISSUE_FLAG');

            if (payFlag === 'Y') {
                this.model.set('CMDECISION', locale.get('CM.PayIssue'));
            } else {
                this.model.set('CMDECISION', locale.get('CM.Paid'));
            }
        } else {
            this.model.set('CMDECISION', locale.get('CM.Return'));
        }
    },

    actionSuccess(confirmResponse) {
        const { contextKey } = this;
        this.getDecisionLocale();

        if (this.checkViewer === undefined) {
            store.set(`${contextKey}-alertMessage`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirms`, confirmResponse);
            workspaceHelper.returnToCurrentWorkspace(this);
        } else {
            store.set(`${contextKey}-alertMessageCV`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirmsCV`, confirmResponse);
            this.checkViewer.nextCheck();
        }
    },
});

export default ViewEPositivePayDecision;
