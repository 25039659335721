var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"range-selector-control ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all\">\n                <div class=\"ui-slider-range ui-widget-header ui-corner-all\" tabindex=\"0\" aria-label=\"Range slider - selected range\"></div>\n                <div class=\"ui-slider-handle lower-handle ui-state-default ui-corner-all\" tabindex=\"0\" role=\"slider\" id=\"lower-slider-handle-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":141},"end":{"line":3,"column":147}}}) : helper)))
    + "\" aria-controls=\"upper-slider-handle-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":184},"end":{"line":3,"column":190}}}) : helper)))
    + " range-slider-date-values\" aria-label=\"Range slider - lower thumb\"></div>\n                <div class=\"ui-slider-handle upper-handle ui-state-default ui-corner-all\" tabindex=\"0\" role=\"slider\" id=\"upper-slider-handle-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":141},"end":{"line":4,"column":147}}}) : helper)))
    + "\" aria-controls=\"lower-slider-handle-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":184},"end":{"line":4,"column":190}}}) : helper)))
    + " range-slider-date-values\" aria-label=\"Range slider - upper thumb\"></div>\n        </div>\n\n        <span class=\"tick beginning\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"maxPastOffset") || (depth0 != null ? lookupProperty(depth0,"maxPastOffset") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxPastOffset","hash":{},"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":54}}}) : helper)))
    + " "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.days",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":55},"end":{"line":7,"column":81}}}))
    + "</span>\n        <span class=\"tick today\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.today",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":60}}}))
    + "</span>\n        <span class=\"tick end\">+"
    + alias4(((helper = (helper = lookupProperty(helpers,"maxFutureOffset") || (depth0 != null ? lookupProperty(depth0,"maxFutureOffset") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxFutureOffset","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":51}}}) : helper)))
    + " "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"cashflow.days",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":52},"end":{"line":9,"column":78}}}))
    + "</span>\n";
},"useData":true});