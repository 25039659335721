import { createUseStyles } from '@glu/theming';

const styles = ({ palette, typography }) => ({
  '@global': {
    body: {

      // To make column menu visible outside of parent
      '& .ag-header-row, .ag-header-cell': {
        overflow: 'visible'
      },

      // To make column menu btn right alignment
      '& .ag-react-container': {
        width: 'inherit'
      }
    }
  },
  active: {
    '& svg': { fill: '#fff' },
    backgroundColor: palette.primary.normal,
    borderRadius: 15
  },
  filterIcon: {
    '& svg': {
      fill: '#fff',
      height: 12,
      left: 4,
      position: 'absolute',
      top: 5,
      width: 12
    },
    backgroundColor: palette.primary.dark,
    borderRadius: 10,
    height: 20,
    position: 'relative',
    width: 20
  },
  headerCell: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerName: {
    color: palette.text.dark,
    fontFamily: typography.fontFamily,
    fontSize: 12,
    marginRight: 5,
    textTransform: 'uppercase'
  },
  headerNameButton: {
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    cursor: ({ enableSorting }) => (enableSorting ? 'pointer' : undefined),
    display: 'flex',
    lineHeight: '24px',
    padding: 0,
    width: '100%'
  },
  menuButton: {
    '& svg': {
      height: 16,
      left: 7,
      position: 'absolute',
      top: 7,
      width: 16
    },
    '&:hover, &:focus': {
      '& svg': { fill: '#fff' },
      backgroundColor: palette.primary.normal
    },
    borderRadius: 15,
    height: 30,
    position: 'relative',
    width: 30
  },
  sortIcon: {
    '& svg': {
      fill: palette.text.dark,
      height: 12,
      left: 2,
      position: 'absolute',
      top: 2,
      width: 12
    },
    height: 16,
    position: 'relative',
    width: 16
  }
});

export default createUseStyles(styles);
