import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const { model } = form.formView;
    const beneAddress1 = form.field('BENE_ADDRESS_1');
    const beneAddress2 = form.field('BENE_ADDRESS_2');
    const beneCity = form.field('BENE_CITY');
    const beneCountry = form.field('BENE_COUNTRY');

    if (initialState) {
        /*
         * if the form has address values at page load (modify, create from payment/template)
         * then determine if the city and country fields should be required
         */
        if (model.get('BENE_ADDRESS_1') || model.get('BENE_ADDRESS_2')) {
            rtgsCommon.toggleCityCountryMandatory(model, beneCity, beneCountry);
        }

        /*
         * when the address fields are changed, update the required status of the city
         * and country inputs as well
         */
        beneAddress1.$el.add(beneAddress2.$el).off('blur.toggleCityCountry change.toggleCityCountry')
            .on('blur.toggleCityCountry change.toggleCityCountry', () => rtgsCommon.toggleCityCountryMandatory(model, beneCity, beneCountry));
    }
}
