import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import store from 'system/utilities/cache';
import constants from 'common/dynamicPages/api/constants';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import mobileGridDefaultBulkActions from 'common/dynamicPages/api/mobileGridDefaultBulkActions';
import template from './scheduledExport.hbs';

const scheduledExportList = ListView.extend({
    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="getAddExport"]': 'addScheduledExport',
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
        },
    ),

    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: 'export/scheduledExport',
            serviceFunc: null,
            businessType: null,
            context: 'SCHEDULED_EXPORTS',
            selector: 'rowSelector',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        this.cleanUpStore();
    },

    /**
     * Reset values in store related to autmated exports
     */
    cleanUpStore() {
        store.unset('SCHEDULED_EXPORTS:currentModelId');
    },

    /**
     * Handle add action
     */
    addScheduledExport() {
        this.navigateTo(this.options.insertRoute);
        return Promise.resolve();
    },

    /**
     * Handle view action
     * @param  {object} item Model associated with item choosen for modification in view
     */
    gridRowSelect(item) {
        store.set('SCHEDULED_EXPORTS:currentModelId', item.model.get('ID'));

        this.navigateTo(this.options.viewRoute);
        return Promise.resolve();
    },

    /**
     * Handle modify action
     * @param  {object} item Model associated with item choosen for modification in view
     */
    gridRowModify(item) {
        // set mode in model to modify
        store.set('SCHEDULED_EXPORTS:currentModelId', item.model.get('ID'));

        this.navigateTo(this.options.modifyRoute);
        return Promise.resolve();
    },

    /**
     * Use the bulk action delete for a single item
     * TODO remove when all grid APIs support delete with an array of items,
     * be it bulk delete or row action
     * @param {Object} options - grid row action options object
     */
    gridRowDelete(options) {
        this.processMultiActionsAsOne(constants.ACTION_DELETE, [options.model]);
        return Promise.resolve();
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_49070,
            summaryInquiryId: constants.INQUIRY_ID_49068,
        };
    },

    /**
     * Call the prototype export function passing in an inquiryId
     */
    export() {
        ListView.prototype.export.call(this, {
            inquiryId: constants.INQUIRY_ID_49068,
        });
    },
});

let list = scheduledExportList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'GIR.New.Scheduled.Export',
                entitlement: 'INSERT',
                handlerMethodName: 'addScheduledExport',
            },
        ],
        bulkActions: mobileGridDefaultBulkActions,
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
