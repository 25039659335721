import alert from '@glu/alerts';
import locale from '@glu/locale';
import Poll from '@glu/polling';
import util from '@glu/core/src/util';
import BaseMFAView from 'system/mfa/views/baseMFA';
import MobileStatusOTP from 'system/mfa/models/mobileStatusOTP';
import OutOfBandModel from 'system/mfa/models/outOfBand';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import mobileOTPTmpl from './mobileOTP.hbs';

export default BaseMFAView.extend({
    template: mobileOTPTmpl,

    initialize(options) {
        this.model = new OutOfBandModel({
            challengedAction: options.challengedAction,
        });
        this.createPoller();
    },

    onClose() {
        this.poller.dispose();
    },

    onRender() {
        this.trigger('dialog:buttons:change', this.buttons());
        this.sendNotification();
    },

    buttons() {
        return [{
            text: locale.get('button.cancel'),
            className: 'btn btn-primary',
            callback: util.bind(this.cancel, this),
        }];
    },

    sendNotification() {
        const self = this;
        this.alertRegion.close();
        this.model.sendNotice({
            success(result) {
                if (result.respHeader.result === false) {
                    const message = result.respHeader.message.join(' ');
                    self.alertView = alert.danger(
                        message,
                        {
                            canDismiss: true,
                        },
                    );
                    self.alertRegion.show(self.alertView);
                } else {
                    self.mobileStatusOTP.claimCheck = result.claimCheck;
                    self.poller.start();
                }
            },

            error: util.bind(this.handleMFAError, this),
        });
    },

    submitCode() {
        const self = this;
        this.alertRegion.close();
        this.model.validateMfa({
            success(result) {
                if (result.respHeader.result === false) {
                    const resp = result.respHeader;
                    const message = resp.message.join(' ');
                    self.alertView = alert.danger(
                        message,
                        {
                            canDismiss: true,
                        },
                    );
                    self.alertRegion.show(self.alertView);
                } else {
                    self.completion();
                }
            },

            error: util.bind(this.cancel, this),
        });
    },

    createPoller() {
        this.mobileStatusOTP = new MobileStatusOTP({
            targetId: -1,
        });
        const self = this;
        const count = serverConfigParams.get('mfaMobileOtpPollLimit') !== undefined ? serverConfigParams.get('mfaMobileOtpPollLimit') : 12;
        let intvl = serverConfigParams.get('mfaMobileOtpPollIntervalSeconds') !== undefined ? serverConfigParams.get('mfaMobileOtpPollIntervalSeconds') : 5;
        intvl *= 1000;
        const options = {
            iterator(poll) {
                this.mobileStatusOTP.fetch({
                    success(model) {
                        if (model.get('result') === true) {
                            self.submitCode();
                        } else {
                            poll();
                        }
                    },

                    error() {
                        self.cancel();
                    },
                });
            },

            limit: count,
            immediateStart: false,

            onLimit() {
                self.cancel();
            },

            interval: intvl,
        };

        this.poller = new Poll(options, this);
        return true;
    },
});
