export default ({ palette, breakpoints }) => ({
    messageItems: {
        color: palette.text.light,
    },

    msgHeader: {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontSize: '14px',
        marginLeft: '30px',
    },

    respMsgHeader: {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontSize: '14px',
        marginLeft: '0px',
    },

    msgLabel: {
        display: 'inline',
        color: palette.accent,
        fontSize: '14px',
        fontWeight: 'normal',
        marginBottom: '8px',
        marginLeft: '5px',

        [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            display: 'block',

            '& ~ span': {
                marginLeft: 15,
            },
        },
    },

    submitted: {
        '& p.responseLabel': {
            marginLeft: '35px',
            padding: '0 5px 0 15px',
        },
        '& p.rfiLabel': {
            padding: '0 5px 0 8px',
            marginLeft: '-25px',
        },
        '& p:nth-child(2)': {
            marginLeft: '5px',
        },
        '& p.ackLabel': {
            marginLeft: '-25px',
            padding: '0 5px 0 15px',
        },
    },

    received: {
        '& p.responseLabel': {
            padding: '0 5px 0 15px',
        },
        '& p.rfiLabel': {
            padding: '0 5px 0 8px',
        },
        '& p.ackLabel': {
            padding: '0 5px 0 15px',
        },
    },

    rfiReason: {
        paddingLeft: '0px',
    },
    rfiReasonDesc: {
        paddingLeft: '0px',
        marginLeft: '-25px',

        [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            marginLeft: 0,
        },
    },
});
