import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import locale from '@glu/locale';
import Model from 'app/payments/models/rdcLogin';
import rdcLoginTmpl from './rdcLogin.hbs';

export default Layout.extend({
    template: rdcLoginTmpl,

    initialize() {
        this.model = new Model();
        this.dialogTitle = this.options.title;
        this.collection = this.options.collection;
        this.dialogButtons = [{
            text: locale.get('button.submit'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
    },

    onRender() {
        this.renderLocationDropdown();
    },

    renderLocationDropdown() {
        const flexOptions = {
            disableMultiButton: true,
            data: this.collection.toJSON(),
        };

        const flexDropdown = new FlexDropdown(flexOptions);
        this.listenTo(flexDropdown, 'selectionChanged', this.setFieldValue);
        this.locationRegion.show(flexDropdown);
    },

    setFieldValue(data) {
        this.model.set('fieldValue', data[0].id);
    },

    cancel() {
        this.trigger('cancel');
    },

    close() {
        this.trigger('close');
    },

    submit() {
        const self = this;
        this.model.save(
            {},
            {
                success(result) {
                    self.trigger('complete', result);
                },
            },
        );
    },
});
