import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import getUsers from './util';
import DropdownRemoteFilter from '../DropdownRemoteFilter/DropdownRemoteFilter';

const propTypes = {
    userGroupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onFilter: PropTypes.func,
};

const defaultProps = {
    onFilter: getUsers,
};

const UserSelector = ({
    userGroupId,
    onFilter,
    name,
    ...props
}) => {
    const myFilter = useCallback(
        searchValue => onFilter(searchValue, userGroupId),
        [userGroupId, onFilter],
    );
    return (
        <DropdownRemoteFilter
            onFilter={myFilter}
            htmlId={`user-selector-${name}`}
            name={name}
            {...props}
        />
    );
};

UserSelector.propTypes = propTypes;
UserSelector.defaultProps = defaultProps;

export default UserSelector;
