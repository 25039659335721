var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"RSAToken-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.enter.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":75}}}))
    + "</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"RSAPin-cleared\">\n                    <span class=\"icon-tick\"></span>\n                    <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.pin.cleared",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":26},"end":{"line":11,"column":58}}}))
    + "</span>\n                </p>\n                <p class=\"RSAToken-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.prompted.create.new.token",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":43},"end":{"line":13,"column":89}}}))
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"RSAToken-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.create.new.pin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":39},"end":{"line":17,"column":75}}}))
    + "</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.nexttoken.intro",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":15},"end":{"line":20,"column":52}}}))
    + "</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"RSAToken-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.reset.pin.serialnumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":82}}}))
    + "</p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"RSAToken-success\">\n                    <p><span class=\"RSASuccess-check icon-checkmark-circle\"></span></p>\n                    <span class=\"RSAMessage-success\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.success",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":53},"end":{"line":30,"column":81}}}))
    + "!</span>\n                    <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.identity.verified",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":26},"end":{"line":31,"column":64}}}))
    + "</span>\n                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group required\">\n                    <label for=\"newTokenPin\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.enter.pin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":45},"end":{"line":36,"column":75}}}))
    + "</label>\n                    <input class=\"form-control\"\n                           data-bind=\"model\"\n                           data-hook=\"getPin\"\n                           id=\"newTokenPin\"\n                           name=\"newTokenPin\"\n                           type=\"password\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"newTokenPin\"></span>\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group\">\n                    <label for=\"systemGenPin\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.sys.gen.pin.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":46},"end":{"line":48,"column":84}}}))
    + "</label>\n                    <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"SystemGeneratedPin") || (depth0 != null ? lookupProperty(depth0,"SystemGeneratedPin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"SystemGeneratedPin","hash":{},"data":data,"loc":{"start":{"line":49,"column":51},"end":{"line":49,"column":73}}}) : helper)))
    + "</p>\n                </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"RSARe-enterPIN required\">\n                    <label for=\"confirmNewTokenPin\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.reenter.pin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":52},"end":{"line":54,"column":84}}}))
    + "</label>\n                    <input class=\"form-control\"\n                        data-bind=\"model\"\n                        data-hook=\"getPin\"\n                        id=\"confirmNewTokenPin\"\n                        name=\"confirmNewTokenPin\"\n                        type=\"password\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"confirmNewTokenPin\"></span>\n                </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group required\">\n                    <label for=\"nextToken\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.next.code.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":66,"column":43},"end":{"line":66,"column":79}}}))
    + "</label>\n                    <input class=\"form-control\"\n                           data-bind=\"model\"\n                           data-hook=\"getCode\"\n                           id=\"nextToken\"\n                           name=\"nextToken\"\n                           type=\"password\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"nextToken\"></span>\n                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                 <div class=\"RSASoftToken-authorization-wrapper required\">\n                    <label for=\"token\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.authorization.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":39},"end":{"line":78,"column":78}}}))
    + "</label>\n                    <input class=\"form-control\"\n                       data-bind=\"model\"\n                       data-hook=\"getCode\"\n                       id=\"token\"\n                       name=\"token\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"token\"></span>\n                	<p class=\"RSAPin-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.code.disp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":41},"end":{"line":85,"column":72}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMFAPin") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":115,"column":19}}})) != null ? stack1 : "")
    + "            </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPin") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":87,"column":16},"end":{"line":114,"column":11}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	                <div class=\"RSAToken-header\"/>\n	                <div class=\"RSASoftTokenPin-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSoftToken") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":24},"end":{"line":92,"column":33}}})) != null ? stack1 : "")
    + "                        <label for=\"pin\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.enter.pin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":93,"column":41},"end":{"line":93,"column":71}}}))
    + "</label>\n                         <input class=\"form-control\"\n                               data-bind=\"model\"\n                               data-hook=\"getPIN\"\n                               id=\"tokenPin\"\n                               name=\"tokenPin\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"tokenPin\"></span>\n                        <span class=\"RSAForgotPin\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.dont.have.pin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":51},"end":{"line":100,"column":85}}}))
    + "</span>\n                        <label class=\"RSAPin-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.create.pin.prompt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":101,"column":52},"end":{"line":101,"column":90}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showForgotPin") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":7},"end":{"line":106,"column":20}}})) != null ? stack1 : "")
    + "    	        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p class=\"RSAToken-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.soft.token.users.only",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":51},"end":{"line":91,"column":93}}}))
    + "</p>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " 	                   <span class=\"RSAForgotPin\">\n                        <a data-action=\"forgotPinEvent\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.forgot.pin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":104,"column":56},"end":{"line":104,"column":87}}}))
    + "</a>\n                    </span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<p class=\"RSAPin-cleared\">\n						<span class=\"icon-tick\"></span>\n						<span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.pin.cleared",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":111,"column":12},"end":{"line":111,"column":44}}}))
    + "</span>\n					</p>\n					<p class=\"RSAToken-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.create.new.token.disp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":113,"column":32},"end":{"line":113,"column":74}}}))
    + "</p>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"RSASoftToken-authorization-wrapper required\">\n                    <label for=\"serialnumber\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.token.serial",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":120,"column":46},"end":{"line":120,"column":79}}}))
    + "</label>\n                    <input class=\"form-control\"\n                           data-bind=\"model\"\n                           data-hook=\"getSerialNumber\"\n                           id=\"tokenString\"\n                           name=\"tokenString\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"tokenString\"></span>\n                </div>\n                <div class=\"RSASoftTokenPin-wrapper\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.serialnumber.instructions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":129,"column":27},"end":{"line":129,"column":73}}}))
    + "</label>\n                    <ol class=\"RSASerialNumber-instructions\">\n                        <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.serialnumber.instruction1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":28},"end":{"line":131,"column":74}}}))
    + "</li>\n                        <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.serialnumber.instruction2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":132,"column":28},"end":{"line":132,"column":74}}}))
    + "</li>\n                        <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.serialnumber.instruction3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":133,"column":28},"end":{"line":133,"column":74}}}))
    + "</li>\n                        <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.serialnumber.instruction4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":134,"column":28},"end":{"line":134,"column":74}}}))
    + "</li>\n                        <li>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.serialnumber.instruction5",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":135,"column":28},"end":{"line":135,"column":74}}}))
    + "</li>\n                    </ol>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form autocomplete=\"off\" data-submit=\"save\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPasscode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNewPin") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNextTokenCode") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showForgotPinModal") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"form-container security-passcode\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSuccessModal") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":33,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNewPin") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":45,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSystemGenerated") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":11},"end":{"line":51,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showConfirmNewPin") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":13},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNextTokenCode") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":75,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPasscode") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":117,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showForgotPinModal") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":12},"end":{"line":138,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </form>\n</div>\n";
},"useData":true});