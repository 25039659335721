export default {
    root: {
        background: '#fff',
        boxShadow: '0 0 10px 0px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        minHeight: 60,
        padding: 10,

        '& svg': {
            height: 32,
            width: 32,
        },
    },
    icon: {
        alignSelf: 'center',
        padding: [0, 10, 0, 0],
    },
    contentArea: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: [10, 0],
    },
    title: {
        margin: 0,
        padding: [0, 0, 10],
    },
    navigationAction: {
        alignSelf: 'center',
        padding: [0, 0, 0, 10],
    },
};
