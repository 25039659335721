import EntryView from 'common/dynamicPages/views/workflow/entry';
import store from 'system/utilities/cache';
import PayUtil from 'common/util/paymentUtil';

export default EntryView.extend({
    initialize(options) {
        EntryView.prototype.initialize.call(this, options);
        this.isRTP = this.tempModel && this.tempModel.context && this.tempModel.context.actionData && this.tempModel.context.actionData.typeCode === 'CRTRAN';
    },

    /**
     * Build the context info object that will get stored in cache and used later in the flow
     * @param {String} serviceName - service name for the object being created ex. /batch/
     * @param {Boolean} loadFromPayments - indicates how values are loaded from the source
     * @param {Boolean} usePaymentContext - true = payment context and false = template context
     * @param {String} returnTab - paymentTab or templateTab
     * @returns {Object}
     */
    buildContextInfo(serviceName, loadFromPayments, usePaymentContext, returnTab) {
        const isReimburse = PayUtil.isReimburse(this.tempModel);
        return {
            functionCode: this.tempModel.get('FUNCTION'),
            serviceName,
            context: usePaymentContext ? this.context.payContext : this.context.tempContext,
            subType: this.tempModel.get('SUBTYPE'),
            tnum: this.tempModel.get('TNUM'),
            loadFromPayments,
            updateCount: this.tempModel.get('UPDATECOUNT__'),
            mode: 'insert',
            templateServiceName: serviceName,
            returnTabStoreName: this.context.returnTabStoreName,
            returnTabStoreValue: returnTab,
            smbOverride: isReimburse,
            reimburse: isReimburse,
        };
    },

    /**
     * Re-direct to the modify screen
     */
    modify() {
        // tempModel will be missing attributes if the user came from another detail screen
        if (this.storedModel && this.tempModel && this.tempModel.isFromDetailScreen) {
            this.tempModel.attributes = this.storedModel.attributes;
        }

        store.set(`${this.contextKey}-actionModel`, this.tempModel);

        // For RTP payments we need to open the SMB modal instead of the full modify screen
        if (this.isRTP) {
            this.openCombinedPaymentModal('modify');
        } else {
            this.navigateTo(this.context.modifyURL);
        }
    },

    /**
     * Create a payment from the current template.
     */
    makeinst() {
        const serviceName = this.tempModel.context.serviceName.replace('/batchTemplate/', '/batch/');
        store.set(this.context.paymentContextStoreName, this.buildContextInfo(serviceName, false, true, 'paymentTab'));
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo(this.context.copyAsPaymentURL);
    },

    /**
     * Create a payment from the current payment.
     */
    copyinst() {
        const contextModel = {
            functionCode: this.tempModel.get('FUNCTION'),
            serviceName: this.tempModel.context.serviceName,
            subType: this.tempModel.get('SUBTYPE'),
            tnum: this.tempModel.get('TNUM'),
            updateCount: this.tempModel.get('UPDATECOUNT__'),
        };
        store.set(this.context.paymentContextStoreName, contextModel);
        store.set(`${this.contextKey}-actionModel`, this.tempModel);

        // For RTP payments we need to open the SMB modal instead of the full create screen
        if (this.isRTP) {
            this.openCombinedPaymentModal('copyinst');
        } else {
            this.navigateTo(this.context.copyPaymentAsPaymentURL);
        }
    },

    /**
     * Create a template from the current payment.
     */
    maketmpl() {
        const serviceName = this.tempModel.context.serviceName.replace('/batch/', '/batchTemplate/');
        store.set(this.context.templateContextStoreName, this.buildContextInfo(serviceName, true, false, 'templateTab'));
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo(this.context.copyPaymentAsTemplateURL);
    },

    /**
     * Create a template from the current template.
     */
    copytmpl() {
        store.set(this.context.templateContextStoreName, this.buildContextInfo(this.tempModel.context.serviceName, true, false, 'templateTab'));
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo(this.context.copyPaymentAsTemplateURL);
    },

    /**
     * Re-direct to the restore screen
     */
    restore() {
        let restoreContext = {
            serviceName: this.tempModel.context.serviceName,
            context: 'SMB_TEMP_EMPLOYEE',
        };
        if (PayUtil.isReimburse(this.tempModel)) {
            restoreContext = {
                ...restoreContext,
                smbOverride: true,
                reimburse: true,
            };
        }
        store.set('smb_restore_context', restoreContext);
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('TEMPLATES/restoreTemplate');
    },

});
