var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"section section-container section-admin\">\n    <div class=\"section-body\">\n\n        <h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.assignedPermissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":48}}}))
    + "</h3>\n\n        <div data-region=\"groupRegion\"></div>\n    </div>\n</section>\n";
},"useData":true});