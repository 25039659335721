var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " required";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"item-row\">\n    <div class=\"item-content\">\n        <fieldset>\n            <div class=\"row\">\n                <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":79}}})) != null ? stack1 : "")
    + " col-md-3\">\n                    <label for=\"fromAcc-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":47}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"CM.FromAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":49},"end":{"line":7,"column":76}}}))
    + "</label>\n                    <input type=\"text\" id=\"fromAcc-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":51},"end":{"line":8,"column":58}}}) : helper)))
    + "\" class=\"form-control\" name=\"ACCOUNT_NUMBER\" data-bind=\"model\" data-hook=\"getFromCombo\" data-select-name=\"DEBIACCOUNT_NUMBERT_ACCOUNT_NUMBER\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNT_NUMBER\"></span>\n                </div>\n                <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":79}}})) != null ? stack1 : "")
    + " col-md-2\">\n                    <label for=\"transferDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":52}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_DATE",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":54},"end":{"line":12,"column":94}}}))
    + "</label>\n                    <input id=\"transferDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":51}}}) : helper)))
    + "\" name=\"VALUE_DATE\" type=\"text\" class=\"input-date form-control\" data-bind=\"model\" autocomplete=\"off\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALUE_DATE\"></span>\n                </div>\n                <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":79}}})) != null ? stack1 : "")
    + " col-md-2\">\n                    <label for=\"debitAmount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":17,"column":44},"end":{"line":17,"column":51}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.AMOUNT",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":86}}}))
    + "</label>\n                    <input type=\"text\" id=\"debitAmount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":18,"column":55},"end":{"line":18,"column":62}}}) : helper)))
    + "\" class=\"form-control\" name=\"DEBIT_AMOUNT\" data-bind=\"model\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_AMOUNT\"></span>\n                </div>\n            </div>\n        </fieldset>\n    </div>\n    <div class=\"remove-button-container\">\n        "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary item-remove","action":"removeItem","locale":"action.remove","name":"close"},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":114}}}))
    + "\n    </div>\n</div>\n";
},"useData":true});