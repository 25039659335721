import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import services from 'services';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import PrintExportUtil from 'common/util/printExportUtil';
import Model from '@glu/core/src/model';
import ExpandRowCellView from 'common/grid/expandRow/expandRowCellView';
import TotalsModel from 'app/balanceAndTransaction/models/accountTotals';
import TotalsView from 'app/balanceAndTransaction/views/accountTotalsView';
import Account from 'app/balanceAndTransaction/models/account';
import BTRGeneralUtil from 'app/balanceAndTransaction/btrUtil';
import userInfo from 'etc/userInfo';
import AccountSections from 'app/balanceAndTransaction/collections/accountSections';
import TransactionDetail from 'app/balanceAndTransaction/views/tieredLoans/tieredLoanTransactionDetail';
import ExportDialog from 'app/balanceAndTransaction/views/tieredLoans/exportTieredLoanAccountDetail';
import BalancePoller from 'app/balanceAndTransaction/models/balancePoller';
import AsOfTimeCellView from 'app/balanceAndTransaction/views/asOfTimeCellView';
import constants from 'app/balanceAndTransaction/constants';
import RefreshBalancesButton from 'app/balanceAndTransaction/views/refreshBalancesButton';
import ViewAccountsDropdown from 'app/balanceAndTransaction/views/viewAccountsDropdown';
import configuration from 'system/configuration';
import errorHandlers from 'system/error/handlers';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import redirectToAssociatedAccountType from 'app/balanceAndTransaction/views/redirectToAssociatedAccountTypeHelper';
import accountUtil from 'app/smbPayments/util/accountUtil';
import { maskValue } from 'common/util/maskingUtil';
import workspaceHelper from 'common/workspaces/api/helper';
import template from './tieredLoanAccountDetail.hbs';

const isClientDeeplink = configuration.isPortal() && configuration.isClient();

/**
 * NH-130560
 * @function setOrGetGridItem
 * @param {Object} noteNumberCellInstance - cache a single instance of a NoteNumberCellView
 * This function is used to set and get a single instance for a NoteNumberCellView. This
 * is part of a somewhat hacky solution for exporting child data for all of the nested
 * child grids on this page. We only need one instance in order to get the proper grid
 * information which is then used in a `transaction` export.
 */
const setOrGetGridItem = util.once(noteNumberCellInstance => noteNumberCellInstance);

const NoteNumberCellView = ExpandRowCellView.extend({

    hideBackHeader: false,

    /**
     * NH-130560
     * Using this override to cache an instance of this view that can be used for exporting
     * transaction details.
     */
    onInitialize() {
        const summaryListViewRowModel = store.get('balanceAndTransactionAccount');
        const account = new Account({
            accountFilter: this.model.get('ACCOUNTFILTER'),
            accountName: this.model.get('ACCOUNTNAME'),
            accountNumber: this.model.get('ACCOUNTNUMBER') || summaryListViewRowModel.get('accountNumber'),
            isRealTime: this.model.get('ISREALTIMEACCOUNT') === '1',
            noteNumber: this.model.get('NOTENUMBER'),
            tabId: summaryListViewRowModel.get('tabId'),
        });

        this.transactionDetail = new TransactionDetail({
            model: account,
        });

        setOrGetGridItem(this);
    },

    onClick() {
        if (this.isExpanded()) {
            // calculate max-width for content inside a td
            const hPaddingWidth = this.$el.innerWidth() - this.$el.width();
            const gridWidth = this.$el.parent().width();

            this.transactionDetail.$el.css('max-width', gridWidth - hPaddingWidth);
            this.showView(this.transactionDetail);
            this.transactionDetail.$el.closest('td').addClass('allow-overflow');
        }
    },
});

const TieredLoanAccountDetail = Layout.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize(options) {
        this.totalsService = options.totalsService || '';
        this.account = options.account;
        Layout.prototype.initialize.call(this, options);

        this.balancePoller = new BalancePoller({
            serviceUrl: services.generateUrl(`${constants.LOAN_ACCTS_SERVICE_PREFIX}accountSummary/requestRealTimeBalances`),
        });
        this.refreshBalancesButton = new RefreshBalancesButton({
            model: this.balancePoller,
        });
        this.listenTo(this.balancePoller, 'foundNewBalances', this.refreshGrid);
        this.listenTo(this.balancePoller, 'change:hasError', this.toggleBalancesError);
        if (isClientDeeplink && !store.has('current-workspace-route')) {
            this.hideBackHeader = true;
        }

        /**
         * Stores the totals columns for the tiered loans details for SMB users.
         * For corp users, the same functionality is already implemented in
         * src/app/balanceAndTransaction/views/accountSummary.js
         */
        if (userInfo.isSmbUser()) {
            const accountSectionsCollection = new AccountSections({
                tabId: options.account.get('tabId') || store.get('btrTabId'),
            });

            const onSuccess = function () {
                BTRGeneralUtil.saveTieredLoansTotalsColumns(
                    accountSectionsCollection,
                    constants.ACCOUNT_SUMMARY.SECTIONS.LOANS,
                );
            };


            accountSectionsCollection.fetch({
                success: util.bind(onSuccess, this),
                error: util.bind(errorHandlers.loading, this),
            });
        }
    },

    onRender() {
        moveToTopCheck(this.model);
        this.loadGrid();
        this.loadTotals();
        this.addRefreshButton();

        this.transactionDetail = new TransactionDetail({
            model: this.account,
        });

        setOrGetGridItem(this);

        // render view account dropdown so user can navigate between different accounts
        if (applicationConfigParams.getValue('GENERAL', 'DISPLAYVIEWANOTHERACCOUNT') === '1') {
            if (!this.viewAccountsDropdown) {
                this.setupViewAccountDropdown();
            } else {
                this.viewAccountsDropdownRegion.show(this.viewAccountsDropdown);
            }
        }
    },

    /*
     * ---------------------------------------------/
     * View Account Dropdown
     * /---------------------------------------------
     */

    setupViewAccountDropdown() {
        this.viewAccountsDropdown = new ViewAccountsDropdown({
            tabId: this.options.account.get('tabId') || store.get('btrTabId'),
            initialAccount: this.account,
            accountSelectCallback: this.onViewAccountSelection.bind(this),
        });

        this.viewAccountsDropdownRegion.show(this.viewAccountsDropdown);
    },

    /**
     * @method onViewAccountSelection
     * @param {object}  acctSelected
     * Callback method that will check the accout selected and if its not a tiered
     * loan selected, will rerender the page,
     * if tiered loan, will direct to tieredloans view and change history
     */
    onViewAccountSelection(acctSelected) {
        this.storeSelectedAccount(acctSelected);
        const updateMasterOnSubAccountEnabled = applicationConfigParams.getValue('TIERLOAN', 'UpdateMasterOnSubAccountEnabled') === '1';
        /**
         * When updateMasterOnSubAccountEnabled is enabled, we will
         * treat all loans as tiered loans
         */
        const isTieredLoan = accountUtil.isLoan(acctSelected.ACCOUNTTYPE)
            && (updateMasterOnSubAccountEnabled || acctSelected.ISTIEREDLOAN);
        // Redirect if tiered loan account selected, since it uses another view
        if (!isTieredLoan) {
            const tabId = this.options.account.get('tabId') || store.get('btrTabId');
            redirectToAssociatedAccountType.call(this, acctSelected, tabId);
        } else {
            this.render();
        }
    },

    /**
     * @method storeSelectedAccount
     * @param {object} acctSelected
     * Stores the user selected account from the account dropdown for display use
     */
    storeSelectedAccount(acctSelected) {
        const newAccount = new Model({
            accountFilter: acctSelected.ACCOUNTFILTER,
            accountNumber: maskValue(acctSelected.ACCOUNTNUM),
            accountName: acctSelected.ACCOUNTNAME,
            accountType: acctSelected.ACCOUNTTYPE,
            currencyCode: acctSelected.CURRENCYCODE,
            clientAccountName: acctSelected.CLIENTACCOUNTNAME,
            subAccountType: acctSelected.ACCOUNTSUBTYPE,
            isRealTime: acctSelected.ISREALTIMEACCOUNT,
            principalBalance: acctSelected.PRINCIPALBALANCE,
            availableCommitmentAmount: acctSelected.AVAILABLECOMMITMENTAMOUNT,
            tabId: acctSelected.tabId,
        });

        store.set('balanceAndTransactionAccount', newAccount);
        this.account = util.extend(newAccount, { totals: store.get('tieredLoanTotals') });
    },

    /**
     * @method redirectToTransactionDetails
     * @param {object} acctSelected
     * Store the account user had selected to view and redirect to a regluar
     * transaction Details page to show account information
     */
    redirectToTransactionDetails(acctSelected) {
        const accountType = acctSelected.ACCOUNTTYPE;
        const redirectRoute = util.contains(constants.ACCOUNT_TYPE.DEPOSIT, accountType)
            ? constants.TRANSACTION_DEPOSIT_DETAIL : constants.TRANSACTION_LOAN_DETAIL;

        // direct to regular transaction listview page
        this.navigateTo(redirectRoute);
    },

    addRefreshButton() {
        this.refreshBalancesButton.setElement(this.$('.refresh-balances-button')).render();
    },

    loadGrid() {
        const self = this;
        const context = {
            productCode: 'GIR',
            functionCode: 'INST',
            typeCode: constants.INQUIRYID_TIERED_LOAN_SUB_ACCOUNT,
            serviceName: 'loanreporting/tieredLoanSubAccount',
            overrideTypeCode: '22484',
        };
        const options = {
            context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            selector: 'none',
            additionalSearchFields: this.getAdditionalSearchFields(),

            cellViews: {
                NOTENUMBER: NoteNumberCellView,
                ASOFTIME: AsOfTimeCellView,
            },

            requestParameters: {
                item: [{
                    name: 'ACCOUNTTYPE',
                    value: this.account.get('accountType'),
                }, {
                    name: 'SUBACCOUNT_TYPE',
                    value: this.account.get('subAccountType'),
                }],
            },

            loadedCallback() {
                self.balancePoller.start();
            },
        };
        this.gridView = GridApi.createServiceGridView(options);
        if (this.gridRegion) {
            this.gridRegion.show(this.gridView);
        }

        this.listenTo(this.gridView, 'gridapi:loaded', this.gridLoaded);
        this.listenTo(this.gridView.getRows(), 'sync', this.rowsLoaded);
    },

    getAdditionalSearchFields() {
        return this.options.additionalSearchFields || [{
            fieldName: 'ACCOUNTFILTER',
            operator: 'EQ',
            fieldValue: [this.account.get('accountFilter')],
            dataType: 'text',
        }];
    },

    loadTotals() {
        this.totalsModel = new TotalsModel({
            filters: this.gridView.wrapper.generateFiltersDataBlock(),
        }, {
            service: services.generateUrl(this.totalsService),
        });

        this.totalsView = new TotalsView({
            model: this.totalsModel,
            isTieredLoans: true,
        });

        if (this.totalsSection) {
            this.totalsSection.show(this.totalsView);
        }
    },

    gridLoaded() {
        const [noteNumberColumn] = this.gridView.wrapper.columns.models;
        noteNumberColumn.set('disableRemove', true);
    },

    rowsLoaded(accounts) {
        this.tellBalancePollerWhichAccountsAreRealTime(accounts);
        this.refreshTotals();
    },

    templateHelpers() {
        return {
            id: this.cid,

            getString(string) {
                return locale.get(string);
            },

            getTitle: () => {
                const { account } = this;
                let accountName = account.get('clientAccountName') ? account.get('clientAccountName') : account.get('accountName');
                accountName = accountName.replace(/&amp;/g, '&');
                return `${accountName} - ${maskValue(account.get('accountNumber'))} - ${account.get('currencyCode')}`;
            },
            hideBackHeader: () => this.hideBackHeader,
        };
    },

    back() {
        // NH-171785: Always send user to current workspace when navigate back
        this.lastFragment = store.get('current-workspace-route') || this.options.returnRoute;
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method print
     *
     * exports the list view as pdf
     */
    print() {
        this.preparePrintExport('PDF');
    },

    /**
     * @method export
     *
     * exports the list view as csv
     */
    export() {
        this.preparePrintExport('CSV');
    },

    /**
     * @method preparePrintExport
     * @param {string} fileFormat - specifies PDF or CSV
     * shows a dialog to choose summary / transaction details,
     * then calls the printExportTieredLoans method.
     */
    preparePrintExport(fileFormat) {
        const exportView = new ExportDialog({ fileFormat });
        this.listenTo(exportView, 'exportTieredLoans', this.printExportTieredLoans);
        dialog.open(exportView);
    },

    /**
     * @method printExportTieredLoans
     * @param {object} dialogModel - contains the request data to print/export
     * export the list view as PDF or CSV.
     */
    printExportTieredLoans(dialogModel) {
        const format = dialogModel.get('fileFormat');
        const options = {
            view: this,
            gridView: this.gridView,
            format,
            columns: null,
            exportURL: configuration.isAdmin()
                ? constants.TRANSACTION_TIERED_LOAN_EXPORT
                : constants.ASYNC_TRANSACTION_TIERED_LOAN_EXPORT,

            searchFields: [{
                fieldName: 'ACCOUNTFILTER',
                operator: 'EQ',
                fieldValue: [this.account.get('accountFilter')],
                dataType: 'text',
            }],
        };

        if (dialogModel.get('expdata') === 'transaction') {
            /*
             * NH-130560
             * Grab a single instance of the nested grid view and call .setPage in order to
             * set all of the proper configs and load the proper data on the nested gridView
             */
            const transactionDetailViewInstance = setOrGetGridItem();
            const { transactionDetail } = transactionDetailViewInstance;
            if (this.account?.attributes) {
                transactionDetail.model.set(this.account.attributes);
            }
            transactionDetail.setPage();
            options.gridView = transactionDetail.gridView;
            transactionDetail.gridView.loadPromise.then(() => {
                options.inquiryId = constants.TRANSACTION_TIERED_LOAN_EXPORT_ID;
                options.gridView.context.typeCode = constants.LOANTRAN;
                options.gridView.context.actionMode = constants.SELECT;
                /*
                 * In order to get ALL nested transaction detail data rather than for just a
                 * single NOTENUMBER, we need to ensure we remove the NOTENUMBER searchField
                 * from the export request payload
                 */
                options.excludeSearchFields = ['NOTENUMBER'];

                /*
                 * NH-166989 need to add NOTENUMBER column always, it should be excluded from the
                 * search fields but it should be included in the columns. copied this from
                 * printExportUtil.js
                 */
                const columns = options.gridView.wrapper.columns
                    .reduce((accum, model) => {
                        if (model.get('condition') !== false) {
                            return [...accum, model.get('field')];
                        }
                        return accum;
                    }, []);

                options.columns = ['NOTENUMBER', ...columns];

                if (format === 'CSV') {
                    PrintExportUtil.export(options);
                } else {
                    PrintExportUtil.print(options);
                }
            });
        } else {
            options.inquiryId = constants.BALANCE_TIERED_LOAN_EXPORT_ID;
            options.gridView.context.typeCode = constants.LOANACCT;
            if (format === 'CSV') {
                PrintExportUtil.export(options);
            } else {
                PrintExportUtil.print(options);
            }
        }
    },

    refreshGrid() {
        if (this.gridView) {
            this.gridView.refreshGridData();
        }
    },

    refreshTotals() {
        this.totalsModel.set({
            filters: this.gridView.wrapper.generateFiltersDataBlock(),
        });
        this.totalsModel.fetch({
            totals: store.get('tieredLoanTotals'),
        });
    },

    toggleBalancesError() {
        this.gridView.$el.toggleClass('has-balance-warning', this.balancePoller.hasError());
    },

    tellBalancePollerWhichAccountsAreRealTime(accounts) {
        const realTimeAccounts = accounts.filter(account => account.get('ISREALTIMEACCOUNT') === '1');

        const accountFilters = realTimeAccounts.map(account => account.get('ACCOUNTFILTER'));
        this.balancePoller.set('accountFilters', accountFilters);

        const subAccountNumbers = realTimeAccounts.map(account => account.get('NOTENUMBER'));
        this.balancePoller.set('subAccountNumbers', subAccountNumbers);
    },
});

export default TieredLoanAccountDetail;
