import Glu from './glu';
import $ from './$';
import util from './util';
import appBus from './appBus';

// Trigger an `http:request` event on all HTTP requests
$.ajaxPrefilter((options) => {
  const { beforeSend } = options;
  const { complete } = options;

  // eslint-disable-next-line func-names
  options.beforeSend = function (xhr, settings) {
    appBus.trigger('http:request', settings.url, xhr, settings.type);

    if (beforeSend) {
      beforeSend.apply(this, arguments);
    }
  };

  // eslint-disable-next-line func-names
  options.complete = function (xhr, ...args) {
    const statusPrefix = (`${xhr.status}`).slice(0, 1);

    if (statusPrefix === '5') {
      appBus.trigger('http:5XX', xhr);
    }

    if (complete) {
      complete.apply(this, [xhr, ...args]);
    }
  };
});

export default {
  statusCodeHandlers: {
    400(result) {
      let response;
      try {
        response = JSON.parse(result.responseText);
      } catch (e) {
        response = result.responseText;
      }
      appBus.trigger('http:400', response);
    },
    401() {
      appBus.trigger('http:401', {
        returnFragment: Glu.history.fragment
      });
    },
    403(xhr) {
      appBus.trigger('http:403', xhr);
    },
    404() {
      appBus.trigger('http:404');
    },
    500() {
      appBus.trigger('http:500');
    },
    504() {
      appBus.trigger('http:504');
    }
  },

  // this method allows you to set headers within a request without specifying
  // additional parameters of your request payload
  setRequestHeaders(headers) {
    if (!headers || typeof headers === 'object' && util.isEmpty(headers)) {
      return;
    }
    $.ajaxSetup({
      beforeSend(xhr) {
        util.each(headers, (value, key) => {
          xhr.setRequestHeader(key, value);
        });
      }
    });
  },

  clearRequestHeaders() {
    $.ajaxSetup({
      beforeSend: null
    });
  },

  request(method, url, data, success, error, options = {}) {
    // ensure that original complete callback will fire along with the appBus event
    const complete = options.silent ? null : ((...args) => {
      appBus.trigger('sync:end', this);
      if (options.complete) {
        options.complete.apply(this, args);
      }
    });

    const params = util.extend({
      type: method,
      url,
      success,
      error,
      statusCode: this.statusCodeHandlers
    }, options, {
      complete
    });

    if (data) {
      params.data = JSON.stringify(data);
      params.contentType = 'application/json';
    }

    if (!options.silent) {
      appBus.trigger('sync:begin', this);
    }

    return $.ajax(params);
  },

  get(url, success, error, options) {
    return this.request('GET', url, null, success, error, options);
  },
  put(url, data, success, error, options) {
    return this.request('PUT', url, data, success, error, options);
  },
  post(url, data, success, error, options) {
    return this.request('POST', url, data, success, error, options);
  },
  del(url, success, error, options) {
    return this.request('DELETE', url, null, success, error, options);
  }
};

