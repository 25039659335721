import React from 'react';
import $ from 'jquery';
import locale from '@glu/locale';
import { appBus } from '@glu/core';
import store from 'system/utilities/cache';
import FakeWorkspaceView from 'app/checkManagement/views/fakeWorkspace';
import Constants from 'app/checkManagement/constants';
import IssuesVoidsList from 'app/checkManagement/views/checkIssueManagement';
import ImportResultsList from 'app/checkManagement/views/importResults';
import PositivePayDecisionList from 'app/checkManagement/views/listPositivePayDecision';
import DecisionHistorySearch from 'app/checkManagement/views/decisionHistorySearch';
import ReversePositivePayDecisionList from 'app/checkManagement/views/listReversePositivePayDecision';
import EPositivePayDecisionList from 'app/checkManagement/views/listEPositivePayDecision';
import ViewPositivePayDecision from 'app/checkManagement/views/viewPositivePayDecision';
import CheckViewer from 'app/checkManagement/views/checkViewerRisk';
import ViewRPPDecision from 'app/checkManagement/views/viewReversePositivePayDecision';
import ViewEPPDecision from 'app/checkManagement/views/viewEPositivePayDecision';
import WorkspaceView from 'common/workspaces/views/workspace';
import FraudControlClientView from 'app/checkManagement/views/fraudControlClientView';
import StopCancelCheckView from 'app/checkManagement/views/stopCancelCheckView';
import MultipleEntryView from 'app/checkManagement/views/multipleIssueVoidEntry';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import PlaceStopMultiView from 'app/checkManagement/views/placeStopMultiView';
import authConstants from 'app/checkManagement/views/achauthrules/constants';
import ACHAuthRulesList from 'app/checkManagement/views/achauthrules/achAuthRulesList';
import AuthRulesEntryView from 'app/checkManagement/views/achauthrules/achAuthRulesEntry';
import StopPaymentsForm from '@dbiqe/form-stop-payments';
import { removeViewIdFromRoute } from 'system/utilities/routeHelper';
import ViewPayAndAdjustPositivePayDecision from 'app/checkManagement/views/viewPayAndAdjustPositivePayDecision';
import ClientListView from 'components/AchClientView/achClientListView';
import StopPayments from './collections/stopPayments';


export default {
    riskIssueVoids(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('RISK/issueVoids', this)) {
            const title = locale.get('menuitem.cm.iv.listView');
            const options = {
                pageTitle: title,
                returnRoute: 'RISK/issueVoids',
                viewId,
            };
            this.showPage(title, new IssuesVoidsList(options));
        }
    },


    riskMultipleEntry() {
        const title = locale.get('menuitem.cm.checkIssueVoids');
        const options = {
            pageTitle: title,
        };
        this.showPage(title, new MultipleEntryView(options));
    },

    riskPaymentFraudControl() {
        if (processDeeplink('RISK/paymentFraudControlView', this)) {
            const title = locale.get('menuitem.cm.paymentFraudControl');
            const options = {
                pageTitle: title,
                returnRoute: 'RISK/paymentFraudControlView',
            };
            this.showPage(title, new FraudControlClientView(options));
        }
    },

    riskPaymentFraudControlWorkspace(viewId) {
        removeViewIdFromRoute(viewId);
        store.set('risk-viewId', viewId);
        this.showPage('', new WorkspaceView({
            id: 'PAYMENT_FRAUD_CONTROL',
            returnRoute: 'RISK/paymentFraudControl',
        }));
    },

    riskAchAuthRulesWorkspace(viewId) {
        removeViewIdFromRoute(viewId);
        store.set('risk-viewId', viewId);
        this.showPage('', new WorkspaceView({
            id: 'ACHAUTHRULES',
            returnRoute: 'RISK/achAuthRulesControl',
        }));
    },

    riskPositivePayDecision() {
        const options = {
            returnRoute: 'RISK/paymentFraudControl',
            viewAll: store.get('cm_listView_payDecision-viewAll'),
        };

        const returnRoute = store.get('cm_listView_paymentFraudControl-returnRoute');
        if (returnRoute) {
            options.returnRoute = returnRoute;
        }

        this.showPage('Payment Fraud Control Decisioning', new PositivePayDecisionList(options));
    },

    riskReversePositivePayDecision() {
        const options = {
            returnRoute: 'RISK/paymentFraudControl',
            viewAll: store.get('cm_listView_payDecision-viewAll'),
        };

        const returnRoute = store.get('cm_listView_paymentFraudControl-returnRoute');
        if (returnRoute !== null) {
            options.returnRoute = returnRoute;
        }
        this.showPage('Payment Fraud Control Decisioning', new ReversePositivePayDecisionList(options));
    },

    riskEPositivePayDecision() {
        const options = {
            returnRoute: 'RISK/paymentFraudControl',
            viewAll: store.get('cm_listView_payDecision-viewAll'),
        };

        const returnRoute = store.get('cm_listView_paymentFraudControl-returnRoute');
        if (returnRoute !== null) {
            options.returnRoute = returnRoute;
        }
        this.showPage('Payment Fraud Control Decisioning', new EPositivePayDecisionList(options));
    },

    riskDecisionHistory() {
        const returnRoute = store.get('cm_listView_paymentFraudControl-returnRoute');
        const fraudControlModel = store.get('cm_listView_paymentFraudControl-actionModel');

        const options = {
            fraudControlModel,
            filterId: fraudControlModel ? Constants.filterId[fraudControlModel.get('TYPE')] : null,
            returnRoute: 'RISK/paymentFraudControl',
            accountFilter: fraudControlModel ? fraudControlModel.get('ACCOUNTFILTER') : null,
        };

        if (returnRoute !== null) {
            options.returnRoute = returnRoute;
        }

        this.showPage(locale.get('cm.decision.history.title'), new DecisionHistorySearch(options));
    },


    riskPayAndAdjustPositivePayDecisionItems() {
        let retRoute;

        if (store.get('cm_listView_payDecision-viewAll')) {
            retRoute = store.get('current-workspace-route');
        } else if (store.get('cm_listView_positivePayDecision-returnRoute')) {
            retRoute = store.get('cm_listView_positivePayDecision-returnRoute');
        } else {
            retRoute = 'RISK/payAndAdjustPositivePayDecisionItems';
        }

        const options = {
            menuCategory: 'RISK',
            serviceName: 'cm/listView/positivePayDecision',
            serviceFunc: null,
            businessType: null,
            context: '',
            mode: 'modify',
            returnRoute: retRoute,
        };

        this.showPage('', new ViewPayAndAdjustPositivePayDecision(options));
    },

    importResults() {
        const options = {
            returnRoute: 'RISK/paymentFraudControl',
        };
        const returnRoute = store.get('cm_listView_paymentFraudControl-returnRoute');
        if (returnRoute !== null) {
            options.returnRoute = returnRoute;
        }
        this.showPage('Import Results', new ImportResultsList(options));
    },

    riskViewPositivePayDecision() {
        let retRoute;

        if (store.get('cm_listView_payDecision-viewAll')) {
            retRoute = store.get('current-workspace-route');
        } else {
            retRoute = 'RISK/positivePayDecision';
        }

        const options = {
            menuCategory: 'RISK',
            serviceName: 'cm/listView/positivePayDecision',
            serviceFunc: null,
            businessType: null,
            context: '',
            mode: 'view',
            returnRoute: retRoute,
        };

        this.showPage('', new ViewPositivePayDecision(options));
    },

    riskViewPositivePayDecisionItems() {
        let checks;
        let retRoute;

        if (store.has('cm_listView_positivePayDecision-actionModels')) {
            checks = store.get('cm_listView_positivePayDecision-actionModels');
            store.set('cm_listView_positivePayDecision-actionModel', checks[0]);
        } else if (store.has('cm_listView_positivePayDecision-actionModel')) {
            checks = [store.get('cm_listView_positivePayDecision-actionModel')];
        } else {
            // No checks found, redirect to fraud control main page
            appBus.trigger('router:navigate', this.getRoute(true), true);
            return;
        }

        if (checks.length < 2) {
            this.riskViewPositivePayDecision();
        } else {
            if (store.get('cm_listView_payDecision-viewAll')) {
                retRoute = store.get('current-workspace-route');
            } else {
                retRoute = 'RISK/positivePayDecision';
            }

            this.showPage('', new CheckViewer({
                checks,
                entryClass: ViewPositivePayDecision,
                serialNumberField: 'CHECKNUMBER',

                entryCfg: {
                    menuCategory: 'RISK',
                    serviceName: 'cm/listView/positivePayDecision',
                    serviceFunc: null,
                    businessType: null,
                    context: '',
                    mode: 'view',
                    returnRoute: retRoute,
                },
            }));
        }
    },

    riskViewReversePositivePayDecision() {
        let retRoute;

        if (store.get('cm_listView_payDecision-viewAll')) {
            retRoute = store.get('current-workspace-route');
        } else {
            retRoute = 'RISK/reversePositivePayDecision';
        }

        const options = {
            menuCategory: 'RISK',
            serviceName: 'cm/listView/reversePositivePayDecision',
            serviceFunc: null,
            businessType: null,
            context: '',
            mode: 'view',
            returnRoute: retRoute,
        };

        this.showPage('', new ViewRPPDecision(options));
    },

    riskViewReversePositivePayDecisionItems() {
        let checks;
        let retRoute;

        if (store.has('cm_listView_reversePositivePayDecision-actionModels')) {
            checks = store.get('cm_listView_reversePositivePayDecision-actionModels');
            store.set('cm_listView_reversePositivePayDecision-actionModel', checks[0]);
        } else if (store.has('cm_listView_reversePositivePayDecision-actionModel')) {
            checks = [store.get('cm_listView_reversePositivePayDecision-actionModel')];
        } else {
            // No checks found, redirect to fraud control main page
            appBus.trigger('router:navigate', this.getRoute(true), true);
            return;
        }

        if (checks.length < 2) {
            this.riskViewReversePositivePayDecision();
        } else {
            if (store.get('cm_listView_payDecision-viewAll')) {
                retRoute = store.get('current-workspace-route');
            } else {
                retRoute = 'RISK/reversePositivePayDecision';
            }

            store.set('current-workspace-route', retRoute);

            this.showPage('', new CheckViewer({
                checks,
                entryClass: ViewRPPDecision,
                serialNumberField: 'CHECKNUMBER',

                entryCfg: {
                    menuCategory: 'RISK',
                    serviceName: 'cm/listView/reversePositivePayDecision',
                    serviceFunc: null,
                    businessType: null,
                    context: '',
                    mode: 'view',
                    returnRoute: retRoute,
                },
            }));
        }
    },

    riskViewEPositivePayDecision() {
        let retRoute;

        if (store.get('cm_listView_payDecision-viewAll')) {
            retRoute = store.get('current-workspace-route');
        } else {
            retRoute = 'RISK/ePositivePayDecision';
        }

        const options = {
            menuCategory: 'RISK',
            serviceName: 'risk/listView/ePositivePayDecision',
            serviceFunc: null,
            businessType: null,
            context: '',
            mode: 'view',
            returnRoute: retRoute,
        };

        this.showPage('', new ViewEPPDecision(options));
    },

    riskViewEPositivePayDecisionItems() {
        let checks;
        let retRoute;

        if (store.has('risk_listView_ePositivePayDecision-actionModels')) {
            checks = store.get('risk_listView_ePositivePayDecision-actionModels');
            store.set('risk_listView_ePositivePayDecision-actionModel', checks[0]);
        } else if (store.has('risk_listView_ePositivePayDecision-actionModel')) {
            checks = [store.get('risk_listView_ePositivePayDecision-actionModel')];
        } else {
            // No checks found, redirect to fraud control main page
            appBus.trigger('router:navigate', this.getRoute(true), true);
            return;
        }

        if (checks.length < 2) {
            this.riskViewEPositivePayDecision();
        } else {
            if (store.get('cm_listView_payDecision-viewAll')) {
                retRoute = store.get('current-workspace-route');
            } else {
                retRoute = 'RISK/ePositivePayDecision';
            }

            this.showPage('', new CheckViewer({
                checks,
                entryClass: ViewEPPDecision,
                serialNumberField: 'CHECKNUMBER',

                entryCfg: {
                    menuCategory: 'RISK',
                    serviceName: 'risk/listView/ePositivePayDecision',
                    serviceFunc: null,
                    businessType: null,
                    context: '',
                    mode: 'view',
                    returnRoute: retRoute,
                },
            }));
        }
    },

    underConstruction() {
        const options = {
            title: 'Under Construction',
        };
        this.showPage('', new FakeWorkspaceView(options));
        $('.alert-warning').html('Under Construction');
    },

    stopCancelCheckView() {
        const options = {
            context: 'PAY_CM_IV_LIST',
            model: store.get('cm_listView_stopCancels-actionModel'),
            mode: 'view',
        };

        this.showPage('', new StopCancelCheckView(options));
    },

    /**
     * @name mdfMultiAdd
     * @description action method to display the multi-stop payment page
     */
    mdfMultiAdd() {
        this.showPage('', <StopPaymentsForm />); // eslint-disable-line
    },

    /**
     * @name mdfMultiPrefill
     * @description action method to display the multi-stop payment page pre-filled
     * with data (from check inquiry)
     * @param type (STOP)
     */
    mdfMultiPrefill(type) {
        if (type === 'STOP') {
            this.showPage('', <StopPaymentsForm />);
            return;
        }
        this.pushPage(
            locale.get('payment.insert.stop.cancel'),
            new PlaceStopMultiView({
                type,
                localeKey: 'cm.stopCancels_',
                addButtonLabel: 'title.stopPaymentsItems',
                collectionClass: StopPayments,
                placeStopModels: store.get('cminst_cancelStop-actionModel'),
            }),
        );
    },

    /**
     * @name riskAchAuthRulesList
     * @description action method to display the ach auth rules listview
     */
    riskAchAuthRulesList() {
        if (processDeeplink(authConstants.ACHAUTHRULES_RETURNROUTE, this)) {
            const title = 'ACH Auth Rules';
            const options = {
                pageTitle: title,
                returnRoute: authConstants.ACHAUTHRULES_RETURNROUTE,
            };
            this.showPage(title, new ACHAuthRulesList(options));
        }
    },

    /**
     * @name authRulesAdd
     * @description action method to display the ach auth rules insert page
     */
    authRulesAdd() {
        const contextKey = store.get('listView-contextKey');
        const contextOverride = store.get(`${contextKey}-contextOverride`);
        const options = {
            contextOverride,
            mode: 'insert',
        };
        this.showPage('', new AuthRulesEntryView(options));
    },

    /**
     * @name authRulesView
     * @description action method to display the ach auth rules view page
     */
    authRulesView() {
        const contextKey = store.get('listView-contextKey');
        const model = store.get(`${contextKey}-actionModel`);
        model.context.serviceName = authConstants.ACHAUTHRULES_CONTEXT_SERVICENAME;
        model.context.typeCode = Constants.ACH_AUTH_RULES_TYPE;
        const options = {
            mode: 'view',
            serviceName: authConstants.ACHAUTHRULES_CONTEXT_SERVICENAME,
            model,
        };
        this.showPage('', new AuthRulesEntryView(options));
    },

    /**
     * @name authRulesModify
     * @description action method to display the ach auth rules view page
     */
    authRulesModify() {
        const contextKey = store.get('listView-contextKey');
        const model = store.get(`${contextKey}-actionModel`);
        model.context.serviceName = authConstants.ACHAUTHRULES_CONTEXT_SERVICENAME;
        model.context.typeCode = Constants.ACH_AUTH_RULES_TYPE;
        const options = {
            mode: 'modify',
            serviceName: authConstants.ACHAUTHRULES_CONTEXT_SERVICENAME,
            model,
        };
        this.showPage('', new AuthRulesEntryView(options));
    },
    achClientViewWorkspace(viewId) {
        store.set('ach-viewId', viewId);
        this.showPage('', new WorkspaceView({
            id: 'ACHCONTROLTOTAL',
            returnRoute: 'RISK/achClientView',
        }));
    },
    clientAchControlTotal() {
        this.showPage('', <ClientListView />);
    },
    /**
     * @method getRoute
     *
     * @param {boolean}[unset] Optional flag.
     * If set the route is detached from the key 'current-workspace-route' in the store
     *
     */
    getRoute(unset) {
        let retRoute = store.get('current-workspace-route');
        if (retRoute && unset) {
            /*
             * OK to unset as the route is used for navigating back
             * to the current workspace/widget
             */
            store.unset('current-workspace-route');
        } else {
            // in case not found, just return the route to the Payment Fraud workspace
            retRoute = 'RISK/paymentFraudControl';
        }
        return retRoute;
    },
};
