var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"row\">\n        <div class=\"col-12\">\n            <div data-region=\"paymentTypesRegion\" class=\"payment-Types-Region\"></div>\n        </div>\n    </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payment.types.select.group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":11},"end":{"line":6,"column":65}}}))
    + "</p>\n\n        <div class=\"checkbox\">\n            <input data-hook=\"type-selectAllPaymentTypes\" type=\"checkbox\" id=\"selectAllPaymentTypes\" class=\"select-all-payment-types\">\n            <label for=\"selectAllPaymentTypes\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.selectall",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":76}}}))
    + "</label>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    <div data-region=\"assignedPermissionsRegion\" class=\"assigned-permissions-region\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div data-region=\"restrictedTemplatesRegion\" class=\"restricted-templates-region\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||alias2).call(alias1,{"name":"panel","hash":{"theme":"panel-primary panel-payment","collapsed":(lookupProperty(helpers,"hasSelection")||(depth0 && lookupProperty(depth0,"hasSelection"))||alias2).call(alias1,{"name":"hasSelection","hash":{},"data":data,"loc":{"start":{"line":4,"column":51},"end":{"line":4,"column":65}}}),"locale":"uce.selectPaymentTypes"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":20,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||alias2).call(alias1,{"name":"panel","hash":{"theme":"panel-primary perms-panel","collapsed":(lookupProperty(helpers,"noSelection")||(depth0 && lookupProperty(depth0,"noSelection"))||alias2).call(alias1,{"name":"noSelection","hash":{},"data":data,"loc":{"start":{"line":22,"column":57},"end":{"line":22,"column":70}}}),"locale":"uce.assignPaymentPermissions"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":24,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||alias2).call(alias1,{"name":"panel","hash":{"theme":"panel-primary templates-panel","collapsed":true,"locale":"uce.restrictedTemplates"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":0},"end":{"line":28,"column":10}}})) != null ? stack1 : "");
},"useData":true});