export default {
    container: {
        display: 'flex',
    },
    input: {
        width: '100%',
        fontWeight: 'normal',
        margin: 0,
        '& p': {
            margin: 0,
        },
    },
};
