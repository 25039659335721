import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import transform from 'common/util/transform';
import services from 'services';
import util from '@glu/core/src/util';
import moment from 'moment';
import http from '@glu/core/src/http';

const RowModel = Model.extend({
    defaults: {
        disabled: false,
        selected: false,
    },

    parse(res) {
        return transform.pairsToHash(res.mapDataList, 'toField', 'value');
    },
});

export default Collection.extend({
    initialize() {
        this.listenTo(
            this,
            {
                'change:disabled sync': this.enforceConstraints,

                // in case the grid loads before the filters
                sync: this.setTabsDisabled,

                'change:selected': this.applyFilter,
            },
        );
    },

    model: RowModel,

    applyFilter(model, value) {
        if (value) {
            this.deselectOthers(model);
        }

        /*
         * add filter if value === true
         * remove filter if value === false
         * use the result to create OR remove a filter
         */

        // some filters apply to multiple columns - need an array for these
        const filters = [];

        switch (model.get('LABEL')) {
        case 'All Bills Year-To-Date':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['FP'],
            });

            filters.push({
                field: 'ISSUE_DATE',
                type: 'date',
                equality: 'GTEQ',
                value: [moment(new Date()).startOf('year').format('MM/DD/YYYY')],
            });

            break;

        case 'All Outstanding':
            switch (model.get('TAB')) {
            case 'Enter':
                filters.push({
                    field: 'STATUS',
                    type: 'multistring',
                    value: ['AV', 'IC'],
                });

                break;

            case 'Master':
                filters.push({
                    field: 'STATUS',
                    type: 'multistring',
                    value: ['EN', 'AV', 'IC', 'AP', 'PT'],
                });

                break;
            default:
            }

            break;

        case 'Approved':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['AP'],
            });

            break;

        case 'Awaiting Approval':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['EN'],
            });

            break;

        case 'Awaiting Payment':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['AP'],
            });

            break;

        case 'Awaiting Validation':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['AV'],
            });

            break;

        case 'Incomplete':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['IC'],
            });

            break;

        case 'Past Due':
            filters.push({
                field: 'DUE_DATE',
                type: 'date',
                equality: 'LT',
                value: [moment(new Date()).format('MM/DD/YYYY')],
            });

            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['EN', 'AV', 'IC', 'AP', 'PT'],
            });

            break;

        case 'Total Paid':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['FP'],
            });

            break;

        case 'Total Paid Month-To-Date':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['FP'],
            });

            filters.push({
                field: 'ISSUE_DATE',
                type: 'date',
                equality: 'GTEQ',
                value: [moment(new Date()).startOf('month').format('MM/DD/YYYY')],
            });

            break;

        case 'Total in Process':
            switch (model.get('TAB')) {
            case 'Validate':
                filters.push({
                    field: 'STATUS',
                    type: 'multistring',
                    value: ['AV', 'EN'],
                });

                break;

            case 'Approve':
                filters.push({
                    field: 'STATUS',
                    type: 'multistring',
                    value: ['AP', 'EN'],
                });

                break;

            case 'Pay':
                filters.push({
                    field: 'STATUS',
                    type: 'multistring',
                    value: ['AP', 'PT'],
                });

                break;
            default:
            }

            break;

        case 'Validated':
            filters.push({
                field: 'STATUS',
                type: 'multistring',
                value: ['EN'],
            });

            break;

        default:
                // console.error('no filter found', model.toJSON());
        }

        if (!(this.gridView && this.gridView.gridView.grid
            && this.gridView.gridView.grid.filterProc)) {
            return;
        }

        if (value) {
            // bulk add filter
            this.gridView.gridView.grid.filterProc.filters.add(filters);
            this.gridView.gridView.grid.filterProc.process();
        } else {
            util.each(filters, function (filterObj) {
                this.gridView.gridView.grid.filterProc.removeFilters(filterObj.field);
            }, this);
        }
    },

    setGridView(gridView) {
        if (this.gridView) {
            this.stopListening(this.gridView.gridView);
        }

        this.gridView = gridView;
        this.loadEventCount = 0;
        this.listenTo(
            this.gridView.gridView,
            {
                // wait for grid filterProc to be available
                'gridapi:loaded': this.waitForGridReady,
            },
        );

        this.setTabsDisabled();
    },

    waitForGridReady() {
        this.loadEventCount += 1;
        if (this.loadEventCount === 2) {
            this.stopListening(
                this.gridView.gridView,
                {
                    'gridapi:loaded': this.waitForGridReady,
                },
            );
            this.gridReady();
        }
    },

    gridReady() {
        const selectedTab = this.findWhere({
            selected: true,
        });
        this.applyFilter(selectedTab, true);

        this.listenTo(
            this.gridView.gridView,
            {
                'gridapi:loaded': this.saveSelectedAndFetch,
            },
        );
    },

    saveSelectedAndFetch() {
        this.selected = this.findWhere({
            selected: true,
        });

        this.fetch();
    },

    restoreSelected() {
        if (this.selected) {
            const newSelected = this.findWhere({
                TAB: this.selected.get('TAB'),
                LABEL: this.selected.get('LABEL'),
            });

            if (newSelected) {
                newSelected.set({
                    selected: true,
                });
            }
        }
    },

    setTabsDisabled() {
        if (util.isUndefined(this.gridView)) {
            return;
        }

        this.each(function (model) {
            const tabsToShowOn = model.get('TAB').split(',');
            model.set({
                disabled: !util.contains(tabsToShowOn, this.gridView.name),
            });
        }, this);
    },

    deselectOthers(model) {
        this.each((m) => {
            if (m !== model) {
                m.set({
                    selected: false,
                });
            }
        });
    },

    enforceConstraints() {
        this.restoreSelected();

        const legalSelected = this.findWhere({
            selected: true,
            disabled: false,
        });
        if (legalSelected) {
            // we're probably ok
            return;
        }

        const firstEnabledModel = this.findWhere({
            disabled: false,
        });
        if (firstEnabledModel) {
            firstEnabledModel.set({
                selected: true,
            });
        }
    },

    parse(res) {
        let rows = [];
        if (res && res.queryResponse && res.queryResponse.QueryData
            && res.queryResponse.QueryData.queryRows) {
            rows = res.queryResponse.QueryData.queryRows;
        }

        return rows;
    },

    sync(method, collection, options) {
        let xhr;

        if (method === 'read') {
            xhr = http.post(
                services.generateUrl('/inquiry/getQueryResults'),
                {
                    requestHeader: {
                        queryOffset: 0,
                        queryPagesize: 0,
                        requestId: '',
                    },

                    IncludeMapData: 1,

                    queryCriteria: {
                        action: {
                            typeCode: 'BILL',
                            entryMethod: 0,
                            actionMode: 'SELECT',
                            productCode: 'BOS',
                            functionCode: 'MAINT',
                        },

                        fieldName: false,
                        inquiryId: '19809',
                        allowDuplicates: true,
                        queryType: 'Query',
                    },

                    formatterType: 'Query',
                },
                options.success,
                options.error,
            );
        }

        this.trigger('request', this, xhr, options);

        return xhr;
    },
});
