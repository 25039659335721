import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import DataApi from 'common/dynamicPages/api/data';
import ListView from 'common/dynamicPages/views/workflow/list';
import Constants from 'app/administration/constants';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './passthroughListView.hbs';

const PassThroughListView = Layout.extend({
    template,

    initialize() {
        const context = {
            productCode: 'USACH',
            functionCode: 'BATCH',
            typeCode: 'PASSTHRU',
        };
        this.paymentModel = store.get('passthru_view_payment_model');
        this.productCode = context.productCode;
        this.functionCode = context.functionCode;
        this.typeCode = context.typeCode;
        this.isModal = this.options.isModal || false;
        this.isAdminContext = this.options.parent.isAdminContext;

        ListView.prototype.setListViewConfig.call(this, context);
    },

    onRender() {
        if (!this.setHasLoadedRequiredData()) {
            this.loadGrid();
            this.setHasLoadedRequiredData(true);
        }
    },

    gridLoadComplete() {
        $('#batchCount').html(this.gridView.wrapper.rows.totalCount);
        if (this.gridView.wrapper.rows.totalCount === 0) {
            $('#batchInfoPanel').hide();
        }
        store.set('batchInfoGridLoaded', true);
        this.options.parent.trigger('bothGridsLoaded');
    },

    templateHelpers() {
        return {
            getButtonString(code) {
                return locale.get(code);
            },
        };
    },

    loadGrid() {
        const productCode = this.isAdminContext ? 'ADMPAY' : this.productCode;
        const typeCode = this.isAdminContext ? 'PAYMENTS' : this.typeCode;
        const functionCode = this.isAdminContext ? 'INST' : this.functionCode;
        const requestParamsValue = this.isAdminContext
            ? `?!_init=true&_productCode=ADMPAY&_functionCode=INST&_typeCode=PAYMENTS&_mode=SELECT&_gridId=${this.inquiryId}`
            : `?!_init=true&_productCode=USACH&_functionCode=BATCH&_typeCode=PASSTHRU&_mode=SELECT&_gridId=${this.inquiryId}`;

        this.inquiryId = this.options.inquiryId || Constants.PASSTHROUGH_PAYLISTVIEW_INQUIRYID;

        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode,
                        productCode,
                        functionCode,
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: this.inquiryId,
                },
            },
        };

        this.customFilters = [{
            filterName: 'Depends',
            filterParam: ['SURROGATETNUM', store.get('SURROGATETNUM')],
        }];

        data.inquiryRequest.searchCriteria.customFilters = this.customFilters;

        const contextLocal = {
            actionMode: 'SELECT',
            displayOrder: 1,
            functionCode,
            inquiryId: this.inquiryId,
            gridId: 0,
            nonDashboardDisplay: 0,
            productCode,
            requestParams: requestParamsValue,
            typeCode,
        };

        const options = {
            context: contextLocal,
            filter: false,
            selector: 'none',
            hideButtons: true,

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
            lvc: this.lvc,
        };
        this.gridView = GridApi.createInquiryGridView(options);
        this.listenTo(this.gridView, 'gridapi:loaded', this.gridLoadComplete);
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        ListView.prototype.setupGridAvailableListener.call(this);

        if (this.tableContent) {
            if (mobileUtil.isMobileGridEnabled()) {
                let MobileList = PassThroughListView;
                const mobileList = configureMobileInterface(MobileList, {
                    useGridViewFromOptions: true,
                });
                MobileList = MobileList.extend(mobileList);
                this.tableContent.show(new MobileList({
                    ...this.options,
                    ...options,
                    renderMobileGridUsingTemplate: false,
                    prebuiltOptions: true,
                    gridView: this.gridView,
                    skipEntitlements: true,
                    enableSavedViews: false,
                    disableFilters: true,
                }));
            } else {
                this.tableContent.show(this.gridView);
            }
        }
    },

    gridRowAction(row) {
        return new Promise((resolve) => {
            const self = this;
            let subType = 'NACHA';
            const serviceName = row.model.get('SERVICENAME');
            if (serviceName !== '/batch/NACHAFileImport' && serviceName !== '/batch/passthru') {
                subType = 'CAEFT';
            }
            if (!self.isModal) {
                const keyListGlobal = ['TNUM', 'UPDATECOUNT__'];
                const model = DataApi.model.generateFromKeyList({
                    context: {
                        serviceName,
                        subType: 'NACHA',

                        actionData: {
                            entryClass: row.model.get('ENTRYCLASS'),
                            entrymethod: '3',
                        },
                    },

                    keyList: keyListGlobal,
                });
                model.set('TNUM', row.model.get('TNUM'));
                model.set('SUBTYPE', subType);
                model.set('FUNCTION', 'BATCH');
                model.set('UPDATECOUNT__', row.model.get('UPDATECOUNT__'));
                model.fetch({
                    success(mParam) {
                        const m = mParam;
                        self.model = m;
                        self.storedModel = m;
                        m.context.subType = subType;
                        store.set('payment_listView_corp-actionModel', self.storedModel);
                        self.navigateTo('PAYMENTS/viewPayment');
                        resolve({ model: mParam });
                    },
                });
            }
            resolve();
        });
    },
});

export default PassThroughListView;
