import React, { useState } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';

export default function Rename(props) {
  const {
    classes,
    rename,
    toggleRenaming,
    view
  } = props;
  const {
    renameWrapper,
    saveCancelButtons
  } = classes;

  const [name, onNameChange] = useState(view.viewData.name);
  return (
    <div className={renameWrapper}>
      <form onSubmit={(e) => {
        e.preventDefault();
        rename(view.viewData.id)(name);
        toggleRenaming(false);
      }}
      >
        <input
          value={name}
          maxLength={25}
          aria-label={locale.get('dataComponents.viewName')}
          data-qa={`rename-input-${view.viewData.id}`}
          onChange={(event) => {
            const { target: { value } } = event;
            onNameChange(value);
          }}
        />
        <div
          className={saveCancelButtons}
        >
          <button
            disabled={!name.length}
            data-qa={`rename-save-${view.viewData.id}`}
            type="submit"
          >
            {locale.get('dataComponents.save')}
          </button>
          <button
            data-qa={`rename-cancel-${view.viewData.id}`}
            onClick={() => {
              toggleRenaming(false);
            }}
            type="button"
          >
            {locale.get('dataComponents.cancel')}
          </button>
        </div>
      </form>
    </div>
  );
}

Rename.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  rename: PropTypes.func.isRequired,
  toggleRenaming: PropTypes.func.isRequired,
  view: PropTypes.shape({
    viewData: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDefault: PropTypes.bool,
      isStatic: PropTypes.bool,
      name: PropTypes.string
    })
  })
};

Rename.defaultProps = {
  view: PropTypes.shape({
    id: 0,
    name: ''
  })
};
