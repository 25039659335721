import locale from '@glu/locale';

const useLocale = () => {
    function getLocaleString(key, ...params) {
        return locale.get(key, ...params);
    }

    function getLocaleStringDefault(key, defaultValue, ...params) {
        return locale.getDefault(key, defaultValue, ...params);
    }

    return {
        getLocaleString,
        getLocaleStringDefault,
    };
};

export default useLocale;
