import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { LinesNextIcon } from '@glu/icons-react';
import {
  Button, TERTIARY, SECONDARY, EXTRA_SMALL
} from '@glu/buttons-react';
import locale from '@glu/locale';
import PinColumn from './PinColumn';
import ColumnFilter from './ColumnFilter';
import styles from './ColumnMenu.style';

const ColumnMenu = ({
  classes,
  column,
  columnApi,
  dataQa,
  context,
  displayName,
  showMenuBtn,
  filters,
  ...rest
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);
  const [pinned, setPinned] = useState();
  const closeMenuWithTimeout = useRef(null);
  let selectedFilterValue = null;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onMenuBtnLeave = () => {
    closeMenuWithTimeout.current = setTimeout(() => {
      setIsMenuOpen(false);
    }, 400);
  };

  const clearBtnLeave = () => {
    clearTimeout(closeMenuWithTimeout.current);
  };

  const getFilterData = (filterData) => {
    selectedFilterValue = filterData;
  };

  const apply = () => {
    setClearSelection(false);
    if (selectedFilterValue && selectedFilterValue.name === 'filters') {
      context.onChange(selectedFilterValue);
    }
    columnApi.setColumnPinned(column.colId, pinned);
  };

  const clear = () => {
    selectedFilterValue = null;
    setClearSelection(true);
  };

  return (
    <div className={classes.menuWrap}>
      { showMenuBtn && (
        <button
          data-qa={dataQa ? `menuOpenBtn-${dataQa}` : `menuOpenBtn-${column.colId}`}
          onClick={toggleMenu}
          type="button"
          className={classes.menuButton}
        >
          <LinesNextIcon />
        </button>
      )}

      {isMenuOpen && (
        <div
          data-qa={dataQa ? `menuWrap-${dataQa}` : `menuWrap-${column.colId}`}
          onMouseEnter={clearBtnLeave}
          onMouseLeave={onMenuBtnLeave}
          className={classes.menu}
        >
          <PinColumn
            classes={classes}
            column={column}
            pinnedClear={clearSelection}
            onChange={setPinned}
          />

          <ColumnFilter
            {...rest}
            clearSelection={clearSelection}
            displayName={displayName}
            setClearSelection={setClearSelection}
            getFilterData={
              (filterData) => {
                getFilterData(filterData);
              }
            }
            datePickerProps={{
              forcePositionY: true
            }}
            data={{
              ...context.data,
              filters
            }}
            records={context.records}
            filterables={context.data.columns.filter(col => col.filterable !== false)}
            columnApi={columnApi}
            column={column}
          />

          <div className={classes.menuFooter}>
            <Button
              onClick={apply}
              className={classes.btnApply}
              text={locale.get('apply')}
              variant={SECONDARY}
              size={EXTRA_SMALL}
            />
            <Button
              onClick={clear}
              className={`${classes.btnClear} ${classes.btnSmall}`}
              text={locale.get('clear')}
              variant={TERTIARY}
              size={EXTRA_SMALL}
            />
          </div>
        </div>

      )}

    </div>
  );
};

ColumnMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  /** Ag grid context */
  context: PropTypes.objectOf(PropTypes.any).isRequired,
  showMenuBtn: PropTypes.bool,
  /** Array of filters to display */
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Column definition for header provided by ag-grid */
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
    userProvidedColDef: PropTypes.object.isRequired,
    colId: PropTypes.string.isRequired,
    pinned: PropTypes.oneOf(['left', 'right'])
  }).isRequired,
  /** ag-grid api to control column */
  columnApi: PropTypes.shape().isRequired,
  dataQa: PropTypes.string,
  displayName: PropTypes.string
};

ColumnMenu.defaultProps = {
  dataQa: '',
  displayName: '',
  showMenuBtn: false
};

export default withStyles(styles)(ColumnMenu);
