import CollectionView from '@glu/core/src/collectionView';
import StepView from './step';

export default CollectionView.extend({
    itemView: StepView,
    tagName: 'ol',
    className: 'StepsNamed steps steps-named',

    itemEvents: {
        changeStep: 'changeStep',
    },

    itemViewOptions(model, index) {
        return {
            index,
        };
    },

    /**
     * Trigger the changeStep event and pass along the original model attached to
     * the itemView
     * that trigger it
     * @param {string} eventName
     * @param {Object} itemView
     */
    changeStep(eventName, itemView) {
        this.trigger('changeStep', itemView.model);
    },
});
