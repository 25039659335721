import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default Model.extend({
    defaults: {
        alertGroup: '',
        alertType: '',
        alertName: '',
        recipient: '',
    },

    initialize() {
        this.validators = {
            alertGroup: {
                description: locale.get('setup.alert.group'),
                exists: true,
            },

            alertType: {
                description: locale.get('setup.alert.type'),
                exists: true,
            },

            alertName: {
                description: locale.get('setup.alert.name'),
                exists: true,
            },

            recipient: {
                description: locale.get('setup.recipient'),
                exists: true,
            },
        };
    },

    // field constants
    ALERT_GROUP: 'alertGroup',

    ALERT_TYPE: 'alertType',
});
