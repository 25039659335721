var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"balance-warning","textVisible":false,"text":(depth0 != null ? lookupProperty(depth0,"warningText") : depth0),"name":"warning"},"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":145}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<span class=\"as-of-time\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"asOfTime") || (depth0 != null ? lookupProperty(depth0,"asOfTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"asOfTime","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":37}}}) : helper)))
    + "</span>";
  stack1 = ((helper = (helper = lookupProperty(helpers,"hasWarning") || (depth0 != null ? lookupProperty(depth0,"hasWarning") : depth0)) != null ? helper : alias2),(options={"name":"hasWarning","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":160}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"hasWarning")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"useData":true});