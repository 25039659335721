import React from 'react';
import locale from '@glu/locale';
import UserDefinedFields from './UserDefinedFields';
import HistoryDetails from './HistoryDetails';
import HistoryDetailsMDF from './HistoryDetailsMDF';

/* eslint jsx-a11y/label-has-for: 0  react/prop-types: 0 */
// Note: I don't provide propTypes because there is LOT of BATCH-related fields,
// And some of fields may be removed in nearest future. TODO - refactor during writing tests.

const useMDF = true;

const BatchInformation = ({
    typeCode,
    batchTnum,
    STATUS,
    ENTRYMETHOD, // numeric value: 0,1,2, etc.
    ENTRYMETHODDESC, // ??
    ENTRYMETHODTEMP, // "check", TODO - not sure what purpose iof this field.
    ENTERED_TIMESTAMP,
    MODIFIED_TIMESTAMP,
    BATCHNAME,
    RELEASE_DATE,
    CHECKAPP_DESC,
    CHECKDESIGN,
    CHKPRINTSOURCE,
    fullPaymentHistory, // { rows, rowHeader }
    ...restBatchTabProps
}) => (
    <div>
        <div className="section section-container">
            <div className="section-header">
                <h3>{locale.get('payment.paymentsummary')}</h3>
            </div>
            <div className="row">
                <div className="form-column inline-fields">
                    <div>
                        <div className="ui-widget field-container">
                            <div className="row">
                                <div className="form-column">
                                    <div className="col-md-2">
                                        <label>{locale.get('clm.Status') || STATUS.label}</label>
                                        <p><span>{locale.get(`CM.BATCH.CMPPDEC.STATUS.${STATUS.value}`)}</span></p>
                                    </div>
                                    <div className="col-md-2">
                                        <label>{locale.get('payment.entrymethod') || ENTRYMETHOD.label}</label>
                                        <p><span>{locale.get(`PAY.EntryMethod.${ENTRYMETHOD.value}`)}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-column">
                                    <div className="col-md-2">
                                        <label>{ENTERED_TIMESTAMP.label}</label>
                                        <p><span>{ENTERED_TIMESTAMP.value}</span></p>
                                    </div>
                                    <div className="col-md-2">
                                        <label>{MODIFIED_TIMESTAMP.label}</label>
                                        <p><span>{MODIFIED_TIMESTAMP.value}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-header">
                <h3>{locale.get('payment.batchsummary')}</h3>
            </div>
            <div className="row">
                <div className="form-column inline-fields">
                    <div>
                        <div className="ui-widget field-container">
                            <div className="row">
                                <div className="form-column">
                                    <div className="col-md-2">
                                        <label>{BATCHNAME.label}</label>
                                        <p><span>{BATCHNAME.value}</span></p>
                                    </div>
                                    <div className="col-md-2">
                                        <label>{RELEASE_DATE.label}</label>
                                        <p><span>{RELEASE_DATE.value}</span></p>
                                    </div>
                                    <div className="col-md-2">
                                        <label>{CHECKAPP_DESC.label}</label>
                                        <p><span>{CHECKAPP_DESC.value}</span></p>
                                    </div>
                                    <div className="col-md-2">
                                        <label>{CHECKDESIGN.label}</label>
                                        <p><span>{CHECKDESIGN.value}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-column">
                                    <UserDefinedFields {...restBatchTabProps} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-header">
                <h3>{locale.get('CLM.BATCH.BAPCHK.AUDITHISTORY')}</h3>
            </div>

            {
                useMDF ? (
                    <HistoryDetailsMDF
                        typeCode={typeCode}
                        batchTnum={batchTnum}
                        sectionTitle={locale.get('CLM.BATCH.BAPCHK.AUDITSECTION.label')}
                    />
                ) : (
                    <HistoryDetails
                        sectionTitle={locale.get('CLM.BATCH.BAPCHK.AUDITSECTION.label')}
                        tableData={fullPaymentHistory}
                    />
                )
            }

        </div>
    </div>
);

export default BatchInformation;
