import ItemView from '@glu/core/src/itemView';
import successDetailTmpl from './successDetail.hbs';

const SuccessDetail = ItemView.extend({
    initialize(options) {
        this.accounts = options.accounts;
        this.inflowCategories = options.inflowCategories;
        this.outflowCategories = options.outflowCategories;
        this.scenarios = options.scenarioCollection;
    },

    template: successDetailTmpl,

    getAccountName() {
        const id = +this.model.get('ACCOUNTID');
        return this.accounts.findWhere({
            accountId: id,
        }).get('accountName');
    },

    getCategoryName() {
        const id = +this.model.get('CASHFLOWCATEGORYID');
        const inflowCategory = this.inflowCategories.findWhere({
            cashFlowCategoryId: id,
        });
        const outflowCategory = this.outflowCategories.findWhere({
            cashFlowCategoryId: id,
        });

        return inflowCategory ? inflowCategory.get('categoryDescription') : outflowCategory.get('categoryDescription');
    },

    getAmount() {
        const type = this.model.get('CASHFLOWTYPE');
        return (type === 'OUTFLOW' || type === 'DEBIT' || type === 'BILL') ? +this.model.get('OUTFLOW') : +this.model.get('INFLOW');
    },

    getScenarioName() {
        const id = +this.model.get('CASHFLOWSCENARIOID');
        const scenario = this.scenarios.findWhere({
            cashFlowScenarioId: id,
        });

        return scenario ? scenario.get('name') : false;
    },

    getRecurringFrequency() {
        let freq = false;
        if (this.model.get('RECURRING') === 1 && this.model.has('FREQUENCY')) {
            freq = this.model.get('FREQUENCY');
            freq = freq.charAt(0).toUpperCase() + freq.substr(1);
        }
        return freq;
    },

    templateHelpers() {
        return {
            accountName: this.getAccountName(),
            categoryName: this.getCategoryName(),
            scenario: this.getScenarioName(),
            amount: this.getAmount(),
            isNewRecurring: (this.model.get('RECURRING') === 1),
            recurringFrequency: this.getRecurringFrequency(),
        };
    },
});

export default SuccessDetail;
