import ItemView from '@glu/core/src/itemView';
import Formatter from 'system/utilities/format';
import locale from '@glu/locale';
import refreshTransactionsControlTmpl from './refreshTransactionsControl.hbs';

const RefreshTransactionsControl = ItemView.extend({
    template: refreshTransactionsControlTmpl,

    initialize(options) {
        this.parentGridView = options.parentGridView;
    },

    events: {
        'click button': 'startPoller',
    },

    ui: {
        $refreshTimeStamp: '[data-hook="getRefreshTimeStamp"]',
        $refreshButton: '[data-hook="getRefreshPoller"]',
    },

    delegateEvents() {
        ItemView.prototype.delegateEvents.call(this);

        if (this.parentGridView) {
            this.listenTo(this.parentGridView, 'gridapi:loaded', this.updateRefreshTimestamp);
        }

        this.listenTo(this.model, 'change', this.refresh);
    },

    refresh() {
        this.updateRefreshTimestamp();
        // Update State of Refresh Button
        this.$el.toggleClass('has-error', this.model.hasError());
        this.ui.$refreshButton.prop('disabled', !this.model.isAvailable())
            .find('.icon-refresh')
            .toggleClass('animate-spin', this.model.isRunning());
    },

    updateRefreshTimestamp() {
        if (this.parentGridView.getRows) {
            const timestamp = this.parentGridView.getRows().lastRefreshTimestamp;
            if (timestamp) {
                this.ui.$refreshTimeStamp.text(`${Formatter.formatDate(timestamp)} ${Formatter.formatTime(timestamp)}`);
            }
        }
    },

    startPoller() {
        this.model.start();
    },

    templateHelpers() {
        return {
            iconText: locale.get('gir.refreshTransactions'),
        };
    },
});

export default RefreshTransactionsControl;
