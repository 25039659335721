/**
 * IdentityGuard nearly doubles startup time
 *  so it is disabled by default and will need
 *  to be enabled for specific customers.
 */

import BioCatch from 'system/interfaces/bioCatch';
import LiveEngage from 'system/interfaces/liveEngage';
import TrusteerPinPoint from 'system/interfaces/trusteerPinpoint';
import TrusteerRapport from 'system/interfaces/trusteerRapport';
import CFRM from 'system/interfaces/cfrm';
import walkMe from 'system/interfaces/walkMe';
import identityGuard from 'system/interfaces/identityGuard';
import ElasticUserMonitoring from 'system/interfaces/elasticUserMonitoring';
import AdobeAnalytics from 'system/interfaces/adobeAnalytics';
import TagManager from 'system/interfaces/tagManager';

const modules = [
    TagManager,
    BioCatch,
    CFRM,
    LiveEngage,
    TrusteerPinPoint,
    TrusteerRapport,
    walkMe,
    identityGuard,
    ElasticUserMonitoring,
    AdobeAnalytics,
];

export default {
    getModules: () => modules,
};
