import http from '@glu/core/src/http';
import { log } from '@glu/core';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import $ from 'jquery';
import services from 'services';

export default function (form, initialState) {
    const { model } = form.formView;
    const view = form.formView;
    const beneCountry = form.field('RECEIVCOUNTRYCODE');
    const beneState = form.field('RECEIVSTATE');
    const beneProvince = form.field('RECEIVPROVINCE');
    const receiveBankType = form.field('RECEIVBANKTYPE');
    const accountNumber = form.field('ACCOUNTNUMBER');

    const processAddendaForCTX = function () {
        $('#MESSAGE').find('p').text('');
        const inquiryService = services.generateUrl('/inquiry/getRowsData');
        const postData = {
            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 24616,

                    customFilters: [{
                        filterName: 'DEPENDS',

                        filterParam: [
                            'TNUM',
                            'XX',
                        ],
                    }],
                },

                rowsPerPage: 1,
                startRow: 0,
            },

            requestHeader: {
                requestId: '',
            },
        };

        postData.inquiryRequest.searchCriteria.customFilters[0].filterParam[1] = model.get('TNUM');

        http.post(inquiryService, postData, (result) => {
            $('#MESSAGE').find('p').text(result.inquiryResponse.rows[0].columns[0].fieldValue);
        }, (err) => {
            log.error(err);
        });
    };

    /**
     * @method validateIBANCountryCode
     * This method validates if the destination country code matches the country
     * code of the IBAN and warn the user if they does not match.
     */
    const validateIBANCountryCode = function () {
        if (model.get('ACCOUNTNUMBER') && model.get('BANKACCOUNTORIBAN') === '03') {
            if (model.get('ACCOUNTNUMBER').length < 2 || model.get('ACCOUNTNUMBER').substring(0, 2) !== view.parentModel.get('DESTCOUNTRYCODE')) {
                dialog.confirm(locale.get('ACH.Warning.CountryInValid'), locale.get('ACH.Warning'), (ok) => {
                    if (!ok) {
                        model.set('ACCOUNTNUMBER', '');
                    }
                });
            }
        }
    };

    /**
     * @method processIAT
     * - Method for the Nacha file import with IAT entry class
     * - reset the State or Province field based on the country field
     */
    const processIAT = function () {
        // Add the handler for bene country change
        beneCountry.$el.on('change', function () {
            /**
             * for country US, clear the province value previously entered, otherwise,
             * clear the state value previously entered
             * if country is US, then show state drop down,
             * if not, then show province/State textbox
             */
            if (this.value === 'US') {
                beneProvince.shouldBeVisibleWhen(false);
                beneState.shouldBeVisibleWhen(true);
                model.set(
                    'RECEIVPROVINCE',
                    '',
                    {
                        silent: true,
                    },
                );
            } else {
                if (!this.value) {
                    model.set(
                        'RECEIVCOUNTRYCODE',
                        '',
                        {
                            silent: true,
                        },
                    );
                }
                beneProvince.shouldBeVisibleWhen(true);
                beneState.shouldBeVisibleWhen(false);
                model.set(
                    'RECEIVSTATE',
                    '',
                    {
                        silent: true,
                    },
                );
            }
        });
        model.on('change:BANKACCOUNTORIBAN', () => {
            if (model.get('BANKACCOUNTORIBAN') === '03') {
                model.set('RECEIVBANKTYPE', 'SWIFT');
                receiveBankType.shouldBeReadOnly(true);
            } else {
                receiveBankType.shouldBeReadOnly(false);
            }

            validateIBANCountryCode();
        });

        /**
         * NH-101788 when the RECEIVBANKTYPE is changed,
         * all the information regarding the bank code should be cleared.
         */
        model.on('change:RECEIVBANKTYPE', () => {
            model.set('RECEIVRBANKCODE', '');
            view.trigger('lookupHelperText:clear', 'RECEIVRBANKCODE');
        });
        /**
         * TODO  the default values are not working for the child screen.
         * We need to remove this code when we address the default values issue.
         */
        if (model.get('BANKACCOUNTORIBAN') === '') {
            model.set('BANKACCOUNTORIBAN', '01');
        }

        if (model.get('BANKACCOUNTORIBAN') === '03') {
            model.set('RECEIVBANKTYPE', 'SWIFT');
            receiveBankType.shouldBeReadOnly(true);
        }

        accountNumber.$el.on('change', () => {
            validateIBANCountryCode();
        });

        /*
         * *
         * Beneficiary state/province display depends on the country user selected,
         * for US, display the state drop down, otherwise, display province text box
         */
        const showState = beneCountry.getValue() === 'US';
        beneState.shouldBeVisibleWhen(showState).shouldBeRequiredWhen(showState);
        beneProvince.shouldBeVisibleWhen(!showState).shouldBeRequiredWhen(!showState);
    };

    if (initialState) {
        if (model.get('TYPE') === 'DNFICTX') {
            processAddendaForCTX();
        } else if (model.get('TYPE') === 'DNFIIAT') {
            processIAT();
        }
    }
}
