var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"administration.fileimport.history",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":77}}}))
    + "</h1>\n</div>\n<div class=\"page fileImportHistoryPage\">\n    <div class=\"section section-container\">\n        <div class=\"section-body\">\n            <div class=\"btn-wrapper\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"page-content\" data-region=\"fileHistoryRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});