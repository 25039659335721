import util from 'underscore';

export default {
    hasChildren(productCode, functionCode, typeCode) {
        const searchKey = { productCode, functionCode, typeCode };
        return [
            { productCode: '_ADMIN', functionCode: 'MAINT', typeCode: 'APPTN_TM' },
            { productCode: '_ADMIN', functionCode: 'MAINT', typeCode: 'BENEADBK' },
            { productCode: '_ADMIN', functionCode: 'MAINT', typeCode: 'PNLPA_TM' },
            { productCode: '_ADMIN', functionCode: 'MAINT', typeCode: 'PNLPR_TM' },
            { productCode: '_UTIL', functionCode: 'MAINT', typeCode: 'ACH_DIMD' },
            { productCode: 'BOS', functionCode: 'MAINT', typeCode: 'BILL' },
            { productCode: 'CMAINT', functionCode: 'MAINT', typeCode: 'APPTN_TM' },
            { productCode: 'CMAINT', functionCode: 'MAINT', typeCode: 'PNLPR_TM' },
            { productCode: 'CMAINT', functionCode: 'MAINT', typeCode: 'PNLPA_TM' },
            { productCode: 'CMAINT', functionCode: 'MAINT', typeCode: 'ADMALERT' },
            { productCode: 'CMAINT', functionCode: 'MAINT', typeCode: 'TMPALERT' },
            { productCode: 'PAY', functionCode: 'TMPLSET', typeCode: 'TMPLSET' },
        ].find(entry => util.isEqual(entry, searchKey)) !== undefined;
    },

    getFunctionCode(context) {
        const { serviceName } = context;
        const functionCode = context?.functionCode
            ?? context?.actionData?.functionCode
            ?? context?.actionContext?.functionCode;

        if (functionCode === 'INST' && serviceName.indexOf('/batch/') === 0) {
            return 'BATCH';
        }
        if (functionCode === 'TMPL' && serviceName.indexOf('/batchTemplate/') === 0) {
            return 'BHTMPL';
        }
        return functionCode;
    },

    isRFPTemplate(context) {
        return (context?.actionData?.productCode === 'RTP' && context?.actionData?.functionCode === 'REQTMPL')
            || (context?.actionContext?.productCode === 'RTP' && context?.actionContext?.functionCode === 'REQTMPL')
            || context?.functionCode === 'REQTMPL';
    },
};
