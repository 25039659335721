import { postData } from 'common/util/services';
import store from 'system/utilities/cache';

export default {
    ACTIONS: {
        approve: 'APPROVE',
        unapprov: 'UNAPPROV',
        complete: 'COMPLETE',
        delete: 'DELETE',
        insert: 'INSERT',
        modify: 'MODIFY',
        needInfo: 'NEEDINFO',
        reject: 'REJECT',
        select: 'SELECT',
        print: 'PRINT', // PCM
        reprint: 'REPRINT', // PCM
    },

    /**
     * Get the entitledActions based on endpoint and data
     * @param {string} baseEndpoint - without 'getEntitledActions' at the end
     * @param {Object} requestData - expected data for the endpoint
     * @returns {Object} Standard entitled action response
     */
    getEntitledActions(baseEndpoint, requestData) {
        return postData(`${baseEndpoint}/getEntitledActions`, requestData);
    },

    /**
     * Check an array of actions to see if it contains the action
     * @param {string} action - action name
     * @param {Array} actionModes - array of actions
     * @returns {boolean} - whether the user is entitle to the action
     */
    hasEntitledAction(action, actionModes = []) {
        return actionModes.includes(action);
    },

    executeEntitledAction(baseEndpoint, action, requestData) {
        return postData(`${baseEndpoint}/${action.toLowerCase()}`, requestData);
    },

    handleSuccess(context, action, response) {
        store.set(`${context}-alertMessage`, action);
        store.set(`${context}-confirms`, response);
    },

    handleError(context, action) {
        store.set(`${context}-alertMessage`, `${action} error`);
    },
};
