var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideReturnButton") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    <h2 class=\"landing-header\" data-hook=\"setLandingText\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(alias1,"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":79}}}))
    + "</h2>\n\n        <div data-section=\"payment-summary\" class=\"amount-wrapper hidden\">\n            <h4 class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.payment.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":75}}}))
    + "</h4>\n            <span data-field=\"summary-amount\" class=\"amount-value-text payment-total\">0.00</span> <span data-field=\"summary-currency\" class=\"amount-value-text payment-total\"></span>\n        </div>\n\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":144},"end":{"line":5,"column":180}}}))
    + "</span></a>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-summary\">\n    <div data-region=\"footerFormRegion\" class=\"hidden\"></div>\n    <div data-hook=\"footerElements\">\n        <div data-section=\"payment-summary\" class=\"section-header hidden\">\n            <div class=\"clearfix\">\n                <div class=\"summary-total-block credit-only\">\n                    <div class=\"summary-total-block-count\">\n                        <h4 data-bind=\"TOTALNUMCREDIT\">0</h4>\n                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.credits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":30},"end":{"line":36,"column":57}}}))
    + "</span>\n                    </div>\n                    <div class=\"summary-total-block-amount\">\n                        <span class=\"summary-large-text\" data-field=\"summary-credit-amount\">0.00</span> <span data-field=\"summary-currency\"></span>\n                    </div>\n                </div>\n                <div class=\"summary-total-block debit-only\">\n                    <div class=\"summary-total-block-count\">\n                        <h4 data-bind=\"TOTALNUMDEBIT\">0</h4>\n                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.debits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":30},"end":{"line":45,"column":56}}}))
    + "</span>\n                    </div>\n                    <div class=\"summary-total-block-amount\">\n                        <span class=\"summary-large-text\" data-field=\"summary-debit-amount\">0.00</span> <span data-field=\"summary-currency\"></span>\n                    </div>\n                </div>\n            </div>\n            <h4><span data-field=\"summary-amount\" class=\"summary-large-text\">0.00</span> <span data-field=\"summary-currency\"></span><span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.summary.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":139},"end":{"line":52,"column":170}}}))
    + " </span><span data-field=\"summary-beneficiaries\">0</span><span class=\"summary-date-text\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":260},"end":{"line":52,"column":283}}}))
    + " </span><span data-field=\"summary-date\"></span></h4>\n        </div>\n        <div data-section=\"save-as-template\"></div>\n        <div class=\"section-body\">\n            <div class=\"widget-action-btn-group\" data-hook=\"getWidgetActionBtnGroup\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":64,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":58,"column":39},"end":{"line":58,"column":51}}}) : helper)))
    + "\"\n                        data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":59,"column":37},"end":{"line":59,"column":50}}}) : helper)))
    + "\"\n                        name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":60,"column":30},"end":{"line":60,"column":43}}}) : helper)))
    + "\"\n                        type=\"button\">\n                        <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":62,"column":42},"end":{"line":62,"column":53}}}) : helper)))
    + "</span>\n                    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideHeader") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "\n<section class=\"section-container\">\n    <div class=\"alert-message\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    </div>\n    <div data-region=\"pmtMessageRegion\"></div>\n</section>\n\n<div data-region=\"pageContent\"></div>\n<div id=\"customRegionDiv\" data-region=\"customRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":69,"column":11}}})) != null ? stack1 : "");
},"useData":true});