import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { util } from '@glu/core';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import { createBSLUrl, encodeParameters } from 'common/util/urlBuilder';
import ContentLoading from './ContentLoading';

const getUrl = (url, urlParams, bsl) => (bsl ? createBSLUrl(url, urlParams) : url + (urlParams ? `?${encodeParameters(urlParams)}` : ''));

const LoadedChildren = ({
    children, data, isLoading, reloadData,
}) => (
    typeof children === 'function' ?
        children({ data, isLoading, reloadData }) :
        React.Children.map(children, child => React.cloneElement(child, {
            data: data || child.props.data,
            isLoading,
            reloadData,
        }))
);

/**
 * Requests data for a component and shows a spinner while it loads (optional),
 * then passes a `data` prop into all top-level children
 *
 * @param url {string} A simple url to request data from. Overridden by the `requests` param
 * @param requests {object|array} Details for one or more requests needed to load the data.
 * There are three formats for requests:
 *                   * one "request" object - returns data as simply the data returned
 *                   by the one request
 *                   * an object with one or more "request" objects as values,
 *                   returns data as an object where each property is a request,
 *                   keyed on the keys in the original object
 *                   * an array of one or more "request" objects, returns data
 *                   as an array in the same order
 *                 The "request" object format is documented as `requestPropType` below.
 * @param promise {Promise} A promise that will resolve when the spinner UI should be replaced
 * with the children
 *                          Short-circuits url/requests handling logic - use to implement custom
 *                          loading behavior
 * @param hideLoadingIndicator {bool} If true, displays the children before all data is returned
 * @param preventLoad {bool} Use to defer the loading logic temporarily
 * @param onLoad {func} A function to call when all requests complete
 * @param onError {func} A function to call if any of the requests fail
 */
class Loader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: !props.preventLoad,
        };

        this.reloadData = this.reloadData.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        if (!this.props.preventLoad) {
            this.loadData(this.props);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.preventLoad) {
            return;
        }

        const shouldLoadChanged = this.props.preventLoad !== prevProps.preventLoad;
        const urlChanged = this.props.url !== prevProps.url;
        const requestsChanged = this.props.requests !== prevProps.requests &&
			JSON.stringify(this.props.requests) !== JSON.stringify(prevProps.requests);
        const promiseChanged = this.props.promise !== prevProps.promise;

        if (shouldLoadChanged || urlChanged || requestsChanged || promiseChanged) {
            this.loadData(this.props);
        }
    }

    componentWillUnmount() {
        this.mounted = false;

        this.loadCallback = function () {};
    }

    render() {
        const { isLoading, data, error } = this.state;
        const {
            children, hideLoadingIndicator, hideErrors, errorComponent, localeError,
        } = this.props;

        if (error && !hideErrors) {
            return errorComponent && typeof errorComponent === 'function' ? errorComponent(error) : errorComponent || <p>{localeError ? locale.get(localeError) : locale.get('systemError')}</p>;
        }

        return isLoading && !hideLoadingIndicator ? (
            <ContentLoading />
        ) : (
            <LoadedChildren
                error={error}
                data={data}
                isLoading={isLoading}
                reloadData={this.reloadData}
            >
                {children}
            </LoadedChildren>
        );
    }

    loadCallback(isSingleRequest, data, name, parse, index) {
        const parsedData = parse ? parse(data) : data;

        this.setState(({ data }) => { // eslint-disable-line
            if (isSingleRequest) {
                this.queuedPromises = [];
                return { data: parsedData, isLoading: false };
            }

            if (!data) {
                data = name ? {} : []; // eslint-disable-line
            }

            if (Array.isArray(data)) {
                this.queuedPromises = this.queuedPromises.filter(value => value !== index);
                data[index] = parsedData; // eslint-disable-line
                return { data };
            }

            this.queuedPromises = this.queuedPromises.filter(value => value !== name);
            return {
                data: util.extend({}, data, {
                    [name]: parsedData,
                }),
            };
        }, () => {
            if (this.mounted && !this.queuedPromises.length) {
                if (this.props.onLoad) {
                    this.props.onLoad(this.state.data);
                }

                this.setState({ isLoading: false });
            }
        });
    }

    loadData(props) {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
        }

        if (props.promise) {
            props.promise.then((data) => {
                if (this.props.promise === props.promise) {
                    this.loadCallback(true, data);

                    if (this.props.onLoad && this.mounted) {
                        this.props.onLoad(data);
                    }
                }
            }, (error) => {
                if (this.props.onError && this.props.promise === props.promise && this.mounted) {
                    this.props.onError(error);
                }
            });

            return;
        }

        const requests = this.processRequests(props.requests || props.url);
        const isSingleRequest = !Array.isArray(requests);

        const promises = (isSingleRequest ? [requests] : requests).reduce((promises, { // eslint-disable-line
            method = 'GET', bsl, url, urlParams, data, options, parse, name, promise,
        }, index) => {
            if (!url && !promise) {
                return promises;
            }

            this.queuedPromises = this.queuedPromises || [];
            this.queuedPromises.push(name || index);

            promise = promise || ( // eslint-disable-line
                http.request(method, getUrl(url, urlParams, bsl), data, null, null, options)
            );

            promise.then(data => this.loadCallback(isSingleRequest, data, name, parse, index)); // eslint-disable-line

            promises.push(promise);

            return promises;
        }, []);

        if (!promises.length) {
            this.setState({ isLoading: false });
        }

        Promise.all(promises).catch((...args) => {
            if (this.mounted) {
                const error = isSingleRequest ? args[0] : args;

                if (props.onError) {
                    props.onError(error);
                }

                this.setState({ error });
            }
        });
    }

    reloadData() {
        this.loadData(this.props);
    }

    processRequests(requests) { // eslint-disable-line
        const getRequestProperties = request => (util.isObject(request) ?
            util.clone(request) : { url: request });

        if (Array.isArray(requests)) {
            return requests.map(getRequestProperties);
        }

        if (util.isString(requests) || requests.url || requests.promise) {
            return getRequestProperties(requests);
        }

        return Object.keys(requests).map((key) => {
            const request = getRequestProperties(requests[key]);
            request.name = key;
            return request;
        });
    }
}

const requestPropType = PropTypes.shape({
    // overrides all other properties here except parse
    promise: PropTypes.object,

    url: PropTypes.string,
    urlParams: PropTypes.object,
    bsl: PropTypes.bool,
    method: PropTypes.oneOf(['GET', 'PUT', 'POST', 'DELETE']),
    data: PropTypes.any,
    options: PropTypes.object,
    parse: PropTypes.func,
    localeError: PropTypes.string,
});

Loader.propTypes = {
    children: PropTypes.any.isRequired, // eslint-disable-line

    url: PropTypes.string, // eslint-disable-line

    requests: PropTypes.oneOfType([ // eslint-disable-line
        requestPropType,
        PropTypes.objectOf(requestPropType),
        PropTypes.arrayOf(requestPropType),
    ]),

    hideLoadingIndicator: PropTypes.bool,
    hideErrors: PropTypes.bool,
    preventLoad: PropTypes.bool,
    errorComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]), // eslint-disable-line
    promise: PropTypes.object, // eslint-disable-line
    onLoad: PropTypes.func, // eslint-disable-line
    onError: PropTypes.func, // eslint-disable-line
    localeError: '',
};

Loader.defaultProps = {
    hideLoadingIndicator: false,
    hideErrors: false,
    preventLoad: false,
    options: {} // eslint-disable-line
};

export default Loader;

export function loader(InputComponent) {
    const LoadedComponent = props => (
        <Loader {...props}>
            {({ data, isLoading, reloadData }) => (
                <InputComponent
                    {...props}
                    data={data}
                    isLoading={isLoading}
                    reloadData={reloadData}
                />
            )}
        </Loader>
    );

    return LoadedComponent;
}
