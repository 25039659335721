import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import CollectionView from '@glu/core/src/collectionView';
import PermCheckView from '../permCheckView';
import groupViewTmpl from './groupView.hbs';

export default Layout.extend({
    template: groupViewTmpl,

    ui: {
        $selectAll: '.select-all-perms',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPerms',
    },

    initialize(opts) {
        const checklistCollection = this.model.get('entitlementsByType');

        if (this.mode === constants.MODES.VIEW) {
            checklistCollection.reset(checklistCollection.filter(entitlement => entitlement.get('entitled')));
        }

        this.checklistView = new CollectionView({
            itemView: PermCheckView,

            itemViewOptions: {
                tagName: 'div',
                className: 'checkbox',
                mode: opts.mode,
            },

            collection: checklistCollection,
        });
    },

    onRender() {
        this.entitlementsRegion.show(this.checklistView);
    },

    selectAllPerms(evt) {
        this.model.get('entitlementsByType').each((alertPerm) => {
            alertPerm.set('entitled', evt.currentTarget.checked);
        });
    },

    templateHelpers() {
        const self = this;
        return {
            readOnly: this.options.mode === constants.MODES.VIEW,

            alertGroup() {
                return locale.get(`uce.alertsGroup.${self.model.get('typeGroupId')}`);
            },

            cid: this.model.cid,
        };
    },
});
