import { Collection } from '@glu/core';
import PrinterTray from '../models/printerTray';

export default Collection.extend({
    model: PrinterTray,

    getSelectedTrays(id) {
        return this.pluck('virtualTrayId').filter(value => ![null, undefined, id].includes(value));
    },
});
