import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import LiveMaintenanceLanguageService from 'app/administration/service/liveMaintenanceLanguageService';
import liveMaintenanceMessageViewTmpl from './liveMaintenanceMessageView.hbs';

export default ItemView.extend({
    template: liveMaintenanceMessageViewTmpl,

    options: {
        allLanguages: '',
    },

    initialize() {
        const self = this;
        LiveMaintenanceLanguageService.getLanguages().then((languages) => {
            self.options.allLanguages = languages;

            // create language dropdown
            self.createLanguageDropdown();
        }).catch(() => {
            appBus.trigger('liveMaintenance.languageServiceError');
        });

        this.listenTo(this.model.collection, 'add remove', this.setModifyModelBtn);
    },

    tagName: 'section',

    /*
     * can't use a data hook for the flex dropdown because
     * it needs to be dynamically generated later
     */
    ui: {
        $messageHeader: '[data-hook="getMessageHeader"]',
        $details: '[data-hook="getDetails"]',
        $clearModelBtn: '[data-hook="getClearModel"]',
        $deleteModelBtn: '[data-hook="getDeleteModel"]',
        $selectContainer: '.select-container',
    },

    modelEvents: {
        change: 'render',
    },

    events: {
        'click @ui $deleteModelBtn': 'deleteModel',
        'click @ui $clearModelBtn': 'clearModel',
        'change [data-hook="getModifyLanguageSection"] .LanguageSection-info': 'updateModel',
    },

    appEvents: {
        'liveMaintenance.updateLanguageDropdowns': 'createLanguageDropdown',
    },

    onRender() {
        // set clear or delete button on creation
        this.setModifyModelBtn();

        // check modify mode and set view mode for model
        this.createLanguageDropdown();
    },

    getSelectedDropdownData() {
        return this.$el.find('.select-container').flexDropdown('data');
    },

    /**
     * Update model with data from view
     */
    updateModel() {
        const self = this;
        const selectedDropdownData = this.getSelectedDropdownData();

        // update model with new values
        this.model.set({
            LANGUAGE: selectedDropdownData[0].id,
            MESSAGEHEADER: self.ui.$messageHeader.val(),
            DETAILS: self.ui.$details.val(),
            LANGUAGELABEL: selectedDropdownData[0].name,
        });

        // validate model
        this.model.validate();
    },

    /**
     * Deletes the model linked to this view instance
     */
    deleteModel() {
        this.model.deleteModel();
    },

    /**
     * Clears the model field linked to this view instance
     */
    clearModel() {
        this.model.clearModel();
    },

    /**
     * Filter function to remove languages that already have set messages from
     * the language selectors flex dropdown
     * @return {array} Array of applicable languages
     */
    setLanguages() {
        const setLanguages = this.retrieveSetLanguages();
        const languageArray = [];

        util.each(this.options.allLanguages, (language) => {
            // filter by languages not already selected
            if (!util.contains(setLanguages, language.name)) {
                languageArray.push({
                    id: language.name,
                    name: language.label,
                });
            }
        });

        return languageArray;
    },

    /**
     * Get and array of languages already set by user
     * @return {Array} List of languages
     */
    retrieveSetLanguages() {
        // loop through all items in collection and get languages
        const languages = this.options.model.collection.pluck('LANGUAGE');

        // remove this model's language so that it will show up in list
        return util.without(languages, this.model.get('LANGUAGE'));
    },

    createLanguageDropdown() {
        const self = this;

        const flexOptions = {
            showTooltip: false,
            defaultSelectMessage: 'Select',
            data: self.setLanguages(),
            preSelectedIds: [self.model.get('LANGUAGE')],
            disableMultiButton: true,
            class: 'LanguageSection-info',
        };

        this.$el.find('.select-container').empty().flexDropdown(flexOptions);
        this.$el.find('.select-container').data('flexDropdown').on('selectionChanged', () => {
            self.updateModel();
            appBus.trigger('liveMaintenance.languageDropdownChanged');
        });
    },

    /**
     * Show/hides clear and delete buttons for model
     */
    setModifyModelBtn() {
        /*
         * fail fast and don't run this because the model is being removed from
         * collection and does not need to have it's view updated
         */
        if (this.isClosed) {
            return;
        }

        /*
         * if collection length is only 1 then we wan the clear model button active
         * but if it's greater than 1 we want the delete model button active
         */
        this.ui.$clearModelBtn.toggleClass('active', this.model.collection.length === 1);
        this.ui.$deleteModelBtn.toggleClass('active', this.model.collection.length > 1);
    },
});
