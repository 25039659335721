import CompositeView from '@glu/core/src/compositeView';
import util from '@glu/core/src/util';
import ScenarioSelectItem from './scenarioSelectItem';
import scenarioSelectTmpl from './scenarioSelect.hbs';

const ScenarioSelect = CompositeView.extend({
    itemView: ScenarioSelectItem,

    initialize(options) {
        // reference to inputCollection
        this.transactions = options.transactions;
        this.lines = options.lines;
        this.filters = options.filters;
        // this.collection is scenarioCollection

        /*
         * TODO this.transactions no longer exists!
         *            this.listenTo(this.transactions, {
         *                'add remove change': this.update
         *            }, this);
         */

        this.listenTo(
            this,
            {
                showEditMode: this.showEditMode,
                saveEditModeChanges: this.saveChanges,
            },
            this,
        );

        this.listenTo(
            this.collection,
            {
                'add remove': this.updateScenarioCount,
            },
            this,
        );

        this.editMode = false;
        //  this.update();
    },

    itemViewContainer: '.scenario-list',

    itemViewOptions() {
        return {
            lines: this.lines,
            filters: this.filters,
        };
    },

    template: scenarioSelectTmpl,

    showEditMode() {
        this.editMode = true;
        this.showChildViewEditMode();
        this.$el
            .first()
            .find(':focusable')
            .first()
            .focus();
    },

    showChildViewEditMode() {
        this.$('.view-mode').addClass('hidden');
        this.$('.edit-mode').removeClass('hidden');
        this.$('.ui-draggable').draggable('destroy');
    },

    onAfterItemAdded(itemView) {
        if (this.editMode) {
            this.showChildViewEditMode();
            itemView.$el.find('input[type="text"]').val('').focus();
        }
    },

    ui: {
        $form: '.add-scenario',
        $btnAddScenario: '.btn-show-add-new-scenario',
    },

    events: {
        'click @ui.$btnAddScenario': 'addScenario',
    },

    attributes: {
        'aria-controls': '#transactions-graph',
    },

    className: '',

    templateHelpers() {
        return {
            scenarioCount: this.collection.length,
        };
    },

    saveChanges() {
        this.editMode = false;
        this.collection.each((model) => {
            model.trigger('saveChangesFromEditMode');
        });

        this.ui.$btnAddScenario.addClass('hidden');
    },

    update() {
        const transactionGroups = this.transactions.getScenarioTransactions();
        this.collection.each((scenario) => {
            const transactions = transactionGroups[scenario.cid] || [];
            const delta = util.reduce(transactions, (memo, transaction) => (memo + (transaction.get('inFlow') || 0)) - (transaction.get('outFlow') || 0), 0);

            scenario.set({
                transactionCount: transactions.length,
                transactionValue: delta,
            });
        });
    },

    addScenario() {
        this.collection.add({
            name: this.collection.getDefaultName(),
        });
    },

    updateScenarioCount() {
        this.$('[data-hook="scenarioCount"]').text(this.collection.length);
    },
});

export default ScenarioSelect;
