import Layout from '@glu/core/src/layout';
import CheckReview from 'common/dynamicPages/views/checkReview';
import depositViewerTmpl from 'app/balanceAndTransaction/views/depositAccounts/depositSlipImageViewer.hbs';
import store from 'system/utilities/cache';

const TransactionDetails = Layout.extend({
    template: depositViewerTmpl,

    initialize(options) {
        this.model = options.model;
        this.checkView = new CheckReview({
            model: this.model,
            result: options.result || store.get('check_result_bool'),
        });
        store.unset('check_result_bool');
    },

    cancel() {
        if (this.stack) {
            this.stack.pop();
        } else {
            window.history.back();
        }
    },

    onRender() {
        this.imageViewer.show(this.checkView);
    },

    templateHelpers() {
        return {
            CLIENTACCOUNTNAME: this.model.get('CLIENTACCOUNTNAME').replace('&amp;', '&'),
        };
    },
});

export default TransactionDetails;
