export default {

    /**
     * @param {Model} checkFreeModel - the model for this checkfree form
     * @param {View} checkFreeForm - the view for this checkfree form
     * @param {String} newlyEnteredCountry - The country selected by the user
     * @param {String} stateFieldName - the name of the state field on this form
     * @param {String} zipCodeFieldName - the name of the zipCode field on this form
     */
    onCountryChange(
        checkFreeModel,
        checkFreeForm,
        newlyEnteredCountry,
        stateFieldName,
        zipCodeFieldName,
    ) {
        const countryIsUSAOrBlank = (newlyEnteredCountry === 'USA' || newlyEnteredCountry === '');

        // show and hide some fields as needed based on the country
        checkFreeForm.field('POSTALCODE').shouldBeVisibleWhen(!countryIsUSAOrBlank);
        checkFreeForm.field('PROVINCENAME').shouldBeVisibleWhen(!countryIsUSAOrBlank);
        checkFreeForm.field(stateFieldName).shouldBeVisibleWhen(countryIsUSAOrBlank);
        checkFreeForm.field(zipCodeFieldName).shouldBeVisibleWhen(countryIsUSAOrBlank);

        // remove existing values from hidden fields as needed
        if (countryIsUSAOrBlank) {
            checkFreeModel.set({
                POSTALCODE: '',
                PROVINCENAME: '',
            });
        } else {
            checkFreeModel.set({
                [stateFieldName]: '',
                [zipCodeFieldName]: '',
            });
        }
    },

    /**
     * @param {Model} checkFreeModel - the model for this checkfree form
     * @param {View} checkFreeForm - the view for this checkfree form
     * @param {Array} addressGroupFields - All of the address fields that share some sort of
     * @param {String} currentCountryCode - The country currently selected by the user
     * consistent logic related to one another
     * @param {String} stateFieldName - the name of the state field on this form
     * @param {String} zipCodeFieldName - the name of the zipCode field on this form
     * @param {String} cityFieldName - the name of the city field on this form
     * @param {Array} optionalCityPrefixes - prefixes of cities that don't require zip codes
     */
    onAddressGroupChange({
        checkFreeModel,
        checkFreeForm,
        addressGroupFields,
        currentCountryCode,
        stateFieldName,
        zipCodeFieldName,
        cityFieldName,
        optionalCityPrefixes,
    }) {
        const countryIsUSAOrBlank = (currentCountryCode === 'USA' || currentCountryCode === '');

        // change a flag if any of the address group fields have a value
        const allAddressFieldsAreEmpty = addressGroupFields
            .every(addressField => !checkFreeModel.get(addressField));

        // set the "required" status of all address group fields accordingly
        addressGroupFields.forEach((addressField) => {
            // if the user has selected USA (or no country)
            if (countryIsUSAOrBlank) {
                switch (addressField) {
                case 'POSTALCODE':
                case 'PROVINCENAME': // when the country is USA, Postal code and province name are not needed
                    checkFreeForm.field(addressField).shouldBeRequiredWhen(false, true);
                    break;
                case zipCodeFieldName: {
                    // depending on the city prefix, zip code may be optional
                    const cityPrefix = checkFreeModel.get(cityFieldName)?.substring(0, 3)
                        .toUpperCase();
                    if (optionalCityPrefixes.includes(cityPrefix)) {
                        checkFreeForm.field(zipCodeFieldName).shouldBeRequiredWhen(false, true);
                        break;
                    } else {
                        checkFreeForm.field(zipCodeFieldName)
                            .shouldBeRequiredWhen(!allAddressFieldsAreEmpty, true);
                        break;
                    }
                }
                default: checkFreeForm.field(addressField)
                    .shouldBeRequiredWhen(!allAddressFieldsAreEmpty, true);
                }
            } else { // the user has selected a country other than USA
                switch (addressField) {
                case stateFieldName:
                case zipCodeFieldName: // when the country isn't USA, state and zip code are uneeded
                    checkFreeForm.field(addressField).shouldBeRequiredWhen(false, true);
                    break;
                default: checkFreeForm.field(addressField)
                    .shouldBeRequiredWhen(!allAddressFieldsAreEmpty, true);
                }
            }
        });
    },

    /**
     * @param {Model} checkFreeModel - the model for this checkfree form
     * @param {View} checkFreeForm - the view for this checkfree form
     * @param {String} cityFieldName - the name of the city field on this form
     * @param {String} stateFieldName - the name of the state field on this form
     * @param {String} zipCodeFieldName - the name of the zipCode field on this form
     */
    setModelListeners(
        checkFreeModel,
        checkFreeForm,
        cityFieldName,
        stateFieldName,
        zipCodeFieldName,
    ) {
        // when the user changes the country, show/hide certain country-specific address fields
        checkFreeModel.on('change:COUNTRYCODE', () => {
            this.onCountryChange(
                checkFreeModel,
                checkFreeForm,
                checkFreeModel.get('COUNTRYCODE'),
                stateFieldName,
                zipCodeFieldName,
            );
        });

        const addressGroupFields = [
            'ADDRESS1',
            'POSTALCODE',
            'PROVINCENAME',
            'COUNTRYCODE',
            cityFieldName,
            stateFieldName,
            zipCodeFieldName];

        // create the single change listener for all of the address fields
        const eventListenerString = addressGroupFields
            .reduce((accumulator, addressField) => `${accumulator} change:${addressField}`, '');

        // when the user changes any of the address fields, evaluate all of their required statuses
        checkFreeModel.on(eventListenerString, () => {
            this.onAddressGroupChange({
                checkFreeModel,
                checkFreeForm,
                addressGroupFields,
                currentCountryCode: checkFreeModel.get('COUNTRYCODE'),
                stateFieldName,
                zipCodeFieldName,
                cityFieldName,
                optionalCityPrefixes: ['APO', 'FPO'],
            });
        });
    },
};
