var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " autocomplete=\"off\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " name=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"nameAttr") || (depth0 != null ? lookupProperty(depth0,"nameAttr") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"nameAttr","hash":{},"data":data,"loc":{"start":{"line":2,"column":174},"end":{"line":2,"column":186}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dateElemId") || (depth0 != null ? lookupProperty(depth0,"dateElemId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dateElemId","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":26}}}) : helper)))
    + "\" class=\"datepicker-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"labelText") || (depth0 != null ? lookupProperty(depth0,"labelText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelText","hash":{},"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":66}}}) : helper)))
    + "</label>\n<input name=\"VALUE_DATE\" type=\"text\" size=\"20\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dateElemClassName") || (depth0 != null ? lookupProperty(depth0,"dateElemClassName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dateElemClassName","hash":{},"data":data,"loc":{"start":{"line":2,"column":54},"end":{"line":2,"column":75}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dateElemId") || (depth0 != null ? lookupProperty(depth0,"dateElemId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dateElemId","hash":{},"data":data,"loc":{"start":{"line":2,"column":81},"end":{"line":2,"column":95}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"autocomplete") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":96},"end":{"line":2,"column":150}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nameAttr") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":151},"end":{"line":2,"column":194}}})) != null ? stack1 : "")
    + "/>\n<span class=\"ui-datepicker-trigger icon-calendar\"></span>\n<div class=\"date-error\"></div>\n";
},"useData":true});