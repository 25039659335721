import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import lookupConstants from 'common/dynamicPages/api/constants';
import Collection from '@glu/core/src/collection';
import UserCentricModel from 'app/administration/models/user/smbUser';
import UserGroupLookup from 'app/administration/views/userMaintenance/userGroupLookup';
import alert from '@glu/alerts';
import UserGroupCollection from 'app/administration/collection/userGroups';
import errHandler from 'system/errHandler';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import userCompanyModalTmpl from './userCompanyModal.hbs';

export default Layout.extend({
    template: userCompanyModalTmpl,
    loadingTemplate: loadingWidgetTmpl,

    ui: {
        alertRegion: '.alert-region',
        $userGroupField: '.usergroup-field',
    },

    events: {
        'change @ui.$userGroupField': 'changeUserGroupSelectHandler',
    },

    initialize(options) {
        this.options = options;
        this.mode = options.mode || constants.MODES.VIEW;
        this.isAdmin = (window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal');
        this.model = options.model || new UserCentricModel();
        this.userGroups = options.collection;
        this.lookupGroups = new Collection();
        this.userGroupLookup = new UserGroupLookup({
            isAdmin: this.isAdmin,
        });

        this.setInitModalTitleButtons();
    },

    changeUserGroupSelectHandler(e) {
        const model = this.lookupGroups.get(e.val);
        if (model) {
            this.model.set('USERGROUP', model);
        }
    },

    renderUserGroupContent() {
        const lookup = this.userGroupLookup;
        const self = this;
        let rowsPerPage = 1;
        this.ui.$userGroupField.comboBox({
            dropdownAutoWidth: true,
            triggerChange: true,

            query: util.debounce((query) => {
                lookup.setStartRow((((query.page - 1) * rowsPerPage) + 1));
                rowsPerPage = (query.term.length < lookupConstants.COMBO_MIN_CHARS)
                    ? lookupConstants.COMBO_MIN_SIZE : lookupConstants.MAX_SERVER_ROWSPERPAGE;
                lookup.setPageSize(rowsPerPage);
                lookup.setSearch(query.term);

                lookup.send().then((result) => {
                    query.callback({
                        results: result.data,
                        more: ((query.page * rowsPerPage) < result.totalRows),
                    });
                    self.lookupGroups.add(self.userGroups.parse(result.data));
                });
            }, lookupConstants.COMBO_DEBOUNCE),
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            this.renderUserGroupContent();
        }
    },

    setInitModalTitleButtons() {
        this.modalClass = this.options.modalClass || '';

        this.dialogTitle = this.options.pageTitle || locale.get('administration.user.add');

        this.dialogButtons = [{
            text: locale.get('administration.continue.btn'),
            className: 'btn btn-primary',
            callback: 'addUserContinue',
        }, {
            text: locale.get('administration.cancel.btn'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    cancel() {
        dialog.close();
    },

    addUserContinue() {
        const selectedCompany = this.model.get('USERGROUP');

        if (!selectedCompany) {
            // display notification message
            this.alertView = alert.danger(
                locale.get('administration.company.select.error'),
                {
                    canDismiss: true,
                    animate: true,
                },
            );

            this.ui.alertRegion.show(this.alertView);
        } else {
            this.clearErrors();
            this.trigger('selectUserCompany:success', selectedCompany);
            dialog.close();
        }
    },

    clearErrors() {
        this.ui.alertRegion.empty();
    },

    loadRequiredData() {
        const self = this;

        // Check if userGroups is already populated from parent view
        if (this.userGroups) {
            self.setHasLoadedRequiredData(true);
            self.render();
            return;
        }

        const userGroupPromise = new Promise((resolve, reject) => {
            new UserGroupCollection({
                isAdmin: self.isAdmin,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        const promiseArr = [userGroupPromise];

        Promise.all(promiseArr)
            .then((results) => {
                [self.userGroups] = results;

                self.setHasLoadedRequiredData(true);
                self.render();
            })
            .then(null, errHandler);
    },
});
