import CompositeView from '@glu/core/src/compositeView';
import constants from 'app/administration/constants';
import ReportGroupView from './reportGroupView';
import reportingTmpl from './reporting.hbs';

export default CompositeView.extend({
    template: reportingTmpl,
    itemView: ReportGroupView,
    itemViewContainer: '.section-body',

    initialize(opts) {
        const groupCollection = this.model.get('simpleTypes');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isSimpleEntitled()));
        }

        this.collection = groupCollection;
        this.itemViewOptions = {
            userGroup: opts.model.userGroupModel.get('id'),
            mode: opts.mode,
            entity: opts.entity,
        };
    },
});
