var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.securityQuestionChallenge",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":76}}}))
    + "</h1>\n</div>\n<section>\n    <div data-region=\"alertRegion\"></div>\n</section>\n<fieldset class=\"section section-container\">\n    <div class=\"section-body form-container\">\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n        <div class=\"form-group\">\n            <div class=\"field-container-lg\">\n                <label for=\"question0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":74}}}))
    + "</label><br/>\n                <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,0,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":43}}})) != null ? stack1 : "")
    + "</strong>\n                <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":47}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":16,"column":53},"end":{"line":16,"column":71}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,0,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":17,"column":74},"end":{"line":17,"column":92}}})) != null ? stack1 : "")
    + "\"></span>\n            </div>\n        </div>\n\n        <div class=\"form-group\">\n            <div class=\"field-container-lg\">\n                <label for=\"question1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":74}}}))
    + "</label><br/>\n                <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,1,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":24,"column":43}}})) != null ? stack1 : "")
    + "</strong>\n                <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":25,"column":29},"end":{"line":25,"column":47}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":25,"column":53},"end":{"line":25,"column":71}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,1,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":26,"column":74},"end":{"line":26,"column":92}}})) != null ? stack1 : "")
    + "\"></span>\n            </div>\n        </div>\n\n        <div class=\"form-group\">\n            <div class=\"field-container-lg\">\n                <label for=\"question2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.secretQuestion3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":39},"end":{"line":32,"column":74}}}))
    + "</label><br/>\n                <strong>"
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias3).call(alias1,2,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":33,"column":43}}})) != null ? stack1 : "")
    + "</strong>\n                <input name=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":34,"column":29},"end":{"line":34,"column":47}}})) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":34,"column":53},"end":{"line":34,"column":71}}})) != null ? stack1 : "")
    + "\" maxlength=\"35\" data-bind=\"model\" type=\"text\" class=\"form-control securityAnswer\" aria-required=\"true\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + ((stack1 = (lookupProperty(helpers,"getColName")||(depth0 && lookupProperty(depth0,"getColName"))||alias3).call(alias1,2,{"name":"getColName","hash":{},"data":data,"loc":{"start":{"line":35,"column":74},"end":{"line":35,"column":92}}})) != null ? stack1 : "")
    + "\"></span>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"form-group\">\n            <button type=\"submit\" id=\"security-challenge-action-submit\" class=\"btn btn-primary\" data-action=\"save\" data-disable-on-submit>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":45,"column":42}}}))
    + "\n            </button>\n            <button type=\"button\" id=\"security-challenge-action-cancel\" class=\"btn btn-link\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":114},"end":{"line":47,"column":140}}}))
    + "</button>\n        </div>\n    </div>\n</div>";
},"useData":true});