import util from '@glu/core/src/util';
import CompositeView from '@glu/core/src/compositeView';
import locale from '@glu/locale';
import ItemView from './itemView';
import LimitsUtil from '../limitsUtil';
import typeLimitsViewTmpl from './typeLimitsView.hbs';

export default CompositeView.extend({
    template: typeLimitsViewTmpl,
    itemView: ItemView,
    itemViewContainer: '.list-container',
    className: 'edit-limit-group',

    ui: {
        limitFields: '.limit-field',
    },

    initialize(options) {
        this.itemViewOptions = {
            modifyMode: options.modifyMode || false,
            mode: options.mode,
            paymentTypeGroup: this.model.id,
        };

        this.collection = this.model.getEntitledTypes(!options.modifyMode);
    },

    onRender() {
        this.$('input').inputmask(
            'number',
            LimitsUtil.wholeNumberWithSeparatorFormat(),
        );
    },

    applyQuick() {
        const self = this;
        const quickValues = [];

        // Extract quick values and their field names in the model, wipe out quick values
        self.ui.limitFields.each((idx, field) => {
            const $field = self.$(field);
            const limitType = $field.data('value');

            if ($field.val()) {
                quickValues.push({
                    field: `limits.${limitType}.value`,
                    value: $field.val(),
                });

                $field.val('');
            }
        });

        // Apply quick values to the model
        this.collection.each((type) => {
            util.each(quickValues, (quickValue) => {
                type.set(quickValue.field, quickValue.value);

                // Validate the value if we are CGBO.  We should only be in modify.
                if (this.options.isCannotGrantBeyondOwnUser && this.options.modifyMode) {
                    type.isValid();
                }
            });
        });
    },

    templateHelpers() {
        const type = this.model.get('types').findWhere({
            entitled: true,
        });
        const id = !util.isUndefined(this.model.get('id')) ? locale.get(`uce.${this.model.get('id')}`) : locale.get(`uce.${this.model.get('type')}`);

        return {
            showTrans: util.has(type.get('limits'), 'transaction'),
            showBatch: util.has(type.get('limits'), 'batch'),
            showDaily: util.has(type.get('limits'), 'daily'),
            modifyMode: this.options.modifyMode,
            getLimitLabel: locale.get('administration.limitsText', id),
        };
    },
});
