export default ({
    // see view factory for relevant documentation
    'SETUP/viewContact/:corpOrSmb': 'beneViewContact',

    'SETUP/modifyContact/:corpOrSmb': 'beneModifyContact',
    'ADMINSTRATION/manageBeneAddressBook': 'beneManageContactsWorkspace',
    'SETUP/manageBeneAddressBook': 'beneManageContactsWorkspace',
    'SETUP/addContact/:corpOrSmb': 'beneAddContact',
    'SETUP/babimport': 'babfileImport',

    /* Deeplinks */
    'ADMINSTRATION/listBeneAddressBook(/)(:viewId)': 'beneManageContacts',
});
