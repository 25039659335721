import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';

const FeatureList = Model.extend({
    sync(method, model, options) {
        if (method === 'read') {
            http.get(services.generateUrl('/security/forgottenPasswordFeature'), (response) => {
                options.success(response);
            }, () => {});
        }
    },
});

export default new FeatureList();
