import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { STATUS } from './AsyncPrintViewModal';

const propTypes = {
    status: PropTypes.string.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    message: PropTypes.arrayOf(PropTypes.string).isRequired,
    onCancel: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

const AsyncPrintViewModalBody = (props) => {
    const {
        status,
        classes,
        message,
        onCancel,
        type,
    } = props;

    const renderSuccessMessage = () => {
        if (!message) return null;
        if (message.length === 3) {
            return (
                <>
                    <p data-testid={status}>{message[0]}</p>
                    <p>{message[1]}</p>
                    <button
                        className={classes.link}
                        onClick={onCancel}
                    >
                        {message[2]}
                    </button>
                </>
            );
        }
        if (message.length === 1) {
            return <p data-testid={status}>{message[0]}</p>;
        }
        return null;
    };

    /**
     * @method getProcessingMessage
     * Helper method to return the text above loading icon in async report modal
     * @return {string}
     */
    const getProcessingMessage = () => {
        if (type === 'export') return locale.get('async.processing.export');
        return locale.get('async.processing');
    };

    switch (status) {
    case STATUS.PROCESSING:
        return (
            <>
                <p data-testid={status}>{getProcessingMessage()}</p>
                <div className="section-body">
                    <div className="loading-indicator-view"><span className={classes.loadingIndicator} /></div>
                </div>
            </>
        );
    case STATUS.SUCCESS:
        return renderSuccessMessage();
    case STATUS.ERROR:
        return (
            <p data-testid={status}>{message.join(' ')}</p>
        );
    default:
        return null;
    }
};

AsyncPrintViewModalBody.propTypes = propTypes;

export default AsyncPrintViewModalBody;
