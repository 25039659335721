import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import BalanceTransactionSummaryView from 'app/balanceAndTransaction/views/balanceTransactionSummary';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import util from '@glu/core/src/util';
import moment from 'moment';
import store from 'system/utilities/cache';
import Model from '@glu/core/src/model';
import constants from 'app/balanceAndTransaction/constants';
import PrintAccountUtil from 'app/balanceAndTransaction/printSingleAccountUtil';
import combinedAccountTmpl from './combinedAccount.hbs';
import PartialAccountSummaryView from './partialAccountSummary';
import PartialSummaryBodyView from './partialSummaryBody';
import CombinedAccountPrintModal from './combinedAccountPrintModal';
import DepositTransactionsListView from './depositAccounts/customReportDepositTransactionListView';

const CombinedAccount = Layout.extend({
    className: 'combined-account-panel panel panel-secondary',
    template: combinedAccountTmpl,

    ui: {
        $modalBtn: 'button[data-hook="show-transactions"]',
        $panelToggle: '[data-toggle="collapse"]',
        $loading: '[data-hook="getLoadingState"]',
        $fetchError: '[data-hook="getErrorState"]',
    },

    events: {
        'click @ui.$panelToggle': 'onPanelToggle',
        'click button[data-hook="print-transactions-button"]': 'printTransactionDetailsModal',
    },

    regions: {
        accountSummary: '[data-hook="account-summary-region"]',
        summaryBody: '[data-hook="summary-body-region"]',
    },

    initialize(options) {
        this.options = options;
        this.displayTransactionTotals = applicationConfigParams.getValue('GENERAL', 'DISPLAYTRANSACTIONTOTALS') === '1';

        this.listenTo(this.model, 'change:BALANCEERROR change:TRANSACTIONERROR', this.onTotalsUpdate.bind(this));
        this.listenTo(this.model, 'realTimeLoading', this.toggleRealTimeLoad.bind(this));
    },

    onRender() {
        this.accountSummary.show(new PartialAccountSummaryView({
            model: this.model,
        }));
        this.renderTotalSummary();
        this.ui.$modalBtn.data('model', this.model);
        this.$('[data-toggle="popover"]').popover();
    },

    renderTotalSummary() {
        this.summaryBody.show(new PartialSummaryBodyView({
            model: this.model,
            stack: this.options.stack,
        }));
    },

    toggleRealTimeLoad(isLoading) {
        // toggle loading
        this.ui.$loading.toggleClass('hide', !isLoading);
    },

    /**
     * @method onTotalsUpdate
     * callback on totals data update, will rerender totals sections and refresh open
     * transaction grids
     */
    onTotalsUpdate() {
        const hasFetchError = (this.model.get('BALANCEERROR') === '1') || (this.model.get('TRANSACTIONERROR') === '1');

        this.toggleRealTimeLoad();
        this.ui.$fetchError.toggleClass('hide', !hasFetchError);

        if (!hasFetchError) {
            this.renderTotalSummary();

            // refresh grids if open
            if (this.transactionDetailsRegion.currentView) {
                const detailRegions = [
                    this.transactionDetailsRegion.currentView,
                    this.transactionDetailsSecondaryRegion.currentView,
                ];

                detailRegions.forEach((detailView) => {
                    if (detailView && detailView.gridView) {
                        detailView.gridView.refreshGridData();
                    }
                });
            }
        }
    },

    /**
     * @return {Object} base grid option props
     * Returns the basic grid options props needed to instantiate a
     * transaction details grid
     */
    createBaseTransGridOptions() {
        const { filterModel, filterView } = this.options;
        const curDate = moment().format('MM/DD/YYYY');
        const startDate = moment(filterModel.get('START_DATE'));
        const endDate = moment(filterModel.get('END_DATE'));
        const setRealTime = (this.model.get('ISREALTIMEACCOUNT') === '1' && ((moment(curDate).isBetween(startDate, endDate)) || moment(curDate).isSame(startDate) || moment(curDate).isSame(endDate)));

        const searchFields = filterView.getTransactionFilters()
            .concat([
                {
                    fieldName: 'AccountFilter',
                    fieldValue: [this.model.get('ACCOUNTFILTER')],
                    dataType: 'text',
                    operator: '=',
                }, {
                    fieldName: 'Post_Date',
                    fieldValue: [
                        filterModel.get('START_DATE'),
                        filterModel.get('END_DATE'),
                    ],
                    dataType: 'date',
                    operator: 'BETWEEN',
                },
            ]);

        return {
            stack: this.options.stack,
            serviceName: this.options.accountDetailServiceName,
            model: new Model({
                accountFilter: this.model.get('ACCOUNTFILTER'),
                accountNumber: this.model.get('ACCOUNT_NUM'),
                isRealTime: setRealTime,
            }),
            additionalSearchFields: searchFields,
            filter: false,
            enableSavedViews: false,
        };
    },

    /**
     * @method showTransactionDetails
     * @param {string} transFilterType
     * Prepare and render the Transaction Detail Grids within the panel
     */
    showTransactionDetails(transFilterType) {
        const gridOptions = this.createBaseTransGridOptions();
        if (transFilterType === 'single') {
            // 'single' credit/debit view
            this.showTransactionGrid(gridOptions, this.transactionDetailsRegion);
        } else {
            // 'separate' (Both credit and debit)
            const creditGridOptions = {
                ...gridOptions,
                model: new Model(Object.assign(gridOptions.model.toJSON(), {
                    transactionType: 'Credit',
                })),
                additionalSearchFields: gridOptions.additionalSearchFields.concat([{
                    fieldName: 'DR_CR',
                    fieldValue: ['CR'],
                    dataType: 'text',
                    operator: '=',
                }]),
            };

            const debitGridOptions = {
                ...gridOptions,
                model: new Model(Object.assign(gridOptions.model.toJSON(), {
                    transactionType: 'Debit',
                })),
                additionalSearchFields: gridOptions.additionalSearchFields.concat([{
                    fieldName: 'DR_CR',
                    fieldValue: ['DR'],
                    dataType: 'text',
                    operator: '=',
                }]),
            };
            this.showTransactionGrid(creditGridOptions, this.transactionDetailsRegion);
            this.showTransactionGrid(debitGridOptions, this.transactionDetailsSecondaryRegion);
        }
        if (this.displayTransactionTotals) {
            this.setupAccountTransactionBalanceDetails(gridOptions.additionalSearchFields);
        }
    },

    /**
     * @param {Object} gridOptions
     * @param {Region} region
     * Shows a grid with specified option props in the passed Marionette Region
     */
    showTransactionGrid(gridOptions, region) {
        region.show(new DepositTransactionsListView(gridOptions));
    },

    /**
     * Event handler for panel toggle click
     * will trigger width setting methods on grid on expand
     */
    onPanelToggle() {
        if (!this.options.canViewTransactions) {
            return;
        }

        if (!this.transactionDetailsRegion.currentView) {
            // show grids on open if none are shown
            this.showTransactionDetails(this.options.filterModel.get('accountTransactionsView'));
        } else {
            // will set grid width constraints on expand
            util.defer(() => {
                if (this.ui.$panelToggle.attr('aria-expanded') === 'true') {
                    const detailRegions = [
                        this.transactionDetailsRegion.currentView,
                        this.transactionDetailsSecondaryRegion.currentView,
                    ];

                    detailRegions.forEach((detailView) => {
                        if (detailView && detailView.gridView.grid) {
                            detailView.gridView.grid.tableView.setWidth();
                        }
                    });
                }
            });
        }
    },

    /**
     * @param {View} gridView
     * @return {Array} sort fields data
     * Extracts and returns sort fields from transaction grids shown
     */
    getTransGridSortFields(gridView) {
        const { sortKey, sortOrder } = gridView.wrapper;
        if (!sortKey && !sortOrder) {
            return [];
        }

        return [{
            fieldName: sortKey,
            sortOrder,
        }];
    },

    /**
     * Helper method to decide whether to display print modal or not.
     * When Display Transactions Totals is enabled: display modal
     * When Display Transactions Totals is disabled: print immediately.
     */
    printTransactionDetailsModal() {
        if (this.displayTransactionTotals) {
            this.combinedAccountPrintModal = new CombinedAccountPrintModal({
                model: this.model,
                onPrint: this.printTransactionDetails.bind(this),
            });
            dialog.open(this.combinedAccountPrintModal);
        } else {
            this.printTransactionDetails();
        }
    },

    /**
     * @method printTransactionDetails
     * Click callback on print button, will produce print modal for transaction details
     */
    printTransactionDetails() {
        const transDetailView = this.transactionDetailsRegion.currentView;
        const secTransDetailView = this.transactionDetailsSecondaryRegion.currentView;
        const clonedFilterModel = this.options.filterModel.clone();
        const accountSelectBy = this.options.filterModel.get('accountSelectBy');

        // Get Transaction Grid sort fields
        const sortFields = (transDetailView)
            ? this.getTransGridSortFields(transDetailView.gridView) : [];

        const sortFieldsDebit = (secTransDetailView)
            ? this.getTransGridSortFields(secTransDetailView.gridView) : [];

        /*
         * inquiry id used to key into inquiries to query balance data. accountSelectBy is the
         * account filter and can be single, group or bank code.
         */
        const inquiryId = (accountSelectBy === 'group') ? constants.INQUIRYID_BAL_ACCOUNTGROUP : constants.INQUIRYID_BAL_ACCOUNT;

        /*
         * account transactions is always a single account lookup. Using cloneFilterModel to
         * not mess with original filter model that displays the filter on screen
         */
        clonedFilterModel.set({
            accountSelectBy: 'number',
            accountSelect: this.model.get('ACCOUNTFILTER').split(),
        });
        const separatedPermissions = store.get('btr:initialTab') === 'showNewCustomReport';
        PrintAccountUtil.print({
            accountURL: `${constants.BTR_REPORT_PREFIX}accountReportView`,
            filterModel: clonedFilterModel,
            searchFields: this.options.filterView.getTransactionFilters(),
            inquiryId,
            sortFields,
            sortFieldsDebit,
            ...this.displayTransactionTotals && {
                includeSummaryInfo: this.model.get('includeSummaryInfo') ? 1 : 0,
            },
            separated: separatedPermissions ? 1 : 0,
        });
    },

    /**
     * @method setupAccountTransactionBalanceDetails
     * Creates and mounts transaction summary section
     * @param {array} gridFilter - list of grid filter objects
     */

    setupAccountTransactionBalanceDetails(gridFilter) {
        const tabId = this.options.filterModel && this.options.filterModel.get('tabId');
        this.balanceTransactionSummaryView = new BalanceTransactionSummaryView({
            currency: this.model.get('CURRENCY_CODE'),
            gridFilter,
            balancesHidden: true,
            tabId,
        });
        this.accountTransactionBalanceRegion.show(this.balanceTransactionSummaryView);
    },

    templateHelpers() {
        const disableCombinedAccountPrint = serverConfigParams.get('DisableCombinedAccountPrint');
        const showAccountPrint = disableCombinedAccountPrint ? disableCombinedAccountPrint === 'false' : true;
        return {
            id: this.cid,
            hasGroup: this.model.has('ACCOUNT_GROUP_NAME'),
            filterStartDate: this.options.filterModel.get('START_DATE'),
            filterEndDate: this.options.filterModel.get('END_DATE'),
            accountName: (this.model.get('ACCOUNT_NAME')) ? util.unescape(this.model.get('ACCOUNT_NAME').replace('&apos;', '\'')) : '',
            canViewTransactions: this.options.canViewTransactions,
            displayPrintTransactionsText: this.displayTransactionTotals && showAccountPrint,
            showAccountPrint,
        };
    },
});

export default CombinedAccount;
