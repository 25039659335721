import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';

export default Model.extend({
    initialize(options) {
        this.typeCode = options.typeCode;
        this.bankCode = options.bankCode;
        this.accountNumber = options.accountNumber;
        this.subAccountNumber = options.subAccountNumber;
        this.currency = options.currency;
        this.id = this.accountNumber;
        this.origin = options.origin;
        this.accountType = options.accountType;
        this.accountSubType = options.accountSubType;
        this.showAccountBalance = options.showAccountBalance;
        this.setService();
    },

    setService() {
        if (this.typeCode === 'LOANPAY') {
            if (this.origin) {
                this.service = services.balanceAndTransaction;
            }
            if (!this.origin) {
                this.service = services.loanReporting;
            }
        }

        if (this.typeCode === 'LOANDRAW') {
            if (this.origin) {
                this.service = services.loanReporting;
            }
            if (!this.origin) {
                this.service = services.balanceAndTransaction;
            }
        }
    },

    sync(method, modelParam, options) {
        const model = modelParam;
        // NH-182148 - returning empty success response when the showAccountBalance is false,
        // to avoid making a post call.
        if (!this.showAccountBalance) {
            options.success({});
            return;
        }
        if (method === 'read') {
            const data = {
                productCode: 'RTGS',
                functionCode: 'INST',
                typeCode: this.typeCode,
                bankCode: this.bankCode,
                accountNumber: this.accountNumber,
                subAccountNum: this.subAccountNumber,
                accountType: this.accountType,
                accountSubType: this.accountSubType,
                currency: this.currency,
            };

            http.post(this.service, data, (response) => {
                options.success(response);
            }, (response) => {
                model.error = JSON.parse(response.responseText);
                options.error(model, {});
            });
        }
    },
});
