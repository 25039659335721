import ItemView from '@glu/core/src/itemView';
import constants from 'app/administration/constants';
import locale from '@glu/locale';
import actionCheckboxesTmpl from './actionCheckboxes.hbs';

export default ItemView.extend({
    template: actionCheckboxesTmpl,
    className: 'entitlement-checkboxes',

    initialize() {
        const { model } = this;

        this.listenTo(model, 'change:approve.entitled', () => {
            model.collection.trigger('approvalRightsChanged:account');
            model.trigger('approvalRightsChanged:account');
        });
        this.model.collection.trigger('approvalRightsChanged:account');
        this.model.trigger('approvalRightsChanged:account');
    },

    templateHelpers() {
        const self = this;
        const readOnly = self.options.column.get('mode') === constants.MODES.VIEW;

        return {
            cid: this.cid,
            manageLabel: locale.get('uce.entitlement.manage'),
            approveLabel: locale.get('uce.entitlement.approve'),
            repairLabel: locale.get('uce.entitlement.repair'),
            modifyLabel: locale.get('uce.entitlement.modify'),

            disableManage() {
                return self.model.get('manage.applicable') && !readOnly ? '' : 'disabled="disabled"';
            },

            disableApprove() {
                return self.model.get('approve.applicable') && !readOnly ? '' : 'disabled="disabled"';
            },

            disableRepair() {
                return self.model.get('repair.applicable') && !readOnly ? '' : 'disabled="disabled"';
            },

            disableModify() {
                return self.model.get('modify.applicable') && !readOnly ? '' : 'disabled="disabled"';
            },
        };
    },
});
