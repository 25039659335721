import SummarySectionWidget from 'common/uiWidgets/summarySectionWidget/summarySectionWidget';

export default SummarySectionWidget.extend({
    exceptions: ['ERRORS'],
    timestampFields: ['LASTPAYDATE', 'PRENOTEDATE'],

    paymentSummaryRows: [
        ['STATUS_DESCRIPTION', 'PRENOTEDATE'],
        ['ERRORS'],
    ],

    templateSummaryRows: [
        ['STATUS_DESCRIPTION', 'LASTPAYDATE', 'PRENOTEDATE'],
        ['ERRORS'],
    ],

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        SummarySectionWidget.prototype.initialize.call(this, options);
    },

    computeLastApprover() {

    },
});
