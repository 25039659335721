import gluLocale from '@glu/locale';
import transform from 'common/util/transform';

export const normalizeParametersResponse = (response) => {
    const parameterRows = response?.queryResponse?.QueryData?.queryRows || [];
    const normalizedParameters = {
        EMAIL: {
            pillGroups: {
                FIELDS: {
                    label: 'Individual Fields',
                    pills: [],
                },
                BLOCKS: {
                    label: 'Blocks of Text',
                    pills: [],
                    tooltipSample: true,
                },
            },
            pills: {},
        },
        TEXT: {
            pillGroups: {
                FIELDS: {
                    label: 'Individual Fields',
                    pills: [],
                },
                BLOCKS: {
                    label: 'Blocks of Text',
                    pills: [],
                    tooltipSample: true,
                },
            },
            pills: {},
        },
    };

    parameterRows.forEach((row) => {
        const rowPairs = transform.pairsToHash(row.mapDataList, 'toField', 'value');
        const deliveryType = rowPairs.DELIVERYTYPE;

        if (rowPairs.BADGETYPE === 'FIELD') {
            normalizedParameters[deliveryType].pillGroups.FIELDS.pills = [
                ...normalizedParameters[deliveryType].pillGroups.FIELDS.pills,
                rowPairs.FIELDNAME,
            ];
        } else {
            normalizedParameters[deliveryType].pillGroups.BLOCKS.pills = [
                ...normalizedParameters[deliveryType].pillGroups.BLOCKS.pills,
                rowPairs.FIELDNAME,
            ];
        }

        normalizedParameters[deliveryType].pills[rowPairs.FIELDNAME] = {
            label: gluLocale.get(rowPairs.FIELDLABEL),
            tooltipDesciption: gluLocale.get(rowPairs.TOOLTIP),
            /*
             * For proper rendering of TEXT sample content,
             * replace newline character with line break
             */
            sampleHtml: deliveryType === 'TEXT'
                ? rowPairs.TOOLTIPDETAIL.replace(/\n/g, '<br>')
                : rowPairs.TOOLTIPDETAIL,
        };
    });

    return normalizedParameters;
};

export default {
    normalizeParametersResponse,
};
