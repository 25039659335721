import React from 'react';
import PropTypes from 'prop-types';
import { CaretRightNextIcon, CaretLeftNextIcon } from '@glu/icons-react';
import locale from '@glu/locale';

import PageSelectorStartEnd from './PageSelectorStartEnd';

export const displayCount = 4;

export default function PageSelector({
  classes,
  onArrowClick,
  onChange,
  page,
  pageSize,
  totalItems
}) {
  const {
    activePage, flexWrapper, paginationButton, selectorWrapper
  } = classes;
  const numberOfPages = Math.ceil(totalItems / pageSize);
  const pages = [...Array(numberOfPages)].map((_, index) => index);
  const last = pages[pages.length - 1] || 0;
  const startEllipsis = page >= displayCount - 1 && numberOfPages >= displayCount + 1;
  const endEllipsis = last - page > 1 && numberOfPages > displayCount + 1;
  const startRange = !startEllipsis
    ? 1 // Start on first index if at beginning of range without ellipsis
    : last === page
      ? last - 2 // Start 2 from the last item if on last page
      : page - 1; // Start one before the current page if not last page
  const endRange = endEllipsis
    ? page === 0
      ? 3 // For first item display 3 records if display end ellipsis
      : page + 2 // Display 3 records including current page
    : last; // Display last index if no ending ellipsis

  return (
    <div className={selectorWrapper}>
      {totalItems !== 0 && page !== 0
        && (
          <button
            onClick={onArrowClick('back')}
            data-qa="pagination-rightArrow"
            type="button"
            className={paginationButton}
            aria-label={locale.get('dataComponents.back')}
          >
            <CaretRightNextIcon focusable={false} />
          </button>
        )}
      <div className={flexWrapper}>
        <PageSelectorStartEnd
          className={page === 0 ? activePage : ''}
          classes={classes}
          enableEllipsis={startEllipsis}
          page={0}
          onChange={onChange}
        />
        {pages.slice(startRange, endRange).map((pageDisplay) => (
          <button
            className={page === pageDisplay ? activePage : ''}
            type="button"
            key={pageDisplay}
            onClick={onChange(pageDisplay)}
            data-qa={`pagination-page-${pageDisplay}`}
          >
            {pageDisplay + 1}
          </button>
        ))}
        {last !== 0 && (
          <PageSelectorStartEnd
            classes={classes}
            className={page === last ? activePage : ''}
            enableEllipsis={endEllipsis}
            pageDisplay={page}
            page={last}
            onChange={onChange}
          />
        )}
      </div>
      {totalItems !== 0 && page !== last
          && (
          <button
            className={paginationButton}
            onClick={onArrowClick('forward')}
            data-qa="pagination-leftArrow"
            type="button"
            aria-label={locale.get('dataComponents.next')}
          >
            <CaretLeftNextIcon focusable={false} />
          </button>
          )}
    </div>
  );
}

PageSelector.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onArrowClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  totalItems: PropTypes.number.isRequired
};

PageSelector.defaultProps = {
  page: 0,
  pageSize: 25
};
