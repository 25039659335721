import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import $ from 'jquery';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import PapaParse from 'papaparse';
import ViewTemplate from './view.hbs';

export default Layout.extend({
    template: ViewTemplate,

    ui: {
        $videoSelect: 'select[name="video-select"]',
        $videoPlayer: '#video-player',
        $videoSource: '#video-source',
        $transcriptText: 'p[name="transcript-text"]',
    },

    events: {
        'change @ui.$videoSelect': 'videoSelectionChanged',
    },

    initialize(options) {
        const self = this;

        this.context = options.context;
        const contextParams = options.context.split('&');
        [this.widgetId, this.marketSegment] = contextParams;
        this.external = contextParams[2] !== undefined && contextParams[2].length > 0;

        this.videos = new Collection();
        this.currentVideo = null;

        const requestData = {};
        requestData.widgetId = this.widgetId;
        requestData.marketSegment = this.marketSegment;
        requestData.external = this.external;

        http.post(services.generateUrl('/cxp/elearning/getWidgetContentModels'), requestData, (result) => {
            self.processContent(result);
        });
    },

    templateHelpers() {
        return {
            videos: this.videos.toJSON(),
        };
    },

    processContent(group) {
        const self = this;
        this.contentList = group.elearningContentModels;

        util.each(this.contentList, (content) => {
            self.videos.add({
                fileName: content.fileName,
                name: content.name,
                contentUrl: content.contentUrl,
                transcript: content.transcript,
                locale: content.locale,
                contentId: content.contentId,
            });
        });

        const firstVideo = this.videos.first();
        const contentId = firstVideo.get('contentId');
        this.ui.$videoSelect.val(contentId).prop('selected', true);

        self.render();
    },

    onRender() {
        const firstVideo = this.videos.first();
        if (firstVideo !== undefined) {
            this.currentVideo = firstVideo;
            this.getTranscript();
            const firstVideoUrl = this.getVideoUrl(firstVideo);
            this.updateVideoUrl(firstVideoUrl);
        }
    },

    videoSelectionChanged() {
        const self = this;
        const selectedId = this.ui.$videoSelect.val();
        this.videos.each((video) => {
            if (video.get('contentId') === +selectedId) {
                self.currentVideo = video;
                self.getTranscript();
                const videoUrl = self.getVideoUrl(video);
                self.updateVideoUrl(videoUrl);
            }
        });
    },

    getVideoUrl(video) {
        return `${video.get('contentUrl')}/${video.get('fileName')}`;
    },

    getTranscriptUrl(video) {
        return `${video.get('contentUrl')}/${video.get('transcript')}`;
    },

    updateVideoUrl(url) {
        const video = this.ui.$videoPlayer.get(0);
        this.ui.$videoPlayer.bind('timeupdate', util.bind(this.updateTranscript, this));
        this.ui.$videoSource.attr('src', url);
        video.load();
        video.play();
    },

    getTranscript() {
        const self = this;
        const transcriptUrl = this.getTranscriptUrl(this.currentVideo);
        $.ajax({
            type: 'GET',
            url: transcriptUrl,
            dataType: 'text/csv',

            success(result) {
                self.parseTranscript(result);
            },

            error(xhr) {
                self.parseTranscript(xhr.responseText);
            },
        });
    },

    parseTranscript(result) {
        const self = this;
        self.currentTranscript = [];
        PapaParse.parse(
            result,
            {
                header: false,
                dynamicTyping: true,

                complete(results) {
                    util.each(results.data, (line) => {
                        const transcriptObj = {};
                        [
                            transcriptObj.start,
                            transcriptObj.end,
                            transcriptObj.text,
                        ] = line;
                        self.currentTranscript.push(transcriptObj);
                    });
                },
            },
        );
    },

    updateTranscript() {
        const self = this;
        const currentTime = Math.floor(this.ui.$videoPlayer.get(0).currentTime);
        let transcriptText = '';
        util.each(self.currentTranscript, (transcriptLine) => {
            if (currentTime > transcriptLine.start && currentTime < transcriptLine.end) {
                transcriptText = transcriptLine.text;
            }
        });
        this.ui.$transcriptText.text(transcriptText);
    },
});
