import util from '@glu/core/src/util';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import RSATokenModel from 'system/mfa/models/rsaToken';
import BaseMFAView from 'system/mfa/views/baseMFA';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import mfaUtil from 'system/mfa/util';
import userInfo from 'etc/userInfo';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import enterRSATokenSoftTokenTmpl from './enterRSATokenSoftToken.hbs';

const EnterRSATokenSoftToken = BaseMFAView.extend({
    template: enterRSATokenSoftTokenTmpl,
    loadingTemplate: loadingModalTmpl,

    initialize(options) {
        const challengeSettingsModel = options.challengeSettings;
        this.challengeType = challengeSettingsModel.get('challengeType');

        this.model = new RSATokenModel({
            challengedAction: options.challengedAction,
            challengeType: options.challengeType,
        });
    },

    onRender() {
        this.trigger('dialog:buttons:change', this.buttons());
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }

        if (this.model.get('showSuccess')) {
            this.submitSuccessComplete();
        } else if (this.model.get('showPinSuccess')) {
            this.submitSuccessPinComplete();
        }
    },

    /**
     * @method getModalPromises
     * - fetches MFA configuration data
     */
    getModalPromises() {
        return new Promise((resolve, reject) => {
            serverConfigParams.fetch({
                success: resolve,
                error: reject,
                isPreLoginMfaFetch: true,
            });
        });
    },

    /**
     * @method loadRequiredData
     * - loads MFA parameters provided at user login
     */
    loadRequiredData() {
        const self = this;
        this.getModalPromises().then((results) => {
            self.model.set(results.toJSON());
            self.model.set('showPin', true);
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    /**
     * @method buttons
     * - sets Submit and Cancel buttons in modal dialog box
     */
    buttons() {
        if (this.model.get('showForgotPin')) {
            return [
                {
                    text: locale.get('button.submit'),
                    className: 'btn btn-primary btn-sm',
                    callback: util.bind(this.forgotPIN, this),
                },
                {
                    text: locale.get('button.cancel'),
                    className: 'btn btn-secondary btn-sm',
                    callback() {
                        this.close();
                        mfaUtil.cancelRSASoftToken();
                        if (!userInfo.isLoggedIn()) {
                            /*
                             * The `logout` route doesn't seem to be available when
                             * the users signs in via SSO and before the app loads.
                             * For now, we are forcing a page reload to let router.js
                             *  handle the logout
                             */
                            window.location.reload();
                        }
                    },
                },
            ];
        }
        return [
            {
                text: locale.get('button.submit'),
                className: 'btn btn-primary btn-sm',
                callback: util.bind(this.submitCode, this),
            },
            {
                text: locale.get('button.cancel'),
                className: 'btn btn-secondary btn-sm',
                callback() {
                    this.close();
                    mfaUtil.cancelRSASoftToken();
                    if (!userInfo.isLoggedIn()) {
                        /*
                         * The `logout` route doesn't seem to be available when
                         * the users signs in via SSO and before the app loads.
                         * For now, we are forcing a page reload to let router.js
                         * handle the logout
                         */
                        window.location.reload();
                    }
                },
            },
        ];
    },

    /**
     * @method submitCode
     * - submits entered token code for authorization and processes response
     *
     */
    submitCode() {
        this.alertRegion.close();
        this.$el.find('.btn-primary').prop('disabled', true);
        this.model.save(
            {},
            {
                success: (result) => {
                    if (result.get('respHeader').result === false) {
                        this.model.set({
                            showConfirmNewPin: result.get('showConfirmNewPin'),
                            showNewPin: result.get('showNewPin'),
                            showNextTokenCode: result.get('showNextTokenCode'),
                            showSystemGenerated: result.get('showSystemGenerated'),
                            prompts: result.get('prompts'),
                            hidePasscode: result.get('requiresMoreInforamtion'),
                            showPin: !result.get('serialNumber'),
                            showSuccess: false,
                            showPinSuccess: false,
                            showForgotPin: false,
                        });
                        util.each(result.get('prompts'), (prompt) => {
                            if (prompt.promptKey === 'SYSTEM_GENERATED_PIN') {
                                this.model.set({
                                    SystemGeneratedPin: prompt.promptValue,
                                    newTokenPin: prompt.promptValue,
                                });
                            }
                        });
                        if (result.get('showNewPin')) {
                            this.model.addNewPinValidator();
                        }
                        if (result.get('showNextTokenCode')) {
                            this.model.addNextTokenCodeValidator();
                        }
                        this.render();
                        const resp = result.get('respHeader');
                        if (resp.message) {
                            // handle error types
                            const message = resp.message.join(' ');

                            this.alertView = alert.danger(
                                message,
                                {
                                    canDismiss: true,
                                },
                            );
                            this.alertRegion.show(this.alertView);
                        }
                    } else if (result.get('newTokenPin')) {
                        this.model.set({
                            showConfirmNewPin: result.get('showConfirmNewPin'),
                            showNewPin: result.get('showNewPin'),
                            showNextTokenCode: result.get('showNextTokenCode'),
                            showSystemGenerated: result.get('showSystemGenerated'),
                            prompts: result.get('prompts'),
                            hidePasscode: true,
                            showSuccess: false,
                            showPinSuccess: true,
                            showSuccessModal: true,
                            showForgotPin: false,
                        });
                        this.render();
                    } else {
                        this.model.set({
                            showConfirmNewPin: false,
                            showNewPin: false,
                            showNextTokenCode: false,
                            showSystemGenerated: false,
                            prompts: result.get('prompts'),
                            hidePasscode: true,
                            showSuccess: true,
                            showPinSuccess: false,
                            showSuccessModal: true,
                            showForgotPin: false,
                        });
                        this.render();
                    }
                    this.$el.find('.btn-primary').prop('disabled', false);
                },
            },
        );
    },

    forgotPIN() {
        this.alertRegion.close();
        this.model.set({
            challengeType: this.challengeType,
        });
        this.model.addSerialNumberValidator();
        this.model.save(
            {},
            {
                success: (result) => {
                    if (result.get('respHeader').result === false) {
                        this.model.set({
                            showConfirmNewPin: false,
                            showNewPin: false,
                            showNextTokenCode: false,
                            showSystemGenerated: false,
                            hidePasscode: false,
                            showSuccess: false,
                            showPinSuccess: false,
                            showSuccessModal: false,
                            showForgotPin: false,
                        });
                        this.render();
                        const resp = result.get('respHeader');
                        if (resp.message) {
                            // handle error types
                            const message = resp.message.join(' ');

                            this.alertView = alert.danger(
                                message,
                                {
                                    canDismiss: true,
                                },
                            );
                            this.alertRegion.show(this.alertView);
                        }
                    } else {
                        this.model.set({
                            showConfirmNewPin: false,
                            showNewPin: false,
                            showNextTokenCode: false,
                            showSystemGenerated: false,
                            hidePasscode: false,
                            showSuccess: false,
                            showPin: false,
                            showPinSuccess: false,
                            showSuccessModal: false,
                            showForgotPin: false,
                        });
                        this.render();
                    }
                },

                error: (response) => {
                    this.model.set({
                        showConfirmNewPin: false,
                        showNewPin: false,
                        showNextTokenCode: false,
                        showSystemGenerated: false,
                        hidePasscode: false,
                        showSuccess: false,
                        showPinSuccess: false,
                        showSuccessModal: false,
                        showForgotPin: false,
                    });
                    this.render();
                    const resp = JSON.parse(response.get('error').generic.shift());
                    const message = resp.respHeader.message.join(' ');

                    this.alertView = alert.danger(
                        message,
                        {
                            canDismiss: true,
                        },
                    );
                    this.alertRegion.show(this.alertView);
                },

            },
        );
        this.model.removeValidator('tokenString');
    },

    submitSuccessComplete() {
        this.completion();
    },

    forgotPinEvent() {
        this.alertRegion.close();
        this.model.set({
            showConfirmNewPin: false,
            showNewPin: false,
            showNextTokenCode: false,
            showSystemGenerated: false,
            hidePasscode: true,
            showSuccess: false,
            showPinSuccess: false,
            showSuccessModal: false,
            showForgotPin: true,
            token: '',
            tokenPin: '',
        });
        this.render();
    },

    submitSuccessPinComplete() {
        this.alertRegion.close();
        if (this.model.get('token')) {
            this.completion();
        } else {
            this.model.set({
                token: '',
                tokenPin: '',
                newTokenPin: '',
                confirmNewTokenPin: '',
            });
            this.model.save(
                {},
                {
                    success: (result) => {
                        this.model.set({
                            showConfirmNewPin: false,
                            showNewPin: false,
                            showNextTokenCode: false,
                            showSystemGenerated: false,
                            prompts: result.get('prompts'),
                            hidePasscode: false,
                            showSuccess: false,
                            showPinSuccess: false,
                            showSuccessModal: false,
                            showForgotPin: false,
                        });
                        this.render();
                    },
                },
            );
        }
    },

    templateHelpers() {
        return {
            showPasscode: !this.model.get('hidePasscode'),
            showPin: this.model.get('showPin'),
            showConfirmNewPin: this.model.get('showConfirmNewPin'),
            showNewPin: this.model.get('showNewPin'),
            showNextTokenCode: this.model.get('showNextTokenCode'),
            showSystemGenerated: this.model.get('showSystemGenerated'),
            showMFAPin: serverConfigParams.get('MFAPINSupport') ? serverConfigParams.get('MFAPINSupport').toUpperCase() === 'TRUE' : false,
            showForgotPin: serverConfigParams.get('ForgotPINSupport') ? serverConfigParams.get('ForgotPINSupport').toUpperCase() === 'TRUE' : false,
            showSoftToken: serverConfigParams.get('ShowSoftTokenLabel') ? serverConfigParams.get('ShowSoftTokenLabel').toUpperCase() === 'TRUE' : false,
            showSuccessModal: this.model.get('showSuccess') || this.model.get('showPinSuccess'),
            showForgotPinModal: this.model.get('showForgotPin'),
        };
    },
});

export default EnterRSATokenSoftToken;
