/* eslint-disable */
/*
 * For Pinpoint to work properly, there are a few server config params that need to be setup
 * for each client.
 * 1. TrusteerPinpointSnippetURL - a URL for the snippet
 * 2. TrusteerPinpointCallbackName - the name of a function that the snippet invokes
 * 3. TrusteerPinpointGlobalFunctionName - the name of a function we invoke exposed by the snippet
 * 
 * Route Tags - branding override
 * Depending on the implementation, different route tags may need to be overridden by branding.
 * The 'getRouteTag' function below provides a switch statement that can easily be updated
 * by adding cases or changing the return value of a case. 
 */

import util from 'underscore';
import Glu from '@glu/core/src/glu';
import * as serverConfigParamsImport from 'system/webseries/models/configurationParameters';
import { TrusteerPinpointRouteContext } from './trusteerPinpointRouteContext';
import getSession from './util';
const { default: serverConfigParams } = serverConfigParamsImport;

const getContext = (route) => {
    return TrusteerPinpointRouteContext[route] || 'other'
};

const trusteerPinpoint = {
    postAuth/* istanbul ignore next */(userInfo) {
        // Begin Trusteer Pinpoint snippet
        const trusteerPinpointCallbackName = serverConfigParams.get('TrusteerPinpointCallbackName');
        const trusteerPinpointSnippetURL = serverConfigParams.get('TrusteerPinpointSnippetURL');
        const trusteerPinpointDefaultTag = serverConfigParams.get('TrusteerPinpointDefaultTag');
        const pinpointIncludeGroup = serverConfigParams.get('pinpointIncludeGroup');

        if (util.isEmpty(trusteerPinpointSnippetURL)) {
            return;
        }
        const snippetUrl = `${trusteerPinpointSnippetURL}?dt=${trusteerPinpointDefaultTag ||
            getContext(window.location.pathname)}&r=${Math.random()}`;

        // Some Pinpoint integrations do not require this callback
        if (!util.isEmpty(trusteerPinpointCallbackName)) {
            // Callback invoked from the pinpoint snippet
            window[trusteerPinpointCallbackName] = function() {
                let result = {
                    u: {p: [userInfo.get('id')]}, // userId
                    c: getSession(serverConfigParams.get('TrusteerPinpointSessionCookieName')), //user session
                }

                if (pinpointIncludeGroup === 'true') {
                    result.u.p.unshift(userInfo.get('group'));
                }

                if (userInfo.get('permanentUserId')) {
                    result = {
                        ...result,
                        p: {p: userInfo.get('permanentUserId')}, // Permanent user id
                    };
                }
                return result;
            };
        }

        var f = document,
            e = window,
            i = e.location.protocol,
            b = [
                ["src", [i == "https:" ? "https:/" : "http:/", snippetUrl].join("/")],
                ["type", "text/javascript"],
                ["async", true]
            ],
            c = f.createElement("script"),
            h = f.getElementsByTagName("head")[0];
        setTimeout(function() {
            for (var d = 0, l = b.length; d < l; d++) {
                c.setAttribute(b[d][0], b[d][1])
            }
            h.appendChild(c)
        }, 0)
        // End Trusteer Pinpoint snippet
    },

    /**
     * When the route changes, invoke the global function created by the Pinpoint
     * snippet, passing in the route tag expected by Pinpoint
     * @param {*} route 
     */
    routeChange(route) {
        // NH-150501 Prevent callback from being invoked on initial load
        if (!Glu.history.fragment || Glu.history.fragment === 'redirecting' || Glu.history.fragment === '') {
            return;
        }
        const functionName = serverConfigParams.get('TrusteerPinpointGlobalFunctionName');
        if (typeof window[functionName] !== "function") {
            return;
        }
        window[functionName](getContext(route));
    },
}

export default trusteerPinpoint;
