import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import PaymentOrCollectionBase from 'app/smbPayments/models/paymentOrCollectionBase';

export default PaymentOrCollectionBase.extend({
    defaults: {},
    setAttributes() {},
    getCustomDefaultKey() {},

    convertModelAttributesToServerJSON() {
        const jsonData = [];
        this.setAttributes();
        util.each(this.attributes, (value, key) => {
            jsonData.push({
                name: key,
                value,
            });
        });
        return {
            item: jsonData,
        };
    },

    // override sync for fedwire because validation works for fedwire create
    sync(method, model, options) {
        let operation;

        if (method === 'create') {
            operation = constants.URL_ADD_ACTION;
        } else if (method === 'update') {
            operation = constants.URL_UPDATE_ACTION;
        }
        let route = services.generateUrl(this.routeBase + operation);
        if (this.validationOnly) {
            route += '/validateOnly';
        }
        http.post(route, this.convertModelAttributesToServerJSON(), (result) => {
            options.success(result);
        }, (result) => {
            options.error({
                errorCode: result.status,
                errorMessage: result.statusText,
                message: result.responseText,
            });
        });
    },
});
