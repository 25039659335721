import locale from '@glu/locale';
import dialog from '@glu/dialog';
import Grid from '@glu/grid';
import constants from 'app/administration/constants';
import Layout from '@glu/core/src/layout';
import DataEntitlements from 'app/administration/collection/user/dataEntitlements';
import ListBuilder from 'common/listBuilder/listBuilder';
import ListBuilderAssetView from 'app/administration/views/userMaintenance/admin/listBuilderAsset';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import AdminUtil from 'app/administration/common/util';
import LimitSetCollectionView from './limitSetCollectionView';
import groupViewTmpl from './groupView.hbs';

export default Layout.extend({
    template: groupViewTmpl,
    loadingTemplate: loadingPageTmpl,

    className() {
        return `assigned-accounts ${this.model.get('typeGroupId')}`;
    },

    ui: {
        $selectAll: '.select-all-perms',
        editItemsButton: '.edit-items-button',
        assignedItemsHeader: '.accounts-div .panel-heading',
        gridContainer: '.accounts-div',
        $popovers: '[data-toggle="popover"]',
        $assignAllChk: '[data-hook="assign-all-checkbox"]',
        $gridSection: '[data-hook="grid-section"]',
        $assignAllMsg: '.assign-all-msg',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPerms',
        'click @ui.editItemsButton': 'openEditItemsDialog',
        'click @ui.assignedItemsHeader': 'togglePanel',
        'click @ui.$assignAllChk': 'changeAssignAll',
    },

    initialize(opts) {
        this.mode = opts.mode;
        this.roleInfo = opts.roleInfo;
        this.permissions = this.model.get('entitlementsByType');
        this.entity = opts.entity;

        if (opts.mode === constants.MODES.VIEW) {
            this.permissions.reset(this.permissions.filter(perm => perm.isEntitled()));
        }

        this.permissions.comparator = function (model) {
            return locale.get(`type.${model.get('typeGroupLabel')}`);
        };
        this.permissions.sort();

        this.listenTo(this.permissions, 'change', this.showHideAccounts);

        this.accountsConfig = this.model.get('simpleDataEntitlements');

        if (this.accountsConfig.get('inquiryId') !== 0) {
            if (this.model.availableAccounts) {
                this.gridColumns = this.model.availableAccounts.columns;
                this.setHasLoadedRequiredData(true);
            }

            this.selectedAccounts = this.accountsConfig.get('dataEntitlements');
            this.model.availableAccounts = this.model.availableAccounts || new DataEntitlements(
                [],
                {
                    inquiryId: this.accountsConfig.get('inquiryId'),
                    keyCol: this.accountsConfig.get('keyCol'),
                    configuredDataType: this.accountsConfig.get('configuredDataType'),
                    userGroup: this.options.userGroup,
                },
            );
        } else {
            this.setHasLoadedRequiredData(true);
        }

        this.hideBankMaintainedSections();
    },

    hideBankMaintainedSections() {
        if (!AdminUtil.isBankMaintainedRolesEnabled()) {
            return;
        }

        const typeGroupId = this.model.get('typeGroupId');
        if (this.roleInfo && this.roleInfo.BANKMAINTAINED !== '1' && (typeGroupId === 'UserAdministration' || typeGroupId === 'RoleAdministration')) {
            this.$el.addClass('hidden');
        }
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);
        if (this.hasLoadedRequiredData()) {
            this.permissionsView = new LimitSetCollectionView({
                collection: this.permissions,
                mode: this.mode,
            });

            this.permissionsRegion.show(this.permissionsView);

            if (this.accountsConfig.get('inquiryId') !== 0
                && (this.options.mode !== constants.MODES.VIEW
                    || this.selectedAccounts.length > 0)) {
                this.showHideAccounts();

                this.gridView = new Grid({
                    columns: this.gridColumns,
                    collection: this.selectedAccounts,
                    emptyViewText: locale.get('uce.emptyGrid.bankAccounts'),
                });

                this.gridRegion.show(this.gridView);

                const checked = this.accountsConfig.get('assignAll');

                this.ui.$assignAllChk.prop('checked', checked);
                this.ui.$gridSection.toggle(!checked);
                this.ui.$assignAllMsg.toggle(checked);
            }
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;

        this.model.availableAccounts.fetch({
            success(accounts) {
                const toRemove = [];

                accounts.each((rec) => {
                    const selectedRec = self.selectedAccounts.matchRecord(rec, self.accountsConfig.get('keyCol'));

                    if (selectedRec) {
                        toRemove.push(rec);
                        selectedRec.mergeData(rec.attributes);
                    }
                });

                self.model.availableAccounts.remove(toRemove);

                self.gridColumns = accounts.columns;

                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    openEditItemsDialog() {
        dialog.open(new ListBuilder({
            id: `uce-${this.model.id}`,
            className: 'list-builder dgb-list-builder',
            sourceCollection: this.model.availableAccounts,
            targetCollection: this.selectedAccounts,
            labelView: ListBuilderAssetView,

            localeKeys: {
                moveSelectedItems: 'uce.moveSelectedAccounts',
                moveAllItems: 'uce.moveAllAccounts',
                removeSelectedItems: 'uce.removeSelectedAccounts',
                removeAllItems: 'uce.removeAllAccounts',
                filter: 'uce.filterAccounts',
                sourceListHeader: 'uce.nAvailableAccounts',
                targetListHeader: 'uce.nAssignedAccounts',
                title: 'BeneAddBook.ContactTypes',
                save: 'BeneAddBook.AddContactTypes',
                cancel: 'button.cancel',
            },
        }));
    },

    togglePanel() {
        if (this.gridView) {
            this.gridView.render();
        }
    },

    showHideAccounts() {
        if (this.model.isEntitled()) {
            this.ui.gridContainer.removeClass('hidden');
        } else {
            this.ui.gridContainer.addClass('hidden');
        }
    },

    selectAllPerms(evt) {
        this.permissions.selectAll(evt.target.checked);
        // have to rerender until two way binding works for nested models
        this.permissionsView.render();
        this.showHideAccounts();
    },

    onClose() {
        this.permissionsView.close();
        if (this.gridView) {
            this.gridView.close();
        }
    },

    changeAssignAll(e) {
        const { checked } = e.target;

        this.accountsConfig.set('assignAll', checked);

        this.ui.$gridSection.toggle(!checked);
        this.ui.$assignAllMsg.toggle(checked);
    },

    templateHelpers() {
        const self = this;

        return {
            cid: this.model.cid,
            readOnly: this.mode === constants.MODES.VIEW,
            hasAccounts: this.accountsConfig.get('inquiryId') !== 0,
            label: locale.get(`adminGroup.${this.model.get('typeGroupId')}`),

            assignAllCheckbox() {
                return locale.get(`role.assignAllCheckbox.${self.model.get('typeGroupId')}`);
            },

            assignAllTooltip() {
                return locale.get(`${self.entity}.assignAllTooltip.${self.model.get('typeGroupId')}`);
            },

            assignAllMsg() {
                return locale.get(`role.assignAllMsg.${self.model.get('typeGroupId')}`);
            },
        };
    },
});
