import React, { useMemo } from 'react';
import FilterPanel from '../MobileGridPanels/FilterPanel/FilterPanel';
import SortPanel from '../MobileGridPanels/SortPanel/SortPanel';
import ColumnPanel from '../MobileGridPanels/ColumnPanel/ColumnPanel';

const useMobileGridPanels = ({
  sortColumns,
  filterColumns,
  columnsWithoutActions,
  filterState,
  sortState,
  getFilterComponent,
  onSaveFilter,
  onSaveSort,
  onSaveColumn,
  drawer,
  closeDrawer
}) => {
  const drawerContent = useMemo(() => {
    if (drawer === 'filter') {
      return (
        <FilterPanel
          columns={filterColumns || columnsWithoutActions}
          closeDrawer={closeDrawer}
          getFilterComponent={getFilterComponent}
          filters={filterState}
          onSave={onSaveFilter}
        />
      );
    }
    if (drawer === 'sort') {
      return (
        <SortPanel
          columns={sortColumns}
          closeDrawer={closeDrawer}
          onSave={onSaveSort}
          sortFieldId={sortState.field}
          sortDirection={sortState.direction}
        />
      );
    }
    return (
      <ColumnPanel
        columns={columnsWithoutActions}
        closeDrawer={closeDrawer}
        onSave={onSaveColumn}
      />
    );
  }, [
    onSaveColumn,
    drawer,
    columnsWithoutActions,
    closeDrawer,
    sortColumns,
    getFilterComponent,
    filterState,
    onSaveSort,
    sortState,
    onSaveFilter,
    filterColumns
  ]);

  return drawerContent;
};

export default useMobileGridPanels;
