/**
 * Override the nested alertView.js which is called by glu/alert
 * Prevents jQuery Animations on Alerts while an overlay is active.
 */
import Layout from '@glu/core/src/layout';
import util from 'underscore';
import AlertLayout from 'no-override!@glu/alerts/src/alertView';

export default AlertLayout.extend({

    initialize(options) {
        util.defaults(
            this.options,
            options,
            {
                animate: false,
                message: '',
                title: '',

                // Type options are success, info, warning, danger
                type: 'default',

                duration: false,
                details: false,
                icon: false,
                canDismiss: true,
                collapsed: false,
            },
        );
        if (util.isString(this.options.canDismiss)) {
            this.options.canDismiss = this.options.canDismiss === 'true';
        }
    },

    onRender() {
        if (this.options.details && this.options.collapsed) {
            this.toggleDetails();
        }
        AlertLayout.prototype.onRender.call(this);
    },

    /**
     * Check for open modals. Mixing jQuery animation and CSS opacity causes
     * display issues.
     * @return {boolean}
     */
    shouldAnimate() {
        return this.animate && !window.document.body.classList.contains('modal-open');
    },

    close() {
        if (this.shouldAnimate()) {
            // fade out
            this.$el.fadeOut('slow', Layout.prototype.close.bind(this));
        } else {
            Layout.prototype.close.call(this);
        }
    },

    toggleDetails() {
        if (this.shouldAnimate()) {
            this.ui.$alertDetails.slideToggle(this.handleDetailsButtons.bind(this));
        } else {
            this.ui.$alertDetails.toggle();
            this.handleDetailsButtons();
        }
    },
});
