import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import template from './adjustAmountsForm.hbs';

export default ItemView.extend({
    template,

    events: {
        'click [data-hook="adjust-form-toggle"]': 'toggleShowForm',
        'click [data-hook="adjust-amounts-apply"]': 'triggerBulkUpdate',
        'click [data-hook="adjust-amounts-cancel"]': 'closeParentDropdown',
        'change [data-hook="bulk-update-type"]': 'clearAmount',
        'keyup [data-hook="getBulkUpdateAmount"]': 'validateAmount',
        'keydown [data-hook="getBulkUpdateAmount"]': 'applyAmountsAdj',
    },

    ui: {
        $adjustForm: '[data-hook="getAdjustForm"]',
        $bulkUpdateAmount: '[data-hook="getBulkUpdateAmount"]',
        $bulkUpdateType: '[data-hook="getBulkUpdateType"]',
        $bulkUpdateAffectAll: '[data-hook="getBulkUpdateAffectAll"]',
        $bulkUpdateAffectSelected: '[data-hook="getBulkUpdateAffectSelected"]',
        $bulkUpdateAffectSelectedLabel: '[class="getBulkUpdateAffectSelectedLabel"]',
        $errmsg: '[data-validate="AdjustAmounts-amountInput"]',
    },

    initialize(options) {
        /*
         * Passed in options that will interact with the effected Editable Grid
         */
        this.parentView = options.parentView;
        this.parentDropdown = options.parentView.amountOptionsDropdown;
        this.bulkUpdateCallback = options.bulkUpdateCallback;
        this.closeParentDropdown = options.closeParentDropdown;
        this.getSelectedRows = options.getSelectedRows;
        this.eventProxy = options.eventProxy;
        this.listenTo(this.eventProxy, 'dropdownOpened', this.updateSelectedRadioState);
    },

    toggleShowForm() {
        this.ui.$adjustForm.toggleClass('hidden');
    },

    clearAmount() {
        this.ui.$bulkUpdateAmount.val(0);
    },

    updateSelectedRadioState() {
        const noSelected = this.getSelectedRows().length === 0;
        this.ui.$bulkUpdateAffectSelected.prop('disabled', noSelected);
        if (noSelected) {
            this.ui.$bulkUpdateAffectSelectedLabel.addClass('BulkUpdate-selectedLabel--inactive');
        } else {
            this.ui.$bulkUpdateAffectSelectedLabel.removeClass('BulkUpdate-selectedLabel--inactive');
        }

        // Switch the selected button if needed
        if (noSelected && this.ui.$bulkUpdateAffectSelected.prop('checked')) {
            this.ui.$bulkUpdateAffectAll.prop('checked', true);
        }
    },

    validateAmount(event) {
        const err = locale.get('ACH.bulkupdate.invalidNumber');
        if (!event.target.validity.valid) {
            this.ui.$errmsg.text(err);
            this.ui.$bulkUpdateAmount.addClass('has-error');
        } else {
            this.ui.$errmsg.empty();
            this.ui.$bulkUpdateAmount.removeClass('has-error');
        }
    },

    applyAmountsAdj(event) {
        if (event.which === 13) {
            event.preventDefault();
            this.triggerBulkUpdate();
        }
    },

    triggerBulkUpdate() {
        const typeOfChange = this.ui.$bulkUpdateType.val();
        let amountOfChange = parseFloat(this.ui.$bulkUpdateAmount.val());
        const affectSelectedRows = this.ui.$bulkUpdateAffectSelected.is(':checked');
        let rows = [];
        let actionType = '';

        // Already a number, so Number.isNaN is ok.
        if (Number.isNaN(amountOfChange) || !(amountOfChange > 0)) {
            return undefined;
        }

        // determine the type of change and adjust the amount for percentages.
        switch (typeOfChange) {
        case 'specificAmount':
            actionType = 'SETAMOUNT';
            break;
        case 'decreaseAmountBy':
            actionType = 'ADJBYAMOUNT';
            amountOfChange *= -1;
            break;
        case 'increaseAmountBy':
            actionType = 'ADJBYAMOUNT';
            break;
        case 'increasePercent':
            actionType = 'ADJBYPERCENT';
            amountOfChange /= 100;
            break;
        case 'decreasePercent':
            actionType = 'ADJBYPERCENT';
            amountOfChange /= -100;
            break;
        default:
        }

        // if only affecting certain rows, account for them and store here
        if (affectSelectedRows) {
            rows = this.getSelectedRows();
        }

        // clear the amount field
        this.clearAmount();

        // send in parameters to trigger the bulkupdate request passed in
        return this.bulkUpdateCallback({
            rows,
            actionType,
            value: amountOfChange,
        });
    },

    templateHelpers() {
        return {
            gridHasSelection: this.getSelectedRows().length,
        };
    },
});
