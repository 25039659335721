import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import _ from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import PaymentDecisionTop from './paymentDecisionTop';
import NewPaymentTypes from './newPayments/newPaymentTypes';
import NewPaymentOptions from './newPayments/newPaymentOptions';
import paymentDecisionTmpl from './paymentDecision.hbs';

const sbPayments = Layout.extend({
    template: paymentDecisionTmpl,
    className: 'page page-grid',
    binding: true,

    ui: {
        $paymentDecisionBtm: '.payment-decision-btm',
        $pdBottomLeftContent: '.pd-bottom-left-content',
        $pdBottomRightContent: '.pd-bottom-right-content',
        $pdBottomButton: '.pd-bottom-button',
    },

    events: {
        'click #btnMakeNewPayment': 'showSmallBusinessPaymentTypes',
        'click #btnExistingRecipientQEG, #btnUploadCopyPayment': 'showServiceNotAvailableDialog',
        'click .payment-type': 'showPaymentTypeOptions',
        'click #showPaymentService': 'showPaymentService',
    },

    getSelectedPaymentOptionId() {
        const option = this.$el.find('.payment-option.active');
        return option[0].id;
    },

    showPaymentService(e) {
        const target = this.$el.find(e.currentTarget);
        const mdata = target.attr('smbpaymenu');
        const optionId = this.getSelectedPaymentOptionId();

        if (mdata === 'SMBTRANSFER') {
            this.navigateTo('payments/newTransferGrid');
        } else if (mdata === 'SMBPAYIND') {
            this.navigateTo(`payments/newPayIndGrid/${optionId}`);
        } else if (mdata === 'SMBPAYEMP' && optionId === 'PAY_EMPWAGES') {
            this.navigateTo(`payments/newPayEmpGrid/${optionId}`);
        } else if (mdata === 'SMBPAYBUSINESS') {
            this.navigateTo(`payments/newPayBusinessGrid/${optionId}`);
        } else if (mdata === 'SMBLOANADVANCE') {
            this.navigateTo('payments/reqLoanAdvanceGrid');
        } else if (mdata === 'SMBFUNDSIND') {
            this.navigateTo('payments/collectFromIndividual');
        } else if (mdata === 'SMBFUNDSBUSINESS') {
            this.navigateTo('payments/collectFromBusiness');
        } else {
            this.showServiceNotAvailableDialog();
        }
    },

    showSmallBusinessPaymentTypes() {
        if ((this.pdBottomLeftContent.currentView instanceof NewPaymentTypes) === false) {
            const data = new Collection(this.options.collection.models[0].get('smbPayMenu'));
            data.comparator = 'displayOrder';
            data.sort();

            // Add active class to .payment-decision-btm panel
            this.ui.$paymentDecisionBtm.addClass('active');

            // Show the payment types
            const paymentTypes = new NewPaymentTypes({
                collection: data,
            });
            this.pdBottomLeftContent.show(paymentTypes);
        }
    },

    showPaymentTypeOptions(e) {
        // Hide all the img-option class and show the selected one
        $('.btn-payment-type').removeClass('active').filter(`#text_${e.currentTarget.id}`).addClass('active');

        if (e.currentTarget.id !== undefined || e.currentTarget !== null) {
            $('#showPaymentService').attr('smbpaymenu', e.currentTarget.id);
        }

        // Get the options by id and then display
        const payMenu = this.options.collection.models[0].get('smbPayMenu');
        const optsArray = _.where(
            payMenu,
            {
                menuCategory: e.currentTarget.id,
            },
        );

        const data = new Collection(optsArray[0].smbMenuGroupItems);
        const paymentOptions = new NewPaymentOptions({
            collection: data,
        });
        this.pdBottomRightContent.show(paymentOptions);

        // Show the continue button
        this.ui.$pdBottomButton.show();

        /*
         * Check the default - if there is only one option add the active class, else
         * select the first radio button as default
         */
        if (this.ui.$pdBottomRightContent.find('.payment-option').length === 1) {
            this.ui.$pdBottomRightContent.find('.payment-option').first().addClass('active');
        } else {
            this.ui.$pdBottomRightContent.find('.payment-option').first().trigger('click');
        }
    },

    showServiceNotAvailableDialog() {
        Dialog.alert(locale.get('smb.service.unavailable.error'));
    },

    onShow() {
        this.pdTopContent.show(new PaymentDecisionTop());
    },
});

export default sbPayments;
