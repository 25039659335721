import locale from '@glu/locale';
import util from '@glu/core/src/util';
import API from 'app/smb/api';
import Constants from 'app/smbPayments/constants';
import CombinedPayment from 'app/smbPayments/views/modals/combinedPayment';
import CollectionView from 'app/smbPayments/views/modals/collection';
import ConfirmCollectionView from 'app/smbPayments/views/modals/confirmCollection';
import ConsumerOrCorporateModel from 'app/smbPayments/models/consumerOrCorporateCollection';
import CorporateChildModel from 'app/smbPayments/models/corporateCollectionChild';
import CorporateChildResetModel from 'app/smbPayments/models/corporateCollectionChildReset';
import ConsumerChildModel from 'app/smbPayments/models/consumerCollectionChild';
import ConsumerChildResetModel from 'app/smbPayments/models/consumerCollectionChildReset';

export default CombinedPayment.extend({
    templateHelpers(...args) {
        const base = CombinedPayment.prototype.templateHelpers.apply(this, args);

        return util.extend(
            {},
            base,
            {
                payeeText: locale.get('SMBPAY.PayerName'),
                accountText: locale.get('SMBPAY.PayerAccount'),
            },
        );
    },

    isPayment() {
        return false;
    },

    getEntryView() {
        return new CollectionView({
            model: this.model,
            paymentTypeCollection: this.paymentTypeCollection,
        });
    },

    getConfirmView() {
        return new ConfirmCollectionView({
            model: this.model,
        });
    },

    getPayButton() {
        return {
            text: locale.get(this.isExistingTransaction() && this.isCompletedTransaction() ? 'smbPayments.collection.update' : 'smbPayments.collection.collect'),
            className: 'btn btn-success',
            callback: util.bind(this.pay, this),
        };
    },

    getCreateTitle() {
        return locale.get(this.isExistingTransaction() ? 'sbPayments.editCollection' : 'sbPayments.newCollection');
    },

    getConfirmTitle() {
        return locale.get(this.isSelect() ? 'sbPayments.viewCollection' : 'sbPayments.confirmCollectionDetails');
    },

    getTotalText() {
        return locale.get('sbPayments.collectionTotal');
    },

    getBeneListPromise() {
        return API.payeeList.get(true);
    },

    saveOrUpdate(viewModel, options) {
        const paymentType = viewModel.beneAccount.get('PAYMENTTYPE');
        if (paymentType === Constants.CORPORATECOLLECTION) {
            this.corporateCollectionSaveOrUpdate(viewModel, options);
        } else if (paymentType === Constants.CONSUMERCOLLECTION) {
            this.consumerCollectionSaveOrUpdate(viewModel, options);
        }
    },

    corporateCollectionSaveOrUpdate(viewModel, options) {
        const routeBase = viewModel.beneAccount ? viewModel.beneAccount.get('RESTSERVICENAME') : '/batch/CorporateCollections';
        let childModel;

        // skip validation here to prevent child updates
        if (viewModel.validationOnly) {
            options.success();
        } else if (viewModel.isExistingPayment()) {
            childModel = new CorporateChildModel(
                {},
                {
                    attributes: viewModel.attributes,
                    beneAccount: viewModel.beneAccount,
                    routeBase,
                },
            );
            childModel.set('BATCHSEQNUM', viewModel.get('BATCHSEQNUM'));
            childModel.save(
                {},
                {
                    success() {
                        childModel.fetch({
                            success() {
                                const corporateModel = new ConsumerOrCorporateModel({
                                    id: viewModel.id,
                                }, {
                                    attributes: viewModel.attributes,
                                    account: viewModel.account,
                                    validationOnly: viewModel.validationOnly,
                                    routeBase,
                                });
                                corporateModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        });
                    },

                    error: options.error,
                },
            );
        } else {
            const resetChildModel = new CorporateChildResetModel({
                subType: viewModel.beneAccount.get('PAYMENTCLEARINGSYSTEM'),
            }, {
                routeBase,
            });
            resetChildModel.save({}, {
                success(resultModel, result) {
                    childModel = new CorporateChildModel(
                        {},
                        {
                            attributes: viewModel.attributes,
                            beneAccount: viewModel.beneAccount,
                            routeBase,
                        },
                    );
                    childModel.set('BATCHSEQNUM', result);
                    viewModel.set('BATCHSEQNUM', result);

                    childModel.save(
                        {},
                        {
                            success() {
                                const corporateModel = new ConsumerOrCorporateModel(
                                    {},
                                    {
                                        attributes: viewModel.attributes,
                                        account: viewModel.account,
                                        validationOnly: viewModel.validationOnly,
                                        routeBase,
                                    },
                                );
                                corporateModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        },
                    );
                },

                error: options.error,
            });
        }
    },

    consumerCollectionSaveOrUpdate(viewModel, options) {
        const routeBase = viewModel.beneAccount ? viewModel.beneAccount.get('RESTSERVICENAME') : '/batch/ConsumerCollections';
        let childModel;

        // skip validation here to prevent child updates
        if (viewModel.validationOnly) {
            options.success();
        } else if (viewModel.isExistingPayment()) {
            childModel = new ConsumerChildModel(
                {},
                {
                    attributes: viewModel.attributes,
                    beneAccount: viewModel.beneAccount,
                    routeBase,
                },
            );
            childModel.set('BATCHSEQNUM', viewModel.get('BATCHSEQNUM'));
            childModel.save(
                {},
                {
                    success() {
                        childModel.fetch({
                            success() {
                                const consumerModel = new ConsumerOrCorporateModel({
                                    id: viewModel.id,
                                }, {
                                    attributes: viewModel.attributes,
                                    account: viewModel.account,
                                    validationOnly: viewModel.validationOnly,
                                    routeBase,
                                });
                                consumerModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        });
                    },

                    error: options.error,
                },
            );
        } else {
            const resetChildModel = new ConsumerChildResetModel({
                subType: viewModel.beneAccount.get('PAYMENTCLEARINGSYSTEM'),
            }, {
                routeBase,
            });
            resetChildModel.save({}, {
                success(resultModel, result) {
                    childModel = new ConsumerChildModel(
                        {},
                        {
                            attributes: viewModel.attributes,
                            beneAccount: viewModel.beneAccount,
                            routeBase,
                        },
                    );

                    childModel.set('BATCHSEQNUM', result);
                    viewModel.set('BATCHSEQNUM', result);

                    childModel.save(
                        {},
                        {
                            success() {
                                const consumerModel = new ConsumerOrCorporateModel(
                                    {},
                                    {
                                        attributes: viewModel.attributes,
                                        account: viewModel.account,
                                        validationOnly: viewModel.validationOnly,
                                        routeBase,
                                    },
                                );
                                consumerModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        },
                    );
                },
                error: options.error,
            });
        }
    },
});
