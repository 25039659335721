import LayoutView from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import Constants from 'common/dynamicPages/api/constants';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import template from './maskedInputSearch.hbs';

const MaskedInputSearch = LayoutView.extend({
    template,

    ui: {
        $submitBtn: 'button[type=submit]',
        $clearBtn: '.btn-clear-filter',
    },

    events: {
        submit: 'handleSubmit',
        'click @ui.$clearBtn': 'handleClearFilter',
    },

    onRender() {
        const MaskedInputView = createMaskedInputView({
            initialValue: '',
            name: this.model.get('field'),
            dataBind: true,
            fieldLabel: '',
            maskExcludeValues: Constants.MASKEDEXCLUDEVALUES,
        });
        if (this.maskedInputRegion) {
            this.maskedInputRegion.show(new MaskedInputView());
        }
    },

    handleSubmit(evt) {
        evt.preventDefault();

        const field = this.model.get('field');
        const value = this.model.get(field);
        const filter = this.buildFilter(field, value);
        try {
            this.trigger('filter', filter);
        } catch (ex) {
            return false;
        }
        return false;
    },

    filter(field, value, model) {
        return model.get(field) === value;
    },

    handleClearFilter() {
        this.trigger('filter:clear', this);
    },

    buildFilter(field, value) {
        const label = (value === 'MULTI') ? value : maskValue(value, getMaskingProperties());
        return {
            title: this.model.get('title'),
            value,
            label,
            type: 'masked',
            field,
            filter: util.bind(this.filter, this, field, value, this.model),
        };
    },
});

export default MaskedInputSearch;
