import ImportModel from 'common/dynamicPages/models/import';
import alert from '@glu/alerts';
import f1 from 'system/utilities/f1';
import $ from 'jquery';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import domUtil from 'common/util/domUtil';
import template from './fileImport.hbs';
import EntryView from './entry';

let form = null;
let selectedRow = null;

const excludeFieldsList = ['IMPORTTYPE', 'CREATEFROM', 'CMB_TEMPLATE_CODE', 'OPTIONIMPORTMETHOD', 'OPTIONIMPORTMETHODNFI', 'error'];

const appendNonRunTimeParameter = (paramName, paramValue) => {
    let fldValue = $('#NonRunTimeParams').val();
    if (paramName && paramValue) {
        /**
         * if val is empty then simply set the paramName and value otherwise
         * add a | separator and concatenate with the old value
         */
        fldValue = (fldValue.length === 0 ? `${paramName}=${paramValue}|` : `${fldValue + paramName}=${paramValue}|`);
        $('#NonRunTimeParams').val(fldValue);
    }
};

const hideRadio = (field) => {
    field.shouldBeHidden();
    if (field.isNotEmpty()) {
        const { name } = field.$el[0];
        $(`label[for=${name}]`).parents('.field-container').hide();
    }
};

const showRadio = (field) => {
    field.shouldBeVisible();
    if (field.isNotEmpty()) {
        const { name } = field.$el[0];
        $(`label[for=${name}]`).parents('.field-container').show();
    }
};

const hidePaymentInformationSection = () => {
    form.field('CREATEFROM').shouldBeHidden();
    form.field('CMB_TEMPLATE_CODE').shouldBeHidden();
    form.field('TEMPLATECODE').shouldBeHidden();
    form.field('TEMPLATEDESCRIPTION').shouldBeHidden();
    form.field('COMPIDNAME').shouldBeHidden();
    form.field('COMPIDNAME_EFT').shouldBeHidden();
    form.field('ORIGCOMPNAME').shouldBeHidden();
    form.field('ORIGCOMPID').shouldBeHidden();
    form.field('OFFSETACCOUNTNUM').shouldBeHidden();
    form.field('OFFSETACCOUNTNUM_EFTCC').shouldBeHidden();
    form.field('OFFSETACCOUNTNUM_EFTCD').shouldBeHidden();
    form.field('OFFSETACCOUNTNUM_EFTCCD').shouldBeHidden();
    form.field('EFFECTIVEDATE').shouldBeHidden();
    form.field('SAMEDAYACH').shouldBeHidden();
    form.field('UE_ENTRYDESC').shouldBeHidden();
    form.field('UE_COMPDISCDATA').shouldBeHidden();
    form.field('COMPDESCDATE').shouldBeHidden();
    form.field('BATCHCOMMENT').shouldBeHidden();
    form.field('IMPORTTYPE').shouldBeHidden();
    form.field('SUBTYPE').shouldBeHidden();
    form.field('ID').shouldBeHidden();
    form.field('SAMEDAYACH').shouldBeHidden();
    hideRadio(form.field('CREATEFROM'));
    form.field('CREATEFROM').$el.closest('.section').hide();
};

const showSameDay = (field) => {
    field.$el.closest('.section').show();
    $('.PAY_IMPORT_CLIENTDEFINED_FIELDS').show();
    field.shouldBeVisible();
    field.$el.attr('checked', false);
};

const clearFields = (model) => {
    if (model) {
        form.field('SAMEDAYACH').$el.prop('checked', false);
        model.set({
            UE_ENTRYDESC: '',
            UE_COMPDISCDATA: '',
            COMPDESCDATE: '',
            BATCHCOMMENT: '',
            EFFECTIVEDATE: '',
            SAMEDAYACH: '0',
        });
    }
};

const getOwnerImportGridSelect = (model) => {
    const productCode = selectedRow.get('PRODUCTCODE');
    const importFunctionCode = selectedRow.get('IMPORTFUNCTIONCODE');
    const paymentTypeCode = selectedRow.get('PAYMENTTYPECODE');
    let subTypeCode = selectedRow.get('SUBTYPE');
    const allowSameDayAch = selectedRow.get('SAMEDAYACH') === '1';
    const optionImportMethodNfi = form.field('OPTIONIMPORTMETHODNFI');
    const optionImportMethodEft = form.field('OPTIONIMPORTMETHODEFT');
    const optionImportMethod = form.field('OPTIONIMPORTMETHOD');
    const functionCode = form.field('FUNCTIONCODE');
    const ueEntryDesc = form.field('UE_ENTRYDESC');
    const ueCompDiscData = form.field('UE_COMPDISCDATA');
    const $importAsCombo = $('#FUNCTIONCODE');
    const sameDayAch = form.field('SAMEDAYACH');
    const owner = selectedRow.get('OWNER');
    const ueEntryDescTex = selectedRow.get('UE_ENTRYDESC') || '';
    /**
     * Cash Concentration is CASH CON
     * Cash Disbursement is  CASH DIS
     * Cash Concentration/Disbursement is CASHCD
     * therefore, the code:paymentTypeDesc.substring(0,8) is not going to get CASHCD
     * we need to set it manually.
     */

    if (productCode === 'USACH') {
        model.set('UE_ENTRYDESC', ueEntryDescTex.substring(0, 10).toUpperCase());
        ueCompDiscData.shouldBeVisible();

        if (subTypeCode === '') {
            subTypeCode = 'NACHA';
        }
    }

    hideRadio(optionImportMethodNfi);
    hideRadio(optionImportMethodEft);
    functionCode.shouldBeVisible();
    $importAsCombo.comboBox('readonly', false);
    $('label[for=FUNCTIONCODE]').show();

    if (owner === locale.get('Client') || owner === locale.get('Bank')) {
        model.set('IMPORTTYPE', paymentTypeCode);
        model.set('SUBTYPE', subTypeCode);
        $('[name="IMPORTTYPE"]').trigger('change');

        if (productCode === 'RTGS') {
            $importAsCombo.one('change', () => {
                // set the type (payment or template) in the drop down, then make it readonly
                $importAsCombo.comboBox('val', importFunctionCode);
                $importAsCombo.comboBox('readonly', true);
            });

            if (importFunctionCode === 'INST' && paymentTypeCode !== 'BPAY') {
                showRadio(optionImportMethod);
            } else {
                hideRadio(optionImportMethod);
            }
            model.set('FUNCTIONCODE', importFunctionCode);
            hidePaymentInformationSection();
            if (paymentTypeCode === 'TRANSFER' && model.context && model.context.workspaceId === 'TRANSFERS') {
                $importAsCombo.comboBox('val', importFunctionCode);
                $importAsCombo.comboBox({
                    placeholder: locale.get(`Confirm.Multi.${importFunctionCode}`),
                });
                $importAsCombo.comboBox('readonly', true);
                hideRadio(optionImportMethod);
            }
        } else if (productCode === 'USACH') {
            $importAsCombo.comboBox('readonly', false);
            functionCode.shouldBeVisible();
            hideRadio(optionImportMethod);
        }

        if (paymentTypeCode === 'BDACHTP' || paymentTypeCode === 'BDACHCSP') {
            ueEntryDesc.shouldBeHidden();
        } else {
            ueEntryDesc.shouldBeVisible();
        }
    } else {
        $importAsCombo.comboBox('val', '');
        $importAsCombo.comboBox({
            placeholder: locale.get('Confirm.Multi.INST'),
        });
        $importAsCombo.comboBox('readonly', true);
        hidePaymentInformationSection();
        hideRadio(optionImportMethod);

        $('.PAY_IMPORT_CLIENTDEFINED_FIELDS').hide();

        $('label[for=OPTIONIMPORTMETHOD]').show();
        $('#OPTIONIMPORTMETHOD').show();

        if (paymentTypeCode === 'TRANSFER') {
            $('label[for=OPTIONIMPORTMETHOD]').hide();
            $('#OPTIONIMPORTMETHOD').hide();
        } else if (paymentTypeCode === 'BDACHNFI') {
            hideRadio(optionImportMethod);
            showRadio(optionImportMethodNfi);
            if (allowSameDayAch) {
                showSameDay(sameDayAch);
            }
            $('label[for=OPTIONIMPORTMETHODNFI]').show();
            $('#OPTIONIMPORTMETHODNFI').show();
            $('label[for=OPTIONIMPORTMETHOD]').hide();
            $('#OPTIONIMPORTMETHOD').hide();
        } else if (paymentTypeCode === 'BIEFTCCD') {
            showRadio(optionImportMethodEft);

            $('label[for=OPTIONIMPORTMETHODEFT]').show();
            $('#OPTIONIMPORTMETHODEFT').show();
            $('label[for=OPTIONIMPORTMETHOD]').hide();
            $('#OPTIONIMPORTMETHOD').hide();
        } else {
            showRadio(optionImportMethod);
        }
    }
};

const processSelectedRow = (model) => {
    /**
     * NH-164621
     * Resetting model attributes and removing their validators as Import type changed
     */
    [
        'SAMEDAYACH',
        'EFFECTIVEDATE',
        'IMPORTTYPE',
        'SUBTYPE',
        'UE_ENTRYDESC',
        'PAYMENTTYPECODE',
        'TYPECODE',
        'ALLOWSAMEDAYACH',
        'PRODUCTCODE',
        'CREATEFROM',
        'FUNCTIONCODE',
        'COMPIDNAME',
    ].forEach((fieldName) => {
        model.set(fieldName, '', { silent: true });
        model.removeValidator(fieldName);
    });
    const productCode = selectedRow.get('PRODUCTCODE');
    const paymentTypeCode = selectedRow.get('PAYMENTTYPECODE');
    let paymentMethod = selectedRow.get('PAYMENTMETHOD');
    const subTypeCode = selectedRow.get('SUBTYPE');
    const importFunctionCode = selectedRow.get('IMPORTFUNCTIONCODE');
    const fileEntitle = selectedRow.get('FILEPAYMENTTYPE');
    const confidentialSetting = selectedRow.get('CONFIDENTIALSETTING');
    const passThruConfidentialSetting = selectedRow.get('PASSTHRUCONFIDENTIALSETTING');
    const eftPassThruConfidentialSetting = selectedRow.get('EFTPASSTHRUCONFIDENTIALSETTING');
    const paymentImportMethod = selectedRow.get('PAYMENTIMPORTMETHOD');
    const importFileType = selectedRow.get('IMPORTFILETYPE');
    const allowSameDayAch = selectedRow.get('SAMEDAYACH') === '1';
    const optionImportMethod = model.get('OPTIONIMPORTMETHOD');
    const optionImportMethodNFI = model.get('OPTIONIMPORTMETHODNFI');
    const optionImportMethodEFT = model.get('OPTIONIMPORTMETHODEFT');
    let mapID = selectedRow.get('ID');
    const sameDayAch = form.field('SAMEDAYACH');
    const confidentialImports = form.field('CONFIDENTIALIMPORTS');
    const isPassThru = selectedRow.get('TYPE') === 'PASSTHRU';
    const isEFTPassThru = selectedRow.get('TYPE') === 'BEFTPASS';
    let ugImport = 0;
    let fileOnly = false;
    const mode = $('#_mode').val();

    hidePaymentInformationSection();

    // set key model values
    model.set({
        PAYMENTTYPECODE: paymentTypeCode,
        ALLOWSAMEDAYACH: allowSameDayAch,
        PRODUCTCODE: productCode,
        TYPECODE: paymentTypeCode,
    });

    // by selecting a file format, most all other fields get reset, so reset the alert as well
    form.formView.alertRegion.close();

    clearFields(model);
    getOwnerImportGridSelect(model);

    paymentMethod = (paymentMethod ? paymentMethod.toUpperCase() : paymentMethod);

    $('#NonRunTimeParams').val('');

    $('#_productCode').val(productCode);
    $('#_typeCode').val(paymentTypeCode);
    $('#_subTypeCode').val(subTypeCode);
    $('#importType').val(paymentTypeCode);
    $('#paymentMethod').val(paymentMethod);
    $('#confidentialSetting').val(confidentialSetting);
    $('#passThruConfidentialSetting').val(passThruConfidentialSetting);
    $('#eftPassThruConfidentialSetting').val(eftPassThruConfidentialSetting);

    if (productCode === 'RTGS') {
        $('#_functionCode').val('INST');
    } else if (productCode === 'USACH') {
        $('#_functionCode').val('BATCH');
    }

    if (paymentTypeCode === 'BDACHNFI' && mode !== 'INSERT') {
        $('#_functionCode').val('BATCH');
    // Detail import will have a value > 0
    } else if (mapID > 0 && productCode === 'USACH' && subTypeCode !== 'UKFP' && subTypeCode !== 'UKBACS') {
        $('#_functionCode').val('BATCH');
        if (paymentMethod === 'ACH') {
            appendNonRunTimeParameter('GENMAPPERCODE', mapID);
        }
    } else {
        // 0 - Payment Workflow Only, 1 - File Workflow Only, 2 - Any Workflow
        ugImport += paymentImportMethod;
        if (productCode !== 'USACH') {
            $('#_functionCode').val('INST');
        }

        if (mapID > 0 && productCode === 'RTGS') {
            $('#_functionCode').val(importFunctionCode);
            appendNonRunTimeParameter('GENMAPPERCODE', mapID);
        } else if (productCode === 'RTP') {
            if (mapID > 0) {
                appendNonRunTimeParameter('GENMAPPERCODE', mapID);
            }
            $('#_functionCode').val(importFunctionCode);
            $('#_typeCode').val('FILE');
            hideRadio(form.field('OPTIONIMPORTMETHOD'));
            form.field('FUNCTIONCODE').$el.prop('disabled', true);
            model.set('FUNCTIONCODE', 'INST');
        } else if (mapID > 0 && (subTypeCode === 'UKFP' || subTypeCode === 'UKBACS')) {
            $('#_functionCode').val('BATCH');
            if (paymentMethod === 'ACH') {
                appendNonRunTimeParameter('GENMAPPERCODE', mapID);
            }
        } else if ((paymentTypeCode === 'CIMIMPRT' || paymentTypeCode === 'STCAIMPT') && paymentMethod === locale.get('CM.Import.PayMethod').toUpperCase()) {
            mapID = selectedRow.get('GENERICMAPPERCODE');
            appendNonRunTimeParameter('GENMAPPERCODE', mapID);
        }

        if (paymentTypeCode === 'BDACHFP' && paymentTypeCode === 'BDACHCD') {
            $('#_productCode').val('USACH');
            $('#_functionCode').val('BATCH');
        }

        /**
         * if the user is entitle for FILE payment type only and no other payment type,
         * do not display the payment work flow radio button and default to File
         * Work flow.
         */
        if ($('#_typeCode').val() === 'FILE') {
            fileOnly = true;
            if (paymentTypeCode !== 'FILE' && (importFileType === 'BAC' || importFileType === 'CSV')) {
                fileOnly = false;
            }
        }
        if ((ugImport > 0) && (fileEntitle > 0)) {
            if (fileOnly || (ugImport === 1)) {
                $('#filePaymentType').val('1');
                $('#filePayment').val('1');
                $('#_Param_Value_1').val('1');
            } else {
                /**
                 * The payment Import Type radio button should be checked if not checked
                 * at this point
                 */
                $('#filePaymentType').val('0');
                $('#filePayment').val('0');
            }
            if (optionImportMethod === 'File' || optionImportMethodNFI === 'PassThrough' || optionImportMethodEFT === 'PassThrough') {
                $('#filePayment').val(1);
                $('#filePaymentType').val(1);
            } else {
                $('#filePayment').val(0);
                $('#filePaymentType').val(0);
            }
        } else {
            $('#filePaymentType').val('0');
        }

        if (isPassThru && $('#OPTIONIMPORTMETHODNFI').is(':visible') === false) {
            $('#filePayment').val(1);
            $('#filePaymentType').val(1);
        }
        if (isEFTPassThru && $('#OPTIONIMPORTMETHODEFT').is(':visible') === false) {
            $('#filePayment').val(1);
            $('#filePaymentType').val(1);
        }
    }

    if (confidentialSetting === '2') {
        showRadio(confidentialImports);
        $('#fileConfidential').val('0');
        model.set('CONFIDENTIALIMPORTS', '0');
        confidentialImports.setValue(['0']);
    } else {
        hideRadio(confidentialImports);
        $('#fileConfidential').val(confidentialSetting);
        model.set('CONFIDENTIALIMPORTS', confidentialSetting);
        confidentialImports.setValue(['1']);
    }

    const localeKey = paymentTypeCode === 'BDACHNFI' ? 'ACH.processSameDay' : 'ACH.MAKESAMEDAY';
    sameDayAch.$el.siblings('label').text(locale.get(localeKey));
};

export default EntryView.extend({
    template,
    loadingTemplate,
    className: 'full-page',

    ui: {
        $cancelButton: 'div.widget-action-btn-group button[name="cancel"]',
        $fileImportButton: 'div.widget-action-btn-group button[name="fileimport"]',
        $refreshButton: 'div.widget-action-btn-group button[name="fileimportrefresh"]',
        $fileImport: '#FILESELECTION',
        $progressBar: '.progress-bar',
    },

    initialize(options) {
        form = f1.getForm(this);

        EntryView.prototype.initialize.call(this, options);

        this.buttonList = [];
        this.buttonList.push({
            action: 'FILEIMPORT',
        });
        this.buttonList.push({
            action: 'FILEIMPORTREFRESH',
        });
        this.buttonList.push({
            action: 'CANCEL',
        });

        this.import = new ImportModel();
        this.listenTo(this.import, 'change:percentComplete', this.showProgressBar);
        this.listenTo(this.appBus, 'grid:selectRow', (view) => {
            // the view.model is the selected row
            selectedRow = view.model;
            processSelectedRow(this.pageView.model);
        });
    },

    fileimport() {
        const self = this;
        const { files } = this.ui.$fileImport[0];
        const [file] = files;

        this.toggleButtons(false);

        if (this.prepareToImport()) {
            this.pageView.pageForm.applyPolicies(false);
        } else {
            this.toggleButtons(true);
            return;
        }

        if (files && file) {
            const reader = new FileReader();

            reader.onload = (readerEvt) => {
                // show our progress bar
                self.ui.$progressBar.parent().removeClass('hide');
                const binaryString = readerEvt.target.result;
                if (!util.isNullOrUndefined($('#_Param_Name_0'))) {
                    $('#_Param_Value_0').val(form.field('TESTMODE').$el.is(':checked'));
                }
                self.import.set('fileName', file.name);
                self.import.set('importType', $('#ImportMapHostAp').val());
                self.import.set('eventName', $('#EventName').val());
                self.import.set('importJCode', $('#ImportJCODE').val());
                try {
                    self.import.set('fileContent', binaryString);
                } catch (e) {
                    self.actionErrorForDetail({
                        responseJSON: {
                            message: [locale.get('common.fileimport.invalidchar')],
                            confirms: {
                                confirmResults: [{
                                    confirmData: null,
                                    messages: [locale.get('common.fileimport.invalidchar')],
                                }],
                            },
                        },
                    });
                    return;
                }
                self.import.set('importParameters', self.getImportParameters());
                self.import.set('paymentData', self.getPaymentValues());
                self.import.save({}, {
                    success(model, confirmResponse) {
                        self.toggleButtons(true);
                        self.actionSuccess(confirmResponse);
                    },
                    error(model, confirmResponse) {
                        self.actionErrorForDetail(confirmResponse);
                    },
                });
            };
            const encoding = serverConfigParams.get('importCharset');
            if (!util.isEmpty(encoding)) {
                reader.readAsText(file, encoding);
            } else {
                reader.readAsText(file);
            }
        }
    },

    actionErrorForDetail(confirmResponse) {
        // hide our progress bar
        this.ui.$progressBar.parent().toggleClass('hide');
        this.renderMessage(confirmResponse.responseJSON);
        this.enableButtons();
        this.alertRegion.$el.hide();
        $('html, body').animate({
            scrollTop: 0,
        }, 300);
        this.alertRegion.$el.fadeIn(600);
    },

    mandatoryFieldsForImport() {
        const importFormatGrid = this.pageView.gridComponentViews.IMPORTFORMAT;
        const rows = importFormatGrid.grid.getSelectedRows();
        const productCode = $('#_productCode').val();
        const { model } = this.pageView;
        const cmMissingFormat = (this.contextDef.productCode === 'CM' && rows.length === 0);
        const requiredFieldsUSACH = Object.keys(model.validators)
            .filter(key => model.validators[key].exists);

        let className = '';
        let bErrorFlag = false;
        const addErrorClass = fieldName => form.field(fieldName).$el.first().parent().addClass('has-error');
        const removeErrorClass = fieldName => form.field(fieldName).$el.first().parent().removeClass('has-error');
        const validate = (fieldName) => {
            if (model.validators[fieldName] && model.validators[fieldName].exists) {
                if (model.get(fieldName) === '') {
                    addErrorClass(fieldName);
                    bErrorFlag = true;
                } else {
                    removeErrorClass(fieldName);
                }
            }
        };

        if (productCode === 'USACH') {
            form.field('FUNCTIONCODE').$el.change(() => {
                requiredFieldsUSACH.forEach(fieldName => removeErrorClass(fieldName));
            });

            requiredFieldsUSACH.forEach(fieldName => validate(fieldName));
        } else {
            validate('FUNCTIONCODE');
        }

        if (this.contextDef.productCode !== 'CM') {
            // reset
            $('[data-validate="FILESELECTION"]').text('');
            $('[data-validate="OPTIONIMPORTMETHOD"]').text('');
            form.field('FILESELECTION').$el.first().parent().addClass('required');

            // we cant continue if a format section is not selected
            if (rows.length === 0) {
                this.populateAlert(locale.get('ACH.error.SelectFormatName'));
                return false;
            }

            if (model.get('OPTIONIMPORTMETHOD') === '') {
                bErrorFlag = true;
            }

            if (rows.length === 0 || model.get('OPTIONIMPORTMETHOD') === '') {
                ({ className } = $('#OPTIONIMPORTMETHOD')[0].parentElement);
                className += ' has-error';
                $('#OPTIONIMPORTMETHOD')[0].parentElement.className = className;
            }
        }

        if (form.field('FILESELECTION').getValue() === '' || cmMissingFormat) {
            if (cmMissingFormat) {
                $('[data-validate="FILESELECTION"]').text(locale.get('CM.error.SelectFormatName'));
            } else {
                $('[data-validate="FILESELECTION"]').text(locale.get('ACH.error.SelectUploadFile'));
            }
            form.field('FILESELECTION').$el.first().parent().addClass('has-error');
            bErrorFlag = true;
        } else {
            form.field('FILESELECTION').$el.first().parent().removeClass('has-error');
        }

        return (!bErrorFlag);
    },

    populateAlert(message) {
        this.alertRegion.show(alert.negative(message, {
            canDismiss: false,
        }));
        const regionLoc = this.$('.alert-region').offset();
        window.scrollTo(regionLoc.left, regionLoc.top);
    },

    prepareToImport() {
        if (!this.mandatoryFieldsForImport()) {
            return false;
        }
        if (!this.checkFileName()) {
            return false;
        }
        return true;
    },

    checkFileName() {
        const fileNameWithPath = form.field('FILESELECTION').getValue();
        let fileNameWithoutPath = '';
        let lastIndex = -1;
        if (fileNameWithPath.indexOf('\\') !== -1) {
            lastIndex = fileNameWithPath.lastIndexOf('\\');
            fileNameWithoutPath = fileNameWithPath
                .substring(lastIndex + 1, fileNameWithPath.length);
        }

        if (fileNameWithoutPath !== '' && fileNameWithoutPath.length > 255) {
            $('[data-validate="FILESELECTION"]').text(locale.get('ACH.UploadFileNameLength.Error'));
            let { className } = $('#FILESELECTION')[0].parentElement;
            className += ' has-error';
            $('#FILESELECTION')[0].parentElement.className = className;
            return false;
        }
        return true;
    },

    fileimportrefresh() {
        const functionCode = form.field('FUNCTIONCODE');
        if (selectedRow) {
            processSelectedRow(this.pageView.model);
            this.pageView.gridComponentViews.IMPORTFORMAT.refreshGridData();
        }
        form.field('FILESELECTION').setValue('');
        functionCode.shouldBeHidden();
    },

    /**
     * @param {Boolean} buttonState - the desired active status of the buttons. True = active
     */
    toggleButtons(buttonState) {
        domUtil.setDisabled([
            this.ui.$cancelButton,
            this.ui.$fileImportButton,
            this.ui.$refreshButton,
        ], !buttonState);
    },

    disableButtons() {
        domUtil.setDisabled(this.ui.$cancelButton, true);
    },

    enableButtons() {
        this.toggleButtons(true);
    },

    getPaymentValues() {
        const { model } = this.pageView;
        const payValues = [];
        util.each(model.attributes, (value, key) => {
            if (!util.contains(excludeFieldsList, key) && value) {
                payValues.push({
                    name: key,
                    value,
                });
            }
        });

        return payValues;
    },

    getImportParameters() {
        const importParameters = [];
        if (!util.isNullOrUndefined($('#_Param_Name_0').val())) {
            importParameters.push({
                name: $('#_Param_Name_0').val(),
                value: $('#_Param_Value_0').val(),
            });
        }
        if (!util.isNullOrUndefined($('#_Param_Name_1').val())) {
            importParameters.push({
                name: $('#_Param_Name_1').val(),
                value: $('#_Param_Value_1').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_2').val())) {
            importParameters.push({
                name: $('#_Param_Name_2').val(),
                value: $('#_Param_Value_2').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_3').val())) {
            importParameters.push({
                name: $('#_Param_Name_3').val(),
                value: $('#_Param_Value_3').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_4').val())) {
            importParameters.push({
                name: $('#_Param_Name_4').val(),
                value: $('#_Param_Value_4').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_5').val())) {
            importParameters.push({
                name: $('#_Param_Name_5').val(),
                value: $('#_Param_Value_5').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_6').val())) {
            importParameters.push({
                name: $('#_Param_Name_6').val(),
                value: $('#_Param_Value_6').val(),
            });
        }

        const nonRunTimeParams = $('#NonRunTimeParams').val();
        if (nonRunTimeParams.length > 0) {
            const nameValuePairs = nonRunTimeParams.split('|');
            for (let i = 0; i < nameValuePairs.length; i += 1) {
                const nameValuePair = nameValuePairs[i].split('=');
                if (nameValuePair.length === 2) {
                    importParameters.push({
                        name: nameValuePair[0],
                        value: nameValuePair[1],
                    });
                }
            }
        }
        return importParameters;
    },

    showProgressBar() {
        const percent = `${this.import.get('percentComplete')}%`;

        this.ui.$progressBar.attr('aria-valuenow', this.import.get('percentComplete'))
            .css('width', percent).html(percent);
    },

});
