import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';

const IssueVoidSet = Model.extend({
    sync(method, model, options) {
        const url = services.generateUrl('cminst/issueVoids/multi/ADD');

        return http.post(url, model.toJSON(), options.success, options.error);
    },

    parse() {
        // override to avoid appending the response to the model.
    },
});

export default IssueVoidSet;
