var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"AdjustAmounts\">\n    <button class=\"AdjustAmounts-toggle\" type=\"button\" data-hook=\"adjust-form-toggle\">\n        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.adjust.amounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":45}}}))
    + "</span>\n    </button>\n    <form class=\"AdjustAmounts-form hidden\" data-hook=\"getAdjustForm\">\n        <div class=\"AdjustAmounts-affected form-section\">\n            <label>\n                <input\n                    checked\n                    data-hook=\"getBulkUpdateAffectAll\"\n                    name=\"bulk-update-affected\"\n                    type=\"radio\"\n                    value=\"all\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.All",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":36}}}))
    + "\n            </label>\n            <label class=\"getBulkUpdateAffectSelectedLabel\">\n                <input\n                    data-hook=\"getBulkUpdateAffectSelected\"\n                    "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"gridHasSelection") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":67}}})) != null ? stack1 : "")
    + "\n                    name=\"bulk-update-affected\"\n                    type=\"radio\"\n                    value=\"selected\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.selected.rows",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":46}}}))
    + "\n            </label>\n        </div>\n        <div class=\"form-section\">\n            <select name=\"adjustType\" data-hook=\"getBulkUpdateType\">\n                <option value=\"specificAmount\" selected>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.specify.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":56},"end":{"line":28,"column":87}}}))
    + "</option>\n                <option value=\"increasePercent\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.increase.by.percent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":48},"end":{"line":29,"column":84}}}))
    + "</option>\n                <option value=\"decreasePercent\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.decrease.by.percent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":48},"end":{"line":30,"column":84}}}))
    + "</option>\n                <option value=\"increaseAmountBy\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.increase.amount.by",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":49},"end":{"line":31,"column":84}}}))
    + "</option>\n                <option value=\"decreaseAmountBy\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.decrease.amount.by",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":49},"end":{"line":32,"column":84}}}))
    + "</option>\n            </select>\n        </div>\n        <div class=\"form-section\">\n            <input\n                class=\"AdjustAmounts-amountInput\"\n                data-hook=\"getBulkUpdateAmount\"\n                maxlength=\"17\"\n                name=\"edit-amounts-amount\"\n                type=\"number\"\n                step=\"any\" \n                min=\"0.0000001\">\n             <span class=\"help-block\" data-validate=\"AdjustAmounts-amountInput\" style=\"color:red\"></span>\n        </div>\n        <div class=\"AdjustAmounts-formFooter form-section\">\n            <button type=\"button\" data-hook=\"adjust-amounts-apply\" class=\"btn btn-sm btn-primary\">\n                <span class=\"text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"action.apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":35},"end":{"line":48,"column":60}}}))
    + "</span>\n            </button>\n            <button type=\"button\" data-hook=\"adjust-amounts-cancel\" class=\"btn btn-sm btn-secondary\">\n                <span class=\"text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":35},"end":{"line":51,"column":54}}}))
    + "</span>\n            </button>\n        </div>\n    </form>\n</div>";
},"useData":true});