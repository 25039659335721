import util from '@glu/core/src/util';
import Controller from '@glu/core/src/controller';
import $ from 'jquery';

const ELEMENT_RESIZE_CHECK_INTERVAL = 250;

const Watcher = Controller.extend({

    /**
     * this method should be used sparingly
     * it is useful in the case where an element's size may change independently of the
     * window size and the size change cannot be handled gracefully with CSS &
     * HTML alone
     */
    triggerEventOnElementResize($el, oldWidth) {
        let oldWidthParam = oldWidth;
        // test whether a cached element reference still exists in the DOM
        if ($('body').find($el).length === 0) {
            return;
        }

        const width = $el.width();

        // this covers the first iteration
        if (util.isUndefined(oldWidthParam)) {
            oldWidthParam = width;
        }

        if (width !== oldWidthParam) {
            $el.trigger('elementResized');
        }

        window.setTimeout(
            util.bind(this.triggerEventOnElementResize, this, $el, width),
            ELEMENT_RESIZE_CHECK_INTERVAL,
        );
    },
});

export default new Watcher();
