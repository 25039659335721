var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"account-summary-button-row\">\n                                        "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"action":"refresh-balances","icon":(depth0 != null ? lookupProperty(depth0,"refresh") : depth0),"textVisible":true,"className":"btn btn-tertiary refresh-balances-button"},"data":data,"loc":{"start":{"line":11,"column":40},"end":{"line":11,"column":157}}}))
    + "\n                        <span class=\"refresh-time\" data-hook=\"refresh-time\"></span>\n                                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"totalsWarning") || (depth0 != null ? lookupProperty(depth0,"totalsWarning") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalsWarning","hash":{},"data":data,"loc":{"start":{"line":19,"column":54},"end":{"line":19,"column":71}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page bankAccountSummary\">\n    <div class=\"section section-container\">\n        <div class=\"reporting-nav-btn-container\">\n            <div class=\"row\">\n                <div class=\"col-6\">\n                    <div class=\"alerts\"></div>\n                </div>\n                <div class=\"col-6 text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRefreshButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":14,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n    <p class=\"panel-description\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalsWarning") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":33},"end":{"line":19,"column":78}}})) != null ? stack1 : "")
    + "</p>\n    <div data-region=\"currencyBadgeRegion\"></div>\n    <div data-region=\"cashPositionCarouselRegion\"></div>\n    <section class=\"SummaryTableSection\">\n        <div class=\"SummaryTable\">\n            <h3 class=\"SummaryTable-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.BalanceSummary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":43},"end":{"line":24,"column":77}}}))
    + "</h3>\n            <div data-region=\"balanceSummaryRegion\"></div>\n        </div>\n        <div class=\"SummaryTable\">\n            <h3 class=\"SummaryTable-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.TransactionSummary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":43},"end":{"line":28,"column":81}}}))
    + "</h3>\n            <div data-region=\"transactionSummaryRegion\"></div>\n        </div>\n    </section>\n    <div class=\"section-body\">\n        <div class=\"deposit-accounts-grid-region\" data-region=\"depositAccounts\"></div>\n    </div>\n</div>\n";
},"useData":true});