import constants from 'common/dynamicPages/api/constants';
import LookupCollection from 'common/dynamicPages/collections/lookup';
import $ from 'jquery';
import util from '@glu/core/src/util';
import dataFormatters from 'common/util/dataFormatters/getFormatter';

export default {
    setupMultiTypeaheadBoxes(viewParam, selector, $alt) {
        const view = viewParam;
        let localSelector = selector;
        // Support an alternate container element
        const $el = $alt || view.$el;

        if (!localSelector) {
            localSelector = '.multi-type-ahead';
        }
        $el.find(localSelector).each(function (j, el) {
            const attrName = $(this).attr('name');
            const fldData = view.model.fieldData[attrName];
            const LookupClass = LookupCollection;
            const multiple = true;
            const lookupHelperTextFld = fldData.fieldUIType === 'TYPEAHEAD_HELPERTEXT';

            /**
             * @method initRequest
             *   Method initializes the combobox query parameters before a read is done on the
             * typeahead field.  The attrName is the field name.
             */
            const initRequest = function () {
                const depends = [];
                let dependsValue;
                const fieldData = view.model.fieldData[attrName];
                if (!util.isNull(fieldData.dependsOn)) {
                    for (let i = 0; i < fieldData.dependsOn.length; i += 1) {
                        dependsValue = view.model.get(fieldData.dependsOn[i]);
                        if (util.isEmpty(dependsValue) && view.model.isChild) {
                            dependsValue = view.parentModel.get(fieldData.dependsOn[i]);
                        }
                        depends.push({
                            name: fieldData.dependsOn[i],
                            value: dependsValue,
                        });
                    }
                }

                // Adding the staticDependsOn defined for the attrName to the depends
                if (view.model.staticDependsOn && view.model.staticDependsOn[attrName]) {
                    util.each(view.model.staticDependsOn[attrName], (staticDepends) => {
                        depends.push({
                            name: staticDepends.filterParam[0],
                            value: staticDepends.filterParam[1],
                        });
                    });
                }

                view.typeAheadCollections[attrName].depends = depends;

                view.typeAheadCollections[attrName].startRow = 1;
            };

            // use meta information when available to not hardcode fieldname
            const typeAheadDataInfo = {
                fieldName: attrName + ((fldData.relatedProperty) ? '' : 'LOOKUP'),
                fieldKey: attrName,
                context: view.context,

                typeInfo: {
                    productCode: view.model.jsonData.typeInfo.productCode,
                    functionCode: view.model.jsonData.typeInfo.functionCode,
                    typeCode: view.model.jsonData.typeInfo.typeCode,
                },

                dependentTypeAheadFields: view.dependentTypeAheadFields,
                typeAheadFields: fldData.typeAhead,
                view,
            };

            util.each(view.options.typeAheadFieldsOverride, (typeAheadFieldOverride) => {
                if (attrName === typeAheadFieldOverride.name) {
                    typeAheadDataInfo.overrideUrl = typeAheadFieldOverride.overrideUrl;
                }
            });

            view.typeAheadCollections[attrName] = new LookupClass(null, typeAheadDataInfo);
            $(this).comboBox({
                initSelection(element, callback) {
                    let data = util.map($(element.val().split(',')), value => ({
                        id: value,
                        text: value,
                    }));
                    if (data.length === 1) {
                        data = util.first(data);
                    }

                    callback(data);
                },

                multiple,
                closeOnSelect: !multiple,
                placeholder: view.model.fieldData[attrName].placeHolder,

                query: util.debounce((query) => {
                    initRequest();

                    if (query.term.length < constants.COMBO_MIN_CHARS) {
                        view.typeAheadCollections[attrName]
                            .setPageSize(constants.COMBO_MIN_SIZE);
                    } else {
                        view.typeAheadCollections[attrName]
                            .setPageSize(constants.MAX_SERVER_ROWSPERPAGE);
                    }

                    view.typeAheadCollections[attrName].queryTerm = query.term;
                    view.typeAheadCollections[attrName].queryPage = query.page;

                    view.typeAheadCollections[attrName].fetch({
                        /*
                         * when fetching additional pages, keep previous models in the
                         * collection
                         */
                        remove: query.page === 1,

                        success(collection, resp) {
                            // extract the data to be added to combo box
                            const result = util.filter(
                                collection.toJSON(),
                                collItem => util.find(
                                    resp,
                                    newItem => newItem.id === collItem.id,
                                ),
                            );

                            const data = {
                                results: result,
                                more: collection.hasMorePages(),
                            };

                            const formatter = dataFormatters.getFormatter(attrName);

                            if (data.results.length > 0) {
                                util.each(data.results, (rowParam) => {
                                    const row = rowParam;
                                    util.each(row.mapDataList, (objParam) => {
                                        const obj = objParam;
                                        obj.value = util.unescape(obj.value);
                                    });
                                    row.text = util.unescape(row.text);
                                });
                            }

                            // if a formatter is available, then use it to format the data
                            if (formatter) {
                                data.results = formatter.formatData(data.results);
                            }
                            query.callback(data);
                        },

                        error() {
                            query.callback({
                                results: [],
                                more: false,
                            });
                        },
                    });
                }, constants.COMBO_DEBOUNCE),
            });

            $(this).on('select2-selecting', (e) => {
                // trigger event in case we need to prevent selection
                view.trigger(`typeahead:beforeChange:${attrName}`, e, attrName);
            });

            // setup change handler
            $(this).off('change.mdf').on('change.mdf', (e) => {
                let id = null;
                if (e.added) {
                    ({ id } = e.added);
                }
                // if an item was removed, then don't do mapdata
                if (!util.isNull(id)) {
                    const lookupModel = view.typeAheadCollections[attrName].get(id);
                    if (lookupModel && lookupModel.get('mapDataList')) {
                        // There should only be ONE set
                        const setData = util.reduce(lookupModel.get('mapDataList'), (acc, item) => {
                            acc[item.toField.toUpperCase()] = item.value;
                            return acc;
                        }, {});
                        view.model.set(
                            setData,
                            {
                                silent: true,
                            },
                        );
                        // only trigger if field is a helper text lookup
                        if (lookupHelperTextFld) {
                            view.trigger('lookupHelperText:update', attrName);
                        }
                    }
                } else if (e.removed) {
                    view.trigger(`typeahead:remove:${attrName}`, attrName, e.removed.id);
                }

                if (!e.removed) {
                    view.trigger(`typeahead:change:${attrName}`, view.model);
                }
            });

            // var self = this;

            // preload the comboBox with data
            view.once('ui-loaded', () => {
                if (view.typeAheadData[attrName] && view.typeAheadData[attrName].length > 0) {
                    $(this).comboBox('data', view.typeAheadData[attrName]);
                } else if (view.model.jsonData.typeInfo.functionCode === 'ALERT'
                    && attrName === 'BAI_CODE' && !util.isEmpty(view.model.get(attrName))) {
                    /**
                     * NH-49476 - Comboboxes that have values that are composed of
                     * multiple fields do not get initialized correctly on modify.
                     * To fix this, the value of the field is read in here and
                     * then set to displayed correctly.
                     */
                    initRequest();
                    view.typeAheadCollections[attrName].queryTerm = view.model.get(attrName);
                    view.typeAheadCollections[attrName].fetch({
                        remove: true,

                        success: util.bind(function (collection, resp) {
                            const text = collection.generateText(resp[0].columns);
                            if (!util.isEmpty(text)) {
                                $(this).select2('val', text);
                            }
                        }, view.model),

                        error() {},
                    });
                }
            });

            view.once('close', () => {
                view.$(el).comboBox('destroy');
            });
        });
    },
};
