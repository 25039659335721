import Constants from 'app/smb/constants';
import Recipient from 'app/smb/models/map/beneficiary';
import util from '@glu/core/src/util';
import RecipientCollection from 'app/smb/collections/inquiry';

export default RecipientCollection.extend({
    // model: Recipient,

    initialize(options) {
        const inquiryId = Constants.BUSINESS_ACH_INQUIRY_ID;
        const id = options.optionId;

        this.data = function () {
            if (id === Constants.BUSINESS_OPTION_1
                || id === Constants.INDIVIDUAL_OPTION_1
                || id === Constants.COLL_FUNDS_INDIVIDUAL
                || id === Constants.COLL_FUNDS_BUSINESS) {
                return {
                    searchType: Constants.ACTION_CONFIGURED_SEARCH_TYPE,
                    inquiryId,
                    entryMethod: Constants.DEFAULT_ENTRY_METHOD,
                    productCode: Constants.ACH_PRODUCT_CODE,
                    functionCode: Constants.MAINT_FUNCTION_CODE,
                    typeCode: Constants.CORPORATE_VENDOR_TYPE_CODE,
                    actionMode: Constants.INSERT_ACTION_MODE,
                    viewId: Constants.DEFAULT_VIEW_ID,

                    customFilters: [{
                        filterName: Constants.FILTER_DEPENDS,

                        filterParam: [
                            Constants.PARAM_TYPE_CODE,
                            Constants.CORPORATE_VENDOR_TYPE_CODE,
                        ],
                    }, {
                        filterName: Constants.FILTER_DEPENDS,

                        filterParam: [
                            Constants.PARAM_SUB_TYPE_CODE,
                            Constants.NACHA_SUB_TYPE_CODE,
                        ],
                    }],
                };
            }
            if (id === Constants.BUSINESS_OPTION_2
                || id === Constants.INDIVIDUAL_OPTION_2) {
                return {
                    searchType: Constants.ACTION_CONFIGURED_SEARCH_TYPE,
                    inquiryId: Constants.WIRE_DOMESTIC_INQUIRY_ID,
                    entryMethod: Constants.DEFAULT_ENTRY_METHOD,
                    productCode: Constants.RTGS_PRODUCT_CODE,
                    functionCode: Constants.MAINT_FUNCTION_CODE,
                    typeCode: Constants.DOMESTIC_WIRE_TYPE_CODE,
                    actionMode: Constants.INSERT_ACTION_MODE,
                    viewId: Constants.DEFAULT_VIEW_ID,
                };
            }
            if (id === Constants.BUSINESS_OPTION_3
                || id === Constants.INDIVIDUAL_OPTION_3) {
                return {
                    searchType: Constants.ACTION_CONFIGURED_SEARCH_TYPE,
                    inquiryId: Constants.WIRE_INTL_INQUIRY_ID,
                    entryMethod: Constants.DEFAULT_ENTRY_METHOD,
                    productCode: Constants.RTGS_PRODUCT_CODE,
                    functionCode: Constants.MAINT_FUNCTION_CODE,
                    typeCode: Constants.INTERNATIONAL_TYPE_CODE,
                    actionMode: Constants.INSERT_ACTION_MODE,
                    viewId: Constants.DEFAULT_VIEW_ID,
                };
            }
            if (id === Constants.EMPLOYEE_WAGES) {
                return {
                    searchType: Constants.ACTION_CONFIGURED_SEARCH_TYPE,
                    inquiryId,
                    entryMethod: Constants.ENTRY_METHOD,
                    productCode: Constants.ACH_PRODUCT_CODE,
                    functionCode: Constants.MAINT_FUNCTION_CODE,
                    typeCode: Constants.PAYROLL_TYPE_CODE,
                    actionMode: Constants.INSERT_ACTION_MODE,
                    viewId: Constants.DEFAULT_VIEW_ID,

                    customFilters: [{
                        filterName: Constants.FILTER_DEPENDS,

                        filterParam: [
                            Constants.PARAM_TYPE_CODE,
                            Constants.PAYROLL_TYPE_CODE,
                        ],
                    }, {
                        filterName: Constants.FILTER_DEPENDS,

                        filterParam: [
                            Constants.PARAM_SUB_TYPE_CODE,
                            Constants.NACHA_SUB_TYPE_CODE,
                        ],
                    }],
                };
            }
            // TODO: Should this happen? Should it error?
            return undefined;
        };
    },

    processData(jsonData) {
        this.jsonData = jsonData.inquiryResponse;
    },

    parse() {
        try {
            // grab other data first
            this.totalCount = this.jsonData.totalRows;
            return util.map(this.jsonData.rows, this.parseColumn, this);
        } catch (err) {
            return [];
        }
    },

    paymentTypeMap: {
        'RTGS.type.fedwire': 'domesticWire',
        'ACH.corp_ven_pay': 'corporatePayment',
        'ACH.con_pay': 'consumerPayment',
        'ACH.con_col': 'consumerCollection',
    },

    parseColumn(row) {
        const model = new Recipient();
        util.each(row.columns, (column) => {
            model.set(column.fieldName, column.fieldValue);
        });

        model.paymentType = this.paymentTypeMap[model.get('TYPE_DESCRIPTION')];
        return model;
    },
});
