import ListView from 'common/dynamicPages/views/workflow/list';
import FlexDropdown from '@glu/flex-dropdown';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import TokenModel from 'app/administration/models/token';
import UserGroupLookup from 'app/administration/views/userMaintenance/userGroupLookup';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import AdminListView from './adminTokenListView';
import template from './adminTokenListView.hbs';

const ExpiringTokenList = AdminListView.extend({
    template,

    regions: {
        dateDropdownRegion: '[data-region="dateDropdownRegion"]',
    },

    initialize() {
        const superOptions = {
            menuCategory: 'TKNMGMT',
            serviceName: '/tokenManagement/expiringTokens/listAllTokens',
            serviceFunc: null,
            businessType: null,
            context: 'TKNMGMTRPT',
            returnRoute: '/',
            hideGridActionButtons: true,
            selector: 'none',
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions));
        this.userGroupLookup = new UserGroupLookup({
            isAdmin: true,
        });
    },

    ui: {
        $submit: '[data-hook="getSubmitBtn"]',
        $grid: '[data-hook="getGrid"]',
        $widgetLinks: '[data-hook="getWidgetLinks"]',
        $userGroupCombo: '.usergroup-select',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click @ui.$submit': 'fetchTokensFromCore',
        },
    ),

    /**
     * - initialize the company dropdown after the dropdown data has been loaded
     * @method initDropdown
     */

    initDropdown() {
        this.model = new TokenModel({
            DAYS: '90',
        });
        this.model.addExpireValidator();
        this.daysDropdown = this.createDropdown(
            'daysDropdownRegion',
            {
                data: [{
                    id: '30',
                    name: locale.get('tknmgmt.ui.30'),
                }, {
                    id: '60',
                    name: locale.get('tknmgmt.ui.60'),
                }, {
                    id: '90',
                    name: locale.get('tknmgmt.ui.90'),
                }],

                label: locale.get('tknmgmt.ui.timeFrame'),
                filter: false,
                preSelectedIds: ['90'],
            },
        );
        this.listenTo(this.daysDropdown, 'selectionChanged', this.setDaysValue);
    },

    /**
     * - sets the selected dropdown value on the model
     * @method setDateValue
     * @param {array} selected - array of selected objects (see Glu docs for details)
     */
    setDaysValue(selected) {
        this.model.set('DAYS', selected[0].id);
    },

    /**
     * - create a flex dropdown
     * @method createDropdown
     * @param {string} region - the region the dropdown should be added to
     * @param {object} optionsParam - the data options object required by all Flex Dropdowns
     * @returns {object} - the dropdown
     */
    createDropdown(region, optionsParam) {
        const options = optionsParam;
        options.multiSelect = false;
        options.disableMultiButton = true;
        options.showTooltip = false;

        const dropdown = new FlexDropdown(options);
        if (this[region]) {
            this[region].show(dropdown);
        }

        return dropdown;
    },

    /**
     * @method fetchTokensFromCore
     * Fetch the tokens from RSA and save it in DGB
     */
    fetchTokensFromCore() {
        this.ui.$submit.attr('aria-busy', true);
        this.model.syncData(
            'expire',
            {
                success: this.fetchSuccess.bind(this),
                error: this.fetchError.bind(this),
            },
        );
    },

    templateHelpers() {
        return {
            expired: true,
        };
    },
});

let list = ExpiringTokenList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
