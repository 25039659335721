var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-region\"></div>\n\n<form class=\"form-container user-info\">\n    <div class=\"field-container-lg required\">\n       <label for=\"REASON\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.Reason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":27},"end":{"line":5,"column":50}}}))
    + "</label>\n       <input type=\"text\" id=\"REASON\" class=\"form-control voidReson-field\" name=\"REASON\">\n       <span class=\"help-block\" data-bind=\"model\" data-validate=\"REASON\"></span>\n    </div>\n</form>\n";
},"useData":true});