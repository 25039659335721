import NestedModel from '@glu/core/src/nestedModel';
import util from '@glu/core/src/util';
import Types from '../../collection/user2/types';
import Entitlements from '../../collection/user2/entitlements';

export default NestedModel.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            id: '',
            label: '',
            displayOrder: null,
            aggregateModels: new Entitlements(),
            types: new Types(),
        };
    },

    getEntitledTypes() {
        return new Types(this.get('types').filter(type => type.get('entitled')));
    },

    isEntitled() {
        return this.get('types').some(type => type.get('entitled'));
    },

    isSimpleEntitled() {
        return this.get('types').some(type => type.get('entitlements').some(entitlement => entitlement.get('actions.view.entitled')));
    },

    getEntitlementsByFunctionCode(codes) {
        return new Entitlements(this.get('aggregateModels').filter(aggregateModel => util.contains(codes, aggregateModel.get('functionCode'))));
    },

    parse(response) {
        response.types = new Types(response.types);
        response.aggregateModels = new Entitlements(response.aggregateModels);
        return response;
    },
});
