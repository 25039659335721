import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import Constants from 'common/uiWidgets/util/constants';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import auditSectionWidgetTmpl from './auditSectionWidget.hbs';

export default BaseWidget.extend({
    template: auditSectionWidgetTmpl,
    className: 'ui-widget auditsection-widget field-container',

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);

        this.model = new Model();
        this.suppressedColumns = options.suppressedColumns;
        /*
         * when displayHistoryLink is not undefined,
         * set the true/false value, otherwise default true
         */
        this.displayHistoryLink = util.isUndefined(options.displayHistoryLink)
            ? true : options.displayHistoryLink;
        this.collectAuditFields();
    },

    getDisplayTimestamp(field) {
        if (this.parentModel.get(field) === '') {
            return '';
        }
        return moment(this.parentModel.get(field), [(`${userInfo.getDateFormat()} ${userInfo.getTimeFormat()}`), 'YYYY-MM-DD HH:mm:ss.S']).format(userInfo.getDateTimeFormat());
    },

    getOneAuditField(parentModel, prefix, suffix, title) {
        return {
            title,
            timestamp: this.getDisplayTimestamp(`${prefix}TIMESTAMP${suffix}`),
            company: parentModel.get(`${prefix}USERGROUP${suffix}`),
            userID: parentModel.get(`${prefix}BY${suffix}`),
            userName: (parentModel.get(`${prefix}BY_NAME${suffix}`)) ? parentModel.get(`${prefix}BY_NAME${suffix}`) : parentModel.get(`${prefix}NAME${suffix}`),
        };
    },

    addAuditField(auditFields, auditType, auditTitle) {
        const { parentModel } = this;
        let auditField;
        const prefix = `${auditType}_`;
        if (parentModel.get(`${prefix}TIMESTAMP`)) {
            auditField = this.getOneAuditField(parentModel, prefix, '', auditTitle);
            if (auditType === 'MODIFIED' && parentModel.get('ERRORS')) {
                auditField.errors = locale.get(parentModel.get('ERRORS'));
            }
            auditFields.push(auditField);
        }
    },

    addApproveAuditFields(auditFields) {
        const { parentModel } = this;
        const prefix = Constants.APPROVED_PREFIX;
        /**
         * Since we are iterating over the number of approvers, we are just using
         * a plain for
         * loop rather than a FP construct
         */
        for (let i = 1; i <= Constants.MAXAPPROVERS; i += 1) {
            if (parentModel.get(`${prefix}TIMESTAMP_${i}`)) {
                auditFields.push(this.getOneAuditField(parentModel, prefix, `_${i}`, locale.get(`Approved ${i}`)));
            }
        }
    },

    /**
     * @function addReversalApproveAuditFields
     * @param {Array} auditFields - Array holding all of the audit rows to be displayed
     * @description Loops through REVERSAL_APPROVED_ audit fields. There can be
     * up to 6 depending on
     * how many users it took to get the required number of signatures.
     */
    addReversalApproveAuditFields(auditFields) {
        const { parentModel } = this;
        const prefix = Constants.REVERSEAL_APPROVED_PREFIX;
        /**
         * Since we are iterating over the number of approvers, we are just using
         * a plain for
         * loop rather than a FP construct
         */
        for (let i = 1; i <= Constants.MAXAPPROVERS; i += 1) {
            if (parentModel.get(`${prefix}TIMESTAMP_${i}`)) {
                auditFields.push(this.getOneAuditField(parentModel, prefix, `_${i}`, locale.get(`Reversal Approved ${i}`)));
            }
        }
    },

    /**
     * @function collectAuditFields
     * @description collects the audit fields from the parent model for rendering.
     * Each audit field must have a title, timestamp, usergroup, userid, and username.
     * if there is not timestamp, then this audit field will not appear.
     */
    collectAuditFields() {
        // every payment/template has an entered audit field set
        const { parentModel } = this;

        let auditFields = [{
            title: locale.get('Entered'),
            timestamp: this.getDisplayTimestamp('ENTERED_TIMESTAMP'),
            company: parentModel.get('USERGROUP'),
            userID: parentModel.get('ENTERED_BY'),
            userName: parentModel.get('ENTERED_BY_NAME'),
        }];

        this.addAuditField(auditFields, 'MODIFIED', locale.get('allReports.Last_Modified'));
        this.addAuditField(auditFields, 'RESTORED', locale.get('PS.screentext.Restored'));
        this.addAuditField(auditFields, 'REPAIRED', locale.get('PS.screentext.Repaired'));

        // add any approval audit fields
        this.addApproveAuditFields(auditFields);

        // add audit fields for other status'
        this.addAuditField(auditFields, 'HV_APPROVED', locale.get('PAY.HighValueApproved'));
        this.addAuditField(auditFields, 'RATE_TRADED', locale.get('PS.screentext.Rate Traded'));

        if (parentModel.get('BALANCECHECKRESULT') === '2') {
            this.addAuditField(auditFields, 'BALANCE_CHECK', locale.get('PS.screentext.BalChecKSuccess'));
        } else if (parentModel.get('BALANCECHECKRESULT') === '1') {
            this.addAuditField(auditFields, 'BALANCE_CHECK', locale.get('PS.screentext.BalChecKFailure'));
        }
        if (parentModel.get('PREFUNDINGRESULT') === '2') {
            auditFields.push({
                title: locale.get('common.prefund.success'),
                timestamp: this.getDisplayTimestamp('PREFUNDINGTIMESTAMP'),
                company: '',
                userID: '',
                userName: '',
            });
        } else if (parentModel.get('PREFUNDINGRESULT') === '1') {
            auditFields.push({
                title: locale.get('common.prefund.failed'),
                timestamp: this.getDisplayTimestamp('PREFUNDINGTIMESTAMP'),
                company: '',
                userID: '',
                userName: '',
            });
        }
        this.addAuditField(auditFields, 'APPROVER_REJECTION', locale.get('PS.screentext.Approver Rejected'));
        this.addAuditField(auditFields, 'UNAPPROVED', locale.get('PS.screentext.Unapproved'));
        this.addAuditField(auditFields, 'DELETED', locale.get('PS.screentext.Deleted'));
        this.addAuditField(auditFields, 'RELEASED', locale.get('PS.screentext.Released'));
        this.addAuditField(auditFields, 'EXTRACT', locale.get('PS.screentext.Extracted'));
        this.addAuditField(auditFields, 'REVERSED', locale.get('PS.screentext.Reversed'));
        this.addReversalApproveAuditFields(auditFields);

        if (parentModel.get('REVERSALEXTRACT_TIMESTAMP')) {
            auditFields.push({
                title: locale.get('PS.screentext.ReversalExtracted'),
                timestamp: this.getDisplayTimestamp('REVERSALEXTRACT_TIMESTAMP'),
                company: '',
                userID: '',
                userName: '',
            });
        }

        this.addAuditField(auditFields, 'FIRST_CONFIRMATION', locale.get('PS.screentext.BankReceived'));
        this.addAuditField(auditFields, 'SECOND_CONFIRMATION', locale.get('PS.screentext.BankConfirmed'));
        this.addAuditField(auditFields, 'CONFIRMED', locale.get('PS.screentext.BankConfirmed'));
        this.addAuditField(auditFields, 'REJECTION', locale.get('PS.screentext.BankRejected'));
        this.addAuditField(auditFields, 'ARCHIVED', locale.get('PS.screentext.Archived'));

        auditFields = util.sortBy(auditFields, auditField => auditField.timestamp);
        this.model.set('auditFields', auditFields);
    },

    templateHelpers() {
        const { parentModel } = this;
        const functionCode = parentModel.get('FUNCTION');
        const typeCode = parentModel.get('TYPE');
        const suppressedColumns = this.suppressedColumns || [];
        return {
            getViewHistoryLabel() {
                if (functionCode === 'INST' || functionCode === 'BATCH') {
                    return locale.get('RTGS.INST.FEDWIRE.*.VIEWHISTORY.label');
                }
                return locale.get('RTGS.TMPL.FEDWIRE.*.VIEWHISTORY.label');
            },

            showUserGroupColumn: suppressedColumns.indexOf('userGroup') === -1,
            showTimestampColumn: suppressedColumns.indexOf('timestamp') === -1,
            showUserNameColumn: suppressedColumns.indexOf('userName') === -1,
            showUserIDColumn: suppressedColumns.indexOf('userID') === -1,

            /*
             * NH-87476 Timestamp wraps - Template allows for potentially 6 sets of data
             * that total "12" columns. Only bump timestamp up if there are suppressed
             * columns or no errors.
             */
            timestampColumnClass: (suppressedColumns.length || !this.parentModel.get('ERRORS')) ? 'col-md-3' : 'col-md-2',

            hasError: this.parentModel.get('ERRORS'),
            displayHistoryLink: (typeCode === 'STOP') ? false : this.displayHistoryLink,
        };
    },
});
