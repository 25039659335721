import { createUseStyles } from '@glu/theming';

const styles = ({ typography }) => ({
    icon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 100,
        marginBottom: 15,
        fontSize: 18,
        fontWeight: typography.fontWeightHeavy,
    },
    imgRow: {
        marginRight: 10,
        marginTop: 2,
    },
    noFlagIcon: {
        marginTop: -6,
    },
    noFlagRow: {
        width: 60,
    },
});

export default createUseStyles(styles);
