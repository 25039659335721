import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { withValidation, validatedComponentPropTypes } from '@glu/validation-react';

import './input.css';

let uniqueId = 0;

const isCheckbox = ({ type }) => type === 'checkbox';
const isRadio = ({ type }) => type === 'radio';

class Input extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { id: `input-${uniqueId++}` }; // eslint-disable-line no-plusplus

    this.inputRef = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.inputRef.current.focus();
    }
  }

  onChange(event) {
    const { value, checked } = event.target;
    const shouldValidate = isCheckbox(this.props) || isRadio(this.props);
    const dataValue = isCheckbox(this.props) ? checked : value;

    this.props.onChange(this.props.name, dataValue, shouldValidate);
  }

  onBlur(event) {
    const { value, checked } = event.target;

    const dataValue = isCheckbox(this.props) ? checked : value;

    if (!isCheckbox(this.props) && !isRadio(this.props)) {
      this.props.onChange(this.props.name, dataValue, true);
    }

    this.props.onBlur(this.props.name, dataValue);
  }

  buildGroupClassName() {
    let className = `react-input ${this.props.groupClass}`;
    const {
      name, errors, inputBeforeLabel, validators
    } = this.props;

    if (isCheckbox(this.props)) {
      className += ' checkbox';
    } else if (isRadio(this.props)) {
      className += ' radio';
    } else {
      className += ' form-group';
    }

    if ((isRadio(this.props) || isCheckbox(this.props)) && !inputBeforeLabel) {
      className += ' input-after-label';
    }

    if (errors[name]) {
      className += ' has-error';
    }

    if (validators && validators.required) {
      className += ' required';
    }

    return className.trim();
  }

  buildInputClass() {
    return `${isCheckbox(this.props) || isRadio(this.props) ? '' : 'form-control'} ${this.props.inputClass}`.trim();
  }

  render() {
    const { id } = this.state;
    const {
      inputBeforeLabel, type, name, errors, value, label, labelClass,
      radioValue, hideLabel, prefixText, suffixText, disabled
    } = this.props;
    const error = errors[name];
    const valueProp = isCheckbox(this.props) ? { checked: value } : (
      isRadio(this.props) ? { checked: value === radioValue, value: radioValue } : { value }
    );

    const InputWrapper = prefixText || suffixText ? (props) => <div className="input-group" {...props} /> : Fragment;

    return (
      <div className={this.buildGroupClassName()}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={id}>
          {inputBeforeLabel ? null : <span className={hideLabel ? 'sr-only' : labelClass}>{label}</span>}

          <InputWrapper>
            {prefixText ? <div className="input-group-addon">{prefixText}</div> : null}

            <input
              className={this.buildInputClass()}
              id={id}
              type={type}
              name={name}
              {...valueProp}
              onChange={this.onChange}
              onBlur={this.onBlur}
              ref={this.inputRef}
              disabled={disabled}
            />

            {suffixText ? <div className="input-group-addon">{suffixText}</div> : null}
          </InputWrapper>

          {inputBeforeLabel ? <span className={hideLabel ? 'sr-only' : labelClass}>{label}</span> : null}
          {error ? <span className="help-block">{error}</span> : ''}
        </label>
      </div>
    );
  }
}

Input.propTypes = Object.assign({}, validatedComponentPropTypes, {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,

  checked: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelClass: PropTypes.string,
  inputBeforeLabel: PropTypes.bool,
  groupClass: PropTypes.string,
  inputClass: PropTypes.string,
  hideLabel: PropTypes.bool,
  prefixText: PropTypes.string,
  suffixText: PropTypes.string,
  disabled: PropTypes.bool
});

Input.defaultProps = {
  errors: {},
  type: 'text',
  value: '',
  labelClass: '',
  injectValidation: true,
  groupClass: '',
  inputClass: '',
  onBlur: () => {},
  onChange: () => {},
  autoFocus: false,
  radioValue: '',
  disabled: false
};

export const ValidatedInput = withValidation(Input);

export default Input;
