import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';

const RecipientsListView = ListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMINSTRATION',
            serviceName: 'tableMaintenance/emailRecipients',
            serviceFunc: null,
            businessType: null,
            context: 'EMAIL_RECP_MAINT',
            enableSavedViews: false,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'EMAIL_RECIPIENTS_LIST',
    view: RecipientsListView,
    options: {},
});

export default RecipientsListView;
