import React from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import useLocale from 'hooks/useLocale';
import List from 'app/checkManagement/views/achClientView/achClientListView';

const AchClientListView = () => {
    const { getLocaleString } = useLocale();

    return (
        <>
            <PageHeader headingText={getLocaleString('risk.ach.pigid.maintenance')} />
            <div className="section section-container">
                <ViewWrapper view={new List()} />
            </div>
        </>
    );
};

export default AchClientListView;
