import EntryView from 'common/dynamicPages/views/workflow/entry';
import DataAPI from 'common/dynamicPages/api/data';
import PageApi from 'common/dynamicPages/api/view';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import gridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import Formatter from 'system/utilities/format';
import { appBus } from '@glu/core';
import configuration from 'system/configuration';

const PaymentManagementList = EntryView.extend({
    ui: util.extend(
        {},
        EntryView.prototype.ui,
        {
            $setLandingText: '[data-hook="setLandingText"]',
        },
    ),

    initialize(options, ...rest) {
        EntryView.prototype.initialize.apply(this, [options, ...rest]);
        this.contextDef.createdFrom = '1';
        // Despite the file name, this is used whenever we are making a template.
        this.contextDef.copySource = ['INST', 'BATCH'].includes(options.functionCode) ? 'payment' : 'template';
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.contextDef.serviceName = this.options.serviceName;
            this.contextDef.subType = this.options.subType;
            this.contextDef.createdFrom = '1';
            this.pageView = PageApi.page.get({
                context: this.contextDef,
                model: this.paymentModel,
                hideButtons: true,
                gridApi,
                overrideChildFunctionCode: this.targetFunction,
            });
            this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
            this.listenTo(this.pageView, 'loaded', this.getPaymentMessage);

            // Start NH-15204: Override header for SMB

            if ((this.model.get('CMB_TYPE_DESCRIPTION') === 'ACH.reimburse' && this.model.get('FROMTEMPLATE') === '1')
              || (this.options.reimburse && this.options.smbOverride)) {
                this.ui.$setLandingText.text(locale.get('smbPayments.employee.reimbursement'));
            } else if (this.options.reimburse) {
                this.ui.$setLandingText.text(locale.get('payment.reimbursement.new'));
            }
            // End NH-15204

            this.pageContent.show(this.pageView);
            this.displaySummaryTotal();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        const keyListGlobal = ['TNUM', 'UPDATECOUNT__', 'FROMTEMPLATE', 'TARGETFUNCTION'];

        /*
         * Set templateServiceName if it doesn't exist.
         * Right now, this is only for SMB menu contexts
         */
        if (!this.contextDef.templateServiceName) {
            this.contextDef.templateServiceName = this.contextDef.serviceName;
        }

        const model = DataAPI.model.generateFromKeyList({
            context: {
                serviceName: this.contextDef.serviceName,
            },

            keyList: keyListGlobal,
        });
        this.targetFunction = this.contextDef.functionCode;
        if (this.contextDef.functionCode === 'TMPL') {
            this.targetFunction = 'INST';
        } else if (this.contextDef.functionCode === 'BHTMPL') {
            this.targetFunction = 'BATCH';
        }
        /*
         * Adjust the locale key to 'payment' from 'template' since the flow
         * can be from the template list view or screen where a template is
         * used to create a payment
         */
        this.localeKey = this.localeKey.replace('template', 'payment');
        model.set('TNUM', this.contextDef.tnum);
        model.set('UPDATECOUNT__', this.contextDef.updateCount);
        model.set('FROMTEMPLATE', '1');
        model.set('TARGETFUNCTION', this.targetFunction);
        model.set('FUNCTION', this.contextDef.functionCode);
        model.set('TEMPLATEENTRYMETHOD', this.contextDef.templateEntryMethod);
        model.fetch({
            success(m) {
                self.model = m;
                let modelEntryMethod = self.contextDef.loadFromPayments ? '0' : '1';

                if (m.attributes.TEMPLATEENTRYMETHOD && m.attributes.TEMPLATEENTRYMETHOD === '2') {
                    self.model.set('ENTRYMETHOD', '2');
                    m.set('ENTRYMETHOD', '2');
                    modelEntryMethod = '2';
                }
                const modelPromise = DataAPI.model.generate({
                    context: {
                        functionCode: self.targetFunction,
                        serviceName: self.contextDef.serviceName,
                        entrymethod: modelEntryMethod,
                        subType: self.contextDef.subType,
                        createdFrom: self.contextDef.createdFrom,
                        copySource: self.contextDef.copySource,
                    },
                }, false);
                const dateService = services.generateUrl(constants.URL_GETDATESLIST);
                const postData = {
                    paymentType: model.get('TYPE'),
                    subType: model.get('SUBTYPE'),
                    debitBank: model.get('CMB_DEBIT_BANK_CODE'),
                    creditCurrency: model.get('CREDIT_CURRENCY'),
                    debitCurrency: model.get('DEBIT_CURRENCY'),
                    debitBankCountry: model.get('DEBIT_COUNTRY'),
                    creditBankCountry: model.get('BENE_COUNTRY'),
                    beneBankId: model.get('CMB_BENE_BANK_ID'),
                    beneBankType: model.get('BENE_BANK_TYPE'),
                };
                http.post(dateService, postData, (data) => {
                    let date = data.defaultDay;
                    let origCompName;
                    let origCompID;
                    let compIdName;
                    modelPromise.then((paymentModelParam) => {
                        const paymentModel = paymentModelParam;
                        util.each(self.model.attributes, (value, key) => {
                            if (!util.isEmpty(self.model.get(key))) {
                                if (key === 'CREDIT_AMOUNT' || key === 'DEBIT_AMOUNT') {
                                    paymentModel.set(
                                        key,
                                        Formatter.formatCurrency(self.model.get(key) || 0),
                                    );
                                } else {
                                    paymentModel.set(key, self.model.get(key));
                                }
                                if (key === 'CLIENTCOMP_GROUPNUM') {
                                    paymentModel.set('ID', self.model.get(key));
                                }
                            }
                        });

                        if (self.model.get('PRODUCT') === 'USACH') {
                            origCompName = self.model.get('ORIGCOMPNAME');
                            origCompID = self.model.get('ORIGCOMPID');
                            compIdName = `${origCompID} / ${origCompName}`;
                            paymentModel.set('COMPIDNAME', compIdName);
                        }

                        /*
                         * remove the context productCode for ACH templates as it was not
                         * finding the cache name for the Children
                         */
                        if (self.model.get('PRODUCT') === 'USACH' && self.contextDef.functionCode === 'BHTMPL') {
                            delete self.contextDef.productCode;
                        }

                        date = moment(date);

                        date = date.format(userInfo.getDateFormat());
                        if (self.model.get('FUNCTION') === 'BATCH') {
                            paymentModel.set('EFFECTIVEDATE', date);
                            paymentModel.fieldData.EFFECTIVEDATE.value = date;
                        } else {
                            paymentModel.set('VALUE_DATE', date);
                            paymentModel.fieldData.VALUE_DATE.value = date;
                        }

                        date = moment(data.tranDate);
                        date = date.format(userInfo.getDateFormat());

                        paymentModel.set('CUTOFF_INFO', data.cutoffDateTimeTz);
                        paymentModel.set('TRAN_DATE', date);
                        paymentModel.fieldData.TRAN_DATE.value = date;

                        if (self.model.get('PRODUCT') !== 'RTP' && paymentModel.get('ENTRYMETHOD') === '1'
                            && paymentModel.get('CUSTOMER_REFERENCE') && paymentModel.get('CUSTOMER_REFERENCE').length > 0) {
                            paymentModel.fieldData.CUSTOMER_REFERENCE.protected = true;
                        } else {
                            paymentModel.fieldData.CUSTOMER_REFERENCE.protected = false;
                        }

                        /*
                         * need to over-ride if lock attributes come into play for customer
                         * reference being locked or not
                         */
                        let lockedFields = [];
                        if (paymentModel.has('LOCKED_FIELDS')) {
                            lockedFields = paymentModel.get('LOCKED_FIELDS').split(',');
                        }
                        /*
                         * if customer_reference is lockable and included in the locked
                         * fields, then we lock, if lockable and not included in lockedfields,
                         * then we dont lock
                         */
                        if (paymentModel.fieldData.CUSTOMER_REFERENCE && paymentModel.fieldData.CUSTOMER_REFERENCE.lockable && !lockedFields.includes('CUSTOMER_REFERENCE')) {
                            paymentModel.fieldData.CUSTOMER_REFERENCE.protected = false;
                        }

                        self.paymentModel = paymentModel;

                        // handle any locked fields setup
                        if (paymentModel.has('LOCKED_FIELDS')) {
                            lockedFields.forEach((fieldName) => {
                                if (paymentModel.has(fieldName)) {
                                    paymentModel.fieldData[fieldName].locked = true;
                                    paymentModel.fieldData[fieldName].protected = true;
                                    if (fieldName === 'CREDIT_AMOUNT' && paymentModel.has('CREDIT_CURRENCY')) {
                                        paymentModel.fieldData.CREDIT_CURRENCY.locked = true;
                                        paymentModel.fieldData.CREDIT_CURRENCY.protected = true;
                                    } else if (fieldName === 'DEBIT_AMOUNT' && paymentModel.has('DEBIT_CURRENCY')) {
                                        paymentModel.fieldData.DEBIT_CURRENCY.locked = true;
                                        paymentModel.fieldData.DEBIT_CURRENCY.protected = true;
                                    }
                                }
                            });
                        }

                        self.setHasLoadedRequiredData(true);
                        self.render();
                    });
                }, () => {
                    // handle errors
                });
            },
            error() {
                if (configuration.isPortal()) {
                    appBus.trigger('router:navigate', 'PAYMENTS/readAccessDenied', true);
                }
            },
        });
    },
});
export default PaymentManagementList;
