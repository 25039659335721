import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { PinNextIcon } from '@glu/icons-react';
import {
  Button, TERTIARY, SMALL
} from '@glu/buttons-react';
import locale from '@glu/locale';
import styles from './ColumnMenu.style';

const PinColumn = ({
  classes,
  column,
  onChange,
  pinnedClear
}) => {
  const [pinned, setPinned] = useState();

  useEffect(() => {
    onChange(pinned);
  }, [pinned]);

  useEffect(() => {
    setPinned(undefined);
  }, [pinnedClear]);

  return (
    <div className={`${classes.menuWrap} ${classes.filterHeader} `}>

      <div className={classes.menuHeader}>
        <PinNextIcon className={classes.pinIcon} />
        <span className={classes.pinText}>{ locale.get('pinColumn') }</span>

        {!column.pinned || (column.pinned && column.pinned === 'right') ? (
          <Button
            onClick={() => setPinned('left')}
            className={`${classes.btnSmall} ${pinned === 'left' ? classes.pinned : ''}`}
            text={locale.get('left')}
            variant={TERTIARY}
            size={SMALL}
          />
        ) : null}

        {!column.pinned && <span className={classes.separator} />}

        {!column.pinned || (column.pinned && column.pinned === 'left') ? (
          <Button
            onClick={() => setPinned('right')}
            className={`${classes.btnSmall} ${pinned === 'right' ? classes.pinned : ''}`}
            text={locale.get('right')}
            variant={TERTIARY}
            size={SMALL}
          />
        ) : null}

        {column.pinned && (
        <>
          <span className={classes.separator} />
          <Button
            onClick={() => setPinned(null)}
            className={`${classes.btnSmall} ${pinned === null ? classes.pinned : ''}`}
            text={locale.get('unpin')}
            variant={TERTIARY}
            size={SMALL}
          />
        </>
        )}
      </div>
    </div>
  );
};

PinColumn.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
    userProvidedColDef: PropTypes.object.isRequired,
    colId: PropTypes.string.isRequired,
    pinned: PropTypes.oneOf(['left', 'right'])
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  pinnedClear: PropTypes.bool
};

PinColumn.defaultProps = {
  pinnedClear: false
};

export default withStyles(styles)(PinColumn);
