import locale from '@glu/locale';
import ShowCaseListView from './views/showCaseListView';
import ShowCaseView from './views/showCase';
import LocaleKeysView from './views/localeKeys';

export default {
    showCaseListViewTestPage() {
        this.showPage('ListView Showcase', new ShowCaseListView({

        }));
    },

    showShowCasePage() {
        // create show case view
        const title = locale.get('menuitem.SETUP.showCase');
        this.showPage(title, new ShowCaseView({

        }));
    },

    localeKeysPage() {
        this.showPage('Locale Keys (Application Resources)', new LocaleKeysView({

        }));
    },
};
