import util from '@glu/core/src/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default {
    /**
     * @description Checks to see if the combo referenced by the input id is mandatory
     *              and contains only one item.  If so, the model is set to that one item.
     *              This only applies to combo and comboselect controls
     * @param view - current view
     * @param id - id of combo/comboselect control
     * @param mandatory - mandatory override.  If present, the function will use this
     *                    to determine if the control is mandatory rather that the
     *                    fieldData information
     */
    selectIfOnlyOne(view, id, mandatory) {
        const selectIfOnlyOne = (serverConfigParams.get('SelectIfOnlyOne') && serverConfigParams.get('SelectIfOnlyOne').toUpperCase() === 'TRUE');
        const { model } = view;
        const fieldData = model.fieldData[id];
        const comboCollections = view.comboCollections || view.childView.comboCollections;
        const currentCollection = comboCollections[id];
        const isMandatory = mandatory === undefined ? fieldData.mandatory : mandatory;
        const viewParam = view;
        if (selectIfOnlyOne && isMandatory) {
            // check to see if this is a combo or combo select
            if (!util.isEmpty(fieldData.choices)) {
                // combo select
                if (fieldData.choices.length === 1) {
                    if (util.isEmpty(model.get(id))) {
                        /*
                         * pass an option here that signifies that this is being set as a
                         * result of the 'select if only one' flow. Meaning that view binding
                         * should trigger a change event on the input.
                         */
                        model.set(id, fieldData.choices[0].name, { selectIfOnlyOne: true });
                    }
                }
            } else if (!util.isEmpty(currentCollection)) {
                // combo
                if (currentCollection.length === 1) {
                    if (util.isEmpty(model.get(id))) {
                        model.set(id, currentCollection[0].name, { selectIfOnlyOne: true });
                        this.showHelperText(view.allWidgets, fieldData.name);
                        if (model.driverFields && model.driverFields.indexOf(id) !== 1) {
                            viewParam.reloadingDriverField = id;
                            view.setHasLoadedRequiredData(false);
                            view.loadViewRequirements(view.initializeOptions);
                        }
                    }
                }
            }
        }
    },

    /**
     * Call showHelperText on the widget if the widget
     * exposes that function
     * @param {Array} widgets - all widgets attached to the view
     * @param {string} fieldName - name of the field
     */
    showHelperText(widgets, fieldName) {
        const widget = this.getWidgetByFieldName(widgets, fieldName);
        if (widget && widget.showHelperText) {
            widget.showHelperText();
        }
    },

    /**
     * Get the widget by field name
     * @param {Array} widgets - array of widgets
     * @param {string} fieldName - name of the field
     * @returns {Object}
     */
    getWidgetByFieldName(widgets, fieldName) {
        return widgets.find(widget => widget.fieldName === fieldName);
    },
};
