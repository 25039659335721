import mobileUtil from 'mobile/util/mobileUtil';
import userInfo from 'etc/userInfo';
import { updateBiometricData } from 'mobile/util/biometricUtil';
import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import CollectionView from '@glu/core/src/collectionView';
import loadingTmpl from 'common/templates/loadingModal.hbs';
import PasswordModel from 'system/password/models/password';
import PasswordRulesCollection from 'system/password/collections/rules';
import PasswordRuleView from 'common/passwordRules/rule';
import tmpl from './change.hbs';

const ChangeView = Layout.extend({
    modalClass: 'modal-lg',
    template: tmpl,
    loadingTemplate: loadingTmpl,

    behaviors: {
        ValidationSupplement: {},
    },

    ui: {
        $password: 'input[name="newPassword"]',
    },

    initialize(options) {
        this.model = new PasswordModel();
        this.brandMode = options && options.brandMode;
        this.dialogTitle = locale.get('function.ChangePwd');
        this.showSuccessMsg = options.showSuccessMsg;

        this.dialogButtons = [{
            text: locale.get('panel.ChangePwd'),
            className: 'btn btn-primary changeBtn',
            callback: 'change',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-primary cancelBtn',
            callback: 'cancel',
        }, {
            text: locale.get('button.close'),
            className: 'btn btn-primary closeBtn hidden',
            callback: 'cancel',
        }];
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.passwordCollectionView = new CollectionView({
                el: '.password-rules',
                collection: this.passwordRules,
                itemView: PasswordRuleView,
            });
            this.passwordCollectionView.render();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        this.passwordRules = new PasswordRulesCollection();
        this.passwordRules.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    change() {
        // validate the form
        const self = this;

        this.model.save(
            {},
            {
                success: () => {
                    const $modal = self.$el.parents('.modal');

                    // Only native app has biometric
                    if (mobileUtil.isNativeApp()) {
                        updateBiometricData({
                            userGroup: userInfo.get('group'),
                            userId: userInfo.get('id'),
                            password: this.model.get('newPassword'),
                        });
                    } else if (self.showSuccessMsg) {
                        $modal.find('.passwordfields, .changeBtn, .cancelBtn').hide();
                        $modal.find('.closeBtn').removeClass('hidden').show();

                        self.alertRegion.show(alert.success(locale.get('common.change.successful')));
                    } else {
                        dialog.close();
                    }
                },

                error(result) {
                    self.alertRegion.show(alert.danger(result.error.responseHeader.message[0]));
                },
            },
        );
    },

    cancel() {
        dialog.close();
    },
});

export default ChangeView;
