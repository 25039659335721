import ItemView from '@glu/core/src/itemView';
import savedViewsTmpl from './savedViews.hbs';

export default ItemView.extend({
    initialize() {
        this.model.on('change', this.render, this);
        this.model.on('sync', this.handleInitialViewSelect, this);
    },

    template: savedViewsTmpl,
    selectedView: false,

    ui: {
        viewSelect: 'select[data-control=viewSelect]',
    },

    events: {
        'change @ui.viewSelect': 'handleViewSelect',
    },

    /**
     * Fire a selection event so the grid can refresh.
     */
    handleInitialViewSelect() {
        // Set the view to the first key in the returned data.
        this.selectedView = this.model.attributes.savedViews.views[0].key;
        this.trigger('select', this.selectedView);
    },

    /**
     * Fire a selection event so the grid can refresh.
     */
    handleViewSelect(e) {
        // Set the view to the selected value in the dropdown.
        this.selectedView = this.ui.viewSelect.val();
        this.trigger('select', this.selectedView);
        return e;
    },
});
