var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"grid-manage-column-menu-trigger\" >\n    "
    + alias3((lookupProperty(helpers,"customizedIcon")||(depth0 && lookupProperty(depth0,"customizedIcon"))||alias2).call(alias1,{"name":"customizedIcon","hash":{"locale":"menu.toggle.filter","name":"gear"},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":62}}}))
    + "\n</button>\n\n<div id=\"region-manage-columns-menu-"
    + alias3(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":43}}}) : helper)))
    + "\"></div>";
},"useData":true});