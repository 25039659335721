import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import LocationModel from 'app/lockbox/models/locations';
import scroll from 'common/util/scroll';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import addLocationTmpl from './addLocation.hbs';

export default Layout.extend({
    template: addLocationTmpl,

    initialize(options) {
        this.model = new LocationModel(options.model);
        this.isFirst = options.isFirst;
        this.viewType = options.viewType || null;
        if (options.title) {
            this.model.set({
                id: options.id,
            });
            this.dialogTitle = `${locale.get('RTGS.action.modify')} ${options.title}`;
            this.dialogButtons = [{
                text: locale.get('LBX.ModifyLocation'),
                className: 'btn btn-primary',
                callback: 'submitLocation',
            }, {
                text: locale.get('button.cancel'),
                className: 'btn btn-primary',
                callback: 'cancelLocation',
            }];
        }
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
    },

    renderMessage(model) {
        const [message] = model.error.message;
        const confirms = new Confirms({
            confirms: model.error.confirms,
        });

        this.alertView = alert.danger(
            message,
            {
                details: confirms,
                canDismiss: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    /**
     * @method getExistingLocationCodes
     * - This method collects all existing location codes from the collection,
     * checks if entered code is found
     * and sets entered code as a pre-existing entry to alert the user within the
     * field validation
     */
    getExistingLocationCodes() {
        const enteredCodeAlreadyExists = util.chain(this.collection.toJSON())
            .pluck('LOCATION_CODE')
            .contains(this.model.get('LOCATION_CODE'))
            .value();

        if (enteredCodeAlreadyExists) {
            this.model.setCodeAsExisting();
        }
    },

    /**
     * @method submitLocation
     * - save handler for lockbox location
     */
    submitLocation() {
        const self = this;
        if (this.collection) {
            this.getExistingLocationCodes();
        }
        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }
        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    self.trigger('location:action:success', confirmResponse);
                },

                error(model) {
                    if (self.isFirst) {
                        self.renderMessage(model);
                    } else if (self.viewType === 'modal') {
                        self.renderMessage(model);
                    } else {
                        self.trigger('location:action:error', model);
                    }
                },
            },
        );
    },

    cancelLocation() {
        this.trigger('location:action:cancel');
    },

    templateHelpers() {
        const self = this;
        return {
            isFirstLocation() {
                return (self.isFirst);
            },

            isModal() {
                return (self.viewType === 'modal');
            },
        };
    },
});
