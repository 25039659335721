import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import template from './accountView.hbs';

export default ItemView.extend({
    template,

    events: {
        'click input': 'toggleSelected',
    },

    templateHelpers() {
        return {
            hx: 'h4',
            name: this.getAccountName(),
            accountNumber: this.getAccountNumber(),
            bankCode: this.model.get('BANKCODE'),
            currencyCode: this.model.get('CURRENCYCODE'),
        };
    },

    getAccountName() {
        return util.unescape(util.isEmpty(this.model.get('CLIENTACCOUNTNAME')) ? this.model.get('ACCOUNTNAME') : this.model.get('CLIENTACCOUNTNAME'));
    },

    getAccountNumber() {
        if (this.model.get('ACCOUNTNUM')) { return util.isEmpty(this.model.get('ACCOUNTNUM_DISP')) ? this.model.get('ACCOUNTNUM') : this.model.get('ACCOUNTNUM_DISP'); }

        return util.isEmpty(this.model.get('ACCOUNTNUMBER_DISP')) ? this.model.get('ACCOUNTNUMBER') : this.model.get('ACCOUNTNUMBER_DISP');
    },
});
