import util from '@glu/core/src/util';
import dateUtil from 'common/util/dateUtil';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const { formView } = form;
    const formState = formView.state;
    const { model } = formView;
    let balanceSelector = '';
    const enteredTimeStampField = form.field('ENTERED_TIMESTAMP');
    let offset = '';
    const paymentType = (model.jsonData.typeInfo) ? model.jsonData.typeInfo.typeCode : '';

    if (initialState) {
        if (formView) {
            if (model.get('OFFSETACCOUNTNUM') !== '') {
                offset = model.get('OFFSETACCOUNTNUM');
            }
            formView.listenTo(form.formView.appBus, 'combo-collections-updated', (keys) => {
                util.each(keys, (key) => {
                    if (key === 'OFFSETACCOUNTNUM') {
                        rtgsCommon.getAccountBalances(
                            form.formView,
                            'OFFSETACCOUNTNUM',
                            'USACH',
                            paymentType,
                            'BankCode',
                            'ORIGCURRENCYCODE',
                            null,
                        );
                        formView.preFill.OFFSETACCOUNTNUM = (offset === '') ? model.get('OFFSETACCOUNTNUMTEMP') : offset;
                    }
                    if (key === 'RETURNS_ACCOUNT_TRANS') {
                        formView.childView.preFill.RETURNS_ACCOUNT_TRANS = model.get('OFFSETACCOUNTNUMTEMP');
                    }
                });
            });
        }

        if (formState === 'select') {
            balanceSelector = 'div#OFFSETACCOUNTNUM strong';
        }

        if (formState !== 'insert') {
            rtgsCommon.getAccountBalances(form.formView, 'OFFSETACCOUNTNUM', 'USACH', paymentType, 'BankCode', 'ORIGCURRENCYCODE', balanceSelector);
        }

        if (model.jsonData.typeInfo.functionCode === 'BATCH') {
            if (model.get('ENTRYMETHOD') === '1') {
                form.field('OFFSETACCOUNTNUM').shouldBeReadOnly(true);
                form.field('TRANS_CODE_1').shouldBeReadOnly(true);
            }
        }

        /*
         * Copy the values from the Default section of the header to beneficiary if
         * the corresponding value in the beneficiary is blank.
         */
        model.on('change:TRANS_CODE_1', () => {
            const { childView } = formView;
            if (childView && childView.model) {
                if (childView.model.get('TRANCODELIVE') === '') {
                    childView.model.set('TRANCODELIVE', model.get('TRANS_CODE_1'));
                }
            }
        });

        model.on('change:OFFSETACCOUNTNUM', () => {
            const { childView } = formView;
            if (childView && childView.model) {
                if (childView.model.get('RETURNS_ACCOUNT_TRANS') === '') {
                    childView.model.set('RETURNS_ACCOUNT_TRANS', model.get('OFFSETACCOUNTNUM'));
                }
            }
        });

        model.on('change:COMPIDNAME', () => {
            const { childView } = formView;
            if (childView && childView.model) {
                if (childView.model.get('ORIGCOMPFULLNAME') === '') {
                    childView.model.set('ORIGCOMPFULLNAME', model.get('ORIGCOMPFULLNAMETEMP'));
                }
                if (childView.model.get('ORIGCOMPNAME_TRANS') === '') {
                    childView.model.set('ORIGCOMPNAME_TRANS', model.get('ORIGCOMPNAME'));
                }
            }
            if (formView.batchChildGridView) {
                const bulkRequest = {
                    rows: null,
                    action: 'bulkUpdate',
                    actionType: 'SETORIGINATOR',
                    strValue: model.get('ORIGCOMPNAME'),
                    originatorValue: model.get('ORIGCOMPFULLNAMETEMP'),
                };
                formView.batchChildGridView.makeBulkUpdateRequest(bulkRequest);
            }
        });

        model.on('change:DESTCURRENCYCODE', () => {
            const { childView } = formView;
            if (childView && childView.model) {
                if (childView.model.get('DESTCURRENCYCODE') === '') {
                    childView.model.set('DESTCURRENCYCODE', model.get('DESTCURRENCYCODE'));
                }
            }
        });

        if (formState === 'insert') {
            // Hide the audit section
            enteredTimeStampField.$el.closest('.section').hide();
        }

        /*
         * The dates in the calendar are not enabled / disabled correctly on load
         * of the page.
         */
        if (model.jsonData.typeInfo.functionCode === 'BATCH' && formState !== 'view') {
            const postData = {
                paymentType: model.jsonData.typeInfo.typeCode,
                debitBank: model.get('BANKCODE'),
                debitCurrency: model.get('ORIGCURRENCYCODE'),
                debitBankCountry: model.get('ORIGCOUNTRY'),
                subType: model.jsonData.subtype,
            };
            dateUtil.refreshEffectiveDateCalendar(form, 'EFFECTIVEDATE', postData);
        }
    }
}
