import IframeView from 'app/administration/views/internalLink/iframeView';
import contextApi from 'common/dynamicPages/api/context';
import store from '@glu/store';
import util from '@glu/core/src/util';
import BaseController from 'system/baseController';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import http from '@glu/core/src/http';
import services from 'services';

/**
 * This file contains the methods that are used for defining the controller
 * that is used by the App Router when the dynamic links routes are added at run time
 */
const retObj = {
    /**
     * @method getDynamicLinkUrl
     * @param {Object} dynamicLinkMenuItem - Object for Menu Item
     *
     * Dynamic Links can append current client side user information.
     * The page session currently stores a copy of the UserInfo object
     *
     */
    getDynamicLinkUrl(dynamicLinkMenuItem) {
        let url = dynamicLinkMenuItem.serviceName;
        let initDelimNotFound = (url.indexOf('?') === -1);
        let userInfo;
        let value;

        const infoValues = dynamicLinkMenuItem.dynamicLinksUserInfoValues;

        util.each(infoValues, (fieldName) => {
            let urlProp = fieldName;
            if (!userInfo) {
                userInfo = store.get('userInfo');
            }
            value = userInfo[fieldName];
            if (value) {
                // do we have an alias for the name that we should use ?
                if (Object.prototype.hasOwnProperty.call(infoValues, fieldName)) {
                    urlProp = infoValues[fieldName];
                }
                if (initDelimNotFound) {
                    url += '?';
                    initDelimNotFound = false;
                } else {
                    url += '&';
                }
                url += `${urlProp}=${value}`;
            }
        });
        return url;
    },

    /**
     * @method externalLink
     * @param {string} menuCode - the designated menu code for the
     * executing dynamic link by way of this 'externalLink' function
     *
     * The dynamic link will be displayed in a separate tab in the browser
     */
    externalLink(menuCode) {
        const menuItem = contextApi.menuContext.getContext(menuCode);
        const url = this.getDynamicLinkUrl(menuItem);

        const externalWindow = window.open(url, 'newWindow');
        if (externalWindow) {
            externalWindow.focus();
        }
    },

    /**
     * @method externalLinkNewWindow
     * @param {string} menuCode - the designated menu code for the
     * executing dynamic link by way of this 'externalLink' function
     *
     * The dynamic link will be displayed in a separate new window
     */
    externalLinkNewWindow(menuCode) {
        const menuItem = contextApi.menuContext.getContext(menuCode);
        const width = serverConfigParams.get('dynamicLinkNewWindowWidth') ? serverConfigParams.get('dynamicLinkNewWindowWidth') : 640;
        const height = serverConfigParams.get('dynamicLinkNewWindowHeight') ? serverConfigParams.get('dynamicLinkNewWindowHeight') : 480;
        const strWindowFeatures = `width=${width},height=${height},scrollbars=1`;
        const externalWindow = window.open(this.getDynamicLinkUrl(menuItem), 'newWindow', strWindowFeatures);

        this.invokeAuditService('/dynamicLinkAudit/audit', menuItem);

        if (externalWindow) {
            externalWindow.focus();
        }
    },

    /**
     * @method invokeAuditService
     * @param {string} servicePath - the auditing service path
     * @param {string} menuItem - the designated menu item for the executing
     * dynamic link
     *
     * The audit service will be invoked for the dynamic link.
     */
    invokeAuditService(servicePath, menuItem) {
        const requestData = {
            productCode: menuItem.productCode,
            functionCode: menuItem.functionCode,
            typeCode: menuItem.typeCode,
        };
        http.post(services.generateUrl(servicePath), requestData);
    },

    /**
     * @method internalLink
     * @param {string} menuCode - the designated menu code for the
     * executing dynamic link by way of this 'externalLink' function
     *
     * The dynamic link will be displayed within the application in an iFrame
     */
    internalLink(menuCode) {
        const menuItem = contextApi.menuContext.getContext(menuCode);
        const helperController = new BaseController();

        this.invokeAuditService('/dynamicLinkAudit/audit', menuItem);

        helperController.showPage('Internal Link', new IframeView({
            iframeSrc: this.getDynamicLinkUrl(menuItem),
        }));
    },

    /**
     * @method internalLinkReplace
     * @param {string} menuCode - the designated menu code for the
     * executing dynamic link by way of this 'externalLink' function
     *
     * The dynamic link will be displayed in the current window replacing the
     * current content displayed in the browser
     */
    internalLinkReplace(menuCode) {
        const menuItem = contextApi.menuContext.getContext(menuCode);
        window.location.href = this.getDynamicLinkUrl(menuItem);
    },
};
export default retObj;
