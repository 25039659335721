import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import PaymentUtil from 'common/util/paymentUtil';

// Set the default option on a comboBox.
function setIfOnlyOption($select, flag) {
    const $options = $select.find('option');
    // if there is only one option in addition to the blank one, select it by default
    const isReadOnly = $options.length === 2;
    if (isReadOnly) {
        $select.comboBox('val', $options.last().attr('value'), flag);
    }

    return isReadOnly;
}

export default function (form, initialState) {
    const $tranTypeSelect = $('select[name=TRANTYPE]');
    const $currencyCode = $('select[name=DESTCURRENCYCODE]');

    const paymentFromTemplateProtectedFields = [
        'RECEIVCOMPNAME',
        'RECEIVCOMPID',
        'RECEIVADDRESS',
        'RECEIVCITY',
        'RECEIVSTATE',
        'RECEIVPROVINCE',
        'RECEIVPOSTALCODE',
        'RECEIVCOUNTRYCODE',
        'BANKACCOUNTORIBAN',
        'RECEIVBANKTYPE',
        'RECEIVRBANKCODE',
        'ACCOUNTNUMBER',
        'ACCOUNTTYPE',
        'TRANTYPE',
        'TRANSACTIONTYPECODE',
        'REMITTANCE1',
        'REMITTANCE2',
        'CORRBANKQUALIFIER1',
        'CORRCODE1',
        'CORRBANKNAME1',
        'CORRADDRESS1A',
        'CORRADDRESS1B',
        'CORRCOUNTRY1',
    ];

    const { model } = form.formView;
    const view = form.formView;
    const formState = form.formView.state;
    const corrDelete = form.field('CORRDELETE1');
    const beneCountry = form.field('RECEIVCOUNTRYCODE');
    const accountNumber = form.field('ACCOUNTNUMBER');
    const beneState = form.field('RECEIVSTATE');
    const beneProvince = form.field('RECEIVPROVINCE');
    const receiveBankType = form.field('RECEIVBANKTYPE');

    const protectFields = function (array) {
        let tempValue = '';
        let displayValue;
        let tempFieldData;
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnly(true);

            tempFieldData = model.fieldData[fieldName];

            if (formState !== 'view' && tempFieldData && (tempFieldData.fieldUIType === 'COMBOSELECT' || tempFieldData.fieldUIType === 'COMBOBOX') && tempFieldData.protected) {
                if (tempFieldData.fieldUIType === 'COMBOBOX') {
                    tempValue = util.findWhere(view.comboCollections[fieldName], {
                        name: model.get(fieldName),
                    });
                    if (tempValue) {
                        displayValue = tempValue.label;
                    }
                } else {
                    tempValue = util.findWhere(tempFieldData.choices, {
                        name: model.get(fieldName),
                    });
                    if (tempValue) {
                        displayValue = tempValue.value;
                    }
                }
                if (displayValue) {
                    view.$(`#${fieldName} >p >span`).text(displayValue);
                }
            }
        });
    };

    /**
     * @method validateIBANCountryCode
     * This method validates if the destination country code matches the country code of the
     * IBAN and warn the user
     * if they does not match.
     */
    const validateIBANCountryCode = function () {
        if (model.get('ACCOUNTNUMBER') && model.get('BANKACCOUNTORIBAN') === '03') {
            if (model.get('ACCOUNTNUMBER').length < 2 || model.get('ACCOUNTNUMBER').substring(0, 2) !== view.parentModel.get('DESTCOUNTRYCODE')) {
                dialog.confirm(locale.get('ACH.Warning.CountryInValid'), locale.get('ACH.Warning'), (ok) => {
                    if (!ok) {
                        model.set('ACCOUNTNUMBER', '');
                    }
                });
            }
        }
    };

    if (initialState) {
        $tranTypeSelect.on('change', () => {
            const isReadOnly = setIfOnlyOption($tranTypeSelect, false);
            $tranTypeSelect.comboBox('readonly', isReadOnly);
        });

        if (form.formView.parentModel && form.formView.parentModel.get('ENTRYMETHOD') === '1') {
            protectFields(paymentFromTemplateProtectedFields);
            $('[data-field="BENE_NAME_LOOKUP"]').addClass('hidden');
        }

        // Add the delete icon x to the correspondent delete button
        view.$('#CORRDELETE1 > span').addClass('icon-close');

        /*
         * Per the new markup of payment redesign, the state and province field will
         * be displayed as mandatory field,
         * so add the mandatory field sign for these two fields here, usually this
         * sign is generated based on the
         * mandatoryfields table configuration, this cannot be done here because its
         * mandatory requirement is
         * based on the country, configure them in mandatoryfields table would fail
         * the form field validation service.
         * only one field will be displayed depends on the country selection and
         * decided later in the policy.
         */
        view.$('[name="RECEIVSTATE"]').parent().addClass('required');
        view.$('[name="RECEIVPROVINCE"]').parent().addClass('required');

        // Add the handler for bene country change
        beneCountry.$el.on('change', function () {
            /*
             * for country US, clear the province value previously entered, otherwise, clear the
             * state value previously entered
             */
            if (this.value === 'US') {
                model.set('RECEIVPROVINCE', '');
            } else {
                model.set('RECEIVSTATE', '');
            }
        });

        model.on('change:BANKACCOUNTORIBAN', () => {
            if (model.get('BANKACCOUNTORIBAN') === '03') {
                model.set('RECEIVBANKTYPE', 'SWIFT');
                receiveBankType.shouldBeReadOnly(true);
            } else {
                receiveBankType.shouldBeReadOnly(false);
            }

            validateIBANCountryCode();
        });
        /*
         * / NH-101788 when the RECEIVBANKTYPE is changed, all the information regarding
         * the bank code should be cleared.
         */
        model.on('change:RECEIVBANKTYPE', () => {
            /*
             * If RECEIVRBANKCODE has also already been changed then we do not want to clear
             * out the new value. An example of this is when the user selects a new
             * beneficiary. Doing this sets a new RECEIVBANKTYPE and RECEIVRBANKCODE value at
             * the same time. Clearing out RECEIVRBANKCODE is only necessary when the user
             * manually changes the RECEIVBANKTYPE, making the RECEIVRBANKCODE no longer valid.
             */
            if (model.changed.RECEIVRBANKCODE) {
                return;
            }

            model.set('RECEIVRBANKCODE', '');
            view.trigger('lookupHelperText:clear', 'RECEIVRBANKCODE');
        });

        if (model.get('BANKACCOUNTORIBAN') === '') {
            model.set('BANKACCOUNTORIBAN', '01');
        }
        if (model.get('BANKACCOUNTORIBAN') === '03') {
            model.set('RECEIVBANKTYPE', 'SWIFT');
            receiveBankType.shouldBeReadOnly(true);
        }

        accountNumber.$el.on('change', () => {
            validateIBANCountryCode();
        });
    }

    // Setup Currency Code
    const isReadOnly = setIfOnlyOption($currencyCode, true);
    $currencyCode.comboBox('enable', !isReadOnly);

    /*
     * Corresponding Bank Logic
     * Potentially we'll support user input up to 5 correspondent banks,
     * but for now, only one correspondent bank is supported, all the correspondent
     * bank related fields start with "CORR" and ends with its index in general:
     * CORRBANKQUALIFIER1 - correspondent bank code type
     * CORRCODE1 - correspondent bank bank code
     * CORRBANKNAME1 - correspondent bank name
     * CORRADDRESS1A - correspondent bank address line 1
     * CORRADDRESS1B - correspondent bank address line 2
     * CORRCOUNTRY1 - correspondent bank country
     * CORRDELETE1 - delete button of the correspondent bank
     */

    /*
     * if there's input in either the bank code type or bank code field, a delete button needs
     * to be displayed for user to click to clear his input
     */
    if (form.field('CORRBANKQUALIFIER1').isNotEmpty() || form.field('CORRCODE1').isNotEmpty()) {
        corrDelete.shouldBeVisibleWhen(true);
    } else {
        corrDelete.shouldBeVisibleWhen(false);
    }

    /*
     * the delete button will clear the user input of the correspondent bank as well as the
     * helper text displayed under the bank code field
     */
    form.field('CORRDELETE1').$el.on('click', () => {
        // Clear the Corresponding Bank fields from the form data
        model.set({
            CORRBANKQUALIFIER1: '',
            CORRCODE1: ' ',
            CORRBANKNAME1: '',
            CORRADDRESS1A: '',
            CORRADDRESS1B: '',
            CORRCOUNTRY1: '',
        });
        // Wipe out the related helper text on the UI
        view.trigger('lookupHelperText:clear', 'CORRCODE1');
    });

    /*
     * Beneficiary state/province display depends on the country user selected,
     * for US, display the state drop down, otherwise, display province text box
     */
    if (formState !== 'view') {
        if (beneCountry.getValue() === 'US') {
            beneState.shouldBeVisibleWhen(true).shouldBeRequiredWhen(true);
            beneProvince.shouldBeVisibleWhen(false).shouldBeRequiredWhen(false);
        } else {
            beneState.shouldBeVisibleWhen(false).shouldBeRequiredWhen(false);
            beneProvince.shouldBeVisibleWhen(true).shouldBeRequiredWhen(true);
        }
    }

    // handle enabling and disabling of lock icon based on selection of hold flag checkbox
    PaymentUtil.toggleCheckboxLock($('[name="HOLDFLAG"]'));
}
