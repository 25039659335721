import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    defaults: {
        LOCATION_CODE: '',
        LOCATION_DESC: '',
    },

    initialize() {
        this.validators = {
            LOCATION_DESC: {
                description: locale.get('lockbox.location.desc'),
                exists: true,
            },

            LOCATION_CODE: {
                description: locale.get('lockbox.location.code'),
                exists: true,
                isUnique: true,
            },
        };
    },

    /**
     * @method setCodeAsExisting
     * - Sets the location code as an existing code to trigger an error on the
     * isUnique validator
     */
    setCodeAsExisting() {
        this.set('EXISTING_LOCATION', this.get('LOCATION_CODE'));
    },

    sync(method, model, options) {
        let url;
        let data;

        if (method === 'create') {
            url = services.generateUrl('lockbox/location/add');

            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'update') {
            url = services.generateUrl('lockbox/location/update');

            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            data = this.convertModelAttributesToServerJSON(model);
            url = services.generateUrl('lockbox/location/delete');

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    convertModelAttributesToServerJSON(model) {
        const jsonData = [];

        util.each(model.toJSON(), (value, key) => {
            if (key !== 'EXISTING_LOCATION') {
                jsonData.push({
                    name: key,
                    value,
                });
            }
        });

        return {
            item: jsonData,
        };
    },
});
