import template from './gridUtilitySection.hbs';

export default {
    /**
     * Based on the options, render a list of icons with the specified attributes
     * @param {Object} options - configuration options for this helper
     * @returns {string} - handlebars template with data buttons inserted
     */
    gridUtilitySection(options) {
        const { customIcons = [], hasRefresh = true, includeDefaultIcons = true } = options;
        const defaultIcons = [{
            dataHook: 'print-button',
            icon: 'icon-print',
            ariaLabel: 'common.printOptions.button.print',
            title: 'common.printOptions.button.print',
        }, {
            dataHook: 'export-button',
            icon: 'icon-export',
            ariaLabel: 'common.export',
            title: 'common.export',
        }];
        const icons = [...customIcons, ...(includeDefaultIcons ? defaultIcons : [])];

        return template({
            actionIcons: icons,
            hasRefresh,
        });
    },
};
