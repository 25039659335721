import AccountView from 'app/accounts/views/accountView';
import util from '@glu/core/src/util';

const GroupedAccountView = AccountView.extend({

    templateHelpers(...args) {
        const base = AccountView.prototype.templateHelpers.apply(this, args);
        return util.extend(base, {
            hx: 'h5',
            hasCheckbox: false,
            hasDeleteButton: true,
            showGroupCount: false,
        });
    },

    events: {
        'click [action=delete]': 'delete',
    },

    delete() {
        this.model.collection.remove(this.model);
    },
});

export default GroupedAccountView;
