import { ItemView } from 'backbone.marionette';
import constants from 'app/administration/constants';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import tpl from './checkGridHeader.hbs';

export default ItemView.extend({
    template: tpl,
    entityType: null,
    singleType: false,
    // Not permitting any sort of this table, only filtering.
    initDefaultSort: util.noop,
    initialize(options) {
        this.collection = options.collection;
        this.rows = this.generateRows();
        this.grid = options.grid;

        /*
         * Communicate up that the header exists
         * Let the table builder finish assignments, etc. first.
         */
        util.defer(() => {
            this.grid.trigger('headerInit');
        });
    },
    generateRows() {
        return util.values(this.collection.reduce((acc, col) => {
            const accum = acc;
            const group = col.get('group');
            if (col.get('type') !== undefined) {
                return accum;
            }

            // Generate the groupings as we cycle over the columns
            if (accum[group] === undefined) {
                accum[group] = {
                    groupName: group,
                    colspan: 1,
                    members: [{
                        field: col.get('field'),
                        label: col.get('label'),
                    }],
                };
            } else {
                accum[group].colspan += 1;
                accum[group].members.push({
                    field: col.get('field'),
                    label: col.get('label'),
                });
            }
            return accum;
        }, {}));
    },

    /**
     * Used by templateHelpers
     * @param {Object} col
     * @return {boolean}
     */
    isColChecked(col) {
        return col.currentFuture || (!col.mixedChangeSet && col.allSet && col.exclusiveSet);
    },

    templateHelpers() {
        // This self is needed. "self" is this file and the template context is "this"
        const self = this;

        return {
            cid: this.cid,
            cfHeaderVisible() {
                return util.some(self.rows, (row) => {
                    if (util.isUndefined(row.groupName)) {
                        return false;
                    }
                    return util.some(row.members, ({ field }) => self.getProxyColumn(field).currentFuture !== '');
                });
            },

            cfChecked() {
                if (self.getProxyColumn) {
                    const col = self.getProxyColumn(this.field);

                    if (col.currentFuture) {
                        return 'checked';
                    }
                }
                return '';
            },

            cfDisabled() {
                if (self.getProxyColumn) {
                    const col = self.getProxyColumn(this.field);
                    if (col.currentFuture === '') {
                        return 'disabled';
                    }
                }
                return '';
            },

            allChecked() {
                const col = self.getProxyColumn(this.field);
                return `${col.currentFuture ? 'disabled ' : ''}${self.isColChecked(col) ? 'checked' : ''}`;
            },

            /**
             * Tests if all columns in the group are checked and checks this if so.
             * @return {String}
             */
            groupChecked() {
                // Cycle over the group children to see if they are checked.
                const { groupName } = this;
                const group = util.find(self.rows, row => row.groupName === groupName);

                if (!groupName || group === undefined) {
                    return '';
                }

                // Cycle over every member to see if they are all checked
                const all = util.every(
                    group.members,
                    ({ field }) => (self.isColChecked(self.getProxyColumn(field)) || false),
                );

                return all ? 'checked' : '';
            },

            /**
             * Tests if all columns in the group are set to Current/Future
             * and disables this group if so.
             * @return {String}
             */
            groupDisabled() {
                // Cycle over the group children to see if they are checked.
                const { groupName } = this;
                const group = util.find(self.rows, row => row.groupName === groupName);

                if (!groupName || group === undefined) {
                    return '';
                }

                // Cycle over every member to see if they are all checked
                const all = util.every(
                    group.members,
                    ({ field }) => (self.getProxyColumn(field).currentFuture || false),
                );

                return all ? 'disabled ' : '';
            },

            rows() {
                return self.rows.map(row => util.extend(row, {
                    memberList: row.members.map(member => member.field).join(','),
                }));
            },
            readOnly: this.grid.mode === constants.MODES.VIEW,
            singleType: self.singleType,
            currentFutureLabel: locale.get(`uce.currentfuturelabel.${self.entityType}`),
            gridRowHeader: locale.get(`uce.${self.entityType}`),
        };
    },
});
