import { appBus } from '@glu/core';
import util from 'underscore';
import store from 'system/utilities/cache';
import { isEntitled, getErrorView } from 'common/util/deeplinkUtil';
import { getMaskingUITypes } from 'common/util/maskingUtil';

/**
 * Builds an options object from the provided jsonField
 * @param  {Object} jsonField  the JSON data for the provided field
 * @return {Object}  a configuration object used to display this field
 */
const buildOptions = jsonField => ({
    fieldName: jsonField.name,
    fieldLabel: jsonField.fieldLabel,
    mandatory: jsonField.mandatory,
    cssClass: jsonField.cssClass,
    blockClass: jsonField.blockClass || '',
    info: jsonField.info,
    lockable: jsonField.lockable,
    lockedByDefault: jsonField.lockedByDefault,
    locked: jsonField.locked,
    isTemplate: jsonField.isTemplate,
    placeHolder: jsonField.placeHolder,
});

/**
 * @param {function} template
 * @param {object} jsonField
 * @param {object} [opts] - options to pass;
 * @return {*}
 */
const callTemplate = function (template, jsonField, opts) {
    const options = opts || buildOptions(jsonField);
    if (jsonField && jsonField.fieldUiToolTip) {
        options.fieldUiToolTip = jsonField.fieldUiToolTip;
    }
    return template(options);
};

const findOperation = function (view, jsonField, operatorOverride) {
    const operators = view.operators();
    const op = operatorOverride || view.model.get(`${jsonField.name}-equality`);
    const operation = util.findWhere(
        operators,
        {
            operator: op,
        },
    );
    return operation.title;
};

const camelize = s => s.toLowerCase().replace(/(_\w)/g, m => m[1].toUpperCase());

const getMultiComboValues = (view, jsonField) => {
    let rawValues = view.model.get(jsonField.name);
    if (!rawValues) {
        return '';
    }
    const comboValues = view.comboCollections[jsonField.name];
    if (comboValues.length === 0) {
        return '';
    }
    if (!util.isArray(rawValues)) {
        rawValues = [rawValues];
    }
    const labels = rawValues.map((rawValue) => {
        const value = comboValues.find(v => v.name === rawValue);
        return value ? value.label : '';
    });

    return labels.join(', ');
};

/**
 * @name triggerMaskedInputChanged
 * @description triggers a 'mapData:change' event on the model
 * for all maskedInputWidget fields.
 * @param {Model} model
 * @param {Object} mapDataList - field data
 */
const triggerMaskedInputChanged = (model, mapDataList = []) => {
    const MASKEDTYPES = getMaskingUITypes();
    const { fieldData } = model;
    const mdList = model.mapDataList || mapDataList;
    let maskedInputs = Object.values(fieldData)
        .filter(v => MASKEDTYPES.includes(v.fieldUIType))
        .map(i => i.name);
    if (mdList.length) {
        const mdFields = mdList.map(md => md.toField);
        maskedInputs = maskedInputs.filter(x => mdFields.includes(x));
    }
    maskedInputs.forEach((maskedField) => {
        const evt = model.cid ? `mapData:changed:${maskedField}-${model.cid}` : `mapData:changed:${maskedField}`;
        appBus.trigger(evt, model.get(maskedField));
    });
};

const processDeeplink = (url, thisObj) => {
    /*
     * Remove any stored workspace routes previously stored. The user may be
     * deep linking
     * to a widget after a previous deeplink to a workspace
     * validate that we are processing a deeplink
     */
    if (window.parent !== window && store.has('current-workspace-route')) {
        store.remove('current-workspace-route');
    }
    if (isEntitled(url)) {
        return true;
    }

    thisObj.showPage.call(thisObj, '', getErrorView());
    return false;
};

export {
    buildOptions,
    callTemplate,
    findOperation,
    camelize,
    getMultiComboValues,
    triggerMaskedInputChanged,
    processDeeplink,
};
