import { createUseStyles } from '@glu/theming';

const styles = ({ fileUploadReact }) => ({
  root: {
    minWidth: 200,
    display: 'flex'
  },
  input: {
    margin: 0
  },
  extension: {
    lineHeight: '32px',
    fontFamily: fileUploadReact.bodyFont,
    fontSize: 14,
    margin: [0, 0, 0, 8]
  }
});

export default createUseStyles(styles);
