import CompositeView from '@glu/core/src/compositeView';
import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import TransferLayout from 'app/smbPayments/views/accounts/balances/transfer/transferLayout';
import AccountView from './accountSelectItem';
import accountSelectTmpl from './accountSelect.hbs';

const AccountSelect = CompositeView.extend({
    template: accountSelectTmpl,
    itemViewContainer: 'tbody',
    itemView: AccountView,

    attributes: {
        'aria-controls': '#transactions-graph',
    },

    className: 'show-view-mode',

    ui: {
        $transferBtn: '.btn[data-action="account-transfer"]',
        $editThresholdBtn: '.btn[data-action="edit-thresholds"]',
        $saveBtn: '.btn[data-action="save-edit"]',
        $cancelBtn: '.btn[data-action="cancel-edit"]',
    },

    events: {
        'click @ui.$transferBtn': 'openAccountTransferDialog',
        'click @ui.$editThresholdBtn': 'toggleEditMode',
        'click @ui.$saveBtn': 'saveChanges',
        'click @ui.$cancelBtn': 'cancelChanges',
    },

    collectionEvents: {
        reset: 'render',
    },

    initialize(options) {
        this.editMode = false;
        this.hasTransferEntitlement = options.hasTransferEntitlement;
    },

    toggleEditMode(value) {
        let localValue = value;
        if (!util.isBoolean(localValue)) {
            localValue = !this.editMode;
        }
        this.editMode = localValue;

        this.$el.toggleClass('show-edit-mode', this.editMode);
        this.$el.toggleClass('show-view-mode', !this.editMode);

        this.$('input[type="checkbox"]').prop('disabled', this.editMode);
    },

    openAccountTransferDialog() {
        Dialog.open(new TransferLayout({
            // send in the first one since it doesn't matter anyway.
            tileModel: this.collection.first(),
        }));
    },

    saveChanges() {
        this.collection.each((model) => {
            model.trigger('saveChangesFromEditMode');
        });
        this.toggleEditMode(false);
    },

    cancelChanges() {
        this.toggleEditMode(false);
        // reset form input values
        this.render();
    },

    templateHelpers() {
        return {
            hasTransferEntitlement: this.hasTransferEntitlement,
        };
    },
});

export default AccountSelect;
