export default function (form) {
    const formState = form.formView.state;
    const bankType = form.field('BANK_TYPE');
    const beneBankId = form.field('BENE_BANK_ID');

    if (formState === 'modify') {
        bankType.shouldBeReadOnly(true);
        beneBankId.shouldBeReadOnly(true);
    }
}
