import ItemView from '@glu/core/src/itemView';
import $ from 'jquery';
import constants from 'app/administration/constants';
import util from '@glu/core/src/util';
import locale from 'system/webseries/api/locale';
import entitlementViewTmpl from './entitlementView.hbs';

export default ItemView.extend({
    template: entitlementViewTmpl,
    tagName: 'fieldset',

    ui: {
        $limitTypeCheckboxes: '.limit-type-entitlements .checkbox-inline input[type=checkbox]',
        $approvalSignatureSelect: '.approval-signatures',
        $approveOwnCheckbox: '.approve-own-checkbox',
        $autoApproveCheckbox: '.auto-approve-checkbox',
    },

    events: {
        'change @ui.$approvalSignatureSelect': 'changeSignatureHandler',
        'change @ui.$approveOwnCheckbox': 'changeApproveOwnHandler',
        'change @ui.$autoApproveCheckbox': 'changeAutoApproveHandler',
        'click @ui.limitTypeCheckboxes': 'selectType',

    },

    initialize(options) {
        this.mode = options.mode;
        this.entitledTypes = options.entitledTypes;
        this.listenTo(this.model, 'change', this.handleActionChanges);
    },

    handleActionChanges(model, o) {
        // not changed by view, ignore
        if (!o.source) {
            return;
        }

        const { name } = o.source[0];
        let value;
        const action = name.split('.')[1];
        const isEntitled = o.source[0].checked;
        const entryMethod = this.model.get('entryMethod');
        const restrictions = {};
        const manageEntitled = this.model.get('actions.manage.entitled');
        const modifyApplicable = this.model.get('actions.modify.applicable');
        const repairApplicable = this.model.get('actions.repair.applicable');
        const checkPaidApplicable = this.model.get('actions.CHCKPAID.entitled');
        const checkStopApplicable = this.model.get('actions.CHCKSTOP.entitled');

        /*
         * For some type of imports, e.g. NACHAPASS, repair and modify are rolled up
         * into manage.
         */
        const modifyEntitled = modifyApplicable ? this.model.get('actions.modify.entitled') : manageEntitled;

        const repairEntitled = repairApplicable ? this.model.get('actions.repair.entitled') : manageEntitled;
        const hasNoImportUpdateEntitlements = !manageEntitled
            && !modifyEntitled && !repairEntitled;
        const approveEntitled = this.model.get('actions.approve.entitled');

        /*
         * Manually set value, since checkboxes data-bind unchecked to undefined
         * instead of false
         */
        this.model.set(name, isEntitled);

        // If action is approve own or autoapprove set the value equal to entitled
        if (util.contains(['approveown', 'autoapprove'], action)) {
            value = `restrictions.${action}.value`;
            this.model.set(value, isEntitled);
        }
        // check if any other checkboxes are selected. If so, view cannot be uncheckedin
        if (action === constants.MODES.VIEW && !isEntitled && this.model.isEntitled()) {
            this.model.set('actions.view.entitled', true);
            return;
        }

        // undo selection of autoapprove/approveown if approve isn't entitled
        if (util.contains(['approveown', 'autoapprove'], action) && isEntitled && !approveEntitled) {
            this.model.set(name, false);
            return;
        }

        // undo selection of debit/credit if manage, modify or repair isn't entitled
        if (entryMethod === 'imported' && util.contains(['debit', 'credit', 'manage', 'modify', 'repair'], action) && hasNoImportUpdateEntitlements) {
            this.model.set({
                'restrictions.debit.entitled': false,
                'restrictions.credit.entitled': false,
                'restrictions.debit.value': false,
                'restrictions.credit.value': false,
            });
        }

        // undo auto approve if approve own isn't entitled
        if (action === 'autoapprove' && isEntitled && !this.model.get('restrictions.approveown.entitled')) {
            restrictions[name] = false;
            restrictions[value] = false;
            this.model.set(restrictions, false);
        }

        /*
         * remove approveown/auto if manage/modify/repair and
         * entryMethods don't match requirements
         */
        if (approveEntitled && !manageEntitled) {
            this.model.set({
                'restrictions.approveown.entitled': false,
                'restrictions.autoapprove.entitled': false,
                'restrictions.approveown.value': false,
                'restrictions.autoapprove.value': false,
            });
        }
        if (action === 'approve' && !isEntitled) {
            this.model.set({
                'restrictions.approveown.entitled': false,
                'restrictions.autoapprove.entitled': false,
                'restrictions.approveown.value': false,
                'restrictions.autoapprove.value': false,
            });
        }

        // remove auto approve if approve own is deselected
        if (action === 'approveown' && !isEntitled) {
            this.model.set({
                'restrictions.autoapprove.entitled': false,
                'restrictions.autoapprove.value': false,
            });
        }

        // auto select view if other checkboxes are selected
        if (this.model.isActionApplicable(constants.MODES.VIEW)
            && action !== constants.MODES.VIEW && isEntitled) {
            this.model.set('actions.view.entitled', true);
        }

        // Update the signature value if approve is changed
        if (this.model.get('restrictions.signature') && action === 'approve') {
            this.updateSignatureAssignedValue();
        }
        this.selectType(isEntitled);

        this.model.set('actions.SELECT.entitled', !!(checkPaidApplicable || checkStopApplicable));
    },

    /**
     * Manually update the entitle flag from the
     * selected Group
     * @param {boolean} checked
     */
    selectType() {
        /**
         * Check if any actions are selected, Even if one of action is selected ,
         * it should return true
         */
        const isEntitled = Object.values(this.model.get('actions')).some(action => action.entitled);

        this.attributes?.types?.each((type) => {
            type.set('entitled', isEntitled);
        });
        this.options.onChildrenChange?.();
    },

    changeSignatureHandler(e) {
        this.model.setSigNum(parseInt(e.target.value, 10));
    },

    updateSignatureAssignedValue() {
        // set signature value if approve is active and signatures exists
        if (this.model.isActionApplicable('approve')) {
            const numSigSelectedVal = this.model.getSigNum();

            /*
             * Default the number of signatures to 1 if the value is not set yet and
             * signatures is not null
             */
            if (numSigSelectedVal === 0) {
                this.model.setSigNum(1);
            }
        } else {
            // set value back to default
            $(this.ui.$approvalSignatureSelect).find('option:selected').removeAttr('selected');
            this.model.setSigNum(0);
        }
    },

    templateHelpers() {
        const self = this;
        const { model } = this;
        const readOnly = this.mode === constants.MODES.VIEW;

        return {
            readOnly,
            viewApplicable: model.isActionApplicable('view'),
            authorizeApplicable: model.isActionApplicable('authorize'),
            checkPaidApplicable: model.isActionApplicable('CHCKPAID'),
            checkStopApplicable: model.isActionApplicable('CHCKSTOP'),
            updateSettingApplicable: model.isActionApplicable('updset'),
            manageApplicable: model.isActionApplicable('manage'),
            modifyApplicable: model.isActionApplicable('modify'),
            getRateApplicable: model.isActionApplicable('getrate'),
            repairApplicable: model.isActionApplicable('repair'),
            approveApplicable: model.isActionApplicable('approve'),
            reverseApplicable: model.isActionApplicable('reverse'),
            printApplicable: model.isActionApplicable('print'), // PCM
            reprintApplicable: model.isActionApplicable('reprint'), // PCM
            stopCheckApplicable: model.isActionApplicable('stopCheck'), // PCM
            voidCheckApplicable: model.isActionApplicable('voidCheck'), // PCM
            updateCheckApplicable: model.isActionApplicable('updateCheck'), // PCM
            confidentialApplicable: model.isActionApplicable('confidential'),
            approveOwnApplicable: model.isRestrictionApplicable('approveown'),
            autoApproveApplicable: model.isRestrictionApplicable('autoapprove'),
            creditApplicable: model.isRestrictionApplicable('credit'),
            debitApplicable: model.isRestrictionApplicable('debit'),
            hasTransactionType: (model.isRestrictionApplicable('credit') || model.isRestrictionApplicable('debit')) && (util.isUndefined(this.entitledTypes) || util.intersection(this.entitledTypes, ['PASSTHRU', 'BDACHNFI']).length > 0),

            appResource(action) {
                return `uce.${action}`;
            },

            /**
             * Determines if signatures are applicable to the action and show only for payments.
             * @returns {boolean}
             */
            hasSignatures() {
                // Only show signatures for payments.
                return !self.options.hasPanelApproval && util.contains(['INST', 'BATCH'], model.get('functionCode')) && model.isRestrictionApplicable('signature');
            },

            selectedSig() {
                const val = model.get('restrictions.signature.value');
                // if no sig, show nothing, otherwise properly pluralize the localized string
                return val > 0 ? (`${val} ${locale.get(`administration.approval.signature${val > 1 ? '.plural' : ''}`)}:`) : '';
            },

            localeLabel() {
                return locale.get(`entryMethod.${self.model.get('entryMethod')}`);
            },

            signatures() {
                const signatures = self.model.get('restrictions.signature');
                const signaturesArr = [];
                let singPluralSignatures;

                if (signatures) {
                    // Override panel signatures in case they are not 1.
                    const maxSignatures = !self.options.hasPanelApproval
                        ? model.maxSigNum() : 1;

                    for (let i = 1; i <= maxSignatures; i += 1) {
                        if (i === 1) {
                            singPluralSignatures = locale.get('administration.approval.signature');
                        } else {
                            singPluralSignatures = locale.get('administration.approval.signature.plural');
                        }

                        const signatureObj = {
                            label: `${i} ${singPluralSignatures}`,
                            value: i,
                            selected: i === parseInt(model.getSigNum(), 10),
                        };

                        signaturesArr.push(signatureObj);
                    }

                    return signaturesArr;
                }
                return undefined;
            },
        };
    },
});
