var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div data-region=\"alertRegion\"></div>\n        <fieldset class=\"section-container\">\n            <div class=\"section-header\">\n                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTPPREFS.RTP_TEXTLINE.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":64}}}))
    + "</legend>\n            </div>\n        </fieldset>\n        <div class=\"row\">\n            <div class=\"col-md-3\">\n                <div class=\"form-group required\">\n                    <label for=\"CRDTTRNSFUTUREDAYS-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":14,"column":51},"end":{"line":14,"column":58}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTPPREFS.CRDTTRNSFUTUREDAYS.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":70}}}))
    + "</label>\n                    <div class=\"input-group\">\n                        <input type=\"text\"\n                            class=\"form-control\"\n                            name=\"CRDTTRNSFUTUREDAYS\"\n                            data-bind=\"model\"\n                            maxlength=\"2\"\n                            id=\"CRDTTRNSFUTUREDAYS-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":22,"column":51},"end":{"line":22,"column":58}}}) : helper)))
    + "\">\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CRDTTRNSFUTUREDAYS\"></span>\n                </div>\n            </div>\n        </div>\n            <div class=\"row\">\n                <div class=\"col-md-3\">\n                    <div class=\"form-group required\">\n                        <label for=\"EXPIRATION-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":31,"column":47},"end":{"line":31,"column":54}}}) : helper)))
    + "\">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTPPREFS.EXPIRATION.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":66}}}))
    + "</label>\n                        <div class=\"input-group\">\n                            <input type=\"text\"\n                                   class=\"form-control\"\n                                   name=\"EXPIRATION\"\n                                   data-bind=\"model\"\n                                   maxlength=\"2\"\n                                   id=\"EXPIRATION-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":39,"column":50},"end":{"line":39,"column":57}}}) : helper)))
    + "\">\n                        </div>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"EXPIRATION\"></span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n            <div class=\"col-md-3\">\n                <div class=\"form-group required\">\n                    <label for=\"CRDTTRNSMAXTRNSAMT-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":48,"column":51},"end":{"line":48,"column":58}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTPPREFS.CRDTTRNSMAXTRNSAMT.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":49,"column":70}}}))
    + "</label>\n                    <div class=\"input-group\">\n                        <input type=\"text\"\n                            class=\"form-control\"\n                            name=\"CRDTTRNSMAXTRNSAMT\"\n                            data-bind=\"model\"\n                            data-type=\"amount\"\n                            data-hook=\"getMaxTransactionAmount\"\n                            id=\"CRDTTRNSMAXTRNSAMT-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":57,"column":51},"end":{"line":57,"column":58}}}) : helper)))
    + "\">\n                        <div class=\"input-group-addon\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"CRDTTRNSCURRENCY") || (depth0 != null ? lookupProperty(depth0,"CRDTTRNSCURRENCY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CRDTTRNSCURRENCY","hash":{},"data":data,"loc":{"start":{"line":58,"column":55},"end":{"line":58,"column":75}}}) : helper)))
    + "</div>\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CRDTTRNSMAXTRNSAMT\"></span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-3\">\n                <div class=\"form-group required\">\n                    <label for=\"OPERATING_CURRENCYCODES-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":67,"column":56},"end":{"line":67,"column":63}}}) : helper)))
    + "\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTPPREFS.OPERATING_CURRENCYCODES.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":24},"end":{"line":68,"column":75}}}))
    + "</label>\n                    <div class=\"input-group\">\n                        <div data-region=\"dropDownRegion\"></div>\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"OPERATING_CURRENCYCODES\"></span>\n                </div>\n            </div>\n        </div>\n            <div class=\"row\">\n                <div class=\"col-md-3\">\n                    <div class=\"form-group required\">\n                        <label for=\"ONUSMAXAMOUNT-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":79,"column":50},"end":{"line":79,"column":57}}}) : helper)))
    + "\">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTPPREFS.ONUSMAXAMOUNT.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":80,"column":28},"end":{"line":80,"column":69}}}))
    + "</label>\n                        <div class=\"input-group\">\n                            <input type=\"text\"\n                                   class=\"form-control\"\n                                   name=\"ONUSMAXAMOUNT\"\n                                   data-bind=\"model\"\n                                   data-type=\"amount\"\n                                   data-hook=\"getMaxOnUsTransactionAmount\"\n                                   id=\"ONUSMAXAMOUNT-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":88,"column":53},"end":{"line":88,"column":60}}}) : helper)))
    + "\">\n                            <div class=\"input-group-addon\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"CRDTTRNSCURRENCY") || (depth0 != null ? lookupProperty(depth0,"CRDTTRNSCURRENCY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CRDTTRNSCURRENCY","hash":{},"data":data,"loc":{"start":{"line":89,"column":59},"end":{"line":89,"column":79}}}) : helper)))
    + "</div>\n                        </div>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"ONUSMAXAMOUNT\"></span>\n                        <span class=\"textline-field\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTPPREFS.ONUSMAXAMOUNT.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":54},"end":{"line":92,"column":94}}}))
    + " </span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row\">\n            <div class=\"col-md-3\">\n                <button type=\"button\"\n                        class=\"btn btn-primary\"\n                        data-hook=\"getSave\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":102,"column":20},"end":{"line":102,"column":44}}}))
    + "\n                </button>\n                <button type=\"button\"\n                        class=\"btn btn-secondary\"\n                        data-hook=\"getReset\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":107,"column":45}}}))
    + "\n                </button>\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"alert alert-negative\" role=\"alert\">\n            <span class=\"icon-warning\"></span>\n            <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.no.permission.to.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":114,"column":15},"end":{"line":114,"column":56}}}))
    + "</p>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","hash":{"hideBreadcrumb":"true"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"section section-container\">\n    <div class=\"section-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasModify") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":116,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</section>\n\n\n";
},"usePartial":true,"useData":true});