import Model from '@glu/core/src/model';
import WidgetCollection from 'common/workspaces/collections/widgets';

export default Model.extend({

    initialize(attributes, options) {
        this.jsonData = options.jsonData;
    },

    sync(method, model, options) {
        if (method === 'read') {
            if (this.jsonData !== null) {
                options.success(this.jsonData);
            }
        }
    },

    parse(jsonData) {
        const obj = {};
        /**
         * NH-164267
         * It was observed that the jsonData passed into this parse function sometimes
         * had the workspace id in workspaceId attribute and sometimes in id attribute
         */
        obj.id = jsonData.workspaceId || jsonData.id;
        obj.description = jsonData.description;

        obj.widgets = new WidgetCollection();
        if (jsonData.widgets) {
            obj.widgets = new WidgetCollection(null, {
                jsonData: jsonData.widgets,
            });
            obj.widgets.fetch();
        }

        obj.externalWidgets = new WidgetCollection();
        if (jsonData.externalWidgets) {
            obj.externalWidgets = new WidgetCollection(null, {
                jsonData: jsonData.externalWidgets,
                isExternal: true,
            });
            obj.externalWidgets.fetch();
        }

        return obj;
    },
});
