var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"btn btn-link add-bill\"><span class=\"icon-add-circle\"></span> <span class=\"txt-label\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbPayments.bills.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":126},"end":{"line":12,"column":160}}}))
    + "</span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget-controls\">\n\n    <div class=\"amount-wrapper\">\n        <h4 class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.bills.due.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":38},"end":{"line":4,"column":78}}}))
    + "</h4>\n        <span class=\"amount-value-text total-bills-due\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"totalBillsDue") || (depth0 != null ? lookupProperty(depth0,"totalBillsDue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"totalBillsDue","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":73}}}) : helper)))
    + "</span>\n    </div>\n\n    <div class=\"btn-wrapper\">\n        <div class=\"btn-group widget-links\">\n            <button type=\"button\" class=\"btn btn-link sync-quickbooks\"><span class=\"icon-refresh\"></span> <span class=\"txt-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.quickbooks.sync",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":130},"end":{"line":10,"column":170}}}))
    + "</span></button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "            <button type=\"button\" class=\"btn btn-link manage-vendors\"><span class=\"icon-edit-doc\"></span> <span class=\"txt-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.vendor.manage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":130},"end":{"line":14,"column":168}}}))
    + "</span></button>\n        </div>\n    </div>\n\n    <div data-region=\"billsAlertsRegion\" class=\"billsAlerts\"></div>\n\n    <div class=\"viewControls\">\n\n        <div class=\"form-group sort-select no-side-padding\">\n            <select class=\"form-control\" id=\"tile-filter\">\n                <option value=\"1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.recently.added",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":34},"end":{"line":24,"column":73}}}))
    + "</option>\n                <option value=\"2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.overdue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":66}}}))
    + "</option>\n                <option value=\"3\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.approved",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":34},"end":{"line":26,"column":67}}}))
    + "</option>\n                <option value=\"4\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.pending.approval",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":34},"end":{"line":27,"column":75}}}))
    + "</option>\n                <option value=\"5\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.amount.highest",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":34},"end":{"line":28,"column":73}}}))
    + "</option>\n                <option value=\"6\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.amount.lowest",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":34},"end":{"line":29,"column":72}}}))
    + "</option>\n            </select>\n        </div>\n\n        <div class=\"btn-group view-switcher\">\n            <button type=\"button\" class=\"btn active btn-tile-view\"><span class=\"icon-grid\"></span></button>\n            <button type=\"button\" class=\"btn btn-list-view\"><span class=\"icon-list\"></span></button>\n        </div>\n\n    </div>\n\n</div>\n\n<div class=\"billsGridWrapper\">\n    <div class=\"view-wrapper\">\n        <div class=\"list-wrapper hidden\">\n            <div class=\"bills-grid\" data-region=\"billsGridRegion\"></div>\n        </div>\n    <!-- TODO - move these into a child view (Jane will try to do this in the next PR) -->\n        <div class=\"tile-wrapper\">\n            <div class=\"tiles-grid clearfix\" data-region=\"tileGridRegion\"></div>\n        <div class=\"droppable-region\" data-region=\"droppableRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});