import $ from 'jquery';
import util from '@glu/core/src/util';
import moment from 'moment';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import http from '@glu/core/src/http';
import format from '@glu/core/src/templateHelpers';
import dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import { log } from '@glu/core';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import dateUtil from 'common/util/dateUtil';

export default function (form, initialState) {
    const { model } = form.formView;
    const { formView } = form;
    const viewModel = formView.model;
    const formState = formView.state;
    const FUNCTIONCODES = {
        CASHDISBURSEMENTTEMPLATE: 'BHTMPL',
    };
    let selectedRow = null;
    let rows = null;
    const importFormatGrid = form.formView.gridComponentViews.IMPORTFORMAT;
    const createFrom = form.field('CREATEFROM');
    const cmbTemplateCode = form.field('CMB_TEMPLATE_CODE');
    const templateCode = form.field('TEMPLATECODE');
    const templateDescription = form.field('TEMPLATEDESCRIPTION');
    const compIdName = form.field('COMPIDNAME');
    const origCompName = form.field('ORIGCOMPNAME');
    const origCompId = form.field('ORIGCOMPID');
    const offsetAccountNum = form.field('OFFSETACCOUNTNUM');
    const effectiveDate = form.field('EFFECTIVEDATE');
    const sameDayAch = form.field('SAMEDAYACH');
    const ueEntryDesc = form.field('UE_ENTRYDESC');
    const ueCompDiscData = form.field('UE_COMPDISCDATA');
    const compDescDate = form.field('COMPDESCDATE');
    const batchComment = form.field('BATCHCOMMENT');
    const tranCodeLive = form.field('TRANCODELIVE');
    const compIdNameEFT = form.field('COMPIDNAME_EFT');
    const offsetAccountNumEftCC = form.field('OFFSETACCOUNTNUM_EFTCC');
    const offsetAccountNumEftCD = form.field('OFFSETACCOUNTNUM_EFTCD');
    const offsetAccountNumEftCCD = form.field('OFFSETACCOUNTNUM_EFTCCD');
    const importType = form.field('IMPORTTYPE');
    const subType = form.field('SUBTYPE');
    const confidentialImports = form.field('CONFIDENTIALIMPORTS');
    const functionCode = form.field('FUNCTIONCODE');
    const optionImportMethod = form.field('OPTIONIMPORTMETHOD');
    const optionImportMethodNfi = form.field('OPTIONIMPORTMETHODNFI');
    const optionImportMethodEft = form.field('OPTIONIMPORTMETHODEFT');
    const fid = form.field('ID');
    const paymentInformationSection = [
        createFrom,
        cmbTemplateCode,
        templateCode,
        templateDescription,
        compIdName,
        origCompName,
        origCompId,
        offsetAccountNum,
        effectiveDate,
        sameDayAch,
        ueEntryDesc,
        ueCompDiscData,
        compDescDate,
        batchComment,
        tranCodeLive,
        compIdNameEFT,
        offsetAccountNumEftCC,
        offsetAccountNumEftCD,
        offsetAccountNumEftCCD,
    ];
    const context = {};
    let previousPaymentModelLockedFields = [];
    const importPaymentfieldsMap = new Map();

    const setNotMandatory = function (id) {
        if (id) {
            form.field(id).shouldBeOptional();
        }
        if (id && form.field(id).$el && form.field(id).$el.length === 1) {
            form.field(id).$el.first().parent().removeClass('required');
        }
    };

    const setMandatory = function (id, condition) {
        let bMandatory = false;
        if (condition === undefined) {
            bMandatory = true;
        } else {
            bMandatory = condition;
        }
        if (bMandatory) {
            if (id) {
                form.field(id).shouldBeRequired();
            }
            if (id && form.field(id).$el && form.field(id).$el.length === 1) {
                form.field(id).$el.first().parent().addClass('required');
            }
        } else {
            setNotMandatory(id);
        }
    };

    const getSelectedRowValue = function (fieldname) {
        if (selectedRow && fieldname) {
            return selectedRow.get(fieldname);
        }
        return '';
    };

    const showPaymentInformationSection = function () {
        util.invoke(paymentInformationSection, 'shouldBeVisible');
        $('.PAY_IMPORT_CLIENTDEFINED_FIELDS').show();
        createFrom.$el.closest('.section').show();
    };

    const hideRadio = function (field) {
        field.shouldBeHidden();
        if (field.isNotEmpty()) {
            const { name } = field.$el[0];
            $(`label[for=${name}]`).parents('.field-container').first().hide();
        }
    };

    const showRadio = function (field) {
        field.shouldBeVisible();
        if (field.isNotEmpty()) {
            const { name } = field.$el[0];
            $(`label[for=${name}]`).parents('.field-container').first().show();
        }
    };

    const hidePaymentInformationSection = function () {
        util.invoke(paymentInformationSection, 'shouldBeHidden');
        hideRadio(createFrom);
        createFrom.$el.closest('.section').hide();
    };

    const showSameDay = function () {
        const paymentTypeCode = model.get('PAYMENTTYPECODE') ? model.get('PAYMENTTYPECODE') : model.get('IMPORTTYPE');
        createFrom.$el.closest('.section').show();
        sameDayAch.shouldBeVisible();
        const localeKey = paymentTypeCode === 'BDACHNFI' ? 'ACH.processSameDay' : 'ACH.MAKESAMEDAY';
        sameDayAch.$el.siblings('label').text(locale.get(localeKey));
    };

    /**
     * @method toggleLockFields
     * @param {array} lockedFields
     * @param {boolean} lock
     * This method locks/unlocks the fields provided in array
     */

    const toggleLockFields = function (lockedFields, lock) {
        const paymentModel = form.formView.model;
        lockedFields.forEach((fieldName) => {
            let localFieldName = fieldName;
            if (importPaymentfieldsMap.has(localFieldName)) {
                localFieldName = importPaymentfieldsMap.get(localFieldName);
            }
            if (paymentModel.has(localFieldName)) {
                paymentModel.fieldData[localFieldName].lockable = lock;
                paymentModel.fieldData[localFieldName].locked = lock;
                paymentModel.fieldData[localFieldName].protected = lock;
                form.field(localFieldName).shouldBeReadOnly(lock);
            }
        });
    };

    const showSameDayWarning = function (date) {
        if ($('#sda-warning').length < 1) {
            const paymentTypeCode = model.get('PAYMENTTYPECODE') ? model.get('PAYMENTTYPECODE') : model.get('IMPORTTYPE');
            const errorCode = paymentTypeCode === 'BDACHNFI' ? 'ACH.sameDayWarning' : 'ACH.allowSameDayWarningInfo';
            const alertMsg = `<div id="sda-warning"><span class="sda-warning icon-exclamation-solid"></span>${locale.get(errorCode, date || model.get('EFFECTIVEDATE'))}</div>`;
            $('#SAMEDAYACH').parent().append(alertMsg);
        }
        model.set(
            'SAMEDAYACH',
            '1',
            {
                silent: true,
            },
        );
    };

    const hideSameDayWarning = function () {
        $('#sda-warning').remove();
        model.set(
            'SAMEDAYACH',
            '0',
            {
                silent: true,
            },
        );
    };

    const resetDate = function () {
        const pType = model.get('PAYMENTTYPECODE') ? model.get('PAYMENTTYPECODE') : model.get('IMPORTTYPE');

        const postData = {
            paymentType: pType,
            debitBank: model.get('BANKCODE'),
            debitCurrency: model.get('ORIGCURRENCYCODE'),
            debitBankCountry: model.get('ORIGCOUNTRY'),
            subType: pType === 'BDACHNFI' ? 'NACHA' : model.get('SUBTYPE'),
            sameDayAch: model.get('SAMEDAYACH'),
        };

        return dateUtil.setEffectiveDate(form, 'EFFECTIVEDATE', postData);
    };

    const sameDayPaymentEffDate = function (sameDayEffectiveDate, settlementWindow) {
        if (settlementWindow === '1' && form.field('COMPDESCDATE')) {
            form.field('COMPDESCDATE').setValue('');
            form.field('COMPDESCDATE').shouldBeReadOnly(true);
        }
        if (sameDayEffectiveDate) {
            const userDateFormat = userInfo.getDateFormat();
            const sameDayeffDate = moment(sameDayEffectiveDate).format(userDateFormat);
            if (form.field('EFFECTIVEDATE')) {
                viewModel.set('EFFECTIVEDATE', sameDayeffDate);
            }
            form.field('EFFECTIVEDATE').shouldBeReadOnly(true);
        }
        showSameDayWarning(format.date($('#EFFECTIVEDATE').val()));
    };

    const standardPaymentEffectiveDate = function (effectiveDt) {
        if (model.get('SAMEDAYACH') === '1') {
            model.set(
                'SAMEDAYACH',
                '0',
                {
                    silent: true,
                },
            );
        }
        form.field('SAMEDAYACH').$el.prop('checked', false);

        if (effectiveDt) {
            const userDateFormat = userInfo.getDateFormat();
            const effDate = moment(effectiveDt).format(userDateFormat);
            if (form.field('EFFECTIVEDATE')) {
                viewModel.set('EFFECTIVEDATE', effDate);
            }
            form.field('EFFECTIVEDATE').shouldBeReadOnly(false);
        }
        if (form.field('COMPDESCDATE')) {
            form.field('COMPDESCDATE').shouldBeReadOnly(false);
        }
        hideSameDayWarning();
    };

    const getSameDayACHData = function () {
        return {
            item: [{
                name: '_productCode',
                value: model.get('PRODUCTCODE'),
            }, {
                name: '_functionCode',
                value: model.get('FUNCTIONCODE'),
            }, {
                name: '_typeCode',
                value: model.get('TYPECODE'),
            }, {
                name: '_subTypeCode',
                value: model.get('SUBTYPE') === undefined ? 'NACHA' : model.get('SUBTYPE'),
            }, {
                name: ' _mode',
                value: formState,
            }, {
                name: 'REQUESTTYPE',
                value: 'achsamedaypayment',
            }, {
                name: '_companyId',
                value: model.get('ORIGCOMPID'),
            }, {
                name: '_sameDayACH',
                value: model.get('SAMEDAYACH'),
            }, {
                name: '_bankCode',
                value: model.get('BANKCODE'),
            }, {
                name: '_currencyCode',
                value: model.get('ORIGCURRENCYCODE'),
            }],
        };
    };

    const gatherSameDayResults = function (result) {
        let sameDayEffectiveDate;
        let effectiveDt;
        let settlementWindow;
        let cutoffTimePassed;
        let sameDayCutoffTimePassed;
        // extract the debit amounts and currency
        util.each(result.item, (entry) => {
            if (entry.name === 'SAMEDAYEFFECTIVEDATE') {
                sameDayEffectiveDate = entry.value;
            } else if (entry.name === 'EFFECTIVEDATE') {
                effectiveDt = entry.value;
            } else if (entry.name === 'SETTLEMENTWINDOW') {
                settlementWindow = entry.value;
            } else if (entry.name === 'ISCUTOFFNOTPASSED') {
                cutoffTimePassed = (entry.value === 'false');
            } else if (entry.name === 'ISSAMEDAYCUTOFFNOTPASSED') {
                sameDayCutoffTimePassed = (entry.value === 'false');
            }
        });
        return {
            sameDayEffectiveDate,
            effectiveDt,
            settlementWindow,
            cutoffTimePassed,
            sameDayCutoffTimePassed,
        };
    };

    const processSameDayResults = function (data) {
        if (form.field('SAMEDAYACH').$el.is(':checked')) {
            if (data.sameDayCutoffTimePassed) {
                // same day cutoff time has passed
                if (data.cutoffTimePassed) {
                    // standard cutoff time has passed
                    let warnMsg = `${locale.get('ACH.SameDayPaymentNextBusinessDay')}(${data.sameDayEffectiveDate})`;
                    warnMsg += '<br><br><br>';
                    warnMsg += locale.get('ACH.ContinueSameDay');
                    const title = locale.get('ACH.Warning');

                    dialog.confirm(
                        warnMsg, title,
                        (ok) => {
                            if (ok) {
                                sameDayPaymentEffDate(
                                    data.sameDayEffectiveDate,
                                    data.settlementWindow,
                                );
                            } else {
                                standardPaymentEffectiveDate(data.effectiveDt);
                            }
                        },
                    );
                } else {
                    // same day cutoff time passed, but standard cutoff time has not
                    standardPaymentEffectiveDate(data.effectiveDt);
                    dialog.error(locale.get('ACH.StandardPayment'));
                    return false;
                }
            } else if (!data.cutoffTimePassed) {
                // same day cutoff time not passed and standard cutoff time not passed
                sameDayPaymentEffDate(data.sameDayEffectiveDate, data.settlementWindow);
            }
        } else {
            standardPaymentEffectiveDate(data.effectiveDt);
        }
        return undefined;
    };

    const processSameDayACH = function () {
        http.post(
            services.generateUrl(constants.URL_PROCESS_SERVICE_ACTION),
            getSameDayACHData(),
        )
            .then(gatherSameDayResults)
            .then(processSameDayResults, (err) => {
                log.error(err);
            });
    };

    const setCompDiscData = function () {
        if (model.get('CREATEFROM') === 'createFromTemplate') {
            /*
             * I could not find a reliable way to handle a new user entered CDD value
             * after changing templates and there is no firm requirement to do so,
             * so I don't try.
             */
            if (model.get('UE_COMPDISCDATA').length <= 0) {
                // Use company default.
                ueCompDiscData.setValue(model.get('COMPDISCDATA'));
            }
        } else {
            const newUeCompDiscData = store.get('newUeCompDiscData');
            if (newUeCompDiscData
                && (newUeCompDiscData.length > 0)
                && !ueCompDiscData.isReadOnly()) {
                // If a user has entered a value before changing ACH companies, use that.
                ueCompDiscData.setValue(newUeCompDiscData);
            } else if (model.get('UE_COMPDISCDATA').length <= 0) {
                // Use company default.
                ueCompDiscData.setValue(model.get('COMPDISCDATA'));
            }
        }
    };

    const showSameAsPayment = function (value) {
        /*
        * get ach company same day flag
        * allowsamedayach is for payment type same day permission
        * clientcomp_allowsamedaypayment is for ach company permission
        */
        if (value) {
            if (model.get('FUNCTIONCODE') !== FUNCTIONCODES.CASHDISBURSEMENTTEMPLATE &&
                model.get('ALLOWSAMEDAYACH') === true &&
                model.get('CLIENTCOMP_ALLOWSAMEDAYPAYMENT') === '1') {
                showSameDay();
            } else {
                hideSameDayWarning();
                sameDayAch.shouldBeHidden();
            }
        }
    };

    if (initialState) {
        const clearFields = function () {
            if (model) {
                form.field('SAMEDAYACH').$el.prop('checked', false);
                model.set({
                    COMPDESCDATE: '',
                    BATCHCOMMENT: '',
                    COMPIDNAME: '',
                    COMPIDNAME_EFT: '',
                    ORIGCOMPNAME: '',
                    ORIGCOMPID: '',
                    OFFSETACCOUNTNUM: '',
                    OFFSETACCOUNTNUM_EFTCC: '',
                    OFFSETACCOUNTNUM_EFTCD: '',
                    OFFSETACCOUNTNUM_EFTCCD: '',
                    CMB_TEMPLATE_CODE: '',
                    SAMEDAYACH: '0',
                    TEMPLATECODE: '',
                    COMPIDNAME1: '',
                });
            }

            compIdName.$el.select2('val', null);
            cmbTemplateCode.$el.select2('val', null);
        };

        /*
         * Hide File level imports for 3.3.
         * The model is set by default to IndividualPayments below
         */
        if (model.jsonData
            && model.jsonData.typeInfo
            && model.jsonData.typeInfo.typeCode
            && model.jsonData.typeInfo.typeCode === 'TRANSFER') {
            hideRadio(optionImportMethod);
            $('label[for="OPTIONIMPORTMETHOD"]').parent().hide();
        }

        // hide fields that are hidden and are in the model (use for client maps)
        importType.shouldBeHidden();
        subType.shouldBeHidden();
        hideRadio(confidentialImports);
        functionCode.shouldBeHidden();
        hideRadio(optionImportMethod);
        hideRadio(optionImportMethodNfi);
        hideRadio(optionImportMethodEft);
        $('label[for="OPTIONIMPORTMETHOD"]').parent().hide();
        $('label[for=OPTIONIMPORTMETHODEFT]').parents('.field-container').first().hide();
        $('label[for=OPTIONIMPORTMETHODNFI]').parents('.field-container').first().hide();

        hidePaymentInformationSection();

        model.set({
            OPTIONIMPORTMETHOD: 'IndividualPayments',
            OPTIONIMPORTMETHODNFI: 'IndividualBatches',
            OPTIONIMPORTMETHODEFT: 'IndividualBatches',
            CREATEFROM: 'createFromManualEntry',
            SAMEDAYACH: '0',
        });
        $('#filePaymentType').val('0');
        $('#_Param_Value_1').val('0');

        importPaymentfieldsMap.set('ENTRYDESC', 'UE_ENTRYDESC');
        importPaymentfieldsMap.set('COMPDISCDATA', 'UE_COMPDISCDATA');

        model.on('change:CMB_TEMPLATE_CODE', () => {
            model.set(
                'COMPIDNAME',
                model.get('COMPIDNAME1'),
                {
                    silent: true,
                },
            );
            compIdName.$el.select2('val', model.get('COMPIDNAME1'));
            setCompDiscData();
            showSameAsPayment(model.get('COMPIDNAME1'));
        });

        // model.on('change:ID', function() {
        model.on('change:COMPIDNAME', () => {
            // reset samedayach
            form.field('SAMEDAYACH').$el.prop('checked', false);
            $('[name="SAMEDAYACH"]').trigger('change');

            origCompName.setValue(model.get('ORIGCOMPNAME'));
            origCompId.setValue(model.get('ORIGCOMPID'));
            setCompDiscData();

            // retrieve date info on change of ID and only for Payments
            if (model.get('FUNCTIONCODE') === 'BATCH' && model.get('COMPIDNAME') !== '') {
                resetDate();
                form.field('COMPIDNAME').$el.first().parent().removeClass('has-error');
            }
            showSameAsPayment(model.get('ORIGCOMPNAME'));
        });

        model.on('change:OFFSETACCOUNTNUM', () => {
            offsetAccountNum.setValue(maskValue(model.get('OFFSETACCOUNTNUM'), getMaskingProperties()));
        });

        model.on('change:OPTIONIMPORTMETHOD', function () {
            if (this.get('OPTIONIMPORTMETHOD') === 'File') {
                $('#filePaymentType').val('1');
                $('#_Param_Value_1').val('1');
            } else {
                $('#filePaymentType').val('0');
                $('#_Param_Value_1').val('0');
            }
        });

        model.on('change:OPTIONIMPORTMETHODNFI', function () {
            if (this.get('OPTIONIMPORTMETHODNFI') === 'PassThrough') {
                $('#filePaymentType').val('1');
                $('#_Param_Value_1').val('1');
                const passThruConfidentialSetting = $('#passThruConfidentialSetting').val();
                if (passThruConfidentialSetting !== '2') {
                    hideRadio(confidentialImports);
                    $('#fileConfidential').val(passThruConfidentialSetting);
                    model.set('CONFIDENTIALIMPORTS', passThruConfidentialSetting);
                } else {
                    showRadio(confidentialImports);
                }
                hidePaymentInformationSection();
                model.set('SAMEDAYACH', '0');
            } else {
                $('#filePaymentType').val('0');
                $('#_Param_Value_1').val('0');
                const confidentialSetting = $('#confidentialSetting').val();
                if (confidentialSetting !== '2') {
                    hideRadio(confidentialImports);
                    $('#fileConfidential').val(confidentialSetting);
                    model.set('CONFIDENTIALIMPORTS', confidentialSetting);
                } else {
                    showRadio(confidentialImports);
                }

                // if type is BDACHNFI
                const paymentTypeCode = model.get('PAYMENTTYPECODE');

                const allowSameDayAch = model.get('ALLOWSAMEDAYACH');

                if (paymentTypeCode === 'BDACHNFI' && allowSameDayAch) {
                    showSameDay();
                }
            }
        });

        model.on('change:OPTIONIMPORTMETHODEFT', function () {
            if (this.get('OPTIONIMPORTMETHODEFT') === 'PassThrough') {
                $('#filePaymentType').val('1');
                $('#_Param_Value_1').val('1');
                const passThruConfidentialSetting = $('#eftPassThruConfidentialSetting').val();
                if (passThruConfidentialSetting !== '2') {
                    hideRadio(confidentialImports);
                    $('#fileConfidential').val(passThruConfidentialSetting);
                    model.set('CONFIDENTIALIMPORTS', passThruConfidentialSetting);
                } else {
                    showRadio(confidentialImports);
                }
                hidePaymentInformationSection();
            } else {
                $('#filePaymentType').val('0');
                $('#_Param_Value_1').val('0');
                const confidentialSetting = $('#confidentialSetting').val();
                if (confidentialSetting !== '2') {
                    hideRadio(confidentialImports);
                    $('#fileConfidential').val(confidentialSetting);
                    model.set('CONFIDENTIALIMPORTS', confidentialSetting);
                } else {
                    showRadio(confidentialImports);
                }
            }
        });

        model.on('change:CONFIDENTIALIMPORTS', function () {
            if (this.get('CONFIDENTIALIMPORTS') === '1') {
                $('#fileConfidential').val('1');
                $('#_Param_Value_3').val('1');
            } else {
                $('#fileConfidential').val('0');
                $('#_Param_Value_3').val('0');
            }
        });

        model.on('change:CMB_TEMPLATE_CODE', function () {
            if (this.get('CMB_TEMPLATE_CODE') !== '') {
                form.field('FUNCTIONCODE').$el.first().parent().removeClass('has-error');
            }
        });

        model.on('change:LOCKED_FIELDS', () => {
            toggleLockFields(previousPaymentModelLockedFields, false);
            const paymentModel = form.formView.model;
            if (paymentModel.has('LOCKED_FIELDS')) {
                const lockedFields = paymentModel.get('LOCKED_FIELDS').split(',');
                previousPaymentModelLockedFields = lockedFields;
                toggleLockFields(lockedFields, true);
            }
            if (paymentModel.has('LOCKED_FIELDS') && paymentModel.has('LOCKABLEFIELDS')) {
                const lockableSet = new Set(paymentModel.get('LOCKABLEFIELDS').split(','));
                const lockedSet = new Set(paymentModel.get('LOCKED_FIELDS').split(','));
                const unlockedFields = new Set([...lockableSet].filter(x => !lockedSet.has(x)));
                toggleLockFields([...unlockedFields], false);
            }
        });

        sameDayAch.$el.on('change', () => {
            if (sameDayAch.$el.is(':checked')) {
                model.set(
                    'SAMEDAYACH',
                    '1',
                    {
                        silent: true,
                    },
                );
            } else {
                hideSameDayWarning();
                effectiveDate.shouldBeReadOnly(false);
            }
            processSameDayACH();
        });

        ueCompDiscData.$el.on('blur', () => {
            if (!ueCompDiscData.isReadOnly()) {
                if (ueCompDiscData.getValue() !== model.get('COMPDISCDATA')) {
                    if (ueCompDiscData.getValue().length <= 0) {
                        ueCompDiscData.setValue(model.get('COMPDISCDATA'));
                        store.set('newUeCompDiscData', '');
                    } else {
                        /*
                         * We need to preserve this value for use in case the user changes
                         * ACH companies.
                         */
                        store.set('newUeCompDiscData', ueCompDiscData.getValue());
                    }
                }
            }
        });

        const compIdNameNotMandatory = function (paymentTypeCode) {
            const notRequired = [
                'BDEFTCC',
                'BDEFTCD',
                'BDEFTCCD',
            ];
            return notRequired.includes(paymentTypeCode);
        };

        model.on('change:CREATEFROM', function () {
            form.field('COMPIDNAME').shouldBeRequired(false);
            form.field('EFFECTIVEDATE').shouldBeRequired(false);
            if (this.get('CREATEFROM') === 'createFromManualEntry') {
                cmbTemplateCode.shouldBeHidden();
                fid.shouldBeVisible();
                setNotMandatory('CMB_TEMPLATE_CODE');
                compIdName.$el.prop('readonly', false);
                compIdName.$el.removeClass('read-only-field');
            } else {
                cmbTemplateCode.shouldBeVisible();
                fid.shouldBeHidden();
                setMandatory('CMB_TEMPLATE_CODE');
                form.field('EFFECTIVEDATE').shouldBeRequired(true);
                compIdName.shouldBeReadOnly(true);
            }
            sameDayAch.shouldBeHidden();

            clearFields();
        });

        model.on('change:FUNCTIONCODE', function () {
            form.field('TEMPLATECODE').shouldBeRequired(false);
            form.field('TEMPLATEDESCRIPTION').shouldBeRequired(false);
            form.field('COMPIDNAME').shouldBeRequired(false);
            form.field('EFFECTIVEDATE').shouldBeRequired(false);
            if (this.get('FUNCTIONCODE') !== '') {
                form.field('FUNCTIONCODE').$el.first().parent().removeClass('has-error');
                clearFields();
                if ($('#_productCode').val() === 'USACH') {
                    showPaymentInformationSection();
                    form.field('COMPIDNAME').shouldBeRequired(true);
                    $('[name="SUBTYPE"]').trigger('change');
                    const fCode = this.get('FUNCTIONCODE'.toUpperCase());
                    const paymentTypeCode = this.get('PAYMENTTYPECODE'.toUpperCase());
                    if (fCode === 'BATCH') {
                        templateCode.shouldBeHidden();
                        templateDescription.shouldBeHidden();
                        effectiveDate.shouldBeVisible();
                        showRadio(createFrom);
                        model.set('CREATEFROM', 'createFromManualEntry');
                        form.field('EFFECTIVEDATE').shouldBeRequired(true);
                        setMandatory('CREATEFROM');

                        if (compIdNameNotMandatory(paymentTypeCode)) {
                            setNotMandatory('COMPIDNAME');
                        } else {
                            setMandatory('COMPIDNAME');
                        }

                        setMandatory('EFFECTIVEDATE');
                        setNotMandatory('TEMPLATECODE');
                        setNotMandatory('TEMPLATEDESCRIPTION');
                        const confidentialSetting = $('#confidentialSetting').val();
                        if (confidentialSetting !== '2') {
                            hideRadio(confidentialImports);
                            $('#fileConfidential').val(confidentialSetting);
                            model.set('CONFIDENTIALIMPORTS', confidentialSetting);
                        } else {
                            showRadio(confidentialImports);
                        }
                        resetDate();
                    } else if (fCode === FUNCTIONCODES.CASHDISBURSEMENTTEMPLATE) {
                        templateCode.shouldBeVisible();
                        templateDescription.shouldBeVisible();
                        effectiveDate.shouldBeHidden();
                        hideRadio(createFrom);
                        hideRadio(confidentialImports);
                        form.field('TEMPLATECODE').shouldBeRequired(true);
                        form.field('TEMPLATEDESCRIPTION').shouldBeRequired(true);

                        if (compIdNameNotMandatory(paymentTypeCode)) {
                            setNotMandatory('COMPIDNAME');
                        } else {
                            setMandatory('COMPIDNAME');
                        }

                        setMandatory('TEMPLATECODE');
                        setMandatory('TEMPLATEDESCRIPTION');
                        setNotMandatory('EFFECTIVEDATE');
                        $('#fileConfidential').val('0');
                        model.set('CONFIDENTIALIMPORTS', '0');
                    }
                    cmbTemplateCode.shouldBeHidden();
                    sameDayAch.shouldBeHidden();
                    if (paymentTypeCode === 'BDEFTCC' || paymentTypeCode === 'BDEFTCD') {
                        tranCodeLive.shouldBeVisible();
                        setMandatory('TRANCODELIVE');
                    } else {
                        tranCodeLive.shouldBeHidden();
                    }
                    if (paymentTypeCode === 'BDEFTCC') {
                        compIdNameEFT.shouldBeVisible();
                        compIdName.shouldBeHidden();
                        offsetAccountNumEftCC.shouldBeVisible();
                        offsetAccountNumEftCD.shouldBeHidden();
                        offsetAccountNumEftCCD.shouldBeHidden();
                        offsetAccountNum.shouldBeHidden();
                        setMandatory('COMPIDNAME_EFT');
                        setMandatory('OFFSETACCOUNTNUM_EFTCC');
                        compDescDate.shouldBeHidden();
                    } else if (paymentTypeCode === 'BDEFTCD') {
                        compIdNameEFT.shouldBeVisible();
                        compIdName.shouldBeHidden();
                        offsetAccountNumEftCC.shouldBeHidden();
                        offsetAccountNumEftCD.shouldBeVisible();
                        offsetAccountNumEftCCD.shouldBeHidden();
                        offsetAccountNum.shouldBeHidden();
                        compDescDate.shouldBeHidden();
                        setMandatory('COMPIDNAME_EFT');
                        setMandatory('OFFSETACCOUNTNUM_EFTCD');
                    } else if (paymentTypeCode === 'BDEFTCCD') {
                        compIdNameEFT.shouldBeVisible();
                        compIdName.shouldBeHidden();
                        offsetAccountNumEftCC.shouldBeHidden();
                        offsetAccountNumEftCD.shouldBeHidden();
                        offsetAccountNumEftCCD.shouldBeVisible();
                        offsetAccountNum.shouldBeHidden();
                        compDescDate.shouldBeHidden();
                        setMandatory('COMPIDNAME_EFT');
                        setMandatory('OFFSETACCOUNTNUM_EFTCCD');
                    } else {
                        compIdNameEFT.shouldBeHidden();
                        compIdName.shouldBeVisible();
                        offsetAccountNumEftCC.shouldBeHidden();
                        offsetAccountNumEftCD.shouldBeHidden();
                        offsetAccountNumEftCCD.shouldBeHidden();
                        offsetAccountNum.shouldBeVisible();
                        compDescDate.shouldBeVisible();
                    }
                }
            } else {
                hidePaymentInformationSection();
            }
        });

        store.set('newUeCompDiscData', '');
    }

    /*
     * The createFromManualEntry values can be handled in the change on COMPIDNAME.  No
     * need to to it here as well and there may be unpleasant side effects.
     */
    if (model.get('CREATEFROM') === 'createFromTemplate') {
        setCompDiscData();
    }

    form.field('ORIGCOMPNAME').shouldBeReadOnly(true);
    form.field('ORIGCOMPID').shouldBeReadOnly(true);
    form.field('OFFSETACCOUNTNUM').shouldBeReadOnly(true);

    if (model.fieldData.TNUM) {
        form.field('TEMPLATECODE').shouldBeReadOnly(true);
        form.field('TEMPLATEDESCRIPTION').shouldBeReadOnly(true);
        $('label[for="OPTIONIMPORTMETHOD"]').hide();
        $('label[for="OPTIONIMPORTMETHODNFI"]').hide();
    }
    if (model.get('IMPORTTYPE') === 'WIRTMPIM') {
        $('label[for="OPTIONIMPORTMETHOD"]').parent().hide();
        $('#filePaymentType').val(0);
    }

    const appendNonRunTimeParameter = function (paramName, paramValue) {
        if (paramName && paramValue) {
            let fldValue = $('#NonRunTimeParams').val();
            /*
             * if val is empty then simply set the paramName and value otherwise add a |
             * separator and concatenate with the old value
             */
            fldValue = (fldValue.length === 0 ? `${paramName}=${paramValue}|` : `${fldValue + paramName}=${paramValue}|`);
            $('#NonRunTimeParams').val(fldValue);
        }
    };

    const getImportMapHostAp = function () {
        const paymentTypeCode = getSelectedRowValue('PAYMENTTYPECODE');
        let importFileType = getSelectedRowValue('IMPORTFILETYPE');
        const paymentTypeDesc = getSelectedRowValue('PAYMENTTYPEDESC');

        if (paymentTypeCode === 'BDACHNFI') {
            return 'JDACHNFI';
        }
        if (paymentTypeCode === 'BIEFTCCD') {
            // Payment type is BDEFTCCD for all EFT Types
            if (paymentTypeDesc !== null) {
                if (paymentTypeDesc.indexOf('1464') !== -1) {
                    return 'EFTF1464';
                }
                if (paymentTypeDesc.indexOf('80') !== -1) {
                    return 'EFTFIMP';
                }
            }
        }
        if (paymentTypeCode === 'CIMIMPRT') {
            return 'CIMIMPQ';
        }
        if (paymentTypeCode === 'STCAIMPT') {
            return 'STCAIMPQ';
        }

        if (importFileType !== '') {
            importFileType = importFileType.toUpperCase();
            if ($('#_functionCode').val() === 'BATCH' || $('#_functionCode').val() === 'INST') {
                if (importFileType === 'FIXED' || importFileType === 'INSDIMF') {
                    return 'DIFIXED';
                }
                if (importFileType === 'DELIMITED' || importFileType === 'INSDIMD') {
                    return 'DIDELIM';
                }
                if (importFileType === 'NACHA' || importFileType === 'INSDIMN') {
                    return 'DINACHA';
                }
            } else if ($('#_functionCode').val() === FUNCTIONCODES.CASHDISBURSEMENTTEMPLATE) {
                if (importFileType === 'FIXED' || importFileType === 'INSDIMF') {
                    return 'DIFXDTMP';
                }
                if (importFileType === 'DELIMITED' || importFileType === 'INSDIMD') {
                    return 'DIDLMTMP';
                }
                if (importFileType === 'NACHA' || importFileType === 'INSDIMN') {
                    return 'DINCHTMP';
                }
            }
        }
        return null;
    };

    const defineJCode = function () {
        const productCode = getSelectedRowValue('PRODUCTCODE');
        const paymentTypeCode = getSelectedRowValue('PAYMENTTYPECODE');
        const paymentTypeDesc = getSelectedRowValue('PAYMENTTYPEDESC');
        let paymentMethod = getSelectedRowValue('PAYMENTMETHOD');
        const subTypeCode = getSelectedRowValue('SUBTYPE');
        const importFunctionCode = getSelectedRowValue('IMPORTFUNCTIONCODE');
        let jCode = getSelectedRowValue('JCODE');
        const mapID = getSelectedRowValue('ID');

        paymentMethod = (paymentMethod ? paymentMethod.toUpperCase() : paymentMethod);

        /*
         * allow the definition of the jcode from the UI. set the value in ImportJCODE, this
         * will be appended the value in the paramenters for QEVENT.
         */
        if (productCode === 'RTGS') {
            if (util.contains(['FEDWIRE', 'INTL', 'DRAFT', 'MULTIBK', 'TRANSFER', 'BPAY', 'DRAWDOWN'], paymentTypeCode)) {
                if (importFunctionCode === 'TMPL') {
                    $('#ImportMapHostAp').val('RTGSTMPIM');
                    $('#ImportJCODE').val('RTGSTMPIM');
                    return;
                }
            }
        }

        if (paymentMethod === 'CSV') {
            $('#ImportMapHostAp').val('PAYIMCSV');
            $('#ImportJCODE').val('PAYIMCSV');
        } else if (paymentMethod === 'MULTICSV' || (paymentMethod === 'ACH' && subTypeCode === 'UKFP')) {
            $('#ImportMapHostAp').val('PAYIMCSV');
            $('#ImportJCODE').val('PAYIMCSV');
        } else if (paymentMethod === 'ACH' && subTypeCode === 'UKBACS') {
            $('#ImportMapHostAp').val('PAYIMBAC');
            $('#ImportJCODE').val('PAYIMBAC');
        } else if (paymentMethod === 'BAC') {
            $('#ImportMapHostAp').val('PAYIMBAC');
            $('#ImportJCODE').val('PAYIMBAC');
        } else if (paymentMethod === 'XML') {
            $('#ImportMapHostAp').val('PAYIMCSV');
            if (paymentTypeCode === 'BDACHCD') {
                $('#ImportJCODE').val('BACIMXML');
            } else {
                $('#ImportJCODE').val('PAYIMXML');
            }
        } else if (paymentMethod === 'MT101') {
            $('#ImportMapHostAp').val('MT101FIMPORT');
            $('#ImportJCODE').val('MT101FIMPORT');
        } else {
            if (paymentMethod === 'ACH') {
                $('#ImportJCODE').val('JDACHDI');
                if (paymentTypeCode === 'BDACHNFI') {
                    $('#ImportJCODE').val('NACHAFIW');
                } else if (paymentTypeCode === 'BDEFTCCD' || paymentTypeCode === 'BDEFTCD' || paymentTypeCode === 'BDEFTCC'
                    || paymentTypeCode === 'BIEFTCCD' || paymentTypeCode === 'BIEFTCD' || paymentTypeCode === 'BIEFTCC') {
                    if (mapID > 0) {
                        $('#ImportJCODE').val('JDACHDI');
                    } else if (paymentTypeDesc !== null && paymentTypeDesc.indexOf('1464') !== -1) {
                        $('#ImportJCODE').val('EFTF1464');
                    } else {
                        $('#ImportJCODE').val('EFTFIMP');
                    }
                }
            }
            if (paymentMethod === locale.get('CM.Import.PayMethod').toUpperCase()) {
                if (jCode) {
                    jCode = jCode.trim();
                    if (jCode.indexOf('JCODE=') === 0) {
                        jCode = jCode.substring(6);
                    }
                }
                $('#ImportJCODE').val(jCode);
            }
            $('#ImportMapHostAp').val(getImportMapHostAp());
        }
    };

    const overrideForClientMaps = function (data) {
        if (selectedRow) {
            const owner = selectedRow.get('OWNER');
            const productCode = selectedRow.get('PRODUCTCODE');
            if ((owner === locale.get('Client') || owner === locale.get('Bank')) && productCode && productCode.toUpperCase() === 'USACH') {
                $('#_Param_Value_3').val($('#_functionCode').val());
                $('#_Param_Name_3').val('RP=FUNCTIONCODE');
                $('#_Param_Value_4').val($('#_typeCode').val());
                $('#_Param_Name_4').val('RP=TYPECODE');
                $('#_Param_Value_5').val($('#fileConfidential').val());
                $('#_Param_Name_5').val('RP=_CONFIDENTIAL');
                $('#_Param_Value_7').val('MODIFY');
                $('#_Param_Name_7').val('RP=ACTIONMODE');
                $('#_Param_Value_8').val(data.get('TNUM'));
                $('#_Param_Name_8').val('RP=BATCHTNUM');
            }
        }
    };

    const setFormData = function () {
        const paymentTypeCode = getSelectedRowValue('PAYMENTTYPECODE');
        const paymentTypeDesc = getSelectedRowValue('PAYMENTTYPEDESC');
        let paymentMethod = getSelectedRowValue('PAYMENTMETHOD');
        const subTypeCode = getSelectedRowValue('SUBTYPE');
        const owner = getSelectedRowValue('OWNER');
        const isPassThru = getSelectedRowValue('TYPE') === 'PASSTHRU';
        let id = getSelectedRowValue('COMPIDNAME');
        const mapID = getSelectedRowValue('ID');
        const isEFTPassThru = getSelectedRowValue('TYPE') === 'BEFTPASS';

        paymentMethod = (paymentMethod ? paymentMethod.toUpperCase() : paymentMethod);

        if (owner === locale.get('Client') || owner === locale.get('Bank')) {
            setMandatory('FUNCTIONCODE');
        } else {
            setNotMandatory('FUNCTIONCODE');
        }

        // from legacy FileImportFunctions.js
        if (paymentTypeCode === 'FILE') {
            $('#_typeCode').val('*');
        } else {
            $('#_typeCode').val(paymentTypeCode);
        }
        defineJCode();

        /*
         * map value from the user selection of 'import As'
         * this basically overrides the function code from the process row selection
         * on the grid
         */
        if (model.get('FUNCTIONCODE') !== '') {
            $('#_functionCode').val(model.get('FUNCTIONCODE'));
        }

        if ($('#_functionCode').val() === 'BATCH') {
            id += 'INST';
            context.functionCode = 'BATCH';
        } else if ($('#_functionCode').val() === FUNCTIONCODES.CASHDISBURSEMENTTEMPLATE) {
            id += 'TMPL';
            context.functionCode = FUNCTIONCODES.CASHDISBURSEMENTTEMPLATE;
        } else if ($('#_functionCode').val() === 'INST') {
            id += 'INST';
            context.functionCode = 'INST';
        }

        if (paymentMethod !== locale.get('CM.Import.PayMethod').toUpperCase()) {
            // Don't add these when processing Check Management File Import

            $('#_Param_Value_1').val('');
            $('#_Param_Name_1').val('RP=_INCLUDECODE');
            $('#_Param_Value_3').val($('#fileConfidential').val());
            $('#_Param_Name_3').val('RP=_CONFIDENTIAL');

            if ($('#_typeCode').val() === 'BDACHNFI') {
                if (isPassThru && $('#OPTIONIMPORTMETHODNFI').is(':visible') === false) {
                    $('#filePayment').val(1);
                    $('#filePaymentType').val(1);
                }
                $('#_Param_Value_1').val($('#filePaymentType').val());
                $('#_Param_Name_1').val('RP=_FILE');
                $('#_Param_Value_4').val($('#_typeCode').val());
                $('#_Param_Name_4').val('RP=_TYPECODE');
                $('#_Param_Value_5').val($('#_functionCode').val());
                $('#_Param_Name_5').val('RP=FUNCTIONCODE');
            } else if (paymentTypeCode === 'BIEFTCCD' && mapID === '0') {
                // PaymentTypeCode is BDEFTCCD for all EFT types.
                if (isEFTPassThru && $('#OPTIONIMPORTMETHODEFT').is(':visible') === false) {
                    $('#filePayment').val(1);
                    $('#filePaymentType').val(1);
                }
                $('#_Param_Value_1').val($('#filePaymentType').val());
                $('#_Param_Name_1').val('RP=_FILE');
                $('#_Param_Value_4').val($('#_typeCode').val());
                $('#_Param_Name_4').val('RP=_TYPECODE');
                $('#_Param_Value_5').val($('#_functionCode').val());
                $('#_Param_Name_5').val('RP=FUNCTIONCODE');
            } else if (paymentMethod === 'CSV' || paymentMethod === 'BAC' || paymentMethod === 'MT101') {
                $('#_Param_Value_1').val($('#filePaymentType').val());
                $('#_Param_Name_1').val('RP=_FILE');
                $('#_Param_Value_4').val($('#_typeCode').val());
                $('#_Param_Name_4').val('RP=_TYPECODE');
                $('#_Param_Value_5').val($('#_functionCode').val());
                $('#_Param_Name_5').val('RP=FUNCTIONCODE');
            } else if (paymentMethod === 'XML') {
                $('#_Param_Value_1').val($('#filePaymentType').val());
                $('#_Param_Name_1').val('RP=_FILE');
                $('#_Param_Value_4').val($('#_typeCode').val());
                $('#_Param_Name_4').val('RP=_TYPECODE');

                appendNonRunTimeParameter('PAYMENTXPATH', './/PmtTpInf/LclInstrm/Prtry');
                appendNonRunTimeParameter('ROOTNODE', '/Document/CstmrCdtTrfInitn/PmtInf');
                if (paymentTypeCode === 'BDACHCD') {
                    appendNonRunTimeParameter('CHILDROOT', '/Document/CstmrCdtTrfInitn/PmtInf/CdtTrfTxInf');
                }
            } else if ($('#_subTypeCode').val() === 'UKBACS' || $('#_subTypeCode').val() === 'UKFP') {
                $('#_Param_Value_1').val($('#filePaymentType').val());
                $('#_Param_Name_1').val('RP=_FILE');
                $('#_Param_Value_4').val($('#_typeCode').val());
                $('#_Param_Name_4').val('RP=_TYPECODE');
            } else {
                $('#_Param_Value_1').val(id);
                $('#_Param_Name_1').val('RP=_INCLUDECODE');
                $('#_Param_Value_4').val($('#_typeCode').val());
                $('#_Param_Name_4').val('RP=_TYPECODE');
                $('#_Param_Value_5').val($('#_functionCode').val());
                $('#_Param_Name_5').val('RP=_FUNCTIONCODE');
            }
        }

        if ($('#_typeCode').val() === 'BDACHNFI') {
            $('#EventName').val('ACHNACHAIMPORT');
        } else if (paymentTypeCode === 'BDEFTCCD' || paymentTypeCode === 'BDEFTCD' || paymentTypeCode === 'BDEFTCC'
            || paymentTypeCode === 'BIEFTCCD' || paymentTypeCode === 'BIEFTCD' || paymentTypeCode === 'BIEFTCC') {
            if (mapID > 0) {
                $('#EventName').val('EFTDETAILIMPORT');
            } else if (paymentTypeDesc !== null && paymentTypeDesc.indexOf('1464') !== -1) {
                $('#EventName').val('EFT FI 1464');
            } else {
                $('#EventName').val('EFT FI 80');
            }
        } else if (paymentMethod === 'CSV' || paymentMethod === 'XML') {
            $('#EventName').val('MULTICSV');
        } else if (paymentMethod === 'MT101') {
            $('#EventName').val('MT101FIMPORT');
        } else if (paymentMethod === 'BAC') {
            $('#EventName').val('BACSIMPORT');
        } else if (paymentTypeCode === 'CIMIMPRT') {
            $('#EventName').val('CIMIMPRTIMPORT');
            model.set('CONFIDENTIALIMPORTS', '');
        } else if (paymentTypeCode === 'STCAIMPT') {
            $('#EventName').val('STCAIMPTIMPORT');
            model.set('CONFIDENTIALIMPORTS', '');
        } else {
            $('#EventName').val('ACHDETAILIMPORT');
        }
        context.subTypeCode = subTypeCode;

        overrideForClientMaps(model);

        /*
         * check if we are creating a payment from a template, if so lets map the
         * Template TNUM
         */
        if (model.get('CREATEFROM') === 'createFromTemplate') {
            $('#_Param_Value_6').val(model.get('TNUM'));
            $('#_Param_Name_6').val('RP=TEMPLATETNUM');
        } else {
            $('#_Param_Value_6').val('');
            $('#_Param_Name_6').val('');
        }
    };

    // is the Grid ready ?
    if (importFormatGrid.grid) {
        rows = importFormatGrid.grid.getSelectedRows();
        if (rows.length === 1) {
            selectedRow = importFormatGrid.wrapper.rows.get(rows[0]);
            setFormData();
        }
    }
    /*
     * HACK: NH-146646
     * When payment is created from template instead of manually
     * the on change model event does not catch this. Adding to end to
     * solve this
     */
    if (model.get('OFFSETACCOUNTNUM')) {
        offsetAccountNum.setValue(maskValue(model.get('OFFSETACCOUNTNUM'), getMaskingProperties()));
    }
}
