import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default Model.extend({
    initialize() {
        this.validators = {
            ss: {
                description: locale.get('payment.subscriberID'),
                exists: true,
            },

            pw: {
                description: locale.get('prompt.password'),
                exists: true,
            },

            confirm: {
                description: locale.get('payment.confirmPassword'),
                sameValue: 'pw',
                otherDescription: locale.get('prompt.newPassword'),
                exists: true,
            },
        };
    },
});
