export default function ({ palette, typography }) {
  return ({
    sortArrow: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAkklEQVQoU9WS0Q3CMAxE7UzACIxgXRboKIzAKB2ho3SBWBmBEZggQYca1ECKxCf+9N2zfE5Ufiwd+c3M2M8553f9CJg2YP0XAMBVRO7uvnBnM+syALiIyMnd51foGONSa10J7QGaVXVKKRGS7koNKqXcKIYQznvzB8DGBtWnqKptcjvv8B0AzDS4O7N1NQS+/ZYHemJJDTPPMqkAAAAASUVORK5CYII=)',
    gridWrapper: {},
    cellHeaderText: {
      textTransform: 'uppercase'
    },
    row: {
      border: 'none',
      shaded: palette.highlightBackground
    },
    whiteBackgroundColor: palette.background,
    actions: {
      dotFill: palette.primary.normal,
      dotHoverBackground: palette.primary.normal,
      dotHoverFill: palette.text.light,
      resetBorder: palette.primary.light,
      buttonColor: palette.text.dark,
      buttonTextDecorationColor: palette.text.dark,
      buttonHoverBackground: palette.grey.G200,
      ...typography
    },
    footer: {
      background: 'rgba(255,255,255,0.95)',
      boxShadow: '0px -3px 3px 0px rgba(0,0,0,0.2)'
    }
  });
}
