import React from 'react';
import locale from '@glu/locale';

/* eslint jsx-a11y/label-has-for: 0  react/prop-types: 0 */
// Note: I don't provide propTypes because there is LOT of REMIT-like fields.

const RemittanceInfo = ({
    TYPE, // 'APCHK' or 'GPCHK'
    REMITAMOUNT1, // APCHK
    REMITAMOUNT2, // APCHK
    REMITAMOUNT3, // APCHK
    REMITDATE1, // APCHK
    // REMITDATE2, // APCHK
    REMITTEXT1, // ? AP or GP
    REMITTEXT5, // ? APCHK
    REMITWHOLERECORD, // GPCHK
}) => {
    let remittanceEntries = [];

    const remittanceData = {
        apchk: { // Invoice Remittance Information
            REMITTEXT1, // Invoice Number
            REMITDATE1, // Date
            REMITTEXT5, // Description
            REMITAMOUNT1, // Gross
            REMITAMOUNT2, // Discount
            REMITAMOUNT3, // Net
            // REMITDATE2, // ?? TODO
        },
        gpchk: { // Free Form Remittance Information
            REMITWHOLERECORD, // Remittance Details
        },
    };

    const title = {
        apchk: locale.get('clm.InvoiceRemitInfo'),
        gpchk: locale.get('clm.FreeFormRemitInfo'),
    };

    const typeCode = TYPE.value.toLowerCase();

    if (typeCode) {
        remittanceEntries = remittanceData[typeCode];

        // meaning, that no need to render empty <DIV><LABEL><SPAN> ... because it will take place.
        // const filtered = remittanceEntries.filter(el => (!!el.label && !!el.value));
        // TODO - maybe
    }

    const entries = remittanceEntries ? Object.values(remittanceEntries) : [];

    return (
        <React.Fragment>
            <div>
                <strong>{title[typeCode] || ''}</strong>
            </div>
            <div className="row">
                {!!entries.length && entries.map((entry, index) => (
                    <div className="col-md-2" key={index}>
                        <label>{entry.label || ''}</label>
                        <p><span>{entry.value || '--'}</span></p>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default RemittanceInfo;
