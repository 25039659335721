var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\"modal-body\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class="
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":45}}})) != null ? stack1 : "")
    + ">\n    <div class=\"form-group\">\n        <label for=\"profileId\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RPT.profileId",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":57}}}))
    + "</label>\n        <input id=\"profileId\" class=\"form-control\" data-bind=\"model\" name=\"PROFILEID\" maxlength=\"50\">\n    </div>\n    <div class=\"form-group\">\n        <label for=\"profileDesc\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RPT.profileDesc",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":61}}}))
    + "</label>\n        <input id=\"profileDesc\" class=\"form-control\" data-bind=\"model\" name=\"PROFILEDESCRIPTION\" maxlength=\"255\">\n    </div>\n    <div>\n        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RPT.sharedFlag",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":41}}}))
    + "</span>\n        <input id=\"sharedFlag\" type=\"checkbox\" data-bind=\"model\" name=\"SHAREDPROFILEFLAG\">\n    </div>\n</div>\n";
},"useData":true});