import 'datePicker';
import $ from 'jquery';
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import constants from 'app/smbPayments/constants';
import FromAccountList from 'app/smbPayments/collections/fromAccountList';
import RtgsHelper from 'app/smbPayments/util/rtgs';
import Decimals from 'common/dynamicPages/api/decimals';
import errHandler from 'system/errHandler';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import moment from 'moment';
import services from 'services';
import http from '@glu/core/src/http';
import alertMessage from 'common/api/alertMessage';
import API from 'app/smb/api';
import userInfo from 'etc/userInfo';
import paymentUtil from 'common/util/paymentUtil';
import accountUtil from 'app/smbPayments/util/accountUtil';
import ContractIdLookup from 'app/transfers/views/contractIdLookup';
import template from './payment.hbs';
import inputMasks from '../../../../common/util/inputMasks';

/*
 * businessDays === '0111110' means Monday - Friday are valid business days
 * and weekends are not. There may be other valid configurations, but they
 * haven't been tested
 */
const SERVER_DATE_FORMAT = 'MM/DD/YYYY';
const defaultBusinessDays = '0111110';
const getDefaultDate = function () {
    return {
        holidays: [],
        businessDays: defaultBusinessDays,
        earliestDay: moment(new Date()).format(SERVER_DATE_FORMAT),
        defaultDay: moment(new Date()).format(SERVER_DATE_FORMAT),
        maxForwardDays: 30,
    };
};

export default Layout.extend({
    template,

    initialize(options) {
        this.noUserSetDate = true;

        this.dates = getDefaultDate();

        this.title = options.title || '';
        this.model = options.model;
        this.fromRTPWorkspace = options.fromRTPWorkspace;

        this.paymentTypeCollection = options.paymentTypeCollection;
        this.fxRateTypePromise = this.getCompanyRTGSPreferences();
        this.fxRateTypePromise.then((result) => {
            this.fxRateType = result.fxRateType;
            this.model.set('fxRateType', this.fxRateType);
        });
        this.amountGroup = 'amountGroup';
        this.amountFields = ['FULL_AMOUNT', 'DISCOUNT_AMOUNT'];
    },

    ui: {
        $paymentAmount: '#paymentAmount',
        $fullAmount: '#fullAmount',
        $discountAmount: '#discountAmount',
        $paymentDate: '#paymentDate',
        $accountSelect: '[data-hook="account-select"]',
        $contractIdCheckbox: '#contractIdCheckbox',
        $exchangeRate: '#exchangeRate',
        $comments: '#commentsText',
        $popovers: '[data-toggle="popover"]',
    },

    events: {
        'keyup @ui.$comments': 'showCountCharacters',
        'blur @ui.$fullAmount': 'toggleAmountsMandatory',
        'blur @ui.$discountAmount': 'toggleAmountsMandatory',
        'blur @ui.$paymentAmount': 'paymentAmountChangedEvent',
        'click @ui.$contractIdCheckbox': 'handleContractIdCheck',
        'blur @ui.$exchangeRate': 'recalculateDebitAmount',
        'change @ui.$paymentDate': 'userSetDate',
    },

    modelEvents: {
        'change:CREDIT_CURRENCY': 'indicativeRateInputsChanged',
        'change:DEBIT_CURRENCY': 'indicativeRateInputsChanged',
        'change:ACCOUNTFILTER': 'debitAccountChanged',
        'change:BENE_NAME': 'beneAccountChanged',
        'change:BENE_ACCOUNT': 'clearContractIdIsChecked',
        'change:VALUE_DATE': 'valueDateChanged',
    },

    regions: {
        accountRegion: '[data-hook="accountRegion"]',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    onRender() {
        this.renderDatePickerContent();

        // show cutoff line if configured to do so
        paymentUtil.showCutoff(this.model.get('CUTOFF_INFO') || this.dates.cutoff_info, this.$('.ui-datepicker-trigger'), this.model.get('PAYMENTTYPE'), this.options.action, this.model.get('STATUS'));

        this.renderPopoverContent();

        this.renderAccount();

        // Render wipes out the amount mask.  Reapply it
        this.applyMask(this.ui.$paymentAmount);
        this.applyMask(this.ui.$discountAmount);
        this.applyMask(this.ui.$fullAmount);
        this.shouldRequire = {
            amountGroup: false,
        };
        this.toggleAmountsMandatory();
        if (this.model.get('contractIdIsChecked')) {
            this.contractIdLookup = new ContractIdLookup({
                formModel: this.model,
                onExchangeRateUpdate: this.onExchangeRateUpdate.bind(this),
                onClearExchangeRate: this.onClearExchangeRate.bind(this),
                prefill: true,
            });
            this.contractIdLookupRegion.show(this.contractIdLookup);
        }
    },
    /**
     * Set the exchange rate and contract id to empty string
     */
    onClearExchangeRate() {
        this.model.set({
            EXCHANGE_RATE: '',
            EXCHANGE_RATE_CONTRACTID: '',
        });
    },

    /**
     * Update the model with the values from the model param
     * @param {Object} model
     */
    onExchangeRateUpdate(model) {
        this.model.set({
            EXCHANGE_RATE: model.get('exchangeRate'),
            EXCHANGE_RATE_CONTRACTID: model.get('contractId'),
            INDICATIVEAMOUNT: '',
            INDICATIVERATE: '',
        });
        this.recalculateDebitAmount();
    },

    userSetDate() {
        // Don't set the datepicker date if the user has already entered a date
        this.noUserSetDate = false;
    },

    applyMask(field) {
        // Inputmask will blowup IE if loaded in render before elements are available
        if (field.get(0) && !field.get(0).inputmask) {
            inputMasks.setupAmountMask(field, {
                integerDigits: 8,
                hidePrefix: true,
                rightAlign: false,
            });
        }
    },

    templateHelpers() {
        return {
            amountText: locale.get('SMBPAY.PaymentAmount'),
            fullAmountText: locale.get('SMBPAY.FullAmount'),
            discountAmountText: locale.get('SMBPAY.DiscountAmount'),
            dateText: locale.get('SMBPAY.PaymentDate'),
            accountText: locale.get('SMBPAY.FromAccount'),
            maxLength: this.model.getCommentMaxLength(),
            showExchangeRate: RtgsHelper.showExchangeRate(this.model),
            contractIdIsChecked: this.model.get('contractIdIsChecked'),
            isFxRateType: this.model.get('fxRateType'),
            cid: this.model.cid,
            isRTPPayment: this.model.get('PAYMENTTYPE') === 'CRTRAN' || this.fromRTPWorkspace,
            amountModificationNotAllowed: this.model.get('AMOUNT_MODIFICATION_ALLOWED') === '0',
        };
    },

    paymentAmountChangedEvent() {
        // pass true to prevent re-rendering of the view
        this.applyMask(this.ui.$paymentAmount);
        this.indicativeRateInputsChanged(true);
    },

    // Modifies the model as if the contract id had been checked
    contractIdCheck() {
        // Add model validators for contract id and exchange rate
        this.model.validators.EXCHANGE_RATE_CONTRACTID = {
            description: locale.get('RTGS.INST.INTL.*.EXCHANGE_RATE_CONTRACTID.label'),
            exists: true,
            rangeLength: [1, 16],
        };

        this.model.validators.EXCHANGE_RATE = {
            description: locale.get('gir.exchRate'),
            exists: true,
            isNumeric: true,
            isGreaterThan: 0,
            maxLength: 22,
        };
        this.model.set('contractIdIsChecked', true);
    },

    // Modifies the model as if the contract id had been unchecked
    contractIdUncheck() {
        // Remove the model validators for contract id and exchange rate
        delete this.model.validators.EXCHANGE_RATE;
        delete this.model.validators.EXCHANGE_RATE_CONTRACTID;
        this.contractIdLookup.removeValidators();
        // The contract id checkbox should be unchecked now
        this.model.set('contractIdIsChecked', false);

        this.model.set({
            EXCHANGE_RATE: '',
            EXCHANGE_RATE_CONTRACTID: '',
            DEBIT_AMOUNT: '',
            ENTERED_AMOUNT_FLAG: 'C',
        });
    },

    /**
     * This is a promise function that will call InquiryService to get the FXRateType
     * for the company .
     * The FXRAteType is used to determine if indicative rate should be retrieved
     * based on usergroup rate entitlements
     */
    getCompanyRTGSPreferences() {
        const url = services.generateUrl('/inquiry/getData');
        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40011,
                },
            },
        };

        //
        return new Promise((resolve) => {
            http.post(url, postData, (data) => {
                let fxRateType = false;

                if (data.inquiryResponse.rows[0].columns[0].fieldName === 'FXRATETYPE'
                    && data.inquiryResponse.rows[0].columns[0].fieldValue === '1') {
                    fxRateType = true;
                }
                resolve({
                    fxRateType,
                });
            }, () => {
                // handle errors
                alertMessage.renderMessage(this, locale.get('common.fxRateType.data.error'), locale.get('common.fxRateType.data.error'));
            });
        });
    },

    /*
     * Indicative rate inputs are credit currency, debit currency, and credit amount.
     * Handle changes to these
     */
    indicativeRateInputsChanged(skipRender) {
        const self = this;
        if (RtgsHelper.showExchangeRate(this.model)) {
            RtgsHelper.doFieldValidation(this.model, 'CREDIT_CURRENCY').then(() => {
                if (self.model.get('contractIdIsChecked')) {
                    self.recalculateDebitAmount();
                } else {
                    /*
                     * Retrieve indicative rate and re-render to show exchange rate and
                     * debit amount
                     */
                    RtgsHelper.getIndicativeRate(self.model)
                        .then(self.render.bind(self)).then(null, errHandler);
                }
            }).then(null, errHandler);
            return;
        }

        // No exchange rate needed and then debit amount is invalid
        this.model.set({
            EXCHANGE_RATE: '',
            EXCHANGE_RATE_CONTRACTID: '',
            DEBIT_AMOUNT: '',
            ENTERED_AMOUNT_FLAG: 'C',
        });

        if (this.model.get('contractIdIsChecked')) {
            // Exchange rates shouldn't be shown, so clear contract id information
            this.contractIdUncheck();
        }

        if (skipRender) {
            return;
        }

        // Hide the exchange rate fields
        this.render();
    },

    // Set up model validators and render view elements if contract id is checked/unchecked
    handleContractIdCheck() {
        const checked = this.ui.$contractIdCheckbox.prop('checked');
        if (checked) {
            this.model.set({
                EXCHANGE_RATE: '',
                EXCHANGE_RATE_CONTRACTID: '',
                ENTERED_AMOUNT_FLAG: 'C',
            });
            this.contractIdCheck();

            // Render to get the contract id and exchange rate to display
            this.render();
        } else {
            this.contractIdUncheck();

            // Set the exchange rate back to the indicative rate
            this.indicativeRateInputsChanged();
        }
    },

    recalculateDebitAmount() {
        const precision = Decimals.getNumberOfDecimalPlaces(this.model.get('DEBIT_CURRENCY'));
        const creditAmount = Formatter.stringToNumber(this.model.get('CREDIT_AMOUNT'));
        const exchangeRate = Formatter.stringToNumber(this.model.get('EXCHANGE_RATE'));
        let amount;
        /*
         * Replicate debit amount calculation from
         * common/policies/payments/rtgs.updateDebitOrCreditAmount(), with
         * isDebitSelected === false (since SMB requires the user to input a credit amount)
         */
        if (this.model.get('MARKETCONVENTION') === 'D') {
            amount = creditAmount * exchangeRate;
        } else {
            amount = creditAmount / exchangeRate;
        }
        // Guaranteed to be a number, here.
        if (Number.isNaN(amount) || !Number.isFinite(amount)) {
            amount = 0;
        }
        this.model.set('DEBIT_AMOUNT', amount.toFixed(precision));
    },

    /**
     * if either Full Amount or Discount Amount is entered, make them both required
     */
    toggleAmountsMandatory() {
        this.applyMask(this.ui.$discountAmount);
        this.applyMask(this.ui.$fullAmount);
        // applying mask sometimes set value to 0.00
        this.resetAmountFields();
        paymentUtil.toggleFieldsMandatory(this.amountGroup, this.amountFields, false, this, '.form-group');
        if (this.shouldRequire[this.amountGroup]) {
            this.model.addValidatorProp('FULL_AMOUNT', 'description', locale.get('SMBPAY.FullAmount'));
            this.model.addValidatorProp('DISCOUNT_AMOUNT', 'description', locale.get('SMBPAY.DiscountAmount'));
        }
    },

    /**
     * if amounts are set to 0 due to applyMask, set it to empty
     */
    resetAmountFields() {
        const amountFields = this.amountFields.reduce((accum, fieldName) => {
            if (this.model.get(fieldName) === '0.00') {
                return {
                    ...accum,
                    [fieldName]: '',
                };
            }
            return accum;
        }, {});
        this.model.set(amountFields);
    },

    paymentTypeChanged() {
        /*
         * Can't modify paymentType in any action except create(null) so when
         * paymentType changes, reset debit account number
         */
        if (this.options.action === null) {
            this.model.set('ACCOUNTFILTER', '');
        }
        this.clearContractIdIsChecked();

        const paymentType = this.model.get('PAYMENTTYPE') || this.model.get('TYPE');
        if (paymentType) {
            const subType = constants.isWire(paymentType) ? '' : 'NACHA';
            const creditCurrency = this.model.get('CREDIT_CURRENCY');
            const debitCurrency = this.model.get('DEBIT_CURRENCY');

            const datesPromise = API.dates.get({
                paymentType,
                subType,
                creditCurrency,
                debitCurrency,
            });

            datesPromise.then((results) => {
                this.dates = results;
                this.render();
            }).then(null, errHandler);
        } else {
            this.render();
        }
    },

    // Helper method to an attribute from the account's mapDataList
    getAccountAttribute(account, attr) {
        const mapDataList = account.get('mapDataList');
        if (!mapDataList) {
            return '';
        }
        const attribute = util.findWhere(
            mapDataList,
            {
                toField: attr,
            },
        );
        return attribute ? attribute.value : '';
    },

    /*
     * @method beneAccountChanged
     * Method is to do any updated required, when we change the bene information
     * on the screen
     */
    beneAccountChanged() {
        this.clearContractIdIsChecked();
        this.updateCutoff(this.model);
    },

    /**
     * Clear the contractIdIsChecked value from the model
     */
    clearContractIdIsChecked() {
        this.model.unset('contractIdIsChecked');
    },

    /**
     * Fedwire accounts exist in account.id - ACH accounts exist in mapDataList
     */
    getAccountNumber(account, typeCode) {
        if (constants.isWire(typeCode)) {
            return account.id;
        }
        if (constants.isACH(typeCode)) {
            return this.getAccountAttribute(account, 'OFFSETACCOUNTNUM');
        }
        return undefined;
    },

    /**
     * @method debitAccountChanged
     * Method is for non-meta-data SMB payment screens. It is called when the Account
     * field's value is intially set or if the value changed. The DEBIT_ACCOUNT_NUMBER
     * field is what is displayed on the payment screens. This can be used directly for
     * RTGS payments.  ACH payments use the ORIGCOMPID and ORIGCOMPNAME fields, so the
     * value must be copied to and from the model as necessary. The account currency
     * type is also set for the selected account.
     */
    debitAccountChanged() {
        if (!this.fromAccountList) {
            return;
        }
        const self = this;
        let accountCurrencyAttribute;
        const paymentType = this.model.get('PAYMENTTYPE');
        const subType = constants.isWire(paymentType) ? '' : 'NACHA';
        let debitCode;
        let dateCode;
        let balanceRequest;
        let balanceResult;
        let accountData;
        let balancePromise = Promise.resolve('{}');
        let debitAccountNum;

        this.model.account = this.fromAccountList.findWhere({
            id: this.model.get('ACCOUNTFILTER'),
        });

        if (paymentType && constants.isACH(paymentType)) {
            /**
             * Set the ACH payment account value.
             * If initial state and MODIFY, set From Account to the originator in the model.
             * If the combo selection changed, set the originator from the account field
             * to the model.
             *
             */
            if ((util.isNullOrUndefined(this.model.get('text')) || util.isEmpty(this.model.get('text'))) && !util.isEmpty(this.model.get('TNUM'))) {
                if (this.model.get('ORIGCOMPID') && this.model.get('ORIGCOMPNAME')) {
                    this.model.account = this.fromAccountList.get(`${this.model.get('ORIGCOMPID')} / ${this.model.get('ORIGCOMPNAME')}`);
                } else if (this.model.get('CMB_ORIGINATOR_ID') && this.model.get('CMB_ORIGINATOR_NAME')) {
                    this.model.account = this.fromAccountList.get(`${this.model.get('CMB_ORIGINATOR_ID')} / ${this.model.get('CMB_ORIGINATOR_NAME')}`);
                }
                /*
                 * Must set the correct value in the model for the DEBIT_ACCOUNT_NUMBER,
                 * otherwise changes to this field will not always trigger a change.
                 */
                if (this.model.account) {
                    this.model.set({
                        ACCOUNTFILTER: this.model.account.get('id'),
                    }, {
                        silent: true,
                    });
                }
            } else if (this.model.account) {
                this.model.set('ORIGCOMPID', this.getAccountAttribute(this.model.account, 'ORIGCOMPID'));
                this.model.set('ORIGCOMPNAME', this.getAccountAttribute(this.model.account, 'ORIGCOMPNAME'));
                this.model.set('OFFSETACCOUNTNUM', this.getAccountAttribute(this.model.account, 'OFFSETACCOUNTNUM'));
            }
        }
        if (this.model.account) {
            debitAccountNum = util.findWhere(
                this.model.account.get('mapDataList'),
                {
                    toField: 'DEBIT_ACCOUNT_NUMBER',
                },
            );

            if (!util.isNullOrUndefined(debitAccountNum)) {
                this.model.set('DEBIT_ACCOUNT_NUMBER', debitAccountNum.value);
            }

            debitCode = util.findWhere(
                this.model.account.get('mapDataList'),
                {
                    toField: 'DEBIT_BANK_CODE',
                },
            );
            dateCode = debitCode ? 'DEBIT_BANK_CODE' : 'BankCode';
        }
        const debitBank = this.model.account
            ? this.getAccountAttribute(this.model.account, dateCode) : false;
        if (constants.isACH(paymentType)) {
            accountCurrencyAttribute = 'ORIGCURRENCYCODE';
        } else if (paymentType === constants.FEDWIRE) {
            accountCurrencyAttribute = 'CREDIT_CURRENCY';
        } else {
            accountCurrencyAttribute = 'DEBIT_CURRENCY';
        }

        const creditCurrency = this.model.get('CREDIT_CURRENCY');
        const debitCurrency = this.model.get('DEBIT_CURRENCY');

        const datesPromise = API.dates.get({
            paymentType,
            subType,
            debitBank: this.model.account
                ? this.getAccountAttribute(this.model.account, dateCode) : false,
            creditCurrency,
            debitCurrency,
        });

        /*
         * NH-86823 if the account balance is not available for the selected account,
         * fetch the balance and update the text.
         */
        if (this.fromAccountList.dispAcctBalance
            && this.fromAccountList.dispAcctBalance === 'true'
            && this.model.account
            && !this.model.account.get('accountBalance')) {
            balanceRequest = {
                accountNumber: this.getAccountNumber(this.model.account, paymentType),
                bankCode: constants.isACH(paymentType)
                    ? this.getAccountAttribute(this.model.account, 'OFFSETBANKCODE')
                    : debitBank,
                currency: this.model.account
                    ? this.getAccountAttribute(this.model.account, accountCurrencyAttribute) : '',
                functionCode: this.model.get('PAYMENTFUNCTIONCODE'),
                productCode: this.model.get('PAYMENTPRODUCTCODE'),
                typeCode: paymentType,
            };

            balancePromise = new Promise((resolve, reject) => {
                http.post(services.balanceAndTransaction, balanceRequest, (data) => {
                    resolve(data);
                }, () => {
                    reject((locale.get('payment.balance.none')));
                });
            });
        }

        Promise.all([datesPromise, balancePromise]).then((results) => {
            self.dates = util.extend(self.dates, results[0]);
            [, balanceResult] = results;
            /*
             * Account might not exist if the selected debit account number
             * isn't valid for a new payment type.  For Example, a debit account number
             * might be present for a wire, but if the user changes the payment type to ACH,
             * debit account number will exist but not be valid, and no account will be
             * found for it
             */
            if (self.model.account && paymentType) {
                if (constants.isACH(paymentType)) {
                    accountCurrencyAttribute = 'ORIGCURRENCYCODE';
                } else if (paymentType === constants.FEDWIRE) {
                    accountCurrencyAttribute = 'CREDIT_CURRENCY';
                } else {
                    accountCurrencyAttribute = 'DEBIT_CURRENCY';
                }
                self.model.set('DEBIT_CURRENCY', self.getAccountAttribute(self.model.account, accountCurrencyAttribute));
                self.model.set('text', self.model.account.get('text'));

                self.model.set({
                    CREDIT_CURRENCY: self.getCreditCurrency(),
                    DEBIT_CURRENCY: self.getAccountAttribute(
                        self.model.account,
                        accountCurrencyAttribute,
                    ),
                    text: self.model.account.get('text'),
                });

                if (paymentType && constants.isACH(paymentType)) {
                    self.renderDatePickerContent();
                } else {
                    RtgsHelper.doFieldValidation(self.model, 'VALUE_DATE').then(() => {
                        self.renderDatePickerContent();
                    });
                }
            } else {
                /*
                 * No account was found, possibly because the payment type changed.
                 *  Clear the debit account number
                 * Quietly to prevent an infinite loop
                 */
                if (constants.isACH(paymentType)) {
                    self.model.set({
                        ORIGCOMPNAME: '',
                        ORIGCOMPID: '',
                        OFFSETACCOUNTNUM: '',
                    }, {
                        silent: true,
                    });
                } else {
                    self.model.set({
                        ACCOUNTFILTER: '',
                    }, {
                        silent: true,
                    });
                }
                self.model.set({
                    DEBIT_CURRENCY: '',
                    text: '',
                });
            }

            if (balanceResult && balanceResult.balance) {
                accountData = util.find(
                    self.accountComboData,
                    comboAccount => (comboAccount.name === self.model.account.id
                        || comboAccount.id === self.model.account.id),
                );
                if (accountData.label) {
                    accountData.label = `${accountData.label} - ${balanceResult.balance} ${locale.get('smbPayments.available')}`;
                } else {
                    accountData.text = `${accountData.text} - ${balanceResult.balance} ${locale.get('smbPayments.available')}`;
                }
                self.model.set('text', accountData.label || accountData.text);
                self.model.account.set('accountBalance', balanceResult.balance);
                self.ui.$accountSelect.comboBox('data', accountData);
            }

            self.renderDatePickerContent();
        });
    },

    /**
     * Get the permitted currency based on app configuration and debit
     * and credit currencies
     * @returns {string} - currency string
     */
    getCreditCurrency() {
        if (RtgsHelper.isUSDOnly.call(this)) {
            return 'USD';
        } else if (this.model.beneAccount) {
            return this.model.beneAccount.get('BENE_ACCOUNT_CURRENCY');
        }
        return this.model.get('CREDIT_CURRENCY');
    },

    valueDateChanged() {
        /*
         * NH-99473 we need to call the do field validation every time a value date is changed
         * (not just for cross currency) so that the tran date is updated as per the holidays
         * and the forward days.
         */
        const paymentType = this.model.get('PAYMENTTYPE');
        if (paymentType && !constants.isACH(paymentType)) {
            RtgsHelper.doFieldValidation(this.model, 'VALUE_DATE');
        }
        // set model values to make call to server to update cutoff when value date changes
        this.updateCutoff(this.model);
    },

    showCountCharacters(e) {
        const $target = $(e.target);
        const $targetTxtAreaVal = $target.val();
        const cmtsTxtAreaReMainLen = this.model.getCommentMaxLength() - $targetTxtAreaVal.length;
        $target.parent().find('.char-counter')
            .text(`${locale.get('sbPayments.charactersRemaining')} ${cmtsTxtAreaReMainLen}`);
    },

    renderPaymentAlertContent(options) {
        // Display notification message
        const message = this.parseMessage(options);

        this.alertView = alert.danger(
            message,
            {
                details: false,
                icon: false,
                isError: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    renderDatePickerContent() {
        const defaultDayMoment = moment(this.dates.defaultDay, SERVER_DATE_FORMAT);

        this.ui.$paymentDate.nhDatePicker({
            blockedDates: this.dates.holidays,
            processingDays: this.dates.businessDays,
            daysBack: 0,
            daysForward: this.dates.maxForwardDays,
            showCalendarIcon: true,
        });

        this.ui.$paymentDate.data('daterangepicker').updateCalendars({
            earliestDay: this.dates.earliestDay,
            defaultDay: this.dates.defaultDay,
            daysForward: this.dates.maxForwardDays,
            daysBack: 0,
        });

        /*
         * Set the VALUE_DATE if it isn't set, and allow it to change
         * if earliestDayMoment changed and it is a new payment
         */
        if (!this.model.get('VALUE_DATE') || !this.model.get('CREDIT_AMOUNT')
            || (this.noUserSetDate && !this.model.get('TNUM'))) {
            this.model.set('VALUE_DATE', defaultDayMoment.format(userInfo.getDateFormat()));
        }

        this.updateCutoff(this.model);
    },

    unescapeData(dataObjParam) {
        const dataObj = dataObjParam;
        Object.keys(dataObj || {}).forEach((prop) => {
            dataObj[prop] = util.unescape(dataObj[prop]);
        });
        return dataObj;
    },

    renderPopoverContent() {
        const options = {
            trigger: 'hover',
        };

        this.ui.$popovers.popover(options);
    },

    renderAccount() {
        // If the payment type is ACH, do not allow the to account to be modified
        const accountSelectEnabled = constants.isACH(this.model.get('TYPE'))
            && this.model.isExistingPayment();

        this.ui.$accountSelect.comboBox({
            placeholder: locale.get('SMBPAY.Select'),
            enabled: accountSelectEnabled,
            query: util.debounce((query) => {
                this.getAccountComboData(query).then(() => {
                    query.callback({
                        results: this.accountComboData,
                    });
                    // set the account
                    this.debitAccountChanged();
                }).catch(() => {
                    query.callback({
                        results: [],
                    });
                });
            }, constants.COMBO_DEBOUNCE),
        });

        // When we have a paymentType set the debit account number
        const paymentType = this.model.get('PAYMENTTYPE');
        if (!util.isNullOrUndefined(paymentType) && paymentType !== '') {
            this.getAccountComboData().then(() => {
                const account = util.findWhere(this.accountComboData, {
                    id: this.model.get('ACCOUNTFILTER'),
                });
                if (account !== undefined) {
                    this.ui.$accountSelect.comboBox('data', {
                        id: account.id,
                        text: account.text,
                    });
                } else {
                    this.model.set('ACCOUNTFILTER', '');
                }
                this.debitAccountChanged();
            }).catch(() => {
                this.model.set('ACCOUNTFILTER', '');
            });
        }

        if (this.payeeNotApproved) {
            this.ui.$accountSelect.comboBox('enable', false);
            this.ui.$paymentDate.attr('disabled', true);
            this.ui.$paymentAmount.attr('disabled', true);
            this.ui.$comments.attr('disabled', true);
        }
    },

    /**
     * Fetch the accounts based on the account options
     * @param {Object} query - query object from select2
     * @returns {Promise}
     */
    fetchAccounts(query) {
        return new Promise((resolve, reject) => {
            const fromAccounts = new FromAccountList(accountUtil.getFromAccountOpts(this.model));
            fromAccounts.fetch({
                success: (data) => {
                    // Can't add filtering to backend, so filter data set after fetch
                    if (!util.isNullOrUndefined(query) && query.term !== '') {
                        const filteredData = data.filter((item) => {
                            const text = item.get('text');
                            const name = item.get('name');

                            const matchesQuery = [text, name]
                                .filter(string => string.includes(query.term));
                            return matchesQuery.length;
                        });
                        // data is the fromAccounts collection, so set the collection to update
                        fromAccounts.set(filteredData);
                        resolve(fromAccounts);
                        return;
                    }
                    resolve(data);
                },
                error: (data) => {
                    reject(data);
                },
            });
        });
    },

    /**
     * Parse the response collection and set fromAccountList
     * @param {Object} result - standard getQueryResults response
     */
    parseAccountData(result) {
        // Unescape html characters
        util.each(result.models, (model) => {
            model.set({
                name: util.unescape(model.get('name')),
                id: util.unescape(model.get('id')),
                label: util.unescape(model.get('label')),
                text: util.unescape(model.get('text')),
            }, {
                silent: true,
            });
        });

        this.fromAccountList = result;
    },

    /**
     * Filter accounts that are USD, since for INTL payments, the server returns all
     * accounts regardless of currency.  Doing this here because fromAccountList might
     * be used for other purposes that don't have the USD restriction
     */
    filterFromAccountList() {
        if (this.model.get('PAYMENTTYPE') === constants.INTL) {
            this.fromAccountList.reset(this.fromAccountList.filter(model => this.getAccountAttribute(model, 'DEBIT_CURRENCY') === 'USD'));
        }
    },

    /**
     * Get the account data and transform it
     * @param {Object} query - query object from select2
     * @returns {Promise}
     */
    getAccountComboData(query) {
        return this.fetchAccounts(query)
            .then(this.parseAccountData.bind(this))
            .then(this.filterFromAccountList.bind(this))
            .then(this.setAccountComboData.bind(this));
    },

    /**
     * Transform the fromAccountList and set accountCombotData
     */
    setAccountComboData() {
        this.accountComboData = this.fromAccountList.map(model => ({
            id: model.get('id'),
            text: model.get('text'),
        }));
    },

    /*
     * @description This pieces together the model values, prior to calling a
     * common payment cutoff update to the server
     * Information is stored differently, based on whether it is
     * ACH or RTGS, so we must bring it to a common model.
     * @param model
     */
    updateCutoff(model) {
        const self = this;
        const product = model.get('PAYMENTPRODUCTCODE');
        const functionCode = model.get('PAYMENTFUNCTIONCODE');
        const typeCode = model.get('PAYMENTTYPE');
        const subType = model.get('PAYMENTCLEARINGSYSTEM');

        // set common data
        model.set({
            EFFECTIVEDATE: model.get('VALUE_DATE'),
            SAMEDAYACH: model.get('SAMEDAYACH'),
        });
        // get bene information if exists
        if (model.beneAccount) {
            const mapDataList = model.beneAccount.get('mapDataList');
            model.set({
                BENE_BANK_CODE: mapDataList
                    ? this.getAccountAttribute(model.beneAccount, 'BENE_BANK_NAME')
                    : model.beneAccount.get('BENE_BANK_NAME'),
                BENE_BANK_COUNTRY: mapDataList
                    ? this.getAccountAttribute(model.beneAccount, 'BENE_BANK_COUNTRY')
                    : model.beneAccount.get('BENE_BANK_COUNTRY'),
                BENE_BANK_ID: mapDataList
                    ? this.getAccountAttribute(model.beneAccount, 'BENE_BANK_ID')
                    : model.beneAccount.get('BENE_BANK_ID'),
                BENE_BANK_TYPE: mapDataList
                    ? this.getAccountAttribute(model.beneAccount, 'BENE_BANK_IDTYPE')
                    : model.beneAccount.get('BENE_BANK_IDTYPE'),
                RECEIVABA: mapDataList
                    ? this.getAccountAttribute(model.beneAccount, 'BENE_BANK_ID')
                    : model.beneAccount.get('BENE_BANK_ID'),
                BENE_BANK_IDTYPE: mapDataList
                    ? this.getAccountAttribute(model.beneAccount, 'BENE_BANK_IDTYPE')
                    : model.beneAccount.get('BENE_BANK_IDTYPE'),
            });
        }
        // set debit information if exists
        if (model.account) {
            const debitDataList = model.account.get('mapDataList');
            if (product === 'RTGS') {
                model.set({
                    DEBIT_BANK_CODE: debitDataList ? this.getAccountAttribute(model.account, 'DEBIT_BANK_CODE') : model.account.get('DEBIT_BANK_CODE'),
                    DEBIT_COUNTRY: debitDataList ? this.getAccountAttribute(model.account, 'DEBIT_COUNTRY') : model.account.get('DEBIT_COUNTRY'),
                });
            } else {
                model.set({
                    DEBIT_BANK_CODE: debitDataList ? this.getAccountAttribute(model.account, 'BankCode') : model.account.get('DEBIT_BANK_CODE'),
                    DEBIT_COUNTRY: debitDataList ? this.getAccountAttribute(model.account, 'ORIGBANKCOUNTRY') : model.account.get('DEBIT_COUNTRY'),
                });
            }
        }
        if (model.beneAccount || model.account) {
            paymentUtil.updateCutoff(product, functionCode, typeCode, subType === undefined ? 'NACHA' : subType, 'INSERT', model, this.$('.ui-datepicker-trigger'));
        } else {
            /*
             * we dont have enough information (neither debit account or bene account),
             * so just do a regular date list call
             */
            API.updateCutoff.get(model, this.$('.ui-datepicker-trigger'), typeCode, API.dates).then((rtnDates) => {
                self.dates = rtnDates;
            });
        }
    },
});
