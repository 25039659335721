import $ from 'jquery';
import moment from 'moment';
import util from 'underscore';
import { log } from '@glu/core';
import http from '@glu/core/src/http';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import viewBinding from 'system/gluOverride/core/internal/viewBinding';

// "alias" imports separated from "global" imports, above
import constants from 'common/dynamicPages/api/constants';
import BeneUpdatedWarning from 'common/dynamicPages/views/beneUpdatedWarning';
import PaymentUtil from 'common/util/paymentUtil';
import dateUtil from 'common/util/dateUtil';
import userInfo from 'etc/userInfo';
import services from 'services';
import Formatter from 'system/utilities/format';
import mobileUtil from 'mobile/util/mobileUtil';

// "local" imports belong after the "global" imports
import rtgsCommon from './rtgsCommon';

const hasUserEnteredDesc = (model) => {
    const userEntered = model.get('UE_ENTRYDESC');
    return userEntered !== '' && !util.isNullOrUndefined(userEntered);
};

const updateUEEntryDescFromEntryDesc = (model, value) => {
    /*
     * Update the UE_ENTRYDESC in three scenarios
     * 1. User hasn't entered UE_ENTRYDESC
     * 2. UE_ENTRYDESC is set to default ('PAYROLL')
     * 3. ENTRYDESC was cleared, set to default ('PAYROLL') or updated
     * to 'REIMBURSE' from the COMPIDNAME
     */
    const invalidValues = ['', 'PAYROLL', 'REIMBURSE'];
    if (!hasUserEnteredDesc(model)
        || model.get('UE_ENTRYDESC') === 'PAYROLL'
        || !invalidValues.includes(value)
    ) {
        model.set('UE_ENTRYDESC', value);
    }
};

const updateEntryDescAfterCompid = (model) => {
    const entryDesc = model.get('ENTRYDESC');
    if (entryDesc === 'PAYROLL' || !hasUserEnteredDesc(model)) {
        model.set('ENTRYDESC', 'REIMBURSE');
    }
};

const initialRepair = (form) => {
    Object.keys(form.fields || {}).forEach((field) => {
        /*
         * NH-52602 - don't set fields within the bene grid
         * batch update dropdowns read only
         */
        if (field === 'AMOUNT'
            || field === 'EFFECTIVEDATE'
            || field === 'edit-amounts-amount'
            || field === 'bulk-update-affected') {
            return;
        }
        form.fields[field].shouldBeReadOnly(true);
    });
    $('textarea[name="BATCHCOMMENT"]').prop('readonly', true);
};

/**
 * This is the core logic that is executed by applyPolicies
 * In some sections of this policy, you will find that we use
 * jQuery rather than the F1 form.field() method.
 * This is due to the fields being in a child region and
 * inaccessible to F1. - RB 10/20/15
 */
export default function (form, initialState) {
    const { formView } = form;
    const viewModel = formView.model;
    const formState = form.formView.context.actionMode
        || form.formView.context.actionData.actionMode;
    const { childViewRegion } = formView;
    const paymentType = (viewModel.jsonData.typeInfo) ? viewModel.jsonData.typeInfo.typeCode : '';
    const functionCode = (viewModel.jsonData.typeInfo) ? viewModel.jsonData.typeInfo.functionCode : '';

    // Strings?
    const totalAmountCreditFieldName = 'TOTALAMTCREDIT';
    const totalAmountDebitFieldName = 'TOTALAMTDEBIT';
    const totalNumCreditFieldName = 'TOTALNUMCREDIT';
    const totalNumDebitFieldName = 'TOTALNUMDEBIT';
    const transactionAmt = 'TRANSACTION_AMOUNT';
    const totalFieldName = 'TOTALAMT';
    const numberBeneFieldName = 'TOTALNUM';

    /*
     * Pure jQuery selectors, not using the F1 library.
     * THESE ARE GLOBAL - they may be outside the form element.
     */
    const $creditOnlyBlocks = $('.credit-only');
    const $debitOnlyBlocks = $('.debit-only');
    const $datePicker = $('input[name="EFFECTIVEDATE"]');
    const $reverseSelection = $('[name="REVERSESELECTION"]');
    const $reverseSelectionAll = $('[id="REVERSESELECTION-All"]');
    const $reverseReason = $('[name="REVERSEREASON"]');
    const $saveAsTemplate = $('[data-section="save-as-template"]');
    const $summarySection = $('[data-widget-id="summarysection"]').closest('fieldset');
    const $paymentSummaryContainer = $('[data-section="payment-summary"]');
    const $currencyFields = $('[data-field="summary-currency"]');
    const $summaryDate = $('[data-field="summary-date"]');
    const $blockTitle = $('.section-summary div[data-section="payment-summary"] .total-heading');

    // F1 Fields
    const aFieldInAuditSection = form.field('VIEWHISTORY');
    const saveAsTemplate = form.field('SAVEASTEMPLATE');
    const templateCode = form.field('TEMPLATECODE');
    const templateDescription = form.field('TEMPLATEDESCRIPTION');
    const restrict = form.field('RESTRICTTEMPLATE_FLAG');
    const compIDCombo = form.field('COMPIDNAME');
    const offset = form.field('OFFSETACCOUNTNUM');
    const effectiveDate = form.field('EFFECTIVEDATE');
    const compdescdate = form.field('COMPDESCDATE');
    const sameDayACHField = form.field('SAMEDAYACH');
    const addChild = form.field('addChild');
    const holdAllChild = form.field('holdAllChild');
    const reverseReason = form.field('REVERSEREASON');
    const entryMethod = viewModel.get('ENTRYMETHOD');
    const healthCareCheckBox = form.field('HEALTHCARE');
    const tmplMax = form.field('TEMPLATE_MAX_AMOUNT');
    const amount = form.field('AMOUNT');
    const chunkedBatchSeq = $('[data-text="PARTNUM"]');

    let id = '';
    let compIDName = '';
    let balanceSelector = '';
    let allowMaxTmplAmount = viewModel.get('ALLOWMAXAMTPAYMENTFROMTMPL') ||
        viewModel.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL ?
        viewModel.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;

    const updateTotalsDisplay = function () {
        // Format the payment total and set beneficiary count
        const $amountFields = $('[data-field="summary-amount"]');

        const $creditAmountFields = $paymentSummaryContainer.find('[data-field="summary-credit-amount"]');
        const $debitAmountFields = $paymentSummaryContainer.find('[data-field="summary-debit-amount"]');
        const $beneficiariesFields = $paymentSummaryContainer.find('[data-field="summary-beneficiaries"]');
        const $numberDebit = $paymentSummaryContainer.find('[data-bind="TOTALNUMDEBIT"]');
        const $numberCredit = $paymentSummaryContainer.find('[data-bind="TOTALNUMCREDIT"]');
        const paymentTotalFormatted =
            Formatter.formatCurrency(viewModel.get(totalFieldName) || 0);
        const creditTotalFormatted =
            Formatter.formatCurrency(viewModel.get(totalAmountCreditFieldName) || 0);
        const debitTotalFormatted =
            Formatter.formatCurrency(viewModel.get(totalAmountDebitFieldName) || 0);
        const beneficiariesCount = (viewModel.get(numberBeneFieldName) || 0);

        // Update the values of the date and beneficiary count summaries.
        $beneficiariesFields.text((beneficiariesCount === 1) ? `${beneficiariesCount} ${locale.get('PAY.beneficiary')}` : `${beneficiariesCount} ${locale.get('PAY.beneficiaries')}`);

        /*
         * Update the values of the amount summaries. Some of these may be inactive
         * based on the payment type.
         */
        $amountFields.text(paymentTotalFormatted);
        $creditAmountFields.text(creditTotalFormatted);
        $debitAmountFields.text(debitTotalFormatted);

        /*
         * Update the display of the credit/debit totals based respectively on their
         * total numbers.
         */
        $numberCredit.text(viewModel.get(totalNumCreditFieldName) || 0);
        $numberDebit.text(viewModel.get(totalNumDebitFieldName) || 0);

        if (paymentType === 'BDACHCCD'
            || paymentType === 'BDACHCCP'
            || paymentType === 'BDACHIAT'
            || (paymentType === 'BDACHNFI'
                && (viewModel.get('ENTRYCLASS') === 'CTX'
                    || viewModel.get('ENTRYCLASS') === 'CCD'
                    || viewModel.get('ENTRYCLASS') === 'PPD'
                    || viewModel.get('ENTRYCLASS') === 'IAT')
            )) {
            $creditOnlyBlocks.toggle(true);
            $debitOnlyBlocks.toggle(true);
            $blockTitle.toggle(true);
        }
        // PARTNUM field text is replaced with text value "PARTNUM of PARTTOTAL"
        chunkedBatchSeq.text(`${viewModel.attributes.PARTNUM} ${locale.get('of')} ${viewModel.attributes.PARTTOTAL}`);

        if (initialState && functionCode.indexOf('TMPL') < 0 && formState !== 'view') {
            const amtBlock = $summaryDate.last();
            PaymentUtil.showTemplateMaxAmtForPayment(viewModel, amtBlock, viewModel.get('TEMPLATE_MAX_AMOUNT') !== '', false, true);
        }
    };

    const onChildSaveError = function () {
        viewModel.set('lastSaveFailed', true);
    };

    const updateBatchTotals = function () {
        const vmEffectiveDate = viewModel.get('EFFECTIVEDATE');

        const postData = {
            productCode: viewModel.jsonData.typeInfo.productCode,
            typeCode: viewModel.jsonData.typeInfo.typeCode,
            functionCode: viewModel.jsonData.typeInfo.functionCode,
            subType: viewModel.jsonData.subtype === undefined ? 'NACHA' : viewModel.jsonData.subtype,
            entryClass: (viewModel.context.actionData === undefined || viewModel.context.actionData.entryClass === undefined) ? viewModel.get('ENTRYCLASS') : viewModel.context.actionData.entryClass,
            effectiveDate: moment(vmEffectiveDate || form.field('EFFECTIVEDATE').getValue(), [userInfo.getDateFormat(), 'YYYY-MM-DD HH:mm:ss.S']).format('MM/DD/YYYY'),
            user: viewModel.get('ENTERED_BY'),
            userGroup: viewModel.get('USERGROUP'),
            batchSeqNumber: viewModel.get('BATCHSEQNUM'),

        };

        const totalService = services.generateUrl(constants.URL_GETTOTALS);

        http.post(totalService, postData, (result) => {
            let displayTotalAmount = Number(result.totalAmount) || 0;
            let displayTotalDebitAmount = Number(result.debitAmount) || 0;
            let displayTotalCreditAmount = Number(result.creditAmount) || 0;
            let displayTotalNumDebit = Number(result.debitTransactions) || 0;
            let displayTotalNumCredit = Number(result.creditTransactions) || 0;
            let displayTotalNumBenes = Number(result.totalTransactions) || 0;

            /*
             * Account for amounts that may not be saved on back end when updating the
             * totalAmount
             * Added lastSaveFailed variable, to avoid adding children from last
             * failed save attempt to total amount
             */
            if (form.formView.childView && form.formView.childView.model && form.formView.childView.model.get('AMOUNT') && !viewModel.get('lastSaveFailed')) {
                const currentChildAmount = form.formView.childView.model.get('AMOUNT');
                const currentChildTranType = form.formView.childView.model.get('TRANTYPE');

                displayTotalAmount += Formatter.unformatNumber(currentChildAmount);
                displayTotalNumBenes += 1;

                if (currentChildTranType === 'DB') {
                    displayTotalDebitAmount += Formatter.unformatNumber(currentChildAmount);
                    displayTotalNumDebit += 1;
                } else if (currentChildTranType === 'CR') {
                    displayTotalCreditAmount += Formatter.unformatNumber(currentChildAmount);
                    displayTotalNumCredit += 1;
                }
            }
            viewModel.set({
                // Set the number of beneficiaries.
                [numberBeneFieldName]: displayTotalNumBenes,
                [totalFieldName]: Formatter.formatNumber(displayTotalAmount),
                [transactionAmt]: result.totalAmount,
                // Update the credit and debit amounts on the model.
                [totalAmountCreditFieldName]:
                    Formatter.formatNumber(displayTotalCreditAmount),
                [totalAmountDebitFieldName]:
                    Formatter.formatNumber(displayTotalDebitAmount),
                [totalNumCreditFieldName]: displayTotalNumCredit,
                [totalNumDebitFieldName]: displayTotalNumDebit,
            });

            updateTotalsDisplay();
        });
    };

    const showOrHideSameDayWarning = function (show) {
        if (show) {
            if ($('#sda-warning').length < 1) {
                const errorCode = 'ACH.allowSameDayWarningInfo';
                const alertMsg = `<div id="sda-warning"><span class="sda-warning icon-exclamation-solid"></span>${locale.get(errorCode, viewModel.get('EFFECTIVEDATE'))}</div>`;
                $('#SAMEDAYACH').parent().append(alertMsg);
            }
        } else {
            $('#sda-warning').remove();
        }
    };

    const updateCutoff = function () {
        let clearReceivSetting = false;
        // currently not supporting EFT
        if (paymentType.indexOf('EFT') < 0) {
            /*
             * if we have the bene aba available, set to the model for more accurate
             * cutoff retrieval..
             * then we can clear it out again
             */
            if (childViewRegion && childViewRegion.currentView && !viewModel.get('RECEIVABA')) {
                viewModel.set(
                    'RECEIVABA',
                    childViewRegion.currentView.model.get('RECEIVABA'),
                    {
                        silent: true,
                    },
                );
                clearReceivSetting = true;
            }
            PaymentUtil.updateCutoff(
                viewModel.jsonData.typeInfo.productCode,
                viewModel.jsonData.typeInfo.functionCode,
                viewModel.jsonData.typeInfo.typeCode,
                viewModel.jsonData.subtype === undefined ? 'NACHA' : viewModel.jsonData.subtype,
                formState, viewModel, $('.ui-datepicker-trigger').first(),
            );
            if (clearReceivSetting) {
                viewModel.unset(
                    'RECEIVABA',
                    {
                        silent: true,
                    },
                );
            }
        }
    };

    const sameDayPaymentEffDate = function (sameDayEffectiveDate, settlementWindow) {
        if (settlementWindow === '1' && form.field('COMPDESCDATE')) {
            form.field('COMPDESCDATE').setValue('');
            form.field('COMPDESCDATE').shouldBeReadOnly(true);
        }
        if (sameDayEffectiveDate) {
            const userDateFormat = userInfo.getDateFormat();
            const sameDayeffDate = moment(sameDayEffectiveDate).format(userDateFormat);
            if (form.field('EFFECTIVEDATE')) {
                viewModel.set('EFFECTIVEDATE', sameDayeffDate);
                /*
                 * update cutoff time even if changed event does not run
                 * user can have multiple cutoff time records with different times
                 * for same day ach and dates just selected on current day
                 */
                if (viewModel.get('EFFECTIVEDATE') === sameDayeffDate) {
                    updateCutoff();
                }
            }
            form.field('EFFECTIVEDATE').shouldBeReadOnly(true);
        }
        showOrHideSameDayWarning(true);
        $summaryDate.text(Formatter.formatDateFromUserFormat(viewModel.get('EFFECTIVEDATE'), dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));
    };

    const standardPaymentEffectiveDate = function (effectiveDt) {
        if (form.field('SAMEDAYACH') && form.field('SAMEDAYACH').getValue() === '1') {
            viewModel.set(
                'SAMEDAYACH',
                '0',
                {
                    silent: true,
                },
            );
            form.field('SAMEDAYACH').$el.prop('checked', false);
        }

        if (effectiveDt) {
            const userDateFormat = userInfo.getDateFormat();
            const effDate = moment(effectiveDt).format(userDateFormat);
            if (form.field('EFFECTIVEDATE')) {
                viewModel.set('EFFECTIVEDATE', effDate);
            }
            form.field('EFFECTIVEDATE').shouldBeReadOnly(false);
        }
        if (form.field('allowSameDayWarning')) {
            form.field('allowSameDayWarning').shouldBeVisibleWhen(false);
        }

        if (form.field('COMPDESCDATE')) {
            form.field('COMPDESCDATE').shouldBeReadOnly(false);
        }
        showOrHideSameDayWarning(false);
        $summaryDate.text(Formatter.formatDateFromUserFormat(viewModel.get('EFFECTIVEDATE'), dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));
    };

    const showOrHideSameDayACHCheckBox = function (reset) {
        if (!viewModel.get('ORIGCOMPID')) {
            return;
        }

        const processActionService = services.generateUrl(constants.URL_PROCESS_SERVICE_ACTION);

        const inputData = [{
            name: '_productCode',
            value: viewModel.jsonData.typeInfo.productCode,
        }, {
            name: '_functionCode',
            value: viewModel.jsonData.typeInfo.functionCode,
        }, {
            name: '_typeCode',
            value: viewModel.jsonData.typeInfo.typeCode,
        }, {
            name: '_subTypeCode',
            value: viewModel.jsonData.subtype === undefined ? 'NACHA' : viewModel.jsonData.subtype,
        }, {
            name: ' _mode',
            value: formState,
        }, {
            name: 'REQUESTTYPE',
            value: 'achsamedaypayment',
        }, {
            name: '_companyId',
            value: viewModel.get('ORIGCOMPID'),
        }, {
            name: '_companyName',
            value: viewModel.get('ORIGCOMPNAME'),
        }];

        let sameDayACHForType;
        let allowSameDayACHForCompany;
        let allowSameDayPayment;
        allowSameDayACHForCompany = viewModel.get('CLIENTCOMP_ALLOWSAMEDAYPAYMENT');

        http.post(
            processActionService,
            {
                item: inputData,
            },
            (result) => {
                // extract the debit amounts and currency
                util.each(result.item, (entry) => {
                    if (entry.name === 'ALLOWSAMEDAYACH') {
                        sameDayACHForType = entry.value;
                    } else if (entry.name === 'ALLOWSAMEDAYPAYMENT') {
                        allowSameDayPayment = entry.value;
                    }
                });
                if ((!allowSameDayACHForCompany || allowSameDayACHForCompany === '') && allowSameDayPayment) {
                    allowSameDayACHForCompany = allowSameDayPayment;
                }

                if (sameDayACHForType === '1' && allowSameDayACHForCompany === '1' && form.field('SAMEDAYACH')) {
                    sameDayACHField.shouldBeVisibleWhen(true);
                } else {
                    sameDayACHField.shouldBeVisibleWhen(false);
                }
                if (reset) {
                    form.field('SAMEDAYACH').$el.prop('checked', false);
                    viewModel.set(
                        'SAMEDAYACH',
                        '0',
                        {
                            silent: true,
                        },
                    );
                    /*
                     * When switching from one originator to another, ensure that if the user had
                     * selected "Same Day Payment" for a previous originator that the value date is
                     * returned to the default and any relevant fields are returned to being
                     * unprotected/unaffected (mimics if the user had manually unchecked the option)
                     */
                    const formattedEffDate = moment(viewModel.get('EFFECTIVEDATE'), userInfo.getDateFormat());
                    standardPaymentEffectiveDate(formattedEffDate);
                }
            },
            (err) => {
                log.error(err);
            },
        );
    };

    const refreshEffectiveDateCalendar = function () {
        const fc = form.formView.options.context.actionData.functionCode;
        // we dont want to call this if this is a template..
        if (fc === 'BHTMPL' || fc === 'TMPL') {
            return;
        }
        const postData = {
            paymentType: viewModel.jsonData.typeInfo.typeCode,
            debitBank: viewModel.get('BANKCODE'),
            debitCurrency: viewModel.get('ORIGCURRENCYCODE'),
            debitBankCountry: viewModel.get('ORIGCOUNTRY'),
            subType: viewModel.jsonData.subtype,
            sameDayAch: viewModel.get('SAMEDAYACH'),
            totalNumNotOnUs: viewModel.get('TOTALNUMNOTONUS'),
            origCompId: viewModel.get('ORIGCOMPID'),
            origCompName: viewModel.get('ORIGCOMPNAME'),
            entryClass: viewModel.get('ENTRYCLASS'),
        };
        dateUtil.refreshEffectiveDateCalendar(form, 'EFFECTIVEDATE', postData, true);
    };
    const setValueDate = function (secondaryCall) {
        const postData = {
            paymentType: viewModel.jsonData.typeInfo.typeCode,
            debitBank: viewModel.get('BANKCODE'),
            debitCurrency: viewModel.get('ORIGCURRENCYCODE'),
            debitBankCountry: viewModel.get('ORIGCOUNTRY'),
            subType: viewModel.jsonData.subtype,
            totalNumNotOnUs: viewModel.get('TOTALNUMNOTONUS'),
        };
        dateUtil.setEffectiveDate(form, 'EFFECTIVEDATE', postData).then(() => {
            if (secondaryCall) {
                secondaryCall();
            }
        });
    };

    const updateHealthCareChanges = function () {
        if (healthCareCheckBox.$el.prop('checked')) {
            viewModel.set(
                'HEALTHCARE',
                '1',
                {
                    silent: true,
                },
            );
            $('#ADDENDAFORMAT').append(`<option value="3">${locale.get('PAY.Healthcare')}</option>`);
            viewModel.set('ADDENDAFORMAT', '3');
            form.field('ADDENDAFORMAT').shouldBeReadOnly(true);
            viewModel.set('ENTRYDESC', 'HCCLAIMPMT');
            form.field('ENTRYDESC').shouldBeReadOnly(true);
        } else {
            viewModel.set('ADDENDAFORMAT', '');
            $('#ADDENDAFORMAT option[value="3"]').detach();
            viewModel.set(
                'HEALTHCARE',
                '0',
                {
                    silent: true,
                },
            );
            form.field('ADDENDAFORMAT').shouldBeReadOnly(false);
            form.field('ENTRYDESC').shouldBeReadOnly(false);
            viewModel.set('ENTRYDESC', '');
        }
    };

    const isDayOff = function () {
        let dayoff = false;
        if (formView.dates.processingDays && formView.dates.blockedDates) {
            const today = moment();
            dayoff = dateUtil.isDayOff(
                formView.dates.processingDays[0],
                formView.dates.blockedDates, today,
            );
            return dayoff;
        }
        return dayoff;
    };

    const getSameDayACHSettings = function (showWarning) {
        const processActionService = services.generateUrl(constants.URL_PROCESS_SERVICE_ACTION);

        const inputData = [{
            name: '_productCode',
            value: viewModel.jsonData.typeInfo.productCode,
        }, {
            name: '_functionCode',
            value: viewModel.jsonData.typeInfo.functionCode,
        }, {
            name: '_typeCode',
            value: viewModel.jsonData.typeInfo.typeCode,
        }, {
            name: '_subTypeCode',
            value: viewModel.jsonData.subtype === undefined ? 'NACHA' : viewModel.jsonData.subtype,
        }, {
            name: ' _mode',
            value: formState,
        }, {
            name: 'REQUESTTYPE',
            value: 'achsamedaypayment',
        }, {
            name: '_companyId',
            value: viewModel.get('ORIGCOMPID'),
        }, {
            name: '_companyName',
            value: viewModel.get('ORIGCOMPNAME'),
        }, {
            name: '_sameDayACH',
            value: viewModel.get('SAMEDAYACH'),
        }, {
            name: '_bankCode',
            value: viewModel.get('BANKCODE'),
        }, {
            name: '_currencyCode',
            value: viewModel.get('ORIGCURRENCYCODE'),
        }, {
            name: 'EFFECTIVEDATE',
            value: viewModel.get('EFFECTIVEDATE'),
        }];

        let sameDayEffectiveDate;
        let effectiveDt;
        let settlementWindow;
        let cutoffTimeNotPassed;
        let sameDayCutoffTimeNotPassed;
        http.post(
            processActionService,
            {
                item: inputData,
            },
            (result) => {
                // extract the cutoff, debit amounts and currency
                util.each(result.item, (entry) => {
                    if (entry.name === 'SAMEDAYEFFECTIVEDATE') {
                        sameDayEffectiveDate = entry.value;
                    } else if (entry.name === 'EFFECTIVEDATE') {
                        effectiveDt = entry.value;
                    } else if (entry.name === 'SETTLEMENTWINDOW') {
                        settlementWindow = entry.value;
                    } else if (entry.name === 'ISCUTOFFNOTPASSED') {
                        cutoffTimeNotPassed = (entry.value === 'true');
                    } else if (entry.name === 'ISSAMEDAYCUTOFFNOTPASSED') {
                        sameDayCutoffTimeNotPassed = (entry.value === 'true');
                    }
                });

                const sameDayACH = viewModel.get('SAMEDAYACH') === '1';

                if (sameDayACH === true) {
                    if (sameDayCutoffTimeNotPassed && cutoffTimeNotPassed) {
                        sameDayPaymentEffDate(sameDayEffectiveDate, settlementWindow);
                    } else if ((!sameDayCutoffTimeNotPassed && !cutoffTimeNotPassed)
                        || (!sameDayCutoffTimeNotPassed && isDayOff())) {
                        const yesButtonAction = function () {
                            sameDayPaymentEffDate(sameDayEffectiveDate, settlementWindow);
                        };
                        const noButtonAction = function () {
                            standardPaymentEffectiveDate(effectiveDt);
                        };
                        const warnMsg = `${locale.get('ACH.SameDayPaymentNextBusinessDay')}(${sameDayEffectiveDate})<br><br><br>${locale.get('ACH.ContinueSameDay')}`;

                        const title = locale.get('ACH.Warning');

                        if (showWarning) {
                            dialog.confirm(
                                warnMsg, title,
                                (ok) => {
                                    if (ok) {
                                        yesButtonAction();
                                    } else {
                                        noButtonAction();
                                    }
                                },
                            );
                        } else {
                            sameDayPaymentEffDate(sameDayEffectiveDate, settlementWindow);
                        }
                    } else if (!sameDayCutoffTimeNotPassed && cutoffTimeNotPassed) {
                        standardPaymentEffectiveDate(effectiveDt);
                        if (showWarning) {
                            dialog.error(locale.get('ACH.StandardPayment'));
                        }
                        return false;
                    }
                } else {
                    standardPaymentEffectiveDate(effectiveDt);
                }
                return undefined;
            },
            (err) => {
                log.error(err);
            },
        );
    };

    /**
     * @method validateTemplateInfo
     * @param {boolean} saveTemplateChecked: true if the SAVEASTEMPLATE field is checked
     *
     * When the Save as Template checkbox is selected, display the Template Code,
     * Template Description, and Restricted checkbox.  Otherwise hide the fields.
     */
    const validateTemplateInfo = function (saveTemplateChecked) {
        if (viewModel.jsonData.typeInfo.functionCode === 'BATCH') {
            // Clear out Template Code and Template Description when not visible
            if (!saveTemplateChecked && (!entryMethod || entryMethod === '0')) {
                viewModel.set({
                    TEMPLATECODE: '',
                    TEMPLATEDESCRIPTION: '',
                });
                templateCode.setValue('');
                templateDescription.setValue('');
            }
            templateCode.shouldBeVisibleWhen(saveTemplateChecked);
            templateDescription.shouldBeVisibleWhen(saveTemplateChecked);
            templateCode.setValidator(templateCode.$el, 'overrideError', 'isTemplateCode');

            // Hide the restricted checkbox for SMB users
            if (!userInfo.isSmbUser()) {
                restrict.shouldBeVisibleWhen(saveTemplateChecked);
            }
            PaymentUtil.validateTemplateInfo({
                saveTemplateChecked,
                templateCode,
                templateDescription,
                tempCodeFieldName: 'TEMPLATECODE',
                tempDesFieldName: 'TEMPLATEDESCRIPTION',
            }, viewModel);
        }
    };

    /**
     * Remove the validation and remove the mandatory requirements for the field passed in
     */
    const removeResetMandatory = (model, fieldName) => {
        if (model && model.validators && model.validators[fieldName]) {
            form.field(fieldName).shouldBeRequired(false).$el.parent().removeClass('required');
            model.removeValidator(fieldName);
        }
    };

    /**
     * This is a custom override for the masked input matcher function for the company name and
     * ID field for ACH batches. The specific issue here is that the offset account number is
     * part of the displayed value of the combo box, but is not part of the actual field value
     * of the combo box. When the offset account number is masked in the display value it can
     * not be matched in type-ahead. This pulls the offset account number from the combo map
     * data and add it to the search strings.
     *
     * @param {string} params - user entered search string
     * @param {string} data - combo box display value for the current match iteration
     */
    const maskedInputMatcher = (params, data) => {
        // if no params, no search, display the item
        if (!params) {
            return data;
        }

        // combo selection data object
        const comboData = this.formView.comboCollections.COMPIDNAME;
        /*
         * combo selection data row currently being iterated
         * payment types that append an amount use data.startsWith
         */
        const currRow = comboData.find(row => row.label === data || data.startsWith(row.label));
        if (currRow === undefined) {
            return null;
        }
        // offset account number data map field
        const offsetAcct = currRow.mapDataList.find(map => map.toField.toUpperCase() === 'OFFSETACCOUNTNUM');
        if (offsetAcct === undefined) {
            return null;
        }
        const matchesParams = [currRow.label, currRow.name, offsetAcct.value]
            .filter(str => str !== undefined && str.toUpperCase().includes(params.toUpperCase()));
        return matchesParams.length ? data : null;
    };

    /**
     * The ACH Originator field must be marked as mandatory, as well as the 2
     * fields the name is composed of, OrigCompName and OrigCompId, .
     *
     */
    if (initialState && (formState.toUpperCase() === 'INSERT' || formState.toUpperCase() === 'MODIFY')) {
        compIDCombo.$el.comboBox({
            matcher: maskedInputMatcher.bind(this),
        });

        compIDCombo.shouldBeRequired(true);
        compIDCombo.$el.parent().addClass('required');
        if (formState.toUpperCase() === 'INSERT') {
            // Remove the audit section from the DOM so styling is not impacted.
            aFieldInAuditSection.$el.closest('.section').remove();
            // If this is not a create payment from template then hide the summary section
            if (!viewModel.get('TEMPLATECODE') || viewModel.context.createdFrom !== '1' || viewModel.context.functionCode !== 'BATCH') {
                $summarySection.hide();
            }
        }
        offset.shouldBeReadOnly(viewModel.jsonData.subtype !== 'CAEFT');
        // update the maxDate of the date picker upon creation of a payment
        if (formView.dates && $datePicker.length && $datePicker.data && $datePicker.data('daterangepicker')) {
            $datePicker.data('daterangepicker').updateCalendars({
                daysForward: formView.dates.maxDate,
            });
        }
    }

    if (initialState) {
        formView.listenTo(formView, 'modelAction:error', onChildSaveError);

        // get the balance account information for the debit account combo
        id = 'COMPIDNAME';
        if (formState !== 'insert') {
            balanceSelector = 'div#OFFSETACCOUNTNUM strong';
        }

        if (paymentType.indexOf('EFT') > -1) {
            allowMaxTmplAmount = '0'; // Template max not allowed for EFT types
        }

        // handle lock icon for amount if template and not view
        if (formState !== 'view' && (viewModel.jsonData.typeInfo.functionCode !== 'BATCH')) {
            if (amount.$el && amount.$el.length > 0) {
                PaymentUtil.runChangeAmount(amount.$el.get(0));
            }
            const amountLocked = viewModel.lockedFields && viewModel.lockedFields.includes('AMOUNT');
            // make sure maximum transaction amount is cleared and protected
            if (amountLocked) {
                viewModel.set('TEMPLATE_MAX_AMOUNT', '');
            }
            $('[name="TEMPLATE_MAX_AMOUNT"]').prop('readonly', amountLocked);
        }

        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            formView,
            viewModel,
            null,
            functionCode,
        );

        if (paymentType !== 'BDEFTCC' && paymentType !== 'BDEFTCD' && paymentType !== 'BDEFTCCD' && paymentType !== 'BIEFTCC' && paymentType !== 'BIEFTCD' && paymentType !== 'BIEFTCCD') {
            rtgsCommon.getAccountBalances(form.formView, id, 'USACH', paymentType, 'BankCode', 'ORIGCURRENCYCODE', balanceSelector);
        }

        if (formState.toUpperCase() === 'REPAIR') {
            viewModel.context.actionData.action = 'REPAIR';
        }
        if (viewModel.get('ENTRYMETHOD') === '1' && formState !== 'insert') {
            $('#TEMPLATECODE').closest('.section').addClass('hidden');
        }
        if ((formState.toUpperCase() === 'INSERT')
            && viewModel.get('FUNCTION') === 'BATCH'
            && (viewModel.get('ENTRYMETHOD') === '1' || viewModel.context.createdFrom === '1')) {
            setValueDate();
        } else if (formState === 'modify') {
            refreshEffectiveDateCalendar();
        }

        showOrHideSameDayACHCheckBox(false);
        if (formState !== 'view' && formState !== 'reverse') {
            getSameDayACHSettings(false);
        }

        // Hide the credit/debit blocks.
        $blockTitle.hide();
        $creditOnlyBlocks.hide();
        $debitOnlyBlocks.hide();

        if (paymentType === 'BDACHNFI' && viewModel.get('ENTRYCLASS') !== 'IAT') {
            // remove the mandatory from specific fields
            removeResetMandatory(viewModel, 'ORIGADDRESS');
            removeResetMandatory(viewModel, 'ORIGCOUNTRY');
            removeResetMandatory(viewModel, 'ORIGCITY');
            removeResetMandatory(viewModel, 'ORIGPOSTALCODE');
            removeResetMandatory(viewModel, 'ORIGINATOR_BANK_CODE_TYPE');
            removeResetMandatory(viewModel, 'ORIGABA');
            // hide rows that are not needed for this specific entryclasse
            $('#DESTCOUNTRYCODE').closest('.section').addClass('hidden');
            $('#ORIGADDRESS').closest('.row').addClass('hidden');
            $('#ORIGCOUNTRY').closest('.row').addClass('hidden');
            $('#ORIGINATOR_BANK_CODE_TYPE').closest('.row').addClass('hidden');
        }

        healthCareCheckBox.shouldBeReadOnly(formState.toUpperCase() !== 'INSERT' || viewModel.get('ENTRYMETHOD') === '1');
        if (formState.toUpperCase() === 'INSERT' && viewModel.context.createdFrom === '1') {
            healthCareCheckBox.$el.prop('checked', viewModel.get('HEALTHCARE') === '1');
        }

        if (paymentType === 'BDACHCVP' && formState !== 'view') {
            form.field('ADDENDAFORMAT').shouldBeReadOnly(healthCareCheckBox.$el.prop('checked'));
            form.field('ENTRYDESC').shouldBeReadOnly(healthCareCheckBox.$el.prop('checked'));
            if (healthCareCheckBox.$el.prop('checked')) {
                $('#ADDENDAFORMAT').append(`<option value="3">${locale.get('PAY.Healthcare')}</option>`);
            } else {
                $('#ADDENDAFORMAT option[value="3"]').detach();
            }
        }

        // Set the new view total fields default values.
        viewModel.set(totalFieldName, 0);
        viewModel.set(numberBeneFieldName, 0);

        viewModel.on('change:ORIGCOMPNAME', () => {
            if (viewModel.jsonData.typeInfo.functionCode === 'BATCH') {
                setValueDate(() => {
                    updateCutoff();
                });
                showOrHideSameDayACHCheckBox(true);
            }
            if (healthCareCheckBox.$el.prop('checked')) {
                viewModel.set(
                    'ENTRYDESC',
                    'HCCLAIMPMT',
                    {
                        silent: true,
                    },
                );
            } else if (viewModel.jsonData.typeInfo.typeCode === 'BDACHCVP') {
                viewModel.set(
                    'ENTRYDESC',
                    viewModel.get('ENTRYDESC1'),
                    {
                        silent: true,
                    },
                );
            }
        });

        if (formState === 'reverse') {
            $reverseSelection.parent().parent().parent().addClass('field-container-md');
        }

        if (!mobileUtil.isMobileGridEnabled()) {
            if (formState === 'reverse') {
                formView.$el.on('change', 'input:checkbox', function () {
                    let bulkRequest;
                    if (formView.batchChildGridView) {
                        if (this.className === 'grid-all-rows-selector') {
                            bulkRequest = {
                                rows: null,
                                action: 'bulkUpdate',
                                actionType: 'REVERSE',
                                value: this.checked ? 1 : 0,
                            };
                            formView.batchChildGridView.makeBulkUpdateRequest(bulkRequest);
                        } else {
                            formView.batchChildGridView
                                .createIndividualReverseRequest(this.dataset.modelCid);
                        }
                    }
                });
            }

            viewModel.on('change:REVERSESELECTION', () => {
                let selectAndProtectAll;
                let bulkUpdateRequest;

                if (formView.batchChildGridView) {
                    selectAndProtectAll = (formView.model.get('REVERSESELECTION') !== 'Individual');
                    formView.batchChildGridView.gridView
                        .wrapper.b.selectAndProtectAll = selectAndProtectAll;
                    formView.batchChildGridView.gridView
                        .selectAndProtectAllRows(selectAndProtectAll);

                    // NEED TO UPDATE MG TO SELECT ALL CHECKBOXES

                    bulkUpdateRequest = {
                        rows: null,
                        action: 'bulkUpdate',
                        actionType: 'REVERSE',
                        value: selectAndProtectAll ? 1 : 0,
                    };

                    formView.batchChildGridView.makeBulkUpdateRequest(bulkUpdateRequest);
                }
            });
        }

        if (formView.batchChildGridView
            && formView.batchChildGridView.gridView
            && formState === 'reverse'
            && viewModel.get('REVERSESELECTION') === '') {
            formView.batchChildGridView.gridView.selectAndProtectAll = true;
            formView.batchChildGridView.gridView.wrapper.b.selectAndProtectAll = true;
            formView.model.set(
                'REVERSESELECTION',
                'All',
                {
                    silent: true,
                },
            );
            viewModel.set(
                'REVERSESELECTION',
                'All',
                {
                    silent: true,
                },
            );
            $reverseSelectionAll.attr('checked', true);
        }

        if (formState === 'SELECT') {
            $reverseReason.parent().addClass('hidden');
            $('[name="ENTRYDESC"]').text(viewModel.get('ENTRYDESC'));
        }

        if (formState !== 'reverse' && formState !== 'view') {
            reverseReason.shouldBeVisibleWhen(false);
        }

        if ((formState === 'reverse' || formState === 'view') && viewModel.get('REVERSESELECTION') === 'Individual') {
            viewModel.set('REVERSEREASON', '');
            $reverseSelectionAll.attr('disabled', true);
            if (formView.batchChildGridView && formView.batchChildGridView.gridView) {
                formView.batchChildGridView.gridView.selectAndProtectAll = false;
                formView.batchChildGridView.gridView.wrapper.b.selectAndProtectAll = false;
            }
        }

        sameDayACHField.$el.on('click', () => {
            if (sameDayACHField.$el.is(':checked')) {
                viewModel.set(
                    'SAMEDAYACH',
                    '1',
                    {
                        silent: true,
                    },
                );
            } else {
                viewModel.set(
                    'SAMEDAYACH',
                    '0',
                    {
                        silent: true,
                    },
                );
            }
            getSameDayACHSettings(true);
        });

        healthCareCheckBox.$el.on('click', () => {
            const beneCount = viewModel.get(numberBeneFieldName);

            const yesButtonAction = function () {
                updateHealthCareChanges();
            };

            const noButtonAction = function (onOff) {
                healthCareCheckBox.$el.prop('checked', onOff);
            };

            const warnMsg = locale.get(`ACH.HealthcareUpdate.${healthCareCheckBox.$el.prop('checked')}`);
            const title = locale.get('ACH.Warning');
            const onOff = !healthCareCheckBox.$el.prop('checked');

            if (beneCount > 0) {
                dialog.confirm(
                    warnMsg, title,
                    (ok) => {
                        if (ok) {
                            yesButtonAction();
                        } else {
                            noButtonAction(onOff);
                        }
                    },
                );
            } else {
                updateHealthCareChanges();
            }
        });

        // Bind to the childViewRegion show event so we can attach events to its fields.
        if (childViewRegion) {
            childViewRegion.on('show', () => {
                childViewRegion.currentView.$el.on('change', 'input, select, textarea', () => {
                    /*
                     * In batch ACH payments where debits and credits are possible,
                     * this determines if the current type is debit or credit
                     * for single payments.
                     */
                    const singleTransactionType = $('#TRANTYPE').val();

                    /*
                     * Set debit or credit totals manually since the form
                     * doesn't know the difference automatically.
                     */
                    if (singleTransactionType === 'DB') {
                        viewModel.set(totalNumCreditFieldName, 0);
                        viewModel.set(totalNumDebitFieldName, 1);
                        viewModel.set(totalAmountDebitFieldName, form.field('AMOUNT').getValue());
                    } else if (singleTransactionType === 'CR') {
                        viewModel.set(totalNumDebitFieldName, 0);
                        viewModel.set(totalNumCreditFieldName, 1);
                        viewModel.set(totalAmountCreditFieldName, form.field('AMOUNT').getValue());
                    }

                    /*
                     * Update the beneficiary total count and the total field name
                     * manually since these values
                     * aren't returning from a service until we have multiple beneficiaries.
                     */
                    viewModel.set(numberBeneFieldName, 1);
                    if (viewModel.jsonData.typeInfo.typeCode === 'BDACHTP') {
                        viewModel.set(totalFieldName, form.field('TOTALAMOUNT').getValue());
                    } else if (form.field('PRENOTEFLAG').isChecked()) {
                        viewModel.set(totalFieldName, 0);
                    } else {
                        viewModel.set(totalFieldName, form.field('AMOUNT').getValue());
                    }

                    form.applyPolicies(false);
                });
            });
        }

        if (viewModel.jsonData && viewModel.jsonData.typeInfo && viewModel.jsonData.typeInfo.typeCode === 'BDACHNFI') {
            // hide the addChild and deleteChild buttons.
            if (addChild) {
                addChild.$el.hide();
            }
            if (holdAllChild) {
                holdAllChild.$el.hide();
            }
        } else if (viewModel.get('ENTRYMETHOD') === '1') {
            if (addChild) {
                addChild.$el.hide();
            }
        }

        // If there is a child grid, wait for it to load before running updateBatchTotals
        if (formView.batchChildGridView
                && formView.batchChildGridView.gridView
                && formView.batchChildGridView.hasLoaded === true) {
            // Get all page totals from the server on load
            form.formView.listenTo(form.formView.batchChildGridView.gridView, 'gridapi:loaded', () => {
                updateBatchTotals();
            });
            // Get totals when we update amounts and save
            formView.listenTo(formView.appBus, 'nachaChild:update:success', updateBatchTotals);
            // Get totals when we update the hold and save
            formView.listenTo(formView.appBus, 'nachaChild:hold:update:success', updateBatchTotals);
            updateBatchTotals();
        } else if (formState.toUpperCase() !== 'INSERT' || (formView.options && formView.options.context && formView.options.context.createdFrom)) {
            /*
             * NH-47905 A new Batch Payment does not require calculation of Batch Totals
             * on initial
             * screen entry,
             * except when we are creating a whole new Batch Payment off an existing
             * Approved Batch
             * Template - createdFrom is defined
             */
            updateBatchTotals();
        }

        // Hide the restricted checkbox for SMB users
        if (userInfo.isSmbUser()) {
            restrict.$el.closest('div').addClass('hidden');
        }

        if (viewModel.jsonData.typeInfo.functionCode === 'BATCH') {
            const $closestSection = saveAsTemplate.$el.closest('.section');

            /*
             * The originator ID should be editable on modify unless the payment was
             * created from copy-from-template or imported.  Does not apply to imported
             * templates.
             */
            if (formState.toUpperCase() === 'VIEW' || ((entryMethod === '1' || entryMethod === '3') && formState.toUpperCase() === 'MODIFY')) {
                $closestSection.addClass('hidden');
                compIDCombo.shouldBeReadOnly(true);
            }
            if (entryMethod === '3' || entryMethod === '1' || formState.toUpperCase() === 'VIEW' || formState.toUpperCase() === 'REVERSE') {
                saveAsTemplate.$el.closest('.section').addClass('hidden');
            } else {
                $saveAsTemplate.empty();
                $closestSection.appendTo($saveAsTemplate);
                // Implement redirected view binding
                viewBinding.bindAdditional(formView, $saveAsTemplate);

                $closestSection.removeClass('section section-container');
                templateCode.setValue('');
                templateDescription.setValue('');
            }
        }

        if (form.formView.state.toUpperCase() === 'REPAIR') {
            initialRepair(form);
        }
        restrict.$el.on('click', () => {
            if (restrict.$el.is(':checked')) {
                viewModel.set('RESTRICTTEMPLATE_FLAG', '1');
            } else {
                viewModel.set('RESTRICTTEMPLATE_FLAG', '');
            }
        });

        PaymentUtil.updateACHSummaryTotal(viewModel, formView);

        viewModel.on('change:EFFECTIVEDATE', () => {
            updateBatchTotals();
            updateCutoff();
        });

        // when bene is updated, we need to update the cutoff value being displayed
        if (childViewRegion && childViewRegion.currentView) {
            childViewRegion.currentView.model.on('change:RECEIVABA', () => {
                updateCutoff();
            });
        }

        if (functionCode.indexOf('TMPL') > -1) {
            $('[data-field="summary-date"]').hide();
            $('.summary-date-text').hide();
        }
        const acceptedStates = [
            'SELECT',
            'MODIFY',
            'REPAIR',
            'RESTORE',
            'REVERSE',
        ];
        const typeCode = viewModel.context.typeCode || '';
        const notAcceptedTypes = [
            'BDACHNFI',
        ];

        if (acceptedStates.includes(formState.toUpperCase())
            && notAcceptedTypes.indexOf(typeCode.toUpperCase()) < 0) {
            if (functionCode.indexOf('TMPL') > -1) {
                templateCode.$el.parent().addClass('read-only-text').removeClass('required');
            }

            if (viewModel.get('ORIGCOMPID') && viewModel.get('ORIGCOMPNAME')) {
                compIDName = `${viewModel.get('ORIGCOMPID')} / ${viewModel.get('ORIGCOMPNAME')}`;
            } else {
                compIDName = `${viewModel.get('CMB_ORIGINATOR_ID')} / ${viewModel.get('CMB_ORIGINATOR_NAME')}`;
            }
            // The batch payment on modify case is handled above in BATCH only section.
            if (functionCode.indexOf('TMPL') > -1 || formState.toUpperCase() !== 'MODIFY') {
                compIDCombo.shouldBeReadOnly();
            }
            /*
             * passing the silent flag to the setter does not map the value into the
             * COMBOBOX, using regular model setter
             */
            viewModel.set('COMPIDNAME', compIDName);
        }

        /*
         * if copy from payment or template, then we want to do an update on the
         * cutoff to reset it
         */
        if (viewModel.context.createdFrom === '1') {
            updateCutoff();
        }

        if (form.formView.state.toUpperCase() === 'INSERT' && (viewModel.fieldData.MAX_SIGNATURES_OVERRIDE) && viewModel.get('MAX_SIGNATURES_OVERRIDE') === '' && viewModel.fieldData.MAX_SIGNATURES_OVERRIDE_DEFAULT) {
            viewModel.set('MAX_SIGNATURES_OVERRIDE', viewModel.fieldData.MAX_SIGNATURES_OVERRIDE_DEFAULT.value);
        }

        /*
         * Need to show the user a warning if the contact used in any of the beneficiaries has
         * been modified or deleted.
         * This flag is set on contact approval/deletion
         */
        const contactHasBeenUpdatedValue = viewModel.get('CONTACTHASBEENUPDATED');
        const isApplicableActionMode = form.formView.state.toUpperCase() === 'MODIFY' || (form.formView.state.toUpperCase() === 'INSERT' && viewModel.context.createdFrom === '1');
        if (isApplicableActionMode && (contactHasBeenUpdatedValue === 'YES' || contactHasBeenUpdatedValue === 'INVALID')) {
            const beneUpdatedWarning = new BeneUpdatedWarning({
                model: viewModel,
                contactHasBeenUpdatedValue,
                inavlidBeneficiaries: viewModel.get('INVALIDBENELIST'),
            });
                /*
                 * if the user accepts the changes the beneficiary grid will have to be refreshed to
                 * show them.
                 */
            form.formView.listenTo(beneUpdatedWarning, 'refreshGrid', () => {
                form.formView.batchChildGridView.gridView.refreshGridData(false, false, true);
            });
            dialog.custom(beneUpdatedWarning);
        }

        validateTemplateInfo(saveAsTemplate.$el.is(':checked'));

        saveAsTemplate.$el.on('change', (e) => {
            validateTemplateInfo(e.target.checked);
        });

        effectiveDate.$el.on('change', () => {
            $summaryDate.text(Formatter.formatDateFromUserFormat($('#EFFECTIVEDATE').val(), dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));
        });

        compdescdate.$el.on('change', () => {
            viewModel.set('COMPDESCDATE', compdescdate.getValue());
        });
        tmplMax.$el.on('change', () => {
            viewModel.set('TEMPLATE_MAX_AMOUNT', tmplMax.getValue());
        });
    }

    /*
     * if cutoff time is available and config allows, then show it. dont run for template
     * creation, and dont show for EFT payments
     */
    if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL' && formState !== 'view' && paymentType.indexOf('EFT') < 0) {
        PaymentUtil.showCutoff(viewModel.get('CUTOFF_INFO') || (formView.dates && formView.dates.cutoffDateTimeTz), $('.ui-datepicker-trigger').first(), paymentType, formState, viewModel.get('STATUS'));
    }

    // Update the summary sections visibility.
    $paymentSummaryContainer.removeClass('hidden');

    if (formState === 'view') {
        $('#TOTALAMTCREDIT').closest('fieldset').addClass('hidden');
    }

    // If available, append the currency code to the formatted payment Total.
    if (!util.isEmpty(viewModel.get('ORIGCURRENCYCODE'))) {
        $currencyFields.text(viewModel.get('ORIGCURRENCYCODE'));
    }

    // Format the payment total and set beneficiary count
    if (!initialState && !viewModel.get('childCanceled') && !viewModel.get('lastSaveFailed')) {
        updateTotalsDisplay();
    }

    if (functionCode.indexOf('BATCH') > -1 && initialState && (formState === 'modify' || formState.toUpperCase() === 'REPAIR')) {
        dateUtil.refreshEffectiveDateCalendar(
            form,
            'EFFECTIVEDATE',
            {
                paymentType: viewModel.jsonData.typeInfo.typeCode,
                debitBank: viewModel.get('BANKCODE'),
                debitCurrency: viewModel.get('ORIGCURRENCYCODE'),
                debitBankCountry: viewModel.get('ORIGCOUNTRY'),
                subType: viewModel.jsonData.subtype,
                sameDayAch: viewModel.get('SAMEDAYACH'),
                totalNumNotOnUs: viewModel.get('TOTALNUMNOTONUS'),
                origCompId: viewModel.get('ORIGCOMPID'),
                origCompName: viewModel.get('ORIGCOMPNAME'),
                entryClass: viewModel.get('ENTRYCLASS'),

            },
            true,
        );
    }

    /*
     * NH-112644 function code for payroll and reimburse are identical, so only a flag
     * passed via options determines if it is a reimbursement. When it is reimbursement,
     * ignore the PAYROLL value from the COMPIDNAME metadata and use REIMBURSE
     */
    if (form.formView.options.reimburse) {
        const userEnteredEntryDesc = viewModel.get('UE_ENTRYDESC');
        if (formState !== 'insert' && hasUserEnteredDesc(viewModel)) {
            // Update ENTRYDESC with user entered entry description (UE_ENTRYDESC)
            viewModel.set('ENTRYDESC', userEnteredEntryDesc);
        }
        viewModel.on('change:ENTRYDESC', updateUEEntryDescFromEntryDesc);
        // Update ENTRYDESC only when the value is PAYROLL and user hasn't entered UE_ENTRYDESC
        viewModel.on('change:COMPIDNAME', updateEntryDescAfterCompid);
    }
}

export {
    initialRepair,
    hasUserEnteredDesc,
    updateUEEntryDescFromEntryDesc,
    updateEntryDescAfterCompid,
};
