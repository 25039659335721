import Layout from '@glu/core/src/layout';
import ElearningGroupModel from 'app/administration/models/elearningModel';
import LocaleCollection from 'app/administration/collection/locales';
import ElearningContentCollection from 'app/administration/collection/elearningContent';
import ListBuilder from 'common/listBuilder/listBuilder';
import Collection from '@glu/core/src/collection';
import errHandler from 'system/errHandler';
import locale from '@glu/locale';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import detailGroupViewTmpl from './detailGroupView.hbs';
import detailGroupTmpl from './detailGroup.hbs';

export default Layout.extend({
    template: detailGroupTmpl,
    viewTemplate: detailGroupViewTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'full-page',

    regions: {
        pageRegion: '.pageRegion',
        alertRegion: '.alert-region',
        contentRegion: '.elearning-content-region',
    },

    initialize(options) {
        this.state = options.state;
        this.groupId = options.groupId;
    },

    getTemplate() {
        if (this.loadingTemplate && this.hasLoadedRequiredData() === false) {
            return this.loadingTemplate;
        }
        if (this.state !== 'view') {
            return this.template;
        }
        return this.viewTemplate;
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    templateHelpers() {
        const self = this;

        return {
            stateDescription() {
                // TODO: Should these be localized?
                switch (self.state) {
                case 'view':
                    return 'View';
                case 'update':
                    return 'Modify';
                case 'add':
                    return 'Add';
                default:
                }
                return undefined;
            },

            editMode() {
                return (self.state === 'update');
            },

            locales() {
                return self.locales.toJSON();
            },

            elearningContentCollection() {
                return self.elearningContentCollection.toJSON();
            },

            language() {
                let label = '';
                self.locales.each((localeObj) => {
                    if (localeObj.get('id') === self.model.get('locale')) {
                        label = localeObj.get('label');
                    }
                });
                return label;
            },
        };
    },

    loadRequiredData() {
        const self = this;

        const promiseArr = [
            // Load locales
            new Promise((resolve, reject) => {
                const options = {
                    isAdmin: true,
                };
                new LocaleCollection(options).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
            // Load content
            new Promise((resolve, reject) => {
                const options = {
                    isAdmin: true,
                };
                new ElearningContentCollection(options).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
        ];

        Promise.all(promiseArr)
            .then(([locales, elearning]) => {
                self.locales = locales;
                self.elearningContentCollection = elearning;

                if (self.state === 'add') {
                    self.model = new ElearningGroupModel();
                    self.model.set('locale', 'en_US');
                    self.loadedRequiredData();
                } else {
                    self.model = new ElearningGroupModel({
                        groupId: self.groupId,
                    });
                    self.model.fetch({
                        success() {
                            self.loadedRequiredData();
                        },
                    });
                }
            })
            .then(null, errHandler);
    },

    loadedRequiredData() {
        this.loadListBuilder();
        this.setHasLoadedRequiredData(true);
        this.render();
        this.contentRegion.show(this.listBuilder);
    },

    loadListBuilder() {
        this.listBuilder = new ListBuilder({
            sourceCollection: this.elearningContentCollection,
            targetCollection: this.model.get('contentList') || new Collection(),
            id: 'group-add-content',

            text: {
                moveSelectedItems: locale.get('CXP.elearning.contentMove'),
                moveAllItems: locale.get('CXP.elearning.contentMoveAll'),
                removeSelectedItems: locale.get('CXP.elearning.contentRemove'),
                removeAllItems: locale.get('CXP.elearning.contentRemoveAll'),
                filter: locale.get('CXP.elearning.contentFilter'),
                sourceListHeader: locale.get('CXP.elearning.contentAvailable'),
                targetListHeader: locale.get('CXP.elearning.contentSelected'),
                title: locale.get('CXP.elearning.contentGroupAdd'),
                save: locale.get('CXP.elearning.contentGroupSave'),
                cancel: locale.get('CXP.elearning.contentGroupCancel'),
            },
        });
    },

    save() {
        const self = this;
        const selectedContent = this.listBuilder.targetCollection;
        this.model.set({
            elearningContentModels: selectedContent,
        });
        this.model.save(
            {},
            {
                success() {
                    self.navigateTo('/CXP/elearning#group');
                },
            },
        );
    },

    cancel() {
        this.navigateTo('/CXP/elearning#group');
    },
});
