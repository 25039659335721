const Constants = {
    productCode: 'RTP',
    functionCode: 'REQUEST',
    typeCode: 'REQOUT',
    subTypeCode: 'CANCLREQ',
    cancelReasonCode: 'CANCEL_REASON_CODE',
    queryService: 'tableMaintenance/getQueryResults',
    saveService: '/requestForPayment/outgoingPaymentRequests/canclreq',
    serverError: 'RTP.REQUEST.REQOUT.CANCEL.ErrorMsg',
};

export default Constants;
