// PCM CHANGES ON HOLE FILE - BE AWARE ON DGB MERGE
import hash from 'common/util/promise-hash';
import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Grid from '@glu/grid/src/grid';
import Wrapper from 'common/dynamicPages/api/gridWrapper';
import EntitlementsAPI from 'common/dynamicPages/api/entitlements';
import ButtonsAPI from 'common/dynamicPages/api/buttons';
import PageApi from 'common/dynamicPages/api/view';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import dynamicTypeSearch from 'system/gridOverride/views/dynamicTypeSearch';
import loadingTemplate from 'common/templates/loadingGrid.hbs';
import SavedViewsView from 'common/dynamicPages/views/savedViews/savedViewsList';
import SavedViewsCollection from 'common/dynamicPages/collections/savedViews';
import TableFooterLayout from '@glu/grid/src/footer/tableFooterLayout';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import NeedInfoDialog from 'common/dynamicPages/views/needInfoDialog';
import LvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import template from './grid.hbs';

const has = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

const DesktopGridView = Layout.extend({
    template,
    loadingTemplate,

    regions: {
        gridContent: '[data-hook="gridContent"]',
    },

    initialize(optionsParam) {
        const options = optionsParam;
        options.gridId = this.cid;
        options.viewId = options.viewId ? parseInt(options.viewId, 10) : '';
        this.compareColumns = options.compareColumns;
        this.gridOptions = options.gridOptions;
        this.wrapper = new Wrapper(options);
        this.context = options.context;
        this.lockedFields = options.lockedFields;
        this.hideGridActionButtons = options.hideGridActionButtons || false;
        this.hideButtons = options.hideButtons || false;
        this.enableColumnRename = options.enableColumnRenameControls || false;
        this.enableDateTimeSupport = options.enableDateTimeSupport || false;
        this.enableRowContextButtonCallbacks = options.enableRowContextButtonCallbacks || false;
        this.disableDeleteConfirm = options.disableDeleteConfirm || false;
        // PCM-1207
        this.disableSuspendConfirm = has(options, 'disableSuspendConfirm') ? options.disableSuspendConfirm : false;
        this.disableActivateConfirm = has(options, 'disableActivateConfirm') ? options.disableActivateConfirm : false;
        // PCM-1207
        this.filter = has(options, 'filter') ? options.filter : true;
        this.disableAuxControl = has(options, 'disableAuxControl') ? options.disableAuxControl : false;
        this.sortable = has(options, 'sortable') ? options.sortable : true;
        this.paginate = has(options, 'paginate') ? options.paginate : 'advanced';
        this.draggable = has(options, 'draggable') ? options.draggable : true;
        this.tableBodyClass = has(options, 'tableBodyClass') ? options.tableBodyClass : false;
        this.rowClassName = has(options, 'rowClassName') ? options.rowClassName : false;
        this.savedViews = new SavedViewsCollection(
            {},
            {
                context: this.context,
            },
        );
        this.serverSidePagination = true;
        if (options.disableServerSidePagination) {
            this.serverSidePagination = false;
        }
        this.rowSubView = options.rowSubView || false;
        this.savedViewsView = null;

        // saved views component configurations
        this.enableSavedViews = options.enableSavedViews;
        this.canSave = options.canSave;
        this.canSetDefault = options.canSetDefault;

        this.setNotificationData({
            title: ((this.context && this.context.serviceName)
                ? this.context.serviceName : options.title),
        });

        // initial errors, set to null initially
        this.errors = null;

        this.loadPromise = this.createGridPromise();
        this.hasRowSelector = options.hasRowSelector;
    },

    createGridPromise() {
        return new Promise((resolve, reject) => {
            let entitlementPromise;

            const initializeGridPromise = this.wrapper.initializeGridData({
                viewId: this.cid,
                compareColumns: this.compareColumns,
            });

            if (this.hideGridActionButtons === false && this.hideButtons === false) {
                entitlementPromise = EntitlementsAPI.getEntitlements(this.options);
            } else {
                entitlementPromise = new Promise((localResolve) => {
                    localResolve(null);
                });
            }

            hash({
                entitlements: entitlementPromise,
                wrapper: initializeGridPromise,
            }).then((result) => {
                if (result.entitlements === null) {
                    this.entitlements = null;
                    this.buttons = null;
                } else {
                    this.entitlements = result.entitlements;
                    this.buttons = ButtonsAPI.getButtonList(this.entitlements);
                }
                resolve();
            }).then(null, reject);
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.displayGrid();
        } else {
            this.loadViewRequirements();
        }
    },

    onClose() {
        if (this.grid) {
            this.grid.close();
        }
    },

    spinner(toggle) {
        this.$el.find('.loading-indicator-grid').toggle(toggle);
    },

    loadViewRequirements() {
        // Expose this promise so ancestors can hold on it.
        this.loadPromise.then(() => {
            this.options?.filterGridRowActions?.(this);
            this.setHasLoadedRequiredData(true);
            this.trigger('gridapi:loaded');
            this.render();
        }, (status) => {
            util.bind(errorHandlers.handleError, this)(true, status);

            this.trigger('gridapi:loaded:failed');
        });
    },

    displayGrid() {
        const CustomGrid = Grid.extend();
        CustomGrid.prototype.filterViewClass = dynamicTypeSearch;

        if (this.tableBodyClass) {
            CustomGrid.prototype.tableBodyClass = this.tableBodyClass;
        }

        /*
         * NH-43191 - SEV 3 HIGH | Fulton Production | ACH Payment Grid issues with
         * 3.2.9.3.7 - Editing and Sorting
         */
        CustomGrid.prototype.tableFooterLayoutClass = TableFooterLayout.extend({
            initialize(...args) {
                TableFooterLayout.prototype.initialize.apply(this, args);

                if (this.hasStickyFooter) {
                    this.changeFooterPosition = util.debounce(
                        this.updateFooterPosition.bind(this),
                        20,
                    );
                }
            },
        });

        const defaultOptions = {
            collection: this.wrapper.rows,
            columns: this.wrapper.columns,
            filter: this.filter,
            sortable: this.sortable,
            draggable: this.draggable,
            defaultResultsName: locale.get('records'),
            disableAuxControl: this.disableAuxControl,
            enableColumnRename: this.enableColumnRename,
            enableDateTimeSupport: this.enableDateTimeSupport,
            enableSavedViews: this.enableSavedViews || false,
            // NH-178965 HACK show saved view without filters
            savedViewsWithoutFilters: this.options.savedViewsWithoutFilters,
            paginate: this.paginate,
            pageSize: this.wrapper.rowsPerPage,
            pagerLocation: 'footer',
            paginateMethod: util.bind(this.wrapper.paginate, this.wrapper),
            sortMethod: util.bind(this.wrapper.sort, this.wrapper),
            filterMethod: util.bind(this.wrapper.filter, this.wrapper),
            serverSidePagination: this.serverSidePagination,
            resultsName: locale.get('records'),
            rowClassName: this.rowClassName,
            rowSubView: this.rowSubView,
            wrapper: this.wrapper,
        };

        this.grid = new CustomGrid(util.extend(defaultOptions, this.gridOptions));

        /*
         * when the build event is triggered, trigger gridAvailable for the listview
         * to listen to
         */
        this.listenTo(this.grid, 'build', () => {
            this.trigger('grid:available');
        });

        if (this.options.loadedCallback) {
            this.grid.once('after:build', this.options.loadedCallback);
        }
        /*
         * show the saved view component.  this is where we could hide the component
         * if the hideSavedViews
         * attribute is set.
         */

        /*
         * the prototype used this approach to listen for events from the saved view
         * component to refresh the grid.  this may need some rethinking.  Ultimately,
         * if a selection is made we want to be able to reload the grid and apply the
         * appropriate filters and sorts.
         */

        /*
         * also, if we use this approach we need to be sure that the listeners are properly
         * shut down.
         * this.listenTo(this.savedViewsView, 'filterGrid', self.filterResults);
         */

        this.gridContent.show(this.grid);
        this.grid.trigger('grid:displayed');

        // update pager to have wrapper
        this.grid.pagerView.wrapper = this.wrapper;

        if (this.enableSavedViews === true) {
            if (this.savedViewsView === null) {
                this.savedViewsView = new SavedViewsView({
                    grid: this.grid,
                    wrapper: this.wrapper,
                    savedViews: this.savedViews,
                    gridView: this,
                    canSave: this.canSave,
                    canSetDefault: this.canSetDefault,
                });
            }
            this.listenTo(this.savedViewsView, 'viewSelected', this.viewSelectionHandler);
            this.listenTo(this.savedViewsView, 'viewSaveSuccess', this.viewSavedHandler);
            this.grid.headerLayout.savedViewsView = this.savedViewsView;
            this.grid.headerLayout.savedViews.show(this.grid.headerLayout.savedViewsView);
        }

        this.notifyPageLoaded();
        this.initializeListeners();

        this.listenTo(this.grid, 'grid:contentRendered', function () {
            const selectAndProtectAll = this.wrapper && this.wrapper.b
                && this.wrapper.b.selectAndProtectAll;

            if (selectAndProtectAll) {
                this.selectAndProtectAllRows(true);
            } else if (selectAndProtectAll === false) {
                this.selectAndProtectIndividualRows();
            }

            if (selectAndProtectAll) {
                this.trigger('doBulkReverse');
            }
        });
    },

    initializeListeners() {
        // other app listeners
        this.listenTo(this.appBus, (`grid:row:action:action_modify_${this.cid}`), this.gridModifyAction);
        this.listenTo(this.appBus, (`grid:row:action:action_reverse_${this.cid}`), this.gridReverseAction);
        this.listenTo(this.appBus, (`grid:row:action:action_needinfo_${this.cid}`), this.gridNeedMoreInfoAction);
        this.listenTo(this.appBus, (`grid:row:action:action_modifyvfimport_${this.cid}`), this.gridModifyvFImportAction);
        this.listenTo(this.appBus, (`grid:row:action:action_repair_${this.cid}`), this.gridRepairAction);
        this.listenTo(this.appBus, (`grid:row:action:action_makeinst_${this.cid}`), this.gridMakeInstlAction);
        this.listenTo(this.appBus, (`grid:row:action:action_maketmpl_${this.cid}`), this.gridMakeTmpllAction);
        this.listenTo(this.appBus, (`grid:row:action:action_select_${this.cid}`), this.gridViewAction);
        this.listenTo(this.appBus, (`grid:row:action:action_viewsumt_${this.cid}`), this.gridViewAction);
        this.listenTo(this.appBus, (`grid:row:action:action_delete_${this.cid}`), this.gridRemoveAction);
        this.listenTo(this.appBus, (`grid:row:action:action_approve_${this.cid}`), this.gridApproveAction);
        this.listenTo(this.appBus, (`grid:row:action:action_unapprov_${this.cid}`), this.gridUnapproveAction);
        this.listenTo(this.appBus, (`grid:row:action:action_validate_${this.cid}`), this.gridValidateAction);
        this.listenTo(this.appBus, (`grid:row:action:action_unvalida_${this.cid}`), this.gridUnvalidateAction);
        this.listenTo(this.appBus, (`grid:row:action:action_reject_${this.cid}`), this.gridRejectAction);
        this.listenTo(this.appBus, (`grid:row:action:action_view_${this.cid}`), this.gridViewRptAction);
        this.listenTo(this.appBus, (`grid:row:action:action_filter_${this.cid}`), this.gridFilterAction);
        this.listenTo(this.appBus, (`grid:row:action:action_create profile_${this.cid}`), this.gridProfileAction);
        this.listenTo(this.appBus, (`grid:row:action:action_spaymt_${this.cid}`), this.gridScheduleAction);
        this.listenTo(this.appBus, (`grid:row:action:action_payissue_${this.cid}`), this.gridPayIssueAction);
        this.listenTo(this.appBus, (`grid:row:action:action_decision_${this.cid}`), this.gridDecisionAction);
        this.listenTo(this.appBus, (`grid:row:action:action_pay_${this.cid}`), this.gridPayAction);
        this.listenTo(this.appBus, (`grid:row:action:action_getrate_${this.cid}`), this.gridGetRate);
        this.listenTo(this.appBus, (`grid:row:action:action_trade_${this.cid}`), this.gridTradeAction);
        this.listenTo(this.appBus, (`grid:row:action:action_return_${this.cid}`), this.gridReturnAction);
        this.listenTo(this.appBus, (`grid:row:action:action_payrule_${this.cid}`), this.gridPayRuleAction);
        this.listenTo(this.appBus, (`grid:row:action:action_copyinst_${this.cid}`), this.gridCopyInstlAction);
        this.listenTo(this.appBus, (`grid:row:action:action_copytmpl_${this.cid}`), this.gridMakeTmpllAction);
        this.listenTo(this.appBus, (`${this.context.productCode}-changed`), this.refreshGridData);
        this.listenTo(this.appBus, (`grid:row:action:action_cancelstop_${this.cid}`), this.gridCancelStopAction);
        this.listenTo(this.appBus, (`grid:row:action:action_imageDetail_${this.cid}`), this.gridImageAction);
        this.listenTo(this.appBus, (`grid:row:action:action_stop_${this.cid}`), this.gridStopAction);
        this.listenTo(this.appBus, (`grid:row:action:action_cancel_${this.cid}`), this.gridCancelAction);
        this.listenTo(this.appBus, (`grid:row:action:action_image_${this.cid}`), this.gridImageAction);
        this.listenTo(this.appBus, (`grid:row:action:action_imageSearchDetail_${this.cid}`), this.gridImageSearchAction);
        this.listenTo(this.appBus, (`grid:row:action:action_photo_${this.cid}`), this.gridPhotoAction);
        this.listenTo(this.appBus, (`gridapi:spin_${this.cid}`), this.spinner);
        this.listenTo(this.appBus, (`grid:row:action:action_comment_${this.cid}`), this.gridCommentAction);
        this.listenTo(this.appBus, (`grid:row:action:action_remitter_${this.cid}`), this.gridRemitterAction);
        this.listenTo(this.appBus, (`grid:row:action:action_dload_${this.cid}`), this.gridDownloadAction);
        this.listenTo(this.appBus, (`grid:row:action:action_match_${this.cid}`), this.gridMatchAction);
        this.listenTo(this.appBus, (`grid:row:action:action_disable_${this.cid}`), this.gridDisableAction);
        this.listenTo(this.appBus, (`grid:row:action:action_enable_${this.cid}`), this.gridEnableAction);
        this.listenTo(this.appBus, (`grid:row:action:action_assign_${this.cid}`), this.gridAssignAction);
        this.listenTo(this.appBus, (`grid:row:action:action_unassign_${this.cid}`), this.gridUnassignAction);
        this.listenTo(this.appBus, (`grid:row:action:action_unlock_${this.cid}`), this.gridUnlockAction);
        this.listenTo(this.appBus, (`grid:row:action:action_lost_${this.cid}`), this.gridLostAction);
        this.listenTo(this.appBus, (`grid:row:action:action_misplace_${this.cid}`), this.gridMisplaceAction);
        this.listenTo(this.appBus, (`grid:row:action:action_found_${this.cid}`), this.gridFoundAction);
        this.listenTo(this.appBus, (`grid:row:action:action_replace_${this.cid}`), this.gridReplaceAction);
        this.listenTo(this.appBus, (`grid:row:action:action_restore_${this.cid}`), this.gridRestoreAction);
        this.listenTo(this.appBus, (`grid:row:action:action_deploy_${this.cid}`), this.gridDeployAction);
        this.listenTo(this.appBus, (`grid:row:action:action_archive_${this.cid}`), this.gridArchiveAction);
        this.listenTo(this.appBus, (`grid:row:action:action_content_${this.cid}`), this.gridContentAction);
        this.listenTo(this.appBus, (`grid:row:action:action_simulate_${this.cid}`), this.gridSimulateAction);
        this.listenTo(this.appBus, (`grid:row:action:action_clone_${this.cid}`), this.gridCloneAction);
        this.listenTo(this.appBus, (`grid:row:action:action_qentry_${this.cid}`), this.gridQEntryAction);
        this.listenTo(this.appBus, (`grid:row:action:action_resetpw_${this.cid}`), this.gridResetPasswordAction);
        this.listenTo(this.appBus, (`grid:row:action:action_copyfromuser_${this.cid}`), this.gridCopyFromUserAction);
        this.listenTo(this.appBus, (`grid:row:action:action_updset_${this.cid}`), this.gridUpdateProfileRestricted);
        this.listenTo(this.appBus, (`grid:row:action:action_export_${this.cid}`), this.gridExportAction);
        this.listenTo(this.appBus, (`grid:row:action:action_createnotification_${this.cid}`), this.gridNotificationFromTemplateAction);
        this.listenTo(this.appBus, (`grid:row:action:action_createtemplate_${this.cid}`), this.gridTemplateFromNotificationAction);
        // PCM-1207
        this.listenTo(this.appBus, (`grid:row:action:action_suspend_${this.cid}`), this.gridSuspendAction);
        this.listenTo(this.appBus, (`grid:row:action:action_activate_${this.cid}`), this.gridActivateAction);
        // PCM-1207
        // PCM-1506
        this.listenTo(this.appBus, (`grid:row:action:action_printchecks_${this.cid}`), this.gridPrintChecksAction);
        this.listenTo(this.appBus, (`grid:row:action:action_reprintchecks_${this.cid}`), this.gridReprintChecksAction);
        this.listenTo(this.appBus, (`grid:row:action:action_reprintcheck_${this.cid}`), this.gridReprintCheckAction);
        // PCM-1506
        this.listenTo(this.appBus, (`grid:row:action:action_void_${this.cid}`), this.gridVoidCheckAction);
        this.listenTo(this.appBus, (`grid:row:action:action_stop_${this.cid}`), this.gridStopCheckAction);
        this.listenTo(this.appBus, (`grid:row:action:action_updchkst_${this.cid}`), this.gridUpdateCheckStatusAction);
    },

    getActionContext() {
        /*
         * If the action data was not provided as options at init
         * see if we can get it from the wrapper, which gets it from the data from
         * the service call
         */
        const actionContext = this.wrapper.getActionContext();
        if (!(this.context.productCode) && (actionContext.productCode)) {
            this.context.productCode = actionContext.productCode;
        }
        if (!(this.context.typeCode) && (actionContext.typeCode)) {
            this.context.typeCode = actionContext.typeCode;
        }
        if (!(this.context.functionCode) && (actionContext.functionCode)) {
            this.context.functionCode = actionContext.functionCode;
        }
        return this.context;
    },

    viewSelectionHandler(model, pagerView) {
        this.wrapper.viewId = (model) ? model.get('viewId') : '';
        this.updateListViewConfigForView(this.wrapper.viewId);
        this.wrapper.totalRefreshGridData(pagerView, false);
    },

    viewSelectionHandlerById(viewId, action) {
        this.wrapper.viewId = viewId;
        this.savedViewsView.selectByViewId(viewId, action);
        this.updateListViewConfigForView(viewId);
        this.wrapper.totalRefreshGridData();
    },

    viewSavedHandler(model) {
        this.wrapper.columns.isUserOwnedView = true;
        this.trigger('gridViewSaved', model);
    },

    /**
     * reset listViewConfig and update config with the newly selected viewId
     * @param {string} viewId
     */
    updateListViewConfigForView(viewId) {
        const model = this.savedViews.getModelByViewId(viewId);
        // only perform this action if the view is actually available
        if (model && this.wrapper.lvc) {
            this.wrapper.lvc.reset(); // reset listview config
            this.wrapper.lvc.set(LvcConstants.VIEWID, viewId);
            if (this.grid.filterProc) {
                this.grid.filterProc.filters.reset([], { silent: true });
            }
            if (this.grid.filterBadgeHeaderView) {
                this.grid.filterBadgeHeaderView.render();
            }
        }
    },

    /**
     *  @method refreshGridData
     *  @param {View} pagerView - pager View of parent grid (shows
     * rowPerpage and display index)
     *  Triggers the 'refreshGridData' method in gridWrapper API to fetch
     *  updated data for the current grid and refresh it.
     *  Optional flags sent to maintain the current view settings in grid (rowsPerPage, index)
     */
    refreshGridData(pagerView) {
        const self = this;
        this.wrapper.refreshGridData(false, pagerView, true).then(() => {
            self.trigger('gridapi:loaded');

            /*
             * HACK: NH-45819 When an existing batch payment's beneficiaries
             * are updated or deleted the trigger event 'grid:contentRendered'
             * that is normally triggered does not get the code that calculate the batch
             * totals to run. The following is a shortcut to address this issue
             * by relying on triggering the event name 'updatePaymentSummary' on the appBus.
             * The listener for the event name 'updateSummary' on the appBus
             * is defined in the method setChildViewListeners in entry.js
             */
            if (self.options && self.options.context && self.options.context.actionContext && (self.options.context.actionContext.functionCode === 'BATCH'
                || self.options.context.actionContext.functionCode === 'BHTMPL')) {
                self.appBus.trigger('updatePaymentSummary');
            }
        });
    },

    /**
     * @method selectAndProtectAllRows
     * @param {boolean} protect
     * - Either selects every row in the grid and protects the row selectors, or
     * unchecks and un protects them.
     */
    selectAndProtectAllRows(protect) {
        this.$('.grid-all-rows-selector, .column-row-selector').prop('checked', protect);
        this.$('.grid-all-rows-selector, .column-row-selector').prop('disabled', protect);
    },

    /**
     * @method selectAndProtectIndividualRow
     * - Select and protect an individual row.
     */
    selectAndProtectIndividualRows() {
        this.wrapper.rows.models.forEach((row) => {
            if (row.get('STATUS_DESCRIPTION') === 'Reversed (Pending Approval)' || row.get('STATUS_DESCRIPTION') === 'Reversed') {
                this.$(`#row-selector-${row.cid}`).prop('checked', true);
                if (row.get('BENEREVERSEREASON')) {
                    this.$(`#row-selector-${row.cid}`).prop('disabled', true);
                }
            }
        });
    },

    /**
     * @method selectRows
     * @param {Array} rowModels - array or row models
     * @param {boolean} [select]
     */
    selectRows(rowModels) {
        rowModels.forEach((model) => {
            const row = this.wrapper.rows.get(model.id);
            if (row) {
                const $rowSelector = this.$(`#row-selector-${row.cid}`);
                if (!$rowSelector.is(':checked')) {
                    $rowSelector.trigger('click');
                }
            }
        });
    },

    gridVoidCheckAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'voidCheck',
                model,
            },
        );
    },

    gridStopCheckAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'stopCheck',
                model,
            },
        );
    },

    gridPrintChecksAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'printchecks',
                model,
            },
        );
    },

    gridReprintChecksAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'reprintchecks',
                model,
            },
        );
    },

    gridReprintCheckAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'reprintcheck',
                model,
            },
        );
    },

    gridUpdateCheckStatusAction(modelParam) {
        const model = modelParam;
        if (this.enableRowContextButtonCallbacks === false) {
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context: model.context,
                            model: newModel,
                            viewType: 'modal',
                            state: 'view',
                        };

                        const view = PageApi.page.get(options);

                        view.on('cancel', () => {
                            Dialog.close();
                        }, this);

                        Dialog.open(view);
                    },
                });
            }).then(null, util.bind(errorHandlers.intraPage, this));
        } else {
            // occasionally the lockedFields array can contain empty strings
            if (this.lockedFields && this.lockedFields.join(',')) {
                // pass the locked fields along to the model that pops up
                model.set('LOCKED_FIELDS', this.lockedFields.join(','));
                model.lockedFields = this.lockedFields;
            }
            this.trigger(
                'rowAction',
                {
                    action: 'updchkst',
                    model,
                },
            );
        }
    },

    gridRemoveAction(model) {
        const self = this;

        if (self.disableDeleteConfirm === true) {
            this.gridRemoveActionHelper(model);
        } else {
            Dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.delete'), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
                if (ok) {
                    self.gridRemoveActionHelper(model);
                }
                self.appBus.trigger(`${self.context.productCode}-delete-${ok}`);
            });
        }
    },

    gridRemoveActionHelper(model) {
        const self = this;
        if (self.enableRowContextButtonCallbacks === false) {
            model.destroy({
                wait: true,

                success() {
                    self.appBus.trigger(`${self.context.productCode}-changed`);
                    Dialog.alert('deleted successfully');
                },

                error(m, response) {
                    Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                },
            });
        } else {
            self.trigger(
                'rowAction',
                {
                    action: 'delete',
                    model,
                },
            );
        }
    },

    // PCM-1207
    gridSuspendAction(model) {
        if (this.disableSuspendConfirm === true) {
            this.gridSuspendActionHelper(model);
        } else {
            Dialog.confirm(
                locale.get('tableMaintenance.dialog.confirm.item.suspend'),
                locale.get('tableMaintenance.dialog.confirm.title.suspend'),
                (ok) => {
                    if (ok) {
                        this.gridSuspendActionHelper(model);
                    }
                    this.appBus.trigger(`${this.context.productCode}-suspend-${ok}`);
                },
            );
        }
    },

    gridSuspendActionHelper(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            model.suspend({
                wait: true,

                success: () => {
                    this.appBus.trigger(`${this.context.productCode}-changed`);
                    Dialog.alert('suspended successfully');
                },

                error: (m, response) => {
                    // Note: it doesn't look proper XHR err handler (done as above examples.)
                    if (response) {
                        Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                    }
                },
            });
        } else {
            this.trigger('rowAction', {
                action: 'suspend',
                model,
            });
        }
    },

    gridActivateAction(model) {
        if (this.disableActivateConfirm === true) {
            this.gridActivateActionHelper(model);
        } else {
            Dialog.confirm(
                locale.get('tableMaintenance.dialog.confirm.item.activate'),
                locale.get('tableMaintenance.dialog.confirm.title.activate'),
                (ok) => {
                    if (ok) {
                        this.gridActivateActionHelper(model);
                    }
                    this.appBus.trigger(`${this.context.productCode}-activate-${ok}`);
                },
            );
        }
    },

    gridActivateActionHelper(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            model.activate({
                wait: true,

                success: () => {
                    this.appBus.trigger(`${this.context.productCode}-changed`);
                    Dialog.alert('activated successfully');
                },

                error: (m, response) => {
                    if (response) {
                        Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                    }
                },
            });
        } else {
            this.trigger('rowAction', {
                action: 'activate',
                model,
            });
        }
    },
    // PCM-1207

    gridApproveAction(model) {
        const self = this;
        if (this.enableRowContextButtonCallbacks === false) {
            Dialog.confirm(locale.get('tableMaintenance.dialog.confim.item.approve'), locale.get('tableMaintenance.dialog.confirm.title.approve'), (ok) => {
                if (ok) {
                    model.approve({
                        wait: true,

                        success() {
                            self.appBus.trigger(`${self.context.productCode}-changed`);
                            Dialog.alert('approved successfully');
                        },

                        error(m, response) {
                            Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                        },
                    });
                }
            });
        } else if (model.get('INITIATEPREFUND') === '1') {
            Dialog.confirm(locale.get('common.prefund.initiate'), 'Warning', (ok) => {
                if (ok) {
                    self.trigger(
                        'rowAction',
                        {
                            action: 'approve',
                            model,
                        },
                    );
                }
            });
        } else {
            self.trigger(
                'rowAction',
                {
                    action: 'approve',
                    model,
                },
            );
        }
    },

    gridUnapproveAction(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            Dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.unapprove'), locale.get('tableMaintenance.dialog.confirm.title.unapprove'), (ok) => {
                if (ok) {
                    model.destroy({
                        wait: true,

                        success() {
                            self.appBus.trigger(`${self.context.productCode}-changed`);
                            Dialog.alert('unapproved successfully');
                        },

                        error(m, response) {
                            Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                        },
                    });
                }
            });
        } else {
            this.trigger(
                'rowAction',
                {
                    action: 'unapprove',
                    model,
                },
            );
        }
    },

    gridValidateAction(model) {
        const self = this;
        if (this.enableRowContextButtonCallbacks === false) {
            Dialog.confirm(locale.get('tableMaintenance.dialog.confim.item.validate'), locale.get('tableMaintenance.dialog.confirm.title.validate'), (ok) => {
                if (ok) {
                    model.validateAction({
                        wait: true,

                        success() {
                            self.appBus.trigger(`${self.context.productCode}-changed`);
                            Dialog.alert('validated successfully');
                        },

                        error(m, response) {
                            Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                        },
                    });
                }
            });
        } else {
            self.trigger(
                'rowAction',
                {
                    action: 'validate',
                    model,
                },
            );
        }
    },

    gridUnvalidateAction(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            Dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.unvalidate'), locale.get('tableMaintenance.dialog.confirm.title.unvalidate'), (ok) => {
                if (ok) {
                    model.destroy({
                        wait: true,

                        success() {
                            self.appBus.trigger(`${self.context.productCode}-changed`);
                            Dialog.alert('unvalidated successfully');
                        },

                        error(m, response) {
                            Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                        },
                    });
                }
            });
        } else {
            this.trigger(
                'rowAction',
                {
                    action: 'unvalidate',
                    model,
                },
            );
        }
    },

    gridRejectAction(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            Dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.reject'), locale.get('tableMaintenance.dialog.confirm.title.reject'), (ok) => {
                if (ok) {
                    model.destroy({
                        wait: true,

                        success() {
                            self.appBus.trigger(`${self.context.productCode}-changed`);
                            Dialog.alert('rejected successfully');
                        },

                        error(m, response) {
                            Dialog.alert(response.errorMessage, `ERROR - ${response.errorCode}`);
                        },
                    });
                }
            });
        } else {
            Dialog.custom(new RejectDialog({
                model,
                grid: this,
            }));
        }
    },

    gridViewAction(modelParam) {
        const model = modelParam;
        if (this.enableRowContextButtonCallbacks === false) {
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context: model.context,
                            model: newModel,
                            viewType: 'modal',
                            state: 'view',
                        };

                        const view = PageApi.page.get(options);

                        view.on('cancel', () => {
                            Dialog.close();
                        }, this);

                        Dialog.open(view);
                    },
                });
            }).then(null, util.bind(errorHandlers.intraPage, this));
        } else {
            // occasionally the lockedFields array can contain empty strings
            if (this.lockedFields && this.lockedFields.join(',')) {
                // pass the locked fields along to the model that pops up
                model.set('LOCKED_FIELDS', this.lockedFields.join(','));
                model.lockedFields = this.lockedFields;
            }
            this.trigger(
                'rowAction',
                {
                    action: 'select',
                    model,
                },
            );
        }
    },

    gridGetRate(model) {
        this.trigger(
            'rowAction',
            {
                action: 'getrate',
                model,
            },
        );
    },

    gridTradeAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'trade',
                model,
            },
        );
    },

    gridMatchAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'match',
                model,
            },
        );
    },

    gridDisableAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'disable',
                model,
            },
        );
    },

    gridEnableAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'enable',
                model,
            },
        );
    },

    gridAssignAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'assign',
                model,
            },
        );
    },

    gridUnassignAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'unassign',
                model,
            },
        );
    },

    gridUnlockAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'unlock',
                model,
            },
        );
    },

    gridLostAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'lost',
                model,
            },
        );
    },

    gridMisplaceAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'misplace',
                model,
            },
        );
    },

    gridFoundAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'found',
                model,
            },
        );
    },

    gridReplaceAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'replace',
                model,
            },
        );
    },

    gridRestoreAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'restore',
                model,
            },
        );
    },

    gridModifyAction(modelParam) {
        const model = modelParam;
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context: model.context,
                            model: newModel,
                            viewType: 'modal',
                            state: 'modify',
                        };

                        const view = PageApi.page.get(options);

                        view.on('save', () => {
                            Dialog.close();
                            self.appBus.trigger((`${self.context.productCode}-changed`));
                            Dialog.alert(locale.get('common.saved.grid'));
                        }, this);

                        view.on('cancel', () => {
                            Dialog.close();
                        }, this);

                        Dialog.open(view);
                    },
                });
            }).then(null, util.bind(errorHandlers.intraPage, this));
        } else {
            // occasionally the lockedFields array can contain empty strings
            if (this.lockedFields && this.lockedFields.join(',')) {
                // pass the locked fields along to the model that pops up
                model.set('LOCKED_FIELDS', this.lockedFields.join(','));
                model.lockedFields = this.lockedFields;
            }
            this.trigger(
                'rowAction',
                {
                    action: 'modify',
                    model,
                },
            );
        }
    },

    gridReverseAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'reverse',
                model,
            },
        );
    },

    gridNeedMoreInfoAction(model) {
        Dialog.custom(new NeedInfoDialog({
            model,
            grid: this,
        }));
    },

    gridModifyvFImportAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'modifyvfimport',
                model,
            },
        );
    },

    gridRepairAction(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context: model.context,
                            model: newModel,
                            viewType: 'modal',
                            state: 'repair',
                        };

                        const view = PageApi.page.get(options);

                        view.on('save', () => {
                            Dialog.close();
                            self.appBus.trigger((`${self.context.productCode}-changed`));
                            Dialog.alert(locale.get('common.saved.grid'));
                        }, this);

                        view.on('cancel', () => {
                            Dialog.close();
                        }, this);

                        Dialog.open(view);
                    },
                });
            }).then(null, util.bind(errorHandlers.intraPage, this));
        } else {
            this.trigger(
                'rowAction',
                {
                    action: 'repair',
                    model,
                },
            );
        }
    },

    gridMakeInstlAction(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context: model.context,
                            model: newModel,
                            viewType: 'modal',
                            state: 'modify',
                        };

                        const view = PageApi.page.get(options);

                        view.on('save', () => {
                            Dialog.close();
                            self.appBus.trigger((`${self.context.productCode}-changed`));
                            Dialog.alert(locale.get('common.saved.grid'));
                        }, this);

                        view.on('cancel', () => {
                            Dialog.close();
                        }, this);

                        Dialog.open(view);
                    },
                });
            }).then(null, util.bind(errorHandlers.intraPage, this));
        } else {
            this.trigger(
                'rowAction',
                {
                    action: 'makeInst',
                    model,
                },
            );
        }
    },

    gridMakeTmpllAction(model) {
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context: model.context,
                            model: newModel,
                            viewType: 'modal',
                            state: 'modify',
                        };

                        const view = PageApi.page.get(options);

                        view.on('save', () => {
                            Dialog.close();
                            self.appBus.trigger((`${self.context.productCode}-changed`));
                            Dialog.alert('saved, grid data refreshed');
                        }, this);

                        view.on('cancel', () => {
                            Dialog.close();
                        }, this);

                        Dialog.open(view);
                    },
                });
            }).then(null, util.bind(errorHandlers.intraPage, this));
        } else {
            this.trigger(
                'rowAction',
                {
                    action: 'makeTmpl',
                    model,
                },
            );
        }
    },

    gridScheduleAction(modelParam) {
        const model = modelParam;
        if (this.enableRowContextButtonCallbacks === false) {
            const self = this;
            DataApi.model.generateModelFromModel(model).then((newModel) => {
                newModel.fetch({
                    silent: true,

                    success() {
                        const options = {
                            context: model.context,
                            model: newModel,
                            viewType: 'modal',
                            state: 'modify',
                        };

                        const view = PageApi.page.get(options);

                        view.on('save', () => {
                            Dialog.close();
                            self.appBus.trigger((`${self.context.productCode}-changed`));
                            Dialog.alert(locale.get('common.update.record.success'));
                        }, this);

                        view.on('cancel', () => {
                            Dialog.close();
                        }, this);

                        Dialog.open(view);
                    },
                });
            }).then(null, util.bind(errorHandlers.intraPage, this));
        } else {
            model.context.serviceName = '/tableMaintenance/scheduledPayments';
            model.context.actionData.typeCode = 'ACH_SCHE';
            model.context.actionData.subType = '*';
            model.context.actionData.functionCode = 'MAINT';
            model.set('FUNCTION', 'MAINT');
            model.set('TYPE', 'ACH_SCHE');
            this.trigger(
                'rowAction',
                {
                    action: 'spaymt',
                    model,
                },
            );
        }
    },

    gridQEntryAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'qentry',
                model,
            },
        );
    },

    gridViewRptAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'viewrpt',
                model,
            },
        );
    },

    gridFilterAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'filter',
                model,
            },
        );
    },

    gridProfileAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'profile',
                model,
            },
        );
    },

    gridPayIssueAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'payissue',
                model,
            },
        );
    },

    gridSimulateAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'simulate',
                model,
            },
        );
    },

    gridCloneAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'clone',
                model,
            },
        );
    },

    gridPayRuleAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'payrule',
                model,
            },
        );
    },

    gridImageAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'image',
                model,
            },
        );
    },

    gridImageSearchAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'imageSearch',
                model,
            },
        );
    },

    gridDecisionAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'decision',
                model,
            },
        );
    },

    gridPayAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'pay',
                model,
            },
        );
    },

    gridReturnAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'return',
                model,
            },
        );
    },

    gridCopyInstlAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'copyinst',
                model,
            },
        );
    },

    gridCommentAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'comment',
                model,
            },
        );
    },

    gridRemitterAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'manageRmt',
                model,
            },
        );
    },

    gridDownloadAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'download',
                model,
            },
        );
    },

    /**
     * @method gridCancelStopAction
     * @param {Model} model - containing the model from the row that is acted on.
     * - This method is called as part of the meta driven framework when user
     * clicks on Cancel Stop from the Stop grid.
     */
    gridCancelStopAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'cancelStop',
                model,
            },
        );
    },

    gridStopAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'stop',
                model,
            },
        );
    },

    gridCancelAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'cancel',
                model,
            },
        );
    },

    gridPhotoAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'photo',
                model,
            },
        );
    },

    gridDeployAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'deploy',
                model,
            },
        );
    },

    gridArchiveAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'archive',
                model,
            },
        );
    },

    gridContentAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'content',
                model,
            },
        );
    },

    gridResetPasswordAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'resetpassword',
                model,
            },
        );
    },

    gridCopyFromUserAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'copyfromuser',
                model,
            },
        );
    },

    gridUpdateProfileRestricted(model) {
        this.trigger(
            'rowAction',
            {
                action: 'updateProfileRestricted',
                model,
            },
        );
    },

    gridExportAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'export',
                model,
            },
        );
    },

    gridNotificationFromTemplateAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'nTemplate',
                model,
            },
        );
    },

    gridTemplateFromNotificationAction(model) {
        this.trigger(
            'rowAction',
            {
                action: 'tNotification',
                model,
            },
        );
    },

    delete() {
        const ar = this.grid.getSelectedRows();
        if (ar.length > 0) {
            const self = this;
            let txtKey = 'tableMaintenance.dialog.confirm.item.delete';
            if (ar.length > 1) {
                txtKey = 'tableMaintenance.dialog.confirm.items.delete';
            }
            Dialog.confirm(locale.get(txtKey), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
                if (ok) {
                    const successMethod = util.after(ar.length, () => {
                        self.appBus.trigger((`${self.context.productCode}-changed`));
                        Dialog.alert('deleted successfully');
                    });

                    for (let i = 0; i < ar.length; i += 1) {
                        const model = self.wrapper.rows.get(ar[i]);
                        model.destroy({
                            success: successMethod,
                        });
                    }
                }
            });
        }
    },

    insert() {
        const self = this;

        const options = {
            context: this.options.insertContext || this.context,
            viewType: 'modal',
        };

        const view = PageApi.page.get(options);
        view.on('save', () => {
            Dialog.close();
            self.appBus.trigger((`${self.context.productCode}-changed`));
            Dialog.alert(locale.get('common.saved.grid'));
        }, this);

        view.on('cancel', () => {
            Dialog.close();
        }, this);

        Dialog.open(view);
    },

    templateHelpers() {
        const self = this;
        return {
            buttonList() {
                if (self.hideGridActionButtons === false && self.hideButtons === false) {
                    /*
                     * to get all buttons, pass request without exception list
                     * return ButtonsAPI.getButtonHTML(self.entitlements);
                     */

                    /*
                     * if you're not ready for all buttons and just want a subset,
                     * pass exception list
                     */
                    return ButtonsAPI.getButtonHTML(self.entitlements, ['modify', 'select']);
                }
                return undefined;
            },
        };
    },

    getResponseParameter(key) {
        return (this.wrapper.responseParameters || {})[key];
    },

    getRows() {
        return this.wrapper.rows;
    },

    /**
     *
     * @param column
     * @returns []
     * @Note: If column is not found, returns array of undefined for each model in rows
     */
    getColumn(column) {
        return this.wrapper.rows.pluck(column);
    },
});

export default DesktopGridView;
