import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import printReportTmpl from './printReport.hbs';

export default ItemView.extend({
    template: printReportTmpl,
    modalClass: 'modal-lg',

    initialize() {
        this.model = new Model();
        this.dialogTitle = locale.get('gir.printReport');
        this.dialogButtons = [{
            text: locale.get('reports.print.btn'),
            className: 'btn btn-primary',
            callback: 'print',
        }, {
            text: locale.get('reports.cancel.btn'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    print() {
        dialog.close();
        const context = {
            productCode: 'GIR',
            functionCode: 'CD',
        };
        if (this.model.get('expdata') === 'both') {
            context.typeCode = 'CTRLDET';
        } else {
            context.typeCode = 'CTRLSUM';
        }
        this.model.set('context', context);
        this.trigger('printReport', this.model);
    },

    cancel() {
        dialog.close();
    },
});
