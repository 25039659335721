import ItemView from '@glu/core/src/itemView';
import customHeaderViewTmpl from './customHeaderView.hbs';

export default ItemView.extend({
    tagName: 'th',
    template: customHeaderViewTmpl,

    onRender() {
        this.$el.attr('data-cid', this.model.cid);
    },
});
