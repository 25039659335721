var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"form-group col-12\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.ofx.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":27},"end":{"line":15,"column":61}}}))
    + "</label>\n                    <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"alternateUserIdValue") || (depth0 != null ? lookupProperty(depth0,"alternateUserIdValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"alternateUserIdValue","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":75}}}) : helper)))
    + "</p>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n            <div class=\"form-group checkbox col-12\">\n                <input class=\"opt-check\" id=\"optOut\" type=\"checkbox\" name=\"OPTUISECURITYCHECK\" data-bind=\"model\" disabled=\"disabled\"/>\n                <label for=\"optOut\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.optout",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":36},"end":{"line":25,"column":70}}}))
    + "</label>\n            </div>\n            <div class=\"form-group col-12\">\n                <label for=\"optReason\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.optout.reason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":80}}}))
    + "</label>\n                <input type=\"text\" id=\"optReason\" class=\"opt-reason\" name=\"OPTUISECURITYCHECKREASON\" data-bind=\"model\" readonly=\"readonly\"/>\n            </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"form-group col-12\">\n                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.Restriction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":22},"end":{"line":42,"column":53}}}))
    + "</span>\n                <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"allowFXValue") || (depth0 != null ? lookupProperty(depth0,"allowFXValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"allowFXValue","hash":{},"data":data,"loc":{"start":{"line":43,"column":22},"end":{"line":43,"column":38}}}) : helper)))
    + "</span>\n            </div>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"entitled") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":51,"column":23}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":50,"column":39}}}) : helper)))
    + "</li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"getPaymentsChk\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.smbperm.benePay.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":41},"end":{"line":61,"column":78}}}))
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"getPaymentsChk\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.smbperm.no.benePay.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":41},"end":{"line":63,"column":81}}}))
    + "</div>\n";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":16},"end":{"line":76,"column":25}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>"
    + alias3(((depths[1] && lookupProperty(depths[1],"maskAccounts"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0),{"name":"../maskAccounts","hash":{},"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":75,"column":57}}}))
    + " - "
    + alias3(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":75,"column":60},"end":{"line":75,"column":75}}}) : helper)))
    + "</li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"assignAllAccount") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":79,"column":12}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"allAccountsText\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.select.all.note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":42},"end":{"line":78,"column":85}}}))
    + "</div>\n            ";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group col-4\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":27},"end":{"line":90,"column":60}}}))
    + "</label>\n                    <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"dailyLimit") || (depth0 != null ? lookupProperty(depth0,"dailyLimit") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"dailyLimit","hash":{},"data":data,"loc":{"start":{"line":91,"column":51},"end":{"line":91,"column":65}}}) : helper)))
    + "</p>\n                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group col-4\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":96,"column":27},"end":{"line":96,"column":66}}}))
    + "</label>\n                    <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"transactionLimit") || (depth0 != null ? lookupProperty(depth0,"transactionLimit") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"transactionLimit","hash":{},"data":data,"loc":{"start":{"line":97,"column":51},"end":{"line":97,"column":71}}}) : helper)))
    + "</p>\n                </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group col-4\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":102,"column":27},"end":{"line":102,"column":49}}}))
    + "</label>\n                    <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"fileLimit") || (depth0 != null ? lookupProperty(depth0,"fileLimit") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"fileLimit","hash":{},"data":data,"loc":{"start":{"line":103,"column":51},"end":{"line":103,"column":64}}}) : helper)))
    + "</p>\n                </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset>\n        <h4>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.additional.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":112,"column":12},"end":{"line":112,"column":48}}}))
    + "</h4>\n\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bankWidgets") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":115,"column":12},"end":{"line":121,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n    </fieldset>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"bankWidgets") : depth0),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":16},"end":{"line":118,"column":25}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":117,"column":24},"end":{"line":117,"column":33}}}) : helper)))
    + "</li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"assignAllBankWidget") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":12},"end":{"line":121,"column":12}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"allBankWidgetsText\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.select.all.bankWidgets.note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":120,"column":45},"end":{"line":120,"column":100}}}))
    + "</div>\n            ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"SMBUserCentricForm\">\n\n    <div data-region=\"pendingChangesRegion\" class=\"SMBPendingChanges\"></div>\n    <!--\n    <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.user.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":45}}}))
    + "</h4>\n    -->\n    <fieldset>\n        <legend class=\"sr-only\">User Fields</legend>\n\n        <div class=\"smbDynamicFieldsRegion\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOFXDirectConnectEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showOptUI") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "\n    </fieldset>\n\n    <fieldset>\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.permissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":51}}}))
    + "</h4>\n        <legend class=\"sr-only\">Permissions</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAllowFXSelection") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"permissions") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":52,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </fieldset>\n\n    <fieldset>\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.beneficiaries",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":57,"column":53}}}))
    + "</h4>\n        <legend class=\"sr-only\">Payees</legend>\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"entitledPaymentsChk") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":64,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n    </fieldset>\n\n    <fieldset>\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":69,"column":48}}}))
    + "</h4>\n        <legend class=\"sr-only\">Accounts</legend>\n\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":79,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n    </fieldset>\n\n    <fieldset>\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":12},"end":{"line":84,"column":46}}}))
    + "</h4>\n        <legend class=\"sr-only\">Limits</legend>\n\n        <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDailyLimitEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":12},"end":{"line":93,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTransactionLimitEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":12},"end":{"line":99,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFileLimitEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":12},"end":{"line":105,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n    </fieldset>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBankWidgetsEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":0},"end":{"line":124,"column":7}}})) != null ? stack1 : "")
    + "\n    <fieldset class=\"panel panel-primary auditPanel\">\n        <legend class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.CONTAINER2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":127,"column":32},"end":{"line":127,"column":76}}}))
    + "</legend>\n        <h4 role=\"tab\" class=\"panel-title\" id=\"auditHeading\">\n            <a role=\"button\" data-toggle=\"collapse\" href=\"#auditPanel\" aria-expanded=\"false\" aria-controls=\"auditPanel\" class=\"collapsed\">\n                <span class=\"indicator-icon\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.CONTAINER2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":130,"column":53},"end":{"line":130,"column":97}}}))
    + "\n            </a>\n        </h4>\n        <div id=\"auditPanel\" class=\"panel-collapse collapse collapsed\" role=\"tabpanel\" aria-labelledby=\"auditHeading\">\n            <div class=\"panel-body\"></div>\n        </div>\n    </fieldset>\n</form>\n";
},"useData":true,"useDepths":true});