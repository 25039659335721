import $ from 'jquery';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import {
    amountEqualityChange, equalityChange,
    amountFieldBlur,
} from '../../util/advancedFilterUtil';

/**
 * Search the current value and determine if it contains a
 * transaction type that requires debit or credit
 * @param {string|Array} value
 */
export const requireDebitCredit = (value) => {
    const valueArray = Array.isArray(value) ? value : [value];
    // Transaction types - codes from the backend
    const valueRequired = ['195', '495'];
    return valueArray.some(v => valueRequired.includes(v));
};

export default function (form, initialState) {
    const { model } = form.formView;
    const $amount = form.formView.$('[name="AMOUNT"]');
    const $amount2 = form.formView.$('[name="AMOUNT2"]');
    const accountFilter = form.fields.ACCOUNTFILTER;
    const accountFilter2 = form.fields.ACCOUNTFILTER2;

    const $serialnumber = form.formView.$('[name="SERIALNUMBER"]');
    const $serialnumber2 = form.formView.$('[name="SERIALNUMBER2"]');

    let $selections = $('.select2-chosen');
    let $elCHECKSTATUSCODE = null;

    if (form.field('CHECKSTATUSCODE').$el.length) {
        [$elCHECKSTATUSCODE] = form.field('CHECKSTATUSCODE').$el;
    }

    const toggleDebitCredit = (required) => {
        if (required) {
            form.field('DR_CR').shouldBeRequired(true);
        } else {
            form.field('DR_CR').shouldBeOptional(true);
        }
    };

    if (initialState) {
        let id;
        const $operators = $('select.field-operator');
        util.each($operators, (item, idx, list) => {
            if (item.value === 'BETWEEN') {
                id = list[idx].name.replace('-equality', '');
                id += '2';
                $(`#${id}`).removeClass('hidden');
            }
        });

        if ($elCHECKSTATUSCODE && $elCHECKSTATUSCODE.value.length > 0) {
            $selections = $('.select2-chosen');
            $selections[0].innerHTML = store.get('check_inq_ACCOUNTFILTER');
            $selections[1].innerHTML = store.get('check_inq_CHECKSTATUSCODE');
        }
        /*
         * Remove listener with the specified callback that is added on the next line to prevent
         * duplicate listeners from being added
         */
        model.off('change:AMOUNT-equality', amountEqualityChange);
        model.on('change:AMOUNT-equality', amountEqualityChange);
        model.off('change:SERIALNUMBER-equality', equalityChange);
        model.on('change:SERIALNUMBER-equality', equalityChange);
        // Namespacing the blur event here, to prevent accidental removal of other listeners
        $amount.off('blur.filter').on('blur.filter', amountFieldBlur.bind(this, model, 'AMOUNT2'));
        $amount2.off('blur.filter').on('blur.filter', amountFieldBlur.bind(this, model, 'AMOUNT'));
        if ($serialnumber.length && $serialnumber2.length) {
            $serialnumber.off('blur.filter').on('blur.filter', amountFieldBlur.bind(this, model, 'SERIALNUMBER2'));
            $serialnumber2.off('blur.filter').on('blur.filter', amountFieldBlur.bind(this, model, 'SERIALNUMBER'));
        }

        // Only add listeners when fields exist
        if (accountFilter && accountFilter2) {
            model.on('change:ACCOUNTFILTER', (changedModel, value = []) => {
                accountFilter2.shouldBeReadOnly(!!value.length);
            });
            model.on('change:ACCOUNTFILTER2', (changedModel, value = []) => {
                accountFilter.shouldBeReadOnly(!!value.length);
            });
        }
        // When in modify state, need to conditionally add validators
        if (form.formView.state === 'modify') {
            amountEqualityChange(model, model.get('AMOUNT-equality'));
            if ($serialnumber.length && $serialnumber2.length) {
                equalityChange(model, 'SERIALNUMBER', model.get('SERIALNUMBER-equality'));
            }

            // When these fields are present, make sure they are disabled when the other has a value
            if (accountFilter && accountFilter2) {
                accountFilter.shouldBeReadOnly(!!model.get('ACCOUNTFILTER2'));
                accountFilter2.shouldBeReadOnly(!!model.get('ACCOUNTFILTER'));
            }
        }

        if (form.formView.context?.functionCode === 'ALERT') {
            // Make sure that the fields are set properly on insert and modify
            toggleDebitCredit(requireDebitCredit(model.get('BAI_CODE')));
            /*
             * NH-171057 Must select debit or credit for certain transaction types.
             * Make the DR_CR field required und such circumstances.
             */
            model.on('change:BAI_CODE', (changedModel, value) => {
                toggleDebitCredit(requireDebitCredit(value));
            });
        }
    } else if ($elCHECKSTATUSCODE) {
        $selections = $('.select2-chosen');
        store.set('check_inq_ACCOUNTFILTER', $selections[0].innerHTML);
        store.set('check_inq_CHECKSTATUSCODE', $selections[1].innerHTML);
    }
}
