import Poll from '@glu/polling';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import ConfirmDataPollerStatus from 'common/dynamicPages/views/workflow/confirmDataPollerStatus';

const POLLING_INTERVAL_MILLISECONDS = 5000;
const POLLING_RETRY_LIMIT = 25;

export default {
    createPoller(confirmDataView, confirmDataResults) {
        const self = this;
        this.startPoll = false;
        this.confirmDataView = confirmDataView;
        this.confirmDataResults = confirmDataResults;
        this.tnums = [];

        // each item
        util.each(this.confirmDataResults, (entry) => {
            if (entry.result && entry.confirmPollingKeys !== null) {
                self.startPoll = true;
                util.each(entry.confirmPollingKeys, (confirmPollingKey) => {
                    util.each(confirmPollingKey.item, (nvp) => {
                        if (nvp.name === 'TNUM') {
                            self.tnums.push(nvp);
                        }
                    });
                });
            }
        });

        if (!this.startPoll) {
            return false;
        }

        this.confirmDataPollerStatus = new ConfirmDataPollerStatus({
            tnums: this.tnums,
        });
        const options = {
            iterator(poll) {
                // var self = this;
                this.confirmDataPollerStatus.fetch({
                    success(model) {
                        const removeTnums = [];

                        util.each(self.tnums, (id) => {
                            if (model.get(id.value) === 'BC') {
                                removeTnums.push(id);
                                confirmDataView.updateConfirmPollerMsg({
                                    tnum: id.value,
                                    msg: locale.get('payment.confirm.processed.msg'),
                                });
                            } else if (model.get(id.value) === 'RJ') {
                                removeTnums.push(id);
                                confirmDataView.updateConfirmPollerMsg({
                                    tnum: id.value,
                                    msg: locale.get('payment.confirm.rejected.msg'),
                                });
                            }
                        });

                        util.each(removeTnums, (id) => {
                            const idx = self.tnums.indexOf(id);
                            self.tnums.splice(idx, 1);
                        });

                        if (self.tnums.length > 0) {
                            poll();
                        }
                    },
                    error() {},
                });
            },
            limit: POLLING_RETRY_LIMIT,
            onLimit() {
                //  limit exceeded
            },
            onStop() {},
            interval: POLLING_INTERVAL_MILLISECONDS,
        };
        this.poller = new Poll(options, this);
        this.poller.start();
        return true;
    },
    dispose() {
        if (this.poller && this.startPoll) {
            this.poller.dispose();
        }
    },

    updateConfirmPollerMsg(res) {
        const pollId = `confirm.poll.${res.tnum}`;
        $(`div[data-pollId="${pollId}"]`).html(res.msg);
        if (res.msg.includes(locale.get('payment.confirm.rejected.msg')) === true) {
            if ($('div[role="alert"]')) {
                $('div[role="alert"]').removeClass('alert-positive').addClass('alert-negative');
            }
            if ($('span[class="icon-tick"]')) {
                $('span[class="icon-tick"]').removeClass('icon-tick').addClass('icon-warning');
            }
        }
    },

};
