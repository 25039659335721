export default {
    viewId: {
        ALL_FEATURES: '8800',
        PENDING_APPROVALS: '8801',
    },
    status: {
        PENDING_DEPLOYMENT: 'AP',
        MODIFIED: 'MD',
    },
};
