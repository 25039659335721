import Layout from '@glu/core/src/layout';
import http from '@glu/core/src/http';
import services from 'services';
import locale from '@glu/locale';
import connectTmpl from './connect.hbs';

export default Layout.extend({
    initialize() {
        this.connected = false;
    },

    template: connectTmpl,

    templateHelpers() {
        return {
            connectedState: this.connected,
        };
    },

    ui: {
        $btnRefreshConnection: '.btn-refresh-conn',
    },

    events: {
        'click @ui.$btnRefreshConnection': 'getStatus',
    },

    onShow() {
        /*
         * this searches the DOM for the ipp:connectToIntuit tag
         * but it's in the view's DOM fragment
         * wait until the view has been appended into the DOM to begin search
         *            intuit.ipp.anywhere.init();
         */

        this.getStatus();
    },

    getStatus() {
        const statusUrl = services.oAuthStatus;

        const self = this;
        http.post(
            statusUrl,
            (data) => {
                const image = document.getElementById('connImage');
                const text = document.getElementById('connText');
                self.connected = data.isConnected;
                if (data.isConnected) {
                    image.className = 'icon-tick';
                    text.textContent = locale.get('setup.quickbooks.connected');
                } else {
                    image.className = 'icon-cancel';
                    text.textContent = locale.get('setup.quickbooks.not.connected');
                }
            },
        );
    },
});
