/**
 * result grid for check inquiry
 */
import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import store from 'system/utilities/cache';
import Dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import PrintExportUtil from 'common/util/printExportUtil';
import DownloadModal from 'common/checkViewer/downloadModal';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import scroll from 'common/util/scroll';
import constants from 'app/payments/constants';
import workspaceHelper from 'common/workspaces/api/helper';
import ExportDetail from 'app/payments/views/exportDetail';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import { setGridActionData, getAlertMessage, removeAlertMessage } from 'common/util/reduxUtil';
import PrintChecks from './printChecks';
import template from './checkInquiryList.hbs';

const CheckInquiryList = ListView.extend(util.extend({}, PrintExportUtil, {
    template,

    ui: {
        $placeStops: '[data-hook="getPlaceStops"]',
        $viewChecks: '[data-hook="getViewChecks"]',
    },

    events: {
        'click @ui.$viewChecks': 'viewChecks',
        'click [data-hook="export-button"]': 'exportChecks',
        'click  [data-hook="print-button"]': 'print',
        'click @ui.$placeStops': 'placeStops',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        serverConfigParams.fetch({
            success: (result) => {
                if (result.get('PrintCheckLimit') && !Number.isNaN(Number(result.get('PrintCheckLimit')))) {
                    this.printCheckLimit = +result.get('PrintCheckLimit');
                }
            },
        });

        const superOptions = {
            serviceName: options.serviceName,
            serviceFunc: null,
            businessType: null,
            returnRoute: store.get('current-workspace-route') || 'PAYMENTS/checkInquiry',
            enableSavedViews: false,
            context: this.options.isHistory ? 'CHECK_INQ_HIST' : 'CHECK_INQ_NEW',
            lvcAlwaysReset: true,
        };
        this.smbUser = userInfo.isSmbUser();
        this.searchFields = options.additionalSearchFields;
        this.filterId = options.filterId;
        this.filterModel = options.filterModel;
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.contextDef.serviceName = this.options.serviceName;
        this.contextDef.isHistory = this.options.isHistory;
        this.listenTo(this.appBus, 'stackView:pop', this.refreshInquiryList);
        this.show3rdPartyMsg = appConfigParams.getValue('REALTIME', 'SHOW3RDPARTYMSG') === '1';
    },

    onRender() {
        if (this.gridView) {
            this.listenToOnce(this.gridView, 'gridapi:loaded', () => {
                this.clearGridRequestParams();
                if (!this.options.isHistory) {
                    this.clearGridAddlSearchFields();
                }
                this.allowPlaceStops();
                this.trigger('checkInquiryGrid:loaded');
            });
            this.renderAlertMessage();
            this.startGridRespAlertListeners(this.gridView.cid);

            this.listenTo(this.appBus, 'grid:selectRow grid:selectAllRows', this.onGridRowSelect.bind(this));
        }
        ListView.prototype.onRender.call(this);
    },

    /**
     * @method allowPlaceStops
     * @description determines if the place stop button should be displayed based on
     * if the 'STOP' button is available for at least one item in the grid
     */
    allowPlaceStops() {
        const allow = util.chain(this.gridView.wrapper.rows.models)
            .pluck('buttons')
            .compact()
            .flatten()
            .any(b => b.action === 'STOP')
            .value();

        this.ui.$placeStops.toggleClass('hide', !allow);
    },

    /**
     * @method getAvailableForPlaceStops
     * @description returns the list of records available to stop
     * @param {array: objects} recs
     * @returns {array: objects} recs available to stop
     */
    getAvailableForPlaceStops(recs) {
        return recs.filter(rec => util.any(rec.buttons, b => b.action === 'STOP'));
    },

    /**
     * @method getAvailableForView
     * @description returns the list of records available with any action
     * @param {array: objects} recs
     * @returns {array: objects} recs available to view
     */
    getAvailableForView(recs) {
        return recs.filter(rec => util.any(rec.buttons, b => b.action === 'IMAGE'));
    },

    /**
     * @method showReqPhotoCopySuccess
     * - reads confirmation message from store cache,
     * - display in the alertRegion, and remove the message from cache
     */
    showReqPhotoCopySuccess() {
        const message = store.get('reqPhotocopyConfirm');
        if (message && message.length > 0) {
            this.alertView = alert.success(
                message.join('\n'),
                {
                    canDismiss: true,
                },
            );
            store.unset('reqPhotocopyConfirm');
            this.alertRegion.show(this.alertView);
        }
    },

    getUpdateData(optionsParam) {
        const options = optionsParam;
        options.model.submissionData = this.convertModelToNameValuePairs(options.model);
        this.gridRowExtend.call(this, options);
    },

    gridRowCustomAction(optionsParam) {
        const options = optionsParam;
        const contextModel = {
            tnum: options.model.get('TNUM'),
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: options.model.get('TYPE'),
            updateCount: options.model.get('UPDATECOUNT__'),
        };
        store.set('cm_stopCancel-contextOverride', contextModel);
        store.set('cm_inq_searchFields', this.searchFields);
        store.set('cm_inq_filterId', this.filterId);
        store.set('cm_inq_filterModel', !util.isNull(this.filterModel)
            ? this.filterModel.toJSON() : this.filterModel);
        store.set('advanceFilterCache', this.searchFields);
        store.set('check_inq_gridContext', this.gridView);

        if (options.action.toUpperCase() === 'STOP') {
            this.placeStop(options.model);
        } else if (options.action.toUpperCase() === 'CANCEL') {
            // go to the stop management workspace after save
            store.set('cminst_cancelStop-actionModel', options.model);
            this.navigateTo('PAYMENTS/insertStopCancels');
        } else if (options.action.toUpperCase() === 'EXTEND') {
            // To use the same API as in stop Payments, we need to hardcode few values here
            options.model.context.serviceName = constants.PLACE_STOP_API;
            this.getUpdateData.call(this, options);
        } else if (options.action.toUpperCase() === 'IMAGE') {
            store.set('cminst_cancelStop-actionModel', options.model);
            this.navigateTo('PAYMENTS/viewImage');
        } else if (options.action.toUpperCase() === 'PHOTO') {
            this.listenTo(options.model, 'reqPhotoCopySuccess', this.showReqPhotoCopySuccess);
            store.set('checkInquiry:paidCheck', options.model);
            this.navigateTo('PAYMENTS/requestPhotoCopy');
        }
        return Promise.resolve();
    },
    /**
     * @name collectData
     * @description To extend the stopped Payment,
     * send the data similar to Modify Place Stop API
     * @returns {Object {"item": [{"name": "","value": ""}...]}}
     */
    convertModelToNameValuePairs(model) {
        const filterArray = Object.entries(model.attributes || {})
            .map(([property, value]) => ({
                name: property,
                value,
            }));
        return {
            items: [{
                item: filterArray,
            }],
        };
    },

    /**
     * @method onGridRowSelected
     * Callback for grid row select
     */
    onGridRowSelect() {
        // cache selection for use when returning to page
        this.selectedRows = this.gridView.grid.getSelectedRowModels();
        // toggle view checks button
        this.toggleViewChecks();
    },

    /**
     * @method toggleViewChecks
     * (Called on Grid Row Select callback) toggle viewChecks button if rows are selected
     */
    toggleViewChecks() {
        this.ui.$viewChecks.prop('disabled', this.gridView.grid.getSelectedRowModels().length < 1);
    },

    /**
     * @method viewChecks
     * Callback to view checks selected in checkviewer
     */
    viewChecks() {
        const recs = this.gridView.grid.getSelectedRowModels();
        const viewRecs = this.getAvailableForView(recs);

        if (viewRecs.length !== recs.length) {
            Dialog.confirm(locale.get('PAY.multi.view.warning'), (status) => {
                /*
                 * if 'yes' was selected and there are records,
                 * then view checks; otherwise, do nothing
                 */
                if (status && viewRecs.length) {
                    store.set('check_inq_gridContext', this.gridView);
                    store.set('cminst_cancelStop-actionModels', viewRecs);
                    this.navigateTo('PAYMENTS/viewImage');
                }
            });
        } else {
            store.set('check_inq_gridContext', this.gridView);
            store.set('cminst_cancelStop-actionModels', viewRecs);
            this.navigateTo('PAYMENTS/viewImage');
        }
    },

    /**
     * Helper method to show any confirmation message for any user action that was made.
     * A fix for NH-150594 portal issue that can’t be reproduced. We need to
     * make sure the alert message is being rendered as
     * the stackView:pop event does not always fire.
     */
    renderAlertMessage() {
        // get the correct context key for payment action that was just done.
        const affectedPaymentContext = store.get('check_inq_AFFECTEDCONTEXT');
        const alertMsgContext = getAlertMessage(constants.STOPPAYMENTS_CONTEXTKEY);
        if (alertMsgContext && Object.keys(alertMsgContext).length) {
            const { alertMessage, confirms } = alertMsgContext;
            this.renderMessage(alertMessage, confirms);
            removeAlertMessage(constants.STOPPAYMENTS_CONTEXTKEY);
            return;
        }

        // Clear alerts if correct context key exists
        if (!util.isNullOrUndefined(affectedPaymentContext)) {
            this.alertRegion.close();
        }

        /*
         * Show confirmation message for payment grid action done before returning to
         * check inquiry list
         */
        if (affectedPaymentContext) {
            this.showGridConfirmationMessage(affectedPaymentContext);
        }
    },

    /**
     *  @method: refreshInquiryList
     *  @description Upon returning to this page thru the stackView from a detail
     * page brought to by a payment list grid action,
     *  This view should show any confirmation message for any user action that was made.
     *  The view will also reset any needed store property that handles the routing
     * back to the check inquiry page
     */
    refreshInquiryList() {
        // get the correct context key for payment action that was just done.
        const affectedPaymentContext = store.get('check_inq_AFFECTEDCONTEXT');

        // Clear alerts if correct context key exists
        if (!util.isNullOrUndefined(affectedPaymentContext)) {
            this.alertRegion.close();
        }

        /*
         * Show confirmation message for payment grid action done before returning to
         * check inquiry list
         */
        if (affectedPaymentContext) {
            this.showGridConfirmationMessage(affectedPaymentContext);
        } else {
            this.renderAlertMessage();
        }

        if (!mobileUtil.isMobileScreen()) {
            if (store.get('check_inq_returnReason') !== 'cancel') {
                const { requestParameters } = this.options;
                requestParameters.item.push({
                    name: 'reload',
                    value: 'true',
                });
                const reloadObj = {
                    requestParameters,
                    searchFields: this.searchFields,
                };

                this.gridView.refreshGridData(null, reloadObj);
            }
            store.unset('check_inq_returnReason');

            this.listenToOnce(this.gridView.grid, 'grid:contentRendered', () => {
            // persist user's selections if available after grid refresh
                if (this.selectedRows) {
                    this.gridView.selectRows(this.selectedRows);
                }
                this.toggleViewChecks();
            });
        }

        // reset the return workspace route, since it gets removed by routing after each action
        store.set('current-workspace-route', (store.get('current-workspace-route')
            || 'PAYMENTS/checkInquiry'));
    },

    /**
     * @method showGridConfirmationMessage
     * @param {string} contextKey - key used associate to confirmation message
     * content in store model
     * Shows a confirmation message for user's recent grid actions
     * (message has a context key that it is related to)
     */
    showGridConfirmationMessage(contextKey) {
        const confirmResponse = store.get(`${contextKey}-confirms`);
        // call inherited render message

        if (this.show3rdPartyMsg && this.checkInquiryAlertRegion) {
            this.displayAlertWith3rdPartyMessage(confirmResponse);
        } else {
            this.renderMessage(store.get(`${contextKey}-alertMessage`), confirmResponse);
        }
        // clear used key
        store.unset(`${contextKey}-alertMessage`);
        store.unset(`${contextKey}-confirms`);
        store.unset('check_inq_AFFECTEDCONTEXT'); // clear out context

        if (this.alertView) {
            scroll.scrollToRegion(this.alertView.$el[0]);
        }
    },

    /**
     * @method displayAlertWith3rdPartyMessage
     * @description will display check inquiry alert along with 3rd party message
     * @param {object} confirmResponse
     */
    displayAlertWith3rdPartyMessage(confirmResponse) {
        const confirms = new Confirms({
            confirms: confirmResponse ? confirmResponse.confirms : null,
        });

        if (!(confirmResponse?.message)) {
            return;
        }
        this.alertView = alert.success(
            confirmResponse.message,
            {
                details: confirmResponse
                && confirmResponse?.confirms?.confirmResults?.[0].confirmData[0].item
                    ? confirms : null,
                canDismiss: true,
            },
        );
        this.checkInquiryAlertRegion.show(this.alertView);
    },

    /**
     * @method placeStop
     * @description creates a place stop from the context menu
     * @param {object} model
     */
    placeStop(model) {
        this.createPlaceStops([model]);
    },

    /**
     * @method placeStops
     * @description retrieves the selected records.  if they are within the limit
     * the go create stops
     */
    placeStops() {
        const recs = this.gridView.grid.getSelectedRowModels();
        const maxRows = userInfo.get('multiEntryForMaxRowCount');
        let availRecs;
        // check to see if we are within the limit for the number of stops
        if (recs.length > maxRows) {
            Dialog.alert(locale.get('cm.max.stops', maxRows));
        } else if (recs.length) {
            availRecs = this.getAvailableForPlaceStops(recs);
            if (availRecs.length !== recs.length) {
                // issue message
                Dialog.confirm(locale.get('PAY.multi.placestop.warning'), (status) => {
                    /*
                     * if 'yes' was selected and there are records,
                     * then create stops; otherwise, do nothing
                     */
                    if (status && availRecs.length) {
                        this.createPlaceStops(availRecs);
                    }
                });
            } else {
                this.createPlaceStops(recs);
            }
        }
    },

    /**
     * @method createPlaceStops
     * @description gets the selected records from the check inquiry listview;
     * sets up for stop payments multi view;
     * (navigate) goes to multi-stop payment view
     * @param {array} recs - array of checkInquiry models
     */
    createPlaceStops(recs) {
        // go back to check inquiry
        const workSpaceRoute = store.get('current-workspace-route') || 'PAYMENTS/checkInquiry';

        // set contextKey for multi-stop entry
        const overrideContext = {
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: '*',
            actionMode: 'SELECT',
            serviceName: '/cminst/placeStop',

            actionContext: {
                subType: '*',
                productCode: 'CM',
                functionCode: 'INST',
                typeCode: '*',
                actionMode: 'SELECT',
                entryMethod: 0,
            },
        };
        const stopsContextKey = constants.STOPPAYMENTS_CONTEXTKEY;

        // store context info for action.  action will unset
        store.set('listView-contextKey', stopsContextKey);
        store.set('multiAdd-contextKey', `${stopsContextKey}-contextOverride`);
        store.set(`${stopsContextKey}-contextOverride`, overrideContext);

        store.set('current-workspace-route', workSpaceRoute);
        store.set('cminst_cancelStop-actionModel', recs);
        const prefillModels = recs.map(r => r.toJSON());

        // re-store values for when we come back to the check inquiry listview
        store.set('cm_inq_searchFields', this.searchFields);
        store.set('cm_inq_filterId', this.filterId);
        store.set('cm_inq_filterModel', !util.isNull(this.filterModel)
            ? this.filterModel.toJSON() : this.filterModel);
        store.set('advanceFilterCache', this.searchFields);
        store.set('check_inq_gridContext', this.gridView);


        setGridActionData({
            ...overrideContext,
            typeCode: 'STOP',
            mode: 'insert',
            prefillModels,
            contextKey: stopsContextKey,
        });

        this.navigateTo('CM/multiPreFillAdd/STOP');
    },
    getFunctionCode() {
        const type = store.get('checkInquiryTypes');
        return type.models[0].get('functionCodeData');
    },
    getTypeCode() {
        const type = store.get('checkInquiryTypes');
        return type.models[0].get('entitleTypeCodeData');
    },

    exportDataOnly(selectedRows) {
        this.gridView.context.productCode = this.gridView.context.actionContext.productCode;
        this.gridView.context.functionCode = this.options.isHistory ? this.getFunctionCode() : store.get('functionCode');
        this.gridView.context.typeCode = this.options.isHistory ? this.getTypeCode() : store.get('typeCode');
        this.gridView.context.actionMode = this.gridView.context.actionContext.actionMode;
        this.gridView.wrapper.additionalSearchFields = null;
        const options = {
            view: this,
            gridView: this.gridView,
            format: 'CSV',
            columns: null,
            inquiryId: this.contextDef.isHistory
                ? constants.CHECK_INQUIRY.INQUIRYID.HISTORY
                : constants.CHECK_INQUIRY.INQUIRYID.REALTIME,
            searchFields: [],
        };
        /**
         * If there are rows selected then send tnum and accountfilter
         */
        if (selectedRows) {
            const tnums = util.map(selectedRows, model => model.get('TNUM'));
            const accountFilter = this.searchFields
                .find(field => field.fieldName === 'ACCOUNTFILTER');
            options.searchFields.push(accountFilter);

            options.searchFields.push({
                fieldName: 'TNUM',
                operator: 'IN',
                fieldValue: tnums,
                dataType: 'number',
            });
        }
        this.export(options);
    },

    /**
     * @method export
     * @description overrides the PrintExportUtils method
     * @param {object} options
     */
    export(options) {
        const optionsParam = options;
        optionsParam.gridView = options.gridView || this.gridView;

        optionsParam.format = optionsParam.format || 'CSV';
        optionsParam.exportModel = this.buildExportModel(optionsParam);
        this.doExport(optionsParam);
    },

    /**
     * @method doExport
     * @description overrides the PrintExportUtils method
     * Removes checkstatuscode searchfield
     * @param {object} options
     */
    doExport(options) {
        const opts = { ...options };
        if (this.contextDef.isHistory) {
            opts.exportModel.searchFields = opts.exportModel.searchFields.filter(searchField => searchField.fieldName !== 'CHECKSTATUSCODE');
        }
        PrintExportUtil.doExport(opts);
    },

    exportChecks() {
        const searchFields = [];
        let selectedRows;
        const success = (resp) => {
            if (resp.errorCode === 0) {
                this.showAlert(resp);
            } else {
                this.showError(resp);
            }
            Dialog.close();
        };
        const error = (resp) => {
            this.showError(resp);
            Dialog.close();
        };
        this.onGridRowSelect();
        /**
         * Check If there is any selected Rows in the grid
         */
        if (this.selectedRows?.length) {
            selectedRows = this.gridView.grid.getSelectedRowModels().length
                ? this.gridView.grid.getSelectedRowModels()
                : this.gridView.grid.masterCollection.models;

            // if there are no records then we have nothing to export
            if (util.isEmpty(selectedRows)) {
                return;
            }
            // Accountfilter is required for the original search.
            const accountFilter = this.searchFields
                .find(field => field.fieldName === 'ACCOUNTFILTER');
            searchFields.push(accountFilter);

            const sequenceNumber = util.map(selectedRows, model => model.get('PIC_SEQUENCE_NUMBER'));
            searchFields.push({
                operator: 'IN',
                dataType: 'text',
                fieldName: 'PIC_SEQUENCE_NUMBER',
                fieldValue: sequenceNumber,
            });
            searchFields.push({
                operator: 'EQ',
                dataType: 'text',
                fieldName: 'ACCOUNT_NUMBER',
                fieldValue: [selectedRows[0].get('ACCOUNT_NUMBER')],
            });
        }

        if (!this.options.hasBulkCheckDownload) {
            this.exportDataOnly((selectedRows));
            return;
        }

        this.downloadView = new DownloadModal({
            success,
            error,
            checks: selectedRows,
            totalItems: selectedRows?.length || this.gridView.grid.masterCollection?.totalCount,
            searchFields,
            isHistory: this.options.isHistory,
        });
        this.listenToOnce(this.downloadView, 'exportDataOnly', () => {
            this.exportDataOnly(selectedRows);
        });

        Dialog.open(this.downloadView);
    },

    // NH-129256 donot show the dialog for the STOPPED and PASSTOP
    isStopOrPadStop() {
        let checkStatusCode;
        const checkStatusCodeObj = util.findWhere(this.options.additionalSearchFields, {
            fieldName: 'CHECKSTATUSCODE',
        });

        if (!util.isNullOrUndefined(checkStatusCodeObj)) {
            ({ fieldValue: [checkStatusCode] } = checkStatusCodeObj);

            return (checkStatusCode === 'STOPPED' || checkStatusCode === 'PADSTOP');
        }

        return false;
    },

    showAlert(resp) {
        const options = {
            exportId: resp.exportId,
        };
        this.alertView = alert.success(
            resp.messages,
            {
                details: new ExportDetail(options),
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    showError(resp) {
        this.alertView = alert.danger(
            resp.messages,
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    /**
     * @method getPrintDisplayOrder
     * @param {Collection} columnCollection - grid columns
     * @return {object} - needed column display info reference object
     */
    getPrintDisplayOrder(columnCollection) {
        // create reference display data out of available check info fields
        const columns = columnCollection.models;
        const defaultColumns = [{
            fieldName: 'ACCOUNT_TITLE',
            label: locale.get('allReports.Account_Name'),
        }, {
            fieldName: 'ACCOUNT_NUMBER',
            label: locale.get('allReports.Account_Number'),
        }];
        const nonDataColumns = ['SELECTOR', 'ACTIONLIST'];

        return defaultColumns.concat(columns
            .filter(col => (col.get('condition') !== false)
                && (nonDataColumns.indexOf(col.get('field')) === -1))
            .map(col => ({
                fieldName: col.get('fieldName'),
                label: col.get('label'),
            })));
    },
    getTypeCodeChange() {
        return this.options.isHistory ? this.getTypeCode() : store.get('typeCode');
    },
    getFunctionCodeChange() {
        return this.options.isHistory ? this.getFunctionCode() : store.get('functionCode');
    },

    print() {
        let checks;

        if (this.isStopOrPadStop()) {
            return this.showPrintOptionsModal();
        }

        checks = this.gridView.grid.getSelectedRowModels();

        if (!checks.length) {
            checks = this.gridView.grid.masterCollection.models;
        }

        if (this.printCheckLimit && !Number.isNaN(Number(this.printCheckLimit))
            && checks.length > this.printCheckLimit) {
            const message = locale.get('cm.maximum.number.checks', this.printCheckLimit);
            this.alertView = alert.danger(
                message,
                {
                    canDismiss: true,
                },
            );
            return this.alertRegion.show(this.alertView);
        }

        return PrintChecks.print({
            checks,
            displayColumns: this.getPrintDisplayOrder(this.gridView.wrapper.columns),
            onError: msg => this.showGridErrorMessages({
                messages: Array.isArray(msg) ? msg : [msg],
            }),
        });
    },

    getPrintOptions() {
        return {
            inquiryId: 20503,
            hasSummarySelection: false,
            summaryInquiryId: 20503,
        };
    },

    templateHelpers() {
        return util.extend(
            ListView.prototype.templateHelpers.call(this),
            {
                hasViewChecksEntitlement: () => {
                    const searchFields = util.filter(this.searchFields, searchField => searchField.fieldName === 'CHECKSTATUSCODE');
                    return !((util.isUndefined(searchFields[0]))
                        || (searchFields[0].fieldValue
                            && searchFields[0].fieldValue[0].indexOf('PAD') > -1));
                },

                showViewButton: () => {
                    const searchFields = util.filter(this.searchFields, searchField => searchField.fieldName === 'CHECKSTATUSCODE');
                    return !((util.isUndefined(searchFields[0]))
                        || (searchFields[0].fieldValue
                            && searchFields[0].fieldValue[0] === 'STOPPED'));
                },
            },
        );
    },

    /**
     * NH-94852 When the checkInquiry service is not available, we need to handle
     * the response
     * messaging. The DGB service response code is 200, but the external service
     * failed, so
     * the response object contains a property called result, which will be false
     * in this scenario.
     * Handle the response message accordingly.
     * @param {object} response - grid request respHeader
     */
    showGridSuccessMessages(response) {
        if (response) {
            if (response.result) {
                ListView.prototype.showGridSuccessMessages.call(this, response);
            } else if (response.message) {
                this.showAlertMessage(response.message.join('\n'), 'danger');
            }
        }
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        if (!mobileUtil.isMobileGridEnabled()) {
            this.setHasLoadedRequiredData(true);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            this.listenForGridErrors();
            this.render();
        } else {
            /** this notifies the parent after the grid has loaded */
            this.clearGridRequestParams();
            this.clearGridAddlSearchFields();
            this.allowPlaceStops();
            this.trigger('checkInquiryGrid:loaded');
        }
        return result.actions;
    },
}));

let list = CheckInquiryList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'CHECK_INQUIRY_LIST',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
