var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <img class=\"soft-token-scannable-image\" src='data:image/png;base64,"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"scannableImageSrc") || (depth0 != null ? lookupProperty(depth0,"scannableImageSrc") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"scannableImageSrc","hash":{},"data":data,"loc":{"start":{"line":17,"column":87},"end":{"line":17,"column":108}}}) : helper)))
    + "' alt='mobile device scannable image'>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form autocomplete=\"off\" data-submit=\"save\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n        <div class=\"row\">\n            <div class=\"col-6 row\">\n                <h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"stepLabel") || (depth0 != null ? lookupProperty(depth0,"stepLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stepLabel","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":33}}}) : helper)))
    + "</h4>\n                <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"stepInstructions") || (depth0 != null ? lookupProperty(depth0,"stepInstructions") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stepInstructions","hash":{},"data":data,"loc":{"start":{"line":8,"column":19},"end":{"line":8,"column":39}}}) : helper)))
    + "</p>\n                <div class=\"form-group required col-8\">\n                    <label for=\"activationCode\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"inputLabel") || (depth0 != null ? lookupProperty(depth0,"inputLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputLabel","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":62}}}) : helper)))
    + "</label>\n                    <input class=\"form-control\" data-bind=\"model\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputMaxLength") || (depth0 != null ? lookupProperty(depth0,"inputMaxLength") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputMaxLength","hash":{},"data":data,"loc":{"start":{"line":11,"column":77},"end":{"line":11,"column":95}}}) : helper)))
    + "\" id=\"activationCode\" name=\"activationCode\" type=\"text\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"activationCode\"></span>\n                </div>\n            </div>\n            <div class=\"soft-token-scannable-image-wrapper col-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"scannableImageSrc") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":18,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});