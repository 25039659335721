const Constants = {
    ROUTE_BALANCE_AND_TRANSACTION: 'REPORTING/balanceAndTransactionReporting',
    DEPOSIT_ACCTS_GRID_CONTEXT: 'RPT_BAL_TRAN',
    DEPOSIT_PD_ACCTS_GRID_CONTEXT: 'RPT_BAL_TRAN_PD',
    WIRE_REPORT_GRID_CONTEXT: 'RPT_WIR_TRAN',
    CHECK_PAID_IMAGE_SERVICE: 'checkPaid/getImage',
    CD_IMAGE_SERVICE: 'controlDisb/getImage',
    CHECK_PAID_IMAGE_SERVICE_SEARCH: 'imageSearchCheckPaid/getImage',
    // Credit Cards
    CREDIT_CARD_ACCTS_SERVICE_PREFIX: 'balanceAndTransaction/creditCardAccounts/',
    CREDIT_CARD_BALANCE_TRANSACTION_TOTALS: 'balanceAndTransaction/creditCardAccounts/accountSummary/getTransactionTotals',
    CREDIT_CARD_CURRENT_DAY_TRANSACTION_TOTALS: 'balanceAndTransaction/creditCardAccounts/currentDay/getTransactionTotals',
    CREDIT_CARD_PREV_DAY_TRANSACTION_TOTALS: 'balanceAndTransaction/creditCardAccounts/previousDay/getTransactionTotals',
    CREDIT_CARD_PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL: '/creditCardAccounts/previousDay/transactions/export/listView',
    ASYNC_CREDIT_CARD_PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL: '/balanceAndTransaction/creditCardAccounts/previousDay/transactions/export/executeListView',
    CREDIT_CARD_ALL_ACCTS_SUMMARY: '/balanceAndTransaction/creditCardAccounts/ALLACCOUNTS/accountSummary/getListView',
    // Deposits
    DEPOSIT_ACCTS_SERVICE_PREFIX: 'balanceAndTransaction/depositAccounts/',
    DEPOSIT_ACCTS_SERVICE_PREFIX_PD: 'balanceAndTransaction/depositAccounts/previousDay/',
    DEPOSIT_ACCTS_SUMMARY: '/balanceAndTransaction/depositAccounts/ALLACCOUNTS/accountSummary/getListView',
    BALANCE_TRANSACTION_TOTALS: 'balanceAndTransaction/depositAccounts/accountSummary/getTransactionTotals',
    DEPOSIT_CURRENT_DAY_TRANSACTION_TOTALS: 'balanceAndTransaction/depositAccounts/currentDay/getTransactionTotals',
    CUSTOM_ACCTS_SUMMARY_TRANSACTION_TOTALS: 'balanceAndTransaction/depositAccounts/accountSummary/getCustomReportingTransactionTotals',
    DEPOSIT_PREV_DAY_TRANSACTION_TOTALS: 'balanceAndTransaction/depositAccounts/previousDay/getTransactionTotals',
    CUSTOM_PREV_DAY_TRANSACTION_TOTALS: 'balanceAndTransaction/depositAccounts/previousDay/getCustomReportingTransactionTotals',
    // Loans
    LOANS_BALANCE_TRANSACTION_TOTALS: 'loanreporting/accountSummary/getTransactionTotals',
    LOAN_CURRENT_DAY_TRANSACTION_TOTALS: 'loanreporting/currentDay/getTransactionTotals',
    LOAN_PREV_DAY_TRANSACTION_TOTALS: 'loanreporting/previousDay/getTransactionTotals',
    LOAN_ACCTS_SUMMARY: '/loanreporting/accountSummary/getListView',
    LOAN_ACCTGRP_EXPORT_PRINT_URL: '/loanAccounts/accountGroup/export/listView',
    ASYNC_LOAN_ACCTGRP_EXPORT_PRINT_URL: '/loanreporting/accountGroup/export/executeListView',
    LOAN_PREV_DAY_ACCTGRP_EXPORT_PRINT_URL: '/loanAccounts/previousDay/accountGroup/export/listView',
    ASYNC_LOAN_PREV_DAY_ACCTGRP_EXPORT_PRINT_URL: '/loanreporting/previousDay/accountGroup/export/executeListView',

    DEPOSIT_ACCTGRP_EXPORT_PRINT_URL: '/depositAccounts/accountGroup/export/listView',
    ASYNC_DEPOSIT_ACCTGRP_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/accountGroup/export/executeListView',
    CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL: '/creditCardAccounts/accountGroup/export/listView',
    ASYNC_CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL: '/balanceAndTransaction/creditCardAccounts/accountGroup/export/executeListView',
    BALANCE_EXPORT_PRINT_URL: '/balanceAndTransaction/summary/export/listView',
    ASYNC_BALANCE_EXPORT_PRINT_URL: '/balanceAndTransaction/summary/export/executeListView',
    TRANS_EXPORT_PRINT_URL: '/depositAccounts/transactions/export/listView',
    ASYNC_TRANS_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/transactions/export/executeListView',
    CREDIT_CARD_TRANS_EXPORT_PRINT_URL: '/creditCardAccounts/transactions/export/listView',
    ASYNC_CREDIT_CARD_TRANS_EXPORT_PRINT_URL: '/balanceAndTransaction/creditCardAccounts/transactions/export/executeListView',
    LOAN_TRANS_EXPORT_PRINT_URL: '/loanAccounts/transactions/export/listView',
    ASYNC_LOAN_TRANS_EXPORT_PRINT_URL: '/loanreporting/transactions/export/executeListView',
    PREVIOUS_DAY_ACCTGRP_EXPORT_PRINT_URL: '/depositAccounts/previousDay/accountGroup/export/listView',
    ASYNC_PREVIOUS_DAY_ACCTGRP_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/previousDay/accountGroup/export/executeListView',
    ASYNC_PREV_TOA_ACCTGRP_EXPORT_PRINT_URL: '/balanceAndTransaction/timeOpenAccounts/previousDay/accountGroup/export/executeListView',
    PREVIOUS_DAY_CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL: '/creditCardAccounts/previousDay/accountGroup/export/listView',
    ASYNC_PREVIOUS_DAY_CREDIT_CARD_ACCTGRP_EXPORT_PRINT_URL: '/balanceAndTransaction/creditCardAccounts/previousDay/accountGroup/export/executeListView',
    PREVIOUS_DAY_EXPORT_PRINT_URL: '/depositAccounts/previousDay/transactions/export/listView',
    ASYNC_PREVIOUS_DAY_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/previousDay/transactions/export/executeListView',
    PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL: '/depositAccounts/previousDay/transactions/export/listView',
    ASYNC_PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/previousDay/transactions/export/executeListView',
    PREVIOUS_DAY_PREFIX: 'previousDay/',
    CURRENT_DAY_PREFIX: 'currentDay/',
    CURRENT_DAY_EXPORT_PRINT_URL: '/depositAccounts/currentDay/transactions/export/listView',
    ASYNC_CURRENT_DAY_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/currentDay/transactions/export/executeListView',
    CURRENT_DAY_TRANS_EXPORT_PRINT_URL: '/depositAccounts/currentDay/transactions/export/listView',
    ASYNC_CURRENT_DAY_TRANS_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/currentDay/transactions/export/executeListView',
    CREDIT_CARD_CURRENT_DAY_TRANS_EXPORT_PRINT_URL: '/creditCardAccounts/currentDay/transactions/export/listView',
    ASYNC_CREDIT_CARD_CURRENT_DAY_TRANS_EXPORT_PRINT_URL: '/balanceAndTransaction/creditCardAccounts/currentDay/transactions/export/executeListView',
    CASH_POSITION_EXPORT_PRINT_URL: '/cashPosition/summary/export/listView',
    ASYNC_CASH_POSITION_EXPORT_PRINT_URL: '/balanceAndTransaction/cashPosition/summary/export/executeListView',
    CASH_POSITION_TRANS_EXPORT_PRINT_URL: '/depositAccounts/cashPosition/transactions/export/listView',
    ASYNC_CASH_POSITION_TRANS_EXPORT_PRINT_URL: '/balanceAndTransaction/depositAccounts/cashPosition/transactions/export/executeListView',
    CASH_POSITION_DETAIL_PAGE_URL: 'BANK_ACC_INFO/depositCashPositionAccountsTransactions',
    CASH_POSITION_TAB: 'CASHPOSITION/',
    CASH_POSITION_GIRTRN_I_TAB: 'CASHPOS_GIRTRN_I/',
    CHECKPAID_IMAGETYPE: 'CHECK',
    DEPOSIT_ACCTS_IMAGETYPE: 'DEPTKT',
    RETURNED_ITEM_IMAGETYPE: 'RDEPITEM',
    GIRTRANS: 'GIRTRANS',

    // old previous day permission
    GIRTRN_P: 'GIRTRN_P',

    // old current day permission
    GIRTRN_I: 'GIRTRN_I',

    // new previous day permission
    GIRTRA_P: 'GIRTRA_P',

    // new current day permission
    GIRTRA_I: 'GIRTRA_I',

    GIRTRNSR: 'GIRTRNSR',
    LONTRNSR: 'LONTRNSR',
    GIRTRSR2: 'GIRTRSR2',
    LNTRNSR2: 'LNTRNSR2',
    LOANACCT: 'LOANACCT',
    LOANACCTSPD: 'LOANACCTSPD', // Prev day loan accounts
    CREDITCARDSPD: 'CREDITCARDSPD', // Prev day CC accounts
    LOANTRAN: 'LOANTRAN',
    ACCT_SUMM_DEPOSIT_ACCTS_AMOUNTCOL: 'OPENINGLEDGER',
    ACCT_SUMM_LOAN_ACCTS_AMOUNTCOL: 'PRINCIPALBALANCE',
    ACCT_SUMM_DEPOSIT_ACCTS_AVAILABLECOL: 'CURRENTDAYAVAILABLE',
    ACCT_SUMM_LOAN_ACCTS_AVAILABLECOL: 'AVAILABLECOMMITMENTAMOUNT',
    PREV_DAY_ACCT_SUMM_DEPOSIT_ACCTS_AMOUNTCOL: 'OPENINGLEDGER_AMT',
    PREV_DAY_ACCT_SUMM_DEPOSIT_ACCTS_AVAILABLECOL: 'CLOSINGLEDGER_AMT',
    BTR_REPORT_PREFIX: 'balanceAndTransaction/combinedAccounts/',
    RETURNED_ITEM_IMAGE_SERVICE: 'returnedItem/getImage',
    RETURNED_ITEM_IMAGE_SERVICE_SEARCH: 'imageSearchReturnedItem/getImage',
    LOAN_ACCTS_SERVICE_PREFIX: 'loanreporting/',
    TRANSACTION_ADVICEDETAILS_SERVICE: 'balanceAndTransaction/depositAccounts/getAdviceDetails',
    CLOSE_TOA_SERVICE: 'balanceAndTransaction/toa/closeAccount',
    DEPOSIT_ACCTS_IMAGE_SERVICE: 'depositSlip/getImage',
    DEPOSIT_ACCTS_IMAGE_SERVICE_SEARCH: 'imageSearchDepositSlip/getImage',
    WIRE_REPORT_TRANSACTIONS_SERVICE: 'balanceAndTransaction/wireReportSummary/getTransactions',
    TRANSACTION_DEPOSIT_DETAIL: 'BANK_ACC_INFO/depositAccountsTransactions',
    TRANSACTION_CREDIT_CARD_DETAIL: 'BANK_ACC_INFO/creditCardAccountsTransactions',
    TRANSACTION_PREVDAY_DEPOSIT_DETAIL: 'BANK_ACC_INFO/depositPreviousDayAccountsTransactions',
    TRANSACTION_PREVDAY_CREDIT_CARD_DETAIL: 'BANK_ACC_INFO/creditCardPreviousDayAccountsTransactions',
    TRANSACTION_CURRDAY_DEPOSIT_DETAIL: 'BANK_ACC_INFO/depositCurrentDayAccountsTransactions',
    TRANSACTION_CURRDAY_CREDIT_CARD_DETAIL: 'BANK_ACC_INFO/creditCardCurrentDayAccountsTransactions',
    TRANSACTION_DEPOSIT_DETAIL_DEEPLINK: 'BANK_ACC_INFO/depositAccountsTransactionsDeepLink',
    TRANSACTION_CURRDAY_DEPOSIT_DETAIL_DEEPLINK: 'BANK_ACC_INFO/currDayDepositAccountTransactionsDeepLink',
    TRANSACTION_PREVDAY_DEPOSIT_DETAIL_DEEPLINK: 'BANK_ACC_INFO/prevDayDepositAccountTransactionsDeepLink',

    // non-tiered loans
    TRANSACTION_LOAN_ACCOUNT_DETAIL: 'LOAN_ACC_INFO/loanAccountsTransactions',
    TRANSACTION_LOAN_DETAIL_DEEPLINK: 'BANK_ACC_INFO/loanAccountTransactionsDeepLink',
    TRANSACTION_PREVDAY_LOAN_DETAIL_DEEPLINK: 'BANK_ACC_INFO/loanPreviousDayAccountsTransactionsDeepLink',
    TRANSACTION_CURRDAY_LOAN_DETAIL_DEEPLINK: 'BANK_ACC_INFO/loanCurrentDayAccountsTransactionsDeepLink',
    TRANSACTION_LOAN_DETAIL: 'BANK_ACC_INFO/loanAccountsTransactions',
    TRANSACTION_CURRDAY_LOAN_DETAIL: 'BANK_ACC_INFO/loanCurrentDayAccountsTransactions',
    TRANSACTION_PREVDAY_LOAN_DETAIL: 'BANK_ACC_INFO/loanPreviousDayAccountsTransactions',

    // tiered loans
    TRANSACTION_TIERED_LOAN_ACCOUNT_DETAIL: 'LOAN_ACC_INFO/tieredLoanAccountDetail',
    TRANSACTION_TIERED_LOAN_DETAIL_DEEPLINK: 'BANK_ACC_INFO/tieredLoanAccountDetailDeepLink',
    TRANSACTION_PREVDAY_TIERED_LOAN_DETAIL_DEEPLINK: 'BANK_ACC_INFO/tieredLoanPreviousDayAccountDetailDeepLink',
    TRANSACTION_CURRDAY_TIERED_LOAN_DETAIL_DEEPLINK: 'BANK_ACC_INFO/tieredLoanCurrentDayAccountDetailDeepLink',
    TRANSACTION_TIERED_LOAN_DETAIL: 'BANK_ACC_INFO/tieredLoanAccountDetail',
    TRANSACTION_PREVDAY_TIERED_LOAN_DETAIL: 'BANK_ACC_INFO/tieredLoanPreviousDayAccountDetail',
    TRANSACTION_CURRDAY_TIERED_LOAN_DETAIL: 'BANK_ACC_INFO/tieredLoanCurrentDayAccountDetail',

    TRANSACTION_TIERED_LOAN_EXPORT: 'tieredLoanSubAccount/transactions/export/listView',
    ASYNC_TRANSACTION_TIERED_LOAN_EXPORT: 'balanceAndTransaction/tieredLoanSubAccount/transactions/export/executeListView',
    INQUIRYID_TIERED_LOAN_SUB_ACCOUNT: '22484',
    INQUIRYID_BAL_ACCOUNTGROUP: '22214',
    TOTALS_INQUIRYID_BAL_ACCOUNTGROUP: '22213',
    INQUIRYID_BAL_ACCOUNT: '22212',
    TOTALS_INQUIRYID_BAL_ACCOUNT: '22215',
    INQUIRYID_BAL_ACCOUNTGROUP_PD: '22264',
    TOTALS_INQUIRYID_BAL_ACCOUNTGROUP_PD: '22263',
    INQUIRYID_BAL_ACCOUNT_PD: '22262',
    TOTALS_INQUIRYID_BAL_ACCOUNT_PD: '22265',
    INQUIRYID_REALTIMEACT_COUNT: '22031',
    INQUIRYID_AVAILABLE_ACCOUNTS: '22249',
    INQUIRYID_AVAILABLE_TOA_LOCATIONS: '22269',
    BTR_REPORT_NUMROWS: '500',
    ENTITLED_GIR_TYPES_URL: 'balanceAndTransaction/getEntitledGIRTypes',
    BALANCE_TIERED_LOAN_EXPORT_ID: '22464',
    TRANSACTION_TIERED_LOAN_EXPORT_ID: '22451',
    TRANSACTION_TIERED_LOAN_PRINT_ID: '22468',
    ACCOUNT_BALANCE_SUMMARY_SERVICE: 'accountSummary/getCashPositionBalSumTotals',
    ACCOUNT_TRANSACTION_SUMMARY_SERVICE: 'accountSummary/getTransactionSummInfo',
    TRANSACTION_TOTALS_SERVICE: 'transactions/getTransactionSummInfo',
    ACCOUNT_SECTIONS_SERVICE: 'balanceAndTransaction/accountSummary/getAccountSections',
    BTR_CONTEXTUAL_DATA: 'btrAccountDetails',
    SELECT: 'SELECT',
    INST: 'INST',
    GIR: 'GIR',
    DEPOSIT: 'DEPOSIT',
    PRO: 'PRO',
    ACC_SUMMARY: 'accountSummary',
    NONE: 'none',

    // BTR User Permission Typecode Values
    BTR_TYPECODES: {
        PREVIOUS: {
            TRANSACTIONS: 'GIRTRA_P',
            SUMMARY: 'GIRBAL_P',
            IMAGES: 'GIRIMG_P',
        },

        CURRENT: {
            TRANSACTIONS: 'GIRTRA_I',
            SUMMARY: 'GIRBAL_I',
            'CASHPOSITION/': 'GIRBAL_I',
            'CASHPOS_GIRTRN_I/': 'GIRTRN_I',
            IMAGES: 'GIRIMG_I',
        },

        EXPORT: {
            BAI_EXPORT: 'BAIEXP',
        },
    },

    BTR_FUNCTIONS: {
        EXPORT: 'EXPORT',
    },

    STORE_ACCOUNT_FILTER: 'DepositAccountFilter',

    REAL_TIME_TRANSACTION_POLLER_SERVICES: {
        CUSTOMREPORT: '/balanceAndTransaction/customReporting/getRealTimeInformation',
        CURRENTDAYCASH: {
            'CASHPOSITION/': 'balanceAndTransaction/depositAccounts/CASHPOSITION/accountSummary/getRealTimeInformation',
            'CASHPOS_GIRTRN_I/': 'balanceAndTransaction/depositAccounts/CASHPOS_GIRTRN_I/accountSummary/getRealTimeInformation',
        },
        DEPOSIT: {
            START: 'balanceAndTransaction/depositAccounts/getTransactions/requestRealTimeTransactions',
            STATUS: 'balanceAndTransaction/depositAccounts/getTransactions/checkRealTimeResponse',
        },

        LOAN: {
            START: 'loanreporting/getTransactions/requestRealTimeTransactions',
            STATUS: 'loanreporting/getTransactions/checkRealTimeResponse',
        },

        CREDIT_CARD: {
            START: 'balanceAndTransaction/creditCardAccounts/getTransactions/requestRealTimeTransactions',
            STATUS: 'balanceAndTransaction/creditCardAccounts/getTransactions/checkRealTimeResponse',
        },
    },

    // Transaction Balance Details Url based on account type
    ACCT_TRANSACTION_BALANCE_SERVICE: {
        DEPOSIT: 'balanceAndTransaction/depositAccounts/accountSummary/getListViewBalanceColumns',
        CREDIT_CARD: 'balanceAndTransaction/creditCardAccounts/accountSummary/getListViewBalanceColumns',
        LOAN: 'loanreporting/accountSummary/getListViewBalanceColumns',
    },

    // Common Account Type Values
    ACCOUNT_TYPE: {
        DEPOSIT: ['DD', 'SV', 'GL', 'SW'],
        LOAN: ['CL', 'LC'],
        CREDIT_CARD: ['CC'],
    },

    ACCOUNT_TYPE_EXPORT: {
        CREDIT_CARD: 'CREDIT_CARD',
    },

    // Transaction or Balance As Of Date & Time keys
    AS_OF_DATE: 'ASOFDATE',

    AS_OF_TIME: 'ASOFTIME',

    EXPORT_TYPE_FILTER_ID: {
        BAIEXP: 22017,
        QUICKBKS: 22018,
        QUICKEN: 22018,
        SWMT940: 22019,
        SWMT942: 22019,
        CAMTEXP: 22023,
        CSVEXP: 22021,
        CSVEXP_GIRBAL: 22018,
        CSBEXP: 22021,
        TSVEXP: 22021,
        TSVEXP_GIRBAL: 22018,
    },
    SCHEDULED_EXPORT_TYPE_FILTER_ID: {
        BAIEXP: 40122,
        QUICKBKS: 43032,
        QUICKEN: 43032,
        SWMT940: 40123,
        SWMT942: 40123,
        CAMTEXP: 40122,
        CSVEXP: 40122,
        CSVEXP_GIRBAL: 40122,
        CSBEXP: 40122,
        TSVEXP: 40122,
        TSVEXP_GIRBAL: 40122,
    },

    TYPEAHEAD_INQUIRY: {
        ACCOUNTFILTER: 22018,
        ACCOUNTFILTER2: 22014,
    },

    BAI_TYPE: {
        PRIOR: 'P',
        CURRENT: 'I',
    },

    ACCOUNT_SUMMARY: {
        TABS: {
            ALL: 'ALLACCOUNTS',
            PRIOR: 'PRIORDAY',
            CASH_POSITION: 'CASHPOSITION',
            CASH_POSITION_GIRTRN_I: 'CASHPOS_GIRTRN_I',
            CURRENT: 'CURRDAY',
            CUSTOM_REPORT: 'CUSTOMREPORT',
            CUSTOM_REPORT_PRIOR: 'CUSTOMREPORTPRIOR',
            ACCOUNT_GROUPS: 'ACCOUNT_GROUPS',
        },

        SECTIONS: {
            DEPOSITS: 'DEPOSITACCTS',
            LOANS: 'LOANACCTS',
            CREDIT_CARDS: 'CREDITCARDS',
            TIMEOPEN: 'TIMEOPEN',
        },

        SECTION_TYPES: {
            DEPOSIT: 'DEPOSIT',
            LOAN: 'LOAN',
        },
    },

    EXPORT_TYPE_RESTRICTIONS: {
        DEFAULT: [
            'CSVEXP',
            'TSVEXP',
        ],

        TRANS: {
            PRIOR: [
                'BAIEXP',
                'CAMTEXP',
                'CSBEXP',
                'QUICKBKS',
                'QUICKEN',
                'SWMT940',
            ],

            CURRENT: [
                'BAIEXP',
                'CAMTEXP',
                'CSBEXP',
                'SWMT942',
            ],
        },
    },

    CUSTOM_REPORT_SELECT: {
        number: {
            inquiryID: 22031,
            fieldName: 'AccountFilter',
            defaultKey: 'listviews.allaccounts',
            fieldLabel: 'AccountNameNumber',
        },

        group: {
            inquiryID: 22013,
            fieldName: 'AccountGroupName',
            defaultKey: 'listviews.allGroups',
            fieldLabel: 'AccountGroup',
        },

        bankCode: {
            inquiryID: 22007,
            fieldName: 'Code',
            defaultKey: 'listviews.allBanks',
            fieldLabel: 'Description',
        },

        currency: {
            inquiryID: 22009,
            fieldName: 'Code',
            defaultKey: 'listviews.allCurrencies',
            fieldLabel: 'DisplayDesc',
        },
    },

    CUSTOM_REPORT_MAX_ACCOUNTS: 50,

    TRANSACTIONS_GRID_NON_SORTABLE_COLUMNS: [
        'PAYMENT_DETAIL',
        'DISP_IMG_INDICATOR',
        'BALANCE',
    ],
    EXPORT_TYPE: {
        BAIEXP: 'BAIEXP',
        CSV: 'CSV',
        CSVEXP: 'CSVEXP',
        TSV: 'TSV',
        TSVEXP: 'TSVEXP',
        CAMTEXP: 'CAMTEXP',
        SWMT942: 'SWMT942',
        SWMT940: 'SWMT940',
    },

    // This is a temp fix for NH-153358
    CHECKS_PAID_VIEW_ID_LIST: [
        '22445', // All Accounts
        '22452', // Prev day
        '29075', // Current day
        '29085', // Account groups
    ],
    // End of temp fix for NH-153358
};

export default Constants;
