import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import InquiryApi from 'system/webseries/api/inquiry';

export default Collection.extend({
    initialize(opts) {
        this.inquiryId = opts.inquiryId;
        this.searchType = opts.searchType || InquiryApi.DEFAULT_SEARCH_TYPE;
        this.typeCode = opts.typeCode || InquiryApi.DEFAULT_TYPE_CODE;
        this.viewId = opts.viewId || InquiryApi.DEFAULT_VIEW_ID;
        this.currentPage = 1;
        this.rowsPerPage = 10;
    },

    sync(method, model, options) {
        const self = this;

        const startRow = ((this.currentPage - 1) * this.rowsPerPage) + 1;

        /*
         * Use the inquiry service to retrieve the header information.  Transform into
         * dataset that grid is happy consuming.
         */
        InquiryApi.getRowsData({
            inquiryId: self.inquiryId,
            searchType: self.searchType,
            typeCode: self.typeCode,
            viewId: self.viewId,
            rowsPerPage: self.rowsPerPage,
            sortKey: self.sortKey,
            sortOrder: self.sortOrder,
            filters: self.filters,
            startRow,
        }).done((data) => {
            options.success(data);
        });
    },

    paginate(page, rowsPerPage) {
        this.currentPage = page;
        this.rowsPerPage = rowsPerPage;
        this.reset();
        this.fetch();
    },

    sort(key, order) {
        this.sortKey = key;
        this.sortOrder = order;
        this.currentPage = 1;
        this.reset();
        this.fetch();
    },

    filter(filters) {
        this.filters = [];
        this.currentPage = 1;
        for (let i = 0; i < filters.models.length; i += 1) {
            const entry = {
                fieldName: filters.models[i].get('field'),
                fieldValue: filters.models[i].get('label'),
            };
            this.filters.push(entry);
        }

        this.reset();
        this.fetch();
    },

    /**
     * Parse newly formatted table data from the backend into a format
     * that can be easily consumed by a grid.
     *
     * @param result
     */
    parse(result) {
        try {
            // grab other data first
            this.totalCount = result.inquiryResponse.totalRows;
            return util.map(result.inquiryResponse.rows, this.parseColumn, this);
        } catch (err) {
            return [];
        }
    },

    parseColumn(row) {
        const { columns } = row;
        const obj = {};
        for (let i = 0, len = columns.length; i < len; i += 1) {
            if (columns[i].fieldName === 'ACTIONLIST') {
                obj.buttons = this.parseActionList(columns[i].fieldValue);
            }

            obj[columns[i].fieldName] = columns[i].fieldValue;
        }
        return obj;
    },

    /**
     * Convert the webseries delimited action list to an array of buttons.
     *
     * @param value
     * @returns {Array}
     */
    parseActionList(value) {
        // parse the list of available actions.
        const actions = value.split('^');

        const buttons = [];

        util.each(actions, (action) => {
            const actionTokens = action.split(',');
            /*
             * prepend a unique string to actions coming from the backend so we don't collide
             * with keywords internal to glu.
             * Note: assumes action label is already localized by the backend
             */
            buttons.push({
                value: `action_${actionTokens[0].toLowerCase()}`,
                label: actionTokens[1],
            });
        });

        return buttons;
    },
});
