var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"DetailsItem-label DetailsItem-label--name col-5\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":6,"column":82},"end":{"line":6,"column":90}}}) : helper)))
    + "</span>\n                    <span class=\"DetailsItem-label DetailsItem-label--value col-5\">"
    + alias1(container.lambda(depth0, depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "balances-hidden";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"SummaryTable\">\n                            <h3 class=\"SummaryTable-title\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.TodaysBalances",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":59},"end":{"line":33,"column":90}}}))
    + "</h3>\n                            <div data-region=\"balanceSummaryRegion\"></div>\n                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <h3 class=\"SummaryTable-title\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.transactions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":59},"end":{"line":39,"column":88}}}))
    + "</h3>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"AcctSubTotalDetails\">\n    <div class=\"AcctSubTotalDetails-header row\">\n        <div class=\"AcctSubTotalDetails-asOfDates col-md-6 col-lg-4\">\n            <div class=\"BalanceAsOf DetailsItem row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"asOfTime") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":8,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"TransactionAsOf DetailsItem row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"asOfDate") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":14,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n    <div class=\"AcctSubTotalDetails-panel panel-collapse collapse\" data-hook=\"getPanel\">\n        <div class=\"panel-body\">\n            <div class=\"summary-section\">\n                <div class=\"title\">\n                    <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":35}}}) : helper)))
    + "</span>\n                </div>\n                <div data-region=\"balanceTransactionSummaryPanelRegion\"></div>\n            </div>\n            <div class='show-details-btn'>\n                <span data-hook=\"getShowHideBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.ShowDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":49},"end":{"line":27,"column":77}}}))
    + "</span>\n            </div>\n            <div class=\"expanded-summary-section\" data-hook=\"getSummarySection\">\n                <section class=\"SummaryTableSection "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"balancesHidden") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":52},"end":{"line":30,"column":96}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"balancesHidden") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":36,"column":31}}})) != null ? stack1 : "")
    + "                    <div class=\"SummaryTable\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"balancesHidden") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":40,"column":35}}})) != null ? stack1 : "")
    + "                        <div data-region=\"transactionSummaryRegion\"></div>\n                    </div>\n                </section>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});