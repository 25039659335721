var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"insertStop\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cm.button_insert_stop",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":138},"end":{"line":12,"column":172}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"insertCancel\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cm.button_insert_cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":140},"end":{"line":15,"column":176}}}))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-tertiary fileimport\" data-hook=\"fileimport-button\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cm.button_fileimportStops",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":154},"end":{"line":18,"column":192}}}))
    + "</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary approve\" data-action=\"approve\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cm.button_approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":96},"end":{"line":31,"column":126}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary unapprove\" data-action=\"unapprove\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cm.button_unapprove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":102},"end":{"line":34,"column":134}}}))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary delete\" data-action=\"delete\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cm.button_delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":96},"end":{"line":38,"column":125}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":52}}}))
    + "</h1>\n</div>\n    <div class=\"section section-container\">\n        <div class=\"section-header\">\n            <h2>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"section-header-list",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":51}}}))
    + "</h2>\n        </div>\n        <div class=\"section-body\">\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasStopInsertEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":13,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCancelInsertEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFileImportEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                    <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n        </div>\n    </div>\n    <div class=\"section section-container\">\n        <div class=\"section-body\">\n            <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUnapproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n";
},"useData":true});