import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import { postData } from 'common/util/services';
import servicesUtil from 'system/servicesList';
import template from './rejectModal.hbs';
import localeKeys from './localeKeys';

const ApprovalModal = Layout.extend({
    template,

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    events: {
        'click [data-hook="cancel-button"]': 'cancel',
    },

    initialize(options) {
        this.options = options;
        this.model = new Model();
        Layout.prototype.initialize.call(this, options);
    },

    save() {
        const rejectUrl = servicesUtil.generateUrl('featureControl/REJECT');
        const reason = this.$el.find('input[name="REJECTION_REASON"]').val();
        postData(rejectUrl, {
            item: {
                item: [
                    {
                        name: 'FEATUREHEADERID',
                        value: '1',
                    },
                    {
                        name: 'REJECTION_REASON',
                        value: reason,
                    },
                ],
            },
        }).then((response) => {
            if (response.result) {
                this.options.refreshData('reject', {
                    response,
                });
                this.close();
            } else {
                this.model.set({
                    error: response.confirms.confirmResults[0].messages[0],
                });
                this.render();
            }
        }).catch((xhr) => {
            this.options.refreshData('reject', {
                response: xhr.responseJSON,
            });
            this.close();
        });
    },

    cancel() {
        this.options.refreshData('cancel');
        this.close();
    },

    templateHelpers() {
        return {
            errorClass: this.model.get('error') ? 'has-error' : '',
            errorString: this.model.get('error') ? this.model.get('error') : '',
            localeKeys,
        };
    },
});

export default ApprovalModal;
