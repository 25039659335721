import f1 from 'system/utilities/f1';
import $ from '@glu/core/src/$';
import Formatter from 'system/utilities/format';
import { transformRemittanceUi, transformRemittanceUiView } from 'pcm/app/payments/RemittanceTransform/remittanceTransform';
import locale from '@glu/locale';

// import { default as $ } from 'glu/core/src/$';
// import { $ } from 'glu/core'; // DGB says it doesn't work

export default function (form, initialState) {
    const $payeeAddressLink2 = $('#PAYEEADDRESS_LINK');
    const formState = form.formView.state;
    const { parentModel } = form.formView;
    let entryMethod = parentModel.context.entrymethod;
    const { useBeneWidget } = form.formView;
    const { beneWidget } = form.formView;
    const { model } = form.formView;
    // const currentDate = Formatter.formatDate(new Date());

    $payeeAddressLink2.addClass('btn-link');

    const beneForm = useBeneWidget ? f1.getForm(beneWidget) : form;
    const hideEmptyAddressLine = function (payeeAddress) {
        if (beneForm.field(payeeAddress).isNotEmpty()) {
            beneForm.field(payeeAddress).shouldBeVisible();
        } else {
            beneForm.field(payeeAddress).shouldBeHidden();
        }
    };
    const checkNumber = beneForm.field('CHECKNUMBER');
    const checkProf = beneForm.field('CHECKPROFILEKEYDESC');
    const beneModel = beneForm.formView.model;
    const beneFormContext = beneForm.formView.context;
    const contextAction = beneFormContext.actionMode.toUpperCase();


    const province = beneModel.get('PROVINCE');
    const payeeProvince = beneModel.get('PAYEESTATE_PROVINCE');
    const $payeeCountryStateRegion = $('.CLM_INST_APCHK_PAYEECOUNTRYSTATE');
    const $payeePostalCode = $('#PAYEEPOSTALCODE');
    const payeeCountry = beneModel.get('PAYEECOUNTRYCODE');
    const payeePostalCode = beneModel.get('PAYEEPOSTALCODE');
    const elem = `<div class="text-group col-md-2 col-mdlg-4 field-container" id="PROVINCE">
                        <label>${locale.get('clm.province.label')}</label>
                        <div class="mdf-input-icon-container">
                        </div>
                        <p class="form-control-static">
                            <span name="PROVINCE">${payeeProvince}</span>
                        </p>
                    </div>`;

    if (!province && payeeCountry !== 'US' && formState === 'view') {
        if (payeePostalCode) {
            $payeePostalCode.before(elem);
        } else {
            $payeeCountryStateRegion.append(elem);
        }
    }

    const hideAdditionalAddresses = function () {
        hideEmptyAddressLine('PAYEEADDRESS_2');
        hideEmptyAddressLine('PAYEEADDRESS_3');
        hideEmptyAddressLine('PAYEEADDRESS_4');
    };
    const findProfileDisclaimer = function () {
        const profileContainer = $('#CHECKPROFILEKEYDESC').parent();
        return profileContainer.children().last();
    };
    const checkProfileEnableDisableOnCheckAppSelection = function () {
        if (!parentModel.get('CHECKAPP_DESC')) {
            checkProf.setValue('');
            checkProf.shouldBeReadOnly(true);
            findProfileDisclaimer().show();
        } else {
            if (parentModel.changed.CHECKAPP_DESC) {
                checkProf.setValue('');
                document.getElementById('s2id_CHECKPROFILEKEYDESC').getElementsByClassName('select2-chosen')[0].innerHTML = 'Select';
            }
            checkProf.shouldBeReadOnly(false);
            findProfileDisclaimer().hide();
        }
    };

    const setDefaultValues = function () {
        hideAdditionalAddresses();
        checkProfileEnableDisableOnCheckAppSelection();
        checkNumber.shouldBeReadOnly(false);
        checkNumber.shouldBeRequired(true);
    };


    const grossDiffDiscountEqualsNet = function () {
        if (beneModel.get('REMITAMOUNT1') !== '' || beneModel.get('REMITAMOUNT2') !== '') {
            const netAmount = beneModel.get('REMITAMOUNT1') - beneModel.get('REMITAMOUNT2');
            beneForm.field('REMITAMOUNT3').setValue(netAmount);
            beneModel.set('REMITAMOUNT3', netAmount);
        }
    };
    const changeCheckNumberStatusOnceCheckProfileIsSelected = function () {
        if (beneModel.attributes.APNYN === '1') {
            checkNumber.setValue('');
            checkNumber.shouldBeReadOnly(true);
            checkNumber.shouldBeOptional(true);
        } else {
            checkNumber.shouldBeReadOnly(false);
            checkNumber.shouldBeRequired(true);
        }
    };
    const ensureCheckNumberIsNumericOnly = function () {
        const reg = /^[0-9]*$/;
        let checkNum = beneForm.field('CHECKNUMBER').getValue();
        if (!reg.test(checkNum)) {
            checkNum = checkNum.substring(0, checkNum.length - 1);
            checkNumber.setValue(checkNum);
        }
        this.changed = {};
    };
    const cleanMandatoryFieldValidationMessages = function () {
        // This method clears validation msg-s that stays once user fill
        // mandatory fields by resetting validation states on those fields
        const validatorsKeys = Object.keys(beneModel.validators);
        for (let index = 0; index < validatorsKeys.length; index += 1) {
            const validatedField = beneForm.field(validatorsKeys[index]);
            const validator = beneModel.validators[validatorsKeys[index]];
            if (validatedField.isNotEmpty() && validator.exists) {
                // validator exists is used for mandatory fields
                validatedField.resetValidationState();
            }
        }
    };
    const hideStateOrProvinceBasedOnSelectedPayeeCountry = function () {
        const isUS = beneForm.field('PAYEECOUNTRYCODE').getValue() === 'US';
        beneForm.field('PAYEESTATE_PROVINCE').shouldBeVisibleWhen(Boolean(isUS)).shouldBeReadOnly(Boolean(!isUS));
        beneForm.field('PROVINCE').shouldBeVisibleWhen(Boolean(!isUS)).shouldBeReadOnly(Boolean(isUS));
    };
    const areAllBeneModelFieldsEmptyBesideDefaultCheckDate = function () {
        return !beneModel.get('CHECKNUMBER') && !beneModel.get('AMOUNT') &&
            !beneModel.get('PAYEENAME') && !beneModel.get('TRANCOMMENT') &&
            !beneModel.get('PAYEEID') && !beneModel.get('PAYEEADDRESS_1') &&
            !beneModel.get('PAYEEADDRESS_2') && !beneModel.get('PAYEEADDRESS_3') &&
            !beneModel.get('PAYEEADDRESS_4') && !beneModel.get('PAYEECOUNTRYCODE') &&
            !beneModel.get('PAYEECITY') && !beneModel.get('PAYEEPOSTALCODE') &&
            !beneModel.get('PAYEESTATE_PROVINCE') && !beneModel.get('REMITTEXT1') &&
            !beneModel.get('REMITDATE1') && !beneModel.get('REMITTEXT5') &&
            !beneModel.get('REMITAMOUNT1') && !beneModel.get('REMITAMOUNT2') &&
            !beneModel.get('REMITAMOUNT3') && !beneModel.get('REMITWHOLERECODR');
    };
    const isThisEmptyAddNewPayeeFormOnModifyScreen = function () {
        // This method verifies whether we have empty add Bene screen on modify page
        return areAllBeneModelFieldsEmptyBesideDefaultCheckDate() && contextAction === 'MODIFY';
    };
    const cleanDefaultCheckDateInEmptyAddNewPayeeFormOnModifyScreen = function () {
        // This method prevents validation to be run on empty add bene form in modify screen
        // by clearing the default check date
        if (isThisEmptyAddNewPayeeFormOnModifyScreen()) {
            beneModel.set('CHECKDATE', '');
            beneForm.field('CHECKDATE').resetValidationState();
        }
    };
    const addBene = beneForm.field('ADDBENE');
    const clearBene = beneForm.field('CLEARBENE');

    addBene.$el.on('click', () => {
        beneForm.field('PAYEEADDRESS_LINK').shouldBeVisible();
    });

    beneForm.field('PAYEEADDRESS_LINK')
        .$el.on('click', (e) => {
            if (beneForm.field('PAYEEADDRESS_2').isNotVisible()) {
                e.stopImmediatePropagation();
                beneForm.field('PAYEEADDRESS_2').shouldBeVisible();
            }
        });

    beneForm.field('PAYEEADDRESS_LINK')
        .$el.on('click', (e) => {
            if (beneForm.field('PAYEEADDRESS_3').isNotVisible() && beneForm.field('PAYEEADDRESS_2').isVisible()) {
                e.stopImmediatePropagation();
                beneForm.field('PAYEEADDRESS_3').shouldBeVisible();
            }
        });

    beneForm.field('PAYEEADDRESS_LINK')
        .$el.on('click', (e) => {
            if (beneForm.field('PAYEEADDRESS_4').isNotVisible() && beneForm.field('PAYEEADDRESS_3').isVisible()) {
                e.stopImmediatePropagation();
                beneForm.field('PAYEEADDRESS_4').shouldBeVisible();
                beneForm.field('PAYEEADDRESS_LINK').shouldBeHidden();
            }
        });

    if (entryMethod === undefined && (formState === 'modify' || formState === 'restore')) {
        entryMethod = parentModel.get('ENTRYMETHOD');
    }

    if (formState === 'insert') {
        addBene.shouldBeVisible();
        clearBene.shouldBeVisible();
    }

    if (contextAction === 'INSERT') {
        checkProfileEnableDisableOnCheckAppSelection();
    }

    if (contextAction === 'INSERT') {
        // hide check audit history for insert only
        const beneModelCheckTypeCode = contextAction === 'INSERT'
            ? beneFormContext.serviceNameTypeCode : beneFormContext.typeCode;

        const checkTypeCode = beneModelCheckTypeCode.indexOf('B') === 0
            ? beneModelCheckTypeCode.substring(1) : beneModelCheckTypeCode;

        $(`.CLM_INST_${checkTypeCode}_AUDITHISTORY_0`).hide();
        $(`.CLM_INST_${checkTypeCode}_AUDITHISTORY_0-heading`).hide();
    }


    if (formState !== 'view') {
        beneModel.on('change', changeCheckNumberStatusOnceCheckProfileIsSelected);
        beneModel.on('change', ensureCheckNumberIsNumericOnly);
        beneModel.on('validate', cleanDefaultCheckDateInEmptyAddNewPayeeFormOnModifyScreen);
        beneModel.on('change', cleanMandatoryFieldValidationMessages);
        beneModel.on('change', grossDiffDiscountEqualsNet);
        parentModel.on('change', checkProfileEnableDisableOnCheckAppSelection);
        beneModel.fieldData.CHECKPROFILEKEYDESC.dependsOn = ['CHECKAPP_GROUPNUM'];
        hideStateOrProvinceBasedOnSelectedPayeeCountry();
    }

    beneForm.field('PAYEECOUNTRYCODE').$el.on('change', () => {
        hideStateOrProvinceBasedOnSelectedPayeeCountry();
        beneForm.field('PROVINCE').$el.on('change', () => {
            // Copy value of province field to payeestate_province & clean province field
            beneModel.set('PAYEESTATE_PROVINCE', beneModel.get('PROVINCE'));
        });
    });

    beneForm.field('PROVINCE').$el.on('change', () => {
        beneModel.set('PAYEESTATE_PROVINCE', beneModel.get('PROVINCE'));
    });


    if (entryMethod === '1' || formState === 'modify') {
        addBene.shouldBeVisibleWhen(false);
        clearBene.shouldBeVisibleWhen(false);
        checkProfileEnableDisableOnCheckAppSelection();
    }

    if (formState === 'view') {
        form.formView.$('.panel-body').filter(':empty').closest('.row').hide();
        const formattedDateOfInvoice = Formatter.formatDate(beneModel.get('REMITDATE1'));
        const unformattedInvoiceDataIndex = 2;
        const invoiceDate = $('#REMITDATE1').children()[unformattedInvoiceDataIndex];
        if (invoiceDate) {
            invoiceDate.innerText = formattedDateOfInvoice;
        }
    }

    model.listenTo(model, 'beneWidget:defaults', () => {
        setDefaultValues();
    });

    if (initialState && formState !== 'view') {
        setDefaultValues();
        const isPayroll = parentModel.context.typeCode === 'BPRCHK'
            || parentModel.context.serviceNameTypeCode === 'BPRCHK';
        if (isPayroll) {
            transformRemittanceUi(beneForm);
        }
        const isUS = beneModel.get('PAYEECOUNTRYCODE') === 'US';
        if (!isUS) {
            beneModel.set('PROVINCE', beneModel.get('PAYEESTATE_PROVINCE'));
        }
    } else if (initialState && formState === 'view') {
        const isPayroll = parentModel.context.typeCode === 'BPRCHK'
            || parentModel.context.serviceNameTypeCode === 'BPRCHK';
        if (isPayroll) {
            transformRemittanceUiView(beneForm);
        }
        const isUS = beneModel.get('PAYEECOUNTRYCODE') === 'US';
        if (!isUS) {
            beneModel.set('PROVINCE', beneModel.get('PAYEESTATE_PROVINCE'));
        }
    }

    $('#CHECKDATE').on('change', (e) => {
        // PCM-7178-Print Date is getting automatically pushed to future date,
        // if any of the checks with Check Date in future Fix
        /* const checkDate = $('#CHECKDATE').val();
         const minimalPrintDate = checkDate < currentDate ? currentDate : checkDate;
         $('#RELEASE_DATE').daterangepicker({
            format: 'MM/DD/YYYY',
            minDate: minimalPrintDate,
            singleDatePicker: true,
        }); */
        beneModel.set('CHECKDATE', $('#CHECKDATE').val());
        e.stopImmediatePropagation();
    });

    $('#RELEASE_DATE').on('change', (e) => {
        // PCM-6295 - removal of restriction of maxDate
        $('#CHECKDATE').daterangepicker({
            format: 'MM/DD/YYYY',
            singleDatePicker: true,
        });
        parentModel.set('RELEASE_DATE', $('#RELEASE_DATE').val());
        e.stopImmediatePropagation();
    });
}
