import { $, $document, $window, Layout, util } from '@glu/core';
import GluIcons from '@glu/icons';
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/keycode';
import './localeStrings';
import FlexDropdownDynamicModel from './models/flexDropdownDynamicModel';
import FlexDropdownModel from './models/flexDropdownModel';
import FlexDropdownItemLayout from './views/flexDropdownItemsLayout';
import FlexDropdownSelectionCompositeView from './views/flexDropdownSelectionCompositeView';
import template from './flexDropdown.hbs';

const gluIconsInstance = new GluIcons({});
gluIconsInstance.registerIconHelper();

export default Layout.extend({
  noDefaultBehaviors: true,
  noGluEvents: true,
  ui: {
    filter: 'input[type="text"]',
    filterAutocomplete: '.filter-autocomplete',
    list: '.list',
    listItems: '.list .items',
    dropdown: '.flex-dropdown',
    selection: '.selection',
    label: 'label',
    dropdownWarning: '.flex-dropdown-warning'
  },
  events: {
    'keyup @ui.filter': 'filterKeyUp',
    'keydown @ui.filter': 'filterKeyDown',
    'paste @ui.filter': 'filterCollection',
    'click @ui.filter': 'clearFilterAutoComplete',
    'click @ui.filterAutocomplete': 'clearFilterAutoComplete',
    'mouseover @ui.listItems li, @ui.listItems tr': 'mouseOverItem',
    'mouseout @ui.listItems li, @ui.listItems tr': 'mouseOutItem',
    'mouseover @ui.dropdown:not(.open)': 'mouseOverDropdown',
    'mouseout @ui.dropdown': 'mouseOutDropdown',
    'click @ui.selection:not(.disabled)': 'toggleOpen',
    'keydown @ui.dropdown': 'keyDown',
    'click @ui.label': 'clickLabel',
    'click .selected-items *[data-value]': 'clickCancel',
    'click .flex-dropdown-remove-item': 'clickCancel'
  },

  modelEvents: {
    sync: 'handleSync',
    'item:selected': 'setOpenDirection'
  },

  regions: {
    items: '.items',
    selected: 'div.selected'
  },
  appEvents: {
    'open.dropdown': 'closeDropdown'
  },
  tagName: 'div',
  className: 'form-group flex-dropdown-wrapper',
  template,
  modelLoaded: false,
  serializeData() {
    return util.extend({
      options: this.options,
      hasLabel: (this.options.label !== undefined),
      isHierarchical: this.model.isHierarchical(),
      multiSelectionWarning: this.options.multiSelectionWarning !== undefined ? this.options.multiSelectionWarning : 'multiSelectionWarning'
    }, Layout.prototype.serializeData.call(this));
  },

  initialize() {
    util.bindAll(this, 'dataLoadedHandler');
    this.loadModel();
  },

  handleSync() {
    this.modelLoaded = true;
    this.render();
  },

  onRender() {
    if (this.modelLoaded) {
      this.dataLoadedHandler();
    }

    this.loadIds();
    this.loadEvents();
    this.setScroll();
  },

  onClose() {
    this.unLoadEvents();
    this.unsetScroll();
  },
  /**
   * Adds scroll event handler for dropdown.
   *
   * 30 items are added to the collection if the user scrolls down until the user loads all items
   *
   */
  setScroll() {
    const that = this;

    this.ui.listItems.scroll(() => {
      const scrollHeightValue = that.ui.listItems[0].scrollHeight;
      const scrollTopValue = that.ui.listItems.scrollTop();
      // fetch next slice of data only if scroll bar has reach 80% if scroll height
      if (scrollTopValue > (0.8 * scrollHeightValue)) {
        const newItemIndex = that.model.get('items').length;
        that.model.getActive(newItemIndex, 'scroll', true);
      }
    });
  },
  /**
   * Removes scroll event handler from DOM when view is unloaded
   */
  unsetScroll() {
    this.ui.listItems.off('scroll');
  },
  /**
   * Creates a unique id/for pair for the dropdown and label
   */
  loadIds() {
    if (!this.dropdownId && this.dropdownId !== 0) {
      this.appBus.dropdownId = this.appBus.dropdownId || 0;
      this.dropdownId = `dropdown-${this.appBus.dropdownId}`;
      this.ui.dropdown.attr('id', this.dropdownId);
      this.ui.label.attr('for', this.dropdownId);
      this.appBus.dropdownId++;
    }
  },

  /**
   * Fired when user clicks on label
   */
  clickLabel() {
    this.ui.dropdown.focus();
  },

  mouseOverDropdown() {
    if (this.options.showTooltip === undefined || this.options.showTooltip) {
      this.showTooltip();
    }
  },

  showTooltip() {
    const $tooltip = this.ui.selection.find('.selection-tooltip.on');
    $tooltip.show();
    const marginBottom = ($tooltip.outerHeight() * -1) - 13;
    $tooltip.css('margin-bottom', marginBottom);
    if (this.ui.selection.find('.selection-tooltip.on ul').length >= 5) {
      $tooltip.addClass('total-selected-tooltip');
    } else {
      $tooltip.removeClass('total-selected-tooltip');
    }
  },

  mouseOutDropdown() {
    if (this.options.showTooltip === undefined || this.options.showTooltip) {
      this.hideTooltip();
    }
  },

  hideTooltip() {
    this.ui.selection.find('.selection-tooltip').hide();
  },

  /**
   * Removes selected style class from list items in dropdown
   */
  clearSelected() {
    this.ui.listItems.find('.selectable').removeClass('selected');
  },

  isCharacterKeyPress(e) {
    if (typeof e.which === 'undefined') {
      return true;
    } if (typeof e.which === 'number' && e.which > 0) {
      return !e.ctrlKey && !e.metaKey && !e.altKey && !(e.which === 8) && !(e.which === 9) && !(e.which === 40) && !(e.which === 38) && !(e.which === 13) && !(e.which === 16) && !(e.which === 27);
    }
    return false;
  },

  /**
   * Captures key presses on the dropdown (outside of the type ahead input field)
   *
   * If the key pressed is a letter, and type ahead input is present, the user is focused on the type ahead box and the letter is added to the input
   *
   * If the key pressed is a letter, and type ahead input is not present, the user is scrolled to that letter in the list
   *
   * Other key press events control keyboard accessibility functions.
   *
   * @param {event} e - DOM event
   */
  keyDown(e) {
    if (this.ui.selection.hasClass('disabled')) {
      return;
    }

    // Add keydown class to listitem and remove it at the beginning of the mouseOverItem function.
    this.ui.listItems.addClass('keydown');

    // if the event did not occur inside the type ahead input
    if (!this.$(e.target).is('input')) {
      const { keyCode } = $.ui;

      // Supports keypad
      const stringKeyCode = (e.keyCode >= 96 && e.keyCode <= 105) ? String.fromCharCode(e.keyCode - 48) : String.fromCharCode(e.keyCode);

      if (e.keyCode === keyCode.BACKSPACE || e.keyCode === keyCode.DELETE) {
        // the delete or backspace key will clear out the selection. For a hierarchical dropdown will clears out current hierarchy level selection.
        e.preventDefault();
        e.stopPropagation();

        if (this.ui.filter) {
          this.clearFilterAutoComplete();
        }

        if (!this.options.disableClear) {
          if (this.ui.selection.find('.selected-items li.focus').index() !== -1) {
            this.ui.selection.find('.selected-items li.focus span.icon-cancel').trigger('click');
          } else {
            this.resetSelected();
          }
        }
      }
      if (e.keyCode === keyCode.SPACE) {
        // the space will make an item selection
        e.preventDefault();
        this.doItemSelection();
      } else if (e.keyCode === keyCode.RIGHT) {
        // Right arrow key will navigate lower in the hierarchy to child records for elements that have child records
        e.preventDefault();

        const $selectedItem = this.ui.listItems.find('.item-list .selectable.selected');
        if ($selectedItem.hasClass('parent')) {
          $selectedItem.find('a').click();
        }
      } else if (e.keyCode === keyCode.LEFT) {
        // Left arrow key will navigate higher in the hierarchy to parent records for elements with parent record
        e.preventDefault();
        this.itemsView.goLevelUp();
      } else if (this.isCharacterKeyPress(e)) {
        if (this.options.filter) {
          this.ui.filter.focus();
          this.filterCollection();
        } else {
          if (this.ui.dropdown.focus) {
            this.setOpenDirection();
            this.ui.dropdown.addClass('open');
            this.setMaxWidth();
            this.trigger('dropdownOpened', true);
          }

          const items = this.$('.item-list').find('li').filter((index, li) => this.$(li).text().trim().indexOf(stringKeyCode) === 0);
          let currentlySelectedItemIndex;

          if (items.length) {
            if (items.length > 1) {
              currentlySelectedItemIndex = util.indexOf(items, items.filter('.selected')[0]);

              if (currentlySelectedItemIndex > -1 && (currentlySelectedItemIndex + 1 !== items.length)) {
                items.splice(0, currentlySelectedItemIndex + 1);
              }
            }

            this.clearSelected();
            this.$(items[0]).addClass('selected');
            this.scrollToSelected();
          }
        }
      } else {
        switch (e.keyCode) {
          // up or down
          case 40:
          case 38:
          {
            const $items = this.ui.listItems.find('.item-list .selectable');
            let index = $items.index($items.filter('.selected'));
            let skipSelectionChange;

            if (!$items.filter('.selected:not(:empty)').length) {
              skipSelectionChange = true;
              $items.removeClass('selected');
              $items.eq(0).addClass('selected');
            }

            // Down
            if (e.keyCode === keyCode.DOWN) {
              if (!this.ui.dropdown.hasClass('open')) {
                this.openDropdown();
                skipSelectionChange = true;
              }

              index++;

              if (index === $items.length) {
                index = 0;
              }
            } else if (e.keyCode === keyCode.UP) { // Up
              const newItemIndex = this.model.get('items').length + 1;
              if (newItemIndex === this.model.data.length && this.model.dynamic) {
                break;
              }

              index--;

              if (index === -1) {
                if (!this.model.dynamic) {
                  index = $items.length - 1;
                } else {
                  break;
                }
              }
            }
            const newlySelectedItem = $items.eq(index);
            if (!skipSelectionChange) {
              this.clearSelected();
              newlySelectedItem.addClass('selected');
              this.filterAutoComplete(false);
            }
            // Determine the top and bottom of both the opened dropdown list and the selected item.
            // If the selected item is not within the opened dropdown,
            // (top offset of item is less than that of the list or bottom of item is larger than bottom of list)
            // then scroll to the item.
            // This is done so that there is no 'jumping' to items that are already visible.
            const windowHeight = $window.height();
            const itemList = this.ui.listItems;
            const itemListTopOffset = itemList.offset().top;
            const selectedItemTopOffset = newlySelectedItem.offset();
            const selectedItemTopOffsetTop = selectedItemTopOffset !== undefined ? selectedItemTopOffset.top : 0;
            const itemListBottom = itemListTopOffset + windowHeight + itemList.height();
            const selectedItemBottom = selectedItemTopOffsetTop + windowHeight + newlySelectedItem.height();
            if (selectedItemTopOffsetTop < itemListTopOffset || selectedItemBottom > itemListBottom) {
              this.scrollToSelected();
            }
            // to prevent subsequent scrolling of window
            e.preventDefault();
            break;
            // enter key
          }
          case 13:
            e.preventDefault();

            if (this.ui.list.find('button').hasClass('focus')) {
              this.ui.list.find('button.focus').trigger('click');
            } else if (this.ui.selection.find('.selected-items li').hasClass('focus')) {
              this.ui.selection.find('.selected-items li.focus span.icon-cancel').trigger('click');
            } else {
              this.doItemSelection();
            }

            break;
          // esc key
          case 27:
            this.closeDropdown();
            this.ui.dropdown.focus();

            break;
          // tab key
          case 9:
            if (this.$('.flex-dropdown').hasClass('open') && this.options.clearBtn) {
              let currentBtnIndex = this.ui.list.find('button.focus').index();
              const currentSelectedIndex = this.ui.selection.find('.selected-items li.focus').index();
              const btnsCount = this.ui.list.find('button').length;
              const selectedCount = this.ui.selection.find('.selected-items li').length;
              const notSelected = this.ui.selection.find('.selected-items li a.not-selected').length;

              this.ui.list.find('button.focus').removeClass('focus');
              this.ui.selection.find('.selected-items li.focus').removeClass('focus');
              this.ui.listItems.find('.selectable.selected').removeClass('selected');

              currentBtnIndex = currentBtnIndex === -1 ? 0 : currentBtnIndex;

              if (currentBtnIndex === 0 && !notSelected && selectedCount < 5) {
                if (currentSelectedIndex === (selectedCount - 1)) {
                  this.ui.list.find('button').eq((btnsCount - 1) - currentBtnIndex).addClass('focus');
                } else {
                  this.ui.selection.find('.selected-items li').eq(currentSelectedIndex + 1).addClass('focus');
                }
              } else {
                this.ui.list.find('button').eq((btnsCount - 1) - currentBtnIndex).addClass('focus');
              }
            } else {
              this.closeDropdown();
            }

            break;

          default:
            event.preventDefault();
            event.stopPropagation(); // ToDo this shouldn't be necessary but leaving in the interest of not changing logic
        }
      }
    }
  },

  /**
   * Do an item select action (it is targeted to Space bar and Enter keys).
   */
  doItemSelection() {
    if (this.ui.dropdown.hasClass('open')) {
      const $items = this.ui.listItems.find('.item-list .selectable');
      const $selectedItem = $items.filter('.selected');
      const selectedId = $selectedItem.data('value');
      if (this.options.multiSelect) {
        const selector = `[data-value="${selectedId}"]`;

        $selectedItem.find('input:checkbox').trigger('click');
        this.ui.listItems.find('.item-list .selectable').removeClass('selected');
        // Don't lose focus after clicking on item.
        this.ui.listItems.find('.item-list .selectable').filter(selector).addClass('selected');
      } else if (!$selectedItem.hasClass('parent')) {
        $selectedItem.find('a').trigger('click');
        this.ui.dropdownWarning.addClass('hide');
      } else if ($selectedItem.hasClass('parent') && !this.options.disableMultiButton) {
        this.setSelectedIds(Number(selectedId));
        this.closeDropdown(true);
        this.ui.dropdownWarning.addClass('hide');
      } else if (this.options.disableMultiButton) {
        this.ui.dropdownWarning.removeClass('hide');
      }
    }
  },

  /**
   * Fires when user clicks on webpage, outside of dropdown
   *
   * @param {event} e - DOM event
   */
  closeOnDocumentClick(e) {
    const $target = $document.find(e.target);
    if (!$target.is('.flex-dropdown') && $target.parents('.flex-dropdown').length === 0) {
      e.data.that.appBus.trigger('open.dropdown');
    }
  },

  /**
   * Loads the events tied to the dropdown
   */
  loadEvents() {
    this.unLoadEvents();
    $document.on(`click.${this.dropdownId}`, {
      that: this
    }, this.closeOnDocumentClick);
  },

  /**
   * Unloads the events tied to the dropdown
   */
  unLoadEvents() {
    $document.off(`click.${this.dropdownId}`, this.closeOnDocumentClick);
  },

  /**
   * Loads the model to be used for the Flex Dropdown
   *
   * This model has two properties, each Collections.
   *
   * items : holds the list of all items currently loaded in JS memory
   * selections: holds the list of items currently selected by the user
   *
   */
  loadModel() {
    this.modelLoaded = false;
    if (this.options.model !== undefined) {
      const CustomDropdownModel = this.options.model;
      this.model = new CustomDropdownModel(null, this.options);
    } else if (!this.options.dynamicUrl) {
      this.model = new FlexDropdownModel(null, this.options);
    } else {
      this.model = new FlexDropdownDynamicModel(null, this.options);
    }

    this.model.defaultSelectMessage = this.options.defaultSelectMessage || '';
    this.model.selectAllBtn = this.options.multiSelect ? this.options.selectAllBtn || false : false;
    this.model.showCancelIcon = this.options.showCancelIcon;
    this.model.clearBtn = this.options.clearBtn || false;

    if (this.options.data) {
      this.setData(this.options.data);
      this.handleSync();
    } else if (this.options.dynamicUrl) {
      this.model.dynamicUrl = this.options.dynamicUrl;
      this.model.fetch();
    } else if (!this.options.disabled && this.options.url) {
      this.model.url = this.options.url;
      this.model.fetch();
    }

    // Note: if options is empty {} object or objects, which has ONLY filter:true in fact has no options.data,
    // then code goes to last condition statement and expects to have options.url to fetch model data.
    // In case with Glu Scheduler, there is no need in such fetching.
    // Looks like code bug, that is why added additional condition "&& this.options.url"
  },

  /**
   * Captures key presses on the dropdown (outside of the type ahead input field)
   *
   * Distributes data and sets events when new data is loaded
   */
  dataLoadedHandler() {
    if (this.isClosed) {
      return;
    }
    this.showSelectedItems();
    this.showDropDownItems();

    if ((!this.options.disabled && !this.model.get('items').isEmpty()) || !!this.options.preSelectedNewItems) {
      this.ui.selection.removeClass('disabled');
    }

    this.listenTo(this.model.get('selection'), 'reset', util.bind(this.triggerSelectionChange, this));

    if (this.options.preSelected !== undefined) {
      this.model.processSelected(this.options.preSelected);
    } else if (this.options.hasOwnProperty('preSelectedNewItems')) {
      this.model.processSelectedText(this.options.preSelectedNewItems, this.options.addSelectedTextItem);
    }
    this.modelLoaded = true;
    this.trigger('dataLoaded', this);

    if (this.ui.dropdown.jquery) {
      let width = this.ui.dropdown.width();
      const minSelectedItemWidth = 65;
      if (!!this.options.preSelected && width < minSelectedItemWidth) {
        width = minSelectedItemWidth;
        this.ui.dropdown.width(width);
      }
    }
  },

  showSelectedItems() {
    this.selected.show(new FlexDropdownSelectionCompositeView({
      collection: this.model.get('selection'),
      showSelected: this.options.showSelected
    }));
  },

  showDropDownItems() {
    this.itemsView = new FlexDropdownItemLayout({
      collection: this.model.get('items'),
      model: this.model,
      multiSelect: this.options.multiSelect,
      disableMultiButton: this.options.disableMultiButton,
      tableView: this.options.tableView,
      columnTitles: this.options.columnTitles,
      customView: this.options.customView || false,
      customViewOptions: this.options.customViewOptions || false,
      dropDownItemView: this.options.dropDownItemView || {},
      showGroups: this.options.showGroups || false
    });

    this.listenTo(this.itemsView, 'closeOnSelection', this.closeDropdown.bind(this, true));

    this.items.show(this.itemsView);
  },

  /**
   * Sets the selected items in the list using the passed id or ids. If the list only supports a single
   * selected item then only the item indicated by the first passed in id will be selected. The list collection
   * will be reset as part of this operation and all breadcrumbs removed.
   *
   * This operation deselects ALL items and then only selects the items with the ids you passed in.
   *
   * @param {number | array | string} ids - One or more ids indicating the items to select.  A single id or an array of ids
   *        are acceptable.
   */
  setSelectedIds(ids) {
    ids = util.isArray(ids) ? ids : [ids];

    // when selecting the first item deselect everything else
    this.model.selectOne(ids[0]);

    // if we allow multiple selections continue on after the first id
    if (this.options.multiSelect && ids.length > 1) {
      for (let i = 1; i < ids.length; i++) {
        this.model.getById(ids[i]).selected = true;
      }
    }

    if (!this.itemsView) {
      return;
    }

    // reset everything back to default
    this.itemsView.resetHierarchy();
  },

  setSelectedByName(name, allLowerCase) {
    const model = this.model.getByName(name, allLowerCase);
    if (model) {
      this.model.selectOne(model.id);
    }

    // reset everything back to default
    this.itemsView.resetHierarchy();
    this.model.get('selection').reset([{
      name,
      id: null,
      selected: true
    }]);

    this.triggerSelectionChange();
  },

  /**
   *  Executes an event when the value selected changes
   */
  triggerSelectionChange() {
    // TODO the first time this runs, this.lastSelection is null and change is triggered
    // if there is a preselected value, that value should be saved at this.lastSelection to prevent false positive change events
    const curValue = this.value();
    const curValueString = JSON.stringify(curValue);
    const { allowConsecutiveSelections } = this.options;

    if (curValueString !== this.lastSelection || allowConsecutiveSelections) {
      this.trigger('selectionChanged', curValue);
      this.trigger('selectionChanged:id', this.value('id'));
      this.trigger('selectionChanged:name', this.value('name'));
      this.$el.trigger('change'); // for data-binding
      this.lastSelection = curValueString;
    }
  },

  /**
   * Scrolls to the currently selected item in the dropdown list
   */
  scrollToSelected() {
    const $items = this.ui.listItems.find('.selectable');
    const $selected = $items.filter('.selected').first();

    const defaultOffset = {
      top: 0
    };

    const selectedOffset = $selected.offset() || defaultOffset;
    const containerOffset = this.ui.listItems.offset() || defaultOffset;
    this.ui.listItems.scrollTop((selectedOffset.top - containerOffset.top) + this.ui.listItems.scrollTop());
    this.ui.dropdown.focus();
  },

  /**
   * Toggles the open state of the dropdown
   */
  toggleOpen() {
    if (this.ui.dropdown.hasClass('open') || this.ui.selection.hasClass('disabled')) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  },

  /**
   * If the item list is the same length as the selection area, add a class
   */
  setMaxWidth() {
    if (this.ui.list.outerWidth() === this.ui.dropdown.outerWidth()) {
      this.ui.dropdown.addClass('max-width');
    } else {
      this.ui.dropdown.removeClass('max-width');
    }
  },

  /**
   * Fired when dropdown is opened
   */
  openDropdown() {
    if ($document.find('.flex-dropdown.open').length > 0) {
      this.appBus.trigger('open.dropdown');
    }
    if (this.options.showTooltip === undefined || this.options.showTooltip) {
      this.hideTooltip();
    }
    // additional checking if dropDown is rendered.
    if (this.isClosed) {
      return;
    }
    this.setOpenDirection();
    this.ui.dropdown.addClass('open');
    this.setMaxWidth();
    this.scrollToSelected();

    this.ui.filter.focus();

    this.trigger('dropdownOpened', true);

    const clearBtnWidth = 65;
    if (this.options.clearBtn && this.ui.dropdown.find('.list').width() < clearBtnWidth) {
      this.ui.dropdown.width(clearBtnWidth);
      this.ui.dropdown.find('.list').width(clearBtnWidth);
    } else if (this.ui.dropdown.find('.list').width() < this.ui.dropdown.find('.item-list').width()) {
      this.ui.dropdown.find('.list').width(this.ui.dropdown.find('.item-list').width() + 20);
    }
  },

  setOpenDirection() {
    if (this.isClosed) {
      return;
    }
    const $list = this.ui.list;
    const listHeight = $list.outerHeight();
    const dropdownHeight = this.ui.dropdown.outerHeight();
    const windowTop = $window.scrollTop();
    const windowBottom = windowTop + $window.height();
    const dropdownOffset = this.ui.dropdown.offset();

    // default dropdown css properties to open in the bottom
    let cssProps = {
      top: 'auto',
      bottom: 'auto'
    };

    // check if there are place to open dropdown in the bottom
    if ((windowBottom - dropdownOffset.top - dropdownHeight) < listHeight) {
      cssProps = {
        top: 'auto',
        bottom: dropdownHeight
      };
    }

    // check if there are place to open dropdown in the top, if not then we should use default dropdown behavior
    if ((dropdownOffset.top - windowTop) < listHeight) {
      cssProps = {
        top: 'auto',
        bottom: 'auto'
      };
    }

    $list.css(cssProps);
  },

  /**
   * Fired when dropdown is closed
   */
  closeDropdown(selection) {
    if (this.isClosed || !this.ui.dropdown.jquery || !this.ui.dropdown.hasClass('open')) {
      return;
    }

    this.appBus.trigger('validateFlexDropdown', {
      dataValidate: this.options.dataValidate,
      el: this.$el
    });
    this.ui.dropdownWarning.addClass('hide');
    if (!selection && this.model.has('breadcrumb') && this.model.get('breadcrumb').length > 0 && !this.options.disableMultiButton) {
      this.itemsView.clickDone();
    } else {
      this.ui.dropdown.removeClass('open');
      this.resetFilter();
    }

    if (this.isClosed) {
      // since the start of this method, the 'change' event has been fired on the dropdown
      // if the parent view reacted to the event, the dropdown may now be closed
      return;
    }

    this.ui.dropdown.height(this.ui.dropdown.find('.flex-wrapper').height());

    this.clearFilterAutoComplete();
  },

  /**
   * Captures key presses on the type ahead input field
   *
   * Certain actions are given functionality here, others are sent to filterCollection to apply the type ahead filter
   *
   * @param {event} e - DOM event
   */
  filterKeyUp(e) {
    const { keyCode } = $.ui;
    // first item form the dropdown should be selected by default, unless the first item is already selected
    let nextItemToSelect = 0;
    let element;

    if (e.keyCode === keyCode.DOWN) {
      // if down is pressed go to first item in dropdown
      element = this.ui.listItems.find('li:has(span)');

      if (element.eq(0).hasClass('selected')) {
        nextItemToSelect = 1;
      }

      this.ui.listItems.find('li').removeClass('selected');
      element.eq(nextItemToSelect).addClass('selected');
      this.scrollToSelected();
      this.ui.dropdown.focus();
      this.clearFilterAutoComplete();
      this.filterAutoComplete(false);
    } else if (e.keyCode === keyCode.ESCAPE) {
      // esc key
      this.closeDropdown();
      this.ui.dropdown.focus();
      this.clearFilterAutoComplete();
    } else if ((e.keyCode === keyCode.ENTER && this.itemsView.filterText && this.itemsView.collection.length === 1)) {
      // enter key
      // the filtered results we want to allow the user to select it, without leaving the filter, by pressing the enter key
      // thus we need 1 item in collection and we need to have some filter text.
      // FlexDropdownItemCompositeView takes care of highlighting the single item for us so all we need to do is select it.
      this.itemsView.selectSingleFilteredItemResult();

      // TODO we really need a boolean on this class to indicate if we are open or not
      // but openDropdown and closeDropdown are not even called all the time so that needs fixing first
      if (this.ui.dropdown.hasClass('open')) {
        this.ui.filter.val('');
        this.filterCollection();
      } else {
        this.ui.dropdown.focus();
      }
      this.clearFilterAutoComplete();
    } else {
      this.filterCollection();
    }
  },

  /**
   * Captures key down presses on the type ahead input field
   *
   * @param {event} e - DOM event
   */
  filterKeyDown(e) {
    const { keyCode } = $.ui;

    if (e.keyCode === keyCode.TAB) {
      // tab key
      e.preventDefault();

      this.ui.list.find('button.clear').eq(0).addClass('focus');
      this.ui.dropdown.focus();
    }

    if (e.keyCode === keyCode.ENTER) {
      this.doItemSelection();
    }

    if (e.keyCode === keyCode.BACKSPACE) {
      this.filterCollection();
    }
  },

  /**
   * Filters the items collection to add hide: true to any items which do not match the value of the type ahead dropdown
   */
  filterCollection() {
    // here is checking if filter input is exist.
    // for cases when we are using custom view with input.
    if (this.ui.filter.jquery && !this.ui.filter.length) {
      return;
    }

    const val = this.ui.filter.val().toLowerCase();
    let filterText = val;
    let shownItems;

    if (this.itemsView !== undefined) {
      if (this.model.dynamic) {
        filterText = val.replace('\'', '\'\'');
      }

      this.itemsView.filterCollection(filterText);
      shownItems = util.where(this.model.data, {
        hide: false
      });
      this.highlightItems(shownItems, filterText);
    }
  },

  highlightItems(shownItems, filterText) {
    if (!filterText) {
      this.clearFilterAutoComplete();
      return;
    }

    let uiItem;
    let value;
    let boldValue;
    let newValue;
    let valueToCompare;
    let boldValueIndex;

    filterText = util.escape(filterText); // GLU-1169

    util.each(shownItems, (item) => {
      uiItem = this.ui.listItems.find(`[data-value="${item.id}"] span`);
      value = util.escape(uiItem.text().trim());
      valueToCompare = value.toLowerCase();
      boldValueIndex = valueToCompare.indexOf(filterText);
      boldValue = value.substr(boldValueIndex, filterText.length).bold();

      if (boldValueIndex !== 0 && (boldValueIndex + filterText.length) < value.length) {
        newValue = value.substr(0, boldValueIndex) + boldValue + value.substr((boldValueIndex + filterText.length), value.length);
      } else if (boldValueIndex === 0) {
        newValue = boldValue + value.substr(filterText.length, value.length);
      } else {
        newValue = value.substr(0, boldValueIndex) + boldValue;
      }

      uiItem.html(newValue);
    });

    this.ui.listItems.find('li').removeClass('selected');
    this.ui.listItems.find('li:has(span)').eq(0).addClass('selected');

    this.filterAutoComplete();
  },

  filterAutoComplete(isHighlighted) {
    if (!(this.ui.filter && this.ui.filter.length)) {
      return;
    }

    const selectedItemText = this.ui.listItems.find('li.selected span').text().trim();
    const shouldBeHighlighted = typeof isHighlighted === 'boolean' ? isHighlighted : true;
    // using this instead of filterText to get value in actual case
    const filterInputValue = this.ui.filter.val();
    const startsWithFilterText = selectedItemText.toLowerCase().indexOf(filterInputValue.toLowerCase()) === 0;
    const $filterAutocomplete = this.ui.filterAutocomplete;

    if (!startsWithFilterText && shouldBeHighlighted) {
      this.clearFilterAutoComplete();
      return;
    }

    $filterAutocomplete.removeClass('hidden');

    if (!shouldBeHighlighted) {
      $filterAutocomplete.find('.preselected-value').text(selectedItemText);
      return;
    }

    $filterAutocomplete.find('.filter-text').text(filterInputValue);
    $filterAutocomplete.find('.highlighted-autocomplete').text(selectedItemText.substr(filterInputValue.length, selectedItemText.length));
  },

  clearFilterAutoComplete() {
    const $filterAutocomplete = this.ui.filterAutocomplete;

    $filterAutocomplete.find('.filter-text').text('');
    $filterAutocomplete.find('.highlighted-autocomplete').text('');
    $filterAutocomplete.find('.preselected-value').text('');
    $filterAutocomplete.addClass('hidden');
  },

  /**
   * Removes filter on item collection
   */
  resetFilter() {
    if (this.options.filter) {
      this.ui.filter.val('');
    }

    if (this.itemsView !== undefined && !this.isSelectedOriginalTextItem()) {
      this.itemsView.resetFilter();
    }
  },

  /**
   * Check if addSelectedTextItem setting is false and user has not select any item from the list yet
   */
  isSelectedOriginalTextItem() {
    if (!!this.options.preSelectedNewItems && this.options.addSelectedTextItem === false) {
      const selection = this.model.get('selection');
      if (selection !== undefined) {
        let isSelectedOriginalTextItem = true;
        util.each(selection.toJSON(), (item) => {
          // if it is not the default selection
          if (item.id !== null) {
            isSelectedOriginalTextItem = false;
          }
        });
        return isSelectedOriginalTextItem;
      }
    }
    return false;
  },

  /**
   * Resets all selected items on item collection. For a hierarchical dropdown will clears out current hierarchy level selection.
   * If dropdown is closed and focus is on the dropdown, clear all selected items.
   * Hides tooltip since there is no selection.
   */
  resetSelected() {
    this.itemsView.clearSelected(!this.ui.dropdown.hasClass('open'));
    if (this.options.showTooltip === undefined || this.options.showTooltip) {
      this.hideTooltip();
    }
    if (this.options.onReset !== undefined) {
      this.options.onReset();
    }
  },

  /**
   * Applies selected class to item currently being hovered over
   *
   * @param {event} e - DOM event
   */
  mouseOverItem(e) {
    if (this.ui.listItems.hasClass('keydown')) {
      this.ui.listItems.removeClass('keydown');
      return;
    }
    if (!this.options.multiSelect) {
      clearTimeout(this.highlightOn);
    }
    this.ui.listItems.find('.item-list .selectable.selected').removeClass('selected');
    this.$(e.currentTarget).addClass('selected');
  },

  /**
   * Removes selected class from item currently being hovered off
   *
   * @param {event} e - DOM event
   */
  mouseOutItem(e) {
    const that = this;

    this.$(e.currentTarget).removeClass('selected');

    if (!this.options.multiSelect) {
      clearTimeout(this.highlightOn);
      this.highlightOn = setTimeout(() => {
        that.ui.listItems.find('.item-list').find('.on').addClass('selected');
      }, 10);
    }
  },

  /**
   * Returns the value of the selections collection.
   *
   * Returns the toJSON output of the collection by default
   *
   * @param {string} property - Optional. If defined, the return will be an array containing the values of the property name sent in.
   *                              Ex: .value('id') returns ['2332','44343','46664']
   */
  value(property) {
    const selection = this.model.get('selection');
    property = property || false;

    if (selection !== undefined) {
      if (property !== false) {
        const items = [];
        util.each(selection.toJSON(), (item) => {
          // if it is not the default selection
          if (item.id !== null) {
            items.push(item[property]);
          }
        });

        return items;
      }
      return this.model.get('selection').toJSON();
    }
    return [];
  },

  unsetSelectedIds(ids) {
    ids = util.isArray(ids) ? ids : [ids];

    // toggle selection
    this.model.toggleSelected(ids[0]);

    // if we allow multiple selections continue on after the first id
    if (this.options.multiSelect && ids.length > 1) {
      for (let i = 1; i < ids.length; i++) {
        this.model.getById(ids[i]).selected = true;
      }
    }

    // reset everything back to default
    this.itemsView.resetHierarchy();
  },

  clickCancel(e) {
    e.preventDefault();
    e.stopPropagation();
    // GLU-1061 fix fo Glu 2.x require to change selector
    if (this.$(e.currentTarget).find('.flex-dropdown-remove-item').length === 0) {
      const $currentItem = this.$(e.currentTarget.parentElement);
      const currentItemId = $currentItem.data('value');

      this.unsetSelectedIds(currentItemId);
    }
    this.toggleOpen();
    this.ui.dropdown.focus();
  },

  /**
   * Checks to see if the selected area is actually showing the item(s) selected or if a count is showing instead.
   *
   * @returns {boolean} - True if the selected area is showing the actual selected items, false otherwise.
   */
  isSelectedShowing() {
    if (!this.selected.currentView) {
      return true;
    }
    return !!this.selected.currentView.selectedShowing;
  },

  setData(data) {
    this.model.setData(data);
  }
});
