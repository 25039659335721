import CollectionView from '@glu/core/src/collectionView';
import GroupListView from './paymentGroup';

export default CollectionView.extend({
    tagName: 'div',
    itemView: GroupListView,

    initialize(options) {
        this.itemViewOptions = {
            mode: options.mode,
            modifyMode: options.modifyMode || false,
        };
    },
});
