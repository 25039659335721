import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import accountViewTmpl from './accountView.hbs';

const AccountView = ItemView.extend({
    template: accountViewTmpl,
    tagName: 'li',
    className: 'list-builder-item',
    initialize() {
        this.listenTo(this.model, 'change:groupCount', this.render);
        this.listenTo(this.model, 'change:searchFilter', this.refreshStatesAndClasses);
    },
    events: {
        'click input': 'toggleSelected',
    },
    attributes() {
        return {
            'data-account-filter': this.model.accountFilter(),
            'data-section-id': this.model.sectionId(),
        };
    },
    templateHelpers() {
        return {
            id: this.cid,
            hasCheckbox: true,
            isChecked: this.model.get('isSelected'),
            name: this.model.name(),
            accountFilter: this.model.accountFilter(),
            accountNumber: this.model.accountNumberDisplay(),
            groupCount: this.model.groupCount(),
            showGroupCount: this.model.groupCount() > 0,
            dynamicTitle: locale.get('gir.manageAccountGroups.deleteAccount', this.model.name()),
        };
    },
    toggleSelected() {
        this.model.set('isSelected', this.$('input[type=checkbox]').prop('checked'));
    },

    refreshStatesAndClasses() {
        this.$el.toggleClass('is-filtered-out', !this.model.matchesFilter());
    },
});

export default AccountView;
