import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@glu/indicators-react';
import useCache from 'hooks/useCache';
import useNavigation from 'hooks/useNavigation';
import FooterContext from '../FooterWrapper/FooterContext';
import {
    getFooter,
    getLocales,
    getFooterMarketSegments,
    insertFooter,
    updateFooter,
} from '../services';
import { MODE } from '../constants';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const STEP = {
    SUMMARY: 'SUMMARY',
    DETAIL: 'DETAIL',
};

const Footer = ({
    ...props
}) => {
    const {
        mode,
        setLocaleOptions,
        setFooterData,
        setFooterParameters,
        setMarketSegmentOptions,
        modelData,
    } = useContext(FooterContext);
    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(mode === MODE.INSERT ? STEP.SUMMARY : STEP.DETAIL);
    const StepComponent = step === STEP.SUMMARY ? StepOne : StepTwo;
    const nextStep = (locale) => {
        getFooter({
            LOCALEID: locale,
        }).then((footerResp) => {
            setFooterData(footerResp);
            setStep(STEP.DETAIL);
        });
    };
    const { navigateTo } = useNavigation(props.returnRoute);
    const { set: setFooterMessage } = useCache('alertContentManagement_footers-alertMessage');
    const { set: setConfirmResponse } = useCache('alertContentManagement_footers-confirms');
    const save = useCallback((data) => {
        const saveFooter = mode === MODE.MODIFY ? updateFooter : insertFooter;
        saveFooter(data).then((resp) => {
            setFooterMessage('insert');
            setConfirmResponse(resp);
            navigateTo(props.returnRoute);
        });
    }, [mode, navigateTo, props.returnRoute, setFooterMessage, setConfirmResponse]);
    useEffect(() => {
        // Each mode requires a different set of initial data, fetch what is appropriate
        switch (mode) {
        case MODE.INSERT:
            Promise.all([
                getLocales().then(setLocaleOptions),
            ]).then(() => {
                setIsLoading(false);
            });
            break;
        case MODE.MODIFY:
        case MODE.COPY:
            Promise.all([
                getFooter(modelData),
                getFooterMarketSegments({
                    ...modelData,
                    mode,
                }),
            ]).then(([footerResp, marksegmentResp]) => {
                setMarketSegmentOptions(marksegmentResp);
                setFooterData({
                    ...footerResp,
                    // Don't preselect marketsegements in COPY mode
                    MARKETSEGMENTS: mode === MODE.MODIFY ? footerResp.MARKETSEGMENTS : undefined,
                    CONTENTTYPE_DESC: modelData.CONTENTTYPE,
                    LOCALE_DESC: modelData.LOCALE,
                    STATUS: modelData.STATUS,
                });
                setIsLoading(false);
            });
            break;
        default:
            setIsLoading(false);
            break;
        }
    }, [
        mode,
        modelData,
        setFooterData,
        setLocaleOptions,
        setMarketSegmentOptions,
        setFooterParameters,
    ]);
    return (
        <Loading isLoading={isLoading}>
            <StepComponent
                nextStep={nextStep}
                onSave={save}
                {...props}
            />
        </Loading>
    );
};

Footer.propTypes = {
    returnRoute: PropTypes.string.isRequired,
};

export default Footer;
