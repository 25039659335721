var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n        <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"handleBack\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToFileImportList",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":148},"end":{"line":3,"column":196}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"fih.title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":56}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"getFileName") || (depth0 != null ? lookupProperty(depth0,"getFileName") : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias1,{"name":"getFileName","hash":{},"data":data,"loc":{"start":{"line":5,"column":57},"end":{"line":5,"column":72}}}) : helper)))
    + "</h1>\n</div>\n<div class=\"page\">\n        <div class=\"section section-container\">\n                <div class=\"section-body\">\n\n                        <div class=\"panel panel-tertiary\" role=\"tablist\" id=\"failedRecordsPanel\">\n                                <div class=\"panel-heading\" role=\"tab\" id=\"failedRecordsHeading\">\n                                  <h2 class=\"panel-title\">\n                                        <a role=\"button\" data-toggle=\"collapse\" href=\"#panel-failedRecords\" aria-expanded=\"true\" aria-controls=\"panel-failedRecords\">\n                                                <span class=\"indicator-icon\"></span> "
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"fih.failedRecords",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":15,"column":85},"end":{"line":15,"column":118}}}))
    + " (<div style=\"display: inline-block\" id=\"failedCount\"></div>)\n                                        </a>\n                                  </h2>\n                                </div>\n                                <div id=\"panel-failedRecords\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"failedRecordsHeading\">\n                                  <div class=\"panel-body\" data-region=\"failedRecords\">\n                                  </div>\n                                </div>\n                        </div>\n\n                    <div class=\"alert alert-warning hide\" role=\"alert\">Warnings go here</div>\n\n                        <div class=\"panel panel-tertiary\" role=\"tablist\" id=\"successfulImportsPanel\" style=\"display: none;\">\n                                <div class=\"panel-heading\" role=\"tab\" id=\"successfulImportsHeading\">\n                                  <h2 class=\"panel-title\">\n                                        <a role=\"button\" data-toggle=\"collapse\" href=\"#panel-successfulImports\" aria-expanded=\"true\" aria-controls=\"panel-successfulImports\">\n                                                <span class=\"indicator-icon\"></span> "
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias3).call(alias1,"fih.successfulImports",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":31,"column":85},"end":{"line":31,"column":122}}}))
    + " (<div style=\"display: inline-block\" id=\"successCount\"></div>)\n                                        </a>\n                                  </h2>\n                                </div>\n                                <div id=\"panel-successfulImports\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"successfulImportsHeading\">\n                                  <div class=\"panel-body\" data-region=\"successfulImports\">\n                                  </div>\n                                </div>\n                        </div>\n                </div>\n        </div>\n</div>\n";
},"useData":true});