import ItemView from '@glu/core/src/itemView';
import CollectionView from '@glu/core/src/collectionView';
import GroupedAccountView from 'app/accounts/views/groupedAccountView';
import AccountGroupHeaderView from 'app/accounts/views/accountGroupHeaderView';
import accountGroupViewTmpl from './accountGroupView.hbs';

const AccountGroupView = ItemView.extend({

    template: accountGroupViewTmpl,

    tagName: 'li',

    className: 'account-group panel panel-primary',

    attributes() {
        return {
            'data-group-id': this.model.cid,
            'data-section-id': this.model.sectionId(),
        };
    },

    initialize() {
        this.accountCollectionView = new CollectionView({
            collection: this.model.get('accounts'),
            itemView: GroupedAccountView,
        });

        this.headerView = new AccountGroupHeaderView({
            model: this.model,
        });

        this.listenTo(this.model, 'change', this.refreshStatesAndClasses);
    },

    templateHelpers() {
        return {
            numberOfAccountsInGroup: this.model.get('accounts').length,
            collapseId: `collapse-${this.model.cid}`,
        };
    },

    refreshStatesAndClasses() {
        const group = this.model;
        if (this.model.collection) {
            this.model.collection.each((model) => {
                if (group.get('NAME') === model.get('NAME') && group.cid !== model.cid) {
                    group.set('NAME', group.previous('NAME'));
                }
            });
        }
        this.$el.toggleClass('is-marked-for-deletion', this.model.isMarkedForDeletion());
    },

    onRender() {
        this.accountCollectionView.setElement(this.$('.account-list')).render();
        this.headerView.setElement(this.$('.panel-heading')).render();
    },
});

export default AccountGroupView;
