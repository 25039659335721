import InquiryCollection from 'common/inquiry/inquiryCollection';

export default InquiryCollection.extend({
    requestBody() {
        return {
            requestHeader: {

            },

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'SEARCH',
                        entryMethod: 0,
                        actionMode: 'SEARCH',
                        functionCode: 'INQ',
                        productCode: '_UTIL',
                    },

                    inquiryId: 9034,
                    searchType: '5',
                },
            },
        };
    },
});
