export default function (form) {
    const formState = form.formView.state;
    const codetable = form.field('CODETABLE');
    const code = form.field('CODE');

    if (formState === 'modify') {
        codetable.shouldBeReadOnly(true);
        code.shouldBeReadOnly(true);
    }
}
