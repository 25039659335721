var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img src=\"data:"
    + alias4(((helper = (helper = lookupProperty(helpers,"IMAGETYPE") || (depth0 != null ? lookupProperty(depth0,"IMAGETYPE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IMAGETYPE","hash":{},"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":3,"column":38}}}) : helper)))
    + ";base64,"
    + alias4(((helper = (helper = lookupProperty(helpers,"STATEMENTIMAGE") || (depth0 != null ? lookupProperty(depth0,"STATEMENTIMAGE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STATEMENTIMAGE","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":64}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"IMAGELABEL") || (depth0 != null ? lookupProperty(depth0,"IMAGELABEL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IMAGELABEL","hash":{},"data":data,"loc":{"start":{"line":3,"column":71},"end":{"line":3,"column":87}}}) : helper)))
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.billPay.noImageAvailable",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":53}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"STATEMENTIMAGE") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});