import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import userInfo from 'etc/userInfo';
import services from 'services';

const LocaleModel = Model.extend({
    sync(method, model, options) {
        if (method === 'read') {
            if (this.fetchList) {
                const data = {
                    locale: (!util.isNullOrUndefined(options.locale))
                        ? options.locale : userInfo.getLocale(),
                    prefixes: this.fetchList,
                };

                http.post(services.localization, data, (result) => {
                    options.success(result);
                }, (result) => {
                    options.error(result);
                });
            } else if (this.resType) {
                if (this.resType === 'LOGIN_RES') {
                    // Support passing a locale for security questions
                    const query = options.locale ? `?locale=${options.locale}` : '';
                    const url = `${services.localizationLoginResources}${query}`;
                    http.get(url, (result) => {
                        options.success(result);
                    }, (result) => {
                        options.error(result);
                    });
                } else {
                    http.get(`${services.localizationAllResources}/${options.locale || userInfo.getLocale()}`, (result) => {
                        options.success(result);
                    }, (result) => {
                        options.error(result);
                    });
                }
            }
        } else if (method === 'loadLocale') {
            const userPath = `${window.Bottomline.assetRoot}/locale/${window.Bottomline.buildId}/content-${userInfo.getLocale()}.json`; // en-US

            http.get(userPath, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        } else if (method === 'reload') {
            http.get(`${services.localizationAllResources}/${options.locale}`, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
    },

    loadLocale(options) {
        this.sync('loadLocale', this, options);
    },

    parse(jsonData) {
        const retData = {};
        const appResource = jsonData?.applicationResource;
        if (appResource) {
            for (let x = 0; x < appResource.length; x += 1) {
                retData[appResource[x].key] = appResource[x].value;
            }
        }
        return retData;
    },

    getLoginResources(options) {
        this.resType = 'LOGIN_RES';
        this.fetch(options);
    },

    getAllResources(options) {
        this.resType = 'ALL_RES';
        this.fetch(options);
    },

    getLocales(prefixes, options) {
        this.fetchList = prefixes;
        this.fetch(options);
    },

    getLocale(prefix, options) {
        this.getLocales([prefix], options);
    },

    clearFetchList() {
        this.fetchList = undefined;
    },
});

export default new LocaleModel();
