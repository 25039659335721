import { appBus } from '@glu/core';
import dialog from '@glu/dialog';
import RfpChooseTemplate from 'components/RfpChooseTemplate/RfpChooseTemplate';
import RtpReactWorkspace from 'components/Rtp/Rtp';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import React from 'react';
import systemConfig from 'system/configuration';
import userInfo from 'etc/userInfo';
import AddMultiTransfer from 'app/payments/multiTransfer/views/addMultiTransfer';
import AddPaymentView from 'app/payments/views/addPayment';
import AddTemplateView from 'app/payments/views/addTemplate';
import CheckDetail from 'app/payments/views/checkInquiry/checkDetail';
import CheckFreeView from 'app/payments/checkFree/view/checkFreeSSOView';
import CheckInquiryTabsView from 'app/payments/views/checkInquiry/checkInquiryTabs';
import CheckInquiryView from 'app/payments/views/checkInquiry/checkInquiry';
import CheckViewer from 'common/checkViewer/checkViewer';
import contextApi from 'common/dynamicPages/api/context';
import { isEntitled, getErrorView } from 'common/util/deeplinkUtil';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import ExceptionReport from 'app/payments/views/exceptionReport';
import IframeView from 'app/utilities/sso/ssoView';
import InquirySearchService from 'app/payments/checkFree/service/inquirySearch';
import LargeBatchListView from 'app/payments/views/largeBatch';
import ManageTemplateGroupsView from 'app/payments/views/manageTemplateGroups';
import ModifyPayment from 'app/payments/views/modifyPayment';
import ModifyTemplate from 'app/payments/views/modifyTemplate';
import ModifyvFImport from 'common/dynamicPages/views/workflow/templateModifyvFImport';
import PassThroughDetailsView from 'app/payments/views/passthroughDetail';
import PaymentsFromTemplateView from 'app/payments/views/paymentFromTemplate';
import PaymentsListView from 'app/payments/views/listPayments';
import QuickEntryView from 'app/payments/views/quickEntry/listControlView';
import QuickTransferWidgetView from 'app/payments/views/quickTransferWidgetView';
import RapidWireEntry from 'app/payments/views/rapidWireEntry';
import RDCCollection from 'app/payments/collections/rdcLogin';
import RDCModel from 'app/payments/models/rdcLogin';
import RDCView from 'app/payments/views/rdcLogin';
import ReceivedRfpEntryView from 'app/payments/views/requestForPayments/receivedRfpEntry';
import ReceivedRfpEntryViewAdmin from 'app/payments/views/requestForPayments/receivedRfpEntryAdmin';
import RegisterForm from 'app/payments/checkFree/view/registerForm';
import RepairPayment from 'app/payments/views/repairPayment';
import RepairTemplate from 'app/payments/views/repairTemplate';
import RequestFromTemplateView from 'app/payments/views/requestForPayments/requestFromTemplate';
import RequestPhotocopy from 'app/payments/views/photocopy/reqPhotocopy';
import RestoreTemplate from 'app/payments/views/restoreTemplate';
import RestoreTemplateRFP from 'app/payments/views/requestForPayments/restoreTemplateRFP';
import ReversePayment from 'app/payments/views/reversePayment';
import RfpEntryView from 'app/payments/views/requestForPayments/rfpEntry';
import RfpTemplateEntryView from 'app/payments/views/requestForPayments/rfpTemplateEntry';
import RfpWorkspaceView from 'app/payments/views/realTimePayments/rfpMainView';
import RtpMultiAddView from 'app/payments/views/rtpMultiAddView';
import RtpPayments from 'app/payments/collections/rtpPayments';
import RtpWorkspaceView from 'app/payments/views/realTimePayments/rtpMainView';
import SchedulePayment from 'app/payments/views/schedulePayment';
import SimpleEntryWidgetView from 'app/payments/views/simpleEntryWidget';
import SmbStopPaymentsListView from 'app/smbPayments/views/stopPaymentGrid';
import ssoApi from 'app/utilities/sso/ssoApi';
import SSORetryView from 'app/utilities/sso/ssoRetryView';
import StackView from 'common/stack/views/stackView';
import StopCancelListViewMgmt from 'app/checkManagement/views/stopPaymentsManagement';
import StopsListView from 'app/checkManagement/views/listStops';
import store from 'system/utilities/cache';
import TemplateFromPaymentView from 'app/payments/views/templateFromPayment';
import TemplatesListView from 'app/payments/views/listTemplates';
import ViewPayment from 'app/payments/views/viewPayment';
import ViewTemplate from 'app/payments/views/viewTemplate';
import WorkspaceView from 'common/workspaces/views/workspace';
import TemplateFromRequestView from 'app/payments/views/requestForPayments/templateFromRequest';
import actionFunction from 'app/payments/api/common';
import loansActions from 'app/loans/actions';
import transfersActions from 'app/transfers/actions';
// PCM related
import withPCM from 'pcmMdf/app/payments/pcmActions'; //eslint-disable-line
import { asView } from '../../common/util/reactUtil';

export default withPCM({
    pmtListPayments(viewId) {
        if (this.processDeeplink('PAYMENTS/listPayments')) {
            this.showPage('', new PaymentsListView({
                viewId,
            }));
        }
    },

    pmtListTemplates(viewId) {
        if (this.processDeeplink('PAYMENTS/listTemplates')) {
            this.showPage('', new TemplatesListView({
                viewId,
            }));
        }
    },

    pmtListStops(viewId) {
        const deeplinkRoute = userInfo.isSmbUser() ? 'PAYMENTS/listSmbStops' : 'PAYMENTS/listStops';
        let stopsView;
        let deeplinkData;

        if (this.processDeeplink(deeplinkRoute)) {
            deeplinkData = {
                viewId,
                returnRoute: deeplinkRoute,
            };
            stopsView = (userInfo.isSmbUser() ?
                new SmbStopPaymentsListView(deeplinkData) :
                new StopsListView(deeplinkData));
            this.showPage('', stopsView);
        }
    },

    pmtListStopPaymentsList(viewId) {
        const deeplinkRoute = userInfo.isSmbUser() ?
            'PAYMENTS/listSmbStops' : 'PAYMENTS/listStops';
        let stopsView;
        let deeplinkData;

        if (this.processDeeplink(deeplinkRoute)) {
            deeplinkData = {
                viewId,
                returnRoute: deeplinkRoute,
            };
            stopsView = userInfo.isSmbUser() ?
                new SmbStopPaymentsListView(deeplinkData) :
                new StopCancelListViewMgmt(deeplinkData);
            this.showPage('', stopsView);
        }
    },

    pmtViewImage() {
        let checks;

        if (store.has('cminst_cancelStop-actionModels')) {
            checks = store.remove('cminst_cancelStop-actionModels');
        } else if (store.has('cminst_cancelStop-actionModel')) {
            checks = [store.remove('cminst_cancelStop-actionModel')];
        } else {
            // No checks found, redirect to fraud control main page
            appBus.trigger('router:navigate', 'PAYMENTS/checkInquiry', true);
            return;
        }

        this.pushPage(
            locale.get('payment.view.image'),
            new CheckViewer({
                mode: 'view',
                checks,
                entryClass: CheckDetail,

                entryCfg: {
                    returnRoute: this.lastFragment,
                },
            }),
        );
    },

    pmtInsertStopCancels() {
        this.pushPage(
            locale.get('payment.insert.stop.cancel'),
            new CheckDetail({
                model: store.get('cminst_cancelStop-actionModel'),
            }),
        );
    },

    addPaymentFromTemplate() {
        const contextTemp = store.get('payment_listView_corp-contextOverride');

        if (!contextTemp) {
            return;
        }

        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
        };
        this.showPage(locale.get('payment.create'), new PaymentsFromTemplateView(util.extend(
            options,
            {
                mode: 'insert',
            },
        )));
    },

    addRequestFromTemplate(functionCode) {
        const key = (functionCode === 'REQUEST')
            ? 'requestForPayment_outgoingPaymentRequests'
            : 'requestTemplate_outgoingPaymentRequestTemplates';
        const contextTemp = store.get(`${key}-contextOverride`);

        if (!contextTemp) {
            return;
        }
        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'RFP',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
        };
        this.showPage(locale.get('payment.create'), new RequestFromTemplateView(util.extend(
            options,
            {
                mode: 'insert',
            },
        )));
    },

    addTemplateFromPayment() {
        const contextTemp = store.get('template_listView_corp-contextOverride');

        if (!contextTemp) {
            return;
        }

        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'TEMP_LIST_VIEW',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
        };
        this.showPage('Create Template', new TemplateFromPaymentView(util.extend(
            options,
            {
                mode: 'insert',
            },
        )));
    },

    addTemplateFromRequest(functionCode) {
        const key = (functionCode === 'REQUEST')
            ? 'requestForPayment_outgoingPaymentRequests'
            : 'requestTemplate_outgoingPaymentRequestTemplates';
        const contextTemp = store.get(`${key}-contextOverride`);

        if (!contextTemp) {
            return;
        }
        const options = {
            menuCategory: 'PMTS',
            serviceName: contextTemp.serviceName,
            serviceFunc: null,
            businessType: null,
            context: 'RFP',
            templateServiceName: contextTemp.templateServiceName,
            tnum: contextTemp.tnum,
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            updateCount: contextTemp.updateCount,
        };
        this.showPage(locale.get('payment.create'), new TemplateFromRequestView(util.extend(
            options,
            {
                mode: 'insert',
            },
        )));
    },

    pmtAddTransferManyToOne() {
        const contextTemp = store.get('payment_listView_corp-contextOverride');
        const options = {
            serviceName: `${contextTemp.serviceName}/multi/ADD`,
            context: 'PAY_LIST_VIEW',
            productCode: 'RTGS',
            typeCode: 'TRANSFER',
            functionCode: contextTemp.functionCode,
            templateEntryMethod: contextTemp.templateEntryMethod,
            subType: contextTemp.subType,
            mode: 'insert',
        };
        this.showPage('', new AddMultiTransfer(options));
    },

    pmtAddTransferOneToMany() {
        const contextTemp = store.get('payment_listView_corp-contextOverride');
        const options = {
            serviceName: `${contextTemp.serviceName}/multi/ADD`,
            context: 'PAY_LIST_VIEW',
            productCode: 'RTGS',
            typeCode: 'TRANSFER',
            functionCode: contextTemp.functionCode,
            subType: contextTemp.subType,
            mode: 'insert',
        };
        this.showPage('', new AddMultiTransfer(options));
    },

    impModifyvFImport() {
        const options = {
            serviceName: '/template/listView/corp',
            productCode: 'USACH',
            functionCode: 'IMPORT',
            mode: 'import',
        };
        this.showPage('', new ModifyvFImport(options));
    },

    pmtReqPhotoCopy() {
        this.pushPage(
            '',
            new RequestPhotocopy(),
        );
    },

    pmtWorkspace() {
        this.showPage('', new WorkspaceView({
            id: 'PAYMENTCENTER',
            returnRoute: 'PAYMENTS/managePayments',
        }));
    },

    pmtTransfersWorkspace() {
        this.showPage('', new WorkspaceView({
            id: 'TRANSFERS',
            returnRoute: 'PAYMENTS/manageTransfers',
        }));
    },

    pmtCheckManagementWorkspace() {
        this.showPage('', new WorkspaceView({
            id: 'CHECK_MANAGEMENT',
            returnRoute: 'PAYMENTS/checkManagement',
        }));
    },

    pmtListStopsWorkspace() {
        this.showPage('', new WorkspaceView({
            id: 'STOP_PAYMENTS',
            returnRoute: 'PAYMENTS/manageStops',
        }));
    },

    pmtRealTimePaymentsWorkspace() {
        // eslint-disable-next-line
        this.showPage('', <RtpReactWorkspace />);
    },

    pmtRealTimePayments(viewId) {
        if (this.processDeeplink('PAYMENTS/realTimePaymentsWidget')) {
            this.showPage('', new RtpWorkspaceView({
                viewId,
            }));
        }
    },
    pmtRequestForPaymentWidget(view) {
        if (this.processDeeplink('PAYMENTS/requestForPaymentWidget')) {
            this.showPage('', new RfpWorkspaceView({
                viewId: view,
            }));
        }
    },
    pmtReceivedRFPNotificationList(view) {
        if (this.processDeeplink('PAYMENTS/requestForPaymentWidget')) {
            this.showPage('', new RfpWorkspaceView({
                receivedRFPViewId: view,
                openAccordion: 'rfpReceived',
                singleAccordionOpen: true,
                returnRoute: 'PAYMENTS/receivedRFPNotificationList',
            }));
        }
    },
    pmtRequestForPayment() {
        const options = {
            serviceName: '/requestForPayment/outgoingPaymentRequests',
            productCode: 'RTP',
            functionCode: 'REQUEST',
            mode: 'insert',
        };
        // When current view is already in a stack
        if (this.isCurrentViewStack()) {
            this.pushPage('', new RfpEntryView(options));
        } else {
            this.showPage(
                '',
                new StackView({
                    initItem: new RfpEntryView(options),
                }),
            );
        }
    },
    pmtReceivedRequestForPay() {
        const options = {
            serviceName: '/requestForPayment/requestReceived',
            productCode: 'RTP',
            functionCode: 'REQUEST',
            mode: 'view',
        };
        this.showPage('', (systemConfig.isAdmin() ?
            new ReceivedRfpEntryViewAdmin(options) : new EntryView(options)));
    },
    pmtPayRequestForPay() {
        const options = {
            serviceName: '/requestForPayment/requestReceived',
            productCode: 'RTP',
            functionCode: 'REQUEST',
            mode: 'view',
            action: 'pay',
        };
        this.showPage('', new ReceivedRfpEntryView(options));
    },
    pmtViewSubmittedRequestForPayment() {
        const options = {
            serviceName: '/requestForPayment/outgoingPaymentRequests',
            productCode: 'RTP',
            functionCode: 'REQUEST',
            mode: 'view',
            comboService: systemConfig.isAdmin() ? 'requestForPayment/outgoingPaymentRequests/getQueryResults'
                : null,
            auditExportInquiryId: 19038,
        };
        this.showPage('', new RfpEntryView(options));
    },
    pmtViewSubmittedRequestForPaymentTemplate() {
        const options = {
            serviceName: '/requestTemplate/outgoingPaymentRequestTemplates',
            productCode: 'RTP',
            functionCode: 'REQTMPL',
            mode: 'view',
            comboService: systemConfig.isAdmin() ? 'requestTemplate/outgoingPaymentRequestTemplates/getQueryResults'
                : null,
            auditExportInquiryId: 19038,
        };
        this.showPage('', new RfpTemplateEntryView(options));
    },
    pmtModifySubmittedRequestForPayment() {
        const options = {
            serviceName: '/requestForPayment/outgoingPaymentRequests',
            mode: 'modify',
        };
        this.showPage('', new RfpEntryView(options));
    },
    pmtModifySubmittedRequestForPaymentTemplate() {
        const options = {
            serviceName: '/requestTemplate/outgoingPaymentRequestTemplates',
            mode: 'modify',
        };
        this.showPage('', new RfpTemplateEntryView(options));
    },
    requestForPaymentTemplate() {
        const options = {
            serviceName: '/requestTemplate/outgoingPaymentRequestTemplates',
            productCode: 'RTP',
            functionCode: 'REQTMPL',
            mode: 'insert',
            contextDef: {
                functionCode: 'REQTMPL',
            },
        };
        this.showPage('', new RfpTemplateEntryView(options));
    },

    rfpChoseTemplate() {
        const options = {
            returnRoute: 'PAYMENTS/requestForPayment',
            submit: (tnum) => {
                if (!tnum) {
                    return;
                }
                const model = new Model();
                model.set({
                    TNUM: tnum,
                    FUNCTION: 'REQTMPL',
                });
                model.loadFromPayments = true;
                model.context = {
                    serviceName: '/requestTemplate/outgoingPaymentRequestTemplates',
                    contextKey: 'requestTemplate_outgoingPaymentRequestTemplates',
                };
                actionFunction.makeRequestForPayment(model, model.context.contextKey);
                appBus.trigger('router:navigate', `PAYMENTS/addRequestFromTemplate/${model.get('FUNCTION')}`, true);
            },
        };

        const WrappedRFPComponent = asView(RfpChooseTemplate);
        this.pushPage(
            '',
            new WrappedRFPComponent(options),
        );
    },

    pmtCheckInquiry() {
        if (this.processDeeplink('PAYMENTS/checkInquiryView')) {
            if (!this.popPage('checkInq')) {
                this.showPage(
                    '',
                    new StackView({
                        initItem: new CheckInquiryView({
                            id: 'CHECK_INQUIRY_VIEW',
                            returnRoute: 'PAYMENTS/checkInquiryView',
                        }),
                    }),
                );
            }
        }
    },

    pmtCheckInquiryWorkspace() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new CheckInquiryTabsView({
                        id: 'CHECK_INQUIRY_TABS',
                        returnRoute: 'PAYMENTS/checkInquiry',
                    }),
                }),
            );
        }
    },

    pmtImageSearchWorkspace() {
        this.showPage('', new WorkspaceView({
            id: 'IMAGE_SEARCH',
            returnRoute: 'PAYMENTS/imageSearch',
        }));
    },

    pmtSimpleEntryWidget() {
        if (this.processDeeplink('PAYMENTS/simpleEntryWidget')) {
            this.showPage('', new SimpleEntryWidgetView());
        }
    },

    pmtQuickTransferWidget() {
        if (this.processDeeplink('PAYMENTS/quickTransferWidget')) {
            this.showPage('', new QuickTransferWidgetView());
        }
    },

    pmtQuickEntry() {
        this.showPage('', new QuickEntryView());
    },

    pmtManageTemplateGroups() {
        this.showPage('', new ManageTemplateGroupsView());
    },

    pmtModifyPayment(isSubPayment) {
        this.showPage('', new ModifyPayment({ isSubPayment }));
    },

    pmtRepairPayment(isSubPayment) {
        this.showPage('', new RepairPayment({ isSubPayment }));
    },

    pmtViewPayment(isSubPayment) {
        this.showPage('', new ViewPayment({ isSubPayment }));
    },

    pmtModifyTemplate() {
        this.showPage('', new ModifyTemplate());
    },

    pmtSchedulePayment() {
        const contextTemp = store.get('template_listView_corp-actionModel');
        this.showPage('', new SchedulePayment({
            opts: contextTemp,
        }));
    },

    pmtViewTemplate() {
        this.showPage('', new ViewTemplate());
    },

    pmtRepairTemplate() {
        this.showPage('', new RepairTemplate());
    },

    /**
     * - callback function to show restore RFP template screen
     */
    requestForPaymentRestoreTemplate() {
        this.showPage('', new RestoreTemplateRFP());
    },
    /**
     * @method pmtRestoreTemplate
     * - callback function to show restore template screen
     */
    pmtRestoreTemplate() {
        this.showPage('', new RestoreTemplate());
    },

    /**
     * @method pmtReversePayment
     * - callback function to show reverse payment screen
     */

    pmtReversePayment() {
        this.showPage('', new ReversePayment());
    },

    listPassThroughDetails() {
        this.showPage('Pass-Through Summary', new PassThroughDetailsView());
    },

    pmtAddPayment() {
        this.showPage('', new AddPaymentView({
            isModal: false,
        }));
    },

    pmtAddTemplate() {
        this.showPage('', new AddTemplateView({
            isModal: false,
        }));
    },

    pmtSSOout(menuCode) {
        const self = this;
        const checkFreeUserInquiry = new InquirySearchService();
        const rdcCollection = new RDCCollection();
        const rdcModel = new RDCModel();
        const menuctx = contextApi.menuContext.getContext(menuCode);
        if (menuctx.typeCode === 'CFSMB') {
            checkFreeUserInquiry.addSearch('VENDCODE', 'CFSMB');
        } else {
            checkFreeUserInquiry.addSearch('VENDCODE', 'CFRXP');
        }
        checkFreeUserInquiry.addSearch('USER_ID', userInfo.get('id'));
        checkFreeUserInquiry.addSearch('USERGROUP', userInfo.get('group'));
        checkFreeUserInquiry.setTypeCode('SSOTPV');
        checkFreeUserInquiry.setProductCode('SSOOUT');
        checkFreeUserInquiry.setInquiryId(29550);

        function showSSOIframe() {
            const params = store.get('ssoout_params');
            store.remove('ssoout_params');
            const promiseMe = ssoApi.login(menuctx, params);
            return promiseMe.then((data) => {
                if (data.inNewWindow) {
                    ssoApi.openSSOWindow(data);
                } else {
                    self.showPage(locale.get('payment.outbound.sso'), new IframeView({
                        loginResponse: data,
                        menuctx,
                    }));
                }
            });
        }

        function showCheckFreeModal(vendorCode) {
            const view = new CheckFreeView({ vendorCode });

            view.once('success', () => {
                view.off();
                dialog.close();
                showSSOIframe();
            });

            view.once('close', () => {
                window.history.back();
            });

            view.once('cancel', () => {
                dialog.close();
            });

            view.once('register', () => {
                view.off();
                dialog.close();
                self.options.vendorCode = vendorCode;
                self.showPage('', new RegisterForm(self));
            });

            dialog.open(
                view,
                {
                    title: locale.get('payment.check.free.settings'),
                    modalClass: 'modal-md',
                },
            );
        }

        function showRDCModal(collection) {
            const view = new RDCView({
                collection,
                menuCtx: menuctx,
            });

            view.once('complete', () => {
                view.off();
                dialog.close();
                showSSOIframe();
            });

            view.once('close', () => {
                window.history.back();
            });

            view.once('cancel', () => {
                dialog.close();
            });

            dialog.open(
                view,
                {
                    title: locale.get('RDC.SSO.Login'),
                    modalClass: 'modal-md',
                },
            );
        }

        if (menuCode === 'CFRXP' || menuCode === 'CFSMB') {
            checkFreeUserInquiry.send().then((response) => {
                if (response.inquiryResponse.rows.length > 0) {
                    showSSOIframe();
                } else {
                    showCheckFreeModal(menuCode);
                }
            });
        } else if (menuCode === 'RDCTYPC') {
            rdcCollection.fetch({
                success(collection) {
                    if (collection.length === 1) {
                        rdcModel.set('fieldValue', collection.at(0).get('id'));
                        rdcModel.save(
                            {},
                            {
                                success: showSSOIframe,
                            },
                        );
                    } else {
                        showRDCModal(collection);
                    }
                },
            });
        } else {
            showSSOIframe();
        }
    },

    /*
     * This is a dummy action that serves only to send request back to
     * pmtSSOout.  The controller doesn't let us navigate to the path
     * we are already on but for outbound SSO it is very useful to do
     * so.
     */
    pmtSSOoutRetry(menuCode) {
        const menuctx = contextApi.menuContext.getContext(menuCode);
        this.showPage('', new SSORetryView({
            menuctx,
        }));
    },

    processDeeplink(url) {
        if (isEntitled(url)) {
            return true;
        }
        this.showPage('', getErrorView());
        return false;
    },

    pmtNotEntitled() {
        this.showPage('', getErrorView());
    },

    pmtExceptionReport() {
        this.showPage('', new ExceptionReport());
    },

    pmtLargeBatch(viewId) {
        if (this.processDeeplink('BATCH/largeBatch')) {
            this.showPage('', new LargeBatchListView({
                viewId,
            }));
        }
    },

    pmtRapidWire() {
        this.showPage('', new RapidWireEntry());
    },

    pmtRapidWireDom() {
        if (this.processDeeplink('PAYMENTS/rapidWireDomEntry')) {
            store.unset('current-workspace-route');
            this.showPage('', new RapidWireEntry());
        }
    },

    pmtRtpMultiAdd() {
        this.showPage('', new RtpMultiAddView({
            localeKey: 'RTP.',
            addButtonLabel: 'payment.crtran_title-insert',
            collectionClass: RtpPayments,
            canSaveAsDraft: true,
        }));
    },

    pmtCheckFreeRegister() {
        this.showPage('', new RegisterForm(this));
    },

    displayErrorPageReadAccessDenied() {
        this.showPage('', getErrorView('dataAccessNotAuthorized'));
    },

    deeplinkAddPaymentFromTemplate() {
        if (!this.processDeeplink('PAYMENTS/addPaymentFromTemplateDeepLink')) {
            return;
        }

        // check for deep linking payment data (contextual data)
        this.storedPreObj = store.remove(
            'templateDetails',
            {
                context: {
                    productCode: 'PAY',
                    functionCode: 'INST',
                },
            },
        );

        if (!this.storedPreObj) {
            this.showPage('', getErrorView('noDataFoundForOperation'));
            return;
        }

        const model = new Model({
            context: {
                tnum: this.storedPreObj.tnum,
                updateCount: this.storedPreObj.updateCount,
                functionCode: this.storedPreObj.functionCode,
                type: this.storedPreObj.typeCode,
                subType: this.storedPreObj.subType,
                serviceName: this.storedPreObj.serviceName,
                templateServiceName: this.storedPreObj.templateServiceName,
                enableSaveDraft: true,
            },
            selection: 'template',
        });

        store.set('current-workspace-route', '/PAYMENTS/listPayments');
        if (model.get('context').type.indexOf('LOANPAY') > -1 || model.get('context').type.indexOf('LOANDRAW') > -1) {
            store.set('basic_payment_data', model);
            model.set('TNUM', model.get('context').tnum);
            loansActions.createPaymentFromTemplate(this);
        } else if (model.get('context').type.indexOf('TRANSFER') > -1) {
            store.set('current-workspace-route', '/PAYMENTS/listTransfers');
            transfersActions.addSingleTransferByTemplate(model.get('context').tnum, this);
        } else {
            store.set('payment_listView_corp-contextOverride', model.get('context'));
            this.addPaymentFromTemplate();
        }
    },
});
