var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form autocomplete=\"off\" data-submit=\"save\">\n    <div class=\"validation-group hide\" role=\"alert\">\n        <p class=\"help-block center\" data-validation=\"generic\"></p>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <div class=\"checkbox\">\n                <input type=\"checkbox\"\n                       name=\"includeSummaryInfo\"\n                       value=\"1\"\n                       id=\"include-summary-info\"\n                       data-hook=\"getIncludeSummaryInfoCheckbox\"\n                       data-bind=\"model\">\n                <label for=\"include-summary-info\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.includeTranSum",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":50},"end":{"line":14,"column":81}}}))
    + "</label>\n            </div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});