import React, { Fragment, useState, useLayoutEffect } from 'react';
/*
 * import PropTypes from 'prop-types';
 * import { Tabs, Tab, TabIndicator } from '@glu/tabs-react'; // use for tabs treatment above pies?
 */
import { withStyles } from '@glu/theming';
import { appBus } from '@glu/core';
import ViewWrapper from 'react-wrappers/ViewWrapper';

import WorkspaceView from 'common/workspaces/views/workspace';

/* istanbul ignore next */
// keeping for now as we will most likely be adding styles, will externalize when we do.
const styles = () => ({
});

const Rtp = () => {
    const [mainView, setMainView] = useState(new WorkspaceView({
        id: 'RTP',
        returnRoute: 'PAYMENTS/manageRealTimePayment',
    }));

    useLayoutEffect(() => {
        appBus.on('rtp:show:page', setMainView);

        return () => {
            appBus.off('rtp:show:page', setMainView);
        };
    }, []);

    return (
        <>
            <ViewWrapper view={mainView} />
        </>
    );
};

export default withStyles(styles)(Rtp);
