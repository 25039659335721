import React from 'react';
import PropTypes from 'prop-types';
import { FaceIdIcon, FingerprintIdIcon, ActionableIcon } from '@glu/icons-react';
import { BIOMETRIC_TYPE } from 'mobile/util/biometricUtil';

const propTypes = {
    biometricType: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const LaunchBiometricButton = ({
    biometricType,
    onClick,
}) => {
    const Icon = biometricType === BIOMETRIC_TYPE.FACE_ID ? FaceIdIcon : FingerprintIdIcon;
    return (
        <ActionableIcon onClick={onClick}>
            <Icon
                height="30px"
                width="30px"
            />
        </ActionableIcon>
    );
};
LaunchBiometricButton.propTypes = propTypes;
export default LaunchBiometricButton;
