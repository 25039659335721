import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';

const RowModel = Model.extend({
    initialize() {
        this.listenTo(
            this,
            {
                'change:QUANTITY change:EACH_AMOUNT': this.updateTotal,
            },
        );
        this.updateTotal();
    },

    isEmpty() {
        // has at least one truthy value - not 0, not "", not null, not undef
        const filledAttr = util.find(this.values(), val => !!val);

        return util.isUndefined(filledAttr);
    },

    updateTotal() {
        let total = this.get('QUANTITY') * this.get('EACH_AMOUNT');

        if (util.isNaN(total)) {
            total = '';
        }

        this.set({
            NET_AMOUNT: total,
        });
    },

    parse(res) {
        return transform.pairsToHash(res.item, 'name', 'value');
    },
});

export default Collection.extend({
    initialize() {
        this.listenTo(
            this,
            {
                'reset remove add': this.enforceConstraints,
                'change:order': this.reorder,
            },
        );
    },

    comparator: 'order',
    model: RowModel,

    getTotal() {
        return this.reduce((memo, model) => memo + +model.get('NET_AMOUNT'), 0);
    },

    isEmpty() {
        const notEmpty = this.find(model => !model.isEmpty());

        return util.isUndefined(notEmpty);
    },

    reorder(model, value, options) {
        if (options && options.source === 'enforceConstraints') {
            return;
        }

        this.sort();
    },

    parse(res) {
        if (util.isUndefined(res)) {
            return [];
        }

        return res.items;
    },

    enforceConstraints() {
        // there must be at least one row
        if (this.length === 0) {
            this.add({});
        }

        /*
         * every model must have an order
         * orders must be sequential and unique
         * models will be sorted before this runs
         */
        this.each((model, i) => {
            if (model.get('order') !== i) {
                model.set({
                    order: i,
                }, {
                    source: 'enforceConstraints',
                });
            }
        }, this);
    },

    getName() {
        throw new Error();
    },

    toServerJSON() {
        const items = this.map(row => ({
            item: transform.hashToPairs(row.toJSON()),
        }));

        return [{
            name: this.getName(),
            items,
        }];
    },
});
