import hash from 'common/util/promise-hash';
import locale from '@glu/locale';
import services from 'services';
import http from '@glu/core/src/http';
import Formatter from 'system/utilities/format';
import errHandler from 'system/errHandler';
import InquiryApi from 'system/webseries/api/inquiry';
import store from 'system/utilities/cache';
import CreateLoanView from 'app/loans/views/payment/add';
import CreateTemplate from 'app/loans/views/template/add';
import CreatePaymentFromTemplate from 'app/loans/views/payment/createPaymentFromTemplate/createPaymentFromTemplate';
import ModifyLoan from 'app/loans/views/payment/modifyPayment/modify';
import ModifyTemplate from 'app/loans/views/template/modifyTemplate/modify';
import ViewLoanView from 'app/loans/views/payment/viewPayment/view';
import ViewTemplate from 'app/loans/views/template/viewTemplate/view';
import TransactionDetails from 'app/loans/views/bankSummary/details';
import Constants from 'app/loans/constants';
import Dropdown from 'app/loans/collections/dropdown';
import loans from 'app/loans/api/common';
import ErrorMsgView from 'system/error/views/errorMessage';


/**
 * This controller handles all loan-related screens.
 */

export default {
    /**
     * Return the loan payments screen
     */
    inCreatePayment() {
        const promises = this.getLoanPaymentPromises();
        const entitlementPromise = loans.getEntitlements('LOANPAY');

        hash({
            debitAccounts: promises.debit,
            creditAccounts: promises.credit,
            entitlement: entitlementPromise,
            preferences: loans.getPaymentPreferences(),
        }).then((results) => {
            const createLoanForm = new CreateLoanView({
                title: locale.get('common.loanPayment'),
                typeCode: 'LOANPAY',
                payMethod: 'RTGS',
                debitInquiryId: 19533,
                creditInquiryId: 19534,
                entitlement: results.entitlement,
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,

                jsonData: {
                    typeInfo: {
                        typeCode: 'LOANPAY',
                        productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                        functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    },
                },

                debitAccountCollection: results.debitAccounts,
                creditAccountCollection: results.creditAccounts,
                preferences: results.preferences,
            });

            this.showPage(createLoanForm.title, createLoanForm);
        }).then(null, errHandler);
    },

    /**
     * Return the loan payments screen
     */
    inLoanDraw() {
        const promises = this.getLoanDrawPromises();
        const entitlementPromise = loans.getEntitlements('LOANDRAW');

        hash({
            debitAccounts: promises.debit,
            creditAccounts: promises.credit,
            entitlement: entitlementPromise,
            preferences: loans.getPaymentPreferences(),
        }).then((results) => {
            const createLoanForm = new CreateLoanView({
                title: locale.get('common.loanDrawdown'),
                typeCode: 'LOANDRAW',
                payMethod: 'RTGS',
                debitInquiryId: 19535,
                creditInquiryId: 19536,
                entitlement: results.entitlement,
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,

                jsonData: {
                    typeInfo: {
                        typeCode: 'LOANDRAW',
                        productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                        functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    },
                },

                debitAccountCollection: results.debitAccounts,
                creditAccountCollection: results.creditAccounts,
                preferences: results.preferences,
            });

            this.showPage(createLoanForm.title, createLoanForm);
        }).then(null, errHandler);
    },

    /**
     * Handler for Payment Type
     */
    addPayment() {
        const model = store.get('basic_payment_data');
        const { action } = model;

        if (model.get('selection') === 'newPayment') {
            if (model.get('type').indexOf('LOANPAY') > -1) {
                this.inCreatePayment();
            }
            if (model.get('type').indexOf('LOANDRAW') > -1) {
                this.inLoanDraw();
            }
        }
        if (model.get('selection') === 'template' || (action && action.toUpperCase().indexOf('MAKE') > -1)) {
            this.createPaymentFromTemplate(null, {
                functionCode: 'INST',
                entryMethod: 1,
            });
        }

        if (action && (action.toUpperCase().indexOf('COPY') > -1)) {
            this.loadExistingPayment(model);
        }
    },

    /**
     * Return the Payment Template creation screen
     */
    addTemplate() {
        const self = this;
        let title = 'Loan';
        const modalData = store.get('basic_template_data');
        let typeCode = modalData.type || modalData.get('TYPE');
        const { action } = modalData;
        let promises;

        if (action && (action.toUpperCase().includes('COPY') || action.toUpperCase().includes('MAKETMPL'))) {
            this.loadExistingTemplate(modalData);
            return;
        }

        const isLoanPay = typeCode.includes('LOANPAY');
        const isLoanDrawdown = typeCode.includes('LOANDRAW');

        if (isLoanPay) {
            title = locale.get('common.loanPayment');
            promises = this.getLoanPaymentPromises();
        } else if (isLoanDrawdown) {
            title = locale.get('common.loanDrawdown');
            promises = this.getLoanDrawPromises();
        } else {
            // We should never get here, but we should know what is wrong if we do.
            throw new Error('Unexpected Loan Type');
        }

        if (typeCode.slice(-2) === '~*') {
            typeCode = typeCode.substring(0, typeCode.length - 2);
        }

        hash({
            debitAccounts: promises?.debit,
            creditAccounts: promises?.credit,
            preferences: loans.getPaymentPreferences(),
        }).then((results) => {
            const templateCode = modalData.Template_Code;
            const templateDescription = modalData.Template_Description;
            const templateType = modalData.type;
            const templateFlag = modalData.RestrictTemplate_Flag;

            const createLoanForm = new CreateTemplate({
                title,
                typeCode,
                templateCode,
                templateDescription,
                templateType,
                templateFlag,
                payMethod: 'RTGS',
                debitInquiryId: isLoanPay ? 19533 : 19535,
                creditInquiryId: isLoanPay ? 19534 : 19536,
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,

                jsonData: {
                    typeInfo: {
                        typeCode,
                        productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                        functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    },
                },

                debitAccountCollection: results.debitAccounts,
                creditAccountCollection: results.creditAccounts,
                preferences: results.preferences,
            });

            self.showPage(createLoanForm.title, createLoanForm);
        }).then(null, errHandler);
    },

    loadExistingPayment(copy) {
        const self = this;
        const gridModel = store.get('custom_modify_payment_model') ? store.get('custom_modify_payment_model').toJSON() : copy.toJSON();
        let model = null;
        let promises = null;
        let parameters = null;
        let entitlementPromise = null;
        let service;

        if (gridModel.TYPE === 'LOANPAY') {
            service = services.loanPaymentRead;

            if (copy) {
                service = copy.loadFromPayments
                    ? services.loanPaymentRead : services.readLoanPaymentTemplate;
            }

            model = this.getPaymentRecord(
                gridModel.TNUM,
                service,
                gridModel.FUNCTION,
                gridModel.FROMTEMPLATE,
                gridModel.TARGETFUNCTION,
            );
            promises = this.getLoanPaymentPromises();

            entitlementPromise = loans.getEntitlements(gridModel.TYPE);

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                preferences: loans.getPaymentPreferences(),
                entitlement: entitlementPromise,
                model,
            }).then((results) => {
                model = loans.convertServerJSONToModelAttributes(results.model.item);
                model.FUNCTION = 'INST';
                model.VALUE_DATE = Formatter.formatDate(model.VALUE_DATE);
                parameters = {
                    paymentType: gridModel.TYPE,
                    debitBank: model.DEBIT_BANK_CODE,
                    creditCurrency: model.CREDIT_CURRENCY,
                    debitCurrency: model.DEBIT_CURRENCY,
                    debitBankCountry: model.DEBIT_COUNTRY,
                    creditBankCountry: model.BENE_COUNTRY,
                };

                if (copy) {
                    delete model.TEMPLATE_CODE;
                    delete model.TEMPLATE_DESCRIPTION;
                    delete model.RESTRICTTEMLPATE_FLAG;
                    delete model.REJECTION_REASON;
                    delete model.REJECTION_TIMESTAMP;
                    delete model.REJECTION_USERGROUP;
                    delete model.STATUS;
                    delete model.TNUM;
                    delete model.STATUS_DESCRIPTION;
                    delete model.VALUE_DATE;
                    delete model.REJECTION_REASON;
                    delete model.REJECTION_TIMESTAMP;
                    delete model.REJECTION_USERGROUP;
                    delete model.STATUS;
                    delete model.TNUM;
                    delete model.STATUS_DESCRIPTION;
                }

                const createLoanForm = new ModifyLoan({
                    jsonData: {
                        typeInfo: {
                            typeCode: 'LOANPAY',
                            productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                            functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                        },
                    },

                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                    preferences: results.preferences,
                    parameters,
                    title: locale.get('common.loanPayment'),
                    model,
                    typeCode: 'LOANPAY',
                    payMethod: 'RTGS',
                    debitInquiryId: 19533,
                    creditInquiryId: 19534,
                    isCopy: copy ? typeof copy.action === 'string' : false,
                    entitlement: results.entitlement,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, errHandler);
        } else if (gridModel.TYPE === 'LOANDRAW') {
            service = services.loanDrawRead;

            if (copy) {
                service = copy.loadFromPayments
                    ? services.loanDrawRead : services.readLoanDrawTemplate;
            }

            model = this.getPaymentRecord(
                gridModel.TNUM,
                service,
                gridModel.FUNCTION,
                gridModel.FROMTEMPLATE,
                gridModel.TARGETFUNCTION,
            );
            promises = this.getLoanDrawPromises();

            entitlementPromise = loans.getEntitlements(gridModel.TYPE);

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                preferences: loans.getPaymentPreferences(),
                entitlement: entitlementPromise,
                model,
            }).then((results) => {
                model = loans.convertServerJSONToModelAttributes(results.model.item);
                model.VALUE_DATE = Formatter.formatDate(model.VALUE_DATE);
                model.FUNCTION = 'INST';
                parameters = {
                    paymentType: gridModel.TYPE,
                    debitBank: model.DEBIT_BANK_CODE,
                    creditCurrency: model.CREDIT_CURRENCY,
                    debitCurrency: model.DEBIT_CURRENCY,
                    debitBankCountry: model.DEBIT_COUNTRY,
                    creditBankCountry: model.BENE_COUNTRY,
                };

                if (copy) {
                    delete model.TEMPLATE_CODE;
                    delete model.TEMPLATE_DESCRIPTION;
                    delete model.RESTRICTTEMLPATE_FLAG;
                    delete model.REJECTION_REASON;
                    delete model.REJECTION_TIMESTAMP;
                    delete model.REJECTION_USERGROUP;
                    delete model.STATUS;
                    delete model.TNUM;
                    delete model.STATUS_DESCRIPTION;
                    delete model.VALUE_DATE;
                    delete model.REJECTION_REASON;
                    delete model.REJECTION_TIMESTAMP;
                    delete model.REJECTION_USERGROUP;
                    delete model.STATUS;
                    delete model.TNUM;
                    delete model.STATUS_DESCRIPTION;
                }

                const createLoanForm = new ModifyLoan({
                    title: locale.get('common.loanDrawdown'),
                    model,
                    typeCode: 'LOANDRAW',
                    payMethod: 'RTGS',
                    debitInquiryId: 19535,
                    creditInquiryId: 19536,
                    isCopy: copy ? typeof copy.action === 'string' : false,
                    entitlement: results.entitlement,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,

                    jsonData: {
                        typeInfo: {
                            typeCode: 'LOANDRAW',
                            productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                            functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                        },
                    },

                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                    preferences: results.preferences,
                    parameters,
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, errHandler);
        }
        store.unset('custom_modify_payment_model');
    },

    /**
     * @method viewPayment
     *
     * @param {Object} referenceView - is a reference to the initial action called by the route
     */
    viewPayment(referenceView) {
        const self = referenceView || this;
        const gridModel = store.get('custom_view_payment_model').toJSON();
        const { buttons, buttonsToHide } = store.get('custom_view_payment_model') || {};
        let model = null;
        let promises = null;

        if (gridModel.TYPE === 'LOANPAY') {
            model = this.getPaymentRecord(
                gridModel.TNUM,
                services.loanPaymentRead,
                gridModel.FUNCTION,
            );
            promises = this.getLoanPaymentPromises();

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                model,
                preferences: loans.getPaymentPreferences(),
            }).then((results) => {
                model = loans.convertServerJSONToModelAttributes(results.model.item);
                model.VALUE_DATE = Formatter.formatDate(model.VALUE_DATE);

                const createLoanForm = new ViewLoanView({
                    title: locale.get('common.loanPayment'),
                    model,
                    buttons,
                    buttonsToHide,
                    typeCode: 'LOANPAY',
                    payMethod: 'RTGS',
                    debitInquiryId: 19533,
                    creditInquiryId: 19534,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                    preferences: results.preferences,
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, () => {
                self.showPage('', new ErrorMsgView({
                    key: 'loadingPage',
                    noContainer: true,
                }));
            });
        } else if (gridModel.TYPE === 'LOANDRAW') {
            model = this.getPaymentRecord(
                gridModel.TNUM,
                services.loanDrawRead,
                gridModel.FUNCTION,
            );
            promises = this.getLoanDrawPromises();

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                preferences: loans.getPaymentPreferences(),
                model,
            }).then((results) => {
                model = loans.convertServerJSONToModelAttributes(results.model.item);
                model.VALUE_DATE = Formatter.formatDate(model.VALUE_DATE);
                const createLoanForm = new ViewLoanView({
                    title: locale.get('common.loanDrawdown'),
                    model,
                    buttons,
                    buttonsToHide,
                    typeCode: 'LOANDRAW',
                    payMethod: 'RTGS',
                    debitInquiryId: 19535,
                    creditInquiryId: 19536,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                    preferences: results.preferences,
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, () => {
                self.showPage('', new ErrorMsgView({
                    key: 'loadingPage',
                    noContainer: true,
                }));
            });
        }
    },

    getPaymentRecord(tnum, service, functionCode, fromTemplate, targetFunction) {
        const data = {
            item: [{
                name: 'TNUM',
                value: tnum,
            }, {
                name: 'FUNCTIONCODE',
                value: functionCode,
            }],
        };

        if (fromTemplate) {
            data.item.push({
                name: 'FROMTEMPLATE',
                value: fromTemplate,
            });
        }
        if (targetFunction) {
            data.item.push({
                name: 'TARGETFUNCTION',
                value: targetFunction,
            });
        }

        return new Promise((resolve, reject) => {
            http.post(service, data, resolve, (response) => {
                resolve(response);
            }, () => {
                reject(locale.get('loans.error.loading'));
            });
        });
    },

    getPaymentPreferences() {
        return new Promise((resolve, reject) => {
            http.post(services.getPreferences, null, resolve, (response) => {
                resolve(response);
            }, () => {
                reject(locale.get('loans.error.loading.preferences'));
            });
        });
    },

    /**
     * Return the transaction list view.
     */
    viewTransaction() {
        const details = new TransactionDetails({
            accountFilter: window.Bottomline.store[Constants.STORE_ACCOUNT_FILTER],
            accountNumber: window.Bottomline.store[Constants.STORE_ACCOUNT_NUMBER],
        });
        this.showPage(locale.get('common.transactionDetails'), details);
    },

    createPaymentFromTemplate(inRef, payload) {
        const basicData = store.get('basic_payment_data');
        const typeCode = basicData.type || basicData.get('TYPE') || basicData.get('context').type;
        const productCode = basicData.PRODUCT || basicData.get('PRODUCT') || basicData.get('context').PRODUCT;
        const isLoanPay = typeCode.indexOf('LOANPAY') > -1;
        let tnum = basicData.get('tnum');

        if (!tnum) {
            tnum = basicData.get('TNUM');
        }

        const entLoanPay = loans.getEntitlements('LOANPAY', payload);
        const entLoanDraw = loans.getEntitlements('LOANDRAW', payload);

        hash({
            preferences: loans.getPaymentPreferences(),
            data: loans.getTemplateData(tnum, typeCode, productCode),
            entLoanPay,
            entLoanDraw,
        }).then((results) => {
            if (results.data.rows && results.data.rows.length > 0) {
                const model = loans.parseTemplateData(results.data.rows);

                const datePickerParameters = {
                    paymentType: model.TYPE,
                    debitBank: model.DEBIT_BANK_CODE,
                    creditCurrency: model.CREDIT_CURRENCY,
                    debitCurrency: model.DEBIT_CURRENCY,
                    debitBankCountry: model.DEBIT_COUNTRY,
                    creditBankCountry: model.BENE_BANK_CODE,
                };

                model.VALUE_DATE = Formatter.formatDate(model.VALUE_DATE);
                delete model.RESTRICTTEMLPATE_FLAG;
                delete model.TEMPLATE_TYPE;

                const createLoanForm = new CreatePaymentFromTemplate({
                    title: loans.getLocaleTitle(model.TYPE),
                    preferences: results.preferences,
                    typeCode: model.TYPE,
                    payMethod: 'RTGS',
                    model,
                    entLoanPay: results.entLoanPay,
                    entLoanDraw: results.entLoanDraw,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    parameters: datePickerParameters,
                    debitInquiryId: isLoanPay ? 19533 : 19535,
                    creditInquiryId: isLoanPay ? 19534 : 19536,

                    jsonData: {
                        typeInfo: {
                            typeCode: model.TYPE,
                            productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                            functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                        },
                    },
                });
                (inRef || this).showPage(createLoanForm.title, createLoanForm);
            }
        }).then(null, errHandler);
    },

    getLoanPaymentPromises() {
        // get debit accounts
        const debitAccountPromise = new Promise((resolve, reject) => {
            new Dropdown({
                inquiryId: 19533,
                typeCode: 'LOANPAY',
                currency: 'NONE',
                column: 'Credit_Currency',
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get credit accounts
        const creditAccountPromise = new Promise((resolve, reject) => {
            new Dropdown({
                allowDuplicates: true,
                inquiryId: 19534,
                typeCode: 'LOANPAY',
                currency: 'NONE',
                column: 'Debit_Currency',
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        return {
            debit: debitAccountPromise,
            credit: creditAccountPromise,
        };
    },

    getLoanDrawPromises() {
        // get debit accounts
        const debitAccountPromise = new Promise((resolve, reject) => {
            new Dropdown({
                allowDuplicates: true,
                inquiryId: 19535,
                typeCode: 'LOANDRAW',
                currency: 'NONE',
                column: 'Credit_Currency',
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get credit accounts
        const creditAccountPromise = new Promise((resolve, reject) => {
            new Dropdown({
                inquiryId: 19536,
                typeCode: 'LOANDRAW',
                currency: 'NONE',
                column: 'Debit_Currency',
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        return {
            debit: debitAccountPromise,
            credit: creditAccountPromise,
        };
    },

    loadExistingTemplate(copy) {
        const self = this;
        const gridModel = store.get('custom_modify_template_model') ? store.get('custom_modify_template_model').toJSON() : copy.toJSON();
        const overloadedMethodName = store.get('custom_modify_template_model') ? store.get('custom_modify_template_model').overloadedMethodName : '';
        let model = null;
        let promises = null;
        let service;
        self.overloadedMethodName = overloadedMethodName;

        if (gridModel.TYPE === 'LOANPAY') {
            service = services.readLoanPaymentTemplate;

            if (copy) {
                service = copy.loadFromPayments
                    ? services.loanPaymentRead : services.readLoanPaymentTemplate;
            }

            model = this.getPaymentRecord(
                gridModel.TNUM,
                service,
                gridModel.FUNCTION,
                gridModel.FROMTEMPLATE,
                gridModel.TARGETFUNCTION,
            );
            promises = this.getLoanPaymentPromises();

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                preferences: loans.getPaymentPreferences(),
                model,
            }).then((results) => {
                model = loans.convertServerJSONToModelAttributes(results.model.item);
                model.FUNCTION = 'TMPL';
                model.FUNCTIONCODE = 'TMPL';
                model.overloadedMethodName = self.overloadedMethodName;
                if (copy) {
                    delete model.TEMPLATE_CODE;
                    delete model.TEMPLATE_DESCRIPTION;
                    delete model.RESTRICTTEMLPATE_FLAG;
                }
                const createLoanForm = new ModifyTemplate({
                    title: locale.get('common.loanPayment'),
                    model,
                    typeCode: 'LOANPAY',
                    payMethod: 'RTGS',
                    debitInquiryId: 19533,
                    creditInquiryId: 19534,
                    isCopy: copy ? typeof copy.action === 'string' : false,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                    preferences: results.preferences,

                    jsonData: {
                        typeInfo: {
                            typeCode: 'LOANPAY',
                            productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                            functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                        },
                    },
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, errHandler);
        } else if (gridModel.TYPE === 'LOANDRAW') {
            service = services.readLoanDrawTemplate;

            if (copy) {
                service = services.loanDrawRead;
            }

            model = this.getPaymentRecord(
                gridModel.TNUM,
                service,
                gridModel.FUNCTION,
                gridModel.FROMTEMPLATE,
                gridModel.TARGETFUNCTION,
            );
            promises = this.getLoanDrawPromises();

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                preferences: loans.getPaymentPreferences(),
                model,
            }).then((results) => {
                model = loans.convertServerJSONToModelAttributes(results.model.item);
                model.FUNCTION = 'TMPL';
                model.FUNCTIONCODE = 'TMPL';
                model.overloadedMethodName = self.overloadedMethodName;
                if (copy) {
                    delete model.TEMPLATE_CODE;
                    delete model.TEMPLATE_DESCRIPTION;
                    delete model.RESTRICTTEMLPATE_FLAG;
                }
                const createLoanForm = new ModifyTemplate({
                    title: locale.get('common.loanDrawdown'),
                    model,
                    typeCode: 'LOANDRAW',
                    payMethod: 'RTGS',
                    debitInquiryId: 19535,
                    creditInquiryId: 19536,
                    isCopy: copy ? typeof copy.action === 'string' : false,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                    preferences: results.preferences,

                    jsonData: {
                        typeInfo: {
                            typeCode: 'LOANDRAW',
                            productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                            functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                        },
                    },
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, errHandler);
        }
        store.unset('custom_modify_template_model');
    },

    viewTemplate() {
        const self = this;
        const gridModel = store.get('custom_view_template_model').toJSON();
        let model = null;
        let promises = null;

        if (gridModel.TYPE === 'LOANPAY') {
            model = this.getPaymentRecord(
                gridModel.TNUM,
                services.readLoanPaymentTemplate,
                gridModel.FUNCTION,
            );
            promises = this.getLoanPaymentPromises();

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                gridModel: model,
                preferences: loans.getPaymentPreferences(),
            }).then((results) => {
                const createLoanForm = new ViewTemplate({
                    title: locale.get('common.loanPayment'),
                    model: results.gridModel.item,
                    typeCode: 'LOANPAY',
                    payMethod: 'RTGS',
                    debitInquiryId: 19533,
                    creditInquiryId: 19534,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                    preferences: results.preferences,
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, errHandler);
        } else if (gridModel.TYPE === 'LOANDRAW') {
            model = this.getPaymentRecord(
                gridModel.TNUM,
                services.readLoanDrawTemplate,
                gridModel.FUNCTION,
            );
            promises = this.getLoanDrawPromises();

            hash({
                debitAccounts: promises.debit,
                creditAccounts: promises.credit,
                gridModel: model,
            }).then((results) => {
                const createLoanForm = new ViewTemplate({
                    title: locale.get('common.loanDrawdown'),
                    model: results.gridModel.item,
                    typeCode: 'LOANDRAW',
                    payMethod: 'RTGS',
                    debitInquiryId: 19535,
                    creditInquiryId: 19536,
                    productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                    functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
                    debitAccountCollection: results.debitAccounts,
                    creditAccountCollection: results.creditAccounts,
                });
                self.showPage(createLoanForm.title, createLoanForm);
            }).then(null, errHandler);
        }
    },
};
