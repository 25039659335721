import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import services from 'services';
import { postData } from 'common/util/services';
import transform from 'common/util/transform';
import { getAdobeAnalyticsFriendlyName } from 'common/util/pageTitleUtil';
import { appendScript } from './util';

let friendlyNames = null;

/**
 * Track the event in Adobe Analytics
 * @param {object} payload
 */
export const trackEvent = (payload) => {
    // eslint-disable-next-line
    window._satellite?.track?.('event', payload);
};

/**
 * Check config params and append the script when everything is
 * configured properly
 * @param {function} onLoad - callback after the script has loaded
 * @returns {boolean}
 */
const setup = (onLoad) => {
    const enabled = serverConfigParams.get('AdobeAnalyticsEnabled') === 'true';
    const adobeAnalyticsUrl = serverConfigParams.get('AdobeAnalyticsUrl');
    if (!enabled || !adobeAnalyticsUrl) {
        return false;
    }
    appendScript(adobeAnalyticsUrl, { async: true, onLoad });
    return true;
};

/**
 * Get a the friendly name based on the current UI route.
 * Fetch the friendly names from the server first if needed.
 * @param {string} route - UI route
 * @returns {string}
 */
export const getFriendlyName = (route) => {
    if (friendlyNames) {
        return Promise.resolve(friendlyNames[route] || 'other');
    }
    // Haven't fetch friendly names yet, so get them now
    const payload = {
        inquiryRequest: {
            searchCriteria: {
                searchType: 5,
                inquiryId: 72315,
            },
        },
    };
    return postData(services.inquiryData, payload).then((resp) => {
        const mapped = resp.inquiryResponse.rows.reduce((accum, row) => {
            const data = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            return {
                ...accum,
                [data.DBIQ_URL]: data.FRIENDLY_NAME,
            };
        }, {});
        friendlyNames = mapped;
        return friendlyNames[route] || 'other';
    });
};

const AdobeAnalytics = {
    done: false,
    postAuth() {
        setup(() => {
            AdobeAnalytics.done = true;
            AdobeAnalytics.routeChange(window.location.pathname);
        });
    },
    routeChange(route) {
        // When script hasn't loaded return
        if (!AdobeAnalytics.done) {
            return;
        }
        const payload = {
            eventName: 'dbiqe page view',
            eventType: 'pageview',
            attributes: {
                page: {
                    friendlyName: getAdobeAnalyticsFriendlyName(route),
                    canonicalURL: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
                    storeFront: serverConfigParams.get('AdobeAnalyticsStoreFront'),
                    pageBrowserName: document.querySelector('title').innerText,
                    productID: serverConfigParams.get('AdobeAnalyticsProductId'),
                    applicationID: serverConfigParams.get('AdobeAnalyticsApplicationId'),
                    userID: userInfo.get('id'),
                    siteVersion: serverConfigParams.get('AdobeAnalyticsSiteVersion'),
                },
            },
        };
        trackEvent(payload);
    },
};

export default AdobeAnalytics;
