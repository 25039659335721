import React, { useState } from 'react';
import { withStyles } from '@glu/theming';
import locale from '@glu/locale';
import { FormContextProvider } from '@glu/validation-react';
import RemittanceInfo from './RemittanceInfo';
import HistoryDetails from './HistoryDetails';
import UpdateCheckStatusDropDown from '../../common/components/UpdateCheckStatusDropDown';
import { Input } from '@glu/form-components';
import styles from 'components/GridActionPopup/CancelRequestPopup/CancelRequestPopup.styles';

/* eslint jsx-a11y/label-has-for: 0  react/prop-types: 0 */
// Note: I don't provide propTypes because there is LOT of TRANSACTION-related fields,
// And some of fields may be removed in nearest future. TODO - refactor during writing tests.

const TransactionDetails = ({
    STATUS,
    STATUS_DESCRIPTION,
    CHECKSTATUS_DESCRIPTION,
    ACTIONDESCRIPTION,
    ENTERED_BY,
    ENTERED_BY_NAME,
    ENTERED_TIMESTAMP,
    USERGROUP,
    COMPANYNAME,
    MODIFIED_BY,
    MODIFIED_BY_NAME,
    MODIFIED_TIMESTAMP,
    MODIFIED_USERGROUP,
    CHECKPROFILEKEYCODE, // From children payment grid
    CHECKPROFILEKEYDESC, // From children payment grid
    CHECKNUMBER,
    TRANCOMMENT, // Memo
    OUTSOURCED_REF_ID, // PCM-5269
    PAYEENAME_1,
    PAYEEID,
    CHECKDATE,
    AMOUNT,
    CHECK,
    UPDATECHECKSTATUS_REASON,
    CHECKSTATUS,
    validate,
    onChangeCheckStatus,
    onChangeLoadCheckStatusReason,
    currentAction,
    newCheckStatus,
    alertType,
    alertMessage,
    classes,
    transactionHistoryDetails, // { rows, rowHeader }
    ...restTransactionTabProps // remit fields, TYPE and other
}) => {

    const setMessage = (msg) => {
        console.log(msg);
        onChangeLoadCheckStatusReason(msg);
    };

    return (
        <div>
            <div className="section section-container">
                <div className="section-header">
                    <h3>{locale.get('clm.CheckInformation')}</h3>
                </div>
                <div className="row">
                    <div className="form-column inline-fields">
                        <div>
                            <div className="ui-widget field-container">
                                <div className="row">
                                    <div className="form-column">
                                        <div className="col-md-2">
                                            <label>{CHECKPROFILEKEYDESC.label}</label>
                                            <p>
                                                <span>{CHECKPROFILEKEYDESC.value}</span>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <label>{CHECKNUMBER.label}</label>
                                            <p>
                                                <span>{CHECKNUMBER.value}</span>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <label>{TRANCOMMENT.label}</label>
                                            <p>
                                                <span>{TRANCOMMENT.value}</span>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <label>{CHECKSTATUS_DESCRIPTION.label}</label>
                                            <p>
                                                <span>{CHECKSTATUS_DESCRIPTION.value}</span>
                                            </p>
                                        </div>
                                        {currentAction == 'UPDCHKST' && (
                                            <div className="col-md-2">
                                                <UpdateCheckStatusDropDown
                                                    name="CHECKSTATUS"
                                                    label={locale.get('checkManagement.newCheckStatus')}
                                                    currentCheckStatus={CHECKSTATUS.value}
                                                    onChange={(
                                                        field,
                                                        dropDownId,
                                                        dropDownValue,
                                                    ) => {
                                                        onChangeCheckStatus(dropDownId);
                                                    }}
                                                    validators={{
                                                        name: locale.get('checkManagement.newCheckStatus'),
                                                        required: true,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {currentAction == 'UPDCHKST' && (
                                            <div className="form-group col-md-2">
                                                <div className="required">
                                                    <label>{locale.get('PAY.UpdateCheckStatusReason')}</label>
                                                </div>
                                                <Input
                                                    validators={{
                                                        name: locale.get('PAY.UpdateCheckStatusReason'),
                                                        maxLength: 250,
                                                        required: true,
                                                    }}
                                                    textarea
                                                    name={locale.get('PAY.UpdateCheckStatusReason')}
                                                    onChange={e =>
                                                        onChangeLoadCheckStatusReason(e.target.value)
                                                    }
                                                    rows={5}
                                                    cols={25}
                                                    placeholder="Enter the Reason."
                                                    required
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-column">
                                        <div className="col-md-2">
                                            <label>{PAYEENAME_1.label}</label>
                                            <p>
                                                <span>{PAYEENAME_1.value}</span>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <label>{PAYEEID.label}</label>
                                            <p>
                                                <span>{PAYEEID.value}</span>
                                            </p>
                                        </div>
                                        {OUTSOURCED_REF_ID && OUTSOURCED_REF_ID.value && (
                                            <div className="col-md-2">
                                                <label>
                                                    {locale.get('transactionDetails.OutsourceReferenceId')}
                                                </label>
                                                <p>
                                                    <span>{OUTSOURCED_REF_ID.value}</span>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-header">
                    <legend>{locale.get('CLM.INST.GPCHK.DATEAMOUNT')}</legend>
                </div>
                <div className="row">
                    <div className="form-column inline-fields">
                        <div>
                            <div className="ui-widget field-container">
                                <div className="row">
                                    <div className="form-column">
                                        <div className="col-md-2">
                                            <label>{CHECKDATE.label}</label>
                                            <p>
                                                <span>{CHECKDATE.value}</span>
                                            </p>
                                        </div>
                                        <div className="col-md-2">
                                            <label>{AMOUNT.label}</label>
                                            <p>
                                                <span>{AMOUNT.value}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RemittanceInfo {...restTransactionTabProps} />

                <HistoryDetails
                    sectionTitle={locale.get('CLM.INST.APCHK.TRANSACTIONAUDIT.label')}
                    tableData={transactionHistoryDetails}
                />
            </div>
        </div>
    );
};

const TransactionDetailsRoot = props => (
    <FormContextProvider initialData={undefined}>
        {({ validate, onChange }) => (
            <TransactionDetails {...props} validate={validate} onChange={onChange} />
        )}
    </FormContextProvider>
);

export default withStyles(styles)(TransactionDetailsRoot);

