import ViewItem from 'app/smbPayments/views/viewItem';
import constants from 'app/smbPayments/views/employees/api/constants';

export default ViewItem.extend({
    initialize(opts) {
        this.context = {
            payContext: 'SMB_EMPLOYEE',
            tempContext: 'SMB_TEMP_EMPLOYEE',
            returnTabStoreName: 'employee_smb-currentTab',
            paymentContextStoreName: 'payment_listView_smbEmployee-contextOverride',
            templateContextStoreName: 'template_listView_smbEmployee-contextOverride',
            modifyURL: 'payments/modifyReimburseExpenses',
            copyAsPaymentURL: 'templates/copyEmpAsPayment',
            copyPaymentAsPaymentURL: 'payments/copyPaymentFromReimbursement',
            copyPaymentAsTemplateURL: 'templates/copyEmployeePaymentAsTemplate',
        };

        const options = {
            ...constants.options,
            ...opts,
            mode: 'view',
        };
        ViewItem.prototype.initialize.call(this, options);
    },
});
