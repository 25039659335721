import { appBus } from '@glu/core';

export default ({
    /**
     * Get the element for this fieldName in the view
     * @param {View} view - that contains the field
     * @param {string} fieldName - name of field
     */
    getFieldElement(view, fieldName) {
        return view.$el.find(`[name="${fieldName}"]`);
    },

    /**
     * Get the field container for the field element
     * @param {Object} $field - The jQuery object representation of the field
     */
    getContainer($field) {
        return $field.parents('.field-container').first();
    },

    /**
     * Get the label element for this fieldName in the view
     * @param {View} view - that contains the field
     * @param {string} fieldName - name of field
     */
    getFieldLabel(view, fieldName) {
        return view.$el.find(`label[for=${fieldName}]`);
    },

    toggleReadOnly(view, fieldName, readOnly) {
        const $field = this.getFieldElement(view, fieldName);
        $field.prop('readonly', readOnly)
            .prop('disabled', readOnly)
            .toggleClass('read-only-field', readOnly);
    },

    /**
     * Toggled the 'exist' validator on the model and update field element required
     * prop and toggle the parent container class to show the asterisks
     * @param {Model} model - on which validation will be toggled
     * @param {View} view - that contains the field
     * @param {string} fieldName - name of the field
     * @param {boolean} required - required or not
     */
    toggleRequired(model, view, fieldName, required) {
        const { fieldData } = model;
        const { fieldUIType } = fieldData[fieldName];
        /*
         * for masked inputs, we cant just directly change the class
         * we need to trigger an event that the maskedInputWrapper listens to
         * to update the required state
         */
        if (fieldUIType === 'MASKEDINPUTWIDGET' || fieldUIType === 'TOGGLEMASKEDWIDGET') {
            appBus.trigger(`maskedInput:changeRequired:${fieldName}`, required);
        } else {
            const $field = this.getFieldElement(view, fieldName);

            $field.prop('required', true);

            const $container = this.getContainer($field);
            $container.toggleClass('required', required);
        }
        if (required) {
            model.addValidatorProp(fieldName, 'exists', true, { silent: true });
        } else {
            model.removeValidatorProp(fieldName, 'exists', { silent: true });
        }
    },
});
