import mobileUtil from 'mobile/util/mobileUtil';
import constants from 'common/dynamicPages/api/constants';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import EmployeeGrid from './employeeGrid';

let list = EmployeeGrid;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'smbPayments.reimburse.expense',
                handleEntitlementMethodName: 'hasPayrollEntitlements',
                handlerMethodName: 'reimburseInsert',
            },
            {
                label: 'batch.Payroll_title',
                handleEntitlementMethodName: 'hasPayrollEntitlements',
                handlerMethodName: 'payrollInsert',
            },
            {
                label: 'PAY.PaymentDescription.SACHCSP',
                handleEntitlementMethodName: 'hasCSPEntitlements',
                handlerMethodName: 'childSupportInsert',
            },
        ],
        bulkActions: [
            {
                label: 'sbPayments.approve',
                entitlement: constants.ACTION_APPROVE,
            },
            {
                label: 'sbPayments.unApprove',
                entitlement: constants.ACTION_UNAPPROVE,
            },
            {
                label: 'button.reject',
                entitlement: constants.ACTION_REJECT,
            },
            {
                label: 'sbPayments.delete',
                entitlement: constants.ACTION_DELETE,
            },
        ],
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
