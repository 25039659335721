import $ from 'jquery';
import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import Bill from 'app/smbPayments/views/payments/models/bill';
import locale from '@glu/locale';
import Dialog from '@glu/dialog';
import { appBus } from '@glu/core';
import API from 'app/smb/api';
import errHandler from 'system/errHandler';
import sbConstants from 'app/smb/constants';
import CollectionComboBoxView from 'common/collectionComboBox/views/base';
import moment from 'moment';
import 'datePicker';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import newBillTmpl from './newBill.hbs';

const COMMENTS_MAX_LENGTH = 80;

export default Layout.extend({
    template: newBillTmpl,
    loadingTemplate: loadingModalTmpl,

    initialize(options) {
        this.loadRequiredData();

        this.title = options.title || '';
        this.model = options.model || new Bill();
        this.defaultDate = 0;

        this.modalClass = 'modal-lg';

        this.dialogTitle = locale.get('smbPayments.bill.add.new');

        this.dialogButtons = [{
            text: locale.get('smbPayments.bill.create'),
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('smbPayments.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    loadRequiredData() {
        const self = this;

        const vendors = API.beneficiaryList.get(sbConstants.BUSINESS_OPTION_2);

        Promise.all([vendors])
            .then((results) => {
                [self.vendors] = results;
                self.setHasLoadedRequiredData(true);
                self.render();
            })
            .then(null, errHandler);
    },

    ui: {
        $invoiceNumber: '#invoiceNumber',
        $vendorFormGroup: '.vendor-group',
        $billAmount: '#billAmount',
        $amountFormGroup: '.billing-amount-group',
        $billingDateFormGroup: '.billing-date-group',
        $descriptionFormGroup: '.bill-description-group',
        $approverFormGroup: '.approver-group',
        $description: 'textarea[name="DESCRIPTION"]',
        $billingDate: '.billing-date-container .input-date',
        $dateRange: '.dateRange',
        $billTotal: '.bill-total',
        $approver: '[data-hook="select-approver"]',
        $terms: '[data-hook="billing-terms"]',
        $commentsText: '#commentsText',
    },

    events: {
        'blur @ui.$invoiceNumber': 'handleInvoiceChange',
        'blur @ui.$description': 'handleDescriptionChange',
        'blur @ui.$billAmount': 'handleAmountChange',
        'blur @ui.$dateRange': 'handleBillingDateChange',
        'change @ui.$approver': 'handleApproverChange',
        'change @ui.$terms': 'handleTermsChange',
        'keyup @ui.$commentsText': 'showCountCharacters',
    },

    showCountCharacters(e) {
        const $target = $(e.target);
        const $targetTxtAreaVal = $target.val();
        const cmtsTxtAreaReMainLen = COMMENTS_MAX_LENGTH - $targetTxtAreaVal.length;
        $target.parent().find('.char-counter').text(`${locale.get('sbPayments.charactersRemaining')} ${cmtsTxtAreaReMainLen}`);
    },

    templateHelpers() {
        return {
            title: 'Add New Bill',
            maxLength: COMMENTS_MAX_LENGTH,
        };
    },

    submit() {
        let message = locale.get('smbPayments.bill.in.queue');

        // set terms since it doesn't appear to be setting.
        this.model.set('DUETERM', this.ui.$terms.val());

        /*
         * Set status - if an approver is set, the bill must be approved before it
         * can be scheduled
         */
        if (this.model.get('APPROVAL_ID_REQUESTED') === ''
            && this.model.get('APPROVAL_NAME_REQUESTED') === '') {
            this.model.set('STATUS', 'AP');
            this.model.set('STATUS_DESCRIPTION', 'Approved');
        } else {
            message = locale.get('smbPayments.bill.pending.in.queue');
            this.model.set('STATUS', 'EN');
            this.model.set('STATUS_DESCRIPTION', 'Entered');
        }

        if (this.model.get('_saveIncomplete')) {
            message = locale.get('smbPayments.bill.save.incomplete');
        }

        // this.appBus.trigger('bill:addBill:save', this.model, message);

        const isValid = this.model.isValid();
        if (isValid) {
            this.model.save(
                {},
                {
                    success() {
                        Dialog.alert(message);
                        appBus.trigger('bill:newBill:billAdded');
                    },
                    error(model, options) {
                        Dialog.alert(`errorCode: ${options.errorCode}errorMessage: ${options.errorMessage} ${options.responseText}`);
                    },
                },
            );

            Dialog.close();
        }
    },

    cancel() {
        Dialog.close();
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            // Setup form fields.
            this.vendorComboBoxView = new CollectionComboBoxView({
                collection: self.vendors,
                displayStache: '{{{BENE_NAME}}}',
                selectedStache: '{{{BENE_NAME}}}',
                noSelectionText: locale.get('smbPayments.vendor.select'),
                emptyText: locale.get('smbPayments.vendor.not.found'),
                addButtonText: locale.get('smbPayments.vendor.add'),
                canAdd: true,
            });

            this.vendorComboBoxView.on('select', (model) => {
                self.handleVendorChange(model);
            });

            this.vendorRegion.show(this.vendorComboBoxView);

            this.renderDatePickerContent();

            this.ui.$billAmount.inputmask('decimal', Formatter.getCurrencyMaskOptions());
        }
    },

    handleTermsChange(e) {
        const terms = $.trim($(e.target).val());
        let termDate = '';
        this.model.set('DUETERM', terms);

        switch (terms) {
        case 'Terms: Net 0':
            termDate = moment(new Date()).format('MM/DD/YYYY');
            break;
        case 'Terms: Net 10':
            termDate = moment(new Date()).add(10, 'days').calendar();
            break;
        case 'Terms: Net 15':
            termDate = moment(new Date()).add(15, 'days').calendar();
            break;
        case 'Terms: Net 30':
            termDate = moment(new Date()).add(30, 'days').calendar();
            break;
        case 'Terms: Net 60':
            termDate = moment(new Date()).add(60, 'days').calendar();
            break;
        default:
            termDate = moment(new Date()).format('MM/DD/YYYY');
        }
        this.model.set('DUEDATE', termDate);

        this.ui.$billingDateFormGroup.addClass('step-complete');
    },

    handleInvoiceChange(e) {
        const invoiceNumber = $.trim($(e.target).val());
        this.model.set('INVOICENUMBER', invoiceNumber);
    },

    handleVendorChange(model) {
        if (model) {
            this.model.set('VENDORID', model.get('BENE_BANK_ID'));
            this.model.set('VENDORNAME', model.get('BENE_NAME'));
            this.ui.$vendorFormGroup.addClass('step-complete');
        } else {
            this.model.set('VENDORID', '');
            this.model.set('VENDORNAME', '');
            this.ui.$vendorFormGroup.removeClass('step-complete');
        }
    },

    handleAmountChange(e) {
        const amount = $(e.target).val();
        if (amount) {
            this.ui.$billTotal.text(Formatter.formatCurrency(amount));
            this.model.set('TOTALAMOUNT', amount);
            this.ui.$amountFormGroup.addClass('step-complete');
        } else {
            this.ui.$amountFormGroup.removeClass('step-complete');
        }
    },

    updateBillingDate(selectedValue) {
        if (selectedValue) {
            this.ui.$billingDateFormGroup.addClass('step-complete');
        } else {
            this.ui.$billingDateFormGroup.removeClass('step-complete');
        }
    },

    handleBillingDateChange(e) {
        this.updateBillingDate($(e.target).val());
    },

    handleDescriptionChange(e) {
        const desc = $(e.target).val();
        if (desc) {
            this.ui.$descriptionFormGroup.addClass('step-complete');
        } else {
            this.ui.$descriptionFormGroup.removeClass('step-complete');
        }
    },

    handleApproverChange(e) {
        const approver = $(e.target).val();
        if (approver) {
            /*
             * Id will come when we hook in the real approver id
             * this.model.set('APPROVAL_ID_REQUESTED', approver.attributes.id);
             */
            this.model.set('APPROVAL_NAME_REQUESTED', approver);
            this.ui.$approverFormGroup.addClass('step-complete');
        } else {
            this.ui.$approverFormGroup.removeClass('step-complete');
        }
    },

    renderDatePickerContent() {
        const self = this;

        const datePicker = this.$el.find('#dateRange');
        datePicker.nhDatePicker({
            singleDatePicker: true,
            showCalendarIcon: true,
            daysBack: 0,
            startDate: moment(new Date()).add(30, 'days').calendar(),
        });

        datePicker.on('apply.daterangepicker', (e) => {
            self.model.set('DUEDATE', e.target.value);
        });
    },
});
