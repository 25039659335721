import constants from 'app/balanceAndTransaction/constants';
import services from 'services';
import AccountDetailsView from './accountDetails';

const PreviousDayAccountDetails = AccountDetailsView.extend({
    initialize(options) {
        AccountDetailsView.prototype.initialize.call(this, options);
    },

    /**
     * Override getModelSErviceUrl function to use route for previous day accounts.
     *
     * @this {getModelServiceUrl}
     */
    getModelServiceUrl() {
        return services.generateUrl(`${constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedUngroupedAccounts_PD`);
    },
});

export default PreviousDayAccountDetails;
