import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import { GearNextIcon } from '@glu/icons-react';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import util from 'underscore';

let list = ListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'SETUP',
            serviceName: 'tableMaintenance/clientSetting',
            serviceFunc: null,
            businessType: null,
            context: 'MODIFY_CLIENT',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/viewClientSettings');
        return Promise.resolve();
    },

    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/modifyClientSettings');
        return Promise.resolve();
    },
});

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'CLIENT_SETTINGS',
    options: {},
    useMobileCard: true,
    view: list,
    mobileCardProps: {
        icon: GearNextIcon,
    },
});

const exportedList = list;

export default exportedList;
