import ItemView from '@glu/core/src/itemView';
import Model from '@glu/core/src/model';
import internalLinkTmpl from './internalLink.hbs';

export default ItemView.extend({
    template: internalLinkTmpl,
    className: 'dynamic-full-page',

    initialize(options) {
        this.model = new Model({
            iframeSrc: options.iframeSrc,
        });
    },
});
