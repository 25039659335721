export default {
    /**
     * When dom element is clicked, move the cursor all the way to the end
     * @param {Event} e - DOM event
     */
    moveCursor: (e) => {
        const end = e.currentTarget.value.length;
        e.currentTarget.setSelectionRange(end, end);
    },
};
