import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import contextApi from 'common/dynamicPages/api/context';
import gridApi from 'common/dynamicPages/api/grid';
import entitlements from 'common/dynamicPages/api/entitlements';
import constants from 'app/smbPayments/constants';
import loadingTemplate from 'common/templates/loadingModal.hbs';
import alert from '@glu/alerts';
import AlertDetails from 'app/smbPayments/alerts/paymentAlertDetails';
import template from './transferGrid.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    regions: {
        gridRegion: '#paymentsGrid',
        alertRegion: '[data-region="alertRegion"]',
    },

    events: {
        'click .add-payment': 'addPayment',
        'click .delete-payments': 'deletePayments',
        'click .approve-payments': 'approvePayments',
        'click .unapprove-payments': 'unapprovePayments',

        // NH-7196  TEST ONLY
        'click .test-submit': 'testSubmit',
    },

    /** NH-6059 TEST ONLY * */
    appEvents: {
        'payment:newPayment:gridAlert': 'renderPaymentAlertContent',
    },

    /** END TEST ONLY * */

    initialize() {
        this.gridLoaded = false;
        this.loadViewRequirements();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        }
    },

    templateHelpers() {
        const self = this;

        return {
            hasInsertEntitlement() {
                return self.hasEntitlement('INSERT');
            },

            hasApproveEntitlement() {
                return self.hasEntitlement('APPROVE');
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement('DELETE');
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement('UNAPPROVE');
            },
        };
    },

    loadViewRequirements() {
        const self = this;

        const options = {
            context: contextApi.menuContext.getContext(constants.TRNSFR_GRID_CONTEXT),

            // default grid action buttons are hidden
            hideGridActionButtons: true,

            // instead of executing context actions, grid handles callback
            enableRowContextButtonCallbacks: true,

            loadedCallback() {
                self.gridLoaded = true;
            },
        };

        const gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);

        entitlementPromise.then((result) => {
            self.gridView = gridView;
            self.entitlements = result;
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    hasEntitlement(action) {
        return this.entitlements.actions[action];
    },

    gridRowAction(options) {
        return new Promise((resolve, reject) => {
            const self = this;

            if (options.action.toUpperCase() === 'SELECT') {
                window.Bottomline.store[constants.STORE_TRANSFERS_CONTEXT] = options.model.context;
                window.Bottomline.store[constants.STORE_TRANSFERS_VIEWMODEL] = options.model;
                this.navigateTo('payments/viewPayment');
                resolve();
            }

            if (options.action.toUpperCase() === 'DELETE') {
                dialog.warning(locale.get('smbPayments.delete.confirm'), (ok) => {
                    if (ok) {
                        options.model.destroy({
                            success() {
                                self.gridView.refreshGridData();
                                dialog.alert('Deleted Successfully');
                                resolve();
                            },

                            error(opts) {
                                self.gridView.refreshGridData();
                                const message = 'Error while deleting the transfer: ';
                                dialog.alert(message + opts.message);
                                reject(new Error(opts.message));
                            },
                        });
                    }
                });
            }

            if (options.action.toUpperCase() === 'APPROVE') {
                dialog.confirm(locale.get('smbPayments.approve.confirm'), (ok) => {
                    if (ok) {
                        options.model.approve({
                            success() {
                                self.gridView.refreshGridData();
                                dialog.alert(locale.get('smbPayments.approve.success'));
                                resolve();
                            },

                            error(opts) {
                                self.gridView.refreshGridData();
                                const message = locale.get('smbPayments.approve.transfer.error');
                                dialog.alert(message + opts.message);
                                reject(new Error(opts.message));
                            },
                        });
                    }
                });
            }

            if (options.action.toUpperCase() === 'UNAPPROVE') {
                dialog.confirm(locale.get('smbPayments.approve.confirm'), (ok) => {
                    if (ok) {
                        options.model.unapprove({
                            success() {
                                self.gridView.refreshGridData();
                                dialog.alert(locale.get('smbPayments.unapprove.success'));
                                resolve();
                            },

                            error(opts) {
                                self.gridView.refreshGridData();
                                const message = locale.get('smbPayments.unapprove.error');
                                dialog.alert(message + opts.message);
                                reject(new Error(opts.message));
                            },
                        });
                    }
                });
            }

            if (options.action.toUpperCase() === 'MODIFY') {
                window.Bottomline.store[constants.STORE_TRANSFERS_CONTEXT] = options.model.context;
                window.Bottomline.store[constants.STORE_TRANSFERS_VIEWMODEL] = options.model;
                this.navigateTo('payments/modifyPayment');
                resolve();
            }
        });
    },

    deletePayments() {
        if (this.gridLoaded) {
            const ar = this.gridView.grid.getSelectedRows();
            if (ar.length > 0) {
                const self = this;
                dialog.warning(locale.get('smbPayments.delete.confirm'), (ok) => {
                    if (ok) {
                        const successMethod = util.after(ar.length, () => {
                            self.gridView.refreshGridData();
                            dialog.alert(locale.get('smbPayments.delete.success'));
                        });

                        const errorMethod = util.after(ar.length, () => {
                            self.gridView.refreshGridData();
                            dialog.alert(locale.get('smbPayments.delete.error'));
                        });

                        for (let i = 0; i < ar.length; i += 1) {
                            const model = self.gridView.wrapper.rows.get(ar[i]);
                            model.destroy({
                                success: successMethod,
                                error: errorMethod,
                            });
                        }
                    }
                });
            }
        }
    },

    approvePayments() {
        if (this.gridLoaded) {
            const ar = this.gridView.grid.getSelectedRows();
            if (ar.length > 0) {
                const self = this;
                dialog.confirm(locale.get('smbPayments.approve.confirm'), (ok) => {
                    if (ok) {
                        const successMethod = util.after(ar.length, () => {
                            self.gridView.refreshGridData();
                            dialog.alert(locale.get('smbPayments.approve.success'));
                        });
                        const errorMethod = util.after(ar.length, () => {
                            self.gridView.refreshGridData();
                            dialog.alert(locale.get('smbPayments.approve.transfer.error'));
                        });

                        for (let i = 0; i < ar.length; i += 1) {
                            const model = self.gridView.wrapper.rows.get(ar[i]);
                            model.approve({
                                success: successMethod,
                                error: errorMethod,
                            });
                        }
                    }
                });
            }
        }
    },

    unapprovePayments() {
        if (this.gridLoaded) {
            const ar = this.gridView.grid.getSelectedRows();
            if (ar.length > 0) {
                const self = this;
                dialog.confirm(locale.get('smbPayments.unapprove.confirm'), (ok) => {
                    if (ok) {
                        const successMethod = util.after(ar.length, () => {
                            self.gridView.refreshGridData();
                            dialog.alert(locale.get('smbPayments.unapprove.success'));
                        });
                        const errorMethod = util.after(ar.length, () => {
                            self.gridView.refreshGridData();
                            dialog.alert(locale.get('smbPayments.unapprove.error'));
                        });

                        for (let i = 0; i < ar.length; i += 1) {
                            const model = self.gridView.wrapper.rows.get(ar[i]);
                            model.unapprove({
                                success: successMethod,
                                error: errorMethod,
                            });
                        }
                    }
                });
            }
        }
    },

    /** NH-7196 TEST ONLY * */
    testSubmit() {
        dialog.alert('Test submit');
    },

    //        /** TEST ONLY **/

    renderPaymentAlertContent(model, message) {
        // create the details view for the alert
        const paymentDetails = new AlertDetails({
            model,
        });

        // display notification message
        this.alertView = alert.success(
            message,
            {
                details: paymentDetails,
            },
        );

        this.alertRegion.show(this.alertView);
    },
});
