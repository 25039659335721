var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"NavTabs-item is-active\"\n                        role=\"presentation\">\n                        <a aria-controls=\"forecast\"\n                            class=\"NavTabs-link btn btn-tertiary\"\n                            href=\"#forecast\"\n                            role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.CashflowForecast",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":39},"end":{"line":13,"column":72}}}))
    + "\n                        </a>\n                    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"NavTabs-item\"\n                        role=\"presentation\">\n                        <a aria-controls=\"plan\"\n                            class=\"NavTabs-link btn btn-tertiary\"\n                            href=\"#plan\"\n                            role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.CashflowPlanning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":72}}}))
    + "\n                        </a>\n                    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"NavTabs-item\"\n                        role=\"presentation\">\n                        <a aria-controls=\"groupplan\"\n                            class=\"NavTabs-link btn btn-tertiary\"\n                            href=\"#groupplan\"\n                            role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.CashflowGroupPlanning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":39},"end":{"line":33,"column":77}}}))
    + "\n                        </a>\n                    </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"NavTabs-item\"\n                        role=\"presentation\">\n                        <a aria-controls=\"compare\"\n                            class=\"NavTabs-link btn btn-tertiary\"\n                            href=\"#compare\"\n                            role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.CashflowComparison",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":39},"end":{"line":43,"column":74}}}))
    + "\n                        </a>\n                    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <ul class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            id=\"navigation-header\"\n            role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasForecastEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPlanningEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":26,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasGroupPlanningEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":36,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPlanCompareEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":46,"column":23}}})) != null ? stack1 : "")
    + "        </ul>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"pageRegion remove-section-styles\"></div>\n    </div>\n</div>\n";
},"useData":true});