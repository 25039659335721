import util from '@glu/core/src/util';
import ItemView from './modal';
import Dialog from '../largeComment/dialog';

export default ItemView.extend({
    initialize(options) {
        this.model = options.model;
        this.column = options.column;
        this.cellHeader = this.column.get('title');
        this.cellValue = this.model.get(this.column.get('field'));
        this.reasons = this.adjustmentReasons;
        this.types = this.referenceNumberTypes;
    },

    viewModal() {
        // only pay a business...right now
        if (this.reasons && this.types) {
            this.populateSelections();
        }
        Dialog.complexAlert(this.cellValue, this.cellHeader);
    },

    findAdjustmentReason() {
        const model = this.reasons.findWhere({
            id: this.cellValue.get('adjustmentReason'),
        });
        return util.isEmpty(model) ? '' : model.get('description');
    },

    findReferenceNumberType() {
        const model = this.types.findWhere({
            id: this.cellValue.get('referenceNumberType'),
        });
        return util.isEmpty(model) ? '' : model.get('description');
    },

    populateSelections() {
        this.cellValue.set('adjustmentReasonDesc', this.findAdjustmentReason());
        this.cellValue.set('referenceNumberTypeDesc', this.findReferenceNumberType());
    },

    cellValueExists() {
        const modelValue = this.model.get(this.column.get('field'));
        if (modelValue.isEmpty()) {
            return false;
        }
        return true;
    },

    templateHelpers() {
        return {
            cellHeader: this.cellHeader,
            cellHeaderExists: !util.isEmpty(this.cellHeader),
            cellValueExists: this.cellValueExists(),
        };
    },
});
