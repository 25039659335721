var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAIDINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                        <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAIDINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"PAIDINVOICEAMOUNT") || (depth0 != null ? lookupProperty(depth0,"PAIDINVOICEAMOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PAIDINVOICEAMOUNT","hash":{},"data":data,"loc":{"start":{"line":30,"column":55},"end":{"line":30,"column":76}}}) : helper)))
    + "</p>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TOTALINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                        <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TOTALINVOICEAMOUNT") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TOTALINVOICEAMOUNT") || (depth0 != null ? lookupProperty(depth0,"TOTALINVOICEAMOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TOTALINVOICEAMOUNT","hash":{},"data":data,"loc":{"start":{"line":36,"column":55},"end":{"line":36,"column":77}}}) : helper)))
    + "</p>\n                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TERMSDISCOUNT") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + "field-container\">\n                            <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"TERMSDISCOUNT") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TERMSDISCOUNT") || (depth0 != null ? lookupProperty(depth0,"TERMSDISCOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TERMSDISCOUNT","hash":{},"data":data,"loc":{"start":{"line":42,"column":59},"end":{"line":42,"column":76}}}) : helper)))
    + "</p>\n                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \n                <h4 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.addenda.reference.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":45},"end":{"line":52,"column":87}}}))
    + "</h4>\n            \n                <div class=\"row\">\n                    \n                    <div class=\"form-column inline-fields\">\n                        \n                        <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                            <label>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                            <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"valueFor")||(depth0 && lookupProperty(depth0,"valueFor"))||container.hooks.helperMissing).call(alias1,"REF_REFNUMBERQUALIFIER",{"name":"valueFor","hash":{},"data":data,"loc":{"start":{"line":60,"column":59},"end":{"line":60,"column":96}}}))
    + "</p>\n                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"RMT_REFNUMBER") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":67,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PAYMENTDESCRIPTION") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":73,"column":27}}})) != null ? stack1 : "")
    + "\n                    </div>\n\n                </div>\n            \n        \n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                            <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"REF_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"REF_REFNUMBER") || (depth0 != null ? lookupProperty(depth0,"REF_REFNUMBER") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"REF_REFNUMBER","hash":{},"data":data,"loc":{"start":{"line":65,"column":59},"end":{"line":65,"column":76}}}) : helper)))
    + "</p>\n                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"fcol-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAYMENTDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                            <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"PAYMENTDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"PAYMENTDESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"PAYMENTDESCRIPTION") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"PAYMENTDESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":71,"column":59},"end":{"line":71,"column":81}}}) : helper)))
    + "</p>\n                        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        \n                <h4 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.addenda.date.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":45},"end":{"line":84,"column":82}}}))
    + "</h4>\n        \n                <div class=\"row\">\n                    \n                    <div class=\"form-column inline-fields\">\n                        <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                            <label>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + ":</label>\n                            <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"valueFor")||(depth0 && lookupProperty(depth0,"valueFor"))||container.hooks.helperMissing).call(alias1,"DAT_REFNUMBERQUALIFIER",{"name":"valueFor","hash":{},"data":data,"loc":{"start":{"line":91,"column":59},"end":{"line":91,"column":96}}}))
    + "</p>\n                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DAT_REFNUMBER") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":20},"end":{"line":98,"column":27}}})) != null ? stack1 : "")
    + "                    </div>\n                    \n                </div>\n            \n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"col-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                                <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"DAT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + ":</label>\n                                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"DAT_REFNUMBER") || (depth0 != null ? lookupProperty(depth0,"DAT_REFNUMBER") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"DAT_REFNUMBER","hash":{},"data":data,"loc":{"start":{"line":96,"column":63},"end":{"line":96,"column":80}}}) : helper)))
    + "</p>\n                            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \n                <h4 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.addenda.adjustment.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":107,"column":45},"end":{"line":107,"column":88}}}))
    + "</h4>\n            \n                <div class=\"row\">\n                    \n                    <div class=\"form-column inline-fields\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"AMOUNTTOADJ") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":20},"end":{"line":117,"column":27}}})) != null ? stack1 : "")
    + "\n                    <div class=\"col-md-"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJREASONCODE") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                        <label>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJREASONCODE") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + ":</label>\n                        <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"valueFor")||(depth0 && lookupProperty(depth0,"valueFor"))||container.hooks.helperMissing).call(alias1,"ADJREASONCODE",{"name":"valueFor","hash":{},"data":data,"loc":{"start":{"line":121,"column":55},"end":{"line":121,"column":83}}}))
    + "</p>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ADJDESCRIPTION") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":20},"end":{"line":129,"column":27}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"AMOUNTTOADJ") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                            <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"AMOUNTTOADJ") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + ":</label>\n                            <p class=\"form-control-static\"> - "
    + alias2(((helper = (helper = lookupProperty(helpers,"AMOUNTTOADJ") || (depth0 != null ? lookupProperty(depth0,"AMOUNTTOADJ") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"AMOUNTTOADJ","hash":{},"data":data,"loc":{"start":{"line":115,"column":62},"end":{"line":115,"column":77}}}) : helper)))
    + "</p>\n                        </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-md-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                            <label>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"ADJDESCRIPTION") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + ":</label>\n                            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"ADJDESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"ADJDESCRIPTION") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ADJDESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":127,"column":59},"end":{"line":127,"column":77}}}) : helper)))
    + "</p>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div class=\"panel-heading\" role=\"tab\" id=\"heading"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":2,"column":53},"end":{"line":2,"column":60}}}) : helper)))
    + "\">\n        <h3 class=\"panel-title\">\n            <a role=\"button\" data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":92},"end":{"line":4,"column":99}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":146},"end":{"line":4,"column":153}}}) : helper)))
    + "\" class=\"collapsed\">\n                <span class=\"indicator-icon\"></span>Addenda "
    + alias4(((helper = (helper = lookupProperty(helpers,"ui_sequenceNumber") || (depth0 != null ? lookupProperty(depth0,"ui_sequenceNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ui_sequenceNumber","hash":{},"data":data,"loc":{"start":{"line":5,"column":60},"end":{"line":5,"column":81}}}) : helper)))
    + " - "
    + alias4((lookupProperty(helpers,"valueFor")||(depth0 && lookupProperty(depth0,"valueFor"))||alias2).call(alias1,"RMT_REFNUMBERQUALIFIER",{"name":"valueFor","hash":{},"data":data,"loc":{"start":{"line":5,"column":84},"end":{"line":5,"column":121}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"RMT_REFNUMBER") || (depth0 != null ? lookupProperty(depth0,"RMT_REFNUMBER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RMT_REFNUMBER","hash":{},"data":data,"loc":{"start":{"line":5,"column":122},"end":{"line":5,"column":139}}}) : helper)))
    + "\n            </a>\n        </h3>\n    </div>\n    <div id=\"collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":21},"end":{"line":9,"column":28}}}) : helper)))
    + "\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"heading"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":102},"end":{"line":9,"column":109}}}) : helper)))
    + "\">\n        <div class=\"panel-body\">\n            <h4 class=\"form-group-label\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.addenda.remittance.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":84}}}))
    + "</h4>\n\n            <div class=\"row\">\n\n                <div class=\"form-column inline-fields\">\n\n                    <div class=\"col-md-"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                        <label>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBERQUALIFIER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                        <p class=\"form-control-static\">"
    + alias4((lookupProperty(helpers,"valueFor")||(depth0 && lookupProperty(depth0,"valueFor"))||alias2).call(alias1,"RMT_REFNUMBERQUALIFIER",{"name":"valueFor","hash":{},"data":data,"loc":{"start":{"line":19,"column":55},"end":{"line":19,"column":92}}}))
    + "</p>\n                    </div>\n\n                    <div class=\"col-md-"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"columnSpan") : stack1), depth0))
    + " field-container\">\n                        <label>"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"RMT_REFNUMBER") : stack1)) != null ? lookupProperty(stack1,"fieldLabel") : stack1), depth0))
    + "</label>\n                        <p class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"RMT_REFNUMBER") || (depth0 != null ? lookupProperty(depth0,"RMT_REFNUMBER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"RMT_REFNUMBER","hash":{},"data":data,"loc":{"start":{"line":24,"column":55},"end":{"line":24,"column":72}}}) : helper)))
    + "</p>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"PAIDINVOICEAMOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TOTALINVOICEAMOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":17},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TERMSDISCOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":44,"column":23}}})) != null ? stack1 : "")
    + "                \n                </div>\n\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAnyReferenceInformation") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":80,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAnyDateInformation") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":12},"end":{"line":103,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAnyAdjustmentInformation") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":12},"end":{"line":132,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"useData":true});