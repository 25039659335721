import {
    appConfigsActions,
    configParamsActions,
    pageContextActions,
    userInfoActions,
    alertMessageActions,
    formActions,
} from '@dbiqe/mdf';
import { reduxStore } from 'components/StoreProvider/StoreProvider';

const getState = () => reduxStore.getState();

const setGridActionData = (actionData) => {
    const mode = (actionData.actionType || actionData.actionMode)?.toLowerCase();
    reduxStore.dispatch({
        type: pageContextActions.actionTypes.ADD_ACTION_DATA,
        payload: {
            mode,
            actionData,
        },
    });
};

const resetPageContext = () => {
    reduxStore.dispatch({
        type: pageContextActions.actionTypes.RESET_PAGECONTEXT,
        payload: {},
    });
};

const setAppConfigs = (appConfigs = {}) => {
    reduxStore.dispatch({
        type: appConfigsActions.actionTypes.ADD_APP_CONFIGS,
        payload: {
            appConfigs,
        },
    });
};

const setConfigParams = (configParams = {}) => {
    reduxStore.dispatch({
        type: configParamsActions.actionTypes.ADD_CONFIG_PARAMS,
        payload: {
            configParams,
        },
    });
};

const setUserInfo = (userInfo = {}) => {
    reduxStore.dispatch({
        type: userInfoActions.actionTypes.ADD_USER_INFO,
        payload: {
            userInfo,
        },
    });
};

const removeAlertMessage = (contextKey) => {
    reduxStore.dispatch({
        type: alertMessageActions.actionTypes.REMOVE_ALERT_MESSAGE,
        payload: {
            key: contextKey,
        },
    });
};

const getAlertMessage = (contextKey) => {
    const state = getState();
    return state?.alertMessages?.[contextKey] || {};
};

const resetFormState = () => {
    reduxStore.dispatch({
        type: formActions.actionTypes.RESET,
        payload: {
            pageContextKeysToKeep: ['previousRoute', 'currentRoute'],
        },
    });
};

const updateRoutes = (route) => {
    reduxStore.dispatch({
        type: pageContextActions.actionTypes.SET_NEW_ROUTE,
        payload: {
            route,
        },
    });
};

export {
    getState,
    setAppConfigs,
    setConfigParams,
    setGridActionData,
    resetPageContext,
    setUserInfo,
    removeAlertMessage,
    getAlertMessage,
    resetFormState,
    updateRoutes,
};

export default reduxStore.getState();
