import EntryView from 'common/dynamicPages/views/workflow/entry';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import actionFunction from 'app/payments/api/common';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'PMTS',
            serviceName: 'templates/corp',
            serviceFunc: null,
            businessType: null,
            context: 'TEMP_LIST_VIEW',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    repair() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('TEMPLATES/repairTemplate');
    },

    modify() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('TEMPLATES/modifyTemplate');
    },

    modifyvfimport() {
        const importContext = {
            serviceName: '/modifyvfimport/import',
            actionMode: 'IMPORT',
            productCode: 'USACH',
            functionCode: 'IMPORT',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        store.set(`${this.contextKey}-preFill`, this.tempModel);
        this.navigateTo('IMPORT/modifyvFImport');
    },

    makeinst() {
        actionFunction.makePayment(this.storedModel, this.contextKey);
        this.navigateTo('PAYMENTS/addPaymentFromTemplate');
    },

    copytmpl() {
        actionFunction.makeTemplate(this.storedModel, this.contextKey);
        this.navigateTo('TEMPLATES/addTemplateFromPayment');
    },

    qentry() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('PAYMENTS/quickEntry');
    },

    spaymt() {
        const { options } = this;
        const model = this.storedModel;
        options.action = 'spaymt';
        if (model.get('CONINTLWIREFLAG') === '1') {
            dialog.error(locale.get('common.schedule.conintl.error'), 'Error');
        } else {
            options.serviceName = '/tableMaintenance/scheduledPayments';
            model.context.serviceName = '/tableMaintenance/scheduledPayments';
            model.set({
                TYPE: 'ACH_SCHE',
                FUNCTION: 'MAINT',
                SUBTYPE: '*',
            });
            model.context.actionData.functionCode = 'MAINT';
            model.context.actionData.productCode = this.contextDef.actionData.productCode;
            model.context.actionData.typeCode = 'ACH_SCHE';
            model.context.actionData.subType = '*';
            store.set(`${this.contextKey}-actionModel`, model);
            this.navigateTo('TEMPLATES/schedulePayment');
        }
    },

    /**
     * @method restore
     * - callback function to navigate to restore template screen from template
     * details screen
     */

    restore() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('TEMPLATES/restoreTemplate');
    },
});
