import serverConfigParams from 'system/webseries/models/configurationParameters';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import moment from 'moment';
import dateUtil from 'common/util/dateUtil';
import userInfo from 'etc/userInfo';

export default function (form, initialState) {
    const { formView } = form;
    const { model } = form.formView;
    const formState = formView.state;
    const userId = form.field('USERID');
    const lastName = form.field('LASTNAME');
    const firstName = form.field('FIRSTNAME');
    const userName = form.field('USERNAME');
    const ssoId = form.field('SSOID');
    const emailAddress = form.field('EMAILADDRESS');
    const tokenSerialNumber = form.field('TOKENSERIALNUMBER');
    const password = form.field('PASSWORD');
    const confirmPassword = form.field('CONFIRMPASSWORD');
    const phoneField = form.field('PHONENUMBER');
    const mobileField = form.field('MOBILEPHONENUMBER');
    const activationDate = form.field('ACTIVATIONDATE');

    // checkboxes
    const clearOTP = form.field('CLEAROTP');
    const mobile = form.field('MOBILE');
    const disableUser = form.field('DISABLEUSER');
    const allowAccountUnMasking = form.field('ALLOWACCOUNTUNMASKING');
    const enablePushNotifications = form.field('ENABLEPUSHNOTIFICATIONS');
    const clearSecurityQuestions = form.field('CLEARSECURITYQUESTIONS');
    const legalAdmin = form.field('LEGALADMIN');

    const isAdmin = (window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal');
    const userCompany = formView.options.smbOverride.get('COMPANY');
    const hasSecurityQuestions = model.get('SECURITYQUESTIONSENABLED');
    const isSSO = (serverConfigParams.get('ClientAppOnSSO') === 'true');
    const enableActivationDate = serverConfigParams.get('enableActivationDate');
    const isLegalAdminShow = serverConfigParams.get('ShowLegalAdmin') === 'true' && (isAdmin || (formState === 'modify' && model.get('LEGALADMIN')));
    // const isSplitUserName = (serverConfigParams.get('userMaintSplitUserName') === 'true');
    const showTokenSerialNum = serverConfigParams.get('showTokenSerialNum') === 'true';
    const showAllowAccountUnmasking = userCompany ? (userCompany.get('allowAccountUnmasking') && serverConfigParams.get('DisableClientAccountMasking') === 'false') : false;
    const showEnablePushNotifications = userCompany ? (userCompany.get('enablePushNotifications') && serverConfigParams.get('EnablePushNotification') === 'true') : false;
    const showMobile = userCompany ? (userCompany.get('isMobile')) : false;

    const modifyAndLegalAdmin = (formState === 'modify' && model.get('LEGALADMIN') === '1');

    const renderDatePickerContent = function () {
        const $activationDatePicker = form.formView.$('[name="ACTIVATIONDATE"]');
        $activationDatePicker.nhDatePicker({
            blockedDates: '1111111',
            daysBack: 0,
            daysForward: 300,
            showCalendarIcon: true,
        });
        if (!model.get('ACTIVATIONDATE')) {
            model.set({
                ACTIVATIONDATE: moment(new Date()).startOf('day').format(userInfo.getDateFormat()),
            }, {
                silent: true,
            });
        }
    };

    const setChallengeListener = function () {
        model.on('change:CHALLENGEMETHOD', () => {
            if (model.get('CHALLENGEMETHOD') === constants.OTP_CHALLENGE_METHOD) {
                clearOTP.shouldBeVisible();
            } else {
                clearOTP.shouldBeHidden();
            }

            if (model.get('CHALLENGEMETHOD') === constants.VASCO_CHALLENGE_METHOD || model.get('CHALLENGEMETHOD') === constants.SYMANTEC_VIP_CHALLENGE_METHOD) {
                tokenSerialNumber.shouldBeVisible();
            } else if (showTokenSerialNum) {
                tokenSerialNumber.shouldBeVisible();
            } else {
                tokenSerialNumber.shouldBeHidden();
            }
        });
    };

    const showActivationDate = function () {
        if (enableActivationDate !== 'Y') {
            activationDate.shouldBeHidden();
        } else if (enableActivationDate === 'Y' && model.get('APPROVEDBYTIMESTAMP') && model.get('ACTIVATIONDATE')) {
            if (moment.duration(dateUtil.getMoment(model.get('ACTIVATIONDATE')).diff(dateUtil.getMoment())).asDays() <= 0) {
                activationDate.shouldBeRequired(false);
                activationDate.shouldBeReadOnly(true);
            } else {
                activationDate.shouldBeReadOnly(false);
                activationDate.shouldBeRequired(true);
            }
        } else {
            activationDate.shouldBeReadOnly(false);
            activationDate.shouldBeRequired(true);
        }
    };

    /**
     * this function is used with PASSWORD and CONFIRMPASSWORD
     * if these fields are the same and one has a lingering in-line error message,
     * clear it out
     * @param field2check
     */
    const checkSameValuePassword = (field2check) => {
        const $validator = form.formView.$(`[data-validate="${field2check}"]`);
        if ($validator.closest('.has-error').length > 0 && (model.get('CONFIRMPASSWORD') === model.get('PASSWORD'))) {
            $validator.text('').closest('.has-error').removeClass('has-error');
        }
    };

    /**
     * remove validator for PASSWORD & CONFIRMPASSWORD and clear any remaining
     * inline-error messages
     */
    const clearPasswordValidation = () => {
        if (password.$el.length > 0) {
            password.removeValidator('exists');
            password.removeValidator('sameValue').$el.parent().removeClass('required');
            // remove any inline errors
            form.formView.$('[data-validate="PASSWORD"]').text('').closest('.has-error').removeClass('has-error');
        }
        if (confirmPassword.$el.length > 0) {
            confirmPassword.removeValidator('exists');
            confirmPassword.removeValidator('sameValue').$el.parent().removeClass('required');
            // remove any inline errors
            form.formView.$('[data-validate="CONFIRMPASSWORD"]').text('').closest('.has-error').removeClass('has-error');
        }
    };

    /**
     * Toggle model validation and required class in the DOM for password fields
     * @param {boolean} required
     */
    const requirePassword = (required) => {
        if (password.$el.length === 0 || confirmPassword.$el.length === 0) {
            return;
        }
        if (required) {
            password.setValidator(password.$el, 'exists', true);
            password.shouldMatchField(confirmPassword).$el.parent().addClass('required');
            confirmPassword.setValidator(confirmPassword.$el, 'exists', true);
            confirmPassword.shouldMatchField(password).$el.parent().addClass('required');
        } else {
            password.setValidator(password.$el, 'exists', false);
            password.shouldMatchField(confirmPassword).$el.parent().removeClass('required');
            confirmPassword.setValidator(confirmPassword.$el, 'exists', false);
            confirmPassword.shouldMatchField(password).$el.parent().removeClass('required');
        }
    };

    if (initialState && ['insert', 'modify', 'view'].includes(formState)) {
        disableUser.shouldBeHidden();
        mobile.shouldBeHidden();
        enablePushNotifications.shouldBeHidden();
        allowAccountUnMasking.shouldBeHidden();
        clearOTP.shouldBeHidden();
        clearSecurityQuestions.shouldBeHidden();
        legalAdmin.shouldBeHidden();

        if (!isSSO) {
            ssoId.shouldBeHidden();
        }
        if (formState !== 'insert') {
            userId.shouldBeReadOnly(true);
        }
        if (!isAdmin && modifyAndLegalAdmin) {
            emailAddress.shouldBeReadOnly(true);
        }

        if (isAdmin) {
            model.set({
                USERGROUP: formView.options.smbOverride.get('USERGROUP'),
            });
        }
        /* if (isSplitUserName) {
            userName.shouldBeHidden();
        } else {
            firstName.shouldBeHidden();
            lastName.shouldBeHidden();
        } */
        userName.shouldBeVisible();
        firstName.shouldBeVisible();
        lastName.shouldBeVisible();
        if (!showTokenSerialNum) {
            if (model.get('CHALLENGEMETHOD') === constants.VASCO_CHALLENGE_METHOD || model.get('CHALLENGEMETHOD') === constants.SYMANTEC_VIP_CHALLENGE_METHOD) {
                tokenSerialNumber.shouldBeVisible();
            } else {
                tokenSerialNumber.shouldBeHidden();
            }
        }

        if (formState === 'modify') {
            disableUser.shouldBeVisible();
            if (model.get('CHALLENGEMETHOD') === constants.OTP_CHALLENGE_METHOD) {
                clearOTP.shouldBeVisible();
            } else {
                clearOTP.shouldBeHidden();
            }
        }

        if (serverConfigParams.get('EnableInternationalPhoneNumbers') === 'true') {
            /*
             * override the PHONE validator and allow up to 20 characters,
             * support at least a +, -, ( and space, and not force a format
             */
            ['PHONENUMBER', 'MOBILEPHONENUMBER'].forEach((phoneFieldName) => {
                const oldValidator = model.validators[phoneFieldName];

                if (oldValidator) {
                    model.removeValidator(phoneFieldName);
                    model.addValidator(phoneFieldName, {
                        ...oldValidator,
                        overrideError: 'internationalPhoneNumber',
                        matches: /^[0-9 ()+-]+$/,
                    });
                }
            });
        } else {
            mobileField.$el.inputmask(userInfo.getPhoneFormat());
            phoneField.$el.inputmask(userInfo.getPhoneFormat());
        }

        if (showMobile) {
            mobile.shouldBeVisible();
        }
        if (showAllowAccountUnmasking) {
            allowAccountUnMasking.shouldBeVisible();
        }
        if (showEnablePushNotifications) {
            enablePushNotifications.shouldBeVisible();
        }
        if (hasSecurityQuestions) {
            clearSecurityQuestions.shouldBeVisible();
        }
        if (isLegalAdminShow) {
            if (isAdmin) {
                legalAdmin.shouldBeVisible();
            } else if (formState === 'modify') {
                legalAdmin.shouldBeReadOnly(true);
            }
        }

        setChallengeListener();
        showActivationDate();

        if (enableActivationDate === 'Y' && formState !== 'view') {
            renderDatePickerContent();
            activationDate.setValidator(activationDate.$el, 'exists', true);
            activationDate.setValidator(activationDate.$el, 'description', locale.get('_ADMIN.USERS._USER.*.ACTIVATIONDATE.label'));
            activationDate.setValidator(activationDate.$el, 'matchesDatePattern', userInfo.getDateFormat());
        }

        if (formState === 'insert') {
            requirePassword(true);
            model.set('TIMEZONEID', userCompany.get('timezone'));
            model.set('LOCALE', userCompany.get('locale'));
        }

        model.listenTo(model, 'valid:field:PASSWORD', () => {
            /*
             * check if CONFIRMPASSWORD has an error message and matches password, then
             * clear out the message
             */
            checkSameValuePassword('CONFIRMPASSWORD');
        });

        model.listenTo(model, 'valid:field:CONFIRMPASSWORD', () => {
            /*
             * check if PASSWORD has an error message and matches password, then clear
             * out the message
             */
            checkSameValuePassword('PASSWORD');
        });
    } else if ((password.$el.length > 0 && password.getValue().length > 0)
                || (confirmPassword.$el.length > 0 && confirmPassword.getValue().length > 0)) {
        password.setValidator(password.$el, 'exists', true);
        password.shouldMatchField(confirmPassword).$el.parent().addClass('required');
        confirmPassword.setValidator(confirmPassword.$el, 'exists', true);
        confirmPassword.shouldMatchField(password).$el.parent().addClass('required');
    } else if (formState === 'modify' && (confirmPassword.$el.length > 0 && confirmPassword.getValue().length === 0)
               && (password.$el.length > 0 && password.getValue().length === 0)) {
        // in modify & both password & confirm password are empty, remove validation
        clearPasswordValidation();
    }
}
