import ActionCell from 'common/dynamicPages/views/gridActionCell';

const BtrActionCellView = ActionCell.extend({
    initialize(options) {
        ActionCell.prototype.initialize.call(this, options);

        const fieldName = this.column.get('fieldName');

        this.model.buttons = [{
            value: `action_select_${this.model.parentViewId}`,
            label: this.model.get(fieldName),
            action: 'SELECT',
        }];
    },
});

export default BtrActionCellView;
