var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset class=\"section section-container\">\n        <div class=\"section-header \">\n            <legend>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Payment.Methods",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":65}}}))
    + "</legend>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-12\">\n                <div class=\"PanelApproval-col\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"assignPayments") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":31,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"PanelApproval-colItem\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"paymentDescription") || (depth0 != null ? lookupProperty(depth0,"paymentDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"paymentDescription","hash":{},"data":data,"loc":{"start":{"line":30,"column":63},"end":{"line":30,"column":85}}}) : helper)))
    + "</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset class=\"section section-container\">\n        <div class=\"section-header \">\n            <legend>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Entry.Methods",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":40,"column":63}}}))
    + "</legend>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-12\">\n                <div class=\"PanelApproval-col\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"entryMethods") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":47,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </fieldset>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"PanelApproval-colItem\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"entryMethodDescription") || (depth0 != null ? lookupProperty(depth0,"entryMethodDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entryMethodDescription","hash":{},"data":data,"loc":{"start":{"line":46,"column":59},"end":{"line":46,"column":85}}}) : helper)))
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <fieldset class=\"section section-container\">\n        <div class=\"section-header \">\n            <legend>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":58}}}))
    + "</legend>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allCurrentFutureAccounts") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":70,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </fieldset>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.panelApproval.All.Current.And.Future.Accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":26},"end":{"line":61,"column":87}}}))
    + "</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"PanelApproval-col\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"account") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":68,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"PanelApproval-colItem\">\n                                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":66,"column":38},"end":{"line":66,"column":53}}}) : helper)))
    + "</span>&nbsp;<span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountNumber") || (depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountNumber","hash":{},"data":data,"loc":{"start":{"line":66,"column":72},"end":{"line":66,"column":89}}}) : helper)))
    + "</span>\n                            </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\"\n                class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":90,"column":27},"end":{"line":90,"column":38}}}) : helper)))
    + "\"\n                data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dataAction") || (depth0 != null ? lookupProperty(depth0,"dataAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dataAction","hash":{},"data":data,"loc":{"start":{"line":91,"column":29},"end":{"line":91,"column":43}}}) : helper)))
    + "\"\n                data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dataHook") || (depth0 != null ? lookupProperty(depth0,"dataHook") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dataHook","hash":{},"data":data,"loc":{"start":{"line":92,"column":27},"end":{"line":92,"column":39}}}) : helper)))
    + "\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":93,"column":12},"end":{"line":93,"column":21}}}) : helper)))
    + "\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<fieldset class=\"section section-container\">\n    <div class=\"section-header \">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Approval.Workflow.Summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":71}}}))
    + "</legend>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-3\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Workflow.Code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":19},"end":{"line":8,"column":62}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"profileCode") || (depth0 != null ? lookupProperty(depth0,"profileCode") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"profileCode","hash":{},"data":data,"loc":{"start":{"line":9,"column":15},"end":{"line":9,"column":30}}}) : helper)))
    + "</p>\n        </div>\n        <div class=\"col-3\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":19},"end":{"line":12,"column":60}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":13,"column":15},"end":{"line":13,"column":30}}}) : helper)))
    + "</p>\n        </div>\n        <div class=\"col-3\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":19},"end":{"line":16,"column":55}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":17,"column":15},"end":{"line":17,"column":25}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n</fieldset>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"assignPayments") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":0},"end":{"line":36,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"entryMethods") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":0},"end":{"line":52,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAccount") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":0},"end":{"line":74,"column":7}}})) != null ? stack1 : "")
    + "<fieldset class=\"section section-container\">\n    <div class=\"section-header \">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Templates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":16},"end":{"line":77,"column":55}}}))
    + "</legend>\n    </div>\n    <div data-region=\"templateListRegion\"></div>\n</fieldset>\n<fieldset class=\"section section-container\">\n    <div class=\"section-header \">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Threshold.Amounts.And.Approvers",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":16},"end":{"line":83,"column":77}}}))
    + "</legend>\n    </div>\n    <div data-region=\"thresholdAmountListRegion\"></div>\n</fieldset>\n<div class=\"section section-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":4},"end":{"line":95,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});