import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    // Identifies if there is only one type in a group
    singleType: true,

    /**
     * Return the lockbox data entitlement attribute
     * @return {string}
     */
    getEntityName() {
        return CONSTANTS.ENTITY_NAME.LOCK;
    },

    /**
     * Return the lockbox data inquiry id for the service call
     * @return {string}
     */
    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.LOCK;
    },

    /**
     * Return the lockbox data inquiry id for current/future for the service call
     * @return {string}
     */
    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.LOCK_CF;
    },

    /**
     * Return the lockbox type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.LOCKBOX;
    },

    /**
     * Return the available lockbox types to populate the grid
     * @return {Array}
     */
    getRecords() {
        return gridDataAccess.lockboxRecords(this.model);
    },
});
