var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "</td>\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"beneficiary") : depth0), depth0))
    + "</td>\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"paymentType") : depth0), depth0))
    + "</td>\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"valueDate") : depth0), depth0))
    + "</td>\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastUpdateTime") : depth0), depth0))
    + "</td>\n                    <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "</td>\n                </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.SMBDuplicateListTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":41}}}))
    + "</p>\n    <table class=\"table duplicate-payment-tbl\">\n        <thead>\n            <tr>\n                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":51}}}))
    + "</th>\n                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.beneficiary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":60}}}))
    + "</th>\n                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.paymentType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":60}}}))
    + "</th>\n                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.valueDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":58}}}))
    + "</th>\n                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.lastUpdateTime",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":63}}}))
    + "</th>\n                <th scope=\"col\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":10,"column":55}}}))
    + "</th>\n            </tr>\n        </thead>\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"duplicatePaymentData") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":23,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n\n<div class=\"col-6\">\n    <div class=\"form-group required\">\n        <label for=\"DUPLICATEREASON\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.DUPLICATEREASON",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":56},"end":{"line":29,"column":88}}}))
    + "</label>\n        <input id=\"DUPLICATEREASON\" type=\"text\" class=\"form-control\" name=\"DUPLICATEREASON\" maxlength=\"255\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"DUPLICATEREASON\"></span>\n    </div>\n</div>";
},"useData":true});