import ItemView from '@glu/core/src/itemView';
import userInfo from 'etc/userInfo';
import modalLayoutTmpl from './modalLayout.hbs';

export default ItemView.extend({
    template: modalLayoutTmpl,
    className: 'modal smb-billing-confirm-payments',

    events: {
        'click button[data-hook="pay"]': 'handlePay',
    },

    getPaymentTotal() {
        // TODO: Should this unformat the PAYMENTAMOUNT??
        return this.collection.reduce((memo, model) => memo + +model.get('PAYMENTAMOUNT'), 0);
    },

    templateHelpers() {
        return {
            currencySymbolString: userInfo.getCurrencySymbol(),
            multiplePayments: this.collection.length > 1,
            deliveryMethod: this.model.getAccountType(),
            id: this.cid,
            total: this.getPaymentTotal(),
            items: this.collection.toJSON(),
            item: this.collection.at(0).toJSON(),
        };
    },

    handlePay() {
        this.close();
    },
});
