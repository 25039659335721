export default
/**
 * This api encapsulates the interaction with webseries inquiry services.
 * Functions here
 * are typically one-to-one with services exposed by webseries.
 *
 * Note: the action block in the request is required for action configured
 * search types.  We will need
 * a way to construct this dynamically.
 *
 * Note: requestId should be a unique value passed by the consumer.  For now
 * we are hijacking this field
 * to specify saved views.
 */
({
    /**
     * Retrieves inquiry data based on custom filter and search parameters.
     * @param options
     */
    getQueryResults(options) {
        const data = {
            IncludeMapData: options.includeMapData,

            queryCriteria: {
                customFilters: options.customFilters,
                inquiryId: options.inquiryId,

                action: {
                    typeCode: options.typeCode,
                    entryMethod: options.entryMethod,
                    productCode: options.productCode,
                    actionMode: options.actionMode,
                    functionCode: options.functionCode,
                },
            },

            requestHeader: {
                requestId: options.viewId,
            },
        };

        return data;
    },

    /**
     * Retrieves inquiry data including both the headers and rows based on requested
     * search type and search parameters.
     */
    getData(options) {
        const data = {
            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 10,

                searchCriteria: {
                    searchType: options.searchType,
                    inquiryId: options.inquiryId,

                    action: {
                        typeCode: options.typeCode,
                        entryMethod: options.entryMethod,
                        productCode: options.productCode,
                        actionMode: options.actionMode,
                        functionCode: options.functionCode,
                    },
                },
            },

            requestHeader: {
                requestId: options.viewId,
            },
        };

        return data;
    },

    /**
     * Retrieves inquiry headers data based on requested search type and search
     * parameters
     */
    getHeaderData(options) {
        const data = {
            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 10,

                searchCriteria: {
                    searchType: options.searchType,
                    inquiryId: options.inquiryId,

                    action: {
                        typeCode: options.typeCode,
                        entryMethod: options.entryMethod,
                        productCode: options.productCode,
                        actionMode: options.actionMode,
                        functionCode: options.functionCode,
                    },
                },
            },

            requestHeader: {
                requestId: options.viewId,
            },
        };

        return data;
    },

    /**
     * Retrieves inquiry rows data based on requested search type and search
     * parameters
     */
    getRowsData(options) {
        let localOptions = options;
        localOptions = localOptions.requestParameters;

        const data = {
            inquiryRequest: {
                startRow: (localOptions.startRow ? localOptions.startRow : 1),
                rowsPerPage: (localOptions.rowsPerPage ? localOptions.rowsPerPage : 10),

                searchCriteria: {
                    customFilters: localOptions.customFilters,
                    searchType: localOptions.searchType,
                    inquiryId: localOptions.inquiryId,

                    action: {
                        typeCode: localOptions.typeCode,
                        entryMethod: localOptions.entryMethod,
                        productCode: localOptions.productCode,
                        actionMode: localOptions.actionMode,
                        functionCode: localOptions.functionCode,
                    },
                },
            },

            requestHeader: {
                requestId: localOptions.viewId,
            },
        };

        if (localOptions.sortOrder && localOptions.sortKey) {
            data.inquiryRequest.searchCriteria.sortFields = [{
                fieldName: localOptions.sortKey,
                sortOrder: localOptions.sortOrder,
            }];
        }

        if (localOptions.filters) {
            const searchFields = [];
            for (let i = 0; i < localOptions.filters.length; i += 1) {
                searchFields[searchFields.length] = {
                    fieldName: localOptions.filters[i].fieldName,
                    operator: 'CONTAINS',
                    fieldValue: [localOptions.filters[i].fieldValue],
                    dataType: 'text',
                };
            }
            data.inquiryRequest.searchCriteria.searchFields = searchFields;
        }

        return data;
    },
});
