import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import { appBus } from '@glu/core';
import EnumCollection from 'common/dynamicPages/collections/enum';
import operatorUtil from 'system/gridOverride/utilities/operators';
import format from 'system/utilities/format';
import dateUtil from 'common/util/dateUtil';
import LvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import services from 'services';

/*
 * list of date codes that are displayed as is.
 * ex: L7D is displayed as = Last 7 days not as a between operation
 */
const displayDateCodes = ['L7D', 'L30D', 'L60D', 'L90D', 'MTD', 'QTD', 'YTD'];

/**
 * formulates the filter badge for an amount filter or an non-datecode date
 * @param {object} filter - the filter to display as a badge
 * @returns {string} - label for an amount or date for the badge
 */
function getAmtDateValueLabel(filter) {
    const [val1] = filter.fieldValue;
    if (filter.dataType === 'amount') {
        return (filter.operator === 'BETWEEN')
            ? `${format.formatNumber(val1)} - ${format.formatNumber(filter.fieldValue[1])}`
            : format.formatNumber(val1);
    }
    return (filter.operator === 'BETWEEN')
        ? `${val1} - ${filter.fieldValue[1]}`
        : val1;
}

/**
 * uses dateUtil to resolve the date code to its corresponding text label
 * @param {string} code
 * @returns {string}
 */
function getDateCode(code) {
    return dateUtil.getDateCodes()[code];
}

/**
 * formulates the filter badge for a datecode date
 * @param {object} filter
 * @returns {string} filter badge label
 */
function getDateCodeValueLabel(filter) {
    if (filter.operator === 'BETWEEN') {
        if (displayDateCodes.indexOf(filter.fieldValue[0]) >= 0) {
            return `= ${getDateCode(filter.fieldValue[0])}`;
        }
        return `${getDateCode(filter.fieldValue[0])} - ${getDateCode(filter.fieldValue[1])}`;
    }
    return getDateCode(filter.fieldValue);
}

/**
 * outputs the label for the filter badge
 * @param {object} filter
 * @param {object} column - grid column object for the filter field
 * @returns {string} filter badge label
 */
function getLabel(filter, column) {
    let values;
    if (column.type === 'amount' || column.type === 'date') {
        if (filter.dateCode) {
            values = getDateCodeValueLabel(filter);
        } else {
            values = getAmtDateValueLabel(filter);
        }
        // the special date code label (see above displayDateCodes array) begin with '='
        return (values.charAt(0) === '=')
            ? values
            : `${operatorUtil.convert4Label(filter.operator)} ${values}`;
    }
    if ((column.type === 'enum' || column.type === 'typeahead') && Array.isArray(filter.enumHash)) {
        const enumItem = filter.enumHash.find(e => e.value === filter.fieldValue[0]);
        if (enumItem) {
            return enumItem.label;
        }
        return filter.fieldValue;
    }
    return filter.fieldValue;
}

/**
 * formulates the value for the filter field model
 * @param {object} filter - filter field
 * @param {object} column - grid column associated with filter field
 * @returns array or string of filter value(s)
 */
function getValue(filter, column) {
    if (column.type === 'amount') {
        if (filter.operator === 'BETWEEN') {
            return [
                format.stringToNumber(filter.fieldValue[0]),
                format.stringToNumber(filter.fieldValue[1]),
            ];
        }
        return format.stringToNumber(filter.fieldValue[0]);
    }
    if (column.type === 'date') {
        if (filter.operator === 'BETWEEN') {
            return [...filter.fieldValue];
        }
        return filter.fieldValue[0];
    }
    return filter.fieldValue[0];
}

export default {
    /**
     *
     * @param {Object} filter
     * @param {Array.<Object>} columns
     * @param {Object} context
     * @returns {model} filter model for the incoming filter field object
     */
    createFilterModel(filter, columns, context) {
        if (util.isEmpty(filter) || util.isEmpty(columns)) {
            return new Model();
        }
        const fieldName = filter.filterFieldName || filter.fieldName;
        const maskedFieldName = `${fieldName}_DISP`;
        const possibleMatches = [
            fieldName.toUpperCase(),
            maskedFieldName.toUpperCase(),
            filter.overrideSearchField?.toUpperCase(),
        ];
        const filterColumn = columns
            .find(c => possibleMatches.includes(c.field.toUpperCase()));
        if (filterColumn) {
            const filterModel = new Model({
                label: getLabel(filter, filterColumn),
                title: filterColumn.title,
                value: getValue(filter, filterColumn),
                type: filterColumn.type,
                field: fieldName,
                equality: filter.operator || 'EQ',
                overrideSearchField: filter.overrideSearchField,
                staticFilter: filter.staticFilter,
            });
            if (filterColumn.type === 'date') {
                filterModel.set('dateCode', filter.dateCode, { silent: true });
            } else if ((filterColumn.type === 'enum' || filterColumn.type === 'typeahead')
                && Array.isArray(filter.enumHash)) {
                const column = columns.find(col => ((col.fieldName
                    && (col.fieldName === filter.fieldName
                    || col.searchField === filter.fieldName))));
                const enumHashModels = filter.enumHash.map(f => new Model(f));
                const enumCollection = new EnumCollection(
                    enumHashModels,
                    {
                        serviceUrl: services.generateUrl('inquiry/getRowsData'),
                        inquiryId: column.popupId,
                        context,
                        fieldName: column.fieldName,
                        customFilters: column.filterStaticDependsOn,
                    },
                );
                filterModel.set('enumHash', enumCollection, { silent: true });
            }
            filterModel.on('change', () => {
                appBus.trigger('cachedSavedFilters:change');
            });
            return filterModel;
        }
        return new Model();
    },


    displayFilterBadges(listView) {
        const savedFilters = listView.lvc.get(LvcConstants.FILTERFIELDS) || [];
        if (savedFilters.length === 0) {
            return; // do nothing if there are no saved filters
        }

        const columns = listView.gridView.grid.columns.models.map(c => c.toJSON());
        /*
         * for each filter, create a model and add it to
         * gridView.grid.filterProc.filters w/ reset
         */
        const savedFilterModels = savedFilters
            .map(filter => this.createFilterModel(filter, columns));

        if (!util.isEmpty(savedFilterModels)) {
            if (listView.gridView.grid.filterProc) {
                listView.gridView.grid.filterProc.filters.reset(savedFilterModels, {
                    silent: true,
                });
            }
            if (listView.gridView.grid.filterBadgeHeaderView) {
                listView.gridView.grid.filterBadgeHeaderView.render();
            }
        }
    },

    /**
     * Format the filters array as LVC expects
     * @param {Array} filters - array of filter fields with filter.field
     * and filter.value properties in each element
     * @returns {Array} - array of filters
     */
    formatFilters(filters) {
        return filters.map(filter => ({
            fieldName: filter.field,
            fieldValue: filter.value,
            operator: 'EQ',
            dataType: 'text',
        }));
    },
};
