import Layout from '@glu/core/src/layout';
import API from 'app/smb/api';
import Collection from '@glu/core/src/collection';
import Formatter from 'system/utilities/format';
import util from '@glu/core/src/util';
import nhUtil from 'system/utilities/accessors';
import sbConstants from 'app/smb/constants';
import errHandler from 'system/errHandler';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import NewBillModal from 'app/smbPayments/views/payments/newBill/newBill';
import ViewBillModal from 'app/smbPayments/views/payments/viewBill/viewBill';
import workspaceHelper from 'common/workspaces/api/helper';
import DroppableView from './droppableView';
import ListControlsView from './listControlsView';
import TileCollectionView from './tileGridCollectionView';
import tileGridViewTmpl from './tileGridView.hbs';

const billTileGridManagement = Layout.extend({
    template: tileGridViewTmpl,

    ui: {
        $totalBillsDue: '.total-bills-due',
        listWrapper: '.list-wrapper',
        tileWrapper: '.tile-wrapper',
    },

    regions: {
        tileGridRegion: '[data-region="tileGridRegion"]',
        droppableRegion: '[data-region="droppableRegion"]',
        billsGridRegion: '[data-region="billsGridRegion"]',
    },

    events: {
        'click .add-bill': 'addBill',
        'click .btn-list-view': 'viewList',
        'click .btn-tile-view': 'viewTiles',
    },

    appEvents: {
        'update:billsAmount': 'calculateTotalBillsDue',
        'payments:draggableTileItem:viewBill': 'viewBill',
        'bill:newBill:billAdded': 'refresh',
    },

    initialize() {
        this.loadRequiredData();
    },

    addBill() {
        const newBillModal = new NewBillModal({});
        dialog.open(newBillModal);
    },

    refresh() {
        this.initialize();
    },

    viewBill(e) {
        const cid = e.delegateTarget.id;

        const bill = util.findWhere(
            this.paymentCollection.models,
            {
                cid,
            },
        );

        const viewBillModal = new ViewBillModal({
            model: bill,
        });
        dialog.open(viewBillModal);
    },

    viewList() {
        this.listControlsView = new ListControlsView({
            collection: this.gridCollection,
        });
        if (this.listControlsView) {
            this.billsGridRegion.show(this.listControlsView);
            this.ui.tileWrapper.addClass('hidden');
            this.ui.listWrapper.removeClass('hidden');
        }
    },

    viewTiles() {
        this.tileCollectionView = new TileCollectionView({
            collection: this.paymentCollection,
        });
        this.droppableView = new DroppableView({
            collection: this.paymentCollection,
        });
        if (this.tileCollectionView) {
            this.tileGridRegion.show(this.tileCollectionView);
            this.ui.listWrapper.addClass('hidden');
            this.ui.tileWrapper.removeClass('hidden');
        }
        if (this.droppableView) {
            this.droppableRegion.show(this.droppableView);
        }
    },

    filterCollection(collection, fieldName, criteria) {
        const newCollection = new Collection();
        util.each(collection.models, (model) => {
            const filteredValue = util.findWhere(
                model.get('columns'),
                {
                    fieldName,
                },
            ).fieldValue;
            if (filteredValue === criteria) {
                newCollection.add(model);
            }
        });

        return newCollection;
    },

    loadRequiredData() {
        const self = this;

        const loadFinished = function () {
            self.setHasLoadedRequiredData(true);
            self.render();
        };

        /*
         * Parameters include the filter of retrieving all bills that have a valid
         * status: Approved or Pending Approval
         */

        const data = {
            startRow: 1,
            rowsPerPage: 25,

            searchFields: [{
                fieldName: 'STATUS_DESCRIPTION',
                fieldValue: ['Approved', 'Pending'],
                dataType: 'text',
                operator: 'IN',
            }, {
                fieldName: 'TOTALAMOUNT',
                fieldValue: ['0'],
                dataType: 'text',
                operator: '>',
            }],
        };

        const payments = API.getSmbBillListView.get(data);
        const recipientList = API.beneficiaryList.get(sbConstants.BUSINESS_OPTION_2);
        const dates = API.dates.get('business');

        const billEntitlements = API.getSmbBillEntitledActions.get({
            subtype: '*',
            action: 'INSERT',
            entryMethod: 0,

            data: [{
                name: 'TYPECODE',
                value: 'BILL',
            }],
        });

        Promise.all([payments, recipientList, dates, billEntitlements])
            .then((results) => {
                const [resultData, recipients, resultDates, entitlements] = results;
                self.paymentCollection = new Collection(resultData.rows);
                self.recipientList = recipients;
                self.dates = resultDates;
                self.entitledActions = entitlements.actions;
                store.set('billEntitlements', self.entitledActions);
                // self.fromAccountList = results[3];  to be added later: tie this to drop zone
                self.blockedDates = self.dates.blockedDates;
                self.cutoffTimes = self.dates.cutoffTimes;
                self.maxDate = self.dates.maxDate;
                self.earliestDay = self.dates.earliestDay;
                self.gridCollection = self.filterCollection(self.paymentCollection, 'STATUS', 'AP');

                loadFinished();
            })
            .then(null, errHandler);
    },

    removeItemFromDraggable(itemParam) {
        const item = itemParam;
        /*
         * add the reference to collection back onto the model so user can drag again
         * if needed.
         */
        item.collection = this.paymentCollection;

        this.collection.remove(item);
        // re-render the view so items don't vanish under  the drop zone.
        this.tileCollectionView.render();
    },

    removeItemFromDroppable(itemParam) {
        const item = itemParam;
        /*
         * add the reference to collection back onto the model so user can drag again
         * if needed.
         */
        item.collection = this.paymentCollection;

        /*
         * move an item from the droppable view back to the collection it came from
         * adding the item back, renders the view with the item automatically
         */

        this.paymentCollection.add(item);
        // re-render the view so items don't vanish under  the drop zone.
        this.tileCollectionView.render();
    },

    getFormattedAmount(value) {
        return Formatter.formatCurrency(value);
    },

    calculateTotalBillsDue() {
        let totalBillsDue = 0;

        util.each(this.paymentCollection.models, (model) => {
            const amount = nhUtil.getFieldValue(model, 'TOTALAMOUNT');
            totalBillsDue += parseFloat(amount);
        });

        const formattedAmount = this.getFormattedAmount(totalBillsDue);

        this.ui.$totalBillsDue.text(formattedAmount);
    },

    templateHelpers() {
        const self = this;

        return {
            hasInsertEntitlement() {
                return self.hasEntitlement('INSERT');
            },
        };
    },

    hasEntitlement(action) {
        const actions = store.get('billEntitlements');
        return !!(actions && actions[action] && actions[action] === true);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            // this.listenTo(self.paymentCollection, 'change add remove reset', this.render);

            // init Tile Collection
            this.tileCollectionView = new TileCollectionView({
                collection: this.paymentCollection,
            });

            this.tileCollectionView.bind('itemInDropzone', util.bind(this.removeItemFromDraggable, this));

            // init Drop Zone
            this.droppableView = new DroppableView({
                collection: this.paymentCollection,
            });

            /*
             * listen to the drop zone view to see when a model is removed from the drop zone
             * so we can add it back to this collection and re-render the ui with this model.
             */

            this.droppableView.bind('itemOut', util.bind(this.removeItemFromDroppable, this));

            // calculate totals
            this.calculateTotalBillsDue();

            // show views
            this.delegateEvents();

            if (this.tileCollectionView) {
                this.tileGridRegion.show(this.tileCollectionView);
            }
            if (this.droppableView) {
                this.droppableRegion.show(this.droppableView);
            }
            this.listControlsView = new ListControlsView({
                collection: this.gridCollection,
            });
            if (this.listControlsView) {
                this.billsGridRegion.show(this.listControlsView);
            }
        }
    },

    onClose() {
        const self = this;

        /*
         * On close moved any items remaining in the droppable area back to status
         * unscheduled
         */
        util.each(this.droppableView.collection.models, (itemParam) => {
            const item = itemParam;
            item.set('status', 'unscheduled');
            item.collection = self.collection;
            // self.collection.add(item);
        });
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'SMBBILLLIST',
    view: billTileGridManagement,
    options: {},
});

export default billTileGridManagement;
