import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import AccountsCollectionView from './assignedAccounts';
import paymentTypeTmpl from './paymentType.hbs';

export default Layout.extend({
    tagName: 'div',
    className: 'panel panel-primary',
    template: paymentTypeTmpl,

    ui: {
        $heading: '.panel-heading',
        $expandPanel: '.collapse',
    },

    events: {
        'click @ui.$heading': 'togglePanel',
    },

    initialize(options) {
        const notViewMode = options.mode !== constants.MODES.VIEW;

        this.mainModel = this.options.mainModel;
        this.mode = options.mode;
        this.modifyMode = this.options.modifyMode;
        this.paymentTypeGroup = options.paymentTypeGroup;
        this.limitsModel = this.options.limitsModel;
        this.actionEntitlements = this.model.getEntitlementsByFunctionCode(['INST', 'BATCH', 'TMPL', 'BHTMPL'], notViewMode);
        this.isCannotGrantBeyondOwnUser = this.options.isCannotGrantBeyondOwnUser;

        /*
         * Get the first item where the type matches the model used for the type,
         * except for
         * transfers.
         * Transfers are an exception because the from and to accounts were split.  Only the
         * from accounts
         * are used for limits.
         */
        this.dataEntitlements = util.chain(this.mainModel.assignAccountsDataEntitlements)
            .filter((dataEntitlement) => {
                const typeCode = this.model.id.toUpperCase();
                const isTransferFrom = typeCode === constants.PAYMENT_GROUPS.TRANSFERS
                    && dataEntitlement.typeCode === constants.TRANSFER_DATA_ENTITLEMENTS.FROM;
                return (dataEntitlement.typeCode === typeCode && !isTransferFrom)
                    || isTransferFrom;
            })
            .reduce((acc, dataEntitlementParam) => {
                const dataEntitlement = dataEntitlementParam;
                /*
                 * Need to change the typecode from TRANSFERBANKACCOUNT
                 * to TRANSFER to process limits correctly
                 */
                if (dataEntitlement.typeCode === constants.TRANSFER_DATA_ENTITLEMENTS.FROM) {
                    dataEntitlement.typeCode = constants.PAYMENT_GROUPS.TRANSFERS;
                }

                // should only be one
                return dataEntitlement;
            }, {})
            .value();
    },

    onRender() {
        this.assignedAccountsView = new AccountsCollectionView({
            mode: this.mode,
            isCannotGrantBeyondOwnUser: this.isCannotGrantBeyondOwnUser,
            model: this.model,
            mainModel: this.mainModel,
            modifyMode: this.modifyMode,
            paymentTypeGroup: this.paymentTypeGroup,
            limitsModel: this.limitsModel,

            entitlements: {
                actionEntitlements: this.actionEntitlements,
                dataEntitlements: this.dataEntitlements,
            },
        });
    },

    togglePanel() {
        if (!this.panelLoaded) {
            this.assignedAccountsRegion.show(this.assignedAccountsView);
        }

        this.panelLoaded = true;
    },

    beforeSave() {
        return this.assignedAccountsView.beforeSave();
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            readOnly: this.mode === constants.MODES.VIEW,
            id: locale.get(this.model.get('label')),
            hasPaymentEntitlements: this.actionEntitlements.length > 0,
        };
    },
});
