import configuration from 'system/configuration';
import userInfo from 'etc/userInfo';
import store from 'system/utilities/cache';

export default {
    /**
     * @method getHelpPage
     * @description - returns the help page in cache associated with the current view
     */
    getHelpPage() {
        return store.get('helpPage');
    },

    /**
     * Build the help page url and launch in a popup
     */
    clientHelp() {
        let helpUrl = `${window.Bottomline.helpRoot}/${userInfo.get('locale')}${configuration.isClient() ? '/client/' : '/admin/'}`;
        let helpPage = this.getHelpPage();

        if (!helpPage) {
            helpPage = (configuration.isClient()) ? 'DB_Client_Help.htm' : 'DB_Administrator_Help_CSH.htm';
        }
        helpUrl += helpPage;
        const helpWindow = window.open(helpUrl, 'DBHelp', 'width=800,height=600,left=100,top=100,scrollbars=1');

        helpWindow.focus();
    },
};
