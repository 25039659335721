/* istanbul ignore file */
/**
 * Should have all locale keys used in this project with translations matching the language-region
 * code of this file.
 */
export default {
  actionControlTitle: 'Grid Actions',
  apply: 'Apply',
  ascending: 'Ascending',
  cancel: 'Cancel',
  columnsTitle: 'Columns',
  descending: 'Descending',
  loadMore: 'Load More',
  mobileGrid: 'Mobile Grid',
  noContentFound: 'No Content Found',
  sort: 'Sort',
  scrollToTop: 'Scroll to Top',
  filters: 'Filters',
  viewingRangeTotal: 'Viewing {0} - {1} of {2}',
  loading: 'Loading',
  more: 'More'
};
