var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"print-payment\">\n    <form autocomplete=\"off\" data-submit=\"save\">\n\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n\n        <div class=\"form-container\">\n            <div>\n                <div class=\"field-container-lg\">\n                    <div class=\"form-group\">\n                        <label class=\"radio-inline\">\n                            <input type=\"radio\" id=\"expdata\" value=\"both\" name=\"expdata\" data-bind=\"model\">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.DetailInformationOnly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":66}}}))
    + "</label>\n                        <label class=\"radio-inline\">\n                            <input type=\"radio\" id=\"expdata\" value=\"summary\" name=\"expdata\" data-bind=\"model\">\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.SummaryInformationOnly",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":67}}}))
    + "</label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});