import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';

export default Model.extend({
    defaults() {
        return {};
    },

    initialize(attributes, options) {
        this.options = options;
        this.routeBase = options.routeBase ? options.routeBase : this.defaultRouteBase;
    },

    sync(method, model, options) {
        let operation;
        let payload;
        if (method === 'read') {
            operation = constants.URL_GETCHILDREN_ACTION;
            payload = this.convertModelAttributesForGet(options);
        } else {
            operation = constants.URL_ADDCHILDREN_ACTION;
            payload = this.convertModelAttributesForSave();
        }
        const route = services.generateUrl(this.routeBase + operation);
        http.post(route, payload, (result) => {
            options.success(result);
        }, (result) => {
            options.error({
                errorCode: result.status,
                errorMessage: result.statusText,
                message: result.responseText,
            });
        });
    },

    convertModelAttributesForGet(options) {
        const { attributes } = this.options;
        const actionMode = (options && options.actionMode) ? options.actionMode : 'MODIFY';

        return {
            rowsPerPage: 10,
            startRow: 1,
            subType: attributes.SUBTYPE,
            entryMethod: 0,
            entryClass: attributes.ENTRYCLASS,
            action: actionMode,

            keyFields: [{
                name: 'TNUM',
                value: attributes.TNUM,
            }],
        };
    },

    setAttributesForSave() {},

    convertModelAttributesForSave() {
        const data = [];
        this.setAttributesForSave();
        util.each(this.attributes, (value, key) => {
            data.push({
                name: key,
                value,
            });
        });

        return {
            grids: [{
                name: this.gridName,

                items: [{
                    item: data,
                }],
            }],
        };
    },

    gridName: 'ACH_BDSummaryGrid_24652',
    defaultRouteBase: '/batch/CorporateVendorPayments',
});
