import locale from '@glu/locale';

export default (noTransactions) => {
    const transOptions = [{
        id: 'GIRTRANS',
        text: locale.get('GIR.ExportType.TransOnly'),
    }, {
        id: 'GIRBALANDTRANS',
        text: locale.get('GIR.ExportType.BalAndTran'),
    }];

    const balOptions = [{
        id: 'GIRBAL',
        text: locale.get('GIR.ExportType.BalOnly'),
    }];

    return (noTransactions) ? balOptions : transOptions.concat(balOptions);
};
