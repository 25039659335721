import 'select2';
import dialog from '@glu/dialog';
import ItemView from '@glu/core/src/layout';
import singleLookupHelper from 'common/dynamicPages/views/mdf/componentHelpers/singleLookup';
import gridApi from 'common/dynamicPages/api/grid';
import transform from 'common/util/transform';
import Model from 'app/payments/models/rapidWireEntry';
import constants from 'common/dynamicPages/api/constants';
import Formatter from 'system/utilities/format';
import amountFormatHelper from 'common/dynamicPages/views/mdf/componentHelpers/amountFormat';
import typeaheadhelper from 'common/dynamicPages/views/mdf/componentHelpers/typeahead';
import beneBankIdTemplate from 'common/dynamicPages/views/mdf/componentTemplates/helperTextTemplates/beneBankIdTemplate.hbs';
import PaymentUtil from 'common/util/paymentUtil';
import { eligibleForOnusModalPrompt } from 'common/policies/payments/rtgs';
import services from 'services';
import http from '@glu/core/src/http';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import locale from '@glu/locale';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import OnUsWireDatePromptView from 'common/modals/onUsWireDatePromptView';
import template from './rapidWireItemView.hbs';

export default ItemView.extend({
    template,

    ui: {
        $dateField: '[name="VALUE_DATE"]',
        $amount: '[name="CREDIT_AMOUNT"]',
        $helperText: '.lookup-helper-text',
        $remove: '[data-hook="getRemoveButton"]',
        $originatorAccount: '[data-hook="getOriginatorAccount"]',
    },

    events: {
        'blur @ui.$amount': 'handlePaymentTotal',
        'click @ui.$remove': 'handleRemovePayment',
        'change @ui.$originatorAccount': 'handleAccountSelect',
    },

    className: 'rapidWireEntryForm collection-entry',

    initialize() {
        // required for the bankCode listVview
        this.initializeOptions = {
            gridApi,
        };
        this.context = {};
        this.dispAcctBalance = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') === 'true';

        this.dateModel = new Model();
        if (this.isFirstDetailRequired()) {
            this.model.addValidator('OBI_TEXT_1', {
                exists: true,
                description: locale.get('RTGS.PaymentDetailLine1.Mandatory'),
            });
        }
    },

    onRender() {
        amountFormatHelper.setupInputMask(this, 'CREDIT_AMOUNT', false);

        this.ui.$originatorAccount.comboBox({
            containerCssClass: 'form-select',
        });

        singleLookupHelper.setupSingleLookups(this);
        typeaheadhelper.setupTypeaheadBoxes(this);
        this.setListeners();
        this.resetModel();
        this.fetchDatesOnRender();
        this.showMaskedAccount();
    },

    /**
     * @name showMaskedAccount
     * @description display the maskedInput component for the account number
     */
    showMaskedAccount() {
        const fieldLabel = locale.get('ACH.AccountNumber');
        const MaskedInputView = createMaskedInputView({
            initialValue: this.model.get('BENE_ACCOUNT') || '',
            name: 'BENE_ACCOUNT',
            maxLength: constants.ACCOUNT_MASKING.PROPERTIES.ACCOUNT_NUMBER_MAXLEN,
            fieldLabel,
            inputClassList: 'form-control',
            dataBind: true,
        });
        if (this.maskedInputRegion) {
            this.maskedInputRegion.show(new MaskedInputView());
        }
    },

    templateHelpers() {
        return {
            originatorAccountOptions: this.options.originatorModel.toDropdownFormat(),
            cid: this.model.cid,
            isFirstDetailRequired: this.isFirstDetailRequired(),
        };
    },

    /**
     * Determine if the first detail line is required
     * @returns {boolean}
     */
    isFirstDetailRequired() {
        return serverConfigParams.get('WireDomesticMultiEntryDetailOneRequired') === 'true';
    },

    /**
     * @method setListeners
     */
    setListeners() {
        this.listenTo(this.model, 'change:BENE_BANK_NAME', () => {
            this.renderBeneBankAddress();
        });
        this.listenTo(this.model, 'change:BENE_BANK_ID', () => {
            this.fetchDates();
        });
        this.listenTo(this.model, 'change:VALUE_DATE', () => {
            this.dateModel.updateServicePayload({
                TRAN_DATE: this.model.get('TRAN_DATE'),
                VALUE_DATE: this.model.get('VALUE_DATE'),
                POST_DATE: this.model.get('POST_DATE'),
                DEBIT_BANK_CODE: this.model.get('DEBIT_BANK_CODE'),
                DEBIT_ACCOUNT_NUMBER: this.model.get('DEBIT_ACCOUNT_NUMBER'),
                DEBIT_COUNTRY: this.model.get('DEBIT_COUNTRY'),
                DEBIT_CURRENCY: this.model.get('DEBIT_CURRENCY'),
                BENE_BANK_CODE: this.model.get('BENE_BANK_CODE'),
                CREDIT_AMOUNT: this.model.get('CREDIT_AMOUNT'),
                DEBIT_AMOUNT: this.model.get('DEBIT_AMOUNT'),
                CREDIT_CURRENCY: this.model.get('CREDIT_CURRENCY'),
                EXCHANGE_RATE: this.model.get('EXCHANGE_RATE'),
                EXCHANGE_RATE_CONTRACTID: this.model.get('EXCHANGE_RATE_CONTRACTID'),
                BENE_BANK_ID: this.model.get('BENE_BANK_ID'),
                RELEASELEADTIME: this.model.get('RELEASELEADTIME'),
                ENTRYMETHOD: this.model.get('ENTRYMETHOD'),
                CREATEDFROM: this.model.get('CREATEDFROM'),
            });
            this.dateModel.processServiceAction({
                success: (results) => {
                    const item = transform.pairsToHash(results.item);
                    this.model.set({
                        TRAN_DATE: Formatter.formatDate(item.TRAN_DATE),
                        VALUE_DATE: Formatter.formatDate(item.VALUE_DATE),
                        CUTOFF_INFO: item.CUTOFF_INFO,
                    }, {
                        silent: true,
                    });
                    PaymentUtil.showCutoff(item.CUTOFF_INFO, this.$(`#rapidEntryWireValueDate-${this.model.cid}`).next('.ui-datepicker-trigger'), 'FEDWIRE');
                    this.$(`#rapidEntryWireValueDate-${this.model.cid}`).val(this.model.get('VALUE_DATE'));
                },
            });
        });
        this.listenTo(this, 'hideRemoveBtn', this.hideRemoveBtn);
        this.listenTo(this, 'showRemoveBtn', this.showRemoveBtn);
    },

    /**
     * @method hideRemoveBtn
     */
    hideRemoveBtn() {
        this.ui.$remove.hide();
    },

    /**
     * @method hideRemoveBtn
     */
    showRemoveBtn() {
        this.ui.$remove.show();
    },

    /**
     * Reset the model to the defaults
     */
    resetModel() {
        this.model.set(this.model.defaults);
    },

    /**
     * @method fetchDatesOnRender
     * This method will perform a one-time service call to the date list service when the screen
     * or rapid entry item renders. This is to allow user selection from the value date field
     * before the account data is populated. This is normaly done automatically by the meta driven
     * form when rendering a date field.
     */
    fetchDatesOnRender() {
        this.dateModel.getDates({
            model: this.model,
            success: (results) => {
                this.dateModel.set(results);
                this.renderDatePicker();
            },
        });
    },

    /**
     * @method fetchDates
     * This method will perform the service call to the date list service when either the debit or
     * beneficiary bank details are updated as long as data exists for both banks at the time the
     * service call is made.
     */
    fetchDates() {
        if (this.model.get('DEBIT_BANK_CODE') && this.model.get('BENE_BANK_ID')) {
            this.dateModel.getDates({
                model: this.model,
                success: (results) => {
                    const isUserDate = (results.userSetValueDate && !results.userSetDateInvalid);

                    PaymentUtil.invalidDateUpdatedWarning(
                        this.ui.$dateField,
                        results.userSetDateInvalid,
                        false,
                    );

                    this.dateModel.set(results);

                    this.model.set({
                        VALUE_DATE: Formatter.formatDate(this.dateModel.get(isUserDate ? 'userSetValueDate' : 'earliestDay')),
                        TRAN_DATE: Formatter.formatDate(this.dateModel.get('tranDate')),
                    });

                    this.renderDatePicker();

                    /*
                     * if the selected BENE_BANK_ID is valid for onUs, show a dialog with the
                     * potential new date
                     */
                    if (eligibleForOnusModalPrompt(results, this.model)) {
                        dialog.open(new OnUsWireDatePromptView({
                            businessDaysResponse: results,
                            model: this.model,
                            $dateInput: this.ui.$dateField,
                        }));
                    }
                },
            });
        }
    },

    /**
     * @method renderBeneBankAddress
     * @description with each selection of a Bank Code, display the address information
     */
    renderBeneBankAddress() {
        this.ui.$helperText.html(beneBankIdTemplate(this.model.toJSON()));
    },

    /**
     * @method renderDatePicker
     */
    renderDatePicker() {
        const icon = this.$(`#rapidEntryWireValueDate-${this.model.cid}`).next('.ui-datepicker-trigger');
        this.ui.$dateField.nhDatePicker({
            blockedDates: this.dateModel.get('holidays'),
            daysBack: this.dateModel.get('maxBackwardDays'),
            daysForward: this.dateModel.get('maxForwardDays'),
            showCalendarIcon: !icon.length,
            cutOffTimes: [this.dateModel.get('cutoff')],
            processingDays: [this.dateModel.get('businessDays')],
            earliestDay: this.dateModel.get('earliestDay'),
        });
        PaymentUtil.showCutoff(this.dateModel.get('cutoffDateTimeTz'), icon, 'FEDWIRE');
    },

    /**
     * @method handlePaymentTotal
     * @description A helper method to trigger an update of the summary total on
     * the parent view
     */
    handlePaymentTotal() {
        this.trigger('updatePaymentTotal');
    },

    handleAccountSelect(dropdownSelect) {
        if (!dropdownSelect.val) {
            return;
        }
        const [selectedAccount] = this.options.originatorModel.get('queryResponse').QueryData.queryRows.filter(entry => entry.name === dropdownSelect.val);
        const nameValuePair = transform.pairsToHash(selectedAccount.mapDataList, 'toField', 'value');

        nameValuePair.DEBIT_ACCOUNT_NUMBER = nameValuePair.ORIGINATOR_ID;
        this.model.set(nameValuePair);

        if (this.dispAcctBalance) {
            this.fetchBalancesForAccount(nameValuePair, 'DEBIT_ACCOUNT_NUMBER')
                .then((balances) => {
                    // setup helper text
                    this.setupBalanceText(balances);
                });
        }
        this.fetchDates();
    },

    /**
     * @description Show/hide account balance helper text
     * @param balances - account balances for the accounts
     */
    setupBalanceText(balances) {
        if (balances && balances[0]) {
            if (balances[0].status === 'success') {
                const text = `${balances[0].balance} ${locale.get('common.available')}`;
                this.ui.$originatorAccount.parent().find('.balance-text').text(text);
            } else {
                this.ui.$originatorAccount.parent().find('.balance-text').empty();
            }
        }
    },

    /**
     * @description retrieves the account balances for all the accounts
     * @param account -- selected bank account to fetch balance for
     * @param fieldName - name of the field
     */
    fetchBalancesForAccount(account, fieldName) {
        // post to the service and return array of balances
        const serviceUrl = services.currencyBalances;

        /**
         * Each item in the request must contain:
         * productCode, functionCode, typeCode, bankCode, accountNumber, currency
         *
         */
        const balanceRequest = {
            productCode: 'RTGS',
            functionCode: 'INST',
            typeCode: 'FEDWIRE',
            bankCode: account.DEBIT_BANK_CODE,
            accountNumber: account.ORIGINATOR_ID,
            currency: account.CREDIT_CURRENCY,
        };
        const postData = {
            requests: [balanceRequest],
        };

        return new Promise((resolve, reject) => {
            http.post(serviceUrl, postData, (data) => {
                if (data && data.responses) {
                    resolve(data.responses);
                } else {
                    resolve(account);
                }
            }, () => {
                reject(locale.get('common.combo.data.error', fieldName));
            });
        });
    },

    handleRemovePayment() {
        this.trigger('remove:entry');
    },
});
