import NestedModel from '@glu/core/src/nestedModel';
import util from '@glu/core/src/util';

export default NestedModel.extend({

    defaults() {
        return {
            productCode: '',
            functionCode: '',
            entryMethod: '',
            actions: {},
            restrictions: {},
        };
    },

    initialize(obj) {
        this.set(this.parse(obj));
    },

    isActionApplicable(action) {
        return !!(this.get(`actions.${action}`) || {}).applicable;
    },

    isActionEntitled(action) {
        return !!(this.get(`actions.${action}`) || {}).entitled;
    },

    isRestrictionApplicable(action) {
        return !!(this.get(`restrictions.${action}`) || {}).applicable;
    },

    isRestrictionEntitled(action) {
        return !!(this.get(`restrictions.${action}`) || {}).entitled;
    },

    getSigNum() {
        return this.get('restrictions.signature.value');
    },

    setSigNum(num) {
        this.set('restrictions.signature.value', num);
    },

    maxSigNum() {
        return (this.get('restrictions.signature') || {}).maxValue || 1;
    },

    isEntitled() {
        return util.some(this.get('actions'), action => action.entitled);
    },
});
