import RemitterModel from 'app/reports/models/remitter';
import validatorPatterns from 'system/validatorPatterns';
import locale from '@glu/locale';

export default RemitterModel.extend({
    initialize() {
        this.validators = {
            ACCOUNTNUMBER: {
                description: locale.get('reports.account.number'),
                exists: true,
                matches: validatorPatterns.ALPHANUMERIC_PATTERN,
            },

            ABA: {
                description: locale.get('reports.routing.code'),
                exists: true,
                matches: validatorPatterns.NUMERIC_PATTERN,
            },
        };
    },

    service: 'lockboxRemitterAccount',
});
