import ListView from 'common/dynamicPages/views/workflow/list';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import alert from '@glu/alerts';
import contextApi from 'common/dynamicPages/api/context';
import TokenModel from 'app/administration/models/token';
import commonConstants from 'common/dynamicPages/api/constants';
import UserGroupLookup from 'app/administration/views/userMaintenance/userGroupLookup';
import mobileUtil from 'mobile/util/mobileUtil';
import template from './adminTokenListView.hbs';

export default ListView.extend({
    template,

    initialize() {
        const superOptions = {
            menuCategory: 'tknmgmt',
            serviceName: '/tokenManagement',
            serviceFunc: null,
            businessType: null,
            context: contextApi.menuContext.getContext('TKNMGMT'),
            returnRoute: '/',
            hideGridActionButtons: true,
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions));

        this.userGroupLookup = new UserGroupLookup({
            isAdmin: true,
        });
    },

    ui: {
        $submit: '[data-hook="getSubmitBtn"]',
        $grid: '[data-hook="getGrid"]',
        $widgetLinks: '[data-hook="getWidgetLinks"]',
        $userGroupCombo: '.usergroup-select',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click @ui.$submit': 'fetchTokensFromCore',
        },
    ),

    /**
     * override list.js onRender
     */
    onRender() {
        const lookup = this.userGroupLookup;
        const self = this;
        if (this.hasLoadedRequiredData()) {
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
        } else {
            this.loadViewRequirements();
        }

        this.userGroupCombo = this.ui.$userGroupCombo.comboBox({
            query: util.debounce((query) => {
                lookup.setSearch(query.term);
                lookup.request.rowsPerPage = query.term.length < 3 ? 10 : 50;
                lookup.send().then((result) => {
                    query.callback({
                        results: result.data,
                    });
                });
            }, commonConstants.COMBO_DEBOUNCE),
        });

        this.userGroupCombo.on('change', (e) => {
            self.model.set('COMPANYID', e.val);
        });

        // call into child component(s) e.g. expiringtoken.js
        if (typeof this.initDropdown === 'function') {
            this.initDropdown();
        }
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        if (!mobileUtil.isMobileGridEnabled()) {
            this.setHasLoadedRequiredData(true);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            this.listenForGridErrors();
            this.render();
        }
        this.model = new TokenModel();
        this.model.addCompanyValidator();
        return result.actions;
    },

    /**
     * @method fetchTokensFromCore
     * Fetch the tokens from RSA and save it in DGB
     */
    fetchTokensFromCore() {
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }

        this.ui.$submit.attr('aria-busy', true);
        this.model.syncData(
            'tokens',
            {
                success: this.fetchSuccess.bind(this),
                error: this.fetchError.bind(this),
            },
        );
    },

    fetchSuccess() {
        if (!this.gridView.hasLoaded) {
            this.gridRegion.show(this.gridView);
        } else {
            this.gridView.refreshGridData();
        }
        this.ui.$submit.attr('aria-busy', false);
        this.ui.$grid.removeClass('hide');
        this.ui.$widgetLinks.removeClass('hide');
    },

    fetchError() {
        const alertView = alert.danger(
            locale.get('tknmgmt.ui.fetch.error'),
            {
                canDismiss: true,
            },
        );
        this.alertRegion.show(alertView);
        this.ui.$submit.attr('aria-busy', false);
    },
});
