import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import Collection from '@glu/core/src/collection';
import GridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import LocationView from 'app/lockbox/views/admin/addLocation';
import LockboxView from 'app/lockbox/views/admin/addLockbox';
import LockboxSelectView from 'app/lockbox/views/admin/viewLockbox';
import LocationModel from 'app/lockbox/models/locations';
import LockboxModel from 'app/lockbox/models/lockbox';
import LocationCollection from 'app/lockbox/collections/locations';
import scroll from 'common/util/scroll';
import template from './panelView.hbs';

export default Layout.extend({
    template,

    initialize() {
        this.collection = new Collection();
        this.model = new LocationModel();
        this.lockboxGrid = {};
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
    },

    events: {
        'shown.bs.collapse .panel-collapse': 'getLockboxGridViewFromClick',
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            if (this.collection.length === 0) {
                const createLocation = new LocationView({
                    isFirst: true,
                });

                createLocation.once('location:action:success', () => {
                    self.loadRequiredData();
                });

                this.firstLocationRegion.show(createLocation);
            } else {
                this.listenTo(this, 'panel:refresh', this.refreshView);

                // Load the grid or add lockbox form (if no lockboxes) for the first panel
                this.getLockboxGridView(this.collection.at(0).get('LOCATION_ID'));
            }
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        const locationsPromise = this.getLockboxLocations();

        Promise.all([locationsPromise]).then((results) => {
            [self.collection] = results;
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    getLockboxLocations() {
        return new Promise((resolve, reject) => {
            const locations = new LocationCollection();
            locations.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    getLockboxGridViewFromClick(e) {
        this.getLockboxGridView(this.$(e.currentTarget).attr('data-id'));
    },

    getLockboxGridView(id) {
        const self = this;
        const lockboxGrid = `lockboxGrid-${id}`;

        const options = {
            context: {
                serviceName: '/lockbox',
            },

            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,

            additionalSearchFields: [{
                dataType: 'text',
                fieldName: 'LOCATION_ID',
                operator: '=',
                fieldValue: [id],
            }],
        };

        this.$(`.panel-heading[data-hook="${id}"]`).find('.btn[data-action="deleteLocation"]').addClass('hide');
        this[lockboxGrid] = GridApi.createServiceGridView(options);
        this.loadLockboxView(id);
        this.listenTo(this[lockboxGrid], 'rowAction', self.gridRowAction);
    },

    /**
     * @method gridRowAction
     * @param {object} options - contains the model associated to the selected row
     * - This method handles Modify, Delete, and Select (view) workflows for lockbox
     */
    gridRowAction(options) {
        return new Promise((resolve) => {
            const self = this;
            if (options.action.toUpperCase() === 'MODIFY') {
                this.modifyLockbox(options.model, false);
                resolve();
            } else if (options.action.toUpperCase() === 'DELETE') {
                options.model.destroy({
                    success(model, result) {
                        self.renderSuccessMessage(result, locale.get('LBX.LockboxRemoved'));
                        resolve({ model, result });
                    },
                });
            } else if (options.action.toUpperCase() === 'SELECT') {
                this.modifyLockbox(options.model, true);
                resolve();
            }
            resolve();
        });
    },

    loadLockboxView(id) {
        const lockboxRegion = `lockboxRegion-${id}`;
        const lockboxGrid = `lockboxGrid-${id}`;
        const self = this;

        const model = this.collection.findWhere({
            LOCATION_ID: id,
        });

        if (this[lockboxRegion] && this[lockboxGrid]) {
            this.listenTo(this[lockboxGrid], 'gridapi:loaded', function () {
                if (this[lockboxGrid].wrapper.rows.totalCount === 0) {
                    const createLockbox = new LockboxView({
                        state: 'form',

                        location: {
                            LOCATION_ID: model.get('LOCATION_ID'),
                            title: `${model.get('LOCATION_DESC')} / ${model.get('LOCATION_CODE')}`,
                            LOCATION_CODE: model.get('LOCATION_CODE'),
                            LOCATION_DESC: model.get('LOCATION_DESC'),
                        },
                    });

                    this.$(`.panel-heading[data-hook="${id}"]`).find('.btn[data-action="deleteLocation"]').removeClass('hide');
                    this.$(`[data-region="${lockboxRegion}"]`).next().toggleClass('hide');

                    createLockbox.on('lockbox:action:success', function (result) {
                        const message = locale.get('LBX.Alert.Lockbox.Success.Add')
                            .replace('*', createLockbox.model.get('NAME'))
                            .replace('@', createLockbox.model.get('LOCATION_DESC'))
                            .replace('#', createLockbox.model.get('LOCATION_CODE'));

                        self.getLockboxGridView(createLockbox.model.get('LOCATION_ID'));
                        self.renderSuccessMessage(result, message);
                        this.$(`[data-region="${lockboxRegion}"]`).next().toggleClass('hide');
                    });
                    createLockbox.on('lockbox:action:error', this.renderErrorMessage, this);
                    this[lockboxRegion].show(createLockbox);
                }
            });

            this[lockboxRegion].show(this[lockboxGrid]);
        }
    },

    handleAddLocation() {
        const self = this;
        const createLocation = new LocationView({
            collection: this.collection,
        });

        createLocation.on('location:action:success', (result) => {
            self.collection.fetch({
                success() {
                    self.trigger(
                        'panel:refresh',
                        {
                            result,
                            message: locale.get('LBX.Alert.Location.Success.Add').replace('*', `"${createLocation.model.get('LOCATION_DESC')}"`),
                        },
                    );
                },
            });
        });
        createLocation.on('location:action:error', (response) => {
            self.renderErrorMessage(response);
        });
        createLocation.on('location:action:cancel', () => {
            createLocation.close();
        });
        this.addLocationRegion.show(createLocation);
    },

    editLocation(e) {
        const self = this;
        const locationId = this.$(e.target).parents('.panel-heading').attr('data-hook');

        const model = this.collection.findWhere({
            LOCATION_ID: locationId,
        });

        const modifyLocation = new LocationView({
            id: locationId,
            title: `${model.get('LOCATION_DESC')} / ${model.get('LOCATION_CODE')}`,
            model: model.toJSON(),
            viewType: 'modal',
        });

        modifyLocation.on('location:action:success', (result) => {
            dialog.close();
            self.collection.fetch({
                success() {
                    self.trigger(
                        'panel:refresh',
                        {
                            result,
                            message: locale.get('LBX.Alert.Location.Success.Modify').replace('*', modifyLocation.model.get('LOCATION_DESC')),
                        },
                    );
                },
            });
        });
        modifyLocation.on('location:action:cancel', () => {
            dialog.close();
        });

        dialog.open(modifyLocation);
    },

    deleteLocation(e) {
        const self = this;
        const locationId = this.$(e.target).parents('.panel-heading').attr('data-hook');

        const location = new LocationModel({
            LOCATION_ID: locationId,
        });

        const model = this.collection.findWhere({
            LOCATION_ID: locationId,
        });

        location.set({
            id: locationId,
            LOCATION_ID: locationId,
        });

        dialog.confirm(locale.get('lockbox.delete.confirm'), `${model.get('LOCATION_DESC')} - ${model.get('LOCATION_CODE')}`, (ok) => {
            if (ok) {
                location.destroy({
                    success(successModel, confirmResponse) {
                        self.collection.fetch({
                            success() {
                                self.trigger(
                                    'panel:refresh',
                                    {
                                        result: confirmResponse,
                                        message: locale.get('LBX.LocationRemoved'),
                                    },
                                );
                            },
                        });
                    },

                    error(errorModel) {
                        self.renderErrorMessage(errorModel);
                    },
                });
            }
        });
    },

    addLockbox(e) {
        const self = this;

        const id = this.$(e.target).parents('.panel').find('.panel-heading').attr('data-hook');
        const code = this.$(e.target).closest('.panel-collapse').attr('data-hook');
        const desc = this.$(e.target).closest('.panel-collapse').attr('data-name');

        const createLockboxView = new LockboxView({
            state: 'modal',

            location: {
                LOCATION_ID: id,
                title: `${desc} / ${code}`,
                LOCATION_CODE: code,
                LOCATION_DESC: desc,
            },
        });
        createLockboxView.on('lockbox:action:success', (result) => {
            const message = locale.get('LBX.Alert.Lockbox.Success.Add')
                .replace('*', createLockboxView.model.get('NAME'))
                .replace('@', createLockboxView.model.get('LOCATION_DESC'))
                .replace('#', createLockboxView.model.get('LOCATION_CODE'));

            self.renderSuccessMessage(result, message);
            self.getLockboxGridView(id);
        });
        createLockboxView.on('lockbox:cancel', () => {
            dialog.close();
        });

        dialog.open(createLockboxView);
    },

    /**
     * @method modifyLockbox
     * @param {Model} model - contains the model associated to the view
     * @param {Boolean} isView - identifies if this is a view mode or modify mode
     * - This method handles the modify and view of lock box..
     */
    modifyLockbox(model, isView) {
        const self = this;
        const attributes = model.toJSON();
        let lockbox;

        util.each(attributes, (value, key) => {
            let localValue = value;
            if (['IMAGE_VIEWING', 'DETAIL_REPORTS', 'REPORT_FLOAT'].indexOf(key) > -1) {
                localValue = (localValue === 'Y');
                attributes[key] = localValue;
            }
        });

        if (isView) {
            lockbox = new LockboxSelectView({
                state: 'modal',
                model: attributes,
            });
        } else {
            lockbox = new LockboxView({
                state: 'modal',
                model: attributes,
            });
        }
        lockbox.on('lockbox:action:success', (result) => {
            const message = locale.get('LBX.Alert.Lockbox.Success.Modify')
                .replace('*', lockbox.model.get('NAME'));

            self.renderSuccessMessage(result, message);
            self.getLockboxGridView(lockbox.model.get('LOCATION_ID'));
        });
        lockbox.on('lockbox:cancel', () => {
            dialog.close();
        });

        dialog.open(lockbox);
    },

    removeLockbox(e) {
        const id = this.$(e.target).parents('.panel').find('.panel-heading').attr('data-hook');
        const lockboxGrid = `lockboxGrid-${id}`;
        let lockbox;
        const promises = [];
        const ar = this[lockboxGrid].grid.getSelectedRows();
        const self = this;

        if (ar.length > 0) {
            const collection = new Collection(this[lockboxGrid].wrapper.rows.models);

            this.successResp = [];
            this.errorResp = [];
            dialog.confirm(locale.get('LBX.ConfirmDeleteLockbox'), locale.get('LBX.ConfirmDelete'), (ok) => {
                if (ok) {
                    for (let x = 0; x < ar.length; x += 1) {
                        lockbox = new LockboxModel();
                        lockbox.set({
                            id: ar[x].cid,
                            LOCKBOX_ID: collection.get(ar[x].cid).get('LOCKBOX_ID'),
                        });
                        promises.push(self.getLockboxPromise(lockbox, id));
                    }

                    Promise.all(promises).then((results) => {
                        if (self.successResp.length > 1) {
                            for (let y = 1; y < self.successResp.length; y += 1) {
                                results[0].confirms.confirmResults
                                    .push(self.successResp[y].confirms.confirmResults[0]);
                            }
                        }

                        if (self.errorResp.length > 0) {
                            self.renderErrorMessage(results[0]);
                        } else {
                            self.renderSuccessMessage(results[0], locale.get('LBX.LockboxRemoved'));
                            self.getLockboxGridView(self.successResp[0].locationId);
                        }
                    });
                }
            });
        }
    },

    getLockboxPromise(lockbox, id) {
        const self = this;
        return new Promise((resolve, reject) => {
            lockbox.destroy({
                success(model, response) {
                    response.locationId = id;
                    self.successResp.push(response);
                    resolve(response);
                },

                error(response) {
                    self.errorResp.push(response);
                    reject(response);
                },
            });
        });
    },

    renderSuccessMessage(confirmResponse, message) {
        const confirms = new Confirms({
            confirms: confirmResponse ? confirmResponse.confirms : null,
        });

        this.alertView = alert.success(
            message,
            {
                details: confirmResponse
                && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                    ? confirms : null,
                canDismiss: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    renderErrorMessage(model) {
        const confirms = new Confirms({
            confirms: model.error.confirms,
        });

        this.alertView = alert.danger(
            model.error.message[0],
            {
                details: confirms,
                canDismiss: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    refreshView(alertData) {
        this.render();
        this.renderSuccessMessage(alertData.result, alertData.message);
    },

    templateHelpers() {
        const self = this;

        return {
            lockboxLocations() {
                return (self.collection.length > 0) ? self.collection.toJSON() : false;
            },
        };
    },
});
