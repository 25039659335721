import $ from 'jquery';
import alert from '@glu/alerts';
import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import { isDeepLinked } from 'common/util/deeplinkUtil';
import template from './reasonDialog.hbs';

export default Layout.extend({
    template,

    ui: {
        alertRegion: '.alert-region',
        $reason: '#REASON',
    },

    initialize(options) {
        this.model = new Model();
        this.options = options;
        this.actionMode = options.actionType === 'voidCheck' ? 'VOID' : 'STOP';
        this.stopVoidCheckURL = '/ptxConnect/stopVoidCheck';
        this.voidCheckSuccessMessage = locale.get('clm.voidCheckSuccessMessage');
        this.voidCheckFailMessage = locale.get('clm.voidCheckFailMessage');
        this.stopCheckSuccessMessage = locale.get('clm.stopCheckSuccessMessage');
        this.stopCheckFailMessage = locale.get('clm.stopCheckFailMessage');

        this.setInitModalTitleButtons();
    },

    setInitModalTitleButtons() {
        this.modalClass = this.options.modalClass || '';

        this.dialogTitle = this.options.pageTitle || locale.get('clm.voidreasondialog.title');

        this.dialogButtons = [{
            text: locale.get('administration.continue.btn'),
            className: 'btn btn-primary',
            callback: 'clickContinue',
        }, {
            text: locale.get('administration.cancel.btn'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    cancel() {
        dialog.close();
    },

    clickContinue() {
        const url = services.generateUrl(this.stopVoidCheckURL);

        this.clearErrors();

        if (util.isEmpty(this.$('#REASON').val())) {
            this.showAlertOnError(locale.get('CLM.ReasonMandatory'));
            return;
        }

        const modalFooter = $('.modal-footer');
        if (modalFooter.length) {
            const continueButton = modalFooter.children()[0];
            continueButton.disabled = !!continueButton;
        }

        const postData = {
            items: [{
                item: [
                    {
                        name: 'TNUM',
                        value: this.options.model.get('TNUM'),
                    },
                    {
                        name: 'ACTIONMODE',
                        value: this.actionMode,
                    },
                    {
                        name: 'REASON',
                        value: this.$('#REASON').val(),
                    },
                    {
                        name: 'UPDATECOUNT__',
                        value: this.options.model.get('UPDATECOUNT__'),
                    },
                    {
                        name: 'documentId',
                        value: this.options.model.get('PTX_CHECK_ID'),
                    },
                ],
            }],
        };
        http.post(url, postData).then((response) => {
            const self = this;

            if (response.item) {
                const pcmStopVoidStatus = response.item.find(element => element.name === 'pcmStopVoidStatus');
                if (pcmStopVoidStatus.value === 'true') {
                    const successMessageObject = {};
                    if (this.actionMode === 'VOID') {
                        successMessageObject.message = this.voidCheckSuccessMessage;
                    } else if (this.actionMode === 'STOP') {
                        successMessageObject.message = this.stopCheckSuccessMessage;
                    }
                    successMessageObject.confirms = {
                        totalSuccess: 1,
                        totalFail: 0,
                        confirmResults: [{ result: true, messages: [] }],
                    };
                    store.set('payment_listView_corp-alertMessage', successMessageObject.message);
                    store.set('payment_listView_corp-confirms', successMessageObject);
                } else if (pcmStopVoidStatus.value === 'false') {
                    if (this.actionMode === 'VOID') {
                        store.set('payment_listView_corp-alertMessage', self.voidCheckFailMessage);
                        store.set('payment_listView_corp-confirms', self.voidCheckFailMessage);
                    } else if (this.actionMode === 'STOP') {
                        store.set('payment_listView_corp-alertMessage', self.stopCheckFailMessage);
                        store.set('payment_listView_corp-confirms', self.stopCheckFailMessage);
                    }
                }
                this.clearErrors();
                dialog.close();
                if (isDeepLinked()) {
                    this.options.refreshData();
                } else {
                    this.refresh();
                }
            }
        });
    },

    showAlertOnError(message) {
        const alertView = alert.danger(message);
        this.ui.alertRegion.append(alertView.render().el);
    },

    clearErrors() {
        this.ui.alertRegion.empty();
    },

});
