import { appBus } from '@glu/core';
import $ from 'jquery';

const attributeName = 'data-disable-on-submit';
let syncCounter = 0;
const $body = $('body');

appBus.on('sync:begin', () => {
    syncCounter += 1;
    $body.find(`[${attributeName}]`).prop('disabled', true);
});

appBus.on('sync:end', () => {
    syncCounter = syncCounter === 0 ? 0 : syncCounter - 1;
    if (syncCounter === 0) {
        $body.find(`[${attributeName}]`).prop('disabled', false);
    }
});
