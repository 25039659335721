var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lockboxItem\">\n    <div>\n        <span class=\"lockbox-byLine\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.EnteredBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":63}}}))
    + " <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"author") || (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"author","hash":{},"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":82}}}) : helper)))
    + "</strong> <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"ENTERED_TIMESTAMP") || (depth0 != null ? lookupProperty(depth0,"ENTERED_TIMESTAMP") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"ENTERED_TIMESTAMP","hash":{},"data":data,"loc":{"start":{"line":3,"column":100},"end":{"line":3,"column":121}}}) : helper)))
    + "</strong></span>\n        <span class=\"icon-cancel pull-right\" data-action=\"removeNote\"></span>\n    </div>\n    <div>\n        <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"NOTE_TEXT") || (depth0 != null ? lookupProperty(depth0,"NOTE_TEXT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"NOTE_TEXT","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":24}}}) : helper)))
    + "</p>\n    </div>\n</div>\n";
},"useData":true});