export default ({ palette, typography }) => ({
    drawer: {
        background: '#fff',
    },
    drawerContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
    },
    drawerHeader: {
        background: `linear-gradient(to left, ${palette.primary.light}, ${palette.primary.dark})`,
        color: palette.text.light,
        left: 0,
        minHeight: 50,
        position: 'fixed',
        textAlign: 'center',
        top: 0,
        width: '100%',
        zIndex: 1,
        '& button': {
            background: 'transparent',
            left: 5,
            padding: 0,
            position: 'absolute',
            top: 15,
        },
        '& [class*="Icon"] svg': {
            width: 20,
            height: 20,
            fill: palette.text.light,
        },
        '& h3': {
            flexGrow: 1,
            fontSize: typography.h3.fontSize,
            fontWeight: typography.h3.fontWeight,
            margin: [15, 0, 0],
            textTransform: typography.h3.textTransform,
        },
    },
    drawerContent: {
        width: '100%',
        margin: [60, 0, 20],
        flexGrow: '1',
        zIndex: 0,
        height: 'inherit',
        overflow: 'scroll',

        '& svg.icon-caret-right': {
            height: 16,
            width: 16,
        },
    },
});
