import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import TypeToggleView from 'common/util/typeToggle/typeToggleView';
import constants from './typeToggleConstants';
import typeToggleWidgetTmpl from './typeToggleWidget.hbs';

export default BaseWidget.extend({
    template: typeToggleWidgetTmpl,
    className: 'ui-widget typeToggle-widget field-container',

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);
        this.options = options;
        this.setupData();
        this.setupListener();
    },

    regions: {
        typeToggleRegion: '.type-toggle-region',
    },

    /**
     * Set initial data required in view
     */
    setupData() {
        this.fieldData = this.options.fieldData;
        this.stateToggleData = this.options.stateToggleData;
        this.iconData = this.fieldData.relatedProperty.split(',');
    },

    /**
     * Setup listener for type toggle event fired when user clicks on inactive
     * toggle button
     */
    setupListener() {
        const toggleCode = this.stateToggleData.queryRows['0'].mapDataList['0'].toField;
        const toggleDescription = this.fieldData.name;

        this.listenTo(this.appBus, 'event:stateToggle', (data) => {
            this.parentModel.set(toggleCode, data.value);
            this.parentModel.set(toggleDescription, data.description);
            this.parentModel.trigger('widget:update', this);
        });
    },

    /**
     * Get icon class name based on data from server
     * @param  {string} iconData Value to map to icon class
     * @return {string} Mapped icon class name
     */
    getIcon(iconData) {
        return constants.iconMap[iconData.trim().toUpperCase()];
    },

    onRender() {
        const buttons = util.map(this.stateToggleData.queryRows, (
            val,
            index,
        ) => ({
            toggleIcon: this.getIcon(this.iconData[index]),
            toggleText: val.label,
            toggleValue: val.mapDataList[0].value,
            active: index === 0,
            hideToggle: !!(this.model.attributes.CHECK_STOP_TYPE === '1' && this.state === 'MODIFY' && index !== 0),
        }));
        // instantiate typeToggle
        this.typeToggleRegion.show(new TypeToggleView({
            toggleDescription: this.fieldData.fieldLabel,
            separatorText: locale.get('multiStop.toggle.separatorText'),
            toggleBtns: buttons,
        }));
    },
});
