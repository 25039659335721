import exportBAIDetailTmpl from 'app/reports/views/exportBTRData/bai/exportBAIDetail.hbs';
import Base from '../common/base';

const BAITYPE = 'BAITYPE';

export default Base.extend({
    template: exportBAIDetailTmpl,

    initialize(options) {
        this.baiRestriction = options.baiRestriction;
        Base.prototype.initialize.call(this, options);
    },

    onRender() {
        this.setupCurrentPriorDropdown(this.model.get(BAITYPE));
        this.renderOutputContentDropdown();
    },
});
