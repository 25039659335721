import React from 'react';

/* eslint jsx-a11y/label-has-for: 0  react/prop-types: 0 */
// Note: I don't provide propTypes because there is LOT of UDFs.

const UserDefinedFields = (props) => {
    const udfEntries = [];

    // 'props' (in fact is rest of parent) contains TYPE, activeTab, and many UDF entries.
    Object.keys(props).forEach((key) => {
        if (key.indexOf('USERDEFINED') > -1) {
            const { label, value } = props[key];
            if (label && value) {
                udfEntries.push(props[key]);
            }
        }
    });

    return (
        <React.Fragment>
            {udfEntries.map((udf, index) => (
                <div className="col-md-2" key={index}>
                    <label>{udf.label || ''}</label>
                    <p><span>{udf.value || '--'}</span></p>
                </div>
            ))}
        </React.Fragment>
    );
};

export default UserDefinedFields;
