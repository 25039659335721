import Layout from '@glu/core/src/layout';
import PaymentsTransitionFields from 'app/administration/views/paymentManagement/paymentsTransitionFields';
import Dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import services from 'services';
import transform from 'common/util/transform';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import FlexDropdown from '@glu/flex-dropdown';
import paymentsTransitionsViewTmpl from './paymentsTransitionsView.hbs';

export default Layout.extend({
    template: paymentsTransitionsViewTmpl,

    ui: {
        bankConfirmedContainer: '[data-hook="bankConfirmedContainer"]',
        bankRejectedContainer: '[data-hook="bankRejectedContainer"]',
        bankCommentContainer: '[data-hook="bankCommentContainer"]',
    },

    onRender() {
        // populate a list of all transition types
        const transitionData = util.map(Object.keys(this.options.transitions), transition => ({
            id: transition,
            name: this.options.transitions[transition],
        }));

        // create the transitions dropdown and apply a listener
        const dropdown = new FlexDropdown({
            data: transitionData,
            disableMultiButton: true,
            showTooltip: false,
            preSelectFirstItem: true,
        });
        this.paymentTransitionsDropdown.show(dropdown);
        this.listenTo(dropdown, 'selectionChanged', this.selectedTransitionChange);
        // toggle the inputs into their initial state
        this.toggleInputs();
        this.selectedTransitionChange(dropdown.value());
    },

    initialize(options) {
        this.options = options;
        this.transitionSelected = '';
        this.status = options.status;
        this.model.validators = {};
    },

    templateHelpers() {
        return {
            STATUS_DESCRIPTION: this.status,
        };
    },

    /*
     * @method selectedTransitionChange
     * @param selected - the selected flex dropdown item
     */
    selectedTransitionChange(selected) {
        this.transitionFields = new PaymentsTransitionFields({
            transitionName: selected[0].id,
        });
        this.transitionSelected = this.transitionFields.contextDef.subType;
        this.detailsRegion.show(this.transitionFields);

        this.toggleInputs(selected[0].id);
    },

    /*
     * @method toggleInputs
     * @param transitionID - string - sets the transition name and
     * - toggles validators/inputs appropriately based on
     * the flex dropdown selection
     */
    toggleInputs(transitionID) {
        this.model.set('TRANSITION_NAME', transitionID);

        switch (transitionID) {
        case 'BANKRJCT':
        case 'EXTRJ':
            this.ui.bankRejectedContainer.show();
            this.ui.bankConfirmedContainer.hide();
            this.ui.bankCommentContainer.removeClass('required');
            this.model.validators.REJECT_REASON = {
                description: locale.get('transition.rejectreason'),
                exists: true,
            };
            delete this.model.validators.TRANSITION_REASON;
            break;
        case 'BANKBC':
        case 'EXTBC':
        case 'BANKRCVD':
        case 'EXTRCVD':
            this.ui.bankRejectedContainer.hide();
            this.ui.bankConfirmedContainer.show();
            this.ui.bankCommentContainer.removeClass('required');
            delete this.model.validators.REJECT_REASON;
            delete this.model.validators.TRANSITION_REASON;
            break;
        default:
            this.ui.bankRejectedContainer.hide();
            this.ui.bankConfirmedContainer.hide();
            this.ui.bankCommentContainer.addClass('required');
            delete this.model.validators.REJECT_REASON;
            this.model.validators.TRANSITION_REASON = {
                description: locale.get('transition.transitionreason.label'),
                exists: true,
            };
            break;
        }
    },

    cancel() {
        Dialog.close();
    },

    /*
     * @method getJSONData
     * compiles/formats the form data before sending as the payload of a save call
     */
    getJSONData() {
        const fields = transform.hashToPairs(this.transitionFields.pageView.model.attributes);
        const items = fields.map(row => ({
            item: transform.hashToPairs({
                FIELDNAME: row.name,
                FIELDVALUE: row.value,
            }),
        }));
        return {
            item: {
                item: transform.hashToPairs({
                    TRANSITION_NAME: this.model.get('TRANSITION_NAME'),
                    TNUM: this.model.get('TNUM'),
                    PAYMENT_PRODUCT: this.model.get('PRODUCT'),
                    PAYMENT_FUNCTION: this.model.get('FUNCTION'),
                    PAYMENT_TYPE: this.model.get('TYPE'),
                    TRANSITION_REASON: this.model.get('TRANSITION_REASON'),
                    CONFIRMATION_NUMBER: this.model.get('CONFIRMATION_NUMBER'),
                    REJECT_REASON: this.model.get('REJECT_REASON'),
                }),
            },

            grids: [{
                name: 'TRANSITIONTRACKINGCHILD',
                items,
            }],
        };
    },

    /**
     * Parse the response and render the Confirms view in the region
     * @param {Object} response - standard DGB response object
     */
    showConfirmErrors(response) {
        const confirms = new Confirms({
            confirms: response.confirms,
        });
        this.alertRegion.show(alert.danger(
            response.message[0],
            {
                details: confirms,
            },
        ));
    },

    save() {
        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }
        if (this.transitionFields && this.transitionFields.pageView.model.validate()) {
            this.transitionFields.pageView.model.trigger('invalid');
            return;
        }
        const jsonData = this.getJSONData();
        http.post(services.generateUrl('transitionsMaint/add'), jsonData, (data) => {
            if (data.result && data.errorCode === 0) {
                this.trigger('resolve', data);
                Dialog.close();
            } else {
                this.showConfirmErrors(data);
            }
        }, (data) => {
            this.showConfirmErrors(data.responseJSON);
        });
    },
});
