import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import GridApi from 'common/dynamicPages/api/grid';
import cashFlowMatchLayoutTmpl from './cashFlowMatchLayout.hbs';

const CashFlowMatchLayout = Layout.extend({
    template: cashFlowMatchLayoutTmpl,
    className: 'modal cashflow-match',

    regions: {
        matchedGrid: 'div[data-hook="matched-grid-region"]',
        newMatchGrid: 'div[data-hook="new-match-grid-region"]',
    },

    ui: {
        $matchBtn: '.btn[data-hook="match"]',
        $unmatchBtn: '.btn[data-hook="unmatch"]',
    },

    events: {
        'click @ui.$matchBtn': 'match',
        'click @ui.$unmatchBtn': 'unmatch',
    },

    initialize() {
        this.matchedItemIds = [];
        this.gridsReady = 0;
    },

    saveMatches(ids) {
        const self = this;

        http.post(
            services.generateUrl('/cashflowMatch/match'),
            {
                cashFlowItemId: +this.model.get('CASHFLOWITEMID'),
                matchCashFlowItemIds: ids,
            },
            (response) => {
                self.matchedItemIds = response.matchCashFlowItemIds;
                self.filterMatchedEntries();
            },
        );
    },

    onShow() {
        const options = {
            context: {
                serviceName: '/tableMaintenance/type.cashFlowItemMatchGrid',
            },

            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
        };

        this.matchedRemoteGridView = GridApi.createServiceGridView(options);
        this.newMatchRemoteGridView = GridApi.createServiceGridView(options);

        this.matchedGrid.show(this.matchedRemoteGridView);
        this.newMatchGrid.show(this.newMatchRemoteGridView);

        this.listenToOnce(
            this.matchedRemoteGridView,
            {
                'item:rendered': this.completeGridSetup,
            },
        );
        this.listenToOnce(
            this.newMatchRemoteGridView,
            {
                'item:rendered': this.completeGridSetup,
            },
        );

        this.listenTo(
            this.appBus,
            {
                'grid:selectRow grid:selectAllRows': this.updateButtons,
            },
        );
    },

    onClose() {
        this.model.trigger('match:completed', this.model);
    },

    updateButtons() {
        if (this.newMatchRemoteGridView.grid) {
            this.ui.$matchBtn.prop('disabled', this.newMatchRemoteGridView.grid.getSelectedRows().length === 0);
        }
        if (this.matchedRemoteGridView.grid) {
            this.ui.$unmatchBtn.prop('disabled', this.matchedRemoteGridView.grid.getSelectedRows().length === 0);
        }
    },

    completeGridSetup() {
        this.gridsReady += 1;
        if (this.gridsReady === 2) {
            this.updateMatchedEntries();

            this.listenTo(
                this.newMatchRemoteGridView.grid.tableBody,
                {
                    'collection:rendered': this.updateButtons,
                },
            );
        }
    },

    updateMatchedEntries() {
        const self = this;
        http.post(
            services.generateUrl('/cashflowMatch/list'),
            {
                cashFlowItemId: +this.model.get('CASHFLOWITEMID'),
                matchCashFlowItemIds: [],
            },
            (response) => {
                self.matchedItemIds = response.matchCashFlowItemIds;
                self.filterMatchedEntries();
            },
        );
    },

    filterMatchedEntries() {
        /*
         * an empty array returns all rows - null will return 0 rows
         * we can only apply 1 filter at a time, but luckily self will never be matched
         */
        this.matchedRemoteGridView.grid.filterProc.addFilter({
            label: locale.get('cashflow.matched.entries'),
            value: this.matchedItemIds.length > 0 ? this.matchedItemIds : null,
            field: 'CASHFLOWITEMID',
            equality: 'IN',
            type: 'number',
        });

        // NH-17274: prefilter for other items that have this description
        this.newMatchRemoteGridView.grid.filterProc.addFilter({
            label: this.model.get('DESCRIPTION'),
            value: this.model.get('DESCRIPTION'),
            field: 'DESCRIPTION',
            equality: 'CONTAINS',
            type: 'string',
        });

        const exclude = [].concat(this.matchedItemIds, +this.model.get('CASHFLOWITEMID'));
        this.newMatchRemoteGridView.grid.filterProc.addFilter({
            label: locale.get('cashflow.unmatched.entries'),
            value: exclude,
            field: 'CASHFLOWITEMID',
            equality: 'NOT IN',
            type: 'number',
        });
    },

    getSelectedIdsFromGridView(gridView) {
        const rowIds = util.pluck(gridView.grid.getSelectedRows(), 'cid');
        const models = gridView.grid.collection
            .filter(model => util.contains(rowIds, model.cid));

        return util.map(models, model => +model.get('CASHFLOWITEMID'));
    },

    match() {
        const modelIds = this.getSelectedIdsFromGridView(this.newMatchRemoteGridView);
        const updatedList = [].concat(modelIds, this.matchedItemIds);
        this.saveMatches(updatedList);
    },

    unmatch() {
        const modelIds = this.getSelectedIdsFromGridView(this.matchedRemoteGridView);
        const updatedList = util.difference(this.matchedItemIds, modelIds);
        this.saveMatches(updatedList);
    },
});

export default CashFlowMatchLayout;
