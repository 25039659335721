import Collection from '@glu/core/src/collection';
import AccountGroup from 'app/accounts/models/accountGroup';
import http from '@glu/core/src/http';
import services from 'services';

const GroupAccount = Collection.extend({

    model: AccountGroup,

    parse(response) {
        return response.manyItems;
    },

    url: services.generateUrl('accountGroups/readAll'),

    sync(method, collection, options) {
        if (method !== 'read') {
            throw new Error(`This collection only knows how to read, but it was asked to ${method}.`);
        }

        const request = {
            headerSearchFields: [{
                fieldName: 'tabId',
                fieldValue: [
                    options.tabId,
                ],
                dataType: 'text',
                operator: '=',
            }],
        };

        return http
            .post(this.url, request)
            .then(options.success, options.error);
    },

    save() {
        const groupsToSave = [];
        this.models.forEach((model) => {
            let action;
            if (model.isNew() && model.isMarkedForDeletion()) {
                action = 'NONE';
            } else if (model.isNew()) {
                action = 'INSERT';
            } else if (model.isMarkedForDeletion()) {
                action = 'DELETE';
            } else if (model.hasBeenModified()) {
                action = 'MODIFY';
            } else {
                action = 'NONE';
            }

            if (action !== 'NONE') {
                const group = model.toJSON();
                group.item.item.push({
                    name: 'ROWACTION',
                    value: action,
                });
                groupsToSave.push(group);
            }
        });

        if (groupsToSave.length === 0) {
            return {
                then: (success) => {
                    success({
                        result: 'noop',
                    });
                },
            };
        }

        return http.post(
            services.generateUrl('accountGroups/update'),
            { manyItems: groupsToSave },
        );
    },
});

export default GroupAccount;
