import Collection from '@glu/core/src/collection';
import WidgetModel from 'common/workspaces/models/widget';

export default Collection.extend({

    initialize(models, options) {
        if (options) {
            this.jsonData = options.jsonData;
            this.isExternal = options.isExternal || false;
        }
    },

    comparator: 'rowNumber',

    sync(method, collection, options) {
        options.success(this.jsonData);
    },

    parse(jsonData) {
        const externalKey = {
            isExternal: this.isExternal,
        };
        return jsonData.map(def => new WidgetModel({ ...def, ...externalKey }));
    },
});
