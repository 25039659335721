import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import client from './client';

// FIXME - Need to set a default url, possibly move to a constants file?
const DEFAULT_NOTIFICATION_URL = '';

export default Model.extend({
    initialize(options = {}) {
        // Precaution to ensure we never lose an active timer.
        if (this.timer) {
            throw new Error('Active poller re-initialized');
        }

        // 1 minute default polling length
        this.interval = options.interval || 60000;

        // Start disabled so we can configure if needed.
        this.active = false;

        // Always check for an existing timer before setting one!
        this.timer = false;

        /*
         * Time from end uses setTimeout, not setInterval.
         *  Useful for requests that may run long, so the interval is between
         *  the end of one request and the start of the next.
         */
        this.timeFromEnd = options.timeFromEnd || false;

        // When enabling the poller, run immediately or wait for the first interval?
        this.leading = options.leading || false;

        this.serviceUrl = options.serviceUrl || DEFAULT_NOTIFICATION_URL;

        /*
         * Consecutive Error logic shuts down the poller if too many errors occur.
         * For unlimited errors, set maxConsecutiveErrors = -1.
         */
        this.consecutiveErrorCount = 0;
        this.maxConsecutiveErrors = options.maxConsecutiveErrors || 5;

        this.setupListeners();
    },

    /**
     * Add listeners for subscribe, unsubscribe, and even potentially listen
     *  for other events/actions on the client, in case we need to poll in
     *  response to some other action happening.
     */
    setupListeners() {
        this.listenTo(client, 'service:subscribe', this.handleSubscribe);
        this.listenTo(client, 'service:unsubscribe', this.handleUnsubscribe);
    },

    /**
     * Override this to specify whether a subscribe event
     *  should cause this poller to act.
     * @param {String} key
     * @return {boolean}
     */
    isRelevantSubscription(key) {
        return key === 'USER';
    },

    /**
     * Receive subscribe events.
     * Starts a poller if relevant.
     * @param {string} key
     */
    handleSubscribe(key) {
        // Need an enable type, a relevant subscribe event, and to not be active already.
        if (!this.isTypeEnabled() || !this.isRelevantSubscription(key) || this.active) {
            return;
        }
        this.enable(this.leading);
    },

    /**
     * Unsubcribe should only be called when a subscription key is removed.
     * We may support multiple subscriptions, so don't disable automatically,
     *  first check if our poller should still be running.
     * @param {String} key
     * @returns {boolean} - whether or not the service for this key is disabled
     */
    handleUnsubscribe(key) {
        if (!this.isRelevantSubscription(key) || !this.active) {
            return false;
        }
        // Check if we should stop our poller
        if (!this.isTypeEnabled()) {
            return this.disable();
        }
        return false;
    },

    /**
     * @param {boolean} startNow
     * @return {boolean}
     */
    enableTimer(startNow) {
        if (this.timer) {
            return false;
        }
        if (!this.timeFromEnd) {
            // Always clear the timer, just in case.
            window.clearInterval(this.timer);
            this.timer = window.setInterval(() => this.poll(), this.interval);
        }

        if (startNow) {
            this.poll();
        }
        return true;
    },

    /**
     * @param {boolean} startNow
     * @return {boolean}
     */
    enable(startNow) {
        if (this.active) {
            return true;
        }
        this.active = true;
        // Reset error counter
        this.consecutiveErrorCount = 0;

        this.enableTimer(startNow);
        return true;
    },

    /**
     * @return {boolean}
     */
    disable() {
        if (!this.active) {
            return false;
        }
        this.active = false;
        // clearInterval will also clear timeout timers.
        window.clearInterval(this.timer);
        this.timer = false;
        return true;
    },

    /**
     * TODO: Is this useful?
     * @param {string} url
     */
    setServiceUrl(url) {
        this.serviceURL = url;
    },

    /**
     * Different pollers will override this function so they do not poll when unneeded.
     * @return {boolean}
     */
    isTypeEnabled() {
        /*
         * Example implementation:
         * return client.getSubs().get('RTP') === true;
         */
        return true;
    },

    /**
     * @abstract
     * Needs to be overridden for the particular poller request.
     * @return {{}}
     */
    buildRequestObject() {
        return {};
    },

    /**
     * @abstract
     * Must be overridden by implementation
     * @param data
     * @return {*}
     */
    requestData(data) {
        // TODO - http to common service endpoint
        return new Promise((res, rej) => http.post(this.serviceUrl, data).then(res, rej));
    },

    /**
     * @abstract
     * Must be overridden by implementation
     * Should trigger events on the client for views to receive.
     */
    processResponses(data) {
        // Generic trigger. Probably want something more refined
        client.trigger('userEvent', data);

        throw new Error('processResponses not implemented');
    },

    /**
     * Override to provide some kind of real error handling
     * TODO:
     *  * Should it retry on failure?
     *  * Should it stop after a number of retries?
     * @param {Error} e
     */
    reportErrors(e) {
        window.console.error(e);
    },

    /**
     * Restart the timer if needed.
     */
    cyclePoll() {
        if (!this.active || !this.timeFromEnd) {
            return;
        }

        // Always clear the timer, just in case
        window.clearTimeout(this.timer);
        this.timer = window.setTimeout(() => this.poll(), this.interval);
    },

    /**
     * Run after completing a polling cycle and start another, if appropriate.
     */
    markFinished() {
        this.running = false;
        this.cyclePoll();
    },

    /**
     * Just reset the error counter
     * @param {*} data
     * @return {*}
     */
    checkSuccess(data) {
        this.consecutiveErrorCount = 0;
        return data;
    },

    /**
     * Increment the error counter and disable the poller if needed.
     * @param {*}e
     * @return {*}
     */
    checkError(e) {
        this.consecutiveErrorCount += 1;

        if (this.maxConsecutiveErrors >= 0
            && this.consecutiveErrorCount > this.maxConsecutiveErrors) {
            this.disable();
            // TODO: Log something?
        }

        // We want to continue down the rejection side.
        return Promise.reject(e);
    },

    /**
     * Actual polling logic.
     */
    poll() {
        if (!this.isTypeEnabled()) {
            return;
        }

        // Prevent overlapping requests if the interval is short or the request runs long
        if (this.running) {
            // This would cause timeFromEnd (setTimeout) handlers to stop, so cycle
            this.cyclePoll();
            return;
        }
        this.running = true;

        this.requestData()
            .then(data => this.checkSuccess(data))
            .catch(e => this.checkError(e))
            .then(data => this.processResponses(data))
            .catch(e => this.reportErrors(e))
            .then(() => this.markFinished());
    },
});
