import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import editPaymentOptionsTmpl from './editPaymentOptions.hbs';

export default ItemView.extend({
    initialize(options) {
        this.events = {
            'click [data-hook="menuCode"]': 'selectedRadioBtn',
        };
        this.paymentOptions = options.paymentOptions;
        this.model = options.model;
        this.menuCode = this.model.get('menuCode');
    },

    selectedRadioBtn(e) {
        const self = this;
        const optId = e.currentTarget.id;
        const payOptsArray = util.where(
            this.paymentOptions,
            {
                menuCode: optId,
            },
        );
        self.model.set('menuCode', payOptsArray[0].menuCode);
        self.model.set('menuDescription', payOptsArray[0].menuDescription);
        self.model.set('optionCost', payOptsArray[0].optionCost);
    },

    type: 'info',
    template: editPaymentOptionsTmpl,
    className: 'modal',
    title: locale.get('smb.payment.option.edit'),

    saveModal() {
        this.trigger('save', this.model);
        this.close();
    },

    closeModal() {
        this.close();
    },

    templateHelpers() {
        const payOptions = this.paymentOptions;
        let i;
        for (i = 0; i < payOptions.length; i += 1) {
            payOptions[i].optionCost = this.model.displayAmount(this.paymentOptions[i].optionCost);
        }
        return {
            title: this.title,
            paymentOptions: payOptions,
        };
    },

    ui: {
        $menuCode: '[data-hook="menuCode"]',
    },

    onShow() {
        const optionRadio = this.$el.find(`input[name=payment-options-radio][value="${this.menuCode}"]`);
        optionRadio.prop('checked', true);
    },
});
