import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import moment from 'moment';
import viewEmailRecipientsTemplate from './viewEmailRecipients.hbs';
import scheduleUtil from '../util';

export default Layout.extend({
    loadingTemplate,

    onRender() {
        if (scheduleUtil.shouldShowEmailRecipients()
            && this.emailRecipientsRegion
            && !util.isEmpty(this.model.get('EMAILRECIPIENTS'))) {
            this.emailRecipientsRegion.show(new Layout({
                template: viewEmailRecipientsTemplate,
                model: this.model,
            }));
        }
    },

    /**
     * Get the description for the report type if it has one and is entitled to it
     * @param {Object} selection - selected report type
     * @param {String} prependedValue - values to place before error message if
     * selection is not available
     * @returns {String} description
     */
    getDescription(selection, prependedValue) {
        if (!selection) {
            return `${prependedValue} - ${locale.get('GIR.No.Longer.Available')}`;
        }
        return selection.name;
    },

    templateHelpers() {
        const timeZoneId = this.model.get('TIMEZONEID');
        let timeZone;
        if (timeZoneId) {
            timeZone = moment(new Date()).tz(timeZoneId).format('z');
        }
        let timeFrequencies = this.model.get('TIMEFREQUENCIES');
        timeFrequencies = timeFrequencies.map(item => (`${item} ${timeZone}`));

        return {
            runTimes: timeFrequencies ? timeFrequencies.join(', ') : '',
            shouldShowEmailRecipients: scheduleUtil.shouldShowEmailRecipients(),
        };
    },
});
