import store from 'system/utilities/cache';
import ServiceRequestListView from './views/serviceRequestListView';
import ModifyView from './views/modifyView';
import DetailView from './views/detailView';

const CONTEXT_KEY = 'serviceRequest_freeform';

/**
 * Get the workspace route stored. If not set,
 * based on whether the user is an admin, return the proper route
 */
const getReturnRoute = () => {
    if (store.has('serviceRequestReturnRoute')) {
        return store.remove('serviceRequestReturnRoute');
    }
    return 'SERVICEREQUEST/serviceRequest';
};

export default {
    serviceRequestList() {
        this.showPage('', new ServiceRequestListView());
    },

    serviceRequestView() {
        this.showPage('', new DetailView({
            id: 'SERVREQ',
            mode: 'view',
            returnRoute: getReturnRoute(),
            contextKey: CONTEXT_KEY,
        }));
    },

    serviceRequestCreate() {
        this.showPage('', new ModifyView({
            id: 'SERVREQ',
            mode: 'insert',
            returnRoute: getReturnRoute(),
            contextKey: CONTEXT_KEY,
        }));
    },

    serviceRequestModify() {
        this.showPage('', new ModifyView({
            id: 'SERVREQ',
            mode: 'modify',
            returnRoute: getReturnRoute(),
            contextKey: CONTEXT_KEY,
        }));
    },
};
