export default ({
    'payments/addPayment': 'smbAddPayment',
    'payments/viewPayment': 'smbViewPayment',
    'payments/modifyPayment': 'smbModifyPayment',
    'templates/modifyTemplate': 'smbModifyTemplate',
    'templates/copyPaymentAsTemplate': 'smbCopyPaymentAsTemplate',
    'templates/copyEmployeePaymentAsTemplate': 'smbEmployeeCopyPaymentAsTemplate',
    'templates/copyAsPayment': 'smbCopyTemplateAsPayment',
    'templates/copyEmpAsPayment': 'smbEmployeeCopyTemplateAsPayment',
    'PAYMENTS/listSmbPayments(/)(:viewId)': 'smbListPayments',
    'PAYMENTS/listSmbStops(/)(:viewId)': 'smbListStops',
    'PAYMENTS/smbManageHomePage': 'smbManageHomePage',
    'PAYMENTS/smbManagePayments': 'smbWorkspace',
    'PAYMENTS/smbManageAccounts': 'smbManageAccounts',
    'payments/smbTileView': 'smbTileView',
    'payments/smbListViewStopPayment': 'chmListViewStopPayment',
    'payments/smbStopCancelCheckView': 'smbStopCancelCheckView',
    'PAYMENTS/smbAccountsCenter': 'smbAccountsCenter',
    'PAYMENTS/smbAccountsCenterListView': 'smbAccountsCenterListView',
    'PAYMENTS/listSmbEmployees': 'smbListEmployees',
    'PAYMENTS/smbManageEmployees': 'smbManageEmployees',
    'payments/viewEmpPayment': 'smbViewEmpPayment',
    'payments/modifyEmpPayment': 'smbModifyEmpPayment',
    'payments/copyPaymentFromEmpPayment': 'smbCopyPaymentFromEmpPayment',
    'payments/copyTax': 'smbCopyTax',
    'PAYMENTS/listSmbTransfers(/)(:viewId)': 'smbListTransfers',
    'PAYMENTS/smbManageTransfers': 'smbManageTransfers',
    'payments/addReimburseExpenses': 'smbAddReimburseExpenses',
    'payments/modifyReimburseExpenses': 'smbModifyReimburseExpenses',
    'payments/viewReimburseExpenses': 'smbViewReimburseExpenses',
    'payments/copyPaymentFromReimbursement': 'smbCopyPaymentFromReimbursement',
    'DEPOSIT_ACC_INFO/depositAccountsTransactions': 'smbDepositAccountTransactions',
    'DEPOSIT_ACC_INFO/depositAccountImage': 'smbDepositSlipImage',
    'LOAN_ACC_INFO/loanAccountsTransactions': 'smbLoanAccountTransactions',
    'LOAN_ACC_INFO/tieredLoanAccountDetail': 'smbTieredLoanAccountDetail',
    'PAYMENTS/smbSystemCenter': 'smbSystemCenter',
});
