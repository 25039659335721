import NestedModel from '@glu/core/src/nestedModel';
import ComplexType from './complexType';
import ComplexTypes from '../../collection/user/complexTypes';

export default NestedModel.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            displayOrder: 0,
            overallBatchLimitForGroup: null,
            overallDailyLimitForGroup: null,
            overallTransactionLimitForGroup: null,
            typeGroupId: '',
            typeGroupLabel: null,
            aggregateTypes: new ComplexType({
                isAggregate: true,
            }),
            typesInGroup: new ComplexTypes(),
        };
    },

    parse(response) {
        response.typesInGroup = new ComplexTypes(response.typesInGroup);
        if (response.aggregateTypes) {
            response.aggregateTypes = new ComplexType(response.aggregateTypes, {
                isAggregate: true,
            });
        }
        return response;
    },

    getEntitledTypes(withLimitValues) {
        return this.get('typesInGroup').filter(type => type.isEntitled() && (!withLimitValues
            || type.get('overallDailyLimitForType').assignedLimit
            || type.get('overallTransactionLimitForType').assignedLimit || type.get('overallBatchLimitForType').assignedLimit));
    },

    hasEntitledType() {
        return this.get('typesInGroup').some(type => type.isEntitled());
    },
});
