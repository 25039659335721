import ItemView from '@glu/core/src/itemView';
import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import alertConstant from 'app/administration/views/alerts/constants';
import constants from 'app/administration/constants';
import recipientItemTmpl from 'app/administration/views/alerts/recipientItem.hbs';

export default ItemView.extend({

    template: recipientItemTmpl,
    className: 'row recipient-item',

    initialize() {
        this.mode = this.model.collection.actionMode;
    },

    ui: {
        $emailContact: 'input[type="checkbox"][id*="-email-"]',
        $smsContact: 'input[type="checkbox"][id*="-sms-"]',
        $phoneContact: 'input[type="checkbox"][id*="-phone-"]',
        $faxContact: 'input[type="checkbox"][id*="-fax-"]',
    },

    events: {
        'change @ui.$emailContact': 'checkEmailContact',
        'change @ui.$smsContact': 'checkSMSContact',
        'change @ui.$phoneContact': 'checkPhoneContact',
        'change @ui.$faxContact': 'checkFaxContact',
        'click a[id^="More"]': 'toggleMoreOrLess',
    },

    /**
     * Toggle the children passed in.
     * @param {jQuery} $children
     */
    toggleChildren($children) {
        const lastChild = $children.length - 2;
        util.each($children, (child, index) => {
            if (index >= alertConstant.CONTACT_HIDESHOW_LIMIT && index <= lastChild) {
                $(child).toggleClass('hidden');
            }
        });
    },

    /**
     * More/Less Toggle to show/hide additional children
     * @param evt
     * @returns {boolean}
     */
    toggleMoreOrLess(evt) {
        const $target = this.$(evt.currentTarget);
        const $children = $target.closest('ul').children();
        const moreCount = $children.length - 1;
        const showAttribute = $target.attr('data-show');

        if (showAttribute === 'more') {
            $target.text(locale.get('alerts.showLess'));
            $target.attr('data-show', 'less');
        } else {
            $target.text(locale.get('alerts.showMore', moreCount));
            $target.attr('data-show', 'more');
        }
        // Always toggle
        this.toggleChildren($children);

        return true;
    },

    checkEmailContact(evt) {
        this.checkContact(evt, 'email');
    },

    checkSMSContact(evt) {
        this.checkContact(evt, 'sms');
    },

    checkPhoneContact(evt) {
        this.checkContact(evt, 'phone');
    },

    checkFaxContact(evt) {
        this.checkContact(evt, 'fax');
    },

    checkContact(evt, type) {
        const name = evt.target.id;
        const { checked } = evt.target;
        let contact;
        const typeSearch = `-${type}-`;

        if (name.indexOf(typeSearch) !== -1) {
            contact = util.findWhere(this.model.get(type), {
                name: evt.target.id,
            });
            contact.selected = checked;
        }
        if (!checked) {
            this.options.uncheckAll(type);
        }
    },

    checkAllContacts(type) {
        const self = this;
        if (this.model.get(type)) {
            util.each(this.model.get(type), (contact) => {
                if (contact.selected) {
                    self.$(`#${contact.name}`).prop('checked', true);
                }
            });
        }
    },

    onRender() {
        let email;
        if (this.mode.toUpperCase() === constants.INSERT) {
        // select the first email only while creating a new recipient
            email = this.model.get('email');
            if (email.length) {
                email[0].selected = true;
            }
        }
        // check the checkboxes
        this.checkAllContacts('email');
        this.checkAllContacts('sms');
        this.checkAllContacts('phone');
        this.checkAllContacts('fax');
    },

    templateHelpers() {
        return {
            hideCheckbox(index) {
                return (index >= alertConstant.CONTACT_HIDESHOW_LIMIT);
            },
            showMoreMessage(count) {
                return locale.get('alerts.showMore', count);
            },
            addShowMore(count) {
                return (count > alertConstant.CONTACT_HIDESHOW_LIMIT);
            },
            notViewMode: this.mode !== 'view',
        };
    },

});
