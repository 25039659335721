import loadingTemplate from 'common/templates/loadingGrid.hbs';
import CashPositionListView from 'app/balanceAndTransaction/views/cashPositionListView';
import locale from '@glu/locale';
import constants from 'app/balanceAndTransaction/constants';
import Layout from '@glu/core/src/layout';
import DataTable from 'common/dynamicPages/views/dataTable/dataTable';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import Formatter from 'system/utilities/format';
import RefreshAccountsButton from 'app/balanceAndTransaction/views/refreshAccountsButton';
import Account from 'app/balanceAndTransaction/models/account';
import ScreenManager from '@glu/screen-manager';
import AccountSections from 'app/balanceAndTransaction/collections/accountSections';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import transform from 'common/util/transform';
import services from 'services';
import systemConfig from 'system/configuration';
import { asView } from 'common/util/reactUtil';
import DocumentsViewer from 'components/Carousel/DocumentsViewer';
import CurrencyBadgeHeader from 'components/CurrencyBadges/CurrencyBadgeHeader';
import cashPositionSummaryTmpl from './cashPositionSummary.hbs';

const CashPositionSummary = Layout.extend({
    template: cashPositionSummaryTmpl,
    loadingTemplate,
    className: 'balance-and-transaction-reporting-account-summary',

    id: 'showCashPositionTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
    },


    initialize(options) {
        this.model = options.model || new Model();
        this.ariaLabelledBy = options.ariaLabelledBy;
        this.refreshAccountsButton = new RefreshAccountsButton({
            action: this.setupSummarySection.bind(this),
        });

        this.listenTo(this.appBus, 'accountSummary:refreshAccountAccess', this.onRefreshAccountAccess);

        if (options.widget) {
            this.setWidget(options.widget);
        }
        this.tabId = options.tabId;
        this.urlTab = options.urlTab;
        this.id = options.id;
        this.entitlements = store.get('btr:entitlements');
        this.BTRShowTotalsForAllAccounts = serverConfigParams.get('BTRShowTotalsForAllAccounts') === 'true';
        let balancesTimeout = parseInt(serverConfigParams.get('realTimeBalancesDataCacheTimeout'), 10);
        let transactionsTimeout = parseInt(serverConfigParams.get('realTimeTransactionsDataCacheTimeout'), 10);
        this.refreshTimeout = Math.max(
            !util.isNaN(balancesTimeout) ? balancesTimeout : 0,
            !util.isNaN(transactionsTimeout) ? transactionsTimeout : 0,
        ) * 1000;
        balancesTimeout = parseInt(serverConfigParams.get('realTimeBalancesRequestTimeout'), 10);
        transactionsTimeout = parseInt(serverConfigParams.get('realTimeTransactionsRequestTimeout'), 10);
        this.requestTimeout = Math.max(
            !util.isNaN(balancesTimeout) ? balancesTimeout : 0,
            !util.isNaN(transactionsTimeout) ? transactionsTimeout : 0,
        );
    },

    events: {
        'click [data-hook="refresh-button"]': 'updateRefreshTimestamp',
    },

    /*
     * Explicitly added the regions hash because it is used
     * farther up the inheritance chain, and you can't mix and match.
     */
    regions: {
        depositAccounts: '[data-region="depositAccounts"]',
        balanceSummaryRegion: '[data-region="balanceSummaryRegion"]',
        transactionSummaryRegion: '[data-region="transactionSummaryRegion"]',
        cashPositionCarouselRegion: '[data-region="cashPositionCarouselRegion"]',
        alerts: '.alerts',
        currencyBadgeRegion: '[data-region="currencyBadgeRegion"]',
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.loadGrid('DEPOSITACCTS');

            if (!this.BTRShowTotalsForAllAccounts) {
                this.addRefreshButton();
            }

            if (ScreenManager.isSmall() || ScreenManager.isExtraSmall()) {
                this.hideAllPanels();
            }
            this.$el.attr('aria-labelledby', this.ariaLabelledBy);
            this.trigger('afterRender');
        } else {
            this.loadRequiredData();
        }
    },

    /*
     * @method: loadRequiredData
     * Method to load requuired data before rendering the view.
     */
    loadRequiredData() {
        this.getAccountSections();
    },

    /*
     *  @method: setWidget
     *  @param: {View} widget
     *  Method to instantiate listeners to connect grid to widget changes
     */
    setWidget(widget) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    /*
     *  @method: handleWidgetSizeChange
     *  Listener to resize the grid when the widget is resized
     */
    handleWidgetSizeChange() {
        // targeted views
        const targetedViews = [
            this.accountsGridRegionDEPOSITACCTS
                ? this.accountsGridRegionDEPOSITACCTS.currentView : false,
            this.accountsGridRegionLOANACCTS
                ? this.accountsGridRegionLOANACCTS.currentView : false,
        ];
        util.each(targetedViews, (view) => {
            if (view) {
                util.defer(() => {
                    view.gridView.grid.tableView.setWidth();
                });
            }
        });
    },

    /*
     *  @method: getAccountSections
     *  Method to get the available and entitled sections to display for
     *  Balance and Reporting tab.
     */
    getAccountSections() {
        this.accountSectionsCollection = new AccountSections({
            tabId: this.tabId,
        });

        this.accountSectionsCollection.fetch({
            success: util.bind(this.onSuccess, this),
            error: util.bind(this.onError, this),
        });
    },

    updateRefreshTimestamp() {
        // update refresh timestamp
        const timestamp = this.listView.gridView.getRows().lastRefreshTimestamp;
        const $refreshEl = this.$('[data-hook="refresh-time"]');

        if (!util.isNullOrUndefined(timestamp) && $refreshEl && $refreshEl.length > 0) {
            $refreshEl.text(`${Formatter.formatDate(timestamp)} ${Formatter.formatTime(timestamp)}`);
        }
    },

    onSuccess() {
        if (!this.hasLoadedRequiredData()) {
            this.setHasLoadedRequiredData(true);
            this.render();
        }
        this.updateRefreshTimestamp();
    },

    onError() {
    },

    hideAllPanels() {
        // or 'show' or 'toggle'
        this.$('.panel-collapse').collapse('hide');
    },

    addRefreshButton() {
        this.refreshAccountsButton.setElement(this.$('.refresh-balances-button')).render();
    },

    /**
     * provides the requisites to load the deposit grid on BTR Current day tab
     * @method loadGrid - extend
     */
    loadGrid(section) {
        const context = {
            actionMode: constants.SELECT,
            displayOrder: 1,
            functionCode: constants.INST,
            gridId: 0,
            productCode: constants.GIR,
            serviceName: constants.DEPOSIT_ACCTS_SERVICE_PREFIX
                + this.urlTab + constants.ACC_SUMMARY,
            typeCode: constants.BTR_TYPECODES.CURRENT[this.urlTab],
            exportPrintURL: systemConfig.isAdmin()
                ? constants.CASH_POSITION_EXPORT_PRINT_URL
                : constants.ASYNC_CASH_POSITION_EXPORT_PRINT_URL,
            inquiryId: 22250,
        };

        const listView = new CashPositionListView({
            accountType: constants.DEPOSIT,
            title: locale.get('gir.DepositAccounts'),
            amountColumn: constants.ACCT_SUMM_DEPOSIT_ACCTS_AMOUNTCOL,
            availableColumn: constants.ACCT_SUMM_DEPOSIT_ACCTS_AVAILABLECOL,
            field1Label: 'gir.OPENING_LEDGER',
            field2Label: 'gir.CLOSING_LEDGER',
            detailPageUrl: constants.CASH_POSITION_DETAIL_PAGE_URL,
            sectionId: section,
            tabId: this.tabId,
            urlTab: this.urlTab,
            entitlements: this.entitlements,
            context,
        });

        this.listView = listView;

        this.depositAccounts.show(listView);
        this.listenTo(listView.gridView, 'grid:available', this.setupSummarySection);
        this.listenTo(listView.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
    },

    /**
     * Callback method to create the Summary Information Tables that accompany
     * the main grid
     * will update the tables if they are already instantiated
     */
    setupSummarySection() {
        const showSummarySections = (gridFilters) => {
            if (this.tranSummaryTableView && this.balanceSummaryTableView) {
                this.tranSummaryTableView.refreshTableData(gridFilters);
                this.balanceSummaryTableView.refreshTableData(gridFilters);
                this.transactionSummaryRegion.show(this.tranSummaryTableView);
                this.balanceSummaryRegion.show(this.balanceSummaryTableView);
            } else {
                this.showTransactionSummary(gridFilters);
                this.showBalanceSummary(gridFilters);
            }
            const { footerRows } =
                this.listView.gridView.getRows().jsonData;
            this.renderDocumentViewerView(footerRows);
        };

        const realTimeResponseHandler = (success) => {
            const gridFilters = success
                ? this.generateVisibleAccountsFiltersBlock(false)
                : this.listView.gridView.wrapper.generateFiltersDataBlock();
            store.set(
                'cashPositionSummary:accountFilters',
                success ? gridFilters.accountNumbers : gridFilters,
            );
            showSummarySections(gridFilters.accountFilters);
            this.listenTo(this.listView.gridView.getRows(), 'sync', this.setupSummarySection);
            this.listView.gridView.spinner(false);
        };

        this.stopListening(this.listView.gridView.getRows(), 'sync', this.setupSummarySection);
        if (this.BTRShowTotalsForAllAccounts) {
            const gridFilters = this.listView.gridView.wrapper.generateFiltersDataBlock();
            showSummarySections(gridFilters);
            this.listenTo(this.listView.gridView.getRows(), 'sync', this.setupSummarySection);
        } else {
            this.listView.gridView.spinner(true);
            this.refreshAccountsButton.refresh('disabled');
            util.delay(
                this.refreshAccountsButton.refresh.bind(
                    this.refreshAccountsButton,
                    'ready',
                ),
                this.refreshTimeout,
            );
            this.transactionSummaryRegion.show(new Layout({ template: loadingTemplate }));
            this.balanceSummaryRegion.show(new Layout({ template: loadingTemplate }));
            this.listView.gridView.wrapper
                .pushGridRefreshPromise(this.getRealTimeBalancePromise().then((response) => {
                    if (response !== null) {
                        const parsedResponse = this.parseRealTimeData(response);
                        this.updateRealTimeBalances(parsedResponse);
                    }
                    realTimeResponseHandler(true);
                }, (rejection) => {
                    if (rejection.message === 'timeout') {
                        this.setupSummarySection();
                    } else {
                        realTimeResponseHandler(false);
                    }
                }));
        }
    },

    /**
     * This function will create a block of account filters corresponding to the accounts
     * currently in the visible grid.
     *
     * @param {Boolean} realTimeOnly - If true, the block will be created from only the real
     * accounts within the visible grid.
     *
     * @return {Object} - Search Fields block for real time balances and totals requests
     */
    generateVisibleAccountsFiltersBlock(realTimeOnly) {
        const models = realTimeOnly
            ? this.listView.gridView.getRows().filter(model => model.get('ISREALTIMEACCOUNT') === '1')
            : this.listView.gridView.getRows();
        const filterValue = [];
        const numberLabel = [];
        const numberValue = [];
        models.forEach((model) => {
            const accountFilter = model.get('ACCOUNTFILTER');
            const accountFilterParts = accountFilter.split(/-(.+)/);
            filterValue.push(accountFilter);
            numberLabel.push(model.get('ACCOUNTNUMBER')); // Masked value
            numberValue.push(accountFilterParts[1]); // Unmasked value
        });
        return {
            accountFilters: [{
                dataType: 'text',
                fieldName: 'ACCOUNTFILTER',
                fieldValue: filterValue,
                operator: 'IN',
            }],
            accountNumbers: {
                field: 'ACCOUNT_NUM',
                type: 'multistring',
                title: locale.get('gir.advice.tranInfo.accountnumber'),
                label: numberLabel.length > 1 ? `${numberLabel.length} ${locale.get('common.Accounts')}` : numberLabel[0],
                value: numberValue,
            },
        };
    },

    /**
     * This will update the attributes of the account models in the grid based on the provided
     * accounts array
     *
     * @param {Array} updatedAccounts - Updated account data, parsed from real time balance
     * response, and parsed by parseRealTimeData
     */
    updateRealTimeBalances(updatedAccounts) {
        util.each(updatedAccounts, (updatedAccount) => {
            const account = this.listView.gridView.getRows()
                .findWhere({
                    ACCOUNTFILTER: updatedAccount.ACCOUNTFILTER,
                });

            const filteredAttrs = Object
                .keys(updatedAccount)
                .reduce((arr, prop) => ((updatedAccount[prop]) ? Object.assign(arr, {
                    [prop]: updatedAccount[prop],
                }) : arr), {});

            account.set(filteredAttrs);
        }, this);
    },

    /**
     * Parses the response to the call for real time account balances, result to be consumed by
     * updateRealTimeBalances
     *
     * @param {Object} res - Response from real time balances call
     *
     * @return {Array} - Array of parsed data
     */
    parseRealTimeData(res) {
        return util.map(res.rows, (row) => {
            const ret = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            if (ret.ACCOUNT_NAME) {
                ret.ACCOUNT_NAME = ret.ACCOUNT_NAME.replace(/&amp;/g, '&');
            }
            ret.headers = res.rowHeader;
            return ret;
        }, this);
    },

    /**
     * Returns a promise wrapping the call to the real time balances API call, if there are
     * real time accounts in the visible grid. Otherwise, returns a promise which immediately
     * resolves with null
     *
     * @return {Promise} - Resolves with the response if call is successful, null if there is
     * no call to be made, or rejects with the status text if the call fails.
     */
    getRealTimeBalancePromise() {
        const self = this;
        const realTimeInVisibleGrid = this.listView.gridView.getRows().some(model => model.get('ISREALTIMEACCOUNT') === '1');
        return new Promise((resolve, reject) => {
            if (realTimeInVisibleGrid) {
                const service = services.generateUrl(constants
                    .REAL_TIME_TRANSACTION_POLLER_SERVICES
                    .CURRENTDAYCASH[self.urlTab]);
                const data = {
                    searchFields: self.generateVisibleAccountsFiltersBlock(true).accountFilters,
                };
                http.post(
                    service, data, (response) => {
                        resolve(response);
                    }, (response) => {
                        reject(new Error(response.statusText));
                    },
                    { timeout: this.requestTimeout },
                );
            } else {
                resolve(null);
            }
        });
    },

    /**
     * @method setupTransactionSummary
     * @param {object} [gridFilters] - additional filters search param object
     * temporary list view to display current day transaction summary totals.
     * Will be replaced soon by new appropriate component
     */
    showTransactionSummary(gridFilters) {
        const tranSummaryTotalContext = {
            actionMode: constants.SELECT,
            displayOrder: 1,
            functionCode: constants.PRO,
            gridId: 0,
            productCode: constants.GIR,
            serviceName: constants.DEPOSIT_ACCTS_SERVICE_PREFIX
                + this.urlTab + constants.ACCOUNT_TRANSACTION_SUMMARY_SERVICE,
            typeCode: constants.BTR_TYPECODES.CURRENT.SUMMARY,
            detailPageUrl: constants.CASH_POSITION_DETAIL_PAGE_URL,
            inquiryId: 22252,
            filters: gridFilters,

            actionCells: {
                TRANTYPE: this.entitlements
                    .isEntitled(constants.BTR_TYPECODES.CURRENT.TRANSACTIONS)
                    || this.entitlements.isEntitled(constants.GIRTRN_I),
            },
            formatTableData: this.formatTableData.bind(this),
        };

        this.tranSummaryTableView = new DataTable(tranSummaryTotalContext);
        this.listenTo(this.tranSummaryTableView, 'table:row:action:select', this.transactionTableRowAction);
        this.transactionSummaryRegion.show(this.tranSummaryTableView);
    },

    /**
     * @method setupBalanceSummaryTotals
     * @param {object} [gridFilters] - additional filters search param object
     * Creates and mounts the view needed to show overall cash position
     */
    showBalanceSummary(gridFilters) {
        const context = {
            actionMode: constants.SELECT,
            displayOrder: 1,
            serviceFunc: null,
            businessType: null,
            selector: constants.NONE,
            functionCode: constants.INST,
            gridId: 0,
            productCode: constants.GIR,
            serviceName: constants.DEPOSIT_ACCTS_SERVICE_PREFIX
                + this.urlTab + constants.ACCOUNT_BALANCE_SUMMARY_SERVICE,
            typeCode: constants.BTR_TYPECODES.CURRENT.SUMMARY,
            filters: gridFilters,
            formatTableData: this.formatTableData.bind(this),
        };

        this.balanceSummaryTableView = new DataTable(context);
        this.balanceSummaryRegion.show(this.balanceSummaryTableView);
    },

    /**
     * @method transactionTableRowAction
     * provides properties for transaction filter to be used while opening the
     * detail page
     */
    transactionTableRowAction(model) {
        const account = new Account({
            baseSearchFields: [...(this.tranSummaryTableView.options.filters || [])],
            accountType: 'DD',
            transactionType: model.get('BAI_GROUP_CODE'),
            tabId: this.tabId,
            urlTab: this.urlTab,
            currencyCode: this.currencyCode,
        });

        store.set('balanceAndTransactionAccount', account);
        this.navigateTo(this.tranSummaryTableView.options.detailPageUrl);
    },

    /**
     * @method handleCurrencyChange
     * @param {string} currencyCode
     * @param {number} count
     */
    handleCurrencyChange({ currencyCode, count }, cb) {
        this.currencyCode = currencyCode;
        const CurrencyBadgesView = asView(CurrencyBadgeHeader);
        this.currencyBadgeRegion.show(new CurrencyBadgesView({
            currencyCode,
            count,
            isRow: true,
        }));
        if (cb) {
            cb();
        }
    },

    renderDocumentViewerView(currencyList) {
        if (this.cashPositionCarouselRegion) {
            const DocumentViewerView = asView(DocumentsViewer);
            const pages = util.map(currencyList, ({ columns }, id) => {
                const currencyCode = util.findWhere(columns, { fieldName: 'FOOTER_KEY' })?.fieldValue;
                const count = util.findWhere(columns, { fieldName: 'FOOTER_KEY_COUNT' })?.fieldValue;
                return {
                    id, pageNumber: id + 1, currencyCode, count: parseInt(count, 10),
                };
            });
            this.cashPositionCarouselRegion.show(new DocumentViewerView({
                pages,
                onActivePageChange: data => this.handleCurrencyChange(data, () => {
                    this.balanceSummaryTableView.render();
                    this.tranSummaryTableView.render();
                }),
            }));
            if (!pages.length) {
                this.handleCurrencyChange({ currencyCode: null });
                return;
            }
            const { currencyCode } = pages[0];
            this.currencyCode = currencyCode;
            this.handleCurrencyChange({
                currencyCode: this.currencyCode,
                count: util.findWhere(pages, { currencyCode: this.currencyCode })?.count,
            });
        }
    },

    formatTableData(collection) {
        const updatedCollection = util.clone(collection);
        updatedCollection.models = updatedCollection.models.filter(model => model.get('CURRENCYCODE') === this.currencyCode);
        return updatedCollection;
    },

    templateHelpers() {
        const self = this;
        return {
            hasRefreshButton: !self.BTRShowTotalsForAllAccounts,
            totalsWarning: !self.BTRShowTotalsForAllAccounts && locale.get('balanceTrans.account.totals.notice'),
        };
    },
});

export default CashPositionSummary;
