import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ValidatedInput } from '@glu/form-components';

const propTypes = {
    editing: PropTypes.bool,
    column: PropTypes.shape({
        get: PropTypes.func.isRequired,
    }).isRequired,
    model: PropTypes.shape({
        get: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    editing: true,
    onChange: () => {},
    onBlur: () => {},
    className: '',
};

const ValidatedEditableTextCell = ({
    editing,
    column,
    model,
    onChange,
    onBlur,
    className,
    ...props
}) => {
    const name = column.get('fieldName');
    const value = model.get(name);
    return (
        <>
            {editing
                ? (
                    <>
                        <ValidatedInput
                            label=""
                            className={className}
                            name={name}
                            onChange={onChange}
                            onBlur={onBlur}
                            {...props}
                        />
                    </>
                )
                : <span>{value}</span>}
        </>
    );
};

ValidatedEditableTextCell.propTypes = propTypes;
ValidatedEditableTextCell.defaultProps = defaultProps;

export default ValidatedEditableTextCell;
