var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a data-attr=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":28,"column":30},"end":{"line":28,"column":39}}}) : helper)))
    + "\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":48},"end":{"line":28,"column":75}}})) != null ? stack1 : "")
    + "\" href=\"#\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":86},"end":{"line":28,"column":94}}}) : helper)))
    + "</a> "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":99},"end":{"line":28,"column":128}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    return "|";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":181}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.TransactionDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":66}}}))
    + " - "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.Lockbox",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":69},"end":{"line":5,"column":93}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"getLockboxCode") || (depth0 != null ? lookupProperty(depth0,"getLockboxCode") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getLockboxCode","hash":{},"data":data,"loc":{"start":{"line":5,"column":94},"end":{"line":5,"column":112}}}) : helper)))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h3>Between "
    + alias2(((helper = (helper = lookupProperty(helpers,"getReportDate") || (depth0 != null ? lookupProperty(depth0,"getReportDate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getReportDate","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":37}}}) : helper)))
    + "</h3>\n        <div class=\"lbxViewType\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":19},"end":{"line":12,"column":40}}}))
    + "</label>\n            <div class=\"btn-group view-switcher\">\n                <button id=\"lbxListView\" type=\"button\" class=\"btn btn-secondary active\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.List",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":88},"end":{"line":14,"column":112}}}))
    + "</button>\n                <button id=\"lbxGroupView\" type=\"button\" class=\"btn btn-secondary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.Group",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":82},"end":{"line":15,"column":107}}}))
    + "</button>\n            </div>\n        </div>\n\n    </div>\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-wrapper\">\n            "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":55}}})) != null ? stack1 : "")
    + "\n            </div>\n        <div class=\"viewControls lbxTransactionTypes\">\n            <strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.Show",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":44}}}))
    + ": </strong>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"transactionTypes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":29,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"LockBox-report-details\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n\n";
},"useData":true});