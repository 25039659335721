import Population from 'app/challengeManager/collections/population';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';
import CompanyModel from 'app/challengeManager/models/companies';

const Companies = Population.extend({
    comparator: 'primaryKey',
    model: CompanyModel,

    parse(response) {
        return response.rows.map((row) => {
            const company = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            company.primaryKey = `${company.USERGROUP}-${company.GROUPNAME.replace(/[^a-z0-9]/gi, '')}`;
            company.totalRows = response.totalRows;
            company.totalPages = response.totalPages;
            company.type = 'COMPANIES';
            return company;
        });
    },

    sync(method, model, options) {
        const url = services.generateUrl('/challengeManager/company/getListView');

        if (this.fieldValue) {
            this.data.searchFields = [{
                fieldName: 'USERGROUP',
                operator: 'IN',
                fieldValue: this.fieldValue,
                dataType: 'text',
            }];
        }

        const searchValue = this.getSearchCriteria();

        if (searchValue && searchValue.length > 0) {
            this.data.searchFields = [{
                fieldName: 'USERGROUP_GROUPNAME',
                operator: 'CONTAINS',
                fieldValue: this.getSearchCriteria(),
                dataType: 'text',
            }];
        }
        http.post(url, this.data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});

export default Companies;
