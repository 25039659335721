import CorpPassThroughListView from 'app/payments/views/passThrough/passthroughListView';
import store from 'system/utilities/cache';
import AdminDataApi from 'app/administration/common/dynamicPages/api/data';

export default CorpPassThroughListView.extend({
    gridRowAction(row) {
        const self = this;
        return new Promise((resolve) => {
            if (!self.isModal) {
                const keyListGlobal = ['TNUM', 'UPDATECOUNT__'];
                const model = AdminDataApi.model.generateFromKeyList({
                    context: {
                        serviceName: 'adminPayment/listView/payments',
                        subType: 'NACHA',

                        actionData: {
                            entryClass: row.model.get('ENTRYCLASS'),
                            entrymethod: '3',
                        },
                    },

                    keyList: keyListGlobal,
                });
                model.set('TNUM', row.model.get('TNUM'));
                model.set('PRODUCT', 'USACH');
                model.set('FUNCTION', 'BATCH');
                model.set('TYPE', 'PASSTHRU');
                model.set('SUBTYPE', 'NACHA');
                model.set('UPDATECOUNT__', row.model.get('UPDATECOUNT__'));
                model.fetch({
                    success(m) {
                        /*
                         * set context actionData because admin rest service needs them to
                         * get the correct model info
                         */
                        const { actionData } = m.context;
                        actionData.productCode = m.get('PRODUCT');
                        actionData.functionCode = m.get('FUNCTION');
                        actionData.typeCode = m.get('TYPE');
                        self.model = m;
                        self.storedModel = m;
                        store.set('adminPayment_listView_payments-actionModel', self.storedModel);
                        self.navigateTo('PAYMENTS/passThroughViewPayment');
                        resolve({ model: m });
                    },
                });
            } else {
                resolve();
            }
        });
    },
});
