import services from 'services';
import { postData } from 'common/util/services';
import transform from 'common/util/transform';
import locale from '@glu/locale';

let data = {};

/**
 * Get the data value based on the route or window.location.pathname
 * @param {string} prop name of the property to access in the data object
 * @returns {string}
 */
const getDataValue = (route, prop) => data[route || window.location.pathname]?.[prop] || '';

/**
 * @param {string} route route to use to lookup the page title
 */
export const getPageTitle = (route) => {
    const pageTitle = getDataValue(route, 'pageTitle');
    return pageTitle === '' ? pageTitle : locale.get(pageTitle);
};

/**
 * @param {string} route route to use to lookup the friendly name
 */
export const getAdobeAnalyticsFriendlyName = route => getDataValue(route, 'adobeAnalyticsFriendlyName');

/**
 * Get the page title data, map it, and set the local data object
 * @returns {Promise}
 */
export const getPageTitleData = () => {
    const payload = {
        inquiryRequest: {
            searchCriteria: {
                searchType: 5,
                inquiryId: 72315,
            },
        },
    };
    return postData(services.inquiryData, payload).then((resp) => {
        data = resp.inquiryResponse.rows.reduce((accum, row) => {
            const mapped = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            return {
                ...accum,
                [mapped.DBIQ_URL]: {
                    pageTitle: mapped.PAGE_TITLE,
                    adobeAnalyticsFriendlyName: mapped.ADOBE_FRIENDLY_NAME,
                },
            };
        }, {});
    });
};
