import $ from 'jquery';
import locale from '@glu/locale';

export default function (form) {
    const newPassword = form.field('newPassword');
    const confirmPassword = form.field('confirmPassword');

    const updateValidationMessage = function () {
        const confirmPasswordMsg = $('span.confirmPassword');
        const newPasswordMsg = $('span.newPassword');
        const cmString = confirmPasswordMsg.text();
        const nwString = newPasswordMsg.text();
        const cmMessage = cmString.replace('newPassword', locale.get('prompt.newPassword'));
        const nwMessage = nwString.replace('confirmPassword', locale.get('prompt.confirmPassword'));

        confirmPasswordMsg.text(cmMessage);
        newPasswordMsg.text(nwMessage);
    };

    newPassword.$el.on('blur', () => {
        updateValidationMessage();
    });
    confirmPassword.$el.on('blur', () => {
        updateValidationMessage();
    });
}
