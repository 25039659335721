import Controller from '@glu/core/src/controller';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';
import services from 'services';

export default Controller.extend({
    initialize() {
        this.request = this.createDefault();
        this.url = services.generateUrl('/payment/quickEntry/getLookupResults');
    },

    setSearch(str) {
        this.request.searchFields[0].fieldValue = [str];
        this.request.searchFields[1].fieldValue = [str];
        this.request.searchFields[2].fieldValue = [str];
    },

    setPageSize(rowsPerPage) {
        this.rowsPerPage = (rowsPerPage > constants.MAX_SERVER_ROWSPERPAGE)
            ? constants.MAX_SERVER_ROWSPERPAGE : rowsPerPage;
    },

    createDefault() {
        return {
            entryMethod: 0,
            fieldName: 'TEMPLATE',
            searchOrOperator: true,
            depends: [],
            subType: '*',
            typeCode: '*',
            action: 'INSERT',
            functionCode: 'INST',
            productCode: 'PAY',

            requestParameters: {
                item: [{
                    name: 'INQUIRYID',
                    value: '20497',
                }],
            },

            startRow: 1,
            rowsPerPage: constants.MAX_SERVER_ROWSPERPAGE,

            searchFields: [{
                fieldValue: [],
                dataType: 'text',
                fieldName: 'CMB_Template_Code',
                operator: 'CONTAINS',
            }, {
                fieldValue: [],
                dataType: 'text',
                fieldName: 'CMB_Bene_Name',
                operator: 'CONTAINS',
            }, {
                fieldValue: [],
                dataType: 'text',
                fieldName: 'CMB_Type_Description',
                operator: 'CONTAINS',
            }],
        };
    },

    getRequest() {
        return this.request;
    },

    send() {
        /*
         * use then() to ensure that the parsed response is passed to the next handler
         * see the note in quickEntry/listView.js about moving this all to a collection
         */
        return http.post(this.url, this.getRequest())
            .then(this.parseResponse, this.handleError);
    },

    parseResponse(response) {
        return util.map(response.rows, (rowItem) => {
            const obj = transform.pairsToHash(rowItem.mapDataList, 'toField', 'value');

            obj.isTemplateGroup = (obj.isTemplateGroup === '1');
            obj.id = obj.isTemplateGroup ? obj.templateSet : obj.createFromExistingTmpl;
            const typeDescription = obj.paymentType;
            const bene = obj.beneName;
            obj.text = `${obj.templateSet} • ${util.unescape(bene)} • ${util.unescape(typeDescription)}`;

            return obj;
        });
    },

    handleError() {},
});
