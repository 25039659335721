import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import paymentGroupTmpl from './paymentGroup.hbs';
import { updateSelectAllState, onChildrenChange } from '../../helpers';

const GROUP_COLUMNS = 3;

export default ItemView.extend({
    tagName: 'div',
    template: paymentGroupTmpl,

    ui: {
        $selectAllTypes: '.select-all-types',
        selectType: '[data-hook="type-checkbox"]',
        selectAll: '[data-hook="type-selectAll"]',
    },

    events: {
        'click @ui.$selectAllTypes': 'selectAllTypes',
        'click @ui.selectType': 'selectType',
    },

    initialize(opts) {
        this.collection = this.model.get('types');
        this.readOnly = opts.readOnly;
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        if (!this.readOnly && this.allTypesSelected()) {
            this.ui.selectAll.prop('checked', true);
        }
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList(), this.ui.selectAll);
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el.find('.paymentType-block input[type="checkbox"]');
    },

    allTypesSelected() {
        let allSelected = true;

        util.each(this.$('[data-hook="type-checkbox"]'), function (input) {
            if (this.$(input).prop('checked') === false) {
                allSelected = false;
            }
        }, this);
        return allSelected;
    },

    // Manually data bind
    selectType(evt) {
        this.collection.get(this.$(evt.target).data('id')).set('entitled', evt.target.checked);
        this.model.collection.trigger('types-changed');
    },

    selectAllTypes(evt) {
        const self = this;
        const { checked } = evt.currentTarget;

        self.ui.selectType.prop('checked', checked);

        this.model.get('types').each((rec) => {
            rec.set('entitled', checked);
        });

        this.model.collection.trigger('types-changed');
    },

    templateHelpers() {
        return {
            readOnly: this.readOnly,
            cid: this.model.cid,
            label: locale.get(`administration.${this.model.get('label')}`),

            // value() ends a chain()
            rows: this.collection.chain()
                .map(rec => util.extend(
                    rec.toJSON(),
                    {
                        cid: rec.cid,
                    },
                ))
                .groupBy((rec, i) => Math.floor(i / GROUP_COLUMNS))
                .values() // values() extracts values from an object
                .value(),
        };
    },
});
