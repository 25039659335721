import ViewPositivePay from 'app/checkManagement/views/viewPositivePayDecision';
import store from 'system/utilities/cache';
import cmconstants from 'app/checkManagement/constants';

export default ViewPositivePay.extend({
    /** @override */
    setModel() {
        this.model = store.get(`adminCM_fraud_listView_positivePayDecision${cmconstants.ACTIONMODEL}`);
    },

    loadRequiredData() {
        this.loadRequiredFCData(cmconstants.CM, cmconstants.POS_PAY_TYPE);
    },

    cancel() {
        this.disableButtons();
        this.navigateTo(this.getListUrl());
    },
});
