import viewTemplate from 'app/reports/views/contentView.hbs';
import constants from 'app/reports/constants';
import ReportStatus from 'app/reports/models/reportStatus';
import ContentView from 'app/reports/views/contentView';
import Layout from '@glu/core/src/layout';
import http from '@glu/core/src/http';
import Dialog from '@glu/dialog';
import Poll from '@glu/polling';
import alert from '@glu/alerts';
import $ from 'jquery';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import ContextApi from 'common/dynamicPages/api/context';
import services from 'services';
import { asView } from 'common/util/reactUtil';
import AsyncPrintViewModal from 'components/AsyncReporting/AsyncPrintViewModal';
import AsyncReportContentView from 'app/reports/views/asyncReportContentView';
import helpers from 'components/AsyncReporting/helpers';
import systemConfig from 'system/configuration';
import reportViewTmpl from './printTransactionAdviceDetails.hbs';

const PrintTransactionAdviceDetails = Layout.extend({
    template: reportViewTmpl,
    className: 'modal',

    regions: {
        alertRegion: '#alertRegion',
    },

    ui: {
        $heading: '.panel-heading',
        $modal: '.modal-dialog',
    },

    events: {
        'click @ui.$heading': 'togglePanel',
    },

    initialize(options) {
        this.model = options.model;

        this.createPoller();

        this.listenTo(this.appBus, constants.REPORT_READY_EVENT, this.renderReport);
        this.listenTo(this.appBus, constants.REPORT_RUNNING_EVENT, this.renderStatus);
        this.listenTo(this.appBus, constants.REPORT_ERROR_EVENT, this.showError);
        this.listenTo(this.appBus, constants.REPORT_TIMEOUT_EVENT, this.showError);

        this.contextDef = this.options.contextDef
            || ContextApi.menuContext.getContext(this.options.context);
        this.mode = 'insert';
    },

    onClose() {
        this.poller.dispose();
        /**
         * NH-133418 In the Credit Advice report, the close button on this modal was leaving
         * an empty modal body blocking user interaction.
         */
        if (Dialog.visible()) {
            Dialog.close();
        }
    },

    onRender() {
        if (this.model.get(constants.FILTERID_ATTR)) {
            this.retrieveFilter();
        } else if (systemConfig.isAdmin()) {
            this.run();
        } else {
            this.handleAsyncJasperReport({
                service: services.runAsyncJasperReport,
                postData: this.getPayload(),
            });
        }
    },

    /**
     * Handles showing async Jasper reports
     * @param {object} options
     */
    handleAsyncJasperReport(options) {
        this.$el.find('.modal-footer').hide();
        this.ui.$modal.removeClass('print-modal modal-2xlg');
        const AsyncPrintView = asView(AsyncPrintViewModal);
        const AsyncPrintViewModalView = new AsyncPrintView({
            onCancel: () => this.close(),
            onReportComplete: result => this.renderAsyncReport(result),
            onReportIncomplete: () => helpers.setupAsyncReportPoller(),
            ...options,
        });
        this.content.show(AsyncPrintViewModalView);
        this.ui.$modal.find('.modal-title').text(locale.get('async.btr'));
    },

    /**
     * Handles generating reports payload
     * @return {object}
     */
    getPayload() {
        return {
            productCode: this.model.get('productCode'),
            reportId: this.model.get('reportId'),
            viewId: this.model.get('viewId'),
            filterData: this.model.get('filterData'),
            additionalParameters: this.model.get('additionalParameters'),
            includeSummaryInfo: this.model.get('includeSummaryInfo'),
        };
    },

    templateHelpers() {
        const self = this;

        return {
            hasFilters() {
                return (!!self.model.get(constants.FILTERID_ATTR));
            },
        };
    },

    togglePanel(event) {
        const $target = $(event.currentTarget);

        $target.find('span[class^=icon-]').first().toggleClass('icon-caret-down icon-arrow-right');
    },

    createPoller() {
        this.reportStatus = new ReportStatus({
            targetId: -1,
        });
        const self = this;
        const count = serverConfigParams.get('tranSearchReportPollLimit') !== undefined ? serverConfigParams.get('tranSearchReportPollLimit') : 24;
        const options = {
            iterator(poll) {
                this.reportStatus.fetch({
                    success(model) {
                        if (model.get('STATUS') === 'DN') {
                            self.appBus.trigger(constants.REPORT_READY_EVENT, model);
                        } else if (model.get('STATUS') === 'RJ') {
                            self.appBus.trigger(
                                constants.REPORT_ERROR_EVENT,
                                constants.ERROR_MESSAGE,
                            );
                        } else {
                            self.appBus.trigger(
                                constants.REPORT_RUNNING_EVENT,
                                model,
                            );
                            poll();
                        }
                    },

                    error() {
                        self.appBus.trigger(
                            constants.REPORT_ERROR_EVENT,
                            constants.STATUS_RETRIEVAL_ERROR_MESSAGE,
                        );
                    },
                });
            },

            limit: count,

            onLimit() {
                self.appBus.trigger(constants.REPORT_TIMEOUT_EVENT, constants.TIMEOUT_MESSAGE);
            },

            interval: 5000,
        };

        this.poller = new Poll(options, this);
    },

    run() {
        const self = this;

        $(this.alertRegion.$el).fadeIn(100);
        this.content.close();

        this.runReport((result) => {
            self.reportStatus.targetId = result.targetId;
            self.poller.start();
        }, () => {
            self.appBus.trigger(
                constants.REPORT_ERROR_EVENT,
                constants.RUN_REQUEST_ERROR_MESSAGE,
            );
        });
    },

    runReport(success, failure) {
        const url = services.runReport;
        http.post(url, this.getPayload(), success, failure);
        const message = alert.info(locale.get(constants.STATUS_MESSAGE));
        this.alertRegion.show(message);
    },

    renderReport(result) {
        this.filledReport = result;

        this.content.show(new ContentView({
            model: result,
            template: viewTemplate,
            reportId: result.get('SEQNO'),
            target: 'pdfContent',
            disposition: 'view',
            exportFormat: 'PDF',
            type: 'jasper',
            formId: 'viewPDF',
        }));
        $(this.alertRegion.$el).fadeOut(300);

        // remove height from iframe
        this.$el.find('iframe').removeAttr('style');
    },

    /**
     * Renders async report pdf file when report generation finishes in time.
     * @param {object} result
     */
    renderAsyncReport(result) {
        this.ui.$modal.addClass('print-modal modal-2xlg');
        this.content.show(new AsyncReportContentView({
            hasDetailReport: this.hasDetailReport,
            ...result,
        }));
    },

    showError(result) {
        const message = alert.danger(locale.get(result));
        this.alertRegion.show(message);
    },

    cancel() {
        Dialog.close();
    },

    getCacheName() {
        return 'model-advanceFilter';
    },

    renderStatus() {
        const txt = $(this.alertRegion.$el).text();
        const message = alert.info(`${txt}.`);
        this.alertRegion.show(message);
    },
});

export default PrintTransactionAdviceDetails;
