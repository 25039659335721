var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notSimulation") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":22,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.checkfree.new",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":42}}}))
    + "\n            <a href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"checkFreeRegistrationUrl") || (depth0 != null ? lookupProperty(depth0,"checkFreeRegistrationUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"checkFreeRegistrationUrl","hash":{},"data":data,"loc":{"start":{"line":21,"column":21},"end":{"line":21,"column":51}}}) : helper)))
    + "\" class=\"btn btn-tertiary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.register",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":78},"end":{"line":21,"column":107}}}))
    + "</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.checkfree.new",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":42}}}))
    + "\n            <button class=\"btn btn-tertiary btn-sm\" data-hook=\"getRegisterBtn\" type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.register",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":93},"end":{"line":25,"column":122}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-region\"></div>\n<div class=\"form-group required\">\n    <label for=\"ss\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.subscriberID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":55}}}))
    + "</label>\n    <input type=\"text\" name=\"ss\" id=\"ss\" data-bind=\"model\" class=\"form-control\"/>\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"ss\"></span>\n</div>\n<div class=\"form-group required\">\n    <label for=\"pw\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.password",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":50}}}))
    + "</label>\n    <input type=\"password\" name=\"pw\" id=\"pw\" data-bind=\"model\" class=\"form-control\" maxlength=\"8\"/>\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"pw\"></span>\n</div>\n<div class=\"form-group required\">\n    <label for=\"confirm\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.confirmPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":25},"end":{"line":13,"column":63}}}))
    + "</label>\n    <input type=\"password\" name=\"confirm\" id=\"confirm\" data-bind=\"model\" class=\"form-control\" maxlength=\"8\"/>\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"confirm\"></span>\n</div>\n<p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"checkFreeRegistrationUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "</p> \n<div class=\"form-group\">\n    <button class=\"btn btn-primary\" data-hook=\"getSubmitBtn\" type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":75},"end":{"line":29,"column":99}}}))
    + "</button>\n    <button class=\"btn btn-default\" data-hook=\"getCancelBtn\" type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":75},"end":{"line":30,"column":101}}}))
    + "</button>\n    <button class=\"btn btn-tertiary hidden\" data-hook=\"getDeleteBtn\" type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":83},"end":{"line":31,"column":110}}}))
    + "</button>\n</div>\n";
},"useData":true});