import { util } from '@glu/core';
import SimpleCell from './simpleCell';

export default SimpleCell.extend({

    /**
     * @param {Object} data
     * @returns {string}
     */
    template(data) {
        if (data.url) {
            return `<a href="${data.url}"><span>${data.value || ''}</span></a>`;
        }
        return data.value;
    },

    /**
     * Extracted field logic from row.js into a real template.
     * @returns {{value: *, url: *}}
     */
    templateHelpers() {
        const urlProperty = this.column.get('url');
        const url = util.isFunction(urlProperty)
            ? urlProperty(this.model, this.column) : urlProperty;
        return {
            url,
            value: this.options.value,
        };
    },
});
