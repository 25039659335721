import { appBus } from '@glu/core';
import constants from 'common/dynamicPages/api/constants';
import $ from 'jquery';
import patterns from 'system/validatorPatterns';
import util from '@glu/core/src/util';
import fiConstants from 'common/policies/setup/constants';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import userInfo from 'etc/userInfo';

export default function (form, initialState) {
    const formState = form.formView.state;
    const aFieldInFilePropertiesSection = form.field('STARTIMPORTATROW');
    const aFieldInDataFormatDetailsSection = form.field('DATEFORMAT');
    const aFieldInSearchPatternSection = form.field('SEARCHPATTERN1');
    const fieldInChildSupportAgencySection = form.field('CSAGENCY');
    const fieldInTaxAuthSection = form.field('TAXAUTH');
    const fieldInAddendaFormatSection = form.field('ADDENDAFORMAT');
    const fieldImportFormatName = form.field('IMPORTFORMATNAME');

    // gridSection = form.field('CHILDGRID'),
    const { model } = form.formView;

    let productCode = model.get('PRODUCTCODE');
    let paymentFunction = model.get('FUNCTIONCODE');
    let paymentTypeCode = model.get('PAYMENTTYPECODE');
    let subType = model.get('SUBTYPE');
    let importFileType = model.get('IMPORTFILETYPE');
    const allowStopsInIssueVoidFile = applicationConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE');

    if (initialState) {
        const viewMode = formState.toUpperCase() === 'VIEW';
        const modifyMode = formState.toUpperCase() === 'MODIFY';
        const insertMode = formState.toUpperCase() === 'INSERT';

        form.field('PAYMENTTYPE').shouldBeHidden();

        if (insertMode) {
            model.set('OWNER', 'Client');
        }
        if (!model.get('USERGROUP')) {
            model.set('USERGROUP', userInfo.get('group'));
        }

        if (insertMode || modifyMode) {
            // labels should still take up space
            $('label[for=TEXTQUALIFER], label[for=FIELDDELIMITER], label[for=RECORDDELIMITER]').css('visibility', 'hidden');
        }

        const hideGrid = function () {
            appBus.trigger('mdf:fileImportMap:hideGrid');
        };

        const showGrid = function () {
            appBus.trigger('mdf:fileImportMap:showGrid');
        };

        const setNotMandatory = function (id) {
            if (id) {
                form.field(id).shouldBeOptional();
            }
            if (id && form.field(id).$el && form.field(id).$el.length === 1) {
                form.field(id).$el.first().parent().removeClass('required');
            }
        };

        const setMandatory = function (id, condition) {
            let bMandatory = false;
            if (condition === undefined) {
                bMandatory = true;
            } else {
                bMandatory = condition;
            }
            if (bMandatory) {
                if (id) {
                    form.field(id).shouldBeRequired();
                }
                if (id && form.field(id).$el && form.field(id).$el.length === 1) {
                    form.field(id).$el.first().parent().addClass('required');
                }
            } else {
                setNotMandatory(id);
            }
        };

        const hideAllSections = function () {
            aFieldInFilePropertiesSection.$el.closest('.section').hide();
            aFieldInDataFormatDetailsSection.$el.closest('.section').hide();
            fieldInChildSupportAgencySection.$el.closest('.section').hide();
            fieldInTaxAuthSection.$el.closest('.section').hide();
            fieldInAddendaFormatSection.$el.closest('.section').hide();
            aFieldInSearchPatternSection.$el.closest('.section').hide();
        };

        const setDefaultValues = function () {
            const DEFAULTS = {
                STARTIMPORTATROW: '1',
                CREDITVALUE: 'CR',
                DEBITVALUE: 'DR',
                CHECKINGVALUE: 'C',
                ISSUECODE: 'I',
                VOIDCODE: 'V',
                STOPCODE: 'S',
                CANCELCODE: 'C',
                CHECKCODE: 'CHK',
                PADCODE: 'PAD',
                SAVINGSVALUE: 'S',
                GENERALLEDGERVALUE: 'GL',
                LOANACCOUNTVALUE: 'CL',
                HEADERRECORDCODE: 'H',
                DETAILRECORDCODE: 'D',
                TRAILERRECORDCODE: 'T',
                EUROSEPACODE: 'EURSEPA',
                EUROURGCODE: 'EURURG',
                TRUEVALUE: 'Y',
                FALSEVALUE: 'N',
            };

            let setData = {
                FUNCTIONCODE: paymentFunction,
                SUBTYPE: (!subType ? '*' : subType),
            };

            // Add defaults for visible fields to the setData
            setData = util.reduce(DEFAULTS, (acc, value, key) => {
                if (form.field(key).isVisible()) {
                    acc[key] = value;
                }
                return acc;
            }, setData);

            model.set(setData);
        };

        const initFieldValuesInsert = function () {
            const setData = {
                IMPORTFORMATNAME: '',
                IMPORTFORMATDESCRIPTION: '',
                MIXEDBATCHESYN: ' ',
            };

            if (importFileType === 'NACHA') {
                // File Properties Section
                util.extend(
                    setData,
                    {
                        FILEPROCESS: 'Match and Update',
                        MATCHFAILURE: 'Append to Batch',
                        TRUEVALUE: 'Y',
                        FALSEVALUE: 'N',
                        FUNCTIONCODE: paymentFunction,
                    },
                );
            } else if (importFileType === 'Delimited' || importFileType === 'Fixed') {
                if (!util.contains(fiConstants.RTGS_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.REMITTER_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.CM_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.UK_SUBTYES, subType)) {
                    util.extend(
                        setData,
                        {
                            FILEPROCESS: 'Match and Update',
                            MATCHFAILURE: 'Append to Batch',
                            RECORDDELIMITERVALUE: constants.CR_LF,
                        },
                    );
                }
                if (importFileType === 'Delimited') {
                    util.extend(
                        setData,
                        {
                            TEXTQUALIFER: constants.DOUBLE_QUOTE_ASCII,
                            TEXTQUALIFERVALUE: constants.DOUBLE_QUOTE_ASCII,
                            FIELDDELIMITER: constants.COMMA_ASCII,
                            FIELDDELIMITERVALUE: constants.COMMA_ASCII,
                        },
                    );
                }
                if ((util.contains(fiConstants.CM_TYPES, paymentTypeCode)
                    || util.contains(fiConstants.REMITTER_TYPES, paymentTypeCode))
                    && !util.contains(fiConstants.UK_SUBTYES, subType)) {
                    setData.FILEPROCESS = 'Append All';
                    setData.MATCHFAILURE = ' ';
                }

                if ((paymentTypeCode === 'WIRTMPIM')) {
                    setData.FILEPROCESS = 'Append All';
                    setData.MATCHFAILURE = ' ';
                }

                // Data Format Details Section
                if (subType !== 'UKBACS' && subType !== 'UKFP') {
                    setData.DATEFORMAT = 'MMDDYY';
                }
                setDefaultValues();
            }

            model.set(setData);
        };

        const setValuesFieldsModify = function () {
            if (importFileType === 'Delimited') {
                model.set('TEXTQUALIFERVALUE', model.get('TEXTQUALIFER'));
                if (model.get('FIELDDELIMITER') === model.get('OTHERFIELDDELIMITERVALUE')) {
                    model.set('FIELDDELIMITERVALUE', 'Other');
                    /*
                     * set FIELDDELIMITER again, since the previous set on FIELDDELIMITERVALUE
                     * mapped the text 'Other' from the popupmapdata
                     */
                    model.set('FIELDDELIMITER', model.get('OTHERFIELDDELIMITERVALUE'));
                } else {
                    model.set('FIELDDELIMITERVALUE', model.get('FIELDDELIMITER'));
                }
                if (model.get('RECORDDELIMITER') === model.get('OTHERRECORDDELIMITERVALUE')) {
                    model.set('RECORDDELIMITERVALUE', 'Other');
                    /*
                     * set RECORDDELIMITER again, since the previous set on RECORDDELIMITERVALUE
                     * mapped the text 'Other' from the popupmapdata
                     */
                    model.set('RECORDDELIMITER', model.get('OTHERRECORDDELIMITERVALUE'));
                } else {
                    model.set('RECORDDELIMITERVALUE', model.get('RECORDDELIMITER'));
                }
            }
        };

        const hideSelectedFields = function () {
            // initially hide the filelds
            if (!insertMode && !modifyMode) {
                form.formView.$('[id="FILEPROCESS"]').hide();
                form.formView.$('[id="MATCHFAILURE"]').hide();
                form.formView.$('[id="RECORDDELIMITER"]').hide();
                form.formView.$('[id="RECORDDELIMITERVALUE"]').hide();
                form.formView.$('[id="OTHERRECORDDELIMITERVALUE"]').hide();
                form.formView.$('[id="OTHERFIELDDELIMITERVALUE"]').hide();
                form.formView.$('[id="TEXTQUALIFER"]').hide();
                form.formView.$('[id="TEXTQUALIFERVALUE"]').hide();
                form.formView.$('[id="ISSUECODE"]').hide();
                form.formView.$('[id="VOIDCODE"]').hide();
                form.formView.$('[id="STOPCODE"]').hide();
                form.formView.$('[id="CANCELCODE"]').hide();
                form.formView.$('[id="CHECKCODE"]').hide();
                form.formView.$('[id="PADCODE"]').hide();

                form.formView.$('[id="CREDITVALUE"]').hide();
                form.formView.$('[id="CHECKINGVALUE"]').hide();
                form.formView.$('[id="DEBITVALUE"]').hide();
                form.formView.$('[id="SAVINGSVALUE"]').hide();
                form.formView.$('[id="TRUEVALUE"]').hide();
                form.formView.$('[id="FALSEVALUE"]').hide();
                form.formView.$('[id="LOANACCOUNTVALUE"]').hide();

                form.formView.$('[id="GENERALLEDGERVALUE"]').hide();
                form.formView.$('[id="HEADERRECORDCODE"]').hide();
                form.formView.$('[id="DETAILRECORDCODE"]').hide();
                form.formView.$('[id="TRAILERRECORDCODE"]').hide();
                form.formView.$('[id="EUROSEPACODE"]').hide();
                form.formView.$('[id="EUROURGCODE"]').hide();
                form.formView.$('[id="RECORDWIDTH"]').hide();
                form.formView.$('[id="REMITTMETHODEMAIL"]').hide();
                form.formView.$('[id="REMITTMETHODOTHER"]').hide();
            } else {
                form.field('FILEPROCESS').shouldBeHidden();
                form.field('MATCHFAILURE').shouldBeHidden();
                form.field('RECORDDELIMITER').shouldBeHidden();
                form.field('RECORDDELIMITERVALUE').shouldBeHidden();
                form.field('OTHERRECORDDELIMITERVALUE').shouldBeHidden();
                form.field('OTHERFIELDDELIMITERVALUE').shouldBeHidden();
                form.field('TEXTQUALIFER').shouldBeHidden();
                form.field('TEXTQUALIFERVALUE').shouldBeHidden();
                form.field('ISSUECODE').shouldBeHidden();
                form.field('VOIDCODE').shouldBeHidden();
                form.field('STOPCODE').shouldBeHidden();
                form.field('CANCELCODE').shouldBeHidden();
                form.field('CHECKCODE').shouldBeHidden();
                form.field('PADCODE').shouldBeHidden();

                form.field('CREDITVALUE').shouldBeHidden();
                form.field('CHECKINGVALUE').shouldBeHidden();
                form.field('DEBITVALUE').shouldBeHidden();
                form.field('SAVINGSVALUE').shouldBeHidden();
                form.field('TRUEVALUE').shouldBeHidden();
                form.field('FALSEVALUE').shouldBeHidden();
                form.field('LOANACCOUNTVALUE').shouldBeHidden();

                form.field('GENERALLEDGERVALUE').shouldBeHidden();
                form.field('HEADERRECORDCODE').shouldBeHidden();
                form.field('DETAILRECORDCODE').shouldBeHidden();
                form.field('TRAILERRECORDCODE').shouldBeHidden();
                form.field('EUROSEPACODE').shouldBeHidden();
                form.field('EUROURGCODE').shouldBeHidden();
                form.field('RECORDWIDTH').shouldBeHidden();
                form.field('REMITTMETHODEMAIL').shouldBeHidden();
                form.field('REMITTMETHODOTHER').shouldBeHidden();
            }
        };

        const initScreenLogic = function () {
            hideSelectedFields();

            if (importFileType === 'NACHA') {
                if (!insertMode && !modifyMode) {
                    // File Properties Section
                    form.formView.$('[id="FILEPROCESS"]').show();
                    form.formView.$('[id="MATCHFAILURE"]').show();

                    // Data Format Details Section
                    form.formView.$('[id="TRUEVALUE"]').show();
                    form.formView.$('[id="FALSEVALUE"]').show();

                    // others
                    form.formView.$('[id="STARTIMPORTATROW"]').hide();
                    form.formView.$('[id="FIELDDELIMITER"]').hide();
                    form.formView.$('[id="FIELDDELIMITERVALUE"]').hide();
                    form.formView.$('[id="DATEFORMAT"]').hide();
                    form.formView.$('[id="DATESEPARATOR"]').hide();
                    form.formView.$('[id="AMOUNTPOSITIONSIMPLIED"]').hide();
                    form.formView.$('[id="DECIMALSEPARATOR"]').hide();
                } else {
                    // File Properties Section
                    form.field('FILEPROCESS').shouldBeVisible();
                    form.field('MATCHFAILURE').shouldBeVisible();
                    // Data Format Details Section
                    form.field('TRUEVALUE').shouldBeVisible();
                    form.field('FALSEVALUE').shouldBeVisible();

                    // others
                    form.field('STARTIMPORTATROW').shouldBeHidden();
                    form.field('FIELDDELIMITER').shouldBeHidden();
                    form.field('FIELDDELIMITERVALUE').shouldBeHidden();
                    form.field('DATEFORMAT').shouldBeHidden();
                    form.field('DATESEPARATOR').shouldBeHidden();
                    form.field('AMOUNTPOSITIONSIMPLIED').shouldBeHidden();
                    form.field('DECIMALSEPARATOR').shouldBeHidden();
                }
                setMandatory('FILEPROCESS');
                setMandatory('MATCHFAILURE');
                setNotMandatory('STARTIMPORTATROW');
                setNotMandatory('TEXTQUALIFERVALUE');
                setNotMandatory('FIELDDELIMITERVALUE');
                setNotMandatory('RECORDDELIMITERVALUE');
            } else if (importFileType === 'Delimited' || importFileType === 'Fixed') {
                setMandatory('FILEPROCESS', productCode === 'USACH');
                setMandatory('MATCHFAILURE', productCode === 'USACH');
                setMandatory('STARTIMPORTATROW', (productCode === 'USACH' || productCode === 'RTGS' || productCode === 'CM' || productCode === 'GIR'));
                if (importFileType === 'Delimited') {
                    setMandatory('TEXTQUALIFERVALUE', (productCode === 'USACH' || productCode === 'RTGS' || productCode === 'CM' || productCode === 'GIR'));
                    setMandatory('FIELDDELIMITERVALUE', (productCode === 'USACH' || productCode === 'RTGS' || productCode === 'CM' || productCode === 'GIR'));
                }
                setMandatory('RECORDDELIMITERVALUE', productCode === 'USACH');
                // File Properties Section
                if (!util.contains(fiConstants.RTGS_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.REMITTER_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.CM_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.UK_SUBTYES, subType)) {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="FILEPROCESS"]').show();
                        form.formView.$('[id="MATCHFAILURE"]').show();
                        form.formView.$('[id="RECORDDELIMITERVALUE"]').show();
                        form.formView.$('[id="RECORDDELIMITER"]').show();
                    } else {
                        form.field('FILEPROCESS').shouldBeVisible();
                        form.field('MATCHFAILURE').shouldBeVisible();
                        form.field('RECORDDELIMITERVALUE').shouldBeVisible();
                        form.field('RECORDDELIMITER').shouldBeVisible();
                    }
                }
                if (importFileType === 'Fixed') {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="RECORDWIDTH"]').show();
                        form.formView.$('[id="FIELDDELIMITER"]').hide();
                        form.formView.$('[id="FIELDDELIMITERVALUE"]').hide();
                    } else {
                        form.field('RECORDWIDTH').shouldBeVisible();
                        form.field('FIELDDELIMITER').shouldBeHidden();
                        form.field('FIELDDELIMITERVALUE').shouldBeHidden();
                    }
                }
                if (importFileType === 'Delimited') {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="TEXTQUALIFER"]').show();
                        form.formView.$('[id="TEXTQUALIFERVALUE"]').show();
                    } else {
                        form.field('TEXTQUALIFER').shouldBeVisible();
                        form.field('TEXTQUALIFERVALUE').shouldBeVisible();
                        form.field('FIELDDELIMITER').shouldBeVisible();
                        form.field('FIELDDELIMITERVALUE').shouldBeVisible();
                    }
                    form.field('TEXTQUALIFER').shouldBeReadOnly(true);
                    if (model.get('FIELDDELIMITERVALUE') === 'Other') {
                        form.field('FIELDDELIMITER').shouldBeReadOnly(false);
                    } else {
                        form.field('FIELDDELIMITER').shouldBeReadOnly(true);
                    }
                    if (model.get('RECORDDELIMITERVALUE') === 'Other') {
                        form.field('RECORDDELIMITER').shouldBeReadOnly(false);
                    } else {
                        form.field('RECORDDELIMITER').shouldBeReadOnly(true);
                    }
                }

                // Data Format Details Section
                if (subType !== 'UKBACS' && subType !== 'UKFP') {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="DATEFORMAT"]').show();
                        form.formView.$('[id="DATESEPARATOR"]').show();
                    } else {
                        form.field('DATEFORMAT').shouldBeVisible();
                        form.field('DATESEPARATOR').shouldBeVisible();
                    }

                    if (form.formView.state === 'insert') {
                        model.set('DATEFORMAT', 'MMDDYY');
                    }
                } else if (!insertMode && !modifyMode) {
                    form.formView.$('[id="DATEFORMAT"]').hide();
                    form.formView.$('[id="DATESEPARATOR"]').hide();
                } else {
                    form.field('DATEFORMAT').shouldBeHidden();
                    form.field('DATESEPARATOR').shouldBeHidden();
                }
                if ((paymentTypeCode === 'CIMIMPRT' || util.contains(fiConstants.REMITTER_TYPES, paymentTypeCode)) && !util.contains(fiConstants.UK_SUBTYES, subType)) {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="ISSUECODE"]').show();
                        form.formView.$('[id="VOIDCODE"]').show();
                        if (allowStopsInIssueVoidFile === '1') {
                            form.formView.$('[id="STOPCODE"]').show();
                            form.formView.$('[id="CANCELCODE"]').show();
                        }
                    } else {
                        form.field('ISSUECODE').shouldBeVisible();
                        form.field('VOIDCODE').shouldBeVisible();
                        if (allowStopsInIssueVoidFile === '1') {
                            form.field('STOPCODE').shouldBeVisible();
                            form.field('CANCELCODE').shouldBeVisible();
                        }
                    }
                } else if (paymentTypeCode === 'STCAIMPT') {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="STOPCODE"]').show();
                        form.formView.$('[id="CANCELCODE"]').show();
                        form.formView.$('[id="CHECKCODE"]').show();
                        form.formView.$('[id="PADCODE"]').show();
                    } else {
                        form.field('STOPCODE').shouldBeVisible();
                        form.field('CANCELCODE').shouldBeVisible();
                        form.field('CHECKCODE').shouldBeVisible();
                        form.field('PADCODE').shouldBeVisible();
                    }
                }

                if (paymentTypeCode === 'CIMIMPRT') {
                    aFieldInSearchPatternSection.$el.closest('.section').show();
                }
                if (!util.contains(fiConstants.CM_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.RTGS_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.REMITTER_TYPES, paymentTypeCode)
                    && !util.contains(fiConstants.UK_SUBTYES, subType)) {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="CREDITVALUE"]').show();
                        form.formView.$('[id="DEBITVALUE"]').show();
                        form.formView.$('[id="CHECKINGVALUE"]').show();
                        form.formView.$('[id="SAVINGSVALUE"]').show();
                        form.formView.$('[id="TRUEVALUE"]').show();
                        form.formView.$('[id="FALSEVALUE"]').show();
                        if (paymentTypeCode === 'BDACHCVP' || paymentTypeCode === 'BDACHCEC') {
                            form.formView.$('[id="GENERALLEDGERVALUE"]').show();
                        }
                        if (paymentTypeCode === 'BDACHCCD' || paymentTypeCode === 'BDACHCD' || paymentTypeCode === 'BDACHCCP' || paymentTypeCode === 'BDACHCP' || paymentTypeCode === 'BDACHCVP' || paymentTypeCode === 'BDACHP') {
                            form.formView.$('[id="LOANACCOUNTVALUE"]').show();
                        }
                    } else {
                        form.field('CREDITVALUE').shouldBeVisible();
                        form.field('DEBITVALUE').shouldBeVisible();
                        form.field('CHECKINGVALUE').shouldBeVisible();
                        form.field('SAVINGSVALUE').shouldBeVisible();
                        form.field('TRUEVALUE').shouldBeVisible();
                        form.field('FALSEVALUE').shouldBeVisible();
                        if (paymentTypeCode === 'BDACHCVP' || paymentTypeCode === 'BDACHCEC') {
                            form.field('GENERALLEDGERVALUE').shouldBeVisible();
                        }
                        if (paymentTypeCode === 'BDACHCCD' || paymentTypeCode === 'BDACHCD' || paymentTypeCode === 'BDACHCCP' || paymentTypeCode === 'BDACHCP' || paymentTypeCode === 'BDACHCVP' || paymentTypeCode === 'BDACHP') {
                            form.field('LOANACCOUNTVALUE').shouldBeVisible();
                        }
                    }
                }
                if (util.contains(fiConstants.RTGS_TYPES, paymentTypeCode)
                    || util.contains(fiConstants.REMITTER_TYPES, paymentTypeCode)
                    || util.contains(fiConstants.UK_SUBTYES, subType)) {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="HEADERRECORDCODE"]').show();
                        form.formView.$('[id="DETAILRECORDCODE"]').show();
                        form.formView.$('[id="TRAILERRECORDCODE"]').show();
                    } else {
                        form.field('HEADERRECORDCODE').shouldBeVisible();
                        form.field('DETAILRECORDCODE').shouldBeVisible();
                        form.field('TRAILERRECORDCODE').shouldBeVisible();
                    }
                }
                if (paymentTypeCode === 'EURO') {
                    if (!insertMode && !modifyMode) {
                        form.formView.$('[id="EUROSEPACODE"]').show();
                        form.formView.$('[id="EUROURGCODE"]').show();
                    } else {
                        form.field('EUROSEPACODE').shouldBeVisible();
                        form.field('EUROURGCODE').shouldBeVisible();
                    }
                }
                if (util.contains(fiConstants.REMITTER_TYPES, paymentTypeCode)) {
                    model.validators.PAYMENTTYPE.matches = patterns.ALPHAUNSCORE_PATTERN;
                    $('#DATEFORMAT').closest('.section').hide();
                } else {
                    model.validators.PAYMENTTYPE.matches = patterns.ALPHA_PATTERN;
                }

                // Real-time payments
                if (paymentTypeCode === 'CRTRAN') {
                    const hiddenFields = [
                        'SUBTYPE',
                        'FILEPROCESS',
                        'MATCHFAILURE',
                        'RECORDDELIMITERVALUE',
                        'RECORDDELIMITER',
                        'CREDITVALUE',
                        'CHECKINGVALUE',
                        'DEBITVALUE',
                        'SAVINGSVALUE',
                        'TRUEVALUE',
                        'FALSEVALUE',
                    ];
                    hiddenFields.forEach((field) => {
                        form.field(field).shouldBeHidden().setValue('');
                    });
                    const visibleFields = [
                        'HEADERRECORDCODE',
                        'TRAILERRECORDCODE',
                        'DETAILRECORDCODE',
                        'REMITTMETHODEMAIL',
                        'REMITTMETHODOTHER',
                    ];
                    visibleFields.forEach((field) => {
                        form.field(field).shouldBeVisible();
                    });
                    // Only set default value on insertMode
                    if (insertMode) {
                        form.field('DETAILRECORDCODE').setValue('B');
                        form.field('FUNCTIONCODE').$el.prop('disabled', true);
                        model.set('REMITTMETHODEMAIL', 'EMAIL');
                        model.set('REMITTMETHODOTHER', 'OTHER');
                    }
                    setMandatory('STARTIMPORTATROW');
                    if (importFileType === 'Delimited') {
                        setMandatory('FIELDDELIMITERVALUE');
                    }
                }
            }

            if (importFileType !== 'NACHA' && !util.isEmpty(paymentFunction) && paymentFunction !== 'TMPL' && paymentFunction !== 'BHTMPL') {
                setMandatory('DATEFORMAT');
            } else {
                setNotMandatory('DATEFORMAT');
            }

            if (insertMode) {
                initFieldValuesInsert();
            } else if (modifyMode) {
                setValuesFieldsModify();
            }

            if (paymentTypeCode === 'BDACHFP') {
                setNotMandatory('DATEFORMAT');
                setNotMandatory('FILEPROCESS');
                setNotMandatory('MATCHFAILURE');
                setNotMandatory('RECORDDELIMITERVALUE');
            }
        };

        const openInputSectionForFileAndData = function () {
            aFieldInFilePropertiesSection.$el.closest('.section').show();
            aFieldInDataFormatDetailsSection.$el.closest('.section').show();
            showGrid();
            initScreenLogic();
        };

        model.on('change:IMPORTFILETYPE', function () {
            importFileType = this.get('IMPORTFILETYPE');
            model.set('PAYMENTTYPECODE', '');
            model.set('FUNCTIONCODE', '');
            model.set('SUBTYPE', '');
            if (importFileType === '') {
                hideAllSections();
                hideGrid();
                form.field('FUNCTIONCODE').shouldBeVisible();
                form.field('SUBTYPE').shouldBeVisible();
            } else if (importFileType === 'Delimited' || importFileType === 'Fixed') {
                form.field('FUNCTIONCODE').shouldBeVisible();
                form.field('SUBTYPE').shouldBeVisible();
            } else if (importFileType === 'NACHA') {
                form.field('FUNCTIONCODE').shouldBeHidden();
                form.field('SUBTYPE').shouldBeHidden();
                paymentFunction = 'BATCH';
                subType = importFileType;
            }
            setMandatory('FUNCTIONCODE', (productCode === 'RTGS' || productCode === 'RTP'));
            setMandatory('SUBTYPE', productCode === 'USACH');
        });

        model.on('change:PAYMENTTYPECODE', () => {
            importFileType = model.get('IMPORTFILETYPE');
            paymentTypeCode = model.get('PAYMENTTYPECODE');
            productCode = model.get('PRODUCTCODE');
            model.set('FUNCTIONCODE', '');
            form.field('FUNCTIONCODE').$el.prop('disabled', false);
            model.set('SUBTYPE', '');
            form.field('FUNCTIONCODE').shouldBeVisible();
            form.field('SUBTYPE').shouldBeVisible();

            hideAllSections();
            hideGrid();
            setMandatory('CSAGENCY', paymentTypeCode === 'BDACHCSP');
            setMandatory('TAXAUTH', paymentTypeCode === 'BDACHTP');
            setMandatory('ADDENDAFORMAT', paymentTypeCode === 'BDACHCVP');
            if (paymentTypeCode !== '') {
                if (productCode === 'RTGS') {
                    form.field('SUBTYPE').shouldBeHidden();
                    if (paymentTypeCode === 'CHAPS'
                        || paymentTypeCode === 'EURO'
                        || paymentTypeCode === 'WIRTMPIM') {
                        form.field('FUNCTIONCODE').shouldBeHidden();
                        model.set('FUNCTIONCODE', 'INST');
                        openInputSectionForFileAndData();
                    }
                } else if (productCode === 'USACH') {
                    form.field('FUNCTIONCODE').shouldBeHidden();
                    paymentFunction = 'BATCH';

                    if (paymentTypeCode === 'BDEFTCC' || paymentTypeCode === 'BDEFTCCD' || paymentTypeCode === 'BDEFTCD') {
                        model.set('SUBTYPE', 'CAEFT');
                        form.field('SUBTYPE').shouldBeHidden();
                        openInputSectionForFileAndData();
                    }
                    if (importFileType === 'NACHA') {
                        subType = importFileType;
                        model.set('SUBTYPE', importFileType);
                        form.field('SUBTYPE').shouldBeHidden();
                        openInputSectionForFileAndData();
                    }
                } else if (productCode === 'CM' || productCode === 'GIR') {
                    form.field('FUNCTIONCODE').shouldBeHidden();
                    form.field('SUBTYPE').shouldBeHidden();
                    paymentFunction = 'INST';
                    openInputSectionForFileAndData();
                } else if (productCode === 'RTP') {
                    form.field('SUBTYPE').shouldBeHidden();
                    model.set('FUNCTIONCODE', 'INST');
                    openInputSectionForFileAndData();
                }
            }
            setMandatory('FUNCTIONCODE', (productCode === 'RTGS' || productCode === 'RTP'));
            if (!(paymentTypeCode === 'BDEFTCC' || paymentTypeCode === 'BDEFTCCD' || paymentTypeCode === 'BDEFTCD')) {
                setMandatory('SUBTYPE', productCode === 'USACH');
            }
        });

        model.on('change:FUNCTIONCODE', () => {
            productCode = model.get('PRODUCTCODE');
            if (productCode !== 'RTGS' && productCode !== 'RTP') {
                return;
            }
            paymentFunction = model.get('FUNCTIONCODE');
            importFileType = model.get('IMPORTFILETYPE');
            paymentTypeCode = model.get('PAYMENTTYPECODE');
            if (importFileType !== '' && paymentTypeCode !== '' && paymentFunction !== '') {
                openInputSectionForFileAndData();
            }
        });

        model.on('change:SUBTYPE', function () {
            subType = this.get('SUBTYPE');
            importFileType = model.get('IMPORTFILETYPE');
            paymentTypeCode = model.get('PAYMENTTYPECODE');
            if (model.get('PRODUCTCODE') === 'USACH' && importFileType !== '' && paymentTypeCode !== '' && subType !== '') {
                if (paymentTypeCode === 'BDACHCSP') {
                    fieldInChildSupportAgencySection.$el.closest('.section').show();
                    model.set('CSAGENCY', '');
                } else if (paymentTypeCode === 'BDACHTP') {
                    fieldInTaxAuthSection.$el.closest('.section').show();
                    model.set('TAXAUTH', '');
                } else if (paymentTypeCode === 'BDACHCVP') {
                    fieldInAddendaFormatSection.$el.closest('.section').show();
                    model.set('ADDENDAFORMAT', '');
                } else if (importFileType !== 'NACHA') {
                    /*
                     * if the import file type is NACHA the
                     * File and Data should already be opened
                     */
                    openInputSectionForFileAndData();
                }
            }
        });

        model.on('change:CSAGENCY', function () {
            const childSupportAgency = this.get('CSAGENCY');
            if (childSupportAgency === '') {
                aFieldInFilePropertiesSection.$el.closest('.section').hide();
                aFieldInDataFormatDetailsSection.$el.closest('.section').hide();
                hideGrid();
            } else {
                openInputSectionForFileAndData();
            }
        });

        model.on('change:TAXAUTH', function () {
            const taxAuth = this.get('TAXAUTH');
            if (taxAuth === '') {
                aFieldInFilePropertiesSection.$el.closest('.section').hide();
                aFieldInDataFormatDetailsSection.$el.closest('.section').hide();
                hideGrid();
            } else {
                openInputSectionForFileAndData();
            }
        });

        model.on('change:ADDENDAFORMAT', function () {
            const addendaFormat = this.get('ADDENDAFORMAT');
            if (addendaFormat === '') {
                aFieldInFilePropertiesSection.$el.closest('.section').hide();
                aFieldInDataFormatDetailsSection.$el.closest('.section').hide();
                hideGrid();
            } else {
                openInputSectionForFileAndData();
            }
        });

        model.on('change:RECORDDELIMITERVALUE', () => {
            const value = model.get('RECORDDELIMITERVALUE');
            if (value !== 'Other') {
                if (value === constants.CR_ASCII) {
                    model.set('RECORDDELIMITER', constants.CR_ASCII);
                } else if (value === constants.CR_LF) {
                    model.set('RECORDDELIMITER', constants.CR_LF);
                } else if (value === constants.LF_ASCII) {
                    model.set('RECORDDELIMITER', constants.LF_ASCII);
                } else if (value === '') {
                    model.set('RECORDDELIMITER', '');
                }
            }

            if (value === 'Other') {
                form.field('RECORDDELIMITER').shouldBeReadOnly(false);
                model.set('RECORDDELIMITER', '');
            } else {
                form.field('RECORDDELIMITER').shouldBeReadOnly(true);
            }
        });

        model.on('change:FIELDDELIMITERVALUE', function () {
            const value = this.get('FIELDDELIMITERVALUE');
            if (value === 'Other') {
                form.field('FIELDDELIMITER').shouldBeReadOnly(false);
                if (model.get('OTHERFIELDDELIMITERVALUE') === '') {
                    model.set('FIELDDELIMITER', '');
                }
            } else {
                form.field('FIELDDELIMITER').shouldBeReadOnly(true);
            }

            if (value !== 'Other') {
                if (value === 'Comma') {
                    model.set('FIELDDELIMITER', constants.COMMA_ASCII);
                } else if (value === 'Semicolon') {
                    model.set('FIELDDELIMITER', constants.SEMICOLON_ASCII);
                } else if (value === 'Space') {
                    model.set('FIELDDELIMITER', constants.SPACE_ASCII);
                } else if (value === 'Tab') {
                    model.set('FIELDDELIMITER', constants.TAB_ASCII);
                }
            }
        });

        model.on('change:TEXTQUALIFERVALUE', function () {
            if (this.get('TEXTQUALIFERVALUE') === 'Blank') {
                model.set('TEXTQUALIFER', 'Blank');
            } else if (this.get('TEXTQUALIFERVALUE') === '') {
                model.set('TEXTQUALIFER', '');
            } else {
                model.set('TEXTQUALIFER', constants.DOUBLE_QUOTE_ASCII);
            }
        });

        model.on('change:AMOUNTPOSITIONSIMPLIED', function () {
            if (this.get('AMOUNTPOSITIONSIMPLIED') === '') {
                form.field('DECIMALSEPARATOR').shouldBeReadOnly(false);
            } else {
                form.field('DECIMALSEPARATOR').shouldBeReadOnly(true);
            }
        });

        fieldImportFormatName.removeValidator('matches');
        fieldImportFormatName.shouldMatchAlphaNumeric();
        hideAllSections();
        if (modifyMode) {
            form.field('IMPORTFILETYPE').shouldBeReadOnly(true);
            form.field('PAYMENTTYPECODE').shouldBeReadOnly(true);
            form.field('FUNCTIONCODE').shouldBeReadOnly(true);
            form.field('SUBTYPE').shouldBeReadOnly(true);
        }
        if (modifyMode || viewMode) {
            if (productCode === 'USACH') {
                form.field('FUNCTIONCODE').shouldBeHidden();
                // Hiding the function code for EFT payments for import map
                if (paymentTypeCode === 'BDEFTCC' || paymentTypeCode === 'BDEFTCCD' || paymentTypeCode === 'BDEFTCD') {
                    form.field('SUBTYPE').shouldBeHidden();
                    $('#FUNCTIONCODE').hide();
                }
            } else if (productCode === 'RTGS') {
                form.field('SUBTYPE').shouldBeHidden();
            }
            if (productCode === 'CM' || productCode === 'GIR') {
                form.field('SUBTYPE').shouldBeHidden();
                form.field('FUNCTIONCODE').shouldBeHidden();
                $('[id=FUNCTIONCODE]').hide();
                $('#SUBTYPE').hide();
            }
            if (paymentTypeCode === 'BDACHCSP') {
                fieldInChildSupportAgencySection.$el.closest('.section').show();
                form.field('CSAGENCY').shouldBeReadOnly(true);
            } else if (paymentTypeCode === 'BDACHTP') {
                fieldInTaxAuthSection.$el.closest('.section').show();
                form.field('TAXAUTH').shouldBeReadOnly(true);
            } else if (paymentTypeCode === 'BDACHCVP') {
                fieldInAddendaFormatSection.$el.closest('.section').show();
                form.field('ADDENDAFORMAT').shouldBeReadOnly(true);
            }
            aFieldInFilePropertiesSection.$el.closest('.section').show();
            aFieldInDataFormatDetailsSection.$el.closest('.section').show();
            showGrid();
            initScreenLogic();
        }

        // if (initialState)
    }
}
