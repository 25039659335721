import alert from '@glu/alerts';
import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import Constants from 'app/balanceAndTransaction/constants';
import $ from 'jquery';
import services from 'services';
import ExportDataView from 'app/reports/views/exportBTRData/exportBTRData';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import PrintAccountsUtil from 'app/balanceAndTransaction/printBTRaccountsUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import mobileUtil from 'mobile/util/mobileUtil';
import { maskValue } from 'common/util/maskingUtil';
import dateRangeUtil from '../api/dateRangeUtil';
import TotalCollection from '../collections/combinedAccountTotals';
import FilterView from './filter';
import FilterModel from '../models/combinedAccountFilter';
import AccountDetailsView from './accountDetails';
import CombinedAccountsCollectionView from './combinedAccountsCollection';
import CombinedAccountPrintModal from './combinedAccountPrintModal';
import template from './reporting.hbs';

export default Layout.extend({
    template,

    id: 'showCustomReportTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showCustomReportLink',
    },

    ui: {
        $printButton: '[data-hook="print-button"]',
        $exportButton: '[data-hook="export-button"]',
        $accountLoader: '[data-hook="accounts-loader"]',
        $criteriaResults: '[data-hook="criteriaResults"]',
    },

    events: {
        'click button[data-hook="show-transactions"]': 'showTransactions',
        'click button[data-hook="print-button"]': 'print',
        'click button[data-hook="export-button"]': 'export',
    },

    regions: {
        accounts: '[data-region="combined-accounts"]',
        filter: '[data-region="filter"]',
        accountAlertRegion: '[data-region="accountAlertRegion"]',
    },

    initialize(options) {
        this.filterModel = new FilterModel(options);
        this.filterView = new FilterView({
            model: this.filterModel,
            rowsPerPage: options.rowsPerPage,
        });
        this.initializeAccountCollections(options.disableRealTime);
        this.tabId = options.tabId;
        this.entitlements = store.get('btr:entitlements');
        this.displayTransactionTotals = applicationConfigParams.getValue('GENERAL', 'DISPLAYTRANSACTIONTOTALS') === '1';
        this.isDeepLinked = window.parent !== window;
    },

    getUngroupedTotalsServiceUrl() {
        return services.generateUrl(`${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedUngroupedAccountSummaryTotals`);
    },

    getUngroupedAccountsServiceUrl() {
        return services.generateUrl(`${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedUngroupedAccounts`);
    },

    getGroupedTotalsServiceUrl() {
        return services.generateUrl(`${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedAccountGroupTotals`);
    },

    getGroupedAccountsServiceUrl() {
        return services.generateUrl(`${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedAccountSummaryForGroup`);
    },

    /**
     * @method initializeAccountCollections
     * @param {boolean} disableRealTime
     * Creates account collection for reporting view
     */
    initializeAccountCollections(disableRealTime) {
        this.ungroupedCollection = new TotalCollection(
            [],
            {
                filterModel: this.filterModel,
                service: this.getUngroupedTotalsServiceUrl(),
                accountsService: this.getUngroupedAccountsServiceUrl(),
                filterView: this.filterView,
                disableRealTime,
            },
        );

        this.groupedCollection = new TotalCollection(
            [],
            {
                filterModel: this.filterModel,
                service: this.getGroupedTotalsServiceUrl(),
                accountsService: this.getGroupedAccountsServiceUrl(),
                filterChildAccounts: true,
                filterView: this.filterView,
                disableRealTime,
            },
        );

        // Move this out of setView Collection (Default CollectionView)
        this.createAccountsCollectionView(this.ungroupedCollection);

        this.listenTo(
            this.filterModel,
            {
                update: this.filterUpdate,
            },
        );

        this.listenTo(
            this.ungroupedCollection,
            {
                sync: this.childCollectionReady,
                'accountGroups:loading': this.onAccountsLoad,
            },
        );

        this.listenTo(
            this.groupedCollection,
            {
                sync: this.childCollectionReady,
                'accountGroups:loading': this.onAccountsLoad,
            },
        );
    },

    /**
     * @param {Collection} totalsAccountCollection
     * Creates a main Account Collection View used by the reporting page
     */
    createAccountsCollectionView(totalsAccountCollection) {
        this.combinedCollectionView = new CombinedAccountsCollectionView({
            collection: totalsAccountCollection,
            filterModel: this.filterModel,
            filterView: this.filterView,
            stack: this.options.workspaceStack,
        });
    },

    setViewCollection() {
        const useGrouped = this.filterModel.get('accountSelectBy') === 'group';
        const correctCollection = useGrouped
            ? this.groupedCollection : this.ungroupedCollection;

        // Only re-create the collectionView if we need to
        if (this.combinedCollectionView.collection !== correctCollection) {
            this.combinedCollectionView.collection.reset();
            this.createAccountsCollectionView(correctCollection);
        }

        // Always happens
        return correctCollection.fetch({
            reset: true,
            useGrouped,
        });
    },

    togglePrintAndExport(model) {
        this.ui.$printButton.toggle(model.length);
        this.ui.$exportButton.toggle(model.length);
    },

    /**
     * @method childCollectionReady
     * Callback on sync events of account totals collection to
     * toggle parts of UI on completion
     */
    childCollectionReady() {
        const accountSelectBy = this.filterModel.get('accountSelectBy');
        const filteredCollection = (accountSelectBy === 'group') ? this.groupedCollection : this.ungroupedCollection;

        this.verifyAccounts(accountSelectBy);
        this.accounts.show(this.combinedCollectionView);

        // Handle the visuals
        if (this.accounts.$el && this.accounts.$el.is(':visible')) {
            this.accounts.$el.hide();
        }

        this.togglePrintAndExport(filteredCollection);
        this.toggleLoadingState(false);
        this.accounts.$el.fadeIn('fast');
    },

    /**
     * @method onAccountsLoad
     * @param {boolean} isLoading
     * Callback to compare filtered accounts vs returned accounts, after all recurring
     * account fetches have finished
     */
    onAccountsLoad(isLoading = true) {
        if (!isLoading) {
            util.defer(() => this.verifyAccounts(this.filterModel.get('accountSelectBy')));
        }

        this.toggleLoadingState(isLoading);
    },

    /**
     * @param {boolean} isLoading
     * Toggles loaing state indicator
     */
    toggleLoadingState(isLoading = true) {
        if (isLoading) {
            this.ui.$accountLoader.show();
        } else {
            this.ui.$accountLoader.hide();
        }
    },

    filterUpdate() {
        // Only when the filter update completes should we start loading

        if (this.accountAlertRegion) {
            this.accountAlertRegion.close();
        }

        this.ui.$criteriaResults.show();
        this.toggleLoadingState();

        // Only if it has rendered already
        if (this.accounts && this.accounts.$el) {
            this.accounts.$el.fadeOut('fast');
        }

        // Kick off the collection reset (if needed) and fetch.
        this.setViewCollection();
    },

    /**
     * @method verifygrouped
     * Check if the returned results, has data for all the acct groups's accounts filtered
     */
    verifyGrouped() {
        const filteredGroups = this.filterModel.get('accountSelect');
        const accountGroupAccountsList = this.filterModel.get('accountGroupAccountList');

        const selectedAccountList = (filteredGroups[0] === 'all') ? accountGroupAccountsList.all
            : filteredGroups
                .reduce((acc, group) => {
                    const groupedAccts = accountGroupAccountsList[group];
                    return acc.concat(groupedAccts);
                }, []);

        const accountsReturned = this.groupedCollection
            .reduce((acc, group) => {
                const groupedAccts = group.get('accountCollection').map(acct => acct.get('ACCOUNTFILTER'));
                return acc.concat(groupedAccts);
            }, []);

        this.toggleAccountAlert(selectedAccountList, accountsReturned);
    },

    /**
     * @method verifyUngrouped
     * Check if the returned results, has data for all the accounts that were filtered for
     */
    verifyUngrouped() {
        const filteredAccounts = this.filterModel.get('accountSelect');
        const allAccounts = this.filterModel.get('accountFilterOptions').map(act => act.id);

        /*
         * If all accounts is selected, populate the account list from all accounts
         * available in drop down
         * Otherwise grab account number dropdown multiselect.
         */
        const selectedAccountList = (filteredAccounts[0] === 'all') ? allAccounts : filteredAccounts;

        // get account results returned
        const hasResults = this.ungroupedCollection.length;
        const accountsReturned = (hasResults) ? this.ungroupedCollection.at(0)
            .get('accountCollection')
            .map(acct => acct.get('ACCOUNTFILTER')) : [];

        this.toggleAccountAlert(selectedAccountList, accountsReturned);
    },

    /**
     * @method verifyAccounts
     * @param {string} accountSelectBy - 'group' or 'number'
     * Will verify the account data returned based on user's account filter
     */
    verifyAccounts(accountSelectBy) {
        if (accountSelectBy === 'group') {
            this.verifyGrouped();
        } else if (accountSelectBy === 'number') {
            this.verifyUngrouped();
        }
    },

    toggleAccountAlert(selectedAccountList, accountsReturned) {
        let accountsMissing = util.difference(selectedAccountList, accountsReturned);

        // get only number from id string: "{BANK}-{NUMBER}"
        accountsMissing = accountsMissing
            .reduce((acc, id) => {
                const num = id?.split(/-(.+)/)[1];
                return (num !== undefined) ? [...acc, num] : acc;
            }, []);

        if (accountsMissing.length > 0 && accountsReturned.length > 0) {
            let message;
            const belowAccountCap = (accountsMissing.length < 100);

            // Mask Account values that are used in the alert
            accountsMissing = accountsMissing.map(account => maskValue(account));

            if (belowAccountCap) {
                const confirms = {
                    confirmResults: [{
                        confirmData: [{
                            item: [{
                                name: locale.get('GIR.combinedView.confirmAccounts:'),
                                value: accountsMissing.join(', '),
                            }],
                        }],
                    }],
                };

                const accountConfirmation = new Confirms({
                    confirms,
                });

                message = alert.info(
                    locale.get('GIR.combinedView.numAccountsMissing').replace('{0}', accountsMissing.length),
                    {
                        details: accountConfirmation,
                    },
                );
            } else {
                message = alert.info(locale.get('GIR.combinedView.numAccountsMissingMax'));
            }

            this.accountAlertRegion.show(message);
            if (!mobileUtil.isMobileGridEnabled()) {
                if (belowAccountCap) {
                    message.toggleDetails();
                }
            }
        }
    },

    onRender() {
        this.ui.$criteriaResults.hide();
        this.filter.show(this.filterView);
    },

    showTransactions(e) {
        const $target = this.$(e.currentTarget);
        const accountModel = $target.data('model');
        const clonedFilterModel = util.clone(this.filterModel);
        let startDate = this.filterModel.get('START_DATE');
        let endDate = this.filterModel.get('END_DATE');

        if (endDate.length < 1) {
            startDate = dateRangeUtil.convertCodesToDates(startDate);
            endDate = moment(new Date()).format(userInfo.getDateFormat());
        }

        clonedFilterModel.set('START_DATE', startDate);
        clonedFilterModel.set('END_DATE', endDate);

        this.showAccountTransactionView(accountModel, clonedFilterModel);
    },

    showAccountTransactionView(accountModel, filterModel) {
        this.stack.push(new AccountDetailsView({
            model: accountModel,
            filterModel,
            filterView: this.filterView,
            workspaceStack: this.options.workspaceStack,
        }));
    },

    /**
     * TODO// NH-146636: To be implemented in a future release.
     * To activate this feature, update teh reporting.hbs file by
     * changing data-action="print" of the print btn to data-action="printModal"
     * Also, update the print method options object by adding the following:
     *      ...this.displayTransactionTotals && {
     *          includeSummaryInfo: this.filterModel.get('includeSummaryInfo') ? 1 : 0,
     *      },
     */
    /**
     * Helper method to decide whether to display print modal or not.
     * When Display Transactions Totals is enabled: display modal
     * When Display Transactions Totals is disabled: print immediately.
     */
    printModal() {
        if (this.displayTransactionTotals) {
            this.combinedAccountPrintModal = new CombinedAccountPrintModal({
                model: this.filterModel,
                onPrint: this.print.bind(this),
            });
            dialog.open(this.combinedAccountPrintModal);
        } else {
            this.print();
        }
    },

    print() {
        let startDate = this.filterModel.get('START_DATE');
        let endDate = this.filterModel.get('END_DATE');
        const useGrouped = this.filterModel.get('accountSelectBy') === 'group';
        const correctCollection = useGrouped
            ? this.groupedCollection : this.ungroupedCollection;
        let numAccounts = 0;
        const printTreshhold = util.isUndefined(serverConfigParams.get('GIRCombinedViewPrintMaxAccounts')) ? 20 : parseInt(serverConfigParams.get('GIRCombinedViewPrintMaxAccounts'), 10);
        const printWarnMessage = alert.warning(locale.get('GIR.combinedView.printMaxError').replace('{0}', printTreshhold));

        /*
         * BASECURRACCOUNT has the total number of accounts that return data from the selection
         * if account group then add the BASECURRACCOUNT for each group to get total count
         */
        if (useGrouped) {
            util.each(correctCollection.models, (calcNumAccountsToPrint) => {
                numAccounts += parseInt(calcNumAccountsToPrint.get('BASECURRACCOUNT'), 10);
            });
        } else {
            numAccounts = correctCollection.models.length > 0 ? correctCollection.models[0].get('BASECURRACCOUNT') : 0;
        }

        if (numAccounts > printTreshhold) {
            this.accountAlertRegion.show(printWarnMessage);
            return;
        }
        if (endDate.length < 1) {
            startDate = dateRangeUtil.convertCodesToDates(startDate);
            endDate = moment(new Date()).format(userInfo.getDateFormat());
        }

        const options = {
            startDate,
            endDate,
            accountsURL: `${Constants.BTR_REPORT_PREFIX}reportView`,
            filterModel: this.filterModel,
            filterView: this.filterView,
        };

        const accountSelectBy = this.filterModel.get('accountSelectBy');
        if (accountSelectBy === 'group') {
            options.inquiryId = this.getInquiryIdForAccountGroup();
            options.totalsInquiryId = this.getTotalsInquiryIdForAccountGroup();
        } else {
            options.inquiryId = this.getInquiryIdForAccount();
            options.totalsInquiryId = this.getTotalsInquiryIdForAccount();
        }

        PrintAccountsUtil.print(options);
    },

    getInquiryIdForAccountGroup() {
        return Constants.INQUIRYID_BAL_ACCOUNTGROUP;
    },

    getTotalsInquiryIdForAccountGroup() {
        return Constants.TOTALS_INQUIRYID_BAL_ACCOUNTGROUP;
    },

    getInquiryIdForAccount() {
        return Constants.INQUIRYID_BAL_ACCOUNT;
    },

    getTotalsInquiryIdForAccount() {
        return Constants.TOTALS_INQUIRYID_BAL_ACCOUNT;
    },

    getTabId(tabId) {
        /**
         * When config param CustRepoCurrDayDataForSeparatedPerm is set to true and the user is on
         * separated custom report tab, then we need to treat the export view as combined custom
         * report
         */
        const CustRepoCurrDayDataForSeparatedPerm =
        serverConfigParams.get('CustRepoCurrDayDataForSeparatedPerm')?.toLowerCase() === 'true';
        if (tabId === Constants.ACCOUNT_SUMMARY.TABS.CUSTOM_REPORT_PRIOR &&
            CustRepoCurrDayDataForSeparatedPerm) {
            return Constants.ACCOUNT_SUMMARY.TABS.CUSTOM_REPORT;
        }
        return tabId;
    },

    export() {
        const { filterModel } = this;
        const drp = this.filterView.ui.$datepicker.data('daterangepicker');
        const initialFilterValues = this.filterView.getTransactionFilters();

        this.exportDataView = new ExportDataView({
            filterModel,
            initialFilterValues,
            typeAheadData: this.getTypeAheadData(this.filterRegion),
            reportType: filterModel.get('accountSelectBy'),
            dateRangeValue: drp.element.val(),
            dateRangeSelected: drp.getActiveRange(),

            defaultDates: {
                startDate: drp.startDate,
                endDate: drp.endDate,
            },

            currentTab: this.getTabId(this.tabId),
            entitlements: this.entitlements,
        });
        const self = this;

        this.listenTo(this.exportDataView, 'doExport', () => {
            // Display link to download screen
            const successMessage = locale.get('GIR.output.success').replace('#', ' ');
            const docRoot = window.Bottomline.documentRoot
                ? `/${window.Bottomline.documentRoot.replace(/^\/|\/$/g, '')}` : '';
            const downloadPage = this.isDeepLinked ? 'REPORTING/download' : 'REPORTING/btrExport';
            const $message = $('<a/>').attr('href', `${docRoot}/${window.Bottomline.appRoot}/${downloadPage}`).text(locale.get('GIR.download.view'));

            // Initial message does not have the link because it cannot contain markup.
            const alertView = alert.success(successMessage);

            // Find the alert in and update it.
            alertView.on('show', () => {
                self.$el.find('.alert p').text(successMessage).append($message);
            });
            self.accountAlertRegion.show(alertView);
            $('html, body').animate({
                scrollTop: 0,
            }, 100);
        });

        this.listenTo(this.exportDataView, 'doExportFailed', () => {
            const alertView = alert.danger(locale.get('payment.export.error.message'));

            self.accountAlertRegion.show(alertView);
            $('html, body').animate({
                scrollTop: 0,
            }, 100);
        });

        dialog.open(this.exportDataView);
    },

    getTypeAheadData() {
        const self = this;
        const selector = '.type-ahead';
        const typeAheadFields = {};
        const reportType = self.filterModel.get('accountSelectBy');
        const reportTypeField = reportType === 'number' ? 'ACCOUNTFILTER' : 'ACCOUNTFILTER2';

        // collect the data on each typeAhead field
        self.filterView.$el.find(selector).each(function () {
            // gather all typeahead combobox data selections to return
            let attrName = $(this).attr('name');

            const comboData = util.map($(this).comboBox('data'), value => value.id);

            /*
             * handling account report filters
             * don't return any specific account typeahead filter data if "all" is selected
             */
            if (attrName === 'accountSelect') {
                if (comboData[0] !== 'all') {
                    typeAheadFields[reportTypeField] = comboData;

                    util.each(comboData, value => value.id);
                }
            } else {
                // convert SWIFT_CODE to SWIFT_TYPE_CODE for the modal
                attrName = (attrName === 'SWIFT_CODE') ? 'SWIFT_TYPE_CODE' : attrName;

                // Handling anything other typeahead filter
                typeAheadFields[attrName] = util.each(comboData, value => value.id);
            }
        });

        return typeAheadFields;
    },

    getTypeAheadFields(modelField, modelList) {
        const self = this;
        const typeAheadFields = [];

        if (self.filterModel.get(modelField)) {
            util.each(self.filterModel.get(modelField).split(','), (value) => {
                const pair = util.findWhere(
                    self.filterModel.get(modelList),
                    {
                        id: value.trim(),
                    },
                );
                if (pair) {
                    typeAheadFields.push({
                        id: pair.id,
                        text: ((modelField === 'BANK_CODE' || modelField === 'CURRENCY_CODE') ? `${pair.id} - ` : '') + pair.text,
                    });
                }
            });
        }
        return typeAheadFields;
    },

    templateHelpers() {
        const disableCombinedAccountPrint = serverConfigParams.get('DisableCombinedAccountPrint');
        const showAccountPrint = disableCombinedAccountPrint ? disableCombinedAccountPrint === 'false' : true;
        return {
            displayPrintTransactionsText:
                this.displayTransactionTotals && disableCombinedAccountPrint,
            showAccountPrint,
        };
    },
});
