import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';
import Formatter from 'system/utilities/format';
import util from '@glu/core/src/util';

const AccountTotals = Model.extend({
    /*
     *  The first parameter of a model is the attributes.
     *      E.g. new Model([attributes], [options])
     */
    initialize(attributes, options) {
        this.service = options.service;
    },

    defaults: {
        filters: [],
    },

    sync(method, model, options) {
        if (method === 'read') {
            const requestParameters = {
                tabId: options.tabId,
                sectionId: options.sectionId,
            };
            const request = {
                requestParameters: {
                    item: transform.hashToPairs(requestParameters),
                },

                rowsPerPage: this.rowsPerPage,
                startRow: this.startRow,
                searchFields: this.get('filters'),
                viewId: this.get('viewId'),
            };

            this.rowsPerPage = this.dataBlock ? this.dataBlock.rowsPerPage : 0;
            this.startRow = this.dataBlock ? this.dataBlock.startRow : 0;

            return http.post(this.service, request, (response) => {
                options.success(response);
            }, () => {
                options.error();
            });
        }

        // TODO: Return rejected Promise for bad method?
        return undefined;
    },

    parse(response, options) {
        const { totals } = options;
        if (response.rowHeader.length === 0) {
            return {};
        }
        const { rows = [] } = response;
        const currencyTotals = rows.map((row) => {
            const totalsByCurrency = {};
            totalsByCurrency.currencyCode = util.findWhere(row.columns, { fieldName: 'CURRENCYCODE' })?.fieldValue;
            util.chain(totals)
                // eslint-disable-next-line consistent-return
                .each((total, key) => {
                /*
                 * Determine if the amount column required
                 * by the account group section exists in the header
                 */
                    const amountColumn = util.find(response.rowHeader, r => [`SUM(${total.amountColumn})`, total.amountColumn].includes(r.fieldName));
                    if (util.isNullOrUndefined(amountColumn)) {
                        return {};
                    }
                    const amount = util.find(row.columns, r => [`SUM(${total.amountColumn})`, total.amountColumn].includes(r.fieldName));
                    const count = util.find(row.columns, r => ['COUNT(*)', 'ACCOUNTS_COUNT', 'COUNT(ACCOUNTFILTER)'].includes(r.fieldName));
                    totalsByCurrency[`${key}Label`] = amountColumn.displayName;
                    totalsByCurrency[`${key}Amount`] = util.isEmpty(amount.fieldValue) ? Formatter.formatNumber(0) : amount.fieldValue;
                    totalsByCurrency.count = util.isEmpty(count) ? 0 : Number(count.fieldValue);
                })
                .value();
            return totalsByCurrency;
        });
        return ({ currencyTotals, filters: this.get('filters') });
    },
});

export default AccountTotals;
