import ListView from 'common/dynamicPages/views/workflow/list';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import constants from 'common/dynamicPages/api/constants';
import { MODE, FOOTERS_CONTEXT } from 'components/AlertContentManagement/constants';
import locale from '@glu/locale';
import template from './alerts.hbs';

const EXPORT_INQUIRY_ID = 24445;

const Footers = ListView.extend({
    template,
    events: util.extend({}, ListView.prototype.events, {
        'click [data-hook="print-button"]': 'showPrintOptionsModal',
    }),
    initialize(options) {
        const superOptions = {
            serviceName: 'alertContentManagement/footers',
            context: 'CMAINT_TMPALERT',
            configContext: FOOTERS_CONTEXT,
        };
        ListView.prototype.initialize.call(this, {
            ...superOptions,
            ...options,
        });
        this.contextDef = {
            ...this.contextDef,
            ...FOOTERS_CONTEXT,
        };
    },
    /**
     * Navigate to the insert route
     */
    insert() {
        this.navigateTo(`CMAINT/alertContentManagement/footer/${MODE.INSERT}`);
    },
    /**
     * Handle view action
     * @param {object} options
     */
    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(`CMAINT/alertContentManagement/footer/${MODE.VIEW}`);
    },
    /**
     * Handle modify action
     * @param  {object} item Model associated with item choosen for modification in view
     */
    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(`CMAINT/alertContentManagement/footer/${MODE.MODIFY}`);
    },
    /**
     * Handle custom row actions
     * @param {Object} options
     * @param {string} options.action
     * @param {Model} options.model
     */
    gridRowCustomAction({ action, model }) {
        if (action === 'copyfooter') {
            store.set(`${this.contextKey}-actionModel`, model);
            this.navigateTo(`CMAINT/alertContentManagement/footer/${MODE.COPY}`);
        }
    },
    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_24445,
            summaryInquiryId: constants.INQUIRY_ID_24445,
        };
    },

    /**
     * Call the prototype export function passing in an inquiryId
     */
    export() {
        ListView.prototype.export.call(this, {
            inquiryId: EXPORT_INQUIRY_ID,
        });
    },

    templateHelpers() {
        return {
            ...ListView.prototype.templateHelpers.call(this),
            insertLabel: locale.get('CMAINT.Customize.Footer.Content'),
        };
    },
});
export default Footers;
