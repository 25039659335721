import $ from 'jquery';
import util from '@glu/core/src/util';
import comboUtil from 'common/util/comboUtil';
import { appBus } from '@glu/core';

export default {
    /**
     * Returns an F1 field object based on the jQuery field and form passed in.
     *
     * @param $field {Object} A jQuery object of a field within the F1 form.
     * @param form {Object} The F1 form which to add logic against.
     * @returns {object} field
     */
    getField($field, form) {
        const { formView } = form;

        function getContainer() {
            return $field.parents('.field-container').first();
        }

        function getFieldBlock() {
            return $field.parents('fieldset').first();
        }

        return {
            // The jQuery representation of the field for any view manipulation needs.
            $el: $field,

            /**
             * Returns true if the value of a visible field matches an expected value.
             *
             * @param {string|number} expected The value to test against.
             * @returns {boolean}
             */
            equalTo(expected) {
                return this.isVisible() && this.hasValue(expected);
            },

            /**
             * Return the current value of the field.
             * @returns {string}
             */
            getValue() {
                return $field.val();
            },

            /**
             * Check the value of a field, regardless of visibility.
             *
             * @param {string|number} expected The value to test against.
             * @returns {boolean}
             */
            hasValue(expected) {
                return $field.val() === expected;
            },

            /**
             * Check to see if the 'checked' property exists on the field.
             * @returns {boolean}
             */
            isChecked() {
                return $field.prop('checked');
            },

            /**
             * Check to see if the 'disabled' property exists on the field.
             * @returns {boolean}
             */
            isDisabled() {
                return $field.prop('disabled');
            },

            /**
             * Checks to see if the value of a field is considered to be empty.
             * @returns {boolean}
             */
            isEmpty() {
                return util.isEmpty($field.val());
            },

            /**
             * Checks to see if the value of the field is NOT considered to be empty.
             * @returns {boolean}
             */
            isNotEmpty() {
                return !this.isEmpty();
            },

            /**
             * Checks to see if the field is currently "hidden".
             * @returns {boolean}
             */
            isNotVisible() {
                return $field.is(':hidden');
            },

            /**
             * Checks to see if the field is currently "visible".
             * @returns {boolean}
             */
            isVisible() {
                return $field.is(':visible');
            },

            /**
             * Checks to see if the field is currently "readonly".
             * @returns {boolean}
             */
            isReadOnly() {
                return $field.prop('readonly');
            },

            /**
             * Returns true if the value of a visible field DOES NOT match an expected value.
             *
             * @param {string|number} expected The value to test against.
             * @returns {boolean}
             */
            notEqualTo(expected) {
                return !this.equalTo(expected);
            },

            /**
             * Returns true if the value of a visible field DOES NOT match one of the
             * supplied values.
             *
             * @param possibleValues {Array} An array of values to test against.
             * @returns {boolean}
             */
            notOneOf(possibleValues) {
                return !this.oneOf(possibleValues);
            },

            /**
             * Returns true if the value of a visible field matches one of the supplied values.
             *
             * @param possibleValues {Array} An array of values to test against.
             * @returns {boolean}
             */
            oneOf(possibleValues) {
                return this.isVisible() && util.indexOf(possibleValues, this.getValue()) !== -1;
            },

            /**
             * Removes a validator from the form view's validators based on the validator key.
             * Be sure this is actually what you desire, in some cases you may just want
             * to inver the value
             * which is passed into setValidator().
             *
             * @param {String} validator - The Glu validator key to remove.
             */
            removeValidator(validator) {
                if (!formView.model.validators) {
                    return this;
                }
                const fieldName = this.$el.attr('name');

                if (!fieldName) {
                    return this;
                }

                formView.model.removeValidatorProp(fieldName, validator);

                return this;
            },

            /**
             * Shortcut to jQuery.prop(name, value). Sets the value of a property on a field.
             *
             * @param propertyName {String} The name of the property to set.
             * @param value {Boolean} Whether or not to set the property.
             */
            setProperty(propertyName, value) {
                $field.prop(propertyName, value);

                return this;
            },

            /**
             * Adds or overrides a validator on the form view's
             * validators object based on validator key.
             *
             * @param $infield {Object} The jQuery object representation of the field.
             * @param validator {String} The Glu validator key to add.
             * @param value {Object} The value the Glu validator expects when testing.
             * @param options {Object} optional object to pass to addValidator
             */
            setValidator($infield, validator, value, options = {}) {
                if (!formView.model.validators) {
                    formView.model.validators = {};
                }

                const fieldName = $infield.attr('name');
                const field = formView.model
                && formView.model.jsonData
                && formView.model.jsonData.fieldInfoList
                && formView.model.jsonData.fieldInfoList.find(item => item.name === fieldName);
                const modelFieldLabel = field ? field.fieldLabel : '';
                const fieldLabel = formView.$(`label[for=${fieldName}]`).text() || modelFieldLabel;
                const fieldValidators = formView.model.validators[fieldName] || {};

                if (!fieldName) {
                    return;
                }

                fieldValidators[validator] = value;
                fieldValidators.description = fieldLabel;
                if (validator === 'sameValue' || validator === 'notSameValue') {
                    fieldValidators.otherDescription = formView.$(`label[for=${value}]`).text();
                }
                formView.model.addValidator(fieldName, fieldValidators, options);
            },

            /**
             * Sets the value of a field to a supplied value.
             *
             * @param value {String|Integer} The value to give the field.
             */
            setValue(value) {
                $field.val(value);

                return this;
            },

            /**
             * @method hideFieldBlockHeader
             * Hide's the field's associated block header,
             * so it won't show if its containing fields are hidden
             */
            hideFieldBlockHeader() {
                getFieldBlock().find('.section-header').first().hide();
            },

            /**
             * Sets the value of a combobox to a supplied value.
             *
             * @param {string|number} value - The value to give to the select2 field.
             */
            setComboBoxValue(value) {
                $field.select2('val', value);

                return this;
            },

            /**
             * Sets an equalTo Glu validator on the view's validators.
             *
             * @param {String|number|boolean} expectedValue - The value the field should match.
             */
            shouldBeEqualTo(expectedValue) {
                this.setValidator($field, 'equalTo', expectedValue);

                return this;
            },

            /**
             * Sets the Glu validator on the field to ensure that the value begins with
             * the provided pattern.
             *
             * @param {string|number} pattern - The pattern the field value must begin with.
             */
            shouldBeginWith(pattern) {
                this.setValidator($field, 'beginsWith', pattern);

                return this;
            },

            /**
             * Hides the field's parent, expecting its parent to be a
             * field group container which holds the label, field,
             * and any error container for the field.
             */
            shouldBeHidden() {
                getContainer().hide();
                getContainer().addClass('hidden');

                return this;
            },

            /**
             * Sets the Glu validator on the field to ensure the value is numeric.
             */
            shouldBeNumeric() {
                this.setValidator($field, 'isNumeric', true);

                return this;
            },

            /**
             * @param {boolean} [setContainerOptional]
             * Sets the field to "optional", AKA not required. The Glu validator for
             * this should be removed, as if Glu sees the `exists` key it will attempt
             * validation and fail.
             */
            shouldBeOptional(setContainerOptional) {
                this.removeValidator('exists');

                if ($field.prop('required')) {
                    $field.prop('required', false);
                }
                if (setContainerOptional) {
                    getContainer().removeClass('required');
                }

                this.resetValidationState();

                return this;
            },

            resetValidationState() {
                const fieldName = $field.prop('name');
                // clear old validation state on ui
                getContainer().removeClass('has-error');
                getContainer().find('.help-block').text('');
                // re-validate field
                formView.model.validateField(fieldName);
            },

            /**
             * @param {boolean} [setContainerRequired] optional defaults to
             * false.  if true sets the parent containers class to required
             * Sets the field to required and adds a Glu validator to the view's validators.
             * @param {boolean} silent optional default to false
             * if true, pass {silent: true} to setValidator
             */
            shouldBeRequired(setContainerRequired = false, silent = false) {
                const fieldName = $field.prop('name');
                let waitUntilReady = false;
                let fieldUIType;

                if (formView.model.fieldData[fieldName]) {
                    ({ fieldUIType } = formView.model.fieldData[fieldName]);
                }

                $field.prop('required', true);

                if (setContainerRequired) {
                    getContainer().addClass('required');
                }

                /**
                 *  if this is a combo see if the first item should be selected
                 *  first make sure that this is a meta data field
                 */
                if (fieldName && formView.model.fieldData[fieldName]
                    && (fieldUIType === 'COMBOBOX' || fieldUIType === 'COMBOBOXWIDGET')) {
                    if (fieldUIType === 'COMBOBOXWIDGET') {
                        if (formView.allWidgets !== undefined && formView.allWidgets.length) {
                            const thisWidget = util.find(
                                formView.allWidgets,
                                widget => widget.fieldName === fieldName,
                            );
                            if (thisWidget) {
                                waitUntilReady = true;
                                // Wait until after it loads
                                thisWidget.isReadyToRender().then(() => {
                                    comboUtil.selectIfOnlyOne(formView, fieldName, true);
                                });
                            }
                        }
                    }
                    if (!waitUntilReady) {
                        comboUtil.selectIfOnlyOne(formView, fieldName, true);
                    }
                }
                let opts = {};
                if (silent) {
                    opts = { silent: true };
                }
                this.setValidator($field, 'exists', true, opts);

                return this;
            },

            /**
             * Sets the field to required or optional based on a condition. Typically, a
             * developer would execute a method which does business logic and returns a
             * boolean to use as the condition.
             *
             * @param {boolean} condition - If true, sets the field to required.
             * @param {boolean} setContainerRequired - optional default to false - sets
             * the container required (adds *) if true
             * @param {boolean} silent = optional, default to false.
             * this is used in shouldBeRequired
             */
            shouldBeRequiredWhen(condition, setContainerRequired = false, silent = false) {
                if (condition) {
                    this.shouldBeRequired(setContainerRequired, silent);
                } else {
                    this.shouldBeOptional(setContainerRequired);
                }

                return this;
            },

            /**
             * Sets the Glu validator on the field to check that the field has a unique
             * value compared to all
             * other fields in the form.
             */
            shouldBeUnique() {
                this.setValidator($field, 'isUnique', true);

                return this;
            },

            /**
             * Shows the field's parent, expecting its parent ot be a field group container
             * which holds the label, field, and any error container for the field.
             */
            shouldBeVisible() {
                getContainer().show();
                getContainer().removeClass('hidden');

                return this;
            },

            /**
             * Adds class(es) to field passed in
             * @param c {string} - class or multiple classes seperated by spaces
             */
            addClassToEl(c) {
                $field.addClass(c);

                return this;
            },

            /**
             * Sets the field to visible or hidden based on a condition. Typically, a
             * developer would
             * execute a method which does business logic and returns a boolean to use as
             * the condition.
             *
             * @param condition {boolean} If true, shows the field.
             */
            shouldBeVisibleWhen(condition) {
                if (condition) {
                    this.shouldBeVisible();
                } else {
                    this.shouldBeHidden();
                }

                return this;
            },

            /**
             * Protects the field's parent, expecting its parent to be a field group container
             * which holds the label, field, and any error container for the field.
             *
             * @param isReadOnly If true, protects the field.
             */
            shouldBeReadOnly(isReadOnly) {
                let localIsReadOnly = isReadOnly;
                if ($field.length < 1) {
                    return this;
                }
                const fieldObj = formView.model.fieldData[$field.attr('name')];
                const { context } = formView.model;
                let { functionCode } = context;
                if (functionCode === undefined && !util.isNullOrUndefined(context.actionData)) {
                    ({ functionCode } = context.actionData);
                } else if (functionCode === undefined && context.actionContext !== undefined) {
                    ({ functionCode } = context.actionContext);
                }
                /*
                 * if lockable, and locked, then read only, if lockable and unlocked, then
                 * not read only.. override the incoming param only if lockable
                 */
                if ((functionCode !== 'BHTMPL' && functionCode !== 'TMPL') && fieldObj && fieldObj.lockable) {
                    /*
                     * only if field has lockable property and lockable is
                     * true, do we override what was passed in
                     */
                    localIsReadOnly = !!fieldObj.locked;
                }

                $field.prop('readonly', localIsReadOnly).toggleClass('read-only-field', localIsReadOnly);

                // Checkboxes don't take a readonly state, but instead must be disabled.
                if ($field.attr('type') === 'checkbox') {
                    $field.prop('disabled', localIsReadOnly);
                }

                return this;
            },

            /**
             * Sets the field to readonly based on a condition. Typically, a developer would
             * execute a method which does business logic and returns a boolean to use as
             * the condition.
             *
             * @param condition {boolean} If true, protect the field.
             */
            shouldBeReadOnlyWhen(condition) {
                let isReadOnly = condition;
                if ($field.length < 1) {
                    return this;
                }
                const fieldObj = formView.model.fieldData[$field.attr('name')];
                const { context } = formView.model;
                let { functionCode } = context;
                if (functionCode === undefined && context.actionData !== undefined) {
                    ({ functionCode } = context.actionData);
                } else if (functionCode === undefined && context.actionContext !== undefined) {
                    ({ functionCode } = context.actionContext);
                }
                /*
                 * if lockable, and locked, then read only, if lockable and unlocked, then
                 * not read only.. override the incoming param only if lockable
                 */
                if ((functionCode !== 'BHTMPL' && functionCode !== 'TMPL') && fieldObj && fieldObj.lockable) {
                    /*
                     * only if field has lockable property and lockable is
                     * true, do we override what was passed in
                     */
                    isReadOnly = !!fieldObj.locked;
                }

                if (isReadOnly) {
                    $field.prop('readonly', isReadOnly);
                    $field.addClass('read-only-field');
                } else {
                    $field.prop('readonly', isReadOnly);
                    $field.removeClass('read-only-field');
                }
                return this;
            },

            /**
             * Sets the Glu validator on the field to ensure that the value contains the
             * provided pattern.
             *
             * @param {string|number} pattern - The pattern the field must contain within
             * its value.
             */
            shouldContain(pattern) {
                this.setValidator($field, 'contains', pattern);

                return this;
            },

            /**
             * Sets the Glu validator on the field to ensure that the value ends with the
             * provided pattern.
             *
             * @param {string|number} pattern - The pattern the field value must end with.
             */
            shouldEndWith(pattern) {
                this.setValidator($field, 'endsWith', pattern);

                return this;
            },

            /**
             * Sets the Glu validator on the field to have a maxLength.
             *
             * @param {number} maxLength - The maximum number of characters allowed in
             * the field.
             */
            shouldHaveMaxLength(maxLength) {
                this.setValidator($field, 'maxLength', maxLength);

                return this;
            },

            /**
             * Sets the Glu validator on the field to have a minLength.
             *
             * @param {number} minLength - The minimum number of characters allowed in
             * the field.
             */
            shouldHaveMinLength(minLength) {
                this.setValidator($field, 'minLength', minLength);

                return this;
            },

            /**
             * Sets the Glu validator on the field, expecting the field to be numeric, to have a
             * maximum value.
             *
             * @param {number} maxValue - The highest number/amount allowed in the field.
             */
            shouldHaveMaxValue(maxValue) {
                this.setValidator($field, 'maxValue', maxValue);

                return this;
            },

            /**
             * Sets the Glu validator on the field, expecting the field to be numeric, to have a
             * minimum value.
             *
             * @param {number} minValue - The lower number/amount allowed in the field.
             */
            shouldHaveMinValue(minValue) {
                this.setValidator($field, 'minValue', minValue);

                return this;
            },

            /**
             * Sets the Glu validator on the field to have a rangeLength, forcing the length to
             * between two
             * numeric points.
             *
             * @param {number} minLength - The minimum length of the field.
             * @param {number} maxLength - The maximum length of the field.
             */
            shouldHaveRangeLength(minLength, maxLength) {
                this.setValidator($field, 'rangeLength', [minLength, maxLength]);

                return this;
            },

            /**
             * Exact length validation
             */
            shouldHaveExactLength(exactLength) {
                this.setValidator($field, 'exactLength', exactLength);
                return this;
            },

            /**
             * Sets the Glu validator on the field to have a range, forcing the value to
             * between two
             * numeric points.
             *
             * @param {number} minValue - The minimum value of the field.
             * @param {number} maxValue - The maximum value of the field.
             */
            shouldHaveRangeValue(minValue, maxValue) {
                this.setValidator($field, 'range', [minValue, maxValue]);

                return this;
            },

            /**
             * Sets the Glu validator on the field to indicate it must match another field.
             *
             * @param field {field} The F1 Field object of the field the current field
             * must match.
             */
            shouldMatchField(field) {
                this.setValidator($field, 'sameValue', field.$el.attr('name'));

                return this;
            },

            /**
             * Sets the Glu validator on the field to match against a regular expression.
             *
             * @param pattern {Pattern|String} A regular expression pattern.
             */
            shouldMatchPattern(pattern) {
                this.setValidator($field, 'matches', pattern);

                return this;
            },

            /**
             * Sets the Glu validator on the field to match against a regular expression.
             */
            shouldMatchAlphaNumeric() {
                this.setValidator($field, 'matchesAlphaNumeric');

                return this;
            },

            /**
             * Sets the Glu validator on the field to indicate it must NOT match another field.
             *
             * @param field {field} The F1 Field object of the field the current field
             * must NOT match.
             */
            shouldNotMatchField(field) {
                this.setValidator($field, 'notSameValue', field.$el.attr('name'));

                return this;
            },
        };
    },

    /**
     * Returns an F1 Fieldset object based on the jQuery-compatible selector
     * passed in.
     *
     * @param $fieldset {Object} A jQuery object of a fieldset within the F1 form.
     */
    getFieldset($fieldset) {
        return {
            shouldBeHidden() {
                $fieldset.hide();
            },

            shouldBeVisible() {
                $fieldset.show();
            },

            shouldBeVisibleWhen(condition) {
                if (condition) {
                    this.shouldBeVisible();
                } else {
                    this.shouldBeHidden();
                }
            },
        };
    },

    /**
     * Returns a F1 Form object based on the jQuery-compatible selector passed in.
     *
     * @param formView {Object} A Backbone View which represents the form to
     * apply F1 to.
     * @returns {object} form
     */
    getForm(formView) {
        const self = this;
        const $form = formView.$el;

        const form = {
            // The supplied Form View.
            formView,

            // All policies applied to the form.
            policies: [],

            // Array of F1 Fields, representative of the fields in the form.
            fields: [],

            // Array of the F1 Fieldsets, representative of the fieldsets in the form.
            fieldsets: [],

            /**
             * Adds a policy to the F1 form and then
             * executes all policies unless otherwise told.
             *
             * @param fn {Function} The policy to apply.
             */
            addPolicy(fn) {
                if (!fn || typeof fn !== 'function') {
                    return this;
                }

                // Push the policy
                form.policies.push(fn);

                return form;
            },

            /**
             * Applies all policies on the F1 form.
             * @param {boolean} [initialState] whether the policy is being applied for
             * the first time
             * @returns {object} form
             */
            applyPolicies(initialState) {
                // Execute the each policy down the policies stack.
                util.each(form.policies, (policy) => {
                    policy.call(this, form, initialState);
                });
                appBus.trigger('policies:applied', initialState);
                return form;
            },

            /**
             * Clears all policies from the F1 form.
             * @returns {object} form
             */
            clearPolicies() {
                form.policies = [];
                return form;
            },

            /**
             * Retrieves a field from our F1 Fields list.
             * @param {string} name The name of the field to return.
             * @returns {object} field
             */
            field(name) {
                return form.fields[name] || self.getField(formView.$(`[name="${name}"]`), form);
            },

            /**
             * Retrieves a fieldset from our F1 Fieldsets list.
             *
             * @param {string} name The name of the fieldset to return.
             * @returns {object} fieldset
             */
            fieldset(name) {
                return form.fieldsets[name] || self.getFieldset($(`[name="${name}"]`));
            },

            /**
             * Empties all fields from the F1 form and adds all fields currently within
             * the form view.
             * @returns {object} form
             */
            refreshFields() {
                // Set the current fields stack to empty.
                form.fields = [];

                // Iterate over fields within the form view.
                $form.find('input, select, button.btn.btn-link').each(function () {
                    const $field = $(this);
                    const fieldName = $field.attr('name');

                    // If there is no name on the field, continue to the next field.
                    if (!fieldName) {
                        return;
                    }

                    // Create a F1 Field object and store it in an array for later.
                    form.fields[fieldName] = self.getField($field, form);

                    // Bind a change event to the field which re-applies the policies.
                    $field.on('change', () => {
                        form.applyPolicies(false);
                    });
                });

                return form;
            },

            /**
             *
             * @param {string} id
             * @return {{formView: Object, policies: Array, fields: Array, fieldsets: Array,
             * addPolicy: addPolicy, applyPolicies: applyPolicies, clearPolicies: clearPolicies,
             * field: field, fieldset: fieldset, refreshFields: refreshFields,
             * refreshField: refreshField, refreshFieldsets: refreshFieldsets}}
             */
            refreshField(id) {
                $form.find(id).each(function () {
                    const $field = $(this);
                    const fieldName = $field.attr('name');

                    // If there is no name on the field, exit
                    if (!fieldName) {
                        return;
                    }

                    // Bind a change event to the field which re-applies the policies.
                    $field.on('change', () => {
                        form.applyPolicies(false);
                    });
                });

                return form;
            },

            /**
             * Empties all fieldsets from the F1 form and adds all fieldsets currently
             * within the
             * form view.
             * @returns {object} form
             */
            refreshFieldsets() {
                // Set the current fieldsets stack to empty.
                form.fieldsets = [];

                // Iterate over fieldsets and store them as Fieldsets for later use.
                $form.find('fieldset').each(function () {
                    const $fieldset = $(this);
                    const fieldId = $fieldset.attr('id');

                    // Create a F1 Fieldset object and store it in an array for later.
                    form.fieldsets[fieldId] = self.getFieldset(fieldId);
                });

                return form;
            },
        };

        // Set the fields and fieldsets with the initialization of the form.
        form.refreshFields().refreshFieldsets();

        return form;
    },
};
