import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import $ from 'jquery';
import moment from 'moment';
import elementResizeWatcher from 'common/util/elementResizeWatcher';
import 'jui/slider';
import 'jui/draggable';
import dateRangeScrubberTmpl from './dateRangeScrubber.hbs';

const WIDTH_CHECK_INTERVAL = 100;

/*
 * TODO this distance should be calculated based on current width of slider/total value
 * range represented by the slider to remain a constant step
 * should users be able to configure the step value?
 */
const KEY_WHITELIST = [
    $.ui.keyCode.LEFT,
    $.ui.keyCode.RIGHT,
    $.ui.keyCode.HOME,
    $.ui.keyCode.END,
    $.ui.keyCode.PAGE_DOWN,
    $.ui.keyCode.PAGE_UP,
];

const DateRangeScrubber = ItemView.extend({
    initialize() {
        this.today = moment(new Date());
        this.width = 0;
        this.keyDown = false;
    },

    getPixelDistanceForStep() {
        const step = 1;
        const rangeLength = this.model.get('max') - this.model.get('min');
        const stepCount = rangeLength / step;

        return (this.width / stepCount);
    },

    modelEvents: {
        'change:daysBack change:daysForward': 'syncSliderWithModel',
        dateUpdateSlide: 'updateAriaAttributes',
        'change:min change:max': 'updateSliderScale',
    },

    className: 'range-selector',
    template: dateRangeScrubberTmpl,

    ui: {
        $slider: '.range-selector-control',
        $fill: '.ui-slider-range',
        $lowerHandle: '.lower-handle',
        $upperHandle: '.upper-handle',
    },

    events: {
        'slidestart @ui.$slider': 'handleSlideStart',
        'slide @ui.$slider': 'handleSlide',
        'click @ui.$slider': 'syncModelWithSlider',
        dragstart: 'handleDragStart',
        'drag .ui-slider-handle': 'handleHandleDrag',
        'drag @ui.$fill': 'handleFillDrag',
        dragstop: 'syncModelWithSlider',
        'keydown .range-selector-control .ui-draggable': 'handleKeyDown',
        'keyup .range-selector-control .ui-draggable': 'handleKeyUp',
        'elementResized @ui.$slider': 'updateOnResize',
    },

    handleKeyUp: util.debounce(function (e) {
        if (KEY_WHITELIST.indexOf(e.which) === -1) {
            return;
        }

        // does not matter which key was released
        this.keyDown = false;
        this.syncModelWithSlider();
    }, 250),

    handleKeyDown(e) {
        // ignore other keys
        if (KEY_WHITELIST.indexOf(e.which) === -1) {
            return;
        }

        // stop page down/up, home, end from jumping around
        e.preventDefault();

        const $target = this.$(e.currentTarget);
        let desiredPosition = 0;
        let stepDistance = this.getPixelDistanceForStep();

        /*
         * shift+arrow should move faster
         * shift+page up/down should be hyperspeed
         */
        if (e.shiftKey) {
            stepDistance *= 10;
        }

        switch (e.which) {
        case $.ui.keyCode.LEFT:
            desiredPosition = $target.position().left - stepDistance;
            break;
        case $.ui.keyCode.RIGHT:
            desiredPosition = $target.position().left + stepDistance;
            break;
        case $.ui.keyCode.HOME:
            desiredPosition = 0;
            break;
        case $.ui.keyCode.END:
            desiredPosition = this.width;
            break;
        case $.ui.keyCode.PAGE_DOWN:
            desiredPosition = $target.position().left - (stepDistance * 10);
            break;
        case $.ui.keyCode.PAGE_UP:
            desiredPosition = $target.position().left + (stepDistance * 10);
            break;
        default:
        }

        // dragstart if this is the first press
        if (!this.keyDown) {
            this.handleDragStart();
        }
        this.keyDown = true;

        if ($target.is(this.ui.$fill)) {
            // check that it will be in bounds when it moves
            if (desiredPosition < 0) {
                desiredPosition = 0;
            } else if (desiredPosition + this.ui.$fill.width() > this.ui.$slider.width()) {
                desiredPosition = this.ui.$slider.width() - this.ui.$fill.width();
            }

            this.ui.$fill.css({
                left: desiredPosition,
            });

            this.handleFillDrag();
        } else {
            // moved a handle
            $target.css({
                left: this.clipHandlePosition($target, desiredPosition),
            });
            this.updateFill();
            // this should run after new positions have been set
            this.fireUpdateEvent();
        }
    },

    fireUpdateEvent() {
        const lowValue = this.getValueFromOffset(this.ui.$lowerHandle.position().left);
        const highValue = this.getValueFromOffset(this.ui.$upperHandle.position().left);
        this.model.trigger('dateUpdateSlide', [lowValue, highValue]);
    },

    handleDragStart() {
        this.model.trigger('dateUpdateStart');
    },

    clipHandlePosition($handle, leftPosition) {
        const parentWidth = this.ui.$slider.width();
        let allowedPosition = leftPosition;

        if ($handle.is('.lower-handle')) {
            if (leftPosition >= this.ui.$upperHandle.position().left) {
                allowedPosition = this.ui.$upperHandle.position().left;
            } else if (leftPosition <= 0) {
                allowedPosition = 0;
            }
        } else if (leftPosition <= this.ui.$lowerHandle.position().left) {
            allowedPosition = this.ui.$lowerHandle.position().left;
        } else if (leftPosition >= parentWidth) {
            allowedPosition = parentWidth;
        }

        return allowedPosition;
    },

    handleHandleDrag(e, uiParam) {
        const ui = uiParam;
        ui.position.left = this.clipHandlePosition(ui.helper, ui.position.left);
        this.updateFill();
        this.fireUpdateEvent();
    },

    updateFill() {
        // adjust scrubber length
        const leftPosition = this.ui.$lowerHandle.position().left;

        const width = this.ui.$upperHandle.position().left - leftPosition;

        this.ui.$fill.css({
            width,
            left: leftPosition,
        });
    },

    handleFillDrag() {
        // adjust drag points on the end of the scrubber bar

        const leftPosition = this.ui.$fill.position().left;
        this.ui.$lowerHandle.css({
            left: leftPosition,
        });

        this.ui.$upperHandle.css({
            left: leftPosition + this.ui.$fill.width(),
        });

        this.fireUpdateEvent();
    },

    onShow() {
        this.ui.$fill.draggable({
            containment: 'parent',
        });

        this.ui.$lowerHandle.draggable({
            axis: 'x',
        });

        this.ui.$upperHandle.draggable({
            axis: 'x',
        });

        this.syncSliderWithModel();

        // needs to handle window resizing & style changes on page load
        elementResizeWatcher.triggerEventOnElementResize(this.ui.$slider);
    },

    getOffsetFromValue(value) {
        const total = Math.abs(this.model.get('max') - this.model.get('min'));
        const width = this.ui.$slider.width();
        const numberOffset = Math.abs(value - this.model.get('min'));
        const leftOffset = (numberOffset / total) * width;

        return leftOffset;
    },

    getValueFromOffset(offset) {
        const total = Math.abs(this.model.get('max') - this.model.get('min'));
        const width = this.ui.$slider.width();
        const sliderOffsetProportion = offset / width;
        const numberOffset = Math.abs(total * sliderOffsetProportion);
        const value = numberOffset + this.model.get('min');

        /*
         * TODO round here based on step
         * for now, use step value of 1
         */
        return Math.round(value);
    },

    syncSliderWithModel() {
        const lowOffset = this.getOffsetFromValue(this.model.get('daysBack'));
        const highOffset = this.getOffsetFromValue(this.model.get('daysForward'));

        this.ui.$lowerHandle.css('left', lowOffset);
        this.ui.$upperHandle.css('left', highOffset);
        this.updateFill();

        this.updateAriaAttributes(this.model.get('daysBack'), this.model.get('daysForward'));
    },

    updateSliderScale() {
        this.$('.beginning').text(`${this.model.get('min')} days`);
        this.$('.end').text(`${this.model.get('max')} days`);

        const todayPercentOffset = 100 * (Math.abs(this.model.get('min')) / (this.model.get('max') - this.model.get('min')));

        this.$('.today').css({
            left: `${todayPercentOffset}%`,
        });

        this.syncSliderWithModel();
    },

    updateOnResize: util.debounce(function () {
        this.syncSliderWithModel();
        // needs to catch the second repeat
    }, WIDTH_CHECK_INTERVAL + 10),

    syncModelWithSlider() {
        const lowValue = this.getValueFromOffset(this.ui.$lowerHandle.position().left);
        const highValue = this.getValueFromOffset(this.ui.$upperHandle.position().left);

        this.model.setRange(lowValue, highValue);
        this.model.trigger('dateUpdateFinish');
    },

    templateHelpers() {
        return {
            maxPastOffset: this.model.get('min'),
            maxFutureOffset: this.model.get('max'),
            id: this.cid,
        };
    },

    updateAriaAttributes(...args) {
        let upperValue;
        let lowerValue;

        /*
         * drag event has values in an array ([lower, upper])
         * direct calls to this function should have 2 args (lower, upper)
         */
        if (args.length === 2) {
            [lowerValue, upperValue] = args;
        } else if (args.length === 1
            && util.isArray(args[0])
            && args[0].length === 2) {
            [[lowerValue, upperValue]] = args;
        } else {
            return;
        }

        this.ui.$lowerHandle.attr({
            'aria-valuemin': this.model.get('min'),
            'aria-valuemax': upperValue,
            'aria-valuenow': lowerValue,
        });

        this.ui.$upperHandle.attr({
            'aria-valuemin': lowerValue,
            'aria-valuemax': this.model.get('max'),
            'aria-valuenow': upperValue,
        });
    },
});

export default DateRangeScrubber;
