import Collection from '@glu/core/src/collection';
import Controller from '@glu/core/src/controller';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import services from 'services';
import transform from 'common/util/transform';
import TextboxCell from 'app/setup/views/fileImportMap/textboxCell';
import CheckboxCell from 'common/grid/cellviews/checkboxCell';
import appConfigParams from 'system/webseries/models/applicationConfiguration';

const constants = {
    FIELD: {
        READONLY: 0,
        HIDDEN: 11,
        TEXTBOX: 13,
        CHECKBOX: 17,
        CHECKBOX_READONLY: 30,
    },
};

let gridHeaderJsonData = null;

const Columns = Collection.extend({
    sync(method, model, options) {
        options.success(this.jsonData);
    },

    parse() {
        const retAr = [];
        gridHeaderJsonData = this.jsonData;
        util.each(this.jsonData, (col) => {
            const column = {};
            column.field = col.fieldName;
            column.title = col.displayName;
            column.sortable = false;
            column.filterable = false;
            column.disableRemove = true;
            column.displayOrder = col.displayOrder;

            if (col.type === constants.FIELD.READONLY) {
                column.type = 'string';
            } else if (col.type === constants.FIELD.TEXTBOX) {
                column.type = TextboxCell;
            } else if (col.type === constants.FIELD.CHECKBOX) {
                column.type = CheckboxCell;
                column.width = 125;
            } else if (col.type === constants.FIELD.CHECKBOX_READONLY) {
                column.type = 'string';
            }

            if (col.type !== constants.FIELD.HIDDEN) {
                retAr.push(column);
            }
        });

        return util.sortBy(retAr, column => column.displayOrder, this);
    },
});

const Rows = Collection.extend({

    initialize(options) {
        this.options = options;
        // Possible real-time payment configurations
        this.requireBeneAddress = appConfigParams.getValue('RTP', 'REQUIREBENEADDRESS') === '1';
    },

    sync(method, model, options) {
        options.success(this.jsonData);
    },

    parse() {
        let activeIsText = false;
        let matchIsText = false;
        util.each(gridHeaderJsonData, (headerCell) => {
            if (headerCell.fieldName === 'ACTIVE' && (headerCell.fieldType === 'text' || headerCell.fieldType === 'readonlycheckbox')) {
                activeIsText = true;
            } else if (headerCell.fieldName === 'MATCH' && (headerCell.fieldType === 'text' || headerCell.fieldType === 'readonlycheckbox')) {
                matchIsText = true;
            }
        });
        const retAr = [];
        util.each(this.jsonData, (row) => {
            let obj = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');

            if (matchIsText) {
                if (obj.MATCH === '1') {
                    obj.MATCH = locale.get('common.yes');
                } else {
                    obj.MATCH = locale.get('common.no');
                }
            }
            if (activeIsText) {
                if (obj.ACTIVE === '1') {
                    obj.ACTIVE = locale.get('common.yes');
                } else {
                    obj.ACTIVE = locale.get('common.no');
                }
            }
            // Only for real-time payments
            if (this.options.paymentType === 'CRTRAN') {
                obj = {
                    ...obj,
                    ...this.setMandatoryRTPRows(obj),
                };
            }
            retAr.push(obj);
        });
        return retAr;
    },

    setMandatoryRTPRows(obj) {
        let attributes = {};
        if (this.requireBeneAddress) {
            const beneFields = [
                'BENE_ADDRESS',
                'BENE_CITY',
                'Bene_State',
                'BENE_COUNTRY',
                'BENE_POSTALCODE',
            ];
            if (beneFields.includes(obj.COLUMNNAME)) {
                attributes = {
                    ...attributes,
                    MANDATORY: '1',
                };
            }
        }
        return attributes;
    },
});

export default Controller.extend({
    initialize(options) {
        this.columns = new Columns();
        this.rows = new Rows(options);
        this.options = options;
    },

    fetch(options) {
        const self = this;

        const data = {
            requestParameters: {
                item: [{
                    name: 'PAYMENTTYPE',
                    value: (this.options.paymentType ? this.options.paymentType : ''),
                }, {
                    name: 'MAPTYPE',
                    value: (this.options.mapType ? this.options.mapType : ''),
                }, {
                    name: 'RECORD_ID',
                    value: (this.options.id ? this.options.id : ''),
                }, {
                    name: 'PAYSUBTYPE',
                    value: (this.options.paySubType ? this.options.paySubType : '*'),
                }, {
                    name: 'TAXAUTH',
                    value: (this.options.taxAuth ? this.options.taxAuth : ''),
                }, {
                    name: 'PAYFUNCTION',
                    value: (this.options.paymentFunction ? this.options.paymentFunction : 'INST'),
                }, {
                    name: 'CSAGENCY',
                    value: (this.options.csAgency ? this.options.csAgency : ''),
                }, {
                    name: 'ADDENDAFORMAT',
                    value: (this.options.addendaFormat ? this.options.addendaFormat : ''),
                }],
            },

            action: this.options.mode,
            startRow: 0,
            rowsPerPage: 0,
        };
        const url = services.generateUrl('tableMaintenance/importMap/getImportDetailGridData');
        http.post(url, data, (response) => {
            self.columns.jsonData = response.rowHeader;
            self.rows.jsonData = response.rows;
            self.columns.fetch({
                success() {
                    self.rows.fetch({
                        success() {
                            options.success(response);
                        },
                    });
                },
            });
        }, () => {
            options.error();
        });
    },
});
