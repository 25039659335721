var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"checkbox\">\n                        <input type=\"checkbox\"\n                               name=\"includeSummaryInfo\"\n                               value=\"1\"\n                               id=\"include-summary-info\"\n                               data-hook=\"getIncludeSummaryInfo\"\n                               data-bind=\"model\">\n                        <label for=\"include-summary-info\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.includeSummaryInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":58},"end":{"line":55,"column":93}}}))
    + "</label>\n                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-6\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":23},"end":{"line":63,"column":61}}}))
    + "</label>\n                <div class=\"form-group\">\n                    <div class=\"radio\">\n                        <label class=\"radio-inline\">\n                            <input type=\"radio\"\n                                   id=\"expdataSum\"\n                                   value=\"summary\"\n                                   name=\"expdata\"\n                                   data-bind=\"model\" />\n                            "
    + alias2(((helper = (helper = lookupProperty(helpers,"printSummaryLabel") || (depth0 != null ? lookupProperty(depth0,"printSummaryLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"printSummaryLabel","hash":{},"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":72,"column":49}}}) : helper)))
    + "\n                        </label>\n                    </div>\n                    <div class=\"radio\">\n                        <label class=\"radio-inline\">\n                            <input type=\"radio\"\n                                   id=\"expdataTran\"\n                                   value=\"transaction\"\n                                   name=\"expdata\"\n                                   data-bind=\"model\" />\n                            "
    + alias2(((helper = (helper = lookupProperty(helpers,"printDetailsLabel") || (depth0 != null ? lookupProperty(depth0,"printDetailsLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"printDetailsLabel","hash":{},"data":data,"loc":{"start":{"line":82,"column":28},"end":{"line":82,"column":49}}}) : helper)))
    + "\n                        </label>\n                    </div>\n                    <span class=\"help-block\"\n                          data-bind=\"model\"\n                          data-validate=\"pay-expdata\">\n                </span>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form autocomplete=\"off\" data-submit=\"save\">\n    <div class=\"validation-group hide\"\n         role=\"alert\">\n        <p class=\"help-block center\"\n           data-validation=\"generic\"></p>\n    </div>\n    <div>\n        <p>\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.numberOfItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":58}}}))
    + "\n            <span data-hook=\"getSelectedItems\"> "
    + alias2(((helper = (helper = lookupProperty(helpers,"selectedItemsCount") || (depth0 != null ? lookupProperty(depth0,"selectedItemsCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"selectedItemsCount","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":70}}}) : helper)))
    + " </span>\n        </p>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-6\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.include",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":15,"column":59}}}))
    + "</label>\n            <div class=\"form-group\">\n                <div class=\"radio\">\n                    <label class=\"radio-inline\"\n                           for=\"selection-model-all\">\n                        <input type=\"radio\"\n                               id=\"selection-model-all\"\n                               value=\"all\"\n                               name=\"selection\"\n                               data-bind=\"model\"\n                               data-hook=\"getSelection\"\n                               "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSelectedRows") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":31},"end":{"line":26,"column":77}}})) != null ? stack1 : "")
    + ">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.allItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":65}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"radio\">\n                    <label class=\"radio-inline\"\n                           for=\"selection-model-selected\">\n                        <input type=\"radio\"\n                               id=\"selection-model-selected\"\n                               value=\"sel\"\n                               name=\"selection\"\n                               data-bind=\"model\"\n                               data-hook=\"getSelection\"\n                               "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSelectedRows") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":31},"end":{"line":39,"column":77}}})) != null ? stack1 : "")
    + ">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.printOptions.selectedItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":70}}}))
    + "\n                    </label>\n                    <span class=\"help-block\"\n                          data-bind=\"model\"\n                          data-validate=\"pay-selection\">\n                     </span>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showIncludeSummaryInfo") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":57,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSummarySelection") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":91,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n</form>\n";
},"useData":true});