import CompositeView from '@glu/core/src/compositeView';
import constants from 'app/administration/constants';
import RiskGroupView from './riskGroupView';
import riskManagementTmpl from './riskManagement.hbs';

export default CompositeView.extend({
    template: riskManagementTmpl,
    itemView: RiskGroupView,
    itemViewContainer: '.risk-groups',

    initialize(opts) {
        const groupCollection = this.model.get('simpleTypes');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isEntitled()));
        }

        this.collection = groupCollection;

        this.itemViewOptions = {
            userGroup: opts.model.userGroupModel.get('id'),
            mode: opts.mode,
            entity: opts.entity,
        };
    },
});
