import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import MDFMultiItemView from 'app/multiAdd/views/mdfMultiItemView';

export default MDFMultiItemView.extend({

    onRender() {
        MDFMultiItemView.prototype.onRender.call(this);

        this.listenTo(this.mdfView, 'ui-loaded', this.setupListeners);
    },

    setupListeners() {
        this.$('[name="CREDIT_AMOUNT"]').change(this.updateAmount.bind(this));
        this.$('[name="DEBIT_ACCOUNT_NUMBER"]').change(this.updateCurrency.bind(this));

        /*
         * The below listeners are tied to the DOM inputs rather than the corresponding
         * model attributes to ensure they only run on a 'change' event, rather than
         * every keystroke
         */
        this.$('[name="LEGAL_ENTITY_ID"], [name="BENE_BIRTH_CITY"], [name="BENE_BIRTH_COUNTRY"], [name="BENE_BIRTH_DATE"]').on('change', this.determineIfLegalEntityIdOrPersonalInfo.bind(this));
    },

    updateAmount() {
        this.model.collection.trigger('amountUpdated');
    },

    updateCurrency() {
        this.model.collection.trigger('currencyUpdated');
    },

    /*
     * Will run every time any of the following fields change:
     * LEGAL_ENTITY_ID, BENE_BIRTH_CITY, BENE_BIRTH_COUNTRY, BENE_BIRTH_DATE
     * the intent is that within this form there can only be values for either the legal entity
     * OR the beneficiary personal info. Not both.
     */
    determineIfLegalEntityIdOrPersonalInfo() {
        const showPersonalInfo = (applicationConfigParams.getValue('RTP', 'REQUIREPERSONALID') === '1');
        if (showPersonalInfo && this.model.get('LEGAL_ENTITY_ID')
        && (this.model.get('BENE_BIRTH_CITY')
        || this.model.get('BENE_BIRTH_DATE')
        || this.model.get('BENE_BIRTH_COUNTRY'))) {
            this.model.collection.trigger('legalEntityError', true);
        } else {
            this.model.collection.trigger('legalEntityError', false);
        }
    },
});
