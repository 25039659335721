import constants from 'app/balanceAndTransaction/constants';
import systemConfig from 'system/configuration';
import CreditCardTransactionListView from './creditCardTransactionListView';

const PreviousDayCreditCardTransactionListView = CreditCardTransactionListView.extend({
    accountType: 'CREDIT_CARD',

    /**
     * provides a part of The URL to hit for Previous Day Transactions
     * @method getServiceName - extend
     * @return {String} A part of the URL to hit for Previous Day Transactions
     */
    getServiceName() {
        return `${constants.CREDIT_CARD_ACCTS_SERVICE_PREFIX + constants.PREVIOUS_DAY_PREFIX}getTransactions`;
    },

    /**
     * provides the URL to hit for print/export on Previous Day Transactions
     * @method getExportPrintURL - extend
     * @return {String} the URL to hit for print/export on Previous Day Transactions
     */
    getExportPrintURL() {
        return systemConfig.isAdmin()
            ? constants.CREDIT_CARD_PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL
            : constants.ASYNC_CREDIT_CARD_PREVIOUS_DAY_TRANS_EXPORT_PRINT_URL;
    },

    /**
     * provides the typeCode for Previous Day Transactions
     * @method getTypeCode - extend
     * @return {String} provides the typeCode for Previous Day Transactions
     */
    getTypeCode() {
        return constants.BTR_TYPECODES.PREVIOUS.TRANSACTIONS;
    },

    /**
     * Override
     */
    setUpRealTimeUi() {
        /**
         * If DISPLAYBALANCESPREVDAY enabled:
         * A real-time call is made to update balances
         */
        if (this.prevDayTodayBalancesEnabled) {
            CreditCardTransactionListView.prototype.setUpRealTimeUi.call(this);
        }
    },

    /**
     * Override
     */
    setupAccountTransactionBalanceDetails() {
        /**
         * If DISPLAYBALANCESPREVDAY enabled:
         * Today's balances for the credit card accounts display above the Transactions
         * list view on the Previous Day tab for credit cards
         */
        if (this.prevDayTodayBalancesEnabled
            || this.displayTransactionTotals) {
            CreditCardTransactionListView.prototype
                .setupAccountTransactionBalanceDetails.call(this);
        }
    },
});

export default PreviousDayCreditCardTransactionListView;
