import locale from '@glu/locale';
import util from '@glu/core/src/util';
import DatePicker from 'system/gluOverride/datepicker/datepicker-glu-1.8';

/*
 * FIXME : Switch back to @glu/datepicker when we have the override solved
 * const { default: DatePicker } = datepickerGlu1.8;
 */

export default {
    create(options, blockedDates, cutoffTimes, maxDate, minDate) {
        let localOptions = options;
        let localBlockedDates = blockedDates;
        let localCutoffTimes = cutoffTimes;
        let datePickerOptions = {
            tagName: 'div',
            labelText: '',
            minDate: minDate || new Date(),
            maxDate: maxDate || 45,

            // can we use userInfo.getDateFormat() here instead?
            dateFormat: 'MM/DD/YYYY',

            rangePicker: false,
            imgAltText: locale.get('sbPayments.datePickerIconAltText'),

            errorMsgs: {
                required: locale.get('sbPayments.datePicker.errorMsgs.required'),
                invalidDateFormat: locale.get('sbPayments.datePicker.errorMsgs.invalidDateFormat'),
                blockedDate: locale.get('sbPayments.datePicker.errorMsgs.blockedDate'),
                afterCutoff: locale.get('sbPayments.datePicker.errorMsgs.afterCutoff'),
                tooFarPast: locale.get('sbPayments.datePicker.errorMsgs.tooFarPast'),
                tooFarFuture: locale.get('sbPayments.datePicker.errorMsgs.tooFarFuture'),
            },

            customSettings: {
                closeOnSelect: true,
            },
        };

        localOptions = localOptions || {};
        localBlockedDates = localBlockedDates || [];
        localCutoffTimes = localCutoffTimes || {};

        datePickerOptions = util.extend(datePickerOptions, localOptions);

        this.datePicker = new DatePicker(datePickerOptions);
        this.datePicker.blockDisabledDates(localBlockedDates, localCutoffTimes);

        return this.datePicker.render().$el;
    },

    defaultDate() {
        /*
         * can we use formatter utility here to consider the user's preferred date format?
         * this is a temporary fix for all pages other than Transfer page
         * return this.datePicker.defaultDate.format(this.datePicker.dtPickerFormat.moment);
         */
        if (this.datePicker.minDate < this.datePicker.startDate
            && !this.datePicker.allowToday) {
            return this.datePicker.startDate.format(this.datePicker.dtPickerFormat.moment);
        }
        return this.datePicker.minDate.format(this.datePicker.dtPickerFormat.moment);
    },

    /**
     * Set the value of the underlying textbox.
     */
    selectDate(value) {
        const selectedValue = value || this.defaultDate();
        this.datePicker.dateElem.val(selectedValue);
    },

    /**
     * Extract the selected date from the date picker.
     *
     * @returns {*}
     */
    setModelDate() {
        if (!this.datePicker.errMsg) {
            if (this.datePicker.startDate !== null) {
                // There is a user typed/selected date in the datepicker, use this
                return this.datePicker.startDate.format(this.datePicker.dtPickerFormat.moment);
            }
            // No date has been entered/selected, use the min date
            return this.datePicker.minDate.format(this.datePicker.dtPickerFormat.moment);
        }
        return '';
    },
};
