/* global Inputmask */
import 'jquery'; // eslint-disable-line
import '@dgb/jquery.inputmask';
import store from '@glu/store';
import Model from '@glu/core/src/model';
import { setUserInfo } from 'common/util/reduxUtil';

const USERINFO_KEY = 'userInfo';
const BT_LOGGED_IN_KEY = 'bt:loggedIn';
const BT_FORGOT_PASSWORD_KEY = 'bt:forgottenPassword';
const BT_OTP_SETUP_KEY = 'otpSetup';
const BT_LOCALE_KEY = 'bt:locale';
const NUMBER_FORMAT_KEY = 'numberFormat';
const CURRENCY_FORMAT_KEY = 'currencyFormat';
const CURRENCY_SYMBOL_KEY = 'currencySymbol';
const DATE_FORMAT_KEY = 'datePattern';
const TIME_FORMAT_KEY = 'timePattern';
const DATE_TIME_FORMAT_KEY = 'dateTimePattern';
const BRAND_KEY = 'brand';
const THOUSANDS_SEPARATOR_KEY = 'thousandsSeparator';
const RADIX_SEPARATOR_KEY = 'decimalSeparator';
const DECIMAL_PLACES_KEY = 'decimalPlaces';
const PHONE_FORMAT_KEY = 'phoneFormat';
const USERPERMISSIONSETTINGS = 'userPermissionSettings';
const MEMBERSHIP_ID = 'membershipID';
const CHECKFREE_AUTH_LEVEL = 'checkfreeAuthLevel';
const DEFAULT_PHONE_FORMAT = '(999) 999-9999';
const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
const DEFAULT_TIME_FORMAT = 'hh:mm A';
const DEFAULT_NUMBER_FORMAT = '0,0.00';
const DEFAULT_CURRENCY_FORMAT = '0,0.00';
const DEFAULT_CURRENCY_SYMBOL = '$';
const DEFAULT_BRAND = false;
const DEFAULT_THOUSANDS_SEPARATOR = ',';
const DEFAULT_RADIX_SEPARATOR = '.';
const DEFAULT_DECIMAL_PLACES = 2;

const UserInfo = Model.extend({
    principals: false,

    initialize(...args) {
        const self = this;

        this.on('change', ({ attributes }) => {
            setUserInfo(attributes);
        });

        // Add a promise for login so we can WAIT FOR IT
        this.loginPromise = new Promise((resolve) => {
            self.resolveLogin = function () {
                resolve(true);
            };
        });

        Model.prototype.initialize.apply(this, args);
        // repopulate if session storage is viable
        const attr = store.get(USERINFO_KEY);
        if (attr) {
            this.set(attr);
        }
    },

    setupInputMasks() {
        const self = this;

        Inputmask.extendAliases({
            number: {
                alias: 'numeric',
                groupSeparator: self.getThousandsSeparator(),
                placeholder: '0',
                autoGroup: !0,
                digits: self.getDecimalPlaces(),
                digitsOptional: !1,

                // clearMaskOnLostFocus: !0,
                radixPoint: self.getRadixSeparator(),
            },
        });
    },

    /**
     * Enable/disable the login flag.
     */
    login() {
        this.set(BT_LOGGED_IN_KEY, true);
        // store the login time that will be used to clean up localStorage
        this.set('loginTime', Date.now());
        this.persist();

        /*
         * setup input masks for currency and others depending on the users locale
         * data in the db.
         * these need to get loaded after login in order to have the info for the user.
         */
        this.setupInputMasks();
        this.resolveLogin();
    },

    /**
     * Determine if the logged in flag is set.
     */
    isLoggedIn() {
        return !!this.get(BT_LOGGED_IN_KEY);
    },

    /**
     * Clear all items in glu store related to the current user.
     */
    logout() {
        if (store.get('challengedAction')) {
            store.unset('challengedAction');
        }
        store.unset(USERINFO_KEY);
        this.clear();
    },

    /**
     * Enable/disable the forgot password flag.
     *
     * @param trueFalse
     */
    setForgotPassword(trueFalse) {
        this.set(BT_FORGOT_PASSWORD_KEY, trueFalse);
        this.persist();
    },

    /**
     * @description - this method is only value for the client user
     */
    isUce() {
        return this.get(USERPERMISSIONSETTINGS) === '3';
    },

    /**
     * Determine if the forgot password flag is set.
     */
    isForgotPassword() {
        return this.get(BT_FORGOT_PASSWORD_KEY) ? this.get(BT_FORGOT_PASSWORD_KEY) : false;
    },

    /**
     * enable / disable user contact points flow
     * @param trueFalse
     */
    setOTPSetup(trueFalse) {
        this.set(BT_OTP_SETUP_KEY, trueFalse);
        this.persist();
    },

    /**
     * determing if MFA setup workflow is complete
     * @returns {*}
     */
    isOTPSetup() {
        return this.get(BT_OTP_SETUP_KEY) ? this.get(BT_OTP_SETUP_KEY) : false;
    },

    getLocale() {
        return this.get('locale') ? this.get('locale') : this.defaultLocale();
    },

    defaultLocale() {
        if (navigator.languages !== undefined) {
            return navigator.languages[0];
        }
        return navigator.language;
    },

    getMarketSegment() {
        return this.get('marketSegment');
    },

    /**
     * Set the brand for the current user.
     *
     * @param brand
     */
    setBrand(brand) {
        this.set(BRAND_KEY, brand);
        this.persist();
    },

    /**
     * Get the brand for the current user.
     */
    getBrand() {
        return this.get(BRAND_KEY) ? this.get(BRAND_KEY) : DEFAULT_BRAND;
    },

    /**
     * Generic method to determine if the current user has privileges to perform
     * a certain action.
     * As we learn more about the various levels of security we can adapt this method.
     * This may ultimately move into a security api.
     *
     * @param accessKey
     * @returns {*|boolean}
     */
    hasAccess(accessKey) {
        if (accessKey === 'smb' || accessKey === 'smb_owner') {
            if (this.get('marketSegment') === 'SMB') {
                return true;
            }
        }

        if (accessKey === 'corp' || accessKey === 'corp_owner') {
            if (this.get('marketSegment') !== 'SMB') {
                return true;
            }
        }

        return false;
    },

    /**
     * Get the preferred number format for the current user.
     *
     * TODO: We'll need to update the code to use this method.
     * It's also worth noting that glu provides templateHelpers
     * for formatting within hbs templates.
     * We may want to update glu to allow for passing in a preferred format.
     */
    getNumberFormat() {
        return this.get(NUMBER_FORMAT_KEY)
            ? this.get(NUMBER_FORMAT_KEY) : DEFAULT_NUMBER_FORMAT;
    },

    /**
     * Set the preferred number format for the current user.
     *
     * @param format
     */
    setNumberFormat(format) {
        this.set(NUMBER_FORMAT_KEY, format);
        this.persist();
    },

    setCurrencySymbol(symbol) {
        this.set(CURRENCY_SYMBOL_KEY, symbol);
        this.persist();
    },

    getCurrencySymbol() {
        return this.get(CURRENCY_SYMBOL_KEY)
            ? this.get(CURRENCY_SYMBOL_KEY) : DEFAULT_CURRENCY_SYMBOL;
    },

    /**
     * Get the preferred currency format for the current user.
     * NOTE: This will need to be set into the userInfo object at some point.
     * Most likely
     * during the login process.
     *
     * TODO: We'll need to update the code to use this method.
     * It's also worth noting that glu provides templateHelpers
     * for formatting within hbs templates.
     * We may want to update glu to allow for passing in a preferred format.
     */
    getCurrencyFormat() {
        // build up the CURRENCY_FORMAT if it doesn't exist
        if (!this.get(CURRENCY_FORMAT_KEY)
                && this.get(THOUSANDS_SEPARATOR_KEY)
                && this.get(RADIX_SEPARATOR_KEY)) {
            // create format after the radix when we get the decimal places value returned
            const currencyFormat = `0${this.get(THOUSANDS_SEPARATOR_KEY)}0${this.get(RADIX_SEPARATOR_KEY)}00`;
            this.set(CURRENCY_FORMAT_KEY, currencyFormat);
        }

        return this.get(CURRENCY_FORMAT_KEY)
            ? this.get(CURRENCY_FORMAT_KEY) : DEFAULT_CURRENCY_FORMAT;
    },

    getCurrencyPattern() {
        let thousands = this.get(THOUSANDS_SEPARATOR_KEY);
        let radix = this.get(RADIX_SEPARATOR_KEY);

        // escape the ., otherwise everything will match
        if (radix === '.') {
            radix = '\\.';
        }

        if (thousands === '.') {
            thousands = '\\.';
        }

        return `^[+-]?(([0,]*${radix}([1-9][0-9]?|[0-9][1-9]))|(${thousands}*[0-9][${thousands}\\d]*(${radix}\\d{0,3})?))$`;
    },

    /**
     * Set the preferred currency format for the current user.
     *
     * @param format
     */
    setCurrencyFormat(format) {
        this.set(CURRENCY_FORMAT_KEY, format);
        this.persist();
    },

    getThousandsSeparator() {
        return this.get(THOUSANDS_SEPARATOR_KEY)
            ? this.get(THOUSANDS_SEPARATOR_KEY) : DEFAULT_THOUSANDS_SEPARATOR;
    },

    setThousandsSeparator(separator) {
        this.set(THOUSANDS_SEPARATOR_KEY, separator);
        this.persist();
    },

    getRadixSeparator() {
        return this.get(RADIX_SEPARATOR_KEY)
            ? this.get(RADIX_SEPARATOR_KEY) : DEFAULT_RADIX_SEPARATOR;
    },

    setRadixSeparator(separator) {
        this.set(RADIX_SEPARATOR_KEY, separator);
        this.persist();
    },

    getDecimalPlaces() {
        return this.get(DECIMAL_PLACES_KEY)
            ? this.get(DECIMAL_PLACES_KEY) : DEFAULT_DECIMAL_PLACES;
    },

    setDecimalPlaces(places) {
        this.set(RADIX_SEPARATOR_KEY, places);
        this.persist();
    },

    getPhoneFormat() {
        return this.get(PHONE_FORMAT_KEY)
            ? this.get(PHONE_FORMAT_KEY) : DEFAULT_PHONE_FORMAT;
    },

    setPhoneFormat(format) {
        this.set(PHONE_FORMAT_KEY, format);
        this.persist();
    },

    /**
     * Get the preferred date format for the current user.
     *
     * TODO: We'll need to update the code to use this method.
     * It's also worth noting that glu provides templateHelpers
     * for formatting within hbs templates.
     * We may want to update glu to allow for passing in a preferred format.
     */
    getDateFormat(useGivenFormat) {
        if (useGivenFormat) {
            return this.get(DATE_FORMAT_KEY) ? this.get(DATE_FORMAT_KEY) : DEFAULT_DATE_FORMAT;
        }
        /*
         * upper case format to support moment.js requirements which is our default
         * formatter.
         */
        return this.get(DATE_FORMAT_KEY)
            ? this.get(DATE_FORMAT_KEY).toUpperCase() : DEFAULT_DATE_FORMAT;
    },

    /**
     * Deriving the dateTimeFormat from dateFormat and timeFormat.
    */

    getDateTimeFormat() {
        return `${this.getDateFormat()} ${this.getTimeFormat()}`;
    },

    getTimeFormat() {
        return this.get(TIME_FORMAT_KEY) || DEFAULT_TIME_FORMAT;
    },

    /**
     * Set the preferred date format for the current user.
     *
     * @param format
     */
    setDateFormat(format) {
        this.set(DATE_FORMAT_KEY, format);
        this.persist();
    },

    setDateTimeFormat(format) {
        this.set(DATE_TIME_FORMAT_KEY, format);
        this.persist();
    },

    setTimeFormat(format) {
        this.set(TIME_FORMAT_KEY, format);
        this.persist();
    },

    /**
     * Set the date format based on the locale
     * NOTE: This method should go away once the user's dateFormat is stored in the database
     */
    setDateFormatFromLocale() {
        const locale = this.get(BT_LOCALE_KEY);
        let dateFormat = DEFAULT_DATE_FORMAT;

        // TODO: WHAT THE HELL?! ONE CASE??
        switch (locale) {
        case 'en_GB':
            dateFormat = 'DD/MM/YYYY';
            break;
        default:
        }

        this.setDateFormat(dateFormat);
    },

    setMembershipID(id) {
        this.set(MEMBERSHIP_ID, id);
        this.persist();
    },

    getMembershipID() {
        return this.get(MEMBERSHIP_ID);
    },

    persist() {
        store.set(USERINFO_KEY, this.attributes);
    },

    hasTopNavigation() {
        return this.get('menuLayout') === 'topNavigation';
    },

    hasLeftNavigation() {
        return this.get('menuLayout') === 'leftNavigation';
    },

    /*
     * This is the pattern that is in use to determine whether the run time user is a Small
     * business user
     */
    isSmbUser() {
        return this.get('menuLayout') === 'leftNavigation';
    },

    /**
     * determines if the user is a that cannot grant beyond own permissionss
     * @returns {boolean}
     */
    isCannotGrantBeyondOwnUser() {
        return this.get('cannotGrantBeyondOwnUser');
    },

    setCheckfreeAuthLevel(level) {
        this.set(CHECKFREE_AUTH_LEVEL, level);
        this.persist();
    },

    getCheckfreeAuthLevel() {
        return this.get(CHECKFREE_AUTH_LEVEL);
    },

});

export default new UserInfo();
