import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import http from '@glu/core/src/http';
import services from 'services';
import Scheduler from '@glu/scheduler';
import NestedModel from '@glu/core/src/nestedModel';
import { log } from '@glu/core';
import Formatter from 'system/utilities/format';
import entitlements from 'common/dynamicPages/api/entitlements';
import locale from '@glu/locale';
import template from 'common/uiWidgets/schedulePaymentWidget/schedulePaymentWidget.hbs';

const dateFormat = userInfo.getDateFormat();

const dateFormats = {
    dateFormats: {
        server: 'YYYY-MM-DD',
        ui: dateFormat,
    },
};

const days = [
    'WEEKLYDAYMON',
    'WEEKLYDAYTUE',
    'WEEKLYDAYWED',
    'WEEKLYDAYTHURS',
    'WEEKLYDAYFRI',
    'WEEKLYDAYSAT',
    'WEEKLYDAYSUN',
];

export default Layout.extend({
    template,
    className: 'ui-widget field-container',

    regions: {
        schedRegion: '[data-hook="getSchedulerRegion"]',
    },

    ui: {
        businessDayMode: '[data-hook="getBusinessDayMode"]',
        recurCheck: '[data-hook="getRecurCheck"]',
    },

    events: {
        'change [data-hook="getRecurCheck"]': 'recurringSelect',
    },

    templateHelpers() {
        return {
            showBusinessDay: true,
            readOnly: this.options.state === 'view',
            showRecur: this.isScheduleActionEntitled,
        };
    },

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);
        this.parentView = options.parentView;
        const entitlementsOpts = {};
        entitlementsOpts.context = options.context;
        entitlementsOpts.entryMethod = options.model.get('ENTRYMETHOD');
        entitlementsOpts.typeCode = options.model.get('TYPE');
        entitlementsOpts.productCode = options.model.get('PRODUCT');
        entitlementsOpts.functionCode = 'TMPL';
        const schedEntitlementPromise = entitlements.getEntitlements(entitlementsOpts);
        schedEntitlementPromise.then((result) => {
            this.isScheduleActionEntitled = result.actions.SPAYMT;
            this.setHasLoadedRequiredData(true);
            this.render();
            if (options.clearRecurring) {
                this.ui.recurCheck.prop('checked', false).change();
            }
        });
        this.listenTo(options.model, 'change:ORIGCOMPNAME change:DEBIT_ACCOUNT_NUMBER', () => {
            this.setStartingEffectiveDate();
        });
        this.buildScheduleModel();

        this.appBus.on('scheduler:modifyschedulecheckbox', ({ readOnly }) => {
            this.disableBusinessOnlyInputs(readOnly);
        });
    },

    disableBusinessOnlyInputs(isDisabled = false) {
        this.$el.find('.businessDay-Mode input[type="radio"]').prop('disabled', isDisabled);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const { schedModel } = this.model;
            if (this.model.schedModel) {
                const showModifyScheduleCheckbox = !!(
                    this.options.state.toLowerCase() === 'modify'
                    && !this.recurCheckUnchecked
                );
                this.scheduler = new Scheduler({
                    model: schedModel,
                    startDate: dateFormats,
                    simpleEndDate: false,
                    startValue: schedModel.get('starts') || Formatter.formatDate(new Date()),
                    endValue: schedModel.get('ends'),
                    weekly: true,
                    monthly: true,
                    daily: false,
                    ends: dateFormats,
                    readOnly: this.options.state === 'view' || showModifyScheduleCheckbox,
                    showModifyScheduleCheckbox,
                });

                if ((this.model.get('PATTERNMODE') && this.model.get('PATTERNMODE') !== 'O')
                    || (this.parentModel && this.parentModel.get('recur'))
                    || this.model.get('recur')
                    || this.ui.recurCheck.prop('checked')) {
                    if (this.parentModel) {
                        this.parentModel.set('recur', true);
                    }
                    this.schedRegion.show(this.scheduler);
                    this.ui.businessDayMode.show();
                    this.disableBusinessOnlyInputs(showModifyScheduleCheckbox);
                } else {
                    if (this.parentModel) {
                        this.parentModel.set('recur', false);
                    }
                    this.ui.businessDayMode.hide();
                }

                if (this.options.state === 'view') {
                    this.scheduler.$('input').prop('disabled', true);
                    this.scheduler.$('select').comboBox('disable', true);
                }
            } else {
                this.ui.businessDayMode.hide();
                this.recurCheckUnchecked = true;
            }
            this.setRecurCheck(this.model.get('PATTERNMODE'));
        }
    },

    initializeSchedModel(attrs = {}) {
        const schedModel = new NestedModel(attrs);
        /*
         * HACK: NH-110556 due to nestedModel failure to access necessary attributes
         * we needed to manually connect nested model attribute to non nested attribute
         */
        this.listenTo(schedModel, 'change', () => {
            const recAttrs = util.omit(schedModel.attributes, 'recurrence');

            /**
             * the array keeps getting converted to a string
             * so let's make sure it stays an array
             */
            if (recAttrs.onN && !Array.isArray(recAttrs.onN)) {
                recAttrs.onN = [recAttrs.onN];
            }

            schedModel.set('recurrence', { ...schedModel.attributes.recurrence, ...recAttrs }, { silent: true });
        });

        return schedModel;
    },

    buildScheduleModel() {
        const obj = this.model;

        if (obj.get('PATTERNMODE') && obj.get('PATTERNMODE') !== 'O') {
            const schedModel = this.initializeSchedModel();
            const recurrence = {};

            // This is needed so scheduler doesn't think it is a new record
            schedModel.idAttribute = 'starts';

            schedModel.set('starts', obj.get('STARTINGEFFECTIVEDATE').indexOf(' ') > -1 ? obj.get('STARTINGEFFECTIVEDATE').substring(0, obj.get('STARTINGEFFECTIVEDATE').indexOf(' ')) : obj.get('STARTINGEFFECTIVEDATE'));

            if (obj.get('ENDMODE') === 'noend') {
                schedModel.set('ends', false);
            } else if (obj.get('ENDMODE') === 'numoccur') {
                schedModel.set('ends', parseInt(obj.get('ENDCYCLES'), 10));
            } else {
                schedModel.set('ends', obj.get('ENDINGEFFECTIVEDATE').indexOf(' ') > -1 ? obj.get('ENDINGEFFECTIVEDATE').substring(0, obj.get('ENDINGEFFECTIVEDATE').indexOf(' ')) : obj.get('ENDINGEFFECTIVEDATE'));
            }

            // Monthly
            if (obj.get('PATTERNMODE') === 'M') {
                schedModel.set('type', 'MONTHLY');

                if (obj.get('MONTHLYMODE') === 'specific') {
                    recurrence.intervalType = 'DAY';
                    recurrence.nMonths = obj.get('MONTHLYMONTH1');
                    recurrence.onN = [Number(obj.get('MONTHLYDAY1'))];
                } else {
                    recurrence.intervalType = obj.get('MONTHLYDAYTYPE').toUpperCase();
                    recurrence.nMonths = obj.get('MONTHLYMONTHNUMBER');
                    recurrence.onN = [obj.get('MONTHLYDAYNUMBER').toUpperCase()];
                }
            } else {
                // Weekly
                schedModel.set('type', 'WEEKLY');

                recurrence.nWeeks = obj.get('WEEKLYWEEKS');
                recurrence.onN = [];

                util.each(days, (day, idx) => {
                    if (obj.get(day) === '1') {
                        recurrence.onN.push(idx + 1);
                    }
                });
            }

            schedModel.set(recurrence);

            schedModel.set('readOnly', this.options.state.toUpperCase() !== 'MODIFY');

            this.model.schedModel = schedModel;
        }
    },

    recurringSelect(e) {
        const isChecked = this.$(e.target).prop('checked');
        if (isChecked) {
            const schedModel = this.initializeSchedModel({
                ends: false,
            });

            if (this.parentModel) {
                this.parentModel.set('recur', true);
            }
            this.model.set({
                NONBUSINESSDAYMODE: 'prev',
                ENDMODE: 'noend',
                recur: true,
            });

            if (!this.model.schedModel) {
                this.model.schedModel = schedModel;
            }
            const showModifyScheduleCheckbox = !!(
                this.options.state.toLowerCase() === 'modify'
                && !this.recurCheckUnchecked
            );

            this.scheduler = new Scheduler({
                model: this.model.schedModel || schedModel,
                startDate: dateFormats,
                simpleEndDate: false,
                startValue: this.model.schedModel.get('starts') || Formatter.formatDate(new Date()),
                weekly: true,
                monthly: true,
                yearly: false,
                daily: false,
                ends: dateFormats,
                readOnly: this.options.state === 'view' || showModifyScheduleCheckbox,
                showModifyScheduleCheckbox,
            });

            this.schedRegion.show(this.scheduler);
            this.ui.businessDayMode.show();
            this.disableBusinessOnlyInputs(showModifyScheduleCheckbox);
        } else {
            this.recurCheckUnchecked = true;
            this.schedRegion.reset();
            this.ui.businessDayMode.hide();
            if (this.parentModel) {
                this.parentModel.set('recur', false);
            }
            this.model.set({
                PATTERNMODE: 'O',
                recur: false,
            });
            delete this.model.schedModel;
        }

        const typeCode = this.model.get('paymentType');
        const isSingle = typeCode === 'LOANDRAW' || this.options.parentView.preferences?.[0]?.singleOnlyPaymentOptionSupported;
        const amountField = isSingle ? 'CREDIT_AMOUNT' : 'AMOUNT_0';
        const container = isSingle ? '.field-container' : '.multifield-container-0';

        if (isChecked) {
            this.parentView.ui.$amount.closest(container).addClass('required');
            this.model.addValidator(
                amountField,
                {
                    description: locale.get(`loans.${typeCode.toLowerCase()}.amount`),
                    exists: true,
                    minValue: 0.01,
                },
            );
        } else {
            this.parentView.ui.$amount.closest(container).removeClass('required');
            this.model.removeValidator(amountField);
            this.parentView.ui.$amount.change();
        }
    },

    setRecurCheck(patternMode) {
        if ((patternMode && patternMode !== 'O')
            || (this.parentModel && this.parentModel.get('recur'))
            || this.model.get('recur')) {
            this.ui.recurCheck.prop('checked', true);
        }
    },

    setStartingEffectiveDate() {
        const self = this;

        const dateOpts = {
            paymentType: self.model.jsonData.typeInfo.typeCode,
            subType: self.model.jsonData.subtype,
            dateProductType: 'SCHEDULED',
            debitBank: self.model.get('OFFSETBANKCODE') || self.model.get('DEBIT_BANK_CODE'),
            debitCurrency: self.model.get('ORIGCURRENCYCODE') || self.model.get('DEBIT_CURRENCY') || self.model.get('CREDIT_CURRENCY'),
            debitBankCountry: self.model.get('ORIGBANKCOUNTRY') || self.model.get('DEBIT_COUNTRY'),
            creditCurrency: self.model.get('DESTCURRENCYCODE') || self.model.get('CREDIT_CURRENCY'),
            tnum: self.model.get('TNUM'),
        };

        const schedModel = this.initializeSchedModel({
            ends: false,
        });

        // for the first time user selects debit account before checking make recur
        if (!this.model.schedModel) {
            this.model.schedModel = schedModel;
        }

        const dateService = services.generateUrl('/date/list');

        http.post(dateService, dateOpts, (response) => {
            /*
             * Setup the scheduler once the data call has completed.
             * The scheduler does not currently support the setting
             * of valid business days when selecting days of the week.
             * Once it does this will need to be updated using the
             * BUSINESSDAY value returned by the date call
             */
            const showModifyScheduleCheckbox = !!(
                this.options.state.toLowerCase() === 'modify'
                    && !this.recurCheckUnchecked
            );
            self.scheduler = new Scheduler({
                model: self.model.schedModel,
                startDate: dateFormats,
                startValue: Formatter.formatDate(response.earliestDay, 'YYYY-MM-DD'),
                weekly: true,
                monthly: true,
                daily: false,
                ends: dateFormats,
                readOnly: this.options.state === 'view' || showModifyScheduleCheckbox,
                showModifyScheduleCheckbox,
            });
        }, (err) => {
            log.error(err);
        });
    },
});
