import Model from '@glu/core/src/model';

export default Model.extend({

    defaults: {
        beforeLeaveStep: () => true,
        view: null,
        viewOptions: {
            // model: model will be passed into the view specified in the above 'view' attribute
        },
        label: '',
        selected: false,
        readOnly: false,
    },

});
