import util from '@glu/core/src/util';
import ListAlertsView from 'app/administration/views/listAlerts';

export default ListAlertsView.extend({
    addUrl: 'ADMINSTRATION/alerts/addAlert/alert',
    modifyUrl: 'ADMINSTRATION/alerts/modifyAlert',
    viewUrl: 'ADMINSTRATION/alerts/viewAlert',

    initialize(options) {
        ListAlertsView.prototype.initialize.call(this, util.extend({}, options));
    },

    /**
     * @returns {object}
     */
    templateHelpers() {
        const obj = ListAlertsView.prototype.templateHelpers.call(this);

        return util.extend(obj, {
            hideSubHeader: true,
        });
    },
});
