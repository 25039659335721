import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import TokenModel from 'app/administration/models/token';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';

const TokenLockedUsersList = ListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMINSTRATION',
            serviceName: 'tokenManagement/user',
            serviceFunc: null,
            businessType: null,
            context: 'TKNMGMTUSERS',
            selector: 'none',
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * @method gridRowUnlock
     * @param {object} options - the options passed by the grid when an action is taken
     */
    gridRowUnlock(options) {
        return this.processGridAction(options, 'unlockUser');
    },

    /**
     * @method gridRowEnable
     * @param {object} options - the options passed by the grid when an action is taken
     */
    gridRowEnable(options) {
        return this.processGridAction(options, 'enableUser');
    },

    /**
     * @method gridRowDisable
     * @param {object} options - the options passed by the grid when an action is taken
     */
    gridRowDisable(options) {
        return this.processGridAction(options, 'disableUser');
    },

    /**
     * @method processGridAction
     * @param options
     * @param event
     * - set the tokenInfo property on the model, post the data to the service
     * based on the event, fetch latest data from core
     */
    processGridAction(options, event) {
        return new Promise((resolve, reject) => {
            const self = this;

            const tokenModel = new TokenModel({
                tokenInfo: {
                    tokenSerialNumber: '',
                    userId: options.model.get('USERID'),
                },
            });

            tokenModel.syncData(
                event,
                {
                    success(confirmResponse, action) {
                        self.renderMessage(action, confirmResponse);

                        tokenModel.fetch({
                            success() {
                                self.gridView.refreshGridData();
                                resolve({ result: confirmResponse });
                            },

                            error(model) {
                                self.errorAlert(model.error);
                                reject(model);
                            },
                        });
                    },

                    error(e) {
                        self.errorAlert(e.error);
                        reject(e);
                    },
                },
            );
        });
    },

    /**
     * - a common errorAlert
     * @method errorAlert
     */
    errorAlert(confirmResponse) {
        this.renderMessage('DANGER', confirmResponse);
    },
});

let list = TokenLockedUsersList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
