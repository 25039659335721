import alert from '@glu/alerts';
import Collection from '@glu/core/src/collection';
import Marionette from 'backbone.marionette';
import { getGridComponent } from 'common/util/reactUtil';
import { getAlertMessage, removeAlertMessage } from 'common/util/reduxUtil';
import LvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import $ from 'jquery';
import mobileUtil from 'mobile/util/mobileUtil';
import ReactDOM from 'react-dom';
import store from 'system/utilities/cache';
import SavedViewsCollection from 'common/dynamicPages/collections/savedViews';
import savedViewFilterUtils from 'common/util/savedViewFilterUtil';
import SavedViewList from 'common/dynamicPages/views/savedViews/savedViewsList';
import gridActions from 'common/dynamicPages/views/createGridActionsMap';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import alertTemplate from './listMobileInterfaceAlert.hbs';

const DEFAULT_SORT_COLUMN = 'NONE';
const DEFAULT_SORT_ORDER = 'DESC';

export default function ListMobileInterface(ListView, listOptions = {}) {
    return {
        constructor(...args) {
            ListView.prototype.constructor.apply(this, args);
        },

        initialize(...args) {
            ListView.prototype.initialize.apply(this, args);
            this.selectedMobileRows = [];
            this.listOptions = listOptions;
            this.originalBatchGrid = args[0].originalBatchGrid;
            /*
             * NH-185511 Sometimes context data is not found in the menu metadata,
             * so providing a way to override context specifically on mobile.
             */
            if (args[0].mobileContextOverride) {
                this.contextDef = args[0].mobileContextOverride;
            }
            if (typeof this.updateMobileGridInsertActions === 'function') {
                this.updateMobileGridInsertActions();
            }
            this.listenTo(this.appBus, 'getrate:complete', ({ rowModel, status, updatedValues }) => {
                this.trigger('mobilegrid:getrate', { rowModel, status, updatedValues });
            });

            this.listenTo(this.appBus, 'getrate:expired', ({ rowModel, status }) => {
                this.trigger('mobilegrid:getrate', { rowModel, status });
            });

            this.listenTo(this.appBus, 'trade:complete', ({ rowModel, status }) => {
                this.trigger('mobilegrid:trade', { rowModel, status });
            });

            this.listenTo(this.appBus, 'mobilegrid:togglemask:originalchild', this.toggleOriginalBatchMaskColumns);
        },

        toggleOriginalBatchMaskColumns() {
            this.originalBatchGrid.maskToggle = !this.originalBatchGrid.maskToggle;
        },

        template: (...args) => {
            if (mobileUtil.isMobileGridEnabled()) {
                return '';
            }
            return ListView.prototype.template.apply(this, args);
        },

        onRender(...args) {
            if (!mobileUtil.isMobileGridEnabled()) {
                ListView.prototype.onRender.apply(this, args);
            }
        },

        // Mobile Grid does not have grid listeners, no-op this
        setupGridAvailableListener(...args) {
            if (!mobileUtil.isMobileGridEnabled()) {
                ListView.prototype.setupGridAvailableListener.apply(this, args);
            }
        },

        initializeMobileGridListeners() {
            /**
             * Some grids don't have a gridRowAction handler. So don't setup the event listeners
             * These are grids that inherit the Layout view and don't have a method setup
             * for themselves. failedRecordsListView is an example of it
             */
            if (!this.gridRowAction) {
                return;
            }
            [...Object.keys(gridActions)].forEach((key) => {
                this.listenTo(this.appBus, (`grid:row:action:action_${key}_${this.gridView.cid}`), (model, action) => this.rowActionHandler({ model, action: action || gridActions[key] || key }));
            });
            import('common/dynamicPages/views/grid').then((gridImport) => {
                const DesktopGrid = gridImport.default;
                DesktopGrid.prototype.context = this.contextDef;
                const bindingObject = DesktopGrid.prototype;
                const actionGridView = methodName => (options) => {
                    this.actionedGridViewId = this.gridView.cid;
                    const ofn = DesktopGrid.prototype[methodName].bind(bindingObject);
                    ofn(options);
                };

                this.listenTo(this.appBus, `grid:row:action:action_approve_${this.gridView.cid}`, actionGridView('gridApproveAction'));
                this.listenTo(this.appBus, `grid:row:action:action_copytmpl_${this.gridView.cid}`, actionGridView('gridMakeTmpllAction'));
                this.listenTo(this.appBus, `grid:row:action:action_delete_${this.gridView.cid}`, actionGridView('gridRemoveAction'));
                this.listenTo(this.appBus, `grid:row:action:action_makeinst_${this.gridView.cid}`, actionGridView('gridMakeInstlAction'));
                this.listenTo(this.appBus, `grid:row:action:action_maketmpl_${this.gridView.cid}`, actionGridView('gridMakeTmpllAction'));
                this.listenTo(this.appBus, `grid:row:action:action_modify_${this.gridView.cid}`, actionGridView('gridModifyAction'));
                this.listenTo(this.appBus, `grid:row:action:action_needinfo_${this.gridView.cid}`, actionGridView('gridNeedMoreInfoAction'));
                this.listenTo(this.appBus, 'grid:row:action:action_popupCompleted', this.gridView.wrapper.refreshGridData);
                this.listenTo(this.appBus, `grid:row:action:action_reject_${this.gridView.cid}`, actionGridView('gridRejectAction'));
                this.listenTo(this.appBus, `grid:row:action:action_repair_${this.gridView.cid}`, actionGridView('gridRepairAction'));
                this.listenTo(this.appBus, `grid:row:action:action_select_${this.gridView.cid}`, actionGridView('gridViewAction'));
                this.listenTo(this.appBus, `grid:row:action:action_spaymt_${this.gridView.cid}`, actionGridView('gridScheduleAction'));
                this.listenTo(this.appBus, `grid:row:action:action_unapprov_${this.gridView.cid}`, actionGridView('gridUnapproveAction'));
                this.listenTo(this.appBus, `grid:row:action:action_unvalida_${this.gridView.cid}`, actionGridView('gridUnvalidateAction'));
                this.listenTo(this.appBus, `grid:row:action:action_validate_${this.gridView.cid}`, actionGridView('gridValidateAction'));
                this.listenTo(this.appBus, `grid:row:action:action_viewsumt_${this.gridView.cid}`, actionGridView('gridViewAction'));
                this.listenTo(this.appBus, 'grid:row:action:action_extendSubmit', actionGridView('gridRowExtendAction'));


                /*
                 * When an action is defined in rowActionHandler to be added by the
                 * desktop it triggers an appBus event which is caught by a listener
                 * defined above. The method invoked by the desktop grid in views/grid.js
                 * then invokes a triggerGridAction, which then triggers a rowAction event
                 * which is what we're listening to below to then call the gridRowAction method.
                 *
                 * While this is required, the inheritent problem with this is that the event
                 * is triggered on the main common/dynamicPages/views/grid prototype object
                 * which means when there are multiple grids displayed on a page, this event gets
                 * triggered for each and every one of them. This can create unexpected scenarios
                 * such as NH-160649.
                 *
                 * To work around this issue, we will store the specific gridView cid being
                 * actioned on within an instance property and then reset it during the
                 * invocation of the rowAction event handler below. This way we can verify that we
                 * are triggering the event only when tied to the appropriate grid instance.
                 */
                this.listenTo(bindingObject, 'rowAction', (options) => {
                    /*
                     * When there are multiple mobile grid instances on a page, the
                     * specific context can get thrown off due to the dynamic import
                     * of the views/grid.js making last in the winner. This resets
                     * the this.context during the course of the event to ensure
                     * that context is handled appropriately.
                     */
                    bindingObject.context = this.contextDef;
                    /*
                     * NH-174552 if additional actions require preserving context,
                     * consider the handler mentioned in the ticket.
                     */
                    if (this.gridView.cid === this.actionedGridViewId) {
                        /**
                         * NH-165324
                         * If there's a parentView, prefer its
                         * gridRowAction over this.gridRowAction
                         */
                        if (this.listOptions.parentView?.gridRowAction) {
                            this.listOptions.parentView.gridRowAction(options);
                        } else {
                            this.gridRowAction(options);
                        }
                        this.actionedGridViewId = null;
                    }
                });
            });
        },

        /**
         * @method prependAlertRegion
         * Because the Mobile Grid is taking the place of the full ListView, prepend the alertRegion
         */
        prependAlertRegion() {
            if (this.isBatchChildGrid || this.skipMobileGridAlertRegion) {
                return;
            }
            const alertEl = $(alertTemplate());
            alertEl.insertBefore(this.$el[0]);
            this.alertRegion = new Marionette.Region({ el: alertEl });
        },

        /**
         * @method prependPreGridContent
         * A method to render content above the grid
         * preGridContent should be a backbone view
         */
        prependPreGridContent() {
            if (this.preGridContent) {
                const preGridContent = this.preGridContent.render();
                preGridContent.$el.addClass(this.preGridContent.className || 'pre-grid-region');
                preGridContent.$el.css(this.preGridContent.styles || {});
                preGridContent.$el.insertBefore(this.$el[0]);
            }
        },

        appendPostGridContent() {
            if (this.postGridContent) {
                this.postGridContent = this.postGridContent.render();
                /**
                 * TODO:
                 * figure out a way to calculate the height of mobile navbar
                 * and replace hardcoded bottom prop
                 */
                const postGridContentStyle = {
                    position: 'relative',
                    bottom: 55, // To be replace by dynamic nav height
                    'margin-bottom': 25,
                    'padding-left': 10,
                };
                this.postGridContent.$el.css(postGridContentStyle);
                this.$el.append(this.postGridContent.$el);
            }
        },

        handlePostGridContent(gridData) {
            const { options: { visibleWhenData, onDataChange } } = this.postGridContent;
            if (visibleWhenData) {
                const dataLength = gridData?.wrapper?.totalRows;
                if (!dataLength) {
                    this.postGridContent.$el.hide();
                } else {
                    this.postGridContent.$el.show();
                }
            }
            if (onDataChange) {
                const data = gridData?.wrapper?.rows;
                onDataChange(data);
            }
        },

        renderMobileGrid() {
            const mountEl = listOptions.mountSelector ?
                this.el.closest(listOptions.mountSelector) : undefined;
            const rootEl = mountEl || this.el;
            rootEl.classList.add(`mg-${this.cid}`);
            ReactDOM.render(
                getGridComponent({
                    fetchData: this.getLoadPromise.bind(this),
                    fetchSavedViews: this.fetchSavedViews.bind(this),
                    pageData: this.pageData.bind(this),
                    sortData: this.sortData.bind(this),
                    changeColumnData: this.changeColumnData.bind(this),
                    filterData: this.filterData.bind(this),
                    interfaceInstance: this,
                    refreshGridData: this.refreshGridData.bind(this),
                    changeSavedView: this.changeSavedView.bind(this),
                    selectMobileGridRow: this.selectMobileGridRow.bind(this),
                    selectAllMobileGridRows: this.selectAllMobileGridRows.bind(this),
                    saveSavedView: this.saveSavedView.bind(this),
                    deleteSavedView: this.deleteSavedView.bind(this),
                    makeDefaultSavedView: this.makeDefaultSavedView.bind(this),
                }),
                rootEl,
            );
        },

        onShow() {
            if (mobileUtil.isMobileGridEnabled()) {
                this.prependAlertRegion();
                this.prependPreGridContent();

                this.renderMobileGrid();

                let alertMessage;
                let confirms;
                // React MDF Messages
                const alertMsgContext = getAlertMessage(this.contextKey);
                if (alertMsgContext && Object.keys(alertMsgContext).length) {
                    ({ alertMessage, confirms } = alertMsgContext);
                }

                const { apiMessageContext } = listOptions;
                if (Array.isArray(apiMessageContext)) {
                    apiMessageContext.forEach((messageContext) => {
                        if (store.has(messageContext)) {
                            this.handleAlertMessage();
                        }
                    });
                } else if (apiMessageContext && store.has(apiMessageContext)) {
                    this.handleAlertMessage();
                } else if (alertMessage && confirms) {
                    // React MDF Messages
                    this.renderMessage(alertMessage, confirms);
                    removeAlertMessage(this.contextKey);
                }
                if (!store.get(`${this.contextKey}-alertMessage`)) return;
                this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
                store.set(`${this.contextKey}-alertMessage`, null);
                store.set(`${this.contextKey}-confirms`, null);
            }
        },

        setAlertDetails(apiMessageContext) {
            const confirmResponse = apiMessageContext.response;
            const confirms = new Confirms({
                confirms: confirmResponse ? confirmResponse.confirms : null,
            });
            const confirmResults = confirmResponse?.confirms?.confirmResults || [];
            const hasConfirms = confirmResults.length &&
            confirmResults.some(cr => cr.confirmData?.length);
            return {
                details: hasConfirms ? confirms : null,
                canDismiss: !!confirmResponse,
                animate: true,
                confirmResponse: confirmResponse
                    && confirmResponse.message ? confirmResponse : false,
            };
        },

        handleAlertMessage() {
            const { apiMessageContext: listOptionsMessageContext } = listOptions;
            let apiMessageContext;

            if (Array.isArray(listOptionsMessageContext)) {
                listOptionsMessageContext.forEach((messageContext) => {
                    const message = store.get(messageContext);
                    if (message) {
                        apiMessageContext = message;
                    }
                    store.unset(messageContext);
                });
            } else {
                apiMessageContext = store.get(listOptions.apiMessageContext);
                store.unset(listOptions.apiMessageContext);
            }
            const props = this.setAlertDetails(apiMessageContext);
            if (typeof apiMessageContext === 'object' && apiMessageContext.response && apiMessageContext.response.message) {
                const alertMessage = apiMessageContext.response.message.join(' ');
                this.alertRegion.show(alert.success(alertMessage, props));
            } else {
                this.alertRegion.show(alert.success(apiMessageContext, props));
            }
        },

        getLoadPromise() {
            const listViewRequirements = this.loadViewRequirements
                && !listOptions.useGridViewFromOptions
                ? this.loadViewRequirements() : Promise.resolve({});
            if (listOptions.useGridViewFromOptions) {
                this.gridView = this.options.gridView;
            }
            if (!this.contextDef) {
                this.contextDef = this.context || listOptions.context || {};
            }

            const listViewRequirementsWithPromise = listViewRequirements?.then ?
                listViewRequirements : Promise.resolve(listViewRequirements);

            /*
             * Because the ESM work refactored things to have the
             * grid API as a dynamic import, there is an additional
             * level of waiting required for this.gridView to be available
             */
            return listViewRequirementsWithPromise
                .then((listViewData = {}) => this.gridView.loadPromise
                    .then(gridData => this.afterGridViewLoadPromise(gridData, listViewData)));
        },

        afterGridViewLoadPromise(gridData, listViewData) {
            this.gridLoaded = true;
            // eslint-disable-next-line max-len
            this.originalRefreshGridData = this.gridView.wrapper.refreshGridData.bind(this.gridView.wrapper);
            this.gridView.wrapper.refreshGridData = (...args) => {
                this.trigger('mobilegridShouldRefresh', ...args);
                return Promise.resolve();
            };

            this.gridView.viewSelectionHandlerById = (view) => {
                /*
                 * When changing the saved view programatically for Get Rate
                 * we need to unmount and remount the mobile grid so that the
                 * saved views update appropriately.
                 */
                this.changeSavedView(view).then(() => {
                    this.onBeforeClose();
                    this.renderMobileGrid();
                });
            };

            if (listOptions.callbackAfterLoad) {
                this[listOptions.callbackAfterLoad]();
            }

            if (this.postGridContent) {
                this.appendPostGridContent();
                this.handlePostGridContent(gridData);
            }

            const sortState = this.gridView.wrapper.lvc
                ? this.gridView.wrapper.lvc.get(LvcConstants.SORTFIELDS)
                : [];
            const columnState = this.gridView.wrapper.lvc
                ? this.gridView.wrapper.lvc.get(LvcConstants.COLUMNS)
                : [];
            const filterState = this.gridView.wrapper.lvc
                ? this.gridView.wrapper.lvc.get(LvcConstants.FILTERFIELDS)
                : [];
            const { fieldName, sortOrder } = sortState?.[0] || {};

            const columns = this.getComposedColumns(this.gridView.wrapper.columns);

            this.initializeMobileGridListeners();

            return {
                entitlements: listViewData.entitlements
                            || listOptions.entitlements
                            || listViewData,
                gridData: gridData.wrapper,
                rows: this.getComposedRows(this.gridView.wrapper.rows),
                columns,
                /*
                 * There could be a different viewInstance in res[0], so only use
                 * 'this' as a fallback which is a reference to the viewInstance
                 */
                viewInstance: listViewData.viewInstance || this,
                rowActionHandler: this.rowActionHandler.bind(this),
                insertActions: this.listOptions.insertActions,
                bulkActionsDisallowed: listOptions.bulkActionsDisallowed,
                bulkActions: listOptions.bulkActions,
                additionalActions: listOptions.additionalActions,
                handleMobileBulkAction: this.handleMobileBulkAction.bind(this),
                sortState: fieldName && sortOrder
                    ? {
                        field: fieldName,
                        direction: sortOrder,
                    }
                    : {},
                columnState,
                filterState: this.transformFilterDataForReact(filterState),
                enableSavedViews: this.enableSavedViews || listOptions.enableSavedViews,
                selectRow: this.selectMobileGridRow.bind(this),
                selectAllRows: this.selectAllMobileGridRows.bind(this),
                interfaceInstance: this,
                showSelector: listOptions.showSelector || false,
            };
        },

        fetchSavedViews(defaultViewId) {
            return new Promise((resolve) => {
                this.gridView.savedViews = new SavedViewsCollection(
                    {},
                    {
                        context: this.context || this.contextDef,
                    },
                );
                this.gridView.savedViews.fetch({
                    success: (model) => {
                        const savedViews = model.map(m => ({
                            ...m.toJSON(),
                            model: m,
                        }));
                        const defaultViewModel = model.find(view => view.get('viewId') === defaultViewId);

                        this.fetchSavedViewDetails(defaultViewModel).then((viewResp) => {
                            resolve([savedViews, viewResp]);
                        });
                    },
                });
            });
        },

        fetchSavedViewDetails(model) {
            if (!model) {
                return Promise.resolve({});
            }
            return new Promise((resolve) => {
                model.fetch({
                    success: (viewModel, resp) => {
                        savedViewFilterUtils.processFilterDisplayFromParams(
                            resp.filters,
                            this.gridView.wrapper.columns.models,
                            viewModel.get('functionCode'),
                        ).then(() => {
                            /**
                             * parsed filters are returned from here; however, we would need to
                             * parse them again to show default options on the filters.
                             * Because of this, this work will likely be part of a future epic as
                             * this functionality also doesn't exist on desktop.
                             * When we change saved views we want to make sure the grid
                             * resets the filter params and sort params.
                             */
                            const hasSort = resp.columnInfo.sortColumn !== 'NONE';
                            this.gridView.wrapper.filters = new Collection([]);

                            /*
                             * Always update the wrapper, and when the savedView does not
                             * have sort data, use the default
                             */
                            if (hasSort) {
                                this.gridView.wrapper.sortKey = resp.columnInfo.sortColumn;
                                this.gridView.wrapper.sortOrder = resp.columnInfo
                                    .sortDir?.toLowerCase();
                            } else {
                                this.gridView.wrapper.sortKey = DEFAULT_SORT_COLUMN;
                                this.gridView.wrapper.sortOrder = DEFAULT_SORT_ORDER;
                            }

                            /*
                             * Always update the wrapper, and when the savedView does not
                             * have sort data, use an empty array
                             */
                            if (this.gridView.wrapper.lvc) {
                                this.gridView.wrapper.lvc.resetToFirstPage();
                                this.gridView.wrapper.lvc.set(
                                    LvcConstants.FILTERFIELDS,
                                    this.gridView.wrapper.createFiltersForConfig(),
                                );
                                if (hasSort) {
                                    this.gridView.wrapper.lvc.set(
                                        LvcConstants.SORTFIELDS,
                                        [{
                                            fieldName: this.gridView.wrapper.sortKey,
                                            sortOrder: this.gridView.wrapper.sortOrder,
                                        }],
                                    );
                                } else {
                                    // No sort found, so set as empty array
                                    this.gridView.wrapper.lvc.set(
                                        LvcConstants.SORTFIELDS,
                                        [],
                                    );
                                }
                            }
                            resolve({
                                sort: hasSort
                                    ? {
                                        field: this.gridView.wrapper.sortKey,
                                        direction: this.gridView.wrapper.sortOrder,
                                    }
                                    : {},
                                filters: {},
                                columns: resp.columnInfo.cellData,
                            });
                        });
                    },
                });
            });
        },

        deleteSavedView(model) {
            return Promise.resolve(model.destroy());
        },

        makeDefaultSavedView(model) {
            return SavedViewList.prototype.makeDefault.call({
                /** we're mocking the `this` of the backbone view to avoid duplicate code */
                ...SavedViewList.prototype,
                currentSavedView: model,
                grid: {
                    collection: {
                        context: this.contextDef,
                    },
                },
            });
        },

        saveSavedView(data, model, sortState) {
            const viewModel = SavedViewList.prototype.constructParamsForView.call({
                /** we're mocking the `this` of the backbone view to avoid duplicate code */
                ...SavedViewList.prototype,
                savedViews: this.gridView.savedViews,
                gridView: this.gridView,
                wrapper: {
                    ...this.gridView.wrapper,
                    /** not sure why this loses the reference to these */
                    sortKey: sortState.field,
                    sortOrder: sortState.direction,
                },
                grid: {
                    collection: {
                        context: this.contextDef,
                    },
                },
            }, data, model);

            return new Promise((resolve) => {
                viewModel.save({}, {
                    success: (successModel) => {
                        const columnInfo = successModel.get('columnInfo');
                        const newSort = {
                            field: columnInfo.sortColumn,
                            direction: columnInfo.sortDir,
                        };
                        // check if model already exists in collection
                        if (!this.gridView.savedViews.get(successModel.id)) {
                            this.gridView.savedViews.add(viewModel);
                        }
                        const successModelViewId = successModel.get('viewId');
                        /*
                         * When creating a saved views we need to unmount and
                         * remount the mobile grid so that the saved views update appropriately.
                         */
                        this.changeSavedView(successModelViewId).then(() => {
                            this.onBeforeClose();
                            this.renderMobileGrid();
                            /** filters set temporarily until the new epic can be done */
                            resolve({
                                columnState: successModel.columnJSON,
                                sortState: newSort,
                                filterState: {},
                            });
                        });
                    },
                });
            });
        },

        changeSavedView(viewId) {
            this.gridView.wrapper.viewId = viewId;
            const selectedModel = this.gridView.savedViews.find(model => model.get('viewId') === viewId);

            if (this.gridView.wrapper.lvc) {
                this.gridView.wrapper.lvc.set(LvcConstants.VIEWID, viewId);
            }

            return Promise.all([
                this.refreshGridData(true, this.gridView, false),
                this.fetchSavedViewDetails(selectedModel),
            ]);
        },

        getComposedRows(rowCollection) {
            return rowCollection.map(row => ({
                ...row.toJSON(),
                cid: row.cid,
                buttons: row.buttons,
                context: row.context,
                model: row,
            }));
        },

        getComposedColumns(columnCollection) {
            return columnCollection.map(column => ({
                ...column.toJSON(),
                model: column,
            }));
        },

        pageData(page, rowsPerPage) {
            this.gridView.wrapper.currentPage = page;
            this.gridView.wrapper.rowsPerPage = rowsPerPage;
            // eslint-disable-next-line max-len
            return this.refreshGridData(false, null, true);
        },

        sortData(key, order) {
            this.gridView.wrapper.sortKey = key;
            this.gridView.wrapper.sortOrder = order;
            this.gridView.wrapper.currentPage = 1;
            if (this.gridView.wrapper.lvc) {
                this.gridView.wrapper.lvc.resetToFirstPage();
                this.gridView.wrapper.lvc.set(
                    LvcConstants.SORTFIELDS,
                    [{
                        fieldName: this.gridView.wrapper.sortKey,
                        sortOrder: this.gridView.wrapper.sortOrder,
                    }],
                );
            }
            return this.refreshGridData(false, null, true);
        },

        // this is an array of columns
        changeColumnData(columnState) {
            this.gridView.wrapper.columns.forEach((col) => {
                const columnFromState = columnState.find(c => c.field === col.get('field'));
                if (columnFromState) {
                    col.set(columnFromState);
                }
            });

            if (this.gridView.wrapper.lvc) {
                // save it without the model
                const newColumns = columnState.map(({ model, ...col }) => col);
                this.gridView.wrapper.lvc.set(
                    LvcConstants.COLUMNS,
                    newColumns,
                );
            }

            return this.getComposedColumns(this.gridView.wrapper.columns);
        },

        transformColumnDataForReact(columnsCollection) {
            return columnsCollection.toJSON();
        },

        filterData(filters) {
            // eslint-disable-next-line max-len
            const backboneFilters = this.transformFilterDataForBackbone(filters, this.gridView.wrapper.columns);
            this.gridView.wrapper.filters = backboneFilters;
            this.gridView.wrapper.currentPage = 1;
            if (this.gridView.wrapper.lvc) {
                this.gridView.wrapper.lvc.resetToFirstPage();
                this.gridView.wrapper.lvc.set(
                    LvcConstants.FILTERFIELDS,
                    this.gridView.wrapper.createFiltersForConfig(),
                );
            }
            return this.refreshGridData(false, null, true);
        },

        transformFilterDataForReact(filtersArray) {
            // eslint-disable-next-line max-len
            return filtersArray?.reduce((obj, { fieldName, fieldValue }) => ({ ...obj, [fieldName]: fieldValue }), {}) || {};
        },

        getOperator(columnModel, value) {
            const fieldType = columnModel.get('type');
            const hasOne = value?.length === 1 || false;
            switch (fieldType) {
            case 'string':
                return 'CONTAINS';
            case 'multistring':
                return 'IN';
            case 'masked':
                return 'CONTAINS';
            case 'date':
            case 'gmtdate':
            case 'gmtdatetime':
                if (hasOne) {
                    return 'EQ';
                }
                return 'BETWEEN';
            case 'amount':
            case 'number':
                return value?.numberType?.toUpperCase() || 'EQ';
            case 'enum':
            case 'typeahead':
            default:
                return 'EQ';
            }
        },

        getValue(value, operator) {
            if (operator === 'BETWEEN' && value.value?.start && value.value?.end) {
                return [value.value.start, value.value.end];
            }
            return value.value || value;
        },

        transformFilterDataForBackbone(filterState, columnCollection) {
            const filterArray = Object.entries(filterState).map(([field, value]) => {
                const columnModel = columnCollection.find(col => col.get('fieldName') === field);
                const operator = this.getOperator(columnModel, value);
                return {
                    field,
                    value: this.getValue(value, operator),
                    type: columnModel.get('type'),
                    dataType: columnModel.get('type') || 'text',
                    operator,
                    equality: operator,
                    enumHash: columnModel.get('enumHash') instanceof Collection
                        ? columnModel.get('enumHash').toJSON()
                        : columnModel.get('enumHash'),
                };
            });
            return new Collection(filterArray);
        },

        onBeforeClose() {
            if (mobileUtil.isMobileGridEnabled()) {
                const rootEl = listOptions.mountSelector ?
                    document.querySelector(`.mg-${this.cid}`) : this.el;
                ReactDOM.unmountComponentAtNode(rootEl);
            }
        },

        setSelectedRowModels(models) {
            this.selectedRowModels = models;
        },

        selectAllMobileGridRows({ isChecked }) {
            this.gridView.wrapper.rows.models.forEach((row) => {
                this.selectMobileGridRow({
                    model: row,
                    isChecked,
                    canOnlySelectOneRow: false,
                    fromSelectAll: true,
                });
            });

            this.trigger('mobilegrid:selectedAllRows', {
                isAllChecked: isChecked,
                selectedRows: this.selectedMobileRows,
            });
        },

        selectMobileGridRow(options) {
            const self = this;
            if (options.canOnlySelectOneRow) {
                this.selectedMobileRows = [options.model];
            } else {
                this.selectedMobileRows = options.isChecked
                    ? this.selectedMobileRows.concat(options.model)
                    : this.selectedMobileRows.filter(row => row.cid !== options.model.cid);
            }
            this.gridView.grid = {
                getSelectedRows() {
                    return self.selectedMobileRows.map(model => ({ cid: model.cid }));
                },
                getSelectedRowModels() {
                    return self.selectedMobileRows;
                },
            };

            this.trigger('mobilegrid:selectedRows', {
                fromSelectAll: options.fromSelectAll,
                selectedRows: this.selectedMobileRows,
            });
        },

        rowActionHandler(options) {
            this.gridView.grid = {
                getSelectedRows() {
                    return [{ cid: options.model.cid }];
                },
                getSelectedRowModels() {
                    return [options.model];
                },
            };
            const actionsToBeHandledByDesktopGrid = [
                'approve',
                'copytmpl',
                'delete',
                'makeinst',
                'maketmpl',
                'modify',
                'needinfo',
                'reject',
                'repair',
                'select',
                'spaymt',
                'unapprov',
                'unvalida',
                'validate',
                'viewsumt',
            ];

            if (actionsToBeHandledByDesktopGrid.includes(options.action)) {
                this.appBus.trigger(`grid:row:action:action_${options.action}_${this.gridView.cid}`, options.model);
            } else {
                this.gridRowAction(options);
            }
        },

        handleMobileBulkAction({ event, selectedRows, methodName }) {
            this.gridView.grid = {
                getSelectedRows() {
                    return selectedRows.map(row => ({ cid: row.cid }));
                },
                getSelectedRowModels() {
                    return selectedRows;
                },
            };
            const func = this[methodName];
            if (typeof func === 'function') {
                func.call(this, event);
            } else {
                this.handleBulkAction(methodName.toUpperCase());
            }
        },

        refreshGridData(...args) {
            return this.originalRefreshGridData(...args)
                .then(this.gridView.wrapper.processGridData.bind(this.gridView.wrapper))
                .then(data => ({
                    gridData: data,
                    rows: this.getComposedRows(this.gridView.wrapper.rows),
                    columns: this.getComposedColumns(this.gridView.wrapper.columns),
                    columnsToBeHidden: this.columnsToBeHidden || [],
                    rowActionHandler: this.rowActionHandler.bind(this),
                    selectRow: this.selectMobileGridRow.bind(this),
                    selectAllRows: this.selectAllMobileGridRows.bind(this),
                    interfaceInstance: this,
                    showSelector: listOptions.showSelector || false,
                }))
                .then((data) => {
                    this.options.onGridRefreshComplete?.(data);
                    return data;
                });
        },
    };
}
