import PaymentFromTemplateView from 'app/payments/views/paymentFromTemplate';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import constants from 'app/smbPayments/views/employees/api/constants';

const CopyPaymentEntryView = PaymentFromTemplateView.extend({
    initialize(opts) {
        const contextTemp = store.get('payment_listView_smbEmployee-contextOverride');
        contextTemp.loadFromPayments = '0';
        store.set('payment_listView_smbEmployee-contextOverride', contextTemp);

        const options = util.extend(
            constants.options,
            {
                context: 'SMB_EMPLOYEE',
                functionCode: contextTemp.functionCode,
                serviceName: contextTemp.serviceName,
                subType: contextTemp.subType,
                tnum: contextTemp.tnum,
                updateCount: contextTemp.updateCount,
                templateServiceName: contextTemp.templateServiceName,
                mode: 'insert',
                smbOverride: opts.smbOverride || contextTemp.smbOverride,
                reimburse: opts.reimburse || contextTemp.reimburse,
            },
        );

        PaymentFromTemplateView.prototype.initialize.call(this, options);
    },
});

export default CopyPaymentEntryView;
