import LayoutView from '@glu/core/src/layout';
import LiveMaintenanceMessageModel from 'app/administration/models/liveMaintenanceMessageModel';
import LiveMaintenanceMessageView from 'app/administration/views/liveMaintenance/liveMaintenanceMessageView';
import CollectionView from '@glu/core/src/collectionView';
import http from '@glu/core/src/http';
import { appBus } from '@glu/core';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import services from 'services';
import alert from '@glu/alerts';
import liveMaintenanceViewTmpl from './liveMaintenanceView.hbs';

export default LayoutView.extend({
    template: liveMaintenanceViewTmpl,

    regions: {
        messagesRegion: '.messages-region',
        alertRegion: '.alert-region',
    },

    initialize(options) {
        // set collection in this for easy access in other functions
        this.liveMaintenanceMessageCollection = options.liveMaintenanceMessageCollection;

        // create collection view using collection passed
        this.liveMaintenanceMessageCollectionView = new CollectionView({
            collection: this.liveMaintenanceMessageCollection,
            itemView: LiveMaintenanceMessageView,
        });
    },

    onRender() {
        this.showMessagesRegion();

        this.getInitialMaintenanceMode();
    },

    settings: {
        maintenanceMode: false,
        modifyMode: false,
    },

    ui: {
        $startMaintenanceSection: '[data-hook="getStartMaintenanceSection"]',
        $stopMaintenanceSection: '[data-hook="getStopMaintenanceSection"]',
        $modifyBtn: '[data-hook="getStartModifyMode"]',
        $modifyControlsOn: '[data-hook="getModifyControlsOn"]',
        $modifyControlsOff: '[data-hook="getModifyControlsOff"]',
    },

    events: {
        'click [data-hook="startMaintenance"]': 'getStartMaintenance',
        'click [data-hook="stopMaintenance"]': 'getStopMaintenance',
        'click @ui.$modifyBtn': 'startModifyMode',
        'click [data-hook="endModifyMode"]': 'endModifyMode',
        'click [data-hook="addLanguage"]': 'addLanguage',
        'click [data-hook="saveModels"]': 'saveModels',
    },

    appEvents: {
        'liveMaintenance.modelsSaved': 'modelsSaved',
        'liveMaintenance.saveError': 'saveError',
        'liveMaintenance.languageServiceError': 'languageServiceError',
        'liveMaintenance.getDataError': 'getDataError',
        'liveMaintenance.alertview': 'showAlert',
        'liveMaintenance.languageDropdownChanged': 'updateAllModels',
    },

    /**
     * Show messages region
     */
    showMessagesRegion() {
        this.messagesRegion.show(this.liveMaintenanceMessageCollectionView);
    },

    handleError(error) {
        throw new Error(error);
    },

    /**
     * Sets initial maintenance mode based on data returned from api
     */
    getInitialMaintenanceMode() {
        const maintenanceMode = this.retrieveApiData(services.liveMaintenanceCheck, 'get');

        // get initial maintenanceMode
        maintenanceMode.then(this.handleInitialMaintenanceMode)
            .catch(this.handleError);
    },

    /**
     * Sets the initial maintenance mode to true or false based on API response
     * @param  {object} response Object containing response from API
     * @return {null} Just used to fail fast
     */
    handleInitialMaintenanceMode(response) {
        // store the maintenance mode
        this.setMaintenanceMode(response.maintenanceMode);

        // start maintenance
        return this.getStartMaintenance();
    },

    /**
     * Start the maintenance mode by sending request to service
     */
    getStartMaintenance() {
        const startMaintenance = this.retrieveApiData(services.liveMaintenanceStart, 'get');

        return startMaintenance.then(this.handleStartMaintenance.bind(this))
            .catch(this.handleError.bind(this));
    },

    /**
     * Handle data that comes back from API
     * @param  {object} response Object containing response from API
     * @return {null} Just used to fail fast
     */
    handleStartMaintenance(response) {
        this.setMaintenanceMode(response.maintenanceMode);

        // set originalCollection value for restoration on cancellation of modify mode
        this.storeCollection();

        // if we are in modify mode make sure we get out
        if (this.settings.modifyMode) {
            this.endModifyMode();
        }

        // disable modify button
        this.disableModifyBtn();
    },

    /**
     * Stop the maintenance mode by sending request to service
     */
    getStopMaintenance() {
        const stopMaintenance = this.retrieveApiData(services.liveMaintenanceStop, 'get');

        stopMaintenance.then(this.handleStopMaintenance.bind(this))
            .catch(this.handleError.bind(this));
    },

    /**
     * Handle data that comes back from API
     * @param  {object} response Object containing response from API
     * @return {null} Just used to fail fast
     */
    handleStopMaintenance(response) {
        this.setMaintenanceMode(response.maintenanceMode);

        // enable modify button
        this.enableModifyBtn();
    },

    /**
     * Disables button when in maintenance mode as you cannot make changes
     */
    disableModifyBtn() {
        this.ui.$modifyBtn.prop('disabled', true);
    },

    /**
     * Re-enables modify button after maintenance mode ends
     */
    enableModifyBtn() {
        this.ui.$modifyBtn.removeProp('disabled');
    },

    /**
     * General function to hit apis and retrieve data
     * @param  {string} url    API endpoint
     * @param  {string} method API method
     * @return {promise} Data returned from API
     */
    retrieveApiData(url, method) {
        return new Promise((resolve, reject) => {
            http[method](url).then((response) => {
                resolve(response);
            }, (error) => {
                reject(error);
            });
        });
    },

    /**
     * Start modify language/message mode
     */
    startModifyMode() {
        // show modification elements
        this.toggleModifyControls(true);

        // store models in collection for restoration on cancel
        this.storeCollection();

        // set all editMode of all models in collection to true
        this.setModelEditMode(true);
    },

    /**
     * End modify language/message mode
     */
    endModifyMode() {
        // toggle modify and update/cancel controls
        this.toggleModifyControls(false);

        // reset collection so that any newly added models are removed
        this.restoreCollection();

        // set all editMode of all models in collection to false
        this.setModelEditMode(false);
    },

    /**
     * @method - Toggle the modify mode controls
     * @param  {boolean} modifyState Boolean indicating whether app is in
     * modify mode or not
     */
    toggleModifyControls(modifyState) {
        /**
         * modify mode or not
         */
        this.settings.modifyMode = modifyState;

        this.ui.$modifyControlsOff.toggleClass('active', !modifyState);
        this.ui.$modifyControlsOn.toggleClass('active', modifyState);
    },

    /**
     * Store live maintenance mode in settings
     * @param  {boolean} mode Live maintenance started
     */
    setMaintenanceMode(mode) {
        this.settings.maintenanceMode = mode;
        this.updateControlsView();
    },

    /**
     * Set dom/view based on live maintenance mode state
     */
    updateControlsView() {
        /*
         * if live maintenance is active then hide start maintenance controls
         * and show stop maintenance controls
         */
        this.ui.$startMaintenanceSection.toggleClass('active', !this.settings.maintenanceMode);
        this.ui.$stopMaintenanceSection.toggleClass('active', this.settings.maintenanceMode);
    },

    /**
     * adds another message in another language to the live maintenance page
     * collection
     */
    addLanguage() {
        let currentMessageModel = {};

        // first create new empty model
        currentMessageModel = new LiveMaintenanceMessageModel({
            LANGUAGE: '',
            MESSAGEHEADER: '',
            DETAIL: '',
            editState: true,
            valid: false,
        });

        // and add to collection so that the new blank fields with show up in view
        this.liveMaintenanceMessageCollection.add(currentMessageModel);
    },

    updateAllModels() {
        appBus.trigger('liveMaintenance.updateLanguageDropdowns');
    },

    /**
     * Call sync on collection with parameter of save to sent updated
     * models to server
     */
    saveModels() {
        if (this.modelsValid()) {
            // save collection to database
            this.liveMaintenanceMessageCollection.sync('save');

            return true;
        }
        /*
         * show error message because some fields are missing
         * TODO: replace message value with app resource when it becomes available
         */
        this.showAlert({
            alertType: 'negative',
            message: 'All fields are required. Please provide missing values in messages.',
        });

        return false;
    },

    /**
     * make sure all models have passed validation before saving
     * @return {boolean} did all models pass validation or not
     */
    modelsValid() {
        // return true if every model has a valid attribute set to true
        const valid = util.every(this.liveMaintenanceMessageCollection.models, model => model.get('valid') === true);

        return valid;
    },

    /**
     * Run on successful save of collection to database
     * @return {[type]} [description]
     */
    modelsSaved() {
        // show error message
        this.showAlert({
            alertType: 'success',
            message: locale.get('live.maintenance.save.successful'),
        });

        // toggle model states
        this.setModelEditMode(false);

        // toggle the modify message controls
        this.toggleModifyControls(false);
    },

    /**
     * @method - Set all editMode of all models based on editState
     * @param  {boolean} editState [description]
     */
    setModelEditMode(editState) {
        util.each(this.liveMaintenanceMessageCollection.models, (model) => {
            model.setEditState(editState);
        });
    },

    /**
     * Show alert view on language service load error
     */
    languageServiceError() {
        // TODO: replace message value with app resource when it becomes available
        this.showAlert({
            alertType: 'negative',
            message: 'There was a problem loading your language selections. Refreshing the page may solve this problem.',
        });
    },

    /**
     * Show alert view on client live maintenance page data load error
     */
    getDataError() {
        // TODO: replace message value with app resource when it becomes available
        this.showAlert({
            alertType: 'negative',
            message: 'There was a problem loading your data. Refreshing the page may solve this problem.',
        });
    },

    /**
     * Show alert view on collection save error
     * @return {[type]} [description]
     */
    saveError() {
        // TODO: replace message value with app resource when it becomes available
        this.showAlert({
            alertType: 'negative',
            message: 'There was a problem saving your language selections and message content. Please try again.',
        });
    },

    showAlert(alertObj) {
        this.alertRegion.show(alert[alertObj.alertType](alertObj.message));
    },

    /**
     * Store a copy of the collection
     */
    storeCollection() {
        this.settings.originalCollection = this.liveMaintenanceMessageCollection.toJSON();
    },

    /**
     * Restore collection to previous state
     */
    restoreCollection() {
        this.liveMaintenanceMessageCollection.reset(this.settings.originalCollection);
    },
});
