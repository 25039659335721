import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';

const RecipientsGroupAssignList = ListView.extend({

    initialize(options) {
        const superOptions = {
            menuCategory: 'ADMINISTRATION',
            serviceName: 'tableMaintenance/emailRecipGrpAssignment',
            serviceFunc: null,
            businessType: null,
            context: 'EMAIL_RECP_ASIGN',
            enableSavedViews: false,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

});

export default RecipientsGroupAssignList;
