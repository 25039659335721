import Model from '../model';

export default Model.extend({
  menuName: 'applications',

  setMenu(name) {
    this.menuName = name;
  },

  parse(obj) {
    return obj[this.menuName][0];
  }
});

