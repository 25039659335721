var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <input id=\"remitter-name\" type=\"radio\" value=\"new\" name=\"type\" data-bind=\"model\" checked>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"col-6\">\n                    <input id=\"remitter-id\" type=\"radio\" value=\"existing\" name=\"type\" data-bind=\"model\">\n                    <label for=\"payment-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.AssignAccountExistingRemitter",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":92}}}))
    + "</label>\n                    <select class=\"form-control\" data-bind=\"model\" name=\"REMITTER_ID\" data-hook=\"existing-remitters\" data-placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.select.place.holder",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":135},"end":{"line":18,"column":171}}}))
    + "\">\n                        <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"existing") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":22,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"REMITTER_ID\"></span>\n                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"REMITTER_ID") || (depth0 != null ? lookupProperty(depth0,"REMITTER_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"REMITTER_ID","hash":{},"data":data,"loc":{"start":{"line":21,"column":43},"end":{"line":21,"column":58}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"REMITTER_NAME") || (depth0 != null ? lookupProperty(depth0,"REMITTER_NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"REMITTER_NAME","hash":{},"data":data,"loc":{"start":{"line":21,"column":73},"end":{"line":21,"column":90}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-region=\"modalAlertRegion\"></div>\n<form autocomplete=\"off\" class=\"lbxRemitter\">\n    <div class=\"glu-container\">\n        <strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Our",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":36}}}))
    + "</strong>\n        <div class=\"row\">\n            <div class=\"col-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAssignExistingRemitters") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "                <label for=\"payment-type\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.RemitterName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":71}}}))
    + "</label>\n                <input type=\"text\" class=\"form-control\" name=\"REMITTER_NAME\" data-bind=\"model\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REMITTER_NAME\"></span>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAssignExistingRemitters") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <hr/>\n        <strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.AccountInformation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":51}}}))
    + "</strong>\n    </div>\n</form>\n<div data-region=\"accountInfoRegion\"></div>\n";
},"useData":true});