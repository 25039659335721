import React, { useState } from 'react';
import { CaretDownNextIcon, DotDotDotNextIcon } from '@glu/icons-react';
import locale from '@glu/locale';
import { withStyles, withTheme } from '@glu/theming';
import PropTypes from 'prop-types';
import Popover from '@glu/popover-react';
import styles from './Actions.styles';
import Menu from './Menu';
import actionsHelper from './actions_helper';

const Actions = ({
  classes,
  alwaysUseActionsPopover,
  api: gridApi,
  colDef: columnDefinition,
  gridId,
  rowIndex,
  gridRowHeight,
  displayFirstAction,
  PopoverProps,
  ...props
}) => {
  const [active, setActive] = useState();
  const { actions } = columnDefinition;
  const visibleActions = actions.reduce((result, action) => {
    if (action.condition !== undefined) {
      if (
        typeof action.condition === 'function'
          ? action.condition(props)
          : action.condition
      ) {
        result.push(action);
      }
    } else {
      result.push(action);
    }
    return result;
  }, []);

  const firstAction = visibleActions[0] || {};
  const restActions = visibleActions.slice(1, visibleActions.length);
  const firstActionProps = actionsHelper.getProps(firstAction, props);
  const Action = actionsHelper.getComponent(firstAction);
  const renderFirstAction = () => (
    <Action
      key={firstAction.label}
      className={classes.actionButton}
      type="button"
      data-qa={`${gridId}-${firstAction.label}-${rowIndex}`}
      {...firstActionProps}
    >
      {firstAction.label}
    </Action>
  );

  return (
    <div className={classes.wrapper}>
      {restActions.length || alwaysUseActionsPopover ? (
        <Popover
          placement="bottom-start"
          className={classes.popover}
          enablePopoverClick
          openOnHover
          noArrow
          content={({ closePopover, visible }) => {
            setActive(visible);
            return (
              <Menu
                classes={classes}
                restActions={displayFirstAction ? restActions : visibleActions}
                gridId={gridId}
                rowIndex={rowIndex}
                closePopover={closePopover}
                visible={visible}
                gridApi={gridApi}
                {...props}
              />
            );
          }}
          {...PopoverProps}
        >
          <div className={classes.buttonWrapper}>
            {displayFirstAction ? renderFirstAction() : null}
            <button
              className={classes.button}
              data-qa={`${gridId}-actions-${rowIndex}`}
              type="button"
              alt={locale.get('caretDown')}
              title={locale.get('expandActionsControl')}
            >
              {displayFirstAction
                ? <CaretDownNextIcon focusable noIconWrapper />
                : (
                  <DotDotDotNextIcon
                    className={`${classes.dot} ${active ? classes.dotActive : ''}`}
                    title={locale.get('actions')}
                  />
                )
              }
            </button>
          </div>
        </Popover>
      ) : (
        renderFirstAction()
      )}
    </div>
  );
};

Actions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  /** href will generate a link. handler will generate a button */
  colDef: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        href: PropTypes.string,
        handler: PropTypes.func,
        condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
      })
    )
  }).isRequired,
  /** Display a dropdown even if only one option */
  alwaysUseActionsPopover: PropTypes.bool,
  /** Ag grid grid api */
  api: PropTypes.shape({}).isRequired,
  /** String to determine qa attributes */
  gridId: PropTypes.string.isRequired,
  /** Number generated by Ag Grid */
  rowIndex: PropTypes.number.isRequired,
  /** Row height */
  gridRowHeight: PropTypes.oneOf(['small', 'medium']),
  /** Display first action instead of ellipsis icon */
  displayFirstAction: PropTypes.bool,
  /** Options for @popperjs/core see docs for details */
  PopoverProps: PropTypes.shape({})
};

Actions.defaultProps = {
  alwaysUseActionsPopover: false,
  displayFirstAction: false,
  gridRowHeight: null,
  PopoverProps: null
};

export const ActionsComponent = Actions;
export default withTheme(withStyles(styles)(Actions));
