var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"modal-header\">\n                <button type=\"button\" class=\"close\" data-action=\"closeModal\" data-dismiss=\"modal\"><span class=\"icon-close\"></span></button>\n                <h2 class=\"modal-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":49}}}) : helper)))
    + "</h2>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":58,"column":103},"end":{"line":58,"column":109}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":58,"column":111},"end":{"line":58,"column":126}}}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":79,"column":95},"end":{"line":79,"column":101}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":79,"column":103},"end":{"line":79,"column":118}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-<%- type %> sb-payments-modal\">\n    <div class=\"modal-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "\n                <div class=\"modal-body\">\n                        <div class=\"row\">\n                                <div class=\"col-lg-6\">\n                                        <input type=\"checkbox\" name=\"usePaymentRemittance\" data-bind=\"model\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"usePaymentRemittance") || (depth0 != null ? lookupProperty(depth0,"usePaymentRemittance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"usePaymentRemittance","hash":{},"data":data,"loc":{"start":{"line":13,"column":116},"end":{"line":13,"column":140}}}) : helper)))
    + "\"  data-hook=\"usePaymentRemittance\"/>\n                                        <label for=“use-payment-remittance-checkbox”><span class=\"payment-grid-header-label\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.usePaymentRemittance",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":125},"end":{"line":14,"column":169}}}))
    + "</span></label>\n                                </div>\n                        </div>\n                </div>\n                <div id=\"full-remittance\" aria-hidden=\"true\">\n                         <div class=\"modal-body\">\n                                        <div class=\"row\">\n                                                <div class=\"col-lg-6\">\n                                                        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.billedAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":113},"end":{"line":22,"column":149}}}))
    + "</span></div>\n                                                                <div class=\"payment-grid-header-input\">\n                                                                        <input id=\"billedAmount\" type=\"text\" class=\"form-control\" data-bind=\"model\" title=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.billedAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":155},"end":{"line":24,"column":191}}}))
    + "\" name=\"billedAmount\" maxlength=\"30\"/>\n                                                                </div>\n                                                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"billedAmount\"></span>\n                                                </div>\n                                                <div class=\"col-lg-6\">\n\n                                                </div>\n                                        </div>\n                                        <div class=\"row\">\n                                                <div class=\"col-lg-6\">\n                                                        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.discountAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":113},"end":{"line":34,"column":151}}}))
    + "</span></div>\n                                                                <div class=\"payment-grid-header-input\">\n                                                                        <input id=\"discountAmount\" type=\"text\" class=\"form-control\" data-bind=\"model\" title=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.discountAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":157},"end":{"line":36,"column":195}}}))
    + "\" name=\"discountAmount\" maxlength=\"30\"/>\n                                                                </div>\n                                                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"discountAmount\"></span>\n                                                </div>\n                                                <div class=\"col-lg-6\">\n\n                                                </div>\n                                        </div>\n                                        <div class=\"row\">\n                                                <div class=\"col-lg-6\">\n                                                        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.adjustmentAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":113},"end":{"line":46,"column":153}}}))
    + "</span></div>\n                                                                <div class=\"payment-grid-header-input\">\n                                                                        <input id=\"adjustmentAmount\" type=\"text\" class=\"form-control\" data-bind=\"model\" title=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.adjustmentAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":159},"end":{"line":48,"column":199}}}))
    + "\" name=\"adjustmentAmount\" maxlength=\"30\"/>\n                                                                </div>\n                                                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"adjustmentAmount\"></span>\n                                                </div>\n\n                                                <div class=\"col-lg-6\">\n                                                        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.adjustmentReason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":113},"end":{"line":54,"column":153}}}))
    + "</span></div>\n                                                                <select id=\"adjustmentReason\" class=\"form-control\" data-bind=\"model\" name=\"adjustmentReason\" data-hook=\"adjustmentReason\">\n                                                                 <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"adjustmentReasons") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":80},"end":{"line":59,"column":89}}})) != null ? stack1 : "")
    + "                                                                        </select>\n                                                </div>\n                                        </div>\n                        </div>\n                        <div class=\"modal-body\">\n                                <div class=\"row\">\n                                        <div class=\"col-lg-6\">\n                                                <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.referenceNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":105},"end":{"line":67,"column":144}}}))
    + "</span></div>\n                                                        <div class=\"payment-grid-header-input\">\n                                                                <input id=\"referenceNumber\" type=\"text\" class=\"form-control\" data-bind=\"model\" title=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.referenceNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":69,"column":150},"end":{"line":69,"column":189}}}))
    + "\" name=\"referenceNumber\" maxlength=\"30\"/>\n                                                        </div>\n                                                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"routingNumber\"></span>\n                                        </div>\n                                         <div class=\"col-lg-6\">\n                                                <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.referenceNumberType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":105},"end":{"line":74,"column":148}}}))
    + "</span></div>\n                                                        <div class=\"payment-grid-header-input\">\n                                                                <select id=\"referenceNumberType\" class=\"form-control\" data-bind=\"model\" name=\"referenceNumberType\" data-hook=\"referenceNumberType\">\n                                                                <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"referenceNumberTypes") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":72},"end":{"line":80,"column":81}}})) != null ? stack1 : "")
    + "                                                                </select>\n                                                        </div>\n                                                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"referenceNumberType\"></span>\n                                        </div>\n                                </div>\n                                <div class=\"row\">\n                                        <div class=\"col-lg-12\">\n                                                <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.paymentDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":88,"column":105},"end":{"line":88,"column":147}}}))
    + "</span></div>\n                                                        <div class=\"payment-grid-header-input\">\n                                                                <textarea data-bind=\"model\" name=\"paymentDescription\" id =\"paymentDescription\" class=\"form-control\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxLength") || (depth0 != null ? lookupProperty(depth0,"maxLength") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxLength","hash":{},"data":data,"loc":{"start":{"line":90,"column":175},"end":{"line":90,"column":188}}}) : helper)))
    + "\"></textarea>\n                                                                <div class=\"pull-right char-counter\" id=\"char-counter\"> </div>\n                                                        </div>\n                                                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"paymentDescription\"></span>\n                                        </div>\n                                </div>\n                        </div>\n                </div>\n                <div id=\"partial-remittance\"  aria-hidden=\"false\">\n                        <div  class=\"modal-body\">\n                                <textarea data-bind=\"model\" name=\"comment\" id =\"business-comments\" class=\"form-control\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxLength") || (depth0 != null ? lookupProperty(depth0,"maxLength") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxLength","hash":{},"data":data,"loc":{"start":{"line":100,"column":131},"end":{"line":100,"column":144}}}) : helper)))
    + "\"></textarea>\n                                 <div class=\"pull-right char-counter\" id=\"char-counter\"> </div>\n                        </div>\n                </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" id=\"comment-modal-ok-button\" class=\"btn btn-primary\" data-disable-on-submit data-action=\"saveComment\">\n                                "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.okBtn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":106,"column":32},"end":{"line":106,"column":61}}}))
    + "\n            </button>\n            <button type=\"button\" id=\"comment-modal-cancel-button\" class=\"btn btn-link\"  data-action=\"closeModal\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"sbPayments.cancelBtn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":108,"column":114},"end":{"line":108,"column":147}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});