import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import template from './warningDialog.hbs';

const WarningDialog = ItemView.extend({
    template,
    grid: null,

    initialize(options) {
        this.model = options.model;
        this.multiAdd = options.multiAdd;
        this.modelsArray = options.modelsArray;
        this.methodName = options.methodName;
        this.grid = options.grid;
        this.multipleApproval = options.multipleApproval;
        this.isQuickTransfer = options.isQuickTransfer;
        this.quickEntryCollection = options.quickEntryCollection;
        this.isQE = options.isQE;
        this.isFromFooter = options.isFromFooter;
        this.origConfirmData = {
            confirmResults: [],
        };
        this.confirms = {
            confirmResults: [],
        };
        if (options.confirms) {
            if (options.modelsArray) {
                this.origConfirmData = options.confirms.confirms;
                const results = options.confirms.confirms.confirmResults;
                for (let j = 0; j < results.length; j += 1) {
                    if (results[j].resultType === 'WARNING') {
                        this.confirms.confirmResults.push(results[j]);
                    }
                }
                // this.confirms.confirmResults = options.confirms.confirms.confirmResults;
            } else {
                this.confirms.confirmResults = options.confirms.confirmResults;
            }
        }
        if (options.updateCount) {
            this.model.set('UPDATECOUNT__', options.updateCount);
        }
        if (options.entryMethod) {
            this.model.set('ENTRYMETHOD', options.entryMethod);
        }
        if (options.keyValue) {
            this.model.set('TNUM', options.keyValue);
        }
    },

    // Necessary when placing the view with `dialog.custom`
    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
        'data-backdrop': 'static',
    },

    warningMessage: locale.get('common.proceed.warning.confirm'),

    // Note the use of `data-submit` in `formDialog.hbs`
    save() {
        if (this.methodName === 'SAVE' || this.methodName === 'CREATE') {
            if (this.isQuickTransfer || this.multiAdd) {
                this.trigger('saveWithWarning', false);
            } else if (this.isQE) {
                this.trigger(
                    'saveQEWithWarning',
                    this.quickEntryCollection,
                    {
                        name: '_saveWithWarning',
                        value: 'true',
                    },
                );
            }
            if (this.model) {
                this.model.trigger('modelAction:saveWithWarning', this.model, {
                    isFromFooter: this.isFromFooter,
                });
            }
        } else if (this.methodName === 'APPROVE') {
            if (this.modelsArray) {
                this.trigger(
                    'list:multi:action:action_approveWithWarning',
                    this.modelsArray,
                    {
                        name: '_saveWithWarning',
                        value: 'true',
                    },
                );
            } else if (this.grid) {
                this.model.set({
                    _saveWithWarning: 'true',
                });
                this.grid.trigger(
                    'rowAction',
                    {
                        action: 'approve',
                        model: this.model,
                    },
                );
            } else {
                this.model.set('_saveWithWarning', 'true');
                this.model.trigger('modelAction:approveWithWarning', {
                    isFromFooter: this.isFromFooter,
                    warningName: '_saveWithWarning',
                });
            }
        } else if (this.methodName === 'DELETE') {
            if (this.modelsArray) {
                this.trigger(
                    'list:multi:action:action_deleteWithWarning',
                    this.modelsArray,
                    {
                        name: '_saveWithWarning',
                        value: 'true',
                    },
                );
            } else if (this.grid) {
                this.model.set({
                    _saveWithWarning: 'true',
                });
                this.grid.trigger(
                    'rowAction',
                    {
                        action: 'delete',
                        model: this.model,
                    },
                );
            } else {
                this.model.trigger('modelAction:deleteWithWarning', this.model);
            }
        } else if (this.methodName === 'MODIFY') {
            // call for modify action
            this.model.trigger('modelAction:modifyWithWarning', this.model);
        } else if (this.methodName === 'REPAIR') {
            // call for repair action
            this.model.trigger('modelAction:repairWithWarning', this.model);
        }
        this.close();
    },

    cancel() {
        /*
         * UPDATECOUNT__ has been updated at backend, need to update here
         * to avoid 'Failed to get instruction data model' error when re-submit
         */
        if (this.model
            && this.confirms
            && this.confirms.confirmResults
            && this.confirms.confirmResults[0]
            && this.confirms.confirmResults[0].updateCount) {
            this.model.set('UPDATECOUNT__', this.confirms.confirmResults[0].updateCount);
        }
        if (this.methodName === 'SAVE' && this.model && this.model.error
            && this.model.error.errorCode === constants.SERVICE_WARNING_CODE) {
            this.model.trigger('modelAction:cancelWarning', this.model);
        } else if (this.methodName === 'APPROVE' || this.methodName === 'DELETE') {
            if (this.modelsArray) {
                this.trigger(
                    'list:multi:action:action_continueWithResponse',
                    this.modelsArray,
                    this.origConfirmData,
                    this.methodName,
                );
                this.close();
            } else if (this.options && this.options.footerForm) {
                this.options.footerForm.handleDialogCancel(this.options.resp);
            } else if (this.options && this.options.detailPage
                && this.options.detailPage.pageView
                && this.options.detailPage.pageView.model) {
                this.options.detailPage.pageView.model.trigger(
                    'modelAction:errorForDetail',
                    this.options.detailPage.pageView.model,
                );
                this.close();
            } else if (this.model) {
                this.model.trigger('modelAction:errorForDetail', this.model);
                this.close();
            }
        } else if (this.multiAdd || this.isQE) {
            this.close();
        } else {
            this.model.trigger('modelAction:errorForDetail', this.model);
            this.close();
        }
    },

    templateHelpers() {
        const self = this;
        return {
            confirms: self.confirms,

            confirmResults() {
                return util.map(self.confirms.confirmResults, (confirmResultParam) => {
                    const confirmResult = confirmResultParam;
                    confirmResult.messages = util.map(confirmResult.messages, (message) => {
                        let localMessage = message;
                        if (localMessage.indexOf('INFO@') > -1) {
                            localMessage = {
                                messageContent: localMessage.split('@')[1],
                                messageType: constants.messageType.info,
                            };
                        } else if (localMessage.indexOf('TITLE@') > -1) {
                            localMessage = {
                                customTitle: localMessage.split('@')[1],
                                messageContent: (localMessage.split('@')[2]),
                                messageType: constants.messageType.warning,
                            };
                        } else {
                            localMessage = {
                                messageContent: localMessage.trim(),
                                messageType: constants.messageType.warning,
                            };
                        }
                        // replaces the \n with an array representation
                        localMessage.messageContentArray = localMessage.messageContent.split('\\n');
                        // gives us back a single string with no \n in it..
                        localMessage.messageContent = localMessage.messageContentArray.join(' ');
                        return localMessage;
                    });
                    return confirmResult;
                });
            },

            getPaymentNumber() {
                return self.confirms.confirmResults.length;
            },

            hasMultipleApproval1() {
                return (self.multipleApproval === true);
            },
        };
    },
});

export default WarningDialog;
