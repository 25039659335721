import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import BaseView from './baseView';
import contactFormTmpl from './contactForm.hbs';
import OrderingPartyView from './orderingPartyView';
import AddressView from './addressView';
import PersonView from './personView';
import PersonalInfoView from './personalInfoView';
import BusinessInfoView from './businessInfoView';
import contactUtil from '../util/contactUtil';

const ContactForm = BaseView.extend({
    initialize() {
        this.mode = this.options.mode || 'create';
        // these values get set again onrender
        this.hasBusinessAccess = false;
        this.hasIndividualAccess = false;
        this.hasEmployeeAccess = false;
        this.rtpEntitled = false;
        this.requirePersonalInfo = applicationConfigParams.getValue('RTP', 'REQUIREPERSONALID') === '1';

        if (this.mode.toUpperCase() === 'CREATE') {
            this.model.set('_mode', 'INSERT');
            this.model.set('_actionMode', 'INSERT');
        } else {
            this.model.set('_mode', 'UPDATE');
            this.model.set('_actionMode', 'UPDATE');
        }

        // if contactType is 4, then both 2 and 3 should be selected
        const selectedType = this.model.get('CONTACTTYPE');
        if (selectedType === '4') {
            this.model.set('CONTACTTYPE', ['2', '3']);
        }

        this.orderingPartyView = new OrderingPartyView({
            model: this.model,
            orderingPartyIdTypes: this.options.orderingPartyIdTypes,
        });

        this.addressView = new AddressView({
            model: this.model,
            states: this.options.states,
            countries: this.options.countries,
        });

        this.personView = new PersonView({
            model: this.model,
        });

        this.personalInfoView = new PersonalInfoView({
            model: this.model,
            countries: this.options.countries,
        });

        this.businessInfoView = new BusinessInfoView({
            model: this.model,
        });
    },

    template: contactFormTmpl,

    ui: {
        $addAddressLineBtn: '.add-address-line',
        $AddressLineTwo: '.address-line-two',
        $checkboxBusiness: '#businessCheckbox',
        $checkboxEmployee: '#employeeCheckbox',
        $checkboxIndividual: '#individualCheckbox',
        $contactTypes: 'input[name="CONTACTTYPE"]',
        $selectedContactTypes: 'input[name="CONTACTTYPE"]:checked',
        form: 'form',
    },

    regions: {
        orderingPartyRegion: '.orderingparty-container',
        addressRegion: '.address-container',
        personRegion: '.person-container',
        personalInfoRegion: '.personalinfo-container',
        businessInfoRegion: '.businessinfo-container',
    },

    events: {
        'change @ui.$contactTypes': 'changeContactTypeHandler',
        'change @ui.$checkboxEmployee': 'toggleEmployeeCheckbox',
        'click  @ui.$contactTypes': 'confirmContactTypeChange',
        'click @ui.$addAddressLineBtn': 'addAddressLine',
    },

    addAddressLine() {
        this.ui.$AddressLineTwo.show();
        this.ui.$addAddressLineBtn.hide();
    },

    setInitialContactTypeState() {
        const contactType = contactUtil.getContactType(this.model);
        // disable based on selected value

        if (this.contactTypeDisable) {
            this.ui.$contactTypes.prop('disabled', true);
        } else if (contactType !== undefined) {
            if (!contactType) {
                this.unlockAllTypes();
            } else if (contactType === '1') {
                this.lockEmployeeType();
                this.lockIndividualType();

                if (this.mode.toUpperCase() === 'MODIFY') {
                    this.ui.$contactTypes.prop('disabled', true);
                }
            } else if (contactType === '2') {
                this.lockBusinessType();

                if (this.mode.toUpperCase() === 'MODIFY') {
                    this.ui.$checkboxIndividual.prop('disabled', true);
                }
            } else if (contactType === '3') {
                this.lockBusinessType();

                if (this.mode.toUpperCase() === 'MODIFY') {
                    this.ui.$checkboxEmployee.prop('disabled', true);
                    this.trigger('employeeCheckboxToggled', { target: { checked: true } });
                }
            } else {
                // both individual and employee are selected
                this.lockBusinessType();
                this.unlockEmployeeType();
                this.unlockIndividualType();
            }
        }

        // disable based on entitlements
        if (!this.hasBusinessAccess) {
            this.ui.$checkboxBusiness.prop('disabled', true);
        }
        if (!this.hasIndividualAccess) {
            this.ui.$checkboxIndividual.prop('disabled', true);
        }
        if (!this.hasEmployeeAccess) {
            this.ui.$checkboxEmployee.prop('disabled', true);
        }
    },

    disableContactTypes() {
        this.contactTypeDisable = true;
    },

    enableContactTypes() {
        this.contactTypeDisable = false;
    },

    unlockAllTypes() {
        this.unlockEmployeeType();
        this.unlockIndividualType();
        this.unlockBusinessType();
    },

    lockEmployeeType() {
        if (this.ui.$checkboxEmployee.length > 0) {
            this.ui.$checkboxEmployee.parent().addClass('locked');
            this.ui.$checkboxEmployee.prop('disabled', true);
        }
    },

    unlockEmployeeType() {
        if (this.hasEmployeeAccess) {
            this.ui.$checkboxEmployee.parent().removeClass('locked');
            this.ui.$checkboxEmployee.prop('disabled', false);
        }
    },

    lockIndividualType() {
        if (this.ui.$checkboxIndividual.length > 0) {
            this.ui.$checkboxIndividual.parent().addClass('locked');
            this.ui.$checkboxIndividual.prop('disabled', true);
        }
    },

    unlockIndividualType() {
        if (this.hasIndividualAccess) {
            this.ui.$checkboxIndividual.parent().removeClass('locked');
            this.ui.$checkboxIndividual.prop('disabled', false);
        }
    },

    lockBusinessType() {
        if (this.ui.$checkboxBusiness.length > 0) {
            this.ui.$checkboxBusiness.parent().addClass('locked');
            this.ui.$checkboxBusiness.prop('disabled', true);
        }
    },

    unlockBusinessType() {
        if (this.hasBusinessAccess) {
            this.ui.$checkboxBusiness.parent().removeClass('locked');
            this.ui.$checkboxBusiness.prop('disabled', false);
        }
    },

    /**
     * When the contact type checkboxes are clicked, show confirmation dialog
     * @param {Event} e - click event
     * @returns {boolean} - whether or not to allow propagation
     */
    confirmContactTypeChange(e) {
        if (this.options.accountCollection.length > 0) {
            dialog.confirm(locale.get('bab.contact.type.change.confirm'), locale.get('bab.contact.type.change.confirm.title'), this.onDialogAction.bind(this, e));
            return false;
        }
        return true;
    },

    /**
     * Evaluate the dialog action and update DOM and model accordingly
     * @param {Event} e - click event
     * @param {boolean} confirm - whether the dialog was confirmed or not
     */
    onDialogAction(e, confirm) {
        if (confirm) {
            e.target.checked = !e.target.checked;
            this.updateContactTypes(e.target.value, e.target.checked);
            this.enableContactTypes();
            this.setInitialContactTypeState();
            this.removeAccounts();
        }
    },

    /**
     * Update the model by either adding or removing the value to the CONTACTTYPE array
     * @param {string} value - value of changed checkbox
     * @param {boolean} checked - status of changed checkbox
     */
    updateContactTypes(value, checked) {
        let contactTypes = this.model.get('CONTACTTYPE') || [];
        if (checked) {
            if (util.isArray(contactTypes)) {
                contactTypes.push(value);
            } else {
                contactTypes = [contactTypes, value];
            }
        } else {
            if (!util.isArray(contactTypes)) {
                contactTypes = contactTypes.split(',');
            }
            contactTypes = contactTypes.filter(type => type !== value);
        }
        this.model.set('CONTACTTYPE', contactTypes);
    },

    /**
     * trigger an event for resetting the account form
     */
    removeAccounts() {
        this.trigger('handle:account:form:reset');
    },

    toggleEmployeeCheckbox(e) {
        this.trigger('employeeCheckboxToggled', e);
    },

    changeContactTypeHandler(e) {
        const selectedItem = e.target;
        const isChecked = e.target.checked;
        const isCreateMode = this.mode.toUpperCase() === 'CREATE';
        const isModifyMode = this.mode.toUpperCase() === 'MODIFY';
        const isIndividualSelected = this.ui.$checkboxIndividual.prop('checked');
        const isEmployeeSelected = this.ui.$checkboxEmployee.prop('checked');

        if (isChecked) {
            // if business checked
            if (this.ui.$checkboxBusiness.length > 0
                && selectedItem.id === this.ui.$checkboxBusiness[0].id) {
                this.lockEmployeeType();
                this.lockIndividualType();
            } else {
                // if employee or individual checked
                this.lockBusinessType();

                /*
                 * if modify mode, and both employee & individual are checked, enable both
                 * checkboxes
                 */
                if (isModifyMode && isEmployeeSelected && isIndividualSelected) {
                    if (this.hasEmployeeAccess) {
                        this.ui.$checkboxEmployee.prop('disabled', false);
                    }
                    if (this.hasIndividualAccess) {
                        this.ui.$checkboxIndividual.prop('disabled', false);
                    }
                }
            } // if business unchecked
        } else if (this.ui.$checkboxBusiness.length > 0
            && selectedItem.id === this.ui.$checkboxBusiness[0].id) {
            this.unlockEmployeeType();
            this.unlockIndividualType();
        } else if (this.ui.$checkboxEmployee.length > 0
            && selectedItem.id === this.ui.$checkboxEmployee[0].id) {
            if (isCreateMode && !isIndividualSelected) {
                this.unlockBusinessType();
            } else if (isModifyMode && isIndividualSelected) {
                this.ui.$checkboxIndividual.prop('disabled', true);
            }
        } else if (this.ui.$checkboxIndividual.length > 0
            && selectedItem.id === this.ui.$checkboxIndividual[0].id) {
            if (isCreateMode && !isEmployeeSelected) {
                this.unlockBusinessType();
            } else if (isModifyMode && isEmployeeSelected) {
                this.ui.$checkboxEmployee.prop('disabled', true);
            }
        }
        this.model.trigger('contactTypeChanged');
        this.render();
    },

    delegateEvents() {
        BaseView.prototype.delegateEvents.call(this);
        this.listenTo(this.options.contactTypes, 'change reset remove', this.render);
    },

    isPersonalInfoContactType() {
        const contactType = this.model.get('CONTACTTYPE');
        return (!contactType || (contactType === '2' || contactType === '3') || util.isArray(contactType));
    },

    onRender() {
        const contactType = this.model.get('CONTACTTYPE');
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            this.contactTypes = this.options.contactTypes || null;

            if (this.contactTypes) {
                this.hasBusinessAccess = this.hasAccessContactType('BU');
                this.hasIndividualAccess = this.hasAccessContactType('IN');
                this.hasEmployeeAccess = this.hasAccessContactType('EM');
            }

            this.setInitialContactTypeState();

            if (this.mode.toUpperCase() === 'MODIFY') {
            // Show the second address line if the model has a value
                if (this.model.get('BENE_ADDRESS_2') && this.model.get('BENE_ADDRESS_2') !== '') {
                    this.ui.$addAddressLineBtn.hide();
                } else {
                    this.ui.$AddressLineTwo.hide();
                }
            } else {
            // Create mode

                // hide the second address line
                this.ui.$AddressLineTwo.hide();
            }

            if (this.fieldTypeData && this.fieldTypeData.ALLOWBYORDEROF
                && orderingPartyUtil.isEntitled(this.fieldTypeData.ALLOWBYORDEROF.value)) {
                this.orderingPartyRegion.show(this.orderingPartyView);
            }

            this.addressRegion.show(this.addressView);

            this.personRegion.show(this.personView);

            if (this.rtpEntitled && this.requirePersonalInfo
                && this.isPersonalInfoContactType()) {
                this.personalInfoRegion.show(this.personalInfoView);
            } else if (contactType === '1' && this.rtpEntitled) {
                this.businessInfoRegion.show(this.businessInfoView);
            }

            infoTooltipHelper.setupInfoTooltips(this);
        }
    },

    loadRequiredData() {
        const self = this;
        const rtpEntitlement = contactUtil.entitlementPromise('RTP');
        rtpEntitlement
            .then((results) => {
                self.handleRtpEntitlement(results);
                self.setHasLoadedRequiredData(true);
                self.render();
            });
    },

    handleRtpEntitlement(model) {
        this.rtpEntitled = model.get('isEntitled');
    },

    hasAccessContactType(shortName) {
        let hasAllContactTypes = false;

        if (this.options.contactTypes) {
            hasAllContactTypes = this.options.contactTypes.find(item => item.get('name') === '_ALL_');

            if (hasAllContactTypes) {
                return true;
            }
            const matches = this.options.contactTypes.find(item => item.get('name') === shortName);

            if (matches) {
                return true;
            }
            return false;
        }
        return false;
    },

    templateHelpers() {
        const self = this;

        return {
            showBusiness() {
                return self.hasAccessContactType('BU');
            },

            showIndividual() {
                return self.hasAccessContactType('IN');
            },

            showEmployee() {
                return self.hasAccessContactType('EM');
            },
            fieldTypeData: self.fieldTypeData,
        };
    },
});

export default ContactForm;
