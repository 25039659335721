import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import BusinessPaymentsCollection from 'app/smb/collections/business';
import ActionCell from 'common/dynamicPages/views/gridActionCell';
import SimpleCellView from 'app/smb/views/cells/simple';
import ModalCellView from 'app/smb/views/cells/complexModal';
import SmbPaymentOptions from 'app/smb/models/newPaymentOptions';
import RecipientCellView from 'app/smb/views/recipient/recipient';
import Model from '@glu/core/src/model';
import Navigation from 'app/smb/navigation';
import BusinessPaymentGridView from '../baseAddGrid';
import BusinessPaymentGridAddRowView from './addRow';
import EditOptionsView from '../modals/editPaymentOptions';
import baseActions from '../baseActions';
import baseTmpl from './base.hbs';

const paymentTypeId = 'SMBPAYBUSINESS';

// This model is used for validation
const BaseModel = function () {
    const ThisModel = Model.extend({
        defaults: {
            fromAccount: '',
            totalPayments: '',
            balance: '',
        },

        validators: {
            fromAccount: {
                description: locale.get('sbPayments.fromAccount'),
                exists: true,
            },

            totalPayments: {
                description: locale.get('sbPayments.totalPayments'),
                minValue: 1,
            },
        },

        displayAmount(balance) {
            if (balance !== locale.get('sbPayments.notAvailable')) {
                return Formatter.formatCurrency(balance);
            }
            return balance;
        },
    });
    return new ThisModel();
};

export default Layout.extend({
    template: baseTmpl,
    draftSaved: false,
    businessPaymentsCollection: new BusinessPaymentsCollection(),
    smbPaymentOptions: new SmbPaymentOptions(),

    initialize(options) {
        this.listenTo(this.businessPaymentsCollection, 'all', this.updateTotal);
        this.recipientList = options.recipientList;
        this.fromAccountList = options.fromAccountList;
        this.paymentTypeCollection = options.paymentTypeCollection;
        this.countryCollection = options.countryList;
        this.accountTypeCollection = options.accountTypeList;
        this.adjustmentReasonCollection = options.adjustmentReasonList;
        this.referenceNumberTypeCollection = options.referenceNumberTypeList;
        this.dates = options.dates;
        this.cutoffTimes = options.cutoffTimes;
        this.optionId = options.optionId;
        this.payTypes = this.paymentTypeCollection.models[0].get('smbPayMenu');
        [this.optsArray] = util.where(
            this.payTypes,
            {
                menuCategory: paymentTypeId,
            },
        );
        this.payOptions = this.optsArray.smbMenuGroupItems;
        [this.payOptsSelected] = util.where(
            this.payOptions,
            {
                menuCode: this.optionId,
            },
        );
        this.smbPaymentOptions.set('menuCode', this.optionId);
        this.smbPaymentOptions.set('menuDescription', this.payOptsSelected.menuDescription);
        this.smbPaymentOptions.set('optionCost', this.payOptsSelected.optionCost);
        this.model = new BaseModel();
        this.modalCellView = ModalCellView.extend({
            adjustmentReasons: this.adjustmentReasonCollection,
            referenceNumberTypes: this.referenceNumberTypeCollection,
        });
    },

    onRender() {
        this.ui.$paymentsList.comboBox();
        this.ui.$fromAccount.comboBox({
            placeholder: locale.get('smb.account.select'),
            allowClear: true,
        });

        const self = this;
        this.ui.$fromAccount.on('change', (e) => {
            self.setFromAccount(e);
        });

        this.ui.$paymentsList.comboBox('val', paymentTypeId);
        this.ui.$totalLabel.text(`${locale.get('sbPayments.totalPaymentsLabel')} ${Formatter.formatCurrency(this.businessPaymentsCollection.total())}`);
    },

    onShow() {
        const columns = new Collection([{
            title: locale.get('sbPayments.recipient'),
            field: 'BENE_NAME',
            type: RecipientCellView,
        }, {
            title: locale.get('sbPayments.effectiveDate'),
            field: 'paymentDisplayDate',
            type: SimpleCellView,
        }, {
            title: locale.get('sbPayments.comment'),
            field: 'comment',
            sort: 'string',
            type: this.modalCellView,
            editCell: 'text',
        }, {
            title: locale.get('sbPayments.amount'),
            field: 'displayAmount',
            sort: 'string',
            type: SimpleCellView,
            editCell: 'text',
        }, {
            type: ActionCell,
            width: 100,
            resizing: false,
            className: 'text-center btn-cell',
            display: 'iconList',

            buttons: [{
                value: 'edit',
                label: locale.get('smb.edit'),
                icon: 'pencil',
            }, {
                value: 'delete',
                label: locale.get('smb.delete'),
                icon: 'cancel',
            }],

            editButtons: [{
                value: 'cancel',
                label: locale.get('smb.cancel'),
            }],
        }]);

        this.businessPaymentGrid = new BusinessPaymentGridView({
            AddEditRowView: BusinessPaymentGridAddRowView,
            columns,
            collection: this.businessPaymentsCollection,

            editOptions: {
                recipients: this.recipientList,
                country: this.countryCollection.toJSON(),
                accountType: this.accountTypeCollection.toJSON(),
                adjustmentReasons: this.adjustmentReasonCollection.toJSON(),
                referenceNumberTypes: this.referenceNumberTypeCollection.toJSON(),
                blockedDates: this.dates.blockedDates,
                maxDate: this.dates.maxDate,
                cutoffTimes: this.cutoffTimes,
            },
        });

        this.content.show(this.businessPaymentGrid);
    },

    ui: {
        $fromAccount: '[data-hook="from-account"]',
        $paymentsList: '[data-hook="payments-list"]',
        $totalLabel: '[data-hook="total-text"]',
        $submitButton: '[data-hook="submit-payments"]',
        $saveButton: '[data-hook="save-draft"]',
        $menuDescription: '[data-hook="menuDesc"]',
        $optionCost: '[data-hook="optCost"]',
        $fromAccSelected: '[data-hook="fromAccSelected"]',
        $selectedRecipient: '[data-hook="recipient-selected"]',
    },

    appEvents: {
        'sbPayments:paymentListUpdated': 'updateTotal',
        'sbPayments:recipientListUpdated': 'updateRecipient',
        'sbPayments:recipientAddRowUpdated': 'updateAddRowRecipient',
        'sbPayments:paymentCollectionUpdated': 'updatePaymentCollection',
    },

    events: {
        'select2-selecting #payments-list': 'selectChange',
        'select2-selecting #fromAccount': 'setFromAccount',
    },

    templateHelpers() {
        const paymentTypes = util.sortBy(this.paymentTypeCollection.models[0].get('smbPayMenu'), 'displayOrder');

        return {
            paymentTypes,
            optionId: this.smbPaymentOptions.get('menuCode'),
            menuDescription: this.smbPaymentOptions.get('menuDescription'),
            optionCost: this.smbPaymentOptions.displayAmount(this.smbPaymentOptions.get('optionCost')),
            fromAccounts: this.fromAccountList.toJSON(),
        };
    },

    selectChange(e) {
        /*
         * paytype can be taken from one of the collections like below.
         * As it is not permitting in few screens, defined value
         * 'paymentTypeId' is taken to compare the menucategory.
         */
        const paytype = this.payOptsSelected.menuCategory;
        const self = this;

        if (paytype !== e.val) {
            dialog.confirm(locale.get('smb.change.paymenttype.warning'), 'Warning', (ok) => {
                if (ok) {
                    Navigation.navigate(e, self);
                }
                self.ui.$paymentsList.comboBox('val', paymentTypeId);
            });
        }
    },

    setFromAccount(e) {
        this.fromAccount = e.val;
        if (util.isEmpty(e.val)) {
            this.ui.$fromAccSelected.empty();
        } else {
            const self = this;
            // TODO: .each() had useless `return false`. Should this .find(), instead?
            util.each(self.fromAccountList.toJSON(), (fromAcc) => {
                if (e.val === fromAcc.id) {
                    if (fromAcc.balance === '') {
                        self.model.set('balance', locale.get('sbPayments.notAvailable'));
                        return;
                    }
                    self.model.set('balance', fromAcc.balance);
                }
            });
            if (self.model.get('balance') === 'undefined') {
                self.ui.$fromAccSelected.empty();
            } else {
                const html = `${locale.get('sbPayments.balance')}: <b>${self.model.displayAmount(self.model.get('balance'))}</b>`;
                self.ui.$fromAccSelected.html(html);
            }
        }
    },

    popEditOption() {
        const self = this;
        const optionsModal = new EditOptionsView({
            paymentOptions: this.payOptions,
            model: this.smbPaymentOptions.clone(),
        });

        optionsModal.on('save', (model) => {
            self.smbPaymentOptions.set('menuCode', model.get('menuCode'));
            self.smbPaymentOptions.set('menuDescription', model.get('menuDescription'));
            self.smbPaymentOptions.set('optionCost', model.get('optionCost'));
            self.ui.$menuDescription.text(self.smbPaymentOptions.get('menuDescription'));
            self.ui.$optionCost.text(self.smbPaymentOptions.displayAmount(model.get('optionCost')));
            self.ui.$optionCost.addClass('optionCost');
            baseActions.updateRecipients(model.get('menuCode'), self.recipientList);
        }, this);

        dialog.custom(optionsModal);
    },

    updateTotal() {
        const total = this.businessPaymentsCollection.total();
        const totalPayments = this.businessPaymentsCollection.models.length;
        this.$el.find('[data-hook="total-text"]').text(`${totalPayments} ${locale.get('sbPayments.totalPaymentsLabel')} ${Formatter.formatCurrency(total)}`);
    },

    updatePaymentCollection(options) {
        util.each(this.businessPaymentsCollection.models, (paymentModel) => {
            const recipient = paymentModel.get('recipient');

            if (options.model.get('BENE_ACCOUNTNUMBER') === recipient.get('BENE_ACCOUNTNUMBER')
                || options.originalModel.get('BENE_ACCOUNTNUMBER') === recipient.get('BENE_ACCOUNTNUMBER')) {
                recipient.set(options.model.attributes);
                // make parent model happy
                paymentModel.set('BENE_NAME', options.model.get('BENE_NAME'));
            }
        });
    },

    updateRecipient(model) {
        util.each(this.recipientList.models, (recipientModel) => {
            if (model.cid === recipientModel.cid) {
                recipientModel.set(model.attributes);
            }
        });
    },

    updateAddRowRecipient(html) {
        this.bindUIElements();
        this.ui.$selectedRecipient[0].innerHTML = html;
    },

    submitPayments() {
        const self = this;
        this.model.set('fromAccount', this.ui.$fromAccount.comboBox('val'));
        const totalPayments = this.businessPaymentsCollection.models.length;
        this.model.set('totalPayments', totalPayments);
        const isValid = this.model.isValid();

        if (isValid) {
            if (totalPayments > 0) {
                util.each(this.businessPaymentsCollection.models, (model) => {
                    model.set('fromAccount', self.model.get('fromAccount'));
                });
            }

            const alertStr = `${totalPayments} ${totalPayments === 1 ? locale.get('sbPayments.submitMessage.payment') : locale.get('sbPayments.submitMessage.payments')}`;

            if (totalPayments > 0) {
                dialog.alert(alertStr, () => {
                    dialog.alert(locale.get('sbPayments.serverSubmit'));
                });
                // Send data to server
                this.businessPaymentsCollection.reset();
            } else {
                dialog.alert(alertStr, () => {
                    // Return to Dashboard
                    self.navigateTo('');
                });
            }
        }
    },

    saveDraft() {
        dialog.alert('Draft Saved');
        this.draftSaved = true;
    },

    showServiceNotAvailableDialog() {
        dialog.alert('This service is not available');
    },

    cancelPayments() {
        this.navigateTo('PAYMENTS/enterPayment');
    },
});
