import Layout from '@glu/core/src/layout';
import template from './charactersRemainingInput.hbs';
import constants from '../constants';

const CharactersRemainingInputView = Layout.extend({
    template,

    ui: {
        $remainingChars: '[data-hook="getRemainingChars"]',
        $input: '[data-hook="getInput"]',
    },

    events: {
        'keyup @ui.$input': 'updateRemainingCharacters',
    },

    templateHelpers() {
        return {
            label: this.options.label,
            maxLength: this.options.maxLength,
            name: this.options.name,
            textArea: this.options.inputType === constants.type.textArea,
            textInput: this.options.inputType === constants.type.textInput,
            customClass: this.options.customClass || '',
            rows: this.options.rows || 4,
        };
    },

    onRender() {
        if (this.model.get(this.options.name) && this.model.get(this.options.name).length) {
            this.ui.$input.trigger('keyup');
        }
    },

    /**
     * Update the remaining characters text based on current length and maxLength
     * @param {Event} e - keyUp event
     */
    updateRemainingCharacters(e) {
        const remaining = this.calculateRemaining(
            e.currentTarget.value.length,
            this.options.maxLength,
        );
        const selector = this.ui.$remainingChars.get(0);
        selector.innerHTML = remaining;
    },

    /**
     * Subtract currentLength from maxLength
     * @param {number} currentLength
     * @param {number} maxLength
     * @returns {number}
     */
    calculateRemaining(currentLength, maxLength) {
        return maxLength - currentLength;
    },
});

export default CharactersRemainingInputView;
