import LayerView from '@glu/core/src/core/internal/layerView';
import http from '@glu/core/src/http';
import $ from 'jquery';
import constants from 'common/dynamicPages/api/constants';
import domUtil from 'common/util/domUtil';
import mobileUtil from 'mobile/util/mobileUtil';
import template from './gridActionPopup.hbs';

export default LayerView.extend({
    template,

    ui: {
        $formScreen: '[data-hook="gridActionPopupForm"]',
        $errorScreen: '[data-hook="gridActionPopupError"]',
        $successScreen: '[data-hook="gridActionPopupSuccess"]',
        $gridActionPopupWrapper: '[data-hook="gridActionPopupWrapper"]',
        $gridActionPopupButtons: '.grid-action-popup-buttons .btn',
        $gridActionPopupErrorMessage: '.grid-action-popup-error-description',
    },

    regions: {
        fieldsRegion: '.grid-action-popup-fields',
    },

    initialize(options) {
        this.fieldsView = options.fieldsView;
        if (!options.isReact) {
            this.fieldsView.render();
        }
        this.listenTo(this.fieldsView, 'sizeOfPopupChanged', this.positionPopupCorrectly);
        if (options.isReact) {
            this.listenTo(this.appBus, 'gridActionPopup:close', this.onClose);
            this.listenTo(this.appBus, 'gridActionPopup:submit', this.success);
        }
        this.dataLoaded = !this.fieldsView.needsData;
        this.formComplete = false; // tracks form state
    },

    onRender() {
        if (this.dataLoaded) {
            this.fieldsRegion.show(this.fieldsView);
            this.positionPopupCorrectly();
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * Handles when a user clicks while a grid action popup is open. This allows for the
     * popup to be closed when a user clicks outside of it. This will not close the action
     * popup when the focus is a modal (such as multi factor challenge) as the result of
     * the modal will report back to the popup upon completion.
     * @param {Event} e - the click event
     */
    handleClicks(e) {
        e.preventDefault();
        if (!this.$(e.target).length && !$(e.target).parents('.glu-modal').length) {
            this.close();
        }
    },

    /*
     * Once the popup is initialized and rendered it still needs to be positioned appropriately
     * based on the grid action button that opened it. This may be called more than once if the
     * size of the popup changes, ex: when data is loaded in and fields are populated, or if
     * errors are present after validation
     */
    positionPopupCorrectly() {
        // Handle click event so we can close the popup if the user clicks outside of it
        $(document).on('click.gridActionPopup', this.handleClicks.bind(this));

        if (!mobileUtil.isMobileGridEnabled()) {
            // determine the appropriate location on the screen for the popup
            this.ui.$gridActionPopupWrapper.css('top', `${this.options.buttonOffset?.top - 60}px`);
            this.ui.$gridActionPopupWrapper.css('left', `${this.options.buttonOffset?.left + 90}px`);
        } else {
            // make sure we lock body scroll
            document.body.classList.add('body-scroll-lock');
            if (this.formComplete) {
                /*
                 * Dispalys the pop-up in full screen mode similar
                 * to the alertRegion in desktop Version
                 */
                this.ui.$gridActionPopupWrapper.addClass('popup-overlay');
                this.ui.$gridActionPopupWrapper.removeClass('has-mobile-grid');
                this.ui.$successScreen.addClass('message-display');
                this.ui.$errorScreen.addClass('message-display');
            }
        }
    },

    /**
     * If the fieldsView has provided a dataUrl then we will attempt to
     * retrieve any necessary information here to populate fields
     */
    loadRequiredData() {
        new Promise((resolve, reject) => {
            http.post(
                this.fieldsView.dataUrl,
                this.fieldsView.getRequestData(this.options.action.toUpperCase()),
            ).then((response) => {
                resolve(response);
            }, (error) => {
                reject(error);
            });
        }).then(
            (data) => {
                this.dataLoaded = true;
                this.render();
                this.fieldsView.loadedRequiredData(data);
                this.positionPopupCorrectly();
            },
            (error) => {
                window.console.log(`${error}-ERROR loading data for grid action popup fields`);
            },
        );
    },

    success(response) {
        this.ui.$formScreen.addClass('hidden');
        this.ui.$successScreen.toggleClass('hidden', !response.result);
        this.ui.$errorScreen.toggleClass('hidden', response.result);

        if (response.result) {
            this.formComplete = true;
        } else {
            const errorMessage = this.getErrorMessageFromResponse(response);
            if (errorMessage) {
                this.ui.$gridActionPopupErrorMessage.text(errorMessage);
            }
        }

        this.positionPopupCorrectly();
    },

    failure() {
        this.ui.$formScreen.addClass('hidden');
        this.ui.$errorScreen.removeClass('hidden');
        this.positionPopupCorrectly();
    },

    /**
     * Submit the "form" with any values input by the user
     */
    onSubmit() {
        // disable the grid action popups buttons
        domUtil.setDisabled(this.ui.$gridActionPopupButtons, true);

        if (this.fieldsView.formIsValid()) {
            this.model.context.actionData.subType = this.options.action;
            this.model.sync(
                this.options.action,
                this.model,
                {
                    submissionData: this.fieldsView.formatData(),
                    success: (response) => {
                        this.success(response);
                    },
                    error: () => {
                        this.failure();
                    },
                },
            );
        } else {
            // Re-enable the buttons so the user can re-submit after a form validation error
            domUtil.setDisabled(this.ui.$gridActionPopupButtons, false);
        }
    },

    /**
     * Find the error message returned from the server and set it on the screen.
     * @param {Object} response - response object returned from the server
     * @return {String} error message from the response
     */
    getErrorMessageFromResponse(response) {
        // Null check to see if the response object exists and is structured as expected.
        const noConfirmResultsInResponse = (!response.confirms
            || !response.confirms.confirmResults
            || response.confirms.confirmResults.length === 0
            || !response.confirms.confirmResults[0].messages);

        // Just return if the response object is not what is expected
        if (noConfirmResultsInResponse) {
            return '';
        }

        // Pull any returned error messages from the server response
        const errorMessage = response.confirms.confirmResults[0].messages[0];

        if (response.errorCode === constants.INVALID_ACCOUNT_STATE_ERROR_CODE
            || response.errorCode === constants.SERVICE_ERROR_CODE) {
            return errorMessage;
        }
        return '';
    },

    // close the grid action popup. Does not store or save any information
    onClose() {
        // allow the body to scroll again if on mobile
        document.body.classList.remove('body-scroll-lock');
        // clear any data the user may have entered
        if (!this.options.isReact) {
            this.fieldsView.clearData();
        }
        this.remove();
        $(document).off('click.gridActionPopup');

        // if the user has completed the popup and closes it by clicking outside, update grid
        if (this.formComplete) {
            this.appBus.trigger('grid:row:action:action_popupCompleted');
        }
    },

    templateHelpers() {
        const opts = (this.options.isReact) ? this.fieldsView.options : this.fieldsView;
        return {
            // ...this.fieldsView,
            failLabel: opts.failLabel,
            failDescription: opts.failDescription,
            successLabel: opts.successLabel,
            closeText: opts.closeText,
            title: opts.title,
            cancelText: opts.cancelText,
            submitText: opts.submitText,
            action: this.options.action,
            showButtons: !this.options.isReact,
            containerClass: mobileUtil.isMobileGridEnabled() ? 'has-mobile-grid' : '',
        };
    },
});
