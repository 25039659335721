import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@glu/layout-react';
import useStyles from './FormFieldStatic.styles';

const FormFieldStatic = ({
    label,
    name,
    value,
    className,
    col,
}) => {
    const classes = useStyles();
    return (
        <Layout col={col}>
            <div className={`${classes.formField} ${className}`}>
                <label htmlFor={`${name}`}>{ label }</label>
                <p id={`${name}`} className={classes.p}>
                    <span>{ value }</span>
                </p>
            </div>
        </Layout>
    );
};

FormFieldStatic.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    col: PropTypes.number,
};
FormFieldStatic.defaultProps = {
    label: '',
    value: '',
    className: '',
    col: null,
};

export default FormFieldStatic;
