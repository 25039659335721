import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';

const styles = ({ palette, typography }) => ({
  filterHint: {
    color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
    fontFamily: typography.fontFamily,
    fontSize: 12
  },
  input: {
    background: 'transparent',
    border: `1px solid ${palette.form.border}`,
    borderRadius: 2,
    boxSizing: 'border-box',
    color: ({ dark }) => (dark ? palette.text.light : palette.text.dark),
    fontFamily: typography.fontFamily,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    padding: [7.5, 10],
    width: '100%'
  }
});

export const FUZZY_FILTER_ID = 'fuzzyFilter';

const FuzzyFilter = ({
  className, classes, createFilterValue, fieldId, filterHint,
  filterId, htmlId, htmlInputProps, onChange,
  options, parseFilterValue, value
}) => {
  const handleChange = ({
    target: { value: inputValue }
  }) => {
    const formatValue = options?.format ? options.format(inputValue) : inputValue;
    return onChange(fieldId, createFilterValue(filterId, fieldId, formatValue));
  };

  return (
    <>
      <input
        type="text"
        id={htmlId}
        name={fieldId}
        value={parseFilterValue(value) || ''}
        className={`${classes.input} ${className}`}
        onChange={handleChange}
        {...htmlInputProps}
      />
      {filterHint && (
        <span className={classes.filterHint}>{filterHint}</span>
      )}
    </>
  );
};

FuzzyFilter.propTypes = {

  /** ClassName to use for this filter component */
  className: PropTypes.string,

  // eslint-disable-line react/no-unused-prop-types
  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * Creates the final filter value that is returned via the filter's onChange function whenever
   * the filter is changed.
   *
   * @param {string} filterId - The id of the filter
   * @param {string} fieldId - The id of the field being filtered
   * @param {string} inputValue - The value to filter on, as entered by the user.
   */
  createFilterValue: PropTypes.func.isRequired,

  /** True if the filter should be in dark mode which styles it for dark colored forms */
  dark: PropTypes.bool,

  /** Unique identifier for the field of data you are filtering on */
  fieldId: PropTypes.string.isRequired,

  /** Hint that is displayed below the filter field */
  filterHint: PropTypes.string,

  /** The id to use for the value returned from onChange */
  filterId: PropTypes.string,

  /** Html id to use for the html control of this filter */
  htmlId: PropTypes.string.isRequired,

  /** Attributes of the html input itself. */
  htmlInputProps: PropTypes.shape({}),

  /**
   * Function to call whenever the filter has been changed.
   *
   * @param {string} fieldId - The fieldId of this filter
   * @param {Object} value - The new value of the filter
   */
  onChange: PropTypes.func.isRequired,

  /** Object passed to Fuzzy filter. Used to configure the input component
   * format
   * @property {string} value - The HTML value of the input field
   * @return {string} - the formatted value
  */
  options: PropTypes.shape({
    format: PropTypes.func
  }),

  /**
   * Parses the criteria used by the filter out of its value which can include other data.
   * Fuzzy filter criteria is a string and represents direct input from the user
   *
   * @param {string} filterValue - The value for the filter, which may contain data other than
   *    the criteria needed for the Fuzzy Filter.
   * @return {string} - User entered data to filter on
   */
  parseFilterValue: PropTypes.func.isRequired,

  /** The current value for this filter. */
  value: PropTypes.shape({})
};

FuzzyFilter.defaultProps = {
  className: '',
  dark: true,
  filterHint: '',
  filterId: FUZZY_FILTER_ID,
  htmlInputProps: {
    autoComplete: 'off'
  },
  options: undefined,
  value: undefined
};

export default withStyles(styles)(FuzzyFilter);
export const BareFuzzyFilter = FuzzyFilter;
