var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"getPageTitle") || (depth0 != null ? lookupProperty(depth0,"getPageTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getPageTitle","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":47}}}) : helper)))
    + "</h1>\n</div>\n<form class=\"securityQuestions-page\">\n    <div data-region=\"alertRegion\"></div>\n    <fieldset class=\"section section-container\">\n        <div class=\"section-body form-container\">\n            <h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"getInstructions") || (depth0 != null ? lookupProperty(depth0,"getInstructions") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getInstructions","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":35}}}) : helper)))
    + "</h4>\n            <div class=\"form-group\">\n                <p class=\"help-block\">\n                <ul>\n                    <li>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"security.HeaderInfoline2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":61}}}))
    + "</li>\n                    <li>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"security.HeaderInfoline3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":61}}}))
    + "</li>\n                    <li>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"security.HeaderInfoline4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":61}}}))
    + "</li>\n                    <li>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"security.HeaderInfoline5",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":61}}}))
    + "</li>\n                </ul>\n                </p>\n            </div>\n            <div>\n                <div>\n                    <div class=\"single-checkbox\">\n                        <input id=\"toggleMask\" type=\"checkbox\" name=\"toggleMask\">\n                        <label for=\"toggleMask\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.maskAnswers",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":48},"end":{"line":23,"column":79}}}))
    + "</label>\n                    </div>\n                </div>\n            </div>\n\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questioncode1\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":47},"end":{"line":30,"column":75}}}))
    + " 1</label>\n                    <select name=\"questioncode1\" id=\"questioncode1\" data-bind=\"model\" class=\"form-control securityQuestion\" aria-required=\"true\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias2).call(alias1,0,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":32,"column":43}}})) != null ? stack1 : "")
    + "\n                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questioncode1\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questionanswer1\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":75}}}))
    + "</label>\n                    <input name=\"questionanswer1\"\n                        id=\"questionanswer1\"\n                        maxlength=\"35\"\n                        data-bind=\"model\"\n                        type=\"text\"\n                        data-hook=\"securityAnswer\"\n                        class=\"form-control securityAnswer\"\n                        aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questionanswer1\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questioncode2\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":47},"end":{"line":53,"column":75}}}))
    + " 2</label>\n                    <select name=\"questioncode2\" id=\"questioncode2\" data-bind=\"model\" class=\"form-control securityQuestion\" aria-required=\"true\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias2).call(alias1,1,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":43}}})) != null ? stack1 : "")
    + "\n                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questioncode2\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questionanswer2\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":62,"column":49},"end":{"line":62,"column":75}}}))
    + "</label>\n                    <input name=\"questionanswer2\"\n                        id=\"questionanswer2\"\n                        maxlength=\"35\"\n                        data-bind=\"model\"\n                        type=\"text\"\n                        data-hook=\"securityAnswer\"\n                        class=\"form-control securityAnswer\"\n                        aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questionanswer2\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questioncode3\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":47},"end":{"line":76,"column":75}}}))
    + " 3</label>\n                    <select name=\"questioncode3\" id=\"questioncode3\" data-bind=\"model\" class=\"form-control securityQuestion\" aria-required=\"true\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias2).call(alias1,2,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":78,"column":43}}})) != null ? stack1 : "")
    + "\n                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questioncode3\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questionanswer3\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":49},"end":{"line":85,"column":75}}}))
    + "</label>\n                    <input name=\"questionanswer3\"\n                        id=\"questionanswer3\"\n                        maxlength=\"35\"\n                        data-bind=\"model\"\n                        type=\"text\"\n                        data-hook=\"securityAnswer\"\n                        class=\"form-control securityAnswer\"\n                        aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questionanswer3\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questioncode4\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":99,"column":47},"end":{"line":99,"column":75}}}))
    + " 4</label>\n                    <select name=\"questioncode4\" id=\"questioncode4\" data-bind=\"model\" class=\"form-control securityQuestion\" aria-required=\"true\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias2).call(alias1,3,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":101,"column":24},"end":{"line":101,"column":43}}})) != null ? stack1 : "")
    + "\n                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questioncode4\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questionanswer4\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":108,"column":49},"end":{"line":108,"column":75}}}))
    + "</label>\n                    <input name=\"questionanswer4\"\n                        id=\"questionanswer4\"\n                        maxlength=\"35\"\n                        data-bind=\"model\"\n                        type=\"text\"\n                        data-hook=\"securityAnswer\"\n                        class=\"form-control securityAnswer\"\n                        aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questionanswer4\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questioncode5\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.question",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":122,"column":47},"end":{"line":122,"column":75}}}))
    + " 5</label>\n                    <select name=\"questioncode5\" id=\"questioncode5\" data-bind=\"model\" class=\"form-control securityQuestion\" aria-required=\"true\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"getQuestion")||(depth0 && lookupProperty(depth0,"getQuestion"))||alias2).call(alias1,4,{"name":"getQuestion","hash":{},"data":data,"loc":{"start":{"line":124,"column":24},"end":{"line":124,"column":43}}})) != null ? stack1 : "")
    + "\n                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questioncode5\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"questionanswer5\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.answer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":49},"end":{"line":131,"column":75}}}))
    + "</label>\n                    <input name=\"questionanswer5\"\n                        id=\"questionanswer5\"\n                        maxlength=\"35\"\n                        data-bind=\"model\"\n                        type=\"text\"\n                        data-hook=\"securityAnswer\"\n                        class=\"form-control securityAnswer\"\n                        aria-required=\"true\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"questionanswer5\"></span>\n                </div>\n            </div>\n        </div>\n    </fieldset>\n    <div class=\"section section-container\">\n        <div class=\"section-body\">\n            <div id=\"complete\">\n                <button type=\"button\" id=\"questions-action-save\" class=\"btn btn-primary\" data-action=\"save\" aria-label=\"Save\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":148,"column":126},"end":{"line":148,"column":150}}}))
    + "</button>\n                <button type=\"button\" id=\"questions-action-cancel\" class=\"btn btn-secondary\" data-action=\"cancel\" aria-label=\"Cancel\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":149,"column":134},"end":{"line":149,"column":160}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});