import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AccordionIcon, AccordionPanelContext } from '@glu/accordion-react';
import { EyeLineNextIcon, FunnelNextIcon } from '@glu/icons-react';
import { Button, SMALL, TERTIARY } from '@glu/buttons-react';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';

const styles = ({ palette }) => ({
  accordionButton: {
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    padding: [5, 2]
  },

  accordionIcon: {
    height: 'auto',
    marginRight: 10,
    width: 'auto'
  },

  buttons: {
    background: 'none',
    color: ({ dark }) => (dark ? palette.text.light : palette.text.midGray)
  },

  clearBtn: {
    margin: [0, 0, 0, 6]
  },

  clearFilter: {
    '& svg': {
      fill: ({ dark }) => (dark ? palette.text.light : palette.text.midGray),
      height: 12,
      width: 12
    },
    alignItems: 'center',
    display: 'flex',

    marginLeft: 10
  },

  hiddenField: {
    '& svg': {
      fill: ({ dark }) => (dark ? '#ccc' : '#6e6e6e'),
      marginRight: 5
    },
    alignItems: 'center',
    color: '#ccc',
    display: 'flex',
    flexGrow: 1,
    fontSize: 12,
    fontWeight: 700,
    justifyContent: 'flex-end',

    textTransform: 'uppercase'
  },

  root: {
    '& svg': {
      fill: ({ dark }) => (dark ? palette.text.light : palette.text.midGray)
    },
    backgroundColor: 'transparent',
    color: ({ dark }) => (dark ? palette.text.light : palette.text.midGray),
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    padding: [5, 0],

    position: 'relative'
  }
});

const FilterAccordionHeader = ({
  className, classes, fieldId, hasFilter, hiddenField,
  label, onClearFilter, qaRoot
}) => {
  const {
    handleSetCurrentOpenPanelsIdsArr,
    isOpen,
    panelId
  } = useContext(AccordionPanelContext);

  const doFilterClear = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    onClearFilter(fieldId);
  }, [fieldId, onClearFilter]);

  return (
    <div className={`${classes.root} ${className}`}>
      <button
        data-qa={`${qaRoot}-trigger`}
        aria-expanded={isOpen}
        aria-disabled="false"
        type="button"
        className={`${classes.buttons} ${classes.accordionButton}`}
        onClick={() => handleSetCurrentOpenPanelsIdsArr(panelId)}
      >
        <AccordionIcon
          className={classes.accordionIcon}
        />
        <span>{label}</span>
      </button>
      {hasFilter && (
        <div className={classes.clearFilter}>
          <FunnelNextIcon noIconWrapper focusable={false} />
          <Button
            variant={TERTIARY}
            size={SMALL}
            text={locale.get('dataComponents.clear')}
            className={`${classes.buttons} ${classes.clearBtn}`}
            onClick={doFilterClear}
          />
        </div>
      )}
      {hiddenField && (
        <div className={classes.hiddenField}>
          <EyeLineNextIcon noIconWrapper focusable={false} />
          <span>{locale.get('dataComponents.hidden')}</span>
        </div>
      )}
    </div>
  );
};

FilterAccordionHeader.propTypes = {

  // eslint-disable-line react/no-unused-prop-types
  /** ClassName to use for customization */
  className: PropTypes.string,

  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** True if the filter should be in dark mode which styles it for dark colored forms */
  // eslint-disable-next-line react/no-unused-prop-types
  dark: PropTypes.bool,

  /** Unique identifier for the field of data you are filtering on */
  fieldId: PropTypes.string.isRequired,

  /** Indicates there is currently a filter present for the field */
  hasFilter: PropTypes.bool,

  /** Indicates the field for the filter is hidden */
  hiddenField: PropTypes.bool,

  /** Label to display for the filter */
  label: PropTypes.string.isRequired,

  /**
   * Function to call to clear the filter for a field
   *
   * @param {string} fieldId - The id of the field whose filter is being cleared
   */
  onClearFilter: PropTypes.func.isRequired,

  /** String to be combined with "-trigger" for the data-qa attribute */
  qaRoot: PropTypes.string.isRequired
};

FilterAccordionHeader.defaultProps = {
  className: '',
  dark: true,
  hasFilter: false,
  hiddenField: false
};

export default withStyles(styles)(FilterAccordionHeader);
