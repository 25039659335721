var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cashFlowScenarioId") || (depth0 != null ? lookupProperty(depth0,"cashFlowScenarioId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cashFlowScenarioId","hash":{},"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":85}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":87},"end":{"line":7,"column":129}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":130},"end":{"line":7,"column":138}}}) : helper)))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected=\"selected\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row select-control\">\n        <div class=\"col-md-12\">\n                <div class=\"widget-form-group required\">\n                        <label for=\"cash-flow-scenario\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activity",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":56},"end":{"line":4,"column":86}}}))
    + "</label>\n                                <select class=\"form-control\" name=\"cash-flow-scenario\" id=\"cash-flow-scenario\"  aria-required=\"true\" required>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"scenarios") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":8,"column":49}}})) != null ? stack1 : "")
    + "                                </select>\n                </div>\n        </div>\n</div>\n\n<div class=\"row view-mode\">\n        <div class=\"col-md-12\">\n                <button type=\"button\" class=\"btn btn-secondary\">\n                        <span class=\"icon-plus\"></span>\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activity.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":58}}}))
    + "\n                </button>\n        </div>\n</div>\n\n<div class=\"row edit-mode hidden\">\n\n        <label for=\"new-scenario-name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activity.new.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":78}}}))
    + "</label>\n        <div class=\"form-inline\">\n                <input type=\"text\" name=\"new-scenario-name\" id=\"new-scenario-name\" class=\"form-control\">\n                <button type=\"button\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.create.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":62},"end":{"line":28,"column":94}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-link\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":59},"end":{"line":29,"column":91}}}))
    + "</button>\n        </div>\n\n</div>\n";
},"useData":true});