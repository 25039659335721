import { useEffect, useState } from 'react';
import { useStorage } from '@glu/utilities-react';

export default function useInitialData({
  data,
  disableSessionStorage,
  showSavedViews,
  storageId
}) {
  const [session, setSession] = useStorage({ storageId });
  const [savedViews, setSavedViews] = useState(false);
  const [initialData, setInitialData] = useState(false);

  const getSessionData = () => {
    if (disableSessionStorage) {
      return {};
    }
    const { savedViews: savedSessionViews, ...restSession } = session || {};
    const { active } = savedSessionViews || {};
    return {
      active,
      session: restSession
    };
  };

  const getActiveView = () => savedViews.views
    .find(view => view.viewData.id === savedViews.active) || {};

  /*
   * @description: Merge initial data, session, and saved views.
   * Session takes priority except for SavedViews views.
   * Otherwise, the saved view data can override session data.
   */
  useEffect(() => {
    const loaded = () => session !== undefined || disableSessionStorage;
    const savedLoaded = () => savedViews || !showSavedViews;
    if (loaded() && savedLoaded()) {
      const {
        active,
        session: activeSession
      } = getSessionData();
      const { viewData, ...activeSaved } = showSavedViews ? getActiveView() : {};
      const update = {
        savedViews: {
          ...savedViews,
          ...(active && { active })
        },
        ...activeSaved,
        ...activeSession
      };

      if (!update.columns) {
        setInitialData(update);
        return;
      }

      // Preserve everything from column defs except the width and order
      const columns = update.columns.map(({ field, hide, width }) => ({
        ...data.columns.find(column => column.field === field),
        hide,
        width
      }));

      setInitialData({
        ...update,
        columns
      });
    }
  /* TODO: Smartly figure out how to add dependencies */
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [disableSessionStorage, session, savedViews]);

  const dataLoaded = () => {
    if (disableSessionStorage && !showSavedViews) {
      return true;
    }

    if (session !== undefined && !showSavedViews) {
      return true;
    }

    return session !== undefined && savedViews;
  };

  return {
    initialData: dataLoaded() && initialData,
    setSavedViews,
    setSession
  };
}
