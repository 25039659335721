var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"export\">\n<form autocomplete=\"off\" data-submit=\"save\">\n\n    <div class=\"validation-group hide\" role=\"alert\">\n        <p class=\"help-block center\" data-validation=\"generic\"></p>\n    </div>\n\n    <div class=\"form-container\">\n        <div id=\"dataDiv\">\n            <div class=\"field-container-lg\">\n                <label>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"payment.Export",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":53}}}))
    + "</label>\n                <div class=\"form-group\">\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\" id=\"expdata\" value=\"summary\" name=\"expdata\" data-bind=\"model\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"gir.SummaryAndBalanceInformation",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":14,"column":106},"end":{"line":14,"column":154}}}))
    + "</label>\n                    <label class=\"radio-inline\">\n                        <input type=\"radio\" id=\"expdata\" value=\"transaction\" name=\"expdata\" data-bind=\"model\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"gir.TransactionInformation",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":16,"column":110},"end":{"line":16,"column":152}}}))
    + "</label>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"pay-expdata\"></span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n</form>\n</div>\n";
},"useData":true});