import util from '@glu/core/src/util';
import Glu from '@glu/core/src/glu';
const { Backbone } = Glu;

Backbone.validationRending = (function(Backbone) {
    return {
        reset(view) {
            // Clear any messages and remove any highlighting
            const $wrapper = view.$el.find('[data-validation]')
                .text('')
                .closest('.form-group, .validation-group')
                .removeClass('has-error has-addon-error');

            // If the wrapper once has a 'hide' class (noted by data-hide="true", then re-apply the class
            $wrapper.each(function() {
                const $el = Backbone.$(this);
                if ($el.data('hide')) {
                    $el.addClass('hide');
                    $el.removeData('hide');
                }
            });
        },

        enable(view) {
            const self = this;
            view.listenTo(view.appBus, 'http:400', messages => {
                self.show(messages, view);
            });
        },

        show(messages, view) {
            let localMessages = messages;
            // allow messages to be the raw 'responseText' from the server (try to parse it)
            if (util.isString(localMessages)) {
                try {
                    localMessages = JSON.parse(localMessages);
                } catch (e) {}
            }

            // return if not object
            if (!util.isObject(localMessages)) {
                return;
            }

            util.each(localMessages, (value, key) => {
                // Set validation message and make it focusable
                const $el = view.$el.find(`[data-validation="${key}"]`)
                    .text(util.isArray(value) ? value[0] : value);

                // Set error highlighting on wrapper element
                const $wrapper = $el.closest('.form-group, .validation-group')
                    .addClass('has-error');

                // If the wrapper has a 'hide' class, remove it, and make a note
                if ($wrapper.hasClass('hide')) {
                    $wrapper.data('hide', 'true').removeClass('hide');
                }
            });

            view.$el.find('.has-error').first().find('input, select, textarea').focus();
        },
    };

}(Backbone));

export default Backbone.validationRending;
