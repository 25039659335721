import store from 'system/utilities/cache';

let viewSelected;
let manageSelected;

// place the actual priv checks for DGB here
const accountManagementPrivilegesMap = {
    view: () => {
        if (viewSelected) {
            return viewSelected;
        }
        const currentPrivileges = store.get('PMX_PRIV') || { entitlements: [] };
        const hasPrivilege = currentPrivileges.entitlements.some(privilege => privilege.typeCode === 'PMXACCT'
                && privilege.actionMode === 'SELECT'
                && privilege.entitled === true);
        viewSelected = hasPrivilege;
        return hasPrivilege;
    },

    manage: () => {
        if (manageSelected) {
            return manageSelected;
        }
        const currentPrivileges = store.get('PMX_PRIV') || { entitlements: [] };
        const hasPrivilege = currentPrivileges.entitlements.some(privilege => privilege.typeCode === 'PMXACCT'
                && privilege.actionMode === 'MODIFY'
                && privilege.entitled === true);
        manageSelected = hasPrivilege;
        return hasPrivilege;
    },
};

export default accountManagementPrivilegesMap;
