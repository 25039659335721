import React from 'react';
import ReactDOM from 'react-dom';
import {
    ThemeProvider,
    ashTheme,
} from '@glu/theming';
import locale from '@glu/locale';
import { legacyTheme as btiqeLegacyTheme } from '@dbiqe/common';
import Layout from '@glu/core/src/layout';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import Constants from 'app/balanceAndTransaction/constants';
import transform from 'common/util/transform';
import services from 'services';
import Formatter from 'system/utilities/format';
import GridApi from 'common/dynamicPages/api/grid';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import MobileViewContainer from 'components/MobileViewContainer/MobileViewContainer';
import { maskValue } from 'common/util/maskingUtil';
import template from './accountTotals.hbs';

const AccountTotalsList = Layout.extend({
    template,
    className: 'cd-account-totals',
    ui: {
        $heading: '.panel-heading',
    },
    events: {
        'click @ui.$heading': 'handleMobilePanelClick',
    },
    loadViewRequirements() {
        const account = this.model.get('account');
        const searchRequest = this.options.searchRequest ? this.options.searchRequest : [];
        // The fieldName for amounts has changed from AMOUNTREPORTED to AMOUNT
        for (let x = 0; x < searchRequest.length; x += 1) {
            if (searchRequest[x].fieldName === 'AMOUNTREPORTED') {
                searchRequest[x].fieldName = 'AMOUNT';
            }
        }
        let additionalSearchFields = [{
            dataType: 'TEXT',
            fieldName: 'ACCOUNTFILTER',
            operator: 'IN',
            fieldValue: [account],
        }, {
            operator: '=',
            fieldValue: [this.getFormattedDate()],
            dataType: 'TEXT',
            fieldName: 'to_char(ASOFDATE,\'YYYYMMDD\')',
        }];
        additionalSearchFields = additionalSearchFields.concat(searchRequest);
        this.reportOptions = {
            context: {
                serviceName: '/controlDist/Detail',
                productCode: 'GIR',
                functionCode: 'CD',
                typeCode: 'CTRLDISB',
            },
            hideGridActionButtons: true,
            selector: 'none',
            enableSavedViews: true,
            additionalSearchFields,
        };
        this.gridView = GridApi.createServiceGridView(this.reportOptions);
        // None of the above code is async, so for mobile grid, return a resolved promised
        return Promise.resolve();
    },

    onBeforeClose() {
        if (mobileUtil.isMobileGridEnabled()) {
            ReactDOM.unmountComponentAtNode(this.el.querySelector('.mobileDrawerPanel'));
        }
    },

    handleMobilePanelClick() {
        this.loadViewRequirements();
        if (mobileUtil.isMobileGridEnabled()) {
            const mobileList = configureMobileInterface(AccountTotalsList);
            const List = AccountTotalsList.extend(mobileList);
            const mobileView = new List({
                ...this.options,
                ...this.reportOptions,
                prebuiltOptions: true,
                gridView: this.gridView,
            });
            mobileView.title = locale.get('gir.transactions');
            ReactDOM.render(
                // eslint-disable-next-line
                <ThemeProvider theme={btiqeLegacyTheme} baseTheme={ashTheme}>
                    <MobileViewContainer
                        isOpen
                        view={mobileView}
                        onClose={() => {
                            this.onBeforeClose();
                        }}
                    />
                </ThemeProvider>,
                this.el.querySelector('.mobileDrawerPanel'),
            );
        } else {
            const account = this.model.get('account');
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this[account].show(this.gridView);
        }
    },

    getFormattedDate() {
        return Formatter.formatDateFromUserFormat(this.model.get('date'), 'YYYYMMDD');
    },
    gridRowAction(options) {
        const action = options.action.toUpperCase();
        if (action === 'IMAGE') {
            this.getImage(options);
        }
        return Promise.resolve();
    },
    reloadGrid() {
        this.showGridView(this.endPoint);
    },
    getImage(optionsParam) {
        const options = optionsParam;
        const { model } = options;
        const jsonData = [];
        const self = this;
        const service = services.generateUrl(Constants.CD_IMAGE_SERVICE);
        jsonData.push({
            name: 'ACCOUNT_NUMBER',
            value: model.get('ACCOUNT_NUM'),
        });
        jsonData.push({
            name: 'ACCOUNTFILTER',
            value: model.get('ACCOUNTFILTER'),
        });
        jsonData.push({
            name: 'POST_DATE',
            value: model.get('ASOFDATE'),
        });
        jsonData.push({
            name: 'CUST_REF',
            value: model.get('CHECKNUM'),
        });
        jsonData.push({
            name: 'AMOUNT',
            value: model.get('AMOUNT'),
        });
        jsonData.push({
            name: 'PIC_SEQUENCE_NUMBER',
            value: model.get('IMAGEID'),
        });
        jsonData.push({
            name: 'BANK_CODE',
            value: model.get('BANKCODE'),
        });
        const postData = {
            item: jsonData,
        };
        options.success = function (resp) {
            self.showImage(resp, options);
        };
        options.error = function (resp) {
            self.showError(resp, options);
        };
        http.post(service, postData, (resp) => {
            options.success(resp, options);
        }, (resp) => {
            options.error(resp, options);
        });
    },
    showError() {
        // placeholder for errors
    },
    showImage(resp, options) {
        const { model } = options;
        const imageResponse = resp;
        const images = [];
        const headerData = transform.pairsToHash(imageResponse.headerData.item);
        const headerLabels = transform.pairsToHash(imageResponse.headerLabels.item);
        const { backImage, frontImage } = imageResponse;
        const frontImageType = frontImage.contentType;
        const backImageType = backImage.contentType;

        util.each(imageResponse.images, (image) => {
            images.push(transform.pairsToHash(image.item));
        });
        /*
         * set the full list of images / thumbnails.
         * TODO - should be a single .set()
         */
        model.set('images', images);
        model.set('headerData', headerData);
        model.set('headerLabels', headerLabels);
        model.set('image_data', frontImage.value);
        model.set('image_type', frontImageType);
        model.set('image_data_reverse', backImage.value);
        model.set('image_type_reverse', backImageType);
        store.set('deposit_data', model);
        this.navigateTo('REPORTING/ctrlDisbursementReport/viewImage');
    },
    templateHelpers() {
        return {
            cid: this.model.cid,
            maskAccount: maskValue(this.model.get('account')),
            name: util.unescape(this.model.get('name')),
        };
    },
});
export default AccountTotalsList;
