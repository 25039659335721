import Layout from '@glu/core/src/layout';
import multiItemViewTmpl from './multiItemView.hbs';

export default Layout.extend({
    className: 'one-item',
    template: multiItemViewTmpl,

    initialize(options) {
        this.model = options.model;
    },

    /**
     * @name removeItem
     * @description triggers an event to remove a model from the collection
     * removeItem is setup with a data-action on the remove button
     * the event handler is set up in the ItemEvents hash
     */
    removeItem() {
        this.trigger('destroy');
    },

    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },
});
