import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    // Identifies if there is only one type in a group
    singleType: true,

    /**
     * Return the legacy data entitlement attribute
     * @return {string}
     */
    getEntityName() {
        return CONSTANTS.ENTITY_NAME.LEGACY;
    },

    /**
     * Return the legacy type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.LEGACY;
    },

    /**
     * Return the legacy data inquiry id for the service call
     * @return {string}
     */
    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.LEGACY;
    },

    /**
     * Return the legacy data inquiry id for current/future for the service call
     * @return {string}
     */
    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.LEGACY_CF;
    },

    /**
     * Return the available legacy types to populate the grid
     * @return {Array}
     */
    getRecords() {
        return gridDataAccess.legacyRecords(this.model);
    },
});
