import $ from 'jquery';
import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import commentTmpl from './comment.hbs';

const COMMENTS_MAX_LENGTH = 80;

export default ItemView.extend({
    initialize(options) {
        this.events = {
            'keyup #commentsText': 'showCountCharacters',
        };

        this.model = options.model.clone();
    },

    showCountCharacters(e) {
        // COMMENTS_MAX_LENGTH can be a Global variable in future
        const $target = $(e.target);
        const $targetTxtAreaVal = $target.val();
        const cmtsTxtAreaReMainLen = COMMENTS_MAX_LENGTH - $targetTxtAreaVal.length;

        $target.parent().find('.char-counter').text(`${locale.get('sbPayments.charactersRemaining')} ${cmtsTxtAreaReMainLen}`);
    },

    type: 'info',
    template: commentTmpl,
    title: locale.get('smb.comment'),
    className: 'modal',
    maxLength: 80,

    saveComment() {
        this.trigger('save', this.model);
        this.close();
    },

    closeModal() {
        this.close();
    },

    templateHelpers() {
        return {
            title: this.title,
            maxLength: this.maxLength,
        };
    },
});
