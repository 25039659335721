import CompositeView from '@glu/core/src/compositeView';
import ItemView from '@glu/core/src/itemView';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import entryFieldItemViewTmpl from './entryFieldItemView.hbs';
import multipleEntryFieldsViewTmpl from './multipleEntryFieldsView.hbs';

const EntryFieldItemView = ItemView.extend({
    template: entryFieldItemViewTmpl,
    className: 'EntryField',
});

export default CompositeView.extend({
    template: multipleEntryFieldsViewTmpl,
    itemView: EntryFieldItemView,
    itemViewContainer: '[data-hook="getSerialInputs"]',

    ui: {
        $addCount: '[data-hook="getAddCount"]',
        $addBtn: '[data-hook="getAddBtn"]',
        $status: '[data-hook="getStatusMessage"]',
        $inputStatus: '[data-hook="getInputStatusMessage"]',
    },

    events: {
        'click @ui.$addBtn': 'onAddInputs',
    },

    initialize(options) {
        this.defaultItemCount = 4;
        this.parentView = options.parentView;
        this.parentModel = options.parentModel;
        this.fieldName = options.fieldName;
        this.fieldLabel = options.fieldLabel;
        this.modelValidator = options.modelValidator;
        this.maxEntries = options.maxEntries;

        // collection holding all Serial Number inputs and data
        this.collection = new Collection();
        this.collection.on(`change:${this.fieldName}`, () => util.defer(this.onFieldInput.bind(this)));
        this.addItemsToCollection(this.defaultItemCount);
    },

    /**
     * @method onAddToInputs
     * Event handler for add Inputs button click
     * Will only add inputs if doesn't exceed max number of inputs
     */
    onAddInputs() {
        const addCount = parseInt(this.ui.$addCount.val(), 10);
        const exceedsMax = (this.collection.length + addCount) > this.maxEntries;

        if (!exceedsMax && addCount > 0) {
            this.addItemsToCollection(addCount);
            this.focusOnInputs();
            this.ui.$addCount.val('');
        }

        this.ui.$status.text((exceedsMax) ? locale.get('PAY.numberOfEntriesNotExceed').replace('{0}', this.maxEntries) : '');
    },

    /**
     * @method addItemsToCollection
     * @param {int} numItems
     * adds items (models) to the collection
     * the number of items added is determined from an input control
     */
    addItemsToCollection(numItems) {
        const models = [...Array(numItems)].map(() => this.createItemViewModel());
        this.collection.add(models);
    },

    createItemViewModel() {
        const model = new Model({
            [this.fieldName]: '',
            fieldName: this.fieldName,
        });
        model.serialNumberLimit = this.parentModel?.serialNumberLimit;
        model.validators = this.modelValidator;
        return model;
    },

    /**
     * @method focusOnInputs
     * After user adds new input entries to the view, will automatically put
     * focus on the first empty input
     */
    focusOnInputs() {
        const firstEmptyItemModel = this.collection
            .find(model => !model.get(this.fieldName).toString().trim());
        // eslint-disable-next-line
        const itemViewID = this.children._indexByModel[firstEmptyItemModel.cid];
        // eslint-disable-next-line
        this.children._views[itemViewID].$el.find('input').focus();
    },

    /**
     * @method validateInputs
     * Validate all available inputs on the screen
     * make sure to validate all models and not just stop at first error to show
     * user which ones need fix
     */
    validateInputs() {
        let isValid = true;

        this.collection.forEach((model) => {
            if (!model.isValid()) {
                isValid = false;
            }
        });

        return isValid;
    },

    /**
     * @method onFieldInput
     * Event handler for input change made user
     */
    onFieldInput() {
        if (!this.validateInputs()) {
            return;
        }

        // get all filled out and unique values
        const fieldValue = this.collection
            .map(model => model.get(this.fieldName).toString().trim())
            .filter((val, i, arr) => val && arr.indexOf(val) === i);

        this.parentModel.set(this.fieldName, fieldValue);

        // clear errors
        this.ui.$inputStatus.text('');
    },

    templateHelpers() {
        return {
            fieldLabel: this.fieldLabel,
            fieldName: this.fieldName,
        };
    },
});
