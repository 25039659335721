import Layout from '@glu/core/src/layout';
import GridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import $ from 'jquery';
import Formatter from 'system/utilities/format';
import Account from 'app/balanceAndTransaction/models/account';
import TotalsModel from 'app/balanceAndTransaction/models/accountTotals';
import Services from 'services';
import TotalsView from 'app/balanceAndTransaction/views/accountTotalsView';
import BalancePoller from 'app/balanceAndTransaction/models/balancePoller';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import BTRActionCellView from 'app/balanceAndTransaction/views/btrActionCellView';
import ListView from 'common/dynamicPages/views/workflow/list';
import printExportUtil from 'common/util/printExportUtil';
import GridExportBTRDataView from 'app/balanceAndTransaction/views/exportBTRData/gridExportBTRDataView';
import transform from 'common/util/transform';
import constants from 'app/balanceAndTransaction/constants';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import accountUtil from 'app/smbPayments/util/accountUtil';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import systemConfig from 'system/configuration';
import { asView } from 'common/util/reactUtil';
import CurrencyBadges from 'components/CurrencyBadges/CurrencyBadges';
import AsOfTimeCellView from './asOfTimeCellView';
import template from './balanceListView.hbs';

// in milliseconds
const TWO_MINUTES_IN_MILLISECONDS = 120000;

const BalanceListView = Layout.extend(util.extend(
    {},
    printExportUtil,
    {
        template,
        className: 'panel panel-primary',

        ui: {
            $exportBtn: '[data-hook="export-button"]',
        },

        events: {
            'click [data-hook="export-button"]': 'customExport',
            'click [data-hook="print-button"]': 'customPrint',
        },

        initialize(options) {
            this.title = options.title || '';
            this.totalsService = options.totalsService || '';
            this.accountType = options.accountType;
            this.suppressServicesUrlSuffix = !!options.suppressServicesUrlSuffix;
            this.context = this.getContext(options);
            this.contextDef = this.context;
            this.additionalSearchFields = options.additionalSearchFields || [];
            this.staticFilterDependsOn = options.staticFilterDependsOn || [];
            this.showTotalsForAllAccounts = options.showTotalsForAllAccounts;
            this.previousPage = 1;
            this.previousPageSize = 10;
            this.amountColumn = options.amountColumn;
            this.availableColumn = options.availableColumn;
            this.field1Label = options.field1Label;
            this.field2Label = options.field2Label;
            this.totalsSection = options.totalsSection;
            this.currencyBadgesRegion = options.currencyBadgesRegion;
            this.sectionId = options.sectionId;
            this.tabId = options.tabId;
            this.urlTab = options.urlTab;
            this.entitlements = store.get('btr:entitlements') || options.entitlements;
            this.sectionHasTotals = options.sectionHasTotals;
            this.totalsWarning = options.totalsWarning;
            this.enableSavedViews = true;
            this.context.additonalLVCKey = options.additonalLVCKey ? `${this.tabId}-${this.sectionId}-${options.additonalLVCKey}`
                : `${this.tabId}-${this.sectionId}`;
            this.updateMasterOnSubAccountEnabled = applicationConfigParams.getValue('TIERLOAN', 'UpdateMasterOnSubAccountEnabled') === '1';
            this.prevDayTodayBalancesEnabled = applicationConfigParams.getValue('LNRPT', 'DISPLAYBALANCESPREVDAY') === '1';
            // staticFilterDependsOn will be used by the grid's filter combos
            this.staticFilterDependsOn.push({
                filterName: 'Depends',
                filterParam: ['tabId', this.tabId],
            }, {
                filterName: 'Depends',
                filterParam: ['sectionId', this.sectionId],
            });

            // HACK  TODO Remove when account groups refactored.
            this.hasTotalsRefactor = options.hasTotalsRefactor || util.has(options, 'totalsSection');

            if (this.model) {
                this.listenTo(
                    this.model,
                    {
                        refresh: this.refreshGrid,
                    },
                );
            }

            this.balancePoller = new BalancePoller({
                serviceUrl: options.requestRealtimeBalancesUrl,
                /**
                 * suppressPollingOnPagination flag: Since we are making a call to get real time
                 * balances inside tellBalancePollerWhichAccountsAreRealTime, there is not need to
                 * make that call on page pagination. By doing this, we are sending one real time
                 * call to the server with the correct accounts after the grid gets re-painted due
                 * to pagination or filter change.
                 */
                suppressPollingOnPagination: true,
            });

            this.maskingProperties = getMaskingProperties();

            ListView.prototype.setListViewConfig.call(this, this.context);

            this.listenTo(this.balancePoller, 'foundNewBalances', this.refreshGrid);
            this.listenTo(this.balancePoller, 'change:hasError', this.toggleBalancesError);
            this.listenTo(this.balancePoller, 'change:hasBankErrorMsg', this.showBankErrorMsg);
            this.loadViewRequirements();
        },

        loadViewRequirements() {
            const self = this;
            const requestParameters = {
                tabId: this.tabId,
                sectionId: this.sectionId,
            };
            const options = {
                context: this.context,
                enableSavedViews: this.enableSavedViews,
                hideGridActionButtons: true,
                enableRowContextButtonCallbacks: true,
                selector: 'none',
                suppressServicesUrlSuffix: this.suppressServicesUrlSuffix,
                staticFilterDependsOn: this.staticFilterDependsOn,
                cellViews: this.getGridCustomCells(this.tabId, this.entitlements),
                additionalSearchFields: this.additionalSearchFields,
                lvc: this.lvc,
                gridOptions: { caption: this?.title },
                requestParameters: {
                    item: transform.hashToPairs(requestParameters),
                },

                loadedCallback() {
                    self.balancePoller.start();
                    self.listenTo(self.gridView.grid, 'grid:resizeManageColumnGear', () => {
                        self.appBus.trigger('grid:resizeManageColumnGear');
                    });
                },
            };
            this.gridView = this.createGridView(options);
            return Promise.resolve();
        },

        refreshGrid() {
            if (this.gridView) {
                this.gridView.refreshGridData();
            }
        },

        /**
         * @method onPanelToggle
         * callback method when the panel expand is being toggled
         */
        onPanelToggle() {
            // will set grid width constraints on expand
            util.defer(() => {
                if (this.$el.is(':visible') && this.gridView.grid) {
                    this.gridView.grid.tableView.setWidth();
                }
            });
        },

        onRender() {
            this.loadViewRequirements();

            this.listenTo(this.gridView.getRows(), 'sync', (accounts) => {
                /**
                 * We should not make RT calls to deposits accounts under
                 * Current Day Cash tabs in BTR. RT calls to updated the accounts
                 * will happen in /getRealTimeInformation when BTRShowTotalsForAllAccounts
                 * config param is set to false. No RT calls should be made when
                 * BTRShowTotalsForAllAccounts is set to true (reference NH-119943)
                 */
                if (![
                    constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION_GIRTRN_I,
                    constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION,
                ].includes(this.tabId)) {
                    this.tellBalancePollerWhichAccountsAreRealTime(accounts);
                }
            });
            ListView.prototype.listenForGridErrors.call(this);
            this.listenTo(
                this.gridView,
                {
                    rowAction: this.gridRowAction,
                },
            );
            ListView.prototype.setupGridAvailableListener.call(this);

            if (this.tableContent) {
                this.tableContent.show(this.gridView);
            }

            this.listenTo(this.gridView, 'gridapi:loaded', () => {
                this.trigger('balanceGridView:child:isReady', this.gridView.getRows().lastRefreshTimestamp);
            });

            this.showTotals();

            /**
             * HACK: need to wait until panels partials in view finish render
             * The view isn't able to reference elements made by block partials thru UI hash
             * partial rendering is not accounted for when onRender fires
             */
            setTimeout(() => {
                // add listener to parent panel toggle for hanlding grid sizing when visible
                const $panelExpandToggle = this.$el.parents('.panel').find('[data-toggle="collapse"]');
                $panelExpandToggle.on('click', this.onPanelToggle.bind(this));
            }, 0);
        },

        showTotals() {
            this.isTOASection = this.sectionId === constants.ACCOUNT_SUMMARY.SECTIONS.TIMEOPEN;
            this.totalsModel = new TotalsModel({
                filters: this.gridView.wrapper.generateFiltersDataBlock(),
                viewId: this.gridView.wrapper.viewId,
            }, {
                service: Services.generateUrl(this.totalsService),
            });

            // There will be no totalsSection for TOA section and Deposits in Prev day
            if (this.totalsSection) {
                this.totalsView = new TotalsView({
                    model: this.totalsModel,
                });
                this.listenTo(this.totalsModel, 'change', () => {
                    const badges = this.totalsModel?.get('currencyTotals');
                    if (this.shouldDisplayCurrencyBadges()) {
                        // Hide totals section
                        if (this.currencyBadgesRegion) {
                            this.renderCurrencyBadgesRegion(
                                this.sectionId,
                                this.currencyBadgesRegion,
                                badges,
                            );
                            this.updateSectionStyles(!!badges.length);
                        }
                    } else {
                        $('.overall-panel-description').hide();
                        this.totalsSection.show(this.totalsView);
                    }
                });
            } else if (this.isTOASection) {
                this.setToaSectionHeight();
            }
            this.listenTo(this.gridView.getRows(), 'sync', this.refreshTotals);
        },

        /**
         * @method updateSectionStyles
         * @param {boolean} currencyBadgesAvailable
         * @description Helper method updates the style of accounts
         * section headers when currency badges region is available
         */
        updateSectionStyles(currencyBadgesAvailable) {
            $('.overall-panel-description').show();
            $(`.panel-description.${this.sectionId}`).hide();
            $('.toa-locations-dropdown').addClass('with-currency-badges');
            if (currencyBadgesAvailable) {
                $(`.panel-primary .panel-${this.sectionId}`).addClass('with-currency-badges');
            }
            const isAccountGroup = this.model?.get('ACCOUNTGROUP');
            if (this.isTOASection && !isAccountGroup) {
                $(`.carousel-${this.sectionId}`).addClass('with-toa-dropdown');
            }
            if (isAccountGroup) {
                $('.panel-title').css({ marginRight: 30 });
                $('.panel-heading').addClass('with-currency-badges');
            }
        },

        /**
         * @method setToaSectionHeight
         * @description Helper method to set TOA section height depending on
         * currency totals
         */
        setToaSectionHeight() {
            $(`.panel-${this.sectionId}`).addClass('without-currency-badges');
        },

        /**
         * @method shouldDisplayCurrencyBadges
         * @description check if we need to display
         * currency badges in BTR tabs
         * @returns {boolean}
         */
        shouldDisplayCurrencyBadges() {
            return [
                constants.ACCOUNT_SUMMARY.TABS.ALL,
                constants.ACCOUNT_SUMMARY.TABS.PRIOR,
                constants.ACCOUNT_SUMMARY.TABS.CURRENT,
            ].includes(this.tabId);
        },

        /**
         * @method renderCurrencyBadgesRegion
         * @param {string} sectionId
         * @param {Region} region
         * @param {[]} badges
         * @description renders currency badges for each BTR section
         */
        renderCurrencyBadgesRegion(sectionId, region, badges) {
            const CurrencyBadgesView = asView(CurrencyBadges);
            region.show(new CurrencyBadgesView({
                sectionId,
                badges,
            }));
        },

        createGridView(options) {
            return GridApi.createServiceGridView(options);
        },

        gridRowAction(options) {
            if (options.action.toUpperCase() === 'SELECT') {
                const account = new Account({
                    accountFilter: options.model.get('ACCOUNTFILTER'),
                    accountName: maskValue(options.model.get('ACCOUNTNAME'), this.maskingProperties),
                    accountNumber: options.model.get('ACCOUNTNUMBER'),
                    clientAccountName: options.model.get('CLIENTACCOUNTNAME'),
                    currencyCode: options.model.get('CURRENCYCODE'),
                    isRealTime: options.model.get('ISREALTIMEACCOUNT') === '1',
                    principalBalance: options.model.get('PRINCIPALBALANCE'),
                    availableCommitmentAmount: options.model.get('AVAILABLECOMMITMENTAMOUNT'),
                    accountType: options.model.get('ACCOUNTTYPE'),
                    accountSubType: options.model.get('ACCOUNTSUBTYPE'),
                    aba: options.model.get('ABA'),
                    bankCode: options.model.get('BANKCODE'),
                    toaLocationNumber: options.model.get('LOCATIONNUMBER'),
                    subAccountType: options.model.get('SUBACCOUNT_TYPE'),
                    tabId: this.tabId,
                    urlTab: this.urlTab,
                    sectionId: this.sectionId,
                });

                store.set('balanceAndTransactionAccount', account);
                store.set(
                    'btr:permissions',
                    {
                        entitlements: this.entitlements,
                        currentTab: this.tabId,
                    },
                );

                const isTieredLoan = this.isTieredLoan(options.model);

                if (isTieredLoan) {
                    const totals = {};

                    // Just pass the valid totals columns
                    if (!util.isNullOrUndefined(this.amountColumn)) {
                        totals.field1 = {
                            amountColumn: this.amountColumn,
                            amountLabel: this.field1Label,
                        };
                    }

                    if (!util.isNullOrUndefined(this.availableColumn)) {
                        totals.field2 = {
                            amountColumn: this.availableColumn,
                            amountLabel: this.field2Label,
                        };
                    }
                    account.totals = totals;
                    this.navigateTo(this.options.tieredLoanDetailPageUrl);
                } else {
                    store.set('balanceAndTransactionReturnRoute', this.options.detailPageUrl);
                    this.navigateTo(this.options.detailPageUrl);
                }
            }
            return Promise.resolve();
        },

        /**
         * @param {string} tabId
         * @param {Model} entitlements
         * @return {object}
         * Based on the current BTR tab and the user's BTR entitlement permissions,
         * will decide what columns will have actionable cells and return an object
         * with cell view
         * customizations used by the grid
         */
        getGridCustomCells(tabId, entitlements) {
            const customCells = {};
            const defaults = {
                ASOFTIME: AsOfTimeCellView,
            };

            if (!tabId || !entitlements) {
                return defaults;
            }

            switch (tabId) {
            // For Prior and Groups tab cases
            case constants.ACCOUNT_SUMMARY.TABS.PRIOR:
                if (entitlements.isEntitled(constants.BTR_TYPECODES.PREVIOUS.TRANSACTIONS)) {
                    customCells.ACCOUNTNUMBER = BTRActionCellView;
                }
                break;

                // For Current and Cash position tab cases
            case constants.ACCOUNT_SUMMARY.TABS.CURRENT:
            case constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION:
                if (entitlements.isEntitled(constants.BTR_TYPECODES.CURRENT.TRANSACTIONS)) {
                    customCells.ACCOUNTNUMBER = BTRActionCellView;
                }
                break;

            // For Current Day Transaction (GIRTRN_I) Cash position tab case
            case constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION_GIRTRN_I:
                if (entitlements.isEntitled(constants.GIRTRN_I)) {
                    customCells.ACCOUNTNUMBER = BTRActionCellView;
                }
                break;

            case constants.ACCOUNT_SUMMARY.TABS.ALL:
                customCells.ACCOUNTNUMBER = BTRActionCellView;
                break;
            default:
            }

            return util.extend(defaults, customCells);
        },

        /*
         * @method: refreshTotals
         * @param {Collection} collection as part of backbone ajax response
         * @param {Object} response
         * Retrieves the totals for the grid from the footer row.  If the footer
         * row is null
         * because there is no data loaded for any entitled accounts, the totals are
         * defaulted to 0.
         */
        refreshTotals(collection, response) {
            if (this.shouldGetTotalsFromServer()) {
                // If section does not have totals, do not retrieve totals from the server.
                if (!util.isNullOrUndefined(this.amountColumn)
                        || (!util.isNullOrUndefined(this.availableColumn))) {
                    this.getTotalsFromServer();
                }
            } else if (this.totalsSection) {
                this.getTotalsFromGrid(response.footerRows);
            }
        },

        /**
         * @method shouldGetTotalsFromServer
         * @description Helper method to check whether to pull section totals from server or
         * from the grid. When true, the totals should not change on grid filter change
         * @return {boolean}
         */
        shouldGetTotalsFromServer() {
            return (this.sectionId === constants.ACCOUNT_SUMMARY.SECTIONS.TIMEOPEN
                    && this.tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR)
                    || this.showTotalsForAllAccounts;
        },

        /**
         * Gets the total amount, label and currency from the server and sets the
         * values in the
         * the totals model.
         */
        getTotalsFromServer() {
            const totals = {};

            // Just pass the valid totals columns
            if (!util.isNullOrUndefined(this.amountColumn)) {
                totals.field1 = {
                    amountColumn: this.amountColumn,
                    amountLabel: this.field1Label,
                };
            }

            if (!util.isNullOrUndefined(this.availableColumn)) {
                totals.field2 = {
                    amountColumn: this.availableColumn,
                    amountLabel: this.field2Label,
                };
            }

            this.totalsModel.set({
                filters: this.gridView.wrapper.generateFiltersDataBlock(),
            });
            this.totalsModel.dataBlock = this.gridView.wrapper.getDataBlock();
            this.totalsModel.fetch({
                tabId: this.tabId,
                sectionId: this.sectionId,
                totals,
            });
        },

        isDeposit() {
            return this.accountType === 'DEPOSIT';
        },

        /*
         * @method: getTotalsFromGrid
         * @param {Array} totalsRow
         * Gets the total amount using the totals passed in the footer row
         */
        getTotalsFromGrid(totalsRow) {
            const totals = this.gatherColumnTotals(totalsRow).filter(total => total.currencyCode);
            let currencyTotals = [];
            // dont show available credit if this is true
            if (applicationConfigParams.get('LNRPT_HideAvailableCreditTotals') === '1' && this.accountType === 'LOAN') {
                // move the outstanding principle over to field2 and label2
                currencyTotals = totals.map(total => ({
                    field2Label: this.hasTotalsRefactor
                        ? this.field1Label : locale.get(this.field1Label),
                    field2Amount:
                        Formatter.formatNumberByCurrency(total.amount, total.currencyCode),
                    count: Number(total.count),
                    currencyCode: total.currencyCode,
                }));
            } else {
                currencyTotals = totals.map(total => ({
                    field1Label: this.hasTotalsRefactor
                        ? this.field1Label : locale.get(this.field1Label),
                    field1Amount:
                        Formatter.formatNumberByCurrency(total.amount, total.currencyCode),
                    field2Label: this.hasTotalsRefactor
                        ? this.field2Label : locale.get(this.field2Label),
                    field2Amount:
                        Formatter.formatNumberByCurrency(total.available, total.currencyCode),
                    count: Number(total.count),
                    currencyCode: total.currencyCode,
                }));
            }
            this.totalsModel.set('currencyTotals', currencyTotals);
            this.totalsView.render();
        },

        /*
         * @method: gatherColumnTotals
         * @param {Array} totalsRow
         * @returns {Object}
         * Gets the total amount using the totals passed
         * in the footer row and returns an object of the amounts.
         */
        gatherColumnTotals(totalsRow) {
            // Determine if a footer row is returned from server.
            const hasTotalsRow = !util.isNullOrUndefined(totalsRow);
            if (!hasTotalsRow) {
                return [{
                    amount: 0,
                    available: 0,
                    count: 0,
                    currencyCode: null,
                }];
            }
            return totalsRow.map((row) => {
                // If no footer is returned then set the amounts to 0.
                const amount = hasTotalsRow
                    ? util.findWhere(row.columns, { fieldName: this.amountColumn }) : 0;
                const available = hasTotalsRow
                    ? util.findWhere(row.columns, { fieldName: this.availableColumn }) : 0;
                const count = hasTotalsRow
                    ? util.findWhere(row.columns, { fieldName: 'FOOTER_KEY_COUNT' }) : 0;
                const currencyCode = hasTotalsRow
                    ? util.findWhere(row.columns, { fieldName: 'FOOTER_KEY' }) : null;
                return {
                    amount: amount ? amount.fieldValue : null,
                    available: available ? available.fieldValue : null,
                    count: count ? count.fieldValue : null,
                    currencyCode: currencyCode ? currencyCode.fieldValue : null,
                };
            });
        },

        /**
         * Formats column formatted string amount to number
         * @param {Array} amount
         * @returns {Array}
         */
        formatTotalsAmount(amount) {
            let localAmount = amount;
            if (!util.isUndefined(localAmount)) {
                localAmount = util.map(localAmount, item => Formatter.stringToNumber(item));
            }
            return localAmount;
        },

        toggleBalancesError() {
            this.gridView.$el.toggleClass('has-balance-warning', this.balancePoller.hasError());
        },

        showBankErrorMsg() {
            const msg = this.balancePoller.getBankErrorMsg();
            if (util.isArray(msg) && msg.length > 0) {
                this.alertView = alert.danger(
                    msg.join('\n'),
                    {
                        canDismiss: true,
                    },
                );
                this.alertRegion.show(this.alertView);
            } else {
                this.alertRegion.close();
            }
        },

        /**
         * @name tellBalancePollerWhichAccountsAreRealTime
         * @description determines which account need to request a realtime balance update
         * this method is called whenever the grid rows are changed (pagination, sorting, etc)
         * @param {list of models} accounts
         */
        tellBalancePollerWhichAccountsAreRealTime(accounts) {
            const [realTimeAccountFilters, realTimeAccountTimestamps] = accounts
                .reduce((acc, account) => {
                    if (account.get('ISREALTIMEACCOUNT') === '1'
                        && !this.isExcludedLoan(account)
                        && !this.isSuppressedPrevDayAccount()) {
                        acc[0].push(account.get('ACCOUNTFILTER'));
                        acc[1].push(account.get('ASOFTIME'));
                    }
                    return acc;
                }, [[], []]);

            const realTimeBalancesDataCacheTimeout = serverConfigParams.get('realTimeBalancesDataCacheTimeout');
            const timeout = realTimeBalancesDataCacheTimeout
                ? +realTimeBalancesDataCacheTimeout * 1000 : TWO_MINUTES_IN_MILLISECONDS;

            const now = moment(new Date()).tz(userInfo.get('timezone'));

            const timeStamps = util.filter(realTimeAccountTimestamps, (time) => {
                const lastUpdate = moment(time);
                const formattedNow = moment(now.format('MM/DD/YYYY HH:mm:ss'));
                const formattedLastUpdate = moment(lastUpdate.format('MM/DD/YYYY HH:mm:ss'));
                return formattedNow.diff(formattedLastUpdate) > timeout;
            });
            /*
             * Configuration to support Eastern Bank requirements where tiered loan accounts
             * that have all non accrual notes are filtered from appearing in loan list view.
             * When true, The config allows RT calls still be made even if no RT account
             * on list view.
             */
            if (this.isLoans()
                     && this.doRealTimeCallForAllAccountsConfigParam()
                         && realTimeAccountFilters.length === 0) {
                this.balancePoller.set('accountFilters', ['All']);
                this.balancePoller.start();
                userInfo.set('lastRefreshTimeStamp', now);
            } else {
                this.balancePoller.set('accountFilters', realTimeAccountFilters);
                if (realTimeAccountFilters.length > 0 && timeStamps.length > 0) {
                    this.balancePoller.start();
                    userInfo.set('lastRefreshTimeStamp', now);
                }
            }
        },

        /**
         * Get print options for this list
         * @returns {Object}
         */
        getPrintOptions() {
            return {
                view: this,
                gridView: this.gridView,
                hasSummarySelection: false,
                exportURL: this.options.exportPrintURL
                        || (systemConfig.isAdmin() ? constants.BALANCE_EXPORT_PRINT_URL
                            : constants.ASYNC_BALANCE_EXPORT_PRINT_URL),

                contextData: {
                    tabId: this.tabId,
                    sectionId: this.sectionId,
                },
                ...(!this.context.suppressPrintExportInquiryId && {
                    inquiryId: this.context.inquiryId,
                    summaryInquiryId: this.context.inquiryId,
                }),
            };
        },

        /**
         * @return {object} accounts filter
         * Generate a grid filter object for all current visible account data on the grid
         * For use in export filters
         */
        buildVisibleAccountsFilter() {
            const visibleAccounts = util.map(this.gridView.grid.collection.models, account => account.get('ACCOUNTFILTER'));
            return {
                fieldName: 'ACCOUNTFILTER',
                fieldValue: visibleAccounts,
            };
        },

        /**
         * @param {array} filterValues
         * @return {array} modified filter values
         * Handles different account or account group filter scenarios for export
         * based on user's selected filters and the default account
         * related filters applied on list view
         */
        handleAccountFiltering(filterValues) {
            let localFilterValues = filterValues;
            const accountGroupFilterApplied = util.findWhere(
                localFilterValues,
                {
                    fieldName: 'ACCOUNTGROUP',
                },
            );

            if (accountGroupFilterApplied && localFilterValues.length > 1) {
                /*
                 * if ACCOUNT GROUP FILTER is applied inherently and
                 * the user applies other filters as well
                 * remove the account-group and create account filter
                 * for all visible accounts
                 */
                localFilterValues = util.difference(localFilterValues, accountGroupFilterApplied);

                localFilterValues.push(this.buildVisibleAccountsFilter());
            }

            return localFilterValues;
        },

        /*
         * @method isLoans
         * check if the current accounts grid view is a loan section
         * @return {boolean}
         */
        isLoans() {
            return this.gridView.context.typeCode === constants.LOANACCT;
        },

        /*
         * @method isCreditCard
         * check if the current accounts grid view is a credit card section
         * @return {boolean}
         */
        isCreditCard() {
            return this.gridView.context.overrideTypeCode
                === constants.ACCOUNT_SUMMARY.SECTIONS.CREDIT_CARDS;
        },

        /*
         * @method doRealTimeCallForAllAccountsConfigParam
         * Configuration to support Eastern Bank requirements where tiered loan accounts
         * that have all non accrual notes are filtered from appearing in loan list view.
         * When true, The config allows RT calls still be made even if no RT account
         * on list view.
         * @return {boolean}
         */
        doRealTimeCallForAllAccountsConfigParam() {
            return serverConfigParams.get('GetRealtimeBalanceForAllLoanAccounts') === 'true';
        },

        customPrint() {
            this.print(this.getPrintOptions());
        },

        customExport() {
            if (this.isLoans()) {
                this.export(this.getPrintOptions());
            } else {
                // disable upon click to prevent multiple instantiation
                this.ui.$exportBtn.prop('disabled', true);

                this.listenToOnce(this.balancePoller, 'pollComplete', () => {
                    /*
                     * Create filters that get sent to the export view (used by the
                     * export service)
                     */
                    const selectedGridRows = this.gridView.grid.getSelectedRows();
                    const gridType = this.gridView.context.typeCode;
                    const standardExportOnly = gridType.indexOf('LOAN') > -1;
                    let initialFilterValues = this.gridView.wrapper.generateFiltersDataBlock();

                    /*
                     * Handle the account filtering based on tab user is on and their
                     * selected filters
                     */
                    initialFilterValues = this.handleAccountFiltering(initialFilterValues);

                    // create export view
                    this.exportDataView = new GridExportBTRDataView({
                        gridExportOptions: this.getPrintOptions(),
                        hasSelectedRows: selectedGridRows.length > 0,
                        initialFilterValues,
                        standardExportOnly,
                        currentTab: this.tabId,
                        entitlements: this.entitlements,
                        accountType: this.accountType,
                        /*
                         * This inquiryId does not return MT940 or MT042,
                         * which is expected for this list view
                         */
                        exportTypeInquiryId: 22025,
                        gridView: this.gridView,
                    });

                    this.listenTo(this.exportDataView, 'doExport', () => {
                        // Display link to download screen
                        const successMessage = locale.get('GIR.output.success').replace('#', ' ');
                        const docRoot = window.Bottomline.documentRoot
                            ? `/${window.Bottomline.documentRoot}` : '';
                        const $message = $('<a/>').attr('href', `${docRoot}/${window.Bottomline.appRoot}/REPORTING/btrExport`).text(locale.get('GIR.download.view'));

                        /*
                         * Initial message does not have the link
                         * because it cannot contain markup.
                         */
                        ListView.prototype.showAlertMessage.call(this, successMessage, 'success');

                        // Find the alert in and update it.
                        this.alertView.$el.find('p').text(successMessage).append($message);
                    });

                    this.listenTo(this.exportDataView, 'doExportFailed', () => {
                        ListView.prototype.showAlertMessage
                            .call(this, locale.get('payment.export.error.message'), 'danger');
                    });

                    this.listenToOnce(this.exportDataView, 'close', () => {
                        this.ui.$exportBtn.prop('disabled', false);
                    });

                    dialog.open(this.exportDataView);
                });

                this.balancePoller.set('accountFilters', ['All']);
                this.balancePoller.start();
            }
        },

        /**
         * Helper method to exclude a tiered loan based on updateMasterOnSubAccountEnabled param
         * When enabled, this configuration assumes all loan accounts are tiered loan accounts.
         * @param {Object} account - model
         * @returns {boolean}
         */
        isExcludedLoan(account) {
            /**
             * If updateMasterOnSubAccountEnabled is disabled, we should make RT calls to both
             * loans types
             * If updateMasterOnSubAccountEnabled is enabled, we should not make RT calls to
             * either loans types
             */
            if (this.updateMasterOnSubAccountEnabled
                && accountUtil.isLoan(account.get('ACCOUNTTYPE'))) {
                return true;
            }
            return false;
        },

        /**
         * Helper method check if an account is a tiered loan.
         * @param {Object} account - model
         * @returns {boolean}
         */
        isTieredLoan(account) {
            /**
             * When updateMasterOnSubAccountEnabled is enabled, we will
             * treat all loans as tiered loans
             * when is disabled, the UI will need to re-route to the correct loan page
             */
            return accountUtil.isLoan(account.get('ACCOUNTTYPE'))
                && (this.updateMasterOnSubAccountEnabled
                || parseInt(account.get('NOTENUMBER'), 10) > 0);
        },

        /**
         * Helper method to check whether we should get real time calls for an
         * account.
         * React time calls will not be made if an account is:
         *  - prev day account
         *  - loan account, and
         *  - prev day today balances config is off
         * @returns {boolean}
         */
        isSuppressedPrevDayAccount() {
            const { tabId } = this.options;
            return (this.isLoans() || this.isCreditCard())
                && tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR
                && !this.prevDayTodayBalancesEnabled;
        },

        /**
         * @method getContext
         * @description get the correct context. Will override filterID & inquiryId
         * only in current day cash
         * @param {Object} options
         * @returns {Object}
         */
        getContext(options) {
            const overrideFilterId = [
                constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION,
                constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION_GIRTRN_I,
            ];
            const { tabId, context = {} } = options;
            if (overrideFilterId.includes(tabId)) {
                /*
                * NH-183046 InquiryId and filterID are usually set via
                * menu context. In this case, there isn't a valid context
                * to use, so manually setting these values to 0.
                */
                return {
                    ...context,
                    filterID: 0,
                    inquiryId: 0,
                };
            }
            return context;
        },

        templateHelpers() {
            const self = this;
            return {
                title: this.title,
                id: this.cid,
                showTotalsForAllAccounts: this.showTotalsForAllAccounts,
                sectionHasTotals: this.sectionHasTotals,

                getTotalsWarning() {
                    return !self.showTotalsForAllAccounts && self.totalsWarning
                        ? self.totalsWarning : null;
                },

                gridUtilityOptions: {
                    hasRefresh: false,
                },
            };
        },
    },
));

let list = BalanceListView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
