import util from '@glu/core/src/util';
import CompositeView from '@glu/core/src/compositeView';
import ComplexTypes from 'app/administration/collection/user/complexTypes';
import locale from '@glu/locale';
import ItemView from './itemView';
import groupViewTmpl from './groupView.hbs';

export default CompositeView.extend({
    template: groupViewTmpl,
    itemView: ItemView,
    itemViewContainer: '.list-container',
    className: 'edit-limit-group',

    ui: {
        limitFields: '.limit-field',
    },

    initialize(options) {
        this.itemViewOptions = {
            modifyMode: options.modifyMode || false,
            mode: options.mode,
            paymentTypeGroup: this.model.get('typeGroupId') || false,
        };

        this.collection = new ComplexTypes(this.model.getEntitledTypes(!options.modifyMode));
    },

    onRender() {
        this.ui.limitFields.inputmask(
            'integer',
            {
                allowMinus: false,
            },
        );
    },

    applyQuick() {
        const self = this;
        const quickValues = [];

        // Extract quick values and their field names in the model, wipe out quick values
        self.ui.limitFields.each((idx, field) => {
            const $field = self.$(field);
            const limitType = $field.data('value');

            if ($field.val()) {
                quickValues.push({
                    field: `overall${limitType}LimitForType.assignedLimit`,
                    value: $field.val(),
                });

                $field.val('');
            }
        });

        // Apply quick values to the model
        this.collection.each((type) => {
            util.each(quickValues, (quickValue) => {
                type.set(quickValue.field, quickValue.value);
            });
        });
    },

    templateHelpers() {
        const type = this.model.get('typesInGroup').at(0);
        const groupLabel = locale.get(`administration.${this.model.get('typeGroupLabel')}`);
        return {
            showTrans: type.get('overallTransactionLimitForType').applicable,
            showBatch: type.get('overallBatchLimitForType').applicable,
            showDaily: type.get('overallDailyLimitForType').applicable,
            modifyMode: this.options.modifyMode,
            groupLabel,
            groupLabelText: locale.get('administration.limitsText', groupLabel),
        };
    },
});
