var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mfaActivationHelpLinkTarget") || (depth0 != null ? lookupProperty(depth0,"mfaActivationHelpLinkTarget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mfaActivationHelpLinkTarget","hash":{},"data":data,"loc":{"start":{"line":11,"column":32},"end":{"line":11,"column":63}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"mfaActivationHelpLinkText") || (depth0 != null ? lookupProperty(depth0,"mfaActivationHelpLinkText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mfaActivationHelpLinkText","hash":{},"data":data,"loc":{"start":{"line":11,"column":81},"end":{"line":11,"column":110}}}) : helper)))
    + "</a></p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form autocomplete=\"off\" data-submit=\"save\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.token.intro",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":44}}}))
    + "</p>\n\n        <div class=\"row\">\n            <div class=\"col-6\">\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.token.serial",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":19},"end":{"line":8,"column":52}}}))
    + ": <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"TokenSerialNumber") || (depth0 != null ? lookupProperty(depth0,"TokenSerialNumber") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"TokenSerialNumber","hash":{},"data":data,"loc":{"start":{"line":8,"column":62},"end":{"line":8,"column":83}}}) : helper)))
    + "</strong></p>\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.token.activation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":19},"end":{"line":9,"column":56}}}))
    + ": <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"registrationCode") || (depth0 != null ? lookupProperty(depth0,"registrationCode") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"registrationCode","hash":{},"data":data,"loc":{"start":{"line":9,"column":66},"end":{"line":9,"column":86}}}) : helper)))
    + "</strong></p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mfaShowActivationHelpLink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-6\">\n                <div class=\"form-group\">\n                    <label for=\"activationCode\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.entrust.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":81}}}))
    + "</label>\n                    <input class=\"form-control\"\n                        data-bind=\"model\"\n                        data-hook=\"getCode\"\n                        id=\"activationCode\"\n                        name=\"activationCode\"\n                        type=\"text\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"activationCode\"></span>\n                </div>\n                <button\n                    type=\"button\"\n                    class=\"btn btn-primary btn-sm\"\n                    data-action=\"submitCode\"\n                    data-hook=\"submit\"\n                >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":17},"end":{"line":32,"column":43}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-secondary btn-sm\" data-action=\"returnToChallenge\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":103},"end":{"line":33,"column":129}}}))
    + "</button>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});