import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import Handlebars from 'handlebars';
import util from '@glu/core/src/util';
import accountWithBalTmpl from './accountWithBal.hbs';

export default ItemView.extend({
    initialize(options) {
        this.model = options.model;
        this.column = options.column;
        this.displayStache = this.column.get('displayStache');
        this.accountList = this.column.get('accountList');
        this.idField = this.column.get('idField');
    },

    template: accountWithBalTmpl,
    binding: true,
    tagName: 'td',
    className: 'sb-simple-cell',

    getAccountId() {
        return this.model.get(this.idField);
    },

    getAccount() {
        const self = this;
        return util.findWhere(
            self.accountList.models,
            {
                id: self.getAccountId(),
            },
        );
    },

    balanceExists() {
        const balance = this.getBalance();
        if (util.isEmpty(balance)) {
            return false;
        }
        return true;
    },

    displayBalanceIfExists() {
        if (!this.balanceExists()) {
            return locale.get('sbPayments.notAvailable');
        }
        return Formatter.formatCurrency(this.getBalance());
    },

    getBalance() {
        return this.getAccount().get('balance');
    },

    templateHelpers() {
        const self = this;

        return {
            cellHeader() {
                return self.column.get('title');
            },

            cellHeaderExists() {
                return !util.isEmpty(this.cellHeader());
            },

            cellValue() {
                const account = self.getAccount(self.getAccount());
                return Handlebars.compile(self.displayStache)(account.toJSON());
            },

            cellValueBalExists() {
                return self.balanceExists();
            },

            cellValueBalance() {
                return self.displayBalanceIfExists();
            },
        };
    },
});
