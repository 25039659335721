export const updateSelectAllState = (view, checkboxList, selectAllCheckbox) => {
    const allChecked = checkboxList?.not(':checked').length === 0;
    (selectAllCheckbox || view.ui.$selectAll).prop('checked', view.allSelected || allChecked);
    view.onChildrenChange();
};

export const onChildrenChange = (view, checkboxList) => {
    checkboxList
        ?.off('change', view.boundUpdateSelectAllState)
        .on('change', view.boundUpdateSelectAllState);
};
