import Collection from '@glu/core/src/collection';
import services from 'services';

import http from '@glu/core/src/http';
import systemConfig from 'system/configuration';

export default Collection.extend({
    sync(method, model, options) {
        const approverService = services.generateUrl('tableMaintenance/getQueryResults');
        const isAdmin = systemConfig.isAdmin();
        const approverData = {
            queryCriteria: {
                action: {
                    productCode: isAdmin ? 'CMAINT' : '_ADMIN',
                    functionCode: isAdmin ? 'MAINT' : 'USERS',
                    typeCode: isAdmin ? 'CLUSR_TM' : '_USER',
                    actionMode: 'SELECT',
                },
                inquiryId: isAdmin ? 17020 : 40008,
                allowDuplicates: false,
                entryClass: '',
                subTypeCode: '*',
            },
        };
        // if in admin vs. client
        if (isAdmin) {
            approverData.queryCriteria.customFilters = [
                {
                    filterName: 'Depends',
                    filterParam: [
                        'USERGROUP',
                        options.userModel.get('USERGROUP'),
                    ],
                },
            ];
        }

        return http.post(approverService, approverData, (result) => {
            options.success(result.queryResponse.QueryData.queryRows.map(item => ({
                id: item.name,
                name: item.label,
            })));
        }, () => {
            options.error();
        });
    },

    parse(response) {
        return response.map(item => ({
            id: item.name,
            name: item.label,
        }));
    },
});
