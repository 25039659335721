import util from '@glu/core/src/util';
import Field from './fieldModel';

export default Field.extend({
    defaults() {
        return util.extend(
            Field.prototype.defaults.call(this),
            {
                dataType: 'text',
                operator: 'EQ',
            },
        );
    },
});
