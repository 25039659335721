// potential name extractColumns()
import util from '@glu/core/src/util';

export function responseToScreenData({ rows }) {
    return rows.map(row =>
        row.columns.reduce((newRow, { fieldName, fieldValue }) => {
            newRow[fieldName.toUpperCase()] = util.unescape(fieldValue); // eslint-disable-line
            return newRow;
        }, {}));
}

// potential name extractColumnsFromMapDataList()
export function responseToScreenDataMap({ rows }) {
    const mapList = rows.map(row =>
        row.mapDataList.reduce((newRow, { toField, value }) => {
        newRow[toField.toUpperCase()] = util.unescape(value); // eslint-disable-line
            return newRow;
        }, {}));
    const columnList = responseToScreenData({ rows });
    const combinedList = mapList.map((item, index) => ({ ...item, ...columnList[index] }));
    return combinedList;
}

// responseToScreenDataMap2() is very similar to responseToScreenDataMap()
// TODO refactor, extract, etc.
// potential name extractMapDataList()
export function responseToScreenDataMap2({ queryRows: rows }) {
    const mapList = rows.map(row =>
        row.mapDataList.reduce((newRow, { toField, value }) => {
            newRow[toField.toUpperCase()] = util.unescape(value); // eslint-disable-line
            return newRow;
        }, {}));
    return mapList;
}

// Felipe, when u created below function in DivisionDropdown,jsx,
// there already were similar functions above in this file!
// TODO simplify this function/extract/modify/etc.
export function getDetailsFromValue(rawList, dataValue) {
    const foundElements = rawList.find(elem => elem.name === dataValue);
    if (!foundElements) return {};
    return foundElements.mapDataList.reduce((acc, detail) => ({
        ...acc,
        [detail.toField]: util.unescape(detail.value),
    }), {});
}

export function nameToValue(array) {
    return array.reduce((result, item) => {
        result[item.name] = util.unescape(item.value); // eslint-disable-line
        return result;
    }, {});
}

export function gridsToJSONByCode(grids) {
    return grids.reduce((result, grid) => {
        const groupedDataByCode = grid.items.reduce((codeDataPerGrid, gridItem) => {
            const formattedItem = nameToValue(gridItem.item);

            if (!codeDataPerGrid[formattedItem.CODE]) {
                codeDataPerGrid[formattedItem.CODE] = []; // eslint-disable-line
            }

            codeDataPerGrid[formattedItem.CODE].push(formattedItem);
            return codeDataPerGrid;
        }, {});

        result.push(groupedDataByCode);
        return result;
    }, []);
}

export function dataToDropdownOptions(data) {
    return data.reduce((result, item) => {
        result.push({
            id: item.name,
            name: util.unescape(item.label),
        });
        return result;
    }, []);
}

export function onlyLabelDataToDropdownOptions(data) {
    return data.reduce((result, item) => {
        result.push({
            id: item.label,
            name: util.unescape(item.label),
        });
        return result;
    }, []);
}

export function rowDataToDropdownOptions({ data, id, name }) {
    const formattedData = responseToScreenData(data);
    const combinedName = (nameArray, item) => nameArray.map(namePart => item[namePart]).join(' ');

    return formattedData.reduce((result, item) => {
        result.push({
            id: item[id],
            name: util.unescape(Array.isArray(name) ? combinedName(name, item) : item[name]),
        });

        return result;
    }, []);
}

export function rowDataToListBuilderOptions({
    data, id, name, description,
}) {
    if (data && data.rows) {
        const formattedData = responseToScreenData(data);
        const combinedName = (nameArray, item) => nameArray.map(namePart => item[namePart]).join(' ');

        return formattedData.reduce((result, item) => {
            result.push({
                id: item[id],
                name: Array.isArray(name) ? combinedName(name, item) : item[name],
                description: util.unescape(Array.isArray(description) ?
                    combinedName(description, item) :
                    item[description]),
            });

            return result;
        }, []);
    }
    return [];
}

// rowDataToDropdownOptions2() is very similar to rowDataToDropdownOptions()
// TODO refactor, extract, etc.
// Implement separator in both functions.
export function rowDataToDropdownOptions2({ data, id, name }) {
    const formattedData = data;
    const combinedName = (nameArray, item) =>
        nameArray.map(namePart => (item[namePart] ? item[namePart] : namePart)).join(' - ');

    return formattedData.reduce((result, item) => {
        result.push({
            id: item[id],
            name: util.unescape(Array.isArray(name) ? combinedName(name, item) : item[name]),
        });

        return result;
    }, []);
}
