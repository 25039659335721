var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"prev-page-breadcrumb\">\n            <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp;\n                <span class=\"breadcrumb-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":46},"end":{"line":6,"column":88}}}))
    + "</span></a>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"section-header row\">\n            <div class=\"col-10\">\n                <h2 data-hook=\"roleName\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"roleName") || (depth0 != null ? lookupProperty(depth0,"roleName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"roleName","hash":{},"data":data,"loc":{"start":{"line":18,"column":41},"end":{"line":18,"column":53}}}) : helper)))
    + "</h2>\n                <span data-hook=\"roleDesc\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"roleDesc") || (depth0 != null ? lookupProperty(depth0,"roleDesc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"roleDesc","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":55}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"col-2 text-right\">\n                <form class=\"form-inline\">\n                    <div class=\"form-group\">\n                        <label>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"administration.company",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":31},"end":{"line":27,"column":66}}}))
    + "</label>\n                        <p class=\"form-control-static\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userGroup") || (depth0 != null ? lookupProperty(depth0,"userGroup") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userGroup","hash":{},"data":data,"loc":{"start":{"line":28,"column":55},"end":{"line":28,"column":68}}}) : helper)))
    + "</p>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBankMaintained") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":35,"column":27}}})) != null ? stack1 : "")
    + "                </form>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"btn btn-tertiary","action":"editRoleInfo","name":"pencil"},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":94}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                       <div class=\"form-group\">\n                       <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.BankMaintained",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":30},"end":{"line":32,"column":60}}}))
    + "</label>\n                       <p data-hook=\"bankMaintained\" class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"bankMaintained") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":81},"end":{"line":33,"column":106}}}))
    + "</p>\n                      </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"section-header user-profile-info\">\n            <div class=\"user-info\">\n                <h2 class=\"name\"></h2>\n                <span class=\"city\"></span>\n            </div>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-tertiary\"\n                        data-step=\"userProfile\"\n                        tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.user.profile",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":37},"end":{"line":57,"column":77}}}))
    + "\n                    </a>\n                </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-tertiary payments\"\n                         data-step=\"payments\"\n                         tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.payments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":66,"column":38},"end":{"line":66,"column":74}}}))
    + "\n                    </a>\n                </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-tertiary\"\n                        data-step=\"reporting\"\n                        tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.reporting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":37},"end":{"line":75,"column":74}}}))
    + "\n                    </a>\n                </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-tertiary\"\n                        data-step=\"riskManagement\"\n                        tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.risk.mgmt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":37},"end":{"line":84,"column":74}}}))
    + "\n                    </a>\n                </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-tertiary\"\n                        data-step=\"admin\"\n                        tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.admin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":93,"column":37},"end":{"line":93,"column":70}}}))
    + "\n                    </a>\n                </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-tertiary\"\n                        data-step=\"alerts\"\n                        tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.alerts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":102,"column":37},"end":{"line":102,"column":71}}}))
    + "\n                    </a>\n                </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link btn btn-tertiary\"\n                        data-step=\"abs\"\n                        tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.abs",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":111,"column":37},"end":{"line":111,"column":68}}}))
    + "\n                    </a>\n                </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary submit\" data-action=\"save\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"addButtonLabel") || (depth0 != null ? lookupProperty(depth0,"addButtonLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addButtonLabel","hash":{},"data":data,"loc":{"start":{"line":127,"column":88},"end":{"line":127,"column":106}}}) : helper)))
    + "</button>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":128,"column":12},"end":{"line":136,"column":12}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":16},"end":{"line":133,"column":27}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":20},"end":{"line":132,"column":29}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":131,"column":57},"end":{"line":131,"column":110}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":131,"column":111},"end":{"line":131,"column":121}}}) : helper)))
    + "\" data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":131,"column":136},"end":{"line":131,"column":146}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":131,"column":148},"end":{"line":131,"column":157}}}) : helper)))
    + "</button>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "btn-primary";
},"32":function(container,depth0,helpers,partials,data) {
    return "btn-secondary";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"modify") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":12},"end":{"line":136,"column":12}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary submit\" data-action=\"save\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.modifyUser",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":135,"column":88},"end":{"line":135,"column":115}}}))
    + "</button>\n            ";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":138,"column":84},"end":{"line":138,"column":174}}})) != null ? stack1 : "")
    + "</button>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.Back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":138,"column":100},"end":{"line":138,"column":121}}}));
},"40":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":138,"column":129},"end":{"line":138,"column":167}}}));
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasSimulateEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":20},"end":{"line":143,"column":39}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-primary simulate\" data-action=\"simulate\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.simulateUser",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":142,"column":106},"end":{"line":142,"column":135}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--breadcrumbs and title-->\n<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    <h1 class=\"landing-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"getPageTitle") || (depth0 != null ? lookupProperty(depth0,"getPageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getPageTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":47}}}) : helper)))
    + "</h1>\n</div>\n\n<!--navigation and alert section-->\n<section class=\"section section-container\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"roleName") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "\n    <!--navigation tabs-->\n    <div class=\"section-body\">\n        <ul id=\"user-maint-layout-tablist\" class=\"NavTabs nav nav-tabs\" role=\"tablist\"\n            data-hook=\"getNavTabs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isUser") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":12},"end":{"line":60,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPaymentsTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":69,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReportingTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":78,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRiskTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":12},"end":{"line":87,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":12},"end":{"line":96,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAlertsTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":12},"end":{"line":105,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isABSTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":12},"end":{"line":114,"column":20}}})) != null ? stack1 : "")
    + "        </ul>\n\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n\n        <!--main section for tab content-->\n        <div class=\"formRegion\" region=\"formRegion\"></div>\n\n        <!--bottom button toolbar-->\n        <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"insert") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":126,"column":12},"end":{"line":136,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"unless","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":12},"end":{"line":139,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeepLinked") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":12},"end":{"line":144,"column":31}}})) != null ? stack1 : "")
    + "         </div>\n    </div>\n</section>\n";
},"useData":true});