import FlexDropdown from '@glu/flex-dropdown';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import scroll from 'common/util/scroll';
import BaseInsert from 'app/scheduledExports/views/baseInsert';
import util from '@glu/core/src/util';
import filterApi from 'common/dynamicPages/api/filters';
import PageApi from 'common/dynamicPages/api/view';
import gridApi from 'common/dynamicPages/api/grid';
import systemConfig from 'system/configuration';
import constants from 'app/balanceAndTransaction/constants';
import template from './balanceAndTransaction.hbs';
import CurrentPriorDay from './currentPriorDay';
import scheduleUtil from '../util';
import { filterModelIsValid } from '../../../common/util/advancedFilterUtil';

const outputContent = [
    constants.EXPORT_TYPE.CSV,
    constants.EXPORT_TYPE.CSVEXP,
    constants.EXPORT_TYPE.TSV,
    constants.EXPORT_TYPE.TSVEXP,
    constants.EXPORT_TYPE.BAIEXP,
    constants.EXPORT_TYPE.CAMTEXP,
];

export default BaseInsert.extend({
    template,
    loadingTemplate,

    ui: {
        $userGroupsDropdown: '[data-hook="getUserGroupsDropdown"]',
        $userIdsDropdown: '[data-hook="getUserIdsDropdown"]',
        $outputContent: '[data-hook="getOutputContent"]',
        $excludeHeader: '[name="EXCLUDEHEADER"]',
        $excludeHeaderContent: '[data-hook="getExcludeHeader"]',
        $continuationAccountSummaryRecord: '[data-hook="getContinuationAccountSummaryRecord"]',
    },

    initialize() {
        BaseInsert.prototype.initialize.call(this);
        this.listenTo(this.appBus, 'typeahead:change', this.clearDependentFilters.bind(this));
        this.listenTo(this.model, 'change:EXPORTTYPECODE', this.onExportTypeCodeChange);
    },

    /**
     * Clear the filterModel and then fetch new filters
     */
    onExportTypeCodeChange() {
        const filterModel = this.advancedFilterRegion?.currentView?.model;
        if (filterModel) {
            filterModel.clear();
        }
        this.createFilters();
    },

    getCreateData() {
        return Promise.all([
            this.getFileFormats(),
            this.getEntitledEmailRecipients(),
        ]);
    },

    getFileFormats() {
        return scheduleUtil.getFileFormats(
            this.model.getIdFromDropdownValue('userGroup'),
            this.model.getIdFromDropdownValue('userId'),
        ).then((exportTypeCodesResponse) => {
            this.getExportFileFormats(exportTypeCodesResponse.inquiryResponse);
        });
    },

    /**
     * Extract the export file formats in a structure that can be used for
     * a flex dropdown
     * @param {Object} inquiryResponse - inquiry response from the server
     */
    getExportFileFormats(inquiryResponse) {
        this.exportFileFormats = inquiryResponse.rows
            .map(row => this.getFlexDropdownData(row.columns));
    },

    /**
     * Convert response data to a format usable by flexDropdown
     * @param {Array} array
     */
    getFlexDropdownData(array) {
        const data = transform.pairsToHash(array, 'fieldName', 'fieldValue');
        return {
            id: data.TYPECODE,
            name: data.DESCRIPTION,
        };
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            return;
        }

        const exportType = this.model.get('EXPORTTYPECODE');
        this.$el.find('[data-hook="getTooltip"]').popover();
        this.createFilters().then(() => {
            // preselect filters in case of modify
            if (this.isModify()) {
                this.setPreselectedFilters(this.advancedFilterView, this.advancedFilterRegion);
            }
        });
        this.multiFieldView = this.createRunTimeFields(this.model);
        this.multifieldRegion.show(this.multiFieldView);
        this.renderOutputContentDropdown();
        this.toggleOutputContent(exportType);
        this.toggleExcludeHeader(exportType);
        this.toggleContinuationAccountSummaryRecord(this.model.get('OUTPUTCONTENT'), exportType);

        this.currentPriorDay.show(new CurrentPriorDay({ model: this.model }));
        this.renderFilterType();

        if (scheduleUtil.shouldShowEmailRecipients()) {
            this.showEmailRecipients();
        }
    },

    /**
     * Get entitled email recipients for balance and transaction reports
     * @param {string} searchValue
     */
    getEntitledEmailRecipients(searchValue) {
        const data = {
            searchValue,
            ...this.getEmailRecipientAdminData(),
        };
        return scheduleUtil.getEmailRecipients(
            'export/scheduledExport/getEntitledEmailsBTR',
            data,
        );
    },

    /**
     * Render a flexDropdown in the exportType region
     */
    renderFilterType() {
        this.flexDropdown = new FlexDropdown({
            data: this.exportFileFormats,
            preSelectedIds: this.model.get('EXPORTTYPECODE') ? [this.model.get('EXPORTTYPECODE')] : [],
            defaultSelectMessage: locale.get('common.select'),
            showTooltip: true,
            label: locale.get('GIR.File.Format'),
            disableMultiButton: true,
        });
        this.listenTo(this.flexDropdown, 'selectionChanged', this.handleExportTypeChange);
        this.exportTypeRegion.show(this.flexDropdown);
    },

    /**
     * Set the attributes of the model and then validate the model
     * @returns {Promise} A resolved promised with JSON object in a format the server expects
     */
    prepareModelData() {
        this.setModelForSave();
        /*
         * Validate the filter model here because always
         * need to validate even when checkDateRange is false
         */
        const validFilters = filterModelIsValid(this.advancedFilterRegion);
        if (!this.model.isValid() || !validFilters) {
            scroll.scrollToFirstError();
            return Promise.reject();
        }

        return Promise.resolve(this.model.getSaveData());
    },

    /**
     * Set model attributes if we are in MODIFY mode
     * @return {Promise} Return promise from server for chaining
     */
    getModifyData() {
        // if model is set in store then set model to values in store
        return this.model.getModel()
            .then(this.getCreateData.bind(this));
    },

    /**
     * Get filterId based on export type
     * @param {string} exportType
     * @returns {number}
     */
    getFilterId(exportType) {
        return constants.SCHEDULED_EXPORT_TYPE_FILTER_ID[exportType] || 40122;
    },

    /**
     * Set the model attribute with the selected value
     * @param {Object} selected
     */
    handleExportTypeChange(selected) {
        const [type] = selected;
        this.model.set('EXPORTTYPECODE', type.id);
        this.toggleOutputContent(type.id);
        this.toggleExcludeHeader(type.id);
        this.toggleContinuationAccountSummaryRecord(this.model.get('OUTPUTCONTENT'), type.id);
    },
    /**
     * Toggle the output content area when the export file type permits
     * the section to be visible
     * @param {string} id - export file type
     */
    toggleOutputContent(id) {
        this.ui.$outputContent.toggleClass('hide', !outputContent.includes(id));
        // Only when BAI export
        if (this.outputDropdown && id === constants.EXPORT_TYPE.BAIEXP) {
            if (!this.isModify()) {
                // Set Balances and Transactions as a default
                this.outputDropdown.setSelectedIds('GIRBALANDTRANS');
            } else {
                this.outputDropdown.setSelectedIds(this.model.get('OUTPUTCONTENT'));
            }
        }
    },
    /**
     * Hide the header based on the type
     * @param {string} exportType
     */
    toggleExcludeHeader(exportType) {
        const visibleTypes = [
            constants.EXPORT_TYPE.CSV,
            constants.EXPORT_TYPE.CSVEXP,
            constants.EXPORT_TYPE.TSV,
            constants.EXPORT_TYPE.TSVEXP,
        ];
        this.ui.$excludeHeaderContent.toggleClass('hide', !visibleTypes.includes(exportType));
    },

    /**
     * @param {boolean} noTransactions - flag to not include transaction options
     * based on user permissions on current page
     * @return {array}
     */
    getOutputOptions(noTransactions) {
        const transOptions = [{
            id: 'GIRTRANS',
            name: locale.get('GIR.ExportType.TransOnly'),
        }, {
            id: 'GIRBALANDTRANS',
            name: locale.get('GIR.ExportType.BalAndTran'),
        }];

        const balOptions = [{
            id: 'GIRBAL',
            name: locale.get('GIR.ExportType.BalOnly'),
        }];

        return (noTransactions) ? balOptions : balOptions.concat(transOptions);
    },

    /**
     * @method renderOutputContentDropdown
     * Renders OUTPUTCONTENT dropdown
     * field is specific to CVS exporting
     */
    renderOutputContentDropdown() {
        this.outputDropdown = new FlexDropdown({
            data: this.getOutputOptions(this.options.noTransactions),
            disableMultiButton: true,
            preSelectFirstItem: true,
        });

        // show
        this.outputContentOptionsRegion.show(this.outputDropdown);

        // event listener to bind to model
        this.listenTo(this.outputDropdown, 'selectionChanged:id', (selected) => {
            this.model.set('OUTPUTCONTENT', selected[0]);
            this.toggleContinuationAccountSummaryRecord(selected[0], this.model.get('EXPORTTYPECODE'));
        });

        if (!this.isModify()) {
            // preset model with preselected value
            this.model.set('OUTPUTCONTENT', this.outputDropdown.value('id')[0]);
        } else {
            this.outputDropdown.setSelectedIds(this.model.get('OUTPUTCONTENT'));
        }
    },

    /**
     * Toggle continuationAccountSummaryRecord based on output and exportTypecode
     * @param {string} output
     * @param {string} exportTypeCode
     */
    toggleContinuationAccountSummaryRecord(output, exportTypeCode) {
        const toggleVal = scheduleUtil.showContinuationAccountSummaryRecord(
            output,
            exportTypeCode,
        );
        this.ui.$continuationAccountSummaryRecord.toggleClass('hide', !toggleVal);
    },

    /**
     * Get filter data with the filterApi
     * @returns {Promise}
     */
    getFilterData() {
        return filterApi.getFilterData(
            {
                filterId: this.getFilterId(this.model.get('EXPORTTYPECODE')),
                typeInfo: {
                    functionCode: 'MAINT',
                    productCode: 'GIR',
                    typeCode: systemConfig.isAdmin() ? 'SCHEXP' : this.model.get('EXPORTTYPECODE'),
                },
            },
            'model-/advanceFilter-GIR-MAINT',
        );
    },

    /**
     * Retrieve and instantiate MDF filters
     * @returns {Promise}
     */
    createFilters() {
        return this.getFilterData().then((response) => {
            const requestContextDef = {
                serviceName: '/advanceFilter',
                productCode: response.typeInfo.productCode,
                functionCode: response.typeInfo.functionCode,
                typeCode: response.typeInfo.typeCode,
            };

            this.advancedFilterView = PageApi.page.get({
                context: requestContextDef,
                state: this.options.mode || 'insert',
                hideButtons: true,
                gridApi,
            });
            this.listenTo(this.advancedFilterView, 'ui-loaded', () => {
                this.advancedFilterView.model.set({
                    USERGROUP: this.model.getIdFromDropdownValue('userGroup'),
                    USERID: this.model.getIdFromDropdownValue('userId'),
                });
            });

            /*
             * show filter region
             * TODO update the model of this view, with the mapped data
             * from the read request ('ACCOUNT_GROUP')
             */
            this.advancedFilterRegion.show(this.advancedFilterView);

            /*
             * FIXME HACK NH-73442 Need to manually map account group IDs to account
             * names, currently MDF does not support this
             */
            this.listenTo(this.advancedFilterView, 'item:rendered', () => {
                this.setAccountGroupData.bind(this);
            });
        });
    },

    /**
     * Directly set the data attribute of the select2 DOM element
     * @param {Array} accountGroupData - array of account group data
     */
    setAccountGroupData(accountGroupData) {
        const $accountGroupInput = this.advancedFilterView.$el.find('[name="ACCOUNTGROUP"]');
        if ($accountGroupInput && accountGroupData !== null) {
            $accountGroupInput.select2('data', accountGroupData);
        }
    },

    /**
     * Reset dependent fields when parent field changes
     * @param  {string} element Name of the model attribute that changed
     */
    clearDependentFilters(element) {
        if (element !== 'USERGROUP') {
            return;
        }
        this.advancedFilterRegion.currentView.model.set({
            ACCOUNTFILTER: '',
            USERID: '',
            ACCOUNTFILTER2: '',
            ACCOUNTGROUP: '',
        });
    },

    /**
     * Preselect MDF filters values when mode is update
     * @param  {object} pageView object containing current MDF filter view
     * @param  {object} region  region we want to work with
     */
    setPreselectedFilters(pageView, region) {
        this.listenTo(pageView, 'loaded', function () {
            region.currentView.model.set(this.model.toJSON());
        });
    },

    /**
     * the gatherFilterData function returns objects even when they have no value
     * so I need to run a check and return an array minus items that have no value
     * @return {array} Array of searchField items that have a value
     */
    validateFilterData(filterData) {
        /*
         * reduce array down to items with values
         * check value of searchField.fieldValue and if it is not empty white space
         */
        return util.filter(filterData, searchField => searchField.fieldValue[0] !== undefined && (/\S/).test(searchField.fieldValue[0]));
    },

    /**
     * Set attributes on model for validation and save
     */
    setModelForSave() {
        // get and set MDF data on our modelV
        let attrs = {
            SEARCHFIELDS: this.validateFilterData(filterApi
                .gatherFilterData(this.advancedFilterRegion.currentView.model)),
            TIMEFREQUENCIES: this.getRunTimes(this.multiFieldView),
            EXCLUDEHEADER: this.ui.$excludeHeader.prop('checked'),
        };

        let exportType = this.model.get('EXPORTTYPECODE');

        if (exportType?.length === 3) {
            this.model.set('EXPORTTYPECODE', exportType += 'EXP');
        }

        // update attributes if CSV or TSV isn't selected
        if (this.model.get('EXPORTTYPECODE') === 'BAI') {
            attrs = util.extend(
                {},
                attrs,
                {
                    OUTPUTCONTENT: '',
                    EXCLUDEHEADER: '',
                },
            );
        }

        this.model.set(attrs);
    },
});
