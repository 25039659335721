import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import exportTieredLoanAccountDetailTmpl from './exportTieredLoanAccountDetail.hbs';

const ExportTieredLoanAccountDetail = ItemView.extend({
    template: exportTieredLoanAccountDetailTmpl,
    modalClass: 'modal-lg',

    initialize(options) {
        this.model = new Model();
        this.fileFormat = options.fileFormat;
        this.model.set('expdata', 'summary');
        this.model.set('fileFormat', this.fileFormat);
        this.dialogTitle = this.fileFormat === 'PDF' ? locale.get('common.printOptions.print') : locale.get('payment.Export');
        this.dialogButtons = [{
            text: this.dialogTitle,
            className: 'btn btn-primary',
            callback: 'export',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    templateHelpers() {
        return {
            getString(type) {
                return locale.get(type);
            },
            getTitle: this.fileFormat === 'PDF' ? locale.get('common.printOptions.title') : locale.get('payment.Export'),
        };
    },

    export() {
        dialog.close();
        this.trigger('exportTieredLoans', this.model);
    },

    cancel() {
        dialog.close();
    },
});

export default ExportTieredLoanAccountDetail;
