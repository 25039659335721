import AdvancedSearchView from 'no-override!@glu/grid/src/filter/advancedSearch';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';

export default AdvancedSearchView.extend({
    initialize(...args) {
        AdvancedSearchView.prototype.initialize.apply(this, args);
        this.alertView = null;
    },

    showAlertView() {
        this.closeAlertView();
        this.alertView = alert.danger('Filter already selected');
        this.$el.prepend(this.alertView.render().$el);
    },

    closeAlertView() {
        if (this.alertView) {
            this.alertView.close();
        }
    },

    hasDuplicateFilters(filters) {
        const count = util.chain(filters).countBy(obj => obj.field).values().max()
            .value();

        return count > 1;
    },

    handleSubmit(e) {
        const self = this;

        e.preventDefault();

        /*
         * daterangepicker uses a the datePicked event twice for ranges so
         * it uses an internal 10ms delay to be sure both possible events
         * have fired.
         *
         * We will forcibly click the 'Done' button first, then, knowing
         * there is a 10ms delay before it parses its own data, we use a
         * 20ms delay.
         */

        // Any Done button inside, just in case there are multiple
        this.$('.date-picker-container .btnDone').trigger('click');

        util.delay(() => {
            // Run the functions after the delay.
            const filters = self.getFilters();

            if (self.hasDuplicateFilters(filters)) {
                self.showAlertView();
                return;
            }

            self.collection.reset(filters);
            self.trigger('submit', self, e);
            dialog.close();
        }, 20);
    },

    onClose(...args) {
        if (AdvancedSearchView.prototype.onClose) {
            AdvancedSearchView.prototype.onClose.apply(this, args);
        }
        this.closeAlertView();
    },
});
