import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import loadingTemplate from 'common/templates/loadingGrid.hbs';
import BalanceListView from 'app/balanceAndTransaction/views/balanceListView';
import datePicker from 'app/smb/views/datePicker';
import userInfo from 'etc/userInfo';
import Model from '@glu/core/src/model';
import Formatter from 'system/utilities/format';
import moment from 'moment';
import ContextApi from 'common/dynamicPages/api/context';
import constants from 'app/balanceAndTransaction/constants';
import Services from 'services';
import ViewTOALocationsDropdown from 'app/balanceAndTransaction/views/viewTOALocationsDropdown';
import RefreshBalancesButton from 'app/balanceAndTransaction/views/refreshBalancesButton';
import CompositePoller from 'app/balanceAndTransaction/models/compositePoller';
import ScreenManager from '@glu/screen-manager';
import AccountSections from 'app/balanceAndTransaction/collections/accountSections';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import mobileUtil from 'mobile/util/mobileUtil';
import BTRGeneralUtil from 'app/balanceAndTransaction/btrUtil';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import tmpl from './accountSummary.hbs';
import balancesListViewTmpl from './balanceListView2.hbs';

export default Layout.extend({
    template: tmpl,
    loadingTemplate,
    className: 'balance-and-transaction-reporting-account-summary',
    totalsWarning: 'balanceTrans.account.totals.notice',

    id: 'showAllAccountsTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showAllAccountsLink',
    },

    initialize(options) {
        this.model = options.model || new Model();
        this.poller = new CompositePoller();
        this.refreshBalancesButton = new RefreshBalancesButton({
            model: this.poller,
        });

        this.listenTo(this.appBus, 'grid:paginate', this.onGridPaginate);
        /*
         * initialize widget listener (needed becuase this view is dynamic and not
         * instantiated through injectorwidget)
         */
        if (options.widget) {
            this.setWidget(options.widget);
        }

        this.tabId = options.tabId;

        // get user btr entitlements stored by parent btr container
        this.entitlements = store.get('btr:entitlements');
        // get user prev selection of TOA location, if any
        this.initialTOALocation = store.get('btr:toaLocation');
        // Context passed to override the default.
        if (options.context) {
            this.context = options.context;
        }

        this.depositDetailPageUrl = options.depositDetailPageUrl;
        this.creditCardDetailPageUrl = options.creditCardDetailPageUrl;
        this.loanDetailPageUrl = options.loanDetailPageUrl;
        this.tieredLoanDetailPageUrl = options.tieredLoanDetailPageUrl;

        this.showTotalsForAllAccounts = this.getShowTotalsForAllAccountsConfigParam();
        this.prevDayTodayBalancesEnabled = applicationConfigParams.getValue('LNRPT', 'DISPLAYBALANCESPREVDAY') === '1';
    },

    ui: {
        $startDateContainer: '.start-date-container',
        $endDateContainer: '.end-date-container',
        $rangeContainers: '.range-container',
        $rangeSelector: 'select[name="statementDate"]',
        $accountTabs: '#accountTabs',
        $panel: '.panel.panel-primary',
    },

    events: {
        'change @ui.$rangeSelector': 'toggleDateRanges',
        'click [data-hook="refresh-button"]': 'updateRefreshTimestamp',
        'click @ui.$panel': 'handleMobilePanelClick',
    },

    // TODO remove and use data-region in hbs.
    regions: {
        alerts: '.alerts',
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (!mobileUtil.isMobileGridEnabled()) {
                this.renderDatePickerContent();
                this.poller.reset();
                this.loadGrids();
                this.addRefreshButton();

                if (ScreenManager.isSmall() || ScreenManager.isExtraSmall()) {
                    this.hideAllPanels();
                }
            } else {
                this.loadGrids();
            }
            this.trigger('afterRender');
        } else {
            this.loadRequiredData();
        }
    },

    /*
     * @method: loadRequiredData
     * Method to load required data before rendering the view.
     */
    loadRequiredData() {
        this.getAccountSections();
    },

    /*
     *  @method: setWidget
     *  @param: {View} widget
     *  Method to instantiate listeners to connect grid to widget changes
     */
    setWidget(widget) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    /*
     *  @method: handleWidgetSizeChange
     *  Listener to resize the grid when the widget is resized
     */
    handleWidgetSizeChange() {
        // targeted views
        const targetedViews = [
            this.accountsGridRegionDEPOSITACCTS
                ? this.accountsGridRegionDEPOSITACCTS.currentView : false,
            this.accountsGridRegionLOANACCTS
                ? this.accountsGridRegionLOANACCTS.currentView : false,
        ];
        util.each(targetedViews, (view) => {
            if (view) {
                util.defer(() => {
                    view.gridView.grid.tableView.setWidth();
                });
            }
        });
    },

    /*
     *  @method: getShowTotalsForAllAccountsConfigParam
     *  Retrieves the configparam that determines if totals are shown for all
     * accounts in listview.
     *  If true, the totals aer tallied for all pages.  False displays totals for
     * that page.
     */
    getShowTotalsForAllAccountsConfigParam() {
        return serverConfigParams.get('BTRShowTotalsForAllAccounts') === 'true';
    },

    /*
     *  @method: getAccountSections
     *  Method to get the available and entitled sections to display for
     *  Balance and Reporting tab.
     */
    getAccountSections() {
        this.accountSectionsCollection = new AccountSections({
            tabId: this.tabId,
        });

        this.accountSectionsCollection.fetch({
            success: util.bind(this.onSuccess, this),
            error: util.bind(this.onError, this),
        });
    },

    updateRefreshTimestamp(inView) {
        // update refresh timestamp
        let timestamp = '';
        const $refreshEl = this.$('[data-hook="refresh-time"]');
        const depositAccountRegion = this[`accountsGridRegion${constants.ACCOUNT_SUMMARY.SECTIONS.DEPOSITS}`] || null;
        const depositAcctView = depositAccountRegion ? depositAccountRegion.currentView : null;

        if (depositAcctView && depositAcctView.gridView && depositAcctView.gridView.getRows()) {
            timestamp = depositAcctView.gridView.getRows().lastRefreshTimestamp;
        } else if (inView && inView.getRows && inView.getRows()) {
            timestamp = inView.getRows().lastRefreshTimestamp;
        } else if (mobileUtil.isMobileGridEnabled()) {
            /*
             * Mobile grid does not use the gridView and so does not have lastRefreshTimestamp.
             * Instead, use the current time as the timestamp
             */
            timestamp = moment(new Date()).tz(userInfo.get('timezone'));
        }
        if (!util.isNullOrUndefined(timestamp) && $refreshEl && $refreshEl.length > 0) {
            $refreshEl.text(`${Formatter.formatDate(timestamp)} ${Formatter.formatTime(timestamp)}`);
        }
    },

    onSuccess() {
        if (!this.hasLoadedRequiredData()) {
            this.setHasLoadedRequiredData(true);
            BTRGeneralUtil.saveTieredLoansTotalsColumns(
                this.accountSectionsCollection,
                constants.ACCOUNT_SUMMARY.SECTIONS.LOANS,
            );
            this.render();
        }
        this.updateRefreshTimestamp();
    },

    onError() {
        // TODO handle errors
    },

    toggleDateRanges(e) {
        const selectedVal = $(e.target).find('option:selected').val();
        if (selectedVal === 'range') {
            this.ui.$rangeContainers.show();
        } else {
            this.ui.$rangeContainers.hide();
        }
    },

    /**
     * Render the date pickers.
     */
    renderDatePickerContent() {
        const self = this;

        // allow user go go 7 years in the past to set the check issue date.
        const minDate = moment({
            hour: 0,
        }).subtract(2, 'years');

        this.ui.$startDateContainer.html(datePicker.create({
            nameAttr: 'startDate',
            dateFormat: userInfo.getDateFormat(),
            minDate,

            customSettings: {
                constrainDates: false,
                closeOnSelect: true,

                onChange() {
                    if (this.target.val()) {
                        self.model.set('startDate', this.target.val());
                    }
                },
            },
        }));

        /*
         * set the field since it's not prefilling
         * can't seem to set default date as an option.
         */
        if (self.model.get('startDate') !== '') {
            datePicker.selectDate(Formatter.formatDate(self.model.get('startDate')));
        }

        this.ui.$endDateContainer.html(datePicker.create({
            nameAttr: 'endDate',
            dateFormat: userInfo.getDateFormat(),
            minDate,

            customSettings: {
                constrainDates: false,
                closeOnSelect: true,

                onChange() {
                    if (this.target.val()) {
                        self.model.set('endDate', this.target.val());
                    }
                },
            },
        }));

        /*
         * set the field since it's not prefilling
         * can't seem to set default date as an option.
         */
        if (self.model.get('endDate') !== '') {
            datePicker.selectDate(Formatter.formatDate(self.model.get('endDate')));
        }
    },

    hideAllPanels() {
        // or 'show' or 'toggle'
        this.$('.panel-collapse').collapse('hide');
    },

    /*
     * @method: loadGrid
     * @param {Model} sectionModel
     * @param {array} [additionalSearchFields] - additional search fields be passed
     * to the grid request
     * @description Method to render each account group section
     */
    loadGrid(sectionModel, additionalSearchFields) {
        const section = sectionModel.get('id');
        const isDepositAccountType = sectionModel.get('sectionType') === constants.ACCOUNT_SUMMARY.SECTION_TYPES.DEPOSIT;
        const listView = new BalanceListView(this.getGridOptions(
            section,
            sectionModel,
            additionalSearchFields,
        ));

        if (!mobileUtil.isMobileGridEnabled()) {
            this[`accountsGridRegion${section}`].show(listView);
            if (isDepositAccountType) {
                this.listenTo(listView.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            }

            this.poller.add(listView.balancePoller);
            // attempt to update balances on initial load NH-39791
            this.poller.start();
        }

        listView.showTotals();

        return listView;
    },

    /*
     * @method: getGridOptions
     * @param {string} section
     * @param {array} [additionalSearchFields] - additional search fields be passed to
     * the grid request
     * @param {Model} sectionModel
     * @description Get all information required to render the listview
     */
    getGridOptions(section, sectionModel, additionalSearchFields) {
        const isCreditCardAccountType = sectionModel.get('sectionId') === constants.ACCOUNT_SUMMARY.SECTIONS.CREDIT_CARDS;
        const isDepositAccountType = sectionModel.get('sectionType') === constants.ACCOUNT_SUMMARY.SECTION_TYPES.DEPOSIT && !isCreditCardAccountType;
        const isTOALocationsAccountType = sectionModel.get('sectionId') === constants.ACCOUNT_SUMMARY.SECTIONS.TIMEOPEN;

        let servicePrefix;
        let moreOptions;

        if (isDepositAccountType || isTOALocationsAccountType) {
            servicePrefix = constants.DEPOSIT_ACCTS_SERVICE_PREFIX;
            moreOptions = this.getDepositOptions(sectionModel, additionalSearchFields);
        } else if (isCreditCardAccountType) {
            servicePrefix = constants.CREDIT_CARD_ACCTS_SERVICE_PREFIX;
            moreOptions = this.getCreditCardOptions(sectionModel);
        } else {
            servicePrefix = constants.LOAN_ACCTS_SERVICE_PREFIX;
            moreOptions = this.getLoanOptions(sectionModel);
        }
        return util.extend({
            showTotalsForAllAccounts: this.showTotalsForAllAccounts,
            template: balancesListViewTmpl,
            accountType: isCreditCardAccountType ? 'CREDIT_CARD' : sectionModel.get('sectionType'),
            title: sectionModel.get('description'),
            totalsSection: this[`totalsSection${section}`],
            currencyBadgesRegion: this[`${section}currencyBadgesRegion`],
            amountColumn: sectionModel.get('totalsColumn1'),
            availableColumn: sectionModel.get('totalsColumn2'),
            field1Label: sectionModel.get('totalsColumn1Label'),
            field2Label: sectionModel.get('totalsColumn2Label'),
            totalsService: `${servicePrefix + (isDepositAccountType || isCreditCardAccountType ? `${this.tabId}/` : '')}accountSummary/getListViewTotals`,
            requestRealtimeBalancesUrl: Services.generateUrl(`${servicePrefix}accountSummary/requestRealTimeBalances`),
            sectionId: section,
            tabId: this.tabId,
            entitlements: this.entitlements,
        }, moreOptions);
    },

    /*
     * @method: getDepositOptions
     * @param {Model} sectionModel - section model
     * @param {array} [additionalSearchFields] - additional search fields be
     * passed to the grid request
     * @description Get the information required to render
     * the listview specific to deposit accounts
     */
    getDepositOptions(sectionModel, additionalSearchFields) {
        const context = util.clone(this.context)
                || ContextApi.menuContext.getContext(constants.DEPOSIT_ACCTS_GRID_CONTEXT);
        util.extend(
            context,
            {
                inquiryId: sectionModel.get('inquiryId'),
                serviceName: sectionModel.get('route'),
                overrideFunctionCode: sectionModel.get('tabId'),
                overrideTypeCode: sectionModel.get('sectionId'),
            },
        );

        return {
            context,
            detailPageUrl: this.depositDetailPageUrl || 'BANK_ACC_INFO/depositAccountsTransactions',
            additionalSearchFields,
        };
    },

    /*
     * @method: getCreditCardOptions
     * {glu/model} sectionModel
     * Get the information required to render the listview specific to credit card accounts
     */
    getCreditCardOptions(sectionModel) {
        const context = util.clone(this.context)
            || ContextApi.menuContext.getContext(constants.DEPOSIT_ACCTS_GRID_CONTEXT);
        const sectionId = sectionModel.get('sectionId');
        const tabId = sectionModel.get('tabId');
        util.extend(
            context,
            {
                inquiryId: sectionModel.get('inquiryId'),
                serviceName: sectionModel.get('route'),
                overrideFunctionCode: sectionModel.get('tabId'),
                overrideTypeCode: sectionModel.get('sectionId'),
                overrideTypeCodePrevDay: this.getOverrideTypeCodePrevDayCC(sectionId, tabId),
            },
        );

        return {
            context,
            detailPageUrl: this.creditCardDetailPageUrl || 'BANK_ACC_INFO/creditCardAccountsTransactions',
        };
    },

    /*
     * @method: getLoanOptions
     * {glu/model} sectionModel
     * Get the information required to render the listview specific to loan accounts
     */
    getLoanOptions(sectionModel) {
        const tabId = sectionModel.get('tabId');
        return {
            detailPageUrl: this.loanDetailPageUrl || 'BANK_ACC_INFO/loanAccountsTransactions',
            tieredLoanDetailPageUrl: this.tieredLoanDetailPageUrl || 'BANK_ACC_INFO/tieredLoanAccountDetail',
            context: {
                actionMode: constants.SELECT,
                functionCode: constants.INST,
                inquiryId: sectionModel.get('inquiryId'),
                productCode: constants.GIR,
                serviceName: sectionModel.get('route'),
                typeCode: 'LOANACCT',
                overrideFunctionCode: sectionModel.get('tabId'),
                overrideTypeCode: sectionModel.get('sectionId'),
                overrideTypeCodePrevDay: this.getOverrideTypeCodePrevDayLoan(tabId),
            },
        };
    },

    /**
     * - attempt to update balances on pagination NH-39791
     * @method onGridPaginate
     */
    onGridPaginate() {
        this.poller.start();
    },

    addRefreshButton() {
        this.refreshBalancesButton.setElement(this.$('.refresh-balances-button')).render();
    },

    /**
     * Get the account totals for each section and then update the timestamp
     */
    refreshBalances() {
        this.accountSectionsCollection.each(section => this.loadGrid(section));
        this.updateRefreshTimestamp();
    },

    /*
     * @method: loadGrids
     * Iterate over each account group and load the grid.
     */
    loadGrids() {
        this.accountSectionsCollection.forEach((sectionModel) => {
            this.renderAccountSections(sectionModel, (additionalSearchFields) => {
                this.loadGrid(sectionModel, additionalSearchFields);
            });
        });
    },

    renderAccountSections(sectionModel, cb) {
        const section = sectionModel.get('id');
        const gridDropdownRegion = this[`${section}_gridDropdownRegion`];
        // Display TOA locations dropdown for TOA section
        if (gridDropdownRegion
                && section === constants.ACCOUNT_SUMMARY.SECTIONS.TIMEOPEN) {
            this.renderViewTOALocationsDropdown(gridDropdownRegion, cb);
        } else {
            cb();
        }
    },

    /**
     * @method renderViewTOALocationsDropdown
     * @param {Region} gridDropdownRegion
     * @param {function} cb - callback
     */
    renderViewTOALocationsDropdown(gridDropdownRegion, cb) {
        this.viewTOALocationsDropdown = new ViewTOALocationsDropdown({
            initialTOALocation: this.initialTOALocation,
            locationSelectCallback: (location) => {
                store.set('btr:toaLocation', location);
                this.expandTOALocationsSection();
                cb([{
                    fieldName: 'LOCATIONNUMBER',
                    dataType: 'text',
                    operator: 'EQ',
                    fieldValue: [location.LOCATIONNUMBER],
                }]);
            },
        });
        if (gridDropdownRegion) {
            gridDropdownRegion.show(this.viewTOALocationsDropdown);
        }
    },

    /**
     * @method expandTOALocationsSection
     * Will expand TOA section when location is selected
     */
    expandTOALocationsSection() {
        const TOASection = this.$el.find(`.panel-${constants.ACCOUNT_SUMMARY.SECTIONS.TIMEOPEN}`);
        TOASection.siblings().collapse('show');
    },

    /**
     * @method: getOverrideTypeCodePrevDayLoan
     * @param {string} tabId
     * Return type code string for prev day loan accounts
     * @returns {string|null}
     */
    getOverrideTypeCodePrevDayLoan(tabId) {
        if (tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR
            && !this.prevDayTodayBalancesEnabled
        ) {
            return constants.LOANACCTSPD;
        }
        return null;
    },

    /**
     * @method: getOverrideTypeCodePrevDayCC
     * @param {string} sectionId
     * @param {string} tabId
     * Return type code string for prev day loan accounts
     * @returns {string|null}
     */
    getOverrideTypeCodePrevDayCC(sectionId, tabId) {
        if (sectionId === constants.ACCOUNT_SUMMARY.SECTIONS.CREDIT_CARDS
            && tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR
            && !this.prevDayTodayBalancesEnabled
        ) {
            return constants.CREDITCARDSPD;
        }
        return null;
    },

    /**
     * @method getSections
     * @returns {array} - array of sections to be rendered
     */
    getSections() {
        const sections = this.accountSectionsCollection.toJSON();
        return sections.map((section) => {
            const updatedSection = section;
            updatedSection.displayPanelDescription = true;
            /**
             * In Prev & Current tabs, TOA locations section
             * should include TOA locations dropdown
             */
            const isTimeOpenSection = section.id === constants.ACCOUNT_SUMMARY.SECTIONS.TIMEOPEN;
            const isPrevDayTab = this.tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR;
            const isCurrentDayTab = this.tabId === constants.ACCOUNT_SUMMARY.TABS.CURRENT;

            if (isTimeOpenSection && isPrevDayTab) {
                updatedSection.displayDropdown = true;
                updatedSection.displayPanelDescription = false;
            } else if (isTimeOpenSection && isCurrentDayTab) {
                updatedSection.displayDropdown = true;
            }
            return updatedSection;
        });
    },

    templateHelpers() {
        return {
            getTotalsWarning: () => (!this.showTotalsForAllAccounts
                        && this.totalsWarning ? this.totalsWarning : null),

            totalsWarningMsg: this.totalsWarning,

            sections: () => this.getSections(),

            gridUtilityOptions: {
                hasRefresh: false,
            },

            isNotMobile: !mobileUtil.isMobileGridEnabled(),
        };
    },

    handleMobilePanelClick(e) {
        if (mobileUtil.isMobileGridEnabled()) {
            const prefixRegion = 'accountsGridRegion';
            const sectionEl = e.currentTarget.querySelector(`div[data-region^="${prefixRegion}"]`);
            const sectionId = sectionEl.dataset.region.replace(prefixRegion, '');
            const section = this.accountSectionsCollection.findWhere({ sectionId });
            store.set('mobileView', this.loadGrid(section));
            store.set('mobileReturnRoute', `${store.get('current-workspace-route')}${document.location.search}`);
            store.set('mobileNavigate', this.navigateTo);
            this.navigateTo(`/MobileCardRoute/${section}`);
        }
    },
});
