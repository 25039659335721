import $ from 'jquery';
import { Layout, appBus } from '@glu/core';
import gluStore from '@glu/store';
import util from '@glu/core/src/util';
import SavedViewFilters from './savedViewFilters';
import filterUtil from '../../../../common/util/savedViewFilterUtil';

import template from './filterBadgesHeaderLayout.hbs';

export default Layout.extend({
    template,

    ui: {
        $removeAllFilters: '[data-hook="clearAllFilters"]',
    },

    events: {
        'click @ui.$removeAllFilters': 'removeAllFilters',
    },

    regions: {
        filterBadges: '.region-filter-badges',
        auxControl: '.region-aux-control',
    },

    initialize(options) {
        Layout.prototype.initialize.call(this, options);

        this.filters = options.filters;

        this.listenTo(this, 'filter:showSavedFilters', this.renderGroupedFilters);
        this.listenTo(this, 'filter:clearSavedFilters', this.resetViewFiltersSection);
        this.listenTo(this.filters, 'add remove refresh reset', this.onUserFiltersChange);
        this.listenTo(appBus, 'cachedSavedFilters:change', this.onUserFiltersChange);
        if (options.masterCollection
            && options.masterCollection.context
            && options.masterCollection.context.actionContext) {
            this.functionCode = options.masterCollection.context.actionContext.functionCode;
        }
    },

    onRender() {
        /*
         * While the feature is off for admin, if a user adds a filter we need to be sure on
         * refresh or going away from a view and then coming back, that those filters are viewable.
         */
        if (this.filters) {
            const processedFilters = this.getProcessedDisplayFilters();
            processedFilters.then((filters) => {
                let showFilters = gluStore.get('currentShowGridFiltersState') || false;
                const hasNoSavedViews = this.options?.grid?.options?.enableSavedViews === false;
                const isAdminWithoutSavedViews = this.isAdminWithoutSavedViews();
                if (filters.length && (hasNoSavedViews || isAdminWithoutSavedViews)) {
                    showFilters = true;
                }
                this.renderViewFiltersSection(filters, showFilters);
            });
        }
    },

    isAdminWithoutSavedViews() {
        /*
         * most of these paths are not actually defined routes and should be actual listviews
         * so that we can pass the enabledSavedViews: false option through in a more straightforward
         * way. Due to release timing (tonight) this is the least risky way to introduce these
         * in an isolated space that can be updated when possible. The main list.js sets the
         * enableSavedViews to true by default which causes issues with the impelementation of
         * the saved grid filters view epics. In addition, many of these routes do not render
         * in the local dev admin.
         */
        const paths = [
            '/SMAINT/list/systemMaintenance/BankCorrFees/CORR_FEE_MAINT',
            '/SMAINT/list/systemMaintenance/BankIntegrationCodes/INTG_CODES',
            '/SMAINT/list/systemMaintenance/BankCorrFees/CORR_FEE_MAINT',
            '/SMAINT/list/systemMaintenance/OthrFees/OTHR_FEE_MAINT',
            '/SMAINT/list/systemMaintenance/BankFees/BANK_FEE_MAINT',
            '/SMAINT/list/systemMaintenance/CountriesWireSetup/CWS_WIRE_MAINT',
            '/SMAINT/list/systemMaintenance/CnsIntlWireConfig/CNSCONFIG_MAINT',
            '/SMAINT/list/systemMaintenance/CurrencyPairs/SMAINT_CCYPR_TM',
            '/SMAINT/MFA/challengeConfig',
            '/SMAINT/intermediaryBankMapping',
            '/SMAINT/tableMaintInquirySimple',
            '/SMAINT/CXPREPO',
            '/CXP/externalWidget',
            '/CXP/elearning',
            '/SMAINT/JWTJKS',
            '/TKNMGMT/tokenManagement/tokensAudit',
            '/SMAINT/fileLoadAdminExport',
        ];
        return paths.includes(document.location.pathname.split(window.Bottomline.appRoot)[1]);
    },

    /**
     * @param {array} viewFilters
     * @param {boolean} [showFilters]
     * Callback for 'filter:showSavedFilters' when saved view filters details have been fetched
     * Will render and toggle controls based on if there is data or not
     */
    renderViewFiltersSection(viewFilters, showFilters = false) {
        this.savedFiltersView = new SavedViewFilters({
            filters: filterUtil.removeRedundantFilters(viewFilters),
            allSavedViewFilters: viewFilters.allSavedViewFilters,
        });

        this.listenTo(this.savedFiltersView, 'delete', this.clearSavedGridFilter);
        /*
         * NH-178965 It is now possible to have savedViews without showing the filters. They are
         * tightly coupled together in GLU, where filters are required for savedViews both
         * to render and save savedViews. Still create the view, just don't show it.
         */
        if (!this.options?.grid?.options?.savedViewsWithoutFilters) {
            this.savedViewFiltersRegion.show(this.savedFiltersView);
        }
        $(this.ui.$removeAllFilters.el).toggleClass('show', showFilters);
        $(this.savedViewFiltersRegion.el).toggleClass('show', showFilters);

        this.firstRender = true;
        if (showFilters) {
            this.savedViewFiltersRegion.$el.closest('.header').find('.GridFilterHeader-toggle span').addClass('icon-eye');
            // NH-158475 - When filters are shown, update the current shown state
            gluStore.set('currentShowGridFiltersState', true);
        } else if (!this.firstRender) {
            this.savedViewFiltersRegion.$el.closest('.header').find('.GridFilterHeader-toggle span').removeClass('icon-eye-line');
        } else {
            this.firstRender = false;
        }

        /*
         * TODO: Test if this is really needed anymore
         * clear current queue of non-view filters if we're pulling in a saved view
         * this.filters.reset();
         */
    },

    clearSavedGridFilter(field) {
        const filterModel = this.options.filters.findWhere({
            field,
        });

        if (!filterModel) {
            return;
        }
        filterModel.destroy();
    },

    /**
     * @method resetViewFiltersSection
     * Callback for 'filter:clearSavedFilters' event
     * For cases where user clears his saved view selection
     */
    resetViewFiltersSection() {
        this.renderViewFiltersSection([]);
        /*
         * TODO: function moved to savedViewList.js
         * Do we need to call it here now?
         * this.toggleSavedViewFilters(false);
         */
    },

    removeAllFilters(e) {
        e.preventDefault();
        // Get any applied static filters, if any
        const staticFilters = this.filters.models.filter(m => m.get('staticFilter'));
        // Clear all applied filters
        this.options.filterProc.clear();
        // re-apply the static filters, if any
        this.options.filterProc.filters.add(staticFilters);
    },

    getProcessedDisplayFilters() {
        /**
         * NH-176167: `hideSavedViewBadge` is set in cashPositionDepositTransactionListView.js
         */
        const filterModels = this.filters.models.filter(model => !model.get('hideSavedViewBadge'));
        return filterUtil.processFilterDisplayFromParams(
            filterUtil.createFilterParams(filterModels),
            this.combineColumnData(
                this.options.columns.models,
                filterModels,
            ),
            this.functionCode,
        );
    },
    /**
     * Combine any data from the filters models into
     * the column models
     * @param {Array} columnModels
     * @param {Array} filterModels
     */
    combineColumnData(columnModels, filterModels) {
        return columnModels.map((cModel) => {
            const usableFieldName = cModel.field || cModel.get('field');
            const filterModel = filterModels
                .find(fModel => (usableFieldName === fModel.get('fieldName') || usableFieldName === fModel.get('field')));
            /*
             * NH-154678 when switching tabs pass on enumHash so
             * that the columns have the proper display values
             */
            if (filterModel && filterModel.get('type') === 'enum') {
                cModel.set('enumHash', filterModel.get('enumHash'));
            }
            return cModel;
        });
    },

    getProcessedSavedViewFilters() {
        // if we're not in a saved view
        if (this.appliedSavedViewFilters) {
            return filterUtil.processFilterDisplayFromParams(
                this.appliedSavedViewFilters.get('filters'),
                this.options.columns.models,
                this.functionCode,
            );
        }
        return [];
    },

    renderGroupedFilters(passedSavedViewFilters, _, onFilterBadgeHeaderViewUpdate) {
        this.onFilterBadgeHeaderViewUpdate = onFilterBadgeHeaderViewUpdate;
        const shouldShow = gluStore.get('currentShowGridFiltersState') || false;
        const promises = [
            this.getProcessedDisplayFilters(),
            passedSavedViewFilters,
        ];

        this.mergeProcessedFilters(promises)
            .then(mergedFilters => this.renderViewFiltersSection(mergedFilters, shouldShow));
    },
    /**
    * HACK NH-184621 The savedView field code is not consistent with the
    * filter badge field code. Update the fieldCode when found in the map
    * to match the display fieldCode.
    * @param {Array} savedViewFilters
    * @returns {Array}
    */
    updateFiltersFieldCode(savedViewFilters) {
        const fieldCodeMap = {
            DR_CR: 'DR_CR_DISPLAY',
        };
        return savedViewFilters.map((filter) => {
            const f = filter;
            const fieldCode = fieldCodeMap[f.fieldCode];
            if (fieldCode) {
                f.fieldCode = fieldCode;
            }
            return f;
        });
    },

    mergeProcessedFilters(filterPromises) {
        return Promise.all(filterPromises)
            .then(([displayFiltersList, savedViewFilters]) => {
                // Update any savedView filter field codes to match the display field code
                const savedViewFiltersMapped = this.updateFiltersFieldCode(savedViewFilters);
                const [staticFilters, displayFilters] =
                    util.partition(displayFiltersList, filter => filter.staticFilter);
                const displayCodes = displayFilters.map(({ fieldCode }) => fieldCode);
                const scrubbedViewFilters = [...staticFilters, ...savedViewFiltersMapped]
                    .filter(({ fieldCode }) => !displayCodes.includes(fieldCode))
                    .map(obj => ({
                        ...obj,
                        savedViewFilter: true,
                    }));

                // If there are no filters passed in, double-check for LVC filters
                let additionalLvcFilters = [];
                const gridWrapper = this.options.grid.options?.wrapper;
                if (!displayFilters.length && !savedViewFiltersMapped.length && gridWrapper) {
                    const { lvcSearchFields = [] } = gridWrapper;
                    additionalLvcFilters = lvcSearchFields.map((lvcSearchField) => {
                        const { fieldName, fieldValue } = lvcSearchField;
                        const col = this.options.columns.findWhere({ fieldName });
                        return {
                            fieldCode: fieldName,
                            label: fieldValue?.length ? fieldValue.join(',') : '',
                            title: col?.get('displayName') || fieldName,
                        };
                    });
                }

                const mergedFilters = [
                    ...scrubbedViewFilters,
                    ...displayFilters,
                    ...additionalLvcFilters,
                ];

                this.appliedDisplayFilters = mergedFilters;
                /*
                 * When the user saved view comes through, the displayFilters has a value but the
                 * same exact filter value exists in savedViewFilters.
                 *
                 * Create a copied array and remove the dupes that are in savedViewFilters.
                 * If there is still a length >0 then allSavedViewFilters should be false
                 * If there is a length of 0 then allSavedViewFilters should be true
                 * This setting determines the "x" appearing on the badges and clear button showing
                 */
                const displayFiltersCopy = displayFilters.filter((filter) => {
                    let notFound = true;
                    savedViewFiltersMapped.forEach((sfilter) => {
                        if (
                            filter.title === sfilter.title
                            && filter.fieldCode === sfilter.fieldCode
                            && filter.label === sfilter.label
                        ) {
                            notFound = false;
                        }
                    });
                    return notFound;
                });
                const allSavedViewFilters = !displayFiltersCopy.length
                    && !additionalLvcFilters.length;

                mergedFilters.allSavedViewFilters = allSavedViewFilters;
                this.updateSavedViewsList(!allSavedViewFilters);
                const savedFilterSelectedLabel = this.$el.closest('.grid').find('.grid-view-controls .select2-chosen').text();
                const defaultSavedViewFilter = savedFilterSelectedLabel ? [{
                    label: savedFilterSelectedLabel,
                }] : [];
                /**
                 * TODO: this needs to be rewritten - we shouldn't be using an array as if
                 * it were an object
                 */
                defaultSavedViewFilter.allSavedViewFilters = true;
                return mergedFilters.length === 0 ? defaultSavedViewFilter : mergedFilters;
            }).catch(err => window.console.log(err));
    },

    /**
     * Set dropdown style to indicate changed to saved list view
     * @param {boolean} updated - if a filter was updated or not
     */
    updateSavedViewsList(updated) {
        if (this.onFilterBadgeHeaderViewUpdate) {
            this.onFilterBadgeHeaderViewUpdate(updated);
        }
    },

    /**
     * Helper method to add change listener to newly created filter models
     */
    listenToNewlyAddedModels() {
        if (this.filters && this.filters.models) {
            this.filters.models.forEach((model) => {
                // Remove lister from the model, if exists
                this.stopListening(model, 'change', this.onUserFiltersChange);
                // Then add it back to improve performance
                this.listenTo(model, 'change', this.onUserFiltersChange);
            });
        }
    },

    onUserFiltersChange() {
        this.listenToNewlyAddedModels();
        // check the overlap between user-applied filters and saved view filters
        this.mergeProcessedFilters([
            this.getProcessedDisplayFilters(),
            this.getProcessedSavedViewFilters(),
        ]).then(mergedFilters => this.renderViewFiltersSection(mergedFilters, true));
    },

    templateHelpers() {
        const self = this;

        return {
            hasFilters: self.filters.length,
        };
    },
});
