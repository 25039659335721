import Format from '@glu/core/src/templateHelpers';
import locale from '@glu/locale';
import moment from 'moment';
import util from 'underscore';
import MaskToggleUtil from 'common/util/maskToggleUtil';
import userInfo from '../../../../etc/userInfo';
import serverConfigParams from '../../../../system/webseries/models/configurationParameters';
import buttonLinkTmpl from './componentTemplates/buttonLink.hbs';
import gridComponentTmpl from './componentTemplates/gridComponent.hbs';
import gridTmpl from './componentTemplates/gridHolder.hbs';
import imageViewerTmpl from './componentTemplates/imageViewer.hbs';
import navLinkTmpl from './componentTemplates/navLink.hbs';
// Templates
import readOnlyTmpl from './componentTemplates/readOnly.hbs';
import readOnlyFilterTmpl from './componentTemplates/readOnlyFilter.hbs';
import readOnlyMultiSelectFilterTmpl from './componentTemplates/readOnlyMultiSelectFilter.hbs';
import textareaMultiLineTmpl from './componentTemplates/textareaMultiLine.hbs';
import uiWidgetTmpl from './componentTemplates/uiWidget.hbs';
import viewTmpl from './componentTemplates/view.hbs';
import viewMultipleEntriesTmpl from './componentTemplates/viewMultipleEntries.hbs';
import viewWithErrorTmpl from './componentTemplates/viewWithErrorIcon.hbs';
import {
    callTemplate, camelize, findOperation, getMultiComboValues,
} from './mdfUtil';
import readOnlyHelpers from './readOnlyHelpers';
import Decimals from '../../api/decimals';

const viewFieldHelpers = {
    // View Calendar Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    CALENDAR(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        if (util.isEmpty(jsonField.value)) {
            return '';
        }

        const format = userInfo.getDateFormat();
        jsonField.value = Format.date(jsonField.value, format);
        return viewTmpl(jsonField);
    },

    // View Calendar Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    CALENDAR_ENABLEALL(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    // View Checkbox Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    CHECKBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        if (util.isEmpty(view.model.get(jsonField.name))) {
            jsonField.value = jsonField.checkboxOffValue;
        } else {
            jsonField.value = (view.model.get(jsonField.name) === jsonField.checkboxOnValue)
                ? locale.get('yes') : locale.get('no');
        }

        return !util.isEmpty(jsonField.value)
        && (jsonField.value !== jsonField.checkboxOffValue) ? viewTmpl(jsonField) : '';
    },

    // View Combobox Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    COMBOBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const clist = view.comboCollections[jsonField.name];
        const cvalue = view.model.get(jsonField.name);

        /*
         * For Admin app, Administered caches needs to be implemented for inquiries,
         * so we are skipping the getQueryResults call.
         * As a result, values are not set for the combos. combo list will not have
         * any values. so checking for admin serviceNames and for clist length as 0
         * and accordingly setting the values for combos so all of the details view
         * admin screen will display all fields for combos.
         */

        if (view.model.context?.serviceName === 'adminPayment/listView/payments'
            || view.model.context?.serviceName.indexOf('adminCM') > -1 || view.model.context?.serviceName === 'adminCM/cminst/issueVoids'
            || view.model.context?.serviceName.indexOf('adminTemplate') > -1) {
            if (clist && clist.length === 0) {
                const comboCollections = util.map(view.comboCollections, name => ({
                    name,
                }));

                /*
                 * FIXME: What is this code meant to do, because
                 *  it doesn't use the increment?
                 */
                for (let i = 0; i < comboCollections.length; i += 1) {
                    jsonField.value = view.model.get(jsonField.name);
                }
            }
        }

        if (clist && !util.isEmpty(cvalue)) {
            jsonField.value = cvalue;
            if (clist.length) {
                for (let j = 0; j < clist.length; j += 1) {
                    if (clist[j].name === cvalue) {
                        jsonField.value = clist[j].label;
                        break;
                    }
                }
            }
        }
        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    // Default ComboSelect Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    COMBOSELECT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const clist = jsonField.choices;
        const cvalue = view.model.get(jsonField.name);
        if (!util.isEmpty(cvalue)) {
            for (let j = 0; j < clist.length; j += 1) {
                if (clist[j].name === cvalue) {
                    jsonField.value = clist[j].value;
                    break;
                }
            }
        }
        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    CHILDGRID(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let tmpl = '';
        if (jsonField.protected && view.state !== 'create') {
            tmpl += readOnlyHelpers.TEXTAREA(view, jsonField);
            jsonField.blockClass = 'hidden';
        }
        // use comboTmpl, need name, fieldLable and list (collection)
        return tmpl + callTemplate(gridTmpl, jsonField);
    },

    /**
     * @returns {String}
     */
    LOOKUPSINGLE() {
        return '';
    },

    /**
     * @returns {String}
     */
    LOOKUPMULTI() {
        return '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    GRID(view, jsonField) {
        return gridComponentTmpl(jsonField);
    },

    // View Radio Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    RADIO(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const rlist = jsonField.choices;
        const rvalue = view.model.get(jsonField.name);

        if (!util.isEmpty(rvalue)) {
            for (let k = 0; k < rlist.length; k += 1) {
                if (rlist[k].value === rvalue) {
                    jsonField.value = rlist[k].name;
                    break;
                }
            }
        }

        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    // View Textarea Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTAREA(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        return !util.isEmpty(jsonField.value) ? textareaMultiLineTmpl(jsonField) : '';
    },

    // View Typeaheadfree
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TYPEAHEADFREE(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));
        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TYPEAHEAD_HELPERTEXT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));
        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    TYPEAHEAD_PREFERRED(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));
        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    MULTITYPEAHEAD(view, jsonField) {
        const concatenatedItems = util.unescape(view.model.get(jsonField.name));
        const items = concatenatedItems.split(',');
        return !util.isEmpty(concatenatedItems)
            ? viewMultipleEntriesTmpl({
                fieldLabel: jsonField.fieldLabel,
                values: items,
                blockClass: jsonField.blockClass,
            })
            : '';
    },

    // Protected Amount Widget
    /*
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    PROTECTAMOUNTWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            blockClass: jsonField.blockClass,
            cssWidgetClass: 'inline-fields',
            fieldName: jsonField.name,
            name: camelize(jsonField.name),
            widgetType: 'PROTECTAMOUNTWIDGET',
        });
    },

    // View Textbox Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTBOX(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));
        let formatAmount = true;

        if (util.isEmpty(jsonField.value) && jsonField.fieldType === 'AMOUNTSUPPRESSBLANK') {
            formatAmount = false;
        }

        if ((jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT' || jsonField.fieldType === 'AMOUNTSUPPRESSBLANK') && formatAmount && jsonField.relatedProperty) {
            jsonField.relatedValue = view.model.get(jsonField.relatedProperty);
            /*
             * HACK: NH-92066
             * denoted as a hack because of inappropriate specificity
             * added to avoid showing second account allocations if it's empty.
             */
            if (!(jsonField.name === 'SECOND_ACCOUNT_ALLOCATION' && util.isEmpty(jsonField.value))) {
                const amountFormat = Decimals.getCurrencyFormat(jsonField.relatedValue);
                if (amountFormat) {
                    jsonField.value = Format.amount(jsonField.value, amountFormat);
                } else {
                    jsonField.value = Format.amount(jsonField.value);
                }
            }
        }

        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTBOX_HELPERTEXT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));

        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTBOX_ERRORICON(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = util.unescape(view.model.get(jsonField.name));

        jsonField.relatedValue = jsonField.relatedProperty
            ? view.model.get(jsonField.relatedProperty) : '';

        return !util.isEmpty(jsonField.value) ? viewWithErrorTmpl(jsonField) : '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    RCOUNT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        if ((jsonField.fieldType === 'AMOUNT' || jsonField.fieldType === 'PAMOUNT') && jsonField.relatedProperty) {
            jsonField.relatedValue = view.model.get(jsonField.relatedProperty);
        }

        jsonField.value = view.model.get(jsonField.name);
        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    // View Time Field
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TIMEFIELD(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const timeZone = userInfo.get('timezone') || serverConfigParams.get('SystemTimezone');

        if (timeZone) {
            jsonField.relatedValue = moment(new Date()).tz(timeZone).format('z');
        }

        return !util.isEmpty(jsonField.value) ? viewTmpl(jsonField) : '';
    },

    // Second account number widget
    /*
     * param {Object} jsonField
     * @returns {String}
     */
    SECONDACCOUNTNUM(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SECONDACCOUNTNUMWIDGET',
            blockClass: 'col-md-2 col-mdlg-4',
            fieldName: jsonField.name,
            shouldBeProtected: jsonField.protected,
        });
    },

    // View password Helper
    /**
     * @returns {String}
     */
    PASSWORD() {
        return '';
    },

    // View Textline Helper
    /**
     * @returns {String}
     */
    TEXTLINE() {
        return '';
    },

    // View Buttonlink Helper
    /**
     * @returns {String}
     */
    BUTTONLINK() {
        return '';
    },

    // View Button Helper
    /**
     * @returns {String}
     */
    BUTTON() {
        return '';
    },

    /**
     * @returns {String}
     */
    BUTTONSMALL() {
        return '';
    },

    /**
     * @returns {String}
     */
    BUTTONLINKSMALL() {
        return '';
    },

    // View ReportLink Helper
    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    REPORTLINK(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        return buttonLinkTmpl(jsonField);
    },

    // View Navlink Helper for readonly navigation from a page
    /**
     * @returns {String}
     */
    NAVLINK(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.iconClass = `icon-${jsonField.name.toLowerCase()}`;
        jsonField.buttonClass = 'btn-tertiary';
        return navLinkTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    IMAGEVIEWER(view, jsonField) {
        const frontImage = view.model.get('FRONTIMAGE');
        const backImage = view.model.get('BACKIMAGE');
        const images = [];
        const frontImageType = view.model.get('FRONTIMAGETYPE');
        const backImageType = view.model.get('BACKIMAGETYPE');

        /*
         * FIXME: this is the only templateHelper that changes the model. Should this
         * happen elsewhere?
         */
        view.model.set({
            images,
            headerData: '',
            headerLabels: '',
            image_data: frontImage,
            image_type: frontImageType,
            image_data_reverse: backImage,
            image_type_reverse: backImageType,
        });

        return imageViewerTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    SIMPLENUMERICFILTER(view, jsonField) {
        viewFieldHelpers.NUMERICFILTER(view, jsonField);
    },

    SIMPLENUMERIC2FILTER(view, jsonField) {
        viewFieldHelpers.NUMERICFILTER(view, jsonField);
    },

    SIMPLENUMERICEXCLUDEIN(view, jsonField) {
        viewFieldHelpers.NUMERICFILTER(view, jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    NUMERICFILTER(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        let value = view.model.get(jsonField.name);

        // if equality operater is between
        if (view.model.get(`${jsonField.name}-equality`) === 'BETWEEN') {
            // get AMOUNT2 attribute from model and tack it on to value
            value += ` ${locale.get('common.and')} ${view.model.get(`${jsonField.name}2`)}`;
        }

        if (util.isEmpty(value)) {
            return '';
        }

        jsonField.operation = findOperation(view, jsonField);
        jsonField.value = value;
        return readOnlyFilterTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    TEXTFILTER(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        return !util.isEmpty(jsonField.value) ? readOnlyTmpl(jsonField) : '';
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    COMBOFILTER(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }

        jsonField.operation = findOperation(view, jsonField);
        jsonField.value = value;
        return readOnlyFilterTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    COMBOFILTERLT(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }

        jsonField.operation = findOperation(view, jsonField);
        jsonField.value = value;
        return readOnlyFilterTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    MULTICOMBOFILTER(view, jsonField) {
        const value = getMultiComboValues(view, jsonField);
        if (!value) {
            return '';
        }
        return readOnlyFilterTmpl({
            blockClass: jsonField.blockClass,
            fieldLabel: jsonField.fieldLabel,
            fieldName: jsonField.name,
            operation: findOperation(view, jsonField, 'IN'),
            value,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    MULTIGRIDFILTER(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }
        const op = view.model.get(`${jsonField.name}-equality`);
        jsonField.operation = op.toLowerCase();
        jsonField.value = value;
        return readOnlyMultiSelectFilterTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    SINGLEGRIDFILTER(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }
        jsonField.operation = findOperation(view, jsonField);
        jsonField.value = value;
        return readOnlyFilterTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    MULTICHECKFILTER(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }
        jsonField.value = value;
        return readOnlyTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    DATEFILTER(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }
        jsonField.value = value;
        return readOnlyTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    BASEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'BASEWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    VALUEDATEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'VALUEDATEWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
            blockClass: jsonField.blockClass,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    ADDRESSFIELDWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'ADDRESSFIELDWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    AUDITSECTIONWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'AUDITSECTIONWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    AUDITHISTORY(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'AUDITHISTORYWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'audit-list-view',
        });
    },

    // Default MultiSelect Combobox Widget Helper
    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    MULTICOMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'MULTISELECTCOMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    COMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'COMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * {Object} view
     * {Object} jsonField
     * @returns {String}
     */
    DOUBLECOMBOBOXWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'DOUBLECOMBOBOXWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    FLEXDROPDOWNWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'FLEXDROPDOWNWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    SUMMARYSECTIONWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SUMMARYSECTIONWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    TRANSACTIONSUMMARY(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'TRANSACTIONSUMMARYWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    TRANSACTIONAUDIT(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'TRANSACTIONAUDITWIDGET',
            fieldName: jsonField.name,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    FXPAYMENTWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'FXPAYMENTWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    RANGEFIELDWIDGET(view, jsonField) {
        // Split the string, then map, remove blanks, and join back together.
        const rangeValue = util.chain(jsonField.relatedProperty.split(','))
            .map(val => val.trim().toUpperCase())
            .map(fldName => view.model.get(fldName))
            .compact()
            .value()
            .join(' - ');

        return viewTmpl({
            blockClass: jsonField.blockClass,
            fieldLabel: jsonField.fieldLabel,
            name: jsonField.name,
            value: rangeValue,
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    SCHEDPAYWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SCHEDULEPAYMENTWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    ACHAUTHRULESSCHEDULE(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'ACHAUTHRULESSCHEDULE',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
        });
    },

    /**
     * @param {Object} view
     * @param {Object} jsonFieldParam
     * @returns {String}
     */
    STATETOGGLE(view, jsonFieldParam) {
        const jsonField = jsonFieldParam;
        jsonField.value = view.model.get(jsonField.name);
        return viewTmpl(jsonField);
    },

    /**
     * @param {Object} view
     * @param {Object} jsonField
     * @returns {String}
     */
    PMXREMITTANCE(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'PMXREMITTANCEWIDGET',
            fieldName: jsonField.name,
        });
    },

    PMXCHECKPRINT(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'PMXCHECKPRINTDETAILWIDGET',
            fieldName: jsonField.name,
        });
    },

    SIMPLETABLEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'SIMPLETABLEWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-12',
        });
    },

    LISTVIEWWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'LISTVIEWWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-12',
        });
    },

    DOCUMENTWIDGET(view, jsonField) {
        const viewParam = view;
        viewParam.useAltGrid = true;
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'DOCUMENTWIDGET',
            fieldName: jsonField.name,
            cssWidgetClass: 'inline-fields',
            blockClass: 'col-12',
        });
    },
    CONVERSATIONWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'CONVERSATIONWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-6',
        });
    },

    LOCKTOGGLEWIDGET(view, jsonField) {
        return uiWidgetTmpl({
            name: camelize(jsonField.name),
            widgetType: 'LOCKTOGGLEWIDGET',
            fieldName: jsonField.name,
            blockClass: 'col-6',
        });
    },
    MASKEDINPUTWIDGET(view, jsonField) {
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }
        return uiWidgetTmpl({
            blockClass: jsonField.blockClass,
            cssWidgetClass: 'inline-fields',
            fieldName: jsonField.name,
            name: camelize(jsonField.name),
            widgetType: 'MASKEDINPUTWIDGET',
            isReadOnly: true,
        });
    },
    /* masked input that can be toggled on and off */
    TOGGLEMASKEDWIDGET(view, jsonField) {
        const value = view.model.get(jsonField.name);
        if (util.isEmpty(value)) {
            return '';
        }
        return uiWidgetTmpl({
            blockClass: jsonField.blockClass,
            cssWidgetClass: 'inline-fields',
            fieldName: jsonField.name,
            name: camelize(jsonField.name),
            widgetType: 'TOGGLEMASKEDWIDGET',
            isReadOnly: true,
        });
    },
    /* toggle switch control that can appear on a detail page */
    TOGGLEWIDGET(view, jsonField) {
        const { shouldShowMaskToggle } = MaskToggleUtil.initMaskToggle();
        if (shouldShowMaskToggle) {
            return uiWidgetTmpl({
                blockClass: jsonField.blockClass,
                cssWidgetClass: 'inline-fields',
                fieldName: jsonField.name,
                name: camelize(jsonField.name),
                widgetType: 'TOGGLEWIDGET',
                isReadOnly: true,
            });
        }
        return '';
    },
    RTPTOGGLEWIDGET(view, jsonField) {
        const { shouldShowMaskToggle } = MaskToggleUtil.initMaskToggle();
        if (shouldShowMaskToggle) {
            return uiWidgetTmpl({
                blockClass: jsonField.blockClass,
                cssWidgetClass: 'inline-fields',
                fieldName: jsonField.name,
                name: camelize(jsonField.name),
                widgetType: 'RTPTOGGLEWIDGET',
                isReadOnly: true,
            });
        }
        return '';
    },
};

export default viewFieldHelpers;
