var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <p>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"section section-container\">\n    <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.Detail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":42}}}))
    + "</legend>\n    <div class=\"row\">\n        <div class=\"form-group col-md-12\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Run.Times",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":19},"end":{"line":5,"column":45}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"runTimes") || (depth0 != null ? lookupProperty(depth0,"runTimes") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"runTimes","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":27}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n</fieldset>\n<fieldset class=\"section section-container\">\n    <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.Criteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":44}}}))
    + "</legend>\n    <div class=\"row\">\n        <div class=\"form-group col-md-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":19},"end":{"line":14,"column":45}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"reportId") || (depth0 != null ? lookupProperty(depth0,"reportId") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"reportId","hash":{},"data":data,"loc":{"start":{"line":15,"column":15},"end":{"line":15,"column":27}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-group col-md-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":19},"end":{"line":20,"column":47}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"reportDesc") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-group col-md-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.File.Type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":19},"end":{"line":28,"column":45}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"fileType") || (depth0 != null ? lookupProperty(depth0,"fileType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"fileType","hash":{},"data":data,"loc":{"start":{"line":29,"column":15},"end":{"line":29,"column":27}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n</fieldset>\n";
},"useData":true});