import Marionette from 'backbone.marionette';

export default Marionette.Behavior.extend({
    events: {
        'click [data-hook="clear-bene"]': 'clearBeneficiaryForm',
    },

    /**
     * Wipe the beneficiary Form
     */
    clearBeneficiaryForm() {
        // clear helper text
        this.clearHelperText();

        let recalculateTotal;
        /*
         * NH-47905 determine whether the totals needs to be recalculated if the
         * clear bene button is used
         */
        if (this.view.model.get('AMOUNT')) {
            recalculateTotal = true;
        }

        // Empty the model without removing the keys themselves
        this.view.model.set(this.view.model.keys().reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {}));

        if (recalculateTotal) {
            this.view.appBus.trigger('updatePaymentSummary');
        }

        // NH-60208 needed to re-render with driver fields
        this.view.render();

        return false;
    },

    clearHelperText() {
        this.$el.find('input.helper-text-container').each((i, element) => {
            this.view.trigger('lookupHelperText:clear', element.id);
        });
    },
});
