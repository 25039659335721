import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import LoanAdvanceCollection from 'app/smb/collections/loanAdvance';
import SimpleCellView from 'app/smb/views/cells/simple';
import ModalCellView from 'app/smb/views/cells/complexModal';
import AccountWithBalCellView from 'app/smb/views/cells/accountWithBal';
import ActionCell from 'common/dynamicPages/views/gridActionCell';
import Navigation from 'app/smb/navigation';
import LoanAdvanceGridAddRowView from './addRow';
import LoanAdvanceGridView from '../baseAddGrid';
import baseTmpl from './base.hbs';

const paymentTypeId = 'SMBLOANADVANCE';

export default Layout.extend({
    initialize(options) {
        this.listenTo(this.loanAdvanceCollection, 'all', this.updateTotal);
        this.fromLoanList = options.fromLoanList;
        this.toAccountList = options.toAccountList;
        this.paymentTypeCollection = options.paymentTypeCollection;
        this.dates = options.dates;
        this.cutoffTimes = options.cutoffTimes;
    },

    template: baseTmpl,
    draftSaved: false,
    loanAdvanceCollection: new LoanAdvanceCollection(),

    onRender() {
        this.ui.$paymentsList.comboBox();
        this.ui.$paymentsList.comboBox('val', paymentTypeId);
        this.ui.$totalLabel.text(`${locale.get('sbPayments.totalAdvancesLabel')} ${Formatter.formatCurrency(this.loanAdvanceCollection.total())}`);
    },

    onShow() {
        const columns = new Collection([{
            title: locale.get('sbPayments.fromLoan'),
            accountList: this.fromLoanList,
            idField: 'fromLoanId',
            displayStache: '{{id}} - {{description}}',
            type: AccountWithBalCellView,
        }, {
            title: locale.get('sbPayments.toAccount'),
            accountList: this.toAccountList,
            idField: 'toAccountId',
            displayStache: '{{id}} - {{description}}',
            type: AccountWithBalCellView,
        }, {
            title: locale.get('sbPayments.effectiveDate'),
            field: 'paymentDisplayDate',
            type: SimpleCellView,
        }, {
            title: locale.get('sbPayments.comment'),
            field: 'comment',
            sort: 'string',
            type: ModalCellView,
            editCell: 'text',
        }, {
            title: locale.get('sbPayments.amount'),
            field: 'displayAmount',
            sort: 'string',
            type: SimpleCellView,
            editCell: 'text',
        }, {
            type: ActionCell,
            width: 100,
            resizing: false,
            className: 'text-center btn-cell',
            display: 'iconList',

            buttons: [{
                value: 'edit',
                label: locale.get('smb.edit'),
                icon: 'pencil',
            }, {
                value: 'delete',
                label: locale.get('smb.delete'),
                icon: 'cancel',
            }],

            editButtons: [{
                value: 'cancel',
                label: locale.get('smb.cancel'),
            }],
        }]);

        const gridView = new LoanAdvanceGridView({
            AddEditRowView: LoanAdvanceGridAddRowView,
            collection: this.loanAdvanceCollection,
            columns,

            editOptions: {
                fromLoans: this.fromLoanList.toJSON(),
                toAccounts: this.toAccountList.toJSON(),
                blockedDates: this.dates.blockedDates,
                maxDate: this.dates.maxDate,
                cutoffTimes: this.cutoffTimes,
            },
        });

        this.content.show(gridView);
    },

    ui: {
        $paymentsList: '[data-hook="payments-list"]',
        $totalLabel: '[data-hook="total-text"]',
        $submitButton: '[data-hook="submit-request"]',
        $saveButton: '[data-hook="save-draft"]',
    },

    appEvents: {
        'sbPayments:paymentListUpdated': 'updateTotal',
    },

    events: {
        'select2-selecting #payments-list': 'selectChange',
    },

    templateHelpers() {
        const paymentTypes = util.sortBy(this.paymentTypeCollection.models[0].get('smbPayMenu'), 'displayOrder');
        return {
            paymentTypes,
        };
    },

    selectChange(e) {
        /*
         * paytype can be taken from one of the collections.
         * As it is not permitting in few screens, defined value
         * 'paymentTypeId' is taken to compare the menucategory.
         */
        const self = this;
        if (paymentTypeId !== e.val) {
            dialog.confirm(locale.get('smb.change.paymenttype.warning'), 'Warning', (ok) => {
                if (ok) {
                    Navigation.navigate(e, self);
                }
                self.ui.$paymentsList.comboBox('val', paymentTypeId);
            });
        }
    },

    updateTotal() {
        const total = this.loanAdvanceCollection.total();
        const totalAdvances = this.loanAdvanceCollection.models.length;
        this.$el.find('[data-hook="total-text"]').text(`${totalAdvances} ${locale.get('sbPayments.totalAdvancesLabel')} ${Formatter.formatCurrency(total)}`);
    },

    submitRequest() {
        const self = this;
        const totalPayments = this.loanAdvanceCollection.models.length;
        const alertStr = `${totalPayments} ${totalPayments === 1 ? locale.get('sbPayments.submitMessage.advance') : locale.get('sbPayments.submitMessage.advances')}`;

        if (totalPayments > 0) {
            dialog.alert(alertStr, () => {
                dialog.alert(locale.get('sbPayments.serverSubmit'));
            });
            // Send data to server
            this.loanAdvanceCollection.reset();
        } else {
            dialog.alert(alertStr, () => {
                // Return to Dashboard
                self.navigateTo('');
            });
        }
    },

    saveDraft() {
        dialog.alert('Draft Saved');
        this.draftSaved = true;
    },

    showServiceNotAvailableDialog() {
        dialog.alert(locale.get('smb.service.unavailable.error'));
    },

    cancelPayments() {
        this.navigateTo('PAYMENTS/enterPayment');
    },
});
