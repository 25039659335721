import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

const IssueVoidSummary = Model.extend({
    /*
     *  The first parameter of a model is the attributes.
     *  E.g. new Model([attributes], [options])
     */
    initialize(attributes, options) {
        this.service = options.service;
        this.order = options.order || false;
    },

    defaults: {
        filters: [],
    },

    sync(method, model, options) {
        if (method === 'read') {
            const data = {
                rowsPerPage: 0,
                startRow: 0,
                searchFields: this.get('filters'),
                viewId: this.get('viewId'),
            };

            http.post(this.service, data, (response) => {
                options.success(response);
            }, () => {
                options.error();
            });
        }
    },

    parse(response) {
        /*
         *  1. Loop over the top level 'issuesByGroupSummary' array
         *      1.1 Create Group Name from 'identifierDescription'
         *  2. Loop over each 'issuesByGroup' Subgroup
         *      2.1 Create 'paymentGroupName' from 'currencyCode'
         *      2.2 Create 'totals' array. (In the case of Issues/Voids, we'll only have
         *          a single item in the array, but the HBS template expects an array
         *          for more flexibility)
         */

        let groups = response.issuesByGroupSummary;

        util.each(groups, function (groupParam) {
            const group = groupParam;
            // Set column label
            group.paymentGroupName = group.identifierDescription;
            // Parse the Subgroups
            const subgroups = group.issuesByGroup;
            util.each(subgroups, function (tileParam) {
                const tile = tileParam;
                // Subgroup name should be the currency
                tile.subGroupName = tile.currency;
                // Create array of "Totals"
                tile.totals = [];
                if (tile.amountTotal) {
                    tile.totals.push(this.createTotalsObj(tile.amountTotal, tile.currency));
                }
            }, this);

            /*
             *  Set parsed groups as new attribute that the CollectionView
             *  will ultimately use as its Collection
             */
            group.totalTiles = subgroups;
        }, this);

        // Remove empty paymentGroups
        groups = util.filter(groups, group => group.totalTiles.length > 0);

        // Sort if order is specified
        if (this.order) {
            groups = util.sortBy(groups, function (obj) {
                return util.indexOf(this.order, obj.identifier);
            }, this);
        }

        // Set the parsed array back into the response
        response.issuesByGroupSummary = groups;
        return response;
    },

    createTotalsObj(amount, currency) {
        return {
            amount,
            currency,
        };
    },
});

export default IssueVoidSummary;
