var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div>\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n        <div class=\"btn-wrapper\">\n        "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":51}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"summary-body\">\n                <div data-region=\"tableContent\" aria-live=\"polite\"></div>\n        </div>\n  </div>\n\n";
},"useData":true});