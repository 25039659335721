import React, { Fragment, useState, useLayoutEffect } from 'react';
import { appBus } from '@glu/core';
import { ViewWrapper } from '@glu/react-wrappers';
import CheckTransactionsView from 'pcmMdf/workspaceWidgets/checkTransactions/checkTransactionList'; // eslint-disable-line

const CheckTransactionsWorkspace = () => {
    const [mainView, setMainView] = useState(new CheckTransactionsView({
        id: 'CHECK_TRANSACTIONS',
        returnRoute: 'PAYMENTS/checkTransactionsWorkspace',
    }));

    useLayoutEffect(() => {
        appBus.on('show:page', setMainView);

        return () => {
            appBus.off('show:page', setMainView);
        };
    }, []);

    return (
        <Fragment>
            <ViewWrapper view={mainView} />
        </Fragment>
    );
};

export default CheckTransactionsWorkspace;
