import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import cache from 'system/utilities/cache';
import moment from 'moment';
import util from '@glu/core/src/util';
import $ from 'jquery';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default function (form, initialState) {
    const formState = form.formView.state;
    const stopType = form.field('STOPTYPE_DESCRIPTION');
    const fromAccount = form.field('ACCOUNT_NUMBER');
    const fromAccountName = form.field('ACCOUNT_TITLE');
    const serialNumber = form.field('SERIALNUMBER');
    const specifyRange = form.field('SPECIFYRANGE');
    const toSerialNumber = form.field('SERIALNUM_TO');
    const abaNumber = form.field('ABA_NUMBER');
    const amount = form.field('AMOUNT');
    const payee = form.field('PAYEE');
    const isInsertOrModify = (formState === 'insert' || formState === 'modify');
    const isCheckRangeDisabled = (serverConfigParams.get('CheckRangeEnabled').toUpperCase() === 'FALSE');
    const { model } = form.formView;
    let fromStopRecord = false;
    let stopRequestModel;
    let prefillData;
    const maskingProps = getMaskingProperties();

    const validatorShouldExist = function (field) {
        if (util.isObject(model.validators[field])) {
            serialNumber.setValidator(serialNumber.$el, 'exists', true);
            serialNumber.setValidator(serialNumber.$el, 'isNumeric', true);
            serialNumber.setValidator(serialNumber.$el, 'matches', '[0-9]*$');
        }
    };

    const validatorShouldNotExist = function (field) {
        if (util.isObject(model.validators[field])) {
            serialNumber.removeValidator('exists');
            serialNumber.removeValidator('isNumeric');
            serialNumber.removeValidator('matches');
        }
    };

    if (initialState) {
        if (formState === 'insert') {
            stopRequestModel = cache.remove('cm_listView_stopCancels-actionModel');
            // if the corp cache is null, check for the smb cache
            if (util.isNull(stopRequestModel)) {
                stopRequestModel = cache.remove('cm_smbStopCancels-actionModel');
            }

            // save the initial mandatory state of the amount field for later use
            amount.$el.data('initial-required-state', amount.$el.parent().hasClass('required'));
            $('#ABA_NUMBER').hide();
            abaNumber.$el.parent().hide();
            $('.mdf-INST-CM-CANCEL fieldset:eq(1)').hide();
            serialNumber.shouldBeNumeric();

            if (!util.isNull(stopRequestModel)) {
                /*
                 * if the stopRequestModel is not null, the control came here from the Stop
                 * Cancel item. so read the values from the actionModel and set it to the
                 * page model.
                 */
                fromStopRecord = true;

                const confirmedDate = stopRequestModel.get('CONFIRMED_TIMESTAMP');
                const finalConfirmedTimestamp = confirmedDate?.length ? moment(new Date(confirmedDate)).format('YYYY-MM-DD') : '';

                prefillData = {
                    ACCOUNTFILTER: stopRequestModel.get('ACCOUNTFILTER'),
                    BANKCODE: stopRequestModel.get('BANKCODE'),
                    ABA_NUMBER: stopRequestModel.get('ABA_NUMBER'),
                    ACCOUNT_NUMBER: stopRequestModel.get('ACCOUNT_NUMBER_DISP'),
                    ACCOUNT_NUMBER_UNMASKED: stopRequestModel.get('ACCOUNT_NUMBER'),
                    ACCOUNT_TITLE: stopRequestModel.get('ACCOUNT_TITLE'),
                    CONFIRMED_TIMESTAMP: finalConfirmedTimestamp,
                    MEMO: stopRequestModel.get('MEMO'),
                    SERIALNUMBER: stopRequestModel.get('SERIALNUMBER'),
                    SERIALNUM_TO: stopRequestModel.get('SERIALNUM_TO'),
                    AMOUNT: stopRequestModel.get('AMOUNT'),
                    PAYEE: stopRequestModel.get('PAYEE'),
                    STOPTYPE_DESCRIPTION: stopRequestModel.get('STOPTYPE_DESCRIPTION'),
                    STOP_DURATION: stopRequestModel.get('STOP_DURATION'),
                    FROMSTOP: true,
                };
            }
        }
    }

    if (fromStopRecord) {
        model.set(prefillData); // resets the initialState to false
    }

    if (serialNumber.isEmpty()) {
        toSerialNumber.setValue('').$el.prop('readonly', true);
    } else {
        toSerialNumber.$el.prop('readonly', false);
        toSerialNumber
            .shouldBeNumeric()
            .shouldHaveMinValue(Number(serialNumber.getValue()) + 1);
    }

    if (model.get('FROMSTOP') || model.get('TYPE') === 'CANCEL') {
        if (isInsertOrModify) {
            if (model.get('SERIALNUM_TO')) {
                toSerialNumber.shouldBeVisible();
                specifyRange.$el.prop('checked', true);
            } else {
                toSerialNumber.shouldBeHidden();
            }
        }

        /*
         * protect all the fields except the memo when we cancel a stop from the
         * Stop List View
         */
        fromAccount.shouldBeReadOnly(true);
        /*
         * for historical inquiry the read doesn't read the entire model
         * so use the ACCOUNT_NUMBER_DISP from the existing model (from the grid)
         */
        if (model.get('viewAction') === 'INQHIST') {
            fromAccount.$el.html(model.get('ACCOUNT_NUMBER_DISP'));
        } else {
            fromAccount.$el.val(maskValue(model.get('ACCOUNT_NUMBER'), maskingProps));
        }
        form.formView.$el.find('[name="ACCOUNT_NUMBER_LOOKUP"]').hide();
        serialNumber.shouldBeReadOnly(true);
        toSerialNumber.shouldBeReadOnly(true);
        payee.shouldBeReadOnly(true);
        amount.shouldBeReadOnly(true);
    } else if (isInsertOrModify) {
        if (specifyRange.$el.prop('checked')) {
            toSerialNumber.shouldBeVisible();
        } else {
            toSerialNumber.shouldBeHidden();
            model.set('SERIALNUM_TO', '');
        }
    }

    if (formState === 'insert') {
        // Hide the audit section
        const aFieldInAuditSection = form.field('ENTERED_TIMESTAMP');
        aFieldInAuditSection.$el.closest('.section').hide();
    }

    if (formState === 'modify'
        && model.get('viewAction') === 'INQUIRY') {
        $('#ENTERED_TIMESTAMP').closest('.section').hide();
    }

    if (formState === 'modify') {
        fromAccount.shouldBeReadOnly(true);
        abaNumber.shouldBeReadOnly(true);
        /*
         * in modify, hide the serial number range checkbox since
         *  we the serial number in modify is protected
         */
        if (util.isEmpty(model.get('SERIALNUM_TO'))) {
            specifyRange.shouldBeHidden();
        }
    }
    if (formState !== 'view') {
        fromAccountName.shouldBeHidden();
    }
    if (isCheckRangeDisabled) {
        toSerialNumber.shouldBeHidden();
        specifyRange.shouldBeHidden();
    }

    if (toSerialNumber.isEmpty() && isInsertOrModify) {
        toSerialNumber.removeValidator('isNumeric');
        toSerialNumber.removeValidator('minValue');
    } else {
        /*
         * when there is a "to" serial number (e.g. range entered) the amount field
         * is disabled and can never be mandatory even if set in the server meta data
         */
        amount.shouldBeOptional(true);
        amount.setValue('');
        amount.$el.parent().find('.help-block').empty();
        model.set('AMOUNT', '');
        payee.shouldBeOptional(true);
        payee.setValue('');
        payee.$el.parent().find('.help-block').empty();
        model.set('PAYEE', '');
    }
    amount.shouldBeReadOnlyWhen(toSerialNumber.isNotEmpty());
    payee.shouldBeReadOnlyWhen(toSerialNumber.isNotEmpty());

    /*
     * reset the amount field to it's initial mandatory state when
     * to serial number is removed.
     */
    if (toSerialNumber.isEmpty() && amount.$el.data('initial-required-state')) {
        amount.shouldBeRequired(true);
    }

    if (model.fieldData.AMOUNT.mandatory === false) {
        // its not mandatory, next check if its a check/preauthdebit
        if (model.get('STOPTYPE') === 'CHECK') {
            $('.CM_CANCEL_STOP_DETAIL_3').show();
            validatorShouldExist('SERIALNUMBER');
            amount.removeValidator('exists');
            payee.removeValidator('exists');
            $('.CM_CANCEL_STOP_DETAIL_4 div').removeClass('required');
            $('.mdf-INST-CM-CANCEL fieldset:eq(1)').show();
            if (!isCheckRangeDisabled) {
                specifyRange.shouldBeVisible();
            }
            /*
             * NH-113113 the payee field is not returned in the view mode as the value will be
             * blank for CHECK, so it is hiding the amount field.
             */
            if (formState !== 'view') {
                $('.CM_CANCEL_STOP_DETAIL_4 div').last().hide();
            }

            if (model.get('FROMSTOP')) {
                stopType.shouldBeReadOnly(true);
            }
        } else if (model.get('STOPTYPE') === 'PREAUTHDEBIT') {
            $('.CM_CANCEL_STOP_DETAIL_3').hide();
            validatorShouldNotExist('SERIALNUMBER');
            amount.setValidator(amount.$el, 'exists', true);
            payee.setValidator(payee.$el, 'exists', true);
            if (formState === 'insert' || formState === 'modify') {
                $('.CM_CANCEL_STOP_DETAIL_4 div').addClass('required');
                if (payee.$el.val() !== undefined && payee.$el.val().trim() === '' && amount.$el.val() !== '') {
                    payee.removeValidator('exists');
                }
                if (amount.$el.val() === '' && payee.$el.val() !== undefined && payee.$el.val().trim() !== '') {
                    amount.removeValidator('exists');
                }
            }
            $('.mdf-INST-CM-CANCEL fieldset:eq(1)').show();
            specifyRange.shouldBeHidden();
            $('.CM_CANCEL_STOP_DETAIL_4 div').last().show();
        }
    } else {
        // amount is now mandatory
        if (toSerialNumber.isEmpty()) {
            if (formState === 'insert' || formState === 'modify') {
                amount.shouldBeRequired();
                if (payee.$el.val() !== undefined && payee.$el.val().trim() === '' && amount.$el.val() !== '') {
                    payee.removeValidator('exists');
                }
                if (amount.$el.val() === '' && payee.$el.val() !== undefined && payee.$el.val().trim() !== '') {
                    amount.removeValidator('exists');
                }
            }
        }
        $('.mdf-INST-CM-CANCEL fieldset:eq(1)').show();
        $('.CM_CANCEL_STOP_DETAIL_4 div').last().show();
    }

    if (isInsertOrModify) {
        $('.CM_CANCEL_STOP_DETAIL_3 div').first().addClass('required');
    } else {
        $('.CM_CANCEL_STOP_DETAIL_3 div').first().removeClass('required');
    }
}
