import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CaretRightIcon } from '@glu/icons-react';
import { CheckboxRadio as Checkbox } from '@glu/form-components';
import { withStyles } from '@glu/theming';

import styles from './DataRow.styles';

const DataRow = ({
  classes,
  rowData,
  bulkActionsEnabled,
  rowId,
  primaryColumns,
  children,
  isSelected,
  onBulkActionSelect,
  forceOpen
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback(() => { setOpen(o => !o); }, []);
  const handleCheckboxSelect = useCallback((_name, _isChecked, isBlurEvent) => {
    if (isBlurEvent) return;
    onBulkActionSelect({ rowId, rowData });
  }, [onBulkActionSelect, rowId, rowData]);

  useEffect(() => {
    if (forceOpen) {
      setOpen(true);
    }
  }, [forceOpen]);

  return (
    <>
      <tr className={classes.primaryRow}>
        {bulkActionsEnabled ? (
          <th rowSpan="2" className={`${classes.bulkActionSelect} ${classes.bulkActionSvg}`}>
            <Checkbox
              name={`checkbox_${rowId}`}
              htmlId={`checkbox_${rowId}`}
              checked={isSelected}
              onChange={handleCheckboxSelect}
              labelText={`Select checkbox ${rowId}`}
              screenReaderLabel
            />
          </th>
        ) : null}
        <th rowSpan="2" className={`${classes.expandoTh} ${classes.subCells}`}>
          <button className={classes.button} type="button" onClick={toggleOpen}>
            <CaretRightIcon className={isOpen ? classes.svgOpen : classes.svg} />
          </button>
        </th>
        {primaryColumns.map(col => (
          <td
            role="gridcell"
            className={classes.mainCells}
            key={col.field}
            onClick={toggleOpen}
          >
            {col.cellDisplay
              ? col.cellDisplay({ rowData, column: col })
              : rowData[col.field]}
          </td>
        ))}
      </tr>
      <tr>
        <td colSpan={primaryColumns.length}>
          <div
            className={isOpen ? classes.contentPanelShown : classes.contentPanelHidden}
            data-qa={`row-children-${isOpen ? 'open' : 'closed'}`}
          >
            {children}
          </div>
        </td>
      </tr>
    </>
  );
};

DataRow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  rowData: PropTypes.shape({}).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  primaryColumns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    cellDisplay: PropTypes.func,
    hide: PropTypes.bool,
    primary: PropTypes.bool
  })).isRequired,
  children: PropTypes.node.isRequired,
  bulkActionsEnabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  onBulkActionSelect: PropTypes.func.isRequired,
  forceOpen: PropTypes.bool
};

DataRow.defaultProps = {
  isSelected: false,
  forceOpen: false
};

export default withStyles(styles)(DataRow);
