import PropTypes from 'prop-types';

/*
 * The collection prop doesn't fit into a React mindset, so instead of passing it in, we have
 *   data and isLoading to handle the majority of use cases. Collection isn't the only bad egg,
 *   but it's the most obvious up front, so it got the axe first.
 *
 * This file is copied to another repo for now, please let Allen Gevry know about
 *   ANY change to this file as it has implications outside this repo.
 */
export default {
  columns: PropTypes.arrayOf(PropTypes.shape({
    // A custom view for each table cell
    cellView: PropTypes.func,

    // The html className attribute for each cell
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    // Whether or not to display the column
    condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

    defaultValue: PropTypes.string,
    disableMultiSort: PropTypes.bool,
    disableRemove: PropTypes.bool,
    draggable: PropTypes.bool,
    enumHash: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    filterable: PropTypes.bool,
    field: PropTypes.string,
    format: PropTypes.func,

    // Only used if type is defined as 'image'
    formatSrc: PropTypes.func,

    // A custom view to use for the column header cell
    headerView: PropTypes.func,

    label: PropTypes.string,
    resizable: PropTypes.bool,

    rowButtons: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      condition: PropTypes.func,
      handler: PropTypes.func,
      seperator: PropTypes.bool // ToDo [SIC]
    }))]),

    safe(props, propName) {
      if (!(typeof props[propName] === 'undefined' || props[propName] === null) && !props[propName]) {
        return new Error('Prop safe should never be disabled');
      }
      return null;
    },
    sortable: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })).isRequired,

  data: PropTypes.array.isRequired,

  bulkActions: PropTypes.array,
  caption: PropTypes.string,
  currentPage: PropTypes.number,

  defaultSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(['asc', 'desc'])
  }),

  disableAuxControl: PropTypes.bool,
  disableDynamicColumnWidths: PropTypes.bool,
  draggable: PropTypes.bool,
  emptyViewText: PropTypes.string,
  filterable: PropTypes.bool,

  // Causes filtering to work on the whole grid, not individual columns
  gridSearch: PropTypes.bool,

  groupBy: PropTypes.shape({
    field: PropTypes.string,
    titleField: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  }),

  id: PropTypes.string,
  isLoading: PropTypes.bool,

  // Custom view for when the grid is loading
  loadingView: PropTypes.func,

  onAllRowsSelected: PropTypes.func,
  onRowSelected: PropTypes.func,

  loadingViewText: PropTypes.string,
  multiColumnsSort: PropTypes.any,

  // Provides basic support for server-side paging
  onPagingChange: PropTypes.func,

  // Custom processor for grid state changes
  onUserStateChange: PropTypes.func,

  // ToDo This block is correct according to the Glu docs. Wut?
  pageable: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    pageSize: PropTypes.number,
    pageSizeValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['ALL'])])),
    method: PropTypes.func // This function is called in the context of the collection
  })]),
  pageSize: PropTypes.number,
  pageSizeValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['ALL'])])),
  paginate: PropTypes.bool,
  paginateMethod: PropTypes.func, // This function is called in the context of the collection

  // prop that receives an array of the ids of all rows to select in the grid
  preSelectedRowIds: PropTypes.array,
  // prop that indicates the field name of the column to treat as the id column
  rowIdColumn: PropTypes.string,

  resizable: PropTypes.bool,
  resultsName: PropTypes.string,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  // Custom view for sub-grids
  rowSubView: PropTypes.func,

  rowSubViewOptions: PropTypes.object,

  savedViews: PropTypes.shape({
    gridName: PropTypes.string,
    storage: PropTypes.string
  }),

  // Glu Collection class
  savedViewsCollection: PropTypes.func,

  serverSidePagination: PropTypes.bool,

  sortable: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    method: PropTypes.func // This function is called in the context of the collection
  })]),

  /*
   * Custom state for the current user.
   * Commonly persisted so the user can customize the columns, sort, etc for a grid
   */
  userState: PropTypes.oneOfType([PropTypes.shape({
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,

    sort: PropTypes.shape({
      field: PropTypes.string,
      order: PropTypes.oneOf(['asc', 'desc'])
    }),

    columnOrder: PropTypes.arrayOf(PropTypes.string),
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),
    filters: PropTypes.object,
    multiColumnSort: PropTypes.array,

    // Not present in the array version
    columnsWidth: PropTypes.object

    // The array version is indexed following the order of attributes listed immediately above
  }), PropTypes.array]),

  // The same as userState, but gives up control of the stateful version after construction
  // ToDo we need to figure out whether we want both of these, or only one
  initialUserState: PropTypes.object
};
