import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import services from 'services';
import userInfo from 'etc/userInfo';
import http from '@glu/core/src/http';
import InquiryApi from 'system/webseries/api/inquiry';
import Constants from 'app/smbPayments/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default Collection.extend({
    initialize(opts) {
        this.inquiryId = opts.inquiryId;
        this.includeMapData = opts.includeMapData || InquiryApi.INCLUDE_MAP_DATA;
        this.typeCode = opts.typeCode || InquiryApi.ALL_TYPE_CODE;
        this.entryMethod = opts.entryMethod || InquiryApi.DEFAULT_ENTRY_METHOD;
        this.productCode = opts.productCode || InquiryApi.DEFAULT_PRODUCT_CODE;
        this.actionMode = opts.actionMode || InquiryApi.INSERT_ACTION_MODE;
        this.functionCode = opts.functionCode || InquiryApi.TRANSFER_FUNCTION_CODE;
        this.queryPageSize = opts.queryPageSize || InquiryApi.DEFAULT_QUERY_PAGE_SIZE;
        this.queryOffset = opts.queryOffset || InquiryApi.DEFAULT_QUERY_OFFSET;
        this.customFilters = opts.customFilters || undefined;
        this.labelFormat = opts.labelFormat || undefined;
        this.viewId = opts.viewId || InquiryApi.DEFAULT_VIEW_ID;
        this.userId = userInfo.get('id');
        this.userGroup = userInfo.get('group');
        this.password = userInfo.get('password');
        this.queryField = opts.queryField || undefined;
        this.subTypeCode = opts.subTypeCode || undefined;
        this.fieldName = opts.fieldName || undefined;
        this.bankCode = opts.bankCode || 'DEBIT_BANK_CODE';
        this.currencyCode = opts.currencyCode || 'CREDIT_CURRENCY';
        this.dispAcctBalance = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens');
    },

    sync(method, model, options) {
        const self = this;

        /*
         * Use the inquiry service to retrieve the query results.  Transform into
         * a format can be easily consumed by web components.
         */
        InquiryApi.getQueryResults({
            inquiryId: self.inquiryId,
            includeMapData: self.includeMapData,
            typeCode: self.typeCode,
            entryMethod: self.entryMethod,
            productCode: self.productCode,
            actionMode: self.actionMode,
            functionCode: self.functionCode,
            viewId: self.viewId,
            queryPageSize: self.queryPageSize,
            queryOffset: self.queryOffset,
            customFilters: self.customFilters,
            labelFormat: self.labelFormat,
            userGroup: self.userGroup,
            userId: self.userId,
            password: self.password,
            queryField: self.queryField,
            subTypeCode: self.subTypeCode,
            fieldName: self.fieldName,
        }).done((data) => {
            const rows = data.queryResponse.QueryData.queryRows;
            const balanceRequests = [];
            let mapDataList;
            let i;
            let accountNumber;
            let bankCodeObject;
            let currencyCodeObject;
            if (!util.isEmpty(rows)) {
                for (i = 0; i < rows.length; i += 1) {
                    ({ mapDataList } = rows[i]);
                    accountNumber = self.getAccountNumber(rows[i]);
                    bankCodeObject = util.findWhere(
                        mapDataList,
                        {
                            toField: self.bankCode,
                        },
                    );
                    currencyCodeObject = util.findWhere(
                        mapDataList,
                        {
                            toField: self.currencyCode,
                        },
                    );
                    if (self.dispAcctBalance && self.dispAcctBalance === 'true' && !util.isEmpty(accountNumber) && !util.isEmpty(bankCodeObject) && !util.isEmpty(currencyCodeObject)) {
                        balanceRequests.push({
                            productCode: self.productCode,
                            functionCode: self.functionCode,
                            typeCode: self.typeCode,
                            bankCode: bankCodeObject.value,
                            accountNumber,
                            currency: currencyCodeObject.value,
                        });
                    }
                }
                /*
                 * retrieve balances for the accounts retrieved.  Regardless of the success
                 * or failure of this call,
                 * account rows are returned
                 */
                if (!util.isEmpty(balanceRequests)) {
                    http.post(
                        services.currencyBalances,
                        {
                            requests: balanceRequests,
                        },
                        (response) => {
                            const { responses } = response;
                            for (i = 0; i < responses.length; i += 1) {
                                rows[i].accountBalance = responses[i].balance;
                            }
                            options.success(rows);
                        },
                        () => {
                            options.success(rows);
                        },
                    );
                } else {
                    options.success(rows);
                }
            } else {
                // the user has no permitted accounts
                options.success({});
            }
        });
    },

    /**
     * Fedwire accounts exist in row.name - ACH accounts exist in mapDataList
     */
    getAccountNumber(row) {
        if (Constants.isWire(this.typeCode)) {
            return row.name;
        }
        if (Constants.isACH(this.typeCode)) {
            return util.findWhere(
                row.mapDataList,
                {
                    toField: 'OFFSETACCOUNTNUM',
                },
            ).value;
        }
        return undefined;
    },

    /**
     * Extract results from the response.
     *
     * @param result
     */
    parse(result) {
        try {
            // do we need to hide or sort records like inquiry header service?
            return util.map(result, (rowParam) => {
                const row = rowParam;
                // Unescape html characters
                row.name = util.unescape(row.name);
                row.label = util.unescape(row.label);

                util.each(row.mapDataList, (modelParam) => {
                    const model = modelParam;
                    model.value = util.unescape(model.value);
                });

                return util.extend(row, this.parseRow(row));
            });
        } catch (err) {
            return [];
        }
    },

    /**
     * Map incoming row data.
     *
     * @param row
     * @returns {{}}
     */
    parseRow(row) {
        const extRow = {};

        extRow.id = row.name;

        // invoke the label format function if provided.
        if (util.isFunction(this.labelFormat)) {
            extRow.text = this.labelFormat(row);
        } else {
            extRow.text = row.label;
        }

        return extRow;
    },
});
