import locale from '@glu/locale';
import { getFlexDropdownFormat } from 'common/util/inquiryQueryResults';
import ViewDetailBase from './viewDetailBase';
import template from './viewBankReport.hbs';
import scheduleUtil from '../util';

export default ViewDetailBase.extend({
    template,

    initialize() {
        const reportId = this.model.get('REPORT_ID');
        const profileNumber = this.model.get('PROFILE_NUMBER');
        const userGroup = this.model.get('USERGROUP');
        const userId = this.model.get('USERID');
        Promise.all([
            scheduleUtil.getBankReports(
                userGroup,
                userId,
            ).then(getFlexDropdownFormat),
            scheduleUtil.getSavedBankReportConfigs(
                reportId,
                userGroup,
                userId,
            ).then(getFlexDropdownFormat),
        ]).then(([bankReports, configs]) => {
            this.bankReport = bankReports.find(report => report.id === reportId);
            this.config = configs.find(config => config.id === profileNumber);
            this.setHasLoadedRequiredData(true);
            this.render();
        });

        this.fileFormats = {
            PDF: 'PDF',
            TXT: locale.get('legacy.format.text'),
            CSV: 'CSV',
        };
    },

    templateHelpers() {
        return {
            ...ViewDetailBase.prototype.templateHelpers.call(this),
            fileFormatDesc: this.fileFormats[this.model.get('FILE_FORMAT')],
            parentDropdown: {
                value: this.getDescription(this.bankReport, this.model.get('REPORT')),
                label: locale.get('GIR.Report'),
            },
            childDropdown: {
                value: this.getDescription(this.config, this.model.get('SAVED_REPORT_NAME')),
                label: locale.get('GIR.Saved.Report.Name'),
            },
        };
    },
});
