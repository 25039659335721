import EntryView from 'common/dynamicPages/views/workflow/entry';
import util from '@glu/core/src/util';
import $ from 'jquery';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import services from 'services';
import http from '@glu/core/src/http';
import userInfo from 'etc/userInfo';
import transform from 'common/util/transform';
import alert from '@glu/alerts';
import PageApi from 'common/dynamicPages/api/view';
import gridApi from 'common/dynamicPages/api/grid';
import DataApi from 'common/dynamicPages/api/data';
import ContextApi from 'common/dynamicPages/api/context';
import CheckReview from 'common/dynamicPages/views/checkReview';
import PrintChecks from 'app/payments/views/checkInquiry/printChecks';
import checkDetailTmpl from './checkDetail.hbs';

export default EntryView.extend({
    template: checkDetailTmpl,

    initialize() {
        this.options = util.extend({
            menuCategory: 'PMTS',
            serviceName: this.getServiceName(),
            serviceFunc: null,
            businessType: null,
            context: 'CM_LIST_VIEW',
            productCode: 'CM',
            functionCode: 'INQ',
            typeCode: this.model.get('TYPE'),
            mode: 'modify',
            tnum: this.model.get('TNUM'),
            updateCount: this.model.get('UPDATECOUNT__'),
        }, this.options);

        this.smbUser = userInfo.isSmbUser();
        this.gridContx = store.get('check_inq_gridContext');
        this.model.context.isHistory = this.gridContx.context.isHistory;
        this.contextDef = this.options.contextDef
            || ContextApi.menuContext.getContext(this.options.context);
        EntryView.prototype.initialize.call(this, this.options);
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            this.contextDef.serviceName = this.options.serviceName;
            this.pageView = PageApi.page.get({
                context: self.model.context,
                model: self.storedModel,
                state: self.mode ? self.mode : 'modify',
                hideButtons: true,
                gridApi,
            });
            this.listenTo(this.pageView, 'loaded', () => {
                this.setPageViewListeners();
                this.getImage(this.storedModel);
            });
            this.listenTo(this.pageView, 'ui-loaded', () => {
                $('[name="DEBIT_ACCOUNT_LOOKUP"]').hide();
            });
            this.detailContent.show(this.pageView);
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const { model } = this;
        const typeCode = model.get('TYPE');
        let functionCode = 'INQ';
        let actionMode = 'MODIFY';

        if (this.isImageType(typeCode)) {
            actionMode = 'VIEW';
        } else if (typeCode === 'CANCEL' || typeCode === 'STOP') {
            functionCode = 'INST';
        }

        const actData = {
            actionMode,
            productCode: 'CM',
            functionCode,
            typeCode: model.get('TYPE'),
        };

        const keyListGlobal = ['TNUM', 'UPDATECOUNT__', 'viewAction'];

        const contextData = {
            // 'cm/checkInquiryDetail/' + model.get('TYPE'),
            serviceName: this.getServiceName(),

            actionData: actData,
            keyList: keyListGlobal,
        };

        const keys = {
            viewAction: 'true',
        };

        util.extend(model.key, keys);

        if (this.model.context.isHistory) {
            model.set('viewAction', 'INQHIST');
        } else {
            model.set('viewAction', 'INQUIRY');
        }

        model.context = contextData;

        DataApi.model.generateModelFromModel(model).then((newModel) => {
            newModel.fetch({
                success: () => {
                    this.storedModel = this.protectFields(newModel);
                    this.updateForAccountMasking();

                    this.setHasLoadedRequiredData(true);
                    this.render();
                },
            });
        });

        this.model.context.actionData.functionCode = 'INST';
    },

    getImage(newModel) {
        if (!this.isImageType(this.model.get('TYPE'))) {
            // trigger finish loading if no images needed
            this.trigger('checkDetail:loaded');
            return;
        }

        const model = newModel;
        const jsonData = [];

        const service = services.generateUrl('cm/checkImage/read');

        jsonData.push({
            name: 'ACCOUNT_TITLE',
            value: model.get('ACCOUNT_TITLE'),
        }, {
            name: 'ACCOUNT_NUMBER',
            value: model.get('ACCOUNT_NUMBER'),
        }, {
            name: 'ABA_NUMBER',
            value: model.get('ABA_NUMBER'),
        }, {
            name: 'ACCOUNTFILTER',
            value: model.get('ACCOUNTFILTER'),
        }, {
            name: 'BANKCODE',
            value: model.get('BANKCODE'),
        }, {
            name: 'BANKNAME',
            value: model.get('BANKNAME'),
        }, {
            name: 'TRAN_DATE',
            value: model.get('TRAN_DATE'),
        }, {
            name: 'VALUE_DATE',
            value: model.get('VALUE_DATE'),
        }, {
            name: 'AMOUNT',
            value: model.get('AMOUNT'),
        }, {
            name: 'PIC_SEQUENCE_NUMBER',
            value: model.get('PIC_SEQUENCE_NUMBER'),
        }, {
            name: 'IMAGELOCATION',
            value: model.get('IMAGELOCATION'),
        }, {
            name: 'PAYEE',
            value: model.get('PAYEE'),
        }, {
            name: 'SERIALNUMBER',
            value: model.get('SERIALNUMBER'),
        });

        http.post(
            service,
            {
                item: jsonData,
            },
            (resp) => {
                if (resp.errorCode !== 0) {
                    this.showErrorAlert(resp.messages);
                    return;
                }
                this.showImage(resp, model);
            },
            () => {
                this.showErrorAlert(locale.get('cm.image.not.found'));
            },
        );
    },

    showImage(resp, model) {
        const imageModel = model;
        let images = [];
        const headerData = transform.pairsToHash(resp.headerData.item);

        images = util.map(resp.images, image => transform.pairsToHash(image.item));

        // set image data
        imageModel.set({
            images,
            headerData,
            image_data: !util.isEmpty(resp.frontImage) ? resp.frontImage.value : '',
            image_type: !util.isEmpty(resp.frontImage) ? resp.frontImage.contentType : '',
            image_data_reverse: !util.isEmpty(resp.backImage) ? resp.backImage.value : '',
            image_type_reverse: !util.isEmpty(resp.backImage) ? resp.backImage.contentType : '',
        });

        /*
         * pass in custom print handler used for Check Inquiry prints as well as
         * needed data to function the same
         */
        this.checkReview = new CheckReview({
            model: imageModel,
            bulkCheckView: this.options.bulkCheckView,
            numberOfChecks: this.options.numberOfChecks,
            checkIndex: this.options.checkIndex,

            onPrint: () => PrintChecks.print({
                checks: [this.model],
                displayColumns: this.getPrintDisplayColumns(this.pageView.jsonModel),
                onError: msg => this.showErrorAlert(Array.isArray(msg) ? msg : [msg]),
            }),
        });

        this.imageViewer.show(this.checkReview);

        // trigger finish loading of check image (if image is needed)
        this.trigger('checkDetail:loaded');
    },

    /**
     * @param {array} columns
     * @return {array} formatted columns
     */
    getPrintDisplayColumns(columns) {
        // create reference display data out of available check info fields
        return columns
            .map(col => ({
                fieldName: col.name,
                label: col.fieldLabel,
            }));
    },

    /**
     * @method: showErrorAlert
     * @param {array} message
     * Show Error alert for image loading error
     */
    showErrorAlert(message) {
        this.alertRegion.show(alert.danger(message));
        // trigger finish loading of check image (if image is needed)
        this.trigger('checkDetail:loaded');
    },

    protectFields(newModelParam) {
        const newModel = newModelParam;
        const type = this.model.get('TYPE');
        const cancelEditableFields = ['MEMO'];
        const stopEditableFields = ['MEMO', 'AMOUNT', 'PAYEE', 'STOPDURATIONDESC', 'STOPPLACEMENT_REASON', 'TRAN_DATE'];

        newModel.keys().forEach((key) => {
            if (key in newModel.fieldData
                && ((type === 'CANCEL' && !util.contains(cancelEditableFields, key))
                    || (type === 'STOP' && !util.contains(stopEditableFields, key)))) {
                newModel.fieldData[key].protected = true;
            }
        });

        return newModel;
    },

    getServiceName() {
        const type = this.model.get('TYPE');

        if (type === 'CANCEL') {
            return '/cminst/cancelStop';
        }
        if (type === 'STOP') {
            return '/cminst/placeStop';
        }
        if (this.isImageType(type)) {
            return '/cm/checkImageDetail';
        }
        return undefined;
    },

    templateHelpers() {
        const self = this;
        const obj = EntryView.prototype.templateHelpers.call(this, undefined);

        return util.extend(
            obj,
            {
                getTitle() {
                    const type = self.model.get('TYPE');
                    const stopType = self.model.get('STOPTYPE_DESCRIPTION').toUpperCase();

                    if (type === 'CANCEL') {
                        return stopType.indexOf('PRE') > -1 ? locale.get('cminst.importCheck_title-view') : locale.get('cminst.cancelStop_title-modify');
                    }
                    if (type === 'STOP') {
                        return locale.get('cminst.placeStop_title-modify');
                    }
                    if (this.isImageType(type)) {
                        return locale.get('CM.CheckInformation');
                    }
                    return undefined;
                },
            },
        );
    },

    cancel() {
        this.disableButtons();
        store.unset('check_inq_AFFECTEDCONTEXT');
        store.unset('check_inq_gridContext');
        store.set('check_inq_returnReason', 'cancel');
        window.history.back();
    },

    save() {
        this.prepareAccountNumberForSave(this.pageView.model);

        this.pageView.model.unset('id', 'silent');
        this.contextKey = (this.smbUser) ? 'cm_smbStopCancels' : 'cm_listView_stopCancelsSearch';
        store.set('check_inq_AFFECTEDCONTEXT', this.contextKey);
        store.set('check_inq_ACCOUNTFILTER', '');
        store.set('check_inq_CHECKSTATUSCODE', '');
        this.pageView.save();
    },

    getListUrl() {
        return (this.smbUser) ? 'PAYMENTS/listSmbStops' : 'PAYMENTS/manageStops';
    },

    isImageType(typeCode) {
        return util.contains(['IMAGE', 'PHOTO'], typeCode);
    },

    getModifyButtons() {
        if (!this.isImageType(this.model.get('TYPE'))) {
            this.buttonList.push({
                action: 'SAVE',
            });
        }
    },

    getViewButtons() {
        this.buttonList = [];
    },

});
