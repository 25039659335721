import ItemView from '@glu/core/src/itemView';
import constants from 'app/administration/constants';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import permissionTmpl from './permission.hbs';

export default ItemView.extend({
    tagName: 'div',

    // TODO: this should be field-container-md but it doesn't seem to be 1/3 of the width
    className: 'permission',

    template: permissionTmpl,
    loadingTemplate: loadingWidgetTmpl,

    initialize() {
        this.model.id = this.model.get('permission');
    },

    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.render();
        this.$('[data-toggle="popover"]').popover();
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    templateHelpers() {
        return {
            isApproval: () => this.model.id === constants.SMB_PERMISSIONS
                .PAYMENT_APPROVAL_PERMISSION,
            showInfo: () => {
                const rfpModel = this.model.collection.findWhere({
                    permission: constants.SMB_PERMISSIONS.RFP_OUTBOUND_PERMISSION,
                });
                return this.model.id === constants.SMB_PERMISSIONS.PAYMENT_APPROVAL_PERMISSION
                    && this.model.get('entitled') && rfpModel && rfpModel.get('entitled');
            },

            checkboxDisabled: () => (this.model.id === constants.SMB_PERMISSIONS.AUTOBOOK_PERMISSION ? 'disabled' : ''),
        };
    },
});
