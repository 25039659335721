var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page\">\n  <div class=\"live-maintenance-user\">\n    <img class=\"header-image\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"logoImagePath") || (depth0 != null ? lookupProperty(depth0,"logoImagePath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"logoImagePath","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":52}}}) : helper)))
    + "\" alt=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"logoAltTxt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":59},"end":{"line":3,"column":82}}}))
    + "\"/>\n    <div class=\"row\">\n      <div class=\"col-12 alert-region\" data-region=\"alert-region\">\n      </div>\n    </div>\n    <div class=\"messages-region\" data-region=\"messages-region\"></div>\n    <ul class=\"messages-nav-region\" data-region=\"messages-nav-region\"></ul>\n\n  </div>\n</div>\n";
},"useData":true});