import Layout from '@glu/core/src/layout';
import ComplexTypeGroups from 'app/administration/collection/user/complexTypeGroups';
import constants from 'app/administration/constants';
import Dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import RestrictedTemplates from './restrictedTemplates';
import PaymentGroupCollectionView from './paymentType/paymentGroupCollectionView';
import AssignPaymentTypes from './assignPaymentTypes';
import EditLimitsDialog from './editLimitsDialog';
import LimitLayout from './limits/limitLayout';
import AssignedPermissions from './assignedPermissions';
import paymentWrapperTmpl from './paymentWrapper.hbs';

export default Layout.extend({
    template: paymentWrapperTmpl,

    initialize(options) {
        this.mode = options.mode || 'insert';
        this.model = options.model;
        this.entity = options.entity;
    },

    onRender() {
        const self = this;

        this.selectedGroups = new ComplexTypeGroups(this.model.getSelectedComplexTypes()
            .filter(child => self.modifyMode || child.hasEntitledType()));

        if (self.mode !== constants.MODES.VIEW) {
            self.buildPaymentTypesRegion();
        }

        self.buildApprovalLimitsRegion();
        self.buildAssignedPermissionsRegion();

        const hasChecks = this.selectedGroups.findWhere({ typeGroupId: 'CHECK' }); // PCM
        const isCheck = hasChecks && this.selectedGroups.length === 1;
        if (!isCheck) {
            self.buildRestrictedTemplatesRegion();
        }
    },

    buildPaymentTypesRegion() {
        this.paymentTypesView = new PaymentGroupCollectionView({
            collection: this.selectedGroups,
            mode: this.mode,
        });

        this.paymentTypesRegion.show(this.paymentTypesView);
    },

    buildApprovalLimitsRegion() {
        this.approvalLimitsView = new LimitLayout({
            mode: this.mode,
            collection: this.selectedGroups,
        });

        this.approvalLimitsRegion.show(this.approvalLimitsView);
    },

    buildAssignedPermissionsRegion() {
        const self = this;

        this.assignedPermissionsView = new AssignedPermissions({
            model: this.model,
            mode: this.mode,
        });

        this.listenTo(this.assignedPermissionsView, 'payments:rerender', () => {
            self.render();
        });

        this.assignedPermissionsRegion.show(self.assignedPermissionsView);
    },

    buildRestrictedTemplatesRegion() {
        const simpleDataEnt = this.model.get('simpleDataEntitlements');
        if (this.mode !== constants.MODES.VIEW
            || (!util.isUndefined(simpleDataEnt) && simpleDataEnt.length > 0 && !util.isUndefined(simpleDataEnt.at(0).get('dataEntitlements'))
                && (simpleDataEnt.at(0).get('dataEntitlements').length > 0 || simpleDataEnt.at(0).get('assignAll') === true))) {
            this.restrictedTemplatesView = new RestrictedTemplates({
                mode: this.mode,
                model: this.model.get('simpleDataEntitlements').at(0),
                userGroup: this.model.userGroupModel.get('id'),
                entity: this.entity,
            });

            this.restrictedTemplatesRegion.show(this.restrictedTemplatesView);
        }
    },

    editPaymentTypes() {
        const self = this;

        Dialog.open(new AssignPaymentTypes({
            collection: this.model.get('complexTypes'),
            mode: this.mode,
            modifyMode: true,

            onSave() {
                /*
                 * must reset collections for aggregate groups when types change
                 * this allows the collections to be fetched with new type values
                 */
                const permissionsBy = self.model.userModel ? self.model.userModel.get('GROUPED_PERMISSIONS_BY')
                    : self.model.roleInfo.GROUPED_PERMISSIONS_BY;

                if (permissionsBy === 'PAYMENT_GROUP') {
                    self.model.getSelectedComplexTypes().each((groupParam) => {
                        const group = groupParam;
                        delete group.get('aggregateTypes').availableAccounts;
                    });
                }

                self.render();
            },
        }));
    },

    editPaymentLimits() {
        const self = this;

        Dialog.open(new EditLimitsDialog({
            collection: self.model.get('complexTypes'),
            mode: self.mode,
            modifyMode: true,

            onSave() {
                self.approvalLimitsView.render();
            },
        }));
    },

    templateHelpers() {
        const self = this;
        return {
            isInsertMode() {
                return self.mode === 'insert';
            },

            readOnly: this.mode === constants.MODES.VIEW,
        };
    },
});
