import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import inquiryApi from 'common/inquiry/api';

export default Collection.extend({

    initialize(requestData) {
        this.data = requestData || {};
    },

    sync(method, model, options) {
        const data = inquiryApi.getQueryResults(this.getCollectionOptions(options));

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    getCollectionOptions(options) {
        return util.extend({}, options, {
            includeMapData: 1,
            inquiryId: this.data.inquiryId,
            productCode: 'SMAINT',
            functionCode: 'MAINT',
            typeCode: 'STATSRPT',

        });
    },

    parse(response) {
        const returnArray = [];
        util.each(response, (rowItem) => {
            const obj = {
                CODE: rowItem.name,
                DESCRIPTION: rowItem.label,

            };
            returnArray.push(obj);
        });
        return returnArray;
    },

});
