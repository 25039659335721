import MDF from 'common/dynamicPages/views/mdf/metaDrivenForm';
import hash from 'common/util/promise-hash';
import util from '@glu/core/src/util';
import errorHandlers from 'system/error/handlers';
import entitlements from 'common/dynamicPages/api/entitlements';
import viewHelper from 'common/dynamicPages/api/viewHelper';
/*
 * import autocomplete from "jui/autocomplete";
 * import datePicker from "datePicker";
 */
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import metaDrivenFormTmpl from './metaDrivenForm.hbs';

const MetaDrivenForm = MDF.extend({
    template: metaDrivenFormTmpl,
    loadingTemplate: loadingPageTmpl,

    loadViewRequirements(options) {
        const self = this;

        const modelPromiseSuccessSetup = () => {
            viewHelper.updateModels(self.model, self);
            /*
             * update the model structure
             * set up model promises for model combos,dates, etc
             */
            const promiseHash = viewHelper.getComboPromises(options, self.model);
            /*
             * promiseHash.date = viewHelper.getDatePromises(options, self.model);
             * promiseHash.dateRange = viewHelper.getDateRangePromise(options);
             */
            if (!options.hideButtons) {
                promiseHash.entitlements = entitlements.getEntitlements(options);
            }
            if (options.isChild && self.state === 'insert') {
                promiseHash.childNumber = viewHelper.getChildNumberPromise(options);
            }
            if (self.model.isBatch && self.state === 'insert' && self.context.createdFrom !== '1') {
                promiseHash.resetChildren = viewHelper.resetChildrenPromise(options);
            }
            hash(promiseHash).then((results) => {
                if (!options.hideButtons) {
                    self.entitlements = results.entitlements.actions;
                }
                if (options.isChild && self.state === 'insert') {
                    self.model.childNumber = results.childNumber;
                }

                const comboCollections = {};
                for (let i = 0; i < self.model.comboList.length; i += 1) {
                    let name;
                    if (self.model.comboList[i].inquiryId) {
                        ({ name } = self.model.comboList[i]);
                    } else {
                        name = self.model.comboList[i];
                    }
                    if (!results[`combo-${i}`] || results[`combo-${i}`].queryResponse.QueryData.queryRows === null) {
                        comboCollections[name] = [];
                    } else {
                        comboCollections[name] = results[`combo-${i}`].queryResponse.QueryData.queryRows;
                    }
                }
                self.comboCollections = comboCollections;
                self.setHasLoadedRequiredData(true);
                self.trigger('loaded');
                self.render();
                self.listenTo(self.model, 'change', self.handleModelChangeEvents);
            }, util.bind(errorHandlers.loading, self));
        };
        let copyModel = (model) => {
            self.model = model;
        };

        let modelPromise;
        if (this.reloadingDriverField !== '') {
            this.stopListening(this.model, 'change', this.handleModelChangeEvents);
            modelPromise = viewHelper.reloadModel({
                model: this.model,
            });
            if (this.state === 'view' || this.state === 'modify') {
                copyModel = (model) => {
                    self.model.jsonData = model.jsonData;
                    self.model.fieldData = model.fieldData;
                    self.model.validators = model.validators;
                };
            }

            this.reloadingDriverField = '';
        } else {
            let foundOne = false;
            if ((this.state === 'view' || this.state === 'modify') && this.model && !util.isEmpty(this.model.driverFields)) {
                const elementFunc = element => !util.isEmpty(self.model.get(element));
                foundOne = this.model.driverFields.some(elementFunc);
            }
            if (foundOne) {
                modelPromise = viewHelper.reloadModel({
                    model: this.model,
                });
                copyModel = (model) => {
                    self.model.jsonData = model.jsonData;
                    self.model.fieldData = model.fieldData;
                    self.model.validators = model.validators;
                };
            } else {
                modelPromise = viewHelper.getModel(options);
            }
        }

        modelPromise.then((model) => {
            copyModel(model);
            modelPromiseSuccessSetup();
        }, util.bind(errorHandlers.loading, self));
    },

});

export default MetaDrivenForm;
