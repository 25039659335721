/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import {
  useEffect
} from 'react';
import isEqual from 'lodash/isEqual';
import { defaultSubRowControlColumnDef } from './subRowControlColumn';

const updatePaging = (api, setVisibleItems, prevRowCount) => {
  setVisibleItems(api.paginationGetRowCount());

  const currRowCount = api.getDisplayedRowCount();
  // show label if no rows
  if (prevRowCount !== currRowCount && currRowCount === 0) {
    api.showNoRowsOverlay();
  }
  // hide 'no rows' label
  if (prevRowCount !== currRowCount && prevRowCount === 0) {
    api.hideOverlay();
  }
};

export const useAddSubRows = (
  {
    SubRow,
    classes,
    columnDefs,
    columnHasSubRowContent,
    data
  },
  capturedColumnApi
) => {
  useEffect(() => {
    if (!capturedColumnApi) return;

    const addSubRowCol = (columnHasSubRowContent || SubRow) && !columnDefs.find(
      (col) => col.colId === defaultSubRowControlColumnDef.colId
    );

    const allColsAreHidden = data.columns && data.columns.every((col) => col.hide);

    /* istanbul ignore next */
    const columns = addSubRowCol && !allColsAreHidden
      ? [
        ...(addSubRowCol
          ? [{
            ...defaultSubRowControlColumnDef,
            cellClass: classes.subRowControl,
            cellRendererParams: { SubRow },
            hide: false
          }]
          : []
        ),
        ...data.columns
      ] : data.columns; // eslint-disable-line prefer-destructuring

    if (columns && columns.length && !isEqual(columns, capturedColumnApi.getColumnState())) {
      capturedColumnApi.setColumnState(columns);
    }
  }, [SubRow, classes.subRowControl,
    columnHasSubRowContent, data.columns, capturedColumnApi, columnDefs]);
};

export const useChangeFilters = ({
  convertFilters, data, records, setVisibleItems
}, gridApi) => {
  useEffect(() => {
    const converted = convertFilters(data.filters);

    if (gridApi && !isEqual(converted, gridApi.getFilterModel())) {
      const prevRowCount = gridApi.getDisplayedRowCount();

      gridApi.setFilterModel(converted);
      gridApi.onFilterChanged();

      updatePaging(gridApi, setVisibleItems, prevRowCount);
    }
  }, [convertFilters, data.filters, gridApi, setVisibleItems, records]);
};

export const useResizeColumns = ({ sizeColumnsToFit }, gridApi, gridReady) => {
  useEffect(() => {
    // functionality covered with tests,
    // but not registering with coverage report
    /* istanbul ignore next */
    const resize = () => {
      if (gridApi && sizeColumnsToFit && gridReady) {
        gridApi.sizeColumnsToFit();
      }
    };
    if (gridApi && sizeColumnsToFit && gridReady) {
      gridApi.sizeColumnsToFit();
      window.addEventListener('resize', resize);
    }
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [gridApi, sizeColumnsToFit, gridReady]);
};

export const useSetColumns = ({ data }, gridContext) => {
  useEffect(() => {
    gridContext.setColumns(data.columns);
  }, [data.columns]);
};

export const useSetCurrentPage = ({ data, serverSidePagination }, gridApi) => {
  useEffect(() => {
    if (serverSidePagination) {
      return;
    }
    if (gridApi && data.page !== gridApi.paginationGetCurrentPage()) {
      if (data.page) {
        gridApi.paginationGoToPage(data.page);
      } else {
        gridApi.paginationGoToFirstPage();
      }
    }
  }, [data.page, gridApi, serverSidePagination]);
};

export const useSetFilters = ({ data }, gridContext) => {
  useEffect(() => {
    gridContext.setFilters(data.filters);
  }, [data.filters]);
};

export const useSetPageSize = ({ data, serverSidePagination }, gridApi) => {
  useEffect(() => {
    if (gridApi && data.pageSize !== gridApi.paginationGetPageSize()) {
      gridApi.paginationSetPageSize(data.pageSize);
    }
  }, [data.pageSize, gridApi, serverSidePagination]);
};

export const useSort = ({ data }, gridApi) => {
  useEffect(() => {
    if (!gridApi) return;

    const sort = data.sort; // eslint-disable-line prefer-destructuring
    const gridSort = gridApi.getSortModel();

    if (!isEqual(sort, gridSort)) {
      gridApi.setSortModel(sort);
    }
  }, [data.sort, gridApi]);
};

export const useUpdatePaging = ({ records, setVisibleItems }, gridApi) => {
  useEffect(() => {
    if (gridApi) {
      updatePaging(gridApi, setVisibleItems);
    }
  }, [gridApi, setVisibleItems, records]);
};
