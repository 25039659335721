import Layout from '@glu/core/src/layout';
import loans from 'app/loans/api/common';
import ListView from 'app/smbPayments/views/accounts/balances/listItemCollectionView';
import listControlsViewTmpl from './listControlsView.hbs';

export default Layout.extend({
    template: listControlsViewTmpl,
    className: 'list-controls',

    regions: {
        listingRegion: '.listing-region',
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.listView = new ListView({
                collection: this.collection,
                loansPrefs: this.loansPrefs,
            });
            this.listingRegion.show(this.listView.render());
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        loans.getPaymentPreferences().then((loansPrefs) => {
            this.loansPrefs = loansPrefs;
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },
});
