import ListView from 'common/dynamicPages/views/workflow/list';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import services from 'services';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import util from '@glu/core/src/util';
import workspaceHelper from 'common/workspaces/api/helper';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './largeBatchView.hbs';

const EXPORTID = '29520';

const LargeBatch = ListView.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'PMTS',
            serviceName: '/batch/largeBatch',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'LARGE_BATCH_LISTVIEW',
            returnRoute: 'BATCH/largeBatch',
            viewId: options.viewId,
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        if (options.viewId) {
            this.markLargeBatchAsRead();
        }
    },

    markLargeBatchAsRead() {
        http.post(services.generateUrl('/notifications/accessAchLargeBatchNotifications'), {});
    },

    loadViewRequirements() {
        const self = this;
        const promise = ListView.prototype.loadViewRequirements.call(this);

        return promise.then(() => {
            self.setHasLoadedRequiredData(true);
        });
    },

    export() {
        this.buildExportModel('CSV');
        this.doExport();
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: 'ACH.largebatch.report',
        });
        dialog.custom(printModal);
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = EXPORTID;
    },
});

let list = LargeBatch;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'LARGEBATCH_LIST',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
