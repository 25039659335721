import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import ServiceModel from 'app/administration/models/service';
import services from 'services';

export default Collection.extend({

    model: ServiceModel,
    initialize(options) {
        // smb 0 , corp 1
        this.state = options.state;
        this.segmentName = options.segmentName || '';

        this.data = {
            segmentName: this.segmentName,
            sophistication: this.state,
        };
    },

    sync(method, collection, options) {
        const url = services.generateUrl('/segmentation/services/getServices');

        http.post(url, this.data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});
