var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"ContractIdLookup-button-wrapper\">\n            <button class=\"btn btn-secondary ContractIdLookup-button\" \n                data-hook=\"getExchangeRateButton\"\n                data-action=\"retrieveExchangeRate\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.ValidateContractService.GetRate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":65}}}))
    + "\n            </button>\n        </div>\n        <div class=\"ContractIdLookup-dynamic-content-wrapper\">\n            <div class=\"ContractIdLookup-loader-wrapper\" data-hook=\"loadingSpinnerContainer\">\n                <div class=\"loading-indicator-view\"><span></span></div>\n            </div>\n            <div class=\"form-group\" data-hook=\"exchangeRateContainer\">\n                <label for=\"exchangeRate-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":25,"column":41},"end":{"line":25,"column":48}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.Exchange_Rate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":50},"end":{"line":25,"column":81}}}))
    + "</label>\n                <p class=\"form-control-static read-only-content ContractIdLookup-exchange-rate\">\n                    <span\n                        data-text=\"exchangeRate\"\n                        data-bind=\"model\"\n                        name=\"exchangeRate\"\n                        data-hook=\"getExchangeRate\"></span>\n                    <span class=\"currency-field\" data-bind=\"model\" name=\"currency\"></span>\n                </p>\n            </div>\n            <div class=\"ContractIdLookup-message-block\" data-hook=\"errorMessageContainer\">\n                <div class=\"has-error\">\n                    <span class=\"help-block\" data-hook=\"errorMessage\"></span>\n                </div>\n                <div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"exchangeRate\"></span>\n                </div>\n            </div>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group ContractIdLookup-manual-exchange "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRequiredIndicator") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":64},"end":{"line":45,"column":108}}})) != null ? stack1 : "")
    + "\">\n            <label for=\"exchangeRate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":46,"column":37},"end":{"line":46,"column":44}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGS.Exchange_Rate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":46},"end":{"line":46,"column":77}}}))
    + "</label>\n            <input\n                class=\"form-control text-right\"\n                name=\"exchangeRate\"\n                data-hook=\"getExchangeRate\"\n                data-bind=\"model\"\n                id=\"exchangeRate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":52,"column":33},"end":{"line":52,"column":40}}}) : helper)))
    + "\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"exchangeRate\"></span>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row ContractIdLookup-wrapper\">\n    <div class=\"form-group ContractIdLookup-contract-id-wrapper "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRequiredIndicator") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":64},"end":{"line":2,"column":108}}})) != null ? stack1 : "")
    + "\">\n        <label for=\"contractID-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":38}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGS.screentext.Contract ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":80}}}))
    + "</label>\n        <input class=\"form-control text-right\" maxlength=\"16\" name=\"contractId\" data-bind=\"model\" id=\"contractID-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":113},"end":{"line":4,"column":120}}}) : helper)))
    + "\">\n        <span class=\"help-block ContractIdLookup-help-block\" data-bind=\"model\" data-validate=\"contractId\"></span>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"getExchangeRateFromFXSystem") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":55,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});