import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import gridApi from 'common/dynamicPages/api/grid';
import MDFMultiItemView from './mdfMultiItemView';
import MultiAddCollectionView from './multiAddCollectionView';

export default MultiAddCollectionView.extend({
    itemView: MDFMultiItemView,

    /**
     * @name itemViewOptions
     * @description returns the options for the multi-add item view
     * @param model
     * @returns {{Object of options for the item view}}
     */
    itemViewOptions(model) {
        return {
            model,
            itemIndex: this.collection.models.length,
            context: this.contextDef,
            state: this.mode,
            hideButtons: true,
            gridApi,
            preFill: store.get(`${this.contextKey}-preFill`),
            smbOverride: null,
            reimburse: null,
        };
    },

    // TODO do we need this here if it can get its base class itemEvents
    itemEvents: {
        /**
         * @name destroy
         * @description event handler for the remove item.  If there is only one
         * model it gets
         * cleared; otherwise,
         * the model is removed from the collection
         * @param event
         * @param itemView
         */
        destroy(event, itemView) {
            // if first item, then just clear model; otherwise, remove model
            if (this.collection.models.length === 1) {
                this.collection.at(0).clear();
            } else {
                this.collection.remove(itemView.model);
            }
        },
    },

    initialize(options) {
        MultiAddCollectionView.prototype.initialize.call(this, options);
        this.contextDef = this.parentView.contextDef;
        this.contextKey = this.parentView.contextKey;
        this.mode = this.parentView.mode;
        this.listenTo(this.collection, 'add remove', this.collectionUpdated.bind(this));
    },

    collectionUpdated() {
        let state;
        if (this.parentView.maxRows > 0) {
            state = this.collection.length >= this.parentView.maxRows;
            this.parentView.disableAddButton(state);
        }
    },

    /**
     * @name updateButtonLabel
     * @description updates the remove button class based on the number of models
     * in the collection
     */
    updateButtonLabel() {
        const onlyOne = this.collection.models.length === 1;
        this.$('[data-hook="getRemoveButton"]').toggleClass('hide', onlyOne);
        this.$('[data-hook="getClearButton"]').toggleClass('hide', !onlyOne);
    },

    /**
     * @name updateTotalsAndButtonLabels
     * @description updates the remove button class based on the number of models
     * in the collection and
     * updates the total item count displayed on the page
     */
    updateTotalsAndButtonLabel() {
        this.updateButtonLabel();
        this.updateItemTotals();
    },

    /**
     * @name onAfterItemAdded
     * @description (marionette) event handler called after an item (model) has
     * been added to the collection
     * We update the totals to reflect the new value of items in the collection
     * and update the remove button class
     * This method will also set focus to the first added item's first focusable field
     */
    onAfterItemAdded(itemView) {
        let focusSelector;
        this.updateTotalsAndButtonLabel();
        if (this.parentView.newFocusItem) {
            // find first focusable field
            focusSelector = this.getFocusSelector(this.findFirstFocusableField());
            itemView.listenToOnce(itemView.mdfView, 'ui-loaded', function (selector) {
                this.$(selector).focus();
            }.bind(itemView.mdfView, focusSelector));
            this.parentView.newFocusItem = null;
        }
    },

    /**
     * @name onItemRemoved
     * @description (marionette) event handler called after an item (model) has
     * been removed from the collection
     * We update the totals to reflect the new value of items in the collection
     * and update the remove button class
     */
    onItemRemoved() {
        this.updateTotalsAndButtonLabel();
    },

    /**
     * @name findFirstFocusableField
     * @description searches through the fieldData list of fields for the first
     * focusable field
     * @returns {object} representing the first focusable field
     */
    findFirstFocusableField() {
        const model = this.collection.at(this.parentView.newFocusItem);
        const focusField = util.chain(model.fieldData)
            .filter(fld => fld.visible)
            .filter(fld => fld.fieldUIOrder)
            .map(fld => ({
                name: fld.name,
                order: fld.fieldUIOrder,
                type: fld.fieldUIType,
            }))
            .reduce(
                (memo, fld) => (fld.order < memo.order ? fld : memo),
                {
                    name: '',
                    order: 999,
                    type: '',
                },
            )
            .value();
        return focusField;
    },

    /**
     * @name getFocusSelector
     * @description for the input focusField, determines the correct jquery selector
     * @param focusField
     * @returns {string} jQuery selector for the field that will get focus
     */
    getFocusSelector(focusField) {
        // TODO expand to other field types if needed
        switch (focusField.type) {
        case 'TEXTBOX':
            return `[name="${focusField.name}"]`;
        case 'COMBOBOX':
            return `[id$="_${focusField.name}"] input.select2-focusser`;
        default:
            return `[name="${focusField.name}"]`;
        }
    },
});
