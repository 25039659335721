import classNames from 'classnames';
import { withStyles } from '@glu/theming';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Drawer.styles';

const propTypes = {
  /** Controls whether the drawer is open or collapsed */
  open: PropTypes.bool.isRequired,
  /** Drawer content */
  children: PropTypes.node.isRequired,
  /** Optional className for styling */
  className: PropTypes.string,

  /* eslint-disable react/no-unused-prop-types */
  // used by styles
  /** Side of the screen to anchor the drawer to */
  anchorTo: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /** Duration of the expand animation */
  expandDuration: PropTypes.number,
  /** Duration of the collapse animation */
  collapseDuration: PropTypes.number,
  /** The animation transition timing function value */
  transitionMode: PropTypes.string,
  /** Size (height or width based on anchorTo value) of content when expanded */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Size of content when collapsed (width to animate from) */
  startingSize: PropTypes.number,

  /** Internal classes hash - do not pass */
  classes: PropTypes.objectOf(PropTypes.string).isRequired
};
const defaultProps = {
  anchorTo: 'left',
  className: undefined,
  expandDuration: 0.5,
  collapseDuration: 0.3,
  transitionMode: 'ease-in-out',
  size: 350,
  startingSize: 0
};

const Drawer = ({
  classes, children, open, className
}) => (
  <div className={classNames(classes.root, className, { [classes.open]: open })}>
    <div className={classNames(classes.content)}>
      {children}
    </div>
  </div>
);

Drawer.propTypes = propTypes;
Drawer.defaultProps = defaultProps;

export const DrawerBase = Drawer;
export default withStyles(styles)(Drawer);
