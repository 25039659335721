import Layout from '@glu/core/src/layout';
import CategoryService from '../service/category';
import PlanCollection from '../collection/plan';
import CompareLayout from './compareLayout';
import compareSurrogateLayoutTmpl from './compareSurrogateLayout.hbs';

const CompareSurrogateLayout = Layout.extend({
    className: 'widget-plan-compare-view widget',
    template: compareSurrogateLayoutTmpl,

    regions: {
        pageRegion: '#region',
    },

    initialize() {
        this.plans = null;
        this.service = new CategoryService();
        this.getPlans().then(plans => this.initViews(plans));
    },

    getPlans() {
        return new Promise((resolve) => {
            const plans = new PlanCollection(
                {},
                {
                    categoryService: this.service,
                },
            );
            plans.once('sync', resolve);
        });
    },

    initViews(plans) {
        this.plans = plans;
        this.show();
    },

    show() {
        if (!this.plans) {
            return;
        }

        const view = new CompareLayout({
            plans: this.plans,
            categoryservice: this.service,
        });

        this.pageRegion.show(view);
    },
});

export default CompareSurrogateLayout;
