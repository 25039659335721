import Layout from '@glu/core/src/layout';
import ForgottenPasswordApi from 'system/webseries/api/forgottenPassword';
import Challenge from 'system/login/views/challenge';
import PasswordReset from 'system/login/views/passwordReset';
import validation from 'validation';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import resetPasswordTmpl from './resetPassword.hbs';

export default Layout.extend({
    template: resetPasswordTmpl,
    className: 'user-initiated-password-reset login floaty-box',
    attributes: {
        role: '',
    },

    ui: {
        $regions: '.regions',
        $successMessage: '.success-message',
        $goToLoginBtn: '[data-hook="getGoToLoginBtn"]',
    },

    regions: {
        alertRegion: '[data-region="alertRegion"]',
    },

    events: {
        'click @ui.$goToLoginBtn': 'goToLoginScreen',
    },

    initialize(options) {
        // check if error message is populated and if so run error function
        if (options.errorMessage) {
            this.errorMessage = options.errorMessage;
        } else {
            this.userCreds = {
                userId: options.userId,
                userGroup: options.userGroup,
            };
            this.hasUserEstablishedSecurityQuestions = (
                !util.isNullOrUndefined(options.hasUserEstablishedSecurityQuestions)
                && options.hasUserEstablishedSecurityQuestions
            );
        }
    },

    onRender() {
        if (this.errorMessage) {
            // show alert message and don't proceed
            this.alertRegion.show(alert.danger(this.errorMessage));
        } else if (this.hasUserEstablishedSecurityQuestions) {
            /**
             * Prompt the user for the answers to the security questions.
             * Upon successful validation, prompt to reset the password.
             * Successful reset will take the use to the login page.
             */
            this.getChallengeQuestions();
        } else {
            /**
             * If no security questions, prompt for password reset first.
             * Then when the user logs in with the new password,
             * it will trigger the prompt to enter security questions.
             */
            this.viewResetPassword();
        }
    },

    getChallengeQuestions() {
        ForgottenPasswordApi.getChallengeQuestions({
            userId: this.userCreds.userId,
            userGroup: this.userCreds.userGroup,
        }).done((res) => {
            if (res.enrollmentStatus) {
                const challenge = new Challenge({
                    questions: res.data.items,
                    title: locale.get('common.security.title'),
                    notModal: true,
                    user: {
                        realm: this.userCreds.userGroup,
                        name: this.userCreds.userId,
                    },
                });

                this.challengeQuestionsRegion.show(challenge);

                challenge.on('save', () => {
                    this.viewResetPassword();
                    this.challengeQuestionsRegion.close();
                });

                challenge.on('cancel', () => {
                    this.goToLoginScreen();
                });
            } else {
                this.alertRegion.show(alert.warning(locale.get('logon.forgotten.system.error')));
            }
        });
    },

    viewResetPassword() {
        const changePassword = new PasswordReset({
            realm: this.userCreds.userGroup,
            name: this.userCreds.userId,
            isForgottenPassword: true,
            notModal: true,
        });

        changePassword.on('cancel', () => {
            this.goToLoginScreen();
        });

        validation.reset(this);
        this.passwordResetRegion.show(changePassword);
        this.listenTo(this.appBus, 'forgottenPassword:reset:success', this.resetSuccess);
        this.$el.addClass('out');
        this.ui.$regions.addClass('out');
    },

    resetSuccess() {
        validation.reset(this);
        this.ui.$regions.addClass('final');
        this.passwordResetRegion.close();
    },

    goToLoginScreen() {
        /**
         * using replace for security reasons as we don't
         * want the user to be able to go back to the
         * security questions or reset password screens
         */
        document.location.replace(window.Bottomline.appRoot);
    },
});
