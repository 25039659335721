import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

const ADMIN_INQUIRYID = '25086';
const CLIENT_INQUIRYID = '25090';
const ADMIN_SINGLE_INQUIRYID = '25094';

export default Collection.extend({
    initialize(options = {}) {
        this.buildAction(options);
        if (options.isAdmin) {
            this.inquiryId = (options.userGroup) ? ADMIN_SINGLE_INQUIRYID : ADMIN_INQUIRYID;
            this.singleUserGroup = options.userGroup;
        } else {
            this.inquiryId = CLIENT_INQUIRYID;
        }
    },
    sync(method, model, options) {
        let customFilters = [];
        /**
         * when admin and creating a user from the admin company page,
         * only retrieve data for that company
         */
        if (this.singleUserGroup) {
            customFilters = [{
                filterName: 'Depends',
                filterParam: ['USERGROUP', this.singleUserGroup],
            }];
        }
        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                inquiryId: this.inquiryId,
                action: this.action,
                queryType: 'SearchQuery',
                customFilters,
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 1,
                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },
                    userId: '',
                },
                requestId: '',
            },
        };

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    buildAction(options) {
        this.action = {};

        if (options && options.isAdmin) {
            this.action = {
                typeCode: 'CLUSR_TM',
                entryMethod: 0,
                actionMode: 'INSERT',
                functionCode: 'MAINT',
                productCode: 'CMAINT',
            };
        } else {
            this.action = {
                typeCode: '_USER',
                entryMethod: 0,
                actionMode: 'INSERT',
                functionCode: 'USERS',
                productCode: '_ADMIN',
            };
        }
    },

    parse(response) {
        return util.map(response, (rowItem) => {
            const mapTmpItem = transform.pairsToHash(rowItem.mapDataList, 'toField', 'value');

            return {
                id: rowItem.name ? rowItem.name : rowItem.label,
                label: rowItem.label,
                parentUserGroup: mapTmpItem.ParentUserGroup,
                isMobile: mapTmpItem.UGMobile === '1',
                isMobileHybrid: mapTmpItem.UGMobileHybrid === '1',
                timezone: mapTmpItem.TimeZoneID,
                locale: mapTmpItem.Locale,
                isSMB: mapTmpItem.MARKETSEGMENT === 'SMB',
                allowSelfAdmin: mapTmpItem.ALLOWSELFADMINISTRATION === '1',
                allowAccountUnmasking: mapTmpItem.ALLOWACCOUNTUNMASKING === '1',
                usachApproveOwnImport: mapTmpItem.ACHALLOWSNGLADMINPAYIMPORT === '1',
                usachApproveOwnPayment: mapTmpItem.ACHALLOWSNGLADMINPAYFROMTMPL === '1',
                usachApproveOwnTemplate: mapTmpItem.ACHALLOWSNGLADMINTMPL === '1',
                rtgsApproveOwnImport: mapTmpItem.RTGSALLOWSNGLADMINPAYIMPORT === '1',
                rtgsApproveOwnPayment: mapTmpItem.RTGSALLOWSNGLADMINPAYFROMTMPL === '1',
                rtgsApproveOwnTemplate: mapTmpItem.RTGSALLOWSNGLADMINTMPL === '1',
                applicationSuperUserInd: mapTmpItem.UGApplicationSuperUserInd,
                superUserInd: mapTmpItem.UGSuperUserInd,
                allowConfidentialPayments: mapTmpItem.UGAllowConfidentialPayments === '1',
                optUISecurityEnabled: mapTmpItem.OPTUISECURITYENABLED === '1',
                optUISecurityCheck: mapTmpItem.OPTUISECURITYCHECK === '1',
                optUISecurityReason: mapTmpItem.OPTUISECURITYREASON,
                hasPanelApproval: mapTmpItem.PaymentApprovalType === '1',
                enablePushNotifications: mapTmpItem.ENABLEPUSHNOTIFICATIONS === '1',
                isUce: mapTmpItem.USERPERMISSIONSETTINGS === '3',
                allowSSO: mapTmpItem.ALLOWSSO === '1', // PCM
                mapDataList: rowItem.mapDataList,
            };
        });
    },
});
