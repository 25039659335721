var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.companies",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":44}}}))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.bankAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":54},"end":{"line":2,"column":83}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        <span class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":21},"end":{"line":10,"column":72}}})) != null ? stack1 : "")
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.advancedAccountPermissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":74},"end":{"line":10,"column":117}}}))
    + "</span>\n            <div class=\"btn-group\" role=\"group\" aria-label=\"Set of buttons\">\n                <button class=\"btn btn-secondary\" data-hook=\"showBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.show",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":70},"end":{"line":12,"column":91}}}))
    + "</button>\n                <button class=\"btn btn-secondary active\" data-hook=\"hideBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.hide",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":77},"end":{"line":13,"column":98}}}))
    + "</button>\n            </div>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"btn btn-tertiary\" data-hook=\"quickLimitsBtn\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.quicklyApplyLimits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":87},"end":{"line":8,"column":122}}}))
    + "</button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "advanced-permissions";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n<h4>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isACH") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":90}}})) != null ? stack1 : "")
    + "</h4>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"currentFuture") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "<div class=\"grid accounts-grid\"></div>\n\n";
},"useData":true});