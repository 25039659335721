import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import AdminDataApi from 'app/administration/common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminPayment/listView/payments',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    loadRequiredData() {
        const self = this;

        this.contextDef.serviceName = 'adminPayment/listView/payments';
        this.localeKey = 'adminPayment_';

        AdminDataApi.model.generateModelFromModel(store.get(`${this.contextKey}-actionModel`)).then((newModelParam) => {
            const newModel = newModelParam;
            newModel.set('PRODUCTCODE', newModel.get('PRODUCT'));
            newModel.set('FUNCTIONCODE', newModel.get('FUNCTION'));
            newModel.set('TYPECODE', newModel.get('TYPE'));
            newModel.set('SUBTYPE', '*');

            newModel.key.PRODUCTCODE = 'true';
            newModel.key.FUNCTIONCODE = 'true';
            newModel.key.TYPECODE = 'true';
            newModel.key.SUBTYPE = 'true';
            newModel.fetch({
                success() {
                    self.storedModel = newModel;
                    self.setHasLoadedRequiredData(true);
                    self.render();
                },

                error: util.bind(errorHandlers.loading, self),
            });
        });
    },

    cancel() {
        window.history.go(-1);
    },
});
