/*
 *   @constructor RadioButton
 */
const RadioButton = function (domNode, groupObj) {
    this.domNode = domNode;
    this.radioGroup = groupObj;

    this.keyCode = Object.freeze({
        RETURN: 13,
        SPACE: 32,
        END: 35,
        HOME: 36,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
    });
    this.init();
};

RadioButton.prototype.init = function () {
    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
    this.domNode.addEventListener('click', this.handleClick.bind(this));
};

RadioButton.prototype.setChecked = function (isChecked, event) {
    this.domNode.setAttribute('aria-checked', 'false');
    this.domNode.setAttribute('tabindex', '-1');
    this.domNode.removeAttribute('checked');
    this.domNode.checked = false;
    if (isChecked) {
        this.domNode.setAttribute('aria-checked', 'true');
        this.domNode.setAttribute('tabindex', '0');
        this.domNode.checked = true;
        this.domNode.focus();
        if (event?.type === 'click') {
            this.domNode.blur();
        }
    }
};

/* EVENT HANDLERS */

RadioButton.prototype.handleKeydown = function (event) {
    let flag = true;

    switch (event.keyCode) {
    case this.keyCode.SPACE:
    case this.keyCode.RETURN:
        this.radioGroup.setChecked(this, event);
        break;

    case this.keyCode.UP:
    case this.keyCode.LEFT:
        this.radioGroup.setCheckedToPreviousItem(this, event);
        break;

    case this.keyCode.DOWN:
    case this.keyCode.RIGHT:
        this.radioGroup.setCheckedToNextItem(this, event);
        break;

    default:
        flag = false;
        break;
    }

    if (flag) {
        event.stopPropagation();
        event.preventDefault();
    }
};

RadioButton.prototype.handleClick = function (event) {
    this.radioGroup.setChecked(this, event);
};

export default RadioButton;

