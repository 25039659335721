import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import sbPaymentsApi from 'app/smb/api';

export default Model.extend({
    defaults: {
        ACCOUNT: '',
        PAYDATE: '',
    },

    initialize() {
        this.validators = {
            ACCOUNT: {
                description: locale.get('smbPayments.account'),
                exists: true,
            },

            PAYDATE: {
                description: locale.get('smbPayments.pay.date'),
                exists: true,
                matches: sbPaymentsApi.DATE_PATTERN,
            },
        };
    },
});
