import appConfigParams from 'system/webseries/models/applicationConfiguration';

export default {
    isBankMaintained(options = {}) {
        // for uce based users, always return false
        if (options.isUce) {
            return false;
        }

        const bankMaintained = appConfigParams.getValue('ROLE', 'bankMaintainedRoles') === '1';
        // if not bankMaintained return false
        if (!bankMaintained) {
            return false;
        }

        /*
         * if client and role base and bankMaintained or
         *     is admin and role based and bankMaintained then return true
         */
        return true;
    },
};
