import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import transform from 'common/util/transform';

export default Collection.extend({
    initialize(models, options) {
        this.name = options.name;
    },

    sync(method, model, options) {
        const data = {
            IncludeMapData: '1',

            queryCriteria: {
                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'Name',
                        this.name,
                    ],
                }],

                inquiryId: '20496',

                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    productCode: 'PAY',
                    actionMode: 'INSERT',
                    functionCode: 'INST',
                },

                queryType: 'SearchQuery',
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 1,
                requestId: '',
            },
        };

        return http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        return util.map(response, (rowItem) => {
            const templateObj = transform.pairsToHash(rowItem.mapDataList, 'toField', 'value');
            templateObj.creditDebit = templateObj.CMB_DBCR_Indicator;
            templateObj.tranAmount = templateObj.CMB_Transaction_Amount;
            templateObj.debitAccount = templateObj.CMB_Debit_Account_Number;
            templateObj.debitAccountDisplay = templateObj.CMB_Debit_Account_Number_Disp;

            return {
                TEMPLATE_ID: rowItem.name,
                text: `${templateObj.CMB_Template_Code} • ${templateObj.CMB_Bene_Name} • ${templateObj.CMB_Type_Description}`,
                groupName: rowItem.label,
                isTemplateGroup: false,
                TEMPLATE: new Model(templateObj),
            };
        });
    },
});
