import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import asOfTimeCellViewTmpl from './asOfTimeCellView.hbs';

const AsOfTimeCellView = ItemView.extend({
    template: asOfTimeCellViewTmpl,
    className: 'as-of-time',

    initialize() {
        this.listenTo(this.appBus, 'grid:resizeManageColumnGear', this.renderRealTimeWarning);
    },

    /**
     * Called at the end of the drag event handler for the column. We re-render
     * the cell to workaround a Chrome
     * specific issue prevening the warning font-icon from rendering while the
     * width of the column covers the
     * element
     * method: renderRealTimeWarning
     */
    renderRealTimeWarning() {
        if (this.model.get('REALTIMESTATUS') === '0') {
            this.render();
        }
    },

    onRender() {
        this.$el.toggleClass('has-realTimePlatform-warning', this.model.get('REALTIMESTATUS') === '0');
    },

    templateHelpers() {
        const isRealTimeAccount = this.model.get('ISREALTIMEACCOUNT');
        const realTimeStatus = this.model.get('REALTIMESTATUS');
        let realTimeMessage = this.model.get('REALTIMEMESSAGE');
        if (util.isString(realTimeMessage)) {
            realTimeMessage = realTimeMessage.replace(/\./g, '').trim();
        }

        return {
            asOfTime: this.model.get('ASOFTIME'),
            hasWarning: isRealTimeAccount === '1' && realTimeStatus === '0' && util.isString(realTimeMessage) && realTimeMessage.length > 0,
            warningText: realTimeStatus === '0' ? realTimeMessage : locale.get('gir.realtimeBalances.error'),
        };
    },
});

export default AsOfTimeCellView;
