import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import Dialog from '@glu/dialog';
import locale from '@glu/locale';
import template from './transferRateDialog.hbs';
import ContractIdLookup from './contractIdLookup';

export default Layout.extend({
    template,
    behaviors: {
        ValidationSupplement: {},
    },

    initialize() {
        this.formModel = this.options.formModel;
        this.model = new Model();

        this.dialogTitle = locale.get('RTGS.AddContractID.title');
        this.modalClass = 'modal-md';

        this.dialogButtons = [{
            text: locale.get('RTGS.ValidateContractService.Apply'),
            className: 'btn-primary',
            callback: 'save',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn-secondary',
            callback: 'cancel',
        }];
    },

    onRender() {
        this.contractIdLookupView = new ContractIdLookup({
            model: this.model,
            formModel: this.formModel,
        });
        this.contractIdLookupRegion.show(this.contractIdLookupView);
    },

    /**
     * attempt to save the modal and store the entered values
     */
    save() {
        if (this.model.isValid()) {
            this.options.handler(this.model.get('exchangeRate'), this.model.get('contractId'));
            Dialog.close();
        } else if (this.contractIdLookupView.getExchangeRateFromFXSystem) {
            this.contractIdLookupView.ui.$errorMessageContainer.show();
        }
    },

    cancel() {
        this.contractIdLookupView.removeValidators();
        Dialog.close();
    },
});
