var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"sections") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":48},"end":{"line":94,"column":57}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"panel panel-primary\">\n                                                        <div class=\"panel-heading\" role=\"tab\" id=\"heading"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":65,"column":105},"end":{"line":65,"column":113}}}) : helper)))
    + "\">\n                                                                <h2 class=\"panel-title\">\n                                                                        <a class=\"collapsed\" role=\"button\" data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":67,"column":162},"end":{"line":67,"column":170}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"collapse"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":67,"column":217},"end":{"line":67,"column":225}}}) : helper)))
    + "\">\n                                                                         <span class=\"indicator-icon\"></span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":68,"column":110},"end":{"line":68,"column":125}}}) : helper)))
    + "\n                                                                        </a>\n                                                                </h2>\n                                                                <div class=\"service-count\">\n                                                                        <span class=\"value\"><span class=\"count\"></span> of <span class=\"total\"></span></span>\n                                                                        <span class=\"name\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"segment.selectedServicesText",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":91},"end":{"line":73,"column":132}}}))
    + "</span>\n                                                                </div>\n                                                        </div> <!-- /.panel-heading -->\n\n                                                         <div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":77,"column":66},"end":{"line":77,"column":74}}}) : helper)))
    + "\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"heading"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":77,"column":148},"end":{"line":77,"column":156}}}) : helper)))
    + "\">\n                                                                <div class=\"panel-body\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"groups") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":72},"end":{"line":90,"column":81}}})) != null ? stack1 : "")
    + "                                                                </div> <!-- /.panel-body -->\n                                                        </div>\n                                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":80},"end":{"line":82,"column":87}}})) != null ? stack1 : "")
    + "                                                                                <ul data-total-field=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"fields") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fields") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":88},"end":{"line":88,"column":97}}})) != null ? stack1 : "")
    + "                                                                                </ul>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                        <h5>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":81,"column":92},"end":{"line":81,"column":107}}}) : helper)))
    + "</h5>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":91},"end":{"line":87,"column":98}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                                                   <li>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":86,"column":103},"end":{"line":86,"column":118}}}) : helper)))
    + "</li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"btn btn-primary submit\"  data-action=\"edit\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.edit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":103,"column":97},"end":{"line":103,"column":126}}}))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-primary submit\"  data-action=\"approve\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":106,"column":104},"end":{"line":106,"column":136}}}))
    + "</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"btn btn-primary submit\"  data-action=\"delete\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":109,"column":103},"end":{"line":109,"column":134}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"segmentation\">\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":62}}}))
    + "</h1>\n    </div>\n\n    <section class=\"section section-container\" role=\"navigation\">\n\n\n        <div class=\"section-body\">\n            <ul class=\"NavTabs nav nav-tabs is-open\" data-hook=\"getNavTabs\" role=\"tablist\">\n                <li class=\"NavTabs-item is-active\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/viewServices\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":58},"end":{"line":14,"column":92}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/viewProfile\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.defaults",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":91}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/addPaymentLimits\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":62},"end":{"line":26,"column":94}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/externalWidgets\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.bankwidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":61},"end":{"line":32,"column":98}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/elearning\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.elearning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":55},"end":{"line":38,"column":90}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n\n                        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n            <div class=\"row\">\n                <div class=\"col-4\">\n                                        <h4 class=\"pseudo-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segmentation.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":65},"end":{"line":47,"column":95}}}))
    + "</h4>\n                                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":48,"column":71},"end":{"line":48,"column":79}}}) : helper)))
    + "</p>\n                                </div>\n                                <div class=\"col-8\">\n                                        <h4 class=\"pseudo-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.segmentation.business.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":65},"end":{"line":51,"column":119}}}))
    + ":</h4>\n                                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"businessType") || (depth0 != null ? lookupProperty(depth0,"businessType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"businessType","hash":{},"data":data,"loc":{"start":{"line":52,"column":71},"end":{"line":52,"column":87}}}) : helper)))
    + "</p>\n                                </div>\n            </div>\n\n\n                        <div class=\"row\">\n                                <div class=\"col-12\">\n\n                                <!--begin panel section -->\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sectionGroups") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":40},"end":{"line":95,"column":49}}})) != null ? stack1 : "")
    + "                                <!--end panel section -->\n                                </div>\n                        </div> <!-- /.row -->\n\n            <div class=\"row\">\n                <div class=\"col-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModifyEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":20},"end":{"line":104,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":20},"end":{"line":107,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":24},"end":{"line":110,"column":31}}})) != null ? stack1 : "")
    + "                    <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":111,"column":88},"end":{"line":111,"column":117}}}))
    + "</button>\n                </div>\n            </div>\n        </div>\n\n    </section>\n\n</div>\n";
},"useData":true});