import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import PayUtil from 'common/util/paymentUtil';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminTemplate/listView/templates',
            serviceFunc: null,
            businessType: null,
            context: 'TEMP_LIST_VIEW',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    loadRequiredData() {
        const self = this;

        if (this.mode === 'insert' || this.mode === 'import') {
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            this.contextDef.serviceName = 'adminTemplate/listView/templates';
            this.localeKey = 'adminTemplate_';

            DataApi.model.generateModelFromModel(store.get(`${this.contextKey}-actionModel`)).then((newModelParam) => {
                const newModel = newModelParam;
                newModel.set('FUNCTIONCODE', newModel.get('FUNCTION'));
                newModel.set('PRODUCTCODE', newModel.get('PRODUCT'));
                newModel.set('TYPECODE', newModel.get('TYPE'));
                newModel.set('SUBTYPE', newModel.get('SUBTYPE'));

                newModel.key.FUNCTIONCODE = 'true';
                newModel.key.PRODUCTCODE = 'true';
                newModel.key.TYPECODE = 'true';
                newModel.key.SUBTYPE = 'true';

                newModel.convertModelAttributesToKeyJSON = util.bind(
                    self.convertModelAttributesToKeyJSON,
                    newModel,
                );

                newModel.fetch({
                    success() {
                        self.storedModel = newModel;
                        self.setEntryDescription(self.storedModel);
                        self.options.smbOverride = PayUtil.isReimburse(self.storedModel);
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },

                    error: util.bind(errorHandlers.loading, self),
                });
            });
        }
    },

    /*
     * We have to override this method to prevent the read request from having a nested
     * item array
     */
    convertModelAttributesToKeyJSON(model) {
        const jsonData = this.convertModelAttributesToKeyWithOptContextJSON(model);
        return jsonData;
    },
});
