import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import locale from '@glu/locale';
import { withStyles, darkModeAware } from '@glu/theming';
import { styles } from './RangeFilter.styles';
import '../../themeDefaults';

function RangeFilter({
  className,
  classes,
  createFilterValue,
  fieldId,
  filterId,
  htmlId,
  onChange,
  parseFilterValue,
  value
}) {
  const { end = '', start = '' } = parseFilterValue(value);

  const handleInput = (type) => (e) => {
    e.preventDefault();

    const newFilterValue = createFilterValue(
      filterId, fieldId, { ...({ end, start }), [type]: e.target.value }
    );
    onChange(fieldId, newFilterValue);
  };

  return (
    <div className={classNames(className, classes.wrapper)}>
      <div className={classes.rangeWrapper}>
        <span>{locale.get('dataComponents.from')}</span>
        <input
          className={classes.input}
          id={`${htmlId}-start`}
          title={locale.get('dataComponents.from')}
          type="number"
          name={`${fieldId}-start`}
          value={start}
          onChange={handleInput('start')}
        />
      </div>
      <div className={classes.rangeWrapper}>
        <span>{locale.get('dataComponents.to')}</span>
        <input
          className={classes.input}
          id={`${htmlId}-end`}
          title={locale.get('dataComponents.to')}
          type="number"
          name={`${fieldId}-end`}
          value={end}
          onChange={handleInput('end')}
        />
      </div>
    </div>
  );
}
RangeFilter.propTypes = {
  /** ClassName to use for this filter component */
  className: PropTypes.string,
  /** Classes for JSS styling */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /**
   * Function used to build object to pass to `onChange` function.
   * This is where you would build an object for consumption by
   * another component such at `Data-Lens` or `Ag-Grid`
   * The `createFilterValue` function will receive three props `filterId`,
   * `fieldId`, and `value`. `value` is an object of `{start: startValue, end: endValue}`
   */
  createFilterValue: PropTypes.func.isRequired,

  /**
   * Field the filter will be applied against. This will match the column field, usually.
   */
  fieldId: PropTypes.string.isRequired,

  filterId: PropTypes.string,

  /** Html id prefix to use for the start and end input controls of this filter.
   * Will get a "-start" or "-end" suffix.
   * */
  htmlId: PropTypes.string.isRequired,

  /**
   * Callback function that consumes the value created by the `createFilterValue` function.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Function to process the raw value object. The value will be used to populate the input field.
   * parseFilterValue is expected to return an object with start and end props which will populate
   * the respective inputs.
   */
  parseFilterValue: PropTypes.func.isRequired,

  /** The current value for this filter. */
  value: PropTypes.shape({
    end: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    start: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })
};

RangeFilter.defaultProps = {
  className: '',
  filterId: 'Range Filter',
  value: {
    end: '',
    start: ''
  }
};

export const RangeFilterBase = RangeFilter;
export default darkModeAware(withStyles(styles)(RangeFilter));
