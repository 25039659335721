import http from '@glu/core/src/http';
import services from 'services';

export default {
    addProfile(searchFields, model) {
        const requestData = {
            profileData: {
                shared: model.get('SHAREDPROFILEFLAG') === true,
                profileId: model.get('PROFILEID'),
                profileDescription: model.get('PROFILEDESCRIPTION'),

                searchCriteria: {
                    action: {
                        typeCode: model.get('ID'),
                        entryMethod: 0,
                        functionCode: 'RPT',
                        actionMode: 'INSERT',
                        productCode: model.get('PRODUCTCODE'),
                    },

                    inquiryId: model.get('FILTERID'),
                    operationalData: true,
                    searchFields,
                },
            },
        };
        return this.createPromise('/profileService/addProfile', requestData);
    },

    updateProfile(searchFields, model) {
        const requestData = {
            profileNumber: model.get('PROFILENUM'),

            profileData: {
                shared: model.get('PROFILEUSERID') === '_SHARED',
                profileId: model.get('PROFILEID'),
                profileDescription: model.get('PROFILEDESCRIPTION'),

                searchCriteria: {
                    action: {
                        typeCode: model.get('ID'),
                        entryMethod: 0,
                        functionCode: 'RPT',
                        actionMode: 'MODIFY',
                        productCode: model.get('PRODUCTCODE'),
                    },

                    inquiryId: model.get('FILTERID'),
                    operationalData: true,
                    searchFields,
                },
            },
        };
        return this.createPromise('/profileService/updateProfile', requestData);
    },

    getProfile(profileId) {
        const requestData = {
            profileNumber: profileId,
        };
        return this.createPromise('/profileService/getProfile', requestData);
    },

    deleteProfile(profileId) {
        const requestData = {
            profileNumber: profileId,
        };
        return this.createPromise('/profileService/deleteProfile', requestData);
    },

    createPromise(servicePath, requestData) {
        const promiseMe = new Promise((resolve, reject) => {
            http.post(services.generateUrl(servicePath), requestData, (data) => {
                resolve(data);
            }, (result) => {
                reject({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                });
            });
        });
        return promiseMe;
    },
};
