var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":57}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":20,"column":72},"end":{"line":20,"column":81}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div class=\"select-printer\">\n        <form autocomplete=\"off\" data-submit=\"save\">\n            <fieldset>\n                <legend class=\"sr-only\" aria-labelledby=\"modal-title\"></legend>\n\n                <div class=\"validation-group hide\" role=\"alert\">\n                    <p class=\"help-block center\" data-validation=\"generic\"></p>\n                </div>\n\n            <!--<div class=\"alert-region\" data-region=\"alertRegion\"></div> -->\n                <label for=\"printer-type\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"clm.printer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":67}}}))
    + "</label>\n                <div class=\"row\">\n                    <div class=\"col-8 printers-group\">\n\n                        <div class=\"form-group\" id=\"printer-type\">\n                            <select class=\"form-control\" data-bind=\"model\" name=\"type\" data-hook=\"printer-select\">\n                                <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"printersCollection") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":32},"end":{"line":21,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"type\"></span>\n                        </div>\n                    </div>\n                </div>\n\n            </fieldset>\n        </form>\n    </div>\n";
},"useData":true});