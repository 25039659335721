import React from 'react';
import PropTypes from 'prop-types';
import useLocale from '../../hooks/useLocale'; // path may change

const propTypes = {
    headingText: PropTypes.string.isRequired,
    returnRoute: PropTypes.string,
};

const defaultProps = {
    returnRoute: '',
};

const { getLocaleString } = useLocale();

const PageHeader = ({
    headingText,
    returnRoute,
}) => (
    <div className="page-header-wrapper">
        {returnRoute
        && (
            <div className="prev-page-breadcrumb">
                {/* TODO Swap this out for new navigation when moving away from backbone router */}
                <a href={`/${window.Bottomline.appRoot}/${returnRoute}`} className="return-to-workspace">
                    <span className="icon-arrow-left" />
                    {' '}
&nbsp;
                    <span className="breadcrumb-text">{getLocaleString('common.back.to.previous')}</span>
                </a>
            </div>
        )}
        <h1 className="landing-header">{headingText}</h1>
    </div>
);

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default PageHeader;
