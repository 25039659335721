import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import viewMixins from '@glu/core/src/core/internal/viewMixins';
import $ from 'jquery';
import locale from '@glu/locale';
import Marionette from 'backbone.marionette';
import moment from 'moment';
import systemConfig from 'system/configuration';
import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import adminConstants from 'app/administration/constants';
import constants from 'common/dynamicPages/api/constants';
import DataAPI from 'common/dynamicPages/api/data';
import Rows from 'common/dynamicPages/collections/rows';
import services from 'services';
import http from '@glu/core/src/http';
import store from 'system/utilities/cache';
import paymentUtil from 'common/util/paymentUtil';
import userInfo from 'etc/userInfo';
import summarySectionWidgetTmpl from './summarySectionWidget.hbs';

const Mixin = viewMixins(Marionette.Layout);
const MAXAPPROVERS = 6;

export default BaseWidget.extend({
    template: summarySectionWidgetTmpl,
    className: 'ui-widget field-container',
    exceptions: [
        'APPROVER_REJECTION_REASON',
        'REJECTION_REASON',
        'HEADERERRORS',
        'ERRORS',
    ],
    // map object of hardcoded custom classes
    classMapObject: {
        CREDITOR_NAME: 'rfp-creditor-name',
    },
    timestampFields: [
        'REVERSAL_LAST_APPROVED_TIMESTAMP',
        'LAST_APPROVED_TIMESTAMP',
        'ENTERED_TIMESTAMP',
        'EXTRACT_TIMESTAMP',
        'MODIFIED_TIMESTAMP',
        'UNAPPROVED_TIMESTAMP',
        'DELETED_TIMESTAMP',
        'FIRST_CONFIRMATION_TIMESTAMP',
        'SECOND_CONFIRMATION_TIMESTAMP',
        'REJECTION_TIMESTAMP',
        'CONINTLWIRELASTCANCELTIME',
        'REVERSED_TIMESTAMP',
        'REVERSALEXTRACT_TIMESTAMP',
        'APPROVAL_WINDOW_PASSED_TIME',
        'APPROVER_REJECTION_TIMESTAMP',
    ],
    buttonFields: ['RFPLINK'],
    paymentSummaryRows: [
        // PCM-2982 - Checks - Entry Method field is missing in Check Details screen
        // PCM-4779 - PARTNUM field is added that will appear for Chunked Batch sequence
        [
            'STATUS_DESCRIPTION',
            'ENTRYMETHOD_DESCRIPTION',
            'ENTRYMETHODDESC',
            'PARTNUM',
            'TEMPLATE_CODE',
            'TEMPLATE_DESCRIPTION',
            'TEMPLATECODE',
            'TEMPLATEDESCRIPTION',
        ],
        [
            'POSSIBLEDUPLICATEFLAG_DESCRIPTION',
            'DUPLICATEREASON',
        ],
        ['ERRORS'],
        [
            'ENTERED_TIMESTAMP',
            'MODIFIED_TIMESTAMP',
            'UNAPPROVED_TIMESTAMP',
            'LAST_APPROVED_TIMESTAMP',
            'APPROVER_REJECTION_TIMESTAMP',
            'APPROVER_REJECTION_REASON',
            'EXTRACT_TIMESTAMP',
            'DELETED_TIMESTAMP',
        ],
        [
            'FIRST_CONFIRMATION_TIMESTAMP',
            'FIRST_CONFIRMATION_NUMBER',
            'SECOND_CONFIRMATION_TIMESTAMP',
            'SECOND_CONFIRMATION_NUMBER',
        ],
        [
            'REJECTION_TIMESTAMP',
            'REJECTION_REASON',
            'APPROVAL_WINDOW_PASSED_TIME',
        ],
        ['HEADERERRORS'],
        ['CONINTLWIRELASTCANCELTIME'],
        ['CUTOFF_INFO'],
        [
            'REVERSED_TIMESTAMP',
            'REVERSAL_LAST_APPROVED_TIMESTAMP',
            'REVERSALEXTRACT_TIMESTAMP',
        ],
    ],
    templateSummaryRows: [
        [
            'STATUS_DESCRIPTION',
            'ENTRYMETHOD_DESCRIPTION',
        ],
        ['ERRORS'],
        [
            'ENTERED_TIMESTAMP',
            'MODIFIED_TIMESTAMP',
            'UNAPPROVED_TIMESTAMP',
            'LAST_APPROVED_TIMESTAMP',
            'APPROVER_REJECTION_TIMESTAMP',
            'APPROVER_REJECTION_REASON',
            'EXTRACT_TIMESTAMP',
        ],
        [
            'DELETED_TIMESTAMP',
        ],
        [
            'FIRST_CONFIRMATION_TIMESTAMP',
            'FIRST_CONFIRMATION_NUMBER',
            'SECOND_CONFIRMATION_TIMESTAMP',
            'SECOND_CONFIRMATION_NUMBER',
        ],
        [
            'REJECTION_TIMESTAMP',
            'REJECTION_REASON',
        ],
        ['HEADERERRORS'],
        ['CONINTLWIRELASTCANCELTIME'],
        ['CUTOFF_INFO'],
    ],
    copyAsPaymentSummaryRows: [
        [
            'TEMPLATE_CODE',
            'TEMPLATE_DESCRIPTION',
            'TEMPLATECODE',
            'TEMPLATEDESCRIPTION',
        ],
    ],
    requestForPaymentReceivedSummaryRows: [
        ['CREDITOR_NAME'],
        [
            'REQUEST_STATUS_DESC',
            'ACTION_COMMENT',
            'REQUEST_STATUS_REASON',
            'PAYMENT_STATUS_DESC',
            'RFPLINK',
            'RFPLINKMSG',
            'RESP_STATUS_REASON_ADDL_INFO',
        ],
    ],

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);
        this.configParams = options.configParams;
        this.fieldData = this.parentModel.fieldData;
        this.summaryRows = options.widgetFields;
        this.model = new Model();
        this.inModal = !!(options.parentInitializeOptions
            && options.parentInitializeOptions.inModal);
        this.function = this.parentModel.get('FUNCTION');
        this.computeAndSetApprover('APPROVED', 'LAST_APPROVED');
        this.computeAndSetApprover('REVERSAL_APPROVED', 'REVERSAL_LAST_APPROVED');
        this.collectSummaryFields();
        this.listenTo(this.parentModel, 'change:STATUS_DESCRIPTION', () => {
            this.collectSummaryFields();
            this.render();
        });
    },

    /**
     * Sets the specified approver timestamp on the model based on information from the
     * parent model
     * @param {String} [typeOfApprover] - the type of approver to retrieve/set
     * @param {String} [valueToSet] - the name of the attribute to set on the model
     */
    computeAndSetApprover(typeOfApprover, valueToSet) {
        let lastApprovedTimestamp;

        for (let i = MAXAPPROVERS; i >= 1; i -= 1) {
            if (this.parentModel.get(`${typeOfApprover}_TIMESTAMP_${i}`)) {
                lastApprovedTimestamp = this.parentModel.get(`${typeOfApprover}_TIMESTAMP_${i}`);
                break;
            }
        }
        if (!lastApprovedTimestamp) {
            lastApprovedTimestamp = this.parentModel.get(`${typeOfApprover}_TIMESTAMP`);
        }
        this.model.set(`${valueToSet}_TIMESTAMP`, lastApprovedTimestamp);
    },

    /**
     * @function collectSumaryFields
     * @description collects the payment/template fields, for display in the
     * summary (top) section, from the parent model for rendering.
     */
    collectSummaryFields() {
        const { model } = this;
        const { parentModel } = this;
        const { fieldData } = this;
        const self = this;

        let summaryRows;

        /**
         * determines the correct column class to use
         * @param {String} fieldName - the name of the field, used to ensure it doesn't
         * have a relevant exception
         * @param {Number} fieldsInRow - the amount of fields within a row, used to determine
         * whether or not the column needs a different class
         * @return {String} - the appropriate class for the column
         */
        const computeColumnClass = (fieldName, fieldsInRow) => {
            /*
             * when in a modal the "viewport" is much more narrow causing 2 colums to wrap
             * bump to 3 in modal but only if arrayLength is max of 4 fields (12 columns)
             */
            const standardColumnClass = (this.inModal && fieldsInRow <= 4)
                ? 'col-md-3' : 'col-md-2';

            const calculatedClass = this.exceptions.includes(fieldName) ? 'col-md-6' : standardColumnClass;

            return `${calculatedClass} ${this.classMapObject[fieldName]} `;
        };

        /**
         * correctly determines a fields label
         * @param {String} field - the requested field
         * @return {String} - the label for the requested field
         */
        const getLabel = (field) => {
            const presetLabels = {
                CUTOFF_INFO: locale.get('PAY.PaymentMustBeApprovedBy'),
                LAST_APPROVED_TIMESTAMP: locale.get('PS.screentext.LastApprovedOn'),
                REVERSAL_LAST_APPROVED_TIMESTAMP: locale.get('PS.screentext.ReversalLastApprovedOn'),
                ERRORS: locale.get('PAY.ERRORS.label'),
                PARTNUM: locale.get('clm.chunkedBatchSequence'),
            };

            if (presetLabels[field]) {
                return presetLabels[field];
            }
            if (fieldData[field]) {
                return fieldData[field].fieldLabel;
            }

            return '';
        };

        /**
         * properly formats the summary rows fields
         * @param {Array} summaryRowFields - fields for a single row within the summary section
         * @return {Array} - properly formatted fields
         */
        const getSummaryRow = function (summaryRowFields) {
            return summaryRowFields.filter((fieldName) => {
                if (fieldName === 'LAST_APPROVED_TIMESTAMP' || fieldName === 'REVERSAL_LAST_APPROVED_TIMESTAMP') {
                    return !util.isEmpty(model.get(fieldName));
                }
                if (fieldName === 'PARTNUM' && parseInt(parentModel.attributes.PARTTOTAL, 10) === 1) {
                    return false; // PCM
                }
                // Button fields will not have a value in the model
                if (self.state === 'VIEW' && self.buttonFields.includes(fieldName) && fieldData[fieldName]) {
                    return true;
                }
                // certain fields, should not show if config dictates
                if (!self.shouldDisplay(fieldName, parentModel)) {
                    return false;
                }
                return !util.isEmpty(parentModel.get(fieldName));
            }).map((fieldName, idx, arr) => ({
                label: getLabel(fieldName),
                name: fieldName,
                value: self.getFieldValue(fieldName),
                columnClass: computeColumnClass(fieldName, arr.length),
                isButton: self.buttonFields.includes(fieldName),
            }));
        };

        if (util.isNullOrUndefined(this.summaryRows)) {
            if (paymentUtil.isTemplate(this.function)) {
                summaryRows = this.templateSummaryRows;
            } else {
                summaryRows = this.paymentSummaryRows;
            }
            if (this.parentModel && this.parentModel.get('TYPE') === 'REQIN') {
                summaryRows = this.requestForPaymentReceivedSummaryRows;
            }
            this.summaryRows = summaryRows;
        }

        // Only show template code and template description fields for copy as payment workflow
        const isPayment = util.contains(['INST', 'BATCH', 'REQUEST'], this.function);
        const isCreatedFrom1 = (this.options && this.options.model && this.options.model.context
        && this.options.model.context.createdFrom === '1');

        if (isPayment && this.state === 'INSERT' && isCreatedFrom1) {
            this.summaryRows = this.copyAsPaymentSummaryRows;
            // Widget title when doing copy as payment from template should be template summary
            $('[data-widget-id="summarysection"]').closest('fieldset').find('legend').text(locale.get('PAY.TemplateInformation'));
        }

        const displaySummaryRows = this.summaryRows.map(getSummaryRow)
            .filter(item => item.length > 0);

        // set in model display
        this.model.set('summaryFields', displaySummaryRows);
    },

    /**
     * correctly formats a passed in timestamp
     * @param {String} fieldValue - the date to be formatted
     * @return {String} - the appropriately formatted date
     */
    getDisplayTimestamp(fieldValue) {
        return moment(
            fieldValue,
            [
                `${userInfo.getDateFormat()} ${userInfo.getTimeFormat()}`,
                'YYYY-MM-DD HH:mm:ss.S',
            ],
        ).format(userInfo.getDateTimeFormat());
    },

    /**
     * correctly determines a fields value
     * @param {String} fieldName - the requested field
     * @return {*} - the requested field value
     */
    getFieldValue(fieldName) {
        // exit early if this is a description
        if (fieldName === 'TYPE_DESCRIPTION') {
            return locale.get(this.parentModel.get(fieldName));
        }

        /*
         * Button fields will not always correspond to a value in the model. Allow a value
         * to be set, or default it to 'View'.
         */
        if (this.buttonFields.includes(fieldName)) {
            if (this.parentModel.get('TYPE') === 'REQIN' && fieldName === 'RFPLINK') {
                return locale.get('RTP.RTPLINKVALUE', this.parentModel.get('PAYMENT_GROUPSEQNUM'));
            }
            return locale.get('PAY.View');
        }

        const sourceModel = (fieldName === 'LAST_APPROVED_TIMESTAMP' || fieldName === 'REVERSAL_LAST_APPROVED_TIMESTAMP')
            ? this.model : this.parentModel;

        return this.timestampFields.includes(fieldName)
            ? this.getDisplayTimestamp(sourceModel.get(fieldName)) : sourceModel.get(fieldName);
    },

    /**
     * determines if a specific field should be displayed or not
     * @param {String} fieldName - the field in question
     * @param {Model} model - the model containing the field
     * @return {Boolean} - whether or not to display the field
     */
    shouldDisplay(fieldName, model) {
        const isCutoffField = fieldName === 'CUTOFF_INFO';
        const hidecutoff = this.configParams.get('hideCutoff');
        const hidecutoffDefinedWithType = (hidecutoff && (hidecutoff.indexOf(model.get('TYPE')) >= 0));
        const isNotStatus = !['EN', 'IA', 'RT', 'HV', 'RA', 'RI', 'IV', 'PI'].includes(model.get('STATUS'));
        const isViewOrReverse = ['VIEW', 'REVERSE'].includes(this.state.toUpperCase());

        if (isCutoffField && (hidecutoffDefinedWithType || isNotStatus || isViewOrReverse)) {
            return false;
        }
        // PCM-2928 PCM-3143
        if (!util.contains(['BGPCHK', 'BAPCHK', 'BPRCHK'], model.get('TYPE')) && fieldName === 'ENTRYMETHODDESC') {
            return false;
        }
        return true;
    },

    /**
     * @name setupStoreForResponse
     * @description sets up the keyOverride and listview return route for the admin system
     * when the typecode is REQIN (received request for payment)
     */
    setupStoreForResponse() {
        // only for REQIN and isAdmin set keyOverride and store listURL
        if (systemConfig.isAdmin && this.parentModel.get('TYPE') === 'REQIN') {
            store.set('detailsViewKeyOverride', constants.REQIN_CONTEXTKEY);
            store.set(`${constants.REQIN_CONTEXTKEY}-listRoute`, adminConstants.ROUTES.REPORTING_REQUESTFORPAYMENTMNGMT);
        }
    },

    /**
     * Handle navigation after user clicks on button
     * @param {Event} e - button click event
     */
    navigateButtonClicked(e) {
        e.stopImmediatePropagation();
        const currentScreenModel = this.parentModel;

        // Find the context of the screen we are navigating to. Used to generate the new model
        const newScreenModelContext = this.getNewModelContext(currentScreenModel);

        // set store if admin & REQIN
        this.setupStoreForResponse();

        // Generate the model of the screen we are navigating to
        const modelGeneratePromise = DataAPI.model.generate({
            context: newScreenModelContext.context,
            state: 'select',
            hideButtons: false,
            gridApi: {},
        });

        // Get the available actions for the item the user is navigating to
        const availableActionsPromise = this.getAvailableActions(
            newScreenModelContext,
            currentScreenModel,
        );

        Promise.all([
            modelGeneratePromise,
            availableActionsPromise,
        ]).then((results) => {
            const newScreenModel = results[0];
            const actionList = results[1];

            // Set the values needed for the data read in the newly created model
            newScreenModel.set({
                PRODUCT: newScreenModelContext.context.actionData.productCode,
                FUNCTION: newScreenModelContext.context.actionData.functionCode,
                TYPE: newScreenModelContext.context.actionData.typeCode,
                SUBTYPE: newScreenModelContext.context.actionData.subType,
                ENTRYMETHOD: 0,
            });

            newScreenModel.key = newScreenModelContext.key;

            // Get the keyValue and keyName (ex. TNUM) for the new model from the current model
            const keyVal = currentScreenModel.get(newScreenModelContext.keyNameNameInOldModel);
            const keyName = newScreenModelContext.context.keyList[0];

            // Set the keyValue in the model attributes object and the id attribute
            newScreenModel.set(keyName, keyVal);
            newScreenModel.set('id', keyVal);

            // The user group field is part of the key for the requests
            newScreenModel.set('USERGROUP', currentScreenModel.get('USERGROUP'));

            // This flag to indicates that the model was generated here and not from a grid load
            newScreenModel.isFromDetailScreen = true;

            /*
             * If an action gets initiated from the screen that is being linked to we need to
             * know where to bring the user in order to display the confirmation message. This
             * is probably different then the list view the user came from to get to the current
             * screen.
             */
            newScreenModel.returnLinkAfterAction = userInfo.isSmbUser()
                ? newScreenModelContext.returnLinkAfterActionSMB
                : newScreenModelContext.returnLinkAfterAction;

            // Add action buttons to the model based on the retrieved available actions
            if (actionList) {
                newScreenModel.buttons = new Rows({}).parseActionList(actionList);
            }

            // Store the new model in the cache so the new screen can retrieve it on render
            store.set(userInfo.isSmbUser() ? newScreenModelContext.storeNameSMB
                : newScreenModelContext.storeName, newScreenModel);

            /*
             * A few lines down 'current-workspace-route' will be overwritten so that the user
             * gets returned to the current screen on cancel from the new screen, instead of the
             * returning to the list view that brought them to the current screen. We are
             * preserving 'current-workspace-route' so that when the user cancels from the
             * new screen, and then also cancels from the current screen we know what list view
             * to send them back to.
             */
            const currentWorkspaceRoute = store.get('current-workspace-route');
            if (currentWorkspaceRoute) {
                store.set(`${currentScreenModel.get('id')}-original-workspace-route`, currentWorkspaceRoute);
            }
            // Set the return route so if the user cancels they are brought back correctly
            store.set('current-workspace-route', userInfo.isSmbUser()
                ? newScreenModelContext.returnRouteSMB : newScreenModelContext.returnRoute);

            // Navigate to the new screen
            Mixin.navigateTo(userInfo.isSmbUser()
                ? newScreenModelContext.navigateRouteSMB : newScreenModelContext.navigateRoute);
        });
    },

    /**
     * Get the context for the new model being generated for the new screen
     * @param {Model} currentScreenModel - model for the current screen
     * @return {Object} Context object containing values for the new screen model
     */
    getNewModelContext(currentScreenModel) {
        const contextObject = currentScreenModel.context.actionData
            || currentScreenModel.context.actionContext;
        const { functionCode, typeCode } = contextObject;
        let { productCode } = contextObject;
        if (systemConfig.isAdmin()) {
            return constants[
                `ADMPAY_${functionCode.toUpperCase()}_${typeCode.toUpperCase()}_CONTEXT`
            ];
        }

        /*
         * The product for RFP's can be different based on how we got to the screen. We need to
         * retrieve the same context either way, so we change it to RFPILIST if it is RTP.
         */
        if (productCode === 'RTP' && functionCode === 'REQUEST' && typeCode === 'REQIN') {
            productCode = 'RFPILIST';
        }

        return constants[
            `${productCode.toUpperCase()}_${functionCode.toUpperCase()}_${typeCode.toUpperCase()}_CONTEXT`
        ];
    },

    /**
     * Get the actions that will be available for the user to perform on the new screen. This
     * will not be needed for every instance and can be skipped by setting lookupActions to
     * false in the configuration object.
     * @param {Object} newContext - configuration object for the new screen
     * @param {Model} currentScreenModel - model for the current screen
     * @return {Promise}
     */
    getAvailableActions(newContext, currentScreenModel) {
        // If we do not need to lookup actions then just return a resolved Promise
        if (!newContext.lookupActions) {
            return Promise.resolve();
        }

        const url = services.generateUrl(constants.URL_GET_DATA);
        const postData = {
            requestHeader: {},
            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: newContext.inquiryTypeCode,
                        productCode: newContext.inquiryProductCode,
                        functionCode: newContext.inquiryFunctionCode,
                        actionMode: newContext.inquiryAction,
                    },
                    searchFields: [{
                        fieldName: newContext.context.keyList[0],
                        fieldValue: [currentScreenModel.get(newContext.keyNameNameInOldModel)],
                        dataType: 'number',
                        operator: '=',
                    }],
                    searchType: newContext.useInquiryID ? newContext.searchType : '0',
                    inquiryId: newContext.useInquiryID ? newContext.inquiryID : 0,
                },
            },
        };

        return new Promise((resolve, reject) => {
            http.post(url, postData, (data) => {
                resolve(data.inquiryResponse.rows[0].columns.filter(row => row.fieldName === 'ACTIONLIST')[0].fieldValue);
            }, () => {
                reject();
            });
        });
    },

});
