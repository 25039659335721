var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bosInvoice.enterinvoiceinfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":83}}}))
    + "</h1>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bosInvoice.viewinvoiceheader",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":84}}}))
    + " <span data-text=\"BILL_NUMBER\" data-bind=\"model\"></span></h1>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "not-";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-tertiary\" data-hook=\"add-new-vendor\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bosInvoice.vendor.add.new",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":76},"end":{"line":20,"column":114}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"btn btn-tertiary\" data-hook=\"add-new-customer\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bosInvoice.customer.add.new",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":86},"end":{"line":59,"column":126}}}))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <textarea class=\"form-control  textarea-field\" rows=\"3\" data-bind=\"model\" id=\"BILL_DESCRIPTION_"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":67,"column":111},"end":{"line":67,"column":117}}}) : helper)))
    + "\" name=\"BILL_DESCRIPTION\" maxlength=\"255\"></textarea>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                        <p data-text=\"BILL_DESCRIPTION\" data-bind=\"model\"></p>\n\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-container\">\n                <div class=\" field-container-lg field-container\">\n                        <label class=\"checkbox-inline\">\n                                <input type=\"checkbox\" data-bind=\"model\" name=\"ENTERANOTHER\">\n                                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"bosInvoice.enteranother",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":130,"column":32},"end":{"line":130,"column":68}}}))
    + "\n                        </label>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"ENTERANOTHER\"></span>\n                </div>\n        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button data-action=\"edit\" class=\"btn btn-secondary\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.button_modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":140,"column":77},"end":{"line":140,"column":111}}}))
    + "</button>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <button data-action=\"save\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.button_save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":142,"column":83},"end":{"line":142,"column":115}}}))
    + "</button>\n                                <button data-action=\"savedraft\" class=\"btn btn-secondary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.button_savedraft",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":143,"column":90},"end":{"line":143,"column":127}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"section section-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":73}}})) != null ? stack1 : "")
    + "read-only\">\n\n<div data-region=\"alert-region\"></div>\n\n<div class=\"form-container\">\n        <div> <!-- break out of .form-container child styles -->\n\n        <div class=\"required field-container-lg field-container vendor-id\">\n                <label for=\"VENDOR_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":44}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.vendor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":76}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"VENDOR_ID\" id=\"VENDOR_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":18,"column":116},"end":{"line":18,"column":122}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":21,"column":27}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"VENDOR_ID\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container required\">\n                <label for=\"BILL_NUMBER_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":26,"column":40},"end":{"line":26,"column":46}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.invoice.number",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":48},"end":{"line":26,"column":86}}}))
    + "</label>\n                <input class=\"form-control\" type=\"text\" maxlength=\"20\" data-bind=\"model\" id=\"BILL_NUMBER_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":27,"column":105},"end":{"line":27,"column":111}}}) : helper)))
    + "\" name=\"BILL_NUMBER\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BILL_NUMBER\"></span>\n        </div>\n\n        <div class=\"required field-container-sm field-container\">\n                <label for=\"TOTAL_AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":32,"column":41},"end":{"line":32,"column":47}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":49},"end":{"line":32,"column":79}}}))
    + "</label>\n                <input class=\"form-control \" type=\"text\" maxlength=\"19\" data-bind=\"model\" id=\"TOTAL_AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":33,"column":107},"end":{"line":33,"column":113}}}) : helper)))
    + "\" name=\"TOTAL_AMOUNT\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TOTAL_AMOUNT\"></span>\n        </div>\n\n        <div class=\"date-picker required field-container-sm field-container\">\n                <label for=\"ISSUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":38,"column":39},"end":{"line":38,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.issue.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":47},"end":{"line":38,"column":81}}}))
    + "</label>\n                <input class=\"input-date\" type=\"text\" data-bind=\"model\" name=\"ISSUE_DATE\" id=\"ISSUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":39,"column":105},"end":{"line":39,"column":111}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"ISSUE_DATE\"></span>\n        </div>\n\n        <div class=\"date-picker required field-container-sm field-container\">\n                <label for=\"DUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":44,"column":37},"end":{"line":44,"column":43}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.due.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":45},"end":{"line":44,"column":77}}}))
    + "</label>\n                <input class=\"input-date\" type=\"text\" data-bind=\"model\" name=\"DUE_DATE\" id=\"DUE_DATE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":45,"column":101},"end":{"line":45,"column":107}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DUE_DATE\"></span>\n        </div>\n\n        <div class=\"required field-container-lg field-container\">\n                <label for=\"TERM_CONDITION_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":50,"column":46},"end":{"line":50,"column":52}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.terms.conditions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":54},"end":{"line":50,"column":94}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"TERM_CONDITION_ID\" id=\"TERM_CONDITION_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":51,"column":132},"end":{"line":51,"column":138}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TERM_CONDITION_ID\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container customer-id\">\n                <label for=\"CUSTOMER_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":56,"column":40},"end":{"line":56,"column":46}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.billable.customer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":48},"end":{"line":56,"column":89}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"CUSTOMER_ID\" id=\"CUSTOMER_ID_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":57,"column":120},"end":{"line":57,"column":126}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":60,"column":27}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"CUSTOMER_ID\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"BILL_DESCRIPTION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":65,"column":45},"end":{"line":65,"column":51}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":53},"end":{"line":65,"column":88}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":71,"column":27}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BILL_DESCRIPTION\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"IMPORTANCE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":76,"column":39},"end":{"line":76,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.importance",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":47},"end":{"line":76,"column":81}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"IMPORTANCE\" id=\"IMPORTANCE_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":77,"column":118},"end":{"line":77,"column":124}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"IMPORTANCE\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"GL_ACCOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":82,"column":39},"end":{"line":82,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.general.ledger",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":82,"column":47},"end":{"line":82,"column":85}}}))
    + "</label>\n                <input class=\"form-control \" type=\"text\" maxlength=\"20\" data-bind=\"model\" id=\"GL_ACCOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":83,"column":105},"end":{"line":83,"column":111}}}) : helper)))
    + "\" name=\"GL_ACCOUNT\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"GL_ACCOUNT\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"LOCATION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":88,"column":37},"end":{"line":88,"column":43}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":88,"column":45},"end":{"line":88,"column":77}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"LOCATION\" id=\"LOCATION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":89,"column":114},"end":{"line":89,"column":120}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\"></span>\n        </div>\n\n        <div class=\"field-container-lg field-container\">\n                <label for=\"DEPARTMENT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":94,"column":39},"end":{"line":94,"column":45}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.department",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":94,"column":47},"end":{"line":94,"column":81}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"DEPARTMENT\" id=\"DEPARTMENT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":95,"column":118},"end":{"line":95,"column":124}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\"></span>\n        </div>\n\n        <div class=\"required field-container-lg field-container\">\n                <label for=\"VALIDATOR_REQUIRED_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":100,"column":47},"end":{"line":100,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.validator",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":55},"end":{"line":100,"column":88}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"VALIDATOR_REQUIRED\" id=\"VALIDATOR_REQUIRED_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":101,"column":134},"end":{"line":101,"column":140}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALIDATOR_REQUIRED\"></span>\n        </div>\n\n        <div class=\"required field-container-lg field-container\">\n                <label for=\"APPROVER_REQUIRED\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.approver",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":106,"column":47},"end":{"line":106,"column":79}}}))
    + "</label>\n                <input type=\"hidden\" class=\"form-control comboBox\" data-bind=\"model\" name=\"APPROVER_REQUIRED\" id=\"APPROVER_REQUIRED_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":107,"column":132},"end":{"line":107,"column":138}}}) : helper)))
    + "\" data-filter-immediately>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"APPROVER_REQUIRED\"></span>\n        </div>\n        </div>\n\n</div>\n\n\n        <fieldset class=\"line-item-container\">\n                <legend>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.line.items",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":116,"column":24},"end":{"line":116,"column":58}}}))
    + "</legend>\n                <div data-region=\"line-items\"></div>\n                <div class=\"line-item-total\">\n                        <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":119,"column":30},"end":{"line":119,"column":59}}}))
    + "</span>\n                        <span class=\"value\"></span>\n                </div>\n        </fieldset>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":8},"end":{"line":135,"column":19}}})) != null ? stack1 : "")
    + "\n    <div class=\"section-body\"> \n        <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":139,"column":24},"end":{"line":144,"column":31}}})) != null ? stack1 : "")
    + "\n                        <button data-action=\"cancel\" class=\"btn btn-tertiary\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.button_cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":146,"column":78},"end":{"line":146,"column":112}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});