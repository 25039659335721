import Layout from '@glu/core/src/layout';
import ImageCollection from 'app/reports/collections/lockboxImages';
import store from 'system/utilities/cache';
import lockboxViewerTmpl from 'app/reports/views/lockbox/lockboxCheckImage.hbs';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import CheckReview from './lockboxCheckReview';

export default Layout.extend({
    template: lockboxViewerTmpl,
    loadingTemplate: loadingPageTmpl,

    initialize(options) {
        const req = store.get('lbxImageRequest');
        this.returnRoute = options.returnRoute;
        this.collection = new ImageCollection({
            request: req,
        });
        this.listenTo(this.collection, 'sync', this.render);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.checkView = new CheckReview({
                model: this.collection.at(0),
                showThumbnails: true,
                hideImageList: true,
                hideExportBtn: true,
            });
            this.imageViewer.show(this.checkView);
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        this.collection.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
            },
        });
    },

    /**
     * @method cancel
     */
    cancel() {
        this.navigateTo(this.returnRoute);
    },
});
