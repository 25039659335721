/**
 * Waits for specific user interaction that might occur while a scrolling animation is occurring.
 * We cannot test for `scroll` or it would catch itself.
 *
 * Multiple event types can indicate a user interaction. We're listening to:
 * wheel - like scroll but not caused by programmatic scrolling
 * keydown - No matter the key the user is probably trying to interact
 * touchstart - The start of virtually every interaction on touchscreen
 *
 * @param {function} interactionCallback - Function to call when a user interaction occurs
 * @returns {function} cleanUp function to remove listeners
 */
export const userInteractionCheck = (interactionCallback) => {
    const eventOccurred = () => {
        // eslint-disable-next-line no-use-before-define
        cleanUp();
        interactionCallback();
    };
    /**
     * The function to stop our event listener once triggered
     *  or when no longer needed.
     */
    const cleanUp = () => {
        window.removeEventListener('wheel', eventOccurred);
        window.removeEventListener('keydown', eventOccurred);
        window.removeEventListener('touchstart', eventOccurred);
    };

    window.addEventListener('wheel', eventOccurred);
    window.addEventListener('keydown', eventOccurred);
    window.addEventListener('touchstart', eventOccurred);

    return cleanUp;
};

/**
 * Handle a repeated action for a duration or until a user interaction should stop it.
 *
 * @example untilTimeOrUserAction(scroll.scrollToFirstError, scroll.stopScroll, 300, 2500);
 *
 * @param {function} action - called every interval until the duration or a user interaction
 * @param {function} cancel - called if user interaction occurs to stop our action
 * @param {number} interval - milliseconds between action calls
 * @param {number} duration - milliseconds until complete
 */
export const untilTimeOrUserAction = (action, cancel, interval, duration) => {
    const timerId = window.setInterval(action, interval);
    const ending = () => window.clearInterval(timerId);

    // One initial run
    action();

    /*
     * Interaction Check should not just stop the interval
     * but should also cancel the action, if possible
     */
    userInteractionCheck(() => {
        ending();
        cancel();
    });
    window.setTimeout(ending, duration);
};

export default {
    untilTimeOrUserAction,
    userInteractionCheck,
};
