/**
 * base layout for account tabs
 */

import Layout from '@glu/core/src/layout';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import adminConstants from 'app/administration/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import errorHandlers from 'system/error/handlers';
import constants from './constants';
import AccountTab from './accountGrid';
import LockboxTab from './lockboxGrid';
import LegacyTab from './legacyGrid';
import PaymentInitiatorGroupIds from './paymentInitiatorGroupIdGrid';
import LocationTab from './locationGrid';
import BankWidgetTab from './bankWidgetGrid';
import ACHTab from './achGrid';
import TemplateTab from './templateGrid';
import TOALocationsWidgetTab from './TOALocationsGrid';
import LocationLoginTab from './locationLogin/locationLoginTab';
import accountTabsTmpl from './accountTabs.hbs';

export default Layout.extend({
    template: accountTabsTmpl,

    async initialize(options) {
        /*
         * Expose the sections in an object by ID rather than just as an array.
         * This will make it easier to associate the section after mapping/filtering
         * the underlying data.
         * TODO: Re-work the gridDataAccess access to use this rather than the complicated
         * .map() nesting.
         * TODO: Move to model?
         */
        this.sectionModels = this.model.get('sections').reduce((acc, section) => {
            acc[section.get('id')] = section;
            return acc;
        }, {});

        // Set if COPY EXISTING USER scenario is being done
        this.copyingUser = (this.model.userModel) ? this.model.userModel.get('COPYEXISTINGUSER') : false;

        this.copyingRole = options.copyRoleId;

        this.eStatementData = {};
        // First we check whether there are any TOA locations records
        const fetchEStatementDataFunc = (toaRecordsFound) => {
            this.fetchEStatementData().then((data) => {
                this.eStatementData = data;
                this.typeProductMap = this.getTypeProductMap();
                this.tabs = this.createAssignAccountTabs(toaRecordsFound);
                this.setActiveTab();
                this.currentTab = undefined;
            });
        };
        try {
            const { totalRows } = await TOALocationsWidgetTab
                .prototype
                .checkForTOARecords
                .call(util.extend(TOALocationsWidgetTab.prototype, {
                    model: this.model,
                    options: this.options,
                }));
            fetchEStatementDataFunc(totalRows > 0);
        } catch (err) {
            fetchEStatementDataFunc(0);
            errorHandlers.loading.call(this);
        }

        // TODO: Move to the Account Child View/Model.
        this.proxyData = {};

        // The grid loads async.
        this.hasLoaded = false;

        // Don't mess with the existing init
        Layout.prototype.initialize.call(this, options);
    },

    generateOptions(key) {
        this.proxyData[key] = this.proxyData[key] || {};

        return {
            entityType: key,
            proxyData: this.proxyData[key],
            model: this.model,
            mode: this.options.mode,
            typeProductMap: this.typeProductMap,
            copyingUser: this.copyingUser,
            copyingRole: this.copyingRole,
            permissionsChangedToByGroup: this.options.permissionsChangedToByGroup,
        };
    },

    setActiveTab() {
        const firstTab = util.find(this.tabs, tab => tab.visible);

        if (firstTab) {
            firstTab.active = true;
        }
    },

    // TODO - replace plain True/False with functions to test for display of these tabs
    createAssignAccountTabs(toaRecordsFound) {
        return {
            accounts: {
                active: false,
                visible: true,
                view: new AccountTab(this.generateOptions(constants.ENTITY_TYPE.ACCOUNT)),
                label: 'uce.bankAccounts',
            },

            originators: {
                active: false,
                visible: true,
                view: new ACHTab(this.generateOptions(constants.ENTITY_TYPE.ORIGINATOR)),
                label: 'uce.achoriginators',
            },

            paymentInitiatorGroupIds: {
                active: false,
                visible: true,
                // eslint-disable-next-line max-len
                view: new PaymentInitiatorGroupIds(this.generateOptions(constants.ENTITY_TYPE.PAYMENY_INITIATOR_GROUP_IDS)),
                label: 'risk.pigids',
            },

            templates: {
                active: false,
                visible: true,
                view: new TemplateTab(this.generateOptions(constants.ENTITY_TYPE.TEMPLATE)),
                label: 'uce.restrictedTemplates',
            },

            legacy: {
                active: false,
                visible: true,
                view: new LegacyTab(this.generateOptions(constants.ENTITY_TYPE.LEGACY)),
                label: 'uce.legacy',
            },

            lockbox: {
                active: false,
                visible: true,
                view: new LockboxTab(this.generateOptions(constants.ENTITY_TYPE.LOCKBOX)),
                label: 'uce.lockbox',
            },

            locations: {
                active: false,
                visible: true,
                view: this.showRDCTabByConfig(serverConfigParams.get('RDCProviderName')),
                label: 'uce.location',
            },

            bankWidgets: {
                active: false,
                visible: true,
                view:
                        new BankWidgetTab(this.generateOptions(constants.ENTITY_TYPE.BANK_WIDGET)),
                label: 'uce.bankWidget',
            },

            TOALocations: {
                active: false,
                visible: false,
                view:
                        new TOALocationsWidgetTab({
                            toaRecordsFound,
                            ...this
                                .generateOptions(constants.ENTITY_TYPE.TOA_LOCATIONS),
                        }),
                label: 'uce.TOA',
            },
        };
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    regions: {
        tabRegion: '.tab-region',
    },

    events: {
        'click @ui.$navLinks': 'changeTabFromUI',
    },

    // TODO: May need to incorporate parent helpers, too?
    templateHelpers() {
        // TODO: Is this the right spot?
        this.updateTabVisibility();

        return {
            tabs: this.tabs,
            anyActive: () => this.getActiveTab() !== undefined,
        };
    },

    changeTabFromUI(event) {
        const key = this.$(event.target).data('hook');

        // Don't let the actual click go through.
        event.preventDefault();

        return this.changeTab(key);
    },

    changeTab(key) {
        const tab = this.tabs[key];

        if (this.currentTab && this.currentTab.view && this.currentTab.view.hasLoaded) {
            // Reset hasLoaded to force fully loading the view before attempting to switch
            this.currentTab.view.hasLoaded = false;

            // If we have a current tab, extract data from before leaving it.
            if (this.currentTab && this.currentTab.view && this.currentTab.view.extractData) {
                this.currentTab.view.extractData();
            }

            // Render new tab
            this.renderTabChange(key);

            // Then set the true true
            if (this.currentTab !== tab) {
                this.currentTab.active = false;
                tab.active = true;
                this.currentTab = tab;
            }
        }
    },

    updateTabVisibility() {
        util.each(this.tabs, (tabParam) => {
            const tab = tabParam;
            if (tab.view && tab.view.shouldShow) {
                // Update visible setting
                tab.visible = tab.view.shouldShow();
            }
        });
    },

    /**
     * @param {string} rdcKey
     * @return {View}
     * Returns the correct RDC tab view based on RDC server config key passed
     */
    showRDCTabByConfig(rdcKey) {
        switch (rdcKey) {
        case adminConstants.RDC_SETTING.DELUXE:
            return new LocationLoginTab(this.generateOptions(constants.ENTITY_TYPE.LOCATION));
        default:
            return new LocationTab(this.generateOptions(constants.ENTITY_TYPE.LOCATION));
        }
    },

    getActiveTab() {
        this.updateTabVisibility();

        let currentActive = util.find(this.tabs, tab => tab.visible && tab.active);

        if (!currentActive) {
            // Get the first visible tab and make it active
            currentActive = util.find(this.tabs, tab => tab.visible);

            if (currentActive) {
                // TODO: Maybe not brute force it?
                util.each(this.tabs, (tabParam) => {
                    const tab = tabParam;
                    tab.active = false;
                });

                currentActive.active = true;
                this.currentTab = currentActive;
            }
        }

        return currentActive;
    },

    renderTabChange(key) {
        //
        const tab = this.tabs[key] || this.currentTab;

        this.tabRegion.show(tab.view);

        this.ui.$navLinks.parent().removeClass('is-active');
        this.$(`[data-hook="${key}"]`).parent().addClass('is-active');

        if (window.innerWidth < 768) {
            this.ui.$navTabs.toggleClass('is-open');
        }
    },

    // TODO: What am I doing here?!
    onRender() {
        const activeTab = this.getActiveTab();
        // Swap tabs if needed
        if (this.currentTab !== activeTab) {
            this.currentTab = activeTab;
        }

        if (this.currentTab) {
            this.tabRegion.show(this.currentTab.view);
        } else {
            // Empty the region if there are no tabs
            this.tabRegion.reset();
        }
    },

    trimOrNothing(input) {
        return (input || '').trim();
    },

    getTypeProductMap() {
        // btr and estatement are special cases that collects unrelated types.
        const btrAndEstatement = {
            BTR: 'GIR',
            TOA: 'GIR',
            CONTROLTOTAL: 'RISK',
        };

        const transferTypes = {
            TRANSFERBANKACCOUNT: 'RTGS',
            TRANSFERBANKACCOUNTCR: 'RTGS',
        };

        util.extend(btrAndEstatement, this.eStatementData);

        return util.chain(this.sectionModels)
            .map((model) => {
                const typeProduct = {};
                model.get('groups').each((group) => {
                    group.get('types').each((type) => {
                        typeProduct[type.id] = type.get('productCode');
                    });
                });
                return typeProduct;
            })
            .reduce(
                (typeProductList, obj) => util.extend({}, typeProductList, obj),
                {},
            )
            .extend(btrAndEstatement)
            .extend(transferTypes)
            .value();
    },

    fetchEStatementData() {
        const url = services.generateUrl('inquiry/getData');

        const data = {
            requestHeader: {},

            inquiryRequest: {
                startRow: 1,
                rowsPerPage: 50,

                searchCriteria: {
                    inquiryId: '22416',
                    searchType: '5',
                    operationalData: false,
                },
            },
        };

        return http.post(url, data)
            .then(response => util.reduce(response.inquiryResponse.rows, (objParam, row) => {
                const obj = objParam;
                obj[`ESTMENT${row.columns[0].fieldValue}`] = 'GIR';
                return obj;
            }, {}));
    },

    /**
     * Determine if rdc location mappings should take place
     * @param {object} version
     * @returns {boolean}
     */
    shouldMapRDCLocations(version) {
        if (version.dataEntAttr === constants.ENTITY_NAME.BANK) {
            return false;
        }

        const RDCProvidernNames = ['DELUXE', 'ITMS'];
        const providerName = serverConfigParams.get('RDCProviderName') || '';
        return version.productCode === 'RDC'
                && RDCProvidernNames.includes(providerName.toUpperCase());
    },

    formatEntitlements() {
        /*
         * Fix the member id formatting needed for entitlements
         * REVIEW: Is there a more appropriate way to get the separate keys?
         */

        return util.chain(this.proxyData)
            .map(tabData => util.map(tabData, (value, idx) => {
                // Pass an empty object for selectAll which will be compacted
                if (idx === 'selectAll') {
                    return util.clone(value);
                }
                const version = util.clone(value);

                if (version.typeCode === '16010') {
                    version.members = this.mapLegacyReportId(version.members);
                } else if (this.shouldMapRDCLocations(version)) {
                    version.members = this.mapRDCLocationLoginId(version.members);
                } else if (version.dataEntAttr.includes('Template')) {
                    version.members = this.mapRestrictedTemplates(version.members);
                    if (version.members.length > 1) {
                        version.typeCode = '*';
                        version.productCode = '*';
                        version.dataEntAttr = 'TemplateCode';
                    }
                } else {
                    let regexSplit = version.dataEntAttr === constants.ENTITY_NAME.BANK
                            || version.dataEntAttr === constants.ENTITY_NAME.BANK_CR ? /-(.+)/ : /-/;

                    if (version.dataEntAttr === 'ACHCompany') {
                        regexSplit = /-~/;
                    }

                    if (version.dataEntAttr === 'TOALOCATION') {
                        regexSplit = /(?!.*)/;
                    }

                    version.members = util.map(version.members, (memberParam) => {
                        const member = memberParam;
                        const splitId = member.id.split(regexSplit);
                        member.value1 = this.trimOrNothing(splitId[0]).replace('&amp;', '&');
                        member.value2 = this.trimOrNothing(splitId[1]).replace('&amp;', '&'); // May not exist
                        member.value3 = this.trimOrNothing(splitId[2]).replace('&amp;', '&'); // May not exist

                        return util.omit(member, 'id');
                    });
                }

                // Remove internal values not part of the data object
                return util.omit(version, ['allSet', 'initialCurrentFuture']);
            }))
            .compact() // get rid of the empty member objects
            .flatten() // Merge the sets
            .reject(value => value.typeCode === 'selectAll'
                    || value.typeCode === 'ALLENTITLED') // not used by server
            .value();
    },

    /**
     * Legacy system id which is formatted as "abcdefg-xyz"
     * should be mapped directly to "value1" of data entitlements.
     */
    mapLegacyReportId(members) {
        return members.map(member => util.omit(Object.assign(member, { value1: member.id }), 'id'));
    },

    /**
     * RDC Location is formatted where the LOGIN name is set as value2 of
     * data entitlements member
     * @param {array} members
     * @return {array} formatted members
     */
    mapRDCLocationLoginId(members) {
        return members.map((member) => {
            const { id, LOGIN, ...remaining } = member;
            return {
                ...remaining,
                value1: util.unescape(id),
                value2: LOGIN,
            };
        });
    },

    /**
     * Applies value2 and value 3 of the restricted template
     * data entitlements member
     * @param {array} members
     * @return {array} formatted members
     */
    mapRestrictedTemplates(members) {
        return util.map(members, member => util
            .chain(member)
            .clone()
            .extend({
                value1: member.templateCode.split('~')[2],
                value2: member.templateCode.split('~')[0],
                value3: member.templateCode.split('~')[1],
            })
            .omit(member, ['id', 'templateCode'])
            .value());
    },

    /**
     * Runs the "save" proxy pre-processor and returns the result
     */
    extractData() {
        util.each(this.tabs, (tab) => {
            if (tab.view && tab.view.extractData) {
                tab.view.extractData();
            }
        });

        return this.formatEntitlements();
    },

    cleanupAfterYourself(...args) {
        // TODO: Produce logic for each child tab.

        // Make sure we extract the Account Grid data
        this.model.assignAccountsDataEntitlements = this.extractData();

        if (Layout.prototype.close) {
            Layout.prototype.close.apply(this, args);
        }
    },

    // Clean up when going from leaving tab.
    close() {
        return this.cleanupAfterYourself();
    },

    // Called by the baseStepLayout when the Save action about to happen.
    beforeSave() {
        this.cleanupAfterYourself();

        util.each(this.model.assignAccountsDataEntitlements, (model) => {
            this.model.get('dataEntitlements').push(model);
        });

        return Promise.resolve();
    },

    // HACK: Override the common glu actions that aren't needed here and add processing time.
    enableViewBinding: util.noop,

    monitorVisibility: util.noop,
    renderAndRestoreFocus: util.noop,
    restoreFocus: util.noop,
});
