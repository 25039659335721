/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disabled react/react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { createUseStyles } from '@glu/theming';
import { Select } from '@glu/form-components';
import Gateway from 'ptxReact/system/gateway';

const useStyles = createUseStyles(({
    modalBody,
    select,
    button,
}) => ({
    modal: {
        zIndex: 9999,
        '& > article': {
            width: '100%',
            maxWidth: 720,
            '& header': {
                padding: [30, 30, 0, 30],
                '& button': {
                    top: 'unset',
                    right: 30,
                },
            },
        },
    },
    modalBody: {
        ...modalBody,
        padding: [0, 6],
    },
    select: {
        ...select,
        width: '50%',
        display: 'inline-block',
    },
    button: {
        ...button,
        marginLeft: 50,
        marginTop: 19,
        display: 'inline-block',
        verticalAlign: 'top',
    },
}));

const ChequebookPopup = ({
    chequeBookId,
    retrieveCheckbooksHandler,
}) => {
    const [chequebooks, setChequebooks] = useState([]);
    const classes = useStyles();

    async function getChequebooks() {
        new Gateway('chequebooks')
            .get()
            .then((result) => {
                result.unshift({
                    id: '',
                    name: '',
                });
                setChequebooks(result);
            });
    }

    useEffect(() => {
        getChequebooks();
    }, [chequeBookId]);

    return (
        <>
            <Select
                className={classes.select}
                labelText={locale.get('chequebook')}
                name="chequeBookId"
                data-qa="chequeBookselector"
                onChange={e => {
                    retrieveCheckbooksHandler(parseInt(document.getElementsByName(e.nativeEvent.target.name)[0].value, 10));
                }}
                validators={{
                    required: true,
                    msg: locale.get('itemIsRequired', locale.get('chequebook')),
                }}
                options={chequebooks?.map((p, i) => ({
                    value: String(p.id),
                    label: p.name,
                    key: p.id,
                    selected: i === 0,
                    disabled: i === 0,
                    hidden: i === 0,
                    'data-qa': 'chequeBookOption',
                }))}
            />

            {/* <Button
                className={classes.button}
                text={locale.get('createNewChequebook')}
                variant={SECONDARY}
                onClick={() => setModal(newChequebookModal)}
                dataQa="new-cheque-book"
            /> */}
        </>
    );
};

ChequebookPopup.defaultProps = {
    chequeBookId: undefined,
    // Prop functions required by the New Chequebook form but not used in the modal version
};

ChequebookPopup.propTypes = {
    chequeBookId: PropTypes.number,
    retrieveCheckbooksHandler: PropTypes.func.isRequired,
};

export default ChequebookPopup;
