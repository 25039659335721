export default ({ grid }) => ({
  root: {
    backgroundColor: grid.footer.background,
    boxShadow: grid.footer.boxShadow,
    margin: 0,
    padding: [10, 0],
    position: 'relative',
    width: '100%'
  }
});
