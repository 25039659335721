import Layout from '@glu/core/src/layout';
import Alert from '@glu/alerts';
import MultiFieldCollection from 'common/util/multiField/multiFieldCollection';
import MultiFieldElementModel from './multiFieldElementModel';
import MultiFieldCollectionView from './multiFieldCollectionView';
import template from './multiField.hbs';

export default Layout.extend({
    template,
    itemViewContainer: '[data-hook="getMultifieldRegion"]',

    regions: {
        multiFieldRegion: '[data-hook="getMultifieldRegion"]',
        multiFieldMessageRegion: '[data-hook="getMultiFieldMessageRegion"]',
    },

    ui: {
        $addBtn: '[data-hook="getAddBtn"]',
    },

    events: {
        'click @ui $addBtn': 'addElement',
    },

    initialize(options) {
        this.collection = new MultiFieldCollection(options.models);
        this.collectionView = new MultiFieldCollectionView({
            collection: this.collection,
            fieldOptions: options.fieldOptions,
            modelValidators: options.modelValidators,
        });
        if (!this.collection.length) {
            this.collection.add(new MultiFieldElementModel());
        }
    },

    onRender() {
        this.multiFieldRegion.show(this.collectionView);
    },

    addElement(e) {
        e.preventDefault();

        // Need to validate each model in the array to show errors, so reduce is needed
        const isValid = this.collection.models.reduce((accum, model) => {
            const valid = model.isValid();
            if (!valid) {
                return false;
            }
            return accum;
        }, true);
        // When every model is not valid, prevent adding new models
        if (!isValid) {
            return;
        }

        // check if the user has reached a limit
        if (this.options.limit && this.collection.length >= this.options.limit) {
            /*
             * Show alert telling user they have added as many multifield elements as
             * they are allowed to
             */
            this.multiFieldMessageRegion.show(Alert.negative(this.options.limitMessage, {
                canDismiss: true,
            }));

            return;
        }

        this.collection.add(new MultiFieldElementModel());
    },

    templateHelpers() {
        return {
            addBtnText: this.options.addBtnText,
        };
    },

});
