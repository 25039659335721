var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <button type=\"button\" class=\"btn btn-secondary inline-block\" data-hook=\"getDefaultButton\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.setasdefault",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":92},"end":{"line":2,"column":124}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"GridFilterHeader-toggle\">\n        <button data-hook=\"getToggleViewFilters\"\n            class=\"btn-tertiary\"\n            aria-expanded=\"false\"\n            aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.filters",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":51}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isVisibleIcon") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.filters",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":39}}}))
    + "\n        </button>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"icon-eye\"\n                data-placement=\"top\"\n                data-toggle=\"tooltip\"\n                title=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.filters",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":23},"end":{"line":15,"column":50}}}))
    + "\">\n              </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"icon-eye-line\"\n                  data-placement=\"top\"\n                  data-toggle=\"tooltip\"\n                  title=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.filters",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":25},"end":{"line":21,"column":52}}}))
    + "\">\n              </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canSetDefault") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":7}}})) != null ? stack1 : "")
    + "<span class=\"inline-block\" data-region=\"dropdownRegion\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"savedGridFilters") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":27,"column":7}}})) != null ? stack1 : "");
},"useData":true});