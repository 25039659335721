var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\"\n            data-hook=\"getScheduledExportsTab\" role=\"tab\">\n            <a class=\"NavTabs-link btn btn-tertiary\" data-action=\"showScheduledExports\">\n                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"GIR.Scheduled.Exports.Schedules",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":60}}}))
    + "\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\"\n            data-hook=\"getTransmissionProfilesTab\" role=\"tab\">\n            <a class=\"NavTabs-link btn btn-tertiary\" data-action=\"showTransmissionProfiles\">\n                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"GIR.Transmission.Profiles",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":54}}}))
    + "\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"GIR.Scheduled.Exports",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":65}}}))
    + "</h1>\n</div>\n<ul id=\"scheduled-exports-tablist\" class=\"NavTabs nav nav-tabs nav-icons\"\n    data-hook=\"getNavTabs\" role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isScheduleEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTransProfileEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n<div data-region=\"tabContentRegion\"></div>";
},"useData":true});