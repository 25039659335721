import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useValidation } from '@glu/validation-react';
import { withStyles } from '@glu/theming';
import styles from './EStatementRecipientCell.styles';
import ValidatedEditableTextCell from '../ValidatedEditableTextCell/ValidatedEditableTextCell';
import useLocale from '../../../hooks/useLocale';
import useModelListener from '../../../hooks/useModelListener';
import { getBooleanValue } from '../SwitchCell/constants';

const propTypes = {
    model: PropTypes.shape({
        get: PropTypes.func.isRequired,
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onBlur: PropTypes.func.isRequired,
    /*
     * This is a function so that the value can be based off
     * of entitlements which haven't resolve when the grid is instantiated
     */
    readOnly: PropTypes.func.isRequired,
};

const MAX_LENGTH = 254;

const EStatementRecipientCell = ({
    model,
    onBlur,
    classes,
    readOnly,
    ...props
}) => {
    const { getLocaleString } = useLocale();
    const initialData = {
        ESTATEMENTRECIPIENT_UPDATED: model.get('ESTATEMENTRECIPIENT_UPDATED'),
    };
    const [defaultFormValues, setDefaultValues] = useState(initialData);
    const [ValidationProvider, validationValue, { data, validate }] = useValidation({
        data: defaultFormValues,
    });
    const [isVisible, setIsVisible] = useState(getBooleanValue(model.get('PAPERLESS_ONOFF_UPDATED')));

    const onInputBlur = () => {
        validate().then(() => {
            onBlur('ESTATEMENTRECIPIENT_UPDATED', data.ESTATEMENTRECIPIENT_UPDATED, model);
        });
    };
    const onEmailUpdate = (email) => {
        setDefaultValues({
            ESTATEMENTRECIPIENT_UPDATED: email,
        });
    };
    useModelListener(model, 'switch:toggled', setIsVisible);
    /**
     * NH-143077 Updating a large number of rows is causing performance issues.
     * Listen for the custom model event and then update
     * the value for the email accordingly.
     */
    useModelListener(model, 'email:updated', onEmailUpdate);
    return (
        <ValidationProvider value={validationValue}>
            {readOnly()
                ? <span>{defaultFormValues.ESTATEMENTRECIPIENT_UPDATED}</span>
                : (
                    <>
                        {isVisible
                        && (
                            <div className={classes.container}>
                                <ValidatedEditableTextCell
                                    className={classes.input}
                                    model={model}
                                    validators={{
                                        emailAddress: true,
                                        name: getLocaleString('_ADMIN.PPRLESS.Email.Address'),
                                        required: true,
                                        maxLength: MAX_LENGTH,
                                    }}
                                    maxLength={MAX_LENGTH}
                                    editing={isVisible}
                                    name="ESTATEMENTRECIPIENT_UPDATED"
                                    onBlur={onInputBlur}
                                    aria-label={getLocaleString('_ADMIN.PPRLESS.Email.Address.placeholder')}
                                    placeholder={getLocaleString('_ADMIN.PPRLESS.Email.Address.placeholder')}
                                    {...props}
                                />
                            </div>
                        )}
                    </>
                )}
        </ValidationProvider>
    );
};

EStatementRecipientCell.propTypes = propTypes;

export default withStyles(styles)(EStatementRecipientCell);
