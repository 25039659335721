import util from '@glu/core/src/util';
import locale from '@glu/locale';
import cmConstants from 'app/checkManagement/constants';
import store from 'system/utilities/cache';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './listDecision.hbs';

const EPositivePayDecisionHistoryList = ListView.extend({
    template,

    id: 'pmtFraudCtrlElecItmsDecisionHistory',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'pmtFraudCtrlElecItmsDecisionHistoryTab',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
        },
    ),

    initialize(options) {
        const superOptions = this.getInitializeOptions(options);
        const optionWithGrid = { ...options, gridOptions: { caption: locale.get('CM.edecision-history') } };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, optionWithGrid));
    },

    /**
     * Creates a options object for the parent
     *
     * @param {object} [options] - an options object
     */
    getInitializeOptions(options) {
        const additionalSearchFields = options && options.additionalSearchFields
            ? options.additionalSearchFields : null;
        this.accountNumber = options.accountNumber;
        this.accountTitle = options.accountTitle;
        this.typeDesc = options.typeDesc;

        const dynamicServiceName = options && options.serviceName;

        return {
            serviceName: dynamicServiceName,
            serviceFunc: null,
            businessType: null,
            selector: 'rowSelector',
            returnRoute: this.options.returnRoute,
            enableSavedViews: false,
            additionalSearchFields,
            configContext: {
                productCode: cmConstants.CM,
                functionCode: cmConstants.BATCH,
                typeCode: cmConstants.ELECTRONIC_POS_PAY_TYPE,
                additonalLVCKey: cmConstants.HISTORY,
            },
        };
    },

    back() {
        this.navigateTo(this.options.returnRoute);
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(this, undefined);
        return util.extend(
            obj,
            {
                hideFields: self.options.hideFields,

                hasApproveEntitlement() {
                    return false;
                },

                hasUnapproveEntitlement() {
                    return false;
                },

                isHistory() {
                    return true;
                },

                accountName() {
                    return self.accountTitle;
                },

                accountNumber() {
                    return self.accountNumber;
                },

                type() {
                    return self.typeDesc;
                },

                gridUtilityOptions: {
                    hasRefresh: false,
                },
            },
        );
    },

    gridRowSelect(options) {
        const { model } = options;
        model.set('ACCOUNT_NUMBER', this.accountNumber);
        model.set('ACCOUNT_TITLE', this.accountTitle);
        store.set(`${this.contextKey}-actionModel`, model);
        this.navigateTo('RISK/viewEPositivePayDecision');
        return Promise.resolve();
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: this.getInquiryId(),
            summaryInquiryId: this.getInquiryId(),
            hasSummarySelection: false,
        };
    },

    export() {
        this.gridView.context.productCode = cmConstants.RISK;
        this.gridView.context.functionCode = cmConstants.BATCH;
        this.gridView.context.typeCode = cmConstants.ELECTRONIC_POS_PAY_TYPE;
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.getInquiryId(),
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },

    /**
     * Returns the inquiry id used by this grid
     * Used by print and export
     * @return {number} the inquiry id
     */
    getInquiryId() {
        if (this.options.serviceName === 'adminCM/fraud/listView/ePositivePayDecisionHistory') {
            return '20549';
        }
        return cmConstants.ELECTRONIC_POS_PAY_FILTERID;
    },
});

let list = EPositivePayDecisionHistoryList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
