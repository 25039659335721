import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useValidation } from '@glu/validation-react';
import { ValidatedInput } from '@glu/form-components';
import { Button, PRIMARY, TERTIARY } from '@glu/buttons-react';
import store from 'system/utilities/cache';
import RequiredLabelText from 'components/RequiredLabelText/RequiredLabelText';
import scheduledExportsUtil from 'app/scheduledExports/util';

import ActionButtonContainer from '../ActionButtonContainer/ActionButtonContainer';
import useRequest from '../../hooks/useRequest';
import useCache from '../../hooks/useCache';
import useNavigation from '../../hooks/useNavigation';
import useLocale from '../../hooks/useLocale';
import FormField from '../FormField/FormField';
import systemConfig from '../../system/configuration';
import UserGroupSelector from '../UserGroupSelector/UserGroupSelector';
import UserSelector from '../UserSelector/UserSelector';
import PageHeader from '../PageHeader/PageHeader';
import getUserGroups from '../UserGroupSelector/util';
import getUsers from '../UserSelector/util';

const { getUserGroupId } = scheduledExportsUtil;

const propTypes = {
    returnRoute: PropTypes.string.isRequired,
};

const getId = (selectedItems) => {
    if (!selectedItems.length) {
        return '';
    }
    const [selected] = selectedItems;
    return selected.id;
};

const CreateTransmissionProfileForm = ({
    returnRoute,
}) => {
    const [defaultFormValues, setDefaultFormValues] = useState({
        name: '',
        description: '',
        userGroup: [],
        userId: [],
    });
    const { getLocaleString } = useLocale();
    const [isSaving, setIsSaving] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const { set: setAlertMessage } = useCache('export_transmissionProfile-confirms');
    const { set: setAlertAction } = useCache('export_transmissionProfile-alertMessage');
    const { post } = useRequest();
    const [disableUser, setDisableUser] = useState(true);
    const { cancel } = useNavigation({
        returnRoute: store.get('ScheduledExportsWidget:returnRoute') || returnRoute,
    });
    const [ValidationProvider, validationValue, { data, validate }] = useValidation({
        data: defaultFormValues,
    });
    const {
        userGroup,
        userId,
    } = data;

    const userGroupSelected = (name, value) => {
        setDefaultFormValues({
            ...data,
            [name]: value,
        });
        setDisableUser(false);
    };
    const userSelected = (name, value) => {
        setDefaultFormValues({
            ...data,
            userGroup,
            [name]: value,
        });
    };

    const validateForm = () => {
        validate(null, true).then(({ isValid }) => {
            setFormIsValid(isValid);
        });
    };
    const save = () => {
        setIsSaving(true);
        const postData = {
            ...data,
            userGroup: getId(userGroup),
            userId: getId(userId),
        };
        const url = 'export/transmissionProfile/add';
        post(url, postData).then((response) => {
            setAlertMessage(response);
            setAlertAction('insert');
            cancel();
        });
    };
    const onFilterUserGroups = useCallback(value => getUserGroups(value, 9080), []);
    const onFilterUsers = useCallback(value => getUsers(
        getUserGroupId(userGroup),
        value,
        9081,
    ), [userGroup]);

    return (
        <ValidationProvider value={validationValue}>
            <PageHeader headingText={getLocaleString('GIR.Create.Transmission.Profile')} />
            <section className="section section-container">
                <form>
                    <fieldset>
                        { systemConfig.isAdmin() && (
                            <div className="row">
                                <FormField
                                    className="col-3"
                                >
                                    <UserGroupSelector
                                        labelText={(
                                            <RequiredLabelText
                                                label={getLocaleString('GIR.User.Group')}
                                            />
                                        )}
                                        name="userGroup"
                                        validators={{
                                            name: getLocaleString('GIR.User.Group'),
                                            arrayNotEmpty: true,
                                        }}
                                        onFilter={onFilterUserGroups}
                                        onChange={userGroupSelected}
                                        selectedOptions={userGroup}
                                        onBlur={validateForm}
                                    />
                                </FormField>
                                <FormField
                                    className="col-3"
                                >
                                    <UserSelector
                                        labelText={(
                                            <RequiredLabelText
                                                label={getLocaleString('GIR.User')}
                                            />
                                        )}
                                        name="userId"
                                        validators={{
                                            name: getLocaleString('GIR.User'),
                                            arrayNotEmpty: true,
                                        }}
                                        userGroupId={getUserGroupId(userGroup)}
                                        onFilter={onFilterUsers}
                                        onChange={userSelected}
                                        selectedOptions={userId}
                                        disabled={disableUser}
                                        onBlur={validateForm}
                                    />
                                </FormField>
                            </div>
                        )}
                        <div className="row">
                            <FormField
                                className="col-3"
                            >
                                <ValidatedInput
                                    labelText={(
                                        <RequiredLabelText
                                            label={getLocaleString('GIR.Profile.Name')}
                                        />
                                    )}
                                    name="name"
                                    validators={{
                                        name: getLocaleString('GIR.Profile.Name'),
                                        required: true,
                                        maxLength: 20,
                                    }}
                                    maxLength="20"
                                    onBlur={validateForm}
                                />
                            </FormField>
                            <FormField
                                className="col-3"
                            >
                                <ValidatedInput
                                    labelText={(
                                        <RequiredLabelText
                                            label={getLocaleString('GIR.Description')}
                                        />
                                    )}
                                    name="description"
                                    validators={{
                                        name: getLocaleString('GIR.Description'),
                                        required: true,
                                        maxLength: 60,
                                    }}
                                    maxLength="60"
                                    Component={ValidatedInput}
                                    onBlur={validateForm}
                                />
                            </FormField>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <p>{getLocaleString('GIR.Transmission.profile.creation.instructions')}</p>
                            </div>
                        </div>
                    </fieldset>
                    <ActionButtonContainer>
                        <Button
                            variant={PRIMARY}
                            text={getLocaleString('button.continue')}
                            disabled={!formIsValid || isSaving}
                            onClick={save}
                        />
                        <Button
                            variant={TERTIARY}
                            text={getLocaleString('button.cancel')}
                            onClick={cancel}
                        />
                    </ActionButtonContainer>
                </form>
            </section>
        </ValidationProvider>
    );
};
CreateTransmissionProfileForm.propTypes = propTypes;

export default CreateTransmissionProfileForm;
