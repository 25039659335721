import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import $ from 'jquery';
import AuditHistoryView from 'app/reports/views/auditHistoryView';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import ExportOptionsModal from 'common/modals/exportOptions';
import PrintOptionsModal from 'common/modals/printOptions';
import userInfo from 'etc/userInfo';
import services from 'services';
import systemConfig from 'system/configuration';
import helpers from 'components/AsyncReporting/helpers';
import locale from '@glu/locale';

export default {

    /**
     * Generates a document which can be printed.
     *
     * @param {object} options - an options object
     * @param {object} options.view - a backbone view
     * @param {object} options.gridView - a grid view object
     * @param {string} options.inquiryId - a inquiry id
     * @param {string} [options.format] - an optional format. Defaults to 'PDF'
     * @param {string} [options.title] - an optional title for the document
     * @param {string} [options.exportURL] - an optional server URL to override the default
     */
    print(options) {
        const optionsParam = options;
        optionsParam.format = optionsParam.format || 'PDF';
        const exportModel = this.buildExportModel(optionsParam);
        const printModal = new PrintViewModal({
            exportModel,
            exportURL: optionsParam.exportURL,
            title: optionsParam.title,
        });
        if (dialog.activeModal) {
            printModal.$el.css('z-index', '1061');
        }
        dialog.custom(printModal);
    },

    /**
     * Get the print options
     */
    getExportOptions() {
        throw new Error('getExportOptions not implemented');
    },

    /**
     * Render a print option view in a dialog and listen to the print:view:modal event
     */
    showExportOptionsModal() {
        const exportOptions = this.getExportOptions();
        const rows = this.gridView.grid.getSelectedRows();
        const defaultOptions = {
            selectedRows: rows.length,
            totalItems: this.gridView.grid.masterCollection.totalCount,
            gridView: this.gridView,
            modalClass: 'export-view-modal',
        };
        const options = util.extend(defaultOptions, exportOptions);

        this.exportOptionsModal = new ExportOptionsModal(options);
        this.listenTo(this.exportOptionsModal, 'export', this.export.bind(this));
        dialog.open(this.exportOptionsModal);
    },

    /**
     * Generates a document for export.
     *
     * @param {object} options - an options object
     * @param {object} options.view - a backbone view
     * @param {object} options.gridView - a grid view object
     * @param {string} options.inquiryId - a inquiry id
     * @param {string} [options.format] - an optional format. Defaults to 'CSV'
     * @param {string} [options.exportURL] - an optional server URL to override the default
     */
    export(options = {}) {
        const optionsParam = options;
        /*
         * NH-94418 sometimes export function is the callback for a click event
         * TODO remove when all exporting is done via print export modal
         */
        optionsParam.gridView = options.gridView || this.gridView;

        optionsParam.format = optionsParam.format || 'CSV';
        optionsParam.exportModel = this.buildExportModel(optionsParam);
        this.doExport(optionsParam);
    },

    /**
     * method gathers the information for the export for non payments list views
     * @param {Object} optionsParam - holds the properties chose on the export dialog
     * @returns {Object}
     */
    buildExportModel(optionsParam) {
        const isExtended = util.isFunction(this.initialize);
        let options = optionsParam;

        if (isExtended && util.isString(optionsParam)) {
            /*
             * We could just add format to `this`
             * this.format = optionsParam;
             * options = this;
             */

            // Collect the potential fields from `this`
            options = [
                'searchFields',
                'gridView',
                'customFilters',
                'inquiryId',
                'contextData',
                'selection',
                'expdate',
                'columns',
            ].reduce((acc, key) => {
                acc[key] = this[key];
                return acc;
            }, {
                format: optionsParam,
            });
        }

        let searchFields = options.searchFields || [];
        const contextExists = options.gridView && options.gridView.context;
        const actionCxtExists = contextExists && options.gridView.context.actionContext;
        const exportModel = {
            outputFormat: options.format,
            pageType: 'LETTER',
            numberOfRows: 0,
            viewId: options.gridView.wrapper.viewId,
            actionData: {
                productCode: (contextExists && options.gridView.context.productCode)
                    || (actionCxtExists && options.gridView.context.actionContext.productCode),
                functionCode: (contextExists && options.gridView.context.functionCode)
                    || (actionCxtExists && options.gridView.context.actionContext.functionCode),
                typeCode: (contextExists && options.gridView.context.typeCode)
                    || (actionCxtExists && options.gridView.context.actionContext.typeCode),
                actionMode: (contextExists && options.gridView.context.actionMode)
                    || (actionCxtExists && options.gridView.context.actionContext.actionMode),
            },
            customFilters: options.customFilters,
            inquiryId: options.inquiryId,
            contextData: options.contextData,
        };

        // Check for Sort by Column
        if (options.gridView.wrapper.sortKey !== null) {
            exportModel.sortFields = [{
                fieldName: options.gridView.wrapper.sortKey,
                sortOrder: options.gridView.wrapper.sortOrder,
            }];
        }

        // When we have a selection of rows, add the keyList to the searchFields
        if (options.selection === 'sel') {
            // Using concat because array spread caused failing tests
            searchFields = searchFields
                .concat(this.getSearchFieldsByKeyList(options.gridView));
        }
        /*
         * Combine search fields with filers from the grid
         * Using concat because array spread caused failing tests
         */
        exportModel.searchFields = searchFields
            .concat(options.gridView.wrapper.generateFiltersDataBlock());

        /*
         * NH-130560 with a tiered loan export, we need to remove a specific searchField to
         * ensure that all of the correct nested child data is included in the export
         */
        if (options.excludeSearchFields && options.excludeSearchFields.length) {
            exportModel.searchFields = exportModel.searchFields
                .filter(field => !options.excludeSearchFields.includes(field.fieldName));
        }

        // exporting detailed report do not include columns, just return the exportModel
        if (options.expdata === 'detail') {
            return exportModel;
        }
        // NH-113135 - for payment list views, the columns are added separately.
        if (!optionsParam.isPayment) {
            // Get columns and assign to the model
            if (options.columns) {
                exportModel.columns = options.columns;
            } else {
                // Get columns and order from the gridview
                exportModel.columns = options.gridView.wrapper.columns
                    .reduce((accum, model) => {
                        if (model.get('condition') !== false) {
                            return [...accum, model.get('field')];
                        }
                        return accum;
                    }, []);
            }
        }

        if (exportModel.actionData.typeCode === 'CORBNKMP') {
            exportModel.inquiryId = 20156;
        }

        // Code to run when it is extended into a View
        if (isExtended) {
            this.exportModel = exportModel;
        }

        return exportModel;
    },

    /**
     * Get the print options
     */
    getPrintOptions() {
        throw new Error('getPrintOptions not implemented');
    },

    /**
     * Render a print option view in a dialog and listen to the print:view:modal event
     */
    showPrintOptionsModal() {
        const printOptions = this.getPrintOptions();
        const rows = this.gridView.grid.getSelectedRows();
        const defaultOptions = {
            selectedRows: rows.length,
            totalItems: this.gridView.grid.masterCollection.totalCount,
        };
        const options = util.extend(defaultOptions, printOptions);

        this.printOptionsModal = new PrintOptionsModal(options);
        this.listenTo(this.printOptionsModal, 'print:view:modal', this.showPrintViewModal);
        dialog.open(this.printOptionsModal);
    },

    /**
     * Render the print view in a custom dialog
     * @param {Model} printModel - Backbone model
     */
    showPrintViewModal(printModel) {
        let options;

        if (printModel) {
            options = {
                exportModel: this.buildPrintModel(printModel, this.gridView, printModel.get('inquiryId')),
                exportURL: printModel.get('exportURL'),
            };
        } else {
            options = {
                exportModel: this.buildExportModel('PDF'),
                title: `${this.localeKey}title`,
            };
        }

        dialog.custom(new PrintViewModal(options));
    },

    /**
     * get the action data for printing, separating this from the  buildPrintModel to override
     *  the action data in the child list views
     * @param {View} gridView
     * @returns {Object}
     */
    getActionData(gridView, inquiryId) {
        return {
            productCode: gridView.context.productCode
            || gridView.context.actionContext.productCode,
            functionCode: this.getFunctionCodeChange?.() || gridView.context.functionCode
            || gridView.context.actionContext.functionCode,
            typeCode: this.getTypeCodeChange?.(inquiryId) || gridView.context.typeCode
            || gridView.context.actionContext.typeCode,
        };
    },

    /**
     * adds additional search fields, to override in the child list views.
     * @returns {Object}
     */
    addAdditionalSearchFields() {
        return [];
    },

    /**
     * Build an object with all of the attributes required for printing
     * @param {Model} model
     * @param {View} gridView
     * @param {string} inquiryId
     * @returns {Object}
     */
    buildPrintModel(model, gridView, inquiryId) {
        let searchFields = [];
        const exportModel = {
            outputFormat: 'PDF',
            pageType: 'LETTER',
            expData: model.get('expdata'),
            numberOfRows: 0,
            inquiryId,
            viewId: gridView.wrapper.viewId,
            detailReportId: gridView.wrapper.detailReportId || 0,
            actionData: this.getActionData(gridView, inquiryId),
            contextData: model.get('contextData'),
        };

        // send includeSummaryInfo in the payload only if showIncludeSummaryInfo is true
        const showIncludeSummaryInfo = model.get('showIncludeSummaryInfo');
        if (showIncludeSummaryInfo) {
            exportModel.includeSummaryInfo = model.get('includeSummaryInfo') ? 1 : 0;
        }

        // Check for Sort by Column
        if (gridView.wrapper.sortKey !== null) {
            exportModel.sortFields = [{
                fieldName: gridView.wrapper.sortKey,
                sortOrder: gridView.wrapper.sortOrder,
            }];
        }

        if (model.get('selection') === 'sel') {
            if (exportModel.actionData.typeCode === 'REQIN' && systemConfig.isAdmin()) {
                searchFields = this.getSearchFieldsForAdmin(gridView);
            } else {
                searchFields = this.getSearchFieldsByKeyList(gridView);
            }
        }

        // Check for Filtering
        searchFields = searchFields.concat(gridView.wrapper.generateFiltersDataBlock())
            .concat(this.addAdditionalSearchFields());

        if (searchFields.length > 0) {
            exportModel.searchFields = searchFields;
        }

        /*
         * In most cases, inquiryId is only used for detailed print,
         * BUT sometimes summary requires inquiryId. The backend service doesn't evaluate
         * expData and therefore only references inquiryId when determining how to process the
         * request. This is why in some situation, inquiryId must be set to null.
         */
        if (exportModel.expData === 'summary' && !model.get('summaryRequiresInquiryId')) {
            exportModel.inquiryId = null;
        }

        if (exportModel.expData !== 'transaction') {
            // Get Columns and Order
            const colCondition = this.filterColumnByCondition;
            const colFieldAttr = this.getColumnFieldAttribute;
            const wrapperCoumns = gridView.wrapper.columns;
            exportModel.columns = wrapperCoumns.filter(colCondition).map(colFieldAttr);
        }

        return exportModel;
    },

    /**
     * Pull the key list from the gridView and return an array of search fields
     * based on the row data
     * @param {Object} gridView - gridView
     * @returns {Array} - array of search fields
     */
    getSearchFieldsByKeyList(gridView) {
        const { keyList } = gridView.wrapper.rows;
        const rows = gridView.grid.getSelectedRows();

        return keyList.map(key => ({
            fieldName: key,
            operator: 'IN',
            fieldValue: rows.map(this.getKeyValueByRow.bind(this, gridView, key)),
            dataType: key === 'MESSAGE_ID' ? 'text' : 'number',
        }));
    },

    /**
     * This function generates a key filter from the received request list view that
     * overrides the existing key fields since the key also requires user group from
     * the admin context. Sending a key field array of two fields returns the superset
     * of matching data so a single concatenated field must be used.
     *
     * @param {Object} gridView - gridView
     * @returns {Array} - array of search fields
     */
    getSearchFieldsForAdmin(gridView) {
        const rows = gridView.grid.getSelectedRows();

        return [
            {
                fieldName: 'UNIQUE_ID',
                operator: 'IN',
                fieldValue: rows.map(this.getKeyValueByRow.bind(this, gridView, 'UNIQUE_ID')),
                dataType: 'string',
            },
        ];
    },

    /**
     * Based on the gridview and the row, get the row data and then
     * return value for the key parameter
     * @param {Object} gridView - gridView
     * @param {string} key - attribute to get on the model
     * @param {Object} row - row model
     * @returns {string} - value of specified attribute
     */
    getKeyValueByRow(gridView, key, row) {
        const model = gridView.wrapper.rows.get(row);
        return model.get(key);
    },

    /**
     * Check column by condition
     * @param {Object} column
     * @returns {boolean}
     */
    filterColumnByCondition(column) {
        return column.get('condition') !== false;
    },

    /**
     * Get the column field attribute
     * @param {Object} column
     */
    getColumnFieldAttribute(column) {
        return column.get('field');
    },

    doExport(options) {
        let optionsParam = options;
        if (!optionsParam && this.options) {
            optionsParam = this.options;
        }
        if (optionsParam && !optionsParam.exportModel && this.exportModel) {
            optionsParam.exportModel = this.exportModel;
        }
        const target = (optionsParam && optionsParam.target) || '_self';

        if (!systemConfig.isAdmin()) {
            const validType = ['DEXAUDIT', 'RFPAUDIT'].includes(optionsParam.exportModel.actionData.typeCode);
            if (validType || $('#AUDITSECTION').length === 1) {
                optionsParam.exportURL = this.isPassthru(this.detailModel)
                    ? services.runAsyncPassthruDetailAuditViewReport
                    : services.runAsyncDetailAuditViewReport;
            }
            helpers.handleExportAsyncReport({
                service: optionsParam.exportURL || services.runAsyncListViewReport,
                postData: optionsParam.exportModel,
            }, dialog);
            return;
        }

        if ($('#listViewExportForm').length === 0) {
            const form = `<form id="listViewExportForm"  method="get" target="${target}" rel="noopener">
                            <input id="listViewRequest" type="hidden" name="listViewRequest" >
                          </form>`;
            if (optionsParam && optionsParam.view && optionsParam.view.$el) {
                optionsParam.view.$el.append(form);
            } else if (this.$el) {
                this.$el.append(form);
            }
        }
        $('#listViewExportForm').attr('action', services.generateUrl(optionsParam.exportURL || '/export/listView'));
        $('#listViewExportForm #listViewRequest').val(JSON.stringify(optionsParam.exportModel));
        $('#listViewExportForm').submit();
    },

    /**
     * @description returns a model that is used for the AuditHistoryView
     * @param {object} model - detail model
     */
    setupAuditHistoryModel(model) {
        const typeCode = model.get('TYPE');
        const productCode = model.get('PRODUCT');
        let idKey = 'TNUM';
        let keyValue = model.get('TNUM');
        let reportKey = 'TNUM';
        if (productCode === 'USACH') {
            reportKey = 'BATCHTNUM';
        } else if (typeCode === 'REQIN') {
            reportKey = 'MESSAGE_ID';
            idKey = 'MESSAGE_ID';
            keyValue = model.get('MESSAGE_ID');
        }
        const functionCode = model.get('FUNCTION');
        const additionalParameters = [{
            name: idKey,
            value: keyValue,
        }, {
            name: 'REPORTKEY',
            value: reportKey,
        }, {
            name: 'PRODUCTCODE',
            value: productCode,
        }, {
            name: 'FUNCTIONCODE',
            value: functionCode,
        }, {
            name: 'TYPECODE',
            value: typeCode,
        }, {
            name: 'SUBTYPE',
            value: model.get('SUBTYPE'),
        }, {
            name: 'BANKUSERGROUP',
            value: model.get('PARENTUSERGROUP'),
        }, {
            name: 'LOCALE',
            value: userInfo.getLocale(),
        }];
        return new Model({
            productCode: this.getAuditHistoryModelProductCode(model),
            reportId: this.getAuditHistoryModelReportId(model),
            filterData: '',
            additionalParameters,
        });
    },

    /**
     * @description displays the view history report for a payment/template in a modal dialog
     * @param {object} model
     */
    viewAuditHistory(model) {
        if (model.get('FEATUREHEADERID')) {
            dialog.close();
            this.print();
            return;
        }
        const reportModel = this.setupAuditHistoryModel(model);
        const auditHistoryView = new AuditHistoryView({
            model: reportModel,
        });
        auditHistoryView.dialogTitle = locale.get('async.report');
        dialog.open(auditHistoryView);
    },

    /**
     * @description get the product code for the report model, default to RTGS
     * @param {object} model
     */
    getAuditHistoryModelProductCode(model) {
        const typeCode = model.get('TYPE');
        let productCode = '';
        switch (typeCode) {
        case 'REQIN':
        case 'REQOUT':
            productCode = 'RTP';
            break;
        default:
            productCode = 'RTGS';
        }
        return productCode;
    },

    /**
     * @description get the report id for the report model, default to 20300
     * @param {object} model
     */
    getAuditHistoryModelReportId(model) {
        const functionCode = model.get('FUNCTION');
        const typeCode = model.get('TYPE');
        let reportId = '';
        switch (functionCode) {
        case 'TMPL':
        case 'BHTMPL':
            reportId = '20310';
            break;
        case 'REQUEST':
            if (typeCode === 'REQIN') {
                reportId = '20320';
            } else if (typeCode === 'REQOUT') {
                reportId = '20301';
            } else {
                reportId = '20300';
            }
            break;
        case 'REQTMPL':
            reportId = '20311';
            break;
        default:
            reportId = '20300';
        }
        return reportId;
    },

    fileDownload(data, textStatus, request) {
        const contentType = request.getResponseHeader('content-type');
        const contentDisposition = request.getResponseHeader('content-disposition');
        const elements = contentDisposition.split(';');
        const filename = elements.length > 1
            ? elements[1].replace(/filename=|"/g, '')
            : 'file.csv';
        const isFirefox = typeof InstallTrigger !== 'undefined';

        if (filename && textStatus === 'success') {
            const binaryData = [];
            binaryData.push(data);

            const blob = new Blob(binaryData, { type: contentType });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.download = filename;
            if (isFirefox) {
                a.setAttribute('type', 'hidden');
                document.body.appendChild(a);
            }
            a.click();

            // for IE
            if (navigator && navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, filename);
            }
            window.URL.revokeObjectURL(url);

            if (isFirefox) {
                a.remove();
            }
        }
    },

    /**
     * Check if the detailModel record is PASSTHRU
     * @param {Object} detailModel
     * @returns {boolean}
     */
    isPassthru(detailModel) {
        return !!detailModel && detailModel.get('TYPE') === 'BDACHNFI' && !!detailModel.get('SURROGATETNUM');
    },
};
