var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog print-modal modal-2xlg glu-dialog\">\n    <form class=\"modal-content\"\n          data-submit=\"save\"\n          data-hook=\"getModalContent\">\n        <div class=\"modal-header\">\n            <button type=\"button\"\n                    class=\"close\"\n                    data-action=\"close\">\n                <span class=\"icon-close\"\n                      title=\"Close\">\n                </span>\n            </button>\n            <h2 class=\"modal-title\"\n                id=\"modal-title\">\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"DISPLAYNAME") || (depth0 != null ? lookupProperty(depth0,"DISPLAYNAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DISPLAYNAME","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":31}}}) : helper)))
    + "\n            </h2>\n        </div>\n        <div class=\"modal-body\"\n             data-hook=\"getModalBody\">\n            <div class=\"row\">\n                <div class=\"col-md-12\"\n                     data-hook=\"getAlertRegion\"\n                     id=\"alertRegion\">\n                </div>\n            </div>\n            <div class=\"row report-row\">\n                <div data-region=\"content\"\n                     class=\"reportmodal\"\n                     aria-live=\"polite\">\n                </div>\n            </div>\n            <div>\n                <div data-region=\"export\"\n                     aria-live=\"polite\">\n                </div>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\"\n                    class=\"btn btn-secondary\"\n                    data-action=\"cancel\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":42,"column":42}}}))
    + "\n            </button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});