import ButtonView from 'common/buttonView';
import locale from '@glu/locale';

const RefreshBalancesButton = ButtonView.extend({
    className: 'btn btn-link',

    attributes: {
        name: 'refreshBalances',
    },

    icon() {
        return 'refresh';
    },

    label() {
        return locale.get('gir.refreshBalances');
    },

    onClick() {
        if (this.model.isReady()) {
            this.model.start(true);
        }
    },

    $toggleClass(path, className, flag) {
        /*
         * Regular toggleClass() does not work with SVG.
         * http://stackoverflow.com/questions/8638621/jquery-svg-why-cant-i-addclass
         */
        this.$(path).attr('class', (index, classNames) => {
            if (flag) {
                if (classNames.indexOf(className) > 0) {
                    return classNames;
                }
                return `${classNames} ${className}`;
            }
            return classNames.replace(className, '');
        });
    },

    refresh() {
        this.$toggleClass('.icon', 'animate-spin', this.model.isWorking());
        this.$el.prop('disabled', this.model.isSleeping());
    },
});

export default RefreshBalancesButton;
