import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleNextIcon } from '@glu/icons-react';
import locale from '@glu/locale';

export default function DeleteConfirmation(props) {
  const {
    classes,
    deleteName,
    setViewsVisibility,
    toggleDeleteConfirmation,
    view
  } = props;
  const {
    deleteIcon,
    deleteText,
    deleteWrapper
  } = classes;

  return (
    <div className={deleteWrapper}>
      <ExclamationTriangleNextIcon className={deleteIcon} />
      <span className={deleteText}>
        {locale.get('dataComponents.deleteThisView')}
      </span>

      <button
        data-qa={`delete-confirm-${view.viewData.id}`}
        onClick={() => {
          deleteName(view.viewData.id);
          setViewsVisibility((views) => {
            const { [view.viewData.id]: viewToDelete, ...viewsWithoutDeleted } = views;
            return viewsWithoutDeleted;
          });
          toggleDeleteConfirmation(false);
        }}
        type="button"
      >
        {locale.get('dataComponents.yes')}
      </button>
      <button
        data-qa={`delete-cancel-${view.viewData.id}`}
        onClick={() => {
          toggleDeleteConfirmation(false);
        }}
        type="button"
      >
        {locale.get('dataComponents.no')}
      </button>
    </div>
  );
}

DeleteConfirmation.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  deleteName: PropTypes.func.isRequired,
  setViewsVisibility: PropTypes.func,
  toggleDeleteConfirmation: PropTypes.func.isRequired,
  view: PropTypes.shape({
    viewData: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isDefault: PropTypes.bool,
      isStatic: PropTypes.bool,
      name: PropTypes.string
    })
  })
};

DeleteConfirmation.defaultProps = {
  setViewsVisibility() {},
  view: PropTypes.shape({
    id: 0,
    name: ''
  })
};
