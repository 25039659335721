var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content\">\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.tenant.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":65}}}))
    + "</h1>\n        <div class=\"page-header\"></div>\n    </div>\n    \n    <section class=\"section section-container\">\n            <div class=\"section-body\">\n            <section class=\"header\" data-region=\"header\">\n                <span class=\"col-md-8\">\n                        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.quickbooks.enter.keys",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":68}}}))
    + "</h3>\n                        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.quickbooks.find.keys.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":69}}}))
    + " <a href=\"http://developer.intuit.com\" target=\"_blank\">http://developer.intuit.com</a>\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.quickbooks.find.keys.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":61}}}))
    + "</h4>\n                </span>\n            </section>\n            <section class=\"content\" data-region=\"content\">\n                    <form>\n                                <div>\n                                    <div class=\"field-container-md required\">\n                                        <label for=\"appToken\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.app.token",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":81},"end":{"line":20,"column":109}}}))
    + "</label>\n                                        <input type=\"text\" class=\"form-control\" name=\"appToken\" data-bind=\"model\" aria-required=\"true\">\n                                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"appToken\"></span>\n                                    </div>\n                                </div>\n                                <div>\n                                    <div class=\"field-container-md required\">\n                                        <label for=\"consumerKey\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.oauth.key",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":84},"end":{"line":27,"column":112}}}))
    + "</label>\n                                        <input type=\"text\" class=\"form-control\" name=\"consumerKey\" data-bind=\"model\" aria-required=\"true\">\n                                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"consumerKey\"></span>\n                                    </div>\n                                </div>\n                                <div>\n                                    <div class=\"field-container-md required\">\n                                        <label for=\"consumerSecret\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"setup.oauth.secret",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":68},"end":{"line":34,"column":99}}}))
    + "</label>\n                                        <input type=\"text\" class=\"form-control\" name=\"consumerSecret\" data-bind=\"model\" aria-required=\"true\">\n                                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"consumerSecret\"></span>\n                                    </div>\n                                </div>\n                            </form>\n                    </section>\n                    <section class=\"footer\" data-region=\"footer\">\n                        <div class=\"col-md-12 widget-action-btn-group\">\n                            <button id=\"btn-save\" type=\"save\" class=\"btn btn-primary\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":105},"end":{"line":43,"column":129}}}))
    + "</button>\n                        </div>\n                    </section>\n                </div>\n        </section>\n</div>\n";
},"useData":true});