import $ from 'jquery';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import http from '@glu/core/src/http';
import services from 'services';
import accountBalances from './accountBalance';

export default {
    getFunctionCode(formView, pCode) {
        let fCode;
        let entryMethod;
        const context = formView.context.actionContext || formView.context.actionData;
        if (context) {
            fCode = context.functionCode;
        } else {
            entryMethod = formView.model.get('ENTRYMETHOD');
            fCode = (entryMethod === '1') ? 'INST' : 'TMPL';
        }
        if (pCode === 'USACH') {
            fCode = 'BATCH';
        }
        return fCode;
    },

    /**
     * Determines the parameters to be used when retrieving account balances
     * @param {object} formView - the current forms view
     * @param {string} id - the ID of the account dropdown combobox widget
     * @param {string} pCode - the product code for the balance retrieval call
     * @param {string} tCode - the type code for the balance retrieval call
     * @param {string} bankCode - the name of the type of bank code
     * @param {string} theCurrency - the name of the type of currency
     * @param {string} balanceSelector - a jquery selector to be used to find the balance?
     */
    getAccountBalances(formView, id, pCode, tCode, bankCode, theCurrency, balanceSelector) {
        if (['ACCOUNTFILTER', 'DEBIT_ACCOUNT_NUMBER', 'COMPIDNAME'].indexOf(id) !== -1) {
            const formField = formView.$el.find(`[name="${id}"]`);
            if (formField && formField.data('ui-widget')) {
                // fields that have been converted to ui widgets load their own balances
                return;
            }
        }

        const { model } = formView;
        const { functionCode } = model.jsonData.typeInfo;
        const dispAcctBalance = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens');

        /*
         * if we don't want account balances shown, or there's no jsonData on this model, or
         * this is a template, then don't retrieve account balances
         */
        if (dispAcctBalance !== 'true' || !model.jsonData || functionCode === 'TMPL') {
            return;
        }

        const collections = formView.comboCollections;
        const fCode = this.getFunctionCode(formView, pCode);
        const selector = balanceSelector || null;
        const self = this;
        const form = formView.$(`#${id}`);

        form.on('change', (selection) => {
            if (selection.added) {
                self.getSingleAccountBalance(
                    formView,
                    id,
                    pCode,
                    tCode,
                    bankCode,
                    theCurrency,
                    balanceSelector,
                    selection,
                );
            }
        });

        if (formView.state === 'modify') {
            form.one('select2-open', () => {
                if (!form.hasClass('allBalancesRequested')) {
                    accountBalances.getAccountBalances(
                        formView,
                        id,
                        collections[id],
                        {
                            productCode: pCode,
                            functionCode: fCode,
                            typeCode: tCode,
                            bankCode,
                            currency: theCurrency,
                            balanceSelector: selector,
                            openOnSuccess: true,
                        },
                    );
                    form.addClass('allBalancesRequested');
                }
            });

            if (!form.hasClass('initBalanceRequested')) {
                this.getSingleAccountBalance(
                    formView,
                    id,
                    pCode,
                    tCode,
                    bankCode,
                    theCurrency,
                    balanceSelector,
                );
                form.addClass('initBalanceRequested');
                return;
            }

            accountBalances.getAccountBalances(
                formView,
                id,
                collections[id],
                {
                    productCode: pCode,
                    functionCode: fCode,
                    typeCode: tCode,
                    bankCode,
                    currency: theCurrency,
                    balanceSelector: selector,
                },
            );
            form.addClass('allBalancesRequested');
            return;
        }

        if (formView.state === 'view') {
            this.getSingleAccountBalance(
                formView,
                id,
                pCode,
                tCode,
                bankCode,
                theCurrency,
                balanceSelector,
            );
            return;
        }

        accountBalances.getAccountBalances(
            formView,
            id,
            collections[id],
            {
                productCode: pCode,
                functionCode: fCode,
                typeCode: tCode,
                bankCode,
                currency: theCurrency,
                balanceSelector: selector,
            },
        );
    },

    getSingleAccountBalance(
        formView,
        id,
        pCode,
        tCode,
        bankCode,
        theCurrency,
        balanceSelector,
        selection,
    ) {
        const collections = formView.comboCollections;
        const fCode = this.getFunctionCode(formView);
        const selector = balanceSelector || null;

        accountBalances.getSingleAccountBalance(
            formView,
            id,
            collections[id],
            {
                productCode: pCode,
                functionCode: fCode,
                typeCode: tCode,
                bankCode,
                currency: theCurrency,
                balanceSelector: selector,
                selection,
            },
        );
    },

    /*
     * if we are in view mode, or reverse mode, or status is entered, incomplete
     * approval, needs rate, or 2nd approval needed,
     * reversal awaiting approval, partial reversal awaiting approval, reversal
     * (incomplete approval), partial reversal (incomplete approval)
     * or hidecutoff is defined and includes the type, do NOT show cutoff verbiage
     */
    hideCutoff(formState, functionCode, hidecutoff, type, status) {
        const isView = formState.toUpperCase() === 'VIEW';
        const isTemplate = functionCode === 'BHTMPL' || functionCode === 'TMPL';
        const isHideCutoff = hidecutoff && hidecutoff.indexOf(type) !== -1;
        const isIncompleteStatus = ['EN', 'IA', 'RT', 'HV', 'RA', 'RI', 'IV', 'PI'].indexOf(status) === -1;
        const isNoShowCondition = !isView || isTemplate || isHideCutoff;

        if (isNoShowCondition || (isView && isIncompleteStatus)) {
            $('#CUTOFF_INFO').addClass('hidden');
        }
    },

    /**
     * toggleStateProvince
     * @param {model} model - model.
     * @param {string} country - country value passed in.
     * @param {object} $province - field representation of bene_province
     * @param {object} $state - field representation of bene_state
     * return {boolean} isState - true if state, false if province
     */
    toggleStateProvince(model, country, $province, $state) {
        let isState = true;
        // if country is US, then show state drop down, if not, then show province/State textbox
        if (country === 'US') {
            $province.find('input').val('');
            $province.hide();
            $state.show();
            model.set(
                'BENE_PROVINCE',
                '',
                {
                    silent: true,
                },
            );
        } else {
            if (!country) {
                model.set(
                    'BENE_COUNTRY',
                    '',
                    {
                        silent: true,
                    },
                );
            }
            $province.show();
            if ($state.find('select').val()) {
                $state.find('select').val(null).trigger('change');
            }
            $state.hide();
            model.set(
                'BENE_STATE',
                '',
                {
                    silent: true,
                },
            );
            isState = false;
        }
        return isState;
    },

    /**
     * toggleCityCountryMandatory
     * @param {model} model - model
     * @param {object} city - field representing beneficiary city
     * @param {object} country - field representing beneficiary country
     */
    toggleCityCountryMandatory(model, city, country) {
        const structAddrValidation = applicationConfigParams.getValue('PAYMENTS', 'STRUCTUREDADDRESSVALIDATION');
        const hasAddress = !!model.get('BENE_ADDRESS_1') || (!model.get('BENE_ADDRESS_1') && !!model.get('BENE_ADDRESS_2'));
        // only validate if validation is on.
        if (structAddrValidation === '1') {
            city.shouldBeRequiredWhen(hasAddress).$el.parent().toggleClass('required', hasAddress);
            country.shouldBeRequiredWhen(hasAddress).$el.parent().toggleClass('required', hasAddress);
        }
    },

    /**
     * toggleAddress1Info
     * @param {model} model - model
     * @param {object} beneAddress1 - F1 field representing beneficiary address 1
     * @param {object} beneAddress2 - F1 field representing beneficiary address 2
     */
    toggleAddressInfo(model, beneAddress1, beneAddress2) {
        const structAddrValidation = applicationConfigParams.getValue('PAYMENTS', 'STRUCTUREDADDRESSVALIDATION');
        if (structAddrValidation === '1') {
            // clear out the info
            beneAddress1.$el.siblings('.textline-field').text('');
            beneAddress2.$el.siblings('.textline-field').text('');
        }
    },

    /**
     * getValueFromRTGSPreferences
     * @param {typeCode} typeCode - typeCode
     */
    getValueFromRTGSPreferences(typeCode) {
        const url = services.generateUrl('/inquiry/getData');

        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 19035,
                    searchFields: [{
                        fieldName: 'TYPECODE',
                        fieldValue: [typeCode],
                        dataType: 'text',
                        operator: '=',
                    }],

                },
            },
        };

        return http.post(url, postData);
    },

    /**
     * parseRTGSPreferencesValue
     * @param {response} response - response returned by inquiry/getData
     */
    parseRTGSPreferencesValue(response) {
        if (response && response.inquiryResponse
            && response.inquiryResponse.rows[0]
            && response.inquiryResponse.rows[0].columns[0]) {
            return response.inquiryResponse.rows[0].columns[0].fieldValue;
        }
        return '1';
    },

    /**
     * isSecondaryBankNameAdd1Req
     * @param {typeCode} typeCode - typeCode
     */
    isSecondaryBankNameAdd1Req(typeCode) {
        return this.getValueFromRTGSPreferences(typeCode).then((useResp) => {
            const secIntBankNameAdd1ReqConfig = this.parseRTGSPreferencesValue(useResp);
            return secIntBankNameAdd1ReqConfig;
        });
    },

};
