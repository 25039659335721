import constants from './constants';

export default ({ palette, breakpoints }) => ({
    childrenContainer: props => ({
        backgroundColor: palette.background,
        border: `1px solid ${palette.form.border}`,
        left: 0,
        marginLeft: 'auto',
        padding: '10px',
        position: 'absolute',
        right: 'unset',
        width: '100%',
        zIndex: '5',
        [`@media (min-width: ${breakpoints.values.md}px)`]: {
            width: 'fit-content',
            left: props.childPlacement === constants.PLACEMENT.LEFT ? '0' : 'unset',
            right: props.childPlacement === constants.PLACEMENT.RIGHT ? '0' : 'unset',
        },
    }),
    triangleDownIcon: {
        marginLeft: '5px',
        '& svg.icon-triangle-down': {
            width: '8px',
            height: '8px',
        },
    },
    button: {
        padding: '9px 12px',
        '&:hover': {
            textDecoration: 'none',
        },
    },
});
