export default {
  console: window.console,

  debug() {
    return this.info(...arguments);
  },

  info() {
    const { console } = this;
    if (!console) {
      return undefined;
    }

    return console.log(...arguments);
  },

  warn() {
    const { console } = this;
    if (!console) {
      return undefined;
    }

    const method = console.warn ? 'warn' : 'log';
    return console[method](...arguments);
  },

  error() {
    const { console } = this;
    if (!console) {
      return;
    }

    const method = console.error ? 'error' : 'log';
    console[method](...arguments);

    if (console.trace) {
      console.trace(...arguments);
    }
  },

  level(level) {
    const silent = () => undefined;

    switch (level) {
      case 'INFO': {
        this.debug = silent;
        break;
      }
      case 'WARN': {
        this.debug = silent;
        this.info = silent;
        break;
      }
      case 'ERROR': {
        this.debug = silent;
        this.info = silent;
        this.warn = silent;
        break;
      }
      default: {
        // do nothing
      }
    }
  }
};

