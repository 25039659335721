import React from 'react';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { DepositBatchWrapper, DepositHistoryWrapper } from '@dbiqe/rdc';
import AddRemoteDepositView from './views/addRemoteDeposit';
import RemoteDepositHistoryView from './views/remoteDepositHistory';

export default {
    /**
     * Show remote deposit screen if service is available and have entitled
     * locations or show error if not
     * @returns {Promise}
     */
    addRemoteDeposit() {
        const multiCheckEnabled = serverConfigParams.get('RDCMultiCheck') === 'true';
        this.showPage(
            '',
            multiCheckEnabled ? (
                <DepositBatchWrapper />
            ) : (
                <ViewWrapper view={new AddRemoteDepositView()} />
            ),
        );
    },

    /**
     * Show remote deposit history screen if service is available and have
     * entitled locations or show error if not
     * @returns {Promise}
     */
    getDepositHistory() {
        const multiCheckEnabled = serverConfigParams.get('RDCMultiCheck') === 'true';
        this.showPage(
            '',
            multiCheckEnabled ? (
                <DepositHistoryWrapper />
            ) : (
                <ViewWrapper view={new RemoteDepositHistoryView()} />
            ),
        );
    },
};
