/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import CurrencyBadge from './CurrencyBadge';
import useStyles from './CurrencyBadges.styles';

const CurrencyBadges = ({ sectionId, badges }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.section} carousel-${sectionId}`}>
            <div tabIndex="0" className={classes.wrapper}>
                <div className={`${classes.container} container-${sectionId}`}>
                    {
                        badges.map(badge =>
                            <CurrencyBadge key={badge.currencyCode} badge={badge} />)
                    }
                </div>
            </div>
        </div>
    );
};

CurrencyBadges.propTypes = {
    sectionId: PropTypes.string.isRequired,
    badges: PropTypes.arrayOf(PropTypes.shape({
        currencyCode: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        field1Label: PropTypes.string.isRequired,
        field1Amount: PropTypes.string.isRequired,
        field2Label: PropTypes.string,
        field2Amount: PropTypes.string,
    })).isRequired,
};

export default CurrencyBadges;
