import appConfigParams from 'system/webseries/models/applicationConfiguration';
import transform from 'common/util/transform';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import ReconciliationReportParent from './reconciliationReportParent';
import summaryTmpl from './summary.hbs';

export default ReconciliationReportParent.extend({
    template: summaryTmpl,

    initialize() {
        this.inquiryId = (appConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE') === '1') ? 20630 : 20620;
        this.totalsInquiryId = (appConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE') === '1') ? 20632 : 20622;
        this.context = {
            serviceName: '/tableMaint',
            actionMode: 'SELECT',
            functionCode: 'INST',
            productCode: 'CM',
            typeCode: `CIMINSTT-${this.inquiryId}`,
        };
        ListView.prototype.setListViewConfig.call(this, this.context);
    },

    events: ListView.prototype.events,

    onRender() {
        /*
         * The request parameters to send down to the
         * generic tablemaint.getListView service.
         */
        const requestParameters = {
            inquiryId: this.inquiryId,
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: 'CIMINSTT',
            viewType: `CM-INST-CIMINSTT-${this.inquiryId}`,
        };

        const options = {
            context: this.context,
            filter: true,
            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
            lvc: this.lvc,
        };

        this.gridView = GridApi.createServiceGridView(options);
        this.listenTo(this.gridView, 'rowAction', this.viewDetails);
        this.gridRegion.show(this.gridView);
        this.listenTo(this.gridView, 'gridapi:loaded', this.loadTotals);
        this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
        this.listenTo(this.gridView.getRows(), 'sync', this.refreshTotals);
        ListView.prototype.setupGridAvailableListener.call(this);
        ListView.prototype.listenForGridErrors.call(this);
    },

    /**
     * Sets the model of the row selected and navigates the user to
     * the detail page.
     *
     * @param {object} row - the row object passed in by row action
     */
    viewDetails(row) {
        store.set('cm-issueVoidReconciliation-fileExtract-model', row.model);
        this.navigateTo('REPORTING/viewIssueVoidReconciliationDetail');
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            title: '',
            format: 'PDF',
        };
        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },

    refreshData() {
        ListView.prototype.refreshData.call(this); // call listView refreshData
    },
});
