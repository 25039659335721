import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import configuration from 'system/configuration';
import locale from '@glu/locale';
/**
 * input masking utility for backbone views
 * the useMask hook is used for react components
 * Once we are fully react we can remove this file and put the logic for masking
 * in the useMask hook
 */

const getMaskingConfiguration = () => {
    const maskConfig = serverConfigParams.get('DisableClientAccountMasking');
    const disableMask = (((maskConfig !== undefined) && (maskConfig.toLowerCase() === 'true' || maskConfig === '1'))
        || userInfo.get('allowAccountUnmasking'));
    const maskPattern = serverConfigParams.get('AccountMaskingFormat');

    return {
        disableMask,
        maskPattern,
    };
};

const getMaskingUITypes = () => ['MASKEDINPUTWIDGET', 'TOGGLEMASKEDWIDGET', 'SECONDACCOUNTNUM'];

const getMaskingProperties = (disableMask = false, maskPattern = null) => {
    const UNMASKEDCHAR = '#';
    let maskLength = -1;
    let maskLeft;
    let maskedChar;
    let unmaskPos;
    let maskedPos;
    let unmaskedLength;
    let isMaskDisabled = disableMask;
    let mPattern = maskPattern;
    if (!mPattern) {
        const maskConfig = getMaskingConfiguration();
        isMaskDisabled = maskConfig.disableMask;
        mPattern = maskConfig.maskPattern;
    }

    if (mPattern && mPattern.length > 0) {
        maskLength = mPattern.length - 1;
        maskLeft = mPattern[maskLength] === UNMASKEDCHAR;
        maskedChar = (maskLeft) ? mPattern[0] : mPattern[maskLength];
        unmaskPos = mPattern.indexOf('#');
        maskedPos = mPattern.indexOf(maskedChar);
        unmaskedLength = (mPattern.match(/#/g) || []).length;
    }

    return {
        disableMask: isMaskDisabled,
        maskPattern: mPattern,
        validMask: maskLength > 0,
        maskLeft,
        unmaskPos,
        maskedPos,
        unmaskedLength,
    };
};

const maskValue = (inValue, properties) => {
    let maskingProps = properties;
    if (maskingProps === undefined || Object.keys(maskingProps).length === 0) {
        maskingProps = getMaskingProperties();
    }
    const {
        forceMasking,
        disableMask,
        maskPattern,
        validMask,
        maskLeft,
        unmaskPos,
        maskedPos,
        unmaskedLength,
    } = maskingProps;
    /*
     * NH-151315 Account masking should not occur in the admin application for now. Remove this
     * piece of code should we desire to bring it back to admin.
     * NH-150998 We do not want to mask field that contain the literal value "confidential" as
     * the relevant data is already sufficiently hidden from display.
     */
    const confidential = locale.get('PAY.fieldValue.confidential');
    if ((configuration.isAdmin() && !forceMasking) || inValue === confidential) {
        return inValue;
    }

    if (!validMask || disableMask || unmaskedLength === maskPattern.length || !inValue) {
        return inValue;
    }

    let theMask;
    let left;
    let right;
    if (maskLeft) {
        theMask = maskPattern.slice(0, unmaskPos);
        left = theMask;
        right = (inValue.length < unmaskedLength)
            ? inValue.toUpperCase() : inValue.slice(inValue.length - unmaskedLength).toUpperCase();
    } else { // mask Right
        theMask = maskPattern.slice(maskedPos);
        right = theMask;
        left = (inValue.length < unmaskedLength)
            ? inValue.toUpperCase() : inValue.slice(0, unmaskedLength).toUpperCase();
    }

    return `${left}${right}`;
};

export {
    getMaskingConfiguration,
    getMaskingUITypes,
    getMaskingProperties,
    maskValue,
};
