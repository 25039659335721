export default ({ palette, breakpoints }) => ({
    fieldset: {
        backgroundColor: palette.grey.G200,
        marginTop: '-20px',
        marginBottom: '25px',
        width: '100%',
        padding: '20px 0',

        [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            marginTop: 0,
        },

    },
});
