var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTGS.FEDWIRE.Notification_Email.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":131},"end":{"line":14,"column":180}}}))
    + "\">\n                        <span class=\"icon-info\"></span>\n                    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " hide ";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " \n                <button class=\"btn btn-popover beneemail-popover\" data-toggle=\"popover\" data-placement=\"top\" data-content=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTGS.FEDWIRE.Notification_EmailCC.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":123},"end":{"line":27,"column":174}}}))
    + "\">\n                    <span class=\"icon-info\"></span>\n                    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.contact.person",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":8},"end":{"line":1,"column":39}}}))
    + " <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"iconClassName") || (depth0 != null ? lookupProperty(depth0,"iconClassName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"iconClassName","hash":{},"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":70}}}) : helper)))
    + "\"></span></legend>\n            \n        <div>\n            <div class=\"form-group\">\n                <label for=\"BENE_CONTACT_NAME\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.contact.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":66},"end":{"line":5,"column":95}}}))
    + "</label>\n                <input class=\"form-control\" type=\"text\" name=\"BENE_CONTACT_NAME\" id=\"BENE_CONTACT_NAME\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_CONTACT_NAME") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"contactName") || (depth0 != null ? lookupProperty(depth0,"contactName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactName","hash":{},"data":data,"loc":{"start":{"line":6,"column":184},"end":{"line":6,"column":199}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_CONTACT_NAME\"></span>\n            </div>\n        </div>\n        <div>\n            <div class=\"form-group\">\n                <label for=\"BENE_EMAIL1\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.email",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":60},"end":{"line":12,"column":82}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEmailToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":17},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "                <input class=\"form-control\" type=\"text\" data-hook=\"BENE_EMAIL\" name=\"BENE_EMAIL1\" id=\"BENE_EMAIL1\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_EMAIL1") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":18,"column":189},"end":{"line":18,"column":198}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_EMAIL1\"></span>\n            </div>\n        </div>\n\n        <div>\n            <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideEmail2") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":66}}})) != null ? stack1 : "")
    + "\">\n                <label for=\"BENE_EMAIL2\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Email2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":60},"end":{"line":25,"column":91}}}))
    + "</label>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEmailToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + "                <input class=\"form-control\" type=\"text\" data-hook=\"BENE_EMAIL\" name=\"BENE_EMAIL2\" id=\"BENE_EMAIL2\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_EMAIL2") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"email2") || (depth0 != null ? lookupProperty(depth0,"email2") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"email2","hash":{},"data":data,"loc":{"start":{"line":31,"column":189},"end":{"line":31,"column":199}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_EMAIL2\"></span>\n            </div>\n        </div>\n\n        <div>\n            <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideEmail3") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":35},"end":{"line":37,"column":66}}})) != null ? stack1 : "")
    + "\">\n                <label for=\"BENE_EMAIL3\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Email3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":60},"end":{"line":38,"column":91}}}))
    + "</label>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEmailToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":43,"column":23}}})) != null ? stack1 : "")
    + "                <input class=\"form-control\" type=\"text\" data-hook=\"BENE_EMAIL\" name=\"BENE_EMAIL3\" id=\"BENE_EMAIL3\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_EMAIL3") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"email3") || (depth0 != null ? lookupProperty(depth0,"email3") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"email3","hash":{},"data":data,"loc":{"start":{"line":44,"column":189},"end":{"line":44,"column":199}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_EMAIL3\"></span>\n            </div>\n        </div>\n\n     <div>\n            <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideEmail4") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":35},"end":{"line":50,"column":66}}})) != null ? stack1 : "")
    + "\">\n                <label for=\"BENE_EMAIL4\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Email4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":60},"end":{"line":51,"column":91}}}))
    + "</label>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEmailToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + "                <input class=\"form-control\" type=\"text\" data-hook=\"BENE_EMAIL\" name=\"BENE_EMAIL4\" id=\"BENE_EMAIL4\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_EMAIL4") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"email4") || (depth0 != null ? lookupProperty(depth0,"email4") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"email4","hash":{},"data":data,"loc":{"start":{"line":57,"column":189},"end":{"line":57,"column":199}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_EMAIL4\"></span>\n            </div>\n        </div>\n\n        <div>\n            <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideEmail5") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":35},"end":{"line":63,"column":66}}})) != null ? stack1 : "")
    + "\">\n                <label for=\"BENE_EMAIL5\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BeneAddBook.Email5",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":60},"end":{"line":64,"column":91}}}))
    + "</label>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEmailToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":69,"column":23}}})) != null ? stack1 : "")
    + "                <input class=\"form-control\" type=\"text\" data-hook=\"BENE_EMAIL\" name=\"BENE_EMAIL5\" id=\"BENE_EMAIL5\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_EMAIL5") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"email5") || (depth0 != null ? lookupProperty(depth0,"email5") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"email5","hash":{},"data":data,"loc":{"start":{"line":70,"column":189},"end":{"line":70,"column":199}}}) : helper)))
    + "\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_EMAIL5\"></span>\n            </div>\n        </div>\n\n        <div class=\"address-button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAddEmailLink") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":35},"end":{"line":75,"column":80}}})) != null ? stack1 : "")
    + "\">\n            <button type=\"button\" class=\"btn-address btn-tertiary\" data-hook=\"BENE_EMAIL\" id=\"BENE_NOTIFICATION_EMAIL_LINK\">\n                <span class=\"\"></span>\n                Add Email\n            </button>\n        </div>\n\n        <div>\n            <div class=\"form-group\">\n                <label for=\"BENE_PHONENUMBER\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.phone",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":65},"end":{"line":84,"column":87}}}))
    + "</label>\n                <input class=\"form-control\" type=\"text\" name=\"BENE_PHONENUMBER\" id=\"BENE_PHONENUMBER\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_PHONENUMBER") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":85,"column":181},"end":{"line":85,"column":190}}}) : helper)))
    + "\">\n            </div>\n        </div>\n        <div>\n            <div class=\"form-group\">\n                <label for=\"BENE_PHONE_MOBILE\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.cell",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":66},"end":{"line":90,"column":87}}}))
    + "</label>\n                <input class=\"form-control\" type=\"text\" name=\"BENE_PHONE_MOBILE\" id=\"BENE_PHONE_MOBILE\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_PHONE_MOBILE") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"mobile") || (depth0 != null ? lookupProperty(depth0,"mobile") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"mobile","hash":{},"data":data,"loc":{"start":{"line":91,"column":184},"end":{"line":91,"column":194}}}) : helper)))
    + "\">\n            </div>\n        </div>\n\n        <div>\n            <div class=\"form-group\">\n                <label for=\"BENE_FAXNUMBER\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.fax",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":97,"column":63},"end":{"line":97,"column":83}}}))
    + "</label>\n                <input class=\"form-control\"type=\"text\" name=\"BENE_FAXNUMBER\" id=\"BENE_FAXNUMBER\" maxlength=\""
    + alias2(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_FAXNUMBER") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"fax") || (depth0 != null ? lookupProperty(depth0,"fax") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"fax","hash":{},"data":data,"loc":{"start":{"line":98,"column":174},"end":{"line":98,"column":181}}}) : helper)))
    + "\">\n            </div>\n        </div>\n";
},"useData":true});