import React from 'react';
import locale from '@glu/locale';

const NoHistoryRecords = () => (
    <tr>
        <td colSpan="6" className="text-center">{locale.get('GIR.noResultsFound')}</td>
    </tr>
);

export default NoHistoryRecords;
