import Marionette from 'backbone.marionette';
import util from '@glu/core/src/util';
import helperTextTemplates from 'common/dynamicPages/views/mdf/componentHelpers/lookupHelperTextTemplates';

export default Marionette.Behavior.extend({
    initialize(options) {
        this.getTemplateInput = options.getTemplateInput || this.getTemplateInput;
        this.fieldName = options.fieldName;
        this.hideOnEmptyTemplateInput = options.hideOnEmptyTemplateInput;

        this.listenTo(this.view, 'lookupHelperText:update', this.outputLookupHelperText.bind(this));
        this.listenTo(this.view, 'lookupHelperText:clear', this.clearHelperText.bind(this));
    },

    // needed to populate the helper text on modify & view
    onRender() {
        const { model } = this.view;
        const lookups = (model && model.lookupHelperText)
            ? Object.keys(model.lookupHelperText) : null;

        /*
         * no fieldname (doing a single lookup vs off a model's lookups)
         * and no model (many possible lookups) means there is nothing more to do
         */
        if (!this.fieldName && !model) {
            return;
        }

        if (!this.fieldName) {
            if (!util.isEmpty(lookups)) {
                lookups.forEach((ctrl) => {
                    this.outputLookupHelperText(ctrl);
                });
            }
        } else {
            this.outputLookupHelperText();
        }
    },

    /*
     * create and append the helper text to the DOM.
     * The id provided is only used if we do not have this.fieldName set from behavior options
     */
    outputLookupHelperText(id) {
        const lhtTemplate = helperTextTemplates.getTemplate(this.fieldName || id);
        const helperText = this.getTemplateInput(this.fieldName || id, this.view);
        const helperTextCompiled = lhtTemplate(helperText);
        const $lookupContainer = this.getLookupContainer(this.fieldName || id);
        const lookupHelperTextClass = `lookup-helper-text${(this.view.state === 'view') ? ' lookup-helper-text-view' : ''}`;
        const { model } = this.view;

        /**
         * NH-44250
         * if the value of the id in the model is empty, default it to value 'BLANK',
         * so that the id field would still be in the page and its visibility could be
         * modified by the policy, this is only applicable to BENE_BANK_ID because it's
         * the one has freeform input.
         */
        if (this.view.state === 'view' && model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM' && id === 'BENE_BANK_ID') {
            model.set(id, 'BLANK');
        }

        // remove it before adding
        $lookupContainer.find('.lookup-helper-text').remove();

        if (!(this.hideOnEmptyTemplateInput && !helperText)
            && !util.isEmpty(helperTextCompiled)) {
            $lookupContainer.append(`<div class="${lookupHelperTextClass}">${helperTextCompiled}</div>`);
        }
    },

    getTemplateInput(id, view) {
        const { model } = view;
        const helperText = model.lookupHelperText[id];
        const helperTextModel = {};

        if (!util.isEmpty(helperText)) {
            helperText.forEach((item) => {
                helperTextModel[item] = model.get(item);
            });
        }
        return helperTextModel;
    },

    clearHelperText(id) {
        const $lookupContainer = this.getLookupContainer(id);

        // remove it before adding
        $lookupContainer.find('.lookup-helper-text').remove();
    },

    /**
     * Gets the DOM element to append help text
     * @param  {string} [id] - Only used if we do not have this.fieldName set
     * from behavior options
     * @returns {jQuery}
     */
    getLookupContainer(id) {
        // HelperText behavior provides this
        if (this.fieldName) {
            return this.view.$el;
        }

        const $id = this.$(`#${id}`);
        let $container = $id.length ? $id : this.$(`[data-select-name="${id}"]`);
        $container = $container.length ? $container : this.$(`[data-validate="${id}"]`);

        return this.view.state === 'view' ? $container : $container.parent();
    },
});
