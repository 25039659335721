import Controller from '@glu/core/src/controller';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import services from 'services';
import userInfo from 'etc/userInfo';
import store from '@glu/store';
import util from '@glu/core/src/util';
import branding from 'etc/branding';
import configuration from 'system/configuration';
import ErrorView from 'system/error/views/base';
import ssoApi from 'app/utilities/sso/ssoApi';
import notificationManager from 'system/notifications/manager';
import configurationParameters from 'system/webseries/models/configurationParameters';
import mobileUtil from 'mobile/util/mobileUtil';
import StackView from 'common/stack/views/stackView';
import { getPageTitle } from 'common/util/pageTitleUtil';

export default Controller.extend({
    initialize(options) {
        this.brandMode = options && options.brandMode;

        /*
         * Apply user branding.
         * TODO: Move this to be its own module.
         */
        branding.apply();
        this.listenTo(this.appBus, 'logout', this.doLogout);
    },

    // default handler for unknown routes
    showError() {
        this.showPage(locale.get('title.error'), new ErrorView());
    },

    showPage(title, view, options, ...rest) {
        const pageTitle = title || getPageTitle();
        Controller.prototype.showPage.apply(this, [pageTitle, view, options, ...rest]);

        store.unset('new-stack');
        this.currentView = view;

        // Used by ARIA logic to announce page changes.
        this.appBus.trigger('notifyShowPage', pageTitle);
    },

    /**
     * Check the currentView to see if it is a StackView
     * @returns {Boolean}
     */
    isCurrentViewStack() {
        return this.currentView instanceof StackView;
    },

    /**
     * pushPage - First checks if there is an existing stack. If so, push the provided page onto
     * it. Otherwise, show the page normally.
     * @param {string} title - The page title
     * @param {View} view - The view to be shown
     * @param {Object} [options] - any options that are to be provided to the Glu controller
     * showPage function
     */
    pushPage(title, view, options) {
        if (this.currentView && this.currentView.push) {
            this.currentView.push(view);
        } else {
            // pushing to stack that isn't there
            this.showPage(title, view, options);
        }
    },

    /**
     * popPage - If there is an existing stack to pop from, and we are not navigating to a new
     * stack, then pop the existing stack.
     *
     * @return {Boolean} True if we popped, otherwise false
     */
    popPage() {
        if (!store.get('new-stack') && this.currentView && this.currentView.pop) {
            this.currentView.pop();
            store.unset('new-stack');
            if (mobileUtil.isMobileGridEnabled() && this.getStackCount() === 0) {
                return false;
            }
            return true;
        }

        store.unset('new-stack');
        return false;
    },

    /**
     * Returns the numbers of views in the currentView stack
     * @returns {Number} - number of views in the stack
     */
    getStackCount() {
        if (!this.currentView || !this.currentView.stack) {
            return 0;
        }
        return this.currentView.stack.length;
    },

    logout() {
        const self = this;
        if (configuration.isClient()) {
            // Close user's outbound SSO sessions and then move on to everything else.
            ssoApi.logout().then(() => {
                self.doLogout(true);
            });
        } else {
            this.doLogout();
        }
    },

    deleteSession() {
        http.del(services.logout, () => {
            // clear cached user information
            userInfo.logout();
        });
    },

    doLogout(shouldSuppressLogoutTrigger, inLogoutGet) {
        const self = this;
        const serverConfigParams = configurationParameters;

        // method to notify parent page of the outcome of the logout attempt
        const logoutNotification = (keyValue, msgInfo) => {
            if (window !== window.parent) {
                notificationManager.notify(
                    'logout',
                    {
                        key: keyValue,
                        message: {
                            info: msgInfo,
                        },
                    },
                );
            }
        };

        const funcError = () => {
            // if applicable notify parent page of failed logout
            logoutNotification('bottomline.logout.failure', 'logout failed');
        };

        const func = (data, textStatus, xhr) => {
            const rsaEnabled = userInfo.get('challengedRequired');
            if (!shouldSuppressLogoutTrigger) {
                // useful for hybrid app wrapper
                self.appBus.trigger('logout');
            }

            // clear cached navigation information
            store.unset('last404');

            // clear cached user information
            userInfo.logout();

            // clear other cached information
            store.unset('ssoEnabled');
            store.unset('dataCollectorEnabled');

            // if applicable notify parent page of successful logout
            logoutNotification('bottomline.logout.successful', 'logout completed');

            if (userInfo.attributes.simulatedSession) {
                // simulated session, send user back to admin app instead of client login
                window.location.href(self.fixUrl(configuration.getOldAdminUrl()));
            } else if (rsaEnabled && serverConfigParams.get('DeeplinkUserCallFailedUrl')) {
                window.location.href = self.fixUrl(serverConfigParams.get('DeeplinkUserCallFailedUrl'));
            } else if (xhr.getResponseHeader('sloUrl')) {
                if (xhr.getResponseHeader('ssoLogoutRedirectUrl')) {
                    const img = new Image();
                    img.src = xhr.getResponseHeader('sloUrl');
                    window.location.href = self.fixUrl(xhr.getResponseHeader('ssoLogoutRedirectUrl'));
                } else {
                    window.location.href = self.fixUrl(xhr.getResponseHeader('sloUrl'));
                }
            } else {
                self.appBus.trigger('router:navigate', self.brandMode ? '/brand' : '');
                if (mobileUtil.isNativeApp()) {
                    // Native app needs to know when redirected to login screen after logout
                    window.history.pushState({ fromLogout: true }, '', '');
                }
                window.location.reload();
            }
        };

        const useLogoutGet = (serverConfigParams && !util.isEmpty(serverConfigParams.get('useLogoutGet')) ? serverConfigParams.get('useLogoutGet') : inLogoutGet);
        if (!util.isEmpty(useLogoutGet) && useLogoutGet.toLowerCase() === 'true') {
            http.get(services.logoutGet, func, funcError);
        } else {
            http.del(services.logout, func, funcError);
        }
        // PCM SSO Logout call
        if (userInfo.get('isSSOLogin')) {
            const logoutURL = (serverConfigParams && !util.isEmpty(serverConfigParams.get('BTIQSSOLogoutEndpoint')) ? serverConfigParams.get('BTIQSSOLogoutEndpoint') : inLogoutGet);
            const redirectURL = userInfo.get('redirectURI');
            const idToken = userInfo.get('idToken');
            if (idToken !== undefined && redirectURL !== undefined) {
                window.location.replace(`${logoutURL}?post_logout_redirect_uri=${encodeURIComponent(redirectURL)}&id_token_hint=${idToken}`);
            }
        }
    },

    fixUrl(url) {
        if (url.indexOf('http') === 0) {
            return url;
        }
        return `${window.location.protocol}//${url}`;
    },
});
