var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"inline-block\" data-region=\"dropdownRegion\"></span>\n<button type=\"button\" class=\"btn btn-tertiary makeDefault\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.setasdefault",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":91}}}))
    + "</button>\n<button type=\"button\" class=\"btn btn-tertiary clearDLButton\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cleardefault",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":61},"end":{"line":3,"column":93}}}))
    + "</button>";
},"useData":true});