import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import vendorFieldsTmpl from 'app/administration/views/sso/vendorFields.hbs';

const constants = {
    FIELDTYPE_REGION: 'fieldTypeDropdownRegion',
    USAGE_REGION: 'usageDropdownRegion',
    CONTENT_TYPE_REGION: 'contentTypeDropdownRegion',
};

export default Layout.extend({
    template: vendorFieldsTmpl,

    ui: {
        $addRequestField: '#addRequestField',
        $addCount: '#addRequestFieldCount',
        $dynamicFields: '[data-hook="getFieldTypeDynamic"]',
        $userFields: '[data-hook="getFieldTypeUser"]',
        $popovers: '[data-hook="getFieldTypeIcon"]',
        $clearBtn: '[data-hook="getClearButton"]',
    },

    events: {
        'click @ui.$clearBtn': 'clearForm',
    },

    onRender() {
        this.ui.$addCount.inputmask('99');

        // manually trigger display of inputs
        this.changeFieldTypeHandler();

        if (this.options.mode !== 'SELECT') {
            this.options.comboData.fieldTypeData.preSelectFirstItem = true;
            this.fieldTypeDropdown = this.createDropdown('fieldTypeDropdownRegion', this.options.comboData.fieldTypeData);
            this.usageDropdown = this.createDropdown('usageDropdownRegion', this.options.comboData.usageData);
            this.contentTypeDropdown = this.createDropdown('contentTypeDropdownRegion', this.options.comboData.fieldContentTypeData);

            // manually listen to change events on dropdowns
            this.listenTo(this.fieldTypeDropdown, 'selectionChanged', this.handleFieldTypeChange);
            this.listenTo(this.usageDropdown, 'selectionChanged', this.setSelectedValue);
            this.listenTo(this.contentTypeDropdown, 'selectionChanged', this.setSelectedValue);

            this.ui.$popovers.popover({
                trigger: 'focus hover',
                html: true,
            });
        }
    },

    /**
     * - perform multiple actions when field type dropdown changes
     * @method handleFieldTypeChange
     * @param {array} selected - array of selected objects (see Glu docs for details)
     */
    handleFieldTypeChange(selected) {
        this.setSelectedValue(selected);
        this.changeFieldTypeHandler();
    },

    /**
     * - create a flex dropdown
     * @method createDropdown
     * @param {string} region - the region the dropdown should be added to
     * @param {object} optionsParam - the data options object required by all Flex Dropdowns
     * @returns {object} - the dropdown
     */
    createDropdown(region, optionsParam) {
        const options = optionsParam;
        options.disableMultiButton = true;
        options.showTooltip = false;
        this.processPreselectedIds(region, options);

        const dropdown = new FlexDropdown(options);

        this[region].show(dropdown);

        return dropdown;
    },

    /**
     * - function called when a dropdown is created
     * @method processPreselectedIds
     * @param {string} region - the string name of the region
     * @param {object} optionsParam - the FlexDropdown options with data
     */
    processPreselectedIds(region, optionsParam) {
        const options = optionsParam;
        const self = this;
        const setPreselectedIds = function (attribute) {
            options.preSelected = util.where(
                options.data,
                {
                    fieldValue: self.model.get(attribute),
                },
            );
        };
        switch (region) {
        case constants.FIELDTYPE_REGION:
            setPreselectedIds('FIELDTYPE');
            break;
        case constants.USAGE_REGION:
            setPreselectedIds('USAGE');
            break;
        case constants.CONTENT_TYPE_REGION:
            setPreselectedIds('CONTENT_TYPE');
            break;
        default:
        }
    },

    /**
     * - handle changes to the field type dropdown
     * - display or hide proper sections
     * @method changeFieldTypeHandler
     */
    changeFieldTypeHandler() {
        if (this.model.get('FIELDTYPE').toUpperCase() === 'DYNAMIC') {
            this.ui.$dynamicFields.show();
            this.ui.$userFields.hide();
            this.model.addValidator(
                'EXPRESSION',
                {
                    description: locale.get('sso.javaExpression'),
                    exists: true,
                },
            );
        } else {
            this.ui.$userFields.show();
            this.ui.$dynamicFields.hide();
            this.model.removeValidator('EXPRESSION');
        }
    },

    /**
     * - sets the selected dropdown value on the model
     * @method setSelectedValue
     * @param {array} selected - array of selected objects (see Glu docs for details)
     */
    setSelectedValue(selected) {
        if (selected[0].fieldName) {
            this.model.set(selected[0].fieldName, selected[0].fieldValue);
        }
    },

    /**
     * - convert boolean values into text usable in view templates
     * @method convertBoolean
     * @param {Boolean} val - value to convert
     * @returns {String} - localized text for "Yes" or "No"
     */
    convertBoolean(val) {
        const num = +val; // convert string to number

        return num === 1 ? locale.get('common.yes') : locale.get('common.no');
    },

    /**
     * - wrapper for boolean conversion method
     * @method getKeyAsBooleanText
     * @param {String} key - the object key to retreive
     */
    getKeyAsBooleanText(key) {
        return this.convertBoolean(this.model.get(key));
    },

    /**
     * - Trigger a remove:field event
     * @method removeBtn
     */
    removeBtn() {
        this.trigger('remove:field', this.model);
    },

    /**
     * - Clear the current form (when only one is displayed)
     * @method clearForm
     * @param {Event} e - the click event
     */
    clearForm(e) {
        e.preventDefault();
        this.model.clear().set(this.model.defaults);
        this.usageDropdown.resetSelected(true);
        this.contentTypeDropdown.resetSelected(true);
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === 'SELECT',
            multipleText: this.getKeyAsBooleanText('MULTIPLE'),
            oneForm: this.options.getRecordCount() === 1,
            urlEncodeText: this.getKeyAsBooleanText('URLENCODE'),
        };
    },
});
