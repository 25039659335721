import Layout from '@glu/core/src/layout';

/**
 * Stack view
 *
 * Supports pushing and popping views, prevent reinstantiating parent views
 * after drilling down.
 */
export default Layout.extend({
    template: () => '',

    initialize(options) {
        this.stack = [];
        this.initItem = options.initItem;
        if (this.initItem) {
            this.initItem.stack = this;
        }
    },

    onRender() {
        if (this.initItem) {
            this.push(this.initItem);
        }
    },

    onClose() {
        this.stack.forEach((stackItem) => {
            stackItem.view.close();
        });
        this.stack = [];
    },

    getCurrentView() {
        return this.stack[this.stack.length - 1].view;
    },

    push(view) {
        // give views a pointer to the stack they are in
        const callingView = view;
        callingView.stack = this;

        // hide current top (if exists)
        if (this.stack.length > 0) {
            this.stack[this.stack.length - 1].div.hide();
        }

        // push to stack and show new view
        const $viewEl = view.render().$el.appendTo(this.$el);

        this.stack.push({
            div: $viewEl,
            view,
        });
    },

    pop() {
        this.stack.pop()?.view?.close();

        if (this.stack.length > 0) {
            this.stack[this.stack.length - 1].div.show();
            /*
             *  trigger event when redisplaying past view in stack
             *  passing the current view after popping.
             */
            this.appBus.trigger('stackView:pop', this.stack[this.stack.length - 1]);
        }
    },
});
