var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"panel panel-primary\">\n        <div class=\"panel-heading\" role=\"tab\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":71}}}) : helper)))
    + "\">\n            <h3 class=\"panel-title\">\n                <a class=\"collapsed\" role=\"button\" data-toggle=\"collapse\" href=\"#collapseSummary-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":5,"column":97},"end":{"line":5,"column":118}}}) : helper)))
    + "\" aria-expanded=\"false\" aria-controls=\"collapseSummary-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":5,"column":173},"end":{"line":5,"column":194}}}) : helper)))
    + "\">\n                    <span class=\"indicator-icon\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOXLOCATION") || (depth0 != null ? lookupProperty(depth0,"LOCKBOXLOCATION") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOXLOCATION","hash":{},"data":data,"loc":{"start":{"line":6,"column":56},"end":{"line":6,"column":75}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOX_ID") || (depth0 != null ? lookupProperty(depth0,"LOCKBOX_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOX_ID","hash":{},"data":data,"loc":{"start":{"line":6,"column":77},"end":{"line":6,"column":91}}}) : helper)))
    + " Lockboxes)\n                </a>\n            </h3>\n\n            <div class=\"panel-totals\">\n                <div class=\"totals-wrapper\">\n                    <h5>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.TotalItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":51}}}))
    + "</h5>\n                    <span data-hook=\"summary-num-payments\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NUM_PAYMENTS") || (depth0 != null ? lookupProperty(depth0,"NUM_PAYMENTS") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NUM_PAYMENTS","hash":{},"data":data,"loc":{"start":{"line":13,"column":59},"end":{"line":13,"column":75}}}) : helper)))
    + "</span>\n                </div>\n                <div class=\"totals-wrapper\">\n                    <h5>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.TotalAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":16,"column":52}}}))
    + "</h5>\n                    <span data-hook=\"summary-total-amount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL_AMOUNT") || (depth0 != null ? lookupProperty(depth0,"TOTAL_AMOUNT") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL_AMOUNT","hash":{},"data":data,"loc":{"start":{"line":17,"column":59},"end":{"line":17,"column":75}}}) : helper)))
    + "</span>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"collapseSummary-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":22,"column":33},"end":{"line":22,"column":54}}}) : helper)))
    + "\" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":22,"column":121},"end":{"line":22,"column":142}}}) : helper)))
    + "\">\n            <div class=\"panel-body\">\n                <div data-region=\"lockboxRegion-"
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCATIONCODE_ATTR") || (depth0 != null ? lookupProperty(depth0,"LOCATIONCODE_ATTR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCATIONCODE_ATTR","hash":{},"data":data,"loc":{"start":{"line":24,"column":48},"end":{"line":24,"column":69}}}) : helper)))
    + "\"></div>\n            </div>\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"lockboxLocations") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":9}}})) != null ? stack1 : "");
},"useData":true});