var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"text-danger\">\n                                        "
    + alias2(lookupProperty(helpers,"icon").call(alias1,"exclamation-solid","Account problem",{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":23,"column":40},"end":{"line":23,"column":87}}}))
    + "\n                                        "
    + alias2(((helper = (helper = lookupProperty(helpers,"currencySymbolString") || (depth0 != null ? lookupProperty(depth0,"currencySymbolString") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currencySymbolString","hash":{},"data":data,"loc":{"start":{"line":24,"column":40},"end":{"line":24,"column":64}}}) : helper)))
    + alias2(lookupProperty(helpers,"money").call(alias1,(depth0 != null ? lookupProperty(depth0,"minimumBalanceAmount") : depth0),(depth0 != null ? lookupProperty(depth0,"currencyFormat") : depth0),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":24,"column":64},"end":{"line":24,"column":109}}}))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":110},"end":{"line":24,"column":134}}}))
    + " "
    + alias2(lookupProperty(helpers,"date").call(alias1,(depth0 != null ? lookupProperty(depth0,"minimumBalanceDate") : depth0),(depth0 != null ? lookupProperty(depth0,"dateFormat") : depth0),{"name":"date","hash":{},"data":data,"loc":{"start":{"line":24,"column":135},"end":{"line":24,"column":173}}}))
    + "\n                                </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <td>\n\n                <input type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cashFlowEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":88}}})) != null ? stack1 : "")
    + " id=\"include-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":4,"column":109},"end":{"line":4,"column":122}}}) : helper)))
    + "\">\n                <label for=\"include-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":5,"column":44},"end":{"line":5,"column":57}}}) : helper)))
    + "\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":39}}}) : helper)))
    + "\n                </label>\n        </td>\n\n        <td>\n                <div class=\"edit-mode\">\n\n                        <input type=\"text\" id=\"account-threshold-"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":13,"column":65},"end":{"line":13,"column":78}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minimumBalance") || (depth0 != null ? lookupProperty(depth0,"minimumBalance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimumBalance","hash":{},"data":data,"loc":{"start":{"line":13,"column":87},"end":{"line":13,"column":105}}}) : helper)))
    + "\" class=\"form-control\">\n                        <label for=\"account-threshold-"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountId") || (depth0 != null ? lookupProperty(depth0,"accountId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountId","hash":{},"data":data,"loc":{"start":{"line":14,"column":54},"end":{"line":14,"column":67}}}) : helper)))
    + "\" class=\"sr-only\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":15,"column":32},"end":{"line":15,"column":47}}}) : helper)))
    + "\n                        </label>\n                </div>\n\n                <div class=\"view-mode\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbolString") || (depth0 != null ? lookupProperty(depth0,"currencySymbolString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbolString","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":48}}}) : helper)))
    + alias4(lookupProperty(helpers,"money").call(alias1,(depth0 != null ? lookupProperty(depth0,"minimumBalance") : depth0),(depth0 != null ? lookupProperty(depth0,"currencyFormat") : depth0),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":20,"column":48},"end":{"line":20,"column":87}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAlert") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":26,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n        </td>\n";
},"useData":true});