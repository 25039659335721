import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    validators: {
        REMITTER_NAME: {
            description: 'Remitter Name',
            exists: true,
        },
    },

    service: 'lockboxRemitter',

    sync(method, model, options) {
        let url;
        let data;

        if (method === 'create') {
            url = services.generateUrl(`tableMaintenance/${this.service}/add`);

            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'read') {
            data = this.convertModelAttributesToServerJSON(model);
            url = services.generateUrl(`tableMaintenance/${this.service}/getListView`);

            http.post(url, {}, (result) => {
                const remitters = [];
                util.each(result.rows, (row) => {
                    const remitter = {};
                    for (let x = 0; x < row.columns.length; x += 1) {
                        remitter[row.columns[x].fieldName] = row.columns[x].fieldValue;
                    }
                    remitters.push(remitter);
                });
                options.success(remitters);
            }, httpError.getResult(options, model));
        }

        if (method === 'update') {
            url = services.generateUrl(`tableMaintenance/${this.service}/update`);

            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            data = this.convertModelAttributesToServerJSON(model);
            if (this.service === 'lockboxRemitter') {
                this.service += 'Account';
            }
            url = services.generateUrl(`tableMaintenance/${this.service}/delete`);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    convertModelAttributesToServerJSON(model) {
        const jsonData = [];

        util.each(model.toJSON(), (value, key) => {
            jsonData.push({
                name: key,
                value,
            });
        });

        return {
            item: jsonData,
        };
    },
});
