var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"page-header-wrapper\">\n        <div class=\"prev-page-breadcrumb\">\n            <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.quick.entry.list.control.view.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":148},"end":{"line":3,"column":203}}}))
    + "</span></a>\n        </div>\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.quickentry",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":35},"end":{"line":5,"column":66}}}))
    + "</h1>\n    </div>\n    <section class=\"section section-container\">\n        <div class=\"section-body\">\n            <div class=\"alert-message\">\n            </div>\n                        <div class=\"quick-entry-list\">\n            </div>\n\n                        "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary","textVisible":true,"action":"addQuickEntry","locale":"payment.quickentry.add","name":"add-circle"},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":152}}}))
    + "\n\n        </div>\n                <div class=\"section-footer\">\n                        <button type=\"button\" class=\"btn btn-primary\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":89},"end":{"line":19,"column":116}}}))
    + "</button>\n                        <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":93},"end":{"line":20,"column":120}}}))
    + "</button>\n                </div>\n    </section>\n";
},"useData":true});