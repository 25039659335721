//
// File created for PCM team/codebase, to have dedicated place to override or create new constants.
//
export const withPCM = (dgbConstants = {}) => {
    //
    // Section1 - mutated DGB constants
    //

    dgbConstants.PAYMENT_TYPES_USING_BENEFICIARY_WIDGET = [ //eslint-disable-line
        ...dgbConstants.PAYMENT_TYPES_USING_BENEFICIARY_WIDGET,
        'BAPCHK',
        'BGPCHK',
    ];

    dgbConstants.PAYMENT_TYPES_USING_CLEARBENE_BEHAVIOR = [ //eslint-disable-line
        ...dgbConstants.PAYMENT_TYPES_USING_CLEARBENE_BEHAVIOR,
        'BAPCHK',
        'BGPCHK',
    ];

    // Alternative 1
    // dgbConstants.ATTRIBUTES_FOR_NAME = [
    //     'RECEIVCOMPNAME',
    //     'INDVNAME',
    //     'CHECKNUM',
    //     'SECOND_BANKCODE'
    // ];
    // dgbConstants.ATTRIBUTES_FOR_NAME.concat(['CHECKNUMBER']); // that would be PCM version

    //
    // Section2 - 100% PCM constants
    //
    const pcmConstants = {
        URL_SUSPEND_ACTION: '/suspend',
        URL_ACTIVATE_ACTION: '/activate',
        CHECK_PRODUCT_CODE: 'CLM',
        CHECKDETAILAUDIT_CONTEXT: 'CHECK_DETAIL_AUDIT_INFO', // PCM-1141
        URL_CHECKDETAILAUDITLIST: 'audit/clmDetailAuditListView', // PCM-1141
        PAYMENT_TYPES_PRINT_BATCH_BUTTON_DETAIL_PAGE: ['BAPCHK', 'BGPCHK', 'BPRCHK'], // PCM-1499, PCM-3110
        PAYMENT_TYPES_NO_PRINT_BUTTON_DETAIL_PAGE: ['BAPCHK', 'BGPCHK', 'BPRCHK'], // PCM-1499, PCM-3110
        PAYMENT_TYPES_USING_BACK_BUTTON: ['BAPCHK', 'BGPCHK', 'BPRCHK'], // PCM-1499, PCM-3110
        ACTION_PRINTCHECK: 'PRINT', // PCM-1544
        ACTION_REPRINTCHECK: 'REPRINT', // PCM-1544
        TAX_PAYMENT_TYPE_CODE: 'BDACHTP',
        TAX_PAYMENT_TYPE: 'BDACHTP~*',

        // Alternative 2
        // ATTRIBUTES_FOR_NAME_DGB: [
        //     'RECEIVCOMPNAME',
        //     'INDVNAME',
        //     'CHECKNUM',
        //     'SECOND_BANKCODE'
        // ], // DGB
        // ATTRIBUTES_FOR_NAME_PCM: [
        //     'RECEIVCOMPNAME',
        //     'INDVNAME',
        //     'CHECKNUM',
        //     'SECOND_BANKCODE',
        //     'CHECKNUMBER'
        // ], // PCM
    };

    return {
        ...dgbConstants,
        ...pcmConstants,
    };
};

// Can be used in summarySectionWidget.js
// 'ENTRYMETHODDESC' added in scope of PCM-848.
// But seems that the order of entries matter. Need to test.
export const paymentSummaryFirstRow = [
    'STATUS_DESCRIPTION',
    'ENTRYMETHOD_DESCRIPTION',
    'ENTRYMETHODDESC',
    'TEMPLATE_CODE',
    'TEMPLATE_DESCRIPTION',
    'TEMPLATECODE',
    'TEMPLATEDESCRIPTION',
];

