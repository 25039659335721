import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import util from '@glu/core/src/util';

export default Model.extend({
    defaults: {
        FIELDTYPE: 'Dynamic',
    },

    initialize() {
        this.validators = {
            FIELDNAME: {
                description: locale.get('sso.fieldName'),
                exists: true,
            },
            FIELDTYPE: {
                description: locale.get('sso.fieldType'),
                exists: true,
            },
            EXPRESSION: {
                description: locale.get('sso.javaExpression'),
                exists: true,
            },
        };
    },

    /**
     * - convert the attributes of the model into a name/value pair format when
     * retrieved from
     * collectionView
     * @method convertModelAttributesToServerJSON
     * @returns {object} name value pair of attributes
     */
    convertModelAttributesToServerJSON() {
        return {
            item: util.map(this.toJSON(), (value, key) => ({
                name: key,
                value,
            })),
        };
    },

    /**
     * - add a new validator key and value onto the model
     * @method addValidator
     * @param {string} key
     * @param {object} value
     */
    addValidator(key, value) {
        this.validators[key] = value;
    },

    /**
     * - delete a given validator from the model
     * @method removeValidator
     * @param {string} key
     */
    removeValidator(key) {
        delete this.validators[key];
    },
});
