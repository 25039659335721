import BillManagementView from 'app/smbBillManagement/views/listBills';
import EnterBillFormView from 'app/smbBillManagement/views/billForm';
import StackView from 'common/stack/views/stackView';
import WorkspaceView from 'common/workspaces/views/workspace';

export default {
    /**
     * Return the default bill workspace view.
     */
    billMgmtWorkspaceDummy() {
        this.showPage('', new BillManagementView());
    },

    billMgmtWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'SMBBILLS',
                    returnRoute: 'billmanagement/workspace',
                }),
            }));
        }
    },

    billMgmtBillView(id) {
        this.showPage('', new EnterBillFormView({
            BILL_ID: id,
            readOnly: true,
        }));
    },

    billMgmtBillModify(id) {
        this.showPage('', new EnterBillFormView({
            BILL_ID: id,
        }));
    },

    billMgmtBillCreate() {
        this.showPage('', new EnterBillFormView({}));
    },
};
