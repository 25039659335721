import ItemView from '@glu/core/src/itemView';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import template from './item.hbs';

export default ItemView.extend({
    tagName: 'div',
    className: 'voice-item form-container',
    template,
    loadingTemplate,

    ui: {
        $contact: '.phone',
        sendNoticeBlock: '.send-notice',
        receiveCodeBlock: '.receive-code',
        activationNoticeBlock: '.activation-notice',
    },

    loadRequiredData() {
        const self = this;
        self.setHasLoadedRequiredData(true);
        self.render();
    },
    templateHelpers() {
        return {
            cid: this.cid,
        };
    },

    onRender() {
        const self = this;

        if (this.hasLoadedRequiredData()) {
            if (serverConfigParams.get('EnableInternationalPhoneNumbers') !== 'true') {
                this.ui.$contact.inputmask('useOnAndroid', userInfo.getPhoneFormat());
            }
            self.ui.sendNoticeBlock.hide();
            self.ui.receiveCodeBlock.hide();

            this.listenTo(this.model, 'change:contact', this.hideShowActivationButton);
            self.listenTo(self.model, 'change:default', self.render);

            this.$('[data-toggle="popover"]').popover();
        } else {
            this.loadRequiredData();
        }
    },

    sendNotification() {
        const self = this;
        // remove any past passcode that was set
        self.model.unset('passcode');

        self.model.sendActivateCode({
            success() {
                self.ui.sendNoticeBlock.hide();
                self.ui.receiveCodeBlock.show();
            },

            error() {
                // notice here.

            },
        });
    },

    activate() {
        const self = this;

        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }

        self.model.validateActivateCode({
            success(response) {
                if (response.respHeader.result) {
                    self.model.set('editable', false);
                    self.model.set('verified', true);
                    self.render();
                } else {
                    self.ui.receiveCodeBlock.addClass('has-error');
                    self.displayMessage(response);
                }
            },

            error() {
                // notice here.
                self.ui.activationNoticeBlock.html(locale.get('administration.mfa.error.activation'));
            },
        });
    },

    setDefault() {
        this.appBus.trigger('security:contact:default', this.model.cid);
    },

    resendCode() {
        const self = this;
        this.model.sendActivateCode({
            success() {
                self.ui.activationNoticeBlock.html(locale.get('administration.mfa.success.activation'));
            },

            error() {
                // notice here.
                self.ui.activationNoticeBlock.html(locale.get('administration.mfa.error.activation'));
            },
        });
    },

    delete() {
        const self = this;
        if (self.model.get('uid') === -1) {
            self.model.destroy();
        } else {
            self.model.set('removeRecord', true);
            self.render();
        }
    },

    handleActivationForAndroid() {
        const intlPhoneEnabled = serverConfigParams.get('EnableInternationalPhoneNumbers') === 'true';
        const ua = navigator.userAgent || navigator.vendor || window.opera;
        const isAndroid = !(/windows phone/i).test(ua) && (/android/i).test(ua);
        const regTest = new RegExp(validatorPatterns.PHONE_NUMERIC_PATTERN);
        if (intlPhoneEnabled && isAndroid && regTest.test(this.ui.$contact[0].value)) {
            return true;
        }
        return false;
    },

    hideShowActivationButton() {
        const self = this;
        if (self.ui.$contact.inputmask('isComplete') || this.handleActivationForAndroid()) {
            this.ui.sendNoticeBlock.show();
            // if we changed the phone number, hide the activation code box and blank it out.
            self.ui.receiveCodeBlock.hide();
            self.model.unset('passcode');
        } else {
            this.ui.sendNoticeBlock.hide();
            self.ui.receiveCodeBlock.hide();
        }
    },

    displayMessage(response) {
        // temporarily separate multiple errors with a space
        let message = null;

        if (response === null
            || response === undefined
            || response.respHeader.message === undefined) {
            return;
        }
        message = response.respHeader.message.join(' ');

        this.ui.activationNoticeBlock.html(message);
    },
});
