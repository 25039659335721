import Model from '@glu/core/src/model';
import locale from '@glu/locale';

const CriteriaModel = Model.extend({
    defaults: {
        frequency: 'ONCEPERSESSION',
    },

    initialize() {
        this.validators = {
            criteriaName: {
                description: locale.get('mfa.ChallengeName'),
                exists: true,
            },

            action: {
                description: locale.get('mfa.aSystemAction_Model'),
                exists: true,
            },
        };
    },

    convertActionsToServerFormat(sysActions) {
        const actionsObj = {
            actions: {
                feature: [],
            },
        };

        let enabledCount = 0;

        Object.keys(sysActions || {}).forEach((action) => {
            if (typeof sysActions[action] === 'boolean') {
                const featureObj = {
                    feature: action,
                    enabled: sysActions[action],
                };
                if (sysActions[action]) {
                    enabledCount += 1;
                }
                actionsObj.actions.feature.push(featureObj);
            }
        });

        if (enabledCount > 0) {
            this.set('action', true);
        } else {
            this.unset('action');
        }

        this.set(actionsObj);
    },
});

export default CriteriaModel;
