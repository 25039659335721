var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.fromLoan",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":65},"end":{"line":3,"column":97}}}))
    + "</span></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":39},"end":{"line":9,"column":45}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":63}}}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.toAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":65},"end":{"line":18,"column":98}}}))
    + "</span></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.effectiveDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":65},"end":{"line":33,"column":102}}}))
    + "</span></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.comment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":65},"end":{"line":39,"column":96}}}))
    + "</span></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":65},"end":{"line":47,"column":95}}}))
    + "</span></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"payment-grid-header-label\"><span class=\"txt\">&nbsp;</span></div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-success btn-left\" data-action=\"add\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.add.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":96},"end":{"line":61,"column":120}}}))
    + "\"><span class=\"icon-plus\"></span></button>\n                <button type=\"button\" class=\"btn btn-success btn-right\" data-action=\"clear\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.clear.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":62,"column":99},"end":{"line":62,"column":125}}}))
    + "\"><span class=\"icon-cancel\"></span></button>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-success btn-left\" data-action=\"save\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.save.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":97},"end":{"line":64,"column":122}}}))
    + "\"><span class=\"icon-tick\"></span></button>\n                <button type=\"button\" class=\"btn btn-success btn-right\" data-action=\"cancel\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smb.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":100},"end":{"line":65,"column":123}}}))
    + "\"><span class=\"icon-cancel\"></span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <select id=\"from-loan\" class=\"form-control\" data-bind=\"model\" name=\"fromLoanId\" data-hook=\"from-loan\">\n            <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromLoans") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"fromLoanId\"></span>\n        <span data-hook=\"fromBalLabel\"></span>&nbsp;<b> <span data-hook=\"fromLnBalance\"></span></b>\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <select id=\"to-account\" class=\"form-control\" name=\"toAccountId\" data-bind=\"model\" data-hook=\"to-account\">\n            <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"toAccounts") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":25,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"toAccountId\"></span>\n        <span data-hook=\"toBalLabel\"></span>&nbsp;<b><span data-hook=\"toAccBalance\"></span></b>\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input payment-date\"></div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <input type=\"button\" id=\"comment\" class=\"btn btn-link\" data-hook=\"commentButton\" data-action=\"popComment\"/>\n    </div>\n</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <input id=\"amount\" type=\"text\" class=\"form-control\" data-bind=\"model\" title=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"sbPayments.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":85},"end":{"line":50,"column":115}}}))
    + "\" name=\"amount\" maxlength=\"12\"/>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"amount\"></span>\n    </div>\n</td>\n<td class=\"align-center btn-cell\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":57,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"payment-grid-header-input\">\n        <div class=\"btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":66,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</td>\n";
},"useData":true});