var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"full-page\">\n    <div class=\"page-header-wrapper\">\n        <div class=\"prev-page-breadcrumb\">\n            <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":148},"end":{"line":5,"column":184}}}))
    + "</span></a>\n        </div>\n        <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"pageTitle") || (depth0 != null ? lookupProperty(depth0,"pageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"pageTitle","hash":{},"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":50}}}) : helper)))
    + "</h1>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"section section-container\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"billpay-region\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"draft-region\"></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <fieldset class=\"section section-container contact-related-grids\">\n                         <div class=\"section-header panel\">\n                            <h2 class=\"panel-title\">\n                                <a role=\"button\" href=\"#relatedGridsContainer\"\n                                    aria-expanded=\"false\" class=\"collapsed\" data-toggle=\"collapse\">\n                                    <span class=\"indicator-icon\"></span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"relatedSectionLabel") || (depth0 != null ? lookupProperty(depth0,"relatedSectionLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"relatedSectionLabel","hash":{},"data":data,"loc":{"start":{"line":31,"column":72},"end":{"line":31,"column":95}}}) : helper)))
    + "\n                                </a>  \n                            </h2>\n                        </div>\n                        <div class=\"row collapse\" data-hook=\"getRelatedPaymentsContainer\" id=\"relatedGridsContainer\"> \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"servicesEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":49,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"templatesEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":28},"end":{"line":63,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </fieldset>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"panel panel-tertiary\">\n                                <div class=\"panel-heading\" role=\"tab\" id=\"relatedPayments-heading\">\n                                    <h2 class=\"panel-title\">\n                                        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"relatedPaymentsHeader") || (depth0 != null ? lookupProperty(depth0,"relatedPaymentsHeader") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"relatedPaymentsHeader","hash":{},"data":data,"loc":{"start":{"line":40,"column":40},"end":{"line":40,"column":65}}}) : helper)))
    + "\n                                    </h2>\n                                </div>\n                                <div class=\"section-container\" role=\"tabpanel\" aria-labelledby=\"relatedPayments-heading\" data-hook=\"getRelatedPaymentsContainer\" id=\"relatedPaymentsContainer\">\n                                    <div class=\"panel-body inline-fields\">\n                                       <div data-region=\"relatedPaymentsGridRegion\" aria-live=\"polite\"/>\n                                    </div>\n                                </div>\n                            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"panel panel-tertiary\">\n                                    <div class=\"panel-heading\" role=\"tab\" id=\"relatedTemplates-heading\">\n                                        <h2 class=\"panel-title \">\n                                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.TemplatesRTGS",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":44},"end":{"line":54,"column":74}}}))
    + "\n                                        </h2>\n                                    </div>\n                                    <div class=\"section-container\" role=\"tabpanel\" aria-labelledby=\"relatedTemplates-heading\" data-hook=\"getRelatedTemplatesContainer\" id=\"relatedTemplatesContainer\">\n                                        <div class=\"panel-body inline-fields\">\n                                            <div data-region=\"relatedTemplatesGridRegion\" aria-live=\"polite\"/>\n                                        </div>\n                                    </div>\n                                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"section section-container\">\n        <div class=\"row\">\n            <div class=\"col-sm-6\">\n                <button class=\"btn btn-primary\" data-hook=\"saveContactButton\" data-action=\"handleContactAccountFormSubmit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.save.contact.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":123},"end":{"line":75,"column":156}}}))
    + "</button>\n                <button class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":71},"end":{"line":76,"column":98}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <div "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":9},"end":{"line":10,"column":72}}})) != null ? stack1 : "")
    + ">\n        <div class=\"alert-region\"></div>\n        <div class=\"contact-region\"></div>\n        <div class=\"row\">\n            <div class=\"col-sm-12\">\n                <fieldset>\n                    <legend>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"bab.payment.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":57}}}))
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"billPayEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":27}}})) != null ? stack1 : "")
    + "                        <div class=\"payment-region\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"draftEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModify") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":66,"column":27}}})) != null ? stack1 : "")
    + "                </fieldset>\n            </div>\n        </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":0},"end":{"line":81,"column":11}}})) != null ? stack1 : "");
},"useData":true});