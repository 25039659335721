import GridApi from 'common/dynamicPages/api/grid';
import transform from 'common/util/transform';
import store from 'system/utilities/cache';
import Layout from '@glu/core/src/layout';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import TotalsView from 'app/administration/common/totalsHeader/totalsView';
import template from './posPayExtractLog.hbs';

export default Layout.extend({
    template,
    inquiryId: 20579,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    onRender() {
        this.loadViewRequirements();
    },

    loadViewRequirements() {
        /*
         * The request parameters to send down to the
         * generic tablemaint.getListView service.
         */
        const requestParameters = {
            inquiryId: this.inquiryId,
            productCode: 'CM',
            functionCode: 'BATCH',
            typeCode: '*',
            viewType: 'CM-BATCH-*-20579',
        };
        const context = {
            serviceName: '/tableMaint',
            actionMode: 'SELECT',
            functionCode: 'BATCH',
            productCode: 'CM',
            typeCode: '*-20579',
        };
        ListView.prototype.setListViewConfig.call(this, context);

        const options = {
            context,
            filter: true,
            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,

            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },

            lvc: this.lvc,
        };

        this.gridView = GridApi.createServiceGridView(options);
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        this.gridRegion.show(this.gridView);
        this.listenTo(this.gridView, 'gridapi:loaded', this.loadTotals);
        this.listenTo(this.gridView.getRows(), 'sync', this.refreshTotals);
        this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
        ListView.prototype.listenForGridErrors.call(this);
        ListView.prototype.setupGridAvailableListener.call(this);
    },

    /**
     * Create and loads the totals view
     */
    loadTotals() {
        this.totalsView = new TotalsView({
            productCode: 'CM',
            functionCode: 'INST',
            typeCode: 'CIMINSTT',
            inquiryId: 20584,
            filters: this.gridView.wrapper.generateFiltersDataBlock(),
            viewId: this.gridView.wrapper.viewId,
        });
        this.totalsSection.show(this.totalsView);
        this.refreshTotals();
    },

    /**
     * Refreshes the totals view on this page.
     */
    refreshTotals() {
        if (this.totalsView) {
            this.totalsView.refresh(
                this.gridView.wrapper.generateFiltersDataBlock(),
                this.gridView.wrapper.viewId,
            );
        }
    },

    /**
     * Navigates the user to the detail page
     */
    gridRowAction(row) {
        store.set('cm-posPay-fileExtract-model', row.model);
        this.navigateTo('REPORTING/viewPosPayExtractDetail');
        return Promise.resolve();
    },

    refreshData() {
        ListView.prototype.refreshData.call(this); // call listView refreshData
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            title: '',
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },
});
