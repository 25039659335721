import '../themeDefaults';

export default ({
  mobilegrid: {
    datarow, focusColor, cellPadding, primaryData
  }
}) => ({
  primaryRow: {
    cursor: 'pointer'
  },
  bulkActionSelect: {
    paddingTop: '1rem',
    verticalAlign: 'top'
  },
  bulkActionSvg: {
    '& input[type="checkbox"] + div svg': {
      width: 16,
      height: 16
    }
  },
  expandoTh: {
    verticalAlign: 'top',
    width: datarow.expandWidth,

    '& svg': {
      fill: datarow.expandIconFill
    }
  },
  subCells: {
    padding: datarow.subCellsPadding
  },
  button: {
    background: 'none',
    border: 0,
    padding: 0,
    '&:focus': {
      outlineColor: focusColor
    }
  },
  svg: {
    transition: datarow.contentTransition,
    transform: 'rotate(0)'
  },
  svgOpen: {
    transition: datarow.contentTransition,
    transform: 'rotate(90deg)'
  },
  contentPanelHidden: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 1s ease-in-out'
  },
  contentPanelShown: {
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    transition: 'max-height 1s ease-in-out'
  },
  mainCells: {
    padding: cellPadding,
    fontFamily: primaryData.fontFamily,
    fontWeight: primaryData.fontWeight,
    fontSize: primaryData.cellFontSize,
    wordBreak: 'break-word',
    paddingRight: 6,
    '& a:focus': {
      outlineColor: focusColor
    }
  }
});
