import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import AdjustAmountsForm from './adjustAmountsForm';
import template from './editAmountsView.hbs';

export default Layout.extend({
    template,

    initialize(options) {
        /*
         * Passed in options that will interact with the related Editable Grid
         */
        this.bulkUpdateEnabled = options.bulkUpdateEnabled;
        this.editableGridView = options.editableGridView;
        // handler to toggle on manual edits for amounts and showing inputs
        this.manualEditToggle = options.manualEditToggle;
    },

    onRender() {
        // CustomView used to incorporate the Adjust Amounts Form
        const options = {
            data: [{
                id: 1,
                name: locale.get('action.edit'),
            }, {
                id: 2,
                name: locale.get('PAY.clearAllAmounts'),
            }],

            disableMultiButton: true,
            showTooltip: false,
            label: locale.get('ACH.edit.amounts'),
        };

        // Only show bulk update functions if available
        if (this.bulkUpdateEnabled) {
            options.data.push({
                id: 2,
                name: locale.get('PAY.clearAllAmounts'),
            });
            options.customView = AdjustAmountsForm;
            options.customViewOptions = {
                parentView: this,
                bulkUpdateCallback: this.applyBulkUpdate.bind(this),
                closeParentDropdown: this.closeDropdown.bind(this),
                getSelectedRows: this.getSelectedRows.bind(this),
                eventProxy: this,
            };
        }

        // Create dropdown menu
        this.amountOptionsDropdown = new FlexDropdown(options);

        this.listenTo(this.amountOptionsDropdown, 'selectionChanged:id', this.onOptionSelect);

        /*
         * Proxy the dropdownOpened event for the customView inside.
         * It instantiates before the FDD returns and isn't accessible after.
         */

        this.listenTo(this.amountOptionsDropdown, 'dropdownOpened', function () {
            this.trigger('dropdownOpened');
        });

        this.optionsDropdownRegion.show(this.amountOptionsDropdown);

        this.amountOptionsDropdown.ui.dropdown[0].setAttribute('role', 'button');
        this.amountOptionsDropdown.ui.dropdown[0].setAttribute('aria-label', locale.get('ACH.edit.amounts'));
    },

    getSelectedRows() {
        if (!this.editableGridView.grid) {
            return [];
        }

        return this.editableGridView.grid.getSelectedRowModels().map(row => ({
            name: 'WORKSEQUENCENUM',
            value: row.get('WORKSEQNUMBER'),
        }));
    },

    /**
     * @method onOptionSelect
     * @param {array} selected - on select return thats an array of id's
     * Will trigger a callback edit method based on the option id selected in dropdown
     */
    onOptionSelect(selected) {
        const [id] = selected;
        switch (id) {
        case 1:
            // Edit: triggers manual edit mode toggle for grid (passed in options)
            return this.manualEditToggle(true);
        case 2:
            // Clear All Amounts:
            return this.clearAllAmounts();
        default:
        }
        return undefined;
    },

    clearAllAmounts() {
        const clearAllAmountsRequest = {
            rows: [],
            action: 'bulkUpdate',
            actionType: 'CLEARAMOUNT',
        };
        this.trigger('bulkUpdateRequest', clearAllAmountsRequest);

        /*
         * Reset dropdown selection, or next time we choose
         * clearAllAmounts won't fire since its already selected
         */
        this.resetDropDownSelection();
    },

    resetDropDownSelection() {
        this.amountOptionsDropdown.resetSelected();
    },

    /**
     * @method applyBulkUpdate
     * @param {object} userOptions - user defined object that contains data for
     * the bulk update request
     *  {
     *      rows {array}
     *      actionType {string}
     *      value {int}
     *  }
     * Takes in user defined options to create and send a bulk update
     */
    applyBulkUpdate(userOptions) {
        const bulkUpdateRequest = {
            rows: userOptions.rows,
            action: 'bulkUpdate',
            actionType: userOptions.actionType,
            value: userOptions.value,
        };

        this.trigger('bulkUpdateRequest', bulkUpdateRequest);
        this.closeDropdown();
    },

    closeDropdown() {
        this.amountOptionsDropdown.$el.find('.flex-dropdown').toggleClass('open');
    },
});
