import Glu from '@glu/core/src/glu';
import Marionette from 'backbone.marionette';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import $ from 'jquery';
import http from '@glu/core/src/http';
import { log } from '@glu/core';
import BaseLayout from 'system/gluOverride/core/internal/baseLayout';
import integrationManager from 'app/integrationManager/integrationManager';

Glu.Host = function (guests) {
    const app = new Marionette.Application();

    /*
     * Place-holder for localisation (i18n) strings
     * TODO Move to Glu.locale
     */
    window.Bottomline = window.Bottomline || {};
    Glu.i18n = {};
    window.Bottomline.i18n = Glu.i18n;

    /*
     * Override default renderer, in order to support Handlebars
     * with runtime localisation support
     * TODO Once moved to Glu.locale, shouldn't need this anymore
     */
    Marionette.Renderer.render = function (template, data) {
        return template(util.extend(data, {
            i18n: Glu.i18n,
        }));
    };

    function setCookieClass() {
        const cookieEnabled = navigator.cookieEnabled || ('cookie' in document && (document.cookie.length > 0 || (document.cookie = 'test').indexOf.call(document.cookie, 'test') > -1));
        document.documentElement.className += cookieEnabled ? ' cookies' : ' no-cookies';
    }

    function run(Router, Controller, options) {
        // Steps to perform after application is started
        // eslint-disable-next-line no-shadow
        app.addInitializer((options) => {
            const baseLayout = new BaseLayout(options);
            const { appRoot } = options;
            baseLayout.render(options);

            // eslint-disable-next-line no-new
            new Router({
                controller: new Controller(options),
            });

            if (options.user.isAuthenticated) {
                return Glu.history.start({
                    pushState: true,
                    root: appRoot || '/',
                });
            }
            return undefined;
        });

        app.start(options);
    }

    Glu.host = {
        guests,

        loadGuest(Router, Controller, options) {
            const promises = options.promises || [];
            const afterAuthPromises = options.afterAuthPromises || [];
            const url = window.location.href;
            const notAuthorised = options.notAuthorised || [];
            const fadeOutMs = options.fadeOutMs || 200;

            // Push the promises into the host
            this.promises = promises;

            setCookieClass();

            // Ensure IE8 or above
            if (document.documentElement.className.indexOf('lt-ie8') > -1) {
                throw new Error(locale.get('iE7Error'));
            }

            const $content = $('.glu-content');
            const $loader = $('.glu-loader');

            function startApp(authenticated) {
                if (options.progressBar !== false) {
                    Glu.host.updateProgressBar('Rendering');
                }

                $content.show();

                $loader.fadeOut(fadeOutMs, () => {
                    run(Router, Controller, util.extend(options, {
                        user: {
                            isAuthenticated: authenticated,
                        },
                        brandMode: options.brandMode || false,
                    }));
                });
            }

            // eslint-disable-next-line no-shadow
            function isANotAuthorizedUrl(url) {
                return util.some(notAuthorised, regex => url.match(regex));
            }

            Promise.all(promises)
                .then(() => integrationManager.callEvent('preAuth'))
                .then(() =>
                    // Make an initial call the the ping URL to ensure we're logged in
                    new Promise(((resolve, reject) => {
                        if (options.authTest) {
                            /*
                             * The object from authentication is passed implicitly to startApp.
                             * Now pass it through for afterAuthPromises and resolve the
                             * authenticated object. It still gets passed to startApp via the
                             * then statement.
                             */
                            http.get(options.authTest, (response) => {
                                /*
                                 * after auth promises should be functions that return promises
                                 * not promises themselves, the idea being that these are
                                 * services you want to hit only after you know the user is
                                 * authenticated, but before you display the nav
                                 * (locale, user info, etc)
                                 */
                                const afterAuthPromiseList = [];
                                util.each(afterAuthPromises, (promise) => {
                                    afterAuthPromiseList.push(promise(response));
                                });

                                /*
                                 * you would also need to implement this in your login success
                                 * response as this path isn't followed from loginNav
                                 */
                                Promise.all(afterAuthPromiseList).then(() => {
                                    resolve(true);
                                });
                            }, reject);
                        } else {
                            resolve(false);
                        }
                    })))
                .then(startApp)
                .then(null, (err) => {
                    if (err.status === 401) {
                        const isUserConsideredAuthenticated = isANotAuthorizedUrl(url);
                        startApp(isUserConsideredAuthenticated);
                    } else {
                        log.error(err);
                        Glu.host.updateProgressBar(err, true);
                    }
                });
        },

        updateProgressBar(message, failure) {
            const $progressBar = $('.glu-progress .progress-bar');
            const $progressMessage = $('.glu-progress-message');

            let processedRequests = 0; // Perform pre-start operations

            if (!failure && this.promises && this.promises.length) {
                processedRequests += 1;
                const percent = Math.ceil((processedRequests / this.promises.length) * 100);
                $progressBar.width(`${percent}%`);
            }

            if (message) {
                $progressMessage.text(message);
            }
        },
    };

    return Glu.host;
};

export default Glu.Host;
