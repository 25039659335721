import userInfo from 'etc/userInfo';
import serverConfigParams from 'system/webseries/models/configurationParameters';
/**
 * Policies for user form
 */

export default function (form, initialState) {
    const mobileField = form.field('MOBILEPHONENUMBER');
    const faxField = form.field('FAXNUMBER');

    if (initialState) {
        if (serverConfigParams.get('EnableInternationalPhoneNumbers') !== 'true') {
            mobileField.$el.inputmask(userInfo.getPhoneFormat());
            faxField.$el.inputmask(userInfo.getPhoneFormat());
        }
    }
}
