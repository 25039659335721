import Glu from '@glu/core/src/glu';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import cmConstants from 'app/checkManagement/constants';
import store from 'system/utilities/cache';
import dialog from '@glu/dialog';
import FiDetailModal from 'app/administration/views/fileImport/fiDetailModal';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import FilterCellView from 'common/dynamicPages/views/filterCellView';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './importResults.hbs';

const importResultsList = ListView.extend({
    template,

    id: 'importResults',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'importResultsTab',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="fileimport-button"]': 'fileimport',
        },
    ),

    initialize(options) {
        this.allowStopsInIssueVoidFile = applicationConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE') === '1';
        this.parent = options.parent;
        const superOptions = {
            enableSaveViews: false,
            menuCategory: 'RISK',
            context: 'CM_IMPORTRESULTS',
            serviceName: this.allowStopsInIssueVoidFile ? 'cm/listView/importResultsStopCancel' : 'cm/listView/importResults',
            returnRoute: Glu.Backbone.history.getFragment(),
            cellViews: {
                EVENTID: FilterCellView,
            },
        };
        store.set('cm_listView_importResults-listRoute', Glu.Backbone.history.getFragment());
        store.set('cm_listView-tab', 'importResultsTab');
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_20579,
            summaryInquiryId: constants.INQUIRY_ID_20579,
            hasSummarySelection: false,
        };
    },

    export() {
        if (this.allowStopsInIssueVoidFile) {
            PrintExportUtil.export(this.getStopCancelOptions('CSV'));
        } else {
            ListView.prototype.export.call(
                this,
                {
                    format: 'CSV',
                },
            );
        }
    },

    getStopCancelOptions(format) {
        this.gridView.context.productCode = 'CM';
        this.gridView.context.functionCode = 'INST';
        this.gridView.context.typeCode = 'CIMIMPRT';
        this.gridView.context.actionMode = 'SELECT';
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: cmConstants.CHKISSUE_STOPCANCE_IMPOERTRESULT_INQ,
            format,
        };
        return options;
    },

    templateHelpers() {
        const self = this;
        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                const key = `${self.localeKey}.button_${type}`;
                let retStr = locale.get(key);
                if (retStr === (`??${key}??`)) {
                    retStr = locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
                }
                return retStr;
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },
        };
    },

    entitlementPromiseSuccess(result) {
        this.entitlements = result.actions;
        if (!mobileUtil.isMobileGridEnabled()) {
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            this.listenForGridErrors('listview.savesystemviewinvalid');
            this.setHasLoadedRequiredData(true);
            this.render();
        }
        return result.actions;
    },

    fileimport() {
        const importContext = {
            serviceName: '/checkfimport/import',
            actionMode: 'IMPORT',
            productCode: 'CM',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        this.navigateTo(this.contextModel.getImportUrl());
    },

    gridRowSelect(options) {
        const { model } = options;
        const status = model.get('STATUS');

        if (status.toUpperCase() === 'RJ') {
            store.set('fileImportId', model.get('IMPORTID'));
            store.set('jobId', model.get('EVENTID'));
            store.set('productCode', model.get('PRODUCT'));
            store.set('functionCode', model.get('FUNCTION'));
            store.set('typeCode', model.get('TYPE'));
            store.set('fileOriginalName', model.get('FILENAME'));
            store.set('importCode', 'CM');
            store.set('templateCode', model.get('TEMPLATE_CODE'));
            store.set('templateName', model.get('TEMPLATE_NAME'));
            const fiDetailModal = new FiDetailModal({});
            dialog.custom(fiDetailModal);
        } else {
            const jobId = options.model.get('EVENTID');
            this.parent.openView({
                href: 'individual',
                filters: [{
                    title: locale.get('CM.JobId'),
                    label: jobId,
                    field: 'JOBID',
                    type: 'multistring',
                    value: [jobId],
                }],
            });
        }
        return Promise.resolve();
    },
});

let list = importResultsList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [],
        bulkActions: [],
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
