import gluAlert from '@glu/alerts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ViewWrapper from './ViewWrapper';

const buildAlert = props => {
  const {
    type, title, message, canDismiss, details, duration, animate
  } = props;
  const alertProps = {
    title, message, canDismiss, details, duration, animate
  };
  let alert;

  switch (type) {
    case 'negative':
      alert = gluAlert.danger(message, alertProps);
      break;

    case 'warning':
      alert = gluAlert.warning(message, alertProps);
      break;

    case 'info':
      alert = gluAlert.info(message, alertProps);
      break;

    default:
      alert = gluAlert.positive(message, alertProps);
  }

  return alert;
};

class Alert extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.message !== nextProps.message) {
      return {
        message: nextProps.message,
        view: buildAlert(nextProps)
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      view: buildAlert(props)
    };
  }

  componentDidMount() {
    if (this.props.callback) {
      this.props.callback();
    }
  }

  render() {
    return (
      <ViewWrapper className={this.props.className} view={this.state.view} />
    );
  }
}

/* eslint-disable react/no-unused-prop-types */
Alert.propTypes = {
  type: PropTypes.oneOf(['negative', 'success', 'warning', 'info']),
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  canDismiss: PropTypes.bool,
  details: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  duration: PropTypes.number,
  animate: PropTypes.bool,
  callback: PropTypes.func // optional handler that will be called on componentDidMount
};

Alert.defaultProps = {
  type: 'success',
  className: '',
  title: '',
  message: '',
  canDismiss: true,
  details: null,
  duration: null,
  animate: null,
  callback: null
};

export default Alert;
