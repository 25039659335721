import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import constants from 'app/administration/constants';
import Type from 'app/administration/models/user2/type';
import EntitlementCollectionView from './entitlementCollectionView';
import paymentTypeTmpl from './paymentType.hbs';
import { updateSelectAllState, onChildrenChange } from '../../helpers';

export default Layout.extend({
    tagName: 'div',
    className: 'panel panel-secondary',
    template: paymentTypeTmpl,

    ui: {
        $heading: '.panel-heading',
        $expandPanel: '.collapse',
        $selectAllPayments: '[data-hook="selectAllPayments"]',
        $selectAllTemplates: '[data-hook="selectAllTemplates"]',
        $selectAll: '[data-hook="selectAllPayTemp"]',
    },

    events: {
        'click @ui.$heading': 'togglePanel',
        'click @ui.$selectAll': 'selectAllPermissions',
        'click @ui.$selectAllPayments': 'selectAllPaymentPerms',
        'click @ui.$selectAllTemplates': 'selectAllTemplatePerms',
    },

    initialize(options) {
        this.aggregateTypes = options.aggregateTypes;
        this.entitledTypes = options.entitledTypes;
        this.model = this.model || new Type({});
        this.mode = options.mode;
        this.userGroup = options.userGroup;
        this.hasPanelApproval = options.hasPanelApproval;

        if (!this.aggregateTypes) {
            this.listenTo(this.model, 'expand-payment-types', function (expand) {
                this.ui.$expandPanel.collapse(expand ? 'show' : 'hide');
                this.togglePanel();
            });
        } else {
            this.model.set('entitlements', this.aggregateTypes);
        }

        const notViewMode = options.mode !== constants.MODES.VIEW;

        this.paymentEntitlements = this.model.getEntitlementsByFunctionCode(['INST', 'BATCH'], notViewMode);
        this.templateEntitlements = this.model.getEntitlementsByFunctionCode(['TMPL', 'BHTMPL'], notViewMode);
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.paymentEntitlementsView = new EntitlementCollectionView({
            mode: this.mode,
            hasPanelApproval: this.hasPanelApproval,
            collection: this.paymentEntitlements,
            entitledTypes: this.entitledTypes,
            onChildrenChange: this.options.onChildrenChange,
        });

        this.templateEntitlementsView = new EntitlementCollectionView({
            mode: this.mode,
            collection: this.templateEntitlements,
            entitledTypes: this.entitledTypes,
            onChildrenChange: this.options.onChildrenChange,
        });

        if (this.aggregateTypes) {
            this.togglePanel();
        }
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    onChildrenChange() {
        const checkboxList = this.$el?.find('input[type="checkbox"]');
        onChildrenChange(this, checkboxList);
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.paymentPermRegion?.$el?.find('input[type="checkbox"]'), this.ui.$selectAllPayments);
        updateSelectAllState(this, this.templatePermRegion?.$el?.find('input[type="checkbox"]'), this.ui.$selectAllTemplates);
        updateSelectAllState(this, this.$el?.find('.row input[type="checkbox"]'));
        if (this.options.allSelected) {
            this.options.allSelected(this.hasFullEntitlements());
        }
    },

    /**
     * @method hasFullEntitlements
     * @description Helper method that will check if a user is
     * fully entitled to specific payment types in order to control
     * the state of select all checkbox
     * @returns {boolean}
     */
    hasFullEntitlements() {
        return this.model?.get('entitlements')
            ?.models
            ?.map(entitlements => Object.values({
                ...entitlements.get('actions'),
                ...entitlements.get('restrictions'),
            }))
            .flat() // now an array of entitlements
            .filter(a => a)
            .every(a => a.entitled);
    },

    togglePanel() {
        if (!this.panelLoaded) {
            if (this.paymentPermRegion) {
                this.paymentPermRegion.show(this.paymentEntitlementsView);
            }
            if (this.templatePermRegion) {
                this.templatePermRegion.show(this.templateEntitlementsView);
            }

            this.panelLoaded = true;
        }
        this.updateSelectAllState();
    },

    selectAllPermissions(evt) {
        this.selectAllPaymentPerms(evt);
        this.selectAllTemplatePerms(evt);
    },

    selectAllPaymentPerms(evt) {
        this.paymentEntitlements.selectAll(evt.currentTarget.checked);
        this.paymentEntitlementsView.render();
    },

    selectAllTemplatePerms(evt) {
        this.templateEntitlements.selectAll(evt.currentTarget.checked);
        this.templateEntitlementsView.render();
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            hideHeader: this.aggregateTypes,
            readOnly: this.mode === constants.MODES.VIEW,
            isAggregate: !!this.aggregateTypes,
            label: this.aggregateTypes ? '' : locale.get(this.model.get('label')),
            hasPaymentEntitlements: this.paymentEntitlements.length > 0,
            hasTemplateEntitlements: this.templateEntitlements.length > 0,
        };
    },
});
