var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li class=\"NavTabs-item is-active\"\n                    role=\"tab\">\n                    <a aria-controls=\"enter\"\n                        class=\"NavTabs-link\"\n                        href=\"#enter\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.EnterBills",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":65}}}))
    + "\n                    </a>\n                </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li class=\"NavTabs-item\"\n                    role=\"tab\">\n                    <a aria-controls=\"validate\"\n                        class=\"NavTabs-link\"\n                        href=\"#validate\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.ValidateBills",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":41},"end":{"line":20,"column":71}}}))
    + "\n                    </a>\n                </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li class=\"NavTabs-item\"\n                    role=\"tab\">\n                    <a aria-controls=\"approve\"\n                        class=\"NavTabs-link\"\n                        href=\"#approve\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.ApproveBills",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":40},"end":{"line":29,"column":69}}}))
    + "\n                    </a>\n                </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li class=\"NavTabs-item\"\n                    role=\"tab\">\n                    <a aria-controls=\"pay\"\n                        class=\"NavTabs-link\"\n                        href=\"#pay\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.PayBills",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":36},"end":{"line":38,"column":61}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <div class=\"section section-container\" role=\"navigation\">\n    <div class=\"section-body\">\n        <ul id=\"list-bills-tablist\" class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":14,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasValidateOrInvalidateEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":23,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveOrUnapproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":32,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPayEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":41,"column":31}}})) != null ? stack1 : "")
    + "            <li class=\"NavTabs-item\"\n                role=\"tab\">\n                <a aria-controls=\"history\"\n                    class=\"NavTabs-link\"\n                    href=\"#history\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BOS.BillHistory",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":64}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\"\n                role=\"tab\">\n                <a aria-controls=\"master\"\n                    class=\"NavTabs-link\"\n                    href=\"#master\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"BOS.BillMaster",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":35},"end":{"line":53,"column":62}}}))
    + "\n                </a>\n            </li>\n        </ul>\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                <div data-region=\"visual-filter\"></div>\n                <div class=\"pageRegion remove-section-styles\"></div>\n    </div>\n</div>\n";
},"useData":true});