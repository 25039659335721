import MDFMultiAddCollectionView from 'app/multiAdd/views/mdfMultiAddCollectionView';

const StopPaymentsMultiAddCollectionView = MDFMultiAddCollectionView.extend({
    itemEvents: {
        /**
         * @name destroy
         * @description event handler for the remove item.  If there is only one
         * model it gets
         * cleared; otherwise,
         * the model is removed from the collection
         * @param event
         * @param itemView
         */
        destroy(event, itemView) {
            let model;
            // if first item, then set all attributes to empty strings, otherwise remove it
            if (this.collection.length === 1) {
                ({ model } = itemView); // first model
                /*
                 * set attributes in the first model to empty strings
                 * except for the STOPTYPE and STOPTYPE_DESCRIPTION
                 */
                model.set(model.keys().reduce((acc, key) => {
                    if (key !== 'STOPTYPE' && key !== 'STOPTYPE_DESCRIPTION') {
                        acc[key] = '';
                    }
                    return acc;
                }, {}));
            } else {
                this.collection.remove(itemView.model);
            }
        },
    },

    /**
     * @name onAfterItemAdded
     * @description (marionette) event handler called after an item (model) has
     * been added to the collection
     * Need to hide the preauthorized debit note for stoptype of check
     */
    onAfterItemAdded(itemView) {
        MDFMultiAddCollectionView.prototype.onAfterItemAdded.call(this, itemView);
        itemView.listenToOnce(itemView.mdfView, 'ui-loaded', function () {
            if (this.model.get('STOPTYPE') === 'CHECK') {
                this.$('.textline-field').addClass('hide');
            }
        }.bind(itemView.mdfView));
    },
});

export default StopPaymentsMultiAddCollectionView;
