var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp;\n            <span class=\"breadcrumb-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":84}}}))
    + "</span></a>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div data-section=\"payment-summary\" class=\"amount-wrapper\">\n            <p class=\"amount-label-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.transfer.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":75}}}))
    + "</p>\n            <span data-hook=\"summary-amount\" class=\"amount-value-text payment-total\">0.00</span> <span data-hook=\"summary-currency\" class=\"amount-value-text payment-total\"></span>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	 <div class = \"row\">\n		 <div class = \"form-heading-info\">\n			 <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfersset.maxamount.message","Total amount of all transactions may not exceed",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":11},"end":{"line":22,"column":111}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"templateMaxAmountFormatted") || (depth0 != null ? lookupProperty(depth0,"templateMaxAmountFormatted") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateMaxAmountFormatted","hash":{},"data":data,"loc":{"start":{"line":22,"column":112},"end":{"line":22,"column":144}}}) : helper)))
    + "</label>\n		 </div>\n	<div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":45,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"row\">\n                <div class=\"xfer-view\">\n                    <div class=\"col-2\">\n                        <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":47},"end":{"line":49,"column":87}}})) != null ? stack1 : "")
    + "\">\n                            <label for=\"templateCode\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":73},"end":{"line":50,"column":102}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":51,"column":28},"end":{"line":56,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                    <div class=\"col-3\">\n                        <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":47},"end":{"line":60,"column":87}}})) != null ? stack1 : "")
    + "\">\n                            <label for=\"templateDescription\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":80},"end":{"line":61,"column":116}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromTemplateNotModify") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":62,"column":28},"end":{"line":67,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"panelApprovalEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":20},"end":{"line":81,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showOverrideSign") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":20},"end":{"line":97,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":20},"end":{"line":106,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleTransfer") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":118,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRefForTemplate") : depth0),{"name":"unless","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":3},"end":{"line":143,"column":14}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRefForTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":12},"end":{"line":241,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\n                    <div class=\"xfer-view\">\n                        <div class=\"col-2\">\n\n                            <label for=\"userGroup\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.USERGROUP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":70},"end":{"line":35,"column":95}}}))
    + "</label>\n                            <p class=\"form-control-static\" data-text=\"USERGROUP\" data-bind=\"model\"></p>\n                        </div>\n                        <div class=\"col-4\">\n\n                            <label for=\"userGroupName\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.USERGROUPNAME",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":74},"end":{"line":40,"column":103}}}))
    + "</label>\n                            <p class=\"form-control-static\" data-text=\"COMPANYNAME\" data-bind=\"model\"></p>\n                        </div>\n                    </div>\n                </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return " required";
},"12":function(container,depth0,helpers,partials,data) {
    return "                                <p class=\"form-control-static\" data-text=\"setName\" data-bind=\"model\"></p>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                                <input id=\"templateCode\" type=\"text\" class=\"form-control\" name=\"setName\" maxlength=\"35\" data-bind=\"model\">\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"setName\"></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                <p class=\"form-control-static\" data-text=\"setDescription\" data-bind=\"model\"></p>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                                <input id=\"templateDescription\" type=\"text\" class=\"form-control\" name=\"setDescription\" maxlength=\"35\" data-bind=\"model\">\n                                <span class=\"help-block\" data-bind=\"model\" data-validate=\"setDescription\"></span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-3\">\n                            <div class=\"form-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":73,"column":32},"end":{"line":78,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <label>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.panelApproval.Approval.Workflow",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":43},"end":{"line":74,"column":90}}}))
    + "</label>\n                                    <p class=\"form-control-static\" data-text=\"panelProfileDetails\" data-bind=\"model\"></p>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                                    <div data-region=\"panelWorkflowAssignmentRegion\"></div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                         <div class=\"col-2 "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":43},"end":{"line":83,"column":83}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":84,"column":28},"end":{"line":95,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <label for=\"MAX_SIGNATURES_OVERRIDE\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PAY.*.MAX_SIGNATURES_OVERRIDE.label","Signatures",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":69},"end":{"line":85,"column":130}}}))
    + "\n                                </label>\n                                <p id=\"MAX_SIGNATURES_OVERRIDE\" class=\"form-control-static\" data-text=\"MAX_SIGNATURES_OVERRIDE\" data-bind=\"model\">\n";
},"28":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <label for=\"MAX_SIGNATURES_OVERRIDE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.*.MAX_SIGNATURES_OVERRIDE.label","Signatures",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":89,"column":69},"end":{"line":89,"column":130}}}))
    + "\n                                </label>\n                                <a tabindex=\"0\" class=\"btn btn-popover\" role=\"button\" data-toggle=\"popover\" title=\"\" data-placement=\"right\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.*.MAX_SIGNATURES_OVERRIDE.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":154},"end":{"line":91,"column":201}}}))
    + "\">\n                                  <span class=\"icon-info\"></span>\n                                 </a>\n                                 <div data-hook=\"getMaxSignaturesOverrideDropdown\"></div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCopyFromTemplate") : depth0),{"name":"unless","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":24},"end":{"line":105,"column":35}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"col-2\">\n                            <input id=\"RESTRICTTEMPLATE_FLAG\" name=\"restrict\" data-bind=\"model\" type=\"checkbox\" data-hook=\"restrict-template-select\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":149},"end":{"line":102,"column":191}}})) != null ? stack1 : "")
    + ">\n                            <label for=\"RESTRICTTEMPLATE_FLAG\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"common.transfers.restrict.template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":103,"column":64},"end":{"line":103,"column":111}}}))
    + "</label>\n                            </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":24},"end":{"line":117,"column":35}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showOverrideSign") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":109,"column":40},"end":{"line":109,"column":89}}})) != null ? stack1 : "")
    + " text-right\">\n                                <div class=\"form-group single-checkbox glu-switch switch-md switch-square\">\n                                    <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.optionalfields","Optional Fields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":111,"column":42},"end":{"line":111,"column":94}}}))
    + "</span>\n                                    <input id=\"agree-02\" data-hook=\"optFieldSwitch\" type=\"checkbox\">\n                                    <label for=\"agree-02\" data-on=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Transfer.option.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":113,"column":67},"end":{"line":113,"column":102}}}))
    + "\" data-off=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Transfer.option.off",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":113,"column":114},"end":{"line":113,"column":150}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.optionalfields","Optional Fields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":113,"column":152},"end":{"line":113,"column":204}}}))
    + "</label>\n                                </div>\n                            </div>\n                            <div class=\"clearfix\"></div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "col-4";
},"38":function(container,depth0,helpers,partials,data) {
    return "col-6";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showMaxAmount") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":4},"end":{"line":142,"column":23}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"row\">\n	                    <div class=\"xfer-view\">\n	                    	<div class=\"col-2\">\n								<div class=\"form-group\">\n									<label for=\"TEMPLATE_MAX_AMOUNT\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"PAY.MaximumAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":128,"column":42},"end":{"line":128,"column":72}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":129,"column":9},"end":{"line":137,"column":16}}})) != null ? stack1 : "")
    + "								</div>\n							</div>\n	                    </div>\n	                </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "		                        		<p id=\"TEMPLATE_MAX_AMOUNT_FORMATTED\" class=\"form-control-static\" data-text=\"TEMPLATE_MAX_AMOUNT_FORMATTED\" data-bind=\"model\">\n";
},"44":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"input-group\">\n											<input class=\"form-control text-right\" name=\"TEMPLATE_MAX_AMOUNT\" data-hook = \"getTemplateMaxAmount\" data-bind=\"model\" data-maxlength=\"19\" id=\"TEMPLATE_MAX_AMOUNT\">\n											<div class=\"field-container currency-type\" name=\"TEMPLATE_MAX_CURRENCY\" data-bind=\"model\"></div>\n											<div class=\"currency-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"baseCurrency") || (depth0 != null ? lookupProperty(depth0,"baseCurrency") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"baseCurrency","hash":{},"data":data,"loc":{"start":{"line":135,"column":38},"end":{"line":135,"column":54}}}) : helper)))
    + "</div>\n										</div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\n                    <div class=\"xfer-view\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMaxAmount") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":22},"end":{"line":166,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOne2OneShowDate") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":25},"end":{"line":191,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShowTime") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":24},"end":{"line":209,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"row\">\n		            <div class=\"xfer-view\">\n		            	<div class=\"col-3\">\n		                    <div class=\"form-group\">\n		                        <label for=\"transferRef\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_REF",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":216,"column":51},"end":{"line":216,"column":90}}}))
    + "</label>\n		                        <input class=\"form-control\"\n		                               data-hook=\"getTransferRef\"\n		                               id=\"transferRef\"\n		                               name=\"commonReference\"\n		                               maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"referenceLength") || (depth0 != null ? lookupProperty(depth0,"referenceLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"referenceLength","hash":{},"data":data,"loc":{"start":{"line":221,"column":44},"end":{"line":221,"column":63}}}) : helper)))
    + "\"\n		                               type=\"text\">\n\n		                        <span class=\"textline-field\">\n		                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.reference.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":225,"column":30},"end":{"line":225,"column":77}}}))
    + "\n		                        </span>\n		                    </div>\n		            	</div>\n		            	<div class=\"col-md-2 col-lg-1\">\n			                <div class=\"form-group transfer-clear-btn\">\n			                    <button class=\"btn btn-tertiary\"\n			                            data-hook=\"getClearButton\"\n			                            type=\"button\">\n			                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.clear.all",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":234,"column":31},"end":{"line":234,"column":70}}}))
    + "\n			                    </button>\n			                </div>\n		            	</div>\n\n		        	</div>\n		        </div>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	                        <div class=\"col-2\">\n								<div class=\"form-group\">\n									<label for=\"TEMPLATE_MAX_AMOUNT\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.MaximumAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":151,"column":42},"end":{"line":151,"column":72}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":152,"column":9},"end":{"line":160,"column":16}}})) != null ? stack1 : "")
    + "									<span class=\"textline-field\">\n										"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.maxamount.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":162,"column":10},"end":{"line":162,"column":57}}}))
    + "\n									</span>\n								</div>\n							</div>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCopyFromTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":25},"end":{"line":190,"column":31}}})) != null ? stack1 : "");
},"50":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"col-2\">\n                                <div class=\"form-group date-picker\">\n                                    <label for=\"transferDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_DATE.DEFAULT",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":171,"column":62},"end":{"line":171,"column":110}}}))
    + "</label>\n                                    <input autocomplete=\"off\"\n                                           class=\"input-date form-control\"\n                                           data-bind=\"model\"\n                                           data-hook=\"getTransferDate\"\n                                           id=\"transferDate\"\n                                           name=\"transDate\"\n                                           type=\"text\">\n\n                                    <span class=\"textline-field\">\n                                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.reference.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":181,"column":40},"end":{"line":181,"column":87}}}))
    + "\n                                    </span>\n\n                                    <span class=\"help-block\"\n                                          data-bind=\"model\"\n                                          data-validate=\"transDate\">\n                                    </span>\n                                </div>\n                            </div>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"col-2\">\n                                <div class=\"form-group\">\n                                    <label for=\"transferTime\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_TIME.DEFAULT",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":196,"column":62},"end":{"line":196,"column":110}}}))
    + "</label>\n                                    <div class=\"input-group\">\n                                        <input id=\"transferTime\" name=\"START_TIME\" type=\"text\" class=\"form-control input-time\"  data-bind=\"model\" data-hook=\"getTransferTime\">\n                                        <span class=\"input-group-addon addon-right\" aria-label=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":199,"column":96},"end":{"line":199,"column":112}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":199,"column":114},"end":{"line":199,"column":130}}}) : helper)))
    + "</span>\n                                    </div>\n\n                                    <span class=\"textline-field\">\n                                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.reference.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":203,"column":40},"end":{"line":203,"column":87}}}))
    + "\n                                    </span>\n\n                                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"START_TIME\"></span>\n                                </div>\n                            </div>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showOptionalFieldRef") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":245,"column":12},"end":{"line":333,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\n                    <div class=\"xfer-view\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRefForTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":248,"column":24},"end":{"line":318,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleTransferOrModify") : depth0),{"name":"unless","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":320,"column":24},"end":{"line":330,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isModifyAndSingleItem") : depth0),{"name":"unless","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":249,"column":28},"end":{"line":317,"column":39}}})) != null ? stack1 : "");
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"col-3\">\n                                    <div class=\"form-group\">\n                                        <label for=\"transferRef\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_REF",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":252,"column":65},"end":{"line":252,"column":104}}}))
    + "</label>\n                                        <input class=\"form-control\"\n                                               data-hook=\"getTransferRef\"\n                                               id=\"transferRef\"\n                                               name=\"commonReference\"\n                                               maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"referenceLength") || (depth0 != null ? lookupProperty(depth0,"referenceLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"referenceLength","hash":{},"data":data,"loc":{"start":{"line":257,"column":58},"end":{"line":257,"column":77}}}) : helper)))
    + "\"\n                                               type=\"text\">\n\n                                        <span class=\"textline-field\">\n                                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.reference.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":261,"column":44},"end":{"line":261,"column":91}}}))
    + "\n                                        </span>\n                                    </div>\n                                </div>\n\n                                <div class=\"col-md-2 col-lg-1\">\n                                    <div class=\"form-group transfer-clear-btn\">\n                                        <button class=\"btn btn-tertiary\"\n                                                data-hook=\"getClearButton\"\n                                                type=\"button\">\n                                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.clear.all",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":271,"column":48},"end":{"line":271,"column":87}}}))
    + "\n                                        </button>\n                                    </div>\n                                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOne2OneShowDate") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":276,"column":32},"end":{"line":298,"column":39}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShowTime") : depth0),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":300,"column":32},"end":{"line":316,"column":39}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"col-2\">\n                                        <div class=\"form-group date-picker\">\n                                            <label for=\"transferDate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_DATE.DEFAULT",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":279,"column":70},"end":{"line":279,"column":118}}}))
    + "</label>\n                                            <input autocomplete=\"off\"\n                                                   class=\"input-date form-control\"\n                                                   data-bind=\"model\"\n                                                   data-hook=\"getTransferDate\"\n                                                   id=\"transferDate\"\n                                                   name=\"transDate\"\n                                                   type=\"text\">\n\n                                            <span class=\"textline-field\">\n                                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.reference.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":289,"column":48},"end":{"line":289,"column":95}}}))
    + "\n                                            </span>\n\n                                            <span class=\"help-block\"\n                                                  data-bind=\"model\"\n                                                  data-validate=\"transDate\">\n                                            </span>\n                                        </div>\n                                    </div>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <div class=\"col-2\">\n                                        <div class=\"form-group\">\n                                            <label for=\"transferTime\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_TIME.DEFAULT",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":303,"column":70},"end":{"line":303,"column":118}}}))
    + "</label>\n                                            <div class=\"input-group\">\n                                                <input id=\"transferTime\" name=\"START_TIME\" type=\"text\" class=\"form-control input-time\"  data-bind=\"model\" data-hook=\"getTransferTime\">\n                                                <span class=\"input-group-addon addon-right\" aria-label=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":306,"column":104},"end":{"line":306,"column":120}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":306,"column":122},"end":{"line":306,"column":138}}}) : helper)))
    + "</span>\n                                            </div>\n\n                                            <span class=\"textline-field\">\n                                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.reference.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":310,"column":48},"end":{"line":310,"column":95}}}))
    + "\n                                            </span>\n\n                                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"START_TIME\"></span>\n                                        </div>\n                                    </div>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":321,"column":28},"end":{"line":329,"column":39}}})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"col-4 text-right\">\n                                    <div class=\"form-group single-checkbox glu-switch switch-md switch-square\">\n                                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.optionalfields","Optional Fields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":324,"column":46},"end":{"line":324,"column":98}}}))
    + "</span>\n                                        <input id=\"agree-02\" data-hook=\"optFieldSwitch\" type=\"checkbox\">\n                                        <label for=\"agree-02\" data-on=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Transfer.option.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":326,"column":71},"end":{"line":326,"column":106}}}))
    + "\" data-off=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Transfer.option.off",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":326,"column":118},"end":{"line":326,"column":154}}}))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.optionalfields","Optional Fields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":326,"column":156},"end":{"line":326,"column":208}}}))
    + "</label>\n                                    </div>\n                                </div>\n";
},"65":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"recur-region\"></div>\n\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"singleLabel") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":345,"column":12},"end":{"line":345,"column":62}}})) != null ? stack1 : "")
    + "\n            <div class=\"single-region\"></div>\n\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiLabel") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":348,"column":12},"end":{"line":348,"column":60}}})) != null ? stack1 : "")
    + "\n            <div class=\"entry-region\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideAdd") : depth0),{"name":"unless","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":351,"column":12},"end":{"line":355,"column":23}}})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"singleLabel") || (depth0 != null ? lookupProperty(depth0,"singleLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"singleLabel","hash":{},"data":data,"loc":{"start":{"line":345,"column":35},"end":{"line":345,"column":50}}}) : helper)))
    + "</h2>";
},"70":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"multiLabel") || (depth0 != null ? lookupProperty(depth0,"multiLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"multiLabel","hash":{},"data":data,"loc":{"start":{"line":348,"column":34},"end":{"line":348,"column":48}}}) : helper)))
    + "</h2>";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"rapidAddAdditional"),depth0,{"name":"rapidAddAdditional","hash":{"label":"PAY.Transfer(s)"},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"74":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"detailAuditHistory"),depth0,{"name":"detailAuditHistory","hash":{"payment":"true","containerTitle":"PAYJS.PaymentHistory"},"data":data,"indent":"             ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"76":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"detailAuditHistory"),depth0,{"name":"detailAuditHistory","hash":{"template":"true","containerTitle":"common.templateHistory"},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"78":function(container,depth0,helpers,partials,data) {
    return "            <div id=\"transitionAuditRegionDiv\" data-region=\"transitionAuditRegion\"></div>\n";
},"80":function(container,depth0,helpers,partials,data) {
    return "            <div data-hook=\"dummyEl\" class=\"hide\"></div>\n            <div data-hook=\"totalDisplay\"></div>\n\n";
},"82":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            (<span data-hook=\"transferCount\">1</span>)\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Transfer","Transfer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":382,"column":12},"end":{"line":382,"column":48}}}))
    + "\n            <span class=\"summary-large-text\" data-hook=\"transferAmount\"></span>\n            <span data-hook=\"currencyDisplay\"></span>\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.STARTINGON","starting on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":385,"column":12},"end":{"line":385,"column":53}}}))
    + "\n            <span data-hook=\"transferDate\"></span>\n\n";
},"84":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "     <div data-section=\"save-as-template\">\n\n          <div class=\"row\">\n                <div class=\"col-4\">\n                   <input id=\"template-save\" name=\"saveAsTemplate\" data-bind=\"model\" type=\"checkbox\" data-hook=\"template-save\">\n                   <label for=\"template-save\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.save.as.template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":397,"column":47},"end":{"line":397,"column":93}}}))
    + "</label>\n                </div>\n          </div>\n\n       <div class=\"row  template-form\">\n            <div class=\"col-2\">\n                <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":403,"column":39},"end":{"line":403,"column":79}}})) != null ? stack1 : "")
    + "\">\n                   <label for=\"templateCode\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":404,"column":64},"end":{"line":404,"column":93}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(85, data, 0),"inverse":container.program(87, data, 0),"data":data,"loc":{"start":{"line":405,"column":20},"end":{"line":410,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class=\"col-2\">\n                <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":414,"column":39},"end":{"line":414,"column":79}}})) != null ? stack1 : "")
    + "\">\n                   <label for=\"templateDescription\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":415,"column":71},"end":{"line":415,"column":107}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(89, data, 0),"inverse":container.program(91, data, 0),"data":data,"loc":{"start":{"line":416,"column":20},"end":{"line":421,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n        <div class=\"row  template-form\">\n            <div class=\"col-2\">\n               <input id=\"RESTRICTTEMPLATE_FLAG\" name=\"restrict\" data-bind=\"model\" type=\"checkbox\">\n               <label for=\"RESTRICTTEMPLATE_FLAG\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.transfers.restrict.template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":428,"column":51},"end":{"line":428,"column":98}}}))
    + "</label>\n            </div>\n        </div>\n    </div>\n";
},"85":function(container,depth0,helpers,partials,data) {
    return "                        <p class=\"form-control-static\" data-text=\"setName\" data-bind=\"model\"></p>\n";
},"87":function(container,depth0,helpers,partials,data) {
    return "                        <input id=\"templateCode\" type=\"text\" class=\"form-control\" name=\"setName\" maxlength=\"35\" data-bind=\"model\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"setName\"></span>\n";
},"89":function(container,depth0,helpers,partials,data) {
    return "                        <p class=\"form-control-static\" data-text=\"setDescription\" data-bind=\"model\"></p>\n";
},"91":function(container,depth0,helpers,partials,data) {
    return "                        <input id=\"templateDescription\" type=\"text\" class=\"form-control\" name=\"setDescription\" maxlength=\"35\" data-bind=\"model\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"setDescription\"></span>\n";
},"93":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary\" data-hook=\"approve-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":436,"column":89},"end":{"line":436,"column":116}}}))
    + "</button>\n";
},"95":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApprove") : depth0),{"name":"if","hash":{},"fn":container.program(96, data, 0),"inverse":container.program(98, data, 0),"data":data,"loc":{"start":{"line":439,"column":49},"end":{"line":439,"column":106}}})) != null ? stack1 : "")
    + "\" data-hook=\"delete-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"button.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":439,"column":134},"end":{"line":439,"column":160}}}))
    + "</button>\n";
},"96":function(container,depth0,helpers,partials,data) {
    return "btn-secondary";
},"98":function(container,depth0,helpers,partials,data) {
    return "btn-primary";
},"100":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary\" data-hook=\"restore-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.restore",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":442,"column":89},"end":{"line":442,"column":116}}}))
    + "</button>\n";
},"102":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"templateEdit") : depth0),{"name":"if","hash":{},"fn":container.program(103, data, 0),"inverse":container.program(105, data, 0),"data":data,"loc":{"start":{"line":445,"column":16},"end":{"line":449,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSaveLater") : depth0),{"name":"unless","hash":{},"fn":container.program(107, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":450,"column":16},"end":{"line":452,"column":27}}})) != null ? stack1 : "");
},"103":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary\" data-hook=\"submit-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"template.button_save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":446,"column":92},"end":{"line":446,"column":125}}}))
    + "</button>\n";
},"105":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary\" data-hook=\"submit-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":448,"column":92},"end":{"line":448,"column":118}}}))
    + "</button>\n";
},"107":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"save-later-button\" >"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"buttons.saveForLater",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":451,"column":99},"end":{"line":451,"column":132}}}))
    + "</button>\n";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasModify") : depth0),{"name":"if","hash":{},"fn":container.program(110, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":455,"column":16},"end":{"line":457,"column":23}}})) != null ? stack1 : "");
},"110":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"modify-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":456,"column":94},"end":{"line":456,"column":120}}}))
    + "</button>\n";
},"112":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"unapprove-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.unapprove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":460,"column":93},"end":{"line":460,"column":122}}}))
    + "</button>\n";
},"114":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"reject-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.reject",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":463,"column":90},"end":{"line":463,"column":116}}}))
    + "</button>\n";
},"116":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"transition-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.transition",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":466,"column":94},"end":{"line":466,"column":124}}}))
    + "</button>\n";
},"118":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"approve-transition-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"adminPayment_.button_approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":469,"column":102},"end":{"line":469,"column":143}}}))
    + "</button>\n";
},"120":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"reject-transition-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"adminPayment_.button_reject",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":472,"column":101},"end":{"line":472,"column":141}}}))
    + "</button>\n";
},"122":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"cancel-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":475,"column":86},"end":{"line":475,"column":112}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideReturnButton") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    <h1 class=\"landing-header\"></h1>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTemplateMaxAmoutMessage") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "")
    + "\n<section class=\"section section-container\">\n	<div class=\"section-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"templateMode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(54, data, 0),"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":335,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRecur") : depth0),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.program(67, data, 0),"data":data,"loc":{"start":{"line":339,"column":8},"end":{"line":356,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPaymentAudit") : depth0),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":358,"column":8},"end":{"line":360,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTemplateAudit") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":361,"column":8},"end":{"line":363,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTransitionAudit") : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":365,"column":8},"end":{"line":367,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</section>\n\n<div class=\"section section-summary\">\n    <div class=\"section-footer\">\n\n      	<div data-hook=\"schedulePayWarning\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRecur") : depth0),{"name":"unless","hash":{},"fn":container.program(80, data, 0),"inverse":container.program(82, data, 0),"data":data,"loc":{"start":{"line":375,"column":8},"end":{"line":388,"column":19}}})) != null ? stack1 : "")
    + "\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSaveTemplate") : depth0),{"name":"unless","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":391,"column":4},"end":{"line":432,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApprove") : depth0),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":435,"column":12},"end":{"line":437,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDelete") : depth0),{"name":"if","hash":{},"fn":container.program(95, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":438,"column":12},"end":{"line":440,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRestore") : depth0),{"name":"if","hash":{},"fn":container.program(100, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":441,"column":12},"end":{"line":443,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":444,"column":12},"end":{"line":453,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(109, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":454,"column":12},"end":{"line":458,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUnapprove") : depth0),{"name":"if","hash":{},"fn":container.program(112, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":459,"column":12},"end":{"line":461,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReject") : depth0),{"name":"if","hash":{},"fn":container.program(114, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":462,"column":12},"end":{"line":464,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTransition") : depth0),{"name":"if","hash":{},"fn":container.program(116, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":465,"column":12},"end":{"line":467,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveTransition") : depth0),{"name":"if","hash":{},"fn":container.program(118, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":468,"column":12},"end":{"line":470,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRejectTransition") : depth0),{"name":"if","hash":{},"fn":container.program(120, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":471,"column":12},"end":{"line":473,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideCancelButton") : depth0),{"name":"unless","hash":{},"fn":container.program(122, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":474,"column":12},"end":{"line":476,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});