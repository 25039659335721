import AuditHistoryList from 'app/auditHistory/views/auditHistoryList';

export default {
    setup(view, detailModel) {
        view.$('[data-name="audit-history"]').one('show.bs.collapse', () => {
            const auditHistoryList = new AuditHistoryList({
                detailModel,
                functionCode: detailModel.get('FUNCTION'),
                tnum: detailModel.get('TNUM'),
                type: detailModel.get('TYPE'),
                productCode: detailModel.get('PRODUCT'),
                entryMethod: detailModel.get('ENTRYMETHOD'),
            });
            if (view.auditListRegion) {
                view.auditListRegion.show(auditHistoryList);
            }
        });
    },
};
