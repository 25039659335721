import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({
    /*
     * model: Account,
     * comparator: 'accountName',
     */

    initialize(options) {
        this.buildCustomFilters(options);
        this.isAdmin = (window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal');
        this.typeCode = this.isAdmin ? 'CLUSR_TM' : '_USER';
        this.functionCode = this.isAdmin ? 'MAINT' : 'USERS';
        this.productCode = this.isAdmin ? 'CMAINT' : '_ADMIN';
    },

    sync(method, model, options) {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {

                inquiryId: '9038',
                action: {
                    typeCode: this.typeCode,
                    entryMethod: 0,
                    actionMode: 'SELECT',
                    functionCode: this.functionCode,
                    productCode: this.productCode,
                },
                customFilters: this.customFilters,
                queryType: 'SearchQuery',
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 1,
                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },
                    userId: '',
                },
                requestId: '',
            },
        };

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const returnArray = [];
        util.each(response, (rowItem) => {
            const accountName = rowItem.mapDataList.find(datum => datum.toField === 'ACCOUNTNAME');
            const obj = {
                id: rowItem.name,
                label: util.unescape(rowItem.label),
                accountName: accountName?.value || '',
                mapDataList: rowItem.mapDataList,
            };
            returnArray.push(obj);
        });
        return returnArray;
    },

    buildCustomFilters(options) {
        this.customFilters = [];

        if (options && options.userGroup) {
            this.customFilters.push({
                filterName: 'Depends',
                filterParam: ['UserGroup', options.userGroup],
            });
        }
    },

});
