import CollectionView from '@glu/core/src/collectionView';
import EmptyView from 'common/chart/views/emptyChart';
import AccountGroupView from './accountGroup';

const CombinedAccountsCollection = CollectionView.extend({
    initialize(options) {
        this.filterModel = options.filterModel;
        this.filterView = options.filterView;
        this.canViewTransactions = (options.canViewTransactions !== undefined)
            ? options.canViewTransactions : true;
    },

    itemView: AccountGroupView,

    itemViewOptions() {
        return {
            filterModel: this.filterModel,
            filterView: this.filterView,
            stack: this.options.stack,
            canViewTransactions: this.canViewTransactions,
            accountDetailServiceName: this.options.accountDetailServiceName,
        };
    },

    emptyView: EmptyView,
});

export default CombinedAccountsCollection;
