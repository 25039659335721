import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import nhUtil from 'system/utilities/accessors';
import Formatter from 'system/utilities/format';
import confirmPayNowLayoutTmpl from './confirmPayNowLayout.hbs';

export default Layout.extend({
    initialize(options) {
        this.model = options.model;
        this.account = options.account;
        this.vendors = options.vendors;
        this.initializeDialog();
    },

    template: confirmPayNowLayoutTmpl,

    ui: {
        $paymentTotal: '.payment-total',
    },

    initializeDialog() {
        this.dialogTitle = 'Confirm Payment Details';

        this.dialogButtons = [{
            text: locale.get('smbPayments.pay'),
            className: 'btn btn-success',
            callback: 'pay',
        }, {
            text: locale.get('smbPayments.edit'),
            className: 'btn btn-secondary',
            callback: 'edit',
        }];
    },

    templateHelpers() {
        return {
            VENDORNAME: nhUtil.getFieldValue(this.model, 'VENDORNAME'),
            PAYDATE: this.model.get('PAYDATE'),
            TOTALAMOUNT: Formatter.formatCurrency(nhUtil.getFieldValue(this.model, 'TOTALAMOUNT')),
        };
    },

    /**
     * Handle the pay action.
     */
    pay() {
        this.trigger('pay', this.model, this.account, this.vendors);
    },

    /**
     * Handle cancel action.
     */
    edit() {
        this.trigger('edit', this.model);
    },
});
