export default props => ({
    helpBlock: {
        color: props.palette.form.error,
        fontSize: '12px',
    },

    userGroupDropdown: {
        display: 'flex',
        '& label p': {
            marginBottom: 5,
        },
        '& label': {
            flex: 1,
            '&::before': {
                content: ' ',
                color: props.palette.form.error,
            },


        },
        '& [data-qa="dropdown-position"]': { /* position the dropdown directly under the select label, no gap */
            top: '58px',
            zIndex: 9,
        },
    },

    userGroupDropdownRequired: {
        display: 'flex',
        '& label': {
            flex: 1,
            '&::before': {
                content: '"*"',
                color: props.palette.form.error,
            },
            '& p:first-of-type': {
                marginTop: '-18px',
                marginLeft: '8px',
                paddingBottom: '0',
                marginBottom: '5px',
            },
        },
        '& [data-qa="dropdown-position"]': { /* position the dropdown directly under the select label, no gap */
            top: '58px',
            zIndex: 9,
        },
    },
});

