import ItemView from '@glu/core/src/itemView';
import moment from 'moment';
import depositHistoryItemTmpl from './depositHistoryItem.hbs';

export default ItemView.extend({
    template: depositHistoryItemTmpl,
    className: 'DepositHistory-item',

    events: {
        'click [data-hook="getDepositHistoryItem"]': 'showCheckImages',
    },

    templateHelpers() {
        return {
            formatCheckDate: this.formatCheckDate,
        };
    },

    /**
     * Format the date string from the server
     * @param {string} dateString - valid date string
     */
    formatCheckDate(dateString) {
        return moment(dateString).format('MM/DD/YYYY, hh:mm:ss A');
    },

    /**
     * Trigger the on item clicked event
     */
    showCheckImages() {
        this.trigger('on:item:clicked', this.model);
    },
});
