import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import constants from 'app/reports/constants';
import workspaceHelper from 'common/workspaces/api/helper';
import ReportSettings from './reportSettings';
import LockboxReport from './lockboxReportWrapper';
import template from './lockboxReportBase.hbs';

const LockboxSummaryReportView = Layout.extend({
    template,

    initialize() {
        this.settings = store.get('lbxReportSettings');
        this.setNotificationData({
            title: 'LockboxReport',
        });
    },

    onRender() {
        this.reportSettingsView = new ReportSettings();
        if (this.settings) {
            this.renderReport();
            this.reportSettingsView.model.set(this.settings.toJSON());
        }
        this.reportSettingsRegion.show(this.reportSettingsView);
        this.listenTo(this.reportSettingsView, 'submit', this.renderReport);
        this.listenTo(this.reportSettingsView, 'reset', this.resetReport);
        this.notifyPageLoaded();
    },

    /**
     * @method renderReport
     * @param {object} settings - The compiled report settings model.
     */
    renderReport(settings) {
        this.settings = settings ? settings.model || this.settings : this.settings;

        // create new report w/ proper settings
        this.lockboxReportView = new LockboxReport({
            reportSettings: this.settings,
            defaultView: store.get('lbxDefaultView'),

            flexModel: (settings ? settings.flexModel : {
                data: [],
            }),
        });

        if (this.reportRegion) {
            this.reportRegion.show(this.lockboxReportView);
        }
    },

    resetReport() {
        if (this.lockboxReportView) {
            this.lockboxReportView.close();
            this.lockboxReportView = null;
        }
    },

    gridRowAction(options) {
        return new Promise((resolve) => {
            if (options.action.toUpperCase() === 'VIEWRPT') {
                store.set(constants.REPORTING_CONTEXT, options.model.context);
                store.set(constants.REPORTING_VIEWMODEL, options.model);
                this.navigateTo(constants.REPORT_VIEW_ROUTE);
            }
            resolve();
        });
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'LOCKBOX_REPORTING',
    view: LockboxSummaryReportView,
    options: {},
});

export default LockboxSummaryReportView;
