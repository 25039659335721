var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.companies",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":34}}}))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "    <div class=\"assign-all-msg\">\n        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assign-all-msg-companies","All current/future originators have been assigned.",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":102}}}))
    + "\n    </div>\n\n    <div data-hook=\"grid-section\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"grid companies-grid\"></div>\n    </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"checkbox\">\n                <input id=\"assignAllChkCompanies-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":49},"end":{"line":5,"column":56}}}) : helper)))
    + "\" type=\"checkbox\" data-hook=\"assign-all-checkbox\" class=\"assign-all-checkbox\">\n                <label for=\"assignAllChkCompanies-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":57}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assign-all-companies","Assign all current/future originators",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":59},"end":{"line":6,"column":136}}}))
    + "</label>\n            <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllTooltipCompanies") || (depth0 != null ? lookupProperty(depth0,"assignAllTooltipCompanies") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllTooltipCompanies","hash":{},"data":data,"loc":{"start":{"line":7,"column":123},"end":{"line":7,"column":152}}}) : helper)))
    + "\">\n                <span class=\"icon-info\"></span>\n            </a>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"right\">\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRole") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":27}}})) != null ? stack1 : "")
    + "\n                <button type=\"button\" class=\"btn btn-tertiary add-companies-button\">\n                    <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.addCompanies",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":58},"end":{"line":25,"column":87}}}))
    + "\n                </button>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-tertiary add-limits-button\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.quicklyApplyLimits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":86},"end":{"line":21,"column":121}}}))
    + "</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCHECK") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":34,"column":0},"end":{"line":98,"column":7}}})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.applications",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":37}}}))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":1},"end":{"line":44,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"assign-all-msg\">\n        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assign-all-msg-applications","All current/future originators have been assigned.",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":46,"column":105}}}))
    + "\n    </div>\n\n    <div data-hook=\"grid-section\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":61,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"grid applications-grid\"></div>\n    </div>\n\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	    <div class=\"checkbox\">\n	        <input id=\"assignAllChkCompanies-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":38,"column":42},"end":{"line":38,"column":49}}}) : helper)))
    + "\" type=\"checkbox\" data-hook=\"assign-all-checkbox\" class = 'assign-all-checkbox'>\n	        <label for=\"assignAllChkCompanies-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":39,"column":43},"end":{"line":39,"column":50}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assign-all-applications","Assign all current/future originators",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":52},"end":{"line":39,"column":132}}}))
    + "</label>\n            <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllTooltipCompanies") || (depth0 != null ? lookupProperty(depth0,"assignAllTooltipCompanies") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllTooltipCompanies","hash":{},"data":data,"loc":{"start":{"line":40,"column":123},"end":{"line":40,"column":152}}}) : helper)))
    + "\">\n                <span class=\"icon-info\"></span>\n            </a>\n        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"right\">\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRole") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":16},"end":{"line":55,"column":27}}})) != null ? stack1 : "")
    + "\n                <button type=\"button\" class=\"btn btn-tertiary add-companies-button\">\n                    <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.addApplications",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":58},"end":{"line":58,"column":90}}}))
    + "\n                </button>\n            </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h4>"
    + alias2(((helper = (helper = lookupProperty(helpers,"bankAccountHeading") || (depth0 != null ? lookupProperty(depth0,"bankAccountHeading") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"bankAccountHeading","hash":{},"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":66,"column":30}}}) : helper)))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":75,"column":19}}})) != null ? stack1 : "")
    + "\n    <div class=\"assign-all-msg\">\n        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"role.assign-all-msg-accounts","All current/future accounts have been assigned.",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":8},"end":{"line":78,"column":99}}}))
    + "\n    </div>\n\n    <div data-hook=\"grid-section\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":95,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"grid bank-accounts-grid\"></div>\n    </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"checkbox\">\n            <input id=\"assignAllChkAccts-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":69,"column":41},"end":{"line":69,"column":48}}}) : helper)))
    + "\" type=\"checkbox\" data-hook=\"assign-all-checkbox\">\n            <label for=\"assignAllChkAccts-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":70,"column":42},"end":{"line":70,"column":49}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"role.assign-all-accounts","Assign All Accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":70,"column":51},"end":{"line":70,"column":110}}}))
    + "</label>\n            <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"assignAllTooltipAccounts") || (depth0 != null ? lookupProperty(depth0,"assignAllTooltipAccounts") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignAllTooltipAccounts","hash":{},"data":data,"loc":{"start":{"line":71,"column":123},"end":{"line":71,"column":151}}}) : helper)))
    + "\">\n                <span class=\"icon-info\"></span>\n            </a>\n        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"right\">\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRole") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":87,"column":27}}})) != null ? stack1 : "")
    + "\n\n                <button type=\"button\" class=\"btn btn-tertiary add-bank-accounts-button\">\n                    <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"uce.addBankAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":58},"end":{"line":91,"column":90}}}))
    + "\n                </button>\n\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isACH") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":100,"column":7}}})) != null ? stack1 : "");
},"useData":true});