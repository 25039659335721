import Collection from '@glu/core/src/collection';
import InquiryApi from 'common/inquiry/api';
import Beneficiary from 'app/smb/models/map/beneficiary';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    model: Beneficiary,

    sync(method, model, options) {
        const self = this;

        const data = InquiryApi.getRowsData({
            requestParameters: this.data(),
        });

        http.post(services.inquiryRowsData, data, (result) => {
            self.processData(result);
            options.success();
        }, (result) => {
            options.error({
                errorCode: result.status,
                errorMessage: result.statusText,
            });
        });
    },
});
