var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"toa-locations-dropdown\">\n    <div class=\"toa-locations-dropdown--label\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"reports.location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":47},"end":{"line":2,"column":78}}}))
    + "</div>\n    <div class=\"form-group\">\n        <input type=\"text\" class=\"form-control\" name=\"LOCATION\"  maxlength=\"35\" data-bind=\"model\" data-hook=\"location-select\"/>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"LOCATION\"></span>\n    </div>\n</div>\n";
},"useData":true});