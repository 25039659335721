var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"clearfix\">\n    <p class=\"static-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.dueDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":58}}}))
    + "</p>\n    <div class=\"form-group clearfix\">\n        <div data-hook=\"schedulerDueDateRegion\"></div>\n    </div>\n\n    <p class=\"static-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.expirationDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":65}}}))
    + "</p>\n    <div class=\"form-group clearfix\">\n        <div data-hook=\"schedulerExpirationDateRegion\"></div>\n    </div>\n</div>";
},"useData":true});