export default ({ palette }) => ({
    messageAlert: {
        color: palette.variants.warning.contrastText,
        paddingTop: '15px',
        margin: '0 20px',

        '& p': {
            margin: 0,
        },
    },
});
