import dom from 'jquery';
import ItemView from '@glu/core/src/itemView';
import CollectionView from '@glu/core/src/collectionView';
import Formatter from 'system/utilities/format';
import newPaymentOptionsTmpl from './newPaymentOptions.hbs';

const NewPaymentOptions = ItemView.extend({
    template: newPaymentOptionsTmpl,
    binding: true,

    ui: {
        $options: '.option-radio',
        $parents: '.payment-option',
    },

    events: {
        'click @ui.$parents': 'handleOptionDivClick',
    },

    handleOptionDivClick(e) {
        dom('.payment-option').removeClass('active');
        this.ui.$parents.filter(e.currentTarget).addClass('active');
        this.ui.$options.prop('checked', true);
    },

    templateHelpers() {
        return {
            optionCost: Formatter.formatCurrency(this.model.get('optionCost')),
            showCost: (this.model.get('optionCost') > 0),
            showOptions: (this.optionCount > 1),
        };
    },

    initialize(options) {
        this.optionCount = options.optionCount;
    },
});

const listNewPaymentOption = CollectionView.extend({
    itemView: NewPaymentOptions,

    itemViewOptions() {
        return {
            optionCount: this.optionCount,
        };
    },

    getChildView() {
        return NewPaymentOptions;
    },

    initialize(options) {
        this.optionCount = options.collection.length;
    },
});

export default listNewPaymentOption;
