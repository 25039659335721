import React from 'react';
import PropTypes from 'prop-types';
import NoHistoryRecords from './NoHistoryRecords';

const HistoryRows = ({ rowHeader, rows }) => {
    const filteredRowHeader = rowHeader.filter(el => el.displayOrder > -1);
    const filteredRowHeaderFields = filteredRowHeader.map(el => el.fieldName);

    let tableBodyCode = <NoHistoryRecords />;

    if (rows && rows.length > 0) {
        const columns = rows.map(row => row.columns); // context not needed.
        const filterFunc = el => (filteredRowHeaderFields.indexOf(el.fieldName) > -1);
        const mapperFunc = columnsRecord => columnsRecord.filter(filterFunc);
        const filteredColumns = columns.map(mapperFunc);

        tableBodyCode = filteredColumns.map((column, trIndex) => {
            const TDs = column.map((el, tdIndex) => <td key={tdIndex}>{el.fieldValue}</td>);

            return <tr key={trIndex}>{TDs}</tr>;
        });
    }

    return (
        <React.Fragment>
            {tableBodyCode}
        </React.Fragment>
    );
};

HistoryRows.propTypes = {
    rowHeader: PropTypes.arrayOf(PropTypes.shape({})),
    rows: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HistoryRows;
