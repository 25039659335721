import CompositeView from '@glu/core/src/compositeView';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import GroupView from './groupView';
import dynamicTabTmpl from './dynamicTab.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default CompositeView.extend({
    template: dynamicTabTmpl,
    itemView: GroupView,
    itemViewContainer: '.dynamic-groups',

    ui: {
        $selectAll: '.select-all-dynamic',
    },

    events: {
        'click @ui.$selectAll': 'selectAll',
    },

    initialize(opts) {
        const groupCollection = this.model.get('groups');

        if (opts.mode === constants.MODES.VIEW) {
            groupCollection.reset(groupCollection.filter(group => group.isEntitled()));
        }

        this.collection = groupCollection;

        this.itemViewOptions = {
            userGroup: opts.model.userGroupModel.get('id'),
            mode: opts.mode,
        };
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el?.find('.dynamic-groups input[type="checkbox"]');
    },

    /*
     * Selects/deselects all actions
     * @param  {jquery/event} evt
     */
    selectAll(evt) {
        const { checked } = evt.currentTarget;
        this.collection.each((group) => {
            group.get('types').each((type) => {
                const entitlement = type.get('entitlements').at(0);

                util.each(entitlement.get('actions'), (action, name) => {
                    if (action.applicable) {
                        entitlement.set(`actions.${name}.entitled`, checked);
                    }
                });
            });
        });
        this.onChildrenChange();
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === constants.MODES.VIEW,
        };
    },
});
