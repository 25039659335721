var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <fieldset>\n     <legend class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"passcode.phone.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":62}}}))
    + "</legend>\n     <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":5},"end":{"line":18,"column":12}}})) != null ? stack1 : "")
    + "        <div class=\"field-container-xs default-options\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"default") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n     </div>\n </fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"field-container-md\">\n            <label for=\"phone_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":30},"end":{"line":8,"column":37}}}) : helper)))
    + "\" class=\"main-label\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.phone.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":91}}}))
    + "</label>\n            <input id=\"phone_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":36}}}) : helper)))
    + "\" type=\"text\" name=\"contact\" class=\"phone form-control\" data-bind=\"model\"/>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"contact\"></span>\n        </div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <div class=\"field-container-xs contact-readonly\">\n                <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"contact") || (depth0 != null ? lookupProperty(depth0,"contact") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"contact","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":35}}}) : helper)))
    + "</strong>\n                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"delete\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.removefrom",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":84},"end":{"line":16,"column":114}}}))
    + "</button>\n          </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"textVisible":true,"className":"default-contact","locale":"PS.voice.message.default","name":"tick-solid"},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":122}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"btn-tertiary contact-option","action":"setDefault","textVisible":true,"locale":"PS.voice.message.make.default","name":"tick"},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":153}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"removeRecord") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":11}}})) != null ? stack1 : "");
},"useData":true});