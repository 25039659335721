import 'jui/draggable';
import 'jui/droppable';
import { appBus } from '@glu/core';
import Glu from '@glu/core/src/glu';
import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import $ from 'jquery';
import services from 'services';
import http from '@glu/core/src/http';
import errHandler from 'system/errHandler';
import Formatter from 'system/utilities/format';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import Transfer from 'app/smb/models/transfer';
import constants from 'app/balanceAndTransaction/constants';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import accountUtil from 'app/smbPayments/util/accountUtil';
import { dark } from 'common/util/featureUtil';
import { getMaskingConfiguration } from 'common/util/maskingUtil';
import tmpl from './flyout.hbs';


export default Layout.extend({

    template: tmpl,
    loadingTemplate,

    initialize() {
        this.accountsCollection = new Collection([]);
        this.loadRequiredData();
    },

    ui: {
        transferAmountInput: '.xfer-amount',
        transferAmountStatic: '.xfer-amtconf',
        transferAmountLabel: '.xfer-form label',
        buttonWrapper: '.topspace',
        cancelBtn: '.xfer-form .cancel',
        submitBtn: '.xfer-form .submit',
        accountList: 'ul.accounts',
        nonLoanAccounts: '.accounts li.non-loan',
        transferForm: '.xfer-box .xfer-form',
        menuMessage: '.xfer-form .flyout-menu-message',
        transferFrom: '.xfer-box .xfer-from',
        transferTo: '.xfer-box .xfer-to',
        currencyPrefix: '.pre-dollar b',
        header: 'header',
        navigateToLink: '.to-nav-primary',
        closeBtn: 'button.close-flyout',
        nav: 'nav.flyout-menu',
    },

    events: {
        'click @ui.cancelBtn': 'cancelTransfer',
        'click @ui.submitBtn': 'submitTransfer',
        'click @ui.header': 'handleNavigation',
    },

    templateHelpers() {
        return {
            accounts: this.accountsCollection.toJSON(),
            preferredFormat: userInfo.getCurrencyFormat(),
        };
    },

    handleNavigation() {
        const url = this.ui.navigateToLink.attr('href');
        this.navigateTo(url);
    },
    /**
     * Fetch checking and savings accounts
     * @returns {Promise}
     */
    getCheckingAndSavings() {
        return this.getAccounts(constants.DEPOSIT_ACCTS_SUMMARY);
    },
    /**
     * Fetch loan accounts
     * @returns {Promise}
     */
    getLoans() {
        return this.getAccounts(constants.LOAN_ACCTS_SUMMARY);
    },
    /**
     * Fetch credit card accounts
     * @returns {Promise}
     */
    getCreditCards() {
        return this.getAccounts(constants.CREDIT_CARD_ALL_ACCTS_SUMMARY);
    },
    /**
     * Fetch accounts from the endpoint
     * @param {string} endPoint - endpointn to use for the accounts
     * @returns {Promise}
     */
    getAccounts(endPoint) {
        const url = services.generateUrl(endPoint);
        return new Promise((resolve, reject) => {
            http.post(url, {}, (result) => {
                resolve(result);
            }, (status) => {
                // eslint-disable-next-line
                    reject(`${status.status} ${status.statusText}`);
            });
        });
    },
    loadRequiredData() {
        return Promise.all([
            this.getCheckingAndSavings(),
            this.getLoans(),
            dark.isLive('CCREPORTING') ? this.getCreditCards() : Promise.resolve({}),
        ]).then(([checkingSavingsResp, loansResp, creditCardsResp]) => {
            if (checkingSavingsResp.rows) {
                this.setAccountFlyoutModels(checkingSavingsResp.rows, false);
            }
            if (loansResp.rows) {
                this.setAccountFlyoutModels(loansResp.rows, false);
            }
            if (creditCardsResp.rows) {
                this.setAccountFlyoutModels(creditCardsResp.rows, false);
            }
            this.setHasLoadedRequiredData(true);
            this.render();
        }).then(null, errHandler);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            // NH-170562: Focus on render
            this.ui.closeBtn.focus();

            const $firstFocusableElement = this.ui.closeBtn;
            const $lastFocusableElement = this.ui.submitBtn;

            // NH-170562: Trap focus inside the modal and handle escape to close
            this.$el.on('keydown', (e) => {
                const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
                const isEscapePressed = e.key === 'Escape';

                if (!isTabPressed && !isEscapePressed) {
                    return;
                }

                if (isEscapePressed) {
                    this.closeFlyout(e);
                    return;
                }

                if (e.shiftKey) {
                    if ($firstFocusableElement.is(':focus')) {
                        $lastFocusableElement.focus(); // add focus for the last focusable element
                        e.preventDefault();
                    }
                } else if ($lastFocusableElement.is(':focus')) {
                    $firstFocusableElement.focus();
                    this.ui.nav.get(0)?.scrollTo(0, 0);
                    e.preventDefault();
                }
            });
        }
    },

    setAccountFlyoutModels(accountData) {
        const self = this;
        const maskingConfig = getMaskingConfiguration();
        accountData.forEach((account) => {
            const accountType = self.getFieldValue(account.columns, 'ACCOUNTTYPE');
            const accountNumber = self.getFieldValue(account.columns, 'ACCOUNTNUMBER');
            const accountName = self.getFieldValue(account.columns, 'ACCOUNTNAME');
            const maskedAccount = self.maskAccountNumber(accountNumber);
            const availableBalanceField = accountUtil.getAvailableBalanceField(accountType);
            const total = self.getFieldValue(account.columns, availableBalanceField);
            let liClasses = '';
            let draggableDroppable = false;

            if (accountUtil.isDeposit(accountType)) {
                liClasses = 'non-loan';
                draggableDroppable = true;
            }

            const accountModel = new Model({
                ACCOUNTNAME: accountName,
                ACCOUNTNUMBER: accountNumber,
                /*
                 * NH-161604
                 * Depending on configuration of masking, the account number
                 * will be displayed
                 */
                MASKEDACCOUNTNUMBER: maskingConfig.disableMask ? accountNumber : maskedAccount,
                TOTAL: total,
                NOTE: '',
                LI_CLASSES: liClasses,
                DRAGGABLE_DROPPABLE: draggableDroppable,
            });

            self.accountsCollection.add(accountModel);
        });
    },

    maskAccountNumber(account) {
        return account.replace(/.(?=.{4})/g, 'X');
    },

    getFieldValue(arrData, fieldName) {
        try {
            return util.findWhere(arrData, {
                fieldName,
            }).fieldValue;
        } catch (e) {
            return '';
        }
    },

    initializeDragDropItems() {
        const self = this;

        let fromAccount = '';
        let fromBalance = 0;
        let toAccount = '';

        this.ui.nonLoanAccounts.draggable({
            helper: 'clone',
            containment: 'parent',
            start() {
                fromAccount = $(this).data('account');
                fromBalance = $(this).data('balance');
            },
            revert(event) {
                $(this).data('ui-draggable').originalPosition = {
                    top: 0,
                    left: 0,
                };
                return !event;
            },
        }).droppable({
            hoverClass: 'account-hover',
            drop() {
                $('.draggable-dragging').remove();

                toAccount = $(this).data('account');

                self.ui.transferAmountInput.show();
                self.ui.currencyPrefix.show();
                self.ui.transferAmountStatic.hide();
                self.ui.transferAmountLabel.hide();

                self.ui.transferFrom.text(fromAccount);
                self.ui.transferTo.text(toAccount);

                // Show the transfer form
                self.ui.accountList.addClass('vanish');
                self.ui.transferForm.addClass('appear');
                self.ui.transferAmountInput.attr('max', fromBalance);
            },
        });
    },

    cancelTransfer() {
        this.ui.transferAmountInput.val('');
        this.ui.accountList.removeClass('vanish');
        this.ui.transferForm.removeClass('appear');
        this.appBus.trigger('flyout:closeFlyout');
    },

    submitTransfer() {
        const self = this;

        this.ui.transferAmountInput.hide();
        const transferModel = new Transfer();
        const transferFrom = this.ui.transferFrom.text();
        const transferTo = this.ui.transferTo.text();
        const amount = this.ui.transferAmountInput.val();
        const formattedAmount = Formatter.formatCurrency(amount);

        const fromAccount = util.find(self.accountsCollection.models, account => account.get('ACCOUNTNUMBER') === transferFrom);

        const toAccount = util.find(self.accountsCollection.models, account => account.get('ACCOUNTNUMBER') === transferTo);

        const transferDate = moment(new Date()).format('MM/DD/YYYY');

        transferModel.set('fromAccountId', fromAccount.get('ACCOUNTNUMBER'));
        transferModel.set('toAccountId', toAccount.get('ACCOUNTNUMBER'));
        transferModel.set('amount', amount);
        transferModel.set('paymentDate', transferDate);

        const isValid = transferModel.isValid();
        if (isValid) {
            transferModel.save({}, {
                success() {
                    // Success function
                },
                error() {
                    // This needs an error condition
                },
            });
        }

        /*
         * This needs to be moved into the success condition above
         * Show the transfer overview
         */
        this.ui.transferAmountStatic.html(formattedAmount).show();
        this.ui.currencyPrefix.hide();
        this.ui.transferAmountLabel.show();
        this.ui.menuMessage.show();
        this.ui.buttonWrapper.hide();

        /*
         * Hide the message after a delay
         * and animate back to the accounts list
         */
        window.setTimeout(() => {
            self.ui.transferAmountInput.val('');
            self.ui.menuMessage.hide();
            self.ui.accountList.removeClass('vanish');
            self.ui.transferForm.removeClass('appear');
            self.ui.buttonWrapper.show();
        }, 3333);
    },

    closeFlyout(e) {
        e.preventDefault();

        const targetUrl = e.currentTarget?.attributes?.href?.value;
        const baseUri = Glu.Backbone.history.getFragment();

        if (targetUrl !== baseUri && (targetUrl !== '#' && targetUrl !== '' && targetUrl !== undefined)) {
            /**
             * NH-182155 Normal navigation items use ClickHandler.jsx
             * which triggers this event with the newStack option set to true.
             * Becaue the flyout doesn't use the click handler, pass these
             * options directly with the event. NOTE: This is required because widgets
             * can be added to the workspace that require persisting data and therefore
             * we have to use stackViews. See NH-144020 for more information.
             */
            appBus.trigger('router:navigate', targetUrl, { trigger: true, newStack: true });
        }

        // NH-170562: Focus on nav menu item when flyout closes
        $('nav.glu-menu [class^="item-PAYMENTS_smbAccountsCenter"]').focus();
        this.appBus.trigger('flyout:closeFlyout');
    },
});
