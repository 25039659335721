import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import entitlements from 'common/dynamicPages/api/entitlements';
import { EntitlementContext } from './entitlementContext';


const EntitlementProvider = (props) => {
    const [entitlementFetched, setEntitlementFetched] = useState(null);

    const getEntitlementByType = (typeCode) => {
        const find = entitlementFetched.find(item => item.typeCode === typeCode);
        return find || null;
    };

    useEffect(() => {
        const fetchEntitlement = () => {
            let { entitlements: entitlementsArray } = props;

            if (entitlementsArray && entitlementsArray.constructor === Object) {
                entitlementsArray = [entitlementsArray];
            }

            const fetchEach = entitlementsArray.map(ent => entitlements.getEntitlements(ent));

            Promise.all(fetchEach).then((response) => {
                if (response) {
                    setEntitlementFetched(response.map(item => item.actions));
                }
            });
        };

        fetchEntitlement();
        return () => {};
    }, [props, props.entitlements]);


    return entitlementFetched && (
        <EntitlementContext.Provider
            value={{ getEntitlementByType, entitlements: entitlementFetched }}
        >
            {props.children}
        </EntitlementContext.Provider>
    );
};

EntitlementProvider.propTypes = {
    children: PropTypes.element.isRequired,
    entitlements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default EntitlementProvider;
