import FileImport from 'app/bab/views/fileImport';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import importVendorTmpl from 'app/administration/views/sso/importVendor.hbs';

export default FileImport.extend({
    template: importVendorTmpl,

    ui: util.extend(
        {},
        FileImport.prototype.ui,
        {
            $import: '[data-hook="getSubmit"]',
            $cancel: '[data-hook="getCancel"]',
        },
    ),

    events: {
        'click @ui.$import': 'submit',
        'click @ui.$cancel': 'cancel',
    },

    /**
     * @method submit
     * Set the file & event name, file content, and import type onto the model.
     * Render error message if applicable
     * @returns {boolean}
     */
    submit() {
        const self = this;
        const { files } = this.ui.$fileImport[0];

        if (!this.checkFileName()) {
            return false;
        }
        if (!files || !files[0]) {
            return undefined;
        }

        const [file] = files;
        const reader = new FileReader();

        reader.onload = function (readerEvt) {
            self.ui.$progressBar.parent().removeClass('hide'); // show our progress bar
            const binaryString = readerEvt.target.result;

            self.import.set({
                importService: '/tableMaintenance/ssoVendors/import',
                fileName: file.name,
                importType: 'SSOPRPRTSIMP',
                eventName: 'SSO Properties Import',
                importJCode: 'SSOPRPRTSIMP',
                fileContent: window.btoa(binaryString),
                importParameters: [],
                paymentData: [],
            });
            /*
             * import parameters & payment data is expected by the import model yet not
             * required for our workflow
             */

            self.import.save(
                {},
                {
                    success(model, confirmResponse) {
                        store.set('sso:action:success', confirmResponse);
                        self.appBus.trigger('create:success', confirmResponse);
                    },

                    error(model, confirmResponse) {
                        self.actionErrorForDetail(confirmResponse);
                    },
                },
            );
        };
        // TODO: Should this be returned?
        reader.readAsText(file);
        return undefined;
    },

    /**
     * @method handleImportSuccess
     * Navigate to list screen
     */
    handleImportSuccess() {
        /*
         * @TODO when rest service is complete store REST response and render message
         * above live view
         */
        this.navigateTo('SSOADMIN/ssoAdministration');
    },

    /**
     * @method cancel
     * Navigate to list screen
     */
    cancel() {
        this.navigateTo('SSOADMIN/ssoAdministration');
    },
});
