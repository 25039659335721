import store from 'system/utilities/cache';
import dialog from '@glu/dialog';
import { appBus } from '@glu/core';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import WorkspaceView from 'common/workspaces/views/workspace';
import transform from 'common/util/transform';
import StackView from 'common/stack/views/stackView';
import { removeViewIdFromRoute } from 'system/utilities/routeHelper';
import constants from './constants';
import AddressBookAPI from './service/addressBook';
import AddContactView from './views/addContact';
import ContactView from './views/viewContact';
import BeneAddressBookView from './views/list';
import BabFileImportView from './views/fileImport';

export default {
    beneAddContact(corpOrSmb) {
        const view = new AddContactView({
            corpOrSmb,
            lastFragment: this.lastFragment,
        });

        this.showPage('New Contact', view);
    },

    beneViewContact(corpOrSmb) {
        const model = store.get(constants.STORE_BAB_VIEWMODEL);
        const addrAPI = new AddressBookAPI();
        const self = this;

        if (model) {
            addrAPI.read(model.get('id')).then((result) => {
                const data = transform.pairsToHash(result.item);

                const view = new ContactView({
                    mode: 'view',
                    corpOrSmb,
                    data,
                    lastFragment: self.lastFragment,
                });

                self.showPage('View Contact', view);
            }, (result) => {
                dialog.alert(result);
                appBus.trigger('router:navigate', 'SETUP/viewContact/corp', true);
            });
        } else {
            appBus.trigger('router:navigate', 'SETUP/viewContact/corp', true);
        }
    },

    beneModifyContact(corpOrSmb) {
        const model = store.get(constants.STORE_BAB_VIEWMODEL);
        const addrAPI = new AddressBookAPI();
        const route = (corpOrSmb === 'corp') ? 'ADMINSTRATION/manageBeneAddressBook' : 'smbContact/customers';
        const self = this;
        let lastFragment;
        if (corpOrSmb !== 'corp') {
            if (this.currentView?.mode === 'view') {
                lastFragment = store.get('current-workspace-route');
            } else {
                ({ lastFragment } = this);
            }
        }

        if (model) {
            addrAPI.read(model.get('id')).then(addrAPI.reduce).then((result) => {
                const view = new AddContactView({
                    mode: 'modify',
                    data: result.data,
                    accountListData: result.accountList,
                    corpOrSmb,
                    lastFragment,
                });

                self.showPage('Modify Contact', view);
            }, (result) => {
                dialog.alert(result);
                appBus.trigger('router:navigate', route, true);
            });
        } else {
            appBus.trigger('router:navigate', route, true);
        }
    },

    babfileImport() {
        const options = {
            menuCategory: 'SETUP',
            serviceName: '/fimport/import',
            serviceFunc: '',
            businessType: '',
            context: '',
            returnRoute: 'ADMINSTRATION/manageBeneAddressBook',
            mode: 'import',
        };
        this.showPage('', new BabFileImportView(options));
    },

    beneManageContacts(viewId) {
        removeViewIdFromRoute(viewId);
        if (processDeeplink('ADMINSTRATION/listBeneAddressBook', this)) {
            const options = {
                returnRoute: 'ADMINSTRATION/listBeneAddressBook',
                viewId,
            };
            this.showPage('', new BeneAddressBookView(options));
        }
    },

    beneManageContactsWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'CONTACTCENTER',
                    returnRoute: 'ADMINSTRATION/manageBeneAddressBook',
                }),
            }));
        }
    },
};
