import Collection from '@glu/core/src/collection';
import { postData } from 'common/util/services';
import transform from 'common/util/transform';

export default Collection.extend({

    /**
     * @param {string} method
     * @param {Object} model
     * @param {Object} options
     * @returns {Promise}
     */
    sync(method, model, options) {
        const data = this.getRequestData(options);

        return postData(this.getServiceURL(), data, options.success, options.error);
    },

    /**
     * @abstract
     */
    getServiceURL() {
        throw new Error('getServiceURL not implemented');
    },

    /**
     * @abstract
     */
    getRequestData() {
        throw new Error('getRequestData not implemented');
    },

    /**
     * @param {Object} jsonData
     * @returns {Array}
     */
    parse(jsonData) {
        return jsonData.rows.map(this.mapRows);
    },

    /**
     * Convert rows to a list for the collection of models
     *
     * @param {Object} row
     * @returns {Array} - list of object with fieldName and fieldValue mapped
     * to object properties
     */
    mapRows(row) {
        return transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
    },
});
