import number from 'numeral';
import $ from 'jquery';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import PaymentUtil from 'common/util/paymentUtil';

export default function (form, initialState) {
    const formState = form.formView.state;
    const { model } = form.formView;
    const { parentModel } = form.formView;
    let totalFormat = model.get('TOTALFIELD') ? model.get('TOTALFIELD').toUpperCase() : 'AUTOTOTAL';
    const prenoteFlag = form.field('PRENOTEFLAG');
    const taxPeriodDate = form.field('TAXPERIODENDDATE');
    const zeroDollarLiveFlag = form.field('ZERODOLLARLIVE');
    const holdFlag = form.field('HOLDFLAG');
    const decimalPlaces = userInfo.getDecimalPlaces();
    let multiplyFactor = 1;
    let totalAmount = 0;
    const amountFields = ['AMOUNT1', 'AMOUNT2', 'AMOUNT3', 'AMOUNT4', 'AMOUNT5'];
    const modifyMode = formState.toUpperCase() === 'MODIFY';
    const insertMode = formState.toUpperCase() === 'INSERT';
    const isTemplate = (model.context.serviceName.toUpperCase().indexOf('TEMPLATE') > -1);

    if (modifyMode && model.fieldData.TOTALFIELD.value) {
        totalFormat = model.fieldData.TOTALFIELD.value.toUpperCase();
    }

    const resetAllAmounts = function (amt) {
        model.set({
            AMOUNT: amt,
            AMOUNT1: amt,
            AMOUNT2: amt,
            AMOUNT3: amt,
            AMOUNT4: amt,
            AMOUNT5: amt,
            TOTALAMOUNT: amt,
            TOTALAMOUNT_FORMAT: amt,
        }, {
            silent: true,
        });
        form.field('AMOUNT1').setValue(amt);
        form.field('AMOUNT2').setValue(amt);
        form.field('AMOUNT3').setValue(amt);
        form.field('AMOUNT4').setValue(amt);
        form.field('AMOUNT5').setValue(amt);
        form.field('TOTALAMOUNT').setValue(amt);
    };

    /**
     * @method protectTaxAmounts
     * enables or disables the tax amount fields
     */
    const protectTaxAmounts = function (protect) {
        if (totalFormat === 'USERENTERED') {
            // if the total is user entered then the field should obey existing protect logic
            form.field('TOTALAMOUNT').setProperty('disabled', protect);
        } else {
            // if the total is not user entered then it should be derived and protected
            form.field('TOTALAMOUNT').setProperty('disabled', true);
        }
        form.field('AMOUNT1').setProperty('disabled', protect);
        form.field('AMOUNT2').setProperty('disabled', protect);
        form.field('AMOUNT3').setProperty('disabled', protect);
        form.field('AMOUNT4').setProperty('disabled', protect);
        form.field('AMOUNT5').setProperty('disabled', protect);
    };

    const getAmount = function (amountFieldName) {
        let amount = number().unformat(model.get(amountFieldName));
        if (amount !== '' && multiplyFactor) {
            amount *= multiplyFactor;
        }
        return amount;
    };

    /**
     * @method isZDLSelected
     * checks model and correctly applies check
     * @returns {boolean}
     */
    const isZDLSelected = function () {
        const onInModel = model.get('ZERODOLLARLIVE') === '1';
        if (modifyMode) {
            zeroDollarLiveFlag.$el.attr('checked', onInModel);
            return onInModel;
        }
        return onInModel || zeroDollarLiveFlag.$el.is(':checked');
    };

    /**
     * @method isPrenoteSelected
     * checks model and correctly applies check
     * @returns {boolean}
     */
    const isPrenoteSelected = function () {
        const onInModel = model.get('PRENOTEFLAG') === '1';
        if (modifyMode) {
            prenoteFlag.$el.attr('checked', onInModel);
            return onInModel;
        }
        return onInModel || prenoteFlag.$el.is(':checked');
    };

    /**
     * @method toggleCheckboxes
     * if zerodollarlive is set, prenote can not be and is disabled
     * if prenote is set, zerodollarlive can not be and is disabled
     */
    const toggleCheckboxes = function () {
        if (isZDLSelected()) {
            prenoteFlag.shouldBeReadOnly(true);
            zeroDollarLiveFlag.shouldBeReadOnly(false);
        } else if (isPrenoteSelected()) {
            prenoteFlag.shouldBeReadOnly(false);
            zeroDollarLiveFlag.shouldBeReadOnly(true);
        } else {
            prenoteFlag.shouldBeReadOnly(false);
            zeroDollarLiveFlag.shouldBeReadOnly(false);
        }
    };

    /**
     * @method setAmountState
     * If prenote or zerodollarlive are set, tax amounts are zero and protected
     */
    const setAmountState = function () {
        if (isZDLSelected() || isPrenoteSelected()) {
            resetAllAmounts('0.00');
            protectTaxAmounts(true);
        } else if (!prenoteFlag.$el.is(':checked') && !zeroDollarLiveFlag.$el.is(':checked')) {
            resetAllAmounts('');
            protectTaxAmounts(false);
        }
        toggleCheckboxes();
    };

    const taxPeriodDateRule = function () {
        if (model.get('AUTHAGENCYNAME') === 'Arkansas Insurance') {
            taxPeriodDate.shouldBeHidden();
        } else {
            taxPeriodDate.shouldBeVisible();
        }
    };

    const handleTotalReset = function () {
        model.set({
            TOTALAMOUNT: totalAmount,
            AMOUNT: totalAmount,
        }, {
            silent: true,
        });
        form.field('TOTALAMOUNT').setValue(totalAmount);
    };

    /**
     * @method holdFlagSet
     * checks the model setting and correctly applies the check
     */
    const holdFlagSet = function () {
        const onInModel = model.get('HOLDFLAG') === '1';
        if (modifyMode) {
            holdFlag.$el.attr('checked', onInModel);
            return;
        }
        PaymentUtil.toggleCheckboxLock($('[name="HOLDFLAG"]'));
    };

    /*
     * This will update the lock icon for any of the amount fields that are actually
     * present on the screen
     * It will also leave disabled, the totalamount field, if other amount fields
     * are present, as the total amount field is protected anyway
     */
    const amountLockUpdate = function () {
        // only perform this if is template and is in modify or insert mode
        if (!isTemplate || (!insertMode && !modifyMode)) {
            return;
        }

        let amountFieldsPresent = false;
        amountFields.forEach((fieldName) => {
            const $amt = form.field(fieldName).$el;
            if ($amt && $amt.length > 0) {
                amountFieldsPresent = true;
                PaymentUtil.runChangeAmount($amt[0], model);
            }
        });

        const $total = form.field('TOTALAMOUNT').$el;
        /*
         * remove the lock icon, if total amount can not be locked, due to other
         * amount fields present
         */
        if (amountFieldsPresent && $total && $total.length > 0) {
            $total.first().closest('.field-container').children('.mdf-input-icon-container').find('[data-action="toggleLock"]')
                .addClass('hidden');
            /*
             * dont call the function to disable or enable, if other amount
             * fields are present
             */
            if (!amountFieldsPresent) {
                PaymentUtil.runChangeAmount($total[0], model);
            }
        } else {
            $total.first().closest('.field-container').children('.mdf-input-icon-container').find('[data-action="toggleLock"]')
                .removeClass('hidden');
        }
    };

    /**
     * @method amountChanged
     *  Runs on changes for the various amount fields and deals with their changed
     * values appropriately
     */
    const amountChanged = function () {
        multiplyFactor = (10 ** decimalPlaces);

        // set summaryLocaleKey if not present
        const functionCode = model.context.functionCode || model.context.actionContext.functionCode;
        const localeKey = (functionCode === 'INST' || functionCode === 'BATCH')
            ? 'common.summary.total' : 'common.template.summary.total';
        const amountValues = amountFields.map(getAmount);
        const formatIndex = amountFields.indexOf(totalFormat);

        form.field('TOTALAMOUNT').shouldBeReadOnly(true);

        switch (totalFormat) {
        case 'AUTOTOTAL':
            totalAmount = amountValues.reduce((acc, value) => {
                if (acc === '' || value === '') {
                    return '';
                }
                return acc + value;
            }, 0);
            break;
        case 'USERENTERED':
            form.field('TOTALAMOUNT').shouldBeReadOnly(false);
            break;
        default:
            totalAmount = amountValues[formatIndex];
            break;
        }

        if (totalAmount !== '') {
            totalAmount = number().unformat(totalAmount) / multiplyFactor;
            totalAmount = Formatter.formatCurrency(totalAmount || 0);
            model.set({
                TOTALAMOUNT: totalAmount.toString(),
                /*
                 * used by payments/achBatch.js to re-render footer total
                 * on 'save as template' change
                 */
                TOTALAMT: totalAmount.toString(),
                AMOUNT: totalAmount.toString(),
            }, {
                silent: true,
            });

            /*
             * NH-35026 - On model change (value date, save as template),
             * achBatch refreshes summary section with data from parentModel
             */
            parentModel.set({
                TOTALAMOUNT: totalAmount.toString(),
                TOTALAMT: totalAmount.toString(),
                AMOUNT: totalAmount.toString(),
            }, {
                silent: true,
            });

            // Updates should only occur for the 1st bene (which is not entered through a modal)
            if (form.formView.options && !form.formView.options.isModal) {
                $('[data-field="summary-amount"]').text(totalAmount.toString());
                PaymentUtil.updateACHSummaryTotal(
                    model, form.formView,
                    { TOTALNUM: 1, localeKey },
                );
            }
        }
        handleTotalReset();
        amountLockUpdate();
    };

    const resetModel = function () {
        taxPeriodDateRule();

        model.set({
            TAXIDNUMBER: '',
            TAXPERIODENDDATE: '',
            VERIFICATION: '',
            ENTRYDESC: '',
            COMPDESCDATE: '',
            DISCDATA: '',
            AUX1_DATE: '',
            AUX1_NUMBER: '',
            AUX1_TEXT: '',
            AUX2_DATE: '',
            AUX2_NUMBER: '',
            AUX2_TEXT: '',
            AUX3_DATE: '',
            AUX3_NUMBER: '',
            AUX3_TEXT: '',
            AUX4_DATE: '',
            AUX4_NUMBER: '',
            AUX4_TEXT: '',
            AUX5_DATE: '',
            AUX5_NUMBER: '',
            AUX5_TEXT: '',
            PAYMENTADDENDA: '',
            HOLDFLAG: '0',
            ZERODOLLARLIVE: '0',
        }, {
            silent: true,
        });

        const prenoteFormat = model.get('PRENOTE_FORMAT');
        if (prenoteFormat && prenoteFormat.toUpperCase() === 'ON') {
            prenoteFlag.$el.attr('checked', true);
            model.set({
                PRENOTEFLAG: '1',
            }, {
                silent: true,
            });
        }

        if (modifyMode) {
            if (!isTemplate) {
                resetAllAmounts('0.00');
            } else {
                resetAllAmounts('');
            }
        }

        holdFlag.$el.attr('checked', false);
        zeroDollarLiveFlag.$el.attr('checked', false);
        PaymentUtil.toggleZdlPrenoteFlags(zeroDollarLiveFlag, prenoteFlag, false, model.get('PRENOTEFLAG') === '1');
    };

    if (initialState) {
        protectTaxAmounts(model.get('ZERODOLLARLIVE') === '1' || prenoteFlag.$el.is(':checked'));

        // Reset amount fields to 0 if 'Zero Dollar Live Entry' or 'Create Prenote' is selected
        model.on('change:ZERODOLLARLIVE', setAmountState);
        model.on('change:PRENOTEFLAG', setAmountState);
        model.on('change:HOLDFLAG', holdFlagSet);
        holdFlagSet();

        if (insertMode) {
            if (!isTemplate) {
                setAmountState();
            }
            // USACH Tax Payments is always USD
            model.set('DESTCURRENCYCODE', 'USD');
        }

        if (modifyMode || insertMode) {
            toggleCheckboxes();
            model.set('STATUS_DESCRIPTION', '');

            /*
             * initial state and we are modify or insert
             * dont protect specific fields
             * This should be handled in protectedfields table
             * however it appears that functionality has been over-written and everything is
             * protected by default.  So for now, has to be here.
             */
            form.field('TAXIDNUMBER').shouldBeReadOnly(false);
            form.field('RECEIVCOMPNAME').shouldBeReadOnly(false);
            form.field('PRENOTEFLAG').shouldBeReadOnly(false);
            form.field('VERIFICATION').shouldBeReadOnly(false);

            // NH-88514 hide the formatted addenda for insert and modify
            form.formView.$('#PAYMENTADDENDA').addClass('hidden');
        }

        amountFields.forEach((fieldName) => {
            form.field(fieldName).$el.on('change', amountChanged);
        });

        form.field('TOTALAMOUNT')
            .$el.on('blur', () => {
                const theTotalAmount = model.get('TOTALAMOUNT');
                model.set(
                    'AMOUNT',
                    theTotalAmount,
                    {
                        silent: true,
                    },
                );
                form.field('TOTALAMOUNT').$el.trigger('change');
            });

        // handle lock icon for amount if template and not view
        amountLockUpdate();

        /*
         * There is a chance we can be in this state
         * with recur already selected, which means amount1
         * field may not have been available when setting up
         * scheduler.  Make sure amount1 is required at this
         * point when recurrence is selected.
         */
        if (parentModel.get('recur') === true) {
            const field = 'AMOUNT1';
            const $amount1 = $(`[name="${field}"]`);
            $amount1.prop('required', true);
            $amount1.closest('.field-container').toggleClass('required', true);
            model.addValidator(
                field,
                {
                    description: model.fieldData[field].fieldLabel,
                    exists: true,
                    minValue: 0.01,
                },
            );
        }
    }

    if (model.hasChanged('TAXNAME_TYPECODE')) {
        const currentValue = model.get('TAXNAME_TYPECODE');
        const previousAttr = model.previousAttributes();
        const previousValue = previousAttr.TAXNAME_TYPECODE;

        if (currentValue !== previousValue) {
            previousAttr.TAXNAME_TYPECODE = currentValue;
            resetModel();
        }
    }

    taxPeriodDateRule();
    PaymentUtil.toggleZdlPrenoteFlags(zeroDollarLiveFlag, prenoteFlag, model.get('ZERODOLLARLIVE') === '1', model.get('PRENOTEFLAG') === '1');
}
