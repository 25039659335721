var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"totals-wrapper\">\n        <h5><span class=\"hidden\">*</span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"field1Label") || (depth0 != null ? lookupProperty(depth0,"field1Label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field1Label","hash":{},"data":data,"loc":{"start":{"line":3,"column":42},"end":{"line":3,"column":57}}}) : helper)))
    + "</h5>\n        <span data-hook=\"summary-num-payments\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"field1Amount") || (depth0 != null ? lookupProperty(depth0,"field1Amount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field1Amount","hash":{},"data":data,"loc":{"start":{"line":4,"column":47},"end":{"line":4,"column":63}}}) : helper)))
    + "</span>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"totals-wrapper\">\n        <h5>"
    + alias4(((helper = (helper = lookupProperty(helpers,"field2Label") || (depth0 != null ? lookupProperty(depth0,"field2Label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field2Label","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":27}}}) : helper)))
    + "</h5>\n        <span data-hook=\"summary-total-amount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"field2Amount") || (depth0 != null ? lookupProperty(depth0,"field2Amount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"field2Amount","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":63}}}) : helper)))
    + "</span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"field1Label") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"field2Label") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":12,"column":7}}})) != null ? stack1 : "");
},"useData":true});