import React, { useContext, useState } from 'react';
import { Tabs, Tab } from '@glu/tabs-react';
import useLocale from 'hooks/useLocale';
import serverConfig from 'system/webseries/models/configurationParameters';
import { ALERT_DELIVERY_TYPE } from '../constants';
import AlertContext from '../AlertWrapper/AlertContext';

const DeliveryTypeTabs = () => {
    const [enabledSMS] = useState(serverConfig.get('EnableSMSalertContent') === 'true');
    const { getLocaleString } = useLocale();
    const {
        alertDeliveryType,
        setAlertDeliveryType,
    } = useContext(AlertContext);
    const onTabChange = (event, value) => {
        setAlertDeliveryType(value);
    };
    return (
        <Tabs value={alertDeliveryType}>
            <Tab
                value={ALERT_DELIVERY_TYPE.EMAIL}
                onChange={onTabChange}
                data-qa="emailTab"
            >
                {getLocaleString('CMAINT.Email')}
            </Tab>
            { enabledSMS && (
                <Tab
                    value={ALERT_DELIVERY_TYPE.SMS}
                    onChange={onTabChange}
                    data-qa="smsTab"
                >
                    {getLocaleString('CMAINT.Text.Message')}
                </Tab>
            )}
        </Tabs>
    );
};

export default DeliveryTypeTabs;
