const modalClose = (onClose, context) => {
    if (typeof onClose === 'function') {
        onClose.call(context);
    }
};
const modalCancel = (onCancel, context) => {
    if (typeof onCancel === 'function') {
        onCancel.call(context);
    }
};

export {
    modalClose,
    modalCancel,
};
