import Collection from '@glu/core/src/collection';
import services from 'services';
import numeral from 'numeral';
import http from '@glu/core/src/http';
import $ from 'jquery';
import domUtil from 'common/util/domUtil';

export default Collection.extend({
    getRequestJSON() {
        return {
            quickEntry: this.chain().reject(item => !item.has('TEMPLATE_ID')).map(item => ({
                templateId: item.get('TEMPLATE_ID'),
                valueDate: item.get('ENTRY_DATE'),
                amount: numeral((item.get('AMOUNT') || 0)).value(),
                indicativeAmount: numeral((item.get('INDICATIVEAMOUNT') || 0)).value(),
                indicativeRate: numeral((item.get('INDICATIVERATE') || 0)).value(),
                interestRate: item.get('INTEREST_RATE'),
                duplicateReason: item.get('DUPLICATEREASON'),
                saveWithWarning: this.saveWithWarning,
                creditCurrency: item.get('TEMPLATE').get('CREDIT_CURRENCY'),
            })).value(),
        };
    },

    /**
     * save - save a payment
     *
     * @return {promise}  return a promise
     */
    saveAllowWarning() {
        this.saveWithWarning = true;
        return this.save();
    },

    save() {
        const self = this;

        const options = {
            success(resp) {
                self.trigger('sync', self, resp, {});
            },

            error(resp) {
                self.trigger('error', self, resp, {});
            },
        };

        return this.sync('save', this, options);
    },

    sync(method, collection, options, ...rest) {
        let ret;
        if (method === 'save') {
            ret = http.post(services.generateUrl('/payment/quickEntry'), this.getRequestJSON(), options.success, options.error);
            this.trigger('request', collection, ret, options);
        } else {
            ret = Collection.prototype.sync.apply(this, [method, collection, options, ...rest]);
        }
        this.saveWithWarning = false;

        return ret;
    },

    disableButtons() {
        // TODO: clean up?
        domUtil.setDisabled($('.btn'), true);
    },

    enableButtons() {
        // TODO : should be fix this to be specific buttons
        domUtil.setDisabled($('.btn'), false);
    },
});
