import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import ChallengeCollection from 'app/administration/collection/challengeMethods';
import LocaleCollection from 'app/administration/collection/locales';
import constants from 'app/administration/constants';
import UserPendingChanges from 'app/administration/views/userMaintenance2/pendingChanges/pendingChanges';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import Collection from '@glu/core/src/collection';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import { getClientLocations } from 'app/administration/views/userMaintenance/smb/services';
import { modalClose } from 'common/modals/mobileGridModalUtil';
import { maskValue } from 'common/util/maskingUtil';
import SMBDynamicFields from './SMBDynamicFields';
import template from './viewUserCentric.hbs';
import UserCentricAudit from './userCentricAudit';

export default Layout.extend({
    template,
    loadingTemplate,

    regions: {
        audit: '.auditPanel .panel-body',
        smbDynamicFieldsRegion: '.smbDynamicFieldsRegion',
    },

    ui: {
        $mobilePhone: '#MOBILEPHONENUMBER',
        $tokenField: '.token-field',
        $phone: '#PHONENUMBER',
    },

    initialize(opts) {
        this.mode = opts.mode || constants.MODES.VIEW;
        this.company = opts.company;
        this.buttons = opts.buttons;

        this.isAdmin = window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal';

        this.sso = serverConfigParams.get('ClientAppOnSSO');
        this.showTokenSerialNum = serverConfigParams.get('showTokenSerialNum') === 'true';
        this.dialogTitle = 'View User';

        this.dialogButtons = util.filter(util.map(this.buttons, (
            button,
            index,
        ) => ({
            text: button.label,
            className: index === 1 ? 'btn btn-primary' : 'btn btn-secondary',
            callback: button.action.toLowerCase(),
        })), button => button.callback !== constants.ACTIONS.SELECT);

        this.dialogButtons.push({
            text: locale.get('administration.close.btn'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        });
    },

    cancel() {
        modalClose(this.options.onClose, this);
        dialog.close();
    },

    modify() {
        this.cancel();
        this.trigger('userAction:switchToModify');
    },

    delete() {
        this.doAction('delete');
    },

    disable() {
        this.doAction('disable');
    },

    approve() {
        this.doAction('approve');
    },

    simulate() {
        this.trigger('userAction:simulateUser');
    },

    updset() {
        this.trigger('userAction:updateUserProfile');
        this.cancel();
    },

    copyfromuser() {
        this.trigger('userAction:copyFromUser');
        this.cancel();
    },

    doAction(action) {
        const options = {
            success: (response) => {
                this.trigger('userAction:success', response, action);
                this.cancel();
            },

            error: (response) => {
                this.trigger('userAction:error', response, action);
                this.cancel();
            },

            context: this.options.context,
        };

        this.model.sync(action, this.model, options);
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            this.audit.show(new UserCentricAudit({
                model: this.model,
            }));

            const userModel = this.model;
            userModel.set({
                COMPANY: this.company,
            });
            this.smbDynamicFields = new SMBDynamicFields({
                mode: this.mode,
                model: userModel,
            });
            this.smbDynamicFieldsRegion.show(this.smbDynamicFields);
            if (this.mode === constants.MODES.VIEW) {
                this.initPendingChangesSection();
            }
        }
    },

    loadRequiredData() {
        const options = {
            isAdmin: this.isAdmin,
        };
        const LocaleCollectionPromise = new Promise((resolve) => {
            new LocaleCollection(options).fetch({
                success: (result) => {
                    resolve(result);
                },
            });
        });
        const clientLocationsPromise = getClientLocations(this.model);

        Promise.all([
            LocaleCollectionPromise,
            clientLocationsPromise,
        ]).then(([result, clientLocations]) => {
            this.clientLocations = clientLocations;
            this.setClientLocationDisplayname(this.clientLocations, this.model);
            // Add fallback for missing LOCALE
            this.localeDisplay = (result.get(this.model.get('LOCALE')) || result.at(0)).get('label');
            if (this.model.get('CHALLENGEMETHOD')) {
                new ChallengeCollection(options).fetch({
                    success: this.processChallengeCollection.bind(this),
                });
            } else {
                this.setHasLoadedRequiredData(true);
                this.render();
            }
        });
    },

    /**
     * Addin display name to the list of clientLocations in the model
     * @param {Array} clientLocations
     * @param {Object} model
     */
    setClientLocationDisplayname(clientLocations, model) {
        let locations = model.get('clientLocations') || [];
        locations = locations.map((location) => {
            const found = clientLocations.find(loc => location.id === loc.id);
            return {
                ...location,
                name: found.name,
            };
        });
        model.set('clientLocations', locations);
    },

    /**
     * @method processChallengeCollection
     * @param challengeCollection
     * Display the challenge method in the markup if found. Create a listener on
     * the model when the value changes,
     * hide/show the token-field based on that change.
     */
    processChallengeCollection(challengeCollection) {
        const challengeMethod = this.model.get('CHALLENGEMETHOD');
        const challengeModel = challengeCollection.get(challengeMethod);

        /**
         * The chalResult collection contains a list of models using the enabled Challenge
         * Methods values as the ID. (e.g 0 for OTP, 1 for RSA, 7 for Vasco, etc)
         * When the user is saved without a challenge method, the system appears to
         * set a default challenge method 0 for the user. If this is not among the enabled
         * Challenge Methods, when a bank user views the selected user, chalResult returns
         * undefined with chalResult.get(0).get('label') thus causing a error to appear
         */
        this.challengeDisplay = challengeModel ? challengeModel.get('label') : false;

        this.model.on('change:CHALLENGEMETHOD', () => {
            if (this.model.get('CHALLENGEMETHOD') === constants.VASCO_CHALLENGE_METHOD || this.model.get('CHALLENGEMETHOD') === constants.SYMANTEC_VIP_CHALLENGE_METHOD) {
                this.$('.token-field').show();
            } else if (this.showTokenSerialNum) {
                this.$('.token-field').show();
            } else {
                this.$('.token-field').hide();
                this.model.set('TOKENSERIALNUMBER', '');
            }
        });
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * @method initPendingChangesSection
     * Creates the panel section where user can preview the pending changes of
     * a user with 'modified' status. If the user has approval and rejection
     * permissions, they will also be given that option in the view
     */
    initPendingChangesSection() {
        /*
         * Extract out Approve and Reject buttons from User Permitted Actions
         * NOTE: reject does not exist yet but will need to be referenced when availble in
         * the future
         */
        const userActions = util.reduce(this.buttons, (actionsParam, btn) => {
            const actions = actionsParam;
            if (btn.action.toLowerCase() === constants.ACTIONS.APPROVE) {
                actions.approve = btn;
                actions.approve.callback = this.approve.bind(this);
            }
            return actions;
        }, {});

        // Create View
        this.pendingChangesView = new UserPendingChanges({
            userProfile: this.model,
            actions: userActions,
        });

        this.pendingChangesRegion.show(this.pendingChangesView);
    },

    /**
     * Extract the boolean value from the company mapped data.
     *
     * @param {string} setting - the name of the attribute to extract from the map data.
     */
    getSettingBooleanValue(setting) {
        const field = util.findWhere(
            this.company.get('mapDataList'),
            {
                toField: setting,
            },
        );
        return field && field.value && field.value === '1';
    },

    /**
     * Check to see if the model contains client locations
     * @returns {Boolean}
     */
    hasClientLocations() {
        return this.model.hasPermission('remoteDepositCapture')
            && serverConfigParams.get('RDCProviderName') === 'Deluxe'
            && !util.isEmpty(this.model.get('clientLocations'));
    },

    templateHelpers() {
        return {
            localeDisplay: this.localeDisplay,
            challengeDisplay: this.challengeDisplay,
            isActivationDateShow: serverConfigParams.get('enableActivationDate') === 'Y' && this.model.get('ACTIVATIONDATE'),
            showTokenSerialNumber: this.model.get('TOKENSERIALNUMBER'),
            showOptUI: this.isAdmin && !this.company.get('optUISecurityCheck'),
            isDailyLimitEnabled: this.model.get('dailyLimit') ? this.model.get('dailyLimit').applicable : false,
            isTransactionLimitEnabled: this.model.get('transactionLimit') ? this.model.get('transactionLimit').applicable : false,
            isFileLimitEnabled: this.model.get('fileLimit') ? this.model.get('fileLimit').applicable : false,
            dailyLimit: this.model.get('dailyLimit') ? this.model.get('dailyLimit').assignedLimit : false,
            transactionLimit: this.model.get('dailyLimit') ? this.model.get('transactionLimit').assignedLimit : false,
            fileLimit: this.model.get('dailyLimit') ? this.model.get('fileLimit').assignedLimit : false,
            permissions: new Collection(this.model.get('permissions').reject(permission => permission.get('permission') === 'additionalServices')).toJSON(),
            accounts: this.model.get('accounts').toJSON(),
            bankWidgets: this.model.get('bankWidgets').toJSON(),
            alternateUserIdValue: this.model.get('ALTERNATEUSERID'),
            maskAccounts: val => maskValue(val),

            isOFXDirectConnectEntitled: () => this.model.get('permissions').some(model => model.get('permission') === 'OFXDirectConnect' && model.get('entitled') === true),

            entitledPaymentsChk: () => {
                const reqForPayments = this.getSettingBooleanValue('CompanyRequireBeneFromAdressBookForPayments');
                const userGlobalSettings = this.model.get('globalSettings');
                return (reqForPayments
                    || (userGlobalSettings && userGlobalSettings.BENEPAY === true));
            },

            showAllowFXSelection: appConfigParams.getValue('WIRES', 'ALLOWFXUSDUSER') === '1',

            allowFXValue: this.model.get('ALLOWFXUSDUSER') === '1'
                ? locale.get('uce.Allow.FX') : locale.get('uce.USD.Only'),
            isBankWidgetsEntitled: () => this.model.get('permissions').some(model => model.get('permission') === 'additionalServices' && model.get('entitled') === true),
            hasClientLocations: this.hasClientLocations(),
        };
    },
});
