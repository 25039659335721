import util from '@glu/core/src/util';

/**
 * This function formats a numeric character string into one of three known phone number
 * formats. If the string length does not match the expected values, we will display the
 * phone number in its original format
 *
 * @param {string} phone - Phone number string either formatted or not
 * @returns {string} Fully formatted phone number string or original input format
 */
const formatPhoneNumber = (phone) => {
    if (!phone) {
        return phone;
    }

    const phoneNum = phone.replace(/\D/g, '');

    if (phoneNum.length === 11) {
        return phoneNum.replace(/^(\d{1})(\d{3})(\d{3})(\d{4})$/, '+$1 ($2) $3-$4');
    }
    if (phoneNum.length === 10) {
        return phoneNum.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    }
    if (phoneNum.length === 7) {
        return phoneNum.replace(/^(\d{3})(\d{4})$/, '$1-$2');
    }

    /*
     * When the length of the phone number numeric content does not match one of the
     * predetermined phone number formats we instead return the input phone number.
     * The input parameter may already be formatted at this point whereas the local
     * variable will have been stripped of all non-numeric content.
     */
    return phone;
};

/*
 * TODO: When someone has the time, this should really be in a helper rather than a policy file.
 * I see this as something that could be useful outside of this specific application
 */
/**
 * constructs a multi-line address string out of various individual address components
 *
 * @param typeOfAddress {String} - indicates the type of address being constructed. Must
 * @param model {Object} - the model for the form
 * correspond to actual model values
 */
const constructFullAddress = (typeOfAddress, model) => {
    const arrayOfAddressComponents = util.compact([
        model.get(`${typeOfAddress}_NAME`),
        `${model.get(`${typeOfAddress}_BUILDING_NUMBER`)} ${model.get(`${typeOfAddress}_STREET_NAME`)}`,
        model.get(`${typeOfAddress}_ADDRESS_LINE`),
        `${model.get(`${typeOfAddress}_TOWN_CITY`)} ${model.get(`${typeOfAddress}_SUB_DIVISION_STATE`)} ${model.get(`${typeOfAddress}_POSTAL_CODE`)}`,
        model.get(`${typeOfAddress}_COUNTRY`),
        formatPhoneNumber(model.get(`${typeOfAddress}_PHONE_NUMBER`)),
    ]);
    model.set(`${typeOfAddress}_FULL_ADDRESS`, arrayOfAddressComponents.join('\n'));
};

export default function (form, initialState) {
    const { model } = form.formView;

    if (initialState) {
        const $rfpLink = form.formView.$('button[id="RFPLINK"]').closest('div');

        constructFullAddress('DEBTOR', model);
        constructFullAddress('CREDITOR', model);

        /*
         * Here will will hide the real time payment link if the request has not yet been paid
         * or if the user is not entitled to view the payment in question. Additionally we are
         * hiding the link if this screen was navigated to from another detail screen (ie: real
         * time payments) to prevent an end user from infinitely clicking back and forth between
         * the two pages.
         */
        if (model.context.isFromDetail || !model.get('PAYMENT_TNUM')
            || !model.get('LINKEDPAYMENTENTITLED') || !(model.get('LINKEDPAYMENTENTITLED') === 'true')) {
            $rfpLink.hide();
        }
    }
}

export { formatPhoneNumber };
export { constructFullAddress };
