import ManageColumns from '@glu/grid/src/manageColumns/manageColumns';
import store from 'system/utilities/cache';
import ManageColumnsMenu from './manageColumnsMenu';
import manageColumnsTemplate from './manageColumns.hbs';

export default ManageColumns.extend({
    template: manageColumnsTemplate,
    toggleManageColumnMenu(e) {
        e.preventDefault();

        if (!this.manageColumnsLayout) {
            this.manageColumnsLayout = new ManageColumnsMenu({
                collection: this.options.columns,
                filterProcessor: this.options.filterProcessor,
                topPosition: this.ui.$menuTrigger.height(),
                grid: this.grid,
            });

            this.listenTo(this.manageColumnsLayout, 'close', this.removeManageColumnsLayout);

            /*
             * This function 'toggleManageColumnMenu' is an exact copy of the function
             * as defined in glu's manageColumns.js file. The only change is storing
             * the value of the clientY property from the mouse's onClick event
             */
            store.set('manage-columns-menu-event-clientY', e.clientY);

            return this.manageColumnsMenu.show(this.manageColumnsLayout);
        }
        this.removeManageColumnsLayout();
        return undefined;
    },
});
