import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';

const FileImportMapList = ListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'SETUP',
            serviceName: 'tableMaintenance/importMap',
            serviceFunc: null,
            businessType: null,
            context: 'ACH_DIMD_MAINT',
            returnRoute: 'ADMINSTRATION/fileImportMap',
            viewId: options.viewId,
        };
        // Why is this a different return route when coming from notifications widget
        if (options.viewId) {
            superOptions.returnRoute = 'SETUP/fileImportMap';
        }

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    insert() {
        this.navigateTo('SETUP/fileImportMap/insert');
        return Promise.resolve();
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('SETUP/fileImportMap/view');
        return Promise.resolve();
    },

    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('SETUP/fileImportMap/modify');
        return Promise.resolve();
    },
});

let list = FileImportMapList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'tableMaintenance.importMap_.button_insert',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'IMPORT_MAP',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
