import FlexDropdown from '@glu/flex-dropdown';
import locale from '@glu/locale';
import rCount from 'common/dynamicPages/views/mdf/componentHelpers/rcount';
import GridActionFieldView from '../gridActionFieldView';
import template from '../templates/gridActionReject.hbs';

export default GridActionFieldView.extend({
    ui: {
        $commentErrorHolder: '[data-hook="commentErrorHolder"]',
        $commentField: '[name="COMMENT"]',
        $commentFieldContainer: '[data-hook="commentFieldContainer"]',
        $rejectReasonErrorHolder: '[data-hook="rejectReasonErrorHolder"]',
        $rejectReasonFieldContainer: '[data-hook="rejectReasonFieldContainer"]',
    },

    template,

    events: {
        'change @ui.$commentField': 'commentChanged',
    },

    onRender() {
        rCount.setupRCountBoxes(this);
    },

    loadedRequiredData(data) {
        const dropDownData = data.queryResponse.QueryData.queryRows.map(element => ({
            id: element.name,
            name: element.label,
        }));

        const flexDropdown = new FlexDropdown({
            data: dropDownData,
            defaultSelectMessage: locale.get('option.select'),
            disableMultiButton: true,
            filter: false,
            multiSelect: false,
            showTooltip: false,
        });

        this.rejectDropdownRegion.show(flexDropdown);

        this.listenTo(flexDropdown, 'selectionChanged', (newValue) => {
            this.model.set('REJECT_REASON', newValue[0].id, { silent: true });

            // if the comment field is now needed
            if (this.model.get('REJECT_REASON') === '1100' || this.model.get('REJECT_REASON') === 'NARR') {
                this.ui.$commentFieldContainer.removeClass('hidden');

                // remove any currently present errors on the comment field
                this.removeError(this.ui.$commentFieldContainer, this.ui.$commentErrorHolder);
                // update the comment fields characters remaining
                this.ui.$commentField.keyup();
            } else { // the comment field is no longer needed
                this.ui.$commentFieldContainer.addClass('hidden');
                this.model.set('COMMENT', '');
            }

            if (this.ui.$rejectReasonErrorHolder.text() !== '') {
                this.removeError(
                    this.ui.$rejectReasonFieldContainer,
                    this.ui.$rejectReasonErrorHolder,
                );
            }
        });
    },

    /**
     * remove any errors present on the desired field
     * @param {jQuery Object} elementContainer - the container of the field
     * @param {jQuery Object} elementErrorHolder - the error text of the field
     */
    removeError(elementContainer, elementErrorHolder) {
        elementContainer.removeClass('has-error');
        elementErrorHolder.text('');
        this.trigger('sizeOfPopupChanged');
    },

    // return the specific fields within this popup
    getDataForSubmission() {
        return {
            REJECT_REASON: this.model.get('REJECT_REASON'),
            COMMENT: this.model.get('COMMENT'),
        };
    },

    // removes error state from the comment field if present
    commentChanged() {
        if (this.model.get('COMMENT') && this.ui.$commentErrorHolder.text() !== '') {
            this.removeError(this.ui.$commentFieldContainer, this.ui.$commentErrorHolder);
        }
    },

    /**
     * Returns the request payload to retrieve data for field population
     * @param {String} action - the type of action being performed by this popup
     * @returns {Object} - the request object
     */
    getRequestData(action) {
        const config = this.model.context.actionData;
        return {
            queryCriteria: {
                action: {
                    productCode: config.productCode,
                    functionCode: config.functionCode,
                    typeCode: config.typeCode,
                    actionMode: 'SELECT',
                },
                subTypeCode: action,
                fieldName: 'REJECT_REASON',
                entryClass: '',
                allowDuplicates: false,
            },
        };
    },

    /**
     * performs validation on the grid action popup
     * @returns {Boolean} - whether or not the form clears validation
     */
    formIsValid() {
        const rejectReason = this.model.get('REJECT_REASON');
        // if a reject reason has been selected
        if (rejectReason) {
            // if the reject reason demands a comment
            if (rejectReason === '1100' || rejectReason === 'NARR') {
                // if a comment has been supplied
                if (this.model.get('COMMENT')) {
                    return true;
                } // a comment has not been supplied
                this.ui.$commentFieldContainer.addClass('has-error');
                this.ui.$commentErrorHolder.text(locale.get('requestForPayment.error.commentRequired'));
                this.trigger('sizeOfPopupChanged');
                return false;
            }
            return true;
        }

        // a reject reason has not been selected, show an error
        this.ui.$rejectReasonFieldContainer.addClass('has-error');
        this.ui.$rejectReasonErrorHolder.text(locale.get('requestForPayment.error.rejectReasonRequired'));
        this.trigger('sizeOfPopupChanged');
        return false;
    },
});
