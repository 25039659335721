var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.intro",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":42}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.changepin.intro",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":15},"end":{"line":9,"column":52}}}))
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.systempin.intro",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":15},"end":{"line":12,"column":52}}}))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.nexttoken.intro",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":15},"end":{"line":15,"column":52}}}))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMFAPin") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"form-group required\">\n                        <label for=\"token\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.selfregister.code.error",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":43},"end":{"line":33,"column":87}}}))
    + "</label>\n                        <input class=\"form-control\"\n                            data-bind=\"model\"\n                            data-hook=\"getCode\"\n                            id=\"token\"\n                            name=\"token\"\n                            type=\"password\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"token\"></span>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResendActivationLink") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":44,"column":27}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"form-group\" >\n                            <label for=\"tokenPin\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.pin.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":80}}}))
    + "</label>\n                            <input class=\"form-control\"\n                                data-bind=\"model\"\n                                data-hook=\"getPin\"\n                                id=\"tokenPin\"\n                                name=\"tokenPin\"\n                                type=\"password\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"tokenPin\"></span>\n                        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p class=\"col-md-6\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.token.activation.email",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":44},"end":{"line":43,"column":87}}}))
    + " <a data-action=\"resendValidationEmail\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.resend.activation.email",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":127},"end":{"line":43,"column":171}}}))
    + "</a></p>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"form-group\">\n                        <label for=\"newTokenPin\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.newpin.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":49},"end":{"line":49,"column":82}}}))
    + "</label>\n                        <input class=\"form-control\"\n                            data-bind=\"model\"\n                            data-hook=\"getPin\"\n                            id=\"newTokenPin\"\n                            name=\"newTokenPin\"\n                            type=\"password\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"newTokenPin\"></span>\n                    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"form-group\">\n                        <label for=\"systemGenPin\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.sys.gen.pin.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":50},"end":{"line":61,"column":88}}}))
    + "</label>\n                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"SystemGeneratedPin") || (depth0 != null ? lookupProperty(depth0,"SystemGeneratedPin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"SystemGeneratedPin","hash":{},"data":data,"loc":{"start":{"line":62,"column":55},"end":{"line":62,"column":77}}}) : helper)))
    + "</p>\n                    </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"form-group\">\n                        <label for=\"confirmNewTokenPin\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.newpin.confirm.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":56},"end":{"line":67,"column":97}}}))
    + "</label>\n                        <input class=\"form-control\"\n                            data-bind=\"model\"\n                            data-hook=\"getPin\"\n                            id=\"confirmNewTokenPin\"\n                            name=\"confirmNewTokenPin\"\n                            type=\"password\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"confirmNewTokenPin\"></span>\n                    </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"form-group required\">\n                        <label for=\"nextToken\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"mfa.rsa.next.code.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":79,"column":47},"end":{"line":79,"column":83}}}))
    + "</label>\n                        <input class=\"form-control\"\n                            data-bind=\"model\"\n                            data-hook=\"getCode\"\n                            id=\"nextToken\"\n                            name=\"nextToken\"\n                            type=\"password\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"nextToken\"></span>\n                    </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"register-tokens-links-container\">\n                        <button type=\"button\" data-action=\"registerHardTokenButtonListener\" class=\"btn btn-tertiary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.onespan.token.hard",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":117},"end":{"line":91,"column":156}}}))
    + "</button>\n                        <button type=\"button\" data-action=\"registerSoftTokenButtonListener\" class=\"btn btn-tertiary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.rsa.onespan.token.soft",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":117},"end":{"line":92,"column":156}}}))
    + "</button>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form autocomplete=\"off\" data-submit=\"save\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPasscode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNewPin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSystemGenerated") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNextTokenCode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"form-container security-passcode token-code-registration-container\">\n            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPasscode") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":45,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNewPin") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":58,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSystemGenerated") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":64,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showConfirmNewPin") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":76,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNextTokenCode") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":16},"end":{"line":88,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTokenRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":94,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});