import Model from '@glu/core/src/model';
import Accounts from 'app/payments/collections/realTimePayments/accounts';
import EntitlementsModel from 'common/dynamicPages/models/entitlements';

const RTPSimpleEntryModel = Model.extend({
    initialize() {
        this.debitAcctList = new Accounts({});

        this.requestOptions = {
            DEBIT_ACCOUNT_NUMBER: {
                bankCodeKey: 'DEBIT_BANK_CODE',
                currencyKey: 'DEBIT_CURRENCY',
                accountNumberKey: 'DEBIT_ACCOUNT_NUMBER',
            },
        };
    },

    /**
     * get entitlements
     * @param {Object} dataParam - request data
     * @param {function} [successHandler] - Success Callback
     * @param {function} [errorHandler] - Error Callback
     * @returns {Promise}
     */
    getEntitlements(dataParam = {}, successHandler = () => {}, errorHandler = () => {}) {
        const data = dataParam;
        data.options = data.options || {};
        data.options.context = data.options.context || {
            serviceName: '/payment/crtran',

            typeInfo: {
                productCode: 'RTP',
                functionCode: 'INST',
                typeCode: '*',
            },
        };
        data.options.state = 'insert';

        return new Promise((resolve, reject) => {
            const entitlements = new EntitlementsModel({}, data.options);

            entitlements.fetch({
                success(model, response) {
                    const resp = {
                        model,
                        entitlements: response,
                    };
                    resolve(resp);
                    successHandler(resp);
                },

                error() {
                    reject(new Error('Error fetching entitlements'));
                    errorHandler(new Error('Error fetching entitlements'));
                },
            });
        });
    },
});

export default new RTPSimpleEntryModel();
