import $ from 'jquery';
import DialogView from '@glu/core/src/itemView';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import http from '@glu/core/src/http';
import store from 'system/utilities/cache';
import { getLastPositionData, isDeepLinked } from 'common/util/deeplinkUtil';
import modalUtil from 'common/util/modal';

import returnReasonDialogTmpl from './returnReasonDialog.hbs';

const ReturnReasonDialog = DialogView.extend({
    template: returnReasonDialogTmpl,
    pageView: null,
    list: null,
    selectedRow: null,
    initialize(options) {
        this.model = options.model;
        this.pageView = options.pageView;
        this.multiSelect = options.multiSelect;
        this.list = options.list;
        this.selectedRow = options.selectedRow;
        this.serviceUrl = options.serviceUrl;
        this.checkViewer = options.checkViewer;
        this.loadRequiredData(options);
        this.listenTo(this.appBus, 'iframePosition', data => modalUtil.updateModal(data.data, this));
    },

    onRender() {
        // If in iframe, configure with the current position
        if (isDeepLinked()) {
            modalUtil.updateModal(getLastPositionData().data, this);
        }
    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    loadRequiredData(options) {
        const self = this;
        const url = services.generateUrl(this.serviceUrl);
        let postData;

        if (options.type === 'BMPPDEC') {
            postData = this.getPostData('CM', 'BATCH', options.type, '20532');
        } else if (options.type === 'CMPPDEC') {
            postData = this.getPostData('CM', 'BATCH', options.type, '20542');
        }

        http.post(url, postData, (result) => {
            self.returnReasons = result.queryResponse.QueryData.queryRows;
            self.render();
        }, () => {
            // TODO reject(status.status + ' ' + status.statusText);
        });
    },

    getPostData(pc, fc, tc, inquiryId) {
        return {
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,

                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },

                    userId: '',
                },

                channelId: '',
                requestId: '',
            },

            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    typeCode: tc,
                    entryMethod: 0,
                    actionMode: 'MODIFY',
                    productCode: pc,
                    functionCode: fc,
                },

                fieldName: '',
                inquiryId,
                queryType: 'Query',

                customFilters: [{
                    filterName: '',

                    filterParam: [
                        '',
                    ],
                }],
            },

            formatterType: 'Query',
        };
    },

    templateHelpers() {
        const self = this;
        return {
            returnReasons() {
                return self.returnReasons;
            },
        };
    },

    save() {
        const returnReasonCode = $('#return_reason').val();
        const returnReasonDescription = $('#return_reason option:selected').text();

        if (this.multiSelect) {
            this.trigger(
                'list:multi:action:action_return',
                {
                    CLIENTRETURNREASONCODE: returnReasonCode,
                    CLIENTRETURNREASONDESC: returnReasonDescription,
                },
            );

            this.executeReturnRoute();
        } else {
            this.model.set({
                CMDECISION: 'R',
                CLIENTRETURNREASONCODE: returnReasonCode,
                CLIENTRETURNREASONDESC: returnReasonDescription,
                PAYISSUE_FLAG: 'N',
            });

            if (this.list) {
                if (this.selectedRow) {
                    this.list.processMultiActionsAsOne(
                        constants.ACTION_RETURN,
                        this.selectedRow,
                    );
                } else {
                    this.list.gridView.refreshGridData();
                    this.list.renderMessage('RETURN');
                }
            } else if (this.pageView) {
                this.mode = this.options.mode;
                this.contextKey = this.options.contextKey;

                this.pageView.model.return({
                    success: (_, confirmResponse) => {
                        if (this.checkViewer) {
                            store.set(`${this.contextKey}-alertMessageCV`, this.mode);
                            store.set(`${this.contextKey}-confirmsCV`, confirmResponse);
                            this.checkViewer.nextCheck();
                            if (this.options.onNextCheckClick) {
                                this.options.onNextCheckClick();
                            }
                        } else {
                            store.set(`${this.contextKey}-alertMessage`, this.mode);
                            store.set(`${this.contextKey}-confirms`, confirmResponse);
                            this.executeReturnRoute();
                        }
                    },
                });
            }
        }
        this.close();
    },

    /**
     * If options returnRoute is set, navigate to the returnRoute
     */
    executeReturnRoute() {
        if (this.options.returnRoute) {
            this.navigateTo(this.options.returnRoute);
        }
    },
});
export default ReturnReasonDialog;
