import util from '@glu/core/src/util';
import $ from 'jquery';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import LookupCollection from 'common/dynamicPages/collections/lookup';
import LookupABACollection from 'common/dynamicPages/collections/lookups/aba';
import applicationConfiguration from 'system/webseries/models/applicationConfiguration';
import PreferredListTypeAheadHelper from './preferredListTypeAhead';

export default {
    /**
     * handleLookup is run whenever a user interacts with the typeAhead dropdown and it
     * performs a lookup to get valid list items
     * @param {View} view - the corresponding view where this lookup is being performed
     * @param {Model} lookupModel - The lookup model for this lookup call
     * @param {Boolean} isALookupHelperTextFld - whether or not this typeAhead has helper text
     * @param {String} attrName - The name of the attribute for the typeAhead input
     * @param {String} newAttrNameValue - Used to prevent unneeded updates when an item
     * @param {Boolean - default false} attrNameAlreadySet - Used to specify situations where we
     * do not want to trigger a change event on the model for the attrName.
     * within a preferredListTypeAhead is set from an outside source
     * @return {Boolean} - strictly for unit testing purposes
     */
    handleLookup(
        view, lookupModel, isALookupHelperTextFld, attrName, newAttrNameValue,
        attrNameAlreadySet = false,
    ) {
        if (!lookupModel || !lookupModel.get('mapDataList')) {
            return false;
        }
        const mapDataList = lookupModel.get('mapDataList');
        const setData = mapDataList.reduce((accumParam, mapData) => {
            const accum = accumParam;
            accum[mapData.toField.toUpperCase()] = mapData.value;
            return accum;
        }, {});

        // don't set the model with data that doesn't match the newly desired list item
        if (newAttrNameValue && setData[attrName] !== newAttrNameValue) {
            return true;
        }

        view.model.set(setData);

        mapDataList.forEach((mapData) => {
            /*
             * the TYPEAHEAD_PREFERRED makes a call to validate a selected item in the typeAhead
             * every time the attrName changes and runs 'handleLookup' if the item is valid. Don't
             * trigger a change here in that instance, or it will loop infinitely
             */
            if (mapData.toField === attrName && attrNameAlreadySet) {
                return;
            }
            $(`#${mapData.toField}`).trigger('change');
            view.appBus.trigger('typeahead:change', mapData.toField);
        });

        // only trigger if field is a helper text lookup
        if (isALookupHelperTextFld) {
            view.trigger('lookupHelperText:update', attrName);
        }
        return true;
    },

    /**
     * Method initializes the combobox query parameters before a read is done on the
     * typeahead field.  The attrName is the field name.
     * @param {Object} viewParam
     * @param {string} attrName
     * @param {string} filterType
     * @param {Boolean} readOnly
     * @returns {Function} Invoke to finish setuping up the collections before
     * fetching data
     */
    createRequestFunc(viewParam, attrName, filterType, readOnly) {
        const view = viewParam;
        const fldData = view.model.fieldData[attrName];
        let LookupClass = LookupCollection;
        let typeAheadFree = (util.isNull(fldData.typeAheadFree))
            ? false : fldData.typeAheadFree;
        if (!Array.isArray(fldData.typeAhead)) {
            if (fldData.typeAhead === 'aba') {
                LookupClass = LookupABACollection;
            }
        }

        if (fldData.fieldUIType === 'TYPEAHEADFREE') {
            typeAheadFree = true;
        }

        // use meta information when available to not hardcode fieldname
        const suffix = ((fldData.relatedProperty) ? '' : 'LOOKUP');
        const fieldName = (view.addLookupToName) ? `${attrName}LOOKUP`
            : `${attrName}${suffix}`;
        const typeAheadDataInfo = {
            fieldName,
            fieldKey: attrName,
            context: view.context,

            typeInfo: {
                productCode: view.model.jsonData.typeInfo.productCode,
                functionCode: view.model.jsonData.typeInfo.functionCode,
                typeCode: view.model.jsonData.typeInfo.typeCode,
            },

            dependentTypeAheadFields: view.dependentTypeAheadFields,
            typeAheadFields: fldData.typeAhead,
            typeAheadFree,
            view,
        };

        util.each(view.options.typeAheadFieldsOverride, (typeAheadFieldOverride) => {
            if (attrName === typeAheadFieldOverride.name) {
                typeAheadDataInfo.overrideUrl = typeAheadFieldOverride.overrideUrl;
            }
        });

        view.typeAheadCollections[attrName] = new LookupClass(null, typeAheadDataInfo);
        return () => {
            const fieldData = view.model.fieldData[attrName];
            let depends = [];

            if (fieldData.dependsOn) {
                depends = fieldData.dependsOn.map((dependentField) => {
                    let dependsValue = view.model.get(dependentField);
                    if (util.isEmpty(dependsValue) && view.model.isChild) {
                        dependsValue = view.parentModel.get(dependentField);
                    }
                    if (dependentField === 'ENTITLETYPECODE') {
                        return {
                            name: dependentField,
                            value: store.get('typeCode'),
                        };
                    }
                    return {
                        name: dependentField,
                        value: dependsValue,
                    };
                }).filter(dependentEntry => !util.isEmpty(dependentEntry.value));
            }

            // Adding the staticDependsOn defined for the attrName to the depends
            if (view.model.staticDependsOn && view.model.staticDependsOn[attrName]) {
                util.each(view.model.staticDependsOn[attrName], (staticDepends) => {
                    depends.push({
                        name: staticDepends.filterParam[0],
                        value: staticDepends.filterParam[1],
                    });
                });
            }

            view.typeAheadCollections[attrName].depends = depends;
            view.typeAheadCollections[attrName].startRow = 1;

            if (readOnly) {
                view.typeAheadCollections[attrName].queryTerm = view.model.get(attrName);
            }
            if (filterType === 'multi-select' || filterType === 'single-select') {
                view.typeAheadCollections[attrName].requestParameters = {
                    item: [{
                        name: 'PRODUCTCODE',
                        value: view.model.jsonData.typeInfo.productCode,
                    }, {
                        name: 'FUNCTIONCODE',
                        value: view.model.jsonData.typeInfo.functionCode,
                    }, {
                        name: 'TYPECODE',
                        value: view.model.jsonData.typeInfo.typeCode,
                    }, {
                        name: 'INQUIRYID',
                        value: fieldData.popupId,
                    }],
                };
            }
        };
    },

    /**
     * Fetch data for any read only typeAhead fields to dispaly the labe
     * properly as from the server
     * @param {Object} viewParam
     * @param {Object} $el
     */
    setupReadOnlyTypeahead(viewParam, $el) {
        const view = viewParam;
        const self = this;
        $el.find('.read-only-type-ahead').each(function () {
            const attrName = $(this).attr('name');
            const filterType = $(this).attr('data-filter-type');
            self.createRequestFunc(view, attrName, filterType, true)();
            view.typeAheadCollections[attrName].fetch({
                preload: true,
                remove: true,
                success: (resp) => {
                    const valueArray = resp.models.map(model => model.get('text'));
                    const $span = $(this).find('[data-hook="getValueSpan"]');
                    $span[0].innerHTML = valueArray.join('<br>');
                },
                error: () => {},
            });
        });
    },

    /**
     * @param {MetaDrivenForm view} viewParam
     * @param {string} selector
     * @param {JQuery Element} $alt
     * @param {Array} preferredListInputs - A list of type aheads that should use the preferred
     * field helper
     */
    setupTypeaheadBoxes(viewParam, selector = '.type-ahead', $alt, preferredListInputs = []) {
        const view = viewParam;

        // Support an alternate container element
        const $el = $alt || view.$el;

        if (view.typeAheadCollections === undefined) {
            view.typeAheadCollections = {};
        }

        const self = this;

        // Locate read only typeahead fields and set those up
        if ($el.find('.read-only-type-ahead').length) {
            this.setupReadOnlyTypeahead(view, $el);
        }

        /*
         * Loops through each typeahead input on the form for setup and filling with
         * preselected content
         */
        $el.find(selector).each(function (j, el) {
            const attrName = $(this).attr('name');
            const fldData = view.model.fieldData[attrName];
            const filterType = $(this).attr('data-filter-type');
            const multiple = (filterType === 'multi-select');
            const lookupHelperTextFld = fldData.fieldUIType === 'TYPEAHEAD_HELPERTEXT';
            const preferredListTypeAheadFld = (fldData.fieldUIType === 'TYPEAHEAD_PREFERRED' || preferredListInputs.includes(attrName));

            /**
             * Change event listener for typeahead fields
             * @param {jquery event} e - the change event when an item is selected
             * @param {Object} triggered - configuration object to store data when
             * programatically updating the combobox
             */
            const changeListener = (e, triggered = false) => {
                if (triggered && !triggered.manualUpdateForPreferredList) {
                    const id = e.currentTarget.value;
                    const { dependsOn } = view.model.fieldData[attrName];

                    if (id) {
                        view.typeAheadCollections[attrName].queryTerm = id;
                        if (dependsOn) {
                            view.typeAheadCollections[attrName].depends = dependsOn
                                .map(field => ({
                                    name: field,
                                    value: view.model.get(field),
                                }));
                        }
                        view.typeAheadCollections[attrName].fetch({
                            success(response) {
                                if (response && response.models && response.models.length) {
                                    self.handleLookup(
                                        view,
                                        view.typeAheadCollections[attrName].get(id),
                                        lookupHelperTextFld || preferredListTypeAheadFld,
                                        attrName,
                                    );
                                } else {
                                    view.trigger('lookupHelperText:update', attrName);
                                }
                            },
                        });
                    } else {
                        view.trigger('lookupHelperText:clear', attrName);
                    }
                } else if (e.val || triggered.manualUpdateForPreferredList) {
                    const lookupElement = e.val || triggered.lookupElement;
                    self.handleLookup(
                        view,
                        view.typeAheadCollections[attrName].get(lookupElement),
                        lookupHelperTextFld || preferredListTypeAheadFld,
                        attrName,
                    );
                }
            };

            let usePreferredListTypeAhead = preferredListTypeAheadFld;

            /*
             * on rare occasion, even if the field type is "TYPEAHEAD_PREFERRED", we still don't
             * want to use a preferred helper if configurations aren't enabled.
             */
            if ((attrName === 'CORRESPONDENT_ID' || attrName === 'INTERMEDIARY_ID') && usePreferredListTypeAhead) {
                usePreferredListTypeAhead = applicationConfiguration.getValue('WIRES', 'RTGSRESTRICTLOOKUPINTERMEDIARY') === '1';

                /*
                 * FIXME: Currently, the only valid intermediary bank accounts are of type 'SWIFT'.
                 * We should have a way of communicating this through meta-data rather than
                 * hardcoding it here.
                 */
                if (usePreferredListTypeAhead) {
                    view.model.set('CORRESPONDENT_TYPE', 'SWIFT');
                }
                if (attrName === 'INTERMEDIARY_ID') {
                    view.model.set('INTERMEDIARY_IDTYPE', 'SWIFT');
                }
            }

            if (usePreferredListTypeAhead) {
                const preferredListTypeAheadInput = new PreferredListTypeAheadHelper(
                    self,
                    view,
                    fldData,
                    $(this),
                    multiple,
                );

                // initialize the combobox
                preferredListTypeAheadInput.initializeCombobox();

                // determine page-load field behavior and appearance
                preferredListTypeAheadInput.setupFieldState();

                /*
                 * create listeners for dependent model attributes. For bank
                 * repetitive templates (entry method 2) users cannot change
                 * any fields other than the amount and amount type. Lookup
                 * is not needed.
                 */
                if (view.model.get('ENTRYMETHOD') !== '2' && !fldData.locked) {
                    preferredListTypeAheadInput.setupListenersForDependents();
                }

                // ONCHANGE callback
                $(this).off('change.mdf').on('change.mdf', (e, triggered) => {
                    changeListener(e, triggered);
                    preferredListTypeAheadInput.removeWarning();
                });

                return;
            }

            const initRequest = self.createRequestFunc(view, attrName, filterType);

            const selfCombo = this;
            // TYPEAHEAD INSTANTIATION
            $(this).comboBox({
                initSelection(element, callback) {
                    const source = selfCombo.multiple ? element.val().split(',') : [element.val()];
                    let data = util.map($(source), value => ({
                        id: value,
                        text: value,
                    }));
                    if (data.length === 1) {
                        data = util.first(data);
                    }

                    callback(data);
                },

                multiple,
                closeOnSelect: !multiple,
                placeholder: view.model.fieldData[attrName].placeHolder,
                query: util.debounce((query) => {
                    initRequest();

                    if (query.term.length < constants.COMBO_MIN_CHARS) {
                        view.typeAheadCollections[attrName]
                            .setPageSize(constants.COMBO_MIN_SIZE);
                    } else {
                        view.typeAheadCollections[attrName]
                            .setPageSize(constants.MAX_SERVER_ROWSPERPAGE);
                    }

                    view.typeAheadCollections[attrName].queryTerm = query.term;
                    view.typeAheadCollections[attrName].queryPage = query.page;

                    view.typeAheadCollections[attrName].fetch({
                        /*
                         * when fetching additional pages, keep previous models in the
                         * collection
                         */
                        remove: query.page === 1,

                        success(collection, resp) {
                            // extract the data to be added to combo box
                            const result = util.filter(
                                collection.toJSON(),
                                collItem => util.find(
                                    resp,
                                    newItem => newItem.id === collItem.id,
                                ),
                            );

                            const data = {
                                results: result,
                                more: collection.hasMorePages(),
                            };

                            if (data.results.length === 0
                                && collection.typeAheadFree === true) {
                                data.results.push({
                                    id: collection.queryTerm,
                                    text: collection.queryTerm,
                                });
                            } else {
                                util.each(data.results, (rowParam) => {
                                    const row = rowParam;
                                    util.each(row.mapDataList, (objParam) => {
                                        const obj = objParam;
                                        obj.value = util.unescape(obj.value);
                                    });
                                    row.text = util.unescape(row.text);
                                });
                            }

                            query.callback(data);
                        },

                        error() {
                            query.callback({
                                results: [],
                                more: false,
                            });
                        },
                    });
                }, constants.COMBO_DEBOUNCE),
            });

            // ONCHANGE callback
            $(this).off('change.mdf').on('change.mdf', changeListener);

            // preload the comboBox in form with data
            view.once('ui-loaded', () => {
                util.defer(() => {
                    /*
                     * NH-70439: If the view has preselected data to fill typeahead field
                     * will need to pre-fetch the typeahead collection and parse out the
                     * corresponding display fields that match the selected id's
                     */

                    if (util.isEmpty(view.model.get(attrName))) {
                        return;
                    }

                    // If collection already has the model attribute, don't fetch
                    if (view.typeAheadCollections[attrName].get(view.model.get(attrName))) {
                        return;
                    }

                    initRequest();
                    /*
                     * TODO: for now only be specific with single select filter type,
                     * need to fix to be specific on all searches but will need to edit service
                     * call for lookup
                     */
                    view.typeAheadCollections[attrName].queryTerm = view.model.get(attrName);
                    view.typeAheadCollections[attrName].fetch({
                        preload: true,
                        remove: true,

                        success: (collection) => {
                            /*
                             * default to using passed in typeahead ids
                             * if collection returns nothing
                             */
                            const preselectedData = view.model.get(attrName);

                            if (collection.models.length < 1) {
                                return;
                            }

                            const preselectedDataArray = Array.isArray(preselectedData)
                                ? preselectedData : [preselectedData];

                            const typeAheadData = util.map(util.union(
                                [],
                                preselectedDataArray,
                            ), (optionID) => {
                                const selectedOption = collection.get(optionID);
                                return (selectedOption ? selectedOption.toJSON() : {
                                    text: optionID,
                                    id: optionID,
                                });
                            });

                            /**
                             * NH-49476 - Comboboxes that have values that are composed of
                             * multiple fields do not get initialized correctly on modify.
                             * To fix this, the value of the field is
                             *            read in here and then set to displayed
                             * correctly.
                             */
                            // Single select input prefilling
                            if (!multiple) {
                                $(this).select2('data', {
                                    text: typeAheadData[0].text,
                                });
                            } else {
                                $(this).comboBox('data', typeAheadData);
                            }
                        },

                        error() {},
                    });
                }); // defer
            });

            view.once('close', () => {
                view.$(el).comboBox('destroy');
            });
        });
    },
};
