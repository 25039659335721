/* eslint-disable */
/*
 * TODO: Rename functions to lowercase, so we can turn the eslint new-cap rule back on.
 */
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import viewHelpers from './viewHelpers';
import standardHelpers from './standardHelpers';

export default {
    helpers(viewParam) {
        const view = viewParam;

        const getLockedFields = function ({ model = {}, parentModel = {} }) {
            if (model.lockedFields && model.lockedFields.length) {
                return model.lockedFields;
            } else if (parentModel.lockedFields && parentModel.lockedFields.length) {
                return parentModel.lockedFields;
            } else if (parentModel.attributes && parentModel.has('LOCKED_FIELDS') && (parentModel.get('TNUM') || parentModel.get('TARGETFUNCTION'))) {
                // this is a modify or copy from. locked fields will be in the read data
                return parentModel.get('LOCKED_FIELDS').split(',');
            } else if (model.attributes && model.has('LOCKED_FIELDS') && (model.get('TNUM') || model.get('TARGETFUNCTION'))) {
                // this is a modify or copy from. locked fields will be in the
                // read data. if not in parent, then here
                return model.get('LOCKED_FIELDS').split(',');
            }
            return [];
        };
        const getUnlockedFields = function ({ model = {}, parentModel = {} }) {
            if (model.unlockedFields && model.unlockedFields.length) {
                return model.unlockedFields;
            } else if (parentModel.unlockedFields && parentModel.unlockedFields.length) {
                return parentModel.unlockedFields;
            } else if (parentModel.attributes && parentModel.has('UNLOCKED_FIELDS')) {
                // this is a modify or copy from. locked fields will be in the read data
                return parentModel.get('UNLOCKED_FIELDS').split(',');
            } else if (model.attributes && model.has('UNLOCKED_FIELDS')) {
                // this is a modify or copy from. locked fields will be in the
                // read data. if not in parent, then here
                return model.get('UNLOCKED_FIELDS').split(',');
            }
            return [];
        };
        const lockedFields = getLockedFields(view);
        const unlockedFields = getUnlockedFields(view);

        const helpers = {
            isBatch() {
                return view.model.isBatch;
            },

            hasSave() {
                return view.state !== 'view';
            },

            hasDelete() {
                return view.hasEntitlement(constants.ACTION_DELETE) && !this.hasSave();
            },

            hasApprove() {
                return view.hasEntitlement(constants.ACTION_APPROVE) && !this.hasSave();
            },

            hasReject() {
                return view.hasEntitlement(constants.ACTION_APPROVE) && !this.hasSave();
            },

            isModal() {
                return view.viewType === 'modal';
            },

            hasBlocks() {
                return !!view.blocks;
            },

            outputGroups(groups) {
                const that = helpers;
                let blockOutput = '';
                let renderBlock = false;

                util.each(groups, (column) => {
                    // All the components of the group (in order)
                    let columnContainerOpen = '';
                    let collapsibleOpen = '';
                    let columnWrapperOpen = '';
                    let panelDivClass;
                    let fieldOutput = '';
                    let subgroupOutput = '';
                    let columnWrapperClose = '';
                    let collapsibleClose = '';
                    let columnContainerClose = '';

                    // Add opening/closing tags for starts and ends of rows
                    columnContainerOpen = column.startRow ? `<div class="${column.rowClass}">` : '';
                    columnContainerClose = column.endRow ? '</div>' : '';

                    // If this section is collapsible then build the Header and Close components
                    if (column.collapsible) {
                        collapsibleOpen = `<div class="form-column ${column.fieldGroupName} ${column.columnClass}"><div class="panel panel-tertiary">`;
                        collapsibleClose = '</div></div>';
                        if (column.fieldGroupLabel) {
                            if (that.hasMandatoryField(column.subgroups)) {
                                collapsibleOpen += `<div class="panel-heading" role="tab" id="${view.cid}-${column.fieldGroupName}-heading"><h4 class="panel-title ${column.labelClasses}"><a role="button" href="#${view.cid}-${column.fieldGroupName}" aria-expanded="false" class="collapsed" data-toggle="collapse" data-container="${column.fieldGroupName}" ><span></span> ${column.fieldGroupLabel}</a></h4></div>`;
                            } else {
                                collapsibleOpen += `<div class="panel-heading" role="tab" id="${view.cid}-${column.fieldGroupName}-heading"><h4 class="panel-title ${column.labelClasses}"><a role="button" href="#${view.cid}-${column.fieldGroupName}" aria-expanded="false" class="collapsed" data-toggle="collapse" data-container="${column.fieldGroupName}" ><span class="indicator-icon"></span> ${column.fieldGroupLabel}</a></h4></div>`;
                            }

                            panelDivClass = column.fieldOrientation === 'H' ? 'panel-body inline-fields' : 'panel-body';

                            if (that.hasMandatoryField(column.subgroups)) {
                                collapsibleOpen += `<div class="form-column ${column.fieldGroupName} ${column.columnClass}">`;
                                collapsibleClose += '</div>';
                            } else {
                                collapsibleOpen += `<div class="form-container panel-collapse collapse" role="tabpanel" aria-labelledby="${view.cid}-${column.fieldGroupName}-heading" id="${view.cid}-${column.fieldGroupName}"><div class="${panelDivClass}">`;
                                collapsibleClose += '</div></div>';
                            }
                        }
                    } else {
                        columnWrapperOpen = `<div class="form-column ${column.fieldGroupName} ${column.columnClass}">`;
                        if (column.fieldGroupLabel) {
                            columnWrapperOpen += `<h3 class="form-group-label ${column.labelClasses}" role="presentation">${column.fieldGroupLabel}</h3>`;
                        }
                        columnWrapperClose = '</div>';
                    }

                    // Generate fields for the fieldOutput component
                    fieldOutput = that.outputFields(column.fields);

                    // If this field group has subgroups, populate the subgroupOutput component
                    subgroupOutput = column.subgroups ? that.outputGroups(column.subgroups).blockOutput : '';

                    if (fieldOutput || subgroupOutput) {
                        renderBlock = true;
                        blockOutput += [columnContainerOpen,
                            collapsibleOpen,
                            columnWrapperOpen,
                            fieldOutput,
                            subgroupOutput,
                            columnWrapperClose,
                            collapsibleClose,
                            columnContainerClose].filter(s => !util.isEmpty(s)).join('\n');
                    }
                });

                return {
                    renderBlock,
                    blockOutput,
                };
            },

            /*
                * Checks a provided group for fields with matching attributes
                * @param {string} attributeType - the type of attribute being searched within the
                * groups fields
                * @param {array} searchTerms - the values to be searched for within the
                * groups fields
                * @return {bool} - indicates if the groups fields contain any of the searched terms
                */
            checkGroupForAttributes(block, attributeType, searchTerms) {
                const fields = block.groups.reduce((accumFields, group) => {
                    if (group.fields) {
                        return [...accumFields, ...group.fields];
                    }
                    return accumFields;
                }, []);

                return fields.some(field => searchTerms.includes(field[attributeType]));
            },

            isBeneficiaryBlock(block) {
                return this.checkGroupForAttributes(block, 'fieldUIType', ['CHILDGRID'])
                    || this.checkGroupForAttributes(block, 'name', ['RECEIVCOMPNAME', 'INDVNAME', 'CHECKNUM', 'SECOND_BANKCODE', 'CHECKNUMBER']);
            },

            pageBlocks() {
                let output = '';
                const that = helpers;

                if (view.useBeneWidget) {
                    // Expose the templateHelpers to the beneWidget because it needs to
                    // render child MDF.
                    // TODO: Now that beneWidget is part of the View, can we require
                    // templateHelpers.js in it?
                    view.beneWidget.helpers = helpers;
                }

                util.each(view.blocks, (block) => {
                    let outputRes;

                    // FIXME -- Remove when working adequately since Templates swallow errors
                    try {
                        // TODO: Is there a better way to identify this block?
                        if (view.useBeneWidget && helpers.isBeneficiaryBlock(block)) {
                            view.beneWidget.addBlock(block, view);

                            if (view.options.isModal) {
                                return;
                            }

                            if (view.options.isChild) {
                                outputRes = view.beneWidget.renderForMDF(block, true);
                            } else {
                                // Take over this block with the widget.
                                outputRes = view.beneWidget.renderForMDF(block);
                            }
                        } else {
                            outputRes = that.outputGroups(block.groups);
                        }
                    } catch (e) {
                        window.console.log('Bene Widget Failed', e);
                    }

                    // FIXME: Why pass back content we don't intend to use?
                    //  Is the render doing other work?
                    if (!outputRes.renderBlock) {
                        return;
                    }

                    output += `<fieldset class="${block.classes} section-container" >`;

                    if (block.label) {
                        if (block.collapsible) {
                            const id = util.uniqueId();
                            output += `<div class="panel panel-tertiary${block.labelClasses}"><div class="panel-heading" role="tab" id="collapseHeading-${id}"><legend class="panel-title"><a role="button" data-toggle="collapse" href="#collapseArea${id}" aria-expanded="false" aria-controls="collapseArea${id}" class="collapsed"><span class="indicator-icon"></span> ${block.label}</a></legend></div><div id="collapseArea${id}" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collapseHeading${id}"><div class="panel-body">`;
                        } else {
                            output += `<div class="section-header ${block.labelClasses}"><legend>${block.label}</legend></div>`;
                        }
                    }

                    output += outputRes.blockOutput;

                    if (block.label && block.collapsible) {
                        // close .panel markup
                        output += '</div></div></div>';
                    }

                    output += '</fieldset>';
                });

                return output;
            },

            hasMandatoryField(subgroups) {
                let isRequired = false;
                util.each(subgroups, function (currentGroup) {
                    util.each(currentGroup.fields, (currentField) => {
                        if (currentField.mandatory === true) {
                            isRequired = true;
                        }
                    }, this);
                }, this);
                return isRequired;
            },

            /**
             * Passes in the context object of a model/view and returns the functionCode
             * @param {Object} context of model
             * @returns {String} functionCode
             */
            getFunctionCode(context) {
                if (context === undefined) {
                    return '';
                }
                if (context.actionData !== undefined) {
                    return context.actionData.functionCode;
                } else if (context.actionContext !== undefined) {
                    return context.actionContext.functionCode;
                }
                return context.functionCode;
            },

            outputField(jsonFieldParam) {
                const jsonField = jsonFieldParam;
                if (jsonField) {
                    let fieldHelper;
                    const viewState = view.state;
                    const uiType = jsonField.fieldUIType;
                    let stateHelper = standardHelpers;
                    let hiddenClass = '';

                    // If defined within the cF model, hide it. Alert the view by setting the
                    // model attr to true
                    if (view.conditionalFields && view.conditionalFields.has(jsonField.name)) {
                        hiddenClass = ' hide';
                        view.conditionalFields.trigger('change');
                    }

                    // Fetch the appropriate helpers based on view state.
                    if (viewState === 'view') {
                        stateHelper = viewHelpers;
                    }

                    if ((view.context && view.context.actionMode && view.context.actionMode !== 'reverse' && viewState === 'insert' && jsonField.name === 'REVERSEREASON') || (viewState === 'reverse' && jsonField.name !== 'REVERSESELECTION' && jsonField.name !== 'REVERSEREASON')) {
                        stateHelper = viewHelpers;
                    }

                    let functionCode = this.getFunctionCode(view.options.context);
                    const targetFunctionCode = view.model.get('TARGETFUNCTION');
                    if (!functionCode) {
                        functionCode = targetFunctionCode;
                    }
                    view.functionCode = functionCode;

                    // Add form state so we can include a class for lock icons
                    jsonField.state = view.state;

                    // Check for protected fields.
                    // skip over CREDIT_CURRENCY AND DEBIT_CURRENCY - they are special
                    // conditions and solely depend on the corresponding amount fields
                    if (jsonField.name !== 'CREDIT_CURRENCY' && jsonField.name !== 'DEBIT_CURRENCY') {
                        if (lockedFields.includes(jsonField.name)) {
                            if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL') {
                                // only set protected non template
                                jsonField.protected = true;
                            }
                            // we must always identify it has been locked
                            jsonField.locked = true;
                        } else if (unlockedFields.includes(jsonField.name)) {
                            jsonField.lockedByDefault = false;
                            jsonField.locked = false;
                        } else if (view.model.get('TNUM') || targetFunctionCode) {
                            // we have lockedFields defined, and this field is lockedByDefault,
                            // but it is not set, so toggle lockedByDefault
                            jsonField.lockedByDefault = false;
                            jsonField.locked = false;
                        } else {
                            jsonField.locked = false;
                        }
                    }

                    this.setupCurrencyLockedFields(view, jsonField);

                    if (targetFunctionCode && (targetFunctionCode === 'TMPL' || targetFunctionCode === 'BHTMPL')) {
                        jsonField.isTemplate = true;
                    } else if (targetFunctionCode === undefined && (functionCode === 'TMPL' || functionCode === 'BHTMPL')) {
                        jsonField.isTemplate = true;
                    } else {
                        jsonField.isTemplate = false;
                    }

                    // If the state helpers has a helper
                    // for the current Field UI Type, fetch it.
                    if (Object.prototype.hasOwnProperty.call(stateHelper, uiType)) {
                        fieldHelper = stateHelper[uiType];
                    }

                    // If we found an applicable field helper, execute it.
                    if (fieldHelper) {
                        if (jsonField.blockClass) {
                            jsonField.blockClass += ` field-container${hiddenClass}`;
                        } else {
                            jsonField.blockClass = `field-container${hiddenClass}`;
                        }
                        return fieldHelper(view, jsonField);
                    }
                }
                return undefined;
            },

            outputFields(jFields) {
                let output = '';

                // Iterate over all fields in the JSON body
                // and build am appropriate template for each.
                util.each(jFields, function (currentField) {
                    // Clone the current field so we don't
                    // make changes to the original fields' properties.
                    output += this.outputField(util.clone(currentField));
                }, this);

                return output;
            },

            pageFields() {
                return this.outputFields(view.jsonModel);
            },

            /**
             * @name setupCurrencyLockedFields
             * @description if the incoming field is in the lockedFields list and it is
             * CREDIT_AMOUNT or DEBIT_AMOUNT, adjust the
             * corresponding currency's protected and locked attributes accordingly.
             * @param {object} theViewParam - current view
             * @param {object} jsonField - json field info for the current field being processed
             */
            setupCurrencyLockedFields(theViewParam, jsonField) {
                const theView = theViewParam;
                if (lockedFields.includes(jsonField.name)) {
                    if (jsonField.name === 'CREDIT_AMOUNT' && theView.model.fieldData.CREDIT_CURRENCY && theView.model.fieldData.CREDIT_AMOUNT) {
                        theView.model.fieldData.CREDIT_CURRENCY.protected =
                            theView.model.fieldData.CREDIT_AMOUNT.protected;
                        theView.model.fieldData.CREDIT_CURRENCY.locked =
                            theView.model.fieldData.CREDIT_AMOUNT.locked;
                    } else if (jsonField.name === 'DEBIT_AMOUNT' && theView.model.fieldData.DEBIT_CURRENCY && theView.model.fieldData.DEBIT_CURRENCY) {
                        theView.model.fieldData.DEBIT_CURRENCY.protected =
                            theView.model.fieldData.DEBIT_AMOUNT.protected;
                        theView.model.fieldData.DEBIT_CURRENCY.locked =
                            theView.model.fieldData.DEBIT_AMOUNT.locked;
                    }
                }
            },

            buttonList() {
                return view.hideButtons !== true;
            },
        };

        return helpers;
    },
};
