import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import CollectionView from '@glu/core/src/collectionView';
import configParam from 'system/webseries/models/configurationParameters';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import systemConfig from 'system/configuration';
import GroupView from './groupView';
import adminTmpl from './admin.hbs';
import utils from './permissionUtils';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default Layout.extend({
    template: adminTmpl,

    ui: {
        $selectAll: '.select-all-admin',
    },

    events: {
        'click @ui.$selectAll': 'selectAll',
    },

    initialize(opts) {
        this.collection = this.model.get('groups');
        this.mainModel = opts.mainModel;
        this.contactTypesPromise = opts.contactTypesPromise;
        const appConfigValue = appConfigParams.getValue('IMPORT', 'ALLOWIMPORTEDFILEDOWNLOAD');
        const isEnabledForAdmin = appConfigValue === 'BOTH_ADMIN_AND_CLIENT' || appConfigValue === 'ADMIN_ONLY';
        const isEnabledForClient = appConfigValue === 'BOTH_ADMIN_AND_CLIENT' || appConfigValue === 'CLIENT_ONLY';
        if (systemConfig.isAdmin()) {
            this.showImportHistoryDownloadCheckbox = isEnabledForAdmin;
        } else {
            this.showImportHistoryDownloadCheckbox = isEnabledForClient;
        }

        if (opts.mode === constants.MODES.VIEW) {
            this.collection.reset(this.collection.filter(group => group.isEntitled()));
        }
        this.boundUpdateSelectAllState = () => util.defer(() => this.updateSelectAllState());
    },

    disableEntitlements(type, action) {
        const isUserType = type === '_USER';
        const isRoleType = type === '_ROLE';

        let disabled = isUserType && configParam.get(`_USER.actions.${action}.disabled`) === 'true';
        if (!disabled && utils.isBankMaintained(this.options)) {
            if (this.infoModel.get('BANKMAINTAINED')) { // checkbox on
                disabled = false;
            } else {
                // checkbox is off or client
                disabled = (isUserType || isRoleType);
            }
        }
        return disabled;
    },

    selectAll(evt) {
        const { checked } = evt.currentTarget;

        this.collection.each((group) => {
            group.get('types').each((type) => {
                const entitlement = type.get('entitlements').at(0);
                const isUserType = entitlement.get('typeCode') === '_USER';

                const actions = Object
                    .keys(entitlement.get('actions'))
                    .reduce((arr, action) => {
                        const isEntitlementDisabled = this.disableEntitlements(entitlement.get('typeCode'), action);

                        return (arr[action].applicable && !isEntitlementDisabled)
                            ? Object.assign(arr, {
                                [action]: Object.assign(arr[action], {
                                    entitled: checked,
                                }),
                            }) : arr;
                    }, JSON.parse(JSON.stringify(entitlement.get('actions'))));

                const restrictions = Object
                    .keys(entitlement.get('restrictions'))
                    .reduce((arr, restriction) => {
                        const isEntitlementDisabled = isUserType && configParam.get(`_USER.restrictions.${restriction}.disabled`) === 'true';

                        return (arr[restriction].applicable && !isEntitlementDisabled)
                            ? Object.assign(arr, {
                                [restriction]: Object.assign(arr[restriction], {
                                    entitled: checked,
                                    value: checked,
                                }),
                            }) : arr;
                    }, JSON.parse(JSON.stringify(entitlement.get('restrictions'))));

                entitlement.set({
                    actions,
                    restrictions,
                });
            });
        });

        if (this.mainModel.contactTypes) {
            this.mainModel.contactTypes.each((model) => {
                model.trigger('selectAll', checked);
            }, this);
        }
        this.onChildrenChange();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.groupsCollectionView = new CollectionView({
                collection: this.collection,
                itemView: GroupView,
            });

            this.infoModel = this.model.roleModel || this.model.userModel;
            let userGroup = this.infoModel && this.infoModel.get('USERGROUP');
            if (!userGroup) {
                userGroup = this.model.userGroupModel && this.model.userGroupModel.get('parentUserGroup');
            }

            const options = {
                userGroup,
                mainModel: this.mainModel,
                mode: this.options.mode,
            };
            if (this.infoModel.get('USERID')) {
                options.userId = this.infoModel.get('USERID');
            }
            if (this.infoModel.get('ROLEID')) {
                options.roleId = this.infoModel.get('ROLEID');
            }
            options.isUce = this.options.isUce;
            options.bankMaintained = this.infoModel.get('BANKMAINTAINED');
            this.groupsCollectionView.itemViewOptions = options;

            this.groupRegion.show(this.groupsCollectionView);
        } else {
            this.loadRequiredData();
        }
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    onChildrenChange() {
        const checkboxList = this.groupRegion?.$el?.find('input[type="checkbox"]');
        onChildrenChange(this, checkboxList);
    },

    updateSelectAllState() {
        const checkboxList = this.groupRegion?.$el?.find('.admin-group input[type="checkbox"]');
        updateSelectAllState(this, checkboxList);
    },

    loadRequiredData() {
        const self = this;
        this.contactTypesPromise.then(() => {
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === constants.MODES.VIEW,
            showImportHistoryDownloadCheckbox: this.showImportHistoryDownloadCheckbox && this.model.get('globalSettings')?.HISTORY?.applicable,
        };
    },

    extractContactTypes() {
        const self = this;

        // Filter the contacts that are checked
        return this.mainModel.contactTypes.chain()
            .filter(contact => contact.get('CHECKED'))
        // Map each member object
            .map(contact => ({
                value1: contact.get('CONTACTTYPE'),
                value2: null,
                value3: null,
            }))
        // iterate over the members and add each one to the data entitlement object
            .reduce((obj, member) => {
                // first time obj is empty so create one and return it
                if (util.isEmpty(obj)) {
                    return {
                        productCode: '_ADMIN',
                        mixedChangeSet: false,
                        exclusiveSet: false,
                        currentFuture: false,
                        group: false,
                        typeCode: '*',
                        dataEntAttr: self.mainModel.contactTypes.first().get('DATAENTATTR'),
                        members: [member],
                    };
                }
                // work on the previously created data entitlement object and add a member.
                obj.members.push(member);
                return obj;
            }, {})
            .value();
    },

    beforeSave() {
        let contactTypes;
        if (this.mainModel.contactTypes) {
            contactTypes = this.extractContactTypes();
            if (!util.isEmpty(contactTypes)) {
                this.mainModel.get('dataEntitlements').push(contactTypes);
            }
        }

        return Promise.resolve();
    },
});
