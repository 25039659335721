import util from '@glu/core/src/util';
import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import constants from 'common/dynamicPages/api/constants';
import userInfo from 'etc/userInfo';
import IssueVoidModel from '../models/issueVoid';
import Accounts from '../collections/accounts';
import viewVoidTmpl from './viewVoid.hbs';

const ViewVoid = Layout.extend({
    template: viewVoidTmpl,
    className: 'xfer-view',

    ui: {
        fromCombo: '[data-hook="fromCombo"]',
        dateField: '[name="ISSUEVOIDDATE"]',
        amtField: '[name="AMOUNT"]',
        optVoidRow: '.void-opt-fields',
        optVoidRowStatic: '.void-opt-fields-static',
        currencyText: '.input-group-addon',
        clearBtn: '[data-hook="clear-fields"]',
        xBtn: '[data-action="removeItem"]',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    events: {
        'change @ui.fromCombo': 'fromChanged',
        'click [data-hook="clear-fields"]': 'removeItem',
    },

    initialize(opts) {
        this.model = this.model || new IssueVoidModel({
            ISSUEVOIDFLAG: 'VOID',
        });

        if (this.model.collection) {
            this.listenTo(
                this.model.collection,
                {
                    'toggle-optional-fields': this.toggleVoidOpt,
                    'toggle-clear-remove': this.toggleClearRemove,
                },
            );
        }

        this.fromAccounts = new Accounts({
            type: opts.type,
        });

        this.processingDates = {
            blockedDates: [],
            processingDays: [],
            daysForward: [],
            cutOffTimes: [],
            showCalendarIcon: true,
            showDropdowns: true,
        };

        this.parentLayout = opts.parentLayout;
    },

    onRender() {
        if (!this.hideAmount) {
            const maxLength = this.model.fieldInfo.find(field => field.name === 'AMOUNT').maxLen - 1;
            const decimalPlaces = userInfo.getDecimalPlaces();
            this.ui.amtField.inputmask('decimal', util.extend(
                Formatter.getCurrencyMaskOptions(true, true),
                {
                    allowMinus: false,
                    placeholder: '0.00',
                    integerDigits: maxLength - decimalPlaces,
                    digitsOptional: false,
                },
            ));
        }
        const dateOptions = {
            blockedDates: this.processingDates.blockedDates,
            processingDays: this.processingDates.processingDays,
            cutOffTimes: this.processingDates.cutOffTimes,
            allowWeekends: true,
            showCalendarIcon: true,
            showDropdowns: true,
            daysForward: this.processingDates.daysForward[0],
        };

        this.ui.dateField.nhDatePicker(dateOptions);

        this.ui.fromCombo.comboBox({
            query: util.debounce((query) => {
                const rowsPerPage = constants.MAX_SERVER_ROWSPERPAGE;
                const startRow = (((query.page - 1) * rowsPerPage) + 1);

                this.fromAccounts.setFilter(query.term, this.filterVal);
                this.fromAccounts.fetch({
                    startRow,

                    success(results) {
                        query.callback({
                            results: results.toJSON(),
                            more: ((query.page * rowsPerPage) < results.totalRows),
                        });
                    },
                });
            }, constants.COMBO_DEBOUNCE),

            initSelection: (element, callback) => {
                const val = element.val();
                const { fromAccount } = this.model;
                let text = '';

                if (this.model.fromAccount) {
                    const labelText = `${fromAccount.get('mapDataList').ACCOUNT_TITLE}  - ${this.model.get('ACCOUNT_NUMBER')} - ${fromAccount.get('mapDataList').BANKCODE} - ${fromAccount.get('mapDataList').BANKNAME} - ${fromAccount.get('CURRENCYCODE')}`;
                    text = util.isEmpty(this.model.get('ACCOUNTNUM_NAME')) ? labelText : this.model.get('ACCOUNTNUM_NAME');
                    this.ui.currencyText.removeClass('hide').text(this.model.fromAccount.get('CURRENCYCODE'));
                }

                const itemData = (text ? {
                    id: val,
                    text,
                } : undefined);

                callback(itemData);
            },
        });

        this.toggleVoidOpt(false);
    },

    fromChanged(e) {
        this.filterVal = e.val;

        if (this.fromAccounts.get(this.filterVal)) {
            // update our model
            this.model.set('CURRENCYCODE', this.fromAccounts.get(this.filterVal).get('mapDataList').CURRENCYCODE);
            this.model.set(
                'ACCOUNTNUM_NAME',
                this.fromAccounts.get(this.filterVal).get('ACCOUNTNUM_NAME'),
                {
                    silent: true,
                },
            );
            this.model.fromAccount = this.fromAccounts.get(this.filterVal);
            this.ui.currencyText.removeClass('hide').text(this.model.fromAccount.get('CURRENCYCODE'));
        }
        this.parentLayout.accountFilter = e.val;
    },

    updateAccountNumber() {
        this.ui.fromCombo.select2('val', this.model.get('ACCOUNT_NUMBER'));
    },

    toggleVoidOpt(flag) {
        this.ui.optVoidRow.toggle(flag);
        this.ui.optVoidRowStatic.toggle(!flag);

        // show only static fields with values
        this.ui.optVoidRowStatic.find('p').each((_, el) => {
            const $dd = this.$(el);
            $dd.parent().toggle($dd.text().length > 0);
        });
    },

    toggleClearRemove(flag) {
        if (flag) {
            this.ui.clearBtn.addClass('hide');
            this.ui.xBtn.removeClass('hide');
        } else {
            this.ui.xBtn.addClass('hide');
            this.ui.clearBtn.removeClass('hide');
        }
    },

    removeItem() {
        this.model.destroy();
    },

    onClose() {
        this.ui.fromCombo.comboBox('destroy');
    },

    templateHelpers() {
        const self = this;
        return {
            cid: this.model.cid,

            isFieldMandatory(fieldName) {
                const field = util.findWhere(
                    self.model.fieldInfo,
                    {
                        name: fieldName,
                    },
                );
                return field.mandatory && field.visible;
            },

            isFieldOptional(fieldName) {
                const field = util.findWhere(
                    self.model.fieldInfo,
                    {
                        name: fieldName,
                    },
                );
                return !field.mandatory && field.visible;
            },
        };
    },
});

export default ViewVoid;
