import CompositeView from 'no-override!@glu/core/src/compositeView';
import Marionette from 'backbone.marionette';
import notificationManager from 'system/notifications/manager';

export default CompositeView.extend({
    constructor(...args) {
        this._notificationsInterface = notificationManager.notificationFunctions();

        CompositeView.apply(this, args);
    },

    getTemplate() {
        if (this.loadingTemplate && this.hasLoadedRequiredData() === false) {
            return this.loadingTemplate;
        } else {
            return Marionette.getOption(this, 'template');
        }
    },

    onBeforeRender() {
        this._notificationsInterface.open(this._notificationData);
    },

    onBeforeClose() {
        this._notificationsInterface.close(this._notificationData);
    },

    setHasLoadedRequiredData(bool) {
        this.hasLoaded = bool;
    },

    hasLoadedRequiredData() {
        return (this.hasLoaded ? this.hasLoaded : false);
    },

    setNotificationData(data) {
        this._notificationData = data;
    },

    notifyPageLoaded() {
        this._notificationsInterface.loaded(this._notificationData);
    },

    /**
     * There are times when a view is shown and depending on if the view is switched away
     * from quickly, it can cause executing code to throw TypeError's as Promises are resolved
     * and callbacks invoked. This utility returns the proper function to be used based on if
     * a view `isClosed` (view property) before allowing things to continue.
     * @param {function} openMethod - Function to perform if the view is open.
     * @param {function} [closedMethod] - Optional. Used for possible cleanup.
     */
    ifNotClosed(openMethod, closedMethod = () => { }) {
        return (...args) => {
            const method = this.isClosed ? closedMethod : openMethod;
            return method.apply(this, args);
        };
    },
});
