import Layout from '@glu/core/src/layout';
import PageApi from 'common/dynamicPages/api/view';
import constants from 'app/administration/constants';
import store from 'system/utilities/cache';
import ContextModel from 'common/dynamicPages/models/context';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import template from './profile.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    initialize(options) {
        this.options = options;
        this.mode = options.mode || constants.MODES.VIEW;

        this.contextDef = this.options.contextDef;

        this.contextModel = new ContextModel({
            menuCategory: this.options.menuCategory,
            serviceName: this.options.serviceName,
            serviceFunc: this.options.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();

        this.mode = this.options.mode || constants.MODES.VIEW;
        this.viewType = this.options.viewType || 'default';
    },

    onRender() {
        const { mode } = this.options;
        if (this.hasLoadedRequiredData()) {
            this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
            this.pageContent.show(this.pageView);
        } else {
            // prepopulate with user data if not in 'INSERT' mode
            this.loadUserData((mode !== 'insert'));
            this.pageView.userCompany = store.get('userCompany');
        }
    },

    loadUserData(prepopulate) {
        const options = {
            context: this.contextDef,
            hideButtons: true,
            state: this.mode,
            entitlements: this.options.entitlements,
        };

        if (prepopulate) {
            options.model = this.model;
        }

        this.pageView = PageApi.page.get(options);

        this.pageView.userGroupModel = this.model.userGroupModel;

        this.model = this.pageView.model;
        this.storedModel = this.model;
        this.setHasLoadedRequiredData(true);
        this.render();
    },
});
