import Glu from '@glu/core/src/glu';
import userInfo from 'etc/userInfo';
import Dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import LoanPaymentLayout from 'app/smbPayments/views/accounts/balances/transfer/loanPaymentLayout';
import LoanDrawLayout from 'app/smbPayments/views/accounts/balances/transfer/loanDrawLayout';
import TransferLayout from 'app/smbPayments/views/accounts/balances/transfer/transferLayout';
import accountUtil from 'app/smbPayments/util/accountUtil';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import locale from '@glu/locale';
import Account from 'app/balanceAndTransaction/models/account';
import ItemView from '@glu/core/src/itemView';
import constants from 'app/balanceAndTransaction/constants';
import template from './listView.hbs';

export default ItemView.extend({
    tagName: 'ul',
    className: 'list-row',
    template,

    initialize(options) {
        this.loansPrefs = options.loansPrefs;
        store.set('btr:fromInitialContainer', true);
    },

    ui: {
        viewDetails: '.view-details',
    },

    addTransfer() {
        const accountType = this.model.get('accountType');
        if (accountUtil.isLoanPayment(accountType)) {
            if (this.model.get('canLoanPay')) {
                Dialog.open(new LoanPaymentLayout({
                    draggedModel: this.model,
                    preferences: this.loansPrefs,
                }));
            }
        } else if (accountUtil.isLoanDraw(accountType)) {
            if (this.model.get('canLoanDraw')) {
                Dialog.open(new LoanDrawLayout({
                    draggedModel: this.model,
                    preferences: this.loansPrefs,
                }));
            }
        } else if (this.model.get('canTransfer')) {
            Dialog.open(new TransferLayout({
                draggedModel: this.model,
            }));
        }
    },

    handleViewDetails() {
        const account = new Account({
            accountFilter: this.model.get('accountFilter'),
            accountName: this.model.get('accountName'),
            accountType: this.model.get('accountType'),
            clientAccountName: this.model.get('clientAccountName'),
            accountNumber: this.model.get('accountNumber'),
            isRealTime: this.model.get('isRealTimeAccount'),
            returnRoute: Glu.Backbone.history.getFragment(),
            currencyCode: this.model.get('currency'),
            isTransferLinkEntitled: this.model.get('ACCT_ENTLD_TRANSFER') > 0 || this.model.get('ACCT_ENTLD_LOANDRAW') > 0 || this.model.get('ACCT_ENTLD_LOANPAY') > 0,
            tabId: constants.ACCOUNT_SUMMARY.TABS.ALL,
            sectionId: this.model.get('sectionId'),
        });
        const balanceAndTransactionAccount = store.get('balanceAndTransactionAccount');
        if (balanceAndTransactionAccount) {
            account.set({
                returnRoute: balanceAndTransactionAccount.get('returnRoute'),
            });
        }
        store.set('balanceAndTransactionAccount', account);
        const route = accountUtil.getViewDetailRoute(
            this.model.get('accountType'),
            this.model.get('isTieredLoan'),
        );
        this.navigateTo(route);
    },

    /**
     * Get the label for the balance column
     * @param {string} accountType
     * @returns {string}
     */
    getBalanceLabel(accountType) {
        const localeKey = accountUtil.getAvailableBalanceField(accountType);
        return locale.get(`GIR.${localeKey}`);
    },

    templateHelpers() {
        const accountType = this.model.get('accountType');
        return {
            balanceLabel: this.getBalanceLabel(accountType),
            showBalance: applicationConfigParams.getValue('OFXEXP', 'DISPLAY_BALANCE') === '1',
            amountTrajectory: this.model.getTrajectories().trajectoryAmount,
            balanceUnchanged: this.model.getTrajectories().balanceUnchanged,
            balanceUp: this.model.getTrajectories().balanceUp,
            preferredFormat: userInfo.getCurrencyFormat(),
            showTransfer: accountUtil.showTransfer(this.model),
        };
    },
});
