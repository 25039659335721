import 'tinymce/tinymce';
import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import WidgetEditView from 'app/administration/views/cxp/externalWidget/widgetEdit';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import editContentTmpl from './editContent.hbs';

const SegmentCollection = Collection.extend({
    sync(method, collection, options) {
        const url = services.generateUrl('/segmentation/getListView');
        const data = {
            rowsPerPage: 2147483647,
            startRow: 0,
        };

        http.post(url, data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    parse(response) {
        const returnArray = [];

        util.each(response.rows, (segment) => {
            const obj = {
                id: segment.columns[1].fieldValue,
                name: segment.columns[1].fieldValue,
            };

            returnArray.push(obj);
        });

        return returnArray;
    },
});

export default Layout.extend({
    template: editContentTmpl,
    loadingTemplate: loadingPageTmpl,

    initialize(options) {
        this.widgetId = options.widgetId;
        this.segments = new SegmentCollection();
    },

    ui: {
        $marketSegment: '[data-hook="getMarketSegment"]',
        $widgetSaveButton: '[data-hook="getWidgetSaveButton"]',
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        }
    },

    templateHelpers() {
        const self = this;

        return {
            segments() {
                return self.segments.toJSON();
            },
        };
    },

    loadRequiredData() {
        const self = this;

        this.segments.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    load() {
        const self = this;

        // remove all tinymce editors
        window.tinymce.remove();

        this.ui.$widgetSaveButton.attr('disabled', true);

        this.editView = new WidgetEditView({
            widgetId: this.widgetId,
            segment: this.ui.$marketSegment.val(),
        });

        this.listenToOnce(this.editView, 'readyForEdits', () => {
            self.ui.$widgetSaveButton.attr('disabled', false);
        });

        this.widgetDisplayRegion.show(this.editView);
    },

    save() {
        this.editView.saveWidgetContent();
    },

    cancel() {
        this.navigateTo('/CXP/externalWidget');
    },
});
