import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import classnames from 'classnames';

import styles from './PanelWrapper.styles';

const PanelWrapper = ({
  classes,
  title,
  children,
  additionalTitleContent,
  contentClassName,
  footer
}) => (
  <div className={classes.root} data-qa="mobilegrid-drawer">
    <div className={classes.titleBar}>
      <h2 className={classes.title}>{title}</h2>
      {additionalTitleContent}
    </div>
    <div className={classnames(classes.content, contentClassName)}>
      {children}
    </div>
    <div className={classes.footer}>
      {footer}
    </div>
  </div>
);

PanelWrapper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  additionalTitleContent: PropTypes.node,
  footer: PropTypes.node,
  contentClassName: PropTypes.string
};

PanelWrapper.defaultProps = {
  additionalTitleContent: null,
  footer: null,
  contentClassName: undefined
};

export default withStyles(styles)(PanelWrapper);
