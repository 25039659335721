import React from 'react';
import PropTypes from 'prop-types';
import useLocale from 'hooks/useLocale';
import PageHeader from 'components/PageHeader/PageHeader';
import { AlertContextProvider } from './AlertContext';
import {
    MODE,
    CONTENT_TYPE_ID,
} from '../constants';
import Alert from '../Alert/Alert';
import AlertReadOnly from '../AlertReadOnly/AlertReadOnly';

const AlertWrapper = ({
    mode,
    returnRoute,
    modelData,
    modelActions,
    ...props
}) => {
    const { getLocaleString } = useLocale();
    const Component = mode === MODE.VIEW
        ? AlertReadOnly : Alert;
    const contentId = modelData.CONTENTTYPEID === CONTENT_TYPE_ID.BANK_DEFINED
        ? `(${modelData.ALERTCONTENTID})`
        : '';
    return (
        <>
            {mode === MODE.VIEW ? (
                <PageHeader
                    headingText={`${getLocaleString('CMAINT.View.Alert.Content')} ${contentId}`}
                    returnRoute={returnRoute}
                />
            ) : (
                <PageHeader
                    headingText={getLocaleString('CMAINT.Customize.Alert.Content')}
                />
            )}
            <AlertContextProvider
                providerState={{
                    mode,
                    modelData,
                    modelActions,
                }}
            >
                <Component
                    returnRoute={returnRoute}
                    {...props}
                />
            </AlertContextProvider>
        </>
    );
};

AlertWrapper.propTypes = {
    mode: PropTypes.string,
    modelData: PropTypes.shape({
        ALERTCONTENTID: PropTypes.string,
        CONTENTTYPEID: PropTypes.string,
    }),
    modelActions: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    returnRoute: PropTypes.string.isRequired,
};
AlertWrapper.defaultProps = {
    mode: MODE.MODIFY,
    modelData: {},
    modelActions: [],
};
export default AlertWrapper;
