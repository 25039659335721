export default {
    dropdownPicker: {
        margin: '20px',
    },
    pickerRow: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    cancel: {
        marginLeft: 10,
    },
};
