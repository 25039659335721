import Model from '@glu/core/src/model';
import services from 'services';
import locale from '@glu/locale';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import Formatter from 'system/utilities/format';
import httpError from 'common/util/httpErrorResult';
import markdownConverter from 'common/util/markdownConverter';

export default Model.extend({
    idAttribute: 'TNUM',

    initialize(options) {
        this.baseServicePath = options && options.serviceName ? options.serviceName : 'bannerNotification';
        this.mode = options.mode;
        this.readService = this.setReadService(options.gridModel);
        this.notificationType = options.notificationType;
        this.validators = {
            SUBJECT: {
                description: locale.get('common.subject'),
                exists: true,
            },
            TEXT: {
                description: locale.get('common.message'),
                exists: true,
            },
        };

        if (this.notificationType === 'notification') {
            this.validators = util.extend(this.validators, {
                START_DATE: {
                    description: locale.get('common.startDate'),
                    exists: true,
                },
                START_TIME: {
                    description: locale.get('common.startTime'),
                    exists: true,
                },
            });
            this.addEndDateValidation();
        }
    },

    /**
     * @method addEndDateValidation
     * @description add validation for END_DATE
     */
    addEndDateValidation() {
        // TODO when object spread operator is supported use that instead of util.extend
        this.validators = util.extend(this.validators, {
            END_DATE: {
                description: locale.get('common.endDate'),
                exists: true,
            },
            END_TIME: {
                description: locale.get('common.endTime'),
                exists: true,
            },
        });
    },

    /**
     * @method removeEndDateValidation
     * @description removes the end date validators
     */
    removeEndDateValidation() {
        if (Object.prototype.hasOwnProperty.call(this.validators, 'END_DATE')) {
            delete this.validators.END_DATE;
        }
        if (Object.prototype.hasOwnProperty.call(this.validators, 'END_TIME')) {
            delete this.validators.END_TIME;
        }
    },

    sync(method, model, options) {
        let payload;
        let service;

        model.unset('serviceName');
        model.unset('gridModel');

        switch (method) {
        case 'create':
            payload = this.convertModelAttributesToServerJSON(model);
            service = services.generateUrl(`${this.baseServicePath}/add`);
            break;
        case 'read':
            payload = this.convertModelAttributesToServerJSON(model, method, true);
            service = services.generateUrl(`${this.isCopyExistingTemplate ? 'bannerNotificationTemplate' : this.readService}/read`);
            break;
        case 'update':
            payload = this.convertModelAttributesToServerJSON(model);
            service = services.generateUrl(`${this.baseServicePath}/modify`);
            break;
        case 'delete':
            payload = this.convertModelAttributesToServerJSON(model);
            service = services.generateUrl(`${this.baseServicePath}/delete`);
            break;
        case 'archive':
            payload = this.convertModelAttributesToServerJSON(model);
            service = services.generateUrl(`${this.baseServicePath}/archive`);
            break;
        case 'template':
            payload = {
                requestHeader: {},
                inquiryRequest: {
                    searchCriteria: {
                        searchType: '5',
                        inquiryId: 45000,
                    },
                    startRow: 1,
                    rowsPerPage: 250,
                },
            };
            service = services.generateUrl('inquiry/getData');
            break;
        default:
            break;
        }

        http.post(service, payload, (result) => {
            let resultParam = result;
            if (method === 'delete' || method === 'archive') {
                this.message = resultParam.message;
            } else if (method === 'read' && result?.item) {
                let index = result.item?.findIndex(i => i.name === 'SUBJECT');
                let value = result.item?.[index]?.value;
                resultParam.item[index].value = this.unescapeAmpersandQuote(value);
                index = result.item?.findIndex(i => i.name === 'TEXT');
                value = result.item?.[index]?.value;
                resultParam.item[index].value = this.unescapeAmpersandQuote(value);
            } else if ((method === 'create' || method === 'update') && result?.message?.[0]) {
                resultParam.message[0] = this.unescapeAmpersandQuote(result.message[0]);
            }
            resultParam = (method === 'template') ? resultParam.inquiryResponse.rows : resultParam;
            options.success(resultParam);
        }, httpError.getResult(options, model));
    },

    /**
     * @name unescapeAmpersandQuote
     * @description the response from the server contains the escaped values for & and "
     * this function converts &amp; to & and &quot; to " in the input string
     * @param {string} inValue
     * @returns unescaped string, &amp; -> &  &quot; -> "
     */
    unescapeAmpersandQuote(inValue) {
        if (!inValue) {
            return inValue;
        }
        return inValue.replace(/&amp;/g, '&').replace(/&quot;/g, '"');
    },

    fetchTemplates(options) {
        this.sync('template', this, options);
    },

    archive(options) {
        this.sync('archive', this, options);
    },

    /**
     * @method setReadService
     * @param gridModel
     * @returns {string|*}
     */
    setReadService(gridModel) {
        let service = this.baseServicePath;
        if (gridModel && gridModel.action.toUpperCase() === 'NTEMPLATE') {
            /**
             * Copy message from template and copy message from message use the same action
             * code, check the flag
             */
            service = gridModel.isCopyFromMessage ? 'bannerNotification' : 'bannerNotificationTemplate';
        } else if (gridModel && gridModel.action.toUpperCase() === 'TNOTIFICATION') {
            service = 'bannerNotification';
        }
        return service;
    },

    parse(result) {
        return result.item ? this.convertServerJSONToModelAttributes(result.item) : result;
    },

    convertModelAttributesToServerJSON(model) {
        let jsonData = [];
        util.each(model.toJSON(), (value, key) => {
            if (key === 'FEATURES' || key === 'POPULATION') {
                jsonData = jsonData.concat(value);
            } else if (key === 'allFeatures' && value) {
                jsonData.push({
                    name: 'FEATURE_ID',
                    value: '*~*~*~*',
                });
                this.unset('allFeatures');
            } else if (key === 'SHARED') {
                if (util.isUndefined(value) || value === '') {
                    jsonData.push({
                        name: key,
                        value: 0,
                    });
                } else {
                    jsonData.push({
                        name: key,
                        value,
                    });
                }
            } else if (key === 'START_DATE') {
                jsonData.push({
                    name: key,
                    value: this.getDateTime(value, model.get('START_TIME')),
                });
            } else if (key === 'END_DATE') {
                jsonData.push({
                    name: key,
                    value: (model.get('NOENDDATE') === '1') ? '' : this.getDateTime(value, model.get('END_TIME')),
                });
            } else if (key !== 'id') {
                jsonData.push({
                    name: key,
                    value,
                });
            }
        }, this);

        const markdown = util.findWhere(jsonData, {
            name: 'TEXT',
        });

        if (markdown) {
            markdown.value = markdownConverter.encodeMarkdown(markdown.value);
        }

        return {
            item: jsonData,
        };
    },

    convertServerJSONToModelAttributes(data) {
        const parsedData = {
            POPULATION: [],
        };

        this.features = [];
        this.population = [];

        data.forEach((datum) => {
            if (datum.name === 'FEATURE_ID' && datum.value !== '*~*~*~*') {
                this.features.push(datum.value);
            } else if (datum.name === 'POPULATION') {
                // store the population id for the flexDropdown
                this.population.push(datum.value);
                // set the nvp of all population items. Is concatenated on submit
                parsedData[datum.name].push({
                    name: datum.name,
                    value: datum.value,
                });
            } else if (datum.name === 'START_DATE' || datum.name === 'END_DATE') {
                parsedData[datum.name] = this.getDateValue(datum.value);
                parsedData[this.getTimeFieldName(datum.name)] = this.formatTime(datum.value);
            } else if (datum.name === 'NOENDDATE') {
                parsedData[datum.name] = util.isEmpty(datum.value) ? '0' : datum.value;
            } else {
                parsedData[datum.name] = datum.value;
            }
        });

        return parsedData;
    },

    /**
     * @method getDateValue
     * @description return the date portion of the timestamp
     * @param {string} value
     */
    getDateValue(value) {
        return moment(value).format(userInfo.getDateFormat());
    },

    /**
     * @method getTimeFieldName
     * @description returns the date's corresponding time field name
     * @param fieldName
     * @returns {string}
     */
    getTimeFieldName(fieldName) {
        return `${fieldName.substring(0, fieldName.indexOf('_'))}_TIME`;
    },

    /**
     * @method getDateTime
     * @description formats the date and time as a timestamp string
     * @param {string} dateString
     * @param {string} timeString
     * @returns {string} formatted date time string
     */
    getDateTime(dateString, timeString) {
        const format = `${userInfo.getDateFormat()} ${userInfo.getTimeFormat()}`;
        return moment(`${dateString} ${timeString}`, format).format(format);
    },

    formatTime(time) {
        return Formatter.formatTime(time);
    },
});
