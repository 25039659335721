import Layout from '@glu/core/src/layout';
import exportCSVViewTmpl from 'app/reports/views/exportBTRData/cvs/exportBTRCSVDetail.hbs';
import outputContentOptions from 'app/reports/views/exportBTRData/common/outputContentOptions';
import serverConfigParams from 'system/webseries/models/configurationParameters';

const OUTPUTCONTENT = 'OUTPUTCONTENT';

export default Layout.extend({
    template: exportCSVViewTmpl,

    ui: {
        $outputContent: '[data-hook="getOutputContentInput"]',
        $date: '[data-hook="getDateInput"]',
        $conditionalField: '[data-hook="getExportConditionalField"]',
        $continuationAccountSummaryRecord: '[data-hook="getContinuationAccountSummaryRecord"]',
        $outputContentDropdown: '[data-hook="getOutputContentDropdown"]',
    },

    initialize(options) {
        this.noTransactions = options.noTransactions;
        this.dateOptions = Object.assign(options.dateOptions || {}, {
            pastOnly: false,
        });
    },

    onRender() {
        this.renderOutputContentDropdown();
        this.toggleContinuationAccountSummaryRecord(this.model.get('OUTPUTCONTENT'));
    },

    /**
     * @param {boolean} noTransactions - flag to not include transaction options
     * based on user permissions on current page
     * @return {array}
     */
    getOutputOptions(noTransactions) {
        return outputContentOptions(noTransactions);
    },

    /**
     * @method renderOutputContentDropdown
     * Renders OUTPUTCONTENT dropdown
     * field is specific to CVS exporting
     */
    renderOutputContentDropdown() {
        const options = outputContentOptions(this.noTransactions);
        const [preselected] = options;
        const opts = {
            data: options,
            initSelection: (element, callback) => {
                callback(options[0]);
            },
        };
        this.outputContentDropdown = this.ui.$outputContentDropdown.comboBox(opts);
        this.outputContentDropdown.on('change', (e) => {
            this.model.set(OUTPUTCONTENT, e.val);
            this.toggleContinuationAccountSummaryRecord(e.val);
        });
        // Preset model with preselected value
        this.model.set(
            OUTPUTCONTENT,
            preselected.id,
            {
                silent: true,
            },
        );
    },

    /**
     * Conditionally show the separate transactions details checkbox
     * @param {string} outputContent
     */
    toggleContinuationAccountSummaryRecord(outputContent) {
        const validOutput = ['GIRBALANDTRANS', 'GIRTRANS'];
        const toggleVal = serverConfigParams.get('SeparateTransDetailsMultiColumns') === 'true'
            && validOutput.includes(outputContent);
        this.ui.$continuationAccountSummaryRecord.toggleClass('hide', !toggleVal);
    },
});
