var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"prev-page-breadcrumb\">\n                <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.back.to.file.import",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":152},"end":{"line":4,"column":192}}}))
    + "</span></a>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                    <div class=\"col-md-3\">\n                                                                            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.unapprovedon",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":83},"end":{"line":37,"column":116}}}))
    + "</label>\n                                                                                 <p class=\"form-control-static\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"UNAPPROVEDON") : stack1), depth0))
    + "</p>\n                                                                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                    <div class=\"col-md-3\">\n                                                                            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.approvedon",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":83},"end":{"line":43,"column":114}}}))
    + "</label>\n                                                                                 <p class=\"form-control-static\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"APPROVEDON") : stack1), depth0))
    + "</p>\n                                                                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                    <div class=\"col-md-3\">\n                                                                            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.rejectreason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":83},"end":{"line":49,"column":116}}}))
    + "</label>\n                                                                                 <p class=\"form-control-static\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"REJECTION_REASON") : stack1), depth0))
    + "</p>\n                                                                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                    <div class=\"col-md-6\">\n                                                                            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.error",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":83},"end":{"line":58,"column":109}}}))
    + "</label>\n                                                                                 <p class=\"form-control-static\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"ERROR") : stack1), depth0))
    + "</p>\n                                                                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                    <div class=\"col-md-3\">\n                                                                            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.approverejectreason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":83},"end":{"line":64,"column":123}}}))
    + "</label>\n                                                                                 <p class=\"form-control-static\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"APPROVER_REJECTION_REASON") : stack1), depth0))
    + "</p>\n                                                                    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                    <div class=\"col-md-3\">\n                                                                            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"payment.cutoff",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":70,"column":83},"end":{"line":70,"column":110}}}))
    + "</label>\n                                                                                 <p class=\"form-control-static\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"CUTOFF_TIME") : stack1), depth0))
    + "</p>\n                                                                    </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":20},"end":{"line":178,"column":29}}})) != null ? stack1 : "")
    + "                    <button class=\"btn btn-secondary\" data-action=\"cancel\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":179,"column":75},"end":{"line":179,"column":101}}}))
    + "</button>\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":172,"column":43},"end":{"line":172,"column":55}}}) : helper)))
    + "\"\n                            data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":173,"column":41},"end":{"line":173,"column":54}}}) : helper)))
    + "\"\n                            name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":174,"column":34},"end":{"line":174,"column":47}}}) : helper)))
    + "\"\n                            type=\"button\">\n                            <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":176,"column":46},"end":{"line":176,"column":57}}}) : helper)))
    + "</span>\n                        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNotModal") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.passthrusummary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":67}}}))
    + " ("
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"USERGROUPSEQUENCENUMBER") : stack1), depth0))
    + ")</h1>\n</div>\n<div data-region=\"pageContent\">\n                <fieldset class=\"section section-container\">\n                        <div class=\"section-header \">\n                                <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.paymentsummary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":40},"end":{"line":12,"column":75}}}))
    + "</legend>\n                        </div>\n                        <div class=\"row\">\n                                <div class=\"form-column inline-fields\">\n                                                <div class=\"ui-widget field-container\">\n                                                    <div class=\"row\">\n                                                        <div class=\"form-column\">\n                                                            <div class=\"col-md-3\">\n                                                                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":75},"end":{"line":20,"column":102}}}))
    + "<br></label>\n                                                                         <p class=\"form-control-static\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"STATUS") : stack1), depth0))
    + "<br></p>\n                                                            </div>\n                                                            <div class=\"col-md-3\">\n                                                                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.entrymethod",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":75},"end":{"line":24,"column":107}}}))
    + "</label>\n                                                                         <p class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.imported",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":104},"end":{"line":25,"column":135}}}))
    + "</p>\n                                                            </div>\n                                                        </div>\n                                                    </div>\n                                                    <div class=\"row\">\n                                                        <div class=\"form-column\">\n                                                            <div class=\"col-md-3\">\n                                                                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.enteredon",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":75},"end":{"line":32,"column":105}}}))
    + "</label>\n                                                                                 <p class=\"form-control-static\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"IMPORTEDON") : stack1), depth0))
    + "</p>\n                                                            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"UNAPPROVEDON") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":72},"end":{"line":40,"column":67}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"APPROVEDON") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":72},"end":{"line":46,"column":67}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"REJECTION_REASON") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":60},"end":{"line":52,"column":67}}})) != null ? stack1 : "")
    + "                                                        </div>\n                                                        </div>\n                                                        <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"ERROR") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":72},"end":{"line":61,"column":67}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"APPROVER_REJECTION_REASON") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":72},"end":{"line":67,"column":67}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"CUTOFF_TIME") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":60},"end":{"line":73,"column":67}}})) != null ? stack1 : "")
    + "                                                        </div>\n                                                        </div>\n                                                </div>\n                                        </div>\n                        </div>\n                </fieldset>\n\n        <fieldset class=\"section section-container\">\n                <div class=\"section-header \">\n                        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.filesummary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":32},"end":{"line":83,"column":64}}}))
    + "</legend>\n                </div>\n                <div class=\"row\">\n                        <div class=\"col-md-12\">\n                                <div class=\"col-md-3\">\n                                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.immedorigin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":88,"column":43},"end":{"line":88,"column":75}}}))
    + "</label>\n                                    <p class=\"form-control-static\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"IMMEDORIGNAME_INBOUND") : stack1), depth0))
    + "</p>\n                                </div>\n                                <div class=\"col-md-3\">\n                                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.filename",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":43},"end":{"line":92,"column":72}}}))
    + "</label>\n                                    <p class=\"form-control-static\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"fileSummary") : depth0)) != null ? lookupProperty(stack1,"FILENAME_INBOUND") : stack1), depth0))
    + "</p>\n                                </div>                           \n                    </div>\n            </div>\n                   <div class=\"row\">\n                          <div class=\"col-md-2\"></div>\n                          <div class=\"col-sm-2 text-right\">\n                                  <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.filetotals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":41},"end":{"line":100,"column":72}}}))
    + "</label>\n                          </div>\n                </div>\n                <div class=\"row\">\n                          <div class=\"col-md-2\">\n                                  <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.batchcount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":41},"end":{"line":105,"column":72}}}))
    + "</label>\n                          </div>\n                          <div class=\"col-sm-2 text-right\">\n                                   <span data-hook=\"ptBatchCount\"></span>\n                          </div>\n                </div>\n                <div class=\"row\">\n                          <div class=\"col-md-2\">\n                                  <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.debitentryamt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":113,"column":41},"end":{"line":113,"column":75}}}))
    + "</label>\n                          </div>\n                          <div class=\"col-sm-2 text-right\">\n                          <span data-hook=\"ptTotalAmtDebit\"></span>\n                          </div>\n                </div>\n                <div class=\"row\">\n                          <div class=\"col-md-2\">\n                                  <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.debititems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":121,"column":41},"end":{"line":121,"column":72}}}))
    + "</label>\n                          </div>\n                          <div class=\"col-sm-2  text-right\">\n                           <span data-hook=\"ptTotalNumDebit\"></span>\n                          </div>\n                </div>\n                <div class=\"row\">\n                          <div class=\"col-md-2\">\n                                  <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.creditentryamt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":129,"column":41},"end":{"line":129,"column":76}}}))
    + "</label>\n                          </div>\n                          <div class=\"col-sm-2 text-right\">\n                           <span data-hook=\"ptTotalAmtCredit\"></span>\n                          </div>\n                </div>\n                <div class=\"row\">\n                          <div class=\"col-md-2\">\n                                  <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.credititems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":137,"column":41},"end":{"line":137,"column":73}}}))
    + "<br></label>\n                          </div>\n                          <div class=\"col-sm-2 text-right\">\n                          <span data-hook=\"ptTotalNumCredit\"></span>\n                          </div>\n                </div>\n    </fieldset>\n        <fieldset class=\"section section-container\">\n                        <div class=\"section-body\">\n                                <div class=\"section-header \">\n                                        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.batchsummary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":147,"column":48},"end":{"line":147,"column":81}}}))
    + "</legend>\n                                </div>\n                                <div id=\"accordion-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":149,"column":51},"end":{"line":149,"column":57}}}) : helper)))
    + "\" role=\"tablist\">\n                                        <div class=\"panel panel-tertiary\" id=\"batchInfoPanel\">\n                                                <div id=\"panel-batchInfo\" aria-labelledby=\"#batchInfoPanel\" role=\"tabpanel\">\n                                                        <div class=\"panel-body\" id=\"batchInfo\"></div>\n                                                </div>\n                                        </div>\n                                </div>\n                        </div>\n        </fieldset>\n        <fieldset class=\"section section-container\">\n                        <div class=\"section-body\">\n                                <div class=\"section-header \">\n                                        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.AuditHistory",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":161,"column":48},"end":{"line":161,"column":77}}}))
    + "</legend>\n                                </div>\n                                <div id=\"panel-auditInfo\">\n                                        <div id=\"auditInfo\"></div>\n                                </div>\n                        </div>\n        </fieldset>\n        <div class=\"section section-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNotModal") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":169,"column":8},"end":{"line":181,"column":23}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});