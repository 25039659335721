var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"timeZoneClass") || (depth0 != null ? lookupProperty(depth0,"timeZoneClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timeZoneClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":13},"end":{"line":1,"column":30}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"userTimeZone") || (depth0 != null ? lookupProperty(depth0,"userTimeZone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userTimeZone","hash":{},"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":60}}}) : helper)))
    + "\">\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"userTimeZone") || (depth0 != null ? lookupProperty(depth0,"userTimeZone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userTimeZone","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":20}}}) : helper)))
    + "\n</span> ";
},"useData":true});