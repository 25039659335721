import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import DataEntitlementLimits from 'app/administration/collection/user2/dataEntitlementLimits';
import PaymentTypeCollectionView from './paymentTypeCollectionView';
import accountsByPaymentTypeTmpl from './accountsByPaymentType.hbs';

export default Layout.extend({
    template: accountsByPaymentTypeTmpl,

    ui: {
        $paymentGroupBtn: '.btn-link-group .btn-link',
    },

    onRender() {
        const groupId = this.model.id;
        this.paymentTypesView = new PaymentTypeCollectionView({
            collection: this.model.getEntitledTypes(groupId),
            mode: this.options.mode,
            mainModel: this.options.mainModel,
            limitsModel: this.options.limitsModel,
            modifyMode: this.options.modifyMode,
            paymentTypeGroup: groupId,
            isCannotGrantBeyondOwnUser: this.options.isCannotGrantBeyondOwnUser,
        });

        this.paymentTypesRegion.show(this.paymentTypesView);
    },

    /**
     * Validates the limits of a CGBO by type user against
     * the logged in user.
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateLimits() {
        // No need to validate if view is unavailable.
        if (util.isNullOrUndefined(this.paymentTypesView)) {
            return true;
        }
        const invalidLimits = this.paymentTypesView.children
            .map(paymentType => paymentType.assignedAccountsView.getDataEntitlmentLimits()
                // Need to filter so all models are validated.
                .filter((dataEntitlementLimit) => {
                    const isValid = dataEntitlementLimit.isValid();
                    return !isValid;
                }));

        return util.isEmpty(util.flatten(invalidLimits, true));
    },

    beforeSave() {
        /*
         * Collect all the models from each listview of each type
         * and do one bulk update to the server.
         */
        const dataEntitlementLimits = new DataEntitlementLimits();

        /*
         * The limits from each type are collected.  Set silent since there is no
         * need to announce changes.
         */
        this.paymentTypesView.children.each((paymentType) => {
            dataEntitlementLimits.add(
                paymentType.assignedAccountsView.getDataEntitlmentLimits(),
                {
                    silent: true,
                },
            );
        });

        return new Promise((resolve, reject) => {
            dataEntitlementLimits.save({
                byGroup: false,
                isACH: this.model?.id === 'ACH',
                success: resolve,

                error(result) {
                    reject(result);
                },
            });
        });
    },
});
