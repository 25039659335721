import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import moment from 'moment';
import util from '@glu/core/src/util';

const DATE_FORMAT = 'MM/DD/YY';

const Chart = Collection.extend({
    initialize(models, options) {
        this.chartViewSettings = options.chartViewSettings;
    },

    loadItems() {
        const sDate = moment(new Date()).add(this.chartViewSettings.get('daysBack'), 'days')
            .format('MM/DD/YYYY');
        const eDate = moment(new Date()).add(this.chartViewSettings.get('daysForward'), 'days')
            .format('MM/DD/YYYY');

        const requestData = {
            startDate: sDate,
            endDate: eDate,
        };

        const plan = this.chartViewSettings.get('plan');
        if (!plan) {
            /*
             * A plan needs to be selected  - even if its the system generated
             * plan with all zeros.
             */
            return;
        }

        requestData.cashFlowPlanId = plan.get('cashFlowPlanId');
        requestData.cashFlowPlanVersion = plan.get('version');
        requestData.multiYearPlanId = plan.get('multiYearPlanId');

        http.post(
            services.generateUrl('/cashflow/chart'),
            requestData,
            util.bind(this.successCallback, this),
            util.bind(this.errorCallback, this),
        );
    },

    parse(result) {
        return util.map(result, (valParam, key) => {
            const val = valParam;
            val.date = moment(key, 'MM/DD/YYYY');
            return val;
        });
    },

    successCallback(result) {
        // TODO parse and reset
        this.reset(this.parse(result));
        this.trigger('sync', this, result);
        this.chartViewSettings.trigger('change:daysBack');
    },

    errorCallback() {
        this.reset();
        this.trigger('error', this);
    },

    getChartData(lines) {
        /*
         * TODO this will handle grouping
         * according to this.chartViewSettings.get('groupBy')
         * refer to cashFlowList#getDataMergedByGroup
         */
        const data = {};

        const groupBy = this.chartViewSettings.get('groupBy');
        let previousGroupId = null;
        const todayGroupId = this.getGroupId(groupBy, moment(new Date()));

        // Keep track of each line scenario balance across all days
        const runningLineBalances = {};

        /*
         * group each time period's entries into an array
         * set balance and line values every day - only the last one in the group
         * will persist
         */
        this.each((dayModel) => {
            const m = dayModel.get('date');
            const groupId = this.getGroupId(groupBy, m);

            if (groupId !== previousGroupId) {
                // add a new group to the object
                data[groupId] = {
                    startDate: m.format(DATE_FORMAT),
                    dayModels: [],
                    dates: [],
                    groupId,
                };
                previousGroupId = groupId;
            }

            if (groupId >= todayGroupId) {
                util.each(lines, (line) => {
                    // Sum scenarios
                    const scenarios = dayModel.get('scenarios');
                    let scenarioTotal = 0;

                    Object.keys(scenarios || {}).forEach((scenarioId) => {
                        if (util.contains(line.scenarioIds, +scenarioId)) {
                            scenarioTotal += parseFloat(scenarios[scenarioId]);
                        }
                    });

                    // Keep track of the line scenario balance
                    runningLineBalances[line.lineId] = (
                        runningLineBalances[line.lineId] || 0) + (scenarioTotal);
                    /*
                     * Set the line balance as the scenario balance for the line plus
                     * the base predicted amount
                     */
                    data[groupId][line.lineId] = (runningLineBalances[line.lineId] + dayModel.get('predicted'));
                }, this);
            }

            // keep all models for debug purposes
            data[groupId].dayModels.push(dayModel);
            data[groupId].dates.push(m.format(DATE_FORMAT));

            // sum up inFlow, outFlow
            data[groupId].inFlow = (data[groupId].inFlow || 0) + dayModel.get('inFlow');
            data[groupId].outFlow = (data[groupId].outFlow || 0) + dayModel.get('outFlow');

            if (groupId >= todayGroupId) {
                data[groupId].predicted = dayModel.get('predicted');
            }
            if (groupId <= todayGroupId) {
                data[groupId].remaining = dayModel.get('remaining');
                data[groupId].predicted = dayModel.get('predicted');
            }
            data[groupId].endDate = m.format(DATE_FORMAT);

            if (groupId === todayGroupId && this.chartViewSettings.get('groupBy') !== 'day') {
                data[groupId].remaining = (data[groupId].predicted + (data[groupId].inFlow || 0))
                    - (data[groupId].outFlow || 0);
            }
        }, this);

        return util.flatten(data);
    },

    getGroupId(groupBy, momentDate) {
        let localGroupBy = groupBy;
        // otherwise we will mutate the date that got passed in
        const m = momentDate.clone();
        if (localGroupBy === 'day') {
            localGroupBy = 'dayOfYear';
        } else {
            /*
             * for groups larger than a day, make sure the date is at the end of the period
             * that way, the right year will be used for the groupId string
             */
            m.endOf(localGroupBy);
        }
        return `${m.year()}-${m.format('DDDD')}`;
    },
});

export default Chart;
