import util from '@glu/core/src/util';
import $ from 'jquery';
import locale from '@glu/locale';
import amountFormatHelper from 'common/dynamicPages/views/mdf/componentHelpers/amountFormat';
import dialog from '@glu/dialog';
import patterns from 'system/validatorPatterns';
import PaymentUtil from 'common/util/paymentUtil';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import rtgsCommon from './rtgsCommon';
import rtgsBeneInfoCommon from './rtgsBeneInfoCommon';

export default function (form, initialState) {
    const addIntBank = form.field('ADDINTBANK');
    const addIntAddress = form.field('INTERMEDIARY_CITY_LINK');
    const debitAcctFieldList = ['DEBIT_ACCOUNT_BANK_NAME', 'DEBIT_ACCOUNT_TITLE', 'DEBIT_BANKNAME', 'DEBIT_BANK_CODE'];
    const beneBankArr = ['BENE_BANK_NAME', 'BENE_BANK_ADDRESS_1', 'BENE_BANK_ADDRESS_2', 'BENE_BANK_CITY', 'BENE_BANK_STATE'];
    const { model } = form.formView;
    const { functionCode } = model.jsonData.typeInfo;
    const interId = form.field('INTERMEDIARY_ID');
    const interBankState = form.field('INTERMEDIARY_STATE');
    const interBankAddress1 = form.field('INTERMEDIARY_ADDRESS_1');
    const interBankAddress2 = form.field('INTERMEDIARY_ADDRESS_2');
    const interBankAddress3 = form.field('INTERMEDIARY_CITY');
    const interBankAccountNumber = form.field('INTER_BANK_ACCOUNT_NUMBER');
    const interBankName = form.field('INTERMEDIARY_NAME');
    const interBankCity = form.field('INTERMEDIARY_CITY');
    const creditAmount = form.field('CREDIT_AMOUNT');
    const intBankArr = ['INTERMEDIARY_NAME', 'INTERMEDIARY_ADDRESS_1', 'INTERMEDIARY_ADDRESS_2', 'INTERMEDIARY_CITY', 'INTERMEDIARY_STATE'];
    const originatorAddrPrefix = 'ORIGINATOR_';
    const allowByOrderOf = model.get('ALLOWBYORDEROF');
    const allowBeneNotificationEmail = model.get('ALLOWBENENOTIFICATIONSWIRES') || ((model.fieldData.ALLOWBENENOTIFICATIONSWIRES) ? model.fieldData.ALLOWBENENOTIFICATIONSWIRES.value : 0);
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;
    const allowExtRemitDoc = model.get('RTGSALLOWEXTREMITDOC');
    const hasOrderingPartyLookupConfig = orderingPartyUtil.isEntitled(model.get('ALLOWBYORDEROF'));
    let beneBankAccountNumberValidator;
    let beneBankAddress1Validator;
    const showHideReadOnly = function (array, controlField, isManual) {
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnly(!isManual);
        });
    };
    const extendedRemitDetailSections = [
        '.RTGS_INST_FEDWIRE_ADDITIONAL_INFORMATION_REMIT_ORIGINATOR',
        '.RTGS_INST_FEDWIRE_ADDITIONAL_INFORMATION_REMIT_BENEFICIARY',
        '.RTGS_INST_FEDWIRE_ADDITIONAL_INFORMATION_REMIT_DOCUMENT',
    ];
    const extendedRemitDetailFields = [
        'RMT_ORIG_NAME',
        'RMT_ORIG_IDTYPE',
        'RMT_ORIG_IDNUM',
        'RMT_ORIG_ADDRLINE1',
        'RMT_ORIG_ADDRLINE2',
        'RMT_ORIG_ADDRCOUNTRY',
        'RMT_ORIG_ADDRCITY',
        'RMT_ORIG_ADDRSTATE',
        'RMT_ORIG_ADDRPROVINCE',
        'RMT_ORIG_ADDRPOSTAL',
        'RMT_CONTACTNAME',
        'RMT_PHONENUMBER',
        'RMT_MOBILENUMBER',
        'RMT_FAXNUMBER',
        'RMT_EMAILADDRESS',
        'RMT_OTHERCONTACTINFO',
        'RMT_BENE_NAME',
        'RMT_BENE_BENETYPE',
        'RMT_BENE_IDTYPE',
        'RMT_BENE_IDNUM',
        'DOCUMENTS',
    ];
    const extendedRemitDetailMandatory = [
        'RMT_ORIG_NAME',
        'RMT_ORIG_IDTYPE',
        'RMT_ORIG_IDNUM',
        'RMT_BENE_NAME',
        'RMT_BENE_BENETYPE',
        'RMT_BENE_IDTYPE',
        'RMT_BENE_IDNUM',
    ];
    const changeableFields = [
        'VALUE_DATE',
        'CREDIT_AMOUNT',
        'CUSTOMER_REFERENCE',
        'SPECIAL_INSTRUCTIONS',
        'OBI_TEXT_1',
        'OBI_TEXT_2',
        'OBI_TEXT_3',
        'OBI_TEXT_4',
        'INSTRUCTIONS_TO_BENE_BANK_1',
        'INSTRUCTIONS_TO_BENE_BANK_2',
        'INSTRUCTIONS_TO_BENE_BANK_3',
        'INSTRUCTIONS_TO_BENE_BANK_4',
        'INSTRUCTIONS_TO_BENE_BANK_5',
        'INSTRUCTIONS_TO_BENE_BANK_6',
        'BENE_NOTIFICATION_EMAIL',
        'BENE_NOTIFICATION_EMAIL2',
        'BENE_NOTIFICATION_EMAIL3',
        'BENE_NOTIFICATION_EMAIL4',
        'BENE_NOTIFICATION_EMAIL5',
        'ALLOWEXTREMITDOC',
        'BENE_ACCOUNT_MASK_TOGGLE',
    ];

    const protectFields = () => {
        Object.keys(form.fields).forEach((fieldName) => {
            if ((changeableFields.indexOf(fieldName) === -1)
                && (extendedRemitDetailFields.indexOf(fieldName) === -1)) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
            const isFieldReadOnly = form.fields[fieldName].isReadOnly();
            const $lookup = form.fields[fieldName].$el.parent().find('.lookup');
            if (isFieldReadOnly && $lookup) {
                $lookup.addClass('hidden');
            }
        });
    };

    const hasAnyExtendedRemit = () => extendedRemitDetailFields
        .some(fieldName => !util.isEmpty(model.get(fieldName)));
    const showExtendedRemit = () => {
        util.each(extendedRemitDetailSections, (sectionName) => {
            if (form.formView.$(sectionName).length > 0) {
                form.formView.$(sectionName).parent().removeClass('hide');
            }
        });
        util.each(extendedRemitDetailFields, (fieldName) => {
            form.field(fieldName).shouldBeVisible().shouldBeReadOnly(false);
            model.validators[fieldName] = {
                description: locale.get(`RTGS.INST.FEDWIRE.*.${fieldName}.description`),
                matches: (fieldName === 'RMT_EMAILADDRESS' ? patterns.EMAIL_PATTERN : patterns.FEDWIRE_PATTERN),
                overrideError: (fieldName === 'RMT_EMAILADDRESS' ? 'isEmailAddress' : 'isFedwire'),
            };
        });
        util.each(extendedRemitDetailMandatory, (fieldName) => {
            model.validators[fieldName].exists = true;
            form.field(fieldName).$el.parent().addClass('required');
        });

        if (!hasAnyExtendedRemit()) {
            // New extended remittance details map from payment details when enabled.
            model.set({
                RMT_ORIG_NAME: model.get('ORIGINATOR_NAME'),
                RMT_ORIG_IDTYPE: 'TXID',
                RMT_ORIG_ADDRLINE1: model.get('ORIGINATOR_ADDRESS_1'),
                RMT_ORIG_ADDRLINE2: model.get('ORIGINATOR_ADDRESS_1'),
                RMT_ORIG_ADDRCOUNTRY: model.get('ORIGINATOR_COUNTRY'),
                RMT_ORIG_ADDRCITY: model.get('ORIGINATOR_CITY'),
                RMT_ORIG_ADDRSTATE: model.get('ORIGINATOR_STATE'),
                RMT_ORIG_ADDRPOSTAL: model.get('ORIGINATOR_POSTALCODE'),
                RMT_BENE_NAME: model.get('BENE_NAME'),
                RMT_BENE_BENETYPE: 'OI',
                RMT_BENE_IDTYPE: 'TXID',
            });
        }

        if (form.field('RMT_ORIG_ADDRCOUNTRY').getValue() === 'US') {
            form.field('RMT_ORIG_ADDRPROVINCE').shouldBeHidden().shouldBeReadOnly(true);
        } else {
            form.field('RMT_ORIG_ADDRSTATE').shouldBeHidden().shouldBeReadOnly(true);
            /*
             * Overrides set above. Non-US countries shouldn't have a
             * state code so this is precautionary.
             */
            model.set('RMT_ORIG_ADDRSTATE', '');
        }
    };

    const isCopyPaymentAsPayment = () => model.get('ENTRYMETHOD') === '0' && model.get('FROMTEMPLATE') === '1';

    const hideExtendedRemit = () => {
        util.each(extendedRemitDetailSections, (sectionName) => {
            if (form.formView.$(sectionName).length > 0) {
                form.formView.$(sectionName).parent().addClass('hide');
            }
        });
        util.each(extendedRemitDetailFields, (fieldName) => {
            model.removeValidator(fieldName);
            form.field(fieldName).removeValidator('exists').$el.parent().removeClass('required');
            form.field(fieldName).shouldBeHidden().shouldBeReadOnly(true);
        });
        const emptyFieldArray = extendedRemitDetailFields
            .reduce((fieldArrayParam, fieldName) => {
                const fieldArray = fieldArrayParam;
                fieldArray[fieldName] = '';
                return fieldArray;
            }, {});
        model.set(emptyFieldArray);
        model.trigger('deleteDocs');

        if (allowExtRemitDoc !== 'DOM' && allowExtRemitDoc !== 'BOTH') {
            form.field('ALLOWEXTREMITDOC').$el.prop('checked', false);
            form.field('ALLOWEXTREMITDOC').shouldBeHidden().shouldBeReadOnly(true);
        }
    };

    /*
     * Handles the displaying/hiding of fields when the user changes the entry method for
     * the 2nd intermediary
     */
    const interBankIdEntrymethodChange = function () {
        const isInterBankFreeform = model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM';
        const isSecondaryBankNameAdd1Req = isInterBankFreeform && model.get('secIntBankNameAdd1Req') === '1';

        form.formView.$(`.RTGS_${functionCode}_FEDWIRE_ADDITIONAL_INFORMATION_INTBANKGROUP_2`).removeClass('hide');

        // if freeform, then hide all address inputs and only show address1
        form.formView.$(`.RTGS_${functionCode}_FEDWIRE_ADDITIONAL_INFORMATION_INTBANKGROUP_2_Address`)
            .toggleClass('hide', !isInterBankFreeform);

        addIntBank.shouldBeHidden();
        interBankState.shouldBeRequiredWhen(!isInterBankFreeform).shouldBeVisibleWhen(!isInterBankFreeform).$el.parent().toggleClass('required', !isInterBankFreeform);
        interId.shouldBeVisibleWhen(!isInterBankFreeform);
        interBankAddress1.shouldBeVisibleWhen(isInterBankFreeform).shouldBeRequiredWhen(isSecondaryBankNameAdd1Req).$el.parent().toggleClass('required', isSecondaryBankNameAdd1Req);
        interBankAddress2.shouldBeVisibleWhen(isInterBankFreeform
            && !util.isEmpty(model.get('INTERMEDIARY_ADDRESS_2')));
        interBankAddress3.shouldBeVisibleWhen(isInterBankFreeform
            && !util.isEmpty(model.get('INTERMEDIARY_CITY')));

        addIntAddress.shouldBeVisibleWhen(isInterBankFreeform
            && interBankAddress1.isVisible()
            && (interBankAddress2.isNotVisible()
            || interBankAddress3.isNotVisible()));

        interBankAccountNumber.shouldBeVisibleWhen(isInterBankFreeform)
            .shouldBeRequiredWhen(model.fieldData.INTER_BANK_ACCOUNT_NUMBER
                && model.fieldData.INTER_BANK_ACCOUNT_NUMBER.mandatory
                && isInterBankFreeform);
        interBankName.shouldBeVisibleWhen(isInterBankFreeform).shouldBeRequiredWhen(isSecondaryBankNameAdd1Req).$el.parent().toggleClass('required', isSecondaryBankNameAdd1Req);
        showHideReadOnly(intBankArr, 'INTERMEDIARY_ID', isInterBankFreeform && form.formView.state !== 'repair');
    };

    if (initialState && (model.get('INTERMEDIARY_ID') || model.get('INTERMEDIARY_NAME'))) {
        interBankIdEntrymethodChange();
    }

    /*
     * TODO: This needs to be changed to lookup the valid values from the DB
     * instead of hard coding them. This should also be moved to RTGS.js and
     * be made generic for all wire types. Doing it here and hardcoding for now
     * due to time constraints and because Fedwire is the most likely payment type
     * to need this. Solves issue where user selects Contact with an invalid type
     * and tries to save. This pre-emptively changes the type correctly to 'Other'
     */
    if ((form.formView.state === 'modify' || form.formView.state === 'restore') && model.get('BENE_ACCOUNT_TYPE') !== 'ABA' && model.get('BENE_ACCOUNT_TYPE') !== 'DDA' && model.get('Other') !== 'ABA' && model.get('BENE_ACCOUNT_TYPE') !== 'SWIFT') {
        model.set('BENE_ACCOUNT_TYPE', 'Other');
    }

    if (initialState) {
        // TEMPORARY Fix: NH-33472
        form.field('UPDATEADDRESSBOOK').shouldBeHidden();
        // end NH-33472

        if (form.formView.state === 'insert') {
            if (util.isEmpty(model.get('BENEBANKIDENTRYMETHOD'))) {
                model.set('BENEBANKIDENTRYMETHOD', 'LOOKUP');
            }

            if (util.isEmpty(model.get('INTERBANKIDENTRYMETHOD'))) {
                model.set('INTERBANKIDENTRYMETHOD', 'LOOKUP');
            }

            if (util.isEmpty(model.get('BENE_BANK_TYPE'))) {
                model.set('BENE_BANK_TYPE', 'ABA');
            }
        }

        /*
         * NH-99275 - when mapped from the beneficiary lookup,
         * default value ABA is being reset to blank.
         */
        model.on('change:INTERMEDIARY_TYPE', () => {
            if (model.get('INTERMEDIARY_TYPE') === '') {
                model.set('INTERMEDIARY_TYPE', 'ABA', {
                    silent: true,
                });
            }
        });

        if (form.formView.state.toUpperCase() === 'INSERT') {
            // default bene_country to US
            if (model.context.createdFrom !== '1' && model.get('BENE_COUNTRY') !== '') {
                model.set('BENE_COUNTRY', 'US');
            }

            const beneAccountType = form.field('BENE_ACCOUNT_TYPE');
            if (util.isEmpty(beneAccountType.getValue())) {
                form.formView.model.set('BENE_ACCOUNT_TYPE', 'Other');
            }
        }

        const hasOrderingPartyLink = PaymentUtil.hasOrderingPartyLink(model);
        if (hasOrderingPartyLink) {
            PaymentUtil.populateOrderingPartyMap(model);
        }

        // only create listeners when contact center link already exists or link could exist
        if (hasOrderingPartyLink || hasOrderingPartyLookupConfig) {
            PaymentUtil.onChangeOrderingPartyFreeForm(model);
        }

        model.on('change:ORDEROF', () => {
            PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');

            if (form.formView.state === 'insert' && model.get('ORDEROF') === '1') {
                $('#ORDEROF_ID_TYPE').val('ACCT').change();
            }
        });

        // set up for ORDEROF_ID_TYPE/ORDEROF_ACCOUNT
        PaymentUtil.orderOfInitialState(form, model);

        model.on('change:ORDEROF_COUNTRY', () => {
            PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');
        });

        model.on('change:ORDEROF_ADDRESS_2', () => {
            PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');
        });

        model.on('change:ORDEROF_STATE', () => {
            model.set(
                'ORDEROF_STATE',
                model.get('ORDEROF_STATE').toUpperCase(),
                {
                    silent: true,
                },
            );
        });

        model.on('change:INTERBANKIDENTRYMETHOD', () => {
            const clearFields = model.get('INTERBANKIDENTRYMETHOD') !== 'FREEFORM';
            if (clearFields) {
                util.each(intBankArr, (field) => {
                    model.set(field, '');
                });
                interId.setValue('');
                model.set('INTERMEDIARY_ID', '');
                model.set('INTER_BANK_ACCOUNT_NUMBER', '');
                // reset the helper text when the attributes are cleared.
                form.formView.trigger('lookupHelperText:update', 'INTERMEDIARY_ID');
            }
            interBankIdEntrymethodChange();
        });

        model.on('change:BENEBANKIDENTRYMETHOD', () => {
            let clearFields = true;
            if (!util.isEmpty(model.get('BENEBOOK_ID'))
                && ((model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM' && (util.isUndefined(model.get('BENE_BANK_ID_MAP')) || model.get('BENE_BANK_ID_MAP') === ''))
                    || (model.get('BENEBANKIDENTRYMETHOD') === 'LOOKUP' && model.get('BENE_BANK_ID_MAP')))) {
                clearFields = false;
            }
            if (clearFields) {
                util.each(beneBankArr, (field) => {
                    model.set(field, '');
                });
                model.set({
                    BENE_BANK_ID: '',
                    BENE_BANK_ACCOUNT_NUMBER: '',
                    BENE_BANK_ADDRESS_1_VIRTUALFIELD: '',
                    BENE_BANK_ADDRESS_2_VIRTUALFIELD: '',
                    BENE_BANK_CITY_VIRTUALFIELD: '',
                    BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD: '',
                });
                model.unset('BENEBOOK_ID');
                model.unset('BENEBOOKCHILD_ID');
            } else {
                // reset the helper text when the attributes are cleared.
                form.formView.trigger('lookupHelperText:update', 'BENE_BANK_ID');
            }
            rtgsBeneInfoCommon.beneBankIdEntryMethodChange(
                form,
                beneBankAccountNumberValidator,
                beneBankAddress1Validator,
            );
        });

        model.on('change:BENE_BANK_ID', () => {
            if (util.isEmpty(model.get('BENE_BANK_ID'))) {
                // reset the helper text when the Bene Bank is cleared.
                form.formView.trigger('lookupHelperText:clear', 'BENE_BANK_ID');
            }
        });
        model.on('change:INTERMEDIARY_ADDRESS_1', () => {
            interBankIdEntrymethodChange();
        });
        model.on('change:INTERMEDIARY_ADDRESS_2', () => {
            interBankIdEntrymethodChange();
        });
        model.on('change:INTERMEDIARY_CITY', () => {
            if (interBankAddress3) {
                addIntAddress.shouldBeHidden();
            }
            interBankIdEntrymethodChange();
        });
        model.on('change:DEBIT_ACCOUNT_NUMBER', () => {
            rtgsBeneInfoCommon.showHideAddr(form, originatorAddrPrefix, (model.fieldData.ORIGINATOR_ID_TYPE.fieldUIType !== 'TEXTBOX'));
        });

        model.on('change:INTERMEDIARY_ID', () => {
            const isInterBankFreeform = model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM';
            if (!isInterBankFreeform) {
                form.field('INTERMEDIARY_ID').shouldBeVisible();
                addIntBank.shouldBeHidden();
            }
        });

        if (allowBeneNotificationEmail !== '1') {
            model.set({
                BENE_NOTIFICATION_EMAIL: '',
                BENE_NOTIFICATION_EMAIL2: '',
                BENE_NOTIFICATION_EMAIL3: '',
                BENE_NOTIFICATION_EMAIL4: '',
                BENE_NOTIFICATION_EMAIL5: '',
            });
            $('[data-widget-field="BENE_NOTIFICATION_EMAIL"]').hide();
        }

        const amtBlock = form.formView.$('[data-validate="CREDIT_AMOUNT"]');
        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            form.formView,
            model,
            amtBlock,
            functionCode,
        );

        if (form.formView.state === 'insert' || form.formView.state === 'modify' || form.formView.state === 'restore' || form.formView.state === 'repair') {
            beneBankAccountNumberValidator = model.validators.BENE_BANK_ACCOUNT_NUMBER;
            beneBankAddress1Validator = model.validators.BENE_BANK_ADDRESS_1_VIRTUALFIELD;
            rtgsBeneInfoCommon.beneBankIdEntryMethodChange(
                form,
                beneBankAccountNumberValidator,
                beneBankAddress1Validator,
            );
            if (allowByOrderOf === '1' || model.fieldData.ALLOWBYORDEROF.value === '1') {
                /*
                 * In copy-as-template, checkbox is checked
                 * when 'ORDEROF'=0; had to manually set it
                 */
                $('#ORDEROF').prop('checked', model.get('ORDEROF') === '1');
                PaymentUtil.showHideOrderingParty(model, form, model.get('ORDEROF') === '1');
            } else {
                $('[class*=FEDWIRE_ONBEHALF_ORIGINATOR]').hide();
            }

            if (form.field('INTERMEDIARY_ID').isNotEmpty()) {
                form.field('INTERMEDIARY_ID').shouldBeVisible();
                addIntBank.shouldBeHidden();
            } else {
                form.field('INTERMEDIARY_ID').shouldBeHidden();
                addIntBank.shouldBeVisible();
            }

            rtgsBeneInfoCommon.showHideAddr(form, originatorAddrPrefix, (model.fieldData.ORIGINATOR_ID_TYPE.fieldUIType !== 'TEXTBOX'));
        }
        // initially give tran date same value as value date, if tran date is empty
        if (model.get('VALUE_DATE') && !model.get('TRAN_DATE')) {
            model.set('TRAN_DATE', model.get('VALUE_DATE'));
        }

        if (form.formView.state === 'view') {
            $('#ORDEROF').text(locale.get('RTGS.screentext.Ordering Party')).css('font-weight', 'bold').css('width', '20%');
            $('#BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD').parent().parent().addClass('hide');
            if (functionCode === 'INST') {
                $('.RTGS_INST_FEDWIRE_BENEINFO_BENEFICIARY_BANK_ADDRESS_VIRTUALFIELD').parent().addClass('hide');
            } else {
                $('.RTGS_TMPL_FEDWIRE_BENEINFO_BENEFICIARY_BANK_ADDRESS_VIRTUALFIELD').parent().addClass('hide');
            }
            $('#BENE_BANK_NAME').addClass('hide');

            if (model.get('INSTRUCTIONS_TO_BENE_BANK_1') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_2') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_3') === '' && model.get('INSTRUCTIONS_TO_BENE_BANK_4') === '') {
                form.formView.$('.RTGS_INST_FEDWIRE_ADDITIONAL_INFORMATION_BANKINSTRUCTIONSGROUP').parent().addClass('hide');
            }
            if (model.get('OBI_TEXT_1') === '' && model.get('OBI_TEXT_2') === '' && model.get('OBI_TEXT_3') === '' && model.get('OBI_TEXT_4') === '') {
                form.formView.$('.RTGS_INST_FEDWIRE_ADDITIONAL_INFORMATION_PAYMENTDETAILSGROUP').parent().addClass('hide');
            }
        } else if ((form.formView.state === 'modify' || form.formView.state === 'restore') && model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM') {
            // Show the Bene_Bank virtual address fields.
            rtgsBeneInfoCommon.showHideAddr(form, 'BENE_BANK_', true, true, true);
        }

        if (!model.get('INTERMEDIARY_ID') && !model.get('INTERMEDIARY_NAME')) {
            form.formView.$(`.RTGS_${functionCode}_FEDWIRE_ADDITIONAL_INFORMATION_INTBANKGROUP_2`).addClass('hide');
            form.formView.$(`.RTGS_${functionCode}_FEDWIRE_ADDITIONAL_INFORMATION_INTBANKGROUP_2_Address`).addClass('hide');
            interBankAccountNumber.shouldBeOptional();
        } else {
            form.formView.$(`.RTGS_${functionCode}_FEDWIRE_ADDITIONAL_INFORMATION_INTBANKGROUP_2`).removeClass('hide');
            addIntBank.shouldBeHidden();
            interBankIdEntrymethodChange();
        }

        // handle lock icon for amount if template and not view
        if (form.formView.state !== 'view' && (functionCode !== 'BATCH')) {
            if (creditAmount.$el && creditAmount.$el.length > 0) {
                PaymentUtil.runChangeAmount(creditAmount.$el[0], model);
            }
        }

        amountFormatHelper.setupInputMask(form.formView, 'CREDIT_AMOUNT', false);

        if (hasAnyExtendedRemit()) {
            if (isCopyPaymentAsPayment()) {
                /*
                 * We must ensure any old entries in RTGS_REMIT_DOC_TEMP are
                 * in the DELETE state so that we don't pick them up as part of this
                 * payment during the backend validation.
                 */
                model.trigger('deleteDocs');
            }
            form.field('ALLOWEXTREMITDOC').$el.prop('checked', true);
            showExtendedRemit();
        } else {
            form.field('ALLOWEXTREMITDOC').$el.prop('checked', false);
            hideExtendedRemit();
        }

        form.field('ALLOWEXTREMITDOC').$el.on('click', () => {
            if (form.field('ALLOWEXTREMITDOC').$el.prop('checked')) {
                showExtendedRemit();
            } else {
                const warningTitle = locale.get('RTGS.INST.RFEDWIRE.warning.deselect.title');
                let warningMsg = locale.get('RTGS.INST.RFEDWIRE.warning.deselect.dataLoss');
                if (allowExtRemitDoc !== 'DOM' && allowExtRemitDoc !== 'BOTH') {
                    warningMsg = locale.get('RTGS.INST.RFEDWIRE.warning.deselect.sectionLoss');
                }
                dialog.confirm(warningMsg, warningTitle, (ok) => {
                    if (ok) {
                        // TODO Delete Existing Documents From Grid
                        hideExtendedRemit();
                    } else {
                        form.field('ALLOWEXTREMITDOC').$el.prop('checked', true);
                    }
                });
            }
        });

        /*
         * The default bene ID type is Tax ID. The bene ID type list changes depending on the
         * bene type.
         * So when the bene type changes, we will reset the bene ID type to default.
         */
        form.field('RMT_BENE_BENETYPE').$el.on('change', () => {
            if (form.field('ALLOWEXTREMITDOC').$el.prop('checked')) {
                model.set('RMT_BENE_IDTYPE', 'TXID');
            }
        });

        form.field('RMT_ORIG_ADDRCOUNTRY').$el.on('change', () => {
            if (form.field('ALLOWEXTREMITDOC').$el.prop('checked')) {
                const isUS = form.field('RMT_ORIG_ADDRCOUNTRY').getValue() === 'US';
                form.field('RMT_ORIG_ADDRSTATE').shouldBeVisibleWhen(isUS).shouldBeReadOnly(!isUS);
                form.field('RMT_ORIG_ADDRPROVINCE').shouldBeVisibleWhen(!isUS).shouldBeReadOnly(isUS);

                if (isUS) {
                    model.set('RMT_ORIG_ADDRPROVINCE', '');
                } else {
                    model.set('RMT_ORIG_ADDRSTATE', '');
                }
            }
        });

        if (util.isUndefined(model.get('secIntBankNameAdd1Req')) && (form.formView.state.toUpperCase() === 'INSERT' || form.formView.state.toUpperCase() === 'MODIFY')) {
            rtgsCommon.isSecondaryBankNameAdd1Req(model.get('TYPE')).then((bankAddReq) => {
                model.set('secIntBankNameAdd1Req', bankAddReq);
            });
        }
    }

    if (model.get('BENEBANKIDENTRYMETHOD') !== 'LOOKUP') {
        model.set({
            BENE_BANK_ACCOUNT_NUMBER: model.get('BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD'),
            BENE_BANK_ADDRESS_1: model.get('BENE_BANK_ADDRESS_1_VIRTUALFIELD'),
            BENE_BANK_ADDRESS_2: model.get('BENE_BANK_ADDRESS_2_VIRTUALFIELD'),
            BENE_BANK_CITY: model.get('BENE_BANK_CITY_VIRTUALFIELD'),
        });
        rtgsBeneInfoCommon.beneBankIdEntryMethodChange(
            form,
            beneBankAccountNumberValidator,
            beneBankAddress1Validator,
        );
    }

    showHideReadOnly(debitAcctFieldList, 'DEBIT_ACCOUNT_NUMBER', false);
    rtgsBeneInfoCommon.setupEmailFields(form);
    rtgsBeneInfoCommon.showHideAddr(form, originatorAddrPrefix, (model.fieldData.ORIGINATOR_ID_TYPE.fieldUIType !== 'TEXTBOX'));

    /**
     * NH-44250 Country is always US, no need displayed for FEDWIRE,
     * now because it's added to the template and has value,
     * it's displayed on the entry screen, hide it for FEDWIRE.
     */
    if (form.formView.state !== 'view') {
        $('[data-hook="bene-country"]').addClass('hide');
    }

    rtgsCommon.toggleStateProvince(
        model, model.get('BENE_COUNTRY'),
        $('input[name="BENE_PROVINCE"]').closest('.field-container'),
        $('select[name="BENE_STATE"]').closest('.field-container'),
    );

    addIntAddress
        .$el.on('click', (e) => {
            e.stopImmediatePropagation();
            if (interBankAddress2.isVisible()) {
                interBankCity.shouldBeVisible();
                addIntAddress.shouldBeHidden();
            } else {
                interBankAddress2.shouldBeVisible();
            }
        });

    addIntBank
        .$el.on('click', (e) => {
            e.stopImmediatePropagation();
            addIntBank.shouldBeHidden();
            interBankAccountNumber.shouldBeHidden();
            interBankName.shouldBeHidden();
            interBankAddress1.shouldBeHidden();
            interBankAddress2.shouldBeHidden();
            interBankCity.shouldBeHidden();
            interId.shouldBeVisible();
            addIntAddress.shouldBeHidden();
            form.formView.$(`.RTGS_${functionCode}_FEDWIRE_ADDITIONAL_INFORMATION_INTBANKGROUP_2`).removeClass('hide');
            form.formView.$(`.RTGS_${functionCode}_FEDWIRE_ADDITIONAL_INFORMATION_INTBANKGROUP_2_Address`).removeClass('hide');
        });

    if (form.formView.model.get('ENTRYMETHOD') && (form.formView.model.get('ENTRYMETHOD') === '1' || form.formView.model.get('ENTRYMETHOD') === '2')) {
        protectFields();
    }
}
