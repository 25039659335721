var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"glu-container\">\n    <div class=\"DepositHistory-layoutWrapper\">\n        <div>\n            <div class=\"row\">\n                <div class=\"page-header-wrapper text-center\">\n                    <h1 class=\"RDC-page-header landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Deposit.History",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":63},"end":{"line":6,"column":95}}}))
    + "</h1>\n                </div>\n            </div>\n\n            <div class=\"panel panel-tertiary RDCPanel\">\n                <div class=\"panel-heading\"\n                     role=\"tab\"\n                     id=\"panel-heading-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":13,"column":39},"end":{"line":13,"column":46}}}) : helper)))
    + "\"\n                     data-hook=\"getPanelHeading\">\n                    <h2 class=\"panel-title RDCPanel-title\">\n                        <a href=\"#panel-body-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":52}}}) : helper)))
    + "\"\n                           aria-expanded=\"true\"\n                           data-toggle=\"collapse\"\n                           data-hook=\"getPanelToggle\">\n                            <span class=\"indicator-icon\"></span>\n                            <span data-hook=\"getPanelTitleText\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Modify.Search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":64},"end":{"line":21,"column":94}}}))
    + "</span>\n                        </a>\n                    </h2>\n                </div>\n\n                <div class=\"panel-collapse collapse in\"\n                     id=\"panel-body-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":27,"column":36},"end":{"line":27,"column":43}}}) : helper)))
    + "\"\n                     aria-expanded=\"true\"\n                     role=\"tabpanel\"\n                     data-hook=\"getFormPanel\">\n                    <div data-hook=\"getFormWrapper\">\n                        <div class=\"row\">\n                            <div class=\"col-12\">\n                                <fieldset>\n                                    <div data-region=\"locationAccountRegion\"></div>\n                                </fieldset>\n                            </div>\n                        </div>\n                        <div class=\"row\">\n                            <div class=\"col-12\">\n                                <button class=\"btn btn-primary RDC-btn--primary\"\n                                        type=\"button\"\n                                        data-hook=\"getRetrieveButton\"\n                                        disabled>\n                                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Retrieve.History",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":36},"end":{"line":45,"column":69}}}))
    + "\n                                </button>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row DepositHistory-listWrapper\">\n            <div class=\"col-12\">\n                <div data-region=\"historyListRegion\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div data-region=\"checkImageDrawerRegion\"></div>\n</div>\n\n\n";
},"useData":true});