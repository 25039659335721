export default {
  getProps(action, props, closePopover) {
    if (action.component) {
      return {};
    }

    if (action.href) {
      return {
        href: action.href
      };
    }
    return {
      type: 'button',
      onClick: () => {
        action.handler(props);
        if (closePopover) {
          closePopover(false);
        }
      }
    };
  },

  getComponent(action) {
    if (action.component) {
      return action.component;
    }
    return action.href ? 'a' : 'button';
  }
};
