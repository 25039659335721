import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import RtgsHelper from 'app/smbPayments/util/rtgs';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { maskValue } from 'common/util/maskingUtil';
import confirmPaymentTmpl from './confirmPayment.hbs';


export default ItemView.extend({
    initialize(options) {
        this.model = options.model;
        this.shouldShowMaskToggle = options.shouldShowMaskToggle;
        this.model.set('DISPLAY_BENE_ACCOUNT', maskValue(this.model.get('BENE_ACCOUNT')));
        this.listenTo(appBus, 'toggleSwitchChanged', this.toggleAccountMasking);
    },

    /**
     * @name toggleAccountMasking
     * @description event handler for account masking toggle.
     * @param {String} fieldName
     * @param {Boolean} newState
     */
    toggleAccountMasking(fieldName, newState) {
        const val = this.model.get('BENE_ACCOUNT');
        this.model.set(
            'DISPLAY_BENE_ACCOUNT',
            (newState) ? maskValue(val) : val,
        );
        this.render();
    },


    template: confirmPaymentTmpl,

    /**
     * Get the display value for the debit account
     * @param {Object} model
     * @returns {string}
     */
    getDebitAccountText(model) {
        const text = model.get('text');
        if (util.isEmpty(text)) {
            if (model.account) {
                return model.account.get('text');
            }
            return '';
        }
        return text;
    },

    displayCutoff() {
        const hideCutoffParams = serverConfigParams.get('hideCutoff');
        const isSelectAction = (this.options.action || '').toUpperCase() === 'SELECT';
        const invalidStatus = ['EN', 'IA', 'RT', 'HV', 'RA', 'RI', 'IV', 'PI'].indexOf(this.model.get('STATUS') < 0);
        const hideCutoff = (hideCutoffParams && hideCutoffParams.indexOf(this.model.get('TYPE')) >= 0) || (isSelectAction && invalidStatus);
        // if cutoff info does not exist, dont show
        if (!this.model.get('CUTOFF_INFO')) {
            return false;
        }
        /*
         * if status is entered, incomplete approval, needs rate, or 2nd approval needed,
         * reversal awaiting approval, partial reversal awaiting approval, reversal
         * (incomplete
         * approval), partial reversal (incomplete approval)
         * or hidecutoff is defined and includes the type, do NOT show cutoff verbiage
         */
        if (hideCutoff) {
            return false;
        }
        return true;
    },

    templateHelpers() {
        let displayBeneAccount;
        if (this.model.get('DISPLAY_BENE_ACCOUNT')) {
            displayBeneAccount = this.model.get('DISPLAY_BENE_ACCOUNT');
        } else if (this.shouldShowMaskToggle) {
            displayBeneAccount = maskValue(this.model.get('BENE_ACCOUNT'));
        } else {
            displayBeneAccount = this.model.get('BENE_ACCOUNT');
        }
        return {
            isPayment: true,
            isPaymentInt: !this.model ? false : this.model.get('CONINTLWIREFLAG') === '1' && this.model.get('TYPE') === 'INTL',
            isStatus: !this.model ? false : this.model.get('STATUS') === 'AP' || this.model.get('STATUS') === 'RT' || this.model.get('STATUS') === 'TR',
            hasComments: !util.isEmpty(this.model.get('SPECIAL_INSTRUCTIONS')),
            hasPaymentDetails1: !util.isEmpty(this.model.get('OBI_TEXT_1')),
            hasPaymentDetails2: !util.isEmpty(this.model.get('OBI_TEXT_2')),
            hasPaymentDetails3: !util.isEmpty(this.model.get('OBI_TEXT_3')),
            hasPaymentDetails4: !util.isEmpty(this.model.get('OBI_TEXT_4')),
            showExchangeRate: RtgsHelper.showExchangeRate(this.model),
            contractIdIsChecked: this.model.get('contractIdIsChecked'),
            notSelectFxRateType: !this.model.get('fxRateType') && (this.options.action || '').toUpperCase() !== 'SELECT',
            displayCutoff: this.displayCutoff(),
            showRemittance: this.model ? this.model.get('REMITTANCE_INFO') : false,
            showRemittanceMethod: this.model ? this.model.get('REMITTANCE_METHOD') : false,
            showRemittanceAddress: this.model ? this.model.get('REMITTANCE_ADDRESS') : false,
            beneAccountText: displayBeneAccount,
            debitAccountText: this.getDebitAccountText(this.model),
        };
    },
});
