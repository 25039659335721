import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import userInfo from 'etc/userInfo';
import Account from '../smbAccount.userMaintenance';
import Permissions from '../../collection/smbPermissions.userMaintenance';
import Accounts from '../../collection/smbAccounts.userMaintenance';

const nullifyAssignedLimit = (limit) => {
    const limitParam = limit;
    if (limitParam && limitParam.assignedLimit === 0) {
        limitParam.assignedLimit = null;
    }
};

const checkboxFields = [
    'ALLOWACCOUNTUNMASKING',
    'CLEAROTP',
    'CLEARSECURITYQUESTIONS',
    'DISABLEUSER',
    'LEGALADMIN',
    'MOBILE',
    'OPTUISECURITYCHECK',
];

export default Model.extend({

    initialize() {
        this.isAdmin = (window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal');
    },

    defaults() {
        return {
            permissions: new Permissions(),
            transactionLimit: {
                assignedLimit: 0,
                applicable: true,
            },
            dailyLimit: {
                assignedLimit: 0,
                applicable: true,
            },
            fileLimit: {
                assignedLimit: 0,
                applicable: true,
            },
            globalSettings: {},
            bankWidgets: [],
            assignAllBankWidget: false,
        };
    },

    setupValidators() {
        this.validators = {
            USERID: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.USERID.label'),
            },
            USERGROUP: {
                exists: true,
                description: locale.get('administration.usergroup'),
            },
            PARENTUSERGROUP: {
                exists: true,
                description: locale.get('administration.parent.usergroup'),
            },
            USERNAME: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.USERNAME.label'),
            },
            FIRSTNAME: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.FIRSTNAME.label'),
            },
            LASTNAME: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.LASTNAME.label'),
            },
            EMAILADDRESS: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.EMAILADDRESS.description'),
                isEmailAddress: true,
            },
            ACTIVATIONDATE: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.ACTIVATIONDATE.label'),
                matchesDatePattern: userInfo.getDateFormat(),
            },
            LOCALE: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.LOCALE.label'),
            },
            TIMEZONEID: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.TIMEZONEID.label'),
            },
            PASSWORD: {
                exists: true,
                description: locale.get('_ADMIN.USERS._USER.*.PASSWORD.label'),
            },
            CONFIRMPASSWORD: {
                exists: true,
                sameValue: 'PASSWORD',
                otherDescription: locale.get('_ADMIN.USERS._USER.*.PASSWORD.label'),
                description: locale.get('_ADMIN.USERS._USER.*.CONFIRMPASSWORD.label'),
            },
        };
    },

    addPasswordValidators() {
        this.addValidator('PASSWORD', {
            exists: true,
            description: locale.get('_ADMIN.USERS._USER.*.PASSWORD.label'),
        });
        this.addValidator('CONFIRMPASSWORD', {
            exists: true,
            sameValue: 'PASSWORD',
            otherDescription: locale.get('_ADMIN.USERS._USER.*.PASSWORD.label'),
            description: locale.get('_ADMIN.USERS._USER.*.CONFIRMPASSWORD.label'),
        });
    },

    /**
     * This method is still referenced by the method configurePasswordValidation in
     * the file /app/administration/view/userMaintenance/smb/userCentricModal.js which in
     * in turn is referenced by the file
     * /app/administration/view/userMaintenance/smb/nonModalWrapper.js
     *
     */
    removePasswordValidators() {
        this.removeValidator('PASSWORD');
        this.removeValidator('CONFIRMPASSWORD');
    },

    addValidator(key, value) {
        if (this.validators) {
            this.validators[key] = value;
        }
    },

    removeValidator(key) {
        if (this.validators) {
            delete this.validators[key];
        }
    },

    sync(method, model, options) {
        let data = {};
        let userService;
        let currentUserGroup;

        if (model.get('PASSWORD') === '') {
            model.unset('PASSWORD');
        }
        switch (method) {
        case 'create':
            data = this.convertDataToServerJSON();
            if (model.get('mode') === 'modify') {
                userService = `${services.userCentric}insertOrUpdateSMBUserEntitlements/${model.get('mode')}`;
            } else {
                userService = `${services.userCentric}insertOrUpdateSMBUserEntitlements/insert`;
            }

            http.post(userService, data, (result) => {
                /*
                 * TODO Fix server responses
                 * In reality the server should be sending the correct response instead
                 * of the UI transposing it.  At a quick glance it seemed that
                 * the table maintenance service (the underlying base class) is
                 * controlling
                 * the response.  Because of this the UI fix was chosen.
                 * Since the server returns a 200 http response for application errors,
                 * check the response code and invoke the error function if the reesponse
                 * should be treated as an error.
                 */
                if (result.errorCode === 0) {
                    options.success(result);
                } else {
                    options.error({
                        errorCode: result.errorCode,
                        message: result.confirms?.confirmResults?.[0]?.messages,
                    });
                }
            }, (result) => {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: JSON.parse(result.responseText),
                });
            });
            model.unset('mode');
            break;

        case 'read':

            currentUserGroup = userInfo.get('group');

            if (options && options.userGroup) {
                data.userGroup = options.userGroup;
                currentUserGroup = options.userGroup;
            }

            userService = services.userCentric;
            if (options && options.userId) {
                data.updateCount = '0';
                data.userId = options.userId;

                /**
                 * If copyFromUser is passed in and true then use the operational
                 * table REST
                 * call.
                 */
                userService += options.copyFromUser ? 'getOperationalSMBUserWithEntitlements' : 'getSMBUserWithEntitlements';
            } else {
                userService += `getSMBUserEntitlementsMaintenanceModel/${currentUserGroup}`;
            }

            http.post(userService, data, (result) => {
                /**
                 * FIX: NH-30592 - Smb does NOT handle Corp UCE attributes well.
                 * This issue is
                 * no exception. The fix below filters out the _ALL_ NV pair from accounts
                 * result from getSMBUserWithEntitlements() call.  See UCE team
                 * for further
                 * details.
                 */
                const resultParam = result;
                resultParam.accounts = util.filter(resultParam.accounts, account => !util.contains(['_ALL_'], account.accountName));

                options.success(resultParam);
            }, (result) => {
                if (this.isPasswordUpdated()) {
                    this.userInfoKeys.push('PASSWORD');
                    this.userInfoKeys.push('CONFIRMPASSWORD');
                }
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: JSON.parse(result.responseText),
                });
            });
            break;

        case 'update':
            // remove password and confirmpassword if they are not set
            if (!this.isPasswordUpdated()) {
                const passwordIndex = this.userInfoKeys.indexOf('PASSWORD');
                this.userInfoKeys.splice(passwordIndex, 1);

                const confirmIndex = this.userInfoKeys.indexOf('CONFIRMPASSWORD');
                this.userInfoKeys.splice(confirmIndex, 1);
            }

            data = this.convertDataToServerJSON();
            userService = `${services.userCentric}insertOrUpdateSMBUserEntitlements/MODIFY`;

            http.post(userService, data, (result) => {
                /*
                 * TODO Fix server responses
                 * In reality the server should be sending the correct response instead
                 * of the UI transposing it.  At a quick glance it seemed that
                 * the table maintenance service (the underlying base class) is
                 * controlling
                 * the response.  Because of this the UI fix was chosen.
                 * Since the server returns a 200 http response for application errors,
                 * check the response code and invoke the error function if the reesponse
                 * should be treated as an error.
                 */
                if (result.errorCode === 0) {
                    options.success(result);
                } else {
                    options.error({
                        errorCode: result.errorCode,
                        message: result.confirms?.confirmResults?.[0]?.messages,
                    });
                }
            }, (result) => {
                if (this.isPasswordUpdated()) {
                    this.userInfoKeys.push('PASSWORD', 'CONFIRMPASSWORD');
                }
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: JSON.parse(result.responseText),
                });
            });

            break;

        case 'delete':
        case 'disable':
        case 'approve':
            data = this.convertModelAttributesToServerJSON(this);
            userService = `${services.generateUrl(options.context.serviceName)}/${method}`;

            http.post(userService, data, (result) => {
                options.success(result);
            }, (result) => {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: JSON.parse(result.responseText),
                });
            });
            break;

        default:
            break;
        }
    },

    isPasswordUpdated() {
        const password = this.get('PASSWORD');
        const confirmPassword = this.get('CONFIRMPASSWORD');

        return (password && confirmPassword && password !== '' && confirmPassword !== '');
    },

    parse(response) {
        const permissionData = (response.permissions || [])
            .filter(a => !!a)
            .map(pitem => ({
                permission: pitem.permission,
                permissionLabel: pitem.permissionLabel,
                entitled: pitem.entitled,
                description: locale.get(pitem.permissionLabel),
            }));

        const permissionsCollection = new Permissions(permissionData);

        let bankWidgetCollection = null;
        if (response.bankWidgets) {
            bankWidgetCollection = new Collection(util.map(
                response.bankWidgets,
                (bankWidget, key) => ({
                    id: key,
                    label: bankWidget,
                }),
            ));
        }

        const accounts = (response.accounts || [])
            .map(aitem => new Account({
                accountName: aitem.accountName,
                accountNumber: aitem.accountNumber,
            }));

        const accountCollection = new Accounts(accounts);

        nullifyAssignedLimit(response.transactionLimit);
        nullifyAssignedLimit(response.dailyLimit);
        nullifyAssignedLimit(response.fileLimit);

        const attributesObject = {
            permissions: permissionsCollection,
            transactionLimit: response.transactionLimit,
            dailyLimit: response.dailyLimit,
            fileLimit: response.fileLimit,
            accounts: accountCollection,
            assignAllAccount: response.assignAllAccount,
            globalSettings: response.globalSettings,
            bankWidgets: bankWidgetCollection,
            assignAllBankWidget: response.assignAllBankWidget,
            templateEntitlement: response.templateEntitlement,
            ...this.extractClientLocations(response),
        };

        /**
         * break list and alphabetize. Makes individual key additions or removals
         * much easier to view and track.
         */
        this.userInfoKeys = [
            'ACTIVATIONDATE',
            'ALLOWFXUSDUSER',
            'ALLOWACCOUNTUNMASKING',
            'CHALLENGEMETHOD',
            'CONFIRMPASSWORD',
            'DISABLEUSER',
            'EMAILADDRESS',
            'ENABLEPUSHNOTIFICATIONS',
            'FIRSTNAME',
            'LASTNAME',
            'LEGALADMIN',
            'LOCALE',
            'MOBILE',
            'MOBILEPHONENUMBER',
            'OPTUISECURITYCHECK',
            'OPTUISECURITYCHECKREASON',
            'PARENTUSERGROUP',
            'PASSWORD',
            'PHONENUMBER',
            'SSOID',
            'TIMEZONEID',
            'TOKENSERIALNUMBER',
            'USERGROUP',
            'USERID',
            'USERNAME',
        ];

        // Add userInfo
        if (response.userInfo) {
            util.each(response.userInfo.item, (userItem) => {
                const userItemParam = userItem;
                if (!util.contains(this.userInfoKeys, userItemParam.name)) {
                    this.userInfoKeys.push(userItemParam.name);
                }

                // Converting 0 or 1 value to boolean for checkboxes
                if (checkboxFields.indexOf(userItemParam.name) > -1) {
                    userItemParam.value = userItemParam.value === '1';
                }

                attributesObject[userItemParam.name] = userItemParam.value;
            });
        }

        return attributesObject;
    },

    convertDataToServerJSON() {
        const data = this.pick([
            'assignAllAccount',
            'assignAllBankWidget',
            'globalSettings',
            'fileLimit',
            'dailyLimit',
            'transactionLimit',
        ]);

        return {
            ...data,
            errorMessages: null,
            permissions: this.get('permissions').serializeCollection(),
            userInfo: this.convertModelAttributesToServerJSON(this),
            accounts: this.get('accounts').toJSON(),
            bankWidgets: this.formatWidgets(),
            modelValid: true,
            clientLocations: this.getClientLocations(),
        };
    },

    /**
     * Map the values so that each location has value1 and value2, dictated
     * by the server
     * @returns {Array}
     */
    getClientLocations() {
        if (!this.hasPermission('remoteDepositCapture')) {
            return [];
        }
        const clientLocations = this.get('clientLocations') || [];
        const loginName = this.get('loginName');
        return clientLocations.map(loc => ({
            locationName: loc.id,
            // Other vendors expect a null value for loginName
            loginId: loginName !== undefined ? loginName : null,
        }));
    },

    /**
     * Extract the locations and login names
     * @param {Object} response
     * @returns {Object}
     */
    extractClientLocations(response) {
        if (!response.clientLocations || !response.clientLocations.length) {
            return {};
        }
        const [location] = response.clientLocations;
        return {
            clientLocations: response.clientLocations.map(loc => ({
                id: loc.locationName,
            })),
            loginName: location ? location.loginId : null,
        };
    },

    /**
     * Maps the selected widgets in a map of ids to labels
     * @returns {Array} - selected map of id->name
     */
    formatWidgets() {
        return util.reduce(this.get('bankWidgets').models, (widgets, obj) => {
            const bankWidgets = widgets;
            bankWidgets[obj.get('id')] = obj.get('name');
            return bankWidgets;
        }, {});
    },

    sanitizeValues(key, value) {
        if (!checkboxFields.includes(key)) {
            return value;
        }
        return (value && value !== '0') ? '1' : '0';
    },

    convertModelAttributesToServerJSON(model) {
        const jsonData = model.toJSON();
        const item = this.userInfoKeys.map(name => ({
            name,
            value: this.sanitizeValues(name, jsonData[name]),
        }));

        return {
            item,
        };
    },

    /**
     * Check to see if model has permission
     * @param {string} permission
     * @returns {boolean}
     */
    hasPermission(permission) {
        return this.get('permissions').some(model => model.get('permission') === permission && model.get('entitled') === true);
    },
});
