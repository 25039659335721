import Collection from '@glu/core/src/collection';
import CompositeView from '@glu/core/src/compositeView';
import ItemView from '@glu/core/src/itemView';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import LocationLoginItemView from './locationLoginItemView';
import emptyLocationLoginViewTmpl from './emptyLocationLoginView.hbs';
import locationLoginViewTmpl from './locationLoginView.hbs';

const EmptyView = ItemView.extend({
    template: emptyLocationLoginViewTmpl,
});

export default CompositeView.extend({
    template: locationLoginViewTmpl,
    itemView: LocationLoginItemView,
    itemViewContainer: '[data-hook="getLoginCollectionContainer"]',
    emptyView: EmptyView,
    collectionEvents: {
        sync: 'render',
    },

    ui: {
        $addBtn: '[data-hook="getAddItemBtn"]',
    },

    events: {
        'click @ui.$addBtn': 'add',
    },

    itemViewOptions() {
        return {
            parentView: this,
            mode: this.options.mode,
            locationOptions: this.locationData.map(location => ({
                id: location.ACCOUNTFILTER,
                name: location.ACCOUNTDISPLAY,
            })),
            locationSelectCallback: this.onItemLocationSelect.bind(this),
        };
    },

    initialize(options) {
        this.locationData = options.data;

        // parse use existing user selections before defaulting to server loaded selections
        this.model = this.createSelectedModel((options.collection)
            ? options.collection.toJSON() : options.data);
        this.collection = options.collection || this.createLoginsCollection(options.data);

        // listener for when a new item is added
        this.listenTo(this.collection, 'add remove', this.toggleAddBtn);
    },

    onRender() {
        this.delegateEvents();
        if (this.options.mode !== 'view') {
            this.toggleAddBtn();
        }
    },

    /**
     * @param {array} locationData
     * @return {Model} Will create a model with all location data options, will be used as
     * a reference to track what options are available to the
     * user vs. what locations they have chosen
     * There is functionality where the user's available selections
     * for each additional location and login will only show what has not been selected.
     */
    createSelectedModel(locationData) {
        const selected = locationData
            .filter(location => location.LOGIN)
            .map(location => location.ACCOUNTFILTER);

        return new Model({ selected });
    },

    /**
     *
     * @param {array} locationData
     * @return {Collection} creates initial Collection from location data passed
     */
    createLoginsCollection(locationData) {
        const isViewMode = (this.options.mode === 'view');
        const initialSelections = util
            .chain(locationData)
            // only populate with filled out values if not in view mode
            .map(location => ((location.LOGIN) ? location : null))
            .compact()
            .value();

        if (!isViewMode && (initialSelections.length < 1)) {
            initialSelections.push({
                ACCOUNTFILTER: null,
                LOGIN: null,
            });
        }

        return new Collection(initialSelections, {
            model: Model,
        });
    },

    /**
     * Callback passed to and called when an ItemView makes a location selection
     */
    onItemLocationSelect() {
        this.model.set('selected', this.collection.models.map(model => model.get('ACCOUNTFILTER')));
    },

    /**
     * Prevent adding more items than there are options available
     */
    toggleAddBtn() {
        const toggleDisable = (this.collection.length === this.locationData.length);
        this.ui.$addBtn.toggleClass('disabled', toggleDisable);
    },

    /**
     * Adds a new total item to this view.
     * Note this does not re-render the view.  Render should be called explicitly.
     */
    add() {
        this.collection.add({
            ACCOUNTFILTER: '',
            LOGIN: '',
        });
    },

    /**
     * @return {Object}
     * return a json object with key data pairs of all valid logins with
     * their associated locations
     */
    getLoginJSONData() {
        return util.chain(this.collection.models)
            .map((model) => {
                if (!model.isValid() || !model.get('LOGIN')) {
                    model.clear({
                        silent: true,
                    });
                    return null;
                }
                return model.toJSON();
            })
            .compact()
            .reduce((arr, item) => ({ [item.ACCOUNTFILTER]: item.LOGIN, ...arr }), {})
            .value();
    },

    /**
     * @method extractData
     * @return {array}
     * Callback called on Account Assignment Views on Close
     * (will send out data to alter user's data entitlements)
     */
    extractData() {
        const loginData = this.getLoginJSONData();

        return this.locationData.map((location) => {
            this.runProxySave(location, loginData[location.ACCOUNTFILTER] || '');
            return Object.assign(util.clone(location), {
                LOGIN: loginData[location.ACCOUNTFILTER] || '',
            });
        });
    },

    /**
     *
     * @param {object} location
     * @param {string} newLogin
     * Will create the values needed for the collectionProxy used by Step 3 grids
     * and passes them to the proxy update method to save the user's changes to
     * data entitlements for this step
     */
    runProxySave(location, newLogin) {
        /**
         * issue with saving different member ids for different location-login pairs
         * it is hard to clear a pair and remove it if the member id's are going to be
         * different all the time
         * save the login name as an attached property to be attached afterwards
         */
        const columnData = this.options.columnTypeData[0];
        const members = location.ACCOUNTFILTER;
        // if login is filled out and different than original, mark as checked
        const currentVal = ((location.LOGIN !== newLogin) && newLogin) ? '1' : '0';
        // if original has login and login different than new login, mark as checked
        const origVal = ((location.LOGIN !== newLogin) && (currentVal === '0')) ? '1' : '0';

        this.options.proxySave(columnData.id, members, currentVal, origVal, {
            LOGIN: newLogin,
        });
    },

    templateHelpers() {
        return {
            notViewMode: this.options.mode !== 'view',
        };
    },
});
