import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';

export default Collection.extend({
    sync(method, model, options) {
        const data = {
            IncludeMapData: 1,

            queryCriteria: {
                inquiryId: '19028',
                queryType: 'RTGSTemplatesQuery',

                action: {
                    typeCode: 'TRANSFER',
                    entryMethod: 0,
                    productCode: 'RTGS',
                    actionMode: 'INSERT',
                    functionCode: 'INST',
                },
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: 0,
                requestId: 0,
            },

            formatterType: 'Query',
        };

        return http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        return util.map(response, (rowItem) => {
            let localRowItem = rowItem;
            localRowItem.id = localRowItem.name;
            localRowItem.text = localRowItem.label;
            if (localRowItem.name !== localRowItem.label) {
                localRowItem.text += ` - ${localRowItem.name}`;
            }

            localRowItem = util.extend(localRowItem, transform.pairsToHash(localRowItem.mapDataList, 'toField', 'value'));

            return localRowItem;
        });
    },
});
