/**
 * main view for a report group
 *
 * @model - simpleTypeGroup
 */
import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import Entitlements from 'app/administration/collection/user2/entitlements';
import EntitlementCollectionView from './entitlementCollectionView';
import reportGroupViewTmpl from './reportGroupView.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default Layout.extend({
    template: reportGroupViewTmpl,
    className: 'report-group',

    ui: {
        selectAllBtn: '.select-all-reports-group',
        selectReport: '[data-hook="report-checkbox"]',
    },

    events: {
        'click @ui.selectAllBtn': 'selectAllReports',
        'click @ui.selectReport': 'selectReport',
    },

    initialize(opts) {
        this.mode = opts.mode;

        if (this.model && this.model.id && this.model.id.toUpperCase() === 'PAYMENTAUTOMATION') {
            this.entitlements = new Entitlements(this.model.get('types').map((type) => {
                const entitlement = type.get('entitlements').at(0);
                entitlement.label = locale.get(type.get('label'));
                return entitlement;
            }));
        } else {
            this.entitlements = this.model.get('types').at(0).get('entitlements');
        }

        if (this.mode === constants.MODES.VIEW) {
            this.entitlements.reset(this.entitlements.filter((entitlement) => {
                const actions = entitlement.get('actions');
                const productcode = entitlement.get('productCode');
                if (productcode === 'PA') {
                    return true;
                }
                return util.has(actions, 'manage') ? actions.manage.entitled : actions.view.entitled;
            }));
        }
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    // manually data bind
    selectReport(evt) {
        this.entitlements.get(this.$(evt.target).data('id')).set({
            'actions.view.entitled': evt.target.checked,
            'actions.manage.entitled': evt.target.checked,
        });
        this.updateSelectAllState();
    },

    onRender() {
        if (this.model && this.model.id && this.model.id.toUpperCase() === 'PAYMENTAUTOMATION') {
            this.entitlementsCollectionView = new EntitlementCollectionView({
                collection: this.entitlements,
                mode: this.options.mode,
            });

            this.permissionsRegion.show(this.entitlementsCollectionView);
        }
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList(), this.ui.selectAllBtn);
        this.options.onChange?.();
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el.find('[data-hook="report-checkbox"], .limit-type-entitlements input[type="checkbox"]');
    },

    selectAllReports(evt) {
        const self = this;

        this.entitlements.each((entitlement) => {
            const { checked } = evt.currentTarget;

            if (this.model && this.model.id && this.model.id.toUpperCase() === 'PAYMENTAUTOMATION') {
                entitlement.set('actions.DLOAD.entitled', checked);
                entitlement.set('actions.SELECT.entitled', checked);
            } else {
                entitlement.set('actions.view.entitled', checked);
                entitlement.set('actions.manage.entitled', checked);
            }
            self.$(`#report-entitlement-${entitlement.cid}`).prop('checked', checked);
        });
    },

    templateHelpers() {
        return {
            rows: this.entitlements.chain()
                .map((rec) => {
                    const actions = rec.get('actions');
                    const productCode = rec.attributes ? rec.attributes.productCode : '';
                    let returnValue;
                    if (productCode === 'PA') {
                        returnValue = true;
                    } else {
                        returnValue = util.has(actions, 'manage') ? actions.manage.entitled : actions.view.entitled;
                    }
                    return {
                        typeCode: rec.get('typeCode'),
                        entitled: returnValue,
                        cid: rec.cid,
                    };
                })
                .groupBy((rec, i) => Math.floor(i / 3))
                .values()
                .value(),

            reportGroup: () => locale.get(`uce.reportGroup.${this.model.get('id')}`),

            entitlementLabel(typeCode) {
                return locale.get(`type.${typeCode}`);
            },

            readOnly: this.mode === constants.MODES.VIEW,
            cid: () => this.model.cid,
            isPA: this.model?.id?.toUpperCase() === 'PAYMENTAUTOMATION',
        };
    },
});
