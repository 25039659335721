import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import locale from '@glu/locale';
import { appBus } from '@glu/core';
import Dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import API from 'app/smb/api';
import Constants from 'app/smbPayments/constants';
import NewPaymentModal from 'app/smbPayments/views/modals/payment';
import ConfirmPaymentModal from 'app/smbPayments/views/modals/confirmPayment';
import DuplicatePaymentModal from 'app/smbPayments/views/modals/duplicatePayment';
import PaymentTotal from 'app/smbPayments/views/modals/paymentTotal';
import Payment from 'app/smbPayments/models/payment';
import AuditModel from 'app/smbPayments/models/auditHistory';
import errHandler from 'system/errHandler';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import scroll from 'common/util/scroll';
import FedwireModel from 'app/smbPayments/models/fedwire';
import IntlModel from 'app/smbPayments/models/intl';
import RTPModel from 'app/smbPayments/models/crtran';
import ContactLookupAccounts from 'app/smbPayments/collections/contactLookupAccounts';
import CorporateChildModel from 'app/smbPayments/models/corporatePaymentChild';
import CorporateChildResetModel from 'app/smbPayments/models/corporatePaymentChildReset';
import ConsumerOrCorporateModel from 'app/smbPayments/models/consumerOrCorporatePayment';
import ConsumerChildModel from 'app/smbPayments/models/consumerPaymentChild';
import DynamicReportUtil from 'app/reports/views/dynamicReports';
import ConsumerChildResetModel from 'app/smbPayments/models/consumerPaymentChildReset';
import RtgsHelper from 'app/smbPayments/util/rtgs';
import dateUtil from 'common/util/dateUtil';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import validatorPatterns from 'system/validatorPatterns';
import paymentUtil from 'common/util/paymentUtil';
import accountUtil from 'app/smbPayments/util/accountUtil';
import FromAccountList from 'app/smbPayments/collections/fromAccountList';
import Model from '@glu/core/src/model';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { maskValue } from 'common/util/maskingUtil';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import mobileUtil from 'mobile/util/mobileUtil';
import { createStatefulSwitchWrapperView } from 'components/StatefulSwitch/StatefulSwitchWrapper';
import MaskToggleUtil from 'common/util/maskToggleUtil';
import combinedPaymentTmpl from './combinedPayment.hbs';

export default Layout.extend({
    template: combinedPaymentTmpl,
    loadingTemplate: loadingModalTmpl,

    initialize(options) {
        this.options = options;
        this.action = this.options.action || null;
        this.contextKey = options.contextKey;
        this.fromDetailView = options.fromDetailView;
        this.fromRTPWorkspace = options.fromRTPWorkspace;
        this.createdFromRFP = this.model && this.model.get('RFP_MSG_ID');
        this.isFromDetailScreen = options.isFromDetailScreen;
        this.returnLinkAfterAction = options.returnLinkAfterAction;

        this.remittanceGroup = 'remittanceGroup';
        this.remittanceGroupFields = ['REMITTANCE_ADDRESS', 'REMITTANCE_METHOD'];
        this.shouldRequire = {
            remittanceGroup: false,
        };
        this.dialogTitle = ' ';

        this.modalClass = 'modal-xlg';
        this.shouldShowMaskToggle = false;
        if (this.action === 'select') {
            ({ shouldShowMaskToggle: this.shouldShowMaskToggle }
                = MaskToggleUtil.initMaskToggle());
        }
    },

    ui: {
        $payeeSelect: '[data-hook="payee-select"]',
        $payeeAccountSelect: '[data-hook="payee-account-select"]',
        $paymentTypeSelect: '[data-hook="payment-type-select"]',
        $payeeSection: '[data-hook="payee-section"]',
        $confirmSection: '[data-hook="confirm-section"]',
        $duplicatePaymentSection: '[data-hook="duplicate-payment-section"]',
        $createSection: '[data-hook="create-section"]',
        $remittance: '[data-hook="remittance-text"]',
        $remittanceMethod: '[data-hook="remittance-method"]',
        $remittanceAddress: '[data-hook="remittance-address"]',
        $paymentDetails: '[data-hook="payment-details"]',
        $toggleSwitchRegion: '[data-region="toggleSwitchRegion"]',
    },

    events: {
        'keyup @ui.$remittance': 'showCountCharacters',
        'blur @ui.$remittanceAddress': 'setRemittanceAddress',
        'change @ui.$remittanceMethod': 'setRemittanceMethod',
    },

    regions: {
        Totals: '[data-hook="totals-region"]',
    },

    paymentIdMap: {
        FEDWIRE: 'RTGS.ID',
        BDACHCVP: 'achcomp.id',
        BDACHCP: 'achcomp.id',
        INTL: 'RTGS.ID',
        BDACHCEC: 'achcomp.id',
        BDACHCRC: 'achcomp.id',
        CRTRAN: 'RTP.ID',
    },

    smbTypeDescriptionKeys: {
        'RTGS.type.fedwire': 'SMBPAY.ExpFedWire',
        'RTGS.type.intl': 'SMBPAY.ExpIntlWire',
        'ACH.corp_ven_pay': 'SMBPAY.StdPaymentsACH',
        'ACH.con_pay': 'SMBPAY.StdPaymentsACH',
        'ACH.payroll': 'SMBPAY.StdPaymentsACH',
        'ACH.corp_col': 'SMBPAY.StdCollectionsACH',
        'ACH.con_col': 'SMBPAY.StdCollectionsACH',
        'RTP.type.crtran': 'RTP.type.crtran',
    },

    beneAccountFields: [
        'BENEBOOKCHILD_ID',
        'BENEBOOKUPDATECOUNT__',
        'BENEBOOK_ID',
        'BENE_ACCOUNT',
        'BENE_ACCOUNTTYPE',
        'BENE_ACCOUNT_CURRENCY',
        'BENE_ADDRESS_1',
        'BENE_ADDRESS_2',
        'BENE_BANK_ADDRESS_',
        'BENE_BANK_ADDRESS_2',
        'BENE_BANK_ADDRESS_3',
        'BENE_BANK_CITY',
        'BENE_BANK_COUNTRY',
        'BENE_BANK_ID',
        'BENE_BANK_IDTYPE',
        'BENE_BANK_NAME',
        'BENE_BANK_PROVINCE',
        'BENE_BANK_STATE',
        'BENE_CITY',
        'BENE_COUNTRY',
        'BENE_NAME',
        'BENE_REFERENCE',
        'CORRBANKIDENTRYMETHOD',
        'CORRESPONDENT_ADDRESS_1',
        'CORRESPONDENT_ADDRESS_2',
        'CORRESPONDENT_ADDRESS_3',
        'CORRESPONDENT_COUNTRY',
        'CORRESPONDENT_ID',
        'CORRESPONDENT_NAME',
        'CORRESPONDENT_STATE',
        'CORRESPONDENT_TYPE',
        'CORR_BANK_ACCOUNT_NUMBER',
        'INTERMEDIARY_ADDRESS_1',
        'INTERMEDIARY_ADDRESS_2',
        'INTERMEDIARY_ADDRESS_3',
        'INTERMEDIARY_COUNTRY',
        'INTERMEDIARY_ID',
        'INTERMEDIARY_IDTYPE',
        'INTERMEDIARY_NAME',
        'INTERMEDIARY_STATE',
        'KEY_FIELD',
        'ORIGACCOUNTLIST_INQUIRYID',
        'PAYMENTCLEARINGSYSTEM',
        'PAYMENTFUNCTIONCODE',
        'PAYMENTPRODUCTCODE',
        'PAYMENTTYPE',
        'PAYMENT_DELIVERYMETHOD',
        'PAYMENT_GRIDNAME',
        'RESTSERVICENAME',
        'TYPE_DESCRIPTION',
    ],

    paymentDetailsFields: [
        'OBI_TEXT_1',
        'OBI_TEXT_2',
        'OBI_TEXT_3',
        'OBI_TEXT_4',
    ],

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.shouldShowMaskToggle) {
                const ToggleMaskView = createStatefulSwitchWrapperView({
                    name: 'maskToggleSMB',
                    htmlId: 'maskToggleSMB',
                    targetField: 'BENE_ACCOUNT',
                    labelOnLeft: true,
                    labelText: locale.get('common.maskAcctNum'),
                    show: true,
                    defaultValue: true,
                });
                this.toggleSwitchRegion?.show?.(new ToggleMaskView());
            }
            this.renderBeneContent();

            if (this.isSelect()) {
                this.showConfirmPayment();
            } else {
                this.showCreatePayment();
            }

            this.Totals.show(this.totalsView);

            if (serverConfigParams.get('showPaymentDetailsForBasic') === 'true') {
                this.listenTo(this.ui.$paymentTypeSelect, 'selectionChanged', this.showPaymentDetails());
                this.showPaymentDetails();
            } else if (this.options.action !== 'select') {
                this.clearPaymentDetails();
            }
        } else {
            this.loadRequiredData(this.options);
        }
    },

    loadRequiredData(options) {
        const self = this;

        const paymentTypes = API.paymentTypes.get();
        const beneListPromise = this.getBeneListPromise();

        let modelPromise;

        if (options.model) {
            // SMB only uses '0' freeform entry method
            this.model.set('entryMethodName', Constants.ENTRYMETHODNAME);

            const attributes = {
                TNUM: options.model.get('TNUM'),
                UPDATECOUNT__: options.model.get('UPDATECOUNT__'),
                TYPE: options.model.get('TYPE'),
                entryMethodName: this.model.get('entryMethodName'),
                total: Formatter.formatCurrency(this.model.get('CMB_CREDIT_AMOUNT')),
            };

            modelPromise = new Promise((resolve, reject) => {
                const model = new Payment(attributes);
                model.fetch({
                    success() {
                        resolve(model);
                    },

                    error: reject,
                });
            });
        } else {
            modelPromise = Promise.resolve(new Payment());
        }

        Promise.all([paymentTypes, beneListPromise, modelPromise])
            .then((results) => {
                let indicativeRateNeeded = false;
                [
                    self.paymentTypeCollection,
                    self.beneList,
                    self.model,
                ] = results;
                if (self.options.isCopyPayment) {
                    // clear fields indicating this is an existing payment
                    self.model.id = null;
                    self.model.set({
                        TNUM: '',
                        UPDATECOUNT__: '',
                        STATUS: '',
                    });
                    // copied payments count as modified to allow submitting
                    self.modelChanged = true;
                    // contractID and exchange rate should not be copied
                    if (self.model.get('EXCHANGE_RATE_CONTRACTID')) {
                        // contractID and exchange rate should not be copied
                        self.model.set({
                            EXCHANGE_RATE: '',
                            EXCHANGE_RATE_CONTRACTID: '',
                            DEBIT_AMOUNT: '',
                        });
                        /*
                         * will need to retrieve the indicative rate after the model is
                         * done being set up
                         */
                        indicativeRateNeeded = true;
                    }
                }
                /*
                 * Let get rate service pull rate regardless of company fx rate type
                 * rate service will apply correct rate based on entitlements
                 */
                if ((self.model.get('INDICATIVERATE') || self.model.get('EXCHANGE_RATE')) && self.action !== 'select') {
                    indicativeRateNeeded = true;
                }

                self.entryView = self.getEntryView();
                self.entryView.options.action = self.action;
                self.totalsView = self.getTotalsView();
                self.totalsView.options.action = self.action;

                // if an existing payment, set the beneficiary
                if (self.options.model) {
                    self.model.bene = self.getBene();
                    self.setAttributesOnExistingModel();
                    self.entryView.paymentTypeChanged();
                    /*
                     * NH-182538 This check needs to occur after the paymentType changes
                     * as changing the payment type clears the contractId. We don't want
                     * to do this on initial setup.
                     */
                    if (this.model.get('EXCHANGE_RATE_CONTRACTID')) {
                        this.entryView.contractIdCheck();
                    }
                }

                if (indicativeRateNeeded) {
                    self.entryView.indicativeRateInputsChanged();
                }

                /*
                 * modelEvents not working as expected.
                 * set up listener here once we have the model
                 */
                self.listenTo(self.model, 'change', self.modelAttributeChanged);
            })
            .then(this.getFromAccounts.bind(this))
            .then(this.setSelectedFromAccount.bind(this))
            .then(() => {
                // if view mode
                if (self.action === 'select') {
                    return self.showAuditHistory();
                }

                return Promise.resolve();
            })
            .then((auditModelData) => {
                if (auditModelData && auditModelData.paymentAuditHistory) {
                    self.auditModel = auditModelData.paymentAuditHistory;
                }

                self.confirmView = self.getConfirmView();

                self.setHasLoadedRequiredData(true);
                self.render();

                return auditModelData;
            })
            .then(null, errHandler);
    },

    /**
     * Get a list of from account based on the bene account selected
     * @returns {Promise}
     */
    getFromAccounts() {
        if (this.options.model && this.model.beneAccount) {
            return new Promise((resolve, reject) => {
                const options = accountUtil.getFromAccountOpts(this.model.beneAccount);
                const fromAccounts = new FromAccountList(options);
                fromAccounts.fetch({
                    success: () => {
                        resolve(fromAccounts);
                    },
                    error: (data) => {
                        reject(data);
                    },
                });
            });
        }
        return Promise.resolve({});
    },

    /**
     * Set the the account property on the model with the selected from account
     * @param {Object} fromAccounts - a collection contains all of the from accounts
     */
    setSelectedFromAccount(fromAccounts = {}) {
        if (fromAccounts.models) {
            this.model.account = fromAccounts.models.find(account => account.get('id') === this.model.get('ACCOUNTFILTER'));
        }
    },

    /**
     * Mask the account number
     * @param {string} val
     */
    maskAccount(val) {
        return maskValue(val);
    },

    /**
     * determine whether or not to show the "view combined disclosure" link
     *
     * @param {String} conIntWireFlag - The flag to indicate if this is a consumer international
     * wire payment
     * @param {String} modelType - The "type" on the model object
     * @param {String} action - the current form action
     * @returns {Boolean} - whether or not to show the "view combined disclosure" link
     */
    shouldShowCombinedDisclosure(conIntlWireFlag, modelType, action) {
        const isInternationalPayment = conIntlWireFlag === '1' && modelType === 'INTL';
        return isInternationalPayment && !this.isCopyAction() && action !== 'modify';
    },

    templateHelpers() {
        const self = this;

        return {
            isPayment: this.isPayment(),
            shouldShowCombinedDisclosure: this.shouldShowCombinedDisclosure(
                this.model?.get('CONINTLWIREFLAG'),
                this.model?.get('TYPE'),
                this.action,
            ),
            payeeText: locale.get('SMBPAY.PayeeName'),

            payeeList() {
                return self.beneList.toJSON();
            },

            accountText: locale.get('SMBPAY.PayeeAccount'),

            accountList() {
                const beneAccountList = self.getBeneAccountList();
                return beneAccountList ? beneAccountList.toJSON() : [];
            },
            maskAccount: this.maskAccount,
            accountNumberList() {
                return self.getBeneAccountNumbers();
            },

            deliveryMethodText: locale.get('SMBPAY.PaymentMethod'),

            deliveryMethods() {
                return self.getDeliveryMethods();
            },

            hasAuditModel: this.auditModel && this.action === 'select',
            auditModelData: this.auditModel !== undefined ? this.auditModel : null,
            payeeNotApproved: this.entryView && this.entryView.payeeNotApproved,
            isRTPPayment: (this.model ? this.model.get('PAYMENTTYPE') === 'CRTRAN' : false) || this.fromRTPWorkspace,
            remittanceMaxSize: Constants.REAL_TIME_REMITTANCE_MAX_LENGTH,
            fromRTPWorkspace: this.fromRTPWorkspace,
            isExistingTransaction: this.isExistingTransaction(),
            typeDescription: this.model ? this.getTypeDescription(this.model.get('CMB_TYPE_DESCRIPTION')) : '',
            hasPaymentDetails: serverConfigParams.get('showPaymentDetailsForBasic') === 'true',
        };
    },

    isPayment() {
        return true;
    },

    isCopyAction() {
        return this.options.action === 'copyinst';
    },

    getEntryView() {
        return new NewPaymentModal({
            model: this.model,
            paymentTypeCollection: this.paymentTypeCollection,
            fromRTPWorkspace: this.fromRTPWorkspace,
        });
    },

    getConfirmView() {
        return new ConfirmPaymentModal({
            model: this.model,
            action: this.options.action,
            shouldShowMaskToggle: this.shouldShowMaskToggle,
        });
    },

    /**
     * Determine if the first detail line is required
     * @returns {boolean}
     */
    isFirstDetailRequired() {
        return serverConfigParams.get('WireDomesticMultiEntryDetailOneRequired') === 'true';
    },

    /**
     * if either REMITTANCE_ADDRESS or REMITTANCE_METHOD is entered, make them both required
     * if REMITTANCE_METHOD is email, validate that REMITTANCE_ADDRESS is valid email address
     */
    toggleRemittanceMandatory() {
        this.model.removeValidatorProp('REMITTANCE_ADDRESS', 'matches');
        this.model.removeValidatorProp('REMITTANCE_ADDRESS', 'overrideError');
        paymentUtil.toggleFieldsMandatory(
            this.remittanceGroup,
            this.remittanceGroupFields, false, this,
        );
        if (this.model.get('REMITTANCE_METHOD') === 'EMAL') {
            this.model.addValidatorProp('REMITTANCE_ADDRESS', 'matches', validatorPatterns.EMAIL_PATTERN);
            this.model.addValidatorProp('REMITTANCE_ADDRESS', 'description', locale.get('RTP.INST.CRTRAN.*.REMITTANCE_ADDRESS'));
            this.model.addValidatorProp('REMITTANCE_ADDRESS', 'overrideError', 'emailAddress');
        }
    },

    setRemittanceMethod() {
        this.model.set('REMITTANCE_METHOD', this.ui.$remittanceMethod.val());
        this.toggleRemittanceMandatory();
        this.model.validateField('REMITTANCE_METHOD');
    },

    setRemittanceAddress() {
        this.model.set('REMITTANCE_ADDRESS', this.ui.$remittanceAddress.val());
        this.toggleRemittanceMandatory();
        this.model.validateField('REMITTANCE_ADDRESS');
    },

    /**
     * @param {array} duplicatePaymentData (array of duplicate payments and their
     * data from the server)
     * Get the duplicate payment view to be displayed in the duplicate payment
     * section if a duplicate warning is received on submit
     */
    getDuplicatePaymentView(duplicatePaymentData) {
        return new DuplicatePaymentModal({
            model: this.model,
            duplicatePaymentData,
        });
    },

    getTotalsView() {
        return new PaymentTotal({
            model: this.model,
            totalText: this.getTotalText(),
        });
    },

    getTotalText() {
        return locale.get('sbPayments.paymentTotal');
    },

    /*
     * Get the payments beneficiary using the BENEBOOK_ID value.
     * @param {String} contactTnum (tnum value for the contact being used in
     * the payment)
     * @param {Object} beneList (list of possible beneficiaries to search in for
     * the one being used)
     */
    getBeneUsingContactTnum(contactTnum = this.model.get('BENEBOOK_ID'), beneList = this.beneList) {
        return beneList.find(bene => bene.attributes.accountList.models[0].get('BENEBOOK_ID') === contactTnum);
    },

    /*
     * Get the payments beneficiary account using the BENEBOOKCHILD_ID value.
     * @param {Object} selectedBene (The model of the bene being used in the payment)
     * @param {String} contactChildTnum (tnum of the contact child account being
     * used in the payment)
     */
    getBeneAccountUsingContactTnum(selectedBene, contactChildTnum = this.model.get('BENEBOOKCHILD_ID')) {
        if (!selectedBene) {
            return undefined;
        }

        return selectedBene.get('accountList')
            .find(account => account.get('BENEBOOKCHILD_ID') === contactChildTnum);
    },

    // helper method to get the beneficiary object
    getBene(beneName, beneList) {
        let localBeneName = beneName;
        let localBeneList = beneList;
        localBeneName = localBeneName || this.model.get('BENE_NAME');
        localBeneList = localBeneList || this.beneList;
        return localBeneList.find(bene => bene.get('BENE_NAME') === localBeneName);
    },

    // helper method to get the account list for a given beneficiary
    getBeneAccountList(beneName, beneList) {
        const selectedBene = this.getBene(beneName, beneList);
        return selectedBene ? selectedBene.get('accountList') : null;
    },

    // helper method to get the beneficiary account object selected
    getBeneAccount(beneName, beneAccountNumber, paymentType, beneList) {
        let localBeneName = beneName;
        let localBeneAccountNumber = beneAccountNumber;
        let localPaymentType = paymentType;
        localBeneName = localBeneName || this.model.get('BENE_NAME');
        localBeneAccountNumber = localBeneAccountNumber || this.model.get('BENE_ACCOUNT');
        localPaymentType = localPaymentType || this.model.get('PAYMENTTYPE');
        const beneAccountList = this.getBeneAccountList(localBeneName, beneList);
        return beneAccountList.find(account => account.get('BENE_ACCOUNT') === localBeneAccountNumber && account.get('PAYMENTTYPE') === localPaymentType);
    },

    getBeneAccountNumbers() {
        const beneAccountList = this.getBeneAccountList();
        let accountNumbers = [];
        if (beneAccountList) {
            accountNumbers = beneAccountList.map(account => account.get('BENE_ACCOUNT'));
        }
        return util.uniq(accountNumbers);
    },

    getDeliveryMethods(beneAccountNumber) {
        let localBeneAccountNumber = beneAccountNumber;
        localBeneAccountNumber = localBeneAccountNumber || this.model.get('BENE_ACCOUNT');
        const beneAccountList = this.getBeneAccountList();
        let deliveryMethods = [];
        if (beneAccountList) {
            const accountsWithAccountNumber = beneAccountList.filter(account => account.get('BENE_ACCOUNT') === localBeneAccountNumber);
            if (accountsWithAccountNumber) {
                deliveryMethods = accountsWithAccountNumber.map(account => ({
                    paymentType: account.get('PAYMENTTYPE'),
                    typeDescription: account.get('TYPE_DESCRIPTION'),
                }));
            }
        }
        return util.uniq(deliveryMethods, item => item.paymentType);
    },

    setAttributesOnExistingModel() {
        const beneAccountList = this.getBeneAccountList(this.model.get('BENE_NAME'), this.beneList);
        const paymentType = this.model.get('TYPE');
        this.model.set('PAYMENTTYPE', paymentType);
        if (Constants.isACH(paymentType)) {
            /*
             * Credit currency isn't always set, even for credits like ACH payments.
             * Set it according to the debit currency, which should always be the same for ACH.
             */
            this.model.set({
                BENE_BANK_ID: this.model.get('CMB_BENE_BANK_ID'),
                BENE_ACCOUNT: this.model.get('CMB_BENE_ACCOUNT'),
                CREDIT_CURRENCY: this.model.get('CMB_CREDIT_CURRENCY') ? this.model.get('CMB_CREDIT_CURRENCY') : this.model.get('CMB_DEBIT_CURRENCY'),
                DEBIT_ACCOUNT_NUMBER: this.model.get('ACCOUNTFILTER'),
            }, {
                silent: true,
            });
        } else if (!this.model.get('DEBIT_ACCOUNT_NUMBER')) {
            this.model.set('DEBIT_ACCOUNT_NUMBER', this.model.get('CMB_DEBIT_ACCOUNT_NUMBER'));
        }
        const searchAttrs = {
            BENE_BANK_ID: this.model.get('BENE_BANK_ID'),
            BENE_ACCOUNT: this.model.get('BENE_ACCOUNT'),
            PAYMENTTYPE: paymentType,
        };

        // check that the beneficiary still has accounts
        if (beneAccountList) {
            const beneAccount = beneAccountList.findWhere(searchAttrs);
            /*
             * check if bene account exists.  It might have been removed for an existing
             * transaction
             */
            if (beneAccount) {
                this.model.beneAccount = beneAccount;
                this.model.set({
                    PAYMENTPRODUCTCODE: beneAccount.get('PAYMENTPRODUCTCODE'),
                    PAYMENTFUNCTIONCODE: beneAccount.get('PAYMENTFUNCTIONCODE'),
                    PAYMENTCLEARINGSYSTEM: beneAccount.get('PAYMENTCLEARINGSYSTEM'),
                    TYPE_DESCRIPTION: beneAccount.get('TYPE_DESCRIPTION'),
                }, {
                    silent: true,
                });
            } else {
                this.model.set({
                    PAYMENTPRODUCTCODE: this.model.get('PRODUCT'),
                    PAYMENTFUNCTIONCODE: this.model.get('FUNCTION'),
                    PAYMENTCLEARINGSYSTEM: this.model.get('SUBTYPE'),
                    TYPE_DESCRIPTION: this.model.get('TYPE_DESCRIPTION'),
                }, {
                    silent: true,
                });
            }
        } else {
            /*
             * if beneAccountList is null, the beneficiary was deleted from contact center.
             * Payee name, Payee Account and Payee Method can't be populated.
             * We need to populate values using values we already have in the model
             * instead of from the bene account.
             * If we don't have these values set the payment will not display correctly.
             */
            this.model.set({
                PAYMENTPRODUCTCODE: this.model.get('PRODUCT'),
                PAYMENTFUNCTIONCODE: this.model.get('FUNCTION'),
                PAYMENTCLEARINGSYSTEM: this.model.get('SUBTYPE'),
                TYPE_DESCRIPTION: this.getTypeDescription(this.model.get('TYPE_DESCRIPTION')),
            }, {
                silent: true,
            });
        }
    },

    modelAttributeChanged(model) {
        this.modelChanged = true;
        if (util.has(model.changed, 'BENE_NAME')) {
            /*
             * wipe out the existing bene account selection which renders, or just render
             * to get the new accounts
             */
            if (this.model.get('BENE_ACCOUNT')) {
                this.model.set({
                    BENE_ACCOUNT: '',
                    CREDIT_AMOUNT: '',
                });
            } else {
                this.render();
            }
        }
        /*
         * we won't usually have a DEBIT_AMOUNT, but if credit amount
         * becomes falsy, wipe out DEBIT_AMOUNT
         */
        if (util.has(model.changed, 'CREDIT_AMOUNT') && !model.get('CREDIT_AMOUNT')) {
            this.model.set('DEBIT_AMOUNT', '');
        }
        if (util.has(model.changed, 'BENE_ACCOUNT')) {
            if (this.fromRTPWorkspace) {
                /*
                 *  If BENE_ACCOUNT has changed and the payment is initiated from the RTP widget
                 *  we need to either initiate the payment changed code, or set the initial type
                 *  value which will also trigger the same code. This is necessary because the
                 *  type dropdown is protected when creating from the RTP widget. So the code
                 *  that would happen when a type is selected must happen when the account is.
                 */
                if (this.model.get('PAYMENTTYPE') === Constants.CRTRAN) {
                    this.paymentTypeChanged();
                } else {
                    this.model.set('PAYMENTTYPE', Constants.CRTRAN);
                }
            } else if (this.isPayment()) {
                if (this.model.get('PAYMENTTYPE')) {
                    this.model.set('PAYMENTTYPE', '');
                }
            } else if (this.model.get('BENE_ACCOUNT')) {
                // Collection instead of a payment.  There is only one PAYMENTTYPE.  Set it
                const { paymentType } = this.getDeliveryMethods(this.model.get('BENE_ACCOUNT'))[0];
                this.model.set('PAYMENTTYPE', paymentType);
                this.paymentTypeChanged();
            } else {
                // Beneficiary account was cleared.  Wipe out PAYMENTTYPE
                this.model.set('PAYMENTTYPE', '');
            }
            // render to get the payment type selections
            this.render();
        }
        if (util.has(model.changed, 'PAYMENTTYPE')) {
            this.paymentTypeChanged();
        }

        if (util.has(model.changed, 'ACCOUNTFILTER')) {
            this.setCreditCurrency();
        }

        // make call to update value date and transaction date, if all data is present to do so
        if (this.model.get('PAYMENTTYPE') && this.model.get('CREDIT_CURRENCY') && this.model.get('DEBIT_CURRENCY') && (this.model.get('CREDIT_AMOUNT') || this.model.get('DEBIT_AMOUNT'))) {
            RtgsHelper.doFieldValidation(this.model, 'VALUE_DATE');
        }
    },

    setCreditCurrency() {
        const beneAccount = this.getBeneAccount();
        const beneAccountCurrency = beneAccount?.get('BENE_ACCOUNT_CURRENCY');
        const isUSDOnly = RtgsHelper.isUSDOnly.call(this);

        if (isUSDOnly) {
            this.model.set('CREDIT_CURRENCY', 'USD');
        } else {
            this.model.set('CREDIT_CURRENCY', beneAccountCurrency || '');
        }
    },

    /*
     * called when the beneficiary account is determined, generally after the payment type
     * has changed
     */
    paymentTypeChanged() {
        const beneAccount = this.getBeneAccount();
        this.model.beneAccount = beneAccount;
        if (this.model.beneAccount) {
            this.model.set({
                PAYMENTPRODUCTCODE: beneAccount.get('PAYMENTPRODUCTCODE'),
                PAYMENTFUNCTIONCODE: beneAccount.get('PAYMENTFUNCTIONCODE'),
                PAYMENTCLEARINGSYSTEM: beneAccount.get('PAYMENTCLEARINGSYSTEM'),
                BENE_ACCOUNT: beneAccount.get('BENE_ACCOUNT'),
                TYPE_DESCRIPTION: beneAccount.get('TYPE_DESCRIPTION'),
            }, {
                silent: true,
            });
        }

        this.setCreditCurrency();

        /*
         * call paymentTypeChanged here to ensure that the model attributes set above
         * are present
         */
        this.entryView.paymentTypeChanged();

        // Clear out remittance info because it is only needed for RTP
        this.model.set('REMITTANCE_INFO', '');

        this.render();
    },

    getSubmitAndVerifyButton() {
        return {
            text: locale.get('smbPayments.submit.verify'),
            className: 'btn btn-primary',
            callback: util.bind(this.submit, this),
        };
    },

    getSaveForLaterButton() {
        return {
            text: locale.get('smbPayments.save.later'),
            className: 'btn btn-secondary',
            callback: util.bind(this.save, this),
        };
    },

    getPayButton() {
        return {
            text: this.isExistingTransaction() && this.isCompletedTransaction() ? locale.get('smbPayments.update') : locale.get('smbPayments.pay'),
            className: 'btn btn-success',
            callback: util.bind(this.pay, this),
        };
    },

    getCancelButton() {
        return {
            text: locale.get('smbPayments.cancel'),
            className: 'btn btn-secondary',
            callback: util.bind(this.cancel, this),
        };
    },

    /**
     * @method getWarningOkButton
     * Gets the Ok button to accept a warning
     * @return {Object} Returns settings for the Ok button needed to accept a warning
     */
    getWarningOkButton() {
        return {
            text: locale.get('button.ok'),
            className: 'btn btn-primary',
            callback: util.bind(this.payWithWarning, this),
        };
    },

    /**
     * @method getDuplicateWarningOkButton
     * Gets the Ok button to accept a duplicate warning
     * @return {Object} Returns settings for the Ok button needed to accept a
     * duplicate payment warning
     */
    getDuplicateWarningOkButton() {
        return {
            text: locale.get('button.ok'),
            className: 'btn btn-primary',
            callback: util.bind(this.payWithDuplicateWarning, this),
        };
    },

    getWarningCancelButton() {
        return {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',
            callback: util.bind(this.handleWarningCancel, this),
        };
    },

    handleWarningCancel() {
        /**
         * If the user has canceled a warning on a chained action such as
         * approve, then the primary action has succeeded and the UPDATECOUNT__
         * has changed.  We must capture that so any subsequent attempts
         * to update the payment will successfully find the current version of
         * the instruction.
         */
        if (this.alertView
            && this.alertView.options
            && this.alertView.options.details
            && this.alertView.options.details.options
            && this.alertView.options.details.options.confirms.chainedAction
            && this.alertView.options.details.options.confirms.confirmResults[0]
            && this.alertView.options.details.options.confirms.confirmResults[0].updateCount) {
            this.model.set(
                'UPDATECOUNT__',
                this.alertView.options.details.options.confirms.confirmResults[0]
                    .updateCount,
            );

            /**
             * We go back to the list view because the primary action has succeeded. There
             * is nothing left for the user to do in this flow.
             */
            this.trigger('smbPayments:paymentAdded');
            Dialog.close();
            const message = this.parseConfirmResponse(this.alertView.options.details.options.confirms, locale.get('sbPayments.successfulSubmitMsg'));
            appBus.trigger('payment:newPayment:gridAlert', this.model, message, this.alertView.options.details.options.confirms);
        } else {
            this.showCreatePayment(this);
        }
    },

    getEditButton() {
        return {
            text: locale.get('smbPayments.edit'),
            className: 'btn btn-secondary',
            callback: util.bind(this.showCreatePayment, this),
        };
    },

    /* Used only when 'select' action is chosen from the grid */
    getViewButtons() {
        const a = (this.options && this.options.model) ? this.options.model.buttons : null;
        const buttons = [];
        let aLowerCase;
        let i;

        if (a) {
            // Get all buttons (except 'view') to match our grid actions, and add 'cancel'
            for (i = 0; i < a.length; i += 1) {
                if (a[i].action !== 'SELECT') {
                    aLowerCase = a[i].action.toLowerCase();

                    buttons.push({
                        text: locale.get(`smbPayments.${aLowerCase}`),
                        className: 'btn btn-secondary',
                        callback: util.bind(this.triggerGridAction, this, aLowerCase),
                    });
                }
            }
        }

        buttons.push(this.getCancelButton());
        this.trigger('dialog:buttons:change', buttons);
    },

    getCreateTitle() {
        return locale.get(this.isExistingTransaction() ? 'sbPayments.editPayment' : 'sbPayments.newPayment');
    },

    getConfirmTitle() {
        return locale.get(this.isSelect() ? 'sbPayments.viewPayment' : 'sbPayments.confirmPaymentDetails');
    },

    getTypeDescription(key) {
        const typeDescriptionKey = this.smbTypeDescriptionKeys[key];
        return typeDescriptionKey ? locale.get(typeDescriptionKey) : key;
    },

    showAuditHistory() {
        this.auditM = new AuditModel({
            functionCode: this.model.get('PAYMENTFUNCTIONCODE') || this.model.get('FUNCTION'),
            tNum: this.model.get('TNUM'),
        });

        return this.auditM.fetch();
    },

    showCreatePayment() {
        /*
         * Existing payments may have stale dates.
         * Clear them out to be populated by the new earliest date
         */
        if (this.model.get('TNUM') && dateUtil.isValidPastDate(this.model.get('VALUE_DATE')) && this.options.action !== 'modify') {
            this.model.unset('VALUE_DATE');
        }

        this.model.unset('DUPLICATEREASON');
        this.model.unset('duplicateAccepted');
        this.model.unset('_saveWithWarning');

        this.alertRegion.reset();
        this.ui.$payeeSection.show();
        this.ui.$createSection.show();
        this.ui.$confirmSection.hide();
        this.ui.$toggleSwitchRegion.hide();
        this.ui.$duplicatePaymentSection.hide();

        this.trigger('dialog:title:change', this.getCreateTitle());
        this.trigger('dialog:buttons:change', [this.getSubmitAndVerifyButton(), this.getSaveForLaterButton(), this.getCancelButton()]);

        /*
         * Check if the contact used in the payment has been changed, deleted, or is not in the
         * approved status. In all of these scenarios the user will see a warning or error
         * message. In two workflows we do not need this check:
         *
         * 1. Originated from RFP - Payments created from RFP's are not linked to contacts.
         * 2. New Payment - When in insert mode only valid contacts/accounts are avaialble for
         *    the user to select from, so there's no risk of it being invalid. this.action is
         *    only defined when actioning existing payments.
         */
        if (!this.createdFromRFP && this.action) {
            this.checkForModifedOrDeletedContact();
        }

        this.createPaymentContent.show(this.entryView);

        this.model.validators.DUPLICATEREASON = null;

        // Trigger change event on the remittance field so the Characters Remaining total is set
        this.ui.$remittance.keyup();

        if (this.options.action === 'modify') {
            this.toggleRemittanceMandatory();
        }
    },

    showConfirmPayment(options) {
        const buttons = [];

        // NH-31559 - remove html characters from form values
        this.model.set('text', util.unescape(this.model.get('text')));

        this.ui.$payeeSection.hide();
        this.ui.$createSection.hide();
        this.ui.$confirmSection.show();
        this.ui.$toggleSwitchRegion.show();


        this.trigger('dialog:title:change', this.getConfirmTitle());
        // TODO: on create this.action isn't defined, research when this changed
        if (this.action === 'select') {
            this.getViewButtons();
        } else {
            if (this.modelCanBeModified()) {
                if (this.modelChanged) {
                    buttons.push(this.getPayButton());
                }
                buttons.push(this.getEditButton());
            }
            this.trigger('dialog:buttons:change', buttons);
        }
        this.confirmPaymentContent.show(this.confirmView);

        if (options && options.errorCode && options.errorCode === 540) {
            this.duplicateWarningReceived = true;
        }
    },

    /**
     * @param {object} options (options object from the server call)
     * @return {array} duplicatePaymentData (array of duplicate payments and
     * their data from the server)
     * Take the duplicate data returned from the server and put it into an array
     * that can be consumed by the view.
     */
    buildDuplicateData(options) {
        const obj = JSON.parse(options.message);
        const duplicatePaymentsDataFromServer = obj.confirms.confirmResults[0].additionalData;

        return Object.values(duplicatePaymentsDataFromServer).map((record) => {
            const smbTypedescription = this.resolvePaymentType(
                this.model,
                record.item[2].value,
            );
            const duplicatePayment = {
                id: record.item[0].value,
                beneficiary: record.item[1].value,
                paymentType: smbTypedescription,
                valueDate: record.item[3].value,
                lastUpdateTime: record.item[4].value,
                status: record.item[5].value,
            };
            return duplicatePayment;
        });
    },

    /**
     * @param {Object} model
     * @param {String} serverTypeDescription (Payment type received from the server)
     * @return {String} type (Payment description that will be meaningful to
     * SMB user.)
     * Take in the payment description recieved from the server and translate it
     * to a description that an SMB user would expect/understand .
     */
    resolvePaymentType(model, serverTypeDescription) {
        const typeCode = model.get('TYPE') || model.get('PAYMENTTYPE');
        const data = {
            BDACHCVP: 'SMBPAY.StdPaymentsACH',
            FEDWIRE: 'SMBPAY.ExpFedWire.NoFee',
            INTL: 'SMBPAY.ExpIntlWire.NoFee',
            BDACHCRC: 'SMBPAY.StdCollectionsACH',
        };
        const key = data[typeCode];
        return key ? locale.get(key) : serverTypeDescription;
    },

    showWarningConfirmation(options) {
        /*
         * If we are dealing with a duplicate warning we need to handle it differently (show
         * grid and duplicate reason field)
         */
        if (options.errorCode && options.errorCode === 540) {
            // NH-31559 - remove html characters from form values
            this.model.set('text', util.unescape(this.model.get('text')));

            this.ui.$payeeSection.hide();
            this.ui.$createSection.hide();
            this.ui.$confirmSection.show();
            this.ui.$toggleSwitchRegion.show();
            this.ui.$duplicatePaymentSection.show();

            this.trigger('dialog:title:change', this.getConfirmTitle());

            this.confirmPaymentContent.show(this.confirmView);

            this.duplicatePaymentView = this.getDuplicatePaymentView(this
                .buildDuplicateData(options));
            this.duplicatePaymentContent.show(this.duplicatePaymentView);

            this.model.validators.DUPLICATEREASON = {
                description: locale.get('sbPayments.duplicateReason'),
                exists: true,
            };
            this.trigger('dialog:buttons:change', [
                this.getDuplicateWarningOkButton(),
                this.getWarningCancelButton(),
            ]);
        } else {
            this.ui.$payeeSection.hide();
            this.ui.$createSection.hide();
            this.ui.$confirmSection.hide();
            this.ui.$toggleSwitchRegion.hide();
            this.trigger('dialog:buttons:change', [
                this.getWarningOkButton(),
                this.getWarningCancelButton(),
            ]);
        }
    },

    triggerGridAction(actionType) {
        // close our modal before calling grid action
        this.cancel();
        this.appBus.trigger(`grid:row:action:action_${actionType}_${this.options.model.parentViewId}`, this.options.model);
    },

    // Was dialog opened from the grid
    isExistingTransaction() {
        return this.options && (this.options.action === 'select' || this.options.action === 'modify');
    },

    // Was this transaction completed, i.e., not saved for later
    isCompletedTransaction() {
        return this.options && this.options.model && this.options.model.get('STATUS') !== 'IC';
    },

    getBeneListPromise() {
        return API.payeeList.get(false, this.fromRTPWorkspace);
    },

    // return true if the action passed in from the grid was select
    isSelect() {
        return this.options.action === 'select';
    },

    // returns true if the grid allowed the action.  Note, the grid actions are upper case
    hasGridAction(action) {
        return util.contains(util.pluck(this.options.model.buttons, 'action'), action.toUpperCase());
    },

    /*
     * model can be modified if it is new, a copy, was selected through modify, or was
     * selected through view but had modify privileges
     */
    modelCanBeModified() {
        return !this.options.action || this.options.action === 'copyinst' || this.options.action === 'modify' || (this.options.action === 'select' && this.hasGridAction('modify'));
    },

    maskedPayeeInputMatcher(params, data) {
        let matchesParams = null;
        let thisItem = null;
        // if no params, no search, display the item
        if (!params) {
            return data;
        }
        this.beneAccountList = this.getBeneAccountList();

        this.beneAccountList.models.forEach((array) => {
            if (this.maskAccount(array.get('BENE_ACCOUNT')) === data) {
                thisItem = array;
            }
        });

        if (thisItem) {
            matchesParams = thisItem.get('BENE_ACCOUNT').includes(params);
        }
        return matchesParams ? data : null;
    },

    renderBeneContent() {
        this.ui.$payeeSelect.comboBox({
            placeholder: locale.get('SMBPAY.Select'),
        });

        this.ui.$payeeAccountSelect.comboBox({
            matcher: this.maskedPayeeInputMatcher.bind(this),
            placeholder: locale.get('SMBPAY.Select'),
        });

        this.ui.$paymentTypeSelect.comboBox({
            placeholder: locale.get('SMBPAY.Select'),
        });

        this.ui.$remittanceMethod.comboBox({
            placeholder: locale.get('SMBPAY.Select'),
            allowClear: true,
        });

        if (this.model.isExistingPayment() || this.isCopyAction()) {
            this.ui.$payeeSelect.comboBox('enable', false);
            this.ui.$paymentTypeSelect.comboBox('enable', false);
            this.ui.$payeeAccountSelect.comboBox('enable', false);
            this.ui.$remittanceMethod.comboBox('enable', true);
        }
    },

    renderPaymentAlertContent(options) {
        // display notification message
        const obj = JSON.parse(options.message);

        let message = this.parseMessage(obj);

        /*
         * Some errors are not reported in the confirms structure. We just get an error message.
         * Just display the message and return.
         */
        if (!obj.confirms) {
            this.renderAlertView(message);
            return;
        }

        const [warningMessage] = obj.confirms.confirmResults[0].messages;
        let confirms;

        /*
         * Wire payments can not be auto approved without accepting the Combined Disclosure
         * and must be approved individually. The payment is still added, but with a warning,
         * so close the modal and show the appropriate warning message.
         */
        if (options.errorCode === 514) {
            Dialog.close();
            appBus.trigger(
                'payment:newPayment:gridAlert',
                this.model,
                obj.message.join(' '),
                obj,
                { canDismiss: true },
            );
            this.trigger('smbPayments:paymentAdded');
            return;
        }

        if (options.errorCode && options.errorCode === 551) {
            this.balanceCheckWarningReceived = true;
        }

        const [, title, sourceMessage] = warningMessage.split('@');

        /*
         * If this a warning for a possible duplicate payment then just display the message
         * in a warning dialog. Cannot be closed and has no 'continue' option.
         */
        if (options.errorCode && options.errorCode === 540) {
            if (!this.model.validationOnly) {
                this.alertView = alert.warning(
                    warningMessage,
                    {
                        canDismiss: false,
                    },
                );
                this.alertRegion.show(this.alertView);
            }
        } else if (options?.errorCode === 20003 && sourceMessage && title) {
            /*
             * NH-183696 React renders the string as is without interpretting
             * line breaks. So we need to join with spaces on mobile, where React
             * is currently in use, and <br> on desktop.
             */
            const messageContent = sourceMessage.split('\\n')
                .join(mobileUtil.isMobileScreen() ? ' ' : '<br>');

            this.alertView = alert.warning(
                messageContent,
                {
                    title,
                    canDismiss: false,
                    allowHtmlInMessage: true,
                },
            );
            this.alertRegion.show(this.alertView);
        } else {
            obj.confirms.confirmResults[0].confirmData = null;

            /*
             * corp-side hard codes the title locale.get('title.warning') for anything
             * that opens the warning dialog.
             * Make the equivalent substitution here
             */
            if (message === 'tablemaint.process.action.warning') {
                message = locale.get('title.warning');
                // add in the question of whether to proceed with the warning
                obj.confirms.confirmResults[0].messages.push(locale.get('common.proceed.warning.confirm'));
            }

            confirms = new Confirms({
                confirms: obj.confirms,
            });
            this.renderAlertView(message, confirms);
        }
    },

    renderAlertView(message, confirms) {
        this.alertView = alert.danger(
            message,
            {
                details: confirms,
                canDismiss: true,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    showPaymentDetails() {
        const getPaymentType = this.model.get('PAYMENTTYPE');
        if (getPaymentType && (getPaymentType === 'INTL' || getPaymentType === 'FEDWIRE')) {
            this.ui.$paymentDetails.show();
            if (this.isFirstDetailRequired()) {
                this.model.addValidatorProp('OBI_TEXT_1', 'exists', true);
            }
        } else {
            this.clearPaymentDetails();
        }
    },

    clearPaymentDetails() {
        this.ui.$paymentDetails.hide();
        this.model.removeValidatorProp('OBI_TEXT_1', 'exists');
        this.paymentDetailsFields.forEach((fieldName) => {
            this.model.set(fieldName, '');
        });
    },

    cancel() {
        this.trigger('dialog:buttons:disableAll');
        Dialog.close();
    },

    submit() {
        this.trigger('dialog:buttons:disableAll');
        if (!this.actionInProgress) {
            this.actionInProgress = true;
            const self = this;
            const isValid = this.model.isValid();
            if (isValid) {
                this.model.validationOnly = true;
                this.createModelsAndSave({
                    success() {
                        // made it past validation
                        self.trigger('dialog:buttons:enableAll');
                        self.actionInProgress = false;
                        self.model.validationOnly = false;
                        // reset alert region since any alerts must have been resolved
                        self.alertRegion.reset();
                        self.showConfirmPayment();
                    },

                    error(model, optionsParam) {
                        const options = optionsParam;
                        /*
                         * options will have server side validation messages
                         * model will contain the client validation - validationError
                         */
                        self.trigger('dialog:buttons:enableAll');
                        options.errorCode = JSON.parse(options.message).errorCode;
                        self.renderPaymentAlertContent(options);
                        self.actionInProgress = false;
                        self.model.validationOnly = false;
                        const { resultType } = JSON.parse(options.message);
                        if (resultType === 'WARNING') {
                            self.showConfirmPayment(options);
                        }
                    },
                });
            } else {
                // a client-side error occurred.  Re-enable buttons
                this.trigger('dialog:buttons:enableAll');
                scroll.scrollToFirstError();
                this.actionInProgress = false;
            }
        }
    },

    save() {
        this.trigger('dialog:buttons:disableAll');
        if (!this.actionInProgress) {
            this.actionInProgress = true;
            const isValid = this.model.isValid();
            if (isValid) {
                this.model.set('_saveIncomplete', true);
                this.createModelsAndSave({
                    success: (response, confirmResponse) => {
                        Dialog.close();
                        this.updateConfirmData(response, this.model);
                        this.trigger('smbPayments:paymentAdded');

                        // Return to the list view if the user is currently on the detail screen
                        if (this.fromDetailView) {
                            store.set(`${this.contextKey}-alertMessage`, this.action.toUpperCase());
                            store.set(`${this.contextKey}-confirms`, confirmResponse);
                            workspaceHelper.returnToCurrentWorkspaceOnResponse(this);
                        } else {
                            const message = this.parseConfirmResponse(confirmResponse, locale.get('sbPayments.successfulSubmitMsg'));
                            appBus.trigger('payment:newPayment:gridAlert', this.model, message, confirmResponse, { canDismiss: true });
                        }
                    },

                    error: (model, options) => {
                        /*
                         * options will have server side validation messages
                         * model will contain the client validation - validationError
                         */
                        this.trigger('dialog:buttons:enableAll');
                        this.actionInProgress = false;
                        this.renderPaymentAlertContent(options);
                    },
                });
            } else {
                // a client-side error occurred.  Re-enable buttons
                this.trigger('dialog:buttons:enableAll');
                this.actionInProgress = false;
            }
        }
    },

    /**
     * @method payWithWarning
     * sets the _saveWithWarning value to true and invokes the pay function.
     */
    payWithWarning() {
        this.model.set('_saveWithWarning', true);
        this.pay();
    },

    /**
     * @method payWithDuplicateWarning
     * sets the duplicateAccepted value to true and invokes the pay function.
     */
    payWithDuplicateWarning() {
        this.model.set('duplicateAccepted', true);
        this.pay();
    },

    /**
     * @method updateConfirmData
     * @param {Model} resp - response from server if submit is success.
     * @param {Model} model to show confirmfields on the screen
     * This method is a wrapper for updateID(). It handles those cases
     * where the response is a Model or some similar object that stores the
     * confirm data as an attribute.
     */
    updateConfirmData(resp, model) {
        const confirms = resp.get('confirms');
        this.updateID(confirms, model);
    },

    /**
     * @method updateID
     * @param {Object} confirms a JSON confirm structure
     * Copies the payment id from the confirm data to the model.
     */
    updateID(confirms) {
        /*
         * TODO NH-160380 Need to find a more elegant solution so that the UI does not have to
         * piece together the contents of the confirmation message.
         * Backend devs confirmed that id should always be the first element in the
         * array, so this is an intermediary fix for now.
         */
        const seqIdObj = confirms?.confirmResults?.[0]?.confirmData?.[0]?.item?.[0] || {};
        if (seqIdObj.value) {
            this.model.set('ID', seqIdObj.value);
        }
    },

    pay() {
        this.trigger('dialog:buttons:disableAll');
        if (!this.actionInProgress) {
            this.actionInProgress = true;
            const self = this;
            const isValid = this.model.isValid();
            if (isValid) {
                this.createModelsAndSave({
                    success(resp, confirmResponse) {
                        Dialog.close();

                        let actionMode = self.action;

                        self.trigger('smbPayments:paymentAdded');
                        self.updateConfirmData(resp, self.model);

                        // Return to the list view if the user is currently on the detail screen
                        if (self.fromDetailView) {
                            store.set(`${self.contextKey}-alertMessage`, actionMode.toUpperCase());
                            store.set(`${self.contextKey}-confirms`, confirmResponse);
                            workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
                        } else if (self.fromRTPWorkspace) {
                            actionMode = actionMode ? actionMode.toUpperCase() : 'INSERT';
                            appBus.trigger('rtp:renderMessage', actionMode, confirmResponse);
                        } else {
                            const message = self.parseConfirmResponse(confirmResponse, locale.get('sbPayments.successfulSubmitMsg'));
                            appBus.trigger('payment:newPayment:gridAlert', self.model, message, confirmResponse, { canDismiss: true });
                        }
                    },

                    error(model, optionsParam) {
                        const options = {
                            ...optionsParam,
                            model,
                        };
                        /*
                         * options will have server side validation messages
                         * model will contain the client validation - validationError
                         */
                        self.trigger('dialog:buttons:enableAll');
                        self.actionInProgress = false;
                        const {
                            resultType,
                            confirms,
                            errorCode,
                        } = JSON.parse(options.message);
                        self.updateID(confirms, self.model);
                        if (resultType === 'WARNING') {
                            options.errorCode = errorCode;
                            self.showWarningConfirmation(options);
                            self.renderPaymentAlertContent(options);
                        } else if (self.isChainedAction(confirms)) {
                            /*
                             * Return to the list view. User cannot fix
                             * anything on the payment modal.
                             */
                            self.trigger('smbPayments:paymentAdded');
                            Dialog.close();
                            const message = self.parseConfirmResponse(confirms, locale.get('sbPayments.successfulSubmitMsg'));
                            appBus.trigger('payment:newPayment:gridAlert', self.model, message, confirms);
                        } else {
                            self.showCreatePayment();
                            self.renderPaymentAlertContent(options);
                        }
                    },
                });
            } else {
                // a client-side error occurred.  Re-enable buttons
                this.trigger('dialog:buttons:enableAll');
                this.actionInProgress = false;
            }
        }
    },

    isChainedAction(confirms) {
        if (confirms && confirms.chainedAction) {
            return true;
        }
        return false;
    },

    /**
     * When present, parse the response messages, join the messages, and return a
     * string, otherwise return default message
     * @param confirmResponse - response object with contains display messages
     * @param defaultMessage - default message, if response doesn't contain message
     * @returns {*|string}
     */
    parseConfirmResponse(confirmResponse, defaultMessage) {
        if (util.isNullOrUndefined(confirmResponse) || !confirmResponse.message) {
            return defaultMessage;
        }

        if (util.isArray(confirmResponse.message)) {
            return confirmResponse.message.join(' ');
        }
        if (util.isString(confirmResponse.message)) {
            return confirmResponse.message;
        }
        return defaultMessage;
    },

    /*
     * message array
     * message[0]: returns innocuous server side error
     * message[1]: returns field validation errors from server
     * message[2]: returns field info from server
     */
    parseMessage(obj) {
        return (obj.message[1] ? obj.message[1] : obj.message[0]);
    },

    // create one or more of the relevant payment type models and save
    createModelsAndSave(options) {
        /*
         * the server does not check whether a payee is valid, possibly because free
         * form wires are possible.  However,
         * in SMB, the payee must be valid.  Retrieve the list of payees anew when
         * saving to validate against what
         * was entered
         */
        const viewModel = this.model;
        const beneListPromise = this.getBeneListPromise();
        const self = this;
        beneListPromise.then((results) => {
            const models = results;
            let selectedBeneAccount;
            // payments from RFP don't have a bene so we cannot look to it for the bene account
            if (this.createdFromRFP) {
                selectedBeneAccount = this.getBeneAccountForPaymentFromRFP(viewModel);
            } else {
                // find the bene for the submitted name
                selectedBeneAccount = self.getBeneAccount(viewModel.get('BENE_NAME'), viewModel.get('BENE_ACCOUNT'), viewModel.get('PAYMENTTYPE'), models);
            }
            if (!selectedBeneAccount) {
                const message = 'Payee is no longer valid';
                viewModel.set(
                    'error',
                    {
                        BENE_NAME: [message],
                    },
                );
                viewModel.trigger('invalid');
                self.renderBeneContent();
                options.error(
                    viewModel,
                    {
                        message,
                    },
                );
            } else {
                viewModel.beneAccount = viewModel.beneAccount || selectedBeneAccount;
                self.resetContactHasBeenUpdatedFlag(viewModel);
                self.saveOrUpdate(viewModel, options);
            }
        });
    },

    /**
     * For normal SMB payments we lookup the bene used to create the payment and then get the
     * bene account details from that. For RTP's created from RFP's there is no bene so we need
     * to pull the values from the passed in model and return an account model with all the
     * values that will be needed for saving.
     * @param {Model} viewModel - model containing current payment values
     * @returns {Model}
     */
    getBeneAccountForPaymentFromRFP(viewModel) {
        const beneAccountModel = new Model();
        const beneFields = [...this.beneAccountFields, ...this.paymentDetailsFields];

        beneFields.forEach((fieldName) => {
            beneAccountModel.set(fieldName, viewModel.get(fieldName) || '');
        });

        return beneAccountModel;
    },

    saveOrUpdate(viewModel, options) {
        const paymentType = viewModel.get('PAYMENTTYPE');
        if (Constants.isWire(paymentType)) {
            this.wireSaveOrUpdate(viewModel, options, paymentType);
        } else if (paymentType === Constants.CORPORATEPAYMENT) {
            this.corporateVendorPaymentSaveOrUpdate(viewModel, options);
        } else if (paymentType === Constants.CONSUMERPAYMENT) {
            this.consumerPaymentSaveOrUpdate(viewModel, options);
        } else if (paymentType === Constants.CRTRAN) {
            this.realTimePaymentSaveOrUpdate(viewModel, options, paymentType);
        }
    },

    wireSaveOrUpdate(viewModel, options, paymentType) {
        let attrs = (viewModel.id ? {
            id: viewModel.id,
        } : {});

        let defaultRouteBase;
        let WireModel;
        let wireModel;
        if (paymentType === Constants.FEDWIRE) {
            defaultRouteBase = '/payment/fedwire';
            WireModel = FedwireModel;
        } else {
            defaultRouteBase = '/payment/Wire-International';
            WireModel = IntlModel;
        }

        const modelOptions = {
            attributes: viewModel.attributes,
            account: viewModel.account,
            beneAccount: viewModel.beneAccount,
            validationOnly: viewModel.validationOnly,
            routeBase: viewModel.beneAccount ? viewModel.beneAccount.get('RESTSERVICENAME') : defaultRouteBase,
        };
        /*
         * if we're past validation, the user acknowledges any warnings and is continuing
         *  This is only not true for the duplicate payment validation, which the
         * user will not have seen yet.
         *
         * Balance check is a tricky one. It is tied to chained action (approve) so
         * only an auto-approve user will encounter it here. If the user cancels out
         * of the BC warning, edits the amount, and proceeds to submit, including
         * the resubmission. There is some state saved on the server that is only
         * cleared when _saveWithWarning != true.
         */
        if (!viewModel.validationOnly && !this.duplicateWarningReceived
            && !this.balanceCheckWarningReceived) {
            // eslint-disable-next-line
            modelOptions.attributes._saveWithWarning = true; // server requires this key
        }

        // Retrieve data that might be missing from the bene account.
        new Promise((resolve, reject) => {
            const collection = new ContactLookupAccounts({
                paymentType,
            });
            collection.fetch({
                success() {
                    resolve(collection);
                },

                error: reject,
            });
        }).then((contactLookupAccounts) => {
            const contactAccountModel = contactLookupAccounts.findWhere({
                BENE_NAME: viewModel.get('BENE_NAME'),
                BENE_ACCOUNT: viewModel.get('BENE_ACCOUNT'),
            });
            attrs = util.extend(attrs, contactAccountModel
                ? contactAccountModel.attributes : {});
            // add the lookup results for the contact as attributes on the wire
            wireModel = new WireModel(attrs, modelOptions);
            wireModel.save(
                {},
                {
                    success: options.success,
                    error: options.error,
                },
            );
        });
    },

    viewDisclosure() {
        const printOptions = {
            tnum: this.model.get('TNUM'),
            outputFormat: 'PDF',
            pageType: 'LETTER',
            viewId: 'WIREINTLDISCLOSURE',
            serviceCode: 'WIREINTLDISCLOSURE',
            dynamicReportURL: `${Constants.WIRE_DISCLOSURE_PREFIX}reportView`,
            asyncReport: false,
        };
        Dialog.close();
        DynamicReportUtil.print(printOptions);
    },

    corporateVendorPaymentSaveOrUpdate(viewModel, options) {
        const routeBase = viewModel.beneAccount ? viewModel.beneAccount.get('RESTSERVICENAME') : '/batch/CorporateVendorPayments';
        let childModel;

        // skip validation here to prevent child updates
        if (viewModel.validationOnly) {
            options.success();
        } else if (viewModel.isExistingPayment()) {
            childModel = new CorporateChildModel(
                {},
                {
                    attributes: viewModel.attributes,
                    beneAccount: viewModel.beneAccount,
                    routeBase,
                },
            );
            childModel.set('BATCHSEQNUM', viewModel.get('BATCHSEQNUM'));
            childModel.save(
                {},
                {
                    success() {
                        childModel.fetch({
                            success() {
                                const corporateModel = new ConsumerOrCorporateModel({
                                    id: viewModel.id,
                                }, {
                                    attributes: viewModel.attributes,
                                    account: viewModel.account,
                                    validationOnly: viewModel.validationOnly,
                                    routeBase,
                                });
                                corporateModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        });
                    },

                    error: options.error,
                },
            );
        } else {
            const resetChildModel = new CorporateChildResetModel({
                subType: viewModel.beneAccount.get('PAYMENTCLEARINGSYSTEM'),
            }, {
                routeBase,
            });
            resetChildModel.fetch({
                success(resultModel, result) {
                    childModel = new CorporateChildModel(
                        {},
                        {
                            attributes: viewModel.attributes,
                            beneAccount: viewModel.beneAccount,
                            routeBase,
                        },
                    );

                    childModel.set('BATCHSEQNUM', result);
                    viewModel.set('BATCHSEQNUM', result);

                    childModel.save(
                        {},
                        {
                            success() {
                                const corporateModel = new ConsumerOrCorporateModel(
                                    {},
                                    {
                                        attributes: viewModel.attributes,
                                        account: viewModel.account,
                                        validationOnly: viewModel.validationOnly,
                                        routeBase,
                                    },
                                );
                                corporateModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        },
                    );
                },

                error: options.error,
            });
        }
    },

    consumerPaymentSaveOrUpdate(viewModel, options) {
        const routeBase = viewModel.beneAccount ? viewModel.beneAccount.get('RESTSERVICENAME') : '/batch/ConsumerPayments';
        let childModel;

        // skip validation here to prevent child updates
        if (viewModel.validationOnly) {
            options.success();
        } else if (viewModel.isExistingPayment()) {
            childModel = new ConsumerChildModel(
                {},
                {
                    attributes: viewModel.attributes,
                    beneAccount: viewModel.beneAccount,
                    routeBase,
                },
            );

            childModel.set('BATCHSEQNUM', viewModel.get('BATCHSEQNUM'));

            childModel.save(
                {},
                {
                    success() {
                        childModel.fetch({
                            success() {
                                const consumerModel = new ConsumerOrCorporateModel({
                                    id: viewModel.id,
                                }, {
                                    attributes: viewModel.attributes,
                                    account: viewModel.account,
                                    validationOnly: viewModel.validationOnly,
                                    routeBase,
                                });
                                consumerModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        });
                    },

                    error: options.error,
                },
            );
        } else {
            const resetChildModel = new ConsumerChildResetModel({
                subType: viewModel.beneAccount.get('PAYMENTCLEARINGSYSTEM'),
            }, {
                routeBase,
            });
            resetChildModel.fetch({
                success(resultModel, result) {
                    childModel = new ConsumerChildModel(
                        {},
                        {
                            attributes: viewModel.attributes,
                            beneAccount: viewModel.beneAccount,
                            routeBase,
                        },
                    );

                    childModel.set('BATCHSEQNUM', result);
                    viewModel.set('BATCHSEQNUM', result);

                    childModel.save(
                        {},
                        {
                            success() {
                                const consumerModel = new ConsumerOrCorporateModel(
                                    {},
                                    {
                                        attributes: viewModel.attributes,
                                        account: viewModel.account,
                                        validationOnly: viewModel.validationOnly,
                                        routeBase,
                                    },
                                );
                                consumerModel.save(
                                    {},
                                    {
                                        success: options.success,
                                        error: options.error,
                                    },
                                );
                            },

                            error: options.error,
                        },
                    );
                },

                error: options.error,
            });
        }
    },

    /*
     * Initialize save process for RTP
     * @param {Object} viewModel - (model that holds all payment values)
     * @param {Object} options - (object holding the success and error options for save result)
     * @param {String} paymentType - (current payment type)
     */
    realTimePaymentSaveOrUpdate(viewModel, options, paymentType) {
        let attrs = (viewModel.id ? {
            id: viewModel.id,
        } : {});

        const modelOptions = {
            attributes: viewModel.attributes,
            account: viewModel.account,
            beneAccount: viewModel.beneAccount,
            validationOnly: viewModel.validationOnly,
            routeBase: (viewModel.beneAccount && viewModel.beneAccount.get('RESTSERVICENAME')) ? viewModel.beneAccount.get('RESTSERVICENAME') : '/payment/crtran',
        };

        /*
         * if we're past validation, the user acknowledges any warnings and is continuing
         *  This is only not true for the duplicate payment validation, which the
         * user will not
         * have seen yet.
         */
        if (!viewModel.validationOnly && !this.duplicateWarningReceived) {
            // eslint-disable-next-line
            modelOptions.attributes._saveWithWarning = true; // server requires this key
        }

        if (this.createdFromRFP) {
            const realTimeModel = new RTPModel(attrs, modelOptions);
            realTimeModel.save(
                {},
                {
                    success: options.success,
                    error: options.error,
                },
            );
        } else {
            // Retrieve data that might be missing from the bene account.
            new Promise((resolve, reject) => {
                const collection = new ContactLookupAccounts({
                    paymentType,
                });
                collection.fetch({
                    success() {
                        resolve(collection);
                    },

                    error: reject,
                });
            }).then((contactLookupAccounts) => {
                const contactAccountModel = contactLookupAccounts.findWhere({
                    BENE_NAME: viewModel.get('BENE_NAME'),
                    BENE_ACCOUNT: viewModel.get('BENE_ACCOUNT'),
                });
                attrs = util.extend(attrs, contactAccountModel
                    ? contactAccountModel.attributes : {});
                // add the lookup results for the contact as attributes on the wire
                const realTimeModel = new RTPModel(attrs, modelOptions);

                this.combineBeneAddressFields(realTimeModel);

                realTimeModel.save(
                    {},
                    {
                        success: options.success,
                        error: options.error,
                    },
                );
            });
        }
    },

    /*
     * Combine BENE_ADDRESS_1 and BENE_ADDRESS_2 into a single BENE_ADDRESS value.
     * @param {Object} model - (model that holds all payment values)
     */
    combineBeneAddressFields(model) {
        const beneAddress1 = model.get('BENE_ADDRESS_1');
        const beneAddress2 = model.get('BENE_ADDRESS_2');
        let finalBeneAddress;
        if (beneAddress1 && beneAddress2) {
            // max length is 70.. so we can add space if less than 70
            if ((beneAddress1.length + beneAddress2.length) < 70) {
                finalBeneAddress = `${beneAddress1} ${beneAddress2}`;
            } else {
                finalBeneAddress = `${beneAddress1}${beneAddress2}`;
            }
        } else if (beneAddress1) {
            finalBeneAddress = beneAddress1;
        } else if (beneAddress2) {
            finalBeneAddress = beneAddress2;
        }
        model.set('BENE_ADDRESS', finalBeneAddress);
    },

    /*
     * Check if the Contact used in the payment is in the Approved Status.
     * If it is not, warn the user that they cannot proceed until it is.
     */
    checkForContactAwaitingApproval() {
        /*
         * There are two reasons why we would not have an available bene:
         * 1. Bene has been deleted
         * 2. Bene is not approved
         * If we do not have a bene and the contact updated flag is not set to 'INVALID'
         * then we can assume the reason for not having the bene is that the contact has
         * been modified and not yet re-approved. Deleting or doing something else to make
         * the contact no longer valid would set the flag.
         */
        if (this.model.get('CONTACTHASBEENUPDATED') !== 'INVALID'
            && !this.model.beneAccount
            && (this.entryView && !this.entryView.payeeNotApproved)) {
            // The template will look to this flag on render
            this.entryView.payeeNotApproved = true;
            this.render();

            // Display error message explaining that they cannot proceed
            this.alertView = alert.danger(
                locale.get('PAY.smbPayeeNotApproved'),
                {
                    canDismiss: true,
                },
            );
            this.alertRegion.show(this.alertView);

            // Remove every button other than 'Cancel'
            this.trigger('dialog:buttons:change', [this.getCancelButton()]);
        }
    },

    refreshUpdatedContact(account) {
        const isRTPPayment = this.model ? this.model.get('PAYMENTTYPE') === 'CRTRAN' : false;
        const fieldNames = Object.keys(account.attributes);
        fieldNames.forEach((fieldName) => {
            this.model.set(
                fieldName,
                account.get(fieldName),
                {
                    silent: true,
                },
            );
        });
        if (isRTPPayment) {
            this.combineBeneAddressFields(this.model);
        }

        this.render();
    },

    getBeneBookIDPromise(model) {
        return new Promise((resolve, reject) => {
            if (!model || model.get('BENEBOOK_ID')) {
                resolve();
            }

            const paymentType = model.get('PAYMENTTYPE') || model.get('TYPE');
            let routeBase = (model && model.beneAccount) ? model.beneAccount.get('RESTSERVICENAME') : '';
            let childModel;

            const paymentTypeHash = {
                [Constants.CORPORATEPAYMENT]: '/batch/CorporateVendorPayments',
                [Constants.CONSUMERPAYMENT]: '/batch/ConsumerPayments',
                [Constants.CORPORATECOLLECTION]: '/batch/CorporateCollections',
                [Constants.CONSUMERCOLLECTION]: '/batch/ConsumerCollections',
            };
            routeBase = routeBase || paymentTypeHash[paymentType];

            const configModel = model.clone();

            if (this.options.isCopyPayment && (model.get('PAYMENTPRODUCTCODE') === 'USACH' || model.get('PRODUCT') === 'USACH')) {
                configModel.set('TNUM', model.get('id'));
            }

            const configs = {
                attributes: configModel.attributes,
                beneAccount: model.beneAccount,
                routeBase,
            };

            if (paymentType === Constants.CORPORATEPAYMENT
                || paymentType === Constants.CORPORATECOLLECTION) {
                childModel = new CorporateChildModel({}, configs);
            } else if (paymentType === Constants.CONSUMERPAYMENT
                || paymentType === Constants.CONSUMERCOLLECTION) {
                childModel = new ConsumerChildModel({}, configs);
            }

            if (childModel) {
                childModel.sync(
                    'read',
                    model,
                    {
                        actionMode: this.options.isCopyPayment ? 'SELECT' : 'MODIFY',

                        success: (result) => {
                            if (result && result.rows.length > 0) {
                                this.model.set('BENEBOOK_ID', result.rows[0].columns.find(row => row.fieldName === 'BENEBOOK_ID').fieldValue);
                                this.model.set('BENEBOOKCHILD_ID', result.rows[0].columns.find(row => row.fieldName === 'BENEBOOKCHILD_ID').fieldValue);
                                resolve();
                            }
                        },

                        error: () => {
                            reject();
                        },
                    },
                );
            }
        });
    },

    /*
     * Set the CONTACTHASBEENUPDATED back to 'NO' once the payment has been corrected.
     * @param {Object} model - (payment model to reset the values in)
     */
    resetContactHasBeenUpdatedFlag(model) {
        model.set('CONTACTHASBEENUPDATED', 'NO');
        if (model.beneAccount) {
            model.beneAccount.set('CONTACTHASBEENUPDATED', 'NO');
        }
    },

    /*
     * Set the remaining characters helper text for the changed text field
     * @param {Event} e
     */
    showCountCharacters(e) {
        const $targetField = this.$(e.target);
        $targetField.parent().find('.char-counter')
            .text(`${locale.get('sbPayments.charactersRemaining')} ${Constants.REAL_TIME_REMITTANCE_MAX_LENGTH - $targetField.val().length}`);
    },

    /*
     * Check for the CONTACTHASBEENUPDATED flag and show
     * the user the correct warning if it is not 'NO'
     */
    checkForModifedOrDeletedContact() {
        if (this.model.get('CONTACTHASBEENUPDATED') === 'NO') {
            this.checkForContactAwaitingApproval();
            return;
        }

        const beneBookIDPromise = this.getBeneBookIDPromise(this.model);

        beneBookIDPromise.then(() => {
            this.model.bene = this.getBeneUsingContactTnum();
            this.model.beneAccount = this.getBeneAccountUsingContactTnum(this.model.bene);

            if (this.model.get('CONTACTHASBEENUPDATED') === 'YES') {
                this.resetContactHasBeenUpdatedFlag(this.model);
                this.refreshUpdatedContact(this.model.beneAccount);
                this.alertView = alert.warning(
                    locale.get('PAY.contactUpdatedMessage'),
                    {
                        canDismiss: true,
                        title: locale.get('PAY.contactUpdatedTitle'),
                    },
                );
                this.alertRegion.show(this.alertView);
            } else if (this.model.get('CONTACTHASBEENUPDATED') === 'INVALID') {
                this.resetContactHasBeenUpdatedFlag(this.model);
                if (!this.options.isCopyPayment) {
                    this.trigger('dialog:buttons:change', [this.getCancelButton()]);
                }

                let beneDeletedError = '';
                if (this.model.bene) {
                    beneDeletedError = this.options.isCopyPayment
                        ? 'PAY.accountRemovedCopyAsPayment' : 'PAY.accountRemovedMessage';
                } else {
                    beneDeletedError = this.options.isCopyPayment
                        ? 'PAY.contactRemovedCopyAsPayment' : 'PAY.contactRemoved';
                }

                this.alertView = alert.danger(
                    locale.get(beneDeletedError),
                    {
                        canDismiss: true,
                    },
                );
                this.alertRegion.show(this.alertView);
            }
        }, (err) => {
            appBus.trigger('promise:error', err);
        });
    },
});
