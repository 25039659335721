import EntryView from 'common/dynamicPages/views/workflow/entry';
import ImportModel from 'common/dynamicPages/models/import';
import f1 from 'system/utilities/f1';
import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import alert from '@glu/alerts';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import helpPageUtil from 'common/util/helpPage';
import domUtil from 'common/util/domUtil';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import fileImportTmpl from './fileImport.hbs';

let form = null;

export default EntryView.extend({
    template: fileImportTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'full-page',

    ui: {
        alertRegion: '.alert-region',
        $cancelButton: 'div.widget-action-btn-group button[name="cancel"]',
        $fileImport: '#FILESELECTION',
        $progressBar: '.progress-bar',
    },

    initialize(options) {
        form = f1.getForm(this);

        EntryView.prototype.initialize.call(this, options);

        this.buttonList = [];
        this.buttonList.push({
            action: 'FILEIMPORT',
        });
        this.buttonList.push({
            action: 'FILEIMPORTREFRESH',
        });
        this.buttonList.push({
            action: 'CANCEL',
        });

        this.import = new ImportModel();
        this.listenTo(this.import, 'change:percentComplete', this.showProgressBar);
        this.listenTo(this.appBus, 'create:success', this.handleImportSuccess);
        this.listenTo(this.appBus, 'create:error', this.handleImportError);
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadViewRequirements();
        }
    },

    loadViewRequirements() {
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'BENEADBK',
            mode: 'IMPORT',
        });

        helpPagePromise.then((results) => {
            if (!util.isEmpty(results)) {
                store.set('helpPage', results.helpPage);
            }
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    fileimport() {
        const self = this;
        const { files } = this.ui.$fileImport[0];
        const [file] = files;

        if (!this.checkFileName()) {
            return false;
        }

        if (files && file) {
            const reader = new FileReader();

            reader.onload = function (readerEvt) {
                // show our progress bar
                self.ui.$progressBar.parent().removeClass('hide');
                const binaryString = readerEvt.target.result;
                const payValues = [];
                if (!util.isNullOrUndefined($('#TESTMODE').val())) {
                    payValues.push({
                        name: 'RP=_TESTMODE',
                        value: form.field('TESTMODE').$el.is(':checked'),
                    });
                }

                self.import.set('fileName', file.name);
                self.import.set('importType', 'BENEADIM');
                self.import.set('eventName', 'Bene Address Book Import');
                self.import.set('importJCode', 'BENEADIM');
                try {
                    self.import.set('fileContent', binaryString);
                } catch (e) {
                    self.actionErrorForDetail({
                        responseJSON: {
                            message: [locale.get('common.fileimport.invalidchar')],

                            confirms: {
                                confirmResults: [{
                                    confirmData: null,
                                    messages: [locale.get('common.fileimport.invalidchar')],
                                }],
                            },
                        },
                    });
                    return;
                }
                self.import.set('importParameters', payValues);
                self.import.set('paymentData', payValues);
                self.import.save(
                    {},
                    {
                        success(model, confirmResponse) {
                            self.appBus.trigger('create:success', confirmResponse);
                        },

                        error(model, confirmResponse) {
                            self.actionErrorForDetail(confirmResponse);
                        },
                    },
                );
            };
            const encoding = serverConfigParams.get('importCharset');
            if (!util.isEmpty(encoding)) {
                reader.readAsText(file, encoding);
            } else {
                reader.readAsText(file);
            }
        }
        return undefined;
    },

    actionErrorForDetail(confirmResponse) {
        // hide our progress bar
        this.ui.$progressBar.parent().toggleClass('hide');
        this.renderMessage(confirmResponse.responseJSON);
        this.enableButtons();
    },

    handleImportError(response) {
        this.clearErrors();
        const errors = response.confirms && response.confirms.confirmResults.length > 0
            ? response.confirms.confirmResults[0].messages : response.messages;
        this.showErrors(errors);
    },

    handleImportSuccess(response) {
        let msg = '';
        if (response.confirms && response.confirms.confirmResults.length > 0
            && response.confirms.confirmResults[0].messages) {
            msg = response.confirms.confirmResults[0].messages;
        } else if (util.isArray(response.message)) {
            util.each(response.message, (val) => {
                if (val !== 'Success') {
                    if (msg.length > 0) {
                        msg += '; ';
                    }
                    msg += val;
                }
            });
        } else {
            msg = response.message;
        }
        this.clearErrors();
        store.set('addContactSuccessMessage', msg);
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    getReturnRoute() {
        let route = 'ADMINSTRATION/manageBeneAddressBook';
        if (store.has('beneAddressBookReturnRoute')) {
            route = store.remove('beneAddressBookReturnRoute');
        }
        return route;
    },

    showErrors(messages) {
        util.each(messages, this.showError, this);
    },

    showError(message) {
        const alertView = alert.danger(message);
        this.ui.alertRegion.append(alertView.render().el);
    },

    clearErrors() {
        this.ui.alertRegion.empty();
    },

    checkFileName() {
        const fileNameWithPath = form.field('FILESELECTION').getValue();
        let fileNameWithoutPath = '';
        let lastIndex = -1;
        if (fileNameWithPath.indexOf('\\') !== -1) {
            lastIndex = fileNameWithPath.lastIndexOf('\\');
            fileNameWithoutPath = fileNameWithPath.substring(lastIndex + 1);
        }

        if (fileNameWithoutPath !== '' && fileNameWithoutPath.length > 255) {
            $('[data-validate="FILESELECTION"]').text(locale.get('ACH.UploadFileNameLength.Error'));
            let { className } = $('#FILESELECTION')[0].parentElement;
            className += ' has-error';
            $('#FILESELECTION')[0].parentElement.className = className;
            return false;
        }
        return true;
    },

    fileimportrefresh() {
        form.field('FILESELECTION').setValue('');
    },

    disableButtons() {
        domUtil.setDisabled(this.ui.$cancelButton, true);
    },

    showProgressBar() {
        const percent = `${this.import.get('percentComplete')}%`;

        this.ui.$progressBar.attr('aria-valuenow', this.import.get('percentComplete'))
            .css('width', percent).html(percent);
    },
});
