import ItemView from '@glu/core/src/itemView';
import companiesViewTmpl from './companiesView.hbs';

const CompaniesView = ItemView.extend({
    template: companiesViewTmpl,

    events: {
        'click input': 'toggleSelected',
    },

    templateHelpers() {
        return {
            hx: 'h4',
            userGroup: this.model.get('USERGROUP'),
            userGroupName: this.model.get('GROUPNAME'),
        };
    },
});

export default CompaniesView;
