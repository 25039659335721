import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import ListBuilder from 'common/listBuilder/listBuilder';
import alertMessage from 'common/api/alertMessage';
import alert from '@glu/alerts';
import TemplateGroupCollection from 'app/payments/collections/templateGroup';
import TemplateGroupModel from 'app/payments/models/templateGroup';
import labelTemplateTmpl from 'app/payments/views/labelTemplate.hbs';
import editTemplateGroupTmpl from 'app/payments/views/editTemplateGroup.hbs';

const LabelView = ItemView.extend({
    className: 'template-group-item',
    template: labelTemplateTmpl,
});

export default Layout.extend({
    template: editTemplateGroupTmpl,

    regions: {
        alertRegion: 'div[data-region="alert"]',
        listBuilder: 'div[data-region="list-builder"]',
    },

    getClass() {
        const classes = ['template-group-builder'];
        if (this.mode === 'create') {
            classes.push('new-template-group');
        } else {
            classes.push('edit-template-group');
        }
        return classes.join(' ');
    },

    getHeader() {
        return (this.mode === 'create') ? locale.get('payment.templategroups.newGroup') : this.model.get('name');
    },

    initialize(options) {
        /*
         * pass an object like this to edit
         * options = {
         *    name: 'test name',
         *    description: 'desc'
         * };
         */

        this.mode = 'create';
        let attr = {};

        if (options && options.name) {
            this.mode = 'edit';
            attr = {
                // this will tell model.isNew that the model exists on the server
                id: options.name,

                name: options.name,
                description: options.description,
            };
        }

        this.model = new TemplateGroupModel(attr);

        this.sourceCollection = new TemplateGroupCollection(
            [],
            {
                mode: 'source',
                templateName: this.model.get('name'),
            },
        );

        if (this.mode === 'edit') {
            this.targetCollection = new TemplateGroupCollection(
                [],
                {
                    mode: 'target',
                    templateName: this.model.get('name'),
                },
            );
        }

        this.listenTo(
            this.model,
            {
                request: this.handleRequest,
                sync: this.handleSync,
                error: this.handleError,
            },
        );
    },

    loadCollections() {
        const promises = [this.sourceCollection.fetch()];
        if (this.mode === 'edit') {
            promises.push(this.targetCollection.fetch());
        }

        Promise.all(promises).then(util.bind(this.buildListBuilder, this));
    },

    handleSync() {
        // successfully saved
        this.trigger('templateGroupSaved', this.model);
        dialog.close();
    },

    getSubmitButtonEl() {
        return this.$el.parents('.template-group-builder').find('.modal-footer button.btn-primary');
    },

    handleRequest() {
        this.getSubmitButtonEl().attr('aria-busy', 'true');
    },

    handleError(model, xhr) {
        this.getSubmitButtonEl().attr('aria-busy', 'false');
        alertMessage.renderMessage(this, '', xhr.responseJSON, 0, true);
    },

    buildListBuilder() {
        if (this.mode !== 'create') {
            this.sourceCollection.remove(this.targetCollection.getEntitledModels());
        }

        this.listBuilderView = new ListBuilder({
            sourceCollection: this.sourceCollection,
            targetCollection: (this.mode === 'create') ? [] : this.targetCollection.getEntitledModels(),
            labelView: LabelView,
        });
        this.listBuilderView.text.sourceListHeader = 'PS.sourceListHeader';
        this.listBuilderView.text.targetListHeader = 'PS.targetListHeader';

        this.listBuilder.show(this.listBuilderView);

        this.injectHiddenTemplatesMessage(this.listBuilderView);
        if (this.mode === 'edit') {
            this.checkTemplateStatus(this.targetCollection.getEntitledModels());
        }

        this.listenTo(this.listBuilderView, 'targetListUpdated', util.bind(this.checkTemplateStatus, this));
    },

    checkTemplateStatus(selectedTemplates) {
        let enteredTemplates = [];
        let msg;
        /*
         * the message is generic, but I am saving all the templates incase at some
         * point we want to display which
         * templates are in EN status
         */
        enteredTemplates = util.filter(selectedTemplates, (template) => {
            const status = (util.isUndefined(template.STATUS)) ? template.get('STATUS') : template.STATUS;
            if (status === 'EN') {
                return template;
            }
            return undefined;
        });
        if (enteredTemplates.length > 0) {
            msg = alert.warning(locale.get('template.templateGroup.enteredTemplateWarning'));
            this.alertRegion.show(msg);
        } else {
            // clear the alert region
            this.alertRegion.close();
        }
    },

    injectHiddenTemplatesMessage(listBuilderView) {
        const hiddenTemplateCount = (this.mode === 'create') ? 0 : this.targetCollection.getNonEntitledModels().length;

        if (hiddenTemplateCount > 0) {
            listBuilderView.$('.target-section-header').append(` <small>${locale.get('payment.templategroups.notPermitted')}</small>`);
        }
    },

    onRender() {
        this.loadCollections();

        // remove create-only controls
        if (this.mode === 'edit') {
            this.$('.create-template-fields').remove();
        }
    },

    templateHelpers() {
        return {
            id: this.cid,
        };
    },

    handleSave() {
        let list = this.listBuilderView.getTargetList();
        if (this.mode === 'edit') {
            // merge any un-entitled models back in here
            const extraModels = util.map(this.targetCollection.getNonEntitledModels(), model => ({
                TNUM: model.get('TNUM'),
            }));

            list = list.concat(extraModels);
        }

        this.model.set({
            list,
        });
        this.model.save();
    },

    getButtons() {
        const self = this;
        return [{
            text: locale.get('common.save'),
            className: 'btn-primary',

            callback() {
                self.handleSave.apply(self);
            },
        }, {
            text: locale.get('common.cancel'),
            className: 'btn-tertiary',

            callback() {
                dialog.close();
            },
        }];
    },
});
