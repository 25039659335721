import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import constants from 'app/balanceAndTransaction/constants';

const EntitledGIRTypes = Model.extend({
    /**
     * Function to save or get data from server
     * @param  {string} method Action to perform
     * @param  {object} model model object affected
     * @param  {object} options object containing success and error callbacks
     * (parse by default)
     * @returns {Promise|undefined} - jQuery XHR/Promise
     */
    sync(method, model, options) {
        if (method === 'read') {
            const url = services.generateUrl(constants.ENTITLED_GIR_TYPES_URL);

            return http.post(url).success((response) => {
                options.success(response);
            }).fail(() => {
                options.error();
            });
        }
        return undefined;
    },

    /**
     * Default Function to modify data fetched from service
     * @param  {object} response data from service
     * @returns {object} Model attributes
     */
    parse(response) {
        return {
            entitledTypes: util.pluck(response, 'typeCode'),
        };
    },

    isEntitled(typeCode) {
        const entitledTypes = this.get('entitledTypes');
        if (entitledTypes) {
            return entitledTypes.indexOf(typeCode) !== -1;
        }
        return false;
    },
});

export default EntitledGIRTypes;
