import services from 'services';
import http from '@glu/core/src/http';
import LoanModel from 'app/loans/models/loan';
import validatorPatterns from 'system/validatorPatterns';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'app/loans/constants';

export default LoanModel.extend({
    defaults: constants.DEFAULTS,

    initialize() {
        this.validators = util.extend(
            {},
            constants.validators(),
            {
                SPECIAL_INSTRUCTIONS: {
                    description: locale.get('loans.comments'),
                    maxLength: 140,
                    matches: validatorPatterns.NOBADCHARS_PATTERN,
                },
                CUSTOMER_REFERENCE: {
                    description: locale.get('loans.customerref'),
                    maxLength: 16,
                    matches: validatorPatterns.NOBADCHARS_PATTERN,
                },
            },
        );
    },

    getServiceName(model) {
        const type = model.get('paymentType');
        if (type === 'LOANDRAW') {
            return services.loanDrawAdd;
        }
        if (type === 'LOANPAY') {
            return services.loanPaymentAdd;
        }
        return undefined;
    },

    convertServerJSONToModelAttributes(data) {
        const parsedData = {};
        for (let x = 0; x < data.length; x += 1) {
            parsedData[data[x].name] = data[x].value;
        }
        return parsedData;
    },

    getBusinessDays(data) {
        if (this.get('Bene_AccountEntitlement') && this.get('AccountFilter')) {
            http.post(services.sbDates, data, (response) => {
                this.trigger('retrieved', response);
            }, () => {
            // error
            });
        }
    },
});
