var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li data-account=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNTNAME") || (depth0 != null ? lookupProperty(depth0,"ACCOUNTNAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTNAME","hash":{},"data":data,"loc":{"start":{"line":11,"column":34},"end":{"line":11,"column":49}}}) : helper)))
    + "\" data-balance=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"TOTAL") || (depth0 != null ? lookupProperty(depth0,"TOTAL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TOTAL","hash":{},"data":data,"loc":{"start":{"line":11,"column":65},"end":{"line":11,"column":74}}}) : helper)))
    + "\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LI_CLASSES") || (depth0 != null ? lookupProperty(depth0,"LI_CLASSES") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LI_CLASSES","hash":{},"data":data,"loc":{"start":{"line":11,"column":83},"end":{"line":11,"column":97}}}) : helper)))
    + "\">\n                    <!-- "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DRAGGABLE_DROPPABLE") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":25},"end":{"line":12,"column":121}}})) != null ? stack1 : "")
    + " -->\n                    <h3>"
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNTNAME") || (depth0 != null ? lookupProperty(depth0,"ACCOUNTNAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTNAME","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":39}}}) : helper)))
    + "</h3>\n                    <h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"MASKEDACCOUNTNUMBER") || (depth0 != null ? lookupProperty(depth0,"MASKEDACCOUNTNUMBER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"MASKEDACCOUNTNUMBER","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":47}}}) : helper)))
    + "</h4>\n                    <div class=\"balance\">"
    + alias4(lookupProperty(helpers,"money").call(alias1,(depth0 != null ? lookupProperty(depth0,"TOTAL") : depth0),(depths[1] != null ? lookupProperty(depths[1],"preferredFormat") : depths[1]),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":15,"column":41},"end":{"line":15,"column":75}}}))
    + "</div>\n                    <div class=\"footnote\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NOTE") || (depth0 != null ? lookupProperty(depth0,"NOTE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NOTE","hash":{},"data":data,"loc":{"start":{"line":16,"column":42},"end":{"line":16,"column":50}}}) : helper)))
    + "</div>\n                </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<div class=\"drag-handle\"><span class=\"icon-rows\"></span></div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"flyout-menu\" id=\"menu-bills\">\n    <header>\n        <button type=\"button\" data-action=\"closeFlyout\" class=\"close-flyout\"><span class=\"icon-close\"></span><span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.close",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":131},"end":{"line":3,"column":156}}}))
    + "</span></button>\n        <a class=\"to-nav-primary\" href=\"/PAYMENTS/smbAccountsCenter\" data-action=\"closeFlyout\">\n            <h2 class=\"inline-display\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.account.center.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":39},"end":{"line":5,"column":83}}}))
    + "</h2><span class=\"icon-arrow-thin-right\"></span>\n        </a>\n    </header>\n    <div class=\"xfer-box\">\n        <ul class=\"accounts\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":18,"column":21}}})) != null ? stack1 : "")
    + "\n\n        </ul>\n        <div class=\"xfer-form\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.transfer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":46}}}))
    + "</h4>\n\n            <div class=\"flyout-menu-message\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.funds.transferred",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":55}}}))
    + "\n            </div>\n\n            <p><b>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.from",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":18},"end":{"line":35,"column":44}}}))
    + "</b> <span class=\"xfer-from\"></span></p>\n            <p class=\"clearfix\"><b>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":35},"end":{"line":36,"column":59}}}))
    + "</b> <span class=\"xfer-to\"></span></p>\n\n            <p class=\"pre-dollar\">\n                <label for=\"amount\" class=\"none\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":77}}}))
    + "</label>\n                <input name=\"amount\" value=\"\" class=\"xfer-amount\" autocomplete=\"off\" maxlength=\"12\">\n                <span class=\"xfer-amtconf\"></span>\n            </p>\n\n            <div class=\"topspace\">\n                <button type=\"button\" class=\"cancel btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":69},"end":{"line":45,"column":101}}}))
    + "</button>\n                <button type=\"button\" class=\"submit btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.transfer.funds",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":69},"end":{"line":46,"column":105}}}))
    + "</button>\n            </div>\n\n        </div>\n    </div>\n\n</nav>\n";
},"useData":true,"useDepths":true});