import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import confidentialSettingTypeViewTmpl from './confidentialSettingTypeView.hbs';

export default ItemView.extend({
    template: confidentialSettingTypeViewTmpl,
    tagName: 'div',
    className: 'account form-group',

    templateHelpers() {
        return {
            canModify: (this.options.canModify === true),
            resolveConfidentialSetting(value) {
                if (value === '1') {
                    return locale.get('type.confidentialImportSetting.always');
                }
                if (value === '2') {
                    return locale.get('type.confidentialImportSetting.prompt');
                }
                return locale.get('type.confidentialImportSetting.never');
            },
        };
    },
});
