import Layout from '@glu/core/src/layout';
import Grid from '@glu/grid';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import formatter from 'system/utilities/format';
import template from './approvalSequenceWarning.hbs';

export default Layout.extend({
    template,

    attributes: {
        role: 'dialog',
        'aria-hidden': 'false',
        class: 'modal',
    },

    events: {
        'click [data-hook="getYes"]': 'save',
        'click [data-hook="getNo"]': 'cancel',
    },

    initialize(options) {
        this.data = options.thresholdGaps.map((threshold) => {
            const sequences = threshold.sequences.map(sequence => `${locale.get('PS.panelApproval.Sequence')} ${sequence}`).join(', ');
            return {
                amount: `${formatter.formatNumber(threshold.amount)} ${options.currency}`,
                sequences,
            };
        });
    },

    onRender() {
        this.listRegion.show(new Grid({
            collection: new Collection(this.data),
            columns: [
                {
                    label: locale.get('PS.ThresholdAmount'),
                    field: 'amount',
                },
                {
                    label: locale.get('PS.panelApproval.Approval.Sequences'),
                    field: 'sequences',
                },
            ],
        }));
    },

    /**
     * @name save
     * @description 'ok' handler for warning dialog.  triggers 'warning:ok'
     */
    save() {
        this.close();
        this.trigger('warning:ok');
    },

    /**
     * @name cancel
     * @description 'cancel' handler for warning dialog. triggers 'warning:cancel'
     */
    cancel() {
        this.close();
    },
});
