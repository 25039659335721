import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import services from 'services';
import ElearningContentModel from 'app/administration/models/elearningContentModel';
import LocaleCollection from 'app/administration/collection/locales';
import errHandler from 'system/errHandler';
import JSZip from 'jszip';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import detailContentTmpl from './detailContent.hbs';
import detailContentViewTmpl from './detailContentView.hbs';

export default Layout.extend({
    template: detailContentTmpl,
    viewTemplate: detailContentViewTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'full-page',

    regions: {
        pageRegion: '.pageRegion',
        alertRegion: '.alert-region',
    },

    ui: {
        $contentFile: 'input[name="content-file"]',
        $loader: '.loader',
        $contentBtnGroup: '.content-action-btn-group',
    },

    events: {
        'change @ui.$contentFile': 'contentFileChanged',
    },

    initialize(options) {
        this.state = options.state;
        this.contentId = options.contentId;
        this.navigateRoute = '/CXP/elearning';
    },

    getTemplate() {
        if (this.loadingTemplate && this.hasLoadedRequiredData() === false) {
            return this.loadingTemplate;
        }
        if (this.state !== 'view') {
            return this.template;
        }
        return this.viewTemplate;
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
            return;
        }
        this.ui.$loader.hide();
    },

    contentFileChanged() {
        [this.contentFile] = this.ui.$contentFile[0].files;
        if (this.contentFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const zip = new JSZip();
                zip.loadAsync(e.target.result)
                    .then((zipContent) => {
                        if (zipContent !== undefined && zipContent.files !== undefined) {
                            util.each(zipContent.files, (file) => {
                                if (file.dir === false && file.name.indexOf('/') === -1 && (file.name.indexOf('.webm') > 0 || file.name.indexOf('.mp4') > 0)) {
                                    this.model.set('fileName', file.name);
                                } else if (file.dir === false && file.name.indexOf('/') === -1 && file.name.indexOf('.csv') > 0) {
                                    this.model.set('transcript', file.name);
                                }
                            });
                        }
                    });
            };
            reader.readAsArrayBuffer(this.contentFile);
        }
    },

    templateHelpers() {
        return {
            stateDescription() {
                // TODO: Should these be localized?
                switch (this.state) {
                case 'view':
                    return 'View';
                case 'update':
                    return 'Modify';
                case 'add':
                    return 'Add';
                default:
                }
                return undefined;
            },

            editMode: () => (this.state === 'update'),

            locales: () => this.locales.toJSON(),

            language: () => {
                let label = '';
                this.locales.each((localeObj) => {
                    if (localeObj.get('id') === this.model.get('locale')) {
                        label = localeObj.get('label');
                    }
                });
                return label;
            },
        };
    },

    addValidators() {
        this.model.addValidator(
            'name',
            {
                exists: true,
                description: locale.get('CXP.elearning.name'),
                matches: '^[A-Za-z0-9\\_]*$',
            },
        );
    },

    loadRequiredData() {
        const promiseArr = [
            // Load locales
            new Promise((resolve, reject) => {
                const options = {
                    isAdmin: true,
                };
                new LocaleCollection(options).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
        ];

        Promise.all(promiseArr)
            .then(([locales]) => {
                this.locales = locales;
                if (this.state === 'add') {
                    this.model = new ElearningContentModel();
                    this.model.set('locale', 'en_US');
                    this.addValidators();
                    this.setHasLoadedRequiredData(true);
                    this.render();
                } else {
                    this.model = new ElearningContentModel({
                        contentId: this.contentId,
                    });
                    this.model.fetch({
                        success: () => {
                            this.addValidators();
                            this.setHasLoadedRequiredData(true);
                            this.render();
                        },
                    });
                }
            })
            .then(null, errHandler);
    },

    save() {
        if (this.model.isValid()) {
            this.ui.$loader.show();
            this.ui.$contentBtnGroup.hide();
        }
        this.model.save(
            {},
            {
                success: () => {
                    if (this.contentFile === null) {
                        return;
                    }

                    const reader = new FileReader();

                    reader.onloadend = (readerEvt) => {
                        if (readerEvt.target.readyState === FileReader.DONE) {
                            const content = readerEvt.target.result;

                            const contentString = window.btoa(content);

                            const uploadModel = new Model();
                            uploadModel.set('fileName', this.contentFile.name);
                            uploadModel.set('fileData', contentString);
                            uploadModel.set('contentName', this.model.get('name'));

                            http.post(services.generateUrl('/cxp/upload'), uploadModel.toJSON(), (result) => {
                                if (result) {
                                    // We need to store the real url for the content
                                    const contentUrl = services.generateWidgetUrl(`${this.model.get('name')}`);
                                    this.model.set('contentUrl', contentUrl);
                                    this.model.save();
                                    this.navigateTo(this.navigateRoute);
                                }
                            });
                        }
                    };
                    if (this.contentFile) {
                        reader.readAsBinaryString(this.contentFile);
                    } else {
                        /* the content file is mandatory for insert,
                         but incase it's empty just navigate back
                         */
                        this.navigateTo(this.navigateRoute);
                    }
                },
            },
        );
    },

    cancel() {
        this.navigateTo(this.navigateRoute);
    },
});
