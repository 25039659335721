import React, {
    useState,
    useContext,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import useLocale from 'hooks/useLocale';
import {
    useValidation,
} from '@glu/validation-react';
import Layout from '@glu/layout-react';
import Alert from '@glu/alerts-react';
import FormFieldStatic from 'components/FormFieldStatic/FormFieldStatic';
import RequiredLabelText from 'components/RequiredLabelText/RequiredLabelText';
import { Button, PRIMARY, TERTIARY } from '@glu/buttons-react';
import FormFieldStaticContainer from 'components/FormFieldStaticContainer/FormFieldStaticContainer';
import ActionButtonContainer from 'components/ActionButtonContainer/ActionButtonContainer';
import useNavigation from 'hooks/useNavigation';
import LabelTextPopover from 'components/LabelTextPopover/LabelTextPopover';
import ValidatedCheckboxRadioGroup from 'components/ValidatedCheckboxRadioGroup/ValidatedCheckboxRadioGroup';
import useStyles from './StepTwo.styles';
import FooterContext from '../FooterWrapper/FooterContext';
import ValidatedContentEditorContainer from '../../ContentEditor/ValidatedContentEditorContainer';
import { ContentEditorProvider } from '../../ContentEditor/ContentEditorContext';

const propTypes = {
    returnRoute: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
};
const StepTwo = ({
    returnRoute,
    onSave,
}) => {
    const classes = useStyles();
    const { cancel } = useNavigation({
        returnRoute,
    });
    const { getLocaleString } = useLocale();
    const {
        footerData,
        marketSegmentOptions,
    } = useContext(FooterContext);
    const [ValidationProvider, validationValue,
        {
            validate,
            data,
            errors,
        }] = useValidation({ data: footerData });

    const [showContentError, setShowContentError] = useState(false);
    // Update setShowContentError state when error attributes changes
    useEffect(() => {
        setShowContentError(!!errors.FOOTERCONTENT?.length);
    }, [errors.FOOTERCONTENT]);

    const [isSaving] = useState(false);
    const save = () => {
        validate().then((result) => {
            const { isValid } = result;
            if (isValid) {
                onSave(data);
            }
        });
    };

    return (
        <>
            <FormFieldStaticContainer>
                <Layout container>
                    <FormFieldStatic
                        col={3}
                        label={getLocaleString('CMAINT.Locale')}
                        name="LOCALE_DESC"
                        value={footerData?.LOCALE_DESC}
                    />
                </Layout>
            </FormFieldStaticContainer>
            <div className="section section-container">
                <ValidationProvider value={validationValue}>
                    <form>
                        <section className={classes.tabContainer}>
                            {showContentError && (
                                <Alert
                                    variant="error"
                                    title={getLocaleString('PS.fixErrors')}
                                    onHide={() => setShowContentError(false)}
                                >
                                    {errors.FOOTERCONTENT?.map(error => (<p>{error}</p>))}
                                </Alert>
                            )}
                            <ContentEditorProvider>
                                <ValidatedContentEditorContainer
                                    content={footerData.FOOTERCONTENT}
                                    contentType="html"
                                    hasPills={false}
                                    maxCharacterCount={2000}
                                    name="FOOTERCONTENT"
                                    validators={{
                                        name: getLocaleString('CMAINT.Footer.content'),
                                        required: true,
                                    }}
                                    hidePreview
                                />
                            </ContentEditorProvider>
                        </section>
                        <section>
                            <ValidatedCheckboxRadioGroup
                                name="MARKETSEGMENTS"
                                autoSelectSingleOption
                                itemList={marketSegmentOptions.map(option => ({
                                    labelText: option.name,
                                    htmlValue: option.id,
                                }))}
                                labelText={(
                                    <LabelTextPopover
                                        labelText={(
                                            <RequiredLabelText
                                                label={getLocaleString('CMAINT.Market.Segments')}
                                            />
                                        )}
                                        popoverContent={getLocaleString('CMAINT.Market.Segments.popover.content')}
                                    />
                                )}
                                validators={{
                                    name: getLocaleString('CMAINT.Market.Segments'),
                                    required: true,
                                }}
                            />
                        </section>
                        <ActionButtonContainer>
                            <Button
                                className="btn btn-primary"
                                variant={PRIMARY}
                                text={getLocaleString('button.save')}
                                aria-busy={isSaving}
                                onClick={save}
                            />
                            <Button
                                variant={TERTIARY}
                                text={getLocaleString('button.cancel')}
                                onClick={cancel}
                            />
                        </ActionButtonContainer>
                    </form>
                </ValidationProvider>
            </div>
        </>
    );
};

StepTwo.propTypes = propTypes;
export default StepTwo;
