var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"back\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":142},"end":{"line":4,"column":184}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(alias1,"decisioning_title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":6,"column":31},"end":{"line":6,"column":64}}}))
    + "</h1>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"section-header\">\n                <h2>"
    + container.escapeExpression((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"decisioning_header",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":54}}}))
    + "</h2>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-sm field-container\">\n                            <label>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"account_number",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":65}}}))
    + " : </label>\n                            <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"accountNumber") || (depth0 != null ? lookupProperty(depth0,"accountNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"accountNumber","hash":{},"data":data,"loc":{"start":{"line":26,"column":36},"end":{"line":26,"column":55}}}) : helper)))
    + "</strong>\n                        </div>\n                        <div class=\"text-group field-container-sm field-container\">\n                            <label>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"account_title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":29,"column":35},"end":{"line":29,"column":64}}}))
    + " : </label>\n                            <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":30,"column":36},"end":{"line":30,"column":53}}}) : helper)))
    + "</strong>\n                        </div>\n                        <div class=\"text-group field-container-sm field-container\">\n                            <label>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"type",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":33,"column":35},"end":{"line":33,"column":55}}}))
    + " : </label>\n                            <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":46}}}) : helper)))
    + "</strong>\n                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary pay\" data-action=\"multiPay\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"pay",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":46,"column":93},"end":{"line":46,"column":118}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary payissue\" data-action=\"multiPayIssue\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"payissue",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":49,"column":105},"end":{"line":49,"column":135}}}))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary return\" data-action=\"multiReturn\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"return",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":52,"column":101},"end":{"line":52,"column":129}}}))
    + "</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary approve\" data-action=\"approve\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"approve",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":55,"column":98},"end":{"line":55,"column":127}}}))
    + "</button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-secondary unapprove\" data-action=\"unapprove\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"unapprove",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":58,"column":102},"end":{"line":58,"column":133}}}))
    + "</button>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"btn btn-secondary cancel\" data-action=\"back\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"cancel",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":61,"column":98},"end":{"line":61,"column":126}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isHistory") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"section section-container\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isHistory") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n                <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":77},"end":{"line":19,"column":103}}}))
    + "\" data-hook=\"export-button\"><span class=\"icon-export\"></span> "
    + alias2((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias3).call(alias1,"export",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":19,"column":165},"end":{"line":19,"column":193}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":76},"end":{"line":20,"column":101}}}))
    + "\" data-hook=\"print-button\"><span class=\"icon-print\"></span> "
    + alias2((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias3).call(alias1,"print",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":20,"column":161},"end":{"line":20,"column":188}}}))
    + "</button>\n            </div>\n            <div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideFields") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":36,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n                        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n        </div>\n    </div>\n    <div class=\"section section-container\">\n        <div class=\"section-body\">\n            <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPayEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":47,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPayIssueEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":50,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReturnEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":53,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUnapproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":59,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideFields") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":62,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});