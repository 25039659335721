import React, { Component } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import FlexDropdown from '@glu/react-wrappers/FlexDropdown';
import { getAdminAccessTokenPromise } from 'pcm/common/ptx-connect-integration/ptxConnectUtils';
import gluStore from '@glu/store';
import { http } from '@glu/core';
import $ from '@glu/core/src/$';

const constants = {
    dropdownUrl: '/api/roles',
    userGroup: '',
};

class DocumentRoleDropdown extends Component {
    state = {
        dropdownList: [],
        ptxError: false,
        errorOnDropdown: false,
    };

    componentDidUpdate = () => {
        $('[data-toggle="popover"]').popover();
    }

    componentDidMount = () => {
        const { isAdmin, userGroup, valueFromModel } = this.props;
        const tokenPromise = getAdminAccessTokenPromise;
        tokenPromise(userGroup, !isAdmin).then(() => {
            http.get(
                constants.dropdownUrl, null, null,
                {
                    headers: {
                        Authorization: `Bearer ${gluStore.get('ptxAccessToken')}`,
                        Accept: 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                },
            ).then((result) => {
                this.setState({
                    dropdownList: result.map(item => ({
                        ...item,
                        selected: valueFromModel === item.id,
                    })),
                });
            }, () => {
                this.setState({ ptxError: true });
            });
        }, () => {
            this.setState({ ptxError: true });
        });
    };

    onDropdownDataLoaded = (data) => {
        const dropdownList = data.map(item => ({
            id: item.id,
            name: item.description,
        }));
        this.setState({
            dropdownList,
        });
    };

    onChangeDocumentRole = (field, id) => {
        const { onChange } = this.props;
        const { dropdownList } = this.state;
        if (id !== undefined) {
            const ptxRoleName = dropdownList.find(item => item.id === id).name;
            onChange(id, ptxRoleName);
        }
    };
    getDropdownOptions = () => {
        const { dropdownList } = this.state;
        const { onChange } = this.props;

        return {
            filter: false,
            disableClear: true,
            disableMultiButton: true,
            showTooltip: false,
            showCancelIcon: false,
            defaultSelectMessage: locale.get('documentRole.select', ''),
            onChange: onChange ? this.onChangeDocumentRole : null,
            options: dropdownList || [],
        };
    };

    render() {
        const { name, required, valueFromModel } = this.props;
        const { ptxError } = this.state;

        if (ptxError) {
            return (
                <React.Fragment>
                    <div className="document-role-ptx-disabled">
                        <FlexDropdown
                            required={required}
                            disabled
                            name={name}
                            label={locale.get('userMaintenance.documentRole', '')}
                            {...this.getDropdownOptions()}
                        />
                        <span
                            data-trigger="hover"
                            data-container="body"
                            data-toggle="popover"
                            data-placement="top"
                            data-content={locale.get('ptx.error.generic', locale.get('ptx.error.prefixDocumentRole'))}
                            className="span-popover-trigger icon-exclamation-circle"
                        />
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <FlexDropdown
                    required={required}
                    name={name}
                    label={locale.get('userMaintenance.documentRole', '')}
                    value={valueFromModel}
                    onChange={this.props.onChange}
                    {...this.getDropdownOptions()}
                />
            </React.Fragment>
        );
    }
}

DocumentRoleDropdown.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    showDetails: PropTypes.bool,
    onLoad: PropTypes.func,
    selected: PropTypes.string,
    valueFromModel: PropTypes.string,
    isAdmin: PropTypes.bool,
    userGroup: PropTypes.string,
    onChange: PropTypes.func,
};

DocumentRoleDropdown.defaultProps = {
    name: 'PTXROLE',
    label: '',
    required: false,
    showDetails: true,
    onLoad: () => {},
    selected: null,
    isAdmin: false,
    userGroup: '',
    onChange: PropTypes.func,
};

export default DocumentRoleDropdown;
