import Layout from '@glu/core/src/layout';
import Glu from '@glu/core/src/glu';
import util from '@glu/core/src/util';
import $ from 'jquery';
import screenManager from '@glu/screen-manager';
import SmbAccountsFlyoutView from 'app/accounts/views/flyout/flyout';
import tmpl from './flyout.hbs';

export default Layout.extend({
    template: tmpl,
    tagName: 'div',
    className: 'flyout',

    appEvents: {
        menuHandleClick(e) {
            const menuItem = e.currentTarget;

            // set active class
            $('nav.glu-menu [class^="item-"]').removeClass('active');
            $(menuItem).addClass('active');

            // Show flyout for payments and accounts
            if (screenManager.isSmall()) {
                // do not show flyouts on small screens
                return;
            }

            if ($(menuItem).hasClass('item-PAYMENTS_smbAccountsCenter')) {
                this.accountsFlyout(e);
            } else {
                this.hideFlyout();
            }
        },

        'flyout:closeFlyout': 'hideFlyout',
    },

    /**
     * Method for accounts flyout - only called for smb users
     */
    accountsFlyout(e) {
        e.stopPropagation();
        e.preventDefault();

        if (!this.currentFlyout || !this.currentFlyout.currentView) {
            this.flyoutView = new SmbAccountsFlyoutView();
        }

        this.currentFlyout.show(this.flyoutView);
        this.showFlyout();
        return false;
    },

    /**
     * Method for hiding the flyout menu
     */
    hideFlyout() {
        $('body').removeClass('glu-flyout-menu-open');
    },

    /**
     * Method for showing the flyout menu
     */
    showFlyout() {
        // NH-170562: Only add open class on next tick to prevent immediate flyout closing
        util.defer(() => {
            $('body').addClass('glu-flyout-menu-open');
            // NH-170562: Focus on close button of flyout when its open
            this.currentFlyout.currentView?.ui.closeBtn.focus();
        });
    },

    onRender() {
        // When the route changes, the flyout menu should hide
        this.listenTo(Glu.Backbone.history, 'route', this.hideFlyout);
    },
});
