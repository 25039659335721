import ItemView from '@glu/core/src/itemView';
import UserChangesModel from 'app/administration/models/user2/userChanges';
import template from './pendingChanges.hbs';

export default ItemView.extend({
    template,
    className: 'panel-heading',

    ui: {
        $approveBtn: '[data-hook="getApproveBtn"]',
    },

    events: {
        'click @ui.$approveBtn': 'approveChanges',
    },

    initialize(options) {
        this.userProfile = options.userProfile;

        // passed in actions
        this.userActions = options.actions;

        this.model = new UserChangesModel({
            userProfile: {
                password: options.userProfile.get('PASSWORD') || '',
                userGroup: options.userProfile.get('USERGROUP'),
                userID: options.userProfile.get('USERID'),
                status: options.userProfile.get('STATUS'),
            },
        });

        this.listenTo(this.model, 'sync change', this.render);
        this.model.fetch();
    },

    approveChanges() {
        this.ui.$approveBtn.attr('aria-busy', true);
        return this.userActions.approve.callback();
    },

    templateHelpers() {
        return {
            collapseNeeded: !(this.options.noCollapse),
            panelClasses: () => {
                let panelClasses = 'panel-collapse collapse';
                if (this.options.noCollapse) {
                    panelClasses += ' in';
                }
                return panelClasses;
            },
            collapseId: `collapse-${this.model.cid}`,
            hasActions: (this.options.noButtons) ? false : this.userActions,
            approvalAction: this.userActions.approve,
            hasPendingChanges: (this.model.get('columns')) && (this.model.get('columns').length > 0),
        };
    },
});
