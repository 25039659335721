import Collection from '@glu/core/src/collection';
import AccountModel from '../model/accountModel';

const BeneAccounts = Collection.extend({
    model: AccountModel,

    hasPrimaryAccount() {
        return !!this.findPrimary();
    },

    clearAllPrimary() {
        this.invoke('set', 'PRIMARY_ACCOUNT', false);
    },

    findPrimary() {
        return this.find(model => model.isPrimary());
    },

    findExPrimary() {
        return this.find(model => model.wasPrimary());
    },
});

export default BeneAccounts;
