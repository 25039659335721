import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';

export default Collection.extend({
    initialize(requestData) {
        this.data = requestData || {};
    },
    baseUrl: 'achControlTotalMaintenance/',
    sync(method, collection, options) {
        let url = '';
        switch (method) {
        case 'read':
            url = services.generateUrl(`${this.baseUrl}read`);
            break;
        case 'delete':
            url = services.generateUrl(`${this.baseUrl}delete`);
            break;
        case 'restore':
            url = services.generateUrl(`${this.baseUrl}restore`);
            break;
        default:
            break;
        }
        this.data = options.reqOptions;
        http.post(url, this.data, (result) => {
            options.success(result);
        }, (err) => {
            options.error(err);
        });
    },
    delete(options) {
        this.sync('delete', this, options);
    },
    restore(options) {
        this.sync('restore', this, options);
    },
    parse(response) {
        const returnArray = [];
        util.each(response, (rowItem) => {
            const obj = {
                id: rowItem.ID,
                pigId: rowItem.PIGID,
                userGroup: rowItem.USERGROUP,
                nickName: rowItem.PIGIDNICKNAME,
            };
            returnArray.push(obj);
        });
        return returnArray;
    },

});
