import $ from 'jquery';
import util from '@glu/core/src/util';
import store from '@glu/store';
import { setPtxHeaders } from 'pcm/common/ptx-connect-integration/ptxConnectUtils';
import configuration from 'system/configuration';
import cookie from './cookie';
// PCM-1049, PCM-1063

$.ajaxPrefilter((optionsParam) => {
    const options = optionsParam;
    const { beforeSend } = options;
    options.beforeSend = function (xhr, ...rest) {
        // Treat the user is already logged in when there is a simulation token.
        if (cookie.get('SIMULATIONSESSIONTOKEN')) {
            store.set('alreadyLoggedInThisTab', 'true');
        }
        /**
         * When the application is not configured for SSO, this happens when the user
         * is logged in to a second tab :
         * store.get('userInfo').token !== cookie.get('x-csrf')
         *
         * When the application is configured for SSO, the aforementioned mismatch can still
         * occur if the DGB session was not invalidated properly (caused by the user not
         * logging out from the bank's portal due to the user navigating away to an external
         * page unrelated to the portal). If the user re-logs in at the portal login page a
         * new DGB session in initiated. So the token corresponding to the new session will
         * not match the token stored in the cached userInfo at the browser.
         * Hence we skip this block of code as it is OK to replace the userInfo in the
         * browser's cache with the new userInfo corresponding to the new DGB session
         * that is returned from the REST /security/user service call.
         */
        if (util.isEmpty(store.get('ssoEnabled')) && !util.isEmpty(store.get('userInfo'))
            && store.get('userInfo').token
            && store.get('userInfo').token !== cookie.get('x-csrf')) {
            xhr.setRequestHeader('staleSSOTab', 'true');
            /*
             * the server is going to redirect to the logging page. at this point we need to
             * treat it as a new tab.
             */
            store.unset('alreadyLoggedInThisTab');
        }

        /**
         *  For non-SSO environments, the header ‘newTab’ is sent in the request causing
         *  the server to return the 401 error code, forcing the user to the login page.
         *  The exception here is when launching a new UI Admin page from the legacy Bank Admin.
         *  In this case the 'newTab' header is NOT sent.
         *  For SSO, the newTab header is sent when running the Client.
         *  It is bundled  together with other indicators to check the conditions at the server
         *  to determine whether to return 401 and force a log off.
         */
        if (!configuration.isAdminPortal() && util.isEmpty(store.get('alreadyLoggedInThisTab'))) {
            xhr.setRequestHeader('newTab', 'true');
        }
        // if portal send the indicator to the bank end
        if (configuration.isPortal()) {
            xhr.setRequestHeader('isPortal', 'true');
        }

        xhr.setRequestHeader('__token__', cookie.get('x-csrf'));

        // PCM-1049, PCM-1063
        const ptx = configuration.isPtxConnectApiCall(options.url);
        // PCM-2486 (bellow - removed the necessity of check route if the api call is from PTX)
        // && (configuration.isPtxConnectRoute() || configuration.isPtxConnectRelatedRoutes());

        if (ptx) {
            // Every PCM ajax call may be either PCM or PTX-related.
            // When it's PTX-related, we need to have latest accessToken from memory (storage)
            // and inject as header. More details in main.jsx and ptxConnectUtils.jsx
            setPtxHeaders(xhr, options);
        }
        // PCM-1049, PCM-1063

        if (beforeSend) {
            beforeSend.apply(this, [xhr, ...rest]);
        }
    };
});
