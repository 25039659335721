import Layout from '@glu/core/src/layout';
import { Model } from '@glu/core';
import locale from 'system/webseries/api/locale';
import Formatter from 'system/utilities/format';
import constants from 'app/balanceAndTransaction/constants';
import DataTable from 'common/dynamicPages/views/dataTable/dataTable';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import accountUtil from 'app/smbPayments/util/accountUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import BTRTableView from './balancesTable';
import BalanceTransactionSummaryPanelView from './balanceTransactionSummaryPanel';
import template from './balanceTransactionSummaryView.hbs';

export default Layout.extend({
    template,

    ui: {
        $panel: '[data-hook="getPanel"]',
        $showDetailsBtnText: '[data-hook="getShowHideBtn"]',
        $summarySection: '[data-hook="getSummarySection"]',
    },

    events: {
        'click .show-details-btn span': 'handleShowHideAccSummary',
    },

    initialize() {
        this.rows = {};
        this.currency = undefined;
        this.balancesHidden = this.options.balancesHidden;
        this.title = this.getTitle();
        if (this.model) {
            this.listenTo(this.model, 'sync change', this.render);
            this.listenTo(this.options.poller, 'responseReceived', this.model.sync);
        } else {
            this.render();
        }
        if (this.options.gridView && this.options.gridView.getRows) {
            this.listenTo(this.options.gridView.getRows(), 'sync', () => {
                if (this.hasLoadedRequiredData()) {
                    this.displayTransactionSummary();
                }
            });
        }
        this.balanceTransactionSummaryPanelView = new BalanceTransactionSummaryPanelView({
            model: new Model(),
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.setTableRows();
            this.displayTransactionSummary();
            this.renderBtrInfoSummaryRegion();
        } else if (!this.dataFetched) {
            this.loadRequiredData();
            this.dataFetched = true;
        }
    },

    loadRequiredData() {
        // trigger initial service call
        if (!this.model) {
            this.setHasLoadedRequiredData(true);
        } else {
            this.model.fetch({
                success: () => {
                    this.setHasLoadedRequiredData(true);
                },
            });
        }
    },

    renderBtrInfoSummaryRegion() {
        this.balanceTransactionSummaryPanelRegion.show(this.balanceTransactionSummaryPanelView);
    },

    getTitle() {
        return this.balancesHidden ? locale.get('common.TransactionSummary') : locale.get('gir.BalanceTransactionSummary');
    },

    getDefaultBalances() {
        this.defaultBalances = {
            totalCredits: Formatter.formatNumberByCurrency(0, this.getCurrency()),
            totalCreditsCount: 0,
            totalDebits: Formatter.formatNumberByCurrency(0, this.getCurrency()),
            totalDebitsCount: 0,
        };
        return this.defaultBalances;
    },

    setTableRows() {
        if (!this.model) {
            return;
        }
        this.rows = this.model.get('columns');
        const currency = this.rows[locale.get('PAY.Currency')];
        if (currency) {
            this.model.set('currency', currency);
        }
        if (!this.balancesHidden) {
            this.totals = this.getTotals(this.rows);
        }
    },

    handleShowHideAccSummary() {
        if (this.ui.$panel.hasClass('collapse in')) {
            this.ui.$showDetailsBtnText.text(locale.get('gir.ShowDetails'));
            this.ui.$panel.collapse('hide');
            this.ui.$panel.removeAttr('style');
            this.ui.$summarySection.hide();
        } else {
            this.ui.$showDetailsBtnText.text(locale.get('gir.HideDetails'));
            this.ui.$panel.collapse('show');
            if (!this.balancesHidden) {
                this.displayBalanceSummary();
            }
            this.ui.$summarySection.show();
        }
    },

    displayBalanceSummary() {
        this.balanceSummaryTableView = new BTRTableView({
            rows: this.rows,
        });
        this.balanceSummaryRegion.show(this.balanceSummaryTableView);
    },

    /**
     * @method getListViewId
     * returns the listview viewId. Null otherwise
     */
    getListViewId() {
        if (this.options.gridView) {
            return this.options.gridView.wrapper.viewId || null;
        }
        return null;
    },

    displayTransactionSummary() {
        const gridFilters = this.getGridFilters();
        const context = {
            serviceName: this.getTransactionServiceName(),
            filters: gridFilters,
            viewId: this.getListViewId(),
            suppressListAction: true,
            suppressActionCells: true,
        };
        this.transactionSummaryTableView = new DataTable(context);
        this.handleSummaryInfo(this.transactionSummaryTableView);
        if (this.transactionSummaryRegion) {
            this.transactionSummaryRegion.show(this.transactionSummaryTableView);
        }
    },

    /**
     * Helper method to return the transaction totals service url based on tab id
     * @return string - service url
     */
    getTransactionServiceName() {
        const { accountType, tabId } = this.options;
        const isLoanAccount = accountUtil.isLoan(accountType);
        const isCreditCardAccount = accountUtil.isCreditCardAccount(accountType);
        if (isLoanAccount) {
            if (tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR) {
                return constants.LOAN_PREV_DAY_TRANSACTION_TOTALS;
            }
            if (tabId === constants.ACCOUNT_SUMMARY.TABS.CURRENT) {
                return constants.LOAN_CURRENT_DAY_TRANSACTION_TOTALS;
            }
            return constants.LOANS_BALANCE_TRANSACTION_TOTALS;
        }
        if (isCreditCardAccount) {
            if (tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR) {
                return constants.CREDIT_CARD_PREV_DAY_TRANSACTION_TOTALS;
            }
            if (tabId === constants.ACCOUNT_SUMMARY.TABS.CURRENT) {
                return constants.CREDIT_CARD_CURRENT_DAY_TRANSACTION_TOTALS;
            }
            return constants.CREDIT_CARD_BALANCE_TRANSACTION_TOTALS;
        }
        if (tabId === constants.ACCOUNT_SUMMARY.TABS.CUSTOM_REPORT_PRIOR) {
            if (serverConfigParams.get('CustRepoCurrDayDataForSeparatedPerm')?.toLowerCase() === 'true') {
                return constants.CUSTOM_ACCTS_SUMMARY_TRANSACTION_TOTALS;
            }
            return constants.CUSTOM_PREV_DAY_TRANSACTION_TOTALS;
        }
        if (tabId === constants.ACCOUNT_SUMMARY.TABS.PRIOR) {
            return constants.DEPOSIT_PREV_DAY_TRANSACTION_TOTALS;
        }
        if (tabId === constants.ACCOUNT_SUMMARY.TABS.CUSTOM_REPORT) {
            return constants.CUSTOM_ACCTS_SUMMARY_TRANSACTION_TOTALS;
        }
        if (tabId === constants.ACCOUNT_SUMMARY.TABS.CURRENT) {
            return constants.DEPOSIT_CURRENT_DAY_TRANSACTION_TOTALS;
        }
        return constants.BALANCE_TRANSACTION_TOTALS;
    },

    getGridFilters() {
        if (this.options.gridView) {
            return this.options.gridView.wrapper.generateFiltersDataBlock()
                .map((filter) => {
                    let fieldName = filter.fieldName.toUpperCase();
                    switch (fieldName) {
                    case 'ACCOUNTGROUP':
                        fieldName = 'ACCOUNTFILTER2';
                        break;
                    case 'DISPLAYAMOUNT':
                        fieldName = 'AMOUNT';
                        break;
                    case 'BAI_GROUP_CODE_DISPLAY':
                        fieldName = 'BAI_GROUP_CODE';
                        break;
                    case 'ASOFTIME':
                        fieldName = 'DATE';
                        break;
                    default:
                        break;
                    }
                    return { ...filter, ...{ fieldName } };
                });
        }
        return this.options.gridFilter || [];
    },

    handleSummaryInfo(view) {
        if (view && view.model) {
            view.on('table:loaded', () => {
                this.onTransactionSummaryTableLoaded(view);
            });
        }
    },

    defaultIfEmpty(val, defaultVal) {
        return util.isEmpty(val) ? defaultVal : val;
    },

    getTotals(rows) {
        const isSmbUser = userInfo.isSmbUser();
        if (isSmbUser) {
            const accountType = this.model?.get?.('accountType');
            const localeKey = accountUtil.getAvailableBalanceField(accountType);
            const field1Label = locale.get(`GIR.${localeKey}`);
            return {
                field1Label,
                field1Value:
                    Formatter.formatNumberByCurrency(rows[field1Label], this.getCurrency()),
            };
        }
        return {
            field1Label: rows.TOTALSCOLUMN1LABEL,
            field2Label: rows.TOTALSCOLUMN2LABEL,
            field1Value: Formatter.formatNumberByCurrency(
                this.rows[rows.TOTALSCOLUMN1LABEL],
                this.getCurrency(),
            ),
            field2Value: Formatter.formatNumberByCurrency(
                this.rows[rows.TOTALSCOLUMN2LABEL],
                this.getCurrency(),
            ),
        };
    },

    getContext(attributes) {
        const {
            CREDIT_AMOUNT, CREDIT_COUNT, DEBIT_AMOUNT, DEBIT_COUNT,
        } = attributes;
        return {
            totalCredits: Formatter.formatNumberByCurrency(CREDIT_AMOUNT, this.getCurrency()),
            totalCreditsCount: CREDIT_COUNT,
            totalDebits: Formatter.formatNumberByCurrency(DEBIT_AMOUNT, this.getCurrency()),
            totalDebitsCount: DEBIT_COUNT,
            currency: this.getCurrency(),
        };
    },

    getCurrency() {
        if (this.model) {
            return this.model.get('currency');
        }
        return this.options.currency;
    },

    onTransactionSummaryTableLoaded(view) {
        let context = {};
        const transactionSummaryTableModals = view.model.get('footerRow').models;
        if (transactionSummaryTableModals
            && transactionSummaryTableModals[0]
            && transactionSummaryTableModals[0].attributes) {
            if (this.balanceTransactionSummaryPanelView) {
                context = this.getContext(transactionSummaryTableModals[0].attributes);
            }
        }
        this.balanceTransactionSummaryPanelView.model.set({
            ...this.getDefaultBalances(),
            ...context,
            ...this.totals,
            currency: this.getCurrency(),
            ready: true,
        });
        this.balanceTransactionSummaryPanelView.render();
    },
    templateHelpers() {
        return {
            balancesHidden: this.balancesHidden,
            title: this.title,
        };
    },
});
