import Layout from '@glu/core/src/layout';
import { getMaskingConfiguration } from 'common/util/maskingUtil';
import accountInputTmpl from 'mobile/common/views/inputs/accountInput.hbs';

export default Layout.extend({
    template: accountInputTmpl,

    ui: {
        $getAccountNum: '[data-hook="getAccountNum"]',
        $selectPlaceholder: '[data-hook="getSelectPlaceholder"]',
    },

    onRender() {
        /*
         * On consecutive re-renders, the handlebars template does not
         * select the first option as it should so need to manually
         * select it
         */
        this.ui.$selectPlaceholder.attr('selected', 'selected');
    },

    templateHelpers() {
        return {
            accounts: this.options.accounts.toJSON(),
            getAccountDisplay: this.getAccountDisplay,
            accountDisabled: this.options.accounts.length === 0,
            hasOneAccount: this.options.accounts.length === 1,
        };
    },

    /**
     * Get the display value for the account
     *
     * @returns {string} - account number display
     */
    getAccountDisplay() {
        const maskConfig = getMaskingConfiguration();
        // When masking is disabled, use existing rdc pattern
        if (maskConfig.disableMask) {
            return `x${this.ACCOUNTNUM_DISP.substring(this.ACCOUNTNUM_DISP.length - 4)}`;
        }
        return this.ACCOUNTNUM_DISP;
    },

    /**
     * Disable/enable the account number selector
     * @param {boolean} disabled
     */
    toggleDisabled(disabled) {
        this.ui.$getAccountNum.prop('disabled', disabled);
    },
});
