import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import balancesTableTmpl from './balancesTable.hbs';

const BalancesTable = Layout.extend({
    template: balancesTableTmpl,

    ui: {
        $tableView: '[data-hook="getTableView"]',
    },

    initialize() {
        const blackListedKeys = [
            locale.get('PAY.Currency'),
            'TOTALSCOLUMN1',
            'TOTALSCOLUMN2',
            'TOTALSCOLUMN1LABEL',
            'TOTALSCOLUMN2LABEL',
        ];
        this.rows = util.chain(this.options.rows)
            .omit(blackListedKeys)
            .pick(util.identity)
            .value();
        this.renderTableColumns();
    },

    /**
     * Helper method that programmatically re-arrange rows in
     * order to place N number of rows into a balanced two columns
     */
    renderTableColumns() {
        const numberOfRows = util.size(this.rows);
        // Start splitting the rows into two columns when column 1 reaches 8 rows
        if (numberOfRows > 8) {
            const column1 = {};
            const column2 = {};
            const numberOfRowsInColumn1 = Math.ceil(numberOfRows / 2);
            util.each(this.rows, (v, k) => {
                if (util.size(column1) < numberOfRowsInColumn1) {
                    column1[k] = v;
                } else {
                    column2[k] = v;
                }
            });
            this.columns = [column1, column2];
        } else {
            this.columns = [this.rows];
        }
    },

    templateHelpers() {
        return {
            columns: this.columns,
        };
    },
});

export default BalancesTable;
