import Layout from '@glu/core/src/layout';
import NestedModel from '@glu/core/src/nestedModel';
import userInfo from 'etc/userInfo';
import Formatter from 'system/utilities/format';
import locale from '@glu/locale';
import services from 'services';
import http from '@glu/core/src/http';
import { log } from '@glu/core';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import store from 'system/utilities/cache';
import Scheduler from '@glu/scheduler';
import entitlements from 'common/dynamicPages/api/entitlements';
import workspaceHelper from 'common/workspaces/api/helper';
import ScheduleModel from '../models/schedulePayment';
import schedulePaymentTmpl from './schedulePayment.hbs';

export default Layout.extend({
    template: schedulePaymentTmpl,

    ui: {
        headline: '.landing-header',
        schedulePayWarning: '[data-hook="schedulePayWarning"]',
    },

    events: {
        'click [data-hook="submit-button"]': 'saveRecurringPayment',
        'click [data-hook="cancel-button"]': 'cancel',
    },

    regions: {
        schedRegion: '[data-hook="schedulerRegion"]',
        alertRegion: '[data-hook="alertRegion"]',
    },

    initialize(options) {
        const self = this;
        this.schedModel = new NestedModel();
        this.options = options;

        // Set model values based on passed in values from options
        this.setModelValues(this.options);

        // Default the radio buttons
        this.model.set('NONBUSINESSDAYMODE', 'prev');

        /*
         * Read in any existing schedule values that were previously set and default
         * the screen to use them
         */
        this.model.read(this.model.get('TNUM')).then((response) => {
            const parsedResponse = self.model.parse(response);

            if (parsedResponse.PSTATUS === 'AP') {
                const ctx = {
                    serviceName: 'template/payment',
                };
                let functionCode = 'TMPL';
                if (parsedResponse.PRODUCT === 'USACH') {
                    functionCode = 'BHTMPL';
                }
                const opt = {
                    context: ctx,
                    productCode: parsedResponse.PRODUCT,
                    functionCode,
                    typeCode: parsedResponse.PTYPE,
                    actionMode: 'INSERT',
                    entryMethod: '0',
                    restrictions: ['AUTOAPPROVE'],
                };
                const transferRestrictionPromise = entitlements.getActionRestrictions(opt);
                transferRestrictionPromise.then((result) => {
                    if (result.restrictions[0].value1 !== 'true') {
                        self.ui.schedulePayWarning.text(locale.get('ACH.SchedulePayChangeWarning'));
                    }
                });
            } else {
                self.ui.schedulePayWarning.text(locale.get('ACH.SchedulePayWarning', parsedResponse.TEMPLATEDESC));
            }

            // Only worry about the read in values if there is an existing saved schedModel
            if (parsedResponse.schedModel && (parsedResponse.schedModel.get('type') === 'WEEKLY' || parsedResponse.schedModel.get('type') === 'MONTHLY')) {
                self.schedModel = parsedResponse.schedModel;

                if (parsedResponse.NONBUSINESSDAYMODE) {
                    self.model.set('NONBUSINESSDAYMODE', parsedResponse.NONBUSINESSDAYMODE);
                }

                if (parsedResponse.EVENTNAME && self.model.get('PRODUCT') === 'USACH') {
                    self.model.set('EVENTNAME', parsedResponse.EVENTNAME);
                }
            }
        });
        this.listenTo(this.model, 'modelAction:modifyWithWarning', this.modifyWithWarning);
        this.listenTo(this.model, 'modelAction:errorForDetail', this.cancelWarning);
    },

    onRender() {
        const self = this;
        const dateFormat = userInfo.getDateFormat();

        const dateFormats = {
            dateFormats: {
                server: 'YYYY-MM-DD',
                ui: dateFormat,
            },
        };

        this.ui.headline.text(locale.getDefault('tableMaintenance.scheduledPayments_title-spaymt', 'Schedule Payment'));

        /*
         * Get inquiry data that will be needed to do the date call and setup the
         * scheduler. Code will wait for both calls to complete
         */
        const paymentData = new Promise((resolve) => {
            resolve(self.getPaymentType());
        });

        const bankData = new Promise((resolve) => {
            resolve(self.getBankInfo());
        });

        /*
         * Don't run until after all inquiry data has been retrieved.
         * Then do call to get date info and setup the scheduler
         */
        Promise.all([paymentData, bankData]).then((results) => {
            self.model.set({
                PAYMENTTYPE: results['0'].inquiryResponse.rows['0'].columns['0'].fieldValue,
                PAYMENTSUBTYPE: results['0'].inquiryResponse.rows['0'].columns['1'].fieldValue,
                COUNTRYCODE: results['1'].inquiryResponse.rows['0'].columns['0'].fieldValue,
                BANKCODE: results['1'].inquiryResponse.rows['0'].columns['1'].fieldValue,
            });

            const dateCallData = {
                paymentType: self.model.get('PAYMENTTYPE'),
                subType: self.model.get('PAYMENTSUBTYPE'),
                dateProductType: 'SCHEDULED',
                debitBank: self.model.get('BANKCODE'),
                debitCurrency: self.model.get('CMB_DEBIT_CURRENCY'),
                debitBankCountry: self.model.get('COUNTRYCODE'),
                creditCurrency: self.model.get('CMB_CREDIT_CURRENCY'),
                tnum: self.model.get('TNUM'),
            };

            const dateService = services.generateUrl('/date/list');

            http.post(dateService, dateCallData, (response) => {
                /*
                 * Setup the scheduler once the data call has completed.
                 * The scheduler does not currently support the setting
                 * of valid business days when selecting days of the week.
                 * Once it does this will need to be updated using the
                 * BUSINESSDAY value returned by the date call
                 */
                self.scheduler = new Scheduler({
                    model: self.schedModel,
                    startDate: dateFormats,
                    startValue: self.schedModel.get('starts')
                        || Formatter.formatDate(response.defaultDay, 'YYYY-MM-DD'),
                    weekly: true,
                    monthly: true,
                    daily: false,
                    ends: dateFormats,
                    readOnly: false,
                });
                self.schedRegion.show(self.scheduler);
            }, (err) => {
                log.error(err);
            });
        });
    },

    // Set passed in values to the model
    setModelValues() {
        const passedInValues = this.options.opts.attributes;
        this.model = new ScheduleModel();
        this.model.set('TYPEDESC', passedInValues.CMB_TYPE_DESCRIPTION);
        this.model.set('TEMPLATEDESC', passedInValues.CMB_TEMPLATE_DESCRIPTION);
        this.model.set('TEMPLATECODE', passedInValues.CMB_TEMPLATE_CODE);
        this.model.set('TNUM', passedInValues.TNUM);
        this.model.set('USERGROUP', passedInValues.USERGROUP);
        this.model.set('CMB_DEBIT_CURRENCY', passedInValues.CMB_DEBIT_CURRENCY);
        this.model.set('CMB_BENE_BANK_ID', passedInValues.CMB_BENE_BANK_ID);
        this.model.set('CMB_CREDIT_CURRENCY', passedInValues.CMB_CREDIT_CURRENCY);
        this.model.set('CMB_DEBIT_ACCOUNT_NUMBER', passedInValues.CMB_DEBIT_ACCOUNT_NUMBER);
        this.model.set('PRODUCT', passedInValues.PRODUCT);
        this.model.set('STATUS', passedInValues.STATUS);
        this.model.set('TYPE', passedInValues.TYPE);
        this.model.set('FUNCTION', passedInValues.FUNCTION);
        this.model.set('FUNCTIONCODE', passedInValues.FUNCTION);
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    getPaymentType() {
        const url = services.generateUrl('/inquiry/getData');
        const tnumValue = [this.model.get('TNUM')];

        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40013,

                    searchFields: [{
                        fieldName: 'TNUM',
                        fieldValue: tnumValue,
                        dataType: 'text',
                        operator: 'IN',
                    }],
                },
            },
        };

        return http.post(url, postData);
    },

    getBankInfo() {
        const url = services.generateUrl('/inquiry/getData');
        const accountValue = [this.model.get('CMB_DEBIT_ACCOUNT_NUMBER')];
        const userGroupValue = [this.model.get('USERGROUP')];

        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    searchType: '5',
                    inquiryId: 40014,

                    searchFields: [{
                        fieldName: 'ACCOUNTNUM',
                        fieldValue: accountValue,
                        dataType: 'text',
                        operator: 'IN',
                    }, {
                        fieldName: 'USERGROUP',
                        fieldValue: userGroupValue,
                        dataType: 'text',
                        operator: 'IN',
                    }],
                },
            },
        };

        return http.post(url, postData);
    },

    saveRecurringPayment() {
        const self = this;

        /*
         * This is always 'update' because we are updating
         * a record that was created with the template
         */
        const url = services.generateUrl('tableMaintenance/scheduledPayments/update');

        return http.post(
            url,
            this.model.convertModelAttributesToServerJSON(this.schedModel),
            (response) => {
                /*
                 * Set response into 'store' so it can be used when
                 * displaying the success message on the Template Management screen
                 */
                store.set('template_listView_corp-confirms', response);
                store.set('template_listView_corp-alertMessage', 'MODIFY');
                workspaceHelper.returnToCurrentWorkspace(self);
            }, (response) => {
                if (response.responseJSON.resultType === 'WARNING') {
                    dialog.custom(new WarningDialog({
                        model: self.model,
                        methodName: 'MODIFY',
                        store,
                        detailPage: self,
                        confirms: response.responseJSON.confirms,
                    }));
                } else {
                    self.alertView = alert.danger(response.responseJSON.confirms.confirmResults['0'].messages['0']);
                    self.alertRegion.show(self.alertView);
                }
            },
        );
    },

    // Callback method handling warning for modify
    modifyWithWarning() {
        if (!this.tempModel && this.model) {
            this.tempModel = this.model;
        }
        this.model.set({
            _saveWithWarning: 'true',
        });
        this.saveRecurringPayment();
    },

    // Callback method handling cancel on warning
    cancelWarning() {
        const $button = this.$('[data-hook="submit-button"]');
        $button.prop('disabled', false);
    },

    templateHelpers() {
        return {
            isACH: this.model.get('PRODUCT') === 'USACH',
        };
    },
});
