import React from 'react';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import ReportsListView from 'app/reports/views/reportsListView';
import ReportsListViewDeeplink from 'app/reports/views/reportsListViewDeeplink';
import ReportView from 'app/reports/views/reportView';
import LegacyReportsListView from 'app/reports/views/legacyReportsListView';
import LegacyReportView from 'app/reports/views/legacyReportView';
import PaymodeReportsWidget from 'common/uiWidgets/pmxReportsWidget/pmxReportsWidget';
import PaymodeVendorNetworkWidget from 'common/paymodeIntegration/vendorNetworkWidget';
import constants from 'app/reports/constants';
import StackView from 'common/stack/views/stackView';
import ImageSearchView from 'app/reports/views/imageSearch/imageSearch';
import EstatementView from 'app/reports/views/eStatement/eStatement';
import LockboxContentWrapperView from 'app/reports/views/lockbox/lockboxReportBase';
import LockboxImages from 'app/reports/views/lockbox/lockboxCheckImage';
import LockboxTransactionView from 'app/reports/views/lockbox/lockboxReportDetails';
import LockboxEnvelopeView from 'app/reports/views/lockbox/lockboxReportEnvelope';
import LockboxRemitterView from 'app/reports/views/lockbox/lockboxListRemitters';
import LockboxAvailabilityView from 'app/reports/views/lockbox/lockboxAvailability';
import ImportRemitterName from 'app/reports/views/lockbox/importRemitterName';
import ExportBTRListView from 'app/reports/views/exportBTRListView';
import ExportBTRDetailView from 'app/reports/views/exportBTRDataDetail';
import CTRLReport from 'app/reports/views/cd/cdReport';
import ControlDisbursementImageView from 'app/reports/views/cd/cdImage';
import store from 'system/utilities/cache';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import { getErrorView } from 'common/util/deeplinkUtil';
import CFFWorkspace from 'components/Cff/CFF';
import services from 'services';
import ErrorView from 'system/error/views/errorMessage';
import configuration from 'system/configuration';
import WorkspaceView from 'common/workspaces/views/workspace';

export default {
    // This is needed to render the PMX_REPORTS_WIDGET in DeepLinks
    pmxReportsWidget() {
        if (processDeeplink('REPORTING/pmxReportsWidget', this)) {
            this.showPage('', new PaymodeReportsWidget());
        }
    },

    // This is needed to render the PMX_VENDOR_NETWORK in DeepLinks
    pmxVendorNetworkWidget() {
        if (processDeeplink('REPORTING/pmxVendorNetworkWidget', this)) {
            this.showPage('', new PaymodeVendorNetworkWidget());
        }
    },

    listReports() {
        if (processDeeplink('REPORTING/reportsListViewInquiry', this)) {
            this.showPage('ListView Reports', new ReportsListView({}));
        }
    },

    viewReport() {
        const storedPreObj = store.remove(
            'reportDetails',
            {
                context: {
                    productCode: 'RPTS',
                    functionCode: 'VIEW_REPORT',
                },
            },
        );

        if (storedPreObj !== null) {
            this.showPage('Reports', new ReportsListViewDeeplink({
                storedPreObj,
            }));
        } else {
            this.showPage('Report', new ReportView({
                model: store.get(constants.REPORTING_VIEWMODEL),
                lastFragment: this.lastFragment,
            }));
        }
    },

    viewLegacyReport() {
        this.pushPage(
            'Legacy Report',
            new LegacyReportView({
                model: store.get(constants.REPORTING_VIEWMODEL),
            }),
        );
    },

    rptDownloadWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'DOWNLOAD',
                    returnRoute: 'REPORTING/btrExport',
                }),
            }));
        }
    },

    rptDownload() {
        if (processDeeplink('REPORTING/download', this)) {
            this.showPage('Export List View', new ExportBTRListView({
                returnRoute: 'REPORTING/download',
            }));
        }
    },

    rptLegacyReportsWorkspace() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new WorkspaceView({
                        id: 'LEGACY_REPORTS',
                        returnRoute: constants.LEGACY_REPORT_LISTVIEW_ROUTE,
                    }),
                }),
            );
        }
    },

    rptLegacyReportsList() {
        if (processDeeplink('REPORTING/legacyReportsList', this)) {
            this.showPage('', new LegacyReportsListView());
        }
    },

    rptBTRWorkspace() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new WorkspaceView({
                        id: 'BAL_TRAN_REPORTING',
                        returnRoute: 'REPORTING/balanceAndTransactionReporting',
                    }),
                }),
            );
        }
    },

    rptReportManagementWorkspace() {
        this.showPage(
            '',
            new StackView({
                initItem: new WorkspaceView({
                    id: 'REPORTS_MANAGEMENT',
                    returnRoute: 'REPORTING/reportsManagement',
                }),
            }),
        );
    },

    rptPmxReportsWorkspace() {
        this.showPage(
            '',
            new StackView({
                initItem: new WorkspaceView({
                    id: 'PMX_REPORTS',
                    returnRoute: 'REPORTING/pmxReports',
                }),
            }),
        );
    },

    /**
     * @method rptLockboxSummaryReport
     * @param {String} fromBack - identifies if we are coming to this action via
     * back button of the drill down screen
     * - This method handles the display of the lockBox workspace.
     *   When we navigate to this action from the menu 'fromBack' will be undefined
     * - then it will create a new StackView and add the current view to it.
     *   When we navigate to this by hitting back from the details page, the 'fromBack'
     * - is 'Y' then we pop the page from the stack and show it.
     */
    rptLockboxSummaryReport(fromBack) {
        if (processDeeplink('REPORTING/lockbox', this)) {
            if (fromBack === 'Y') {
                this.popPage();
            } else {
                this.showPage(
                    '',
                    new StackView({
                        initItem: new LockboxContentWrapperView({}),
                    }),
                );
            }
        }
    },

    /**
     * @method rptLockboxSummaryReportWorkspace
     * @param {String} fromBack - identifies if we are coming to this action via
     * back button of the drill down screen
     * - This method handles the display of the lockBox workspace.
     *   When we navigate to this action from the menu 'fromBack' will be undefined
     * - then it will create a new StackView and add the current view to it.
     *   When we navigate to this by hitting back from the details page, the 'fromBack'
     * - is 'Y' then we pop the page from the stack and show it.
     */
    rptLockboxSummaryReportWorkspace(fromBack) {
        if (fromBack === 'Y') {
            this.popPage();
        } else {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'LOCKBOX_REPORTING',
                    returnRoute: 'REPORTING/lockbox/reports/Y',
                }),
            }));
        }
    },

    /**
     * @method rptLockboxCheckImage
     * - This method handles the display of the check image from the lock box
     * details or the envelope details, It just pushes the view to the stack.
     */
    rptLockboxCheckImage() {
        this.pushPage(
            'LBX.check.image',
            new LockboxImages({
                returnRoute: 'REPORTING/lockbox/reports/envelopeDetails/Y',
            }),
        );
    },

    /**
     * @method rptLockboxTransactionDetails
     * @param {String} fromBack - identifies if we are coming to this action
     * navigating forward or backward using back button of the drill down screen.
     * This method handles the display of the lockBox transaction details.
     * When we navigate to this action forward 'fromBack' will be undefined
     * then it will add the current view to the stack.
     * When we navigate to this by hitting back from the envelope details page
     * or check image page, the 'fromBack' is 'Y' then we pop the page
     * from the stack and show it.
     */
    rptLockboxTransactionDetails(fromBack) {
        if (fromBack === 'Y') {
            this.popPage();
        } else {
            this.pushPage(
                'LBX.TransactionDetails',
                new LockboxTransactionView({
                    returnRoute: 'REPORTING/lockbox/reports/Y',
                }),
            );
        }
    },

    /**
     * @method rptLockboxEnvelopeDetails
     * @param {String} fromBack - identifies if we are coming to this action
     * navigating forward or backward using back button of the drill down screen.
     * This method handles the display of the lockBox envelope details.
     * When we navigate to this action forward 'fromBack' will be undefined
     * then it will add the current view to the stack. When we navigate to this
     * by hitting back from the envelope details page or check image page, the
     * 'fromBack' is 'Y' then we pop the page from the stack and show it.
     */
    rptLockboxEnvelopeDetails(fromBack) {
        if (fromBack === 'Y') {
            this.popPage();
        } else {
            this.pushPage(
                locale.get('LBX.EnvelopeDetails'),
                new LockboxEnvelopeView({
                    returnRoute: 'REPORTING/lockbox/reports/details/Y',
                }),
            );
        }
    },

    rptLockboxAvailability() {
        if (processDeeplink('REPORTING/lockbox/availability', this)) {
            this.showPage('Lockbox Availability', new LockboxAvailabilityView());
        }
    },

    rptLockboxAvailabilityDetails() {
        this.showPage('Lockbox Availability', new LockboxAvailabilityView({
            isAccountDetails: true,
        }));
    },

    rptLockboxAvailabilityWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'LOCKBOX_AVAILABLE',
                    returnRoute: 'REPORTING/lockbox/availability',
                }),
            }));
        }
    },

    showImageSearch() {
        const title = locale.get('menuitem.SETUP.imageSearch');
        this.showPage(title, new ImageSearchView());
    },

    imageSearchWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'IMAGE_SEARCH',
                    returnRoute: 'REPORTING/imageSearch',
                }),
            }));
        }
    },

    imageSearch() {
        if (processDeeplink('REPORTING/showImageSearch', this)) {
            this.showPage('Image Search', new ImageSearchView({
                returnRoute: 'REPORTING/imageSearch',
            }));
        }
    },

    rptListRemitters() {
        this.pushPage(
            locale.get('reports.manage.remitters'),
            new LockboxRemitterView({
                returnRoute: 'REPORTING/lockbox/reports/details/Y',
            }),
        );
    },

    rptRemitterFimport() {
        const options = {
            menuCategory: 'Reporting',
            serviceName: '/fimport/import',
            serviceFunc: '',
            businessType: '',
            context: '',
            returnRoute: 'REPORTING/lockbox/remitters',
            mode: 'import',
        };
        this.showPage('Import Remitter Names', new ImportRemitterName(options));
    },

    btrExport() {
        this.showPage(locale.get('reports.export.listview'), new ExportBTRListView({
            returnRoute: 'REPORTING/btrExport',
        }));
    },

    rptEStatementSearchWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'ESTATEMENTS',
                }),
            }));
        }
    },

    rptEStatementSearch() {
        if (processDeeplink('REPORTING/eStatement', this)) {
            this.showPage(locale.get('menuitem.eStatements.search'), new EstatementView());
        }
    },

    rptCtrlDisbursementReport() {
        if (processDeeplink('REPORTING/showCtrlDisbursement', this)) {
            this.showPage('gir.controlDisbursementReport', new CTRLReport());
        }
    },

    rptControlDisbursementReport() {
        if (!this.popPage()) {
            this.showPage(
                '',
                new StackView({
                    initItem: new WorkspaceView({
                        id: 'CTRL_DISB_REPORT',
                        returnRoute: 'REPORTING/ctrlDisbursementReport',
                    }),
                }),
            );
        }
    },

    rptShowControlDisbursementImage() {
        const title = locale.get('gir.transactions');
        const model = store.get('deposit_data');
        const options = {
            pageTitle: title,
            accountFilter: model.get('ACCOUNTFILTER'),
            model,
        };

        this.showPage(title, new ControlDisbursementImageView(options));
    },

    viewExportDetail() {
        this.showPage('', new ExportBTRDetailView());
    },

    rptNotEntitled() {
        this.showPage('', getErrorView());
    },

    cffWidgetForecast() {
        if (configuration.isPortal()) {
            this.cffWidgetPortal('REPORTING/cff/forecast');
        } else {
            this.cffWidgetForecastRegularAccess();
        }
    },

    cffWidgetTrans() {
        if (configuration.isPortal()) {
            this.cffWidgetPortal('REPORTING/cff/transactions');
        } else {
            this.cffWidgetTransRegularAccess();
        }
    },

    cffWidgetSettings() {
        if (configuration.isPortal()) {
            if (processDeeplink('REPORTING/cff/forecast', this) || processDeeplink('REPORTING/cff/transactions', this)) {
                // eslint-disable-next-line
                this.showPage('', <CFFWorkspace />);
            } else {
                this.showPage('', getErrorView());
            }
        } else {
            this.cffWidgetSettingsRegularAccess();
        }
    },

    cffWidgetForecastRegularAccess() {
        const url = services.generateUrl('accessService/hasAccess');
        const hasForecastEntitlements = {
            functionCode: 'CFF',
            actionMode: 'SELECT',
            productCode: 'GIR',
            typeCode: 'FORECAST',
        };

        const forecastEntitlementPromise = new Promise((resolve, reject) => {
            http.post(url, hasForecastEntitlements, resolve, reject);
        });

        forecastEntitlementPromise.then((forecastEntitled) => {
            if (forecastEntitled) {
                // eslint-disable-next-line
                    this.showPage('', <CFFWorkspace />);
            } else {
                const errorPage = new ErrorView({
                    key: 'notAuthorized',
                    noContainer: true,
                });
                this.showPage('', errorPage);
            }
        });
    },

    cffWidgetTransRegularAccess() {
        const url = services.generateUrl('accessService/hasAccess');
        const hasTransactionsEntitlements = {
            functionCode: 'CFF',
            actionMode: 'SELECT',
            productCode: 'GIR',
            typeCode: 'TRAN',
        };

        const transactionsEntitlementsPromise = new Promise((resolve, reject) => {
            http.post(url, hasTransactionsEntitlements, resolve, reject);
        });

        transactionsEntitlementsPromise.then((transactionEntitled) => {
            if (transactionEntitled) {
                // eslint-disable-next-line
                    this.showPage('', <CFFWorkspace />);
            } else {
                const errorPage = new ErrorView({
                    key: 'notAuthorized',
                    noContainer: true,
                });
                this.showPage('', errorPage);
            }
        });
    },

    cffWidgetSettingsRegularAccess() {
        const url = services.generateUrl('accessService/hasAccess');
        const hasForecastEntitlements = {
            functionCode: 'CFF',
            actionMode: 'SELECT',
            productCode: 'GIR',
            typeCode: 'FORECAST',
        };

        const hasTransactionsEntitlements = {
            functionCode: 'CFF',
            actionMode: 'SELECT',
            productCode: 'GIR',
            typeCode: 'TRAN',
        };

        const forecastEntitlementPromise = new Promise((resolve, reject) => {
            http.post(url, hasForecastEntitlements, resolve, reject);
        });
        const transactionsEntitlementsPromise = new Promise((resolve, reject) => {
            http.post(url, hasTransactionsEntitlements, resolve, reject);
        });

        Promise.all([forecastEntitlementPromise, transactionsEntitlementsPromise])
            .then(([forecastEntitled, transactionEntitled]) => {
                if (forecastEntitled || transactionEntitled) {
                    // eslint-disable-next-line
                    this.showPage('', <CFFWorkspace />);
                } else {
                    const errorPage = new ErrorView({
                        key: 'notAuthorized',
                        noContainer: true,
                    });
                    this.showPage('', errorPage);
                }
            });
    },

    cffWidgetPortal(url) {
        if (processDeeplink(url, this)) {
            this.showPage('', <CFFWorkspace />);
        } else {
            this.showPage('', getErrorView());
        }
    },

};
