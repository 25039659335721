import PlanPropertiesView from '../planning/planPropertiesView';
import groupPlanPropertiesViewTmpl from './groupPlanPropertiesView.hbs';

const GroupPlanPropertiesView = PlanPropertiesView.extend({
    template: groupPlanPropertiesViewTmpl,

    templateHelpers() {
        return {
            plans: this.collection.toJSON(),
            id: this.cid,
        };
    },
});

export default GroupPlanPropertiesView;
