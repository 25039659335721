import locale from '@glu/locale';
import {
    getStatusInformation,
    setBiometricSecureData,
} from '@mob/mobile';

const BIOMETRIC_TYPE = {
    TOUCH_ID: 'touchID',
    FACE_ID: 'faceID',
    FINGERPRINT: 'fingerprint',
    NONE: 'none',
};

const BIOMETRIC_ERROR = {
    AUTHENTICATION_CANCELED: 'authenticationCanceled',
    NO_BIOMETRIC_DATA: 'noBiometricData',
    BIOMETRIC_AUTHENTICATION_FAILURE: 'biometricAuthenticationFailure',
    UNKNOWN_BIOMETRIC_FAILURE: 'unknownBiometricFailure',
};

const hasBiometricCapability = type => type !== BIOMETRIC_TYPE.NONE && type !== undefined;

const getTypeLocaleString = type => locale.get(`logon.biometric.type.${type}`);
const getErrorLocaleString = code => (code ? locale.get(`logon.biometric.error.${code}`)
    : locale.get(`logon.biometric.error.${BIOMETRIC_ERROR.UNKNOWN_BIOMETRIC_FAILURE}`));
const updateBiometricData = authData => getStatusInformation()
    .then(({ data }) => {
        // Only when the device has biometric and there is already data, should it be updated
        if (hasBiometricCapability(data.biometricCapability)
            && data.sdkContainsBiometricSecureData) {
            setBiometricSecureData(authData);
        }
    });

export {
    BIOMETRIC_TYPE,
    BIOMETRIC_ERROR,
    hasBiometricCapability,
    getTypeLocaleString,
    getErrorLocaleString,
    updateBiometricData,
};
