import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import transform from 'common/util/transform';
import ControlModel from 'app/reports/models/controlSummary';

export default Collection.extend({
    model: ControlModel,

    initialize(options) {
        if (options.searchCriteria) {
            this.searchCriteria = options.searchCriteria;
            this.setSearchParams();
        } else if (options.defaultCriteria) {
            this.defaultCriteria = options.defaultCriteria;
            this.setDefaultCriteria();
        }
    },

    setDefaultCriteria() {
        const minDate = this.defaultCriteria.date.min;
        const maxDate = this.defaultCriteria.date.max;

        this.searchFields = [{
            operator: '>=',
            fieldValue: [minDate],
            dataType: 'TEXT',
            fieldName: 'to_char(ASOFDATE,\'YYYYMMDD\')',
        }, {
            operator: '<=',
            fieldValue: [maxDate],
            dataType: 'TEXT',
            fieldName: 'to_char(ASOFDATE,\'YYYYMMDD\')',
        }];
    },

    setSearchParams() {
        const { accounts } = this.searchCriteria;
        const minDate = this.searchCriteria.date.min;
        const maxDate = this.searchCriteria.date.max;
        const minAmount = this.searchCriteria.minimum;
        const maxAmount = this.searchCriteria.maximum;
        const searchSpecificAccounts = accounts.every(account => account !== 'all');

        this.savedSearchField = [{
            operator: '>=',
            fieldValue: [minDate],
            dataType: 'TEXT',
            fieldName: 'to_char(ASOFDATE,\'YYYYMMDD\')',
        }, {
            operator: '<=',
            fieldValue: [maxDate],
            dataType: 'TEXT',
            fieldName: 'to_char(ASOFDATE,\'YYYYMMDD\')',
        }];
        this.searchFields = [{
            operator: '>=',
            fieldValue: [minDate],
            dataType: 'TEXT',
            fieldName: 'to_char(ASOFDATE,\'YYYYMMDD\')',
        }, {
            operator: '<=',
            fieldValue: [maxDate],
            dataType: 'TEXT',
            fieldName: 'to_char(ASOFDATE,\'YYYYMMDD\')',
        }];
        if (searchSpecificAccounts) {
            this.searchFields.push({
                operator: 'IN',
                fieldValue: accounts,
                dataType: 'TEXT',
                fieldName: 'ACCOUNTFILTER',
            });
        }
        if (minAmount) {
            this.savedSearchField.push({
                operator: 'GTEQ',
                fieldValue: [minAmount],
                dataType: 'AMOUNT',
                fieldName: 'AMOUNTREPORTED',
            });
        }
        if (maxAmount) {
            this.savedSearchField.push({
                operator: 'LTEQ',
                fieldValue: [maxAmount],
                dataType: 'AMOUNT',
                fieldName: 'AMOUNTREPORTED',
            });
        }
    },

    getSearchFields() {
        // Used by details getListView
        return this.savedSearchField;
    },

    sync(method, model, options) {
        const url = services.generateUrl('/controlDist/Summary/getListView');
        // Set viewId to -1 for summary grid to prevent applying detail level saved view
        const data = { viewId: -1 };

        if (this.searchFields) {
            data.searchFields = this.searchFields;
        }

        http.post(url, data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    parse(response) {
        const { rows } = response;
        const accounts = [];

        util.each(rows, (account) => {
            let localAccount = account;
            localAccount = this.convertServerJsonToModelAttributes(localAccount.columns);

            accounts.push(localAccount);
        });

        return accounts;
    },

    convertServerJsonToModelAttributes(columns) {
        return transform.pairsToHash(columns, 'fieldName', 'fieldValue');
    },
});
