var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"view") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":12,"column":27}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":57}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                        --\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"view.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":83},"end":{"line":14,"column":116}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":29,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"manage") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.manage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":59}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"manage.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.manage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":85},"end":{"line":28,"column":120}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":43,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"modify") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":40,"column":27}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":59}}}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"modify.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":85},"end":{"line":42,"column":120}}}))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":57,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"getrate") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":54,"column":27}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.getrate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":48}}}))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"getrate.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.getrate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":86},"end":{"line":56,"column":110}}}))
    + "\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":74,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"repair") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":67,"column":20},"end":{"line":71,"column":27}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.repair",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":24},"end":{"line":68,"column":59}}}))
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"repair.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.repair",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":85},"end":{"line":73,"column":120}}}))
    + "\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":80,"column":16},"end":{"line":88,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"approve") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":81,"column":20},"end":{"line":85,"column":27}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":82,"column":60}}}))
    + "\n";
},"37":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"approve.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":87,"column":86},"end":{"line":87,"column":122}}}))
    + "\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(43, data, 0),"data":data,"loc":{"start":{"line":94,"column":16},"end":{"line":102,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reverse") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":95,"column":20},"end":{"line":99,"column":27}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.reverse",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":96,"column":24},"end":{"line":96,"column":60}}}))
    + "\n";
},"43":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"reverse.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.entitlement.reverse",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":101,"column":86},"end":{"line":101,"column":122}}}))
    + "\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(49, data, 0),"data":data,"loc":{"start":{"line":108,"column":16},"end":{"line":116,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"confidential") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":109,"column":20},"end":{"line":113,"column":27}}})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.confidential",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":110,"column":24},"end":{"line":110,"column":53}}}))
    + "\n";
},"49":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"confidential.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.confidential",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":115,"column":91},"end":{"line":115,"column":120}}}))
    + "\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.program(55, data, 0),"data":data,"loc":{"start":{"line":122,"column":16},"end":{"line":130,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"print") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":123,"column":20},"end":{"line":127,"column":27}}})) != null ? stack1 : "");
},"53":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":124,"column":24},"end":{"line":124,"column":46}}}))
    + "\n";
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"print.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":129,"column":84},"end":{"line":129,"column":106}}}))
    + "\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(61, data, 0),"data":data,"loc":{"start":{"line":136,"column":16},"end":{"line":144,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reprint") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":137,"column":20},"end":{"line":141,"column":27}}})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.reprint",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":138,"column":24},"end":{"line":138,"column":48}}}))
    + "\n";
},"61":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"reprint.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.reprint",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":143,"column":86},"end":{"line":143,"column":110}}}))
    + "\n";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.program(67, data, 0),"data":data,"loc":{"start":{"line":150,"column":16},"end":{"line":158,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"stopCheck") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":151,"column":20},"end":{"line":155,"column":27}}})) != null ? stack1 : "");
},"65":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.stopCheck",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":152,"column":24},"end":{"line":152,"column":50}}}))
    + "\n";
},"67":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"stopCheck.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.stopCheck",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":157,"column":88},"end":{"line":157,"column":114}}}))
    + "\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.program(73, data, 0),"data":data,"loc":{"start":{"line":164,"column":16},"end":{"line":172,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"voidCheck") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":165,"column":20},"end":{"line":169,"column":27}}})) != null ? stack1 : "");
},"71":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.voidCheck",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":166,"column":24},"end":{"line":166,"column":50}}}))
    + "\n";
},"73":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"voidCheck.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.voidCheck",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":171,"column":88},"end":{"line":171,"column":114}}}))
    + "\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.program(79, data, 0),"data":data,"loc":{"start":{"line":178,"column":16},"end":{"line":186,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"76":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"updateCheck") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(77, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":179,"column":20},"end":{"line":183,"column":27}}})) != null ? stack1 : "");
},"77":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.updateCheck",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":180,"column":24},"end":{"line":180,"column":52}}}))
    + "\n";
},"79":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" name=\"updateCheck.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.updateCheck",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":185,"column":90},"end":{"line":185,"column":118}}}))
    + "\n";
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(82, data, 0),"inverse":container.program(86, data, 0),"data":data,"loc":{"start":{"line":196,"column":12},"end":{"line":205,"column":23}}})) != null ? stack1 : "");
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <!-- This should be dynamically driven -->\n            <select class=\"form-control approval-signatures\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"signatures") : depth0),{"name":"each","hash":{},"fn":container.program(83, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":16},"end":{"line":201,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n";
},"83":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":200,"column":35},"end":{"line":200,"column":46}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":48},"end":{"line":200,"column":79}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":200,"column":80},"end":{"line":200,"column":89}}}) : helper)))
    + "</option>\n";
},"84":function(container,depth0,helpers,partials,data) {
    return "selected";
},"86":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"selectedSig") || (depth0 != null ? lookupProperty(depth0,"selectedSig") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"selectedSig","hash":{},"data":data,"loc":{"start":{"line":204,"column":16},"end":{"line":204,"column":31}}}) : helper)))
    + "\n";
},"88":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(89, data, 0),"inverse":container.program(92, data, 0),"data":data,"loc":{"start":{"line":210,"column":16},"end":{"line":218,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"89":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"approveOwn") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(90, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":211,"column":20},"end":{"line":215,"column":27}}})) != null ? stack1 : "");
},"90":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.approveOwn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":212,"column":24},"end":{"line":212,"column":51}}}))
    + "\n";
},"92":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" class=\"approve-own-checkbox\" name=\"approveOwn.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.approveOwn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":217,"column":118},"end":{"line":217,"column":145}}}))
    + "\n";
},"94":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(95, data, 0),"inverse":container.program(98, data, 0),"data":data,"loc":{"start":{"line":224,"column":16},"end":{"line":232,"column":23}}})) != null ? stack1 : "")
    + "            </label>\n";
},"95":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"autoApprove") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(96, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":225,"column":20},"end":{"line":229,"column":27}}})) != null ? stack1 : "");
},"96":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.autoApprove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":226,"column":24},"end":{"line":226,"column":52}}}))
    + "\n";
},"98":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"checkbox\" class=\"auto-approve-checkbox\" name=\"autoApprove.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.autoApprove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":231,"column":120},"end":{"line":231,"column":148}}}))
    + "\n";
},"100":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(101, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":239,"column":12},"end":{"line":251,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"debitApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(109, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":253,"column":12},"end":{"line":265,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"101":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.program(107, data, 0),"data":data,"loc":{"start":{"line":241,"column":20},"end":{"line":249,"column":27}}})) != null ? stack1 : "")
    + "                </label>\n";
},"102":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"credit") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(103, data, 0),"inverse":container.program(105, data, 0),"data":data,"loc":{"start":{"line":242,"column":24},"end":{"line":246,"column":31}}})) != null ? stack1 : "");
},"103":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.credit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":243,"column":28},"end":{"line":243,"column":51}}}))
    + "\n";
},"105":function(container,depth0,helpers,partials,data) {
    return "                            --\n";
},"107":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"checkbox\" class=\"credit-checkbox\" name=\"credit.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.credit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":248,"column":113},"end":{"line":248,"column":136}}}))
    + "\n";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label class=\"checkbox-inline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(110, data, 0),"inverse":container.program(113, data, 0),"data":data,"loc":{"start":{"line":255,"column":20},"end":{"line":263,"column":27}}})) != null ? stack1 : "")
    + "                </label>\n";
},"110":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"debit") : depth0)) != null ? lookupProperty(stack1,"entitled") : stack1),{"name":"if","hash":{},"fn":container.program(111, data, 0),"inverse":container.program(105, data, 0),"data":data,"loc":{"start":{"line":256,"column":24},"end":{"line":260,"column":31}}})) != null ? stack1 : "");
},"111":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.debit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":257,"column":28},"end":{"line":257,"column":50}}}))
    + "\n";
},"113":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <input type=\"checkbox\" class=\"debit-checkbox\" name=\"debit.entitled\" data-bind=\"model\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.debit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":262,"column":111},"end":{"line":262,"column":133}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <legend>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"localeLabel") || (depth0 != null ? lookupProperty(depth0,"localeLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"localeLabel","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":27}}}) : helper)))
    + "</legend>\n\n    <div class=\"checkbox\">\n        <div class=\"checkbox-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"viewApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"manageApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modifyApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":45,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"getrateApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":59,"column":15}}})) != null ? stack1 : "")
    + "        \n        </div>\n\n        <div class=\"checkbox-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"repairApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":8},"end":{"line":76,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"approveApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":8},"end":{"line":90,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reverseApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":8},"end":{"line":104,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confidentialApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":8},"end":{"line":118,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"printApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":8},"end":{"line":132,"column":15}}})) != null ? stack1 : "")
    + "        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reprintApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":8},"end":{"line":146,"column":15}}})) != null ? stack1 : "")
    + "        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stopCheckApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":8},"end":{"line":160,"column":15}}})) != null ? stack1 : "")
    + "        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"voidCheckApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":8},"end":{"line":174,"column":15}}})) != null ? stack1 : "")
    + "        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"updateCheckApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":176,"column":8},"end":{"line":188,"column":15}}})) != null ? stack1 : "")
    + "        \n        </div>\n    </div>\n\n<!-- This part should only work if approve checkbox is selected -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSignatures") : depth0),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":4},"end":{"line":206,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"approveOwnApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":8},"end":{"line":220,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"autoApproveApplicable") : depth0),{"name":"if","hash":{},"fn":container.program(94, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":222,"column":8},"end":{"line":234,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"checkbox\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTransactionType") : depth0),{"name":"if","hash":{},"fn":container.program(100, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":238,"column":8},"end":{"line":267,"column":11}}})) != null ? stack1 : "");
},"useData":true});