function navigatetoPages(e, context) {
    const mdata = e.val;
    const payments = context.paymentTypeCollection.models[0].get('smbPayMenu');
    let optionId;
    let text = '';

    Object.keys(payments || {}).find((i) => {
        text = payments[i].menuCategory;
        if (mdata === text.trim()) {
            optionId = payments[i].smbMenuGroupItems[0].menuCode;
            return true;
        }
        return false;
    });

    if (mdata === 'SMBTRANSFER') {
        context.navigateTo('payments/newTransferGrid');
    } else if (mdata === 'SMBPAYBUSINESS') {
        context.navigateTo(`payments/newPayBusinessGrid/${optionId}`);
    } else if (mdata === 'SMBPAYIND') {
        context.navigateTo(`payments/newPayIndGrid/${optionId}`);
    } else if (mdata === 'SMBPAYEMP' && optionId === 'PAY_EMPWAGES') {
        context.navigateTo(`payments/newPayEmpGrid/${optionId}`);
    } else if (mdata === 'SMBLOANADVANCE') {
        context.navigateTo('payments/reqLoanAdvanceGrid');
    } else if (mdata === 'SMBFUNDSIND') {
        context.navigateTo('payments/collectFromIndividual');
    } else if (mdata === 'SMBFUNDSBUSINESS') {
        context.navigateTo('payments/collectFromBusiness');
    } else {
        context.showServiceNotAvailableDialog();
    }

    return '';
}

const navigation = {
    navigate(e, context) {
        return navigatetoPages(e, context);
    },
};

export default navigation;
