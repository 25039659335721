import Layout from '@glu/core/src/layout';
import alert from '@glu/alerts';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import { DocumentArrowNextIcon } from '@glu/icons-react';
import services from 'services';
import ListView from 'common/dynamicPages/views/workflow/list';
import gridApi from 'common/dynamicPages/api/grid';
import PrintExportUtil from 'common/util/printExportUtil';
import workspaceHelper from 'common/workspaces/api/helper';
import contextApi from 'common/dynamicPages/api/context';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './list.hbs';

const FileImportView = Layout.extend({
    template,

    ui: {
        $panel: '.panel',
        $heading: '.panel-heading',
    },

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    setWidget(widget = {}) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    handleWidgetSizeChange() {
        util.defer(() => {
            if (this.gridView && this.gridView.grid) {
                this.gridView.grid.tableView.setWidth();
            }
        });
    },

    initialize(options) {
        /**
         * HACK: Do not repeate this pattern.
         * creation of unused view in order to avoid scoping issue in templateHelpers below
         */
        this.listView = new ListView();
        if (options.viewId) {
            this.markFileImportAsRead();
        }

        const context = contextApi.menuContext.getContext('FIMPORT_LISTVIEW');

        this.gridView = gridApi.createServiceGridView({
            context,
            hideGridActionButtons: true,
            enableSavedViews: true,
            selector: 'none',
            enableRowContextButtonCallbacks: true,
            dateFormat: userInfo.getDateFormat(),
            lvc: this.lvc,
        });
        this.enableSavedViews = true;
        this.contextDef = context;

        ListView.prototype.setListViewConfig.call(this, context);
    },

    markFileImportAsRead() {
        http.post(services.generateUrl('/notifications/accessFileImportNotifications'), {});
    },

    onRender() {
        this.loadGrid();
    },

    loadGrid() {
        this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        this.listenTo(this.gridView.getRows(), 'sync', ListView.prototype.updateRefreshTimestamp);
        ListView.prototype.listenForGridErrors.call(this);
        ListView.prototype.setupGridAvailableListener.call(this);

        if (this.fileHistoryRegion) {
            this.fileHistoryRegion.show(this.gridView);
        }
    },

    templateHelpers() {
        const obj = ListView.prototype.templateHelpers.call(this.listView, undefined);

        return util.extend(
            obj,
            {
                hasDeleteEntitlement() {
                    return false;
                },

                getButtonString(code) {
                    return locale.get(code);
                },
            },
        );
    },

    refreshData() {
        ListView.prototype.refreshData.call(this); // call listView refreshData
    },

    print() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.inquiryId,
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.inquiryId,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },

    gridRowAction(options) {
        if (options.action.toUpperCase() === 'SELECT') {
            store.set('fileImportId', options.model.get('IMPORTID'));
            store.set('jobId', options.model.get('EVENTID'));
            store.set('productCode', options.model.get('PRODUCTCODE'));
            store.set('functionCode', options.model.get('FUNCTIONCODE'));
            store.set('typeCode', options.model.get('TYPECODE'));
            store.set('fileOriginalName', options.model.get('INBOUND_FILE'));
            store.set('importCode', options.model.get('IMPORT_CODE'));
            store.set('templateCode', options.model.get('TEMPLATE_CODE'));
            store.set('templateName', options.model.get('TEMPLATE_NAME'));
            this.navigateTo('ADMINSTRATION/fileImportDetails');
            return Promise.resolve();
        }
        if (options.action.toUpperCase() === 'DOWNLOAD') {
            return new Promise((resolve, reject) => {
                const { model } = options;
                const $loading = this.$('.loading-indicator-grid');
                const EVENTID = model.get('EVENTID');

                $loading.show();
                http.setRequestHeaders({ eventId: EVENTID });
                const service = services.generateUrl('/fileImportHistory/downloadBlobId');

                http.get(service, (data, textStatus, request) => {
                    // success handler
                    if (!request.getResponseHeader('content-disposition')) {
                        $loading.hide();
                        if (this.alertRegion) {
                            this.alertRegion.close();
                        }
                        this.alertRegion.show(alert.danger(data, {
                            canDismiss: true,
                            animate: true,
                        }));
                    } else {
                        PrintExportUtil.fileDownload(data, textStatus, request);
                        $loading.hide();
                        resolve({ result: data });
                    }
                }, (e) => {
                    // error handler
                    $loading.hide();
                    reject(e);
                });
            });
        }

        return new Promise((resolve, reject) => {
            if (options.action.toUpperCase() === 'DELETE') {
                options.model.destroy({
                    success: (model, resp) => {
                        this.gridView.refreshGridData();
                        const message = Array.isArray(resp.message)
                            ? resp.message.join(' ')
                            : resp.message;
                        const alertView = alert.success(message);
                        this.alertRegion.show(alertView);
                        resolve({ model, result: resp });
                    },

                    error: (model, resp) => {
                        this.gridView.refreshGridData();
                        const message = Array.isArray(resp.message)
                            ? resp.message.join(' ')
                            : resp.message;
                        const alertView = alert.danger(message);
                        this.alertRegion.show(alertView);
                        reject(new Error(resp.message));
                    },
                });
            } else {
                resolve();
            }
        });
    },
});

let list = FileImportView;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'FILEIMPORT_HISTORY',
    view: list,
    options: {},
    useMobileCard: true,
    mobileCardProps: {
        icon: DocumentArrowNextIcon,
        cardContent: 'Hi there, this is some content.',
    },
});

const exportedList = list;

export default exportedList;
