import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';
import store from 'pcm/common/store';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import { ipLogonEntitlements } from './ipLogonWhiteListUtils';

const IpLogonWhiteList = React.lazy(() => import('./IpLogonWhiteList'));
const AddIpLogonWhiteList = React.lazy(() => import('./AddIpLogonWhiteList'));
const EditIpLogonWhiteList = React.lazy(() => import('./EditIpLogonWhiteList'));
const ViewIpLogonWhiteList = React.lazy(() => import('./ViewIpLogonWhiteList'));


const getActionToTitleOptions = () => ({
    title: {
        view: locale.get('logonwhitelist.ipaddress.view'),
        add: locale.get('logonwhitelist.addIpAddresslist'),
        edit: locale.get('logonwhitelist.editIpAddresslist'),
    },
    backLink: '/ui/ADMINSTRATION/ip_logon_management',
});


export default {

    ipLogonWhiteListConfig() {
        this.showPage(
            locale.get('logonwhitelist.ipaddress.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={ipLogonEntitlements}>
                    <IpLogonWhiteList />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('logonwhitelist.ipaddress.list'),
            },
        );
    },


    ipLogonWhiteListActions(action) {
        const components = {
            view: ViewIpLogonWhiteList,
            add: AddIpLogonWhiteList,
            edit: EditIpLogonWhiteList,
        };

        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();
        const ComponentToShow = components[action];

        this.showPage(
            actionToTitleOptions.title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={ipLogonEntitlements}>
                    <ComponentToShow action={action} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title[action],
                backLink: actionToTitleOptions.backLink,
            },
        );
    },

};
