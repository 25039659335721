import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(options) {
        this.accountData = options.account;
    },

    sync(method, model, options) {
        const data = {
            item: [{
                name: 'ACCOUNTTYPE',
                value: this.accountData.BENE_ACCOUNT_TYPE || '',
            }, {
                name: 'SUBACCOUNT_TYPE',
                value: this.accountData.BENEACCOUNT_SUBTYPE || '',
            }],
        };

        http.post(services.getPreferences, data, (response) => {
            options.success(response);
        }, () => {
            options.error();
        });
    },
});
