var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n    <div class=\"btn-wrapper\">\n        "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":51}}})) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"summary-body\">\n        <div data-region=\"tableContent\" aria-live=\"polite\"></div>\n    </div>\n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"button-row\"></div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"panelWithTotals")||(depth0 && lookupProperty(depth0,"panelWithTotals"))||alias2).call(alias1,{"name":"panelWithTotals","hash":{"totalsWarning":(lookupProperty(helpers,"getTotalsWarning")||(depth0 && lookupProperty(depth0,"getTotalsWarning"))||alias2).call(alias1,{"name":"getTotalsWarning","hash":{},"data":data,"loc":{"start":{"line":3,"column":133},"end":{"line":3,"column":151}}}),"totalsId":(depth0 != null ? lookupProperty(depth0,"totalsSection") : depth0),"hasTotals":(depth0 != null ? lookupProperty(depth0,"sectionHasTotals") : depth0),"theme":"panel-primary","collapsed":false,"label":(depth0 != null ? lookupProperty(depth0,"title") : depth0)},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":13,"column":20}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});