var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":181}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.ManageRemitters",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":63}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div data-region=\"alertRegion\"></div>\n    <div class=\"section-body\">\n        <div>\n            <div class=\"btn-group pull-left widget-links\">\n                <button type=\"button\" class=\"btn btn-tertiary add-remitter\" data-action=\"addRemitter\"><span class=\"icon-add-circle\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.AddRemitter",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":140},"end":{"line":13,"column":168}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary remove-remitters\" data-action=\"removeRemitter\"><span class=\"icon-minus-circle\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.RemoveRemitter",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":149},"end":{"line":14,"column":180}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary fileimport\" data-action=\"fileImport\"><span class=\"icon-add-circle\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"fimport.button_fileimport",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":137},"end":{"line":15,"column":175}}}))
    + "</button>\n            </div>\n                        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});