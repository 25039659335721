export default function (form, initialState) {
    const { model } = form.formView;
    const countryCode = form.field('COUNTRYCODE');
    const city = form.field('BUSCITY');
    const relevantForm = form.formView.parentView || form.formView;

    if (initialState) {
        // hide the fields not needed(not defined in visiblefields but they still showing)
        model.set('COUNTRYCODE', 'USA');
        form.field('POSTALCODE').shouldBeVisibleWhen(false)
            .shouldBeRequiredWhen(false);
        form.field('PROVINCENAME').shouldBeVisibleWhen(false)
            .shouldBeRequiredWhen(false);

        const $taxId = relevantForm.$('[name="TAXID"]');
        const $taxIdFieldContainer = $taxId.closest('.field-container');
        const $taxIdHelpBlock = $taxIdFieldContainer.find('.textline-field');
        $taxIdHelpBlock.hide();

        /*
         * if the country code is USA use state and zip code fields(mandatory),
         * else use province name and postal code (optional).
         */
        model.on('change:COUNTRYCODE', () => {
            const isUSA = countryCode.getValue() === 'USA';
            form.field('POSTALCODE').shouldBeVisibleWhen(!isUSA)
                .shouldBeRequiredWhen(!isUSA).setValue('');
            form.field('PROVINCENAME').shouldBeVisibleWhen(!isUSA)
                .shouldBeRequiredWhen(!isUSA).setValue('');
            form.field('BUSSTATE').shouldBeVisibleWhen(isUSA)
                .shouldBeRequiredWhen(isUSA).setValue('');
            form.field('BUSZIPCODE').shouldBeVisibleWhen(isUSA)
                .shouldBeRequiredWhen(isUSA).setValue('');
        });

        /*
         * if the city start with APO or FPO make the zip code optional
         * otherwise if USA zip code  is mandatory
         */
        model.on('change:BUSCITY', () => {
            const prefixCity = city.getValue().substring(0, 3).toUpperCase();
            const isUSA = countryCode.getValue() === 'USA';
            const militaryAddress = prefixCity === 'APO' || prefixCity === 'FPO';
            if (!isUSA) {
                return;
            }
            if (militaryAddress) {
                form.field('BUSZIPCODE').shouldBeOptional(true);
            } else {
                form.field('BUSZIPCODE').shouldBeRequired(true);
            }
        });
    }
}
