import { createUseStyles } from '@glu/theming';

const styles = ({ palette }) => ({
    root: {
        alignItems: 'flex-end',
        color: palette.grey.G600,
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
    },
    label: {
        marginRight: 5,
    },
    warning: {
        color: palette.error,

        '& svg': {
            margin: [5, 5, 2],
        },

        '& svg path': {
            fill: palette.error,
        },
    },
});

export default createUseStyles(styles);
