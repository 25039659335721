import services from 'services';
import serviceUtil from 'common/util/services';
import locale from '@glu/locale';
import errorHandlers from 'system/error/handlers';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import NotificationPoller from 'common/notificationClient/notifications/notificationsPoller';
import { asView } from 'common/util/reactUtil';
import AsyncPrintViewModal from 'components/AsyncReporting/AsyncPrintViewModal';

export default {
    fileDownload(data, onSuccess, onFailure) {
        const service = data.service ? services.generateUrl(data.service)
            : `${services.downloadAsyncReport}?${serviceUtil.generateUrlParams(data)}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', service, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
            const contentDisposition = xhr.getResponseHeader('content-disposition');
            const contentType = xhr.getResponseHeader('content-type');
            const elements = contentDisposition.split(';');
            const filename = elements.length > 1
                ? elements[1].replace(/filename=|"/g, '')
                : 'file.csv';
            if (this.status === 200) {
                const blob = new Blob([this.response], { type: contentType });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                const isFirefox = typeof InstallTrigger !== 'undefined';

                if (isFirefox) {
                    link.setAttribute('type', 'hidden');
                    document.body.appendChild(link);
                }
                link.click();

                // for IE
                if (navigator && navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, filename);
                }

                if (isFirefox) {
                    link.remove();
                }
                // Remove created element
                window.URL.revokeObjectURL(link.href);
                link.remove();

                onSuccess();
            }
        };

        xhr.onerror = function (e) {
            onFailure(e);
        };

        xhr.send();
    },

    handleAsyncReport(result, onSuccess, onFailure) {
        // Download the report
        const data = {
            disposition: result.disposition || 'view',
            exportId: result.exportId,
            format: result.format || 'PDF',
            type: 'jasper',
            ...(result.service && { service: result.service }),
        };
        this.fileDownload(data, onSuccess, onFailure);
    },

    /**
     * @method setupAsyncReportPoller
     * @description A method that will start async the poller every time a user trigger async report
     */
    setupAsyncReportPoller() {
        if (!this.asyncNotificationPoller) {
            this.asyncNotificationPoller = new NotificationPoller({
                notificationKey: 'ASYNCJASPERREPORTS',
                eventName: 'notification:asyncReports',
                interval: this.getAsyncReportInterval(),
                serviceUrl: services.generateUrl('/notifications/getNotificationData'),
                leading: true,
            });
        } else {
            this.asyncNotificationPoller.enable(true);
        }
        return this.asyncNotificationPoller;
    },

    /**
     * Helper method to get async report interval. Will return 10000 milliseconds if
     * NotificationSubscriptionPollerInterval is null/undefined OR the value is less
     * than 10000 milliseconds
     * @returns number
     */
    getAsyncReportInterval() {
        const MINIMUM_INTERVAL = 10000; // 10 seconds
        const interval = serverConfigParams.get('NotificationSubscriptionPollerInterval');
        if (!interval || interval < MINIMUM_INTERVAL) return MINIMUM_INTERVAL;
        return interval;
    },

    /**
     * @method handleDownloadFile
     * @param {object} result
     * @param {object} dialog
     */
    handleDownloadFile(result, dialog) {
        this.handleAsyncReport(
            {
                ...result,
                ...{
                    disposition: 'export',
                    format: 'CSV',
                },
            },
            () => dialog.close(),
            () => errorHandlers.loading.call(this),
        );
    },

    /**
     * Handles export async reports
     * @param {object} options
     * @param {object} dialog
     */
    handleExportAsyncReport(options, dialog) {
        dialog.close();
        const AsyncPrintView = asView(AsyncPrintViewModal);
        const AsyncPrintViewModalView = new AsyncPrintView({
            type: 'export',
            onCancel: () => dialog.close(),
            onReportComplete: result => this.handleDownloadFile(result, dialog),
            onReportIncomplete: () => this.setupAsyncReportPoller(),
            ...options,
        });
        AsyncPrintViewModalView.dialogTitle = locale.get('async.export');
        dialog.open(AsyncPrintViewModalView);
    },
};
