var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"btn btn-tertiary toggleTotals\" data-hook=\"toggle-totals-button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.show.totals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":127},"end":{"line":17,"column":158}}}))
    + "</button>";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div data-hook=\"payment-totals-region\" class=\"totals-region\" aria-live=\"polite\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-tertiary\" data-hook=\"insert-button\">\n                        <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"insert",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":28,"column":62},"end":{"line":28,"column":90}}}))
    + "\n                    </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-tertiary quickentry\" data-hook=\"quickentry-button\">\n                        <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"quickentry",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":33,"column":62},"end":{"line":33,"column":94}}}))
    + "\n                    </button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-tertiary fileimport\" data-hook=\"fileimport-button\">\n                        <span class=\"icon-add-circle\"></span> "
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fileimport",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":38,"column":62},"end":{"line":38,"column":94}}}))
    + "\n                    </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary approve\" data-action=\"approve\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"approve",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":49,"column":94},"end":{"line":49,"column":123}}}))
    + "</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary unapprove\" data-action=\"unapprove\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"unapprove",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":52,"column":98},"end":{"line":52,"column":129}}}))
    + "</button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary reject\" data-action=\"reject\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"reject",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":55,"column":92},"end":{"line":55,"column":120}}}))
    + "</button>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary delete\" data-action=\"delete\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"delete",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":58,"column":92},"end":{"line":58,"column":120}}}))
    + "</button>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary getrate\" data-action=\"getrate\">"
    + alias3((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias2).call(alias1,"getrate",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":61,"column":94},"end":{"line":61,"column":123}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-secondary getrate\" data-action=\"trade\">"
    + alias3((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias2).call(alias1,"trade",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":62,"column":92},"end":{"line":62,"column":119}}}))
    + "</button>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary print\" data-action=\"printchecks\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"printchecks",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":65,"column":96},"end":{"line":65,"column":129}}}))
    + "</button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary re-print\" data-action=\"reprintchecks\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"re-printchecks",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":68,"column":101},"end":{"line":68,"column":137}}}))
    + "</button>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary void-check\" data-action=\"void\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"void-checks",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":71,"column":94},"end":{"line":71,"column":127}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":52}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"section-header-list",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":47}}}))
    + "</h3>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"btn-wrapper\">\n            <div class=\"maxDays\">\n                <span class=\"maxDaysMessage\"></span>\n                <span class=\"icon-info hidden\" data-toggle=\"popover\" data-trigger=\"hover focus\" data-placement=\"right\" data-content=\""
    + alias3(lookupProperty(helpers,"locale").call(alias1,"PS.ListView.MaxDaysMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":133},"end":{"line":14,"column":172}}}))
    + "\"></span>\n            </div>\n            <div class=\"btn-group widget-info-links\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTotals") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":174}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTotals") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTemplateSelectEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFileImportEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":40,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":42,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n\n        <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":50,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUnapproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRejectEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":56,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":59,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasGetRateEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrintCheckEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":66,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReprintCheckEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":69,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasVoidCheckEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":72,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});