import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import cmConstants from 'app/checkManagement/constants';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import ListDecision from './listDecision';

const EPositivePayDecisionList = ListDecision.extend({
    id: 'pmtFraudCtrlTodaysDecisions',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'pmtFraudCtrlTodaysDecisionsTab',
    },

    initialize(options) {
        // set options for listView
        this.mobileListViewOptions = {
            bulkActions: [
                {
                    label: 'cm.button_pay',
                    entitlement: 'PAY',
                },
                {
                    label: 'cm.button_return',
                    entitlement: 'RETURN',
                },
                {
                    label: 'cm.button_approve',
                    entitlement: 'APPROVE',
                },
                {
                    label: 'cm.button_unapprove',
                    entitlement: 'UNAPPROVE',
                },
            ],
        };
        this.mobileGridView = EPositivePayDecisionList;
        this.renderMobileGridUsingTemplate = options.renderMobileGridUsingTemplate !== undefined
            ? options.renderMobileGridUsingTemplate
            : true;

        // call the parent
        ListDecision.prototype.initialize.call(this, options);
    },

    getProductCode() {
        return cmConstants.RISK;
    },

    getFunctionCode() {
        return cmConstants.BATCH;
    },

    getTypeCode() {
        return cmConstants.ELECTRONIC_POS_PAY_TYPE;
    },

    getMenuContext() {
        return 'risk/listView/ePositivePayDecision/multi';
    },

    getInquiryId() {
        return constants.INQUIRY_ID_20520;
    },

    /** @override */
    getInitializeOptions(options) {
        const initOptions = {
            serviceName: 'risk/listView/ePositivePayDecision',
            serviceFunc: null,
            businessType: null,
            returnRoute: options.returnRoute,
            enableSavedViews: true,
            viewAll: options.viewAll,
            enableViewChecks: false,
        };

        let rowModel;

        if (options.viewAll) {
            store.set('cm_listView_comingToList', 'ePay-items');
        } else {
            rowModel = this.getRowModel(options);
            initOptions.additionalSearchFields = [{
                fieldName: 'PPACCOUNTBATCHID',
                operator: 'EQ',
                fieldValue: [rowModel.get('BATCHTNUM')],
                dataType: 'text',
            }];
        }

        return initOptions;
    },

    /** @override */
    getRowModel() {
        return store.get('cm_listView_paymentFraudControl-actionModel');
    },

    /** @override */
    gridRowSelect(options) {
        const { model } = options;
        model.set({
            ACCOUNT_NUMBER: this.ACCOUNT_NUMBER,
            ACCOUNT_TITLE: this.ACCOUNT_TITLE,
            _mode: 'SELECT',
        });
        store.set(`${this.contextKey}-actionModel`, model);
        this.navigateTo('RISK/viewEPositivePayDecision');
        return Promise.resolve();
    },

    getReturnPath() {
        this.navigateTo('RISK/viewEPositivePayDecisionItems');
    },

    /** @override */
    gridRowCustomAction(options) {
        const action = options.action.toUpperCase();
        const { model } = options;
        const selectedRow = [];
        model.fieldData = this.getFieldDataToSendToServer();
        selectedRow.push(model);
        // TODO use constants for actions (in constants.js)
        if (action === 'PAY' || action === 'PAYRULE') {
            model.set({
                ACTIONCODE: 'P',
            });
        } else if (action === 'RETURN') {
            model.set({
                ACTIONCODE: 'R',
            });
        }

        this.processMultiActionsAsOne(action, selectedRow);
        return Promise.resolve();
    },

    back() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /** @override */
    populateCustomKeys(item) {
        item.item.push({
            name: 'CUSTOMKEY',
            value: 'PPEBMPPSUSPECTITEMID',
        });
        return item;
    },

    /** @override */
    getCustomItem(model, methodName) {
        let item;
        if (methodName === constants.ACTION_RETURN) {
            item = this.convertModelAttributesToJSONForCM(
                model,
                {
                    ACTIONCODE: 'R',
                    PPEBMPPSUSPECTITEMID: '',
                    UPDATECOUNT__: '',
                    CUSTOMEKEY: 'PPEBMPPSUSPECTITEMID',
                },
            );
        }

        if (methodName === constants.ACTION_PAY || methodName === constants.ACTION_PAYRULE) {
            item = this.convertModelAttributesToJSONForCM(
                model,
                {
                    ACTIONCODE: 'P',
                    PPEBMPPSUSPECTITEMID: '',
                    UPDATECOUNT__: '',
                    CUSTOMEKEY: 'PPEBMPPSUSPECTITEMID',
                },
            );
            if (methodName === constants.ACTION_PAYRULE) {
                // for payrule, store the required fields to populate on the create rule page
                const achRuleModel = this.getAchRuleFields(model);
                store.set(cmConstants.ELECTRONIC_POS_PAY_RULE_PREFILL, achRuleModel);
            }
        }
        return item;
    },

    /** @override */
    getFieldDataToSendToServer() {
        return {
            PPEBMPPSUSPECTITEMID: {
                name: 'PPEBMPPSUSPECTITEMID',
                virtualField: false,
            },
            ACTIONCODE: {
                name: 'ACTIONCODE',
                virtualField: false,
            },
            // eslint-disable-next-line
            UPDATECOUNT__: {
                // eslint-disable-next-line
                name: 'UPDATECOUNT__',
                virtualField: false,
            },
        };
    },

    /**
     * @override
     */
    updateSelectedModels(methodName) {
        if (methodName === 'PAY') {
            this.updateModelOfSelectedRows({
                ACTIONCODE: 'P',
            });
        }
        if (methodName === 'RETURN') {
            this.updateModelOfSelectedRows({
                ACTIONCODE: 'R',
            });
        }
    },

    getAchRuleFields(model) {
        const obj = util.pick(model.toJSON(), cmConstants.ELECTRONIC_POS_PAY_PREFILL_FIELDS);
        // ACH Auth Rules uses ACCOUNTNUMBER
        obj.ACCOUNTNUMBER = obj.ACCOUNT_NUMBER;
        obj.ACCOUNTFILTER = `${obj.BANKCODE}-${obj.ACCOUNTNUMBER}`;
        return obj;
    },

    multiPay() {
        this.handleBulkAction(constants.ACTION_PAY);
    },

    multiReturn() {
        this.handleBulkAction(constants.ACTION_RETURN);
    },

    defaultConfirmData(confirmResponse) {
        if (confirmResponse.confirms) {
            util.each(confirmResponse.confirms.confirmResults, (confirmResultParam) => {
                const confirmResult = confirmResultParam;
                if (!confirmResult.confirmData[0].item) {
                    confirmResult.confirmData[0].item = {};
                }
            });
        }
    },

    /**
     * @method renderMessage
     * @param {object} action - grid/page action data
     * @param {object} confResponse - confirmation message content
     * @param {number} errorCount
     * Takes in action data and a action confirmation response to produce an alert message
     * Usually used after a grid action is made and the data is stored in the
     * Store to be used in this method.
     */
    renderMessage(action, confResponse, errorCount) {
        let success = false;
        // TODO make this a method in list.js & call here & in list.js renderMessage if possible
        if (action && confResponse) {
            const confirm = typeof confResponse === 'object' ? confResponse.confirms : null;
            const successCount = confirm ? confirm.totalSuccess : 0;
            const failCount = confirm ? confirm.totalFail : errorCount;
            const totalCount = failCount + successCount;
            success = successCount === totalCount;
        }
        if (action === 'PAYRULE' && success) {
            // store message for ach auth rule page
            store.set(cmConstants.ELECTRONIC_POS_PAY_PAY_MESSAGE, confResponse);
            this.navigateTo('RISK/achAuthRulesAdd');
        } else {
            ListDecision.prototype.renderMessage.call(this, action, confResponse, errorCount);
        }
    },
    templateHelpers() {
        const obj = ListDecision.prototype.templateHelpers.call(this);
        return util.extend(
            obj,
            {
                hasViewChecksEntitlement() {
                    return false;
                },
            },
        );
    },
});

export default EPositivePayDecisionList;
