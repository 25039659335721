export default ({
    PMX_PROXY_SERVLET: 'PmxProxyServlet',

    PMX_DGB_ROUTES: [{
        route: 'px/admin/membership/simple-account-management/add-account',

        entitlementCheckParams: {
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'PMXACCT',
            actionMode: 'MODIFY',
        },
    }, {
        route: /px\/admin\/membership\/simple-account-management\/account-details\/(.)+\/edit/,

        entitlementCheckParams: {
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'PMXACCT',
            actionMode: 'MODIFY',
        },
    }, {
        route: 'px/admin/membership/simple-account-management',

        entitlementCheckParams: {
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'PMXACCT',
            actionMode: 'SELECT',
        },
    }, {
        route: 'px/admin/membership/bank-account-documents',

        entitlementCheckParams: {
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'PMXACCT',
            actionMode: 'SELECT',
        },
    }, {
        route: /px\/vendors\/(paid)|(unpaid)/,

        entitlementCheckParams: {
            productCode: 'RTGS',
            functionCode: 'RPT',
            typeCode: 'PMXVNDOR',
            actionMode: 'SELECT',
        },
    }, {
        route: 'px/admin/membership/contact-options',

        entitlementCheckParams: {
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'PMXPYCT',
            actionMode: 'SELECT',
        },
    }, {
        route: 'px/admin/membership/edit-contact-options',

        entitlementCheckParams: {
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'PMXPYCT',
            actionMode: 'MODIFY',
        },
    }],

    PMX_PRIVILEGES: {
        actions: [{
            functionCode: 'MAINT',
            productCode: '_ADMIN',
            typeCode: 'PMXACCT',
            actionMode: 'SELECT',
        }, {
            functionCode: 'MAINT',
            productCode: '_ADMIN',
            typeCode: 'PMXACCT',
            actionMode: 'MODIFY',
        }, {
            functionCode: 'MAINT',
            productCode: '_ADMIN',
            typeCode: 'PMXPYCT',
            actionMode: 'SELECT',
        }, {
            functionCode: 'MAINT',
            productCode: '_ADMIN',
            typeCode: 'PMXPYCT',
            actionMode: 'MODIFY',
        }, {
            functionCode: 'RPT',
            productCode: 'RTGS',
            typeCode: 'PMXRPT',
            actionMode: 'SELECT',
        }],
    },
});
