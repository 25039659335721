import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import moment from 'moment';
import validatorPatterns from 'system/validatorPatterns';
import EditableTableCollection from 'app/smbBillManagement/collections/editableTable';
import services from 'services';

export default Model.extend({
    idAttribute: 'BILL_ID',

    defaults: {
        VALIDATOR_REQUIRED: '*',
        APPROVER_REQUIRED: '*',
    },

    initialize() {
        this.set({
            LINE_ITEMS: new EditableTableCollection([{}]),
        });

        this.validators = {
            VENDOR_ID: {
                description: locale.get('smbBillMgmt.vendor'),
                exists: true,
            },

            BILL_NUMBER: {
                description: locale.get('smbBillMgmt.bill.number'),
                exists: true,
                matches: validatorPatterns.ALPHANUMERICSPACEPERIODDASH_PATTERN,
            },

            ISSUE_DATE: {
                description: locale.get('smbBillMgmt.issue.date'),

                // for date, pass a function that returns the string to validate against
                exists: true,

                date() {
                    return userInfo.getDateFormat();
                },
            },

            DUE_DATE: {
                description: locale.get('smbBillMgmt.due.date'),
                exists: true,

                date() {
                    return userInfo.getDateFormat();
                },
            },

            TOTAL_AMOUNT: {
                description: locale.get('smbBillMgmt.amount'),
                exists: true,
                isNumeric: true,
            },

            TERM_CONDITION_ID: {
                description: locale.get('smbBillMgmt.terms.conditions'),
                exists: true,
            },

            VALIDATOR_REQUIRED: {
                description: locale.get('smbBillMgmt.validator'),
                exists: true,
            },

            APPROVER_REQUIRED: {
                description: locale.get('smbBillMgmt.approver'),
                exists: true,
            },
        };
    },

    convertLocalDateToServerDate(localDateString) {
        const serverDateFormat = 'MM/DD/YYYY';
        const localDateFormat = userInfo.getDateFormat();
        return moment(localDateString, localDateFormat).format(serverDateFormat);
    },

    toServerJSON() {
        let item = this.toJSON();

        item.ISSUE_DATE = this.convertLocalDateToServerDate(this.get('ISSUE_DATE'));
        item.DUE_DATE = this.convertLocalDateToServerDate(this.get('DUE_DATE'));
        item.PINNED = (item.PINNED) ? '1' : '0';

        delete item.LINE_ITEMS;
        item = transform.hashToPairs(item);

        const json = {
            item: {
                item,
            },

            grids: [],
        };

        if (this.has('LINE_ITEMS') && util.isFunction(this.get('LINE_ITEMS').toServerJSON)) {
            json.grids = this.get('LINE_ITEMS').toServerJSON();
        }

        return json;
    },

    getReadJSON(id) {
        return {
            item: {
                item: [{
                    name: 'BILL_ID',
                    value: id,
                }],
            },
        };
    },

    parse(res) {
        // sometimes the success callback will fire but the JSON will be an error message
        if (res.result === false || util.isUndefined(res.item)) {
            return undefined;
        }

        const attrs = transform.pairsToHash(res.item.item, 'name', 'value');

        attrs.PINNED = (attrs.PINNED === '1');

        this.get('LINE_ITEMS').reset(
            res.grids[0],
            {
                parse: true,
            },
        );

        return attrs;
    },

    sync(method, model, optionsParam) {
        const options = optionsParam;
        let xhr;
        switch (method) {
        case 'create':
            xhr = http.post(services.generateUrl('/bill/add'), this.toServerJSON(), options.success, options.error);
            break;

        case 'update':
            xhr = http.post(services.generateUrl('/bill/update'), this.toServerJSON(), options.success, options.error);
            break;

        case 'read':
            if (!(options && options.id)) {
                // TODO: Should this throw an error? Return a rejected Promise?
                return undefined;
            }
            options.read = true;
            xhr = http.post(services.generateUrl('/bill/readchildren'), this.getReadJSON(options.id), options.success, options.error);

            break;
        default:
        }

        this.trigger('request', model, xhr, options);
        return xhr;
    },

    canBeModified() {
        if (util.isString(this.get('availableActions'))) {
            // separate actions
            const actionArray = this.get('availableActions').split('^');

            const modifyAction = util.find(actionArray, actionPair => // split label from action
                util.contains(actionPair.split(','), 'MODIFY'));

            return !util.isUndefined(modifyAction);
        }
        return false;
    },
});
