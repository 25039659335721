import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import Model from '@glu/core/src/model';
import template from './transactionDetailsViewBtn.hbs';

const TransactionDetailsView = Layout.extend({
    template,
    initialize(options) {
        this.model = new Model();
        this.model.set(options);
    },
    viewTransactionDetails() {
        store.set(
            'lbxReportData',
            {
                batch: this.model.get('batchIDs'),
                types: this.model.get('selectedTypeNames'),
                range: this.model.get('depositDates'),
                minDate: this.model.get('depositDatesMinUnFormatted'),
                maxDate: this.model.get('depositDatesMaxUnFormatted'),
                groupView: false,
            },
        );
        store.set('lbxDefaultView', this.model.get('isDefaultView'));
        this.navigateTo('REPORTING/lockbox/reports/details');
    },
});
export default TransactionDetailsView;
