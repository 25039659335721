var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"btn btn-primary\" data-action=\"saveChallenge\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":93},"end":{"line":11,"column":117}}}))
    + "</button>";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"btn btn-primary\" data-action=\"modifyChallenge\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":91},"end":{"line":12,"column":117}}}))
    + "</button>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"challengeMgr.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":186}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":40}}}) : helper)))
    + "</h1>\n</div>\n<div data-region=\"multiStepRegion\"></div>\n<div data-region=\"auditRegion\"></div>\n<div class=\"section section-container challenge-buttons\">\n    <div class=\"btn-group\">\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"viewChallenge") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":137}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"viewChallenge") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":133}}})) != null ? stack1 : "")
    + "\n        <button class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":63},"end":{"line":13,"column":89}}}))
    + "</button>\n    </div>\n    <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"mfa.saveNotification",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":7},"end":{"line":15,"column":40}}}))
    + "</p>\n</div>\n";
},"useData":true});