import Layout from '@glu/core/src/layout';
import PrintContentView from 'common/dynamicPages/views/workflow/printContentView';
import Poll from '@glu/polling';
import { appBus } from '@glu/core';
import http from '@glu/core/src/http';
import services from 'services';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import viewTemplate from 'app/reports/views/contentView.hbs';
import ReportContentView from 'app/reports/views/contentView';
import constants from 'app/reports/constants';
import ReportStatus from 'app/reports/models/reportStatus';
import { getLastPositionData, isDeepLinked } from 'common/util/deeplinkUtil';
import { asView } from 'common/util/reactUtil';
import AsyncPrintViewModal from 'components/AsyncReporting/AsyncPrintViewModal';
import AsyncReportContentView from 'app/reports/views/asyncReportContentView';
import helpers from 'components/AsyncReporting/helpers';
import systemConfig from 'system/configuration';
import template from './printViewModal.hbs';

const ReportView = Layout.extend({
    template,
    className: 'print modal',
    regions: {
        alertRegion: '#alertRegion',
    },
    ui: {
        $modal: '.modal-dialog',
        $modalContent: '[data-hook="getModalContent"]',
        $modalBody: '[data-hook="getModalBody"]',
        $alertRegion: '[data-hook="getAlertRegion"]',
        $buttons: '[data-hook="getBtnContainer"] .btn',
    },
    initialize(options) {
        this.exportModel = options.exportModel;
        this.exportURL = options.exportURL;
        this.viewId = options.viewId;
        this.formMethod = options.formMethod;
        this.hasDetailReport = (options.exportModel.detailReportId > 0 && options.exportModel.expData === 'transaction');
        this.isApprovalDisclosure = options.isApprovalDisclosure;
        this.approvalDisclosureModel = options.approvalDisclosureModel;
        this.approvalDisclosureGrid = options.approvalDisclosureGrid;
        this.approvalDisclosureModelsArray = options.approvalDisclosureModelsArray;
        this.approvalDisclosureResponse = options.approvalDisclosureResponse;
        this.disclosureActionMode = options.disclosureActionMode;
        this.tnum = options.tnum;
        this.updateCount = options.updateCount;
        this.isAdmin = systemConfig.isAdmin();
        this.asyncReport = options.asyncReport ?? true;
        if (options.title && options.title.length > 0) {
            this.title = options.title;
        } else {
            this.title = 'export.PrintListView';
        }
        if (this.hasDetailReport) {
            this.detailReportId = this.exportModel.detailReportId;
            this.createPoller();
            this.listenTo(this.appBus, constants.REPORT_READY_EVENT, this.renderReport);
            this.listenTo(this.appBus, constants.REPORT_RUNNING_EVENT, this.renderStatus);
            this.listenTo(this.appBus, constants.REPORT_ERROR_EVENT, this.showError);
            this.listenTo(this.appBus, constants.REPORT_TIMEOUT_EVENT, this.showError);
        }

        // DeepLink/iFrame setup
        if (isDeepLinked()) {
            this.listenTo(appBus, 'iframePosition', this.updatePositionFromIframe);
        }
    },

    /**
     * Accepts the scroll event message data from the parent frame
     * Figure out how tall the modal can be.
     * @param {object} data
     */
    setModalContentHeight(data) {
        const isAsyncModal = this.ui.$modalBody.find('.async-modal').length;
        if (isAsyncModal) {
            return;
        }

        const $modalParts = this.ui.$modalBody.siblings();
        const modalSpace = $modalParts.filter('.modal-header').height()
            + $modalParts.filter('.modal-footer').height();
        const calculatedHeight = data.usableHeight - modalSpace;

        this.ui.$modalContent.css('height', calculatedHeight);
    },

    /**
     * @param {object} data - position data
     *
     * Adjust the top edge position of the modal displayed on a deeplink in iFrame.
     * The top edge position of the modal is adjusted whenever scrolling occurs.
     */
    updatePositionFromIframe(data) {
        if (!data) return;
        if (data.contentTop !== undefined && this.ui.$modalContent.css) {
            this.ui.$modalContent.css({
                top: data.contentTop,
            });
        }
        this.setModalContentHeight(data);
    },

    /**
     * Clean up pollers, event handlers, etc.
     */
    onClose() {
        if (this.poller) {
            this.poller.dispose();
        }
        if (this.approvalDisclosureResponse && this.approvalDisclosureModel
            && this.approvalDisclosureModel.get('CONSUMER_DISCLOSURE_USER_ACCEPTED') !== '1') {
            this.cancelDisclosure();
        } else if (this.approvalDisclosureModel) {
            this.approvalDisclosureModel.trigger('modelAction:cancelDisclosure', this.approvalDisclosureModel);
            this.close();
        }

        this.appBus.trigger('glu:modal:close');
    },
    onShow() {
        /*
         * If the grid has a detail report Id, run a jasper report
         * Else run the normal export process
         */
        if (this.hasDetailReport) {
            this.handleDetailReport();
        } else {
            this.handleSummaryReport();
        }

        // If in iframe, configure with the current position
        if (isDeepLinked()) {
            this.updatePositionFromIframe(getLastPositionData());
        }
    },

    /**
     * @method handleSummaryReport
     * Handles printing summary report logic in admin and client UI
     */
    handleSummaryReport() {
        if (this.isAdmin || !this.asyncReport) {
            delete this.exportModel.detailReportId;
            this.content.show(new PrintContentView({
                exportModel: this.exportModel,
                exportURL: this.exportURL,
                viewId: this.viewId,
                formMethod: this.formMethod,
            }));
        } else {
            delete this.exportModel.detailReportId;
            this.handleAsyncReport(
                this.exportURL || services.runAsyncListViewReport,
                this.exportModel,
            );
        }
    },

    /**
     * @method handleSummaryReport
     * Handles printing detailed report logic in admin and client UI
     */
    handleDetailReport() {
        if (this.isAdmin || !this.asyncReport) {
            this.run();
        } else {
            this.handleAsyncReport(services.runAsyncJasperReport, this.getPayload());
        }
    },

    /**
     * Handles running async reports
     * @param {string} service
     * @param {object} postData
     */
    handleAsyncReport(service, postData) {
        this.handleAsyncJasperReport({
            service,
            postData,
        });
    },

    /**
     * Handles showing async Jasper reports
     * @param {object} options
     */
    handleAsyncJasperReport(options) {
        this.$el.find('.modal-footer').hide();
        this.ui.$modal.removeClass('print-modal modal-2xlg');
        const AsyncPrintView = asView(AsyncPrintViewModal);
        const AsyncPrintViewModalView = new AsyncPrintView({
            onCancel: () => this.close(),
            onReportComplete: result => this.renderAsyncReport(result),
            onReportIncomplete: () => helpers.setupAsyncReportPoller(),
            ...options,
        });
        this.content.show(AsyncPrintViewModalView);
    },

    templateHelpers() {
        return {
            title: this.title,
            hasDetailReport: this.hasDetailReport,
            isApprovalDisclosure: this.isApprovalDisclosure,
        };
    },
    createPoller() {
        this.reportStatus = new ReportStatus({
            targetId: -1,
        });
        const self = this;
        const options = {
            iterator(poll) {
                this.reportStatus.fetch({
                    success(model) {
                        if (model.get('STATUS') === 'DN') {
                            self.appBus.trigger(constants.REPORT_READY_EVENT, model);
                        } else if (model.get('STATUS') === 'RJ') {
                            self.appBus.trigger(
                                constants.REPORT_ERROR_EVENT,
                                constants.ERROR_MESSAGE,
                            );
                        } else {
                            self.appBus.trigger(constants.REPORT_RUNNING_EVENT, model);
                            poll();
                        }
                    },
                    error() {
                        self.appBus.trigger(
                            constants.REPORT_ERROR_EVENT,
                            constants.STATUS_RETRIEVAL_ERROR_MESSAGE,
                        );
                    },
                });
            },
            limit: 100,
            onLimit() {
                self.appBus.trigger(constants.REPORT_TIMEOUT_EVENT, constants.TIMEOUT_MESSAGE);
            },
            interval: 5000,
        };

        this.poller = new Poll(options, this);
    },
    run() {
        // Disable the buttons while the report runs
        this.ui.$buttons.attr('disabled', true);

        this.ui.$alertRegion.fadeIn(100);
        this.content.close();

        this.runReport((result) => {
            this.reportStatus.targetId = result.targetId;
            this.poller.start();
        }, () => {
            this.appBus.trigger(
                constants.REPORT_ERROR_EVENT,
                constants.RUN_REQUEST_ERROR_MESSAGE,
            );
        });
    },

    runReport(success, failure) {
        const url = services.runReport;
        http.post(url, this.getPayload(), success, failure);
        const message = alert.info(locale.get(constants.STATUS_MESSAGE));
        this.alertRegion.show(message);
    },

    /**
     * Handles generating reports payload
     * @return {object}
     */
    getPayload() {
        return {
            productCode: this.exportModel.actionData.productCode,
            reportId: this.detailReportId,
            searchFields: this.exportModel.searchFields,
            viewId: this.exportModel.viewId,
            additionalParameters: this.exportModel.additionalParameters,
            sortFields: this.exportModel.sortFields,
            detailSortFields: this.exportModel.detailSortFields,
        };
    },

    renderReport(result) {
        // Enable the buttons when the report completes
        this.ui.$buttons?.attr?.('disabled', false);

        this.filledReport = result;

        this.content?.show?.(new ReportContentView({
            model: result,
            template: viewTemplate,
            reportId: result.get('SEQNO'),
            target: 'pdfContent',
            disposition: 'view',
            exportFormat: 'PDF',
            type: 'jasper',
            formId: 'viewPDF',
        }));
        this.ui.$alertRegion?.fadeOut?.(300);
    },

    /**
     * Renders async report pdf file when report generation finishes in time.
     * @param {object} result
     */
    renderAsyncReport(result) {
        this.ui.$modal.addClass('print-modal modal-2xlg');
        this.content.show(new AsyncReportContentView({
            hasDetailReport: this.hasDetailReport,
            ...result,
        }));
    },

    acceptDisclosure() {
        if (this.approvalDisclosureModel) {
            this.approvalDisclosureModel.set({
                CONSUMER_DISCLOSURE_USER_ACCEPTED: '1',
                CONINTLWIREFLAG: '1',
            });
            if (this.tnum) {
                this.approvalDisclosureModel.set({
                    TNUM: this.tnum,
                });
            }
            if (this.updateCount) {
                this.approvalDisclosureModel.set({
                    UPDATECOUNT__: this.updateCount,
                });
            }
        }
        if (this.approvalDisclosureModelsArray) {
            util.forEach(this.approvalDisclosureModelsArray, (item) => {
                const model = this.approvalDisclosureGrid.wrapper.rows.get(item);
                model.set('CONSUMER_DISCLOSURE_USER_ACCEPTED', '1');
            });
            this.trigger('list:multi:action:action_acceptDisclosure', this.approvalDisclosureModelsArray, {
                name: 'CONSUMER_DISCLOSURE_USER_ACCEPTED',
                value: '1',
            });
        } else if (this.approvalDisclosureGrid) {
            this.approvalDisclosureGrid.trigger('rowAction', {
                action: 'approve',
                model: this.approvalDisclosureModel,
            });
        } else if (this.approvalDisclosureModel) {
            this.approvalDisclosureModel.set({
                DISCLOSURE_ACTION_MODE: this.disclosureActionMode,
            });
            this.approvalDisclosureModel.trigger('modelAction:acceptDisclosure', this.approvalDisclosureModel);
        }
        this.close();
    },
    cancelDisclosure() {
        if (this.approvalDisclosureModel) {
            this.approvalDisclosureModel.set({
                CONSUMER_DISCLOSURE_USER_REJECTED: '1',
                CONINTLWIREFLAG: '1',
            });
            if (this.tnum) {
                this.approvalDisclosureModel.set({
                    TNUM: this.tnum,
                });
            }
            if (this.updateCount) {
                this.approvalDisclosureModel.set({
                    UPDATECOUNT__: this.updateCount,
                });
            }
            if (this.approvalDisclosureModel.get('ENTRYMETHOD') === '1' && this.approvalDisclosureModel.get('MAX_SIGNATURES_OVERRIDE') === '0') {
                this.close();
            } else {
                this.approvalDisclosureModel.trigger('modelAction:acceptDisclosure', this.approvalDisclosureModel);
                this.close();
            }
        }
    },
    showError(result) {
        const message = alert.danger(locale.get(result));
        this.alertRegion.show(message);
    },
    getCacheName() {
        return 'model-advanceFilter';
    },
    renderStatus() {
        const txt = this.ui.$alertRegion?.text?.();
        const message = alert.info(`${txt}.`);
        this.alertRegion.show(message);
    },
});

export default ReportView;
