import $ from 'jquery';
import services from 'services';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import alert from '@glu/alerts';
import moment from 'moment';
import locale from '@glu/locale';
import { log } from '@glu/core';
import dynamicPagesConstants from 'common/dynamicPages/api/constants';
import format from '@glu/core/src/templateHelpers';
import Formatter from 'system/utilities/format';
import userInfo from 'etc/userInfo';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import Transfer from 'app/payments/multiTransfer/model/transfer';
import Transfers from 'app/payments/multiTransfer/collections/transfers';
import scroll from 'common/util/scroll';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import CompositeView from 'app/payments/multiTransfer/views/multiTransferCompositeView';
import ModifyTransferDialog from 'app/payments/multiTransfer/views/modifyTransferDialog';
import domUtil from 'common/util/domUtil';
import AccountHeader from './accountHeaderView';
import addMultiTransferTmpl from './addMultiTransfer.hbs';

export default EntryView.extend({
    template: addMultiTransferTmpl,

    events: {
        'click #ADDACCOUNT': 'addTransfer',
    },

    ui: {
        summaryAmount: '[data-field="summary-amount"]',
        summaryDate: '[data-field="summary-date"]',
        summaryCurrency: '[data-field="summary-currency"]',
        summaryBene: '[data-field="summary-beneficiaries"]',
    },

    regions: {
        transfersFromAccountRegion: '[data-region="transfersFromAccountRegion"]',
        transfersToAccountRegion: '[data-region="transfersToAccountRegion"]',
    },

    initialize(options) {
        this.subType = options.subType;
        this.transfers = new Transfers([], options);
        this.accountHeaderModel = new Model();
        this.accountHeaderView = new AccountHeader({
            model: this.accountHeaderModel,
        });

        EntryView.prototype.initialize.call(this, options);
        this.listenTo(this.appBus, ('multiTransfer:removeTransfer'), this.removeTransfer);
        this.listenTo(this.appBus, ('multiTransfer:modifyTransfer'), this.showModifyTransferDialog);
        this.listenTo(this.appBus, ('multiTransfer:modifyTransferAmount'), this.modifyTransferAmount);
    },

    onRender() {
        EntryView.prototype.onRender.call(this);

        if (this.hasLoadedRequiredData()) {
            const region = (this.isManyToOne())
                ? this.transfersFromAccountRegion : this.transfersToAccountRegion;
            region.show(new CompositeView({
                collection: this.transfers,
                subType: this.subType,
            }));
            this.listenTo(this.pageView, 'ui-loaded', this.pageViewLoaded);
        }
    },

    /**
     * Helper function to determine if its a One2Many or Many2One transfer
     * @return {boolean} true if it is a many to one transfer, false for one to many.
     */
    isManyToOne() {
        return this.subType === 'MANY2ONE';
    },

    /**
     * Called once when the page has been loaded and the UI elements
     * have been rendered.
     */
    pageViewLoaded() {
        const self = this;
        self.setValidValueDate();
        $('#VALUE_DATE').on('change', () => {
            self.setValidValueDate();
        });

        $('#BENE_ACCOUNT, #DEBIT_ACCOUNT_NUMBER').on('change', () => {
            self.alertRegion.reset();
            self.setValidValueDate();
            self.checkSameAccountAndCrossCurrency();
        });
    },

    templateHelpers() {
        const obj = EntryView.prototype.templateHelpers.call(this, undefined);
        const self = this;
        return util.extend(
            obj,
            {
                title() {
                    if (self.isManyToOne()) {
                        return locale.get('payment.NewManyToOneTransfer');
                    }
                    return locale.get('payment.NewOneToManyTransfer');
                },

                // Flips bracket depending on multi-transfer type
                getTransferType() {
                    return self.isManyToOne() ? 'many-to-one' : 'one-to-many';
                },
            },
        );
    },

    resizeBracket() {
        // Expands Bracket Height to Match Transfers List Height
        const $transferListHeight = $('#multiTransferView').height() - 20;

        const $tlistHalf = ($transferListHeight / 2);

        if ($transferListHeight >= 50) {
            /*
             * If list is taller than default bracket graphic,
             * then increase the bracket height to match
             */
            $('.bracket').height($transferListHeight);

            // Moves Bracket Label down with Bracket
            $('.bracket-label').css('margin-top', $tlistHalf);
        }
    },

    /**
     * Adds a transfer to the grid
     */
    addTransfer() {
        this.alertRegion.reset();
        const { model } = this.pageView;

        this.checkSameAccountAndCrossCurrency();

        if (!model.isValid()) {
            scroll.scrollToFirstError();
            return;
        }

        const newTransfer = new Transfer(
            util.clone(model.attributes),
            {
                subType: this.subType,
            },
        );

        this.transfers.add(newTransfer);
        this.lockAccount(true);
        this.updateLockedAccountText();
        this.updateNotification();
        this.updateTransferTotal();
        this.updateTransferSummary();
        this.resizeBracket();
        this.clearFields();
    },

    /**
     * Checks for same account and cross currency.
     * Shows an alert message on errors.
     */
    checkSameAccountAndCrossCurrency() {
        const { model } = this.pageView;

        if (this.isSameAccount()) {
            this.alertView = alert.warning(
                locale.get('payment.FromAndToCannotBeSame'),
                {
                    canDismiss: false,
                },
            );
            this.alertRegion.show(this.alertView);
            return;
        }

        if (this.isCrossCurrency()) {
            const message = locale.get(
                'payment.CrossCurrencyWarning',
                model.get('DEBIT_ACCOUNT_NUMBER'),
                model.get('DEBIT_CURRENCY'),
                locale.get('payment.ToAccount'),
                model.get('CREDIT_CURRENCY'),
            );

            this.alertView = alert.warning(
                message,
                {
                    canDismiss: false,
                },
            );
            this.alertRegion.show(this.alertView);
        }
    },

    /**
     * Removes a transfer from the grid.
     */
    removeTransfer() {
        this.updateTransferTotal();
        this.updateTransferSummary();
        this.updateNotification();
        this.updateLockedAccountText();
        this.resizeBracket();

        const size = this.transfers.size();
        if (size === 0) {
            this.lockAccount(false);
        }
    },

    /**
     * Opens a modify transfer dialog for transfers displayed on the grid.
     */
    showModifyTransferDialog(transfer) {
        const modifyTransferDialog = new ModifyTransferDialog({
            transfer,
        });
        this.listenTo(modifyTransferDialog, 'save', this.modifyTransfer);
        dialog.open(modifyTransferDialog);
    },

    /**
     * Saves changes entered in the modify dialog back to the grid item.
     */
    modifyTransfer() {
        this.transfers.trigger('reset');
        this.updateTransferTotal();
        this.updateTransferSummary();
    },

    /**
     * Allows for quick edit of the amount directly on the grid.
     */
    modifyTransferAmount(transfer, $input) {
        const originalAmount = transfer.getAmount();
        const enteredAmount = $input.val();
        if (!util.isEmpty(enteredAmount)) {
            transfer.setAmount(format.amount(enteredAmount));
            this.updateTransferTotal();
            this.updateTransferSummary();
        } else {
            $input.val(originalAmount);
        }
    },

    /**
     * Checks the selected "from" and "to" accounts are the same.
     * @return {boolean} true if they are the same, false if different or both
     * are empty.
     */
    isSameAccount() {
        const { model } = this.pageView;
        const debit = model.get('DEBIT_ACCOUNT_NUMBER');
        const credit = model.get('BENE_ACCOUNT');
        if (util.isEmpty(debit) && util.isEmpty(credit)) {
            return false;
        }
        return debit === credit;
    },

    /**
     * Checks the selected "from" and "to" accounts have different currencies.
     * @return {boolean} true if they are different currencies, false if they
     * are the same
     * or either one is empty.
     */
    isCrossCurrency() {
        const { model } = this.pageView;
        const debitCurrency = model.get('DEBIT_CURRENCY');
        const creditCurrency = model.get('CREDIT_CURRENCY');
        if (util.isEmpty(debitCurrency) || util.isEmpty(creditCurrency)) {
            return false;
        }
        return debitCurrency !== creditCurrency;
    },

    /**
     * Resets the fields on the form
     */
    clearFields() {
        const { model } = this.pageView;

        model.set('SPECIAL_INSTRUCTIONS', '');
        if (this.isManyToOne()) {
            model.set('DEBIT_ACCOUNT_NUMBER', '');
            model.set('DEBIT_AMOUNT', '');
        } else {
            model.set('BENE_ACCOUNT', '');
            model.set('CREDIT_AMOUNT', '');
        }
    },

    /**
     * Makes either the "from" or "to" section of the screen editable or not editable.
     * @param  {boolean} lock - true to lock the account, false to unlock
     */
    lockAccount(lock) {
        let locked;
        let sectionToLock;
        const lockedCssClass = 'locked-section';
        const readOnlyCssClass = 'read-only-field';
        let accountSelector;
        let popupSelector;

        if (this.isManyToOne()) {
            sectionToLock = $('.RTGS_INST_TRANSFER_Container1_Group2');
            locked = sectionToLock.hasClass(lockedCssClass);
            accountSelector = '#BENE_ACCOUNT';
            popupSelector = '[data-field="BENE_ACCOUNT_LOOKUP"]';
        } else {
            sectionToLock = $('.RTGS_INST_TRANSFER_Container1One2Many_Group1One2Many');
            locked = sectionToLock.hasClass(lockedCssClass);
            accountSelector = '#DEBIT_ACCOUNT_NUMBER';
            popupSelector = '[data-field="DEBIT_ACCOUNT_NUMBER_LOOKUP"]';
        }

        if (lock && !locked) {
            sectionToLock.addClass(lockedCssClass);
            $('.form-group-label', sectionToLock).prepend('<span class="icon-lock"></span> ');
            $(accountSelector).prop('readonly', true).addClass(readOnlyCssClass);
            $(popupSelector).hide();
        } else if (!lock) {
            sectionToLock.removeClass(lockedCssClass);
            $('.form-group-label', sectionToLock).find('.icon-lock').remove();
            $(accountSelector).prop('readonly', false).removeClass(readOnlyCssClass);
            $(popupSelector).show();
        }
    },

    /**
     * Updates the account header in the grid with the account name and number of
     * the locked account.
     *
     */
    updateLockedAccountText() {
        const { model } = this.pageView;
        if (this.isManyToOne()) {
            this.transfersToAccountRegion.show(this.accountHeaderView);
            this.accountHeaderModel.set('accountTitle', locale.get('payment.ToAccount'));
            this.accountHeaderModel.set('accountNumber', model.get('BENE_ACCOUNT'));
        } else {
            this.transfersFromAccountRegion.show(this.accountHeaderView);
            this.accountHeaderModel.set('accountTitle', locale.get('payment.FromAccount'));
            this.accountHeaderModel.set('accountNumber', model.get('DEBIT_ACCOUNT_NUMBER'));
        }
    },

    /**
     * Updates the notification area above the grid with
     * the correct message when transfers are added to the grid.
     */
    updateNotification() {
        const size = this.transfers.size();
        if (size === 0) {
            this.alertRegionBottom.reset();
        } else {
            const message = `${size} ${size === 1 ? locale.get('payment.single.AccountAddedForTransfer') : locale.get('payment.multi.AccountAddedForTransfer')}`;
            this.alertView = alert.success(
                message,
                {
                    canDismiss: false,
                },
            );
            this.alertRegionBottom.show(this.alertView);
        }
    },

    /**
     * Updates the transfers totals at the top of the page
     * when adding or removing items from the grid.
     */
    updateTransferTotal() {
        const totalFormatted = Formatter.formatNumber(this.transfers.totalAmount() || 0);
        this.ui.summaryAmount.html(totalFormatted);
    },

    /**
     * Updates the transfer summary section at the bottom of the page
     * with the appropriate summary text.
     */
    updateTransferSummary() {
        const size = this.transfers.size();
        const message = `${size} ${size === 1 ? locale.get('payment.Account') : locale.get('payment.Accounts')}`;
        this.ui.summaryBene.html(message);
        this.ui.summaryCurrency.html(this.transfers.getCurrency());
    },

    /**
     * Sends the items in the grid to the server for saving.
     */
    save() {
        if (this.transfers.size() === 0) {
            return;
        }
        this.disableButtons();

        const self = this;
        const jsonData = [];

        const selectedDate = this.getEarliestAvailableDate(this.pageView.model.get('VALUE_DATE'));

        this.transfers.each((transfer) => {
            // set all the transfers to the selected user date
            transfer.set('VALUE_DATE', selectedDate);
            self.normalizeTransferForSave(transfer);
            jsonData.push(transfer.convertModelAttributesToJSON());
        });

        const options = {
            success(confirmResponse) {
                if (confirmResponse.confirms && confirmResponse.confirms.totalFail > 0) {
                    self.renderError(confirmResponse);
                } else {
                    self.pageView.trigger('modelAction:success', confirmResponse);
                }
                self.enableButtons();
            },

            error(model) {
                const errors = model.message.message.join(', ');
                dialog.alert(errors, 'Error');
                self.enableButtons();
                scroll.scrollToFirstError();
            },
        };

        const url = services.generateUrl(self.options.serviceName);

        http.post(
            url,
            {
                items: jsonData,
            },
            (result) => {
                options.success(result);
            },
            (result) => {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: JSON.parse(result.responseText),
                });
                scroll.scrollToFirstError();
            },
        );
    },

    disableButtons() {
        // TODO: clean up?
        domUtil.setDisabled($('.btn'), true);
    },

    enableButtons() {
        domUtil.setDisabled($('.btn'), false);
    },

    /**
     *
     * Sets additional fields in the model which the server requires to be set
     * before sending off to the server.
     *
     */
    normalizeTransferForSave(transfer) {
        transfer.set('USERGROUP', userInfo.get('group'));
        transfer.set('PRODUCT', this.options.productCode);
        transfer.set('FUNCTION', this.options.functionCode);
        transfer.set('TYPE', this.options.typeCode);
        transfer.set('SUBTYPE', '*');
        transfer.set('ENTRYMETHOD', '0');
        transfer.set('EXCHANGE_RATE', '1');
        transfer.set('MARKETCONVENTION', 'D');
        transfer.set('TRAN_DATE', transfer.get('VALUE_DATE'));

        const amount = transfer.getAmount();
        transfer.set('CREDIT_AMOUNT', amount);
        transfer.set('DEBIT_AMOUNT', amount);

        const currency = transfer.getCurrency();
        transfer.set('CREDIT_CURRENCY', currency);
        transfer.set('DEBIT_CURRENCY', currency);
        transfer.set('ENTERED_AMOUNT_FLAG', this.isManyToOne() ? 'D' : 'C');
    },

    getEarliestAvailableDate(selectedDate) {
        const { earliestDay } = this.pageView.dates;
        const beforeEarliestDay = moment(selectedDate, userInfo.getDateFormat()).diff(moment(earliestDay), 'mins');

        if (beforeEarliestDay < 0) {
            return Formatter.formatDate(earliestDay);
        }
        return selectedDate;
    },

    /**
     * Overrides the default behavior of navigating to the list view and instead
     * displays the errors on this entry page.
     *
     */
    renderError(confirmResponse, errorCount) {
        const action = this.mode.toUpperCase();
        const confirm = typeof confirmResponse === 'object' ? confirmResponse.confirms : null;
        const successCount = confirm !== null ? confirm.totalSuccess : confirmResponse;
        const failCount = confirm !== null ? confirm.totalFail : errorCount;
        const totalCount = failCount + successCount;
        const success = successCount === totalCount;
        const alertFunc = success ? alert.success : alert.danger;
        const chainedMessages = typeof confirmResponse === 'object' ? confirmResponse.message : null;
        const message = typeof confirmResponse === 'object' && confirmResponse.message.length > 0 ? confirmResponse.message[chainedMessages.length - 1] : action;

        const confirms = new Confirms({
            confirms: confirmResponse ? confirmResponse.confirms : null,
        });

        // display notification message
        this.alertView = alertFunc(
            message,
            {
                details: confirmResponse
                    && confirmResponse.confirms.confirmResults[0].confirmData[0].item
                    ? confirms : null,
                canDismiss: !!confirmResponse,
                animate: true,
            },
        );

        if (message) {
            this.alertRegion.show(this.alertView);
        }
        scroll.scrollToFirstError();
    },

    setValidValueDate() {
        const { model } = this.pageView;
        const postData = {
            paymentType: this.options.typeCode,
            debitBank: model.get('DEBIT_BANK_CODE'),
            debitCurrency: model.get('DEBIT_CURRENCY'),
            debitBankCountry: model.get('DEBIT_COUNTRY'),
            subType: model.jsonData.subtype === undefined ? '*' : model.jsonData.subtype,
            creditCurrency: model.get('CREDIT_CURRENCY'),
            creditBankCountry: model.get('BENE_BANK_COUNTRY'),
        };

        /*
         * if we already have a transfer added to the list, then the user has already
         * selected a date,
         * therefore, we do not need to go and grab new values dates nor update the UI.
         * So, skip this, if transfer is already in list.
         */
        if (this.mode.toUpperCase() === 'INSERT' && this.transfers.length > 0) {
            return;
        }

        const dateService = services.generateUrl(dynamicPagesConstants.URL_GETDATESLIST);
        http.post(dateService, postData, (result) => {
            this.handleBusinessDaysResponse(result);
        }, (err) => {
            log.error(err);
        });
    },

    handleBusinessDaysResponse(result) {
        const { model } = this.pageView;
        const { processingDates } = this.pageView;
        let earliestDate = moment(result.earliestDay);
        earliestDate = earliestDate.format(userInfo.getDateFormat());

        // this gets updated based on the more information provided to the date service call
        this.pageView.dates.earliestDay = earliestDate;

        processingDates.daysForward.shift();
        processingDates.daysForward.push(result.maxForwardDays);

        processingDates.processingDays.shift();
        processingDates.processingDays.push(result.businessDays);

        processingDates.cutOffTimes.shift();
        processingDates.cutOffTimes.push(result.cutoff);

        // remove previous blocked dates
        processingDates.blockedDates.splice(0, processingDates.blockedDates.length);
        if (result.holidays.length > 0) {
            processingDates.blockedDates.push(...result.holidays);
        }

        const dateRangePicker = $('#VALUE_DATE').data('daterangepicker');
        dateRangePicker.updateCalendars({
            blockedDates: processingDates.blockedDates,
            daysBack: earliestDate,
            daysForward: processingDates.daysForward[0],
        });

        let currentSelectedDate = model.get('VALUE_DATE');
        if (currentSelectedDate) {
            currentSelectedDate = moment(currentSelectedDate, userInfo.getDateFormat());
            const isValidDate = !dateRangePicker.isInvalidDay(currentSelectedDate);
            if (isValidDate) {
                return;
            }
        }
        model.set('VALUE_DATE', earliestDate);
    },
});
