import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({

    defaults: {
        TOKENSERIALNUMBER: null,
        TOKENTYPE: null,
        USERID: null,
        ID: null,
        DEVICETYPELABEL: null,
    },

    /**
     * sync with support for multiple custom method types
     * @param {string} method
     * @param {object} model
     * @param {object} options
     * @returns {function}
     */
    sync(method, model, options) {
        const map = {
            assign: '/tokenManagement/assignToken',
            disable: '/tokenManagement/disable',
            enable: '/tokenManagement/enable',
            lost: '/tokenManagement/markLostToken',
            found: '/tokenManagement/markFound',
            read: '/tokenManagement/user/fetchUsers',
            expire: '/tokenManagement/expiringTokens/listAllTokens',
            replace: '/tokenManagement/replaceToken',
            misplaced: '/tokenManagement/misplaced',
            email: '/tokenManagement/email',
            tokens: `/tokenManagement/listTokens/${this.get('COMPANYID')}`,
            unassign: '/tokenManagement/unassignToken',
            unlockUser: '/tokenManagement/user/unLock',
            enableUser: '/tokenManagement/user/enable',
            disableUser: '/tokenManagement/user/disable',
            inquiry: '/inquiry/getQueryResults',
        };

        return this.postData(method, model, options, map[method]);
    },

    /**
     * @method postData
     * @param {string} method
     * @param {object} model - the model
     * @param {object} options - an options object
     * @param {string} url
     */
    postData(method, model, options, url) {
        let urlParam = url;
        const data = this.convertModelAttributesToServerJSON(model, method);
        urlParam = services.generateUrl(urlParam);

        return http.post(urlParam, data, options.success, httpError.getResult(options, model));
    },

    /**
     * - call sync with the proper method
     * @method syncData
     * @param {string} method
     * @param {object} options - an options object
     */
    syncData(method, options) {
        this.sync(method, this, options);
    },

    /**
     * @method addCompanyValidator
     * Helper method to add validator for Company dropdown
     */
    addCompanyValidator() {
        this.validators = {
            COMPANYID: {
                description: locale.get('administration.company'),
                exists: true,
            },
        };
    },

    /**
     * @method addExpireValidator
     * Helper method to add validator for Time Frame dropdown
     */
    addExpireValidator() {
        this.validators = {
            DAYS: {
                description: locale.get('tknmgmt.ui.timeFrame'),
                exists: true,
            },
        };
    },

    /**
     * @method addTokenAssignmentValidator
     * Helper method to add validator for Token assignmentList in model
     */
    addTokenAssignmentValidator() {
        this.addValidator('TOKENSERIALNUMBER', {
            exists: true,
            description: locale.get('tknmgmt.ui.token'),
        });
        this.addValidator('USERID', {
            exists: true,
            description: locale.get('tknmgmt.ui.user'),
        });
    },

    /**
     * @method addTokenLostReplaceValidator
     * Helper method to add validator for Token Serial Number in model
     */
    addTokenLostReplaceValidator() {
        this.addValidator('TOKENSERIALNUMBER', {
            exists: true,
            description: locale.get('tknmgmt.ui.token'),
        });
    },

    /**
     * @method addTokenTypeValidator
     * Helper method to add validator for Token type in model
     */
    addTokenTypeValidator() {
        this.addValidator('TOKENTYPE', {
            exists: true,
            description: locale.get('PS.Type'),
        });
    },

    /**
     * @method addTokenDeviceTypeValidator
     * Helper method to add validator for Token Device type in model
     */
    addTokenDeviceTypeValidator() {
        this.addValidator('ID', {
            exists: true,
            description: locale.get('tknmgmt.ui.device.type'),
        });
    },

    /**
     * @method convertModelAttributesToServerJSON
     * @param {object} model
     * @param {string} method - the string method
     */
    convertModelAttributesToServerJSON(model, method) {
        let json = {};
        const commonTokenActions = ['enable', 'disable', 'found', 'lost', 'unlock'];

        if (commonTokenActions.indexOf(method) > -1) {
            json = {
                userId: model.get('USERID'),
            };
            if (method !== 'unlock') {
                json.tokenSerialNumber = model.get('TOKENSERIALNUMBER');
            }
        }
        switch (method) {
        case 'enable':
        case 'disable':
        case 'found':
        case 'unlockUser':
        case 'enableUser':
        case 'disableUser':
        case 'misplaced':
        case 'email':
            json = {
                tokenInfo: model.get('tokenInfo'),
            };
            break;
        case 'assign':
        case 'unassign':
            json = {
                assignmentList: model.get('assignmentList'),
            };
            break;
        case 'lost':
        case 'replace':
            json = {
                tokenReplacement: model.get('tokenReplacement'),
            };
            break;
        case 'inquiry':
            json = {
                IncludeMapData: 1,
                queryCriteria: {
                    action: {
                        typeCode: 'TKNMGMT',
                        entryMethod: 0,
                        productCode: 'TKNMGMT',
                        actionMode: '*',
                        functionCode: 'MAINT',
                    },
                    inquiryId: model.get('inquiryId'),
                },
            };
            break;
        case 'expire':
            json = {
                item: [{
                    name: 'DAYS',
                    value: this.get('DAYS'),
                }],
            };
            if (this.has('COMPANYID')) {
                json.item.push({
                    name: 'COMPANYID',
                    value: this.get('COMPANYID'),
                });
            }
            break;
        default:
            break;
        }
        return json;
    },
});
