import 'jquery-ui/ui/version';
import 'jquery-ui/ui/focusable'; // menuView needs :focusable selector only
import util from '../util';
import $window from '../$window';
import $document from '../$document';
import ItemView from '../itemView';
import template from './menu.hbs';

export default ItemView.extend({
  initialize() {
    util.bindAll(this, 'handleResize', 'handleDocumentMouseMove', 'handleDocumentTouchStart');
  },

  template,

  ui: {
    $navbar: 'nav.glu-menu',
    $top: '.top',
    $parents: '.top > li',
    $hasDD: '.has-dd',
    $dropdowns: '.dropdown',
    $all: '[class^="item-"]',
    $links: 'nav.glu-menu a[aria-controls="main"]'
  },

  attributes: {
    'data-qa': 'menubar-container'
  },

  events: {
    'keypress @ui.$top': 'handleParentKeypress',
    'blur @ui.$all': 'handleBlur',
    'click @ui.$hasDD': 'handleClick',
    'focus @ui.$all': 'handleFocus',
    'keydown @ui.$all': 'handleKeydown',
    'keypress @ui.$all': 'handleKeypress',
    'mouseenter @ui.$all': 'handleMouseenter',
    'mouseleave @ui.$top': 'resetDropdowns',
    'mouseleave @ui.$hasDD': 'handleMouseleave',
    'mouseout @ui.$all': 'handleMouseout',
    'click @ui.$links': 'handleCloseMenus'
  },

  templateHelpers() {
    // This is necessary as `icon` is a reserved template helper
    return {
      iconName: this.model.get('iconName') || this.model.get('menuIcon') || this.model.get('icon') || '',
      menuCategory: this.model.get('menuCategory') || '' // ???
    };
  },

  menuToggle() {
    this.ui.$navbar.toggleClass('open');
    this.appBus.trigger('pbNavToggle', this.ui.$navbar);
  },

  delegateEvents() {
    ItemView.prototype.delegateEvents.call(this);
    $window.on({
      resize: this.handleResize
    });

    $document.find('html').on({
      mousemove: this.handleDocumentMouseMove,
      touchstart: this.handleDocumentTouchStart
    });
  },

  undelegateEvents() {
    ItemView.prototype.undelegateEvents.call(this);
    $window.off({
      resize: this.handleResize
    });

    $document.find('html').off({
      mousemove: this.handleDocumentMouseMove,
      touchstart: this.handleDocumentTouchStart
    });
  },

  // Menu Accessibility

  access: {

    active: null,

    isTouch() {
      return this.touched && (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    },

    keys: {
      tab: 9,
      enter: 13,
      esc: 27,
      space: 32,
      left: 37,
      up: 38,
      right: 39,
      down: 40
    },

    openDropOnFocus: false

  },

  handleDocumentMouseMove() {
    this.touched = false;
  },

  handleDocumentTouchStart() {
    this.touched = true;
  },


  /* enable users to jump into the menu from the focusable parent element */
  handleParentKeypress(e) {
    if (e.keyCode === this.access.keys.enter || e.keyCode === this.access.keys.space) {
      e.preventDefault(); // stop the page from jumping
      this.ui.$top.find(':focusable').eq(0).focus();
    }
  },


  /*
   * Close menus on touch screen devices when menu link is tapped.
   */
  handleCloseMenus() {
    if (this.access.isTouch() && !this.ui.$navbar.hasClass('locked')) {
      this.ui.$dropdowns.attr('aria-hidden', 'true').siblings(this.ui.$hasDD).removeClass('dd-open');
    }

    // close the side menu on small screen view, if it's open
    if (this.ui.$navbar.hasClass('open')) {
      this.menuToggle();
    }

    return true;
  },


  handleBlur(e) {
    this.ui.$all.filter(e.target).removeClass('menu-focus');
    return true;
  },

  handleClick(e) {
    if (this.access.isTouch() || this.ui.$navbar.hasClass('locked')) {
      // if our menu item doesn't have an id, it's an icon inside the menu item
      const linkTarget = e.target.getAttribute('id') === null ? $document.find(e.target).parent() : e.target;

      // check if we have a dom element and the is() function exists otherwise we have an object
      if ((util.isFunction(linkTarget.is)) && linkTarget.is('li')) {
        // click the link
        linkTarget.children().eq(0).click();
      } else {
        // only attempt to open submenu's if the target isn't a link
        const $this = this.ui.$all.filter(linkTarget);
        const $dd = $this.next();

        if ($dd.is('[aria-hidden=false]')) {
          // The menu is already open - close it
          $this.removeClass('dd-open').blur();
          $dd.attr('aria-hidden', 'true');
        } else {
          // The menu is closed - close other menus and open it
          this.ui.$dropdowns.attr('aria-hidden', 'true').siblings(this.ui.$hasDD).removeClass('dd-open');
          $dd.attr('aria-hidden', 'false').siblings(this.ui.$hasDD).addClass('dd-open');
        }
      }
    }
  },

  handleFocus(e) {
    const $item = this.ui.$all.filter(e.target);

    // if activeItem is null, we are getting focus from outside the menu. Store
    // the item that triggered the event
    if (this.access.active === null) {
      this.access.active = $item;
    } else if ($item[0] !== this.access.active[0]) {
      return true;
    }

    // remove focus styling from all other menu items
    this.ui.$all.removeClass('menu-focus');

    // add styling to the active item
    this.access.active.addClass('menu-focus').closest(this.ui.$all).addClass('menu-focus');

    return true;
  },

  handleKeydown(e) {
    if (e.altKey || e.ctrlKey) {
      // Modifier key pressed: Do not process
      return true;
    }

    const $item = this.ui.$all.filter(e.target);
    const $itemUL = $item.closest('ul');

    switch (e.keyCode) {
      case this.access.keys.tab: {
        // hide all menu items and update their aria attributes
        this.ui.$hasDD.removeClass('dd-open');
        this.ui.$dropdowns.attr('aria-hidden', 'true');

        // remove focus styling from all menu items
        this.ui.$all.removeClass('menu-focus').removeClass('menu-hover');
        // Set active item to null
        this.access.active = null;
        break;
      }
      case this.access.keys.esc: {
        if (!$itemUL.is(this.ui.$top)) {
          // move up one level
          this.access.active = $itemUL.prev();
          this.access.active.removeClass('dd-open');
          // hide the active menu and update the aria attributes
          $itemUL.attr('aria-hidden', 'true');
        } else {
          this.access.active = $item;
        }

        if (!this.access.active !== 'null') {
          // Assign active state
          this.access.active.focus();
        }
        e.stopPropagation();
        return false;
      }
      case this.access.keys.enter:
      case this.access.keys.space: {
        if ($itemUL.is(this.ui.$top) && $item.is('.has-dd')) {
          // If we are on the vertical menu top-level, move down to the next level
          this.access.active = this.moveDown($item);
        } else {
          // process the menu choice
          $item.not('.disabled').click();

          // remove accessibility hover/focus styling
          this.ui.$all.removeClass('menu-hover').removeClass('menu-focus');
          this.ui.$hasDD.removeClass('dd-open');

          // close the menu
          this.ui.$dropdowns.attr('aria-hidden', 'true');

          // clear the active item
          $document.find('div[role=main]').focus();
          return true;
        }

        if (!this.access.active !== 'null') {
          // Assign active state
          this.access.active.focus();
        }
        e.stopPropagation();
        return false;
      }
      case this.access.keys.left: {
        if ($itemUL.is(this.ui.$top) ||
          (this.verticalMenu() === true && $itemUL.is(this.ui.$dropdowns) && !this.ui.$navbar.hasClass('open'))) {
          this.access.active = this.moveToPrevious($item);
          this.access.active.focus();
        }
        e.stopPropagation();
        return false;
      }
      case this.access.keys.right: {
        if ($itemUL.is(this.ui.$top) && (this.verticalMenu() === true && this.ui.$navbar.not('.open'))) {
          // If we are on the vertical menu top-level, move down to the next level
          this.access.active = this.moveDown($item);
        } else if ($itemUL.is(this.ui.$top)) {
          // If we are in the top-level menu, move to the next menu item
          this.access.active = this.moveToNext($item);
        }

        this.access.active.focus();

        e.stopPropagation();
        return false;
      }
      case this.access.keys.up: {
        if (this.verticalMenu() === true && $itemUL.is(this.ui.$top)) {
          // If this is a vertical menu and the root-level is active, move
          // to the previous root-level menu
          this.access.active = this.moveToPrevious($item);
        } else {
          this.access.active = this.moveUp($item);
        }

        if (!this.access.active !== 'null') {
          // Assign active state
          this.access.active.focus();
        }
        e.stopPropagation();
        return false;
      }
      case this.access.keys.down: {
        if (this.verticalMenu() === true && $itemUL.is(this.ui.$top)) {
          // If this is a vertical menu and the root-level is active, move
          // to the next root-level menu
          this.access.active = this.moveToNext($item);
        } else {
          this.access.active = this.moveDown($item);
        }

        if (!this.access.active !== 'null') {
          // Assign active state
          this.access.active.focus();
        }
        e.stopPropagation();
        return false;
      }
      default: {
        const chr = String.fromCharCode(e.which);

        this.access.active = this.goToChar($item, chr);
        if (!this.access.active !== 'null') {
          // Assign active state
          this.access.active.focus();
        }
        e.stopPropagation();
        return false;
      }
    }

    return true;
  },

  handleKeypress(e) {
    const $item = this.ui.$all.filter(e.target);

    if (e.altKey || e.ctrlKey || e.shiftKey) {
      // Modifier key pressed: Do not process
      return true;
    }

    switch (e.keyCode) {
      case this.access.keys.tab:
        return true;
      case this.access.keys.esc:
      case this.access.keys.enter:
      case this.access.keys.space:
      case this.access.keys.up:
      case this.access.keys.down:
      case this.access.keys.left:
      case this.access.keys.right:
        e.stopPropagation();
        return false;
      default: {
        const chr = String.fromCharCode(e.which);

        this.access.active = this.goToChar($item, chr);
        if (!this.access.active !== 'null') {
          // Assign active state
          this.access.active.focus();
        }
        e.stopPropagation();
        return false;
      }
    }
  },

  handleMouseenter(e) {
    if (!this.access.isTouch() && !this.ui.$navbar.hasClass('locked')) {
      const $this = this.ui.$all.filter(e.target);

      $this.addClass('menu-hover').focus();

      // If there is a dropdown
      if ($this.hasClass('has-dd')) {
        // If we are on a new top level menu item close the previous one
        if (this.ui.$dropdowns.not($this.siblings()).is('[aria-hidden=false]')) {
          this.ui.$dropdowns.attr('aria-hidden', 'true');
          this.ui.$hasDD.removeClass('dd-open');
        }
        // Set the aria-hidden flag to false
        $this.addClass('dd-open');
        $this.next().attr('aria-hidden', 'false');
      }
    } else {
      // if we are a touch device consider this a click since the first tap triggers mouseEnter event
      this.handleClick(e);
    }

    return true;
  },

  handleMouseleave(e) {
    if (!this.access.isTouch() && !this.ui.$navbar.hasClass('locked')) {
      this.ui.$dropdowns.filter(e.target)
        .find(this.ui.$all).removeClass('menu-hover').blur()
        .end()
        .closest(this.ui.$dropdowns)
        .attr('aria-hidden', 'true')
        .prev()
        .removeClass('dd-open');
    }

    return true;
  },

  handleMouseout(e) {
    if (!this.access.isTouch() && !this.ui.$navbar.hasClass('locked')) {
      this.ui.$all.filter(e.target).removeClass('menu-hover').blur();
    }

    return true;
  },

  moveToNext($item) {
    const $itemUL = $item.closest('ul');
    const $menuItems = $itemUL.children('li:visible').not('.separator');
    const menuNum = $menuItems.length;
    let menuIndex = $menuItems.index($item.closest('li'));
    let $newItem = null;
    let $childMenu = null;

    if ($itemUL.is(this.ui.$top)) {
      // this is the root level move to next sibling. This will require closing
      // the current child menu and opening the new one.
      if (menuIndex < menuNum - 1) { // not the last root menu
        $newItem = $item.closest(this.ui.$parents).next().children().first();
      } else { // wrap to first item
        $newItem = $menuItems.first().children().first();
      }

      // close the current child menu (if applicable)
      if ($item.attr('aria-haspopup') === 'true') {
        $childMenu = $item.next();

        if ($childMenu.attr('aria-hidden') === 'false') {
          // hide the child and update aria attributes accordingly
          $childMenu.attr('aria-hidden', 'true');
        }
      }

      // remove the focus styling from the current menu
      $item.removeClass('menu-focus');

      // open the new child menu (if applicable)
      if (this.access.openDropOnFocus === true && $newItem.attr('aria-haspopup') === 'true') {
        $childMenu = $newItem.next();

        // open the child and update aria attributes accordingly
        $childMenu.attr('aria-hidden', 'false');
      }
    } else {
      // at deepest level, move to the next root-level menu
      if (this.verticalMenu() === true) {
        // do nothing
        return $item;
      }

      let $parent = null;
      let $rootItem = null;

      // get the submenu item's parent UL
      $parent = $item.closest(this.ui.$dropdowns);

      // hide the current menu and update its aria attributes accordingly
      $parent.attr('aria-hidden', 'true');

      // remove the focus styling from the active menu
      $parent.find('a').removeClass('menu-focus');

      $rootItem = $parent.parent();

      menuIndex = this.ui.$parents.index($rootItem);

      // if this is not the last root menu item, move to the next one
      if (menuIndex < this.ui.$hasDD.length - 1) {
        $newItem = this.ui.$parents.eq(menuIndex).next().children().first();
      } else { // loop
        $newItem = this.ui.$parents.first().children().first();
      }

      if (this.access.openDropOnFocus === true && $newItem.attr('aria-haspopup') === 'true') {
        $childMenu = $newItem.next(this.ui.$dropdowns);

        // show the child menu and update it's aria attributes
        $childMenu.attr('aria-hidden', 'false');
      }
    }

    return $newItem;
  },

  moveToPrevious($item) {
    const $itemUL = $item.closest('ul');
    const $menuItems = $itemUL.children('li:visible').not('.separator');
    const menuIndex = $menuItems.index($item.closest('li'));
    let $newItem = null;
    let $childMenu = null;

    if ($itemUL.is(this.ui.$top)) {
      // this is the root level move to next sibling. This will require closing
      // the current child menu and opening the new one.
      if (menuIndex > 0) { // not the first root menu
        $newItem = $item.closest(this.ui.$parents).prev().children().first();
      } else { // wrap to first item
        $newItem = $menuItems.last().children().first();
      }

      // close the current child menu (if applicable)
      if ($item.attr('aria-haspopup') === 'true') {
        $childMenu = $item.next();

        if ($childMenu.attr('aria-hidden') === 'false') {
          // hide the child and update aria attributes accordingly
          $childMenu.attr('aria-hidden', 'true');
        }
      }

      // remove the focus styling from the current menu
      $item.removeClass('menu-focus');

      // open the new child menu (if applicable)
      if (this.access.openDropOnFocus === true && $newItem.attr('aria-haspopup') === 'true') {
        $childMenu = $newItem.next();

        // open the child and update aria attributes accordingly
        $childMenu.attr('aria-hidden', 'false');
      }
    } else {
      // This is a submenu, close it and move up to the parent
      const $parent = $itemUL.prev();

      $item.removeClass('menu-focus');
      $itemUL.attr('aria-hidden', 'true');

      // Set the new item
      $newItem = $parent;

      // add the focus styling to the new menu
      $newItem.removeClass('dd-open').addClass('menu-focus');
    }

    return $newItem;
  },

  moveDown($item) {
    const $itemUL = $item.closest('ul');
    const $menuItems = $itemUL.children('li').not('.separator');
    const menuNum = $menuItems.length;
    const menuIndex = $menuItems.index($item.closest('li'));
    let $newItem = null;
    let $newItemUL = null;

    if ($itemUL.is(this.ui.$top)) { // this is the root level menu
      if ($item.attr('aria-haspopup') !== 'true') {
        // No child menu to move to
        return $item;
      }

      // Move to the first item in the child menu
      $newItemUL = $item.next();
      $newItem = $newItemUL.find(this.ui.$all).first();

      // make sure the child menu is visible
      $item.addClass('dd-open');
      $newItemUL.attr('aria-hidden', 'false');
    } else if (menuIndex < menuNum - 1) {
      $newItem = $menuItems.eq(menuIndex + 1).children(this.ui.$all);
    } else {
      $newItem = $menuItems.first().children(this.ui.$all);
    }

    // remove the focus styling from the current item
    $item.removeClass('menu-focus');

    return $newItem;
  },

  moveUp($item) {
    const $itemUL = $item.closest('ul');
    const $menuItems = $itemUL.children('li').not('.separator');
    const menuIndex = $menuItems.index($item.closest('li'));
    let $newItem = null;

    if ($itemUL.is(this.ui.$top)) { // this is the root level menu
      // nothing to do
      return $item;
    }

    // if $item is not the first item in its menu, move to the previous item
    if (menuIndex > 0) {
      $newItem = $menuItems.eq(menuIndex - 1).children(this.ui.$all);
    } else {
      // loop to top of menu
      $newItem = $menuItems.last().children(this.ui.$all);
    }

    // remove the focus styling from the current item
    $item.removeClass('menu-focus');

    return $newItem;
  },

  goToChar($item, key) {
    const $itemUL = $item.closest('ul');
    const $menuItems = $itemUL.children('li').not('.separator');
    const menuNum = $menuItems.length;
    const menuIndex = $menuItems.index($item.closest('li'));
    let $newItem = null;

    // make sure that the received key is converted to lowercase (keydown)
    key = key.toLowerCase();

    // Set current index of the menu item to start search at
    let currIndex = menuIndex + 1;

    if (currIndex === menuNum) {
      currIndex = 0;
    }

    while (currIndex !== menuIndex) {
      const titleCharCode = $menuItems.eq(currIndex).children().first().text()
        .trim()
        .charAt(0)
        .toLowerCase();

      if (titleCharCode === key) {
        // There's a match! Set the newItem and break
        $newItem = $menuItems.eq(currIndex).children().first();
        $item.removeClass('menu-focus');
        return $newItem;
      }
      // No match, increment the counter
      currIndex++;
      // Counter at the end? Start from the beginning..
      if (currIndex === menuNum) {
        currIndex = 0;
      }
    }

    // If you made it this far, I have nothing for you..
    return $item;
  },

  resetDropdowns() {
    this.ui.$all.removeClass('menu-hover').removeClass('dd-open');
    this.ui.$dropdowns.attr('aria-hidden', true);

    return true;
  },

  verticalMenu() {
    return this.ui.$navbar.height() > this.ui.$navbar.width();
  },

  // Window Resize Handling
  resizeTimer: 0,

  handleResize() {
    const $body = $document.find('body');

    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }

    this.resizeTimer = setTimeout(() => {
      $body.removeClass('resizing');
    }, 500);

    $body.addClass('resizing');

    // Close the menu on resize to avoid cross-breakpoint conflicts
    if ($body.is('.glu-menu-open, .glu-flyout-menu-open')) {
      $body.removeClass('glu-menu-open glu-flyout-menu-open');
      this.$('nav.glu-menu').removeClass('open');
    }
  }
});

