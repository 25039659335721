var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rdc.Deposit.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":50}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data,"loc":{"start":{"line":14,"column":19},"end":{"line":14,"column":29}}}) : helper)))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-hook=\"getDepositHistoryItem\">\n    <div class=\"DepositHistory-details\">\n        <div>\n            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":38}}}))
    + "</p>\n            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"rdc.Deposited.On",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":44}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"DepositHistory-values\">\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"formattedCheckAmount") || (depth0 != null ? lookupProperty(depth0,"formattedCheckAmount") : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias1,{"name":"formattedCheckAmount","hash":{},"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":39}}}) : helper)))
    + "</p>\n            <p>"
    + alias2((lookupProperty(helpers,"formatCheckDate")||(depth0 && lookupProperty(depth0,"formatCheckDate"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"checkDate") : depth0),{"name":"formatCheckDate","hash":{},"data":data,"loc":{"start":{"line":12,"column":15},"end":{"line":12,"column":44}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"DepositHistory-chevron\">\n            <span class=\"icon-caret-right\"></span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});