import $ from 'jquery';
import http from '@glu/core/src/http';
import services from 'services';
import cache from 'system/utilities/cache';
import PageApi from 'common/dynamicPages/api/view';
import gridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import constants from 'app/payments/constants';

export default {
    /*
     * NH-26044: use this method to verify that all mandatory fields have been added to the
     * filter list
     * returns a list of missing fields or an empty array if all manadatory fields are found.
     */
    validateFilterList(filterFields, fieldList) {
        const missing = util.filter(fieldList, (field) => {
            if (field.mandatory) {
                return (util.pluck(filterFields, 'fieldName').indexOf(field.name) === -1);
            }
            return false;
        });

        return util.pluck(missing, 'fieldLabel');
    },

    getFilterData(requestData, cacheName) {
        return new Promise((resolve, reject) => {
            http.post(services.generateUrl('/advanceFilter/getFilterFields'), requestData, (dataParam) => {
                const data = dataParam;
                // change filterFieldInfoList to fieldInfoList
                data.fieldInfoList = data.filterFieldInfoList;
                delete data.filterFieldInfoList;
                data.fieldContainerList = data.filterFieldContainerInfoList;
                delete data.filterFieldContainerList;

                data.isBatch = false;
                data.isChild = false;
                cache.set(cacheName, data);
                resolve(data);
            }, (result) => {
                reject({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                });
            });
        });
    },

    /*
     * should be deprecated & users should use function above and use the return promise to
     * show results
     * view MUST have filterRegion
     */
    getFilters(requestData, view) {
        const promiseMe = new Promise((resolve, reject) => {
            http.post(services.generateUrl('/advanceFilter/getFilterFields'), requestData, (dataParam) => {
                const data = dataParam;
                data.fieldInfoList = data.filterFieldInfoList.map((element) => {
                    const item = element;
                    if (item.popupId) {
                        item.dynamicDateOptions = true;
                        item.payload = constants.DYNAMIC_DATE_OPTION_PAYLOAD;
                    }
                    return item;
                });

                // if a function is present to sort radio choices, call it.
                if (util.isFunction(view.radioChoicesSortComparator)) {
                    view.radioChoicesSortComparator(data.fieldInfoList);
                }

                delete data.filterFieldInfoList;
                data.fieldContainerList = data.filterFieldContainerInfoList;
                delete data.filterFieldContainerList;

                data.isBatch = false;
                data.isChild = false;
                cache.set(view.getCacheName(), data);
                resolve();
            }, (result) => {
                reject({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                });
            });
        });

        promiseMe.then(() => {
            if (view.filterRegion) {
                view.filterRegion.show(PageApi.page.get({
                    context: view.filterContextDef,
                    state: view.mode,
                    hideButtons: true,
                    gridApi,
                    datePickerOptions: view.datePickerOptions || false,
                    dependentTypeAheadFields: view.dependentTypeAheadFields,
                    staticDependentFields: view.staticDependentFields,
                    typeAheadData: view.typeAheadData,
                    comboBoxData: view.comboBoxData,
                    typeAheadFieldsOverride: view.typeAheadFieldsOverride,
                    conditionalFields: view.conditionalFieldsModel || false,
                    pastOnly: serverConfigParams.get('DatepickerEnablePastOnlyImport') === 'true',
                    currentAndPastOnly: view.currentAndPastOnly,

                }));
            }
        });
        return promiseMe;
    },

    gatherFilters(view) {
        // gather up the filters
        const filters = [];
        const filterModel = view.filterRegion.currentView.model;
        const fields = filterModel.jsonData.fieldInfoList;
        const self = this;

        const dateformat = userInfo.getDateFormat();

        util.each(filterModel.attributes, (value, key) => {
            let filter;
            let fld;
            let $op;
            let operator = 'LIKE';
            let fvalue;
            let ftype;
            if ($.isArray(value)) {
                fvalue = value;
            } else {
                fvalue = [value];
            }
            if (value) {
                // find the key in the fields list
                fld = util.findWhere(
                    fields,
                    {
                        name: key,
                    },
                );
                if (fld) {
                    if (fld.fieldUIType === 'DATEFILTER') {
                        // eslint-disable-next-line
                        const dateValues = filterModel._datePickerValues[key];
                        if (dateValues.length > 0) {
                            if (dateValues[0].isSame(dateValues[1], 'day')) {
                                operator = 'EQ';
                                fvalue = [dateValues[0].format(dateformat)];
                            } else {
                                operator = 'BETWEEN';
                                fvalue = `${dateValues[0].format(dateformat)}^${dateValues[1].format(dateformat)}`;
                            }
                            switch (fld.filterType) {
                            case 12:
                                ftype = 'gmtdate';
                                break;
                            case 49:
                                ftype = 'gmtdatetime';
                                break;
                            default:
                                ftype = 'date';
                                break;
                            }
                        }
                    } else if (fld.fieldUIType === 'RANGEFIELDWIDGET') {
                        const { rValue, rOperator } = this.getRangeFields(fld, fvalue, filterModel);
                        operator = rOperator;
                        fvalue = rValue;
                        ftype = this.getSearchType(fld.filterType, fld.fieldType);
                    } else {
                        $op = $(`#${key}-equality`);
                        fvalue = value;
                        ftype = fld.fieldType;
                        // does it have an operand/grid
                        if ($op.val()) {
                            operator = $op.val();
                            /*
                             * NH-144471 when the filter is BETWEEN,
                             * need to add the second value as well
                             */
                            const secondValue = filterModel.get(`${key}2`);
                            if (operator === 'BETWEEN' && secondValue) {
                                fvalue = `${fvalue}^${secondValue}`;
                            }
                        } else if (fld.fieldUIType === 'MULTIGRIDFILTER' || fld.fieldUIType === 'MULTICOMBOFILTER' || fld.fieldUIType === 'MULTICHECKFILTER') {
                            operator = 'IN';
                            let v = '';
                            /*
                             * Array.toString() produces a comma separated list which the
                             * filter parser will misunderstand. Separate values with ^.
                             */
                            if (value instanceof Array) {
                                util.each(value, (current) => {
                                    v += `${current}^`;
                                });
                                fvalue = v.replace(/\^$/, '');
                            }
                        } else if (fld.fieldUIType === 'SINGLEGRIDFILTER' || fld.fieldUIType === 'COMBOFILTER') {
                            operator = 'EQ';
                        }
                    }
                    // create the filter
                    filter = `${key}^${key}^${self.getFilterType(ftype)}^${operator}^${fvalue}`;
                    filters.push(filter);
                }
            }
        });
        return filters;
    },

    getSearchType(dataType, fieldType) {
        let localDataType = dataType;
        let searchType = 'text';
        localDataType += '';
        switch (localDataType) {
        case '1':
            searchType = 'text';
            break;
        case '0':
        case '20':
        case '22':
            searchType = 'amount';
            break;
        case '21':
            if (util.contains(['AMOUNT', 'WHOLENUMERIC'], fieldType)) {
                searchType = 'amount';
            }
            break;
        case '4':
        case '107':
            searchType = 'date';
            break;
        case '12':
            searchType = 'gmtdate';
            break;
        case '49':
            searchType = 'gmtdatetime';
            break;
        case '23':
            searchType = 'singlenumeric';
            break;
        default:
        }
        return searchType;
    },

    getFilterType(searchType) {
        const types = {
            amount: '0',
            AMOUNT: '0',
            number: '0',
            WHOLENUMERIC: '0',
            text: '1',
            date: '4',
            gmtdate: '12',
            gmtdatetime: '49',
        };

        const returnType = types[searchType];

        return returnType !== undefined ? returnType : '1';
    },

    gatherFilterData(filterModel, convertSearchFieldType) {
        let localConvertSearchFieldType = convertSearchFieldType;
        const searchFields = [];
        const fields = filterModel.jsonData.fieldInfoList;
        const dateformat = userInfo.getDateFormat();

        // Set to true if not passed. Otherwise use passed in value.
        if (typeof localConvertSearchFieldType === 'undefined') {
            localConvertSearchFieldType = true;
        }

        util.each(filterModel.attributes, (value, key) => {
            let searchField;
            let fld;
            let $op;
            let operator = 'LIKE';
            let fvalue;
            if ($.isArray(value)) {
                fvalue = value;
            } else {
                fvalue = [value];
            }
            if (value) {
                fld = util.findWhere(
                    fields,
                    {
                        name: key,
                    },
                );
                if (fld) {
                    const dataType = localConvertSearchFieldType
                        ? this.getSearchType(fld.filterType, fld.fieldType) : fld.filterType;

                    if (fld.fieldUIType === 'DATEFILTER') {
                        // eslint-disable-next-line
                        const dateValues = filterModel._datePickerValues[key];
                        if (dateValues.length > 0) {
                            if (dateValues[0].isSame(dateValues[1], 'day')) {
                                operator = 'EQ';
                                fvalue = [dateValues[0].format(dateformat)];
                            } else {
                                operator = 'BETWEEN';
                                fvalue = [
                                    dateValues[0].format(dateformat),
                                    dateValues[1].format(dateformat),
                                ];
                            }
                        }
                    } else if (fld.fieldUIType === 'RANGEFIELDWIDGET') {
                        const { rValue, rOperator } = this.getRangeFields(fld, fvalue, filterModel);
                        operator = rOperator;
                        fvalue = rValue;
                    } else {
                        $op = $(`#${key}-equality`);
                        /*
                         * used value set in model or defaulted input value
                         * (in case different entry styles get used)
                         */
                        const opVal = filterModel.get(`${key}-equality`) || $op.val();
                        if ($op.val()) {
                            operator = opVal;
                        } else if (fld.fieldUIType === 'MULTIGRIDFILTER' || fld.fieldUIType === 'MULTICOMBOFILTER' || fld.fieldUIType === 'MULTICHECKFILTER') {
                            operator = 'IN';
                            fvalue = util.uniq(fvalue);
                        } else if (fld.fieldUIType === 'SINGLEGRIDFILTER' || fld.fieldUIType === 'COMBOFILTER') {
                            operator = 'EQ';
                        } else if (fld.fieldUIType === 'COMBOFILTERLT') {
                            operator = 'LTEQ';
                        }

                        if (operator === 'BETWEEN') {
                            fvalue = [value, $(`#${key}2`).val()];
                        }
                    }
                    searchField = {
                        operator,
                        dataType,
                        fieldName: key,
                        fieldValue: fvalue,
                    };
                    searchFields.push(searchField);
                }
            }
        });
        return searchFields;
    },

    /**
     * Return the template data entitlement attribute
     * @param {String} [field] - field info object.
     * @param {String} [fvalue] - value of the from field.
     * @param {String} [filterModel] - model containing all the fields.
     * @return {object} [fieldValue, operator]
     */
    getRangeFields(field, fvalue, filterModel) {
        /*
         * The range widget displays a from field, to allow
         * for a range, when the range button is clicked
         * The widget then has a to/from fields to complete
         * a range.  The related proerty stores both fields
         * and renders the second one for the to field.  Therefore
         * The second field must be saved in the array of values
         * and the operator should be set to BETWEEN.  If range
         * is not selected, only the from field is available.
         */
        let operator = 'EQ';
        const fieldValue = fvalue;
        if (!util.isEmpty(field.relatedProperty)) {
            const toFromFields = field.relatedProperty.split(',');
            if (toFromFields.length > 1) {
                // Only populate between if from field is populated.
                const fromField = toFromFields[1].toUpperCase();
                const fromFieldValue = filterModel.get(fromField);
                if (!util.isEmpty(fromFieldValue)) {
                    operator = 'BETWEEN';
                    fieldValue.push(fromFieldValue);
                }
            }
        }

        return {
            rValue: fieldValue,
            rOperator: operator,
        };
    },

    filtersToPairs(profileFilters) {
        const result = {};
        util.each(profileFilters, (filter) => {
            const parts = filter.paramValue.split('^');
            const [,,, operator] = parts;
            if (operator !== 'IN' && operator !== 'BETWEEN') {
            // TODO: Deep destructuring like this kind of sucks
                [, , , , result[parts[0]]] = parts;
            } else {
                const values = [...parts.slice(4)];
                result[parts[0]] = values;
            }
            result[`${parts[0]}-equality`] = operator;
        });
        return result;
    },
};
