var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"#\" data-action=\"viewTransactionDetails\">\n    <strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.ViewTransactionDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":51}}}))
    + "</strong>\n</a>\n\n<div class=\"summary-wrapper\">\n    <div class=\"summary-heading\">\n        <h4 class=\"summary-title\">\n            Summary for\n            <span class=\"highlight\">\n                <span data-hook=\"summary-num-lockboxes\"></span>\n                    in\n                <span data-hook=\"summary-num-locations\"></span>\n            </span>\n        </h4>\n\n        <div class=\"summary-title-summary\">\n\n            <div data-section=\"lockbox-summary\" class=\"amount-wrapper\">\n                <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.TotalItems",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":45},"end":{"line":19,"column":72}}}))
    + "</p>\n                <span data-hook=\"summary-num-payments\" class=\"amount-value-text\"></span>\n            </div>\n\n            <div data-section=\"lockbox-summary\" class=\"amount-wrapper\">\n                <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.TotalAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":45},"end":{"line":24,"column":73}}}))
    + "</p>\n                <span data-hook=\"summary-total-amount\" class=\"amount-value-text\"></span>\n            </div>\n        </div>\n\n    </div>\n\n    <div class=\"btn-wrapper\">\n        "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":51}}})) != null ? stack1 : "")
    + "\n    </div>\n\n    <div class=\"summary-body\">\n        <div class=\"summary-region lockbox-summary-region\" data-region=\"summaryRegion\"></div>\n    </div>\n</div>\n\n<div class=\"lockbox-view-details\" data-region=\"transactionDetailsViewBtnContent\"></div>\n";
},"useData":true});