import locale from '@glu/locale';

function getRelevantOptions(options, totalItems) {
  const reducedOptions = options.filter((option) => (option < totalItems));
  return [...reducedOptions, totalItems];
}

function convertName(options) {
  const [last, ...others] = options.reverse();
  const convervted = others.reverse()
    .map((number) => ({
      name: number,
      value: number
    }));
  return [...convervted, { name: locale.get('dataComponents.all'), value: last }];
}

export function updateOptions({
  options,
  originalPageSize,
  totalItems
}) {
  if (options.includes(originalPageSize) || !Number(originalPageSize)) {
    return convertName(
      getRelevantOptions(
        options, totalItems
      )
    );
  }
  const updated = [...options, Number(originalPageSize)].sort((a, b) => a - b);
  return convertName(
    getRelevantOptions(
      updated, totalItems
    )
  );
}

export default updateOptions;
