import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import store from 'system/utilities/cache';
import $ from 'jquery';
import ContextApi from 'common/dynamicPages/api/context';
import services from 'services';
import http from '@glu/core/src/http';
import viewTmpl from './view.hbs';

export default Layout.extend({
    template: viewTmpl,

    initialize(options) {
        this.segmentName = store.get('segmentName');
        this.externalWidgetModels = options.externalWidgets;
        this.contextDef = ContextApi.menuContext.getContext('SEGMNT_MAINT');
        this.isEditMode = store.get('segmentModify') || store.get('segmentInsert');
    },

    ui: {
        $externalWidgetLock: '.externalwidget-locklabel',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click @ui.$externalWidgetLock': 'handleLock',
    },

    onShow() {
        const self = this;
        this.externalWidgetModels.each((model) => {
            const widget = model.get('externalWidgetModel');
            const { widgetId } = widget;
            const check = `#${widgetId}-check`;
            const locked = `#${widgetId}-locked`;
            const workspace = `#${widgetId}-workspace`;

            if (model.get('marketSegment') === self.segmentName) {
                $(check).prop('checked', true);
            }

            if (model.get('locked') === true) {
                $(locked).prop('checked', true);
            }
            // NH-136420 - select the workspace even if it is a PLACEHOLDER workspace.
            $(workspace).val(model.get('workspaceId')).prop('selected', true);
        });

        this.ui.$navLinks.on('click', (e) => {
            e.preventDefault();
            const path = this.$(e.target).attr('href');
            this.navigateTo(path);
        });
    },

    templateHelpers() {
        return {
            externalWidgets: this.externalWidgetModels.toJSON(),
            isEditMode: this.isEditMode,
        };
    },

    save() {
        const self = this;
        const deploy = new Collection();

        this.externalWidgetModels.each((model) => {
            const widget = model.get('externalWidgetModel');
            const { widgetId, widgetName } = widget;
            const check = `#${widgetId}-check`;

            // Check if widget is supposed to be locked
            const locked = $(`#${widgetId}-locked`).is(':checked');

            // Find in which workspace should the widget be deployed to
            const workspace = $(`#${widgetId}-workspace`).val();

            const deploymentModel = new Model();
            deploymentModel.set('marketSegment', self.segmentName);
            deploymentModel.set('workspaceId', workspace);
            deploymentModel.set('locked', locked);

            const widgetModel = new Model();
            widgetModel.set('widgetId', widgetId);
            widgetModel.set('widgetName', widgetName);
            deploymentModel.set('externalWidgetModel', widgetModel);

            if ($(check).is(':checked')) {
                deploymentModel.set('deploy', true);
            } else {
                deploymentModel.set('deploy', false);
            }

            deploy.add(deploymentModel);
        });

        http.post(
            services.generateUrl('/cxp/externalWidget/deploy'),
            {
                externalWidgetDeploymentModel: deploy.toJSON(),
            },
            () => {
                self.navigateTo('/SEGMENTATION/viewSegments');
            },
        );
    },

    handleLock(e) {
        const widgetId = $(e.target).attr('for').replace('-locked', '');
        const lock = `#${widgetId}-locked`;
        const workspace = `#${widgetId}-workspace`;
        // The switch flips on/off
        if (!$(lock).is(':checked')) {
            // Set the workspace to home
            if (this.segmentName === 'SMB') {
                $(workspace).val('SMBHOME').prop('selected', true);
            } else {
                $(workspace).val('HOME').prop('selected', true);
            }
        } else {
            // Set workspace to none (PLACEHOLDER)
            $(workspace).val('').prop('selected', true);
        }
    },

    cancel() {
        store.unset(
            'segmentName',
            {
                silent: true,
            },
        );
        store.unset(
            'segmentModify',
            {
                silent: true,
            },
        );
        this.navigateTo('/SEGMENTATION/viewSegments');
    },
});
