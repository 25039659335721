import Population from 'app/challengeManager/collections/population';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';
import UserModel from 'app/challengeManager/models/users';

const UsersInquiry = Population.extend({
    comparator: 'primaryKey',
    model: UserModel,

    sync(method, model, options) {
        if (method === 'read') {
            return http.post(
                services.generateUrl('/inquiry/getData'),
                {
                    requestHeader: {},

                    inquiryRequest: {
                        searchCriteria: {
                            inquiryId: 22423,
                            searchType: '5',

                            customFilters: [{
                                filterName: 'Depends',
                                filterParam: ['CRITERIA_NAME', this.cname],
                            }],
                        },
                    },
                },
                (result) => {
                    options.success(result.inquiryResponse);
                },
                (result) => {
                    options.error(result);
                },
            );
        }
        return undefined;
    },

    parse(response) {
        return response.rows.map((row) => {
            const user = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            user.USERGROUP = user.COMPANY_ID;
            user.USERID = user.USER_ID;
            user.primaryKey = `${user.COMPANY_ID}-${user.USER_ID}`;
            user.totalRows = response.totalRows;
            user.totalPages = response.totalPages;
            user.type = 'USERS';

            return user;
        });
    },
});

export default UsersInquiry;
