import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import transform from 'common/util/transform';
import ModelParameterParent from './loanModifyParent';

export default ModelParameterParent.extend({
    initialize() {
        this.set(this.parse(this.parameters));
        this.validators = {
            DEBIT_CURRENCY: {
                description: locale.get('loans.account'),
                exists: true,
                otherDescription: locale.get('RTGS.*.credit_currency'),
                sameValue: 'CREDIT_CURRENCY',
            },

            CREDIT_CURRENCY: {
                description: locale.get('loans.account'),
                exists: true,
                otherDescription: locale.get('RTGS.*.debit_currency'),
                sameValue: 'DEBIT_CURRENCY',
            },

            CREDIT_AMOUNT: {
                description: locale.get('loans.credit.amount'),
                matches: validatorPatterns.PAMOUNT_PATTERN,
                maxLength: 13,
            },

            SELECTION_0: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_1: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_2: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_3: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_4: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            STANDARD_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            PRINCIPAL_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            INTEREST_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            ESCROW_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 16,
            },

            OTHER_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 16,
            },

            SPECIAL_INSTRUCTIONS: {
                description: locale.get('ACH.Comments'),
                maxLength: 140,
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },

            DEBIT_ACCOUNT_NUMBER: {
                description: locale.get('loans.account'),
                exists: true,
            },

            BENE_ACCOUNT: {
                description: locale.get('loans.account'),
                exists: true,
            },
            CUSTOMER_REFERENCE: {
                description: locale.get('loans.customerref'),
                maxLength: 16,
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },
        };
    },

    defaults: {
        DEBIT_CURRENCY: '',
        SPECIAL_INSTRUCTIONS: '',
        CREDIT_CURRENCY: '',
        CREDIT_AMOUNT: '',
        VALUE_DATE: '',
        AMOUNT_0: '',
        AMOUNT_1: '',
        AMOUNT_2: '',
        AMOUNT_3: '',
        AMOUNT_4: '',
    },

    parse(mapDataList) {
        const parsedData = {};
        for (let x = 0; x < mapDataList.length; x += 1) {
            parsedData[mapDataList[x].toField.toUpperCase()] = mapDataList[x].value;
        }
        return parsedData;
    },

    sync(method, modelParam, options) {
        const model = modelParam;
        const service = this.getServiceName(model, method);
        let loanData;

        if (method === 'update') {
            loanData = this.convertModelAttributesToServerJSON(model);

            http.post(service, loanData, (response) => {
                options.success(response);
            }, (response) => {
                model.error = JSON.parse(response.responseText);
                options.error(model, {});
            });
        }

        if (method === 'create') {
            loanData = this.convertModelAttributesToServerJSON(model);

            http.post(service, loanData, (response) => {
                options.success(response);
            }, (response) => {
                model.error = JSON.parse(response.responseText);
                options.error(model, {});
            });
        }
    },

    getServiceName(model, method) {
        const type = model.get('paymentType');
        let url;

        if (model.get('TEMPLATE_TYPE') || model.isCopy) {
            if (type === 'LOANDRAW') {
                url = method === 'update' ? services.updateLoanDrawTemplate : services.addLoanDrawTemplate;
                if (model.get('overloadedMethodName') === 'restore') {
                    url = services.restoreLoanDrawTemplate;
                }
            }
            if (type === 'LOANPAY') {
                url = method === 'update' ? services.updateLoanPaymentTemplate : services.addLoanPaymentTemplate;
                if (model.get('overloadedMethodName') === 'restore') {
                    url = services.restoreLoanPaymentTemplate;
                }
            }
            return url;
        }
        if (type === 'LOANDRAW') {
            return services.loanDrawUpdate;
        }
        if (type === 'LOANPAY') {
            return services.loanPaymentUpdate;
        }
        return undefined;
    },

    convertModelAttributesToServerJSON(model) {
        let jsonData = [];
        const attribute = model.toJSON();

        // convert CREDIT_AMOUNT to DEBIT_AMOUNT
        attribute.DEBIT_AMOUNT = model.get('CREDIT_AMOUNT');

        jsonData = transform.hashToPairs(attribute, 'name', 'value');

        if (model.get('TEMPLATE_TYPE') || (attribute.TEMPLATE_DESCRIPTION && attribute.TEMPLATE_CODE)) {
            jsonData.push({
                name: 'FUNCTIONCODE',
                value: 'TMPL',
            });
        }
        if (model.schedModel) {
            jsonData = jsonData.concat(transform.convertScheduleModelToNVP(model.schedModel));
        }
        return {
            item: jsonData,
        };
    },

    getBusinessDays(data) {
        const self = this;
        http.post(services.sbDates, data, (response) => {
            self.trigger('retrieved', response);
        }, () => {
            // error
        });
    },

    parameters: ModelParameterParent.prototype.createParameters(),
});
