import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import services from 'services';
import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import constants from 'common/dynamicPages/api/constants';
import singleLookupHelper from 'common/dynamicPages/views/mdf/componentHelpers/singleLookup';
// import { triggerMaskedInputChanged } from 'common/dynamicPages/views/mdf/mdfUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import PaymentUtil from 'common/util/paymentUtil';
import userInfo from 'etc/userInfo';
import accountBalanceHelpTextLoader from './accountBalanceHelpTextLoader';
import comboboxWidgetTmpl from './comboboxWidget.hbs';

export default BaseWidget.extend({
    template: comboboxWidgetTmpl,

    className() {
        let cssClass = 'ui-widget combobox-widget field-container';
        const { fieldInfo } = this.options;

        if (fieldInfo.mandatory && !this.isReadOnly(this.fieldName, this.model)) {
            cssClass += ' required';
        }

        if (fieldInfo.blockClass && !this.options.isSubWidget) {
            cssClass += (` ${fieldInfo.blockClass}`);
        }

        if (fieldInfo.relatedProperty) {
            cssClass += ' lookup-container';
        }

        if (this.isReadOnly(this.fieldName, this.model)) {
            cssClass += ' text-group read-only-group';
        }

        return cssClass;
    },

    ui: {
        select: 'select',
    },

    events: {
        'change @ui.select': 'onChange',
    },

    behaviors() {
        return {
            LookupHelperText: {
                getTemplateInput: this.getHelperTextTemplateInput.bind(this),
                fieldName: this.fieldName,
                hideOnEmptyTemplateInput: true,
            },
        };
    },

    initialize(options) {
        BaseWidget.prototype.initialize.call(this, options);

        this.comboData = [];
        this.comboCollections = options.comboCollections;

        // Do not show the balances for Templates
        if (this.shouldShowHelperText()) {
            this.initHelperTextLoader();
        }
        // PCM-3013
        this.readyState = this.loadComboData().then(this.onDataLoadComplete.bind(this));

        this.readyState.then(this.loadHelpTextData()
            .then(this.onHelperTextLoadComplete.bind(this)))
            .then(() => {
                this.listenTo(this.parentModel, `change:${this.fieldName}`, this.onParentModelChange);
                this.render();
            });

        // TODO this is a hack to allow the singleLookup.js lookup functionality to
        // work with this widget.
        this.initializeOptions = options.parentInitializeOptions;
    },

    onBeforeRender() {
        // ensure cleaned from prior renders
        this.cleanupSelect2Combobox();
    },

    onRender() {
        this.initSelect2Combobox();
        singleLookupHelper.setupSingleLookups(this);
    },

    // templateHelpers is frequently used in some lines of business but unless they are
    // true handlebars helpers its kind of a misnomer.
    // If all you are doing is enriching model data or setting some data then serializeData
    // is the appropriate place for this, not templateHelpers.
    serializeData() {
        const lockedByDefault = this.isLockedByDefault(
            this.fieldName,
            this.fieldInfo.lockedByDefault,
            this.model,
        );
        const locked = this.isLocked(this.fieldName, this.model, lockedByDefault);
        return {
            fieldData: this.fieldData,
            fieldInfo: this.fieldInfo,
            fieldName: this.fieldName,
            fieldId: util.uniqueId(this.fieldInfo.name),
            fieldLabel: this.fieldInfo.fieldLabel,
            comboData: this.comboData,
            info: this.fieldData.info,
            relatedProperty: this.fieldInfo.relatedProperty,
            cssClass: this.fieldInfo.cssClass,
            currentSelection: this.currentSelection,
            lockable: this.fieldInfo.lockable,
            lockedByDefault,
            locked,
            unlocked: this.isUnlocked(this.fieldName, this.model, locked),
            isTemplate: this.isTemplate(this.model),
            isReadOnly: this.isReadOnly(this.fieldName, this.model),
            state: (this.initializeOptions && this.initializeOptions.state) || {},
        };
    },

    isLocked(fieldName, model, defaultLocked) {
        if (!model) {
            return false;
        }
        const lockedFields = model.get('LOCKED_FIELDS');
        const unlockedFields = model.get('UNLOCKED_FIELDS');
        let isLockedByDefault = defaultLocked;
        if (!isLockedByDefault && model.fieldData && model.fieldData[fieldName]) {
            isLockedByDefault = model.fieldData[fieldName].lockedByDefault;
        }
        if (lockedFields && lockedFields.includes(fieldName)) {
            return true;
        } else if (unlockedFields && unlockedFields.includes(fieldName)) {
            return false;
        } else if (isLockedByDefault && !unlockedFields && !(model.get('TNUM') || model.get('TARGETFUNCTION'))) {
            return true;
        }
        return false;
    },

    /**
     * Return if unlocked
     * @param {String} fieldName
     * @param {Model} model
     * @param {boolean} true/false
     * @returns {boolean} true/false
     */
    isUnlocked(fieldName, model, locked) {
        if (!model || locked) {
            return false;
        }
        const unlockedFields = model.get('UNLOCKED_FIELDS');
        if (unlockedFields && unlockedFields.includes(fieldName)) {
            return true;
        }
        return false;
    },

    /**
      * Return if lockedByDefault
      * @param {String} fieldName
      * @param {boolean} isLockedByDefault
      * @param {Model} model
      * @returns {boolean} true/false
      */
    isLockedByDefault(fieldName, isLockedByDefault, model) {
        const lockedFields = model.get('LOCKED_FIELDS');
        // if field is not defined as a locked field, and we have a tnum, then this
        // field was unlocked
        if ((!lockedFields || (lockedFields && !lockedFields.includes(fieldName))) && (model.get('TNUM') || model.get('TARGETFUNCTION'))) {
            // dont lock by default if purposely unlocked
            return false;
        }
        return isLockedByDefault;
    },

    /**
     * Return if isTemplate or not
     * @param {Model} model
     * @returns {boolean} true/false
     */
    isTemplate(model) {
        if (model === undefined) {
            return false;
        }
        const targetFunction = model.get('TARGETFUNCTION');
        const functionCode = model.context.functionCode ||
            (model.context.actionContext && model.context.actionContext.functionCode) ||
            (model.context.actionData && model.context.actionData.functionCode);
        if (targetFunction && (targetFunction === 'TMPL' || targetFunction === 'BHTMPL')) {
            return true;
        }
        return targetFunction === undefined && (functionCode === 'TMPL' || functionCode === 'BHTMPL');
    },

    /**
     * Evaluate whether or not to show helper text
     * @returns {boolean}
     */
    shouldShowHelperText() {
        try {
            const configParamName = (this.options.parentInitializeOptions.context.productCode === 'USACH') ? 'DisplayAccountBalanceOnACHScreens' : 'DisplayAccountBalanceOnPaymentsScreens';

            if (!this.isTemplate(this.model)) {
                return serverConfigParams.get(configParamName);
            }
            return false;
        } catch (e) {
            return false;
        }
    },

    initHelperTextLoader() {
        // TODO might want to replace this with a more elegant solution
        const loaders = {
            ACCOUNTFILTER: accountBalanceHelpTextLoader,
            COMPIDNAME: accountBalanceHelpTextLoader,
        };

        this.loadHelpTextData = loaders[this.fieldName] || this.loadHelpTextData;
    },

    /**
     * Return if readOnly
     * @param {String} fieldName
     * @param {Model} model
     * @returns {boolean} true/false
     */
    isReadOnly(fieldName, model) {
        let isProtected = this.isReadOnlyProp(this.fieldName) || this.state === 'VIEW' || this.fieldInfo.protected;
        // this can be over-written by if lockable and locked or not locked
        if (this.isLocked(fieldName, model) &&
            this.fieldInfo.lockable &&
            !this.isTemplate(model)) {
            isProtected = true;
        } else if (this.state !== 'VIEW' && this.fieldInfo.lockable) {
            isProtected = false;
        }
        return isProtected;
    },

    isReadyToRender() {
        return this.readyState;
    },

    loadComboData() {
        // var parentModel = options.parentModel;
        // TODO this I don't completely understand how it will work as its not
        // the parent model for this widget its the parent model for the mdf view
        // that currently holds this widget I think
        const { context } = this.parentModel;
        const dependsOnList = this.fieldData.dependsOn;
        const subTypeStr = context.subType || '*';
        const entryClass = context.actionData ? context.actionData.entryClass : '';
        const actionMode = context.actionMode ? context.actionMode.toUpperCase() : 'SELECT';
        let postdata;
        // for SMB, the context has an inquiry id but it should not be used.
        if (context.inquiryId && !userInfo.isSmbUser()) {
            postdata = {
                IncludeMapData: 1,

                queryCriteria: {
                    action: {
                        typeCode: context.typeCode,
                        entryMethod: '0',
                        productCode: context.productCode,
                        actionMode,
                        functionCode: context.functionCode,
                    },

                    inquiryId: context.inquiryId,
                },

                requestHeader: {
                    queryPagesize: 200,
                },
            };
        } else {
            postdata = {
                queryCriteria: {
                    action: {
                        productCode: this.parentModel.jsonData.typeInfo.productCode,
                        functionCode: this.parentModel.jsonData.typeInfo.functionCode,
                        typeCode: this.parentModel.jsonData.typeInfo.typeCode,
                        actionMode,
                    },

                    subTypeCode: subTypeStr,
                    fieldName: this.fieldName,
                    entryClass,
                },
            };
        }

        if (context.queryType) {
            postdata.queryCriteria.queryType = context.queryType;
        }

        // add templateTnum to request, if it is available
        if (this.parentModel.get('TEMPLATETNUM')) {
            postdata.queryCriteria.templateTnum = this.parentModel.get('TEMPLATETNUM');
        } else if (this.parentView && this.parentView.context && this.parentView.context.actionMode === 'INSERT' &&
            this.parentView.context.copySource === 'template' && this.parentView.context.createdFrom === '1' &&
            this.parentView.context.functionCode === 'TMPL') {
            postdata.queryCriteria.templateTnum = this.parentView.context.tnum;
        }

        if (this.parentModel.get('TNUM')) {
            postdata.queryCriteria.tnum = this.parentModel.get('TNUM');
        }

        // add the depends fields as customFilters
        if (dependsOnList !== null) {
            postdata.queryCriteria.customFilters = [];

            for (let i = 0; i < dependsOnList.length; i += 1) {
                const customFilter = {
                    filterName: 'Depends',
                    filterParam: [],
                };
                customFilter.filterParam.push(dependsOnList[i]);

                const fieldMeta = this.parentModel.fieldData[dependsOnList[i]];
                let tempVal = this.parentModel.get(dependsOnList[i]);

                // for checkboxes the model has a false instead of Off Value.
                if (fieldMeta && fieldMeta.fieldUIType === 'CHECKBOX') {
                    // if the value does not match the on value, then use the off value.
                    if (tempVal !== fieldMeta.checkboxOnValue) {
                        tempVal = fieldMeta.checkboxOffValue;
                    }
                }
                customFilter.filterParam.push(tempVal);

                postdata.queryCriteria.customFilters.push(customFilter);
            }
        }

        postdata.queryCriteria.allowDuplicates = this.fieldData.comboAllowDuplicates;

        if (this.parentModel.staticDependsOn &&
            this.parentModel.staticDependsOn[this.fieldName]) {
            postdata.queryCriteria.customFilters =
                this.parentModel.staticDependsOn[this.fieldName];
        }

        const serviceUrl = this.createServiceUrl(context.serviceName, this.parentModel.isChild);

        // TODO is this still valid, not loading data for a combobox?
        if (!serviceUrl) {
            // handle the fact that sometimes we don't get a url and so don't load data
            return Promise.resolve(true);
        }

        return new Promise((resolve, reject) => {
            http.post(serviceUrl, postdata, (data) => {
                resolve(data);
            }, () => {
                reject(locale.get('common.combo.data.error', this.fieldName));
            });
        });
    },

    createServiceUrl(serviceName, isChild) {
        /* For Admin Payments the service urls are different
         * so changing the urls to process for admin payments and templates.
         * TODO remove these checks and pass comboService as an option
         * to the view
         */
        const isAdminPay = serviceName.indexOf('adminPayment/listView') > -1;
        const isAdminTemp = serviceName.indexOf('adminTemplate/listView') > -1;
        const isAdminCm = serviceName.indexOf('adminCM/cminst') > -1;
        const isAdminStopDetail = serviceName.indexOf('adminCM/cm') > -1;
        let serviceUrl;

        if ((isAdminPay || isAdminTemp || isAdminCm) && isChild) {
            return undefined;
        }

        if (isAdminPay) {
            serviceUrl = services.generateUrl('adminPayment/listView/payments/getQueryResults');
        } else if (isAdminTemp) {
            serviceUrl = services.generateUrl('adminTemplate/listView/templates/getQueryResults');
        } else if (isAdminCm) {
            serviceUrl = services.generateUrl('adminCM/cm/issueVoids/getQueryResults');
        } else if (isAdminStopDetail) {
            serviceUrl = services.generateUrl('adminCM/cm/stopCancels/getQueryResults');
        } else if (this.options.parentInitializeOptions &&
            this.options.parentInitializeOptions.comboService) {
            serviceUrl = services.generateUrl(this.options
                .parentInitializeOptions.comboService);
        } else {
            serviceUrl = services.generateUrl(constants.URL_GETQUERYRESULTS_ACTION);
        }

        return serviceUrl;
    },

    loadHelpTextData() {
        // default implementation does nothing, must be implemented per field to get
        // needed data and store it in the this.helperTextData array
        // each entry in the array must match the corresponding entry in this.comboData where
        // each entry there matches an entry in the combobox itself
        return Promise.resolve();
    },

    setCurrentSelectionByName(name) {
        [this.currentSelection] = this.comboData.filter(item => item.name === name);

        this.currentSelectionIndex = this.currentSelection ?
            this.comboData.indexOf(this.currentSelection) : undefined;
    },

    onParentModelChange(model, value, options) {
        if (options.selectIfOnlyOne) {
            return;
        }
        if (!this.currentSelection || value !== this.currentSelection.name) {
            this.setCurrentSelectionByName(value);
            this.render();
        }
    },

    onDataLoadComplete(data) {
        let comboData = data && data.queryResponse.QueryData.queryRows;
        if (comboData && !util.isEmpty(comboData)) {
            comboData = this.massageComboData(comboData);
        }
        if (!this.isClosed && comboData) {
            this.comboData = comboData;
            if (this.isReadOnly(this.fieldName, this.model)) {
                this.comboCollections[this.fieldName] = comboData;
                this.setCurrentSelectionByName(this.parentModel.get(this.fieldName));
            }
        }
    },

    /**
     * Convert any escaped char like & ,<,> ,'   (single quotes) by
     * calling serverDataUnescape
     * @param data
     * @returns {object} massaged response data
     */
    massageComboData(data) {
        data.forEach((d) => {
            const dupD = d; // assign to a different variable b/c of eslint
            dupD.name = this.serverDataUnescape(d.name);
            dupD.label = this.serverDataUnescape(d.label);
            dupD.mapDataList.forEach((md) => {
                const dupMD = md;
                dupMD.value = this.serverDataUnescape(md.value);
            });
        });
        return data;
    },

    /**
     * if the input value has any escaped like & (&amp;) , single quotes (&apos;)
     * these values will be unescaped to respectively characters & , single quotes
     * @param value
     */
    serverDataUnescape(value) {
        return util.unescape(value);
    },

    onHelperTextLoadComplete(data) {
        this.setCurrentSelectionByName(this.parentModel.get(this.fieldName));
        if (data) {
            this.helperTextData = data.helperTextData;
        }

        if (this.currentSelection) {
            this.showHelperText();
        }
    },

    showHelperText() {
        this.trigger('lookupHelperText:clear');
        if (!this.hasCurrBalance()) {
            this.loadHelpTextData().then(() => {
                this.trigger('lookupHelperText:update');
            });
        } else {
            this.trigger('lookupHelperText:update');
        }
    },

    getHelperTextTemplateInput() {
        if (!this.hasHelperText()) {
            return undefined;
        }

        return this.helperTextData[this.currentSelectionIndex];
    },

    hasCurrBalance() {
        return (this.currentSelection !== undefined &&
            this.currentSelection.hasBalance !== undefined &&
            this.currentSelection.hasBalance);
    },

    hasHelperText() {
        return (this.helperTextData &&
            (this.currentSelectionIndex || this.currentSelectionIndex === 0) &&
            this.helperTextData[this.currentSelectionIndex]);
    },

    onChange(e) {
        if (this.comboData && this.comboData.length > 0) {
            let mapDataList;
            const item = {};

            this.setCurrentSelectionByName(e.currentTarget.value);

            if (this.currentSelection) {
                ({ mapDataList } = this.currentSelection);
                if (mapDataList) {
                    // For now, assume that the list is coming in order
                    // convert toField to uppercase b/c of fields on client side are
                    // upper case
                    // mapdata fields may not be set up properly
                    mapDataList.forEach((mapData) => {
                        item[mapData.toField.toUpperCase()] = mapData.value;
                    });
                    item.ORIGINATOR_FULL_NAME = item.ORIGINATOR_FULL_NAME || item.ORIGCOMPNAME;
                    if (this.initializeOptions.reimburse) {
                        const entryDesc = util.findWhere(
                            mapDataList,
                            {
                                toField: 'ENTRYDESC',
                            },
                        );
                        entryDesc.value = 'REIMBURSE';

                        const batchDesc = util.findWhere(
                            mapDataList,
                            {
                                toField: 'BATCHTYPEDESC',
                            },
                        );
                        batchDesc.value = 'REIMBURSE';
                    } else if (!util.isNullOrUndefined(this.model)) {
                        if (!util.isNullOrUndefined(this.model.get('UE_ENTRYDESC'))) {
                            // HACK  in order to set the value of ENTRYDESC
                            if (this.model.get('UE_ENTRYDESC').length > 0) {
                                item.ENTRYDESC = this.model.get('UE_ENTRYDESC');
                            }
                        }

                        // The is-not-null-or-undefined test ensures we do not do any of
                        // this on models that do
                        // possess COMPDISCDATA or UE_COMPDISCKDATA members, e.g., wires.
                        if (!util.isNullOrUndefined(this.model.get('UE_COMPDISCDATA'))) {
                            // HACK  Ensures we display the correct value in the page.
                            if (this.model.get('UE_COMPDISCDATA').length > 0) {
                                item.COMPDISCDATA = this.model.get('UE_COMPDISCDATA');
                            }
                        }
                        if (!util.isNullOrUndefined(this.model.get('COMPDISCDATA'))) {
                            // HACK  If we have neither a COMPDISCDATA value nor one
                            // for UE_COMPDISCDATA, default to that configured for
                            // the ACH company.
                            if (!this.model.get('COMPDISCDATA').length > 0) {
                                this.model.set('COMPDISCDATA', item.COMPDISCDATA);
                            }
                        }
                    }
                }

                item[this.fieldName.toUpperCase()] = this.currentSelection.name;
            } else {
                // need to clear map data list
                // grab the first item in the collection
                [this.currentSelection] = this.comboData;
                // get its mapDataList
                mapDataList = this.currentSelection ?
                    this.currentSelection.mapDataList : null;
                // clear out the toFields
                if (mapDataList) {
                    mapDataList.forEach((mapData) => {
                        item[mapData.toField.toUpperCase()] = '';
                    });
                }

                item[this.fieldName.toUpperCase()] = '';
            }

            this.parentModel.set(item);

            if (e.val) {
                this.showHelperText();
            }
        }
    },

    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e  Jquery click handler event
     */
    toggleLock(e) {
        // can not toggle in view mode
        if (this.state === 'VIEW') {
            return;
        }
        // the field is being unlocked
        if (e.currentTarget.classList.contains('icon-lock')) {
            /*
             * HACK: NH-111194
             * Currently, due to the existing implementation of lock icons
             * (see `etc/partials/lockicon.hbs`)
             * lock icons that are "lockedByDefault", then toggled, then re-rendered
             * do not accurately reflect their toggled state. They revert to being locked.
             * as such, individual widgets or fields that rely on re-rendering themselves
             * MUST directly change the fieldInfo.lockedByDefault attribute in order to retain
             * the fields locked status.
             */
            this.fieldInfo.lockedByDefault = false;
        }
        PaymentUtil.toggleLock(e, this.model);
    },

    onBeforeClose() {
        this.cleanupSelect2Combobox();
    },

    cleanupSelect2Combobox() {
        if (this.ui.select && this.ui.select.comboBox) {
            this.ui.select.comboBox('destroy');
        }
    },

    initSelect2Combobox() {
        this.ui.select.comboBox({
            dropdownAutoWidth: 'true',
            placeholder: this.fieldData.placeHolder,
            allowClear: true,
        });
    },
});
