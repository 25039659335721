import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import scroll from 'common/util/scroll';
import AddLockboxModel from 'app/lockbox/models/lockbox';
import addLockboxTmpl from './addLockbox.hbs';

export default Layout.extend({
    template: addLockboxTmpl,
    modalClass: 'modal-xlg',

    initialize(options) {
        this.location = options.location;
        this.action = 'INSERT';
        if (this.location) {
            this.model = new AddLockboxModel({
                LOCATION_ID: this.location.LOCATION_ID,
                LOCATION_CODE: this.location.LOCATION_CODE,
                LOCATION_DESC: this.location.LOCATION_DESC,
            });
            this.dialogTitle = `${locale.get('LBX.LockboxModalTitle')} ${this.location.title}`;
        } else {
            this.action = 'UPDATE';
            this.model = new AddLockboxModel(options.model);
            this.dialogTitle = `${locale.get('LBX.LockboxModify')} ${this.model.get('NAME')} (${this.model.get('LOCKBOX_CODE')})`;
        }
        this.viewState = options.state || null;
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
        this.totalAdded = [];
        this.dialogButtons = [{
            text: this.action === 'INSERT' ? locale.get('LBX.AddToLocation') : locale.get('LBX.ModifyLockbox'),
            className: 'btn btn-primary',
            callback: 'submitLockbox',
        }, {
            text: locale.get('button.cancel'),
            className: 'btn btn-primary',
            callback: 'cancel',
        }];
    },

    submitLockbox() {
        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }

        const self = this;

        if (this.viewState === 'modal' && this.model.get('continue')) {
            // more to be added to cover this requirement

            this.model.save(
                {},
                {
                    success(model) {
                        self.renderMessage(model);
                        self.totalAdded.push(self.model);
                        self.model.clear();
                        self.model.set({
                            LOCATION_ID: self.location.LOCATION_ID,
                            LOCATION_CODE: self.location.LOCATION_CODE,
                            LOCATION_DESC: self.location.LOCATION_DESC,
                        });
                    },

                    error(model) {
                        self.renderMessage(model);
                    },
                },
            );
        } else {
            this.model.save(
                {},
                {
                    success(model, confirmResults) {
                        if (self.viewState === 'modal') {
                            self.totalAdded.push(self.model);

                            // TODO: Create a handler for multiple saved lockboxes.
                            self.trigger('lockbox:action:success', confirmResults);
                            dialog.close();
                        }
                        if (self.model.get('continue')) {
                            self.viewState = 'modal';
                            self.render();
                        }
                        self.trigger('lockbox:action:success', confirmResults);
                    },

                    error(model) {
                        self.renderMessage(model);
                    },
                },
            );
        }
    },

    renderMessage(model) {
        if (model.error) {
            const confirms = new Confirms({
                confirms: model.error.confirms,
            });

            this.alertView = alert.danger(
                model.error.message[0],
                {
                    details: confirms,
                    canDismiss: true,
                },
            );
        } else {
            const message = locale.get('LBX.Alert.Lockbox.Success.Add')
                .replace('*', this.model.get('NAME'))
                .replace('@', this.model.get('LOCATION_DESC'))
                .replace('#', this.model.get('LOCATION_CODE'));
            this.alertView = alert.success(
                message,
                {
                    canDismiss: true,
                },
            );
        }

        this.modalAlertRegion.show(this.alertView);
    },

    cancel() {
        if (this.viewState === 'modal') {
            dialog.close();
        }
        this.trigger('lockbox:cancel');
    },

    templateHelpers() {
        const self = this;
        return {
            isModal: (self.viewState === 'modal'),
            allowContinue: (self.action === 'INSERT'),
        };
    },
});
