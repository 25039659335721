var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"recPayExceptions.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":66}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"recPayExceptions.section-header-list",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":61}}}))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n                <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":77},"end":{"line":12,"column":103}}}))
    + "\" data-hook=\"export-button\"><span class=\"icon-export\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"recPayExceptions.button_export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":165},"end":{"line":12,"column":208}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":76},"end":{"line":13,"column":101}}}))
    + "\" data-hook=\"print-button\"><span class=\"icon-print\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"recPayExceptions.button_print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":161},"end":{"line":13,"column":203}}}))
    + "</button>\n            </div>\n        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"filter-region\" data-region=\"filterRegion\"></div>\n                <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n\n    </div>\n</div>\n\n";
},"useData":true});