import locale from '@glu/locale';
import scroll from 'common/util/scroll';
import store from 'system/utilities/cache';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import actionsUtil from 'common/util/entitledActions';
import alert from '@glu/alerts';
import Layout from '@glu/core/src/layout';
import template from './modifyView.hbs';
import FreeFormView from './freeFormView';
import FreeFormModel from '../models/freeFormModel';

const ModifyView = Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $submitButton: '[data-hook="getSubmit"]',
    },

    events: {
        'click [data-hook="getCancel"]': 'cancel',
        'click @ui.$submitButton': 'submit',
    },

    initialize() {
        this.tnum = '';
        if (store.has('serviceRequest-actionModel')) {
            this.tnum = store.remove('serviceRequest-actionModel').get('TNUM');
            this.modify = true;
        } else {
            this.setHasLoadedRequiredData(true);
        }
        this.model = new FreeFormModel({
            tnum: this.tnum,
        });
    },

    templateHelpers() {
        return {
            headingText: this.modify ? locale.get('serviceRequest.Modify.Service.Request')
                : locale.get('serviceRequest.New.Service.Request'),
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.requestTypeRegion.show(new FreeFormView({
                model: this.model,
            }));
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * Fetch model data from the server and then re-render the view
     */
    loadRequiredData() {
        this.model.fetch({
            tnum: this.tnum,
            modify: true,
        }).then(() => {
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    /**
     * When the model is valid, save it to the server
     */
    submit() {
        if (this.model.isValid()) {
            this.ui.$submitButton.attr('aria-busy', true);
            this.model.save({}, {
                modify: this.modify,
            }).then(this.handleSaveSuccess.bind(this))
                .catch(this.handleSaveFailure.bind(this));
        }
    },

    /**
     * Set message in the store based on the contextKey and then navigate to the
     * return route
     * @param {Object} response - response object
     */
    handleSaveSuccess(response) {
        const action = this.modify ? actionsUtil.ACTIONS.modify : actionsUtil.ACTIONS.insert;
        store.set(`${this.options.contextKey}-alertMessage`, action);
        store.set(`${this.options.contextKey}-confirms`, response);
        this.cancel();
    },

    /**
     * Show there error from the view in the alertRegion and scroll to it
     * @param {Object} response - response object
     */
    handleSaveFailure(response) {
        this.ui.$submitButton.attr('aria-busy', false);
        const { message } = response.responseJSON;
        this.alertRegion.show(alert.negative(message.join(' ')));
        scroll.scrollToRegion(this.alertRegion);
    },

    /**
     * Navigate to the returnRoute specified in the options during initialization
     */
    cancel() {
        this.navigateTo(this.options.returnRoute);
    },
});

export default ModifyView;
