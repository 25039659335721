var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"menuitem.appConfig",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":62}}}))
    + "</h1>\n</div>\n<div class=\"section section-container\" role=\"navigation\">\n    <div class=\"section-body\">\n		<ul id=\"configurations-tablist\"\n            class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            role=\"tablist\">\n			<li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"BTR\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"BTR\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.BTR",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":71}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"INFRA\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"INFRA\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.INFRA",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":32},"end":{"line":19,"column":75}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"WIRES\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"WIRES\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.WIRES",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":25,"column":75}}}))
    + "\n                </a>\n            </li>\n        	<li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"ACH\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"ACH\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.ACH",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":30},"end":{"line":31,"column":71}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"TRANSFER\"\n                   class=\"NavTabs-link btn btn-tertiary\"\n                   id=\"TRANSFER\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.TRANSFER",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":34},"end":{"line":37,"column":80}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"Loans\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"LOANS\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.LOANS",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":32},"end":{"line":43,"column":75}}}))
    + "\n                </a>\n            </li>\n		    <li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"SECURITY\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"SECURITY\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.SECURITY",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":35},"end":{"line":49,"column":81}}}))
    + "\n                </a>\n            </li>\n			<li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"RISK\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"RISK\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.RISK",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":31},"end":{"line":55,"column":73}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"RDC\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"RDC\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.RDC",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":30},"end":{"line":61,"column":71}}}))
    + "\n                </a>\n            </li>\n            <li class=\"NavTabs-item\" role=\"tab\">\n                <a aria-controls=\"RTP\"\n                   class=\"NavTabs-link btn btn-tertiary\"\n                   id=\"RTP\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"SMAINT.MAINT.APPCONF.TAB.RTP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":29},"end":{"line":67,"column":70}}}))
    + "\n                </a>\n            </li>\n		</ul>\n		<div class=\"pageRegion configTabs\"></div>\n	</div>\n</div>\n";
},"useData":true});