var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input type=\"checkbox\" id=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":60},"end":{"line":4,"column":67}}}) : helper)))
    + "\" class=\"select-all-perms\">\n            <label for=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":52}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignAll",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":54},"end":{"line":5,"column":80}}}))
    + "</label>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.ABS",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":32}}}))
    + "</h3>\n\n        <div class=\"checkbox\">\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.abs.selectBene",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":43}}}))
    + "\n            <div class=\"checkbox-inline\">\n                <input id=\"paymentsChk\" type=\"checkbox\" data-bind=\"model\" name=\"globalSettings.BENEPAY\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":104},"end":{"line":14,"column":146}}})) != null ? stack1 : "")
    + ">\n                <label for=\"paymentsChk\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payments.rfp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":41},"end":{"line":15,"column":81}}}))
    + "</label>\n            </div>\n            <div class=\"checkbox-inline\">\n                <input id=\"templatesChk\" type=\"checkbox\" data-bind=\"model\" name=\"globalSettings.BENETEMP\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":106},"end":{"line":18,"column":148}}})) != null ? stack1 : "")
    + ">\n                <label for=\"templatesChk\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.templates.rfp",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":83}}}))
    + "</label>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"section section-container section-abs\">\n    <div class=\"section-body\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRFP") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "\n        <div data-region=\"permissionsRegion\" class=\"assigned-permissions-region\"></div>\n        <div data-region=\"restrictedTemplatesRegion\" class=\"restricted-templates-region\"></div>\n    </div>\n</section>\n";
},"useData":true});