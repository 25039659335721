import Layout from '@glu/core/src/layout';
import userInfo from 'etc/userInfo';
import d3 from 'd3';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import moment from 'moment';
import EmptyChart from 'common/chart/views/emptyChart';
import ChartView from './chartView';
import template from './chartLayout.hbs';

export default Layout.extend({
    template,
    emptyChartMessage: 'No data available',

    initialize(options) {
        this.collection = options.collection;
        this.chartData = new Collection();
        this.dateFormatStr = userInfo.getDateFormat();

        this.emptyChart = new EmptyChart({
            message: this.emptyChartMessage,
        });

        this.mapDataForChart(options.collection);

        this.viewFactory = options.viewFactory;

        this.createChartConfig();
        this.updateChartConfig();
    },

    ui: {
        chart: '.chart',
    },

    regions: {
        chartRegion: '.chart',
    },

    createChartConfig() {
        const self = this;
        const entries = self.chartData.models;
        const selfDateStr = self.dateFormatStr;
        const formattedMoment = moment(entries[0].attributes.date, this.dateFormatStr);
        const firstDate = entries.length > 0 ? formattedMoment : moment(new Date());

        const todayDate = moment(new Date());
        const futureStart = todayDate.diff(firstDate, 'days');

        this.chartConfig = {
            // bindto: this.ui.chart.get(0),
            data: {
                json: self.chartData.toJSON(),
                onclick(d, i) {
                    self.didClickOnChart(d, i);
                },
            },

            axis: {
                x: {
                    type: 'category',
                    show: true,
                    tick: {
                        format(x) {
                            const dataObj = self.findObjectByXValue(Math.floor(x));
                            if (dataObj !== undefined) {
                                return moment(dataObj.date, selfDateStr).format(selfDateStr);
                                // return dataObj.date;
                            }
                            return '';
                        },
                        count: util.bind(self.getTickCount, self),
                    },
                },
                y: {
                    tick: {
                        format: d3.format('s'),
                    },
                },
            },
            grid: {
                x: {
                    show: false,
                    lines: [{
                        value: moment(new Date()).format(selfDateStr),
                        text: 'Today',
                    }],
                },
                y: {
                    show: true,
                    lines: [{
                        value: 0,
                        text: '',
                    }],
                },
            },
            tooltip: {
                format: {
                    name(name) {
                        return name;
                    },
                    value: d3.format('$,.2f'),
                },
            },
            legend: {
                show: false,
            },
            point: {
                r: 4,
            },
            zoom: {
                enabled: true,
                rescale: true,
            },
            regions: [{
                axis: 'x',
                start: futureStart,
                class: 'future',
            }],

        };
    },

    updateChartConfig() {

    },

    updateChart(collection) {
        const todayDate = moment(new Date());
        let futureStart = 0;

        // Calculate futureStart if collection is not empty
        if (collection.length > 0) {
            const firstDate = moment(collection.models[0].get('date'), this.dateFormatStr);
            futureStart = todayDate.diff(firstDate, 'days');
        }

        if (!this.chart) {
            return;
        }

        /*
         * TODO: Find out why all the data doesn't load when we use
         * this.chart.load instead of rerendering the entire chart
         * this.showChart();
         */

        this.chart.c3.regions.remove();

        this.chart.c3.load({
            json: collection.toJSON(),
            keys: {
                // it's possible to specify 'x' when category axis
                x: 'date',
                value: ['amount'],
            },
        });

        this.chart.c3.regions.add([{
            axis: 'x',
            start: futureStart,
            class: 'future',
        }]);
    },

    rebuildChart(collection) {
        this.mapDataForChart(collection);
        this.updateChart(this.chartData);
    },

    /*
     * Update the data used for the grid for the chart by combining all
     * payments per day into one data point
     */
    mapDataForChart(collection) {
        const collectionParam = collection;
        const self = this;
        const firstModDate = collection.models[0].attributes.date;
        const selfDateStr = self.dateFormatStr;
        const entryCount = collection.models.length;
        const collectModMinusOneDate = collection.models[entryCount - 1].attributes.date;
        const entries = [];
        const firstDate = moment(firstModDate, selfDateStr).format(selfDateStr);
        const lastDate = moment(collectModMinusOneDate, selfDateStr).format(selfDateStr);
        const diff = moment(firstDate).diff(moment(lastDate), 'days');
        let checkDate = firstDate;
        let reduced = {};

        if (collection.length > 0) {
            // sort collection by date
            collectionParam.comparator = model => Date.parse(model.get('date'));
            collection.sort();

            /*
             * OLD CODE \\
             * LOOP ONE \\
             * Account for all dates that exist between our starting and final dates
             */
            for (let i = diff; i < 0; i += 1) {
                const newEntry = {
                    attributes: {
                        date: moment(checkDate, selfDateStr).format(selfDateStr),
                        amount: 0,
                    },
                };
                entries.push(newEntry);
                checkDate = moment(checkDate).add('days', 1);
            }

            /*
             * LOOP TWO \\
             * Assemble the data we do have into the array of date entries
             */
            for (let i = 0; i < collection.models.length; i += 1) {
                const modDateAttrs = collection.models[i].attributes.date;
                const dateValue = moment(modDateAttrs, selfDateStr).format(selfDateStr);
                const amountValue = collection.models[i].attributes.amount;

                const originalEntry = {
                    attributes: {
                        date: dateValue,
                        amount: amountValue,
                    },
                };
                entries.push(originalEntry);
            }

            // massage transaction data so there is only one entry per date
            reduced = util.flatten(util.reduce(entries, (merged, object) => {
                const objDateAttr = object.attributes.date;
                const entry = merged[objDateAttr] || {};
                return {
                    ...merged,
                    [objDateAttr]: {
                        date: moment(objDateAttr, selfDateStr).format(selfDateStr),
                        // sum of credit transactions for the day,
                        amount: (entry.amount || 0) + object.attributes.amount,
                        // number of payments for the day
                        paymentsQty: (entry.paymentsQty || 0)
                            + (object.attributes.amount ? 1 : 0),
                    },
                };
            }, {}));
        }
        this.chartData.reset(reduced);
    },

    onRender() {
        const self = this;

        // TODO: This is hacky - the C3 chart will not load if there isn't a delay
        if (this.collection.length > 0) {
            setTimeout(() => {
                self.showChart();
            }, 0);
        } else {
            this.chartRegion.show(this.emptyChart);
        }
    },

    // Remove the stuff below this
    showChart() {
        const self = this;

        this.chart = new ChartView({
            c3: self.chartConfig,
        });

        this.chartRegion.show(this.chart);

        setTimeout(() => {
            self.trigger('showChart');
            // delay here because glu/chartView.js ln 30 adds a delay to generate c3 chart
        }, 50);
    },

    didClickOnChart(data) {
        const dateObj = this.findObjectByXValue(data.index);
        if (dateObj === undefined) {
            return;
        }
        const searchDate = dateObj.date;
        if (searchDate === undefined) {
            return;
        }

        this.trigger('paginateGrid', searchDate);
    },

    findObjectByXValue(xValue) {
        const dates = util.flatten(util.reduce(this.chartData.models, (merged, object) => {
            const mergedParam = merged;
            mergedParam[object.attributes.date] = merged[object.attributes.date] || {};
            mergedParam[object.attributes.date] = {
                date: object.attributes.date,
            };
            return mergedParam;
        }, {}));

        return dates[xValue];
    },

    getTickCount() {
        if (util.isUndefined(this.chartData)) {
            return 1;
        }
        const l = this.chartData.length;

        const defaultTicks = 8;
        const biweekly = 3.5;
        const weekly = 7;
        const bimonthly = 15;
        const monthly = 30;

        const twoWeeks = 14;
        const monthAndAHalf = 45;
        const quarterYear = 90;
        const halfYear = 180;
        const year = 365;

        if (l < twoWeeks) {
            return l;
        }

        if (l < monthAndAHalf) {
            return Math.ceil(l / biweekly);
        }

        if (l < quarterYear) {
            return Math.ceil(l / weekly);
        }

        if (l < halfYear) {
            return Math.ceil(l / bimonthly);
        }

        if (l < year) {
            return Math.ceil(l / monthly);
        }

        return defaultTicks;
    },

});
