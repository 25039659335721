import Layout from '@glu/core/src/layout';
import constants from 'app/administration/constants';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import RTPMessagesView from './rtpMessages';
import PaymentRequests from './paymentRequests';
import InboundPaymentRequests from './inboundRequests';
import { updateSelectAllState, onChildrenChange } from '../helpers';

/*
 * TODO Uncomment when restricted templates are implemented.
 * import RestrictedTemplates  from '../payments/restrictedTemplates';
 */
import globalSettings from '../global/globalSettings';
import permissionsViewTmpl from './permissionsView.hbs';

export default Layout.extend({
    template: permissionsViewTmpl,

    ui: {
        /*
         * TODO Uncomment when restricted templates are implemented.
         * $templatesPanel: '.templates-panel',
         */
        $selectAll: '[data-hook="selectAllPermissions"]',
        $paymentCheckbox: '[data-hook="paymentsChk"]',
        $templateCheckbox: '[data-hook="templatesChk"]',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPermissions',
    },

    regions: {
        requestForPaymentsRegion: '[data-hook="requestForPaymentsRegion"]',
        inboundRequestsRegion: '[data-hook="inboundRequestsRegion"]',
        /*
         * TODO Uncomment when restricted templates are implemented.
         * restrictedTemplatesRegion: '[data-hook="restrictedTemplatesRegion"]',
         */
        rtpMessagesRegion: '[data-hook="rtpMessagesRegion"]',
    },

    initialize(options) {
        this.mode = options.mode || 'insert';
        this.model = options.model;
        this.mainModel = options.mainModel;
        this.readOnly = this.mode === constants.MODES.VIEW;
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        /*
         * TODO Uncomment lines below when restricted templates are implemented.
         * this.ui.$templatesPanel.hide();
         */
        this.buildInboundRequestForPaymentsRegion();
        this.buildRequestForPaymentsRegion();
        this.buildRTPMessagesRegion();
        // this.buildRestrictedTemplatesRegion();
        globalSettings.populateBeneAddressBookSettings(
            this.model,
            this.ui.$paymentCheckbox, this.ui.$templateCheckbox,
        );
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el?.find('[data-hook$="Region"]').find('input[type="checkbox"]');
    },

    /**
     * Check all of the permissions on the abs tab except contacts.
     */
    selectAllPermissions(evt) {
        if (this.inboundRequestsView) {
            this.inboundRequestsView.selectAllPerms(evt);
        }

        if (this.paymentRequestsView) {
            this.paymentRequestsView.selectAllPermissions(evt);
        }

        if (this.rtpMessagesView) {
            this.rtpMessagesView.selectAllReports(evt);
        }
        const { checked } = evt.target;
        this.$el.find(`
            .select-all-reports-group,
            [data-hook="selectAllPayTemp"],
            [data-hook="selectAllGroupPermissions"]
        `).prop('checked', checked);
    },

    /**
     * Build the outbound requests for payment region.
     */
    buildRequestForPaymentsRegion() {
        this.paymentRequestsView = new PaymentRequests({
            model: this.model.get('groups').at(0).get('types').findWhere({ id: 'REQOUT' }),
            mode: this.mode,
            readOnly: this.readOnly,
        });

        this.requestForPaymentsRegion.show(this.paymentRequestsView);
    },

    /**
     * Build the RFP Inbound Payment Requests - Authorizations region.
     */
    buildInboundRequestForPaymentsRegion() {
        this.inboundRequestsView = new InboundPaymentRequests({
            model: this.model.get('groups').at(0),
            mode: this.mode,
            readOnly: this.readOnly,
            onChildrenChange: this.updateSelectAllState.bind(this),
        });

        this.inboundRequestsRegion.show(this.inboundRequestsView);
    },

    /**
     * Build the RTP Messages region.
     */
    buildRTPMessagesRegion() {
        const rtpMessagesTypes = util.chain(this.model.get('groups').at(0).get('types').models)
            .map((type) => {
                const entitlement = type.getEntitlementsByFunctionCode(['MSGS'], !this.readOnly);
                return entitlement.length > 0 ? entitlement.at(0) : null;
            })
            .compact()
            .value();

        this.rtpMessagesView = new RTPMessagesView({
            collection: new Collection(rtpMessagesTypes),
            model: this.model.get('groups').at(0),
            mode: this.mode,
            readOnly: this.readOnly,
            onChange: () => this.updateSelectAllState(),
        });

        this.rtpMessagesRegion.show(this.rtpMessagesView);
    },

    /**
     * Build the restricted templates for outbound requests for payment region.
     */
    // TODO Uncomment when restricted templates are implemented.
    /*
     * buildRestrictedTemplatesRegion() {
     *  this.restrictedTemplatesView = new RestrictedTemplates({
     *      mode: this.mode,
     *      mainModel: this.mainModel,
     *      model: this.model,
     *      userGroup: this.model.userGroupModel.get('id'),
     *      userId: this.model.userModel.get('USERID'),
     *      readOnly: this.readOnly,
     *  });
     *
     *  this.listenToOnce(this.restrictedTemplatesView,
     *  'restricteddTemplatesPanel:show', () => {
     *      this.ui.$templatesPanel.show();
     *  }, this);
     *
     *  this.restrictedTemplatesRegion.show(this.restrictedTemplatesView);
     * },
     *
     * beforeSave() {
     *  return this.restrictedTemplatesView ?
     *      this.restrictedTemplatesView.beforeSave() : Promise.resolve();
     * },
     */

    templateHelpers() {
        const types = this.model.get('groups').at(0).get('types');
        const hasRFPOutbound = types.get('REQOUT');
        const hasRFPInbound = types.get('REQIN');
        const hasRTPMessages = types.get('RTPACK');

        return {
            readOnly: this.readOnly,
            hasMessages: hasRTPMessages,
            hasOutbound: hasRFPOutbound,
            hasInbound: hasRFPInbound,
            hasRFP: hasRFPOutbound || hasRFPInbound,
        };
    },
});
