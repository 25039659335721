/**
 * A collection of utils needed to drive the EditContextApi provided as the value of the
 * EditContext.
 *
 * @module EditUtils
 */
import { parseValidators, validationProcessor } from '@glu/validation-react';
import isEqual from 'lodash/isEqual';

export const originalRowProp = Symbol('originalRow');
export const errorsProp = Symbol('errors');

export function fromEntries(entries) {
  return entries.reduce((memo, [key, value]) => ({ ...memo, [key]: value }), {});
}

/**
 * Set the data onto the dataRef's current property for a rowId. If the data is falsy remove it.
 *
 * @param {Object} dataRef - React ref holding all the edited data and errors.
 * @param {string|number} rowId - Id to set the data for.
 * @param {Object} data - Data for the row, or falsy to remove the data for rowId.
 * @memberof EditUtils
 */
export function setDataForRow(dataRef, rowId, data) {
  if (!data && dataRef.current[rowId]) {
    dataRef.current = { ...dataRef.current }; // eslint-disable-line no-param-reassign
    delete dataRef.current[rowId]; // eslint-disable-line no-param-reassign
  } else if (data) {
    dataRef.current = { // eslint-disable-line no-param-reassign
      ...dataRef.current,
      [rowId]: data
    };
  }
}

/**
 * Gather validators off the passed in columns, using only validators from a single column
 * if field is supplied.
 *
 * @param {Array<Object.<string, *>>} columns - Columns describing your data and, optionally,
 *    how to validate it.
 * @param {string} [field] - Field to use to enable single field validation.
 * @returns {Object.<string, *>} - Hash of validators keyed by field.
 * @memberof EditUtils
 */
export function getValidators(columns, field) {
  return columns && parseValidators.getValidators(columns.reduce((
    memo, { field: colField, validators: colValidators }
  ) => ({
    ...memo,
    ...(colValidators && (!field || colField === field) ? {
      [colField]: typeof colValidators === 'function' ? colValidators() : colValidators
    } : {})
  }), {}));
}

/**
 * Receive the results of a validation processor run on data and update the dataRef with the
 * results as well as call any registered callbacks that want to know about errors.
 *
 * @param {Object} dataRef - React ref holding all the edited data and errors.
 * @param {string|number} rowId - The id of the row the results are for
 * @param {Object<string, Array<function>>} errorCallbacksByRowId - Callbacks by rowId that wish
 *    to be notified when there are changes in the errors for their row.
 * @param {string} [field] - Enable setting the new errors to all the old errors minus the ones
 *    for this field overlaid with the ones found by the calling validation run.
 * @returns {function} - Function to process results from the validation library.
 * @memberof EditUtils
 */
export function createValidationResultProcessor(
  dataRef, rowId, errorCallbacksByRowId, field
) {
  return (errorHash) => {
    const prevErrors = dataRef.current[rowId] && dataRef.current[rowId][errorsProp];
    const rowErrors = {
      ...(field && prevErrors ? (
        fromEntries(Object.entries(prevErrors).filter(([key]) => key !== field))
      ) : {}),
      ...errorHash
    };

    // ensure we don't churn so only change the errors if they are actually different
    // ensuring no errors is consistently undefined
    const newErrors = isEqual(prevErrors, rowErrors) ? prevErrors : (
      Object.keys(rowErrors).length ? rowErrors : undefined
    );

    if (newErrors !== prevErrors) {
      const { [errorsProp]: oldErrors, ...rest } = dataRef.current[rowId];
      setDataForRow(dataRef, rowId, { ...rest, ...(newErrors ? { [errorsProp]: newErrors } : {}) });

      if (errorCallbacksByRowId[rowId]) {
        errorCallbacksByRowId[rowId].forEach((callback) => callback(newErrors));
      }
    }

    return newErrors;
  };
}

/**
 * Validate a single row of data, identified by rowId and store the errors, if any, on the errors
 * prop for the row on its entry in dataRed, simultaneously returning those errors.
 *
 * @param {Array<Object.<string, *>>} columns - Columns describing your data and, optionally,
 *    how to validate it.
 * @param {string|number} rowId - The id of the row we are to validate
 * @param {Object} editedRowData - The edited copy of the row data to be validated
 * @param {Object} dataRef - React ref holding all the edited data and errors.
 * @param {Object<string, Array<function>>} errorCallbacksByRowId - Callbacks by rowId that wish
 *    to be notified when there are changes in the errors for their row.
 * @param {string} [field] - Enable setting the new errors to all the old errors minus the ones
 *    for this field overlaid with the ones found by the calling validation run.
 * @returns {Promise} Promise that will resolve with the errors, if any, for the row.
 * @memberof EditUtils
 */
export function doValidationForRow(
  columns, rowId, editedRowData, dataRef, errorCallbacksByRowId, field
) {
  const validators = getValidators(columns, field);

  const validationResult = validators ? (
    validationProcessor.runValidators(editedRowData, validators)
  ) : undefined;

  if (validationResult) {
    return validationResult.then(createValidationResultProcessor(
      dataRef, rowId, errorCallbacksByRowId, field
    ));
  }

  return undefined;
}

/**
 * Determine if a row has changed or not based on comparing the data for the row with
 * its symbol originalRowProp data.
 *
 * @param {Object<string, *>} editedRowData - Edited data for the row
 * @returns {boolean} - True if the row data has changed from the original, false otherwise.
 * @memberof EditUtils
 */
export function hasRowChanged(editedRowData) {
  const bareEditedRowData = { ...editedRowData };
  delete bareEditedRowData[originalRowProp];
  delete bareEditedRowData[errorsProp];

  return !isEqual(bareEditedRowData, editedRowData[originalRowProp]);
}

/**
 * Get the row data with the non edited rows optionally filtered out.
 *
 * @param {Object} dataRef - React ref holding all the edited data and errors.
 * @param filterUnchangedRows - True if only the changed rows are to be returned, false if all
 *    rows are to be returned
 * @returns {Object<string|number, Object>}
 * @memberof EditUtils
 */
export function getFilteredEditedData(dataRef, filterUnchangedRows, stripSymbols) {
  return fromEntries(
    Object.entries(dataRef.current).filter(([, value]) => {
      if (!filterUnchangedRows) return true;

      const { [errorsProp]: errors, [originalRowProp]: original, ...current } = value;

      return !isEqual(current, original);
    }).map(([key, value]) => {
      const stripped = stripSymbols ? { ...value } : value;

      if (stripSymbols) {
        delete stripped[originalRowProp];
        delete stripped[errorsProp];
      }

      return [key, stripped];
    })
  );
}

/**
 * Create a new, edited, copy of a row's data, storing the original data the first time it is
 * edited on a special symbol prop so we can compare original to edited later.
 *
 * @param {string|number} rowId - The id of the row we are editing
 * @param {Object<string, *>} rowData - Current data for the row
 * @param {string} field - The field in rowData to be changed
 * @param {*} fieldValue - The new value for the field
 * @param {Object} dataRef - React ref holding all the edited data and errors.
 * @param {boolean} [allowUndefined=false] - True if we want to be able to set undefined as the
 *    value for a field. False if undefined should not be set on the edited data for the row.
 * @return {*} - The edited row data
 * @memberof EditUtils
 */
export function createEditedRowData(rowId, rowData, field, fieldValue, dataRef, allowUndefined) {
  const { [field]: priorValue, ...priorEdits } = dataRef.current[rowId] || {};

  // merge the current base row with the prior edits and the current field edit
  const editedRowData = {
    ...(rowData[originalRowProp] || rowData),
    [originalRowProp]: rowData[originalRowProp] || { ...rowData },
    ...priorEdits,
    ...(allowUndefined || fieldValue !== undefined ? { [field]: fieldValue } : {})
  };

  return editedRowData;
}

/**
 * Validate the entire set of edited rows, optionally choosing to skip validating rows whose data
 * matches their original data.
 *
 * @param {Array<Object.<string, *>>} columns - Columns describing your data and, optionally,
 *    how to validate it.
 * @param {Object} dataRef - React ref holding all the edited data and errors.
 * @param {boolean} skipUnchangedRows - If true do not validate unchanged rows.
 * @param {Object<string, Array<function>>} errorCallbacksByRowId - Callbacks by rowId that wish
 *    to be notified when there are changes in the errors for their row.
 * @return {Promise} - Promise resolving to a hash, keyed by row id, of the errors for each row
 *    that has errors.
 * @memberof EditUtils
 */
export function validateData(columns, dataRef, skipUnchangedRows, errorCallbacksByRowId) {
  const validators = getValidators(columns);

  return Promise.all(
    Object.entries(getFilteredEditedData(dataRef, skipUnchangedRows)).map(([rowId, rowData]) => (
      validationProcessor.runValidators(rowData, validators).then(
        createValidationResultProcessor(dataRef, rowId, errorCallbacksByRowId)
      ).then(
        (errors) => ([rowId, errors])
      )
    ))
  ).then((results) => {
    const errorsByRowId = fromEntries(results.filter(([, errors]) => errors));

    return Object.keys(errorsByRowId).length ? errorsByRowId : undefined;
  });
}
