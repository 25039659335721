import util from '@glu/core/src/util';
import locale from '@glu/locale';
import ListView from 'app/payments/views/listPayments';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import constants from 'common/dynamicPages/api/constants';
import alert from '@glu/alerts';
import ConversationView from 'app/payments/views/realTimePayments/rtpConversationView';

const AdminPaymentsList = ListView.extend({
    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
        },
    ),

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: '/adminPayment/listView/payments',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            returnRoute: 'REPORTING/viewPayments',
            viewId: options.viewId,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        this.exportListViewPath = '/export/listView';

        // reset the drawer state for tab and open state on init
        this.appBus.trigger('dgb:drawer:update', {
            showTab: false,
            showTabOnClose: false,
            shouldBeOpen: false,
            viewOptions: {
                allowDetail: true,
                keepAlive: false,
            },
        });
    },

    onRender(...args) {
        ListView.prototype.onRender.call(this, args);

        // dont proceed with alert region if this view does not have one
        if (!this.alertRegion) {
            return;
        }
        // grab the stored message for loan transition event, if it exists.
        const alertObj = store.get('loanTransition:alertMessage') || null;
        // if message exists, then display alert
        if (alertObj) {
            this.showAlert(alertObj);
        }
    },

    showAlert(alertObj) {
        this.alertRegion.show(alertObj);
        // immediately unset this alert, if it exists, so we only show it once
        store.unset('loanTransition:alertMessage');
    },

    prepareExportScreenInfo(exportModelParam) {
        const exportModel = exportModelParam;
        ListView.prototype.prepareExportScreenInfo.call(this, exportModel);
        // disable export type selections for admin
        exportModel.showDataFormatForSel = false;
        exportModel.showDataFormatForAll = false;
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        if (this.exportModel.expData !== 'transaction') {
            this.exportModel.inquiryId = '20007';
        }

        return this.exportModel;
    },

    /**
     * Clear the alertRegion, then check to make sure we have selected row before
     * show the printOptionsModal. When no rows are selected, show a warning.
     */
    showPrintOptionsModal() {
        const rows = this.gridView.grid.getSelectedRows();
        if (this.alertRegion) {
            this.alertRegion.close();
        }
        if (rows.length) {
            ListView.prototype.showPrintOptionsModal.call(this);
        } else {
            this.alertView = alert.warning(locale.get('payment.print.error.message'));
            this.alertRegion.show(this.alertView);
        }
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            summaryInquiryId: constants.INQUIRY_ID_20007,
        };
    },

    isLoan(model) {
        const type = model.get('TYPE');
        return type === 'LOANPAY' || type === 'LOANDRAW';
    },

    isPassThru(model) {
        const type = model.get('TYPE');
        return type === 'PASSTHRU';
    },

    gridRowSelect(options) {
        if (this.isLoan(options.model)) {
            store.set('loanModel', options.model);
            this.navigateTo('REPORTING/viewLoanPaymentDetails');
        } else if (this.isPassThru(options.model)) {
            store.set('passthru_view_payment_model', options.model);
            store.set('SURROGATETNUM', options.model.get('TNUM'));
            this.navigateTo('REPORTING/passThroughDetails');
        } else {
            const messageStatus = options.model.get('MESSAGE_STATUS');
            if (options.model.get('PRODUCT') === 'RTP' && ((messageStatus && messageStatus !== '-'))) {
                if (options.fromReact) {
                    // Drawer is already present, called from detail click in drawer
                    this.appBus.trigger('dgb:drawer:update', {
                        shouldBeOpen: false,
                        showTab: true,
                        showTabOnClose: true,
                        viewOptions: {
                            allowDetail: false,
                            keepAlive: true,
                        },
                    });
                } else {
                    /*
                     * called from view click in grid
                     * create the view passing in the model
                     */
                    const rtpConversationData = {
                        view: ConversationView,
                        showTab: true,
                        shouldBeOpen: false,
                        viewOptions: {
                            model: options.model,
                            conversationStarted: true,
                            defaultOpen: false,
                            allowDetail: false,
                            currentTab: 'submitted',
                            keepAlive: true,
                        },
                    };
                    this.appBus.trigger('dgb:drawer:update', rtpConversationData);
                }
            }

            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('REPORTING/viewPaymentsDetails');
        }
        return Promise.resolve();
    },

    loadViewRequirements() {
        // eslint-disable-next-line
        ListView.__super__.loadViewRequirements.call(this);
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'PAY_LIST_VIEW',
    view: AdminPaymentsList,
    options: {},
});

export default AdminPaymentsList;
