import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import transform from 'common/util/transform';

export default Layout.extend({
    initialize(options) {
        this.closeText = options.closeText || locale.get('gridAction.popup.error.missingCloseText');
        this.cancelText = options.cancelText || locale.get('gridAction.popup.error.missingCancelText');
        this.dataUrl = options.dataUrl;
        this.failDescription = options.failDescription || locale.get('gridAction.popup.error.failMessage');
        this.failLabel = options.failLabel || locale.get('gridAction.popup.error.submitFailed');
        this.model = options.model;
        this.modelAttributesSnapshot = this.model.keys();
        this.needsData = options.dataUrl;
        this.offsetX = options.offsetX;
        this.offsetY = options.offsetY;
        this.submitText = options.submitText || locale.get('gridAction.popup.error.missingSubmitText');
        this.successLabel = options.successLabel || locale.get('gridAction.popup.success');
        this.title = options.title || locale.get('gridAction.popup.error.missingTitle');
    },

    /*
     * Identify any attributes that weren't on the model when the popup was opened and
     * and unset them from the model now
     */
    clearData() {
        this.model.keys().filter(key => !this.modelAttributesSnapshot.includes(key))
            .forEach((newAttribute) => {
                this.model.unset(newAttribute, { silent: true });
            });
    },

    // formats data for submission of the grid action popup
    formatData() {
        const submissionData = {
            ENTRYMETHOD: 0,
            FUNCTIONCODE: 'REQUEST',
            MESSAGE_ID: this.model.get('MESSAGE_ID'),
            UPDATECOUNT__: this.model.get('UPDATECOUNT__'),
        };

        // getDataForSubmission only exists within views that extend this view
        return {
            item: transform.hashToPairs({ ...submissionData, ...this.getDataForSubmission() }),
        };
    },

    /**
     * performs validation on the grid action popup, intended to be overridden by forms
     * that actually do have validation to perform
     * @returns {Boolean} - whether or not the form clears validation
     */
    formIsValid() { return true; },
});
