var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-md-2\">\n        <div class=\"form-group required\">\n            <label for=\"amount\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.ThresholdAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":63}}}))
    + "</label>\n            <div class=\"input-group\">\n                <input type=\"text\"\n                       class=\"form-control\"\n                       name=\"amount\"\n                       id=\"amount\"\n                       data-bind=\"model\"\n                       data-hook=\"getAmountInput\"\n                       maxlength=\"20\"\n                       data-type=\"amount\"\n                       data-maxlength=\"14\">\n                <div class=\"input-group-addon\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"currency") || (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currency","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":59}}}) : helper)))
    + "</div>\n            </div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"amount\"></span>\n\n        </div>\n    </div>\n</div>\n<div data-region=\"approvalSequencesRegion\"></div>\n\n<div class=\"row\">\n    <div class=\"col-12 form-group\">\n        <button type=\"button\"\n                class=\"btn btn-tertiary\"\n                data-hook=\"getAddSequenceButton\">\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Add.Approver.Sequence",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":63}}}))
    + "\n        </button>\n  </div>\n</div>\n";
},"useData":true});