import userAdmin from 'system/webseries/api/userAdmin';
import PasswordReset from 'system/login/views/passwordReset';

export default PasswordReset.extend({
    initialize(options) {
        PasswordReset.prototype.initialize.call(this, options);
        this.updateCount = options.updateCount;
    },

    change() {
        const self = this;

        /*
         * validate the form
         * NH-109035 'hybrid' implementation until reset password is done only by email
         */
        if ((!this.emailResetPassword || this.options.entitlements.MODIFY)
            && this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }

        // Call Admin Change Password service
        userAdmin.changePassword(this.userGroup, this.userId, this.updateCount, this.model.get('newPassword'))
            .then((data) => {
                self.success(data);
            }, (data) => {
                self.failure(data);
            });
    },
});
