import { createUseStyles } from '@glu/theming';

const styles = ({ breakpoints }) => ({
    subject: {
        width: '50%',
        maxWidth: 580,
    },
    tabContainer: {
        marginBottom: 50,
    },
    parameters: {
        flex: 1,
        margin: 30,
        width: '100%',
        [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            width: 'auto',
        },
    },
});

export default createUseStyles(styles);
