import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import store from 'system/utilities/cache';
import locale from 'system/webseries/api/locale';
import systemConfig from 'system/configuration';
import ContextApi from 'common/dynamicPages/api/context';
import DataApi from 'common/dynamicPages/api/data';
import constants from 'app/administration/constants';
import ContextModel from 'common/dynamicPages/models/context';
import alertMessage from 'common/api/alertMessage';
import AdminUtil from 'app/administration/common/util';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import services from 'services';
import Collection from '@glu/core/src/collection';
import UserGroupCollection from 'app/administration/collection/userGroups';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import scroll from 'common/util/scroll';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
// PCM- eslint-disable-line
import ReactDOM from 'react-dom';
import React from 'react';
import $ from 'jquery';
import DocumentRoleDropdown from 'pcm/common/components/DocumentRoleDropdown';
// PCM- eslint-disable-line
import UserGroupLookup from './userGroupLookup';
import actionUtils from './actionUtils';
import UserProfileView from './profile/userProfile';
import AssignRolesLayout from './assignRolesLayout';
import userByRoleLayoutTmpl from './userByRoleLayout.hbs';

export default Layout.extend({
    template: userByRoleLayoutTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'user-by-role',

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        paymentsScreen: '.payments',
        userHeading: '.user-profile-info',
        userNote: '[data-hook="userNote"]',
        saveUser: 'button[data-action="save"]',
        displayInfo: '[data-hook="displayInfo"]',
    },

    events: {
        'click @ui.$navLinks': 'handleToggleButtonClick',
        'keypress @ui.$navLinks': 'handleKeyPress',
    },

    regions: {
        formRegion: '.formRegion',
        alertRegion: '.alert-region',
    },

    isInitialRender: true,
    isTxClient: false,
    initialize(opts) {
        this.mode = opts.mode;
        this.buttons = opts.buttons;
        this.userGroup = opts.userGroup;
        this.parentUserGroup = opts.parentUserGroup;
        this.userId = opts.userId;
        this.isAdmin = systemConfig.isAdmin();
        this.isDeepLinked = opts.deeplinked;
        this.entitlement = opts.entitlement;
        this.userInfo = opts.userInfo;
        // NH-175030 - Checking if user is currently modifying a user via deeplink
        this.isModifyUserViaDeeplink = opts.isModifyUserViaDeeplink;

        /*
            * Retrieve configparam that determines whether to render User Tab
            * in a portal environment. The value is always set to true in an
            * non-portal environment since the User Profile tab should always
            * be rendered. The value of the configparam is not used in that case.
            * RoleBasedDisplayUserTabInPortal set to false -
            * the User Profile tab did not display for Modify screen
            * RoleBasedDisplayUserTabInPortal set to true -
            * the User Profile tab is display for Modify screen
            */
        this.isProfileTabRequired = this.isModifyUserViaDeeplink ? serverConfigParams.get('RoleBasedDisplayUserTabInPortal') === 'true' : true;

        this.returnURL = opts.returnRoute;
        if (this.returnURL) {
            store.set('current-workspace-route', this.returnURL);
        }

        this.contextDef = ContextApi.menuContext.getContext(constants.USER_MAINT);

        if (this.isAdmin) {
            this.contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'USERS',
                productCode: '_ADMIN',
                subType: '*',
                typeCode: '_USER',
            };
        } else {
            this.contextDef.actionContext = {
                actionMode: 'SELECT',
                entryMethod: 0,
                functionCode: 'MAINT',
                productCode: 'CMAINT',
                subType: '*',
                typeCode: 'CLUSR_TM',
            };
        }

        this.contextModel = new ContextModel({
            menuCategory: this.options.menuCategory,
            serviceName: this.options.serviceName,
            serviceFunc: this.options.serviceFunc,
            businessType: this.options.businessType,
            context: this.options.context,
            contextDef: this.contextDef,
        });

        this.contextKey = this.contextModel.getContextKey();
        this.localeKey = this.contextModel.getLocaleKey();
    },

    filterButtons() {
        this.buttons = actionUtils.filterActionButtons(
            this.buttons, this.model,
            this.mode, false,
        );
    },

    displayHeader(id, name) {
        this.ui.displayInfo.text(`${id} | ${name}`);
    },

    /**
     * NH-175030 If we are using first name and last name separately, listen for change in the same.
     * Otherwise listen for change in username input box
     */
    initUserNameDisplay() {
        if (serverConfigParams.get('userMaintSplitUserName') === 'true') {
            this.listenTo(this.model, 'change:USERID change:FIRSTNAME change:LASTNAME', function () {
                this.displayHeader(this.model.get('USERID'), `${this.model.get('FIRSTNAME')} ${this.model.get('LASTNAME')}`);
            }, this);
        } else {
            this.listenTo(this.model, 'change:USERID change:USERNAME', function () {
                this.displayHeader(this.model.get('USERID'), this.model.get('USERNAME'));
            }, this);
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.currentTabText = locale.get('rolebased.userdetail');
            setTabButtonText(this, this.currentTabText);
            createTabsToggleButton(this);
            if (store.has('addUserCentricSuccessMessage')) {
                this.renderMessage(store.get('addUserCentricSuccessMessage'));
                store.unset('addUserCentricSuccessMessage');
            }

            this.userProfileForm = new UserProfileView({
                contextDef: this.contextDef,
                model: this.model,
                mode: this.mode,
                pageTitle: locale.get('administration.user.maint'),
                listViewLabel: locale.get('administration.user.maint.center'),
                returnRoute: constants.ROUTES.USER_LISTVIEW,
            });
            this.formRegion.show(this.userProfileForm);

            /*
             * We must wait until the MDF form is loaded to properly set the correct
             * model to this view.
             */
            this.listenTo(this.userProfileForm.pageView, 'loaded', () => {
                this.model = this.userProfileForm.pageView.model;
                if (this.userProfileForm.pageView.userGroupModel) {
                    this.userProfileForm.pageView.userGroupModel.attributes.mapDataList
                        .forEach((mapData) => {
                            if (mapData.toField === 'TREASURYINTEGRATED') {
                                this.isTxClient = mapData.value === '1';
                            }
                        });
                }
                this.model.set('USERGROUP', this.userGroup);
                this.model.set('ISTXCLIENT', this.isTxClient);
                if (this.isDeepLinked && this.isTxClient) {
                    this.model.set('USERID', this.userId);
                    if (this.mode === constants.MODES.INSERT) {
                        this.model.set('EMAILADDRESS', this.userInfo.emailAddress);
                        this.model.set('SSOID', this.userInfo.ssoId);
                        this.model.set('FIRSTNAME', this.userInfo.firstName);
                        this.model.set('LASTNAME', this.userInfo.lastName);
                        this.model.set('USERNAME', this.userInfo.userName);
                    }
                }

                this.assignRolesLayout = new AssignRolesLayout({
                    model: this.model,
                    mode: this.mode,
                    copyFromUser: this.options.copyFromUser || null,
                    hasPanelApproval: this.hasPanelApproval,
                });

                /*
                 * In case user saves the form without opening the "Assign Roles" tab the
                 * roles are not populated and getting deleted from USERROLESMAINT table.
                 */
                if (!this.assignRolesLayout.hasLoadedRequiredData()) {
                    this.assignRolesLayout.loadRequiredData();
                }
                /**
                 * NH-175030 - Displaying user ID and user name at the top of
                 * user maintenance screen
                 */
                // this.initUserNameDisplay();
                const displaySep = this.model.get('USERID') ? ' | ' : '';
                this.ui.displayInfo.text(`${this.model.get('USERID')}${displaySep}${this.model.get('USERNAME')}`);
                if (this.isModifyUserViaDeeplink) {
                    this.changeStep('roles');
                }
                this.isInitialRender = false;
                if (this.mode !== constants.MODES.MODIFY && this.isTxClient && this.isDeepLinked) {
                    this.ui.saveUser[0].innerText = locale.get('uce.assignUserPermission');
                }
            });
        } else {
            this.load();
        }
    },

    // PCM RELATED
    getPtxGroupContainer() {
        const container = $('.form-column.PTXGroup_user');
        if (container.length) {
            return container;
        }
        return null;
    },

    renderRoleDropdownComponent() {
        const ptxContainer = this.getPtxGroupContainer();
        if (ptxContainer) {
            ptxContainer.get(0).children[0].hidden = true;
            // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
            ReactDOM.render(<DocumentRoleDropdown
                name="PTXROLE"
                isAdmin={this.isAdmin}
                userGroup={this.userGroup}
                label={locale.get('checkManagement.documentProfile')}
                valueFromModel={this.model.get('_CLFLDS_PTXRoleName')}
                onChange={(id, name) => {
                    this.model.set('_CLFLDS_PTXRoleId', id);
                    this.model.set('_CLFLDS_PTXRoleName', name);
                }}
            />, ptxContainer.get(0).children[1]);
        }
    },

    renderMessage(alertResponse) {
        const confirmResponse = alertResponse.response;
        if (typeof confirmResponse === 'object' && confirmResponse.message.length > 0) {
            /*
             * handles a response on this detail page which is normally displayed on list view.
             * This occurs when deep linking directly to the modify page.
             */
            alertMessage.renderMessage(this, alertResponse.action, confirmResponse);
        } else {
            // handles a normal detail response
            this.alertRegion.show(alert.success(alertResponse));
        }
    },

    load() {
        const isNotInsert = this.mode !== constants.MODES.INSERT;
        const rowGridModel = store.get(`${this.contextKey}-actionModel`);

        if (isNotInsert) {
            const metadrivenModel = new Model();

            metadrivenModel.id = this.userGroup + this.userId;
            metadrivenModel.context = rowGridModel ? rowGridModel.context : this.contextDef;
            metadrivenModel.set({
                USERGROUP: this.userGroup,
                USERID: this.userId,
                EMAILADDRESS: this.emailAddress,
                id: metadrivenModel.id,
            });

            DataApi.model.generateModelFromModel(metadrivenModel).then((newModelParam) => {
                const newModel = newModelParam;
                newModel.fetch({
                    /*
                     * The response contains data for the assigned roles separately
                     * from the model attributes
                     * This extra config allows us to attach that data to the model,
                     *    instead of it being discarded by the MDF load
                     */
                    extraParsing(data, response) {
                        const rawRoles = util.find(
                            response.grids,
                            rec => rec.name === 'ActionRoles',
                        ).items;
                        newModel.roles = util.map(
                            rawRoles,
                            rawRole => transform.pairsToHash(rawRole.item),
                        );
                    },

                    success: () => {
                        this.model = newModel;
                        if (this.isDeepLinked && this.isTxClient) {
                            actionUtils.getUserActionList(newModel, (buttons) => {
                                this.buttons = buttons;
                                this.loadSuccess();
                            });
                        } else {
                            this.loadSuccess();
                        }
                    },
                });
            });
        } else {
            this.model = new Model({
                USERGROUP: this.userGroup,
                USERID: this.userId,
            });

            this.setHasLoadedRequiredData(true);
            this.loadUsergroup();
        }
    },

    loadSuccess() {
        this.filterButtons();
        this.setHasLoadedRequiredData(true);
        this.loadUsergroup();
    },

    // Also renders the grid, because of this function's location in the above load
    loadUsergroup() {
        const userGroups = new UserGroupCollection({
            isAdmin: this.isAdmin,
        });

        const lookup = new UserGroupLookup({
            isAdmin: this.isAdmin,
        });

        lookup.setSearch(this.userGroup);
        lookup.send().then((result) => {
            const recs = new Collection();

            recs.reset(userGroups.parse(result.data));
            this.model.userGroupModel = recs.get(this.userGroup);
            const mobileUserProperty = util.findWhere(
                this.model.userGroupModel.get('mapDataList'),
                {
                    toField: 'MOBILEMFA_ENABLED',
                },
            );
            this.model.userGroupModel.set('isMobileDeviceEnabled', !(util.isNullOrUndefined(mobileUserProperty) || mobileUserProperty.value === '0'));

            const paymentApprovalType = util.findWhere(this.model.userGroupModel.get('mapDataList'), {
                toField: 'PaymentApprovalType',
            });
            this.hasPanelApproval = paymentApprovalType.value === '1';
            this.render();
        });
    },

    /**
     * save the user
     */
    save() {
        const isModify = this.mode !== constants.MODES.INSERT;
        const { model } = this;
        let saveObject = {};
        const rowGridModel = store.get(`${this.contextKey}-actionModel`);

        // Validate form before saving
        if (model.isValid()) {
            if (this.isAdmin) {
                if (isModify) {
                    /*
                     * rowGridModel only exists when modifying user from user list
                     * view, undefined when accessed through contextual deeplink
                     */
                    if (!util.isNullOrUndefined(rowGridModel)) {
                        model.set('PARENTUSERGROUP', rowGridModel.get('PARENTUSERGROUP'));
                    }
                } else {
                    model.set('PARENTUSERGROUP', this.parentUserGroup);
                }
            }

            model.set('UPDATECOUNT__', model.get('UPDATECOUNT__') || '0');
            model.set('STRIKES', model.get('STRIKES') || '0');

            // Unset the password if data is in modify mode
            const password = model.get('PASSWORD');
            if (isModify && !password) {
                model.unset(
                    'PASSWORD',
                    {
                        silent: true,
                    },
                );
            }
            this.ui.saveUser.attr('aria-busy', true);

            saveObject = this.createSaveObject();
            if (isModify) {
                /*
                 * This item indicates to the server to skip
                 * reading children when re-reading the model
                 */
                saveObject.item.item.push({
                    name: 'readChildren',
                    value: 'false',
                });
            }

            /*
             * Save with direct ajax call, model is metadriven and also
             * nested so cannot save via the model
             */
            http.post(
                services.generateUrl(`userMaintenance/user/${isModify ? 'update' : 'add'}`), saveObject,
                // Success
                (result) => {
                    const action = isModify ? 'Update' : 'Add';
                    let isReturnable = false;
                    if (result.errorCode && result.errorCode !== constants.USER_AUTOAPPROVE_ERROR) {
                        this.ui.saveUser.attr('aria-busy', false);
                        alertMessage.renderMessage(this, 'save', result);

                        util.defer(() => {
                            scroll.scrollToFirstError();
                        });

                        // If this is deeplink than we need to scroll to error
                        if (window.parent !== window) {
                            isReturnable = true;
                            moveToTopCheck(this.model);
                        } else {
                            return;
                        }
                    }
                    // For Deeplink make sure to postMessage for TreasuryXpress
                    if (window.parent !== window) {
                        const userStore = store.get('uce-load');
                        const ssoidVal = userStore ? userStore.ssoId : {};
                        const parentIframeUrl = window.location.ancestorOrigins !== undefined ? window.location.ancestorOrigins[0] : '*';
                        const message = result.confirms.confirmResults[0].messages[0];
                        const responseToTx = {
                            action,
                            isSuccess: !isReturnable,
                            message,
                            ssoId: ssoidVal,
                            userExists: isModify,

                        };
                        window.parent.postMessage(responseToTx, parentIframeUrl);
                        if (isReturnable) {
                            return;
                        }
                    }

                    store.set(
                        'addUserCentricSuccessMessage',
                        {
                            action: 'save',
                            response: result,
                        },
                    );
                    this.ui.saveUser.attr('aria-busy', false);
                    this.navigateTo(this.options.returnRoute);
                },
                // Failure
                (userModel, response) => {
                    this.ui.saveUser.attr('aria-busy', false);
                    AdminUtil.errorAlert(this.alertRegion, response.responseJSON);
                },
            );
        } else {
            // Switch to user tab to show validation issues
            this.changeStep('user');
        }
    },

    modify() {
        store.set('uce-load', {
            userGroup: this.userGroup,
            userId: this.userId,
            buttons: this.buttons,
            entitlement: this.entitlement,
            hasSimulate: this.hasSimulate,
        });
        this.navigateTo(constants.ROUTES.MODIFY_ROLE_USER);
    },

    delete() {
        this.doAction(constants.ACTIONS.DELETE);
    },

    disable() {
        this.doAction(constants.ACTIONS.DISABLE);
    },

    approve() {
        this.doAction(constants.ACTIONS.APPROVE);
    },

    restore() {
        this.doAction(constants.ACTIONS.RESTORE);
    },

    doAction(action) {
        const options = {
            success: (response) => {
                this.doActionResponse(action, response);
            },

            error: (response) => {
                this.doActionResponse(action, response);
            },
            context: this.options.context,
        };

        this.model.sync(action, this.model, options);
    },

    doActionResponse(action, response) {
        const alertResponse = {
            action,
            response,
        };
        store.set('addUserCentricSuccessMessage', alertResponse);
        if (this.isDeepLinked && this.isTxClient) {
            this.setHasLoadedRequiredData(false);
            this.render();
        } else {
            this.navigateTo(this.options.returnRoute);
        }
    },

    simulate() {
        const model = store.get(`${this.contextKey}-actionModel`);
        actionUtils.simulateUser(model, (response) => {
            const alertResponse = {
                action: 'SIMULATE',
                response,
            };
            this.renderMessage(alertResponse);
        });
    },

    copyfromuser() {
        actionUtils.addUser(false, this.userGroup, this.parentUserGroup, this.userId);
    },

    updset() {
        this.navigateTo(constants.ROUTES.UPDATE_USER_SETTING);
    },

    resetpw() {
        const model = store.get(`${this.contextKey}-actionModel`);
        actionUtils.resetPassword(this, model, () => {
            if (this.isDeepLinked && this.isTxClient) {
                this.renderMessage(locale.get('panel.ResetPwd'));
            } else {
                store.set('addUserCentricSuccessMessage', {
                    action: 'RESETPW',
                    response: {
                        message: locale.get('panel.ResetPwd'),
                        confirms: {
                            totalSuccess: 1,
                            totalFail: 0,
                            confirmResults: [],
                        },

                    },
                });
                this.navigateTo(this.options.returnRoute);
            }
        });
    },

    createSaveObject() {
        return {
            item: this.model.convertModelAttributesToServerJSON(this.model, 'POST'),

            grids: [{
                name: 'ActionRoles',
                items: this.createRoleItems(),
            }],
        };
    },

    createRoleItems() {
        const items = (this.assignRolesLayout.targetCollection || []).map(selectedRole => ({
            item: [{
                name: 'ROLEID',
                value: selectedRole.get('id'),
            }, {
                name: 'ROLETYPE',
                value: selectedRole.get('roleType'),
            }],
        }));

        util.each(this.assignRolesLayout.bankMaintainedRoles, (role) => {
            items.push({
                item: [{
                    name: 'ROLEID',
                    value: role.get('id'),
                }, {
                    name: 'ROLETYPE',
                    value: role.get('roleType'),
                }],
            });
        });

        return items;
    },

    handleToggleButtonClick(e) {
        this.changeStep(e.currentTarget.getAttribute('data-step'));
    },

    handleKeyPress(e) {
        // enter key
        if (e.which === 13) {
            this.changeStep(e.currentTarget.getAttribute('data-step'));
        }
    },

    changeStep(step) {
        // PCM RELATED
        if (step === 'user') {
            this.formRegion.show(this.userProfileForm);
            if (this.mode === constants.MODES.VIEW) {
                const ptxContainer = this.getPtxGroupContainer();
                if (ptxContainer) {
                    ptxContainer.get(0).children[0].hidden = true;
                }
            } else {
                this.renderRoleDropdownComponent();
            }
        } else {
            this.formRegion.show(this.assignRolesLayout);
        }
        this.ui.userNote.toggle(step === 'user');
        this.ui.$navLinks.parent().removeClass('is-active');
        this.ui.$navLinks.filter(`[data-step=${step}]`).parent().addClass('is-active');

        this.currentTabText = this.ui.$navLinks.filter(`[data-step=${step}]`).text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }

        // Show validations when tabbing to user
        if (step === 'user') {
            this.model.isValid();
        }
    },

    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    templateHelpers() {
        return {
            buttons: this.buttons,
            modify: this.mode === constants.MODES.MODIFY,
            readOnly: this.mode === constants.MODES.VIEW,

            getPageTitle() {
                return locale.get('menuitem.SETUP.UserMaint');
            },
            insert: this.mode === constants.MODES.INSERT,
            isDeepLinked: this.isDeepLinked,
            isProfileTabRequired: this.isProfileTabRequired,
        };
    },
});
