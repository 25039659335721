var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"pd-btns\">\n        <div class=\"pd-btn-wrap\">\n                <button type=\"button\" class=\"btn-payment btn-existing-recipient-qeg\" id=\"btnExistingRecipientQEG\">\n                        <span class=\"payment-icon\" role=\"presentation\"></span>\n                        <span class=\"payment-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.useAnExistingRecipient",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":97}}}))
    + "<br>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.quickEntryGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":101},"end":{"line":5,"column":140}}}))
    + "</span>\n                </button>\n        </div>\n        <div class=\"pd-btn-wrap\">\n                <button type=\"button\" class=\"btn-payment btn-make-new-payment\" id=\"btnMakeNewPayment\">\n                        <span class=\"payment-icon\" role=\"presentation\"></span>\n                        <span class=\"payment-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.makeNewPayment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":51},"end":{"line":11,"column":89}}}))
    + "</span>\n                </button>\n        </div>\n        <div class=\"pd-btn-wrap\">\n                <button type=\"button\" class=\"btn-payment btn-upload-copy-payment\" id=\"btnUploadCopyPayment\">\n                        <span class=\"payment-icon\" role=\"presentation\"></span>\n                        <span class=\"payment-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.uploadCopyPayment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":51},"end":{"line":17,"column":92}}}))
    + "</span>\n                </button>\n        </div>\n        <!-- End remove -->\n</div>\n";
},"useData":true});