import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

const AccountModel = Model.extend({
    idAttribute: 'KEY_FIELD',
});

export default Collection.extend({
    model: AccountModel,

    initialize(models, options) {
        this.VENDORID = options.VENDORID;
    },

    sync(method, collection, options) {
        let xhr;

        if (method === 'read' && options.FROMACCTTYPE) {
            const url = services.generateUrl('/inquiry/getQueryResults');

            const request = {
                requestHeader: {
                    queryOffset: 0,
                    queryPagesize: 0,
                    requestId: '',
                },

                IncludeMapData: 1,

                queryCriteria: {
                    action: {
                        typeCode: '*',
                        entryMethod: 0,
                        actionMode: 'SELECT',
                        productCode: 'PAY',
                        functionCode: 'INST',
                    },

                    inquiryId: '19814',

                    customFilters: [{
                        filterName: 'Depends',
                        filterParam: ['FROMACCTTYPE', options.FROMACCTTYPE],
                    }, {
                        filterName: 'Depends',
                        filterParam: ['VENDORID', this.VENDORID],
                    }],

                    allowDuplicates: true,
                    queryType: 'Query',
                },

                formatterType: 'Query',
            };

            xhr = http.post(url, request, options.success, options.error);
        }

        this.trigger('request', collection, xhr, options);
        return xhr;
    },

    parse(res) {
        let parsed = [];
        if (res.queryResponse
            && res.queryResponse.QueryData
            && res.queryResponse.QueryData.queryRows) {
            parsed = util.map(res.queryResponse.QueryData.queryRows, obj => transform.pairsToHash(obj.mapDataList, 'toField', 'value'), this);
        }

        return parsed;
    },

    getComboBoxArray() {
        return this.map(model => ({
            id: model.get('KEY_FIELD'),
            text: model.get('BENE_ACCOUNT'),
        }));
    },

    getPrimaryAccount() {
        return this.findWhere(model => model.get('PRIMARY_ACCOUNT') === 'Y');
    },
});
