import locale from '@glu/locale';
import constants from 'app/administration/constants';
import Entitlements from 'app/administration/collection/user2/entitlements';
import EntitlementView from '../permissionsGroupView';
import inboundRequestsTmpl from './inboundRequests.hbs';

export default EntitlementView.extend({
    template: inboundRequestsTmpl,
    className: '',

    initialize(opts) {
        this.entitlements = new Entitlements(this.model.get('types')
            .filter(type => type.id === 'REQIN')
            .map((type) => {
                const entitlement = type.get('entitlements').at(0);
                entitlement.label = locale.get(type.get('label'));
                return entitlement;
            }));

        if (opts.mode === constants.MODES.VIEW) {
            this.entitlements.reset(this.entitlements.filter(type => type.isEntitled()));
        }
    },
});
