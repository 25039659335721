import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import transform from 'common/util/transform';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    initialize(options) {
        this.eid = options.envelopeDetails.eid;
        this.endpoint = options.envelopeDetails.endpoint;
        this.typeCode = options.envelopeDetails.typeCode;
        this.filter = options.envelopeDetails.filter;
    },

    sync(method, model, options) {
        const payload = {
            item: [{
                name: 'ENVELOPE_ID',
                value: this.eid,
            }],
        };

        http.post(services.generateUrl(`lockbox/${this.endpoint}/read`), payload, (result) => {
            options.success(result);
        }, httpError.getResult(options, model));
    },

    parse(results) {
        return transform.pairsToHash(results.item);
    },
});
