var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label class=\"checkbox-inline\">\n                                <input type=\"checkbox\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"itemTypeCode") || (depth0 != null ? lookupProperty(depth0,"itemTypeCode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemTypeCode","hash":{},"data":data,"loc":{"start":{"line":28,"column":62},"end":{"line":28,"column":78}}}) : helper)))
    + "\" name=\"lockboxType\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":110},"end":{"line":28,"column":118}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":120},"end":{"line":28,"column":128}}}) : helper)))
    + "\n                            </label><br/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.settings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":41}}}))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"glu-container\">\n            <div class=\"row\">\n                <div class=\"col-3\">\n                    <div data-region=\"comboBoxRegion\"></div>\n                </div>\n            </div>\n            <div class=\"row lbx-search\">\n                <div class=\"col-3\">\n                    <div class=\"form-group\">\n                        <label for=\"VALUE_DATE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.date.plural",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":48},"end":{"line":15,"column":80}}}))
    + "</label><br/>\n                        <input id=\"VALUE_DATE\" name=\"VALUE_DATE\" type=\"text\" size=\"10\" class=\"input-date\" data-bind=\"model\" autocomplete=\"off\"/>\n                        <span id=\"credit-value-date\" class=\"help-block\" data-bind=\"model\" data-validate=\"VALUE_DATE\"></span>\n                        <span id=\"credit-value-date\" class=\"help-block\" data-bind=\"model\" data-validate=\"LBXMAXDAYS\"></span>\n                        <span class=\"field-info help-block pull-left\"></span>\n                    </div>\n                </div>\n                <div class=\"col-5\">\n                    <div class=\"transaction-type-list form-group\" name=\"VALUE_TRANSACTION_TYPES\" data-validate=\"VALUE_TRANSACTION_TYPES\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.transaction.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":31},"end":{"line":24,"column":68}}}))
    + "</label><br/>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"lockboxTypes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":30,"column":33}}})) != null ? stack1 : "")
    + "                        <span data-bind=\"model\" data-validate=\"VALUE_TRANSACTION_TYPES\"  class=\"field-info help-block pull-left\"></span>\n                    </div>\n                </div>\n                <div class=\"col-3\">\n                    <div class=\"form-group\">\n                        <label for=\"VALUE_LOCKBOXLIST\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LocationsAndLockboxes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":55},"end":{"line":36,"column":93}}}))
    + "</label><br/>\n                        <div data-region=\"locationLockboxesRegion\" name=\"VALUE_LOCKBOXLIST\"></div>\n                        <span data-bind=\"model\" data-validate=\"VALUE_LOCKBOXLIST\"  class=\"field-info help-block pull-left\"></span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row lbx-criteria hidden\">\n                <div class=\"col-12\">\n                    <form class=\"form-inline\">\n                        <div class=\"form-group\">\n                            <label for=\"exampleInputName2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.reportCriteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":59},"end":{"line":46,"column":90}}}))
    + "</label>\n                            <button type=\"submit\" data-action=\"toggleSearchCriteria\" class=\"btn btn-tertiary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.edit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":110},"end":{"line":47,"column":134}}}))
    + "</button>\n                        </div>\n                    </form>\n                </div>\n                <div class=\"col-3\">\n                    <div class=\"form-group\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.date.plural",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":31},"end":{"line":53,"column":63}}}))
    + "</label>\n                        <p class=\"form-control-static\" data-hook=\"static_date\"></p>\n                    </div>\n                </div>\n                <div class=\"col-5\">\n                    <div class=\"form-group\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.transaction.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":31},"end":{"line":59,"column":68}}}))
    + "</label>\n                        <p class=\"form-control-static\" data-hook=\"static_types\"></p>\n                    </div>\n                </div>\n                <div class=\"col-3\">\n                    <div class=\"form-group\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.LocationsAndLockboxes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":31},"end":{"line":65,"column":69}}}))
    + "</label>\n                        <p class=\"form-control-static\" data-hook=\"static_lbx\"></p>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-12\">\n                    <button type=\"button\" data-action=\"submit\" class=\"btn btn-primary\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":105},"end":{"line":72,"column":131}}}))
    + "</span></button>\n                    <button type=\"button\" data-action=\"resetReport\" class=\"btn btn-secondary\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":112},"end":{"line":73,"column":137}}}))
    + "</span></button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});