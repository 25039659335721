import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import ListView from 'common/dynamicPages/views/workflow/list';
import checkManagementConstants from 'app/checkManagement/constants';
import constants from 'common/dynamicPages/api/constants';
import PrintExportUtil from 'common/util/printExportUtil';
import PrintOptionsModal from 'common/modals/printOptions';
import workspaceHelper from 'common/workspaces/api/helper';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';

const PaymentFraudControlList = ListView.extend({
    id: 'pmtFraudCtrlTodaysDecisions',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'pmtFraudCtrlTodaysDecisionsTab',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'printDecision',
        },
    ),

    initialize(options) {
        const superOptions = {
            serviceName: null,
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'CM_FRAUD_CTRL',
            returnRoute: this.options.returnRoute,
            selector: 'none',
            additonalLVCKey: 'CM_FRAUD_CTRL',
        };
        const optionWithGrid = { ...options, gridOptions: { caption: locale.get('CM.todays-decisions') } };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, optionWithGrid));
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_20578,
            summaryInquiryId: constants.INQUIRY_ID_20578,
            hasSummarySelection: false,
            exportURL: '/cm/executeListViewExport',
        };
    },

    export() {
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: 20578,
            format: 'CSV',
            exportURL: '/cm/executeListViewExport',
        };

        PrintExportUtil.export(options);
    },
    printDecision() {
        const printOptions = this.getPrintOptions();
        const rows = this.gridView.grid.getSelectedRows();
        const defaultOptions = {
            selectedRows: rows.length,
            totalItems: this.gridView.grid.masterCollection.totalCount,
        };
        const options = util.extend(defaultOptions, printOptions);

        this.printOptionsModal = new PrintOptionsModal(options);
        /**
         * Adding a if condition, because pop-up shouldn't be visible only for
         * Todays's Decision Tab
         */
        if (this.gridView.context.typeCode === '*') {
            this.showPrintViewModal(this.printOptionsModal.model);
        } else {
            this.showPrintOptionsModal();
        }
    },

    gridRowSelect(options) {
        return this.gridRowModify(options);
    },

    gridRowModify(options) {
        const type = options.model.get('TYPE');

        store.set(`${this.contextKey}-actionModel`, options.model);
        store.set(`${this.contextKey}-returnRoute`, this.options.returnRoute);
        store.set('cm_listView_payDecision-viewAll', false);

        if (type === checkManagementConstants.POS_PAY_TYPE) {
            this.navigateTo('RISK/positivePayDecision');
        } else if (type === checkManagementConstants.REVERSE_POS_PAY_TYPE) {
            this.navigateTo('RISK/reversePositivePayDecision');
        } else if (type === checkManagementConstants.ELECTRONIC_POS_PAY_TYPE) {
            this.navigateTo('RISK/ePositivePayDecision');
        }
        return Promise.resolve();
    },

    gridRowCustomAction(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        store.set(`${this.contextKey}-returnRoute`, this.options.returnRoute);
        this.navigateTo('RISK/decisionHistory');
        return Promise.resolve();
    },

    templateHelpers() {
        const obj = ListView.prototype.templateHelpers.call(this, undefined);

        return util.extend(
            obj,
            {
                getString(type) {
                    return locale.get(`fraud.listView_${type}`);
                },

                hideSubHeader: true,
            },
        );
    },
});

let list = PaymentFraudControlList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list);
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'POSPAY_ACCOUNT_LIST',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
