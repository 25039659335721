import ItemView from '@glu/core/src/itemView';
import template from './filterCellView.hbs';

export default ItemView.extend({
    template,

    events: {
        'click a': 'handleClick',
    },

    initialize(options) {
        this.column = options.column;
    },

    handleClick(e) {
        e.stopPropagation();
        this.model.collection.trigger(`grid:filter:${this.column.get('fieldName')}`, this.getValue());
    },

    templateHelpers() {
        return {
            getValue: this.getValue(),
        };
    },

    getValue() {
        return this.model.get(this.column.get('fieldName'));
    },
});
