import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import ListView from 'common/dynamicPages/views/workflow/list';
import http from '@glu/core/src/http';
import services from 'services';
import errorHandlers from 'system/error/handlers';
import gridApi from 'common/dynamicPages/api/grid';
import constants from 'common/dynamicPages/api/constants';
import filterUtil from 'common/util/filterUtil';
import workspaceHelper from 'common/workspaces/api/helper';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { setGridActionData, getAlertMessage, removeAlertMessage, resetFormState } from 'common/util/reduxUtil';
import template from './listStops.hbs';

const StopManagementList = ListView.extend({
    template,

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-hook="fileimport-button"]': 'fileimport',
        },
    ),

    initialize(options) {
        const superOptions = {
            serviceName: 'null',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_CM_IV_LIST',
            returnRoute: 'PAYMENTS/manageStops',
        };
        // Why is this a different return route when coming from notifications widget
        if (options.viewId) {
            superOptions.returnRoute = 'PAYMENTS/listStops';
        }
        this.returnRoute = superOptions.returnRoute;
        store.set('cm_stopPaymentImportlistView-tab', 'individual');
        this.keyOverride = 'cm_listView_importResultsStopPaymentImport';
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        if (options.filters) {
            this.lvc.set('FILTERFIELDS', filterUtil.formatFilters(options.filters), true);
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            /**
             * right now we are supporting both bb store and react redux state to contain the
             * success/failure message
             */
            let alertMessage = store.get(`${this.contextKey}-alertMessage`) || store.get(`${this.keyOverride}-alertMessage`);
            let confirms = store.get(`${this.contextKey}-confirms`) || store.get(`${this.keyOverride}-confirms`);

            const alertMsgContext = getAlertMessage(this.contextKey);
            const alertMsgOverride = getAlertMessage(this.keyOverride);
            if (alertMsgContext && Object.keys(alertMsgContext).length) {
                ({ alertMessage, confirms } = alertMsgContext);
            }
            if (alertMsgOverride && Object.keys(alertMsgOverride).length) {
                ({ alertMessage, confirms } = alertMsgOverride);
            }
            this.renderMessage(alertMessage, confirms);
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.keyOverride}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
            store.set(`${this.keyOverride}-confirms`, null);
            removeAlertMessage(this.contextKey);
            // clear mdf-react state
            resetFormState();

            this.listenTo(this.gridView.wrapper.rows, 'sync', this.updateRefreshTimestamp);
        } else {
            this.loadViewRequirements();
        }
    },

    isPlaceStop(model) {
        return model.get('TYPE') === 'STOP';
    },

    gridRowSelect(opts) {
        const options = {
            ...opts,
            ...(this.isPlaceStop(opts.model) ? {
                mfeComponent: 'StopPaymentsForm',
            } : {}),
        };

        if (options.action === 'select' && options.model.get('SERIALNUM_TO') && options.model.get('SERIALNUM_TO') !== '' && ['Bank Confirmed', 'Rejected', 'Partial Success'].indexOf(options.model.get('STATUS_DESCRIPTION')) > -1) {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('CM/stopCancelCheckView');
        } else {
            ListView.prototype.gridRowSelect.call(this, options);
        }
        return Promise.resolve();
    },

    gridRowModify(opts) {
        const options = {
            ...opts,
            ...(this.isPlaceStop(opts.model) ? {
                mfeComponent: 'StopPaymentsForm',
            } : {}),
        };
        ListView.prototype.gridRowModify.call(this, options);
    },

    /**
     * @method gridRowCustomAction
     * @param {object} options - containing the action and the model
     * - This method handles that custom grid actions, in this case CANCELSTOP.
     * - This method should work just like insert but the values in the detail
     * page should be pre populated with the values from the grid row.
     * - The use of cache is unavoidable as the MDF expects the model to be available
     * in the cache for using in the detail page.
     * just like all the methods in the parent file list.js
     */

    gridRowCustomAction(options) {
        let action;
        if (options.action) {
            action = options.action.toUpperCase();
        }

        if (action === 'CANCELSTOP') {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.insertCancel();
        }
        return Promise.resolve();
    },

    /**
     * store context info for action. action will unset overrideContext
     * action for CANCEL comes as SELECT, but gets changed to INSERT on non-MDF
     * flow via metadriven flow
     * need QueryProcessing to send right actionMode and not SELECT when INSERT
     * action for CustomFiltering
     * so overriding action here to INSERT for mdf STOP
     * MODIFY Stop does not come via this flow and gets updated to MODIFY correctly
     * using gridAction
     */
    insertStop() {
        const overrideContext = util.extend(
            this.contextDef,
            {
                serviceName: '/cminst/placeStop',
            },
        );
        overrideContext.actionContext.actionMode = 'INSERT';
        overrideContext.actionMode = 'INSERT';
        store.set('listView-contextKey', this.contextKey);
        store.set('multiAdd-contextKey', `${this.contextKey}-contextOverride`);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        overrideContext.returnRoute = this.returnRoute;

        setGridActionData({
            ...overrideContext,
            typeCode: 'STOP',
            mode: 'insert',
            contextKey: this.contextKey,
        });

        this.navigateTo('CM/multiAdd/STOP');
        return Promise.resolve();
    },

    insertCancel() {
        const overrideContext = util.extend(
            this.contextDef,
            {
                serviceName: '/cminst/cancelStop',
            },
        );
        this.insertOverride(overrideContext);
        return Promise.resolve();
    },

    loadViewRequirements() {
        const url = services.generateUrl('/cm/listView/stopCancels/getEntitledActionList');
        const options = this.viewOptionRequirements();
        options.gridOptions = { caption: this?.contextDef?.name };
        const gridView = gridApi.createServiceGridView(options);
        let entResActions = this.entitledActionList;

        this.gridView = gridView;
        ListView.prototype.setupGridAvailableListener.call(this);

        this.localeKey = 'cm.stopCancels_';

        return new Promise((resolve) => {
            http.post(url, {}, (response) => {
                this.entitledActionList = response;
                entResActions = this.entitledActionList;
                entResActions.IMPORTRESULTS = util.contains(entResActions[2].actionModes, 'SELECT');
                resolve(response);
            });
        }).then(() => {
            this.entitlements = entResActions;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
                this.listenForGridErrors();
                this.setHasLoadedRequiredData(true);
                this.render();
            } else {
                const helpers = this.getEntitlementHelpers();
                return {
                    actions: {
                        APPROVE: helpers.hasApproveEntitlement,
                        DELETE: helpers.hasDeleteEntitlement,
                        CANCEL: helpers.hasCancelInsertEntitled,
                        IMPORT: helpers.hasFileImportEntitlement,
                        REJECT: helpers.hasStopInsertEntitled,
                    },
                };
            }
            return entResActions;
        }, util.bind(errorHandlers.loading, this));
    },

    hasActionEntitled(type, action) {
        const self = this;
        if (!self.entitledActionList || !action) {
            return false;
        }

        const item = util.findWhere(
            self.entitledActionList,
            {
                typeCode: type,
            },
        );
        return (item && item.actionModes && util.contains(item.actionModes, action));
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(this, undefined);
        return util.extend(
            obj,
            self.getEntitlementHelpers(),
        );
    },

    getEntitlementHelpers() {
        const self = this;
        return {
            hasStopInsertEntitled() {
                return self.hasActionEntitled('STOP', 'INSERT');
            },

            hasCancelInsertEntitled() {
                return serverConfigParams.get('showCancelButtonOnStopPayments') !== 'false' && self.hasActionEntitled('CANCEL', 'INSERT');
            },

            hasFileImportEntitlement() {
                return self.hasActionEntitled('STCAIMPT', constants.ACTION_IMPORT);
            },

            hasApproveEntitlement() {
                return self.hasActionEntitled('STOP', constants.ACTION_APPROVE) || self.hasActionEntitled('CANCEL', constants.ACTION_APPROVE) || self.hasActionEntitled('STCAIMPT', constants.ACTION_APPROVE);
            },

            hasDeleteEntitlement() {
                return self.hasActionEntitled('STOP', constants.ACTION_DELETE) || self.hasActionEntitled('CANCEL', constants.ACTION_DELETE) || self.hasActionEntitled('STCAIMPT', constants.ACTION_DELETE);
            },
        };
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_20577,
            summaryInquiryId: constants.INQUIRY_ID_20577,
            hasSummarySelection: false,
        };
    },

    export() {
        const options = this.getPrintOptions();
        const selectedRow = this.getSearchFieldsByKeyList(this.gridView);
        options.context = 'PAY_CM_IV_LIST';
        options.returnRoute = 'PAYMENTS/manageStops';
        options.format = 'CSV';
        options.gridView = this.gridView;
        // check if we have any selected row
        if (selectedRow.length) {
            options.searchFields = selectedRow;
        }
        options.exportModel = this.buildExportModel(options);
        this.doExport(options);
    },

    fileimport() {
        const importContext = {
            serviceName: '/checkfimport/import',
            actionMode: 'IMPORT',
            keyOverride: this.keyOverride,
            productCode: 'CM',
            typeCode: 'STCAIMPT',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        store.set('cm_stopPaymentImportlistView-tab', 'individual');
        this.navigateTo(this.contextModel.getImportUrl());
    },
});

let list = StopManagementList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'cm.button_insert_stop',
                entitlement: 'REJECT',
                handlerMethodName: 'insertStop',
            },
            {
                label: 'cm.button_insert_cancel',
                entitlement: 'CANCEL',
                handlerMethodName: 'insertCancel',
            },
        ],
        bulkActions: [
            {
                label: 'cm.button_approve',
                entitlement: constants.ACTION_APPROVE,
            },
            {
                label: 'cm.button_delete',
                entitlement: constants.ACTION_DELETE,
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'PAY_CM_IV_LIST',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
