import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import CollectionView from '@glu/core/src/collectionView';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import util from '@glu/core/src/util';
import TypeLimitsView from './typeLimitsView';
import overallLimitsLayoutTmpl from './overallLimitsLayout.hbs';

export default Layout.extend({
    template: overallLimitsLayoutTmpl,
    itemViewContainer: '.list-container',
    className: 'approval-limits-region',

    ui: {
        infoIcon: '.btn .icon-info',
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    regions: {
        typeLimitsByGroupRegion: '[data-hook="typeLimitsByGroupRegion"]',
    },

    onRender() {
        createTabsToggleButton(this);
        this.modifyMode = this.options.mode === 'insert' || this.options.mode === 'modify';
        this.isCannotGrantBeyondOwnUser = this.options.isCannotGrantBeyondOwnUser;
        this.itemViewOptions = {
            mode: this.options.mode,
            modifyMode: this.modifyMode,
            isCannotGrantBeyondOwnUser: this.isCannotGrantBeyondOwnUser,
        };

        // Comment out until we implement group limits
        /*
         *          this.groupLimitsRegion.show(new CollectionView({
         *             collection: this.collection,
         *             itemView: GroupLimitsView,
         *             itemViewOptions: options
         *          }));
         */

        if (this.ui.$navLinks.length > 0) {
            this.ui.$navItems.first().addClass('is-active');
            this.currentTabText = this.ui.$navItems.first().find('a').text();
            setTabButtonText(this, this.currentTabText);
            this.selectedGroupId = this.ui.$navLinks.data().value;
            this.loadLimitsByPaymentGroup(this.selectedGroupId);
        }

        if (this.ui.infoIcon) {
            this.ui.infoIcon.popover({
                content: locale.get('administration.overall.limits.explanation'),
                trigger: 'hover focus',
            });
        }
    },

    loadLimitsByPaymentGroup(groupId) {
        const groupCollection = new Collection(this.collection.findWhere({
            id: groupId,
        }));

        this.typeLimitsByGroupRegion.show(new CollectionView({
            collection: groupCollection,
            itemView: TypeLimitsView,
            itemViewOptions: this.itemViewOptions,
        }));
    },

    showLimitsByPaymentGroup(e) {
        // Validate limits before going to a different limits tab.
        if (!this.validateLimits()) {
            // Stop the event if validation failure.
            e.preventDefault();
            return;
        }

        const $target = this.$(e.currentTarget);
        this.selectedGroupId = e.target.dataset.value;

        this.ui.$navItems.removeClass('is-active');
        $target.parent().addClass('is-active');

        this.currentTabText = $target.text();
        toggleTabs(this);
        this.loadLimitsByPaymentGroup(this.selectedGroupId);
    },

    /**
     * Validates the limits of a CGBO user against
     * the logged in user.
     * @return {boolean}
     * Returns true if limits are valid
     */
    validateLimits() {
        // Need to filter so all models are validated.

        // No need to validate non CGBO users.
        if (!this.isCannotGrantBeyondOwnUser) {
            return true;
        }

        const model = this.collection.get(this.selectedGroupId);

        const invalidLimits = model.getEntitledTypes()
            .filter((typeModel) => {
                const isValid = typeModel.isValid();
                return !isValid;
            });

        return util.isEmpty(invalidLimits);
    },

    templateHelpers() {
        const self = this;
        return {
            label() {
                return locale.get(`administration.${this.get('label')}`);
            },

            groups() {
                return self.collection.filter(group => group.isEntitled());
            },

            modifyMode: this.modifyMode,
        };
    },
});
