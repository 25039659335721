const Constants = {
    // Known Inquiry IDs
    DEFAULT_INQUIRY_ID: 0,

    REPORTS_INQUIRY_ID: 17050,
    ALERTS_INQUIRY_ID: 17051,
    CHECK_MANAGEMENT_ALERT_LIST_INQUIRY_ID: 20550,

    // need to finalize value for payments alerts list.
    PAYMENTS_ALERT_LIST_INQUIRY_ID: 20560,

    CHECK_PRODUCTION_ALERT_LIST_INQUIRY_ID: 21090,
    INFORMATION_REPORTING_ALERT_LIST_INQUIRY_ID: 22300,
    ADMIN_ALERT_LIST_INQUIRY_ID: 9028,
    ALERT_GROUPS_LIST_INQUIRY_ID: 17047,
    ALERT_TYPES_LIST_INQUIRY_ID: 17046,
    STATE_CODES_LIST_INQUIRY_ID: 17097,
    COUNTRY_CODES_LIST_INQUIRY_ID: 17076,
    BANK_CODES_LIST_INQUIRY_ID: 17089,
    CURRENCY_CODES_LIST_INQUIRY_ID: 17084,
    TRANSFER_FROM_ACCOUNT_INQUIRY_ID: 19497,
    TRANSFER_TO_ACCOUNT_INQUIRY_ID: 19498,
    WIRE_DOMESTIC_INQUIRY_ID: 25556,
    WIRE_INTL_INQUIRY_ID: 19483,
    BUSINESS_ACH_INQUIRY_ID: 24954,

    // Search Types
    DEFAULT_SEARCH_TYPE: 2,

    INQUIRY_CONFIGURED_SEARCH_TYPE: 2,
    ACTION_CONFIGURED_SEARCH_TYPE: 5,

    // Type Codes
    DEFAULT_TYPE_CODE: '',

    ALL_TYPE_CODE: '*',
    ADDRESS_BOOK_TYPE_CODE: 'BENEADBK',
    BANK_HOLIDAY_TYPE_CODE: 'CBANK_TM',
    SECURITY_QUESTIONS_TYPE_CODE: 'USECQ_TM',
    CORPORATE_VENDOR_TYPE_CODE: 'BDACHCVP',
    DOMESTIC_WIRE_TYPE_CODE: 'FEDWIRE',
    PAYROLL_TYPE_CODE: 'BDACHP',
    INTERNATIONAL_TYPE_CODE: 'INTL',
    TRANSFER_TYPE_CODE: 'TRANSFER',

    // Sub Type Codes
    NACHA_SUB_TYPE_CODE: 'NACHA',

    // View Ids
    DEFAULT_VIEW_ID: 0,

    // Column Types
    COLUMN_TYPE_HIDDEN: 6,

    COLUMN_TYPE_STRING: 0,
    COLUMN_TYPE_ACTION_LIST: 32,

    // apparently webseries has two types of action list columns.
    COLUMN_TYPE_ENTITLEMENT_ACTION_LIST: 38,

    COLUMN_TYPE_ACCESSIBILITY_ACTION_LIST: 39,
    COLUMN_TYPE_CHECKBOX: 5,

    // Query Results
    DEFAULT_QUERY_PAGE_SIZE: 0,

    DEFAULT_QUERY_OFFSET: 0,

    // Action Modes
    INSERT_ACTION_MODE: 'INSERT',
    MODIFY_ACTION_MODE: 'MODIFY',
    COPY_ACTION_MODE: 'COPYINST',
    SELECT_ACTION_MODE: 'SELECT',

    // Function Codes
    ALERT_FUNCTION_CODE: 'ALERT',

    MAINT_FUNCTION_CODE: 'MAINT',
    INST_FUNCTION_CODE: 'INST',

    // Product Codes
    DEFAULT_PRODUCT_CODE: '*',

    ADMIN_PRODUCT_CODE: '_ADMIN',
    SECURITY_PRODUCT_CODE: 'SMAINT',
    RTGS_PRODUCT_CODE: 'RTGS',
    ACH_PRODUCT_CODE: 'USACH',

    // Entry Methods
    DEFAULT_ENTRY_METHOD: 0,

    ENTRY_METHOD: 1,

    // Map Data
    INCLUDE_MAP_DATA: 1,

    EXCLUDE_MAP_DATA: 0,

    // Filter Names
    DEFAULT_FILTERS: '',

    FILTER_DEPENDS: 'Depends',

    // Filter Params
    PARAM_TYPE_CODE: '_typeCode',

    PARAM_SUB_TYPE_CODE: '_subTypeCode',

    // Pay a Business Options
    BUSINESS_OPTION_1: 'PAY_BUSINESSOPT1',

    BUSINESS_OPTION_2: 'PAY_BUSINESSOPT2',
    BUSINESS_OPTION_3: 'PAY_BUSINESSOPT3',

    // Pay an Individual options
    INDIVIDUAL_OPTION_1: 'PAY_IND_OPT1',

    INDIVIDUAL_OPTION_2: 'PAY_IND_OPT2',
    INDIVIDUAL_OPTION_3: 'PAY_IND_OPT3',

    // Pay Employees Options
    EMPLOYEE_WAGES: 'PAY_EMPWAGES',

    // Collect Funds Business
    COLL_FUNDS_BUSINESS: 'COLL_FUNDS_BUSI',

    // Collect Funds Business
    COLL_FUNDS_INDIVIDUAL: 'COLL_FUNDS_IND',
};

export default Constants;
