import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import template from './conversationWidget.hbs';

export default BaseWidget.extend({
    template,
    className: 'ui-widget',
    /**
     * @return {Object}
     */
    templateHelpers() {
        // only display widget in view mode when status is Bank Confirmed (BC)
        const status = this.options.model ? this.options.model.get('STATUS') : '';
        const shouldBeDisplayed = (this.options.state === 'view' && status === 'BC');
        // TODO get entitlements for showing the buttons
        return {
            display: shouldBeDisplayed,
            notYetStarted: true,
            showRFI: true,
            showAck: true,
        };
    },
});
