var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div data-region=\"locationOptionsRegion\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <input class=\"form-control\" data-hook=\"getLocationSelection\" data-bind=\"model\" name=\"ACCOUNTFILTER\" readonly></input>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "readonly";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"locationLoginItem-field removeBtn\" data-hook=\"getRemoveBtn\">\n            "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"className":"btn btn-tertiary","name":"close"},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":62}}}))
    + "\n        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"LocationLoginItem\">\n    <div class=\"locationLoginItem-field\">\n        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.clientlocation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":46}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notViewMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"locationLoginItem-field\">\n        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.loginname",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":41}}}))
    + "</label>\n        <input class=\"form-control locationLoginInput\" \n            data-bind=\"model\"\n            name=\"LOGIN\"\n            type=\"text\"\n            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"notViewMode") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":54}}})) != null ? stack1 : "")
    + " />\n        <span class=\"help-block\"  data-bind=\"model\" data-validate=\"LOGIN\"></span>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notViewMode") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});