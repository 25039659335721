var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div>\n    <div class=\"page-header-wrapper\">\n        <div class=\"prev-page-breadcrumb\">\n            <a class=\"return-to-workspace\" href=\"#\" data-action=\"back\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"checkMgmt.back.to.fraud",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":146},"end":{"line":5,"column":182}}}))
    + "</span></a>\n        </div>\n        <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":35},"end":{"line":7,"column":44}}}) : helper)))
    + "</h1>\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n    <section>\n        <div class=\"workbench-container\" data-region=\"workbench\"></div>\n    </section>\n</div> \n";
},"useData":true});