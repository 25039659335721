import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import errorHandlers from 'system/error/handlers';
import userInfo from 'etc/userInfo';
import DeliveryMethodCollection from 'app/payments/collections/deliveryMethods';
import MethodView from 'app/payments/views/photocopy/method';
import store from 'system/utilities/cache';
import alertMessage from 'common/api/alertMessage';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import reqPhotocopyTmpl from './reqPhotocopy.hbs';

export default Layout.extend({
    template: reqPhotocopyTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $method: '.radio input',
        alertRegion: '.alert-region',
    },

    events: {
        'change @ui.$method': 'switchMethod',
    },

    initialize() {
        this.checkModel = store.get('checkInquiry:paidCheck');

        this.model = new Model();
        this.setCheckGridData();
    },

    setCheckGridData() {
        if (this.checkModel) {
            const attr = this.checkModel.attributes;
            if (attr.PIC_SEQUENCE_NUMBER) {
                this.model.set('IMAGEID', attr.PIC_SEQUENCE_NUMBER);
            }
            if (attr.SERIALNUMBER) {
                this.model.set('SERIALNUMBER', attr.SERIALNUMBER);
            }
            if (attr.AMOUNT) {
                this.model.set('AMOUNT', attr.AMOUNT);
            }
            if (attr.VALUE_DATE) {
                this.model.set('PAIDDATE', attr.VALUE_DATE);
            }
            if (attr.PAYEE) {
                this.model.set('PAYEE', attr.PAYEE);
            }
        }

        const self = this;
        const searchFields = store.get('cm_inq_searchFields');
        util.each(searchFields, (searchField) => {
            if (searchField.fieldName === 'ACCOUNTFILTER') {
                self.model.set('ACCOUNTFILTER', searchField.fieldValue[0]);
            }
        });
    },

    onRender() {
        moveToTopCheck(this.model);
        if (this.hasLoadedRequiredData()) {
            const methodView = new MethodView({
                method: this.deliveryMethods.at(0).get('name'),
                checkModel: this.model,
                email: userInfo.get('emailAddress') || false,
            });
            this.listenTo(methodView, 'request:failed', this.handleError);
            this.listenTo(methodView, 'request:success', this.handdleSuccess);
            this.methodRegion.show(methodView);
        } else {
            this.loadRequiredData();
        }
    },

    handleError(model) {
        alertMessage.renderMessage(this, null, model.error, 0, true);
    },

    handdleSuccess(model) {
        store.set('reqPhotocopyConfirm', model.message);
        this.checkModel.trigger('reqPhotoCopySuccess');
    },

    switchMethod() {
        const method = this.$('.delivery-methods:checked').val();
        this.model.set('DELIVERYMETHOD', method);

        const methodView = new MethodView({
            method,
            checkModel: this.model,
        });

        /*
         * After switching the view, original view is closed and everything on it unbound,
         * including listeners. Need to re-create listeners
         */
        this.listenTo(methodView, 'request:failed', this.handleError);
        this.listenTo(methodView, 'request:success', this.handdleSuccess);
        this.methodRegion.show(methodView);
    },

    deliveryMethodPromise() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.deliveryMethods = new DeliveryMethodCollection();
            self.deliveryMethods.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    loadRequiredData() {
        const self = this;
        const deliveryMethodPromise = this.deliveryMethodPromise();

        Promise.all([deliveryMethodPromise]).then(() => {
            self.setHasLoadedRequiredData(true);
            self.render();
        }, util.bind(errorHandlers.loadingModal, self));
    },

    cancel() {
        window.history.back();
    },

    templateHelpers() {
        return {
            methods: this.deliveryMethods ? this.deliveryMethods.toJSON() : [],
        };
    },
});
