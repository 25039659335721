import Collection from '@glu/core/src/collection';
import services from 'services';
import transform from 'common/util/transform';
import { postData } from 'common/util/services';

export default Collection.extend({
    sync() {
        const data = {
            IncludeMapData: 1,
            queryCriteria: {
                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    actionMode: 'INSERT',
                    productCode: 'PAY',
                    functionCode: 'TMPL',
                },
                inquiryId: '42345',
                allowDuplicates: true,
                queryType: 'Query',
            },
            formatterType: 'Query',
        };

        return postData(services.inquiryQueryResults, data)
            .then(this.parse)
            .then(collectionData => this.set(collectionData));
    },

    parse(response) {
        const rows = response.queryResponse.QueryData.queryRows;
        return rows.map((row) => {
            const item = transform.pairsToHash(row.mapDataList, 'toField', 'value');
            return {
                id: item.PANELPROFILECODE,
                name: item.PANELDESCRIPTION,
            };
        });
    },
});
