var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add-payment page-2col\">\n    <div class=\"col\">\n        <p>\n            <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payee",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":48}}}))
    + ":</span><br>\n            <span><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"VENDORNAME") || (depth0 != null ? lookupProperty(depth0,"VENDORNAME") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"VENDORNAME","hash":{},"data":data,"loc":{"start":{"line":5,"column":26},"end":{"line":5,"column":40}}}) : helper)))
    + "</strong></span>\n        </p>\n\n        <p>\n            <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payment.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":55}}}))
    + ":</span><br>\n            <span><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"PAYDATE") || (depth0 != null ? lookupProperty(depth0,"PAYDATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"PAYDATE","hash":{},"data":data,"loc":{"start":{"line":10,"column":26},"end":{"line":10,"column":37}}}) : helper)))
    + "</strong></span>\n        </p>\n\n        <p>\n            <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.delivery.method",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":58}}}))
    + ":</span><br>\n            <span><strong>Expedited (Wire Transfer)</strong></span>\n        </p>\n\n        <p>\n            <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payment.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":18},"end":{"line":19,"column":58}}}))
    + ":</span><br>\n            <span><strong>"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"ACCOUNT") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</strong></span>\n        </p>\n\n        <br><br>\n\n        <p>\n            <label for=\"notifyPayee\">\n                <input type=\"checkbox\" name=\"notifyPayee\">  "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.pending.notification.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":60},"end":{"line":27,"column":109}}}))
    + "\n            </label>\n        </p>\n\n        <p class=\"payment-total-container\">\n            <h3><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payment.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":32,"column":62}}}))
    + ": <span class=\"payment-total\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TOTALAMOUNT") || (depth0 != null ? lookupProperty(depth0,"TOTALAMOUNT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"TOTALAMOUNT","hash":{},"data":data,"loc":{"start":{"line":32,"column":92},"end":{"line":32,"column":107}}}) : helper)))
    + "</span></h3></span>\n        </p>\n    </div>\n</div>\n\n";
},"useData":true});