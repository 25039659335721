import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import fiscalYearSelectTmpl from './fiscalYearSelect.hbs';
import planSelectTmpl from './planSelect.hbs';

const PlanSelect = ItemView.extend({
    className: 'plan-select',
    template: planSelectTmpl,

    initialize(options) {
        this.name = options.name || '';
        this.onlyShowPlans = options.onlyShowPlans;
        this.disabled = !!options.disabled;
        /*
         * this.collection will be a collection of plans that are available
         * this.model is undefined to start
         */
    },

    events: {
        'change select': 'handleSelectChange',
        'change input[type="radio"]': 'handleFiscalYearChange',
    },

    templateHelpers() {
        return {
            id: this.cid,
            name: this.name,
            plans: this.getPlanJSON(),
        };
    },

    toggleDisabled(disabled) {
        /*
         * if we want to change back to a disabled state later, we will need to handle the
         * radio buttons too
         */
        this.disabled = disabled;
        this.$('select').prop('disabled', this.disabled);
    },

    onShow() {
        this.toggleDisabled(this.disabled);
    },

    getPlanJSON() {
        let json = this.collection.toJSON();

        if (this.onlyShowPlans) {
            json = util.reject(json, row => row.usesRealData);
        }

        return json;
    },

    handleSelectChange() {
        if (!this.model) {
            // the first time it runs, model is undefined
            this.$('option[value=""]').remove();
            this.trigger('firstSelectionMade');
        }

        const $option = this.$('select option:selected');
        this.model = this.collection.getByIdAndVersion($option.data('id'), $option.data('version'));

        // TODO should we only attach this if compareData isn't already loaded?
        this.listenToOnce(
            this.model,
            {
                compareDataReady: this.updateRadioButtons,
            },
        );

        /*
         * this allows views to re-render when the model already has data cached
         * TODO make sure this isn't re-rendering when we need to re-fetch actual & forecast
         */
        if (this.model.has('compareArray') && !this.model.get('usesRealData')) {
            this.model.trigger('compareDataReady');
        }

        // this tells the collection that is should fetch compare data on a model
        this.model.trigger('selected', this.model, this);
    },

    updateRadioButtons() {
        if (!this.model.get('usesRealData')) {
            this.$('fieldset').html(fiscalYearSelectTmpl({
                id: this.cid,
                buttons: this.getFiscalYears(),
            }));
        } else {
            this.$('fieldset').empty();
        }
    },

    getFiscalYears() {
        const years = [];
        const yearObjs = [];

        util.each(this.model.get('compareArray'), (month, i) => {
            if (years.indexOf(month.fyYear) === -1) {
                years.push(month.fyYear);
                yearObjs.push({
                    year: month.fyYear,
                    offset: i,
                });
            }
        });

        return yearObjs;
    },

    getFiscalYearOffset() {
        return +this.$('input[type="radio"]:checked').val() || 0;
    },

    getSelectedFiscalYear() {
        return this.$('input[type="radio"]:checked').data('year');
    },

    handleFiscalYearChange() {
        this.model.trigger('fiscalYearChanged', this.model, this);
    },
});

export default PlanSelect;
