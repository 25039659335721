var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"function.ChangePwd",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":62}}}))
    + "</h1>\n</div>\n<form class=\"resetExpiredPassword-page\" data-submit=\"change\">\n    <div data-region=\"alertRegion\"></div>\n    <fieldset class=\"section section-container\">\n        <div class=\"section-body form-container\">\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"a11y-old-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.oldPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":82}}}))
    + "</label>\n                    <input id=\"a11y-old-password\" type=\"password\" class=\"form-control\" data-bind=\"model\" name=\"oldPassword\" autofocus>\n                    <span class=\"help-block oldPassword\" data-bind=\"model\" data-validate=\"oldPassword\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"a11y-new-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.newPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":51},"end":{"line":17,"column":82}}}))
    + "</label>\n                    <input id=\"a11y-new-password\" type=\"password\" data-hook=\"new-password\" class=\"form-control\" data-bind=\"model\" name=\"newPassword\">\n                    <span class=\"help-block newPassword\" data-bind=\"model\" data-validate=\"newPassword\"></span>\n                </div>\n            </div>\n            <div>\n                <div class=\"field-container-lg\">\n                    <label for=\"a11y-confirm-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.confirmPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":55},"end":{"line":24,"column":90}}}))
    + "</label>\n                    <input id=\"a11y-confirm-password\" type=\"password\" data-hook=\"new-password-confirm\" class=\"form-control\" data-bind=\"model\" name=\"confirmPassword\">\n                    <span class=\"help-block confirmPassword\" data-bind=\"model\" data-validate=\"confirmPassword\"></span>\n                </div>\n            </div>\n            <div class=\"password-rules\"></div>\n        </div>\n    </fieldset>\n    <div class=\"section section-container\">\n        <div class=\"section-body\">\n            <div id=\"complete\">\n                <button type=\"submit\" data-hook=\"change-password\" class=\"btn btn-primary\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"panel.ChangePwd",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":48}}}))
    + "\n                </button>\n                <button type=\"button\" class=\"btn btn-link\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":80},"end":{"line":38,"column":106}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});