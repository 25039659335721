import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import $ from 'jquery';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import GridApi from 'common/dynamicPages/api/grid';
import contextApi from 'common/dynamicPages/api/context';
import constants from 'app/reports/constants';
import userinfo from 'etc/userInfo';
import moment from 'moment';
import services from 'services';
import filterApi from 'common/dynamicPages/api/filters';
import workspaceHelper from 'common/workspaces/api/helper';
import SystemIDsCollection from 'app/reports/collections/systemIDs';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import ListView from 'common/dynamicPages/views/workflow/list';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import systemConfig from 'system/configuration';
import helpers from 'components/AsyncReporting/helpers';
import dialog from '@glu/dialog';
import { asView } from 'common/util/reactUtil';
import AsyncPrintViewModal from 'components/AsyncReporting/AsyncPrintViewModal';
import locale from '@glu/locale';
import template from './legacyReportsListView.hbs';
import reportDateTypeTmpl from './reportDateType.hbs';
import searchCriteriaTmpl from './searchCriteria.hbs';

const LegacyReportsListView = Layout.extend({
    loadingTemplate,
    template,
    className: 'legacyReports',

    ui: {
        $searchButton: '#legacySearch',
        $searchFilters: '.legacyReportSearchFilters',
        $searchCriteria: '.legacyReportSearchCriteria',
        $searchData: '.searchData',
        $searchDate: '#LEGACYREPORTDATE',
        $toggleBtn: '[data-hook="getToggleSearchFilterBtn"]',
    },

    events: {
        'click @ui.$searchButton': 'search',
        'click @ui.$toggleBtn': 'toggleSearchFilter',
    },

    initialize() {
        this.collection = new Collection();
        this.hasLoadedFilterOnce = false;
        this.dateType = 'CREATIONDATETIME';
        this.startDate = moment(new Date()).format(userinfo.getDateFormat());
        this.datePickerOptions = {
            allowWeekends: true,
        };
        this.context = contextApi.menuContext.getContext('LEGACY_REPORTS');
        this.context.serviceName = '/legacyReports/reports';
        /**
         * Set suppressSavedViews to true to prevent mobile grid from fetching
         * saved views
         */
        this.suppressSavedViews = true;

        if (mobileUtil.isMobileGridEnabled()) {
            this.createInitialServiceGridView();
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.ui.$searchButton.hide();
            this.ui.$searchFilters.hide();
            this.ui.$searchData.html(searchCriteriaTmpl());
            this.loadGrid();
            this.setInitialDate = true;
        } else {
            this.loadRequiredData();
        }
    },

    getCacheName() {
        return `model-${this.filterContextDef.serviceName}-_ADMIN-*`;
    },

    loadFilter() {
        const requestData = {
            filterId: 22488,

            typeInfo: {
                productCode: '_ADMIN',
                functionCode: '*',
                typeCode: '*',
            },
        };

        const filterContextDef = {};
        filterContextDef.serviceName = '/advanceFilter';
        filterContextDef.productCode = '_ADMIN';
        filterContextDef.functionCode = '*';
        filterContextDef.typeCode = '*';
        this.filterContextDef = filterContextDef;

        const promiseMe = filterApi.getFilters(requestData, this);
        promiseMe.then(() => {
            this.listenToOnce(this.filterRegion.currentView, 'item:rendered', () => {
                this.$el.find('div[data-region="filterRegion"] fieldset.section.section-container').removeClass('section-container');
                this.$el.find('div[data-region="filterRegion"] #LEGACYREPORTDATE').closest('.field-container').append(reportDateTypeTmpl());
                this.ui.$searchButton.show();
                if (!this.hasLoadedFilterOnce) {
                    this.hasLoadedFilterOnce = true;
                    this.toggleSearchFilter();
                }
            });
        });
    },

    search() {
        const filterModel = this.filterRegion.currentView
            ? this.filterRegion.currentView.model : null;
        const additionalSearchFields = filterApi.gatherFilterData(filterModel);
        const context = contextApi.menuContext.getContext('LEGACY_REPORTS');

        this.dateType = this.$('[name="LegacyReportDateType"]:checked').val();

        util.chain(additionalSearchFields).map((searchFieldParam) => {
            const searchField = searchFieldParam;
            if (searchField.fieldName === 'LEGACYREPORTDATE') {
                searchField.fieldName = this.dateType;
                if (searchField.fieldValue.length === 1) {
                    searchField.operator = 'EQ';
                }
                if (this.dateType === 'CREATIONDATETIME') {
                    searchField.dataType = 'gmtdate';
                }
            }
            return searchField;
        }).reject(searchField => searchField.fieldValue === []);

        /*
         * Hardcoded for now. menuContext is null currently. Service will be updated
         * later this sprint
         */
        context.serviceName = '/legacyReports/reports';

        this.createServiceGridView(this.context, additionalSearchFields);

        this.showListView();

        this.showSelectedSearchCriteria();
    },

    showSelectedSearchCriteria() {
        const filterModel = this.filterRegion.currentView.model.toJSON();
        this.ui.$searchData.html(searchCriteriaTmpl(filterModel));
        this.toggleSearchFilter();
    },

    loadRequiredData() {
        const systemIDsPromise = new Promise((resolve, reject) => {
            const systemIDs = new SystemIDsCollection();
            systemIDs.fetch({
                success: resolve,
                error: reject,
            });
        });

        return Promise.all([systemIDsPromise]).then((results) => {
            [this.collection] = results;
            this.setHasLoadedRequiredData(true);
            this.render();
        });
    },

    createInitialServiceGridView() {
        const additionalSearchFields = [{
            fieldName: 'CREATIONDATETIME',
            fieldValue: [this.startDate],
            dataType: 'gmtdate',
            operator: 'EQ',
        }];

        const context = contextApi.menuContext.getContext('RPT_LEGACY');

        context.additonalLVCKey = 'LEGACY_REPORTS'; // misspelling is on purpose
        ListView.prototype.setListViewConfig.call(this, context);

        this.createServiceGridView(context, additionalSearchFields);
    },

    showListView() {
        if (mobileUtil.isMobileGridEnabled()) {
            let MobileList = ListView.extend({
                collection: this.collection,
                gridRowAction: this.gridRowAction.bind(this),
                doDownload: this.doDownload,
                findSelectedSystemId: this.findSelectedSystemId,
            });
            const mobileList = configureMobileInterface(MobileList);
            MobileList = MobileList.extend(mobileList);
            this.legacyReport.show(new MobileList({
                ...this.options,
                ...this.gridViewOptions,
                prebuiltOptions: true,
                gridView: this.gridView,
                skipEntitlements: true,
            }));
        } else {
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.legacyReport.show(this.gridView);
        }
    },

    loadGrid() {
        this.createInitialServiceGridView();
        ListView.prototype.setupGridAvailableListener.call(this);

        this.showListView();
    },

    // When listview is displayed in the native mobile app, we should not allow download
    filterGridRowActions(gridView) {
        if (mobileUtil.isNativeApp() && gridView) {
            gridView.wrapper.rows.forEach((row) => {
                // eslint-disable-next-line no-param-reassign
                row.buttons = row.buttons.filter(({ action }) => action !== 'DLOAD');
            });
        }
    },

    createServiceGridView(context, additionalSearchFields) {
        const options = {
            context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            selector: 'none',
            sortKey: this.dateType,
            sortOrder: 'DESC',
            additionalSearchFields,
            lvc: this.lvc,
            gridOptions: { caption: context?.name },
            filterGridRowActions: this.filterGridRowActions,
        };
        this.gridViewOptions = options;
        this.gridView = GridApi.createServiceGridView(options);
    },

    gridRowAction(options) {
        if (options.action.toUpperCase() === 'SELECT') {
            const sid = this.findSelectedSystemId(options.model.get('REPORTID'));
            // Let's not break everything just for the sid used for a useless title
            if (sid) {
                options.model.set('systemId', sid);
                options.model.set('DISPLAYNAME', `${sid.get('systemid')}  ${sid.get('name')}`);
            }
            store.set(constants.REPORTING_CONTEXT, options.model.context);
            store.set(constants.REPORTING_VIEWMODEL, options.model);
            if (!systemConfig.isAdmin()) {
                this.handleAsyncJasperReport({
                    service: services.runAsyncLegacyReport,
                    postData: this.getPayload(options.model.get('REPORTUID')),
                    model: options.model,
                });
            } else {
                this.navigateTo(constants.LEGACY_REPORT_VIEW_ROUTE);
            }
        } else if (options.action.toUpperCase() === 'DOWNLOAD') {
            this.doDownload(options);
        }
        return Promise.resolve({ refresh: false });
    },

    /**
     * Handles generating reports payload
     * @param {string} reportUId - report id
     * @return {object}
     */
    getPayload(reportUId) {
        return {
            format: 'PDF',
            disposition: 'view',
            type: 'legacy',
            reportUId,
        };
    },

    /**
     * Handles showing async Jasper reports
     * @param {object} options
     */
    handleAsyncJasperReport(options) {
        const AsyncPrintView = asView(AsyncPrintViewModal);
        const AsyncPrintViewModalView = new AsyncPrintView({
            onCancel: () => dialog.close(),
            onReportComplete: (result) => {
                dialog.close();
                const { exportId } = result;
                options.model.set({ exportId });
                store.set(constants.REPORTING_VIEWMODEL, options.model);
                this.navigateTo(constants.LEGACY_REPORT_VIEW_ROUTE);
            },
            onReportIncomplete: () => helpers.setupAsyncReportPoller(),
            ...options,
        });
        AsyncPrintViewModalView.dialogTitle = locale.get('async.report');
        dialog.open(AsyncPrintViewModalView);
    },

    doDownload(options) {
        const { model } = options;
        if ($('#listViewDownloadForm').length === 0) {
            const form = '<form id="listViewDownloadForm"  method="get" target="_self"><input type="hidden" name="format" value="download"><input type="hidden" name="type" value="legacy"><input id="requestUID" type="hidden" name="filledReportId"><input id="fileName" type="hidden" name="fileName"><input id="mimeType" type="hidden" name="mimeType"></form>';
            this.$el.append(form);
        }
        $('#listViewDownloadForm').attr('action', services.generateUrl('/downloadReports/download'));
        $('#listViewDownloadForm #requestUID').val(model.get('REPORTUID'));
        $('#listViewDownloadForm #fileName').val(model.get('FILENAME'));
        $('#listViewDownloadForm #mimeType').val(model.get('MIMETYPE'));
        $('#listViewDownloadForm').submit();
    },

    findSelectedSystemId(id) {
        for (let index = 0; index < this.collection.models.length; index += 1) {
            for (let idx = 0; idx < this.collection.models[index].id.length; idx += 1) {
                if (this.collection.models[index].id[idx] === id) {
                    return this.collection.models[index];
                }
            }
        }

        return null;
    },

    toggleSearchFilter() {
        if (this.hasLoadedFilterOnce) {
            this.ui.$searchCriteria.fadeToggle('fast', () => {
                this.ui.$searchFilters.fadeToggle('fast');
            });
            if (this.setInitialDate) {
                this.filterRegion.currentView.model.set('LEGACYREPORTDATE', this.startDate);
                this.setInitialDate = false;
            }
        } else {
            this.loadFilter();
        }
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'LEGACY_REPORTS',
    view: LegacyReportsListView,
    options: {},
});

export default LegacyReportsListView;
