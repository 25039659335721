import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import parseHtmlToJsx from 'html-react-parser';
import useStyles from './TooltipText.styles';

const TooltipText = ({ pill, showSampleHtml }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h3 className={classes.tooltipHeading}>{pill.label}</h3>
            <p className={classes.tooltipDesciption}>{pill.tooltipDesciption}</p>
            {showSampleHtml && (
                <>
                    <h4 className={classes.tooltipExampleHeading}>Example:</h4>
                    <div className={classes.tooltipExampleContainer}>
                        {parseHtmlToJsx(pill.sampleHtml)}
                    </div>
                </>
            )}
        </div>
    );
};

TooltipText.propTypes = {
    pill: PropTypes.shape({
        label: PropTypes.string,
        tooltipDesciption: PropTypes.string,
        sampleHtml: PropTypes.string,
    }).isRequired,
    showSampleHtml: PropTypes.bool.isRequired,
};

export default TooltipText;
