import Layout from '@glu/core/src/layout';
import constants from 'app/administration/constants';
import Type from 'app/administration/models/user2/type';
import EntitlementCollectionView from './entitlementCollectionView';
import paymentRequestsTmpl from './paymentRequests.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default Layout.extend({
    template: paymentRequestsTmpl,

    ui: {
        $selectAllPayments: '[data-hook="selectAllPayments"]',
        $selectAllTemplates: '[data-hook="selectAllTemplates"]',
        $selectAll: '[data-hook="selectAllPayTemp"]',
    },

    events: {
        'click @ui.$selectAll': 'selectAllPermissions',
        'click @ui.$selectAllPayments': 'selectAllPaymentPerms',
        'click @ui.$selectAllTemplates': 'selectAllTemplatePerms',
    },

    initialize(options) {
        this.model = this.model || new Type({});
        this.mode = options.mode;
        this.userGroup = options.userGroup;

        // Keep for now.  Do we need panel approval????
        this.hasPanelApproval = options.hasPanelApproval;

        this.viewMode = options.mode === constants.MODES.VIEW;

        this.paymentEntitlements = this.model.getEntitlementsByFunctionCode(['REQUEST'], !this.viewMode);
        this.templateEntitlements = this.model.getEntitlementsByFunctionCode(['REQTMPL'], !this.viewMode);
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.paymentEntitlementsView = new EntitlementCollectionView({
            mode: this.mode,
            hasPanelApproval: this.hasPanelApproval,
            collection: this.paymentEntitlements,
            entitledTypes: this.entitledTypes,
        });

        this.templateEntitlementsView = new EntitlementCollectionView({
            mode: this.mode,
            collection: this.templateEntitlements,
            entitledTypes: this.entitledTypes,
        });

        if (this.paymentPermRegion) {
            this.paymentPermRegion.show(this.paymentEntitlementsView);
        }
        if (this.templatePermRegion) {
            this.templatePermRegion.show(this.templateEntitlementsView);
        }
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    onChildrenChange() {
        const checkboxList = this.$el?.find('input[type="checkbox"]');
        onChildrenChange(this, checkboxList);
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.paymentPermRegion?.$el?.find('input[type="checkbox"]'), this.ui.$selectAllPayments);
        updateSelectAllState(this, this.templatePermRegion?.$el?.find('input[type="checkbox"]'), this.ui.$selectAllTemplates);
        updateSelectAllState(this, this.$el?.find('.row input[type="checkbox"]'));
    },

    selectAllPermissions(evt) {
        this.selectAllPaymentPerms(evt);
        this.selectAllTemplatePerms(evt);
        this.onChildrenChange();
    },

    selectAllPaymentPerms(evt) {
        const { checked } = evt.currentTarget;
        this.paymentEntitlements.selectAll(checked);
        this.paymentEntitlementsView.render();
        this.ui.$selectAllPayments.prop('checked', checked);
    },

    selectAllTemplatePerms(evt) {
        const { checked } = evt.currentTarget;
        this.templateEntitlements.selectAll(checked);
        this.templateEntitlementsView.render();
        this.ui.$selectAllTemplates.prop('checked', checked);
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            readOnly: this.viewMode,
            hasPaymentEntitlements: this.paymentEntitlements.length > 0,
            hasTemplateEntitlements: this.templateEntitlements.length > 0,
        };
    },
});
