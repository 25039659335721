import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ViewWrapper } from '@glu/react-wrappers';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import { ThemeProvider, legacyTheme } from '@glu/theming';
import Drawer from 'components/Drawer/Drawer';
import SnackbarNotification from 'components/Notifications/SnackbarNotification';
// import history from './reactRouterBrowserHistory';
import BuildInfo from './BuildInfo';
import HrefHandler from './ClickHandler';
import Page from './Page';
import Headline from './Headline';

const Root = (props) => {
    const [title] = useState('');
    const [gluContentClassName] = useState('');
    const [theme] = useState(legacyTheme());
    const [, setTitleAndPageClass] = useState('');

    const pageProps = {
        updateTitleAndPageClass: updatedTitleAndPageClass =>
            setTitleAndPageClass(updatedTitleAndPageClass),
        formatDocumentTitle: (titleToFormat) => {
            /**
             * if the app resource returns a valid page title then return app resource
             * If not (indicated by double ?? in result of locale.get), then default to
             * 'Digital Banking'
             */
            // TODO extract logic into titleSuffix. locale.get() doesn't work
            const documentTitle = locale.get('PS.app.name').indexOf('??') === -1 ?
                locale.get('PS.app.name') : 'Payments and Cash Management';

            return titleToFormat ?
                util.escape(`${titleToFormat} - ${documentTitle}`) : documentTitle;
        },
    };

    const {
        NavView, FooterView, showHeader, showFooter,
    } = props;

    // this is major ickyness but in main.js afterAuthPromises resolves with some userInfo and
    // menu options are literally shoved onto the prototype here to pass them along.
    // Its pretty gross but right now if we don't deal with it the user menu is empty
    const { options: navViewOptions = {} } = NavView || {};

    const navigation = showHeader && NavView ?
        <ViewWrapper view={new NavView(navViewOptions)} /> : null;
    const footer = showFooter && FooterView ?
        <ViewWrapper view={new FooterView()} /> : null;

    const isDeepLink = window.parent !== window;

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <HrefHandler style={{
                    height: 'inherit',
                }}
                >
                    <BuildInfo buildInfo={props.buildInfo} />
                    <h1 className="sr-only">You are on the {title} page.</h1>

                    <div
                        className={`glu-content ${gluContentClassName}`}
                        style={{
                            display: 'block',
                        }}
                    >
                        <div id="nav" className="glu-base-nav" role="navigation">
                            {navigation || ''}
                        </div>

                        <div id="main" role="main">
                            <Headline />
                            <Page {...pageProps} />
                        </div>

                        <div id="footer" className="glu-footer" role="contentinfo">
                            {footer || ''}
                        </div>

                        <div id="modal-content" />
                    </div>
                    <Drawer defaultOpen={false} />
                    <SnackbarNotification isDeepLink={isDeepLink} />
                </HrefHandler>
            </Router>
        </ThemeProvider>
    );
};

Root.propTypes = {
    buildInfo: PropTypes.string,
    NavView: PropTypes.func,
    FooterView: PropTypes.func,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
};

Root.defaultProps = {
    buildInfo: '',
    NavView: () => {},
    FooterView: () => {},
    showHeader: true,
    showFooter: false,
};

export default Root;
