import Layout from '@glu/core/src/layout';
import GridApi from 'common/dynamicPages/api/grid';
import detailsTmpl from './details.hbs';

export default Layout.extend({
    initialize(options) {
        this.accountFilter = [options.accountFilter];
    },

    template: detailsTmpl,
    grid: undefined,

    onRender() {
        this.loadGrid();
    },

    cancel() {
        window.history.back();
    },

    loadGrid() {
        this.context = {
            productCode: 'GIR',
            functionCode: 'INST',
            actionMode: 'SELECT',
        };
        const data = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'LOANTRAN',
                        productCode: 'GIR',
                        functionCode: 'INST',
                        actionMode: 'SELECT',
                    },

                    searchFields: [{
                        fieldName: 'AccountFilter',
                        fieldValue: this.accountFilter,
                        dataType: 'text',
                        operator: '=',
                    }],

                    searchType: '5',
                    inquiryId: 22451,
                },
            },
        };

        const opts = {
            context: this.context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            inquirySearchCriteria: data.inquiryRequest.searchCriteria,
        };

        this.gridView = GridApi.createInquiryGridView(opts);
        this.tableContent.show(this.gridView);
    },
});
