/**
 * since we need to support both react and bb view input masking,
 * the actual masking logic is in maskingUtil, so that the
 * bb view can use it (since it cannot use the hook)
 * We don't want to have the masking logic in 2 places.
 * this react hook will call the maskingUtil.
 * Once we can remove the backbone views and just use react
 * we can move the logic back into the hook.
 */
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';

const useMask = (props) => {
    const { disableMask, maskPattern } = props;
    const {
        validMask,
        maskLeft,
        unmaskPos,
        maskedPos,
        unmaskedLength,
    } = getMaskingProperties(disableMask, maskPattern);

    function maskIt(inValue) {
        return maskValue(inValue, {
            disableMask,
            maskPattern,
            validMask,
            maskLeft,
            unmaskPos,
            maskedPos,
            unmaskedLength,
        });
    }

    return {
        maskIt,
    };
};

export default useMask;
