import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';
import Collection from '@glu/core/src/collection';
import TransferPaymentsCollection from 'app/smb/collections/transfers';
import SimpleCellView from 'app/smb/views/cells/simple';
import ModalCellView from 'app/smb/views/cells/complexModal';
import ActionCell from 'common/dynamicPages/views/gridActionCell';
import Navigation from 'app/smb/navigation';
import InquiryQueryResults from 'system/webseries/collections/inquiryQueryResults';
import InquiryAPI from 'system/webseries/api/inquiry';
import TransferPaymentGridView from '../baseAddGrid';
import TransferPaymentGridAddRowView from './addRow';
import baseTmpl from './base.hbs';

const paymentTypeId = 'SMBTRANSFER';

export default Layout.extend({
    template: baseTmpl,
    draftSaved: false,
    transferPaymentCollection: new TransferPaymentsCollection(),

    initialize(options) {
        this.listenTo(this.transferPaymentCollection, 'all', this.updateTotal);
        this.paymentTypeCollection = options.paymentTypeCollection;
        this.payTypes = this.paymentTypeCollection.models[0].get('smbPayMenu');
        [this.optsArray] = util.where(
            this.payTypes,
            {
                menuCategory: paymentTypeId,
            },
        );
        [this.payOptions] = this.optsArray.smbMenuGroupItems;
        this.productCode = this.payOptions.productCode;
        this.typeCode = this.payOptions.typeCode;
        this.functionCode = this.payOptions.functionCode;
        this.fromAccountList = this.renderFromAccounts();
        this.toAccountList = this.renderToAccounts();
        this.dates = options.dates;
    },

    renderFromAccounts() {
        this.fromAccountList = new InquiryQueryResults({
            inquiryId: InquiryAPI.TRANSFER_FROM_ACCOUNT_INQUIRY_ID,
            typeCode: this.typeCode,
            productCode: this.productCode,
            functionCode: this.functionCode,

            labelFormat(data) {
                let accountName;
                util.each(data.mapDataList, (mapDataList) => {
                    if (mapDataList.toField === 'Debit_Account_Title') {
                        accountName = mapDataList.value;
                    }
                });
                return `${data.label}-${accountName}`;
            },
        });
        this.fromAccountList.fetch();
        return this.fromAccountList;
    },

    renderToAccounts() {
        this.toAccountList = new InquiryQueryResults({
            inquiryId: InquiryAPI.TRANSFER_TO_ACCOUNT_INQUIRY_ID,
            typeCode: this.typeCode,
            productCode: this.productCode,
            functionCode: this.functionCode,

            labelFormat(data) {
                let accountName;
                util.each(data.mapDataList, (mapDataList) => {
                    if (mapDataList.toField === 'Bene_Name') {
                        accountName = mapDataList.value;
                    }
                });
                return `${data.label}-${accountName}`;
            },
        });
        this.toAccountList.fetch();
        return this.toAccountList;
    },

    onRender() {
        this.ui.$paymentsList.comboBox();
        this.ui.$totalLabel.text(`${locale.get('sbPayments.totalTransfersLabel')} ${Formatter.formatCurrency(this.transferPaymentCollection.total())}`);
    },

    onShow() {
        const columns = new Collection([{
            title: locale.get('sbPayments.fromAccount'),
            field: 'fromAccountId',
            description: 'fromAccountName',
            type: SimpleCellView,
        }, {
            title: locale.get('sbPayments.toAccount'),
            field: 'toAccountId',
            description: 'toAccountName',
            type: SimpleCellView,
        }, {
            title: locale.get('sbPayments.effectiveDate'),
            field: 'paymentDisplayDate',
            type: SimpleCellView,
        }, {
            title: locale.get('sbPayments.comment'),
            field: 'comment',
            sort: 'string',
            type: ModalCellView,
            editCell: 'text',
        }, {
            title: locale.get('sbPayments.amount'),
            field: 'displayAmount',
            sort: 'string',
            type: SimpleCellView,
            editCell: 'text',
        }, {
            type: ActionCell,
            width: 100,
            resizing: false,
            className: 'text-center btn-cell',
            display: 'iconList',

            buttons: [{
                value: 'edit',
                label: locale.get('smb.edit'),
                icon: 'pencil',
            }, {
                value: 'delete',
                label: locale.get('smb.delete'),
                icon: 'cancel',
            }],

            editButtons: [{
                value: 'cancel',
                label: locale.get('smb.cancel'),
            }],
        }]);

        const gridView = new TransferPaymentGridView({
            AddEditRowView: TransferPaymentGridAddRowView,
            collection: this.transferPaymentCollection,
            columns,

            editOptions: {
                fromAccounts: this.fromAccountList,
                toAccounts: this.toAccountList,
                blockedDates: this.dates.blockedDates,
                maxDate: this.dates.maxDate,
                cutoffTimes: this.dates.cutoffTimes,
                earliestDay: this.dates.earliestDay,
            },
        });

        this.content.show(gridView);
    },

    ui: {
        $paymentsList: '[data-hook="payments-list"]',
        $totalLabel: '[data-hook="total-text"]',
        $submitButton: '[data-hook="submit-payments"]',
        $saveButton: '[data-hook="save-draft"]',
    },

    appEvents: {
        'sbPayments:paymentListUpdated': 'updateTotal',
    },

    events: {
        'select2-selecting #payments-list': 'selectChange',
    },

    templateHelpers() {
        const paymentTypes = util.sortBy(this.paymentTypeCollection.models[0].get('smbPayMenu'), 'displayOrder');
        return {
            paymentTypes,
        };
    },

    selectChange(e) {
        /*
         * paytype can be taken from one of the collections.
         * As it is not permitting in few screens, defined value
         * 'paymentTypeId' is taken to compare the menucategory.
         */
        const self = this;
        if (paymentTypeId !== e.val) {
            dialog.confirm(locale.get('smb.change.paymenttype.warning'), 'Warning', (ok) => {
                if (ok) {
                    self.transferPaymentCollection.reset();
                    Navigation.navigate(e, self);
                }
                self.ui.$paymentsList.comboBox('val', paymentTypeId);
            });
        }
    },

    updateTotal() {
        const total = this.transferPaymentCollection.total();
        const totalTransfers = this.transferPaymentCollection.models.length;
        this.$el.find('[data-hook="total-text"]').text(`${totalTransfers} ${locale.get('sbPayments.totalTransfersLabel')} ${Formatter.formatCurrency(total)}`);
    },

    submitPayments() {
        const self = this;

        const alertStr = `${this.transferPaymentCollection.models.length} ${this.transferPaymentCollection.models.length === 1 ? locale.get('sbPayments.submitMessage.transfer') : locale.get('sbPayments.submitMessage.transfers')}`;

        if (this.transferPaymentCollection.models.length > 0) {
            // Send data to server
            util.each(this.transferPaymentCollection.models, (transferModel) => {
                transferModel.save(
                    {},
                    {
                        success(model) {
                            const message = locale.get('smb.transfer.success.msg', model.get('amount'), model.get('fromAccountName'), model.get('fromAccountId'), model.get('toAccountName'), model.get('toAccountId'));
                            dialog.alert(message);
                        },

                        error(model, options) {
                            const message = locale.get('smb.transfer.failed.msg', model.get('amount'), model.get('fromAccountName'), model.get('fromAccountId'), model.get('toAccountName'), model.get('toAccountId'));
                            dialog.alert(`${message} Error Code: ${options.errorCode} Error Message: ${options.errorMessage} - ${options.message}`);
                        },
                    },
                );
            });

            this.transferPaymentCollection.reset();
        } else {
            dialog.alert(alertStr, () => {
                // Return to Dashboard
                self.navigateTo('');
            });
        }
    },

    showServiceNotAvailableDialog() {
        dialog.alert(locale.get('smb.service.unavailable.error'));
    },

    saveDraft() {
        dialog.alert(locale.get('smb.draft.saved'));
        this.draftSaved = true;
    },

    cancelPayments() {
        this.transferPaymentCollection.reset();
        this.navigateTo('PAYMENTS/enterPayment');
    },
});
