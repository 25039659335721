import EntryView from 'common/dynamicPages/views/workflow/entry';

export default EntryView.extend({
    initialize(opts) {
        const options = {
            menuCategory: 'PMTS',
            serviceName: 'payments/corp',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            mode: 'repair',
        };

        if (opts.isSubPayment) {
            options.subPaymentContextKey = 'payment_File_getListViewDetails';
        }

        EntryView.prototype.initialize.call(this, options);
    },
});
