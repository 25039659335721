import Layout from '@glu/core/src/layout';
import baseWidgetTmpl from './baseWidget.hbs';

export default Layout.extend({
    template: baseWidgetTmpl,
    className: 'ui-widget field-container',

    /**
     * Do default init for the widgets
     * @param {context: Object, state: String ('insert', 'modify', 'view'),
     * model: Object, widgetID: String, fieldName: String} options
     */
    constructor(options) {
        /*
         * put the options on the class earlier than usual so that the className
         * function will have access to options if needed
         */
        this.options = options;

        this.widgetID = options.widgetID;
        // Access to the passed model for the fieldName
        this.parentModel = options.model;
        // Access to the parent view for dependant field manipulation
        this.parentView = options.parentView;
        /*
         * the base widget will have the same model as the parent, if other widgets
         * need their own model, then they will create it.
         */
        this.model = options.model;
        this.fieldName = options.fieldName;
        this.state = options.state ? options.state.toUpperCase() : '';
        this.context = options.context;
        this.fieldInfo = options.fieldInfo;
        this.fieldData = options.fieldData;
        this.shouldBeProtected = options.shouldBeProtected;

        /*
         * Create a default readyState
         * example for reject: Promise.reject('Promise rejected: An Error has occurred');
         */
        this.readyState = Promise.resolve(true);

        /*
         * Due to some of the needs for className the above is done first and done as
         * a constructor call so that we can set the root level classes using core widget items
         * like the fieldInfo and such
         */
        Layout.prototype.constructor.call(this, options);
    },

    /**
     * Override this for custom behavior, otherwise, update this.readyState as needed
     * If a widget is loading data, it may choose to Reject this request, because
     * user action is still required.
     * If an async request is still open, but user action is not required on
     * completion, the pending promise can/
     *  should be passed.
     * @return {Promise}
     */
    isReadyToSave() {
        return this.readyState;
    },

    /**
     * Widgets that don't need to load data can use the default (ready immediately)
     * If a widget loads data, it can decide if it needs to hold up render waiting for
     * its data, or if it can be
     *  rendered but interaction will still require the data.
     * The readyToRender state may need to affect the readyToSave state, as well.
     * @return {Promise}
     */
    isReadyToRender() {
        return Promise.resolve();
    },

    /**
     * Utility functions for widget fields
     */

    shouldBeHidden(fieldName) {
        this.$(`[name="${fieldName}"]`).parents('.widget-field-container').first().hide();
    },

    shouldBeVisible(fieldName) {
        this.$(`[name="${fieldName}"]`).parents('.widget-field-container').first().show();
    },

    shouldBeVisibleWhen(fieldName, condition) {
        if (condition) {
            this.shouldBeVisible(fieldName);
        } else {
            this.shouldBeHidden(fieldName);
        }
    },

    shouldBeReadOnly(fieldName, readOnly) {
        this.$(`[name="${fieldName}"]`).prop('readonly', readOnly).toggleClass('read-only-field', readOnly);
    },

    isReadOnlyProp(fieldName) {
        return (Object.prototype.hasOwnProperty.call(this, 'el')) ? this.$(`[name="${fieldName}"]`).prop('readonly') : false;
    },

    templateHelpers() {
        return {
            widgetID: this.widgetID,
        };
    },
});
