import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import mobileUtil from 'mobile/util/mobileUtil';
import template from './rateTradeResultCellView.hbs';

export default ItemView.extend({
    disableInitialViewBinding: true,
    template,

    initialize(options) {
        ItemView.prototype.initialize.call(this, options);
        this.column = options.column;
        this.model = options.model;
        if (!this.model.timeOutArr) {
            this.model.timeOutArr = [];
        }
        this.clearQuoteExpiryTimeOut();
        if (this.model.get('RATETRADERESULT') && !Number.isNaN(Number(this.model.get('RATETRADERESULT')))) {
            this.updateExpirationTime(this.model.get('RATETRADERESULT'));
        } else {
            this.render();
        }
    },

    updateExpirationTime(quoteExpTime) {
        const self = this;
        if (quoteExpTime > 0) {
            this.model.set({
                RATETRADERESULT: parseInt(this.model.get('RATETRADERESULT'), 10) - 1,
            }, {
                silent: true,
            });
            const timeoutVar = setTimeout(() => {
                self.updateExpirationTime(quoteExpTime - 1);
            }, 1000);
            this.model.timeOutArr.push(timeoutVar);
        } else {
            this.model.set({
                RATETRADERESULT: locale.get('payment.quote.expired'),
            }, {
                silent: true,
            });
            this.clearQuoteExpiryTimeOut();
            util.each(this.model.buttons, (buttonParam) => {
                const button = buttonParam;
                if (button.action === 'TRADE') {
                    button.action = 'GETRATE';
                    button.label = 'Get Rate';
                    button.value = button.value.replace('trade', 'getrate');
                }
            });
            if (mobileUtil.isMobileGridEnabled()) {
                this.appBus.trigger('getrate:expired', {
                    rowModel: this.model,
                    status: 'expired',
                });
            }
        }
        this.render();
    },

    templateHelpers() {
        return {
            resultIsNumber: !Number.isNaN(Number(this.model.get('RATETRADERESULT'))),
        };
    },

    clearQuoteExpiryTimeOut() {
        if (this.model.timeOutArr) {
            util.each(this.model.timeOutArr, (timeoutVar) => {
                clearTimeout(timeoutVar);
            });
            this.model.timeOutArr.splice(0, this.model.timeOutArr.length);
            this.render();
        }
    },
});
