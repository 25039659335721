import ListView from 'common/dynamicPages/views/workflow/list';
import TransactionListView from 'app/balanceAndTransaction/views/transactionListView';
import GridApi from 'common/dynamicPages/api/grid';
import constants from 'app/balanceAndTransaction/constants';
import AdditonalInfoCellView from 'app/balanceAndTransaction/views/additionalInfoCellView';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import DataTable from 'common/dynamicPages/views/dataTable/dataTable';
import TotalsModel from 'app/balanceAndTransaction/models/accountTotals';
import Services from 'services';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';
import TransactionDetailView from 'app/balanceAndTransaction/views/depositAccounts/transactionDetails';
import workspaceHelper from 'common/workspaces/api/helper';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import store from 'system/utilities/cache';
import helpPageUtil from 'common/util/helpPage';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import searchFieldsUtil from 'common/util/searchFieldsUtil';
import ShortenedCell from 'common/dynamicPages/views/gridCells/shortenedCell';
import gridUtil from 'app/utilities/views/gridUtil';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import systemConfig from 'system/configuration';
import template from './cashPositionDepositTransactionListView.hbs';

export default TransactionListView.extend({
    template,
    accountType: 'DEPOSIT',

    ui: {
        $refreshTransactionsControls: '[data-hook="getRefreshTransactionsControls"]',
        $exportBtn: '[data-hook="export-button"]',
        $sumTitle: '[data-hook="getSummaryTitle"]',
    },

    initialize(options) {
        this.stack = options.stack;
        this.paginate = options.paginate;
        this.showTotalsForAllAccounts = false;
        this.urlTab = options.model.get('urlTab');

        this.accountFilters = options.accountFilters;

        if (!this.accountType) {
            this.accountType = options.accountType;
        }

        // A flag that allows us to determine if this came from the type summary
        this.fromTransactionTypeSummary = options.model.has('transactionType');
        this.context = this.getContext();
        if (mobileUtil.isMobileGridEnabled()) {
            ListView.prototype.setListViewConfig.call(this, this.context);
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.setPage();

            this.totalsModel = new TotalsModel({
                filters: this.gridView.wrapper.generateFiltersDataBlock(),
                viewId: this.gridView.wrapper.viewId,
            }, {
                service: Services.generateUrl(this.totalsService),
            });
            const nonSortableColumns = ['PAYMENT_DETAIL'];
            this.listenTo(this.gridView, 'gridapi:loaded', gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
            this.appBus.on(`gridapi:showMessages${this.gridView.cid}`, gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
        } else {
            moveToTopCheck(this.model);
            store.unset('helpPage');

            this.loadViewRequirements();
        }
    },

    loadViewRequirements() {
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'GIR',
            functionCode: 'RPT',
            typeCode: '*',
            mode: 'SELECT',
        });

        return helpPagePromise.then((results) => {
            store.set('helpPage', results.helpPage); // store the help page
            this.setHasLoadedRequiredData(true);
            this.render();
            return {};
        });
    },

    /**
     * @method getRequestParameters
     * Constructs request params object
     * @return {object} request params object to be sent in the getListView payload
     */
    getRequestParameters() {
        return {
            item: [{
                name: 'sectionId',
                value: this.model.get('sectionId'),
            },
            {
                name: 'tabId',
                value: this.model.get('tabId'),
            }],
        };
    },

    checkAccountFilterInTransDetailsPage() {
        return (this.accountFilters
            && !this.fromTransactionTypeSummary
            && (!util.isArray(this.accountFilters) || this.accountFilters.length > 0));
    },

    /**
     * depending on the model decides whether to use account or transaction filter
     * creates gridView object
     * @method setPage - extend
     */
    setPage() {
        let options = {
            context: this.context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            filter: true,
            additionalSearchFields: [
                ...this.options.additionalSearchFields || [],
                ...this.model.get('baseSearchFields') || [],
            ],
            hasRowSelector: true,
        };

        // Do not add from transaction type summary because account filter is not populated
        if (!this.options.additionalSearchFields && !this.fromTransactionTypeSummary) {
            options.additionalSearchFields.push(searchFieldsUtil.createSearchField(
                'AccountFilter',
                this.model.get('accountFilter'),
                'uppertext',
            ));
        }

        const filters = [];

        const accNum = this.model.get('accountNumber');
        if (accNum) {
            const accountFilterParts = this.model.get('accountFilter').split(/-(.+)/);

            filters.push({
                field: 'ACCOUNT_NUM',
                type: 'multistring',
                title: locale.get('gir.advice.tranInfo.accountnumber'),
                label: accNum, // Masked value
                value: [accountFilterParts[1]], // Unmasked value
                staticFilter: true,
            });
        }

        const tranType = this.model.get('transactionType');
        if (tranType?.length > 0) {
            filters.push({
                field: 'BAI_GROUP_CODE',
                type: 'multistring',
                title: locale.get('gir.advice.tranInfo.type'),
                label: tranType,
                value: [tranType],
                staticFilter: true,
            });
        }

        const currencyCode = this.model.get('currencyCode');
        if (currencyCode) {
            filters.push({
                field: 'CURRENCY_CODE',
                title: locale.get('bab.currency'),
                type: 'enum',
                value: [currencyCode],
                staticFilter: true,
            });
        }

        if (this.checkAccountFilterInTransDetailsPage()) {
            /**
             * NH-176167: `hideSavedViewBadge` will be used to hide the account filters
             * saved view badge
             */
            this.accountFilters.hideSavedViewBadge = this.accountFilters.value?.length > 1;
            filters.push(this.accountFilters);
        }

        this.filters = filters;

        if (this.paginate === false) {
            options.paginate = false;
        }

        options = util.extend(
            options,
            {
                cellViews: {
                    ADDITIONAL_INFO: AdditonalInfoCellView,
                    PAYMENT_DETAIL: ShortenedCell,
                    PAYMENT_DETAIL_ORIG: ShortenedCell,
                },
                requestParameters: this.getRequestParameters(),
                loadedCallback: () => {
                    this.gridLoaded = true;

                    if (this.filters.length > 0) {
                        this.gridView.grid.filterProc.filters.add(this.filters);
                        this.gridView.grid.filterProc.process();
                    }
                },
                onGridRefreshComplete: this.setupSummarySection.bind(this),
            },
        );

        // This would duplicate some fields if done for transaction type summary
        if (!this.fromTransactionTypeSummary) {
            this.setAdditionalSearchFields(options);
        }

        this.gridViewOptions = options;
        this.gridView = this.createGridView(options);
        /*
         * NH-183046 InquiryId and filterID are usually set via
         * menu context. In this case, there isn't a valid context
         * to use, so manually setting these values to 0.
         */
        this.gridView.context = {
            filterID: 0,
            inquiryId: 0,
            ...this.gridView.context,
        };

        if (this.transactionsGrid) {
            if (mobileUtil.isMobileGridEnabled()) {
                let MobileList = ListView;
                const mobileList = configureMobileInterface(MobileList);
                MobileList = MobileList.extend(mobileList);
                this.mobileGridView = new MobileList({
                    ...this.options,
                    ...this.gridViewOptions,
                    prebuiltOptions: true,
                    gridView: this.gridView,
                    skipEntitlements: true,
                    mobileContextOverride: this.gridView.context,
                    lvc: this.lvc,
                });

                this.transactionsGrid.show(this.mobileGridView);
            } else {
                this.transactionsGrid.show(this.gridView);
            }
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
        }
    },

    /**
     * @method setAdditionalSearchFields
     * adds search fields to options
     * the code is taken out from setPage function for re-using
     */
    setAdditionalSearchFields(options) {
        if (applicationConfigParams.getValue('RUNBAL', 'DISPLAYRUNBALANCE') !== '1') {
            return;
        }

        const accountFilterParts = this.model.get('accountFilter').split(/-(.+)/);

        options.additionalSearchFields.push({
            fieldName: 'ACCOUNT_NUM',
            fieldValue: [accountFilterParts[1]], // Unmasked value
            dataType: 'uppertext',
            operator: '=',
        }, {
            fieldName: 'BANK_CODE',
            fieldValue: [accountFilterParts[0]],
            dataType: 'uppertext',
            operator: '=',
        });
    },

    createGridView(options) {
        return GridApi.createServiceGridView(options);
    },

    /**
     * @method setupSummarySection
     * Callback method to create the Summary Information Tables that accompany
     * the main grid
     * will update the tables if they are already instantiated
     */
    setupSummarySection() {
        const gridView = this.getGridViewInstance();
        const gridFilters = gridView.wrapper.generateFiltersDataBlock();
        const tType = this.model.get('transactionType');

        if (this.summaryTableView) { // applying filtering on the transaction page
            const summaryGridTypeIsBalance = store.get('girCPPTransSummarySection') === 'b';
            let transFiltersApplied = false;

            util.each(gridFilters, (filterParam) => {
                const filter = filterParam;
                if (!util.isEqual(filter.fieldName, 'ACCOUNTFILTER') && !util.isEqual(filter.fieldName, 'ACCOUNT_NUM') && !util.isEqual(filter.fieldName, 'BANK_CODE')) {
                    if (util.isEqual(filter.fieldName, 'BAI_GROUP_CODE')) {
                        if (filter.fieldValue[0].length > 1) {
                            transFiltersApplied = true;
                        }
                    } else {
                        transFiltersApplied = true;

                        if (util.isEqual(filter.fieldName, 'BAI_DESCRIPTION_DISPLAY')) {
                            filter.fieldName = 'DESCRIPTION';
                        } else if (util.isEqual(filter.fieldName, 'BAI_GROUP_CODE_DISPLAY')) {
                            filter.fieldName = 'BAI_GROUP_CODE';
                        }
                    }
                }
            });

            if (transFiltersApplied) {
                if (summaryGridTypeIsBalance) {
                    store.set('girCPPTransSummarySection', 't'); // store as transactions grid
                    this.showTotalsData(constants.INST, this.urlTab + constants.TRANSACTION_TOTALS_SERVICE, 'common.TransactionSummary', gridFilters);
                } else {
                    this.summaryTableView.refreshTableData(
                        gridFilters,
                        this.getGridViewInstance().wrapper.viewId,
                    );
                }
            } else {
                store.set('girCPPTransSummarySection', 'b'); // store as balance grid
                this.showTotalsData(constants.PRO, this.urlTab + constants.ACCOUNT_BALANCE_SUMMARY_SERVICE, 'common.BalanceSummary', gridFilters);
            }
        } else if (util.isUndefined(tType) || tType.length < 1) {
            // opening the transaction page
            store.set('girCPPTransSummarySection', 'b'); // store as balance grid
            this.showTotalsData(constants.PRO, this.urlTab + constants.ACCOUNT_BALANCE_SUMMARY_SERVICE, 'common.BalanceSummary', gridFilters);
        } else {
            store.set('girCPPTransSummarySection', 't'); // store as transactions grid
            this.showTotalsData(constants.INST, this.urlTab + constants.TRANSACTION_TOTALS_SERVICE, 'common.TransactionSummary', gridFilters);
        }
    },

    /**
     * @method showTotalsData
     * Creates and mounts the view needed to show specific balance totals data
     * @param {object} [functionCode] - distinct between balance and transactions
     * @param {object} [serviceName] - distinct between balance and transactions
     * @param {object} [title] - distinct between balance and transactions
     * @param {object} [gridFilters] - additional filters search param object
     */
    showTotalsData(functionCode, serviceName, title, gridFilters) {
        const context = {
            actionMode: constants.SELECT,
            displayOrder: 1,
            serviceFunc: null,
            businessType: null,
            selector: constants.NONE,
            functionCode,
            gridId: 0,
            productCode: constants.GIR,
            serviceName: constants.DEPOSIT_ACCTS_SERVICE_PREFIX + serviceName,
            typeCode: constants.GIRBAL_I,
            filters: gridFilters,
            viewId: this.getGridViewInstance().wrapper.viewId,
        };

        this.summaryTableView = new DataTable(context);
        this.totalsRegion.show(this.summaryTableView);
        this.ui.$sumTitle.text(locale.get(title));
    },

    getContext() {
        return {
            functionCode: 'INST',
            menuCatergory: 'reporting',
            menuDescription: 'Balance and Transaction Reporting',
            name: locale.get('balanceTrans.currentDayCashTransaction'),
            productCode: 'GIR',
            requestMappings: 'balanceAndTransactionReporting',
            serviceName: this.getServiceName(),
            typeCode: this.getTypeCode(),
            exportPrintURL: this.getExportPrintURL(),
            inquiryId: 22280,
            /*
             * NH-178966 Custom typeCode from the backend to make the savedViews for the list
             * uniquie from the Current Day Cash screen.
             */
            overrideTypeCode: 'GIRDETAILTRN',
        };
    },

    /**
     * provides a part of The URL to hit for Current Day Transactions
     * @method getServiceName - extend
     * @return {String} A part of the URL to hit for Current Day Transactions
     */
    getServiceName() {
        return `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX + this.urlTab}getTransactions`;
    },

    /**
     * provides the URL to hit for print/export on Current Day Transactions
     * @method getExportPrintURL - extend
     * @return {String} the URL to hit for print/export on Current Day Transactions
     */
    getExportPrintURL() {
        return systemConfig.isAdmin() ? constants.CASH_POSITION_TRANS_EXPORT_PRINT_URL
            : constants.ASYNC_CASH_POSITION_TRANS_EXPORT_PRINT_URL;
    },

    /**
     * provides the typeCode for Current Day Transactions
     * @method getTypeCode - extend
     * @return {String} provides the typeCode for Current Day Transactions
     */
    getTypeCode() {
        return this.model.get('tabId') === constants.ACCOUNT_SUMMARY.TABS.CASH_POSITION ? constants.GIRTRA_I : constants.GIRTRN_I;
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * @method gridRowAction
     * navigates to image details screen
     * @param {object} options - contains the model associated to the selected row
     */
    gridRowAction(options) {
        return new Promise((resolve) => {
            const action = options.action.toUpperCase();

            if (action === 'IMAGE') {
                this.getImage(options);
            }
            resolve();
        });
    },

    /**
     * @method getImage
     * creates json data and posts it
     * @param {object} optionsParam - contains the model of the selected row
     */
    getImage(optionsParam) {
        const options = optionsParam;
        const { model } = options;
        const type = model.get('BAI_GROUP_CODE');
        const tranType = model.get('DR_CR');
        const postedFlag = model.get('POSTED_FLAG_DESC');
        let postedFlagValue;
        let imageType;
        let service;
        const jsonData = [];
        const self = this;

        if (type === 'DEPOSITED ITEMS RETURNED') {
            service = Services.generateUrl(constants.RETURNED_ITEM_IMAGE_SERVICE);
            imageType = constants.RETURNED_ITEM_IMAGETYPE;
        } else if (tranType === 'CR') {
            service = Services.generateUrl(constants.DEPOSIT_ACCTS_IMAGE_SERVICE);
            imageType = constants.DEPOSIT_ACCTS_IMAGETYPE;
        } else {
            service = Services.generateUrl(constants.CHECK_PAID_IMAGE_SERVICE);
            imageType = constants.CHECKPAID_IMAGETYPE;
        }

        if (postedFlag === 'Pending') {
            postedFlagValue = 'I';
        } else {
            postedFlagValue = 'P';
        }

        jsonData.push({
            name: 'ACCOUNT_TITLE',
            value: model.get('ACCOUNT_NAME'),
        }, {
            name: 'ACCOUNT_NUMBER',
            value: model.get('ACCOUNT_NUM'),
        }, {
            name: 'ACCOUNTFILTER',
            value: model.get('ACCOUNTFILTER'),
        }, {
            name: 'BANKCODE',
            value: model.get('BANK_CODE'),
        }, {
            name: 'BANK_NAME',
            value: model.get('BANK_NAME'),
        }, {
            name: 'BANK_ID',
            value: model.get('BANK_ID'),
        }, {
            name: 'POST_DATE',
            value: model.get('POST_DATE'),
        }, {
            name: 'AMOUNT',
            value: model.get('AMOUNT'),
        }, {
            name: 'BANK_REF',
            value: model.get('BANK_REF'),
        }, {
            name: 'CURRENCY_CODE',
            value: model.get('CURRENCY_CODE'),
        }, {
            name: 'CUST_REF',
            value: model.get('CUST_REF'),
        }, {
            name: 'BAI_CODE',
            value: model.get('BAI_CODE'),
        }, {
            name: 'DR_CR',
            value: tranType,
        }, {
            name: 'MESSAGE_ID',
            value: model.get('MESSAGE_ID'),
        }, {
            name: 'POSTED_FLAG',
            value: postedFlagValue,
        }, {
            name: 'IMAGETYPE',
            value: imageType,
        }, {
            name: 'PIC_SEQUENCE_NUMBER',
            value: model.get('BANK_REF'),
        }, {
            name: '_productCode',
            value: model.get('PRODUCT'),
        }, {
            name: '_functionCode',
            value: model.get('FUNCTION'),
        });

        options.success = (resp) => {
            self.showImage(resp, options);
        };
        options.error = (resp) => {
            self.showError(resp, options);
        };

        http.post(
            service,
            {
                item: jsonData,
            },
            (resp) => {
                options.success(resp, options);
            },
            (resp) => {
                options.error(resp, options);
            },
        );
    },

    /**
     * @method showError
     * displays error message
     * @param {object} response from getImage function
     */
    showError(response) {
        if (response.message) {
            this.renderMessage({
                type: 'DANGER',
                message: util.filter(response.message, msg => msg.toUpperCase() !== locale.get('cm.image.not.found').toUpperCase()).join('\n'),
            });
        }
    },

    /**
     * @method showImage
     * navigates to image details screen
     * @param {Object} imageResponse
     * @param {object} options - contains the model associated to the selected row
     */
    showImage(imageResponse, options) {
        const { model } = options;
        const headerData = transform.pairsToHash(imageResponse.headerData.item);
        const headerLabels = transform.pairsToHash(imageResponse.headerLabels.item);
        const { frontImage, backImage } = imageResponse;
        const frontImageType = frontImage?.contentType ?? '';
        const backImageType = backImage?.contentType ?? '';

        const images = imageResponse?.images?.map(image => transform.pairsToHash(image.item)) ?? [];

        model.set({
            images,
            headerData,
            headerLabels,
            image_data: !util.isEmpty(frontImage) ? frontImage.value : '',
            image_type: frontImageType,
            image_data_reverse: !util.isEmpty(backImage) ? backImage.value : '',
            image_type_reverse: backImageType,
            respMessages: util.filter(imageResponse.messages, msgs => msgs.toUpperCase() !== locale.get('cm.image.not.found').toUpperCase()),
        });

        if (this.stack) {
            this.stack.push(new TransactionDetailView({
                pageTitle: locale.get('gir.transactions'),
                accountFilter: model.get('ACCOUNTFILTER'),
                model,
                result: imageResponse.result,
            }));
        } else {
            store.set('deposit_data', model);
            store.set('check_result_bool', imageResponse.result);
            this.navigateTo('BANK_ACC_INFO/depositAccountImage');
        }
    },

    /**
     * @param {string} format
     * @return {object} options
     * Generate the standard options object used for standard Print and Export
     * operations
     */
    getPrintOptions(format) {
        const summaryGridTypeIsBalance = store.get('girCPPTransSummarySection') === 'b' ? '1' : '0';
        return {
            view: this,
            gridView: this.gridView,
            format: format || 'PDF',
            inquiryId: this.context.inquiryId,
            hasSummarySelection: !this.getSuppressDetailedPrintGIR(),

            contextData: {
                isBalance: summaryGridTypeIsBalance,
            },

            exportURL: this.getExportPrintURL(),
            printSummaryLabel: locale.get('gir.transactionsListView'),
            printDetailsLabel: locale.get('gir.transactionsDetailReport'),
        };
    },
    /**
     * NH-185994 The gridView for this view is not the same instance on mobile.
     * When mobile is enbled, return the gridView for mobile, otherwise,
     * the current gridView.
     * @returns {Object}
     */
    getGridViewInstance() {
        if (mobileUtil.isMobileGridEnabled()) {
            return this.mobileGridView.gridView;
        }
        return this.gridView;
    },
});
