import EntryView from 'common/dynamicPages/views/workflow/entry';

export default EntryView.extend({
    /**
     * @name setupListeners
     * @description override entry's method
     * adds event handler for 'rfp:payFromListView'
     */
    setupListeners() {
        EntryView.prototype.setupListeners.call(this);
        if (this.options.action === 'pay') {
            this.listenTo(this.pageView, 'ui-loaded', this.payFromListView);
        }
    },

    /**
     * @name payFromListView
     * @description when the PAY action is selected from the RFP list view, the user is taken
     * to the RFP detail view where the PAY meta driven footer will open
     */
    payFromListView() {
        this.createFooterForm(this.createFooterFormConfigurationObject('PAY'));

        this.listenTo(this.footerForm, 'ui-loaded', () => {
            window.scroll(0, this.footerForm.$el.offset().top);
        });
    },
});
