import ItemView from '@glu/core/src/itemView';
import planItemTmpl from './planItem.hbs';

const PlanItem = ItemView.extend({
    template: planItemTmpl,
    tagName: 'tr',

    ui: {
        $radioSelector: 'input[type="radio"]',
        $remove: '.remove',
    },

    modelEvents: {
        'change:selected': 'updateRadioState',
    },

    events: {
        'change @ui.$radioSelector': 'toggleSelectedPlan',
        'click @ui.$remove': 'removeItem',
    },

    updateRadioState(model, value) {
        this.ui.$radioSelector.prop('checked', value);
    },

    toggleSelectedPlan() {
        if (this.ui.$radioSelector.prop('checked')) {
            this.model.set('selected', true);
        }
    },

    removeItem() {
        this.model.destroy();
    },
});

export default PlanItem;
