import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import Layout from '@glu/core/src/layout';
import balanceTransactionSummaryPanelTmpl from './balanceTransactionSummaryPanel.hbs';

const BalanceTransactionSummaryPanel = Layout.extend({
    template: balanceTransactionSummaryPanelTmpl,
    loadingTemplate: loadingPageTmpl,
    initialize() {
        this.listenTo(this.model, 'sync change', this.render);
    },
    onRender() {
        if (this.model.get('ready')) {
            this.setHasLoadedRequiredData(true);
        }
    },
});

export default BalanceTransactionSummaryPanel;
