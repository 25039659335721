import React, {
    Fragment, useCallback, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { CaretLeftNextIcon } from '@glu/icons-react';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import MobileDrawer from 'components/MobileDrawer/MobileDrawer';
import store from 'system/utilities/cache';
import styles from './workspaceWidgetCard.styles';

const propTypes = {
    /** Content to display in card */
    cardContent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** JSS classes for styling */
    classes: PropTypes.objectOf(PropTypes.string),
    /** Icon component to use */
    icon: PropTypes.func,
    /** Action for when navigation item is actioned */
    navigationAction: PropTypes.shape({
        /**
         * Function to invoke on click of navigation item
         * If no panelComponent provided, this *must* be included
         * If panelComponent provided and this is included, it will be invoked on click
         * of panel navigation icon
         */
        onClick: PropTypes.func,
        /**
         * Panel component reference
         * *not* instance (to prevent need to rename widget files to JSX)
         */
        panelComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        /** Object of props to pass to panel component */
        panelComponentProps: PropTypes.shape({}),
    }),
    /** Should widget start open in panel already */
    startOpen: PropTypes.bool,
    /** Widget view attributes.toJSON representation */
    widgetAttributes: PropTypes.shape({
        /** Widget identifier (name) */
        widgetName: PropTypes.string.isRequired,
        /** Main heading for card */
        widgetDescription: PropTypes.string.isRequired,
    }).isRequired,
};

const defaultProps = {
    cardContent: '',
    classes: {},
    icon: () => null, // allow for no icon but sending a func that returns null
    navigationAction: {
        onClick: () => {},
        panelComponent: undefined,
        panelComponentProps: {},
    },
    startOpen: false,
};

const WorkspaceWidgetCard = ({
    cardContent,
    classes,
    icon: Icon,
    navigationAction: {
        panelComponent: PanelComponent,
        panelComponentProps,
    },
    startOpen,
    widgetAttributes: {
        widgetName,
        widgetDescription: title,
    },
}) => {
    const [isOpen, setIsOpen] = useState(startOpen);
    const originalWorkspaceRoute = useRef(store.get('current-workspace-route'));

    const getPanel = useCallback(() => {
        if (PanelComponent.cid) {
            return <ViewWrapper view={PanelComponent} />;
        }
        return <PanelComponent {...panelComponentProps} />;
    }, [PanelComponent, panelComponentProps]);

    const [drawerContent, setDrawerContent] = useState(startOpen ? getPanel() : <div />);

    const removeWidgetInSearchQuery = () => {
        const { location: { search } } = window;
        const widgetSearchName = widgetName.split(' ').join('');
        let newSearch = '';

        // go through and replace only the necessary keys in search query, not just blank it out
        if (search) {
            newSearch = search.substr(1).split('&').reduce((acc, cur) => {
                const parts = cur.split('=');
                if (parts[0] === 'widget' && parts[1] === widgetSearchName) {
                    return acc;
                }
                return acc.concat([cur]);
            }, []).join('&');
            if (newSearch) {
                newSearch = `?${newSearch}`;
            }
        }
        return newSearch;
    };

    const closeDrawer = () => {
        const { history, location: { pathname } } = window;
        const newSearch = removeWidgetInSearchQuery();
        setIsOpen(false);
        history.pushState(null, document.title, `${pathname}${newSearch}`);
    };

    const updateAndGetCurrentWorkspaceRoute = useCallback(() => {
        const { location: { search } } = window;
        const widgetSearch = `widget=${widgetName.split(' ').join('')}`;
        const fullSearch = `?${widgetSearch}${search ? '&' : ''}${search.replace(/^\?/g, '')}`;
        return {
            fullSearch,
            widgetSearch,
        };
    }, [widgetName]);

    const handleNavigationClick = () => {
        const { history, location: { pathname } } = window;
        const panel = getPanel();

        const { fullSearch } = updateAndGetCurrentWorkspaceRoute();
        store.set('current-workspace-route', `${originalWorkspaceRoute.current}${fullSearch}`);
        history.pushState(null, document.title, `${pathname}${fullSearch}`);
        setDrawerContent((
            <div>
                {panel}
            </div>
        ));
        setIsOpen(true);
    };

    useEffect(() => {
        if (startOpen) {
            const { widgetSearch } = updateAndGetCurrentWorkspaceRoute();
            store.set('current-workspace-route', `${originalWorkspaceRoute.current}?${widgetSearch}`);
        }
    }, [startOpen, updateAndGetCurrentWorkspaceRoute, originalWorkspaceRoute]);

    const handleKeyDownOnContainer = (e) => {
        if (e.which === 13) {
            handleNavigationClick();
        }
    };

    return (
        <>
            <div
                className={classes.root}
                role="button"
                tabIndex="0"
                onKeyDown={handleKeyDownOnContainer}
                onClick={handleNavigationClick}
            >
                <div className={classes.icon}>
                    <Icon />
                </div>
                <div className={classes.contentArea}>
                    <h2 className={classes.title}>{title}</h2>
                    <span className={classes.content}>
                        {cardContent}
                    </span>
                </div>
                <div className={classes.navigationAction}>
                    <CaretLeftNextIcon actionable onClick={handleNavigationClick} />
                </div>
            </div>
            <MobileDrawer isOpen={isOpen} title={title} onClose={closeDrawer}>
                {drawerContent}
            </MobileDrawer>
        </>
    );
};

WorkspaceWidgetCard.propTypes = propTypes;
WorkspaceWidgetCard.defaultProps = defaultProps;

export const BareWorkspaceWidgetCard = WorkspaceWidgetCard;

export default withStyles(styles)(WorkspaceWidgetCard);
