import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import cmConstants from 'app/checkManagement/constants';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import PrintExportUtil from 'common/util/printExportUtil';
import workspaceHelper from 'common/workspaces/api/helper';
import FilterCellView from 'common/dynamicPages/views/filterCellView';
import template from './listView.hbs';

const IssuesVoidsList = ListView.extend({

    template,

    events: util.extend({}, ListView.prototype.events, {
        'click [data-hook="getBackButton"]': 'cancel',
    }),

    initialize(options) {
        this.allowStopsInIssueVoidFile = applicationConfigParams.getValue('CMCIM', 'ALLOWSTOPSINISSUEVOIDFILE') === '1';
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: null,
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            enableDateTimeSupport: true,
            context: 'CM_IV_LIST',
            returnRoute: 'REPORTING/viewCMIssueVoids',
            viewId: options.viewId,
            selector: 'none',
            cellViews: {
                JOBID: FilterCellView,
            },
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    export() {
        this.gridView.context.actionMode = 'SELECT';
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.allowStopsInIssueVoidFile
                ? cmConstants.CHKISSUE_STOPCANCEL_INQUIRYID : cmConstants.CHKISSUE_INQUIRYID,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },

    print() {
        const options = {
            format: 'PDF',
            gridView: this.gridView,
        };

        this.buildExportModel(options);
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = this.allowStopsInIssueVoidFile
            ? cmConstants.CHKISSUE_STOPCANCEL_INQUIRYID : cmConstants.CHKISSUE_INQUIRYID;
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('REPORTING/viewCMIssueVoidsDetails');
        return Promise.resolve();
    },

});

workspaceHelper.publishedWidgets.add({
    id: 'CM_IV_LIST',
    view: IssuesVoidsList,
    options: {},
});

export default IssuesVoidsList;
