var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <h4 class=\"form-section-border\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.locationloginname",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":70}}}))
    + "</h4>\n</div>\n<div class=\"row\">\n    <div class=\"form-column col-md-4 required\">\n        <div data-region=\"clientLocationsRegion\"></div>\n    </div>\n    <div class=\"form-column col-md-4 required\">\n        <label for=\"loginName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.loginname",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":57}}}))
    + "</label>\n        <input id=\"loginName\" class=\"form-control\" data-bind=\"model\" name=\"loginName\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"loginName\"></span>\n    </div>\n</div>\n";
},"useData":true});