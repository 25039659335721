import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import useLocale from '../../hooks/useLocale';

import styles from './RtpCharCountTextArea.styles';

const propTypes = {
    name: PropTypes.string.isRequired,
    placeHolderText: PropTypes.string,
    rows: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
    internalMessage: PropTypes.string,
    invalidErrorMessage: PropTypes.string,
    setInvalidErrorMessage: PropTypes.func,
    errorIndicator: PropTypes.bool,
    disabled: PropTypes.shape({}),
    textAreaClass: PropTypes.string,
    labelText: PropTypes.string,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    internalMessage: '',
    placeHolderText: '',
    invalidErrorMessage: '',
    setInvalidErrorMessage: null,
    errorIndicator: false,
    disabled: {},
    textAreaClass: '',
    labelText: '',
};

const CharCountTextArea = ({
    name, placeHolderText, rows, maxLength, message, internalMessage,
    setMessage, invalidErrorMessage, setInvalidErrorMessage, errorIndicator,
    disabled, textAreaClass, labelText, classes,
}) => {
    const [remainingLength, setRemainingLength] = useState(maxLength);
    const [charCountClass, setCharCountClass] = useState(classes.charCountTextArea);
    const { getLocaleString } = useLocale();

    const updateRemainingCharacterCount = useCallback((value) => {
        const max = parseInt(maxLength, 10);
        if(value == null )
            return max;
        const newRemaining = max - value.length;
        if (newRemaining < 0) {
            return;
        }
        setRemainingLength(newRemaining);
    }, [maxLength]);

    useEffect(() => {
        if (setInvalidErrorMessage) {
            setInvalidErrorMessage('');
        }

        updateRemainingCharacterCount(message);
    }, [message, setInvalidErrorMessage, updateRemainingCharacterCount]);

    useEffect(() => {
        let theClass = textAreaClass || classes.charCountTextArea;
        if (errorIndicator) {
            theClass = `${theClass} ${classes.error}`;
        }
        setCharCountClass(theClass);
    }, [classes.charCountTextArea, classes.error, errorIndicator,
        internalMessage.length, textAreaClass]);

    const countCharacters = (evt) => {
        const inputValue = evt.target.value;
        updateRemainingCharacterCount(inputValue);
        setMessage(inputValue);
    };

    const generateLabel = () => {
        if (labelText) {
            return (<label htmlFor={name}>{labelText}</label>);
        }
        return (<label htmlFor={name} className="sr-only">{getLocaleString('rtp.conversation.enterMessage')}</label>);
    };

    return (
        <div>
            <div>
                {generateLabel()}
                <textarea
                    className={charCountClass}
                    rows={rows}
                    name={name}
                    id={name}
                    placeholder={placeHolderText}
                    onChange={countCharacters}
                    maxLength={maxLength}
                    value={message}
                    data-qa="character-counter"
                    aria-controls="remaining-characters"
                    {...disabled}
                />
                <span className={classes.helpBlock}>{invalidErrorMessage}</span>
            </div>
            <div>
                <div id="remaining-characters" className={classes.remainingChars} role="status" aria-live="polite">
                    <span data-qa="remaining-characters">{remainingLength}</span>
                    {` ${getLocaleString('rtp.requestForInfo.charactersRemaining')}`}
                </div>
            </div>
        </div>
    );
};
CharCountTextArea.propTypes = propTypes;
CharCountTextArea.defaultProps = defaultProps;

export default withStyles(styles)(CharCountTextArea);
