import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default function (options) {
    const LoginModel = Model.extend({
        validators: {
            realm: {
                description: locale.get('logon.prompt.usergroup'),
                exists: true,
            },

            username: {
                description: locale.get('logon.prompt.userid'),
                exists: true,
            },

            password: {
                description: locale.get('logon.prompt.password'),
                exists: true,
            },
        },

        loginError: false,
    });
    return new LoginModel(options);
}
