import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import dateUtil from 'common/util/dateUtil';
import entitlements from 'common/dynamicPages/api/entitlements';
import Formatter from 'system/utilities/format';
import moment from 'moment';
import productHelper from 'common/dynamicPages/api/productHelper';
import services from 'services';
import userInfo from 'etc/userInfo';
import http from '@glu/core/src/http';
import NestedModel from '@glu/core/src/nestedModel';
import Scheduler from '@glu/scheduler';
import util from '@glu/core/src/util';
import template from './schedulePaymentWidget.hbs';

let dateFormat;
let dateFormats;

const days = [
    'WEEKLYDAYMON',
    'WEEKLYDAYTUE',
    'WEEKLYDAYWED',
    'WEEKLYDAYTHURS',
    'WEEKLYDAYFRI',
    'WEEKLYDAYSAT',
    'WEEKLYDAYSUN',
];

export default BaseWidget.extend({
    template,
    className: 'ui-widget field-container',

    regions: {
        schedRegion: '[data-hook="getSchedulerRegion"]',
    },

    ui: {
        businessDayMode: '[data-hook="getBusinessDayMode"]',
        recurCheck: '[data-hook="getRecurCheck"]',
    },

    events: {
        'click [data-hook="getRecurCheck"]': 'recurringSelect',
    },

    templateHelpers() {
        return {
            showBusinessDay: !this.isRTPTemplate && !this.isRFPTemplate,
            readOnly: this.options.state === 'view',
            showRecur: this.isScheduleActionEntitled,
            isACHAuthRules: this.isACHAuthRules,
            showModifyRecurCheckbox: !!(
                this.isSchedulePaySection
                    && this.model.context.actionMode === 'modify'
                    && !this.recurCheckUnchecked
            ),
        };
    },

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);
        const context = { ...this.model.context };
        const functionCode = context.functionCode
            || (context.actionData && context.actionData.functionCode);
        const entitlementsOpts = {};

        this.mode = options.state;
        this.isSchedulePaySection = options.widgetID === 'schedpaysection';
        this.isRTPTemplate = context.serviceName === '/payment/crtran';
        this.isACHAuthRules = context.typeCode === 'EBMPPRUL';
        this.isRFPTemplate = context.functionCode === 'REQTMPL';
        if (!this.isRFPTemplate) {
            const actionData = context.actionData || context.actionContext;
            if (actionData) {
                this.isRFPTemplate = actionData.functionCode === 'REQTMPL';
            }
        }

        entitlementsOpts.context = options.parentInitializeOptions.context;
        entitlementsOpts.entryMethod = options.model.get('ENTRYMETHOD');
        entitlementsOpts.typeCode = options.model.get('TYPE');
        entitlementsOpts.productCode = options.model.get('PRODUCT');
        entitlementsOpts.functionCode = options.model.get('FUNCTION') || productHelper.getFunctionCode(entitlementsOpts.context);
        const schedEntitlementPromise = entitlements.getEntitlements(entitlementsOpts);
        schedEntitlementPromise.then((result) => {
            this.isScheduleActionEntitled = result.actions.SPAYMT;
            this.setHasLoadedRequiredData(true);
            this.render();
        });
        // do not setup listener for RFP template
        if (!this.isRFPTemplate && !this.isACHAuthRules) {
            this.listenTo(this.model, 'change:ORIGCOMPNAME change:DEBIT_ACCOUNT_NUMBER', () => {
                this.setStartingEffectiveDate();
            });
        }

        /*
         * This listens for an event to suppress the whole schedule recurrence widget.
         * When logic specific to the payment type requires that there be no recurring
         * payments, the requisite policy file can call this event, and the schedule
         * widget will clear and hide itself.
         * bHide is a boolean where true deletes the schedule and hides the section.
         */
        if (functionCode === 'TMPL') {
            this.listenTo(this.parentView, 'schedulePayWidget:hideSchedule', (bHide) => {
                if (bHide) {
                    this.schedRegion.reset();
                    this.ui.businessDayMode.hide();
                    this.model.set({
                        PATTERNMODE: 'O',
                        recur: false,
                    });
                    this.model.schedModel = this.initializeSchedModel();
                    this.ui.recurCheck.prevObject.hide();
                } else {
                    this.ui.recurCheck.prevObject.show();
                }
            });
        }

        this.buildScheduleModel();
        dateFormat = userInfo.getDateFormat();
        dateFormats = {
            dateFormats: {
                server: 'YYYY-MM-DD',
                ui: dateFormat,
            },
        };
        this.appBus.on('scheduler:modifyschedulecheckbox', ({ readOnly }) => {
            this.disableBusinessOnlyInputs(readOnly);
        });
    },

    disableBusinessOnlyInputs(isDisabled = false) {
        this.$el.find('.businessDay-Mode input[type="radio"]').prop('disabled', isDisabled);
    },

    /**
     * @name getShowModifyScheduleCheckbox
     * @description determines whether or not to show the modify schedule checkbox
     */
    getShowModifyScheduleCheckbox() {
        return !!(this.isSchedulePaySection
                && this.model.context.actionMode === 'modify'
                && this.model.context.typeCode !== 'EBMPPRUL'
                && !this.recurCheckUnchecked);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const { schedModel } = this.model;
            if (this.model.schedModel) {
                const showModifyScheduleCheckbox = this.getShowModifyScheduleCheckbox();
                this.setShowWeekends();

                this.scheduler = new Scheduler({
                    model: schedModel,
                    startDate: dateFormats,
                    simpleEndDate: false,
                    startValue: this.handleStartDate(),
                    endValue: schedModel.get('ends'),
                    weekly: true,
                    monthly: true,
                    daily: false,
                    dueDate: this.isRFPTemplate,
                    ends: dateFormats,
                    readOnly: this.options.state === 'view' || showModifyScheduleCheckbox,
                    isACHAuthRules: this.isACHAuthRules,
                    mode: this.options.state,
                    // Do not auto update date field in ACH auth rules in editing mode
                    autoUpdateInput: this.isACHAuthRules && this.model.context.actionMode === 'modify' ? false : undefined,
                    showModifyScheduleCheckbox,
                });
                this.setMinDate(schedModel);
                if (this.isACHAuthRules) {
                    this.schedRegion.show(this.scheduler);
                }
                if ((this.model.get('PATTERNMODE') && this.model.get('PATTERNMODE') !== 'O')
                    || (this.parentModel && this.parentModel.get('recur'))
                    || this.model.get('recur')
                    || this.ui.recurCheck.prop('checked')) {
                    if (this.parentModel) {
                        this.parentModel.set('recur', true);
                    }
                    this.schedRegion.show(this.scheduler);
                    if (!this.isRTPTemplate && !this.isRFPTemplate) {
                        this.ui.businessDayMode.show();
                        if (showModifyScheduleCheckbox) {
                            this.disableBusinessOnlyInputs(true);
                        }
                    }
                } else {
                    if (this.parentModel) {
                        this.parentModel.set('recur', false);
                    }
                    this.ui.businessDayMode.hide();
                }

                if (this.options.state === 'view') {
                    this.scheduler.$('input').prop('disabled', true);
                    this.scheduler.$('select').comboBox('disable', true);
                    this.scheduler.$('select').prop('disabled', true);
                }
            } else {
                this.ui.businessDayMode.hide();
            }
            this.setRecurCheck(this.model.get('PATTERNMODE'));
        } else {
            this.loadedRequiredData();
        }
    },

    setMinDate(schedModel) {
        if (this.model.context.actionMode === 'modify' || this.model.context.actionMode === 'INSERT') {
            const sD = moment(schedModel.get('starts'), 'YYYY-MM-DD');
            // if previously set startDate has passed, use earliest Day
            if (dateUtil.isValidPastDate(sD, new Date())) {
                this.scheduler.model.set('minDate', Formatter.formatDate(this.minDate, 'YYYY-MM-DD'));
            } else {
                /*
                 * Otherwise, use the earlier day between the two : earliestDay and
                 * previously set startDay
                 */
                const mD = dateUtil.getEarlierDate(moment(this.minDate, 'YYYY-MM-DD'), moment(schedModel.get('starts'), 'YYYY-MM-DD'));
                this.scheduler.model.set('minDate', Formatter.formatDate(mD, 'YYYY-MM-DD'));
            }
        }
    },

    handleStartDate() {
        const { schedModel } = this.model;
        const schedModelStarts = schedModel && schedModel.get('starts');
        const startingEffectiveDate = this.model.get('STARTINGEFFECTIVEDATE');

        return Formatter.formatDate(
            startingEffectiveDate || schedModelStarts || +new Date(),
            'YYYY-MM-DD',
        );
    },

    hasLoadedRequiredData() {
        if (this.model.context.actionMode === 'modify') {
            return this.hasLoaded && !!this.minDate;
        }
        return this.hasLoaded;
    },

    loadedRequiredData() {
        if (this.isRFPTemplate || this.isACHAuthRules) {
            this.setStartingEffectiveDateManually();
        } else {
            this.setStartingEffectiveDate(true);
        }
    },

    initializeSchedModel(attrs = {}) {
        const schedModel = new NestedModel(attrs);
        /*
         * HACK: NH-110556 due to nestedModel failure to access necessary attributes
         * we needed to manually connect nested model attribute to non nested attribute
         */
        this.listenTo(schedModel, 'change', () => {
            const recAttrs = util.clone(schedModel.attributes);
            delete recAttrs.recurrence;

            /**
             * the array keeps getting converted to a string
             * so let's make sure it stays an array
             */
            if (recAttrs.onN && !Array.isArray(recAttrs.onN)) {
                recAttrs.onN = [recAttrs.onN];
            }

            schedModel.set('recurrence', { ...schedModel.attributes.recurrence, ...recAttrs }, { silent: true });
        });
        return schedModel;
    },

    buildScheduleModel() {
        if ((this.model.get('PATTERNMODE') && this.model.get('PATTERNMODE') !== 'O') || (this.isACHAuthRules && this.mode !== 'insert')) {
            const schedModel = this.initializeSchedModel();

            const recurrence = {};

            // This is needed so scheduler doesn't think it is a new record
            schedModel.idAttribute = 'starts';

            schedModel.set('starts', this.model.get('STARTINGEFFECTIVEDATE').indexOf(' ') > -1 ? this.model.get('STARTINGEFFECTIVEDATE').substring(0, this.model.get('STARTINGEFFECTIVEDATE').indexOf(' ')) : this.model.get('STARTINGEFFECTIVEDATE'));

            if (this.model.get('ENDMODE') === 'noend') {
                schedModel.set('ends', false);
            } else if (this.model.get('ENDMODE') === 'numoccur') {
                schedModel.set('ends', parseInt(this.model.get('ENDCYCLES'), 10));
            } else {
                schedModel.set('ends', this.model.get('ENDINGEFFECTIVEDATE').indexOf(' ') > -1 ? this.model.get('ENDINGEFFECTIVEDATE').substring(0, this.model.get('ENDINGEFFECTIVEDATE').indexOf(' ')) : this.model.get('ENDINGEFFECTIVEDATE'));
            }

            // Monthly
            if (this.model.get('PATTERNMODE') === 'M') {
                schedModel.set('type', 'MONTHLY');

                if (this.model.get('MONTHLYMODE') === 'specific') {
                    recurrence.intervalType = 'DAY';
                    recurrence.nMonths = this.model.get('MONTHLYMONTH1');
                    recurrence.onN = [Number(this.model.get('MONTHLYDAY1'))];
                } else {
                    recurrence.intervalType = this.model.get('MONTHLYDAYTYPE').toUpperCase();
                    recurrence.nMonths = this.model.get('MONTHLYMONTHNUMBER');
                    recurrence.onN = [this.model.get('MONTHLYDAYNUMBER').toUpperCase()];
                }
            } else {
                // Weekly
                schedModel.set('type', 'WEEKLY');

                recurrence.nWeeks = this.model.get('WEEKLYWEEKS');
                recurrence.onN = [];

                util.each(days, (day, idx) => {
                    if (this.model.get(day) === '1') {
                        recurrence.onN.push(idx + 1);
                    }
                });
            }

            schedModel.set(recurrence);

            if (!util.isEmpty(this.model.get('DUE_DATE_INPUT_MODE')) && !util.isEmpty(this.model.get('EXPIRATION_DATE_INPUT_MODE'))) {
                const scheduleData = {
                    dueDateInputMode: this.model.get('DUE_DATE_INPUT_MODE'),
                    expiresDateInputMode: this.model.get('EXPIRATION_DATE_INPUT_MODE'),
                };
                if (this.model.get('DUE_DATE_INPUT_MODE') === 'DUE_ON') {
                    scheduleData.dueOnTheDay = [Number(this.model.get('PAYMENT_DUE_DAYS'))];
                    scheduleData.dueOnDateTime = this.model.get('DUE_DATE_INPUT_TIME');
                }
                if (this.model.get('DUE_DATE_INPUT_MODE') === 'DUE_WITHIN') {
                    scheduleData.dueWithin = this.model.get('PAYMENT_DUE_DAYS');
                    scheduleData.dueWithinDateTime = this.model.get('DUE_DATE_INPUT_TIME');
                }
                scheduleData.expiresOnDueDate = (this.model.get('EXPIRES_ON_DUE_DATE') === '1' ? '1' : '');
                if (this.model.get('EXPIRATION_DATE_INPUT_MODE') === 'EXPIRES_ON') {
                    scheduleData.expiresOnTheDay = [Number(this.model.get('REQUEST_EXPIRES_DAYS'))];
                    scheduleData.expiresOnDateTime = this.model.get('EXPIRES_DATE_INPUT_TIME');
                }
                if (this.model.get('EXPIRATION_DATE_INPUT_MODE') === 'EXPIRES_WITHIN') {
                    scheduleData.expiresWithin = this.model.get('REQUEST_EXPIRES_DAYS');
                    scheduleData.expiresWithinDateTime = this.model.get('EXPIRES_DATE_INPUT_TIME');
                }
                schedModel.set(scheduleData);
            }

            schedModel.set('readOnly', this.parentView.state !== 'modify');

            this.model.schedModel = schedModel;
        }
    },

    /**
     * Method to toggle the mandatory state and validator for the template fields when
     * the template is scheduled for recurring payments.
     *
     * @param {String} field - field name of the affected field
     * @param {boolean} required - if the affected field is to be mandatory
     * @param {boolean} isWidgetField - if the affected field visibility is controled by a
     *     containing widget such as the fields in the fx amount widget for
     *     international cross currency wires and transfers. Default is false.
     */
    toggleRequiredField(field, required, isWidgetField = false) {
        const $field = this.parentView.$(`[name="${field}"]`);
        if ($field.length === 0) {
            return;
        }
        const updateModel = this.model;
        $field.prop('required', required);
        $field.closest(isWidgetField ? '.widget-field-container' : '.field-container')
            .toggleClass('required', required);
        if (required) {
            updateModel.addValidator(
                field,
                {
                    description: updateModel.fieldData[field].fieldLabel,
                    exists: true,
                },
            );
        } else {
            updateModel.removeValidator(field);
        }
    },

    /**
     * Method to toggle the mandatory state and validator for the template fields when
     * the template is scheduled for recurring payments. This includes amount specific
     * minimum value validation.
     *
     * @param {String} field - field name of the amount field
     * @param {boolean} required - if the amount field is to be mandatory
     * @param {boolean} isWidgetField - if the amount field visibility is controled by a
     *     containing widget such as the amount fields in the fx amount widget for
     *     international cross currency wires and transfers. Default is false.
     */
    toggleRequiredAmount(field, required, isWidgetField = false) {
        const $field = this.parentView.$(`[name="${field}"]`);
        if ($field.length === 0) {
            return;
        }
        const updateModel = ((field === 'AMOUNT' || field === 'AMOUNT1') && this.parentView.childView)
            ? this.parentView.childView.model : this.model;
        $field.prop('required', required);
        $field.closest(isWidgetField ? '.widget-field-container' : '.field-container')
            .toggleClass('required', required);
        if (required) {
            updateModel.addValidator(
                field,
                {
                    description: updateModel.fieldData[field].fieldLabel,
                    exists: true,
                    minValue: 0.01,
                },
            );
        } else {
            updateModel.removeValidator(field);
        }
    },

    recurringSelect(e) {
        const isChecked = this.$(e.target).prop('checked');
        let product = this.model.get('PRODUCT');
        let typeCode = this.model.get('TYPE');
        let setRequired = true;
        // RTGS default, then we test for ACH below
        let amountField = 'CREDIT_AMOUNT';
        if (!product && this.model && this.model.context) {
            ({ productCode: product } = this.model.context);
        }
        if (!typeCode && this.model && this.model.context) {
            ({ typeCode } = this.model.context);
        }
        // update amountField here, if conditions apply - for ACH
        if ((product === 'USACH' || product === 'PAY')) {
            if (this.parentView.childView && this.parentView.childView.model) {
                if (this.parentView.childView.model.context.serviceNameTypeCode === 'BDACHTP') {
                    // tax payments are slightly different
                    amountField = 'AMOUNT1';
                } else {
                    amountField = 'AMOUNT';
                }
            } else {
                /*
                 * Only when there's a childView (new template) we toggle the amount field
                 * Otherwise, the amount field is not visible and not available to toggle
                 */
                setRequired = false;
            }
        } else if (product === 'RTGS' && typeCode === 'FEDTAX') {
            amountField = 'AMOUNT1';
        }

        if (isChecked) {
            if (this.parentModel) {
                this.parentModel.set('recur', true);
            }
            this.model.set({
                NONBUSINESSDAYMODE: 'prev',
                ENDMODE: 'noend',
                recur: true,
            });

            if (!this.model.schedModel) {
                const schedModel = this.initializeSchedModel({
                    ends: false,
                });

                this.model.schedModel = schedModel;
            }
            const showModifyScheduleCheckbox = this.getShowModifyScheduleCheckbox();

            this.setShowWeekends();

            this.scheduler = new Scheduler({
                model: this.model.schedModel,
                startDate: dateFormats,
                simpleEndDate: false,
                startValue: this.model.schedModel.get('starts') || Formatter.formatDate(new Date()),
                weekly: true,
                monthly: true,
                yearly: false,
                daily: false,
                dueDate: this.isRFPTemplate,
                ends: dateFormats,
                readOnly: this.options.state === 'view' || showModifyScheduleCheckbox,
                isACHAuthRules: this.isACHAuthRules,
                showModifyScheduleCheckbox,
            });
            this.setMinDate(this.model.schedModel);
            this.schedRegion.show(this.scheduler);
            if (!this.isRTPTemplate && !this.isRFPTemplate) {
                this.ui.businessDayMode.show();
                if (showModifyScheduleCheckbox) {
                    this.disableBusinessOnlyInputs(true);
                }
            }
        } else {
            this.recurCheckUnchecked = true;
            this.schedRegion.reset();
            this.ui.businessDayMode.hide();
            if (this.parentModel) {
                this.parentModel.set('recur', false);
            }
            this.model.set({
                PATTERNMODE: 'O',
                recur: false,
            });
            delete this.model.schedModel;
        }

        const isAmountVisible = this.model.fieldData[amountField]
            && this.model.fieldData[amountField].visible;
        const isFxAmountVisible = this.model.fieldData.FXPAYMENTWIDGET
            && this.model.fieldData.FXPAYMENTWIDGET.visible;

        if (setRequired && isAmountVisible) {
            // amount needs to be mandatory if selected and amount is visible
            this.toggleRequiredAmount(amountField, isChecked);

            /*
             * For transactions with multiple amount fields, the amount type should be
             * mandatory when the amount is mandatory.
             */
            if (amountField === 'AMOUNT1' && this.model.fieldData.AMOUNTTYPE1
                    && this.model.fieldData.AMOUNTTYPE1.visible) {
                this.toggleRequiredField('AMOUNTTYPE1', isChecked);
            }
        } else if (setRequired && isFxAmountVisible && product === 'RTGS') {
            /*
             * For FX transactions there may be two amount fields on screen. When the FX widget
             * is visible then the individual amount fields may not be visible by default on
             * the model. In this case it is important to reset the mandatory validators on
             * each of the enterable amount fields.
             */
            const isDebit = this.model.get('ENTERED_AMT_FLAG') === 'D'
                    || this.model.get('CREDIT_DEBIT_ENTERED') === 'Debit';
            this.toggleRequiredAmount('CREDIT_AMOUNT', !isDebit && isChecked, true);
            this.toggleRequiredAmount('DEBIT_AMOUNT', isDebit && isChecked, true);
        }
    },

    setRecurCheck(patternMode) {
        if ((patternMode && patternMode !== 'O')
                || (this.parentModel && this.parentModel.get('recur'))
                || this.model.get('recur')) {
            this.ui.recurCheck.prop('checked', true);
        }
    },

    setStartingEffectiveDate(setMinDateOnly) {
        const dateOpts = {
            paymentType: this.model.jsonData.typeInfo.typeCode,
            subType: this.model.jsonData.subtype,
            dateProductType: 'SCHEDULED',
            debitBank: this.model.get('OFFSETBANKCODE') || this.model.get('DEBIT_BANK_CODE'),
            debitCurrency: this.model.get('ORIGCURRENCYCODE') || this.model.get('DEBIT_CURRENCY') || this.model.get('CREDIT_CURRENCY'),
            debitBankCountry: this.model.get('ORIGBANKCOUNTRY') || this.model.get('DEBIT_COUNTRY'),
            creditCurrency: this.model.get('DESTCURRENCYCODE') || this.model.get('CREDIT_CURRENCY'),
            tnum: this.model.get('TNUM'),
        };

        // for the first time user selects debit account before checking make recur
        if (!this.model.schedModel) {
            const schedModel = this.initializeSchedModel({
                ends: false,
            });

            this.model.schedModel = schedModel;
        }

        const dateService = services.generateUrl('/date/list');

        return new Promise((resolve, reject) => {
            http.post(dateService, dateOpts, (response) => {
                const currentStartingDay = this.model.schedModel.get('starts');
                const defaultValidDay = response.defaultDay;
                const earliestValidDay = response.earliestDay;

                /*
                 * If the previously set start date is before the earliest valid date then
                 * replace it with the default valid date.
                 */
                if (this.model.context.actionMode === 'modify' && this.model.schedModel
                        && dateUtil.isValidPastDate(currentStartingDay, earliestValidDay)) {
                    this.model.schedModel.set('starts', Formatter.formatDate(defaultValidDay, 'YYYY-MM-DD'));
                }

                /*
                 * Setup the scheduler once the data call has completed. The scheduler does not
                 * currently support the setting of valid business days when selecting days of
                 * the week. Once it does this will need to be updated using the BUSINESSDAY
                 * value returned by the date call
                 */
                if (!setMinDateOnly) {
                    const showModifyScheduleCheckbox = this.getShowModifyScheduleCheckbox();
                    this.setShowWeekends();

                    this.scheduler = new Scheduler({
                        model: this.model.schedModel,
                        startDate: dateFormats,
                        startValue: Formatter.formatDate(response.earliestDay, 'YYYY-MM-DD'),
                        weekly: true,
                        monthly: true,
                        daily: false,
                        ends: dateFormats,
                        readOnly: this.options.state === 'view' || showModifyScheduleCheckbox,
                        isACHAuthRules: this.isACHAuthRules,
                        showModifyScheduleCheckbox,
                    });
                } else {
                    this.minDate = Formatter.formatDate(response.earliestDay, 'YYYY-MM-DD');
                }
                resolve(response);
                this.render();
            }, (result) => {
                reject({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                });
            });
        });
    },

    /**
     * @name setStartingEffectiveDateManually
     * @description - set up the schedule model to start today and not end
     */
    setStartingEffectiveDateManually() {
        // for the first time user selects debit account before checking make recur
        if (!this.model.schedModel) {
            const schedModel = this.initializeSchedModel({
                ends: false,
                starts: Formatter.formatDate(new Date(), 'YYYY-MM-DD'),
            });
            if (this.isRFPTemplate && (util.isEmpty(this.model.get('PATTERNMODE')) || this.model.get('PATTERNMODE') === 'O')) {
                schedModel.set({
                    dueOnDateTime: this.model.fieldData.DUE_DATE_INPUT_TIME.value,
                    dueWithinDateTime: this.model.fieldData.DUE_DATE_INPUT_TIME.value,
                    expiresOnDateTime: this.model.fieldData.EXPIRES_DATE_INPUT_TIME.value,
                    expiresWithinDateTime: this.model.fieldData.EXPIRES_DATE_INPUT_TIME.value,
                });
            }

            this.model.schedModel = schedModel;
        }
        if (!this.isACHAuthRules) {
            this.model.schedModel.set('expirationMaxValue', this.model.fieldData.EXPIRATION.value);
        }
        this.minDate = Formatter.formatDate(new Date(), 'YYYY-MM-DD');
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    setShowWeekends() {
        // RTP & RFP require all days to appear
        if (this.isRTPTemplate || this.isRFPTemplate) {
            this.model.schedModel.set('showWeekends', true);
        }
    },
});
