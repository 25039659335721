import util from '@glu/core/src/util';
import $ from 'jquery';
import { getMaskingConfiguration } from 'common/util/maskingUtil';
import PaymentUtil from 'common/util/paymentUtil';
import fxPaymentUtil from 'common/util/fxPaymentUtil';
import rtgsCommon from './rtgsCommon';
import rtgsBeneInfoCommon from './rtgsBeneInfoCommon';

export default function (form, initialState) {
    /*
     * Multi Bank does not allow Freeform input of the Bene Bank, Correspondent,
     * or Originator bank values. The address fields are not set to be visible in MDF.
     * This way the address widget can take care of displaying the address fields as needed.
     * The DEBITPARTYISINSTRUCTINGPARTY checkbox values are opposite of most, '0' is checked,
     * '1' is not checked, because the checkbox represents the opposite of the field.
     * That is, DEBITPARTYISINSTRUCTINGPARTY checkbox is checked when the debit party is
     * different from the Instructing party.
     */
    const { model } = form.formView;

    const debitAcctFieldList = ['DEBIT_ACCOUNT_BANK_NAME', 'DEBIT_ACCOUNT_TITLE', 'DEBIT_BANKNAME', 'DEBIT_BANK_CODE'];
    const beneBankFieldList = ['BENE_BANK_NAME', 'BENE_BANK_ADDRESS_1', 'BENE_BANK_ADDRESS_2', 'BENE_BANK_CITY', 'BENE_BANK_COUNTRY'];
    const beneAcctFieldList = ['BENE_BANK_ACCOUNT_TYPE', 'BENE_BANK_ID'];
    const beneInfoFieldList = ['BENE_NAME', 'BENE_ACCOUNT_TYPE', 'BENE_ACCOUNT', 'BENE_ADDRESS_1', 'BENE_ADDRESS_2', 'BENE_CITY', 'BENE_COUNTRY'];
    const bankToBankCodesThatRequireInfo = ['CMTO', 'PHON', 'OTHR'];
    const instructionCodeIdList = ['1', '2', '3', '4'];
    const originatorAddrPrefix = 'ORIGINATOR_';
    const instructingPartyCheckbox = form.field('ORIGINATOR_ID');
    const originatorId = form.field('ORIGINATOR_ID_VIRTUALFIELD');
    const { functionCode } = model.jsonData.typeInfo;
    const functionCodeType = functionCode === 'INST' ? 'INST' : 'TMPL';
    const isManualOrigBank = false;
    const instructingPartyIsDiffFromDebitParty = form.field('DEBITPARTYISINSTRUCTINGPARTY');
    const instrPartyChecked = instructingPartyIsDiffFromDebitParty.isChecked();
    const changeableFields = [
        'VALUE_DATE',
        'CREDIT_AMOUNT',
        'DEBIT_AMOUNT',
        'CUSTOMER_REFERENCE',
        'SPECIAL_INSTRUCTIONS',
        'OBI_TEXT_1',
        'OBI_TEXT_2',
        'OBI_TEXT_3',
        'OBI_TEXT_4',
        'BENE_ACCOUNT_MASK_TOGGLE',
    ];
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;
    const maskConfig = getMaskingConfiguration();

    const showHideReadOnly = function (array, controlField, isManual) {
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnly(!isManual);
        });
    };

    /*
     * The showHideInstructions function protects the Bank to Bank Info field if
     * the type is not in the bankToBankCodesThatRequireInfo list.
     */
    const showHideInstructions = function (array) {
        util.each(array, (fieldId) => {
            if (form.field(`INSTRUCTIONCODE${fieldId}`).isNotEmpty() && !util.contains(bankToBankCodesThatRequireInfo, form.field(`INSTRUCTIONCODE${fieldId}`).getValue())) {
                form.field(`INSTRUCTIONS_TO_BENE_BANK_${fieldId}`).setValue('');
                model.set(`INSTRUCTIONS_TO_BENE_BANK_${fieldId}`, '');
                form.field(`INSTRUCTIONS_TO_BENE_BANK_${fieldId}`).shouldBeReadOnly(true);
            } else {
                form.field(`INSTRUCTIONS_TO_BENE_BANK_${fieldId}`).shouldBeReadOnly(false);
            }
        });
    };

    const protectBeneInfo = function (array) {
        util.each(array, (fieldName) => {
            form.field(fieldName).shouldBeReadOnly(true);
        });
    };

    const protectFields = function () {
        Object.keys(form.fields || {}).forEach((fieldName) => {
            if (changeableFields.indexOf(fieldName) === -1) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
        });
        $('.lookup').addClass('hidden');
    };

    /**
     * @method - openOptionalSections
     * This function shows or hides the sections under the "Additional Information"
     * region if there is any data to edit/display.
     */
    const openOptionalSections = function () {
        if (form.formView.state === 'modify' || form.formView.state === 'restore') {
            if (form.field('REGULATORYREPORTINGCODEWORD').isNotEmpty()) {
                form.field('REGULATORYREPORTINGCODEWORD').$el.closest('.panel-collapse').collapse('show');
            }
            if (form.field('INSTRUCTIONCODE1').isNotEmpty()) {
                form.field('INSTRUCTIONCODE1').$el.closest('.panel-collapse').collapse('show');
            }
        } else if (form.formView.state === 'view') {
            if (model.get('CORRESPONDENT_ID') === '' && model.get('CORRESPONDENT_TYPE') === '') {
                form.formView.$(`.RTGS_${functionCodeType}_MULTIBK_INTBANKGROUP`).parent().addClass('hide');
            }
            if (model.get('REGULATORYREPORTINGCODEWORD') === '' && model.get('REGULATORYREPORTINGLINE1') === '' && model.get('REGULATORYREPORTINGLINE2') === '' && model.get('REGULATORYREPORTINGLINE3') === '') {
                form.formView.$(`.RTGS_${functionCodeType}_MULTIBK_REGULATORY_GROUP`).parent().addClass('hide');
            }
            if (model.get('INSTRUCTIONCODE1') === '' && model.get('INSTRUCTIONCODE2') === '' && model.get('INSTRUCTIONCODE3') === '' && model.get('INSTRUCTIONCODE4') === '') {
                form.formView.$(`.RTGS_${functionCodeType}_MULTIBK_BANKTOBANK_GROUP`).parent().addClass('hide');
            }
            if (model.get('OBI_TEXT_1') === '' && model.get('OBI_TEXT_2') === '' && model.get('OBI_TEXT_3') === '' && model.get('OBI_TEXT_4') === '') {
                form.formView.$(`.RTGS_${functionCodeType}_MULTIBK_PAYDETAILS_GROUP`).parent().addClass('hide');
            }
        }
    };

    /**
     *  @method - updateRegReporting
     *  This function updates the form fields in the Regulatory Reporting section
     * based off the REGULATORYREPORTINGCODEWORD
     *  select box value.
     */
    const updateRegReporting = function () {
        const regReportLine = form.field('REGULATORYREPORTINGLINE1');

        if (model.get('REGULATORYREPORTINGCODEWORD') === 'BENEFRES' || model.get('REGULATORYREPORTINGCODEWORD') === 'ORDERRES') {
            const beneCountry = form.field('BENE_COUNTRY');
            const reportCountry = form.field('REGULATORYREPORTINGCOUNTRY');

            form.formView.$(`.RTGS_${functionCodeType}_MULTIBK_REGULATORY_GROUP_4`).removeClass('hide');
            form.formView.$('#REGULATORYREPORTINGLINE2, #REGULATORYREPORTINGLINE3').attr('maxlength', 33);
            regReportLine.shouldBeReadOnly(true);

            if (model.get('REGULATORYREPORTINGCODEWORD') === 'ORDERRES') {
                const debitCountry = model.get('DEBIT_COUNTRY');

                if (!util.isEmpty(debitCountry)) {
                    util.defer(() => {
                        model.set('REGULATORYREPORTINGLINE1', debitCountry);
                        model.set('REGULATORYREPORTINGCOUNTRY', debitCountry);
                        regReportLine.setValue(debitCountry);
                    });
                }
            } else if (!util.isEmpty(beneCountry.getValue())) {
                util.defer(() => {
                    model.set('REGULATORYREPORTINGCOUNTRY', form.field('BENE_COUNTRY').$el.val());
                    model.set('REGULATORYREPORTINGLINE1', form.field('BENE_COUNTRY').$el.val());
                    regReportLine.setValue(reportCountry.getValue());
                });
            }
        } else {
            regReportLine.shouldBeReadOnly(false);
            regReportLine.setValue('');
            form.formView.$(`.RTGS_${functionCodeType}_MULTIBK_REGULATORY_GROUP_4`).addClass('hide');
            form.formView.$('#REGULATORYREPORTINGLINE2, #REGULATORYREPORTINGLINE3').attr('maxlength', 35);
        }
    };

    /**
     *  @method - updateRegReportingLine
     *  This function updates the REGULATORYREPORTINGLINE1 form field with the
     * value from the
     *  REGULATORYREPORTINGCOUNTRY select box.
     */
    const updateRegReportingLine = function () {
        const reportCountry = form.field('REGULATORYREPORTINGCOUNTRY');
        util.defer(() => {
            model.set('REGULATORYREPORTINGLINE1', reportCountry.getValue());
        });
    };

    /**
     *  @method - updateRegReportingCountry
     *  This function updates the REGULATORYREPORTINGCOUNTRY select box with the
     * BENE_COUNTRY select box value
     *  if BENEFRES was selected in the REGULATORYREPORTINGCODEWORD select box.
     */
    const updateRegReportingCountry = function () {
        if (model.get('REGULATORYREPORTINGCODEWORD') === 'BENEFRES') {
            util.defer(() => {
                model.set('REGULATORYREPORTINGCOUNTRY', $('[name="BENE_COUNTRY"]').val());
            });
        }
    };

    /**
     *  @method - updateDebitCountry
     *  This function updates the REGULATORYREPORTINGCOUNTRY select box with the
     * DEBIT_COUNTRY select box value
     *  if ORDERRES was selected in the REGULATORYREPORTINGCODEWORD select box.
     */
    const updateDebitCountry = function () {
        if (model.get('REGULATORYREPORTINGCODEWORD') === 'ORDERRES') {
            model.set('REGULATORYREPORTINGCOUNTRY', model.get('DEBIT_COUNTRY'));
        }
    };

    /**
     *  @method toggleInstructingParty
     *  @param {boolean} display - true to display the Instructing Paty field (Originator_id)
     */
    const toggleInstructingParty = function (display) {
        instructingPartyCheckbox.shouldBeVisibleWhen(display).shouldBeRequiredWhen(display);
        instructingPartyCheckbox.$el.parent().toggleClass('required', display);
    };

    if (initialState) {
        model.on('change:REGULATORYREPORTINGCODEWORD', updateRegReporting);
        model.on('change:REGULATORYREPORTINGCOUNTRY', updateRegReportingLine);
        model.on('change:BENE_COUNTRY', updateRegReportingCountry);
        model.on('change:DEBIT_COUNTRY', updateDebitCountry);

        // TEMPORARY Fix: NH-33472
        form.field('UPDATEADDRESSBOOK').shouldBeHidden();
        // end NH-33472

        // Set up Instructing Party checkbox and Originator_ID field
        instructingPartyIsDiffFromDebitParty.$el.parent().removeClass('align-checkbox');
        form.field('ORIGINATOR_ID_VIRTUALFIELD').$el.parent().addClass('read-only-text');
        toggleInstructingParty(instrPartyChecked);
        instructingPartyCheckbox.shouldBeReadOnly(form.formView.state === 'view');
        instructingPartyCheckbox.$el.parent().toggleClass('read-only-text', form.formView.state === 'view');

        form.formView.$(`.RTGS_${functionCodeType}_MULTIBK_REGULATORY_GROUP_4`).addClass('hide');

        const amtBlock = form.formView.$('[data-validate="CREDIT_AMOUNT"]');
        PaymentUtil.shouldShowTemplateMaxAmt(
            allowMaxTmplAmount,
            form.formView,
            model,
            amtBlock,
            functionCode,
        );

        if (form.formView.model.context.entrymethod && form.formView.model.context.entrymethod === '1'
            && functionCode !== 'TMPL') {
            protectBeneInfo(beneInfoFieldList);
            protectBeneInfo(beneAcctFieldList);
            protectBeneInfo(beneBankFieldList);
        }

        if (form.formView.state === 'insert') {
            const beneBankType = form.field('BENE_BANK_TYPE');
            if (util.isEmpty(beneBankType.getValue())) {
                form.formView.model.set('BENE_BANK_TYPE', 'SWIFT');
            }
        }

        if (form.formView.state !== 'view') {
            model.on('change:DEBIT_ACCOUNT_NUMBER', (changedModel, accountNumber) => {
                fxPaymentUtil.evaluateUSDOnly(changedModel, [], accountNumber);

                if (fxPaymentUtil.USD_ONLY) {
                    model.set({
                        CREDIT_CURRENCY: 'USD',
                        DEBIT_CURRENCY: 'USD',
                    });
                }
                /*
                 *  Set the Originator Id from the Debit Account if the debit party
                 * checkbox says it is the same as the selected debit account (unchecked).
                 *  Defer updating the Originator fields because they get populated
                 * by the ComboBoxWidget when it validates the DEBIT_BANK_CODE.
                 */
                util.defer(() => {
                    if (!instructingPartyIsDiffFromDebitParty.isChecked()) {
                        const origId = (!util.isEmpty(model.get('DEBIT_ACCOUNT_NUMBER'))) ? model.get('DEBIT_ACCOUNT_NUMBER') : '';
                        model.set({
                            ORIGINATOR_ID: origId,
                            ORIGINATOR_ID_VIRTUALFIELD: origId,
                        });
                    }
                    rtgsBeneInfoCommon.showHideAddr(
                        form,
                        originatorAddrPrefix,
                        isManualOrigBank,
                    );
                });
            });

            model.on('change:BENE_BANK_ID', () => {
                if (util.isEmpty(model.get('BENE_BANK_ID'))) {
                    // reset the helper text when the attributes are cleared.
                    form.formView.trigger('lookupHelperText:clear', 'BENE_BANK_ID');
                }
            });

            /*
             * BENE_BANK_CURR_MAP is populated from the mapped data defined for the
             * beneficiary bank country drop down field. The credit currency should
             * only be defaulted to the mapped value when the UserGroup wire product
             * entitlements for defaulting the credit currency is enabled.
             */
            if (model.get('DEFAULTCREDITCURRENCYFLAG') === '1') {
                model.on('change:BENE_BANK_CURR_MAP', () => {
                    model.set('CREDIT_CURRENCY', model.get('BENE_BANK_CURR_MAP'));
                });
            }

            instructingPartyIsDiffFromDebitParty.$el.on('change', () => {
                const instrPartyIsChecked = instructingPartyIsDiffFromDebitParty.isChecked();
                if (!instrPartyIsChecked) {
                    model.set({
                        ORIGINATOR_ID: model.get('DEBIT_ACCOUNT_NUMBER'),
                        ORIGINATOR_ID_VIRTUALFIELD: model.get('DEBIT_ACCOUNT_NUMBER'),
                    });
                }
                toggleInstructingParty(instrPartyIsChecked);
            });

            fxPaymentUtil.evaluateUSDOnly(model, []);
        }

        if (form.formView.state === 'insert' || form.formView.state === 'modify' || form.formView.state === 'restore' || form.formView.state === 'repair') {
            // This updates the address widget for the Bene Bank fields.
            rtgsBeneInfoCommon.beneBankIdEntryMethodChange(form);
        }
        /*
         * CREDIT_CURRENCY and DEBIT_CURRENCY are relatedProperty fields of the CREDIT_AMOUNT
         * and DEBIT_AMOUNT. These fields have to be included in the visiblefields
         * to get the label text for the aria-label. It must be hidden though because it is
         * part of the amount template.
         */
        if (form.formView.state === 'insert' || (functionCode === 'TMPL' && form.formView.state === 'modify')) {
            form.field('CREDIT_CURRENCY').shouldBeHidden();
            form.field('DEBIT_CURRENCY').shouldBeHidden();
            if (form.formView.model.fieldData.CREDIT_CURRENCY.protected
                && form.formView.model.fieldData.CREDIT_CURRENCY.locked) {
                $('#CREDIT_CURRENCY').addClass('hide');
            }
            if (form.formView.model.fieldData.DEBIT_CURRENCY.protected
                && form.formView.model.fieldData.DEBIT_CURRENCY.locked) {
                $('#DEBIT_CURRENCY').addClass('hide');
            }
        } else if (form.formView.state === 'view') {
            /*
             * This needs to be separate for view because the currency fields do not
             * exist in the form fields.
             */
            $('#CREDIT_CURRENCY').addClass('hide');
            $('#DEBIT_CURRENCY').addClass('hide');
        } else {
            form.field('CREDIT_CURRENCY').shouldBeHidden();
            // The DEBIT_CURRENCY not associated to the amount field needs to be hidden.
            form.field('DEBIT_CURRENCY').$el.parents('.field-container').next(['id="DEBIT_CURRENCY"']).addClass('hide');
        }

        /*
         * Templates do not show the debit amount or rate information.  Template
         * code is always
         * read only on modify.
         */
        if (functionCode === 'TMPL') {
            if (form.formView.state === 'modify' || form.formView.state === 'restore') {
                form.field('TEMPLATE_CODE').shouldBeReadOnly(true);
            }
            /*
             * This needs to be separate for view because the currency fields do not
             * exist in the form fields.
             */
            if (form.formView.state === 'view') {
                $('#DEBIT_AMOUNT').addClass('hide');
            } else {
                form.field('DEBIT_AMOUNT').shouldBeHidden();
            }
            // Remove the DEBIT_AMOUNT since this could be a copy from payment.
            model.set('DEBIT_AMOUNT', '');
            form.field('EXCHANGE_RATE').shouldBeHidden();
            form.field('EXCHANGE_RATE_CONTRACTID').shouldBeHidden();
        }

        /*
         * This will open the optional sections that exist only for Multi-Bank payments that
         * are populated in modify mode.
         */
        openOptionalSections();

        if (!maskConfig.disableMask) {
            originatorId.$el.parent().addClass('hide');
        }
    }

    showHideReadOnly(debitAcctFieldList, 'DEBIT_ACCOUNT_NUMBER', false);
    showHideInstructions(instructionCodeIdList);

    // Protect fields when entering as Copy From Template or Copy Payment From Repetitive
    if (model.get('ENTRYMETHOD') && (model.get('ENTRYMETHOD') === '1' || model.get('ENTRYMETHOD') === '2')) {
        protectFields();
    }

    rtgsCommon.toggleStateProvince(
        model, model.get('BENE_COUNTRY'),
        $('input[name="BENE_PROVINCE"]').closest('.field-container'),
        $('select[name="BENE_STATE"]').closest('.field-container'),
    );
}
