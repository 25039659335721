var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":40}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":42},"end":{"line":9,"column":58}}}))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group field-container\">\n    <span class=\"radio-inline\">\n        <input type=\"radio\" data-hook=\"expires-date-on-radio\" id=\"expires-date"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":3,"column":78},"end":{"line":3,"column":85}}}) : helper)))
    + "-expires-on\" value=\"EXPIRES_ON\" name=\"expiresDateInputMode\" data-bind=\"model\"/>\n        <label for=\"expires-date"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":32},"end":{"line":4,"column":39}}}) : helper)))
    + "-expires-on\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.expiresOnThe",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":87}}}))
    + "</label>\n        <div class=\"radio has-text-input form-group\">\n            <select id=\"expires-on-the-day-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":6,"column":43},"end":{"line":6,"column":50}}}) : helper)))
    + "\" data-hook=\"expires-date-on-select\" class=\"form-control js-basic-expires-on-select\" data-bind=\"model\"\n                name=\"expiresOnTheDay\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"daysInMonth") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "            </select>\n            <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.expiresOnOfTheMonthAt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":18},"end":{"line":12,"column":62}}}))
    + "</span>\n            <div class=\"RFPScheduler-input-wrapper\">\n                <div>\n                    <input type=\"text\" id=\"expires-date-time-input-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":15,"column":67},"end":{"line":15,"column":74}}}) : helper)))
    + "\" class=\"form-control input-time\" data-hook=\"expires-on-date-time\" data-bind=\"model\" name=\"expiresOnDateTime\" />\n                    <span>"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"timeZoneAddOn"),depth0,{"name":"timeZoneAddOn","hash":{"userTimeZone":(depth0 != null ? lookupProperty(depth0,"userTimeZone") : depth0),"timeZoneClass":"addon-bottom"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n                </div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"expiresOnDateTime\"></span>\n            </div>\n        </div>\n    </span>\n</div>\n<div class=\"form-group field-container\">\n    <span class=\"radio-inline\">\n        <input type=\"radio\" data-hook=\"expires-date-within-radio\" id=\"expires-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":25,"column":83},"end":{"line":25,"column":90}}}) : helper)))
    + "-expires-within\" value=\"EXPIRES_WITHIN\" name=\"expiresDateInputMode\" data-bind=\"model\"/>\n        <label for=\"expires-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":26,"column":33},"end":{"line":26,"column":40}}}) : helper)))
    + "-expires-within\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.expires",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":57},"end":{"line":26,"column":87}}}))
    + "</label>\n\n        <div class=\"radio has-text-input form-group\">\n            <div class=\"RFPScheduler-input-wrapper\">\n                <div>\n                    <input type=\"text\" id=\"expires-within-the-day-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":31,"column":66},"end":{"line":31,"column":73}}}) : helper)))
    + "\" class=\"form-control\" data-hook=\"expires-within-days\" name=\"expiresWithin\" data-bind=\"model\" />\n                    <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.expiresDayAfterDueDateAt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":26},"end":{"line":32,"column":73}}}))
    + "</span>\n                </div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"expiresWithin\"></span>\n            </div>\n            <div class=\"RFPScheduler-input-wrapper\">\n                <div>\n                    <input type=\"text\" id=\"expires-within-date-time-input-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":38,"column":74},"end":{"line":38,"column":81}}}) : helper)))
    + "\" class=\"form-control input-time\" data-hook=\"expires-within-date-time\" name=\"expiresWithinDateTime\" data-bind=\"model\" />\n                    <span>"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"timeZoneAddOn"),depth0,{"name":"timeZoneAddOn","hash":{"userTimeZone":(depth0 != null ? lookupProperty(depth0,"userTimeZone") : depth0),"timeZoneClass":"addon-bottom"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n                </div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"expiresWithinDateTime\"></span>\n            </div>\n          </div>\n    </span>\n</div>\n";
},"usePartial":true,"useData":true});