import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';

import styles from './DefinitionList.styles';

const DefinitionList = ({
  classes, rowData, rowId, columns
}) => (
  <dl className={classes.root}>
    {columns.map((col) => (
      <Fragment key={`frag_${col.field}_${rowId}`}>
        <dt key={`dt_${col.fieldId}_${rowId}`} className={classes.dt}>
          {col.headerDisplay ? col.headerDisplay({ column: col, rowData }) : col.headerName}
        </dt>
        <dd key={`dd_${col.fieldId}_${rowId}`} className={classes.dd}>
          {col.cellDisplay
            ? col.cellDisplay({ rowData, column: col })
            : rowData[col.field]}
        </dd>
      </Fragment>
    ))}
  </dl>
);

DefinitionList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  rowData: PropTypes.shape({}).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default withStyles(styles)(DefinitionList);
