import CompositeView from '@glu/core/src/compositeView';
import util from '@glu/core/src/util';
import lineManagerTmpl from './lineManager.hbs';
import lineManagerReadOnlyTmpl from './lineManagerReadOnly.hbs';
import LineView from './line';

const LineManager = CompositeView.extend({
    itemView: LineView,

    initialize(options) {
        /*
         * options.collection -> lines
         * options.scenarios -> scenarios
         */
        this.scenarios = options.scenarios;
        this.manageLines = util.isUndefined(options.manageLines) ? true : options.manageLines;

        if (!this.manageLines) {
            this.template = lineManagerReadOnlyTmpl;
        }

        this.listenTo(
            this,
            {
                showEditMode: this.showEditMode,
                saveEditModeChanges: this.saveChanges,
            },
            this,
        );

        this.editMode = false;
    },

    itemViewContainer: '.line-list',

    itemViewOptions() {
        return {
            manageLines: this.manageLines,
            scenarios: this.scenarios,
        };
    },

    template: lineManagerTmpl,

    ui: {
        $btnAddLine: '.btn-show-add-line',
    },

    events: {
        'click @ui.$btnAddLine': 'addLine',
    },

    attributes: {
        // 'aria-controls': '#transactions-graph'
    },

    className: '',

    showEditMode() {
        this.editMode = true;
        this.showChildViewEditMode();
        this.$el
            .first()
            .find(':focusable')
            .first()
            .focus();
    },

    showChildViewEditMode() {
        this.$('.view-mode').addClass('hidden');
        this.$('.edit-mode').removeClass('hidden');
        this.$('.ui-draggable').draggable('destroy');
    },

    onAfterItemAdded(itemView) {
        if (this.editMode) {
            this.showChildViewEditMode();
            itemView.$el.find('input[type="text"]').val('').focus();
        }
    },

    saveChanges() {
        this.editMode = false;
        this.collection.each((model) => {
            model.trigger('saveChangesFromEditMode');
        });
        this.ui.$btnAddLine.addClass('hidden');
    },

    addLine() {
        this.collection.add({
            name: this.collection.getDefaultName(),
        });
    },
});

export default LineManager;
