import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import PrintExportBase from './printExportBase';
import printOptionsTmpl from './printOptions.hbs';

export default PrintExportBase.extend({
    template: printOptionsTmpl,

    initialize(options) {
        this.model = new Model({
            selection: options.selectedRows
                ? this.SELECTION_MODE_SELECT : this.SELECTION_MODE_ALL,
            expdata: options.expdata || 'summary',
            summaryRequiresInquiryId: !!options.summaryInquiryId,
            // default to summaryInquiryId when present
            inquiryId: options.summaryInquiryId || options.inquiryId,
            contextData: options.contextData,
            exportURL: options.exportURL,
        });

        if (options.showIncludeSummaryInfo) {
            // includeSummaryInfo defaults to 1
            this.model.set({ includeSummaryInfo: 1 });
        }

        this.dialogTitle = locale.get('common.printOptions.title');

        this.dialogButtons = [{
            text: locale.get('common.printOptions.button.print'),
            className: 'btn btn-primary',
            callback: 'print',
        }, {
            text: locale.get('common.printOptions.button.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    /**
     * Close this dialog and trigger the print:view:modal event and pass in this model
     */
    print() {
        dialog.close();
        this.trigger('print:view:modal', this.model);
    },
});
