const addToConversationReducer = (state, action) => {
    const {
        type, reasonCode, reasonType, message, errorMessage, invalidErrorMessage, currentTab,
    } = action;
    switch (type) {
    case 'DRAWER_CLOSED':
        return {
            ...state,
            showRequest: false,
            showAck: false,
            showButtons: true,
        };
    case 'SHOULD_SHOW_RESPONSE':
        return {
            ...state,
            showResponse: currentTab === 'submitted',
        };
    case 'DISPLAY_ACK':
        return {
            ...state,
            showAck: true,
            showButtons: false,
            messageType: 'ACK',
        };
    case 'DISPLAY_RFI':
        return {
            ...state,
            showRequest: true,
            showButtons: false,
            messageType: 'REQUEST',
            reasonType: 'I',
        };
    case 'RESET':
        return {
            ...state,
            showRequest: false,
            showAck: false,
            showButtons: true,
            invalidErrorMessage: '',
            message: '',
            errorMessage: '',
        };
    case 'SET_REASON_CODE':
        return {
            ...state,
            reasonCode,
        };
    case 'SET_REASON_TYPE':
        return {
            ...state,
            reasonType,
        };
    case 'SET_MESSAGE':
        return {
            ...state,
            message,
        };
    case 'START_SENDING':
        return {
            ...state,
            isSending: true,
            invalidErrorMessage: '',
        };
    case 'STOP_SENDING':
        return {
            ...state,
            isSending: false,
        };
    case 'STOP_DUE_TO_ERROR':
        return {
            ...state,
            isSending: false,
            errorMessage,
        };
    case 'CLEAR_MESSAGE':
        return {
            ...state,
            errorMessage: '',
            message: '',
        };
    case 'SET_INVALID_ERROR_MESSAGE':
        return {
            ...state,
            invalidErrorMessage,
        };
    default:
        return state;
    }
};

export default addToConversationReducer;
