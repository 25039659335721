import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import UserPendingChanges from 'app/administration/views/userMaintenance2/pendingChanges/pendingChanges';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import { modalClose } from 'common/modals/mobileGridModalUtil';
import template from './viewUserCentricPendingChanges.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    initialize(opts) {
        this.mode = opts.mode || constants.MODES.VIEW;
        this.buttons = opts.buttons;

        this.dialogTitle = `${this.model.get('USERGROUP')} - ${this.model.get('USERID')} - ${this.model.get('USERNAME')}`;

        this.dialogButtons = util.filter(util.map(this.buttons, (
            button,
            index,
        ) => ({
            text: button.label,
            className: index === 0 ? 'btn btn-primary' : 'btn btn-secondary',
            callback: button.action.toLowerCase(),
        })), button => button.callback !== constants.ACTIONS.SELECT);
        this.dialogButtons.push({
            text: locale.get('administration.close.btn'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        });
    },

    cancel() {
        modalClose(this.options.onClose, this);
        dialog.close();
    },

    approve() {
        this.doAction('approve');
    },

    doAction(action) {
        const options = {
            success: (response) => {
                this.trigger('userAction:success', response, action);
                this.cancel();
            },

            error: (response) => {
                this.trigger('userAction:error', response, action);
                this.cancel();
            },

            context: this.options.context,
        };

        this.model.sync(action, this.model, options);
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            this.initPendingChangesSection();
        }
    },

    /**
     * even though this method does not make any service calls,
     * this was added for consistency and as a placeholder in case
     * a service call is required in the future
     */
    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * @method initPendingChangesSection
     * Creates the panel section where user can preview the pending changes of
     * a user with 'modified' status. If the user has approval and rejection
     * permissions, they will also be given that option in the view
     */
    initPendingChangesSection() {
        /*
         * Extract out Approve and Reject buttons from User Permitted Actions
         * NOTE: reject does not exist yet but will need to be referenced when availble in
         * the future
         */
        const userActions = util.reduce(this.buttons, (actionsParam, btn) => {
            const actions = actionsParam;
            if (btn.action.toLowerCase() === constants.ACTIONS.APPROVE) {
                actions.approve = btn;
                actions.approve.callback = this.approve.bind(this);
            }
            return actions;
        }, {});

        // Create View
        this.pendingChangesView = new UserPendingChanges({
            userProfile: this.model,
            actions: userActions,
            noCollapse: true,
            noButtons: true,
        });

        this.pendingChangesRegion.show(this.pendingChangesView);
    },
});
