import util from 'underscore';
import $ from 'jquery';
import services from 'services';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';

// constants for index values
const METHODINDEX = 0;
const CONTACTALIAS = 1;
const CONTACTADDRESS = 2;
const CONTACTEXT = 3;
const CONTACTPAUSEIND = 4;
const CONTACTDELETE = 5;
const CONTACTVOICE = 6;
const CONTACTTEXT = 7;
const NUMBEROFFIELDS = 10;

const additionalContactFields = [
    ['ALERTMETHOD1', 'ALERTMETHODALIAS1', 'ALERTMETHODADDRESS1', 'ALERTMETHODADDREXT1', 'ALERTMETHODPAUSEIND1', 'ALERTMETHODDELETE1', 'ALERTMETHODVOICEFLAG1', 'ALERTMETHODTEXTFLAG1'],
    ['ALERTMETHOD2', 'ALERTMETHODALIAS2', 'ALERTMETHODADDRESS2', 'ALERTMETHODADDREXT2', 'ALERTMETHODPAUSEIND2', 'ALERTMETHODDELETE2', 'ALERTMETHODVOICEFLAG2', 'ALERTMETHODTEXTFLAG2'],
    ['ALERTMETHOD3', 'ALERTMETHODALIAS3', 'ALERTMETHODADDRESS3', 'ALERTMETHODADDREXT3', 'ALERTMETHODPAUSEIND3', 'ALERTMETHODDELETE3', 'ALERTMETHODVOICEFLAG3', 'ALERTMETHODTEXTFLAG3'],
    ['ALERTMETHOD4', 'ALERTMETHODALIAS4', 'ALERTMETHODADDRESS4', 'ALERTMETHODADDREXT4', 'ALERTMETHODPAUSEIND4', 'ALERTMETHODDELETE4', 'ALERTMETHODVOICEFLAG4', 'ALERTMETHODTEXTFLAG4'],
    ['ALERTMETHOD5', 'ALERTMETHODALIAS5', 'ALERTMETHODADDRESS5', 'ALERTMETHODADDREXT5', 'ALERTMETHODPAUSEIND5', 'ALERTMETHODDELETE5', 'ALERTMETHODVOICEFLAG5', 'ALERTMETHODTEXTFLAG5'],
    ['ALERTMETHOD6', 'ALERTMETHODALIAS6', 'ALERTMETHODADDRESS6', 'ALERTMETHODADDREXT6', 'ALERTMETHODPAUSEIND6', 'ALERTMETHODDELETE6', 'ALERTMETHODVOICEFLAG6', 'ALERTMETHODTEXTFLAG6'],
    ['ALERTMETHOD7', 'ALERTMETHODALIAS7', 'ALERTMETHODADDRESS7', 'ALERTMETHODADDREXT7', 'ALERTMETHODPAUSEIND7', 'ALERTMETHODDELETE7', 'ALERTMETHODVOICEFLAG7', 'ALERTMETHODTEXTFLAG7'],
    ['ALERTMETHOD8', 'ALERTMETHODALIAS8', 'ALERTMETHODADDRESS8', 'ALERTMETHODADDREXT8', 'ALERTMETHODPAUSEIND8', 'ALERTMETHODDELETE8', 'ALERTMETHODVOICEFLAG8', 'ALERTMETHODTEXTFLAG8'],
    ['ALERTMETHOD9', 'ALERTMETHODALIAS9', 'ALERTMETHODADDRESS9', 'ALERTMETHODADDREXT9', 'ALERTMETHODPAUSEIND9', 'ALERTMETHODDELETE9', 'ALERTMETHODVOICEFLAG9', 'ALERTMETHODTEXTFLAG9'],
    ['ALERTMETHOD10', 'ALERTMETHODALIAS10', 'ALERTMETHODADDRESS10', 'ALERTMETHODADDREXT10', 'ALERTMETHODPAUSEIND10', 'ALERTMETHODDELETE10', 'ALERTMETHODVOICEFLAG10', 'ALERTMETHODTEXTFLAG10'],
];

const additionalMultiboxFields = ['ALERTMETHOD1', 'ALERTMETHOD2', 'ALERTMETHOD3', 'ALERTMETHOD4', 'ALERTMETHOD5', 'ALERTMETHOD6', 'ALERTMETHOD7', 'ALERTMETHOD8', 'ALERTMETHOD9', 'ALERTMETHOD10'];
let allowSMS = true;
let allowVoice = true;

const getContactContainer = (elt) => {
    const method = elt.getValue();
    const parent = (method === 'PHONE') ? elt.$el.parents().eq(2).next().children()
        .eq(0)
        : elt.$el.parents().eq(1);
    return parent;
};

/**
 * @function resetValidations
 * @param {Array} contactFields - the current selection of contact fields
 * @param {Object} model - the form's model
 * @param {Object} form - the form view
 * removes the some specific contact field validations and removes any associated errors
 */
const resetValidations = (contactFields, model, form) => {
    const view = form.formView;
    // remove validators
    model.removeValidator(contactFields[CONTACTADDRESS]);
    model.validateField(contactFields[CONTACTADDRESS]);
    model.removeValidator(contactFields[METHODINDEX]);
    model.validateField(contactFields[METHODINDEX]);

    // remove possible error messages
    const $bindings = view.$('[data-bind]');
    $bindings.filter(`[data-validate="${contactFields[CONTACTADDRESS]}"]`).text('').closest('.has-error').removeClass('has-error');
    $bindings.filter(`[data-validate="${contactFields[METHODINDEX]}"]`).text('').closest('.has-error').removeClass('has-error');
};

/**
 * @function addPhoneValidations
 * @param {Array} contactFields - current selection of fields
 * @param {Object} model - the form's model
 * @param {Object} form - the form
 * Adds model validators for newly added phone contact
 */
const addPhoneValidations = (contactFields, model, form) => {
    model.addValidator(contactFields[CONTACTADDRESS], {
        description: locale.get('allReports.Phone_Number'),
        exists: true,
        matches: '^\\+?[1-9]\\d{1,14}$',
    });
    model.addValidator(contactFields[CONTACTEXT], {
        description: locale.get('ALERTMETHODADDREXT4'),
        matches: '^[0-9]*$',
    });
    if (serverConfigParams.get('EnableInternationalPhoneNumbers') !== 'true') {
        form.field(contactFields[CONTACTADDRESS]).$el.inputmask(userInfo.getPhoneFormat());
    }
};

const selectVoice = (contactFields, model, form) => {
    const view = form.formView;
    if (view.$(`[name="${contactFields[CONTACTVOICE]}"]`).is(':checked')) {
        form.field(contactFields[CONTACTEXT]).shouldBeVisible();
        form.field(contactFields[CONTACTPAUSEIND]).shouldBeVisible();
    } else {
        model.set(contactFields[CONTACTEXT], '');
        model.set(contactFields[CONTACTPAUSEIND], '');
        form.field(contactFields[CONTACTEXT]).shouldBeHidden();
        form.field(contactFields[CONTACTPAUSEIND]).shouldBeHidden();
    }
    addPhoneValidations(contactFields, model, form);
};

const resetContact = (contactFields, model, form) => {
    const view = form.formView;
    const $voiceCheckbox = view.$(`[name="${contactFields[CONTACTVOICE]}"]`);
    const $textCheckbox = view.$(`[name="${contactFields[CONTACTTEXT]}"]`);

    model.set(contactFields[CONTACTADDRESS], '');
    model.set(contactFields[CONTACTALIAS], '');
    model.set(contactFields[CONTACTEXT], '');
    model.set(contactFields[CONTACTPAUSEIND], '');
    model.set(contactFields[CONTACTVOICE], '0');
    model.set(contactFields[CONTACTTEXT], '0');

    // Make sure both checkboxes are active before resetting.
    if ($voiceCheckbox.length && $textCheckbox.length) {
        $voiceCheckbox.prop('checked', false);
        $textCheckbox.prop('checked', false);
    }

    resetValidations(contactFields, model, form);
};

/**
 * @function addEmailValidations
 * @param {Array} contactFields - current selection of fields
 * @param {Object} model - the form's model
 * @param {Object} form - the form
 * Adds model validation for a specific email contact
 */
const addEmailValidations = (contactFields, model, form) => {
    model.addValidator(contactFields[CONTACTADDRESS], {
        description: model.validators.EMAILADDRESS.description,
        exists: true,
        matches: model.validators.EMAILADDRESS.matches,
    });
    // remove input mask, if any, from contactaddress
    form.field(contactFields[CONTACTADDRESS]).$el.inputmask('remove');
};

/**
 * @function addFaxValidations
 * @param {Array} contactFields - current selection of fields
 * @param {Object} model - the form's model
 * @param {Object} form - the form
 * Adds model validation for a specific fax contact
 */
const addFaxValidations = (contactFields, model, form) => {
    model.addValidator(contactFields[CONTACTADDRESS], {
        description: locale.get('CM.FaxNumber'),
        exists: true,
        matches: '^\\+?[1-9]\\d{1,14}$',
    });
    if (serverConfigParams.get('EnableInternationalPhoneNumbers') !== 'true') {
        form.field(contactFields[CONTACTADDRESS]).$el.inputmask(userInfo.getPhoneFormat());
    }
};

/**
 * @function addAlertMethodValidation
 * @param {Array} contactFields - the contact fields for the current contact
 * @param {Object} model - the form model
 * Adds "exists" validation for the ALERTMETHOD field for the current set of contact fields
 */
const addAlertMethodValidation = (contactFields, model) => {
    model.addValidator(contactFields[METHODINDEX], {
        exists: true,
        description: locale.get('_ADMIN.MAINT.CEMAD_TM.*.ALERTMETHOD.label'),
    });
};

const switchContact = (contactFields, model, form) => {
    form.field(contactFields[CONTACTALIAS]).shouldBeVisible();
    form.field(contactFields[CONTACTADDRESS]).shouldBeVisible();
    const methodField = form.field(contactFields[METHODINDEX]);
    const method = methodField.getValue();
    const parent = getContactContainer(methodField);
    const phoneLabel = locale.get('allReports.Phone');
    const emailLabel = locale.get('administration.emailAddress');
    const faxLabel = locale.get('allReports.Fax');
    if (!parent.hasClass('contactMethodSection')) {
        parent.addClass('contactMethodSection');
    }
    const $labelElt = $(`label[for="${contactFields[CONTACTADDRESS]}"]`);
    if (method === 'PHONE') {
        // remove any existing style before phone
        methodField.$el.parents().eq(1).removeClass('contactMethodSection');
        // add style to container
        resetContact(contactFields, model, form);
        form.field(contactFields[CONTACTVOICE]).shouldBeVisibleWhen(allowVoice);
        form.field(contactFields[CONTACTTEXT]).shouldBeVisibleWhen(allowSMS);
        $labelElt.html(phoneLabel);

        // default the phone selection to 'voice'
        if (allowVoice && allowSMS) {
            form.field(contactFields[CONTACTVOICE]).$el.prop('checked', true);
            form.field(contactFields[CONTACTVOICE]).$el.prop('disabled', true);
            model.set(contactFields[CONTACTVOICE], '1');
            selectVoice(contactFields, model, form);
        } else if (allowVoice) {
            form.field(contactFields[CONTACTVOICE]).$el.prop('checked', true);
            form.field(contactFields[CONTACTVOICE]).$el.prop('disabled', true);
            model.set(contactFields[CONTACTVOICE], '1');
            selectVoice(contactFields, model, form);
        } else if (allowSMS) {
            form.field(contactFields[CONTACTTEXT]).$el.prop('checked', true);
            form.field(contactFields[CONTACTTEXT]).$el.prop('disabled', true);
            model.set(contactFields[CONTACTTEXT], '1');
            // selectVoice(contactFields,model,form);
        }

        // Show checkbox parents if selection requires it.
        if (allowVoice) {
            form.field(contactFields[CONTACTVOICE]).$el.parents().eq(1).show();
        }
        if (allowSMS) {
            form.field(contactFields[CONTACTTEXT]).$el.parents().eq(1).show();
        }
    } else {
        resetContact(contactFields, model, form);
        form.field(contactFields[CONTACTEXT]).shouldBeHidden();
        form.field(contactFields[CONTACTPAUSEIND]).shouldBeHidden();
        form.field(contactFields[CONTACTVOICE]).shouldBeHidden();
        form.field(contactFields[CONTACTTEXT]).shouldBeHidden();

        // Hide checkbox parents if selection requires it.
        form.field(contactFields[CONTACTVOICE]).$el.parents().eq(1).hide();
        form.field(contactFields[CONTACTTEXT]).$el.parents().eq(1).hide();

        if (form.field(contactFields[METHODINDEX]).getValue() === 'EMAIL') {
            $labelElt.html(emailLabel);
            addEmailValidations(contactFields, model, form);
        } else if (form.field(contactFields[METHODINDEX]).getValue() === 'FAX') {
            $labelElt.html(faxLabel);
            addFaxValidations(contactFields, model, form);
        }
    }
    addAlertMethodValidation(contactFields, model);
};

const deleteContact = (contactFields, model, form) => {
    model.set(contactFields[METHODINDEX], '');
    model.set(contactFields[CONTACTALIAS], '');
    model.set(contactFields[CONTACTADDRESS], '');
    model.set(contactFields[CONTACTEXT], '');
    model.set(contactFields[CONTACTPAUSEIND], '');
    model.set(contactFields[CONTACTVOICE], '0');
    model.set(contactFields[CONTACTTEXT], '0');
    // remove from display
    form.field(contactFields[METHODINDEX]).shouldBeHidden();
    form.field(contactFields[CONTACTALIAS]).shouldBeHidden();
    form.field(contactFields[CONTACTADDRESS]).shouldBeHidden();
    form.field(contactFields[CONTACTEXT]).shouldBeHidden();
    form.field(contactFields[CONTACTPAUSEIND]).shouldBeHidden();
    form.field(contactFields[CONTACTDELETE]).shouldBeHidden();
    form.field(contactFields[CONTACTVOICE]).shouldBeHidden();
    form.field(contactFields[CONTACTTEXT]).shouldBeHidden();
    const container = getContactContainer(form.field(contactFields[METHODINDEX]));
    container.removeClass('contactMethodSection');

    // display the add contact link
    if (!form.field('ADDANOTHERCONTACT').isVisible()) {
        form.field('ADDANOTHERCONTACT').shouldBeVisibleWhen(true);
    }
    resetValidations(contactFields, model, form);
};

const setStatesOfPhoneCheckboxes = (voiceBox, textBox) => {
    // voice checked, text not
    if (voiceBox.prop('checked') === true && textBox.prop('checked') === false) {
        voiceBox.prop('disabled', true);
    }
    // text checked, voice not
    if (textBox.prop('checked') === true && voiceBox.prop('checked') === false) {
        textBox.prop('disabled', true);
    }
    // both checked
    if (textBox.prop('checked') === true && voiceBox.prop('checked') === true) {
        textBox.prop('disabled', false);
        voiceBox.prop('disabled', false);
    }
};

const removeSelect2Option = (optionValue, form) => {
    const view = form.formView;
    // Iterate over the select2 fields:
    Object.keys(additionalMultiboxFields || {}).forEach((fieldKey) => {
        const currentFieldName = additionalMultiboxFields[fieldKey];
        const $currentField = view.$(`select[name="${currentFieldName}"]`);

        // Remove the value from the selects.
        $currentField.find(`option[value="${optionValue}"]`).remove();
    });
};

const hideAddressFields = (array, form) => {
    util.each(array, (value) => {
        for (let i = 0; i < value.length; i += 1) {
            const curField = form.field(value[i]);
            const $curParent = curField.$el.parent();
            const isNotEmpty = curField.isNotEmpty();

            // Show the field if it has value.
            curField.shouldBeVisibleWhen(isNotEmpty);

            // Change the parent container's display state to prevent "empty" parents.
            $curParent.parent().toggle(isNotEmpty);
        }
    });
};

const removePhoneOption = (voiceOrText, form) => {
    const view = form.formView;
    const removeVoice = (voiceOrText === 'VOICE');
    const removeText = (voiceOrText === 'TEXT');

    for (let i = 1; i <= NUMBEROFFIELDS; i += 1) {
        if (removeVoice) {
            view.$(`[name="ALERTMETHODVOICEFLAG${i}"]`).parent().parent().remove();
            view.$(`[name="ALERTMETHODTEXTFLAG${i}"]`).prop('checked', true).prop('disabled', true);
        } else if (removeText) {
            view.$(`[name="ALERTMETHODTEXTFLAG${i}"]`).parent().parent().remove();
            view.$(`[name="ALERTMETHODVOICEFLAG${i}"]`).prop('checked', true).prop('disabled', true);
        }
    }
};

const updateVoiceAndText = (displaySMS, displayVoice, form) => {
    util.each(additionalContactFields, (newField) => {
        if (form.field(newField[METHODINDEX]).getValue() === 'PHONE') {
            form.field(newField[CONTACTVOICE]).shouldBeVisibleWhen(displayVoice);
            form.field(newField[CONTACTTEXT]).shouldBeVisibleWhen(displaySMS);
        }
    });
};

const setByTenantConfig = (form) => {
    const tenantdata = {
        queryCriteria: {
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['TENANTID', userInfo.get('tenantId')],
            }],

            fieldName: 'TENANT_LOOKUP',
        },
    };

    /*
     * Pull the tenant configuration for secondary email, sms, phone, and fax
     * visibility.
     */
    http.post(
        services.alertsRecipients, tenantdata,
        (result) => {
            // If we have configurations, continue.
            if (result.queryResponse.QueryData.numRows > 0
                && result.queryResponse.QueryData.queryRows.length > 0) {
                // Iterate over the configurations and update the view accordingly.
                const list = result.queryResponse.QueryData.queryRows[0].mapDataList;
                for (let i = 0; i < list.length; i += 1) {
                    const configuration = list[i].toField;
                    const configurationValue = list[i].value;

                    /*
                     * Remove options from the Select2 if it is not allowed by the
                     * client configuration.
                     */
                    if (configuration === 'ALLOWSECONDARYEMAIL' && configurationValue !== 'Y') {
                        removeSelect2Option('EMAIL', form);
                    } else if (configuration === 'ALLOWSMS' && configurationValue !== 'Y') {
                        allowSMS = false;
                    } else if (configuration === 'ALLOWPHONE' && configurationValue !== 'Y') {
                        allowVoice = false;
                    } else if (configuration === 'ALLOWFAX' && configurationValue !== 'Y') {
                        removeSelect2Option('FAX', form);
                    }
                }

                // If both SMS and Phone are disabled, remove the phone option.
                if (!allowSMS && !allowVoice) {
                    removeSelect2Option('PHONE', form);
                    // If only SMS is enabled, remove the voice check box.
                } else if (allowSMS && !allowVoice) {
                    removePhoneOption('VOICE', form);
                    // If only Phone is enabled, remove the text check box.
                } else if (allowVoice && !allowSMS) {
                    removePhoneOption('TEXT', form);
                    // If both SMS and Phone are disabled, remove the phone option.
                    if (!allowSMS && !allowVoice) {
                        removeSelect2Option('PHONE', form);
                    }
                }
                updateVoiceAndText(allowSMS, allowVoice, form);
            }
        },
    );
};

const additionalContacts = [
    'ADDANOTHERCONTACT',
    [
        ['ALERTMETHOD1', 'ALERTMETHODALIAS1', 'ALERTMETHODADDRESS1', 'ALERTMETHODADDREXT1', 'ALERTMETHODPAUSEIND1', 'ALERTMETHODDELETE1', 'ALERTMETHODVOICEFLAG1', 'ALERTMETHODTEXTFLAG1'],
        ['ALERTMETHOD2', 'ALERTMETHODALIAS2', 'ALERTMETHODADDRESS2', 'ALERTMETHODADDREXT2', 'ALERTMETHODPAUSEIND2', 'ALERTMETHODDELETE2', 'ALERTMETHODVOICEFLAG2', 'ALERTMETHODTEXTFLAG2'],
        ['ALERTMETHOD3', 'ALERTMETHODALIAS3', 'ALERTMETHODADDRESS3', 'ALERTMETHODADDREXT3', 'ALERTMETHODPAUSEIND3', 'ALERTMETHODDELETE3', 'ALERTMETHODVOICEFLAG3', 'ALERTMETHODTEXTFLAG3'],
        ['ALERTMETHOD4', 'ALERTMETHODALIAS4', 'ALERTMETHODADDRESS4', 'ALERTMETHODADDREXT4', 'ALERTMETHODPAUSEIND4', 'ALERTMETHODDELETE4', 'ALERTMETHODVOICEFLAG4', 'ALERTMETHODTEXTFLAG4'],
        ['ALERTMETHOD5', 'ALERTMETHODALIAS5', 'ALERTMETHODADDRESS5', 'ALERTMETHODADDREXT5', 'ALERTMETHODPAUSEIND5', 'ALERTMETHODDELETE5', 'ALERTMETHODVOICEFLAG5', 'ALERTMETHODTEXTFLAG5'],
        ['ALERTMETHOD6', 'ALERTMETHODALIAS6', 'ALERTMETHODADDRESS6', 'ALERTMETHODADDREXT6', 'ALERTMETHODPAUSEIND6', 'ALERTMETHODDELETE6', 'ALERTMETHODVOICEFLAG6', 'ALERTMETHODTEXTFLAG6'],
        ['ALERTMETHOD7', 'ALERTMETHODALIAS7', 'ALERTMETHODADDRESS7', 'ALERTMETHODADDREXT7', 'ALERTMETHODPAUSEIND7', 'ALERTMETHODDELETE7', 'ALERTMETHODVOICEFLAG7', 'ALERTMETHODTEXTFLAG7'],
        ['ALERTMETHOD8', 'ALERTMETHODALIAS8', 'ALERTMETHODADDRESS8', 'ALERTMETHODADDREXT8', 'ALERTMETHODPAUSEIND8', 'ALERTMETHODDELETE8', 'ALERTMETHODVOICEFLAG8', 'ALERTMETHODTEXTFLAG8'],
        ['ALERTMETHOD9', 'ALERTMETHODALIAS9', 'ALERTMETHODADDRESS9', 'ALERTMETHODADDREXT9', 'ALERTMETHODPAUSEIND9', 'ALERTMETHODDELETE9', 'ALERTMETHODVOICEFLAG9', 'ALERTMETHODTEXTFLAG9'],
        ['ALERTMETHOD10', 'ALERTMETHODALIAS10', 'ALERTMETHODADDRESS10', 'ALERTMETHODADDREXT10', 'ALERTMETHODPAUSEIND10', 'ALERTMETHODDELETE10', 'ALERTMETHODVOICEFLAG10', 'ALERTMETHODTEXTFLAG10'],
    ],
];

const showHideAddMore = (form, model, initialState, array = additionalContacts) => {
    const [addLink] = array;
    const additionalFields = array[1];
    const fieldsReversed = additionalFields.slice().reverse();
    let valueFound = false;
    let visible = false;
    const formState = form.formView.state;

    form.field(addLink).shouldBeHidden();

    util.each(fieldsReversed, (newField) => {
        visible = form.field(newField[METHODINDEX]).isNotEmpty()
                                || model.get(newField[METHODINDEX]);

        form.field(newField[METHODINDEX]).shouldBeVisibleWhen(visible);
        form.field(newField[CONTACTALIAS]).shouldBeVisibleWhen(visible);
        form.field(newField[CONTACTADDRESS]).shouldBeVisibleWhen(visible);
        form.field(newField[CONTACTDELETE]).shouldBeVisibleWhen(visible);

        if (form.field(newField[METHODINDEX]).getValue() === 'PHONE' || model.get(newField[METHODINDEX]) === 'PHONE') {
            form.field(newField[CONTACTVOICE]).shouldBeVisibleWhen(visible && allowVoice);
            form.field(newField[CONTACTTEXT]).shouldBeVisibleWhen(visible && allowSMS);

            // Match checkbox parent visibility with its field visiblity.
            if (model.get(newField[CONTACTVOICE]) !== '1') {
                form.field(newField[CONTACTEXT]).shouldBeVisibleWhen(false);
                form.field(newField[CONTACTPAUSEIND]).shouldBeVisibleWhen(false);
            }
        } else {
            form.field(newField[CONTACTEXT]).shouldBeVisibleWhen(false);
            form.field(newField[CONTACTPAUSEIND]).shouldBeVisibleWhen(false);
            form.field(newField[CONTACTVOICE]).shouldBeVisibleWhen(false);
            form.field(newField[CONTACTTEXT]).shouldBeVisibleWhen(false);
        }

        if (initialState) {
            form.field(newField[METHODINDEX])
                .$el.change(newField, (e1) => {
                    switchContact(e1.data, model, form);
                });

            form.field(newField[CONTACTDELETE])
                .$el.click(newField, (e1) => {
                    deleteContact(e1.data, model, form);
                });

            form.field(newField[CONTACTVOICE]).$el.click(() => {
                selectVoice(newField, model, form);
                setStatesOfPhoneCheckboxes(
                    form.field(newField[CONTACTVOICE]).$el,
                    form.field(newField[CONTACTTEXT]).$el,
                );
            });
            form.field(newField[CONTACTTEXT]).$el.click(() => {
                setStatesOfPhoneCheckboxes(
                    form.field(newField[CONTACTVOICE]).$el,
                    form.field(newField[CONTACTTEXT]).$el,
                );
            });

            if (formState === 'modify') {
                /*
                 * Modify fields are shown by default, so update their restricted
                 * state immediately.
                 */
                setStatesOfPhoneCheckboxes(
                    form.field(newField[CONTACTVOICE]).$el,
                    form.field(newField[CONTACTTEXT]).$el,
                );
            }
        }

        valueFound = visible;
        if (!valueFound && !form.field(addLink).isVisible()) {
            form.field(addLink).shouldBeVisibleWhen(true);
        }
    });

    if (initialState) {
        form.field(addLink).$el.parent('.field-container').addClass('add-more-link');
        form.field(addLink).$el.click(additionalFields, (e) => {
            // handler for add contact link
            let fieldNeeded = true;
            e.stopImmediatePropagation();
            let contactCount = 1;
            /*
             * iterate thru all the 'sets' of contact fields to determine
             * what needs to be shown and what needs to be hidden
             */
            util.each(e.data, (newField) => {
                const fieldKey = newField[METHODINDEX];
                let fieldKeyId = fieldKey.substr(fieldKey.length - 1);
                const fieldname = `#s2id_${fieldKey}`;
                const $currentField = $(`[name="${fieldKey}"]`);
                /*
                 * fieldKeyId ranges from 1-10 since there are a max of 10 contact
                 * methods per recipient
                 */
                if (fieldKeyId === '0') {
                    fieldKeyId = 10;
                }
                // keep track of the number of visible contacts
                if ($(fieldname).is(':visible')) {
                    contactCount += 1;
                }

                // if the contact method isn't visible yet, then show it
                if (!$(fieldname).is(':visible') && fieldNeeded) {
                    form.field(fieldKey).shouldBeVisible();
                    form.field(newField[CONTACTDELETE]).shouldBeVisible();

                    // Show field parent container when requested.
                    form.field(fieldKey).$el.parents().eq(1).show();
                    fieldNeeded = false;

                    /*
                     * If there is only one option, disable the Select2 and remove the
                     * empty option.
                     */
                    if ($currentField.find('option[value!=""]').length === 1) {
                        $currentField.find('option[value!=""]').first().prop('selected', true);
                        $currentField.prop('disabled', true).trigger('change');
                        switchContact(additionalContactFields[fieldKeyId - 1], model, form);
                    }
                }
            });
            // hide the add contact link when we have reached the maximum number of contacts
            if (contactCount === NUMBEROFFIELDS) {
                form.field(addLink).shouldBeHidden();
            }
        });

        setByTenantConfig(form);
    }
};

export default function emailRecipeintPolicy(form, initialState) {
    const formState = form.formView.state;
    const { model } = form.formView;
    const view = form.formView;
    const name = form.field('NAME');
    const emailLabel = locale.get('administration.emailAddress');
    const phoneLabel = locale.get('allReports.Phone');
    const faxLabel = locale.get('allReports.Fax');
    let $label;
    let container;

    if (initialState && form.formView.state === 'insert') {
        hideAddressFields(additionalContactFields, form);
    }

    if (initialState) {
        // style method combo
        showHideAddMore(form, model, initialState);
        for (let j = 1; j <= NUMBEROFFIELDS; j += 1) {
            view.$(`#ALERTMETHODDELETE${j} > span`).addClass('icon-close');
        }
        if (formState !== 'modify' || model.get('SHOW_WARNING') !== '1') {
            view.$('#MESSAGE1').hide();
        }
    }

    if (formState === 'modify') {
        name.shouldBeReadOnly(true);
    }
    if (formState !== 'insert') {
        for (let i = 1; i <= NUMBEROFFIELDS; i += 1) {
            $label = (formState === 'view') ? $(`div#ALERTMETHODADDRESS${i} label`) : $(`label[for="ALERTMETHODADDRESS${i}"]`);

            if (util.isEmpty(model.get(`ALERTMETHOD${i}`)) === false) {
                if (model.get(`ALERTMETHOD${i}`) === 'EMAIL') {
                    $label.html(emailLabel);
                    addEmailValidations(additionalContactFields[i - 1], model, form);
                }
                if (model.get(`ALERTMETHOD${i}`) === 'FAX') {
                    $label.html(faxLabel);
                    addFaxValidations(additionalContactFields[i - 1], model, form);
                }
                if (model.get(`ALERTMETHOD${i}`) === 'PHONE') {
                    $label.html(phoneLabel);
                    addPhoneValidations(additionalContactFields[i - 1], model, form);
                }

                // add bottom border
                container = getContactContainer(form.field(`ALERTMETHOD${i}`));
                container.addClass('contactMethodSection');
            }
        }
    }
}

export {
    additionalContacts,
    additionalContactFields,
    showHideAddMore,
    addAlertMethodValidation,
    resetValidations,
    addPhoneValidations,
    addEmailValidations,
    addFaxValidations,
    METHODINDEX,
    CONTACTADDRESS,
    CONTACTEXT,
};
