const styles = {
  enter: {
    position: 'relative',
    right: -550
  },
  enterActive: {
    right: 0,
    transition: 'right .3s'
  },
  exit: {
    position: 'relative',
    right: 0
  },
  exitActive: {
    opacity: 0,
    right: -550,
    transition: 'right .3s, opacity .2s'
  }
};

export default styles;
