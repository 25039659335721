import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StopPaymentsForm from '@dbiqe/form-stop-payments';
import { resetFormState } from 'common/util/reduxUtil';

const RenderMfe = ({ name }) => {
    useEffect(() => () => {
        resetFormState();
    }, []);

    switch (name) {
    case 'StopPaymentsForm':
        return <StopPaymentsForm />;
    default:
        return null;
    }
};

RenderMfe.propTypes = {
    name: PropTypes.string.isRequired,
};

export default RenderMfe;
