import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import $ from 'jquery';

const protectedPayRuleFields = ['ACCOUNTNUMBER', 'COMPANYID', 'COMPANYNAME', 'CREDITDEBITCODE'];

const protectAchAuthRulesFields = (form) => {
    protectedPayRuleFields.forEach((fld) => {
        if (form.fields[fld]) {
            form.fields[fld].shouldBeReadOnly(true);
        }
    });
    // hide look up
    if (form.formView) {
        form.formView.$('[data-field="ACCOUNTNUMBER"]').addClass('hidden');
    }
};

const setupForCreateAfterPay = (form, model) => {
    protectAchAuthRulesFields(form);
    const creditdebit = model.get('CREDITDEBITCODE') === 'Debit' ? 'DR' : 'CR';
    model.set({ AMOUNTINDICATOR: 'S', CREDITDEBITCODE: creditdebit });
};

/**
 * override ACH auth rules form selectors
 * @param {Object} formView - ACH auth rules form view
 */
const overrideFormSelectors = (formView) => {
    // Fix AMOUNTINDICATOR radio btns alignment.
    formView.$('label[for="AMOUNTINDICATOR"]').siblings('div').first().addClass('radio');
};

/**
 * This is the core logic that is executed by applyPolicies
 * In some sections of this policy, you will find that we use
 * jQuery rather than the F1 form.field() method.
 * This is due to the fields being in a child region and
 * inaccessible to F1. - RB 10/20/15
 */
export default function (form, initialState) {
    const { formView } = form;
    const { model } = form.formView;
    const formState = formView.state;
    const $summarySection = $('[data-widget-id="summarysection"]').closest('fieldset');

    if (initialState) {
        $summarySection.hide();
        if (formState === 'view') {
            $summarySection.show();
        }

        /*
         * when coming from 'pay & create' some fields are prefilled
         * some fields need to be protected and the amount must be the exact amount
         */
        if (!util.isEmpty(formView.preFill)) {
            // protect accountnum, companyid and companyname
            setupForCreateAfterPay(form, model);
        }

        // in modify mode protect ACCOUNTNUMBER, COMPANYID and COMPANYNAME
        if (formState === 'modify') {
            protectAchAuthRulesFields(form);
        }

        /**
         * override ACH auth rules form selectors
         * @param {Object} formView - ACH auth rules form view
         */
        overrideFormSelectors(formView);
        // Reset end date validity on schedModel change
        model.schedModel.on('change', () => {
            const ends = model.schedModel.get('ends');
            if (!util.isString(ends)) {
                appBus.trigger('endDate:valid');
                appBus.trigger('schedModel:change');
            }
        });
    }
}

export { protectAchAuthRulesFields, setupForCreateAfterPay, overrideFormSelectors };
