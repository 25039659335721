import alert from '@glu/alerts';
import Collection from '@glu/core/src/collection';
import dialog from '@glu/dialog';
import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import constants from 'common/dynamicPages/api/constants';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import entitlements from 'common/dynamicPages/api/entitlements';
import errorHandlers from 'system/error/handlers';
import helpPageUtil from 'common/util/helpPage';
import ItemsEffectedWarning from 'common/dynamicPages/views/itemsEffectedWarning';
import scroll from 'common/util/scroll';
import store from 'system/utilities/cache';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import workspaceHelper from 'common/workspaces/api/helper';
import orderingPartyUtil from 'common/util/orderingPartyLookupUtil';
import DataAPI from 'common/dynamicPages/api/data';
import AccountCollection from '../collections/beneAccounts';
import AccountForm from './accountForm';
import AccountModel from '../model/accountModel';
import AddressBookAPI from '../service/addressBook';
import BillPayModel from '../model/billPay';
import BillPayView from './billPay';
import ContactForm from './contactForm';
import ContactModel from '../model/contactModel';
import contactUtil from '../util/contactUtil';
import DraftModel from '../model/draftModel';
import DraftPaymentView from './draftPayment';
import InquiryService from '../service/inquiry';
import relatedDataGrid from './relatedDataGrid';
import addContactTmpl from './addContact.hbs';

function detectDraftAccount(account) {
    return Object.prototype.hasOwnProperty.call(account, 'DRAFT');
}

const smbReturnRoute = 'smbContact/customers';
const corpReturnRoute = 'ADMINSTRATION/manageBeneAddressBook';

const AddContact = Layout.extend({
    template: addContactTmpl,

    ui: {
        toggleButtons: '.form-step-container button',
        alertRegion: '.alert-region',
        accountsRegion: '.accounts-region',
        listViewTitle: '.list-view-title div',
        $relatedPaymentsContainer: '[data-hook="getRelatedPaymentsContainer"]',
        $relatedTemplatesContainer: '[data-hook="getRelatedTemplatesContainer"]',
        $saveContactButton: '[data-hook="saveContactButton"]',
    },

    regions: {
        formRegion: '.form-region',
        contactRegion: '.contact-region',
        billPayRegion: '.billpay-region',
        paymentRegion: '.payment-region',
        draftRegion: '.draft-region',
        accountRegion: '.accounts-region',
    },

    initialize(options) {
        this.options = util.extend({}, options);
        this.lastFragment = this.options.lastFragment;
        store.set('current-workspace-route', this.options.lastFragment);

        this.contactModel = this.options.data
            ? new ContactModel(this.options.data) : new ContactModel();
        this.accountModel = this.options.data
            ? new AccountModel(this.options.data) : new AccountModel();
        this.isSMB = (options.corpOrSmb === 'smb');
        this.draftData = util.find(options.accountListData, detectDraftAccount);
        this.CRTRANPaymentTypeSelected = false;
        this.INTLPaymentTypeSelected = false;

        if (this.draftData) {
            this.draftData = this.draftData.DRAFT;
        } else {
            this.draftData = {};
        }

        this.billData = util.find(options.accountListData, account => Object.prototype.hasOwnProperty.call(account, 'BPAY'));

        this.options.accountListData = util.reject(this.options.accountListData, account => detectDraftAccount(account) || Object.prototype.hasOwnProperty.call(account, 'BPAY'));

        this.accountCollection = new AccountCollection(this.options.accountListData || []);

        this.mode = this.options.mode || 'create';
        // displaying view in modal is no longer wanted
        this.isModal = false;

        this.model = new Model({

        });

        // Set some modal related values
        if (this.isModal) {
            this.setInitModalTitleButtons();
        }

        this.orderingPartyIdTypeCollection = new Collection();
        this.contactTypeCollection = new Collection();
        this.countryCollection = new Collection();
        this.stateCollection = new Collection();
        this.draftCountryCollection = new Collection();
        this.draftCurrencyCollection = new Collection([{
            label: locale.get('bab.currency.usd'),
            name: 'USD',
        }, {
            label: locale.get('bab.currency.aud'),
            name: 'AUD',
        }, {
            label: locale.get('bab.currency.cad'),
            name: 'CAD',
        }, {
            label: locale.get('bab.currency.eur'),
            name: 'EUR',
        }, {
            label: locale.get('bab.currency.gbp'),
            name: 'GBP',
        }]);

        this.addrAPI = new AddressBookAPI();

        this.orderingPartyIdTypeService = new InquiryService();
        this.contactTypeService = new InquiryService();
        this.countryService = new InquiryService();
        this.stateService = new InquiryService();
        this.draftCountryService = new InquiryService();
        this.draftCurrencyService = new InquiryService();

        // These "setXCode" functions could be abstracted/shortened to cut down on repetition
        this.orderingPartyIdTypeService.setActionMode('SELECT'); // INSERT
        this.orderingPartyIdTypeService.setFunctionCode('MAINT');
        this.orderingPartyIdTypeService.setProductCode('_ADMIN');
        this.orderingPartyIdTypeService.setInquiryId(19247);
        this.orderingPartyIdTypeService.setTypeCode('BENEADBK');
        this.orderingPartyIdTypeService.send();

        this.draftCountryService.setActionMode('SELECT'); // INSERT
        this.draftCountryService.setFunctionCode('MAINT');
        this.draftCountryService.setProductCode('_ADMIN');
        this.draftCountryService.setInquiryId(29059);
        this.draftCountryService.setTypeCode('BENEADBK');
        this.draftCountryService.send();

        this.draftCurrencyService.setActionMode('SELECT'); // INSERT
        this.draftCurrencyService.setFunctionCode('MAINT');
        this.draftCurrencyService.setProductCode('_ADMIN');
        this.draftCurrencyService.setInquiryId(29060);
        this.draftCurrencyService.setTypeCode('BENEADBK');
        // this.draftCurrencyService.send();

        this.contactTypeService.setActionMode('SELECT');
        this.contactTypeService.setFunctionCode('MAINT');
        this.contactTypeService.setProductCode('_ADMIN');
        this.contactTypeService.setInquiryId(17246);
        this.contactTypeService.setTypeCode('BENEADBK');
        this.contactTypeService.send();

        this.countryService.setActionMode('SELECT');
        this.countryService.setFunctionCode('MAINT');
        this.countryService.setProductCode('_ADMIN');
        this.countryService.setInquiryId(17076);
        this.countryService.setTypeCode('BENEADBK');
        this.countryService.send();

        this.stateService.setActionMode('SELECT');
        this.stateService.setFunctionCode('MAINT');
        this.stateService.setProductCode('_ADMIN');
        this.stateService.setInquiryId(17097);
        this.stateService.setTypeCode('BENEADBK');
        this.stateService.send();

        this.contactForm = new ContactForm({
            mode: this.mode,
            model: this.contactModel,
            contactTypes: this.contactTypeCollection,
            countries: this.countryCollection,
            states: this.stateCollection,
            accountCollection: this.accountCollection,
            orderingPartyIdTypes: this.orderingPartyIdTypeCollection,
        });

        this.initializeAccountForm();
    },

    initializeAccountForm() {
        let isFirstAccount = false;
        if (!this.options.accountListData || this.options.accountListData.length === 0) {
            isFirstAccount = true;
        }
        this.accountForm = new AccountForm({
            mode: this.mode,
            collapsed: !this.isModal,

            // paymentTypeCollection: this.paymentTypeCollection,
            isSMB: this.isSMB,

            model: this.accountModel,
            contactModel: this.contactModel,
            isEntryRow: true,
            isFirstAccount,
            countries: this.countryCollection,
            accountCollection: this.accountCollection,
        });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            moveToTopCheck(this.model);
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData();
        } else {
            const hasOrderingPartyLookupConfig = orderingPartyUtil.isEntitled(this.contactModel.get('ALLOWBYORDEROF'));

            relatedDataGrid.setRelatedGridDataListener({
                $container: this.ui.$relatedPaymentsContainer,
                gridRegion: this.relatedPaymentsGridRegion,
                inquiryId: constants.babItemsAffected.INQUIRYID_PAYMENTS_AFFECTED,
                tnum: this.contactModel.get('TNUM'),
                allowOrderingParty: hasOrderingPartyLookupConfig ? '1' : '0',
                templateGridOptions: {
                    $container: this.ui.$relatedTemplatesContainer,
                    gridRegion: this.relatedTemplatesGridRegion,
                    inquiryId: constants.babItemsAffected.INQUIRYID_TEMPLATES_AFFECTED,
                    tnum: this.contactModel.get('TNUM'),
                    allowOrderingParty: hasOrderingPartyLookupConfig ? '1' : '0',
                    relatedItemTypes: this.relatedItemTypes,
                },
            });

            // All of the views that makeup the contact screen need the field type data
            this.assignFieldTypeDataToViews();

            this.contactRegion.show(this.contactForm);

            if (this.billPayEntitled) {
                this.billPayRegion.show(this.billPayView);
            }

            this.paymentRegion.show(this.accountForm);

            if (this.draftEnabled) {
                this.draftRegion.show(this.draftView);
            }

            this.applyPaymentTypeFieldRules();
        }
    },

    loadRequiredData() {
        const self = this;
        const billPayEntitlement = contactUtil.entitlementPromise('BPAY');
        const draftEntitlement = contactUtil.entitlementPromise('DRAFT');

        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            typeCode: 'BENEADBK',
            mode: '*',
        });

        const companyEntitlementPromise = entitlements.getCompanyEntitlements({
            context: {
                serviceName: '/beneAddressBook',
            },
            data: {
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: 'BENEADBK',
                action: 'SELECT',
            },
        });

        // Retrieve model information for the contact screen (field length, type etc..)
        const modelDataPromise = DataAPI.model.generate({
            context: {
                serviceName: '/beneAddressBook',
                functionCode: 'MAINT',
                typeInfo: {
                    functionCode: 'MAINT',
                    productCode: '_ADMIN',
                    typeCode: 'BENEADBK',
                },
            },
        }, false);

        Promise.all([
            billPayEntitlement,
            draftEntitlement,
            helpPagePromise,
            companyEntitlementPromise,
            modelDataPromise,
        ]).then(([billPay, draft, help, company, modelData]) => {
            self.handleBillPayEntitlement(billPay);
            self.handleDraftEntitlement(draft);
            store.set('helpPage', help.helpPage);
            self.isCompanyEntitledToTemplates = contactUtil
                .companyIsEntitledToTemplates(company);
            self.isCompanyEntitledToPayments = contactUtil
                .companyIsEntitledToPayments(company);
            self.isCompanyEntitledToRFPTemplates = contactUtil
                .companyIsEntitledToRFPTemplates(company);
            self.isCompanyEntitledToRFP = contactUtil.companyIsEntitledToRFP(company);
            self.relatedItemTypes = contactUtil.getRelatedItemTypes(self);
            self.relatedItemlabels = contactUtil.getRelatedItemLabels()[self.relatedItemTypes];
            self.relatedSectionLabel = locale.get(self.relatedItemlabels.sectionLabel);
            self.relatedPaymentsHeader = locale.get(self.relatedItemlabels.paymentSectionHeader);
            self.fieldTypeData = modelData.fieldData;
            self.accountCollection.fieldTypeData = modelData.fieldData;
            self.setHasLoadedRequiredData(true);
            self.accountForm.firstRender = true;
            self.render();
        }, util.bind(errorHandlers.loadingModal, self));
    },

    setInitModalTitleButtons() {
        if (this.isModal) {
            if (this.mode === 'create') {
                this.dialogTitle = locale.get('bab.contact.add');

                this.dialogButtons = [{
                    text: locale.get('bab.save.contact.btn'),
                    className: 'btn btn-primary',
                    callback: 'handleContactAccountFormSubmit',
                }, {
                    text: locale.get('bab.cancel.btn'),
                    className: 'btn btn-secondary',
                    callback: 'cancel',
                }];
            } else {
                this.dialogTitle = locale.get('bab.contact.modify');

                this.dialogButtons = [{
                    text: locale.get('bab.contact.save'),
                    className: 'btn btn-primary',
                    callback: 'handleContactAccountFormSubmit',
                }, {
                    text: locale.get('bab.cancel.btn'),
                    className: 'btn btn-secondary',
                    callback: 'cancel',
                }];
            }
        }
    },

    delegateEvents() {
        Layout.prototype.delegateEvents.call(this);
        this.listenTo(this.accountCollection, 'specialPaymentTypeChanged', this.applyPaymentTypeFieldRules);
        this.listenTo(this.accountForm, 'account:change', this.handleAccountChange);
        this.listenTo(this.accountForm, 'back', this.handleAccountFormBack);
        this.listenTo(this.accountForm, 'submit', this.handleContactAccountFormSubmit);
        this.listenTo(this.contactForm.orderingPartyView, 'specialOrderingPartyChanged', this.applyOrderingPartyAddressRules);
        this.listenTo(this.addrAPI, 'create:error', this.handleAccountCreateError);
        this.listenTo(this.addrAPI, 'create:fail', this.handleAccountCreateFail);
        this.listenTo(this.addrAPI, 'create:success', this.handleAccountCreateSuccess);
        this.listenTo(this.appBus, 'delete:added:account', this.handleAccountRemove);
        this.listenTo(this.contactForm, 'employeeCheckboxToggled', this.employeeCheckboxToggled);
        this.listenTo(this.contactForm, 'handle:account:form:reset', this.handleResetAccountForm);
        this.listenTo(this.contactForm, 'submit', this.handleContactAccountFormSubmit);
        this.listenTo(this.contactTypeService, 'success', this.handleContactTypeSuccess);
        this.listenTo(this.countryService, 'success', this.handleCountrySuccess);
        this.listenTo(this.orderingPartyIdTypeService, 'success', this.handleOrderingPartyIdTypeSuccess);
        this.listenTo(this.draftCountryService, 'success', this.handleDraftCountrySuccess);
        this.listenTo(this.draftCurrencyService, 'success', this.handleDraftCurrencySuccess);
        this.listenTo(this.model, 'modelAction:modifyWithWarning', this.saveWithWarning);
        this.listenTo(this.model, 'modelAction:saveWithWarning', this.saveWithWarning);
        this.listenTo(this.stateService, 'success', this.handleStateSuccess);
    },

    /**
     * Assign out model information retrieved from the server to all of the views that makup
     * the contact screen. This field data is used to set maxLen values on fields.
     */
    assignFieldTypeDataToViews() {
        // Views containing header fields
        this.contactForm.fieldTypeData = this.fieldTypeData;

        if (this.contactForm.personView) {
            this.contactForm.personView.fieldTypeData = this.fieldTypeData;
        }

        if (this.contactForm.personalInfoView) {
            this.contactForm.personalInfoView.fieldTypeData = this.fieldTypeData;
        }

        if (this.contactForm.addressView) {
            this.contactForm.addressView.fieldTypeData = this.fieldTypeData;
        }

        if (this.contactForm.businessInfoView) {
            this.contactForm.businessInfoView.fieldTypeData = this.fieldTypeData;
        }

        if (this.contactForm.orderingPartyView) {
            this.contactForm.orderingPartyView.fieldTypeData = this.fieldTypeData;
        }

        // Views containg child (account) fields
        this.accountForm.fieldTypeData = this.fieldTypeData;

        if (this.accountForm.intermediaryBankView.bankTwoView) {
            this.accountForm.intermediaryBankView.bankTwoView.fieldTypeData = this.fieldTypeData;
        }

        if (this.accountForm.secondaryAccountForm) {
            this.accountForm.secondaryAccountForm.fieldTypeData = this.fieldTypeData;
        }

        if (this.billPayView) {
            this.billPayView.fieldTypeData = this.fieldTypeData;
        }

        if (this.draftView) {
            this.draftView.fieldTypeData = this.fieldTypeData;
        }
    },

    /**
     * When a concact is allowed to be used as an ordering party and the system is configured
     * to require address information for an ordering party, then the appropriate address
     * fields need to reflect the correct mandatory status to accommodate.
     */
    applyOrderingPartyAddressRules() {
        this.contactForm.addressView.applyMandatoryFieldRules();
    },

    /**
     * Once when the form is loaded, whenever an account is removed, and every time a relevant
     * payment type is selected or deselected, check to see if special rules need to be
     * applied to some of the contact forms fields.
     * Currently, this is only applicable for the CRTRAN and INTL payment types
     */
    applyPaymentTypeFieldRules() {
        const CRTRANChanged = this.updatePaymentTypeTrackers('CRTRAN');
        const INTLChanged = this.updatePaymentTypeTrackers('INTL');

        // if there has been a change in the selected payment types, update the form as needed
        if (CRTRANChanged || INTLChanged) {
            this.contactForm.addressView.updatePaymentTypesSelected(
                this.CRTRANPaymentTypeSelected,
                this.INTLPaymentTypeSelected,
            );
        }
    },

    /**
     * Update the contact-wide tracker of a specific payment type to indicate if it is
     * currently selected in any of the present accounts
     * @param {string} paymentType - the payment type in question
     * @return {bool} - whether or not the selected status of this payment type has changed
     */
    updatePaymentTypeTrackers(paymentType) {
        const selectedStatus = this.checkForPaymentTypeAcrossAllAccounts(paymentType);

        // return early if no changes necessary
        if (selectedStatus === this[`${paymentType}PaymentTypeSelected`]) {
            return false;
        }
        this[`${paymentType}PaymentTypeSelected`] = selectedStatus;
        return true;
    },

    /**
     * Check across the currently active account, and previously created accounts, to see if a
     * specific payment type has been enabled.
     * @param {string} paymentTypeToCheck - the payment type to check for
     * @return {bool} - true if the payment type is present, false if not
     */
    checkForPaymentTypeAcrossAllAccounts(paymentTypeToCheck) {
        const modelPaymentTypes = this.accountModel.get('PAYMENTTYPE');
        const currentlySelectedPaymentTypes = util.isArray(modelPaymentTypes)
            ? modelPaymentTypes : [modelPaymentTypes];
        let paymentTypeActive = (currentlySelectedPaymentTypes.indexOf(paymentTypeToCheck)
            !== -1);

        // see if any already created accounts have the payment type we're searching for
        if (!paymentTypeActive) {
            paymentTypeActive = this.accountCollection.models.some((model) => {
                const accountCollectionModelPaymentTypes = util.isArray(model.get('PAYMENTTYPE')) ? model.get('PAYMENTTYPE') : [model.get('PAYMENTTYPE')];
                return accountCollectionModelPaymentTypes.indexOf(paymentTypeToCheck) !== -1;
            });
        }
        return paymentTypeActive;
    },

    /**
     * Reset the account model, reset accountCollection and reinitialize and show
     * the accountForm
     */
    handleResetAccountForm() {
        this.resetEntryModel();
        this.accountCollection.reset([]);
        this.initializeAccountForm();
        this.paymentRegion.show(this.accountForm);
    },

    onSubmit() {
        return false;
    },

    handleDraftEntitlement(model) {
        this.draftEnabled = model.get('isEntitled');

        if (!this.draftEnabled) {
            return;
        }

        this.draftModel = new DraftModel(
            this.draftData,
            {
                contactModel: this.contactModel,
            },
        );

        this.draftView = new DraftPaymentView({
            model: this.draftModel,
            countries: this.draftCountryCollection,
            currency: this.draftCurrencyCollection,
        });

        this.listenTo(this.draftModel, 'change:BENE_COUNTRY_CHILD', this.handleDraftCountryChange);
    },

    handleBillPayEntitlement(model) {
        this.billPayEntitled = model.get('isEntitled');

        if (!this.billPayEntitled) {
            return;
        }
        this.billPayModel = new BillPayModel(this.billData);

        this.billPayView = new BillPayView({
            model: this.billPayModel,
        });
    },

    handleDraftCountryChange(view, val) {
        this.draftCurrencyService.setCustomFilter('Bene_Country_Child_draft', val);
        this.draftCurrencyService.send();
    },

    handleAccountChange() {
        this.contactForm.disableContactTypes();
    },

    /**
     * REVIEW
     * as far as I can tell, this never gets called?
     */
    handleAccountRemove(options) {
        this.accountCollection.remove(options.model);

        // account for the potential removal of payment types with account removals
        this.applyPaymentTypeFieldRules();

        // TODO: on modify this action needs to persist changes to db
    },

    showDetailsHeader() {
        $('.list-view-title div').removeClass('title-condition');
    },

    resetEntryModel() {
        const contactType = this.accountModel.get('CONTACTTYPE');
        const beneName = this.accountModel.get('BENE_NAME');

        this.accountModel.clear();

        // set default values
        this.accountModel.set({
            BENE_ACCOUNTTYPE: 'OTHER',
            BENEBANKIDENTRYMETHOD: 'LOOKUP',
            CONTACTTYPE: contactType,
            BENE_NAME: beneName,
            BENE_NAME_CHILD: beneName,
            PAYMENTTYPE_COLLECTION: new Collection(),
        });
    },

    handleOrderingPartyIdTypeSuccess(response) {
        this.orderingPartyIdTypeCollection.reset(response);
    },

    handleContactTypeSuccess(response) {
        this.contactTypeCollection.reset(response);
    },

    handleCountrySuccess(response) {
        this.countryCollection.reset(response);
    },

    handleStateSuccess(response) {
        this.stateCollection.reset(response);
    },

    handleDraftCountrySuccess(response) {
        this.draftCountryCollection.reset(response);
    },

    handleDraftCurrencySuccess(response) {
        this.draftCurrencyCollection.reset(response);
    },

    employeeCheckboxToggled(e) {
        this.accountForm.model.set('isEmployee', e.target.checked);

        /*
         * generally, when a user toggles the 'employee' status, accounts in the
         * accountCollection are cleared. but there exists situations where this
         * function will run at the start of modifying an existing contact with
         * multiple accounts so it needs to iterate through them to signify if
         * they can/cannot hold secondary accounts
         */
        this.accountCollection.forEach((account) => {
            account.set('isEmployee', e.target.checked);
        });
    },

    /**
     * Determines the total number of accounts enabled for real-time payments
     * and notifies the address view.
     * @returns {undefined}
     */
    checkRtp() {
        // Get a count of RTP enabled accounts retrieved from the server.
        this.rtpCount = this.accountCollection
            .filter(model => this.isModelRtp(model)).length;

        // Check the account entry form for RTP
        this.rtpCount += this.isModelRtp(this.accountModel) ? 1 : 0;
        // Tell the addressView to set the mandatory flags.
        this.contactForm.addressView.toggleBeneAddressRtp(this.rtpCount > 0);
    },

    isModelRtp(model) {
        let selected = false;
        const ptypes = model.get('PAYMENTTYPE');
        if (util.isArray(ptypes)) {
            selected = ptypes.includes('CRTRAN') || ptypes.includes('REQOUT');
        } else {
            selected = ptypes === 'CRTRAN' || ptypes === 'REQOUT';
        }
        return selected;
    },
    /**
     * @param {Model} acct - Payee information Model
     * @returns {boolean}
     */
    validateBillPayAccount(acct) {
        const validateState = acct.validate();
        let isPayeeEmpty = false;
        if (validateState === 'invalid') {
            /**
             * Check if the Payee is empty or not,
             * if empty then continue without error else
             * show error
             */
            isPayeeEmpty = this.payeeEmpty(acct);
            return !isPayeeEmpty;
        }
        return true;
    },
    /**
     * @param {Model} acct
     * @returns {boolean}
     */
    payeeEmpty(acct) {
        return !!acct.get('BENE_BANK_NAME');
    },

    validateAccount(acct, parentAcct, bankCodeFieldName) {
        // make sure validators are accurate based on if freeform or not
        acct.updateValidators(
            acct.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM',
            acct.get('ACCT_NUM_MANDATORY') === true,
            (acct.get('CORRESPONDENT_ID_MANDATORY') === true || (this.accountModel && this.accountModel.get('CORRESPONDENT_ID_MANDATORY'))),
            acct.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM',
            acct.get('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED') === 'true',
            bankCodeFieldName,
            acct.validators,
        );
        if (parentAcct) {
            const validateState = acct.validate();
            let isCurrentAccountEmpty = false;
            if (validateState === 'invalid') {
                isCurrentAccountEmpty = this.isCurrentAccountEmpty();
                if (!isCurrentAccountEmpty) {
                    return false;
                }
            }
        }
        return true;
    },

    handleContactAccountFormSubmit(options) {
        const self = this;
        const bankCodeFieldName = this.mode === 'create' ? 'BENE_BANK_CODE_SORTCODETYPE' : 'BENE_BANK_CODE';

        if (options && options.warningName === constants.CONTACT_CHANGES_ACCEPTED_INDICATOR) {
            this.contactModel.set(constants.CONTACT_CHANGES_ACCEPTED_INDICATOR, 'true');
        }

        this.toggleSaveContactButton(true);

        if (this.contactModel.save()) {
            const allAccounts = this.accountCollection.clone();
            // process parent account (active account)
            let acctValid = this.validateAccount(this.accountModel, true, 'BENE_BANK_CODE_SORTCODETYPE');
            if (!acctValid) {
                // if account failed validation, errors were rendered, so just exit function
                this.accountModel.save();
                scroll.scrollToFirstError();
                this.toggleSaveContactButton(false);
                return true;
            }
            if (this.billPayModel) {
                const billPayAcc = this.validateBillPayAccount(this.billPayModel);
                if (!billPayAcc) {
                    // if account failed validation, errors were rendered, so just exit function
                    this.billPayModel.save();
                    scroll.scrollToFirstError();
                    this.toggleSaveContactButton(false);
                    return true;
                }
            }

            // now update validators non-active child accounts
            allAccounts.each((acct) => {
                acctValid = self.validateAccount(acct, false, bankCodeFieldName);
            });

            this.setContactTypeForSubmit();
            // if the account is not empty, add it to the all accounts list
            if (!this.isCurrentAccountEmpty()) {
                allAccounts.push(this.accountModel);
            }

            if (this.draftEnabled && this.draftModel.isEnabled()) {
                allAccounts.push(this.draftModel);
            }

            if (this.billPayModel && this.billPayModel.isBillerEntered()) {
                allAccounts.push(this.billPayModel);
            }

            const accountsValidBool = allAccounts.all(account => account.save());
            this.toggleSaveContactButton(false);

            if (accountsValidBool) {
                const contactName = this.contactModel.get('BENE_NAME');
                const contactType = this.contactModel.get('CONTACTTYPE');
                allAccounts.each((account) => {
                    account.set('BENE_NAME', contactName);
                    account.set('BENE_NAME_CHILD', contactName);
                    account.set('CONTACTTYPE', contactType);
                    if (account.has('PAYMENTTYPE_COLLECTION')) {
                        const isFreeForm = account.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM';
                        if (isFreeForm) {
                            account.get('PAYMENTTYPE_COLLECTION').each((payType) => {
                                payType.set('payTypeLevelBankAddress1', account.get('BENE_BANK_ADDRESS_1'));
                                payType.set('payTypeLevelBankAddress2', account.get('BENE_BANK_ADDRESS_2'));
                                payType.set('payTypeLevelBankCountryCode', account.get('BENE_BANK_COUNTRY'));
                                payType.set('payTypeLevelSortCodeType', account.get('BENE_BANK_COUNTRY'));
                                payType.set('BENEBANKIDENTRYMETHOD', 'FREEFORM');
                            });
                        } else {
                            account.get('PAYMENTTYPE_COLLECTION').each((payType) => {
                                payType.set('BENEBANKIDENTRYMETHOD', 'LOOKUP');
                            });
                        }
                    }
                });

                this.setContactTypeForSubmit();

                const accounts = {
                    accounts: allAccounts.toJSON(),
                };

                const data = util.extend({}, accounts, this.contactModel.toJSON());

                if (this.mode.toUpperCase() === 'CREATE') {
                    this.addrAPI.create(data, false);
                } else {
                    this.addrAPI.update(data, false);
                }
            }
        } else {
            scroll.scrollToFirstError();
            this.toggleSaveContactButton(false);
        }
        return undefined;
    },

    /*
     * An account is considered empty when all of the required fields are empty
     * TODO: this may not be a scalable method if the accountModel changes, or if the type
     * of validator errors change
     */
    isCurrentAccountEmpty() {
        const errors = this.accountModel.get('error');
        const tmpAccountModel = new AccountModel();

        tmpAccountModel.updateValidators(
            this.accountModel.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM',
            this.accountModel.get('ACCT_NUM_MANDATORY') === true,
            this.accountModel.get('CORRESPONDENT_ID_MANDATORY') === true,
            this.accountModel.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM',
            this.accountModel.get('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED') === 'true',
        );
        /*
         * check if all of the errors are the same as all of the validators in the
         * account model
         */
        if (util.size(errors) === util.size(tmpAccountModel.validators)) {
            return true;
        }
        /*
         * sometimes the BENE_ACCOUNTTYPE is set by default (even if nothing else
         * is entered),
         * so if that is the case - check if errors are all except BENE_ACCOUNTTYPE
         */
        if (util.size(errors) === (util.size(tmpAccountModel.validators) - 1)) {
            return !util.has(errors, 'BENE_ACCOUNTTYPE');
        }
        return false;
    },

    setContactTypeForSubmit() {
        const selectedType = contactUtil.getContactType(this.contactModel);

        if (selectedType === '4') {
            this.contactModel.set('CONTACTTYPE_DESC', 'Individual, Employee');
        } else if (selectedType === '1') {
            this.contactModel.set('CONTACTTYPE_DESC', 'Business');
        } else if (selectedType === '2') {
            this.contactModel.set('CONTACTTYPE_DESC', 'Individual');
        } else if (selectedType === '3') {
            this.contactModel.set('CONTACTTYPE_DESC', 'Employee');
        }

        this.contactModel.set('CONTACTTYPE', selectedType, { silent: true });
    },

    handleAccountCreateSuccess(response) {
        this.toggleSaveContactButton(false);
        this.clearErrors();
        store.set('addContactSuccessMessage', response.message[0]);
        store.set('addContactSuccessResponse', response);

        if (this.isModal) {
            this.trigger('addContact:success');
            dialog.close();
        } else {
            workspaceHelper.returnToCurrentWorkspace(this);
        }
    },

    cancel() {
        if (this.isModal) {
            dialog.close();
        } else {
            workspaceHelper.returnToCurrentWorkspace(this);
        }
    },

    getReturnRoute() {
        let route = (this.isSMB) ? smbReturnRoute : corpReturnRoute;
        if (store.has('beneAddressBookReturnRoute')) {
            route = store.remove('beneAddressBookReturnRoute');
        }
        return route;
    },

    showErrors(messages) {
        util.each(messages, this.showError, this);
    },

    showError(message) {
        const alertView = alert.danger(message);
        this.ui.alertRegion.append(alertView.render().el);
        scroll.scrollToRegion(alertView.$el[0]);
    },

    clearErrors() {
        this.ui.alertRegion.empty();
    },

    handleAccountCreateError(response) {
        this.clearErrors();
        this.toggleSaveContactButton(false);

        if (response.errorCode === constants.ITEMS_EFFECTED_WARNING_CODE) {
            const itemsEffectedWarning = new ItemsEffectedWarning({
                resp: response,
                actionMode: 'MODIFY',
            });
            itemsEffectedWarning.once('saveContact', this.handleContactAccountFormSubmit, this);
            dialog.custom(itemsEffectedWarning);
        } else if (response.resultType === 'WARNING') {
            dialog.custom(new WarningDialog({
                model: this.model,
                methodName: this.mode.toUpperCase(),
                confirms: response.confirms,
            }));
        } else {
            /*
             * If the server response has a populated confirmResults object use it to get
             * the error message, else just get messages from response.messages
             */
            const errors = response.confirms && response.confirms.confirmResults.length > 0
                ? response.confirms.confirmResults[0].messages : response.messages;
            this.showErrors(errors);
        }
    },

    saveWithWarning() {
        const warningName = '_saveWithWarning';
        this.contactModel.set(warningName, 'true');
        this.handleContactAccountFormSubmit();
    },

    handleAccountCreateFail(xhr) {
        if (xhr.responseJSON) {
            this.handleAccountCreateError(xhr.responseJSON);
        } else {
            this.toggleSaveContactButton(false);
            this.showError(xhr.responseText || xhr.statusText);
        }
    },

    syncAccountAndContactValues() {
        const contactName = this.contactModel.get('BENE_NAME');
        this.accountModel.set('BENE_NAME', contactName);
        this.accountModel.set('BENE_NAME_CHILD', contactName);

        const contactType = this.contactModel.get('CONTACTTYPE');
        this.accountModel.set('CONTACTTYPE', contactType);
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    /**
     * changes the "disabled" prop of the save contact button, this prevents it being pressed
     * multiple times in rapid succession which may result in multiple contacts being created or
     * modals presented to the user
     * @param {Boolean} state - true for disabled, false for enabled
     */
    toggleSaveContactButton(state) {
        this.ui.$saveContactButton.prop('disabled', state);
    },

    templateHelpers() {
        const self = this;
        return {
            isContactStep() {
                return false;
            },

            isAccountStep() {
                return false;
            },

            pageTitle() {
                const action = self.mode.toUpperCase() === 'MODIFY' ? locale.get('bab.modify') : locale.get('bab.new');
                return `${action} ${locale.get('bab.contact')}`;
            },

            action() {
                return self.mode.toUpperCase() === 'MODIFY' ? locale.get('bab.modify') : locale.get('bab.add');
            },

            billPayEntitled: this.billPayEntitled,
            draftEntitled: this.draftEnabled,
            isModal: self.isModal,
            isModify: self.mode.toUpperCase() === 'MODIFY',
            isSMB: self.isSMB,
            templatesEntitled: self.isCompanyEntitledToTemplates
                || self.isCompanyEntitledToRFPTemplates,
            servicesEntitled: self.isCompanyEntitledToPayments || self.isCompanyEntitledToRFP,
            relatedSectionLabel: self.relatedSectionLabel,
            relatedPaymentsHeader: self.relatedPaymentsHeader,
        };
    },
});

export default AddContact;
