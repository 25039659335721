var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"INTERMEDIARY_ADDRESS_1") || (depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_ADDRESS_1") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"INTERMEDIARY_ADDRESS_1","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":59}}}) : helper)))
    + "</p>";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"INTERMEDIARY_ADDRESS_2") || (depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_ADDRESS_2") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"INTERMEDIARY_ADDRESS_2","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":59}}}) : helper)))
    + "</p>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"INTERMEDIARY_CITY") || (depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_CITY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"INTERMEDIARY_CITY","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":49}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_STATE") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":95}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return ",";
},"8":function(container,depth0,helpers,partials,data) {
    return "</p>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_CITY") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":26},"end":{"line":5,"column":69}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"INTERMEDIARY_STATE") || (depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_STATE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"INTERMEDIARY_STATE","hash":{},"data":data,"loc":{"start":{"line":5,"column":69},"end":{"line":5,"column":91}}}) : helper)))
    + "</p>";
},"11":function(container,depth0,helpers,partials,data) {
    return "<p>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"INTERMEDIARY_NAME") || (depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_NAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"INTERMEDIARY_NAME","hash":{},"data":data,"loc":{"start":{"line":1,"column":3},"end":{"line":1,"column":24}}}) : helper)))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_ADDRESS_1") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":70}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_ADDRESS_2") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":70}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_CITY") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":4,"column":102}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"INTERMEDIARY_STATE") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":102}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});