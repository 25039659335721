import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import CollectionView from '@glu/core/src/collectionView';
import util from '@glu/core/src/util';
import moment from 'moment';
import locale from 'system/webseries/api/locale';
import Model from '@glu/core/src/model';
import planningLayoutTmpl from './planningLayout.hbs';
import PlanModel from '../model/plan';
import ColumnSumController from './columnSumController';
import DateRowView from './dateRowView';
import RowView from './rowView';
import ReadOnlyRowView from './readOnlyRowView';
import RowSumView from './rowSumView';
import CategorySumView from './categorySumView';
import CategoryPlanModel from './categoryPlanModel';
import DisplayFactorView from './displayFactorView';
import PlanPropertiesView from './planPropertiesView';
import PlanningConfiguration from './configuration';
import SaveAsView from './controls/saveAs';

const PlanningLayout = Layout.extend({
    template: planningLayoutTmpl,
    className: 'page cashflow-planning',

    regions: {
        tableHead: 'thead',
        inflowDates: '.date-row.inflow',
        outflowDates: '.date-row.outflow',
        inflowParent: '.category-subtotal.inflow',
        outflowParent: '.category-subtotal.outflow',
        totalsRow: '.column-totals',
        scaleLegend: '[data-hook="scale-legend-region"]',
        propsRegion: '[data-hook="plan-properties-region"]',
        inflowTotal: '[data-region="inflow-total"]',
        outflowTotal: '[data-region="outflow-total"]',
    },

    initialize(options) {
        this.viewModel = new Model({
            displayFactor: 1,
        });

        this.categoryService = options.categoryservice;

        this.model = new PlanModel(
            {},
            {
                categoryService: this.categoryService,
            },
        );

        this.listenTo(
            this.model,
            {
                sync: this.handleModelSync,
            },
        );

        this.plans = options.plans;

        this.inflowPlanCollection = this.model.get('inflowCollection');
        this.outflowPlanCollection = this.model.get('outflowCollection');

        const selectedPlan = this.plans.selectedPlan();
        this.fyStart = selectedPlan.get('fyStart');
        this.fyEnd = selectedPlan.get('fyEnd');
        const startMoNum = PlanningConfiguration.get('fiscalYearStartMonth');
        const endMoNum = (startMoNum === 0) ? 11 : startMoNum - 1;
        this.fyStartMonth = moment(new Date()).month(startMoNum).format('MMM');
        this.fyEndMonth = moment(new Date()).month(endMoNum).format('MMM');

        this.listenTo(
            this.plans,
            {
                'change:selected': this.updateModel,
            },
        );
        // selected is already set from when the plans were synced
        this.updateModel(this.plans.selectedPlan());

        this.inflowView = null;
        this.outflowView = null;

        this.inflowSumModel = new CategoryPlanModel({
            name: locale.get('cashflow.total'),
        });
        this.outflowSumModel = new CategoryPlanModel({
            name: locale.get('cashflow.total'),
        });

        this.totalSumModel = new CategoryPlanModel({
            name: locale.get('cashflow.cashflow'),
        });

        // these will manage the math across columns
        this.inflowSumController = new ColumnSumController({
            target: this.inflowSumModel,
            collectionsToSum: [this.inflowPlanCollection],
        });

        /*
         * even though these two object instantiations are not set to variables they
         * have listeners that keep them relevant
         * TODO: These should be saved off?
         */
        // eslint-disable-next-line
        new ColumnSumController({
            target: this.outflowSumModel,
            collectionsToSum: [this.outflowPlanCollection],
        });

        /*
         * this needs to do inflow - outflow
         * TODO: These should be saved off?
         */
        // eslint-disable-next-line
        new ColumnSumController({
            target: this.totalSumModel,
            collectionsToSum: [this.outflowPlanCollection, this.inflowPlanCollection],
            subtractOutflows: true,
        });

        // some views need to own <tbody> elements, so we can't use regions
        this.childViews = [];
    },

    handleModelSync(model, response, options) {
        if (['create', 'update'].indexOf(options.method) === -1) {
            return;
        }

        if (options.method === 'update' && !model.get('asNewVersion')) {
            this.ui.$saveBtn.removeClass('loading').attr('aria-busy', false);
            return;
        }

        /*
         * if a new model was saved (create) or a new version was created (update)
         * fetch the collection with options selectedPlan: model
         */
        this.plans.fetch({
            reset: true,
            selectedPlan: model,
        });
    },

    updateModel(model, newYearTab) {
        /*
         * this runs twice when the selected model changes - make sure we fetch the
         * newly selected ones
         */
        if (!model.get('selected') && !newYearTab) {
            return;
        }

        this.model.set({
            cashFlowPlanId: model.get('cashFlowPlanId'),
            version: model.get('version'),
            fyStart: this.fyStart,
            fyEnd: this.fyEnd,
            name: model.get('name'),
            multiYearPlanId: model.get('multiYearPlanId'),
        });

        this.model.fetch();
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $saveBtn: 'button[data-hook="save"]',
        $saveAsBtn: 'button[data-hook="save-as"]',
        $returnAnchor: 'button[data-hook="return"]',
        $collapseTriggers: '[data-toggle="collapseByAria"]',
    },

    events: {
        'click @ui.$navLinks': 'handleFYTab',
        'click @ui.$saveBtn': 'handleSaveButton',
        'click @ui.$saveAsBtn': 'handleSaveAs',
        'click @ui.$collapseTriggers': 'handleCollapse',
        'click @ui.$returnAnchor': 'handleReturnButton',
    },

    handleCollapse(e) {
        const $clicked = this.$(e.currentTarget);
        const idsToToggle = $clicked.attr('aria-controls').split(' ');
        const expandTargets = $clicked.is('.collapsed');

        util.each(idsToToggle, function (id) {
            const $el = this.$(`#${id}`);
            if ($el.length) {
                $el.toggle(expandTargets).attr('aria-expanded', expandTargets);
            }
        }, this);

        $clicked.toggleClass('collapsed', !expandTargets).attr('aria-expanded', expandTargets);
        $clicked.closest('tr').toggleClass('collapsed', !expandTargets);
    },

    templateHelpers() {
        this.fiscalYearBtnNames = [
            moment(new Date()).format('YYYY'),
            moment(new Date()).add(1, 'years').format('YYYY'),
            moment(new Date()).add(2, 'years').format('YYYY'),
            moment(new Date()).add(3, 'years').format('YYYY'),
            moment(new Date()).add(4, 'years').format('YYYY'),
        ];

        return {
            id: this.cid,
            fiscalYearBtnNames: this.fiscalYearBtnNames,
        };
    },

    handleFYTab(e) {
        const tabYear = this.$(e.currentTarget).data('year');
        if (this.model.isChanged() === true) {
            const self = this;
            Dialog.confirm(
                locale.get('BOS.confirm.leavepage.warning'), locale.get('BOS.confirm.edit.heading'),
                (ok) => {
                    if (ok) {
                        self.model.save();
                    }
                    self.swapTabs(tabYear, e);
                },
            );
        } else {
            this.swapTabs(tabYear, e);
        }
    },

    swapTabs(tabYear, e) {
        this.fyStart = `01-${this.fyStartMonth}-${tabYear}`;
        this.fyEnd = `01-${this.fyEndMonth}-${tabYear}`;
        this.updateModel(this.model, true);

        this.ui.$navItems.removeClass('is-active active');
        this.$(e.currentTarget).parent().addClass('is-active');

        if (window.innerWidth < 768) {
            this.ui.$navTabs.toggleClass('is-open');
        }
    },

    handleSaveAs() {
        Dialog.custom(new SaveAsView({
            model: this.model,
            plans: this.plans,
        }));
    },

    handleSaveButton() {
        this.ui.$saveBtn.addClass('loading').attr('aria-busy', true);
        this.model.save();
    },

    handleReturnButton() {
        window.history.back();
    },

    onShow() {
        // tableHead is for screen readers
        this.tableHead.show(new DateRowView());
        // these 2 are visible, but set to speak: none
        this.inflowDates.show(new DateRowView());
        this.outflowDates.show(new DateRowView());

        this.inflowTotal.show(new CategorySumView({
            viewModel: this.viewModel,
            model: this.inflowSumModel,
        }));

        this.outflowTotal.show(new CategorySumView({
            viewModel: this.viewModel,
            model: this.outflowSumModel,
        }));

        this.inflowParent.show(new RowSumView({
            flowType: 'Inflow',
            model: this.inflowSumModel,
            collection: this.inflowPlanCollection,
            viewModel: this.viewModel,
        }));

        this.outflowParent.show(new RowSumView({
            flowType: 'Outflow',
            model: this.outflowSumModel,
            collection: this.outflowPlanCollection,
            viewModel: this.viewModel,
        }));

        this.totalsRow.show(new ReadOnlyRowView({
            model: this.totalSumModel,
            viewModel: this.viewModel,
        }));

        this.scaleLegend.show(new DisplayFactorView({
            model: this.viewModel,
        }));

        this.propsRegion.show(new PlanPropertiesView({
            model: this.model,
            collection: this.plans,
        }));

        /*
         * don't use regions - need to directly attach to <tbody> elements
         * make sure we clean up child view onClose
         */
        this.inflowView = new CollectionView({
            itemView: RowView,

            itemViewOptions: {
                flowType: 'Inflow',
                viewModel: this.viewModel,
            },

            collection: this.inflowPlanCollection,
            el: this.$('.category-plans.inflow'),
        });

        this.childViews.push(this.inflowView);
        this.inflowView.render();

        this.outflowView = new CollectionView({
            itemView: RowView,

            itemViewOptions: {
                flowType: 'Outflow',
                viewModel: this.viewModel,
            },

            collection: this.outflowPlanCollection,
            el: this.$('.category-plans.outflow'),
        });

        this.childViews.push(this.outflowView);
        this.outflowView.render();
    },

    onClose() {
        util.each(this.childViews, (view) => {
            view.close();
        });

        this.childViews = [];
    },
});

export default PlanningLayout;
