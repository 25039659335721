/* istanbul ignore file */
export const styles = (theme) => {
  const {
    dataComponents: {
      btnTextColor,
      buttonReset,
      countLabelColor,
      fontWeightHeavy,
      paginate,
      textDark,
      textStyles,
      textTransform
    }
  } = theme;
  return ({

    activePage: {
      '& button': {
        fontWeight: fontWeightHeavy
      }
    },
    countWrapper: {
      '& label': {
        margin: [0, 10, 0, 0]
      },
      alignItems: 'center',
      color: countLabelColor,
      display: 'flex',
      fontSize: 12,
      fontWeight: fontWeightHeavy,
      margin: [0, 40, 0, 0],
      textTransform
    },
    flexWrapper: {
      alignItems: 'center',
      display: 'flex'
    },
    pageButtons: {
      display: 'flex'
    },
    pageEllipsis: {
      color: textDark,
      lineHeight: '20px'
    },
    paginationButton: {
      ...buttonReset,
      color: btnTextColor
    },
    range: {
      color: textDark,
      fontSize: 12,
      textTransform
    },
    selectorWrapper: {
      '& button': {
        ...buttonReset,
        '&$activePage': {
          fontWeight: fontWeightHeavy
        },
        color: btnTextColor,
        padding: [0, 5],
        ...paginate
      },
      display: 'flex'
    },
    wrapper: {
      ...textStyles,
      '& > div': {
        display: 'flex'
      },
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 20
    }
  });
};

export default styles;
