import RecipientCollection from 'app/smb/collections/recipients';

export default {
    beneficiaryList: {
        get(id) {
            const recipientCollection = new RecipientCollection({
                optionId: id,
            });

            return new Promise((resolve, reject) => {
                recipientCollection.fetch({
                    success: resolve,
                    error: reject,
                });
            });
        },
    },
};
