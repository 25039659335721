import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// module reducers
import { mdfReducers, mdfInitialState } from '@dbiqe/mdf';
// import { multiCheckReducers, multiCheckInitialState } from '@dbiqe/mrdc';

const initialState = {
    ...mdfInitialState,
    // ...MultiCheckInitialState,
};

const coreReducer = combineReducers({
    ...mdfReducers,
    // ...multiCheckReducers,
});

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (state = initialState) => createStore(
    coreReducer,
    state,
    composeEnhancers(applyMiddleware(thunk)),
);
