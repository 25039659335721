import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import Collection from '@glu/core/src/collection';
import $ from 'jquery';
import moment from 'moment';
import transform from 'common/util/transform';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import printChecksTmpl from './printChecks.hbs';
import printChecksStylesTmpl from './printChecksStyles.hbs';

const PrintChecks = {
    /**
     * @method Print
     * @param {Object} options
     * @param {View} options.view - parent view
     * @param {array} options.checks -  array of check Models
     * @param {array} options.displayColumns - array of columns in order to be displayed
     * @param {function} options.onError - parent view error handler
     */
    print(options) {
        /*
         * Get a reference object to display check info in the same order presented
         * in parent grid
         */
        this.columnDisplayRef = options.displayColumns;

        /*
         * make a promise.all to wait for all check data to fetch
         * sets printCollection and calls printChecks after
         */
        const checkDataFetches = options.checks.map((check, i) => {
            const formattedCheck = this.formatCheckData(check, i);
            return this.getCheckImageData(formattedCheck);
        });

        return Promise.all(checkDataFetches)
            .then((checkModels) => {
                this.printCollection = new Collection(checkModels);
                this.printChecks(options);
            })
            .catch(() => {
                options.onError(locale.get('PAY.Status_Description.Print Failure'));
            });
    },

    /**
     * @method getCheckImageData
     * @param {Model} check
     * @return {Promise} check data fetch
     */
    getCheckImageData(check) {
        const checkDetailService = services.generateUrl('cm/checkImage/read');
        const checkRequest = this.getCheckRequest(check);

        return new Promise((resolve, reject) => {
            http.post(
                checkDetailService, checkRequest,
                (checkImageResponse) => {
                    if (checkImageResponse.errorCode === 0) {
                        // image fetch successful
                        check.set({
                            frontSrc: this.buildImageSrc(
                                checkImageResponse.frontImage.value,
                                checkImageResponse.frontImage.contentType,
                            ),
                            backSrc: this.buildImageSrc(
                                checkImageResponse.backImage.value,
                                checkImageResponse.backImage.contentType,
                            ),
                        });
                    }

                    resolve(check);
                }, err => reject(err),
            );
        });
    },

    /**
     * @param {Model} check
     * @param {number} index
     * @return {glu/model} formatCheckData
     */
    formatCheckData(check, index) {
        check.set({
            page: index + 1,
            fields: (this.columnDisplayRef) ? this.getSortedCheckFields(check) : [],
        });
        return check;
    },

    /**
     * @param {Model} check - current check model
     * @return {array} - sorted check detail information
     * Loops through set sorted reference object of check detail fields and sets
     * a value on to them
     * reflecting the check's info in the sorted order and format
     */
    getSortedCheckFields(check) {
        return this.columnDisplayRef
            .map(col => util.extend({
                fieldValue: (check.get(col.fieldName)) ? check.get(col.fieldName).replace('&amp;', '&') : '',
            }, col))
            .filter(col => col.fieldValue);
    },

    /**
     * @return {string} - image data string for check image
     */
    buildImageSrc(data, type) {
        return `data:${type};base64,${data}`;
    },

    /**
     * @return {object} - check details request params
     */
    getCheckRequest(check) {
        return {
            item: transform.hashToPairs({
                ACCOUNT_TITLE: check.get('ACCOUNT_TITLE'),
                ACCOUNT_NUMBER: check.get('ACCOUNT_NUMBER'),
                ABA_NUMBER: check.get('ABA_NUMBER'),
                ACCOUNTFILTER: `${check.get('BANKCODE')}-${check.get('ACCOUNT_NUMBER')}`,
                BANKCODE: check.get('BANKCODE'),
                TRAN_DATE: check.get('TRAN_DATE'),
                VALUE_DATE: check.get('VALUE_DATE'),
                AMOUNT: check.get('AMOUNT'),
                PIC_SEQUENCE_NUMBER: check.get('PIC_SEQUENCE_NUMBER'),
                IMAGELOCATION: check.get('IMAGELOCATION'),
                PAYEE: check.get('PAYEE'),
                SERIALNUMBER: check.get('SERIALNUMBER'),
            }),
        };
    },

    printChecks(options) {
        const checks = this.printCollection.toJSON();
        const element = window.open('', '_blank');

        if (element !== null) {
            const frag = element.document.createDocumentFragment();
            const elementHead = $(element.document).find('head').get(0);
            const elementBody = $(element.document).find('body').get(0);

            /**
             *  HACK: on IE11 there are networking issues that prevent us from loading
             * css assets,
             *  so we must include hard-coded styles
             */
            if (navigator.msSaveBlob) {
                elementHead.insertAdjacentHTML('beforeend', printChecksStylesTmpl());
            } else {
                util.each($(document).find('link[rel=stylesheet]'), (link) => {
                    const sheet = element.document.createElement('link');
                    sheet.href = link.href;
                    sheet.rel = 'stylesheet';
                    sheet.type = 'text/css';
                    frag.appendChild(sheet);
                });

                elementHead.appendChild(element.document.importNode(frag, true));
            }

            elementBody.insertAdjacentHTML('beforeend', printChecksTmpl({
                checks,
                today: moment(new Date()).format(userInfo.getDateFormat()),
                rootUrl: window.document.location.origin,
            }));

            element.focus();
            setTimeout(() => element.print(), 0);
        } else if (options.onError) {
            options.onError(locale.get('common.browser.prevents.popups'));
        }
    },
};

export default PrintChecks;
