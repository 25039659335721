import util from '@glu/core/src/util';
import cmConstants from 'app/checkManagement/constants';
import store from 'system/utilities/cache';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import template from './listDecision.hbs';

const ReversePositivePayDecisionHistoryList = ListView.extend({
    template,

    initialize(options) {
        const superOptions = this.getInitializeOptions(options);
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * Creates a options object for the parent
     *
     * @param {object} [options] - an options object
     */
    getInitializeOptions(options) {
        const additionalSearchFields = options && options.additionalSearchFields
            ? options.additionalSearchFields : null;
        this.accountNumber = options.accountNumber;
        this.accountTitle = options.accountTitle;
        this.typeDesc = options.typeDesc;

        return {
            serviceName: 'cm/listView/reversePositivePayDecisionHistory',
            serviceFunc: null,
            businessType: null,
            selector: 'none',
            returnRoute: this.options.returnRoute,
            enableSavedViews: false,
            additionalSearchFields,
        };
    },

    back() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    templateHelpers() {
        const self = this;
        const obj = ListView.prototype.templateHelpers.call(this, undefined);
        return util.extend(
            obj,
            {
                hasApproveEntitlement() {
                    return false;
                },

                hasUnapproveEntitlement() {
                    return false;
                },

                isHistory() {
                    return true;
                },

                accountName() {
                    return self.accountTitle;
                },

                accountNumber() {
                    return self.accountNumber;
                },

                type() {
                    return self.typeDesc;
                },
            },
        );
    },

    gridRowSelect(options) {
        const { model } = options;
        model.set('ACCOUNT_NUMBER', this.accountNumber);
        model.set('ACCOUNT_TITLE', this.accountTitle);
        store.set(`${this.contextKey}-actionModel`, model);
        this.navigateTo('RISK/viewReversePositivePayDecision');
        return Promise.resolve();
    },

    print() {
        this.gridView.context.productCode = cmConstants.CM;
        this.gridView.context.functionCode = cmConstants.BATCH;
        this.gridView.context.typeCode = cmConstants.REVERSE_POS_PAY_TYPE;
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.getInquiryId(),
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        this.gridView.context.productCode = cmConstants.CM;
        this.gridView.context.functionCode = cmConstants.BATCH;
        this.gridView.context.typeCode = cmConstants.REVERSE_POS_PAY_TYPE;
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: this.getInquiryId(),
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },

    /**
     * Returns the inquiry id used by this grid.
     * Used by print and export
     * @return {number} the inquiry id
     */
    getInquiryId() {
        return cmConstants.REVERSE_POS_PAY_FILTERID;
    },
});

export default ReversePositivePayDecisionHistoryList;
