import React from 'react';
import locale from '@glu/locale';
import { isDeepLinked } from 'common/util/deeplinkUtil';
import entitlements from 'common/dynamicPages/api/entitlements';
import ContentLoading from 'pcm/common/components/ContentLoading';
import store from 'pcm/common/store';
import { accountEntitlementsOptions, achEntitlementsOptions, bankEntitlementsOptions } from './accountMaintenanceUtils';

const AccountMaintenance = React.lazy(() => import('./AccountMaintenance'));

const AccountsComponent = React.lazy(() => import('./accounts/Accounts'));
const AchCompaniesComponent = React.lazy(() => import('./achCompanies/AchCompanies'));
const BanksComponent = React.lazy(() => import('./banks/Banks'));

const AddBank = React.lazy(() => import('./banks/AddBank'));
const AddAccount = React.lazy(() => import('./accounts/AddAccount'));
const AddAchCompany = React.lazy(() => import('./achCompanies/AddAchCompany'));

const BankDetails = React.lazy(() => import('./banks/BankDetails'));
const AccountDetails = React.lazy(() => import('./accounts/AccountDetails'));
const AchCompanyDetails = React.lazy(() => import('./achCompanies/AchCompanyDetails'));

const routeBase = isDeepLinked() ? 'ui-portal' : 'ui';

export default {
    pcmAccountMaintenance(activeTab) {
        store.activeTab = activeTab;

        const fetchAccountEntitlements = entitlements.getEntitlements(accountEntitlementsOptions);
        const fetchACHEntitlements = entitlements.getEntitlements(achEntitlementsOptions);
        const fetchBankEntitlements = entitlements.getEntitlements(bankEntitlementsOptions);
        const tabs = {
            banks: BanksComponent,
            accounts: AccountsComponent,
            achCompanies: AchCompaniesComponent,
        };
        Promise.all([fetchAccountEntitlements,
            fetchACHEntitlements, fetchBankEntitlements]).then((response) => {
            const [accountEntitlementsData, ACHEntitlementsData, bankEntitlementsData] = response;

            if (!bankEntitlementsData.actions ||
                    !Object.keys(bankEntitlementsData.actions).length) {
                delete tabs.banks;
            }
            if (!accountEntitlementsData.actions ||
                !Object.keys(accountEntitlementsData.actions).length) {
                delete tabs.accounts;
            }
            if (!ACHEntitlementsData.actions || !Object.keys(ACHEntitlementsData.actions).length) {
                delete tabs.achCompanies;
            }
            this.showPage(
                locale.get('menuitem.bank.and.account.maintenance'), (
                    <React.Suspense fallback={<ContentLoading />}>
                        <AccountMaintenance
                            accountEntitlements={accountEntitlementsData.actions}
                            achEntitlements={ACHEntitlementsData.actions}
                            bankEntitlements={bankEntitlementsData.actions}
                            activeTab={activeTab || Object.keys(tabs)[0]}
                            tabs={tabs}
                        />
                    </React.Suspense>
                ), {
                    headlineText: locale.get('menuitem.bank.and.account.maintenance'),
                },
            );
        });
    },

    pcmAccountMaintenanceAction(activeTab, action) {
        store.activeTab = activeTab;
        store.action = action;

        const tabToComponent = {
            accounts: action === 'add' ? AddAccount : AccountDetails,
            banks: action === 'add' ? AddBank : BankDetails,
            achCompanies: action === 'add' ? AddAchCompany : AchCompanyDetails,
        };

        const actionToTitleOptions = {
            accounts: {
                title: {
                    view: locale.get('accMaint.bankAccountDetails'),
                    edit: locale.get('accMaint.modifyBankAccountDetails'),
                    add: locale.get('accMaint.addBankAccountDetails'),
                },
                backLink: `/${routeBase}/ADMINSTRATION/bank-and-account-maintenance/accounts`,
            },
            banks: {
                title: {
                    view: locale.get('accMaint.bankDetails'),
                    edit: locale.get('accMaint.modifyBankDetails'),
                    add: locale.get('accMaint.addBankInfo'),
                },
                backLink: `/${routeBase}/ADMINSTRATION/bank-and-account-maintenance/banks`,
            },
            achCompanies: {
                title: {
                    view: locale.get('accMaint.achCompanyDetails'),
                    edit: locale.get('accMaint.modifyAchCompanyDetails'),
                    add: locale.get('accMaint.addAchCompanyDetails'),
                },
                backLink: `/${routeBase}/ADMINSTRATION/bank-and-account-maintenance/achCompanies`,
            },
        };

        const ComponentToShow = tabToComponent[activeTab];

        this.showPage(
            actionToTitleOptions[activeTab].title[action], (
                <React.Suspense fallback={<ContentLoading />}>
                    <ComponentToShow action={action} tab={activeTab} />
                </React.Suspense>
            ), {
                headlineText: actionToTitleOptions[activeTab].title[action],
                backLink: actionToTitleOptions[activeTab].backLink,
            },
        );
    },
};
