import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '@glu/layout-react';
import useStyles from './AuditRow.styles';

const AuditRow = ({
    data,
    actionLabel,
    col,
}) => {
    const classes = useStyles();
    const [show] = useState(!!data?.[0]);
    return (
        <div>
            { show ? (
                <Layout container className={classes.auditRow}>
                    <Layout col={col}>
                        {actionLabel}
                    </Layout>
                    {data.map(cell => (
                        <Layout col={col}>
                            {cell}
                        </Layout>
                    ))}
                </Layout>
            ) : (
                null
            )}
        </div>
    );
};
AuditRow.propTypes = {
    col: PropTypes.number,
    actionLabel: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.string).isRequired,
};
AuditRow.defaultProps = {
    col: 2,
};
export default AuditRow;
