import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';

const CompositePoller = Model.extend({
    defaults() {
        return {
            children: new Collection(),
        };
    },

    initialize() {
        this.listenTo(this.get('children'), 'change', function () {
            this.trigger('change');
        });
    },

    add(poller) {
        this.get('children').add(poller);
    },

    /**
     * will start the balance poller
     * @param {boolean} forceRefresh
     */
    start(forceRefresh) {
        this.get('children').each((poller) => {
            if (poller.isReady() && (forceRefresh || !poller.get('suppressPollingOnPagination'))) {
                poller.start();
            }
        });
    },

    isWorking() {
        return this.get('children').any(poller => poller.isWorking());
    },

    isSleeping() {
        return this.get('children').any(poller => poller.isSleeping());
    },

    isReady() {
        return this.get('children').any(poller => poller.isReady()) && this.get('children').every(poller => !poller.isSleeping()) && this.get('children').every(poller => !poller.isWorking());
    },

    reset() {
        this.get('children').reset();
    },
});

export default CompositePoller;
