import Model from '@glu/core/src/model';

export default Model.extend({
    defaults: {
        notificationType: '',
        count: '0',
        subject: '',
        message: '',
        url: '',
        evenRow: false,
        warning: false,
        urlText: '',
        hideCount: false,
    },
});
