var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-warning\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <h2 class=\"modal-title\"><span class=\"icon-info-solid\"></span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"modalTitle") || (depth0 != null ? lookupProperty(depth0,"modalTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modalTitle","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":87}}}) : helper)))
    + "</h2>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"form-group\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"bodyMessage") || (depth0 != null ? lookupProperty(depth0,"bodyMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bodyMessage","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":31}}}) : helper)))
    + "\n                <div class=\"noManage\" data-hook=\"updated-bene-grid\"></div>\n            </div>\n            <p>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PAY.continueMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":47}}}))
    + "</p>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\" data-action=\"save\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":77},"end":{"line":14,"column":105}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\" data-dismiss=\"modal\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":101},"end":{"line":15,"column":127}}}))
    + "</button>\n        </div>\n    </div>\n</div>";
},"useData":true});