import ItemView from '@glu/core/src/itemView';
import userInfo from 'etc/userInfo';
import noteTmpl from 'app/reports/views/lockbox/modals/notes/note.hbs';

export default ItemView.extend({
    template: noteTmpl,

    initialize(options) {
        this.parent = options.parent;
    },

    removeNote() {
        this.model.action = 'destroy';
        this.parent.models.push(this.model);
        this.trigger(this.model.action);
    },

    templateHelpers() {
        const self = this;
        return {
            author() {
                return self.model.get('ENTERED_BY') || userInfo.id;
            },
        };
    },
});
