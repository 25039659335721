var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                    <span class=\"col-3 plan-prop\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"lineStatus") || (depth0 != null ? lookupProperty(depth0,"lineStatus") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lineStatus","hash":{},"data":data,"loc":{"start":{"line":9,"column":50},"end":{"line":9,"column":64}}}) : helper)))
    + "</span>\n                    <span class=\"col-2 plan-value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"lineValue") || (depth0 != null ? lookupProperty(depth0,"lineValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lineValue","hash":{},"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":64}}}) : helper)))
    + "</span>\n                    <span class=\"col-2 plan-prop\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"planProp") || (depth0 != null ? lookupProperty(depth0,"planProp") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"planProp","hash":{},"data":data,"loc":{"start":{"line":11,"column":50},"end":{"line":11,"column":62}}}) : helper)))
    + "</span>\n                    <span class=\"col-2 plan-value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"propValue") || (depth0 != null ? lookupProperty(depth0,"propValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"propValue","hash":{},"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":64}}}) : helper)))
    + "</span>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"col-1 plan-count-col\">\n    <div class=\"plan-count\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"subPlanCount") || (depth0 != null ? lookupProperty(depth0,"subPlanCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subPlanCount","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":44}}}) : helper)))
    + "</div>\n        <div class=\"plan-count-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"plansLabel") || (depth0 != null ? lookupProperty(depth0,"plansLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"plansLabel","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":51}}}) : helper)))
    + "</div>\n</div>\n<div class=\"col-7 plan-properties-col\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"planProps") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"col-4\">\n    <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"planNoteLabel") || (depth0 != null ? lookupProperty(depth0,"planNoteLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"planNoteLabel","hash":{},"data":data,"loc":{"start":{"line":17,"column":9},"end":{"line":17,"column":26}}}) : helper)))
    + "</div>\n        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"planNotes") || (depth0 != null ? lookupProperty(depth0,"planNotes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"planNotes","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":26}}}) : helper)))
    + "</div>\n</div>\n";
},"useData":true});