var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"back\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"checkMgmt.back.to.fraud",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":142},"end":{"line":3,"column":178}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.decision.history.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":69}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"ACCOUNTFILTER") || (depth0 != null ? lookupProperty(depth0,"ACCOUNTFILTER") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ACCOUNTFILTER","hash":{},"data":data,"loc":{"start":{"line":5,"column":70},"end":{"line":5,"column":87}}}) : helper)))
    + "</h1>\n</div>\n<div class=\"page\">\n    <div class=\"section-container\">\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n        <div data-region=\"filterRegion\" aria-live=\"polite\"/>\n        <div class=\"section-body\">\n            <button type=\"button\" name=\"search\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":72},"end":{"line":14,"column":98}}}))
    + "</button>\n        </div>\n\n        <div data-region=\"decisionHistoryListRegion\" aria-live=\"polite\"/>\n    </div>\n\n\n</div>\n";
},"useData":true});