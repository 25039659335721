import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import { appBus } from '@glu/core';
import locale from '@glu/core/src/locale';
import dialog from '@glu/dialog';
import services from 'services';
import configuration from 'system/configuration';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { postData } from 'common/util/services';
import store from 'system/utilities/cache';
import errHandler from 'system/errHandler';
import userInfo from 'etc/userInfo';
import initPaymodeIntegration from '@dgb/paymode-integration'; // DGB
// import initPaymodeIntegration from 'paymode-integration'; // PCM
import 'common/uiWidgets/pmxReportsWidget/pmxReportsWidget';
import pmxConst from './constants';
import paymodeFeatures from './paymodeFeatures';
import paymodePrivileges from './paymodePrivileges';
import externalClasses from './externalClassNames';
import './vendorNetworkWidget';

let integrationAPIPromise;
let dgbShowPage;

/**
 * Wrapper function for showPage that is passed into the paymode integration API
 * We wrap because we won't have the real showPage immediately but we need to pass something to
 * paymode immediately.  So we pass a wrapper and fill in the implementation later.
 *
 * @param {Object} pageView - The view to show with showPage.
 */
function wrappedDgbShowPage(pageView) {
    if (dgbShowPage) {
        dgbShowPage(pageView);
    }
}

/**
 * Kickstarts the integration by loading it up and configuring it.
 * This entails actually loading the code into the browser which is not done
 * until someone actually needs to see
 * paymode functionality.  When the code is done loading and initializing it
 * returns the integration API as the
 * resolved value of the immediately returned promise.
 *
 * @return {Promise} Promise that resolves with the integration API as its value.
 */
export function loadPaymode() {
    if (integrationAPIPromise) {
        return integrationAPIPromise;
    }
    integrationAPIPromise = initPaymodeIntegration({
        uiRootPrefix: configuration.isPortal() ? 'ui-portal/' : 'ui/',
        showPage: wrappedDgbShowPage,

        formatOverrides: {
            overrideDateFormat: userInfo.getDateFormat(),
            overrideDateTimeFormat: userInfo.getDateTimeFormat(),
            overrideCurrencyFormat: '0,0.00',
        },

        routeToBanking: (route) => {
            appBus.trigger('router:navigate', route, true);
        },

        updateRouteFragment: (fragment) => {
            Backbone.history.fragment = fragment;
        },

        appBusHandlers: {
            'http:401': () => {
                dialog.alert(locale.get('anErrorOccurredMsg'), locale.get('anErrorOccurredTitle'));
            },
        },

        localeOverlay: {
            strings: {
                systemError: locale.get('anErrorOccurredMsg'),
                'contactOptions.grid.action.edit': locale.get('action.modify'),
            },
        },

        userData: {
            companyId: userInfo.getMembershipID(),
            commonName: userInfo.get('group'),
        },

        privileges: paymodePrivileges,
        features: paymodeFeatures,

        // the url will be encoded in PMX
        processBslUrlFunction: (encodedUrl) => {
            const url = decodeURIComponent(encodedUrl);
            const defaultPath = serverConfigParams.get('pmxPaymodeIntegrationDefaultPath');
            const replacementPath = serverConfigParams.get('pmxPaymodeIntegrationReplacementPath');
            let url2 = url;
            if (defaultPath && replacementPath) {
                url2 = url.replace(defaultPath, replacementPath);
            }
            return `${services.generateServletUrl(pmxConst.PMX_PROXY_SERVLET)}${url2}`;
        },

        pmxHeaders: {
            pmx: true,
        },

        externalClasses,
    });

    return integrationAPIPromise;
}

/**
 * Installs the paymode showPage hook to obtain a live "showPage" function
 * that we can use to show paymode pages in the integration.
 *
 * @param {function} Controller - Controller class, not instance, for DGB
 */
export function addShowPagePaymodeIntegrationHook(Controller) {
    // we need access to this.showPage from the initialized controller ASAP
    // in case we get sent to a paymode full screen page.  This could be
    // attached using a setter on this module if that is preferred though
    // this keeps the changes to DGB small
    const origInitialize = Controller.prototype.initialize;
    const ControllerEditable = Controller;

    ControllerEditable.prototype.initialize = function init(options) {
        dgbShowPage = this.showPage.bind(this);
        origInitialize.call(this, options);
    };
}

let paymodeRoutingUpdaterAdded = false;

function addPaymodeRoutingUpdater(fullPageIntegrationAPI) {
    if (paymodeRoutingUpdaterAdded) {
        return;
    }

    appBus.on('router:navigate', (route) => {
        // keep paymode's router in sync so we know where we are as the user moves around in banking
        fullPageIntegrationAPI.updateRouteFragment(route);
    });

    paymodeRoutingUpdaterAdded = true;
}

/**
 * Returns a route to use in PMX (without 'px/')
 *
 * @param {string} fragment - Backbone.history.fragment
 */
const getRoute = fragment => fragment.split('px/')[1] || '';

/**
 * Connects DGB to the paymode integration by hooking into key points so that
 * whenever the user goes to a paymode
 * url/route the integration will load up the paymode code, if not loaded
 * already, initialize it, and then when its
 * ready complete navigation to that route. This is intended to be called at
 * DGB startup so that we can handle
 * full page reloads where the very first url hit is a paymode one.
 *
 * @param {function} Controller - Controller class, not instance, for DGB
 * @param {function} Router - Router class, not instance, for DGB
 */
export function addFullPagePaymodeIntegrationHooks(Controller, Router) {
    let pmxPromise;
    let matchedRouteDef;
    let routeInvoked;

    const RouterEditable = Router;
    RouterEditable.prototype.appRoutes['px/*path'] = 'loadPaymodeAndReroute';

    const ControllerEditable = Controller;
    ControllerEditable.prototype.loadPaymodeAndReroute = () => {
        routeInvoked = Backbone.history.fragment;
        routeInvoked = routeInvoked.substring(routeInvoked.indexOf('px/'));
        matchedRouteDef = util.find(pmxConst.PMX_DGB_ROUTES, (definedRoute) => {
            if (typeof definedRoute.route === 'string' && routeInvoked.includes(definedRoute.route)) {
                return true;
            }
            if (definedRoute.route instanceof RegExp
                && definedRoute.route.test(routeInvoked)) {
                return true;
            }
            return false;
        });

        if (!matchedRouteDef) {
            return;
        }

        pmxPromise = new Promise((resolve, reject) => {
            http.post(services.generateUrl('/accessService/hasAccess'), matchedRouteDef.entitlementCheckParams, (result) => {
                resolve(result);
            }, (status) => {
                reject(`${status.status} ${status.statusText}`);
            });
        });

        pmxPromise.then((result) => {
            if (result) {
                loadPaymode().then((integrationAPI) => {
                    integrationAPI.loadFullPageIntegration().then((fullPageIntegrationAPI) => {
                        fullPageIntegrationAPI.routeToPaymode(
                            getRoute(Backbone.history.fragment),
                            {
                                trigger: true,
                                replace: true,
                            },
                        );

                        addPaymodeRoutingUpdater(fullPageIntegrationAPI);
                    });
                });
            }
        }).then(null, errHandler);
    };

    ControllerEditable.prototype.loadPaymode = () => {
        loadPaymode().then((integrationAPI) => {
            integrationAPI.loadFullPageIntegration()
                .then(fullPageIntegrationAPI => addPaymodeRoutingUpdater(fullPageIntegrationAPI));
        });
    };

    addShowPagePaymodeIntegrationHook(Controller);
}

/**
 * Get a single component from the integration API by its id.
 *
 * @param {string} id - The id of the component desired.
 */
export function getComponentById(id) {
    return loadPaymode().then(integrationAPI => integrationAPI.getComponentById(id));
}

/**
 * Get multiple components from the integration API by their ids.
 *
 * @param {Array<string>} id - The ids of the components desired
 */
export function getComponentByIdAsView(id) {
    return loadPaymode()
        .then(integrationAPI => integrationAPI.getComponentByIdAsView(id, Marionette.ItemView));
}

/**
 * Get a single component from the integration API by its id and wrap it
 * inside a marionette view.
 *
 * @param {string} ids - The id of the component desired.
 */
export function getComponentByIds(ids) {
    return loadPaymode().then(integrationAPI => integrationAPI.getComponentsByIds(ids));
}

/**
 * Get multiple components from the integration API by their ids and wrap
 * them inside a marionette views.
 *
 * @param {Array<string>} ids - The ids of the components desired
 */
export function getComponentsByIdsAsViews(ids) {
    return loadPaymode()
        .then(integrationAPI => integrationAPI.getComponentsByIdsAsViews(ids, Marionette.ItemView));
}

/**
 * Get a single util/object from the integration API by its id.
 * Utils are not UI components and are utility functions or objects.
 *
 * @param {string} id - The id of the util desired.
 */
export function getUtilById(id) {
    return loadPaymode().then(integrationAPI => integrationAPI.getUtilById(id));
}

/**
 * Get multiple utils/objects from the integration API by its id.
 * Utils are not UI components and are utility functions or objects.
 *
 * @param {Array<string>} ids - The ids of the utils desired.
 */
export function getUtilsByIds(ids) {
    return loadPaymode().then(integrationAPI => integrationAPI.getUtilsByIds(ids));
}

/**
 * Fetch PMX entitlements so that we can setup the paymodePrivileges object correctly
 * @returns {Promise}
 */
export function fetchPrivileges() {
    return new Promise((resolve, reject) => {
        postData(
            services.generateUrl('/accessService/hasEntitlements'),
            pmxConst.PMX_PRIVILEGES,
        )
            .then((results) => {
                store.set('PMX_PRIV', results);
                resolve(results);
            })
            .catch(reject);
    });
}

/**
 * Set some or all meta privileges.  See app.js in paymode integration code
 * in pmx-ui report for most up to date documentation.  Copy is below
 * @param {Object} privileges - object with meta privileges specified. Meta
 * privileges are used to tie keys specifying a particular action that
 * can or cannot be taken in the UI to logic or static values
 * that return a boolean to indicate whether the UI should allow that action or not.
 * The overall structure is a nested object with the combination
 * of property names on any path forming the action to be evaluated and the leaf
 * node at the end forming the answer (true/false) as to
 * whether the user can do that action.
 *
 * One use of this structure is to check if a user can view
 * the remittance details for individual remittances in the remittance info section on
 * the payment details screen. The meta privilege key "paymentDetails.viewRemittanceDetails"
 * is used for this.  The privileges object is an
 * object with a paymentDetails property containing another
 * object with all the privileges for the payment details screen as properties whose
 * values evaluate to true or false indicating if access is granted or not.
 * Basically we split on the "."'s and recurse into the privileges
 * object to get the value. The value may be a function which
 * can even receive arguments to be used in evaluating the true/false result.
 * Privileges are checked using the hasPrivilege function
 * which has a final rest argument used to pass in the parameters used to check any
 * individual privilege. The "paymentDetails.releaseFiles.onAccount privilege is one example,
 * it receives an accountId to check.
 *
 * Note, that you need to mirrors paymode's hierarchy in defining the privileges
 * but can define all the values they lead to any way you like.
 * You can use any passed in arguments and form a new function to
 * evaluate them or just specify true or false statically. Also the privileges
 * passed in here can be replaced or added too with the setPrivileges integration API function.
 */
export function setPrivileges(privileges) {
    return loadPaymode().then(integrationAPI => integrationAPI.setPrivileges(privileges));
}

export default {
    getComponentById,
    getComponentByIds,
    getComponentByIdAsView,
    getComponentsByIdsAsViews,
    getUtilById,
    getUtilsByIds,
    setPrivileges,
    addFullPagePaymodeIntegrationHooks,
};
