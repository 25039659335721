import Column from './column';

export default Column.extend({
    delegateEvents(...args) {
        Column.prototype.delegateEvents.apply(this, args);

        /*
         *  On editable grids, each change made needs to be saved in order for updates to the
         * page to be made When a save is made, it always triggers "sync", which causes
         * the grid to render after every change This is removed to prevent performance issues
         * with constant rerendering on grids with many columns of data
         */
        if (this.options.grid.columns.editableChildGrid) {
            this.stopListening(this.collection, 'sync');
        }
    },
});
