var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-12 row\">\n        <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"newDateInfo") || (depth0 != null ? lookupProperty(depth0,"newDateInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newDateInfo","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":26}}}) : helper)))
    + "</p>\n        <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"newDatePrompt") || (depth0 != null ? lookupProperty(depth0,"newDatePrompt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newDatePrompt","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":28}}}) : helper)))
    + "</p>\n    </div>\n</div>\n";
},"useData":true});