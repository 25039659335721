var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-2 field-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isModifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label for=\"limitTrans-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":42}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"administration.limit.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":60},"end":{"line":6,"column":105}}}))
    + "</label>\n            <input type=\"text\" class=\"limit-field\" name=\"limits.transaction.value\" data-bind=\"model\" id=\"limitTrans-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":116},"end":{"line":7,"column":123}}}) : helper)))
    + "\">\n            <span class=\"help-block limit-field\" data-bind=\"model\" data-validate=\"limits.transaction.value\"></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"limits") : depth0)) != null ? lookupProperty(stack1,"transaction") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":12,"column":8}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limit.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":46},"end":{"line":10,"column":91}}}))
    + "</span>\n            <span class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"money").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"limits") : depth0)) != null ? lookupProperty(stack1,"transaction") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":11,"column":46},"end":{"line":11,"column":80}}}))
    + "</span>\n        ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-2 field-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isModifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label for=\"limitBatch-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":42}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"administration.limit.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":60},"end":{"line":19,"column":99}}}))
    + "</label>\n            <input type=\"text\" class=\"limit-field\" name=\"limits.batch.value\" data-bind=\"model\"  id=\"limitBatch-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":20,"column":111},"end":{"line":20,"column":118}}}) : helper)))
    + "\">\n            <span class=\"help-block limit-field\" data-bind=\"model\" data-validate=\"limits.batch.value\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"limits") : depth0)) != null ? lookupProperty(stack1,"batch") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":25,"column":8}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limit.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":46},"end":{"line":23,"column":85}}}))
    + "</span>\n            <span class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"money").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"limits") : depth0)) != null ? lookupProperty(stack1,"batch") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":24,"column":46},"end":{"line":24,"column":74}}}))
    + "</span>\n        ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-2 field-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isModifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label for=\"limitDaily-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":32,"column":35},"end":{"line":32,"column":42}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"administration.limit.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":60},"end":{"line":32,"column":99}}}))
    + "</label>\n            <input type=\"text\" class=\"limit-field\" name=\"limits.daily.value\" data-bind=\"model\"  id=\"limitDaily-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":33,"column":111},"end":{"line":33,"column":118}}}) : helper)))
    + "\">\n            <span class=\"help-block limit-field\" data-bind=\"model\" data-validate=\"limits.daily.value\"></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"limits") : depth0)) != null ? lookupProperty(stack1,"daily") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":38,"column":8}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limit.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":46},"end":{"line":36,"column":85}}}))
    + "</span>\n            <span class=\"form-control-static\">"
    + alias2(lookupProperty(helpers,"money").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"limits") : depth0)) != null ? lookupProperty(stack1,"daily") : stack1)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":37,"column":46},"end":{"line":37,"column":74}}}))
    + "</span>\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-3\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":35}}}))
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTransaction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":14,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBatch") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":27,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDaily") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":0},"end":{"line":40,"column":7}}})) != null ? stack1 : "");
},"useData":true});