import Layout from '@glu/core/src/layout';
import applicationConfiguration from 'system/webseries/models/applicationConfiguration';
import intermediaryBankWrapperTmpl from './intermediaryBankWrapper.hbs';
import IntermediaryBankForm from './intermediaryBankForm';
import IntermediaryBankFormPreferredResults from './intermediaryBankFormPreferredResults';

const IntermediaryBankWrapper = Layout.extend({
    template: intermediaryBankWrapperTmpl,

    regions: {
        bankOne: '.intermediary-bank-view-one',
        bankTwo: '.intermediary-bank-view-two',
    },

    ui: {
        $bankTwo: '.intermediary-bank-view-two',
        $addSecond: '.add-second-button-container',
    },

    initialize(options) {
        this.mode = options.mode;
        this.isEntryRow = options.isEntryRow;

        if (applicationConfiguration.getValue('WIRES', 'RTGSRESTRICTLOOKUPINTERMEDIARY') === '1') {
            this.bankOneView = new IntermediaryBankFormPreferredResults({
                mode: this.mode,
                isEntryRow: this.isEntryRow,
                model: this.model,
                fieldTypeData: this.options.fieldTypeData,
            });
        } else {
            this.bankOneView = new IntermediaryBankForm({
                mode: this.mode,
                model: this.model,
                fieldTypeData: this.options.fieldTypeData,
            });
        }
        this.bankTwoView = new IntermediaryBankForm({
            mode: this.mode,
            model: this.model,
            suffix: '2',
            fieldTypeData: this.options.fieldTypeData,
        });
    },

    onRender() {
        if (this.firstRender) {
            this.bankOneView.firstRender = true;
            this.firstRender = false;
        } else {
            this.bankOneView.firstRender = false;
        }
        this.bankOne.show(this.bankOneView);
        this.bankTwo.show(this.bankTwoView);
    },

    addIntermediaryBank() {
        this.ui.$bankTwo.toggleClass('hidden');
        this.ui.$addSecond.toggleClass('hidden');
    },

    templateHelpers() {
        const m = this.model;
        return {
            hasBank() {
                return !!m.get('INTERMEDIARY_ID');
            },

            hasSecond() {
                return m.has('INTERMEDIARY2_ID') || m.has('INTERMEDIARY2_NAME');
            },
        };
    },
});

export default IntermediaryBankWrapper;
