import { buttonReset } from '../../utils/helpers.styles';
/* istanbul ignore file */
const TEMP = '#a8cf38';
export const styles = (theme) => {
  const {
    palette: {
      background,
      primary
    }
  } = theme;
  return ({
    background: {
      height: '100%',
      minHeight: '100%',
      backgroundColor: primary.darkBackground,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 15
    },
    closeButton: {
      ...buttonReset,
      position: 'absolute',
      top: 20,
      right: 17,
      cursor: 'pointer',
      '& svg': {
        fill: background
      }
    },
    headerWrapper: {
      padding: '50px 6px 25px'
    },
    content: {
      overflow: 'hidden',
      height: '100%'
    },
    tabs: {
      '& > span': {
        background: TEMP
      }
    },
    tab: {
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", "sans-serif"',
      fontSize: 20,
      color: background,
      textTransform: 'uppercase',
      display: 'inline-block',
      '& > div': {
        paddingBottom: 5
      }
    }
  });
};

export default styles;
