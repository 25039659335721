var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    "
    + alias2(((helper = (helper = lookupProperty(helpers,"downloadMessage") || (depth0 != null ? lookupProperty(depth0,"downloadMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"downloadMessage","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":23}}}) : helper)))
    + " <a href=\"/REPORTING/btrExport\" data-action=\"navigateToDownloads\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.export.success.link",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":89},"end":{"line":2,"column":129}}}))
    + "</a>.\n</div>\n";
},"useData":true});