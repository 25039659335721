import { Layout } from '@glu/core';
import tmpl from './flexDropdownItemsLayout.hbs';
import FlexDropdownItemCompositeView from './flexDropdownItemCompositeView';

export default Layout.extend({
  template: tmpl,
  regions: {
    customView: '.region-custom-view',
    items: '.region-items'
  },

  initialize() {
    this.itemsView = new FlexDropdownItemCompositeView({
      collection: this.collection,
      model: this.model,
      multiSelect: this.options.multiSelect,
      disableMultiButton: this.options.disableMultiButton,
      tableView: this.options.tableView,
      columnTitles: this.options.columnTitles,
      showGroups: this.options.showGroups,
      dropDownItemView: this.options.dropDownItemView
    });
    // just proxy this event to parent view
    this.listenTo(this.itemsView, {
      closeOnSelection: this.closeDropdown,
      'reset:hierarchy': this.resetHierarchy,
      'clear:selected': this.clearSelected,
      'select:all': this.selectAll
    });
  },

  onRender() {
    this.showCustomView();
    this.showItems();
  },

  showItems() {
    this.items.show(this.itemsView);
    this.filterText = this.itemsView.filterText;
  },

  showCustomView() {
    const CustomView = this.options.customView;
    const customViewOptions = this.options.customViewOptions || {};

    if (!CustomView) {
      return;
    }

    this.customView.show(new CustomView(customViewOptions));
  },

  closeDropdown() {
    this.trigger('closeOnSelection');
  },

  /**
   * Sets the location collection back to the default
   */
  resetHierarchy() {
    this.resetCollection();
    this.model.set('breadcrumb', []).trigger('change');
  },

  goLevelUp() {
    if (!this.itemsView) {
      return;
    }
    this.itemsView.goLevelUp();
  },

  clickDone() {
    if (!this.itemsView) {
      return;
    }
    this.itemsView.clickDone();
  },
  /**
   * Sets the dropdown data UI back to original state, but leaves selected items as selected
   */
  resetFilter() {
    this.model.resetFilter();
    this.resetHierarchy();
  },

  /**
   * Resets all items on item collection to unselected. For a hierarchical dropdown will clears out current hierarchy level selection.
   *
   * @param clearAll - boolean - if true, clear all selected items
   */
  clearSelected(clearAll) {
    let parentId;
    let clearThisLevelOnly = false;
    if (!clearAll && this.options.multiSelect) {
      parentId = this.model.get('breadcrumb').length > 0 ? this.model.get('breadcrumb')[this.model.get('breadcrumb').length - 1].id : 0;
      clearThisLevelOnly = true;
    }

    this.model.clearSelected(clearThisLevelOnly, parentId);
    this.reloadCollection();
  },

  /**
   * Enables the selection of the single item that is the result of filtering the dropdown via the filter feature using the enter key.
   *
   * If this is called we check to ensure a filter is in effect and that the filter has narrowed the dropdown items to a single item.
   * If it has then we select that item.
   *
   * Note: This will enable selecting the top level of a hierarchy and drilling down a single level. After that
   * the breadcrumbs will be an item in the collection and the user will need to use the down arrow to leave the
   * filter to select items. Further enhancement could allow infinite drilldown via the filter if desired.
   */
  selectSingleFilteredItemResult() {
    let itemModel;

    if (!this.itemsView) {
      return;
    }

    if (this.filterText && this.collection.length === 1) {
      itemModel = this.collection.at(0);

      this.itemsView.selectItem(0, itemModel.get('id'), itemModel.get('name'), !!itemModel.get('child'));
    }
  },

  /**
   * Filters the items collection to add hide: true to any items which do not match the value of the type ahead dropdown
   *
   * @param {string} filterText - string used to filter data
   * */

  filterCollection(filterText) {
    this.filterText = filterText.toLowerCase() || '';
    this.model.filterCollection(this.filterText);

    if (!this.model.dynamic) {
      this.reloadCollection();
      return;
    }

    if (!this.itemsView) {
      return;
    }

    this.itemsView.addSpinner();
  },

  /**
   * Sets the collection back to original set of data
   */
  resetCollection() {
    this.model.getActive();
  },

  reloadCollection() {
    if (!this.itemsView) {
      return;
    }
    this.itemsView.reloadCollection();
  }
});
