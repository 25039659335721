import Layout from '@glu/core/src/layout';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import entitlements from 'common/dynamicPages/api/entitlements';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import store from 'system/utilities/cache';
import ScheduledExportView from './scheduledExportView';
import TransmissionProfiles from './transmissionProfiles';
import template from './scheduledExportsWidget.hbs';
import mobileUtil from '../../../mobile/util/mobileUtil';

const SCHEDULED_EXPORTS = 'scheduledExports';
const TRANSMISSION_PROFILES = 'transmissionProfiles';

export default Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $scheduledExportsTab: '[data-hook="getScheduledExportsTab"]',
        $transmissionProfilesTab: '[data-hook="getTransmissionProfilesTab"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
    },

    isInitialRender: true,

    initialize(options) {
        if (options?.returnRoute?.length) {
            let { returnRoute } = options;
            if (returnRoute.charAt(0) === '/') {
                returnRoute = returnRoute.slice(1);
            }
            store.set('ScheduledExportsWidget:returnRoute', options.returnRoute);
        }
        const getScheduledEntitlements = entitlements.getEntitlements({
            context: {
                serviceName: 'export/scheduledExport',
            },
        });
        const getTransProfilesEntitlements = entitlements.getEntitlements({
            context: {
                serviceName: 'export/transmissionProfile',
            },
        });
        Promise.all([getScheduledEntitlements, getTransProfilesEntitlements])
            .then(this.parseEntitlements.bind(this))
            .then(() => {
                this.setHasLoadedRequiredData(true);
                this.render();
            });
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            return;
        }
        createTabsToggleButton(this);
        this.renderInitialTab();
        this.isInitialRender = false;
    },

    /**
     * Get default view and tab
     * @param {string} storedTab - tab from store
     * @returns {Object} object containing default view and tab selector
     */
    getDefaultView(storedTab) {
        if (this.scheduleEntitlements.SELECT && storedTab !== TRANSMISSION_PROFILES) {
            return {
                view: this.createScheduledExportView(),
                tab: this.ui.$scheduledExportsTab,
            };
        }
        return {
            view: new TransmissionProfiles(),
            tab: this.ui.$transmissionProfilesTab,
        };
    },

    /**
     * Extra a list of actions from each of the entitlement promises
     * @param {Array} response - destructured to schedule and
     * transmission profile entitlements
     */
    parseEntitlements([scheduleEntitlements, transProfileEntitlements]) {
        this.scheduleEntitlements = scheduleEntitlements.actions;
        this.transProfileEntitlements = transProfileEntitlements.actions;
    },

    /**
     * Get active tab from the store, get default view and show it
     */
    renderInitialTab() {
        const storedTab = store.get('ScheduledExportsWidget:activeTab');
        const { view, tab } = this.getDefaultView(storedTab);
        this.showTabContent(storedTab, view, tab);
    },

    /**
     * Get a new scheculed export list view
     * @returns {Object}
     */
    createScheduledExportView() {
        return new ScheduledExportView({
            id: 'AUTOMATED_EXPORT_LIST',
            insertRoute: 'REPORTING/scheduledExports/insert',
            modifyRoute: 'REPORTING/scheduledExports/modify',
            viewRoute: 'REPORTING/scheduledExports/view',
        });
    },

    /**
     * Render the scheduledExportsList in the tab content region
     */
    showScheduledExports() {
        this.showTabContent(
            SCHEDULED_EXPORTS,
            this.createScheduledExportView(),
            this.ui.$scheduledExportsTab,
        );
    },

    /**
     * Render the transmissionProfile in the tab content region
     */
    showTransmissionProfiles() {
        this.showTabContent(
            TRANSMISSION_PROFILES,
            new TransmissionProfiles(),
            this.ui.$transmissionProfilesTab,
        );
    },

    /**
     * When the tab is not yet active, update the content in the
     * region, save the active tab
     * @param {string} key - key to set as active tab in the store
     * @param {Object} view - view to render in the tab region
     * @param {Object} tabSelector - tab to set as active
     * @returns {function}
     */
    showTabContent(key, view, tabSelector) {
        if (tabSelector.hasClass('is-active')) {
            toggleTabs(this);
            return;
        }
        store.set('ScheduledExportsWidget:activeTab', key);
        const viewWithCaption = view;
        // caption added
        if (view.contextKey === 'export_transmissionProfile') {
            viewWithCaption.options.gridOptions = { caption: view?.contextDef?.serviceName.split('/')[0] + view?.contextDef?.name };
        } else if (view.contextKey === 'export_scheduledExport') {
            viewWithCaption.options.gridOptions = { caption: view?.contextDef?.name };
        }
        this.tabContentRegion.show(mobileUtil.isMobileGridEnabled() ? view : viewWithCaption);
        this.setActiveTab(tabSelector);
    },

    /**
     * Remove 'is-active' class and add only to the desired active tab
     * @param {Object} $tab - jquery object for the tab that is to be selected
     */
    setActiveTab($tab) {
        this.ui.$navItems.removeClass('is-active');
        $tab.addClass('is-active');
        this.currentTabText = $tab.find('a').text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

    templateHelpers() {
        return {
            isScheduleEntitled: this.scheduleEntitlements
                ? this.scheduleEntitlements.SELECT : false,
            isTransProfileEntitled: this.transProfileEntitlements
                ? this.transProfileEntitlements.SELECT : false,
        };
    },
});
