import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import moment from 'moment';
import userInfo from 'etc/userInfo';

export default Model.extend({
    defaults() {
        return {
            functionCode: '',
            tNum: '',
        };
    },

    sync(method, model, optionsParam) {
        const options = optionsParam;
        const self = this;
        const xhr = http.post(services.generateUrl('/payment/getPaymentAuditHistory'), model, options.success, options.error);

        options.read = true;
        this.trigger('request', model, xhr, options);

        return xhr.then((data) => {
            self.trigger('sync', data);
            return data;
        });
    },

    parse(data) {
        if (data && data.paymentAuditHistory) {
            // format data
            this.fixNewLine(data.paymentAuditHistory);
            this.formatTime(data.paymentAuditHistory);
        }
    },

    /*
     * Split our 'change' comments data string by newline into
     * an object of arrays with the same name on the data object
     */
    fixNewLine(dataParam) {
        const data = dataParam;
        for (let i = 0; i < data.length; i += 1) {
            if (data[i].change) {
                data[i].change = data[i].change.split('\n');
            }
        }

        return data;
    },

    // Format date/time with the userinfo
    formatTime(dataParam) {
        const data = dataParam;
        for (let i = 0; i < data.length; i += 1) {
            data[i].changedTs = moment(data[i].changedTs).format(userInfo.getDateTimeFormat());
        }

        return data;
    },
});
