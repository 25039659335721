var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <br><strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"typeDescription") || (depth0 != null ? lookupProperty(depth0,"typeDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"typeDescription","hash":{},"data":data,"loc":{"start":{"line":62,"column":32},"end":{"line":62,"column":51}}}) : helper)))
    + "</strong>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <select class=\"form-control\" name=\"widgetType\" data-bind=\"model\">\n                        <option value=\"R\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.remote",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":42},"end":{"line":65,"column":77}}}))
    + "</option>\n                        <option value=\"L\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.local",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":66,"column":42},"end":{"line":66,"column":76}}}))
    + "</option>\n                    </select>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <br><strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"externalWidgetType") || (depth0 != null ? lookupProperty(depth0,"externalWidgetType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"externalWidgetType","hash":{},"data":data,"loc":{"start":{"line":102,"column":32},"end":{"line":102,"column":54}}}) : helper)))
    + "</strong>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <select class=\"form-control\" name=\"externalWidgetType\" data-bind=\"model\">\n                        <option value=\"WIDGET\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.widget",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":47},"end":{"line":105,"column":82}}}))
    + "</option>\n                        <option value=\"MFA\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.mfa",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":106,"column":44},"end":{"line":106,"column":76}}}))
    + "</option>\n                    </select>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.backToPreviousPage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":191}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"stateDescription") || (depth0 != null ? lookupProperty(depth0,"stateDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stateDescription","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":51}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.bankWidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":92}}}))
    + "</h1>\n</div>\n\n<fieldset class=\"section section-container\">\n    <div class=\"section-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.identifiers",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":72}}}))
    + "</div>\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"required field-container-lg field-container\">\n                <label for=\"dataWidgetId\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":42},"end":{"line":14,"column":73}}}))
    + "</label>\n                <input class=\"form-control\" name=\"dataWidgetId\" data-bind=\"model\" type=\"text\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"dataWidgetId\"></span>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"required field-container-lg field-container\">\n                <label for=\"widgetName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":40},"end":{"line":21,"column":73}}}))
    + "</label>\n                <input class=\"form-control\" name=\"widgetName\" data-bind=\"model\" type=\"text\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"widgetName\"></span>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"required field-container-lg field-container\">\n                <label for=\"widgetDescription\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":47},"end":{"line":30,"column":87}}}))
    + "</label>\n                <input class=\"form-control\" name=\"widgetDescription\" data-bind=\"model\" type=\"text\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"widgetDescription\"></span>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataVersion\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.version",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":41},"end":{"line":37,"column":77}}}))
    + "</label>\n                <input class=\"form-control\" name=\"dataVersion\" data-bind=\"model\" type=\"text\">\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"required field-container-lg field-container\">\n                <label for=\"dataEntitle\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.dataEntitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":41},"end":{"line":45,"column":81}}}))
    + "</label>\n                <select class=\"form-control\" name=\"dataEntitle\" data-bind=\"model\">\n                    <option value=\"false\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.dataEntitle.false",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":42},"end":{"line":47,"column":88}}}))
    + "</option>\n                    <option value=\"true\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.dataEntitle.true",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":41},"end":{"line":48,"column":86}}}))
    + "</option>\n                </select>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<fieldset class=\"section section-container\">\n    <div class=\"section section-container\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.specification",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":43},"end":{"line":56,"column":85}}}))
    + "</div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetType\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.location",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":60,"column":40},"end":{"line":60,"column":77}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":68,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n    <div class=\"row\" data-hook=\"getRemote\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataUrl\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.url",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":37},"end":{"line":75,"column":69}}}))
    + "</label>\n                <input class=\"form-control\" name=\"dataUrl\" data-bind=\"model\" type=\"text\"/>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"trusted\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.trusted",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":37},"end":{"line":81,"column":73}}}))
    + "</label>\n                <select class=\"form-control\" name=\"trusted\" data-bind=\"model\">\n                    <option value=\"false\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.no",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":83,"column":42},"end":{"line":83,"column":73}}}))
    + "</option>\n                    <option value=\"true\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.yes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":41},"end":{"line":84,"column":73}}}))
    + "</option>\n                </select>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\" data-hook=\"getLocal\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"importFile\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.importFile",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":40},"end":{"line":92,"column":79}}}))
    + "</label>\n                <input class=\"form-control\" data-hook=\"getFile\" name=\"fileName\" type=\"file\">\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"externalWidgetType\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":48},"end":{"line":100,"column":81}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"editMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":101,"column":16},"end":{"line":108,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\" data-hook=\"getMfaUrl\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"mfaUrl\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.mfaEndpoint",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":113,"column":36},"end":{"line":113,"column":76}}}))
    + "</label>\n                <input class=\"form-control\" name=\"mfaUrl\" data-bind=\"model\" type=\"text\">\n            </div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\" data-hook=\"getMfaUrl\">\n            <div class=\"field-container-lg field-container\">\n                <div class=\"checkbox-inline\">\n                    <label for=\"iplat\">\n                        <input name=\"iplat\" data-bind=\"model\" type=\"checkbox\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.mfaiplat",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":123,"column":78},"end":{"line":123,"column":115}}}))
    + "\n                    </label>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"row\" data-hook=\"getProxy\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"dataProxify\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.proxyUrl",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":132,"column":41},"end":{"line":132,"column":78}}}))
    + "</label>\n                <input class=\"form-control\" name=\"dataProxify\" data-bind=\"model\" type=\"text\">\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<fieldset class=\"section section-container\" data-hook=\"getFormat\">\n    <div class=\"section-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.format",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":140,"column":32},"end":{"line":140,"column":67}}}))
    + "</div>\n    <div class=\"row\">\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetSize\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.width",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":144,"column":40},"end":{"line":144,"column":74}}}))
    + "</label>\n                <select class=\"form-control external-widget-type\" name=\"widgetSize\" data-bind=\"model\" id=\"external-widget-width\">\n                    <option value=\"S\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.small",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":146,"column":38},"end":{"line":146,"column":72}}}))
    + "</option>\n                    <option value=\"L\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.large",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":147,"column":38},"end":{"line":147,"column":72}}}))
    + "</option>\n                </select>\n            </div>\n        </div>\n        <div class=\"form-column col-md-6 horizontal\">\n            <div class=\"field-container-lg field-container\">\n                <label for=\"widgetDescription\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.height",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":153,"column":47},"end":{"line":153,"column":82}}}))
    + "</label>\n                <input class=\"form-control\" name=\"height\" data-bind=\"model\" type=\"text\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"height\"></span>\n            </div>\n        </div>\n    </div>\n</fieldset>\n\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" data-action=\"save\" class=\"btn btn-primary\" name=\"save\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":164,"column":107},"end":{"line":164,"column":140}}}))
    + "</span></button>\n            <button type=\"button\" data-action=\"cancel\" class=\"btn btn-secondary\" name=\"cancel\"><span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CXP.bankWidget.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":165,"column":113},"end":{"line":165,"column":148}}}))
    + "</span></button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});