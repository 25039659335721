import http from '@glu/core/src/http';
import constants from 'common/dynamicPages/api/constants';
import services from 'services';
import util from '@glu/core/src/util';

const memo = {};

const makeRequest = (url, data) => new Promise((resolve, reject) => http.post(
    url,
    data,
    resolve,
    reject,
));

const makeRequestMemo = (url, data) => {
    const key = `${url}-${JSON.stringify(data)}`;
    if (memo[key]) {
        return memo[key];
    }
    const request = makeRequest(url, data);
    memo[key] = request;
    return request;
};

const getEntitledActions = function (serverJson) {
    const ent = {};
    ent.productCode = serverJson.productCode;
    ent.functionCode = serverJson.functionCode;
    ent.typeCode = serverJson.typeCode;
    ent.actions = {};
    const { actions } = ent;
    util.each(serverJson.actionModes, (action) => {
        let localAction = action;
        localAction = localAction.toUpperCase();
        switch (localAction) {
        case 'INSERT':
        case 'INSDIMD':
        case 'INSDIMF':
        case 'INSDIMN':
            actions.INSERT = true;
            break;
        case 'IMPORT':
            actions.IMPORT = true;
            break;
        case 'MODIFY':
            actions.MODIFY = true;
            actions.MODIFYVFIMPORT = true;
            break;
        case 'DELETE':
            actions.DELETE = true;
            break;
        case 'SUSPEND':
            actions.SUSPEND = true;
            break;
        case 'ACTIVATE':
            actions.ACTIVATE = true;
            break;
        case 'APPROVE':
            actions.APPROVE = true;
            // If this request is around Command & Control don't auto-assign REJECT
            if (ent.productCode !== 'FEATURES') {
                actions.REJECT = true;
            }
            break;
        case 'UNAPPROV':
            actions.UNAPPROVE = true;
            break;
        case 'REJECT':
            actions.REJECT = true;
            break;
        case 'SELECT':
            actions.SELECT = true;
            break;
        case 'SPAYMT':
            actions.SPAYMT = true;
            break;
        case 'GETRATE':
            actions.GETRATE = true;
            break;
        case 'TRADE':
            actions.TRADE = true;
            break;
        case 'PAY':
            actions.PAY = true;
            break;
        case 'PAYANDADJUST':
            actions.PAYANDADJUST = true;
            break;
        case 'PAYISSUE':
            actions.PAYISSUE = true;
            break;
        case 'RETURN':
            actions.RETURN = true;
            break;
        case 'VALIDATE':
            actions.VALIDATE = true;
            break;
        case 'INVALIDA':
            actions.INVALIDATE = true;
            break;
        case 'SIMULATE':
            actions.SIMULATE = true;
            break;
        case 'ASSIGN':
            actions.ASSIGN = true;
            break;
        case 'RESTORE':
            actions.RESTORE = true;
            break;
        case 'RESETPW':
            actions.RESETPW = true;
            break;
        case 'PRINT': // PCM-2923
            actions.PRINT = true;
            break;
        case 'REPRINT': // PCM-2905
            actions.REPRINT = true;
            break;
        default:
        }
    });
    return ent;
};

export default {
    getEntitlements(options) {
        const resolveContextData = function (opt) {
            const contextData = opt.context;
            const data = {};
            data.entryMethod = opt.entryMethod;
            data.typeCode = opt.typeCode;
            data.productCode = opt.productCode;
            data.functionCode = opt.functionCode;

            if (contextData && contextData.actionData) {
                data.entryMethod = contextData.actionData.entryMethod;
            }
            if (contextData && contextData.actionData) {
                data.typeCode = contextData.actionData.typeCode;
            }
            return data;
        };

        const resolveServiceName = function (opt) {
            if (opt.overrideContext) {
                return opt.overrideContext.serviceName;
            }
            return opt.context.serviceName;
        };

        const contextDataServiceName = resolveServiceName(options);
        const data = resolveContextData(options);

        if (options.state && options.state.toUpperCase() === 'SPAYMT') {
            const contextData = options.context;
            if (contextData && contextData.actionData) {
                const actdata = contextData.actionData;
                const argobj = {};
                argobj.actionModes = ['SPAYMT', 'MODIFY'];
                argobj.functionCode = actdata.functionCode;
                argobj.productCode = actdata.productCode;
                argobj.typeCode = actdata.typeCode;
                argobj.subType = '*';
                return Promise.resolve(getEntitledActions(argobj));
            }
            // TODO - missing logic for no actionData condition?
        }

        const getEntitledActionsService = services.generateUrl(contextDataServiceName)
            + constants.URL_GETENTITLEDACTIONS_ACTION;

        return makeRequestMemo(getEntitledActionsService, data)
            .then(getEntitledActions);
    },

    /**
     * Service call that retrieves all of the current company entitlements
     * @param {object} options containing the service name and daa for the request
     * @return {promise}
     */
    getCompanyEntitlements(options) {
        const { serviceName } = options.context;
        const { data } = options;
        const serviceURL = `${services.generateUrl(serviceName)}${constants.URL_GETCOMPANYENTITLMENTS_ACTION}`;

        return makeRequestMemo(serviceURL, data);
    },

    getEntitlementsForContext(options) {
        const resolveContextData = function (opt) {
            const contextData = options.context;
            const data = {};
            if (contextData) {
                data.data = contextData;
            }
            if (opt.entryMethod) {
                data.entryMethod = opt.entryMethod;
            }
            if (contextData && contextData.actionData) {
                data.entryMethod = contextData.actionData.entryMethod;
            }
            return data;
        };

        const resolveServiceName = function (opt) {
            if (opt.overrideContext) {
                return opt.overrideContext.serviceName;
            }
            return opt.context.serviceName;
        };

        const contextDataServiceName = resolveServiceName(options);
        const data = resolveContextData(options);

        const getEntitledActionsService = services.generateUrl(contextDataServiceName)
            + constants.URL_GETENTITLEMENTSFORCONTEXT_ACTION;

        return makeRequestMemo(getEntitledActionsService, data).then(getEntitledActions);
    },

    getActionRestrictions(options) {
        const resolveContextData = function (opt) {
            const data = {};
            data.action = {
                actionMode: opt.actionMode,
                productCode: opt.productCode,
                functionCode: opt.functionCode,
                typeCode: opt.typeCode,
                entryMethod: opt.entryMethod,
            };
            data.restrictions = opt.restrictions;
            return data;
        };

        const resolveServiceName = function (opt) {
            if (opt.overrideContext) {
                return opt.overrideContext.serviceName;
            }
            return opt.context.serviceName;
        };

        const contextDataServiceName = resolveServiceName(options);
        const data = resolveContextData(options);

        const getActionRestrictionsService = services.generateUrl(contextDataServiceName)
            + constants.URL_GETACTIONRESTRICTIONS_ACTION;

        return makeRequestMemo(getActionRestrictionsService, data);
    },
};
