/**
 * Automatically attaches itself as a direct child of the body.
 * This allows for easy injection of floating views such as modals
 * or growl messages that do not rely on a specific container to render
 */
import Layout from '../../layout';
import $ from '../../$';

export default Layout.extend({
  className: 'glu-layer',
  template() {
    return '';
  },

  setTop(num) {
    this.$el.css('top', `${num}px`);
  },

  height() {
    return this.$el.height();
  },

  position() {
    return this.$el.position();
  },

  setElement(element, delegate) {
    /* eslint "backbone/no-native-jquery": 0 */
    Layout.prototype.setElement.call(this, element, delegate);
    $('body').prepend(this.$el);
  }
});

