var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"form-group\">\n        <label for=\"emailId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":35}}}) : helper)))
    + "\" class=\"required\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"passcode.emailmessage.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":54},"end":{"line":3,"column":94}}}))
    + "</label>\n        <input id=\"emailId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":34}}}) : helper)))
    + "\" type=\"email\" name=\"EMAILADDRESS\" class=\"email form-control\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"EMAILADDRESS\"></span>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"form-group\">\n        <label id=\"fax_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":30}}}) : helper)))
    + "\"  class=\"required\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"CM.FaxNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":50},"end":{"line":11,"column":75}}}))
    + "</label>\n        <input id=\"fax_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":12,"column":23},"end":{"line":12,"column":30}}}) : helper)))
    + "\"  type=\"text\" name=\"FAXNUMBER\" class=\"phone form-control\" data-bind=\"model\">\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"FAXNUMBER\"></span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isEmailMethod") || (depth0 != null ? lookupProperty(depth0,"isEmailMethod") : depth0)) != null ? helper : alias2),(options={"name":"isEmailMethod","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":18}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isEmailMethod")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isFaxMethod") || (depth0 != null ? lookupProperty(depth0,"isFaxMethod") : depth0)) != null ? helper : alias2),(options={"name":"isFaxMethod","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":15,"column":16}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isFaxMethod")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<hr/>\n<div class=\"section section-container\">\n    <div>\n        <button class=\"btn btn-primary submit\" data-action=\"submit\">"
    + alias5(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":68},"end":{"line":19,"column":94}}}))
    + "</button>\n        <button class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias5(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":63},"end":{"line":20,"column":89}}}))
    + "</button>\n    </div>\n</div>";
},"useData":true});