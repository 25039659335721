import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    initialize() {
        this.validators = {
            NOTE_TEXT: {
                description: locale.get('reports.transaction.note'),
                exists: true,
            },
        };
    },

    defaults: {
        NOTE_TEXT: '',
    },

    sync(method, model, options) {
        let url;
        let data;

        if (method === 'create') {
            // TODO: Update with proper service url
            url = services.generateUrl('tableMaintenance/lockboxNotes/add');

            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'read') {
            data = this.convertModelAttributesToServerJSON(model);
            // TODO: Update with proper service url
            url = services.generateUrl('tableMaintenance/lockboxNotes/getListView');

            http.post(url, {}, (result) => {
                const notes = [];
                util.each(result.rows, (row) => {
                    const note = {};
                    for (let x = 0; x < row.columns.length; x += 1) {
                        note[row.columns[x].fieldName] = row.columns[x].fieldValue;
                    }
                    notes.push(note);
                });
                options.success(notes);
            }, httpError.getResult(options, model));
        }

        if (method === 'update') {
            // TODO: Update with proper service url
            url = services.generateUrl('tableMaintenance/lockboxNotes/update');

            data = this.convertModelAttributesToServerJSON(model);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            data = this.convertModelAttributesToServerJSON(model);
            // TODO: Update with proper service url
            url = services.generateUrl('tableMaintenance/lockboxNotes/delete');

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    convertModelAttributesToServerJSON(model) {
        const jsonData = [];

        util.each(model.toJSON(), (value, key) => {
            jsonData.push({
                name: key,
                value,
            });
        });

        return {
            item: jsonData,
        };
    },
});
