var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"text-group\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":40}}}) : helper)))
    + "\">\n        <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":29}}}) : helper)))
    + "</label>\n        <p class=\"form-control-static\">\n            <span name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":5,"column":24},"end":{"line":5,"column":32}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"formattedValue") || (depth0 != null ? lookupProperty(depth0,"formattedValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formattedValue","hash":{},"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":52}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"currencyLabel") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"currency-field\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"currencyLabel") || (depth0 != null ? lookupProperty(depth0,"currencyLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"currencyLabel","hash":{},"data":data,"loc":{"start":{"line":7,"column":45},"end":{"line":7,"column":62}}}) : helper)))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"required\">\n        <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":28}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":13,"column":29},"end":{"line":13,"column":36}}}) : helper)))
    + "\" class=\"amount-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":13,"column":59},"end":{"line":13,"column":73}}}) : helper)))
    + "</label>\n        <div class=\"form-group\">\n            <div class=\"input-group\">\n                <input class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cssClass") || (depth0 != null ? lookupProperty(depth0,"cssClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssClass","hash":{},"data":data,"loc":{"start":{"line":16,"column":30},"end":{"line":16,"column":42}}}) : helper)))
    + "\" type=\"text\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxLen") || (depth0 != null ? lookupProperty(depth0,"maxLen") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxLen","hash":{},"data":data,"loc":{"start":{"line":16,"column":67},"end":{"line":16,"column":77}}}) : helper)))
    + "\" \n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"originalMaxLen") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":82}}})) != null ? stack1 : "")
    + " \n                data-type=\"amount\" data-bind=\"model\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":18,"column":59},"end":{"line":18,"column":67}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":18,"column":81},"end":{"line":18,"column":95}}}) : helper)))
    + "\" \n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"protected") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":50}}})) != null ? stack1 : "")
    + " \n                value=\"\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":20,"column":29},"end":{"line":20,"column":37}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":20,"column":38},"end":{"line":20,"column":45}}}) : helper)))
    + "\" \n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeHolder") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":73}}})) != null ? stack1 : "")
    + " >\n\n                <div class=\"input-group-addon\">\n                    <input class=\"currency-field\" readonly data-bind=\"model\" tabIndex=\"-1\" \n                    name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"relatedProperty") || (depth0 != null ? lookupProperty(depth0,"relatedProperty") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"relatedProperty","hash":{},"data":data,"loc":{"start":{"line":25,"column":26},"end":{"line":25,"column":45}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"currencyLabel") || (depth0 != null ? lookupProperty(depth0,"currencyLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencyLabel","hash":{},"data":data,"loc":{"start":{"line":25,"column":59},"end":{"line":25,"column":76}}}) : helper)))
    + "\" \n                    id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"relatedProperty") || (depth0 != null ? lookupProperty(depth0,"relatedProperty") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"relatedProperty","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":43}}}) : helper)))
    + "\" />\n                </div>\n            </div>\n        </div>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":30,"column":66},"end":{"line":30,"column":74}}}) : helper)))
    + "\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"protected") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-maxlength=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"originalMaxLen") || (depth0 != null ? lookupProperty(depth0,"originalMaxLen") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"originalMaxLen","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":73}}}) : helper)))
    + "\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return " readonly ";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"placeHolder") || (depth0 != null ? lookupProperty(depth0,"placeHolder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"placeHolder","hash":{},"data":data,"loc":{"start":{"line":21,"column":49},"end":{"line":21,"column":64}}}) : helper)))
    + "\" ";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.mustPayThisAmount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":33,"column":50}}}))
    + "\n            </span>\n            <button tabindex=\"0\" class=\"btn btn-popover toolTip-btn-popover\" data-toggle=\"popover\" role=\"button\" data-placement=\"auto right\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTP.noAmountChangeMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":155},"end":{"line":35,"column":193}}}))
    + "\">\n                <span class=\"textBoxError-icon icon-info-circle\"></span>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"viewMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":40,"column":7}}})) != null ? stack1 : "");
},"useData":true});