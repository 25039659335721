import CollectionView from '@glu/core/src/collectionView';
import PaymentTypeView from './paymentType';

export default CollectionView.extend({
    tagName: 'div',
    itemView: PaymentTypeView,

    initialize(options) {
        this.mode = options.mode;
        this.paymentTypeGroup = options.paymentTypeGroup;
        this.modifyMode = this.options.modifyMode;
        this.mainModel = this.options.mainModel;
        this.limitsModel = this.options.limitsModel;
        this.isCannotGrantBeyondOwnUser = this.options.isCannotGrantBeyondOwnUser;
    },

    itemViewOptions() {
        return {
            paymentTypeGroup: this.paymentTypeGroup,
            mode: this.mode,
            limitsModel: this.limitsModel,
            modifyMode: this.modifyMode,
            mainModel: this.mainModel,
            isCannotGrantBeyondOwnUser: this.isCannotGrantBeyondOwnUser,
        };
    },
});
