import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import dateRangeUtil from 'app/balanceAndTransaction/api/dateRangeUtil';
import moment from 'moment';
import $ from 'jquery';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import constants from 'app/balanceAndTransaction/constants';
import GridApi from 'common/dynamicPages/api/grid';
import TransactionSearchFilterModel from 'app/balanceAndTransaction/models/transSearchAccountFilter';
import AccountDetailsView from 'app/balanceAndTransaction/views/accountDetails';
import TransactionListView from 'app/balanceAndTransaction/views/transactionListView';
import AdditonalInfoCellView from 'app/balanceAndTransaction/views/additionalInfoCellView';
import printExportUtil from 'common/util/printExportUtil';
import GridExportBTRDataView from 'app/balanceAndTransaction/views/exportBTRData/gridExportBTRDataView';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import btrImageUtil from 'app/balanceAndTransaction/btrImageUtil';
import searchFieldsUtil from 'common/util/searchFieldsUtil';
import PrintTransView from 'app/balanceAndTransaction/views/printTransView';
import ShortenedCell from 'common/dynamicPages/views/gridCells/shortenedCell';
import gridUtil from 'app/utilities/views/gridUtil';
import TransactionSearchFilterView from './transactionSearchFilter';
import template from './transactionSearch.hbs';

const GET_TRANSACTIONS_SERVICE_NAME = 'balanceAndTransaction/depositAccounts/getSearchTransactions';

export default Layout.extend(util.extend(
    {},
    printExportUtil,
    {
        template,

        ui: {
            $accountLoader: '[data-hook="accounts-loader"]',
            $btnWrapper: '[data-hook="getBtnWrapper"]',
        },

        events: {
            'click [data-hook="export-button"]': 'export',
            'click [data-hook="print-button"]': 'print',
            'click button[data-hook="show-transactions"]': 'showTransactions',
        },

        regions: {
            accounts: '[data-region="combined-accounts"]',
            filter: '[data-region="filter"]',
        },

        initialize() {
            this.filterModel = new TransactionSearchFilterModel();
            this.filterView = new TransactionSearchFilterView({
                model: this.filterModel,
            });
            this.listenTo(this.filterModel, 'update', this.filterUpdate);
        },

        /**
         * This needs to be overridden in child class to define the deposit context
         *  @abstract
         */
        getDepositContext() {
            return {};
        },

        /**
         * This needs to be overridden in child class to define the loan context
         *  @abstract
         */
        getLoanContext() {
            return {};
        },

        /**
         * @method showGridSuccessMessages
         * @param {object} response -- grid request respHeader
         * On grid request success, parses the message and shows a success or warning
         * alert message.
         */
        showGridResponseMessages(response) {
            const message = this.gridView.wrapper.getRespHeader('message');

            if (message && message.length > 1) {
                const { errorCode } = response;
                const status = (errorCode === 0) ? 'success' : 'warning';

                /*
                 * skip the default "Success" message returned by the service
                 * and join all others onto newline
                 */
                const gridMsg = message.splice(1).join('\n');

                this.showAlertMessage(gridMsg, status);
            }
        },

        /**
         * @method showAlertMessage
         * @param {string} message
         * @param {string} type - glu alert types associated to an alert creation
         * method (success, warning, danger, etc)
         * @param {object} props - object containing extra properties for glu alert
         * construction
         * Shows an alert message in the ListView. The status passed in is associated
         * to the type of
         * alert that is shown.
         */
        showAlertMessage(message, type, props) {
            let localType = type;
            let localProps = props;
            localType = alert[localType] ? localType : 'success';
            localProps = localProps || {
                canDismiss: true,
            };

            // create alert message using associated alert status type
            this.alertView = alert[localType](message, localProps);
            this.gridAlertRegion.show(this.alertView);
        },

        filterUpdate() {
            // Only when the filter update completes should we start loading

            this.ui.$accountLoader.show();
            this.ui.$btnWrapper.removeClass('hide').prop('aria-hidden', false);

            // hide previous alert message
            this.hideAlertMessage();

            // Only if it has rendered already
            if (this.accounts && this.accounts.$el) {
                this.accounts.$el.fadeOut('fast');
            }

            this.loadTranSearchListView();
        },

        onRender() {
            this.filter.show(this.filterView);
        },

        /**
         * Close alert region when there is no alert message
         */
        hideAlertMessage() {
            this.gridAlertRegion.close();
        },

        showTransactions(e) {
            const $target = this.$(e.currentTarget);
            const accountModel = $target.data('model');
            const clonedFilterModel = util.clone(this.filterModel);
            let startDate = this.filterModel.get('START_DATE');
            let endDate = this.filterModel.get('END_DATE');

            if (endDate.length < 1) {
                startDate = dateRangeUtil.convertCodesToDates(startDate);
                endDate = moment(new Date()).format(userInfo.getDateFormat());
            }

            clonedFilterModel.set('START_DATE', startDate);
            clonedFilterModel.set('END_DATE', endDate);

            this.stack.push(new AccountDetailsView({
                model: accountModel,
                filterModel: clonedFilterModel,
                filterView: this.filterView,
            }));
        },

        getTypeAheadData() {
            const self = this;
            const selector = '.type-ahead';
            const typeAheadFields = {};
            let comboData;
            const reportType = self.filterModel.get('accountSelectBy');
            const reportTypeField = reportType === 'number' ? 'ACCOUNTFILTER' : 'ACCOUNTFILTER2';

            // collect the data on each typeAhead field
            self.filterView.$el.find(selector).each(function () {
                let attrName = $(this).attr('name');
                comboData = $(this).comboBox('data');

                util.each(comboData, (valueParam) => {
                    const value = valueParam;
                    delete value.mapDataList;
                });

                // convert SWIFT_CODE to SWIFT_TYPE_CODE for the modal
                attrName = (attrName === 'SWIFT_CODE') ? 'SWIFT_TYPE_CODE' : attrName;
                typeAheadFields[attrName] = comboData;
            });

            comboData = this.$('#accountSelect').comboBox('data');

            if (comboData[0].id !== 'all') {
                typeAheadFields[reportTypeField] = comboData;
            }

            return typeAheadFields;
        },

        getTypeAheadFields(modelField, modelList) {
            const self = this;
            const typeAheadFields = [];

            if (self.filterModel.get(modelField)) {
                util.each(self.filterModel.get(modelField).split(','), (value) => {
                    const pair = util.findWhere(
                        self.filterModel.get(modelList),
                        {
                            id: value.trim(),
                        },
                    );
                    if (pair) {
                        typeAheadFields.push({
                            id: pair.id,
                            text: ((modelField === 'BANK_CODE' || modelField === 'CURRENCY_CODE') ? `${pair.id} - ` : '') + pair.text,
                        });
                    }
                });
            }
            return typeAheadFields;
        },

        loadTranSearchListView() {
            const context = (this.filterModel.get('accountType') === 'DEPOSIT') ? this.getDepositContext() : this.getLoanContext();

            const options = {
                prebuiltOptions: true,
                context,
                hideGridActionButtons: true,
                enableRowContextButtonCallbacks: true,
                enableSavedViews: true,
                // NH-178965 HACK show saved view without filters
                savedViewsWithoutFilters: true,
                skipEntitlements: true,
                serviceName: GET_TRANSACTIONS_SERVICE_NAME,
                selector: 'rowSelector',
                hasRowSelector: true,
                additionalSearchFields: this.options.additionalSearchFields || [{
                    fieldName: 'AccountFilter',
                    fieldValue: this.getSelectedAccounts(),
                    dataType: 'text',
                    operator: 'IN',
                }, {
                    fieldName: 'Post_Date',

                    fieldValue: [
                        this.filterModel.get('START_DATE'),
                        this.filterModel.get('END_DATE'),
                    ],

                    dataType: 'date',
                    operator: 'BETWEEN',
                }],

                cellViews: {
                    ADDITIONAL_INFO: AdditonalInfoCellView,
                    PAYMENT_DETAIL: ShortenedCell,
                    PAYMENT_DETAIL_ORIG: ShortenedCell,
                },

                requestParameters: {
                    item: [{
                        name: 'inquiryId',
                        value: context.inquiryId,
                    }, {
                        name: 'productCode',
                        value: context.productCode,
                    }, {
                        name: 'functionCode',
                        value: context.functionCode,
                    }, {
                        name: 'typeCode',
                        value: context.typeCode,
                    }],
                },
            };

            // add the transaction filters to searchFields.
            const tranFilters = this.filterView.getTransactionFilters();
            util.each(tranFilters, (filter) => {
                if (!this.filterView.rejectSearchField(filter)) {
                    options.additionalSearchFields.push(filter);
                }
            });
            this.gridView = this.createGridView(options);

            if (this.transactionsGrid) {
                this.transactionsGrid.show(this.gridView);
                this.ui.$btnWrapper.show();
                this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            }

            const nonSortableColumns = constants.TRANSACTIONS_GRID_NON_SORTABLE_COLUMNS;
            this.listenTo(this.gridView, 'gridapi:loaded', this.updateAsOfDate);
            this.listenTo(this.gridView, 'gridapi:loaded', gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
            this.appBus.on(`gridapi:showMessages${this.gridView.cid}`, gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
            this.listenTo(this.appBus, (`gridapi:showMessages${this.gridView.cid}`), this.showGridResponseMessages);
        },

        getSelectedAccounts() {
            const isAccountGroups = this.filterModel.get('accountSelectBy') === 'group';
            const accountsSelected = this.filterModel.get('accountSelect');

            if (!isAccountGroups) {
                return accountsSelected.indexOf('all') !== -1 ? [] : accountsSelected;
            }
            const accountList = this.filterModel.get('accountType') === 'LOAN'
                ? 'loanAccountGroupAccountList' : 'accountGroupAccountList';
            return util.chain(accountsSelected)
                .map(accountGroup => this.filterModel.get(accountList)[accountGroup])
                .flatten()
                .value();
        },

        /**
         * @method getSuppressDetailedExportGIR
         * @return {boolean}
         * Gets the value of the suppressDetailedExportGIR flag from the server
         */
        getSuppressDetailedExportGIR() {
            return serverConfigParams.get('suppressDetailedExportGIR')
                && (serverConfigParams.get('suppressDetailedExportGIR').toUpperCase() === 'TRUE');
        },

        /**
         * @method getSuppressDetailedPrintGIR
         * @return {boolean}
         * Gets the value of the suppressDetailedPrintGIR flag from the server
         */
        getSuppressDetailedPrintGIR() {
            return serverConfigParams.get('suppressDetailedPrintGIR')
                && (serverConfigParams.get('suppressDetailedPrintGIR').toUpperCase() === 'TRUE');
        },

        /**
         * Get print options for this list
         * @param {string} [format] - the output format.  Typically PDF or CSV
         * @returns {Object}
         */
        getPrintOptions(format) {
            const context = this.getContext();
            const selectedRows = this.gridView.grid.getSelectedRows();
            return {
                view: this,
                gridView: this.gridView,
                inquiryId: context.inquiryId,
                format: format || 'PDF',
                hasSummarySelection: !this.getSuppressDetailedPrintGIR(),
                exportURL: context.exportPrintURL,
                selection: selectedRows?.length > 0 ? 'sel' : 'all',
            };
        },

        fixExportColumns(columns) {
            const newColumns = [...columns];
            if (columns.indexOf('DISPLAYPAYMENT_DETAIL') > -1) {
                newColumns[columns.indexOf('DISPLAYPAYMENT_DETAIL')] = 'PAYMENT_DETAIL';
            }

            return newColumns.filter(column => (column !== 'ADDITIONAL_INFO') && (column !== 'MESSAGE_STATE'));
        },

        /**
         * Extending original buildPrintModel method used by
         * PrintExportUtil (extended in ListView)
         * Will edit the model created to use full details columns
         */
        buildPrintModel(model, gridView, inquiryId) {
            return TransactionListView.prototype.buildPrintModel
                .call(this, model, gridView, inquiryId);
        },

        print() {
            this.printExport('PDF', 'print');
        },

        export() {
            this.printExport('CSV');
        },

        getContext() {
            return this.filterModel.get('accountType') === 'DEPOSIT' ? this.getDepositContext() : this.getLoanContext();
        },

        printExport(format, action) {
            const options = this.getPrintOptions(format);
            if (action === 'print') {
                this.handlePrintByConfigParam(this.getSuppressDetailedPrintGIR(), options);
            } else {
                this.showExportBTRView(options);
            }
        },

        gatherFilters(options) {
            // gather up the filters
            const searchFields = options.gridView.wrapper.generateFiltersDataBlock();
            return searchFieldsUtil.convertSearchFieldsToFilterFormat(searchFields);
        },

        handlePrintByConfigParam(suppressDetails, options) {
            if (suppressDetails) {
                this.showPrintOptionsModal('PDF');
            } else {
                // if DETAILED REPORTS is allowed, will only apply the detailed print option
                this.PrintTransView = new PrintTransView(options);
                this.listenTo(this.PrintTransView, 'printTransaction', this.printTransaction);
                dialog.open(this.PrintTransView);
            }
        },

        /**
         * Generates a Transaction List print document
         *
         * @param {object} options - an options object
         */
        printTransaction(options) {
            TransactionListView.prototype.printTransaction.call(this, options);
        },

        /**
         * @method getListViewId
         * @return {string}
         */
        getListViewId() {
            return TransactionListView.prototype.getListViewId.call(this);
        },

        /**
         * @param {object} options
         * SHow an export data modal that will allow the user to perform a standard
         * grid export,
         * or choose other export/download options
         */
        showExportBTRView(options) {
            const { filterModel } = this;
            const initialFilterValues = this.filterView.getTransactionFilters();

            this.exportDataView = new GridExportBTRDataView({
                filterModel,
                initialFilterValues,
                typeAheadData: this.getTypeAheadData(this.filterRegion),
                standardExportOnly: true,
                includeDetails: !this.getSuppressDetailedExportGIR(),
                gridExportOptions: options,
                gridExportCallback: this.exportTransaction.bind(this),
                gridView: this.gridView,
            });

            const self = this;

            this.listenTo(this.exportDataView, 'doExport', () => {
                // Display link to download screen
                const successMessage = locale.get('GIR.output.success').replace('#', ' ');
                const docRoot = window.Bottomline.documentRoot
                    ? `/${window.Bottomline.documentRoot.replace(/^\/|\/$/g, '')}` : '';
                const $message = $('<a/>').attr('href', `${docRoot}/${window.Bottomline.appRoot}/REPORTING/btrExport`).text(locale.get('GIR.download.view'));

                // Initial message does not have the link because it cannot contain markup.
                const alertView = alert.success(successMessage);

                // Find the alert in and update it.
                alertView.on('show', () => {
                    self.$el.find('.alert p').text(successMessage).append($message);
                });

                self.alertRegion.show(alertView);
                $('html, body').animate({
                    scrollTop: 0,
                }, 100);
            });

            this.listenTo(this.exportDataView, 'doExportFailed', () => {
                const alertView = alert.danger(locale.get('payment.export.error.message'));

                self.alertRegion.show(alertView);
                $('html, body').animate({
                    scrollTop: 0,
                }, 100);
            });

            dialog.open(this.exportDataView);
        },

        /**
         * Generates a Transaction List document for export.
         *
         * @param {object} optionsParam - an options object
         */
        exportTransaction(optionsParam) {
            const options = optionsParam;
            options.view = this;

            const exportModel = printExportUtil.buildExportModel(options);

            if (options.includeDetails) {
                const extraSearchFields = [{
                    fieldName: 'includeDetalis',
                    operator: '=',
                    dataType: 'text',
                    fieldValue: ['true'],
                }];

                if (exportModel.searchFields) {
                    exportModel.searchFields = exportModel.searchFields.concat(extraSearchFields);
                } else {
                    exportModel.searchFields = extraSearchFields;
                }
            }

            options.exportModel = exportModel;
            printExportUtil.doExport(options);
        },

        /**
         * Defines what occurs when a row action is selected for a specific row
         *
         * @param {object} options - the row options
         */
        gridRowAction(options) {
            const action = options.action.toUpperCase();

            if (action === 'IMAGE') {
                if (this.options.stack) {
                    this.stack = this.options.stack;
                }
                btrImageUtil.getImage(options, this);
            }
            return Promise.resolve();
        },

        // overridablehook for unit tests
        createGridView(options) {
            return GridApi.createServiceGridView(options);
        },

        templateHelpers() {
            return {
                gridUtilityOptions: {
                    hasRefresh: false,
                },
            };
        },
    },
));
