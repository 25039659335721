import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import TemplateHelpers from 'common/dynamicPages/views/mdf/templateHelpers';
import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import comboboxHelper from 'common/dynamicPages/views/mdf/componentHelpers/combobox';
import constants from 'common/dynamicPages/api/constants';
// import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import PaymentUtil from 'common/util/paymentUtil';
import Model from '@glu/core/src/model';

// PCM-4845
import store from 'system/utilities/cache';
import { navigateTo } from 'common/util/navigationUtils';
// PCM-4845
import beneficiariesWidgetTmpl from './beneficiariesWidget.hbs';

const checkRoutes = {
    addRoute: '/PAYMENTS/addCheck',
    viewRoute: '/PAYMENTS/viewCheck',
    editRoute: '/PAYMENTS/editCheck',
};

export default BaseWidget.extend({
    template: beneficiariesWidgetTmpl,

    // TODO: unused by MDF implementation.
    className: 'ui-widget field-container uiWidget-Beneficiaries',

    ui: {
        $header: '.section-header',
        $addForm: '.Beneficiary-addForm',

        // TODO: Not implemented yet
        $modForm: '.Beneficiary-modifyForm',

        $grid: '.Beneficiary-grid',
        $addBtn: '[name="ADDBENE"]',
        $clearBtn: '[name="CLEARBENE"]',
        $addAddendaBtn: '[name="ADDADDENDA"]',
        $deleteChildBtn: '[name="deleteChild"]',
        $holdAllBtn: '[name="holdAllChild"]',
        $amount: '[name="AMOUNT"]',
        $currencyCode: '#DESTCURRENCYCODE',
        $prenoteFlag: '#PRENOTEFLAG',
        $reverseReason: '#REVERSEREASON',
    },

    events: {
        'click @ui.$addBtn': 'saveChild',
        'click @ui.$clearBtn': 'clearBeneficiaryForm',
        'click @ui.$addAddendaBtn': 'saveGrandChild',
        'click #batch-child-edit-toggle': 'toggleEditMode',
        'click @ui.$deleteChildBtn': 'deleteChild',
        'click @ui.$holdAllBtn': 'holdAll',
        'change @ui.$amount': 'changeAmount',
        'click @ui.$prenoteFlag': 'handlePrenoteCheck',
    },

    behaviors: {
        ValidationSupplement: {},
        LookupHelperText: {},
    },

    initialize(options) {
        this.isActive = false;
        this.viewId = util.uniqueId();
        this.setStartingViewState(options);
        // PCM-846
        this.context = options.context;
        this.productCode = this.determineProductCode();
        // PCM-846

        // Use debounce because this could get called more than once?
        this.processForMDFChild = util.debounce(() => {
            this.parentView.childView.setupFormComponents(this.$el);
        }, 100);

        this.setupModelListeners();

        window.bene = this;
    },

    // PCM-846
    determineProductCode() {
        if (this.context) {
            const { actionData, productCode } = this.context;
            return actionData ? actionData.productCode : productCode;
        }
        return '*';
    },
    // PCM-846

    /** Make sure when account type is changed that currency is set */
    setupModelListeners() {
        if (this.model && this.parentModel) {
            this.model.on('change:ACCOUNTTYPE', () => {
                const currentCurrencyCode = this.parentModel.get('DESTCURRENCYCODE');
                this.model.set('DESTCURRENCYCODE', currentCurrencyCode);
                this.ui.$currencyCode.val(currentCurrencyCode);
            });
        }
    },

    /** Make sure when prenote is checked that AMOUNT is readonly and not required */
    handlePrenoteCheck(e) {
        const validatorDesc = this.model.validators.AMOUNT
            && this.model.validators.AMOUNT.description;

        if (e.currentTarget.checked) {
            this.shouldBeReadOnly('AMOUNT', true);
            this.model.removeValidator('AMOUNT');
            this.model.set('AMOUNT', '');
            this.ui.$amount.val('');
        } else {
            this.shouldBeReadOnly('AMOUNT', false);
            this.model.addValidator(
                'AMOUNT',
                {
                    exists: true,
                    description: validatorDesc,
                },
            );
            this.model.set({
                AMOUNT: 0,
                PRENOTEFLAG: 0,
            });
            this.ui.$amount.val(0);
        }
    },

    addBlock(block, view) {
        this.isActive = true;

        /*
         * FIXME: This is dumb code; should we replace the bene block each time, or
         * use something smarter?
         */
        if (!view.options.isModal) {
            // Add the individual blocks.
            this.blocks.push(block);
        }

        // TODO: Do we need any special block sorting?
        this.groups = this.groups.concat(block.groups);

        // Hacky update for the conditions object
        this.blockHasGrid = this.helpers.checkGroupForAttributes({ groups: this.groups }, 'fieldUIType', ['CHILDGRID']);

        if (view.options.isChild) {
            // FIXME: Use child model for my content... makes it
            this.model = view.model;
            this.childView = view;
            this.setupModelListeners();
        }
    },

    /**
     * Filters through all groups and returns the group with either fieldNames or
     * fieldUITypes that match
     * the given search terms. Currently used to identify the grid, bene, and
     * secondary account groups
     * @param searchAttribute {string} - the attribute to be checked for the
     * provided search terms
     * @param searchTerms {array} - a list of matching field names and uiTypes in
     * the requested group
     * @return {object} - the matched group
     */
    getGroupByAttribute(searchAttribute, searchTerms) {
        return this.groups.filter(group => group.fields
            && group.fields.some(field => searchTerms.includes(field[searchAttribute])))[0];
    },

    /**
     * Provide a mapping for the MDF groups so we can call them from our template.
     * @param groups
     * @return {{beneRequired: *, beneOptional: *}}
     */
    mapGroups(groups) {
        /*
         * FIXME: Assumes our custom arrangements, right now, which will need additional logic
         * to confirm
         */
        return {
            beneRequired: groups[0],

            // TODO: Other field sets
            beneOptional: groups[1],
        };
    },

    /**
     * Creates some initial view state
     */
    setStartingViewState(options) {
        this.userOpenedAddBeneficiary = false;
        this.userClickedAddAnotherBeneficiary = false;
        this.userOpenedOptionalFields = false;
        this.parentView = options.view;

        if (this.parentView) {
            this.helpers = TemplateHelpers.helpers(this.parentView);
        }

        this.parentModel = options.model;

        this.blocks = [];
        this.groups = []; // options.block.groups;

        // Keep a separate model for the Entry form, as it isn't part of the actual submit data
        this.entryModel = new Model();

        // Now that we are instantiated, we need to keep track of what we have.
        this.hasGrid = false;
        this.hasBeneficiary = false;
    },

    /**
     * @return {number}
     */
    getBeneficiaryCount() {
        // TODO: Get beneficiary collection count
        if (this.gridWrapper) {
            return this.rows.totalCount;
        }

        return 0;
    },

    // PCM-1220
    getAddBeneficiaryLocale() {
        return this.productCode !== constants.CHECK_PRODUCT_CODE ? 'common.beneficiary.new' : 'common.payee.new';
    },
    // PCM-1220

    /**
     * If we are in view mode
     * @return {boolean}
     */
    isViewMode() {
        // FIXME: One of the *other* constants files has the MODES
        return this.state === 'view'; // constants.MODES.VIEW;
    },

    isModifyMode() {
        /*
         * FIXME: One of the *other* constants files has the MODES
         * Use ParentView because we *might* be lied to?
         */
        return this.parentView.state === 'modify'; // constants.MODES.MODIFY;
    },

    /**
     * Is this loaded from a Copy or Import
     * @return {boolean}
     */
    isFromCopy() {
        /*
         * NH-45817,45818
         * Adding check to see if the payment/template is from copy as.
         */
        return this.options.context.createdFrom === '1';
    },

    /**
     * Hide Bene Add link for non freeform
     * @return {boolean}
     */
    hideBeneAdd() {
        return this.showEntry() || this.isFromTemplateToPayment();
    },

    /**
     * @return {boolean}
     */
    doesOptionalSetContainRequiredField() {
        // TODO: Loop over optional field group to see what we need.
    },

    /**
     * @return {boolean}
     */
    shouldShowGrid() {
        return this.userClickedAddAnotherBeneficiary || this.getBeneficiaryCount() > 1;
    },

    /**
     * @return {boolean}
     */
    shouldShowOptionalFields() {
        return this.userOpenedOptionalFields || this.doesOptionalSetContainRequiredField();
    },

    showEntryForm(fromUser) {
        if (fromUser) {
            this.userOpenedAddBeneficiary = true;
        }

        this.ui.$addForm.show();
        // TODO: More conditions
    },

    showEditForm() {
        this.ui.$modForm.show();
    },

    /**
     *
     * @param {jQuery} $btn
     * @param {Boolean} enabled
     */
    setElementEnabled($btn, enabled) {
        $btn.prop('disabled', !enabled);
    },

    /**
     * Called from template
     */
    openAddBeneficiary() {
        this.userOpenedAddBeneficiary = true;
        this.$('.Beneficiary-addForm').removeClass('hidden');
        this.$('.Beneficiary-showAdd').addClass('hidden');
        this.setFocusToBeneficiary();
        this.model.trigger('beneWidget:defaults');
    },

    reattachViewForMDF() {
        /*
         * FIXME: Attaching to an element **inside** our tempalte right now. This
         * needs correction.
         */
        this.setElement(this.parentView.$(`[data-hook="bene${this.viewId}"]`).parent()[0]);

        if (this.parentView.batchChildGridView) {
            util.defer(() => {
                this.childGridRegion.show(this.parentView.batchChildGridView);
            });
        }
    },

    /**
     * Set focus to start of beneficiary form. Used to "Add Another".
     */
    setFocusToBeneficiary() {
        this.$('.Beneficiary-addForm').find('.form-control').eq(0).focus();
    },

    wrapHTMLForMDF() {
        return this.$el[0].outerHTML;
    },

    saveGrandChild() {
        if (this.childView.isGrandChildDirty()) {
            this.childView.saveGrandChild();
        }
    },

    /**
     * Removes helper text after successful save (clearing the input form).
     */
    clearHelperText() {
        const self = this;

        /*
         * search for TYPEAHEAD helper text UI controls in order to clear their text
         * box helper text
         */
        this.$el.find('div.helper-text-container').each(function () {
            self.childView.trigger('lookupHelperText:update', this.nextElementSibling.id);
        });
    },

    /**
     * Wipe the beneficiary Form
     */
    clearBeneficiaryForm(...args) {
        let recalculateTotal;
        /*
         * NH-47905 determine whether the totals needs to be recalculated if the
         * clear bene button is used
         */
        if (this.model.get('AMOUNT') && args.length > 0) {
            recalculateTotal = true;
        }

        const lockedFields = this.model.get('LOCKED_FIELDS');
        // Empty the model without removing the keys themselves
        this.model.set(this.model.keys().reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {}));
        // repopulate the lockedFields to model
        this.model.set('LOCKED_FIELDS', lockedFields);

        this.clearHelperText();
        comboboxHelper.checkForSelectIfOnlyOne(this);

        if (this.focusChild) {
            this.focusChild = false;
            this.setFocusToBeneficiary();
        }

        if (recalculateTotal) {
            this.appBus.trigger('updatePaymentSummary');
        }

        // clear old validation state on ui
        this.$('.has-error').removeClass('has-error').find('.help-block').text('');

        this.model.trigger('beneWidget:defaults');
        this.parentView.trigger('clearBeneficiaryForm');
    },

    /**
     * Modified version of the MDF saveChild logic to the beneficiary widget.
     */
    saveChild() {
        const self = this;

        // Disable Add button right away.
        self.setElementEnabled(self.$('[name="ADDBENE"]'), false);
        self.model.set('BATCHSEQNUM', this.batchSeqNum);

        const saveFunc = function () {
            return self.model.save(
                {},
                {
                    success() {
                        self.clearBeneficiaryForm();
                        self.parentView.trigger('removeAlert');
                        self.childView.trigger('childSaveSuccess');

                        self.readyState = new Promise(function (resolve, reject) {
                            const service = services.generateUrl(this.context.serviceName)
                                + constants.URL_GETCHILDREN_ACTION;
                            const postData = {
                                rowsPerPage: 1,
                                startRow: 1,
                                action: 'INSERT',
                                entryMethod: 0,
                                subType: this.context.subType,
                                batchSeqNumber: self.batchSeqNum,
                            };
                            http.post(service, postData, (data) => {
                                resolve(data.nextSeqNum);
                                if (self.childView.isCTX) {
                                    self.childView.gridComponentViews
                                        .GRANDCHILD.setNextSeqNumber(data.nextSeqNum);
                                    self.childView.gridComponentViews
                                        .GRANDCHILD.refreshCollectionData();
                                }

                                // Add focus indicator
                                self.focusChild = true;
                            }, () => {
                                reject(1);
                            });
                        }.bind(self.parentView)).then((results) => {
                            self.model.childNumber = results;
                            self.setElementEnabled(self.$('[name="ADDBENE"]'), true);
                        });
                    },

                    error() {
                        const functionCode = self.context.functionCode
                            || (self.context.actionContext
                            && self.context.actionContext.functionCode);
                        if (functionCode && functionCode.endsWith('TMPL')) {
                            self.ui.$addBtn.prop('disabled', false);
                        }
                        self.trigger('childSaveError');
                        self.parentView.trigger('modelAction:error', self.model);
                        self.setElementEnabled(self.$('[name="ADDBENE"]'), true);
                    },
                },
            );
        };

        if (this.childView.isGrandChildDirty()) {
            /*
             * When a grandChild save fails, this listern has already been added,
             * so before adding the listener again, remove it, to make sure that we
             * don't have multiple callbacks firing
             */
            this.childView.stopListening(this.childView, 'grandChildSaveSuccess');
            /*
             * save the grand child first and on success proceed to save the Beneficiary
             * (child record)
             */
            this.childView.listenToOnce(this.childView, 'grandChildSaveSuccess', saveFunc);
            this.childView.saveGrandChild();
        } else if (!saveFunc()) {
            self.setElementEnabled(self.$('[name="ADDBENE"]'), true);
        }
    },

    /**
     * We're only in the template cycle, so complex elements don't exist, yet.
     * Attach the ui-loaded deven to wait for additional actions.
     * REVIEW: This seems outrageously hacky and may not need all the child conditions?
     */
    attachMDFEvents() {
        this.listenTo(this.parentView, 'ui-loaded', function () {
            if (this.blockHasGrid && this.parentView.batchChildGridView) {
                // Grid is probably already ready already.
                if (this.parentView.batchChildGridView.hasLoaded) {
                    this.gridWrapper = this.parentView.batchChildGridView.gridView.wrapper;
                }
            }
        });
    },

    /**
     * MDF is all a template, so we have to wait for the render to finish to
     * actually find out element.
     * @param {Object} block - MDF block element
     * @param {boolean} [isChild] - Children are not directly rendered, so they
     * must be handled.
     * @return {{blockOutput: string, renderBlock: boolean}}
     */
    renderForMDF(block, isChild) {
        let data;

        if (isChild) {
            this.reRenderForMDFChild();

            data = this.helpers.outputGroups(block.groups);
            // Wrap the child view to hide it.
            data.blockOutput = '';

            return data;
        }
        this.render();
        return {
            blockOutput: this.wrapHTMLForMDF(),
            renderBlock: true,
        };
    },

    reRenderForMDFChild() {
        this.reattachViewForMDF();
        this.render();

        if (this.state !== 'VIEW' && this.state !== 'REVERSE') {
            this.ui.$reverseReason.parent().hide();
        }

        // FIXME: Can we extract content from the childView to avoid being tied to it?
        this.jsonModel = this.childView.jsonModel;
        this.model.jsonData = this.childView.model.jsonData;

        // this.processForMDFChild();

        /**
         * HACK
         * NH-169644
         * It was observed during the fix for this issue that for beneficiaryWidget view
         * passed here couldn't find the [data-bind] elements. The beneficiary widget
         * created in 8.3.3 uses some messy logic to mimic the original beneficiary childView
         * that appeared in a modal previously. The lookup is populating the original hidden view,
         * not the visible beneficiary widget. In order to work around this, we are attaching an
         * "alternateView" to the hidden childView. When viewBinding fires with a Model update,
         * it will check for the existence of the alternateView — which only exists in the
         * case of the beneficiaryWidget — and update the DOM of the widget,
         * not the original hidden childView.
         */
        this.childView.alternateView = this;
        this.childView.setupFormComponents(this.$el);
        comboboxHelper.checkForSelectIfOnlyOne(this);

        /*
         * Retrigger events from the detached childView for now
         * FIXME: We should get these events on our own view...
         */
        this.listenTo(this.parentView.childView, 'all', function (name, event, data) {
            this.trigger(name, event, data);
        });
        // PCM-4845
        // PCM-5037
        if (this.parentView.batchChildGridView) {
            this.listenTo(this.parentView.batchChildGridView.gridView, 'rowAction', this.gridRowActionBefore);
        }
    },

    /**
     * Call the MDF helper class to render nested block content.
     * @return {{grid: (undefined|string), bene: (undefined|string), beneOpt: string}}
     */
    blockRenderForTemplate() {
        // TODO: We should use a mapping to avoid extra churn on finding the groups
        const gridGroup = this.getGroupByAttribute('fieldUIType', ['CHILDGRID']);
        const pcmBeneAttributes = ['CHECKNUMBER']; // PCM-846, PCM-1103
        const beneGroup = this.getGroupByAttribute('name', ['RECEIVCOMPNAME', 'INDVNAME', 'CHECKNUM'].concat(pcmBeneAttributes));
        const secondaryAccountGroup = this.getGroupByAttribute('name', ['SECOND_ACCOUNT_ALLOCATION']);

        // TODO: Is this reject safe enough? Do we need to exclude Beneficiary Exclusion Dates?
        const remainingGroups = util.reject(this.groups, group => group === gridGroup
            || group === beneGroup || group === secondaryAccountGroup);
        let gridContent;
        let beneContent;
        let secondAccountContent;

        if (beneGroup) {
            beneContent = this.helpers.outputGroups([beneGroup]);
        }

        if (gridGroup) {
            gridContent = this.helpers.outputGroups([gridGroup]);
        }

        if (secondaryAccountGroup) {
            secondAccountContent = this.helpers.outputGroups([secondaryAccountGroup]);
        }

        const beneOtherContent = this.helpers.outputGroups(remainingGroups);

        this.attachMDFEvents();

        // TODO: Add logic for child support (Add Beneficiary child form)

        return {
            grid: gridGroup && gridContent.blockOutput,
            bene: beneGroup && beneContent.blockOutput,
            beneSecondAccount: secondaryAccountGroup && secondAccountContent.blockOutput,
            beneOpt: beneOtherContent.blockOutput,
        };
    },

    toggleEditMode() {
        this.$el.toggleClass('is-edit-mode', this.$('#batch-child-edit-toggle').is(':checked'));
        if (!this.$el.hasClass('is-edit-mode')) {
            /*
             * defer is used so that the edit checkbox is immediately unchecked, and the
             * re-render of the data
             * will occur afterwards
             */
            util.defer(() => {
                this.parentView.batchChildGridView.gridView.refreshGridData();
            });
        }
    },

    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e  Jquery click handler event
     */
    toggleLock(e) {
        PaymentUtil.toggleLock(e, this.model);
    },

    /**
     * Correctly sets the lock icons disabled state on the amount input change
     * @param  {Event} e  Jquery change handler event
     */
    changeAmount(e) {
        PaymentUtil.changeAmount(e);
    },

    deleteChild() {
        this.parentView.batchChildGridView.deleteChild();
    },

    holdAll() {
        this.parentView.batchChildGridView.holdAll();
    },

    /**
     * Tells is if we are creating payment from a template
     * @return {boolean}
     */
    isFromTemplateToPayment() {
        /*
         * NH-45449
         * Adding check of entry method to see if we are creating payment from template from
         * template list view.
         */
        return this.options.context.PARENTENTRYMETHOD === '1';
    },

    /**
     * Tells is if we want to show the bene info input section on the batch payment page
     *
     * NH-45817,45818
     * When using new bene widget, the bene info input section displayed if the
     * payment is not in modify mode or user didn't click on the add bene button.
     * This results in the bene info input also displayed when the
     * payment is created through copy as payment or copy as template.  Based on
     * the new screen prototype, screen of payment or template from copy is similar
     * to payment in modify, bene info input should not be displayed.
     * So add checking if payment/template is from copy as when setting the showEntry flag.
     *
     * @return {boolean}
     */
    showEntry() {
        return (!this.isModifyMode() && !this.isFromCopy()) || this.userOpenedAddBeneficiary;
    },

    /**
     * @description determine if the bene section should be rendered in the DOM
     * the bene section should be rendered except when copy to payment from a template
     * @param {Object} templateContent
     * @return {boolean}
     */
    showBeneBlock(templateContent) {
        return templateContent.bene !== undefined
            && !(this.isFromCopy() && this.isFromTemplateToPayment());
    },

    // PCM-4845
    gridRowActionBefore(options) {
        if (options && options.action === 'image') {
            const { model } = options;
            const batchTnum = model.get('BATCHTNUM');
            const tnum = model.get('TNUM');
            // PCM-5290
            let typeCode = model.get('TYPE').toLowerCase();
            if (model.context
                && model.context.serviceName
                && model.context.serviceName.indexOf('bprchk') > -1) {
                typeCode = 'prchk';
            }

            store.set('checkTransactionInfo', options.model.toJSON());
            // PCM
            store.set('entitlementsInfo', this.entitlements);
            navigateTo(`${checkRoutes
                .viewRoute}/${batchTnum}/${tnum}/${typeCode}/checkPreview/`);
        }
    },

    // PCM-846, PCM-1103
    showAddBeneButtonBlock() {
        return this.productCode !== constants.CHECK_PRODUCT_CODE;
    },
    // PCM-846, PCM-1103

    templateHelpers() {
        const templateContent = this.blockRenderForTemplate();

        return {
            viewId: this.viewId,
            gridBlock: templateContent.grid,
            beneficiaryBlock: templateContent.bene,
            beneficiarySecondaryAccount: templateContent.beneSecondAccount,
            beneficiaryOptional: templateContent.beneOpt,
            hasBene: templateContent.bene !== undefined,
            hasGrid: templateContent.grid !== undefined,
            showBeneBlock: this.showBeneBlock(templateContent),
            showAddBeneButtonBlock: this.showAddBeneButtonBlock(), // PCM-846, PCM-1103
            showEntry: this.showEntry(),
            hideBeneAdd: this.hideBeneAdd(),
            getAddBeneficiaryLocale: this.getAddBeneficiaryLocale(), // PCM-1220
        };
    },
});
