import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import dialog from '@glu/dialog';
import constants from 'app/administration/constants';
import UserCentricModel from 'app/administration/models/user/smbUser';
import util from '@glu/core/src/util';
import AccountCollection from 'app/administration/collection/smbAccounts.userMaintenance';
import BankWidgetCollection from 'app/administration/collection/user/smb/bankWidgets';
import TimeZoneCollection from 'app/administration/collection/timeZones';
import LocaleCollection from 'app/administration/collection/locales';
import ChallengeCollection from 'app/administration/collection/challengeMethods';
import UserGroupCollection from 'app/administration/collection/userGroups';
import ExistingUsersCollection from 'app/administration/collection/existingUsers';
import errHandler from 'system/errHandler';
import userInfo from 'etc/userInfo';
import locale from '@glu/locale';
import AdminUtil from 'app/administration/common/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import Formatter from 'system/utilities/format';
import moment from 'moment';
import dateUtil from 'common/util/dateUtil';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import { getClientLocations } from 'app/administration/views/userMaintenance/smb/services';
import { modalClose } from 'common/modals/mobileGridModalUtil';
import loadingTemplate from 'common/templates/loadingWidget.hbs';
import store from 'system/utilities/cache';
import template from './userCentricModal.hbs';
import UserCentricAudit from './userCentricAudit';
import PermissionCollectionView from './assignedPermissions/permissionCollectionView';
import SMBDynamicFields from './SMBDynamicFields';
import ClientLocationLogin from './clientLocationLogin';

export default Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $dailyLimitField: '.daily-limit-field',
        $transactionLimitField: '.transaction-limit-field',
        $fileLimitField: '.file-limit-field',
        $selectAllPermsCheckbox: '.all-permissions',
        $selectAllAccsCheckbox: '.all-accounts',
        $selectAllWidgetsCheckbox: '.all-bank-widgets',
        $paymentCheckbox: '[data-hook="getPaymentsChk"]',
        $challengeField: '.challenge-field',
        $optCheckField: '.opt-check',
        $optReasonField: '.opt-reason',
        $accountsField: '.accounts-field',
        $bankWidgetsField: '.bank-widgets-field',
        $allAccountsText: '#allAccountsText',
        $allAccounts: '#all-accounts',
        $allBankWidgetsText: '#allBankWidgetsText',
        $allBankWidgets: '#all-bank-widgets',
        $copyExistingUser: 'select[name="COPYEXISTINGUSER"]',
    },

    events: {
        'change @ui.$dailyLimitField': 'changeDailyLimitHandler',
        'change @ui.$transactionLimitField': 'changeTransactionLimitHandler',
        'change @ui.$fileLimitField': 'changeFileLimitHandler',
        'click @ui.$selectAllPermsCheckbox': 'toggleSelectAllPermissions',
        'click @ui.$selectAllAccsCheckbox': 'toggleSelectAllAccounts',
        'click @ui.$selectAllWidgetsCheckbox': 'toggleSelectAllWidgets',
        'click @ui.$paymentCheckbox': 'toggleBenePayment',
        'change @ui.$copyExistingUser': 'changeExistingUser',
    },

    regions: {
        alertRegion: '.alert-region',
        audit: '.auditPanel .panel-body',
        smbDynamicFieldsRegion: '.smbDynamicFieldsRegion',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    initialize(options) {
        this.options = options;
        this.company = this.options.company;
        this.mode = options.mode || constants.MODES.VIEW;
        this.model = options.model || new UserCentricModel();
        this.sso = serverConfigParams.get('ClientAppOnSSO');
        this.isInsert = this.mode === constants.MODES.INSERT;
        this.isInsertOrModify = [
            constants.MODES.INSERT,
            constants.MODES.MODIFY,
        ].includes(this.mode);
        this.showTokenSerialNum = serverConfigParams.get('showTokenSerialNum') === 'true';
        this.enableActivationDate = serverConfigParams.get('enableActivationDate') === 'Y';
        this.isAdmin = (window.Bottomline.appRoot === 'ui-admin'
            || window.Bottomline.appRoot === 'ui-admin-portal');

        // Set UserGroup

        if (!this.isAdmin && this.isInsert) {
            this.model.set('USERGROUP', userInfo.get('group'));
            this.model.set('PARENTUSERGROUP', userInfo.get('group'));
            this.model.set('TIMEZONEID', userInfo.get('timezone'));
            this.model.set('LOCALE', userInfo.get('locale'));
        } else if (this.isAdmin && this.isInsert) {
            this.model.set('USERGROUP', options.userGroup);
            this.model.set('PARENTUSERGROUP', options.company.get('parentUserGroup'));
            this.model.set('TIMEZONEID', options.company.get('timezone'));
            this.model.set('LOCALE', options.company.get('locale'));
            this.model.set('CHALLENGEMETHOD', 0);
        }

        this.setInitModalTitleButtons();
    },

    setInitModalTitleButtons() {
        this.dialogTitle = this.isInsert ? locale.get('administration.user.add') : locale.get('administration.user.modify');

        this.dialogButtons = [{
            text: this.isInsert ? locale.get('uce.addUser') : locale.get('uce.modifyUser'),
            className: 'btn btn-primary',
            callback: 'saveUser',
        }, {
            text: locale.get('administration.close.btn'),
            className: 'btn btn-tertiary',
            callback: 'cancel',
        }];
    },

    /**
     * Renders the view and accepts param if copy from user
     * @param {boolean} [copyUser]
     */
    onRender(copyUser) {
        if (!this.hasLoadedRequiredData()) {
            this.loadRequiredData();
        } else {
            this.renderUserInfoSections(copyUser);
            const userModel = this.model;
            userModel.set({
                COMPANY: this.company,
            });
            this.smbDynamicFields = new SMBDynamicFields({
                mode: this.mode,
                model: userModel,
            });
            this.smbDynamicFieldsRegion.show(this.smbDynamicFields);
            this.$('[data-toggle="popover"]').popover();

            if (this.hasLocationLogin()) {
                this.showLocationLogin(true);
            }
        }
    },

    /**
     * Set the global setting checkbox to be selected
     *
     * @param {String} type - the assign all type - e.g. Account or BankWidget
     * @param {String} selector - partial name of the selector used in select2
     * @param {jQuery} $selectAll - select all text element
     */
    setAssignAll(type, selector, $selectAll) {
        if (this.model.get(`assignAll${type}`)) {
            this.displayField(true, $selectAll);
            this.$(`#s2id_${selector}`).hide();
            this.$(`#all-${selector}`).prop('checked', true);
        } else {
            this.displayField(false, $selectAll);
            this.$(`#s2id_${selector}`).show();
        }
    },

    /**
     * Set the global setting checkbox to be selected
     *
     * @param {object} $input - the payment checkbox element.
     * @param {boolean} isReadOnly - true if the checkbox is read only
     */
    updateGlobalSettingInput($input, isReadOnly) {
        $input.prop('checked', true);
        if (isReadOnly) {
            $input.addClass('read-only-field').prop('disabled', true);
        }
    },

    /**
     * Extract the boolean value from the company mapped data.
     *
     * @param {string} setting - the name of the attribute to extract from the map data.
     */
    getSettingBooleanValue(setting) {
        const field = util.findWhere(
            this.company.get('mapDataList'),
            {
                toField: setting,
            },
        );
        return field && field.value && field.value === '1';
    },

    /**
     * Initialize the user setting checkbox for when Beneficiaries must be selected
     * from the Contact Center for payments.
     */
    populateBeneAddressBookSettings() {
        const reqForPayments = this.getSettingBooleanValue('CompanyRequireBeneFromAdressBookForPayments');
        const userGlobalSettings = this.model.get('globalSettings');

        if (reqForPayments) {
            userGlobalSettings.BENEPAY = true;
            userGlobalSettings.BENETEMP = true;
            this.updateGlobalSettingInput(this.ui.$paymentCheckbox, true);
            return;
        }
        if (userGlobalSettings && userGlobalSettings.BENEPAY === true) {
            this.updateGlobalSettingInput(this.ui.$paymentCheckbox, false);
        }
    },

    /**
     * initialize combo boxes
     *
     * @param isInsert
     */
    setupCombos(isInsert) {
        let selected;
        let widgetsSelected;

        if (!isInsert) {
            // Seed the account combo with the items already selected.
            selected = this.model.get('accounts').map(account => account.get('accountNumber'));
            widgetsSelected = this.model.get('bankWidgets').map(bankWidget => bankWidget.get('id'));
            this.ui.$selectAllAccsCheckbox.prop('checked', this.model.get('assignAllAccount'));
        } else {
            this.ui.$copyExistingUser.comboBox();
        }

        this.accountsCombo = this.ui.$accountsField.comboBox({});
        // Seed the acount combo with the items already selected.
        this.accountsCombo.comboBox('val', selected);

        this.bankWidgetsCombo = this.ui.$bankWidgetsField.comboBox({});
        // Seed the bank widget combo with the items already selected.
        this.bankWidgetsCombo.comboBox('val', widgetsSelected);

        if (this.challenges.length > 0) {
            this.ui.$challengeField.comboBox();
        }
    },

    setupAdmin() {
        if (this.isAdmin) {
            // default opt security fields if new user
            if (!this.company.get('optUISecurityCheck')) {
                this.setupOptUI();
            }
        }
    },

    isSplitUserName() {
        return serverConfigParams.get('userMaintSplitUserName') === 'true';
    },

    /**
     * initialize opt trustee fields
     */
    setupOptUI() {
        const optUI = this.model.get('OPTUISECURITYCHECK');
        const optReasonField = this.ui.$optReasonField;

        // handle initial disabled status
        optReasonField.prop('disabled', !optUI);
        if (!optUI) {
            optReasonField.val('');
        }

        // set disabled status when option changed
        this.model.on('change:OPTUISECURITYCHECK', (model, value) => {
            optReasonField.prop('disabled', !value);
            if (!value) {
                optReasonField.val('');
            }
        });
    },

    renderPermissionCollectionView() {
        // embed collection view for the payment types
        this.permissionCollectionView = new PermissionCollectionView({
            el: this.$('.role-permissions')[0],
            collection: new Collection(this.model.get('permissions').reject(permission => permission.get('permission') === 'additionalServices')),
        });

        this.listenTo(this.permissionCollectionView.collection, 'change:entitled', this.handleChangeEntitled);

        this.permissionCollectionView.render();
    },

    handleChangeEntitled(model) {
        this.handlePermissionChange(model);
        /*
         * check or uncheck all permissions depending on
         * if all checkboxes are checked or unchecked
         */
        const uniqueCheckboxVals = util.uniq(this.permissionCollectionView.collection.pluck('entitled'));
        if (uniqueCheckboxVals.length === 1) {
            this.ui.$selectAllPermsCheckbox.prop('checked', uniqueCheckboxVals[0]);
        } else {
            this.ui.$selectAllPermsCheckbox.prop('checked', false);
        }
    },

    /**
     * Perform any entitlement specific logic
     * @param {Object} model
     */
    handlePermissionChange(model) {
        const permission = model.get('permission');
        switch (permission) {
        case 'remoteDepositCapture':
            this.showLocationLogin(this.hasLocationLogin());
            break;
        default:
            break;
        }
    },

    /**
     * Show the LocationLogin view or reset the region
     * @param {boolean} show
     */
    showLocationLogin(show) {
        if (!show) {
            this.locationLoginRegion.reset();
            this.model.removeValidator('loginName');
            return;
        }
        this.model.addValidator('loginName', {
            exists: true,
            description: locale.get('uce.loginname'),
        });
        this.locationLoginRegion.show(new ClientLocationLogin({
            model: this.model,
            clientLocations: this.clientLocations,
        }));
    },

    toggleSelectAllPermissions(e) {
        const isChecked = e.currentTarget.checked;

        // update all permissions to true
        this.model.get('permissions').each((permission) => {
            permission.set('entitled', isChecked);
        });

        this.permissionCollectionView.collection = new Collection(this.model.get('permissions').reject(permission => permission.get('permission') === 'additionalServices'));
        this.permissionCollectionView.render();
    },

    /**
     * Either hide the accounts combo and c/f note
     * or show depending on the checkbox being checked.
     */
    toggleSelectAllAccounts(e) {
        const isChecked = e.currentTarget.checked;
        this.model.set('assignAllAccount', isChecked);
        this.displayField(isChecked, this.ui.$allAccountsText);
        this.displayField(!isChecked, this.$('.assign-accounts > div'));
    },

    /**
     * Either hide the bank widgets combo and c/f note
     * or show depending on the checkbox being checked.
     */
    toggleSelectAllWidgets(e) {
        const isChecked = e.currentTarget.checked;
        this.model.set('assignAllBankWidget', isChecked);
        this.displayField(isChecked, this.ui.$allBankWidgetsText);
        this.displayField(!isChecked, this.$('.assign-bank-widgets > div'));
    },

    /**
     * Update the global settings list in the model with the
     * "Beneficiary from Contact Center for payment" checkbox value.
     */
    toggleBenePayment(e) {
        const globalSettings = this.model.get('globalSettings');
        globalSettings.BENEPAY = e.currentTarget.checked;
        globalSettings.BENETEMP = e.currentTarget.checked;
    },

    changeDailyLimitHandler(e) {
        this.model.get('dailyLimit').assignedLimit = parseInt(e.target.value, 10);
    },

    changeTransactionLimitHandler(e) {
        this.model.get('transactionLimit').assignedLimit = parseInt(e.target.value, 10);
    },

    changeFileLimitHandler(e) {
        this.model.get('fileLimit').assignedLimit = parseInt(e.target.value, 10);
    },

    /**
     * Obtains the entitlements from an existing
     * user to copy them to the new user.
     * @param  {Event|String} e jquery event from combo change or string if
     * from listview
     */
    changeExistingUser(e) {
        /*
         * Get the user from the value, which is
         * 'userid - description' or string if from listView.
         */
        const copyUserFromListView = typeof e === 'string';

        const copyFromUser = copyUserFromListView ? e : e.target.value.split('-')[0];

        /*
         * Get the model of the existing user.  Pass
         * flag of copyFromUser to use the REST call
         * that obtains the operational tables' values.
         */
        new UserCentricModel().fetch({
            copyFromUser: true,
            userId: copyFromUser,
            userGroup: this.model.get('USERGROUP'),

            success: (copyFromUserModel) => {
                // Copy the the entitlements from the existing user to the new one.
                this.model.set({
                    accounts: copyFromUserModel.get('accounts'),
                    bankWidgets: copyFromUserModel.get('bankWidgets'),
                    assignAllAccount: copyFromUserModel.get('assignAllAccount'),
                    assignAllBankWidget: copyFromUserModel.get('assignAllBankWidget'),
                    dailyLimit: copyFromUserModel.get('dailyLimit'),
                    fileLimit: copyFromUserModel.get('fileLimit'),
                    transactionLimit: copyFromUserModel.get('transactionLimit'),
                    permissions: copyFromUserModel.get('permissions'),
                    globalSettings: copyFromUserModel.get('globalSettings'),
                    COPYEXISTINGUSER: copyFromUser,
                });
                // Re-paint role permissions
                this.$('.role-permissions').empty();
                // Re-render all sections
                this.renderUserInfoSections(true);
            },

            error: (response) => {
                AdminUtil.errorAlert(this.alertRegion, response);
            },
        });
    },

    cancel() {
        store.unset('userCompany');
        modalClose(this.options.onClose, this);
        dialog.close();
    },

    updset() {
        this.navigateTo(constants.ROUTES.UPDATE_USER_SETTING);
    },

    displayField(isVisible, field) {
        if (isVisible) {
            field.show();
        } else {
            field.hide();
        }
    },

    configurePasswordValidation() {
        const passwordValue = this.model.get('PASSWORD');
        const confirmPassword = this.model.get('CONFIRMPASSWORD');
        const passwordEmpty = passwordValue === '' || passwordValue === undefined;
        const confirmPasswordEmpty = confirmPassword === '' || confirmPassword === undefined;

        if (passwordEmpty && confirmPasswordEmpty) {
            this.model.removePasswordValidators();
        } else {
            this.model.addPasswordValidators();
        }
    },

    saveUser() {
        if (this.mode === constants.MODES.MODIFY) {
            this.model.set({
                mode: this.mode,
            });
        }
        if (this.mode === constants.MODES.INSERT) {
            this.model.set({
                STRIKES: '0',
            });
        }

        // process accounts
        const selectedAccounts = this.accountsCombo.comboBox('val');

        const collection = new Collection();

        const mapArr = selectedAccounts.map((item) => {
            const rec = this.bankAccounts.get(item);

            if (rec !== undefined) {
                return {
                    accountName: rec.get('accountName'),
                    accountNumber: rec.get('id'),
                };
            }
            return undefined;
        });

        collection.reset(mapArr);
        this.model.set({
            accounts: collection,
        });

        this.model.set({
            bankWidgets: new Collection(this.getSelectedBankWidgets()),
        });

        if (!(this.model.get('CHALLENGEMETHOD') === constants.VASCO_CHALLENGE_METHOD
        || this.model.get('CHALLENGEMETHOD') === constants.SYMANTEC_VIP_CHALLENGE_METHOD) || (this.showTokenSerialNum)) {
            this.model.set('TOKENSERIALNUMBER', '');
        }

        /*
         * if there are validation errors found in the dynamically created field
         * section on the screen, exit
         */
        if (!this.smbDynamicFields.pageView.model.isValid()) {
            return;
        }
        this.mapSMBDynamicFieldsToModel();

        this.model.save(
            {},
            {
                success: (model, response) => {
                    this.trigger('userAction:success', response, this.mode);
                    modalClose(this.options.onClose, this);
                    dialog.close();
                },

                error: (model, response) => {
                    AdminUtil.errorAlert(this.alertRegion, response);
                },
            },
        );
    },

    /**
     * Maps the selected widgets
     * @returns {Array} - selected widgets
     */
    getSelectedBankWidgets() {
        const selectedWidgets = this.bankWidgetsCombo.comboBox('val');

        return selectedWidgets.filter(item => this.bankWidgets.get(item) !== undefined)
            .map((item) => {
                const rec = this.bankWidgets.get(item);
                return {
                    id: rec.get('id'),
                    name: rec.get('label'),
                };
            });
    },

    /**
     * Maps the attributes from the dynamically created field section to
     * this model for saving.
     */
    mapSMBDynamicFieldsToModel() {
        const dynPageView = this.smbDynamicFields.pageView;
        if (dynPageView && dynPageView.model) {
            dynPageView.model.set({
                USERGROUP: this.model.get('USERGROUP'),
                PARENTUSERGROUP: this.model.get('PARENTUSERGROUP'),
            });
            if (dynPageView.model.isValid()) {
                const dynPageFieldData = dynPageView.model.fieldData;
                const { userInfoKeys } = this.model;
                util.each(dynPageFieldData, (item) => {
                    const itemName = item.name;
                    userInfoKeys.push(itemName);
                    this.model.set(itemName, dynPageView.model.get(itemName));
                });
            }
        }
    },

    /**
     * Get client locations assigned to the user
     * @returns {Promise}
     */
    getClientLocations() {
        return getClientLocations(this.model).then((clientLocations) => {
            this.clientLocations = clientLocations;
            /*
             * When in insert mode, and only one location, need to set the model manually
             * because it is not updated when the dropdown is preselected
             */
            if (this.mode === constants.MODES.INSERT && this.clientLocations.length === 1) {
                const [defaultSelection] = this.clientLocations;
                this.model.set('clientLocations', [defaultSelection]);
            }
        });
    },

    loadRequiredData() {
        const promiseArr = [
            /*
             * timezone
             * locale
             */
            new Promise((resolve, reject) => {
                const options = {
                    isAdmin: this.isAdmin,
                };
                new TimeZoneCollection(options).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
            // accounts
            new Promise((resolve, reject) => {
                const options = {
                    isAdmin: this.isAdmin,
                };
                new LocaleCollection(options).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
            new Promise((resolve, reject) => {
                new AccountCollection({
                    userGroup: this.model.get('USERGROUP'),
                    isAdmin: this.isAdmin,
                }).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
            new Promise((resolve, reject) => {
                new BankWidgetCollection({
                    userGroup: this.model.get('USERGROUP'),
                    isAdmin: this.isAdmin,
                }).fetch({
                    success: resolve,
                    error: reject,
                });
            }),
        ];
        this.model.set('ACTIVATIONDATE', Formatter.formatDate(this.model.get('ACTIVATIONDATE')));

        // challenges
        promiseArr.push(new Promise((resolve, reject) => {
            new ChallengeCollection().fetch({
                success: resolve,
                error: reject,
            });
        }));
        if (this.isAdmin) {
            // usergroups
            promiseArr.push(new Promise((resolve, reject) => {
                new UserGroupCollection({
                    isAdmin: this.isAdmin,
                }).fetch({
                    success: resolve,
                    error: reject,
                });
            }));
        }

        if (this.isInsertOrModify) {
            promiseArr.push(new Promise((resolve, reject) => {
                const options = {
                    userGroup: this.model.get('USERGROUP'),
                    isAdmin: this.isAdmin,
                    userId: this.model.get('USERID'),
                };

                // Only get the existing users if in insert/modify mode.
                new ExistingUsersCollection(options).fetch({
                    success: resolve,
                    error: reject,
                });
            }));
        }

        // Fetch client locations for rdc
        promiseArr.push(this.getClientLocations());

        Promise.all(promiseArr)
            .then((results) => {
                const [
                    timezones,
                    locales,
                    bankAccounts,
                    bankWidgets,
                    challenges,
                    userGroupsOrExistingUsers,
                    adminExistingUsers,
                ] = results;
                this.timezones = timezones;
                this.locales = locales;
                this.bankAccounts = bankAccounts;
                this.bankWidgets = bankWidgets;

                if (this.isInsertOrModify) {
                    this.existingUsers = this.isAdmin
                        ? adminExistingUsers : userGroupsOrExistingUsers;
                }

                this.challenges = challenges;
                if (this.isAdmin) {
                    this.userGroups = userGroupsOrExistingUsers;
                    // insert the selected company into the collection
                    userGroupsOrExistingUsers.add(this.company);
                }

                //                this.setChallengeListener();
                this.setHasLoadedRequiredData(true);

                // If this is from the listview copy from user context menu item
                if (this.options.copyFromUser) {
                    this.changeExistingUser(this.options.copyFromUser);
                }
                this.render();
            })
            .then(null, errHandler);
    },

    isBankWidgetsEntitled() {
        return this.model.get('permissions').some(model => model.get('permission') === 'additionalServices' && model.get('entitled') === true);
    },

    /**
     * Is the user configured to use location login for rdc
     * @returns {boolean}
     */
    hasLocationLogin() {
        return this.model.hasPermission('remoteDepositCapture') && serverConfigParams.get('RDCProviderName') === 'Deluxe';
    },


    /**
     * @method getFormattedAccounts
     * Helper method to format accounts name
     * @returns {array}
     */
    getFormattedAccounts() {
        if (!this.bankAccounts) {
            return [];
        }
        const accounts = this.bankAccounts.toJSON();
        return accounts.map(account => ({
            ...account,
            accountName: util.unescape(account.accountName),
        }));
    },

    /**
     * @method renderUserInfoSections
     * @description method to render user info sections
     * @param {boolean} copyUser
     */
    renderUserInfoSections(copyUser) {
        this.setupCombos(copyUser ? false : this.isInsert);

        this.setupAdmin();

        // set limit values
        this.ui.$dailyLimitField.val(this.model.get('dailyLimit').assignedLimit);
        this.ui.$transactionLimitField.val(this.model.get('transactionLimit').assignedLimit);
        this.ui.$fileLimitField.val(this.model.get('fileLimit').assignedLimit);

        this.renderPermissionCollectionView();

        this.populateBeneAddressBookSettings();

        // No audit trail on insert.
        if (!this.isInsert) {
            this.audit.show(new UserCentricAudit({
                model: this.model,
            }));
        } else {
            this.displayField(false, this.ui.$allAccountsText);
            this.displayField(false, this.ui.$allBankWidgetsText);
        }

        /*
         * Show the current/future checkbox and
         * label if already set or from a copied user
         */
        if (!this.isInsert || copyUser) {
            this.setAssignAll('Account', 'accounts', this.ui.$allAccountsText);
            this.setAssignAll('BankWidget', 'bankWidgets', this.ui.$allBankWidgetsText);
        }
    },

    templateHelpers() {
        return {
            isSplitUserName: this.isSplitUserName,
            isLegalAdminShow: serverConfigParams.get('ShowLegalAdmin') === 'true' && ((this.isAdmin) || (this.mode === 'modify' && this.model.get('LEGALADMIN'))),
            isActivationDateShow: serverConfigParams.get('enableActivationDate') === 'Y' && this.model.get('ACTIVATIONDATE'),
            isDailyLimitEnabled: this.model.get('dailyLimit') ? this.model.get('dailyLimit').applicable : false,
            isTransactionLimitEnabled: this.model.get('transactionLimit') ? this.model.get('transactionLimit').applicable : false,
            isFileLimitEnabled: this.model.get('fileLimit') ? this.model.get('fileLimit').applicable : false,
            isAdmin: this.isAdmin,
            isModifyMode: (this.mode === 'modify'),
            showChallengeMethod: this.challenges && this.challenges.length > 0,
            showClearOTP: this.model.get('CHALLENGEMETHOD') === '0',
            challenges: this.challenges && this.challenges.toJSON(),
            accounts: this.getFormattedAccounts(),
            bankWidgets: this.bankWidgets && this.bankWidgets.toJSON(),
            timezones: this.timezones && this.timezones.toJSON(),
            locales: this.locales && this.locales.toJSON(),
            existingUsers: this.existingUsers && this.existingUsers.toJSON(),
            userGroups: this.userGroups && this.userGroups.toJSON(),
            showOptUI: this.isAdmin && !this.company.get('optUISecurityCheck'),
            MustSelectBeneLabel: this.model.get('templateEntitlement') ? locale.get('uce.smbperm.benePayAndTemp') : locale.get('uce.smbperm.benePay'),
            displayPassword: () => {
                // dont display password for sso
                if (this.sso === 'true') {
                    return false;
                }
                // always display password inputs on the client and insert mode
                if (!this.isAdmin || this.options.mode === 'insert') {
                    return true;
                }

                const emailResetPasswordConfig = serverConfigParams.get('EmailResetPassword');
                const emailResetPassword = emailResetPasswordConfig
                    && emailResetPasswordConfig.toUpperCase() === 'TRUE';
                // NH-109035 'hybrid' implementation until reset password is done only by email
                if (this.options.entitlements.MODIFY
                    && this.options.entitlements.RESETPW
                    && emailResetPassword) {
                    return true;
                }
                // Do not display the password inputs when config is on or lacking entitlement
                if (emailResetPassword || !this.options.entitlements.RESETPW) {
                    return false;
                }

                return true;
            },

            isOFXDirectConnectEntitled: () => this.model.get('permissions').some(model => model.get('permission') === 'OFXDirectConnect' && model.get('entitled') === true),

            isInsert: this.isInsert,
            alternateUserIdValue: this.model.get('ALTERNATEUSERID'),
            isLegalAdmin: this.model.get('LEGALADMIN') === '1',
            isActivationDate: this.model.get('ACTIVATIONDATE') !== null,

            isActiveUser: () => {
                if (serverConfigParams.get('enableActivationDate') === 'Y' && this.model.get('APPROVEDBYGROUPTIMESTAMP') && this.model.get('ACTIVATIONDATE')) {
                    if (moment.duration(dateUtil.getMoment(this.model.get('ACTIVATIONDATE')).diff(dateUtil.getMoment())).asDays() <= 0) {
                        return true;
                    }
                    return false;
                }
                return false;
            },

            insertOrModify: this.mode === 'modify' || this.isInsert,
            modifyAndLegalAdmin: this.mode === 'modify' && this.model.get('LEGALADMIN') === true,
            modifyAndActivationDate: this.mode === 'modify' && this.model.get('ACTIVATIONDATE') !== null,
            showAllowFXSelection: appConfigParams.getValue('WIRES', 'ALLOWFXUSDUSER') === '1',
            isBankWidgetsEntitled: this.isBankWidgetsEntitled(),
        };
    },
});
