var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " range-input ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"form-inline amount-filter\">\n    <div class=\"search-input-wrapper\">\n        <div class=\"region-field-equality\"></div>\n    </div>\n    <div class=\"search-input-wrapper search-operand-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOpBetween") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":101}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"search-operand\">\n            <input type=\"text\"\n                   id=\"field-value-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":42}}}) : helper)))
    + "\"\n                   name=\"field-value\"\n                   value=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0))
    + "\"\n                   data-bind=\"model\"\n                   class=\"form-control\"\n                   data-hook=\"getAmountInputField\"\n            >\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"field-value\"></span>\n        </div>\n        <div class=\"search-operand\">\n            <input type=\"text\" id=\"field-value2-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":55}}}) : helper)))
    + "\" name=\"field-value2\" data-bind=\"model\" class=\"form-control\"\n                   value=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? lookupProperty(stack1,"1") : stack1), depth0))
    + "\" placeholder=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"filter.between.end",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":89}}}))
    + "\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"field-value2\"></span>\n        </div>\n        <button type=\"button\" class=\"close\"  data-control=\"removeFilter\"><span class=\"icon-close\"></span></button>\n    </div>\n    <button type=\"submit\" name=\"submit\" class=\"btn btn-secondary\" disabled>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"apply",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":75},"end":{"line":24,"column":93}}}))
    + "</button>\n    <button type=\"button\" name=\"clear\" class=\"btn btn-tertiary btn-clear-filter hidden\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":88},"end":{"line":25,"column":106}}}))
    + "</button>\n</form>\n";
},"useData":true});