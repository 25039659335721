import Marionette from 'backbone.marionette';
import util from '@glu/core/src/util';
import $ from 'jquery';

export default Marionette.Behavior.extend({
    events: {
        'keydown input[data-number-accelerate]': 'handleKeydown',
        'keyup input[data-number-accelerate]': 'handleKeyup',
    },

    incrementValue(e) {
        if (e.keyCode !== $.ui.keyCode.UP && e.keyCode !== $.ui.keyCode.DOWN) {
            return;
        }

        const $target = this.$(e.currentTarget);
        const step = (+$target.attr('step') || 1) * ((e.keyCode === $.ui.keyCode.UP) ? 1 : -1);
        let newValue = +$target.val() + step;
        const max = $target.attr('max');
        const min = $target.attr('min');

        // respect min and max attributes
        if (!util.isUndefined(max) && newValue > +max) {
            newValue = +max;
        }

        /*
         * check for undefined specifically
         * need to be able to test 0 here
         */
        if (!util.isUndefined(min) && newValue < +min) {
            newValue = +min;
        }

        // so data binding/events can pick it up
        $target.val(newValue).trigger('change');
    },

    handleKeydown(e) {
        const $target = this.$(e.currentTarget);

        if (!this.keyPressCount) {
            this.keyPressCount = 0;
            this.activeEl = e.currentTarget;
            this.originalStep = $target.attr('step') || 1;
        }

        this.keyPressCount += 1;

        if (this.keyPressCount === 10) {
            $target.attr('step', this.originalStep * 5);
        } else if (this.keyPressCount === 20) {
            $target.attr('step', this.originalStep * 10);
        } else if (this.keyPressCount === 30) {
            $target.attr('step', this.originalStep * 20);
        }

        // this adds up/down arrow key functionality to input type = text
        if ($target.is('[type="text"]')) {
            this.incrementValue(e);
        }
    },

    handleKeyup() {
        /*
         * it is possible to move focus between keydown and keyup
         * this ensures we reset the step value on the right element
         */
        delete this.keyPressCount;
        this.$(this.activeEl).attr('step', this.originalStep);
    },
});
