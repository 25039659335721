import React from 'react';
import locale from '@glu/locale';
import PropTypes from 'prop-types';

const AddAnotherPaymentOptionLink = ({ onClick }) => (
    <button
        id="addOption"
        type="button"
        className="btn btn-tertiary"
        onClick={onClick}
        testid="addOption"
    >
        <span className="icon-add-circle" />{ locale.get('loans.add.payment.option') }
    </button>
);

AddAnotherPaymentOptionLink.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default AddAnotherPaymentOptionLink;
