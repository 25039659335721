import '../themeDefaults';

export default ({ mobilegrid }) => ({
  root: {
    margin: [30, 0, 30, 20],
    position: mobilegrid.savedViews.position,
    zIndex: mobilegrid.savedViews.zIndex,
    '&.hidden': {
      display: 'none'
    }
  }
});
