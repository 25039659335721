var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page page-payments page-payments-decision\">\n        <div class=\"page-header\">\n        </div>\n\n        <div class=\"wrap payment-decision-top\" data-region=\"pdTopContent\" id=\"pdTopContent\"></div>\n\n        <div class=\"wrap payment-decision-btm\" id=\"pdBottomContent\" aria-live=\"polite\">\n                <div class=\"wrap\">\n                        <div class=\"two-pane-left pd-bottom-left-content\" data-region=\"pdBottomLeftContent\" id=\"pdBottomLeftContent\" role=\"navigation\"></div>\n                        <div class=\"two-pane-right\">\n                                <div class=\"pd-bottom-right-content\" data-region=\"pdBottomRightContent\" id=\"pdBottomRightContent\"></div>\n                                <div class=\"pd-bottom-button\" id=\"pdBottomButton\">\n                                        <hr>\n                                        <button id=\"showPaymentService\" type=\"button\" class=\"btn-continue\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":107},"end":{"line":14,"column":139}}}))
    + "<span class=\"icon-continue-arrow\"></span></button>\n                                </div>\n                        </div>\n                </div>\n        </div>\n</div>\n";
},"useData":true});