import React from 'react';
import util from '@glu/core/src/util';
import GridApi from 'common/dynamicPages/api/grid';
import ContextApi from 'common/dynamicPages/api/context';
import ListView from 'common/dynamicPages/views/workflow/list';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import FileImportView from 'common/dynamicPages/views/workflow/fileImport';
import store from 'system/utilities/cache';
import { processDeeplink } from 'common/dynamicPages/views/mdf/mdfUtil';
import { getState } from 'common/util/reduxUtil';
import RenderMfe from './views/mfe/RenderMfe';

export default {
    dpShowContextPage(menuCategory, serviceName, serviceFunc, businessType, context) {
        const contextDefinition = ContextApi.menuContext.getContext(context);

        const options = {
            context: contextDefinition,
        };

        this.showPage('', GridApi.createServiceGridView(options));
    },

    dpShowPage(menuCategory, action, serviceName, serviceFunc, businessType, context) {
        let lastFragment;
        if (this.currentView?.mode !== 'modify' && this.currentView?.mode !== 'view') {
            store.set('current-workspace-route', this.lastFragment);
            ({ lastFragment } = this);
        } else {
            lastFragment = store.get('current-workspace-route');
        }
        const options = {
            menuCategory,
            serviceName,
            serviceFunc,
            businessType,
            context,
            lastFragment,
        };

        const inAction = action.toUpperCase();
        const { pageContext: { actionData } } = getState();

        if (actionData?.component) {
            this.showPage('', <RenderMfe name={actionData.component} />); // eslint-disable-line
            return;
        }

        if (inAction === 'LIST') {
            if (processDeeplink(`${menuCategory}/${action}/${serviceName}/${context}`, this)) {
                options.returnRoute = `${menuCategory}/${action}/${serviceName}/${context}`;
                this.showPage('', new ListView(options));
            }
        }

        if (inAction === 'PAGE') {
            this.showPage('', GridApi.createServiceGridView({
                context: ContextApi.menuContext.getContext(context),
            }));
        }

        if (inAction === 'INSERT' || inAction === 'VIEW' || inAction === 'MODIFY') {
            this.showPage('', new EntryView(util.extend(
                options,
                {
                    mode: action,
                },
            )));
        }

        if (inAction === 'IMPORT') {
            this.showPage('', new FileImportView(util.extend(
                options,
                {
                    mode: action,
                },
            )));
        }
    },
};
