var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add-bill\">\n    <form data-submit=\"save\">\n\n        <div>\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n       <div class=\"ui-droppable droppable-target\">\n          <p class=\"text-center empty-queue-msg\">\n            <span class=\"icon-add-doc\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.drag.to.import",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":47},"end":{"line":9,"column":86}}}))
    + "\n          </p>\n       </div>\n\n        <div class=\"form-group vendor-group\">\n            <label for=\"payee\" class=\"main-label\"><span>1</span>. "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":66},"end":{"line":14,"column":109}}}))
    + "</label>\n            <div class=\"field-container\">\n                <div class=\"vendor-region\" data-region=\"vendorRegion\" data-hook=\"vendor\"></div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"VENDORNAME\"></span>\n            </div>\n        </div>\n\n        <div class=\"form-group\">\n            <label for=\"payee\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.invoice.number",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":47},"end":{"line":22,"column":86}}}))
    + "</label>\n             <div class=\"field-container\">\n                 <input type=\"text\" name=\"reference\" value=\"\" id=\"invoiceNumber\" class=\"form-control\" maxlength=\"10\" placeholder=\"Enter vendor invoice number\"/>\n             </div>\n        </div>\n\n        <div class=\"form-group billing-amount-group multifield-container\">\n            <label for=\"billAmount\" class=\"main-label\"><span>2</span>. "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":71},"end":{"line":29,"column":114}}}))
    + "</label>\n            <div class=\"field-container\">\n                <input type=\"text\" name=\"total\" value=\"\" id=\"billAmount\" class=\"form-control\" placeholder=\"\" />\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TOTALAMOUNT\"></span>\n            </div>\n        </div>\n\n        <div class=\"form-group billing-date-group\">\n            <label for=\"billingDate\" class=\"main-label\"><span>3</span>. "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":72},"end":{"line":37,"column":115}}}))
    + "</label>\n            <div class=\"field-container\">\n                <div class=\"billing-date-container\"></div>\n                <input type=\"text\" name=\"DUEDATE\" id=\"dateRange\" class=\"dateRange\" data-bind=\"model\"/>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DUEDATE\"></span>\n            </div>\n        </div>\n\n         <div class=\"form-group\">\n             <label for=\"billingDue\" class=\"sr-only main-label\"><span></span></label>\n                <div class=\"field-container\">\n                    <select class=\"form-control\" id=\"terms\" name=\"terms\" data-hook=\"billing-terms\">\n                        <option value=\"Terms: Net 0\" data-item=\"Due on Receipt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.on.receipt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":80},"end":{"line":49,"column":119}}}))
    + "</option>\n                        <option value=\"Terms: Net 10\" data-item=\"Terms: Net 10\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net10",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":80},"end":{"line":50,"column":114}}}))
    + "</option>\n                        <option value=\"Terms: Net 15\" data-item=\"Terms: Net 15\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net15",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":80},"end":{"line":51,"column":114}}}))
    + "</option>\n                        <option value=\"Terms: Net 30\" data-item=\"Terms: Net 30\" selected=\"true\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net30",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":96},"end":{"line":52,"column":130}}}))
    + "</option>\n                        <option value=\"Terms: Net 60\" data-item=\"Terms: Net 60\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.due.net60",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":80},"end":{"line":53,"column":114}}}))
    + "</option>\n                    </select>\n                </div>\n         </div>\n\n        <div class=\"form-group bill-description-group\">\n            <label for=\"billDescription\" class=\"main-label\"><span>4</span>. "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":76},"end":{"line":59,"column":119}}}))
    + "</label>\n            <div class=\"field-container\">\n                <textarea rows=\"2\" class=\"form-control\" type=\"text\" maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"maxLength") || (depth0 != null ? lookupProperty(depth0,"maxLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"maxLength","hash":{},"data":data,"loc":{"start":{"line":61,"column":79},"end":{"line":61,"column":92}}}) : helper)))
    + "\" name=\"DESCRIPTION\" value=\"\" id=\"commentsText\" data-bind=\"model\"></textarea>\n                <div class=\"char-counter pull-right\" id=\"char-counter\"></div>\n            </div>\n        </div>\n\n        <div class=\"form-group approver-group\">\n            <label for=\"approver\" class=\"main-label\"><span>5</span>. "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.new.bill.question5",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":69},"end":{"line":67,"column":112}}}))
    + "</label>\n            <div class=\"field-container\">\n                <select class=\"form-control\" id=\"approver\" name=\"approver\" data-hook=\"select-approver\">\n                    <option value=\"\" selected=\"true\"></option>\n                    <option value=\"Approver 1\" data-item=\"Approver 1\">Approver 1</option>\n                    <option value=\"Approver 2\" data-item=\"Approver 2\">Approver 2</option>\n                    <option value=\"Approver 3\" data-item=\"Approver 3\">Approver 3</option>\n                    <option value=\"Approver 4\" data-item=\"Approver 4\">Approver 4</option>\n                </select>\n            </div>\n        </div>\n\n    <div class=\"payment-total-container\">\n        <h2><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.bill.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":80,"column":20},"end":{"line":80,"column":55}}}))
    + ":</strong> <span class=\"bill-total\">$0.00</span></h2>\n    </div>\n\n    </div>\n\n    </form>\n</div>\n";
},"useData":true});