var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"accountContainer\"></div>\n\n<div class=\"lbxAdd\">\n    <div class=\"btn-wrapper\">\n        <div class=\"btn-group widget-links\">\n            <button type=\"button\" class=\"btn btn-link export\" data-action=\"addAccount\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.AddAnotherAccount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":125},"end":{"line":6,"column":159}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});