import BaseLoanView from 'app/loans/views/baseLoanView';
import locale from '@glu/locale';
import $ from 'jquery';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import Loan from 'app/loans/models/modifyPayment';
import Balance from 'app/loans/models/balance';
import Details from 'app/loans/models/details';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import balanceTmpl from 'app/loans/views/balance.hbs';
import paymentTmpl from 'app/loans/views/payment/viewPayment/viewPayment.hbs';
import drawTmpl from 'app/loans/views/payment/viewPayment/viewLoanDraw.hbs';
import loans from 'app/loans/api/common';
import informationalMessage from 'common/util/informationalMessage';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import AuditHistorySetup from 'app/auditHistory/util/auditHistorySetup';
import { maskValue } from 'common/util/maskingUtil';
import systemConfig from 'system/configuration';

export default BaseLoanView.extend({
    initialize(optionsParam) {
        const options = optionsParam;
        this.template = options.typeCode === 'LOANPAY' ? paymentTmpl : drawTmpl;
        this.isViewWorkflow = true;
        this.buttonsToHide = optionsParam.buttonsToHide;
        /**
         * leaving this in for clarity as it allows for
         * extention of baseLoanView like in over views
         */
        options.model = new Loan(options.model);
        BaseLoanView.prototype.initialize.apply(this, [options]);
    },

    events: {
        'click @ui.$viewHistory': 'viewAuditHistory',
    },

    ui: util.extend(
        {},
        BaseLoanView.prototype.ui,
        {
            $accountName: '[name="accountName"]',
            $clientAccountName: '[name="clientAccountName"]',
            $bankName: '[name="bankName"]',
            $currency: '[name="currency"]',
            $creditBalance: '.to-account .balanceTotal',
            $debitBalance: '.from-account .balanceTotal',
            $debitAccount: '#debit-account',
            $creditAccount: '#credit-account',
            $transactionDate: '#credit-transaction-date',
            $appliesTo: '[name="apply-to"]',
            $creditAmount: '[name="CREDIT_AMOUNT"]',
        },
    ),

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            moveToTopCheck(this.model);
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData();
        } else {
            // needed to retrieve the balances
            this.setDebitDetails(this.model.get('DEBIT_ACCOUNT_NUMBER'));
            this.setCreditDetails(this.model.get('BENE_ACCOUNT'));
            if (this.paymentOptions.length === 1) {
                this.$el.find('#amount-label').text('Amount');
            }
            this.setPaymentMessage();
            // setup for audit history
            AuditHistorySetup.setup(this, this.model);
            this.displaySummaryTotal();

            /*
             * When veiwing a loan payment or loan draw, the loan account input is not
             * initialized as a combobox and thus never triggers the 'change' event when an
             * account is selected that would normally kick off the "onLoanAccountChanged"
             * function. Instead, we get the account details here manually.
             */
            this.getDebitAccountDetails(undefined, true);
            this.getLoanAccountDetails(undefined, undefined, true);
        }
    },

    setDebitDetails(id) {
        const self = this;
        // get the model from the collection by id
        const accountModel = this.debit.get(id);
        if (accountModel) {
            const currency = this.model.parse(accountModel.get('mapDataList')).Debit_Currency;
            this.details = new Details({
                inquiryId: this.debitInquiryId,
                typeCode: this.typeCode,
                account: accountModel.get('name'),
                filterParam: 'Credit',
                currency,
            });
            this.details.fetch({
                success(model) {
                    let queryResponse = model.get('queryResponse');
                    queryResponse = queryResponse.QueryData.queryRows[0].mapDataList;
                    const account = self.model.parse(queryResponse);
                    self.accountDetails.debit = account;
                    self.showBalance(accountModel.get('name'), self.typeCode, account.Debit_Bank_Code, account.Debit_Currency, self.ui.$debitBalance);
                },
            });
        }
    },

    setPaymentMessage() {
        const { model } = this;
        let pmtMessageKey = 'PAY.PMT.MESSAGE.';
        let pmtMessage = '';

        pmtMessageKey += model.get('PRODUCT');
        pmtMessageKey += '.';
        pmtMessageKey += model.get('FUNCTION');
        pmtMessageKey += '.';
        pmtMessageKey += model.get('TYPE');
        if (model.get('SUBTYPE')) {
            pmtMessageKey = `${pmtMessageKey}.${model.get('SUBTYPE')}`;
        }
        pmtMessage = locale.get(pmtMessageKey);

        pmtMessage = (pmtMessage.indexOf('??') === 0) ? '' : pmtMessage;

        if (pmtMessage !== '') {
            this.pmtMessageRegion.show(informationalMessage.showMessage(pmtMessage));
        }
    },

    setCreditDetails(id) {
        const self = this;
        // get the model from the collection by id
        const accountModel = this.credit.get(id);
        if (accountModel) {
            const currency = this.model.parse(accountModel.get('mapDataList')).Credit_Currency;
            this.details = new Details({
                inquiryId: this.creditInquiryId,
                typeCode: this.typeCode,
                account: accountModel.get('name'),
                filterParam: 'Debit',
                currency,
            });
            this.details.fetch({
                success(model) {
                    let queryResponse = model.get('queryResponse');
                    queryResponse = queryResponse.QueryData.queryRows[0].mapDataList;
                    const account = self.model.parse(queryResponse);
                    self.accountDetails.credit = account;
                    self.showBalance(accountModel.get('name'), self.typeCode, account.Bene_Bank_Code, account.Credit_Currency, self.ui.$creditBalance);
                },
            });
        }
    },

    showBalance(accountNumber, typeCode, bankCode, currency, selector) {
        const prefix = (selector === this.ui.$creditBalance) ? locale.get('common.remainingBalance') : locale.get('common.availableBalance');

        this.balance = new Balance({
            accountNumber,
            typeCode: this.typeCode,
            bankCode: this.bankCode,
            currency,
        });
        this.balance.fetch({
            success(model) {
                if (model.get('balance')) {
                    const jsonTmpl = {
                        text: prefix,
                        balance: model.get('balance'),
                        timestamp: model.get('balanceAsOf'),
                        currency: model.get('currency'),
                    };
                    $(selector).text(balanceTmpl(jsonTmpl));
                } else {
                    $(selector).text('');
                }
            },
        });
    },

    cancel() {
        window.history.back();
    },

    modify() {
        store.set('custom_modify_payment_model', this.model);
        this.navigateTo('PAYMENTS/modifyLoanPayment');
    },

    copyinst() {
        const storedModel = store.get('custom_view_payment_model');
        storedModel.action = 'COPY';
        storedModel.loadFromPayments = true;
        store.set('basic_payment_data', storedModel);
        this.navigateTo('PAYMENTS/addPayment');
    },

    maketmpl() {
        const storedModel = store.get('custom_view_payment_model');
        storedModel.action = 'COPY';
        storedModel.loadFromPayments = true;
        store.set('basic_template_data', storedModel);
        this.navigateTo('TEMPLATE/addTemplate');
    },

    reject() {
        const self = this;
        dialog.custom(new RejectDialog({
            model: this.model,
            store,
            detailPage: self,
        }));
    },

    delete() {
        const self = this;
        dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.delete'), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
            if (ok) {
                self.model.delete({
                    success(model, resp) {
                        store.set(`${self.contextKey}-alertMessage`, 'DELETE');
                        store.set(`${self.contextKey}-confirms`, resp);
                        self.cancel();
                    },

                    error() {
                        store.set(`${self.contextKey}-alertMessage`, 'DELETE error');
                        self.cancel();
                    },
                });
            }
        });
    },

    print() {
        const printModal = new PrintViewModal({
            exportModel: {
                outputFormat: 'PDF',
                pageType: 'LETTER',
                expData: 'transaction',
                detailReportId: systemConfig.isAdmin() ? 41001 : 40001,


                actionData: {
                    productCode: systemConfig.isAdmin() ? 'ADMPAY' : 'PAY',
                    functionCode: this.functionCode,
                    typeCode: this.typeCode,
                },

                searchFields: [{
                    fieldName: 'TNUM',
                    operator: 'IN',
                    fieldValue: [this.model.get('TNUM')],
                    dataType: 'number',
                }],
            },
        });
        dialog.custom(printModal);
    },

    unapprov() {
        const self = this;
        this.model.unapprove({
            success(model, resp) {
                store.set(`${self.contextKey}-alertMessage`, 'UNAPPROVE');
                store.set(`${self.contextKey}-confirms`, resp);
                self.cancel();
            },

            error() {
                store.set(`${self.contextKey}-alertMessage`, 'UNAPPROVE error');
                self.cancel();
            },
        });
    },

    approve() {
        const self = this;
        this.model.approve({
            success(model, resp) {
                store.set(`${self.contextKey}-alertMessage`, 'APPROVE');
                store.set(`${self.contextKey}-confirms`, resp);
                self.cancel();
            },

            error() {
                store.set(`${self.contextKey}-alertMessage`, 'APPROVE error');
                self.cancel();
            },
        });
    },

    viewAuditHistory(e) {
        e.stopImmediatePropagation();
        loans.openDialog(this);
    },

    getViewButtons(buttons) {
        // buttons VIEWSUMT and REPORT are from FILE payments (File Import)
        let addMe = false;

        let button = null;
        if (buttons) {
            for (let i = 0; i < buttons.length; i += 1) {
                button = buttons[i];
                switch (button.action) {
                case 'SELECT':
                case 'GETRATE':
                case 'TRADE':
                case 'VIEWSUMT':
                case 'REPORT':
                    addMe = false;
                    break;
                default:
                    addMe = true;
                    break;
                }
                if (addMe) {
                    this.buttonList.push({
                        action: button.action,
                    });
                }
            }
        }
    },

    displayCutoff() {
        const hidecutoff = serverConfigParams.get('hideCutoff');
        // if cutoff info does not exist, dont show
        if (!this.model.get('CUTOFF_INFO')) {
            return false;
        }
        // if cutoff infor does exist, but the config does not let it show, then dont show
        if (hidecutoff && hidecutoff.indexOf(this.model.get('TYPE')) >= 0) {
            return false;
        }
        // if view and status is not one of these.. dont show
        if ((['EN', 'IA', 'RT', 'HV'].indexOf(this.model.get('STATUS')) < 0)) {
            return false;
        }

        return true;
    },

    /**
     * @method getButtons
     * @returns {Array}
     * @description Formats the buttonList into an array of objects used to render
     * each button
     */
    getButtons() {
        const buttons = [];

        util.each(this.buttonList, (el, i) => {
            const btnActionLowercase = el.action.toLowerCase();
            const button = {
                btnText: locale.get(`button.${btnActionLowercase}`),
                btnClass: (i === 0 && btnActionLowercase !== 'delete' ? 'btn-primary' : 'btn-secondary'),
                btnAction: btnActionLowercase,
            };

            buttons[i] = button;
        });

        return buttons;
    },

    templateHelpers() {
        const self = this;
        return {
            hasInvoiceNumber: this.model.has('INVOICENUM') && !util.isEmpty(this.model.get('INVOICENUM')),
            hasStandardAmount: this.model.has('STANDARD_AMOUNT') && this.model.get('STANDARD_AMOUNT') !== '',
            hasInterestAmount: this.model.has('INTEREST_AMOUNT') && this.model.get('INTEREST_AMOUNT') !== '',
            hasPrincipalAmount: this.model.has('PRINCIPAL_AMOUNT') && this.model.get('PRINCIPAL_AMOUNT') !== '',
            hasEscrowAmount: this.model.has('ESCROW_AMOUNT') && this.model.get('ESCROW_AMOUNT') !== '',
            hasOtherAmount: this.model.has('OTHER_AMOUNT') && this.model.get('OTHER_AMOUNT') !== '',

            getDebitSubaccount: () => {
                const subaccountNumber = self.model.get('DEBIT_SUBACCOUNT_NUM');
                return util.isEmpty(subaccountNumber) ? '' : ` - ${subaccountNumber}`;
            },

            getBeneSubaccount: () => {
                const subaccountNumber = self.model.get('BENE_SUBACCOUNT_NUM');
                return util.isEmpty(subaccountNumber) ? '' : ` - ${subaccountNumber}`;
            },

            getAvailableBalance: '',
            getRemainingBalance: '',
            originLabel: loans.getOriginLabel(this.typeCode),
            destinationLabel: loans.getDestinationLabel(this.typeCode),

            savedOptions: () =>
                /*
                 * @TODO Add logic to only display the CREDIT_AMOUNT
                 *  when only one option is saved
                 */
                self.savedCollection.toJSON(),

            isPossibleDuplicate() {
                return self.model.get('POSSIBLEDUPLICATEFLAG') === '1';
            },

            getTitle: () => {
                self.title = (self.typeCode === 'LOANPAY') ? locale.get('loans.view.payment') : locale.get('loans.view.drawdown');
                return self.title;
            },

            hasMultipleOptions: (this.typeCode === 'LOANPAY'),
            approved: loans.extractApproversFromModel(this.model.toJSON()),
            unapproved: (this.model.get('UNAPPROVED_BY') !== ''),
            userRejected: (this.model.get('APPROVER_REJECTION_BY') !== ''),
            hasBeenModified: (this.model.get('MODIFIED_BY') !== ''),
            rejected: (this.model.get('REJECTION_TIMESTAMP') !== ''),
            confirmed: (this.model.get('FIRST_CONFIRMATION_TIMESTAMP') !== ''),
            buttons: this.getButtons.bind(this),

            getEntryMethodDescription() {
                const createdFrom = self.model.get('CREATEDFROM');
                const entryMethod = self.model.get('ENTRYMETHOD');
                const importId = self.model.get('IMPORTID');
                let entryMethodDesc = '';
                if (createdFrom && entryMethod === '1' && createdFrom === '2') {
                    entryMethodDesc = locale.get('PAY.EntryMethod.Schedule.1');
                } else if (importId && (entryMethod === '1' || entryMethod === '2')) {
                    entryMethodDesc = locale.get(`PAY.EntryMethod.${entryMethod}`);
                } else {
                    entryMethodDesc = importId ? locale.get('PAY.EntryMethod.3') : locale.get(`PAY.EntryMethod.${entryMethod}`);
                }
                return entryMethodDesc;
            },

            getAmountText: locale.get(`loans.${this.typeCode.toLowerCase()}.amount`),
            displayCutoff: this.displayCutoff(),

            maskIt: value => maskValue(value),

            hideCancelButton: () => (this.buttonsToHide ? util.contains(this.buttonsToHide, 'CANCEL') : false),

            hideReturnButton: () => (this.buttonsToHide ? util.contains(this.buttonsToHide, 'CANCEL') : false),
        };
    },
});
