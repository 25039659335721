import filterApi from 'common/dynamicPages/api/filters';
import util from '@glu/core/src/util';

export default {
    /**
     * Defines the list of DataTypes that are currently supported by the server code.
     */
    DataType: {
        TEXT: 'TEXT',
        DATE: 'DATE',
        NUMERIC: 'NUMERIC',
        AMOUNT: 'AMOUNT',
        GMTDATE: 'GMTDATE',
    },

    /**
     * Defines the list of Operators that are currently supported by the server code.
     */
    Operator: {
        EQ: 'EQ',
        NOTEQ: 'NOTEQ',
        GT: 'GT',
        LT: 'LT',
        GTEQ: 'GTEQ',
        LTEQ: 'LTEQ',
        IN: 'IN',
        NOTIN: 'NOTIN',
        BETWEEN: 'BETWEEN',
        CONTAINS: 'CONTAINS',
        LIKE: 'LIKE',
        STARTSWITH: 'STARTSWITH',
        NOTEQNULL: 'NOTEQNULL',
    },

    /**
     * This is convenience function that wraps the createSearchField function to
     * invoke with
     * dataType as DATE. The operator is either BETWEEN or EQ based on comparison
     * of the first 2
     * fieldValues passed to this function.
     * @param {string} fieldName Column Name to be searched on the server.
     * @param {string[]} formattedDateRange List of date values to be send to the server.
     * @returns {object} the searchField Object.
     */
    createDateRangeSearchField(fieldName, formattedDateRange) {
        const dateOperator = formattedDateRange[0] !== formattedDateRange[1]
            ? this.Operator.BETWEEN : this.Operator.EQ;
        return this.createSearchField(
            fieldName,
            formattedDateRange,
            this.DataType.DATE,
            dateOperator,
        );
    },

    /**
     * This is convenience function that wraps the createSearchField function to
     * invoke with
     * dataType as TEXT and operator as IN.
     * @param {string} fieldName Column Name to be searched on the server.
     * @param {string[]|number[]} fieldValue List of values to be send to the server.
     * @returns {object} the searchField Object.
     */
    createTextInSearchField(fieldName, fieldValue) {
        return this.createSearchField(
            fieldName,
            fieldValue,
            this.DataType.TEXT,
            this.Operator.IN,
        );
    },

    /**
     * This is convenience function that wraps the createSearchField function to
     * invoke with
     * dataType as TEXT and operator as BETWEEN.
     * @param {string} fieldName Column Name to be searched on the server.
     * @param {string[]|number[]} fieldValue List of values to be send to the server.
     * @returns {object} the searchField Object.
     */
    createTextBetweenSearchField(fieldName, fieldValue) {
        return this.createSearchField(
            fieldName,
            fieldValue,
            this.DataType.TEXT,
            this.Operator.BETWEEN,
        );
    },

    /**
     * This is convenience function that wraps the createSearchField function to
     * invoke with
     * dataType as NUMERIC and operator as IN.
     * @param {string} fieldName Column Name to be searched on the server.
     * @param {string[]|number[]} fieldValue List of values to be send to the server.
     * @returns {object} the searchField Object.
     */
    createNumericInSearchField(fieldName, fieldValue) {
        return this.createSearchField(
            fieldName,
            fieldValue,
            this.DataType.NUMERIC,
            this.Operator.IN,
        );
    },

    /**
     * SearchField object instance to be added to the list of SearchFields on
     * InquiryRequest,
     * LookupDataRequest, SaveProfileEntityRequest, ModifyProfileEntityRequest, GridView,
     * ListViewRequest, ListRequest, ChildListViewRequest.
     * @param {string} fieldName Column Name to be searched on the server.
     * @param {string|number|string[]|number[]} fieldValue - single value or list
     * of values to
     * be send to the server.
     * @param {string} [dataType=TEXT] - Optional data Type of the Values. Ideally should be
     * one of the DataType defined above.
     * @param {string} [operator=EQ] Optional operator type that will be used by
     * the Server to
     * generate part of the WHERE Clause. Ideally should be one of the Operator
     * defined above.
     * @returns {object} the searchField Object.
     */
    createSearchField(fieldName, fieldValue, dataType, operator) {
        const value = util.isArray(fieldValue) ? fieldValue : [fieldValue];
        return {
            fieldName,
            dataType: dataType || this.DataType.TEXT,
            operator: operator || this.Operator.EQ,
            fieldValue: value,
        };
    },

    /**
     * Converts the search fields array to filter string format
     * @param {array} searchFields - a list of search field objects
     * @return {string} - a formatted string
     */
    convertSearchFieldsToFilterFormat(searchFields) {
        const filters = [];
        util.each(searchFields, (searchField) => {
            let value = searchField.fieldValue;
            if (util.isArray(value)) {
                value = value.join('^');
            }
            const filter = `${searchField.fieldName}^${searchField.fieldName}^${filterApi.getFilterType(searchField.dataType)}^${searchField.operator}^${value}`;
            filters.push(filter);
        });
        return filters;
    },
};
