var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"btn btn-tertiary\" data-hook=\"insert-button\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"insert",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":15,"column":157},"end":{"line":15,"column":185}}}))
    + "</button>";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary approve\" data-action=\"approve\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"approve",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":30,"column":94},"end":{"line":30,"column":123}}}))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary unapprove\" data-action=\"unapprove\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"unapprove",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":33,"column":98},"end":{"line":33,"column":129}}}))
    + "</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary reject\" data-action=\"reject\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"reject",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":36,"column":92},"end":{"line":36,"column":120}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary delete\" data-action=\"delete\">"
    + container.escapeExpression((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"delete",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":39,"column":92},"end":{"line":39,"column":120}}}))
    + "</button>                \n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":52}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"recurring_section-header-list",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":57}}}))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n                <div class=\"btn-wrapper\">\n                        <div class=\"btn-group widget-links\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScheduleEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":201}}})) != null ? stack1 : "")
    + "\n                <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias3(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":77},"end":{"line":16,"column":103}}}))
    + "\" data-hook=\"export-button\"><span class=\"icon-export\"></span> "
    + alias3((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias2).call(alias1,"export",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":16,"column":165},"end":{"line":16,"column":193}}}))
    + "</button>\n                                <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias3(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":92},"end":{"line":17,"column":117}}}))
    + "\" data-hook=\"print-button\"><span class=\"icon-print\"></span> "
    + alias3((lookupProperty(helpers,"getButtonString")||(depth0 && lookupProperty(depth0,"getButtonString"))||alias2).call(alias1,"print",{"name":"getButtonString","hash":{},"data":data,"loc":{"start":{"line":17,"column":177},"end":{"line":17,"column":204}}}))
    + "</button>\n                        </div>\n            <div class=\"btn-group widget-info-links\">\n                <button type=\"button\" class=\"btn btn-tertiary refresh\" title=\""
    + alias3(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":78},"end":{"line":20,"column":105}}}))
    + "\" data-hook=\"refresh-button\"><span class=\"icon-refresh\"></span>\n                    <span class=\"refresh-time\" data-hook=\"refresh-time\"></span>\n                </button>\n            </div>\n                </div>\n\n                <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n\n        <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUnapproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":34,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRejectEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n    </div>\n</div>\n";
},"useData":true});