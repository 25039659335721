import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';
import Model from '@glu/core/src/model';

export default Collection.extend({
    model: Model.extend({
        idAttribute: 'INVOICENUM',

        defaults: {
            STANDARD_AMOUNT: '',
            PRINCIPAL_AMOUNT: '',
            ESCROW_AMOUNT: '',
            INTEREST_AMOUNT: '',
            OTHER_AMOUNT: '',
        },
    }),

    url: services.generateUrl('loan/getAcctType'),

    modelId(attrs) {
        return attrs.invoiceNumber;
    },

    initialize(options) {
        this.payload = {
            bankCode: options.bankCode,
            accountNumber: options.accountNumber,
            currency: options.currency,
            subAccountNum: options.subAccountNumber,
            accountFilter: options.BENE_ACCOUNTENTITLEMENT,
            productCode: 'RTGS',
            functionCode: 'INST',
            typeCode: 'LOANPAY',
        };
        this.creditCurrency = options.CREDIT_CURRENCY;
        this.existingInvoiceNumber = options.invoiceNum || false;
    },

    sync(method, model, options) {
        http.post(services.generateUrl('loan/getAcctType'), this.payload, (response) => {
            options.success(response);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        this.status = response.status;
        this.accountType = response.accountType;
        // transform the invoice object key names to match what the loan model currently expects
        return util.map(response.invoices, (invoice) => {
            const formattedInvoice = util.extend(
                invoice,
                {
                    STANDARD_AMOUNT: invoice.standardAmount || '',
                    PRINCIPAL_AMOUNT: invoice.principalAmount || '',
                    ESCROW_AMOUNT: invoice.escrowAmount || '',
                    INTEREST_AMOUNT: invoice.interestAmount || '',
                    OTHER_AMOUNT: invoice.otherAmount || '',
                    CREDIT_AMOUNT: invoice.totalAmount,
                    DISPLAY_AMOUNT: Formatter.formatCurrency(invoice.totalAmount),
                    CREDIT_CURRENCY: this.creditCurrency,
                    INVOICENUM: invoice.invoiceNumber,
                },
            );
            this.removeInvalidKeys(formattedInvoice);

            return formattedInvoice;
        });
    },

    /**
     * @method removeInvalidKeys
     * @param dataParam
     * description after upper casing the five keys below, the original keys are
     * unwanted, and cause errors at the
     * rest service when the backend computes the amount
     */
    removeInvalidKeys(dataParam) {
        // TODO: We should be able to use omit instead of deleting keys
        const data = dataParam;
        delete data.standardAmount;
        delete data.principalAmount;
        delete data.interestAmount;
        delete data.escrowAmount;
        delete data.otherAmount;
        delete data.invoiceNumber;
        delete data.totalAmount;
    },

    /**
     * @method toFlexDropdataFormat
     * @returns {{data: *, preSelectedIds: Array}}
     * @description converts the collection models into a format accepted by
     * FlexDropdown
     */
    toFlexDropdataFormat() {
        const flexData = util.map(this.models, model => util.extend(
            model.toJSON(),
            {
                id: model.get('INVOICENUM'),
                name: `Invoice #${model.get('INVOICENUM')} - ${model.get('DISPLAY_AMOUNT')} ${model.get('CREDIT_CURRENCY')}`,
                model,
            },
        ));
        let preSelectedIds = flexData.length > 0 ? [flexData[0].id] : [];
        if (this.existingInvoiceNumber) {
            preSelectedIds = [this.existingInvoiceNumber];
        }
        return {
            data: flexData,
            preSelectedIds,
        };
    },
});
