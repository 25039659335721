import Model from '@glu/core/src/model';

const GroupPlanItemModel = Model.extend({
    defaults: {
        // the id for the group plan this item belongs to
        groupPlanId: null,

        groupPlanVersion: 0,
        lineId: -1,
        assignedTo: null,
        status: '',
        subPlanName: '',
        savedOn: null,
        assignedToName: '',
        multiYearSubPlanId: null,
        subPlanVersion: 0,
    },

    initialize() {

    },
});

export default GroupPlanItemModel;
