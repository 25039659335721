import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckboxRadio } from '@glu/form-components';

const propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
};

const defaultProps = {
    onChange: () => {},
    checked: false,
};

const StatefulCheckbox = ({
    onChange,
    checked,
    ...props
}) => {
    const [isChecked, toggleChecked] = useState(checked);
    const onSwitchChange = (name, value) => {
        onChange(name, value);
        toggleChecked(value);
    };
    return (
        <CheckboxRadio
            {...props}
            checked={isChecked}
            onChange={onSwitchChange}
        />
    );
};
StatefulCheckbox.propTypes = propTypes;
StatefulCheckbox.defaultProps = defaultProps;
export default StatefulCheckbox;
