var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":144},"end":{"line":5,"column":185}}}))
    + "</span></a>\n    </div>\n\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.employee.reimbursement",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":31},"end":{"line":8,"column":78}}}))
    + "</h1>\n\n    <div data-section=\"payment-summary\" class=\"amount-wrapper hidden\">\n        <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.payment.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":37},"end":{"line":11,"column":75}}}))
    + "</p>\n        <span data-field=\"summary-amount\" class=\"amount-value-text payment-total\">0.00</span> <span data-field=\"summary-currency\" class=\"amount-value-text payment-total\"></span>\n    </div>\n\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-summary\">\n    <div data-section=\"payment-summary\" class=\"section-header hidden\">\n        <div data-hook=\"payment-summary\" data-region=\"summaryTotalRegion\" />\n    </div>\n    <div data-section=\"save-as-template\"></div>\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">       \n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":39,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnClass") || (depth0 != null ? lookupProperty(depth0,"btnClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnClass","hash":{},"data":data,"loc":{"start":{"line":33,"column":35},"end":{"line":33,"column":47}}}) : helper)))
    + "\"\n                    data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":34,"column":33},"end":{"line":34,"column":46}}}) : helper)))
    + "\"\n                    name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"btnAction") || (depth0 != null ? lookupProperty(depth0,"btnAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnAction","hash":{},"data":data,"loc":{"start":{"line":35,"column":26},"end":{"line":35,"column":39}}}) : helper)))
    + "\"\n                    type=\"button\">\n                    <span class=\"txt\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"btnText") || (depth0 != null ? lookupProperty(depth0,"btnText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data,"loc":{"start":{"line":37,"column":38},"end":{"line":37,"column":49}}}) : helper)))
    + "</span>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "<div class=\"alert-message\">\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    <div data-region=\"pmtMessageRegion\"></div>\n</div>\n\n<div data-region=\"pageContent\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":43,"column":11}}})) != null ? stack1 : "");
},"useData":true});