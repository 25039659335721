import InquiryApi from 'system/webseries/api/inquiry';
import InquiryQueryResults from 'system/webseries/collections/inquiryQueryResults';
import Model from '@glu/core/src/model';

export default Model.extend({
    parseDate(date) {
        let localDate = date;
        localDate = localDate.substring(0, 10).split('-');
        return `${localDate[1]}-${localDate[2]}-${localDate[0]}`;
    },

    convertServerJSONToModelAttributes(data) {
        const parsedData = {};
        for (let x = 0; x < data.length; x += 1) {
            parsedData[data[x].name] = data[x].value;
        }
        return parsedData;
    },

    getLoanPaymentPromises(currency) {
        // get debit accounts
        const debitAccountPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                includeMapData: InquiryApi.INCLUDE_MAP_DATA,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'Credit_Currency',
                        currency,
                    ],
                }],

                inquiryId: 19533,
                typeCode: 'LOANPAY',
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get credit accounts
        const creditAccountPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                includeMapData: InquiryApi.INCLUDE_MAP_DATA,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'Debit_Currency',
                        'NONE',
                    ],
                }],

                inquiryId: 19534,
                typeCode: 'LOANPAY',
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        return {
            debit: debitAccountPromise,
            credit: creditAccountPromise,
        };
    },

    getLoanDrawPromises(currency) {
        // get debit accounts
        const debitAccountPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                includeMapData: InquiryApi.INCLUDE_MAP_DATA,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'Credit_Currency',
                        currency,
                    ],
                }],

                inquiryId: 19535,
                typeCode: 'LOANDRAW',
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        // get credit accounts
        const creditAccountPromise = new Promise((resolve, reject) => {
            new InquiryQueryResults({
                includeMapData: InquiryApi.INCLUDE_MAP_DATA,

                customFilters: [{
                    filterName: 'Depends',

                    filterParam: [
                        'Debit_Currency',
                        'NONE',
                    ],
                }],

                inquiryId: 19536,
                typeCode: 'LOANDRAW',
                productCode: InquiryApi.TRANSFER_PRODUCT_CODE,
                functionCode: InquiryApi.TRANSFER_FUNCTION_CODE,
            }).fetch({
                success: resolve,
                error: reject,
            });
        });

        return {
            debit: debitAccountPromise,
            credit: creditAccountPromise,
        };
    },

    addValidator(key, value) {
        this.validators[key] = value;
    },

    removeValidator(key) {
        delete this.validators[key];
    },

    createParameters() {
        return [{
            toField: 'Number_OBI_Lines_Loaded',
            value: '0',
        }, {
            toField: '_subTypeCode',
            value: '*',
        }, {
            toField: '_mode',
            value: 'INSERT',
        }, {
            toField: 'showUserDefinedFieldsTab',
            value: 'FALSE',
        }, {
            toField: 'payMethod',
            value: 'RTGS',
        }, {
            toField: 'beneLookupOnly',
            value: 'false',
        }, {
            toField: '_actionMode',
            value: 'INSERT',
        }, {
            toField: '_formName',
            value: 'detail',
        }, {
            toField: '_fromFunction',
            value: 'INST',
        }, {
            toField: '_Entered_Amount_Flag',
            value: '0',
        }, {
            toField: 'Exchange_Rate',
            value: '1',
        }, {
            toField: 'Entered_Amount_Flag',
            value: 'C',
        }, {
            toField: 'ExchangeRateTBD_Flag',
            value: 'N',
        }, {
            toField: 'MARKETCONVENTION',
            value: 'D',
        }];
    },

    /**
     *   Adds the two different parameter objects to the array for loans
     */
    createLoanParameters() {
        const loanParameters = this.createParameters();
        loanParameters.push({
            toField: 'UpdateCount__',
            value: '0',
        }, {
            // DIFFERENT
            toField: '_saveIncomplete',
            value: 'false',
        });
        return loanParameters;
    },
});
