import services from 'services';
import userInfo from 'etc/userInfo';
import transform from 'common/util/transform';
import locale from '@glu/locale';
import moment from 'moment';
import http from '@glu/core/src/http';
import constants from 'common/dynamicPages/api/constants';
import btrConstants from 'app/balanceAndTransaction/constants';
import util from '@glu/core/src/util';
import dateRangeUtil from '../api/dateRangeUtil';
import AccountFilterParent from './accountFilterParent';

const CombinedAccountFilter = AccountFilterParent.extend({
    initialize(options) {
        this.listenTo(
            this,
            {
                'change:accountSelectBy': this.resetOptionsList,
                'change:accountSelect': this.enforceAccountSelectRules,
                'change:accountFilterOptions': this.updateAccountOptionList,
            },
        );

        // Expose this in case we need to wait for all the loading.
        this.initPromise = Promise.all([
            this.fetchAccountFilterOptions({
                selectBy: this.get('accountSelectBy'),
                rowsPerPage: options.rowsPerPage,
            }),
            this.getTypeAheadFieldData(),
        ]);
    },

    defaults() {
        return {
            START_DATE: moment(new Date()).subtract(6, 'day').format(userInfo.getDateFormat()),
            END_DATE: moment(new Date()).format(userInfo.getDateFormat()),
            accountList: [],
            accountFilterOptions: [],
            accountGroupAccountList: [],
            accountSelectBy: 'number',
            accountTransactionsView: 'single',
            accountSelect: ['all'],
            Accounts: ['all'],
            CUST_REF: '',
            AMOUNT: '',
            BANK_REF: '',
            POSTED_FLAG: '',
            DR_CR: '',
            BAI_CODE: '',
            BAI_GROUP_CODE: '',
            SWIFT_TYPE_CODE: '',
            canDelete: true,
            functionCode: 'PRO',
            productCode: 'GIR',
            typeCode: 'GIRACCT',
        };
    },

    /**
     * @method enforceAccountSelectRules
     * @param {Model} model
     * @param {array} value
     * @param {object} options
     * `accountSelect` property change handler, will enforce
     * that "all" is chosen if options are cleared,
     * or clear out all other selections if all is chosen
     */
    enforceAccountSelectRules(model, value, options) {
        const accountSelect = this.get('accountSelect');
        if ((options && options.enforced) || accountSelect.length === 1) {
            return;
        }

        const allIndex = accountSelect.indexOf('all');
        let enforcedVal = [];

        if (accountSelect.length !== 0) {
            if (allIndex === accountSelect.length - 1 || !accountSelect.length) {
                // if all was last selected, or no values selected, default to "all"
                enforcedVal = ['all'];
            } else {
                // if not, clear out all, and leave other values
                enforcedVal = util.without(accountSelect, 'all');
            }
        }

        this.set(
            'accountSelect',
            enforcedVal,
            {
                enforced: true,
            },
        );
    },

    /**
     * @param {object} options
     */
    fetchAccountFilterOptions(options) {
        let localOptions = options;
        localOptions = localOptions || {};
        const optionsByType = btrConstants.CUSTOM_REPORT_SELECT[localOptions.selectBy];
        const data = {
            rowsPerPage: localOptions.rowsPerPage || 10,
            startRow: localOptions.startRow || 1,
            fieldName: optionsByType.fieldName,
            typeCode: 'GIRACCT',
            productCode: 'GIR',
            functionCode: 'INST',
            entryClass: null,
            depends: [],

            requestParameters: {
                item: [{
                    name: 'PRODUCTCODE',
                    value: 'GIR',
                }, {
                    name: 'FUNCTIONCODE',
                    value: 'INST',
                }, {
                    name: 'TYPECODE',
                    value: 'GIRACCT',
                }, {
                    name: 'INQUIRYID',
                    value: optionsByType.inquiryID,
                }],
            },

            searchFields: [{
                fieldName: 'ACCOUNTFILTER',
                operator: 'CONTAINS',
                fieldValue: [localOptions.queryTerm || ''],
                dataType: 'text',
            }],
        };

        const url = services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION);

        return new Promise((resolve, reject) => http.post(url, data, (response) => {
            const filters = util.map(response.rows, (row) => {
                const columns = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
                // use group_name as value if account filtered by groups
                return {
                    id: columns[optionsByType.fieldName],
                    text: columns[optionsByType.fieldLabel]?.replace(/&amp;/g, '&'),
                };
            });

            if (data.startRow === 1) {
                filters.unshift({
                    id: 'all',
                    text: locale.get(optionsByType.defaultKey),
                });
            }

            if (localOptions.selectBy === 'group') {
                this.set('accountGroupAccountList', this.parseAccountGroupsList(response.rows));
            }

            this.set('accountFilterOptions', filters);

            /* trigger event to set the 'Accounts' attribute in the model
               the Accounts needs to be set after the getLookupResults
               resolved so we can set the combo correctly,
               i.e. it shows the correct display label in the combo
               the event should only be handled after the call to getListViewFilterDetails
            */
            this.trigger('getLookupResults:success');

            return resolve({
                collection: filters,
                totalRows: response.totalRows,
            });
        }, (err) => {
            reject(err);
        }));
    },

    /**
     * @method parseAccountGroupsList
     * @param {array} rows
     * @return {object} Returns Key value pairs of all account group options and
     * the account numbers involved
     */
    parseAccountGroupsList(rows) {
        let all = [];
        const accountGroupsList = rows
            .reduce((arr, row) => {
                const [rowData] = row.mapDataList;
                const rowName = row.columns[2].fieldValue;

                // split values to get individual account numbers
                const accountNums = rowData.value.split('^');

                // merge all nums together for the all filter
                all = all.concat(accountNums);

                // create list of account nums for each group
                return Object.assign(arr, { [rowName]: accountNums });
            }, {});

        accountGroupsList.all = all;
        return accountGroupsList;
    },

    getTypeAheadFieldData() {
        this.fieldData = [{
            field: 'BAI_GROUP_CODE_DATA',
            inquiryId: 22012,
        }, {
            field: 'BAI_CODE_DATA',
            inquiryId: 22310,
        }, {
            field: 'SWIFT_TYPE_CODE_DATA',
            inquiryId: 22312,
        }];
        AccountFilterParent.prototype.getTypeAheadFieldData();
    },

    updateAccountOptionList() {
        const newList = this.get('accountFilterOptions');
        this.set(
            'accountList',
            util.chain(this.get('accountList'))
                .union(newList)
                .uniq()
                .value(),
        );
    },

    resetOptionsList() {
        const optionsByType = btrConstants.CUSTOM_REPORT_SELECT[this.get('accountSelectBy')];
        this.set({
            accountList: [{
                id: 'all',
                text: locale.get(optionsByType.defaultKey),
            }],
        });

        this.fetchAccountFilterOptions({ selectBy: this.get('accountSelectBy') });
    },

    getSearchFields() {
        let startDate = this.get('START_DATE');
        let endDate = this.get('END_DATE');
        let fieldName;

        if (endDate.length < 1) {
            startDate = dateRangeUtil.convertCodesToDates(startDate);
            endDate = moment(new Date()).format(userInfo.getDateFormat());
        }

        const searchFields = [{
            fieldName: 'start_date',
            fieldValue: [startDate],
            dataType: 'date',
            operator: '>=',
        }, {
            fieldName: 'end_date',
            fieldValue: [endDate],
            dataType: 'date',
            operator: '<=',
        }];

        // if accountSelect is all or blank, do not add this to the filter
        if (util.isArray(this.get('accountSelect')) && this.get('accountSelect').indexOf('all') === -1 &&
            this.get('accountSelect').length !== 0) {
            if (this.get('accountSelectBy') === 'number') {
                fieldName = 'accountFilter';
            } else if (this.get('accountSelectBy') === 'group') {
                fieldName = 'account_group_name';
            } else if (this.get('accountSelectBy') === 'currency') {
                fieldName = 'currency_code';
            } else {
                fieldName = 'bank_code';
            }
            searchFields.push({
                fieldName,
                fieldValue: this.get('accountSelect'),
                operator: 'IN',
                dataType: 'text',
            });
        }
        return searchFields;
    },
});

export default CombinedAccountFilter;
