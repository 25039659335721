var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"section section-container col-md-12\">\n        <div class=\"section-header\">\n            <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"user.otherCompanyInformation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":61}}}))
    + "</legend>\n        </div>\n        <div class=\"form-column PTXGroup_user col-md-6\">\n            <h3 class=\"form-group-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"user.ptxInformation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":73}}}))
    + "</h3>\n            <legend></legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"text-group field-container-lg field-container\">\n                    <label>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"user.documentRole",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":27},"end":{"line":12,"column":57}}}))
    + "</label>\n                    <br>\n                    <span name=\"_CLFLDS_PTXRoleId\"></span>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-region=\"pageContent\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":19,"column":7}}})) != null ? stack1 : "");
},"useData":true});