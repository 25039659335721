import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    initialize(options) {
        if (options) {
            if (options.text) {
                this.set('id', options.text);
                this.set('originalName', options.text);
            }
            if (options.type) {
                switch (options.type) {
                case 'transactions':
                    this.service = '/tableMaintenance/lockboxProfilesTran';
                    this.selections = options.selections;
                    break;
                case 'lockbox':
                    this.service = '/tableMaintenance/lockboxProfilesLock';
                    this.selections = options.selections;
                    break;
                default:
                    this.service = '/tableMaintenance/lockboxProfiles';
                }
            }
        } else {
            this.service = '/tableMaintenance/lockboxProfiles';
        }
    },

    sync(method, model, options) {
        this.unset('type');
        this.unset('selections');

        if (method === 'update') {
            // sending id into the request causing an error
            model.unset('id');
        }

        let url;
        const nameValuePairs = transform.hashToPairs(model.toJSON());

        let data = {
            item: nameValuePairs,
        };

        if (method === 'create') {
            url = services.generateUrl(`${this.service}/add`);

            if (this.service !== '/tableMaintenance/lockboxProfiles') {
                data = {
                    item: nameValuePairs.concat(this.selections),
                };
            }

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'update') {
            url = services.generateUrl(`${this.service}/update`);

            if (this.service !== '/tableMaintenance/lockboxProfiles') {
                data = {
                    item: nameValuePairs.concat(this.selections),
                };
            }

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            url = services.generateUrl(`${this.service}/delete`);

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    reportID() {
        const confirmItems = this.attributes.confirms.confirmResults[0].confirmData[0].item;
        return util.findWhere(
            confirmItems,
            {
                name: 'REPORT_ID',
            },
        ).value;
    },
});
