import ItemView from '@glu/core/src/itemView';
import bankMaintainedNoteViewTmpl from './bankMaintainedNoteView.hbs';

export default ItemView.extend({
    template: bankMaintainedNoteViewTmpl,

    modelEvents: {
        change: 'render',
    },

    ui: {
        noteButton: '.bankmaintained-note-button',
    },

    onRender() {
        if (this.ui.noteButton) {
            this.ui.noteButton.popover({
                trigger: 'hover focus',
            });
        }
    },
});
