import AuditHistoryList from 'app/auditHistory/views/auditHistoryList';

export default {
    /**
     * Method to build options for the related data grid
     * @param {Object} options - Options to cascade to related grid options
     * @returns {Object}
     */
    buildRelatedGridOps(options) {
        return {
            optsAlreadyBuilt: true,

            opts: {
                isInquiry: true,
                context: 'DETAIL_AUDIT_INFO',

                inquirySearchCriteria: {
                    searchType: '5',
                    inquiryId: options.inquiryId,

                    searchFields: [],
                    customFilters: [
                        {
                            filterName: 'Depends',
                            filterParam: ['contactId', options.tnum],
                        },
                        {
                            filterName: 'Depends',
                            filterParam: ['allowOrderingParty', options.allowOrderingParty],
                        },
                    ],
                },

                filter: false,
                skipEntitlements: true,
                selector: 'none',
                enableSavedViews: false,
                hideGridActionButtons: true,
            },
        };
    },

    /**
     * Sets the listener to create a grid when a collapsible section is shown.
     * @param {Object} options - The options for the related grid
     * @param {Object} options.$container - jQuery object for container
     * @param {Object} options.gridRegion - Related data grid region
     * @param {number} options.inquiryId
     * @param {string} options.tnum
     */
    setRelatedGridDataListener(options) {
        // This need to be when the panel is 'shown' so that the grid can render properly
        options.$container.one('shown.bs.collapse', () => {
            this.showGrids(options);
        });
    },

    /**
     * create and show grid(s) when a collapsible section is shown.
     * @param {Object} options - The options for the related grid
     * @param {Object} options.$container - jQuery object for container
     * @param {Object} options.gridRegion - Related data grid region
     * @param {number} options.inquiryId
     * @param {string} options.tnum
     * @param {string} options.templateGridOptions --
     * an extra options object for another grid with the above params
     */
    showGrids(options) {
        if (options.gridRegion) {
            options.gridRegion.show(new AuditHistoryList(this.buildRelatedGridOps(options)));
        }
        if (options.templateGridOptions) {
            const ops = options.templateGridOptions;
            if (ops.gridRegion) {
                ops.gridRegion.show(new AuditHistoryList(this.buildRelatedGridOps(ops)));
            }
        }
    },
};
