import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';
import { Button, SECONDARY, SMALL } from '@glu/buttons-react';
import { FilterAccordionWrapper } from '@glu/data-components';

import PanelWrapper from '../PanelWrapper/PanelWrapper';
import styles from './FilterPanel.styles';

const FilterPanel = ({
  classes,
  columns,
  closeDrawer,
  getFilterComponent,
  filters,
  onSave
}) => {
  const [filterState, setFilterState] = useState(
    columns.reduce(
      (obj, { field }) => ({
        ...obj,
        ...(filters[field] && { [field]: filters[field] || '' })
      }),
      {}
    )
  );

  const handleFilterSave = useCallback(() => {
    onSave(filterState);
  }, [onSave, filterState]);

  const handleClose = useCallback(() => {
    setFilterState(filters);
    closeDrawer();
  }, [closeDrawer, filters]);

  const handleChange = useCallback((name, value) => {
    setFilterState(o => ({ ...o, [name]: value }));
  }, []);

  const handleClear = useCallback(name => {
    setFilterState(oldState => Object.keys(oldState || {}).reduce((newState, key) => {
      if (key === name) {
        return newState;
      }
      return { ...newState, [key]: oldState[key] };
    }, {}));
  }, []);

  useEffect(() => {
    const newState = columns.reduce(
      (obj, { field }) => ({
        ...obj,
        ...(filters[field] && { [field]: filters[field] || '' })
      }),
      {}
    );
    setFilterState(newState);
  }, [columns, filters]);

  return (
    <PanelWrapper
      title={locale.get('filters')}
      footer={(
        <>
          <Button
            text={locale.get('apply')}
            size={SMALL}
            className={classes.mobileButtons}
            onClick={handleFilterSave}
          />
          <Button
            type="button"
            variant={SECONDARY}
            size={SMALL}
            className={classes.mobileButtons}
            onClick={handleClose}
            text={locale.get('cancel')}
          />
        </>
      )}
    >
      {columns.map(column => {
        const { field, hide, headerName } = column;
        return (
          <FilterAccordionWrapper
            key={column.field}
            htmlId={`filterField-${column.field}`}
            fieldId={field}
            label={headerName}
            hiddenField={hide}
            hasFilter={!!filterState[field]}
            onClearFilter={handleClear}
            FilterComponent={getFilterComponent}
            value={filterState[field]}
            onChange={handleChange}
            dark={false}
            column={column}
            name={field}
          />
        );
      })}
    </PanelWrapper>
  );
};

FilterPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDrawer: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  getFilterComponent: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      hide: PropTypes.bool,
      headerName: PropTypes.string,
      field: PropTypes.string.isRequired
    })
  ).isRequired
};

FilterPanel.defaultProps = {
  filters: {}
};

export default withStyles(styles)(FilterPanel);
