import { createUseStyles } from '@glu/theming';

const styles = ({ fileUploadReact }) => ({
  root: {
    background: fileUploadReact.background,
    border: `1px dashed ${fileUploadReact.border}`,
    fontFamily: fileUploadReact.bodyFont,
    padding: [0, 20],
    color: fileUploadReact.textColor,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& form': {
      height: 0
    }
  },
  uploadMessage: {
    fontSize: 14,
    lineHeight: 1.2,
    margin: 0,
    width: '100%',
    fontWeight: 400,
    textAlign: 'center'
  },
  uploadButton: {
    appearance: 'none',
    color: fileUploadReact.buttonColor,
    padding: 0,
    background: 'transparent',
    margin: [0, 5],
    border: 'none',
    fontSize: 16,
    fontFamily: fileUploadReact.bodyFont,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:focus': {
      outlineColor: fileUploadReact.focusColor
    }
  },
  uploadRules: {
    fontSize: 12,
    lineHeight: 1.2,
    textAlign: 'center',
    margin: [5, 0, 0, 0]
  },
  icon: {
    margin: [0, 10, -2, 0],
    '& svg': {
      fill: fileUploadReact.iconColor,
      height: 16
    }
  },
  disabled: {
    color: fileUploadReact.disabledColor,
    cursor: 'not-allowed',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  highlight: {
    background: fileUploadReact.highlightBackground
  },
  fileInput: {
    'input[type="file"]&': {
      display: 'none'
    }
  }
});

export default createUseStyles(styles);
