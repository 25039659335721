var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\"\n            data-action=\"cancel\"\n            href=\"#\">\n            <span class=\"icon-arrow-left\"></span> &nbsp;\n            <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":84}}}))
    + "</span>\n        </a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.import.vendor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":61}}}))
    + "</h1>\n</div>\n\n<section class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n        <form enctype=\"multipart/form-data\"\n            id=\"importform\"\n            method=\"POST\"\n            name=\"importform\"\n            target=\"_blank\">\n            <section class=\"section section-container\">\n                <div class=\"row\">\n                    <div class=\"form-column col-md-12\">\n                        <h4 class=\"form-group-label \" role=\"presentation\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.file.section",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":74},"end":{"line":26,"column":103}}}))
    + "</h4>\n                        <div class=\"required field-container-lg field-container\">\n                            <label for=\"FILESELECTION\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sso.select.importfile",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":55},"end":{"line":28,"column":89}}}))
    + "</label>\n                            <input class=\"form-control \"\n                                data-bind=\"model\"\n                                id=\"FILESELECTION\"\n                                maxlength=\"255\"\n                                name=\"FILESELECTION\"\n                                type=\"file\"\n                                value=\"\">\n                            <span class=\"help-block\"\n                                data-bind=\"model\"\n                                data-validate=\"FILESELECTION\">\n                            </span>\n                            <div class=\"progress hide\">\n                                <div aria-valuenow=\"0\"\n                                    aria-valuemin=\"0\"\n                                    aria-valuemax=\"100\"\n                                    class=\"progress-bar progress-bar-striped active\"\n                                    role=\"progressbar\">\n                                    0%\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </section>\n        </form>\n        <div class=\"row\">\n            <button class=\"btn btn-primary\"\n                data-hook=\"getSubmit\"\n                type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.import",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":30},"end":{"line":57,"column":56}}}))
    + "\n            </button>\n            <button class=\"btn btn-tertiary\"\n                data-hook=\"getCancel\"\n                type=\"button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":30},"end":{"line":61,"column":68}}}))
    + "\n            </button>\n        </div>\n    </div>\n</section>\n";
},"useData":true});