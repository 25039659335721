import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import template from './dataCard.hbs';

export default ItemView.extend({
    tagName: 'li',
    ui: {
        $popovers: '[data-toggle="popover"]',
        $icon: '.icon-info',
    },
    template,
    className: 'dataCard',
    onRender() {
        // enable the popovers
        this.$('[data-toggle="popover"]').popover();

        /**
         * HACK - 30315
         * When flexbox wraps the second Total value, I need to change
         * the seperators. but there's no way of doing this with just CSS.
         * Also, the onRender method is firing before the dom actually
         * renders (hence the delay)
         */
        util.defer((scope) => {
            const $list = scope.$('.dataCard__list');
            const $listItems = scope.$('.dataCard__total');
            if ($list.length && $listItems.length) {
                if ($list.height() > $listItems.first().outerHeight(true)) {
                    $listItems.eq(1).addClass('dataCard__total--wrapped');
                }
            }
        }, this);
    },
});
