const opposites = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right'
};
const getDistance = property => ({ anchorTo }) => (anchorTo === opposites[property] ? 'auto' : 0);
const expandVertical = anchorTo => anchorTo === 'top' || anchorTo === 'bottom';

export default {
  root: {
    top: getDistance('top'),
    right: getDistance('right'),
    bottom: getDistance('bottom'),
    left: getDistance('left'),
    fillOpacity: 0,
    height: ({ startingSize, anchorTo }) => (expandVertical(anchorTo) ? startingSize : undefined),
    opacity: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
    position: 'fixed',
    transition: ({ transitionMode, collapseDuration, anchorTo }) => [
      `${expandVertical(anchorTo) ? 'height' : 'width'} ${collapseDuration}s ${transitionMode}`,
      `background ${collapseDuration}s ${transitionMode}`,
      `opacity 0s ${transitionMode} ${collapseDuration}s`,
      `fill-opacity 0s ${transitionMode} ${collapseDuration}s`,
      `visibility ${collapseDuration}s`
    ].join(', '),
    visibility: 'hidden',
    width: ({ startingSize, anchorTo }) => (expandVertical(anchorTo) ? undefined : startingSize),
    zIndex: 100,

    '&:before': {
      borderRadius: 'inherit',
      content: '""',
      display: 'block',
      height: '100%',
      left: 0,
      opacity: 0,
      position: 'absolute',
      top: 0,
      transition: ({ collapseDuration, transitionMode }) => `opacity ${collapseDuration}s ${transitionMode}`,
      width: '100%',
      zIndex: -100
    }
  },

  open: {
    fillOpacity: 1,
    height: ({ size, anchorTo }) => (expandVertical(anchorTo) ? size : undefined),
    maxHeight: '100vh',
    maxWidth: '100vw',
    opacity: 1,
    pointerEvents: 'inherit',
    transition: ({ expandDuration, transitionMode, anchorTo }) => [
      `${expandVertical(anchorTo) ? 'height' : 'width'} ${expandDuration}s ${transitionMode}`,
      `background ${expandDuration}s ${transitionMode}`,
      `visibility ${expandDuration}s`
    ].join(', '),
    visibility: 'visible',
    width: ({ size, anchorTo }) => (expandVertical(anchorTo) ? undefined : size),

    '&:before, & $content': {
      fillOpacity: 1,
      opacity: 1
    },

    '& $content': {
      transition: ({ expandDuration, transitionMode }) => [
        `opacity ${expandDuration / 2}s ${transitionMode} ${expandDuration / 2}s`,
        `fill-opacity ${expandDuration / 2}s ${transitionMode} ${expandDuration / 2}s`
      ].join(', ')
    }
  },

  content: {
    opacity: 0,
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    transition: ({ collapseDuration, transitionMode }) => [
      `opacity ${collapseDuration / 2}s ${transitionMode}`,
      `fill-opacity ${collapseDuration / 2}s ${transitionMode}`
    ].join(', ')
  }
};
