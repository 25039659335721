const externalClasses = {
    accountManagementStyles: {
        'account-management': 'pmx-account-management',
    },

    addAccountFlowStyles: {
        'add-account-flow': 'pmx-add-account-flow',
        'side-info-panel': 'pmx-side-info-panel',
    },

    remittanceAddressSectionStyles: {
        'remittance-address-info': 'pmx-remittance-address-info',
    },

    vendorNetworkWidgetStyles: {
        'paymode-x-vendor-network': 'pmx-paymode-x-vendor-network',
    },

    payerDividendsStyles: {
        'payer-dividend': 'pmx-payer-dividend',
    },

    bankAccountReconciliationStyles: {
        'bank-reconciliation': 'pmx-bank-reconciliation',
    },
};

export default externalClasses;
