import Layout from '@glu/core/src/layout';
import { View } from 'backbone';
import util from '@glu/core/src/util';
import StepCollectionView from './stepCollectionView';
import stepLayoutTmpl from './stepLayout.hbs';

export default Layout.extend({
    template: stepLayoutTmpl,

    initialize(options) {
        this.stepCollectionView = new StepCollectionView({
            collection: options.collection,
        });

        this.listenTo(this.stepCollectionView, 'changeStep', this.changeStep);
    },

    onRender() {
        this.stepCollectionRegion.show(this.stepCollectionView);
        this.renderStepContent(this.getCurrentStep());
    },

    /**
     * Get the step view and then show in the region
     * @params {Object} step - model that represents a step
     */
    renderStepContent(step) {
        const stepView = this.getStepViewInstance(step);
        this.stepContentRegion.show(stepView);
    },

    /**
     * Get the current step. When it is the same as the parameter, do nothing.
     * Otherwise,
     * execute beforeLeaveStep, updateStep, and catch an errors
     * @param {Object} step - step model
     * @returns {*|Object}
     */
    changeStep(step) {
        const currentStep = this.getCurrentStep();

        if (currentStep === step) {
            return currentStep;
        }
        return Promise.resolve()
            .then(() => this.beforeLeaveStep(currentStep))
            .then(() => this.updateStep(step, currentStep))
            .catch(this.onFail);
    },

    /**
     * Update the step to be unselected then execute the beforeLeaveStep
     * function on the model
     * @param {Object} step - step model
     */
    beforeLeaveStep(step) {
        return step.get('beforeLeaveStep').apply();
    },

    /**
     * Deselect currentStep and set the step as selected and then render
     * the view corresponding to the step
     * @param {Object} step - step model
     * @param {Object} currentStep - step model
     * @returns {Object} step
     */
    updateStep(step, currentStep) {
        this.setSelected(currentStep, false);
        this.setSelected(step, true);
        this.stepCollectionView.render();
        this.renderStepContent(step);
        this.trigger('step:changed', step);
        return step;
    },

    /**
     * Get the previous step and then execute the change
     * @returns {Object} step model
     */
    goToPrevStep() {
        return this.changeStep(this.getPrevStep());
    },

    /**
     * Get the next step and then execute the change
     * @returns {Object} step model
     */
    goToNextStep() {
        return this.changeStep(this.getNextStep());
    },

    /**
     * Based on currently selected model, get the next model in the collection
     * or if it's the last model in the collection, return the last model
     * @param {Object} [step] - optional step model
     * @returns {Object} step model
     */
    getNextStep(step) {
        const currentIndex = this.getStepIndex(step);
        if (this.hasNextStep(step)) {
            return this.collection.at(currentIndex + 1);
        }
        return step || this.getCurrentStep();
    },

    /**
     * Based on currently selected model, get the previous model in the collection
     * or if it's the first model in the collection, return the first model
     * @param {Object} [step] - optional step model
     * @returns {Object} step model
     */
    getPrevStep(step) {
        const currentIndex = this.getStepIndex(step);
        if (this.hasPrevStep(step)) {
            return this.collection.at(currentIndex - 1);
        }
        return step || this.getCurrentStep();
    },

    /**
     * Evaluate the step to determine there is another step to follow
     * in the collection
     * @returns {boolean}
     */
    hasNextStep(step) {
        return this.getStepIndex(step) < this.collection.length - 1;
    },

    /**
     * Evaluate the step to determine there is a step prior
     * in the collection
     * @returns {boolean}
     */
    hasPrevStep(step) {
        return this.getStepIndex(step) !== 0;
    },

    /**
     * Get the index of the model in the collection
     * @param {Object} step - step model
     */
    getStepIndex(step = this.getCurrentStep()) {
        return this.collection.indexOf(step);
    },

    /**
     * Get the model with the selected attribute set to true, or if not found
     * return the first model in the collection
     * @returns {Object} model
     */
    getCurrentStep() {
        let currentStep = this.collection.findWhere({
            selected: true,
        });
        if (!currentStep) {
            currentStep = this.collection.at(0);
        }
        return currentStep;
    },

    /**
     * Get the view stored on the model and if it is an instance, return it,
     * otherwise, instantiate an new view, passing in the viewOptions from the model
     * @param {Object} step
     * @returns {View} an instance of the view
     */
    getStepViewInstance(step) {
        const StepView = step.get('view');
        if (StepView instanceof View) {
            return StepView;
        }
        // Get the results of viewOptions then instantiate the view with those options
        const instantiatedView = new StepView(util.result(step.attributes, 'viewOptions'));
        // Now update the model so don't have to instantiate again in the future
        step.set('view', instantiatedView);
        return instantiatedView;
    },

    /**
     * Set the model attribute 'selected' to the provided value
     * @param {Object} model
     * @param {boolean} value
     * @returns {Object} step model
     */
    setSelected(model, value) {
        model.set('selected', value);
        return model;
    },

    onFail(error) {
        if (error instanceof Error) {
            throw error;
        } else {
            throw new Error(error);
        }
    },
});
