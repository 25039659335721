import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';

export default Model.extend({
    defaults: {
        account: '',
        currency: '',
        amount: '',
        comment: '',
    },

    initialize(attributes, options) {
        this.subType = options.subType;
        Model.prototype.initialize.call(this, attributes, options);
    },

    isManyToOne() {
        return this.subType === 'MANY2ONE';
    },

    getAccountNumber() {
        if (this.isManyToOne()) {
            return this.get('DEBIT_ACCOUNT_NUMBER');
        }
        return this.get('BENE_ACCOUNT');
    },

    getAmount() {
        if (this.isManyToOne()) {
            return this.get('DEBIT_AMOUNT');
        }
        return this.get('CREDIT_AMOUNT');
    },

    setAmount(amount) {
        if (this.isManyToOne()) {
            return this.set('DEBIT_AMOUNT', amount);
        }
        return this.set('CREDIT_AMOUNT', amount);
    },

    getCurrency() {
        if (this.isManyToOne()) {
            return this.get('DEBIT_CURRENCY');
        }
        return this.get('CREDIT_CURRENCY');
    },

    convertModelAttributesToJSON() {
        const jsonData = [];
        util.each(this.attributes, (value, key) => {
            jsonData.push({
                name: key,
                value,
            });
        });

        jsonData.push({
            name: 'PRODUCTCODE',
            value: this.get('PRODUCT'),
        });
        jsonData.push({
            name: 'FUNCTIONCODE',
            value: this.get('FUNCTION'),
        });
        jsonData.push({
            name: 'TYPECODE',
            value: this.get('TYPE'),
        });

        return {
            item: jsonData,
        };
    },
});
