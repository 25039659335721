import PaymentUtil from 'common/util/paymentUtil';
import MDFMultiAddCollectionView from 'app/multiAdd/views/mdfMultiAddCollectionView';

export default MDFMultiAddCollectionView.extend({
    itemEvents: {
        destroy(event, itemView) {
            // if first item, then just clear model; otherwise, remove model
            if (this.collection.length === 1) {
                itemView.model.set(itemView.model.keys().reduce((acc, key) => {
                    if (key !== 'CUTOFF_INFO' && key !== 'TYPE' && key !== 'FUNCTION') {
                        acc[key] = '';
                    }
                    return acc;
                }, {}));

                // return the 'Payment Date' date picker to its correct default date
                itemView.model.set('VALUE_DATE', this.parentView.defaultDate);

                // clear the lookup helper text for bene bank
                itemView.mdfView.trigger('lookupHelperText:clear', 'BENE_BANK_ID');

                // reset the character limit counters by triggering a keyup on relevant fields
                itemView.$el.find('[name="CUSTOMER_REFERENCE"]').trigger('keyup');
                itemView.$el.find('[name="REMITTANCE_INFO"]').trigger('keyup');

                // trigger an update to the datepickers cutoff helper text
                PaymentUtil.updateCutoff(
                    itemView.model.get('PRODUCT'),
                    itemView.model.get('FUNCTION'),
                    itemView.model.get('TYPE'),
                    '*',
                    itemView.model.context.actionMode,
                    itemView.model,
                    itemView.$el.find('.ui-datepicker-trigger'),
                );
            } else {
                this.collection.remove(itemView.model);
                // let the parent view know that a payment has been removed
                this.collection.trigger('paymentRemoved');
            }
            if (this.parentView.updateFooter) {
                this.parentView.updateFooter();
            }
            this.parentView.updateSummaryTotal();
        },
    },

    onAfterItemAdded() {
        this.parentView.updateSummaryTotal();
    },

    onItemRemoved() {
        this.parentView.updateSummaryTotal();
    },
});
