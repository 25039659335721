import moment from 'moment';
import Model from '@glu/core/src/model';
import Layout from '@glu/core/src/layout';
import userInfo from 'etc/userInfo';
import { getComponentByIdAsView, getUtilById } from 'no-paymode!common/paymodeIntegration/paymodeIntegration';
import tpl from './bankReconciliationWidget.hbs';

const PaymodeBankReconciliationWidget = Layout.extend({

    tabTitle: 'widget.pmx-reports.bank-recon.description',

    template: tpl,

    className: 'ui-widget field-container pmx-field-container',

    initialize() {
        this.model = new Model();
    },

    onRender() {
        Promise.all([
            getComponentByIdAsView('widgets.bankReconciliationReport.widgetView'),
            getUtilById('widgets.bankReconciliationReport.widgetStyles'),
        ]).then(this.handleBankReconciliationComponent.bind(this));
    },

    /**
     * handleBankReconciliationComponent
     *
     * @results array - results from Promise.all call
     */
    handleBankReconciliationComponent(results) {
        const BankReconciliationReportWidget = results[0];
        this.bankReconciliationView = new BankReconciliationReportWidget({
            type: 'PAYER',
            companyId: userInfo.getMembershipID(),
            data: { datePeriod: [moment(new Date()).startOf('month'), moment(new Date()).startOf('day')] },
            searchOnMount: false,
        });

        this.regionManager.addRegion('pmxBankReconciliationRegion', '.pmx-bank-reconciliation');

        this.pmxBankReconciliationRegion.show(this.bankReconciliationView);
    },

    onBeforeClose() {
        if (this.bankReconciliationView) {
            this.bankReconciliationView.close();
        }
    },
});

export default PaymodeBankReconciliationWidget;
