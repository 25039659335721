import { createUseStyles } from '@glu/theming';

const styles = ({ palette, typography }) => {
    const title = {
        ...typography.h5,
        color: palette.primary.darkBackground,
        textTransform: 'none',
        margin: 0,
        marginBottom: 8,
    };
    return {
        root: {},
        hide: {
            display: 'none',
        },
        title,
        help: {
            ...typography.text,
            color: palette.text.dark,
            margin: 0,
            marginBottom: 18,
        },
        box: {
            border: `1px solid ${palette.highlightBackground}`,
            padding: [5, 8],
            background: palette.background,
        },
        containerTitle: {
            ...title,
            textTransform: 'capitalize',
            opacity: 0.75,
        },
        pillContainer: {
            display: 'inline-block',
            paddingLeft: 6,
        },
    };
};

export default createUseStyles(styles);
