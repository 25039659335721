import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import store from 'system/utilities/cache';
import SMBDynamicFieldsTmpl from './SMBDynamicFields.hbs';

export default EntryView.extend({
    template: SMBDynamicFieldsTmpl,

    initialize(optionsParam) {
        const options = optionsParam;
        if (options.mode.toUpperCase() !== 'INSERT') {
            if (!options.model.context) {
                const context = {};
                context.serviceName = '/users/userCentric/smb';
                options.model.context = context;
            }
            store.set('users_userCentric_smb-actionModel', options.model);
        }
        const newOptions = {
            menuCategory: 'ADMINSTRATION',
            serviceName: '/users/userCentric/smb',
            serviceFunc: null,
            businessType: null,
            context: 'USER_VIEW',
            mode: options.mode,
        };
        EntryView.prototype.initialize.call(this, newOptions);
    },
});
