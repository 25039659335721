// The base of a subRowControl column definition.
// Requires the cellClass and cellRendererParams to be added to be complete
export const defaultSubRowControlColumnDef = {
  colId: 'subRowControl',
  hide: false,
  width: 40,
  sortable: false,
  lockPosition: true,
  suppressSizeToFit: true,
  cellRenderer: 'subRowControl',

  // based on https://stackoverflow.com/questions/59055325/tabbing-into-custom-cell-renderers-in-ag-grid
  suppressKeyboardEvent({
    event: {
      code, key, shiftKey, target
    }
  }) {
    if (code === 'Tab' || key === 'Tab') {
      // get focusable children of parent cell
      const focusableChildren = target.closest('.ag-cell').querySelectorAll(
        'button, [href], :not(.ag-hidden) > input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      if (
        !focusableChildren.length
        || (!shiftKey && target === focusableChildren[focusableChildren.length - 1])
        || (shiftKey && target === focusableChildren[0])
        || (shiftKey && target.classList.contains('ag-cell'))
      ) {
        return false; // do not suppress
      }

      return true; // suppress
    }
    return false; // do not suppress by default
  }
};

// The matching ag grid column state for the subRowControl column
// Add this to your initial column state to ensure ag grid doesn't hide the column on you
// for calling columnApi.setColumnState() with an state array that doesn't include the
// subRowControl column
export const defaultSubRowControlColumnState = {
  colId: 'subRowControl',
  hide: false,
  width: 40,
  aggFunc: null,
  pinned: null,
  pivotIndex: null,
  rowGroupIndex: null
};
