import util from '@glu/core/src/util';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import DuplicatePaymentModal from 'app/smbPayments/views/modals/duplicatePayment';

export default {

    /**
     * @method updateID
     * @param {type} confirms a JSON confirm structure
     * @param {type} model the Model supporting the display the confirmation.
     * Copies the payment id from the confirm data to the model.
     */
    updateID(confirms, model) {
        if (confirms
            && confirms.confirmResults[0]
            && confirms.confirmResults[0].confirmData[0]) {
            const seqIdObj = util.findWhere(
                confirms.confirmResults[0].confirmData[0].item,
                {
                    name: locale.get(this.paymentIdMap[model.get('PAYMENTTYPE')]),
                },
            );
            if (!util.isNullOrUndefined(seqIdObj)) {
                this.model.set('ID', seqIdObj.value);
            }
        }
    },

    showCreatePayment(view) {
        const viewParam = view;
        view.model.unset('DUPLICATEREASON');
        view.model.unset('duplicateAccepted');
        view.model.unset('_saveWithWarning');

        view.alertRegion.reset();
        view.ui.$duplicatePaymentSection.hide();

        view.trigger('dialog:title:change', view.getHeader());

        viewParam.model.validators.DUPLICATEREASON = null;
    },

    /**
     * @method getDuplicateWarningOkButton
     * Gets the Ok button to accept a duplicate warning
     * @return {Object} Returns settings for the Ok button needed to accept a
     * duplicate payment warning
     */
    getDuplicateWarningOkButton(view) {
        return {
            text: locale.get('button.ok'),
            className: 'btn btn-primary',
            callback: util.bind(this.scheduleWithDuplicateWarningBtnHandler, view, view),
        };
    },

    getWarningCancelButton(view) {
        return {
            text: locale.get('button.cancel'),
            className: 'btn btn-secondary',
            callback: util.bind(this.handleWarningCancel, this, view),
        };
    },

    /**
     * @method scheduleWithDuplicateWarningBtnHandler
     * sets the duplicateAccepted value to true and invokes the submit function.
     */
    scheduleWithDuplicateWarningBtnHandler(view) {
        view.model.set('duplicateAccepted', true);
        view.scheduleBtnHandler();
    },

    showWarningConfirmation(options, view) {
        const viewParam = view;
        /*
         * If we are dealing with a duplicate warning we need to handle it differently (show
         * grid and duplicate reason field)
         */
        if (options.errorCode && options.errorCode === 540) {
            // NH-31559 - remove html characters from form values
            view.model.set('text', util.unescape(view.model.get('text')));

            view.ui.$formElement.hide();
            view.ui.$duplicatePaymentSection.show();

            view.trigger('dialog:title:change', this.getConfirmTitle(view));

            viewParam.duplicatePaymentView = this.getDuplicatePaymentView(
                this.buildDuplicateData(options, view),
                viewParam,
            );
            view.duplicatePaymentContent.show(view.duplicatePaymentView);

            viewParam.model.validators.DUPLICATEREASON = {
                description: locale.get('sbPayments.duplicateReason'),
                exists: true,
            };
            view.trigger('dialog:buttons:change', [
                this.getDuplicateWarningOkButton(view),
                this.getWarningCancelButton(view),
            ]);
        }
    },

    handleWarningCancel(view) {
        this.showCreatePayment(view);
        view.ui.$formElement.show();
        view.trigger('dialog:buttons:change', view.getButtons());
    },

    /**
     * @param {array} duplicatePaymentData (array of duplicate payments and their
     * data from the server)
     * @param {View} view
     * Get the duplicate payment view to be displayed in the duplicate payment
     * section if a duplicate warning is received on submit
     */
    getDuplicatePaymentView(duplicatePaymentData, view) {
        return new DuplicatePaymentModal({
            model: view.model,
            duplicatePaymentData,
        });
    },

    getConfirmTitle(view) {
        return locale.get(this.isSelect(view)
            ? 'sbPayments.viewPayment' : 'PAY.PossibleDuplicatePayment');
    },

    // return true if the action passed in from the grid was select
    isSelect(view) {
        return view.options.action === 'select';
    },

    /**
     * @param {object} options (options object from the server call)
     * @param {View} view
     * @return {array} duplicatePaymentData (array of duplicate payments and
     * their data from the server)
     * Take the duplicate data returned from the server and put it into an array
     * that can be consumed by the view.
     */
    buildDuplicateData(options, view) {
        const dpData = options.confirms;
        const duplicatePaymentsDataFromServer = dpData.confirmResults[0].additionalData;

        return Object.values(duplicatePaymentsDataFromServer).map((record) => {
            const smbTypedescription = this.resolvePaymentType(
                view.model,
                record.item[2].value,
                view,
            );
            const duplicatePayment = {
                id: record.item[0].value,
                beneficiary: record.item[1].value,
                paymentType: smbTypedescription,
                valueDate: record.item[3].value,
                lastUpdateTime: record.item[4].value,
                status: record.item[5].value,
            };
            return duplicatePayment;
        });
    },

    /**
     * @param {Object} model
     * @param {String} serverTypeDescription (Payment type received from the server)
     * @param {View} view
     * @return {String} type (Payment description that will be meaningful to
     * SMB user.)
     * Take in the payment description recieved from the server and translate it
     * to a description that an SMB user would expect/understand .
     */
    resolvePaymentType(model, serverTypeDescription, view) {
        const typeCode = model.get('TYPE') || model.get('PAYMENTTYPE');
        const data = {
            TRANSFER: view.getHeader(),
        };
        const key = data[typeCode];
        return key || serverTypeDescription;
    },

    renderPaymentAlertContent(options, view) {
        // display notification message
        const [warningMessage] = options.confirms.confirmResults[0].messages;
        const viewParam = view;
        /*
         * If this a warning for a possible duplicate payment then just display the message
         * in a warning dialog. Cannot be closed and has no 'continue' option.
         */
        if (options.errorCode && options.errorCode === 540) {
            if (!view.model.validationOnly) {
                viewParam.alertView = alert.warning(
                    warningMessage,
                    {
                        canDismiss: false,
                    },
                );
                view.alertRegion.show(view.alertView);
            }
        }
    },
};
