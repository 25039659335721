import CompositeView from '@glu/core/src/compositeView';
import Collection from '@glu/core/src/collection';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import loadingModalTmpl from 'common/templates/loadingModal.hbs';
import EmptyRemitterView from 'app/reports/views/lockbox/modals/remitter/emptyView';
import AccountModel from 'app/reports/models/accounts';
import AccountCollection from 'app/reports/collections/lockboxAccounts';
import Account from 'app/reports/views/lockbox/modals/remitter/account';
import accountInfoTmpl from 'app/reports/views/lockbox/modals/remitter/accountInfo.hbs';

export default CompositeView.extend({
    template: accountInfoTmpl,
    loadingTemplate: loadingModalTmpl,
    modalClass: 'modal-lg lockbox-notes',
    itemView: Account,
    emptyView: EmptyRemitterView,

    itemEvents: {
        destroy(event, itemView) {
            this.collection.remove(itemView.model);
        },
    },

    initialize(options) {
        this.itemViewOptions = {
            parent: this,
        };

        this.rid = options.rid;
        this.modalType = options.modalType;
        // initial model from the grid
        this.model = this.model || new AccountModel();
        // flag all new models
        this.model.action = 'save';
        // stored models to be submitted through a promise on save
        this.models = new Collection();
        // actual collection for the composite view
        this.collection = new AccountCollection();
        this.collection.rid = this.rid;
        this.listenTo(this.collection, 'sync', this.render);
        this.listenTo(this.collection, 'remove', this.accountDeleteAlert);
        this.hasUnstoredAccountModel = false;
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.modalType === 'basic' && !this.rid) {
                this.setInitialModel();
            } else if (this.modalType === 'advanced' && !this.rid) {
                this.setInitialModel();
            } else {
                this.models.add(this.model);
            }
        } else {
            this.loadRequiredData();
        }
    },

    accountDeleteAlert() {
        if (this.rid && this.collection.length < 1) {
            const accountAlert = alert.warning(locale.get('LBX.NoRemitterAccount'));
            this.trigger('deletingAllAccounts', accountAlert);
        }
    },

    appendHtml(cv, iv) {
        cv.$el.find('.accountContainer').append(iv.el);
    },

    setInitialModel() {
        this.initialModelCid = this.model.cid;
        if (this.modalType === 'basic') {
            this.model.isGridModel = true;
        }
        this.addModelToCollection(this.model);
        this.storeModelForSubmit(this.model);
    },

    addAccount() {
        this.addModelToCollection();
    },

    addModelToCollection(model) {
        const lastModel = this.collection.at(this.collection.length - 1);
        const account = model || new AccountModel();

        // validate last model before adding another
        if (lastModel) {
            if (!lastModel.isValid()) {
                lastModel.trigger('invalid');
                return;
            }

            lastModel.action = 'save';

            // re-render last itemView if it is a new account
            const lastItemView = this.children.findByIndex(this.children.length - 1);

            if (lastItemView) {
                lastItemView.render();
            }

            if (lastModel.cid !== this.initialModelCid) {
                this.storeModelForSubmit(lastModel);
            }
        }

        this.collection.add(account);
        this.hasUnstoredAccountModel = true;
    },

    /**
     * @method storeModelForSubmit
     * @param model
     * The models collection stores all newly added models which have passed
     * field validation
     */
    storeModelForSubmit(model) {
        this.models.add(model);
        this.hasUnstoredAccountModel = false;
    },

    loadRequiredData() {
        const self = this;

        if (this.rid) {
            this.collection.fetch({
                success() {
                    self.setHasLoadedRequiredData(true);
                },
            });
        } else {
            this.setHasLoadedRequiredData(true);
            this.render();
        }
    },
});
