import Backbone from 'backbone';
import moment from 'moment';
import $ from 'jquery';
import $document from '@glu/core/src/$document';
import util from 'underscore';
import locale from '@glu/locale';
import ItemView from '@glu/core/src/itemView';
import template from './datePicker-glu-1.8.hbs'

import gluDatepicker from '@glu/datepicker';
// importing for Jest namespace issues
import daterangepicker from 'daterangepicker';

var dateFormats = {
    'DD-MMM-YYYY': {
        moment: 'DD-MMM-YYYY',
        date: 'dd-M-yy'
    },
    'MM/DD/YYYY': {
        moment: 'MM/DD/YYYY',
        date: 'mm/dd/yy'
    },
    'YYYY-MM-DD': {
        moment: 'YYYY-MM-DD',
        date: 'yy-mm-dd'
    },
    'DD MMM YYYY': {
        moment: 'DD MMM YYYY',
        date: 'dd M yy'
    },
    'DD-MM-YYYY': {
        moment: 'DD-MM-YYYY',
        date: 'dd-M-yy'
    },
    'DD.MM.YYYY': {
        moment: 'DD.MM.YYYY',
        date: 'dd.mm.yy'
    },
    'D.MM.YYYY': {
        moment: 'D.MM.YYYY',
        date: 'dd.mm.yy'
    },
    'DD. MM. YYYY': {
        moment: 'DD. MM. YYYY',
        date: 'dd.mm.yy'
    },
    'YYYY.MM.DD.': {
        moment: 'YYYY.MM.DD.',
        date: 'yy.mm.dd'
    },
    'YYYY.MM.DD': {
        moment: 'YYYY.MM.DD',
        date: 'yy.mm.dd'
    },
    'YYYY/MM/DD': {
        moment: 'YYYY/MM/DD',
        date: 'yy/mm/dd'
    },
    'MM/D/YYYY': {
        moment: 'MM/D/YYYY',
        date: 'mm/d/yy'
    },
    'DD/MM/YYYY': {
        moment: 'DD/MM/YYYY',
        date: 'dd/mm/yy'
    }
};
const defaultDateFormat = 'DD-MMM-YYYY';

/**
 * Internal view for rendering the date picker in.  This is the default view that is created and then returned
 * from the DatePicker for insertion into your UI.  It can be replaced with a custom view by passing in your
 * class that extends ItemView in the DatePicker options as long as the replacement defines the same UI hash,
 * accepts the DatePicker as the "datePicker" property on its options, and calls the date picker renderOnElem method
 * when the view renders.
 *
 * @type {Object}
 */

var DatePickerView = ItemView.extend({
    tagName: 'div',
    validate: false,
    focusOnError: false,
    initialize: function(options) {
        this.dateElemId = 'rangeDatePicker-' + this.cid;
        this.dateElemIconId = this.dateElemId + '-icon';
        this.datePicker = options.datePicker;
    },

    events: {
        'keydown .input-date': 'handleEnterKey'
    },

    ui: {
        datePickerLabel: 'label',
        errorMessage: '.date-error',
        rangePicker: '.ui-daterangepicker'
    },

    serializeData: function() {
        return {
            dateElemId: this.dateElemId,
            dateElemClassName: this.datePicker.inputClassName,
            labelText: this.datePicker.labelText,
            imgAltText: this.datePicker.imgAltText,
            autocomplete: this.datePicker.autocomplete,
            nameAttr: this.datePicker.nameAttr
        };
    },

    onRender: function() {
        var datePickerElem = this.$('#' + this.dateElemId);
        var dateElemIcon = this.$('#' + this.dateElemIconId);

        this.datePicker.renderOnElem(datePickerElem);
        this.rangePicker = this.$('.ui-daterangepicker');

        datePickerElem.on('change', null, null, util.bind(function() {
            //close the picker when they change the date by typing
            this.closePicker();
        }, this));

        dateElemIcon.on('click', () => this.$('#' + this.dateElemId).click());
    },

    onShow: function() {
        this.$('.ui-daterangepicker-specificDate a, .ui-daterangepicker-dateRange a').on('click', util.bind(this.handleCalendarShowClick, this));
    },

    onClose: function() {
        this.$('.ui-daterangepicker-specificDate a, .ui-daterangepicker-dateRange a').off('click');
    },

    templateHelpers: function () {
        return {
            dateElemIconId: this.dateElemIconId,
        };
    },

    /**
     * If the use hits the enter key we blur so that any change events will fire on the input element
     * and allow us to see if they changed the date. The date picker is then closed.
     *
     * @param evt - Normalized keydown event
     */
    handleEnterKey: function(evt) {
        this.usedUserInput = true;
        if (evt.which === $.ui.keyCode.ENTER) {
            evt.target.blur();
            this.closePicker();
        } else if (evt.which === $.ui.keyCode.DOWN) {
            this.$el.find('.ui-datepicker-trigger').trigger('click');
        } else if (evt.which === $.ui.keyCode.ESCAPE) {
            this.closePicker();
        }
    },

    closePicker: function() {
        if (this.rangePicker.data('state') !== 'closed') {
            this.rangePicker.fadeOut(300).data('state', 'closed');
        }
        this.usedUserInput = false;
    },

    handleCalendarShowClick: function() {
        if (this.datePicker.minDate) {
            this.$('.range-end').datepicker('option', 'minDate', this.datePicker.minDate.toDate());
        }

        if (this.datePicker.maxDate) {
            util.defer(util.bind(function() {
                this.$('.range-start').datepicker('option', 'maxDate', this.datePicker.maxDate.toDate());
                this.$('.range-start').datepicker('option', 'maxDate', this.datePicker.maxDate.toDate());
            }, this));
        }
    }

});

/**
 * Create a date range picker using the filament group's date range picker control(dateRangePicker.js).
 * The date picker handles all dealings with the control, UI interactions, parsing and formatting of dates, and some
 * basic validation.
 * When a new date range is picked the input is parsed and set as moment.js dates on startDate and endDate properties
 * of the date picker where you can retrieve them as needed. Additionally, if you prefer to be notified the date picker
 * fires an event on itself, "datePicked" that you can listen to.  This event is only fired when the user selects a date
 * or types one in that is different than the current date and passes all parsing and validation.  The eventTarget can
 * be changed if desired from the date picker to another object support events.
 *
 * @param options {Object}
 *        Options hash that supports the following properties:
 *        <ul>
 *            <li>startDate - Moment.js moment for the start date</li>
 *            <li>endDate - Moment.js moment for the end date</li>
 *            <li>required - True if the date is required and a blank one should result in an error, false otherwise. Defaults to true.</li>
 *            <li>setDateOnOpen - True if the date picker control should be set with the initial dates for this DatePicker, false otherwise.  Defaults to true/</li>
 *            <li>minDate - Moment.js moment for the minimum date the date picker should allow. Defaults to 7 years prior to today.</li>
 *            <li>maxDate - Moment.js moment for the maximum date the date picker should allow. Defaults to 1 years from today.</li>
 *            <li>customSettings - settings hash that will override the default settings passed to the date control.</li>
 *            <li>labelText - Label for the date picker on the UI. Defaults to "Date"</li>
 *            <li>imgAltText - Text to use for the alt attribute on the date picker trigger image. Defaults to "Date range picker calendar"</li>
 *            <li>datePickerView - View class to use for rendering the date picker.  Defaults to the date picker's internal view.</li>
 *            <li>template - Template to use for the datePickerView. Accepts a compiled template or a string that can be compiled by handlebars. Defaults to the date pickers own internal template.</li>
 *            <li>eventTarget - Custom event target for the "datePicked" event that is fired on successful date range selection. Defaults to this date picker.</li>
 *            <li>eventName - Custom event name for the "datePicked" event. Defaults to "datePicked".</li>
 *            <li>inputClassName - Space seperated list of classes to use for the date control's input element. Defaults to "input-date".</li>
 *            <li>viewId - Css Id to use for the datepicker view's element. Defaults to no id.</li>
 *            <li>viewClassName - Space seperated list of classes to use for the datepicker view's element. Defaults to control-group.</li>
 *            <li>autocomplete - Boolean: Whether to allow autocomplete on the input element used for the datepicker. Defaults to false.</li>
 *            <li>allowToday - Boolean: Whether to allow today as a choice on the datepicker. Defaults to true.</li>
 *            <li>errorMsgs - Hash of keys to messages for all error messages used by the date picker.  Defaults to an internal set of messages</li>
 *            <li>dateFormats - Hash of moment date format strings(keys) to bundles of consisting of a moment date format (matching the key) and jquery ui date format strings
 *            that are used to format dates internally and for display. These should evaluate to the same string when formatting a date with each.
 *            Defaults to a built in internal set of allowed formats.</li>
 *            <li>defaultDateFormat - The default format to select from dateFormats if a dateFormat option is not provided.</li>
 *            <li>dateFormat - The format to select from dateFormats for this date picker. Optional.</li>
 *        </ul>
 */
function DatePicker(options) {
    this.errMsg = null;
    this.hasError = false;
    this.startDate = options.startDate ? moment(options.startDate) : null;
    this.endDate = options.endDate ? moment(options.endDate) : null;
    this.period = options.period;

    this.initFromOptions(options);

    // Set Datepicker Date format
    this.dtPickerFormat = this.dateFormats[options.dateFormat] || this.dateFormats[this.defaultDateFormat];

    // Update min and max dates if passed in options as something other than a moment
    // Note that this is completely unsafe as it depends on moment parsing dates other than iso 8601 dates (http://momentjs.com/docs/#/parsing/string/)
    // Changing this to use moment(date,format) is also unsafe for some formats like MM/DD/YYYY where the day and months can be ambiguous,
    // for example 12/01/2015 could be January 12, 2015 or December 1, 2015
    // only leaving this in to avoid breaking other code that might use it, but a change is needed to parse non moments properly as well as return a moment
    // vs a moment formatted into a string which we then parse into a moment which is not ideal and requires that this code be after we set the dtPickerFormat
    if (options.minDate && !moment.isMoment(options.minDate)) {
        this.minDate = moment(this.determineDate(options.minDate)).startOf('day');
    }

    if (options.maxDate && !moment.isMoment(options.maxDate)) {
        this.maxDate = moment(this.determineDate(options.maxDate)).endOf('day');
    }

    this.dateRangePickerSettings = util.extend({
        presets: {
            specificDate: 'Specific Date',
            dateRange: 'Date Range'
        },
        dateFormat: this.dtPickerFormat.date,
        locale: {
            format: options.dateFormat,
        },
        rangeStartTitle: 'Start Date',
        rangeEndTitle: 'End Date',
        rangeSplitter: 'to',
        earliestDate: this.minDate.toDate(),
        latestDate: this.maxDate.toDate(),
        datepickerOptions: {
            minDate: this.minDate.toDate(),
            maxDate: this.maxDate.toDate(),
            changeMonth: true,
            changeYear: true,
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNamesShort: ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ],
            prevText: ' ',
            nextText: ' ',
            showOtherMonths: true
        },
        closeOnSelect: false,
        rangePicker: this.rangePicker,

        //It is HIGHLY recommended that you NOT override this function unless you really know what you are doing
        //and have a good reason
        onOpen: this.setDateOnOpen ? util.bind(this.dateRangePickerOnOpen, this) : function() {},

        onClose: util.bind(function() {
            // validate whenever we close the date picker
            // if we use onChange it will call 2x for date ranges though so close is used instead
            // we set a timeout as close is called for presets after the 1st part of the range
            // is chosen, but before the 2nd part. Thus "today", which is done as a two part range of
            // identical dates will fail validation if we validate immediately as the 2nd part of the range is
            // not set and "from" can be before "to" in this interdeterminant state. The timeout is
            // necessary as the daterangepicker is not extendable and cannot be modified. It should be safe
            // as the two calls to "trigger" in clickActions() should be synchronous so our timeout
            // should always be after them.
            util.defer(util.bind(this.processDateInput, this), 10);
        }, this)
    }, (options && options.customSettings) ? options.customSettings : {});

}

//add event handling powers
util.extend(DatePicker.prototype, Backbone.Events);

util.extend(DatePicker.prototype, {
    defaults: {
        required: true,
        setDateOnOpen: true,
        allowToday: true,
        dateFormats: dateFormats,
        defaultDateFormat: defaultDateFormat,
        dtPickerFormat: defaultDateFormat,
        labelText: 'Date',
        imgAltText: 'Date picker calendar',
        DatePickerView: DatePickerView,
        template: template,
        eventName: 'datePicked',
        inputClassName: 'input-date',
        viewId: null,
        viewClassName: 'control-group',
        autocomplete: false,
        rangePicker: true,
        errorMsgs: {
            required: locale.get('dateRequired'),
            invalidDateFormat: locale.get('invalidDateFormat'),
            invalidDateRange: locale.get('invalidDateRange'),
            historyLimit: locale.get('historyLimit'),
            dailyLimit: locale.get('dailyLimit'),
            blockedDate: locale.get('blockedDate'),
            afterCutoff: locale.get('afterCutoff'),
            tooFarPast: locale.get('tooFarPast'),
            tooFarFuture: locale.get('tooFarFuture')
        },
        nameAttr: null,
        dateElemId: null,
        modelBind: false
    },

    /**
     * Defaults on this.defaults are overridden if specified in options and then copied to "this" object
     *
     * @param options
     */
    initFromOptions: function(options) {


        var defaultOptions = util.extend({
                minDate: moment({ //this and maxDate should be calculated anew each time in case the app runs for a long time in the users browser
                    hour: 0
                }).subtract(7, 'years'),
                maxDate: moment({
                    hour: 0
                }).add(1, 'years'),
                eventTarget: this //we need to set this here as we cannot set it on the defaults property of the prototype
            }, this.defaults),
            safeOptions = util.pick(options ? options : {}, util.keys(defaultOptions));

        // extend does not deeply extend objects
        if (util.isObject(safeOptions.dateFormats)) {
            safeOptions.dateFormats = util.extend(defaultOptions.dateFormats, safeOptions.dateFormats);
        }

        util.extend(this, defaultOptions, safeOptions);
    },

    /**
     * Creates and renders a view for the date picker returning the rendered view to you for inclusion in the UI.
     * A reference to the view is retained in the date picker so you don't need to hold onto it.
     *
     * @returns {ItemView}
     */
    render: function() {
        if (this.view && !this.view.isClosed) {
            this.view.close();
        }

        this.view = new this.DatePickerView({
            id: this.viewId,
            className: this.viewClassName,
            template: this.template,
            datePicker: this,
            nameAttr: this.nameAttr
        });

        this.view.render();

        return this.view;
    },

    /**
     * Creates a datePickerRange.js date picker on the passed in element and binds this date picker to it.
     *
     * @param datePickerElem {Object|string}
     *        Jquery selector string or jquery object for a form input element to use as the date picker.
     */
    renderOnElem: function(datePickerElem) {
        var self = this;
        this.dateElem = $(datePickerElem); // eslint-disable-line backbone/no-native-jquery
        this.updateDateElem();
        this.dateRangePickerSettings.appendTo = this.view.$el;

        if (!this.rangePicker) {
            this.view.$el.addClass('no-range-select');
        }

        datePickerElem.daterangepicker(this.dateRangePickerSettings);

        datePickerElem.on('change', null, null, function(evt) {
            // onchange for the input element is for when the user types something into the field
            // this is not called when closing the date picker. However, if the user changes the
            // field manually AND uses the picker we will get
            // 2 calls so the validation shouldn't do anything too intensive as it can, in limited
            // cases be called twice.
            if (!self.rangePicker) {
                var input = moment(evt.target.value);
                self.view.$('.range-start').datepicker('setDate', input.toDate());
                self.updateDateElem(input);
            }

            self.processDateInput();
        });
    },

    /**
     * Set an error on the picker that will cause it to be styled with error styling and display
     * the passed in message. This error will be removed like anyother the next time validaton is successful.
     *
     * @param errMsg {string}
     *        The error message to show.
     */
    setError: function(errMsg) {
        if (!util.isUndefined(errMsg)) {
            this.errMsg = errMsg;
            this.hasError = true;
            this.showErrors();
        }
    },

    /**
     * Updates the UI of this date picker to indicate to the user if the current entered date has errors or not.
     * If there are no errors we ensure that all prior error indicators are removed from the UI.
     */
    showErrors: function() {
        this.clearErrors();

        if (this.hasError === true) {
            this.dateElem.addClass('err-input');
            this.dateElem.closest('.control-group').addClass('has-error');
            this.view.ui.datePickerLabel.addClass('err-label');
            this.view.ui.errorMessage.text(this.errMsg ? this.errMsg : '');
        }
    },

    /**
     * Function for removing errors and error classes
     */
    clearErrors: function() {
        // because this method may be invoked after view is closed and context of DOM elements will be missed
        if (this.view.isClosed) {
            return;
        }
        this.view.ui.errorMessage.empty();
        this.dateElem.removeClass('err-input');
        this.dateElem.closest('.control-group').removeClass('has-error');
        this.view.ui.datePickerLabel.removeClass('err-label');
    },

    /**
     * Resets this date picker's date and error indicator fields and then parses the new input, sets new error flags
     * and messages if needed.  If the new dates are good and we have to and from form elements to set we then
     * put a properly formatted date in each of them.  If the dates are not good then showErrors() will set the proper UI
     * elements to indicate this to the user.
     */
    processDateInput: function() {
        var lastStart = this.startDate ? this.startDate.valueOf() : null,
            lastEnd = this.endDate ? this.endDate.valueOf() : null,
            triggerFunctionName = this.eventTarget.triggerMethod ? 'triggerMethod' : 'trigger',
            curStart,
            curEnd;

        this.startDate = null;
        this.endDate = null;
        this.errMsg = null;
        this.hasError = false;

        this.parseDateRange();
        this.showErrors();

        if (this.hasError === false) {
            this.updateDateElem();
            curStart = this.startDate ? this.startDate.valueOf() : null;
            curEnd = this.endDate ? this.endDate.valueOf() : null;

            if (lastStart !== curStart || lastEnd !== curEnd) {
                this.eventTarget[triggerFunctionName](this.eventName, this, this.startDate, this.endDate);
            }
        }
    },

    /**
     * Update the date element for this date picker to contain a properly formatted representation of the start
     * and end dates for this date picker. If we only have a start date then that is output. If we have an end date
     * that is not the same as the start date then both are output as a range with the date picker ranger splitter
     * separator between them.
     */
    updateDateElem: function(date) {
        if (date || this.startDate) {
            var startDate = date || this.startDate,
                formattedRange = this.formatDate(startDate);

            // rangePicker = false means it should only handle a single date
            if (this.rangePicker && this.endDate && this.endDate.valueOf() !== this.startDate.valueOf()) {
                formattedRange += ' ' + this.dateRangePickerSettings.rangeSplitter + ' ' + this.formatDate(this.endDate);
            }

            if (date) {
                this.parseDateRange();
                this.showErrors();
                return;
            }

            this.dateElem.val(formattedRange);
        }
    },

    /**
     * Updates the dates for this date picker as well as the UI element used to show them.
     *
     * Any errors that are present will be cleared and the dates you pass checked for errors. If any are found the new errors will be shown.
     *
     * @param startDate {moment} The start date for the date picker. A moment.js moment object or something that can be directly turned into one via a call to moment().
     * @param endDate {moment} The end date for the date picker. A moment.js moment object or something that can be directly turned into one via a call to moment().
     */
    updateDates: function(startDate, endDate) {
        this.errMsg = null;
        this.hasError = false;
        this.startDate = startDate ? moment(startDate) : null;
        this.endDate = endDate ? moment(endDate) : null;
        this.updateDateElem();
        this.parseDateRange();
        this.showErrors();
    },

    /**
     * Parses the date input for this date picker and validates it. Ensures the date is a valid date range, is present
     * if it is required, does not have a start date that is earlier than the system limit, and that the end date is
     * not before the start date.
     */
    parseDateRange: function() {
        var val = $.trim(this.dateElem.val()),
            /*
            The first time through, the val of the dateElem may not come have the locale in it
            and may be `-` instead. This update accounts for that and ensures we split a range
            up if we have one
            */
            dArr = util.isString(val) ? val.split(' ' + this.dateRangePickerSettings.rangeSplitter + ' ') : null,
            period = util.isUndefined(this.period) ? null : this.period;

        //no date provided at all, or we have less than the absolute minumum to form a parsable date
        if (!val.length && this.required) {
            this.errMsg = this.errorMsgs.required;
        }

        if (val && val.length > 0) {
            // if there's only 1 date in the range, make sure we're not hitting the '-'
            if (dArr && dArr.length === 1) {
                dArr = dArr[0].split(' - ');
            }

            // date couldn't be separated into from and to dates with regex
            if (!this.errMsg && dArr === null) {
                this.errMsg = this.errorMsgs.invalidDateFormat;
            } else if (!this.errMsg) {
                var startDate = this.startDate = this.parseDate(dArr[0]);
                var endDate = this.endDate = this.parseDate(dArr[1]);

                // Date Format Validation
                if (!startDate.isValid() || (endDate && !endDate.isValid())) {
                    this.errMsg = this.errorMsgs.invalidDateFormat;
                }

                // Non-Ranged Datepicker Validations
                if (!this.rangePicker) {
                    // Payment date specified is after the after allowed future payment date
                    if (!this.errMsg && startDate.isBefore(this.minDate)) {
                        this.errMsg = this.errorMsgs.tooFarPast;
                    }

                    // Payment date specified is after the after allowed future payment date
                    if (!this.errMsg && startDate.isAfter(this.maxDate)) {
                        this.errMsg = this.errorMsgs.tooFarFuture;
                    }

                    // A blocked date was selected (after cut-off, holiday, weekend, etc)
                    if (!this.errMsg && !this.validDate(startDate, this.blockedDatesFormatted)) {
                        // After cutoff?
                        if (!this.allowToday && startDate.isSame(moment(), 'day')) {
                            this.errMsg = this.errorMsgs.afterCutoff;
                            // Other blocked date
                        } else {
                            this.errMsg = this.errorMsgs.blockedDate;
                        }
                    }

                    // Ranged Date Validations
                } else {
                    // Invalid date combination (end date before start date)
                    if (!this.errMsg && (endDate && endDate.isBefore(startDate))) {
                        this.errMsg = this.errorMsgs.invalidDateRange;
                    }

                    // End date beyond allowed range for special DAILY period
                    if (!this.errMsg && endDate) {
                        if (endDate.diff(startDate, 'years', true) > 1 && period === 'DAILY') {
                            this.errMsg = this.errorMsgs.dailyLimit;
                        }
                    }

                    // Start date is before allowed range
                    if (!this.errMsg && startDate.isBefore(this.minDate)) {
                        this.errMsg = this.errorMsgs.historyLimit;
                    }

                    // End date is after allowed range
                    if (!this.errMsg && endDate && endDate.isAfter(this.maxDate)) {
                        this.errMsg = this.errorMsgs.tooFarFuture;
                    }
                }
            }
        }

        this.hasError = !!this.errMsg;
    },

    /**
     * Parses a single date obtained from the date range using moment and the configured allowed formats.
     *
     * @param dateInput {string} a date to parse using moment.js
     * @returns {moment}
     *        A parsed moment or null if null was input.  Note that the whether the date is
     *        valid or not is indicated on the dates isValid() function.
     */
    parseDate: function(dateInput) {
        var format = (!this.view || !this.view.usedUserInput) ? this.dtPickerFormat.moment : util.pluck(this.dateFormats, 'moment');

        return dateInput ? moment($.trim(dateInput), format, true) : null;
    },

    /**
     * Formats a date for use in the date picker using the system date format.
     *
     * @param {moment} date - moment.js date to format.
     * @returns {string} Formatted date.
     */
    formatDate: function(date) {
        return date.format(this.dtPickerFormat.moment);
    },

    /**
     * Check the current date against an object of daily cutoff times
     *
     * The cutoff time object is formatted:
     *
     * { '1' : '03:00:00-05:00', '2' : '03:00:00-05:00' }
     * { isoWeekday (e.g. 1 = Monday, 2 = Tuesday, etc.) : HH:mm:ssZ }
     *
     * @param {object} cutoffTimes with daily cutoff times
     * @param formattedDates
     * @returns {array}
     */
    checkCutoffTime: function(cutoffTimes, formattedDates) {
        var now = moment(),
            day = now.isoWeekday();

        if (cutoffTimes[day]) {
            var todaysCutoff = moment(now.format('YYYY-MM-DD') + 'T' + cutoffTimes[day]);

            if (now.isAfter(todaysCutoff)) {
                formattedDates.push(now.format(this.dtPickerFormat.moment));
                this.defaultDate = this.nextAllowedDate(formattedDates);
                this.startDate = this.defaultDate;
                this.allowToday = false;
            }
        }

        return formattedDates;
    },

    /**
     * Formats a date for use in the date picker using the system date format.
     *
     * @param formattedDates
     * @returns {moment} next valid date for payment
     */
    nextAllowedDate: function(formattedDates) {
        var now = moment(),
            noWeekends = formattedDates.indexOf('weekends') >= 0,
            returnDate = false,
            testDate;

        while (returnDate === false) {
            testDate = now.add(1, 'days').format(this.dtPickerFormat.moment);

            if (!((noWeekends && (moment(testDate).isoWeekday() > 5)) || formattedDates.indexOf(testDate) >= 0)) {
                returnDate = now;
            }
        }

        return returnDate;
    },

    /**
     * Set the list of dates to disable on the calendar.
     *
     * @param disabledDates
     * @param cutoffTimes
     */
    blockDisabledDates: function(disabledDates, cutoffTimes) {
        var self = this,
            formattedDates = this.formatDates(disabledDates);

        if (cutoffTimes) {
            formattedDates = this.checkCutoffTime(cutoffTimes, formattedDates);
        }

        // Save formatted dates for use in validation
        this.blockedDatesFormatted = formattedDates;

        if (this.allowToday === true && formattedDates.indexOf(moment().format(this.dtPickerFormat.moment)) >= 0) {
            this.defaultDate = this.nextAllowedDate(formattedDates);
            this.allowToday = false;
        } else if (!this.defaultDate) {
            this.defaultDate = moment();
        }

        util.extend(this.dateRangePickerSettings, {
            datepickerOptions: {
                beforeShowDay: function(date) {
                    return [self.validDate(date, formattedDates)];
                }
            }
        });
    },

    /**
     * Returns true if the specified date does not exist in the specified array of disabled date expressions.
     *
     * Disabled date expressions are first normalized to simplify comparisons.  The following expressions
     * are currently supported:
     *
     * Exact date: '2014-01-01'
     * Relative date string: '4y -3m +2w -1d'
     * Relative number of days: 5
     * Weekends: 'weekends'
     *
     * @param date
     * @param disabledDates
     * @returns {boolean|*}
     */
    validDate: function(date, disabledDates) {
        var dateMoment = moment(date),
            datestring = dateMoment.format(this.dtPickerFormat.moment);

        // see if the date is included in the disabled list.
        if (disabledDates && disabledDates.indexOf(datestring) > -1) {
            return false;
        }

        // Disable dates before minDate and after maxDate
        if (dateMoment.isBefore(this.minDate) || dateMoment.isAfter(this.maxDate)) {
            return false;
        }

        // disable weekends if specified.
        if (disabledDates && disabledDates.indexOf('weekends') > -1) {
            return $.datepicker.noWeekends(dateMoment.toDate())[0];
        }

        return true;
    },

    /**
     * Format a list of disabled date expressions as standard yy-mm-dd values for easier comparison.
     *
     * @param dates
     * @returns {*}
     */
    formatDates: function(dates) {
        var formattedDates = [],
            self = this;

        util.each(dates, function(value) {
            // weekends is a special term that we will use to
            // leverage the datepicker noWeekends flag.
            // We can use this approach to create other custom expressions.
            if (value === 'weekends') {
                formattedDates.push(value);
            } else {
                formattedDates.push(self.determineDate(value));
            }
        });

        return formattedDates;
    },

    /**
     * Normalize a date expression into a standard form.
     *
     * @param date
     * @returns {undefined}
     */
    determineDate: function(date) {
        var newDate;

        if (typeof date === 'string') {
            // see if this is already a valid date (ie, 2014-01-01).  If so, use as is.
            if (moment(date).isValid()) {
                // properly formatted date string.
                newDate = moment(date);
            } else {
                // parse the date string using jquery datepicker syntax (ie '+1w +2d').
                newDate = this.offsetString(date);
            }
        } else if (typeof date === 'object') {
            // see if this is already a valid date (ie, 2014-01-01).  If so, use as is.
            if (moment(date).isValid()) {
                // properly formatted date string.
                newDate = moment(date);
            }
        } else if (typeof date === 'number') {
            // simply add the specified number of days to today's date.
            newDate = this.offsetNumeric(date);
        }

        return newDate.format(this.dtPickerFormat.moment);
    },

    /**
     * Add the specified numeric offset to the current date.
     *
     * @param offset
     * @returns {*}
     */
    offsetNumeric: function(offset) {
        return moment().add(offset, 'd');
    },

    /**
     * Add the current offset expression to the current date.
     *
     * Note: a single offset expression is a space-separated list of moment.js format options (ie, '1M 2d -3w')
     *
     * Options include:
     * d - day
     * M - month
     * w - week
     * y - year
     */
    offsetString: function(offset) {
        var newMoment = moment(),
            pattern = /([+\-]?[0-9]+)\s*([dwMy])?/g,
            matches = pattern.exec(offset);

        while (matches) {
            newMoment.add(parseInt(matches[1], 10), matches[2]);
            matches = pattern.exec(offset);
        }

        return newMoment;
    },

    /**
     * When a date picker is opened this function will be called if we were instantiated with the option to
     * set the dates on open.
     */
    dateRangePickerOnOpen: function() {
        //This is a way to allow us to maybe have more than one picker per page(and since we only have 1 page in BB ...).
        //The old code just did the stuff directly below which is obviously not very safe.  I moved the date
        //picker under the view's element with the setting appendTo.  This is normally set to the body.
        //Unfortunately the date picker, being the annoying non customizable thing it is cannot handle being inside
        //a relatively positioned element, setting its position based on offset() and thus getting it wrong
        //so I need to move it again in here so it isn't in the wrong spot.
        //The reason this needs to be under the view elem in the first place is so we can find OUR date picker
        //and not ALL date picker range-start and ends.
        //Still need to make this do the right left window positioning the daterangepicker does, but this will do for now
        //			$('.range-start').datepicker('setDate', this.startDate.toDate());
        //			$('.range-end').datepicker('setDate', this.endDate.toDate());

        //we use position as this takes into account and position:relative containers above us in DOM which
        //the css top and left WILL take into account as well.
        var left = this.dateElem.position().left;
        var top = this.dateElem.position().top + this.dateElem.outerHeight() - 1;

        //override the styles set in dateRangePicker.js with ones that make sense
        this.view.$('.ui-daterangepickercontain').css({
            'top': top,
            'left': left,
            'right': ''
        });

        this.setRangesPosition();

        // If we are past todays cutoff date, hide that option from the quick selections
        if (!this.allowToday) {
            this.view.$('.ui-daterangepicker-Today').remove();
        }

        if (!this.hasError && !this.required && this.startDate && this.endDate) {
            this.view.$('.range-start').datepicker('setDate', this.startDate.toDate());
            this.view.$('.range-end').datepicker('setDate', this.endDate.toDate());
        }
    },

    /**
     * When a date picker is opened this function will show ranges in proper screen side.
     * Due to dateRangePicker rendered in the container with position absolute we should override width property for it.
     */
    setRangesPosition: function() {
        var $container = this.view.$('.ui-daterangepickercontain'),
            bodyWidth = $document.width(),
            $widgetList = this.view.$('ul.ui-widget-content'),
            widgetListWidth = $widgetList.outerWidth(),
            $ranges = this.view.$('.ranges'),
            controlGroupWidth = this.dateElem.parent('.control-group').outerWidth();

        // will automatically calculate where we should show ranges for dates
        // if there are no place to show ranges on the left or right sides will keep native behaviour. 336px is default dateRange container width with padding width
        if (((bodyWidth - $container.offset().left) < (widgetListWidth + 336)) && ($container.offset().left < 336)) {
            // should clear previous properties to allow default behaviour.
            $widgetList.css({
                float: 'left'
            });
            $ranges.css({
                float: 'left'
            });
            $container.width('auto');
            $ranges.width('auto');
            // if there are no space to show date ranges on the right side will override css properties and show ranges on the left side
        } else if ((bodyWidth - $container.offset().left) < (widgetListWidth + 336)) {
            //override float property for presets date list and dateRanges container
            $widgetList.css({
                float: 'right'
            });
            $ranges.css({
                float: 'right'
            });

            $container.css({
                'right': controlGroupWidth - widgetListWidth,
                'left': ''
            });

            //will check ability to show datepickers in one row
            if (($container.offset().left + $container.outerWidth()) > (widgetListWidth + 630)) {
                $ranges.outerWidth('auto');
                return $container.outerWidth(630 + widgetListWidth);
            }

            $container.outerWidth(336 + widgetListWidth);
            $ranges.outerWidth(316);
            // if there are no space to show date ranges on the left side will override css properties and show ranges on the right side
        } else {
            //override float property for presets date list and dateRanges container
            $widgetList.css({
                float: 'left'
            });
            $ranges.css({
                float: 'left'
            });

            //will check ability to show datepickers in one row
            if ((bodyWidth - $container.offset().left) > (widgetListWidth + 630)) {
                $ranges.outerWidth('auto');
                return $container.outerWidth(630 + widgetListWidth);
            }

            $container.outerWidth(336 + widgetListWidth);
            $ranges.outerWidth(316);
        }
    }
});

// Make DatePicker capable of easy extension
DatePicker.extend = Backbone.Model.extend;

export default DatePicker;
