export default {
    addToConversationMessage: {
        padding: '0 10px',

        '& button': {
            color: '#fff',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },

    centeredHeading: {
        width: '100%',
        textAlign: 'center',
        borderBottom: '1px solid #ccc',
        lineHeight: '0.1em',
        margin: [10, 0, 25, 0],
        fontSize: 16,

        '& span': {
            background: '#37435c',
            padding: [0, 10],
            color: '#ccc',
        },
    },

    requestTypeHeading: {
        display: 'flex',
        '& > :first-child': {
            flexBasis: '45%',
        },
        '& div': {
            color: '#ccc',
            fontSize: '14px',
            marginBottom: '5px',
        },
    },

    requestType: {
        display: 'flex',
        paddingBottom: '15px',
    },

    requestChoices: {
        flexBasis: '45%',
        '& label': {
            fontWeight: 'normal',
            color: '#ccc',
            paddingRight: '10px',
        },

        '& input[type="radio"]': {
            marginRight: '5px',
        },
    },

    requestTypeReasons: {
        color: '#000',
        flexBasis: '55%',
        '& select': {
            borderRadius: '4px',
            width: '100%',
            padding: '5px 24px 5px 12px',
        },
    },

    msgButtons: {
        display: 'flex',
        justifyContent: 'flex-end',

        '& button': {
            backgroundColor: 'transparent',
            border: 'none',
            paddingLeft: '10px',
            marginTop: '-25px',

            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
};
