import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import Formatter from 'system/utilities/format';
import ReportSetting from 'app/reports/models/lockboxReportSettings';
import $ from 'jquery';
import locale from '@glu/locale';
import moment from 'moment';
import errHandler from 'system/errHandler';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import FlexDropdown from '@glu/flex-dropdown';
import LocationLockboxCollection from 'app/reports/collections/locationLockboxes';
import LockboxTypeCollection from 'app/reports/collections/lockboxTypes';
import LockboxProfileModel from 'app/reports/models/lockboxProfile';
import LockboxProfileCollection from 'app/reports/collections/lockboxProfile';
import LockboxDataRetentionCollection from 'app/reports/collections/lockboxDataRetention';
import Constants from 'app/reports/constants';
import searchFieldsUtil from 'common/util/searchFieldsUtil';
import CollectionComboBoxView from 'common/dynamicPages/views/savedViews/combobox';
import helpPageUtil from 'common/util/helpPage';
import template from './reportSettings.hbs';

export default Layout.extend({
    template,

    ui: {
        $datePicker: 'input[name="VALUE_DATE"]',
        $transactionTypes: '.transaction-type-list input[type="checkbox"]',
        $lockboxLocations: '[data-region="locationLockboxesRegion"]',
        $readOnlyDate: '[data-hook="static_date"]',
        $readOnlyTypes: '[data-hook="static_types"]',
        $readOnlyLbx: '[data-hook="static_lbx"]',
        $lbxSearchForm: '.lbx-search',
        $lbxCriteria: '.lbx-criteria',
    },

    events: {
        'change @ui.$transactionTypes': 'changeTransactionTypeHandler',
    },

    initialize() {
        this.model = new ReportSetting();
        this.setAllPromises();
        this.loadRequiredData();
    },

    /**
     * @method toggleSearchCriteria
     * - fadeToggle between the search form, and the readOnly search criteria
     */
    toggleSearchCriteria() {
        const self = this;
        this.ui.$lbxCriteria.fadeToggle('fast', () => {
            self.ui.$lbxSearchForm.fadeToggle('fast');
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.renderDatePicker();
            this.renderLockboxFlexDropdown();
            this.renderLockboxProfiles(true);
            this.setTransactionTypes();
        }
    },

    renderDatePicker() {
        const self = this;
        const maxDays = this.lbxRetentionData.at(0).get('LBXMAXDAYS');

        if (store.get('lbxReportData')) {
            this.model.set('VALUE_DATE', store.get('lbxReportData').range);
        }

        this.model.setMaxDays(maxDays);
        this.ui.$datePicker.nhDatePicker({
            showCalendarIcon: true,
            showDropdowns: true,
            rangeDatePicker: true,
            allowPreviousYearInRange: true,
            allowWeekends: true,
            daysBack: this.lbxRetentionData.at(0).get('LBXDAYSAVAIL'),
        });

        this.listenTo(this.model, 'change:VALUE_DATE', this.updateSearchCriteriaMarkup);

        $('.daterangepicker .ranges li').click((e) => {
            self.selectedDateType = e.currentTarget.id;
            if (self.selectedDateType.length === 0) {
                self.selectedDateType = 'OTHER';
            }
        });
    },

    renderLockboxFlexDropdown() {
        const flexOptions = {
            multiSelect: true,
            data: this.lockboxLocationsData,
            defaultSelectMessage: locale.get('LBX.report.settings.default.select.message'),
            showTooltip: true,
            selectAllBtn: true,
        };
        if (this.preSelectedIds) {
            flexOptions.preSelectedIds = this.preSelectedIds;
        }
        this.flexDropdown = new FlexDropdown(flexOptions);
        this.listenTo(this.flexDropdown, 'selectionChanged', this.handleLockboxLocationChange);
        this.locationLockboxesRegion.show(this.flexDropdown);
    },

    renderLockboxProfiles(showNoSelectionText) {
        let localShowNoSelectionText = showNoSelectionText;
        const startIndex = this.indexOfSelectedProfile || 0;

        const options = {
            collection: this.lockboxProfileCollection,
            noSelectionText: locale.get('LBX.AddNewReport'),
            addButtonText: locale.get('LBX.AddNewReport'),
            canAdd: true,
            canEdit: 'true',
        };

        if (!localShowNoSelectionText) {
            options.selected = this.lockboxProfileCollection.at(startIndex);
        } else {
            localShowNoSelectionText = false;
        }

        this.comboBoxView = new CollectionComboBoxView(options);

        this.listenTo(this.comboBoxView, 'create', this.createReportProfile);
        this.listenTo(this.comboBoxView, 'remove', this.itemDestroyed);
        this.listenTo(this.comboBoxView, 'cleared', this.selectionCleared);
        this.listenTo(this.comboBoxView, 'select', this.itemSelected);
        this.comboBoxRegion.show(this.comboBoxView);
        this.updateSearchCriteriaMarkup();
    },

    /**
     * @method updateSearchCriteriaMarkup
     * - takes the values from the model, $transactionTypes, adn flexDropdown to
     * update the readOnly search criteria
     */
    updateSearchCriteriaMarkup() {
        const self = this;
        let selectedTypes = [];
        this.ui.$readOnlyDate.text(this.model.get('VALUE_DATE'));

        this.ui.$transactionTypes.each(function () {
            if (this.checked) {
                selectedTypes.push(self.$(this).attr('data-hook'));
            }
        });

        if (selectedTypes.length === this.ui.$transactionTypes.length) {
            selectedTypes = [locale.get('PAY.All')];
        }

        this.ui.$readOnlyTypes.html(selectedTypes.join(', '));

        // get the list of selected lockboxes
        const selectedModels = this.flexDropdown.model.get('selection').models;

        const lockboxIdList = util.chain(selectedModels)
            .map((model) => {
            // if model is a location, return all child lockbox IDs.
                if (model.has('child')) {
                    return util.pluck(model.get('child'), 'name');
                }
                return model.get('name');
            })
            .flatten()
            .uniq()
            .value();

        this.ui.$readOnlyLbx.text(lockboxIdList.join(', '));
    },

    handleLockboxLocationChange(selectedLockboxes) {
        const $wrapper = $(this.ui.$lockboxLocations).parent();
        const hasError = $wrapper.hasClass('has-error');

        if (selectedLockboxes.length > 0 && hasError) {
            $wrapper.removeClass('has-error');
            const helpText = $wrapper.find('.field-info');
            helpText.text('');
        }
        this.updateSearchCriteriaMarkup();
    },

    changeTransactionTypeHandler(e) {
        const selectedItem = e.target;
        const isChecked = $(selectedItem).is(':checked');
        const $wrapper = $(selectedItem).parent().parent();

        if (isChecked && $wrapper.hasClass('has-error')) {
            $wrapper.removeClass('has-error');
            const helpText = $(selectedItem).parent().siblings('.field-info');
            helpText.text('');
        }
        this.updateSearchCriteriaMarkup();
    },

    setAllPromises() {
        this.lbxPromises = [];
        this.setHelpPagePromise();
        this.setLocationLockboxPromise();
        this.setLockboxTypePromise();
        this.setLockboxProfilePromise();
        this.setLockboxDataRetentionPromise();
    },

    setHelpPagePromise() {
        // get the workspace help file for quick entry
        store.unset('helpPage');
        this.lbxPromises.push(helpPageUtil.getHelpPagePromise({
            productCode: 'GIR',
            functionCode: 'LOCKBOX',
            typeCode: 'LBX_TNP',
            mode: 'SELECT',
        }));
    },

    setLocationLockboxPromise() {
        this.lbxPromises.push(new Promise((resolve, reject) => {
            new LocationLockboxCollection().fetch({
                success: resolve,
                error: reject,
            });
        }));
    },

    setLockboxTypePromise() {
        this.lbxPromises.push(new Promise((resolve, reject) => {
            new LockboxTypeCollection().fetch({
                success: resolve,
                error: reject,
            });
        }));
    },

    setLockboxProfilePromise() {
        const self = this;
        this.lockboxProfileCollection = new LockboxProfileCollection();
        this.lbxPromises.push(new Promise((resolve, reject) => {
            self.lockboxProfileCollection.fetch({
                success: resolve,
                error: reject,
            });
        }));
    },

    setLockboxDataRetentionPromise() {
        const self = this;
        this.lbxRetentionData = new LockboxDataRetentionCollection();
        this.lbxPromises.push(new Promise((resolve, reject) => {
            self.lbxRetentionData.fetch({
                success: resolve,
                error: reject,
            });
        }));
    },

    setTransactionTypes(source) {
        let localSource = source;
        if (!localSource && store.get('lbxReportData')) {
            localSource = store.get('lbxReportData').types;
        }

        util.each(this.ui.$transactionTypes, (type) => {
            const value = $(type).val();

            if (localSource && localSource.indexOf(value) > -1) {
                $(type).prop('checked', true);
            }
        });
    },

    getProfilePromises(model) {
        const self = this;
        const reportId = model.get('reportId');

        this.transactionList = new LockboxProfileCollection({
            type: 'transactions',
            rid: reportId,
        });
        this.lockboxList = new LockboxProfileCollection({
            type: 'lockbox',
            rid: reportId,
        });

        const profilePromises = [];

        profilePromises.push(new Promise((resolve, reject) => {
            self.transactionList.fetch({
                success: resolve,
                error: reject,
            });
        }));

        profilePromises.push(new Promise((resolve, reject) => {
            self.lockboxList.fetch({
                success: resolve,
                error: reject,
            });
        }));

        this.flexDropdown.trigger('exportSelections', this.flexDropdown.model);

        Promise.all(profilePromises)
            .then((results) => {
                const [profileTransactions] = results;
                const profileLockboxes = results[1];
                const types = profileTransactions.pluck('type');

                self.preSelectedIds = profileLockboxes.map(lockbox => `lbx${lockbox.get('lockboxId')}`);

                /**
                 * The flexDropdown is not intelligent enough to know when all children have
                 * been selected
                 * Handle that behavior here. Filter all locations where all child items are
                 * preSelected, then return locationId
                 */
                const preSelectedLocations = util.chain(self.lockboxLocationsData)
                    .filter(location => util.every(
                        location.child,
                        lockbox => self.preSelectedIds.indexOf(lockbox.id) > -1,
                    )).map(location => location.id).value();

                self.preSelectedIds = self.preSelectedIds.concat(preSelectedLocations);
                self.indexOfSelectedProfile = self.lockboxProfileCollection.indexOf(model);
                self.renderDatePicker();
                self.renderLockboxFlexDropdown();
                self.renderLockboxProfiles();
                self.setTransactionTypes(types);
                self.updateSearchCriteriaMarkup();
            })
            .then(null, errHandler);
    },

    loadRequiredData() {
        Promise.all(this.lbxPromises)
            .then((results) => {
                if (results[0].helpPage) {
                    store.set('helpPage', results[0].helpPage);
                }
                if (results[1].length > 0) {
                    this.lockboxLocationsData = results[1].models[0].get('locationLockboxes');
                } else {
                    this.lockboxLocationsData = [];
                }

                this.lockboxTypeData = results[2].models[0].get('lockboxTypes');
                this.setHasLoadedRequiredData(true);
                this.render();
            })
            .then(null, errHandler);
    },

    calculateMaxDays() {
        const range = this.model.get('VALUE_DATE').split(' - ');
        const min = moment(range[0], userInfo.getDateFormat());
        const max = moment(range[1], userInfo.getDateFormat());
        let days = max.diff(min, 'days');

        /*
         * Setting days to 1 so if Single day is selected Validator returns true
         * instead of false
         */
        if (min.isSame(max)) {
            days = 1;
        }

        this.model.set('LBXMAXDAYS', days);
    },

    getSelectedLockboxes() {
        const selectedModels = this.flexDropdown.model.get('selection').models;

        return util.chain(selectedModels)
            .reject(model => !model.get('id'))
            .map((model) => {
                // if model is a location, return all child lockbox IDs.
                if (model.has('child')) {
                    return util.pluck(model.get('child'), 'id');
                }
                return model.get('id');
            })
            .flatten()
            .map((id) => {
                if (id) {
                    // remove 'lbx' prefix
                    return id.replace(/\D/g, '');
                }
                return undefined;
            })
            .uniq()
            .value();
    },

    resetReport() {
        const self = this;
        this.model.set({
            VALUE_DATE: '',
            VALUE_LOCKBOXLIST: '',
            VALUE_TRANSACTION_TYPES: '',
        });

        this.flexDropdown.setSelectedIds(null);

        // set all types unchecked
        util.each(this.ui.$transactionTypes, (typeParam) => {
            const type = typeParam;
            type.checked = false;
        });

        if (!this.ui.$lbxSearchForm.is(':visible')) {
            this.ui.$lbxCriteria.fadeToggle('fast', () => {
                if (self.ui.$lbxSearchForm.hasClass('hidden')) {
                    self.ui.$lbxSearchForm.removeClass('hidden');
                } else {
                    self.ui.$lbxSearchForm.fadeToggle('fast');
                }
            });
        }
        this.selectionCleared();
        this.trigger('reset');
    },

    setDates(date) {
        this.model.set({
            VALUE_DATE: date,
        });
    },

    createReportProfile(report) {
        const profileModel = new LockboxProfileModel();

        const reportName = {
            REPORT_NAME: report.value,
            DATETYPE: '',
        };

        const range = this.model.get('VALUE_DATE').split(' - ');

        const isExistingReportProfile = this.comboBoxView.collection.get(report.value);

        if (isExistingReportProfile) {
            profileModel.set('id', report.value);
            profileModel.set('REPORT_ID', isExistingReportProfile.get('reportId'));
        }

        if (range.length === 2) {
            [reportName.FROM_DATE, reportName.TO_DATE] = range;
        } else {
            const today = moment(new Date()).format(userInfo.getDateFormat());
            reportName.FROM_DATE = today;
            reportName.TO_DATE = today;
        }

        if (this.selectedDateType) {
            switch (this.selectedDateType) {
            case 'dateRange0':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_LAST_7;
                break;
            case 'dateRange1':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_LAST_30;
                break;
            case 'dateRange2':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_LAST_60;
                break;
            case 'dateRange3':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_LAST_90;
                break;
            case 'dateRange4':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_MONTH_TO_DATE;
                break;
            case 'dateRange5':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_QUARTER_TO_DATE;
                break;
            case 'dateRange6':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_YEAR_TO_DATE;
                break;
            case 'dateRange7':
                this.selectedPredefinedDateKey = Constants.LOCKBOX_PROFILE_TODAY;
                break;
            default:
                this.selectedPredefinedDateKey = 'OTHER';
            }

            reportName.DATETYPE = this.selectedPredefinedDateKey;
        }

        this.reportName = reportName;

        if (isExistingReportProfile) {
            this.destroyLockboxProfile(profileModel);
        } else {
            this.saveLockboxProfile(profileModel);
        }

        this.$('[data-hook="selectList"]').comboBox('close');
    },

    destroyLockboxProfile(profileModel) {
        const self = this;
        profileModel.destroy({
            success() {
                self.saveLockboxProfile();
            },

            error() {
                self.comboBoxView.collection.fetch();
            },
        });
    },

    saveLockboxProfile(profileModel) {
        let localProfileModel = profileModel;
        const self = this;

        if (!localProfileModel) {
            localProfileModel = new LockboxProfileModel();
        }

        localProfileModel.save(
            this.reportName,
            {
                success() {
                    self.addUpdateTransactionTypes(localProfileModel.reportID());
                },

                error() {
                    self.comboBoxView.collection.fetch();
                },
            },
        );
    },

    addUpdateTransactionTypes(rid) {
        const selectedTypes = this.$('[name="lockboxType"]:checked');
        const typeArr = [];

        if (selectedTypes.length > 0) {
            this.transactionPromises = [];
            for (let x = 0; x < selectedTypes.length; x += 1) {
                typeArr.push([{
                    name: 'TRANSTYPE',
                    value: $('[name="lockboxType"]:checked')[x].value,
                }]);
            }
            for (let t = 0; t < typeArr.length; t += 1) {
                const transactionModel = new LockboxProfileModel({
                    type: 'transactions',
                    selections: typeArr[t],
                });
                transactionModel.set({
                    REPORT_ID: rid,
                });
                this.transactionPromises.push(this.getSaveTransactionPromise(transactionModel));
            }
            this.saveTransactionRecords(rid);
        } else {
            this.addUpdateLockboxLocations(rid);
        }
    },

    saveTransactionRecords(rid) {
        const self = this;
        Promise.all(this.transactionPromises)
            .then(() => {
                self.addUpdateLockboxLocations(rid);
            })
            .then(null, errHandler);
    },

    saveLockboxRecords(rid) {
        const self = this;
        Promise.all(this.lockboxPromises)
            .then(() => {
                self.refreshComboBox(rid);
            })
            .then(null, errHandler);
    },

    getSaveTransactionPromise(transactionModel) {
        return new Promise((resolve, reject) => {
            transactionModel.save(
                null,
                {
                    success(model, response) {
                        resolve(response);
                    },

                    error(response) {
                        reject(response);
                    },
                },
            );
        });
    },

    getSaveLockboxPromise(lockboxModel) {
        return new Promise((resolve, reject) => {
            lockboxModel.save(
                null,
                {
                    success(model, response) {
                        resolve(response);
                    },

                    error(response) {
                        reject(response);
                    },
                },
            );
        });
    },

    addUpdateLockboxLocations(rid) {
        const lockboxesArr = [];
        const { data } = this.flexDropdown.model;

        this.lockboxPromises = [];
        util.each(data, (locations) => {
            if (locations.id === 'all') {
                return;
            }
            util.each(locations.child, (lockbox) => {
                if (lockbox.selected || locations.selected) {
                    const id = lockbox.id.replace(/\D/g, '');

                    lockboxesArr.push([{
                        name: 'LOCKBOX_ID',
                        value: id,
                    }, {
                        name: 'LOCATION_ID',
                        value: locations.id,
                    }]);
                }
            });
        });

        for (let l = 0; l < lockboxesArr.length; l += 1) {
            lockboxesArr[l].push({
                name: 'REPORT_ID',
                value: rid,
            });

            const lockboxModel = new LockboxProfileModel({
                type: 'lockbox',
                selections: lockboxesArr[l],
            });

            this.lockboxPromises.push(this.getSaveLockboxPromise(lockboxModel));
        }
        this.saveLockboxRecords(rid);
    },

    refreshComboBox(rid) {
        const self = this;
        this.lockboxProfileCollection = new LockboxProfileCollection();
        this.lockboxProfileCollection.fetch({
            success() {
                if (rid) {
                    const model = self.lockboxProfileCollection.findWhere({
                        reportId: rid,
                    });
                    self.indexOfSelectedProfile = self.lockboxProfileCollection.indexOf(model);
                }
                self.renderLockboxProfiles(false);
            },
        });
    },

    itemDestroyed(model) {
        const profile = new LockboxProfileModel({
            type: 'profile',
        });

        const self = this;

        profile.set({
            id: model.get('reportId'),
            REPORT_ID: model.get('reportId'),
        });
        profile.destroy({
            success() {
                self.refreshComboBox();
            },
        });
    },

    selectionCleared() {
        this.renderLockboxProfiles(true);
    },

    setValueDate(momentDateObj) {
        this.model.set('VALUE_DATE', `${momentDateObj[0].format(userInfo.getDateFormat())} - ${momentDateObj[1].format(userInfo.getDateFormat())}`);
    },

    itemSelected(model) {
        const dateType = model.get('dateType');

        switch (dateType) {
        case Constants.LOCKBOX_PROFILE_LAST_7:
            this.setValueDate([moment(new Date()).subtract(6, 'days'), moment(new Date())]);
            break;
        case Constants.LOCKBOX_PROFILE_LAST_30:
            this.setValueDate([moment(new Date()).subtract(29, 'days'), moment(new Date())]);
            break;
        case Constants.LOCKBOX_PROFILE_LAST_60:
            this.setValueDate([moment(new Date()).subtract(59, 'days'), moment(new Date())]);
            break;
        case Constants.LOCKBOX_PROFILE_LAST_90:
            this.setValueDate([moment(new Date()).subtract(89, 'days'), moment(new Date())]);
            break;
        case Constants.LOCKBOX_PROFILE_MONTH_TO_DATE:
            this.setValueDate([moment(new Date()).startOf('month'), moment(new Date())]);
            break;
        case Constants.LOCKBOX_PROFILE_QUARTER_TO_DATE:
            this.setValueDate([moment(new Date()).startOf('quarter'), moment(new Date())]);
            break;
        case Constants.LOCKBOX_PROFILE_YEAR_TO_DATE:
            this.setValueDate([moment(new Date()).startOf('year'), moment(new Date())]);
            break;
        case Constants.LOCKBOX_PROFILE_TODAY:
            this.setValueDate([moment(new Date()), moment(new Date())]);
            break;
        default:
            this.model.set('VALUE_DATE', `${model.get('fromDate')} - ${model.get('toDate')}`);
        }

        this.getProfilePromises(model);
    },

    submit() {
        const self = this;
        const lockboxIdList = this.getSelectedLockboxes();
        let selectedTransactionTypes = '';

        this.calculateMaxDays();

        if (lockboxIdList.length > 0) {
            this.model.set('VALUE_LOCKBOXLIST', lockboxIdList);
        }

        // set transaction type
        util.each(this.ui.$transactionTypes, (type) => {
            if ($(type).is(':checked')) {
                if (selectedTransactionTypes === '') {
                    selectedTransactionTypes = [];
                }

                selectedTransactionTypes.push({
                    value: type.value,
                    name: $(type).attr('data-hook'),
                });
            }
        }, this);

        this.model.set('VALUE_TRANSACTION_TYPES', selectedTransactionTypes);

        if (this.model.validate()) {
            this.model.trigger('invalid');
            if (this.ui.$lbxCriteria.is(':visible')) {
                this.ui.$lbxCriteria.fadeToggle('fast', () => {
                    self.ui.$lbxSearchForm.fadeToggle('fast');
                });
            }
            return;
        }

        this.trigger(
            'submit',
            {
                model: this.model,
                flexModel: this.flexDropdown.model,
            },
        );
        this.auditLockboxReportRequest(this.getAuditLockboxReportRequest());

        if (!this.ui.$lbxCriteria.is(':visible')) {
            this.ui.$lbxSearchForm.fadeToggle('fast', () => {
                if (self.ui.$lbxCriteria.hasClass && self.ui.$lbxCriteria.hasClass('hidden')) {
                    self.ui.$lbxCriteria.removeClass('hidden');
                } else if (self.ui.$lbxCriteria.fadeToggle) {
                    self.ui.$lbxCriteria.fadeToggle('fast');
                }
            });
        }
    },

    auditLockboxReportRequest(auditInquiryRequestData) {
        const auditurl = services.generateUrl('/lockbox/auditInquiryRequest');
        http.post(auditurl, auditInquiryRequestData);
    },

    getSelectedTypeNames() {
        return util.map(this.model.get('VALUE_TRANSACTION_TYPES'), item => `'${item.name}'`);
    },

    getSelectedLockBoxNames() {
        return util.chain(this.flexDropdown.model.get('selection').models)
            .map((model) => {
                // if model is a location, return all child lockbox IDs.
                if (model.has('child')) {
                    return util.pluck(model.get('child'), 'name');
                }
                return model.get('name');
            })
            .flatten()
            .uniq()
            .value();
    },

    formatDate(dateValue) {
        return Formatter.formatDate(moment(dateValue, userInfo.getDateFormat()), 'YYYY-MM-DD');
    },

    createDateSearchField(fieldName, dateRange) {
        const formattedDateRange = [this.formatDate(dateRange[0]), this.formatDate(dateRange[1])];
        return searchFieldsUtil.createDateRangeSearchField(fieldName, formattedDateRange);
    },

    getAuditLockboxReportRequest() {
        const dateRange = this.model.get('VALUE_DATE').split(' - ');
        const dateField = this.createDateSearchField('VALUE_DATE', dateRange);
        const typesField = searchFieldsUtil.createTextInSearchField('TRANSACTION_TYPES', this.getSelectedTypeNames());
        const lockboxesField = searchFieldsUtil.createTextInSearchField('LOCKBOXES', this.getSelectedLockBoxNames());

        return {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'LBX_RPT',
                        productCode: 'GIR',
                        functionCode: 'LOCKBOX',
                        actionMode: 'SELECT',
                    },

                    searchFields: [
                        dateField,
                        typesField,
                        lockboxesField,
                    ],
                },
            },
        };
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    templateHelpers() {
        const self = this;
        return {
            lockboxTypes() {
                return self.lockboxTypeData;
            },
        };
    },
});
