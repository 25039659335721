var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"full-page\">\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.reportGroup.LGREP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":69}}}))
    + "</h1>\n\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n    </div>\n\n    <section class=\"section section-container\">\n        <div class=\"legacyReportSearchCriteria\">\n            <h3>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.SearchCriteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":47}}}))
    + " <button type=\"button\" data-hook=\"getToggleSearchFilterBtn\"\n                    class=\"btn btn-tertiary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.editOption",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":45},"end":{"line":14,"column":79}}}))
    + "</button>\n            </h3>\n            <div class=\"searchData\"></div>\n        </div>\n        <div class=\"legacyReportSearchFilters\">\n            <div data-region=\"filterRegion\"></div>\n            <button type=\"button\" id=\"legacySearch\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":76},"end":{"line":20,"column":102}}}))
    + "</button>\n        </div>\n    </section>\n    <section class=\"section section-container\">\n        <div class=\"widget-container\">\n            <div class=\"page-content\" data-region=\"legacyReport\"></div>\n        </div>\n    </section>\n</div>\n";
},"useData":true});