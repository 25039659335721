import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import tableMaintApi from 'system/webseries/api/tableMaintenance';
import Beneficiary from 'app/setup/models/beneficiary';
import Collection from '@glu/core/src/collection';
import CollectionComboBoxView from 'common/collectionComboBox/views/base';
import viewBeneficiaryTmpl from './viewBeneficiary.hbs';

export default Layout.extend({
    initialize(opts) {
        const self = this;
        this.stateCodesCollection = opts.stateCodesCollection || new Collection();
        this.countryCodesCollection = opts.countryCodesCollection || new Collection();
        this.bankCodesCollection = opts.bankCodesCollection || new Collection();
        this.currencyCodesCollection = opts.currencyCodesCollection || new Collection();
        this.title = opts.title || '';
        this.beneficiary = opts.id;
        tableMaintApi.getData(self.beneficiary).done((response) => {
            const beneficaryData = {};
            $.each(response.item, (key, value) => {
                $.each(value, () => {
                    beneficaryData[self.parseName(value.name)] = value.value;
                });
            });
            self.model.set(beneficaryData);
        });
    },

    template: viewBeneficiaryTmpl,
    model: new Beneficiary(),

    onRender() {
        this.renderStateContent();
        this.renderCountryContent();
        this.renderBankContent();
        this.renderCurrencyContent();
    },

    /**
     * Render the state region.
     */
    renderStateContent() {
        const self = this;

        const stateCodeComboBoxView = new CollectionComboBoxView({
            collection: self.stateCodesCollection,
            isDisabled: true,
        });
        this.stateRegion.show(stateCodeComboBoxView);
    },

    /**
     * Render the country region.
     */
    renderCountryContent() {
        const self = this;

        const countryCodeComboBoxView = new CollectionComboBoxView({
            collection: self.countryCodesCollection,
            isDisabled: true,
        });

        this.countryRegion.show(countryCodeComboBoxView);
    },

    /**
     * Render the bank region.
     */
    renderBankContent() {
        const self = this;

        const bankCodeComboBoxView = new CollectionComboBoxView({
            collection: self.bankCodesCollection,
            isDisabled: true,
        });

        this.bankRegion.show(bankCodeComboBoxView);
    },

    /**
     * Render the currency region.
     */
    renderCurrencyContent() {
        const self = this;

        const currencyCodeComboBoxView = new CollectionComboBoxView({
            collection: self.currencyCodesCollection,
            isDisabled: true,
        });

        this.currencyRegion.show(currencyCodeComboBoxView);
    },

    // convert name into an acceptable format for the model
    parseName(name) {
        let localName = name;
        localName = localName.toLowerCase();
        if (localName.indexOf('_') > -1) {
            const nameObj = localName.split('_');
            [localName] = nameObj;
            for (let x = 1; x < nameObj.length; x += 1) {
                if (nameObj[x].length > 1) {
                    nameObj[x] = nameObj[x].substring(0, 1).toUpperCase()
                        + nameObj[x].substring(1, nameObj[x].length);
                }
                localName += nameObj[x];
            }
        }
        return localName;
    },

    viewModify() {
        const beneficiaryId = this.model.get('tnum');
        this.navigateTo(`SETUP/beneBookInquiry/modify/${beneficiaryId}`);
    },

    viewRemove() {
        const beneficiaryId = {
            id: this.model.get('tnum'),
        };
        tableMaintApi.removeData(beneficiaryId);
        this.navigateTo('SETUP/beneBookInquiry');
    },

    viewCancel() {
        window.history.back();
    },
});
