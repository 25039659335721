import React, {
  createContext, useState, useEffect, useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line import/no-named-as-default
import Snackbar from '../Snackbar/Snackbar';
import SnackbarContainer from '../SnackbarContainer/SnackbarContainer';
import styles from './SnackbarManager.styles';

export const SnackbarContext = createContext({});

export const SnackbarManager = ({
  classes, displayedInternally, children, dismissTimeout, className
}) => {
  const [messages, setMessages] = useState([]);
  const [queuedForRemoval, setQueuedForRemoval] = useState([]);
  const [timeouts, setTimeouts] = useState([]);
  const dismissMessage = useCallback(({ id }) => {
    setMessages(ms => ms.filter(m => m.id !== id));
  }, []);

  const addMessage = useCallback(message => {
    setMessages(m => m.concat({
      ...message,
      autoDismiss: message.autoDismiss ?? (!message.actions || message.actions.length === 0),
      id: message.id ?? uuid()
    }));
  }, []);

  const hasMultiple = useMemo(() => messages.length > 1, [messages]);

  useEffect(() => {
    messages.forEach((m) => {
      if (!queuedForRemoval.includes(m.id) && m.autoDismiss) {
        setQueuedForRemoval(q => [...q, m.id]);
        const timeout = setTimeout(() => {
          setQueuedForRemoval(q => q.filter(id => id !== m.id));
          setTimeouts(t => t.filter(id => id !== timeout));
          dismissMessage(m);
        }, dismissTimeout);
        setTimeouts(t => [...t, timeout]);
      }
      return () => timeouts.map(timeout => clearTimeout(timeout));
    });
  }, [dismissTimeout, messages, dismissMessage]);

  const valueMemo = useMemo(() => ({
    messages,
    addMessage,
    dismissMessage,
    hasMultiple
  }), [messages, addMessage, dismissMessage, hasMultiple]);
  return (
    <SnackbarContext.Provider value={valueMemo}>
      {children}
      {displayedInternally
        ? (
          <SnackbarContainer className={className}>
            <TransitionGroup>
              {(messages).map((m) => (
                <CSSTransition
                  key={m.id}
                  timeout={300}
                  classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive
                  }}
                >
                  <Snackbar
                    {...m}
                    dismissMessage={dismissMessage}
                    hasMultiple={messages.length > 1}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </SnackbarContainer>
        )
        : null}
    </SnackbarContext.Provider>
  );
};

SnackbarManager.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,

  /** className for internally displayed container */
  className: PropTypes.string,

  /** timeout for auto-dismissible messages */
  dismissTimeout: PropTypes.number,

  /** when true will manage the display of the snackbars internally */
  displayedInternally: PropTypes.bool
};

SnackbarManager.defaultProps = {
  className: '',
  dismissTimeout: 5000,
  displayedInternally: true
};

export default withStyles(styles)(SnackbarManager);
