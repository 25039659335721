import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import contentEditTmpl from './contentEdit.hbs';

export default Layout.extend({
    template: contentEditTmpl,
    className: 'modal deployment-modal',

    ui: {
        $saveContentEditBtn: '.save-content-edit',
        $marketSegmentSelect: '#external-widget-segment',
    },

    events: {
        'click @ui.$saveContentEditBtn': 'saveContentModel',
    },

    initialize(options) {
        // Generate an array of segements to pass to the collection.
        const { rows } = this.model.get('segments');

        // Accumulate the column name/value pairs. Could we use transform.pairsToHash here?
        const segments = rows.map(row => row.columns.reduce((acc, col) => {
            acc[col.fieldName] = col.fieldValue;
            return acc;
        }, {}));

        this.segments = new Collection(segments);

        this.externalWidgetContentModel = new Model({
            widgetId: parseFloat(options.widgetId),
            marketSegment: this.segments.first().get('NAME'),
        });
    },

    saveContentModel() {
        const selectedVal = this.ui.$marketSegmentSelect.val();
        this.externalWidgetContentModel.set('marketSegment', selectedVal);

        if (this.externalWidgetContentModel.get('marketSegment') === undefined
            || this.externalWidgetContentModel.get('marketSegment').length < 1) {
            return;
        }

        // ExternalWidgetContentModel example
        /*
         * {
         *   "widgetId": 123,
         *   "marketSegment": "Default",
         *   "htmlKeyValues": {
         *     "id1": "value1",
         *     "id2": "value2"
         *     "id3": "value3"
         *   }
         * }
         */

        const data = {
            id1: 'value1',
            id2: 'value2',
        };

        this.externalWidgetContentModel.set('htmlKeyValues', data);

        http.post(
            services.generateUrl('/cxp/externalWidget/saveWidgetContent'),
            this.externalWidgetContentModel.toJSON(),
            () => {
                // FIXME: This code does NOTHING.
            },
        );
    },

    templateHelpers() {
        return {
            segments: this.segments.toJSON(),
        };
    },
});
