import Layout from '@glu/core/src/layout';
import userInfo from 'etc/userInfo';
import { appBus } from '@glu/core';
import miSnapImageTmpl from './miSnapImage.hbs';

export default Layout.extend({
    template: miSnapImageTmpl,
    className: 'Misnap-container',

    events: {
        'click [data-hook="getInitiateMiSnap"]': 'initiateMiSnap',
    },

    templateHelpers() {
        return {
            getImageLabel: this.label,
            imgBase: `${window.Bottomline.assetRoot}/images/${window.Bottomline.buildId}/`,
            image: this.model.get(this.options.checkSide),
        };
    },

    initialize(options) {
        this.model = options.model;
        this.label = options.label;
        this.listenTo(this.model, `change:${this.options.checkSide}`, this.render);
    },

    /**
     * When the user is on mobile, send notification to native for MiSnap
     */
    initiateMiSnap() {
        if (userInfo.has('mobileAppPlatform')) {
            if (this.options.amountValidationWorkflow
                && this.model.get(this.options.checkSide)) {
                return;
            }
            appBus.trigger('rdc:capture:image', this.options.checkSide);
        }
    },
});
