import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import PaymentModel from 'app/smbPayments/models/payment';
import nhUtil from 'system/utilities/accessors';
import { appBus } from '@glu/core';
import SmbPayNowView from 'app/smbPayments/views/payments/payNow/payNowLayout';
import SmbConfirmPayNowView from 'app/smbPayments/views/payments/payNow/confirmPayNowLayout';

export default Layout.extend({
    initialize(options) {
        this.model = options.model;
    },

    template: util.template(''),

    appEvents: {
        'payment:payNow:submit': 'submit',
        'payment:payNow:pay': 'pay',
        'payment:payNow:edit': 'edit',
    },

    onRender() {
        this.renderPayNow();
    },

    renderPayNow(model) {
        const self = this;

        if (model !== undefined) {
            this.model = model;
        }

        const smbPayNowView = new SmbPayNowView({
            model: this.model,
        });

        smbPayNowView.on('submit', (aModel, accounts, vendors) => {
            self.submit(aModel, accounts, vendors);
        });
        smbPayNowView.on('cancel', () => {
            Dialog.close();
        });

        Dialog.open(smbPayNowView);
    },

    submit(model, account, vendors) {
        const self = this;

        const isValid = model.isValid();
        if (isValid) {
            Dialog.close();

            const smbConfirmPayNowView = new SmbConfirmPayNowView({
                model,
                account,
                vendors,
            });

            Dialog.open(smbConfirmPayNowView);

            smbConfirmPayNowView.on('pay', (aModel, aAccount, aVendors) => {
                Dialog.close();
                self.pay(aModel, aAccount, aVendors);
            });
            smbConfirmPayNowView.on('edit', (aModel) => {
                Dialog.close();
                self.edit(aModel);
            });
        }
    },

    /**
     * Handle the pay action.
     */
    pay(model, account, vendors) {
        this.appBus.trigger('bills:outstandingBill:paid');

        // this.appBus.trigger('payment:newPayment:gridAlert', model);

        this.schedulePayment(model, account, vendors);
    },

    schedulePayment(model, account, vendors) {
        const self = this;
        const paymentModel = new PaymentModel();
        // should be: SOURCEID is === BENE_ACCOUNTNUMBER
        const vendor = self.findInCollection(vendors, model, 'BENE_NAME');
        if (vendor) {
            const payment = paymentModel.convertBillToPayment(model, vendor, account);
            self.processBillPayment(payment);

            nhUtil.setFieldValue(model, 'STATUS_DESCRIPTION', 'Scheduled');
            nhUtil.setFieldValue(model, 'STATUS', 'SC');

            /*
             * remove bill from table , remove ok button
             * trigger update total bills due
             */
            appBus.trigger('update:billsStatus', model, paymentModel);
        }
    },

    processBillPayment(model) {
        model.save(
            {},
            {
                success(aModel) {
                    appBus.trigger('smbPayments:paymentAdded');
                    appBus.trigger('payment:newPayment:gridAlert', aModel, locale.get('sbPayments.successfulSubmitMsg'));
                    appBus.trigger('bill:newBill:billAdded');
                },

                error(aModel, options) {
                    appBus.trigger('payment:newPayment:modalAlert', options);
                },
            },
        );
    },

    findInCollection(vendorCollection, billModel, fieldName) {
        const foundItem = util.filter(vendorCollection.models, (model) => {
            const value = model.get(fieldName);
            const columns = billModel.get('columns');
            const source = util.findWhere(
                columns,
                {
                    fieldName: 'VENDORNAME',
                },
            ).fieldValue;

            return (value === source);
        });

        // always send 1st in array
        return foundItem[0];
    },

    edit(model) {
        this.renderPayNow(model);
    },

    toPaymentGridRow(model) {
        model.set({
            due: '6/19/2015',
            vendor: 'Vendor Bank One',
            amount: '21.00',
            total: '21.00',
            account: 'Bank Account - 11222332323  - $1500.00',
            reference: '',
            status: 'Paid',
            paymentStatus: 'Processed',
        });
        return model;
    },
});
