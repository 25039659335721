export default function (form, initialState) {
    const formState = form.formView.state;

    if (initialState && formState === 'insert') {
        form.field('DEBITCURRENCYDESCR').shouldBeHidden();
        form.field('CREDITCURRENCYDESCR').shouldBeHidden();
        form.field('ENTEREDBY').shouldBeHidden();
        form.field('ENTEREDBYNAME').shouldBeHidden();
        form.field('ENTEREDBYUSERGROUP').shouldBeHidden();
        form.field('ENTEREDTIMESTAMP').shouldBeHidden();
    }
}
