import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import assoc from 'lodash/fp/assoc';
import SavedViewsDisplay from './SavedViewsDisplay';
import '../../themeDefaults';
import { columnScrub } from './utils';

export const CHANGE_TYPES = {
  CHANGE: 'CHANGE',
  CHANGE_DEFAULT: 'CHANGE_DEFAULT',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  FETCH: 'FETCH',
  RENAME: 'RENAME',
  UPDATE: 'UPDATE'
};

const getNextActive = ([first]) => first
  && first.viewData.id !== undefined
  && { active: first.viewData.id };

export const iterateName = (name, views) => {
  const nameNotUnique = views.find((view) => view.viewData.name === name);

  if (!nameNotUnique) {
    return name;
  }

  const testExpression = new RegExp(`(${name}-)(\\d+)`);
  const numbers = views
    .map((view) => view.viewData.name.match(testExpression))
    .filter(Boolean)
    .map((matches) => Number(matches[2]));

  let nextAvailable = null;
  let option = 1;

  while (!nextAvailable) {
    if (!numbers.includes(option)) {
      nextAvailable = option;
    }
    option += 1;
  }

  return `${name}-${nextAvailable}`;
};

export function SavedViewsComponent(props) {
  const {
    PopoverProps,
    comparisonConvertor,
    data,
    errors: currentErrors,
    fetchSavedViews,
    id,
    initialData,
    maxViewsCount,
    name,
    onChange,
    onChangeAsync,
    onLoad,
    onLoadError,
    onSave,
    onSaveError,
    saveView,
    suggestedName,
    validate
  } = props;

  const saved = data[name] || {};
  const { active, views = [], headers } = saved;

  const shouldAllowCreation = !maxViewsCount || maxViewsCount > views.length;

  const validateAndDispatch = useCallback((rawUpdate) => {
    const { type, ...update } = rawUpdate;
    const value = {
      active,
      headers,
      views,
      ...update
    };
    const results = {
      errors: validate ? validate(value) : {},
      name,
      type,
      value
    };
    if (onChangeAsync) {
      return onChange(results).then(() => results);
    }
    onChange(results);
    return Promise.resolve(results);
  }, [onChange, onChangeAsync, active, views, name, headers, validate]);

  useEffect(() => {
    let mounted = true;
    fetchSavedViews(id)
      .then((storedViews) => {
        if (!mounted) {
          return {};
        }

        const staticViews = views.filter((view) => view.viewData.isStatic);

        // columns from db should be filtered (in case of add/delete columns)
        const storedViewsFormatted = (data && data.columns && storedViews.map((view) => assoc(
          'columns',
          columnScrub(view.columns, data.columns),
          view
        ))) || storedViews;

        const mergedViews = storedViews.length ? [...staticViews, ...storedViewsFormatted] : views;

        if (active) {
          return validateAndDispatch({
            active,
            type: CHANGE_TYPES.FETCH,
            views: mergedViews
          });
        }

        const defaultViewInStored = storedViews.find(({ viewData }) => (
          viewData && viewData.isDefault
        ));

        const defaultViewInStatic = staticViews.find(({ viewData }) => (
          viewData && viewData.isDefault
        ));

        if (defaultViewInStored || defaultViewInStatic) {
          const defaultView = defaultViewInStored || defaultViewInStatic;

          // When default view is changed, we need to cleanup static views
          // (prevent duplicated default views)
          return validateAndDispatch({
            active: defaultView.viewData.id,
            type: CHANGE_TYPES.FETCH,
            views: defaultViewInStored ? mergedViews.map((view) => {
              if (view.viewData.isStatic) {
                const { isDefault, ...viewData } = view.viewData;
                return {
                  ...view,
                  viewData
                };
              }
              return view;
            }) : mergedViews
          });
        }

        return validateAndDispatch({
          ...getNextActive(mergedViews),
          type: CHANGE_TYPES.FETCH,
          views: mergedViews
        });
      })
      .then(onLoad)
      .catch(onLoadError);
    return () => { mounted = false; };
    /*
     * TODO: React advises against this pattern (even though it is
     * it is in the documentation). Need to revisit and find way to
     * run once while keeping the spirit of the effect.
     * Long issue with suggestions: https://github.com/facebook/react/issues/14920
     * Suggestions: https://reactjs.org/docs/hooks-faq.html#what-can-i-do-if-my-effect-dependencies-change-too-often
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveChange = useCallback(({ id: changeId, newViews, viewId }) => {
    const viewsUpdate = newViews.filter((newView) => !newView.viewData.isStatic);
    saveView({ id: changeId, newViews: viewsUpdate, viewId })
      .then(onSave)
      .catch(onSaveError);
  }, [saveView, onSave, onSaveError]);

  const handleDefaultChange = useCallback((viewId) => {
    const newViews = views.map((view) => ({
      ...view,
      viewData: {
        ...view.viewData,
        isDefault: view.viewData.id === viewId
      }
    }));
    return validateAndDispatch({
      type: CHANGE_TYPES.CHANGE_DEFAULT, views: newViews
    }).then(({ errors }) => {
      if (Object.keys(errors).length) {
        return false;
      }
      return saveChange({ id, newViews, viewId });
    });
  }, [id, saveChange, validateAndDispatch, views]);

  const handleSelectChange = useCallback((view) => () => {
    validateAndDispatch({
      active: view.viewData.id,
      type: CHANGE_TYPES.CHANGE
    });
  }, [validateAndDispatch]);

  const handleSaveView = useCallback((saveName) => {
    // TODO: add error message
    if (!saveName) {
      return Promise.resolve(false);
    }
    const verifiedName = iterateName(saveName, views);
    const { savedViews, ...toSave } = data;
    const viewId = views.filter((view) => Number(view.viewData.id))
      .reduce((maxId, view) => (
        view.viewData.id > maxId ? view.viewData.id : maxId), 0) + 1;

    const newView = {
      viewData: {
        id: viewId,
        name: verifiedName
      },
      ...toSave
    };

    const newViews = [...views, newView];
    return validateAndDispatch({
      active: viewId, type: CHANGE_TYPES.CREATE, views: newViews
    }).then(({ errors }) => {
      if (Object.keys(errors).length) {
        return false;
      }

      return saveChange({ id, newViews });
    });
  }, [data, id, saveChange, validateAndDispatch, views]);

  const handleUpdate = useCallback(() => {
    const { savedViews, ...toSave } = data;
    const newViews = views.reduce((all, view) => {
      if (view.viewData.id !== active) {
        return [...all, view];
      }
      return [...all, { ...view, ...toSave }];
    }, []);
    return validateAndDispatch({ type: CHANGE_TYPES.UPDATE, views: newViews })
      .then(({ errors }) => {
        if (Object.keys(errors).length) {
          return false;
        }

        return saveChange({ id, newViews, viewId: active });
      });
  }, [active, data, id, saveChange, validateAndDispatch, views]);

  const handleDeleteName = useCallback((viewId) => {
    const newViews = views.filter((view) => view.viewData.id !== viewId);
    const update = {
      ...(viewId === active && getNextActive(newViews)),
      type: CHANGE_TYPES.DELETE,
      views: newViews
    };
    return validateAndDispatch(update).then(({ errors }) => {
      if (Object.keys(errors).length) {
        return false;
      }
      return saveChange({ id, newViews, viewId });
    });
  }, [active, id, saveChange, validateAndDispatch, views]);

  const handleRename = useCallback((viewId) => (nameUpdate) => {
    const newViews = views.reduce((all, view) => {
      if (view.viewData.id !== viewId) {
        return [...all, view];
      }
      return [...all,
        {
          ...view,
          viewData: {
            ...view.viewData,
            name: nameUpdate
          }
        }
      ];
    }, []);
    return validateAndDispatch({ type: CHANGE_TYPES.RENAME, views: newViews })
      .then(({ errors }) => {
        if (Object.keys(errors).length) {
          return false;
        }
        return saveChange({ id, newViews, viewId });
      });
  }, [id, saveChange, validateAndDispatch, views]);

  const hasDiverged = () => {
    const { savedViews, ...rest } = data;
    const activeView = views.find((view) => view.viewData.id === active);
    if (!activeView) {
      if (!initialData) {
        return false;
      }
      const { savedViews: initialSaved, ...other } = initialData;

      const convertedRest = comparisonConvertor ? comparisonConvertor(rest) : rest;
      const convertedOther = comparisonConvertor ? comparisonConvertor(other) : other;
      return JSON.stringify(convertedRest) !== JSON.stringify(convertedOther);
    }
    const { viewData, ...savedFields } = activeView;
    const convertedRest = comparisonConvertor ? comparisonConvertor(rest) : rest;
    const convertedFields = comparisonConvertor ? comparisonConvertor(savedFields) : savedFields;
    return JSON.stringify(convertedRest) !== JSON.stringify(convertedFields);
  };

  return (
    <SavedViewsDisplay
      deleteName={handleDeleteName}
      diverged={hasDiverged()}
      errors={currentErrors}
      onDefaultChange={handleDefaultChange}
      PopoverProps={PopoverProps}
      rename={handleRename}
      save={handleSaveView}
      select={handleSelectChange}
      selected={active}
      update={handleUpdate}
      views={views}
      suggestedName={suggestedName}
      headers={headers}
      shouldAllowCreation={shouldAllowCreation}
    />
  );
}

SavedViewsComponent.propTypes = {

  /** Options for @popperjs/core see docs for details */
  PopoverProps: PropTypes.shape({}),

  /**
   * Function to convert data object before comparison
   * Can be used to whitelist or blacklist fields.
   */
  comparisonConvertor: PropTypes.func,

  /** Everything that is not under savedViews will be saved. */
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    savedViews: PropTypes.shape({
      views: PropTypes.arrayOf(PropTypes.shape({
        viewData: PropTypes.shape({
          headerId: PropTypes.string,
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          isDefault: PropTypes.bool,
          name: PropTypes.string
        })
      }))
    })
  }),

  errors: PropTypes.objectOf(PropTypes.string),

  /** Fetch function must return a promise */
  fetchSavedViews: PropTypes.func,

  /** Id for saving and fetching data */
  id: PropTypes.string.isRequired,

  /** Initial data. Used to detect changes before a saved view is loaded */
  initialData: PropTypes.shape({
    savedViews: PropTypes.shape({
      views: PropTypes.arrayOf(PropTypes.shape({
        viewData: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          isDefault: PropTypes.bool,
          name: PropTypes.string
        })
      }))
    })
  }),

  /** Maximum length of views can be saved */
  maxViewsCount: PropTypes.number,

  /** Custom name. Defaults to savedViews. Must match data object */
  name: PropTypes.string,

  /**
    * Called when a view is saved.
    *  Called with an object of
    * {
    *    name: 'Saved Views',
    *    value: updated data object,
    *    errors
    *  }
    */
  onChange: PropTypes.func,

  /** if onChange returns a promise or is asynchronous itself pass */
  onChangeAsync: PropTypes.bool,

  /** Called after fetchSavedViews returns */
  onLoad: PropTypes.func,

  /** Called if fetchSavedViews errors */
  onLoadError: PropTypes.func,

  /** Called after saveView returns */
  onSave: PropTypes.func,

  /** Called if saveView errors */
  onSaveError: PropTypes.func,

  /** Function for saving. Must return a promise */
  saveView: PropTypes.func,

  /** Suggested name - technical field name in data, using for save as name */
  suggestedName: PropTypes.string,

  /** Validation function for naming a saved view */
  validate: PropTypes.func
};

const fetchLocalSavedViews = (lsSavedViews) => Promise.resolve()
  .then(() => localStorage.getItem(lsSavedViews))
  .then(JSON.parse)
  .then((data) => data || []);

const saveToLocalStorage = ({ id, newViews }) => Promise.resolve()
  .then(
    () => localStorage
      .setItem(id, JSON.stringify(newViews))
  );

SavedViewsComponent.defaultProps = {
  PopoverProps: undefined,
  comparisonConvertor: null,
  data: {},
  errors: {},
  fetchSavedViews: fetchLocalSavedViews,
  initialData: null,
  maxViewsCount: undefined,
  name: 'savedViews',

  onChange() { },

  onChangeAsync: false,

  onLoad() { },

  /* istanbul ignore next */
  onLoadError() { },

  onSave() { },
  /* istanbul ignore next */
  onSaveError() { },
  saveView: saveToLocalStorage,
  suggestedName: '',
  validate: null
};

export default SavedViewsComponent;
