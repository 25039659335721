var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"radio\">\n        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.Restriction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":45}}}))
    + "</span>\n        <div class=\"radio-inline\">\n            <input type=\"radio\"\n                   name=\"ALLOWFXUSDUSER\"\n                   value=\"0\"\n                   data-bind=\"infoModel\"\n                   id=\"allowFXUSDUser0\"\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notAllowFXUSERUser") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":56}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":47}}})) != null ? stack1 : "")
    + "/>\n            <label for=\"allowFXUSDUser0\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.USD.Only",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":41}}}))
    + "\n            </label>\n        </div>\n        <div class=\"radio-inline\">\n            <input type=\"radio\"\n                   name=\"ALLOWFXUSDUSER\"\n                   value=\"1\"\n                   data-bind=\"infoModel\"\n                   id=\"allowFXUSDUser1\"\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowFXUSERUser") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":36,"column":53}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":37,"column":47}}})) != null ? stack1 : "")
    + "/>\n            <label for=\"allowFXUSDUser1\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.Allow.FX",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":41}}}))
    + "\n            </label>\n        </div>\n        <a data-toggle=\"popover\"\n           data-trigger=\"hover focus\"\n           role=\"button\"\n           data-placement=\"auto\"\n           data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.user.tooltip",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":25},"end":{"line":46,"column":57}}}))
    + "\">\n            <span class=\"icon-info\"></span>\n        </a>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "checked";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assignPermissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":58,"column":46}}}))
    + "\n            <div class=\"btn-group\" role=\"group\" aria-label=\"Set of buttons\">\n                <button class=\"btn btn-secondary\" data-hook=\"byGroupBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.byGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":60,"column":73},"end":{"line":60,"column":97}}}))
    + "</button>\n                <button class=\"btn btn-secondary\" data-hook=\"byTypeBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.byType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":72},"end":{"line":61,"column":95}}}))
    + "</button>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"global-select-all-perms\">\n        <label class=\"checkbox-inline\" for=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":69,"column":65},"end":{"line":69,"column":72}}}) : helper)))
    + "\">\n            <input type=\"checkbox\" id=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":70,"column":60},"end":{"line":70,"column":67}}}) : helper)))
    + "\" data-hook=\"globalSelectAllPerm\">\n            "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.selectall",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":71,"column":12},"end":{"line":71,"column":41}}}))
    + "\n        </label>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.paymentSettings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":36}}}))
    + "</h2>\n<div class=\"checkbox\">\n\n    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.selectBene",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":31}}}))
    + "\n\n    <div class=\"checkbox-inline\">\n        <input id=\"paymentsChk\" type=\"checkbox\" data-bind=\"model\" data-hook=\"paymentsChk\" name=\"globalSettings.BENEPAY.value\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":126},"end":{"line":7,"column":157}}})) != null ? stack1 : "")
    + ">\n        <label for=\"paymentsChk\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":69}}}))
    + "</label>\n    </div>\n    <div class=\"checkbox-inline\">\n        <input id=\"templatesChk\" type=\"checkbox\" data-bind=\"model\" data-hook=\"templatesChk\" name=\"globalSettings.BENETEMP.value\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":129},"end":{"line":11,"column":160}}})) != null ? stack1 : "")
    + ">\n        <label for=\"templatesChk\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.templates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":34},"end":{"line":12,"column":71}}}))
    + "</label>\n    </div>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAllowFXSelection") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":50,"column":7}}})) != null ? stack1 : "")
    + "<div data-region=\"approverTypeRegion\"></div>\n<div class=\"row\">\n    <div class=\"col-4\">\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assignedPermissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":54,"column":48}}}))
    + "</h4>\n    </div>\n    <div class=\"col-8 text-right\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":0},"end":{"line":74,"column":11}}})) != null ? stack1 : "")
    + "\n<!-- Swap out content below based on dropdown select-->\n<div data-region=\"permissionsRegion\"></div>\n";
},"useData":true});