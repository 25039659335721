var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"accountsGridWrapper\">\n    <div>\n        <div class=\"amount-wrapper\">\n            <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.cash.on.hand",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":41},"end":{"line":4,"column":78}}}))
    + "</p>\n            <span class=\"amount-value-text total-payments\"></span>\n        </div>\n\n        <div class=\"viewControls widget-info-links\">\n            \n            <button type=\"button\" class=\"btn btn-tertiary refresh-balances-button refresh\" data-hook=\"refresh-balances\">\n                <span class=\"icon-refresh\" sr-only=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.accountSummary.refreshBalances",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":99}}}))
    + "\"></span>\n                <span class=\"refresh-time\" data-hook=\"refresh-time\"></span>\n            </button>\n\n            <div class=\"btn-group view-switcher\">\n                <br>\n                <button type=\"button\" class=\"btn active btn-tile-view\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.toggle.tile",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":78},"end":{"line":17,"column":111}}}))
    + "\" aria-pressed=\"true\">\n                    <span class=\"icon-grid\"></span>\n                </button>\n                <button type=\"button\" class=\"btn btn-list-view\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"accounts.toggle.list",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":71},"end":{"line":20,"column":104}}}))
    + "\" aria-pressed=\"false\">\n                    <span class=\"icon-list\"></span>\n                </button>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    \n    <div class=\"balances-list-view hidden view-wrapper\">\n        <div class=\"list-view-accounts\" data-region=\"listViewAccounts\">\n\n        </div>\n    </div>\n    <div class=\"balances-tile-view view-wrapper\">\n        <p class=\"interaction-directions\"><span class=\"icon-documents\"></span> "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"dragAndDropMsg") || (depth0 != null ? lookupProperty(depth0,"dragAndDropMsg") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"dragAndDropMsg","hash":{},"data":data,"loc":{"start":{"line":35,"column":79},"end":{"line":35,"column":99}}}) : helper))) != null ? stack1 : "")
    + "</p><br/><br/>\n        <div class=\"account-list\">\n            <div class=\"account-container checking-container\">\n                <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.checking.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":38,"column":62}}}))
    + "</h4>\n                <div class=\"row\">\n                    <div class=\"checking-accounts-grid clearfix\" data-region=\"checkingAccountsGrid\"></div>\n                </div>\n            </div>\n            \n            <div class=\"account-container savings-container\">\n                <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.savings.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":45,"column":61}}}))
    + "</h4>\n                <div class=\"row\">\n                    <div class=\"saving-accounts-grid clearfix\" data-region=\"savingAccountGrid\"></div>\n                </div>\n            </div>\n\n            <div class=\"account-container loans-container\">\n                <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.loans",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":20},"end":{"line":52,"column":50}}}))
    + "</h4>\n                <div class=\"row\">\n                    <div class=\"loans-grid clearfix\" data-region=\"loansGrid\"></div>\n                </div>     \n            </div>\n            <div class=\"account-container\" data-hook=\"getCreditCardContainer\">\n                <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.creditCards",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":20},"end":{"line":58,"column":56}}}))
    + "</h4>\n                <div class=\"row\">\n                    <div class=\"clearfix\" data-region=\"creditCardsRegion\"></div>\n                </div>     \n            </div>\n            <div class=\"clearfix\"></div>\n        </div>\n        \n    </div>\n\n</div>\n";
},"useData":true});