import TopView from '@glu/scheduler/src/recurrence/monthly/top';
import template from '@glu/scheduler/src/recurrence/monthly/top.hbs';

export default TopView.extend({
  template,

  ui: {
    $recurrenceSwitch: '.js-type-change'
  },

  regions: {
    content: '.js-region-monthly-recurrence'
  },

  events: {
    'click @ui.$recurrenceSwitch': 'changeRecurrenceView'
  },

  initialize() {
    if (!this.model.has('nMonths')) {
      this.model.set('nMonths', '1');
    }

    if (!this.model.has('intervalType')) {
      this.model.set('intervalType', 'DAY');
    }

    if (this.model.isNew()) {
      this.isAdvanced = false;
      this.model.set({
        'recurrence.onN': '1', // GLU-1185
        'recurrence.intervalType': 'DAY',
        'recurrence.nMonths': '1'
      });
      return;
  }

    const monthDays = this.model.get('recurrence.onN');

    this.isAdvanced = Array.isArray(monthDays) && monthDays.length > 1;

    return this.isAdvanced;
  },


});
