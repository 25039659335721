import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { AccessibilityText } from '@glu/utilities-react';
import { withInputAPI } from '@glu/validation-react';
import './themeDefaults';
import useStyles from './Switch.styles';

let uniqueId = 0;

const Switch = ({
  value, name, className, positiveClass, negativeClass, theme, labelText,
  negativeLabel = locale.get('no'), positiveLabel = locale.get('yes'),
  onChange: onChangeProp, htmlId, registerValidators, unregisterValidators, dataQa,
  screenReaderLabel, inline, ...rest
}) => {
  // eslint-disable-next-line no-plusplus
  const id = useMemo(() => htmlId || `switch-${uniqueId++}`, [htmlId]);
  const onChange = useCallback(
    event => {
      onChangeProp(name, event.target.checked);
    },
    [onChangeProp, name]
  );
  const classes = useStyles({
    positive: !!value, inline, ...rest
  });

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`${className} ${value ? positiveClass : negativeClass}`}>
      <label htmlFor={id}>
        {screenReaderLabel ? (
          <AccessibilityText>{labelText}</AccessibilityText>
        ) : (
          <span className={classes.switchLabel}>{labelText}</span>
        )}
        <span data-qa={`${dataQa}-wrap`} className={`${classes.switch} ${isFocused ? classes.focus : ''}`}>
          {/* Toggling visibility: hidden on the label spans so that the width of
            * the switch remains the same, in both states, based on the widest content
            */}
          <span className={`${classes.switchState} ${classes.switchStatePositive} positivePosition`}>{positiveLabel}</span>
          <span className={`${classes.switchState} ${classes.switchStateNegative} negativePosition`}>{negativeLabel}</span>
          <input
            {...rest}
            id={id}
            name={name}
            checked={value}
            type="checkbox"
            className={classes.inputCheckbox}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </span>
      </label>
    </div>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  registerValidators: PropTypes.func,
  unregisterValidators: PropTypes.func,
  inline: PropTypes.bool,

  /** Text to display for truthy value */
  positiveLabel: PropTypes.string,

  /** Text to display for falsy value */
  negativeLabel: PropTypes.string,

  /** className when set to 'Yes' */
  positiveClass: PropTypes.string,

  /** className when set to 'No' */
  negativeClass: PropTypes.string,

  /** Text to display for label */
  labelText: PropTypes.string.isRequired,

  /** Boolean controlling whether the labelText should only be shown to screen readers */
  screenReaderLabel: PropTypes.bool,

  htmlId: PropTypes.string,

  theme: PropTypes.shape({}),

  /** data-qa attribute */
  dataQa: PropTypes.string
};

Switch.defaultProps = {
  className: '',
  value: false,
  positiveClass: '',
  negativeClass: '',
  positiveLabel: undefined,
  negativeLabel: undefined,
  htmlId: undefined,
  inline: false,
  screenReaderLabel: false,
  registerValidators: /* istanbul ignore next */ () => {},
  unregisterValidators: /* istanbul ignore next */ () => {},
  theme: undefined,
  dataQa: 'switch'
};

export const BaseSwitch = Switch;
export const ValidatedSwitch = withInputAPI(Switch);
ValidatedSwitch.displayName = 'Switch';
export default ValidatedSwitch;
