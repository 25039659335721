import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import intermediaryBankFormTmpl from './intermediaryBankForm.hbs';
import InquiryService from '../service/inquiry';
import AccountLookup from '../service/bankCodeLookup';
import AccountPaymentDetailAPI from '../service/accountPaymentDetail';
import LookupInquiryService from '../service/bankCodeTypeLookup';

const ACCOUNT_NUMBER_MAXLEN = 35;

const IntermediaryBankForm = Layout.extend({
    template: intermediaryBankFormTmpl,
    className: 'intermediary-bank-form row',

    ui() {
        return {
            $bankCodeId: `input[name="INTERMEDIARY${this.suffix}_ID_SORTCODETYPE"]`,
            $bankCodeIdType: `select[name="INTERMEDIARY${this.suffix}_IDTYPE"]`,
            $bankEntryMethod: 'input[name="INTERBENEBANKIDENTRYMETHOD"]',
            $intermediaryAccountNumber: 'input[name="INTER_BANK_ACCOUNT_NUMBER"]',
        };
    },

    events() {
        return {
            'change @ui.$bankCodeIdType': 'handleBankCodeTypeChange',
            'change @ui.$bankCodeId': 'handleBankCodeChange',
            'focusout @ui.$bankCodeId': 'handleBankCodeChange',
            'change @ui.$bankEntryMethod': 'changeInputModeHandler',
        };
    },

    regions: {
        intermediaryBankForm: '.intermediary-bank-form',
    },

    constructor(options) {
        this.suffix = options.suffix || '';
        Layout.prototype.constructor.call(this, options);
    },

    initialize(options) {
        this.options = options;
        this.model = options.model;

        this.INTERMEDIARY_IDTYPE = `INTERMEDIARY${this.suffix}_IDTYPE`;
        this.INTERMEDIARY_ID = `INTERMEDIARY${this.suffix}_ID`;
        this.INTERMEDIARY_NAME = `INTERMEDIARY${this.suffix}_NAME`;
        this.INTERMEDIARY_COUNTRY = `INTERMEDIARY${this.suffix}_COUNTRY`;
        this.INTERMEDIARY_ADDRESS_1 = `INTERMEDIARY${this.suffix}_ADDRESS_1`;
        this.INTERMEDIARY_ADDRESS_2 = `INTERMEDIARY${this.suffix}_ADDRESS_2`;
        this.INTERMEDIARY_ADDRESS_3 = `INTERMEDIARY${this.suffix}_ADDRESS_3`;
        this.INTERMEDIARY_STATE = `INTERMEDIARY${this.suffix}_STATE`;
        this.INTERMEDIARY_ID_SORTCODETYPE = `INTERMEDIARY${this.suffix}_ID_SORTCODETYPE`;

        // Set default entry method values if none exist
        if (!this.model.get('INTERBENEBANKIDENTRYMETHOD') && !this.model.get('INTERBANKIDENTRYMETHOD')) {
            this.model.set('INTERBENEBANKIDENTRYMETHOD', 'LOOKUP');
            this.model.set('INTERBANKIDENTRYMETHOD', 'LOOKUP');
        }

        // Set the radio button correctly if we recieved a value from the server
        if (this.model.get('INTERBANKIDENTRYMETHOD')) {
            this.model.set('INTERBENEBANKIDENTRYMETHOD', this.model.get('INTERBANKIDENTRYMETHOD'));
        }

        this.bankIdCollection = new Collection();
        this.bankIdTypeCollection = new Collection();

        this.codeTypeService = new LookupInquiryService();
        this.bankIdService = new InquiryService();
        this.accountCodeLookup = new AccountLookup();
        this.paymentDetailsService = new AccountPaymentDetailAPI();

        this.codeTypeService.setActionMode('SELECT');
        this.codeTypeService.setFunctionCode('MAINT');
        this.codeTypeService.setProductCode('_ADMIN');
        this.codeTypeService.setInquiryId(17088);
        this.codeTypeService.setTypeCode('*');
        this.codeTypeService.send();

        this.bankIdService.setActionMode('SELECT');
        this.bankIdService.setFunctionCode('MAINT');
        this.bankIdService.setProductCode('_ADMIN');
        this.bankIdService.setInquiryId(17091);
        this.bankIdService.setTypeCode('*');

        if (this.model.has(this.INTERMEDIARY_ID)) {
            this.fetchBankId(
                this.model.get(this.INTERMEDIARY_ID),
                this.model.get(this.INTERMEDIARY_IDTYPE),
            );
        }

        this.fieldTypeData = options.fieldTypeData;
    },

    delegateEvents() {
        Layout.prototype.delegateEvents.call(this);
        this.listenTo(this.bankIdService, 'success', this.handleBankIdServiceSuccess);
        this.listenTo(this.accountCodeLookup, 'success', this.handleBankIdServiceSuccess);
        this.listenTo(this.codeTypeService, 'success', this.handleBankIdTypeServiceSuccess);
        this.listenTo(this.paymentDetailsService, 'success', this.handleBankDetailsSuccess);
    },

    parseIdTypeResponse(response) {
        return util.collect(response, (obj) => {
            const tmp = transform.pairsToHash(obj.mapDataList, 'toField', 'value');
            tmp.text = tmp.Intermediary_IdType;
            tmp.id = tmp.Intermediary_IdType;
            return tmp;
        });
    },

    handleBankIdTypeServiceSuccess(response) {
        this.bankIdTypeResponse = response;
        let bankIdCodes = this.parseIdTypeResponse(this.bankIdTypeResponse);
        bankIdCodes = util.map(bankIdCodes, obj => ({
            name: obj.id,
            label: obj.text,
        }));

        this.bankIdTypeCollection.reset(bankIdCodes);
        this.render();
    },

    parseResponse(response) {
        return util.collect(response, (obj) => {
            const tmp = transform.pairsToHash(obj.mapDataList, 'toField', 'value');
            tmp.text = `${tmp.Intermediary_Id} - ${tmp.Intermediary_Name}`;
            tmp.id = tmp.Intermediary_Id;
            return tmp;
        });
    },

    handleBankIdServiceSuccess(response) {
        this.bankIdResponseSuccess = response;
        let bankCodes = this.parseResponse(this.bankIdResponseSuccess);
        bankCodes = util.map(bankCodes, obj => ({
            name: obj.id,
            label: obj.text,
        }));

        this.bankIdCollection.reset(bankCodes);

        this.render();
    },

    /**
     * @name showMaskedIntermediaryAccount
     * @description display the maskedInput component for the intermediary account number
     */
    showMaskedIntermediaryAccount() {
        /**
         * FIXME
         * this.fieldTypeData is not defined in modify mode (even in insert when modifying
         * an newly inserted account).
         * The scope of the change for NH-144687, does not allow for reworking the contact
         * center so for now I am hard-coding the maxlength to 35, which is what is specified
         * in the database
         */
        const maxLength = this.fieldTypeData
            ? this.fieldTypeData.INTER_BANK_ACCOUNT_NUMBER.maxLen
            : ACCOUNT_NUMBER_MAXLEN;
        const fieldLabel = locale.get('RTGS.INTER_BANK_ACCOUNT_NUMBER');
        const MaskedInputView = createMaskedInputView({
            initialValue: this.model.get('INTER_BANK_ACCOUNT_NUMBER') || '',
            name: 'INTER_BANK_ACCOUNT_NUMBER',
            maxLength,
            fieldLabel,
            inputClassList: 'form-control',
            dataBind: true,
        });
        if (this.interBankAccountRegion) {
            this.interBankAccountRegion.show(new MaskedInputView());
        }
    },

    onRender() {
        const self = this;

        if (this.model.get('INTERMEDIARY_FREE_FORM_ACCOUNT_REQUIRED') === 'true') {
            this.ui.$intermediaryAccountNumber.parent().addClass('required');
        }

        // if id is mandatory and bene bank id entry method is NOT lookup
        if ((this.model.get('CORRESPONDENT_ID_MANDATORY') === true) && !(this.model.get('BENEBANKIDENTRYMETHOD') === 'LOOKUP')) {
            this.ui.$bankCodeIdType.parent().addClass('required');
            this.ui.$bankCodeId.parent().addClass('required');
        } else {
            this.ui.$bankCodeId.parent().removeClass('required');
            this.ui.$bankCodeIdType.parent().removeClass('required');
        }
        this.showMaskedIntermediaryAccount();

        const lookup = this.accountCodeLookup;
        lookup.setFieldName('interboth');
        let rowsPerPage = 1;
        this.ui.bankCodeIdCombo = this.ui.$bankCodeId.comboBox({
            dropdownAutoWidth: true,
            triggerChange: true,

            query: util.debounce((query) => {
                lookup.setStartRow((((query.page - 1) * rowsPerPage) + 1));
                rowsPerPage = (query.term.length < constants.COMBO_MIN_CHARS)
                    ? constants.COMBO_MIN_SIZE : constants.MAX_SERVER_ROWSPERPAGE;
                lookup.setPageSize(rowsPerPage);
                lookup.setSearch(query.term);
                lookup.clearDepends();
                lookup.setDepends({
                    name: 'SORTCODETYPE',
                    value: self.model.get(self.INTERMEDIARY_IDTYPE),
                });
                lookup.send().then((result) => {
                    query.callback({
                        results: result.data,
                        more: ((query.page * rowsPerPage) < result.totalRows),
                    });
                });
            }, constants.COMBO_DEBOUNCE),

            initSelection(element, cb) {
                const val = element.val();
                const text = `${self.model.get(self.INTERMEDIARY_ID)}-${self.model.get(self.INTERMEDIARY_NAME)}`;
                if (text !== 'undefined-undefined' && text !== 'null-null' && text !== '-') {
                    cb({
                        id: val,
                        text,
                    });
                } else {
                    cb();
                }
            },
        });
    },

    applyFieldsFromMapDataList(response) {
        const self = this;
        self.model.set(this.INTERMEDIARY_NAME, response.bankName);
        self.model.set(this.INTERMEDIARY_ADDRESS_1, response.bankAddress1);
        self.model.set(this.INTERMEDIARY_ADDRESS_2, response.bankAddress2);
        self.model.set(this.INTERMEDIARY_ADDRESS_3, response.bankCity);
        self.model.set(this.INTERMEDIARY_COUNTRY, response.beneCountryCode);
        self.model.set(this.INTERMEDIARY_STATE, response.bankState);
        self.model.set(this.INTERMEDIARY_ID_SORTCODETYPE, `${response.bankSortCode} - ${response.sortCodeType}`);
        this.render();
    },

    handleBankCodeTypeChange() {
        const bankCodeType = this.model.get(this.INTERMEDIARY_IDTYPE);
        if (bankCodeType) {
            this.ui.bankCodeIdCombo.select2('data', null);
            this.model.set(this.INTERMEDIARY_ID, '');
            this.model.set(this.INTERMEDIARY_NAME, '');
            this.model.set(this.INTERMEDIARY_ADDRESS_1, '');
            this.model.set(this.INTERMEDIARY_ADDRESS_2, '');
            this.model.set(this.INTERMEDIARY_ADDRESS_3, '');
            this.model.set(this.INTERMEDIARY_COUNTRY, '');
            this.model.set(this.INTERMEDIARY_STATE, '');
            this.render();
        }
    },

    handleBankDetailsSuccess(response) {
        if (response) {
            this.applyFieldsFromMapDataList(response);
        }
    },

    handleBankCodeChange(e) {
        const bankCodesortcodetype = e.target.value;
        const bankCode = bankCodesortcodetype.substring(0, bankCodesortcodetype.indexOf(' - '));
        const sortCodeType = bankCodesortcodetype.substring(bankCodesortcodetype.indexOf(' - ') + 4);
        if (bankCode) {
            this.model.set(this.INTERMEDIARY_ID, bankCode);
            this.model.set(this.INTERMEDIARY_IDTYPE, sortCodeType);
            this.paymentDetailsService.setIntermediaryId(bankCode);
            this.paymentDetailsService.setIntermediaryIdType(sortCodeType);
            this.paymentDetailsService.getBankDetail();
        }
    },

    fetchBankId(bankCode, bankCodeType) {
        if (bankCode) {
            this.paymentDetailsService.setIntermediaryId(bankCode);
            this.paymentDetailsService.setIntermediaryIdType(bankCodeType);
            this.paymentDetailsService.getBankDetail();
        }
    },

    changeInputModeHandler(e) {
        const selectedVal = e.target.value;

        // Reset the values on input method toggle
        this.model.set({
            INTERMEDIARY2_NAME: '',
            INTERMEDIARY2_ADDRESS_1: '',
            INTERMEDIARY2_ADDRESS_2: '',
            INTERMEDIARY2_ADDRESS_3: '',
            INTERMEDIARY2_COUNTRY: '',
            INTERMEDIARY2_STATE: '',
            INTER_BANK_ACCOUNT_NUMBER: '',
            INTERMEDIARY2_IDTYPE: '',
            INTERMEDIARY2_ID: '',
        }, {
            silent: true,
        });

        /*
         * TODO: We are setting the value of the radio button into another value.
         * // This is not ideal, it was done because the radio button value was not
         * // available in the model when needed. Need to spend time investigating why.
         */
        // Set the selected input method to be used by the template
        this.model.set('INTERBENEBANKIDENTRYMETHOD', selectedVal);
        this.model.set('INTERBANKIDENTRYMETHOD', selectedVal);
        this.render();
    },

    clearIntermediary() {
        this.handleBankCodeTypeChange();
        this.ui.$bankCodeIdType.val('');

        this.model.set(this.INTERMEDIARY_IDTYPE, '');
    },

    templateHelpers() {
        return {
            bankIdTypes: this.bankIdTypeCollection ? this.bankIdTypeCollection.toJSON() : {},
            suffix: this.suffix,
            isIntermediaryFreeFormEntitled: this.model.get('INTERMEDIARY_FREE_FORM_ENTITLED') === 'true',
            isIntermediaryFreeForm: this.model.get('INTERBANKIDENTRYMETHOD') === 'FREEFORM',
            is2ndIntermediary: this.suffix === '2',

            isSecond() {
                return this.suffix === '2';
            },

            getIntermediaryName() {
                return this[`INTERMEDIARY${this.suffix}_NAME`];
            },

            hasAddress() {
                return this[`INTERMEDIARY${this.suffix}_ADDRESS_1`] || this[`INTERMEDIARY${this.suffix}_STATE`]
                        || this[`INTERMEDIARY${this.suffix}_COUNTRY`] || this[`INTERMEDIARY${this.suffix}_ADDRESS_3`];
            },

            getAddress1() {
                return this[`INTERMEDIARY${this.suffix}_ADDRESS_1`];
            },

            getAddress2() {
                return this[`INTERMEDIARY${this.suffix}_ADDRESS_2`];
            },

            getAddress3() {
                return this[`INTERMEDIARY${this.suffix}_ADDRESS_3`];
            },

            getState() {
                return this[`INTERMEDIARY${this.suffix}_STATE`];
            },

            getCountry() {
                return this[`INTERMEDIARY${this.suffix}_COUNTRY`];
            },

            cid: this.cid,
            modelCid: this.model.cid,
            fieldTypeData: this.fieldTypeData,
            INTER_BANK_ACCOUNT_LENGTH: this.fieldTypeData
                ? this.fieldTypeData.INTER_BANK_ACCOUNT_NUMBER.maxLen : 35,
            INTER_NAME_LENGTH: this.fieldTypeData
                ? this.fieldTypeData.INTERMEDIARY2_NAME.maxLen : 35,
            INTER_ADDRESS_1_LENGTH: this.fieldTypeData
                ? this.fieldTypeData.INTERMEDIARY2_ADDRESS_1.maxLen : 35,
            INTER_ADDRESS_2_LENGTH: this.fieldTypeData
                ? this.fieldTypeData.INTERMEDIARY2_ADDRESS_2.maxLen : 35,
            INTER_ADDRESS_3_LENGTH: this.fieldTypeData
                ? this.fieldTypeData.INTERMEDIARY2_ADDRESS_3.maxLen : 35,
        };
    },
});

export default IntermediaryBankForm;
