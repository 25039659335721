import moment from 'moment';
import ItemView from '@glu/core/src/itemView';
import API from 'app/smb/api';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import errHandler from 'system/errHandler';
import paymentDateTmpl from './paymentDate.hbs';

export default ItemView.extend({
    className: 'payment-date-cell form-group has-validator',
    template: paymentDateTmpl,

    templateHelpers() {
        return {
            id: this.model.cid,
        };
    },

    events: {
        'change input': 'isValid',
        'keyup input': 'isValid',
    },

    onRender() {
        this.$('input').nhDatePicker({
            showCalendarIcon: true,
        });

        const datesPromise = API.dates.get({
            paymentType: 'INTL',
            subType: '',
        });

        datesPromise.then(util.bind(function (results) {
            if (this.isClosed) {
                return;
            }
            this.dates = results;
            this.updateDatePicker();
        }, this)).then(null, errHandler);

        this.$el.data('view', this);
    },

    updateDatePicker() {
        const format = 'YYYY-MM-DD';
        const todayMoment = moment(moment(new Date()).format(format), format);
        const earliestDayMoment = moment(this.dates.earliestDay, format);
        const daysBack = moment.duration(todayMoment.diff(earliestDayMoment)).asDays();
        this.$('input').data('daterangepicker').updateCalendars({
            blockedDates: this.dates.holidays,
            allowWeekends: this.dates.businessDays !== '0111110',
            daysBack,
            daysForward: this.dates.maxForwardDays,
        });
        this.$('input').data('daterangepicker').setStartDate(earliestDayMoment);
    },

    isValid() {
        const drp = this.$('input').data('daterangepicker');
        const calendarDate = drp.startDate.format(userInfo.getDateFormat());
        const inputDate = this.$('input').val();
        let isValid = true;

        // datepicker couldn't parse the string into a date
        if (calendarDate !== inputDate) {
            isValid = false;
        }

        // the specific day falls on a blackout date
        if (drp.isInvalidDay(drp.startDate)) {
            isValid = false;
        }

        // test within min/max date range
        if (!(drp.startDate.isBetween(drp.minDate, drp.maxDate) || drp.startDate.isSame(drp.maxDate, 'day') || drp.startDate.isSame(drp.minDate, 'day'))) {
            isValid = false;
        }

        this.$el.toggleClass('has-error', !isValid);

        return isValid;
    },
});
