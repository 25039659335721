import Collection from '@glu/core/src/collection';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';

export default Collection.extend({
    url: services.generateUrl('/inquiry/getData'),

    initialize(options) {
        this.buildCustomFilters(options);
    },

    parse(response) {
        const self = this;
        const columns = [];

        util.each(response.inquiryResponse.rowHeader, (rowHeader) => {
            if (rowHeader.fieldName !== 'CHECKBOX') {
                columns.push({
                    field: rowHeader.fieldName,
                    label: rowHeader.displayName,
                    displayOrder: rowHeader.displayOrder,
                    condition: rowHeader.displayOrder >= 0 && rowHeader.hidden !== true,
                });
            }
        });

        this.columns = util.sortBy(columns, column => column.displayOrder, this);

        return response.inquiryResponse.rows.map((row) => {
            const data = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');

            // override only for estatement for part 3
            const isEstatement = self.configuredDataType === 'ESTATEMENTID';
            let keyValues;
            if (isEstatement) {
                data.idBankCode = data[self.keyCol];
                keyValues = [data.EDESCRIPTION, data.ACCOUNTNUM, data.BANKCODE];
                data.ESTATACCTLabel = keyValues.join('-');
            }

            data.id = data[self.keyCol];

            return data;
        });
    },

    sync(method, collection, options) {
        if (method !== 'read') {
            throw new Error(`This collection only knows how to read, but it was asked to ${method}.`);
        }
        return http.post(util.result(collection, 'url'), util.result(this, 'requestBody')).then(options.success, options.error);
    },

    buildCustomFilters(options) {
        this.customFilters = [];

        if (options && options.userGroup) {
            this.customFilters.push({
                filterName: 'Depends',
                filterParam: ['UserGroup', options.userGroup],
            });
        }
    },

    // Override requestBody or the three properties below.
    requestBody() {
        return {
            requestHeader: util.result(this.requestHeader),
            inquiryRequest: {
                searchCriteria: {
                    action: util.result(this, 'action'),
                    customFilters: this.customFilters,
                    inquiryId: util.result(this, 'inquiryId'),
                },
            },
        };
    },

    requestHeader: {},
    action: {},
    inquiryId: 0,

});
