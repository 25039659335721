import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import locale from '@glu/locale';
import http from '@glu/core/src/http';

const getFieldValue = function (dataList, fieldKey) {
    return util.findWhere(
        dataList,
        {
            toField: fieldKey,
        },
    ).value;
};

// a map of types.description to the SMB equivalent
const smbTypeDescriptionKeys = {
    'RTGS.type.fedwire': 'SMBPAY.ExpFedWire',
    'RTGS.type.intl': 'SMBPAY.ExpIntlWire',
    'ACH.corp_ven_pay': 'SMBPAY.StdPaymentsACH',
    'ACH.con_pay': 'SMBPAY.StdPaymentsACH',
    'ACH.payroll': 'SMBPAY.StdPaymentsACH',
    'ACH.corp_col': 'SMBPAY.StdCollectionsACH',
    'ACH.con_col': 'SMBPAY.StdCollectionsACH',
};

export default Collection.extend({
    initialize(models, options) {
        if (options.isCollection) {
            this.inquiryId = '25557';
        } else if (options.fromRTPWorkspace) {
            this.inquiryId = '25558';
        } else {
            this.inquiryId = '25556';
        }

        this.actionMode = 'SELECT';
    },
    parse(jsonData) {
        const beneficiaries = {};
        let dataList;
        let beneId;
        let beneficiary;
        let account;
        let key;
        let val;
        util.each(jsonData, (rowItem) => {
            dataList = rowItem.mapDataList;
            beneId = getFieldValue(dataList, 'BENEBOOK_ID');
            if (!beneficiaries[beneId]) {
                beneficiaries[beneId] = {
                    accountList: new Collection(),
                };
            }
            beneficiary = beneficiaries[beneId];
            account = {};
            util.each(dataList, (dataItem) => {
                key = dataItem.toField.toUpperCase();
                val = dataItem.value;

                if (key === 'BENE_NAME') {
                    beneficiary.BENE_NAME = util.unescape(val);
                }

                if (key === 'TYPE_DESCRIPTION') {
                    account[key] = locale.get(smbTypeDescriptionKeys[val] || val);
                } else {
                    account[key] = val;
                }
            });

            beneficiary.accountList.add(account);
        });

        return util.values(beneficiaries);
    },

    sync(method, model, options) {
        const url = services.generateUrl('/inquiry/getQueryResults');
        const postData = {
            IncludeMapData: 1,

            queryCriteria: {
                queryField: 'KEY_FIELD',
                inquiryId: this.inquiryId,

                action: {
                    productCode: 'PAY',
                    functionCode: 'INST',
                    actionMode: this.actionMode,
                    typeCode: '*',
                    entryMethod: 0,
                },
            },

            requestHeader: {
                requestId: '1002',
            },
        };
        http.post(url, postData, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, (result) => {
            options.error(result);
        });
    },
});
