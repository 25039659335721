import $ from 'jquery';
import TileView from 'app/smbPayments/common/tile/tilePayments';
import nhUtil from 'system/utilities/accessors';
import { appBus } from '@glu/core';
import PayNow from 'app/smbPayments/views/payments/models/payNow';
import SmbPayNowDialogView from 'app/smbPayments/views/payments/payNow/payNowDialogLayout';
import 'jui/draggable';

export default TileView.extend({
    tagName: 'li',

    initialize(options) {
        // unapproved items are editable
        this.editable = true;
        // Only make the item draggable if the status is unscheduled
        this.initializeDraggableItem();
        this.options = options;
    },

    initializeDraggableItem() {
        const draggableStatus = nhUtil.getFieldValue(this.options.model, 'STATUS_DESCRIPTION');

        if (draggableStatus === 'Approved') {
            $(this.el).draggable({
                // clicking an icon won't initiate dragging
                cancel: 'a.ui-icon',

                // when not dropped, the item will revert back to its initial position
                revert: 'invalid',

                revertDuration: 200,

                /*
                 * containment: 'document',
                 * helper: 'clone',
                 */
                cursor: 'move',

                start(event, ui) {
                    ui.helper.addClass('tilt');
                },

                stop(event, ui) {
                    ui.helper.removeClass('tilt');
                },
            });
        }

        $(this.el).data('backbone-view', this);
    },

    /**
     * Handle pay now action.
     *
     * @param e
     */
    payNow(e) {
        e.preventDefault();

        const payNow = new PayNow({
            columns: this.model.get('columns'),
        });

        const smbPayNowDialogView = new SmbPayNowDialogView({
            model: payNow,
        });

        smbPayNowDialogView.render();
    },

    viewBill(e) {
        appBus.trigger('payments:draggableTileItem:viewBill', e);
    },

    onRender() {
        // We need the cid of the model to view the bill
        $(this.el).attr('id', this.options.model.cid);

        this.delegateEvents();
    },
});
