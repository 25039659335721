import CollectionView from '@glu/core/src/collectionView';
import ApproverSequence from './approverSequence';

export default CollectionView.extend({
    itemView: ApproverSequence,

    itemEvents: {
        'remove:sequence': 'removeModel',
    },

    itemViewOptions(model, index) {
        return {
            index,
            sequenceTypes: this.options.sequenceTypes,
            approverTypes: this.options.approverTypes,
        };
    },

    removeModel(eventName, view) {
        if (this.collection.length > 1) {
            this.collection.remove(view.model);
        }
    },
});
