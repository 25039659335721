import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import inquiryApi from 'common/inquiry/api';

export default Collection.extend({
    sync(method, model, options) {
        util.extend(
            options,
            {
                includeMapData: 1,
                inquiryId: '20593',
                typeCode: 'CMINQ',
                entryMethod: 0,
                actionMode: 'SELECT',
                productCode: 'CM',
                functionCode: 'INST',
            },
        );

        const data = inquiryApi.getQueryResults(options);

        http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const returnArray = [];
        util.each(response, (rowItem) => {
            const functionCodeData = rowItem.mapDataList.find(item => item.toField === 'FUNCTIONCODE')?.value;
            const entitleTypeCodeData = rowItem.mapDataList.find(item => item.toField === 'ENTITLETYPECODE')?.value;
            const filterId = rowItem.mapDataList.find(item => item.toField === 'FILTERID')?.value;
            const viewId = rowItem.mapDataList.find(item => item.toField === 'VIEWID')?.value;
            const obj = {
                id: rowItem.name,
                label: rowItem.label,
                filterId,
                functionCodeData,
                entitleTypeCodeData,
                viewId,
            };
            returnArray.push(obj);
        });
        return util.sortBy(returnArray, 'label');
    },
});
