import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import store from 'system/utilities/cache';
import moment from 'moment';
import scroll from 'common/util/scroll';
import AuditList from 'app/administration/views/audit/auditList';
import workspaceHelper from 'common/workspaces/api/helper';
import filterApi from 'common/dynamicPages/api/filters';
import userInfo from 'etc/userInfo';
import tmpl from './audit.hbs';

const Audit = Layout.extend({
    template: tmpl,

    initialize(options) {
        this.model = options.model || new Model();

        this.options = util.extend({}, {
            menuCategory: 'PAYMENTS',
            mode: 'insert',
            viewUrl: 'ADMINSTRATION/audit/viewAudit',
            returnRoute: 'ADMINSTRATION/audit',
            context: 'AUDIT_INFO',
            filterId: 9036,
            typeInfo: {
                productCode: '_ADMIN',
                functionCode: 'MAINT',
                typeCode: 'AUDIT',
            },
        }, options);

        this.filterId = options.filterId || 9036;

        this.datePickerOptions = {
            daysForward: 0,
            allowWeekends: true,
        };

        this.typeAheadFieldsOverride = [];
        this.typeAheadFieldsOverride.push({
            name: 'USERGROUP',
            overrideUrl: '/adminAudit/getCompanyLookupResults',
        });
        this.filterData = store.get('cm_inq_searchFields');
        store.unset('cm_inq_searchFields');
        this.setNotificationData({
            title: 'Audit',
        });
    },

    ui: {
        searchButton: '#checkSearch',
    },

    events: {
        'click @ui.searchButton': 'search',
    },

    regions: {
        auditList: '#auditList',
    },

    getCacheName() {
        const retStr = `model-${this.filterContextDef.serviceName}-${this.filterContextDef.productCode}-${this.filterContextDef.functionCode}`;
        return retStr;
    },

    onRender() {
        this.showFilters();
        this.notifyPageLoaded();
    },

    showFilters() {
        this.filterContextDef = util.extend({
            serviceName: '/advanceFilter',
        }, this.options.typeInfo);
        const requestData = {
            filterId: this.filterId,
            typeInfo: this.options.typeInfo,
        };
        store.unset(this.getCacheName());
        store.set(this.getCacheName(), this.model);
        if (typeof this.filterData !== 'undefined') {
            this.search();
        }
        const promiseMe = filterApi.getFilters(requestData, this);
        promiseMe.then(() => {
            this.listenToOnce(this.filterRegion.currentView, 'item:rendered', () => {
                this.$el.find('div[data-region="filterRegion"] fieldset.section.section-container').removeClass('section-container');
            });
        });
    },

    search() {
        if (!this.filterRegion.currentView) {
            return;
        }
        const filterModel = this.filterRegion.currentView.model;
        const dates = filterModel.get('LOGTIME').split(' - ');
        const startDate = moment(dates[0], userInfo.getDateFormat());
        const endDate = moment(dates[1], userInfo.getDateFormat());
        // eslint-disable-next-line
        filterModel._datePickerValues.LOGTIME = [startDate, endDate];
        const additionalSearchFields = filterModel
            ? filterApi.gatherFilterData(filterModel) : this.filterData;
        const options = {
            additionalSearchFields,
            filter: false,
            selector: 'none',
            serviceName: this.options.serviceName,
            context: this.options.context,
        };

        if (filterModel.isValid()) {
            this.alertRegion.close();
            this.auditListRegion.show(new AuditList(options));
        } else {
            scroll.scrollToFirstError();
        }
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'AUDIT_INFO',
    view: Audit,
    options: {},
});

export default Audit;
