import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(options) {
        this.isTransfer = options.isTransfer;
        this.queryOffset = options.queryOffSet || 0;
    },

    sync(method, model, options) {
        const data = {
            IncludeMapData: 1,

            queryCriteria: {
                inquiryId: '20036',
                queryType: 'PayTemplatesQuery',

                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    productCode: 'PAY',
                    actionMode: 'INSERT',
                    functionCode: 'INST',
                },
            },

            requestHeader: {
                queryPagesize: 0,
                queryOffset: this.queryOffset,
                requestId: 0,
            },

            formatterType: 'Query',
        };

        return http.post(services.inquiryQueryResults, data, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const returnArray = [];

        util.each(response, (rowItem) => {
            const contextObj = {};
            let typeCode = '';

            if (rowItem.mapDataList) {
                for (let i = 0; i < rowItem.mapDataList.length; i += 1) {
                    if (rowItem.mapDataList[i].toField.toUpperCase() === 'SERVICENAME') {
                        contextObj.serviceName = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'TEMPSERVICENAME') {
                        contextObj.templateServiceName = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'TNUM') {
                        contextObj.tnum = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'TYPE') {
                        contextObj.type = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'UPDATECOUNT') {
                        contextObj.updateCount = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'TEMPLATEENTRYMETHOD') {
                        contextObj.templateEntryMethod = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'TYPECODE') {
                        typeCode = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'FUNCTION') {
                        contextObj.functionCode = rowItem.mapDataList[i].value;
                    } else if (rowItem.mapDataList[i].toField.toUpperCase() === 'SUBTYPE') {
                        if (rowItem.mapDataList[i].value === null || rowItem.mapDataList[i].value === '') {
                            contextObj.subType = '*';
                        } else {
                            contextObj.subType = rowItem.mapDataList[i].value;
                        }
                    }
                }
            }

            const obj = {
                id: rowItem.name,
                name: rowItem.label,
                label: util.unescape(rowItem.label),
                typeCode,
                context: contextObj,
            };

            returnArray.push(obj);
        });

        return returnArray;
    },
});
