import ItemView from '@glu/core/src/itemView';
import services from 'services';
import template from './printContentView.hbs';

const PrintContentView = ItemView.extend({
    template,
    className: 'PrintContentView',

    initialize(options) {
        this.exportModel = options.exportModel;
        this.exportURL = options.exportURL;
        this.viewId = options.viewId;
        this.formMethod = options.formMethod || 'post';
    },

    onRender() {
        if (this.viewId === 'printBTRModal' || this.viewId === 'WIREINTLDISCLOSURE'
            || this.viewId === 'wireReport') {
            this.doExport(this.exportModel);
        }
    },

    onBeforeClose() {
        /*
         * HACK: NH-38817 There is an IE bug where removing an iframe
         * causes a whitescreen on the page. Removing src attribute
         * prior to removal fixes this issue, although it's not
         * entirely clear why this works.
         */
        this.$el.find('iframe')
            .attr('src', '')
            .remove();
    },

    doExport(exp) {
        const self = this;
        const defaultExportURL = `/export/listView?${+new Date()}`;
        const f = `<form id="listViewExportForm" action="${services.generateUrl(this.exportURL || defaultExportURL)}" method="${this.formMethod}" target="pdfContent">
        <input id="listViewRequest" type="hidden" name="listViewRequest"></form>`;
        self.$el.append(f);
        self.$('#listViewRequest').val(JSON.stringify(exp));
        self.$('iframe').one('load', () => {
            self.$('#listViewExportForm').submit();
        });
    },

    templateHelpers() {
        return {
            type: 'application/pdf',
            url: `${services.generateUrl(this.exportURL || '/export/listView')}?listViewRequest=${JSON.stringify(this.exportModel)}`,
            viewId: (this.viewId === 'printBTRModal' || this.viewId === 'WIREINTLDISCLOSURE'
                || this.viewId === 'wireReport'),
        };
    },
});
export default PrintContentView;
