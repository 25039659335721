import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(options) {
        this.serviceUrl = '/tableMaintenance/getQueryResults';
        if (options.transfer.isManyToOne()) {
            this.fieldName = 'DEBIT_ACCOUNT_NUMBER';
            this.subTypeCode = 'MANY2ONE';
            this.customFilters = [{
                filterName: 'Depends',
                filterParam: ['BENE_ACCOUNT', options.transfer.get('BENE_ACCOUNT')],
            }];
        } else {
            this.fieldName = 'BENE_ACCOUNT';
            this.customFilters = [{
                filterName: 'Depends',
                filterParam: ['DEBIT_ACCOUNT_NUMBER', options.transfer.get('DEBIT_ACCOUNT_NUMBER')],
            }];
            this.subTypeCode = 'ONE2MANY';
        }
    },

    parse(jsonData) {
        const returnArray = [];
        util.each(jsonData, (rowItem) => {
            const obj = {
                id: rowItem.name,
                name: rowItem.label,
                label: rowItem.label,
                mapDataList: rowItem.mapDataList,
            };
            returnArray.push(obj);
        });
        return util.sortBy(returnArray, 'label');
    },

    sync(method, model, options) {
        const url = services.generateUrl(this.serviceUrl);

        const postData = {
            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    productCode: 'RTGS',
                    functionCode: 'INST',
                    typeCode: 'TRANSFER',
                    actionMode: 'INSERT',
                },

                customFilters: this.customFilters,
                entryClass: '',
                fieldName: this.fieldName,
                subTypeCode: this.subTypeCode,
            },
        };
        http.post(url, postData, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, (result) => {
            options.error(result);
        });
    },
});
