/* eslint no-shadow:0, no-loop-func:0, strict:0 */
import util from '../util';
import http from '../http';

/**
 * Bottomline Business Service Layer Query API
 *
 * @class Query
 * @static
 */
const Query = {};
let cache = {};
const urls = {
  servicePath: ''
};

let httpApi = http;

/**
 * Utility function, that makes a simple http request, with no modifications to data.
 * @method simpleHttpRequest
 * @private
 * @param method {String} Must be 'get', 'post', 'put' or 'del'.
 * @param url {String} The URL to be requested.
 * @param data
 * @param callback {Function} Function to be invoked at end of HTTP request.
 */
function simpleHttpRequest(method, url, data, callback) {
  const success = function (result) {
    return callback(null, result);
  };

  const error = function (result) {
    let response;
    try {
      response = JSON.parse(result.responseText);
    } catch (e) {
      response = result.responseText;
    }
    return callback(response);
  };

  if (typeof data === 'function') {
    callback = data;
    data = null;
  }

  return method === 'get' ?
    httpApi[method](url, success, error) :
    httpApi[method](url, data, success, error);
}

/**
 * Override the http interface used by the API to make AJAX calls. Used for testing.
 * @method setHttpInterface
 * @param httpInterface
 */
Query.setHttpInterface = httpInterface => {
  httpApi = httpInterface;
};

/**
 * Provide an ability to extend the cache of loaded model definitions. This supports client-side
 * management of model definitions.
 *
 * @method setCache
 * @param data
 */
Query.setCache = data => {
  util.extend(cache, data);
};

/**
 * Provide an ability to clear cache of loaded model definitions. Sometimes there are cases when
 * you need to clear existing cache after the lapse of some period of time, some kind of cache
 * expiration.
 *
 * @method clearCache
 */
Query.clearCache = () => {
  cache = {};
};

/**
 * @method setServicePath
 * @param servicePath - custom path to project API root/services calls.
 */
Query.setServicePath = servicePath => {
  urls.servicePath = servicePath || '';
};

/**
 * @method getServicePath
 */
Query.getServicePath = () => urls.servicePath;

// TODO this whole file is written in a very old style and some things don't
// quickly convert to ES6 well.  Recommend a redo on entire file
const QueryPart = function() {}; // eslint-disable-line

util.extend(QueryPart.prototype, {
  getQueryPart(modelDefinition) {
    const self = this;
    const value = this.process(modelDefinition);

    return {
      getErrors() {
        return value.getErrors();
      },

      getJSON() {
        const result = {};
        const json = value.getJSON();

        if (!util.isEmpty(json)) {
          result[self.getQueryPartKey()] = json;
        }

        return result;
      }
    };
  }
});
const QueryParameter = function (fieldName, operator) {
  const qpType = 'QueryParameter';
  const qpFieldName = fieldName;
  const qpOperator = operator;
  let values = [].slice.apply(arguments).slice(2);

  if (util.isArray(values[0])) { // for in([]), notIn([]) operator
    [values] = values;
  }

  return {
    getFieldName() {
      return qpFieldName;
    },

    getType() {
      return qpType;
    },

    getOperator() {
      return qpOperator;
    },

    getValue() {
      return values;
    }
  };
};

const QueryRelationship = function (fieldName, operator) {
  const qrType = 'QueryRelationship';
  const qrFieldName = fieldName;
  const qrOperator = operator;

  return {

    getFieldName() {
      return qrFieldName;
    },

    getType() {
      return qrType;
    },

    getOperator() {
      return qrOperator;
    }
  };
};

const operators = {
  EQUALS: 'Equals',
  NOT_EQUALS: 'Not equals',
  GREATER_THAN: 'Greater Than',
  GREATER_THAN_OR_EQUALS: 'Greater Than or Equals',
  LESS_THAN: 'Less Than',
  LESS_THAN_OR_EQUALS: 'Less Than or Equals',
  AND: 'AND',
  OR: 'OR',
  CONTAINS: 'Contains',
  IN: 'Is in',
  NOT_IN: 'Is not in',
  STARTS_WITH: 'Starts with',
  ENDS_WITH: 'Ends with',
  BETWEEN: 'Between'
};

/**
 * Private class for building queries. Follows fluent API style so
 * that you can easily chain together multiple criteria. Instantiate by using {Query.filter}
 * @class Criteria
 * @private
 * @constructor
 */
const Criteria = function () {
  this.parameters = [];
};

util.extend(Criteria.prototype, {

  where(fieldName) {
    const self = this;

    return {

      /**
       * Creates a criterion using '==' operator
       *
       * @param value {Object}
       * @method is
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('level_of_difficulty').is('simple'); // level_of_difficulty == 'simple'
       */
      is(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.EQUALS, value));
        return self;
      },

      /**
       * Creates a criterion using '!=' operator
       *
       * @param value {Object}
       * @method isNot
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('color').isNot('brown'); // color != 'brown'
       */
      isNot(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.NOT_EQUALS, value));
        return self;
      },

      /**
       * Creates a criterion using '>' operator
       *
       * @param value {Object}
       * @method greaterThan
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('weight').greaterThan(100).and('unit').is('kilos'); // weight > 100 kilos
       */
      greaterThan(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.GREATER_THAN, value));
        return self;
      },

      /**
       * Creates a criterion using '>=' operator
       *
       * @param value {Object}
       * @method greaterThanOrEqual
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('weight').greaterThanOrEqual(100).and('unit').is('kilos'); // weight >= 100 kilos
       */
      greaterThanOrEqual(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.GREATER_THAN_OR_EQUALS, value));
        return self;
      },

      /**
       * Creates a criterion using '<' operator
       *
       * @param value {Object}
       * @method lessThan
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('age').lessThan(18); // age < 18
       */
      lessThan(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.LESS_THAN, value));
        return self;
      },

      /**
       * Creates a criterion using '<=' operator
       *
       * @param value {Object}
       * @method lessThanOrEqual
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('age').lessThanOrEqual(18); // age <= 18
       */
      lessThanOrEqual(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.LESS_THAN_OR_EQUALS, value));
        return self;
      },

      /**
       * Creates a criterion which tries to search occurrences inside of string.
       *
       * @param value {Object}
       * @method contains // TODO - seems to be analogue to .like() method
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('name').contains('Ja'); // applies to Jake -> Jason -> Janette
       */
      contains(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.CONTAINS, value));
        return self;
      },

      /**
       * Creates a criterion which tries to find occurrences with specific starting value.
       *
       * @param value {Object}
       * @method startsWith
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('name').startsWith('Ja'); // applies to Jake -> Jason -> Janette
       */
      startsWith(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.STARTS_WITH, value));
        return self;
      },

      /**
       * Creates a criterion which tries to find occurrences with specific ending value.
       *
       * @param value {Object}
       * @method contains
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('name').endsWith('lyn'); // applies to Evelyn -> Gerilyn
       */
      endsWith(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.ENDS_WITH, value));
        return self;
      },

      /**
       * Creates a criterion which specifies the range of values in range between two values.
       *
       * @param start {Object}
       * @param end {Object}
       * @method between
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('date').between('2020-10-10', '2040-10-10');
       */
      between(start, end) {
        self.parameters.push(new QueryParameter(fieldName, operators.BETWEEN, start, end));
        return self;
      },

      /**
       * Creates a criterion which tries to find occurrences with specific vales from array.
       *
       * @param value {Array}
       * @method in
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('date').in(['123', '456', '789']);
       */
      in(value) {
        self.parameters.push(new QueryParameter(fieldName, operators.IN, value));
        return self;
      },


      /**
       * Creates a criterion which tries to find all but NOT specific vales from array.
       *
       * @param arrayValue {Array}
       * @method in
       * @return {Criteria}
       * @example
       *
       *      Query.Criteria.where('date').notIn(['123', '456', '789']);
       */
      notIn(arrayValue) {
        self.parameters.push(new QueryParameter(fieldName, operators.NOT_IN, arrayValue));
        return self;
      }
    };
  },

  /**
   * Creates a criterion using 'AND' operator
   *
   * @param fieldName {String}
   * @method and
   * @return {Criteria}
   * @example
   *
   *      Query.filter.where('shape').is('triangle').and('corners').is('round');
   */
  and(fieldName) {
    this.parameters.push(new QueryRelationship(fieldName, operators.AND));

    return this.where(fieldName);
  },

  /**
   * Creates a criterion using 'OR' operator
   *
   * @param fieldName {String}
   * @method or
   * @return {Criteria}
   * @example
   *
   *      Query.filter.where('color').is('green').or('color').is('yellow');
   */
  or(fieldName) {
    this.parameters.push(new QueryRelationship(fieldName, operators.OR));

    return this.where(fieldName);
  },

  /**
   * @method process
   * @protected
   * @param modelDefinition {Object}
   * @return {String}
   */
  process(modelDefinition) {
    const errors = [];
    const result = [];
    const existingSearchFields = modelDefinition.searchFields;
    const { operators } = modelDefinition;
    const errorsWithJSON = {

      getErrors() {
        return errors;
      },

      getJSON() {
        if (result.length > 0) {
          return {
            searchCriteria: result
          };
        }
        return {};
      }

    };

    if (!operators) {
      errors.push(`${modelDefinition.entity.name}.operators is not defined inside model`);
      return errorsWithJSON;
    }

    util.each(this.parameters, parameter => {
      let searchCriteriaItem = {};
      const operatorName = parameter.getOperator();
      const type = parameter.getType();

      if (type === 'QueryParameter') {
        const fieldName = parameter.getFieldName();
        let foundSearchField;
        const foundOperator = util.findWhere(operators, {
          name: operatorName
        });

        util.each(existingSearchFields, (searchField) => {
          if (searchField.field.name === fieldName) {
            foundSearchField = searchField.field;
            return false;
          }

          return undefined;
        }, this);

        if (foundSearchField) {
          const value = parameter.getValue();
          const formatQueryValue = function (value) {
            let type = foundSearchField.fieldType.toLowerCase();

            // use necessary type for JAXB marshalling
            if (type === 'timestamp') {
              type = 'dateTime';
            } else if (type === 'enum') {
              type = 'string';
            }

            // convert strings to numbers if BSL send id as a string
            if (type === 'integer') {
              value = +value;
            }

            return {
              '@type': type,
              $value: value
            };
          };

          searchCriteriaItem = {
            '@type': type,
            field: foundSearchField,
            operator: {
              symbol: foundOperator.symbol
            }
          };

          if (value.length === 1) {
            searchCriteriaItem.queryValue = formatQueryValue(value[0]);
          } else {
            searchCriteriaItem.queryValues = util.map(value, formatQueryValue);
          }
        } else {
          errors.push(`${modelDefinition.entity.name}.searchFields["${fieldName}"] no definition found inside model`);
        }
      } else if (type === 'QueryRelationship') {
        if (operatorName === 'AND') {
          searchCriteriaItem = {
            '@type': 'QueryRelationship',
            name: 'AND',
            description: 'AND relationship.',
            symbol: 'AND'
          };
        } else if (operatorName === 'OR') {
          searchCriteriaItem = {
            '@type': 'QueryRelationship',
            name: 'OR',
            description: 'OR relationship.',
            symbol: 'OR'
          };
        } else {
          errors.push(`${modelDefinition.entity.name}.relationships["${operatorName}"] no definition found inside model`);
        }
      }

      if (!util.isEmpty(searchCriteriaItem)) {
        result.push(searchCriteriaItem);
      }
    });

    return errorsWithJSON;
  },

  /**
   * @method getQueryPartKey
   * @protected
   * @return {String}
   */
  getQueryPartKey() {
    return 'criteria';
  }

});

util.extend(Criteria.prototype, QueryPart.prototype);
/**
 * Helper class to define sorting criteria for a Query instance.
 *
 * @class Sort
 * @private
 * @constructor
 */
const Sort = function () {
  this.pairs = {};
};

util.extend(Sort.prototype, {
  /**
   * Apply ascending ordering to specified `fieldName`.
   * @method ascending
   * @param fieldName
   * @example
   *      Query.order.ascending('givenName').descending('familyName');
   */
  ascending(fieldName) {
    return fieldName ? this.add(fieldName, 'ASC') : this;
  },

  /**
   * Apply descending ordering to specified `fieldName`.
   * @method descending
   * @param fieldName
   * @example
   *      Query.order.descending('givenName').ascending('familyName');
   */
  descending(fieldName) {
    return fieldName ? this.add(fieldName, 'DESC') : this;
  },

  /**
   * Internal method for sorting order to specified field.
   *
   * @method add
   * @param fieldName {String}
   * @param orders {Sort.Order|Array<Sort.Order>}
   * @return {Sort} Sort object for chaining
   * @example
   *
   *      Query.Sort.add('name', Query.Sort.Order.ASC);
   *      Query.Sort.add('accountNumber', [Query.Sort.Order.ASC, Query.Sort.Order.DESC]);
   *
   */
  add(fieldName, orders) {
    orders = (orders && orders.length > -1) ? orders : [orders];

    const existing = this.pairs[fieldName] ? this.pairs[fieldName] : [];
    this.pairs[fieldName] = existing.concat(orders);

    return this;
  },

  process(modelDefinition) {
    const result = [];
    const errors = [];
    const existingSortFields = modelDefinition[this.getQueryPartKey()];

    if (!existingSortFields || existingSortFields && existingSortFields.length === 0) {
      return {
        getErrors() {
          return [`${modelDefinition.entity.name}.sortFields is empty`];
        },

        getJSON() {
          return result;
        }
      };
    }

    for (const key in this.pairs) {
      if (this.pairs.hasOwnProperty(key)) {
        const fieldName = key;
        const orders = this.pairs[key];
        let found = null;

        util.each(existingSortFields, sortFieldDefinition => {
          const { sortTypes } = sortFieldDefinition;

          if (sortFieldDefinition.field.name === fieldName) {
            found = {
              field: sortFieldDefinition.field,
              sortTypes: []
            };
            util.each(orders, (order) => {
              const sortType = util.findWhere(sortTypes, {
                symbol: order
              });

              if (sortType) {
                found.sortTypes.push(sortType);
              } else {
                errors.push(`${modelDefinition.entity.name}.${fieldName}.sortTypes[${order}] no definition found.`);
              }
            }, this);
          }
        });

        if (found) {
          result.push(found);
        } else {
          errors.push(`${modelDefinition.entity.name}.sortFields["${fieldName}"] no definition found within such sort types: ${orders.join(', ')}`);
        }
      }
    }

    return {

      getErrors() {
        return errors;
      },

      getJSON() {
        return result;
      }

    };
  },

  getQueryPartKey() {
    return 'sortFields';
  }
});

util.extend(Sort.prototype, QueryPart.prototype);
Query.Service = (function service() {
  /**
   * Provides API to perform actions with entities' model definitions.
   *
   * @class Service
   * @static
   */
  const Service = {
    /**
     * Provides list of model definitions.
     *
     * @method getTypes
     * @param callback {Function} Function to be invoked at end of HTTP request.
     * @example
     *
     *      var callback = function(err, result) {
             *          if (err) {
             *              // err - array of error messages
             *              return;
             *          }
             *          // do something with data
             *      };
     *
     *      Query.Service.getTypes(callback);
     */
    getTypes(callback) {
      const url = `${urls.servicePath}/query/types`;

      simpleHttpRequest('get', url, callback);
    },

    /**
     * Provides model definition using specified key.
     *
     * @method getTypeByKey
     * @param key {String}
     * @param callback {Function} Function to be invoked at end of HTTP request.
     * @example
     *
     *      var callback = function(err, result) {
             *          if (err) {
             *              // err - array of error messages
             *              return;
             *          }
             *          // do something with data
             *      };
     *      var key = 'SOME_UNIQUE_KEY';
     *
     *      Query.Service.getTypeByKey(key, callback);
     */
    getTypeByKey(key, callback) {
      const url = `${urls.servicePath}/query/type/entity/key/${key}`;

      simpleHttpRequest('get', url, callback);
    },

    /**
     * Provides model definition using specified name.
     *
     * @method getTypeByName
     * @param name {String} Entity name
     * @param callback {Function} Function to be invoked at end of HTTP request.
     * @example
     *
     *      var callback = function(err, result) {
             *          if (err) {
             *              // err - array of error messages
             *              return;
             *          }
             *          // do something with data
             *      };
     *      var type = 'ENTITY_TYPE';
     *
     *      Query.Service.getTypeByName(type, callback);
     */
    getTypeByName(name, callback) {
      const url = `${urls.servicePath}/query/type/entity/name/${name}`;

      simpleHttpRequest('get', url, callback);
    }

  };

  return Service;
}());

// Main Public API
util.extend(Query, {

  /**
   * Methods for building a query filter.
   * @class Query.filter
   * @static
   */
  filter: {

    /**
     * Creates a new `Criteria` instance using the provided `fieldName`.
     * @method where
     * @param fieldName
     * @return {Criteria}
     * @example
     *
     *      // humans with given name - John and family name - Doe
     *      Query.filter.where('givenName').is('John').and('familyName').is('Doe');
     */
    where(fieldName) {
      return new Criteria().where(fieldName);
    }

  },

  /**
   * Methods for building a query order.
   * @class Query.order
   * @static
   */
  order: {

    /**
     * Creates a new `Sort` instance, where the first `fieldName` is ordered ascending.
     * @method ascending
     * @param fieldName
     * @returns {Sort}
     * @example
     *      Query.order.ascending('givenName').descending('familyName');
     */
    ascending(fieldName) {
      return fieldName ? new Sort().add(fieldName, 'ASC') : null;
    },

    /**
     * Creates a new `Sort` instance, where the first `fieldName` is ordered descending.
     * @method descending
     * @param fieldName
     * @returns {Sort}
     * @example
     *      Query.order.descending('givenName').ascending('familyName');
     */
    descending(fieldName) {
      return fieldName ? new Sort().add(fieldName, 'DESC') : null;
    }

  }
});

Query.create = (function create() {
  const QueryEntity = function (type) {
    this.type = type;
  };

  util.extend(QueryEntity.prototype, {
    getType() {
      return this.type;
    },

    getQueryPartKey() {
      return 'entity';
    },

    process(modelDefinition) {
      const self = this;

      return {
        getErrors() {
          return [];
        },

        getJSON() {
          return modelDefinition[self.getQueryPartKey()];
        }
      };
    }
  });

  util.extend(QueryEntity.prototype, QueryPart.prototype);
  const QueryFields = function () {
    this.fields = [];
  };

  util.extend(QueryFields.prototype, {
    getQueryPartKey() {
      return 'resultFields';
    },

    setFields(fields) {
      this.fields = fields;
    },

    process(modelDefinition) {
      const result = [];
      const errors = [];
      const existingResultFields = modelDefinition[this.getQueryPartKey()];

      util.each(this.fields, (fieldName) => {
        const found = util.findWhere(existingResultFields, {
          name: fieldName
        });

        if (found) {
          result.push(found);
        } else {
          errors.push(`${modelDefinition.entity.name}.fields["${fieldName}"] is not defined inside model`);
        }
      }, this);

      return {

        getErrors() {
          return errors;
        },

        getJSON() {
          return result;
        }

      };
    }
  });

  util.extend(QueryFields.prototype, QueryPart.prototype);
  const QueryPaginate = function () {
    this.firstResult = -1;
    this.maxResults = -1;
  };

  util.extend(QueryPaginate.prototype, {
    setFirstResult(firstResult) {
      this.firstResult = firstResult;
    },

    setMaxResults(maxResults) {
      this.maxResults = maxResults;
    },

    getQueryPartKey() {
      return 'resultsPage';
    },

    process() {
      const result = {};

      if (this.firstResult !== -1) {
        result.firstResult = this.firstResult;
      }

      if (this.maxResults !== -1) {
        result.maxResults = this.maxResults;
      }

      return {

        getErrors() {
          return [];
        },

        getJSON() {
          return result;
        }

      };
    }
  });

  util.extend(QueryPaginate.prototype, QueryPart.prototype);

  /**
   * Builder class to build queries.
   *
   * @class Query
   * @constructor
   * @param entity {QueryEntity}
   */
  const Query = function (entity) {
    entity = entity || new QueryEntity();

    this.entity = entity;
    this.criteria = null;
    this.sort = null;
    this.paginate = new QueryPaginate();
    this.fields = new QueryFields();

    const self = this;

    return {

      /**
       * Adds the given criteria to the current Query.
       *
       * @method filterBy
       * @param criteria {Criteria}.
       * @return {Query} Query object for chaining
       */
      filterBy(criteria) {
        // Continue silently if no criteria is supplied
        if (criteria) {
          self.criteria = criteria;
        }
        return this;
      },

      /**
       * Adds a Sort to the Query instance.
       *
       * @method sortBy
       * @param sort {Sort}
       * @return {Query} returns Query object for chaining
       */
      sortBy(sort) {
        self.sort = sort;

        return this;
      },

      /**
       * Sets the given pagination and limit information on the Query instance.
       *
       * @method paginate
       * @param firstResult {Number} number of skipper items from start in result set
       * @param maxResults {Number} maximum amount of items in result set
       * @return {Query} Query object for chaining
       */
      paginate(firstResult, maxResults) {
        self.paginate.setFirstResult(firstResult);
        self.paginate.setMaxResults(maxResults);

        return this;
      },

      /**
       * Method takes one or more string parameter of field names to define the
       * output data result set.
       *
       * @method fields
       * @return {Query} Query object for chaining
       */
      fields() {
        self.fields.setFields(Array.prototype.slice.call(arguments, 0));

        return this;
      },

      /**
       * Builds the Query instance.
       *
       * @method build
       * @return
       */
      build() {
        return {

          getEntityType() {
            return self.entity.getType();
          },

          apply(modelDefinition) {
            let errors = [];
            let nativeQuery = {};
            const result = {
              getErrors() {
                return errors;
              },

              getNativeQuery() {
                return nativeQuery;
              }
            };

            if (!modelDefinition) {
              errors.push(`There is no model definition found within such type: ${self.entity.getType()}`);
              return result;
            }

            const collect = function (part) {
              errors = errors.concat(part.getErrors());
              nativeQuery = util.extend(nativeQuery, part.getJSON());
            };

            collect(self.entity.getQueryPart(modelDefinition));

            if (self.criteria) {
              collect(self.criteria.getQueryPart(modelDefinition));
            }

            if (self.sort) {
              collect(self.sort.getQueryPart(modelDefinition));
            }

            collect(self.fields.getQueryPart(modelDefinition));
            collect(self.paginate.getQueryPart(modelDefinition));

            return result;
          },

          getRequestBody(callback) {
            const entityType = this.getEntityType();
            const url = `${urls.servicePath}/query/type/entity/name/${entityType}`;
            const cachedDefinition = cache[entityType];
            const handler = (err, modelDefinition) => {
              if (err) {
                return callback([err]);
              }

              const result = this.apply(modelDefinition);
              const data = result.getNativeQuery();

              return callback(null, data);
            };

            if (cachedDefinition) {
              handler(null, cachedDefinition);
            } else {
              simpleHttpRequest('get', url, handler);
            }
          },

          /**
           * Returns data by given query.
           *
           * @method execute
           * @param callback {Function} Function to be invoked at the end of HTTP request.
           * @param options - not sure, but if options passed it changes context/this
           * @example
           *
           *      var callback = function(err, result) {
                         *          if (err) {
                         *              // err - array of error messages
                         *              return;
                         *          }
                         *          // do something with data
                         *      };
           *
           *      var sort = Query.order.ascending('Value Date');
           *      var criteria = Query.filter.where('Account Number').is('373434348348348')
           *                              .and('Bank Code').is('83755GGKY');
           *
           *      var query = Query.create('TransactionDetail')
           *                      .filterBy(criteria)
           *                      .sortBy(sort)
           *                      .fields('Account Id', 'Account Detail')
           *                      .paginate(0, 1)
           *                      .build();
           *
           *      query.execute(callback);
           *
           */
          execute(callback, options = {}) {
            const entityType = this.getEntityType();
            const url = `${urls.servicePath}/query/type/entity/name/${entityType}`;
            const cachedDefinition = cache[entityType];
            const handler = (err, modelDefinition) => {
              if (err) {
                callback([err]);
                return;
              }

              const result = this.apply(modelDefinition);
              const errors = result.getErrors();
              const data = result.getNativeQuery();
              let url = `${urls.servicePath}/query/execute`;

              url = options.url ? options.url(url) : url;

              if (errors.length > 0) {
                callback(errors);
              } else {
                if (!data.resultFields) {
                  data.resultFields = [];
                }
                data.resultFields.push({
                  name: 'rowCount',
                  symbol: 'com.bottomline.query.count',
                  fieldType: 'LONG',
                  key: false
                });
                simpleHttpRequest('post', url, data, (err, rawJSON) => {
                  rawJSON = rawJSON || {
                    rows: [],
                    fields: []
                  };

                  if (err) {
                    callback([err]);
                    return;
                  }

                  // push model definition to cache
                  cache[entityType] = modelDefinition;

                  const options = {};
                  const result = [];
                  const { fields } = rawJSON;
                  let rows = [].concat(rawJSON.rows);

                  // match the content of the last received row
                  // it can be rowCount or normal result field
                  if (rows.length > 0) {
                    const type = rows[rows.length - 1].values[0].resultValues[0].value['@type'];

                    if (type && type.toLowerCase() === 'long') {
                      rows = rows.slice(0, rows.length - 1);
                      options.totalCount = rawJSON.rows[rawJSON.rows.length - 1].values[0].resultValues[0].value.$value;
                    }
                  }

                  util.each(rows, (valuesObj) => {
                    const rowData = {};
                    const columns = valuesObj.values;

                    util.each(fields, (fieldDefinition, index) => {
                      if (fieldDefinition.name !== 'rowCount') {
                        const fieldName = fieldDefinition.name;
                        const valueData = columns[index].resultValues[0];

                        rowData[fieldName] = valueData ? valueData.value : null;
                      }
                    });

                    result.push(rowData);
                  });

                  callback(null, result, options);
                });
              }
            };

            if (cachedDefinition) {
              handler(null, cachedDefinition);
            } else {
              simpleHttpRequest('get', url, handler);
            }
          }
        };
      }
    };
  };

  /**
   * Creates a `Query` instance.
   * @method create
   * @param entityType {String} entity type
   * @returns {Query} instance of the Query object
   * @example
   *
   *  Find humans with given name 'Alex' and age greater than 21 also sort
   *  found items by family name and retrieve only ids with offset 0 and limit 10
   *
   *      Query.create('Human')
   *          .filterBy(Query.filter.where('givenName').is('Alex').and('age').greaterThan(21))
   *          .sortBy(Query.order.ascending('familyName'))
   *          .fields('id')
   *          .paginate(0, 10)
   *          .build()
   */
  function create(entityType) {
    return new Query(new QueryEntity(entityType));
  }

  return create;
}());

export default Query;

