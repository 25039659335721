import { ashTheme, ThemeProvider } from '@glu/theming';
import mobileUtil from 'mobile/util/mobileUtil';
import React from 'react';
import ReactDOM from 'react-dom';
import PopupAlert from '../../../components/PopupAlert/PopupAlert';
import AlertLayout from './alertView-bullet';

export default AlertLayout.extend({
    initialize(options) {
        this.FromMobile = mobileUtil.isMobileScreen();
        if (this.FromMobile) {
            this.options = options;
            this.previousFocusElement = document.activeElement;
            options.remove = this.remove.bind(this);
        } else {
            AlertLayout.prototype.initialize.call(this, options);
        }
    },
    render() {
        if (this.FromMobile) {
            ReactDOM.render(
                <ThemeProvider baseTheme={ashTheme}>
                    <PopupAlert {...this.options} />
                </ThemeProvider>
                , this.$el[0]);

            /*  
            * HACK: remove alert clases and role from old alert container
            * this avoids scrollToFirstError method inside scroll.js
            * to change focus from modal to container after PopupAlert renders
            */
            this.el.className = '';
            this.el.removeAttribute('role');
        } else {
            AlertLayout.prototype.render.call(this);
        }
        return this;
    },

    remove() {
        if (this.FromMobile) {
            ReactDOM.unmountComponentAtNode(this.$el[0]);
            this.previousFocusElement.focus();
        }
        AlertLayout.prototype.remove.call(this);
    },
});
