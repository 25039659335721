import services from 'services';
import BaseRDCCollection from './baseRDCCollection';

export default BaseRDCCollection.extend({

    useLocationWorkflow: true,

    /**
     * Get the service URL for this collection
     * @returns {string}
     */
    getServiceURL() {
        return this.useLocationWorkflow
            ? services.rdcDepositAccountsByLocationEntitlements
            : services.rdcDepositAccountsByEntitlements;
    },

    /**
     * @param {Object} options
     * @param {string} options.locationName
     * @returns {Object} data
     */
    getRequestData(options) {
        const data = {
            startRow: 1,
            searchFields: [{
                fieldName: 'PRODUCT',
                dataType: 'text',
                operator: 'IN',
                fieldValue: [
                    'RDC',
                ],
            },
            {
                fieldName: 'TYPE',
                dataType: 'text',
                operator: 'IN',
                fieldValue: [
                    '*', 'MOBILE',
                ],
            },
            ],
            dataOnly: 0,
        };

        if (options && options.locationName) {
            data.searchFields.push({
                fieldName: 'LOCATIONNAME',
                dataType: 'text',
                operator: 'IN',
                fieldValue: [
                    options.locationName,
                ],
            });
        }

        return data;
    },

    /**
     * Set the userLocationWorkflow flag to the specified value
     * @param {boolean} value
     */
    setUseLocationWorkflow(value) {
        this.useLocationWorkflow = value;
    },
});
