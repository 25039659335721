var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cminst.importCheck_title-view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":77}}}))
    + "</h1>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.check.detail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":67}}}))
    + "</h1>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <input type=\"hidden\" id=\"serialField\" class=\"form-control serial-field\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <p class=\"form-control-static serial-field\"></p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <button href=\"#\" title=\"Previous Check\" class=\"prev-page btn-tertiary btn\" disabled=\"disabled\">\n                    <span class=\"sr-only\">Previous Page</span>\n                    <span class=\"icon-arrow-left\" role=\"presentation\"></span>\n                </button>\n\n                <div class=\"check-nav-display\"></div>\n\n                <button href=\"#\" title=\"Next Check\" class=\"next-page btn-tertiary btn\" disabled=\"disabled\">\n                    <span class=\"sr-only\">Next Page</span>\n                    <span class=\"icon-arrow-right\" role=\"presentation\"></span>\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":144},"end":{"line":4,"column":186}}}))
    + "</span></a>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPreAuthDebit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n</div>\n\n<div class=\"check-viewer-header\">\n    <div class=\"row\">\n        <div class=\"form-group col-2\" id=\"serialNumDiv\">\n\n            <label for=\"serialField\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.SerialNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":37},"end":{"line":19,"column":65}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMany") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "\n\n        </div>\n        <div class=\"check-paging col-2\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMany") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n\n<div class=\"check-display\"></div>\n";
},"useData":true});