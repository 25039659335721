import 'jquery-mask-plugin/dist/jquery.mask';
import $ from '../../$';
import util from '../../util';
import number from '../../number';

export default function loadMasking() {
  /**
   * @param customMaskPattern {String}
   */
  $.fn.maskNumeric = function maskNumeric(customMaskPattern) {
    let mask = 'SDDDDDDDDDC0D'; // Default mask - up to 9,999,999,999.99
    if (!util.isEmpty(customMaskPattern) && util.isString(customMaskPattern)) {
      mask = customMaskPattern.toUpperCase();
    }

    return $.fn.mask.apply(this, [mask, {
      translation: {
        S: {
          pattern: /^[-+]/,
          optional: true
        },
        C: {
          pattern: /\./,
          options: true
        },
        D: {
          pattern: /[0-9]/,
          optional: true
        }
      }
    }]);
  };

  $.fn.maskAmount = function maskAmount() {
    // Generate mask according to user localization
    const { decimal, thousands } = number.languageData().delimiters;
    const mask = `#${thousands}##0${decimal}00`;

    return $.fn.mask.apply(this, [mask, {
      onKeyPress(value, e, field) {
        if (value.length < 2) {
          value = `0${decimal}0${+value}`;
        } else if (value.length < 3) {
          value = `0${decimal}${value}`;
        } else {
          // will format value according to user localization
          value = number(value).format('0,0.00');
        }
        field.val(value);
      },
      selectOnFocus: true,
      reverse: true
    }]);
  };
}
