import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import TransactionNoteModel from 'app/reports/models/transactionNote';
import moment from 'moment';
import http from '@glu/core/src/http';
import httpError from 'common/util/httpErrorResult';

export default Collection.extend({
    model: TransactionNoteModel,

    initialize(options) {
        this.eid = options.eid || null;
        this.request = {};
    },

    comparator(model) {
        return -moment(model.get('ENTERED_TIMESTAMP')).unix();
    },

    parse(result) {
        const notes = [];
        util.each(result.rows, (data) => {
            const note = {};
            for (let x = 0; x < data.columns.length; x += 1) {
                const { fieldName } = data.columns[x];
                let { fieldValue } = data.columns[x];

                if (fieldName === 'ENTERED_TIMESTAMP' || fieldName === 'MODIFIED_TIMESTAMP') {
                    fieldValue = moment(fieldValue).format('YYYY-MM-DD hh:mm');
                }
                note[fieldName] = fieldValue;
            }
            notes.push(note);
        });

        return notes;
    },

    sync(method, model, options) {
        const url = services.generateUrl('tableMaintenance/lockboxNotes/getListView');

        if (this.eid) {
            this.request = {
                rowsPerPage: 10,
                startRow: 1,

                searchFields: [{
                    dataType: 'number',
                    fieldName: 'ENVELOPE_ID',
                    operator: 'IN',
                    fieldValue: [this.eid],
                }],
            };
        }

        if (method === 'read') {
            http.post(url, this.request, (results) => {
                options.success(results);
            }, httpError.getResult(options, model));
        }
    },
});
