var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.GroupAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":34}}}))
    + "</h3>\n<p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.AccountGroupParagraph",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":41}}}))
    + "</p>\n<p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.AccountGroupParagraph.D",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":3},"end":{"line":3,"column":43}}}))
    + "</p>\n<button class=\"btn btn-secondary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.CreateGroups",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":63}}}))
    + "</button>";
},"useData":true});