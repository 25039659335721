import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import patterns from 'system/validatorPatterns';
import locale from '@glu/locale';

export default Model.extend({
    idAttribute: 'contentId',
    parse(resp) {
        return resp;
    },
    initialize() {
        this.validators = {
            name: {
                description: locale.get('CXP.elearning.name'),
                matches: patterns.ALPHANUMERICUNSCORE_PATTERN,
            },
        };
    },

    sync(method, model, options) {
        let xhr;

        switch (method) {
        case 'read':
            http.post(services.generateUrl('/cxp/elearningContent/read'), {
                contentId: parseInt(this.get('contentId'), 10),
            }, options.success, options.error);
            break;
        case 'create':
            xhr = http.post(services.generateUrl('/cxp/elearningContent/add'), model.toJSON(), (result) => {
                if (result !== undefined) {
                    model.set('contentId', result.contentId);
                }
                options.success(result);
            }, options.error);
            break;
        case 'update':
            xhr = http.post(
                services.generateUrl('/cxp/elearningContent/update'), model.toJSON(), (result) => {
                    options.success(result);
                },
                (result) => {
                    if (result.status === 200) {
                        options.success(result);
                    } else {
                        options.error(result);
                    }
                },
            );
            break;
        case 'delete':
            xhr = http.post(
                services.generateUrl('/cxp/elearningContent/delete'), model.toJSON(), (result) => {
                    options.success(result);
                },
                (result) => {
                    if (result.status === 200) {
                        options.success(result);
                    } else {
                        options.error(result);
                    }
                },
            );
            break;
        default:
        }
        return xhr;
    },
});
