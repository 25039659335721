import ItemView from '@glu/core/src/itemView';
import template from './formattedCell.hbs';

export default ItemView.extend({
    template,

    templateHelpers() {
        const self = this;
        return {
            formattedValue: self.getFormattedValue(),
        };
    },

    getFormattedValue() {
        return this.model.get('DISPLAY_STRING');
    },
});
