export default ({
    set(key, value, daysUntilExpiry) {
        let date;
        let expires = '';

        if (daysUntilExpiry) {
            date = new Date();
            date.setTime(date.getTime() + (daysUntilExpiry * 24 * 60 * 60 * 1000));
            expires = `; expires=${date.toGMTString()}`;
        }

        const secure = window.location.protocol === 'https:' ? '; SameSite=None; Secure' : '';

        document.cookie = `${key}=${value}${expires}; path=/;${secure}`;
    },

    get(key) {
        const nameEQ = `${key}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substr(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substr(nameEQ.length, c.length);
            }
        }
        return undefined;
    },

    unset(key) {
        this.set(key, '', -1);
    },
});
