var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" data-hook=\"getBackButton\" href=\"#\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":149},"end":{"line":3,"column":191}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"stats.adminReportHeading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":68}}}))
    + "</h1>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n            <div class=\"btn-group widget-links\">\n                <button type=\"button\" class=\"btn btn-tertiary export\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":77},"end":{"line":11,"column":103}}}))
    + "\" data-hook=\"export-button\"><span class=\"icon-export\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":165},"end":{"line":11,"column":191}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary print\" title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":76},"end":{"line":12,"column":101}}}))
    + "\" data-hook=\"print-button\"><span class=\"icon-print\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":161},"end":{"line":12,"column":186}}}))
    + "</button>\n            </div>\n        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n    </div>\n</div>\n";
},"useData":true});