import { appBus } from '@glu/core';
import http from '@glu/core/src/http';
import services from 'services';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import userInfo from 'etc/userInfo';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import constants from 'common/dynamicPages/api/constants';

const cutoffAlerts = ['EPCUTOFF', 'BMCUTOFF', 'CMCUTOFF', 'FICVALRT', 'FIPTALRT', 'PSTAALRT', 'CSTAALRT', 'FISCALRT', 'CTOFALRT', 'SSACKS', 'SSACKE'];
const isCutoffAlert = alerttype => util.indexOf(cutoffAlerts, alerttype.getValue()) !== -1;

const alertTypesWithTimeToggle = ['APRVEWP', 'PAYNRATE', 'RTGSALRT', 'PTMPALRT'];
/**
 * @function canShowAlertTimingToggle
 * Determines whether or not the Alert Timing toggle (ALERTCLASS param) can be shown.
 * Currently determined by both an application config and which alert types have this feature.
 * @param {string} alertType - the current alert type
 * @param {array} allowedTypes - the alert types that allow real time.
 * Defaults to alertTypesWithTimeToggle
 * @returns {boolean} whether or the alert timing toggle should be available on the form
 */
const canShowAlertTimingToggle = (alertType, allowedTypes = alertTypesWithTimeToggle) => allowedTypes.includes(alertType) && applicationConfigParams.getValue('ALRT', 'ALLOWTOGGLETIMEDACTIONALERTS') === '1';

const hideWarning = (form) => {
    form.formView.$el.find('.icon-exclamation-circle').parent().addClass('hidden');
};

const displayWarning = (form) => {
    form.formView.$el.find('.icon-exclamation-circle').parent().removeClass('hidden');
};

const checkFormField = (currentListItem, formFieldName, form) => {
    if (currentListItem.value === 'Y') {
        form.field(formFieldName).shouldBeVisible();
    } else {
        form.field(formFieldName).shouldBeHidden();
    }
};

const setByTenantConfig = (form) => {
    const tenantdata = {
        queryCriteria: {
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['TENANTID', userInfo.get('tenantId')],
            }],

            fieldName: 'TENANT_LOOKUP',
        },
    };
    // check tenant config
    http.post(
        services.generateUrl(form.formView.context.serviceName.concat('/getQueryResults')), tenantdata,
        (result) => {
            if (result.queryResponse.QueryData.numRows > 0) {
                if (result.queryResponse.QueryData.queryRows.length > 0) {
                    const list = result.queryResponse.QueryData.queryRows[0].mapDataList;
                    list.forEach((listItem) => {
                        if (listItem.toField === 'ALLOWSECONDARYEMAIL') {
                            checkFormField(listItem, 'RECEIVEEMAIL', form);
                        } else if (listItem.toField === 'ALLOWSMS') {
                            checkFormField(listItem, 'RECEIVESMS', form);
                        } else if (listItem.toField === 'ALLOWPHONE') {
                            checkFormField(listItem, 'RECEIVEVOICE', form);
                        } else if (listItem.toField === 'ALLOWFAX') {
                            checkFormField(listItem, 'RECEIVEFAX', form);
                        }
                    });
                }
            }
        },
    );
    displayWarning(form);
};

const setByUserAlertConfig = (model, form) => {
    const name = model.get('RECIPIENT').toString();
    const userdata = {
        queryCriteria: {
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['NAME', name],
            }],

            fieldName: 'USERALERTMETHOD_LOOKUP',
        },
    };
    // check user config
    http.post(
        services.generateUrl(form.formView.context.serviceName.concat('/getQueryResults')), userdata,
        (result) => {
            if (result.queryResponse.QueryData.numRows > 0
                && result.queryResponse.QueryData.queryRows.length > 0) {
                const list = result.queryResponse.QueryData.queryRows[0].mapDataList;
                list.forEach((listItem) => {
                    if (listItem.toField === 'USERALERTMETHODS') {
                        const userAlertMethods = listItem.value.split('^');
                        userAlertMethods.forEach((userAlertMethod) => {
                            if (userAlertMethod === 'EMAIL') {
                                form.field('RECEIVEEMAIL').shouldBeReadOnly(false);
                            } else if (userAlertMethod === 'SMS') {
                                form.field('RECEIVESMS').shouldBeReadOnly(false);
                            } else if (userAlertMethod === 'VOICE') {
                                form.field('RECEIVEVOICE').shouldBeReadOnly(false);
                            } else if (userAlertMethod === 'FAX') {
                                form.field('RECEIVEFAX').shouldBeReadOnly(false);
                            }
                        });
                    }
                });
            }
        },
    );
    hideWarning(form);
};
const setAlertMethodForRecipient = (model, form) => {
    const data = {
        queryCriteria: {
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['NAME', model.get('RECIPIENT').toString()],
            }],

            fieldName: 'RECIPIENT_LOOKUP',
        },
    };

    http.post(
        services.generateUrl(form.formView.context.serviceName.concat('/getQueryResults')), data,
        (result) => {
            if (result.queryResponse.QueryData.numRows > 0) {
                if (result.queryResponse.QueryData.queryRows.length > 0) {
                    const list = result.queryResponse.QueryData.queryRows[0].mapDataList;
                    for (let i = 0; i < list.length; i += 1) {
                        if (list[i].toField === 'GROUPFLAG') {
                            if (list[i].value === '0') {
                                // individual recipient
                                setByUserAlertConfig(model, form);
                            }
                        }
                    }
                }
            }
        },
        () => {
            // No Error on Failed Lookup
        },
    );
};

const setTimeZoneId = (timeZoneVal, formParam, model, timezone) => {
    const form = formParam;
    /**
     * If the Timezone is not set, display the user's timezone on insert
     * or the alert timezone on modify. This forces the combo box to load the value
     * that matches the users timezone so the text can be displayed in the same format.
     */
    form.formView.typeAheadCollections.TIMEZONEID.queryTerm = timeZoneVal;
    form.formView.typeAheadCollections.TIMEZONEID.fetch({
        remove: true,

        success: (collection, resp) => {
            const text = collection.generateText(resp[0].columns);
            model.set('TIMEZONEID', resp[0].id);
            timezone.setComboBoxValue(text);
        },
        error() { },
    });
};

/**
 * @function showOrHideTime
 * Shows or hides the hours, minutes, and timezone fields
 * @param {Object} timeFields
 * @param {Object} timeFields.hours - hours field
 * @param {Object} timeFields.mins - minutes field
 * @param {Object} timeFields.timezone - timezone field
 * @param {boolean} doShow - show or hide the time fields
 */
const showOrHideTime = ({ hours, mins, timezone }, doShow) => {
    if (doShow) {
        hours.shouldBeVisible();
        mins.shouldBeVisible();
        timezone.shouldBeVisible();
        hours.shouldBeRequired(true);
        mins.shouldBeRequired(true);
        timezone.shouldBeRequired(true);
    } else {
        hours.shouldBeHidden();
        mins.shouldBeHidden();
        timezone.shouldBeHidden();
        hours.shouldBeOptional(true);
        mins.shouldBeOptional(true);
        timezone.shouldBeOptional(true);
    }
};

/**
 * @function setScheduleFieldForAlertType
 * Shows or hides time fields based on the the model's ALERTCLASS param as well as availability
 * of the real time toggle. Sets the timezone if it is not set.
 * @param {Object} formProps
 * @param {Object} formProps.model - the alert form model
 * @param {Object} formProps.hours - hours field
 * @param {Object} formProps.mins - minutes field
 * @param {Object} formProps.timezone - timezone field
 * @param {Object} formProps.alerttype - alert type field
 * @param {Object} formProps.alertClass - alert class field
 * @param {Object} formProps.form - the form object
 */
const setScheduleFieldForAlertType = ({
    model,
    hours,
    mins,
    timezone,
    alerttype,
    alertClass,
    form,
}) => {
    /*
     * TODO _ALERTCLASS is set in INSERT mode; ALERTCLASS is used in modify. We
     * should use one not both
     */
    const alertClassValue = model.get('_ALERTCLASS') || model.get('ALERTCLASS');
    if ((alertClassValue === 'TIMED' || alertClassValue === 'SERVICE') && alerttype.getValue() && !isCutoffAlert(alerttype)) {
        showOrHideTime({ hours, mins, timezone }, true);
        if (canShowAlertTimingToggle(alerttype.getValue())) {
            alertClass.shouldBeVisible();
            alertClass.shouldBeRequired(true);
        }

        if (timezone.isEmpty() && !util.isEmpty(userInfo.get('timezone'))) {
            setTimeZoneId(userInfo.get('timezone'), form, model, timezone);
        }
    } else {
        showOrHideTime({ hours, mins, timezone }, false);
        if (canShowAlertTimingToggle(alerttype.getValue())) {
            alertClass.shouldBeHidden();
            alertClass.shouldBeOptional(true);
        }
    }
};

export default (formParam, initialState) => {
    const form = formParam;
    const { model } = form.formView;
    const hours = form.field('SCHEDULE_HOURS');
    const mins = form.field('SCHEDULE_MINS');
    const timezone = form.field('TIMEZONEID');
    const alertid = form.field('ALERTID');
    const alerttype = form.field('ALERTTYPE');
    const alertClass = form.field('ALERTCLASS');
    const recipientEmail = form.field('RECIPIENT_EMAIL');
    const recipientGroup = form.field('RECIPIENT_GROUP');
    const mode = model.context.actionMode;

    const alertTimingIsAvailable = canShowAlertTimingToggle(alerttype.getValue() || model.get('TYPE'));
    if (!alertTimingIsAvailable) {
        alertClass.shouldBeHidden();
    }

    const toggleRecipientLookups = (groupFlag) => {
        if (groupFlag === '0') {
            model.removeValidator('RECIPIENT_GROUP');
            recipientGroup.setComboBoxValue('');
            recipientGroup.shouldBeHidden(true);
            recipientEmail.shouldBeVisible(true);
            model.addValidator(
                'RECIPIENT_EMAIL',
                {
                    exists: true,
                    description: locale.get('alert.Recipient'),
                },
            );
        } else {
            model.removeValidator('RECIPIENT_EMAIL');
            recipientEmail.setComboBoxValue('');
            recipientEmail.shouldBeHidden(true);
            recipientGroup.shouldBeVisible(true);
            model.addValidator(
                'RECIPIENT_GROUP',
                {
                    exists: true,
                    description: locale.get('alert.RecipientGroup'),
                },
            );
        }
    };

    const handleTimezoneLocale = () => {
        /**
         * Make sure that Alert Timezone respects translated locale
         * Since the response doesn't come back with necessary information, an HTTP request is
         * necessary to gather the necessary data.
         */
        const $staticChosenTimezone = form.formView.$el.find('#TIMEZONEID').find('.form-control-static');
        const timezoneString = $staticChosenTimezone.text().trim();

        if (timezoneString) {
            /*
              * since policies can be applied multiple times, taking a side approach and
              * storing a global prob to monitor if a request for this data is already happening.
              * Once it completes the POST and gets the data, the prop is deleted.
              */
            if (!form.tempRequestInProgress) {
                form.tempRequestInProgress = true;
                const data = {
                    rowsPerPage: '10',
                    startRow: '1',
                    fieldName: 'TIMEZONEIDLOOKUP',
                    typeCode: '*',
                    productCode: '*',
                    functionCode: 'ALERT',
                    depends: [],
                    subType: '*',
                    searchOrOperator: true,
                    searchFields: [{
                        fieldName: 'ID',
                        operator: 'CONTAINS',
                        fieldValue: [timezoneString],
                        dataType: 'text',
                    }],
                };
                http.post(services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION), data)
                    .then((result) => {
                        const translatedValue = result?.rows[0]?.columns[0]?.fieldValue;
                        $staticChosenTimezone.text(translatedValue);
                    })
                    .always(() => {
                        // `always` is part of jQuery's promise handling
                        form.tempRequestInProgress = false;
                    });
            }
        }
    };

    if (initialState) {
        if (mode === 'view') {
            handleTimezoneLocale();
            /*
             * hide groupflag & alertid value
             * there is no form element in 'view' mode
             */
            $('#GROUPFLAG').addClass('hidden');
            $('#ALERTID').addClass('hidden');
        } else {
            alertid.shouldBeHidden();
        }

        if ((model.get('ALERTCLASS') !== 'TIMED' && model.get('ALERTCLASS') !== 'SERVICE') || isCutoffAlert(alerttype)) {
            hours.shouldBeHidden();
            mins.shouldBeHidden();
            timezone.shouldBeHidden();
            if (alertTimingIsAvailable) {
                if (mode === 'view') {
                    alertClass.shouldBeVisible();
                } else {
                    alertClass.shouldBeHidden();
                }
            }
        }

        model.on('change:_ALERTCLASS', () => {
            appBus.trigger('alert:closeFilter');
            setScheduleFieldForAlertType({
                model,
                hours,
                mins,
                timezone,
                alerttype,
                form,
                alertClass,
            });
            return true;
        });

        model.on('change:ALERTGROUP', () => {
            appBus.trigger('alert:closeFilter');
            showOrHideTime({ hours, mins, timezone }, false);
            if (alertTimingIsAvailable) {
                alertClass.shouldBeHidden();
                alertClass.shouldBeHidden();
            }

            alerttype.$el.val('');
        });

        model.on('change:ALERTTYPE', () => {
            appBus.trigger('alert:alertTypeChanged');
            /*
             * Display the time fields for timed alerts (except ones that are for cuttoff
             * times).  Hide them for all others.
             */
            setScheduleFieldForAlertType({
                model,
                hours,
                mins,
                timezone,
                alerttype,
                form,
                alertClass,
            });
        });

        model.on('change:GROUPFLAG', (changedModel, val) => {
            form.formView.trigger('empty:recipient:region');
            model.set(
                {
                    RECIPIENT: '',
                    RECIPIENT_EMAIL: '',
                    RECIPIENT_GROUP: '',
                },
                { silent: true },
            );
            toggleRecipientLookups(val);
        });

        model.on('change:RECIPIENT_EMAIL change:RECIPIENT_GROUP', (m, value) => {
            model.set('RECIPIENT', value.join(','));
            if (value.length) {
                form.formView.trigger('show:recipient:region', model);
            }
            setAlertMethodForRecipient(model, form);
        });

        if (form.formView.state === 'insert') {
            if (util.isEmpty(model.get('RECIPIENT')) === false) {
                setAlertMethodForRecipient(model, form);
            }
        }

        setByTenantConfig(form);
        hideWarning(form);

        if (form.formView.state === 'modify') {
            if (!util.isEmpty(model.get('TIMEZONEID'))) {
                setTimeZoneId(model.get('TIMEZONEID'), form, model, timezone);
            }
            const recipients = model.get('RECIPIENT').split(',');
            if (model.get('GROUPFLAG') === '0') {
                model.set('RECIPIENT_EMAIL', recipients);
            } else {
                model.set('RECIPIENT_GROUP', recipients);
            }
        }
        toggleRecipientLookups(model.get('GROUPFLAG'));
    }

    if (form.formView.state === 'modify') {
        form.field('ALERTGROUP').shouldBeReadOnly(true);
        form.field('ALERTTYPE').shouldBeReadOnly(true);
        form.field('ALERTNAME').shouldBeReadOnly(true);
        if (initialState) {
            setAlertMethodForRecipient(model, form);
            setScheduleFieldForAlertType({
                model,
                hours,
                mins,
                timezone,
                alerttype,
                form,
                alertClass,
            });
        }
        if (alertTimingIsAvailable) {
            alertClass.shouldBeVisible();
            alertClass.shouldBeRequired(true);
        }
        if (model.get('ALERTCLASS') === 'TIMED' && !isCutoffAlert(alerttype)) {
            showOrHideTime({ hours, mins, timezone }, true);
        } else if (!alertTimingIsAvailable && model.get('ALERTCLASS') === 'ACTION'
                   && model.get('LOADCLASS') === 'TIMED') {
            showOrHideTime({ hours, mins, timezone }, true);
            model.set({
                ALERTCLASS: 'TIMED',
                LAST_EXECUTED_TIME: '',
            });
        } else {
            showOrHideTime({ hours, mins, timezone }, false);
        }
    } else if (form.formView.state === 'insert') {
        /*
         * Right now insert mode leverages _ALERTCLASS when loading
         * though user action will set ALERTCLASS
         */
        if (canShowAlertTimingToggle(alerttype.getValue())) {
            if (model.get('_ALERTCLASS') === 'TIMED' && model.get('ALERTCLASS') === 'TIMED') {
                showOrHideTime({ hours, mins, timezone }, true);
            } else {
                showOrHideTime({ hours, mins, timezone }, false);
            }
        } else if (model.get('_ALERTCLASS') === 'TIMED' && !isCutoffAlert(alerttype)) {
            showOrHideTime({ hours, mins, timezone }, true);
        } else {
            showOrHideTime({ hours, mins, timezone }, false);
        }
    }
};

export {
    isCutoffAlert,
    hideWarning,
    displayWarning,
    setByTenantConfig,
    setByUserAlertConfig,
    setAlertMethodForRecipient,
    setTimeZoneId,
    setScheduleFieldForAlertType,
    canShowAlertTimingToggle,
    showOrHideTime,
};
