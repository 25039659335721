import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import apiConstants from 'common/dynamicPages/api/constants';
import LookupCollection from 'common/dynamicPages/collections/lookup';
import transform from 'common/util/transform';
import errorConstants from 'common/dynamicPages/views/mdf/constants';

class preferredListTypeAhead {
    /**
     * initializes the preferred list input and stores relevant variables
     * @param {Object} typeAheadHelper - the typeAheadHelper which contains several functions
     * @param {Object} view - The this.formView for the form
     * @param {Object} fldData - Data pertinent to this specific preferredListTypeAhead input
     * @param {Jquery Selector} $listInput - The DOM element selector for this input
     * @param {Boolean} multiple - whether or not this typeAhead will support multi-select
     */
    constructor(typeAheadHelper, view, fldData, $listInput, multiple) {
        // save information needed by this helper
        this.dependentFields = fldData.dependsOn;
        this.typeAheadHelper = typeAheadHelper;
        this.formView = view;
        this.formModel = this.formView.model;
        this.$listInput = $listInput;
        this.attrName = fldData.name;
        this.isLocked = fldData.locked;
        this.multiple = multiple;
        this.$searchIcon = this.$listInput.siblings('.mdf-input-icon-container').find('.icon-search');
        this.$parentContainer = this.$listInput.closest('.field-container');
        this.$helpBlock = this.$parentContainer.find('.help-block');
        this.initialPageLoad = true;
        this.aWarningHasBeenRemoved = false;
        this.isMDF = view.isMDF ?? true;
        this.bankRepetitiveTemplate = this.formModel.get('ENTRYMETHOD') === '2';
        this.isTemplate = this.formModel.get('functionCode') === 'template';

        // if this is an MDF, we'll need this array to track fields with active requests
        if (this.isMDF) {
            this.formModel.activeFieldRequests = [];
        }

        let typeAheadFree = (util.isNull(fldData.typeAheadFree))
            ? false : fldData.typeAheadFree;

        // should only be possible when not MDF, otherwise fieldUIType = 'TYPEAHEAD_PREFERRED'
        if (!this.isMDF && fldData.fieldUIType === 'TYPEAHEADFREE') {
            typeAheadFree = true;
        }

        const typeAheadDataInfo = {
            fieldName: this.attrName,
            fieldKey: this.attrName,
            context: this.formView.context,
            typeInfo: {
                productCode: this.formModel.jsonData.typeInfo.productCode,
                functionCode: this.formModel.jsonData.typeInfo.functionCode,
                typeCode: this.formModel.jsonData.typeInfo.typeCode,
            },
            dependentTypeAheadFields: this.formView.dependentTypeAheadFields,
            typeAheadFields: fldData.typeAhead,
            typeAheadFree,
            view: this.formView,
            influencedAttributes: this.formView.influencedAttributes,
        };

        util.each(this.formView.options.typeAheadFieldsOverride, (typeAheadFieldOverride) => {
            if (this.attrName === typeAheadFieldOverride.name) {
                typeAheadDataInfo.overrideUrl = typeAheadFieldOverride.overrideUrl;
            }
        });

        this.formView.typeAheadCollections[this.attrName] = new LookupCollection(
            null,
            typeAheadDataInfo,
        );
    }

    /**
     * initializes the combobox. Depending on the status of the dependent fields, the combobox
     * may or may not be immediately populated
     */
    initializeCombobox() {
        this.$listInput.comboBox({
            multiple: this.multiple,
            closeOnSelect: !this.multiple,
            placeholder: this.formModel.fieldData[this.attrName].placeHolder,
            query: util.debounce((query) => {
                this.query = query;
                this.configureRequestData(query);

                // fetch data from the list of valid items for this combobox
                this.formView.typeAheadCollections[this.attrName].fetch({
                    // when fetching additional pages keep previous models in the collection
                    remove: query.page === 1,
                    success: (collection, resp) => {
                        // extract the data to be added to combo box
                        const result = util.filter(
                            collection.toJSON(),
                            collItem => util.find(
                                resp,
                                newItem => newItem.id === collItem.id,
                            ),
                        );

                        const data = {
                            results: result,
                            more: collection.hasMorePages(),
                        };

                        if (data.results.length === 0
                            && collection.typeAheadFree === true) {
                            data.results.push({
                                id: collection.queryTerm,
                                text: collection.queryTerm,
                            });
                        } else {
                            util.each(data.results, (rowParam) => {
                                const row = rowParam;
                                util.each(row.mapDataList, (objParam) => {
                                    const obj = objParam;
                                    obj.value = util.unescape(obj.value);
                                });
                                row.text = util.unescape(row.text);
                            });
                        }

                        query.callback(data);
                    },
                    error: this.onListRetrievalError.bind(this),
                });
            }, apiConstants.COMBO_DEBOUNCE),
        });
    }

    /**
     * Run any needed "page-load" logic to determine list input content or visibility.
     * This may be from a driver field re-render, or just the first time the form is loaded.
     */
    setupFieldState() {
        const currentValue = this.formModel.get(this.attrName);

        /*
         * if this workflow is NOT a template AND the field is locked OR this is a payment made
         * from a bank-repetitive template, then don't allow the input to ever change. Listeners
         * for dependent changes aren't even set in typeAhead.js
         */
        if (!this.isTemplate && (this.bankRepetitiveTemplate || this.isLocked)) {
            /*
             * if there is a value present, ensure the list input is visible and
             * styled appropriately, otherwise hide it and quit here.
             */
            if (currentValue) {
                // ensure the combobox reads the correct value
                this.$listInput.comboBox('data', { text: this.formModel.get(this.attrName) });
                this.$listInput.comboBox('readonly', true);
                this.$searchIcon.addClass('hidden');
                this.shouldShowInput(true);
            } else {
                this.shouldShowInput(false);
            }
            return;
        }

        if (this.hasBeenReRenderedFromDriverField()) {
            this.setupFromDriverFieldReRender(currentValue);
        } else if (this.allDependentsArePopulated()) {
            /*
             * If the dependents are satisfied, manually trigger a dependent change. This
             * serves two purposes. If there IS a current value for this list input (like in
             * modify mode), then it will be validated. If there is NOT a current value then
             * triggering a dependent change will check for preferred list items.
             */
            this.onDependentChange();
        } else if (this.currentValue) {
            /*
             * FIXME: This hardcoded scenario is specific to the INTL_CORRESPONDENT_ID
             * field. Divergent behavior like this should be denoted by meta-data rather
             * than accounted for in specific hard-coded instances like this.
             */
            if (this.attrName === 'INTL_CORRESPONDENT_ID') {
                /*
                 * if there is a value saved for INTL_CORRESPONDENT_ID on page load, and the
                 * dependents are not populated, clear the value and remove the field
                 */
                this.clearAnyCurrentlySelectedItem();
            } else {
                /*
                 * show warning to indicate that the current value is invalid due to
                 * the dependents not being populated
                 */
                this.addWarningTextToInput();
            }
        } else {
            // if there is no current value and unpopulated dependents, hide the input
            this.shouldShowInput(false);
        }

        // listener for the user manually clearing the list input
        this.$listInput.on('select2-clearing', (e) => {
            e.preventDefault();
            this.userHasManuallyCleared = true;
            this.clearAnyCurrentlySelectedItem();
        });
    }

    /**
     * @param {String} currentValue - The current value of the list input
     * Run this logic on form-load after a form is re-rendered due to a driver field interaction
     */
    setupFromDriverFieldReRender(currentValue) {
        // ensure the form is finished re-rendering before acting
        this.formView.once('ui-loaded', () => {
            util.defer(() => {
                if (this.allDependentsArePopulated()) {
                    if (currentValue) {
                        /*
                         * if there is a currently existing value for the list input after a
                         * driver field re-render, retrieve the potential valid list items.
                         * This is needed to determine how many list items are present to
                         * ensure the list input has the correct read-only status
                         */
                        this.$listInput.comboBox('data', { text: currentValue });
                        this.shouldShowInput(true);

                        this.configureRequestData();
                        this.formView.typeAheadCollections[this.attrName].fetch({
                            success: (collection, resp) => {
                                if (resp.length === 1) {
                                    this.$listInput.comboBox('readonly', resp.length === 1);
                                    this.$searchIcon.addClass('hidden');
                                }
                            },
                            error: this.onListRetrievalError.bind(this),
                        });
                    } else {
                        /*
                         * there is no current value, but that doesn't mean there are no
                         * valid items for the current dependent combination. Act like a
                         * dependent changed to retrieve them.
                         */
                        this.onDependentChange();
                    }
                } else if (currentValue) {
                    /*
                     * not all dependents are satisfied, there is no way the current value
                     * is valid. Clear it (will also hide the input as needed)
                     */
                    this.clearAnyCurrentlySelectedItem();
                } else {
                    // no current value present dependents not populated just hide the input
                    this.shouldShowInput(false);
                }
            });
        });
    }

    /**
     * remove this field from the driver field deferral array
     */
    removeFromDriverFieldDeferralArray() {
        this.formModel.activeFieldRequests =
        this.formModel.activeFieldRequests?.filter(item => item !== this.attrName);
    }

    /**
     * add this field to the driver field deferral array
     */
    addToDriverFieldDeferralArray() {
        if (!this.formModel.activeFieldRequests.includes(this.attrName)) {
            this.formModel.activeFieldRequests.push(this.attrName);
        }
    }

    /**
     * On MDFs this component may have been re-rendered due to a driver-field interaction
     * this is important to know to keep from doing things that should only happen on initial
     * page load
     * @return {Boolean} - whether or not this form has been rerendered by a driver field
     */
    hasBeenReRenderedFromDriverField() {
        return !!(this.formView.formReloadedDriverFieldName
            || this.formModel.formReloadedFromDriverField);
    }

    /**
     * Clears any currently selected combobox item and hides the list input
     *
     * @param {Boolean} keepInputVisible - indicates that there are other valid list items present
     * and the input shouldn't be hidden
     */
    clearAnyCurrentlySelectedItem(keepInputVisible) {
        const currentCollection = this.formView.typeAheadCollections[this.attrName];
        const lookupModel = currentCollection.at(0);

        let mapDataList = {};

        if (this.isMDF) {
            mapDataList = lookupModel.get('mapDataList');
        } else {
            /*
             * in non-MDF workflows, the collection may be empty and won't have information on which
             * model fields to clear. Instead, check the collection for a list of
             * "influenced attributes".
             */
            if (!currentCollection.influencedAttributes) {
                this.$listInput.comboBox('data', { text: '' });
                this.formModel.set(this.attrName, '');
                this.formView.trigger('lookupHelperText:clear', this.attrName);
                return;
            }
            mapDataList = currentCollection.influencedAttributes;
        }

        const setData = mapDataList.reduce((accumParam, mapData) => {
            const accum = accumParam;
            accum[mapData.toField.toUpperCase()] = '';
            return accum;
        }, {});

        // clear all of the model attributes associated with the collection item cleared
        this.formView.model.set(setData);

        mapDataList.forEach((mapData) => {
            $(`#${mapData.toField}`).trigger('change');
            this.formView.appBus.trigger('typeahead:change', mapData.toField);
        });

        // update the list inputs appearance
        this.$listInput.comboBox('data', { text: '' });
        this.formView.trigger('lookupHelperText:clear', this.attrName);
        this.$listInput.comboBox('readonly', false);
        this.$searchIcon.removeClass('hidden');

        /*
         * Hide the input unless there are other valid results list items possible, or the user has
         * manually cleared the selected list item
         */
        this.shouldShowInput(keepInputVisible || this.userHasManuallyCleared);
    }

    /**
     * This function removes any warnings currently on the list input. These would be present on
     * rare occasions like when we're modifying a form with a selected list item that is
     * no longer valid.
     */
    removeWarning() {
        this.$parentContainer.removeClass('has-warning');
        this.$helpBlock.text('');
        this.warningState = false;
    }

    /**
     * create listeners for dependent model attributes to signify when to update the combobox
     */
    setupListenersForDependents() {
        this.dependentFields.forEach((dependentField) => {
            this.formView.listenTo(this.formModel, `change:${dependentField}`, util.bind(this.onDependentChange, this));
        });

        /*
         * if this is an MDF, account for another field potentially setting this one. Instances
         * of this include selecting a pre-made beneficiary when creating a payment
         */
        if (this.isMDF) {
            this.formView.listenTo(this.formModel, `change:${this.attrName}`, util.bind(this.onAttrNameChange, this));
        }
    }

    onDependentChange() {
        // see below defer for explanation of this conditional's importance
        if (!this.formModel || this.formModel.activeFieldRequests?.includes(this.attrName)) {
            return;
        }

        if (this.isMDF) {
            this.addToDriverFieldDeferralArray();
        }

        this.updatedFromDependent = true;

        /*
         * this functionality is deferred in case there are other dependents being updated
         * at the same time. This way we don't retrieve valid list items with only parts
         * of the dependents populated, we wait until all of them are properly updated.
         * This does not mean all of them will be populated every time, it just keeps us
         * from running this function several times unneccesarily
         */
        util.defer(() => {
            /*
             * if the attrName is being set directly from another source, don't do anything
             * since it's already being handled in onAttrNameChange
             */
            if (this.scheduledAttrNameUpdate && this.isMDF) {
                this.removeFromDriverFieldDeferralArray();
                return;
            }

            if (this.warningState) {
                this.removeWarning();
                this.aWarningHasBeenRemoved = true;
            }

            this.$searchIcon.removeClass('hidden');

            // ensure that all of the variables needed to retrieve list data are in place
            this.configureRequestData();

            /*
             * programatically retrieve the data for the list of valid items to populate
             * the input now that the dependents have changed
             */
            this.formView.typeAheadCollections[this.attrName].fetch({
                success: this.onListRetrievalSuccess.bind(this),
                error: this.onListRetrievalError.bind(this),
            });
        });
    }

    /**
     * The list input has been set from another source (like someone selecting a pre-made contact).
     * Make a call to retrieve this list's potential items so we can validate that the set value
     * is among the valid options presented there. NOTE - this does not update the list input's
     * items. It merely validates the list input's current value
     */
    onAttrNameChange() {
        /*
         * don't do anything if this change is the result of the user manually clearing the list
         * input. This will be handled in 'clearAnyCurrentlySelectedItem'.
         */
        if (this.userHasManuallyCleared) {
            this.userHasManuallyCleared = false;
            return;
        }

        /*
         * don't do anything if the list value was just set by a preferred result amongst a list of
         * valid items retrieved due to a dependent update
         */
        if (this.updatedFromDependent) {
            return;
        }

        this.newAttrNameValue = this.formModel.get(this.attrName);
        this.scheduledAttrNameUpdate = true;

        /*
         * this functionality is deferred in case there are other model attributes being
         * set at the same time. Specifically dependents.
         */
        util.defer(() => {
            // ensure that all of the variables needed to retrieve list data are in place
            this.configureRequestData();

            // retrieve all valid list items for this list input
            this.formView.typeAheadCollections[this.attrName].fetch({
                success: () => {
                    // lookup the desired list item from the current collection.
                    const listItemSelected = this.formView
                        .typeAheadCollections[this.attrName]
                        .get(this.newAttrNameValue);

                    // ensure the combobox reads the correct new value
                    this.$listInput.comboBox('data', { text: this.newAttrNameValue });

                    this.shouldShowInput(true);

                    // if the newly desired list item is valid, set it appropriately
                    if (listItemSelected) {
                        // remove any currently present warnings
                        if (this.warningState) {
                            this.removeWarning();
                            this.aWarningHasBeenRemoved = true;
                        }

                        this.$searchIcon.removeClass('hidden');

                        // apply all the data on the model from this selected list item
                        this.typeAheadHelper.handleLookup(
                            this.formView,
                            listItemSelected,
                            true,
                            this.attrName,
                            this.newAttrNameValue,
                            true,
                        );
                    } else {
                        // the value selected was not part of the list of eligible items
                        this.scheduledAttrNameUpdate = false;
                        this.onDependentChange();
                        return;
                    }

                    // reset variables used in this update
                    this.newAttrNameValue = '';
                    this.scheduledAttrNameUpdate = false;

                    if (this.isMDF) {
                        // conclude this update and allow the MDF to rerender if needed
                        this.removeFromDriverFieldDeferralArray();

                        if (!this.formModel.activeFieldRequests.length) {
                            this.formModel.trigger('executeDeferredComboDriverField');
                        }
                    }
                },
                error: () => {
                    this.onListRetrievalError();
                    this.newAttrNameValue = '';
                    this.scheduledAttrNameUpdate = false;
                },
            });
        });
    }

    /**
     * ensures that all dependents have corresponding model values
     * @return {Boolean} - whether or not all dependents are present
     */
    allDependentsArePopulated() {
        return !this.dependentFields
            .some(dependentField => util.isEmpty(this.formModel.get(dependentField)));
    }

    /**
     * @param {Object} query - contains information regarding what page of results to return for
     * this retrieval of list items. Is not passed when programatically performing this lookup
     *
     * Initializes the combobox query parameters before a read is done on the typeahead field.
     * Uses data present in the field's dependents to influence the returned list
     */
    configureRequestData(query) {
        const fieldData = this.formModel.fieldData[this.attrName];
        let depends = [];

        if (fieldData.dependsOn) {
            depends = fieldData.dependsOn.map((dependentField) => {
                let dependsValue = this.formModel.get(dependentField);
                if (util.isEmpty(dependsValue) && this.formModel.isChild) {
                    dependsValue = this.formView.parentModel.get(dependentField);
                }
                return {
                    name: dependentField,
                    value: dependsValue,
                };
            }).filter(dependentEntry => !util.isEmpty(dependentEntry.value));
        }

        // Adding the staticDependsOn defined for the attrName to the depends
        if (this.formModel.staticDependsOn?.[this.attrName]) {
            util.each(this.formModel.staticDependsOn[this.attrName], (staticDepends) => {
                depends.push({
                    name: staticDepends.filterParam[0],
                    value: staticDepends.filterParam[1],
                });
            });
        }

        const currentCollection = this.formView.typeAheadCollections[this.attrName];
        currentCollection.depends = depends;
        currentCollection.startRow = 1;

        if (this.$listInput.attr('data-filter-type') === 'multi-select' || this.$listInput.attr('data-filter-type') === 'single-select') {
            currentCollection.requestParameters = {
                item: [{
                    name: 'PRODUCTCODE',
                    value: this.formModel.jsonData.typeInfo.productCode,
                }, {
                    name: 'FUNCTIONCODE',
                    value: this.formModel.jsonData.typeInfo.functionCode,
                }, {
                    name: 'TYPECODE',
                    value: this.formModel.jsonData.typeInfo.typeCode,
                }, {
                    name: 'INQUIRYID',
                    value: fieldData.popupId,
                }],
            };
        }

        /*
         * TODO: At the moment, lookup calls like this one return AT MOST 250 results.
         * this could *potentially* be an issue for a preferredList if a "preferred"
         * item isn't in the initial batch of 250 results. Current use-cases for this
         * field only return 3-5 results, but if this is ever intended to be scaled up
         * it should observe similar practices to the "getAccumulatedData" workflow
         * in src/app/loans/collections/dropdown.js" where multiple calls are made to
         * retrieve all list items before logic is performed
         */
        currentCollection.setPageSize(apiConstants.MAX_SERVER_ROWSPERPAGE);

        /*
         * in cases where the list info is being retrieved as a result of dependent fields
         * updating, rather than human interaction with the combobox, set a default query
         */
        currentCollection.queryTerm = query ? this.query.term : '';
        currentCollection.queryPage = query ? this.query.page : 1;
    }

    /**
     * Format data returned from list retrieval when a dependent has been updated. List retrieval
     * that has been kicked off from user interaction with the list is handled in the combobox's
     * 'query' handler
     */
    onListRetrievalSuccess(collection, respParam) {
        const resp = respParam;

        this.updateComboboxWithNewData(resp);

        /*
         * only relevant when the form loads with a value in the list input, used to signify that
         * the initial page load is complete and we should no longer validate existing list items
         * when retrieving new list data, just replace them
         */
        this.initialPageLoad = false;

        // conclude this update
        this.updatedFromDependent = false;

        if (this.isMDF) {
            this.removeFromDriverFieldDeferralArray();

            if (!this.formModel.activeFieldRequests.length) {
                this.formModel.trigger('executeDeferredComboDriverField');
            }
        }
    }

    /**
     * In the event that the retrieval call fails, treat the returned list as empty
     */
    onListRetrievalError() {
        if (this.query) {
            this.query.callback({
                results: [],
                more: false,
            });
        }
    }

    /**
     * @param {Boolean} show - default false, the desired visibility of the list input
     */
    shouldShowInput(show = false) {
        // attempt to get any collapsable containers (may have re-rendered since init)
        const $inputContainer = this.$listInput.closest('.collapse');
        if ($inputContainer.get(0)) {
            /*
             * FIXME: This hardcoded scenario is specific to the INTL_CORRESPONDENT_ID field.
             * divergent behavior like this should be denoted by meta-data rather than accounted
             * for in specific hard-coded instances like this.
             */
            if (this.attrName === 'INTL_CORRESPONDENT_ID') {
                const $inputPanel = $inputContainer.closest('.panel');
                // in the case of INTL_CORRESPONDENT_ID, hide the entire section if hidden
                $inputPanel.toggle(show);
            }
            if (show) {
                $inputContainer.collapse('show');
            } else {
                $inputContainer.collapse('hide');
            }
        }
    }

    /**
     * @param {Object} newListDataParam - The new combobox list data to update the input with
     * Update the combobox with the list of provided valid items
     */
    updateComboboxWithNewData(newListDataParam) {
        let newListData = newListDataParam;

        /*
         * if no data is returned, there's no need to check for any preferred items
         * we restrict this to nonMDF pages, because MDF pages will ALWAYS return at least
         * 1 result so the model knows what fields to wipe clean. non-mdf pages already know
         * the affected fields
         */
        if (!newListData.length && !this.isMDF) {
            this.clearAnyCurrentlySelectedItem();
            return;
        }

        const upperCaseFirstElement = newListData[0]?.mapDataList.map(item => ({
            toField: item.toField.toUpperCase(),
            value: item.value,
        }));

        /**
         * When there are no valid results, rather than an empty list returned, a single list
         * item with all blank attributes will be returned so the UI knows which model fields to
         * clear
         */
        if (transform.pairsToHash(upperCaseFirstElement, 'toField', 'value')[this.attrName] === '') {
            newListData = [];
        }

        let newItemToBeSelected;
        const existingListItemPresent = !!this.formModel.get(this.attrName);
        const onlyOneListItemReturned = newListData.length === 1;

        /*
         * if this population of the list is taking place on page load AND there is a currently
         * selected list item then check to see if that item is valid.
         */
        if (this.initialPageLoad
            && existingListItemPresent
            && !this.hasBeenReRenderedFromDriverField()) {
            this.validateExistingValueOnPageLoad(newListData);
            return;
        }

        if (newListData.length === 0) {
            this.clearAnyCurrentlySelectedItem();
            return;
        }

        /*
         * if there is only one list item present, mark it to be selected and style it appropriately
         */
        if (onlyOneListItemReturned) {
            [newItemToBeSelected] = newListData;
            this.$listInput.comboBox('readonly', onlyOneListItemReturned);
        } else {
            // check to see if any list items are preferred
            const preferredListItem = newListData.filter((listItem) => {
                const attributes = transform.pairsToHash(listItem.columns, 'fieldName', 'fieldValue');
                return ['Y', '1', 'Yes'].includes(attributes.PREFERRED ?? attributes.Preferred);
            })[0];

            /*
             * if there is a preferred item mark it to be selected
             */
            newItemToBeSelected = preferredListItem || newItemToBeSelected;
        }

        /*
         * if there is no preferred item, and more than one list item returned, clear any
         * currently selected item and do not autoselect any others. BUT, ensure the list input
         * isn't hidden.
         */
        if (!newItemToBeSelected) {
            this.clearAnyCurrentlySelectedItem(true);
            return;
        }

        // if we've reached this point, something will populate the list input, so show it.
        this.shouldShowInput(true);
        this.$searchIcon.toggleClass('hidden', onlyOneListItemReturned);

        // update the list input with the newly desired value
        if (!this.isMDF) {
            const formattedData = newItemToBeSelected.mapDataList.map(item => ({
                toField: item.toField.toUpperCase(),
                value: item.value,
            }));

            this.$listInput.trigger('change', {
                manualUpdateForPreferredList: true,
                lookupElement: transform.pairsToHash(formattedData, 'toField', 'value')[this.attrName],
            });
        } else {
            this.typeAheadHelper.handleLookup(
                this.formView,
                this.formView.typeAheadCollections[this.attrName].get(newItemToBeSelected),
                true,
                this.attrName,
                this.newAttrNameValue,
            );
        }

        this.$listInput.comboBox('data', { text: this.formModel.get(this.attrName) });
    }

    /*
     * If this is the first time a form is loaded and a value is present within this listInput,
     * the existing value will be tested for validity after retrieving the list of valid items.
     *
     * @param {Array} newListData - The list of freshly retrieved valid items for this input
     */
    validateExistingValueOnPageLoad(newListData) {
        // if the currently selected list item is included in the new list of valid items
        if (this.isExistingValueValid(newListData)) {
            // show the combobox and ensure it reads the correct value
            this.shouldShowInput(true);
            this.$listInput.comboBox('data', { text: this.formModel.get(this.attrName) });
            this.$searchIcon.removeClass('hidden');

            if (newListData.length === 1) {
                this.$listInput.comboBox('readonly', true);
                this.$searchIcon.addClass('hidden');
            }
            return;
        }

        /*
         * FIXME: This hardcoded scenario is specific to the INTL_CORRESPONDENT_ID field.
         * divergent behavior like this should be denoted by meta-data rather than accounted
         * for in specific hard-coded instances like this.
         */
        if (this.attrName === 'INTL_CORRESPONDENT_ID') {
            /*
             * in the case of INTL_CORRESPONDENT_ID, we don't want to show 'warning' text that the
             * selected item is invalid. Instead, just wipe it as though the existing value was
             * never there
             */
            this.clearAnyCurrentlySelectedItem();
            this.initialPageLoad = false;

            // once the input is wiped, treat the newly returned data how we normally would
            this.updateComboboxWithNewData(newListData);
            return;
        }

        this.addWarningTextToInput();
    }

    /*
     * The currently existing value has been determined to be invalid, and this is page-load.
     * Mark the value with a warning to ensure the user knows it's incorrect.
     */
    addWarningTextToInput() {
        // warn the user that the previously selected list item is now invalid
        this.warningState = true;

        const warningText = locale.get(errorConstants.TYPEAHEAD_PREFERRED_ERRORS[this.attrName]);

        if (!this.isMDF) {
            // ensure the combobox reads the correct value
            this.$listInput.comboBox('data', { text: this.formModel.get(this.attrName) });
            this.$parentContainer.addClass('has-warning');
            this.$helpBlock.text(warningText);
        } else {
            // ensure the combobox reads the correct value
            this.$listInput.comboBox('data', { text: this.formModel.get(this.attrName) });
            this.formView.trigger('lookupHelperText:clear', this.attrName);
            this.$parentContainer.addClass('has-warning');
            this.$helpBlock.text(warningText);

            this.formView.once('ui-loaded', () => {
                util.defer(() => {
                    if (this.warningState) {
                        this.$parentContainer.addClass('has-warning');
                        this.$helpBlock.text(warningText);
                        this.$listInput.comboBox('readonly', false);
                        this.$searchIcon.removeClass('hidden');
                    }
                });
            });
        }
    }

    /**
     * determines if the currently selected item is amongst the new list of valid items
     * @param {JSON} newListData - The new combobox list data to update the input with
     */
    isExistingValueValid(newListData) {
        // check to see if the current item is within the list of new items
        return newListData.some(listItem => listItem.id === this.formModel.get(this.attrName));
    }
}
export default preferredListTypeAhead;
