import Collection from '@glu/core/src/collection';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';

const Accounts = Collection.extend({
    filterVal: '',
    rowsPerPage: 10,

    setFilter(term, accountFilter) {
        this.filterVal = term;
        this.rowsPerPage = term.length > 2 ? 50 : 10;
        if (accountFilter) {
            this.accountFilter = accountFilter;
        }
    },

    sync(method, model, options) {
        const data = {
            rowsPerPage: 5000,
            startRow: options.startRow || 1,
            fieldName: 'ACCOUNT_NUMBER',
            typeCode: 'CIMINSTT',
            productCode: 'CM',
            functionCode: 'INST',

            requestParameters: {
                item: [{
                    name: 'INQUIRYID',
                    value: '20604',
                }],
            },
        };

        if (this.filterVal) {
            data.searchFields = [{
                fieldName: 'ACCOUNTNUM',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'ACCOUNTNUM_NAME',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }, {
                fieldName: 'CURRENCYCODE',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }];
            data.searchOrOperator = true;
        }

        http.post(
            services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION),
            data,
            (result) => {
                this.totalRows = result.totalRows;
                options.success(result.rows);
            }, () => {
                options.error();
            },
        );
    },

    parse(response) {
        return util.map(response, (rowItem) => {
            const obj = transform.pairsToHashUnescape(rowItem.columns, 'fieldName', 'fieldValue');
            obj.mapDataList = transform.pairsToHashUnescape(rowItem.mapDataList, 'toField', 'value');

            obj.id = obj.AccountNum;
            obj.text = (!util.isEmpty(obj.ACCOUNTNUM_NAME)) ? obj.ACCOUNTNUM_NAME : `${obj.mapDataList.ACCOUNT_TITLE} - ${obj.AccountNum} - ${obj.mapDataList.BANKCODE} - ${obj.mapDataList.BANKNAME} - ${obj.CURRENCYCODE}`;

            return obj;
        });
    },
});

export default Accounts;
