import { createUseStyles } from '@glu/theming';

const styles = (theme) => {
    const {
        palette: { accent },
    } = theme;
    return {
        root: {
            fontSize: 'inherit',
            border: `1px solid ${accent}`,
            borderRadius: '0px',
            padding: '5px 15px',
        },
    };
};

export default createUseStyles(styles);
