import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';

const ContactModel = Model.extend({
    initialize() {
        this.validators = {
            BENE_NAME: {
                exists: true,
                description: locale.get('bab.contact.name'),
            },

            CONTACTTYPE: {
                exists: true,
                description: locale.get('bab.contact.type'),
            },

            BENE_REFERENCE: { //   PCM-5784
                exists: true,
                description: locale.get('bab.contact.id'),
            },

            BENE_STATE: {
                matches: validatorPatterns.UPPERALPHA_PATTERN,
                maxLength: 2,
                description: locale.get('bab.state'),
            },

            BENE_CONTACT_NAME: {
                matches: validatorPatterns.NOBADCHARS_PATTERN,
                description: locale.get('bab.contact.name'),
            },

            BENE_EMAIL1: {
                matches: validatorPatterns.EMAIL_PATTERN,
                description: locale.get('BeneAddBook.Email'),
                overrideError: 'emailAddress',
            },

            BENE_EMAIL2: {
                matches: validatorPatterns.EMAIL_PATTERN,
                description: locale.get('BeneAddBook.Email2'),
                overrideError: 'emailAddress',
            },

            BENE_EMAIL3: {
                matches: validatorPatterns.EMAIL_PATTERN,
                description: locale.get('BeneAddBook.Email3'),
                overrideError: 'emailAddress',
            },

            BENE_EMAIL4: {
                matches: validatorPatterns.EMAIL_PATTERN,
                description: locale.get('BeneAddBook.Email4'),
                overrideError: 'emailAddress',
            },

            BENE_EMAIL5: {
                matches: validatorPatterns.EMAIL_PATTERN,
                description: locale.get('BeneAddBook.Email5'),
                overrideError: 'emailAddress',
            },
        };
    },

    sync() {
        return true;
    },
});

export default ContactModel;
