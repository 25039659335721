import Collection from './collection';

export default Collection.extend({

  initialize(models, options) {
    this.originalConstructorArguments = {
      modelsAsJson: JSON.stringify(models),
      options
    };

    this.on('change add remove reset sort destroy', () => {
      throw new Error('An immutable collection was changed. If you need to change this collection, get a copy by calling yourCollection.mutableCopy()');
    });
  },

  mutableCopy() {
    const models = JSON.parse(this.originalConstructorArguments.modelsAsJson);
    const { options } = this.originalConstructorArguments;
    return new Collection(models, options);
  }
});

