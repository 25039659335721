import workspaceHelper from 'common/workspaces/api/helper';
import ItemView from '@glu/core/src/itemView';
import { getComponentByIdAsView, getUtilById } from 'no-paymode!common/paymodeIntegration/paymodeIntegration';

let resourceLoadPromise;

function loadResources() {
    resourceLoadPromise = Promise.all([
        getComponentByIdAsView('widgets.vendorNetwork.loadedPaymodeXVendorNetworkContent'),
        getUtilById('widgets.vendorNetwork.styles'),
    ]).then(([paymodeXVendorNetworkContent, styles]) => ({
        paymodeXVendorNetworkContent,
        styles,
    }));
}

const VendorNetworkWidget = ItemView.extend({
    template: () => '',

    initialize() {
        if (!resourceLoadPromise) {
            loadResources();
        }
    },

    onShow() {
        // marionette-dashboard is "render"ing twice, make sure we haven't already done this
        if (this.showCalled) {
            return;
        }
        this.showCalled = true;
        resourceLoadPromise.then(({
            paymodeXVendorNetworkContent: VendorNetworkContentView,
            styles,
        }) => {
            // make sure widget works in standalone deeplinks where there is no widget container
            if (!this.widgetContainer) {
                this.setWidget(this);
            }

            this.vendorNetworkContent = new VendorNetworkContentView();

            // save some divs this way, cannot use setElement or we lose the top level classes
            // on the shared widget
            this.vendorNetworkContent.render();
            this.$el.append(this.vendorNetworkContent.$el);
            this.vendorNetworkContent.triggerMethod('show');

            // convert dgb large/medium to paymode large/small
            const sizeClass = this.widgetContainer.model.get('size') === 'large'
                ? styles.large : styles.small;

            // add the widget's top level class higher up the hierarchy as
            // it is needed this way to work with a widget that lives in paymode
            // and work here where we have different systems add the pmx- class to
            // enable overridding paymode styles for this widget
            this.widgetContainer.$el.addClass(`${styles['paymode-x-vendor-network']} pmx-paymode-x-vendor-network ${styles['single-currency']} ${sizeClass}`);
        });
    },

    onBeforeClose() {
        if (this.vendorNetworkContent) {
            this.vendorNetworkContent.close();
        }
    },

    setWidget(widgetContainer) {
        this.widgetContainer = widgetContainer;
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'PMX_VENDOR_NETWORK',
    view: VendorNetworkWidget,
    options: {},
});

export default VendorNetworkWidget;
