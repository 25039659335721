import EPositivePayDecisionList from 'app/checkManagement/views/listEPositivePayDecision';
import dialog from '@glu/dialog';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import template from './listDecision.hbs';

export default EPositivePayDecisionList.extend({
    template,

    /** @override */
    getInitializeOptions(options) {
        const initOptions = {
            serviceName: 'adminCM/fraud/listView/ePositivePayDecision',
            serviceFunc: null,
            businessType: null,
            returnRoute: 'REPORTING/viewPaymentFraudControl',
            enableSavedViews: true,
            selector: 'none',
            viewAll: options.viewAll,
            configContext: {
                productCode: 'RISK',
                functionCode: 'BATCH',
                typeCode: 'EBMPPDEC',
                subType: '*',
                actionMode: 'SELECT',
            },
        };

        let rowModel;

        if (options.viewAll) {
            store.set('cm_listView_comingToList', 'ePay-items');
        } else {
            rowModel = this.getRowModel(options);
            initOptions.additionalSearchFields = [{
                fieldName: 'PPACCOUNTBATCHID',
                operator: 'EQ',
                fieldValue: [rowModel.get('BATCHTNUM')],
                dataType: 'text',
            }];
        }

        return initOptions;
    },

    /** @override */

    back() {
        this.navigateTo(this.options.returnRoute);
    },

    getInquiryId() {
        return constants.INQUIRY_ID_20520;
    },

    /** @override */
    getRowModel(options) {
        if (options && options.model) {
            return options.model;
        }
        return store.get('adminCM_fraud_listView_paymentFraudControl-actionModel');
    },

    /** @override */
    gridRowSelect(options) {
        const { model } = options;
        model.set('ACCOUNT_NUMBER', this.ACCOUNT_NUMBER);
        model.set('ACCOUNT_TITLE', this.ACCOUNT_TITLE);

        store.set(`${this.contextKey}-actionModel`, model);
        this.navigateTo('REPORTING/viewEPositivePayDecision');
        return Promise.resolve();
    },

    print() {
        const options = {
            format: 'PDF',
            gridView: this.gridView,
        };

        this.buildExportModel(options);
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    export() {
        const options = {
            format: 'CSV',
            gridView: this.gridView,
        };

        this.buildExportModel(options);
        this.doExport(options);
    },

    buildExportModel(format) {
        EPositivePayDecisionList.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = this.getInquiryId();
    },
});
