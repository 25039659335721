/* eslint no-underscore-dangle:0, new-cap:0   */
// History of Component:
// 2014, file was added by @Jarvis, and at that time it was 100% copy of blueimp-file-upload@9.5.7/js/jquery-fileupload@5.40.1
// 2017, during Glu 2.x development, blueimp-file-upload added as npm dependency and majority of code has been removed from this file.
// 2018, Converted to ES6, but looks like module can be removed at all.
// There were many code changes around JQuery UI dependency, but since Glu 3.x it's simplified.

import $ from 'jquery';
import 'blueimp-file-upload';

// This below part remain here, because it was added by @Jarvis in 2014.
// But not sure if it's really needed/used by LOB codebases (PT-X or DGB or else)
// It creates a new method (alias) - uploader() to extend the default behavior of this component.
// But not sure how wide this is usage among LOBs
// It's important here to NOT use => function and rest params (..args)

$.fn.uploader = function () {
  return $(this).fileupload.apply(this, arguments);
};
