import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';

export default Model.extend({

    initialize(data) {
        if (data && data.text) {
            this.set({
                id: data.text,
                originalName: data.text,
            });
        }
        this.currentState = null;
    },

    syncRead(model, options) {
        const modelParam = model;
        const { context } = model.collection;
        const {
            productCode, functionCode, typeCode, filterID, inquiryId,
            overrideFunctionCode, overrideTypeCode,
        } = context;

        const customFilters = [];
        /**
         * this code (adding the depends for tabId and sectionId) is
         * specific to GIR
         * TODO refactor
         */
        if (overrideFunctionCode && overrideTypeCode) {
            customFilters.push({
                filterName: 'Depends',
                filterParam: ['tabId', overrideFunctionCode],
            }, {
                filterName: 'Depends',
                filterParam: ['sectionId', overrideTypeCode],
            });
        }
        return http.post(services.generateUrl('/listViewFilter/getListViewFilterDetails'), {
            viewID: modelParam.get('viewId'),
            productCode,
            functionCode,
            typeCode,
            inquiryId,
            filterId: filterID,
            customFilters,
        }, (result) => {
            modelParam.currentState = result;
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    syncUpdate(model, options) {
        return http.post(services.generateUrl('/listViewFilter/save'), this.getSaveData(true), () => {
            options.success({
                update: true,
            });
        }, (result) => {
            options.error(result);
        });
    },

    syncCreate(model, options) {
        return http.post(services.generateUrl('/listViewFilter/save'), this.getSaveData(false), (result) => {
            model.set({
                viewId: result.viewId,
                text: model.get('listName'),
                id: model.get('listName'),
                canDelete: true,
            });

            options.success({
                update: false,
            });
        }, (result) => {
            options.error(result);
        });
    },

    syncDelete(model, options) {
        return http.post(services.generateUrl('/listViewFilter/delete'), {
            filterIds: [model.get('viewId')],
        }, (result) => {
            options.success(result);
        }, (error) => {
            options.error(error);
        });
    },

    // eslint-disable-next-line
    sync(method, model, options) {
        if (method === 'read') {
            return this.syncRead(model, options);
        }
        if (method === 'update') {
            return this.syncUpdate(model, options);
        }
        if (method === 'create') {
            return this.syncCreate(model, options);
        }
        if (method === 'delete') {
            return this.syncDelete(model, options);
        }
    },

    getSaveData(update) {
        const data = {
            productCode: this.get('productCode'),
            functionCode: this.get('overrideFunctionCode') || this.get('functionCode'),
            typeCode: this.get('overrideTypeCode') || this.get('typeCode'),
            mode: '',
            update: `${update}`,
            listName: this.get('listName'),
            filters: this.get('filters'),
            columnInfo: this.get('columnInfo'),
            shared: this.get('shared'),
            rowsPerPage: this.get('rowsPerPage'),
            referenceViewId: this.get('referenceViewId'),
        };

        data.columnInfo.cellData = util.chain(this.columnJSON)
            .filter(column => column.field !== 'SELECTOR').map(column => ({
                name: column.label || column.field,
                field: column.field,
                hidden: !column.condition,
                width: column.width ? (`${Math.round(column.width)}px`) : '150px',
                displayName: column.userDefinedLabel || '',
            })).value();

        return data;
    },

    default(options) {
        let viewType = `${options.productCode}-${options.functionCode}-`;
        viewType += (options.typeCode) ? options.typeCode : '*';

        if (viewType === 'ADMPAY-INST-PAYMENTS') {
            viewType = 'PAY-INST-*';
        } else if (viewType === 'ADMPAY-TMPL-TEMPLATE') {
            viewType = 'PAY-TMPL-*';
        } else if (viewType === 'ADMPAY-INST-FXTRANS') {
            viewType = 'PAY-INST-FXTRANS';
        }
        // Passing viewID as 0 or '0' clears the default list view set
        const data = {
            viewID: (options.viewID !== undefined ? options.viewID : this.get('viewId')),
            viewName: '',
            viewType,
        };

        http.post(services.generateUrl('/listViewFilter/setDefault'), data, (result) => {
            options.success(result);
        }, (error) => {
            options.error(error);
        });
    },
});
