import util from '@glu/core/src/util';
import Glu from '@glu/core/src/glu';
import store from 'system/utilities/cache';
import dynamicLinkController from 'system/dynamiclinks/appRouterController';

export default {
    /**
     * @method setupDynamicLinks
     * @param {string} menuModel - model representing menu. This should be the
     * object that was returned from the Menu Rest Service.
     *
     * This method defines a route for each one of the defined Dynamic Links
     * during start up time
     * On the back end the number of Dynamic links that can be setup can be any
     * number and under whichever menu category
     */
    setupDynamicLinks(menuModel) {
        let localMenuModel = menuModel;
        const applicationRouter = Glu.Marionette.AppRouter;

        const AppRouterExt = applicationRouter.extend({
            controller: dynamicLinkController,
        });

        const dynamicLinkRouter = new AppRouterExt();
        let menuCategory;
        let componentStr;
        let routeName;

        // retrieve the model representing the menu if it was not passed as a parameter
        if (!localMenuModel) {
            localMenuModel = store.get('menuModel');
        }

        if (!localMenuModel || !localMenuModel.attributes
            || !localMenuModel.attributes.modules
            || localMenuModel.attributes.modules.length === 0) {
            return;
        }

        const [{ components }] = localMenuModel.attributes.modules;
        util.each(components, (component) => {
            if (component.actions) {
                util.each(component.actions, (menuItem) => {
                    /*
                     * DYNLINK is the product code for all Dynamic Links
                     * that are configured in the back end's database
                     */
                    if (menuItem.productCode === 'DYNLINK') {
                        menuCategory = menuItem.menuCatergory.toUpperCase();
                        componentStr = menuItem.requestMappings.split('/');
                        routeName = `${menuCategory}/${componentStr[0]}(/)(:context)`;
                        dynamicLinkRouter.appRoute(routeName, componentStr[0]);
                    }
                });
            } else if (component.dynamicLink) {
                /*
                 * there can be top level dynamic links which does not have any actions, the
                 * routes for those needs to be added.
                 */
                componentStr = component.key.split('/');
                routeName = `${componentStr[0]}(/)(:context)`;
                dynamicLinkRouter.appRoute(routeName, componentStr[0]);
            }
        });
    },
};
