import { ItemView } from 'backbone.marionette';
import util from '@glu/core/src/util';
import template from './checkGridGenerator.hbs';

export default (options = {}) => {
    // TODO: We should probably throw an error if nothing is passed

    const viewOpts = {

        template: ((options.template || template)),
        ui: {
            input: 'input',
        },

        events: {
            'change @ui.input': 'checkOption',
        },

        checkOption(event) {
            this.model.set(this.key, event.currentTarget.checked ? '1' : '0');
        },

        initialize() {
            let helpers = util.omit(options, 'attributes', 'key', 'template', 'useBind');

            if (options.useBind) {
                // NOTE: Use _.reduce - _.mapObject isn't available until Underscore 1.8.0
                helpers = util.reduce(helpers, (accum, val, key) => {
                    const acc = accum;
                    acc[key] = util.isFunction(val) ? val.bind(this) : val;
                    return acc;
                }, {});
            }

            this.key = options.key;
            this.helpers = helpers;
        },

        templateHelpers() {
            const val = this.model.get(options.key);

            return util.extend({
                cid: this.cid,
                isDisabled: val === '',
                isChecked: val === '1',
            }, this.helpers);
        },

        /*
         * HACK: FIXME: TODO: This is  a test
         * shaves a couple milliseconds off EVERY CELL RENDER
         * enableViewBinding: util.noop,
         */
        monitorVisibility: util.noop,
        renderAndRestoreFocus: util.noop,
    };

    if (options.attributes) {
        viewOpts.attributes = options.attributes;
    }

    return ItemView.extend(viewOpts);
};
