import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import ConversationDetailView from 'app/payments/views/realTimePayments/rtpComponents/rtpConversationDetailView';
import AddToConversationView from 'app/payments/views/realTimePayments/rtpComponents/rtpAddToConversationView';
import rtpConversationViewTmpl from './rtpConversationView.hbs';

export default Layout.extend({
    template: rtpConversationViewTmpl,

    ui: {
        $viewPaymentBtn: '[data-hook="getViewPaymentBtn"]',
        $printBtn: '[data-action="print"]',
        $exportBtn: '[data-action="export"]',
    },

    events: {
        'click @ui.$closeBtn': 'closeDrawer',
        'click @ui.$doneBtn': 'closeDrawer',
        'click @ui.$viewPaymentBtn': 'viewPayment',
    },

    initialize(options) {
        this.options = options;
        this.conversationStarted = options.conversationStarted;
        this.isAdmin = window.Bottomline.appRoot === 'ui-admin' || window.Bottomline.appRoot === 'ui-admin-portal';
    },

    onRender() {
        this.viewOptions = {
            model: this.model,
            conversationStarted: this.conversationStarted,
        };

        if (!this.isAdmin) {
            this.addToConversationRegion.show(new AddToConversationView(this.viewOptions));
        }
    },

    createConversationDetailRegion() {
        this.conversationDetailRegion.show(new ConversationDetailView(this.viewOptions));
    },

    viewPayment() {
        const options = {
            action: 'select',
            model: this.model,
        };
        this.appBus.trigger('rtp:viewPayment', options);
    },

    closeDrawer() {
        this.appBus.trigger('dgb:drawer:close');
    },

    templateHelpers() {
        return {
            noPrintExport: this.options.noPrintExport,
            isRequestForInfo: this.isRequestForInfo,
            submittedTab: store.get('payment_rtp-currentTab') === 'submitted',
        };
    },

});
