import Dialog from '@glu/dialog';
import $ from 'jquery';
import util from '@glu/core/src/util';
import LookupGridView from 'common/dynamicPages/views/lookupGrid';

export default {
    setupMultiLookups(view, $alt) {
        // Support an alternate container element
        const $el = $alt || view.$el;

        $el.find('[data-hook="lookup-multi"]').each(function () {
            const fieldName = $(this).attr('data-field');
            $(this).bind('click', (e) => {
                const depends = [];
                const fieldData = view.model.fieldData[fieldName];
                if (fieldData.dependsOn !== null) {
                    for (let i = 0; i < fieldData.dependsOn.length; i += 1) {
                        depends.push({
                            name: fieldData.dependsOn[i],
                            value: view.model.get(fieldData.dependsOn[i]),
                        });
                    }
                }
                const lookupOptions = {
                    hideButtons: true,
                    fieldName,

                    context: {
                        serviceName: view.model.context.serviceName,
                    },
                };
                if (view.model.context.subType) {
                    lookupOptions.subType = view.model.context.subType;
                }
                if (depends) {
                    lookupOptions.depends = depends;
                }
                const lookupViewOptions = {
                    gridOptions: lookupOptions,
                    gridApi: view.initializeOptions.gridApi,
                    isSingle: false,
                };

                let lookupView;
                if (view.initializeOptions.isModal) {
                    lookupViewOptions.isModal = false;
                    lookupView = new LookupGridView(lookupViewOptions);

                    view.listenTo(lookupView, 'lookup:cancel', () => {
                        view.trigger(
                            'lookup:layover',
                            {
                                view: null,
                            },
                        );
                    });
                    // need to modify this for multi select.
                    view.listenTo(lookupView, 'lookup:selected', (model) => {
                        if (model.mapDataList) {
                            for (let j = 0; j < model.mapDataList.length; j += 1) {
                                view.model.set(
                                    model.mapDataList[j].toField,
                                    model.mapDataList[j].value,
                                );
                            }
                        }
                        view.trigger(
                            'lookup:layover',
                            {
                                view: null,
                            },
                        );
                    });
                    view.trigger(
                        'lookup:layover',
                        {
                            view: lookupView,
                            event: e,
                        },
                    );
                } else {
                    // close any other dialog
                    lookupViewOptions.isModal = true;
                    lookupView = new LookupGridView(lookupViewOptions);
                    Dialog.close();
                    view.listenTo(lookupView, 'lookup:cancel', () => {
                        Dialog.close();
                    });
                    // need to modify this for multi select.
                    view.listenTo(lookupView, 'lookup:selected', (models) => {
                        const currentTotalRows = view
                            .gridComponentViews[fieldData.relatedProperty]
                            .wrapper.rows.totalCount;
                        let index = 1;
                        util.each(models, (model) => {
                            model.set('id', currentTotalRows + index);
                            index += 1;
                            view.gridComponentViews[fieldData.relatedProperty]
                                .wrapper.rows.add(model);
                            // view.userRoleGridView.wrapper.rows.add(model);
                        });
                        Dialog.close();
                    });
                    Dialog.open(lookupView);
                }
            });
        });
    },
};
