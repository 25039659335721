import React from 'react';
import PropTypes from 'prop-types';
import {
    ThemeProvider,
    ashTheme,
} from '@glu/theming';
import Accordion from '@glu/accordion-react';
import useLocale from 'hooks/useLocale';
import AuditRow from './AuditRow';
import AuditHeader from './AuditHeader';
import useStyles from './AuditSectionSimple.styles';

const DEFAULT_FIELD_NAME_MAP = {
    entered: {
        user: 'ENTERED_BY',
        timestamp: 'ENTERED_TIMESTAMP',
        userName: 'ENTERED_NAME',
    },
    modified: {
        user: 'MODIFIED_BY',
        timestamp: 'MODIFIED_TIMESTAMP',
        userName: 'MODIFIED_NAME',
    },
    approved: {
        user: 'APPROVED_BY',
        timestamp: 'APPROVED_TIMESTAMP',
        userName: 'APPROVED_NAME',
    },
    deleted: {
        user: 'DELETED_BY',
        timestamp: 'DELETED_TIMESTAMP',
        userName: 'DELETED_NAME',
    },
};

const AuditSectionSimple = ({
    panelTitle,
    panelId,
    data,
    dataFieldNameMap,
    columnHeaders,
    col,
}) => {
    const { getLocaleString } = useLocale();
    const defaultColumns = [
        {
            fieldName: 'timestamp',
            label: getLocaleString('audit.Timestamp'),
        },
        {
            fieldName: 'user',
            label: getLocaleString('audit.User.ID'),
        },
        {
            fieldName: 'userName',
            label: getLocaleString('audit.User.Name'),
        },
    ];
    const auditColumns = columnHeaders || defaultColumns;
    const classes = useStyles();
    const fieldNameMap = {
        ...DEFAULT_FIELD_NAME_MAP,
        ...dataFieldNameMap,
    };
    const rowData = Object.keys(fieldNameMap).reduce((accum, key) => {
        const action = fieldNameMap[key];
        return {
            ...accum,
            [key]: auditColumns.map((column) => {
                const { fieldName } = column;
                const mappedName = action[fieldName];
                return data[mappedName];
            }),
        };
    }, {});

    return (
        // TODO When switching over to the ASH theme, remove this provider
        <ThemeProvider baseTheme={ashTheme}>
            <div className={classes.wrapper}>
                <Accordion>
                    <Accordion.Panel panelId={panelId}>
                        <Accordion.Header>{panelTitle || getLocaleString('common.Audit')}</Accordion.Header>
                        <Accordion.Content>
                            <AuditHeader
                                col={col}
                                columnHeaders={auditColumns}
                            />
                            <AuditRow
                                col={col}
                                actionLabel={getLocaleString('audit.Entered')}
                                data={rowData.entered}
                            />
                            <AuditRow
                                col={col}
                                actionLabel={getLocaleString('audit.Last.Modified')}
                                data={rowData.modified}
                            />
                            <AuditRow
                                col={col}
                                actionLabel={getLocaleString('audit.Approved')}
                                data={rowData.approved}
                            />
                            <AuditRow
                                col={col}
                                actionLabel={getLocaleString('audit.Deleted')}
                                data={rowData.deleted}
                            />
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
            </div>
        </ThemeProvider>
    );
};
AuditSectionSimple.propTypes = {
    panelTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    panelId: PropTypes.string,
    data: PropTypes.shape({}).isRequired,
    dataFieldNameMap: PropTypes.shape({
        entered: PropTypes.shape({
            user: PropTypes.string,
            timestamp: PropTypes.string,
            userName: PropTypes.string,
        }),
        modified: PropTypes.shape({
            user: PropTypes.string,
            timestamp: PropTypes.string,
            userName: PropTypes.string,
        }),
        approved: PropTypes.shape({
            user: PropTypes.string,
            timestamp: PropTypes.string,
            userName: PropTypes.string,
        }),
        deleted: PropTypes.shape({
            user: PropTypes.string,
            timestamp: PropTypes.string,
            userName: PropTypes.string,
        }),
    }),
    columnHeaders: PropTypes.arrayOf(PropTypes.shape({
        fieldName: PropTypes.string,
        label: PropTypes.string,
    })),
    col: PropTypes.number,
};
AuditSectionSimple.defaultProps = {
    // Undefined because it's not possible to use an app resource in the defaultProps
    panelTitle: undefined,
    panelId: 'auditSectionSimple',
    dataFieldNameMap: {},
    columnHeaders: undefined,
    col: 2,
};
export default AuditSectionSimple;
