import date from 'moment';
import number from 'numeral';
import util from '@glu/core/src/util';

function buildValue(template, params) {
    // If there are no dynamic value placeholders, return early
    if (template.indexOf('{') === -1) {
        return template;
    }

    let result = template;

    // Replace dynamic value placeholders with real values
    params.forEach((value, index) => {
        result = result.replace(`{${index}}`, value);
    });

    return result;
}

function getValue(key, pairs) {
    const keyParts = key.split('.');
    const flatResult = pairs[key];
    let deepResult;

    // Basic support for a flat structure
    if (flatResult) {
        return flatResult;
    }

    // Support deep keys (e.g. `common.helloWorld`)
    deepResult = pairs;

    keyParts.forEach(part => {
        if (!deepResult) {
            return;
        }

        deepResult = deepResult[part];
    });

    return deepResult;
}

const locale = {
    pairs: { },

    set(key, str) {
        if (!!key && typeof key === 'object') {
            this.pairs = { ...this.pairs, ...key };
            return;
        }

        this.pairs[key] = str;
    },

    get(key, ...params) {
        const result = getValue(Array.isArray(key) ? key.join('') : key, this.pairs);
        const template = result || `??${key}??`;

        return buildValue(template, params);
    },

    getDefault(key, defaultValue, ...params) {
        if (!defaultValue) {
            throw new Error('No default value provided. Did you mean to use locale.get?');
        }

        const result = getValue(key, this.pairs);
        const template = result || defaultValue;

        return buildValue(template, params);
    },

    clear(resetToDefaultStrings) {
        this.pairs = resetToDefaultStrings ? { ...defaultStrings } : {};
    },

    /**
     * Setup locale to support a range of different languages keyed by isoCode.
     * Each key must point to a function that accepts a callback that takes the actual locale
     * bundle for the given isoCode as its parameter. This design allows the freedom to load the
     * bundle for each iso code as you see fit, lazily or not.  It would be possible to make an
     * http call to a server as well to get the data.  When the call returns with your bundle you
     * just call the callback with it. The callback can be called synchronously or asynchronously
     *
     * For example (if using webpack and dynamic imports to lazy load your bundles):
     * {
     *    'en_US': callback => {
     *      import ( /* webpackChunkName: 'paymode_en_US' * / 'common/locale/paymode_en_US')
     *        .then((bundle => callback(bundle.default)));
     *    },
     *     'fr_CA': callback => {
     *      import ( /* webpackChunkName: 'paymode_fr_CA' * / 'common/locale/paymode_fr_CA')
     *        .then((bundle => callback(bundle.default)));
     *     }
     * }
     *
     * @param bundleHash Required isoCode keyed hash of bundle loading via callback functions.
     */
    initBundles: function initBundles(bundleHash) {
        this.bundles = bundleHash;
    },

    /**
     * Fetches the bundle matching the passed in isoCode as setup in initBundles()
     *
     * @param isoCode Required, Key to use the the bundle, generally the language and locale codes.
     * @param callback Optional function to call with the bundle once it is loaded after it is used
     *     by locale itself.
     */
    fetch(isoCode, callback) {
        const getBundle = this.bundles[isoCode];

        if (getBundle) {
            getBundle((bundle) => {
                this.loadFetchedBundle(bundle, callback);
            });
        }
    },

    loadFetchedBundle(bundle, callback) {
        // Append localised strings
        if (bundle.strings) {
            this.set(bundle.strings);
        }

        // Load localisation settings for momentjs
        if (bundle.dates && bundle.iso) {
            date.locale(bundle.iso);
        }

        // Load localisation settings for numeraljs
        if (bundle.numbers && bundle.iso) {
            number.language(bundle.iso, bundle.numbers);
            number.language(bundle.iso);
        }

        if (typeof callback === 'function') {
            callback(bundle);
        }

        this.hasBundleBeenLoaded = true;
        this.runBundleLoadedCallbacks();
    },

    onBundleLoaded: function onBundleLoaded(callback) {
        if (!this.bundleCallbacks) {
            this.bundleCallbacks = [];
        }

        this.bundleCallbacks.push(callback);

        if (this.hasBundleBeenLoaded) {
            callback();
        }
    },

    runBundleLoadedCallbacks: function runBundleLoadedCallbacks() {
        if (this.bundleCallbacks) {
            this.bundleCallbacks.forEach(callback => {
                callback();
            });
        }
    }
};

const origGet = locale.get;

locale.get = function get(key, ...rest) {
    let localKey = key;
    // passing in an array allows using keys composed by concatenating parts together
    // The array parameter makes it easier to use code scanning tools to trace
    // which locale keys are used where in the code by providing an easy to find signature
    if (util.isArray(localKey)) {
        localKey = localKey.join('');
    }

    return origGet.apply(locale, [localKey, ...rest]);
};

const origLoadFetchedBundle = locale.loadFetchedBundle;

locale.loadFetchedBundle = function loadFetchedBundle(bundle, callback) {
    origLoadFetchedBundle.call(this, bundle, callback);

    this.hasBundleBeenLoaded = true;
    this.runBundleLoadedCallbacks();
};

locale.onBundleLoaded = function onBundleLoaded(callback) {
    if (!this.bundleCallbacks) {
        this.bundleCallbacks = [];
    }

    this.bundleCallbacks.push(callback);

    if (this.hasBundleBeenLoaded) {
        callback();
    }
};

locale.runBundleLoadedCallbacks = function runBundleLoadedCallbacks() {
    if (this.bundleCallbacks) {
        this.bundleCallbacks.forEach(callback => {
            callback();
        });
    }
};

export default locale;
