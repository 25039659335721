export default function (
    form,
    initialState,
) {
    const formState = form.formView.state;
    const { model } = form.formView;
    const countryField = form.field('COUNTRY_CODE');
    const currencyField = form.field('CURRENCY_CODE');
    const bicField = form.field('BANKSORTCODE');
    const bicLookupField = form.field('BANKSORTCODE_LOOKUP');
    const preferredField = form.field('PREFERRED');

    if (initialState) {
        if (formState === 'modify') {
            countryField.shouldBeReadOnly(true);
            currencyField.shouldBeReadOnly(true);
            bicField.shouldBeReadOnly(true);
            bicLookupField.shouldBeHidden().shouldBeOptional();

            if (model.get('PREFERRED') === '1') {
                preferredField.shouldBeReadOnly(true);
            }
        }
    }
}
