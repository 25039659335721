import ItemView from '@glu/core/src/itemView';
import constants from 'common/dynamicPages/api/constants';
import $ from 'jquery';
import store from 'system/utilities/cache';
import { maskValue } from 'common/util/maskingUtil';
import template from './lockboxCell.hbs';

export default ItemView.extend({
    className: 'align-center btn-cell',
    disableInitialViewBinding: true,

    initialize() {
        this.isStatic = true;

        const hasCheckImageArr = this.options.model.hasCheckImage;
        const fieldName = this.options.column.get('fieldName');
        const currentColumnHasCheckImage = (hasCheckImageArr.indexOf(fieldName) > -1);

        if (hasCheckImageArr.length > 0) {
            if (currentColumnHasCheckImage) {
                this.isStatic = false;
            }
        }

        if (fieldName === 'REMITTER') {
            const value = this.options.value || '';
            this.isStatic = value.trim() !== '';
            this.useRemitterModal = true;

            /**
             * During the Grouped View of the Lockbox Transaction Details screen, the
             * KEYEDBYBANK
             * flag is not passed
             * backed to the UI. Since List View is rendered first by default, store
             * the flag in
             * the cache.
             * When KEYEDBYBANK is undefined on the model for Group View, get the flag
             * from the cache
             */
            this.isStatic = this.options.model.get('KEYEDBYBANK') === 'Y';
            if (this.options.model.get('KEYEDBYBANK')) {
                store.set(`keyedByBank-lbx${this.options.model.get('LOCKBOXFILTER')}`, this.options.model.get('KEYEDBYBANK'));
            } else {
                const keyedByBank = store.get(`keyedByBank-lbx${this.options.model.get('LOCKBOXFILTER')}`);
                this.isStatic = keyedByBank === 'Y';
            }
            /*
             * Keyed by bank or user, if field has check image and value is not empty,
             * link to check image
             */
            if (currentColumnHasCheckImage && value.trim() !== '') {
                this.isStatic = false;
                this.useRemitterModal = false;
            }
            // When KEYEDBYBANK and string value is not empty
            if (this.isStatic) {
                this.$el.removeClass('align-center btn-cell');
                this.$el.addClass('text-left');
            }
        }

        let serviceName;
        // always allow modification on remitter management screen
        if (this.model.collection && this.model.collection.context) {
            ({ serviceName } = this.model.collection.context);
        }

        if (serviceName && serviceName.toUpperCase().indexOf('REMITTER') > -1) {
            this.isStatic = false;
        }
    },

    onRender() {
        this.updateClassName();
        this.$el.attr('data-title', this.options.value);
        this.$('[data-toggle="tooltip"]').popover();
    },

    updateClassName() {
        const typeCode = this.options.column.get('typeCode');

        switch (typeCode) {
        case constants.COLUMN_TYPE_AMOUNT:
        case constants.COLUMN_TYPE_LOCKBOX_AMOUNT:
        case constants.COLUMN_TYPE_AMOUNT_SCS:
            this.$el.attr('class', 'text-right btn-cell');
            break;
        default:
        }
    },

    showView() {
        this.model.typeCode = this.options.column.get('typeCode');

        if (this.useRemitterModal) {
            this.appBus.trigger(`grid:row:action:action_remitter_${this.model.parentViewId}`, this.model);
        } else {
            this.model.fieldName = this.options.column.get('fieldName');
            this.appBus.trigger(`grid:row:action:action_imageDetail_${this.model.parentViewId}`, this.model);
        }
    },

    getTemplate() {
        return template;
    },

    templateHelpers() {
        const self = this;
        return {
            isStatic: self.isStatic,
            hasMultipleValues: this.options.value.split('|').length > 1,

            /**
             * @method cellValue
             * Parses the value of the cell based on the keybybank config and multiple
             * values separated by pipes
             * @returns {*|jQuery}
             */
            cellValue() {
                let value = self.options.value || '';
                const chkRout = self.model.get('CHKROUT');
                const chkAcct = self.model.get('CHKACCT');
                const numOfValues = value.split('|').length;
                const title = self.options.column.get('title');

                /**
                 * if the cellValue is a collection of values separated by a pipe "|",
                 * count the values and add a title.
                 */
                if (numOfValues > 1) {
                    value = `${numOfValues} ${title}`;
                }
                if (chkRout && chkRout.trim() !== '' && chkAcct && chkAcct.trim() !== '') {
                    const field = self.options.column.get('fieldName');
                    const keyedByBank = self.options.model.get('KEYEDBYBANK');
                    if (keyedByBank === 'N' && field === 'REMITTER' && value.trim() === '') {
                        value = '[Remitter Name]';
                    }
                    if (field === 'CHKACCT') {
                        value = maskValue(value);
                    }
                }
                return $('<div/>').html(value).text();
            },

            /**
             * @method cellValues
             * cellValues() is called to add the tooltip content when hasMultipleValues is true.
             * Split each value by
             * the pipe and join the array
             * @returns {string}
             */
            cellValues() {
                const { value } = self.options;
                return value.split('|').join(', ');
            },
        };
    },
});
