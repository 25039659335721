import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import cache from 'system/utilities/cache';
import constants from 'common/dynamicPages/api/constants';
import ContextModel from 'common/dynamicPages/models/context';
import MetaModel from 'common/dynamicPages/models/meta';
import productHelper from 'common/dynamicPages/api/productHelper';
import services from 'services';
import RFPTemplateMetaModel from 'app/payments/models/realTimePayments/rfp/meta';

export default {
    // will probably rename this class to a factory

    model: {
        /**
         *
         * @param {object} options
         * @param {object} options.context - The options object must have a child
         * of context
         * @param {string} options.context.serviceName
         * @param {boolean} [isChild]
         * @return {string}
         */
        getCacheName(options, isChild) {
            let { context } = options;
            if (context.containerContext) {
                // HACK to pass the parent context for Grids so the Model Cache works.
                context = context.containerContext;
            }

            const isFromCopy = context.createdFrom === '1';
            const isChildModel = options.isChild || isChild;
            const actionContext = context.actionData || context.actionContext;

            const pieces = [
                'model',
                context.serviceName,
                context.productCode
                    || (context.actionData && context.actionData.productCode) || undefined,
                context.functionCode
                    || (context.actionData && context.actionData.functionCode) || undefined,
                context.subType || (actionContext && actionContext.subType),
                context.entryMethod,
                isFromCopy ? 'copy' : undefined,
                context.copySource,
                isChildModel ? 'child' : undefined,
            ];

            const PFT_SERVICES = [
                'adminPayment/listView/payments',
                'adminTemplate/listView/templates',
                '/adminCM/cm/stopCancels',
            ];

            const isPFTService = PFT_SERVICES.indexOf(context.serviceName) !== -1;
            const isFraudService = options.context.serviceName.indexOf('adminCM/fraud/listView') !== -1;

            if (context.actionData && context.actionData.productCode === 'USACH') {
                pieces.push(context.actionMode === 'reverse' ? 'reverse' : '*');
            }

            // RTP multi entry screen has a different field layout
            if (context.isRtpMultiAdd) {
                pieces.push('MULTI');
            }

            if (!isChildModel && context.actionData) {
                pieces.push(context.actionData.entryClass);
            }
            if (isPFTService || isFraudService) {
                pieces.push(
                    actionContext.productCode,
                    actionContext.functionCode,
                    actionContext.typeCode,
                );
            }

            return util.compact(pieces).join('-');
        },

        getModelInfo(options) {
            /*
             * deep clone the object from the cache so that mutating it will not pollute
             * the cached copy
             */
            const obj = cache.get(this.getCacheName(options));
            if (obj) {
                return JSON.parse(JSON.stringify(obj));
            }
            return obj;
        },

        generateModelFromModel(modelParam) {
            const model = modelParam;
            const self = this;

            if (model && model.context.actionData) {
                if (model.context.actionData.subType) {
                    model.context.subType = model.context.actionData.subType;
                }
                if (Object.prototype.hasOwnProperty.call(model.context.actionData, 'entryMethod')) {
                    model.context.entryMethod = model.context.actionData.entryMethod;
                }
            }

            return new Promise((resolve) => {
                try {
                    self.generate({
                        context: model.context,
                        deeplinked: model.deeplinked,
                    }).then((newModel) => {
                        newModel.set(model.attributes);
                        resolve(newModel);
                    });
                } catch (e) {
                    window.console.log(`An Error Occurred ${e}`);
                }
            });
        },

        generateFromKeyList(options) {
            const model = new MetaModel(
                {},
                {
                    context: options.context,
                },
            );
            model.key = {};
            util.each(options.keyList, (key) => {
                model.key[key] = 'true';
            });
            return model;
        },

        generate(options, overrideCache) {
            /*
             * Note:  context.actionData.entrymethod vs. context.actionData.entryMethod
             * are for ui vs. admin  (note the capitalization on method)
             */

            const self = this;

            return new Promise((resolve, reject) => {
                const success = function () {
                    const model = self.generateFromPreload(options);
                    let contextModel;
                    let contextKey;

                    if (options.deeplinked) {
                        contextModel = new ContextModel({
                            contextDef: options.context,
                        });
                        contextKey = contextModel.getContextKey();
                        cache.set(`${contextKey}-actionModel`, model);
                    }
                    resolve(model);
                };

                if (util.isEmpty(cache.get(self.getCacheName(options))) || overrideCache) {
                    let passedEntryMethod = 0;
                    // entrymethod is available in the actionData.
                    if (options.context.actionData) {
                        if (!util.isNullOrUndefined(options.context.actionData.entryMethod)) {
                            passedEntryMethod = options.context.actionData.entryMethod;
                        }
                    } else if (!util.isNullOrUndefined(options.context.entrymethod)) {
                        passedEntryMethod = options.context.entrymethod;
                    }

                    let actionMode = 'INSERT';
                    if (options && options.state) {
                        if (actionMode !== options.state.toUpperCase()) {
                            actionMode = options.state.toUpperCase();
                        }
                    }

                    if (options.context.actionMode && options.context.actionMode === 'reverse') {
                        actionMode = options.context.actionMode.toUpperCase();
                    }

                    // RTP multi entry screen has a different field layout
                    if (options.context.actionData
                        && options.context.actionData.typeCode === 'CRTRAN'
                        && !options.context.isRtpMultiAdd
                        && options.context.actionData.actionMode) {
                        actionMode = options.context.actionData.actionMode.toUpperCase();
                    }

                    const data = {
                        entryMethod: passedEntryMethod,
                        action: actionMode,
                    };

                    const modelInfoService = services.generateUrl(options.context.serviceName)
                        + constants.URL_GETMODELINFO_ACTION;

                    if (options.context.subType) {
                        data.subtype = options.context.subType;
                    }

                    if (!data.subtype && options.context.actionContext
                        && options.context.actionContext.subType) {
                        data.subtype = options.context.actionContext.subType;
                    }

                    if (options.context.actionData && options.context.actionData.entryClass) {
                        data.entryClass = options.context.actionData.entryClass;
                    }

                    const functionCode = productHelper.getFunctionCode(options.context);
                    if (functionCode) {
                        data.functionCode = functionCode;
                    }

                    if (options.state && options.state.toUpperCase() === 'IMPORT') {
                        data.productCode = options.context.actionContext.productCode;
                        data.functionCode = options.state.toUpperCase();
                        data.typeCode = options.context.typeCode;
                    }
                    const actionContext = options.context.actionData
                        || options.context.actionContext;
                    if (modelInfoService.indexOf('adminPayment') > 0
                        || modelInfoService.indexOf('adminCM') > 0
                        || modelInfoService.indexOf('adminTemplate') > 0) {
                        data.productCode = actionContext.productCode;
                        data.typeCode = actionContext.typeCode;
                        data.action = 'SELECT';
                    }

                    if (modelInfoService.indexOf('userCentric/smb/getModelInfo') > 0) {
                        data.subtype = 'SMB';
                    }
                    http.post(modelInfoService, data, (response) => {
                        const parentCacheName = self.getCacheName(options);

                        if (options.context.subType) {
                            response.subtype = options.context.subType;
                        }

                        if (!response.subtype && options.context.actionContext
                            && options.context.actionContext.subType) {
                            response.subtype = options.context.actionContext.subType;
                        }

                        if (!util.isEmpty(response.childFieldInfoList)) {
                            const childData = {
                                fieldInfoList: response.childFieldInfoList,
                                fieldContainerList: response.childfieldContainerList,
                                typeInfo: response.typeInfo,
                                isChild: true,
                                isBatch: false,
                                childSequenceKey: response.childSequenceColumn,
                                childGridKey: response.childGridName,
                                driverFields: response.childDriverFields,
                                grandChildName: response.grandChildName,
                                parentCacheName,
                                grandChildFields: response.grandChildFields,
                            };

                            const cacheStr = self.getCacheName(options, true);

                            /**
                             * **************************************************************
                             * In some situation, different parent share the same
                             * cacheName for their children
                             * For instance, NFI payments with different entry classes use the
                             * same cacheName for their children
                             * If you open a NFI CCD payment, and then an NFI IAT
                             * payment, the child-cache will now store the IAT children
                             * Now if you open a NFI CCD payment again, it won't do a fresh load
                             * but instead will use the child-cache to load its children
                             * Which will cause issue, in particular to the combo-boxes if the
                             * two payment have different combo-boxes
                             * Before replacing the children cache, we need clean up the cache
                             * for its TRUE parent to force a fresh load next time it's read
                             ****************************************************************
                             */
                            const obj = cache.get(cacheStr);
                            if (obj && obj.parentCacheName
                                && obj.parentCacheName !== parentCacheName) {
                                cache.unset(obj.parentCacheName);
                            }
                            cache.set(cacheStr, childData);
                            response.childFieldInfoList = null;
                            response.childfieldContainerList = null;
                            response.isBatch = true;
                        } else {
                            response.isBatch = false;
                        }
                        response.isChild = false;

                        cache.set(parentCacheName, response);
                        success();
                    }, (result) => {
                        reject({
                            errorCode: result.status,
                            errorMessage: result.statusText,
                        });
                    });
                } else {
                    success();
                }
            });
        },

        generateFromPreload(options) {
            const modelData = this.getModelInfo(options);
            const model = this.generateMetaModel(options, modelData);
            model.setupFromData();
            return model;
        },

        reload(options) {
            return new Promise((resolve, reject) => {
                const originalModel = options.model;
                let reloadService = services.generateUrl(originalModel.context.serviceName)
                    + constants.URL_RELOAD_ACTION;
                let origFunctionCode = '';
                let entryMethodOverride = 0;
                if (originalModel.context && originalModel.context.functionCode
                    && originalModel.context.functionCode !== '') {
                    origFunctionCode = originalModel.context.functionCode;
                } else if (originalModel.context && originalModel.context.actionData
                    && originalModel.context.actionData
                    && originalModel.context.actionData.functionCode
                    && originalModel.context.actionData.functionCode !== '') {
                    origFunctionCode = originalModel.context.actionData.functionCode;
                } else if (originalModel.get('FUNCTION') !== '') {
                    origFunctionCode = originalModel.get('FUNCTION');
                }

                // TODO revisit the concept of entryMethodOverride NH-114234
                if (originalModel.context.loadFromPayments) {
                    entryMethodOverride = 0;
                } else if (origFunctionCode === 'BATCH'
                    && ((originalModel.context.createdFrom
                        && originalModel.context.createdFrom === '1')
                        || (originalModel.get('ENTRYMETHOD') === 1))
                    && (reloadService.indexOf('/batch/ChildSupportPayments') > 0
                        || reloadService.indexOf('/batch/TaxPayments') > 0)) {
                    entryMethodOverride = 1;
                } else if (originalModel.get('ENTRYMETHOD') === '1' && reloadService.indexOf('payment/Wire-International') > 0) {
                    /*
                     * HACK: NH-152550
                     * No idea why we're overriding entry methods like this. I suspect it should
                     * be done on a rare as-needed basis rather than all the time like this,
                     * but I don't have the scope or understanding (or time) to affect such a
                     * change at this moment. For now, I'm adding an exception for this specific
                     * workflow and Ill add a comment on NH-152550 that provides further context
                     */
                    entryMethodOverride = 1;
                } else if (originalModel.get('ALLOWBENEUNLOCK') === '1') {
                    /*
                     * NH-114184 When driver fields change, need to pass
                     * along original entryMethod
                     */
                    entryMethodOverride = originalModel.get('ENTRYMETHOD');
                }
                const data = {
                    entryMethod: entryMethodOverride,
                    action: 'INSERT',
                    subtype: originalModel.context.subType || '*',
                    functionCode: origFunctionCode,
                    data: originalModel.getNameValuePairAttributes(),
                };

                if (reloadService.indexOf('adminPayment/listView/payments') > 0 || reloadService.indexOf('adminTemplate/listView/templates') > 0) {
                    if (options.model.context.actionData.productCode === 'USACH' && (options.model.context.actionData.functionCode === 'BATCH'
                            || options.model.context.actionData.functionCode === 'BHTMPL')) {
                        reloadService = services.generateUrl(originalModel.context.serviceName)
                            + constants.URL_RELOAD_BATCH_ACTION;
                    }
                    data.productCode = options.model.context.actionData.productCode;
                    data.typeCode = options.model.context.actionData.typeCode;
                }

                http.post(reloadService, data, (result) => {
                    const jsonData = {
                        allowedCreditCurrencies: originalModel.jsonData.allowedCreditCurrencies,
                        beneBankCurrMap: originalModel.jsonData.beneBankCurrMap,
                        fieldInfoList: result.fieldInfoList,
                        fieldContainerList: result.fieldContainerList,
                        isBatch: originalModel.jsonData.isBatch,
                        isChild: originalModel.jsonData.isChild,
                        childSequenceKey: originalModel.jsonData.childSequenceKey,
                        childGridKey: originalModel.jsonData.childGridKey,
                        driverFields: originalModel.jsonData.driverFields,
                        typeInfo: originalModel.jsonData.typeInfo,
                        subtype: originalModel.jsonData.subtype,
                        childNumber: originalModel.childNumber,
                        batchSeqNumber: originalModel.batchSeqNumber,
                    };

                    const model = new MetaModel(
                        {},
                        {
                            context: originalModel.context,
                            jsonData,
                        },
                    );
                    model.setupFromData();
                    resolve(model);
                }, () => {
                    // TODO - implement error handling
                    reject(originalModel);
                });
            });
        },

        /**
         * TODO - find a generic way to inject the correct model if
         * a particular payment type has a custom model
         */
        generateMetaModel(options, modelData) {
            let model;
            const isRfpTemplate = productHelper.isRFPTemplate(options.context);
            if (isRfpTemplate) {
                model = new RFPTemplateMetaModel(
                    {},
                    {
                        context: options.context,
                        jsonData: modelData,
                    },
                );
            } else {
                model = new MetaModel(
                    {},
                    {
                        context: options.context,
                        jsonData: modelData,
                    },
                );
            }
            return model;
        },
    },
};
