var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                               <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":46},"end":{"line":20,"column":52}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":20,"column":67},"end":{"line":20,"column":76}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modify-transfer\">\n\n                <div class=\"alert-message glu-alert glu-alert-warning\" role=\"alert\">\n                    <div class=\"glu-alert-content\">\n                                <p class=\"glu-alert-message\">\n                                        <span class=\"icon-warning\"></span>\n                                        <span class=\"glu-alert-message-content\" data-validation=\"generic\"></span></p>\n                    </div>\n                </div>\n\n       \n       <form autocomplete=\"off\" data-submit=\"save\">\n           <fieldset>\n               <div>       \n                   <div class=\"required field-container-lg field-container\">\n                       <label for=\"ACCOUNTNUMBER\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"payment.AccountNumber",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":16,"column":50},"end":{"line":16,"column":89}}}))
    + "</label>\n                       <select class=\"form-control\" data-bind=\"model\" id=\"ACCOUNTNUMBER\" name=\"ACCOUNTNUMBER\">\n                           <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accountList") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":27},"end":{"line":21,"column":36}}})) != null ? stack1 : "")
    + "                       </select>\n                       <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTNUMBER\"></span>\n                   </div>\n                   \n                                   <div class=\"required field-container-lg field-container\">\n                                            <label for=\"AMOUNT\" class=\"amount-label\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"getAmountLabel") || (depth0 != null ? lookupProperty(depth0,"getAmountLabel") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"getAmountLabel","hash":{},"data":data,"loc":{"start":{"line":27,"column":85},"end":{"line":27,"column":105}}}) : helper)))
    + "</label>\n                                            <input class=\"form-control  amount-field\" type=\"text\" maxlength=\"19\" data-bind=\"model\" name=\"AMOUNT\" id=\"AMOUNT\">\n                                            <input class=\"currency-field\" readonly=\"readonly\" data-bind=\"model\" tabindex=\"-1\" name=\"CURRENCY\" id=\"CURRENCY\">\n                                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"AMOUNT\"></span>\n                                   </div>\n \n                                        <div class=\"field-container-lg field-container\">\n                                            <label for=\"COMMENTS\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"payment.Comments",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":34,"column":66},"end":{"line":34,"column":98}}}))
    + "</label>\n                                            <input class=\"form-control \" type=\"text\" maxlength=\"140\" data-bind=\"model\" id=\"COMMENTS\" name=\"COMMENTS\">\n                                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"COMMENTS\"></span>\n                                        </div>\n\n                                        <div class=\" field-container-lg field-container\">\n                                            <span class=\"textline-field\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"RTGS.INST.TRANSFER.MANY2ONE.SPECIALINSTRUCTIONSINFOTEXT.label",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":40,"column":73},"end":{"line":40,"column":152}}}))
    + "</span>\n                                        </div>\n               </div>\n            </fieldset>\n       </form>\n</div>\n";
},"useData":true});