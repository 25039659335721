var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":25}}}) : helper)))
    + "_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":32}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldLabel") || (depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldLabel","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":48}}}) : helper)))
    + "</label>\n<div class=\"form-inline\">\n        <input class=\"form-control\" id=\"NEW_NAME_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":49},"end":{"line":3,"column":55}}}) : helper)))
    + "\" name=\"NEW_NAME\" data-bind=\"model\">\n        <button class=\"btn btn-secondary\" data-hook=\"save\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.newAddress.save.and.use",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":59},"end":{"line":4,"column":106}}}))
    + "</button>\n        <button class=\"btn btn-tertiary\" data-hook=\"cancel\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"bosInvoice.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":60},"end":{"line":5,"column":94}}}))
    + "</button>\n</div>\n<span class=\"help-block\" data-bind=\"model\" data-validate=\"NEW_NAME\"></span>\n";
},"useData":true});