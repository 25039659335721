import { withStyles, withTheme } from '@glu/theming';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, SECONDARY } from '@glu/buttons-react';
import styles from './BulkActions.styles';

const BulkActions = ({
  bulkActions,
  classes,
  records,
  selections,
  enabled
}) => (
  <div className={classes.bulkActionsWrap} data-qa="bulkButtons">
    {bulkActions.map(action => {
      if (action.condition !== undefined) {
        const condition = typeof action.condition === 'function' ? action.condition(records) : action.condition;
        if (!condition) {
          return null;
        }
      }
      return (
        <Button
          key={action.label}
          variant={SECONDARY}
          disabled={!selections.length || !enabled}
          type="button"
          text={action.label}
          className={classes.bulkActionsBtn}
          onClick={() => action.handler(selections)}
        />
      );
    })}
  </div>
);

BulkActions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  bulkActions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  })).isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enabled: PropTypes.bool
};

BulkActions.defaultProps = {
  enabled: true
};

export const BulkActionsComponent = BulkActions;
export default withTheme(withStyles(styles)(BulkActions));
