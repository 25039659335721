export default {
    msgButtons: {
        display: 'flex',
        justifyContent: 'flex-end',

        '& button': {
            backgroundColor: 'transparent',
            border: 'none',
            paddingLeft: '10px',
            marginTop: '-20px',

            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },

    spinner: {
        '&:before': {
            width: '20px',
            height: '20px',
            top: '-15px',
            position: 'relative',
            color: 'white',
        },
    },
};
