import Model from '@glu/core/src/model';

const PopulationModel = Model.extend({
    defaults: {
        selectionMethod: 'INCLUSIVE',
        populationType: 'SEGMENTS',
    },
});

export default PopulationModel;
