import $ from 'jquery';
import http from '@glu/core/src/http';
import services from 'services';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import PaymentUtil from 'common/util/paymentUtil';
import fxPaymentUtil from 'common/util/fxPaymentUtil';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const formState = form.formView.state;
    const beneBankCity = form.field('BENE_BANK_CITY');
    const beneAddMore = form.field('ADDMORE');
    const orderPartyIsInstitution = form.field('ORDERPARTYISINSTITUTION');
    const beneBankType = form.field('BENE_ACCOUNT_TYPE');
    const beneBankId = form.field('BENE_BANK_ID');
    const ID = form.field('ORIGINATOR_ID');
    const hidecutoff = serverConfigParams.get('hideCutoff');
    const functionCode = (form.formView.context.actionData)
        ? form.formView.context.actionData.functionCode
        : form.formView.model.jsonData.typeInfo.functionCode;
    const idType = form.field('ORIGINATOR_ID_TYPE');
    const orderBankFieldArr = ['BENE_BANK_NAME', 'BENE_BANK_ADDRESS_1', 'BENE_BANK_ADDRESS_2', 'BENE_BANK_CITY', 'BENE_BANK_COUNTRY'];
    const intermBankFieldArr = ['CORRESPONDENT_NAME', 'CORRESPONDENT_ADDRESS_1', 'CORRESPONDENT_ADDRESS_2', 'CORRESPONDENT_CITY', 'CORRESPONDENT_COUNTRY'];
    const addressAddMoreList = ['BENE_BANK_'];
    const { model } = form.formView;
    const creditAmount = form.field('CREDIT_AMOUNT');
    const fromTemplateReadOnlyFields = ['DEBIT_ACCOUNT_NUMBER', 'DEBIT_ACCOUNT_BANK_NAME', 'ORDERPARTYISINSTITUTION', 'BENE_BANK_ID', 'BENE_BANK_TYPE', 'BENE_ACCOUNT_TYPE'];
    const allowMaxTmplAmount = model.get('ALLOWMAXAMTPAYMENTFROMTMPL') || (model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL) ? model.fieldData.ALLOWMAXAMTPAYMENTFROMTMPL.value : 0;

    const makeFieldsReadOnly = function (array, isReadOnly) {
        util.each(array, (value) => {
            form.field(value).shouldBeReadOnly(isReadOnly);
        });
    };

    const setFieldsArray = function (array, valueSetter) {
        util.each(array, (value) => {
            form.field(value).setValue(valueSetter);
        });
    };

    const showHideAddMore = function (array) {
        util.each(array, (fieldPrefix) => {
            form.field(fieldPrefix.concat('ADDRESS_2'))
                .shouldBeVisibleWhen(form.field(fieldPrefix.concat('ADDRESS_2')).isNotEmpty() || form.field(fieldPrefix.concat('ADDRESS_2_LINK')).isNotVisible());
            form.field(fieldPrefix.concat('ADDRESS_2_LINK'))
                .shouldBeVisibleWhen(form.field(fieldPrefix.concat('ADDRESS_2')).isNotVisible() && form.field(fieldPrefix.concat('ID')).isEmpty())
                .$el.on('click', (e) => {
                    e.stopImmediatePropagation();
                    form.field(fieldPrefix.concat('ADDRESS_2')).shouldBeVisible();
                    form.field(fieldPrefix.concat('ADDRESS_2_LINK')).shouldBeHidden();
                    form.field(fieldPrefix.concat('CITY_LINK')).shouldBeVisible();
                });
            form.field(fieldPrefix.concat('CITY'))
                .shouldBeVisibleWhen(form.field(fieldPrefix.concat('CITY')).isNotEmpty() || (form.field(fieldPrefix.concat('ADDRESS_2')).isVisible() && form.field(fieldPrefix.concat('CITY_LINK')).isNotVisible()));
            form.field(fieldPrefix.concat('CITY_LINK'))
                .shouldBeVisibleWhen(form.field(fieldPrefix.concat('ADDRESS_2')).isVisible() && form.field(fieldPrefix.concat('CITY')).isNotVisible())
                .$el.on('click', (e) => {
                    e.stopImmediatePropagation();
                    form.field(fieldPrefix.concat('CITY')).shouldBeVisible();
                    form.field(fieldPrefix.concat('CITY_LINK')).shouldBeHidden();
                });
        });
    };

    const doFieldValidation = function (fieldname) {
        const validationService = services.generateUrl(constants.URL_DO_FIELD_VALIDATION);
        const requestType = fieldname === 'CUTOFF_INFO' ? 'cutoffdateProcessor' : 'fieldValidation';

        const inputData = [{
            name: 'REQUESTTYPE',
            value: requestType,
        }, {
            name: 'FIELDNAME',
            value: fieldname,
        }, {
            name: 'VALUE_DATE',
            value: form.field('VALUE_DATE').getValue(),
        }, {
            name: 'ACTION',
            value: formState.toUpperCase(),
        }, {
            name: 'TRAN_DATE',
            value: model.get('TRAN_DATE'),
        }, {
            name: 'EFFECTIVEDATE',
            value: model.get('TRAN_DATE'),
        }, {
            name: 'PRODUCT',
            value: model.get('PRODUCT'),
        }, {
            name: 'FUNCTION',
            value: model.get('FUNCTION'),
        }, {
            name: 'TYPE',
            value: model.get('TYPE'),
        }, {
            name: 'SUBTYPE',
            value: model.get('SUBTYPE'),
        }, {
            name: 'DEBIT_BANK_CODE',
            value: model.get('DEBIT_BANK_CODE'),
        }, {
            name: 'DEBIT_COUNTRY',
            value: model.get('DEBIT_COUNTRY'),
        }, {
            name: 'DEBIT_CURRENCY',
            value: model.get('DEBIT_CURRENCY'),
        }, {
            name: 'BENE_BANK_CODE',
            value: model.get('BENE_BANK_CODE'),
        }, {
            name: 'BENE_BANK_COUNTRY',
            value: model.get('BENE_BANK_COUNTRY'),
        }, {
            name: 'Credit_Amount',
            value: model.get('CREDIT_AMOUNT'),
        }, {
            name: 'Debit_Amount',
            value: model.get('DEBIT_AMOUNT'),
        }, {
            name: 'CREDIT_CURRENCY',
            value: model.get('CREDIT_CURRENCY'),
        }];

        return http.post(
            validationService,
            {
                item: inputData,
            },
        ).then((result) => {
            const retrievedValues = util.reduce(result.item, (acc, nvp) => {
                const accumulator = acc;
                if (nvp.name === 'TRAN_DATE' || nvp.name === 'VALUE_DATE') {
                    accumulator[nvp.name] = moment(nvp.value, 'YYYY-MM-DD').format(userInfo.getDateFormat());
                } else if (nvp.name === 'CUTOFF_INFO') {
                    accumulator[nvp.name] = nvp.value;
                }
                return accumulator;
            }, {});
            model.set(retrievedValues);
            // update screen cutoff
            PaymentUtil.showCutoff(form.formView.model.get('CUTOFF_INFO') || form.formView.dates.cutoffDateTimeTz, $('.ui-datepicker-trigger'), form.formView.model.get('TYPE'), formState, form.formView.model.get('STATUS'));

            return result;
        });
    };

    if (formState === 'insert') {
        // Hide the audit section
        const aFieldInAuditSection = form.field('VIEWHISTORY');
        aFieldInAuditSection.$el.closest('.section').hide();
    }
    if (formState === 'modify' || formState === 'restore') {
        const { typeCode } = form.formView.context.actionData;
        const { productCode } = form.formView.context.actionData;
        if (functionCode !== 'INST' && typeCode !== 'NRECEIVE' && productCode !== 'RTGS') {
            form.field('TEMPLATE_CODE').shouldBeReadOnly(true);
        }
    }

    const amtBlock = form.formView.$('[data-validate="CREDIT_AMOUNT"]');
    PaymentUtil.shouldShowTemplateMaxAmt(
        allowMaxTmplAmount,
        form.formView,
        model,
        amtBlock,
        functionCode,
    );

    // display cutoff info if configured and available
    PaymentUtil.showCutoff(form.formView.model.get('CUTOFF_INFO') || form.formView.dates.cutoffDateTimeTz, $('.ui-datepicker-trigger'), form.formView.model.get('TYPE'), formState, form.formView.model.get('STATUS'));

    // temporary fix that till getValue works for checkbox
    const isOrderPartyChecked = orderPartyIsInstitution.$el.is(':checked');

    idType.shouldBeVisibleWhen(!isOrderPartyChecked);
    ID.shouldBeVisibleWhen(!isOrderPartyChecked);

    beneBankType.shouldBeVisibleWhen(isOrderPartyChecked);
    beneBankId.shouldBeVisibleWhen(isOrderPartyChecked);
    $('span[data-field="BENE_BANK_IDLOOKUP"]').toggle(isOrderPartyChecked);
    if (isOrderPartyChecked) {
        makeFieldsReadOnly(orderBankFieldArr, true);

        beneBankType.shouldBeRequiredWhen(true).$el.parent().addClass('required');
        beneBankId.shouldBeRequiredWhen(true).$el.parent().addClass('required');
    }
    makeFieldsReadOnly(intermBankFieldArr, true);
    showHideAddMore(addressAddMoreList);

    if (form.formView.model.get('ENTRYMETHOD') && form.formView.model.get('ENTRYMETHOD') === '1') {
        makeFieldsReadOnly(fromTemplateReadOnlyFields, true);
    }

    form.field('VALUE_DATE').$el.on('change', (e) => {
        if (formState === 'modify' || formState === 'insert') {
            doFieldValidation('VALUE_DATE');
            e.stopImmediatePropagation();
        }
    });

    form.field('BENE_ACCOUNT_TYPE').$el.on('change', () => {
        if (form.field('BENE_ACCOUNT_TYPE').getValue() === '') {
            makeFieldsReadOnly(orderBankFieldArr, true);
        } else {
            makeFieldsReadOnly(orderBankFieldArr, true);
            setFieldsArray(orderBankFieldArr, '');
            form.field('BENE_BANK_ID').setValue('');
            form.field('BENE_BANK_ID').setComboBoxValue('');
            form.field('BENE_BANK_COUNTRY').setValue('');
            form.field('BENE_BANK_COUNTRY').setComboBoxValue('');
        }
        doFieldValidation('CUTOFF_INFO');
    });

    form.field('ORDERPARTYISINSTITUTION').$el.on('change', () => {
        // temporary fix that till getValue works for checkbox
        const isOPChecked = orderPartyIsInstitution.$el.is(':checked');

        if (isOPChecked) {
            makeFieldsReadOnly(orderBankFieldArr, true);
            setFieldsArray(orderBankFieldArr, '');
            form.field('BENE_BANK_ID').setValue('');
            form.field('BENE_BANK_ID').setComboBoxValue('');

            beneBankType.shouldBeRequiredWhen(true).$el.parent().addClass('required');
            beneBankId.shouldBeRequiredWhen(true).$el.parent().addClass('required');
        } else {
            makeFieldsReadOnly(orderBankFieldArr, false);
            setFieldsArray(orderBankFieldArr, '');

            beneBankId.shouldBeRequiredWhen(false).$el.parent().removeClass('required');
            beneBankType.shouldBeRequiredWhen(false).$el.parent().removeClass('required');
        }

        form.field('BENE_BANK_COUNTRY').setValue('');
        form.field('BENE_BANK_COUNTRY').setComboBoxValue('');
        form.field('BENE_BANK_COUNTRY').shouldBeRequiredWhen(isOPChecked);
    });

    form.field('CORRESPONDENT_ID').$el.on('change', () => {
        if (form.field('CORRESPONDENT_ID').isNotEmpty()) {
            form.field('CORRESPONDENT_ADDRESS_1').shouldBeReadOnlyWhen(form.field('CORRESPONDENT_ADDRESS_1').isNotEmpty());
        }
    });

    model.on('change:DEBIT_ACCOUNT_NUMBER', (changedModel, accountNumber) => {
        fxPaymentUtil.evaluateUSDOnly(changedModel, [], accountNumber);
        if (fxPaymentUtil.USD_ONLY) {
            model.set('CREDIT_CURRENCY', 'USD');
        }
    });

    form.field('CORRESPONDENT_TYPE').$el.on('change', () => {
        const corrType = form.field('CORRESPONDENT_TYPE').getValue();

        if (corrType === '') {
            makeFieldsReadOnly(intermBankFieldArr, true);
        } else {
            makeFieldsReadOnly(intermBankFieldArr, true);
            setFieldsArray(intermBankFieldArr, '');
            form.field('CORRESPONDENT_ID').setValue('');
            form.field('CORRESPONDENT_ID').setComboBoxValue('');
            form.field('CORRESPONDENT_COUNTRY').setValue('');
            form.field('CORRESPONDENT_COUNTRY').setComboBoxValue('');
        }
    });

    // Shows how to implement behavior for a hyperlink, e.g. add more
    beneAddMore
        .shouldBeVisibleWhen(beneBankCity.isNotVisible())
        .$el.on('click', (e) => {
            e.stopImmediatePropagation();
            if (beneBankCity.isNotVisible()) {
                beneBankCity.shouldBeVisible();
                beneAddMore.shouldBeHidden();
            }
        });

    if (initialState) {
        rtgsCommon.getAccountBalances(form.formView, 'DEBIT_ACCOUNT_NUMBER', 'RTGS', 'NRECEIVE', 'Debit_Bank_Code', 'CREDIT_CURRENCY');

        // hide the amount section onload
        if (form.field('DEBIT_ACCOUNT_NUMBER').isEmpty() || form.field('DEBIT_ACCOUNT_NUMBER').isNotEmpty()) {
            makeFieldsReadOnly(orderBankFieldArr, isOrderPartyChecked);
            makeFieldsReadOnly(intermBankFieldArr, isOrderPartyChecked);
        }

        // handle lock icon for amount if template and not view
        if (formState.toUpperCase() !== 'VIEW' && (model.jsonData.typeInfo.functionCode !== 'BATCH')) {
            if (creditAmount.$el && creditAmount.$el.length > 0) {
                PaymentUtil.runChangeAmount(creditAmount.$el[0], model);
            }
        }
    }

    /*
     * hide cutoff if conditions are true  (EN-Entered, IA-Incomplete Approval, RT-Needs
     * Rate, HV-2nd Approval Required are status defined in database)
     */
    rtgsCommon.hideCutoff(formState, functionCode, hidecutoff, 'NRECEIVE', form.formView.model.get('STATUS'));
}
