import React from 'react';
import locale from '@glu/locale';
import CheckTransactionsWorkspace from './components/CheckTransactionsWorkspace';
import ViewPcmPayment from './views/viewPcmPayment';

// Note: Workspace page "Check Transactions" not required.
// But having this pcm actions gives ability to register widget view

export default (dgbPaymentsActions = {}) => Object.assign(dgbPaymentsActions, {// eslint-disable-line
    checkTransactionsWorkspace() {
        // eslint-disable-next-line
        this.showPage(locale.get('widget.check_transactions.description'), <CheckTransactionsWorkspace />);
    },

    pmtViewPayment(isSubPayment) {
        this.showPage('', new ViewPcmPayment({ isSubPayment }));
    },
});
