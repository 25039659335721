export default {
  bulkActionsWrap: {
    marginTop: 20,
    marginBottom: 30
  },
  bulkActionsBtn: {
    height: 22,
    padding: '0 25px',
    marginLeft: 25,
    fontSize: 12,
    '&:focus': {
      padding: '0 24px'
    }
  }
};
