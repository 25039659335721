import http from '@glu/core/src/http';
import mobileUtil from 'mobile/util/mobileUtil';
import client from '../client';
import basePoller from '../poller';

const rtpPoller = basePoller.extend({

    notificationKeys: [],

    initialize(options) {
        basePoller.prototype.initialize.call(this, options);
        this.notificationKeys = options.notificationKeys;
    },

    /**
     * removes the client listeners
     * this is called when the rtp notification is unsubscribed
     * (when the page goes away)
     */
    removeListeners() {
        this.stopListening(client, 'service:subscribe');
        this.stopListening(client, 'service:unsubscribe');
    },

    /**
     * @override
     * Unsubcribe should only be called when a subscription key is removed.
     * We may support multiple subscriptions, so don't disable automatically,
     * first check if our poller should still be running.
     * @return {boolean}
     *
     * call the base handleUnsubscribe.  if that unsubscribes, then
     * remove the listeners
     * @param {String} key
     */
    handleUnsubscribe(key) {
        if (basePoller.prototype.handleUnsubscribe.call(this, key)) {
            this.removeListeners();
            this.unsubscribeFromNotifications();
        }
    },

    /**
     * Handles unsubscription from notifications. Will
     * call the notifications endpoint with unSubscribeTypeList
     */
    unsubscribeFromNotifications() {
        this.requestData(false);
    },

    /**
     * Override this to specify whether a subscribe event
     *  should cause this poller to act.
     * @param {String} key
     */
    isRelevantSubscription(key) {
        return this.notificationKeys.includes(key);
    },

    /**
     * Different pollers will override this function so they do not poll when unneeded.
     * @return {boolean}
     */
    isTypeEnabled() {
        const subs = client.getSubs();
        return this.notificationKeys.some(type => subs.get(type));
    },

    /**
     * @override
     * @return {{list of subscribe topics}}
     */
    buildRequestObject(isSubscription) {
        return {
            [isSubscription ? 'subscribeTypeList' : 'unSubscribeTypeList']:
                [...this.notificationKeys],
        };
    },

    /**
     * @override
     *
     * @param {boolean} isSubscription
     * @return {*}
     */
    requestData(isSubscription = true) {
        const data = this.buildRequestObject(isSubscription);
        return new Promise((res, rej) => http.post(this.serviceUrl, data).then((resp) => {
            this.checkForAsyncNotifications(resp);
            res(resp);
        }, rej));
    },

    /**
     * @method checkForAsyncNotifications
     * @description Method to check if there is any async reports notifications before exiting rtp
     * poller
     * @param {object} notificationMessages
     */
    checkForAsyncNotifications({ notificationMessages }) {
        if (mobileUtil.isMobileScreen()) {
            return;
        }
        if (notificationMessages?.[0]?.data.count) {
            client.trigger('notification:asyncReports', notificationMessages[0].data.rows);
        }
    },

    /**
     * Must be overridden by implementation
     * Should trigger events on the client for views to receive.
     */
    processResponses(data) {
        client.trigger('notification:rtp', data);
    },
});
export default rtpPoller;
