var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"modal-header\">\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span class=\"icon-close\"></span></button>\n                <h2 class=\"modal-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":49}}}) : helper)))
    + "</h2>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-<%- type %> sb-payments-modal sb-payments-comment-modal\">\n    <div class=\"modal-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"modal-body\">\n            <textarea data-bind=\"model\" name=\"comment\" id =\"commentsText\" class=\"form-control\" maxlength=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"maxLength") || (depth0 != null ? lookupProperty(depth0,"maxLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"maxLength","hash":{},"data":data,"loc":{"start":{"line":10,"column":106},"end":{"line":10,"column":119}}}) : helper)))
    + "\"></textarea>\n            <div class=\"char-counter\" id=\"char-counter\"></div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" id=\"comment-modal-ok-button\" class=\"btn btn-primary\" data-disable-on-submit data-action=\"saveComment\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.okBtn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":45}}}))
    + "\n            </button>\n            <button type=\"button\" id=\"comment-modal-cancel-button\" class=\"btn btn-link\"  data-action=\"closeModal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"sbPayments.cancelBtn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":114},"end":{"line":17,"column":147}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});