import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import gridDataAccess from './gridDataActions';

function mixedChangeSetNeeded(data, mode, copyingUser, permissionsChangedToByGroup) {
    /*
     * If we are in exclusive or inclusive all set, we do not need this
     * This was changed because we don't ge the entire data set back.
     * There is no way to confirm if a mixed changeset is needed, so we assume it
     * unless the "all" or "exclusive" is set.
     * If the payment permissions were changed from by type to group, we cannot
     * send a mixedchangeset.
     * For all payment types we must treat the date entitlements as new.  By
     * having mixedchangeset to false
     * any old data entitlements are deleted and only the members of the new
     * request are added.
     */
    const modifyOrCopyUser = mode !== constants.MODES.INSERT || copyingUser;

    const selectAllOrExclusiveSet = data.exclusiveSet || data.allSet;
    const initPaymentPermissions = permissionsChangedToByGroup
        && ['WIRES', 'TRANSFER', 'LOANS', 'ACH'].includes(data.productCode);

    return modifyOrCopyUser
        && !data.initialCurrentFuture
        && !selectAllOrExclusiveSet
        && !initPaymentPermissions;
}

/**
 * Generate reusable proxyColumn functions for each Step 3 tab.
 * @param {Object} context - the View context
 * @param {boolean} [addToContext] - Add to the passed context object, or
 * just return
 * @return {{setupProxyColumn: setupProxyColumn, hasProxyColumn: hasProxyColumn,
 * getProxyColumn: getProxyColumn}}
 */
function setupTabProxyFunctions(context, addToContext) {
    const contextParam = context;
    const proxyFunctions = {
        /**
         * Generate Proxy Column functions for entitlements
         * @param {String} colName
         * @param {Boolean} [noSave] - Whether a generated column should be saved.
         * @param {String} rowId - Pass for restricted templates to find grid row to populate
         * @return {{productCode: *, members: Array, exclusiveSet: boolean, allSet: boolean,
         * currentFuture: boolean, mixedChangeSet: boolean, group: boolean, typeCode:
         * string, dataEntAttr: *}}
         */
        setupProxyColumn(colName, noSave, rowId) {
            const isTransfer = colName.indexOf(constants.PAYMENT_GROUPS.TRANSFERS) === 0;
            const colNameMatcher = isTransfer
                ? constants.PAYMENT_GROUPS.TRANSFERS.toLowerCase() : colName.toLowerCase();
            const isRestrictedTemplate = colNameMatcher === 'template';
            const rowData = context.permissionRecords
                    || gridDataAccess.getAllRecords(context.model, true);
            const colMatch = util.find(rowData, row => colNameMatcher === row.id.toLowerCase());
            const grouped = (colMatch && colMatch.isGroup) || false;
            let groupName = this.getGroupName(grouped, colName, context.typeProductMap);
            let typeName = grouped ? '' : colName;
            if (isRestrictedTemplate) {
                if (!util.isNullOrUndefined(rowId)) {
                    const currentRow = util.findWhere(
                        contextParam.gridCollection.originalData,
                        { ACCOUNTFILTER: rowId },
                    );
                    typeName = currentRow.VALUE3;
                    groupName = currentRow.VALUE2;
                } else {
                    typeName = '*';
                    groupName = '*';
                }
            }
            const data = {
                productCode: groupName,
                members: [],
                exclusiveSet: false,
                allSet: false,
                currentFuture: false,
                initialCurrentFuture: false,
                mixedChangeSet: false,
                group: grouped,
                typeCode: typeName,
                dataEntAttr: grouped && isTransfer
                    ? this.getTransferDE(colMatch, colName, groupName)
                    : context.getEntityName(groupName),
            };

            if (!noSave) {
                contextParam.proxyData[colName] = data;
            }

            return data;
        },

        /**
         * Retrive the correct data entitlement attribute for transfers
         * @param {Object} group - transfers group object
         * @param {String} [colName] - Column Name of the selected checkbox.
         * @param {Array} [groupName] - Name of group - groupname + DE attribute.
         * @return {String} data entitlement attribute
         */
        getTransferDE(group, colName, groupName) {
            const expectedEntitlement = colName.slice(groupName.length);
            const dataEntitlementsAttributes = group.types[0].get('dataEntitlementsAttributes');

            return util.find(
                dataEntitlementsAttributes,
                dataEntitlement => dataEntitlement.toUpperCase() === expectedEntitlement,
            );
        },

        /**
         * Retrive grouped name for data entitlements object sent to server
         * @param {Boolean} grouped - Are payment permissions by group or type
         * @param {String} [colName] - Column Name of the selected checkbox.
         * @param {Array} [typeProductMap] - Map of types to product to be used for
         * group name
         * when permisions by type.
         * @return {String} name of grouping used for product
         */
        getGroupName(grouped, colName, typeProductMap) {
            let groupName;

            if (grouped) {
                groupName = colName.indexOf(constants.PAYMENT_GROUPS.TRANSFERS) === 0
                    ? constants.PAYMENT_GROUPS.TRANSFERS : colName;
            } else {
                groupName = typeProductMap[colName];
            }

            return groupName;
        },

        /**
         * Check for column
         * @param {String} colName
         * @return {boolean}
         */
        hasProxyColumn(colName) {
            return context.proxyData[colName] !== undefined;
        },

        /**
         * TODO: Should we refactor the noCreate uses to run hasProxyColumn first??
         * TODO: Should we refactor the noSave to use hasProxyColumn and a "getDefaults"??
         * @param {String} colName
         * @param {boolean} noCreate - Sometimes we need to *not* get the data
         * @param {boolean} noSave - If we need default fields but don't want to save an object
         * @param {String} rowId - Pass for restricted templates to find grid row to populate
         * @return {*}
         */
        getProxyColumn(colName, noCreate, noSave, rowId) {
            const existing = context.proxyData[colName];

            if (existing) {
                return existing;
            }

            if (!noCreate || noSave) {
                /*
                 * ensure colName is always sent as string
                 * (few cases where it may be retrieved as number)
                 */
                return proxyFunctions.setupProxyColumn(String(colName), noSave, rowId);
            }

            return undefined;
        },

        /**
         * This function is over-built for the current load/store behavior of collectionProxy
         * TODO: are any return values actually used?
         * @param {string} colName
         * @param {string} rowId
         * @param {string} currentVal
         * @param {string} originalVal
         * @param {object} [additionalProps] - optional data to be sent with the column member
         * data saved (can be used for futher formatting purposes)
         */
        updateColDataRow(colName, rowId, currentVal, originalVal, additionalProps) {
            // Reusable predicate for find and reject
            const checked = currentVal === '1';

            const originallyChecked = originalVal === '1';

            const findRowPredicate = function (member) {
                return member.id === rowId;
            };

            const colExists = proxyFunctions.hasProxyColumn(colName);

            /*
             * Get the column colData or generate it if this is
             * the first time we've touched this column
             * That makes the dataset as small as possible
             */
            let colData = proxyFunctions.getProxyColumn(colName, false, true, rowId) || {};

            if ((currentVal === originalVal && !colExists) || currentVal === '') {
                return false;
            }

            // Nothing is done if C/F is set.
            if (colData.currentFuture) {
                return undefined;
            }

            // We need to generate the data
            if (!colExists) {
                colData = proxyFunctions.getProxyColumn(colName, undefined, undefined, rowId);
            }

            const member = util.find(colData.members, findRowPredicate);
            const isMember = member !== undefined;

            // Check if we need to switch to a mixedChangeset
            if (mixedChangeSetNeeded(
                colData,
                context.mode,
                context.options.copyingUser,
                context.permissionsChangedToByGroup,
            )) {
                colData.mixedChangeSet = true;
            }

            // Mixed Changeset has separate logic
            if (colData.mixedChangeSet) {
                // If we have a previous value, compare it for mixedChangeset.
                if (checked === originallyChecked) {
                    // If they are the same, remove this from the dataset
                    if (isMember) {
                        colData.members = util.reject(colData.members, findRowPredicate);
                    }
                } else if (isMember) {
                    /*
                     * If they don't match, or there isn't an original value to compare, add it
                     * Update the existing member record to match the current state.
                     */
                    member.checked = checked;
                } else {
                    colData.members.push({
                        id: rowId,
                        checked,
                        ...additionalProps,
                    });
                }
                // bitwise XOR (^) has no easy equivalent
                // eslint-disable-next-line no-bitwise
            } else if (colData.exclusiveSet ^ checked) {
                /*
                 * Add it if it isn't already there
                 * (hopefully not, that would be an integrity issue)
                 */
                if (!isMember) {
                    colData.members.push({
                        id: rowId,
                        // Not needed for this type, but used if we switch to mixed changeset
                        checked,
                        ...additionalProps,
                    });
                }
            } else if (isMember) {
                // Not handled by Mixed Changeset, and should not be in the colData set.
                colData.members = util.reject(colData.members, findRowPredicate);
            }

            /*
             * Add a property for restricted templates since they can
             * have different types/products.  It will be removed before
             * sending to server.
             */
            if (colName === 'TEMPLATE') {
                // Get from originalData because row selection doesn't contain correct model
                const currentRow = util.findWhere(
                    context.gridCollection.originalData,
                    { ACCOUNTFILTER: rowId },
                );
                let currentMember;

                if (colData.members.length > 0) {
                    currentMember = colData.members.find(({ id }) => id === rowId);
                    // currentMember can be empty if an item in grid is not selected
                    if (util.isNullOrUndefined(currentMember)) {
                        currentMember = {};
                    }
                    currentMember.templateCode = `${currentRow.VALUE2 === 'USACH' ? 'ACH' : currentRow.VALUE2
                    }~${currentRow.VALUE3}~${rowId}`;
                }
            }
            return undefined;
        },
    };

    if (addToContext) {
        util.each(proxyFunctions, (value, key) => {
            contextParam[key] = value;
        });
    }

    return proxyFunctions;
}

export default setupTabProxyFunctions;
