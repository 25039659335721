import util from '@glu/core/src/util';

export default function (form, initialState) {
    const { model } = form.formView;
    const docAdjustedAmount = form.field('DOC_ADJUSTMENTAMOUNT');
    const docAdjustedDBCR = form.field('DOC_ADJUSTMENTDBCR');
    const docAdjustedReason = form.field('DOC_ADJUSTMENTREASON');

    const makeAdjustedMandatory = (state) => {
        docAdjustedAmount.shouldBeRequiredWhen(state, true);
        docAdjustedDBCR.shouldBeRequiredWhen(state, true);
        docAdjustedReason.shouldBeRequiredWhen(state, true);
    };

    const updateAdjustedFields = () => {
        const adjustedAmount = model.get('DOC_ADJUSTMENTAMOUNT');
        const adjustedDBCR = model.get('DOC_ADJUSTMENTDBCR');
        const adjustedReason = model.get('DOC_ADJUSTMENTREASON');
        const adjustedInfo = model.get('DOC_ADJUSTMENTINFO');

        /*
         * if one or more of the adjusted fields is non-empty, then make them all mandatory
         * if they are all blank them make them optional
         */
        if (!util.isEmpty(adjustedAmount)
            || !util.isEmpty(adjustedDBCR)
            || !util.isEmpty(adjustedReason)
            || !util.isEmpty(adjustedInfo)) {
            makeAdjustedMandatory(true);
        } else {
            makeAdjustedMandatory(false);
        }
    };
    if (initialState) {
        // Value should not be locale string or it break saving in other currency
        model.set({
            DOC_PAIDAMOUNTCURR: 'USD',
            DOC_DISCOUNTAMOUNTCURR: 'USD',
            DOC_ORIGINALAMOUNTCURR: 'USD',
            DOC_ADJUSTMENTAMOUNTCURR: 'USD',
        });
        model.on('change:DOC_ADJUSTMENTAMOUNT change:DOC_ADJUSTMENTDBCR change:DOC_ADJUSTMENTREASON change:DOC_ADJUSTMENTINFO', () => {
            updateAdjustedFields();
        });
    }
}
