import React from 'react';
import PropTypes from 'prop-types';
import { ActionGroup } from '@glu/grid-mobile-react';
import { asView } from 'common/util/reactUtil';

const ActionsGroup = ({
    rowData,
    actionDefs,
    classes,
}) => (
    <ActionGroup rowData={rowData} actionDefs={actionDefs} classes={classes} />
);

ActionsGroup.propTypes = {
    rowData: PropTypes.shape({
        actions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    actionDefs: PropTypes.shape({}).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default ActionsGroup;

export const ActionsGroupView = asView(ActionsGroup);
