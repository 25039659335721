import Layout from '@glu/core/src/layout';
import amountFormatHelper from 'common/dynamicPages/views/mdf/componentHelpers/amountFormat';
import ApproverSequences from './approverSequences';
import ApproverSequenceModel from '../../models/approverSequence';
import thresholdTmpl from './threshold.hbs';

export default Layout.extend({
    template: thresholdTmpl,

    ui: {
        $addSequenceButton: '[data-hook="getAddSequenceButton"]',
        $amountInput: '[data-hook="getAmountInput"]',
    },

    events: {
        'click @ui.$addSequenceButton': 'addApproverSequence',
    },

    initialize(options) {
        this.approverSequences = options.model.get('approverSequences');

        this.approverSequencesView = new ApproverSequences({
            collection: this.approverSequences,
            sequenceTypes: options.sequenceTypes,
            approverTypes: options.approverTypes,
        });
    },

    onRender() {
        this.approvalSequencesRegion.show(this.approverSequencesView);
        amountFormatHelper.setupInputMask(this, 'amount', false);
    },

    templateHelpers() {
        return {
            currency: this.options.currency,
        };
    },

    addApproverSequence() {
        this.approverSequences.add(new ApproverSequenceModel());
    },

});
