import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import { createMaskedInputView } from 'components/MaskedInput/MaskedInputWrapper';
import maskedInputWidgetTmpl from './maskedInputWidget.hbs';

export default BaseWidget.extend({
    template: maskedInputWidgetTmpl,
    className: 'ui-widget',

    createWidget() {
        const name = this.fieldName;
        const model = this.parentModel;
        const { fieldData, state } = this;
        const inputClassList = 'form-control';
        const readOnlyInputClassList = 'form-control-static';
        const MaskedInputView = createMaskedInputView({
            initialValue: model.get(name),
            cid: model.cid,
            name,
            dataBind: true,
            inputClassList,
            readOnlyInputClassList,
            fieldLabel: fieldData.fieldLabel,
            isRequired: state !== 'VIEW' && fieldData.mandatory,
            isProtected: fieldData.protected,
            maxLength: fieldData.maxLen,
            isReadOnly: state === 'VIEW',
        });
        return MaskedInputView;
    },

    onRender() {
        const MaskedInputView = this.createWidget();
        this.maskedInputRegion.show(new MaskedInputView());
    },

});
