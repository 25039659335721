import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import GridApi from 'common/dynamicPages/api/grid';
import transform from 'common/util/transform';
import EditTemplateGroupViewTemplate from 'app/payments/views/viewTemplateGroup.hbs';
import mobileUtil from 'mobile/util/mobileUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';

export default Layout.extend({
    template: EditTemplateGroupViewTemplate,

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (mobileUtil.isMobileGridEnabled()) {
                let MobileList = ListView;
                const mobileList = configureMobileInterface(
                    MobileList,
                    this.mobileListViewOptions,
                );
                MobileList = MobileList.extend(mobileList);
                this.selectedTemplateGridRegion.show(new MobileList({
                    ...this.options,
                    ...this.gridViewOptions,
                    skipEntitlements: true,
                    renderMobileGridUsingTemplate: false,
                    prebuiltOptions: true,
                    gridView: this.gridView,
                }));
            } else {
                this.selectedTemplateGridRegion.show(this.gridView);
            }
        } else {
            this.loadViewRequirements();
        }
    },

    getHeader() {
        return (this.mode === 'create') ? locale.get('payment.templategroups.newGroup') : this.options.name;
    },

    /**
     * @method loadViewRequirements
     * This loads selected templates group grid view data using 20490 inquiryId
     */
    loadViewRequirements() {
        //
        const requestParameters = {
            inquiryId: '20490',
            productCode: 'PAY',
            functionCode: 'TMPLSET',
            typeCode: 'TMPLSET',
            UserGroup: this.options.userGroup,
            Name: this.options.name,
        };

        const options = {
            context: {
                serviceName: '/paymentTemplate',
                actionMode: 'SELECT',
                productCode: 'PAY',
                functionCode: 'TMPLSET',
                typeCode: 'TMPLSET',
            },

            filter: true,
            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: false,
            enableSavedViews: false,

            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
        };
        this.gridViewOptions = options;
        this.gridView = GridApi.createServiceGridView(options);
        this.setHasLoadedRequiredData(true);
        this.onRender();
    },
});
