import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';

const MessageLog = React.lazy(() => import('./MessageLogList'));

export default {

    pcmMessageLog() {
        this.showPage(
            locale.get('message.log.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <MessageLog />
            </React.Suspense>,
            {
                headlineText: locale.get('message.log.list'),
            },
        );
    },
};
