import ChildBase from 'app/smbPayments/models/childBase';
import userInfo from 'etc/userInfo';

export default ChildBase.extend({
    defaults() {
        return {
            ACCOUNTNUMBER: '',
            ACCOUNTTYPE: '',
            AMOUNT: '',
            ENTRYMETHOD: 0,
            HOLDFLAG: 0,
            PRENOTEFLAG: 0,
            RECEIVABA: '',
            RECEIVRBANKCODE: '',
            RECEIVBANKCOUNTRYCODE: '',
            RECEIVBANKNAME: '',
            INDVID: '',
            INDVNAME: '',
            USERGROUP: userInfo.get('group'),
            BENEBOOKCHILD_ID: '',
            BENEBOOK_ID: '',
            BENEBOOKUPDATECOUNT__: '',
            WORKSEQNUMBER: '1',
        };
    },

    setAttributesForSave() {
        const { attributes, beneAccount } = this.options;
        this.set({
            ACCOUNTNUMBER: beneAccount.get('BENE_ACCOUNT'),
            ACCOUNTTYPE: beneAccount.get('BENE_ACCOUNTTYPE'),
            AMOUNT: attributes.CREDIT_AMOUNT,
            RECEIVABA: beneAccount.get('BENE_BANK_ID'),
            RECEIVRBANKCODE: beneAccount.get('BENE_BANK_ID'),
            RECEIVBANKCOUNTRYCODE: beneAccount.get('BENE_COUNTRY'),
            RECEIVBANKNAME: beneAccount.get('BENE_BANK_NAME'),
            INDVID: beneAccount.get('BENE_REFERENCE'),
            INDVNAME: beneAccount.get('BENE_NAME'),
            BENEBOOKCHILD_ID: beneAccount.get('BENEBOOKCHILD_ID'),
            BENEBOOK_ID: beneAccount.get('BENEBOOK_ID'),
            BENEBOOKUPDATECOUNT__: beneAccount.get('BENEBOOKUPDATECOUNT__'),
            CONTACTHASBEENUPDATED: beneAccount.get('CONTACTHASBEENUPDATED'),
            init_ABAValidationMethod: 'popup',
        });
    },

    gridName: 'ACH_BDSummaryGrid_24656',
    defaultRouteBase: '/batch/ConsumerPayments',
});
