import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

const AccountSectionGroups = Collection.extend({
    initialize(options) {
        const { sectionModel } = options;
        this.sectionId = sectionModel.get('sectionId');
        this.tabId = sectionModel.get('tabId');
        this.route = sectionModel.get('route');
    },

    sync(method, model, options) {
        const requestParameters = {
            tabId: this.tabId,
            sectionId: this.sectionId,
        };
        const request = {
            /*
             * Set viewId to -1 so it does not try to find a default view that might not
             * return all data.
             */
            viewId: -1,

            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
        };
        const url = services.generateUrl(`${this.route}/getAcctGroupTotalsList`);

        return http.post(url, request, (result) => {
            options.success(result);
        }, () => {
            options.error();
        });
    },

    parse(result) {
        return util.map(result.rows, row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue', true));
    },
});

export default AccountSectionGroups;
