import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import scroll from 'common/util/scroll';
import store from 'system/utilities/cache';
import ServicesCollection from 'app/administration/collection/services';
import ServiceModel from 'app/administration/models/service';
import AdminUtil from 'app/administration/common/util';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import addTmpl from './add.hbs';

export default Layout.extend({
    className: 'segmentation segmentation-add',
    template: addTmpl,
    loadingTemplate: loadingWidgetTmpl,

    regions: {
        alertRegion: '.alert-region',
    },

    ui: {
        $panelTitle: '.panel-title',
        $selectAll: '.select-all',
        $segmentationTypeRadio: 'input[name="segmentation-type"]',
        $actionAlerts: '[name="RTGS@RTGSALRA@ALERT"], [name="RTGS@PTMPALRA@ALERT"],[name="RTGS@APRVEWPA@ALERT"],[name="RTGS@RTGSALAR@ALERT"],[name="RTGS@RTGSBRAL@ALERT"]',
        $scheduledAlerts: '[name="RTGS@RTGSALRT@ALERT"], [name="RTGS@PTMPALRT@ALERT"],[name="RTGS@APRVEWP@ALERT"],[name="RTGS@RTGSAR@ALERT"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'change @ui.$segmentationTypeRadio': 'updateSegmentationType',
        'click @ui.$selectAll': 'handleSelectAll',
        'change .individual-select-control input[type="checkbox"]': 'handleCheckboxChange',
    },

    segmentTypes: {
        0: 'segment.basicBusinessTitle',
        1: 'segment.advancedBusinessTitle',
        3: 'segment.microBusinessTitle',
    },

    initialize(options) {
        this.model = options.model || new ServiceModel();

        this.segmentName = store.get('segmentName');
        this.isEditMode = store.get('segmentModify');

        if (this.isEditMode) {
            // set our segment name in the model for display
            this.model.set('id', this.segmentName);
            this.model.set('NAME', this.segmentName);
        } else {
            // show basic(smb) as default
            this.state = '0';
        }
    },

    modelEvents: {
        invalid: 'scrollToError',
    },

    scrollToError() {
        util.defer(() => {
            scroll.scrollToFirstError();
        });
    },

    loadViewRequirements() {
        const self = this;

        const opts = {
            segmentName: self.segmentName,
        };

        if (!this.isEditMode) {
            opts.state = self.state;
        }
        self.collection = new ServicesCollection(opts);
        self.collection.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    /* update the model with all the checkboxes that are checked */
    setModelDataForEdit() {
        const self = this;

        $.each(self.collection.models, (i, modelObj) => {
            this.state = modelObj.get('sophistication');
            $.each(modelObj.attributes.sections, (i2, sections) => {
                $.each(sections.groups, (i3, groups) => {
                    $.each(groups.fields, (i4, field) => {
                        if (field.checked) {
                            self.model.set(field.name, 'true');
                        }
                    });
                });
            });
        });

        // save off the current state so we know if something changes.
        self.originalAttributes = util.clone(self.model.attributes);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.isEditMode) {
                this.setModelDataForEdit();
                this.displayLeavePageWarning();
            } else {
                // only allow switching type on in new segments
                this.updateStateButtons();
                const sophistication = locale.get(this.segmentTypes[this.state]);
                store.set('segment:sophistication', sophistication);
            }
            this.displaySelected();
            // open the first accordion
            this.$('.panel-collapse').eq(0).collapse('show');

            this.$('button[data-toggle="popover"]').popover({
                html: true,
            });
            this.hideActionAlerts();
        } else {
            this.loadViewRequirements();
        }
    },

    /**
     * @method hideActionAlerts
     * @description for the five action alerts introduced into 3.6, the action
     * and schedule alerts must not be visible simultaneously
     */
    hideActionAlerts() {
        if (applicationConfigParams.get('ALRT_REALTIMEALRT') === '0') {
            this.ui.$actionAlerts.parents('.checkbox').hide();
        } else {
            this.ui.$scheduledAlerts.parents('.checkbox').hide();
        }
    },

    displayLeavePageWarning() {
        const self = this;

        self.ui.$navLinks.on('click', (e) => {
            e.preventDefault();
            if (this.$(e.target).parent('li').hasClass('is-active')) {
                return;
            }
            const path = self.$(e.target).attr('href');

            const hasChanged = !util.isEqual(self.originalAttributes, self.model.attributes);

            if (!util.isEmpty(path) && path !== '#' && hasChanged) {
                dialog.confirm(
                    locale.get('segment.confirm.leavepage.warning'), locale.get('segment.confirm.edit.heading'),
                    (ok) => {
                        if (ok) {
                            self.navigateTo(path);
                        }
                    },
                );
            } else {
                // no changes so just go there.
                self.navigateTo(path);
            }
        });
    },

    templateHelpers() {
        const self = this;
        return {
            sectionGroups() {
                return self.collection.models;
            },
            segmentTypes: self.segmentTypes,
            isEditMode: this.isEditMode,
            businessType: store.get('segment:sophistication'),
        };
    },

    updateSegmentationType() {
        this.state = this.ui.$segmentationTypeRadio.filter(':checked').val();
        this.loadViewRequirements();
    },

    updateStateButtons() {
        const selector = `[value="${this.state}"]`;
        const $button = this.ui.$segmentationTypeRadio.filter(selector);

        // Remove active from other parents?
        this.ui.$segmentationTypeRadio.parent().removeClass('active');

        $button.prop('checked', true).parent().addClass('active');
    },

    handleCheckboxChange(e) {
        const $panel = this.$(e.currentTarget).parents('.panel');
        this.setDisplayTotals($panel);
    },

    displaySelected() {
        const self = this;
        this.$('.panel').each((i, el) => {
            const $panel = self.$(el);
            self.setDisplayTotals($panel);
        });
    },

    setDisplayTotals($panel) {
        const total = $panel.find('[type=checkbox]').not('.select-all').length;
        const count = $panel.find('[type=checkbox]:checked').not('.select-all').length;

        $panel.find('.service-count .count').text(count);
        $panel.find('.service-count .total').text(total);
    },

    handleSelectAll(event) {
        const $target = this.$(event.currentTarget);
        const isChecked = $target.is(':checked');
        this.$(`input[data-group=${$target.attr('name')}]`).prop('checked', isChecked).trigger('change');
    },

    save() {
        const self = this;

        this.model.set('sophistication', this.state);
        this.model.save(
            {},
            {
                success() {
                    store.set('segmentName', self.model.get('NAME'));

                    if (self.isEditMode) {
                        store.set('admin:segmentation:add', locale.get('administration.segmentation.update.success', self.segmentName));
                        self.cancel();
                    } else {
                        self.navigateTo('/SEGMENTATION/addProfile');
                    }
                },

                error(response) {
                    AdminUtil.errorAlert(
                        self.alertRegion,
                        response.error.confirms.confirmResults[0].messages[0],
                    );
                },
            },
        );
    },

    cancel() {
        store.unset(
            'segmentName',
            {
                silent: true,
            },
        );
        store.unset(
            'segmentModify',
            {
                silent: true,
            },
        );
        this.navigateTo('/SEGMENTATION/viewSegments');
    },
});
