import locale from '@glu/locale';
import ScheduledExportModel from './scheduledExportModel';

export default ScheduledExportModel.extend({
    initialize(options) {
        ScheduledExportModel.prototype.initialize.call(this, options);

        this.validators = {
            ...this.validators,
            REPORT_ID: {
                description: locale.get('GIR.Report'),
                exists: true,
            },
            PROFILE_NUMBER: {
                description: locale.get('GIR.Saved.Report.Name'),
                exists: true,
            },
            FILE_FORMAT: {
                description: locale.get('GIR.File.Format'),
                exists: true,
            },
        };
    },

    defaults() {
        return {
            ...ScheduledExportModel.prototype.defaults.call(this),
            EXPORTTYPECODE: 'JRPTREQ',
        };
    },
});
