var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"groupId") || (depth0 != null ? lookupProperty(depth0,"groupId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupId","hash":{},"data":data,"loc":{"start":{"line":2,"column":15},"end":{"line":2,"column":26}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":35}}}) : helper)))
    + "\" class=\"product-feature-title\">\n        <strong class=\"feature-title-text\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"desc") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":58}}}))
    + " -</strong> <span data-hook=\"counter\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"selectedCount") || (depth0 != null ? lookupProperty(depth0,"selectedCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"selectedCount","hash":{},"data":data,"loc":{"start":{"line":3,"column":96},"end":{"line":3,"column":113}}}) : helper)))
    + "</span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PAY.Selected",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":121},"end":{"line":3,"column":146}}}))
    + "\n    </div>\n    <div class=\"product-feature-content\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSubGroup") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":105}}})) != null ? stack1 : "")
    + "\">\n            <div class=\"featureRegion child-item\"></div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label for=\"feature-"
    + alias4(((helper = (helper = lookupProperty(helpers,"groupId") || (depth0 != null ? lookupProperty(depth0,"groupId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupId","hash":{},"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":43}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":7,"column":44},"end":{"line":7,"column":52}}}) : helper)))
    + "\">\n                <input id=\"feature-"
    + alias4(((helper = (helper = lookupProperty(helpers,"groupId") || (depth0 != null ? lookupProperty(depth0,"groupId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupId","hash":{},"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":46}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":55}}}) : helper)))
    + "\" type=\"checkbox\" class=\"product-feature select-all-parent-item\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allFeaturesSelected") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":120},"end":{"line":8,"column":161}}})) != null ? stack1 : "")
    + "> <span>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.selectall",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":169},"end":{"line":8,"column":198}}}))
    + "</span>\n            </label>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked";
},"5":function(container,depth0,helpers,partials,data) {
    return "product-subgroup-container";
},"7":function(container,depth0,helpers,partials,data) {
    return "product-feature-container";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shouldBeHidden") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":15,"column":11}}})) != null ? stack1 : "");
},"useData":true});