// TODO - Maybe, refactor into new DataUtils() way,
// To avoid passing parameters batchTnum, tnum, typeCode, etc.
import { $, $document, http, util } from '@glu/core';
import locale from '@glu/locale';
import gluStore from '@glu/store';
import { getAccessTokenPromise } from 'pcm/common/ptx-connect-integration/ptxConnectUtils';
import {
    transactionDetailsContract,
    batchInformationContract,
} from './paymentContract';

const logError = false;

export const fileCheckDownloadPtx = (authenticatedUrl, done) => {
    // Use an existing download frame, if one exists.
    let $downloadFrame = $document.find('#download-frame');

    if (!$downloadFrame.length) {
        $downloadFrame = $('<iframe id="download-frame" name="download-frame" class="hidden" src="about:blank" />');
        $document.find('body').append($downloadFrame);
    }

    // Reset the iframe source to force a refetch when we change the URL below
    $downloadFrame.attr('src', 'about:blank');

    $downloadFrame.attr('src', authenticatedUrl);
    if (typeof done === 'function') {
        done();
    }
};

const errorHandler = (result) => {
    if (logError) {
        console.error(result); // eslint-disable-line
    }
};

/**
 * Get Check Preview download from PT-X Connect directly.
 * @param ptxCheckId
 * @returns {Promise<{}>}
 */
export function getPreviewESendDownloadPdf(url) {
    // if (!ptxCheckId) {
    //     return Promise.reject(new Error('No transaction Check ID'));
    // }

    fileCheckDownloadPtx(`/api${url.split('/api')[1]}`);
}

/**
 * Get Batch Model Info
 *
 * @param type - aka typeCode - APCHK or GPCHK
 * @param functionCode - in particular - BATCH
 *
 * @returns {Promise<*>}
 */
export function batchGetModelInfo(type, functionCode = 'BATCH') {
    const url = `/banking-services/api/batch/b${type.toLowerCase()}/getModelInfo`;
    return http.post(url, {
        action: 'INSERT',
        entryMethod: 0,
        functionCode,
        subtype: '*', // TODO how this payload defines it's exact MODEL INFO?????
    }, (response) => {
        const {
            childfieldContainerList,
            childFieldInfoList,
            childKeys, // TNUM only
            fieldContainerList,
            fieldInfoList,
        } = response;

        const aggregatedMdfData = [
            ...childfieldContainerList,
            ...fieldContainerList,
        ];

        return Promise.resolve({
            childfieldContainerList,
            childFieldInfoList,
            childKeys,
            fieldContainerList,
            fieldInfoList,
            aggregatedMdfData,
        });
    }, errorHandler);
}

/**
 *
 * @param type - aka typeCode - APCHK or GPCHK
 * @param tnum - transaction number, ID
 * @param functionCode (in particular - BATCH)
 * @returns {Promise<*>}
 */
export function batchRead(type, tnum, functionCode = 'BATCH') {
    const url = `/banking-services/api/batch/b${type.toLowerCase()}/read`;
    const payload =
        {
            item: {
                item: [
                    {
                        name: 'TNUM',
                        value: `${tnum}`,
                    },
                    {
                        name: 'ENTRYMETHOD',
                        value: '0', // ???
                    },
                    {
                        name: 'FUNCTIONCODE',
                        value: `${functionCode}`,
                    },
                    {
                        name: 'UPDATECOUNT__',
                        value: '8', // ???
                    },
                    {
                        name: '_saveWithWarning',
                    },
                    {
                        name: 'ACTIONCODE',
                    },
                    {
                        name: 'CMDECISION',
                    },
                    {
                        name: 'CLIENTRETURNREASONCODE',
                    },
                    {
                        name: 'CLIENTRETURNREASONDESC',
                    },
                    {
                        name: 'PAYISSUE_FLAG',
                    },
                    {
                        name: 'CONINTLWIREFLAG',
                        value: '',
                    },
                    {
                        name: 'CONSUMER_DISCLOSURE_USER_ACCEPTED',
                    },
                    {
                        name: 'CONSUMER_DISCLOSURE_USER_REJECTED',
                    },
                    {
                        name: 'duplicateAccepted',
                    },
                    {
                        name: 'contactChangesAccepted',
                    },
                    {
                        name: 'beneUpdatedChangesAccepted',
                    },
                    // TODO research if all these fields needed in payload.
                ],
            },
        };

    return http.post(url, payload, (response) => {
        const returnData = response.item ? response.item : response;
        return Promise.resolve(returnData);
    }, errorHandler);
}

/**
 * Get Transaction Info as one of children in BATCH.
 *
 * @param type - aka typeCode - APCHK or GPCHK
 * @param tnum - transaction number, ID
 *
 * @returns {Promise<*>}
 */
export function transactionGetChildren(type, batchTnum, tnum) {
    const url = `/banking-services/api/batch/b${type.toLowerCase()}/details/getChildren`;

    const payload = {
        action: 'SELECT',
        disableDrillDown: false,
        entryMethod: 0,
        keyFields: [{ name: 'TNUM', value: batchTnum.toString() }],
        searchFields: [{
            fieldName: 'TNUM',
            operator: '=',
            fieldValue: [tnum.toString()],
            dataType: 'text',
        }],
        startRow: 1,
        subType: '*',
    };

    return http.post(url, payload, (response) => {
        const { rowHeader, rows } = response;

        return Promise.resolve({
            rowHeader, // for localized labels
            rows, // for data values
        });
    }, errorHandler);
}

/**
 * Get Batch Children - all Transactions.
 *
 * @overload transactionGetChildren()
 *
 * @param type - aka typeCode - APCHK or GPCHK
 * @param batchTnum - batch of checks ID (transactions, payees, etc.).
 * Using BATCHTNUM value as TNUM, same endpoint /getChildren will return Array of transactions.
 *
 * @returns {Promise<*>}
 */
export function batchGetChildren(type, batchTnum, tnum) {
    return transactionGetChildren(type, batchTnum, tnum);
}

/**
 * Check Transaction History Details
 *
 * @param type - APCHK or GPCHK.
 *
 * @param tnum - can be value of TNUM or BATCHTNUM:
 * - TNUM value for Check Transactions.
 * - BATCHTNUM value for batch of checks.
 * By logic how these ids generated:
 * - BATCHTNUM is first from database, and
 * - transaction(s) TNUM is(are) next increments of value.
 *
 * @param functionCode - '' as value for Check Transaction, 'BATCH' for Batch of Checks.
 *
 * @returns {Promise<{rowHeader, rows}>}
 */
export function transactionHistoryDetails(type, tnum, functionCode = '') {
    const url = '/banking-services/api/audit/clmDetailAuditListView/getListView';

    const payload = {
        startRow: 1,
        disableDrillDown: false,
        dataOnly: 0,
        searchFields: [{
            operator: 'EQ',
            dataType: 'text',
            fieldName: 'TYPE',
            fieldValue: [type.toUpperCase()],
        }, {
            operator: 'EQ',
            dataType: 'text',
            fieldName: 'TNUM',
            fieldValue: [tnum.toString()],
        }, {
            operator: 'EQ',
            dataType: 'text',
            fieldName: 'FUNCTION',
            fieldValue: [functionCode],
        }],
    };

    return http.post(url, payload, (response) => {
        const { rowHeader, rows } = response;

        return Promise.resolve({
            rowHeader, // for localized labels
            rows, // for data values
        });
    }, errorHandler);
}

/**
 * Batch History Details
 *
 * @overload transactionHistoryDetails()
 *
 * @param type - APCHK or GPCHK.
 *
 * @param batchTnum aka BATCHTNUM value for batch of checks.
 * By logic how these ids generated:
 * - BATCHTNUM is first from database, and
 * - transaction(s) TNUM is(are) next increments of value.
 *
 * @returns {Promise<{rowHeader, rows}>}
 */
export function batchHistoryDetails(type, batchTnum) {
    return transactionHistoryDetails(`B${type}`, batchTnum, 'BATCH');
}

/**
 * Get Check Preview download from PT-X Connect directly.
 * @param ptxCheckId
 * @returns {Promise<{}>}
 */
export function getCheckPreviewDownloadPdf(ptxCheckId) {
    if (!ptxCheckId) {
        return Promise.reject(new Error('No transaction Check ID'));
    }

    const url = '/api/auth/preauth';

    return http.post(
        url, { url: `/api/documents/${ptxCheckId}/pdf?dir=in` }
        , (response) => {
            if (response && response.model && response.model.url) {
                fileCheckDownloadPtx(`/api${response.model.url.split('/api')[1]}`);
            }
            return {};
        }, errorHandler,
    );
}

/**
 * Get Check Preview Image from PT-X Connect directly.
 * @param ptxCheckId
 * @returns {Promise<{}>}
 */
export function getCheckPreviewImage(ptxCheckId) {
    if (!ptxCheckId) {
        return Promise.reject(new Error('No transaction Check ID'));
    }

    const url = `/api/cheques/${ptxCheckId}`;

    return getAccessTokenPromise().then((tokenData) => {
        if (tokenData) {
            return http.get(url, (response) => {
                if (response) {
                    return response;
                }
                return {};
            }, errorHandler);
        }

        return {};
    });
}
/**
 * Get Job Summary Report from PT-X Connect directly.
 * @param ptxBatchId
 * @returns {Promise<{}>}
 */
export async function getSobSummaryReport(ptxBatchId) {
    if (!ptxBatchId) {
        return Promise.reject(new Error('No batch Check ID'));
    }

    const url = `/api/batches/${ptxBatchId}/reportfiles`;
    const fileInfo = await getAccessTokenPromise().then((tokenData) => {
        if (tokenData) {
            return http.get(url, () => {
            }, errorHandler);
        }
        return {};
    });

    if (!fileInfo || !fileInfo.length) {
        return Promise.reject(new Error('No batch Check ID'));
    }

    const preAuthUrl = '/api/auth/preauth';

    const finalUrl = await http.post(
        preAuthUrl, { url: `/api/batches/${ptxBatchId}/reportfiles/${fileInfo[0].fileId}` }
        , () => {}, errorHandler,
    );

    if (!finalUrl) {
        return Promise.reject(new Error('No batch Check ID'));
    }

    if (finalUrl && finalUrl.model && finalUrl.model.url) {
        // PCM-4780 - Leaving the bellow code here before final requirement validation on this JIRA
        // window.open(`/api${finalUrl.model.url.split('/api')[1]}`, '_blank');
        fileCheckDownloadPtx(`/api${finalUrl.model.url.split('/api')[1]}`);
        return `/api${finalUrl.model.url.split('/api')[1]}`;
    }
    return null;
}

/**
 * Get Job Summary Report download from PT-X Connect directly.
 * @param ptxCheckId
 * @returns {Promise<{}>}
 */
export function getSobSummaryReportData(ptxBatchId, reportFileId) {
    if (!ptxBatchId || !reportFileId) {
        return Promise.reject(new Error('No batch Check ID or ReportFieldId'));
    }

    const url = `/api/batches/${ptxBatchId}/reportfiles/${reportFileId}`;
    return getAccessTokenPromise().then((tokenData) => {
        if (tokenData) {
            return http.get(url, (response) => {
                if (response) {
                    return response;
                }
                return {};
            }, errorHandler);
        }

        return {};
    });
}

export function parseCheckPreview(data) {
    const { model } = data;
    if (!model) {
        return [];
    }

    const { previews } = model;
    // previews[0] is first page of PDF Check
    // previews[1] is second page of PDF Check
    // and so on

    return previews.length ? previews : [];
}

export function getESendPreviewImage(ptxESendDocumentId) {
    if (!ptxESendDocumentId) {
        return Promise.reject(new Error('No eSend Document ID'));
    }

    const url = `/api/documents/${ptxESendDocumentId}`;
    return getAccessTokenPromise().then((tokenData) => {
        if (tokenData) {
            return http.get(
                url, null, null,
                {
                    headers: {
                        Authorization: `Bearer ${gluStore.get('ptxAccessToken')}`,
                        Accept: 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                },
            ).then((response) => {
                if (response) {
                    return response;
                }
                return {};
            });
        }

        return {};
    });
}

export function parseESendDocumentPreview(data) {
    const { previews } = data;
    if (!previews) {
        return [];
    }

    return previews.length ? previews : [];
}

/**
 * Parsing Function to collect Transaction Details Tab content (main data).
 *
 * Note: Real endpoint `/getChildren` requires TNUM value as value of BATCHTNUM !!!
 * And as result returns multiple rows
 *
 * Note:Transaction History Details taken by another endpoint.
 *
 * @param getChildrenData - aka payment data.
 * If BATCH contained only 1 transaction, 1 Payee selected, then rows[0] will contain the main data.
 * If BATCH will contains 2 Payees aka 2 Transactions, then => rows[0], rows[1]
 * And so on.
 *
 * @param tnum
 *
 * @return Object {
 *     "label": ""
 *     "value": ""
 * }
 * So that ReactJS component can use it in JSX.
 */
const parseTransactionDetails = (getChildrenData, tnum) => {
    const childrenFields = Object.keys(transactionDetailsContract);
    const parsedData = {};
    const { rowHeader, rows } = getChildrenData;
    const childrenHeader = rowHeader;
    let childrenColumns = rows[0] && rows[0].columns;
    // Note: there is also rows[0].context. Not used.
    // rows[0] it's Transaction 1
    // rows[1] it's Transaction 2
    // rows[2] it's Transaction 3

    // So using tnum, we have to filter out needed transaction
    if (rows.length > 1) {
        const allColumns = rows.map(el => el.columns);
        const findColumnByTNUM = (el) => {
            // because string
            const found = util.findWhere(el, { fieldName: 'TNUM', fieldValue: tnum.toString() });
            return !!found;
        };
        childrenColumns = allColumns.find(findColumnByTNUM);
    }

    const getLabel = (fieldName) => {
        const found = util.findWhere(childrenHeader, { fieldName });
        if (!found) return '';

        return found.displayKey ? locale.get(found.displayKey) : found.displayName;
    };

    const getValue = (fieldName) => {
        if (!childrenColumns) return '';

        const found = util.findWhere(childrenColumns, { fieldName });
        if (!found) return '';

        return found.fieldValue;
    };

    // Iterating getChildrenFields gives less items in resulting object.
    childrenFields.forEach((fieldName) => {
        parsedData[fieldName] = {
            label: getLabel(fieldName),
            value: getValue(fieldName),
        };
    });

    return parsedData;
};

/**
 * Parsing Function to collect Batch Information Tab content.
 *
 * @param getModelInfoData aka whole Payment model data
 * @param readData aka read endpoint data
 *
 * @return Object {
 *     "label": ""
 *     "value": ""
 * }
 * So that ReactJS component can use it in JSX.
 */
const parseBatchInformation = (getModelInfoData, readData) => {
    const readFields = Object.keys(batchInformationContract);
    const { fieldInfoList } = getModelInfoData;
    const parsedData = {};

    const getLabel = (fieldName) => {
        const found = util.findWhere(fieldInfoList, { name: fieldName }); // NOT locale string !!!
        if (!found) return '';

        // getModelInfo
        // - found.name  is always uppercase
        // - found.value  is always empty
        // getListView
        // - found.fieldLabel
        // - found.fieldValue
        return found.fieldLabel || (found.fieldLabel ? locale.get(found.fieldLabel) : '');
    };

    const getValue = (fieldName) => {
        const found = util.findWhere(readData, { name: fieldName });
        if (!found) return '';

        return found.value;
    };

    // Iterating readFields gives less items in resulting object.
    readFields.forEach((fieldName) => {
        parsedData[fieldName] = {
            label: getLabel(fieldName),
            value: getValue(fieldName),
        };
    });

    return parsedData;
};

/**
 * Main Parsing function to transform MDF data of Payment entity for View Page.
 *
 * @param allData - All resolved promises
 * @param batchTnum
 * @param tnum
 *
 * @returns {{transactionDetails, batchInformation, checkPreview: {}}}
 */
export const parsePaymentData = (allData, batchTnum, tnum) => {
    const [
        getModelInfoData,
        readData,
        transactionGetChildrenData,
        transactionHistoryDetailsData,
        fullPaymentHistoryData,
    ] = allData;

    const transactionDetails = {
        ...parseTransactionDetails(transactionGetChildrenData, tnum),
        transactionHistoryDetails: transactionHistoryDetailsData,
    };

    const batchInformation = {
        ...parseBatchInformation(getModelInfoData, readData.item),
        fullPaymentHistory: fullPaymentHistoryData,
    };

    return {
        transactionDetails,
        batchInformation,
        checkPreview: {}, // Not used for tab, but will be used in future as container of tab data
    };
};

/**
 * Main function to get all MDF related Payment data
 *
 * @param batchTnum - batch of checks ID.
 * @param tnum - transaction number/ID.
 * @param type - APCHK | GPCHK (Important - value used UPPERCASE and lowercase)
 *
 * @returns {Promise<{transactionDetails, batchInformation, checkPreview: {}} | never>}
 */
export function getAllPaymentData(batchTnum, tnum, type) {
    const p1 = batchGetModelInfo(type); // get BATCH mapping info, including UDFs
    const p2 = batchRead(type, batchTnum); // get real BATCH info values.
    const p3 = batchGetChildren(type, batchTnum, tnum); // in fact used for Transaction Details tab.
    const p4 = transactionHistoryDetails(type, tnum);
    const p5 = batchHistoryDetails(type, batchTnum);

    return Promise.all([p1, p2, p3, p4, p5])
        .then(allData => parsePaymentData(allData, batchTnum, tnum));
}
