import services from 'services';
import locale from '@glu/locale';
import validatorPatterns from 'system/validatorPatterns';
import LoanModel from 'app/loans/models/loan';

export default LoanModel.extend({
    getServiceName(model) {
        const type = model.get('paymentType');

        if (type === 'LOANDRAW') {
            return services.addLoanDrawTemplate;
        }
        if (type === 'LOANPAY') {
            return services.addLoanPaymentTemplate;
        }
        return undefined;
    },

    initialize() {
        this.validators = {
            Template_Code: {
                description: locale.get('loans.template.name'),
                exists: true,
                matches: validatorPatterns.TEMPLATECODE_PATTERN,
                overrideError: 'matchesAlphaNumeric',
            },

            Template_Description: {
                description: locale.get('loans.template.desc'),
                exists: true,
            },

            DEBIT_CURRENCY: {
                description: locale.get('loans.account'),
                exists: true,
                otherDescription: locale.get('RTGS.*.credit_currency'),
                sameValue: 'CREDIT_CURRENCY',
            },

            CREDIT_CURRENCY: {
                description: locale.get('loans.account'),
                exists: true,
                otherDescription: locale.get('RTGS.*.debit_currency'),
                sameValue: 'DEBIT_CURRENCY',
            },

            CREDIT_AMOUNT: {
                description: locale.get('loans.amount'),
                matches: validatorPatterns.PAMOUNT_PATTERN,
                maxLength: 16,
            },

            SELECTION_0: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_1: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_2: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_3: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_4: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            STANDARD_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            PRINCIPAL_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            INTEREST_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            ESCROW_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            OTHER_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            SPECIAL_INSTRUCTIONS: {
                description: locale.get('sbPayments.comment'),
                maxLength: 140,
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },

            DEBIT_ACCOUNT_NUMBER: {
                description: locale.get('loans.account'),
                exists: true,
            },

            BENE_ACCOUNT: {
                description: locale.get('loans.account'),
                exists: true,
            },
            CUSTOMER_REFERENCE: {
                description: locale.get('loans.customerref'),
                maxLength: 16,
                matches: validatorPatterns.NOBADCHARS_PATTERN,
            },
        };
    },
});
