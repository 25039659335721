var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"ALPHASTART\">\n        <span></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.password.rule.1",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":57}}}))
    + "\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"ALPHACOUNT\">\n        <span></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":22},"end":{"line":9,"column":57}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"ALPHACOUNT") || (depth0 != null ? lookupProperty(depth0,"ALPHACOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"ALPHACOUNT","hash":{},"data":data,"loc":{"start":{"line":9,"column":58},"end":{"line":9,"column":72}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.2b",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":73},"end":{"line":9,"column":109}}}))
    + "\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"LOWERCASECOUNT\">\n        <span></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.3",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":57}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"LOWERCASECOUNT") || (depth0 != null ? lookupProperty(depth0,"LOWERCASECOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"LOWERCASECOUNT","hash":{},"data":data,"loc":{"start":{"line":14,"column":58},"end":{"line":14,"column":76}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.3b",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":77},"end":{"line":14,"column":113}}}))
    + "\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"UPPERCASECOUNT\">\n        <span></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.4",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":22},"end":{"line":19,"column":57}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"UPPERCASECOUNT") || (depth0 != null ? lookupProperty(depth0,"UPPERCASECOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"UPPERCASECOUNT","hash":{},"data":data,"loc":{"start":{"line":19,"column":58},"end":{"line":19,"column":76}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.4b",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":77},"end":{"line":19,"column":113}}}))
    + "\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"DIGITCOUNT\">\n        <span></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.5",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":22},"end":{"line":24,"column":57}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DIGITCOUNT") || (depth0 != null ? lookupProperty(depth0,"DIGITCOUNT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"DIGITCOUNT","hash":{},"data":data,"loc":{"start":{"line":24,"column":58},"end":{"line":24,"column":72}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.5b",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":73},"end":{"line":24,"column":109}}}))
    + "\n    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"SPECIALCOUNT\">\n        <span></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.6",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":29,"column":57}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"SPECIALCOUNT") || (depth0 != null ? lookupProperty(depth0,"SPECIALCOUNT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"SPECIALCOUNT","hash":{},"data":data,"loc":{"start":{"line":29,"column":58},"end":{"line":29,"column":74}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.6b",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":75},"end":{"line":29,"column":111}}}))
    + "  <strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"SPECIALCHARS") || (depth0 != null ? lookupProperty(depth0,"SPECIALCHARS") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"SPECIALCHARS","hash":{},"data":data,"loc":{"start":{"line":29,"column":121},"end":{"line":29,"column":137}}}) : helper)))
    + "</strong>\n    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"MINIMUMLENGTH\">\n        <span></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":22},"end":{"line":34,"column":57}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"MINIMUMLENGTH") || (depth0 != null ? lookupProperty(depth0,"MINIMUMLENGTH") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"MINIMUMLENGTH","hash":{},"data":data,"loc":{"start":{"line":34,"column":58},"end":{"line":34,"column":75}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.password.rule.7b",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":76},"end":{"line":34,"column":112}}}))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"password-rule-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ALPHASTART") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ALPHACOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"LOWERCASECOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":16,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"UPPERCASECOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DIGITCOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SPECIALCOUNT") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":31,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"MINIMUMLENGTH") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":0},"end":{"line":36,"column":7}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});