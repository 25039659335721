var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n                    "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":52}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<br>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"section section-container\">\n    <div class=\"row\">\n        <div class=\"col-3\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Subject",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":19},"end":{"line":4,"column":54}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"SUMMARY") || (depth0 != null ? lookupProperty(depth0,"SUMMARY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"SUMMARY","hash":{},"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":26}}}) : helper)))
    + "</p>\n        </div>\n        <div class=\"col-6\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Detail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":19},"end":{"line":8,"column":53}}}))
    + "</label>\n            <p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"detailText") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":13,"column":25}}})) != null ? stack1 : "")
    + "            </p>\n        </div>\n    </div>\n</fieldset>\n";
},"useData":true});