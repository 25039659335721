/**
 * risk mgmt's extension of entitlement view
 */
import constants from 'app/administration/constants';
import EntitlementView from '../payments/assignedPermissions/entitlementView';
import EntitlementTemplate from './entitlementView.hbs';

export default EntitlementView.extend({
    template: EntitlementTemplate,

    initialize(opts, ...rest) {
        EntitlementView.prototype.initialize.call(this, [opts, ...rest]);
        this.label = this.model.label;
        this.mode = opts.mode;
    },

    handleActionChanges(model, o) {
        // not changed by view, ignore
        if (!o.source) {
            return;
        }

        const { name } = o.source[0];
        const action = name.split('.')[1];
        const isEntitled = o.source[0].checked;

        /*
         * Manually set value, since checkboxes data-bind unchecked to undefined
         * instead of false
         */
        this.model.set(name, isEntitled);

        // check if any other checkboxes are selected. If so, view cannot be uncheckedin
        if (action === 'SELECT' && !isEntitled && this.model.isEntitled()) {
            this.model.set('actions.SELECT.entitled', true);
            return;
        }

        // auto select view if other checkboxes are selected
        if (this.model.isActionApplicable('SELECT')
            && action !== 'SELECT' && isEntitled) {
            this.model.set('actions.SELECT.entitled', true);
        }
    },

    templateHelpers() {
        const { model } = this;

        return {
            label: this.label,

            dloadApplicable: model.isActionApplicable('DLOAD'),
            selectApplicable: model.isActionApplicable('SELECT'),
            readOnly: this.options.mode === constants.MODES.VIEW,
        };
    },
});
