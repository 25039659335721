export default ({ palette }) => ({
    charCountTextArea: {
        backgroundColor: palette.background,
        borderRadius: '4px',
        color: '#000',
        height: 'auto',
        marginBottom: '4px',
        width: '250px',
        paddingLeft: '15px',
        paddingRight: '10px',
        paddingBottom: '0px',
        resize: 'vertical',
        '&::placeholder': {
            color: palette.grey.G500,
        },
    },

    helpBlock: {
        color: palette.form.error,
        fontSize: '12px',
    },

    cancelReasons: {
        '& label': {
            width: '100%',
            '&::before': {
                content: '"*"',
                color: palette.form.error,
            },
            '& p:first-of-type': {
                marginTop: '-18px',
                marginLeft: '8px',
                paddingBottom: '0',
                marginBottom: '5px',
            },
        },
        '& [data-qa="dropdown-position"]': { /* position the dropdown directly under the select label, no gap */
            top: '58px',
        },
    },

    required: {
        '&::before': {
            content: '"* "',
            color: palette.form.error,
        },
        marginBottom: '0',
    },

    cancelRequestButton: {
        marginRight: '10px',
    },
});
