/**
 * Override the nested alertView.js which is called by glu/alert
 * Adds support for bullet list display of messages.
 */
import AlertLayout from './alertView-dgb';
import template from './alert.hbs';
import util from 'underscore';

export default AlertLayout.extend({
    template,

    initialize(options) {
        AlertLayout.prototype.initialize.call(this, options);
        this.bullets = options.bullets;
    },

    templateHelpers() {
        const isFunc = util.isFunction(AlertLayout.prototype.templateHelpers);
        const result = (isFunc ? AlertLayout.prototype.templateHelpers.call(this) :
            AlertLayout.prototype.templateHelpers) || {};

        return util.extend({}, result, {
            bullets: this.bullets,
        });
    },
});
