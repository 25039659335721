var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <form autocomplete=\"off\">\n        <div class=\"row\">\n            <div class=\"form-column col-md-12\">\n                <div class=\"field-container-md field-container\"><strong>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":72},"end":{"line":5,"column":103}}}))
    + "</strong></div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":27},"end":{"line":11,"column":53}}}))
    + "</label>\n                    <div>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"model") : stack1)) != null ? lookupProperty(stack1,"PIGID") : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\" field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.UserGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":27},"end":{"line":17,"column":53}}}))
    + "</label>\n                    <div>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"model") : stack1)) != null ? lookupProperty(stack1,"USERGROUP") : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.pigid.nickname",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":27},"end":{"line":25,"column":59}}}))
    + "</label>\n                    <div>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"model") : stack1)) != null ? lookupProperty(stack1,"PIGIDNICKNAME") : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\" field-container-md field-container\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":27},"end":{"line":31,"column":49}}}))
    + "</label>\n                    <div>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"model") : stack1)) != null ? lookupProperty(stack1,"STATUS") : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});