import util from '@glu/core/src/util';
import $ from 'jquery';

export default {
    /**
     * The showHideAddr function works with a set of address fields, usually from
     * the AddressFieldWidget, to show or hide each field based on its
     * content and if it is mandatory (must show).  The fields need to be named
     * with all the same prefix.
     * @param {Object} form - The MDF form, used to get the model.
     * @param {String} fieldPrefix - The base name of all the address fields.
     * @param {boolean} showLink - true if the 'Add More' link is needed in the address fields.
     * This is not needed if the state is View.
     * @param {boolean} [showAddressFields] - false if all the fields need to be
     * hidden. Defaults to true.
     * @param {boolean} [useVirtualField] - true when the address fields are
     * named as VIRTUALFIELD.  Defaults to false.
     */
    showHideAddr(form, fieldPrefix, showLink, showAddressFields, useVirtualField) {
        const showAddress = showAddressFields !== undefined ? showAddressFields : true;
        const isVirtualField = useVirtualField !== undefined ? useVirtualField : false;
        const { model } = form.formView;
        const address2 = (isVirtualField) ? `${fieldPrefix}ADDRESS_2_VIRTUALFIELD` : `${fieldPrefix}ADDRESS_2`;
        const city = (isVirtualField) ? `${fieldPrefix}CITY_VIRTUALFIELD` : `${fieldPrefix}CITY`;
        const addrLink = (isVirtualField) ? $(`#${fieldPrefix}ADDRESS_1_VIRTUALFIELD_LINK`) : $(`#${fieldPrefix}ADDRESS_1_LINK`);
        if (showAddress && (form.field(address2).isNotEmpty()
            || form.field(city).isNotEmpty()
            || model.fieldData[address2].mandatory
            || model.fieldData[city].mandatory)) {
            form.field(address2).$el.parent().removeClass('hide');
        } else {
            form.field(address2).$el.parent().addClass('hide');
        }
        if (showAddress && (form.field(city).isNotEmpty() || model.fieldData[city].mandatory)) {
            form.field(city).$el.parent().removeClass('hide');
        } else {
            form.field(city).$el.parent().addClass('hide');
        }
        if (showAddress && showLink && (form.field(address2).$el.parent().hasClass('hide') || form.field(city).$el.parent().hasClass('hide'))) {
            addrLink.parent().removeClass('hide');
        } else {
            addrLink.parent().addClass('hide');
        }
    },

    /**
     * The setupEmailFields function works with a set of Bene Notification email fields,
     * to show or hide each field based on its  content.
     * @param {Object} form - The MDF form.
     */
    setupEmailFields(form) {
        const linkId = '#BENE_NOTIFICATION_EMAIL_LINK';
        const showEmail5 = form.field('BENE_NOTIFICATION_EMAIL5').isNotEmpty();
        const showEmail4 = showEmail5 || form.field('BENE_NOTIFICATION_EMAIL4').isNotEmpty();
        const showEmail3 = showEmail4 || form.field('BENE_NOTIFICATION_EMAIL3').isNotEmpty();
        const showEmail2 = showEmail3 || form.field('BENE_NOTIFICATION_EMAIL2').isNotEmpty();
        if (showEmail2) {
            form.field('BENE_NOTIFICATION_EMAIL2').$el.parent().removeClass('hide');
        } else {
            form.field('BENE_NOTIFICATION_EMAIL2').$el.parent().addClass('hide');
        }
        if (showEmail3) {
            form.field('BENE_NOTIFICATION_EMAIL3').$el.parent().removeClass('hide');
        } else {
            form.field('BENE_NOTIFICATION_EMAIL3').$el.parent().addClass('hide');
        }
        if (showEmail4) {
            form.field('BENE_NOTIFICATION_EMAIL4').$el.parent().removeClass('hide');
        } else {
            form.field('BENE_NOTIFICATION_EMAIL4').$el.parent().addClass('hide');
        }
        if (showEmail5) {
            form.field('BENE_NOTIFICATION_EMAIL5').$el.parent().removeClass('hide');
            $(linkId).parent().addClass('hide');
        } else {
            form.field('BENE_NOTIFICATION_EMAIL5').$el.parent().addClass('hide');
            $(linkId).parent().removeClass('hide');
        }
    },

    /**
     * The toggleBeneBankValidator function enables and disables the validator
     * for the given field.  This is needed when
     * the Bank Entry Method toggles between Freeform and Lookup.
     * Lookup automatically populates the fields, and the fields are not
     * editable, so the validator should not run.
     * @param {boolean} removeValidator - true if requesting to remove the validator
     * from the field.
     * @param {Object} validator - validation object which defines what type of
     * validation should be performed.
     * @param {Object} model - model for the payment being displayed.
     * @param {String} fieldName - The name of the field that will have the validator
     * added or removed.
     */
    toggleBeneBankValidator(removeValidator, validator, model, fieldName) {
        if (util.isUndefined(validator)) {
            return;
        }

        if (removeValidator) {
            model.removeValidator(fieldName);
        } else {
            model.addValidator(fieldName, validator);
        }
    },

    /**
     * The beneBankIdEntryMethodChange function is used when the Bank Entry Method
     * toggles between Freeform and Lookup.  It controls the display
     * of the Bene Bank information based on the format selected.
     * @param {Object} form - The MDF form, used to get the model.
     * @param {Object} [addressValidator] - BENE_BANK_ACCOUNT_NUMBER validator.
     * @param {Object} [accountValidator] - BENE_BANK_ADDRESS_1 validator.
     */
    beneBankIdEntryMethodChange(form, addressValidator, accountValidator) {
        const beneBankIdEntryMethod = form.field('BENEBANKIDENTRYMETHOD');
        const { model } = form.formView;
        const beneBankId = form.field('BENE_BANK_ID');
        const beneBankName = form.field('BENE_BANK_NAME');
        const beneBankAddress1FreeForm = form.field('BENE_BANK_ADDRESS_1_VIRTUALFIELD');
        const beneBankAcctNumberFreeForm = form.field('BENE_BANK_ACCOUNT_NUMBER_VIRTUALFIELD');
        const corrId = form.field('CORRESPONDENT_ID');
        const isBeneBankFreeform = beneBankIdEntryMethod && beneBankIdEntryMethod.$el.length > 0 && model.get('BENEBANKIDENTRYMETHOD') === 'FREEFORM';
        const beneAccountNumberMandatory = model.fieldData.BENE_BANK_ACCOUNT_NUMBER
            ? (model.fieldData.BENE_BANK_ACCOUNT_NUMBER.mandatory && isBeneBankFreeform) : false;

        this.toggleBeneBankValidator(!isBeneBankFreeform, addressValidator, model, 'BENE_BANK_ACCOUNT_NUMBER');
        this.toggleBeneBankValidator(!isBeneBankFreeform, accountValidator, model, 'BENE_BANK_ADDRESS_1');
        beneBankId
            .shouldBeRequiredWhen(!isBeneBankFreeform)
            .shouldBeVisibleWhen(!isBeneBankFreeform || form.formView.state === 'view')
            .$el.parent().toggleClass('required', !isBeneBankFreeform);
        beneBankName
            .shouldBeRequiredWhen(isBeneBankFreeform)
            .shouldBeVisibleWhen(isBeneBankFreeform);
        beneBankAddress1FreeForm
            .shouldBeRequiredWhen(isBeneBankFreeform)
            .shouldBeVisibleWhen(isBeneBankFreeform)
            .$el.parent().toggleClass('required', isBeneBankFreeform);
        beneBankAcctNumberFreeForm
            .shouldBeVisibleWhen(isBeneBankFreeform)
            .shouldBeRequiredWhen(beneAccountNumberMandatory)
            .$el.parent().toggleClass('required', beneAccountNumberMandatory);
        if (corrId) {
            corrId.shouldBeRequiredWhen(model.fieldData.CORRESPONDENT_ID
                && model.fieldData.CORRESPONDENT_ID.mandatory
                && isBeneBankFreeform)
                .$el.parent().toggleClass(
                    'required',
                    (model.fieldData.CORRESPONDENT_ID
                    && model.fieldData.CORRESPONDENT_ID.mandatory
                    && isBeneBankFreeform),
                );
        }
        this.showHideAddr(form, 'BENE_BANK_', true, isBeneBankFreeform, true);
    },

};
