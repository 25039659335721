import $ from 'jquery';
import ViewHelper from 'common/dynamicPages/api/viewHelper';
import util from '@glu/core/src/util';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import ContextApi from 'common/dynamicPages/api/context';

export default function (
    form,
    initialState,
) {
    const view = form.formView;
    const formState = view.state;
    const fromAccount = form.field('ACCOUNT_NUMBER');
    const typeDescription = form.field('TYPE_DESCRIPTION');
    const issueVoidFlag = form.field('ISSUEVOIDFLAG');
    const issueVoidDate = form.field('ISSUEVOIDDATE');
    const serialNumber = form.field('SERIALNUMBER');
    const amount = form.field('AMOUNT');
    const { model } = view;
    const fromPayAndIssue = formState === 'modify' && model.get('PAYISSUE_FLAG') === 'Y';
    let drp;
    const allFields = ['ACCOUNT_NUMBER', 'PAYEE', 'AMOUNT', 'ISSUEVOIDDATE', 'SERIALNUMBER', 'MEMO'];
    const payeeNameLengthMax = appConfigParams.getValue('CMCIM', 'PAYEENAMELENGTH');

    const showHide = function (flag, field) {
        if (flag) {
            field.$el.parent().removeClass('hidden');
        } else {
            field.$el.parent().addClass('hidden');
        }
    };
    const showRequiredOptions = function (flag, field) {
        if (flag) {
            field.$el.parent().addClass('required');
        } else {
            field.$el.parent().removeClass('required has-error');
            field.$el.next().text('');
        }
    };
    const setupFieldDefinition = function (issueVoidModel) {
        model.validators = issueVoidModel.validators;
        let vrealField;
        let visMandatory;
        let visVisible;
        util.each(allFields, (vfieldName) => {
            vrealField = form.field(vfieldName);
            const fields = util.findWhere(
                issueVoidModel,
                {
                    name: vfieldName,
                },
            );
            visMandatory = fields.mandatory;
            visVisible = fields.visible;
            if (vrealField) {
                showHide(visVisible, vrealField);
                showRequiredOptions(visMandatory, vrealField);
            }
        });
    };
    const changeFieldDefinition = function (isVoidSelected) {
        if (isVoidSelected) {
            model.set('PAYEE', '');
            if (model.voidFields) {
                setupFieldDefinition(model.voidFields);
            }
        } else if (model.issueFields) {
            setupFieldDefinition(model.issueFields);
        }
    };

    if (initialState) {
        let isVoid;
        const contextDefIssues = ContextApi.menuContext.getContext('CM_IV_LIST');
        const contextDefVoids = ContextApi.menuContext.getContext('CM_IV_LIST');
        if (issueVoidDate.isNotEmpty()) {
            view.processingDates.daysForward = [];

            // NH-35034 - Check for the DRP before using it.
            drp = issueVoidDate.$el.data('daterangepicker');

            if (drp) {
                drp.updateCalendars({
                    blockedDates: [],
                    processingDays: [],
                    daysForward: false,
                    cutOffTimes: [],
                    daysBack: false,
                });
            }
        }
        contextDefIssues.subType = 'ISSUE';
        const issuePromise = ViewHelper.getModel({
            context: contextDefIssues,
            hideButtons: true,
        });

        contextDefVoids.subType = 'VOID';
        const voidPromise = ViewHelper.getModel({
            context: contextDefVoids,
            hideButtons: true,
        });
        Promise.all([
            issuePromise,
            voidPromise,
        ]).then(([issues, voids]) => {
            model.issueFields = issues.fieldData;
            model.issueFields.validators = issues.validators;
            model.voidFields = voids.fieldData;
            model.voidFields.validators = voids.validators;
            isVoid = model.get('ISSUEVOIDFLAG') === 'VOID';
            changeFieldDefinition(isVoid);
        });

        if (formState === 'insert') {
            // Hide the audit section
            form.field('ENTERED_TIMESTAMP').$el.closest('.section').hide();
        }

        if (formState === 'modify') {
            fromAccount.shouldBeReadOnly(true);
            typeDescription.shouldBeReadOnly(true);
        }
    }

    // Make fields read only when issue is created from a 'pay and issue' decision in pos pay.
    amount.shouldBeReadOnlyWhen(fromPayAndIssue);
    serialNumber.shouldBeReadOnlyWhen(fromPayAndIssue);
    issueVoidFlag.shouldBeReadOnlyWhen(fromPayAndIssue);
    $('#PAYEE').attr('maxlength', payeeNameLengthMax);
}
