import React from 'react';
import PropTypes from 'prop-types';
import getUserGroups from './util';
import DropdownRemoteFilter from '../DropdownRemoteFilter/DropdownRemoteFilter';

const propTypes = {
    onFilter: PropTypes.func,
    name: PropTypes.string.isRequired,
};

const defaultProps = {
    onFilter: getUserGroups,
};

const UserGroupSelector = ({
    onFilter,
    name,
    ...props
}) => (
    <DropdownRemoteFilter
        onFilter={onFilter}
        htmlId={`user-group-selector-${name}`}
        name={name}
        {...props}
    />
);
UserGroupSelector.propTypes = propTypes;
UserGroupSelector.defaultProps = defaultProps;

export default UserGroupSelector;
