import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import systemConfig from 'system/configuration';
import pmxConst from 'no-paymode!common/paymodeIntegration/constants';
import {
    getComponentByIdAsView,
    getUtilById,
} from 'no-paymode!common/paymodeIntegration/paymodeIntegration';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import pmxRemittanceWidgetTmpl from './pmxRemittanceWidget.hbs';

export default BaseWidget.extend({
    template: pmxRemittanceWidgetTmpl,
    className: 'ui-widget field-container pmx-field-container',
    exceptions: [],
    pmxRemittanceRows: [],

    initialize(options) {
        /*
         * call base to init model, parentModel, readyState, fieldName, etc (note
         * from other widget)
         */
        BaseWidget.prototype.initialize.call(this, options);
        this.fieldData = this.parentModel.fieldData;
        this.model = new Model();
    },

    onRender() {
        Promise.all([
            getComponentByIdAsView('paymentDetail.remittanceInfoSection'),
            getUtilById('paymentDetail.styles'),
        ]).then(this.handleRemittanceComponent.bind(this));
    },

    /**
     * handleRemittanceComponent
     *
     * @param {[View, Object]} results - Promise.all results
     */
    handleRemittanceComponent([RemittancesView, styles]) {
        // paymentscommon.customer_reference is the paymentID (DPA)
        const urlPmxRemittances = serverConfigParams.get('pmxPaymodeRemittancesRestEndpoint');
        let remittancesView;

        const postData = {
            paymentId: this.parentModel.get('PAYMENT_EXTERNAL_ID'),
            unmaskValues: true,
            unmaskPCIValues: true,
        };

        const self = this;
        let url;

        if (systemConfig.isAdmin()) {
            url = services.generateAdminServletUrl(pmxConst.PMX_PROXY_SERVLET
                + urlPmxRemittances);
            if (this.parentModel.get('MEMBERSHIPID')) {
                postData.membershipId = this.parentModel.get('MEMBERSHIPID');
            } else {
                postData.userGroup = this.parentModel.get('USERGROUP');
            }
        } else {
            url = services.generateServletUrl(pmxConst.PMX_PROXY_SERVLET + urlPmxRemittances);
            postData.membershipId = userInfo.getMembershipID();
        }

        /*
         * Add the header pmx to the request to indicate we will making a call to the
         * PMX server using the proxyServlet
         */
        const requestOptions = util.extend(
            {},
            {
                beforeSend(request) {
                    request.setRequestHeader('pmx', true);
                },
            },
        );

        const pmxRemittanceWidgetPromise = new Promise((resolve, reject) => {
            http.post(url, postData, (result) => {
                resolve(result);
            }, (status) => {
                reject(`${status.status} ${status.statusText}`);
            }, requestOptions);
        });

        pmxRemittanceWidgetPromise.then(
            (data) => {
                remittancesView = new RemittancesView({
                    toggleMasking() {

                    },

                    masked: false,
                    canUnmask: data.unmaskRDValues || false,
                    paymentId: data.paymentDetail.paymentId,
                    showRemittanceSummary: data.showRemittanceSummary,
                    currencyCode: data.paymentDetail.currencyCode,
                    remittanceMaps: data.remitInfo.remitMaps,
                    remittances: data.remitInfo.remittances,
                });

                self.regionManager.addRegion('pmxremittanceRegion', '.pmx-remittance');

                self.$('.pmx-remittance').addClass(styles.paymentDetail);
                self.pmxremittanceRegion.show(remittancesView);
            },
            () => {
            // do nothing - just recieve exception
            },
        );
    },
});
