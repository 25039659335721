import ItemView from '@glu/core/src/itemView';
import userViewTmpl from './userView.hbs';

const UserView = ItemView.extend({
    template: userViewTmpl,

    events: {
        'click input': 'toggleSelected',
    },

    templateHelpers() {
        return {
            hx: 'h4',
            userId: this.model.get('USERID'),
            userName: this.model.get('USERNAME'),
            userGroup: this.model.get('USERGROUP'),
            userGroupName: this.model.get('GROUPNAME'),
        };
    },
});

export default UserView;
