/* eslint-disable */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disabled react/react-hooks/rules-of-hooks */

import React, {
    useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { ComposedGrid } from '@glu/grid-react';
import locale from '@glu/locale';
import { Button, TERTIARY } from '@glu/buttons-react';
import Popover from '@glu/popover-react';
import urls from 'ptxReact/system/urls';
import downloadFile from 'ptxReact/system/downloadFile';
import { paths } from 'ptxReact/system/routes';
import { defaultStrings } from 'ptxReact/app/client/pages/documentProfile/defaults/defaults';
import useStyles from 'ptxReact/app/client/pages/styles/Styles';
import { useGridColumnStringSorter } from 'ptxReact/app/client/pages/utilities/utilities';
import gridDateColumn from 'ptxReact/app/client/pages/utilities/gridDateColumn';

const savedData = JSON.parse(localStorage.getItem('profileDomLogs'));

const domLogs = ({
    returnedData,
    totalRecords,
    getDomLogs,
    profileType: type,
}) => {
    const { cheque } = defaultStrings;
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortBy, setSortBy] = useState(savedData?.sortBy || {
        sortField: null,
        sortOrder: null,
    });
    const [firstPass, setFirstPass] = useState(true);
    const classes = useStyles();

    const columnDefs = [{
        field: 'name',
        cellRenderer: 'LinkToBatch',
        headerName: locale.get('name'),
        sortable: true,
        flex: 1,
        comparator: useGridColumnStringSorter,
        lockPosition: true,
    },
    {
        field: 'profileDescription',
        headerName: locale.get('description'),
        sortable: true,
        flex: 1,
        comparator: useGridColumnStringSorter,
    },
    {
        field: 'creationDate',
        headerName: locale.get('creationDate'),
        sortable: true,
        flex: 1,
        ...gridDateColumn,
    },
    {
        field: 'documentTotalCount',
        headerName: locale.get('documentsCount'),
        sortable: true,
        flex: 1,
        comparator: useGridColumnStringSorter,
    },
    {
        field: 'mailingStatus',
        headerName: locale.get('status'),
        sortable: true,
        flex: 1,
        comparator: useGridColumnStringSorter,
    },
    {
        field: 'testMode',
        headerName: locale.get('testMode'),
        sortable: true,
        flex: 0.75,
        comparator: useGridColumnStringSorter,
    },
    {
        field: 'projectType',
        headerName: locale.get('projectType'),
        sortable: true,
        flex: 1,
        comparator: useGridColumnStringSorter,
    },
    {
        field: 'downloadLogFile',
        cellRenderer: 'DownloadLogLink',
        headerName: locale.get('downloadLogFile'),
        sortable: false,
        flex: 1,
        comparator: useGridColumnStringSorter,
    },
    {
        field: 'downloadInputFile',
        cellRenderer: 'DownloadInputLink',
        headerName: locale.get('downloadInputFile'),
        sortable: false,
        flex: 1,
        comparator: useGridColumnStringSorter,
    }];

    const downloadLogFile = (batchId, domLogId) => {
        downloadFile.downloadByUrl(urls.downloadDomLogFile.params({
            batchId,
            domLogId,
        }));
    };

    const downloadInputFile = (batchId, domLogId) => {
        downloadFile.downloadByUrl(urls.downloadDomLogInputFile.params({
            batchId,
            domLogId,
        }));
    };

    const defaultColDef = {
        resizable: true,
    };

    useEffect(() => {
        if (!firstPass) {
          const pagination = {
            pageNum,
            pageSize,
            sortBy
          };
          localStorage.setItem('profileDomLogs', JSON.stringify(pagination));
        }
      }, [pageNum, pageSize, sortBy]);

    useEffect(() => {
        let defaultPageNum = pageNum;
        let defaultPageSize = pageSize;
        if (savedData) {
            defaultPageNum = savedData.pageNum;
            defaultPageSize = savedData.pageSize;
        }
        setPageNum(defaultPageNum);
        setPageSize(defaultPageSize);
        setFirstPass(false);
    }, []);

    useEffect(() => {
        getDomLogs(pageNum, pageSize, sortBy);
      }, [pageNum, pageSize, sortBy]);

    const onChange = (e) => {
        if (e.name === 'sort') {
            const sortField = e.value[0]?.colId;
            const sortOrder = e.value[0]?.sort.toUpperCase();
            setSortBy({ sortField, sortOrder });
        }
        if (e.name === 'pageSize') {
            setPageSize(e.value);
        }
        if (e.name === 'page') {
            setPageNum(e.value + 1);
        }
    };

    const defaultState = useMemo(
        () => ({
            sort: [{
                colId: sortBy.sortField || null,
                sort: sortBy.sortOrder?.toLowerCase() || null,
            }],
            page: pageNum - 1 || 0,
            pageSize: pageSize || 10,
            totalItems: totalRecords,
        }),
        [totalRecords, pageNum, pageSize, sortBy],
    );

    return (
        <>
            <ComposedGrid
                gridId="ProfileLogssGrid"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                records={returnedData}
                onChange={onChange}
                domLayout="autoHeight"
                footerSticky={false}
                defaultState={defaultState}
                paginationTotalItems={totalRecords}
                footerProps={{
                    paginationTotalItems: totalRecords
                }}
                serverSidePagination
                disableSessionStorage
                frameworkComponents={{
                    DownloadLogLink: cellProps => <Button variant={TERTIARY} className={classes.domLinkButton} text={locale.get('downloadLogFile')} onClick={() => downloadLogFile(cellProps.data.batchId, cellProps.data.id)} />,
                    DownloadInputLink: cellProps => <Button variant={TERTIARY} className={classes.domLinkButton} text={locale.get('downloadInputFile')} onClick={() => downloadInputFile(cellProps.data.batchId, cellProps.data.id)} />,
                    LinkToBatch: cellProps => (
                        <>
                            <a
                                href={type !== cheque
                                    ? paths.outboxByMailingName(cellProps.data.name.trim().replace(/\s/g, '%20')).replace('ui/documents','ui-admin-portal/ADMINSTRATION/documents')
                                    : paths.chequeBatchById(cellProps.data.batchId).replace('ui/documents','ui-admin-portal/ADMINSTRATION/documents')}
                                className={classes.linkToBatchAnchor}
                            >
                                { cellProps.value }
                                <Popover
                                    content={locale.get('showDocumentsInBatch')}
                                    openOnHover
                                    placement="right"
                                    className={classes.linkToBatchPopover}
                                >
                                    <div className={classes.linkToBatchPopoverArea} />
                                </Popover>
                            </a>
                        </>
                    ),
                }}
                // hiding exports pending a decision by the product owners
                hideExport
            />
            <div id="#download-frame" />
        </>
    );
};

domLogs.defaultProps = {
    totalRecords: undefined,
    profileType: undefined,
};

domLogs.propTypes = {
    returnedData: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalRecords: PropTypes.number,
    getDomLogs: PropTypes.func.isRequired,
    profileType: PropTypes.string,
};

export default domLogs;
