var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog\">\n    <form class=\"modal-content\">\n        <div class=\"modal-header\" id=\"fi-detail-modal-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":62},"end":{"line":3,"column":68}}}) : helper)))
    + "\">\n            "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"close","action":"closeModal","text":"Close","name":"close"},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":85}}}))
    + "\n            <h2 class=\"modal-title\" id=\"modal-title\"><span class=\"indicator-icon\"> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"fih.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":83},"end":{"line":5,"column":107}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"getFileName") || (depth0 != null ? lookupProperty(depth0,"getFileName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getFileName","hash":{},"data":data,"loc":{"start":{"line":5,"column":108},"end":{"line":5,"column":123}}}) : helper)))
    + "</h2>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"panel panel-primary\" id=\"failedRecordsPanel\">\n                <div class=\"panel-heading\" role=\"tab\" id=\"fi-detail-failed-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":83},"end":{"line":9,"column":89}}}) : helper)))
    + "\" data-hook=\"failedRecords\">\n                    <h3 class=\"panel-title\">\n                        <a role=\"button\" data-toggle=\"collapse\" href=\"#fi-detail-failed-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":93},"end":{"line":11,"column":99}}}) : helper)))
    + "\" aria-expanded=\"true\" aria-controls=\"fi-detail-failed-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":159},"end":{"line":11,"column":165}}}) : helper)))
    + "\">\n                            <span class=\"indicator-icon\"></span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"fih.failedRecords",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":65},"end":{"line":12,"column":97}}}))
    + "\n                        </a>\n                    </h3>\n                </div>\n                <div id=\"fi-detail-failed-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":53}}}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"fi-detail-failed-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":16,"column":148},"end":{"line":16,"column":154}}}) : helper)))
    + "\">\n                    <div id=\"failedRecords\" class=\"panel-body\"></div>\n                </div>\n            </div>\n            <div class=\"panel panel-primary\" id=\"successfulImportsPanel\" style=\"display: none;\">\n                <div class=\"panel-heading\" role=\"tab\" id=\"fi-detail-successful-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":21,"column":87},"end":{"line":21,"column":93}}}) : helper)))
    + "\" data-hook=\"successfulImports\">\n                    <h3 class=\"panel-title\">\n                        <a role=\"button\" data-toggle=\"collapse\" href=\"#fi-detail-successful-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":23,"column":97},"end":{"line":23,"column":103}}}) : helper)))
    + "\" aria-expanded=\"true\" aria-controls=\"fi-detail-successful-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":23,"column":167},"end":{"line":23,"column":173}}}) : helper)))
    + "\">\n                            <span class=\"indicator-icon\"></span> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"fih.successfulImports",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":101}}}))
    + "\n                        </a>\n                    </h3>\n                </div>\n                <div id=\"fi-detail-successful-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":28,"column":51},"end":{"line":28,"column":57}}}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"fi-detail-successful-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":28,"column":156},"end":{"line":28,"column":162}}}) : helper)))
    + "\">\n                    <div id=\"successfulImports\" class=\"panel-body\"></div>\n                </div>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"button\" class=\"btn btn-secondary\" data-action=\"closeModal\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.close",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":85},"end":{"line":34,"column":110}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});