import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import FailedRecordsGrid from 'app/administration/views/fileImportHistory/failedRecords/failedRecordsListView';
import SuccessfulImportsGrid from 'app/administration/views/fileImportHistory/successfulImports/successfulImportsListView';
import workspaceHelper from 'common/workspaces/api/helper';
import Model from '@glu/core/src/model';
import fiDetailTmpl from './fiDetail.hbs';

export default Layout.extend({
    template: fiDetailTmpl,

    initialize(options) {
        this.model = options.model || new Model();
    },

    handleBack() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    templateHelpers() {
        return {
            getString(code) {
                return locale.get(code);
            },

            getFileName() {
                return store.get('fileOriginalName');
            },
        };
    },

    allGridsLoadComplete() {
        if (store.get('failedRecordsGridLoaded') === true && store.get('successfulImportsGridLoaded') === true) {
            store.set('failedRecordsGridLoaded', false);
            store.set('successfulImportsGridLoaded', false);
        }
    },

    onRender() {
        this.listenTo(this, 'bothGridsLoaded', this.allGridsLoadComplete);

        const options = {
            parent: this,
        };

        this.successfulImportsGrid = new SuccessfulImportsGrid(options);
        this.failedRecords.show(new FailedRecordsGrid(options));
        this.successfulImports.show(this.successfulImportsGrid);
    },
});
