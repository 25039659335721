import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import constants from 'app/balanceAndTransaction/constants';

const AccountTransactionBalance = Model.extend({
    defaults: {
        filters: [],
        accountType: '',
    },

    /**
     * @method getServiceUrlFromAccountType
     * @return {string} serviceURL
     * Returns a service url to fetch account transaction balance based on account
     * type passed in model
     */
    getServiceUrlFromAccountType(accountType) {
        if (util.contains(constants.ACCOUNT_TYPE.LOAN, accountType)) {
            return services.generateUrl(constants.ACCT_TRANSACTION_BALANCE_SERVICE.LOAN);
        }
        if (util.contains(constants.ACCOUNT_TYPE.CREDIT_CARD, accountType)) {
            return services.generateUrl(constants.ACCT_TRANSACTION_BALANCE_SERVICE.CREDIT_CARD);
        }
        return services.generateUrl(constants.ACCT_TRANSACTION_BALANCE_SERVICE.DEPOSIT);
    },

    /**
     * Function to save or get data from server
     * @param  {string} method Action to perform
     * @param  {object} model model object affected
     * @param  {object} options object containing success and error callbacks
     * (parse by default)
     * @return {promise} http promise call
     */
    sync(method, model, options) {
        if (method === 'read') {
            const url = this.getServiceUrlFromAccountType(this.get('accountType'));

            const data = {
                requestParameters: {
                    item: [{
                        name: 'isRealTime',
                        value: model.get('isRealTime'),
                    }],
                },
                searchFields: this.get('filter'),
            };

            return http.post(url, data, (response) => {
                options.success(response);
            }, () => {
                options.error();
            });
        }
        // TODO: Return rejected Promise for bad method?
        return undefined;
    },

    /**
     * Default Function to modify data fetched from service
     * @param  {object} response data from service
     * In this case, the response is an object where each column item is a property of
     * the object
     * "key - value pairs" where {"key => label" : "value => numeric value" }
     *
     * The ASOFTIME data that usually holds the "Balance as of" column
     * will need to be extracted and put on a top level since the UI design calls for
     * presenting that column first and above all other columns
     */
    parse(response) {
        // parse out 'As Of Date' value and move into its own top level
        const asOfTimeKey = constants.AS_OF_TIME;

        const columns = util.omit(response, asOfTimeKey);
        let asOfTime;

        // NEED TO EDIT AFTER SERVICE CHANGE
        try {
            asOfTime = JSON.parse(response[asOfTimeKey]);
        } catch (e) {
            asOfTime = null;
        }

        return {
            asOfTime,
            columns,
        };
    },
});

export default AccountTransactionBalance;
