import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';

export default Model.extend({
    initialize(options) {
        this.tnums = options.tnums;
    },
    sync(method, model, options) {
        const data = {
            item: this.tnums,
        };
        const readService = services.generateUrl('payment/transfer') + constants.URL_POLL_PAY_STATUS;
        http.post(readService, data, (response) => {
            options.success(response);
        }, () => {
            options.error();
        });
    },
    parse(response) {
        const obj = {};
        util.each(response.item, (nvp) => {
            obj[nvp.name] = nvp.value;
        });
        return obj;
    },
});
