import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import services from 'services';
import transform from 'common/util/transform';
import constants from 'app/balanceAndTransaction/constants';
import dynamicConstants from 'common/dynamicPages/api/constants';

const AvailableTOALocations = Model.extend({
    initialize() {
        this.url = services.generateUrl(dynamicConstants.URL_GETLOOKUPRESULTS_ACTION);
        this.filterVal = '';
        this.rowsPerPage = dynamicConstants.COMBO_MIN_SIZE;
        this.hasMorePages = false;
        this.request = this.createDefault();
    },

    /**
     * @method createDefault
     * @return {object} service request object
     * Creates service param data needed to get data on nonRealTimeAccounts count
     */
    createDefault() {
        const requestParameters = {
            INQUIRYID: constants.INQUIRYID_AVAILABLE_TOA_LOCATIONS,
        };

        const data = {
            rowsPerPage: this.rowsPerPage,
            startRow: this.startRow || 1,
            fieldName: 'LOCATION',
            typeCode: 'GIRACCT',
            functionCode: 'INST',
            productCode: 'GIR',

            requestParameters: {
                item: transform.hashToPairs(requestParameters),
            },
        };

        if (this.filterVal) {
            data.searchFields = [{
                fieldName: 'LOCATION',
                operator: 'CONTAINS',
                fieldValue: [this.filterVal],
                dataType: 'text',
            }];

            data.searchOrOperator = true;
        }

        return data;
    },

    getRequest() {
        return this.createDefault();
    },

    send() {
        return http.post(this.url, this.getRequest())
            .then(this.parseResponse, this.handleError);
    },

    setSearch(str) {
        this.filterVal = str;
    },

    setSearchOperator(str) {
        this.request.searchFields[0].operator = str;
    },

    /**
     * Function to save or get data from server
     * @param  {string} method Action to perform
     * @param  {object} model model object affected
     * @param  {object} options object containing success and error callbacks
     * (parse by default)
     * @returns {Promise} - jQuery XHR Promise
     */
    sync(method, model, options) {
        if (method === 'read') {
            const data = this.createDefault();
            http.post(
                this.url,
                data,
                (result) => {
                    this.hasMorePages = (this.startRow + result.rows.length) <= result.totalRows;
                    options.success(result);
                    this.startRow += this.rowsPerPage;
                }, (result) => {
                    options.error(result);
                },
            );
        }
        return undefined;
    },

    /**
     * Default Function to modify data fetched from service
     * @param  {object} response data from service
     * @returns {Object} parsed data from service
     */
    parse(response) {
        return {
            locations: response.rows
                .map(row => transform
                    .pairsToHash(row.columns, 'fieldName', 'fieldValue')),
        };
    },
});

export default AvailableTOALocations;
