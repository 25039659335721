import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default Model.extend({
    defaults: {
        VALUE_DATE: '',
        VALUE_TRANSACTION_TYPES: '',
        VALUE_LOCKBOXLIST: '',
    },

    initialize() {
        this.validators = {
            VALUE_DATE: {
                description: locale.get('reports.value.date'),
                exists: true,
            },

            VALUE_LOCKBOXLIST: {
                description: locale.get('reports.filtered.lockbox'),
                exists: true,
            },

            VALUE_TRANSACTION_TYPES: {
                description: locale.get('reports.transaction.type'),
                exists: true,
            },

            LBXMAXDAYS: {
                description: locale.get('reports.days.selected'),
            },
        };
    },

    setMaxDays(max) {
        this.validators.LBXMAXDAYS.maxValue = max;
    },
});
