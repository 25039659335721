import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import dialog from '@glu/dialog';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import ReasonCellView from 'app/payments/views/exceptionReport/reasonCellView';
import workspaceHelper from 'common/workspaces/api/helper';
import recurListViewTmpl from './recurListView.hbs';

const RecurPaymentsList = ListView.extend({
    template: recurListViewTmpl,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: '/recPayExceptions',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            context: 'RECURPAY_VIEW',
            returnRoute: 'REPORTING/viewRecurPayExceptions',
            selector: 'none',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    loadViewRequirements() {
        const promise = ListView.prototype.loadViewRequirements.call(this);
        const self = this;
        return promise.then(() => {
            self.listenTo(self.gridView.wrapper.columns, 'sync', self.handleColumnSync);
        });
    },

    export() {
        this.buildExportModel('CSV');
        this.doExport();
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: `${this.localeKey}title`,
        });
        dialog.custom(printModal);
    },

    handleColumnSync(columns) {
        const model = columns.findWhere({
            field: 'REASON',
        });
        model.set('type', ReasonCellView);
    },

    buildExportModel(format) {
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = '43030';
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'RECURPAY_VIEW',
    view: RecurPaymentsList,
    options: {},
});

export default RecurPaymentsList;
