import locale from '@glu/locale';
import { appBus, log } from '@glu/core';
import $ from 'jquery';
import services from 'services';
import http from '@glu/core/src/http';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import warningTextTemplate from 'common/dynamicPages/views/mdf/componentTemplates/warningText.hbs';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default function (formParam, initialState) {
    const form = formParam;
    const { model } = form.formView;
    let mPatternMode = model.get('PATTERNMODE');
    let cPatternMode = '';
    let mDailyMode = model.get('DAILYMODE');
    let cDailyMode = '';
    let mMonthlyMode = model.get('MONTHLYMODE');
    let cMonthlyMode = '';
    let mYearlyMode = model.get('YEARLYMODE');
    let cYearlyMode = '';
    let mEndMode = model.get('ENDMODE');
    let cEndMode = '';
    let mNonBizDaysMode = model.get('NONBUSINESSDAYMODE');
    let cNonBizDaysMode = '';
    const $weeklyWeeksLabel = $('label[for="WEEKLYWEEKS"]');
    const gr21dailyMode = form.field('_DAILYMODE');
    const gr21dailyDays = form.field('DAILYDAYS');
    const gr22weeklyweeks = form.field('WEEKLYWEEKS');
    const gr22sunday = form.field('WEEKLYDAYSUN');
    const gr22monday = form.field('WEEKLYDAYMON');
    const gr22tuesday = form.field('WEEKLYDAYTUE');
    const gr22wednesday = form.field('WEEKLYDAYWED');
    const gr22thursdays = form.field('WEEKLYDAYTHURS');
    const gr22friday = form.field('WEEKLYDAYFRI');
    const gr22saturday = form.field('WEEKLYDAYSAT');
    const gr23monthlymode = form.field('_MONTHLYMODE');
    const gr23monthlyday1 = form.field('MONTHLYDAY1');
    const gr23monthlymonth1 = form.field('MONTHLYMONTH1');
    const gr23monthlydaynumber = form.field('MONTHLYDAYNUMBER');
    const gr23monthlydaytype = form.field('MONTHLYDAYTYPE');
    const gr23monthlymonthnumber = form.field('MONTHLYMONTHNUMBER');
    const gr24yearlymode = form.field('_YEARLYMODE');
    const gr24yearlydaynumber = form.field('YEARLYDAYNUMBER');
    const gr24yearlydaytype = form.field('YEARLYDAYTYPE');
    const gr24yearlymonthnumber = form.field('YEARLYMONTHNUMBER');
    const gr24yearlymonth1 = form.field('YEARLYMONTH1');
    const gr24yearlyday1 = form.field('YEARLYDAY1');
    let postData = '';
    const gr44eventname = form.field('EVENTNAME');
    const cnt3endcycles = form.field('ENDCYCLES');
    const cnt3endingeffectivedate = form.field('ENDINGEFFECTIVEDATE');

    const handleBusinessDaysResponse = function (result) {
        let { defaultDay } = result;
        defaultDay = moment(defaultDay);
        defaultDay = defaultDay.format(userInfo.getDateFormat());

        form.formView.processingDates.daysForward.shift();
        form.formView.processingDates.daysForward.push(result.maxForwardDays);

        form.formView.processingDates.processingDays.shift();
        form.formView.processingDates.processingDays.push(result.businessDays);

        form.formView.processingDates.cutOffTimes.shift();
        form.formView.processingDates.cutOffTimes.push(result.cutoff);

        // remove previous blocked dates
        form.formView.processingDates.blockedDates
            .splice(0, form.formView.processingDates.blockedDates.length);
        if (result.holidays.length > 0) {
            form.formView.processingDates.blockedDates.push(...result.holidays);
        }

        const key = $(form.formView.$el.find('#STARTINGEFFECTIVEDATE')).attr('name');
        const dateOptions = {
            blockedDates: form.formView.processingDates.blockedDates,
            processingDays: form.formView.processingDates.processingDays,
            cutOffTimes: form.formView.processingDates.cutOffTimes,
            showCalendarIcon: true,
            daysBack: moment(new Date()).diff(moment(defaultDay), 'days'),
        };
        form.formView.$el.find('#STARTINGEFFECTIVEDATE').nhDatePicker(dateOptions, (start, end) => {
            // eslint-disable-next-line
            form.formView.model._datePickerValues[key] = [start, end];
        });
        model.set('STARTINGEFFECTIVEDATE', moment(result.defaultDay).format(userInfo.getDateFormat()));
    };

    const setupScheduleDate = function (dateEl) {
        const key = $(dateEl).attr('name');
        const dateOptions = {
            processingDays: '1111111',
            showCalendarIcon: true,
            daysBack: 0,
        };
        dateEl.nhDatePicker(dateOptions, (start, end) => {
            // eslint-disable-next-line
            form.formView.model._datePickerValues[key] = [start, end];
        });
    };

    if (initialState) {
        if (serverConfigParams.get('EnableSaveAlertAuthorizationMessage') === 'true') {
            form.alertView = alert.warning();
            form.formView.$el.before(form.alertView(locale.get('EnableSaveAlertAuthorizationMessage'), { canDismiss: false }).render().el);
        }

        $('body').addClass('no-form-branding');
        form.formView.on('close', () => {
            $('body').removeClass('no-form-branding');
        });

        if (model.get('PSTATUS') !== 'AP') {
            $('.section-summary .section-body').before(`<div class="section-header"><span class="icon-warning" aria-hidden="true"></span>${locale.get('ACH.SchedulePayWarning', model.get('TEMPLATECODE'))}</div>`);
        }
        if (model.get('NEXTVALUEDATE')) {
            const nextValHTMLStr = warningTextTemplate({
                warningMsg: locale.get('ACH.Schedule.NextStartingDate', model.get('NEXTVALUEDATE')),
            });
            $('.USACH_MAINT_ACH_SCHE_Container3_Group3_2').before(nextValHTMLStr);
        }
        if (!model.get('STARTINGEFFECTIVEDATE')) {
            postData = {
                paymentType: model.get('PTYPE'),
                debitBank: model.get('PBANKCODE'),
                debitCurrency: model.get('PDEBIT_CURRENCY'),
                debitBankCountry: model.get('PDEBIT_COUNTRY'),
                creditCurrency: model.get('PDEBIT_CURRENCY'),
                subType: model.get('PSUBTYPE'),
                dateProductType: 'SCHEDULED',
                tnum: model.get('TNUM'),
            };
            const dateService = services.generateUrl('/date/list');

            http.post(dateService, postData, (result) => {
                handleBusinessDaysResponse(result);
            }, (err) => {
                log.error(err);
            });
        } else {
            setupScheduleDate(form.formView.$el.find('#STARTINGEFFECTIVEDATE'));
        }
        setupScheduleDate(form.formView.$el.find('#ENDINGEFFECTIVEDATE'));

        /*
         * START NH-12874
         * Adjust the field orientation so it reads as a user would expect.
         */
        gr21dailyDays.$el.addClass('pull-left');
        gr22weeklyweeks.$el.addClass('input-inline');
        gr23monthlyday1.$el.addClass('pull-left');
        gr23monthlymonth1.$el.addClass('pull-left');
        gr23monthlydaynumber.$el.addClass('pull-left').parent().addClass('clear-left');
        gr23monthlydaytype.$el.addClass('pull-left');
        gr23monthlymonthnumber.$el.addClass('pull-left');
        gr24yearlymonth1.$el.parent().addClass('clear-left');

        // Adjust the checkboxes so they are not jumbled.
        $('#_DAILYMODE, #_MONTHLYMODE, #_YEARLYMODE').find('.radio-inline').removeClass('radio-inline').addClass('radio-stacked');

        // Replace the ellipsis in the label with a span placeholder.
        const weeklyWeeksLabelText = $weeklyWeeksLabel.text().replace('...', '<span></span>');
        /*
         * Detach (remove, but keep associated data) the Weekly Weeks field and place
         * it inside the label.
         */
        $weeklyWeeksLabel.html(weeklyWeeksLabelText).find('span').html(gr22weeklyweeks.$el.detach());

        // Now that we've manipulated the label, we need to make sure its hidden.
        $weeklyWeeksLabel.hide();
        gr22weeklyweeks.shouldBeHidden();

        /*
         * Wrap the non-checkbox fields with a wrapper
         * to allow appropriate positioning and styling.
         */
        $('.USACH_MAINT_ACH_SCHE_Container2_Group2_3 .field-container:gt(1)').wrapAll('<div class="field-container-wrap pull-left inline-fields" />');
        $('.USACH_MAINT_ACH_SCHE_Container2_Group2_4 .field-container:gt(1)').wrapAll('<div class="field-container-wrap pull-left inline-fields" />');
        // END NH-12874
    }

    if ($('[id="_PATTERNMODE-O"]').prop('checked') === true) {
        cPatternMode = 'O';
    } else if ($('[id="_PATTERNMODE-D"]').prop('checked') === true) {
        cPatternMode = 'D';
    } else if ($('[id="_PATTERNMODE-W"]').prop('checked') === true) {
        cPatternMode = 'W';
    } else if ($('[id="_PATTERNMODE-M"]').prop('checked') === true) {
        cPatternMode = 'M';
    } else if ($('[id="_PATTERNMODE-Y"]').prop('checked') === true) {
        cPatternMode = 'Y';
    }

    if (cPatternMode.length > 0) {
        mPatternMode = cPatternMode;
        model.set('PATTERNMODE', mPatternMode);
    } else {
        $('[name="_PATTERNMODE"][value="O"]').prop('checked', true);
    }

    if (model.context.actionData.productCode === 'RTGS') {
        gr44eventname.shouldBeHidden();
        gr44eventname.removeValidator('exists');
    }

    gr24yearlymonthnumber.removeValidator('exists');

    /*
     * NH-12874: Hide all row containers so we can toggle
     * on only the appropriate one when needed.
     */
    $('.USACH_MAINT_ACH_SCHE_Container2_Group2_1').hide();
    $('.USACH_MAINT_ACH_SCHE_Container2_Group2_2').hide();
    $('.USACH_MAINT_ACH_SCHE_Container2_Group2_3').hide();
    $('.USACH_MAINT_ACH_SCHE_Container2_Group2_4').hide();

    if (mPatternMode === 'D') {
        // NH-12874: Show the appropriate row container.
        $('.USACH_MAINT_ACH_SCHE_Container2_Group2_1').show();

        $('[name="_PATTERNMODE"][value="D"]').prop('checked', true);
        gr21dailyMode.$el.closest('.field-container').show();
        gr21dailyDays.shouldBeVisible();

        if ($('[id="_DAILYMODE-interval"]').prop('checked') === true) {
            cDailyMode = 'interval';
        } else if ($('[id="_DAILYMODE-everyday"]').prop('checked') === true) {
            cDailyMode = 'everyday';
        }

        if (cDailyMode.length === 0) {
            if (mDailyMode.length > 0) {
                cDailyMode = mDailyMode;
            } else {
                cDailyMode = 'interval';
            }
        } else if (mDailyMode !== cDailyMode) {
            mDailyMode = cDailyMode;
            model.set('DAILYMODE', mDailyMode);
        }

        if (cDailyMode === 'interval') {
            $('[name="_DAILYMODE"][value="interval"]').prop('checked', true);
            gr21dailyDays.shouldBeReadOnly(false);
        } else {
            $('[name="_DAILYMODE"][value="everyday"]').prop('checked', true);
            gr21dailyDays.setValue('');
            gr21dailyDays.shouldBeReadOnly(true);
        }
    } else {
        gr21dailyMode.$el.closest('.field-container').hide();
        gr21dailyDays.shouldBeHidden();
    }

    // If the user selected weekly occurrence.
    if (mPatternMode === 'W') {
        // NH-12874: Show the appropriate row container.
        $('.USACH_MAINT_ACH_SCHE_Container2_Group2_2').show();

        $('[name="_PATTERNMODE"][value="W"]').prop('checked', true);

        gr22weeklyweeks.shouldBeVisible();
        gr22sunday.shouldBeVisible();
        gr22monday.shouldBeVisible();
        gr22tuesday.shouldBeVisible();
        gr22wednesday.shouldBeVisible();
        gr22thursdays.shouldBeVisible();
        gr22friday.shouldBeVisible();
        gr22saturday.shouldBeVisible();

        // NH-12874: Show the label for Weekly Weeks.
        $weeklyWeeksLabel.show();
    } else {
        gr22weeklyweeks.shouldBeHidden();
        gr22sunday.shouldBeHidden();
        gr22monday.shouldBeHidden();
        gr22tuesday.shouldBeHidden();
        gr22wednesday.shouldBeHidden();
        gr22thursdays.shouldBeHidden();
        gr22friday.shouldBeHidden();
        gr22saturday.shouldBeHidden();

        // NH-12874: Hide the label for Weekly Weeks.
        $weeklyWeeksLabel.hide();
    }

    if (mPatternMode === 'M') {
        // NH-12874: Show the appropriate row container.
        $('.USACH_MAINT_ACH_SCHE_Container2_Group2_3').show();

        $('[name="_PATTERNMODE"][value="M"]').prop('checked', true);
        gr23monthlymode.$el.closest('.field-container').show();
        gr23monthlyday1.shouldBeVisible();
        gr23monthlymonth1.shouldBeVisible();
        gr23monthlydaynumber.shouldBeVisible();
        gr23monthlydaytype.shouldBeVisible();
        gr23monthlymonthnumber.shouldBeVisible();

        if ($('[id="_MONTHLYMODE-specific"]').prop('checked') === true) {
            cMonthlyMode = 'specific';
        } else if ($('[id="_MONTHLYMODE-interval"]').prop('checked') === true) {
            cMonthlyMode = 'interval';
        }

        if (cMonthlyMode.length === 0) {
            if (mMonthlyMode.length > 0) {
                cMonthlyMode = mMonthlyMode;
            } else {
                cMonthlyMode = 'specific';
            }
        } else if (mMonthlyMode !== cMonthlyMode) {
            mMonthlyMode = cMonthlyMode;
            model.set('MONTHLYMODE', mMonthlyMode);
        }

        if (cMonthlyMode === 'specific') {
            $('[name="_MONTHLYMODE"][value="specific"]').prop('checked', true);
            gr23monthlyday1.shouldBeReadOnly(false);
            gr23monthlymonth1.shouldBeReadOnly(false);
            gr23monthlydaynumber.shouldBeReadOnly(true);
            gr23monthlydaytype.shouldBeReadOnly(true);
            gr23monthlymonthnumber.shouldBeReadOnly(true);
            gr23monthlydaynumber.setValue('');
            gr23monthlydaytype.setValue('');
            gr23monthlymonthnumber.setValue('');
        } else {
            $('[name="_MONTHLYMODE"][value="interval"]').prop('checked', true);
            gr23monthlyday1.shouldBeReadOnly(true);
            gr23monthlymonth1.shouldBeReadOnly(true);
            gr23monthlydaynumber.shouldBeReadOnly(false);
            gr23monthlydaytype.shouldBeReadOnly(false);
            gr23monthlymonthnumber.shouldBeReadOnly(false);
            gr23monthlyday1.setValue('');
            gr23monthlymonth1.setValue('');
        }
    } else {
        gr23monthlymode.$el.closest('.field-container').hide();
        gr23monthlyday1.shouldBeHidden();
        gr23monthlymonth1.shouldBeHidden();
        gr23monthlydaynumber.shouldBeHidden();
        gr23monthlydaytype.shouldBeHidden();
        gr23monthlymonthnumber.shouldBeHidden();
    }

    if (mPatternMode === 'Y') {
        // NH-12874: Show the appropriate row container.
        $('.USACH_MAINT_ACH_SCHE_Container2_Group2_4').show();

        $('[name="_PATTERNMODE"][value="Y"]').prop('checked', true);
        gr24yearlymode.$el.closest('.field-container').show();
        gr24yearlydaynumber.shouldBeVisible();
        gr24yearlydaytype.shouldBeVisible();
        gr24yearlymonthnumber.shouldBeVisible();
        gr24yearlymonth1.shouldBeVisible();
        gr24yearlyday1.shouldBeVisible();

        if ($('[id="_YEARLYMODE-interval"]').prop('checked') === true) {
            cYearlyMode = 'interval';
        } else if ($('[id="_YEARLYMODE-specific"]').prop('checked') === true) {
            cYearlyMode = 'specific';
        }

        if (cYearlyMode.length === 0) {
            if (mYearlyMode.length > 0) {
                cYearlyMode = mYearlyMode;
            } else {
                cYearlyMode = 'interval';
            }
        } else if (mYearlyMode !== cYearlyMode) {
            mYearlyMode = cYearlyMode;
            model.set('YEARLYMODE', mYearlyMode);
        }

        if (cYearlyMode === 'interval') {
            $('[name="_YEARLYMODE"][value="interval"]').prop('checked', true);
            gr24yearlydaynumber.shouldBeReadOnly(false);
            gr24yearlydaytype.shouldBeReadOnly(false);
            gr24yearlymonthnumber.shouldBeReadOnly(false);
            gr24yearlymonthnumber.setValidator($('[name="YEARLYMONTHNUMBER"]'), 'exists', true);
            gr24yearlymonth1.setValue('');
            gr24yearlyday1.setValue('');
            gr24yearlymonth1.shouldBeReadOnly(true);
            gr24yearlyday1.shouldBeReadOnly(true);
        } else {
            $('[name="_YEARLYMODE"][value="specific"]').prop('checked', true);
            gr24yearlydaynumber.setValue('');
            gr24yearlydaytype.setValue('');
            gr24yearlymonthnumber.setValue('');
            gr24yearlydaynumber.shouldBeReadOnly(true);
            gr24yearlydaytype.shouldBeReadOnly(true);
            gr24yearlymonthnumber.shouldBeReadOnly(true);
            gr24yearlymonth1.shouldBeReadOnly(false);
            gr24yearlyday1.shouldBeReadOnly(false);
        }
    } else {
        gr24yearlymode.$el.closest('.field-container').hide();
        gr24yearlydaynumber.shouldBeHidden();
        gr24yearlydaytype.shouldBeHidden();
        gr24yearlymonthnumber.shouldBeHidden();
        gr24yearlymonth1.shouldBeHidden();
        gr24yearlyday1.shouldBeHidden();
    }

    if ($('[id="_ENDMODE-noend"]').prop('checked') === true) {
        cEndMode = 'noend';
    } else if ($('[id="_ENDMODE-numoccur"]').prop('checked') === true) {
        cEndMode = 'numoccur';
    } else if ($('[id="_ENDMODE-effdate"]').prop('checked') === true) {
        cEndMode = 'effdate';
    }

    if (cEndMode.length === 0) {
        if (mEndMode.length > 0) {
            cEndMode = mEndMode;
        } else {
            cEndMode = 'noend';
        }
    } else if (mEndMode !== cEndMode) {
        mEndMode = cEndMode;
        model.set('ENDMODE', mEndMode);
    }

    if (cEndMode === 'noend') {
        $('[name="_ENDMODE"][value="noend"]').prop('checked', true);
        cnt3endcycles.setValue('');
        cnt3endingeffectivedate.setValue('');
        cnt3endcycles.shouldBeReadOnly(true);
        cnt3endingeffectivedate.shouldBeReadOnly(true);
    } else if (cEndMode === 'numoccur') {
        $('[name="_ENDMODE"][value="numoccur"]').prop('checked', true);
        cnt3endingeffectivedate.setValue('');
        cnt3endcycles.shouldBeReadOnly(false);
        cnt3endingeffectivedate.shouldBeReadOnly(true);
    } else if (cEndMode === 'effdate') {
        $('[name="_ENDMODE"][value="effdate"]').prop('checked', true);
        cnt3endcycles.setValue('');
        cnt3endcycles.shouldBeReadOnly(true);
        cnt3endingeffectivedate.shouldBeReadOnly(false);
    }

    // Get the currently checked value. Could probably use .val(), here.
    cNonBizDaysMode = $('[name="_NONBUSINESSDAYMODE"]:checked').attr('value');

    if (cNonBizDaysMode) {
        if (mNonBizDaysMode.length > 0) {
            cNonBizDaysMode = mNonBizDaysMode;
        } else {
            cNonBizDaysMode = 'prev';
        }
    } else if (mNonBizDaysMode !== cNonBizDaysMode) {
        mNonBizDaysMode = cNonBizDaysMode;
        model.set('NONBUSINESSDAYMODE', mNonBizDaysMode);
    }

    // Set the matching input
    $(`[name="_NONBUSINESSDAYMODE"][value="${cNonBizDaysMode}"]`).prop('checked', true);

    /*
     * Deeplink Frame sizing
     * ---------------------
     * Since we are not inherently changing the dom but toggling visbility of
     * fields upon selection
     * we need to make sure gthe frame resizes itself based on the height change caused
     * by certain fields showing up[ and being taken away
     */
    if (!initialState) {
        appBus.trigger('forcePublishNewResizeMinHeight', $('body').height());
    }
}
