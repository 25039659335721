var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<h3>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"paymentGroupName") : depth0), depth0))
    + " <span class=\"payment-totals__groupCount\">("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"transactionCount") : depth0), depth0))
    + ")</span></h3>\n<ul>\n\n</ul>\n";
},"useData":true});