import { createUseStyles } from '@glu/theming';

const styles = (theme) => {
    const {
        palette: { primary: { normal } },
    } = theme;
    return {
        root: {
            fontSize: 'inherit',
            borderRadius: '0px',
            padding: '5px 15px',
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        loadingIndicator: {
            left: '0 !important',
            margin: '30px 0',
        },
        link: {
            marginTop: '10px',
            fontSize: '16px',
            color: normal,
            border: 'none',
            background: 'none',
        },
    };
};

export default createUseStyles(styles);
