import util from '@glu/core/src/util';
import locale from '@glu/locale';
import LimitSetView from '../payments/assignedPermissions/limitSet';
import limitSetTmpl from './limitSet.hbs';

export default LimitSetView.extend({
    template: limitSetTmpl,

    templateHelpers() {
        const self = this;

        return util.extend(
            LimitSetView.prototype.templateHelpers.call(this),
            {
                label() {
                    return locale.get(self.model.get('typeGroupLabel'));
                },
            },
        );
    },
});
