import 'jui/droppable';
import Model from '@glu/core/src/model';
import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import store from 'system/utilities/cache';
import Collection from '@glu/core/src/collection';
import services from 'services';
import ScreenManager from '@glu/screen-manager';
import workspaceHelper from 'common/workspaces/api/helper';
import { dark } from 'common/util/featureUtil';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import numeral from 'numeral';
import alert from '@glu/alerts';
import errHandler from 'system/errHandler';
import constants from 'app/balanceAndTransaction/constants';
import BalancePoller from 'app/balanceAndTransaction/models/balancePoller';
import TileModel from 'app/smbPayments/models/accounts/tile';
import AlertDetails from 'app/smbPayments/alerts/transferAlertDetails';
import ListView from 'app/smbPayments/views/accounts/balances/listControlsView';
import loans from 'app/loans/api/common';
import TileCollectionView from 'app/smbPayments/views/accounts/balances/tileGridCollectionView';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import locale from '@glu/locale';
import accountUtil from 'app/smbPayments/util/accountUtil';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import tmpl from './accountsListView.hbs';

const getPaymentEntitlement = (typeCode) => {
    const request = {
        productCode: 'RTGS',
        functionCode: 'INST',
        typeCode,
        actionMode: 'INSERT',
    };
    return new Promise((resolve, reject) => {
        const url = services.generateUrl('accessService/hasAccess');
        http.post(url, request, (response) => {
            resolve(response);
        }, (e) => {
            reject(e);
        });
    });
};

const realTimeRoute = 'accountSummary/requestRealTimeBalances';
const requestDepositRealtimeBalancesUrl = services
    .generateUrl(constants.DEPOSIT_ACCTS_SERVICE_PREFIX + realTimeRoute);
const requestLoanRealtimeBalancesUrl = services
    .generateUrl(constants.LOAN_ACCTS_SERVICE_PREFIX + realTimeRoute);

const AccountListView = Layout.extend({
    template: tmpl,
    loadingTemplate,
    className: 'page',

    model: new Model({
        transferAmount: 0,
        formattedTransferAmount: '$0',
    }),

    initialize() {
        this.isListView = store.get('accountsViewDisplayMode') === 'list';

        loans.getPaymentPreferences().then(
            this.initializeCheckingSavingLoansAccountGrid.bind(this),
            errHandler,
        );

        this.setNotificationData({
            title: 'smbAccounts',
        });

        this.depositBalancePoller = new BalancePoller({
            serviceUrl: requestDepositRealtimeBalancesUrl,
        });

        this.loanBalancePoller = new BalancePoller({
            serviceUrl: requestLoanRealtimeBalancesUrl,
        });
        this.creditCardBalancePoller = new BalancePoller({
            serviceUrl: services
                .generateUrl(constants.CREDIT_CARD_ACCTS_SERVICE_PREFIX + realTimeRoute),
        });

        this.updateMasterOnSubAccountEnabled = applicationConfigParams.getValue('TIERLOAN', 'UpdateMasterOnSubAccountEnabled') === '1';

        this.listenTo(this.depositBalancePoller, 'foundNewBalances', this.refreshData);
        this.listenTo(this.loanBalancePoller, 'foundNewBalances', this.refreshData);
        this.listenTo(this.creditCardBalancePoller, 'foundNewBalances', this.refreshData);
        store.set('btr:fromInitialContainer', true);
    },
    ui: {
        $btnTileView: '.btn-tile-view',
        $btnListView: '.btn-list-view',
        $tileView: '.balances-tile-view',
        $listView: '.balances-list-view',
        okBtn: '.ok-btn',
        $totalPayments: '.total-payments',
        $checkingContainer: '.checking-container',
        $savingsContainer: '.savings-container',
        $loansContainer: '.loans-container',
        $creditCardContainer: '[data-hook="getCreditCardContainer"]',
        $refreshButton: '[data-hook="refresh-balances"]',
        $refreshTime: '[data-hook="refresh-time"]',
    },

    events: {
        'click @ui.$btnTileView': 'showTileView',
        'click @ui.$btnListView': 'showListView',
        'click @ui.$refreshButton': 'refreshData',
    },
    appEvents: {
        'smbPayments:newTransfer:gridAlertForAccount': 'showTransferDetailsAlert',
    },

    /**
     * display alert box containing transfer details on successful transfer of amount
     * @param {Object} model - Config data
     */
    showTransferDetailsAlert(model) {
        const transferDetails = new AlertDetails({
            model,
        });
        this.alertView = alert.success(
            /*
             * Join the message array before passing it as a parameter
             * as the success function joins with an undesirable comma
             * in this situation
             */
            model.get('message').join(''),
            {
                details: transferDetails,
            },
        );
        this.alertRegion.show(this.alertView);
    },

    templateHelpers() {
        return {
            dragAndDropMsg: locale.get('smbPayments.drag.drop.transfer.msg'),
        };
    },

    refreshData() {
        this.setHasLoadedRequiredData(false);
        this.render();
        // disable refresh button
        this.ui.$refreshButton.prop('disabled', true);

        // Reset and rebuild models (fetch data)
        this.setupModels().then(() => {
            // Show our refreshed views
            this.showViews();

            if (this.isListView) {
                // Are we in listView ?
                this.refreshListView();
            }

            // update refresh timer
            this.setHasLoadedRequiredData(true);
            this.render();
            this.updateRefreshTimestamp();
            this.resetTimerControl();
        });
    },

    updateRefreshTimestamp() {
        // Update refresh timestamp
        const currentDate = new Date();
        this.ui.$refreshTime.text(`${Formatter.formatDate(currentDate)} ${Formatter.formatTime(currentDate)}`);
    },

    resetTimerControl() {
        const retryAfter = this.depositBalancePoller.get('retryAfter') * 1000;
        /*
         * If this view is closed before the timeout, then 'prop' is not an
         * available function.
         * TODO This is just adding a fix on top of many layers of messiness.
         * We need to fix the many pollers, multiple calls to refreshData, and multiple renders
         */
        const timer = setTimeout(() => this.ui.$refreshButton.prop?.('disabled', false), retryAfter);
        this.ui.$refreshButton.prop('disabled', true);
        if (retryAfter === 0) {
            clearTimeout(timer);
            this.ui.$refreshButton.prop('disabled', false);
        }
    },

    showTileView() {
        store.set('accountsViewDisplayMode', 'tile');
        this.isListView = false;

        this.ui.$tileView.removeClass('hidden');
        this.ui.$listView.addClass('hidden');

        this.ui.$btnTileView.attr('aria-pressed', 'true');
        this.ui.$btnListView.attr('aria-pressed', 'false');

        this.ui.$btnTileView.addClass('active');
        this.ui.$btnListView.removeClass('active');
    },

    refreshListView() {
        const ourModels = [].concat(
            this.checkingAccountView.collection.models,
            this.savingsAccountGridView.collection.models,
            this.loansView.collection.models,
            this.creditCardView.collection.models,
        );

        this.listView.collection.reset(ourModels);
    },

    showListView() {
        store.set('accountsViewDisplayMode', 'list');
        this.isListView = true;

        /*
         * The list view displays all the accounts rolled up in one list instead of
         * individual sections.
         * This may come from one webseries call or several. For now, combined the
         * seperate json data calls
         * into one collection for display purposes.
         */

        const ourModels = [].concat(
            this.checkingAccountView.collection.models,
            this.savingsAccountGridView.collection.models,
            this.loansView.collection.models,
            this.creditCardView.collection.models,
        );

        const combinedCollection = new Collection(ourModels);

        this.listView = new ListView({
            collection: combinedCollection,
        });
        this.listViewAccountsRegion.show(this.listView);

        // Handle buttons to toggle views
        this.ui.$listView.removeClass('hidden');
        this.ui.$tileView.addClass('hidden');

        this.ui.$btnListView.attr('aria-pressed', 'true');
        this.ui.$btnTileView.attr('aria-pressed', 'false');

        this.ui.$btnTileView.removeClass('active');
        this.ui.$btnListView.addClass('active');
    },

    fetchModel(url, postData) {
        return new Promise((resolve, reject) => {
            http.post(url, postData || {}).then(resolve, (status) => {
                reject(`Error with Service Endpoint: ${status.status} ${status.statusText}`);
            });
        });
    },

    getRequestParameters(sectionId) {
        return {
            item: [{
                name: 'tabId',
                value: 'SMBALLACCOUNTS',
            },
            {
                name: 'sectionId',
                value: sectionId,
            }],
        };
    },

    fetchData(serviceURL, sectionId) {
        return this.fetchModel(services.generateUrl(serviceURL), {
            requestParameters: this.getRequestParameters(sectionId),
        });
    },

    setupModels() {
        const checkingAndSavings = this.fetchData(constants.DEPOSIT_ACCTS_SUMMARY, 'DEPOSITACCTS');
        const loansSummary = this.fetchData(constants.LOAN_ACCTS_SUMMARY, 'LOANACCTS');
        const creditCardSummary = dark.isLive('CCREPORTING') ? this.fetchData(constants.CREDIT_CARD_ALL_ACCTS_SUMMARY, 'CREDITCARDACCTS') : Promise.resolve({});
        return Promise.all([
            getPaymentEntitlement('TRANSFER'),
            getPaymentEntitlement('LOANPAY'),
            getPaymentEntitlement('LOANDRAW'),
            checkingAndSavings,
            loansSummary,
            creditCardSummary,
        ]).then((results) => {
            [
                this.canTransfer,
                this.canLoanPay,
                this.canLoanDraw,
            ] = results;
            const [,,, checkingSavingResp, loansResp, creditCardResp] = results;

            if (checkingSavingResp.rows) {
                this.resetSavingCheckingCollections(
                    checkingSavingResp.rows,
                    this.checkingAccountView.collection,
                    this.savingsAccountGridView.collection,
                );
            }

            if (loansResp.rows) {
                this.resetCollection(loansResp.rows, this.loansView);
            }

            if (creditCardResp.rows) {
                this.resetCollection(creditCardResp.rows, this.creditCardView);
            }

            this.startPoller(
                this.depositBalancePoller,
                [
                    ...this.checkingAccountView.collection.models,
                    ...this.savingsAccountGridView.collection.models,
                ],
            );
            this.startPoller(
                this.loanBalancePoller,
                this.loansView.collection.models,
            );
            this.startPoller(
                this.creditCardBalancePoller,
                this.creditCardView.collection.models,
            );

            this.setHasLoadedRequiredData(true);
        });
    },

    startPoller(poller, models) {
        poller.set('accountFilters', this.getRealTimeAccountFilters(models));
        if (poller.get('accountFilters').length) {
            poller.start();
        }
    },

    initializeCheckingSavingLoansAccountGrid(loanPrefs) {
        this.checkingAccountView = new TileCollectionView({
            collection: new Collection([]),
            loanPrefs,
        });
        this.savingsAccountGridView = new TileCollectionView({
            collection: new Collection([]),
            loanPrefs,
        });
        this.loansView = new TileCollectionView({
            collection: new Collection([]),
            loanPrefs,
        });
        this.creditCardView = new TileCollectionView({
            collection: new Collection([]),
            loanPrefs,
        });

        // Setup our models
        this.setupModels().then(this.render.bind(this), errHandler);
    },

    /**
     * Create a new title model, passing into account data and properties from
     * the context
     * @param {Object} account - should be an account response object
     * @param {Object} context
     * @returns {Object}
     */
    createTileModel(account, context) {
        return new TileModel(
            {},
            {
                account,
                canTransfer: context.canTransfer,
                canLoanPay: context.canLoanPay,
                canLoanDraw: context.canLoanDraw,
                updateMasterOnSubAccountEnabled: this.updateMasterOnSubAccountEnabled,
            },
        );
    },

    /**
     * Map the data and reset the collection
     * @param {Array} data - Array of accounts
     * @param {Object} view - View containing collection
     */
    resetCollection(data, view) {
        const mappedData = util.map(data, account => this.createTileModel(account, this));
        const { collection } = view;
        collection.reset(mappedData);

        /*
         * HACK for NH-99461 (Merge of NH-98492) - this was added as resetting the collection
         * on the loans view does not show the latest data that was retrieved in the
         * service (getListView)
         */
        view.render();
    },

    /**
     * Filter the account Data and reset the checking and savings collections
     * @param {Array} accountData - array of accounts, probably mixed savings
     * @param {Collection} checkingCollection
     * @param {Collection} savingsCollection
     * and checking
     */
    resetSavingCheckingCollections(accountData, checkingCollection, savingsCollection) {
        const accounts = this.filterSavingsCheckingAccounts(accountData);

        checkingCollection.reset(accounts.checking);
        savingsCollection.reset(accounts.savings);

        /*
         * HACK for NH-99461 (Merge of NH-98492) - this was added as resetting the collection
         * on either the checkings view or savings view does not show the latest data
         * that was retrieved in the service (getListView)
         */
        this.checkingAccountView.render();
        this.savingsAccountGridView.render();
    },

    /**
     * Filter an array of accounts into seperate savings and checking
     * account arrays
     * @param {Array} accountData - array of accounts, probably mixed savings
     * and checking
     */
    filterSavingsCheckingAccounts(accountData) {
        return util.reduce(
            accountData,
            (accounts, account) => {
                const accountTileModel = this.createTileModel(account, this);
                const accountType = accountTileModel.get('accountType');

                if (accountType === 'DD' || accountType === 'GL') {
                    accounts.checking.push(accountTileModel);
                } else if (accountType === 'SV') {
                    accounts.savings.push(accountTileModel);
                }
                return accounts;
            },
            {
                savings: [],
                checking: [],
            },
        );
    },

    regions: {
        listViewAccountsRegion: '.list-view-accounts',
        checkingAccountsRegion: '.checking-accounts-grid',
        savingAccountsGridRegion: '.saving-accounts-grid',
        loansGridRegion: '.loans-grid',
    },

    hideGridsWithNoData() {
        if (this.checkingAccountView.collection.length === 0) {
            this.ui.$checkingContainer.hide();
        }

        if (this.savingsAccountGridView.collection.length === 0) {
            this.ui.$savingsContainer.hide();
        }

        if (this.loansView.collection.length === 0) {
            this.ui.$loansContainer.hide();
        }
        if (this.creditCardView.collection.length === 0) {
            this.ui.$creditCardContainer.hide();
        }
    },

    showViews() {
        // Display list view only, if we are on a small screen. No tile views.
        if (ScreenManager.isExtraSmall()) {
            this.showListView();
        }

        this.ui.$totalPayments.text(this.getCashOnHandFormatted());

        this.hideGridsWithNoData();
    },

    getCashOnHandFormatted() {
        let cashOnHand = 0;
        const addCashFromModel = function (tileModel) {
            cashOnHand += numeral().unformat(tileModel.get('amountAvailable'));
        };
        this.checkingAccountView.collection.each(addCashFromModel);
        this.savingsAccountGridView.collection.each(addCashFromModel);
        return Formatter.formatCurrency(cashOnHand);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.isListView) {
                this.showListView();
            }

            if (this.checkingAccountView) {
                this.checkingAccountsRegion.show(this.checkingAccountView);
            }

            if (this.savingsAccountGridView) {
                this.savingAccountsGridRegion.show(this.savingsAccountGridView);
            }

            if (this.loansView) {
                this.loansGridRegion.show(this.loansView);
            }

            if (this.creditCardView) {
                this.creditCardsRegion.show(this.creditCardView);
            }

            this.showViews();

            this.notifyPageLoaded();

            this.updateRefreshTimestamp();
        }
    },
    /**
     * Helper method to extract account names/filters from
     * accounts collection
     * @param {Object[]} accounts
     * @returns {string[]} - list of account numbers/filters
     */
    getRealTimeAccountFilters(accounts) {
        return util.chain(accounts)
            .filter(account => account.get('isRealTimeAccount')
                && !this.isExcludedLoan(account))
            .map(account => account.get('accountFilter'))
            .value();
    },

    /**
     * Helper method to exclude a tiered loan based on updateMasterOnSubAccountEnabled param
     * When enabled, this configuration assumes all loan accounts are tiered loan accounts.
     * @param {Object} account - model
     * @returns {boolean}
     */
    isExcludedLoan(account) {
        /**
         * If updateMasterOnSubAccountEnabled is disabled, we should make RT calls to both
         * loans types
         * If updateMasterOnSubAccountEnabled is enabled, we should not make RT calls to
         * either loans types
         */
        if (this.updateMasterOnSubAccountEnabled
            && accountUtil.isLoan(account.get('accountType'))) {
            return true;
        }
        return false;
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'SMBACCTLIST',
    view: AccountListView,
    options: {},
});

export default AccountListView;
