import $ from 'jquery';
import Layout from '@glu/core/src/layout';
import services from 'services';
import userInfo from 'etc/userInfo';
import mfaManager from 'system/mfa/manager';
import MenuModel from 'system/menuModel';
import LogoView from 'system/navigation/views/logo';
import FlyoutView from 'system/navigation/views/flyout';
import UserMenuView from 'system/navigation/views/userMenu';
import notificationManager from 'system/notifications/manager';
import configuration from 'system/configuration';
import MenuView from 'system/navigation/views/superMenu';
import EntitledGIRTypesModel from 'app/balanceAndTransaction/models/entitledGIRTypes';
import { appBus } from '@glu/core';
import helpers from 'components/AsyncReporting/helpers';
import mobileUtil from 'mobile/util/mobileUtil';
import clientLayoutTmpl from './clientLayout.hbs';
import clientLayoutPortalTmpl from './clientLayoutPortal.hbs';

export default Layout.extend({
    template: clientLayoutTmpl,
    portalTemplate: clientLayoutPortalTmpl,
    tagName: 'header',
    className: 'application-header',

    ui: {
        menuItem: '.glu-menu .parent > span',
    },

    events: {
        'mouseenter @ui.menuItem': 'toggleBoundaryCheck',
    },

    initialize(options) {
        this.initializeLogoHeader();
        this.isPortal = configuration.isPortal();
        this.marketSegment = userInfo.get('marketSegment');

        /*
         * Pass in menuModel if it came from a direct url entry/bookmark.
         * In that case the model is retrieve after user authentication
         */

        this.modelMenu = options.menuModel;

        this.initializeMenuHeader(this.modelMenu);
        this.initializeUserMenuHeader();
        this.initializeScrolling();

        this.hasLoadedViewRequirements = false;
        this.isMobileScreen = mobileUtil.isNativeApp() || mobileUtil.isMobileScreen();
    },

    loadViewRequirements() {
        // Only smb users will have the flyout menu
        if (userInfo.hasLeftNavigation()) {
            /**
             * If the user is not entitled to view balance and transactions, they will need
             * to skip the flyout as they cannot access it, but they can access the accounts
             * center behind it
             */
            this.entitledGIRTypes = new EntitledGIRTypesModel();
            this.entitledGIRTypes.fetch({
                success: () => {
                    this.hasLoadedViewRequirements = true;
                    if (this.entitledGIRTypes.isEntitled('GIRTRN_I')
                        && this.entitledGIRTypes.isEntitled('GIRTRN_P')) {
                        this.initializeFlyout();
                    }
                    this.render();
                },
                error: () => {
                    this.hasLoadedViewRequirements = true;
                    this.render();
                },
            });
        } else {
            this.hasLoadedViewRequirements = true;
            this.render();
        }
    },

    /**
     * TODO: Add details for why this is necessary.
     */
    initializeScrolling() {
        $(window).scroll(this.handleScroll);
    },

    /**
     * Initialize logo header region.
     */
    initializeLogoHeader() {
        this.logoHeaderView = new LogoView();
    },

    /**
     * Initialize menu header region.
     */
    initializeMenuHeader(modelNav) {
        const self = this;

        /*
         * If from direct url entry/bookmark the model will already exist.
         * and is passed.  There is no need to fetch the model.
         */
        this.menuModel = modelNav || new MenuModel();
        const menuUrl = services.profile;

        /*
         * pass in current user information
         * Fetch if from a menu click.
         */
        if (!modelNav) {
            this.menuModel.fetch({
                url: menuUrl,
                cache: false,
            });
        }

        if (self.menuModel.get('modules') && userInfo.hasLeftNavigation()) {
            self.menuModel.get('modules')[0].components.sort((menuItem1, menuItem2) => menuItem1.displayOrder - menuItem2.displayOrder);
        }

        this.menuHeaderView = new MenuView({
            model: this.menuModel,
        });

        /*
         * COMMENTED OUT BUT KEEPING FOR FUTURE DEV WORK
         * this.listenTo(this.menuHeaderView, 'render', this.injectBadges);
         */

        window.menuHeaderView = this.menuHeaderView;
    },

    getTemplate() {
        if (configuration.isPortal()) {
            return this.portalTemplate;
        }
        return this.template;
    },

    /**
     * Initialize the user menu region.
     */
    initializeUserMenuHeader() {
        this.userMenuHeaderView = new UserMenuView();
    },

    /**
     * Initialize the flyout menu region.
     */
    initializeFlyout() {
        this.flyoutView = new FlyoutView();
    },

    appEvents: {
        menuHandleClick: 'setActiveMenuItem',
    },

    // Set the current active item on the side navigation bar
    setActiveMenuItem(e) {
        const menuItem = e && e.currentTarget ? e.currentTarget : false;

        if (menuItem) {
            this.$('nav.glu-menu [class^="item-"]').removeClass('active');
            // Selector could be a span or anchor
            this.$(menuItem).parents('.parent').find('> span, > a').addClass('active');
        }
    },

    handleScroll() {
        const $window = $(window);
        const $navMenu = $('div[data-region=menuHeader]');
        const $flyoutMenu = $('.flyout-menu-wrapper');
        const headerHeight = $('.glu-base-nav').outerHeight();
        const scrollPosition = $window.scrollTop();
        let adjustedPosition = 0;

        if ($navMenu.find('div.toggle').not(':visible').length > 0) {
            if (scrollPosition <= 0) {
                /*
                 * Adjusted Position is the startPosition plus the absolute value of the
                 * scroll position. The scroll position is negative when "drag to refresh"
                 * is active, this allows the fixed position of the menu to move accordingly
                 * as the user drags down.
                 */
                adjustedPosition = headerHeight + Math.abs(scrollPosition);

                $navMenu.css({
                    top: `${adjustedPosition}px`,
                });

                $flyoutMenu.css({
                    top: `${headerHeight}px`,
                });
            } else if (scrollPosition > 0 && scrollPosition < headerHeight) {
                $navMenu.css({
                    top: `${headerHeight - scrollPosition}px`,
                });

                $flyoutMenu.css({
                    top: `${headerHeight - scrollPosition}px`,
                });
            } else if (scrollPosition >= headerHeight) {
                $navMenu.css({
                    top: '0px',
                });

                $flyoutMenu.css({
                    top: '0px',
                });
            }
        }
    },

    /**
     * Addresses the issue of very long flyout menus getting hidden
     * by the bottom edge of the browser. Also adds scrollbar to prevent
     * the same issue from occuring at the top when orientation is flipped.
     */
    toggleBoundaryCheck(event) {
        const windowHeight = $(window).height();
        const $currentMenuItem = $(event.currentTarget);
        const $currentDropdown = $currentMenuItem.siblings('.dropdown');

        $currentDropdown.css({
            'max-height': 'none',
        });

        const currentDropdownHeight = $currentDropdown.outerHeight();
        const currentOffset = $currentMenuItem.offset().top - $(window).scrollTop();

        $currentDropdown.toggleClass('orientation-up', (currentOffset + currentDropdownHeight) >= windowHeight);

        // check whether dropdown is now sticking off the top of the page
        const topOffset = $currentDropdown.offset().top - $(window).scrollTop();
        if (topOffset <= 0) {
            $currentDropdown.css({
                'max-height': $currentDropdown.outerHeight(true) + topOffset,
                'overflow-y': 'auto',
            });
        }
    },

    setLogoHeaderView(view) {
        this.logoHeaderView = view;
    },

    setMenuHeaderView(view) {
        this.menuHeaderView = view;
    },

    setUserMenuHeaderView(view) {
        this.userMenuHeaderView = view;
    },

    onRender() {
        if (this.hasLoadedViewRequirements) {
            /* After logging in, remove class used for login screens */
            $('body').removeClass('login-screen');

            // Set a body class for SMB or Corp users
            if (userInfo.hasLeftNavigation()) {
                $('body').addClass('smb-usergroup');
            } else {
                $('body').addClass('corp-usergroup');
            }

            if (this.isPortal) {
                $('body').addClass('portal');
            }

            if (this.isPortal === false) {
                if (this.logoHeaderView) {
                    this.logoHeader.show(this.logoHeaderView);
                }

                if (this.menuHeaderView) {
                    this.menuHeader.show(this.menuHeaderView);
                }

                if (this.userMenuHeaderView) {
                    /*
                     * process userMenu from the menuModel as there may be dynamic links to be
                     * set up under the user menu
                     */
                    this.userMenuHeaderView.processUserMenuModel(this.menuModel);
                    this.userMenuHeader.show(this.userMenuHeaderView);
                }

                if (this.flyoutView) {
                    this.flyoutHeader.show(this.flyoutView);
                }
            }

            // add global listener for glu modal open/close for notifications
            this.listenTo(this.appBus, 'glu:modal:open', (data) => {
                notificationManager.notify('modal:open', data);
            });
            this.listenTo(this.appBus, 'glu:modal:close', (data) => {
                notificationManager.notify('modal:close', data);
            });
            mfaManager.listen();
        } else {
            this.loadViewRequirements();
        }
    },

    /**
     * @method shouldStartAsyncReportPoller
     * @description Helper to check if async report poller should start when user logs in
     * Will return true in client UI and false in admin UI and mobile.
     * @returns {boolean}
     */
    shouldStartAsyncReportPoller() {
        const passwordExpired = userInfo.get('passwordExpired');
        return !passwordExpired && configuration.isClient() && !this.isMobileScreen;
    },

    onShow() {
        // Native needs to know when the layout is in the DOM for various js snippets
        appBus.trigger('clientLayout:rendered');
        if (this.shouldStartAsyncReportPoller()) {
            helpers.setupAsyncReportPoller();
        }
    },
});
