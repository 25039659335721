import Model from '@glu/core/src/model';
import services from 'services';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import ElearningContentModel from './elearningContentModel';

export default Model.extend({
    idAttribute: 'groupId',
    parse(data) {
        const contentList = new Collection();
        util.each(data.elearningContentModels, (content) => {
            const contentModel = new ElearningContentModel();
            contentModel.set({
                contentId: content.contentId,
                id: content.contentId,
                name: content.name,
                title: content.name,
                transcript: content.transcript,
                fileName: content.fileName,
                locale: content.locale,
                contentUrl: content.contentUrl,
            });
            contentList.add(contentModel);
        });
        this.set({
            contentList,
        });
        return data;
    },
    sync(method, model, options) {
        let xhr;

        switch (method) {
        case 'read':
            http.post(services.generateUrl('/cxp/elearning/read'), {
                groupId: parseInt(this.get('groupId'), 10),
            }, options.success, options.error);
            break;
        case 'create':
            xhr = http.post(services.generateUrl('/cxp/elearning/add'), model.toJSON(), (result) => {
                if (result !== undefined) {
                    model.set('groupId', result.groupId);
                }
                options.success(result);
            }, options.error);
            break;
        case 'update':
            xhr = http.post(
                services.generateUrl('/cxp/elearning/update'), model.toJSON(), (result) => {
                    options.success(result);
                },
                (result) => {
                    if (result.status === 200) {
                        options.success(result);
                    } else {
                        options.error(result);
                    }
                },
            );
            break;
        case 'delete':
            xhr = http.post(
                services.generateUrl('/cxp/elearning/delete'), model.toJSON(), (result) => {
                    options.success(result);
                },
                (result) => {
                    if (result.status === 200) {
                        options.success(result);
                    } else {
                        options.error(result);
                    }
                },
            );
            break;
        default:
        }

        this.trigger('request', model, xhr, options);
        return xhr;
    },
});
