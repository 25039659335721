import util from '@glu/core/src/util';

import adminConstants from 'app/administration/constants';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import store from 'system/utilities/cache';
import userInfo from 'etc/userInfo';
import template from './smbUsersListView.hbs';

const checkfreeSmbUserList = ListView.extend({

    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'CHECKFREE',
            serviceName: '/checkFreeSMBUsers',
            serviceFunc: null,
            businessType: null,
            enableSavedViews: true,
            enableDateTimeSupport: true,
            context: 'CHECKFREE_SMB',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/viewCheckfreeSmbUserDetails');
        return Promise.resolve();
    },

    templateHelpers() {
        return {
            isEntitledToAddUser: userInfo.getCheckfreeAuthLevel() !== 'Level3',
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CFSMBUSERS',
    view: checkfreeSmbUserList,
    options: {},
    restrictViewBasedOnUserAttribute: adminConstants.CHECKFREE_WIDGET_RESTRICT_ATTRIBUTE,
    restrictWhenIs: adminConstants.CHECKFREE_WIDGET_RESTRICT_LEVEL,
});

export default checkfreeSmbUserList;
