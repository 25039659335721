var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"panel","hash":{"theme":"panel-tertiary","collapsed":true,"locale":"common.full.payment.history","name":"audit-history"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":7,"column":26}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                   <div data-region=\"auditListRegion\" aria-live=\"polite\"/>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"panel","hash":{"theme":"panel-tertiary","collapsed":true,"locale":"common.full.template.history","name":"audit-history"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":11,"column":26}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"section section-container\">\n    <div class=\"section-header\"><legend>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"containerTitle") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":40},"end":{"line":2,"column":65}}}))
    + "</legend></div>\n    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"payment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":12,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n</fieldset>\n";
},"useData":true});