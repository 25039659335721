import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import Approver from './approver';

export default Model.extend({

    defaults() {
        return {
            sequence: null,
            // three empty models by default
            approvers: new Collection([
                {},
                {},
                {},
            ], { model: Approver }),
        };
    },

    initialize() {
        this.validators = {
            sequence: {
                description: locale.get('panelfilter.sequence'),
                exists: true,
            },
        };
    },

    validate(attributes, options) {
        const attributeErrors = Model.prototype.validate.call(this, attributes, options);

        const errors = attributes.approvers.map(approver => approver.isValid());

        if (util.contains(errors, false)) {
            return 'Invalid Approver(s)';
        }
        return attributeErrors;
    },
});
