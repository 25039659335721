import $ from 'jquery';

export default function (form, initialState) {
    let imageViewer;
    const formState = form.formView.state;
    if (initialState) {
        imageViewer = form.formView.$el.find('div[data-region="imageViewer"] .section.section-container');

        if (imageViewer.length > 0) {
            imageViewer.removeClass('section section-container');
        }

        const headerLabels = [];
        const headerData = [];

        $('.field-container').each(function () {
            headerLabels.push($('label', this).text());
            headerData.push($('p.form-control-static span', this).text());
        });

        form.formView.model.set('headerLabels', headerLabels);
        form.formView.model.set('headerData', headerData);
    }
    if (formState === 'modify') {
        const { model } = form.formView;
        const accNumber = form.field('ACCOUNT_NUMBER');
        const accTitle = form.field('ACCOUNT_TITLE');
        const clientDesc = form.field('CLIENTRETURNREASONDESC');
        const cmDecision = form.field('CMDECISION');
        const checkNumber = form.field('CHECKNUMBER');
        const issuedate = form.field('ISSUEDATE');
        const paiddate = form.field('PAIDDATE');
        const paidAmount = form.field('PAIDAMOUNT');
        const issuedPayee = form.field('ISSUEDPAYEENAME');
        const reasonCode = form.field('BANKREASONCODE');
        const status = form.field('STATUS');
        const serialNumber = form.field('ADJUSTEDSERIALNUMBER');
        const amount = form.field('ADJUSTEDAMOUNT');


        accNumber.shouldBeReadOnly(true);
        accTitle.shouldBeReadOnly(true);
        clientDesc.shouldBeReadOnly(true);
        cmDecision.shouldBeReadOnly(true);
        checkNumber.shouldBeReadOnly(true);
        issuedate.shouldBeReadOnly(true);
        paiddate.shouldBeReadOnly(true);
        paidAmount.shouldBeReadOnly(true);
        issuedPayee.shouldBeReadOnly(true);
        reasonCode.shouldBeReadOnly(true);
        status.shouldBeReadOnly(true);
        serialNumber.shouldBeRequiredWhen(amount.isEmpty());
        amount.shouldBeRequiredWhen(serialNumber.isEmpty());
        model.addValidator('ADJUSTEDSERIALNUMBER', {
            ...model.validators.ADJUSTEDSERIALNUMBER,
            integerOnly: true,
        });
    }
}
