import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import moment from 'moment';
import ItemView from '@glu/core/src/itemView';
import GroupPlanModel from '../model/groupPlan';
import GroupPlanPropertiesView from './groupPlanPropertiesView';
import GroupPlanStatsView from './groupPlanStatsView';
import GroupPlanSubPlanView from './groupPlanSubPlanView';
import groupPlanLayoutTmpl from './groupPlanLayout.hbs';
import createGroupPlanDialogTmpl from './createGroupPlanDialog.hbs';

const CreateFormDialog = ItemView.extend({
    template: createGroupPlanDialogTmpl,

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal create-group-plan',
    },

    ui: {
        $groupPlanDatePicker: '#group-plan-date',
    },

    initialize(options) {
        this.model = options.model;
    },

    templateHelpers() {
        const yearsArray = [];
        for (let i = 0; i < 5; i += 1) {
            yearsArray[i] = moment(moment(new Date()).year()) + i;
        }

        return {
            yearsArray,
        };
    },

    save() {
        const fiscalYear = this.model.get('fyStart').substr(2);

        /*
         * until we support something other than calendar
         * year as a fiscal year, use jan-dec. (NH-18937)
         */
        this.model.set({
            groupPlan: true,
            cashFlowPlanId: null,
            multiYearPlanId: null,
            fyStart: `01-JAN-${fiscalYear}`,
            fyEnd: `31-DEC-${fiscalYear}`,
        });

        this.model.save();
        this.close();
    },

    updateDatePicker() {
        this.ui.$groupPlanDatePicker.nhDatePicker({
            showCalendarIcon: true,
        });
    },

    onRender() {
        this.updateDatePicker();
    },
});

const GroupPlanLayout = Layout.extend({
    template: groupPlanLayoutTmpl,
    className: 'page cashflow-group-plan',

    regions: {
        propsRegion: '[data-hook="groupplan-properties-region"]',
        statsRegion: '[data-region="groupplan-stats-region"]',
        subPlanRegion: '[data-region="subplan-region"]',
    },

    ui: {
        $saveBtn: 'button[data-hook="save"]',
        $subPlanItemContainer: '.subplan-row',
    },

    handleModelSync(model, response, options) {
        if (['create', 'update'].indexOf(options.method) === -1) {
            return;
        }

        if (options.method === 'update' && !model.get('asNewVersion')) {
            return;
        }

        /*
         * if a new model was saved (create)
         * fetch the collection with options selectedPlan: model
         */
        this.hasGroupPlans = true;
        this.groupPlans.fetch({
            reset: true,
        });

        this.listenTo(
            this.groupPlans,
            {
                'change:selected': this.updateModel,
            },
        );

        this.render();
    },

    updateModel(model) {
        /*
         * this runs twice when the selected model changes - make sure we fetch the
         * newly selected ones
         */
        if (!model.get('selected')) {
            return;
        }

        this.model.set({
            cashFlowPlanId: model.get('cashFlowPlanId'),
            version: model.get('version'),
            fyStart: model.get('fyStart'),
            fyEnd: model.get('fyEnd'),
            name: model.get('name'),
            multiYearPlanId: model.get('multiYearPlanId'),
            createdOn: model.get('createdOn'),
            expectedDate: model.get('expectedDate'),
        });

        this.model.fetch();
    },

    createGroupPlanDialog() {
        dialog.custom(new CreateFormDialog({
            model: this.model,
        }));
    },

    initialize(options) {
        this.groupPlans = options.groupPlans;
        this.readOnly = options.readOnly;
        this.hasGroupPlans = (!util.isUndefined(this.groupPlans) && this.groupPlans.length > 0);
        this.model = new GroupPlanModel();

        this.listenTo(
            this.model,
            {
                'request sync': this.handleModelSync,
            },
        );

        if (this.hasGroupPlans) {
            this.listenTo(
                this.groupPlans,
                {
                    'change:selected': this.updateModel,
                },
            );

            // selected is already set from when the plans were synced
            this.updateModel(this.groupPlans.selectedPlan());
        }
    },

    templateHelpers() {
        return {
            hasGroupPlans: this.hasGroupPlans,
        };
    },

    handleSaveButton() {
        this.model.save();
    },

    onRender() {
        if (this.hasGroupPlans) {
            this.propsRegion.show(new GroupPlanPropertiesView({
                model: this.model,
                collection: this.groupPlans,
            }));
            this.statsRegion.show(new GroupPlanStatsView({
                model: this.model,
            }));
        }
        if (this.readOnly && this.groupPlans.isEmpty()) {
            this.ui.$subPlanItemContainer.hide();
        } else {
            this.ui.$subPlanItemContainer.show();
            this.subPlanRegion.show(new GroupPlanSubPlanView({
                model: this.model,
                readOnly: this.readOnly,
            }));
        }
    },
});

export default GroupPlanLayout;
