var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" data-action=\"cancel\" href=\"#\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":186}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">\n            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.currDayCashTrans",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":45}}}))
    + "\n    </h1>\n</div>\n<div class=\"page transactionDetails\">\n    <div class=\"section section-container\">\n        <section class=\"SummaryTableSection\">\n            <div class=\"SummaryTable\">\n                <h3 class=\"SummaryTable-title\" data-hook=\"getSummaryTitle\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.TransactionSummary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":75},"end":{"line":13,"column":113}}}))
    + "</h3>\n                <div data-region=\"totalsRegion\"></div>\n            </div>\n        </section>\n        <div class=\"BalanceTransactionControls col-lg-6 col-md-6\">\n            <div class=\"btn-wrapper\">\n                <div class=\"btn-group widget-info-links\">\n                    <span class=\"RefreshTransactionsBtn\" data-hook=\"getRefreshTransactionsControls\"></span>\n                </div>\n                "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":59}}})) != null ? stack1 : "")
    + "\n                </div>\n        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"section-body\">\n            <div data-region=\"transactionsGrid\" aria-live=\"polite\"></div>\n        </div>\n        <div class=\"section-footer\">\n            <button type=\"button\" data-action=\"cancel\" class=\"btn btn-primary\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":79},"end":{"line":30,"column":105}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});