var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"grid-action-popup-buttons\">\n            <button class=\"btn btn-primary\" type=\"button\" name=\"submitButton\" data-action=\"onSubmit\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"submitText") || (depth0 != null ? lookupProperty(depth0,"submitText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"submitText","hash":{},"data":data,"loc":{"start":{"line":9,"column":101},"end":{"line":9,"column":115}}}) : helper)))
    + "</button>\n            <button class=\"btn btn-tertiary\" type=\"button\" name=\"closeButton\" data-action=\"onClose\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelText") || (depth0 != null ? lookupProperty(depth0,"cancelText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelText","hash":{},"data":data,"loc":{"start":{"line":10,"column":100},"end":{"line":10,"column":114}}}) : helper)))
    + "</button>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-action-popup-wrapper grid-action-popup-"
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":1,"column":56},"end":{"line":1,"column":66}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"containerClass") || (depth0 != null ? lookupProperty(depth0,"containerClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"containerClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":85}}}) : helper)))
    + "\" data-hook=\"gridActionPopupWrapper\">\n    <div class=\"grid-action-popup-form\" data-hook=\"gridActionPopupForm\">\n        <div class=\"grid-action-popup-title\">\n            <h2>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":25}}}) : helper)))
    + "</h2>\n        </div>\n        <div class=\"grid-action-popup-fields\" data-region=\"fieldsRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showButtons") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"grid-action-popup-error hidden\" data-hook=\"gridActionPopupError\">\n        <span class=\"icon-exclamation-triangle grid-action-icon\"></span>\n        <h2 class=\"grid-action-popup-error-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"failLabel") || (depth0 != null ? lookupProperty(depth0,"failLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"failLabel","hash":{},"data":data,"loc":{"start":{"line":17,"column":50},"end":{"line":17,"column":63}}}) : helper)))
    + "</h2>\n        <p class=\"grid-action-popup-error-description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"failDescription") || (depth0 != null ? lookupProperty(depth0,"failDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"failDescription","hash":{},"data":data,"loc":{"start":{"line":18,"column":55},"end":{"line":18,"column":74}}}) : helper)))
    + "</p>\n        <button class=\"btn btn-tertiary\" type=\"button\" name=\"closeButton\" data-action=\"onClose\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"closeText") || (depth0 != null ? lookupProperty(depth0,"closeText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"closeText","hash":{},"data":data,"loc":{"start":{"line":19,"column":96},"end":{"line":19,"column":109}}}) : helper)))
    + "</button>\n    </div>\n    <div class=\"grid-action-popup-success hidden\" data-hook=\"gridActionPopupSuccess\">\n        <span class=\"icon-checkmark-circle grid-action-icon\"></span>\n        <h2 class=\"grid-action-popup-success-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"successLabel") || (depth0 != null ? lookupProperty(depth0,"successLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"successLabel","hash":{},"data":data,"loc":{"start":{"line":23,"column":52},"end":{"line":23,"column":68}}}) : helper)))
    + "</h2>\n        <button class=\"btn btn-tertiary\" type=\"button\" name=\"closeButton\" data-action=\"onClose\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"closeText") || (depth0 != null ? lookupProperty(depth0,"closeText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"closeText","hash":{},"data":data,"loc":{"start":{"line":24,"column":96},"end":{"line":24,"column":109}}}) : helper)))
    + "</button>\n    </div>\n    <div class=\"arrow\"></div>\n</div>\n";
},"useData":true});