import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';

export default Model.extend({
    defaults: {
        appToken: '',
        consumerKey: '',
        consumerSecret: '',
    },

    initialize() {
        this.validators = {
            appToken: {
                description: locale.get('setup.oauth.token'),
                exists: true,
            },

            consumerKey: {
                description: locale.get('setup.oauth.key'),
                exists: true,
            },

            consumerSecret: {
                description: locale.get('setup.oauth.secret'),
                exists: true,
            },
        };
    },

    sync(method, model, options) {
        const setKeyUrl = services.oAuthSetKey;
        let xhr;
        let success;
        let error;

        switch (method) {
        case 'create':
        case 'save':
        case 'update':
            xhr = http.post(
                setKeyUrl,
                {
                    appToken: this.get('appToken'),
                    consumerKey: this.get('consumerKey'),
                    consumerSecret: this.get('consumerSecret'),
                },
                success,
                error,
            );

            break;
        case 'delete':
            break;
        default:
        }

        this.trigger('request', model, xhr, options);
        return xhr;
    },
});
