/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { appBus } from '@glu/core';
import { withStyles } from '@glu/theming';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import useLocale from '../../hooks/useLocale';
import useRequest from '../../hooks/useRequest';

import styles from './RtpAddToConversation.styles';
import addToConversationReducer from './addToConversationReducer';
import AddToConversationMessage from '../RtpAddToConversationMessage/RtpAddToConversationMessage';
import AddToConversationButtons from '../RtpAddToConversationButtons/RtpAddToConversationButtons';
import RtpUtils from '../../app/payments/views/realTimePayments/utils/conversationUtils';

import conversationConstants from '../RtpDrawerContainer/RtpConversationConstants';

const propTypes = {
    data: PropTypes.shape({
        RTPREQ: PropTypes.string,
        RTPACK: PropTypes.string,
        RFIPARTICIPANT: PropTypes.string,
        PAYMENTACKPARTICIPANT: PropTypes.string,
        INSTRUCTION_ID: PropTypes.string,
        TNUM: PropTypes.string,
    }).isRequired,
    isConversationStarted: PropTypes.bool.isRequired,
    setShowConversationStarted: PropTypes.func.isRequired,
    currentTab: PropTypes.string.isRequired,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const AddToConversation = ({
    data, currentTab, isConversationStarted, setShowConversationStarted, classes,
}) => {
    const { getLocaleString } = useLocale();
    const { post } = useRequest();

    const initialState = {
        showAck: false,
        showRequest: false,
        showResponse: false,
        showButtons: true,
        messageType: 'RESPONSE',
        reasonType: 'I',
        reasonCode: '',
        message: '',
        isSending: false,
        invalidErrorMessage: '',
        errorMessage: '',
    };

    const [add2ConversationState, dispatch] = useReducer(addToConversationReducer, initialState);

    // use useCallback so these functions are only created once
    const setReasonCode = useCallback((code) => {
        dispatch({ type: 'SET_REASON_CODE', reasonCode: code });
    }, []);

    const setReasonType = useCallback((type) => {
        dispatch({ type: 'SET_REASON_TYPE', reasonType: type });
    }, []);

    const setInvalidErrorMessage = useCallback((msg) => {
        dispatch({ type: 'SET_INVALID_ERROR_MESSAGE', invalidErrorMessage: msg });
    }, []);

    const setMessage = useCallback((message) => {
        dispatch({ type: 'SET_MESSAGE', message });
    }, []);

    useEffect(() => {
        const actionWhenDrawerClosed = () => {
            // reset when drawer is closed
            if (currentTab === 'received') {
                dispatch({ type: 'DRAWER_CLOSED' });
            }
        };
        appBus.on('dgb:drawer:wasClosed', actionWhenDrawerClosed);
        return () => {
            appBus.off('dgb:drawer:wasClosed', actionWhenDrawerClosed);
        };
    }, [currentTab]);

    useEffect(() => {
        dispatch({ type: 'SHOULD_SHOW_RESPONSE', currentTab });
    }, [currentTab]);

    const displayAckSection = (evt) => {
        const event = evt;
        event.preventDefault();
        dispatch({ type: 'DISPLAY_ACK' });
        setShowConversationStarted(false);
    };

    const displayRequestForInfoSection = (evt) => {
        const event = evt;
        event.preventDefault();
        dispatch({ type: 'DISPLAY_RFI' });
        setShowConversationStarted(false);
    };

    const handleError = (error) => {
        // adjust for different error responses
        const actionResponse = error.responseJSON
            ? error.responseJSON.actionResponse
            : error.actionResponse;
        let errorMessage = getLocaleString('rtp.internalError');
        if (actionResponse.confirms.confirmResults[0].messages.length) {
            errorMessage = actionResponse.confirms.confirmResults[0].messages.join(' ');
        }

        dispatch({ type: 'STOP_DUE_TO_ERROR', errorMessage });
        appBus.trigger('rtp:conversationUpdated', currentTab);
    };

    function handleCancel(evt) {
        evt.preventDefault();
        dispatch({ type: 'RESET' });
        setShowConversationStarted(!isConversationStarted);
    }

    const handleClear = (evt) => {
        evt.preventDefault();
        dispatch({ type: 'CLEAR_MESSAGE' });
    };

    function handleSend(evt) {
        evt.preventDefault();
        if (add2ConversationState.messageType === 'REQUEST'
            && add2ConversationState.reasonCode === 'NARR' && add2ConversationState.message.length === 0) {
            dispatch({
                type: 'SET_INVALID_ERROR_MESSAGE',
                invalidErrorMessage: getLocaleString('RTP.error.conversation.invalid.messageRequired'),
            });
            return;
        }
        if (add2ConversationState.messageType === 'RESPONSE' && add2ConversationState.message.length === 0) {
            dispatch({
                type: 'SET_INVALID_ERROR_MESSAGE',
                invalidErrorMessage: getLocaleString('RTP.error.conversation.invalid.messageRequired'),
            });
            return;
        }

        dispatch({ type: 'START_SENDING' });
        // compose request to for request for info
        let payload;
        let url;
        let item;
        switch (add2ConversationState.messageType) {
        case 'ACK':
            url = services.generateUrl(`${constants.URL_RTP_PAYMENT_ACKNOWLEDGEMENT}/add`);
            payload = RtpUtils.getPAItem({
                paymentId: data.INSTRUCTION_ID,
                message: add2ConversationState.message,
            });
            break;
        case 'REQUEST':
            url = services.generateUrl(`${constants.URL_RTP_REQUEST_FOR_INFO}/add`);
            item = RtpUtils.getRFIItem({
                paymentId: data.INSTRUCTION_ID,
                message: add2ConversationState.message,
                reasonType: add2ConversationState.reasonType,
                reasonCode: add2ConversationState.reasonCode,
            });
            payload = {
                items: [
                    item,
                ],
            };
            break;
        case 'RESPONSE':
            url = services.generateUrl(`${constants.URL_RTP_RESPONSE_TO_REQUEST_FOR_INFO}/add`);
            payload = RtpUtils.getResponseItem({
                paymentId: data.TNUM,
                message: add2ConversationState.message,
            });
            break;
        default:
            break;
        }

        post(url, payload).then((response) => {
            // unmark send button - not busy
            dispatch({ type: 'STOP_SENDING' });
            // check for errors - if any call handleError
            if (response.actionResponse.confirms.totalFail > 0) {
                handleError(response);
            } else {
                // trigger event for drawer to update messages
                appBus.trigger('rtp:newMessageSuccess');
                // hide message and show buttons
                dispatch({ type: 'RESET' });
            }
        }).catch((error) => {
            handleError(error); // display error message
        });
    }

    return (
        <div className={classes.addToConversation}>
            {add2ConversationState.showAck
                && (
                    <AddToConversationMessage
                        inputType="ACK"
                        maxLength={conversationConstants.MAX_MESSAGE_LENGTH_RECEIVE}
                        rows={conversationConstants.MESSAGE_ROWS}
                        handleCancel={handleCancel}
                        handleSend={handleSend}
                        message={add2ConversationState.message}
                        errorMessage={add2ConversationState.errorMessage}
                        setMessage={setMessage}
                        isSending={add2ConversationState.isSending}
                    />
                )}
            {add2ConversationState.showRequest
            && (
                <AddToConversationMessage
                    inputType="REQUEST"
                    maxLength={conversationConstants.MAX_MESSAGE_LENGTH_RECEIVE}
                    rows={conversationConstants.MESSAGE_ROWS}
                    handleCancel={handleCancel}
                    handleSend={handleSend}
                    message={add2ConversationState.message}
                    setMessage={setMessage}
                    reasonCode={add2ConversationState.reasonCode}
                    reasonType={add2ConversationState.reasonType}
                    setReasonType={setReasonType}
                    setReasonCode={setReasonCode}
                    errorMessage={add2ConversationState.errorMessage}
                    invalidErrorMessage={add2ConversationState.invalidErrorMessage}
                    setInvalidErrorMessage={setInvalidErrorMessage}
                    isSending={add2ConversationState.isSending}
                    {...add2ConversationState}
                />
            )}
            { add2ConversationState.showButtons
                && (
                    <div>
                        {currentTab
                    && (
                        <AddToConversationButtons
                            data={data}
                            currentTab={currentTab}
                            initiateAck={displayAckSection}
                            initiateRFI={displayRequestForInfoSection}
                        />
                    )}
                    </div>
                )}
            {add2ConversationState.showResponse
            && (
                <AddToConversationMessage
                    inputType="RESPONSE"
                    maxLength={conversationConstants.MAX_MESSAGE_LENGTH_SUBMIT}
                    rows={conversationConstants.MESSAGE_ROWS}
                    handleCancel={handleClear}
                    handleSend={handleSend}
                    message={add2ConversationState.message}
                    setMessage={setMessage}
                    isSending={add2ConversationState.isSending}
                    errorMessage={add2ConversationState.errorMessage}
                    invalidErrorMessage={add2ConversationState.invalidErrorMessage}
                    setInvalidErrorMessage={setInvalidErrorMessage}
                />
            )}

        </div>
    );
};
AddToConversation.propTypes = propTypes;

export default withStyles(styles)(AddToConversation);
