import AccountInputView from 'mobile/common/views/inputs/accountInput';
import LocationAccountBase from './locationAccountBase';
import locationAccountViewTmpl from './locationAccountView.hbs';

export default LocationAccountBase.extend({
    template: locationAccountViewTmpl,

    ui: {
        $locationSelector: '[data-hook="getLocation"]',
    },

    initialize() {
        this.locations = this.options.locations;
        this.accounts = this.options.accounts;

        this.accountInputView = new AccountInputView({
            model: this.model,
            accounts: this.accounts,
        });

        this.listenTo(this.model, 'change:LOCATIONNAME', this.getAccountsByLocation.bind(this));
    },

    templateHelpers() {
        return {
            locations: this.locations.toJSON(),
            hasOneLocation: this.hasOneItem.bind(this, this.locations),
        };
    },

    onRender() {
        this.checkForSingleItem();

        if (!this.options.hideAccounts) {
            this.accountInputRegion.show(this.accountInputView);
        }
    },

    /**
     * Get a list of accounts based on location
     * @param {Object} model - changed model
     * @param {string} value - new value
     */
    getAccountsByLocation(model, value) {
        return this.accounts.fetch({
            locationName: value,
        })
            .then(this.receiveAccounts.bind(this));
    },

    /**
     * When only one location, update the model with the location
     */
    checkForSingleItem() {
        if (this.hasOneItem(this.locations)) {
            this.updateModelWithFirstItem(this.locations, this.model, 'LOCATIONNAME');
        }
    },

    /**
     * Call the toggledDisabled function of the account input
     * and disable/endable the location selector
     * @param {boolean} disabled
     */
    toggleDisabled(disabled) {
        this.accountInputView.toggleDisabled(disabled);
        this.ui.$locationSelector.prop('disabled', disabled);
    },
});
