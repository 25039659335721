import util from '@glu/core/src/util';

export default {
    menuContext: {
        getAll() {
            return window.menuHeaderView.context;
        },

        findServiceName(serviceName) {
            /*
             * remove the first and last parts of the service that came from server
             * Routes/service are set up by convention in the format of
             * page/payment/listView/corp/PAY_LIST_VIEW
             * page/tableMaintenance/showcase/SHWCAS
             */

            if (typeof serviceName !== 'string') {
                throw new TypeError('Expected serviceName to be a string');
            }

            const start = serviceName.indexOf('/');
            const end = serviceName.lastIndexOf('/');

            if (start === -1 || start === end) {
                throw new RangeError(`Invalid argument for findServiceName: <${serviceName}>. The root cause might be a missing entitlement.`);
            }

            return serviceName.substring(start, end);
        },

        getContext(key) {
            const context = util.clone(window.menuHeaderView.context[key]) || {};

            if (context.menuContext) {
                context.serviceName = context.menuContext;
            } else {
                context.serviceName = context.serviceName
                    ? this.findServiceName(context.serviceName) : null;
            }
            return context;
        },

        getServiceName(key) {
            return this.findServiceName(window.menuHeaderView.context[key].serviceName);
        },
    },
};
