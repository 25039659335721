import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

const BalancePoller = Model.extend({
    defaults: {
        state: 'READY',
        accountFilters: [],
        hasError: false,
        hasBankErrorMsg: false,
        retryAfter: 0,
    },

    initialize() {
        util.bindAll(this, 'handleResponse', 'handleError', 'wakeUp');
        this.listenTo(this, 'change:accountFilters', this.wakeUp);
    },

    /**
     * Starts the poller and polls the server for data.
     * Retrieves the array of account numbers from
     * the attribute "accountFilters".
     * To look up all accounts set accountFilters as ['All']
     *
     */
    start(options) {
        const self = this;
        const accounts = this.get('accountFilters');
        const searchFields = [];
        const noAccountsSelected = !accounts || accounts.length === 0;
        if (noAccountsSelected) {
            return undefined;
        }

        searchFields.push({
            fieldName: 'ACCOUNTFILTER',
            fieldValue: accounts,
            dataType: 'text',
            operator: 'IN',
        });

        const subAccountNumbers = this.get('subAccountNumbers');
        if (subAccountNumbers) {
            searchFields.push({
                fieldName: 'SUBACCOUNT_NUM',
                fieldValue: subAccountNumbers,
                dataType: 'text',
                operator: 'IN',
            });
        }

        this.set({
            state: 'WORKING',
            hasError: false,
            foundNewBalances: false,
        });

        const moreFields = (options && options.searchFields) || [];

        const xhr = http.post(
            this.get('serviceUrl'),
            { ...options, searchFields: searchFields.concat(moreFields) },
            this.handleResponse,
            this.handleError,
        );
        xhr.always(() => {
            this.set('retryAfter', +xhr.getResponseHeader('retry-after') || 0);
            this.setSleepTimer(this.get('retryAfter'));
            self.trigger('pollComplete');
        });
        return xhr;
    },

    /**
     * Resets the state of this server to READY and clears out the error flag
     */
    reset() {
        this.set({
            state: 'READY',
            isRunning: false,
            hasError: false,
            foundNewBalances: false,
        });
    },

    /**
     * Checks if the poller is in a "ready" state.
     * @return {boolean} true if the state is ready, false otherwise.
     */
    isReady() {
        const accounts = this.get('accountFilters');
        return this.get('state') === 'READY' && (!util.isUndefined(accounts) && accounts.length > 0);
    },

    /**
     * Checks if the poller is in a "working" state.  This state implies
     * that the poller is in the process of querying the server.
     * @return {boolean} true if the state is working, false otherwise.
     */
    isWorking() {
        return this.get('state') === 'WORKING';
    },

    /**
     * Checks if the poller is "sleeping"
     * @return {boolean} true if the state is sleeping, false otherwise.
     */
    isSleeping() {
        return this.get('state') === 'SLEEPING';
    },

    /**
     * Checks the error state of this poller
     * @return {boolean} true if there was an error, false otherwise.
     */
    hasError() {
        return this.get('hasError');
    },

    /**
     * Returns the bank defined error message from integration platform
     * @return [array] if array size greater than 0, false otherwise.
     */
    getBankErrorMsg() {
        return this.get('hasBankErrorMsg');
    },

    /**
     * Sets this poller to "sleeping" state for a number of seconds
     * @param {number} seconds - the number of seconds
     */
    setSleepTimer(seconds) {
        clearTimeout(this.sleepTimer);
        this.set('state', 'SLEEPING');
        this.sleepTimer = setTimeout(this.wakeUp, seconds * 1000);
    },

    /**
     * Sets the state to "ready" and clears out the sleep timer if running
     */
    wakeUp() {
        clearTimeout(this.sleepTimer);
        this.set('state', 'READY');
    },

    /**
     * Callback for handling the success response from the server
     */
    handleResponse(resp) {
        this.set('hasError', false);
        this.set('foundNewBalances', resp);
        this.trigger('foundNewBalances');
    },

    /**
     * Callback for handling error response from the server.
     * Sets the hasError attribute to true for all errors except
     * for 503.
     * The 503 error is used by the server to indicate
     * that the request was sent within the timeout period and therefore
     * could not be completed.
     */
    handleError(jqXHR) {
        const resp = jqXHR.responseJSON;
        this.set('hasError', jqXHR.status !== 429);
        this.set('hasBankErrorMsg', util.isArray(resp) && resp.length > 0 ? resp : false);
    },
});

export default BalancePoller;
