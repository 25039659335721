import DynamicTypeSearch from '@glu/grid/src/filter/dynamicTypeSearch';
import TypeaheadSearch from 'system/gridOverride/views/typeaheadSearch';
import DateRangeSearch from 'system/gridOverride/views/dateRangeSearch';
import DateRangeSearch2 from 'system/gridOverride/views/dateRangeSearch2';
import MaskedInputSearch from 'system/gridOverride/views/maskedInputSearch';
import ManageFiltersLink2 from 'system/gridOverride/filter/manageFiltersLink2';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import FlexDropdown from '@glu/flex-dropdown';

/**
 * Check the notSearchable attribute for a boolean value
 * @param {Model} column
 * @returns {boolean}
 */
export const removeNotSearchable = column => !(column.has('notSearchable')
    && column.get('notSearchable') === true);

/**
 * NH-178966 When static filters are applied to the grid, do not
 * allow filtering on that field
 * @param {Model} column
 * @param {Collect} filtersCollection
 * @returns {boolean}
 */
export const removeStaticFilters = (filtersCollection, column) => {
    const filter = filtersCollection.find(f => column.get('field') === f.get('field'));
    if (!filter) {
        return true;
    }
    return filter.get('staticFilter') !== true;
};

/**
 * Check the column to determine if it should be filterable
 * @param {Model} column
 * @param {Collection} filtersCollection
 * @returns {boolean}
 */
export const filterableColumns = (filtersCollection, column) => removeNotSearchable(column)
    && removeStaticFilters(filtersCollection, column);

function getHiddenColumns(column) {
    const condition = column.get('condition');
    const isFunction = util.isFunction(condition) && condition(column) === false;
    const isBoolean = util.isBoolean(condition) && !condition;
    return (isFunction || isBoolean);
}

function getVisibleColumn(column) {
    return !getHiddenColumns(column);
}

export default DynamicTypeSearch.extend({
    initialize(...args) {
        DynamicTypeSearch.prototype.initialize.apply(this, args);
    },

    createDropdown() {
        const flexDropdownOptions = {
            data: this.getDropDownData(),
            filter: false,
            multiSelect: false,
            showGroups: true,
            preSelectedIds: this.model && this.model.get('field') ? [this.model.get('field')] : [],
            defaultSelectMessage: locale.get('selectFields'),
            label: locale.get('filter'),
            showTooltip: false,
            showCancelIcon: false,
            clearBtn: false,
            disableMultiButton: true,
        };

        if (this.options.disableAuxControl) {
            util.extend(
                flexDropdownOptions,
                {
                    customView: ManageFiltersLink2,

                    customViewOptions: {
                        grid: this.options.grid,
                        collection: this.filtersCollection,
                        filterViewClass: this.options.filterViewClass,
                        columns: this.collection,
                        filterProc: this.options.filterProc,
                    },
                },
            );
        }

        const dropdown = new FlexDropdown(flexDropdownOptions);

        this.listenTo(dropdown, 'selectionChanged:id', this.handleSelectType);

        return dropdown;
    },

    getDropDownData() {
        const itemsCollection = new Collection(this.collection
            .filter(column => filterableColumns(this.options.filtersCollection, column)));
        const visibleColumns = itemsCollection.filter(getVisibleColumn);
        const hiddenColumns = itemsCollection.filter(getHiddenColumns);
        const data = [];

        const mapper = (item) => {
            const id = item.get('field') || '';

            return {
                id,
                name: item.get('title') || id,
            };
        };

        if (this.options.gridSearch) {
            data.unshift({
                id: 'table',
                name: locale.get('entireTable'),
            });
        }

        data.push({
            id: 'visibleColumns',
            name: '',
            child: util.map(visibleColumns, mapper),
        });

        if (!hiddenColumns.length) {
            return data;
        }

        data.push({
            id: 'hiddenColumns',
            name: locale.get('notCurrentlyDisplayed'),
            child: util.map(hiddenColumns, mapper),
        });

        return data;
    },

    getSearchViewByType(type, model, ...rest) {
        const args = [type, model, ...rest];
        let view;

        if (type === 'typeahead') {
            view = new TypeaheadSearch({
                model,
            });
            view.setField(model.get('field'));
        } else if (type.toString().indexOf('date') > -1) {
            if (this.options.grid.options.enableDateTimeSupport) {
                view = new DateRangeSearch2({
                    model,
                });
            } else {
                view = new DateRangeSearch({
                    model,
                });
            }

            view.setField(model.get('field'));
        } else if (type === 'masked') {
            view = new MaskedInputSearch({
                model,
            });
        }

        if (view) {
            this.listenTo(
                view,
                {
                    filter: this.hideSearchView,
                    'filter:clear': this.clearFilter,
                    'filter:remove': this.handleRemoveFilter,
                },
            );

            return view;
        }

        return DynamicTypeSearch.prototype.getSearchViewByType.apply(this, args);
    },
});
