import PaymentFromTemplateView from 'app/payments/views/paymentFromTemplate';
import store from 'system/utilities/cache';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import workspaceHelper from 'common/workspaces/api/helper';

const CopyTaxEntryView = PaymentFromTemplateView.extend({
    initialize() {
        const contextTemp = store.get('payment_listView_smb-contextOverride');
        contextTemp.loadFromPayments = true;
        /*
         * some of these are probably wrong/unneeded.
         * Refactor from server-side menu response is warranted
         */
        const options = {
            menuCategory: 'PMTS',
            context: 'SMB_PAY_LIST',
            functionCode: contextTemp.functionCode,
            serviceName: '/batch/TaxPayments',
            subType: contextTemp.subType,
            tnum: contextTemp.tnum,
            updateCount: contextTemp.updateCount,
            mode: 'insert',
        };

        PaymentFromTemplateView.prototype.initialize.call(this, options);
    },

    actionSuccess(confirmResponse) {
        let alertMessage;
        if (confirmResponse.resultType === 'WARNING') {
            alertMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages[0],
                type: confirmResponse.resultType,
            };
            store.set('template_listView_corp-alertMessage', alertMessage);
        } else if (confirmResponse.resultType === 'DELEGATERESULT') {
            alertMessage = {
                message: confirmResponse.confirms.confirmResults[0].messages,
                type: confirmResponse.resultType,
            };
            store.set(`${this.contextKey}-alertMessage`, alertMessage);
        } else {
            if (this.options.reimburse) {
                const paymentType = util.findWhere(
                    confirmResponse.confirms.confirmResults[0].confirmData[0].item,
                    {
                        name: locale.get('ACH.PaymentType:'),
                    },
                );
                if (paymentType) {
                    paymentType.value = locale.get('ACH.reimburse');
                }
            }
            store.set(`${this.contextKey}-alertMessage`, this.mode.toUpperCase());
            store.set(`${this.contextKey}-confirms`, confirmResponse);
        }

        workspaceHelper.returnToCurrentWorkspace(this);
    },
});

export default CopyTaxEntryView;
