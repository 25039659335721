import Model from '@glu/core/src/model';
import locale from '@glu/locale';

export default Model.extend({
    defaults: {
        beneName: '',
        beneReference: '',
        beneAddress1: '',
        beneAddress2: '',
        beneAddress3: '',
        beneCountry: '',
        beneProvince: '',
        beneState: '',
        benePostalcode: '',
        benePhonenumber: '',
        beneFaxnumber: '',
        tnum: '',
        enteredBy: '',
        enteredTimestamp: '',
        enteredUsergroup: '',
        modifiedBy: '',
        modifiedTimestamp: '',
        modifiedUsergroup: '',
        approvedBy: '',
        approvedTimestamp: '',
        approvedUsergroup: '',
        lastActionTime: '',
        paymentName: '',
        paymentRid: '',
        accountType: '',
        accountNumber: '',
        bank: '',
        currency: '',
    },

    initialize() {
        this.validators = {
            name: {
                description: locale.get('setup.name'),
                exists: true,
            },
        };
    },
});
