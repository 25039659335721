import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

export default Collection.extend({

    initialize(requestData) {
        this.data = requestData || {};
    },
    defaults: {
        filters: [],
    },

    sync(method, collection, options) {
        const url = services.generateUrl('payment/getFXPaymentTotals');

        http.post(url, this.data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

    parse(response) {
        const returnArray = [];
        util.each(response.rows, (rowItem) => {
            const obj = {};
            for (let x = 0; x < rowItem.columns.length; x += 1) {
                obj[rowItem.columns[x].fieldName] = rowItem.columns[x].fieldValue;
            }
            returnArray.push(obj);
        });
        return returnArray;
    },

    processedFilterPredicate(model) {
        return model.get('STATUS') === '1';
    },

    processedData() {
        return new Collection(this.filter(this.processedFilterPredicate));
    },

    unprocessedData() {
        return new Collection(this.reject(this.processedFilterPredicate));
    },

});
