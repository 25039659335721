import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';
import SavedViewModel from 'common/dynamicPages/models/savedView';

export default Collection.extend({
    model: SavedViewModel,

    initialize(models, options) {
        this.context = options.context;
    },

    sync(method, model, options) {
        if (method === 'read') {
            let typeCode = this.context.overrideTypeCodePrevDay
                || this.context.overrideTypeCode
                || this.context.typeCode || this.context.actionContext.typeCode;
            let productCode = this.context.productCode
                || this.context.actionContext.productCode;
            let functionCode = this.context.overrideFunctionCode
                || this.context.functionCode || this.context.actionContext.functionCode;

            // use the childActionContext if one is provided
            if (this.context.childActionContext) {
                ({ typeCode } = this.context.childActionContext);
                ({ productCode } = this.context.childActionContext);
                ({ functionCode } = this.context.childActionContext);
            }

            const readService = services.generateUrl('/listViewFilter/getListViewFilters');

            const postData = {
                requestHeader: {
                    requestId: 1,
                    channelId: '',

                    requestUser: {
                        data: {
                            item: [{
                                name: '',
                                value: '',
                            }],
                        },

                        userId: '',
                    },
                },

                queryCriteria: {
                    entryClass: '',
                    fieldName: '',

                    customFilters: [{
                        filterName: '',
                        filterParam: [],
                    }],

                    subTypeCode: '',

                    action: {
                        typeCode: typeCode || '*',
                        entryMethod: 0,
                        actionMode: '',
                        productCode,
                        functionCode,
                    },

                    inquiryId: 9019,
                    queryType: 'SearchQuery',
                    queryField: '',
                    queryValue: '',
                },

                queryOffset: 0,
                queryPageSize: 0,
            };

            http.post(readService, postData, (response) => {
                options.success(response);
            }, () => {});
        }
    },

    parse(jsonData) {
        const retArr = [];
        util.each(jsonData.items, (item) => {
            const obj = {};
            util.each(item.data, (data) => {
                if (data.name.toUpperCase() === 'VIEWID') {
                    obj.viewId = data.value;
                }
                if (data.name.toUpperCase() === 'USERID') {
                    obj.system = (data.value.toUpperCase() === '_SYSTEM');
                    obj.canDelete = !(data.value.toUpperCase() === '_SYSTEM');
                    obj.canEdit = !(data.value.toUpperCase() === '_SYSTEM');
                }
                if (data.name.toUpperCase() === 'NAME') {
                    obj.text = data.value;
                }
                if (data.name.toUpperCase() === 'SHARED') {
                    obj.shared = data.value === 'Y';
                }
            });
            retArr.push(obj);
        });
        return retArr;
    },

    getModelByViewId(viewId) {
        return util.find(this.models, model => model.get('viewId') === viewId);
    },
});
