export default {
    unescapeString(str) {
        return (typeof str !== 'string') ? str
            : str.replace(/&amp;/g, '&')
                .replace(/&quot;/g, '"');
    },

    unescapeQueryResponseData(data) {
        let rows = [];
        if (data.queryResponse && data.queryResponse.QueryData) {
            rows = data.queryResponse.QueryData.queryRows || []; // PCM change
        }
        rows.forEach((row) => {
            const newRow = row;
            newRow.label = this.unescapeString(newRow.label);
            // massage map data too
            if (newRow.mapDataList) {
                newRow.mapDataList.forEach((md) => {
                    const temp = md; // b/c of eslint rule
                    temp.value = this.unescapeString(md.value);
                });
            }
        });
    },
};
