import integrations from './integrations';

export default {
    callEvent(eventName, ...args) {
        return integrations.getModules()
            .map(module => module[eventName])
            .filter(fn => fn) // remove undefined
            .map(fn => fn(...args));
    },
};
