import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import { ValidatedFlexDropdown as FlexDropdown } from '@glu/react-wrappers/FlexDropdown';
import Loader from 'pcm/common/components/Loader';
import { dataToDropdownOptions } from 'pcm/common/util/responseJSONParser';

const constants = {
    dropdownUrl: '/banking-services/api/inquiry/getQueryResults',
 
};

const getDropdownPostData = (customProps) => ({
    IncludeMapData: 1,

    queryCriteria:  {
        inquiryId: 72325,
        action: {
            typeCode: '*',
            entryMethod: 0,
            productCode: 'CLM',
            actionMode: 'UPDCHKST',
            functionCode: 'CHKMGMT',            
        },
        customFilters: [{
            filterName: 'Depends',
            filterParam: ['CHECKSTATUS', customProps.currentCheckStatus],
        }],
    },
});

class UpdateCheckStatusDropDown extends PureComponent {
    state = {
        dropdownList: [],
    };

    onDropdownDataLoaded = (data) => {
        const { onLoad } = this.props;
        this.setState({
            dropdownList: dataToDropdownOptions(data.queryResponse.QueryData.queryRows),
        }, () => {
            onLoad();
        });
    };

    onChangeCheckType = (field, id) => {
        const { onChange } = this.props;
        const { dropdownList } = this.state;
        if (id !== undefined) {
            const checkTypeName = dropdownList.find(item => item.id === id).name;
            onChange(field, id, checkTypeName);
        }
    };

    getDropdownOptions = () => {
        const { onChange } = this.props;
        const { dropdownList } = this.state;

        return {
            filter: false,
            disableClear: true,
            disableMultiButton: true,
            showCancelIcon: false,
            showTooltip: false,
            onChange: onChange ? this.onChangeCheckType : null,
            defaultSelectMessage: locale.get('checkManagement.select', ''),
            options: dropdownList || [],
        };
    };

    render() {
        const {
            name, label, required, validators,
            disabled,
        } = this.props;

        return (
            <React.Fragment>
                <Loader
                    requests={{
                        url: constants.dropdownUrl,
                        method: 'POST',
                        data: getDropdownPostData(this.props),
                    }}
                    onLoad={data => this.onDropdownDataLoaded(data)}
                    hideLoadingIndicator
                >
                    <FlexDropdown
                        required={required}
                        name={name}
                        label={label}
                        disabled={disabled}
                        {...this.getDropdownOptions()}
                        validators={validators}
                    />
                </Loader>
            </React.Fragment>
        );
    }
}

UpdateCheckStatusDropDown.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    validators: PropTypes.shape({}),
    onLoad: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    currentCheckStatus: PropTypes.string,
};

UpdateCheckStatusDropDown.defaultProps = {
    name: 'availableCheckStatusDropDown',
    label: locale.get('checkManagement.newCheckStatus'),
    required: true,
    validators: {
        name: locale.get('checkManagement.newCheckStatus'),
        required: true,
    },
    onLoad: () => {},
    onChange: PropTypes.func,
    disabled: false,
    currentCheckStatus: '',
};

export default UpdateCheckStatusDropDown;
