import { appBus } from '@glu/core';

/**
 * Get the current route, by removing the app root
 * @returns {string}
 */
export const getCurrentRoute = () => {
    const pattern = new RegExp(`^/${window.Bottomline.appRoot}/(.*)`);
    return window.location.pathname.replace(pattern, '$1');
};

/**
 * Replace the current route with another. Do not trigger the route
 * callback, only replace the route.
 * @param {string} newUrl
 */
export const replaceRoute = newUrl => appBus.trigger(
    'router:navigate',
    newUrl,
    {
        trigger: false,
        replace: true,
    },
);

/**
 * Use regex to remove the viewId from the route by replacing it.
 * Exclude the appRoot as well so that the new route is not redudant, ie. /ui/ui/...
 * @param {string} viewId
 * @returns {null}
 */
export const removeViewIdFromRoute = (viewId) => {
    if (!viewId) {
        return;
    }
    // Create regex with capture group that does not contain the app root or viewId
    const pattern = new RegExp(`^/${window.Bottomline.appRoot}/(.*)/${viewId}$`);
    const { pathname } = window.location;
    if (pathname.match(pattern)) {
        // Replace the full pathname with the capture group '$1'
        const route = pathname.replace(pattern, '$1');
        replaceRoute(route);
    }
};
