import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import patterns from 'system/validatorPatterns';

export default {

    /**
     * function shouldValidate: determine whether need to continue overriding
     * the validator
     * @param {object} field: the field to override its validator
     * @param {Model} model: the model whose validator need to be set
     */
    shouldValidate(field, model) {
        const fieldParam = field || {};
        const { fieldType } = fieldParam;

        if (util.isEmpty(fieldType) || util.isUndefined(model)) {
            return false;
        }
        if (fieldParam.editableOnCreate === false || fieldParam.fieldUIType === 'COMBOBOX') {
            return false;
        }

        // don't set up validation for amounts
        if (fieldType === 'AMOUNT' || fieldType === 'PAMOUNT') {
            return false;
        }
        return true;
    },

    /**
     * function setValidator: override a fields validator for model
     * @param {object} field: the field to override its validator
     * @param {Model} model: the model whose validator need to be override
     */
    setValidator(field, model) {
        const { name } = field;
        const { fieldType } = field;
        const modelParam = model;

        if (!this.shouldValidate(field, modelParam)) {
            return;
        }

        if (!modelParam.validators) {
            modelParam.validators = {};
        }

        /*
         * check if fieldType starts w/ _, string.startsWith does not
         * work in IE
         */
        const pattern = (fieldType.indexOf('_', 0) === 0) ? `${fieldType.substring(1)}_PATTERN` : `${fieldType}_PATTERN`;

        const addExists = modelParam.validators[name];

        if (fieldType === 'NEGATIVENUMERIC' || fieldType === 'NUMERIC') {
            modelParam.validators[name] = {
                isNumeric: true,
                description: field.fieldLabel,
            };
        } else if (fieldType === 'DATE') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matchesDatePattern: userInfo.getDateFormat(),
            };
        } else if (fieldType === 'SWIFT') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'isSwift',
            };
        } else if (fieldType === 'EMAIL') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'emailAddress',
            };
        } else if (fieldType === 'EMAILLIST') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'emailAddressList',
            };
        } else if (fieldType === 'PHONE') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'phoneNumer',
            };
        } else if (fieldType === 'PHONEPLUSONE') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'phoneNumberPlusOne',
            };
        } else if (fieldType === 'FEDWIRE') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'isFedwire',
            };
        } else if (fieldType === 'TEMPLATECODE') {
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'matchesAlphaNumeric',
            };
        } else if (fieldType === 'ALPHANUMERIC') {
            // PCM-1081
            modelParam.validators[name] = {
                description: field.fieldLabel,
                matches: patterns[pattern],
                overrideError: 'matchesAlphaNumeric',
            };
            // PCM-1081
        } else if (patterns[pattern]) {
            if (modelParam.validators[name]) {
                // add in exists
                modelParam.validators[name] = {
                    exists: true,
                    matches: patterns[pattern],
                    description: field.fieldLabel,
                };
            } else {
                modelParam.validators[name] = {
                    matches: patterns[pattern],
                    description: field.fieldLabel,
                };
            }
        }

        if (addExists) {
            // add in exists
            modelParam.validators[name].exists = true;
        }
    },

};
