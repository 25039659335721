import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import RtpConversationCollection from 'app/payments/collections/realTimePayments/rtpConversationCollection';
import services from 'services';
import store from 'system/utilities/cache';
import http from '@glu/core/src/http';
import RTPConversationModel from 'app/payments/models/realTimePayments/rtpMessageModel';
import rtpConversationDetailTmpl from './rtpConversationDetail.hbs';

export default Layout.extend({
    className: 'Conversation-Detail-View',

    template: rtpConversationDetailTmpl,

    initialize(options) {
        this.options = options;
        this.conversationStarted = this.options.conversationStarted;
        this.conversationUpdated = false;
        this.model = options.model;
        const rtpTab = store.get('payment_rtp-currentTab');
        if (rtpTab) {
            this.currentTab = rtpTab.toLowerCase();
        } else {
            this.currentTab = (this.model.get('TYPE') === 'CRTRAN') ? 'submitted' : 'received';
        }
        this.payment_id = this.model.get('PAYMENT_ID') || this.model.get('TNUM');
        this.listenTo(this.appBus, 'rtp:newMessageSuccess', this.updateConversationCollection);
        this.listenTo(this.appBus, 'dgb:drawer:wasClosed', this.checkForGridUpdate);
        this.setup();
    },

    setup() {
        // instatiate collection view here
        this.rtpConversationCollection = new RtpConversationCollection();

        /**
         * if conversation has started then retrieve it
         */
        if (this.conversationStarted) {
            this.loadRequiredData();
        } else {
            this.setHasLoadedRequiredData(true);
            this.render();
        }
    },

    loadRequiredData() {
        const reasonsPromises = this.setRFIReasons();
        const conversationPromise = this.rtpConversationCollection
            .read(this.payment_id, this.currentTab);

        Promise.all([...reasonsPromises, conversationPromise])
            .then((response) => {
                const incorrectReasons = this.formatRFIReasons(response[0].queryResponse
                    .QueryData.queryRows);
                const missingReasons = this.formatRFIReasons(response[1].queryResponse
                    .QueryData.queryRows);

                this.allReasons = [...incorrectReasons, ...missingReasons];

                const conversationItems = response[2].grids[0].items;

                let models;

                if (conversationItems.length > 0) {
                    /**
                     * this means that there is a conversation going on so
                     * we will create the models for the collection
                     */
                    models = this.createConversationModels(conversationItems);
                    this.rtpConversationCollection.add(models);
                }
                this.setHasLoadedRequiredData(true);
                this.render();
            });
    },
    onRender(view) {
        this.scrollToLastMessage(view.el);
    },

    scrollToLastMessage(viewEl) {
        const lastMessage = viewEl.querySelector('.message-group:last-child');
        if (lastMessage) {
            lastMessage.scrollIntoView();
        }
    },

    createConversationModel(attributes) {
        return new RTPConversationModel(attributes);
    },

    createConversationModels(conversationItems) {
        const self = this;
        const models = util.map(conversationItems, (item) => {
            const obj = transform.pairsToHash(item.item);
            return this.createConversationModel({
                REQUESTFORINFO_REASON_FLAG: obj.INCORRECT_MISSING_FLAG,
                REQUESTFORINFO_REASON_TYPE: locale.get(`rtp.requestForInfo.${obj.INCORRECT_MISSING_FLAG === 'I' ? 'incorrect' : 'missing'}`),
                REQUESTFORINFO_REASON_INFO_CODE: obj.INFO_CODE,
                REQUESTFORINFO_REASON: obj.INFO_CODE ? self.getRFIReason(obj.INFO_CODE).label : '',
                USERGROUP: obj.USERGROUP || obj.PARENTUSERGROUP,
                USERID: obj.USERID,
                CONVITEM_ID: obj.CONVITEM_ID,
                CASE_ID: obj.CASE_ID,
                ASSIGNMENT_ID: obj.ASSIGNMENT_ID,
                TIMESTAMP: obj.ENTERED_TIMESTAMP,
                ITEM_TYPE: obj.ITEM_TYPE,
                IS_REQUEST: obj.ITEM_TYPE === 'REQUEST',
                IS_RESPONSE: obj.ITEM_TYPE === 'RESPONSE',
                IS_ACKNOWLEDGEMENT: obj.ITEM_TYPE === 'ACK',
                PAYMENT_ID: obj.PAYMENT_ID,
                ADDITIONAL_INFO: obj.ADDITIONAL_INFO || obj.REMITTANCE_INFO || locale.get(`rtp.no${obj.ITEM_TYPE}MessageEntered`),
                requiresAdditionalClass: (this.currentTab === 'received' && obj.ITEM_TYPE !== 'RESPONSE') || (this.currentTab === 'submitted' && obj.ITEM_TYPE === 'RESPONSE'),
            });
        });

        return models;
    },

    updateConversationCollection() {
        this.conversationStarted = true;
        this.conversationUpdated = true;
        this.setup();
    },

    checkForGridUpdate() {
        if (this.conversationUpdated) {
            this.appBus.trigger('rtp:conversationUpdated');
        }
    },

    setRFIReasons() {
        const url = services.generateUrl('/tableMaintenance/getQueryResults');
        const method = 'post';
        const incorrectReasons = this.createRequestObj('I');
        const missingReasons = this.createRequestObj('M');

        // hit service and return data for dropdown
        const incorrectReasonsPromise = this.retrieveApiData(url, method, incorrectReasons);
        const missingReasonsPromise = this.retrieveApiData(url, method, missingReasons);

        return [incorrectReasonsPromise, missingReasonsPromise];
    },

    getRFIReason(infoCode) {
        return util.findWhere(this.allReasons, {
            name: infoCode,
        });
    },

    formatRFIReasons(reasons) {
        return reasons.map(item => ({
            name: item.name,
            label: item.label,
        }));
    },

    /**
     * Create request obj for API dropdown data retrieval
     * @param  {string} reason string describing which radio button selected
     * @return {obj} request object for API call
     */
    createRequestObj(reason) {
        const requestObj = {
            queryCriteria: {
                action: {
                    productCode: 'RTP',
                    functionCode: 'MSGS',
                    typeCode: 'RTPREQ',
                    actionMode: 'SELECT',
                },
                subTypeCode: '*',
                fieldName: reason === 'I' ? 'INCORRECT_INFO_CODE' : 'MISSING_INFO_CODE',
                allowDuplicates: false,
            },
        };

        return requestObj;
    },

    retrieveApiData/* istanbul ignore next */(url, method, data) {
        return new Promise((resolve, reject) => {
            http[method](url, data).then((response) => {
                resolve(response);
            }, (error) => {
                reject(error);
            });
        });
    },

    /**
     * replaces existing messages with properly unescaped characters prior to showing within
     * the widget. Does not mutate actual model
     * @param  {Object[]} messages - messages within the widget
     * @return {Object[]} properly unescape array of messages
     */
    unescapeStrings(messages) {
        return messages.map(message => ({
            ...message,
            ADDITIONAL_INFO: util.unescape(message.ADDITIONAL_INFO),
        }));
    },

    templateHelpers() {
        return {
            unescapeCharacters: this.unescapeStrings(this.rtpConversationCollection.toJSON()),
            currentTab: this.currentTab,
        };
    },
});
