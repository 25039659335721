import Marionette from 'backbone.marionette';
import Glu from './glu';
import $ from './$';
import util from './util';
import appBus from './appBus';

export default Marionette.AppRouter.extend({
  constructor() {
    Marionette.AppRouter.prototype.constructor.apply(this, Array.prototype.slice.call(arguments));
    this.addGluEvents();
  },

  appBus,

  addGluEvents() {
    // only create a single set of listeners for the glu events or we get one for each router all repeatedly trying to do the same thing
    // use glu.history to store that we have added events.
    // This allows integration of code on different versions of glu via webpack sandboxing while still sharing common libraries like backbone.
    // As long as we share backbone we don't have to deal with multiple instances of bacbbone.history and routing, even if we have multiple glu/router's.
    if (Glu.history.appBusRoutingEventsAdded) {
      return;
    }

    Glu.history.appBusRoutingEventsAdded = true;

    // Event-based router navigation
    Glu.history.listenTo(this.appBus, 'router:navigate', (fragment, options) => {
      this.navigate(fragment, options);
    });

    // Event-based 'refresh'
    Glu.history.listenTo(this.appBus, 'router:reload', () => {
      // Do a little redirection dance to ensure that we reload the whole page
      const returnUrl = Glu.history.fragment;
      this.navigate('redirecting', {
        trigger: true,
        replace: false
      });
      this.navigate(returnUrl, {
        trigger: true,
        replace: true
      });
    });

    this.listenToKeyboard();
  },

  execute() {
    this.appBus.trigger('route', arguments);
    Marionette.AppRouter.prototype.execute.apply(this, arguments);
  },

  listenToKeyboard() {
    let isMetaKey = false;

    // only register the DOM events and initialize the key bindings hash once
    if (!Glu.history.routerKeyBindings) {
      Glu.history.routerKeyBindings = {};

      $('html').on('keydown', (e) => {
        if (!Glu.History.started) {
          return undefined;
        }

        // Is 'ctrl' or 'cmd' is being held down
        if (e.metaKey) {
          isMetaKey = true;
        }

        // Ignore keydown on input areas and meta keys
        const tag = e.target.tagName;
        if (tag === 'INPUT' || tag === 'SELECT' || tag === 'TEXTAREA' || tag === 'BUTTON' || tag.isContentEditable || isMetaKey) {
          return undefined;
        }

        const key = String.fromCharCode(e.keyCode);
        const action = Glu.history.routerKeyBindings[key];

        if (!action) {
          return undefined;
        }

        return (typeof action === 'function') ? action() : Glu.history.navigate(action, true);
      });

      // Is 'ctrl' or 'cmd' released
      $('html').on('keyup', (e) => {
        if (e.metaKey || e.keyCode === 91) {
          isMetaKey = false;
        }
      });

      // If 'ctrl' or 'cmd' keyup could not be read due to popup
      $(window).on('blur', () => {
        isMetaKey = false;
      });
    }

    this.addKeyBindings();
  },

  addKeyBindings() {
    // add keybindings to the hash used for responding to keyboard events
    util.each(this.keyBindings, (value, key) => {
      key = key.toUpperCase();
      Glu.history.routerKeyBindings[key] = (typeof value === 'function') ? value.bind(this) : value;
    });
  }
});

