import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import services from 'services';
import { postData } from 'common/util/services';
import systemConfig from 'system/configuration';

const flattenResponse = function (response) {
    return response.queryResponse.QueryData.queryRows;
};

export default {
    getEntitlements(userGroup) {
        const url = services.generateUrl('panelApprovalWorkflow/getEntitledActions');
        const requestData = {
            data: [{
                name: 'USERGROUP',
                value: userGroup,
            }],

            typeCode: 'PNLPR_TM',
            productCode: systemConfig.isAdmin() ? 'CMAINT' : '_ADMIN',
            functionCode: 'MAINT',
        };
        return http.post(url, requestData).then(data => data.actionModes);
    },

    getCurrencyRequest(userGroup) {
        const url = services.generateUrl('/inquiry/getQueryResults');

        return http.post(
            url,
            {
                IncludeMapData: 1,

                queryCriteria: {
                    inquiryId: '40009',

                    customFilters: [{
                        filterName: 'Depends',

                        filterParam: [
                            'UserGroup',
                            userGroup,
                        ],
                    }],

                    action: {
                        typeCode: 'PNLPR_TM',
                        entryMethod: 0,
                        productCode: systemConfig.isAdmin() ? 'CMAINT' : '_ADMIN',
                        actionMode: 'INSERT',
                        functionCode: 'MAINT',
                    },
                },

                requestHeader: {
                    queryPagesize: 0,
                    queryOffset: 0,
                },
            },
        ).then(flattenResponse).then(response => transform.pairsToHash(response[0].mapDataList, 'toField', 'value').BaseCurrency);
    },

    getApproverTypesRequest(userGroup) {
        const url = services.generateUrl('panelmaint/READCHILDREN');
        return http.post(
            url,
            {
                item: {
                    item: [{
                        name: 'USERGROUP',
                        value: userGroup,
                    }],
                },
            },
        ).then(response => util.map(response.grids[0].items, (item) => {
            const obj = transform.pairsToHash(item.item);
            return {
                type: obj.APPROVERTYPE,
                id: obj.APPROVERTYPE,
                name: obj.NAME,
            };
        }));
    },

    getThresholds(profileCode) {
        const url = services.generateUrl('/inquiry/getQueryResults');
        return http.post(
            url,
            {
                IncludeMapData: 1,

                queryCriteria: {
                    inquiryId: '40010',

                    customFilters: [{
                        filterName: 'Depends',

                        filterParam: [
                            'PANELPROFILECODE',
                            profileCode,
                        ],
                    }],

                    allowDuplicates: 'true',

                    action: {
                        productCode: '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'INSERT',
                        typeCode: 'PRENTDET',
                    },
                },
            },
        ).then(flattenResponse).then(flattened => util.chain(flattened).map(panel => transform.pairsToHash(panel.mapDataList, 'toField', 'value')).groupBy('THRESHOLDAMOUNT').value());
    },

    getSequenceRequest() {
        const url = services.generateUrl('/inquiry/getQueryResults');
        return http.post(
            url,
            {
                IncludeMapData: 0,

                queryCriteria: {
                    inquiryId: '17041',

                    action: {
                        typeCode: 'BPRNTPRA',
                        entryMethod: 0,
                        productCode: systemConfig.isAdmin() ? 'CMAINT' : '_ADMIN',
                        actionMode: 'SELECT',
                        functionCode: 'MAINT',
                    },
                },

                requestHeader: {
                    queryPagesize: 0,
                    queryOffset: 0,
                },
            },
        ).then(flattenResponse).then(response => response.map(type => ({
            id: type.name,
            name: type.label,
        })));
    },

    getApproverRequests() {
        const requests = [];
        for (let i = 17035; i <= 17040; i += 1) {
            const url = services.generateUrl('/inquiry/getQueryResults');
            const data = {
                requestHeader: {
                    queryOffset: 0,
                    queryPagesize: 0,
                },

                IncludeMapData: 0,

                queryCriteria: {
                    action: {
                        typeCode: 'BPRNTPRA',
                        entryMethod: 0,
                        productCode: '_ADMIN',
                        functionCode: 'MAINT',
                        actionMode: 'SELECT',
                    },

                    // for dropdowns 1-6
                    inquiryId: i,
                },
            };

            const promise = http.post(url, data).then(flattenResponse);

            requests.push(promise);
        }
        return requests;
    },

    actionRequest(endpoint, userGroup, profileCode) {
        const data = {
            item: {
                item: [{
                    name: 'USERGROUP',
                    value: userGroup,
                }, {
                    name: 'PANELPROFILECODE',
                    value: profileCode,
                }],
            },
        };

        return postData(endpoint, data);
    },
};
