import $ from 'jquery';
import PaymentUtil from 'common/util/paymentUtil';

export default function (form, initialState) {
    const { model } = form.formView;
    const { collection } = model;
    const $saveAsTemplate = $('[data-section="save-as-template"]');
    const entryMethod = model.get('ENTRY_METHOD');
    const restrict = form.field('RESTRICTTEMPLATE_FLAG');
    const saveAsTemplate = form.field('SAVEASTEMPLATE');
    const templateCode = form.field('TEMPLATE_CODE');
    const templateDescription = form.field('TEMPLATE_DESCRIPTION');

    /**
     * @method validateTemplateInfo
     * @param {boolean} saveTemplateChecked: true if the SAVEASTEMPLATE field is checked
     *
     * When the Save as Template checkbox is selected, display the Template Code,
     * Template Description, and Restricted checkbox.  Otherwise hide the fields.
     */
    const validateTemplateInfo = function (saveTemplateChecked) {
        const entryMeth = model.get('ENTRYMETHOD');
        // Clear out Template Code and Template Description when not visible
        if (!saveTemplateChecked && (!entryMeth || entryMeth === '0')) {
            model.set({
                TEMPLATE_CODE: '',
                TEMPLATE_DESCRIPTION: '',
            });
            templateCode.setValue('');
            templateDescription.setValue('');
        }
        templateCode.shouldBeVisibleWhen(saveTemplateChecked);
        templateDescription.shouldBeVisibleWhen(saveTemplateChecked);
        restrict.shouldBeVisibleWhen(saveTemplateChecked);
        PaymentUtil.validateTemplateInfo({
            saveTemplateChecked,
            templateCode,
            templateDescription,
            tempCodeFieldName: 'TEMPLATE_CODE',
            tempDesFieldName: 'TEMPLATE_DESCRIPTION',
        }, model);
    };

    /**
     * Establish which model is affected by the changing of template fields. Really only
     * complicated for a multi-add form where we'd like the template details to go into the
     * first model of the collection. On a standard form, just affect the model like normal
     */
    const setupTemplateFieldListeners = function () {
        const modelToAffect = collection ? collection.at(0) : model;

        $saveAsTemplate.off('change', '[name="SAVEASTEMPLATE"]');
        $saveAsTemplate.on('change', '[name="SAVEASTEMPLATE"]', (e) => {
            modelToAffect.set('SAVEASTEMPLATE', e.target.checked ? '1' : '0');
            validateTemplateInfo(e.target.checked);
        });

        $saveAsTemplate.off('change', '[name="RESTRICTTEMPLATE_FLAG"]');
        $saveAsTemplate.on('change', '[name="RESTRICTTEMPLATE_FLAG"]', (e) => {
            modelToAffect.set('RESTRICTTEMPLATE_FLAG', e.target.checked ? '1' : '0');
        });

        $saveAsTemplate.off('change', '[name="TEMPLATE_CODE"]');
        $saveAsTemplate.on('change', '[name="TEMPLATE_CODE"]', (e) => {
            modelToAffect.set('TEMPLATE_CODE', e.target.value);
        });

        $saveAsTemplate.off('change', '[name="TEMPLATE_DESCRIPTION"]');
        $saveAsTemplate.on('change', '[name="TEMPLATE_DESCRIPTION"]', (e) => {
            modelToAffect.set('TEMPLATE_DESCRIPTION', e.target.value);
        });
    };

    if (model.jsonData.typeInfo.functionCode === 'INST' || model.jsonData.typeInfo.functionCode === 'REQUEST') {
        if (initialState) {
            /*
             * In addition to entryMethod we need to take into account collection length.
             * The appendTo in the else if takes the fields from the form and places them
             * into the footer is limited to just the first form since NH-172523, so we
             * need to be sure $saveAsTemplate is hidden on subsequent forms.
             */
            if (entryMethod === '1' || entryMethod === '3' || collection?.length > 1) {
                saveAsTemplate.$el.closest('.section').addClass('hidden');
            } else if (!collection || collection.length === 1) {
                /*
                 * NH-172523 - This code should execute on the initial state of a single entry
                 * payment or for the first transaction of a multi entry payment screen. The fields
                 * affected only exist once in the footer section and are not part of the payment
                 * form view. This policy file executes in an initial state for each payment added
                 * in a multi entry context so we need to check that we only execute for the first
                 * instance (the "initial" initialState).
                 */
                $saveAsTemplate.empty();
                saveAsTemplate.$el.closest('.section').appendTo($saveAsTemplate);
                saveAsTemplate.$el.closest('.section').removeClass('section section-container');
                templateCode.setValue('');
                templateDescription.setValue('');
            }

            validateTemplateInfo(saveAsTemplate.$el.is(':checked'));

            setupTemplateFieldListeners();
            /*
             * if this form features a collection (multi add form) add a listener to account for
             * the potentially changed template model when removing models from the collection
             */
            if (collection && !collection.hasRemoveTemplateFlag) {
                collection.on('remove', setupTemplateFieldListeners);
                collection.on('paymentRemoved', validateTemplateInfo);
                collection.hasRemoveTemplateFlag = true;
            }
        }
    }
}
