import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import CollectionView from '@glu/core/src/collectionView';
import constants from 'app/administration/constants';
import SimpleEntitlements from 'app/administration/collection/user/simpleEntitlements';
import Collection from '@glu/core/src/collection';
import LimitSetCollectionView from '../../payments/assignedPermissions/limitSetCollectionView';
import AssignedAccountsView from './absAssignedAccounts';
import MessageAccountsView from './assignedMessageAccounts';
import PermCheckView from '../../permCheckView';
import absTypesTmpl from './absTypes.hbs';

export default Layout.extend({
    tagName: 'div',
    className: 'panel permission-group',
    template: absTypesTmpl,

    ui: {
        $selectAllReqIn: '.select-all-reqin',
        $selectAllReqOut: '.select-all-reqout',
        $selectAllReqOutTmpl: '.select-all-reqouttmpl',
        $selectAllMsgs: '.select-all-msgs',
    },

    events: {
        'click @ui.$selectAllReqIn': 'selectAllReqInPerms',
        'click @ui.$selectAllReqOut': 'selectAllReqOutPerms',
        'click @ui.$selectAllReqOutTmpl': 'selectAllReqOutTmplPerms',
        'click @ui.$selectAllMsgs': 'selectAllMsgsPerms',
    },

    initialize(options) {
        this.entitledTypes = options.entitledTypes;
        this.mode = options.mode;
        this.userGroup = options.userGroup;

        const complexTypes = this.model.get('complexTypes');
        const typesInGroup = complexTypes.length > 0 ? complexTypes.at(0).get('typesInGroup') : new Collection();
        typesInGroup.each(type => type.set('entitled', true));
        const detailedTypeEntitlements = this.extractDetailEntitlements(typesInGroup);

        // Messages are simple types.
        const simpleTypes = this.model.get('simpleTypes');
        const msgTypes = simpleTypes.length > 0 ? simpleTypes.at(0) : new Collection();

        this.reqInPermissions = this.getPermissions(detailedTypeEntitlements, 'request', ['REQIN']);
        this.reqOutPermissions = this.getPermissions(detailedTypeEntitlements, 'request', ['REQOUT']);
        this.reqOutTmplPermissions = this.getPermissions(detailedTypeEntitlements, 'reqtmpl', ['REQOUT'], ['freeForm']);
        this.msgsPermissions = msgTypes.get('entitlementsByType');

        this.requestTypeDataEntitlements = typesInGroup.filter(item => item.get('typeDataEntitlements').length > 0 && util.contains(['REQIN', 'REQOUT'], item.get('typeDataEntitlements').at(0).get('type')));
        this.msgsTypeDataEntitlements = msgTypes.get('simpleDataEntitlements');
    },

    onRender() {
        if (this.hasReqInPermissions()) {
            this.reqInPermissionsView = new LimitSetCollectionView({
                mode: this.mode,
                collection: this.reqInPermissions,
            });
        }

        if (this.hasReqOutPermissions()) {
            this.reqOutPermissionsView = new LimitSetCollectionView({
                mode: this.mode,
                collection: this.reqOutPermissions,
            });
        }

        if (this.hasReqOutTmplPermissions()) {
            this.reqOutTmplPermissionsView = new LimitSetCollectionView({
                mode: this.mode,
                collection: this.reqOutTmplPermissions,
            });
        }

        if (this.hasMsgsPermissions()) {
            this.msgsPermissionsView = new CollectionView({
                itemView: PermCheckView,
                collection: this.msgsPermissions,

                itemViewOptions: {
                    tagName: 'div',
                    className: 'checkbox',
                    mode: this.mode,
                },
            });
        }

        if (this.hasReqInPermissions() || this.hasReqOutPermissions()
            || this.hasReqOutTmplPermissions()) {
            this.requestAssignedAccountsView = new AssignedAccountsView({
                collection: this.requestTypeDataEntitlements[0].get('typeDataEntitlements'),
                mode: this.mode,
                model: this.requestTypeDataEntitlements[0],
                userGroup: this.userGroup,
                entitledTypes: this.entitledTypes,
                isRole: this.options.isRole,
            });
        }

        if (this.hasMsgsPermissions()) {
            this.msgsAssignedAccountsView = new MessageAccountsView({
                mode: this.mode,
                model: this.model.get('simpleTypes').at(0),
                userGroup: this.userGroup,
                entitledTypes: this.entitledTypes,
                isRole: this.options.isRole,
            });
        }

        this.renderPermissions();
    },

    /**
     * Returns the permissions for a function/types/entry method
     * @param  {Array} detailedTypeEntitlements  - the map of permissions
     * @param  {string} functionCode
     * @param  {Array} types - types per function code
     * @param  {string} entryMethods optional
     * @return {Collection} SimpleEntitlements - collection of entitlements
     */
    getPermissions(detailedTypeEntitlements, functionCode, types, entryMethods) {
        let entitlements = [];
        if (!util.isNullOrUndefined(detailedTypeEntitlements)) {
            entitlements = detailedTypeEntitlements.filter((model) => {
                const hasFunc = model.get('functionCode') === functionCode;
                const hasType = util.contains(types, model.get('typeCode'));
                const hasEntryMethod = entryMethods ? util.contains(entryMethods, model.get('entryMethod')) : true;
                const isEntitled = model.isEntitled();
                const isView = this.mode === constants.MODES.VIEW;
                return hasFunc && hasType && hasEntryMethod && (!isView || isEntitled);
            });
        }
        return new SimpleEntitlements(entitlements.length > 0 ? entitlements : []);
    },

    /**
     * Returns the permissions for a function/types/entry method
     * @param  {Array} typesInGroup  - array of types
     * @return {Array} array of permissions
     */
    extractDetailEntitlements(typesInGroup) {
        return typesInGroup.chain()
            .map(item => item.get('detailedTypeEntitlements').toArray())
            .reduce((a, b) => a.concat(b))
            .value();
    },

    /**
     * Renders the appropriate regions
     */
    renderPermissions() {
        if (this.reqInPermRegion) {
            this.reqInPermRegion.show(this.reqInPermissionsView);
        }
        if (this.reqOutPermRegion) {
            this.reqOutPermRegion.show(this.reqOutPermissionsView);
        }
        if (this.reqOutTemplPermRegion) {
            this.reqOutTemplPermRegion.show(this.reqOutTmplPermissionsView);
        }
        if (this.msgsPermRegion) {
            this.msgsPermRegion.show(this.msgsPermissionsView);
        }

        if (this.requestAccountsRegion) {
            this.requestAccountsRegion.show(this.requestAssignedAccountsView);
        }
        if (this.msgsAccountsRegion) {
            this.msgsAccountsRegion.show(this.msgsAssignedAccountsView);
        }
    },

    /**
     * Selects all permissoins for Inbound RFP
     */
    selectAllReqInPerms(evt) {
        if (this.reqInPermissionsView) {
            this.reqInPermissions.selectAll(evt.currentTarget.checked);
            this.reqInPermissionsView.render();
        }
    },

    /**
     * Selects all permissoins for Outbound RFP
     */
    selectAllReqOutPerms(evt) {
        if (this.reqOutPermissionsView) {
            this.reqOutPermissions.selectAll(evt.currentTarget.checked);
            this.reqOutPermissionsView.render();
        }
    },

    /**
     * Selects all permissions for Outbound RFP templates
     */
    selectAllReqOutTmplPerms(evt) {
        if (this.reqOutTmplPermissionsView) {
            this.reqOutTmplPermissions.selectAll(evt.currentTarget.checked);
            this.reqOutTmplPermissionsView.render();
        }
    },

    /**
     * Selects all permissions for RFP messages
     */
    selectAllMsgsPerms(evt) {
        // Iterate for simple entitlements and set.
        if (this.msgsPermissions) {
            this.msgsPermissions.each((permission) => {
                permission.set('entitled', evt.currentTarget.checked);
            });
        }
    },

    /**
     * Returns whether or not inbound RFP is entitled
     * @return {boolean}
     */
    hasReqInPermissions() {
        return this.reqInPermissions && this.reqInPermissions.length > 0
            && (this.mode !== constants.MODES.VIEW
                || this.reqInPermissions.hasAnyEntitledAction());
    },

    /**
     * Returns whether or not outbound RFP is entitled
     * @return {boolean}
     */
    hasReqOutPermissions() {
        return this.reqOutPermissions && this.reqOutPermissions.length > 0
            && (this.mode !== constants.MODES.VIEW
                || this.reqOutPermissions.hasAnyEntitledAction());
    },

    /**
     * Returns whether or not outbound RFP templates is entitled
     * @return {boolean}
     */
    hasReqOutTmplPermissions() {
        return this.reqOutTmplPermissions && this.reqOutTmplPermissions.length > 0
            && (this.mode !== constants.MODES.VIEW
                || this.reqOutTmplPermissions.hasAnyEntitledAction());
    },

    /**
     * Returns whether or not outbound RFP templates is entitled
     * @return {boolean}
     */
    hasReqAccounts() {
        return this.hasReqInPermissions() || this.hasReqOutPermissions()
            || this.hasReqOutTmplPermissions();
    },

    /**
     * Returns whether or not RFP messages is entitled
     * @return {boolean}
     */
    hasMsgsPermissions() {
        return this.msgsPermissions && this.msgsPermissions.length > 0
            && (this.mode !== constants.MODES.VIEW
                || this.msgsPermissions.hasAnyEntitledAction());
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            readOnly: this.mode === constants.MODES.VIEW,
            hasReqInPermissions: this.hasReqInPermissions(),
            hasReqOutPermissions: this.hasReqOutPermissions(),
            hasReqOutTmplPermissions: this.hasReqOutTmplPermissions(),
            hasMsgsPermissions: this.hasMsgsPermissions(),
            hasReqAccounts: this.hasReqAccounts(),
        };
    },
});
