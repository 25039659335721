import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';

export default Collection.extend({
    model: Model,

    parse(response) {
        return response.map(row => transform.pairsToHash(row.columns, 'fieldName', 'fieldValue'));
    },

    sync(method, model, options) {
        const url = services.generateUrl('/inquiry/getData');
        const postData = {
            requestHeader: {},

            inquiryRequest: {
                searchCriteria: {
                    action: {
                        typeCode: 'CTRLDISB',
                        productCode: 'GIR',
                        functionCode: 'CD',
                        actionMode: 'SELECT',
                    },

                    searchType: '5',
                    inquiryId: 22477,
                },
            },
        };

        http.post(url, postData, (result) => {
            options.success(result.inquiryResponse.rows);
        }, (result) => {
            options.error(result);
        });
    },
});
