import ItemView from '@glu/core/src/itemView';
import numeral from 'numeral';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import displayFactorViewTmpl from './displayFactorView.hbs';

const DisplayFactorView = ItemView.extend({
    className: 'display-factor-legend form-inline',
    template: displayFactorViewTmpl,

    ui: {
        $select: 'select',
    },

    events: {
        'change @ui.$select': 'handleSelectChange',
    },

    modelEvents: {
        'change:displayFactor': 'handleModelChange',
    },

    handleSelectChange() {
        this.model.set({
            displayFactor: +this.ui.$select.val(),
        }, {
            changeSource: this.cid,
        });
    },

    handleModelChange(model, value, options) {
        if (options && options.changeSource === this.cid) {
            return;
        }

        this.render();
    },

    getFormattedNumber(number) {
        if (number === 1) {
            return locale.get('cashflow.no.scaling');
        }

        return numeral(number).format(`0${userInfo.get('thousandsSeparator')}0`);
    },

    getFormattedOptions(options) {
        return util.map(options, function (option) {
            return {
                value: option,
                name: this.getFormattedNumber(option),
            };
        }, this);
    },

    templateHelpers() {
        return {
            id: this.cid,
            options: this.getFormattedOptions([1, 1000, 1000000]),
        };
    },

    onRender() {
        this.ui.$select.val(this.model.get('displayFactor'));
    },
});

export default DisplayFactorView;
