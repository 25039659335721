import { modifyDataToPost } from 'pcm/common/serviceGridUtils';
import configuration from 'system/configuration';

const dataToPostBACCTTM = inquiryId => ({
    IncludeMapData: 1,
    queryCriteria: {
        inquiryId,
        action: {
            typeCode: 'BACCT_TM',
            entryMethod: 0,
            productCode: '_ADMIN',
            actionMode: 'SELECT',
            functionCode: 'MAINT',
        },
    },
});

const dataToPostBENEADBK = ({ inquiryId, customFilters }) => ({
    IncludeMapData: 1,
    queryCriteria: {
        customFilters,
        inquiryId,
        action: {
            typeCode: 'BENEADBK',
            entryMethod: 0,
            productCode: '_ADMIN',
            actionMode: 'INSERT',
            functionCode: 'ALERT',
        },
    },
});

const dataToPostADMINCountry = ({ inquiryId, customFilters }) => ({
    IncludeMapData: 1,
    queryCriteria: {
        customFilters,
        inquiryId,
        action: {
            typeCode: '*',
            entryMethod: 0,
            productCode: '_ADMIN',
            actionMode: 'INSERT',
            functionCode: 'MAINT',
        },
    },
});


const dataToPostSHWCASTB = ({ inquiryId, customFilters }) => ({
    IncludeMapData: 1,
    queryCriteria: {
        customFilters,
        inquiryId,
        action: {
            typeCode: 'SHWCASTB',
            entryMethod: 0,
            productCode: 'SHWCAS',
            actionMode: 'INSERT',
            functionCode: 'MAINT',
        },
    },
});

const dataToPostADMINState = ({ inquiryId, customFilters }) => ({
    IncludeMapData: 1,
    queryCriteria: {
        customFilters,
        inquiryId,
        action: {
            typeCode: '*',
            entryMethod: 0,
            productCode: 'CMAINT',
            actionMode: 'INSERT',
            functionCode: 'MAINT',
        },
    },
});

export function getDropdownPostData(type) {
    const typeToPostData = {
        country: configuration.isAdminCheckMgtApp() ? dataToPostADMINCountry({
            inquiryId: 50164,
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['label', 'Description'],
            }],
        }) : dataToPostBENEADBK({
            inquiryId: 17076,
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['label', 'Description'],
            }],
        }),
        state: configuration.isAdminCheckMgtApp() ? dataToPostADMINState({
            inquiryId: 10555,
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['label', 'Description'],
            }],
        }) : dataToPostSHWCASTB({
            inquiryId: 9600,
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['label', 'Description'],
            }],
        }),
        accountTypes: dataToPostBACCTTM(50005),
        currency: dataToPostBACCTTM(50009),
        banks: {
            rowsPerPage: 200, // shouldn't be more then 50, but just in case
            startRow: 1,
            fieldName: 'BANK_LIST',
            typeCode: 'BACCT_TM',
            productCode: '_ADMIN',
            functionCode: 'MAINT',
            depends: [],
            subType: 'SINGLE',
        },
        fundingMethods: dataToPostBACCTTM(50008),
    };

    return typeToPostData[type];
}

export function defaultSort(data, key) {
    return data.sort((a, b) => {
        if (a[key] > b[key]) {
            return 1;
        }

        if (a[key] < b[key]) {
            return -1;
        }

        return 0;
    });
}

function getEntitlementsOptions(typeCode, serviceName) {
    return {
        context: [{
            name: 'productCode',
            value: '_ADMIN',
        }, {
            name: 'functionCode',
            value: 'MAINT',
        }, {
            name: 'typeCode',
            value: typeCode,
        }],
        entryMethod: 0,
        overrideContext: {
            serviceName: `/tableMaintenance/${serviceName}`,
        },
    };
}

export const bankDataToPost = modifyDataToPost([{
    name: 'inquiryId',
    value: '50001',
}, {
    name: 'typeCode',
    value: 'CBANK_TM',
}]);

export const bankAccountDataToPost = modifyDataToPost([{
    name: 'inquiryId',
    value: '50002',
}, {
    name: 'typeCode',
    value: 'BACCT_TM',
}]);

export const achDataToPost = modifyDataToPost([{
    name: 'inquiryId',
    value: '50003',
}, {
    name: 'typeCode',
    value: 'ACHCO_TM',
}]);

export const achCompanyDataToPost = modifyDataToPost([{
    name: 'inquiryId',
    value: '50010',
}, {
    name: 'typeCode',
    value: 'ACHCO_TM',
}, {
    name: 'ID',
    value: 34,
}]);

// "BACCT_TM", "ACHCO_TM", "CBANK_TM"
export const bankEntitlementsOptions = getEntitlementsOptions('CBANK_TM', 'clientBank');
export const achEntitlementsOptions = getEntitlementsOptions('ACHCO_TM', 'ACHCompanies');
export const accountEntitlementsOptions = getEntitlementsOptions('BACCT_TM', 'clientBankAccounts');

export default {
    accountEntitlementsOptions,
    achEntitlementsOptions,
    bankEntitlementsOptions,
    defaultSort,
    getDropdownPostData,
    bankDataToPost,
    bankAccountDataToPost,
    achDataToPost,
};
