var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"row\">\n                        <h2 class=\"col-md-12\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.graph.activities",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":84}}}))
    + "</h2>\n        </div>\n        <div class=\"row manager-main\">\n                <div class=\"primary col-md-7\"></div>\n                <div class=\"secondary col-md-5\"></div>\n        </div>\n        <div class=\"row actions view-mode\">\n                <div class=\"col-md-7\">\n                        <button class=\"btn btn-primary\" data-hook=\"done\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.back.to.graph",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":73},"end":{"line":11,"column":108}}}))
    + "</button>\n                        <button class=\"btn btn-link pull-right\" data-hook=\"editLines\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.edit.scenario.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":86},"end":{"line":12,"column":125}}}))
    + "</button>\n                </div>\n                <div class=\"col-md-5\">\n                        <button class=\"btn btn-link pull-right\" data-hook=\"editScenarios\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.activity.edit.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":90},"end":{"line":15,"column":129}}}))
    + "</button>\n                </div>\n\n        </div>\n        <div class=\"row actions edit-mode hidden scenario\">\n                <div class=\"col-md-12\">\n                        <button class=\"btn btn-primary pull-right\" data-hook=\"saveEdits\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.edit.done.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":89},"end":{"line":21,"column":124}}}))
    + "</button>\n                </div>\n\n        </div>\n        <div class=\"row actions edit-mode hidden line\">\n                <div class=\"col-md-7\">\n                        <button class=\"btn btn-primary pull-right\" data-hook=\"saveEdits\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.edit.done.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":89},"end":{"line":27,"column":124}}}))
    + "</button>\n                </div>\n\n        </div>\n";
},"useData":true});