var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":45}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":14,"column":60},"end":{"line":14,"column":68}}}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add-payment\">\n    <form autocomplete=\"off\" data-submit=\"save\">\n\n        <div class=\"validation-group hide\" role=\"alert\">\n            <p class=\"help-block center\" data-validation=\"generic\"></p>\n        </div>\n\n        <div class=\"form-group account-group\">\n            <label for=\"account\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.pay.from.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":52},"end":{"line":9,"column":93}}}))
    + ":</label>\n            <div class=\"field-container\">\n                <select class=\"form-control\" data-bind=\"model\" name=\"account\" data-hook=\"select-account\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNT\"></span>\n            </div>\n            <div class=\"field-container checkbox-input-group\">\n                <label for=\"withholdPayment\">\n                    <input type=\"checkbox\" name=\"withholdPayment\" id=\"withholdPayment\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"withholdPayment") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":105},"end":{"line":21,"column":142}}})) != null ? stack1 : "")
    + ">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.withhold.payment.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":65}}}))
    + "\n                </label>                \n            </div>\n        </div>\n\n\n        <div class=\"form-group payment-date-group\">\n            <label for=\"paymentDate\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.pay.on.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":56},"end":{"line":29,"column":92}}}))
    + ":</label>\n            <div class=\"field-container\">\n                <div class=\"payment-date-container\">\n                    <input type=\"text\" name=\"PAYDATE\" id=\"dateRange\" class=\"dateRange\" data-bind=\"model\"/>\n                </div>\n            </div>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"PAYDATE\"></span>\n            <div class=\"field-container popover-group\">\n                <label for=\"expeditedPayment\">\n                    <input type=\"checkbox\" name=\"expeditedPayment\" id=\"expeditedPayment\" data-bind=\"model\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.pay.send.expedited",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":39,"column":63}}}))
    + "\n                </label>\n\n                <a tabindex=\"0\" class=\"btn btn-popover\" role=\"button\" data-toggle=\"popover\" title=\"\" data-placement=\"top\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"smbPayments.send.expedited.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":136},"end":{"line":42,"column":179}}}))
    + "\">\n                    <span class=\"icon-info-circle\"></span>\n                </a>\n\n            </div>\n\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n        </div>\n    </form>\n</div>\n";
},"useData":true});