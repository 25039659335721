var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "accountGroups";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"GIR.btr.showGroupTotals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":40},"end":{"line":17,"column":76}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"GIR.btr.showAccountTotals",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":19,"column":78}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <h4 class=\"AccountGroup-heading\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"ACCOUNT_GROUP_NAME") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_GROUP_NAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ACCOUNT_GROUP_NAME","hash":{},"data":data,"loc":{"start":{"line":31,"column":65},"end":{"line":31,"column":87}}}) : helper)))
    + "</h4>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"account-group\">\n                                        <h4 class=\"SummaryDetails accountTotals\">\n                                                <span class=\"Details-secondary\">\n                                                        <div data-hook=\"insert-currency-flag-"
    + alias4(((helper = (helper = lookupProperty(helpers,"CURRENCY_CODE") || (depth0 != null ? lookupProperty(depth0,"CURRENCY_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CURRENCY_CODE","hash":{},"data":data,"loc":{"start":{"line":45,"column":93},"end":{"line":45,"column":110}}}) : helper)))
    + "\"></div>\n                                                        <span data-hook=\"account-count\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountCount") || (depth0 != null ? lookupProperty(depth0,"accountCount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountCount","hash":{},"data":data,"loc":{"start":{"line":46,"column":88},"end":{"line":46,"column":104}}}) : helper)))
    + "</span>\n                                                </span>\n                                        </h4>\n                                        <div class=\"SummaryDetails accountInfo\" data-hook=\"insert-account-summary-"
    + alias4(((helper = (helper = lookupProperty(helpers,"CURRENCY_CODE") || (depth0 != null ? lookupProperty(depth0,"CURRENCY_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CURRENCY_CODE","hash":{},"data":data,"loc":{"start":{"line":49,"column":114},"end":{"line":49,"column":131}}}) : helper)))
    + "\"></div>\n                                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"CombinedAccountSummary\">\n        <div class=\"CombinedAccountSummary-controls "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ACCOUNT_GROUP_NAME") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":52},"end":{"line":2,"column":98}}})) != null ? stack1 : "")
    + "\">\n                <div class='CombinedAccountSummary-header'>\n                        <div class=\"DateRange\">\n                                <span class=\"DateRange-heading\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.btr.forPeriod",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":94}}}))
    + "</span> "
    + alias2(((helper = (helper = lookupProperty(helpers,"filterStartDate") || (depth0 != null ? lookupProperty(depth0,"filterStartDate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"filterStartDate","hash":{},"data":data,"loc":{"start":{"line":5,"column":102},"end":{"line":5,"column":121}}}) : helper)))
    + " "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.hyphen",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":122},"end":{"line":5,"column":145}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"filterEndDate") || (depth0 != null ? lookupProperty(depth0,"filterEndDate") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"filterEndDate","hash":{},"data":data,"loc":{"start":{"line":5,"column":146},"end":{"line":5,"column":163}}}) : helper)))
    + "\n                        </div>\n                        <div class=\"ExpandControls\">\n                                <a role=\"button\"\n                                        class=\"btn btn-tertiary\"\n                                        data-hook=\"getShowTotalsBtn\"\n                                        data-toggle=\"collapse\"\n                                        href=\"#accountTotals-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":61},"end":{"line":12,"column":67}}}) : helper)))
    + "\"\n                                        aria-expanded=\"false\"\n                                        class=\"collapsed\"\n                                        aria-controls=\"accountTotals-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":69},"end":{"line":15,"column":75}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ACCOUNT_GROUP_NAME") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":16,"column":40},"end":{"line":20,"column":47}}})) != null ? stack1 : "")
    + "                                </a>\n                                <!-- TODO: add in Expand All Button functionality in next story\n                                <a role=\"button\" class=\"btn btn-tertiary\" data-hook=\"\">\n                                        expand all\n                                </a>\n                                -->\n                        </div>\n                </div>\n                <div class=\"ControlsHeader\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ACCOUNT_GROUP_NAME") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":32,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n        </div>\n        <div class=\"CombinedAccountSummary-TotalsPanel panel-collapse collapse\"\n                aria-expanded=\"false\"\n                id=\"accountTotals-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":37,"column":34},"end":{"line":37,"column":40}}}) : helper)))
    + "\"\n                role=\"tabpanel\"\n                aria-labelledby=\"accountTotals-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":39,"column":47},"end":{"line":39,"column":53}}}) : helper)))
    + "\">\n                <div class=\"TotalsPanel-header\" data-hook=\"getTotalsHeader\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accountTotals") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":51,"column":33}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"TotalsPanel-loading\" data-hook=\"getLoadingState\">\n                        <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.accountLoading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":27},"end":{"line":54,"column":58}}}))
    + "</p>\n                        <div class=\"loader BTRLoader\">\n                                <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.loading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":54},"end":{"line":56,"column":81}}}))
    + "</span>\n                        </div>\n                </div>\n        </div>\n</div>\n\n<div class=\"CombinedAccountItems\">\n</div>\n";
},"useData":true});