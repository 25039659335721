import { buttonReset } from '../../utils/helpers.styles';

/* istanbul ignore file */
export const styles = (theme) => {
  const {
    palette: {
      accent,
      background,
      primary,
      text
    },
    spacing: { unit },
    typography
  } = theme;
  return ({
    lastSyncWrapper: {
      display: 'flex'
    },
    lastSyncIcon: {
      marginRight: 10,
      cursor: 'pointer'
    },
    syncText: {
      fontSize: unit * 1.5,
      margin: '0 10px 0 0',
      fontFamily: typography.fontFamily,
      color: text.dark
    },
    controlBar: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: [20, 0, 30, 0]
    },

    controlGroup: {
      ...buttonReset,
      display: 'flex'
    },

    controlIcon: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: 32,
      height: 32,
      borderRadius: 16,
      margin: [0, 2],
      '& svg ': {
        fill: primary.normal
      },
      '&:hover': {
        background: primary.normal,
        '& svg': {
          fill: background
        }
      }
    },

    controls: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 auto'
    },

    divider: {
      borderLeft: `2px solid ${accent}`,
      display: 'flex',
      height: 16,
      margin: [0, 15]
    },

    preControls: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 auto',

      '& >:not(:first-child)': { paddingLeft: 10 }
    }

  });
};

export default styles;
