import util from '@glu/core/src/util';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import moment from 'moment';
import AuditSectionWidget from 'common/uiWidgets/auditSectionWidget/auditSectionWidget';
import AuditHistoryView from 'app/reports/views/auditHistoryView';
import Dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import auditSectionWidgetTmpl from 'common/uiWidgets/auditSectionWidget/auditSectionWidget.hbs';
import configuration from 'system/configuration';

const AuditReportModel = Model.extend({});

export default AuditSectionWidget.extend({
    template: auditSectionWidgetTmpl,

    initialize() {
        this.collectAuditFields();
    },

    onRender() {
        this.$('button[name="VIEWHISTORY"]').on('click', (e) => {
            e.stopImmediatePropagation();
            this.viewAuditHistory();
        });
    },

    /**
     * @method viewAuditHistory
     * - sets up parameters for the reportModel and opens the dialog
     */
    viewAuditHistory() {
        const tNum = this.model.get('TNUM');
        const reportModel = new AuditReportModel();
        const reportKey = 'BATCHTNUM';

        const additionalParameters = [{
            name: 'TNUM',
            value: tNum,
        }, {
            name: 'REPORTKEY',
            value: reportKey,
        }, {
            name: 'PRODUCTCODE',
            value: this.model.get('PRODUCT'),
        }, {
            name: 'FUNCTIONCODE',
            value: this.model.get('FUNCTION'),
        }, {
            name: 'TYPECODE',
            value: 'PASSTHRU',
        }, {
            name: 'SUBTYPE',
            value: this.model.get('SUBTYPE'),
        }, {
            name: 'LOCALE',
            value: userInfo.getLocale(),
        }];

        const isTemplate = (this.model.get('FUNCTION') === 'TMPL' || this.model.get('FUNCTION') === 'BHTMPL');
        const reportID = isTemplate ? '20310' : '20300';

        reportModel.set({
            productCode: 'RTGS',
            reportId: reportID,
            filterData: '',
            additionalParameters,
        });

        const auditHistoryView = new AuditHistoryView({
            model: reportModel,
        });
        const dialogTitle = configuration.isAdmin() ? 'export.PrintListView' : 'async.report';
        auditHistoryView.dialogTitle = locale.get(dialogTitle);
        Dialog.open(auditHistoryView);
    },

    /**
     * @method collectAuditFields
     * - Method called to add the Audit section
     * - set auditFields to the model for the audit template
     */
    collectAuditFields() {
        // every payment/template has an entered audit field set

        const parentModel = this.model;

        const auditFields = [{
            title: locale.get('Entered'),
            timestamp: this.getDisplayTimestamp('ENTERED_TIMESTAMP'),
            company: parentModel.get('USERGROUP'),
            userID: parentModel.get('ENTERED_BY'),
            userName: parentModel.get('ENTERED_BY_NAME'),
        }];

        this.addApproveAuditFields(auditFields);
        this.addAuditField(auditFields, 'UNAPPROVED', locale.get('PS.screentext.Unapproved'));
        this.addAuditField(auditFields, 'DELETED', locale.get('PS.screentext.Deleted'));

        this.model.set('auditFields', auditFields);
    },

    /**
     * Function called to convert the GMT time store in Database to the user
     * timezone and format. This returns the converted timestamp
     * @param {string} field - Name of field with the timestamp
     */
    getDisplayTimestamp(field) {
        return moment(this.model.get(field), [(`${userInfo.getDateFormat()} ${userInfo.getTimeFormat()}`), 'YYYY-MM-DD HH:mm:ss.S']).format(userInfo.getDateTimeFormat());
    },

    /**
     * @method addApproveAuditFields
     * @param {Array} auditFields - auditFields has the list of the audit fields
     * - Method called to add the Last Approver to the Audit section
     * - this adds the Approver Audit to auditFields
     */
    addApproveAuditFields(auditFields) {
        const parentModel = this.model;
        const prefix = 'APPROVED_';

        // TODO make a constant
        const max = 6;

        for (let i = 1; i <= max; i += 1) {
            if (parentModel.get(`${prefix}BY_${i}`)) {
                auditFields.push({
                    title: `Approved ${i}`,
                    timestamp: this.getDisplayTimestamp(`${prefix}TIMESTAMP_${i}`),
                    company: parentModel.get(`${prefix}USERGROUP_${i}`),
                    userID: parentModel.get(`${prefix}BY_${i}`),
                    userName: parentModel.get(`${prefix}BY_NAME_${i}`),
                });
            }
        }

        if (parentModel.get('STATUS') === 'RL' && parentModel.get('ENTRYMETHOD') === '3') {
            auditFields.push({
                title: locale.get('Released'),
                timestamp: this.getDisplayTimestamp('LAST_ACTION_TIME'),
                company: parentModel.get('USERGROUP'),
                userID: '_SYSTEM',
            });
        }
    },

    /**
     * Method called to add the audit of different actions
     * @param {[Object]} auditFields
     * @param {string} auditType
     * @param {string} auditTitle
     */
    addAuditField(auditFields, auditType, auditTitle) {
        const parentModel = this.model;
        const prefix = `${auditType}_`;
        if (parentModel.get(`${prefix}TIMESTAMP`)) {
            auditFields.push({
                title: auditTitle,
                timestamp: this.getDisplayTimestamp(`${prefix}TIMESTAMP`),
                company: parentModel.get(`${prefix}USERGROUP`),
                userID: parentModel.get(`${prefix}BY`),
                userName: (parentModel.get(`${prefix}BY_NAME`)) ? parentModel.get(`${prefix}BY_NAME`) : parentModel.get(`${prefix}NAME`),
            });
        }
    },

    templateHelpers() {
        this.parentModel = this.model;
        const parentHelpers = AuditSectionWidget.prototype.templateHelpers.call(this);
        return util.extend(
            {},
            parentHelpers,
            {
                getString(code) {
                    return locale.get(code);
                },

                displayHistoryLink: true,
                auditInfo: this.model.attributes,
            },
        );
    },
});
