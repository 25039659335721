import ItemView from '@glu/core/src/itemView';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import transform from 'common/util/transform';
import multiSelectComboboxWidgetItemTmpl from './multiSelectComboboxWidgetItem.hbs';

export default ItemView.extend({
    template: multiSelectComboboxWidgetItemTmpl,

    initialize() {
        this.maskingProps = getMaskingProperties();
        this.model.id = this.model.cid;
    },

    templateHelpers() {
        const data = transform.pairsToHash(this.model.get('mapDataList'), 'toField', 'value');
        return {
            accountName: data.DEBIT_ACCOUNT_TITLE,
            accountNumber: data.ORIGINATOR_ID,
            maskIt: value => maskValue(value, this.maskingProps),
        };
    },
});
