var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <button type=\"button\" class=\"btn btn-tertiary add-payment\" data-hook=\"insert-button\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"sbPayments.addPayment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":163},"end":{"line":18,"column":197}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertCollectionEntitlements") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":21,"column":47}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTaxPaymentEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":24,"column":47}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <button type=\"button\" class=\"btn btn-tertiary add-payment\" data-hook=\"collect-button\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbPayments.add.collection",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":172},"end":{"line":20,"column":211}}}))
    + "</button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <button type=\"button\" class=\"btn btn-tertiary add-tax-payment\" data-hook=\"taxInsert-button\"><span class=\"icon-add-circle\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"smbPayments.add.tax.payment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":178},"end":{"line":23,"column":218}}}))
    + "</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-secondary approve-payments\"\n                    data-action=\"approve\"\n                    type=\"button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.approve",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":34},"end":{"line":38,"column":65}}}))
    + "\n                </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-secondary unapprove-payments\"\n                    data-action=\"unapprove\"\n                    type=\"button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.unApprove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":34},"end":{"line":44,"column":67}}}))
    + "\n                </button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-secondary reject\"\n                    data-action=\"reject\"\n                    type=\"button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.reject",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":34},"end":{"line":50,"column":60}}}))
    + "\n                </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"btn btn-secondary delete-payments\"\n                    data-action=\"delete\"\n                    type=\"button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"sbPayments.delete",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":34},"end":{"line":56,"column":64}}}))
    + "\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":52}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"section-header-list",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":47}}}))
    + "</h2>\n    </div>\n\n        <div class=\"section-body\">\n                <div>\n                    <span class=\"maxDaysMessage\"></span>\n                         <span class=\"icon-info\" data-toggle=\"popover\" data-trigger=\"hover focus\" data-placement=\"right\" data-content=\""
    + alias3(lookupProperty(helpers,"locale").call(alias1,"PS.ListView.MaxDaysMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":135},"end":{"line":13,"column":174}}}))
    + "\"></span>\n                </div>\n                <div class=\"btn-wrapper\">\n                        <div class=\"btn-group widget-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":32},"end":{"line":25,"column":39}}})) != null ? stack1 : "")
    + "                        </div>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridUtilitySection") || (depth0 != null ? lookupProperty(depth0,"gridUtilitySection") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n                <div class=\"grid-region\" data-region=\"gridRegion\"></div>\n\n                <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":40,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasUnapproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":46,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRejectEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":12},"end":{"line":52,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDeleteEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":58,"column":19}}})) != null ? stack1 : "")
    + "                </div>\n        </div>\n<div>\n";
},"useData":true});