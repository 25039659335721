function getEntitlementsOptions(typeCode) {
    return {
        typeCode,
        productCode: 'SMAINT',
        functionCode: 'MAINT',
        entryMethod: 0,
        overrideContext: {
            serviceName: '/userMaintenance/user',
        },
    };
}

export const eventConfigEntitlementsOptions = getEntitlementsOptions('EVT_CFG');

export const eventConfigEntitlements =
    [eventConfigEntitlementsOptions];

