import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';
import ModelParameterParent from './loanModifyParent';

export default ModelParameterParent.extend({
    initialize() {
        this.set(this.parse(this.parameters));
    },

    defaults: {
        DEBIT_CURRENCY: 'NONE',
        SPECIAL_INSTRUCTIONS: '',
        CREDIT_CURRENCY: 'NONE',
        CREDIT_AMOUNT: '',
        AMOUNT_0: '',
        AMOUNT_1: '',
        AMOUNT_2: '',
        AMOUNT_3: '',
        AMOUNT_4: '',
        BENE_ACCOUNT: 'NONE',
        DEBIT_ACCOUNT_NUMBER: 'NONE',
    },

    parse(mapDataList) {
        const parsedData = {};
        for (let x = 0; x < mapDataList.length; x += 1) {
            parsedData[mapDataList[x].toField.toUpperCase()] = mapDataList[x].value;
        }
        return parsedData;
    },

    sync(method, modelParam, optionsParam) {
        const model = modelParam;
        const options = optionsParam;
        const service = this.getServiceName(model);

        if (method === 'update' || method === 'create') {
            const loanData = this.convertModelAttributesToServerJSON(model);

            http.post(service, loanData, (response) => {
                options.success(response);
            }, (response) => {
                model.error = JSON.parse(response.responseText);
                options.error(model, {});
            });
        } else if (method === 'reject') {
            options.rejectReasonItem = {
                name: 'APPROVER_REJECTION_REASON',
                value: model.get('APPROVER_REJECTION_REASON'),
            };
            return this.processAction(method, model, options);
        }
        return undefined;
    },

    unapprove(options) {
        this.sync('unapprove', this, options);
    },

    approve(options) {
        this.sync('approve', this, options);
    },

    reject(options) {
        this.sync('reject', this, options);
    },

    delete(options) {
        this.sync('delete', this, options);
    },

    processAction(method, model, options) {
        let data;
        const pType = this.get('paymentType');
        const pTypeUrl = pType.toUpperCase().indexOf('PAY') > -1 ? 'payment/loanPayment/' : 'payment/loanDraw/';
        const url = options.templateMode ? 'template/listView/corp/' : pTypeUrl + method;

        if (options.templateMode) {
            data = {
                items: [{
                    item: transform.hashToPairs({
                        FUNCTIONCODE: 'TMPLSET',
                        TYPECODE: 'TRANSFER',
                        PRODUCTCODE: 'RTGS',
                        TNUM: model.get('TNUM'),
                        ENTRYMETHOD: model.get('ENTRYMETHOD'),
                        UPDATECOUNT__: model.get('UPDATECOUNT__'),
                        _saveWithWarning: model.get('_saveWithWarning'),
                    }),
                }],
            };
        } else {
            data = {
                item: transform.hashToPairs({
                    FUNCTIONCODE: model.get('FUNCTION'),
                    TNUM: model.get('TNUM'),
                    ENTRYMETHOD: model.get('ENTRYMETHOD'),
                    UPDATECOUNT__: model.get('UPDATECOUNT__'),
                    _saveWithWarning: model.get('_saveWithWarning'),
                }),
            };
            if (method === 'reject') {
                data.item.push(options.rejectReasonItem);
            }
        }

        http.post(services.generateUrl(url), data, (result) => {
            options.success(model, result, options);
        }, (result) => {
            options.error({
                errorCode: result.status,
                errorMessage: result.statusText,
                message: result.responseText,
            });
        });
    },

    convertModelAttributesToServerJSON(model) {
        let jsonData = [];
        const { attributes } = model;
        let isTemplate = false;
        let x = 0;
        if (model.get('TEMPLATE_TYPE') || (attributes.Template_Description && attributes.Template_Code)) {
            jsonData[x] = {
                name: 'FUNCTIONCODE',
                value: 'TMPL',
            };
            x += 1;
            isTemplate = true;
        }
        // convert CREDIT_AMOUNT to DEBIT_AMOUNT for loandraw
        if (model.get('paymentType') === 'LOANDRAW') {
            if ('DEBIT_AMOUNT' in attributes) {
                attributes.DEBIT_AMOUNT = model.get('CREDIT_AMOUNT');
            } else {
                jsonData[x] = {
                    name: 'DEBIT_AMOUNT',
                    value: model.get('CREDIT_AMOUNT'),
                };
                x += 1;
            }
        }
        Object.keys(attributes || {}).forEach((data) => {
            if (data === 'SAVEASTEMPLATE' || data.toUpperCase() === 'RESTRICTTEMPLATE_FLAG') {
                attributes[data] = attributes[data] ? '1' : '0';
            }
            jsonData[x] = {
                name: data,
                value: attributes[data],
            };
            x += 1;
        });

        if (isTemplate && model.schedModel) {
            jsonData = jsonData.concat(transform.convertScheduleModelToNVP(model.schedModel));
        }
        return {
            item: jsonData,
        };
    },

    parameters: ModelParameterParent.prototype.createLoanParameters(),
});
