import locale from '@glu/locale';
import EditableTable from 'app/bos_common/editableTable/view/editableTable';
import editableTableTmpl from 'app/bos_common/editableTable/view/editableTable.hbs';
import SubPlanTableRow from './subPlanTableRow';

const GroupPlanSubPlanView = EditableTable.extend({
    className: 'group-subplan',

    initialize(...args) {
        EditableTable.prototype.initialize.apply(this, args);
        this.listenTo(this.model, 'sync', this.render);

        this.collection = this.model.get('groupPlanLines');
        this.columnHeaders = [{
            header: locale.get('cashflow.groupplan.team.member'),
        }, {
            header: locale.get('cashflow.plan.name.title'),
        }, {
            header: locale.get('cashflow.status'),
        }, {
            header: locale.get('cashflow.lastupdated'),
        }, {
            header: locale.get('cashflow.note'),
        }];
    },

    template: editableTableTmpl,
    itemView: SubPlanTableRow,

    itemViewOptions() {
        return {
            readOnly: this.readOnly,
        };
    },
});

export default GroupPlanSubPlanView;
