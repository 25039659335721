var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\">\n                    <a aria-controls=\"alertsList\"\n                        aria-selected=\"true\"\n                        class=\"NavTabs-link\"\n                        tabindex=\"0\"\n                        role=\"tab\"\n                        aria-label="
    + alias4(((helper = (helper = lookupProperty(helpers,"alertsTitle") || (depth0 != null ? lookupProperty(depth0,"alertsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alertsTitle","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":50}}}) : helper)))
    + "\n                        id=\"alertsListTab\"\n                        data-tab=\"alertsList\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"alertsTitle") || (depth0 != null ? lookupProperty(depth0,"alertsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alertsTitle","hash":{},"data":data,"loc":{"start":{"line":21,"column":46},"end":{"line":21,"column":61}}}) : helper)))
    + "\n                    </a>\n                </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\">\n                    <a aria-controls=\"recipientGroups\"\n                        aria-selected=\"false\"\n                        class=\"NavTabs-link\"\n                        tabindex=\"-1\"\n                        role=\"tab\"\n                        aria-label="
    + alias4(((helper = (helper = lookupProperty(helpers,"recipientGroupsTitle") || (depth0 != null ? lookupProperty(depth0,"recipientGroupsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recipientGroupsTitle","hash":{},"data":data,"loc":{"start":{"line":32,"column":35},"end":{"line":32,"column":59}}}) : helper)))
    + "\n                        id=\"recipientGroupsTab\"\n                        data-tab=\"recipientGroups\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"recipientGroupsTitle") || (depth0 != null ? lookupProperty(depth0,"recipientGroupsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recipientGroupsTitle","hash":{},"data":data,"loc":{"start":{"line":34,"column":51},"end":{"line":34,"column":75}}}) : helper)))
    + "\n                    </a>\n                </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\">\n                    <a aria-controls=\"recipients\"\n                        aria-selected=\"false\"\n                        class=\"NavTabs-link\"\n                        tabindex=\"-1\"\n                        role=\"tab\"\n                        aria-label="
    + alias4(((helper = (helper = lookupProperty(helpers,"recipientsTitle") || (depth0 != null ? lookupProperty(depth0,"recipientsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recipientsTitle","hash":{},"data":data,"loc":{"start":{"line":45,"column":35},"end":{"line":45,"column":54}}}) : helper)))
    + "\n                        id=\"recipientsTab\"\n                        data-tab=\"recipients\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"recipientsTitle") || (depth0 != null ? lookupProperty(depth0,"recipientsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recipientsTitle","hash":{},"data":data,"loc":{"start":{"line":47,"column":46},"end":{"line":47,"column":65}}}) : helper)))
    + "\n                    </a>\n                </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\">\n                    <a aria-controls=\"mySettings\"\n                        aria-selected=\"false\"\n                        tabindex=\"-1\"\n                        role=\"tab\"\n                        class=\"NavTabs-link\"\n                        aria-label="
    + alias4(((helper = (helper = lookupProperty(helpers,"mySettingsTitle") || (depth0 != null ? lookupProperty(depth0,"mySettingsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mySettingsTitle","hash":{},"data":data,"loc":{"start":{"line":58,"column":35},"end":{"line":58,"column":54}}}) : helper)))
    + "\n                        id=\"mySettingsTab\"\n                        data-tab=\"mySettings\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"mySettingsTitle") || (depth0 != null ? lookupProperty(depth0,"mySettingsTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mySettingsTitle","hash":{},"data":data,"loc":{"start":{"line":60,"column":46},"end":{"line":60,"column":65}}}) : helper)))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":40}}}) : helper)))
    + "</h1>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <!-- Alert Center tabs start -->\n        <div class=\"row\">\n            <ul id=\"alerts-center-tablist\"\n                class=\"NavTabs nav nav-tabs\"\n                data-hook=\"getNavTabs\"\n                role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"alertTabEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recipGroupTabEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recipientsTabEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":50,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mySettingsTabEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":63,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    <!-- Alert Center tabs End -->\n    <section class=\"section section-container TabsContent-container\">\n                <div aria-hidden=\"false\"\n            class=\"tab-section alerts-region\"\n            data-tabcontent=\"alertsList\"\n            id=\"alertsList\"\n            role=\"tabpanel\"></div>\n                <div aria-hidden=\"true\"\n            class=\"tab-section recipient-groups-region\"\n            data-tabcontent=\"recipientGroups\"\n            id=\"recipientGroups\"\n            role=\"tabpanel\"></div>\n                <div aria-hidden=\"true\"\n            class=\"tab-section custom-recipients-region\"\n            data-tabcontent=\"recipients\"\n            id=\"recipients\"\n            role=\"tabpanel\"></div>\n                <div aria-hidden=\"true\"\n            class=\"tab-section my-settings-region\"\n            data-tabcontent=\"mySettings\"\n            id=\"mySettings\"\n            role=\"tabpanel\"></div>\n    </section>\n";
},"useData":true});