import React from 'react';
import PropTypes from 'prop-types';

const onClick = (action, name) => (event) => {
    if (typeof action === 'function') {
        event.preventDefault();
        action(name, event);
    }
};

/* eslint jsx-a11y/anchor-is-valid: "off" */
const Tab = ({
    label, active, action, count, name,
}) => (
    <li className={active ? 'active' : ''}>
        <a href={typeof action === 'string' ? action : '#'} onClick={onClick(action, name)}>
            {(count !== undefined) && <div className="count">{count}</div>}
            <span>{label}</span>
        </a>
    </li>
);

Tab.propTypes = {
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    active: PropTypes.bool,
};

Tab.defaultProps = {
    active: false,
    count: undefined,
};

export default Tab;
