import Model from '@glu/core/src/model';

export default Model.extend({
    defaults: {
        LANGUAGE: '',
        MESSAGEHEADER: '',
        DETAILS: '',
        active: false,
    },

    /**
     * Sets active attribute of model based on whether or not this model
     * @param  {string} cid
     */
    setActiveMode(cid) {
        // if this model's language is equal to the passed language then set active to active
        this.set('active', this.cid === cid);
    },
});
