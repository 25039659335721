import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import services from 'services';

export default Collection.extend({
    initialize(models, options) {
        this.fieldName = options.fieldName;
        this.context = options.context;
        this.url = services.generateUrl(options.url);
    },

    sync(method, collection, options) {
        http.post(
            this.url,
            {
                queryCriteria: {
                    action: this.context,
                    subTypeCode: '*',
                    fieldName: this.fieldName,
                    entryClass: '',
                },
            },
            (response) => {
                options.success(response.queryResponse.QueryData.queryRows);
            },
        );
    },

    parse(rowData) {
        return util.map(rowData, row => ({
            id: row.name,
            desc: row.label,
            mapData: row.mapDataList,
        }));
    },
});
