import Collection from '@glu/core/src/collection';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import smbConstants from 'app/smbPayments/constants';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(opts) {
        this.paymentType = opts.paymentType;
    },

    sync(method, model, options) {
        if (method === 'read') {
            const data = {
                rowsPerPage: -1,
                dataOnly: 0,
                fieldName: 'BENE_NAME',
                depends: [],
                productCode: smbConstants.isWire(this.paymentType) ? 'RTGS' : 'RTP',
                functionCode: 'INST',
                typeCode: this.paymentType,
            };

            http.post(
                services.generateUrl(constants.URL_GETLOOKUPRESULTS_ACTION),
                data,
                (result) => {
                    options.success(result);
                }, () => {
                    options.error();
                },
            );
        }
    },

    parse(response) {
        return util.map(response.rows, (row) => {
            const obj = {};
            util.each(row.columns, (column) => {
                if (column.fieldValue) {
                    obj[column.fieldName] = column.fieldValue;
                }
            });
            util.each(row.mapDataList, (mapData) => {
                // include only items with a value, if that value is not a _MAP
                if (mapData.value && mapData.toField.indexOf('_MAP') === -1) {
                    obj[mapData.toField] = mapData.value;
                }
            });
            return obj;
        });
    },
});
