var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form autocomplete=\"off\" data-submit=\"change\">\n    <div class=\"alert-message\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n    </div>\n\n    <div class=\"passwordfields\">\n        <!-- Old password -->\n        <div class=\"form-row\">\n            <label for=\"a11y-old-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.oldPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":43},"end":{"line":9,"column":74}}}))
    + "</label>\n            <div class=\"field-container\">\n                <input id=\"a11y-old-password\" type=\"password\" class=\"form-control\" data-bind=\"model\" name=\"oldPassword\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"oldPassword\"></span>\n            </div>\n        </div>\n        <!-- New password -->\n        <div class=\"form-row\">\n            <label for=\"a11y-new-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.newPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":43},"end":{"line":17,"column":74}}}))
    + "</label>\n            <div class=\"field-container\">\n                <input id=\"a11y-new-password\" type=\"password\" data-hook=\"new-password\" class=\"form-control\" data-bind=\"model\" name=\"newPassword\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"newPassword\"></span>\n            </div>\n        </div>\n        <!-- Confirm password -->\n        <div class=\"form-row\">\n            <label for=\"a11y-confirm-password\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"prompt.confirmPassword",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":47},"end":{"line":25,"column":82}}}))
    + "</label>\n            <div class=\"field-container\">\n                <input id=\"a11y-confirm-password\" type=\"password\" data-hook=\"new-password-confirm\" class=\"form-control\" data-bind=\"model\" name=\"confirmPassword\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"confirmPassword\"></span>\n            </div>\n        </div>\n        <div class=\"form-row password-rules\"></div>\n    </div>\n</form>\n";
},"useData":true});