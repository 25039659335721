import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import constants from 'common/dynamicPages/api/constants';
import PanelView from './panelView';
import BillersCollection from '../collections/billers';
import BillerImageView from '../../billPay/views/billerImage';
import billPayTmpl from './billPay.hbs';

const BillPay = PanelView.extend({
    template: billPayTmpl,

    ui: {
        billerSelect: '.biller-select',
        accountWithBiller: '.account-with-biller',
        statementImage: '.account-statement-image',
    },

    events: {
        'click @ui.statementImage': 'showStatementImage',
        'change @ui.accountWithBiller': 'accountChange',
    },

    initialize(options) {
        const self = this;
        const beneId = this.model.get('BENE_BANK_ID');

        this.billerModel = options.model || new Model();
        this.billersCollection = new BillersCollection();

        /*
         * Need to get the biller info so we can validate the account number
         * and grab the check image.
         */
        if (beneId) {
            this.billersCollection.setFilter(beneId);
            this.billersCollection.fetch({
                success(results) {
                    const bene = results.findWhere({
                        BENE_BANK_ID: beneId,
                    });
                    self.billerChange({
                        val: bene.id,
                    });
                },
            });
        }
    },

    onRender() {
        this.ui.statementImage.hide();
        this.createCombo();
    },

    createCombo() {
        const self = this;

        const combo = this.ui.billerSelect.comboBox({
            maximumSelectionSize: 1,

            query: util.debounce((query) => {
                self.billersCollection.setFilter(query.term);
                self.billersCollection.fetch({
                    success(results) {
                        query.callback({
                            results: results.toJSON(),
                        });
                    },
                });
            }, constants.COMBO_DEBOUNCE),

            initSelection(element, cb) {
                const val = element.val();
                const text = `${self.model.get('BENE_BANK_NAME')} - ${self.model.get('BENE_BANK_ID')}`;
                if (text) {
                    self.ui.statementImage.show();
                    cb({
                        id: val,
                        text,
                    });
                } else {
                    cb();
                }
            },
        });

        combo.on('change', (e) => {
            self.billerChange(e);
        });
    },

    billerChange(e) {
        this.billerModel = this.billersCollection.get(e.val);
        this.model.set({
            BENE_BANK_ID: this.billerModel.get('MerchantId'),
            BENE_BANK_NAME: this.billerModel.get('MerchantName'),
        });

        this.model.updateAccountValidator(this.billerModel.get('AccountFormat'));
        this.ui.statementImage.show();
    },

    accountChange() {
        this.model.validateField('BENE_ACCOUNTNUMBER');
    },

    showStatementImage() {
        this.billerImageView = new BillerImageView({
            billerModel: this.billerModel,
        });
        dialog.open(this.billerImageView);
    },

    clearBillPayee() {
        this.ui.billerSelect.comboBox('data', null);
        this.model.set({
            BENE_BANK_ID: '',
            BENE_BANK_NAME: '',
            BENE_ACCOUNTNUMBER: '',
        });
        delete this.model.validators.BENE_ACCOUNTNUMBER;
    },
});

export default BillPay;
