import React from 'react';
import PropType from 'prop-types';
import {
    useInputAPI,
} from '@glu/validation-react';
import ContentEditorContainer from './ContentEditorContainer';

const ValidatedContentEditorContainer = ({
    name,
    validators,
    ...props
}) => {
    const {
        onChange,
    } = useInputAPI({
        name,
        validators,
    });
    const editorChange = (value) => {
        onChange(name, value);
    };
    return (
        <ContentEditorContainer
            {...props}
            onChange={editorChange}
        />
    );
};
ValidatedContentEditorContainer.propTypes = {
    name: PropType.string.isRequired,
    validators: PropType.shape({
        name: PropType.string,
        required: PropType.bool,
    }),
};
ValidatedContentEditorContainer.defaultProps = {
    validators: {},
};
export default ValidatedContentEditorContainer;
