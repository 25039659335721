import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';

import styles from './RtpMessageDetail.styles';

const propTypes = {
    itemType: PropTypes.string.isRequired,
    additionalInfo: PropTypes.string.isRequired,
    userId: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    currentTab: PropTypes.string.isRequired,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    userId: null,
};

const MessageDetail = ({
    itemType, additionalInfo, userId, timestamp, currentTab, classes,
}) => {
    const getMessageStyle = (type) => {
        const left = `${classes.messageBody} ${classes.messageBodyLeft}`;
        const right = `${classes.messageBody} ${classes.messageBodyRight}`;
        if (currentTab === 'received') {
            return (type === 'RESPONSE') ? left : right;
        }
        return (type === 'RESPONSE') ? right : left;
    };

    return (
        <div className={getMessageStyle(itemType)}>
            <p>{additionalInfo}</p>
            <p className={classes.messageId}>
                <span className="icon-clock" />
                {userId
                && <span>{userId}</span>}
                <span>{timestamp}</span>
            </p>
        </div>
    );
};

MessageDetail.propTypes = propTypes;
MessageDetail.defaultProps = defaultProps;

export default withStyles(styles)(MessageDetail);
