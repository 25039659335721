import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import template from './checkViewer.hbs';

export default Layout.extend({
    template,
    className: 'bulk-check-viewer',

    ui: {
        serialField: '.serial-field',
        checkNavDisplay: '.check-nav-display',
        prevBtn: '.prev-page',
        nextBtn: '.next-page',
        serialNumDiv: '#serialNumDiv',
    },

    regions: {
        checkDisplay: '.check-display',
    },

    events: {
        'click @ui.prevBtn': 'prevCheck',
        'click @ui.nextBtn': 'nextCheck',
    },

    initialize() {
        const self = this;

        this.serialNumberField = this.options.serialNumberField || 'SERIALNUMBER';
        this.comboArray = [];
        this.checkIndex = 0;

        this.comboArray = util.map(this.options.checks, check => ({
            id: check.get('id'),
            text: check.get(self.serialNumberField),
        }));

        this.hasMany = this.comboArray.length > 1;
    },

    onRender() {
        const self = this;
        if (this.isPAD()) {
            this.ui.serialNumDiv.hide();
        }

        if (this.hasMany) {
            const combo = this.ui.serialField.comboBox({
                data: this.comboArray,
            });

            combo.on('change', (e) => {
                self.disableNextPrevBtn();
                self.checkIndex = util.indexOf(self.comboArray, util.findWhere(
                    self.comboArray,
                    {
                        id: self.$(e.target).val(),
                    },
                ));
                self.loadCheck();
            });

            combo.val(this.comboArray[this.checkIndex].id).trigger('change');
        } else {
            this.ui.serialField.text(this.comboArray[0].text);
            this.loadCheck();
        }
    },

    loadCheck() {
        const EntryClass = this.options.entryClass;

        this.ui.checkNavDisplay.text(`${this.checkIndex + 1} of ${this.comboArray.length}`);

        // toggle off buttons until check has fully loaded
        this.toggleViewButtons(false);

        this.entryView = new EntryClass(util.extend({
            model: this.options.checks[this.checkIndex],
            hideHeader: true,
            bulkCheckView: true,
            mode: this.options.mode ? this.options.mode : 'modify',
            numberOfChecks: this.options.checks.length,
            checkIndex: this.checkIndex,
        }, this.options.entryCfg));

        this.listenToOnce(this.entryView, 'checkDetail:loaded', this.toggleViewButtons);
        this.checkDisplay.show(this.entryView);
    },

    /**
     * @method toggleViewButtons
     * @param {boolean=} toggleOn - optional
     * Toggles the view checks navigation buttons either based on passed in flag
     * or by current check index rules
     */
    toggleViewButtons(toggleOn) {
        let localToggleOn = toggleOn;
        localToggleOn = (localToggleOn !== undefined) ? localToggleOn : true;
        this.ui.prevBtn.prop('disabled', (localToggleOn) ? (this.checkIndex === 0) : true);
        this.ui.nextBtn.prop('disabled', (localToggleOn) ? (this.checkIndex === this.options.checks.length - 1) : true);
    },

    prevCheck() {
        this.disableNextPrevBtn();
        this.checkIndex -= 1;
        this.ui.serialField.val(this.comboArray[this.checkIndex].id).trigger('change');
        this.loadCheck();
    },

    nextCheck() {
        this.disableNextPrevBtn();
        this.checkIndex += 1;
        this.ui.serialField.val(this.comboArray[this.checkIndex].id).trigger('change');
    },

    cancel() {
        this.navigateTo(store.get('current-workspace-route'));
    },

    isPAD() {
        let flag = false;
        if (this.options.checks[0]) {
            const stopType = (this.options.checks[0].get('STOPTYPE_DESCRIPTION') || '').toUpperCase();
            if (stopType.indexOf('PRE') > -1) {
                flag = true;
            }
        }
        return flag;
    },

    templateHelpers() {
        const self = this;
        const obj = EntryView.prototype.templateHelpers.call(this);
        return util.extend(
            obj,
            {
                hasMany() {
                    return this.hasMany;
                },

                isPreAuthDebit() {
                    let flag = false;
                    if (self.options.checks[0]) {
                        const stopType = (self.options.checks[0].get('STOPTYPE_DESCRIPTION') || '').toUpperCase();
                        if (stopType.indexOf('PRE') > -1) {
                            flag = true;
                        }
                    }
                    return flag;
                },
            },
        );
    },

    disableNextPrevBtn() {
        this.ui.prevBtn.prop('disabled', true);
        this.ui.nextBtn.prop('disabled', true);
    },
});
