import React, {
  useContext, useEffect, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { AccessibilityText } from '@glu/utilities-react';
import locale from '@glu/locale';
import {
  LinesNextIcon, ArrowDownAshIcon, ArrowUpAshIcon, FunnelAshIcon
} from '@glu/icons-react';
import GridContext from '../Grid/GridContext';
import Menu from './Menu/Menu';
import useStyles from './HeaderCell.styles';
import Popover from '../Popover/Popover';

const HeaderCellRenderer = (props) => {
  const {
    api: gridApi,
    column,
    columnApi,
    columnHeaderFilters,
    context,
    dataQa,
    displayName,
    enableSorting,
    gridWrapperRef,
    numberFilterTypes,
    setSort
  } = props;
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(column.isFilterActive());
  const gridContext = useContext(GridContext);
  const headerRef = useRef();
  const [sortOrder, setSortOrder] = useState((column.sort || column.userProvidedColDef.sort) || 'none');

  const handleSortChange = (event) => {
    const orderSort = column.isSortAscending()
      ? 'desc'
      : column.isSortDescending()
        ? 'none'
        : 'asc';
    setSort(orderSort, event.shiftKey);
    setSortOrder(orderSort);
  };

  /** sort changed requested from user */
  const onSortRequested = (event) => {
    if (column.userProvidedColDef.sortable || column.colDef.sortable) {
      handleSortChange(event);
    }
  };

  /** sort changed from event handler from ag-grid */
  const onSortChange = () => {
    setSortOrder(column.sort || 'none');
  };

  useEffect(() => {
    column.addEventListener('sortChanged', onSortChange);
    return () => {
      column.removeEventListener('sortChanged', onSortChange);
    };
  }, []);

  useEffect(() => {
    const found = gridContext?.filters?.filter(((filter) => filter.field === column.colId));
    setShowFilters(found?.length > 0);
  }, [gridContext.filters]);

  return (
    <div
      data-qa={dataQa || column.colId}
      className={classes.headerCell}
      ref={headerRef}
    >
      <button
        className={classes.headerNameButton}
        type="button"
        onClick={(event) => onSortRequested(event)}
      >
        {enableSorting && (
          <>
            <AccessibilityText>{locale.get('gridReact.headerCell.sort')}</AccessibilityText>
            {sortOrder === 'desc'
              && (
                <div className={classes.sortIcon}>
                  <ArrowDownAshIcon />
                </div>
              )}
            {sortOrder === 'asc'
              && (
                <div className={classes.sortIcon}>
                  <ArrowUpAshIcon />
                </div>
              )}
          </>
        )}

        <div className={classes.columnTitle}>
          <span className={classes.headerName}>{displayName}</span>
        </div>

        {showFilters && (
          <div data-qa="filter-indicator" className={classes.filterIcon}>
            <FunnelAshIcon />
          </div>
        )}
      </button>
      {column.userProvidedColDef.filterable !== false && columnHeaderFilters && (
        <Popover
          gridWrapperRef={gridWrapperRef}
          childrenRef={headerRef}
          leftOffset={-32}
          dataQa={column.colId}
          minWidth={220}
          topOffset={-2}
          activeClass={classes.active}
          open={open}
          setOpen={setOpen}
          content={(
            <Menu
              closeMenu={() => setOpen(false)}
              columnApi={columnApi}
              column={column}
              dataQa={dataQa}
              gridApi={gridApi}
              context={context}
              filters={gridContext.filters}
              numberFilterTypes={numberFilterTypes}
              displayName={displayName}
            />
          )}
        >
          <div className={classes.menuButton}>
            <AccessibilityText>{locale.get('gridReact.headerCell.menu')}</AccessibilityText>
            <div className={classes.menuButtonIcon}>
              <LinesNextIcon />
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

HeaderCellRenderer.propTypes = {
  api: PropTypes.shape({}).isRequired,
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    colDef: PropTypes.shape({ sortable: PropTypes.bool }),
    colId: PropTypes.string.isRequired,
    isFilterActive: PropTypes.func.isRequired,
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
    removeEventListener: PropTypes.func,
    sort: PropTypes.string,
    userProvidedColDef: PropTypes.shape({
      filterable: PropTypes.bool,
      sort: PropTypes.string,
      sortable: PropTypes.bool
    }).isRequired
  }).isRequired,
  columnApi: PropTypes.shape().isRequired,
  columnHeaderFilters: PropTypes.bool,
  context: PropTypes.objectOf(PropTypes.any).isRequired,
  dataQa: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  enableSorting: PropTypes.bool,
  gridWrapperRef: PropTypes.shape({}).isRequired,
  numberFilterTypes: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })
  ),
  setSort: PropTypes.func
};

HeaderCellRenderer.defaultProps = {
  columnHeaderFilters: true,
  dataQa: '',
  enableSorting: true,
  numberFilterTypes: undefined,
  setSort: /* istanbul ignore next */ () => { }
};

export default HeaderCellRenderer;
