export default ({ palette, typography }) => ({

  headerName: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: typography.fontFamily,
    color: palette.text.dark,
    marginRight: 5
  }
});
