import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import Services from 'services';
import cache from 'system/utilities/cache';
import Rows from 'common/dynamicPages/collections/rows';
import DataAPI from 'common/dynamicPages/api/data';
import Columns from 'common/dynamicPages/collections/columns';
import Constants from 'common/dynamicPages/api/constants';
import LvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import transform from 'common/util/transform';
import { appBus } from '@glu/core';
import moment from 'moment';
import DateUtil from 'common/util/dateUtil';
import userInfo from 'etc/userInfo';
import filterUtil from 'common/util/filterUtil';
import mobileUtil from 'mobile/util/mobileUtil';

export default function (options) {
    const servicesUrlSuffix = (options.suppressServicesUrlSuffix === true) ? '' : Constants.URL_GETLISTVIEW_ACTION;
    const wrapper = {
        gridId: options.gridId,
        isInquiry: options.isInquiry || false,
        isLookup: options.isLookup || false,
        isComponent: options.isComponent || false,
        isService: options.isService || false,
        context: options.context,
        isChild: options.isChild || false,
        batchSeqNum: options.batchSeqNum,
        editableChildGrid: options.editableChildGrid || false,
        b: options,
        viewId: options.viewId || '',
        inquirySearchCriteria: options.inquirySearchCriteria,
        additionalSearchFields: options.additionalSearchFields,
        requestParameters: options.requestParameters || false,
        servicesUrl: (options.isInquiry === true)
            ? null : Services.generateUrl(options.context.serviceName) + servicesUrlSuffix,
        lookupUrl: (options.isLookup === true)
            ? Services.generateUrl(Constants.URL_GETLOOKUPRESULTS_ACTION) : null,
        componentUrl: (options.isComponent === true)
            ? Services.generateUrl(options.context.serviceName)
            + Constants.URL_GETGRIDCOMPONENTRESULTS_ACTION : null,
        inquiryRowsUrl: Services.inquiryRowsData,
        inquiryColumnsUrl: Services.inquiryHeaderData,
        defaultDateFormat: options.dateFormat || 'MM/DD/YYYY',
        cashflowOverride: options.cashflowOverride || false,
        detailReportId: options.detailReportId || 0,
        disableDrillDown: options.disableDrillDown || false,

        rows: new Rows({
            context: options.context,
            isInquiry: options.isInquiry || false,
            additionalParse: options.parseRow || false,
            batchSeqNum: options.batchSeqNum,
        }),

        // TODO removing the checkbox for the component grid and also adding the
        // formatter for the combo
        columns: (options.isComponent || false) ? new Columns(
            {},
            {
                selector: 'none',
                formatCombo: true,
                cellViews: options.cellViews,
                columnsToBeHidden: options.columnsToBeHidden || false,
                customFormatters: options.customFormatters,
                enableColumnRenameControls: options.enableColumnRenameControls || false,
            },
        ) : new Columns(
            {},
            {
                selector: options.selector,
                cellViews: options.cellViews,
                context: options.context,
                editableChildGrid: options.editableChildGrid,
                sortKey: options.sortKey || null,
                columnsToBeHidden: options.columnsToBeHidden || false,
                customFormatters: options.customFormatters,
                enableColumnRenameControls: options.enableColumnRenameControls || false,
                filterStaticDependsOn: options.staticFilterDependsOn || [],
            },
        ),

        currentPage: (options.currentPage) ? options.currentPage : Constants.GRID_CURRENT_PAGE,
        rowsPerPage: (options.rowsPerPage) ? options.rowsPerPage : null,
        sortKey: options.sortKey || null,
        sortOrder: options.sortOrder || null,
        filters: null,
        ignoreNextGridFilterRequest: false,
        parentState: options.parentState,
        parentKey: options.parentKey,
        parentModel: options.parentModel,
        componentFieldName: options.componentFieldName,
        componentDepends: options.componentDepends,
        lvc: options.lvc,
        beforeGridContentReady: [],

        initializeGridData(gridDataOptions) {
            const self = this;

            if (this.isChild) {
                this.servicesUrl = Services.generateUrl(this.context.serviceName)
                    + Constants.URL_GETCHILDREN_ACTION;
                this.rows.isChild = true;
                this.rows.childModelGenerator = DataAPI;
            }

            if (gridDataOptions && gridDataOptions.viewId) {
                this.rows.parentViewId = gridDataOptions.viewId;
            }

            if (gridDataOptions && gridDataOptions.selectAndProtectAll) {
                this.selectAndProtectAll = gridDataOptions.selectAndProtectAll;
            }

            const storedPreObj = cache.remove(
                'listView',
                {
                    context: this.context,
                },
            );

            if (storedPreObj !== null && Object.prototype.hasOwnProperty.call(storedPreObj, 'filters')) {
                this.filters = new Collection(storedPreObj.filters);
            }

            if (this.isInquiry) {
                return new Promise((resolve, reject) => {
                    const columnAndRowSuccess = util.after(2, () => {
                        resolve();
                    });

                    http.post(this.inquiryColumnsUrl, this.generateDataBlock(0), (data) => {
                        this.columns.processData(data.inquiryResponse, this.lvc);
                        columnAndRowSuccess();
                    }, (status) => {
                        reject(status);
                    });

                    http.post(this.inquiryRowsUrl, this.generateDataBlock(0), (data) => {
                        const lvc = this.lvc || { get: () => undefined };
                        this.rows.currentPage = this.currentPage;
                        if (lvc.get(LvcConstants.ROWSPERPAGE)) {
                            this.rowsPerPage = this.lvc.get(LvcConstants.ROWSPERPAGE);
                        } else if (data.rowsPerPage) {
                            this.rowsPerPage = data.rowsPerPage;
                        } else {
                            this.rowsPerPage = Constants.GRID_ROWS_PER_PAGE;
                        }
                        this.rows.processData(data.inquiryResponse);
                        columnAndRowSuccess();
                    }, (status) => {
                        reject(status);
                    });
                });
            } if (this.isComponent || this.isLookup || this.isService) {
                let url = this.servicesUrl;
                if (this.isComponent) {
                    url = this.componentUrl;
                }
                if (this.isLookup) {
                    url = this.lookupUrl;
                    /*
                     * NH-111901
                     * if lookup on first render load default amount of rows
                     * default amount is set in constants
                     */
                    this.rowsPerPage = Constants.LOOKUP_GRID_ROWS_PER_PAGE;
                }
                return new Promise((resolve, reject) => {
                    http.post(url, this.generateDataBlock(0, this.rowsPerPage), (dataParam) => {
                        const data = dataParam;
                        const lvc = {
                            get: () => undefined,
                            reset: () => undefined,
                            ...this.lvc,
                        };

                        if (data.viewId) {
                            this.viewId = data.viewId;
                        }
                        if (data.responseParameters) {
                            this.responseParameters = transform
                                .pairsToHash(data.responseParameters.item);
                        }
                        if (data.context && data.context.actionData) {
                            this.context.actionContext = data.context.actionData;
                        }
                        if (data.context && data.context.childActionData) {
                            this.context.childActionContext = data.context.childActionData;
                        }
                        if (data.respHeader) {
                            this.respHeader = data.respHeader;
                        }
                        if (data.rowsPerPage) {
                            this.rowsPerPage = data.rowsPerPage;
                        }
                        if (data.warningMessage) {
                            self.warningMessage = data.warningMessage;
                            appBus.trigger(`gridapi:showWarningMessage${self.gridId}`, data.warningMessage);
                        }

                        this.nextSeqNum = data.nextSeqNum;
                        data.filterFields = data.filterFields?.map((element) => {
                            const item = element;
                            if (item.popupId && item.fieldType === 'DATE') {
                                item.payload = Constants.DYNAMIC_DATE_OPTION_PAYLOAD;
                            }
                            return item;
                        });

                        appBus.trigger((`gridapi:showMessages${self.gridId}`), data.respHeader);
                        this.columns.processData(data, this.lvc);
                        this.rows.currentPage = (lvc.get(LvcConstants.CURRENTPAGE))
                            ? lvc.get(LvcConstants.CURRENTPAGE) : this.currentPage;
                        this.rows.processData(data);
                        this.detailReportId = data.detailReportId;
                        // TODO - put in a function - may need to call below in refresh
                        if (!util.isEmpty(lvc.get(LvcConstants.FILTERFIELDS))) {
                            const columns = this.columns.models.map(c => c.toJSON());
                            // for each filter, create a model and add it to this.filters
                            const savedFilters = lvc.get(LvcConstants.FILTERFIELDS)
                                .map(filter => filterUtil.createFilterModel(
                                    filter,
                                    columns,
                                    this.context,
                                ));
                            this.filters = new Collection([...savedFilters]);
                        }
                        resolve(data);
                    }, (status) => {
                        appBus.trigger(`gridapi:showErrorMessages${self.gridId}`, status);
                        reject(status);
                    });
                });
            }
            return undefined;
        },

        processGridData(data) {
            const lvc = {
                get: () => undefined,
                reset: () => undefined,
                ...this.lvc,
            };

            if (data.viewId) {
                this.viewId = data.viewId;
            }
            if (data.responseParameters) {
                this.responseParameters = transform.pairsToHash(data.responseParameters.item);
            }
            if (data.context && data.context.actionData) {
                this.context.actionContext = data.context.actionData;
            }
            if (data.context && data.context.childActionData) {
                this.context.childActionContext = data.context.childActionData;
            }
            if (data.respHeader) {
                this.respHeader = data.respHeader;
            }
            if (data.rowsPerPage) {
                this.rowsPerPage = data.rowsPerPage;
            }
            if (data.warningMessage) {
                this.warningMessage = data.warningMessage;
                appBus.trigger(`gridapi:showWarningMessage${this.gridId}`, data.warningMessage);
            }

            this.nextSeqNum = data.nextSeqNum;

            appBus.trigger((`gridapi:showMessages${this.gridId}`), data.respHeader);
            this.columns.processData(data, this.lvc);
            this.rows.currentPage = (lvc.get(LvcConstants.CURRENTPAGE))
                ? lvc.get(LvcConstants.CURRENTPAGE) : this.currentPage;
            this.rows.processData(data);
            this.detailReportId = data.detailReportId;
            // TODO - put in a function - may need to call below in refresh
            if (!util.isEmpty(lvc.get(LvcConstants.FILTERFIELDS))) {
                const columns = this.columns.models.map(c => c.toJSON());
                // for each filter, create a model and add it to this.filters
                const savedFilters = lvc.get(LvcConstants.FILTERFIELDS)
                    .map(filter => filterUtil.createFilterModel(
                        filter,
                        columns,
                        this.context,
                    ));
                this.filters = new Collection([...savedFilters]);
            }
            return data;
        },

        totalRefreshGridData(pagerView, sendRowsPerPage) {
            // Clear lvcSearchFields after view filter change
            if (pagerView) this.lvcSearchFields = [];
            return this.refreshGridData(true, pagerView, sendRowsPerPage);
        },

        /**
         * @method refreshGridData
         * @param {boolean} [totalRefresh] - Optional Flag for complete refresh/reset
         * of grid data
         * @param {object} [pagerViewParam] - Optional pagerView object needed for updating page
         * information
         * @param {boolean} [sendRowsPerPage] - Optional Flag to indicate if rowsPerPage should
         * be sent to server
         * - Method called after grid action has been made to send a service call for
         * new grid data
         * - Will send data needed to initiate a sort/filter/search etc
         *  @param {object} [reloadObj] - Object containing requestParams and searchFields
         *  in the event that we are reloading the grid by returing to the page
         *  from a detail view. We don't want a default value for this param as
         *  it's existence would indicate that this is a reload based on a return
         *  from a detail view.
         */
        refreshGridData(totalRefresh, pagerViewParam, sendRowsPerPage, reloadObj) {
            const pagerView = pagerViewParam;
            let localTotalRefresh = totalRefresh;
            let url;
            /*
             * There is a bug  on the backend that causes the profile filter to be skipped
             * when sending `dataOnly = 1`. Due to 3.7 timing, this wasn't able to be fixed
             * on the server so we are bypassing it for now until it can be addressed.
             */
            let dataOnly = 0;

            if (localTotalRefresh) {
                this.sortKey = null;
                this.sortOrder = null;
                if (this.filters !== null) {
                    this.ignoreNextGridFilterRequest = true;
                    this.filters.reset();
                    this.filters = null;
                }
                this.currentPage = 1;
                dataOnly = 0;
            }

            if (this.isInquiry) {
                url = this.inquiryRowsUrl;
            } else if (this.isLookup) {
                url = this.lookupUrl;
            } else if (this.isComponent) {
                url = this.componentUrl;
            } else {
                url = this.servicesUrl;
            }

            appBus.trigger((`gridapi:spin_${this.gridId}`), true);
            return http.post(
                url,
                this.generateDataBlock(dataOnly, sendRowsPerPage, reloadObj),
                (data) => {
                    const processData = (this.isInquiry) ? data.inquiryResponse : data;
                    const { rowsPerPage } = processData;
                    const lvc = this.lvc || { get: () => undefined };
                    this.currentPage = (lvc.get(LvcConstants.CURRENTPAGE))
                        ? lvc.get(LvcConstants.CURRENTPAGE) : this.currentPage;
                    this.rows.currentPage = this.currentPage;

                    if (pagerView && rowsPerPage) {
                        this.rowsPerPage = rowsPerPage;
                        const $paginate = pagerView.$el.find('.paginate-page-size');

                        // We hide this pagination drop down in mobile so lets make sure
                        // its there before updating
                        if ($paginate.length > 0) {
                            pagerView.pageSize = rowsPerPage;
                            pagerView.updatePageSizeValue(rowsPerPage);
                            pagerView.$el.find('.paginate-page-size').data('flexDropdown').setSelectedIds(rowsPerPage);
                        }
                    }

                    /* trigger event to update the saved view combo if necessary
                       (when a view has been deleted).
                     */
                    if (data.viewId && (data.viewId !== lvc.get(LvcConstants.VIEWID))) {
                        appBus.trigger('gridapi:savedView:set', data.viewId, this.context.serviceName);
                    }

                    if (data.viewId) {
                        this.viewId = data.viewId;
                    }
                    if (data.responseParameters) {
                        this.responseParameters = transform
                            .pairsToHash(data.responseParameters.item);
                        if (!localTotalRefresh) {
                            localTotalRefresh = (this.responseParameters.totalRefresh === 'true');
                        }
                    }

                    this.nextSeqNum = data.nextSeqNum;

                    this.rows.reset();
                    this.rows.processData(processData);
                    if (localTotalRefresh) {
                        this.columns.reset();
                        this.columns.processData(processData, this.lvc);
                    }
                    if (processData.respHeader) {
                        this.respHeader = processData.respHeader;
                    }

                    appBus.trigger((`gridapi:showMessages${this.gridId}`), processData.respHeader);

                    Promise.all(this.beforeGridContentReady)
                        .then(this.gridRefreshComplete.bind(this, processData.respHeader));
                },
                (status) => {
                    this.gridRefreshComplete(status, true);
                },
            );
        },

        /**
         * @method addGridRefreshPromise
         * @param {Promise} promise - Promise that the grid wrapper will wait for fulfillment
         * before finishing the grid refresh
         */
        pushGridRefreshPromise(promise) {
            this.beforeGridContentReady.push(promise);
        },

        /**
         * @method gridRefreshComplete
         * @param {string} message - Message to be passed to the showMessages trigger
         * @param {string} error
         * Triggers the appBus events to stop the grid spinner and show the grid messages
         */
        gridRefreshComplete(message, error) {
            if (this.b && typeof this.b.onGridRefreshComplete === 'function') {
                this.b.onGridRefreshComplete();
            }
            appBus.trigger((`gridapi:spin_${this.gridId}`), false);
            appBus.trigger((`gridapi:show${error ? 'Error' : ''}Messages${this.gridId}`), message);
            this.beforeGridContentReady = [];
        },

        /**
         * @method generateDataBlock
         * Generates a data object used for service calls to retrieve new grid data
         * usually called after grid actions done
         * @param {object} dataOnly
         * @param {Boolean} sendRowsPerPage
         * @param {Object} [reloadObj]
         */
        generateDataBlock(dataOnly, sendRowsPerPage, reloadObj) {
            let localDataOnly = dataOnly;
            let forceSendRowsPerPage = false;
            const rowsPerPage = this.rowsPerPage || 1;
            let startRow = (((this.currentPage - 1) * rowsPerPage) + 1);
            const lvc = this.lvc || { get: () => undefined };
            if (lvc.get(LvcConstants.ROWSPERPAGE)) {
                this.rowsPerPage = lvc.get(LvcConstants.ROWSPERPAGE);
                forceSendRowsPerPage = (rowsPerPage);
            }

            if (
                !util.isUndefined(lvc.get(LvcConstants.STARTROW))
                && !mobileUtil.isMobileGridEnabled()
            ) {
                startRow = lvc.get(LvcConstants.STARTROW);
            }

            let dataBlock = {
                startRow,
            };

            localDataOnly = (util.isNullOrUndefined(localDataOnly)) ? 0 : localDataOnly;

            if (forceSendRowsPerPage
                || (sendRowsPerPage && (this.rowsPerPage && this.rowsPerPage > 0))) {
                dataBlock.rowsPerPage = this.rowsPerPage;
            }

            if (lvc.get(LvcConstants.VIEWID)) {
                dataBlock.viewId = lvc.get(LvcConstants.VIEWID);
            } else if (!util.isNaN(parseInt(this.viewId, 10))) {
                dataBlock.viewId = this.viewId;
            }

            if (!util.isEmpty(this.disableDrillDown)
                || !util.isUndefined(this.disableDrillDown)) {
                dataBlock.disableDrillDown = this.disableDrillDown;
            }

            // only send the dataOnly flag for listviews not for batch child grids
            // only the getListView service is expecting this in the requestR
            if (!this.isChild) {
                dataBlock.dataOnly = localDataOnly;
            }
            if (this.context.subType && this.isChild) {
                dataBlock.subType = this.context.subType;
                dataBlock.entryMethod = 0;
                if (this.context.actionData && this.context.actionData.entryClass) {
                    dataBlock.entryClass = this.context.actionData.entryClass;
                }
                if (this.context.actionData && this.context.actionData.entryMethod) {
                    dataBlock.entryMethod = this.context.actionData.entryMethod;
                }
                dataBlock.action = this.parentState.toUpperCase();
                if (this.parentState.toUpperCase() !== 'INSERT') {
                    dataBlock.keyFields = this.parentKey.item;
                }
                dataBlock.batchSeqNumber = this.batchSeqNum;
            }

            const savedSort = lvc.get(LvcConstants.SORTFIELDS);
            if (savedSort) {
                dataBlock.sortFields = savedSort;
            } else if (this.sortKey && this.sortOrder) {
                dataBlock.sortFields = [{
                    fieldName: this.sortKey,
                    sortOrder: this.sortOrder,
                }];
            }
            this.rows.sortFields = dataBlock.sortFields;

            const additionalSearchFields = this.additionalSearchFields || [];

            const currentSearchFields = lvc.get(LvcConstants.FILTERFIELDS)
                ? this.generateFiltersFromConfig()
                : this.generateFiltersDataBlock();

            // keep the additionalSearchFields that initiated with the grid
            dataBlock.searchFields = util.union(currentSearchFields, additionalSearchFields);

            if (this.requestParameters) {
                dataBlock.requestParameters = this.requestParameters;
            }

            if (reloadObj && !util.isEmpty(reloadObj)) {
                dataBlock = { ...dataBlock, ...reloadObj };
            }

            // this.dataOnly = dataOnly;

            if (this.isInquiry) {
                return this.generateInquiryDataBlock(dataBlock);
            } if (this.isLookup) {
                return this.generateLookupDataBlock(dataBlock);
            } if (this.isComponent) {
                return this.generateComponentDataBlock(dataBlock);
            }

            return dataBlock;
        },

        getColumnModel(name) {
            let m = null;
            this.columns.each((c) => {
                if (c.get('fieldName') === name) {
                    m = c;
                }
            });
            return m;
        },

        getRespHeader(headerAttr) {
            if (!this.respHeader) {
                return false;
            } if (headerAttr) {
                return this.respHeader[headerAttr];
            }
            return this.respHeader;
        },

        getDataBlock() {
            return this.dataBlock;
        },

        generateFiltersDataBlock() {
            let searchFields = [];
            const { filters } = this;
            if (filters !== null && filters.models.length > 0) {
                filters.each((filter) => {
                    const column = this.getColumnModel(filter.get('field'));
                    const searchField = (column !== null && column.get('searchField')) || filter.get('field');
                    const equality = 'CONTAINS';

                    // workaround for a glu filter issue.
                    const filterType = column === null || !this.cashflowOverride ? filter.get('type') : column.get('type');
                    if (filterType === 'string' || filterType === 'multistring' || filterType === 'masked') {
                        searchFields[searchFields.length] = {
                            fieldName: searchField,
                            operator: (filterType === 'multistring') ? 'IN' : equality,
                            fieldValue: util.isArray(filter.get('value')) ? filter.get('value') : [filter.get('value')],
                            dataType: 'text',
                        };
                    } else if ((filterType === 'date' || filterType === 'gmtdate' || filterType === 'gmtdatetime') && this.cashflowOverride) {
                        let dates = [];
                        // date range
                        if (filter.get('value').indexOf(' to ') > 0) {
                            dates = filter.get('value').split(' to ');
                        } else if (util.isArray(filter.get('value'))) {
                            dates = filter.get('value');
                        } else {
                            dates = [filter.get('value')];
                        }

                        dates = dates.map((date) => {
                            if (filterType !== 'gmtdate') {
                                const actualDate = DateUtil.convertCashFlowCodesToDates(date);
                                return moment(actualDate, userInfo.getDateFormat())
                                    .format(this.defaultDateFormat);
                            }
                            return date;
                        });

                        searchFields[searchFields.length] = {
                            fieldName: searchField,
                            operator: (dates.length === 1 ? 'EQ' : 'BETWEEN'),
                            fieldValue: dates,
                            dataType: filterType,
                        };
                    } else if (filterType === 'amount' || filterType === 'number'
                               || ((filterType === 'date' || filterType === 'gmtdate' || filterType === 'gmtdatetime') && !this.cashflowOverride)) {
                        searchFields[searchFields.length] = {
                            fieldName: searchField,
                            operator: filter.get('equality') || 'EQ',
                            fieldValue: util.isArray(filter.get('value')) ? filter.get('value') : [filter.get('value')],
                            dataType: filterType,
                        };
                    } else if (filterType === 'enum' || filterType === 'typeahead') {
                        searchFields[searchFields.length] = {
                            fieldName: searchField,
                            operator: filter.get('equality') || 'EQ',
                            fieldValue: util.isArray(filter.get('value')) ? filter.get('value') : [filter.get('value')],
                            dataType: 'text',
                        };
                    }
                });
            }
            if (this.lvc && this.lvc.get(LvcConstants.ADDITIONALSEARCH)) {
                searchFields = searchFields.concat(this.lvc.get(LvcConstants.ADDITIONALSEARCH));
            } else if (this.additionalSearchFields) {
                searchFields = searchFields.concat(this.additionalSearchFields);
            }
            return searchFields;
        },

        /**
         * generates the filter object for the request from the listViewConfig
         * @returns {Object[]} - currentFilter array of
         */
        generateFiltersFromConfig() {
            let searchFields = [];
            const filters = this.lvc.get(LvcConstants.FILTERFIELDS);
            if (filters && filters !== null && filters.length > 0) {
                searchFields = filters.map((filter) => {
                    const column = this.getColumnModel(filter.fieldName);
                    const searchField = filter.fieldName;

                    // workaround for a glu filter issue.
                    const filterType = column === null || !this.cashflowOverride ? filter.dataType : column.get('type');
                    if ((filterType === 'date' || filterType === 'gmtdate' || filterType === 'gmtdatetime') && this.cashflowOverride) {
                        const dates = filter.fieldValue.map((date) => {
                            if (filterType !== 'gmtdate') {
                                const actualDate = DateUtil.convertCashFlowCodesToDates(date);
                                return moment(actualDate, userInfo.getDateFormat())
                                    .format(this.defaultDateFormat);
                            }
                            return date;
                        });

                        return {
                            fieldName: searchField,
                            operator: (dates.length === 1 ? 'EQ' : 'BETWEEN'),
                            fieldValue: dates,
                            dataType: filterType,
                        };
                    }
                    if (filter.enumHash) {
                        return {
                            fieldName: filter.fieldName,
                            operator: filter.operator,
                            fieldValue: filter.fieldValue,
                            dataType: filterType,
                        };
                    }
                    return filter;
                });
            }

            if (this.lvc.get(LvcConstants.ADDITIONALSEARCH)) {
                searchFields = searchFields.concat(this.lvc.get(LvcConstants.ADDITIONALSEARCH));
            }
            // make this property easily available to consumers
            this.lvcSearchFields = searchFields;
            return searchFields;
        },

        generateInquiryDataBlock(dataBlock) {
            const inquiryDataBlock = {
                inquiryRequest: {
                    startRow: dataBlock.startRow,
                    rowsPerPage: (dataBlock.rowsPerPage)
                        ? dataBlock.rowsPerPage : Constants.GRID_ROWS_PER_PAGE,
                    searchCriteria: this.inquirySearchCriteria,
                },

                requestHeader: {
                    requestId: 0,
                },
            };

            const criteria = inquiryDataBlock.inquiryRequest.searchCriteria;
            // if the search fields are prebuilt, merge them with any exsiting dataBlock fields
            if (criteria.searchFields
                && (dataBlock.searchFields && dataBlock.searchFields.length)) {
                criteria.searchFields = [
                    ...criteria.searchFields,
                    ...dataBlock.searchFields,
                ];
            } else if (!criteria.searchFields) {
                criteria.searchFields = dataBlock.searchFields;
            }

            criteria.sortFields = dataBlock.sortFields;

            return inquiryDataBlock;
        },

        generateLookupDataBlock(dataBlockParam) {
            const dataBlock = dataBlockParam;
            dataBlock.fieldName = this.b.fieldName;

            if (this.b.subType) {
                dataBlock.subType = this.b.subType;
            }
            dataBlock.depends = this.b.depends;
            if (this.b.typeInfo) {
                dataBlock.productCode = this.b.typeInfo.productCode;
                dataBlock.functionCode = this.b.typeInfo.functionCode;
                dataBlock.typeCode = this.b.typeInfo.typeCode;
            }
            return dataBlock;
        },

        generateComponentDataBlock(dataBlockParam) {
            const dataBlock = dataBlockParam;
            dataBlock.fieldName = this.componentFieldName;
            dataBlock.depends = this.componentDepends;
            dataBlock.action = this.parentState.toUpperCase();
            if (this.context) {
                dataBlock.productCode = this.context.productCode;
                dataBlock.typeCode = this.context.typeCode;
            }
            return dataBlock;
        },

        paginate(page, rowsPerPage) {
            this.currentPage = page;
            this.rowsPerPage = rowsPerPage;
            return this.refreshGridData(false, null, true);
        },

        /**
         * @method sort
         * @param {string} key - property key to sort by
         * @param {string} order - direction to sort by ("asc" or "desc")
         * - Will set the info needed to sort data in grid.
         * - Actual sorting handled by server, by sending this data in service call
         */
        sort(key, order) {
            this.sortKey = key;
            this.sortOrder = order;
            this.currentPage = 1;
            if (this.lvc) {
                this.lvc.resetToFirstPage();

                /* store in listViewConfig
                    TODO - update when we support multi-column sort
                  */
                this.lvc.set(
                    LvcConstants.SORTFIELDS,
                    [{
                        fieldName: this.sortKey,
                        sortOrder: this.sortOrder,
                    }],
                );
            }
            this.refreshGridData(false, null, true);
        },

        /**
         * @method filter
         * @param {object} filters - contains array of models to specify filtering
         * between keys
         * and values
         * - Will set the info needed to filter data in grid.
         * - Actual filtering handled by server, by sending this data in service call
         */
        filter(filters) {
            if (this.ignoreNextGridFilterRequest === false) {
                this.filters = filters;
                this.currentPage = 1;
                if (this.lvc) {
                    this.lvc.resetToFirstPage();
                    this.lvc.set(
                        LvcConstants.FILTERFIELDS,
                        this.createFiltersForConfig(filters),
                    );
                }
                this.refreshGridData(false, null, true);
                appBus.trigger(`gridapi:filter_${this.gridId}`, filters.models);
            } else {
                this.ignoreNextGridFilterRequest = false;
            }
        },

        setRequestParams(requestParams) {
            this.requestParameters = requestParams;
        },

        getRequestParams() {
            return this.requestParameters;
        },

        clearRequestParams() {
            this.requestParameters = false;
        },

        clearAddlSearchFields() {
            this.additionalSearchFields = null;
        },

        getActionContext() {
            if (this.context.actionContext) {
                return this.context.actionContext;
            }
            return {};
        },

        setComponentDepends(componentDepends) {
            if (componentDepends) {
                this.componentDepends = componentDepends;
            }
        },

        getComponentDepends() {
            return this.componentDepends;
        },

        createFiltersForConfig() {
            const searchFields = [];
            let theField;
            const { filters } = this;
            if (filters !== null && filters.models.length > 0) {
                filters.each((filter) => {
                    const column = this.getColumnModel(filter.get('field'));
                    const filterFieldName = filter.get('field');
                    let searchField = (column !== null && column.get('searchField')) || filterFieldName;
                    const equality = 'CONTAINS';
                    if (filterFieldName?.toLowerCase() === searchField?.toLowerCase()) {
                        searchField = filterFieldName;
                    }

                    // workaround for a glu filter issue.
                    const filterType = column === null || !this.cashflowOverride ? filter.get('type') : column.get('type');
                    if (filterType === 'string' || filterType === 'multistring' || filterType === 'masked') {
                        theField = {
                            fieldName: searchField,
                            operator: (filterType === 'multistring') ? 'IN' : equality,
                            fieldValue: util.isArray(filter.get('value')) ? filter.get('value') : [filter.get('value')],
                            dataType: 'text',
                        };
                    } else if ((filterType === 'date' || filterType === 'gmtdate' || filterType === 'gmtdatetime') && this.cashflowOverride) {
                        let dates = [];
                        const dateCode = filter.get('dateCode');
                        // date range
                        if (filter.get('value').indexOf(' to ') > 0) {
                            dates = filter.get('value').split(' to ');
                        } else if (util.isArray(filter.get('value'))) {
                            dates = filter.get('value');
                        } else {
                            dates = [filter.get('value')];
                        }

                        theField = {
                            fieldName: searchField,
                            operator: (dates.length === 1 ? 'EQ' : 'BETWEEN'),
                            fieldValue: dates,
                            dataType: filterType,
                        };
                        if (dateCode !== 'specific' && dateCode !== 'range') {
                            theField.dateCode = dateCode;
                        }
                    } else if (filterType === 'amount' || filterType === 'number'
                        || ((filterType === 'date' || filterType === 'gmtdate' || filterType === 'gmtdatetime') && !this.cashflowOverride)) {
                        theField = {
                            fieldName: searchField,
                            operator: filter.get('equality') || 'EQ',
                            fieldValue: util.isArray(filter.get('value')) ? filter.get('value') : [filter.get('value')],
                            dataType: filterType,
                        };
                    } else if (filterType === 'enum') {
                        theField = {
                            fieldName: searchField,
                            operator: filter.get('equality') || 'EQ',
                            fieldValue: util.isArray(filter.get('value')) ? filter.get('value') : [filter.get('value')],
                            dataType: 'text',
                            /*
                             * Check that we are dealing with a Collection before calling toJSON
                             * to avoid an exception. It might already be in a JSON format.
                             */
                            enumHash: column?.get('enumHash') instanceof Collection ? column?.get('enumHash').toJSON() : column?.get('enumHash'),
                        };
                    } else if (filterType === 'typeahead') {
                        theField = {
                            filterFieldName,
                            fieldName: searchField,
                            operator: 'EQ',
                            fieldValue: util.isArray(filter.get('value')) ? filter.get('value') : [filter.get('value')],
                            dataType: 'text',
                            enumHash: column.get('enumHash').toJSON(),
                        };
                    }
                    // NH-166152 bug fix.
                    theField.overrideSearchField = column?.get('overrideSearchField');
                    searchFields.push(theField);
                });
            }
            return searchFields;
        },
    };

    return wrapper;
}
