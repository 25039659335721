var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label class=\"btn btn-secondary btn-sm\">\n                <input type=\"radio\" name=\"section-id\" autocomplete=\"off\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":80},"end":{"line":6,"column":86}}}) : helper)))
    + "\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":6,"column":89},"end":{"line":6,"column":104}}}) : helper)))
    + "\n            </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div>\n        <div class=\"btn-group\" data-toggle=\"buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sections") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n</div>\n\n\n<section class=\"source-section\">\n        <h3></h3>\n\n        <div class=\"filter form-inline\">\n                <label for=\"filter-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":41}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.account.filter",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":59},"end":{"line":17,"column":93}}}))
    + "</label>\n                <input type=\"text\" placeholder="
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.search",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":73}}}))
    + " class=\"form-control show-search\" id=\"filter-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":18,"column":118},"end":{"line":18,"column":124}}}) : helper)))
    + "\" name=\"account-filter\">\n        </div>\n\n        <ul class=\"source-list\">\n\n        </ul>\n\n        <div class=\"move-accounts-control\"></div>\n</section>\n\n<section class=\"target-section\">\n    <h3>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.accountGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":37}}}))
    + "</h3>\n        "
    + alias4((lookupProperty(helpers,"customizedIcon")||(depth0 && lookupProperty(depth0,"customizedIcon"))||alias2).call(alias1,{"name":"customizedIcon","hash":{"textVisible":true,"className":"btn btn-tertiary pull-right","action":"add-group","locale":"gir.manageAccountGroups.addGroup","name":"add-circle"},"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":30,"column":163}}}))
    + "\n\n    <div class=\"target-list\">\n                <ul class=\"account-groups\">\n\n\n                </ul>\n        </div>\n</section>\n\n";
},"useData":true});