import MenuModel from '@glu/core/src/menuModel';
import locale from '@glu/locale';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';

export default MenuModel.extend({
    parse(obj) {
        if (!obj) {
            return undefined;
        }

        let home;
        const [menu] = obj[this.menuName];

        // Hide 'home' nav menu item if ShowHomeMenu is false
        if (serverConfigParams.get('ShowHomeMenu') !== 'false') {
            home = userInfo.hasLeftNavigation() ? locale.get('workspaces.SMBHOME.description') : locale.get('workspaces.HOME.description');

            menu.modules[0].components.unshift({
                description: home,
                digitalBanking: 0,
                displayOrder: 1,
                id: 'home',
                itemCount: 0,
                key: userInfo.hasLeftNavigation() ? 'PAYMENTS/smbManageHomePage' : 'workspace',
                menuCategory: 'home',
                name: home,
                menuIcon: 'home',
                parentMenu: 'MAIN',
            });
        }
        return menu;
    },
});
