import util from '@glu/core/src/util';
import BaseCollection from '@glu/core/src/collection';

export default (InputCollection) => {
    // Just covering ourselves, here.
    const Collection = InputCollection || BaseCollection;

    return Collection.extend({

        initialize(data, options) {
            this.rowProxies = [];
            this.rowProxyKeys = {};

            // Save off the original data
            this.storeData(data);

            if (options) {
                // Process each proxy in the object
                if (options.proxies) {
                    util.forEach(options.proxies, (fns, name) => {
                        this.addProxy(name, fns.load, fns.save);
                    });
                }
            }

            Collection.prototype.initialize.call(this, data, options);
        },

        generateProxyProcessor(isSave) {
            if (this.rowProxies.length === 0) {
                // pass-through if there are no proxies
                return data => data;
            }

            // Get the functions, remove the
            const proxyFunctions = util.chain(this.rowProxies)
                .compact()
                .pluck(isSave ? 'save' : 'load')
                .map((fn) => {
                    if (util.isFunction(fn)) {
                        return util.bind(fn, this);
                    }
                    // TODO: Should we throw an error here?
                    return undefined;
                })
                .compact()
                .value();

            return util.compose(...proxyFunctions);
        },

        addProxy(name, load, save) {
            // TODO: Support name in use check?

            const newLength = this.rowProxies.push({
                load,
                save,
            });

            // Index = length - 1;
            this.rowProxyKeys[name] = newLength - 1;
        },

        removeProxy(name) {
            const nameIndex = this.rowProxyKeys[name];

            if (nameIndex !== undefined) {
                // Too complicated to remove array elements.
                this.rowProxies[nameIndex] = undefined;
                this.rowProxyKeys[name] = undefined;
            }
        },

        storeData(data) {
            // Wipe out whatever existed before
            this.originalData = undefined;

            if (!data || !util.isObject(data)) {
                return false;
            }

            // Make a deep copy so it can't be accidentally modified
            this.originalData = JSON.parse(JSON.stringify(data));
            return true;
        },

        /**
         * Runs the "save" proxy pre-processor and returns the result
         */
        extractData() {
            const fromData = this.toJSON();
            const processor = this.generateProxyProcessor(true);

            return processor(fromData);
        },

        reset(data, options) {
            this.storeData(data);

            // Manipulate incoming data
            const processor = this.generateProxyProcessor();
            const outputData = processor(data);

            return Collection.prototype.reset.call(this, outputData, options);
        },

        sync(method, collection, options) {
            let outputData = collection.toJSON();

            if (method === 'read') {
                // Cut out the callbacks because we need to wait for our modifications.
                const passedOptions = util.extend({}, options, {
                    success: util.noop,
                    error: util.noop,
                });
                return Collection.prototype.sync(method, collection, passedOptions)
                    .then((data) => {
                        this.storeData(data);

                        // Modify the data
                        const processor = this.generateProxyProcessor();
                        outputData = processor(data);

                        // Reset it
                        Collection.prototype.reset.call(this, outputData, {
                            silent: true,
                        });

                        return outputData;
                    })
                    // Now handle the original callbacks with the new data
                    .then((data) => {
                        options.success(data);
                        return data;
                    }, (err) => {
                        options.error(err);
                        return err;
                    });
            }
            if (method === 'update') {
                const fromData = this.toJSON();
                const processor = this.generateProxyProcessor(true);
                outputData = processor(fromData);
                return Collection.prototype.sync.call(this, method, outputData, options);
            }
            if (method === 'create') {
                // TODO - Do we need something on create
                return Collection.prototype.sync.call(this, method, outputData, options);
            }
            if (method === 'delete') {
                // TODO - Do we need something on delete
                return Collection.prototype.sync.call(this, method, outputData, options);
            }
            // Unknown Method
            return Collection.prototype.sync.call(this, method, outputData, options);
        },
    });
};
