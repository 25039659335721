var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"alert-region\" data-region=\"alertRegion\"></div>\n<div class=\"panel\" role=\"tablist\">\n        <div aria-expanded=\"true\" class=\"panel-heading\" role=\"tab\" data-region=\"reportRegion\" id=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":112},"end":{"line":4,"column":118}}}) : helper)))
    + "\"  data-toggle=\"collapse\" data-target=\"#panel-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":169},"end":{"line":4,"column":175}}}) : helper)))
    + "\">\n                <span class=\"caret-indicator\" role=\"presentation\"></span>\n                <div class=\"filter-summary\">\n                        <div class=\"row\">\n                                <h2 class=\"panel-title form-group\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.reportsettings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":67},"end":{"line":8,"column":101}}}))
    + "</h2>\n                                <button type=\"button\" class=\"btn btn-tertiary form-group editButton edit-button-position\" data-action=\"edit\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.edit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":141},"end":{"line":9,"column":165}}}))
    + "</button>\n                        </div>\n                        <div class=\"row\">\n                                <div class=\"form-group  required col-4\">\n                                        <h3>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"allReports.AccountType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":79}}}))
    + "</h3>\n                                        <div>\n                                                <span data-hook=\"accountTypeFormatted\"></span>\n                                        </div>\n                                </div>\n                                <div class=\"form-group required col-4\">\n                                        <h3>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"payment.Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":44},"end":{"line":19,"column":72}}}))
    + "</h3>\n                                        <div>\n                                                <span data-hook=\"accountSelectionSummary\"></span>\n                                        </div>\n                                </div>\n                                <div class=\"form-group  required col-4\">\n                                        <h3>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.dateRange",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":44},"end":{"line":25,"column":83}}}))
    + "</h3>\n                                        <div>\n                                                <span data-bind=\"model\" data-text=\"START_DATE\"></span> - <span data-bind=\"model\" data-text=\"END_DATE\"></span>\n                                        </div>\n                                </div>\n                        </div>\n                </div>\n    </div>\n\n        <div aria-expanded=\"true\" id=\"panel-body-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":34,"column":49},"end":{"line":34,"column":55}}}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":34,"column":139},"end":{"line":34,"column":145}}}) : helper)))
    + "\">\n                <div class=\"form-inline\">\n                        <div class=\"form-group\"><h3>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.newreportsettings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":52},"end":{"line":36,"column":89}}}))
    + "</h3></div>\n\n                        <div class=\"form-row\">\n                                <div class=\"btn-group acctTypeBtns\" data-toggle=\"buttons\">\n                                  <label class=\"btn btn-secondary btn-sm active\">\n                                        <input type=\"radio\" name=\"account-type\" autocomplete=\"off\" value=\"DEPOSIT\"> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.DepositAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":116},"end":{"line":41,"column":148}}}))
    + "\n                                  </label>\n                                  <label class=\"btn btn-secondary btn-sm\">\n                                        <input type=\"radio\" name=\"account-type\" autocomplete=\"off\" value=\"LOAN\"> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.LoanAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":113},"end":{"line":44,"column":142}}}))
    + "\n                                  </label>\n                                </div>\n                        </div>\n\n                        <div class=\"form-row\">\n                                <div class=\"form-group\">\n                                        <label for=\"accountSelectBy\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.selectAccountsBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":85},"end":{"line":51,"column":131}}}))
    + "</label>\n                                        <select class=\"form-control row-label\" name=\"accountSelectBy\" id=\"accountSelectBy\" data-bind=\"model\">\n                                                <option value=\"number\" selected=\"selected\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":91},"end":{"line":53,"column":129}}}))
    + "</option>\n                                                <option value=\"group\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.accountGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":70},"end":{"line":54,"column":99}}}))
    + "</option>\n                                        </select>\n                                </div>\n                                <div class=\"form-group search-input-wrapper\">\n                                        <label for=\"accountSelect\" class=\"sr-only\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.accountTransactionsView",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":83},"end":{"line":58,"column":136}}}))
    + "</label>\n                                        <input type=\"hidden\" name=\"accountSelect\" id=\"accountSelect\" data-bind=\"model\">\n                                </div>\n                        </div>\n\n                        <div class=\"form-row\">\n                                <div class=\"form-group required\">\n                                        <label for=\"dateRange\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.dateRange",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":63},"end":{"line":65,"column":102}}}))
    + "</label>\n                                        <input type=\"text\" class=\"datepicker form-control\" name=\"dateRange\" id=\"dateRange\">\n                                </div>\n                        </div>\n\n           <div data-region=\"filterRegion\"/>\n        </div>\n\n                <div class=\"actions\">\n                        <button class=\"btn btn-primary\" data-action=\"update-filter\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":84},"end":{"line":74,"column":110}}}))
    + "</button>\n                        <button class=\"btn btn-secondary\" data-action=\"reset\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":78},"end":{"line":75,"column":103}}}))
    + "</button>\n                        <button class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":78},"end":{"line":76,"column":104}}}))
    + "</button>\n                </div>\n        </div>\n</div>\n";
},"useData":true});