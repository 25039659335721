import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import httpError from 'common/util/httpErrorResult';

const ChallengeModel = Model.extend({
    formatPopulationData() {
        const populationArray = [];
        const population = this.get('population');

        const type = this.get('populationType');

        // When a user adds or removes an item in the target panel, update the model
        switch (type) {
        case 'USERS':
            for (let u = 0; u < population.length; u += 1) {
                populationArray.push({
                    criteriaName: this.get('criteriaName'),
                    userId: population[u].USERID,
                    userName: population[u].USERNAME,
                    companyId: population[u].USERGROUP,
                    companyName: null,
                    segmentId: null,
                    segmentName: null,
                });
            }
            break;
        case 'COMPANIES':
            for (let c = 0; c < population.length; c += 1) {
                populationArray.push({
                    criteriaName: this.get('criteriaName'),
                    userId: null,
                    userName: null,
                    companyId: population[c].USERGROUP,
                    companyName: population[c].GROUPNAME,
                    segmentId: null,
                    segmentName: null,
                });
            }
            break;
        case 'SEGMENTS':
            for (let s = 0; s < population.length; s += 1) {
                populationArray.push({
                    criteriaName: this.get('criteriaName'),
                    userId: null,
                    userName: null,
                    companyId: null,
                    companyName: null,
                    segmentId: population[s].NAME,
                    segmentName: population[s].NAME,
                });
            }
            break;
        default:
        }

        this.set('population', populationArray);
    },

    sync(method, model, options) {
        let url;
        let data;

        this.formatPopulationData();

        if (method === 'create') {
            url = services.generateUrl('challengeManager/add');
            this.unset('action');
            data = this.toJSON();
            data.id = data.criteriaName;
            delete data.criteriaName;
            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'update') {
            url = services.generateUrl('challengeManager/update');

            this.unset('action');
            data = this.toJSON();
            data.id = data.criteriaName;
            delete data.criteriaName;

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }

        if (method === 'delete') {
            url = services.generateUrl('challengeManager/delete');

            data = {
                name: 'CRITERIANAME',
                value: this.get('Criteria'),
            };

            http.post(url, data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },
});
export default ChallengeModel;
