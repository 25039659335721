import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    SECONDARY,
} from '@glu/buttons-react';
import useLocale from 'hooks/useLocale';
import useRequest from 'hooks/useRequest';
import Dialog from '@glu/dialog';
import CloseToaModal from 'app/balanceAndTransaction/views/closeToaModal';
import useStyles from './CloseToaButton.styles';

const propTypes = {
    model: PropTypes.shape({
        get: PropTypes.func.isRequired,
        set: PropTypes.func.isRequired,
    }).isRequired,
    onToaTransferSuccess: PropTypes.func.isRequired,
};

const CloseToaButton = (props) => {
    const {
        model, onToaTransferSuccess,
    } = props;

    const [visible, setVisibility] = useState(false);
    const { post } = useRequest();
    const { getLocaleString } = useLocale();
    const classes = useStyles();

    const CAN_CLOSE_TOA_ENDPOINT = '/balanceAndTransaction/depositAccounts/toalocation/account/canClose';

    useEffect(() => {
        post(CAN_CLOSE_TOA_ENDPOINT, {
            item: [
                {
                    name: 'accountFilter',
                    value: model.get('accountFilter'),
                },
            ],
        }).then((res) => {
            setVisibility(res);
        });
    }, [model]); //eslint-disable-line

    const handleClick = () => {
        Dialog.open(new CloseToaModal({
            model,
            onToaTransferSuccess,
        }));
    };

    if (!visible) return null;

    return (
        <Button
            onClick={handleClick}
            variant={SECONDARY}
            className={classes.root}
            data-testid="close-toa-btn"
            text={getLocaleString('toa.CloseAccount')}
        />
    );
};

CloseToaButton.propTypes = propTypes;

export default CloseToaButton;
