import ItemView from '@glu/core/src/itemView';
import amountDetailTmpl from './amountDetail.hbs';

export default ItemView.extend({
    className: 'amountdetail-cell-condensed',
    template: amountDetailTmpl,

    templateHelpers() {
        const pendingAmount = this.model.get('PENDING_PAID_AMOUNT');

        return {
            exists: (pendingAmount !== null && Math.abs(pendingAmount) > 0),
        };
    },
});
