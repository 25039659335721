import LayoutView from '@glu/core/src/layout';
import $ from 'jquery';
import Grid from '@glu/grid';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import FileImportMapFieldsCollection from 'app/setup/collections/fileImportMapFields';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import FieldNameTemplate from './cell/fieldName';
import gridTmpl from './grid.hbs';

export default LayoutView.extend({
    template: gridTmpl,
    loadingTemplate: loadingPageTmpl,

    ui: {
        $selectAll: '.checkAll',
    },

    events: {
        'click @ui.$selectAll': 'selectAllBoxes',
    },

    initialize(options) {
        this.options = options;
    },

    onRender() {
        if (this.gridHasNoData) {
            this.showError(locale.get('IMPORT.gridHasNoData'));
        } else if (this.hasLoadedRequiredData()) {
            this.setMandatoryFieldNames();
            if (this.options.paymentType === 'CRTRAN') {
                this.setPersonalFieldsForRTP();
            }

            this.grid = new Grid({
                collection: this.gridCollections.rows,
                columns: this.gridCollections.columns,
                filter: false,
                sortable: false,
                draggable: false,
                resizable: false,
                disableAuxControl: true,
                paginate: false,
                pageSize: 10,
                pagerLocation: 'footer',
                loadingViewText: locale.get('setup.loading'),
            });
            this.gridContent.show(this.grid);

            if (this.options.paymentType === 'LBX_MRN') {
                const self = this;
                this.listenTo(this.grid.tableBody, 'render', () => {
                    self.disableCheckbox();
                });
            }

            if ($('.MATCH').length === 0) {
                // don't show check all match button if we don't have editable rows
                $('.checkAllMatched').hide();
            }
        } else {
            this.loadRequiredData();
        }
    },

    /*
     * Only show personal fields if it's RTP and REQUIREPERSONALID is enabled
     */
    setPersonalFieldsForRTP() {
        const personalFields = ['BENE_BIRTH_CITY', 'BENE_BIRTH_COUNTRY', 'BENE_BIRTH_DATE'];
        const isPersonalField = entry => personalFields.includes(entry.get('COLUMNNAME'));

        if (applicationConfigParams.getValue('RTP', 'REQUIREPERSONALID') !== '1') {
            const nonPersonalFields = this.gridCollections.rows.reject(isPersonalField);
            this.gridCollections.rows.reset(nonPersonalFields);
        }
    },

    /**
     * Map the Field Name and use the fieldName template for display of
     * column in the view
     */
    setMandatoryFieldNames() {
        const column = this.gridCollections.columns.findWhere({ field: 'SCREENNAME' });
        if (!column) {
            return;
        }
        column.set({
            cellView: FieldNameTemplate,

            cellViewOptions: {
                mode: this.options.mode,
            },
        });
    },

    disableCheckbox() {
        const tblRows = $('table tr');
        util.each(tblRows, (row, index) => {
            if (index !== 0) {
                const checkbox = $(row).find('input[type="checkbox"]');
                $(checkbox).attr('disabled', true);
            }
        });
    },

    loadRequiredData() {
        const self = this;

        this.gridCollections = new FileImportMapFieldsCollection(this.options);

        this.gridCollections.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },

            error() {
                self.gridHasNoData = true;
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    showError(message) {
        // display notification message
        this.alertView = alert.danger(
            message,
            {
                canDismiss: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    clearError() {
        this.alertRegion.reset();
    },

    /* Temporary fix to add ability to check and uncheck all until glu can be updated */
    checkAllActive() {
        this.toggleCheck('ACTIVE');
    },

    checkAllMatched() {
        this.toggleCheck('MATCH');
    },

    toggleCheck(clazz) {
        const obj = $(`.${clazz}`);
        const totalChecked = $(`.${clazz}:checked`).length;
        if (obj.length === totalChecked) {
            obj.each(function () {
                // uncheck them all
                $(this).click();
            });
        } else {
            obj.each(function () {
                if (!$(this).is(':checked')) {
                    $(this).click();
                }
            });
        }
    },
});
