/* istanbul ignore file */
import commonTheme from './commonTheme';

export default ({ palette, typography }) => ({
  dropdownChevron: {
    '&::before': {
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderTop: '4px solid #333',
      content: '""',
      display: 'block',
      height: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 10,
      top: 14,
      width: 0
    }
  },
  paginate: {
    '& svg': {
      fill: '#333'
    },
    fontSize: 14
  },
  selection: {
    borderRadius: 4,
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
    padding: [9, 30, 7, 10]
  },
  svgIconDisplay: 'none',
  textTransform: 'capitalize',
  ...commonTheme(typography, palette)
});
