import { registerDefaultsForModule } from '@glu/theming';
import legacy from './themes/legacyTheme';
import ash from './themes/ashTheme';

/**
 * Create the defaults for the switch module
 *
 * @param {Object} palette - palette hash from glu theming
 */

const themes = { ash, legacy };

registerDefaultsForModule('switchReact', theme => themes[theme.name](theme));
