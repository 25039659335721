import CompositeView from '@glu/core/src/compositeView';
import Recipient from 'app/administration/views/alerts/recipientItem';
import RecipientsCollection from 'app/administration/collection/recipients';
import util from '@glu/core/src/util';
import errorHandlers from 'system/error/handlers';
import $ from 'jquery';
import recipientsTmpl from 'app/administration/views/alerts/recipients.hbs';

const recipients = CompositeView.extend({

    template: recipientsTmpl,
    tagName: 'div',
    className: 'section section-container recipients-container',
    itemView: Recipient,
    itemViewOptions() {
        return {
            uncheckAll: this.uncheckAll.bind(this),
        };
    },
    itemViewContainer: '.recipient-items',
    alertModel: null,

    ui: {
        $allEmails: '#allEmails',
        $allSMS: '#allSMS',
        $allPhone: '#allPhone',
        $allFax: '#allFax',
        $allCheckboxes: 'input[type="checkbox"]',
    },

    events: {
        'change @ui.$allEmails': 'setAllEmails',
        'change @ui.$allSMS': 'setAllSMS',
        'change @ui.$allPhone': 'setAllPhone',
        'change @ui.$allFax': 'setAllFax',
    },

    initialize(options) {
        this.collection = new RecipientsCollection({
            actionMode: options.actionMode,
        });
        this.alertModel = this.options.formModel;

        this.recipientChangeHandler();
    },

    /**
     * @name uncheckAll
     * @description unchecks the ALL checkbox if associated
     * child box is unchecked
     * @param {string} type
     */
    uncheckAll(type) {
        const uncheckBox = util.filter(this.ui.$allCheckboxes, checkbox => $(checkbox).data('hook') === type);
        $(uncheckBox).prop('checked', false);
    },

    showHideContact(flag, selector) {
        if (flag) {
            this.$(selector).removeClass('hidden');
        } else {
            this.$(selector).addClass('hidden');
        }
    },

    validContact(list) {
        return !!(list && list.length > 0);
    },

    /**
     * @name hideHeadings
     * @description hides or shows the recipient container heading
     * @param {boolean} state
     */
    hideHeadings(state) {
        this.$('.recipients-container').toggleClass('hide', state);
    },

    /**
     * @name getValidFieldPredicate
     * @description returns a function that is used by the collection to find a
     * contact method
     * match
     * @param method
     * @returns {function(this:recipients)}
     */
    getValidFieldPredicate(method) {
        return recipient => this.validContact(recipient.get(method));
    },

    /**
     * @name updateContactHeaders
     * @description updates the recipient contact headers (emails, phone, SMS,
     * and fax) based on
     * the available contact methods of the recipients
     * @param {object} collection
     */
    updateContactHeaders(collection) {
        const anyEmails = collection.some(this.getValidFieldPredicate('email'));
        const anyPhone = collection.some(this.getValidFieldPredicate('phone'));
        const anySMS = collection.some(this.getValidFieldPredicate('sms'));
        const anyFax = collection.some(this.getValidFieldPredicate('fax'));

        this.hideHeadings(false);

        this.showHideContact(anyEmails, '.emailList');
        this.showHideContact(anyPhone, '.phoneList');
        this.showHideContact(anySMS, '.smsList');
        this.showHideContact(anyFax, '.faxList');
    },

    /**
     * @name removeRecipient
     * @description when a recipient is removed, this function is called.
     * the recipient (or recipients) are removed from the collection.  if there
     * are no more
     * recipients,
     * then the header is hidden
     * @param {string} recipientToGo
     * @param {boolean} group
     */
    removeRecipient(recipientToGo, group) {
        // find the model to be deleted
        let toGo;
        if (group) {
            // there will be one or more recipients in the group, so find all of them
            toGo = this.collection.where({
                groupName: recipientToGo,
            });
        } else {
            // there will be only 1 recipient that matches
            toGo = this.collection.findWhere({
                name: recipientToGo,
            });
        }
        this.collection.remove(toGo);

        if (this.collection.length === 0) {
            // hide header
            this.hideHeadings(true);
        } else {
            this.updateContactHeaders(this.collection);
        }
    },

    /**
     * @name addRecipient
     * @description retrieves the new recipient's contact information
     * @param {object} newRecipient
     */
    addRecipient(newRecipient) {
        /*
         * NH-180536 When this recipient is already in the collection,
         * there is no need to attempt to add it again. This whole section
         * of code around recipients is very convoluted and my be worth considering
         * a rebuild in React sooner than later.
         */
        if (this.collection.get(newRecipient.recipient) !== undefined) {
            return;
        }
        this.collection.recipientName = newRecipient.recipient;
        this.collection.alertId = newRecipient.alertId;
        this.collection.groupFlag = newRecipient.groupFlag;
        this.collection.fetch({
            add: true,
            merge: true,
            remove: false,
            success: this.updateNewRecipient.bind(this),
            error: errorHandlers.loading.bind(this),
        });
    },

    /**
     * @name recipientChangeHandler
     * @description gets the recipient's contact information when a recipient has been added
     */
    recipientChangeHandler() {
        /*
         * fetch the recipients, i.e. collection.fetch
         * args should have the recipient name, alertid and the groupflag
         */
        this.collection.recipientName = this.alertModel.get('RECIPIENT');
        this.collection.alertId = this.alertModel.get('ALERTID');
        this.collection.groupFlag = this.alertModel.get('GROUPFLAG');
        this.collection.fetch({
            initial: true,
            success: this.updateContactHeaders.bind(this),
            error: errorHandlers.loading.bind(this),
        });
    },

    /**
     * @name updateNewRecipient
     * @description handler for when a new recipient is added.
     * Need to update the contact method headings
     * @param {Collection} collection
     */
    updateNewRecipient(collection) {
        this.updateContactHeaders(collection);
    },

    updateAllContactByType(evt, type) {
        const state = evt.currentTarget.checked;
        const allModels = this.collection.models;
        util.each(allModels, (oneRecipient) => {
            let name = oneRecipient.get('name');
            // convert name, if email
            if (name.indexOf('.') !== -1) {
                name = name.replace(/\./g, '-').replace('@', '-');
            }
            name = name.replace(/ /g, '_');

            util.each(oneRecipient.get(type), (contact, index) => {
                const contactParam = contact;
                contactParam.selected = state;
                this.$(`#${name}-${type}-${index}`).prop('checked', state);
            }, this);
        }, this);
    },

    setAllEmails(evt) {
        /*
         * check the checkboxes for all the emails
         * update the email collections for each recipient
         */
        this.updateAllContactByType(evt, 'email');
    },

    setAllSMS(evt) {
        this.updateAllContactByType(evt, 'sms');
    },

    setAllPhone(evt) {
        this.updateAllContactByType(evt, 'phone');
    },

    setAllFax(evt) {
        this.updateAllContactByType(evt, 'fax');
    },

    templateHelpers() {
        return {
            notViewMode: this.collection.actionMode !== 'view',
        };
    },
});

export default recipients;
