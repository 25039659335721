import BaseWidget from 'common/uiWidgets/baseWidget/baseWidget';
import widgetValidator from 'common/util/validatorUtil';
import util from '@glu/core/src/util';
import template from './addressFieldWidget.hbs';

export default BaseWidget.extend({
    template,
    className: 'ui-widget address-widget field-container',

    initialize(options) {
        // Call base to init model, parentModel, readyState, fieldName, etc.
        BaseWidget.prototype.initialize.call(this, options);
        const { parentModel } = this;
        const msgEvtName = `${options.fieldName.toLowerCase()}_link:click`;

        this.fields = parentModel.fieldData;
        this.listenTo(this, msgEvtName, this.showAddressFields.bind(this));

        this.additionalAddressFields = [];
    },

    /*
     * Callback for the button link shown below the main Address field
     * As the user clicks on the 'add address line' link, the other
     * address lines are made visible (one at a time)
     */
    showAddressFields(e) {
        let fieldNeeded = true;
        const linkId = `#${e.target.id}`;
        let count = 0;
        const self = this;
        e.stopImmediatePropagation();
        /*
         * additionalAddressFields are the other Address Lines that are made visible
         * as the user clicks on the 'add address line' link
         */
        if (this.additionalAddressFields) {
            util.each(this.additionalAddressFields, (field) => {
                if (self.$(`[name=${field}]`).parent().hasClass('hide') && fieldNeeded) {
                    self.$(`[name=${field}]`).parent().removeClass('hide');
                    widgetValidator.setValidator(self.fields[field], self.parentModel);
                    fieldNeeded = false;
                }
                if (self.$(`[name=${field}]`).parent().hasClass('hide')) {
                    count += 1;
                }
            });
        }
        /*
         * if there are no more address lines left to be displayed,
         * let's hide the 'add address line' link
         */
        if (count > 0) {
            this.$(linkId).parent().removeClass('hide');
        } else {
            this.$(linkId).parent().addClass('hide');
        }
    },

    /*
     * onRender - Define callback for the on click for the 'add address line' button link
     */
    onRender() {
        const self = this;
        const msgEvtName = `${this.fieldName.toLowerCase()}_link:click`;
        const fldData = this.model.fieldData[this.fieldName];
        /*
         * 'ADDRESSFIELDWIDGET' is a new FIELDUITYPE that can be defined
         * in the FIELDTYPES table for the MDF screens
         */
        if (fldData.fieldUIType === 'ADDRESSFIELDWIDGET') {
            self.$(`#${this.fieldName}_LINK`).click((e) => {
                self.trigger(msgEvtName, e);
            });
            self.setupAddressFields(this.fieldName, fldData.showHideFields);
        }
    },

    /*
     * Setup the address fields
     * The additional address line fields are hidden by default when the action is INSERT mode
     * The other address lines are made visible as needed (when it contains data) -
     * MODIFY mode /INSERT mode (for copy as payment scenarios)
     */
    setupAddressFields(name, otherAddressFields) {
        const linkId = `#${name}_LINK`;
        if (otherAddressFields) {
            this.additionalAddressFields = otherAddressFields.slice(0);
            if (this.state === 'MODIFY' || this.state === 'INSERT') {
                const fieldsReversed = otherAddressFields.slice().reverse();
                let visible = false;
                let valueFound = false;
                let iCount = 0;
                const self = this;
                util.each(fieldsReversed, (field) => {
                    visible = !util.isEmpty(self.$(`[name=${field}]`).val()) || valueFound;
                    if (visible) {
                        self.$(`[name=${field}]`).parent().removeClass('hide');
                        widgetValidator.setValidator(self.fields[field], self.parentModel);
                    }
                    valueFound = visible;
                    if (valueFound || self.$(linkId).parent().hasClass('hide')) {
                        self.$(linkId).parent().removeClass('hide');
                        iCount += 1;
                    }
                });
                if (fieldsReversed.length - iCount === 0) {
                    this.$(linkId).parent().addClass('hide');
                }
            }
            if (this.state === 'VIEW') {
                this.$(linkId).parent().addClass('hide');
            }
        }
    },

    templateHelpers() {
        const self = this;
        const { state } = this;
        const fieldData = this.fields[this.fieldName];

        const object = {
            cid: this.cid,
            mainName: fieldData.name,
            mainFieldId: util.uniqueId(fieldData.name),
            mainFieldLabel: fieldData.fieldLabel,
            mainFieldMandatory: fieldData.mandatory,
            mainFieldProtected: fieldData.protected,
            mainFieldPlaceHolder: fieldData.placeHolder,
            mainFieldBlockClass: fieldData.blockClass,
            cssClass: fieldData.cssClass,
            mainFieldInfo: fieldData.info,
            mainFieldMaxLen: fieldData.maxLen,
            mainFieldOriginalMaxLen: fieldData.originalMaxLen,
            isReadOnly: state === 'VIEW',
            getMainFieldValue: this.parentModel.get(fieldData.name),
            hideMainFieldLabel: (state === 'VIEW' && this.parentModel.get(fieldData.name) === ''),
            fieldUiToolTip: fieldData.fieldUiToolTip,
            showToolTip: !!(state !== 'VIEW' && !util.isNullOrUndefined(fieldData.fieldUiToolTip)),
            otherFields: [],
        };

        util.each(fieldData.showHideFields, (field) => {
            self.fields[field].cssClass = fieldData.cssClass;
            self.fields[field].fieldId = util.uniqueId(field);
            self.fields[field].fieldValue = self.parentModel.get(field);
            object.otherFields.push(self.fields[field]);
        });
        return object;
    },
});
