import Collection from '@glu/core/src/collection';
import Type from '../../models/user2/type';

export default Collection.extend({
    model: Type,

    // select all entitlements for each type in a group
    selectAll(checked) {
        this.each((type) => {
            type.get('entitlements').selectAll(checked);
        });
    },
});
