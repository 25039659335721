import util from '@glu/core/src/util';
import { postData } from 'common/util/services';
import services from 'services';
import transform from 'common/util/transform';
import interfaceUtil from './util';

const defaultAttributes = {
    CMB_TYPE_DESCRIPTION: 'cmbTypeDescription',
    CMB_DEBIT_ACCOUNT_NAME: 'cmbDebitAccountName',
    CMB_DEBIT_ACCOUNT_NUMBER: 'cmbDebitAccountNumber',
    CMB_BENE_NAME: 'cmbBeneName',
    CMB_CREDIT_CURRENCY: 'cmbCreditCurrency',
    CMB_TRANSACTION_AMOUNT: 'cmbTransactionAmount',
    CMB_VALUE_DATE: 'cmbValueDate',
    LASTAPPROVER: 'lastApprover',
    TNUM: 'tNum',
    UPDATECOUNT__: 'updateCount__',
    APPROVED_BY_NAME_1: 'approvedByName1',
    APPROVED_BY_NAME_2: 'approvedByName2',
    APPROVED_BY_NAME_3: 'approvedByName3',
    APPROVED_BY_NAME_4: 'approvedByName4',
    APPROVED_BY_NAME_5: 'approvedByName5',
    APPROVED_BY_NAME_6: 'approvedByName6',
};

export default {
    /**
     * Make request for users pending approvals
     * @param {Object} options - options object
     * @param {number} options.startRow - db starting record
     * @param {number} options.rowsPerPage - db max rows returned
     * @returns {Promise} Promise object represents the request for user's pending approvals
     */
    getMyPendingApprovals(options = {}) {
        const defaultData = {
            startRow: 0,
            rowsPerPage: 50,
            viewId: 16,
            dataOnly: 0,
            searchFields: [],
        };
        const data = util.extend(defaultData, options);

        return postData(services.paymentList, data)
            .then(this.formatResponse.bind(this))
            .then(accounts => JSON.stringify(accounts))
            .catch(error => Promise.reject(JSON.stringify(error)));
    },

    /**
     * Convert the stand response object to data structure that native expects
     * @param {Object} response
     * @returns {Object}
     */
    formatResponse(response) {
        const rows = response.rows.map(this.mapRow.bind(this));
        return {
            rows,
            rowsPerPage: response.rowsPerPage,
            totalRows: response.totalRows,
            totalPages: response.totalPages,
        };
    },

    /**
     * Pull out entryMethod, functionCode, and service name from the context and use
     * it as a base object to add other properties to
     * @param {Object} row
     * @returns {Array}
     */
    mapRow(row) {
        const baseData = {
            entryMethod: row.context.actionData.entryMethod,
            functionCode: row.context.actionData.functionCode,
            serviceName: row.context.serviceName,
        };
        return util.extend(baseData, interfaceUtil.extractData(
            row.columns,
            defaultAttributes,
        ));
    },

    /**
     * Handle given action for payment. Action can be: approve|reject|delete
     * @param {Object} opts - options object
     * @param {string} opts.action - Action to take: approve|reject|delete
     * @param {string} opts.data - Required posted data
     */
    handleApprovalAction(opts = {}) {
        const options = util.extend({
            data: {},
        }, opts);
        const allowedActions = ['approve', 'reject', 'delete'];
        const requiredProps = [
            'serviceName', // row.context.serviceName
            'TNUM', // row.columns WHERE fieldName === TNUM
            'ENTRYMETHOD', // row.columns WHERE fieldName === ENTRYMETHOD
            'FUNCTIONCODE', // row.context.actionData.functionCode
            'UPDATECOUNT__', // row.columns WHERE fieldName === UPDATECOUNT__ (typically 0 but you never know)
        ];
        const hasRequiredProps = util.every(requiredProps, util.has.bind(util, options.data));
        const reqData = {};

        if (options.action === 'reject') {
            requiredProps.push('APPROVER_REJECTION_REASON');
        }

        if (!hasRequiredProps) {
            return Promise.reject(new Error('Missing required data props'));
        }
        if (!options.action || allowedActions.indexOf(options.action) === -1) {
            return Promise.reject(new Error(`Missing or Improper action: ${options.action}`));
        }

        const itemData = transform.hashToPairs(util.pick(
            options.data,
            ['TNUM', 'ENTRYMETHOD', 'FUNCTIONCODE', 'UPDATECOUNT__'],
        ));

        itemData.push({
            name: '_saveWithWarning',
        }, {
            name: 'ACTIONCODE',
        }, {
            name: 'CMDECISION',
        }, {
            name: 'CLIENTRETURNREASONCODE',
        }, {
            name: 'CLIENTRETURNREASONDESC',
        }, {
            name: 'PAYISSUE_FLAG',
        });

        if (options.action === 'reject') {
            itemData.push({
                name: 'APPROVER_REJECTION_REASON',
                value: options.data.APPROVER_REJECTION_REASON,
            });
        }

        // if the serviceName includes 'batch' we need to wrap the item in another object
        if (/batch\//.test(options.data.serviceName)) {
            reqData.item = {
                item: itemData,
            };
        } else {
            reqData.item = itemData;
        }

        return postData(
            services.generateUrl(`${options.data.serviceName}/${options.action}`),
            reqData,
        ).then(data => JSON.stringify(data))
            .catch(error => Promise.reject(JSON.stringify(error)));
    },

    /**
     * Approve given payment
     * @param {Object} options - options object
     * @param {string} options.serviceName - data from pending approvals response
     * @param {string} options.TNUM - data from pending approvals response
     * @param {string} options.ENTRYMETHOD - data from pending approvals response
     * @param {string} options.FUNCTIONCODE - data from pending approvals response
     * @param {string} options.UPDATECOUNT__ - data from pending approvals response
     */
    approve(options = {}) {
        return this.handleApprovalAction({
            action: 'approve',
            data: options,
        });
    },

    /**
     * Reject given payment
     * @param {Object} options - options object
     * @param {string} options.serviceName - data from pending approvals response
     * @param {string} options.TNUM - data from pending approvals response
     * @param {string} options.ENTRYMETHOD - data from pending approvals response
     * @param {string} options.FUNCTIONCODE - data from pending approvals response
     * @param {string} options.UPDATECOUNT__ - data from pending approvals response
     * @param {string} options.APPROVER_REJECTION_REASON - reason for payment rejection
     */
    reject(options = {}) {
        return this.handleApprovalAction({
            action: 'reject',
            data: options,
        });
    },

    /**
     * Delete given payment
     * @param {Object} options - options object
     * @param {string} options.serviceName - data from pending approvals response
     * @param {string} options.TNUM - data from pending approvals response
     * @param {string} options.ENTRYMETHOD - data from pending approvals response
     * @param {string} options.FUNCTIONCODE - data from pending approvals response
     * @param {string} options.UPDATECOUNT__ - data from pending approvals response
     */
    delete(options = {}) {
        return this.handleApprovalAction({
            action: 'delete',
            data: options,
        });
    },
};
