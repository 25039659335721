import _ from 'underscore';

const util = _;

util.isNullOrUndefined = function isNullOrUndefined(value) {
  return util.isNull(value) || util.isUndefined(value);
};

/*
 * TODO This polyfill for `findKeys` was added before Glu updated to Underscore 1.8+
 * Attempting to remove it now that we are on ~1.8.0 causes errors in unit tests.
 * Differences in predicate handling from the native cause failures for buildFilter()
 * in fieldSearch.js and others whose mechanism is unclear.
 */
// Returns the first key on an object that passes a predicate test
util.findKey = function findKey(obj, predicate, context) {
  const func = predicate;
  predicate = function (value) {
    return func.call(context, value);
  };
  const keys = util.keys(obj);
  let key;
  for (let i = 0, { length } = keys; i < length; i++) {
    key = keys[i];
    if (predicate(obj[key], key, obj)) {
      return key;
    }
  }

  return undefined;
};

export default util;

