import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import ListView from './listItemCollectionView';
import listControlsViewTmpl from './listControlsView.hbs';

export default Layout.extend({
    initialize(options) {
        this.listView = new ListView({
            collection: options.collection,
        });
    },

    template: listControlsViewTmpl,
    className: 'list-controls',

    regions: {
        listingRegion: '.listing-region',
    },

    ui: {
        selectAllBtn: '.btn-select-all',
        selectText: '.select-text',
    },

    events: {
        'click @ui.selectAllBtn': 'selectAllHandler',
    },

    selectAllHandler(e) {
        e.preventDefault();
        const self = this;
        if (self.ui.selectText.text() === 'Select All') {
            // ALL ON
            util.each(self.$el.find('.listing-region input[type=checkbox]'), (elem) => {
                self.$(elem).attr('checked', 'checked').addClass('checked');
                self.ui.selectText.text('Unselect All');
            });
        } else {
            // ALL OFF
            util.each(self.$el.find('.listing-region input[type=checkbox]'), (elem) => {
                self.$(elem).attr('checked', false).removeClass('checked');
                self.ui.selectText.text('Select All');
            });
        }
    },

    onRender() {
        this.listingRegion.show(this.listView.render());
    },
});
