import ItemView from '@glu/core/src/itemView';
import paymentTotalTmpl from 'app/smbPayments/views/modals/paymentTotal.hbs';
import RtgsHelper from 'app/smbPayments/util/rtgs';

export default ItemView.extend({
    initialize(options) {
        this.model = options.model;
        this.totalText = options.totalText;
    },

    template: paymentTotalTmpl,

    modelEvents: {
        'change:CREDIT_AMOUNT': 'render',
        'change:DEBIT_AMOUNT': 'render',
        'change:INDICATIVEAMOUNT': 'render',
    },

    templateHelpers() {
        return {
            totalText: this.totalText,
            total: this.model.formattedAmount(),
            debitTotal: this.model.formattedDebitAmount(),
            indiTotal: this.model.formattedIndicativeAmount(),
            showExchangeRate: RtgsHelper.showExchangeRate(this.model),
        };
    },
});
