import { CompositeView, util } from '@glu/core';
import FlexDropdownSelectionView from './flexDropdownSelectionView';
import FlexDropdownTooltip from './flexDropdownTooltipCollectionView';

import template from './flexDropdownSelectionCompositeView.hbs';

export default CompositeView.extend({
  noDefaultBehaviors: true,
  noGluEvents: true,
  template,
  itemView: FlexDropdownSelectionView,
  itemViewOptions() {
    return {
      clearBtn: this.clearBtn,
      showCancelIcon: this.options.collection.showCancelIcon
    };
  },

  itemViewContainer: 'ul.selected-items',

  ui: {
    tooltip: '.selection-tooltip',
    selectedItems: '.selected-items',
    totalSelected: '.total-selected',
    totalSelectedTotal: '.total-selected span'
  },

  onShow() {
    util.defer(util.bind(function onShow() {
      if (this.isClosed) {
        return;
      }
      this.collectionReset();
    }, this));
  },

  onCompositeCollectionRendered() {
    this.collectionReset();
  },

  initialize() {
    this.clearBtn = this.options.collection.clearBtn;
    this.showRemoveIcon(this.clearBtn);
  },

  /**
   * adding x-icon functionality
   */
  showRemoveIcon(icon) {
    if (!icon) {
      return;
    }
    this.collection.each((model) => {
      model.set({
        clearBtn: icon,
        showCancelIcon: this.options.collection.showCancelIcon
      });
    });
  },

  /**
   * Functions to execute when collection is modified
   */
  collectionReset() {
    this.showRemoveIcon(this.clearBtn);
    this.updateResults();
    this.updateTooltip();
  },

  /**
   * Tests to determine if the selected items are wrapping and displaying on more than one line.
   *
   * This tests two things.
   * 1. Is the relative top offset of a selected item greater than the previous one.
   *        This means an entire selected item has been moved to the next line because it did not fit.
   * 2. Is the height of a selected item greater than the previous one.
   *        This means the item did not fit on the line and has been internally wrapped and is now taller than the others.
   * @returns {boolean}
   */
  areSelectedItemsWrapping() {
    if (this.options.showSelected > -1) {
      return false;
    }

    let curHeight;
    let lastHeight;
    let curTopOffset;
    let lastTopOffset;
    let viewForModel;
    let wrapping = false;

    for (let i = 0; i < this.collection.length && !wrapping; i++) {
      viewForModel = this.children.findByModel(this.collection.at(i));

      if (viewForModel) {
        curHeight = viewForModel.$el.height();
        curTopOffset = viewForModel.$el.position().top;

        if ((lastHeight && curHeight > lastHeight) || (lastTopOffset && curTopOffset > lastTopOffset)) {
          wrapping = true;
        } else {
          lastHeight = curHeight;
          lastTopOffset = curTopOffset;
        }
      }
    }

    return wrapping;
  },

  /**
   * Check length of selected items array. If it is 5 or greater, show a total HTML
   */
  updateResults() {
    const { length } = this.collection;
    const lengthLimit = this.options.showSelected || 5;

    // if there is a chance the selected items might fit we need to show them if they are not showing as
    // we cannot check if they are wrapping onto multiple lines if they are hidden (display:none) as all measurements will be 0
    if (length <= lengthLimit && !this.selectedShowing) {
      this.ui.selectedItems.show();
      this.ui.totalSelected.hide();
      this.selectedShowing = true;
    }

    if (length > lengthLimit || this.areSelectedItemsWrapping()) {
      this.ui.selectedItems.hide();
      this.ui.totalSelected.show();
      this.ui.totalSelectedTotal.html(length);
      this.selectedShowing = false;
    }
  },

  /**
   * Loads HTML into the tooltip when selected item list is updated
   */
  updateTooltip() {
    if (this.isClosed) {
      return;
    }

    const showTooltip = this.collection.showTooltip();

    if (this.tooltip) {
      this.tooltip.close();
    }

    this.tooltip = new FlexDropdownTooltip({
      collection: this.collection
    });

    if (!showTooltip) {
      this.ui.tooltip.html('').removeClass('on');
      return;
    }

    this.ui.tooltip.html(this.tooltip.render().$el).addClass('on');
  }
});
