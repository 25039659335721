import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import $ from 'jquery';
import locale from '@glu/locale';
import ActionCell from 'common/dynamicPages/views/gridActionCell';
import Constants from 'common/dynamicPages/api/constants';
import EnumCollection from 'common/dynamicPages/collections/enum';
import services from 'services';
import ImageIndicatorCellView from 'common/dynamicPages/views/imageIndicatorCellView';
import ImageConversationCellView from 'common/dynamicPages/views/imageConversationCellView';
import NotesCellView from 'common/dynamicPages/views/notesCellView';
import EditableHoldCheckboxView from 'common/dynamicPages/views/gridCells/nachaHold';
import EditableAmountView from 'common/dynamicPages/views/gridCells/nachaAmount';
import ToolTipCellView from 'common/dynamicPages/views/toolTipCellView';
import MultiLineCellView from 'common/dynamicPages/views/multilineCellView';
import LockboxCellView from 'common/dynamicPages/views/lockboxCell';
import RateTradeResultCellView from 'common/dynamicPages/views/rateTradeResultCellView';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import LvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import serverConfigParams from 'system/webseries/models/configurationParameters';

export default Collection.extend({
    singleSelector: 'singleRowSelector',
    multiSelector: 'rowSelector',
    formatCombo: false,

    initialize(models, options) {
        this.selector = this.multiSelector;

        if (options) {
            if (options.selector) {
                this.selector = options.selector;
            }
            this.formatCombo = options.formatCombo;
            this.cellViews = options.cellViews;
            this.sortKey = options.sortKey || '';
            this.editableChildGrid = options.editableChildGrid || false;
            this.customFormatters = options.customFormatters;
            this.enableColumnRenameControls = options.enableColumnRenameControls;
            this.columnsToBeHidden = options.columnsToBeHidden;
            this.context = options.context;
            this.filterStaticDependsOn = options.filterStaticDependsOn;
        }
    },

    processData(jsonData, listViewConfig, compareColumns) {
        this.jsonData = jsonData;
        this.compareColumns = compareColumns;
        this.lvc = listViewConfig || { get: () => undefined };
        this.fields = {};

        this.isUserOwnedView = false;
        if (jsonData.viewOwner) {
            this.isUserOwnedView = !(jsonData.viewOwner === '_SYSTEM');
        }
        util.each(this.jsonData.filterFields, (field) => {
            this.fields[field.fieldName] = field;
        });
        util.each(this.jsonData.rowHeader, (column, index) => {
            const theField = this.fields[column.fieldName];
            if (theField && theField.popupId !== 0) {
                if (theField.type === Constants.COLUMN_TYPE_FROMSERVER_TYPEAHEAD) {
                    this.jsonData.rowHeader[index].type = 'TYPEAHEAD';
                } else if (theField.type === Constants.COLUMN_TYPE_FROMSERVER_DYNAMIC_DATE) {
                    this.jsonData.rowHeader[index].type = 2;
                } else {
                    if (column.type === Constants.COLUMN_TYPE_IMAGE_CONVERSATION) {
                        this.jsonData.rowHeader[index].actualType = column.type;
                    }
                    this.jsonData.rowHeader[index].type = 'ENUM';
                }
                this.jsonData.rowHeader[index].popupId = theField.popupId;
                this.jsonData.rowHeader[index].payload = theField.payload;

                this.jsonData.rowHeader[index].filterStaticDependsOn = this.filterStaticDependsOn;
                if (theField.codeField) {
                    this.jsonData.rowHeader[index].searchField = theField.codeField;

                    // NH-166152: Adding a new property to store fieldName in the filter.
                    if (theField.fieldName !== theField.codeField) {
                        this.jsonData.rowHeader[index].overrideSearchField = theField.fieldName;
                    }
                }
            }
            if (column.type === Constants.COLUMN_TYPE_DUMMY && column.fieldName.endsWith('_DISP')) {
                this.jsonData.rowHeader[index].searchField = column.fieldName.substring(0, column.fieldName.indexOf('_DISP'));
            }
        });
        this.fetch({
            reset: true,
        });
    },

    sync(method, model, options) {
        options.success(this.jsonData);
    },

    /**
     * Parse newly formatted header data from the backend into a format
     * that can be easily consumed by a grid.
     */
    parse() {
        let selector = null;
        let columnMap;
        if (this.selector !== 'none') {
            selector = {
                title: (this.selector === this.multiSelector) ? locale.get('common.all') : '',
                field: 'SELECTOR',
                type: this.selector,
                displayOrder: 0,
                disableRemove: true,
                enableRename: false,
                sortable: false,
                filterable: false,
                notSearchable: true,
                width: '80px',
            };
        }
        const actionData = this.jsonData.context?.actionData;
        const key = actionData ? `${actionData.typeCode}~${actionData.productCode}~${
            actionData.functionCode}` : null;
        let fixedWidth = false;
        const staticGridList = serverConfigParams.get('staticGridList');

        if (staticGridList && staticGridList.indexOf(key) > -1) {
            fixedWidth = true;
        }

        const baseColumnMap = util.map(
            this.jsonData.rowHeader,
            column => new Model(util.extend(
                column,
                this.parseColumn(column, undefined, fixedWidth),
            )),
        );
        // Map properties from result set into grid consumable values.
        if (this.lvc.get(LvcConstants.COLUMNS)) {
            const filteredBaseColumnMap = baseColumnMap
                .filter(i => i.attributes.displayOrder >= 0 &&
                    i.attributes.typeCode !== Constants.COLUMN_TYPE_HIDDEN);
            const lvcStoredColumns = this.lvc.get(LvcConstants.COLUMNS);
            const syncedColumnSet = this.syncColumnSets(filteredBaseColumnMap, lvcStoredColumns);

            columnMap = util.map(
                syncedColumnSet,
                column => new Model(util.extend(
                    column,
                    this.parseColumn(column, true),
                )),
            );
            if (this.selector === 'none') {
                /**
                 * NH-132333. Radio selector should still be shown when lvc columns are updated
                 */
                const radioSelector = util.find(
                    this.jsonData.rowHeader,
                    column => column.type === Constants.COLUMN_TYPE_RADIO,
                );
                selector = radioSelector
                    ? util.extend(radioSelector, this.parseColumn(radioSelector))
                    : null;
            }
            if (selector) {
                columnMap.unshift(selector);
            }
            return columnMap;
        }

        columnMap = baseColumnMap;
        const columnsToBeHidden = this.columnsToBeHidden || [];

        /*
         * Filter out hidden columns
         * Note: The intent for columns of type "hidden" from webseries is that
         * they are never visible to the user.
         * They are working on an additional property to express columns
         * that are initially not displayed
         * but available for filtering and via the add/remove menu.
         * A negative displayOrder also indicates the column should be hidden.
         */
        const visibleColumnMap = util.reject(columnMap, column => column.get('type') === 'hidden' || column.get('displayOrder') < 0 || columnsToBeHidden.indexOf(column.get('fieldName')) > -1);

        if (selector) {
            visibleColumnMap.unshift(selector);
        }

        // Sort columns by displayOrder.
        return util.sortBy(visibleColumnMap, (column) => {
            if (column.get) {
                return column.get('displayOrder');
            }
            return column.displayOrder;
        });
    },

    checkForColumns(baseColumns, lvcColumns) {
        let commonColumns;
        if (lvcColumns) {
            commonColumns = baseColumns
                .map((row) => {
                    if (!row.hidden) {
                        const lvcColumn = lvcColumns.find(item => row.get('fieldName') === item.fieldName);
                        if (lvcColumn) {
                            row.set('width', lvcColumn.width);
                        }
                    }
                    return row;
                })
                .map(i => i.attributes);
        } else {
            return baseColumns;
        }
        return commonColumns;
    },

    /**
     * @name - syncColumnSets
     * @description - makes sure returned lvc columns are in sync with possible server changes
     * @param {Object} baseColumns
     * @param {Object} lvcColumns
     * @returns {array} array of columns to use when leveraging LVC stored columns
     */
    syncColumnSets(baseColumns = [], lvcColumns = []) {
        if (this.compareColumns) {
            return this.checkForColumns(baseColumns, lvcColumns);
        }
        if (baseColumns.length === lvcColumns.length) {
            return lvcColumns;
        } else if (baseColumns.length < lvcColumns.length) {
            return lvcColumns.filter((col) => {
                const baseIndexFound = baseColumns.findIndex(el => (
                    el.attributes.fieldName === col.fieldName
                ));
                return baseIndexFound !== -1;
            });
        }
        const missingColumns = baseColumns.filter((col) => {
            const lvcIndexFound = lvcColumns.findIndex(el => (
                el.fieldName === col.attributes.fieldName
            ));
            return lvcIndexFound === -1;
        }).map(i => i.attributes);
        return [
            ...lvcColumns,
            ...missingColumns,
        ];
    },

    /**
     * @name - setupImageIndicatorColumn
     * @description - sets up imageIndicator column properties
     * @param {object} column
     * @returns {object} column with imageIndicator column properties
     */
    setupImageIndicatorColumn(column) {
        return {
            ...column,
            cellView: ImageIndicatorCellView,
            iconName: 'image-indicator',
        };
    },

    /**
     * @name - setupImageNoteColumn
     * @description - sets up imageNote column properties
     * @param {object} column
     * @returns {object} column with imageNote column properties
     */
    setupImageNoteColumn(column) {
        return {
            ...column,
            cellView: NotesCellView,
            iconName: 'claim-notes',
        };
    },

    /**
     * @name - setupImageConversationColumn
     * @description - sets up imageConversationColumn properties
     * @param {object} column - current column attributes
     * @param {object} origColumn - column attributes from server
     * @returns {object} column with imageConversation column properties
     *
     */
    setupImageConversationColumn(column, origColumn) {
        return {
            ...column,
            cellView: ImageConversationCellView,
            iconName: 'chat-bubble',
            iconDesc: 'rtp.conversation.image.start',
            ...this.setupEnumColumn(origColumn),
        };
    },

    /**
     * @name setupEnumColumn
     * @description returns an object that contains the emun column properties
     * @param {object} column
     * @returns {object} object with the emum column properties
     */
    setupEnumColumn(column) {
        return {
            type: 'enum',
            serverSideEnum: true,
            enumHash: new EnumCollection(
                [],
                {
                    // for enum
                    serviceUrl: services.generateUrl('inquiry/getRowsData'),

                    inquiryId: column.popupId,
                    context: this.context,
                    customFilters: column.filterStaticDependsOn,
                },
            ),
        };
    },

    /**
     * Map incoming column data to grid equivalents.
     *
     * @param col
     * @param {boolean} [leaveConditionAsIs]
     * @returns {{}}
     */
    parseColumn(col, leaveConditionAsIs, fixedWidth) {
        const column = col;
        let extColumn = {
            field: column.fieldName,
            title: column.displayName,
            condition: (leaveConditionAsIs) ? column.condition : !column.hidden,
            typeCode: column.type,
        };

        /*
         *  NH-148916
         *  since the server is not respecting the notsearchable column in gridcolumns,
         *  set the notSearchable attribute to true for image columns
         */
        if (column.type === Constants.COLUMN_TYPE_IMAGE_INDICATOR) {
            column.notSearchable = true;
        }

        if (fixedWidth) {
            extColumn.width = column.fieldSize;
        }

        // all columns are left aligned unless numeric or amount
        extColumn.className = 'text-left';
        const allowSecondary = applicationConfigParams.getValue('ACH', 'ALLOWSECONDPRACC');
        const vyes = locale.get('common.yes');
        const vno = locale.get('common.no');
        /*
         *  COLUMN_TYPE_IMAGE_CONVERSATION is a special type
         *  the filter is an enum but the column displays as an icon, not text
         *  actualType is set up in parse to reflect the display type for this
         *  column type.
         *  In addition, some grid views(e.g.Needs Rate) already come back with columns
         *  describing their typeCode
         */
        let typeCode;
        if (column.actualType) {
            typeCode = column.actualType;
        } else if (column.typeCode) {
            typeCode = typeof column.typeCode === 'string' ? column.typeCode.toUpperCase() : column.typeCode;
        } else {
            typeCode = column.type;
        }
        switch (typeCode) {
        case Constants.COLUMN_TYPE_HIDDEN_IF_NOSECONDARY:
        case Constants.COLUMN_TYPE_HIDDEN_IF_NOSECONDARY2:
            if (allowSecondary !== '1' || this.context.reimburse) {
                extColumn.type = 'hidden';
                extColumn.condition = false;
            } else {
                extColumn.type = 'string';
                if (column.tooltipCol) {
                    extColumn.cellView = ToolTipCellView;
                }
            }
            break;
        case Constants.COLUMN_TYPE_PAYMENT_DETAILS:
            extColumn.type = 'string';
            extColumn.cellView = MultiLineCellView;
            break;
        case Constants.COLUMN_TYPE_STRING:
        case Constants.COLUMN_TYPE_MESSAGE:
            extColumn.type = 'string';
            if (column.tooltipCol) {
                extColumn.cellView = ToolTipCellView;
            }
            break;
        case Constants.COLUMN_TYPE_MASKED_ACCOUNT:
            extColumn.type = this.getMaskedType(column);
            if (column.tooltipCol) {
                extColumn.cellView = ToolTipCellView;
            }
            break;
        case Constants.COLUMN_TYPE_DATE:
            extColumn.type = 'date';
            if (column.tooltipCol) {
                extColumn.cellView = ToolTipCellView;
            }
            break;
        case Constants.COLUMN_TYPE_SYSTEMDATE:
        case Constants.COLUMN_TYPE_GMT_TIMESTAMP:
            extColumn.type = 'date';
            if (column.tooltipCol) {
                extColumn.cellView = ToolTipCellView;
            }
            break;
        case Constants.COLUMN_TYPE_GMT_DATETIME:
            extColumn.type = 'gmtdatetime';
            break;
        case Constants.COLUMN_TYPE_NUMERIC:
            extColumn.type = 'number';
            extColumn.className = 'text-right';
            break;
        case Constants.COLUMN_TYPE_AMOUNT:
        case Constants.COLUMN_TYPE_AMOUNT_SCS:
        case Constants.COLUMN_TYPE_EDIT_AMOUNT_SCS:
        case Constants.COLUMN_TYPE_DECIMAL:
            if (column.fieldName === 'AMOUNT' && this.editableChildGrid) {
                extColumn.cellView = EditableAmountView;
            }
            extColumn.typeCode = Constants.COLUMN_TYPE_AMOUNT;
            extColumn.type = 'amount';
            extColumn.className = 'text-right';
            break;
        case Constants.COLUMN_TYPE_BOOLEAN:
            /*
             * Boolean columns are displayed in the grid as yes/no enumerations. This logic sets
             * the column type from boolean to 'enum'. When the grid display is modified by the
             * user, the grid model is cached. When the cached grid is rendered later having the
             * type 'enum' causes the column to display as a default rather than an enumeration.
             * Saving the actualType with the cached grid model will override the "incorrect" type
             * and correctly process and render the content.
             */
            extColumn.actualType = Constants.COLUMN_TYPE_BOOLEAN;
            extColumn.type = 'enum';
            if (column.fieldName === 'HOLDFLAG' && this.editableChildGrid && !(this.context && this.context.actionMode === 'repair')) {
                extColumn.cellView = EditableHoldCheckboxView;
            }

            extColumn.serverSideEnum = false;
            extColumn.enumHash = {};
            extColumn.enumHash[vno] = 0;
            extColumn.enumHash[vyes] = 1;

            // yes/no formatter display instead of 0/1
            extColumn.format = (curVal) => {
                if (curVal === 0 || curVal === '0') {
                    return locale.get('common.no');
                } else if (curVal === 1 || curVal === '1') {
                    return locale.get('common.yes');
                }
                return curVal;
            };
            break;
        case Constants.COLUMN_TYPE_ACTION_LIST:
        case Constants.COLUMN_TYPE_ENTITLEMENT_ACTION_LIST:
        case Constants.COLUMN_TYPE_ACCESSIBILITY_ACTION_LIST:
            extColumn.cellView = ActionCell;
            extColumn.display = 'dropdown';
            extColumn.sortable = false;
            extColumn.filterable = false;
            extColumn.disableRemove = true;
            extColumn.condition = true;
            break;
        case Constants.COLUMN_TYPE_HIDDEN:
        case Constants.COLUMN_TYPE_HIDDEN_DATE:
            extColumn.type = 'hidden';
            extColumn.condition = false;
            break;
        case Constants.COLUMN_TYPE_COMBO:
            extColumn.type = 'string';
            // TODO Made this very generic, this has to be addressed globally for all the Grids
            if (this.formatCombo) {
                extColumn.format = (curVal) => {
                    let retVal = curVal;
                    let option;
                    /*
                     * get the dom element / select  that has the same name as the
                     * column name and get the text for the option value.
                     */
                    const domElem = $(`select[name="${column.fieldName}"]`);
                    if (domElem && domElem.length === 1) {
                        option = util.findWhere(
                            domElem[0].options,
                            {
                                value: curVal,
                            },
                        );
                        if (option) {
                            retVal = option.text;
                        }
                    }
                    return retVal;
                };
            }
            break;

        case Constants.COLUMN_TYPE_IMAGE_INDICATOR:
            extColumn.type = 'string';
            extColumn = this.setupImageIndicatorColumn(extColumn);
            break;

        case Constants.COLUMN_TYPE_RATETRADERESULT:
            extColumn.type = 'string';
            extColumn.cellView = RateTradeResultCellView;
            break;

        case Constants.COLUMN_TYPE_IMAGE_NOTE_INDICATOR:
            extColumn.type = 'string';
            extColumn = this.setupImageNoteColumn(extColumn);
            break;
        case Constants.COLUMN_TYPE_IMAGE_CONVERSATION:
            extColumn.type = 'enum';
            extColumn = this.setupImageConversationColumn(extColumn, column);
            break;
        case Constants.COLUMN_TYPE_RADIO:
            extColumn.type = 'singleRowSelector';
            extColumn.sortable = false;
            extColumn.filterable = false;
            extColumn.disableRemove = true;
            extColumn.width = '75px';
            break;
        case Constants.COLUMN_TYPE_TYPEAHEAD:
            extColumn.type = 'typeahead';
            extColumn.serverSideEnum = true;
            extColumn.enumHash = new EnumCollection(
                [],
                {
                    // for typeaheads
                    serviceUrl: services.generateUrl('inquiry/getRowsData'),

                    inquiryId: column.popupId,
                    context: this.context,
                    fieldName: column.fieldName,
                    customFilters: column.filterStaticDependsOn,
                },
            );
            break;
        case Constants.COLUMN_TYPE_ENUM:
            // extColumn needs to include the enum properties
            extColumn = { ...extColumn, ...this.setupEnumColumn(column) };
            break;
        case Constants.COLUMN_TYPE_LOCKBOX_CHECK_DATA:
            extColumn.type = 'string';
            extColumn.cellView = LockboxCellView;
            break;
        case Constants.COLUMN_TYPE_LOCKBOX_AMOUNT:
            extColumn.type = 'amount';
            extColumn.cellView = LockboxCellView;
            extColumn.typeCode = Constants.COLUMN_TYPE_AMOUNT;
            extColumn.className = 'text-right';
            break;
        case Constants.COLUMN_TYPE_LOCKBOX_DATE:
            extColumn.type = 'date';
            extColumn.cellView = LockboxCellView;
            break;
        case Constants.COLUMN_TYPE_DUMMY:
            /*
             * masked input - when masking is configured,
             * the input will be masked; otherwise, plain text
             */
            extColumn.type = 'masked';
            break;
        default:
            if (!leaveConditionAsIs) {
                extColumn.type = 'string';
            }
            extColumn.typeCode = column.typeCode;
            switch (column.typeCode) {
            case Constants.COLUMN_TYPE_PAYMENT_DETAILS:
                extColumn.cellView = MultiLineCellView;
                break;
            case Constants.COLUMN_TYPE_IMAGE_CONVERSATION:
                extColumn = this.setupImageConversationColumn(extColumn, column);
                break;
            case Constants.COLUMN_TYPE_IMAGE_INDICATOR:
                extColumn = this.setupImageIndicatorColumn(extColumn);
                break;
            case Constants.COLUMN_TYPE_IMAGE_NOTE_INDICATOR:
                extColumn = this.setupImageNoteColumn(extColumn);
                break;
            default:
                break;
            }
            if (column.typeCode === Constants.COLUMN_TYPE_AMOUNT
                || column.typeCode === Constants.COLUMN_TYPE_NUMERIC) {
                extColumn.className = 'text-right';
            }
            if (column.tooltipCol && column.typeCode === Constants.COLUMN_TYPE_STRING) {
                extColumn.cellView = ToolTipCellView;
            }
            if (column.fieldName === 'AMOUNT' && this.editableChildGrid) {
                extColumn.cellView = EditableAmountView;
            }
        }

        const customFormatter = util.findWhere(
            this.customFormatters,
            {
                fieldName: column.fieldName,
            },
        );
        if (customFormatter) {
            extColumn.format = customFormatter.format;
        }

        if (this.cellViews && this.cellViews[extColumn.field]) {
            extColumn.cellView = this.cellViews[extColumn.field];
        }

        if (this.enableColumnRenameControls) {
            extColumn.enableRename = this.enableColumnRenameControls;
        }
        return extColumn;
    },

    /**
     * @name getMaskedType
     * @description returns either 'string' or 'masked' based on the filter field
     * associated with the column
     * @param {object} column
     */
    getMaskedType(column) {
        let type = 'string';
        const { filterFields } = this.jsonData;
        if (filterFields) {
            const filterField = filterFields.find(f => f.fieldName === column.fieldName);
            if (filterField && filterField.type === Constants.MASKED_INPUT_FILTER_TYPE) {
                type = 'masked';
            }
        } else {
            type = 'masked';
        }
        return type;
    },
});
