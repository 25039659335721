var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"title",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":52}}}))
    + "</h1>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"command-and-control-fat-tabs\">\n    <ul>\n        <li role=\"presentation\" class=\""
    + alias3((lookupProperty(helpers,"getActiveClass")||(depth0 && lookupProperty(depth0,"getActiveClass"))||alias2).call(alias1,"allFeatures",{"name":"getActiveClass","hash":{},"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":71}}}))
    + "\">\n            <a href=\"#\" data-hook=\"tab-button\" data-tab=\"allFeatures\">\n                <span class=\"fat-tabs-main-text\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"allFeaturesCount") || (depth0 != null ? lookupProperty(depth0,"allFeaturesCount") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"allFeaturesCount","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":69}}}) : helper)))
    + "</span>\n                <span class=\"fat-tabs-sub-text\">"
    + alias3(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"allFeatures") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":81}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"presentation\" class=\""
    + alias3((lookupProperty(helpers,"getActiveClass")||(depth0 && lookupProperty(depth0,"getActiveClass"))||alias2).call(alias1,"pendingApprovals",{"name":"getActiveClass","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":76}}}))
    + "\">\n            <a href=\"#\" data-hook=\"tab-button\" data-tab=\"pendingApprovals\">\n                <span class=\"fat-tabs-main-text\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"pendingApprovalCount") || (depth0 != null ? lookupProperty(depth0,"pendingApprovalCount") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"pendingApprovalCount","hash":{},"data":data,"loc":{"start":{"line":18,"column":49},"end":{"line":18,"column":73}}}) : helper)))
    + "</span>\n                <span class=\"fat-tabs-sub-text\">"
    + alias3(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"requestsPending") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":48},"end":{"line":19,"column":85}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAllFeaturesBeforeRequestPretext") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAllFeaturesAfterRequestPretext") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPendingApprovalsForRequestorPretext") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPendingApprovalsForApproverPretext") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":41,"column":23}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"listBeforeRequestPretext") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":19},"end":{"line":31,"column":65}}}))
    + "</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"listAfterRequestPretext") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":19},"end":{"line":34,"column":64}}}))
    + "</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"listForRequestorPretext") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":19},"end":{"line":37,"column":64}}}))
    + "</p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"listForApproverPretext") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":19},"end":{"line":40,"column":63}}}))
    + "</p>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModifyEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":53,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasModifyEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":58,"column":23}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary requestChanges\"\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"requestButtonsDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":65}}})) != null ? stack1 : "")
    + "\n                    data-action=\"requestChanges\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"submitForApproval") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":49},"end":{"line":52,"column":88}}}))
    + "</button>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary reset\"\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"requestButtonsDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":65}}})) != null ? stack1 : "")
    + "\n                    data-action=\"reset\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"reset") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":40},"end":{"line":57,"column":67}}}))
    + "</button>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasApproveEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":65,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasRejectEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":70,"column":23}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary approve\"\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"approveButtonsDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":20},"end":{"line":63,"column":65}}})) != null ? stack1 : "")
    + "\n                    data-action=\"approve\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"approve") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":42},"end":{"line":64,"column":71}}}))
    + "</button>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-secondary reject\"\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"approveButtonsDisabled") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":65}}})) != null ? stack1 : "")
    + "\n                    data-action=\"reject\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"localeKeys") : depth0)) != null ? lookupProperty(stack1,"reject") : stack1),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":69,"column":41},"end":{"line":69,"column":69}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSubHeader") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"initialDataLoaded") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":24,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"section section-container command-and-control-list-view hide-actions-column\">\n    <div class=\"section-body\">\n        <div class=\"btn-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"initialDataLoaded") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div class=\"grid-region "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gridErrorClass") || (depth0 != null ? lookupProperty(depth0,"gridErrorClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"gridErrorClass","hash":{},"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":45,"column":50}}}) : helper)))
    + "\" data-region=\"gridRegion\"></div>\n\n        <div id=\"complete\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRequestButtons") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":59,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showApproveButtons") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":71,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});