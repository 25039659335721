var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"required field-container\" data-hook=\"rejectReasonFieldContainer\">\n    <label for=\"REJECT_REASON\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gridAction.popup.rejectionReason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":76}}}))
    + "</label>\n    <div data-region=\"rejectDropdownRegion\">\n    </div>\n    <span class=\"help-block\" data-hook=\"rejectReasonErrorHolder\"></span>\n</div>\n<div class=\"required field-container hidden\" data-hook=\"commentFieldContainer\">\n    <label for=\"COMMENT\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gridAction.popup.comment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":63}}}))
    + "</label>\n    <input class=\"rcount form-control\" type=\"text\" maxlength=\"105\" data-bind=\"model\" id=\"COMMENT\" name=\"COMMENT\" value=\"\">\n    <span class=\"textline-field pull-right\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.charactersRemaining",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":44},"end":{"line":11,"column":83}}}))
    + "105</span>\n    <span class=\"help-block\" data-hook=\"commentErrorHolder\"></span>\n</div>";
},"useData":true});