var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"print-payment\">\n  <form autocomplete=\"off\" data-submit=\"save\">\n    <div class=\"validation-group hide\" role=\"alert\">\n        <p class=\"help-block center\" data-validation=\"generic\"></p>\n    </div>\n      <div id=\"dataDiv\">\n          <div class=\"field-container-lg\">\n              <label>"
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"fileImportHistory.print.data",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":8,"column":21},"end":{"line":8,"column":65}}}))
    + "</label>\n              <div class=\"form-group\">\n                  <label class=\"radio-inline\">\n                      <input type=\"radio\" id=\"expdataSum\" value=\"summary\" name=\"expdata\" data-bind=\"model\" />\n                      "
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"fileImportHistory.summary.info",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":12,"column":22},"end":{"line":12,"column":68}}}))
    + "\n                  </label>\n                  <label class=\"radio-inline\">\n                      <input type=\"radio\" id=\"expdataTran\" value=\"transaction\" name=\"expdata\" data-bind=\"model\" />\n                      "
    + alias3((lookupProperty(helpers,"getString")||(depth0 && lookupProperty(depth0,"getString"))||alias2).call(alias1,"fileImportHistory.detail.info",{"name":"getString","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":67}}}))
    + "\n                  </label>\n                  <span class=\"help-block\" data-bind=\"model\" data-validate=\"pay-expdata\"></span>\n              </div>\n          </div>\n      </div>\n  </form>\n</div>";
},"useData":true});