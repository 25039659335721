import ItemView from '@glu/core/src/itemView';
import Formatter from 'system/utilities/format';
import paymentAmountTmpl from './paymentAmount.hbs';

export default ItemView.extend({
    className: 'payment-amount-cell form-group has-validator',
    template: paymentAmountTmpl,

    events: {
        'change @ui.$input': 'isValid',
        'keyup @ui.$input': 'isValid',
    },

    ui: {
        $input: 'input',
    },

    isValid() {
        const amountDue = +this.model.get('AMOUNT_DUE');
        const inputAmount = +this.ui.$input.val();
        const isValid = !(inputAmount <= 0 || inputAmount > amountDue);

        this.$el.toggleClass('has-error', !isValid);

        return isValid;
    },

    templateHelpers() {
        return {
            id: this.model.cid,
        };
    },

    onRender() {
        const options = Formatter.getCurrencyMaskOptions();
        options.rightAlign = true;
        this.ui.$input.inputmask('decimal', options);
        this.ui.$input.val(this.model.get('AMOUNT_DUE'));
        this.$el.data('view', this);
    },
});
