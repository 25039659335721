import dialog from '@glu/dialog';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import { TransferNextIcon } from '@glu/icons-react';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import gridApi from 'common/dynamicPages/api/grid';
import ListView from 'common/dynamicPages/views/workflow/list';
import entitlements from 'common/dynamicPages/api/entitlements';
import AddTemplateModalView from 'app/payments/views/addTemplate';
import actionFunction from 'app/payments/api/common';
import workspaceHelper from 'common/workspaces/api/helper';
import ExportPaymentView from 'app/payments/views/exportPayments';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import systemConfig from 'system/configuration';
import ExportDetail from 'app/payments/views/exportDetail';
import services from 'services';
import TotalsModel from 'common/dataCard/models/paymentSummary';
import TotalsView from 'common/dataCard/views/dataCardCollectionView';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import helpers from 'components/AsyncReporting/helpers';
import MaskToggleUtil from 'common/util/maskToggleUtil';
import template from './listTemplates.hbs';

const TemplateManagementList = ListView.extend({
    className: 'payment-management-list',

    regions: {
        totalsSection: '[data-hook="template-totals-region"]',
    },

    template,

    ui: {
        $toggleTotals: '.toggleTotals',
        $totalsRegion: '[data-hook="template-totals-region"]',
    },

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-action="insert-button"]': 'insert',
            'click [data-hook="print-button"]': 'showPrintOptionsModal',
            'click [data-action="manageTemplateGroups-button"]': 'manageTemplateGroups',
            'click [data-action="fileimport-button"]': 'fileimport',
            'click [data-hook="toggle-totals-button"]': 'toggleTotals',
        },
    ),

    initialize(options) {
        this.isAdmin = systemConfig.isAdmin();
        const superOptions = {
            menuCategory: 'PMTS',
            serviceName: 'templates/corp',
            serviceFunc: null,
            businessType: null,
            context: 'TEMP_LIST_VIEW',
            returnRoute: 'PAYMENTS/listTemplates',
            sortKey: 'tnum',
            sortOrder: 'desc',
            viewId: options.viewId,
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
        this.nachaExportListViewPath = '/paymentExport/listView/corp/exportListView';
        this.exportListViewPath = this.isAdmin
            ? '/paymentExport/listView/corp/exportListView' : services.runAsyncListViewReport;
        this.shouldShowTotals = serverConfigParams.get('ShowPaymentListViewTotals') === 'true';
        ({ shouldShowMaskToggle: this.shouldShowMaskToggle, maskToggle: this.maskToggle }
            = MaskToggleUtil.initMaskToggle());
    },

    loadViewRequirements() {
        const options = {
            ...this.viewOptionRequirements(),
            gridOptions: { caption: this?.contextDef?.name },
        };
        const gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);
        const optionsFimport = util.clone(options); // for file import
        optionsFimport.entryMethod = 3;
        const entitlementFimportPromise = entitlements.getEntitlements(optionsFimport);
        const optionsTemplateGroups = util.clone(options);

        this.gridView = gridView;


        // for template groups
        optionsTemplateGroups.productCode = 'PAY';
        optionsTemplateGroups.functionCode = 'TMPLSET';
        optionsTemplateGroups.typeCode = 'TMPLSET';
        optionsTemplateGroups.entryMethod = '0';
        const entitlementTemplateGroupPromise = entitlements.getEntitlements(optionsTemplateGroups);

        ListView.prototype.setupGridAvailableListener.call(this);

        // merge both promises (regular templates and the other one for file import)
        return Promise.all([
            entitlementPromise,
            entitlementFimportPromise,
            entitlementTemplateGroupPromise,
        ]).then((results) => {
            const entResActions = results[0].actions;
            if (results[1].actions && results[1].actions.INSERT) {
                // IMPORT is a convenience action for the UI side
                entResActions.IMPORT = true;
            }
            /*
             * If there is SELECT entitlements for the template groups then the button
             * should be displayed.
             */
            if (results[2].actions && results[2].actions.SELECT) {
                // TEMPLATEGROUP is a convenience action for the UI side
                entResActions.TEMPLATEGROUP = true;
            }
            this.entitlements = entResActions;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.setHasLoadedRequiredData(true);
                this.listenTo(this.gridView.getRows(), 'sync', this.updateFromRender);
                this.listenForGridErrors();
                this.render();
            }
            return entResActions;
        });
    },

    updateFromRender() {
        // Do not fetch if ShowPaymentListViewTotals config param is false and totalsModel is empty
        if (this.shouldShowTotals && !this.isAdmin && this.totalsModel) {
            this.updateSubTotals();
        }
        // update refresh timestamp
        this.updateRefreshTimestamp();
    },

    updateSubTotals() {
        this.totalsModel.set('filters', this.gridView.wrapper.generateFiltersDataBlock());
        this.totalsModel.set('viewId', this.gridView.wrapper.viewId);
        this.totalsModel.fetch();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.shouldShowMaskToggle) {
                if (this.shouldShowMaskToggle) {
                    MaskToggleUtil.showMaskToggle(this, {
                        name: 'maskToggleTemplates',
                        htmlId: 'maskToggleTemplates',
                        labelText: locale.get('common.maskBeneAcctNum'),
                        onChange: this.toggleMaskColumns.bind(this),
                        region: this.toggleSwitchOuterRegion,
                    });
                }
            }
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
        } else {
            this.loadViewRequirements();
        }
    },

    initializeTotals() {
        if (this.totalsModel && this.totalsView) {
            this.totalsModel.set('filters', this.gridView.wrapper.generateFiltersDataBlock());
            this.totalsSection.show(this.totalsView);
            this.listenTo(this.totalsModel, 'change', function () {
                const paymentTotalsData = this.totalsModel.get('paymentTotalsByPaymentGroupList');
                this.totalsView.isLoading = true;
                if (paymentTotalsData) {
                    this.totalsCollection.reset(paymentTotalsData);
                    this.totalsView.render();
                }
            });
            return;
        }
        if (!this.totalsModel) {
            this.totalsModel = new TotalsModel({
                filters: this.gridView.wrapper.generateFiltersDataBlock(),
            }, {
                service: services.generateUrl('payment/getPaymentTemplatesSummary'),
                order: ['ACH', 'LOANS', 'WIRES', 'BILLPAY', 'RTP'],

                payGroupConfig: {
                    ALL: {
                        showZeroValueAccounts: true,
                    },
                },
            });
            /*
             * TODO: 30315 - Once the API is updated to handle in Admin,
             * the check for systemConfig.isAdmin() can be removed.
             */
            if (this.shouldShowTotals && !this.isAdmin) {
                this.totalsCollection = new Collection();
                this.totalsView = new TotalsView({
                    collection: this.totalsCollection,
                    isLoading: true,
                });
                this.totalsSection.show(this.totalsView);
                this.listenTo(this.totalsModel, 'change', function () {
                    const paymentTotalsData = this.totalsModel.get('paymentTotalsByPaymentGroupList');
                    this.totalsView.isLoading = true;
                    if (paymentTotalsData) {
                        this.totalsCollection.reset(paymentTotalsData);
                    }
                });
            }
        }
    },

    toggleTotals() {
        const isVisible = this.ui.$totalsRegion.height() > 0;
        if (isVisible) {
            this.ui.$totalsRegion.css({
                height: '0',
                visibility: 'hidden',
            });
            this.ui.$toggleTotals.text(locale.get('common.show.totals'));
        } else {
            // Show totals data only when user clicks on "show totals" button for the first time
            if (!this.totalsModel) {
                this.initializeTotals();
                this.updateFromRender();
            }
            this.ui.$totalsRegion.css({
                height: 'auto',
                visibility: 'visible',
            });
            this.ui.$toggleTotals.text(locale.get('common.hide.totals'));
        }
    },

    insert() {
        this.addTemplateView = new AddTemplateModalView({
            isModal: true,
        });
        this.listenTo(this.addTemplateView, 'selected', this.addTemplateSelected);
        dialog.open(this.addTemplateView);
        return Promise.resolve();
    },

    addTemplateSelected(model) {
        if (model.get('type').indexOf('LOANPAY') > -1 || model.get('type').indexOf('LOANDRAW') > -1) {
            store.set('basic_template_data', model.toJSON());
            this.navigateTo('TEMPLATE/addTemplate');
        } else {
            const overrideContext = util.extend(this.contextDef, model.get('context'));
            this.insertOverride(overrideContext);
        }
    },

    gridPaymentFromTemplate(optionsParam) {
        const options = optionsParam;
        const { typeCode } = options.model.context.actionData;

        if (typeCode.indexOf('LOANPAY') > -1 || typeCode.indexOf('LOANDRAW') > -1) {
            options.model.action = options.action;
            options.model.loadFromPayments = false;
            store.set('basic_payment_data', options.model);
            this.navigateTo('PAYMENTS/addPayment');
        } else {
            actionFunction.makePayment(options.model, this.contextKey);
            this.navigateTo('PAYMENTS/addPaymentFromTemplate');
        }
        return Promise.resolve();
    },

    gridTemplateFromPayment(optionsParam) {
        const options = optionsParam;
        const { typeCode } = options.model.context.actionData;

        if (typeCode.indexOf('LOANPAY') > -1 || typeCode.indexOf('LOANDRAW') > -1) {
            options.model.action = options.action;
            options.model.loadFromPayments = false;
            options.model.set('FROMTEMPLATE', '1');
            options.model.set('TARGETFUNCTION', this.contextDef.functionCode);
            store.set('basic_template_data', options.model);
            this.navigateTo('TEMPLATE/addTemplate');
        } else {
            actionFunction.makeTemplate(options.model, this.contextKey);
            this.navigateTo('TEMPLATES/addTemplateFromPayment');
        }
        return Promise.resolve();
    },

    gridRowSelect(options) {
        const serviceName = options.model.context.serviceName.toLowerCase();

        if (serviceName.indexOf('loandraw') > -1 || serviceName.indexOf('loanpayment') > -1) {
            store.set('custom_view_template_model', options.model);
            this.navigateTo('TEMPLATE/viewLoanTemplate');
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('TEMPLATES/viewTemplate');
        }
        return Promise.resolve();
    },

    gridRowModify(options) {
        const serviceName = options.model.context.serviceName.toLowerCase();

        if (serviceName.indexOf('loandraw') > -1 || serviceName.indexOf('loanpayment') > -1) {
            store.set('custom_modify_template_model', options.model);
            this.navigateTo('TEMPLATE/modifyLoanTemplate');
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('TEMPLATES/modifyTemplate');
        }
        return Promise.resolve();
    },

    gridRowModifyvFImport(options) {
        const importContext = {
            serviceName: '/modifyvfimport/import',
            actionMode: 'IMPORT',
            batchTnum: options.model.get('TNUM'),
            productCode: 'USACH',
            functionCode: 'IMPORT',
            importType: options.model.context.actionData.typeCode,
            typeCode: options.model.context.actionData.typeCode,
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        overrideContext.actionContext.typeCode = options.model.context.actionData.typeCode;
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('IMPORT/modifyvFImport');
        return Promise.resolve();
    },

    gridRowRepair(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('TEMPLATES/repairTemplate');
        return Promise.resolve();
    },

    gridRowSchedule(optionsParam) {
        const options = optionsParam;
        if (options.model.get('CONINTLWIREFLAG') === '1') {
            dialog.error(locale.get('common.schedule.conintl.error'), 'Error');
            options.model.context.serviceName = '/payment/Wire-International';
            options.model.context.actionData.typeCode = 'INTL';
            options.model.context.actionData.functionCode = 'TMPL';
            options.model.set({
                FUNCTION: 'TMPL',
                TYPE: 'INTL',
            });
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('TEMPLATES/schedulePayment');
        }
        return Promise.resolve();
    },

    gridRowQEntry(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('PAYMENTS/quickEntry');
        return Promise.resolve();
    },

    /**
     * @method gridRowRestore
     * @param {object} optionsParam -  data from grid row
     * - function to navigate to restore template screen on select of Restore
     * action from context menu
     */
    gridRowRestore(optionsParam) {
        const options = optionsParam;
        const serviceName = options.model.context.serviceName.toLowerCase();

        if (serviceName.indexOf('loandraw') > -1 || serviceName.indexOf('loanpayment') > -1) {
            options.model.overloadedMethodName = 'restore';
            store.set('custom_modify_template_model', options.model);
            this.navigateTo('TEMPLATE/restoreLoanTemplate');
        } else {
            store.set(`${this.contextKey}-actionModel`, options.model);
            this.navigateTo('TEMPLATES/restoreTemplate');
        }
        return Promise.resolve();
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                return locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
            },

            context() {
                return self.contextKey;
            },

            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT);
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasFileImportEntitlement() {
                return self.hasEntitlement(constants.ACTION_IMPORT);
            },

            hasRejectEntitlement() {
                return self.hasEntitlement(constants.ACTION_REJECT);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasTemplateGroupEntitlement() {
                return self.hasEntitlement(constants.ACTION_TEMPLATEGROUP);
            },

            hasBulkActionEntitlements() {
                // return true if the user is entitled to perform bulk actions on grid rows
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || self.hasEntitlement(constants.ACTION_REJECT)
                    || self.hasEntitlement(constants.ACTION_DELETE);
            },

            showTotals() {
                const shouldShow = self.shouldShowTotals;
                return (shouldShow && !this.isAdmin);
            },
        };
    },

    fileimport() {
        const importContext = {
            serviceName: '/fimport/import',
            actionMode: 'IMPORT',
        };
        const overrideContext = util.extend(this.contextDef, importContext);
        store.set(`${this.contextKey}-contextOverride`, overrideContext);
        this.navigateTo(this.contextModel.getImportUrl());
    },

    manageTemplateGroups() {
        this.navigateTo('/PAYMENTS/manageTemplateGroups');
    },

    export() {
        // customised in listTemplatesView
        const exportModel = {};
        this.prepareExportScreenInfo(exportModel);
        const rows = this.gridView.grid.getSelectedRowModels();
        exportModel.enableSel = (rows.length > 0);

        /*
         * if nothing is selected or all selected ones are not nacha or everything in
         * the grid are nacha
         */
        exportModel.enableAll = (rows.length === 0)
            || !exportModel.showDataFormatForSel
            || exportModel.showDataFormatForAll;
        exportModel.selected = rows;
        this.exportPaymentsView = new ExportPaymentView(exportModel);
        this.listenTo(this.exportPaymentsView, 'exportPayments', this.exportPay);
        dialog.open(this.exportPaymentsView);
    },

    prepareExportScreenInfo(exportModelParam) {
        const exportModel = exportModelParam;
        let allNachaRowsFound = true;
        let allSelectedNachaRowsFound = true;
        const allRowModels = this.gridView.wrapper.rows.models;
        const selectedRowModels = this.gridView.grid.getSelectedRowModels();
        const isTemplate = true;

        util.each(selectedRowModels, (model) => {
            if (model.get('SUBTYPE') !== 'NACHA') {
                allSelectedNachaRowsFound = false;
            }
        });
        exportModel.showDataFormatForSel = allSelectedNachaRowsFound;

        util.each(allRowModels, (model) => {
            if (model.get('SUBTYPE') !== 'NACHA') {
                allNachaRowsFound = false;
            }
        });
        exportModel.showDataFormatForAll = allNachaRowsFound;

        exportModel.isTemplate = isTemplate;
        exportModel.functioncode = ' Payment Templates';
    },

    exportPay(model) {
        this.buildExportModel(model);
        this.doExport();
    },

    buildExportModel(model) {
        this.exportModel = {};
        this.exportModel.outputFormat = model.get('fileformat');
        // this.exportModel.outputFormat = 'PDF';
        this.exportModel.pageType = 'LETTER';
        this.exportModel.numberOfRows = 0;
        this.exportModel.expData = model.get('expdata');
        this.exportModel.viewId = this.gridView.wrapper.viewId;
        this.exportModel.actionData = {};
        this.exportModel.actionData.productCode = this.gridView.context.productCode;
        this.exportModel.actionData.functionCode = this.gridView.context.functionCode;
        this.exportModel.actionData.typeCode = this.gridView.context.typeCode;

        // Check for Sort by Column
        if (this.gridView.wrapper.sortKey !== null) {
            const sortField = {};
            sortField.fieldName = this.gridView.wrapper.sortKey;
            sortField.sortOrder = this.gridView.wrapper.sortOrder;
            this.exportModel.sortFields = [];
            this.exportModel.sortFields.push(sortField);
        }
        const searchFields = [];
        let selectedTnums = [];
        // Check for Filtering
        const { filters } = this.gridView.wrapper;
        if (model.get('selection') && model.get('selection') !== 'all') {
            const rows = this.gridView.grid.getSelectedRows();
            if (rows.length > 0) {
                selectedTnums = rows.map((r) => {
                    const paymodel = this.gridView.wrapper.rows.get(r);
                    return paymodel.get('TNUM');
                });
            }
            let searchFieldName = 'TNUM';
            if (this.exportModel.expData === 'transaction') {
                searchFieldName = 'BATCH_TNUM';
            }
            searchFields[searchFields.length] = {
                fieldName: searchFieldName,
                operator: 'IN',
                fieldValue: selectedTnums,
                dataType: 'number',
            };
        }

        if (filters && filters.length > 0 && filters.models && filters.models.length > 0) {
            for (let i = 0; i < filters.models.length; i += 1) {
                const filterType = filters.models[i].get('type');
                const filterValues = filters.models[i].get('value');
                const filterName = filters.models[i].get('field');

                switch (filterType) {
                case 'string':
                case 'typeahead':
                    searchFields[searchFields.length] = {
                        fieldName: filterName,
                        operator: 'CONTAINS',
                        fieldValue: [filterValues],
                        dataType: 'text',
                    };
                    break;
                case 'date':
                case 'amount':
                case 'number':
                    searchFields[searchFields.length] = {
                        fieldName: filterName,
                        operator: filters.models[i].get('equality') || 'EQ',
                        fieldValue: util.isArray(filterValues) ? filterValues : [filterValues],
                        dataType: filterType,
                    }; break;
                case 'enum':
                    searchFields[searchFields.length] = {
                        fieldName: filterName,
                        operator: 'EQ',
                        fieldValue: [filterValues],
                        dataType: 'text',
                    };
                    break;
                default:
                    break;
                }
            }
        }

        if (searchFields.length > 0) {
            this.exportModel.searchFields = searchFields;
        }

        if (this.exportModel.expData === 'transaction') {
            // columns are read from db meta data
            this.exportModel.inquiryId = '20018';
        } else {
            /*
             * Get Columns and Order
             * TODO refactor
             */
            const columns = [];
            this.gridView.wrapper.columns.each((m) => {
                if (m.get('condition') !== false) {
                    columns.push(m.attributes.field);
                }
            });
            this.exportModel.columns = columns;
        }
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_20018,
        };
    },

    doExport() {
        if (this.exportModel.outputFormat === 'NACHA') {
            const url = services
                .generateUrl(this.nachaExportListViewPath || this.exportListViewPath);
            /*
             * if the NACHA export file format is selected, then this export will be
             * queued and available
             * at a later time via the Downloads section
             */

            const options = {
                success: () => {
                    this.alertView = alert.success(
                        locale.get('payment.Export'),
                        {
                            details: new ExportDetail(),
                        },
                    );

                    this.alertRegion.show(this.alertView);
                },

                error: () => {
                    this.alertView = alert.negative(
                        locale.get('payment.export.error.message'),
                        {
                            title: locale.get('payment.export.error.title'),
                        },
                    );

                    this.alertRegion.show(this.alertView);
                },
            };

            const jsonData = this.exportModel;

            http.post(url, jsonData, (result) => {
                options.success(result);
            }, (result) => {
                // when notifications are in place this service will be include some response
                options.success(result);
            });
        } else {
            /*
             * if the CSV export file format is selected, then this export
             * will be available immediately as an automatic browser file download
             */

            if (!this.isAdmin) {
                helpers.handleExportAsyncReport({
                    service: this.exportListViewPath || services.runAsyncListViewReport,
                    postData: this.exportModel,
                }, dialog);
                return;
            }

            let mode = 'get';
            if (this.exportListViewPath !== '/export/listView') {
                mode = 'post';
            }

            const url = services.generateUrl(this.exportListViewPath);

            if (this.$('#listViewExportForm').length === 0) {
                const f = `<form id="listViewExportForm" action="${url}" method="${mode}" target="_self"><input id="listViewRequest" type="hidden" name="listViewRequest" ></form>`;
                this.$el.append(f);
            }

            this.$('#listViewExportForm #listViewRequest').val(JSON.stringify(this.exportModel));
            this.$('#listViewExportForm').submit();
        }
    },
});

let list = TemplateManagementList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'payment.template.add',
                entitlement: constants.ACTION_INSERT,
                handlerMethodName: 'insert',
            },
            {
                label: 'payment.templategroups.manage',
                entitlement: constants.ACTION_TEMPLATEGROUP,
                handlerMethodName: 'manageTemplateGroups',
            },
        ],
        bulkActions: [
            {
                label: 'button.approve',
                entitlement: constants.ACTION_APPROVE,
            },
            {
                label: 'button.unapprove',
                entitlement: constants.ACTION_UNAPPROVE,
            },
            {
                label: 'button.reject',
                entitlement: constants.ACTION_REJECT,
            },
            {
                label: 'button.delete',
                entitlement: constants.ACTION_DELETE,
            },
        ],
        additionalActions: [
            MaskToggleUtil.getMobileGridActions(),
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'TEMPLATE_LIST',
    view: list,
    options: {},
    useMobileCard: true,
    mobileCardProps: {
        icon: TransferNextIcon,
    },
});

const exportedList = list;

export default exportedList;
