import validators from 'system/validatorPatterns';

export default function (form) {
    const phone = form.field('PHONENUMBER');
    const fax = form.field('FAXNUMBER');
    const email = form.field('EMAILADDRESS');
    const contactEmail = form.field('BENENOTIFICATIONCONTACTEMAIL');
    const contactPhone = form.field('BENENOTIFICATIONCONTACTNUMBER');
    const { model } = form.formView;
    const primaryContactSect = form.formView.$el.find('._ADMIN_MAINT_MPREF_TM_CONTAINER1_GROUP3');
    const textNote = form.formView.$el.find('._ADMIN_MAINT_MPREF_TM_CONTAINER1_GROUP3 div').first().find('.textline-field');

    /*
     * The textNote is added as INFO column of a dummy field; need to remove the css Class
     * so it looks as desired
     */
    if (textNote.length > 0) {
        textNote.removeClass('textline-field');
    }
    if (model.get('ALLOWBENENOTIFICATIONSWIRES') !== '1') {
        primaryContactSect.addClass('hide');
    }
    // Matches (658)154-1122 | 6581541122 | 658-154-1122
    phone.shouldMatchPattern(new RegExp(validators.PHONE_PATTERN));
    fax.shouldMatchPattern(new RegExp(validators.PHONE_PATTERN));
    email.shouldMatchPattern(new RegExp(validators.EMAIL_PATTERN));
    contactEmail.shouldMatchPattern(new RegExp(validators.EMAIL_PATTERN));
    contactPhone.shouldMatchPattern(new RegExp(validators.PHONE_PATTERN));
}
