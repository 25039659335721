import React from 'react';
import locale from '@glu/locale';
import store from 'pcm/common/store';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import ContentLoading from 'pcm/common/components/ContentLoading';
import { checkEntitlements } from './achEsendManagementUtils';

const ACHEsendManagement = React.lazy(() => import('./ACHEsendManagement'));
const AddACHProfile = React.lazy(() => import('./achProfile/AddACHProfile'));
const ViewACHProfile = React.lazy(() => import('./achProfile/ViewACHProfile'));
const EditACHProfile = React.lazy(() => import('./achProfile/EditACHProfile'));
const AddACHApplication = React.lazy(() => import('./achApplications/AddACHApplication'));
const ApplicationSteps = React.lazy(() => import('./achApplications/ApplicationSteps'));
const ViewACHApplication = React.lazy(() => import('./achApplications/ViewACHApplication'));
const AddCheckDivision = React.lazy(() => import('./checkDivisions/AddCheckDivision'));
const ViewCheckDivision = React.lazy(() => import('./checkDivisions/ViewCheckDivision'));
const EditCheckDivision = React.lazy(() => import('./checkDivisions/EditCheckDivision'));
const ACHApplicationsList = React.lazy(() => import('./achApplications/ACHApplicationsList'));
const ACHProfileList = React.lazy(() => import('./achProfile/ACHProfileList'));
const CheckDivisionsList = React.lazy(() => import('./checkDivisions/CheckDivisionsList'));

const getActionToTitleOptions = () => ({
    profile: {
        title: {
            add: locale.get('achEsendManagement.newACHProfile'),
            edit: locale.get('achEsendManagement.modifyACHProfile'),
            view: locale.get('achEsendManagement.viewACHProfile'),
        },
        backLink: '/ui/ADMINSTRATION/ach-esend-management/profiles',
    },
    division: {
        title: {
            add: locale.get('achEsendManagement.newCheckDivision'),
            edit: locale.get('achEsendManagement.modifyCheckDivision'),
            view: locale.get('achEsendManagement.viewCheckDivision'),
        },
        backLink: '/ui/ADMINSTRATION/ach-esend-management/divisions',
    },
    application: {
        title: {
            add: locale.get('achEsendManagement.newACHApplication'),
            edit: locale.get('achEsendManagement.modifyACHApplication'),
            view: locale.get('achEsendManagement.viewACHApplication'),
            selectAchProfiles: locale.get('achEsendManagement.selectACHProfiles.pageTitle'),
        },
        backLink: '/ui/ADMINSTRATION/ach-esend-management/applications',
    },
});

export default {
    achEsendManagement(activeTab) {
        const tabsComponents = {
            applications: ACHApplicationsList,
            profiles: ACHProfileList,
            divisions: CheckDivisionsList,
        };

        this.showPage(
            locale.get('menuitem.ach.esend.management'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={checkEntitlements}>
                    <ACHEsendManagement tabsComponents={tabsComponents} activeTab={activeTab || 'applications'} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('menuitem.ach.esend.management'),
            },
        );
    },

    achEsendManagementActions(activeTab, action) {
        const components = {
            profile: {
                add: AddACHProfile,
                view: ViewACHProfile,
                edit: EditACHProfile,
            },
            application: {
                add: AddACHApplication,
                edit: ApplicationSteps,
                view: ViewACHApplication,
                selectAchProfiles: ApplicationSteps,
            },
            division: {
                add: AddCheckDivision,
                view: ViewCheckDivision,
                edit: EditCheckDivision,
            },
        };

        store.activeTab = activeTab;
        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();

        const ComponentToShow = components[activeTab][action];

        this.showPage(
            actionToTitleOptions[activeTab].title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={checkEntitlements}>
                    <ComponentToShow action={action} tab={activeTab} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions[activeTab].title[action],
                backLink: actionToTitleOptions[activeTab].backLink,
            },
        );
    },
};
