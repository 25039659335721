import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import { CheckboxRadio } from '@glu/form-components';
import useLocale from '../../hooks/useLocale';
import TextArea from '../RtpCharCountTextArea/RtpCharCountTextArea';
import conversationConstants from '../RtpDrawerContainer/RtpConversationConstants';
import styles from './RtpPairedResponseMessage.styles';
import RtpContext from '../RtpDrawerContainer/RtpContext';

const propTypes = {
    data: PropTypes.shape({
        CONVITEM_ID: PropTypes.string,
        ITEM_TYPE: PropTypes.string,
        PAYMENT_ID: PropTypes.string,
        SEQUENCE_NUM: PropTypes.string,
    }).isRequired,
    updateResponse: PropTypes.func.isRequired,
    errorIndicator: PropTypes.bool,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    errorIndicator: false,
};

const RtpPairedResponseMessage = ({
    data, updateResponse, errorIndicator, classes,
}) => {
    const state = useContext(RtpContext); // get state from RtpContext
    const [disabled, setDisabled] = useState({});

    // set up message and checkbox values
    const theResponse = state.responses.find(r => r.seqno === data.SEQUENCE_NUM);

    useEffect(() => {
        if (theResponse.noresponse === false) {
            setDisabled({});
        }
    }, [theResponse.noresponse]);

    const updateResponseMessage = (msg) => {
        updateResponse({
            message: msg, internalMessage: msg, seqno: data.SEQUENCE_NUM, noresponse: false,
        });
    };
    const noResponseMessage = (name, checked) => {
        if (checked) {
            updateResponse({
                message: '', seqno: data.SEQUENCE_NUM, noresponse: true, internalMessage: 'NO_RESPONSE',
            });
        } else {
            updateResponse({
                seqno: data.SEQUENCE_NUM,
                noresponse: false,
                message: theResponse.message,
                internalMessage: theResponse.internalMessage,
            });
        }
        // disable textarea
        setDisabled(checked ? { disabled: 'disabled ' } : {});
    };
    const { getLocaleString } = useLocale();

    const className = `conversationItem ${classes.responseContainer}`;
    return (
        <div className={className}>
            <TextArea
                name="RESPONSE"
                placeHolderText={getLocaleString('rtp.placeholderText.enterRequiredResponse')}
                rows={conversationConstants.MESSAGE_ROWS}
                maxLength={conversationConstants.MAX_MESSAGE_LENGTH_SUBMIT}
                message={theResponse.message}
                internalMessage={theResponse.internalMessage}
                setMessage={updateResponseMessage}
                errorIndicator={errorIndicator}
                disabled={disabled}
            />
            <div className={classes.noResponse}>
                <CheckboxRadio
                    type="checkbox"
                    name="noResponse"
                    onChange={noResponseMessage}
                    htmlId="noResponse"
                    labelText={getLocaleString('rtp.noResponseLabel')}
                    labelClass={classes.noResponseLabel}
                    value="NO_RESPONSE"
                    checked={theResponse.noresponse}
                    dark
                />
            </div>
        </div>
    );
};

RtpPairedResponseMessage.propTypes = propTypes;
RtpPairedResponseMessage.defaultProps = defaultProps;

export default withStyles(styles)(RtpPairedResponseMessage);
