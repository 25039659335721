import $ from 'jquery';
import store from 'system/utilities/cache';
import http from 'system/gluOverride/http';
import alert from '@glu/alerts';
import dialog from '@glu/dialog';
import ModalView from 'no-override!@glu/dialog/src/views/modalView';
import locale from '@glu/locale';
import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import services from 'services';
import workspaceHelper from 'common/workspaces/api/helper';
import loadingTemplate from 'common/templates/loadingModal.hbs';
import PrintersCollection from '../collections/printersCollection';
import template from './printChecks.hbs';

const PrintModel = Model.extend({});

export default Layout.extend({
    template,
    loadingTemplate,
    modalClass: 'modal-lg print-checks-modal',

    ui: {
        $printerSelection: '[data-hook="printer-select"]',
        $printersGroup: '.printers-group',
    },

    onClose() {
        this.$('select, input[type="hidden"]').comboBox('destroy');
    },

    events: {
        'click #print': 'printBatch',
    },

    initialize() {
        this.isReprintBatch = this.options.isReprintBatch;
        this.isReprintCheck = this.options.isReprintCheck;
        this.paymentListModel = this.options.paymentListModel;
        this.model = new PrintModel();
        this.isModal = this.options.isModal;
        this.printBatchUrl = '/ptxConnect/printBatch';
        this.reprintBatchUrl = '/ptxConnect/reprintBatch';
        this.reprintCheckUrl = '/ptxConnect/reprintCheck';
        this.printBatchErrorMessage = locale.get('clm.sendToPrinterPrintError');
        this.printBatchSuccessMessage = locale.get('clm.sendToPrinterPrintBatchSuccess');
        // PCM-3455
        this.printBatchElgibleErrorMessage = locale.get('clm.printchecks.eligible.info');
        this.reprintBatchElgibleErrorMessage = locale.get('clm.reprintchecks.eligible.info');

        this.reprintErrorMessage = locale.get('clm.sendToPrinterReprintError');
        this.reprintBatchSuccessMessage = locale.get('clm.sendToPrinterReprintBatchSuccess');
        this.reprintCheckSuccessMessage = locale.get('clm.checkReprintGenericSuccess');
        if (this.isReprintBatch) {
            this.callback = 'reprintBatch';
            this.dialogTitle = locale.get('clm.reprintChecks');
            this.buttonText = locale.get('button.continue');
        } else if (this.isReprintCheck) {
            this.callback = 'reprintCheck';
            this.dialogTitle = locale.get('clm.reprintCheck');
            this.buttonText = locale.get('button.continue');
        } else {
            this.callback = 'printBatch';
            this.dialogTitle = locale.get('clm.printChecks');
            this.buttonText = locale.get('clm.printChecksButton');
        }

        this.dialogButtons = [{
            text: this.buttonText,
            className: 'btn btn-primary',
            callback: this.callback,
        }, {
            text: locale.get('payment.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.model.set('selection', 'selectedPrinter');
            this.ui.$printersGroup.show();
            this.renderPrinters();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;
        const printersPromise = this.printersPromise();
        Promise.all([printersPromise]).then((results) => {
            [self.printersCollection] = results;
            self.setHasLoadedRequiredData(true);
            self.render();
        }, () => {
            self.errorResult({
                ...self,
                isPtxError: true,
            });
        });
    },

    printersPromise() {
        return new Promise((resolve, reject) => {
            const printersCollection = new PrintersCollection();
            printersCollection.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(type);
            },

            printersCollection() {
                return self.printersCollection.toJSON();
            },
            title: this.dialogTitle,
        };
    },

    cancel() {
        dialog.close();
    },

    renderPrinters() {
        const self = this;
        this.ui.$printerSelection.comboBox({
            matcher(term, text) {
                // first check and see if there are exact matches
                let exactMatch = false;
                self.printersCollection.each((printer) => {
                    if (printer.get('label').toUpperCase() === term.toUpperCase()) {
                        exactMatch = true;
                    }
                });
                if (exactMatch && term.toUpperCase() === text.toUpperCase()) {
                    return true;
                }
                return !exactMatch && (text.toUpperCase().indexOf(term.toUpperCase()) > -1);
            },
        });

        this.ui.$printerSelection.on('change', (e) => {
            if (e.val) {
                this.model.set('selectedPrinter', self.printersCollection.get(e.val));
            }
        });
    },

    giveSuccessMsgForSingleCheckReprint(checkModel) {
        const msg =
            `${locale.get('clm.checkReprintSuccessPrefix')} 
                ${checkModel.get('CHECKNUMBER')} 
                ${locale.get('payment.summary.to')} 
                ${checkModel.get('PAYEENAME_1')} 
                ${locale.get('clm.checkReprintSuccessSuffix')}`;
        return msg;
    },

    constructMessageObject(resultMessage, confirmResults) {
        return {
            message: resultMessage,
            confirms: confirmResults,
        };
    },

    successResult(self, successMessage) {
        const confirms = {
            totalSuccess: 1,
            totalFail: 0,
            confirmResults: [{ result: true, messages: [] }],
        };
        const successMessageObject = this.constructMessageObject(
            successMessage,
            confirms,
        );
        if (!self.options.isActionTriggeredFromPaymentsList && !self.isReprintCheck) {
            store.set('payment_listView_corp-alertMessage', successMessage);
            store.set('payment_listView_corp-confirms', successMessageObject);
        }
        dialog.close();
        if (!self.isReprintCheck && !self.options.isActionTriggeredFromPaymentsList) {
            workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
        }

        if (self.isReprintCheck) {
            const alertView = alert.success(successMessage);
            self.paymentListModel.alertRegion.show(alertView);
        }

        if (self.options.isActionTriggeredFromPaymentsList && !self.isReprintCheck) {
            self.paymentListModel.gridView.refreshGridData();
            self.paymentListModel.renderMessage('printbatch', successMessageObject);
        }
    },

    // PCM-3455
    errorResultOnIncorrectCheckstatusInsideBatch(response) {
        if (response.item[0].value === 'incorrectCheckStatus') {
            dialog.close();
            const infoPopup = new ModalView({
                type: 'info',
                icon: 'exclamation-solid',
                message: this.isReprintBatch ?
                    this.reprintBatchElgibleErrorMessage : this.printBatchElgibleErrorMessage,
                title: this.isReprintBatch ?
                    locale.get('clm.reprintChecks') : locale.get('clm.printChecks'),
                buttons: [{
                    text: locale.get('button.continue'),
                    className: 'btn-primary',
                    callback() { this.close(); },
                }],
            });
            dialog.custom(infoPopup);
        }
    },

    errorResult(self) {
        const errorMessage = self.isReprintCheck || self.isReprintBatch ?
            self.reprintErrorMessage : this.printBatchErrorMessage;
        if (self.isReprintCheck) {
            dialog.close();
            const errorPopup = new ModalView({
                type: 'warning',
                icon: 'exclamation-solid',
                message: errorMessage,
                title: locale.get('clm.checkReprintErrorTitle'),
                buttons: [{
                    text: locale.get('common.close'),
                    className: 'btn-primary',
                    callback() { this.close(); },
                }],
            });
            dialog.custom(errorPopup);
        } else if (self.isPtxError) {
            dialog.close();

            const div = `<div class="row">
                <span class="sda-warning icon-exclamation-solid left"></span>
                <span class="col-10">
                        ${locale.get(
        'ptx.error.generic',
        locale.get('ptx.error.prefixPrintCheck'),
    )}</span>
            </div>`;


            const popup = new ModalView({
                type: 'alert',
                modalClass: 'modal-lg',
                buttons: [{
                    text: locale.get('ok'),
                    className: 'btn-primary',
                    callback() {
                        this.close();
                    },
                }],
            });
            dialog.custom(popup);
            popup.$el.find(popup.ui.body).append(div);
        } else if (self.options.isActionTriggeredFromPaymentsList) {
            dialog.close();
            const confirms = {
                totalSuccess: 0,
                totalFail: 1,
                confirmResults: [{ result: true, messages: [] }],
            };
            const errorMessageObject = this.constructMessageObject(
                errorMessage,
                confirms,
            );
            self.paymentListModel.renderMessage('printbatch', errorMessageObject);
        } else {
            store.set('payment_listView_corp-alertMessage', errorMessage);
            store.set('payment_listView_corp-confirms', errorMessage);
            dialog.close();
            workspaceHelper.returnToCurrentWorkspaceOnResponse(self);
        }
    },

    convertArrayIntoNameValuePairItem(array, nameOfValue) {
        const itemsCollection = {};
        const item = [];
        for (let index = 0; index < array.length; index += 1) {
            const nameValuePair = {};
            nameValuePair.name = nameOfValue;
            nameValuePair.value = array[index];
            item.push(nameValuePair);
        }
        itemsCollection.item = item;
        return itemsCollection;
    },

    convertKeyValuesObjectsIntoNameValuePairItems(
        keyValuesArray,
        selectedPrinterId,
        selectedPrinterName,
        tnumValue,
        ptxIdValue,
        entryMethod,
        updateCount,
    ) {
        const postData = {
            items: [{
                item: [
                    {
                        name: 'printerPTXId',
                        value: selectedPrinterId,
                    }],
            }, {
                item: [
                    {
                        name: 'printerPTXName',
                        value: selectedPrinterName,
                    }],
            }, {
                item: [
                    {
                        name: 'entryMethod',
                        value: entryMethod,
                    }],
            }],
        };
        const tnumsCollection = { item: [] };
        const statusCollection = { item: [] };
        const printStatusCollection = { item: [] };
        const ptxIdsCollection = { item: [] };
        const typeCodesCollection = { item: [] };
        const updateCountCollection = { item: [] };
        const checkStatusCollection = { item: [] };

        for (let index = 0; index < keyValuesArray.length; index += 1) {
            const keyValuesObject = keyValuesArray[index];
            tnumsCollection.item.push({ name: tnumValue, value: keyValuesObject.tnum });
            statusCollection.item.push({ name: 'status', value: keyValuesObject.status });
            ptxIdsCollection.item.push({ name: ptxIdValue, value: keyValuesObject.PTXId });
            printStatusCollection.item.push({ name: 'printStatus', value: keyValuesObject.printStatus });
            typeCodesCollection.item.push({ name: 'typeCode', value: keyValuesObject.typeCode });
            checkStatusCollection.item.push({ name: 'checkStatus', value: keyValuesObject.checkStatus });
            // PCM-3551
            updateCountCollection.item.push({
                name: 'updateCount',
                value: keyValuesObject.updateCount != null ?
                    keyValuesObject.updateCount : updateCount,
            });
        }
        postData.items.push(tnumsCollection);
        postData.items.push(statusCollection);
        postData.items.push(printStatusCollection);
        postData.items.push(ptxIdsCollection);
        postData.items.push(typeCodesCollection);
        postData.items.push(updateCountCollection);
        postData.items.push(checkStatusCollection);
        return postData;
    },

    printBatch() {
        const self = this;
        const url = services.generateUrl(this.printBatchUrl);
        if (!this.model.get('selectedPrinter')) return '';
        const selectedPrinterId = this.model.get('selectedPrinter').get('id');
        const selectedPrinterName = this.model.get('selectedPrinter').get('label');
        const { entryMethod } = this.options;
        // PCM-3551
        const updateCount = self.options.checkBatchesData[0].updateCount != null ?
            self.options.checkBatchesData[0].updateCount : this.paymentListModel.tempModel.get('UPDATECOUNT__');
        const postData = this.convertKeyValuesObjectsIntoNameValuePairItems(
            this.options.checkBatchesData,
            selectedPrinterId,
            selectedPrinterName,
            'batchTnum',
            'batchPTXId',
            entryMethod,
            updateCount,
        );
        const modalFooter = $('.modal-footer');
        const printButton = modalFooter.children()[0];
        printButton.disabled = true;
        return new Promise((resolve, reject) => {
            // PCM-3455
            http.post(url, postData, (response) => {
                if (response.item !== null && response.item[0] != null &&
                    response.item[0].value === 'incorrectCheckStatus') {
                    reject(this.errorResultOnIncorrectCheckstatusInsideBatch(response));
                } else {
                    resolve(this.successResult(self, self.printBatchSuccessMessage));
                }
            }, () => {
                reject(this.errorResult(self));
            });
        });
    },

    reprintBatch() {
        const self = this;
        if (!this.model.get('selectedPrinter')) return '';
        const modalFooter = $('.modal-footer');
        const reprintButton = modalFooter.children()[0];
        reprintButton.disabled = true;
        const url = services.generateUrl(this.reprintBatchUrl);
        const selectedPrinterId = this.model.get('selectedPrinter').get('id');
        const selectedPrinterName = this.model.get('selectedPrinter').get('label');
        const { entryMethod } = this.options;
        // PCM-3551
        const updateCount = self.options.checkBatchesData[0].updateCount != null ?
            self.options.checkBatchesData[0].updateCount : this.paymentListModel.tempModel.get('UPDATECOUNT__');
        const postData = this.convertKeyValuesObjectsIntoNameValuePairItems(
            this.options.checkBatchesData,
            selectedPrinterId,
            selectedPrinterName,
            'batchTnum',
            'batchPTXId',
            entryMethod,
            updateCount,
        );

        return new Promise((resolve, reject) => {
            // PCM-3455
            http.post(url, postData, (response) => {
                if (response.item !== null && response.item[0] != null &&
                    response.item[0].value === 'incorrectCheckStatus') {
                    reject(this.errorResultOnIncorrectCheckstatusInsideBatch(response));
                } else {
                    resolve(this.successResult(self, self.reprintBatchSuccessMessage));
                }
            }, () => {
                reject(this.errorResult(self));
            });
        });
    },

    reprintCheck() {
        const self = this;
        const modalFooter = $('.modal-footer');
        const reprintButton = modalFooter.children()[0];
        reprintButton.disabled = true;
        const url = services.generateUrl(this.reprintCheckUrl);
        const selectedPrinterId = this.model.get('selectedPrinter').get('id');
        const selectedPrinterName = this.model.get('selectedPrinter').get('label');
        const { entryMethod } = this.options;
        const singleCheckSelectedFromTheGrid = self.options.checkModel;
        let successMessage;
        if (singleCheckSelectedFromTheGrid) {
            successMessage =
                self.giveSuccessMsgForSingleCheckReprint(singleCheckSelectedFromTheGrid);
        } else {
            successMessage = self.reprintCheckSuccessMessage;
        }
        const postData = this.convertKeyValuesObjectsIntoNameValuePairItems(
            this.options.checkBatchesData,
            selectedPrinterId,
            selectedPrinterName,
            'checkTnum',
            'checkPTXId',
            entryMethod,
            this.options.checkBatchesData[0].updateCount,
        );

        return new Promise((resolve, reject) => {
            http.post(url, postData, () => {
                resolve(this.successResult(self, successMessage));
            }, () => {
                reject(this.errorResult(self));
            });
        });
    },
});
