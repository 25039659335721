import $ from 'jquery';

export default function (form, initialState) {
    const formState = form.formView.state;
    const { model } = form.formView;
    const countryCode = form.field('COUNTRYCODE');

    if (initialState && formState === 'modify') {
        countryCode.shouldBeReadOnly(true);
    }

    if (initialState && formState === 'view') {
        // FIX ME
        if (model.get('ALLOWFORWIREINTERNATIONAL') === '1') {
            $('#ALLOWFORWIREINTERNATIONAL span').text('Yes');
        } else {
            $('#ALLOWFORWIREINTERNATIONAL span').text('No');
        }

        if (model.get('APPLYTAXESFORPAYMENT') === '1') {
            $('#APPLYTAXESFORPAYMENT span').text('Yes');
        } else {
            $('#APPLYTAXESFORPAYMENT span').text('No');
        }

        if (model.get('AREPAYMENTSEXEMPT') === '1') {
            $('#AREPAYMENTSEXEMPT span ').text('Yes');
        } else {
            $('#AREPAYMENTSEXEMPT span ').text('No');
        }
    }
}
