import './themeDefaults';

export default ({
  popover: {
    content: {
      lineHeight,
      color: {
        negativeColor
      },
      backgroundColor: {
        defaultBackgroundColor
      }
    },
    fontSize,
    border,
    borderRadius,
    boxShadow,
    title
  }
}) => ({
  root: {
    background: defaultBackgroundColor,
    border,
    borderRadius,
    boxShadow,
    margin: ({ noArrow }) => (noArrow ? 0 : 10),
    padding: 16,
    zIndex: ({ zIndex }) => zIndex,
    position: 'absolute',
    display: 'none',
    fontSize,
    lineHeight,

    '& $arrow::after': {
      background: defaultBackgroundColor,
      boxShadow,
      content: '\'\'',
      height: 10,
      position: 'absolute',
      width: 10,
      border
    },

    '&[data-popper-out-of-boundaries]': {
      display: 'none'
    },

    '&[data-popper-placement^="bottom"] $arrow, &[data-popper-placement^="top"] $arrow': {
      height: 10,
      width: 10 * 2
    },

    '&[data-popper-placement^="bottom"] $arrow': {
      top: -10,

      '&::after': {
        transform: 'translateX(50%) translateY(50%) rotate(45deg)'
      }
    },

    '&[data-popper-placement^="top"] $arrow': {
      bottom: -10,

      '&::after': {
        transform: 'translateX(50%) translateY(-50%) rotate(45deg)'
      }
    },

    '&[data-popper-placement^="left"] $arrow, &[data-popper-placement^="right"] $arrow': {
      height: 10 * 2,
      width: 10
    },

    '&[data-popper-placement^="left"] $arrow': {
      right: -10,

      '&::after': {
        transform: 'translateX(-50%) translateY(50%) rotate(45deg)'
      }
    },

    '&[data-popper-placement^="right"] $arrow': {
      left: -10,

      '&::after': {
        transform: 'translateX(50%) translateY(50%) rotate(45deg)'
      }
    }
  },
  hide: {
    opacity: 0,
    transition: 'opacity 50ms ease-in'
  },
  show: {
    display: 'block',
    opacity: 1,
    transition: 'opacity 50ms ease-out'
  },
  title: {
    fontWeight: 700,
    fontSize,
    color: negativeColor,
    textDecoration: 'none solid rgb(51,51,51)',
    marginBottom: 14,
    ...title
  },
  arrow: {
    // needed to ensure this style is dynamic so the dynamic ones above can find it, dumb but
    // it is what it is
    //
    // Need to set height and width at max possible so it can calculate center correctly.
    // The other styles will override.
    height: 20,
    margin: () => 0,
    position: 'absolute',
    overflow: 'hidden',
    width: 20
  }
});
