import securityChallengeQuestions from 'system/login/services/securityChallengeQuestions';
import Challenge from './challenge';
import template from './authenticatedChallenge.hbs';

export default Challenge.extend({
    template,

    validateAnswers(data) {
        return securityChallengeQuestions.validateAnswers(data);
    },
});
