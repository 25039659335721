import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Carousel from './Carousel';
import useStyles from './DocumentsViewer.styles';

const DocumentsViewer = ({
    className, pages,
    activePage: propsActivePage, onActivePageChange, ...documentProps
}) => {
    const classes = useStyles();
    const [activePageIndex, setActivePageIndex] = useState(0);
    const activePage = pages[activePageIndex];
    const { pageNumber } = activePage || {};
    const pagesCount = pages.length;
    const propsActivePageId = propsActivePage ? propsActivePage.id : undefined;

    const onActiveIndexChange = useCallback((index) => {
        setActivePageIndex(index);
        onActivePageChange(pages[index]);
    }, [onActivePageChange, pages]);

    useEffect(() => {
        const newIndex = pages.findIndex(({ id }) => id === propsActivePageId);

        if (propsActivePageId && newIndex > -1) {
            setActivePageIndex(newIndex);
        }
    }, [pages, propsActivePageId]);

    // opens needed page when highlighted element is not on the active page
    useEffect(() => {
        if (!documentProps.locationToHighlight ||
            documentProps.locationToHighlight.pageNumber === pageNumber) {
            return;
        }
        const { pageNumber: pageToOpen } = documentProps.locationToHighlight;
        const pageIndex = pages.findIndex(page => page.pageNumber === pageToOpen);
        if (pageIndex >= 0) onActiveIndexChange(pageIndex);
    }, [documentProps.locationToHighlight, onActiveIndexChange, pageNumber, pages]);

    if (!pages?.length) return null;
    return (
        <div className={`${classes.root} ${className}`}>
            {pagesCount > 1 ? (
                <Carousel
                    classes={classes}
                    activePageIndex={activePageIndex}
                    pages={pages}
                    onChange={onActiveIndexChange}
                />
            ) : null}
        </div>
    );
};

const pagePropType = PropTypes.shape({
    currencyCode: PropTypes.string,
    count: PropTypes.number,
    pageNumber: PropTypes.number,
    id: PropTypes.number,
});

DocumentsViewer.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.arrayOf(pagePropType).isRequired,
    onActivePageChange: PropTypes.func,
    activePage: pagePropType,
};

DocumentsViewer.defaultProps = {
    className: '',
    activePage: {},
    onActivePageChange: () => { },
};

export default DocumentsViewer;
