var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":35}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":9,"column":50},"end":{"line":9,"column":59}}}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-md-2\">\n        <div class=\"widget-field-container text-group\">\n            <label class=\"amount-label\" for=\"CREDIT_AMOUNT\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"creditAmountLabel") || (depth0 != null ? lookupProperty(depth0,"creditAmountLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creditAmountLabel","hash":{},"data":data,"loc":{"start":{"line":19,"column":60},"end":{"line":19,"column":81}}}) : helper)))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditAmountToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCreditAmountLockable") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":40,"column":23}}})) != null ? stack1 : "")
    + "            <p class=\"form-control-static\" data-amount-type=\"CREDIT_VIEW\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"CREDIT_AMOUNT") : depth0),(depth0 != null ? lookupProperty(depth0,"creditViewFormat") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":41,"column":74},"end":{"line":41,"column":115}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"CREDIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"CREDIT_CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CREDIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":41,"column":116},"end":{"line":41,"column":135}}}) : helper)))
    + "</p>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPayment") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":52,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"textline-field\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"creditInfo") || (depth0 != null ? lookupProperty(depth0,"creditInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creditInfo","hash":{},"data":data,"loc":{"start":{"line":53,"column":37},"end":{"line":53,"column":51}}}) : helper)))
    + "</span>\n\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a tabindex=\"0\" class=\"btn btn-popover\" role=\"button\" data-toggle=\"popover\" data-trigger=\"hover focus\"\n                data-placement=\"auto top\"\n                data-content=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"creditAmountToolTip") || (depth0 != null ? lookupProperty(depth0,"creditAmountToolTip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"creditAmountToolTip","hash":{},"data":data,"loc":{"start":{"line":23,"column":30},"end":{"line":23,"column":53}}}) : helper)))
    + "\"\n                data-original-title=\"\">\n                    <span class=\"icon-info\"></span>\n                </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"mdf-input-icon-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCreditAmountLockedByDefault") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":37,"column":27}}})) != null ? stack1 : "")
    + "                            btn-tertiary btn\" name=\"CREDIT_AMOUNT\"></button>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"view icon-lock\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCreditLocked") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":37,"column":20}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "                            <button class=\"view icon-lock\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                        <button class=\"view icon-unlock\n                    ";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"widget-field-container fxWidgetRate\" name=\"EXCHANGE_RATE_VIEW\">\n                <label for=\"EXCHANGE_RATE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.exchange_rate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":43},"end":{"line":45,"column":76}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"viewAmount")||(depth0 && lookupProperty(depth0,"viewAmount"))||alias3).call(alias1,"EXCHANGE_RATE",{"name":"viewAmount","hash":{},"data":data,"loc":{"start":{"line":46,"column":47},"end":{"line":46,"column":77}}}))
    + "</p>\n            </div>\n            <div class=\"widget-field-container fxWidgetRate\" name=\"INDICATIVE_RATE_VIEW\">\n                <label for=\"INDICATIVE_RATE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.INDICATIVE_RATE",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":45},"end":{"line":49,"column":80}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"viewAmount")||(depth0 && lookupProperty(depth0,"viewAmount"))||alias3).call(alias1,"INDICATIVE_RATE",{"name":"viewAmount","hash":{},"data":data,"loc":{"start":{"line":50,"column":47},"end":{"line":50,"column":79}}}))
    + "</p>\n            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-md-2 col-mdlg-4 widget-spacing widget-field-container required\" data-hook=\"credit-amount\">\n        <label class=\"amount-label\" for=\"CREDIT_AMOUNT\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"creditAmountLabel") || (depth0 != null ? lookupProperty(depth0,"creditAmountLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creditAmountLabel","hash":{},"data":data,"loc":{"start":{"line":58,"column":56},"end":{"line":58,"column":77}}}) : helper)))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditAmountToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":66,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCreditAmountLockable") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":78,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"input-group input-group-combobox\">\n            <input class=\"form-control\"\n                data-amount-type=\"CREDIT\"\n                data-bind=\"model\"\n                data-maxlength=\""
    + alias4((lookupProperty(helpers,"getOriginalMaxLen")||(depth0 && lookupProperty(depth0,"getOriginalMaxLen"))||alias2).call(alias1,"CREDIT_AMOUNT",{"name":"getOriginalMaxLen","hash":{},"data":data,"loc":{"start":{"line":83,"column":32},"end":{"line":83,"column":69}}}))
    + "\"\n                data-type=\"amount\"\n                maxlength=\""
    + alias4((lookupProperty(helpers,"getMaxLen")||(depth0 && lookupProperty(depth0,"getMaxLen"))||alias2).call(alias1,"CREDIT_AMOUNT",{"name":"getMaxLen","hash":{},"data":data,"loc":{"start":{"line":85,"column":27},"end":{"line":85,"column":56}}}))
    + "\"\n                name=\"CREDIT_AMOUNT\"\n                id=\"CREDIT_AMOUNT\"\n                "
    + ((stack1 = (lookupProperty(helpers,"isProtected")||(depth0 && lookupProperty(depth0,"isProtected"))||alias2).call(alias1,"CREDIT_AMOUNT",{"name":"isProtected","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":16},"end":{"line":88,"column":72}}})) != null ? stack1 : "")
    + "\n                type=\"text\">\n            <div class=\"input-group-addon\">\n                <select class=\"currency-field currency-dropdown\"\n                    data-bind=\"model\"\n                    data-hook=\"getCreditCurrency\"\n                    "
    + ((stack1 = (lookupProperty(helpers,"isDisabled")||(depth0 && lookupProperty(depth0,"isDisabled"))||alias2).call(alias1,"CREDIT_CURRENCY",{"name":"isDisabled","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":20},"end":{"line":94,"column":76}}})) != null ? stack1 : "")
    + "\n                    name=\"CREDIT_CURRENCY\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditCurrencyList") : depth0),{"name":"each","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":20},"end":{"line":99,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </div>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n        <span class=\"textline-field\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"creditInfo") || (depth0 != null ? lookupProperty(depth0,"creditInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creditInfo","hash":{},"data":data,"loc":{"start":{"line":104,"column":37},"end":{"line":104,"column":51}}}) : helper)))
    + "</span>\n\n    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a tabindex=\"0\" class=\"btn btn-popover\" role=\"button\" data-toggle=\"popover\" data-trigger=\"hover focus\"\n                data-placement=\"auto top\"\n                data-content=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"creditAmountToolTip") || (depth0 != null ? lookupProperty(depth0,"creditAmountToolTip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"creditAmountToolTip","hash":{},"data":data,"loc":{"start":{"line":62,"column":30},"end":{"line":62,"column":53}}}) : helper)))
    + "\"\n                data-original-title=\"\">\n                    <span class=\"icon-info\"></span>\n                </a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"mdf-input-icon-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCreditAmountLockedByDefault") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":75,"column":19}}})) != null ? stack1 : "")
    + "            btn-tertiary btn\" data-action=\"toggleLock\" name=\"CREDIT_AMOUNT\"></button>\n            </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "                       <button class=\"icon-lock\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCreditLocked") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":71,"column":12},"end":{"line":75,"column":12}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "                    <button class=\"icon-lock\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "                       <button class=\"icon-unlock\n            ";
},"29":function(container,depth0,helpers,partials,data) {
    return "readonly";
},"31":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":98,"column":35},"end":{"line":98,"column":43}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":98,"column":58},"end":{"line":98,"column":67}}}) : helper)))
    + "</option>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-md-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"crossCurr") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":8},"end":{"line":142,"column":15}}})) != null ? stack1 : "")
    + "                <span class=\"textline-field\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"debitInfo") || (depth0 != null ? lookupProperty(depth0,"debitInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"debitInfo","hash":{},"data":data,"loc":{"start":{"line":143,"column":45},"end":{"line":143,"column":58}}}) : helper)))
    + "</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPayment") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":8},"end":{"line":150,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"widget-field-container text-group\">\n                <label class=\"amount-label\" for=\"DEBIT_AMOUNT\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"debitAmountLabel") || (depth0 != null ? lookupProperty(depth0,"debitAmountLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"debitAmountLabel","hash":{},"data":data,"loc":{"start":{"line":119,"column":63},"end":{"line":119,"column":83}}}) : helper)))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"debitAmountToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":16},"end":{"line":127,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDebitAmountLockable") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":16},"end":{"line":139,"column":27}}})) != null ? stack1 : "")
    + "                <p class=\"form-control-static\" data-amount-type=\"DEBIT_VIEW\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),(depth0 != null ? lookupProperty(depth0,"debitViewFormat") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":140,"column":77},"end":{"line":140,"column":116}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":140,"column":117},"end":{"line":140,"column":135}}}) : helper)))
    + "</p>\n            </div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a tabindex=\"0\" class=\"btn btn-popover\" role=\"button\" data-toggle=\"popover\" data-trigger=\"hover focus\"\n                data-placement=\"auto top\"\n                data-content=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"debitAmountToolTip") || (depth0 != null ? lookupProperty(depth0,"debitAmountToolTip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"debitAmountToolTip","hash":{},"data":data,"loc":{"start":{"line":123,"column":30},"end":{"line":123,"column":52}}}) : helper)))
    + "\"\n                data-original-title=\"\">\n                    <span class=\"icon-info\"></span>\n                </a>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"mdf-input-icon-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDebitAmountLockedByDefault") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":130,"column":32},"end":{"line":136,"column":39}}})) != null ? stack1 : "")
    + "                                btn-tertiary btn\" data-action=\"toggleLock\" name=\"DEBIT_AMOUNT\"></button>\n                        </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "                                        <button class=\"view icon-lock\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDebitLocked") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":132,"column":32},"end":{"line":136,"column":32}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    return "                                        <button class=\"view icon-unlock\n                                ";
},"43":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"widget-field-container fxWidgetRate\" name=\"CONTRACTID_VIEW\">\n                <label for=\"EXCHANGE_RATE_CONTRACTID\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.screentext.Contract ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":147,"column":54},"end":{"line":147,"column":94}}}))
    + "</label>\n                <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"EXCHANGE_RATE_CONTRACTID") || (depth0 != null ? lookupProperty(depth0,"EXCHANGE_RATE_CONTRACTID") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"EXCHANGE_RATE_CONTRACTID","hash":{},"data":data,"loc":{"start":{"line":148,"column":47},"end":{"line":148,"column":75}}}) : helper)))
    + "</p>\n            </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-md-2 col-mdlg-4 widget-spacing widget-field-container\" data-hook=\"debit-amount\">\n        <label class=\"amount-label\" for=\"DEBIT_AMOUNT\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"debitAmountLabel") || (depth0 != null ? lookupProperty(depth0,"debitAmountLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"debitAmountLabel","hash":{},"data":data,"loc":{"start":{"line":154,"column":55},"end":{"line":154,"column":75}}}) : helper)))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"debitAmountToolTip") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":8},"end":{"line":162,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"input-group\">\n            <input class=\"form-control\"\n                type=\"text\"\n                maxlength=\""
    + alias4((lookupProperty(helpers,"getMaxLen")||(depth0 && lookupProperty(depth0,"getMaxLen"))||alias2).call(alias1,"DEBIT_AMOUNT",{"name":"getMaxLen","hash":{},"data":data,"loc":{"start":{"line":166,"column":27},"end":{"line":166,"column":55}}}))
    + "\"\n                data-maxlength=\""
    + alias4((lookupProperty(helpers,"getOriginalMaxLen")||(depth0 && lookupProperty(depth0,"getOriginalMaxLen"))||alias2).call(alias1,"DEBIT_AMOUNT",{"name":"getOriginalMaxLen","hash":{},"data":data,"loc":{"start":{"line":167,"column":32},"end":{"line":167,"column":68}}}))
    + "\"\n                data-type=\"amount\"\n                data-amount-type=\"DEBIT\"\n                data-bind=\"model\"\n                name=\"DEBIT_AMOUNT\"\n                "
    + ((stack1 = (lookupProperty(helpers,"isProtected")||(depth0 && lookupProperty(depth0,"isProtected"))||alias2).call(alias1,"DEBIT_AMOUNT",{"name":"isProtected","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":16},"end":{"line":172,"column":71}}})) != null ? stack1 : "")
    + "\n                type=\"text\">\n            <div class=\"input-group-addon\"><input class=\"currency-field\" readonly data-bind=\"model\" tabIndex=\"-1\" name=\"DEBIT_CURRENCY\"  /></div>\n        </div>\n        <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_AMOUNT\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDebitAmountLockable") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":8},"end":{"line":188,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"textline-field\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"debitInfo") || (depth0 != null ? lookupProperty(depth0,"debitInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"debitInfo","hash":{},"data":data,"loc":{"start":{"line":189,"column":37},"end":{"line":189,"column":50}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPayment") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":8},"end":{"line":207,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a tabindex=\"0\" class=\"btn btn-popover\" role=\"button\" data-toggle=\"popover\" data-trigger=\"hover focus\"\n                data-placement=\"auto top\"\n                data-content=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"debitAmountToolTip") || (depth0 != null ? lookupProperty(depth0,"debitAmountToolTip") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"debitAmountToolTip","hash":{},"data":data,"loc":{"start":{"line":158,"column":30},"end":{"line":158,"column":52}}}) : helper)))
    + "\"\n                data-original-title=\"\">\n                    <span class=\"icon-info\"></span>\n                </a>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"mdf-input-icon-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDebitAmountLockedByDefault") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(49, data, 0),"data":data,"loc":{"start":{"line":179,"column":12},"end":{"line":185,"column":19}}})) != null ? stack1 : "")
    + "            btn-tertiary btn\" data-action=\"toggleLock\" name=\"DEBIT_AMOUNT\"></button>\n            </div>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDebitLocked") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":181,"column":12},"end":{"line":185,"column":12}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <span class=\"widget-rate\" data-hook=\"indicative-rate\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.INDICATIVE_RATE",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":193,"column":23},"end":{"line":193,"column":58}}}))
    + "</label><input type=\"text\" name=\"INDICATIVE_RATE\" readonly\n                    data-bind=\"model\" />\n            </span>\n            <span class=\"widget-rate\" data-hook=\"exchange-rate\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.exchange_rate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":197,"column":23},"end":{"line":197,"column":56}}}))
    + "</label><input type=\"text\" name=\"EXCHANGE_RATE\" readonly data-bind=\"model\" />\n            </span>\n            <span data-hook=\"contractIDContainer\" class=\"contractid-container hide\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.screentext.Contract ID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":200,"column":23},"end":{"line":200,"column":63}}}))
    + "</label><span class=\"contractid\" data-hook=\"contractID\"></span>\n            </span>\n            <div>\n                <a href=\"#\" data-hook=\"contractIDpopUp\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.AddContractId",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":203,"column":56},"end":{"line":203,"column":87}}}))
    + "</a>\n                <a href=\"#\" data-hook=\"removeContractID\" class=\"hide\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.RemoveContractID",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":204,"column":71},"end":{"line":204,"column":105}}}))
    + "</a>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-md-2 col-mdlg-4 widget-spacing widget-field-container\">\n   <label for=\"CREDIT_DEBIT_ENTERED\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"creditDebitEnteredLabel") || (depth0 != null ? lookupProperty(depth0,"creditDebitEnteredLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creditDebitEnteredLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":64}}}) : helper)))
    + "</label>\n    <select class=\"form-control form-select\"\n        data-bind=\"model\"\n        data-hook=\"getCreditDebitEntered\"\n        "
    + ((stack1 = (lookupProperty(helpers,"isDisabled")||(depth0 && lookupProperty(depth0,"isDisabled"))||alias2).call(alias1,"CREDIT_DEBIT_ENTERED",{"name":"isDisabled","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":69}}})) != null ? stack1 : "")
    + "\n        name=\"CREDIT_DEBIT_ENTERED\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditDebitEnteredOptions") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + "    </select>\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":12,"column":62},"end":{"line":12,"column":75}}}) : helper)))
    + "\"></span>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isView") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":16,"column":0},"end":{"line":107,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"col-md-2 widget-field-container hide\"  data-hook=\"indicative-amount\">\n    <input class=\"form-control\" type=\"text\" data-type=\"amount\"  data-bind=\"model\" name=\"INDICATIVEAMOUNT\" readonly  >\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isView") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(45, data, 0),"data":data,"loc":{"start":{"line":115,"column":0},"end":{"line":209,"column":7}}})) != null ? stack1 : "");
},"useData":true});