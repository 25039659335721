import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import recipientTmpl from './recipient.hbs';

export default ItemView.extend({
    template: recipientTmpl,
    binding: true,
    tagName: 'td',
    className: 'sb-simple-cell',

    initialize(options) {
        this.model = options.model;
        this.column = options.column;
        this.recipient = this.model.get('recipient');
    },

    templateHelpers() {
        return {
            cellHeader: this.column.get('title'),
            cellHeaderExists: !util.isEmpty(this.column.get('title')),
            cellValue: this.recipient.get('BENE_NAME'),
            cellValueRouting: this.recipient.get('BENE_BANK_ID'),
            cellValueAccount: this.recipient.get('BENE_ACCOUNTNUMBER'),
        };
    },
});
