var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"first")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":7},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"TypeToggle-btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideToggle") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":66}}})) != null ? stack1 : "")
    + "\" data-hook=\"getToggleBtn\">\n            <input role=\"radio\" aria-controls=\"stopPaymentsTabPanel\" type=\"radio\" name=\"stateToggle\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"toggleValue") || (depth0 != null ? lookupProperty(depth0,"toggleValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleValue","hash":{},"data":data,"loc":{"start":{"line":11,"column":108},"end":{"line":11,"column":123}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"toggleValue") || (depth0 != null ? lookupProperty(depth0,"toggleValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleValue","hash":{},"data":data,"loc":{"start":{"line":11,"column":129},"end":{"line":11,"column":144}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":146},"end":{"line":11,"column":253}}})) != null ? stack1 : "")
    + ">\n            <label class=\"TypeToggle-btnLabel\" for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"toggleValue") || (depth0 != null ? lookupProperty(depth0,"toggleValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleValue","hash":{},"data":data,"loc":{"start":{"line":12,"column":52},"end":{"line":12,"column":67}}}) : helper)))
    + "\">\n                <span class=\"icon "
    + alias4(((helper = (helper = lookupProperty(helpers,"toggleIcon") || (depth0 != null ? lookupProperty(depth0,"toggleIcon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleIcon","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":48}}}) : helper)))
    + "\"></span>\n                <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"toggleText") || (depth0 != null ? lookupProperty(depth0,"toggleText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggleText","hash":{},"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":36}}}) : helper)))
    + "</span>\n            </label>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "           <div class=\"TypeToggle-separator "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hideToggle") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":44},"end":{"line":6,"column":75}}})) != null ? stack1 : "")
    + "\">\n               <span class=\"TypeToggle-textSeparator\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"separatorText")), depth0))
    + "</span>\n           </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " hide ";
},"5":function(container,depth0,helpers,partials,data) {
    return " aria-checked=\"true\" tabindex=\"0\" checked ";
},"7":function(container,depth0,helpers,partials,data) {
    return " aria-checked=\"false\" tabindex=\"-1\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"TypeToggle\">\n    <div class=\"TypeToggle-description\" id=\"typeToggleOptions\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"toggleDescription") || (depth0 != null ? lookupProperty(depth0,"toggleDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"toggleDescription","hash":{},"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":84}}}) : helper)))
    + "</div>\n    <div role=\"radiogroup\" class=\"TypeToggle-options\" id=\"typeToggleRadioGroup\" aria-labelledby=\"typeToggleOptions\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"toggleBtns") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});