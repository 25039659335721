import ItemView from '@glu/core/src/itemView';
import template from './multilineCell.hbs';

export default ItemView.extend({
    template,
    disableInitialViewBinding: true,

    initialize() {
        ItemView.prototype.initialize(this);
        this.$el.attr('data-title', this.options.value);
    },

    templateHelpers() {
        let linesArray;

        if (this.options.value === '') {
            linesArray = ['—'];
        } else {
            linesArray = this.options.value.split(/\r?\n/g);
        }

        return {
            splitLines: linesArray,
        };
    },
});
