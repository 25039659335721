import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ViewWrapper from 'react-wrappers/ViewWrapper';
import MobileDrawer from 'components/MobileDrawer/MobileDrawer';
import store from 'system/utilities/cache';

const propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    returnRoute: PropTypes.string,
    view: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

const defaultProps = {
    isOpen: true,
    returnRoute: undefined,
    onClose: () => {},
};

const MobileViewContainer = ({
    isOpen, view, returnRoute, onClose,
}) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(isOpen);

    const handleClose = () => {
        if (!returnRoute) {
            setDrawerIsOpen(false);
            onClose();
            return;
        }
        const navigateTo = store.get('mobileNavigate');
        navigateTo(returnRoute);
    };

    return (
        <MobileDrawer isOpen={drawerIsOpen} onClose={handleClose} title={view.title}>
            <ViewWrapper view={view} className="mobile-drawer-wrapper" />
        </MobileDrawer>
    );
};

MobileViewContainer.propTypes = propTypes;
MobileViewContainer.defaultProps = defaultProps;

export default MobileViewContainer;
