var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"form-column col-md-12\">\n                    <div class=\"field-container-md field-container\"><strong>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.AddToLocationSubTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":6,"column":114}}}))
    + "</strong></div>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"field-container-lg field-container\">\n                        <label class=\"checkbox-inline\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.ImageViewing",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":29,"column":57}}}))
    + "\n                        </label>\n                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"field-container-lg field-container\">\n                        <label class=\"checkbox-inline\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.DetailReports",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":36,"column":58}}}))
    + "\n                        </label>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"field-container-lg field-container\">\n                        <label class=\"checkbox-inline\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"LBX.FloatAvailability",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":43,"column":62}}}))
    + "\n                        </label>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add-lockbox\">\n    <form autocomplete=\"off\" data-submit=\"save\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-md field-container\">\n                    <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOX_CODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOX_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOX_CODE","hash":{},"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":48}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.LockboxCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":50},"end":{"line":13,"column":78}}}))
    + "</label>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"LOCKBOX_CODE") || (depth0 != null ? lookupProperty(depth0,"LOCKBOX_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LOCKBOX_CODE","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":36}}}) : helper)))
    + "\n                </div>\n            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\" field-container-md field-container\">\n                    <label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":19,"column":32},"end":{"line":19,"column":40}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.LockboxName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":70}}}))
    + "</label>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":28}}}) : helper)))
    + "\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"IMAGE_VIEWING") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"DETAIL_REPORTS") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"REPORT_FLOAT") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":46,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container-lg field-container\">\n                    <label for=\"remitterManageByBank\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.RemitterNameData",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":54},"end":{"line":50,"column":87}}}))
    + "</label>\n                </div>\n                <div id=\"remitterManageByBank\" class=\"field-container field-container-lg field-container\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"getRemitterManageBy") || (depth0 != null ? lookupProperty(depth0,"getRemitterManageBy") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"getRemitterManageBy","hash":{},"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":53,"column":45}}}) : helper)))
    + "\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});