import CollectionView from '@glu/core/src/collectionView';
import Collection from '@glu/core/src/collection';
import productFeatureSubGroupTmpl from 'app/administration/views/notificationManagement/productFeatureSubGroup.hbs';
import productFeatureTmpl from 'app/administration/views/notificationManagement/productFeature.hbs';
import ItemView from '@glu/core/src/itemView';

const features = ItemView.extend({
    tagName: 'li',
    className: 'subFeature',
    template: productFeatureTmpl,

    events: {
        'click @ui.$checkbox': 'selected',
    },

    ui: {
        $checkbox: 'input.product-feature',
    },

    selected(e) {
        if (this.$(e.currentTarget).prop('checked')) {
            this.model.collection.selectedChildCount += 1;
            this.model.set('isSelected', true);
        } else {
            if (this.model.collection.selectedChildCount > 0) {
                this.model.collection.selectedChildCount -= 1;
            }
            this.model.set('isSelected', false);
        }
    },
});

export default ItemView.extend({
    template: productFeatureSubGroupTmpl,

    ui: {
        $title: '.product-subgroup-title',
        $checkbox: 'input.product-feature',
        $features: '.product-feature-content',
        $counter: '[data-hook="counter"]',
        $selectAll: '.product-subgroup-selectAll',
    },

    events: {
        'click @ui.$title': 'toggleSubGroup',
        'click @ui.$selectAll': 'selectAll',
    },

    initialize() {
        this.listenTo(this.appBus, `allFeatures allSubFeatures-${this.model.get('groupId')}`, this.handleToggleAllFeatures);
    },

    onRender() {
        this.setFeatureCollection();
        this.renderCollectionView();
        this.handleSelectAllFeatures();
    },

    handleSelectAllFeatures() {
        this.ui.$selectAll.prop('checked', this.options.selectAllFeatures);
        if (this.options.selectAllFeatures) {
            this.updateIsSelectedAttr(this.options.selectAllFeatures);
        }
    },

    handleToggleAllFeatures(value) {
        this.ui.$selectAll.prop('checked', value);
        this.updateIsSelectedAttr(value);
    },

    setFeatureCollection() {
        this.featureCollection = new Collection(this.model.get('branches'));
        // model count is 0 for the subgroup
        this.featureCollection.selectedChildCount = this.model.get('selectedChildCount') || 0;
        this.updateCounter(true);
        this.listenTo(this.featureCollection, 'change:isSelected', this.handleSingleFeatureChange);
    },

    allFeaturesSelected() {
        return this.featureCollection.selectedChildCount === this.featureCollection.length;
    },

    /**
     * When all items are selected update the property on all models and the
     * count on the collection
     * @param value
     */
    updateIsSelectedAttr(value) {
        this.featureCollection.each((model) => {
            model.set({
                isSelected: value,
            }, {
                silent: true,
            });
        });
        this.featureCollection.selectedChildCount = value ? this.featureCollection.length : 0;
        this.collectionView.$el.find('li.subFeature input').prop('checked', value);
        this.updateCounter();
    },

    handleSingleFeatureChange(model, value) {
        if (!value) {
            this.model.set('allItemsSelected', value);
            this.ui.$selectAll.prop('checked', value);
        }
        this.updateCounter();
        if (this.allFeaturesSelected()) {
            this.ui.$selectAll.prop('checked', true);
        }
    },

    renderCollectionView() {
        this.collectionView = new CollectionView({
            el: this.$('.product-feature-container'),
            tagName: 'ul',
            collection: this.featureCollection,
            itemView: features,

            itemViewOptions: {
                mode: this.options.mode,
            },
        });
        this.collectionView.render();
    },

    toggleSubGroup(e) {
        this.$(e.currentTarget).toggleClass('active');
        this.ui.$features.slideToggle();
    },

    selectAll(e) {
        this.updateIsSelectedAttr(e.currentTarget.checked);
        this.model.set('allItemsSelected', this.allFeaturesSelected());
        this.updateCounter();
    },

    updateCounter(shouldGetCollectionCount) {
        if (this.options.mode !== 'INSERT' && shouldGetCollectionCount) {
            this.featureCollection.selectedChildCount = this.featureCollection.filter(model => model.get('isSelected')).length;
        }
        this.ui.$counter.html(this.featureCollection.selectedChildCount);
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === 'SELECT',
        };
    },
});
