import { useState, useEffect, useRef } from 'react';

/*
 * thinking this could be a common 'toggle' hook to abstract
 * the keybindings for escape, blur and such
 */
const useToggle = (startOpen, classes = {}) => {
    const [isOpen, setOpen] = useState(startOpen);
    const containerRef = useRef(null);

    const classesString = (isOpen)
        ? `${classes.drawerMessageToggle} ${classes.drawerMessageToggleOpen}`
        : `${classes.drawerMessageToggle}`;

    function handleKeyUp(event) {
        if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    function togglePageScrollLock(toggle) {
        if (toggle) {
            document.body.classList.add('glu-drawer-open');
        } else {
            document.body.classList.remove('glu-drawer-open');
        }
    }

    function open() {
        setOpen(true);
        if (containerRef.current) {
            togglePageScrollLock(true);
            containerRef.current.focus();
        }
    }

    function close() {
        setOpen(false);
        togglePageScrollLock(false);
    }

    function toggleOpen() {
        setOpen(!isOpen);
        togglePageScrollLock(!isOpen);
    }

    useEffect(() => {
        function handleOutsideClick(event) {
            if (event.defaultPrevented) {
                return;
            }
            const fallbackCheck = containerRef.current.outerHTML.indexOf(event.target.outerHTML)
                !== -1;
            const containerRefHasIt = containerRef.current.contains(event.target);
            if (isOpen && !containerRefHasIt && !fallbackCheck) {
                setOpen(false);
                togglePageScrollLock(false);
            }
        }

        document.addEventListener('keydown', handleKeyUp);
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('keydown', handleKeyUp);
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);

    return {
        classesString,
        handleKeyUp,
        isOpen,
        containerRef,
        open,
        close,
        toggleOpen,
    };
};

export default useToggle;
