import locale from '@glu/locale';
import panelWithTotalsTmpl from './panelWithTotals.hbs';

let panelIdAssign = 0;

export default {
    panelWithTotals(...args) {
        const options = args[args.length - 1];
        const hash = options.hash || {};

        if (hash.locale) {
            hash.label = locale.get(hash.locale);
        }

        if (!hash.theme) {
            hash.theme = 'panel-primary';
        }

        if (hash.totalsWarning) {
            hash.warning = locale.get(hash.totalsWarning);
        }

        const panelId = panelIdAssign;
        panelIdAssign += 1;
        return panelWithTotalsTmpl({
            panelId,
            label: hash.label,
            isExpanded: !hash.collapsed,
            themeClass: hash.theme,
            totalsWarning: hash.warning,
            totalsId: hash.totalsId,
            hasTotals: hash.hasTotals,
            displayDropdown: hash.displayDropdown,
            displayWarningMessage: hash.totalsWarning && hash.hasTotals,
            displayPanelDescription: hash.displayPanelDescription,
            body: options.fn(this),
        });
    },
};
