var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":25,"column":47},"end":{"line":25,"column":55}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"section section-container\">\n    <div class=\"alert-message\">\n        <div data-region=\"alertRegion\" role=\"alert\"></div>\n    </div>\n</section>\n<form class=\"ScheduledExports-add\">\n    <fieldset class=\"section section-container\">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.Detail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":46}}}))
    + "</legend>\n        <div class=\"MultiField-region\" data-region=\"multifieldRegion\"></div>\n    </fieldset>\n    <fieldset class=\"section section-container\">\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.Criteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":48}}}))
    + "</legend>\n        <div class=\"row\">\n            <div class=\"col-md-3 required\">\n                <div data-region=\"reportIdRegion\"></div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REPORT_ID\"></span>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-3 field-container required\">\n                <div data-region=\"descriptionRegion\"></div>\n                <label for=\"REPORTDESC\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":22,"column":69}}}))
    + "</label>\n                <select id=\"REPORTDESC\" class=\"form-control\" data-hook=\"getReportDescDropdown\" multiple=\"multiple\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"reportDescriptions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":26,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REPORTDESC\"></span>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-md-3 required\">\n                <div data-region=\"fileFormatRegion\"></div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"REPORTORDOWNLOAD\"></span>\n            </div>\n        </div>\n        <div calss=\"row\">\n            <div class=\"col-md-6\">\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.Generated.Details",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":19},"end":{"line":39,"column":60}}}))
    + "</p>\n            </div>\n        </div>\n    </fieldset>\n</form>\n\n";
},"useData":true});