import { Layout, util } from '@glu/core';
import template from './dueOnTheDay.hbs';
import locale from '@glu/locale';
import { clearError } from '../../schedulerUtils';

export default Layout.extend({

    template,

    ui: {
        $dueOnRadio: '[data-hook="due-date-on-radio"]',
        $dueWithinRadio: '[data-hook="due-date-within-radio"]',
        $expiresOnDueDateCheckBox: '[data-hook="expires-on-due-date"]',
        $dueOnTheDaySelect: '[data-hook="due-date-on-select"]',
        $dueOnTheDayTime: '[data-hook="due-date-on-day-time"]',
        $dueWithinTheDay: '[data-hook="due-days-within"]',
        $dueWithinTheDayTime: '[data-hook="due-date-within-day-time"]',
    },

    events: {
        'change @ui.$dueOnRadio': 'setRadioButtonsForDueOnDueWithin',
        'change @ui.$dueWithinRadio': 'setRadioButtonsForDueOnDueWithin',
        'change @ui.$expiresOnDueDateCheckBox': 'setExpiresOnDueDate',
        'click @ui.$dueOnRadio': 'clickRadioButtonsForDueOn',
        'click @ui.$dueWithinRadio': 'clickRadioButtonsForDueWithin',
    },

    initialize(options) {
        this.daysInMonth = util.range(1, 32).map((value) => {
            const label = `scheduler.days.${value}${this.ending(value)}`;

            return {
                value,
                label,
            };
        });

        this.model = options.model;

        if (util.isUndefined(this.model.get('dueDateInputMode'))) {
            this.model.set('dueDateInputMode', this.model.get('type') === 'WEEKLY' ? 'DUE_WITHIN' : 'DUE_ON');
        }

        if (this.model.get('dueDateInputMode') === 'DUE_ON') {
            if (util.isUndefined(this.model.get('dueOnTheDay'))) {
                this.model.set({
                    dueOnTheDay: [1],
                });
            }
        }
        this.listenTo(this.model, 'change:type', this.processRecurrencePeriodChange);
    },

    templateHelpers() {
        return {
            daysInMonth: this.daysInMonth,
            cid: this.model.cid,
            userTimeZone: this.model.get('userTimeZone'),
        };
    },

    setRadioState() {
        if (this.model.get('type') === 'WEEKLY') {
            this.$(this.ui.$dueOnRadio).prop('disabled', true);
        } else {
            this.$(this.ui.$dueOnRadio).prop('disabled', false);
        }
    },

    onShow() {
        const value = this.model.get('dueDateInputMode');
        this.setRadioState();
        this.commonDueDateScreenFields(value);
        // A temp fix for NH-152296 where views don't update
        this.ui.$dueOnTheDaySelect.val(this.model.get('dueOnTheDay'));
    },

    setRadioButtonsForDueOnDueWithin(e) {
        const value = this.$(e.currentTarget).val();
        this.commonDueDateScreenFields(value);
    },

    /**
     * If user selects a due billing cycle day, clear any client
     * side error(s) on the specific day of the month fields
     *
     * this code is needed because the code in viewBinding does not get
     * invoked when the validateField function for the fields 'dueOnTheDay'
     * and 'dueOnDateTime' are invoked after their validators are removed
     * TODO - Clean up to rely on the validation in the models
     *
     */
    clickRadioButtonsForDueWithin() {
        const error = this.model.get('error');
        if (error) {
            if (error.dueOnTheDay) {
                delete error.dueOnTheDay;
            }
            this.ui.$dueOnTheDaySelect.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="dueOnTheDay"]').text('').closest('.has-error').removeClass('has-error');

            if (error.dueOnDateTime) {
                delete error.dueOnDateTime;
            }
            this.ui.$dueOnTheDayTime.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="dueOnDateTime"]').text('').closest('.has-error').removeClass('has-error');

            this.model.set('error', error);
        }
    },

    /**
     * If user selects a specific day of the month, clear
     * any client side error on the due billing cycle days
     *
     * this code is needed because the code in viewBinding does not get
     * invoked when the validateField function for the fields 'dueWithin'
     * and 'dueWithinDateTime' are invoked after their validators are removed
     * TODO - Clean up to rely on the validation in the models
     *
     */
    clickRadioButtonsForDueOn() {
        const error = this.model.get('error');
        if (error) {
            if (error.dueWithin) {
                delete error.dueWithin;
            }
            this.ui.$dueWithinTheDay.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="dueWithin"]').text('').closest('.has-error').removeClass('has-error');
            if (error.dueWithinDateTime) {
                delete error.dueWithinDateTime;
            }
            this.ui.$dueWithinTheDayTime.closest('.form-group').find('.help-block').empty();
            this.$('[data-validate="dueWithinDateTime"]').text('').closest('.has-error').removeClass('has-error');
            this.model.set('error', error);
        }
    },

    commonDueDateScreenFields(value) {
        const maskOptions = {
            alias: 'datetime',
            mask: 'h:s t\\m',
            hourFormat: '12',
            placeholder: '00:00 am',
        };
        this.ui.$dueOnTheDayTime.inputmask(maskOptions);
        this.ui.$dueWithinTheDayTime.inputmask(maskOptions);
        if (value === 'DUE_WITHIN') {
            this.$(this.ui.$dueOnRadio).prop('checked', false);
            this.$(this.ui.$dueWithinRadio).prop('checked', true);
            this.$(this.ui.$dueOnTheDaySelect).prop('disabled', true);
            this.$(this.ui.$dueOnTheDayTime).prop('disabled', true);
            this.$(this.ui.$dueWithinTheDay).prop('disabled', false);
            this.$(this.ui.$dueWithinTheDayTime).prop('disabled', false);
            if (this.model.get('type') === 'MONTHLY') {
                this.appBus.trigger('dueWithinMonthly:click');
            }
            this.$(this.ui.$dueOnRadio).parent().removeClass('required');
            if (this.model.validators) {
                this.model.removeValidator('dueOnTheDay', 'exists');
                this.model.removeValidator('dueOnDateTime', 'exists');
                // remove any errors
                clearError(this.$('[data-validate="dueOnTheDay"]'));
                clearError(this.$('[data-validate="dueOnDateTime"]'));
            }
            this.$(this.ui.$dueWithinRadio).parent().addClass('required');
            this.model.addValidator('dueWithin', { exists: true, description: locale.get('RFP.scheduler.due.within.days') });
            this.model.addValidator('dueWithinDateTime', { exists: true, description: locale.get('RFP.scheduler.due.within.time') });
            this.ui.$dueWithinTheDay.inputmask('numeric', {
                mask: '99',
                placeholder: '',
                showMaskOnHover: false,
                showMaskOnFocus: false,
                clearMaskOnLostFocus: true,
            });
        }
        if (value === 'DUE_ON') {
            this.$(this.ui.$dueOnRadio).prop('checked', true);
            this.$(this.ui.$dueWithinRadio).prop('checked', false);
            this.$(this.ui.$dueOnTheDaySelect).prop('disabled', false);
            this.$(this.ui.$dueOnTheDayTime).prop('disabled', false);
            this.$(this.ui.$dueWithinTheDay).prop('disabled', true);
            this.$(this.ui.$dueWithinTheDayTime).prop('disabled', true);
            this.appBus.trigger('dueOnDate:click');
            this.$(this.ui.$dueWithinRadio).parent().removeClass('required');
            if (this.model.validators) {
                this.model.removeValidator('dueWithin', 'exists');
                this.model.removeValidator('dueWithinDateTime', 'exists');
                // remove any errors
                clearError(this.$('[data-validate="dueWithin"]'));
                clearError(this.$('[data-validate="dueWithinDateTime"]'));
            }
            this.$(this.ui.$dueOnRadio).parent().addClass('required');
            this.model.addValidator('dueOnTheDay', { exists: true, description: locale.get('RFP.scheduler.due.on.day') });
            this.model.addValidator('dueOnDateTime', { exists: true, description: locale.get('RFP.scheduler.due.on.time') });
            if (this.model.get('dueOnTheDay') === undefined) {
                this.model.set('dueOnTheDay', '1');
            }
        }
    },

    processRecurrencePeriodChange() {
        if (this.model.get('type') === 'WEEKLY') {
            this.model.set('dueDateInputMode', 'DUE_WITHIN');
            this.$(this.ui.$dueOnRadio).prop('disabled', true);
        } else {
            this.model.set('dueDateInputMode', 'DUE_ON');
            this.$(this.ui.$dueOnRadio).prop('disabled', false);
        }

        this.commonDueDateScreenFields(this.model.get('dueDateInputMode'));
        // run this only if the user actually selected a new recurrence type
        if (this.model.hasChanged('type') && this.ui.$expiresOnDueDateCheckBox.prop('checked') === true) {
            this.ui.$expiresOnDueDateCheckBox.prop('checked', false);
        }
    },

    setExpiresOnDueDate() {
        if (this.ui.$expiresOnDueDateCheckBox.prop('checked') === true) {
            this.appBus.trigger('expiresOnDueDate:check', true);
        } else {
            this.appBus.trigger('expiresOnDueDate:check', false);
        }
    },

    /**
     * Gets the last two characters of a date.
     * e.g. "st" for "first" -> 1st
     * @param {Number} day
     * @return {string}
     */
    ending(day) {
        switch (day) {
        case 1:
        case 21:
        case 31:
            return 'st'; // first
        case 2:
        case 22:
            return 'nd'; // second
        case 3:
        case 23:
            return 'rd'; // third
        default:
            return 'th'; // fourth and everything else
        }
    },

});
