import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import PlanningView from 'app/cashflow/planning/planningLayout';
import GroupPlanView from 'app/cashflow/groupplan/groupPlanLayout';
import ContextApi from 'common/dynamicPages/api/context';
import http from '@glu/core/src/http';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import services from 'services';
import CashFlowForecastView from 'app/cashflow/chart/cashFlowChartLayout';
import ComparisonView from 'app/cashflow/compare/compareLayout';
import workspaceHelper from 'common/workspaces/api/helper';
import CategoryService from '../service/category';
import PlanCollection from '../collection/plan';
import GroupPlanCollection from '../collection/groupPlan';
import constants from '../api/constants';
import navigationTmpl from './navigation.hbs';

const Navigation = Layout.extend({
    className: 'page smb-cashflow',
    template: navigationTmpl,

    regions: {
        pageRegion: '.pageRegion',
        alertRegion: '.alert-region',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click #navigation-header a': 'updateView',
    },

    isInitialRender: true,

    initialize(options) {
        Layout.prototype.initialize.call(this, options);
        this.contextKey = 'cashflow_navigation';
        this.categoryService = new CategoryService();
        this.plans = null;
        this.groupPlans = null;
    },

    setWidget(widget) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    handleWidgetSizeChange() {
        if (this.hasLoadedRequiredData() && this.activeView.gridView) {
            util.defer(() => {
                this.activeView.gridView.grid.tableView.setWidth();
            });
        }
    },

    getPlans() {
        const self = this;
        return new Promise((resolve) => {
            const plans = new PlanCollection(
                {},
                {
                    categoryService: self.categoryService,
                },
            );
            plans.once('sync', resolve);
        });
    },

    getGroupPlans() {
        return new Promise((resolve) => {
            const groupPlans = new GroupPlanCollection();
            groupPlans.once('sync', resolve);
            groupPlans.fetch();
        });
    },

    getCFFEntitlements() {
        return new Promise((resolve) => {
            const context = ContextApi.menuContext.getContext('BOS_CASHFLOW');
            const data = {};
            const getEntitledActionsService = `${services.generateUrl(context.serviceName)}/getEntitlements`;

            http.post(
                getEntitledActionsService, data, (response) => {
                    resolve(response);
                },
                () => null,
            );
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            if (this.previousState) {
                this.openView(this.previousState);
            } else {
                this.showFirstEntitledView();
            }
            this.isInitialRender = false;
        } else {
            this.loadViewRequirements();
        }
    },

    handleSuccess(action, message) {
        this.openView(this.previousState);
        this.renderMessage(action, message);
    },

    handleError(action, message) {
        this.renderMessage(action, message);
        const regionLoc = this.$('.alert-region').offset();
        window.scrollTo(regionLoc.left, regionLoc.top);
    },

    updateView(e) {
        const href = this.$(e.currentTarget).attr('href');
        e.preventDefault();
        this.openView(href);
        this.changeTab(href);
    },

    openView(href) {
        if (this.alertRegion) {
            this.alertRegion.close();
        }

        if (this.activeView !== undefined && this.activeView !== null) {
            this.stopListening(this.activeView.gridView, 'rowAction', this.gridRowAction);
        }

        switch (href) {
        case '#forecast':
            this.activeView = new CashFlowForecastView({
                hasTransferEntitlement: this.hasTransferEntitlement,
            });
            break;
        case '#plan':
            this.activeView = new PlanningView({
                plans: this.plans,
                categoryservice: this.categoryService,
            });
            break;
        case '#groupplan':
            this.activeView = new GroupPlanView({
                groupPlans: this.groupPlans,
                readOnly: false,
                entitlements: this.entitlements,
            });
            break;
        case '#compare':
            this.activeView = new ComparisonView({
                plans: this.plans,
                categoryservice: this.service,
            });
            break;
        default:
            this.showFirstEntitledView();
            this.isInitialRender = false;
        }
        this.pageRegion.show(this.activeView);
        this.previousState = href;

        if (this.isInitialRender) {
            this.currentTabText = this.$(`a[href$=${href}]`).text();
            setTabButtonText(this, this.currentTabText);
        }
    },

    changeTab(e) {
        this.$(`a[href$=${e}]`).parent('li').addClass('is-active').siblings()
            .removeClass('is-active');

        this.currentTabText = this.$(`a[href$=${e}]`).text();
        toggleTabs(this);
    },

    templateHelpers() {
        const self = this;

        return {
            hasForecastEntitlement() {
                return true; // self.hasEntitlement(constants.FORECAST);
            },

            hasPlanningEntitlement() {
                return true; // self.hasEntitlement(constants.PLAN);
            },

            hasGroupPlanningEntitlement() {
                return self.hasEntitlement(constants.GROUPPLAN);
            },

            hasPlanCompareEntitlement() {
                return true; // self.hasEntitlement(constants.COMPARE);
            },
        };
    },

    loadViewRequirements() {
        const self = this;
        const entitlementPromise = this.getCFFEntitlements();
        const plans = this.getPlans();

        const groupPlans = entitlementPromise.then((hasEnt) => {
            self.entitlements = {};
            self.entitlements.actions = {};
            if (hasEnt && hasEnt.actionModes) {
                if (hasEnt.actionModes.indexOf('TRANSFER') > -1) {
                    self.hasTransferEntitlement = true;
                }
                if (hasEnt.actionModes.indexOf('CASHFGPL') > -1) {
                    self.entitlements.actions[constants.GROUPPLAN] = true;
                    return self.getGroupPlans();
                }
            }
            return false;
        });

        Promise.all([plans, groupPlans]).then((results) => {
            [self.plans] = results;
            if (results[1] !== false) {
                [, self.groupPlans] = results;
            }
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    hasEntitlement(action) {
        /*
         * CFF widget is entitled, and only GROUPPLAN tab is entitled through the
         * tenantconfig DB table as of 8.3.2.9.
         * The Account Transfer modal is entitled through the payment entitlement.
         */
        return (action !== constants.GROUPPLAN
            || (this.entitlements && this.entitlements.actions
                && this.entitlements.actions[action] === true));
    },

    showFirstEntitledView() {
        let href;
        if (this.hasEntitlement(constants.FORECAST)) {
            href = '#forecast';
        } else if (this.hasEntitlement(constants.PLAN)) {
            href = '#plan';
        } else if (this.hasEntitlement(constants.GROUPPLAN)) {
            href = '#groupplan';
        } else if (this.hasEntitlement(constants.COMPARE)) {
            href = '#compare';
        } else {
            href = '#forecast';
        }
        this.openView(href);
        if (this.isInitialRender) {
            this.currentTabText = this.$(`a[href$=${href}]`).text();
            setTabButtonText(this, this.currentTabText);
        }
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'SMBCASHFLOW',
    view: Navigation,
    options: {},
});

export default Navigation;
