import React from 'react';
import PropTypes from 'prop-types';
import {
    AustraliaFlag,
    BrazilFlag,
    CanadaFlag,
    ChinaFlag,
    SwitzerlandFlag,
    DenmarkFlag,
    EuropeFlag,
    HongKongFlag,
    JapanFlag,
    MexicoFlag,
    NorwayFlag,
    NewZealandFlag,
    SingaporeFlag,
    ThailandFlag,
    UnitedKingdomFlag,
    UnitedStatesFlag,
    SouthAfricaFlag,
    SwedenFlag,
    KuwaitFlag,
    CzechRepublicFlag,
    HungaryFlag,
    IsraelFlag,
    PolandFlag,
    TurkeyFlag,
    UnitedArabEmiratesFlag,
} from '@glu/icons-react';

const RenderFlag = ({ name, width }) => {
    let Flag;
    switch (name) {
    case 'AUD':
        Flag = AustraliaFlag;
        break;
    case 'BRL':
        Flag = BrazilFlag;
        break;
    case 'CAD':
        Flag = CanadaFlag;
        break;
    case 'CNH':
    case 'CNY':
        Flag = ChinaFlag;
        break;
    case 'CHF':
        Flag = SwitzerlandFlag;
        break;
    case 'DKK':
        Flag = DenmarkFlag;
        break;
    case 'EUR':
        Flag = EuropeFlag;
        break;
    case 'HKD':
        Flag = HongKongFlag;
        break;
    case 'JPY':
        Flag = JapanFlag;
        break;
    case 'MXN':
        Flag = MexicoFlag;
        break;
    case 'NOK':
        Flag = NorwayFlag;
        break;
    case 'NZD':
        Flag = NewZealandFlag;
        break;
    case 'SGD':
        Flag = SingaporeFlag;
        break;
    case 'THB':
        Flag = ThailandFlag;
        break;
    case 'GBP':
        Flag = UnitedKingdomFlag;
        break;
    case 'USD':
        Flag = UnitedStatesFlag;
        break;
    case 'ZAR':
        Flag = SouthAfricaFlag;
        break;
    case 'SEK':
        Flag = SwedenFlag;
        break;
    case 'KWD':
        Flag = KuwaitFlag;
        break;
    case 'AED':
        Flag = UnitedArabEmiratesFlag;
        break;
    case 'TRY':
        Flag = TurkeyFlag;
        break;
    case 'PLN':
        Flag = PolandFlag;
        break;
    case 'NIS':
        Flag = IsraelFlag;
        break;
    case 'HUF':
        Flag = HungaryFlag;
        break;
    case 'CZK':
        Flag = CzechRepublicFlag;
        break;
    default:
        return null;
    }
    return Flag ? <Flag width={width} data-testid={name} /> : null;
};

RenderFlag.defaultProps = {
    width: 25,
};

RenderFlag.propTypes = {
    name: PropTypes.string.isRequired,
    width: PropTypes.number,
};

export default RenderFlag;
