import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';
import { appendScript } from './util';

const TagManager = {
    serverConfigParamsReady() {
        const account = serverConfigParams.get('TealiumAccount');
        const profile = serverConfigParams.get('TealiumProfile');
        const environment = serverConfigParams.get('TealiumEnvironment');

        if (!account || !profile || !environment) {
            return;
        }
        appendScript(
            `//tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.js`,
            {
                async: true,
            },
        );
    },
    postAuth() {
        window.utag?.link?.({
            tealium_event: 'user_login',
            user_id: userInfo.get('id'),
            user_group: userInfo.get('group'),
        });
    },
};

export default TagManager;
