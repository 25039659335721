import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import util from '@glu/core/src/util';

const DraftModel = Model.extend({
    defaults: {
        TYPE: 'BENEBKCH',
        PAYMENTPRODUCT: 'RTGS',
        PAYMENTTYPE: 'DRAFT',
        CLEARINGSYSTEM: '*',
        TYPE_DESCRIPTION: 'RTGS.type.draft',
        BENE_REFERENCE_CHILD: null,
        BENE_ACCOUNTNUMBER: null,
        BENE_ACCOUNTTYPE: null,
        BENE_BANK_IDTYPE: null,
        BENE_BANK_NAME: null,
        BENE_BANK_ACCOUNT_NUMBER: null,
        BENE_BANK_ADDRESS_1: null,
        BENE_BANK_ADDRESS_2: null,
        BENE_BANK_ADDRESS_3: null,
        BENE_BANK_COUNTRY: null,
        BENE_BANK_STATE: null,
        BENE_POSTALCODE_CHILD: null,
        INTERMEDIARY_IDTYPE: null,
        INTERMEDIARY_ID: null,
        INTERMEDIARY_NAME: null,
        INTERMEDIARY_ADDRESS_1: null,
        INTERMEDIARY_ADDRESS_2: null,
        INTERMEDIARY_ADDRESS_3: null,
        INTERMEDIARY_COUNTRY: null,
        INTERMEDIARY_STATE: null,
        BENE_ACCOUNTTYPE_DESC: null,
        STATUS: null,
        STATUS_DESCRIPTION: null,
    },

    initialize(data, options) {
        this.contactModel = options.contactModel;

        this.lazyBindPropArr = [{
            src: 'BENE_ADDRESS_1',
            dest: 'BENE_ADDRESS_1_CHILD',
        }, {
            src: 'BENE_CITY',
            dest: 'BENE_ADDRESS_2_CHILD',
        }, {
            src: 'BENE_COUNTRY',
            dest: 'BENE_COUNTRY_CHILD',
        }];

        this.bindLazyProps(this.lazyBindPropArr, this.contactModel);

        this.listenTo(this, 'change:BENE_COUNTRY_CHILD', this.handleCountryChange);

        this.unset('PAYMENTTYPE_COLLECTION');

        this.validators = {
            BENE_ADDRESS_1_CHILD: {
                exists: true,
                description: locale.get('bab.address.1'),
            },

            BENE_ADDRESS_2_CHILD: {
                exists: true,
                description: locale.get('bab.city'),
            },

            BENE_COUNTRY_CHILD: {
                exists: true,
                description: locale.get('bab.country'),
            },

            BENE_ACCOUNT_CURRENCY: {
                exists: true,
                description: locale.get('bab.currency'),
            },
        };
    },

    handleCountryChange() {
        if ((!this.contactModel.has('BENE_COUNTRY') || this.contactModel.get('BENE_COUNTRY') === this.previousAttributes().BENE_COUNTRY_CHILD)) {
            this.contactModel.set('BENE_COUNTRY', this.get('BENE_COUNTRY_CHILD'));
        }
    },

    bindLazyProps(props, model) {
        const self = this;
        util.each(props, (prop) => {
            self.listenTo(model, `change:${prop.src}`, (changedModel, val, options) => {
                self.handlePrimaryChange.call(self, changedModel, val, prop, options);
            });
            self.lazySet(model, null, prop);
        });
    },

    handlePrimaryChange(model, val, prop) {
        this.lazySet(model, val, prop);
    },

    lazySet(model, val, prop) {
        if (!this.has(prop.dest) && this.get(prop.dest) !== '' && model.get(prop.src) !== '') {
            this.set(prop.dest, model.get(prop.src));
        } else if (this.get(prop.dest) === model.previousAttributes()[prop.src]) {
            this.set(prop.dest, model.get(prop.src));
        }
    },

    isEnabled() {
        return this.get('DRAFT_PAYMENT_ENABLED') === 'DRAFT';
    },

    isDraftCompliant() {
        const valid = this.validate();
        return valid !== 'invalid';
    },

    // Don't send checkbox value to server
    toJSON() {
        const obj = Model.prototype.toJSON.call(this);

        delete obj.DRAFT_PAYMENT_ENABLED;

        obj.BENE_ACCOUNTNUMBER = null;

        obj.BENE_BANK_ID = null;

        if (util.isArray(obj.PAYMENTTYPE)) {
            [obj.PAYMENTTYPE] = obj.PAYMENTTYPE;
        }

        return obj;
    },

    sync() {
        return true;
    },
});

export default DraftModel;
