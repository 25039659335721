import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import services from 'services';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import moment from 'moment';
import constants from 'common/dynamicPages/api/constants';
import TemplateAndGroupLookup from 'app/payments/views/quickEntry/templateGroupLookup';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import fxPaymentUtil from 'common/util/fxPaymentUtil';
import PaymentUtil from 'common/util/paymentUtil';
import { log } from '@glu/core';
import interestRateUtil from 'common/util/interestRateUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import template from './listView.hbs';

export default ItemView.extend({
    tagName: 'fieldset',
    className: 'payment-entry-item row',
    template,

    initialize() {
        this.showInterestRate = serverConfigParams.get('LookupLoanDrawInterestRate') === 'true';
        this.variableRateSubtypes = serverConfigParams.get('LookupLoanDrawInterestRateAcctSubTypes')?.split(',') || [];
        this.templateList = new Collection([]);
        this.processingDates = {
            blockedDates: [],
            processingDays: [],
            daysForward: [],
            cutOffTimes: [],
            daysBack: 0,
        };
        this.processingDates.daysForward[0] = 30;
        this.templateLookup = new TemplateAndGroupLookup();
    },

    ui: {
        $dateField: '[name="ENTRY_DATE"]',
        $amount: '.quick-entry-amount',
        $readonlyData: '.readonly-data',
        $templateField: '.template-field',
    },

    events: {
        'change @ui.$templateField': 'changeTemplateSelectHandler',
        'change @ui.$dateField': 'changeEntryDateHandler',
    },

    templateHelpers() {
        return {
            templateName: this.model.get('text'),
            id: this.cid,
        };
    },

    changeEntryDateHandler(e) {
        const model = this.model.get('TEMPLATE');
        if (model) {
            const product = model.get('productCode');
            const functionCode = 'INST';
            const typeCode = model.get('typeCode');
            const subType = model.get('subType');
            const effDate = e && e.target ? e.target.value : this.model.get('ENTRY_DATE');

            const typeInfo = {
                typeCode,
                productCode: product,
                functionCode,
            };

            const jsonData = {
                typeInfo,
            };

            if (product === 'USACH' || product === 'ACH') {
            // set it up for ACH call back to server
                model.set({
                    DEBIT_ACCOUNT_NUMBER: model.get('debitAccount'),
                    SAMEDAYACH: model.get('sameDayAch') ? model.get('sameDayAch') : 0,
                    BANKCODE: model.get('debitBankCode'),
                    ORIGCURRENCYCODE: model.get('debitCurrency'),
                    EFFECTIVEDATE: effDate,
                    RECEIVBANKCOUNTRYCODE: model.get('beneBankCountry'),
                    ORIGCOUNTRY: model.get('debitCountry'),
                    CREDIT_CURRENCY: model.get('creditCurrency'),
                    TEMPLATE_ID: model.get('id'),
                });

                PaymentUtil.updateCutoff(
                    product, functionCode, typeCode,
                    subType === undefined ? 'NACHA' : subType,
                    'INSERT', model, this.$('.ui-datepicker-trigger'),
                );
            } else {
            // set up for rtgs call
                model.jsonData = jsonData;
                model.set({
                    VALUE_DATE: effDate,
                    DEBIT_BANK_CODE: model.get('debitBankCode'),
                    DEBIT_ACCOUNT_NUMBER: model.get('debitAccount'),
                    DEBIT_COUNTRY: model.get('debitCountry'),
                    DEBIT_CURRENCY: model.get('debitCurrency'),
                    BENE_BANK_CODE: model.get(''),
                    BENE_BANK_COUNTRY: model.get('beneBankCountry'),
                    CREDIT_AMOUNT: model.get('creditAmount'),
                    DEBIT_AMOUNT: model.get('debitAmount'),
                    CREDIT_CURRENCY: model.get('creditCurrency'),
                    EXCHANGE_RATE: model.get('exchangeRate'),
                    EXCHANGE_RATE_CONTRACTID: model.get('exchangeRateContractId'),
                    BENE_BANK_ID: model.get('beneBankId'),
                    BENE_BANK_TYPE: model.get('beneBankType'),
                    PREADVISEWIRES: model.get('PREADVISEWIRES'),
                });
                fxFieldValidation.doFieldValidation(this, model, 'VALUE_DATE', 'INSERT').then(() => {
                    PaymentUtil.showCutoff(model.get('CUTOFF_INFO'), this.$('.ui-datepicker-trigger'), typeCode);
                });
            }
        }
    },

    changeTemplateSelectHandler(e) {
        if (e.val) {
            const model = this.templateList.get(e.val);

            if (model) {
                this.model.set({
                    TEMPLATE_ID: model.get('id'),
                    TEMPLATE: model,
                });
                this.handleScreenDisplay();
            }

            // Clear out collection contents.  No need to keep this hanging around.
            this.templateList.reset([]);
        }
    },

    renderTemplateControl() {
        const self = this;
        const lookup = this.templateLookup;

        this.ui.$templateField.comboBox({
            dropdownAutoWidth: true,
            maximumSelectionSize: 1,
            triggerChange: true,
            placeholder: locale.get('payment.templategroups.selectTemplateText'),

            initSelection($element, callback) {
                callback({
                    id: $element.val(),
                    text: $element.val(),
                });
            },

            query: util.debounce((query) => {
                /*
                 * TODO the collection should have fetch defined
                 * use the collection to request & store results
                 * use fetch's success handler to call the callback
                 */
                if (query.term.length < constants.COMBO_MIN_CHARS) {
                    lookup.setPageSize(constants.COMBO_MIN_SIZE);
                } else {
                    lookup.setPageSize(constants.MAX_SERVER_ROWSPERPAGE);
                }

                lookup.setSearch(query.term);
                lookup.send().then((result) => {
                    query.callback({
                        results: result,
                    });

                    const newTemplates = self.templateList.parse(result);
                    self.templateList.reset(newTemplates);
                });
            }, constants.COMBO_DEBOUNCE),
        });
    },

    onRender() {
        const self = this;
        this.renderTemplateControl();
        // eslint-disable-next-line
        this.model._datePickerValues = [];
        // eslint-disable-next-line
        this.model._datePickerValues.ENTRY_DATE = [];
        const dateOptions = {
            blockedDates: this.processingDates.blockedDates,
            processingDays: this.processingDates.processingDays,
            cutOffTimes: this.processingDates.cutOffTimes,
            showCalendarIcon: true,
            daysForward: this.processingDates.daysForward[0],
            daysBack: moment(new Date()).diff(moment(this.processingDates.earliestDay), 'days'),
        };
        const key = this.ui.$dateField.attr('name');
        this.ui.$dateField.nhDatePicker(dateOptions, (start, end) => {
            // eslint-disable-next-line
            self.model._datePickerValues[key] = [start, end];
        });

        // format the payment amount while typing
        this.ui.$amount.inputmask('number');

        // if a template is already selected, update display
        this.handleScreenDisplay();
    },

    removeBtn() {
        this.model.destroy();
    },

    isACH() {
        return !['RTGS', 'RTP'].includes(this.model.get('TEMPLATE').get('productCode'));
    },

    handleBusinessDaysResponse(result) {
        let earliestDate = result.earliestDay;
        earliestDate = moment(earliestDate, 'MM/DD/YYYY');
        earliestDate = earliestDate.format(userInfo.getDateFormat());

        let defaultDate = result.defaultDay;
        defaultDate = moment(defaultDate, 'MM/DD/YYYY');

        /*
         * NH-152833
         * If defaultdate is more than the max amount of forward days for ACH types
         * then we use the ACH max date for the defaultDate
         */
        if (this.isACH()) {
            const maxDateACH = moment(new Date()).add(result.maxForwardDays, 'days');
            defaultDate = defaultDate.isAfter(maxDateACH) ? maxDateACH : defaultDate;
        }

        defaultDate = defaultDate.format(userInfo.getDateFormat());

        this.processingDates.daysForward.shift();
        this.processingDates.daysForward.push(result.maxForwardDays);

        this.processingDates.processingDays.shift();
        this.processingDates.processingDays.push(result.businessDays);

        this.processingDates.cutOffTimes.shift();
        this.processingDates.cutOffTimes.push(result.cutoff);

        // display cutoff if returned and config allows
        if (result.cutoffDateTimeTz) {
            PaymentUtil.showCutoff(result.cutoffDateTimeTz, this.$('.ui-datepicker-trigger'), this.model.get('TEMPLATE').get('typeCode'));
        }

        // remove previous blocked dates
        this.processingDates.blockedDates.splice(0, this.processingDates.blockedDates.length);
        if (result.holidays.length > 0) {
            this.processingDates.blockedDates.push(...result.holidays);
        }

        if (this.ui.$dateField.length > 0) {
            this.ui.$dateField.data('daterangepicker').updateCalendars({
                blockedDates: this.processingDates.blockedDates,
                daysBack: (result.maxBackwardDays > 0
                    ? result.maxBackwardDays : (result.maxBackwardDays * -1)),
                defaultDay: defaultDate,
                earliestDay: this.model.get('TEMPLATE').get('subType') === 'CAEFT' ? '' : earliestDate,
                minDaysForward: result.minDaysForward,
                daysForward: this.processingDates.daysForward[0],
                isACH: this.isACH(),
            });

            this.model.set('ENTRY_DATE', defaultDate);
        }
    },

    /*
     * Set any readonly data we may have from the mapDataList
     */
    handleScreenDisplay() {
        const model = this.model.get('TEMPLATE');
        const self = this;
        if (!model || model.get('isTemplateGroup')) {
            return;
        }

        let subtitle = '';
        if (model.get('productCode') === 'RTGS') {
            subtitle = `${model.get('templateDescription')} ${model.get('debitAccountDisplay')} ${model.get('debitAccountName')}`;
        } else {
            subtitle = `${model.get('templateDescription')} ${model.get('debitAccountDisplay')} ${model.get('companyName')}`;
        }

        this.ui.$readonlyData.text(subtitle).removeClass('hide');

        /*
         * check if this transaction should be in USD only. If so, and the active template is not
         * a bank template, then set it as such
         */
        if (fxPaymentUtil.evaluateUSDOnly(model) && model.get('TEMPLATEENTRYMETHOD') !== '2') {
            model.set({
                creditCurrency: 'USD',
                debitCurrency: 'USD',
            });
        }

        /* NH-143756 - When the credit/debit flag is set we will set the on screen currency to the
         * selected currency. When the credit/debit flag is not set we will default to credit
         * currency if it exists and the debit currency as a last resort.
         */
        if (model.get('creditDebit').indexOf('credit') > -1) {
            this.$('.currency-field').val(model.get('creditCurrency'));
        } else if (model.get('creditDebit').indexOf('debit') > -1) {
            this.$('.currency-field').val(model.get('debitCurrency'));
        } else if (model.get('creditCurrency')) {
            this.$('.currency-field').val(model.get('creditCurrency'));
        } else {
            this.$('.currency-field').val(model.get('debitCurrency'));
        }

        this.model.set('AMOUNT', model.get('tranAmount'));

        const postData = {
            paymentType: model.get('typeCode'),
            debitBank: model.get('debitBankCode'),
            debitBankCountry: model.get('debitCountry'),
            creditCurrency: model.get('creditCurrency'),
        };

        if (model.get('typeCode') === 'USACH') {
            util.extend(
                postData,
                {
                    debitCurrency: model.get('origCurrencyCode'),
                    subType: model.get('subType'),
                },
            );
        } else {
            util.extend(
                postData,
                {
                    debitCurrency: model.get('debitCurrency'),
                    subType: model.get('subType') === '' ? '*' : model.get('subType'),
                    creditBankCountry: model.get('beneBankCountry'),
                    releaseLeadTime: model.get('releaseLeadTime') || '0',
                    beneBankId: model.get('beneBankId'),
                    beneBankType: model.get('beneBankType'),
                    preAdviseWires: model.get('PREADVISEWIRES'),
                },
            );
        }

        http.post(services.generateUrl('/date/list'), postData, util.bind(this.handleBusinessDaysResponse, this)).then(() => {
            // this will trigger a call to the update cutoff
            self.changeEntryDateHandler();
        });

        if (model.get('typeCode') === 'LOANDRAW' && this.showInterestRate
            && this.variableRateSubtypes?.includes(model.get('Account_SubType'))) {
            interestRateUtil.createPromiseToRetrieveInterestRate({
                productCode: model.get('productCode'),
                functionCode: model.get('FunctionCode'),
                typeCode: model.get('typeCode'),
                bankCode: model.get('debitBankCode'),
                accountNumber: model.get('debitAccount'),
                currency: model.get('debitCurrency'),
                subAccountNum: model.get('Debit_SubAccount_Num'),
                accountFilter: model.get('account_or_Company'),
            }).then(
                (response) => {
                    this.model.set(
                        'INTEREST_RATE',
                        (response.status === 'success' && !!response.interestRate)
                            ? response.interestRate : '',
                    );
                },
                (err) => {
                    this.model.set('INTEREST_RATE', '');
                    log.error(err);
                },
            );
        } else {
            this.model.set('INTEREST_RATE', '');
        }

        /*
         * handle locked field for amount - if CREDIT_AMOUNT is locked from template,
         * then lock the amount field in quick entry
         */
        let lockedFields = [];
        if (model.has('LOCKED_FIELDS')) {
            lockedFields = model.get('LOCKED_FIELDS').split(',');
        }
        lockedFields.forEach((fieldName) => {
            if (model.has('tranAmount') && (fieldName === 'CREDIT_AMOUNT' || fieldName === 'AMOUNT' || fieldName === 'DEBIT_AMOUNT')) {
                this.$('[name="AMOUNT"]').prop('readonly', true);
            }
        });
    },
});
