var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":37,"column":43},"end":{"line":37,"column":52}}}) : helper)))
    + "\" data-accountnumber=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNT_NUM") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_NUM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNT_NUM","hash":{},"data":data,"loc":{"start":{"line":37,"column":74},"end":{"line":37,"column":89}}}) : helper)))
    + "\" data-subaccountnumber=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"SUBACCOUNT_NUM") || (depth0 != null ? lookupProperty(depth0,"SUBACCOUNT_NUM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SUBACCOUNT_NUM","hash":{},"data":data,"loc":{"start":{"line":37,"column":114},"end":{"line":37,"column":132}}}) : helper)))
    + "\" data-accountfilter=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNT_FILTER") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_FILTER") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNT_FILTER","hash":{},"data":data,"loc":{"start":{"line":37,"column":154},"end":{"line":37,"column":172}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayValue") || (depth0 != null ? lookupProperty(depth0,"displayValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayValue","hash":{},"data":data,"loc":{"start":{"line":37,"column":174},"end":{"line":37,"column":190}}}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row\" data-hook=\"getInvoices\" data-region=\"pmtInvoicesRegion\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\" data-hook=\"getInvoiceAmounts\" data-region=\"pmtInvoiceAmountsRegion\"></div>\n            <div class=\"payment-options hide\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"paymentOptions") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":16},"end":{"line":134,"column":25}}})) != null ? stack1 : "")
    + "                <div class=\"row\">\n                    <div class=\"form-column col-md-6\">\n                        <div class=\"field-container field-container-lg\">\n                            <div class=\"addAnotherPaymentOptionLinkRegion\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"row\">\n                        <div class=\"form-column col-md-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onlyOption") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":94,"column":28},"end":{"line":131,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"field-container\">\n                                    <div class=\"form-group amount\">\n                                        <label for=\"credit-amount\">"
    + alias1(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + "<span data-hook=\"getAmountDue\"></span></label>\n                                        <div class=\"input-group\">\n                                            <input id=\"credit-amount\" type=\"text\" class=\"form-control credit-amount\" data-type=\"amount\" data-bind=\"model\" maxLength=\"21\" data-hook=\"getAmount\" name=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":99,"column":197},"end":{"line":99,"column":204}}}) : helper)))
    + "\">\n                                            <div class=\"input-group-addon\">\n                                                <input id=\"credit-currency\" readonly class=\"currency-field\" data-bind=\"model\" data-hook=\"getCurrency\" tabindex=\"-1\" maxLength=\"16\" name=\"CREDIT_CURRENCY\">\n                                            </div>\n                                        </div>\n                                        <span class=\"help-block\" data-bind=\"model\" data-validate=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (depth0 != null ? lookupProperty(depth0,"key") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":104,"column":98},"end":{"line":104,"column":105}}}) : helper)))
    + "\"></span>\n                                    </div>\n                                </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"multifield-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":108,"column":65},"end":{"line":108,"column":73}}}) : helper)))
    + " payment-option "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"firstOption") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":89},"end":{"line":108,"column":127}}})) != null ? stack1 : "")
    + "\">\n                                    <div class=\"form-column column-md-6 payment-amounts\">\n                                        <div class=\"form-group amount\">\n                                            <label for=\"amount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":111,"column":63},"end":{"line":111,"column":71}}}) : helper)))
    + "\">"
    + alias4(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"firstOption") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":97},"end":{"line":111,"column":161}}})) != null ? stack1 : "")
    + "</label>\n                                            <div class=\"input-group\">\n                                                <input id=\"amount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":113,"column":66},"end":{"line":113,"column":74}}}) : helper)))
    + "\" type=\"text\" class=\"form-control amount_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":113,"column":115},"end":{"line":113,"column":123}}}) : helper)))
    + "\" data-type=\"amount\" data-bind=\"model\" maxLength=\"16\" data-hook=\"getAmount\" value=\"\" name=\"AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":113,"column":221},"end":{"line":113,"column":229}}}) : helper)))
    + "\">\n                                                <div class=\"input-group-addon\">\n                                                    <input id=\"credit-currency\" readonly class=\"currency-field\" data-bind=\"model\" data-hook=\"getCurrency\" tabindex=\"-1\" maxLength=\"16\" name=\"CREDIT_CURRENCY\">\n                                                </div>\n                                            </div>\n                                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"AMOUNT_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":118,"column":109},"end":{"line":118,"column":117}}}) : helper)))
    + "\"></span>\n                                        </div>\n                                    </div>\n                                    <div class=\"form-column col-md-6 payment-types\">\n                                        <div class=\"form-group type\">\n                                            <label for=\"selection_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":123,"column":66},"end":{"line":123,"column":74}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"loans.applies.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":123,"column":76},"end":{"line":123,"column":105}}}))
    + "</label>\n                                            <select data-bind=\"model\" id=\"selection_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":124,"column":84},"end":{"line":124,"column":92}}}) : helper)))
    + "\" class=\"form-control applies-to\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":124,"column":137},"end":{"line":124,"column":145}}}) : helper)))
    + "\" name=\"SELECTION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":124,"column":163},"end":{"line":124,"column":171}}}) : helper)))
    + "\">\n                                            </select>\n                                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"SELECTION_"
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":126,"column":112},"end":{"line":126,"column":120}}}) : helper)))
    + "\"></span>\n                                        </div>\n                                    </div>\n                                    <span class=\"icon-close remove\" id=\"remove-option\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":129,"column":98},"end":{"line":129,"column":106}}}) : helper)))
    + "\"></span>\n                                </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "hide";
},"12":function(container,depth0,helpers,partials,data) {
    return "<span data-hook=\"getAmountDue\"></span>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row\">\n                <div class=\"form-column col-md-6\">\n                    <div class=\"field-container field-container-lg payment-options\">\n                        <label for=\"credit-amount\" class=\"required\">"
    + container.escapeExpression(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + "</label>\n                        <div class=\"form-group amount\">\n                            <div class=\"input-group\">\n                                <input id=\"credit-amount\" type=\"text\" class=\"form-control\" data-type=\"amount\" data-type=\"amount\" data-bind=\"model\" maxLength=\"16\" data-hook=\"getAmount\" name=\"CREDIT_AMOUNT\">\n                                <div class=\"input-group-addon\">\n                                    <input id=\"credit-currency\" readonly class=\"currency-field\" data-bind=\"model\" data-hook=\"getCurrency\" tabindex=\"-1\" maxLength=\"16\" name=\"CREDIT_CURRENCY\">\n                                </div>\n                            </div>\n                        </div>\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_AMOUNT\"></span>\n                    </div>\n                        </div>\n                    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div data-section=\"save-as-template\">\n                <div class=\"row\">\n                    <div class=\"form-column inline-fields\">\n                        <div id=\"SAVEASTEMPLATE\" class=\"align-checkbox field-container-lg field-container\">\n                            <input type=\"checkbox\" data-bind=\"model\" name=\"SAVEASTEMPLATE\" id=\"template-save\">\n                            <label class=\"checkbox-inline\" for=\"SAVEASTEMPLATE\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.save.as.template",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":202,"column":81},"end":{"line":202,"column":116}}}))
    + "</label>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"SAVEASTEMPLATE\"></span>\n                        </div>\n                        <div class=\"col-md-2 col-mdlg-4 field-container template-code-form required hide\">\n                            <label for=\"Template_Code\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":206,"column":55},"end":{"line":206,"column":84}}}))
    + "</label>\n                            <input class=\"form-control\" type=\"text\" maxlength=\"35\" data-bind=\"model\" id=\"\" name=\"TEMPLATE_CODE\" required>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEMPLATE_CODE\"></span>\n                        </div>\n                        <div class=\"col-md-2 col-mdlg-4  field-container template-descr-form required hide\">\n                            <label for=\"Template_Description\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":211,"column":62},"end":{"line":211,"column":98}}}))
    + "</label>\n                            <input class=\"form-control \" type=\"text\" maxlength=\"35\" data-bind=\"model\" id=\"\" name=\"TEMPLATE_DESCRIPTION\" required>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEMPLATE_DESCRIPTION\"></span>\n                        </div>\n                    </div>\n                    <div class=\"form-column inline-fields\">\n                        <div class=\"align-checkbox field-container-lg field-container restrict-check-form hide\">\n                            <input type=\"checkbox\" data-bind=\"model\" name=\"RESTRICTTEMPLATE_FLAG\" id=\"RESTRICTTEMPLATE_FLAG\">\n                            <label class=\"checkbox-inline\" for=\"RESTRICTTEMPLATE_FLAG\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.screentext.Restrict",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":219,"column":88},"end":{"line":219,"column":124}}}))
    + "</label>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"RESTRICTTEMPLATE_FLAG\"></span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":179}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"getTitle") || (depth0 != null ? lookupProperty(depth0,"getTitle") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getTitle","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":43}}}) : helper)))
    + "</h1>\n\n    <div class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.paymentTotal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":69}}}))
    + "</p>\n        <div class=\"amount-value-text payment-total\"><span data-hook=\"getSummaryAmountTotal\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":9,"column":93},"end":{"line":9,"column":116}}}))
    + "</span> <span data-hook=\"getCurrency\"></span></div>\n    </div>\n</div>\n<section class=\"section section-container\">\n  <div class=\"alert-message\">\n      <div id=\"alertRegion\" role=\"alert\"></div>\n  </div>\n  <div data-region=\"pmtMessageRegion\"></div>\n</section>\n\n<div class=\"corp-loans-page\" data-region=\"\">\n    <fieldset class=\"section section-container\">\n        <div class=\"section-header\">\n            <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.advice.transaction.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":60}}}))
    + "</legend>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-6 from-account \">\n                <div class=\"field-container field-container-lg lookup-container\">\n                    <label for=\"DEBIT_ACCOUNT_NUMBER\" class=\"required\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"originLabel") || (depth0 != null ? lookupProperty(depth0,"originLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"originLabel","hash":{},"data":data,"loc":{"start":{"line":28,"column":71},"end":{"line":28,"column":86}}}) : helper)))
    + "</label>\n                    <select\n                        id=\"DEBIT_ACCOUNT_NUMBER\"\n                        data-hook=\"getDebitAccountNumber\"\n                        class=\"form-control form-select\"\n                        name=\"DEBIT_ACCOUNT_NUMBER\"\n                        data-bind=\"model\">\n                        <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"debitAccounts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":38,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <button class=\"icon-search btn-tertiary btn\" data-hook=\"lookup-single\" data-field=\"DEBIT_ACCOUNT_NUMBER\" name=\"DEBIT_ACCOUNT_NUMBER\"></button>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_ACCOUNT_NUMBER\"></span>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"DEBIT_CURRENCY\"></span>\n                </div>\n                <div class=\"text-group field-container field-container-lg field-info\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-6 to-account\">\n                <div class=\"field-container field-container-lg lookup-container\">\n                    <label for=\"BENE_ACCOUNT\" class=\"required\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"destinationLabel") || (depth0 != null ? lookupProperty(depth0,"destinationLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"destinationLabel","hash":{},"data":data,"loc":{"start":{"line":52,"column":63},"end":{"line":52,"column":83}}}) : helper)))
    + "</label>\n                    <select id=\"BENE_ACCOUNT\" class=\"form-control form-select\" data-bind=\"model\" name=\"BENE_ACCOUNT\">\n                        <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditAccounts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":57,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                    <button class=\"icon-search btn-tertiary btn\" data-hook=\"lookup-single\" data-field=\"BENE_ACCOUNT\" name=\"BENE_ACCOUNT\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ACCOUNT\"></span>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_CURRENCY\"></span>\n                </div>\n                <div class=\"text-group field-container field-container-lg field-info\">\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-10\">\n                <div class=\"field-container field-container-lg amounts-due\"></div>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleOptions") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":8},"end":{"line":76,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"date-picker field-container field-container-sm payment-date\">\n                    <label for=\"VALUE_DATE\" class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.valueDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":61},"end":{"line":81,"column":90}}}))
    + " <div data-hook=\"getDueDate\" class=\"loan-due-date\"></div></label>\n                    <input name=\"VALUE_DATE\" type=\"text\" size=\"20\" class=\"input-date form-control\" data-bind=\"model\" autocomplete=\"off\" id=\"VALUE_DATE\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALUE_DATE\"></span>\n                </div>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleOptions") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":88,"column":8},"end":{"line":160,"column":15}}})) != null ? stack1 : "")
    + "         <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container field-container-lg\">\n                    <label for=\"customer-reference\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.customerref",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":164,"column":52},"end":{"line":164,"column":82}}}))
    + "</label>\n                    <input id=\"customer-reference\" type=\"text\" name=\"CUSTOMER_REFERENCE\" data-bind=\"model\" class=\"form-control\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CUSTOMER_REFERENCE\"></span>\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                <div class=\"field-container field-container-lg payment-comments\">\n                    <label for=\"special-instructions\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Comments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":173,"column":54},"end":{"line":173,"column":79}}}))
    + "</label>\n                    <textarea id=\"special-instructions\" type=\"text\" class=\"form-control textarea-field\" rows=3 data-bind=\"model\" name=\"SPECIAL_INSTRUCTIONS\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"SPECIAL_INSTRUCTIONS\"></span>\n                </div>\n                <div class=\"field-container field-container-lg\">\n                    <span class=\"textline-field\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.InternalMemoMessage",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":178,"column":49},"end":{"line":178,"column":85}}}))
    + "</span>\n                </div>\n                    </div>\n        </div>\n    </fieldset>\n\n    <div class=\"section section-summary\">\n        <div class=\"section-header\" data-section=\"payment-summary\">\n            <h3 class=\"total-heading\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.payment.summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":186,"column":38},"end":{"line":186,"column":72}}}))
    + "</h3>\n            <h4>\n                <span data-hook=\"getSummaryAmountTotal\" class=\"summary-large-text\">0.00</span>\n                <span data-hook=\"getCurrency\"></span>\n                <span data-hook=\"getSummaryTo\" class=\"hide\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.summary.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":190,"column":61},"end":{"line":190,"column":92}}}))
    + " </span>\n                <span data-hook=\"getSummaryBene\"></span>\n                <span data-hook=\"getSummaryOn\" class=\"hide\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.on",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":192,"column":61},"end":{"line":192,"column":84}}}))
    + " </span>\n                <span data-hook=\"getSummaryDate\"></span>\n            </h4>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInsertTemplateEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":8},"end":{"line":225,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"section-body\">\n            <div class=\"widget-action-btn-group\">\n                <button type=\"button\" class=\"btn btn-success submit\" data-action=\"submit\" aria-label=\"Submit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":228,"column":110},"end":{"line":228,"column":136}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"saveDraft\" aria-label=\"Save Draft\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"buttons.saveForLater",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":229,"column":112},"end":{"line":229,"column":145}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":230,"column":84},"end":{"line":230,"column":110}}}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});