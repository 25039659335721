import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    getEntityName() {
        return 'BankAccount';
    },

    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.BANK;
    },

    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.BANK_CF;
    },

    /**
     * Return the account type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.ACCOUNT;
    },

    getRecords() {
        return gridDataAccess.getAllRecords(this.model);
    },
});
