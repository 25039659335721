import ItemView from '@glu/core/src/itemView';
import template from './multiFieldElement.hbs';

export default ItemView.extend({
    template,

    className: 'MultiField-element',

    ui: {
        $multiElement: '[data-hook="getMultiElement"]',
        $deleteElement: '[data-hook="getDeleteBtn"]',
    },

    events: {
        'click @ui $deleteElement': 'deleteElement',
        'blur @ui $multiElement': 'updateModelValues',
    },

    initialize(options) {
        this.options = options;
        this.fieldOptions = options.fieldOptions;

        this.listenTo(this.model.collection, 'add remove', () => {
            // if this model is the first in the collection
            this.showHideDeleteBtn();
        });

        if (options.modelValidators) {
            Object.keys(options.modelValidators).forEach((key) => {
                this.model.addValidator(key, options.modelValidators[key]);
            });
        }
    },

    onRender() {
        // run only if this is the first model in the collection
        if (this.model.collection.at(0) === this.model) {
            this.showHideDeleteBtn();
        }

        // set input mask if exists in model
        if (this.fieldOptions.inputMask) {
            this.ui.$multiElement.inputmask(this.fieldOptions.inputMask);
        }

        this.setModelValue();
    },

    /**
     * Check if collection has only one model in it and hide it's delet button
     * becasue we don't want to allow the user to delete all models in collection
     */
    showHideDeleteBtn() {
        if (this.model.collection.at(0) === this.model) {
            // and the length of the collection is less than 2
            if (this.model.collection.length < 2) {
                // then hide the delete button
                this.ui.$deleteElement.hide();
            } else {
                this.ui.$deleteElement.show();
            }
        }
    },

    /**
     * Remove element from collection by destroying it
     */
    deleteElement() {
        this.model.destroy();
    },

    /**
     * Can't use bind in this case so we need to update the model values
     * manually when the user leaves the field
     * @return {[type]} [description]
     */
    updateModelValues() {
        this.setModelValue();
        this.model.validateField(this.fieldOptions.fieldName);
        this.fieldOptions.onBlur?.(this.model, this.options);
    },

    /**
     * Set the model with the value from the input based on fieldName
     */
    setModelValue() {
        this.model.set(this.options.fieldOptions.fieldName, this.ui.$multiElement.val());
    },

    templateHelpers() {
        return {
            fieldType: this.fieldOptions.fieldType,
            fieldPlaceholder: this.fieldOptions.fieldPlaceholder,
            fieldLabel: this.fieldOptions.fieldLabel,
            fieldName: this.fieldOptions.fieldName,
            fieldId: `${this.fieldOptions.fieldLabel.replace(' ', '_')}_${this.model.cid}`,
            fieldClass: this.fieldOptions.fieldClass,
            required: this.fieldOptions.required,
            timeZone: this.fieldOptions.timeZone,
        };
    },

});
