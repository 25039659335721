import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import workspaceHelper from 'common/workspaces/api/helper';
import Entitlements from 'common/dynamicPages/api/entitlements';
import store from 'system/utilities/cache';
import systemConfig from 'system/configuration';
import tmpl from './rfpMainView.hbs';
import AccordionLayout from './accordionLayoutBase';
import RequestGrid from './rfpGridView';

const RequestForPayment = AccordionLayout.extend({
    template: tmpl,

    ui: util.extend({}, AccordionLayout.prototype.ui, {
        $insertBtn: '[data-hook="insertBtn"]',
        $insertTmplBtn: '[data-hook="insertTemplateBtn"]',
    }),
    /*
     * add back in in 3.9.1
     *  $rfpSubmittedCount: '[data-hook="getRfpSubmittedCount"]',
     *  $rfpReceivedCount: '[data-hook="getRfpReceivedCount"]',
     *  $rfpSubmittedBubble: '[data-hook="getRfpSubmittedChatBubble"]',
     *  $rfpReceivedBubble: '[data-hook="getRfpReceivedChatBubble"]',
     * }),
     */

    initialize(options) {
        AccordionLayout.prototype.initialize.call(this, {
            storeName: 'rfp-accordionStates',
            singleAccordionOpen: options.singleAccordionOpen,
        });
        this.storeAccordionStates(options.openAccordion, true);
        this.notificationKeys = [];
        this.rfpReceivedCount = 0;
        this.rfpSubmittedCount = 4;
        this.gridData = this.getGridData(options);
        // TODO this.listenTo(this.appBus, 'grid:refreshed', this.updateNotifications);
        this.listenTo(this.appBus, 'stackView:pop', this.onStackViewPop);
    },

    /**
     * Check regions for views containing the showConfirmMessage function
     * and call it when returning from a stack
     */
    onStackViewPop() {
        /*
         * NH-155321 Currently only the rfpSubmitted grid uses a stack view.
         * Add other regions to the list if they use stack views too.
         */
        const stackViewRegions = ['rfpSubmittedRegion'];
        if (this.$el.is(':visible')) {
            stackViewRegions.forEach((region) => {
                if (this[region]?.currentView?.showConfirmMessage) {
                    this[region].currentView.showConfirmMessage();
                }
            });
        }
    },

    /**
     * Get grid data for all the grids in the accordions
     * @param {Object} options
     * @returns {Object}
     */
    getGridData(options) {
        return {
            sentRequestGridData: {
                menuCategory: 'PMTS',
                serviceName: '/requestForPayment/outgoingPaymentRequests',
                context: 'RFP',
                menuContext: constants.URL_RTP_REQUEST_SUBMIT_MULTI_ACTION,
                enableSavedViews: true,
                configContext: {
                    productCode: 'RTP',
                    functionCode: 'REQUEST',
                    typeCode: 'REQOUT',
                    subType: '*',
                    actionMode: 'SELECT',
                },
                returnRoute: options.returnRoute,
                inquiryId: systemConfig.isAdmin() ? constants.INQUIRY_ID_28601 : null,
                summaryInquiryId: systemConfig.isAdmin() ? constants.INQUIRY_ID_28601 : null,
                gridOptions: { caption: locale.get('rfp.heading.submitted') },
            },
            receivedRequestGridData: {
                menuCategory: 'PMTS',
                serviceName: '/requestForPayment/requestReceived',
                context: 'RFP',
                enableSavedViews: true,
                configContext: {
                    productCode: 'RTP',
                    functionCode: 'REQUEST',
                    typeCode: 'REQIN',
                    subType: '*',
                    actionMode: 'SELECT',
                },
                viewId: options.receivedRFPViewId,
                selector: 'none',
                gridOptions: { caption: locale.get('rfp.heading.received') },
            },
            templateRequestGridData: {
                menuCategory: 'PMTS',
                serviceName: '/requestTemplate/outgoingPaymentRequestTemplates',
                context: 'RFP',
                menuContext: constants.URL_RTP_REQUEST_SUBMIT_MULTI_ACTION,
                enableSavedViews: true,
                configContext: {
                    productCode: 'RTP',
                    functionCode: 'REQTMPL',
                    typeCode: 'REQOUT',
                    subType: '*',
                    actionMode: 'SELECT',
                },
                returnRoute: options.returnRoute,
                inquiryId: systemConfig.isAdmin() ? constants.INQUIRY_ID_28602 : null,
                summaryInquiryId: systemConfig.isAdmin() ? constants.INQUIRY_ID_28602 : null,
                gridOptions: { caption: locale.get('rfp.heading.templates') },
            },
        };
    },

    events: {
        'click @ui.$insertBtn': 'insert',
        'click @ui.$insertTmplBtn': 'insertTemplate',
    },

    onRender/* istanbul ignore next */() {
        if (this.hasLoadedRequiredData()) {
            this.setupToggle();
            /*
             * this.setupPoller();
             * TODO put this in notification handler
             * TODO add back in for 3.9.1
             * this.ui.$rfpSubmittedBubble.toggleClass('unreadBig', this.rfpSubmittedCount > 0);
             * this.ui.$rfpReceivedBubble.toggleClass('unreadBig', this.rfpReceivedCount > 0);
             * end TODO
             */

            this.setStateOfAccordions();
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * @name setStateOfAccordions
     * @description opens the accordions that need to be openSetAccordions
     * based on what its state and
     * if we came from copy as request from a template,
     * then we need to open the submitted accordion
     */
    setStateOfAccordions() {
        const toOpen = store.get('returnAccordionId');
        if (toOpen) {
            this.storeAccordionStates(toOpen, true);
            store.remove('returnAccordionId');
        }

        this.openSetAccordions();
    },

    /**
     * Return a promise for the gridView
     * @param {Object} gridView
     * @returns {Promise}
     */
    getViewEntitlements(gridView) {
        if (gridView) {
            return Entitlements.getEntitlements(gridView.viewOptionRequirements());
        }
        return Promise.resolve();
    },

    /**
     * Retrieve entitlements data and create views
     */
    loadRequiredData/* istanbul ignore next */() {
        // create views and check entitlements
        this.views = this.createViews();

        // get Entitlements
        const entitlements = this.views.map(view => this.getViewEntitlements(view));

        Promise.all(entitlements)
            .then(([sub, rec = {}, template = {}]) => {
                this.submittedEntitlements = sub.actions;
                this.subEntitlement = sub.actions.SELECT;
                // Currently admin is not able to view recevied or templates
                this.recEntitlement = rec.actions ? rec.actions.SELECT : false;
                this.templateEntitlement = template.actions ? template.actions.SELECT : false;
                this.hasRFPInsert = sub.actions.INSERT;
                this.hasRFPTemplateInsert = template.actions ? template.actions.INSERT : false;
                this.setHasLoadedRequiredData(true);
                this.render();
            });
    },

    /**
     * @description Event handler for accordions
     * @param {object} e - the mouse event
     */
    handleOpen/* istanbul ignore next */(e) {
        let opts;
        const $el = this.$(e.currentTarget);

        // get active tab id
        const accordionId = $el.data('accordionid');
        if (accordionId === 'rfpSubmitted' && !this.rfpSubmittedRegion.currentView) {
            opts = {
                paymentsOpts: this.gridData.sentRequestGridData,
                gridView: this.views[0],
                currentRegion: this.rfpSubmittedRegion,
            };

            this.showGrid(opts);
        } else if (accordionId === 'rfpReceived' && !this.rfpReceivedRegion.currentView) {
            opts = {
                paymentsOpts: this.gridData.receivedRequestGridData,
                gridView: this.views[1],
                currentRegion: this.rfpReceivedRegion,
            };
            opts.paymentsOpts.returnRoute = this.options.returnRoute;
            this.showGrid(opts);
        } else if (accordionId === 'rfpTemplates' && !this.rfpTemplateRegion.currentView) {
            opts = {
                paymentsOpts: this.gridData.templateRequestGridData,
                gridView: this.views[2],
                currentRegion: this.rfpTemplateRegion,
            };

            this.showGrid(opts);
        }

        this.storeAccordionStates(accordionId, true);
    },

    showGrid(opts) {
        const { gridView, currentRegion } = opts;
        if (currentRegion) {
            currentRegion.show(gridView);
        }
    },

    createViews() {
        const sentRequestGridView = this.createGrid(this.gridData.sentRequestGridData);
        const receivedRequestGridView = this.createGrid(this.gridData.receivedRequestGridData);
        const templateRequestGridView = this.createGrid(this.gridData.templateRequestGridData);

        return [
            sentRequestGridView,
            receivedRequestGridView,
            templateRequestGridView,
        ];
    },

    createGrid(gridData) {
        return new RequestGrid(gridData);
    },

    insert() {
        this.storeAccordionStates('rfpSubmitted', true);
        if (this.options.returnRoute) {
            store.set('current-workspace-route', this.options.returnRoute);
        }
        this.navigateTo('PAYMENTS/requestForPayment');
    },

    insertTemplate() {
        this.storeAccordionStates('rfpTemplates', true);
        if (this.options.returnRoute) {
            store.set('current-workspace-route', this.options.returnRoute);
        }
        this.navigateTo('TEMPLATES/requestForPaymentTemplate');
    },

    templateHelpers() {
        return {
            rfpSubmittedCount: this.rfpSubmittedCount,
            rfpReceivedCount: this.rfpReceivedCount,
            submittedEntitlement: this.subEntitlement,
            receivedEntitlement: this.recEntitlement,
            templateEntitlement: this.templateEntitlement,
            canInsertRFP: this.hasRFPInsert,
            canInsertRFPTemplate: this.hasRFPTemplateInsert,
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'RFP',
    view: RequestForPayment,
    options: {},
});

export default RequestForPayment;
