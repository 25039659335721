import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import smbConstants from 'app/smbPayments/constants';
import empConstants from 'app/smbPayments/views/employees/api/constants';
import store from 'system/utilities/cache';
import gridApi from 'common/dynamicPages/api/grid';
import ListView from 'common/dynamicPages/views/workflow/list';
import entitlements from 'common/dynamicPages/api/entitlements';
import ContextApi from 'common/dynamicPages/api/context';
import ContextModel from 'common/dynamicPages/models/context';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import PaymentTypeCollection from 'app/smbPayments/collections/paymentTypes';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import payPrintExportUtil from 'common/util/payPrintExportUtil';
import dialog from '@glu/dialog';
import PayUtil from 'common/util/paymentUtil';
import mobileUtil from 'mobile/util/mobileUtil';
import template from './employeeGrid.hbs';

function filterDate(field, value, model) {
    const d = moment(model.get(field));
    const valObj = moment(value);
    return d.isAfter(valObj);
}

const EmployeeManagementList = ListView.extend(util.extend({}, payPrintExportUtil, {
    template,

    events: {
        'click [data-hook="reimburseInsert"]': 'reimburseInsert',
        'click [data-hook="payrollInsert"]': 'payrollInsert',
        'click [data-hook="childSupportInsert"]': 'childSupportInsert',
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'showPrintOptionsModal',
        'click [data-hook="fileimport-button"]': 'fileImport',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(options) {
        this.context = options;
        const superOptions = empConstants.options;
        superOptions.enableSavedViews = true;
        superOptions.smbEmployee = true;
        superOptions.serviceName = this.context.serviceName;
        superOptions.context = this.context.context;
        this.isPaymentView = options.isPaymentView;

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);

            if (store.get('batch_Payroll-alertMessage')) {
                store.set(`${this.contextKey}-alertMessage`, store.get('batch_Payroll-alertMessage'));
            }

            if (store.get('batch_Payroll-confirms')) {
                const batchConfirm = store.get('batch_Payroll-confirms');
                util.findWhere(
                    batchConfirm.confirms.confirmResults[0].confirmData[0].item,
                    {
                        name: 'Payment Type:',
                    },
                ).value = 'Expense Reimbursement';
                store.set(`${this.contextKey}-confirms`, batchConfirm);
            }

            if (store.get('payment_listView_smbEmployee-confirms')) {
                const paymentConfirm = store.get('payment_listView_smbEmployee-confirms');
                store.set(`${this.contextKey}-confirms`, paymentConfirm);
            }

            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set('batch_Payroll-alertMessage', null);
            store.set('batch_Payroll-confirms', null);
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
            this.updateRefreshTimestamp();
        } else {
            this.loadViewRequirements();
        }
    },

    addDateFilter(days, label, type) {
        const date = moment(new Date()).subtract(days, 'day');
        const dateStr = date.format(userInfo.getDateFormat());

        this.gridView.grid.filterProc.addFilter({
            value: dateStr,
            label,
            field: 'CMB_VALUE_DATE',
            type,
            filter: util.bind(filterDate, this, 'CMB_VALUE_DATE', dateStr),
        });
    },

    getOverrideContext(serviceName) {
        return util.extend(
            this.contextDef,
            {
                serviceName,
                subType: 'NACHA',
                enableSaveDraft: true,
                smbOverride: true,
                serviceNameTypeCode: 'BDACHP',
            },
        );
    },

    reimburseInsert() {
        return this.insertReimburseOverride();
    },

    payrollInsert() {
        const overrideContext = this.getOverrideContext('/batch/Payroll');
        this.insertOverride(overrideContext);
        return Promise.resolve();
    },

    childSupportInsert() {
        const overrideContext = this.getOverrideContext('/batch/ChildSupportPayments');
        this.insertOverride(overrideContext);
        return Promise.resolve();
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: 'smbPayments.employee.center',
        });
        dialog.custom(printModal);
    },

    insertReimburseOverride() {
        const paymentsContextModel = new ContextModel({
            menuCategory: 'PMTS',
            serviceName: 'payments/smbEmployee',
            serviceFunc: null,
            businessType: null,
            context: 'SMB_EMPLOYEE',
            contextDef: this.contextDef,
            returnRoute: 'PAYMENTS/smbManageEmployees',
            smbOverride: true,
            reimburse: true,
        });
        const overrideContext = this.getOverrideContext('/batch/Payroll');
        overrideContext.reimburse = true;
        store.set('payments_smbEmployee-contextOverride', overrideContext);
        store.set(`${paymentsContextModel.getContextKey()}-listRoute`, '/PAYMENTS/smbManageEmployees');
        this.navigateTo('payments/addReimburseExpenses');
        return Promise.resolve();
    },

    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        if (this.isReimbursement(options.model)) {
            this.navigateTo('payments/viewReimburseExpenses');
        } else {
            this.navigateTo('payments/viewEmpPayment');
        }
        return Promise.resolve();
    },

    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        if (this.isReimbursement(options.model)) {
            this.navigateTo('payments/modifyReimburseExpenses');
        } else {
            this.navigateTo('payments/modifyEmpPayment');
        }
        return Promise.resolve();
    },

    /**
     * Handle the restore action from the employee grid
     * @param {Object} options - object containing the options needed to create the payment
     */
    gridRowRestore(options) {
        let restoreContext = {
            serviceName: options.model.context.serviceName,
            context: 'SMB_TEMP_EMPLOYEE',
        };
        if (this.isReimbursement(options.model)) {
            restoreContext = {
                ...restoreContext,
                smbOverride: true,
                reimburse: true,
            };
        }
        store.set(
            'smb_restore_context',
            restoreContext,
        );
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('TEMPLATES/restoreTemplate');
        return Promise.resolve();
    },

    /**
     * Identifiy if this model is for a reimbursement
     * @param {Model} model - model to evaluate
     */
    isReimbursement(model) {
        return model.get('ENTRYDESC') === 'REIMBURSE' || model.get('CMB_TYPE_DESCRIPTION') === 'Expense Reimbursement';
    },

    // NH-27619  Override to force REIMBURSE info on payment

    gridRowDelete(options) {
        return new Promise((resolve, reject) => {
            const self = this;

            // adding options.wait = true, will wait for server response before removal
            options.model.destroy({
                wait: 'true',

                success(model, resp) {
                    self.gridView.refreshGridData();

                    if (options.model.get('ENTRYDESC') === 'Expense Reimbursement') {
                        const paymentType = util.findWhere(
                            resp.confirms.confirmResults[0].confirmData[0].item,
                            {
                                name: 'Payment Type:',
                            },
                        );
                        paymentType.value = options.model.get('ENTRYDESC');
                    }
                    self.renderMessage(options.action, resp);
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.refreshGridData();
                    self.renderMessage(options.action);
                    reject(e);
                },
            });
        });
    },

    // End of NH-27619

    /**
     * Handle the Copy As Payment action from the employee grid
     * @param {Object} options - object containing the options needed to create the payment
     */
    gridPaymentFromTemplate(options) {
        const isReimburse = PayUtil.isReimburse(options.model);
        const serviceName = options.model.context.serviceName.replace('/batchTemplate/', '/batch/');
        const contextModel = {
            functionCode: options.model.get('FUNCTION'),
            serviceName,
            context: 'SMB_EMPLOYEE',
            subType: options.model.get('SUBTYPE'),
            tnum: options.model.get('TNUM'),
            loadFromPayments: this.isPaymentView,
            updateCount: options.model.get('UPDATECOUNT__'),
            mode: 'insert',
            templateServiceName: serviceName,
            returnTabStoreName: 'employee_smb-currentTab',
            returnTabStoreValue: 'paymentTab',
            smbOverride: isReimburse,
            reimburse: isReimburse,
        };
        store.set('payment_listView_smbEmployee-contextOverride', contextModel);
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('templates/copyEmpAsPayment');
        return Promise.resolve();
    },

    /**
     * Handle the Copy As Template action from the employee grid
     * @param {Object} optionsParam - object containing the options needed to create the payment
     */
    gridTemplateFromPayment(optionsParam) {
        const isReimburse = PayUtil.isReimburse(optionsParam.model);
        const serviceName = optionsParam.model.context.serviceName.replace('/batch/', '/batchTemplate/');
        const contextModel = {
            functionCode: optionsParam.model.get('FUNCTION'),
            serviceName,
            context: 'SMB_TEMP_EMPLOYEE',
            subType: optionsParam.model.get('SUBTYPE'),
            tnum: optionsParam.model.get('TNUM'),
            loadFromPayments: true,
            updateCount: optionsParam.model.get('UPDATECOUNT__'),
            mode: 'insert',
            templateServiceName: serviceName,
            returnTabStoreName: 'employee_smb-currentTab',
            returnTabStoreValue: 'templateTab',
            reimburse: isReimburse,
            smbOverride: isReimburse,
        };
        store.set('template_listView_smbEmployee-contextOverride', contextModel);
        store.set(`${this.contextKey}-actionModel`, optionsParam.model);
        this.navigateTo('templates/copyEmployeePaymentAsTemplate');
        return Promise.resolve();
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                return locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
            },

            context() {
                return self.contextKey;
            },

            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT) && self.isPaymentView;
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasRejectEntitlement() {
                return self.hasEntitlement(constants.ACTION_REJECT);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasBulkActionEntitlements() {
                // return true if the user is entitled to perform bulk actions on grid rows
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || self.hasEntitlement(constants.ACTION_REJECT)
                    || self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasPayrollPaymentEntitlement() {
                return self.hasPayrollEntitlements();
            },

            hasChildSupportEntitlement() {
                return self.hasCSPEntitlements();
            },
        };
    },

    loadViewRequirements() {
        const options = {
            // default grid action buttons are hidden
            context: ContextApi.menuContext.getContext(this.context.context),

            // instead of executing context actions, grid handles callback
            hideGridActionButtons: true,

            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,

            customFormatters: [{
                fieldName: 'CMB_TRANSACTION_AMOUNT',

                format(value) {
                    return `${value}`;
                },
            }],

            loadedCallback: () => {
                this.gridLoaded = true;
            },

            lvc: this.lvc,
        };
        this.gridView = gridApi.createServiceGridView(options);
        const entitlementPromise = entitlements.getEntitlements(options);
        const paymentTypePromise = this.paymentTypePromise();
        ListView.prototype.setupGridAvailableListener.call(this);

        return Promise.all([entitlementPromise, paymentTypePromise]).then((results) => {
            const entResActions = results[0].actions;
            [, this.paymentTypes] = results;
            this.entitlements = entResActions;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
                this.listenForGridErrors();
                this.setHasLoadedRequiredData(true);
                this.render();
            }

            return this.entitlements;
        });
    },

    paymentTypePromise() {
        return new Promise((resolve, reject) => {
            const paymentTypes = new PaymentTypeCollection({
                functionCode: this.isPaymentView ? 'INST' : 'TMPL',
                inquiryId: this.isPaymentView ? 20011 : 20095,
            }, {
                base: this.isPaymentView ? 'employees' : 'employeeTemplates',
            });
            paymentTypes.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    hasPayrollEntitlements() {
        let hasPermission = false;
        util.each(this.paymentTypes.models, (model) => {
            if (model.get('typeCode') === smbConstants.PAYROLL) {
                hasPermission = true;
            }
        });
        if (this.isPaymentView && hasPermission) {
            return hasPermission;
        }
        return false;
    },

    hasCSPEntitlements() {
        let hasPermission = false;
        util.each(this.paymentTypes.models, (model) => {
            if (model.get('typeCode') === smbConstants.CHILDSUPPORT) {
                hasPermission = true;
            }
        });
        if (this.isPaymentView && hasPermission) {
            return hasPermission;
        }
        return false;
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        const inquiryId = this.isPaymentView
            ? constants.INQUIRY_ID_20106 : constants.INQUIRY_ID_20599;
        return {
            inquiryId,
            summaryInquiryId: inquiryId,
        };
    },

    /**
     * Get action data for this list
     * @param {View} gridView for the list
     * @returns {Object}
     */
    getActionData(gridView) {
        const actionData = ListView.prototype.getActionData.call(this, gridView);
        if (this.isPaymentView) {
            /*
             * HACK The metadata for employee payment grid expects 'PAY' as
             * product code. Once SMB uses proper metadata, this whole function
             * to override the productCode won't be needed.
             */
            return {
                ...actionData,
                productCode: 'PAY',
                functionCode: 'INST',
            };
        }
        return actionData;
    },

    /**
     * Pull the key list from the gridView and return an array of search fields
     * based on the row data
     * @returns {[{fieldName: string, dataType: string, fieldValue: string[], operator: string}]}
     */
    addAdditionalSearchFields() {
        return [{
            fieldName: 'TYPE',
            operator: 'IN',
            fieldValue: ['BDACHP', 'BDACHCSP'],
            dataType: 'text',
        }];
    },

    /**
     * Customized export method to show the options dialog based on the row data.
     */
    export() {
        this.exportPayListView();
    },

}));

export default EmployeeManagementList;
