export default () => ({
    tabs: {
        '& > div': {
            alignItems: 'flex-end',
        },
    },
    tabTitleWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    tabCheckbox: {
        '& svg': {
            fill: '#659a3b',
            height: '24px',
            width: '24px',
        },
    },
});
