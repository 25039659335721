/**
 * The Digital Banking application is now leveraging a root React component on the page
 * and controlling the showing of components, both React and Marionette, in the main page region.
 *
 * Currently, DGB uses host.js from Glu for application startup. Host.js uses baseLayout.js to
 * overwrite the main Glu part of the DOM on startup. This temporary adapter serves to replace
 * baseLayout.js entirely and enable the continued use of host.js until DGB is able to move away.
 *
 * The adapter basically mimics the baseLayout.js API used by host.js: a constructor call followed
 * by a render call to do its work.  This is NOT a view or Marionette or React pattern here.  It is
 * simply some code to bridge from a host.js world to a Root.jsx react world.
 */
import Glu from '@glu/core/src/glu';
import util from 'underscore';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import dialog from '@glu/dialog';
import { appBus, log } from '@glu/core';
// TODO - New repo/dependency?
import Poll from '@glu/polling';
import userInfo from 'etc/userInfo';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'app/root/Root';
import configuration from 'system/configuration';
import UserInitiatedPasswordReset from 'app/utilities/views/resetPassword';
import scroll from 'common/util/scroll';

const ONE_MINUTE_MS = 60000;
const FIVE_MINUTES_MS = 300000;

// host.js does a new BaseLayout() so we make a constructor here for it to call and will make a fake render() method too
function BaseLayout(options) {
    this.options = options;

    this.LoginView = options.loginView;
    this.LiveMaintenanceUserView = options.liveMaintenance ? options.liveMaintenanceUserView : '';
    this.emailResetPassword = options.emailResetPassword || false;
    this.notAuthorised = options.notAuthorised || [];

    this.onUnauthorized = this.onUnauthorized.bind(this);
    this.monitorSessionExpiry = this.monitorSessionExpiry.bind(this);
    this.showNavigationAndFooter = this.showNavigationAndFooter.bind(this);

    this.attachAppBusEvents();
    this.handleUnexpectedErrors();
}

BaseLayout.prototype = {
    attachAppBusEvents() {
        const serverErrorHandler = this.options.serverErrorHandler || function () {
            dialog.alert(locale.get('anErrorOccurredMsg'), locale.get('anErrorOccurredTitle'));
        };

        appBus.on('http:401', this.onUnauthorized);
        appBus.on('http:5XX', serverErrorHandler);
        appBus.on('http:request', this.monitorSessionExpiry);
        appBus.on('router:reload', this.showNavigationAndFooter);
        appBus.on('router:navigate', scroll.scrollToFirstErrorOnce.bind(scroll));
    },

    // this is just to keep the API contract with host.js so we don't have to replace anything with it at the moment
    // reminder: read the note at the top. this is NOT a view from a backbone/marionette or react perspective
    render() {
        const isAuthenticatedRoute = !util.some(this.notAuthorised, regex =>
            // TODO: replace window.location.href with function which does the same as history.fragment.
            window.location.href.match(regex) );

        const isAuthenticated = this.options.user.isAuthenticated && isAuthenticatedRoute;

        if (isAuthenticated) {
            return this.renderReactRoot(isAuthenticated, false);
        }

        // If not authorized, then show login page immediately
        this.onUnauthorized();
    },

    renderReactRoot(showHeader, showFooter) {
        ReactDOM.render(React.createElement(Root, {
            showHeader,
            showFooter,
            NavView: this.options.navView,
            FooterView: this.options.footerView,
        }, null), document.getElementById('root'));
    },

    showNavigationAndFooter() {
        this.renderReactRoot(true, false);
    },

    /*
     * TODO this was an override from DGB's version on top of the Glu one
     *  Remove this when the Glu version creates the title and message after login
     *  so locale of user is known.
     */
    handleUnexpectedErrors() {
        let duringError = false;

        const clientErrorHandler = this.options.clientErrorHandler || function (err) {
            log.debug(err.stack ? err.stack : err);
            if (duringError) {
                console.log('error loop');
                return;
            }
            duringError = true;
            appBus.once('glu:modal:close', () => {
                duringError = false;
                console.log('exiting error');
            });
            dialog.alert(locale.get('anErrorOccurredMsg'), locale.get('anErrorOccurredTitle'));
        };

        window.onerror = clientErrorHandler;
    },

    // TODO this was an override from DGB's version on top of the glu one
    onUnauthorized() {
        // check if the url belongs to the 'safe list', before stopping the page load
        const url = window.location.href;

        if (util.indexOf(this.notAuthorised, url) > -1) {
            return;
        }
        // This stops further 401s/HTTP activity being handled superfluously
        Glu.history.stop();

        this.renderReactRoot(false, false);

        appBus.trigger('session.clear-expiry-alert');

        // DB3: adding check for user login, if so then wipe the userInfo object and
        // refresh the page so that we lose the branding inserts on the index html
        if (userInfo.isLoggedIn()) {
            userInfo.logout();
            if (configuration.isAdmin()) {
                window.close();
            } else {
                location.reload();
            }
        } else if (this.options.liveMaintenance) {
            /**
             * If live maintenance is active then show the live maintenance
             * landing page instead of the login view
             */
            appBus.trigger('show:page', new this.LiveMaintenanceUserView(this.options));
        } else if (this.emailResetPassword) {
            appBus.trigger('show:page', new UserInitiatedPasswordReset(this.emailResetPassword));
        } else {
            // This could also be a redirect to a login page outside of the app
            appBus.trigger('show:page', new this.LoginView(this.options));
        }
    },

    monitorSessionExpiry(url) {
        // Only activate this functionality if a ping URL is provided
        if (!this.options.sessionExpiryPingUrl) {
            return;
        }

        // If the request URL is the ping URL don't queue up another poll, otherwise
        // we'll end up in a recursive mess
        if (url === this.options.sessionExpiryPingUrl) {
            return;
        }

        // Reset the timer, unless this was invoked by ping URL itself
        if (this.inactiveHttpTimer && (url !== this.options.sessionExpiryPingUrl)) {
            window.clearTimeout(this.inactiveHttpTimer);
            appBus.trigger('session.clear-expiry-alert');
        }

        if (this.sessionExpiryPing) {
            this.sessionExpiryPing.dispose();
        }

        // Only ping if there is an active session
        if (!Glu.History.started) {
            return;
        }

        // After 5 minutes without HTTP activity, begin pinging the server for a warning about impending session expiry
        this.inactiveHttpTimer = window.setTimeout(() => {
            // Only ping if there is an active session
            if (!Glu.History.started) {
                return;
            }

            this.sessionExpiryPing = new Poll({
                iterator(poll, stop) {
                    // TODO Fix this up, its PT-X specific
                    http.get(this.options.sessionExpiryPingUrl, (result) => {
                        if (result === 'NEARING_EXPIRY') {
                            appBus.trigger('session.near-expiry');
                        }
                        poll(ONE_MINUTE_MS);
                    }, null, {
                        silent: true,
                    });
                },
                immediateStart: true,
            });
        }, FIVE_MINUTES_MS);
    },
};

export default BaseLayout;
