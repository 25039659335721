import EntryView from 'common/dynamicPages/views/workflow/entry';
import paymentsTransitionFieldsTmpl from './paymentsTransitionFields.hbs';

export default EntryView.extend({
    template: paymentsTransitionFieldsTmpl,

    initialize() {
        const contextDef = {
            productCode: 'ADMPAY',
            functionCode: 'INST',
            typeCode: 'PAYMENTS',
            subType: this.options.transitionName,
            serviceName: 'transitionsMaint',
        };
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'transitionsMaint',
            serviceFunc: null,
            businessType: null,
            mode: 'insert',
            state: 'insert',
            contextDef,
        };

        EntryView.prototype.initialize.call(this, options);
    },
});
