import React from 'react';
import PropTypes from 'prop-types';

const HeadlineContent = ({ headlineText, headlineContent }) => (
    <div className="headline-content">
        <h1>{headlineText}</h1>
        {headlineContent || ''}
    </div>
);

HeadlineContent.propTypes = {
    headlineText: PropTypes.string,
    headlineContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

HeadlineContent.defaultProps = {
    headlineText: '',
    headlineContent: '',
};

export default HeadlineContent;
