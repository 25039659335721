var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"checkbox\">\n        <input type=\"checkbox\" id=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":6,"column":56},"end":{"line":6,"column":63}}}) : helper)))
    + "\" class=\"select-all-perms\">\n        <label for=\"selectAllPermissions-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":48}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"uce.assignAll",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":50},"end":{"line":7,"column":76}}}))
    + "</label>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<h3 class=\"report-group-heading\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"alertGroup") || (depth0 != null ? lookupProperty(depth0,"alertGroup") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"alertGroup","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":47}}}) : helper)))
    + "</h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n\n<div class=\"col-4\">\n    <fieldset>\n        <legend class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.entitledAlerts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":63}}}))
    + "</legend>\n        <div data-region=\"entitlementsRegion\"></div>\n    </fieldset>\n</div>\n\n<hr>";
},"useData":true});