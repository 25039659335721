import { Button, TERTIARY } from '@glu/buttons-react';

export default {
  getComponent(action) {
    if (action.component) {
      return action.component;
    }
    return action.href ? 'a' : Button;
  },

  getProps(action, props) {
    if (action.component) {
      return {};
    }

    if (action.href) {
      return {
        href: action.href
      };
    }
    return {
      onClick: () => {
        action.handler(props);
      },
      variant: TERTIARY
    };
  }
};
