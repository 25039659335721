var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item\"\n                    role=\"presentation\">\n                    <a aria-controls=\"imports\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        id=\"imports\"\n                        role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"CM.ImportedFiles",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":35},"end":{"line":20,"column":64}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <ul id=\"stop-payments-tablist\" class=\"NavTabs nav nav-tabs\"\n            data-hook=\"getNavTabs\"\n            role=\"tablist\">\n            <li class=\"NavTabs-item is-active\"\n                role=\"presentation\">\n                <a aria-controls=\"individual\"\n                    class=\"NavTabs-link btn btn-tertiary\"\n                    id=\"individual\"\n                    role=\"tab\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"cm.stopCancels_title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":64}}}))
    + "\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasImportResultsEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n        <div class=\"pageRegion checkIssueTabs\"></div>\n    </div>\n</div>\n";
},"useData":true});