import ComboboxWidget from 'common/uiWidgets/comboboxWidget/comboboxWidget';
import ListBuilder from 'common/listBuilder/listBuilder';
import Collection from '@glu/core/src/collection';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import services from 'services';
import MultiSelectComboboxWidgetItem from './multiSelectComboboxWidgetItem';
import multiSelectComboboxWidgetTmpl from './multiSelectComboboxWidget.hbs';

export default ComboboxWidget.extend({
    template: multiSelectComboboxWidgetTmpl,

    initialize(options) {
        ComboboxWidget.prototype.initialize.call(this, options);

        /*
         * used to store a "master" list of all accounts in a situation where not all accounts
         * are available at page load
         */
        this.completeListOfAccounts = [];

        /*
         * 'temp' collections are used to store results of the listbuidler and discarded
         * if the user elects to 'cancel' instead of 'save'
         */
        this.tempUnselectedAccounts = new Collection();
        this.tempSelectedAccounts = new Collection();
        this.unselectedAccounts = new Collection();
        this.selectedAccounts = new Collection();
    },

    /**
     * Runs after data has been loaded, sets up list builder
     * @param data {object} - the data for the combobox/list builder
     */
    onDataLoadComplete(data) {
        const onDataLoadCompletePromise = ComboboxWidget.prototype
            .onDataLoadComplete.call(this, data);

        // populate the selected/unselected collections appropriately
        this.populateInitialCollections();

        this.listBuilder = new ListBuilder({
            matchType: 'exact',
            className: 'list-builder dgb-list-builder',
            dataPersist: true,
            id: `multiaddComboboxListBuilder-${this.model.cid}`,
            labelView: MultiSelectComboboxWidgetItem,
            sourceCollection: this.tempUnselectedAccounts,
            targetCollection: this.tempSelectedAccounts,

            saveHandler: () => {
                this.selectedAccounts.reset(this.tempSelectedAccounts.models);
                this.unselectedAccounts.reset(this.tempUnselectedAccounts.models);
                this.render();
                this.storeSelectedAccounts();
            },

            localeKeys: {
                cancel: 'common.cancel',
                clearSelection: 'RTGS.ClearSelection',
                moveAllItems: 'RTGS.MoveAll',
                moveSelectedItems: 'RTGS.MoveSelected',
                removeSelectedItems: 'RTGS.RemoveSelected',
                save: 'common.save',
                sourceListHeader: 'RTGS.Accounts',
                targetListHeader: 'RTGS.AssignedAccounts',
                title: 'RTGS.AddAccounts',
            },

            queryDetails: {
                queryCriteria: {
                    action: {
                        actionMode: this.model.context.actionMode,
                        functionCode: this.model.context?.actionData?.functionCode
                        || this.model?.context?.functionCode,
                        productCode: this.model.context?.actionData?.productCode || 'RTGS',
                        typeCode: this.model.context?.actionData?.typeCode || 'INTL',

                    },
                    tnum: this.model.get('TNUM'),
                    allowDuplicates: false,
                    entryClass: '',
                    fieldName: this.fieldName,
                    subTypeCode: this.model.context.subType,
                    customFilters: [{
                        filterName: 'Depends',
                        filterParam: ['USERGROUP', ''],
                    }],
                },
                path: services.generateUrl('tableMaintenance/getQueryResults'),
            },

            formatData: (allData) => {
                // ensure we locally remember the complete list of accounts
                this.completeListOfAccounts = allData.queryResponse.QueryData.queryRows;
                this.populateInitialCollections();
                return allData.queryResponse.QueryData.queryRows;
            },
        });
        return onDataLoadCompletePromise;
    },

    // data-action bound to run when the 'edit accounts' button is clicked
    editAccounts() {
        // ensure the temp collections remain up to date in case they're incorrect
        this.tempUnselectedAccounts.reset(this.unselectedAccounts.models);
        this.tempSelectedAccounts.reset(this.selectedAccounts.models);
        dialog.open(this.listBuilder);
    },

    // functionally identical to templateHelpers
    serializeData() {
        const isLocked = ComboboxWidget.prototype.isLocked.call(this, this.fieldName, this.model);
        return {
            ...ComboboxWidget.prototype.serializeData.call(this),
            isLocked,
            selectedAccounts: this.selectedAccounts.models.map(model => model.get('label')),
            fieldLabel: isLocked ? this.fieldInfo.fieldLabel : locale.get('RTGS.defaultAccount'),
        };
    },

    /**
     * Correctly updates the lock icon state upon when the user clicks it
     * @param  {Event} e query click handler event
     */
    toggleLock(e) {
        ComboboxWidget.prototype.toggleLock.call(this, e);
        /*
         * if the field is being locked, clear out selected accounts and return the
         * component to its default state
         */
        if (e.currentTarget.classList.contains('icon-unlock')) {
            this.populateInitialCollections();
        } else if (e.currentTarget.classList.contains('icon-lock')) {
            this.model.unset('ACCOUNTRESTRICTIONS');
            this.preSelectedAccounts = null;
        }
        this.render();
    },

    /*
     * helps store restricted accounts on the model in the expected format prior to
     * form submission
     */
    storeSelectedAccounts() {
        let accountData;
        const accountRestrictions = this.selectedAccounts.models.map((selectedAccount) => {
            accountData = transform.pairsToHash(selectedAccount.get('mapDataList'), 'toField', 'value');
            return {
                BANKCODE: accountData.DEBIT_BANK_CODE,
                ACCOUNTNUM: accountData.ORIGINATOR_ID,
            };
        });

        this.model.set('ACCOUNTRESTRICTIONS', JSON.stringify(accountRestrictions), { silent: true });
    },

    /*
     * resets the component to its default state including accounting for data sets loaded
     * after page load. And any existing pre-selected accounts
     */
    populateInitialCollections() {
        // empty any existing account lists
        this.unselectedAccounts.reset();
        this.selectedAccounts.reset();

        // if the accounts loaded at page-load are not the complete list
        if (this.completeListOfAccounts.length) {
            this.unselectedAccounts.reset(this.completeListOfAccounts);
        } else {
            this.unselectedAccounts.reset(this.comboData);
        }

        // if there are pre-selected accounts, assign them to their correct list
        if (this.preSelectedAccounts) {
            this.removePreselectedAccountsFromSourceCollection();
        }

        // populate the temporary collection with the appropriate accounts
        this.tempUnselectedAccounts.reset(this.unselectedAccounts.models);
    },

    removePreselectedAccountsFromSourceCollection() {
        let accountData;
        // lookup the pre-selected accounts from the data provided at page load
        // eslint-disable-next-line
        const retrievedAccountsFromData = this.preSelectedAccounts
            .map(preSelectedAccount => this.unselectedAccounts.models.find((account) => {
                accountData = transform.pairsToHash(account.get('mapDataList'), 'toField', 'value');
                return (preSelectedAccount.ACCOUNTNUM === accountData.ORIGINATOR_ID
                        && preSelectedAccount.BANKCODE === accountData.DEBIT_BANK_CODE);
            }))
            .filter(entry => entry);

        /*
         * go about removing the pre-selected accounts from the unselectedAccounts collection
         * and add them to the selectedAccounts collection
         */
        this.selectedAccounts.add(this.unselectedAccounts.remove(retrievedAccountsFromData));

        // visually update the selected accounts list
        this.render();
    },
});
