export default ({
    FRAUDMENUCATEGORY: 'RISK',
    ACHAUTHRULES_SERVICENAME: 'payment/ACHAuthRules',
    ACHAUTHRULES_CONTEXT: 'ACH_AUTH_RULES',
    ACHAUTHRULES_RETURNROUTE: 'RISK/achAuthRules',
    ACHAUTHRULES_CONTEXT_SERVICENAME: 'payment/ACHAuthRules',
    ACHAUTHRULES_VIEW_ROUTE: 'RISK/achAuthRulesView',
    ACHAUTHRULES_MODIFY_ROUTE: 'RISK/achAuthRulesModify',
    ACHAUTHRULES_INSERT_ROUTE: 'RISK/achAuthRulesAdd',
    ACHAUTHRULES_PAYANDCREATE_ROUTE: 'RISK/achAuthRulesControl',
    ACHAUTHRULES_PRODUCTCODE: 'RISK',
    ACHAUTHRULES_FUNCTIONCODE: 'INST',
    ACHAUTHRULES_TYPECODE: 'EBMPPRUL',
});
