import { createUseStyles } from '@glu/theming';

const styles = {
    enter: {
        position: 'relative',
        right: -550,
    },
    enterActive: {
        right: 0,
        transition: 'right .3s',
    },
    exit: {
        position: 'relative',
        right: 0,
    },
    exitActive: {
        opacity: 0,
        right: -550,
        transition: 'right .3s, opacity .2s',
    },
    root: {
        bottom: 0,
        float: 'right',
        position: 'sticky',
        width: 550,
        zIndex: 250, // gird `.sticky-footer` z-index is 250
    },
    deepLink: {
        position: 'absolute',
        right: 0,
        zIndex: 250,
    },
};

export default createUseStyles(styles);
