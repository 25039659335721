import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import template from './typeToggle.hbs';

export default Layout.extend({
    template,
    attributes: {
        role: 'typeToggle',
    },
    className: 'type-toggle-outer',
    ui: {
        $toggleBtn: '[data-hook="getToggleBtn"]',
        $stateBtnToggle: 'input[name="stateToggle"]',
    },
    initialize(options) {
        this.options = options;
        this.setupView();
    },


    /**
     * Accept toggle button click event
     * @param {Event|Element} e
     */
    startToggle(e) {
        const $el = (e instanceof Event) ? $(e.currentTarget) : $(e);
        const { value } = $el[0].domNode;
        const description = $el[0].domNode.textContent || value;

        // removing checked property from all and adding it to the current button
        this.ui.$stateBtnToggle.prop('checked', false);
        $el[0].domNode.checked = true;

        // trigger toggle event
        this.triggerEvent(value, description);
    },
    /**
     * Tell view that type toggle change just happened
     * @return {[type]} [description]
     */
    triggerEvent(value, description) {
        this.appBus.trigger('event:stateToggle', {
            value,
            description,
        });
    },
    setupView() {
        // listen for update event to set active toggle
        this.listenTo(this.appBus, 'event:updateStateToggle', (value) => {
            this.setActiveToggle(value);
        });
    },

    setActiveToggle(info) {
        const value = info.newType;
        const { stopPropagation } = info;
        const $activeButton = this.$(`input[value="${value}"]`);
        const $activeLabel = this.$(`label[for="${value}"]`).text();

        // uncheck  and remove focus from both toggle checkboxes
        this.ui.$toggleBtn.prop('checked', false);
        this.ui.$stateBtnToggle.attr('tabindex', '-1');
        this.ui.$stateBtnToggle.attr('aria-checked', 'false');

        // find input with value attribute and make sure it is checked
        $activeButton.prop('checked', true);
        $activeButton.attr('tabindex', '0');
        $activeButton.attr('aria-checked', 'true');

        /*
         * To handle any of the buttons clicked on OkCancelDialog
         * Based on the 'info.newType', we are setting the active button inside radioGroup
         */
        info.radioGroup?.setActiveButton?.($activeButton);

        if (!stopPropagation) {
            // trigger event that toggle state has changed
            this.appBus.trigger('event:stateToggle', {
                value,
                description: $activeLabel,
            });
        }
    },
    templateHelpers() {
        return {
            toggleDescription: this.options.toggleDescription,
            separatorText: this.options.separatorText,
            toggleBtns: this.options.toggleBtns,
        };
    },
});
