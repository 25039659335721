// API file for aggregating bsl for the public glu npm module API
import collection from './collection';
import principalsCollection from './principalsCollection';
import queryApi from './queryApi';

export default {
  collection,
  principalsCollection,
  queryApi
};
