import ItemView from '@glu/core/src/itemView';
import fieldNameTmpl from './fieldName.hbs';

export default ItemView.extend({
    template: fieldNameTmpl,

    initialize(options) {
        this.options = options;
    },

    /**
     * Determine if field is mandatory by checking if:
     * - Model MANDATORY attribute is set to 1
     * - mode is INSERT or EDIT
     * @return {boolean} Is field mandatory based on valuesc
     */
    isMandatoryField() {
        return (this.options.mode === 'INSERT' || this.options.mode === 'MODIFY' || this.options.mode === 'EDIT') && this.options.model.get('MANDATORY') === '1';
    },

    templateHelpers() {
        return {
            MANDATORY: this.isMandatoryField(),
        };
    },
});
