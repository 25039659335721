var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"field-container\">\n    <label for=\"COMMENT\" >"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gridAction.popup.comment",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":63}}}))
    + "</label>\n    <input class=\"rcount form-control\" type=\"text\" maxlength=\"255\" data-bind=\"model\" id=\"COMMENT\" name=\"COMMENT\" value=\"\">\n    <span class=\"textline-field pull-right\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.charactersRemaining",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":83}}}))
    + "255</span> \n</div>";
},"useData":true});