import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import moment from 'moment-timezone';
import inquiryService from 'app/payments/views/passThrough/inquiry';
import transform from 'common/util/transform';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import BatchInfoGrid from 'app/payments/views/passThrough/passthroughListView';
import AuditInfo from 'app/payments/views/passThrough/auditInfo';
import format from '@glu/core/src/templateHelpers';
import Model from '@glu/core/src/model';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import passthroughDetailTmpl from './passthroughDetail.hbs';

const PassThroughDetails = Layout.extend({
    template: passthroughDetailTmpl,

    initialize(options) {
        this.model = options.model || new Model();
        this.isModal = options.isModal || false;
        this.inquiryId = options.inquiryId;
        this.paymentModel = store.get('passthru_view_payment_model');
        this.contextKey = 'payment_listView_corp';
        this.fileSummary = new Model();
        inquiryService.send(this.paymentModel.get('TNUM'))
            .then(this.handleFileSummarySuccess.bind(this));
        this.buttonList = [];
        if (this.paymentModel && this.paymentModel.buttons) {
            for (let i = 0; i < this.paymentModel.buttons.length; i += 1) {
                const button = this.paymentModel.buttons[i];
                if (button.action !== 'SELECT') {
                    this.buttonList.push({
                        action: button.action,
                    });
                }
            }
        }
        this.buttonList.push({
            action: 'PRINT',
        });
    },

    ui: {
        $fileBatchCount: '[data-hook="ptBatchCount"]',
        $totalAmtDebit: '[data-hook="ptTotalAmtDebit"]',
        $totalNumDebit: '[data-hook="ptTotalNumDebit"]',
        $totalAmtCredit: '[data-hook="ptTotalAmtCredit"]',
        $totalNumCredit: '[data-hook="ptTotalNumCredit"]',
    },

    setCounter() {
        this.ui.$fileBatchCount.html(this.fileSummary.get('BATCHCOUNT'));
        this.ui.$totalAmtDebit.html(this.fileSummary.get('TOTALAMTDEBIT'));
        this.ui.$totalNumDebit.html(this.fileSummary.get('TOTALNUMDEBIT'));
        this.ui.$totalAmtCredit.html(this.fileSummary.get('TOTALAMTCREDIT'));
        this.ui.$totalNumCredit.html(this.fileSummary.get('TOTALNUMCREDIT'));
    },

    regions: {
        batchInfo: '#batchInfo',
        auditInfo: '#auditInfo',
    },

    handleFileSummarySuccess(response) {
        this.fileSummary.set(transform.pairsToHash(response[0].mapDataList, 'toField', 'value'));
        if (this.fileSummary.get('STATUS_DESCRIPTION')) {
            this.fileSummary.set('STATUS', locale.get(this.fileSummary.get('STATUS_DESCRIPTION')));
        } else if (this.fileSummary.get('STATUS')) {
            this.fileSummary.set('STATUS', locale.get(this.fileSummary.get('STATUS')));
        }
        this.displayCorrectTime('IMPORTEDON');
        this.displayCorrectTime('UNAPPROVEDON');
        this.displayCorrectTime('APPROVEDON');
        this.displayCorrectTime('CUTOFF_TIME');
        if (this.fileSummary.get('TOTALAMTCREDIT')) {
            this.fileSummary.set('TOTALAMTCREDIT', format.amount(this.fileSummary.get('TOTALAMTCREDIT')));
        }
        if (this.fileSummary.get('TOTALAMTDEBIT')) {
            this.fileSummary.set('TOTALAMTDEBIT', format.amount(this.fileSummary.get('TOTALAMTDEBIT')));
        }

        const immedOrig = this.fileSummary.get('IMMEDORIGNAME_INBOUND');
        if (!util.isNullOrUndefined(immedOrig)) {
            this.fileSummary.set('IMMEDORIGNAME_INBOUND', util.unescape(immedOrig));
        }

        this.render();
        this.setCounter();
    },

    allGridsLoadComplete() {
        if (store.get('batchInfoGridLoaded') === true) {
            store.set('batchInfoGridLoaded', false);
        }
    },

    /**
     * @method displayCorrectTime
     * @param {string} fieldName - FieldName that the timestamp
     * - Method called to convert the GMT time store in Database to the user
     * timezone and format.
     * - this reset the fieldname with user adjusted timezone and format
     */
    displayCorrectTime(fieldName) {
        if (this.fileSummary.get(fieldName)) {
            const importOnDate = moment.tz(this.fileSummary.get(fieldName), 'GMT');
            this.fileSummary.set(fieldName, importOnDate.tz(userInfo.get('timezone')).format(userInfo.getDateTimeFormat()));
        }
    },

    onRender() {
        this.listenTo(this, 'bothGridsLoaded', this.allGridsLoadComplete);
        const options = {
            parent: this,
            isModal: this.isModal,
            model: this.paymentModel,
            inquiryId: this.inquiryId,
        };

        this.batchInfo.show(new BatchInfoGrid(options));
        this.auditInfo.show(new AuditInfo(options));
    },

    print() {
        const reportId = 40001;
        const fieldName = 'TNUM';
        const productCode = 'PAY';
        const typeCode = '*';
        const functionCode = 'INST';

        const printModal = new PrintViewModal({
            exportModel: {
                outputFormat: 'PDF',
                pageType: 'LETTER',
                expData: 'transaction',
                detailReportId: reportId,
                viewId: '110000',

                actionData: {
                    productCode,
                    functionCode,
                    typeCode,
                },

                searchFields: [{
                    fieldName,
                    operator: 'IN',
                    fieldValue: [this.paymentModel.get(fieldName)],
                    dataType: 'string',
                }],
            },
        });

        if (reportId === 40001 && this.paymentModel.get('TYPE') && this.paymentModel.get('CMB_TYPE_DESCRIPTION')) {
            printModal.exportModel.viewId = '110000';
        }
        dialog.custom(printModal);
    },

    templateHelpers() {
        const self = this;
        return {
            id: this.cid,

            getString(code) {
                return locale.get(code);
            },

            isNotModal() {
                return !self.isModal;
            },

            buttons() {
                return self.buttonList.map((el, i) => {
                    const btnActionLowercase = el.action.toLowerCase();
                    return {
                        btnText: locale.get(`payment.button_${btnActionLowercase}`),
                        btnClass: (i === 0 && btnActionLowercase !== 'delete' ? 'btn-primary' : 'btn-secondary'),
                        btnAction: btnActionLowercase,
                    };
                });
            },

            fileSummary: this.fileSummary.toJSON(),
        };
    },

    approve() {
        const self = this;
        this.paymentModel.approve({
            success(model, resp) {
                store.set(`${self.contextKey}-alertMessage`, 'APPROVE');
                store.set(`${self.contextKey}-confirms`, resp);
                self.cancel();
            },

            error() {
                store.set(`${self.contextKey}-alertMessage`, 'APPROVE error');
            },
        });
    },

    unapprov() {
        const self = this;
        this.paymentModel.unapprove({
            success(model, resp) {
                store.set(`${self.contextKey}-alertMessage`, 'UNAPPROVE');
                store.set(`${self.contextKey}-confirms`, resp);
                self.cancel();
            },

            error() {
                store.set(`${self.contextKey}-alertMessage`, 'UNAPPROVE error');
            },
        });
    },

    delete() {
        const self = this;
        dialog.confirm(locale.get('tableMaintenance.dialog.confirm.item.delete'), locale.get('tableMaintenance.dialog.confirm.title.delete'), (ok) => {
            if (ok) {
                self.paymentModel.destroy({
                    success(model, resp) {
                        store.set(`${self.contextKey}-alertMessage`, 'DELETE');
                        store.set(`${self.contextKey}-confirms`, resp);
                        self.cancel();
                    },

                    error() {
                        store.set(`${self.contextKey}-alertMessage`, 'DELETE error');
                        self.cancel();
                    },
                });
            }
        });
    },

    reject() {
        const self = this;
        dialog.custom(new RejectDialog({
            model: this.paymentModel,
            store,
            detailPage: self,
        }));
    },

    cancel() {
        const currentWorkspaceRoute = store.get('current-workspace-route');
        if (currentWorkspaceRoute) {
            this.navigateTo(currentWorkspaceRoute);
            return;
        }
        window.history.back();
    },
});

export default PassThroughDetails;
