import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import appConfigParams from 'system/webseries/models/applicationConfiguration';
import userInfo from 'etc/userInfo';

export default {
    USD_ONLY: false,

    /**
     * Evaluate the app configuration params and determine if the credit currency
     * should be USD only
     * @param {Object} model - model containing payment attributes
     * @param {Object} comboCollections - list of original data for combobox widgets
     * @param {string} [accountfilter] - optional selected account filter
     * @returns {boolean}
     */
    evaluateUSDOnly(model, comboCollections, accountFilter) {
        // User setting should be evaluated first, followed by accounts
        if (appConfigParams.getValue('WIRES', 'ALLOWFXUSDUSER') === '1') {
            this.USD_ONLY = userInfo.get('allowFXUSDUser') === '0';
        }

        if (this.USD_ONLY) {
            return this.USD_ONLY;
        }

        if (appConfigParams.getValue('INTL', 'ALLOWFXUSDSPLIT') === '1') {
            this.USD_ONLY = this.accountAllowFX(model, comboCollections, accountFilter);
        } else {
            this.USD_ONLY = false;
        }
        return this.USD_ONLY;
    },

    /**
     * Evaluate the model, the selected debit account to
     * determine if the account allows foreign currencies
     * @param {Object} model - model containing payment attributes
     * @param {Object} comboCollections - list of original data for combobox widgets
     * @param {string} [accountfilter] - optional selected account filter
     * @returns {boolean}
     */
    accountAllowFX(model, comboCollections, accountFilter) {
        let account;
        let accountData;
        const debitAccountFilter = accountFilter || model.get('ACCOUNTFILTER');
        try {
            account = util.find(
                comboCollections.ACCOUNTFILTER,
                item => item.name === debitAccountFilter,
            );
            accountData = transform.pairsToHash(account.mapDataList, 'toField', 'value');
        } catch (error) {
            return false;
        }

        return accountData.ALLOWFX === '0';
    },

    /**
     *
     * @param {Model} model
     * @returns {string|*}
     */
    getFunctionCode(model) {
        if (!util.isNullOrUndefined(model.context)
            && !util.isNullOrUndefined(model.context.functionCode)) {
            return model.context.functionCode;
        }
        if (!util.isNullOrUndefined(model.context.actionData)) {
            return model.context.actionData.functionCode;
        }
        if (!util.isNullOrUndefined(model.context.actionContext)) {
            return model.context.actionContext.functionCode;
        }
        if (model.jsonData && model.jsonData.typeInfo) {
            return model.jsonData.typeInfo;
        }
        return '';
    },
};
