import Collection from '@glu/core/src/collection';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import constants from '../constants';

const AccountSections = Collection.extend({
    initialize(options) {
        this.options = options;
    },

    sync(method, model, options) {
        const url = services.generateUrl(`${constants.ACCOUNT_SECTIONS_SERVICE}/${this.options.tabId}`);

        return http.post(url, null, (result) => {
            options.success(result);
        }, () => {
            options.error();
        });
    },

    parse(sections) {
        return util.map(sections, section => util.extend(
            section,
            {
                id: section.sectionId,
                sectionHasTotals: !util.isNull(section.totalsColumn1)
                    || !util.isNull(section.totalsColumn2),
            },
        ));
    },
});

export default AccountSections;
