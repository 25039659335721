import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import workspaceHelper from 'common/workspaces/api/helper';
import domUtil from 'common/util/domUtil';
import MultiItemModel from '../models/multiItemModel';
import template from './multiAddLayoutView.hbs';

export default Layout.extend({
    template,
    className: 'multi-add-layout',

    ui: {
        $addItem: '[data-hook="getAddItemButton"]',
        $addCount: '[data-hook="getAddCount"]',
    },

    events: {
        'click @ui.$addItem': 'addItemsToCollection',
    },

    initialize(options) {
        this.localeKey = options.localeKey;
        this.title = `${this.localeKey}title`;
        this.totalLabel = `${this.localeKey}totals`;
        this.mode = 'insert';
        this.type = options.type;
        this.canSaveAsDraft = options.canSaveAsDraft;
        this.setupButtons();
    },

    onRender() {
        // display collection view in region
        this.collectionRegion.show(this.collectionView);
    },

    /**
     * @name addModels
     * @description adds 'count' items (models) to an array
     * @param {number} count
     * @returns {Model[]} an array of 'count' items (models)
     */
    addModels(count) {
        let i;
        const newModels = [];

        for (i = 0; i < count; i += 1) {
            newModels.push(new MultiItemModel());
        }
        return newModels;
    },

    /**
     * @name addItemsToCollection
     * @description adds items (models) to the collection
     * the number of items added is determined from an input control
     */
    addItemsToCollection() {
        const numItems = parseInt(this.ui.$addCount.val() || 1, 10);
        this.collection.add(this.addModels(numItems));
    },

    setupButtons() {
        this.buttonList = [{
            action: 'SAVE',
        }, {
            action: 'CANCEL',
        }];

        if (this.canSaveAsDraft) {
            this.buttonList.splice(1, 0, { action: 'DRAFT' });
        }
    },

    /**
     * @name disableButtons
     * @description disables/enables the action buttons (save, cancel)
     * @param state true disables the buttons; false enables the buttons
     */
    disableButtons(state) {
        domUtil.setDisabled(this.$('button[data-action]'), state || false);
    },

    /**
     * @name cancel
     * @description cancel button action.  returns to the workspace
     */
    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /**
     * draft - data-action handler for viewMixins
     */
    draft() {
        this.saveDraft();
    },

    /**
     * save - data-action handler for viewMixins
     */
    save() {
        this.multiSave();
    },

    /**
     * @name multiSave
     * @description triggers the 'multi-item:save' event.
     * Layout views that extend from this view need to handle this
     * event to save the multi-item collection
     */
    multiSave() {
        this.trigger('multi-item:save');
    },

    /**
     * @name saveDraft
     * @description initiates a save with the incomplete flag. Saved items will be in the
     * 'Incomplete' (IC) status.
     */
    saveDraft() {
        this.collection.forEach((model) => {
            model.set('_saveIncomplete', 'true');
        });

        this.multiSave();
    },

    templateHelpers() {
        return {
            title: locale.get(this.title),
            getTotalLabel: locale.get(this.totalLabel),
            getItemsLabel: locale.get('title.items'),

            buttons: () => this.buttonList.map((button, i) => {
                const lowerCaseAction = button.action.toLowerCase();
                return {
                    btnText: locale.get(`${this.localeKey.split('.')[0]}.button_${lowerCaseAction}`),
                    btnClass: ((i === 0) ? 'btn-primary' : 'btn-secondary'),
                    btnAction: lowerCaseAction,
                };
            }),
        };
    },
});
