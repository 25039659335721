/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disabled react/react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@glu/modal-react';
import { createUseStyles } from '@glu/theming';
import { Button, SECONDARY } from '@glu/buttons-react';
import locale from '@glu/locale';
import { CloseXAshIcon } from '@glu/icons-react';
import Gateway from 'ptxReact/system/gateway';
import urls from 'ptxReact/system/urls';
import Loading from 'ptxReact/system/components/Loading';
import LogoSearch from 'ptxReact/app/client/pages/documentProfile/emailTab/emailBuilder/components/logoSelection/logoSearch';

const useStyles = createUseStyles(({
    modalFooter,
    modalBody,
    typography,
    button,
    modal
}) => ({
    '@global section[class^="modalComponent"]': {
        zIndex: 'unset'
    },
    modalFooter,
    modalBody: {
        ...modalBody,
        flexDirection: 'column',
        marginTop: 90
    },
    button,
    title: {
        ...typography.h2,
        display: 'contents',
        margin: 0,
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: 28
    },
    modal: {
        ...modal,
        zIndex: 2050,
        '& article': {
            width: 'fit-content',
            marginTop: 60
        },
        '& article>div': {
            borderRadius: 8,
            padding: [30, 40],
            '& section': {
                padding: 0
            },
            '& section>div': {
                textAlign: 'left'
            }
        },
        '& article>div:before': {
            content: 'none'
        }
    },
    closeModal: {
        float: 'right',
        backgroundColor: 'transparent',
        border: 'none'
    },
    selectionContainer: {
        margin: [30, 0, 32, 0],
        border: '1px solid #ccc',
        width: 420,
        height: 304,
        overflow: 'scroll',
    },
    shortSelectionContainer: {
        height: 244,
        marginTop: 0
    },
    logoSelectionButton: {
        height: 60,
        width: 400,
        display: 'flex',
        alignItems: 'center',
        margin: [0, 10],
        backgroundColor: 'transparent',
        border: 'none',
        borderBottom: '1px solid #ccc',
    },
    logoImageContainer: {
        width: 120,
        textAlign: 'center',
        '& img': {
            maxWidth: 120,
            maxHeight: 45
        }
    },
    logoTextContainer: {
        marginLeft: 15
    },
    logoSearchArea: {
        width: 420,
        height: 60,
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderBottom: 'none',
        backgroundColor: 'transparent'
    },
    searchField: {
        width: 350,
        border: 'none'
    },
    loadingSpinner: {
        position: 'absolute',
        top: 0,
        margin: '0 auto'
    }
}));

const logoSelection = ({
    cancelLogoSelectionHandler,
    selectLogo,
    accountId,
    showLogoSelection
}) => {
    const [returnedLogos, setReturnedLogos] = useState(null);
    const [totalLogos, setTotalLogos] = useState(0);
    const [allLogoIds, setAllLogoIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const classes = useStyles();

    const logosImgArray = [];

    const createLogoIcon = (data) => (
        <button
            className={classes.logoSelectionButton}
            type="button"
            id={data?.description?.toLowerCase().trim()}
            key={data?.description?.toLowerCase().trim()}
            data-qa="selectLogoButton"
            onClick={() => {
                selectLogo(data.id);
                cancelLogoSelectionHandler();
            }}
        >
            <span className={classes.logoImageContainer}>
                <img src={data.url} alt={`${locale.get('logo')}: ${data.description}`} />
            </span>
            <span className={classes.logoTextContainer}>{data.description}</span>
        </button>
    );

    const renderLogos = () => {
        logosImgArray.sort((a, b) => a.description?.localeCompare(b.description));
        const logosItems = logosImgArray?.map((item) => (createLogoIcon(item)));
        setReturnedLogos(logosItems);
        setTotalLogos(logosImgArray.length);
    };

    useEffect(() => {
        if (allLogoIds.length > 0) {
            allLogoIds.forEach(id => (
                new Gateway(urls.singleLogo.toString())
                    .params({ logoId: id })
                    .get()
                    .then((result) => {
                        logosImgArray.push(result);
                        if (logosImgArray.length === allLogoIds.length) {
                            renderLogos();
                        }
                    })
            ));
        }
    }, [allLogoIds, logosImgArray, renderLogos]);

    useEffect(() => {
        let allLogos = [];
        new Gateway(urls.logos.toString()).get(accountId).then((result) => {
            allLogos = result.map((logo) => logo.id);
            setAllLogoIds(allLogos);
        });
        // Hack to add Aria-label to the modal (no way of adding one in the current version of Glu modal and later versions introduce breaking changes)
        // document.querySelectorAll('[role="dialog"]')[0].setAttribute('aria-label', locale.get('selectALogo'));
    }, [accountId]);

    const searchFieldKeyUpHandler = (e) => {
        e.key === 'Enter' ? e.preventDefault() : setSearchTerm(e.target.value);
    };

    return (
        showLogoSelection && (
            <Modal
                open
                className={classes.modal}
                suppressIcon
            >
                <h2 className={classes.title}>
                    {locale.get('selectALogo')}
                </h2>
                <button
                    type="button"
                    className={classes.closeModal}
                    data-qa="close"
                    onClick={cancelLogoSelectionHandler}
                    aria-label={locale.get('close')}
                >
                    <CloseXAshIcon title={locale.get('close')} />
                </button>

                {!returnedLogos && (<Loading isLoading className={classes.loadingSpinner} />)}

                {totalLogos > 4 && (
                    <LogoSearch
                        searchFieldKeyUpHandler={searchFieldKeyUpHandler}
                    />
                )}

                <div
                    className={`${classes.selectionContainer} ${totalLogos > 4 && classes.shortSelectionContainer}`}
                    id="selectionContainer"
                >
                    {returnedLogos?.length > 0 && (
                        !searchTerm
                            ? returnedLogos
                            : returnedLogos?.filter((logo) => logo.key.includes(searchTerm.toLowerCase()))
                    )}
                </div>
                <Button
                    variant={SECONDARY}
                    text={locale.get('cancel')}
                    data-qa="cancel"
                    onClick={cancelLogoSelectionHandler}
                />
            </Modal>
        )
    );
};

logoSelection.propTypes = {
    cancelLogoSelectionHandler: PropTypes.func.isRequired,
    selectLogo: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    showLogoSelection: PropTypes.bool.isRequired
};

export default logoSelection;
