import EntryView from 'common/dynamicPages/views/workflow/entry';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import errorHandlers from 'system/error/handlers';
import cmconstants from 'app/checkManagement/constants';
import DataApi from 'common/dynamicPages/api/data';

const ViewBasePayDecision = EntryView.extend({
    /**
     * Loads required data for Fraud Control pay decisions
     */
    loadRequiredFCData(productCode, typeCode) {
        const self = this;

        if (this.mode === cmconstants.INSERT || this.mode === cmconstants.IMPORT) {
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            const storedModel = store.get(this.contextKey + cmconstants.ACTIONMODEL);

            const actionData = {
                productCode,
                typeCode,
                functionCode: cmconstants.BATCH,
            };

            storedModel.context.actionData = actionData;

            storedModel.set(cmconstants.PRODUCTCODE, productCode);
            storedModel.set(cmconstants.TYPECODE, typeCode);
            storedModel.set(cmconstants.FUNCTIONCODE, cmconstants.BATCH);

            DataApi.model.generateModelFromModel(storedModel).then((newModelParam) => {
                const newModel = newModelParam;
                newModel.key.PRODUCTCODE = cmconstants.TRUE;
                newModel.key.TYPECODE = cmconstants.TRUE;
                newModel.key.FUNCTIONCODE = cmconstants.TRUE;

                newModel.fetch({
                    success() {
                        self.storedModel = newModel;
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },

                    error: util.bind(errorHandlers.loading, self),
                });
            });
        }
    },
});

export default ViewBasePayDecision;
