import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import $ from 'jquery';
import util from '@glu/core/src/util';
import Security from 'system/login/models/questions';
import scroll from 'common/util/scroll';
import questionTmpl from 'system/login/views/question/base.hbs';
import template from './questions.hbs';

export default Layout.extend({
    initialize(opts) {
        this.collection = opts.collection;
        this.isEnrollmentPage = opts.isEnrollmentPage;
        this.model.clear();
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstError);
    },

    template,
    model: new Security(),
    binding: true,
    class: 'securityQuestions',

    ui: {
        toggle: 'input[name="toggleMask"]',
        $saveBtn: '[data-action="save"]',
    },

    events: {
        'click @ui.toggle': 'toggleMask',
    },

    onRender() {
        const element = $('form div.form-group select');
        util.each(element, (dropdown) => {
            $(dropdown).find('option').first().prop('selected', true);
        });
        this.model.clear();
    },

    save() {
        if (this.model.isValid()) {
            // disable submit button
            this.ui.$saveBtn.prop('disabled', true);
            this.model.save({}, {
                success: () => {
                    if (this.isEnrollmentPage) {
                        this.trigger('submit');
                    } else {
                        this.navigateTo('/');
                    }
                },
                error: () => {
                    // enable submit button
                    this.ui.$saveBtn.removeProp('disabled');
                },
            });
        }
    },

    cancel(e) {
        e.preventDefault();
        if (this.isEnrollmentPage) {
            this.navigateTo('logout');
        } else {
            this.navigateTo('/');
        }
    },

    toggleMask(e) {
        if (e.currentTarget.checked) {
            $('[data-hook="securityAnswer"]').attr('type', 'password');
        } else {
            $('[data-hook="securityAnswer"]').attr('type', 'text');
        }
    },

    templateHelpers() {
        const self = this;

        return {
            getQuestion(number) {
                let x = -1;
                let options = '';
                let value;

                if (self.collection.length > 0) {
                    const { length } = self.collection[number].models;
                    for (x; x < length; x += 1) {
                        if (x !== -1) {
                            value = self.collection[number].models[x].attributes;
                        } else {
                            value = {
                                label: locale.get('security.pleaseChooseAQuestion'),
                                isFirst: true,
                            };
                        }
                        options += questionTmpl(value);
                    }
                }
                return options;
            },

            getInstructions() {
                if (self.isEnrollmentPage) {
                    return locale.get('security.HeaderInfoline1b');
                }
                return locale.get('security.HeaderInfoline1');
            },

            getPageTitle() {
                return (self.isEnrollmentPage) ? locale.get('common.securityTitle') : locale.get('widget.29.description');
            },
        };
    },
});
