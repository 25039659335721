import { Collection, Model, util } from '@glu/core';
import FlexDropdownSelectionCollection from '../collections/flexDropdownSelectionCollection';

export default Model.extend({
  defaultGet: 300,

  filterMethods: {
    contains(item, filterText) {
      function containsFilterText() {
        let contains;

        if (util.isArray(item.name)) {
          contains = !!util.find(item.name, (name) => !(name.toLowerCase().indexOf(filterText) < 0));
        } else {
          contains = !(item.name.toLowerCase().indexOf(filterText) < 0);
        }

        return contains;
      }

      return (item.hasOwnProperty('name') && (containsFilterText(filterText)));
    },
    startsWith(item, filterText) {
      function startsWithFilterText() {
        let startsWith;

        if (util.isArray(item.name)) {
          startsWith = !!util.find(item.name, (name) => !(name.toLowerCase().indexOf(filterText) !== 0));
        } else {
          startsWith = !(item.name.toLowerCase().indexOf(filterText) !== 0);
        }

        return startsWith;
      }

      return (item.hasOwnProperty('name') && startsWithFilterText(filterText));
    }
  },

  initialize(attrs, options) {
    this.filterMethod = options.filterMethod || 'contains';
    this.multiSelect = options.multiSelect;
    this.preSelectedIds = options.preSelectedIds;
    this.preSelectedNames = options.preSelectedNames;
    this.preSelectedFirstItem = options.preSelectFirstItem;
    this.preSelectedNewItems = options.preSelectedNewItems || false;
    this.addSelectedTextItem = options.addSelectedTextItem || false;
    this.customParse = options.customParse || this.customParse;
    this.tableView = options.tableView;
    this.columnTitles = options.columnTitles;
  },

  parse(data) {
    if (data && data.length) {
      if (this.customParse !== undefined) {
        data = this.customParse(data);
      }

      let maxLength = 0;
      this.hierarchy = false;

      util.each(data, function parseItem(item) {
        if (item.child !== undefined) {
          this.highlightParents(item);
          this.hierarchy = true;
        }
        if (item.name.length > maxLength) {
          maxLength = item.name.length;
        }
        item.hide = false;
      }, this);

      this.data = data;

      // preselect any ids that were provided before setting up models and collections
      this.preSelectIdsInData(this.preSelectedIds);

      // preselect any names that were provided before setting up models and collections
      this.preSelectNamesInData(this.preSelectedNames);

      // preselect the first item before setting up models and collections
      if (this.preSelectedFirstItem) {
        this.preSelectFirstItem();
      }

      // items holds all items currently in JS memory
      data = {
        items: new Collection(data.slice(0, this.defaultGet))
      };

      // selection holds all items currently selected
      data.selection = new FlexDropdownSelectionCollection(this.getSelected(), {
        defaultSelectMessage: this.defaultSelectMessage,
        clearBtn: this.clearBtn,
        showCancelIcon: this.showCancelIcon
      });

      data.breadcrumb = [];

      // Selected items are marked as selected in the items collection
      // When the items collection changes we updated the selection collection
      data.items.on('change reset', function reset() {
        data.selection.reset(this.getSelected());
      }, this);

      this.minLength = Math.ceil(maxLength * 6.2);
    } else {
      if (this.preSelectedNewItems !== false) {
        this.data = [{
          name: this.preSelectedNewItems[0].name,
          id: -1,
          selected: true,
          noData: false,
          preSelected: true,
          notVisibleItem: this.addSelectedTextItem === false
        }];
      } else {
        this.data = [{
          name: this.defaultSelectMessage || '--',
          id: null,
          selected: true,
          noData: true,
          notVisibleItem: true
        }];
      }
      this.hierarchy = false;
      this.minLength = null;

      data = {
        items: new Collection([]),
        selection: new FlexDropdownSelectionCollection(this.data, {
          clearBtn: !!this.preSelectedNewItems
        }),
        breadcrumb: []
      };

      if (this.preSelectedNewItems !== false) {
        // Selected items are marked as selected in the items collection
        // When the items collection changes we updated the selection collection
        data.items.on('change reset', function reset() {
          data.selection.reset(this.getSelected());
        }, this);
      }
    }

    return data;
  },
  processSelected(preSelected) {
    const selectedAdded = [];
    const data = this.data.slice(0);

    for (let i = 0; i < preSelected.length; i++) {
      selectedAdded[i] = false;
    }

    for (let j = 0; j < data.length; j++) {
      for (let k = 0; k < preSelected.length; k++) {
        if (preSelected[k].id === data[j].id) {
          data[j].selected = true;
          selectedAdded[k] = true;
        } else if (data[j].child !== undefined) {
          this.processSelectedChild(data[j].child, preSelected, selectedAdded);
        }
      }
    }

    for (let l = 0; l < selectedAdded.length; l++) {
      if (selectedAdded[l] !== true) {
        preSelected[l].selected = true;
        data.push(preSelected[l]);
      }
    }

    this.data = data;
    this.get('items').reset(this.data.slice(0, this.defaultGet));
  },
  isHierarchical() {
    return this.hierarchy;
  },
  /**
   * Finds any child items which are highlighted and highlights the parent items
   */
  highlightParents(item) {
    const parents = [];
    const highlightRecursive = function (object) {
      if (object !== null && object.highlight && parents.length > 0) {
        util.each(parents, (parent) => {
          if (!parent.highlight) {
            parent.highlight = true;
          }
        });
      }

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        if (typeof object[objectKeys[i]] === 'object') {
          if (!util.isArray(object)) {
            parents.push(object);
          }

          const o = highlightRecursive(object[objectKeys[i]]);

          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    highlightRecursive(item);
  },
  processSelectedChild(child, preSelected, selectedAdded) {
    for (let l = 0; l < child.length; l++) {
      for (let k = 0; k < preSelected.length; k++) {
        if (preSelected[k].id === child[l].id) {
          child[l].selected = true;
          selectedAdded[k] = true;
        } else if (child[l].child !== undefined) {
          this.processSelectedChild(child[l].child, preSelected, selectedAdded);
        }
      }
    }
  },

  /**
   * Preselect items by item text
   *
   * @param preSelectedNewItems - item text
   * @param addSelectedTextItem - {boolean} if true - will add preSelectedNewItems as an item to the list of dropdown items
   */
  processSelectedText(preSelectedNewItems, addSelectedTextItem) {
    const selectedAdded = [];
    const data = this.data.slice(0);

    for (let i = 0; i < preSelectedNewItems.length; i++) {
      selectedAdded[i] = false;
    }

    for (let j = 0; j < data.length; j++) {
      for (let k = 0; k < preSelectedNewItems.length; k++) {
        if (preSelectedNewItems[k].name === data[j].name) {
          data[j].selected = true;
          selectedAdded[k] = true;
        } else if (data[j].child !== undefined) {
          this.processSelectedChild(data[j].child, preSelectedNewItems, selectedAdded);
        }
      }
    }

    for (let l = 0; l < selectedAdded.length; l++) {
      if (selectedAdded[l] !== true) {
        preSelectedNewItems[l].selected = true;
        if (preSelectedNewItems[l].id === undefined) {
          preSelectedNewItems[l].id = -1;
        }
        if (addSelectedTextItem === false) {
          preSelectedNewItems[l].notVisibleItem = true;
        }
        data.push(preSelectedNewItems[l]);
      }
    }

    this.data = data;
    this.get('items').reset(this.data.slice(0, this.defaultGet));
  },

  /**
   * Preselect child items (for a hierarchical dropdown) by item text
   *
   * @param child - child node
   * @param preSelectedNewItems - item text
   * @param selectedAdded - boolean array of preselected items that were found and marked as selected
   */
  processSelectedTextChild(child, preSelectedNewItems, selectedAdded) {
    for (let l = 0; l < child.length; l++) {
      for (let k = 0; k < preSelectedNewItems.length; k++) {
        if (preSelectedNewItems[k].name === child[l].name) {
          child[l].selected = true;
          selectedAdded[k] = true;
        } else if (child[l].child !== undefined) {
          this.processSelectedChild(child[l].child, preSelectedNewItems, selectedAdded);
        }
      }
    }
  },
  /**
   * Sets all items to visible
   */
  resetFilter() {
    const showAllRecursive = function (object) {
      if (object !== null && object.hasOwnProperty('hide')) {
        object.hide = false;
      }

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        if (typeof object[objectKeys[i]] === 'object') {
          const o = showAllRecursive(object[objectKeys[i]]);
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    if (this.hierarchy) {
      showAllRecursive(this.data);
    } else {
      util.each(this.data, (item) => {
        item.hide = false;
      });
    }
  },
  /**
   * Selects all items.
   */
  selectAll() {
    util.each(this.data, (item) => {
      item.selected = true;
    });
  },
  /**
   * Sets all items to unselected.
   *
   * @param clearThisLevelOnly - if true will clear the current level only (For a hierarchical dropdown)
   * @param parentId - id of the parent item for the currently expanded level, 0 - for the top level
   */
  clearSelected(clearThisLevelOnly, parentId) {
    const getItemIDsToUnselect = function (object, parentIdUnselect, isTopLevel) {
      const itemIDsToUnselect = [];
      if (isTopLevel && parentIdUnselect === 0) {
        for (let l = 0; l < object.length; l++) {
          itemIDsToUnselect.push(object[l].id);
        }
      } else if (parentIdUnselect === object.id) {
        for (let j = 0; j < object.child.length; j++) {
          itemIDsToUnselect.push(object.child[j].id);
        }
      }
      return itemIDsToUnselect;
    };

    const resetRecursive = function (object, clearThisLevelOnlyRecursive, parentIdRecursive, isTopLevel, itemIDsToUnselect) {
      if (clearThisLevelOnlyRecursive) {
        if (itemIDsToUnselect === undefined || itemIDsToUnselect.length === 0) {
          itemIDsToUnselect = getItemIDsToUnselect(object, parentIdRecursive, isTopLevel);
        } else {
          for (let k = 0; k < itemIDsToUnselect.length; k++) {
            if (itemIDsToUnselect[k] === object.id) {
              object.selected = false;
            }
          }
        }
      } else if (object.hasOwnProperty('selected')) {
        object.selected = false;
      }

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        if (typeof object[objectKeys[i]] === 'object') {
          const o = resetRecursive(object[objectKeys[i]], clearThisLevelOnlyRecursive, parentIdRecursive, false, itemIDsToUnselect);
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    if (this.hierarchy) {
      resetRecursive(this.data, clearThisLevelOnly, parentId, true);
    } else {
      util.each(this.data, (item) => {
        item.selected = false;
      });
    }
  },
  /**
   * Filters all data using string entered.
   *
   * @param {string} filterText - string to use to filter the data
   */
  filterCollection(filterText) {
    const filter = this.filterMethods[this.filterMethod];
    const filterRecursive = function (object, filterTextRecursive) {
      object.hide = !filter(object, filterTextRecursive);

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        if (typeof object[objectKeys[i]] === 'object') {
          const o = filterRecursive(object[objectKeys[i]], filterTextRecursive);
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    if (this.hierarchy) {
      filterRecursive(this.data, filterText);
    } else {
      util.each(this.data, (item) => {
        item.hide = !filter(item, filterText);
      });
    }
  },
  /**
   * Returns the active items for the dropdown
   *
   * @param {number} slice1 - first item of data in array to retrieve
   * @param {number} slice2 - last item of data in array to retrieve
   * @param {boolean} addTo - if true, add to to the current collection. If false, reset current collection
   *
   * */
  getActive(slice1, slice2, addTo) {
    const that = this;
    const getActive = function (slice1Active, slice2Active) {
      const visible = [];
      const { data } = that;
      const { length: dataLength } = data;
      slice1Active = slice1Active || 0;
      slice2Active = slice2Active || that.defaultGet;

      if (slice2Active === 'scroll') {
        slice2Active = slice1Active + 100;
      }
      // set items to selected as they come in if they were previously selected
      for (let index = 0; index < dataLength; index++) {
        if (!data[index].hide && index >= slice1Active) {
          visible.push(data[index]);
          if (visible.length >= (slice2Active - slice1Active)) {
            return visible;
          }
        }
      }

      return visible;
    };
    const visible = getActive(slice1, slice2);


    if (!addTo) {
      this.get('items').reset(visible);
    } else if (visible.length) {
      this.get('items').add(visible);
    }
  },
  /**
   * Returns a single item selected by id. Can only be triggered with hierarchical data
   *
   * @param {number} id - id of the item to return
   */
  getById(id) {
    const filter = function (object, filterId) {
      if (object.hasOwnProperty('id') && `${object.id}` === `${filterId}`) {
        return object;
      }

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        // object[objectKeys[i]] could be null, added checking on existing object[objectKeys[i]]
        if (object[objectKeys[i]] && typeof object[objectKeys[i]] === 'object') { // GLU-1239
          const o = filter(object[objectKeys[i]], filterId);
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    return filter(this.data, id);
  },

  /**
   * Returns a single item selected by name. Can only be triggered with hierarchical data
   *
   * @param {string} name - name of the item to return
   * @param {boolean} allLowerCase - if object name should be converted to lowercase before comparison
   */
  getByName(name, allLowerCase) {
    const filter = function (object, nameFilter, allLowerCaseFilter) {
      if (object.hasOwnProperty('name')) {
        if (allLowerCaseFilter && (`${object.name}`).toLowerCase() === `${nameFilter}`) {
          return object;
        } if (`${object.name}` === `${nameFilter}`) {
          return object;
        }
      }

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        // object[objectKeys[i]] could be null, added checking on existing object[objectKeys[i]]
        if (object[objectKeys[i]] && typeof object[objectKeys[i]] === 'object') { // GLU-1239
          const o = filter(object[objectKeys[i]], nameFilter, allLowerCaseFilter);
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    return filter(this.data, name, allLowerCase);
  },

  /**
   * Toggles an item as selected by id
   *
   * @param {number} id - id of the item to toggle
   */
  toggleSelected(id) {
    const toggleByIdRecursive = function (object, idRecursive) {
      if (object.hasOwnProperty('id') && `${object.id}` === `${idRecursive}`) {
        object.selected = !object.selected;
        return object.selected;
      }

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        if (typeof object[objectKeys[i]] === 'object') {
          const o = toggleByIdRecursive(object[objectKeys[i]], idRecursive);
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    if (this.hierarchy) {
      return toggleByIdRecursive(this.data, id);
    }
    for (const item in this.data) {
      if (this.data.hasOwnProperty(item)) {
        if (`${this.data[item].id}` === `${id}`) {
          this.data[item].selected = !this.data[item].selected;
          return this.data[item].selected;
        }
      }
    }

    return null;
  },

  /**
   * Selects one item and sets the rest to unselected
   *
   * @param {number} id - id of the item to select
   */
  selectOne(id) {
    const selectByIdRecursive = function (object, idRecursive) {
      object.selected = (object.hasOwnProperty('id') && `${object.id}` === `${idRecursive}`);

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        if (typeof object[objectKeys[i]] === 'object') {
          const o = selectByIdRecursive(object[objectKeys[i]], idRecursive);
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    if (this.hierarchy) {
      selectByIdRecursive(this.data, id);
    } else {
      util.each(this.data, (item) => {
        item.selected = (`${item.id}` === `${id}`);
      });
    }
  },

  /**
   * Returns a JSON array of all selected items
   */
  getSelected() {
    const selected = [];
    let parents = [];
    const isSelected = function (item) {
      if (item.selected) {
        let selectedItem = item;
        if (parents.length) {
          selectedItem.parents = parents.slice(0);
          if (item.child !== undefined) {
            selectedItem.parents.pop();
          }
        }

        if (util.isArray(selectedItem.name)) {
          selectedItem = util.clone(selectedItem);
          [selectedItem.name] = selectedItem.name;
        }

        selected.push(selectedItem);
      }
    };
    const selectedRecursive = function (object, level) {
      isSelected(object);

      const objectKeys = Object.keys(object);

      for (let i = 0; i < objectKeys.length; i++) {
        if (typeof object[objectKeys[i]] === 'object') {
          if (level === 0) {
            parents = [];
          }

          let newLevel = level;
          const hasChild = object[objectKeys[i]].child !== undefined;
          if (hasChild) {
            parents.push(object[objectKeys[i]].name);
            newLevel = level + 1;
          }
          const o = selectedRecursive(object[objectKeys[i]], newLevel);
          if (hasChild) {
            parents.pop();
          }
          if (o !== null) {
            return o;
          }
        }
      }

      return null;
    };

    if (this.hierarchy) {
      selectedRecursive(this.data, 0);
    } else {
      util.each(this.data, (item) => {
        isSelected(item);
      });
    }

    return selected;
  },

  /**
   * Designed to enable preselecting of items on the dropdown in data loaded via a url where you only have the id(s) you wish to select
   * and the url does not return data that is preselected. This method allows one to avoid calling {@link FlexDropDown#setSelectedIds} on
   * on a fully initialized FlexDropdown which resets collections and is rather expensive.
   *
   * Notes:
   *   This method will preselect all the of the passed in ids and deselect everything else if one or more ids are provided.
   *   This method will only select more than one id: if more than 1 id is provided, and if multiselect is true in this model's options hash.
   *
   * @param {object|array} ids - A single id to preselect, or an array of ids to preselect.  If nothing is provided
   *        then no changes are made, if id(s) are provided all currently selected items will be deselected.
   */
  preSelectIdsInData(ids) {
    if (ids || ids === 0) {
      ids = util.isArray(ids) ? ids : [ids];

      // when selecting the first item deselect everything else
      this.selectOne(ids[0]);

      // if we allow multiple selections continue on after the first id
      if (this.multiSelect && ids.length > 1) {
        for (let i = 1; i < ids.length; i++) {
          const selection = this.getById(ids[i]);
          if (selection) {
            selection.selected = true;
          }
        }
      }
    }
  },

  /**
   * Designed to enable preselecting of items on the dropdown in data where you have the name(s), but not the id(s) you wish to select.
   * This method allows one to avoid calling {@link FlexDropDown#setSelectedByName} on a fully initialized FlexDropdown which resets collections
   * and is rather expensive.
   *
   * Notes:
   *   This method will preselect all the of the passed in names and deselect everything else if one or more names are provided.
   *   This method will only select more than one name: if more than 1 name is provided, and if multiselect is true in this model's options hash.
   *
   * @param {object|array} names - A single name to preselect, or an array of names to preselect.  If nothing is provided
   *        then no changes are made, if name(s) are provided all currently selected items will be deselected.
   */
  preSelectNamesInData(names) {
    let dataByName;

    if (names || names === 0) {
      names = util.isArray(names) ? names : [names];

      dataByName = this.getByName(names[0]);

      if (dataByName) {
        // when selecting the first item deselect everything else
        this.selectOne(dataByName.id);

        // if we allow multiple selections continue on after the first name
        if (this.multiSelect && names.length > 1) {
          for (let i = 1; i < names.length; i++) {
            dataByName = this.getByName(names[i]);

            if (dataByName) {
              dataByName.selected = true;
            }
          }
        }
      }
    }
  },

  /**
   * Designed to enable preselecting of the first item on the dropdown in data loaded via a url
   * where the url does not return data that is preselected. This method allows one to avoid calling {@link FlexDropDown#setSelectedIds}
   * or similar means on a fully initialized FlexDropdown which resets collections and is rather expensive.
   *
   * Notes:
   *   This method will preselect the first item and deselect everything else.
   */
  preSelectFirstItem() {
    const firstId = this.data[0].id;

    if (firstId || firstId === 0) {
      // when selecting the first item deselect everything else
      this.selectOne(firstId);
    }
  },

  setData(data) {
    return this.clear()
      .set(this.parse(data))
      .trigger('sync', this, data);
  }
});
