import $ from 'jquery';
import util from '@glu/core/src/util';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import store from 'system/utilities/cache';

export default function (form, initialState) {
    const allowSelfAdmin = form.field('ALLOWSELFADMINISTRATION');
    const addressBookPurgeInterval = form.field('ADDRBOOKPURGEINTERVALDAYS');
    const addressBookPurgeRetention = form.field('ADDRBOOKPURGERETENTION');
    const allowByOrderOf = applicationConfigParams.getValue('WIRES', 'ALLOWBYORDEROF');
    const allowBeneficiaryNotificationsWires = applicationConfigParams.getValue('WIRES', 'ALLOWBENENOTIFICATIONSWIRES');
    const allowBeneficiaryNotificationsRTP = applicationConfigParams.getValue('RTP', 'ALLOWBENENOTIFICATIONSRTP');
    const allowHealthCareConfig = applicationConfigParams.getValue('ACH', 'ALLOWHEALTHCARE');
    const allowReversalConfig = applicationConfigParams.getValue('ACH', 'ALLOWREVERSAL');
    const allowSignatureOverride = applicationConfigParams.getValue('PAYMENTS', 'ALLOWTEMPLATESIGNATUREOVERRIDE');
    const allowLockedFieldFromTmpl = applicationConfigParams.getValue('PAYMENTS', 'ALLOWLOCKEDFIELDSFROMTMPL');
    const allowBeneUnlock = applicationConfigParams.getValue('WIRES', 'ALLOWBENEUNLOCK');
    const allowRapidEntryWires = applicationConfigParams.getValue('WIRES', 'ALLOWRAPIDENTRYWIRES');
    const allowExtendedRemitWires = applicationConfigParams.getValue('WIRES', 'RTGSALLOWEXTREMITDOC');
    const allowPreAdviseWires = applicationConfigParams.getValue('WIRES', 'RTGSALLOWPREADVISEWIRES');
    const enablePushNotification = serverConfigParams.get('EnablePushNotification');
    const { formView } = form;
    const { formState, model } = formView;

    const processAddressBookPurgeFlag = function () {
        if (model.get('ADDRBOOKPURGEFLAG') === '1') {
            addressBookPurgeInterval.shouldBeReadOnly(false);
            addressBookPurgeRetention.shouldBeReadOnly(false);
        } else {
            addressBookPurgeInterval.shouldBeReadOnly(true);
            addressBookPurgeRetention.shouldBeReadOnly(true);
        }
    };

    const processAppSupUserIndFlag = function () {
        if (model.get('APPLICATIONSUPERUSERIND') === '1') {
            model.set('ALLOWSELFADMINISTRATION', '1');

            allowSelfAdmin.shouldBeReadOnly(true);
        }
    };

    const processRTGSAllowLockedFromTemplate = function () {
        const allowLockedFromTemplate = model.get('RTGSALLOWLOCKEDFIELDSFROMTMPL') === '1';
        form.field('RTGSALLOWLOCKEDBENESFROMTMPL').shouldBeReadOnly(!allowLockedFromTemplate);
        if (!allowLockedFromTemplate) {
            form.field('RTGSALLOWLOCKEDBENESFROMTMPL').$el.prop('checked', false);
        }
    };

    if (initialState) {
        const isAdvancedSegment = model.get('SOPHISTICATION') === '1';
        processAppSupUserIndFlag();
        processAddressBookPurgeFlag();

        if (serverConfigParams.get('userMaintCGBO') === 'true') {
            allowSelfAdmin.shouldBeHidden();
        }

        if (serverConfigParams.get('DisableClientAccountMasking') === 'true') {
            form.field('ALLOWACCOUNTUNMASKING').shouldBeHidden();
        }

        model.on('change:APPLICATIONSUPERUSERIND', processAppSupUserIndFlag);

        model.on('change:ADDRBOOKPURGEFLAG', processAddressBookPurgeFlag);

        model.on('change:RTGSALLOWLOCKEDFIELDSFROMTMPL', processRTGSAllowLockedFromTemplate);

        if (formState !== 'view' && !store.has('segmentModify')) {
            $('#ENTERED_BY_TIMESTAMP').closest('.section').addClass('hidden');
        }

        if (model.get('SOPHISTICATION') === '0' || allowByOrderOf === '0') {
            $('#ALLOWBYORDEROF').parent().addClass('hidden');
        }

        if (allowExtendedRemitWires === 'NOT') {
            $('#RTGSALLOWEXTREMITDOC').addClass('hidden');
        } else if (allowExtendedRemitWires === 'DOM') {
            $('#RTGSALLOWEXTREMITDOC').children('option').filter('[value="INTL"],[value="BOTH"]').remove();
        } else if (allowExtendedRemitWires === 'INTL') {
            $('#RTGSALLOWEXTREMITDOC').children('option').filter('[value="DOM"],[value="BOTH"]').remove();
        }

        // Pre-advise wires notification settings are only valid for advanced markets
        if (allowPreAdviseWires !== '1' || !isAdvancedSegment) {
            $('#RTGSALLOWPREADVISEWIRES').parent().addClass('hidden');
        }

        if (model.get('SOPHISTICATION') === '0' || allowHealthCareConfig !== '1') {
            $('#ACHALLOWHEALTHCARE').parent().addClass('hidden');
        }

        if (util.isNullOrUndefined(enablePushNotification) || enablePushNotification.toUpperCase() !== 'TRUE') {
            model.set('ENABLEPUSHNOTIFICATIONS', '0');
            $('#ENABLEPUSHNOTIFICATIONS').parent().addClass('hidden');
        }

        /*
         * dont show allow locked fields configuration
         * when not advance or when config is not set
         */
        if (model.get('SOPHISTICATION') !== '1' || allowLockedFieldFromTmpl !== '1') {
            form.field('ACHALLOWLOCKEDFIELDSFROMTMPL').shouldBeHidden();
            form.field('BPAYALLOWLOCKEDFIELDSFROMTMPL').shouldBeHidden();
            form.field('LOANALLOWLOCKEDFIELDSFROMTMPL').shouldBeHidden();
            form.field('RTGSALLOWLOCKEDFIELDSFROMTMPL').shouldBeHidden();
            form.field('TRFRALLOWLOCKEDFIELDSFROMTMPL').shouldBeHidden();
            form.field('RTPALLOWLOCKEDFIELDSFROMTMPL').shouldBeHidden();
            form.field('RTGSALLOWLOCKEDBENESFROMTMPL').shouldBeHidden();
        }
        if (model.get('SOPHISTICATION') !== '1' || allowBeneUnlock !== '1') {
            form.field('RTGSALLOWLOCKEDBENESFROMTMPL').shouldBeHidden();
        }
        processRTGSAllowLockedFromTemplate();

        if (model.get('SOPHISTICATION') !== '1' || allowSignatureOverride !== '1') {
            form.field('ACHALLOWTMPLSIGNATUREOVERRIDE').shouldBeHidden();
            form.field('BPAYALLOWTMPLSIGNATUREOVERRIDE').shouldBeHidden();
            form.field('LOANALLOWTMPLSIGNATUREOVERRIDE').shouldBeHidden();
            form.field('RTGSALLOWTMPLSIGNATUREOVERRIDE').shouldBeHidden();
            form.field('TRANSFERALLOWTMPLSIGNOVERRIDE').shouldBeHidden();
            form.field('RTPALLOWTMPLSIGNATUREOVERRIDE').shouldBeHidden();
        }

        if (model.get('SOPHISTICATION') === '0' || allowReversalConfig !== '1') {
            $('#ALLOWREVERSALS').parent().addClass('hidden');
        }

        if (!isAdvancedSegment) {
            $('#USERPERMISSIONSETTINGS').closest('.form-column').addClass('hidden');
        }

        form.field('RTPALLOWSNGLADMINPAYFROMTMPL').shouldBeVisibleWhen(isAdvancedSegment);
        form.field('RTPALLOWSNGLADMINTMPL').shouldBeVisibleWhen(isAdvancedSegment);
        form.field('ALLOWBENENOTIFICATIONSRTP').shouldBeVisibleWhen(isAdvancedSegment && allowBeneficiaryNotificationsRTP === '1');

        if (allowBeneficiaryNotificationsWires === '0') {
            $('#ALLOWBENENOTIFICATIONSWIRES').parent().addClass('hidden');
        }

        if (model.get('SOPHISTICATION') !== '1' || allowRapidEntryWires !== '1') {
            $('#ALLOWRAPIDENTRYWIRES').parent().addClass('hidden');
        }
    }
}
