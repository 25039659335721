export default ({ palette }) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        padding: '9px 12px',
        color: palette.text.dark,
        '&:hover': {
            backgroundColor: palette.highlightBackground,
            textDecoration: 'none',
        },
        textDecoration: 'none',
    },
});
