const { default: $ } = require('@glu/core/src/$');

const createTransformTable = (columns, tbodyId) => {
    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const trThead = document.createElement('tr');

    columns.map((colum) => {
        const th = document.createElement('th');
        th.style.width = `${colum.width}px`;
        th.style.maxWidth = `${colum.width}px`;
        th.innerHTML = colum.title;
        trThead.appendChild(th);
        return true;
    });
    thead.appendChild(trThead);
    table.appendChild(thead);

    const tbody = document.createElement('tbody');
    tbody.id = tbodyId;

    table.appendChild(tbody);
    table.className = 'table secondary';
    table.style.width = 'auto';

    const tableContainer = document.createElement('div');
    tableContainer.className = 'panel-body inline-fields';
    tableContainer.appendChild(table);

    return tableContainer;
};

const transformDeductionSection = (type) => {
    // view2278-CLM_INST_PRCHK_REMITINFO2
    const earningArea = $("[id$='CLM_INST_PRCHK_REMITINFO2']").first();
    const items = earningArea.children().children();
    const isView = type === 'view';

    const columns = isView ? [{
        width: 180,
        title: 'This Period',
    },
    {
        width: 180,
        title: 'YDT',
    }] : [
        {
            width: 180,
            title: 'Tax Deduction Type',
        },
        {
            width: 180,
            title: 'This Period',
        },
        {
            width: 180,
            title: 'YTD',
        },
    ];

    const columnsOther = isView ? [{
        width: 180,
        title: 'This Period',
    },
    {
        width: 180,
        title: 'YDT',
    }] : [
        {
            width: 180,
            title: 'Other Deduction Type',
        },
        {
            width: 180,
            title: 'This Period',
        },
        {
            width: 180,
            title: 'YTD',
        },
    ];

    const table = createTransformTable(columns, 'TBODY_CLM_INST_PRCHK_REMITINFO2_01');
    const tableOhther = createTransformTable(columnsOther, 'TBODY_CLM_INST_PRCHK_REMITINFO2_02');
    earningArea.append($(table));
    earningArea.append($(tableOhther));
    earningArea.children().eq(0).hide();

    const inputs = [];
    const breakLineNumber = isView ? 2 : 3;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
        const mod = i % breakLineNumber;
        if (mod === 0) {
            inputs.push([]);
        }
        const indexEarning = inputs.length - 1;
        inputs[indexEarning] =
            [
                ...inputs[indexEarning],
                items.eq(i),
            ];
    }

    inputs.slice(0, 6).map((inputsLine) => {
        const $tbody = $(table).find('tbody');
        const tr = document.createElement('tr');

        inputsLine.map((input, index) => {
            const td = document.createElement('td');
            td.style.padding = '10px';
            if (isView) {
                const inputField = input.find('p')[0];
                inputField.style.width = `${columns[index].width}px`;
                td.appendChild(inputField);
                tr.appendChild(td);
            } else {
                const inputField = input.find('input')[0];
                inputField.style.width = `${columns[index].width}px`;
                td.appendChild(inputField);
                tr.appendChild(td);
            }

            return true;
        });
        $tbody.append(tr);
        return true;
    });
    inputs.slice(6, inputs.length).map((inputsLine, indexLine) => {
        const $tbody = $(tableOhther).find('tbody');
        const tr = document.createElement('tr');

        if (indexLine === 8) {
            const grossTd = document.createElement('td');
            const label = document.createElement('span');
            if (isView) {
                label.innerHTML = '<b>Net</b>';
                grossTd.colSpan = 1;
            } else {
                label.style.float = 'right';
                label.style.marginTop = '7px';
                label.innerHTML = '<b>Net</b>';
                grossTd.colSpan = 1;
            }
            grossTd.appendChild(label);
            tr.appendChild(grossTd);
        }
        inputsLine.map((input, index) => {
            const td = document.createElement('td');
            td.style.padding = '10px';
            if (isView) {
                const inputField = input.find('p')[0];
                inputField.style.width = `${columns[index].width}px`;
                td.appendChild(inputField);
                tr.appendChild(td);
            } else {
                const inputField = input.find('input')[0];
                inputField.style.width = `${columns[index].width}px`;
                td.appendChild(inputField);
                tr.appendChild(td);
            }
            return true;
        });
        if (indexLine === 8) {
            const lastTd = document.createElement('td');
            tr.appendChild(lastTd);
        }
        $tbody.append(tr);
        return true;
    });

    return items;
};

const transformTimeOffSection = (type) => {
    const earningArea = $("[id$='CLM_INST_PRCHK_REMITINFO3']").first();
    const items = earningArea.children().children();
    const isView = type === 'view';
    const columns = [
        {
            width: 180,
            title: 'Type',
        },
        {
            width: 90,
            title: 'Accrued',
        },
        {
            width: 90,
            title: 'Used',
        },
        {
            width: 90,
            title: 'Remaining',
        },
    ];

    const table = createTransformTable(columns, 'TBODY_CLM_INST_PRCHK_REMITINFO3');
    earningArea.append($(table));
    earningArea.children().eq(0).hide();

    const earningsInputs = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
        const mod = i % 4;
        if (mod === 0) {
            earningsInputs.push([]);
        }
        const indexEarning = earningsInputs.length - 1;
        earningsInputs[indexEarning] =
            [
                ...earningsInputs[indexEarning],
                items.eq(i),
            ];
    }

    earningsInputs.map((inputsLine) => {
        const $tbody = $(table).find('tbody');
        const tr = document.createElement('tr');

        inputsLine.map((input, index) => {
            const td = document.createElement('td');
            td.style.padding = '10px';
            if (isView) {
                const inputField = input.find('p')[0];
                if (inputField) {
                    inputField.style.width = `${columns[index].width}px`;
                    td.appendChild(inputField);
                }

                tr.appendChild(td);
            } else {
                const inputField = input.find('input')[0];
                inputField.style.width = `${columns[index].width}px`;
                td.appendChild(inputField);
                tr.appendChild(td);
            }
            return true;
        });
        $tbody.append(tr);
        return true;
    });

    return items;
};

const transformEarningSection = (type) => {
    const earningArea = $("div[id$='CLM_INST_PRCHK_REMITINFO1']").first();
    const items = earningArea.children().children();
    const isView = type === 'view';
    const columns = isView ? [{
        width: 180,
        title: 'This Period',
    },
    {
        width: 180,
        title: 'YDT',
    }] : [
        {
            width: 180,
            title: 'Earnings Type',
        },
        {
            width: 80,
            title: 'Rate',
        },
        {
            width: 80,
            title: 'Hours',
        },
        {
            width: 180,
            title: 'This Period',
        },
        {
            width: 180,
            title: 'YDT',
        },
    ];

    const table = createTransformTable(columns, 'TBODY_CLM_INST_PRCHK_REMITINFO1');
    earningArea.append($(table));
    earningArea.children().eq(0).hide();

    const earningsInputs = [];

    const breakLineNumber = isView ? 2 : 5;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
        const mod = i % breakLineNumber;
        if (mod === 0) {
            earningsInputs.push([]);
        }
        const indexEarning = earningsInputs.length - 1;
        earningsInputs[indexEarning] =
            [
                ...earningsInputs[indexEarning],
                items.eq(i),
            ];
    }

    earningsInputs.map((inputsLine, indexLine) => {
        const $tbody = $(table).find('tbody');
        const tr = document.createElement('tr');

        if (indexLine === 8) {
            const grossTd = document.createElement('td');
            const grossLabel = document.createElement('span');
            if (isView) {
                grossLabel.innerHTML = '<b>Gross</b>';
                grossTd.colSpan = 1;
            } else {
                grossLabel.style.float = 'right';
                grossLabel.style.marginTop = '7px';
                grossLabel.innerHTML = '<b>Gross</b>';
                grossTd.colSpan = 3;
            }
            grossTd.appendChild(grossLabel);
            tr.appendChild(grossTd);
        }
        inputsLine.map((input, index) => {
            const td = document.createElement('td');
            td.style.padding = '10px';
            if (isView) {
                const inputField = input.find('p')[0];
                inputField.style.width = `${columns[index].width}px`;
                td.appendChild(inputField);
                tr.appendChild(td);
            } else {
                const inputField = input.find('input')[0];
                inputField.style.width = `${columns[index].width}px`;
                td.appendChild(inputField);
                tr.appendChild(td);
            }
            return true;
        });
        if (indexLine === 8) {
            const lastTd = document.createElement('td');
            tr.appendChild(lastTd);
        }
        $tbody.append(tr);
        return true;
    });

    return items;
};

const calcTotal = (fields) => {
    let gross = 0;
    fields.each((index, obj) => {
        const value = parseFloat(Number($(obj).val()));
        gross += value;
    });
    return gross;
};
const listnerFields = (beneForm) => {
    const grossField = beneForm.field('GROSS');
    grossField.shouldBeReadOnly(true);
    const fieldsGross = $(`[name=REMITAMOUNT1],
        [name=REMITAMOUNT1_2],
        [name=REMITAMOUNT1_3],
        [name=REMITAMOUNT1_4],
        [name=REMITAMOUNT1_5],
        [name=REMITAMOUNT1_6],
        [name=REMITAMOUNT1_7],
        [name=REMITAMOUNT1_8]`);
    fieldsGross.blur(() => {
        const gross = calcTotal(fieldsGross);
        grossField.setValue(gross);
    });

    const netField = beneForm.field('NET');
    netField.shouldBeReadOnly(true);
    const fieldsNet = $(`[name=REMITAMOUNT1_13],
        [name=REMITAMOUNT1_14],
        [name=REMITAMOUNT1_15],
        [name=REMITAMOUNT1_16],
        [name=REMITAMOUNT1_17],
        [name=REMITAMOUNT1_18],
        [name=REMITAMOUNT1_19],
        [name=REMITAMOUNT1_20],
        [name=REMITAMOUNT1_21],
        [name=REMITAMOUNT1_22],
        [name=REMITAMOUNT1_23],
        [name=REMITAMOUNT1_24],
        [name=REMITAMOUNT1_25],
        [name=REMITAMOUNT1_26]`)
        .blur(() => {
            const net = calcTotal(fieldsNet) * -1;
            netField.setValue(net);
        });
};


export const transformRemittanceUi = (beneForm) => {
    transformEarningSection();
    transformDeductionSection();
    transformTimeOffSection();
    listnerFields(beneForm);
};

export const transformRemittanceUiView = () => {
    transformEarningSection('view');
    transformDeductionSection('view');
    transformTimeOffSection('view');
};
