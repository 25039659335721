import Layout from '@glu/core/src/layout';
import GridApi from 'common/dynamicPages/api/grid';
import Constants from 'common/dynamicPages/api/constants';
import ContextApi from 'common/dynamicPages/api/context';

import template from './showCaseListView.hbs';

const ShowCaseListView = Layout.extend({
    template,

    onRender() {
        const context = ContextApi.menuContext.getContext(Constants.SHOWCASE_MENU_ITEM);

        const options = {
            context,
        };

        this.content.show(GridApi.createServiceGridView(options));
    },
});

export default ShowCaseListView;
