// TODO this file does not yet have test coverage and needs it ASAP
import BslCollection from './collection';
import http from '../http';
import util from '../util';

export default BslCollection.extend({
  constructor() {
    BslCollection.prototype.constructor.apply(this, arguments);
    this.initialModelDefinition();
  },

  // TODO likely makes more sense to just test if these exist at point of instantiation and throw an error if they do not.
  principalName: 'RealmPrincipal',
  principalSymbol: 'com.bottomline.banking.security.usergroup',
  principalKey: 'com.bottomline.banking.security.usergroup',

  getSort() {
    let symbol;

    if (this.sortKey) {
      symbol = this.symbols[this.sortKey];

      this.modelDefinition.sortFields = [{
        field: {
          name: this.sortKey,
          symbol,
          fieldType: 'STRING'
        },
        sortTypes: [{
          name: 'Ascending',
          description: 'Sort in ascending order.',
          symbol: 'ASC'
        }, {
          name: 'Descending',
          description: 'Sort in descending order.',
          symbol: 'DESC'
        }]
      }];
    }

    return BslCollection.prototype.getSort.apply(this, arguments);
  },

  getFilter() {
    if (!(this.filters && this.filters.length)) {
      return null;
    }

    this.modelDefinition.searchFields = this.filters.map(model => {
      const name = model.get('field');
      const symbol = this.symbols[name];

      return {
        mandatory: false,
        field: {
          name,
          symbol,
          fieldType: 'STRING'
        }
      };
    });

    return BslCollection.prototype.getFilter.apply(this, arguments);
  },

  fetch() {
    const self = this;

    // trigger 'desync' event in order to display 'Loading...' label in the grid
    this.trigger('desync');

    return new Promise(((resolve) => {
      const pageNumber = self.currentPage || 1;
      const recordsPerPage = self.recordsPerPage || 50;
      const start = (pageNumber - 1) * recordsPerPage;

      let query = self.getQuery();

      query.paginate(start, recordsPerPage);

      query = query.build();

      const result = query.apply(self.modelDefinition);

      const data = result.getNativeQuery();

      // TODO could use Marionette.getOption here for the URL (and throw an error if there is none)
      http.post(self.url, data, (resp) => {
        resp = resp || {
          rows: [],
          resultInfo: {}
        };

        const results = [];

        util.each(resp.rows, (row) => {
          results.push(row.values[0].resultValues[0].value);
        });

        self.totalCount = resp.resultInfo.resultCount || 0;

        self.set(results, {
          parse: true
        });

        self.trigger('sync', self, resp);
        resolve(self, resp);
      });
    }));
  },

  initialModelDefinition() {
    this.modelDefinition = {
      name: this.principalName,
      entity: {
        name: this.principalName,
        symbol: this.principalSymbol,
        key: this.principalKey
      },
      operators: [{
        name: 'Equals',
        description: 'Is equal to.',
        symbol: '='
      }, {
        name: 'Greater Than',
        description: 'Is greater than.',
        symbol: '>'
      }, {
        name: 'Greater Than or Equals',
        description: 'Is greater than or equal to.',
        symbol: '>='
      }, {
        name: 'Less Than',
        description: 'Is Less than.',
        symbol: '<'
      }, {
        name: 'Less Than or Equals',
        description: 'Is less than or equal to.',
        symbol: '<='
      }, {
        name: 'Not equals',
        description: 'Is not equal to.',
        symbol: '<>'
      }, {
        name: 'Between',
        description: 'Value is between provided values.',
        symbol: 'BETWEEN'
      }, {
        name: 'Contains',
        description: 'Value is contains substring.',
        symbol: 'CONTAINS'
      }]
    };
  }
});

