import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import services from 'services';
import { postData, getData } from 'common/util/services';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import LocationCollection from '../collections/locationCollection';
import EntitledAccountCollection from '../collections/entitledAccountCollection';
import errorTemplate from '../../common/views/errorView.hbs';

const RDC_CONFIG = 'imaging/remoteDepositCapture/getRDCConfig';

export default Layout.extend({
    loadingTemplate,
    className: 'remote-deposit-capture',

    initialize() {
        this.accounts = new EntitledAccountCollection();
        this.locations = new LocationCollection();
    },

    /**
     * Calls api to retrive system availability response
     * @return {Promise} API response
     */
    isSystemAvailable() {
        return getData(services.rdcIsSystemAvailable).then((response) => {
            if (response === false) {
                return Promise.reject(new Error('RDC System not available'));
            }
            return response;
        });
    },

    /**
     * Get rdc configuration from the server
     * @returns {Promise} API Response
     */
    getRDCConfig() {
        return postData(services.generateUrl(RDC_CONFIG));
    },

    loadRequiredData() {
        Promise.all([this.isSystemAvailable(), this.getRDCConfig()])
            .then(this.evaluateConfig.bind(this))
            .then(this.fetchCollections.bind(this))
            .catch(this.showErrorView.bind(this));
    },

    /**
     * Evaluate isSystemAvailable and useLocation requests and conditiaonlly
     * show error views and set userLocationWorkflow flag
     * @param {array} response - array of response objects from Promise.all
     */
    evaluateConfig(response) {
        if (!Array.isArray(response) || !response[0]) {
            this.showErrorView();
        }

        if (response.length > 1) {
            this.useLocationWorkflow = response[1].locationEnabled;
            this.amountValidationWorkflow = response[1].amountValidation;
            this.accounts.setUseLocationWorkflow(response[1].locationEnabled);
        }
    },

    /**
     * Get all of the collections required for the view
     */
    fetchCollections() {
        if (this.useLocationWorkflow) {
            this.locations.fetch().then(this.setLoadedAndRender.bind(this));
        } else {
            this.accounts.fetch().then(this.setLoadedAndRender.bind(this));
        }
    },

    /**
     * Update the model with error title and message, then update the template
     * to use the errorTemplate
     */
    showErrorView() {
        this.model.set({
            title: locale.get('RDC.ProductName'),
            message: locale.get('logon.ssoSignin'),
        });
        this.template = errorTemplate;
        this.setLoadedAndRender();
    },

    /**
     * Indicate that the view has required data and render the view
     */
    setLoadedAndRender() {
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    /**
     * Show alert with error message from the response
     * Eventually all error handling for RDC will be moved to action
     * response structure, at which point this function will be
     * removed
     * @param {Object} err - Error message object from the server
     */
    onServiceError(err) {
        this.setButtonBusy(false);
        dialog.error(err.responseJSON.message.join(' '));
    },

    /**
     * Show alert with error message from the action response
     * @param {Object} response - Action response error object from the server
     */
    actionResponseError(response) {
        this.setButtonBusy(false);
        dialog.error(response.responseJSON.actionResponse.message.join(' '));
    },

    /**
     * On input blur, validate this attribute of the model
     * @param {Object} e - blur event
     */
    onInputBlur(e) {
        this.validateAttribute(e.target.name);
    },

    /**
     * Validate the field that has changed and enable/disable the submit button
     * @param attribute
     */
    validateAttribute(attribute) {
        this.model.validateField(attribute);
        this.enableDisableAction();
    },

    /**
     * @abstract
     */
    enableDisableAction() {
        throw new Error('Must implement enableDisableAction function');
    },

    /**
     * @abstract
     */
    setButtonBusy() {
        throw new Error('Must implement setButtonBusy function');
    },

    /**
     * Based on the account number set in the model, locate the account from the
     * collection
     * and extract bank code
     * @param collection -
     * @param model - model that was updated
     */
    setBankCode(collection, model) {
        const account = collection.findWhere({
            ACCOUNTNUM: model.get('ACCOUNTNUM'),
        });
        if (account) {
            model.set('BANKCODE', account.get('BANKCODE'));
        }
    },
});
