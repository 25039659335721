import $ from 'jquery';
import util from '@glu/core/src/util';
import moment from 'moment';
import ItemView from '@glu/core/src/itemView';
import Formatter from 'system/utilities/format';
import userInfo from 'etc/userInfo';
import tilePaymentsTmpl from './tilePayments.hbs';

export default ItemView.extend({
    initialize() {
        $(window).resize(this.handleResize);
    },

    template: tilePaymentsTmpl,

    ui: {
        $navbar: 'nav.glu-menu',
    },

    attributes: {
        'data-qa': 'tile-container',
    },

    events: {
        'blur @ui.$all': 'handleBlur',
    },

    /**
     * Keep our status' one word
     */
    compressedStatus() {
        let formattedStatus = util.findWhere(
            this.options.model.get('columns'),
            {
                fieldName: 'STATUS_DESCRIPTION',
            },
        ).fieldValue;

        switch (formattedStatus) {
        case 'Pending Approval':
            formattedStatus = 'pendingApproval';
            break;
        default:
            break;
        }
        return formattedStatus;
    },

    getFormattedAmount(value) {
        /*
         * use the localized formatter in the util object which allows
         * localization formatting by user and other preferences
         */
        return Formatter.formatCurrency(value);
    },

    templateHelpers() {
        return {
            beneficiary: util.findWhere(
                this.options.model.get('columns'),
                {
                    fieldName: 'VENDORNAME',
                },
            ).fieldValue,

            paymentAmount: this.getFormattedAmount(util.findWhere(
                this.options.model.get('columns'),
                {
                    fieldName: 'TOTALAMOUNT',
                },
            ).fieldValue),

            accountNumber: util.findWhere(
                this.options.model.get('columns'),
                {
                    fieldName: 'VENDORID',
                },
            ).fieldValue,

            dueDate: moment(util.findWhere(
                this.options.model.get('columns'),
                {
                    fieldName: 'DUEDATE',
                },
            ).fieldValue).format('MM/DD/YYYY'),

            preferredFormat: userInfo.getCurrencyFormat(),
            dateFormat: userInfo.getDateFormat(),
            status: this.compressedStatus(),
        };
    },
});
