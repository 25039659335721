import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import ReportTypeCollection from 'app/administration/collection/adminReporting/reportTypes';

export default ReportTypeCollection.extend({

    getCollectionOptions(options) {
        return util.extend({}, options, {
            includeMapData: 1,
            inquiryId: this.data.inquiryId,
            productCode: 'SMAINT',
            functionCode: 'MAINT',
            typeCode: 'STATSRPT',
            customFilters: [{
                filterName: 'Depends',
                filterParam: ['REPORTTYPECODE', this.data.REPORTTYPECODE],
            }],
        });
    },

    parse(response) {
        return util.map(response, (row) => {
            const pair = transform.pairsToHash(row.mapDataList, 'toField', 'value');
            pair.CODE = row.name;
            pair.DESCRIPTION = row.label;
            return pair;
        });
    },

});
