var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"radio-inline\">\n        <input "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":15},"end":{"line":5,"column":53}}})) != null ? stack1 : "")
    + " type=\"radio\" id=\"fiscal-year-"
    + alias4(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":5,"column":83},"end":{"line":5,"column":91}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\" data-year=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":5,"column":114},"end":{"line":5,"column":122}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"offset") || (depth0 != null ? lookupProperty(depth0,"offset") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offset","hash":{},"data":data,"loc":{"start":{"line":5,"column":131},"end":{"line":5,"column":141}}}) : helper)))
    + "\" name=\"fiscal-year-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\">\n        <label for=\"fiscal-year-"
    + alias4(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":40}}}) : helper)))
    + "-"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"year") || (depth0 != null ? lookupProperty(depth0,"year") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data,"loc":{"start":{"line":6,"column":52},"end":{"line":6,"column":60}}}) : helper)))
    + "</label>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<legend class=\"sr-only\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"cashflow.fiscal.year",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":57}}}))
    + "</legend>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":8,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});