import '../../themeDefaults';
import { createUseStyles } from '@glu/theming';

const styles = ({ grid }) => ({
  children: {
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    font: 'inherit',
    padding: 0
  },
  closeOnFocusButton: {
    border: 'none',
    height: 0,
    margin: 0,
    padding: 0,
    position: 'absolute',
    width: 0
  },
  content: {
    boxShadow: grid.boxShadow,
    left: 0,
    position: 'absolute',
    top: 0,
    zIndex: 99999
  }
});

export default createUseStyles(styles);
