var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <ul class=\"NavTabs nav nav-tabs is-open\" role=\"tablist\"\n                data-hook=\"getNavTabs\">\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/addServices\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":57},"end":{"line":16,"column":91}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item is-active\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/addProfile\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.defaults",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":56},"end":{"line":22,"column":90}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/addPaymentLimits\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":62},"end":{"line":28,"column":94}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/externalWidgets\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.bankwidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":61},"end":{"line":34,"column":98}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/elearning\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.elearning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":55},"end":{"line":40,"column":90}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary submit\" data-action=\"save\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":88},"end":{"line":52,"column":117}}}))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\" class=\"btn btn-primary submit\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":88},"end":{"line":54,"column":121}}}))
    + "</button>\n                <button type=\"button\" class=\"btn btn-secondary \" data-action=\"back\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":84},"end":{"line":55,"column":113}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":62}}}))
    + "</h1>\n\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n\n    <section class=\"section section-container\" role=\"navigation\">\n        <div class=\"section-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"pageRegion remove-section-styles\" region=\"pageRegion\"></div>\n        </div>\n\n        <div>\n            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":56,"column":19}}})) != null ? stack1 : "")
    + "\n                <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":85},"end":{"line":58,"column":116}}}))
    + "</button>\n            </div>\n        </div>\n    </section>\n\n</div>\n";
},"useData":true});