import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';

const DeeplinkModel = Model.extend({

    sync(method, model, options) {
        const url = services.generateUrl('/workspaces/getAllDeeplinks');

        http.get(url, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },

});

export default new DeeplinkModel();
