const evaluateAppRoot = function () {
    let definedAppRoot = `${window.Bottomline.appRoot}/`;
    const definedDocumentRoot = window.Bottomline.documentRoot;
    const path = window.location.pathname;
    let addOn = '';

    /**
     * If the document root is defined, take it into account.
     * The document root is the top level directory if it exists and it can be
     * several layers deep
     * */
    if (definedDocumentRoot) {
        definedAppRoot = `${definedDocumentRoot}/${definedAppRoot}`;
        if (definedAppRoot.indexOf('/') === 0) {
            definedAppRoot = definedAppRoot.slice(1);
        }
    }

    // check for no trailing slash
    if (path.indexOf(definedAppRoot) < 0) {
        definedAppRoot = definedAppRoot.slice(0, definedAppRoot.lastIndexOf('/'));
        addOn = '/';
    }
    const pathIndex = path.indexOf(definedAppRoot);
    return path.slice(0, pathIndex) + definedAppRoot + addOn;
};

export default {
    appRoot: evaluateAppRoot(),
    environment: 'dev',
    adminPath: 'ui-admin',
    adminPortalPath: 'ui-admin-portal',
    clientPath: 'ui',
    clientPortalPath: 'ui-portal',

    // PCM - PTX RELATED
    ptxPathsArray: [
        'api/cheques',
        'api/batches',
        'api/printjobs',
        'api/preauth',
        'api/auth/preauth',
        'api/printers',
        'api/documents',
        'api/apps',
        'api/projects',
        'api/roles',
        'api/chequebooks',
        'api/profiles',
        'api/mailingprofiles',
        'api/verifydomain',
        'api/logos',
        'api/domlogs',
        'api/outbox-activity',
    ],

    isPtxConnectApiCall(ajaxUrl) {
        let isOneOfPtxPaths = false;
        for (let i = 0; i < this.ptxPathsArray.length; i += 1) {
            const isPtxPath = ajaxUrl.indexOf(this.ptxPathsArray[i]) > -1;
            if (isPtxPath) {
                isOneOfPtxPaths = true;
                break; // no need to iterate other items.
            }
        }

        return isOneOfPtxPaths;
    },

    isPtxConnectRoute() {
        const { pathname } = window.location;

        // full route/url - /ui/PAYMENTS/documents/cheques (also nested endpoints)
        const isPtxCheques = pathname.indexOf('ui/PAYMENTS/documents/') > -1;

        // PCM-1484.
        // full route/url - /ui/ADMINSTRATION/documents/printers (also /printer/queue)
        const isPtxPrinters = pathname.indexOf('ui/ADMINSTRATION/documents/printer') > -1
        || pathname.indexOf('/printers') > -1;

        // PCM-1981.
        // full route/url - /ui/ADMINSTRATION/documents/printers (also /printer/queue)
        const isPtxApps = pathname.indexOf('ui/ADMINSTRATION/documents/apps') > -1
        || pathname.indexOf('ui/apps') > -1
        || pathname.indexOf('ui-portal/ADMINSTRATION/documents/apps') > -1
        || pathname.indexOf('ui-portal/apps') > -1;
        const isPtxProjects = pathname.indexOf('ui-admin-portal/ADMINSTRATION/documents/projects') > -1
        || pathname.indexOf('ui-admin-portal/projects') > -1;
        const isPtxChequeBooks = pathname.indexOf('ui-admin-portal/ADMINSTRATION/documents/chequebooks') > -1
        || pathname.indexOf('ui-admin-portal/chequebooks') > -1;
        const isPtxRoles = pathname.indexOf('ui-admin-portal/ADMINSTRATION/documents/roles') > -1
        || pathname.indexOf('ui-admin-portal/roles') > -1;
        const isPtxProfiles = pathname.indexOf('ui-admin-portal/ADMINSTRATION/documents/profiles') > -1
        || pathname.indexOf('ui-admin-portal/profiles') > -1
        || pathname.indexOf('profiles/cheque') > -1;
        const isPtxClientRoles = pathname.indexOf('ui/ADMINSTRATION/documents/clientRoles') > -1
        || pathname.indexOf('ui/ADMINSTRATION/documents/roles') > -1;
        const isPtxClientPrinters = pathname.indexOf('ui/ADMINSTRATION/documents/clientPrinters') > -1
        || pathname.indexOf('/clientPrinters') > -1;
        const isPtxAdminPrinters = pathname.indexOf('ui/ADMINSTRATION/documents/adminPrinters') > -1;
        const isPtxOutbox = pathname.indexOf('ui-admin-portal/ADMINSTRATION/documents/outbox') > -1
        || pathname.indexOf('ui-admin-portal/outbox') > -1;

        return isPtxCheques
            || isPtxPrinters
            || isPtxApps
            || isPtxProjects
            || isPtxChequeBooks
            || isPtxRoles
            || isPtxProfiles
            || isPtxClientRoles
            || isPtxClientPrinters
            || isPtxAdminPrinters
            || isPtxOutbox;
    },

    // Idea of this function is to have check OTHER PT-X Connect related routes/pages/URLs
    isPtxConnectRelatedRoutes() {
        const { pathname } = window.location;
        // full route/url - /ui/PAYMENTS/viewCheck/470/616/gpchk/checkPreview/
        const isCheckPreview = pathname.indexOf('/checkPreview/') > -1;
        const isAchApplication = pathname.indexOf('/ach-esend-management') > -1; // PCM-2315
        const isCheque = pathname.indexOf('/check-management') > -1;
        const isProfile = pathname.indexOf('profiles/standard') > -1;
        return isCheckPreview || isAchApplication || isCheque || isProfile;
    },

    isDev() {
        return this.environment === 'dev';
    },

    isProd() {
        return this.environment === 'prod';
    },

    isPortal() {
        return (window.Bottomline.appRoot === this.clientPortalPath
            || window.Bottomline.appRoot === this.adminPortalPath);
    },
    // PCM RELATED
    isClientOnly() {
        return (window.Bottomline.appRoot === this.clientPath);
    },

    isAdminPortal() {
        return (window.Bottomline.appRoot === this.adminPortalPath);
    },

    isAdmin() {
        return (window.Bottomline.appRoot === this.adminPath
            || window.Bottomline.appRoot === this.adminPortalPath);
    },

    isClient() {
        return (window.Bottomline.appRoot === this.clientPath
            || window.Bottomline.appRoot === this.clientPortalPath);
    },

    getAdminUrl() {
        if (this.isPortal()) {
            return `${window.Bottomline.documentRoot}/${this.adminPortalPath}`;
        }
        return `${window.Bottomline.documentRoot}/${this.adminPath}`;
    },

    getClientUrl() {
        if (this.isPortal()) {
            return `${window.Bottomline.documentRoot}/${this.clientPortalPath}`;
        }
        return `${window.Bottomline.documentRoot}/${this.clientPath}`;
    },

    // super hacky, but hopefully short lived
    getOldAdminUrl() {
        return '/admin/webseries';
    },

    isAdminCheckMgtApp: () => {
        const { pathname } = window.location;
        return pathname.includes('/ui-admin-portal/') || pathname.includes('/ui-admin/');
    },
};
