import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import transform from 'common/util/transform';
import services from 'services';
import httpError from 'common/util/httpErrorResult';

export default Model.extend({
    initialize(options) {
        this.method = options.DELIVERYMETHOD;
        this.setValidators();
    },

    sync(method, model, options) {
        const data = this.convertModelAttributesToServerJSON(model);

        http.post(services.generateUrl('/cminst/photocopy/add'), data, (result) => {
            options.success(result);
        }, httpError.getResult(options, model));
    },

    convertModelAttributesToServerJSON(model) {
        return {
            item: transform.hashToPairs(model.attributes),
        };
    },

    setValidators() {
        this.validators = {};

        switch (this.method) {
        case 'EMAIL':
            this.validators.EMAILADDRESS = {
                description: locale.get('administration.emailAddress'),
                exists: true,
            };
            break;
        case 'FAX':
            this.validators.FAXNUMBER = {
                description: locale.get('allReports.Fax'),
                exists: true,
            };
            break;
        default:
            break;
        }
    },
});
