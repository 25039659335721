import LimitDataEntitlements from 'app/administration/collection/user/limitDataEntitlements';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import Grid from '@glu/grid';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import AssignedAccountsView from '../../payments/assignedAccounts/assignedAccounts';

export default AssignedAccountsView.extend({

    initialize(options) {
        this.dataEntitlementConfig = this.model.get('simpleDataEntitlements');
        this.userGroup = options.userGroup;
        this.mode = options.mode;
        this.entity = options.isRole ? 'role' : 'uce';

        if (this.availableAccounts && this.availableAccounts.fetched) {
            this.setHasLoadedRequiredData(true);
        }

        this.availableAccounts = this.availableAccounts || new LimitDataEntitlements(
            [],
            {
                inquiryId: this.dataEntitlementConfig.get('inquiryId'),
                paymentType: this.dataEntitlementConfig.get('type'),
                keyCol: this.dataEntitlementConfig.get('keyCol'),
                configuredDataType: this.dataEntitlementConfig.get('configuredDataType'),
                userGroup: this.userGroup,
                entitledTypes: this.options.entitledTypes,
            },
        );
        this.availableAccounts.unselected = true;
        this.selectedAccounts = this.dataEntitlementConfig.get('dataEntitlements');

        // when adding new accounts we need to properly default the permissions
        this.selectedAccounts.on('reset', (newList, opts) => {
            newList.each((newAccount) => {
                // only update new additions
                if (!util.contains(opts.previousModels, newAccount)) {
                    newAccount.entitleAll();
                }
            });
        });

        this.listenTo(this.model.get('entitlementsByType'), 'change', (model) => {
            const entitled = model.get('entitled');
            model.set({
                'view.entitled': entitled,
                'view.applicable': entitled,
            });
        });
    },

    onRender() {
        infoTooltipHelper.setupInfoTooltips(this);

        if (this.hasLoadedRequiredData()) {
            const cols = [{
                field: 'CLIENTACCOUNTNAME',
                label: locale.get('uce.name'),
            }, {
                field: 'ACCOUNTNUM',
                label: locale.get('uce.accountNumber'),
            },
            ];

            this.grid = new Grid({
                columns: cols,
                collection: this.selectedAccounts,
                emptyViewText: locale.get('uce.emptyGrid.bankAccounts'),
                el: this.$('.bank-accounts-grid'),
            }).render();

            const checked = this.dataEntitlementConfig.get('assignAll');

            this.ui.$assignAllChk.prop('checked', checked);
            this.ui.$gridSection.toggle(!checked);
            this.ui.$assignAllMsg.toggle(checked);
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * Override function.for simple types
     * Returns entitled actions
     * @return {Array}
     */
    getApplicableActions() {
        this.model.get('entitlementsByType').getEntitledActions();
    },

    /**
     * Overrides the assignedAccounts method.
     * This should just be a noop.
     */
    saveHandler() {

    },

});
