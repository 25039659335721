var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <th scope=\"col\" colspan=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"colspan") : depths[1]), depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</th>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                        <th scope=\"col\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <td>"
    + container.escapeExpression(lookupProperty(helpers,"amount").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":21,"column":51}}}))
    + "</td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <td>\n                                        "
    + container.escapeExpression(lookupProperty(helpers,"amount").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":40,"column":40},"end":{"line":40,"column":55}}}))
    + "\n                                </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n        <thead>\n                <tr>\n                        <td></td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"months") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":8,"column":33}}})) != null ? stack1 : "")
    + "                </tr>\n                <tr>\n                        <td></td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"labels") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":14,"column":33}}})) != null ? stack1 : "")
    + "                <tr>\n        </thead>\n        <tbody>\n                <tr>\n                        <th scope=\"row\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.inflow",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":40},"end":{"line":19,"column":68}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"inflows") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":22,"column":33}}})) != null ? stack1 : "")
    + "                </tr>\n                <tr>\n                        <th scope=\"row\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.outflow",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":40},"end":{"line":25,"column":69}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"outflows") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":28,"column":33}}})) != null ? stack1 : "")
    + "                </tr>\n                <tr>\n                        <th scope=\"row\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.total",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":40},"end":{"line":31,"column":67}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"totals") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":34,"column":33}}})) != null ? stack1 : "")
    + "                </tr>\n                <tr>\n                        <th scope=\"row\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.plan1.diff",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":40},"end":{"line":37,"column":72}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"diffs") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":42,"column":33}}})) != null ? stack1 : "")
    + "                </tr>\n        </tbody>\n";
},"useData":true,"useDepths":true});