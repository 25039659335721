export default () => ({
  '@global': {
    body: {
      // To make column menu btn right alignment
      '& .ag-react-container': {
        width: 'inherit'
      },
      // To make column menu visible outside of parent
      '& .ag-header-row, .ag-header-cell': {
        overflow: 'visible'
      }
    }
  },
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  sortWrapper: {
    width: '100%',
    border: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    padding: 0
  }
});
