import Model from '@glu/core/src/model';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import http from '@glu/core/src/http';

export default Model.extend({
    defaults: {
        REQUESTFORINFO_REASON_FLAG: 'I',
        REQUESTFORINFO_REASON_INFO_CODE: '',
        REQUESTFORINFO_REASON: '',
        ADDITIONAL_INFO: '',
        PAYMENT_ID: '',
        MESSAGE_REQUIRED: '',
        disableReason: {
            missing: false,
            incorrect: false,
        },
    },

    validators: {
        ADDITIONAL_INFO: {
            description: 'Message',
            maxLength: 140,
        },
    },

    setMessageValidators() {
        const removeExists = ({
            exists,
            ...rest
        }) => rest;
        const addExists = obj => ({
            ...obj,
            exists: true,
        });
        // if reason is Other which has a code of NARR
        if (this.get('REQUESTFORINFO_REASON_INFO_CODE') === 'NARR' || this.get('RESPONSE_MODE')) {
            this.modifyValidator(addExists, 'ADDITIONAL_INFO');
            this.set('MESSAGE_REQUIRED', true);
        } else {
            this.modifyValidator(removeExists, 'ADDITIONAL_INFO');
            this.set('MESSAGE_REQUIRED', false);
        }
    },

    modifyValidator(changeFn, key) {
        const oldValidator = this.validators[key];
        if (oldValidator === undefined) {
            return false;
        }
        this.removeValidator(key);
        const newValidator = changeFn(oldValidator);
        this.addValidator(key, newValidator);
        return true;
    },

    getRFIItem() {
        const item = [{
            name: 'INCORRECT_MISSING_FLAG',
            value: this.get('REQUESTFORINFO_REASON_FLAG'),
        }, {
            name: 'INFO_CODE',
            value: this.get('REQUESTFORINFO_REASON_INFO_CODE'),
        }, {
            name: 'ADDITIONAL_INFO',
            value: this.get('ADDITIONAL_INFO'),
        }, {
            name: 'PAYMENT_ID',
            value: this.get('PAYMENT_ID'),
        }];

        return { item };
    },

    getPAItem() {
        return {
            item: [
                {
                    name: 'PAYMENT_ID',
                    value: this.get('PAYMENT_ID'),
                }, {
                    name: 'ADDITIONAL_INFO',
                    value: this.get('ADDITIONAL_INFO'),
                },
            ],
        };
    },

    getResponseItem() {
        return {
            item: [
                {
                    name: 'PAYMENT_ID',
                    value: this.get('TNUM'),
                },
                {
                    name: 'REMITTANCE_INFO',
                    value: this.get('ADDITIONAL_INFO'),
                },
            ],
        };
    },

    sync() {
        let url;
        let item;
        let data;
        if (this.get('RFI_MODE')) {
            url = services.generateUrl(`${constants.URL_RTP_REQUEST_FOR_INFO}/add`);
            item = this.getRFIItem();
            data = {
                items: [
                    item,
                ],
            };
        } else if (this.get('PA_MODE')) {
            url = services.generateUrl(`${constants.URL_RTP_PAYMENT_ACKNOWLEDGEMENT}/add`);
            item = this.getPAItem();
            data = item;
        } else {
            url = services.generateUrl(`${constants.URL_RTP_RESPONSE_TO_REQUEST_FOR_INFO}/add`);
            item = this.getResponseItem();
            data = item;
        }

        return new Promise((resolve, reject) => {
            http.post(url, data).then(resolve, reject);
        });
    },

});
