import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './transmissionProfiles.hbs';

const TransmissionProfiles = ListView.extend({

    events: util.extend({}, ListView.prototype.events, {
        'click [data-hook="getAddTransmissionProfile"]': 'addTransmissionProfile',
        'click [data-hook="print-button"]': 'showPrintOptionsModal',
    }),

    template,

    initialize(options) {
        const superOptions = {
            menuCategory: 'REPORTING',
            serviceName: 'export/transmissionProfile',
            serviceFunc: null,
            businessType: null,
            context: 'TRANSMISSION_PROFILES',
            selector: 'rowSelector',
            menuContext: 'export/transmissionProfile',
        };
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    /**
     * Handle view action
     * @param  {object} item Model associated with item choosen for modification in view
     */
    gridRowSelect(item) {
        store.set(`${this.contextKey}-actionModel`, item.model);
        this.navigateTo('REPORTING/transmissionProfiles/view');
        return Promise.resolve();
    },

    /**
     * Navigate to the insert route for transmission profiles
     */
    addTransmissionProfile() {
        this.navigateTo('REPORTING/transmissionProfiles/insert');
        return Promise.resolve();
    },

    /**
     * Use the bulk action delete for a single item
     * TODO remove when all grid APIs support delete with an array of items,
     * be it bulk delete or row action
     * @param {Object} options - grid row action options object
     */
    gridRowDelete(options) {
        this.processMultiActionsAsOne(constants.ACTION_DELETE, [options.model]);
        return Promise.resolve();
    },

    /**
     * Get print options for this list
     * @returns {Object}
     */
    getPrintOptions() {
        return {
            inquiryId: constants.INQUIRY_ID_72209,
        };
    },

    /**
     * Never pulls the right contextDef from the menu, as transmisison profiles
     * are not actually in the menu, so the product code is always undefined.
     * Therefore, pass the product code specifically into the prototype call.
     * @override
     */
    runProcessMultiActionsAsOne() {
        return ListView.prototype.runProcessMultiActionsAsOne.call(this, 'GIR');
    },
});

let list = TransmissionProfiles;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'GIR.Add.Transmission.Profile',
                entitlement: 'INSERT',
                handlerMethodName: 'addTransmissionProfile',
            },
        ],
    });
    list = list.extend(mobileList);
}

const exportedList = list;

export default exportedList;
