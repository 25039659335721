var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                        <td>\n                                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"ssoLogin\" typecode=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"typecode") : depth0), depth0))
    + "\"\n                                 menucode=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"menucode") : depth0), depth0))
    + "\" productcode=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productcode") : depth0), depth0))
    + "\" requestType=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"requestType") : depth0), depth0))
    + "\">\n                                 <span class=\"icon-new-tab\"></span> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</button>\n                        </td>\n                </tr>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"htmlContent") || (depth0 != null ? lookupProperty(depth0,"htmlContent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"htmlContent","hash":{},"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":21,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<iframe name=\"ssoFrame\" height=\"100%\" width=\"100%\"></iframe>\n"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"myForm") || (depth0 != null ? lookupProperty(depth0,"myForm") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"myForm","hash":{},"data":data,"loc":{"start":{"line":25,"column":0},"end":{"line":25,"column":12}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<iframe name=\"ssoFrame\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"iframeSrc") || (depth0 != null ? lookupProperty(depth0,"iframeSrc") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"iframeSrc","hash":{},"data":data,"loc":{"start":{"line":28,"column":29},"end":{"line":28,"column":42}}}) : helper)))
    + "\" height=\"100%\" width=\"100%\"></iframe>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"alertRegion\"></div>\n\n<table class=\"table\">\n        <thead>\n                <tr><th scope=\"col\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"payment.outbound.sso.list.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":80}}}))
    + "</th></tr>\n        </thead>\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sso") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "        </tbody>\n</table>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"htmlContent") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":0},"end":{"line":22,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"postRedirect") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"iframeSrc") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":29,"column":7}}})) != null ? stack1 : "");
},"useData":true});