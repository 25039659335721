import React, { useRef, createContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  originalRowProp, errorsProp, createEditedRowData, hasRowChanged, setDataForRow,
  doValidationForRow, getFilteredEditedData, fromEntries, validateData
} from './editUtils';

/**
 * Context used to store the edited rows of a row based dataset by row id.
 *
 * @alias EditContext
 * @type {React.Context<{}>}
 */
export const EditContext = createContext({});

/**
 * The value provided by the EditContext is a collection of functions and props that compromise
 * the EditContextApi and provide a means of interacting with the data contained in the context.
 * With it you can edit data, validate it, retrieve it, etc.
 *
 * @typedef {class} EditContextApi
 */

/**
 * Provider of a context to store the edited rows of a row based dataset by row id, keeping
 * track of all changes to this data done with the editDataForRow function. Provides a very low
 * overhead way to gather the edits and then validate them by row, by field, or by the entire
 * set of edited data.
 *
 * The provider provides an API via the EditContext's value which is documented as functions of
 * EditProvider.
 *
 * @param {Object} props - EditProvider props
 */
export const EditProvider = ({
  allowEditToUndefined, children, columns, preserveUnchangedRows, singleFieldValidation
}) => {
  const dataRef = useRef({});
  const errorCallbacksByRowIdRef = useRef({});

  /**
   * Edit the data for a a single field on a given row of data.
   *
   * Note: The actions performed by this function are affected by the preserveUnchangedRows and
   * singleFieldValidation props.
   *
   * @memberof EditContextApi
   * @param {string|number} rowId - Id of the row being edited
   * @param {Object} rowData - Hash of the current data for the row. This must be the original
   *    data at least the first time this function is called as it is stored as the original
   *    data for the row the first time and used to compare against future edits.
   * @param {string} field - The field of data being edited
   * @param {*} fieldValue - The new value to set for the field
   * @param {boolean} validate - Whether to perform validation after storing the edited data.
   * @return {Promise|undefined} - If validate is true and columns were provided with
   *    validators then a Promise which will resolve with the validation results, otherwise
   *    undefined.
   */
  const editDataForRow = useCallback(({
    field, fieldValue, rowData, rowId, validate
  }) => {
    // merge the current base row with the prior edits and the current field edit
    const editedRowData = createEditedRowData(
      rowId, rowData, field, fieldValue, dataRef, allowEditToUndefined
    );
    const isRowChanged = hasRowChanged(editedRowData);
    const saveEditedRow = (
      // we want to save the edited row data if it has changed from the original
      // or if it had previously changed and we are preserving row data in the edit context
      // even if the user changes it back
      hasRowChanged(editedRowData) || (dataRef.current[rowId] && preserveUnchangedRows)
    );
    const lastErrors = (dataRef.current[rowId] || {})[errorsProp];

    if (!isRowChanged && lastErrors) {
      delete editedRowData[errorsProp];
    }

    setDataForRow(
      dataRef,
      rowId,
      saveEditedRow ? editedRowData : undefined
    );

    if (validate && isRowChanged) {
      return doValidationForRow(
        columns, rowId, editedRowData, dataRef, errorCallbacksByRowIdRef.current,
        singleFieldValidation ? field : undefined
      );
    }

    if (!isRowChanged && lastErrors) {
      return new Promise((resolve) => {
        if (lastErrors && errorCallbacksByRowIdRef.current[rowId]) {
          errorCallbacksByRowIdRef.current[rowId].forEach((callback) => callback(undefined));
        }
        resolve(undefined);
      });
    }

    return undefined;
  }, [
    allowEditToUndefined, preserveUnchangedRows, columns, singleFieldValidation
  ]);

  /**
   * Returns all data contained in the EditContext in a hash, by rowId.
   *
   * Note that by default this will include, on each row's data, the originalRowProp symbol
   * property as well as potentially the errorsProp symbol property. If this is undesirable
   * passing true for the stripSymbols argument will remove them. Additionally the
   * preserveUnchangedRows prop will affect the data stored in the context and whether the
   * filterUnchangedRows argument has any effect.
   *
   * @memberof EditContextApi
   * @param {boolean} [filterUnchangedRows=false] - If true only rows that have been changed
   *    from their original values will be included. This has no effect if the
   *    preserveUnchangedRows prop is false as rows will not be kept in the context if they are
   *    unchanged.
   * @param {boolean} [stripSymbols=false] - If true the originalRowProp and errorsProp symbol
   *    properties will be stripped of the data for each row.
   * @returns {Object<string|number, Object>} - A hash, by rowId, of all the edited data
   *    contained in the EditContext.
   */
  const getEditedData = useCallback((filterUnchangedRows, stripSymbols) => (
    // if we are purging unchanged rows there is no need or desire to filter out unchanged ones
    getFilteredEditedData(
      dataRef, !preserveUnchangedRows ? false : filterUnchangedRows, stripSymbols
    )
  ), [preserveUnchangedRows, dataRef]);

  /**
   * Adds a callback for the row identified by rowId.  This callback will be called whenever
   * validation is performed for the row identified by rowId and passed the errors for that row.
   *
   * @memberof EditContextApi
   * @param {string|number} rowId - The id of the row for which we would like the current errors
   *    whenever it is validated.
   * @param {function} callback - Callback to call whenever the row identified by rowId is validated
   */
  const addErrorCallbackForRow = useCallback((rowId, callback) => {
    errorCallbacksByRowIdRef.current[rowId] = [
      ...(errorCallbacksByRowIdRef.current[rowId] || []).filter((cb) => cb !== callback),
      callback
    ];
  }, []);

  /**
   * Removes a error callback for the row identified by rowId.
   *
   * @memberof EditContextApi
   * @param {string|number} rowId - The id of the row for which we would like to remove an
   *    error callback.
   * @param {function} callback - Callback to remove.
   */
  const removeErrorCallbackForRow = useCallback((rowId, callback) => {
    const newCallbacks = (errorCallbacksByRowIdRef.current[rowId] || []).filter(
      (cb) => cb !== callback
    );

    if (newCallbacks.length) {
      errorCallbacksByRowIdRef.current[rowId] = newCallbacks;
    } else {
      delete errorCallbacksByRowIdRef.current[rowId];
    }
  }, []);

  /**
   * Get all the current errors for the row identified by rowId.
   *
   * Note this does not include any new errors for the field they are currently editing.
   * Additionally if the singleFieldValidation prop is true it will not include any errors
   * for fields they have not edited yet
   *
   * @memberof EditContextApi
   * @param {string|number} rowId - The id of the row for which we would like errors.
   * @return {Object<string, Array<string>>} - Errors keyed by field, if any, undefined otherwise.
   */
  const getErrorsForRow = useCallback((rowId) => (
    (getFilteredEditedData(dataRef)[rowId] || {})[errorsProp]
  ), []);

  /**
   * Get all the current errors for the supplies rowId/field.
   *
   * Note this does not include any new errors for the field they are currently editing.
   * Additionally if the singleFieldValidation prop is true it will not include any errors
   * for fields they have not edited yet
   *
   * @memberof EditContextApi
   * @param {string|number} rowId - The id of the row for which we would like errors on 1 field.
   * @param {string} field - The field for which we would like errors.
   * @return {Array<string>} - Errors, if any, undefined otherwise
   */
  const getErrorsForField = useCallback((rowId, field) => (
    (getErrorsForRow(rowId) || {})[field]
  ), [getErrorsForRow]);

  /**
   * Gets a hash, by row id, of all the errors for each edited row in the context.
   *
   * Note this does not include any new errors for the field they are currently editing.
   * Additionally if the singleFieldValidation prop is true it will not include any errors
   * for fields they have not edited yet
   *
   * @memberof EditContextApi
   * @return {Object<string|number, Object<Array<string>>>} - Hash of errors keyed by row id,
   *    then by field, if any, undefined otherwise.
   */
  const getErrors = useCallback(() => (
    fromEntries(
      Object.entries(getFilteredEditedData(dataRef)).map(
        ([key, value]) => [key, value[errorsProp]]
      ).filter(([, value]) => value)
    )
  ), []);

  /**
   * Validate all the data currently stored in the EditContext and return all errors.
   * This function is useful for checking everything is ok before saving because you will
   * know all the errors when the promise resolves and don't need to worry about
   * singleFieldValidation or the order of blur events and row validation, etc.
   *
   * Note: Unlike any of the other functions on the api validate() is asynchronous but will
   * always include errors for all data, even data that is currently being edited. By default
   * it will also validate ALL rows/fields that are stored in the edit context. Thus the UI
   * could show no errors for a blank row that was added to the data set with required fields
   * and only one field filled out if singleFieldValidation was true.  After validate() it
   * would though as validate() validates everything by default.
   *
   * @memberof EditContextApi
   * @return {Promise} - Resolves to a hash of errors, keyed by row id, then by field,
   *    if any, resolving to undefined if there are no errors.
   */
  const validate = useCallback(() => (
    validateData(columns, dataRef, true, errorCallbacksByRowIdRef.current)
  ), [columns, dataRef]);

  const value = {
    addErrorCallbackForRow,
    editDataForRow,
    /**
     * Symbol used to name the property added to all rows of edited data stored in the EditContext
     * where that row's errors are stored. This is not present on rows without errors.
     *
     * @memberof EditContextApi
     * @type {Symbol}
     */
    errorsProp,

    getEditedData,

    getErrors,

    getErrorsForField,

    getErrorsForRow,

    /**
     * Symbol used to name the property added to all rows of edited data stored in the EditContext
     * where that row's original data is stored.
     *
     * @memberof EditContextApi
     * @type {Symbol}
     */
    originalRowProp,

    removeErrorCallbackForRow,

    validate
  };

  return (
    <EditContext.Provider value={value}>
      {children}
    </EditContext.Provider>
  );
};

/**
 * Holds documentation for the propTypes of the provider
 *
 * @name EditProviderPropTypes
 */
EditProvider.propTypes = /** @lends EditProviderPropTypes */ {

  /**
   * Controls whether or not editing a field's value to be undefined will set undefined as the
   * new value or set no value at all in the edited data for that row. You should set this to
   * false if your data omits the properties for fields whose values are undefined.
   */
  allowEditToUndefined: PropTypes.bool,

  /** Child elements that will have access to the EditContext */
  children: PropTypes.oneOfType([
    PropTypes.func, PropTypes.node, PropTypes.elementType
  ]).isRequired,

  /**
   * The columns defining the data in the rows that can be edited.
   * The field prop of the column must match up the the key in the data that the column belongs to.
   */
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    validators: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})])
  })).isRequired,

  /**
   * Controls whether to preserve unchanged rows in the store of edited data if the row is later
   * edited back to its original state. This is normally not desired as you will have to filter
   * out the unchanged rows from the data to get at the actual edits. However, it may help if
   * you are mutating your original row data, overlaying the edits, for example. In that case
   * there is a risk of editing a row, then editing it back, and then later editing it again and
   * having the original data for the row in the edit context reflect a prior edited state
   * leading to errors. Keeping any row that has ever been edited in the context will ensure the
   * context cannot lose track of the original value ever. Unless you need to
   * do it it is not recommended that you mutate your row data with the edited row data.
   */
  preserveUnchangedRows: PropTypes.bool,

  /**
   * Controls whether to validate only the current field vs the entire row when calling
   * editDataForRow.
   */
  singleFieldValidation: PropTypes.bool
};

EditProvider.defaultProps = {
  allowEditToUndefined: true,
  preserveUnchangedRows: false,
  singleFieldValidation: false
};
