import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';

export default EntryView.extend({

    initialize() {
        const options = {
            menuCategory: 'REPORTING',
            serviceName: 'adminCM/cminst/issueVoids',
            serviceFunc: null,
            businessType: null,
            context: 'CM_IV_LIST',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },

    loadRequiredData() {
        const self = this;

        if (this.mode === 'insert' || this.mode === 'import') {
            this.setHasLoadedRequiredData(true);
            this.render();
        } else {
            this.contextDef.serviceName = 'adminCM/cminst/issueVoids';
            this.localeKey = 'cminst.issueVoids_';

            DataApi.model.generateModelFromModel(store.get(`${this.contextKey}-actionModel`)).then((newModel) => {
                const newModelParam = newModel;
                newModelParam.set('PRODUCTCODE', newModelParam.get('PRODUCT'));
                newModelParam.set('TYPECODE', newModelParam.get('TYPE'));
                newModelParam.set('SUBTYPE', '*');

                newModelParam.key.PRODUCTCODE = 'true';
                newModelParam.key.TYPECODE = 'true';
                newModelParam.key.SUBTYPE = 'true';
                newModelParam.fetch({
                    success() {
                        self.storedModel = newModelParam;
                        self.setHasLoadedRequiredData(true);
                        self.render();
                    },
                    error: util.bind(errorHandlers.loading, self),
                });
            });
        }
    },

});
