var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"submitted-accordion\">\n                        <div role=\"tabpanel\" id=\"rfpSubId\" aria-label=\"accordion\">\n                            <div class=\"row accordion-titlebar\">\n                                <a class=\"col-10 accordion-link collapsed\" role=\"button\" data-toggle=\"collapse\" href=\"#rfpSubmittedCollapseArea\" aria-expanded=\"false\" aria-controls=\"rfpSubmittedCollapseArea\">\n                                <span class=\"icon-plus\"></span><span class=\"icon-minus\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"rfp.heading.submitted",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":96},"end":{"line":17,"column":130}}}))
    + "\n                                </a>\n                                <div class=\"col-2 banner\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canInsertRFP") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":22,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                        </div>\n                        <div id=\"rfpSubmittedCollapseArea\" class=\"panel-collapse collapse\" role=\"region\" data-accordionid=\"rfpSubmitted\" aria-labelledby=\"rfpSubId\">\n                            <div>\n                                <div data-region=\"rfpSubmittedRegion\"></div>\n                            </div>\n                        </div>\n                    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <button class=\"new-payment-btn btn btn-secondary\" type=\"button\" name=\"button\" data-hook=\"insertBtn\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rfp.newRequest",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":140},"end":{"line":21,"column":167}}}))
    + "</button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"received-accordion\">\n                        <div role=\"tabpanel\" id=\"rfpReceiveId\" aria-label=\"accordion\">\n                            <div class=\"row accordion-titlebar\">\n                                <a class=\"col-11 accordion-link collapsed\" role=\"button\" data-toggle=\"collapse\" href=\"#rfpReceivedCollapseArea\" aria-expanded=\"false\" aria-controls=\"rfpReceivedCollapseArea\">\n                                <span class=\"icon-plus\"></span><span class=\"icon-minus\"></span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rfp.heading.received",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":95},"end":{"line":47,"column":128}}}))
    + "\n                                </a>\n                            </div>\n                        </div>\n                        <div id=\"rfpReceivedCollapseArea\" class=\"panel-collapse collapse\" role=\"region\" data-accordionid=\"rfpReceived\" aria-labelledby=\"rfpReceiveId\">\n                            <div>\n                                <div data-region=\"rfpReceivedRegion\"></div>\n                            </div>\n                        </div>\n                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"submitted-accordion\">\n                        <div role=\"tabpanel\" id=\"rfpTemplateId\" aria-label=\"accordion\">\n                            <div class=\"row accordion-titlebar\">\n                                <a class=\"col-10 accordion-link collapsed\" role=\"button\" data-toggle=\"collapse\" href=\"#rfpTemplatesCollapseArea\" aria-expanded=\"false\" aria-controls=\"rfpTemplatesCollapseArea\">\n                                <span class=\"icon-plus\"></span><span class=\"icon-minus\"></span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"rfp.heading.templates",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":95},"end":{"line":72,"column":129}}}))
    + "\n                                </a>\n                                <div class=\"col-2 banner\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canInsertRFPTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":36},"end":{"line":77,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                        </div>\n                        <div id=\"rfpTemplatesCollapseArea\" class=\"panel-collapse collapse\" role=\"region\" data-accordionid=\"rfpTemplates\" aria-labelledby=\"rfpTemplateId\">\n                            <div>\n                                <div data-region=\"rfpTemplateRegion\"></div>\n                            </div>\n                        </div>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <button class=\"new-payment-btn btn btn-secondary\" type=\"button\" name=\"button\" data-hook=\"insertTemplateBtn\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"rfp.newTemplate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":148},"end":{"line":76,"column":176}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"rfp.pageHeading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":59}}}))
    + "</h1>\n</div>\n<div class=\"page\">\n    <div class=\"section-body\">\n        <div class=\"section-container RealTimePayments\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"PaymentAccordions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"submittedEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"receivedEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":63,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"templateEntitlement") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":16},"end":{"line":87,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});