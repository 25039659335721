import 'datePicker';
import Collection from '@glu/core/src/collection';
import Layout from '@glu/core/src/layout';
import Dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import loadingTemplate from 'common/templates/loadingModal.hbs';
import alert from '@glu/alerts';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import { appBus, log } from '@glu/core';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import QueryResultsCollecton from 'common/dynamicPages/collections/queryResults';
import PaymentUtil from 'common/util/paymentUtil';
import fxFieldValidation from 'common/uiWidgets/util/fxFieldValidation';
import DataAPI from 'common/dynamicPages/api/data';
import SmbAPI from 'app/smb/api';
import LoansCommonAPI from 'app/loans/api/common';
import userInfo from 'etc/userInfo';
import RejectDialog from 'common/dynamicPages/views/rejectDialog';
import AuditModel from 'app/smbPayments/models/auditHistory';
import moment from 'moment';

import interestRateUtil from 'common/util/interestRateUtil';
import constants from 'app/smbPayments/constants';
import smbConstants from 'app/smb/constants';
import services from 'services';
import { postData } from 'common/util/services';
import { clearInterestRateInformation } from 'app/loans/views/baseLoanView';
import duplicatePaymentUtil from 'app/smbPayments/util/duplicatePaymentUtil';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import { modalClose } from 'common/modals/mobileGridModalUtil';
import getAvailableActions from 'app/smb/common/actionUtil';
import dateUtil from 'common/util/dateUtil';
import template from './loanPaymentLayout.hbs';

export default Layout.extend({
    template,
    className: 'transfer-model',
    loadingTemplate,

    ui: {
        scheduleBtn: '.schedule-button',
        $transferAmt: '.transfer-amount',
        $debitAcctList: '[name="DEBIT_ACCOUNT_NUMBER"]',
        $beneAcctList: '[name="BENE_ACCOUNT"]',
        $dateField: '[name="VALUE_DATE"]',
        $comments: '#special-instructions',
        $rejectionReason: '[data-hook="getRejectionReason"]',
        $approverRejectionReason: '[data-hook="getApproverRejectionReason"]',
        $duplicatePaymentSection: '[data-hook="duplicate-payment-section"]',
        $interestRateContainer: '[data-hook="getInterestRateContainer"]',
        $payType: '.applies-to',
        $removeIcon: '.icon-cancel',
        $addIcon: '#addOption',
        $amountFields: '[data-hook="amount"]',
        $formElement: 'form',
        $auditHistory: '.audit-history-wrapper',
        $paymentAuditHistory: '.payment-audit-history',
    },

    events: {
        'click @ui.scheduleBtn': 'scheduleBtnHandler',
    },

    behaviors: {
        ValidationSupplement: {},
    },

    getHeader() {
        return locale.get('payment.transfer');
    },

    getClass() {
        return 'modal-body';
    },

    getTypeCode() {
        return 'TRANSFER';
    },

    getServiceName() {
        return '/payment/transfer';
    },

    getDebitAcctName() {
        return 'ACCOUNTFILTER';
    },

    getBeneAcctName() {
        return 'BENE_ACCOUNTENTITLEMENT';
    },

    /**
     * Get a list of button for the modal
     * @returns {Array}
     */
    getButtons() {
        const buttons = [];
        const userActionsWithScheduleButton = [
            smbConstants.COPY_ACTION_MODE,
            smbConstants.MODIFY_ACTION_MODE,
        ];
        const userAction = this.options.action && this.options.action.toUpperCase();
        if (userAction === smbConstants.SELECT_ACTION_MODE) {
            const excludedActions = [smbConstants.SELECT_ACTION_MODE];
            const availableActions = getAvailableActions(this.options);
            let includeSchedule;
            availableActions.forEach(({ action, label }) => {
                if (excludedActions.includes(action)) {
                    return;
                }
                const lowercaseAction = action.toLowerCase();
                if (action === smbConstants.COPY_ACTION_MODE
                    || action === smbConstants.MODIFY_ACTION_MODE) {
                    includeSchedule = true;
                    buttons.push({
                        text: util.isEmpty(label) ? locale.get(`smbPayments.${lowercaseAction}`) : label,
                        className: `btn btn-secondary ${lowercaseAction}-button`,
                        callback: `enable${action.toUpperCase()}`,
                    });
                } else {
                    buttons.push({
                        text: util.isEmpty(label) ? locale.get(`smbPayments.${lowercaseAction}`) : label,
                        className: `btn btn-secondary ${lowercaseAction}-button`,
                        callback: util.bind(
                            this.triggerGridAction,
                            this,
                            lowercaseAction,
                        ),
                    });
                }
            });
            if (includeSchedule) {
                // hide the button so it is in the DOM, should the user need it later
                buttons.push({
                    text: locale.get('smbPayments.schedule.transfer'),
                    className: 'btn btn-secondary schedule-button hidden',
                    callback: 'scheduleBtnHandler',
                });
            }
        } else if (userActionsWithScheduleButton.includes(userAction) || !userAction) {
            // Only show schedule button during certain actions
            buttons.push({
                text: locale.get('smbPayments.schedule.transfer'),
                className: 'btn btn-secondary create-or-update-button',
                callback: 'scheduleBtnHandler',
            });
        }
        // Always add cancel button
        buttons.push({
            text: locale.get('smbPayments.cancel'),
            className: 'btn btn-secondary cancel-button',
            callback: 'cancel',
        });
        return buttons;
    },

    getButtonsForWarning() {
        return [{
            text: locale.get('button.ok'),
            className: 'btn-primary create-or-update-button',
            callback: 'scheduleWithWarningBtnHandler',
        }, {
            text: locale.get('smbPayments.cancel'),
            className: 'btn btn-secondary cancel-button',
            callback: 'cancelFromWarning',
        }];
    },

    showAuditHistory() {
        this.auditM = new AuditModel({
            functionCode: this.context.typeInfo.functionCode,
            tNum: this.model.get('TNUM'),
        });

        return this.auditM.fetch();
    },

    /**
     * Copy As Payment is not an 'update', its a 'create'
     * So when backbone checks model.isNew we need to fool it
     * Clear fields indicating this is an existing payment...very quietly
     */
    prepModelFromCopy() {
        this.model.unset(
            'id',
            {
                silent: true,
            },
        );
        this.model.unset(
            'TNUM',
            {
                silent: true,
            },
        );
        this.model.unset(
            'UPDATECOUNT__',
            {
                silent: true,
            },
        );

        this.model.unset(
            'EXCHANGE_RATE',
            {
                silent: true,
            },
        );
        this.model.unset(
            'EXCHANGE_RATE_CONTRACTID',
            {
                silent: true,
            },
        );
    },

    /**
     * Set the action to copyinst, re-render, and show the proper modal buttons
     */
    enableCOPYINST() {
        this.options.action = 'copyinst';
        this.render();

        this.showModifyButtons();
    },

    /**
     * Show the appropriate buttons in the modal for modify mode
     */
    showModifyButtons() {
        this.$el.parents('.modal').find('.modal-footer button').hide();
        this.$el.parents().find('.modal-footer .schedule-button').removeClass('hidden').show();
        this.$el.parents().find('.modal-footer .create-or-update-button')
            .removeClass('hidden').show();
        this.$el.parents().find('.modal-footer .cancel-button').show();
    },

    enableMODIFY() {
        this.showModifyButtons();
        this.options.action = 'modify';
        this.render();
    },

    /*
     * Within our modal (this view) we have the same options as the grid actions
     * (i.e. delete, reject) We can trigger grid actions or open dialogs
     */
    triggerGridAction(actionType) {
        if (actionType === 'reject') {
            Dialog.open(new RejectDialog({
                model: this.options.model,
                grid: this.options.gridView,
            }));

            // listen to meta.js - genericObjectFunction & rejectDialog.js
            this.listenTo(this.options.model, 'reject change', this.cancel);
        } else if (actionType === 'delete') {
            /*
             * hide our modal, trigger grid action to show delete modal:yes/no -- if yes:
             * close our modal, if no: unhide it
             */
            this.appBus.trigger(`grid:row:action:action_${actionType}_${this.options.model.parentViewId}`, this.options.model);
            this.listenTo(this.appBus, `${this.context.typeInfo.productCode}-${actionType}-true`, this.cancel);
        } else {
            // close our modal before calling grid action
            this.cancel();
            this.appBus.trigger(`grid:row:action:action_${actionType}_${this.options.model.parentViewId}`, this.options.model);
        }
    },

    initialize(options) {
        this.options = options;
        this.context = {
            serviceName: this.getServiceName(),

            typeInfo: {
                productCode: 'RTGS',
                functionCode: 'INST',
                typeCode: this.getTypeCode(),
            },

            // SMB only uses '0' which is Freeform entry
            entryMethodName: constants.ENTRYMETHODNAME,
        };
        this.serverDisplayBalancesParam = serverConfigParams.get('DisplayAccountBalanceOnPaymentsScreens') === 'true';
        this.shouldDisplayBalancesResult = null;
    },

    /*
     * Allow the full account number to be returned when typed in
     * @param {string} type - Either 'credit' or 'debit'
     * @param {Object} params - search params from select2
     * @param {string} data - option data from select2
     */
    maskedInputMatcher(type, params, data) {
        if (!params) {
            return data;
        }
        let theData = [];
        if (type === 'credit') {
            theData = this.beneAcctList;
        } else {
            theData = this.debitAcctList;
        }
        const thisItem = theData.find((item) => {
            /*
             * Filtering needs to take place on the label AND the account
             * number. We need to find the account based on 'data' (which
             * is actually the option text from select2). This is done by
             * matching the data to the model label. Available Balance is
             * a configuration that when enabled, availableBalance is concatenated
             * to the model label. TODO When SMB is rebuilt to use proper metadata,
             * filtering can be done on the backend.
             */
            if (item.get('availableBalance')) {
                return `${item.get('label')} - ${item.get('availableBalance')}` === data;
            }
            return item.get('label') === data;
        });
        if (!thisItem) {
            return null;
        }
        const text = thisItem.get('label');
        const name = thisItem.get('name');
        const matchesParams = [text, name]
            .filter(string => string.includes(params));
        return matchesParams.length ? data : null;
    },

    onRender() {
        // Clear the value so that we can pick any DEBIT_ACCOUNT_NUMBER changes
        this.currentDebitValue = null;
        if (this.hasLoadedRequiredData()) {
            this.ui.$debitAcctList.comboBox({
                matcher: this.maskedInputMatcher.bind(this, 'debit'),
                placeholder: locale.get('smbPayments.account.select'),
                allowClear: true,
            });

            this.ui.$beneAcctList.comboBox({
                matcher: this.maskedInputMatcher.bind(this, 'credit'),
                placeholder: locale.get('smbPayments.account.select'),
                allowClear: true,
            });

            this.ui.$debitAcctList
                .html(this.getOptionsForComboFromCollection(this.debitAcctList));
            const debitVal = this.model.get(this.getDebitAcctName());

            this.ui.$debitAcctList.val(debitVal).trigger('change');

            this.ui.$transferAmt.inputmask('decimal', Formatter.getCurrencyMaskOptions());

            // NH-153726 Copying a payment could have a past date. Make sure the date is valid
            if (util.isEmpty(this.model.get('VALUE_DATE')) || this.options.action === 'copyinst') {
                this.model.set('VALUE_DATE', this.getValidDate(this.model.get('VALUE_DATE')));
            }

            this.renderDatePickerContent();

            // trigger account number events in case we have values
            this.debitAccountNumberChange(
                this.model,
                this.model.get(this.getDebitAcctName()), true,
            );

            // if existing transaction and view was clicked, disable all ui elements on view
            if (this.options && this.options.action?.toUpperCase()
                === smbConstants.SELECT_ACTION_MODE) {
                this.enableFields(false);
            }

            // add input mask for amount value localization
            this.ui.$amountFields.inputmask('number');

            duplicatePaymentUtil.showCreatePayment(this);
        } else {
            this.loadRequiredData();
        }
    },

    /**
     * Check the date against the earliest date to see if it
     * has past. When it has return the earliest, otherwise return
     * the date
     * @param {moment|Date|string} date - potential date
     * @returns {moment}
     */
    getValidDate(date) {
        let potentialDate = date;
        if (util.isEmpty(potentialDate)) {
            potentialDate = moment(new Date()).startOf('day');
        }
        const earliestDay = Formatter.formatDate(this.dateSettings.earliestDay);
        const isPastDate = dateUtil.isValidPastDate(potentialDate, earliestDay);
        if (isPastDate) {
            return earliestDay;
        }
        return Formatter.formatDate(potentialDate);
    },

    shouldDisplayBalances() {
        if (this.shouldDisplayBalancesResult !== null) {
            return this.shouldDisplayBalancesResult;
        }
        const hideAcctBalanceStatuses = serverConfigParams.get('HideAccountBalanceStatuses');
        const status = this.model.get('STATUS');
        let dispAcctBalance = true;
        if ((!util.isEmpty(status)
            && !util.isEmpty(hideAcctBalanceStatuses)
            && hideAcctBalanceStatuses.indexOf(status) !== -1)
            || !this.serverDisplayBalancesParam) {
            dispAcctBalance = false;
        }
        this.shouldDisplayBalancesResult = dispAcctBalance;
        return dispAcctBalance;
    },

    updateAccountDropdownBalances(fieldName) {
        let listModels;
        if (fieldName === 'DEBIT_ACCOUNT_NUMBER') {
            listModels = this.debitAcctList.models;
        } else {
            listModels = !util.isEmpty(this.beneAcctList.models)
                ? this.beneAcctList.models : undefined;
        }
        /**
         * If listModels is undefined then no need to run this code as
         * there are no accounts found
         */
        if (!listModels) {
            return Promise.resolve();
        }
        if (!Array.isArray(listModels)) {
            listModels = [listModels];
        }
        return this.fetchBalancesForAccounts(listModels, fieldName)
            .then((resp) => {
                if (resp) {
                    const balances = resp.responses || resp;
                    const accounts = balances.length ? balances
                        : new Array(listModels.length);
                    listModels.forEach((item, index) => {
                        const account = accounts[index];
                        if (account && account.balance) {
                            item.set('availableBalance', `${account.balance} ${locale.get('smbPayments.available')}`);
                        } else {
                            item.set('availableBalance', '');
                        }
                    });
                }
            });
    },

    /**
     * Fetch method can be called with list of accounts or a single account, for a single
     * account, we use a different serviceUrl
     */

    fetchBalancesForAccounts(accounts, fieldName) {
        const requestOptions = {
            DEBIT_ACCOUNT_NUMBER: {
                bankCodeKey: 'DEBIT_BANK_CODE',
                currencyKey: 'DEBIT_CURRENCY',
                subAcctKey: 'DEBIT_SUBACCOUNT_NUM',
                acctTypeKey: 'ACCOUNT_TYPE',
                acctSubTypeKey: 'ACCOUNT_SUBTYPE',
            },
            BENE_ACCOUNT: {
                bankCodeKey: 'BENE_BANK_CODE',
                currencyKey: 'CREDIT_CURRENCY',
                subAcctKey: 'BENE_SUBACCOUNT_NUM',
                acctTypeKey: 'BENE_ACCOUNT_TYPE',
                acctSubTypeKey: 'BENEACCOUNT_SUBTYPE',
            },
        };
        const options = requestOptions[fieldName];
        let reqData;
        let callBalanceService = true;
        const isAccountArray = Array.isArray(accounts);
        if (isAccountArray) {
            reqData = {
                requests: accounts.map((item) => {
                    const mapDataList = item.get('mapDataList');
                    if (util.isEmpty(mapDataList)) {
                        callBalanceService = false;
                        return true;
                    }
                    const { productCode, functionCode, typeCode } = item.collection.typeInfo
                    || item.collection;
                    return {
                        productCode,
                        functionCode,
                        typeCode,
                        bankCode: mapDataList.find(datum => datum.toField.toUpperCase()
                                === options.bankCodeKey).value,
                        accountNumber: mapDataList.find(datum => datum.toField.toUpperCase()
                                 === fieldName).value,
                        currency: mapDataList.find(datum => datum.toField.toUpperCase()
                                === options.currencyKey).value,
                        subAccountNum: this.getNullableField(mapDataList, options.subAcctKey),
                        accountType: mapDataList.find(datum => datum.toField.toUpperCase()
                                === options.acctTypeKey).value,
                        accountSubType: this.model.get('ACCOUNT_SUBTYPE'),
                    };
                }),
            };
        } else {
            const { productCode, functionCode, typeCode } = accounts.collection.typeInfo
                        || accounts.collection;
            reqData = {
                productCode,
                functionCode,
                typeCode,
                bankCode: accounts.get('mapDataList').find(datum => datum.toField.toUpperCase() === options.bankCodeKey).value,
                accountNumber: accounts.get('mapDataList').find(datum => datum.toField.toUpperCase() === fieldName).value,
                currency: accounts.get('mapDataList').find(datum => datum.toField.toUpperCase() === options.currencyKey).value,
                subAccountNum: this.getNullableField(accounts.get('mapDataList'), options.subAcctKey),
                accountType: accounts.get('mapDataList').find(datum => datum.toField.toUpperCase() === options.acctTypeKey).value,
                accountSubType: this.model.get('BENEACCOUNT_SUBTYPE'),
            };
        }

        if (!callBalanceService) {
            return Promise.resolve();
        }

        return this.getAccountBalancePromise(reqData, fieldName, isAccountArray);
    },
    /**
     * Return the field value if it is found, else return ''
     * @param {Array} mapDataList
     * @param {string} key
     * @returns {string}
     */
    getNullableField(mapDataList, key) {
        const field = mapDataList.find(datum => datum.toField.toUpperCase() === key);
        return field ? field.value : '';
    },

    /**
     * Based on the parameters, return the promise used to fetch the accounts
     * @param {Object} data
     * @param {string} fieldName
     * @param {boolean} isAccountArray
     * @returns {Promise}
     */
    getAccountBalancePromise(data, fieldName, isAccountArray) {
        const serviceUrl = isAccountArray ? services.currencyBalances
            : services.balanceAndTransaction;
        return postData(serviceUrl, data);
    },

    getOptionsForComboFromJson(comboData) {
        const optionsList = comboData.map((data) => {
            if (data?.name) {
                let { label } = data;
                if (data.availableBalance) {
                    label = `${label} - ${data.availableBalance}`;
                }
                return `<option value="${data.name}">${label}</option>`;
            }
            return `<option value="searching">${locale.get('smbPayments.account.searching')}</option>`;
        });
        // Start with a blank option and add the others
        return ['<option value=""></option>'].concat(optionsList).join('');
    },

    getOptionsForComboFromCollection(collection) {
        // Extract only the keys we need rather than doing a full .toJSON()
        const neededData = collection?.map(model => model.pick(
            'mapDataList',
            'name',
            'text',
            'label',
            'availableBalance',
        ));

        return this.getOptionsForComboFromJson(neededData || [{}]);
    },

    enableFields(enable) {
        this.ui.$transferAmt.prop('disabled', !enable);
        this.ui.$dateField.prop('disabled', !enable);
        this.ui.$debitAcctList.comboBox('enable', enable);
        this.ui.$beneAcctList.comboBox('enable', enable);
        this.ui.$comments.prop('disabled', !enable);
        this.ui.$amountFields.prop('disabled', !enable);
        this.ui.$payType.prop('disabled', !enable);

        if (enable) {
            this.ui.$addIcon.show();
            this.ui.$removeIcon.show();
        } else {
            this.ui.$addIcon.hide();
            this.ui.$removeIcon.hide();
        }
    },

    cancel() {
        modalClose(this.options.onClose, this);
        Dialog.close();
    },

    cancelFromWarning() {
        this.alertRegion.reset();
        this.ui.$formElement.show();
        this.trigger('dialog:buttons:change', this.getButtons());
    },

    /**
     * @method updateConfirmData
     * @param {object||model} resp   response from server if submit is success
     * @param {object} model to show confirmfields on the screen
     * This method iterates through response object for ID and set it to model
     */
    updateConfirmData(resp, model) {
        const confirms = resp.confirms || resp.get('confirms');
        this.updateID(confirms, model);
    },

    /**
     * @method updateID
     * @param {Object} confirms a JSON confirm structure
     * Copies the payment id from the confirm data to the model.
     */
    updateID(confirms) {
        /*
         * TODO NH-160380 Need to find a more elegant solution so that the UI does not have to
         * piece together the contents of the confirmation message.
         * Backend devs confirmed that id should always be the first element in the
         * array, so this is an intermediary fix for now.
         */
        const seqIdObj = confirms?.confirmResults?.[0]?.confirmData?.[0]?.item?.[0] || {};
        if (seqIdObj.value) {
            this.model.set('ID', seqIdObj.value);
        }
    },

    scheduleBtnHandler() {
        const self = this;
        // Flag to differentiate between Account and Transfer Widget.
        const modalOpeningFromAccountWidget = !!(this.options?.draggedModel);
        if (this.options && this.options.action === 'copyinst' && util.isUndefined(this.model.get('duplicateAccepted'))) {
            // When used from list page this must be called for Copy as Payment.
            this.prepModelFromCopy();
        }
        if (util.isEmpty(this.model.get('BENE_BANK_CODE'))) {
            this.model.set('BENE_BANK_CODE', this.model.get('TRANSFERCREDITBANKCODE'));
        }
        this.trigger('dialog:buttons:disableAll');
        if (!this.actionInProgress) {
            this.actionInProgress = true;
            if (this.model.isValid()) {
                /*
                 * for existing transactions, the TRAN_DATE may be earlier that today.  This
                 * hack allows the update
                 */
                const debitModelValue = this.model.get('DEBIT_ACCOUNT_NUMBER');
                this.model.save({
                    TRAN_DATE: this.model.get('VALUE_DATE'),
                    DEBIT_ACCOUNT_NUMBER: debitModelValue?.split('-')?.[1] || debitModelValue,
                }, {
                    success(resp) {
                        DataAPI.model.generate({
                            context: self.context,
                            state: 'insert',
                        }, false).then(() => {
                            Dialog.close();
                            self.updateConfirmData(resp, self.model);
                            if (modalOpeningFromAccountWidget) {
                                appBus.trigger('smbPayments:newTransfer:gridAlertForAccount', self.model);
                            } else {
                                appBus.trigger('smbPayments:newTransfer:gridAlert', self.model);
                            }
                            modalClose(self.options.onClose, self);
                        });
                    },

                    error(model, optionsParam) {
                        const options = optionsParam.error;
                        self.actionInProgress = false;

                        const { resultType } = options;
                        if (resultType === 'WARNING' && (options.errorCode && options.errorCode === 540)) {
                            duplicatePaymentUtil.showWarningConfirmation(options, self);
                            duplicatePaymentUtil.renderPaymentAlertContent(options, self);
                        } else {
                            self.ui.$duplicatePaymentSection.hide();
                            self.ui.$formElement.show();
                            self.renderMessage(self.model.error);
                        }
                        self.trigger('dialog:buttons:enableAll');
                        self.actionInProgress = false;
                    },
                });
            } else {
                // a client-side error occurred.  Re-enable buttons
                this.trigger('dialog:buttons:enableAll');
                this.actionInProgress = false;
            }
        }
    },

    scheduleWithWarningBtnHandler() {
        this.model.set('_saveWithWarning', true);
        this.scheduleBtnHandler();
    },

    renderDatePickerContent() {
        this.ui.$dateField.nhDatePicker({
            blockedDates: this.dateSettings.blockedDates,
            processingDays: this.dateSettings.businessDays,
            minDate: this.dateSettings.earliestDay,
            daysForward: this.dateSettings.maxForwardDays,
            showCalendarIcon: true,
        });
        if (!this.model.get('VALUE_DATE')) {
            this.model.set({
                VALUE_DATE: moment(new Date()).startOf('day').format(userInfo.getDateFormat()),
            }, {
                silent: true,
            });
        }
    },

    setAccountMapDataList(newValue, acctList, fieldName) {
        let localNewValue = newValue;
        const self = this;
        let override = false;
        if (localNewValue === '') {
            localNewValue = self.model.previous(fieldName);
            override = true;
        }
        acctList.each((acctModel) => {
            if (acctModel.get('name') === localNewValue) {
                const mapDataList = acctModel.get('mapDataList');
                const setObj = {};
                for (let i = 0; i < mapDataList.length; i += 1) {
                    setObj[mapDataList[i].toField.toUpperCase()] = (override) ? '' : mapDataList[i].value;
                }
                self.model.set(setObj);
            }
        });
    },

    convertComboData(comboData) {
        // no conversion is needed for transfer
        return comboData;
    },

    refreshBeneAcctCombo(newValue, refreshBeneFromModel) {
        const self = this;
        const beneAcctCombo = this.ui.$beneAcctList;
        const comboVal = this.model.get(this.getBeneAcctName()) || null;

        if (!newValue) {
            return;
        }
        /**
         * Reset the values in the dropdown when the parent value is changes
         */
        beneAcctCombo.html(self.getOptionsForComboFromCollection());
        this.getCreditAccountList(this.model.get('ACCOUNTFILTER')).then(() => {
            let accountBalancePromise = Promise.resolve();
            if (self.shouldDisplayBalances()) {
                accountBalancePromise = self.updateAccountDropdownBalances.call(self, 'BENE_ACCOUNT');
            }
            return accountBalancePromise;
        }).then(() => {
            beneAcctCombo
                .html(self.getOptionsForComboFromCollection(self.beneAcctList));
            beneAcctCombo.val(comboVal).trigger('change');

            if (refreshBeneFromModel) {
                self.beneAccountNumberChange(self.model, comboVal);
            }
        });
    },

    loadRequiredData() {
        const self = this;
        let modelPromise;
        let options;
        let txnModel;
        let beneAccountPromise;

        if (this.options.model) {
            // view, edit, or copy clicked from the transfers grid
            txnModel = this.options.model;

            if (this.options.action.toLowerCase() === 'copyinst') {
                // handle copy as new and populate relevant data fields after the retrieval
                options = {
                    context: this.context,
                    state: 'insert',
                };
                modelPromise = new Promise((resolve) => {
                    DataAPI.model.generateModelFromModel(txnModel).then((genModel) => {
                        genModel.fetch({
                            success: resolve,
                        });
                    });
                });
            } else {
                /*
                 * clone the txn model so we don't mess up the grid's model on fetch.
                 * Usually, date fields are displayed in the wrong format if we fetch
                 * the original grid model
                 */
                this.context = util.extend(this.context, txnModel.context);
                modelPromise = new Promise((resolve) => {
                    DataAPI.model.generateModelFromModel(txnModel).then((genModel) => {
                        genModel.fetch({
                            success: resolve,
                        });
                    });
                });
            }
        } else {
            // transfer clicked from a tile or drag and drop
            options = {
                context: this.context,
                state: 'insert',
            };
            modelPromise = DataAPI.model.generate(options, false);
        }
        modelPromise.then((modelInfo) => {
            this.model = modelInfo;
            this.setupDragDrop();
        });

        const actionMode = (!this.options.action) ? 'insert' : this.options.action;

        Promise.all([
            modelPromise,
            this.getDebitAccountList(modelPromise),
            SmbAPI.dates.get({
                paymentType: this.getTypeCode(),
            }),
            LoansCommonAPI.getPaymentPreferencesData(),
        ]).then(([, debitAcctList, dateSettings, loansPrefs]) => {
            self.debitAcctList = debitAcctList;
            self.dateSettings = dateSettings;
            self.loansPrefs = loansPrefs;
            self.paymentOptions = LoansCommonAPI.getAvailablePaymentOptions(self.loansPrefs);

            // add amount values to paymentOptions
            for (let x = 0; x < self.paymentOptions.length; x += 1) {
                self.paymentOptions[x].amount = self.model.get(self.paymentOptions[x].key);
            }

            self.enabledOptions = new Collection(self.paymentOptions);

            self.model.validators = {
                DEBIT_ACCOUNT_NUMBER: {
                    description: locale.get('smbPayments.transfer.from'),
                    exists: true,
                },

                BENE_ACCOUNT: {
                    description: locale.get('smbPayments.transfer.to'),
                    exists: true,
                },

                CREDIT_AMOUNT: {
                    description: locale.get('smbPayments.amount'),
                    exists: true,
                    matches: SmbAPI.AMOUNT_PATTERN,
                },

                VALUE_DATE: {
                    description: locale.get('smbPayments.transfer.date'),
                    exists: true,
                    matchesDatePattern: userInfo.getDateFormat(),
                },
            };

            if (actionMode.toLowerCase() === 'copyinst') {
                self.model.set({
                    DEBIT_ACCOUNT_NUMBER: self.options.model.get('CMB_DEBIT_ACCOUNT_NUMBER'),
                    BENE_ACCOUNT: self.options.model.get('CMB_BENE_ACCOUNT'),
                    CREDIT_AMOUNT: self.options.model.get('CMB_TRANSACTION_AMOUNT'),
                    VALUE_DATE: self.options.model.get('CMB_VALUE_DATE'),
                });

                beneAccountPromise = self.getCreditAccountList(self.model.get('ACCOUNTFILTER'));
            } else if (self.options.draggedModel) {
                const acctNumber = self.options.draggedModel.get('accountFilter');
                const debitAccountFound = self.debitAcctList.find((debitAcctModel) => {
                    const accountFilter = debitAcctModel.get('mapDataList')
                        .find(data => data.toField.toUpperCase() === 'ACCOUNTFILTER');
                    return accountFilter && accountFilter.value === acctNumber;
                });
                if (debitAccountFound) {
                    beneAccountPromise = self.getCreditAccountList(self.model.get('ACCOUNTFILTER'));
                }
            }

            if (!beneAccountPromise) {
                this.beneAcctList = new Collection();
            }

            // Format our value_date (drop down) correctly
            const tranDate = self.model.get('TRAN_DATE');

            if (tranDate) {
                self.model.set('VALUE_DATE', Formatter.formatDate(tranDate));
            }

            return beneAccountPromise;
        }).then(() => {
            let auditPromise;

            self.listenTo(self.model, 'change:DEBIT_ACCOUNT_NUMBER', self.debitAccountNumberChange);
            self.listenTo(self.model, 'change:BENE_ACCOUNT', self.beneAccountNumberChange);
            self.listenTo(self.model, 'change:VALUE_DATE', self.valueDateChange);

            // If view mode, get audit history
            if (self.options && self.options.action === 'select') {
                auditPromise = self.showAuditHistory();
            } else {
                auditPromise = Promise.resolve();
            }

            return auditPromise;
        }).then((auditModelData) => {
            let localAuditModelData = auditModelData;
            if (localAuditModelData && localAuditModelData.paymentAuditHistory) {
                self.auditModel = localAuditModelData.paymentAuditHistory;
            } else {
                localAuditModelData = Promise.resolve();
            }

            self.setHasLoadedRequiredData(true);
            self.render();

            return localAuditModelData;
        });
    },

    /**
     * When drag and drop models exist, set the view model with the proper values
     */
    setupDragDrop() {
        const { draggedModel, droppedModel } = this.options;
        if (draggedModel) {
            const acctNumber = draggedModel.get('accountFilter');
            this.model.set(this.getDebitAcctName(), acctNumber);
        }
        if (droppedModel) {
            const acctNumber = droppedModel.get('accountFilter');
            this.model.set(this.getBeneAcctName(), acctNumber);
        }
    },

    debitAccountNumberChange(model, newValue) {
        /**
         * Checking to make sure that the value has actually changed before making
         * additional API calls. this.currentDebitValue only when there is a change
         * in the selection. This will also be cleared on render, so that we
         * can pickup and dependent changes.
         */
        if ((this.currentDebitValue !== newValue && this.currentDebitValue?.split('-')[1] !== newValue) || newValue === '') {
            this.currentDebitValue = model.get('DEBIT_ACCOUNT_NUMBER');
            // Need to update model data from mapDataList before anything else

            this.setAccountMapDataList(newValue, this.debitAcctList, 'DEBIT_ACCOUNT_NUMBER');

            // if this is a loan draw payment that is NOT in view mode
            if (this.context.typeInfo.typeCode === 'LOANDRAW' &&
                this.context.typeInfo.functionCode === 'INST' &&
                this.options.action !== 'select') {
                /*
                 * if the interest rate configuration is active AND the account selected is not
                 * empty AND the account is a "variable rate" subtype, retrieve interest rate
                 */
                if (this.showInterestRate && newValue !== '' && this.variableRateSubtypes?.includes(this.model.get('ACCOUNT_SUBTYPE'))) {
                    interestRateUtil.createPromiseToRetrieveInterestRate({
                        productCode: this.context.typeInfo.productCode,
                        functionCode: this.context.typeInfo.functionCode,
                        typeCode: this.context.typeInfo.typeCode,
                        bankCode: this.model.get('DEBIT_BANK_CODE'),
                        accountNumber: this.model.get('DEBIT_ACCOUNT_NUMBER'),
                        currency: this.model.get('DEBIT_CURRENCY'),
                        subAccountNum: this.model.get('DEBIT_SUBACCOUNT_NUM'),
                        accountFilter: this.model.get('ACCOUNTFILTER'),
                    }).then(
                        (response) => {
                            if (response.status === 'success') {
                                this.ui.$interestRateContainer.removeClass('hide');
                                this.model.set('INTEREST_RATE', response.interestRate);
                            } else {
                                clearInterestRateInformation(
                                    this.ui.$interestRateContainer,
                                    this.model,
                                );
                            }
                        },
                        (err) => {
                            clearInterestRateInformation(
                                this.ui.$interestRateContainer,
                                this.model,
                            );
                            log.error(err);
                        },
                    );
                } else {
                    clearInterestRateInformation(this.ui.$interestRateContainer, this.model);
                }
            }

            /*
             * if the account balance is not availble, fetch the account
             * balance when  the account is selected
             */
            this.updateSelectedAccountBalance('DEBIT_ACCOUNT_NUMBER', newValue);
            this.refreshBeneAcctCombo(newValue, model);
            this.updateCutoff();
        }
    },

    beneAccountNumberChange(model, newValue) {
        // Need to update model data from mapDataList before anything else
        this.setAccountMapDataList(newValue, this.beneAcctList, 'BENE_ACCOUNT');
        /*
         * if the account balance is not availble, fetch the account
         * balance whent he account is selected
         */
        this.updateSelectedAccountBalance('BENE_ACCOUNT', newValue);
        this.updateCutoff();
    },

    valueDateChange() {
        this.updateCutoff();
    },

    txnCanBeModified() {
        let returnVal = false;
        /*
         * if a model was passed in, the model needs to have MODIFY privileges, or
         * have been a copyinst action.
         * If no model was passed in, it's a new transaction and can be modified
         */
        if (this.options.model) {
            if (this.options.action === 'copyinst') {
                returnVal = true;
            } else {
                util.each(this.options.model.buttons, (button) => {
                    returnVal = returnVal || button.action === 'MODIFY';
                });
            }
        } else {
            returnVal = true;
        }
        return returnVal;
    },

    getDebitAccountList(modelPromise) {
        const accountPromise = new Promise((resolve, reject) => {
            this.debitAcctList = new QueryResultsCollecton(
                [],
                {
                    context: this.context,
                    fieldName: 'ACCOUNTFILTER',
                },
            );
            this.debitAcctList.fetch({
                success: resolve,
                error: reject,
            });
        });
        /*
         * Need to wait for the model promise, so it is possible to evaluate if
         * account balances should be displayed
         */
        return Promise.all([
            modelPromise,
            accountPromise,
        ]).then(() => {
            if (this.shouldDisplayBalances()) {
                return this.updateAccountDropdownBalances('DEBIT_ACCOUNT_NUMBER')
                    .then(() => Promise.resolve(this.debitAcctList));
            }
            return Promise.resolve(this.debitAcctList);
        });
    },

    getCreditAccountList(debitAccountNumber) {
        return new Promise((resolve, reject) => {
            this.beneAcctList = new QueryResultsCollecton(
                [],
                {
                    context: this.context,
                    fieldName: 'BENE_ACCOUNTENTITLEMENT',
                },
            );
            if (debitAccountNumber) {
                this.beneAcctList.customFilters = [{
                    filterName: 'Depends',
                    filterParam: ['ACCOUNTFILTER', debitAccountNumber],
                }];
            }
            this.beneAcctList.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    renderMessage(errorParam) {
        const error = errorParam;
        // temporarily separate multiple errors with a space
        let message = null;
        let single = false;

        if (error === null || error === undefined) {
            return;
        }
        message = error.message.join(' ');
        error.confirms.confirmResults[0].confirmData = null;

        if (error.confirms.confirmResults && error.confirms.confirmResults.length === 1
            && error.confirms.confirmResults[0].messages
            && error.confirms.confirmResults[0].messages.length === 1) {
            [message] = error.confirms.confirmResults[0].messages;
            single = true;
        }

        if (single) {
            this.alertView = alert.danger(
                message,
                {
                    canDismiss: true,
                },
            );
        } else {
            const confirms = new Confirms({
                confirms: error.confirms,
            });

            // display notification message
            this.alertView = alert.danger(
                message,
                {
                    details: confirms,
                    canDismiss: true,
                },
            );
        }

        this.alertRegion.show(this.alertView);

        const { resultType } = error;
        if (resultType === 'WARNING') {
            this.ui.$formElement.hide();
            this.trigger('dialog:buttons:change', this.getButtonsForWarning());
        }
    },

    updateCutoff() {
        const self = this;
        const { model } = this;
        const datePicker = this.$('.ui-datepicker-trigger');
        const product = model.get('PRODUCT') || model.jsonData.typeInfo.productCode;
        const functionCode = model.get('FUNCTION') || model.jsonData.typeInfo.functionCode;
        const typeCode = model.get('TYPE') || model.jsonData.typeInfo.typeCode;
        const subType = model.get('PAYMENTCLEARINGSYSTEM') || model.get('SUBTYPE');
        const cutoff = model.get('CUTOFF_INFO');
        const beneBank = model.get('BENE_BANK_CODE');
        const debitBank = model.get('DEBIT_BANK_CODE');
        let beneAccount = null;
        /**
         * Avoiding calling processServiceAction API
         * multiple times even when the debit account number is same.
         */
        if (model.get('DEBIT_ACCOUNT_NUMBER') !== this.debitAccountNumber || model.get('DEBIT_ACCOUNT_NUMBER') === '') {
            this.debitAccountNumber = model.get('DEBIT_ACCOUNT_NUMBER');
            if (this.options.action === 'select' && cutoff) {
            // if this is just a view, the date is already available.. just use it
                PaymentUtil.showCutoff(cutoff, datePicker, typeCode, this.options.action, model.get('STATUS'));
            } else if (beneBank || debitBank) {
            // get bene account
                beneAccount = this.beneAcctList.find(item => item.get('id') === model.get('BENE_ACCOUNT')
                    || item.get('name') === model.get('BENE_ACCOUNTENTITLEMENT'));
                model.set({
                    EFFECTIVEDATE: model.get('VALUE_DATE'),
                    SAMEDAYACH: model.get('SAMEDAYACH'),
                    BENE_BANK_CODE: beneAccount ? this.getAccountAttribute(beneAccount, 'Bene_Bank_Code') : '',
                    BENE_BANK_COUNTRY: beneAccount ? this.getAccountAttribute(beneAccount, 'BENE_COUNTRY') : '',
                    BENE_BANK_ID: beneAccount ? this.getAccountAttribute(beneAccount, 'BENE_ACCOUNT') : '',
                    BENE_BANK_TYPE: beneAccount ? this.getAccountAttribute(beneAccount, 'Bene_Account_Type') : '',
                });
                if (product === 'USACH' || product === 'ACH') {
                // ACH call back to server
                    PaymentUtil.updateCutoff(product, functionCode, typeCode, subType === undefined ? 'NACHA' : subType, 'INSERT', model, datePicker);
                } else {
                // set up for rtgs call
                    fxFieldValidation.doFieldValidation(this, model, 'VALUE_DATE', 'INSERT').then(() => {
                        PaymentUtil.showCutoff(model.get('CUTOFF_INFO'), datePicker, typeCode);
                    });
                }
            } else {
            /*
             * we dont have enough information (neither debit account or bene account),
             * so just do a regular date list call
             */
                SmbAPI.updateCutoff.get(model, datePicker, typeCode, SmbAPI.dates)
                    .then((rtnDates) => {
                        self.dates = rtnDates;
                    });
            }
        }
    },

    // Helper method to an attribute from the account's mapDataList
    getAccountAttribute(account, attr) {
        const val = util.findWhere(
            account.get('mapDataList'),
            {
                toField: attr,
            },
        );
        if (val) {
            return val.value;
        }
        return undefined;
    },

    templateHelpers() {
        const self = this;
        const isViewMode = this.options?.action === 'select';

        return {
            debitAccountLabel: locale.get('SMBPAY.TransferFrom'),
            creditAccountLabel: locale.get('SMBPAY.TransferTo'),
            hasAuditModel: (self.auditModel !== undefined && isViewMode),
            entryMethodName: self.context.entryMethodName,
            showComments: !isViewMode || this.model.get('SPECIAL_INSTRUCTIONS'),
            isViewMode,

            auditModelData() {
                return self.auditModel;
            },
        };
    },
    /**
     * When account is selected, if the account does not have balance then fetches the balance
     * and appends the balance to the label
     */
    updateSelectedAccountBalance(fieldName, newValue) {
        let listModels;
        let listElement;

        if (!util.isNullOrUndefined(newValue)) {
            if (fieldName === 'DEBIT_ACCOUNT_NUMBER') {
                listModels = this.debitAcctList.models;
                listElement = this.ui.$debitAcctList;
            } else {
                listModels = !util.isEmpty(this.beneAcctList.models)
                    ? this.beneAcctList.models[0].collection.models : undefined;
                listElement = this.ui.$beneAcctList;
            }
            if (listModels && this.shouldDisplayBalances()) {
                const selectedModel = util.find(listModels, listModel => listModel.get('name') === newValue);
                const entry = util.find(
                    listElement.get(0),
                    item => item.value === newValue,
                );
                    /*
                     * if the selected model already has balance available, then do
                     * not fetch the balance again
                     */
                if (selectedModel && !selectedModel.get('availableBalance')) {
                    this.fetchBalancesForAccounts(selectedModel, fieldName)
                        .then((response) => {
                            if (response && response.balance) {
                                selectedModel.set('availableBalance', `${response.balance} ${locale.get('smbPayments.available')}`);
                                entry.innerHTML = `${selectedModel.get('label')} - ${response.balance} ${locale.get('smbPayments.available')}`;
                            } else {
                                selectedModel.set('availableBalance', locale.get('payment.balance.none'));
                                entry.innerHTML = `${selectedModel.get('label')} -  ${locale.get('payment.balance.none')}`;
                            }
                            listElement.trigger('change');
                        });
                }
            }
        }
    },
});
