import Layout from '@glu/core/src/layout';
import tmpl from 'app/reports/views/lockbox/lockboxReportListType.hbs';
import GridApi from 'common/dynamicPages/api/grid';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import LockboxBatchIDs from 'app/reports/collections/lockboxBatchIDs';
import LockboxReportApi from 'app/reports/api/lockboxReports';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import Formatter from 'system/utilities/format';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import TransactionDetailsViewBtn from './transactionDetailsViewBtn';

const ReportListView = Layout.extend({
    ui: {
        $numPayments: '[data-hook="summary-num-payments"]',
        $totalAmount: '[data-hook="summary-total-amount"]',
        $numLockboxes: '[data-hook="summary-num-lockboxes"]',
        $numLocations: '[data-hook="summary-num-locations"]',
        $viewDetailsBtn: '[data-action="viewTransactionDetails"]',
    },

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
    },

    initialize(options) {
        this.reportSettings = options.reportSettings;
        this.isDefaultView = options.defaultView;
        this.depositDates = options.dates;
        this.selectedLockboxIds = options.selectedLockboxIds;
        this.selectedTypeNames = options.selectedTypeNames;
        this.lockboxList = this.selectedLockboxIds.join(', ');
        this.typeList = util.map(this.selectedTypeNames, item => `'${item}'`);
        this.typeList = this.typeList.join(', ');

        this.numPayments = 0;
        this.numLocations = 0;
        this.numLockboxes = 0;
        this.totalAmount = 0;
        this.once('removeFloats', this.hideFloatColumns);
        const types = util.chain(this.selectedTypeNames)
            .pluck('value')
            .uniq()
            .value();

        const opts = {
            context: {
                serviceName: '/lockbox/getGroupSummary',
                productCode: 'GIR',
                functionCode: 'LOCKBOX',
                typeCode: 'LBX_RPT',
            },

            enableSavedViews: true,
            selector: 'none',
            hideGridActionButtons: true,

            additionalSearchFields: [{
                operator: 'IN',
                fieldValue: this.selectedLockboxIds,
                dataType: 'NUMBER',
                fieldName: 'LOCKBOX_ID',
            }, {
                operator: 'BETWEEN',

                fieldValue: [
                    this.depositDates.minUnFormatted,
                    this.depositDates.maxUnFormatted,
                ],

                dataType: 'date',
                fieldName: 'DEPOSIT_DATE',
            }, {
                operator: 'IN',
                fieldValue: types,
                dataType: 'TEXT',
                fieldName: 'ITEM_TYPE_CODE',
            }],
        };

        this.transactionDetailsViewBtn = new TransactionDetailsViewBtn({
            batchIDs: this.batchIDs,
            selectedTypeNames: this.selectedTypeNames,
            depositDates: this.depositDates.value,
            depositDatesMinUnFormatted: this.depositDates.minUnFormatted,
            depositDatesMaxUnFormatted: this.depositDates.maxUnFormatted,
            isDefaultView: options.defaultView,
            visibleWhenData: true,
            /**
             * onDataChange method will be triggered from mobile grid
             * when mobile grid data changes, if onDataChange is defined
             * The method will return the new data as a way to communicate
             * between the mobile grid and backbone views.
             */
            onDataChange: (data) => {
                if (data) {
                    const batchIds = data.reduce((acc, row) => {
                        acc.push((util.findWhere(row.columns, { fieldName: 'BATCH_ID' }) || {}).fieldValue);
                        return acc;
                    }, []);
                    this.transactionDetailsViewBtn.model.set('batchIDs', batchIds);
                }
            },
        });

        this.postGridContent = this.transactionDetailsViewBtn;
        this.gridViewOptions = opts;
        this.gridView = GridApi.createServiceGridView(opts);
    },

    template: tmpl,
    grid: undefined,

    regions: {
        gridContent: '.grid-region',
    },

    onRender() {
        this.ui.$viewDetailsBtn.hide();
        if (this.hasLoadedRequiredData()) {
            // show grid data
            this.showGridData();
        } else {
            this.loadRequiredData();
        }
        this.renderTransactionDetailsViewBtn();
    },

    renderTransactionDetailsViewBtn() {
        if (this.transactionDetailsViewBtnContent) {
            this.transactionDetailsViewBtnContent.show(this.transactionDetailsViewBtn);
        }
    },

    loadRequiredData() {
        // Create an inquiry promise then do the logic below
        this.lockboxBatchIDs = new LockboxBatchIDs({
            additionalSearchFields: [{
                operator: 'IN',
                fieldValue: this.selectedLockboxIds,
                dataType: 'NUMBER',
                fieldName: 'LOCKBOX_ID',
            }, {
                operator: 'BETWEEN',

                fieldValue: [
                    this.depositDates.minUnFormatted,
                    this.depositDates.maxUnFormatted,
                ],

                dataType: 'date',
                fieldName: 'DEPOSIT_DATE',
            }],
        });

        this.getPromise(this.lockboxBatchIDs).then((batchIDs) => {
            this.batchIDs = batchIDs.pluck('BATCH_ID');
            this.transactionDetailsViewBtn.model.set('batchIDs', this.batchIDs);
            this.renderGridView();
        });
    },

    /**
     * @method renderGridView
     * - Defines the options for the GridApi,
     * renders the grid within the summaryRegion,
     * and creates a listener.
     */
    renderGridView() {
        const self = this;
        if (mobileUtil.isMobileGridEnabled()) {
            let MobileList = ReportListView;
            const mobileList = configureMobileInterface(MobileList);
            MobileList = MobileList.extend(mobileList, {
                useGridViewFromOptions: true,
            });
            this.summaryRegion.show(new MobileList({
                ...this.options,
                ...this.gridViewOptions,
                gridView: this.gridView,
            }));
        } else {
            this.summaryRegion.show(this.gridView);
        }
        this.gridView.on('gridapi:loaded', () => {
            self.setHasLoadedRequiredData(true);
            if (!mobileUtil.isMobileGridEnabled()) {
                self.showGridData();
            }
            self.inquiryId = 22606;
        });
        this.listenTo(this.appBus, `gridapi:spin_${this.gridView.cid}`, this.showGridData);
    },
    /**
     * @method showGridData
     * - Displays the grid based on data
     */
    showGridData() {
        const data = this.gridView.wrapper.rows;

        if (data.totalCount > 0) {
            this.ui.$viewDetailsBtn.show();
        }

        if (this.gridView.wrapper.rows.models) {
            this.totalAmount = this.calculateTotalAmount(data.models);
            this.numPayments = this.calculateNumPayments(data.models);
            this.numLockboxes = this.selectedLockboxIds.length;
            this.numLocations = this.options.flexModel.get('selection').length;
        }

        let singPluralLocation = locale.get('reports.location.plural');
        if (this.numLocations === 1) {
            singPluralLocation = locale.get('reports.location');
        }

        this.ui.$numLocations.html(`${this.numLocations} ${singPluralLocation}`);

        let singPluralLockbox = locale.get('reports.lockbox.plural');
        if (this.numLockboxes === 1) {
            singPluralLockbox = locale.get('reports.lockbox');
        }
        this.ui.$numLockboxes.html(`${this.numLockboxes} ${singPluralLockbox}`);

        this.ui.$numPayments.html(this.numPayments);
        this.ui.$totalAmount.html(Formatter.formatCurrency(this.totalAmount));
    },
    /**
     * @method export
     * - Performs the export main functionality
     * based on the data in the grid.
     */
    export() {
        if (this.exceedsExportRecordLimit()) {
            return;
        }

        const exportData = this.buildExportData('CSV');
        LockboxReportApi.doExport(this, exportData);
    },
    /**
     * @method print
     * - Performs the print main functionality
     * based on the data in the grid.
     */
    print() {
        if (this.exceedsExportRecordLimit()) {
            return;
        }

        const exportData = this.buildExportData('PDF');
        const printModal = new PrintViewModal({
            exportModel: exportData,
        });
        dialog.custom(printModal);
    },
    /**
     * @method exceedsExportRecordLimit
     * - Performs check on total number
     * records against max record number
     * allowed from app config
     * @return {boolean}
     */
    exceedsExportRecordLimit() {
        const exportLimit = parseInt(this.getMaxListViewExportRecords(), 10);
        let totalCount = 0;
        totalCount = this.numPayments;

        if (totalCount > exportLimit) {
            dialog.error(locale.get('export.lockboxexceed.error', exportLimit), locale.get('export.lockboxexceed.title'));
            return true;
        }

        return false;
    },
    /**
     * @method getMaxListViewExportRecords
     * - Returns the number max records
     * allowed from app config
     * @return {number}
     */
    getMaxListViewExportRecords() {
        return serverConfigParams.get('LockBoxExportReportMaxRows') || 5000;
    },
    /**
     * @method buildExportData
     * @param {String} format - Type of data
     * - Creates the data used in the export/print
     * methods
     * @return {object}
     */
    buildExportData(format) {
        const exportData = LockboxReportApi.buildExportData(format, 22599, this.gridView);

        if (!exportData.searchFields) {
            exportData.searchFields = [];
        }

        let searchField = {};
        const types = util.pluck(this.selectedTypeNames, 'value');
        searchField.operator = 'IN';
        searchField.fieldValue = this.selectedLockboxIds;
        searchField.dataType = 'numeric';
        searchField.fieldName = 'LOCKBOX_ID';
        exportData.searchFields.push(searchField);

        searchField = {};
        searchField.operator = 'IN';
        searchField.fieldValue = types;
        searchField.dataType = 'TEXT';
        searchField.fieldName = 'ITEM_TYPE_CODE';
        exportData.searchFields.push(searchField);

        exportData.customFilters = [{
            filterName: 'Depends',

            filterParam: [
                'minDepositDate',
                this.depositDates.min,
            ],
        }, {
            filterName: 'Depends',

            filterParam: [
                'maxDepositDate',
                this.depositDates.max,
            ],
        }];

        return exportData;
    },

    checkFloatColumns() {
        const self = this;
        this.hideAllFloats = true;
        util.each(this.gridView.wrapper.rows.models, (model) => {
            if (model.get('REPORT_FLOAT') === 'Y') {
                self.hideAllFloats = false;
            }
        });
        if (this.hideAllFloats) {
            this.trigger('removeFloats');
        }
    },

    hideFloatColumns() {
        this.loadRequiredData();
    },

    /**
     * Sum the total amount of all lockboxes
     * @param {Collection} collection
     * @returns {Number}
     */
    calculateTotalAmount(collection) {
        return collection.reduce((total, value) => total + parseFloat(value.get('TOTAL_AMOUNT').replace(/,/g, '')), 0);
    },

    /**
     * Sum the number of payments across lockboxes
     * @param {Collection|Array} arr
     * @returns {Number}
     */
    calculateNumPayments(arr) {
        return arr.reduce((total, value) => total + parseInt(value.get('NUM_PAYMENTS'), 10), 0);
    },

    /**
     * Get the total number of lockboxes
     * @param {Collection|Array} arr
     * @returns {Number}
     */
    calculateNumLockboxes(arr) {
        // Check for a collection. If not, use the util.pluck instead.
        const boxes = arr.pluck ? arr.pluck('LOCKBOX') : util.chain(arr).pluck('attributes').pluck('LOCKBOX');

        return util.chain(boxes).uniq().value().length;
    },

    /**
     * Get the total number of locations
     * @param {Collection|Array} arr
     * @returns {Number}
     */
    calculateNumLocations(arr) {
        const regExp = /\(([^)]+)\)/;

        const boxes = arr.map((value) => {
            const matches = regExp.exec(value.get('LOCKBOX'));
            return matches.length > 1 ? matches[1] : null;
        });

        return util.uniq(boxes).length;
    },

    viewTransactionDetails() {
        // Call the new button behavior
        this.transactionDetailsViewBtn.viewTransactionDetails();
    },

    /**
     * @method getPromise
     * @param {object} collection
     * @returns new promise
     * - Accepts a collection and returns a new promise
     */
    getPromise(collection) {
        return new Promise((resolve, reject) => {
            collection.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    templateHelpers() {
        const self = this;
        return {
            reportDate() {
                return self.reportSettings.get('VALUE_DATE');
            },

            numLockboxes() {
                return `1 ${locale.get('reports.lockbox')}`;
            },

            numLocations() {
                return `1 ${locale.get('reports.location')}`;
            },

            gridUtilityOptions: {
                hasRefresh: false,
            },
        };
    },
});

export default ReportListView;
