var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp;\n            <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":84}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\"></h1>\n</div>\n\n<section class=\"IssueVoidEntry section section-container\">\n\n    <div class=\"section-body\">\n\n        <div class=\"form-group IssueVoidEntry-item\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n            <div class=\"row\">\n                <div class=\"col-3\">\n                    <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.enter.issue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":53}}}))
    + "</h2>\n                </div>\n                <div class=\"col-9 text-right\">\n                    <div class=\"single-checkbox glu-switch switch-md switch-square\">\n                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.showoptionalfields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":30},"end":{"line":21,"column":68}}}))
    + "</span>\n                        <input id=\"optIssueFieldSwitch\" data-hook=\"getOptFieldSwitch\" type=\"checkbox\" data-checktype=\"issue\">\n                        <label for=\"optIssueFieldSwitch\" data-on=\"\" data-off=\"\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.showoptionalfields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":80},"end":{"line":23,"column":118}}}))
    + "</label>\n                    </div>\n                </div>\n            </div>\n            <div class=\"issue-region\"></div>\n            <div class=\"row IssueVoidEntry-add\">\n                <div class=\"col-12\">\n\n                    <label for=\"ADDISSUEAMOUNT\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RISK.Add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":48},"end":{"line":31,"column":69}}}))
    + " <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RISK.issue(s)",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":92},"end":{"line":31,"column":118}}}))
    + "</span>  </label>\n                    <input class=\"rapid-add-additional-amount form-control input-sm\" name=\"ADDISSUEAMOUNT\" id=\"ADDISSUEAMOUNT\" data-hook=\"getAddIssueAmount\" value=\"1\">\n                    <span aria-hidden=\"true\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RISK.issue(s)",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":45},"end":{"line":33,"column":71}}}))
    + "</span>\n                    <button class=\"btn btn-secondary\" data-hook=\"getAddItems\" data-checktype=\"issue\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":101},"end":{"line":34,"column":124}}}))
    + "</button>\n                </div>\n                <div class=\"item-info-container\">\n                    <span data-hook=\"getAddInfoIssue\" class=\"info-line\"></span>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"form-group IssueVoidEntry-item\">\n            <div class=\"row\">\n                <div class=\"col-3\">\n                    <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"risk.enter.void",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":45,"column":52}}}))
    + "</h2>\n                </div>\n                <div class=\"col-9 text-right\">\n                    <div class=\"single-checkbox glu-switch switch-md switch-square\">\n                        <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.showoptionalfields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":30},"end":{"line":49,"column":68}}}))
    + "</span>\n                        <input id=\"optVoidFieldSwitch\" data-hook=\"getOptFieldSwitch\" type=\"checkbox\" data-checktype=\"void\">\n                        <label for=\"optVoidFieldSwitch\" data-on=\"\" data-off=\"\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.showoptionalfields",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":79},"end":{"line":51,"column":117}}}))
    + "</label>\n                    </div>\n                </div>\n            </div>\n            <div class=\"void-region\"></div>\n            <div class=\"row IssueVoidEntry-add\">\n                <div class=\"col-12\">\n                    <label for=\"ADDVOIDAMOUNT\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RISK.Add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":47},"end":{"line":58,"column":68}}}))
    + " <span class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RISK.void(s)",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":91},"end":{"line":58,"column":116}}}))
    + "</span> </label>\n                    <input class=\"rapid-add-additional-amount form-control input-sm\" name=\"ADDVOIDAMOUNT\"  id=\"ADDVOIDAMOUNT\" data-hook=\"getAddVoidAmount\" value=\"1\">\n                    <span aria-hidden=\"true\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RISK.void(s)",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":60,"column":45},"end":{"line":60,"column":70}}}))
    + "</span>\n                    <button class=\"btn btn-secondary\" data-hook=\"getAddItems\" data-checktype=\"void\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.add",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":100},"end":{"line":61,"column":123}}}))
    + "</button>\n                </div>\n                 <div class=\"item-info-container\">\n                    <span data-hook=\"getAddInfoVoid\" class=\"info-line\"></span>\n                </div>\n            </div>\n        </div>\n\n    </div>\n\n</section>\n\n<div class=\"section section-summary\">\n    <div class=\"section-footer\">\n            <div class=\"row\">\n                <span data-hook=\"totalIssuesDisplay\" class=\"summary-large-text\"></span>\n                <span class=\"summary-med-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Issues",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":47},"end":{"line":77,"column":70}}}))
    + "</span>\n                <span class=\"summary-large-text\"> | </span>\n                <span data-hook=\"totalVoidsDisplay\" class=\"summary-large-text\"></span>\n                <span class=\"summary-med-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.Voids",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":80,"column":47},"end":{"line":80,"column":69}}}))
    + "</span>\n            </div>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n            <button type=\"button\" class=\"btn btn-primary\" data-hook=\"submit-button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":84},"end":{"line":85,"column":110}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"cancel-button\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":86,"column":86},"end":{"line":86,"column":112}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});