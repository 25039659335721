import constants from 'app/balanceAndTransaction/constants';
import util from '@glu/core/src/util';
import AccountSummaryView from 'app/balanceAndTransaction/views/accountSummary';

const CurrentDaySummary = AccountSummaryView.extend({
    id: 'showCurrentDayTab',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'showCurrentDayLink',
    },

    initialize(options) {
        util.extend(
            options,
            {
                context: {
                    actionMode: constants.SELECT,
                    functionCode: constants.INST,
                    productCode: constants.GIR,
                    typeCode: constants.BTR_TYPECODES.CURRENT.SUMMARY,
                },

                totalsService: `${constants.DEPOSIT_ACCTS_SERVICE_PREFIX + constants.CURRENT_DAY_PREFIX}accountSummary/getListViewTotals`,
                depositDetailPageUrl: constants.TRANSACTION_CURRDAY_DEPOSIT_DETAIL,
                creditCardDetailPageUrl: constants.TRANSACTION_CURRDAY_CREDIT_CARD_DETAIL,
                loanDetailPageUrl: 'BANK_ACC_INFO/loanCurrentDayAccountsTransactions',
                tieredLoanDetailPageUrl: 'BANK_ACC_INFO/tieredLoanCurrentDayAccountDetail',
            },
        );

        AccountSummaryView.prototype.initialize.call(this, options);
    },
});

export default CurrentDaySummary;
