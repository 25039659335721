import util from '@glu/core/src/util';
import PanelLayout from './panelView';
import ValidationController from '../validationController';

const ValidatePanel = PanelLayout.extend({
    ui: {
        completeIcon: '.complete-icon',
    },

    initialize(options) {
        PanelLayout.prototype.initialize.call(this, options);
        this.validator = new ValidationController({
            whiteList: options.whiteList || this.getWhiteList(),
            model: options.model || this.model,
        });
    },

    getWhiteList() {
        return [];
    },

    templateHelpers() {
        const helpers = PanelLayout.prototype.templateHelpers.call(this);

        return util.extend(
            helpers,
            {
                iconClassName: this.validator.isValid() ? 'complete-icon' : 'complete-icon hidden',
            },
        );
    },

    delegateEvents() {
        PanelLayout.prototype.delegateEvents.call(this);
        this.listenTo(this.validator, 'change', this.handleValidationChange);
    },

    handleValidationChange(bool) {
        if (bool) {
            this.ui.completeIcon.removeClass('hidden');
        } else {
            this.ui.completeIcon.addClass('hidden');
        }
    },
});

export default ValidatePanel;
