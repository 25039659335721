import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import services from 'services';
import moment from 'moment';
import compareLayoutTmpl from './compareLayout.hbs';
import PlanSelectView from './planSelect';
import CompareTableView from './compareTable';
import CompareGraphView from './compareGraph';

const CompareLayout = Layout.extend({
    template: compareLayoutTmpl,
    className: 'page cashflow-comparison',

    initialize(options) {
        this.collection = options.plans;

        if (!this.collection.findWhere({
            cashFlowPlanId: -1,
        })) {
            // only add these items if they don't exist.
            this.collection.add([{
                name: locale.get('cashflow.plan.comparison.actuals'),
                cashFlowPlanId: -1,
                version: 0,
                multiYearPlanId: -1,

                // as in, non-plan data
                usesRealData: true,
            }, {
                name: locale.get('cashflow.plan.comparison.forecast'),
                cashFlowPlanId: -2,
                version: 0,
                multiYearPlanId: -2,
                usesRealData: true,
            }]);
        }

        this.categoryService = options.categoryservice;

        this.listenTo(
            this.collection,
            {
                selected: this.getCompareData,
                fiscalYearChanged: this.handleFiscalYearChange,
            },
        );
    },

    ui: {
        $viewToggle: 'input[name="view-toggle"]',
    },

    events: {
        'change @ui.$viewToggle': 'toggleView',
    },

    regions: {
        planSelectPrimary: 'div[data-region="plan-select-primary"]',
        planSelectSecondary: 'div[data-region="plan-select-secondary"]',
        visualization: 'div[data-region="visualization"]',
    },

    onShow() {
        // this is for future expansion
        this.selectControls = [
            new PlanSelectView({
                name: locale.get('cashflow.plan.1'),

                // do not show forecast & actuals
                collection: this.collection,

                onlyShowPlans: true,
            }),
            new PlanSelectView({
                name: locale.get('cashflow.plan.2'),
                collection: this.collection,
                disabled: true,
            }),
        ];

        this.planSelectPrimary.show(this.selectControls[0]);
        this.planSelectSecondary.show(this.selectControls[1]);

        this.listenToOnce(
            this.selectControls[0],
            {
                firstSelectionMade: this.enableOtherControls,
            },
        );

        this.toggleView();
    },

    enableOtherControls() {
        this.selectControls[1].toggleDisabled(false);
    },

    toggleView() {
        let view;

        if (this.ui.$viewToggle.filter(':checked').val() === 'graph') {
            view = new CompareGraphView({
                collection: this.collection,
                selectControls: this.selectControls,
            });
        } else {
            view = new CompareTableView({
                collection: this.collection,
                selectControls: this.selectControls,
            });
        }

        this.visualization.show(view);
    },

    handleFiscalYearChange(model) {
        if (this.selectControls[1].model && this.selectControls[1].model.get('usesRealData')) {
            this.getCompareData(this.selectControls[1].model);
        } else {
            model.trigger('compareDataReady');
        }
    },

    getCompareData(model) {
        if (model.has('compareArray') && !model.get('usesRealData')) {
            // do not re-fetch compare data
            return;
        }

        // fyEnd and fyStart are required when using actuals & forecast
        const request = {
            multiYearPlanId: model.get('multiYearPlanId'),
            version: model.get('version'),
        };

        if (model.get('usesRealData')) {
            const plan1 = this.selectControls[0].model;
            if (plan1) {
                const year = this.selectControls[0].getSelectedFiscalYear();

                /*
                 * these dates do not correspond to the actual selected fiscal year
                 * start = moment(plan1.get('fyStart'), 'DD-MMM-YY'),
                 */
                const end = moment(plan1.get('fyEnd'), 'DD-MMM-YY').year(year);

                /*
                 * fiscal year is based on the year of the fyEnd
                 * set fyEnd year to selected year
                 * then subtract a year to get the start
                 */
                const calculatedStart = end.clone().subtract(1, 'year').add(1, 'day');
                request.fyStart = calculatedStart.format('MM/DD/YYYY');
                request.fyEnd = end.format('MM/DD/YYYY');
            } else {
                // if plan 1 isn't set, can't compare to anything
                return;
            }
        }

        http.post(services.generateUrl('/cashflowPlan/compare'), request, (data) => {
            // actuals & forecast come back unsorted
            const sortedData = util.sortBy(data, month => moment(new Date()).month(month.month - 1).year(month.year).format('X'));

            model.set({
                compareArray: sortedData,
            });
            /*
             * listening to change:compareArray is not useful
             * because it is cached after the first request
             */
            model.trigger('compareDataReady');
        });
    },
});

export default CompareLayout;
