import Wrapper from 'common/dynamicPages/api/gridWrapper';
import mobileUtil from 'mobile/util/mobileUtil';
import GridView from './grid';

const has = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export default GridView.extend({
    initialize(optionsParam) {
        if (!mobileUtil.isMobileGridEnabled()) {
            GridView.prototype.initialize.apply(this, [optionsParam]);
        } else {
            this.useMobileGrid = optionsParam.useMobileGrid;
            if (!optionsParam.useMobileGrid) {
                GridView.prototype.initialize.call(this, optionsParam);
                return;
            }
            const options = optionsParam;
            options.gridId = this.cid;
            options.viewId = options.viewId ? parseInt(options.viewId, 10) : '';
            this.gridOptions = options.gridOptions;
            this.wrapper = new Wrapper(options);
            this.context = options.context;
            this.lockedFields = options.lockedFields;
            this.hideGridActionButtons = options.hideGridActionButtons || false;
            this.hideButtons = options.hideButtons || false;
            this.enableColumnRename = options.enableColumnRenameControls || false;
            this.enableDateTimeSupport = options.enableDateTimeSupport || false;
            this.enableRowContextButtonCallbacks = options.enableRowContextButtonCallbacks
                || false;
            this.disableDeleteConfirm = options.disableDeleteConfirm || false;
            this.filter = has(options, 'filter') ? options.filter : true;
            this.disableAuxControl = has(options, 'disableAuxControl') ? options.disableAuxControl : false;
            this.sortable = has(options, 'sortable') ? options.sortable : true;
            this.paginate = has(options, 'paginate') ? options.paginate : 'advanced';
            this.draggable = has(options, 'draggable') ? options.draggable : true;
            this.tableBodyClass = has(options, 'tableBodyClass') ? options.tableBodyClass : false;
            this.rowClassName = has(options, 'rowClassName') ? options.rowClassName : false;
            this.serverSidePagination = true;
            if (options.disableServerSidePagination) {
                this.serverSidePagination = false;
            }
            this.rowSubView = options.rowSubView || false;
            this.savedViewsView = null;

            // saved views component configurations
            this.enableSavedViews = options.enableSavedViews;
            this.canSave = options.canSave;
            this.canSetDefault = options.canSetDefault;

            this.setNotificationData({
                title: ((this.context && this.context.serviceName)
                    ? this.context.serviceName : options.title),
            });

            // initial errors, set to null initially
            this.errors = null;

            this.loadPromise = this.createGridPromise();
        }
    },

    onRender() {
        if (!this.useMobileGrid) {
            GridView.prototype.onRender.call(this);
        }
    },
});
