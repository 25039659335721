var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"col-6\">\n                    <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Reject.Reason",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":27},"end":{"line":16,"column":68}}}))
    + "</label>\n                    <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"APPROVER_REJECTION_REASON") || (depth0 != null ? lookupProperty(depth0,"APPROVER_REJECTION_REASON") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"APPROVER_REJECTION_REASON","hash":{},"data":data,"loc":{"start":{"line":17,"column":23},"end":{"line":17,"column":52}}}) : helper)))
    + "</p>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <section class=\"section section-container\">\n        <div class=\"panel panel-tertiary\">\n            <div class=\"panel-heading\"\n                 role=\"tab\"\n                 id=\"notePanel\">\n                <h3 class=\"panel-title\">\n                    <a role=\"button\"\n                       data-toggle=\"collapse\"\n                       href=\"#notesPanelCollapse\"\n                       aria-expanded=\"true\"\n                       aria-controls=\"notesPanelCollapse\">\n                        <span class=\"indicator-icon\"></span>\n                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"serviceRequest.Additional.Information.Requested",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":24},"end":{"line":38,"column":84}}}))
    + "\n                    </a>\n                </h3>\n            </div>\n            <div id=\"notesPanelCollapse\"\n                 class=\"panel-collapse collapse in\"\n                 role=\"tabpanel\"\n                 aria-labelledby=\"notePanel\"\n                 aria-expanded=\"true\">\n                <div class=\"panel-body\">\n                    <div data-region=\"noteListRegion\"></div>\n                </div>\n            </div>\n        </div>\n    </section>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button type=\"button\"\n                class=\"btn "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":57,"column":27},"end":{"line":57,"column":38}}}) : helper)))
    + "\"\n                data-action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dataAction") || (depth0 != null ? lookupProperty(depth0,"dataAction") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dataAction","hash":{},"data":data,"loc":{"start":{"line":58,"column":29},"end":{"line":58,"column":43}}}) : helper)))
    + "\"\n                data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"dataHook") || (depth0 != null ? lookupProperty(depth0,"dataHook") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dataHook","hash":{},"data":data,"loc":{"start":{"line":59,"column":27},"end":{"line":59,"column":39}}}) : helper)))
    + "\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":60,"column":21}}}) : helper)))
    + "\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"section section-container\">\n    <div class=\"section-body\">\n        <div data-region=\"alertRegion\"></div>\n        <div class=\"row\">\n            <div class=\"col-3\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Request.Type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":63}}}))
    + "</label>\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Free-Form.Request",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":19},"end":{"line":8,"column":64}}}))
    + "</p>\n            </div>\n            <div class=\"col-3\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":57}}}))
    + "</label>\n                <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"getStatus") || (depth0 != null ? lookupProperty(depth0,"getStatus") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getStatus","hash":{},"data":data,"loc":{"start":{"line":12,"column":19},"end":{"line":12,"column":32}}}) : helper)))
    + "</p>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"APPROVER_REJECTION_REASON") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div data-region=\"detailsRegion\"></div>\n    </div>\n</section>\n<div data-region=\"noteFormRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNotes") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":0},"end":{"line":53,"column":7}}})) != null ? stack1 : "")
    + "<section class=\"section section-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":62,"column":13}}})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});