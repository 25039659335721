import GluListBuilder from '@glu/list-builder';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import listBuilderTmpl from './listBuilder.hbs';

/**
 * listbuilder extension to add support as a dialog (buttons/header), locale conversion,
 * counts in the header, and a loading screen for large lists
 */
export default GluListBuilder.extend({

    ui: util.extend({}, GluListBuilder.prototype.ui, {
        $loadingSpinner: '.loading-indicator-grid',
    }),

    template: listBuilderTmpl,
    skipLocaleKeys: ['sourceListHeader', 'targetListHeader'],

    // default countItems to true for our app
    initialize(optionsParam, ...rest) {
        const options = optionsParam;
        const self = this;

        this.saveHandler = options.saveHandler;
        this.cancelHandler = options.cancelHandler;
        this.queryDetails = options.queryDetails;
        options.countItems = options.countItems || true;
        this.dataIsLoaded = false;

        if (options.localeKeys) {
            options.text = {};
            util.each(options.localeKeys, (value, key) => {
                options.text[key] = self.skipLocaleKeys.indexOf(key) !== -1
                    ? value : locale.get(value);
            });
        }

        /*
         * Normally this is done in the glu listBuilder's initialize function but we've got to
         * do it manually since there is the potential that we don't run the Glu list builder's
         * initialization function until all data has been retrieved for the list
         */
        this.text = GluListBuilder.prototype.setText(options);

        // if the list is incomplete additional calls will need to be made before initialization
        if (this.queryDetails && options.sourceCollection.length >= 250) {
            this.loadData(options, ...rest);
        } else { // otherwise, show things like normal
            this.dataIsLoaded = true;
            this.dataLoaded(options, ...rest);
        }
    },

    /*
     * Uses existing query details passed in when creating the list builder to kick off network
     * calls to retreive the remainder of the data for the list. This is generally only
     * necessary in instances where the list exceeds 250 items
     */
    loadData(options, ...rest) {
        this.getListData(0).then((allItems) => {
            // indicate that all items are loaded
            this.dataIsLoaded = true;

            // if the user has provided a specific function to format the returned data, do it
            const newCollectionContent = this.options.formatData
                ? this.options.formatData(allItems)
                : allItems;

            this.options.sourceCollection.reset(newCollectionContent);

            this.dataLoaded(options, ...rest);
        }, options.loadDataFailure);
    },

    getListData(starting, accumulator) {
        const self = this;

        const data = {
            queryCriteria: this.queryDetails.queryCriteria,
            requestHeader: {
                queryPagesize: 250,
                queryOffset: starting || 0,
                requestId: 0,
            },
        };

        return http.post(this.queryDetails.path, data).then((response) => {
            const { numRows } = response.queryResponse.QueryData;
            const accumulatedData = self.mergeAccumulatedResponse(accumulator, response);
            if (numRows >= 250) {
                return self.getListData(starting + numRows, accumulatedData);
            }
            return accumulatedData;
        });
    },

    /**
     * Keeps track of items within the list as they come in from network calls
     * @param {Object} [accumulator]
     * @param {Object} response
     * @return {Object}
     */
    mergeAccumulatedResponse(accumulator, response) {
        if (accumulator === undefined) {
            return response;
        }

        const totalRows = response.queryResponse.QueryData.numRows
            + accumulator.queryResponse.QueryData.numRows;
        const temp = accumulator.queryResponse.QueryData.queryRows;
        const queryRows = temp.concat(response.queryResponse.QueryData.queryRows);

        return util.extend({}, accumulator, {
            queryResponse: {
                QueryData: {
                    numRows: totalRows,
                    queryRows,
                },
                respHeader: accumulator.queryResponse.respHeader,
                responseParameters: accumulator.queryResponse.responseParameters,
            },
        });
    },

    dataLoaded(options, ...rest) {
        GluListBuilder.prototype.initialize.apply(this, [options, ...rest]);

        this.render();

        // set listeners for updated lists
        this.on('sourceListUpdated', util.debounce(() => { this.updateSourceHeader(); }, 50));
        this.on('targetListUpdated', util.debounce(() => { this.updateTargetHeader(); }, 50));
    },

    onRender(...args) {
        if (this.dataIsLoaded) {
            GluListBuilder.prototype.onRender.apply(this, args);
            this.updateSourceHeader();
            this.updateTargetHeader();
        } else {
            this.ui.$loadingSpinner.show();
        }
    },
    applySearchFilter() {
        const $filter = this.ui.sourceListSearchFilter;
        const filterText = $filter.val()?.trim() || '';

        /**
         * The matcher is passed element text and filterText.
         * Eventually, we may pass the entire model to allow
         *  custom matchers to access any attributes.
         */
        this.sourceList.$el.children().each((index, element) => {
            const $element = this.$(element);
            const matches = this.matcher($element.text().toLowerCase(), filterText.toLowerCase());
            $element.toggleClass('is-filtered-out', !matches);
        });
    },
    templateHelpers() {
        return {
            ...GluListBuilder.prototype.templateHelpers.call(this),
            dataIsLoaded: this.dataIsLoaded,
            showSearch: this.options?.showSearch,
        };
    },

    updateSourceHeader() {
        this.$('.source-section-header-text')
            .text(locale.get(this.text.sourceListHeader, this.sourceCollection.length));
    },

    updateTargetHeader() {
        this.$('.target-section-header-text')
            .text(locale.get(this.text.targetListHeader, this.targetCollection.length));
    },

    getButtons() {
        const self = this;

        return [{
            text: this.text.save,
            className: 'btn-primary',

            callback(context) {
                if (context && self.options.showBusyIndicator) {
                    context.target.setAttribute('aria-busy', true);
                }
                util.delay(() => {
                    if (!self.options.dataPersist) {
                        /*
                         * we have to clear collections first,
                         * otherwise the records occasionally get tangled
                         * when adding/removing back and forth
                         */
                        self.options.sourceCollection.reset();
                        self.options.targetCollection.reset();
                        self.options.sourceCollection.add(self.sourceCollection.slice(0));
                        self.options.targetCollection.add(self.targetCollection.slice(0));
                    }

                    if (self.saveHandler) {
                        self.saveHandler();
                    }

                    if (context) {
                        context.target.setAttribute('aria-busy', false);
                    }
                    dialog.close();
                }, 100);
            },
        }, {
            text: this.text.cancel,
            className: 'btn-secondary',

            callback() {
                if (self.cancelHandler) {
                    self.cancelHandler();
                }
                dialog.close();
            },
        }];
    },

    getHeader() {
        return this.text.title;
    },
});
