var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||alias2).call(alias1,{"name":"panel","hash":{"name":"secondary-account-panel","collapsed":(lookupProperty(helpers,"collapsed")||(depth0 && lookupProperty(depth0,"collapsed"))||alias2).call(alias1,{"name":"collapsed","hash":{},"data":data,"loc":{"start":{"line":2,"column":77},"end":{"line":2,"column":88}}}),"theme":"panel-tertiary","locale":"bab.secondary.account"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":96,"column":14}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"col-6\">\n                <div class=\"form-group\">\n                    <label for=\"SECOND_BENE_ACCOUNTTYPE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.account.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":86}}}))
    + "</label>\n                    <a data-toggle=\"popover\" data-trigger=\"hover focus\" title=\"\" role=\"button\" data-placement=\"auto\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.nacha.requirements.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":131},"end":{"line":7,"column":170}}}))
    + "\">\n                        <span class=\"icon-info\"></span>\n                    </a>\n\n                    <select class=\"form-control\" data-bind=\"model\" name=\"SECOND_BENE_ACCOUNTTYPE\">\n                        <option value=\"\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.select.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":41},"end":{"line":12,"column":69}}}))
    + "</option>\n                        <option value=\"DD\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.checking",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":43},"end":{"line":13,"column":68}}}))
    + "</option>\n                        <option value=\"SV\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.savings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":43},"end":{"line":14,"column":67}}}))
    + "</option>\n                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"SECOND_BENE_ACCOUNTTYPE\"></span>\n                </div>\n            </div>\n            <div class=\"col-6\">\n                <div class=\"form-group required\">\n                    <div data-region=\"maskedInputRegion\" /> \n                </div>\n            </div>\n        </div>\n        <div class=\"row bene-bank-code\">\n            <div class=\"col-12\">\n                <div class=\"form-group required\">\n                    <label for=\"SECOND_BENE_BANK_SORTCODETYPE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.bank.routing.code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":63},"end":{"line":28,"column":97}}}))
    + "</label>\n                    <input type=\"text\" class=\"form-control\" name=\"SECOND_BENE_BANK_SORTCODETYPE\"  maxlength=\"35\" data-bind=\"model\" data-hook=\"secondary-bene-bank-code\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"SECOND_BENE_BANK_SORTCODETYPE\"></span>\n                </div>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_ID") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAddress") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":65,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"row\">\n            <div class=\"col-12\">\n                <p class=\"secondary-account-allocation-text\"> Allocation is set for the Secondary Payroll Account. The remaining amount will be deposited into the Primary Payroll Account. </p>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"col-4\">\n                <div class=\"form-group\">\n                    <label for=\"SECOND_ALLOCATION_TYPE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.allocation.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":56},"end":{"line":74,"column":88}}}))
    + "</label>\n                    <select class=\"form-control\" data-bind=\"model\" name=\"SECOND_ALLOCATION_TYPE\" data-hook=\"allocation-type\">\n                        <option disabled selected value>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.select.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":76,"column":56},"end":{"line":76,"column":84}}}))
    + "</option>\n                        <option value=\"1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.percent",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":42},"end":{"line":77,"column":66}}}))
    + "</option>\n                        <option value=\"2\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.fixedamount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":42},"end":{"line":78,"column":70}}}))
    + "</option>\n                    </select>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"SECOND_ALLOCATION_TYPE\"></span>\n                </div>\n            </div>\n            <div class=\"col-4\" data-hook=\"allocation-amount-container\">\n                <div class=\"form-group\">\n                    <label data-hook=\"allocation-label\" for=\"SECOND_ACCOUNT_ALLOCATION\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.allocation.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":88},"end":{"line":85,"column":122}}}))
    + "</label>\n                    <div class=\"input-group\">\n                        <input class=\"form-control\" type=\"text\" data-type=\"amount\" data-bind=\"model\" name=\"SECOND_ACCOUNT_ALLOCATION\" data-hook=\"allocation-amount\"/>\n                        <div class=\"input-group-addon\" data-hook=\"allocation-amount-currency-container\">\n                            <input class=\"currency-field\" readonly data-bind=\"model\" data-hook=\"secondary-currency-display\" tab-index=\"-1\" name=\"SECOND_BENE_ACCOUNT_CURRENCY\"/>\n                        </div>\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"SECOND_ACCOUNT_ALLOCATION\"></span>\n                </div>\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"col-12 bene-bank-aux-field\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SECOND_BENE_BANK_NAME") || (depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_NAME") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SECOND_BENE_BANK_NAME","hash":{},"data":data,"loc":{"start":{"line":36,"column":56},"end":{"line":36,"column":83}}}) : helper)))
    + "</div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"col-12\">\n                    <p class=\"bene-bank-aux-field\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_ADDRESS_1") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":45,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_ADDRESS_2") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":48,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_CITY") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":58,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_COUNTRY") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":61,"column":31}}})) != null ? stack1 : "")
    + "                    </p>\n                </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SECOND_BENE_BANK_ADDRESS_1") || (depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_ADDRESS_1") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SECOND_BENE_BANK_ADDRESS_1","hash":{},"data":data,"loc":{"start":{"line":44,"column":28},"end":{"line":44,"column":59}}}) : helper)))
    + "<br>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SECOND_BENE_BANK_ADDRESS_2") || (depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_ADDRESS_2") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SECOND_BENE_BANK_ADDRESS_2","hash":{},"data":data,"loc":{"start":{"line":47,"column":28},"end":{"line":47,"column":59}}}) : helper)))
    + "<br>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SECOND_BENE_BANK_CITY") || (depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_CITY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"SECOND_BENE_BANK_CITY","hash":{},"data":data,"loc":{"start":{"line":50,"column":28},"end":{"line":50,"column":55}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_STATE") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":28},"end":{"line":53,"column":35}}})) != null ? stack1 : "")
    + " <br>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                , "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SECOND_BENE_BANK_STATE") || (depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_STATE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SECOND_BENE_BANK_STATE","hash":{},"data":data,"loc":{"start":{"line":52,"column":34},"end":{"line":52,"column":62}}}) : helper)))
    + "\n                            ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_STATE") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":28},"end":{"line":57,"column":35}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SECOND_BENE_BANK_STATE") || (depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_STATE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SECOND_BENE_BANK_STATE","hash":{},"data":data,"loc":{"start":{"line":56,"column":32},"end":{"line":56,"column":60}}}) : helper)))
    + "<br>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SECOND_BENE_BANK_COUNTRY") || (depth0 != null ? lookupProperty(depth0,"SECOND_BENE_BANK_COUNTRY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SECOND_BENE_BANK_COUNTRY","hash":{},"data":data,"loc":{"start":{"line":60,"column":28},"end":{"line":60,"column":58}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isEmployee") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":97,"column":7}}})) != null ? stack1 : "");
},"useData":true});