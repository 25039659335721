import Layout from '@glu/core/src/layout';
import viewAccountsDropdownTmpl from 'app/balanceAndTransaction/views/viewAccountsDropdown.hbs';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import constants from 'common/dynamicPages/api/constants';
import { maskValue } from 'common/util/maskingUtil';
import AvailableAccountsModel from 'app/balanceAndTransaction/models/availableAccounts';

const ViewAccountsDropdown = Layout.extend({
    template: viewAccountsDropdownTmpl,

    ui: {
        $accountSelect: '[data-hook="account-select"]',
    },

    events: {
        'change @ui.$accountSelect': 'onViewAccountSelection',
    },

    initialize(options) {
        // Passed in options that will interact with the affected Editable Grid
        this.accountSelectCallback = options.accountSelectCallback;
        this.initialAccount = options.initialAccount;

        // Setup up model to collect other available accounts to show on this page
        this.availableAccountsModel = new AvailableAccountsModel({ tabId: options.tabId });
        this.listenTo(this.availableAccountsModel, 'change', this.setupViewAccountDropdown);
    },

    onRender() {
        if (!this.dropDownSetup) {
            this.availableAccountsModel.fetch();
        } else {
            this.setupViewAccountDropdown();
        }
    },

    // Essential setup for dropdown
    setupViewAccountDropdown() {
        const accountsData = this.availableAccountsModel.get('accounts');

        // only create dropdown if account data contains more than 1 account
        if (accountsData.length > 1) {
            const currentAcctIndex = this.findIndexOfCurrentAccount(accountsData);
            const availableAccounts = this.aggregateViewAccountsData(accountsData);
            const lookup = this.availableAccountsModel;

            let rowsPerPage = 1;

            const accountOptions = {
                dropdownAutoWidth: true,
                triggerChange: true,

                query: util.debounce((query) => {
                    lookup.setStartRow((((query.page - 1) * rowsPerPage) + 1));
                    rowsPerPage = (query.term.length < constants.COMBO_MIN_CHARS)
                        ? constants.COMBO_MIN_SIZE : constants.MAX_SERVER_ROWSPERPAGE;
                    lookup.setSearch(query.term);

                    lookup.request.rowsPerPage = query.term.length < 3 ? 10 : 50;

                    lookup.send().then((result) => {
                        this.dropDownSetup = true;
                        const rowHashes = this.availableAccountsModel.parse(result);
                        this.lookupAccounts = rowHashes.accounts;
                        const results = this.aggregateViewAccountsData(this.lookupAccounts);

                        query.callback({
                            results,
                        });
                    });
                }, constants.COMBO_DEBOUNCE),

                initSelection: (element, cb) => {
                    const { text } = availableAccounts[currentAcctIndex] || {};
                    if (text) {
                        cb({
                            id: element.val(),
                            text,
                        });
                    } else {
                        this.fetchAndMergeMissingAccount()
                            .then(() => {
                                this.setupViewAccountDropdown();
                            })
                            .catch(() => {
                                cb();
                            });
                    }
                },
            };

            this.ui.$accountSelect.comboBox(accountOptions);
        }
    },

    /**
     * @method fetchAndMergeMissingAccount
     * @return {Promise} Promise resolves if missing account is successfully merged.
     * Promise rejects otherwise.
     */
    fetchAndMergeMissingAccount() {
        return new Promise((resolve, reject) => {
            const currentAcctFilter = this.initialAccount.get('accountFilter') || null;
            if (!currentAcctFilter) {
                reject();
            }

            const { url } = this.availableAccountsModel;
            const payload = this.availableAccountsModel.getRequest();
            delete payload.sortFields;
            payload.searchFields = [{
                fieldName: 'ACCOUNTFILTER',
                operator: 'EQ',
                fieldValue: [currentAcctFilter],
                dataType: 'text',
            }];

            http.post(url, payload).then((response) => {
                const missingAccountArray = util.map(
                    response.rows,
                    this.availableAccountsModel.createAccountObject,
                );
                const existingAccounts = this.availableAccountsModel.get('accounts');
                const mergedAccounts = [
                    ...existingAccounts,
                    ...missingAccountArray,
                ];
                this.availableAccountsModel.set('accounts', mergedAccounts);
                resolve();
            }, () => {
                reject();
            });
        });
    },

    /**
     * @method findIndexOfCurrentAccount
     * @param {Object[]} accounts
     * @return {number} index
     */
    findIndexOfCurrentAccount(accounts) {
        let currentAcctFilter = this.initialAccount.get('accountFilter') || null;

        if (this.acctSelected) {
            currentAcctFilter = this.acctSelected.ACCOUNTFILTER;
        }

        return util.indexOf(accounts, util.findWhere(accounts, {
            ACCOUNTFILTER: currentAcctFilter,
        }));
    },

    /**
     * @method aggregateViewAccountsData
     * @param {array} accounts
     * @return {array} formatted dropdown data array containing dropdown options
     * with labels
     */
    aggregateViewAccountsData(accounts) {
        return util.map(accounts, (
            acct,
            i,
        ) => ({
            id: i,
            text: util.unescape(`${maskValue(acct.ACCOUNTNUM)} - ${acct.CLIENTACCOUNTNAME} - ${acct.CURRENCYCODE}`),
        }));
    },

    /**
     * @method onViewAccountSelection
     * @param {Event} e
     * @return {*} result of the account callback
     * Will get account selected and call parent's account selection callback with the account.
     */
    onViewAccountSelection(e) {
        this.acctSelected = this.lookupAccounts[e.val];
        return this.accountSelectCallback(this.acctSelected);
    },
});

export default ViewAccountsDropdown;
