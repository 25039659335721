import CollectionView from '@glu/core/src/collectionView';
import ItemListView from './permission';

export default CollectionView.extend({
    tagName: 'div',
    className: 'group-list',
    itemView: ItemListView,

    initialize() {
        this.itemViewOptions = {};
    },
});
