import Glu from '../../glu';
import $ from '../../$';
import util from '../../util';
import appBus from '../../appBus';
import Model from '../../model';
import NestedModel from '../../nestedModel';

function canBeChecked(el) {
  return el.tagName === 'INPUT' && (el.type === 'checkbox' || el.type === 'radio');
}

function isSelectElement(el) {
  return el.type === 'select-one' || el.type === 'select-multiple';
}

function isHiddenElement(el) {
  return el.type === 'hidden';
}

function hasTextSelection(el) {
  // use try catch in order to see if feature is supported, suggested by stackoverflow:
  // http://stackoverflow.com/a/23704449
  try {
    return el.selectionEnd;
  } catch (e) {
    // Attempting to access element.selectionEnd will throw a DOMException if
    // not supported
    return false;
  }
}

function getDomValue($el) {
  let value = $el.val();

  if (canBeChecked($el.get(0))) {
    value = $el.prop('checked') ? value : undefined;
    value = value === 'on' ? true : value; // `on` is the value for a checked checkbox with no set value.
  }
  // TODO remove/modify this condition
  if ($el.data('select2')) {
    value = $el.data('select2').val(); // select2/comboBox returns an array for multi-select vs. comma-separated string from $.val
  } else if ($el.data('flexDropdown')) {
    value = $el.flexDropdown('val'); // get selected IDs
  }

  return value;
}

function setDomValue($el, modelValue) {
  const el = $el.get(0);
  const selectElement = isSelectElement(el);
  const hidden = isHiddenElement(el);
  let selectionStart;
  let selectionEnd;

  if (canBeChecked(el)) {
    const domValue = $el.is(':checked');

    modelValue = !!modelValue;

    // trigger 'change' event only if initial value is different from a new one
    if (domValue !== modelValue) {
      $el.prop('checked', modelValue).change();
    }

    return;
  }

  if (hasTextSelection(el)) {
    selectionStart = el.selectionStart; // eslint-disable-line prefer-destructuring
    selectionEnd = el.selectionEnd; // eslint-disable-line prefer-destructuring
  }

  // If this is a select element and, only attempt to set value if it's a valid option
  if (selectElement) {
    const isAvailableOption = ($el.find(`option[value="${modelValue}"]`).length > 0);

    if (!util.isNullOrUndefined(modelValue) && !isAvailableOption) {
      return;
    }
  }

  $el.val(modelValue);

  // Change event has to be fired for `select` elements in order to re-draw any Glu.combobox
  // Without a `change` event, select2 will not re-draw the selected value
  if (selectElement || hidden) {
    $el.trigger('change');
  }

  // Nicely place the cursor where it was before and avoid nasty jump
  if (selectionStart) {
    el.setSelectionRange(selectionStart, selectionEnd);
  }
}

function setModelValueCheckbox(model, $el) {
  const key = $el.attr('name');

  // check if set value is unique & add it as an array
  const currentVal = model.get(key);
  const newVal = getDomValue($el);
  let finalVal = null;

  // adding items
  if (newVal) {
    if (currentVal) {
      if (util.isArray(currentVal)) {
        finalVal = currentVal.concat([newVal]); // ensure that the 'change' event will be fired by creating a new array
      } else {
        // if only one item, turn val into an array
        finalVal = [];
        finalVal.push(currentVal);
        finalVal.push(newVal);
      }
    } else {
      // if previously no item set, return single item
      finalVal = newVal;
    }
  }

  // removing items
  if (!newVal) {
    // if array, then there are multiple items selected
    if (util.isArray(currentVal)) {
      // get the value of unchecked item, then use that value to remove from array
      const uncheckedVal = $el.val();

      // remove selected item from array
      finalVal = util.without(currentVal, util.findWhere(currentVal, uncheckedVal));

      // if the array only has 1 item, do not use array to story value
      if (finalVal.length === 1) {
        [finalVal] = finalVal;
      } else if (finalVal.length === 0) {
        finalVal = null;
      }
    } else {
      // the unchecked item was the only item selected from before, so set finalVal to null
      finalVal = null;
    }
  }

  return model.set(key, finalVal, {
    changeFromBinding: true
  });
}

function setModelValue(model, $el) {
  const key = $el.attr('name');

  // Handle checkbox fields differently, if there are more than one checkbox, multiple values are stored in array
  if ($el.get(0).type === 'checkbox' && $el.is('[data-nullable]')) {
    return setModelValueCheckbox(model, $el);
  }

  return model.set(key, getDomValue($el), {
    changeFromBinding: true
  });
}

function listenToInput(view) {
  // Only apply listener to elements that have *both* `data-bind` and `name` attributes
  view.$el.on('input change', '[data-bind][name]', (e) => {
    // Prevent parent view for from responding to the child view event
    e.stopPropagation();

    const $el = $(e.currentTarget);
    const model = view[$el.data().bind];

    setModelValue(model, $el);
  });
}

function insertValues(attrs, bindings) {
  util.each(attrs, (value, key) => {
    let $inputs = bindings.filter(`[name="${key}"]`);

    // If this matches a set of radio buttons, only check the relevant button
    if ($inputs.length > 1 && $inputs.prop('type') === 'radio') {
      $inputs = $inputs.filter(function getInputValue() {
        return this.value === value;
      });
    }

    // nestedModel binding uses the following attribute on element name="some.nestedValue[0]"
    // which returns as some.nestedValue.0 which can't be find in the view
    if (!$inputs.length && util.isArray(value)) {
      util.each(value, (content, index) => {
        const $input = bindings.filter(`[name="${key}[${index}]"]`);

        // return early if it's not present in dom
        if (!$input.length) {
          return undefined;
        }

        setDomValue($($input), value[index]);
        return bindings.filter(`[data-text="${key}[${index}]"]`).text(value[index]);
      });
    }

    $inputs.each(function doSetDomValue() {
      setDomValue($(this), value);
    });

    bindings.filter(`[data-text="${key}"]`).text(value);
  });
}

function handleElementsVisibility(view) {
  const { model } = view;
  const dataShow = view.$('[data-show-if]');
  const dataHide = view.$('[data-hide-if]');
  const dataDisable = view.$('[data-disable-if]');
  const dataEnable = view.$('[data-enable-if]');

  util.each(dataShow, (el) => {
    const data = $(el).data();

    const show = util.isFunction(model[data.showIf]) ?
      model[data.showIf]() :
      !!model.get(data.showIf);

    $(el).toggleClass('hidden', !show);
  });

  util.each(dataHide, (el) => {
    const data = $(el).data();

    const hide = util.isFunction(model[data.hideIf]) ?
      model[data.hideIf]() :
      !!model.get(data.hideIf);

    $(el).toggleClass('hidden', hide);
  });

  util.each(dataDisable, (el) => {
    const data = $(el).data();

    const disable = util.isFunction(model[data.disableIf]) ?
      model[data.disableIf]() :
      !!model.get(data.disableIf);

    $(el).attr('disabled', disable);
  });

  util.each(dataEnable, (el) => {
    const data = $(el).data();

    const enable = util.isFunction(model[data.enableIf]) ?
      model[data.enableIf]() :
      !!model.get(data.enableIf);

    $(el).attr('disabled', !enable);
  });
}

function listenToModel(view) {
  view.listenTo(view.model, 'change', (model, options = {}) => {
    const error = view.model.get('error');
    const warning = view.model.get('warning');
    const attrs = view.model.changedAttributes();

    if (options.changeFromBinding) {
      const $texts = view.$('[data-text]');
      util.each(attrs, (value, key) => {
        const $tags = $texts.filter(`[data-text="${key}"]`);
        const $validationElement = view.$(`[data-validate="${key}"]`);

        $tags.each(function setTextValue() {
          $(this).text(value);
        });

        // if element is not part of this view then return
        if (!$validationElement.length) {
          return;
        }

        if (!util.isEmpty(error) && error[key]) {
          $validationElement.text('').closest('.has-error').removeClass('has-error');
          delete error[key];
        }

        if (!util.isEmpty(warning) && warning[key]) {
          $validationElement.text('').closest('.has-warning').removeClass('has-warning');
          delete warning[key];
        }
      });
    } else {
      insertValues(attrs, view.$('[data-bind]'));
    }

    handleElementsVisibility(view);
  });

  view.listenTo(view.model, 'request validate', () => {
    view.$('[data-disable-on]').attr('disabled', true);
  });

  view.listenTo(view.model, 'sync invalid', () => {
    view.$('[data-disable-on]').removeAttr('disabled');
  });
}

function listenToValidation(view) {
  view.listenTo(view.model, 'validate', () => {
    const warnings = view.model.get('warning');
    const errors = view.model.get('error');
    const $bindings = view.$('[data-bind]');

    if (warnings) {
      util.each(warnings, (value, key) => {
        $bindings.filter(`[data-validate="${key}"]`).text('').closest('.has-warning').removeClass('has-warning');
      });
    }

    if (errors) {
      util.each(errors, (value, key) => {
        $bindings.filter(`[data-validate="${key}"]`).text('').closest('.has-error').removeClass('has-error');
      });
    }

    view.model.unset('warning');
    view.model.unset('error');
  });

  view.listenTo(view.model, 'invalid', () => {
    const warnings = view.model.get('warning');
    const errors = view.model.get('error');
    const $bindings = view.$('[data-bind]');

    if (warnings) {
      util.each(warnings, (value, key) => {
        const $filteredValue = $bindings.filter(`[data-validate="${key}"]`);
        let $filteredValueContainer = $filteredValue.closest('.form-group');

        // If no form-group is present, fallback to the closest div.  This allows
        // us to move toward semantic classes and not being tied to bootstrap's .form-group element.
        if ($filteredValueContainer.length === 0) {
          $filteredValueContainer = $filteredValue.closest('div');
        }

        $filteredValue.text(value.join('. '));
        $filteredValueContainer.addClass('has-warning');
      });
    }

    if (errors) {
      util.each(errors, (value, key) => {
        const $filteredValue = $bindings.filter(`[data-validate="${key}"]`);
        let $filteredValueContainer = $filteredValue.closest('.form-group');

        // If no form-group is present, fallback to the closest div.  This allows
        // us to move toward semantic classes and not being tied to bootstrap's .form-group element.
        if ($filteredValueContainer.length === 0) {
          $filteredValueContainer = $filteredValue.closest('div');
        }

        $filteredValue.text(value.join('. '));
        $filteredValueContainer.addClass('has-error');
      });
    }

    if (warnings || errors) {
      appBus.trigger('validation:rendered', view);
    }
  });
}

function setInitialValues(view) {
  let { attributes } = view.model;
  const $bindings = view.$('[data-bind]');

  function getNestedAttributes(set, depth) {
    depth = depth || '';

    // set param has a simple type i.e. set = array[0] = 'sample'
    // so attributes['array[0]'] = 'sample'
    if (!util.isObject(set) && !util.isArray(set)) {
      attributes[depth] = set;
      return;
    }

    util.each(set, (value, key) => {
      if (util.isArray(value)) {
        util.each(value, (itemValue, i) => {
          getNestedAttributes(itemValue, depth ? `${depth}.${key}[${i}]` : `${key}[${i}]`);
        });

        return;
      }

      if (util.isObject(value)) {
        // TODO Necessary PT-X hack: Account for an attribute being a Model
        // (This is only required until PT-X fully drops BackboneRelational)
        if (value && typeof value.toJSON === 'function') {
          value = value.toJSON();
        }
        getNestedAttributes(value, depth ? `${depth}.${key}` : key);
        return;
      }

      attributes[depth ? `${depth}.${key}` : key] = value;
    });
  }

  if (view.model instanceof NestedModel) {
    attributes = {};
    getNestedAttributes(view.model.attributes);
  }

  // Render current attributes
  util.each(attributes, (value, key) => {
    const $inputs = $bindings.filter(`[name="${key}"]`);
    $inputs.each(function processInputs() {
      const $el = $(this);

      if (canBeChecked($el.get(0))) {
        if ($el.is(':radio')) {
          // we should check name and value of the radio buttons
          if ($el.val() === value) {
            $el.prop('checked', !!value);
          }
        } else if (util.isArray(value) && $el.is('[data-nullable]')) {
          const isChecked = util.contains(value, $el.val());
          $el.prop('checked', isChecked);
        } else if ($el.is('[data-nullable]')) {
          if ($el.val() === value) {
            $el.prop('checked', !!value);
          }
        } else {
          $el.prop('checked', !!value);
        }
      } else {
        $el.val(value);
      }
    });

    $bindings.filter(`[data-text="${key}"]`).text(value);
  });

  handleElementsVisibility(view);
}

Glu.templateBindings = {
  bind(view) {
    if (!view.model) {
      view.model = new Model();
    }

    setInitialValues(view);

    if (view.viewBindingEnabled) {
      return;
    }

    listenToModel(view);
    listenToInput(view);
    listenToValidation(view);

    view.viewBindingEnabled = true;

    view.once('close', () => {
      view.viewBindingEnabled = false;
    });
  }
};

export default Glu.templateBindings;
