import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import locale from '@glu/locale';
import $ from 'jquery';
import filterApi from 'common/dynamicPages/api/filters';
import dateUtil from 'common/util/dateUtil';
import Constants from 'app/balanceAndTransaction/constants';
import Model from '@glu/core/src/model';
import FilterParent from 'app/balanceAndTransaction/views/filterParent';
import transactionSearchFilterTmpl from './transactionSearchFilter.hbs';

const TransactionSearchFilter = FilterParent.extend({
    template: transactionSearchFilterTmpl,
    className: 'balance-and-transaction-filter',

    templateHelpers() {
        return {
            id: this.cid,
        };
    },

    ui: {
        $datepicker: '.datepicker',
        $acctTypeBtns: '.acctTypeBtns',
        $depositBtn: '.acctTypeBtns input[value="DEPOSIT"]',
        $loanBtn: '.acctTypeBtns input[value="LOAN"]',
        $collapse: '.collapse',
        $addCriteriaButton: '.btn[data-action="add-criteria"]',
        $accountSelect: '.search-input-wrapper input',
        $editButton: '[data-action="edit"]',
        $updateButton: '[data-action="update-filter"]',
        $resetButton: '[data-action="reset"]',
        $cancelButton: '[data-action="cancel"]',
        $saveForm: '.saveForm',
        $transactionsFormatSelect: 'select[name="accountTransactionsView"]',
        $accountSelectBy: 'select[name="accountSelectBy"]',
        $accountTypeSummary: '[data-hook="accountTypeFormatted"]',
        $accountSelectionSummary: '[data-hook="accountSelectionSummary"]',
        $accountTypeRadioButtons: 'input[name="account-type"]',
    },

    events: {
        'apply.daterangepicker @ui.$datepicker': 'updateModelDates',
        'click @ui.$addCriteriaButton': 'addCriteria',
        'click @ui.$editButton': 'editLink',
        'click @ui.$updateButton': 'updateFilters',
        'click @ui.$resetButton': 'resetFilters',
        'click @ui.$cancelButton': 'cancelFilters',
        'change @ui.$accountTypeRadioButtons': 'setAccountType',
    },

    modelEvents: {
        'change:accountList': 'updateAccountSelect',
        'change:accountSelectBy change:accountSelect': 'updateAccountSummary',
        'change:accountSelectBy change:accountTransactionsView': 'updateComboBoxes',
        'change:accountSelect': 'onAccountSelectChange',
    },

    regions: {
        alertRegion: '.alert-region',
        accountAlertRegion: '[data-region="accountAlertRegion"]',
        reportRegion: '[data-region="reportRegion"]',
    },

    initialize() {
        this.onRefreshAccountAccess();
        this.storedModelForCancel = new Model();
    },

    onRender() {
        this.bindUIElements();
    },

    onRefreshAccountAccess() {
        const accessUrl = services.generateUrl(`${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getAccountAccess`);
        const success = data => this.ifNotClosed(this.onSuccess)(data);
        const error = util.bind(this.onError, this);
        http.post(accessUrl, undefined, success, error);
    },

    onSuccess(data) {
        const loanAccountsCount = +data.LoanAccounts;
        const depositAccountsCount = +data.DepositAccounts;

        this.model.set('accountType', (depositAccountsCount === 0 && loanAccountsCount > 0) ? 'LOAN' : 'DEPOSIT');

        if (depositAccountsCount === 0) {
            this.ui.$depositBtn.closest('.btn').hide();
        }
        if (loanAccountsCount === 0) {
            this.ui.$loanBtn.closest('.btn').hide();
        }
    },

    onError() {
        this.depositAccountGroups = 0;
        this.loanAccountGroups = 0;
    },

    updateAccountSummary() {
        const selectBy = this.model.get('accountSelectBy');
        const unit = (selectBy === 'number') ? 'Account' : 'Group';
        const selected = this.model.get('accountSelect');
        const labelText = FilterParent.prototype.updateAccountSummary.call(this, unit, selected);
        this.ui.$accountSelectionSummary.text(labelText);
        this.ui.$accountTypeSummary.text(this.model.get('accountType') === 'LOAN' ? locale.get('gir.LoanAccounts') : locale.get('gir.DepositAccounts'));
    },

    updateAccountSelect() {
        const list = this.model.get('accountList')
            .map((l) => {
                const item = l;
                item.text = item.text.replace(/&amp;/g, '&');
                return item;
            });
        /*
         * default value of input to savedView's filtered accounts if applicable,
         * if not use 'all'
         */
        let allText;
        switch (this.model.get('accountSelectBy')) {
        case 'group':
            allText = locale.get('listviews.allGroups');
            break;
        case 'number':
            allText = locale.get('listviews.allaccounts');
            break;
        case 'bankCode':
            allText = locale.get('listviews.allBanks');
            break;
        default:
            allText = locale.get('listviews.allaccounts');
        }
        const defaultVal = (this.model.changed.accountSelect) ? this.model.get('accountSelect') : { id: 'all', text: allText };
        this.ui.$accountSelect.comboBox({
            multiple: true,
            data() {
                return list;
            },
            initSelection: (element, callback) => {
                const id = element.val();
                const item = util.findWhere(list, { id }) || {};
                callback({
                    id,
                    text: item.text,
                });
            },
            query: util.debounce((query) => {
                let results;
                if (query.term.length === 0) {
                    results = list;
                } else {
                    results = list
                        .filter(l => [l.id.toLowerCase(),
                            l.text.toLowerCase()]
                            .find(option => option.includes(query.term.toLowerCase())));
                }
                query.callback({
                    results,
                    more: false,
                });
            }, 300),
        });
        const setValue = (defaultVal.id === 'all') ? 'data' : 'val';
        this.ui.$accountSelect.comboBox(setValue, defaultVal, true);
    },

    /**
     * @method onAccountSelectChange
     * Callback to check account select typeahead values on change
     */
    onAccountSelectChange() {
        /**
         * will check for cases with selecting/deselecting "all" option
         * if selecting "all" option, should clear other selections
         * if selecting another option while "all" is selected, should remove "all"
         */
        let values = this.ui.$accountSelect.comboBox('data');

        if (values.length === 1) {
            return;
        }

        const allSelectIndex = values
            .map(item => item.id)
            .indexOf('all');

        if (allSelectIndex === -1) {
            return;
        }
        if (allSelectIndex === values.length - 1) {
            values = [values[allSelectIndex]];
        } else {
            values = values.filter(item => item.id !== 'all');
        }

        this.ui.$accountSelect.comboBox('data', values);
    },

    updateComboBoxes() {
        this.ui.$transactionsFormatSelect.comboBox('val', this.model.get('accountTransactionsView'));
        this.ui.$accountSelectBy.comboBox('val', this.model.get('accountSelectBy'));
    },

    closePanelAndSetSelect() {
        const localModel = this.model;
        const panel = this.ui.$collapse.get(0);
        const savedCriteriaCombo = this.$('[data-region="savedListViews"] [data-hook="selectList"]');

        if (localModel.get('viewId') !== '0' && !localModel.get('selectionChange') && !localModel.get('saved') && panel.className.indexOf('collapse in') > -1) {
            localModel.set('viewId', '0');
            savedCriteriaCombo.select2('val', null);
            this.$('.btn.makeDefault').hide();
            this.$('.btn.clearDLButton').hide();
        }
    },

    clearFilterModel() {
        const filterModel = this.filterRegion.currentView.model;
        filterModel.clear();
        this.clearCombos();
        filterModel.set('AMOUNT-equality', 'EQ');
    },

    postSubmitOperations() {
        this.cancelTranFilters('storeFilterModel');
        FilterParent.prototype.postSubmitOperations.call(this, this.model);
    },

    updateFilters() {
        FilterParent.prototype.updateFilters.call(this);

        if (this.checkDateRange()) {
            this.closePanelAndSetSelect();
            this.ui.$collapse.collapse('hide');

            this.model.trigger('update');
            FilterParent.prototype.postSubmitOperations.call(this, this.model);
        }
    },

    resetFilters() {
        this.filterRegion.currentView.model.clear();
        this.clearTranFilters();
        /**
         * NH-167989
         * clearing combobox fields like status, debit/credit
         * when user clicks the clear button in Transaction Filters
         */
        this.clearCombos();
    },

    cancelFilters() {
        this.cancelTranFilters('clear');
        this.ui.$collapse.collapse('hide');
    },

    editLink() {
        this.ui.$collapse.collapse('show');
        this.updateCollapseState();
    },

    updateCollapseState(state) {
        this.$('.TransactionFilters .panel-collapse').collapse(state || 'toggle');
    },

    rejectSearchField(filter) {
        return (!filter.fieldValue[0] || ((filter.fieldName === 'DR_CR' || filter.fieldName === 'POSTED_FLAG') && filter.fieldValue[0].trim() === ''));
    },

    renderTransactionFilters() {
        const context = {
            productCode: 'GIR',
            functionCode: 'INST',
            typeCode: 'GIRTRANS',
        };
        this.filterContextDef = util.extend({}, context);
        this.filterContextDef.serviceName = 'advanceFilter';

        const requestData = {
            filterId: 22411,
            typeInfo: context,
        };

        filterApi.getFilters(requestData, this).then(() => {
            this.listenToOnce(this.filterRegion.currentView, 'item:rendered', () => {
                this.openTransactionFiltersPanel();
                if (this.options.hidePostedFlag) {
                    this.hidePostedFlag();
                }
            });
        });
    },

    getTransactionFilters() {
        return filterApi.gatherFilterData(this.filterRegion.currentView.model) || [];
    },

    getTransactionProfileFilters() {
        return filterApi.gatherFilters(this) || [];
    },

    getCacheName() {
        return `model-${this.filterContextDef.serviceName}-GIR-INST`;
    },

    updateViewDates() {
        const dp = this.ui.$datepicker.data('daterangepicker');
        dp.setStartDate(this.model.get('START_DATE'));
        dp.setEndDate(this.model.get('END_DATE'));
    },

    updateModelDates() {
        dateUtil.updateModelDates(this.model, this.ui.$datepicker, true);
    },

    setNameFields(name) {
        this.model.set({
            VIEWNAME: name,
            id: name,
            originalName: name,
            listName: name,
            text: name,
            system: false,
        });
    },

    clearTranFilters() {
        this.ui.$datepicker.val('');
        this.updateModelDates();
        this.updateModelDates();
        if (!this.ui.$accountTypeRadioButtons.filter('[value="DEPOSIT"]').parent().hasClass('active')) {
            this.ui.$accountTypeRadioButtons.eq(1).parent().removeClass('active');
            this.ui.$accountTypeRadioButtons[0].checked = false;
            this.ui.$accountTypeRadioButtons.eq(0).parent().addClass('active');
            this.ui.$accountTypeRadioButtons[0].checked = true;
        }
        this.model.set({
            CUST_REF: '',
            AMOUNT: '',
            BANK_REF: '',
            POSTED_FLAG: '',
            DR_CR: '',
            BAI_CODE: '',
            BAI_GROUP_CODE: '',
            BANK_CODE: '',
            SWIFT_TYPE_CODE: '',
            CURRENCY_CODE: '',
            accountSelect: ['all'],
            accountSelectBy: 'number',
            accountType: 'DEPOSIT',
            update: false,
        });
    },

    cancelTranFilters(param) {
        const filterModel = this.filterRegion.currentView.model;
        const selector = '.type-ahead';
        let filterKeys = [];
        let filterUpdateSet = [];

        if (param === 'clear') {
            filterKeys = ['START_DATE', 'END_DATE', 'CUST_REF', 'AMOUNT', 'BANK_REF', 'POSTED_FLAG', 'DR_CR', 'BAI_CODE', 'BAI_GROUP_CODE', 'BANK_CODE', 'SWIFT_TYPE_CODE', 'CURRENCY_CODE'];
            filterUpdateSet = this.extractKeysWithDefaults(this.storedModelForCancel, '', filterKeys);

            filterModel.set(filterUpdateSet);

            const attributes = {
                accountSelect: this.storedModelForCancel.get('accountSelect') || ['all'],
                accountSelectBy: this.storedModelForCancel.get('accountSelectBy') || 'number',
                datepicker: this.storedModelForCancel.get('datepicker') || ' ',
            };

            this.filterRegion.$el.find(selector).each(function () {
                const attrName = $(this).attr('name');
                // check if set value is unique & add it as an array
                if (attrName) {
                    const currentVal = util.isArray(filterModel.get(attrName))
                        ? filterModel.get(attrName).join() : filterModel.get(attrName);

                    attributes[attrName] = currentVal;
                }
            });

            this.model.set(attributes);

            if (this.storedModelForCancel.get('AMOUNTequality')) {
                filterModel.set('AMOUNT-equality', this.storedModelForCancel.get('AMOUNTequality'));
            } else {
                filterModel.set('AMOUNT-equality', 'EQ');
            }
            this.ui.$datepicker.val(this.storedModelForCancel.get('datepicker') || ' ');
            this.ui.$transactionsFormatSelect.comboBox('val', this.storedModelForCancel.get('accountTranView'));
            this.updateModelDates();
            this.setTypeAheadText(this.filterRegion);
        } else {
            const filterOthers = {
                accountSelect: util.toArray(this.ui.$accountSelect.val()) || ['all'],
                accountSelectBy: this.ui.$accountSelectBy.val() || 'number',
                datepicker: this.ui.$datepicker.val() || ' ',
                accountTranView: this.ui.$transactionsFormatSelect.val(),
            };
            filterKeys = ['START_DATE', 'END_DATE', 'CUST_REF', 'AMOUNT', 'AMOUNTequality', 'BANK_REF', 'POSTED_FLAG', 'DR_CR', 'BAI_CODE', 'BAI_GROUP_CODE', 'BANK_CODE', 'SWIFT_TYPE_CODE', 'CURRENCY_CODE'];
            filterUpdateSet = util.extend({}, filterOthers, this.extractKeysWithDefaults(filterModel, '', filterKeys));

            this.storedModelForCancel.set(filterUpdateSet);
        }
    },

    extractKeysWithDefaults(fromModel, defaultValue, keyList) {
        return util.extend(
            {},
            util.reduce(keyList, (acc, val) => {
                acc[val] = defaultValue;
                return acc;
            }, {}),
            fromModel.pick(keyList),
        );
    },

    clearCombos() {
        const self = this;
        this.filterRegion.currentView.$el.find('.form-container .form-select').each((i, el) => {
            self.$(el).select2('val', null);
        });
    },

    viewSelectionHandler(model) {
        const self = this;
        if (this.alertRegion) {
            this.alertRegion.close();
        }

        this.filterRegion.currentView.model.clear();
        this.clearTranFilters();
        if (model) {
            const viewId = model.get('viewId');
            this.clearCombos();
            this.setNameFields(model.id);
            this.model.set('SHARED', model.get('shared'));
            this.wrapper.viewId = viewId;
            this.model.set('viewId', viewId);
            this.model.fetch({
                success() {
                    self.updateViewDates();
                    self.ui.$accountSelect.comboBox('val', self.model.get('Accounts'));
                    self.updateAccountSummary();
                    self.setTypeAheadText(self.filterRegion);
                    self.updateFilters();
                },
            });
        }
    },

    onShow() {
        this.ui.$datepicker.nhDatePicker({
            rangeDatePicker: true,
            allowWeekends: true,
        });

        this.$('select').comboBox({
            minimumResultsForSearch: Infinity,
        });

        this.renderTransactionFilters();
    },

    setAccountType() {
        this.accountType = this.ui.$accountTypeRadioButtons.filter(':checked').val();
        this.model.set('accountType', this.accountType);
    },

    getTypeAheadData(view) {
        const self = this;
        const reportType = self.model.get('accountSelectBy');
        const reportTypeField = reportType === 'number' ? 'ACCOUNTFILTER' : 'ACCOUNTFILTER2';

        FilterParent.prototype.getTypeAheadData.call(this, view, reportTypeField);
    },

    setTypeAheadText(view) {
        const self = this;
        const selector = '.type-ahead';
        view.$el.find(selector).each(function (i, el) {
            const $el = self.$(el);
            const attrName = $(this).attr('name');
            let typeAheadFields = [];
            if (attrName) {
                typeAheadFields = FilterParent.prototype.getTypeAheadFields.call(self, attrName, `${attrName}_DATA`);
                $el.comboBox('data', typeAheadFields);
            }
        });
    },

    /**
     * - open the transaction filters panel
     * @method openTransactionFiltersPanel
     */
    openTransactionFiltersPanel() {
        this.filterRegion.getEl('#TransactionFilters').collapse('show');
    },

    /**
     * Hides the status field and label.
     */
    hidePostedFlag() {
        this.$('#POSTED_FLAG').parent().hide();
    },
});

export default TransactionSearchFilter;
