var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h4 class=\"account-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"reportid") || (depth0 != null ? lookupProperty(depth0,"reportid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reportid","hash":{},"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":41}}}) : helper)))
    + "</h4>\n    <small class=\"account-number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"reportdescription") || (depth0 != null ? lookupProperty(depth0,"reportdescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reportdescription","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":55}}}) : helper)))
    + "</small>\n    <small class=\"bank-code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"reportfiletype") || (depth0 != null ? lookupProperty(depth0,"reportfiletype") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reportfiletype","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":47}}}) : helper)))
    + "</small>\n    <small class=\"currency-code\"></small>       \n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h4 class=\"account-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountname") || (depth0 != null ? lookupProperty(depth0,"accountname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountname","hash":{},"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":44}}}) : helper)))
    + "</h4>\n    <small class=\"account-number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountnum") || (depth0 != null ? lookupProperty(depth0,"accountnum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountnum","hash":{},"data":data,"loc":{"start":{"line":10,"column":34},"end":{"line":10,"column":48}}}) : helper)))
    + "</small>\n    <small class=\"bank-code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"bankcode") || (depth0 != null ? lookupProperty(depth0,"bankcode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bankcode","hash":{},"data":data,"loc":{"start":{"line":11,"column":29},"end":{"line":11,"column":41}}}) : helper)))
    + "</small>\n    <small class=\"currency-code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencycode") || (depth0 != null ? lookupProperty(depth0,"currencycode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencycode","hash":{},"data":data,"loc":{"start":{"line":12,"column":33},"end":{"line":12,"column":49}}}) : helper)))
    + "</small>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h4 class=\"account-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"estatementaccount") || (depth0 != null ? lookupProperty(depth0,"estatementaccount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"estatementaccount","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":50}}}) : helper)))
    + "</h4>\n    <small class=\"account-number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountnum") || (depth0 != null ? lookupProperty(depth0,"accountnum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountnum","hash":{},"data":data,"loc":{"start":{"line":17,"column":34},"end":{"line":17,"column":48}}}) : helper)))
    + "</small>\n    <small class=\"bank-code\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"bankcode") || (depth0 != null ? lookupProperty(depth0,"bankcode") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bankcode","hash":{},"data":data,"loc":{"start":{"line":18,"column":29},"end":{"line":18,"column":41}}}) : helper)))
    + "</small>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h4 class=\"account-name\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":22,"column":29},"end":{"line":22,"column":38}}}) : helper)))
    + "</h4>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLegacyReport") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAccount") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEstatement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":19,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEverythingElse") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":0},"end":{"line":23,"column":7}}})) != null ? stack1 : "");
},"useData":true});