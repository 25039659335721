import ItemView from '@glu/core/src/itemView';
import exportButtonTmpl from './exportButton.hbs';

export default ItemView.extend({
    template: exportButtonTmpl,
    triggers: {
        'keydown button': 'export',
        'mousedown button': 'export',
    },
    ui: {
        anchor: 'button a',
    },
    setUri(uri) {
        this.ui.anchor.attr('href', uri);
    },
});
