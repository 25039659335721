import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@glu/drawer-react';
import { CaretRightNextIcon } from '@glu/icons-react';
import { withStyles } from '@glu/theming';

import styles from './MobileDrawer.styles';

const propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    isOpen: false,
    title: 'Drawer Title',
};

const MobileDrawer = ({
    isOpen, title, classes, onClose, children,
}) => (
    <Drawer open={isOpen} size="100%" anchorTo="right" className={classes.drawer}>
        <div className={classes.drawerContainer}>
            <div className={classes.drawerHeader}>
                <CaretRightNextIcon actionable onClick={onClose} />
                <h3>{title}</h3>
            </div>
            <div className={classes.drawerContent}>
                {children}
            </div>
        </div>
    </Drawer>
);

MobileDrawer.propTypes = propTypes;
MobileDrawer.defaultProps = defaultProps;

export default withStyles(styles)(MobileDrawer);
