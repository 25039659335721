import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'common/dynamicPages/api/constants';
import store from 'system/utilities/cache';
import gridApi from 'common/dynamicPages/api/grid';
import ListView from 'common/dynamicPages/views/workflow/list';
import entitlements from 'common/dynamicPages/api/entitlements';
import ContextApi from 'common/dynamicPages/api/context';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import alert from '@glu/alerts';
import AlertDetails from 'app/smbPayments/alerts/transferAlertDetails';
import workspaceHelper from 'common/workspaces/api/helper';
import loans from 'app/loans/api/common';
import TransferLayout from 'app/smbPayments/views/accounts/balances/transfer/transferLayout';
import LoanPaymentView from 'app/smbPayments/views/accounts/balances/transfer/loanPaymentLayout';
import LoanDrawView from 'app/smbPayments/views/accounts/balances/transfer/loanDrawLayout';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import smbConstants from 'app/smbPayments/constants';
import PaymentTypeCollection from 'app/smbPayments/collections/paymentTypes';
import dialog from '@glu/dialog';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import template from './transfersGrid.hbs';

function filterDate(field, value, model) {
    const d = moment(model.get(field));
    const valObj = moment(value);
    return d.isAfter(valObj);
}

const TransferManagementList = ListView.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="transfer-button"]': 'addTransfer',
        'click [data-hook="loandraw-button"]': 'addLoanDraw',
        'click [data-hook="loanpay-button"]': 'addLoanPay',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    appEvents: {
        'smbPayments:newTransfer:gridAlert': 'refreshTransferGrid',
    },

    refreshTransferGrid(model) {
        if (this.gridView) {
            this.gridView.refreshGridData();

            const transferDetails = new AlertDetails({
                model,
            });
            // display notification message
            this.alertView = alert.success(
                /*
                 * Join the message array before passing it as a parameter
                 * as the success function joins with an undesirable comma
                 * in this situation
                 */
                model.get('message').join(''),
                {
                    details: transferDetails,
                },
            );
            this.alertRegion.show(this.alertView);
        }
    },

    addTransfer() {
        return new Promise((resolve) => {
            dialog.open(new TransferLayout({
                onClose: resolve,
            }));
        });
    },

    addLoanDraw() {
        return new Promise((resolve) => {
            dialog.open(new LoanDrawView({
                preferences: this.preferences,
                onClose: resolve,
            }));
        });
    },

    addLoanPay() {
        return new Promise((resolve) => {
            dialog.open(new LoanPaymentView({
                preferences: this.preferences,
                onClose: resolve,
            }));
        });
    },

    initialize(options) {
        this.context = this.contextInfo();
        this.contextDef = ContextApi.menuContext.getContext(this.context);
        this.contextDef.serviceName = 'payment/listView/smbTransfer';

        const superOptions = {
            menuCategory: 'ACCTS',
            serviceName: 'payments/smbTransfer',
            serviceFunc: null,
            businessType: null,
            context: 'SMB_TRANSFER',
            contextDef: this.contextDef,
            returnRoute: 'PAYMENTS/listSmbTransfers',
        };
        superOptions.enableSavedViews = true;
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.gridRegion.show(this.gridView);
            this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
            this.listenTo(this.gridView.getRows(), 'sync', this.updateRefreshTimestamp);
            this.renderMessage(store.get(`${this.contextKey}-alertMessage`), store.get(`${this.contextKey}-confirms`));
            store.set(`${this.contextKey}-alertMessage`, null);
            store.set(`${this.contextKey}-confirms`, null);
            this.updateRefreshTimestamp();
        } else {
            this.loadViewRequirements();
        }
    },

    print() {
        this.buildExportModel('PDF');
        const printModal = new PrintViewModal({
            exportModel: this.exportModel,
            title: 'smbPayments.account.center',
        });
        dialog.custom(printModal);
    },

    contextInfo() {
        const context = {
            actionMode: 'SELECT',
            displayOrder: 1,
            filterId: 20105,
            functionCode: 'INST',
            inquiryId: 20105,
            gridId: 20105,
            menuCategory: 'payments',
            menuCode: 'SMB_TRANSFER',
            menuContext: 'payment/listView/smbTransfer',
            menuDescription: 'Employees',
            nonDashboardDisplay: 0,
            productCode: 'RTGS',
            requestMappings: 'listSmbTransfers',
            requestParams: '?!_init=true&_productCode=RTGS&_functionCode=INST&_typeCode=*&_mode=SELECT&inquiryID=20105&_gridId=20105&_filterID=20105',
            serviceName: 'payment/listView/smbTransfer',
            typeCode: '*',
        };
        return context;
    },

    subOptions(context, contextDef) {
        const self = this;
        const options = {
            context,
            contextDef,
            serviceName: 'payment/listView/smbTransfer',

            // default grid action buttons are hidden
            hideButtons: true,

            // instead of executing context actions, grid handles callback
            enableRowContextButtonCallbacks: true,

            enableSavedViews: true,

            customFormatters: [{
                fieldName: 'CMB_TRANSACTION_AMOUNT',

                format(value) {
                    return `${value}`;
                },
            }],

            lvc: this.lvc,

            loadedCallback() {
                self.gridLoaded = true;
            },
        };
        options.contextDef.serviceName = 'payment/listView/smbTransfer';
        return options;
    },

    addDateFilter(days, label, type) {
        const date = moment(new Date()).subtract(days, 'day');
        const dateStr = date.format(userInfo.getDateFormat());

        this.gridView.grid.filterProc.addFilter({
            value: dateStr,
            label,
            field: 'CMB_VALUE_DATE',
            type,
            filter: util.bind(filterDate, this, 'CMB_VALUE_DATE', dateStr),
        });
    },

    openLayout(options) {
        if (options.model.get('TYPE') === 'TRANSFER') {
            dialog.open(new TransferLayout(this.getExtendedOptions(options)));
        } else if (options.model.get('TYPE') === 'LOANPAY') {
            dialog.open(new LoanPaymentView(this.getExtendedOptions(options)));
        } else {
            dialog.open(new LoanDrawView(this.getExtendedOptions(options)));
        }
    },

    gridRowSelect(optionsParam) {
        return new Promise((resolve) => {
            const options = {
                ...optionsParam,
                // we need a reference to the grid so we can trigger certain actions
                gridView: this.gridView,
                onClose: resolve,
            };
            this.openLayout(options);
        });
    },

    gridRowModify(optionsParam) {
        return new Promise((resolve) => {
            const options = {
                ...optionsParam,
                // we need a reference to the grid so we can trigger certain actions
                gridView: this.gridView,
                onClose: resolve,
            };
            this.openLayout(options);
        });
    },

    // Copy as Instruction action
    gridPaymentFromTemplate(options) {
        return new Promise((resolve) => {
            this.openLayout({
                ...options,
                onClose: resolve,
            });
        });
    },

    getExtendedOptions(options) {
        return util.extend(
            {},
            options,
            {
                preferences: this.preferences,
            },
        );
    },

    templateHelpers() {
        const self = this;

        return {
            getString(type) {
                return locale.get(self.localeKey + type);
            },

            getButtonString(type) {
                return locale.get(`${self.localeKey.split('.')[0]}.button_${type}`);
            },

            context() {
                return self.contextKey;
            },

            hasInsertEntitlement() {
                return self.hasEntitlement(constants.ACTION_INSERT);
            },

            hasApproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_APPROVE);
            },

            hasUnapproveEntitlement() {
                return self.hasEntitlement(constants.ACTION_UNAPPROVE);
            },

            hasRejectEntitlement() {
                return self.hasEntitlement(constants.ACTION_REJECT);
            },

            hasDeleteEntitlement() {
                return self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasBulkActionEntitlements() {
                // return true if the user is entitled to perform bulk actions on grid rows
                return self.hasEntitlement(constants.ACTION_APPROVE)
                    || self.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || self.hasEntitlement(constants.ACTION_REJECT)
                    || self.hasEntitlement(constants.ACTION_DELETE);
            },

            hasTransferEntitlements() {
                return self.hasTransferEntitlements();
            },

            hasLoanDrawEntitlements() {
                return self.hasLoanDrawEntitlements();
            },

            hasLoanPayEntitlements() {
                return self.hasLoanPayEntitlements();
            },
        };
    },

    loadViewRequirements() {
        const options = this.subOptions(this.context, this.contextDef);
        const entitlementPromise = entitlements.getEntitlements(options);
        const paymentTypePromise = this.paymentTypePromise();
        this.gridView = gridApi.createServiceGridView(options);

        // for file import
        const optionsFimport = util.clone(options);
        optionsFimport.entryMethod = 3;
        const entitlementFimportPromise = entitlements.getEntitlements(optionsFimport);
        const loanPreferencesPromise = loans.getPaymentPreferences();

        ListView.prototype.setupGridAvailableListener.call(this);

        // merge both promises (regular payments and the other one for file import)
        return Promise.all([
            entitlementPromise,
            entitlementFimportPromise,
            loanPreferencesPromise,
            paymentTypePromise,
        ]).then((results) => {
            const entResActions = results[0].actions;
            if (results[1].actions && results[1].actions.INSERT) {
                // IMPORT is a convenience action for the UI side
                entResActions.IMPORT = true;
            }
            [, , this.preferences, this.paymentTypes] = results;
            this.entitlements = entResActions;
            if (!mobileUtil.isMobileGridEnabled()) {
                this.listenForGridErrors();
                this.setHasLoadedRequiredData(true);
                this.render();
            }
            return this.entitlements;
        });
    },

    paymentTypePromise() {
        return new Promise((resolve, reject) => {
            const paymentTypes = new PaymentTypeCollection({
                functionCode: 'INST',
                inquiryId: 20011,
            }, {
                base: 'payments',
            });
            paymentTypes.fetch({
                success: resolve,
                error: reject,
            });
        });
    },

    hasPaymentEntitlement(entitlement) {
        return !!util.find(
            this.paymentTypes.models,
            model => model.get('typeCode') === entitlement,
        );
    },

    hasTransferEntitlements() {
        return this.hasPaymentEntitlement(smbConstants.TRANSFER);
    },

    hasLoanDrawEntitlements() {
        return this.hasPaymentEntitlement(smbConstants.LOANDRAW);
    },

    hasLoanPayEntitlements() {
        return this.hasPaymentEntitlement(smbConstants.LOANPAY);
    },

    buildExportModel(format) {
        /*
         * Transfers Grid  is an Inquiry based list view, so we need to provide this
         * info to export
         */
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.inquiryId = this.context.inquiryId;
    },
});

let list = TransferManagementList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'smbPayments.add.transfer',
                handleEntitlementMethodName: 'hasTransferEntitlements',
                handlerMethodName: 'addTransfer',
            },
            {
                label: 'smbPayments.add.loandraw',
                handleEntitlementMethodName: 'hasLoanDrawEntitlements',
                handlerMethodName: 'addLoanDraw',
            },
            {
                label: 'smbPayments.add.loanpay',
                handleEntitlementMethodName: 'hasLoanPayEntitlements',
                handlerMethodName: 'addLoanPay',
            },
        ],
        bulkActions: [
            {
                label: 'sbPayments.approve',
                entitlement: constants.ACTION_APPROVE,
            },
            {
                label: 'sbPayments.unApprove',
                entitlement: constants.ACTION_UNAPPROVE,
            },
            {
                label: 'button.reject',
                entitlement: constants.ACTION_REJECT,
            },
            {
                label: 'sbPayments.delete',
                entitlement: constants.ACTION_DELETE,
            },
        ],
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'SMBTRANSFERLIST',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
