var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"alert-region\" data-region=\"alertRegion\">\n</div>\n<div class=\"panel\">\n        <div class=\"panel-heading\">\n                <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.selectsavedreportcriteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":65}}}))
    + "</h2>\n                <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.savedreports",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":52}}}))
    + "</h3>\n                <div class=\"form-row\">\n                        <div class=\"form-group\">\n                                <div class=\"savedListViews\" data-region=\"savedListViews\" aria-live=\"polite\"></div>\n                        </div>\n                </div>\n        </div>\n\n        <div aria-expanded=\"true\" class=\"panel-heading\" role=\"tab\" data-region=\"reportRegion\" id=\"panel-heading-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":112},"end":{"line":15,"column":118}}}) : helper)))
    + "\" data-toggle=\"collapse\" data-target=\"#panel-body-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":15,"column":168},"end":{"line":15,"column":174}}}) : helper)))
    + "\">\n                <span class=\"caret-indicator\" role=\"presentation\"></span>\n                <div class=\"filter-summary\">\n                        <div class=\"row\">\n                                <h3 class=\"panel-title form-group\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.reportsettings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":67},"end":{"line":19,"column":101}}}))
    + "</h3>\n                                <button type=\"button\" class=\"btn btn-tertiary form-group editButton edit-button-position\" data-action=\"edit\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.edit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":141},"end":{"line":20,"column":165}}}))
    + "</button>\n                        </div>\n                        <div class=\"row\">\n                                <div class=\"form-group required col-4\">\n                                        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":44},"end":{"line":24,"column":72}}}))
    + "</h3>\n                                        <div>\n                                                <span data-hook=\"accountSelectionSummary\"></span>\n                                        </div>\n                                </div>\n                                <div class=\"form-group  required col-4\">\n                                        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.dateRange",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":44},"end":{"line":30,"column":83}}}))
    + "</h3>\n                                        <div>\n                                                <span data-bind=\"model\" data-text=\"START_DATE\"></span> - <span data-bind=\"model\" data-text=\"END_DATE\"></span>\n                                        </div>\n                                </div>\n                                <div class=\"form-group  required col-4\">\n                                        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.accountTransactions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":44},"end":{"line":36,"column":93}}}))
    + "</h3>\n                                        <div>\n                                                <span data-hook=\"accountTransactionsViewFormatted\"></span>\n                                        </div>\n                                </div>\n                        </div>\n                </div>\n        </div>\n\n        <div aria-expanded=\"true\" id=\"panel-body-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":45,"column":49},"end":{"line":45,"column":55}}}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\" aria-labelledby=\"panel-heading-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":45,"column":139},"end":{"line":45,"column":145}}}) : helper)))
    + "\">\n                <div class=\"or-block\">\n                        <span class=\"or-block-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.or",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":52},"end":{"line":47,"column":74}}}))
    + "</span>\n                          <hr class=\"or-block-rule\" />\n                </div>\n                <div class=\"form-inline\">\n                        <div class=\"form-group\"><h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.newreportsettings",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":52},"end":{"line":51,"column":89}}}))
    + "</h3></div>\n                                 <div class=\"form-row\">\n                                        <div class=\"form-group\">\n                                                <label for=\"accountSelectBy\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.selectAccountsBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":77},"end":{"line":54,"column":123}}}))
    + "</label>\n                                                <div>\n                                                        <select class=\"form-control row-label\" name=\"accountSelectBy\" id=\"accountSelectBy\" data-bind=\"model\">\n                                                                <option value=\"number\" selected=\"selected\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":107},"end":{"line":57,"column":145}}}))
    + "</option>\n                                                                <option value=\"group\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.accountGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":86},"end":{"line":58,"column":115}}}))
    + "</option>\n                                                                <option value=\"bankCode\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.bank_code",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":89},"end":{"line":59,"column":115}}}))
    + "</option>\n                                                                <option value=\"currency\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.currency",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":60,"column":89},"end":{"line":60,"column":114}}}))
    + "</option>\n                                                        </select>\n                                                </div>\n                                        </div>\n\n                                        <div class=\"form-group\">\n                                                <label for=\"accountSelect\"></label>\n                                                <div>\n                                                        <input\n                                                                type=\"hidden\"\n                                                                class=\"form-control type-ahead\"\n                                                                name=\"accountSelect\"\n                                                                id=\"accountSelect\"\n                                                                data-bind=\"model\">\n                                                        </input>\n                                                </div>\n                                        </div>\n                                </div>\n\n                        <div class=\"form-row\">\n                                <div class=\"form-group required\">\n                                        <label for=\"dateRange\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":63},"end":{"line":81,"column":84}}}))
    + "</label>\n                                        <div>\n                                                <input type=\"text\" class=\"datepicker form-control\" name=\"dateRange\" id=\"dateRange\">\n                                        </div>\n                                </div>\n                                <div class=\"form-group\">\n                                        <label for=\"accountTransactionsView\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.accountTransactionsView",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":87,"column":77},"end":{"line":87,"column":130}}}))
    + "</label>\n                                        <div>\n                                                <select class=\"form-control\" name=\"accountTransactionsView\" id=\"accountTransactionsView\" data-bind=\"model\">\n                                                        <option value=\"single\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.crDrSingleView",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":79},"end":{"line":90,"column":123}}}))
    + "</option>\n                                                        <option value=\"separate\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.combinedView.separateCreditDebitViews",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":91,"column":81},"end":{"line":91,"column":135}}}))
    + "</option>\n                                                </select>\n                                        </div>\n                                </div>\n                        </div>\n\n           <div data-region=\"filterRegion\"/>\n           <div class=\"form-row\">\n                               <div class=\"checkbox\">\n                                   <input type=\"checkbox\" name=\"SAVEREPORT\" id=\"SAVEREPORT\">\n                                   <label for=\"SAVEREPORT\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.savethisreport",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":101,"column":59},"end":{"line":101,"column":93}}}))
    + "</label>\n                           </div>\n                    <div class=\"form-inline saveForm\" style=\"display:none\">\n                            <div class=\"required field-container VIEWNAME_"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":104,"column":74},"end":{"line":104,"column":80}}}) : helper)))
    + "\">\n                                                <label for=\"VIEWNAME_"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":105,"column":69},"end":{"line":105,"column":75}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"allReports.Report_Name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":77},"end":{"line":105,"column":112}}}))
    + "</label>\n                                                <input class=\"form-control\" type=\"text\" maxlength=\"20\" data-bind=\"model\" data-validate=\"VIEWNAME\" id=\"VIEWNAME_"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":106,"column":159},"end":{"line":106,"column":165}}}) : helper)))
    + "\" name=\"VIEWNAME\">\n                                        </div>\n                                        <label class=\"checkbox\">\n                                <input type=\"checkbox\" data-bind=\"model\" name=\"SHARED\" id=\"SHARED_"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":109,"column":98},"end":{"line":109,"column":104}}}) : helper)))
    + "\" data-validate=\"SHARED\">\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"filter.shared",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":110,"column":32},"end":{"line":110,"column":58}}}))
    + "\n                            </label>\n                    </div>\n                        </div>\n\n                        <div class=\"actions\">\n                                <button class=\"btn btn-primary\" data-action=\"update-filter\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":116,"column":92},"end":{"line":116,"column":118}}}))
    + "</button>\n                                <button class=\"btn btn-primary\" data-action=\"saveView\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":117,"column":87},"end":{"line":117,"column":113}}}))
    + "</button>\n                                <button class=\"btn btn-secondary\" data-action=\"reset\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":118,"column":86},"end":{"line":118,"column":111}}}))
    + "</button>\n                                <button class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":119,"column":86},"end":{"line":119,"column":112}}}))
    + "</button>\n                        </div>\n                </div>\n        </div>\n</div>\n";
},"useData":true});