import { registerDefaultsForModule } from '@glu/theming';

registerDefaultsForModule(
  'popover', ({ name, palette }) => {
    const commonTheme = {
      content: {
        lineHeight: '26px',
        backgroundColor: {
          defaultBackgroundColor: palette.background
        },
        color: {
          positiveColor: palette.text.light,
          negativeColor: palette.text.dark
        }
      }
    };

    const themes = {
      ash: {
        ...commonTheme,
        fontSize: 16,
        colorLabel: palette.text.midGray,
        border: 'none',
        borderRadius: 3,
        boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, 0.2)',
        title: {
          textTransform: 'uppercase'
        }
      },
      legacy: {
        ...commonTheme,
        fontSize: 14,
        colorLabel: palette.text.dark,
        border: `1px solid ${palette.accent}`,
        borderRadius: 6,
        boxShadow: 'none',
        title: {
          margin: [-16, -16, 0, -16],
          backgroundColor: palette.grey.G100,
          padding: [8, 14],
          borderRadius: [6, 6, 0, 0]
        }
      }
    };
    return themes[name];
  }
);
