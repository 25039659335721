import { Layout, date, util, $ } from '@glu/core';
import DatePicker from '@glu/datepicker';

export default Layout.extend({

    initialize(options) {
        this.options = options || {};
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            dateFormat: this.uiFormat,
            isEnabled: this.isEnabled(),
            hasDate: util.isString(this.model.get(this.propertyName)),
            thisValue: this.formattedDateValue()
        };
    },

    formattedDateValue() {
        return date(this.model.get(this.propertyName), this.serverFormat).format(this.uiFormat);
    },

    isEnabled() {
        return util.isString(this.model.get(this.propertyName)) && !this.options.readOnly;
    },

    onRender() {
        this.renderDatePicker();
    },

    onClose() {
        if (!this.datepicker) {
            return;
        }

        this.datepicker.close();
    },

    renderDatePicker() {
        const existingDate = this.model.get(this.propertyName);

        if (!util.isString(existingDate)) {
            return;
        }

        const $input = this.$('input');
        const $wrapper = $('<div/>');

        if (this.options && this.options.readOnly !== true) {
            const shouldAutoUpdateInput = this.options.autoUpdateInput;
            this.datepicker = new DatePicker({
                el: $wrapper.get(0),
                $target: $input,
                model: this.model,
                serverFormat: this.serverFormat,
                propertyName: this.propertyName,
                config: {
                    autoUpdateInput: (typeof shouldAutoUpdateInput === 'boolean') ? shouldAutoUpdateInput : true,
                    locale: {
                        format: this.uiFormat
                    },
                    minDate: this.minDate,
                    startDate: existingDate ? date(existingDate, [this.uiFormat, this.serverFormat]) : date(),
                    singleDatePicker: true,
                    opens: 'right',
                    showDropdowns: true
                },
                // handle z-index properly for cases if scheduler was shown in a popup view
                parentEl: true
            });
        }

        $wrapper.insertAfter($input);
        if (this.datepicker) {
            this.datepicker.render();
        }

        // cosmetic one - remove date from input field if disabled
        if (!existingDate && !this.options.readOnly) {
            $input.val('');
        }
    },

    setDate() {
        let properDate = this.propertyName === 'ends' ? date().add(1, 'days') : date();
        this.model.set(this.propertyName, properDate.format(this.serverFormat));
    }
});
