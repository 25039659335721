export default ({ palette, typography }) => ({
  content: {
    lineHeight: '27px',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: {
      defaultBackgroundColor: palette.background,
      positiveBackgroundColor: palette.money.normal,
      negativeBackgroundColor: palette.grey.G100
    },
    height: {
      toggleHeight: 27,
      pointerHeight: 25
    },
    color: {
      positiveColor: palette.text.light,
      negativeColor: palette.text.dark
    }
  },
  switchWrap: {
    minWidth: 60,
    border: `1px solid ${palette.grey.A100}`,
    borderRadius: 0,
    boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.3)',
    backgroundColor: '#f7f7f7',
    fontFamily: typography.fontFamily
  },
  value: {
    textTransform: 'none',
    fontWeight: 'normal',
    paddingLeft: 5
  },
  positive: {
    paddingRight: 35,
    paddingLeft: 5
  },
  negative: {
    paddingRight: 5,
    paddingLeft: 35
  },
  pointer: {
    borderRadius: 0,
    width: 30,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    marginTop: -1,
    marginLeft: -1,
    backgroundColor: '#d6d6d6'
  },
  pointerShift: 'calc(100% - 29px)',
  focusColor: palette.focus.normal
});
