import util from '@glu/core/src/util';
import localStore from 'common/util/localStore/localStore';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';

const storeIt = (gridStateKey, configsContainer) => {
    const all = configsContainer;
    all.lastUpdate = Date.now();
    const saveConfig = Object.entries(all).reduce((acc, [key, value]) => {
        if (typeof value === 'string') {
            acc[key] = value;
        } else {
            acc[key] = JSON.stringify(value);
        }
        return acc;
    }, {});
    localStore.set(gridStateKey, saveConfig);
};

const throttleStoreIt = util.throttle(storeIt, 1000, { leading: false, trailing: true });

const simulatedUser = (userInfo.get('simulatedSession'));

export default {
    /**
     * store the gridState container, which holds the individual listView configurations.
     * when running as a simulated user, nothing is stored in localStore
     * if the immediate flag is true, the container is stored immediately in
     * localStore;
     * otherwise, the store is throttled.
     * @param {string} gridStateKey - key for localStore
     * @param {object} configsContainer - gridState/listViewConfig container
     * @param {boolean} immediate - flag to indicate if item is stored immediately
     * or delayed
     */
    set(gridStateKey, configsContainer, immediate) {
        if (simulatedUser) {
            return;
        }
        if (immediate) {
            storeIt(gridStateKey, configsContainer);
        } else {
            throttleStoreIt(gridStateKey, configsContainer);
        }
    },

    /**
     * retrieves the gridState/listView container from localStore using
     * the input key, if it is not stale.
     * when running as a simulated user, nothing is stored in localStore
     * if the defaultGridState is present, then this is returned for a simulated user or
     * when localStore is unavailable.
     * @param {string} gridStateKey - key for localStore
     * @param {object} defaultGridState - container returned in simulation mode or if
     * localStore is unavailable
     * @returns {object} gridState/listView container
     */
    get(gridStateKey, defaultGridState) {
        if (simulatedUser) {
            return defaultGridState;
        }
        const defaultContainer = defaultGridState || null;
        const container = localStore.get(gridStateKey);
        // we have a container from localStore, check if it is still valid
        if (!this.isStale(container)) {
            return container;
        }
        return defaultContainer;
    },

    /**
     * removes the gridState/listView container from localStore
     * when running as a simulated user, nothing is stored in localStore
     * @param {string} gridStateKey
     * @returns {boolean} indicator if remove was successful
     */
    remove(gridStateKey) {
        if (simulatedUser) {
            return true;
        }
        return localStore.remove(gridStateKey);
    },

    /**
     * retrieves the gridState/listView config from the container stored in
     * localStore.
     * @param {string} gridStateKey - container key
     * @param {string} key - listViewConfig key
     * @returns {object|null} listViewConfig object or null if no config is available
     */
    getConfig(gridStateKey, key) {
        if (simulatedUser) {
            return null;
        }
        let config = null;

        const configsContainer = localStore.get(gridStateKey);

        if (configsContainer) {
            config = configsContainer[key];
        }
        return (config) ? JSON.parse(config) : null;
    },

    /**
     * stores the listViewConfig in the container into localStore
     * when running as a simulated user, nothing is stored in localStore
     * @param {string} gridStateKey - container key
     * @param {string} key - listViewConfig (gridState) key
     * @param {object} lvc - listViewConfig
     * @param {boolean} immediate - flag to indicate if the store should be immediate or
     * delayed.
     */
    setConfig(gridStateKey, key, lvc, immediate) {
        if (simulatedUser) {
            return;
        }
        const configsContainer = localStore.get(gridStateKey);
        if (configsContainer) {
            configsContainer[key] = JSON.stringify(lvc);
            // store in localStore
            if (immediate) {
                storeIt(gridStateKey, configsContainer);
            } else {
                throttleStoreIt(gridStateKey, configsContainer);
            }
        }
    },

    /**
     * removes the listView configuration from localStore
     * when running as a simulated user, nothing is stored in localStore
     * @param {string} gridStateKey - container key
     * @param {string} key - listViewConfig key
     * @param {boolean} immediate - perform the localStore update immediately if true
     */
    removeConfig(gridStateKey, key, immediate) {
        if (simulatedUser) {
            return;
        }
        const configsContainer = localStore.get(gridStateKey);
        if (configsContainer) {
            delete configsContainer[key];
            // store in localStore
            if (immediate) {
                storeIt(gridStateKey, configsContainer);
            } else {
                throttleStoreIt(gridStateKey, configsContainer);
            }
        }
    },

    /**
     * determines if a container is stale/old based on its lastUpdate time,
     * the login time and the duration set up in configparams
     * @param {object} container - gridState container
     * @returns {boolean} true if the container is old; otherwise, false
     */
    isStale(container) {
        if (util.isNullOrUndefined(container)) {
            return true;
        }

        // If the last update is more than 'GridStateDuration' minutes ago, it's stale
        const duration = serverConfigParams.get('GridStateDuration');
        return (userInfo.get('loginTime') - container.lastUpdate > (duration * 60000));
    },

    /**
     * TODO - commented out for now b/c we only want to check the time on the container level
     *  at some point, we may need to check at the listViewConfig level
     * cleans up a gridState/listView container
     * when running as a simulated user, nothing is stored in localStore
     * Iterates through each listViewConfig in the container to check if it is
     * stale, i.e.,
     * the last update on that config is older than the duration set up in
     * configParams
     * @param {string} configContainerKey
     * @param {object} cofigContainer - gridState/listView container
     * @param {time} duration - configParam that specifies the number of minutes that
     * the configurations should be active
     * @param {time} loginTime - time the user logged in
     */
    // cleanUpConfig(containerKey, configContainer, duration, loginTime) {
    //     if (simulatedUser) {
    //         return;
    //     }
    //     let cleaned = false;
    //     const clean = util.clone(configContainer);
    //     Object.keys(configContainer)
    //         .forEach((key) => {
    //             if (key !== 'lastUpdate') {
    //                 const lvc = JSON.parse(configContainer[key]);
    //                 const ts = new Date(lvc.TIMESTAMP);
    //                 const endTime = ts.getTime() + (duration * 60000);
    //                 if (endTime < loginTime.getTime()) {
    //                     delete clean[key];
    //                     cleaned = true;
    //                 }
    //             }
    //         });
    //     if (cleaned) {
    //         storeIt(containerKey, clean);
    //     }
    // },

    /**
     * Cleans up the gridState/listView container
     * when running as a simulated user, nothing is stored in localStore
     * if the container is stale, it is removed.
     * Otherwise, the container's configs are check to see if they are stale.
     * @param {string} key - container key
     */
    cleanUpContainer(key) {
        if (simulatedUser) {
            return;
        }
        const container = localStore.get(key);
        if (this.isStale(container)) {
            localStore.remove(key);
        }
        /*
         * TODO - at some point we may need to clean up individual listViewConfigs
         * if the container is still active but we need more room
         */
    },

    /**
     * removes the expired containers/configs from localStore
     * when running as a simulated user, nothing is stored in localStore
     * the containers are stored in localStore with a key that is prefixed with
     * 'DGB-GRID'.
     * First, the containers are checked to see if they are stale and need to
     * be removed.  Then the individual listViewConfigs are checked.
     * @param {boolean} connectWithLocalStore - flag to indicate if the garbageCollection
     * method should be set in localStore (wrapper for browser localStore)
     */
    removeExpired(connectWithLocalStore) {
        if (simulatedUser) {
            return;
        }
        // get the grid state keys
        const lsKeys = Object.keys(localStorage);
        // const gsKeys = lsKeys.filter(key => key.startsWith('DGB-GRID'));
        const gsKeys = lsKeys.filter(key => key.startsWith('PCM-GRID'));
        gsKeys.forEach((key) => {
            this.cleanUpContainer(key);
        });

        if (connectWithLocalStore) {
            localStore.setCleanUp(util.bind(this.removeExpired, this));
        }
    },
};
