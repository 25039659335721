import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

const BillerImage = Model.extend({
    sync(method, model, options) {
        const data = {
            item: [],
        };

        data.item.push({
            name: 'merchantId',
            value: model.get('MerchantId'),
        }, {
            name: 'fileName_en',
            value: model.get('FileName_en'),
        }, {
            name: 'fileName_fr',
            value: model.get('FileName_fr'),
        });

        http.post(services.billPayImage, data, (result) => {
            options.success(result.item);
        }, () => {
            options.error();
        });
    },

    parse(response) {
        const obj = {};

        util.each(response, (item) => {
            obj[item.name] = item.value;
        });

        return obj;
    },
});

export default BillerImage;
