import CollectionView from '@glu/core/src/collectionView';
import Collection from '@glu/core/src/collection';
import accountCollectionViewTmpl from './accountCollectionView.hbs';

/**
 * Represents a list of view types on the list layout
 *
 * @class LayoutListView
 */
const ListItemsView = CollectionView.extend({
    template: accountCollectionViewTmpl,
    tagName: 'div',
    className: 'list-view table no-border panel-group',

    initialize(options) {
        this.options = options;
        this.mode = options.mode || 'create';
        this.isSMB = options.isSMB;

        this.itemViewOptions = {
            mode: this.mode,
            isSMB: this.isSMB,
            accountCollection: new Collection(),
            currencyCollection: options.currencyCollection,
            contactModel: this.options.contactModel,
            countries: options.countries,
        };
    },
});

export default ListItemsView;
