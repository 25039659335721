var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"workflowPageHeader"),depth0,{"name":"workflowPageHeader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"section section-container\">\n    <div class=\"section-body\">\n        <div data-region=\"alertRegion\"></div>\n        <div class=\"row\">\n            <div class=\"form-group col-md-4 required\">\n                <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Request.Type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":63}}}))
    + "</label>\n                <select class=\"form-control\"\n                        data-bind=\"model\"\n                        disabled\n                        name=\"requestType\">\n                    <option value=\"freeForm\" selected>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"serviceRequest.Free-Form.Request",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":54},"end":{"line":12,"column":99}}}))
    + "</option>\n                </select>\n                <span class=\"help-block\"\n                      data-bind=\"model\"\n                      data-validate=\"requestType\"></span>\n            </div>\n        </div>\n        <div data-region=\"requestTypeRegion\"></div>\n        <div class=\"row\">\n            <div class=\"col-md-12\">\n                <button type=\"button\"\n                        class=\"btn btn-primary\"\n                        data-hook=\"getSubmit\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":44}}}))
    + "\n                </button>\n                <button type=\"button\"\n                        class=\"btn btn-secondary\"\n                        data-hook=\"getCancel\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":46}}}))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n</section>\n";
},"usePartial":true,"useData":true});