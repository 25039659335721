var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"pageTitle") || (depth0 != null ? lookupProperty(depth0,"pageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pageTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":44}}}) : helper)))
    + "</h1>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item original-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showAllAccountsTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showAllAccounts\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.AllAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":21,"column":64}}}))
    + "\"\n                        aria-selected=\"true\"\n                        tabindex=\"0\"\n                        id=\"showAllAccountsLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.AllAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":63}}}))
    + "\n                    </a>\n                </li>\n   \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCashPositionSummary_GIRTRN_I") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":43,"column":23}}})) != null ? stack1 : "")
    + "   \n                <li class=\"NavTabs-item acctGroups-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showAccountGroupsTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showAccountGroups\"\n                        aria-selected=\"false\"\n   \n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.AccountGroupsTabTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":53,"column":74}}}))
    + "\"\n                        tabindex=\"-1\"\n                        id=\"showAccountGroupsLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.AccountGroupsTabTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":35},"end":{"line":56,"column":73}}}))
    + "\n                    </a>\n                </li>\n             \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNonRealTimeAccounts") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":76,"column":23}}})) != null ? stack1 : "")
    + "\n                <li class=\"NavTabs-item tranSearch-tab\"\n                    role=\"presentation\"\n                    data-hook=\"getTransactionBtn\">\n                    <a aria-controls=\"showTransactionSearchTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showTransactionSearch\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.TransactionSearch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":36},"end":{"line":85,"column":70}}}))
    + "\"\n                        aria-selected=\"false\"\n                      \n                        tabindex=\"-1\"\n                        id=\"showTransactionSearchLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.TransactionSearch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":35},"end":{"line":90,"column":69}}}))
    + "\n                    </a>\n                </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item cashPosition_GIRTRN_I-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showCashPositionTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showCashPosition_GIRTRN_I\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.cashPositionPlacemat",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":36},"end":{"line":36,"column":73}}}))
    + "\"\n                        aria-selected=\"false\"\n                        tabindex=\"-1\"\n                        id=\"showCashPositionGirtrnLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.cashPositionPlacemat",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":40,"column":35},"end":{"line":40,"column":72}}}))
    + "\n                    </a>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item customRpt-tab\"\n                    data-region=\"customReportingRegion\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showCustomReportTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showCustomReport\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.CustomReporting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":36},"end":{"line":68,"column":68}}}))
    + "\"\n                        aria-selected=\"false\"\n\n                        tabindex=\"-1\"\n                        id=\"showCustomReportLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.CustomReporting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":35},"end":{"line":73,"column":67}}}))
    + "\n                    </a>\n                </li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item priorDay-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showPreviousDayTab\"\n                        id=\"showPreviousDayLink\"\n                        href=\"#\" class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showPreviousDay\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.PreviousDay",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":103,"column":36},"end":{"line":103,"column":64}}}))
    + "\"\n                        aria-selected=\"false\"\n                        tabindex=\"0\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.PreviousDay",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":106,"column":35},"end":{"line":106,"column":63}}}))
    + "\n                    </a>\n                </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item currentDay-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showCurrentDayTab\"\n                        href=\"#\" class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showCurrentDay\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.CurrentDay",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":118,"column":36},"end":{"line":118,"column":63}}}))
    + "\"\n                        aria-selected=\"false\"\n                        \n                        tabindex=\"-1\"\n                        id=\"showCurrentDayLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.CurrentDay",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":123,"column":35},"end":{"line":123,"column":62}}}))
    + "\n                    </a>\n                </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item cashPosition-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showCashPositionTab\"\n                        href=\"#\" class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showCashPosition\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.cashPositionPlacemat",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":135,"column":36},"end":{"line":135,"column":73}}}))
    + "\"\n                        aria-selected=\"false\"\n               \n                        tabindex=\"-1\"\n                        id=\"showCashPositionGirbalLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.cashPositionPlacemat",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":140,"column":35},"end":{"line":140,"column":72}}}))
    + "\n                    </a>\n                </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item acctGroupsPriorDay-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showAccountGroupsTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showAccountGroupsPriorDay\"\n                        aria-selected=\"false\"\n                   \n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.AccountGroupsTabTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":154,"column":36},"end":{"line":154,"column":74}}}))
    + "\"\n                        tabindex=\"-1\"\n                        id=\"showAccountGroupsPriorDayLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.AccountGroupsTabTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":157,"column":35},"end":{"line":157,"column":73}}}))
    + "\n                    </a>\n                </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNonRealTimeAccounts") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":16},"end":{"line":175,"column":23}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item customRpt2-tab\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showNewCustomReportTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showNewCustomReport\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.CustomReporting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":168,"column":36},"end":{"line":168,"column":68}}}))
    + "\"\n                        aria-selected=\"false\"\n                        tabindex=\"-1\"\n                        id=\"showNewCustomReportLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.CustomReporting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":172,"column":35},"end":{"line":172,"column":67}}}))
    + "\n                    </a>\n                </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"NavTabs-item newTranSearch-tab\"\n                    data-hook=\"getNewTransactionSearchBtn\"\n                    role=\"presentation\">\n                    <a aria-controls=\"showNewTransactionSearchTab\"\n                        class=\"NavTabs-link btn btn-tertiary\"\n                        data-action=\"showNewTransactionSearch\"\n                        aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.TransactionSearch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":186,"column":36},"end":{"line":186,"column":70}}}))
    + "\"\n                        aria-selected=\"false\"\n                        \n                        tabindex=\"-1\"\n                        id=\"showNewTransactionSearchLink\"\n                        role=\"tab\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.TransactionSearch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":191,"column":35},"end":{"line":191,"column":69}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pageTitle") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"page bankAccountSummary\">\n    <div class=\"section section-container\">\n        <div class=\"section-body\">\n            <ul class=\"NavTabs nav nav-tabs\"\n                data-hook=\"getNavTabs\"\n                id=\"navigation-header\"\n                role=\"tablist\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCurrentOrPreviousTransactions") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":93,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPreviousDaySummary") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":12},"end":{"line":109,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCurrentDaySummary") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":12},"end":{"line":126,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCashPositionSummary_GIRBAL_I") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":12},"end":{"line":143,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPreviousDaySummary") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":12},"end":{"line":176,"column":19}}})) != null ? stack1 : "")
    + "            \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNewPreviousDayTransactions") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":12},"end":{"line":194,"column":19}}})) != null ? stack1 : "")
    + "            \n            </ul>\n            <div id=\"btrContentRegion\" data-region=\"btrContentRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});