import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import template from './textboxCell.hbs';

export default ItemView.extend({
    template,

    events: {
        'change [data-hook="getTextbox"]': 'textboxChanged',
    },

    ui: {
        $textbox: '[data-hook="getTextbox"]',
    },

    templateHelpers() {
        const self = this;

        return {
            cid: this.cid,
            value() {
                return self.dataValue();
            },
            field: this.dataLabel(),
        };
    },

    textboxChanged() {
        this.model.set(
            this.dataName(),
            this.ui.$textbox.val(),
            {
                silent: true,
            },
        );
    },

    dataValue() {
        return this.model.get(this.dataName());
    },

    dataName() {
        return this.options.column.get('field');
    },

    dataLabel() {
        const label = this.options.column.get('label');
        if ([locale.get('ps.DefaultValue'), locale.get('fimport.field.number')].includes(label)) {
            return label;
        }
        return '';
    },
});
