var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bottom-border\">\n           <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.textmessage.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":56}}}))
    + "</h2>\n\n            <div class=\"textmessage-contacts-region\" data-region=\"textMessageContactRegion\"></div>\n\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"addPhoneText\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.addPhone.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":86},"end":{"line":15,"column":122}}}))
    + "</button>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bottom-border\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.voicemessage.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":58}}}))
    + "</h2>\n\n            <div class=\"voicemessage-contacts-region\" data-region=\"voiceMessageContactRegion\"></div>\n            <div>\n                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"addPhoneVoice\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.addPhone.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":91},"end":{"line":25,"column":127}}}))
    + "</button>\n            </div>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"bottom-border\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.emailmessage.heading",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":58}}}))
    + "</h2>\n                <!--end panel section -->\n\n            <div class=\"email-contacts-region\" data-region=\"emailContactRegion\"></div>\n            <div>\n                <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"addEmail\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.addEmail.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":86},"end":{"line":37,"column":122}}}))
    + "</button>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"security-passcode\">\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"passcode.settings.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":72}}}))
    + "</h1>\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n\n    <section class=\"section section-container\">\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowSMS") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowVoice") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowEmail") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":40,"column":15}}})) != null ? stack1 : "")
    + "        <div>\n            <div>\n                 <button type=\"button\" class=\"btn btn-primary submit\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":89},"end":{"line":43,"column":113}}}))
    + "</button>\n                 <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"reset\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":84},"end":{"line":44,"column":109}}}))
    + "</button>\n            </div>\n        </div>\n\n    </section>\n\n</div>\n";
},"useData":true});