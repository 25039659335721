import ItemView from '@glu/core/src/itemView';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';

export default ItemView.extend({
    className: 'print-options',
    SELECTION_MODE_ALL: 'all',
    SELECTION_MODE_SELECT: 'sel',

    ui: {
        $selectedItems: '[data-hook="getSelectedItems"]',
        $includeSummaryInfoCheckbox: '[data-hook="getIncludeSummaryInfo"]',
    },

    modelEvents: {
        'change:selection': 'updateSelectedItems',
        'change:expdata': 'setInquiryId',
    },

    onRender() {
        if (this.isIncludeSummaryInfoEnabled()) {
            this.handleIncludeSummaryInfoCheckboxState('summary');
        }
    },

    templateHelpers() {
        return {
            selectedItemsCount: this.getSelectedItemsCount(),
            hasSelectedRows: this.options.selectedRows,
            hasSummarySelection: this.hasSummarySelection(),
            showIncludeSummaryInfo: this.options.showIncludeSummaryInfo,
            printSummaryLabel: this.options.printSummaryLabel || locale.get('common.printOptions.summary'),
            printDetailsLabel: this.options.printDetailsLabel || locale.get('common.printOptions.details'),
        };
    },

    /**
     * Sometimes a request requires inquiryId for summary and detailed printing.
     * When a summaryInquiryId is required and summary is selected, update model
     * inquiryId to use summaryInquiryId
     * @param model
     * @param value
     */
    setInquiryId(model, value) {
        let inquiryId;

        if (value === 'summary') {
            inquiryId = this.options.summaryInquiryId ? this.options.summaryInquiryId : null;
        } else {
            ({ inquiryId } = this.options);
        }
        model.set('inquiryId', inquiryId);
        if (this.isIncludeSummaryInfoEnabled()) {
            this.handleIncludeSummaryInfoCheckboxState(value);
        }
    },

    /**
     * Evaluate options to determine if should show summary selection, default to true
     * @returns {boolean} - show summary selection or not
     */
    hasSummarySelection() {
        return this.options.hasSummarySelection === undefined
            ? true : this.options.hasSummarySelection;
    },

    /**
     * Based on selection mode, return selected or total count
     * @returns {number} - number of selected item or total items
     */
    getSelectedItemsCount() {
        return this.model.get('selection') === this.SELECTION_MODE_ALL ? this.options.totalItems : this.options.selectedRows;
    },

    /**
     * Update the element with the selected count
     */
    updateSelectedItems() {
        this.ui.$selectedItems.text(this.getSelectedItemsCount());
    },

    /**
     * Close this dialog
     */
    cancel() {
        dialog.close();
    },

    /**
     * Helper method to check whether include summary info is enabled
     * @returns {boolean}
     */
    isIncludeSummaryInfoEnabled() {
        return this.options.showIncludeSummaryInfo && this.ui.$includeSummaryInfoCheckbox;
    },

    handleIncludeSummaryInfoCheckboxState(value) {
        const displayTransactionTotals = applicationConfigParams
            .getValue('GENERAL', 'DISPLAYTRANSACTIONTOTALS') === '1';
        const transactionSelected = value === 'transaction';
        const summarySelected = value === 'summary';
        const isChecked = this.ui.$includeSummaryInfoCheckbox.prop('checked');
        /**
         * Disable the checkbox btn if transactions radio btn was selected
         * and display totals is disabled
         */
        const isCheckboxDisabled = (!displayTransactionTotals && transactionSelected)
            || (!displayTransactionTotals && summarySelected && this.options.balancesHidden);
        this.ui.$includeSummaryInfoCheckbox.prop('disabled', isCheckboxDisabled);
        if (isCheckboxDisabled) {
            this.ui.$includeSummaryInfoCheckbox.prop('checked', false);
            this.model.set('includeSummaryInfo', 0);
        } else if (!isChecked && summarySelected && !displayTransactionTotals) {
            this.ui.$includeSummaryInfoCheckbox.prop('checked', true);
            this.model.set('includeSummaryInfo', 1);
        } else if (isChecked) {
            this.model.set('includeSummaryInfo', 1);
        }
    },
});
