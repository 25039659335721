import Layout from '@glu/core/src/layout';
import $ from 'jquery';
import locale from '@glu/locale';
import SimpleEntitlements from 'app/administration/collection/user/simpleEntitlements';
import PaymentTypeCollectionView from './assignedPermissions/paymentTypeCollectionView';
import permissionsByPaymentTypeTmpl from './permissionsByPaymentType.hbs';

export default Layout.extend({
    template: permissionsByPaymentTypeTmpl,

    ui: {
        $paymentGroupBtn: '.btn-link-group .btn-link',
        expandBtn: '.expand-all-btn',
        collapseBtn: '.collapse-all-btn',
    },

    initialize(options) {
        this.mode = options.mode;
    },

    onRender() {
        this.ui.collapseBtn.hide();
        if (this.ui.$paymentGroupBtn.length > 0) {
            this.ui.$paymentGroupBtn.first().addClass('active');
            this.loadPermissionsByPaymentGroup(this.ui.$paymentGroupBtn.data().value);
        }
    },

    showPermissionsByPaymentGroup(e) {
        const $target = $(e.currentTarget);
        const selectedGroupId = e.target.dataset.value;

        this.ui.$paymentGroupBtn.removeClass('active');
        $target.addClass('active');

        this.ui.expandBtn.toggle(true);
        this.ui.collapseBtn.toggle(false);

        this.loadPermissionsByPaymentGroup(selectedGroupId);
    },

    loadPermissionsByPaymentGroup(groupId) {
        this.paymentTypesView = new PaymentTypeCollectionView({
            collection: new SimpleEntitlements(this.model.get('complexTypes').findWhere({
                typeGroupId: groupId,
            })
                .get('typesInGroup').where({
                    entitled: true,
                })),

            mode: this.mode,
            isRole: Boolean(this.model.roleInfo),
            paymentTypeGroup: groupId,
            hasPanelApproval: this.model.userGroupModel.get('hasPanelApproval'),
            userGroup: this.model.userGroupModel.get('id'),
        });

        this.paymentTypesRegion.show(this.paymentTypesView);
    },

    expandCollapseAll(e) {
        const $el = this.$(e.currentTarget);
        const expand = Boolean($el.find('.icon-plus').length);

        this.ui.expandBtn.toggle(!expand);
        this.ui.collapseBtn.toggle(expand);

        this.paymentTypesView.collection.each((model) => {
            model.trigger('expand-payment-types', expand);
        });
    },

    templateHelpers() {
        const self = this;

        return {
            paymentGroupIds() {
                const paymentGroupTypes = [];
                self.model.getSelectedComplexTypes().each((type) => {
                    paymentGroupTypes.push({
                        id: type.get('typeGroupId'),
                        label: locale.get(`administration.${type.get('typeGroupLabel')}`),
                    });
                });
                return paymentGroupTypes;
            },
        };
    },
});
