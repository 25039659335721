import store from 'pcm/common/store';
import React from 'react';
import locale from '@glu/locale';
import ContentLoading from 'pcm/common/components/ContentLoading';
import EntitlementProvider from 'pcm/common/entitlementContext/EntitlementProvider';
import { paymentExtractConfigEntitlements } from './PaymentExtractConfigUtils';

const PaymentExtractConfig = React.lazy(() => import('./PaymentExtractConfigList'));
const AddPaymentExtractConfig = React.lazy(() => import('./AddPaymentExtractConfig'));
const ViewPaymentExtractConfig = React.lazy(() => import('./ViewPaymentExtractConfig'));
const EditPaymentExtractConfig = React.lazy(() => import('./EditPaymentExtractConfig'));

const getActionToTitleOptions = () => ({
    title: {
        view: locale.get('paymentExtractConfig.viewPaymentExtractConfig'),
        add: locale.get('paymentExtractConfig.addPaymentExtractConfig'),
        edit: locale.get('paymentExtractConfig.editPaymentExtractConfig'),
    },
    backLink: '/ui-admin-portal/ADMINSTRATION/paymentExtractConfig',
});

export default {

    paymentExtractConfig() {
        this.showPage(
            locale.get('paymentExtractConfig.list'),
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={paymentExtractConfigEntitlements}>
                    <PaymentExtractConfig />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: locale.get('paymentExtractConfig.list'),
            },
        );
    },

    paymentExtractConfigActions(action) {
        const components = {
            view: ViewPaymentExtractConfig,
            add: AddPaymentExtractConfig,
            edit: EditPaymentExtractConfig,
        };

        store.action = action;
        store.alerts = {};

        const actionToTitleOptions = getActionToTitleOptions();
        const ComponentToShow = components[action];

        this.showPage(
            actionToTitleOptions.title[action],
            <React.Suspense fallback={<ContentLoading />}>
                <EntitlementProvider entitlements={paymentExtractConfigEntitlements}>
                    <ComponentToShow action={action} />
                </EntitlementProvider>
            </React.Suspense>,
            {
                headlineText: actionToTitleOptions.title[action],
                backLink: actionToTitleOptions.backLink,
            },
        );
    },
};
