const Constants = {
    productCode: 'CM',
    functionCode: 'INST',
    typeCode: 'STOP',
    subTypeCode: '*',
    actionMode: 'INSERT',
    fieldName: 'STOPDURATIONDESC',
    extendReasonCode: 'EXTEND_REASON_CODE',
    queryService: 'tableMaintenance/getQueryResults',
    serverError: 'RTP.REQUEST.REQOUT.EXTEND.ErrorMsg',
};
export default Constants;
