import Collection from '@glu/core/src/collection';
import services from 'services';
import http from '@glu/core/src/http';
import transform from 'common/util/transform';
import httpError from 'common/util/httpErrorResult';
import util from '@glu/core/src/util';

export default Collection.extend({
    idAttribute: 'LOGIN',

    sync(method, model, options) {
        if (method === 'read') {
            const data = {
                requestParameters: {
                    item: [{
                        name: 'typeCode',
                        value: '*',
                    }],
                },
            };
            http.post(services.generateUrl('imaging/remoteDepositCapture/locations/getListView'), data, (result) => {
                options.success(result);
            }, httpError.getResult(options, model));
        }
    },

    parse(response) {
        return response.rows.map((row) => {
            const hash = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            return {
                id: util.unescape(hash.LOGIN),
                name: util.unescape(hash.LOCATIONNAME),
            };
        });
    },
});
