var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fieldLabel") : depth0), depth0))
    + "</span>\n                                        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fieldValue") : depth0), depth0))
    + "</span>\n                                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"summary-body\">\n\n                <div class=\"first-day\">\n                        <h5>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.first.day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":51}}}))
    + " <span>("
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.as.of",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":90}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"START_DATE") || (depth0 != null ? lookupProperty(depth0,"START_DATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"START_DATE","hash":{},"data":data,"loc":{"start":{"line":5,"column":91},"end":{"line":5,"column":105}}}) : helper)))
    + ")</span>\n                        </h5>\n\n        <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"startFields") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":14,"column":33}}})) != null ? stack1 : "")
    + "                </ul>\n                </div>\n\n\n                <div class=\"last-day\">\n                        <h5>\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.last.day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":50}}}))
    + " <span>("
    + alias2(lookupProperty(helpers,"locale").call(alias1,"balanceTrans.as.of",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":58},"end":{"line":21,"column":89}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"END_DATE") || (depth0 != null ? lookupProperty(depth0,"END_DATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"END_DATE","hash":{},"data":data,"loc":{"start":{"line":21,"column":90},"end":{"line":21,"column":102}}}) : helper)))
    + ")</span>\n                        </h5>\n\n                <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"endFields") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":30,"column":33}}})) != null ? stack1 : "")
    + "                </ul>\n        </div>\n</div>\n";
},"useData":true});