import locale from '@glu/locale';

const fileExtentionInList = (file, list) => list.find((ext) => ext.toLowerCase() === `.${file.name.toLowerCase().split('.').slice(-1)}`);

const validate = (files, condition) => {
  const validFiles = [];
  const invalidFiles = [];

  /* eslint-disable no-plusplus */
  for (let i = 0; i < files.length; i++) {
    if (condition(files[i])) {
      validFiles.push(files[i]);
    } else {
      invalidFiles.push(files[i]);
    }
  }
  return [validFiles, invalidFiles];
};

/** Analyze the files based on conditions set in props and return valid files and errors
 * @param {Array} denylist - list of extentions that a file cannot have
 * @param {FileList} files - The files selected by the user
 * @param {function} fileSizeErrorFormat - expected to take FileList and return array of messages.
 * @param {function} fileTypeErrorFormat - expected to take FileList and return array of messages.
 * @param {integer} maxFileSelect
 * @param {integer} minFileSelect
 * @param {Array} allowlist - list of extentions that a file may have
 */
export default function validateSelectedFiles(
  denylist,
  files,
  internalErrorFormat,
  maxFileSelect,
  maxFileSize,
  minFileSelect,
  allowlist
) {
  if (files.length < minFileSelect) {
    return [[], [{
      title: locale.get('errorAlertTitle'),
      type: 'error',
      message: `${locale.get('validationNotEnoughFiles')}`
    }]];
  }

  if (files.length > maxFileSelect) {
    return [[], [{
      title: locale.get('errorAlertTitle'),
      type: 'error',
      message: `${locale.get('validationTooManyFiles')}`
    }]];
  }
  const [validSizedFiles, filesInvalidSize] = validate(files,
    (file) => !file.size || file.size <= maxFileSize * 1000000);

  const [filesPassAllowlist, filesFailAllowlist] = validate(validSizedFiles,
    (file) => (allowlist ? fileExtentionInList(file, allowlist) : true));

  const [validFiles, filesFailDenylist] = validate(filesPassAllowlist,
    (file) => (denylist ? !fileExtentionInList(file, denylist) : true));

  const invalidFiles = {
    filesInvalidSize,
    filesFailAllowlist,
    filesFailDenylist
  };

  const errors = internalErrorFormat(invalidFiles, maxFileSize);

  return [validFiles, errors];
}
