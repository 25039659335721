import './themeDefaults';
import { Select, Input } from '@glu/form-components';
import locale from '@glu/locale';
import PropTypes from 'prop-types';
import { ProgressBar } from '@glu/indicators-react';
import {
  ContainerArrowUpNextIcon,
  ArrowCirclesNextIcon,
  XCircleIcon
} from '@glu/icons-react';
import React, { useState } from 'react';
import moment from 'moment';

import FileNameInput from './FileNameInput';
import useStyles from './FileRow.styles.js';

const FileRow = ({
  categories,
  dateFormat,
  enableNotes,
  notePlaceholder,
  fileStatus,
  onRemove,
  onUpload,
  permissions,
  richFile,
  setRichFiles,
  showProgress,
  progressFormatter,
  ...rest
}) => {
  const classes = useStyles({ rest });
  const [formState, setFormState] = useState({
    category: '',
    permission: '',
    filename: richFile.file.name.split('.').slice(0, -1).join('.'),
    notes: ''
  });
  const extension = `.${richFile.file.name.split('.').slice(-1)}`;
  const disabled = fileStatus && fileStatus.inProgress;

  const addKeyIfMatch = (prevFile, key, value) => (prevFile.id === richFile.id ? {
    ...prevFile,
    [key]: value
  } : prevFile);

  const onFieldChange = (key, suffix = '') => ({ target: { value } }) => {
    setFormState(prev => ({ ...prev, [key]: value }));
    setRichFiles(prev => prev.map(prevFile => addKeyIfMatch(prevFile, key, value + suffix)));
  };

  const fileProgress = fStatus => fStatus.amount / fStatus.total * 100;
  const fileProgressValue = fStatus => progressFormatter(fileProgress(fStatus));

  return (
    <tr>
      <td>
        <FileNameInput
          value={formState.filename}
          extension={extension}
          onChange={onFieldChange('filename', extension)}
          disabled={disabled}
        />
      </td>
      {categories.length > 0 && (
        <td>
          <Select
            className={classes.select}
            disabled={disabled}
            labelText={locale.get('labelCategorySelect')}
            onChange={onFieldChange('category')}
            options={categories}
            screenReaderLabel
            value={formState.category}
          />
        </td>
      )}
      {dateFormat && (
        <td>
          {moment(richFile.date).format(dateFormat)}
        </td>
      )}
      {permissions.length > 0 && (
        <td>
          <Select
            className={classes.select}
            disabled={disabled}
            labelText={locale.get('labelPermissionSelect')}
            onChange={onFieldChange('permission')}
            options={permissions}
            screenReaderLabel
            value={formState.permission}
          />
        </td>
      )}
      {enableNotes && (
        <td>
          <Input
            className={classes.input}
            disabled={disabled}
            labelText={locale.get('labelNotesInput')}
            placeholder={notePlaceholder}
            name="notes-input"
            value={formState.notes}
            onChange={onFieldChange('notes')}
            screenReaderLabel
            type="text"
          />
        </td>
      )}
      {showProgress && (
        <td>
          { fileStatus
            ? (
              <>
                {fileStatus && fileStatus.status === 'positive' && (
                <ProgressBar
                  status="positive"
                  message={`${fileStatus.amount}${locale.get('progressUnit')} / ${fileStatus.total}${locale.get('progressUnit')}`}
                  progress={fileProgressValue(fileStatus)}
                  dataQa="progress-positive"
                />
                )}
                {fileStatus && fileStatus.status === 'stalled' && (
                <ProgressBar
                  status="stalled"
                  message={locale.get('progressStalledMessage')}
                  progress={fileProgressValue(fileStatus)}
                  dataQa="progress-stalled"
                />
                )}
                {fileStatus && fileStatus.status === 'error' && (
                <ProgressBar
                  status="error"
                  message={locale.get('progressErrorMessage')}
                  progress={fileProgressValue(fileStatus)}
                  dataQa="progress-error"
                />
                )}
              </>
            )
            : (
              <ProgressBar
                status="stalled"
                progress={0}
                dataQa="progress-unstarted"
              />
            )
        }
        </td>
      )}
      <td>
        <div className={classes.rowActions}>
          {fileStatus && (fileStatus.status === 'error' || fileStatus.status === 'stalled')
            ? (
              <button
                className={classes.uploadButton}
                data-qa="button-restart"
                disabled={disabled}
                onClick={() => { onUpload(richFile); }}
                type="button"
                title={locale.get('buttonRestart')}
              >
                <ArrowCirclesNextIcon title={locale.get('buttonRestart')} />
              </button>
            )
            : (
              <button
                className={classes.uploadButton}
                data-qa="button-upload"
                disabled={disabled}
                onClick={() => { onUpload(richFile); }}
                type="button"
                title={locale.get('buttonUpload')}
              >
                <ContainerArrowUpNextIcon title={locale.get('buttonUpload')} />
              </button>
            )}
          <button
            type="button"
            className={classes.removeButton}
            onClick={onRemove}
            title={locale.get('buttonRemove')}
          >
            <XCircleIcon title={locale.get('buttonRemove')} />
          </button>
        </div>
      </td>
    </tr>
  );
};

FileRow.propTypes = {
  /** File being uploaded */
  richFile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.shape({}),
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string
    })
  }).isRequired,

  /** Whether to show a notes field for text to attach to file */
  enableNotes: PropTypes.bool.isRequired,

  /** Placeholder for note */
  notePlaceholder: PropTypes.string,

  /** String passed to moment to assign as uploaded date */
  dateFormat: PropTypes.string,

  /** Options to display as selectable categories */
  categories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,

  /** The status of this specific file */
  fileStatus: PropTypes.shape({
    id: PropTypes.string,
    inProgress: PropTypes.bool,
    status: PropTypes.string,
    amount: PropTypes.number,
    total: PropTypes.number
  }),

  /** Options to display as selectable permissions */
  permissions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,

  /** Toggle display and UX of progress column */
  showProgress: PropTypes.bool.isRequired,
  progressFormatter: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  setRichFiles: PropTypes.func.isRequired
};


FileRow.defaultProps = {
  dateFormat: undefined,
  fileStatus: undefined,
  notePlaceholder: '',
  progressFormatter: progress => +progress.toPrecision(4)
};

export default FileRow;
