import BaseLoanView from 'app/loans/views/baseLoanView';
import locale from '@glu/locale';
import $ from 'jquery';
import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import userInfo from 'etc/userInfo';
import store from 'system/utilities/cache';
import Formatter from 'system/utilities/format';
import singleLookupHelper from 'common/dynamicPages/views/mdf/componentHelpers/singleLookup';
import scroll from 'common/util/scroll';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import loans from 'app/loans/api/common';
import Loan from 'app/loans/models/addPayment';
import workspaceHelper from 'common/workspaces/api/helper';
import validatorPatterns from 'system/validatorPatterns';
import moment from 'moment';
import dateUtil from 'common/util/dateUtil';
import DuplicateDialog from 'common/dynamicPages/views/duplicateDialog';
import Constants from 'app/loans/constants';
import paymentTmpl from './addPayment.hbs';
import drawTmpl from './addLoanDraw.hbs';

export default BaseLoanView.extend({
    initialize(optionsParam) {
        const options = optionsParam;
        this.template = options.typeCode === 'LOANPAY' ? paymentTmpl : drawTmpl;
        /**
         * leaving this in for clarity as it allows for
         * extention of baseLoanView like in over views
         */
        options.model = new Loan(options.model);
        BaseLoanView.prototype.initialize.apply(this, [options]);
        this.stopListening(this.model, 'invalid');
    },

    events: util.extend(
        {},
        BaseLoanView.prototype.events,
        {
            'click @ui.$toggleForm': 'toggleForm',
            'click @ui.$toggleRestrict': 'toggleRestrict',
        },
    ),

    ui: util.extend(
        {},
        BaseLoanView.prototype.ui,
        {
            $inputGroupAddon: '.input-group-addon',
            $selection0: '[name="SELECTION_0"]',
            $selection1: '[name="SELECTION_1"]',
            $selection2: '[name="SELECTION_2"]',
            $selection3: '[name="SELECTION_3"]',
            $selection4: '[name="SELECTION_4"]',
        },
    ),

    regions: {
        alertRegion: '#alertRegion',
        addAnotherPaymentOptionLinkRegion: '.addAnotherPaymentOptionLinkRegion',
    },

    dialogButtons: [{
        text: 'Close',
        className: 'btn btn-primary',
        callback: 'close',
    }],

    handleBusinessDays(days) {
        // remove previous blocked dates
        this.blockedDates.splice(0, this.blockedDates.length);
        if (days.holidays.length > 0) {
            this.blockedDates.push(...days.holidays);
        }

        this.daysForward.shift();
        this.daysForward.push(days.maxForwardDays);

        this.processingDays.shift();
        this.processingDays.push(days.businessDays);

        this.cutOffTimes.shift();
        this.cutOffTimes.push(days.cutoff);

        // display cutoff if returned and config allows
        if (days.cutoffDateTimeTz) {
            this.model.set('CUTOFF_INFO', days.cutoffDateTimeTz);
        }

        this.earliestDay = days.earliestDay;
        const currDate = moment(new Date()).startOf('day');
        const earliestDate = moment(days.earliestDay);
        const diffDays = currDate.diff(earliestDate, 'days');
        const valueDate = Formatter.formatDateFromUserFormat(this.model.get('VALUE_DATE'), 'MM/DD/YYYY');

        if (!this.model.get('VALUE_DATE') || moment(valueDate).isBefore(earliestDate)) {
            this.model.set('VALUE_DATE', earliestDate.format(userInfo.getDateFormat()));
        }

        this.model.set('TRAN_DATE', Formatter.formatDate(days.tranDate));
        this.ui.$datePicker.data('daterangepicker').updateCalendars({
            blockedDates: this.blockedDates,
            daysBack: diffDays,
            daysForward: days.maxForwardDays,
        });

        /*
         * any time we update the dates, which is credit and debit updates, as well
         * as initial load, lets get most up to date cutoff
         */
        loans.updateCutoff(this.options, this.model, $('.ui-datepicker-trigger'));
    },

    onRender() {
        if (!this.hasLoadedRequiredData()) {
            // clear out helpPage from cache
            store.unset('helpPage');
            this.loadRequiredData();
        } else {
            this.ui.$debitAccount.comboBox({
                placeholder: locale.get('loans.account.select'),
                allowClear: true,
                matcher: this.maskedInputMatcher.bind(this, 'debit'),
            }).on('change', $.proxy(this.onDebitAccountChanged, this));

            loans.selectIfOnlyOne(this.model, this.debit, 'DEBIT_ACCOUNT_NUMBER');

            this.ui.$creditAccount.comboBox({
                placeholder: locale.get('loans.account.select'),
                allowClear: true,
                matcher: this.maskedInputMatcher.bind(this, 'credit'),
            }).on('change', $.proxy(this.onLoanAccountChanged, this));

            loans.selectIfOnlyOne(this.model, this.credit, 'BENE_ACCOUNT');

            this.ui.$datePicker.nhDatePicker({
                blockedDates: this.blockedDates,
                processingDays: this.processingDays,
                cutOffTimes: this.cutOffTimes,
                daysBack: 0,
                daysForward: this.daysForward[0],
                showCalendarIcon: true,
                showDropdowns: true,
            });

            this.ui.$datePicker.on('apply.daterangepicker', (e) => {
                this.ui.$summaryDate.text(Formatter.formatDateFromUserFormat(
                    e.target.value,
                    dateUtil.PAYMENT_SUMMARY_DATE_FORMAT,
                ));
                if (this.ui.$summaryOn.hasClass('hide')) {
                    this.ui.$summaryOn.removeClass('hide');
                }
                this.model.set('TRAN_DATE', e.target.value);
                this.updateSummaryTotal();
            });

            this.ui.$inputGroupAddon.hide();
            singleLookupHelper.setupSingleLookups(this);
            this.getPaymentMessage();
            loans.applyMask(this);
            this.displaySummaryTotal();
        }
    },

    clearDebitDetails() {
        this.debitAccount = '';
        this.debitSubaccount = '';
        this.model.unset('DEBIT_ACCOUNT_NUMBER');
        this.model.unset('DEBIT_SUBACCOUNT_NUM');
        this.model.unset('DEBIT_CURRENCY');
        this.model.unset('DEBIT_TIEREDLOANTYPE');
        this.model.unset('DEBIT_ACCOUNT_BANK_NAME');
        this.model.unset('DEBIT_ACCOUNT_TITLE');
        this.model.unset('DEBIT_BANKNAME');
        this.model.unset('DEBIT_BANK_CODE');
        this.model.unset('DEBIT_BANK_CODE_UI');
        this.model.unset('DEBIT_COUNTRY');
        this.model.unset('DEBIT_INTERNATIONALBIC');
        this.model.unset('ACCOUNTFILTER');
        this.model.unset('AMOUNT_0');
        this.updateSummaryAmountTotal();
    },

    submit() {
        if (this.selectedPaymentType === 'NONE') {
            this.displayNoInvoiceError();
            return;
        }
        this.beforeSubmit();

        // validate the form
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            return;
        }

        this.toggleButtonsEnabled(false);

        this.model.unset('_saveIncomplete');

        const self = this;

        // merge debit and credit attributes into a single object
        const accountAttributes = $.extend(
            null,
            this.accountDetails.debit,
            this.accountDetails.credit,
        );
        this.model.set(accountAttributes);

        // needs to be set after the accountAttributes
        this.model.set({
            DEBIT_ACCOUNT_NUMBER: (this.accountDetails.debit.DEBIT_ACCOUNT_NUMBER || '').trim(),
            DEBIT_SUBACCOUNT_NUM: (this.accountDetails.debit.DEBIT_SUBACCOUNT_NUM || '').trim(),
            BENE_ACCOUNT: (this.accountDetails.credit.BENE_ACCOUNT || '').trim(),
            BENE_SUBACCOUNT_NUM: (this.accountDetails.credit.BENE_SUBACCOUNT_NUM || '').trim(),
        });

        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    store.set(`${self.contextKey}-alertMessage`, 'INSERT');
                    store.set(`${self.contextKey}-confirms`, confirmResponse);
                    workspaceHelper.returnToCurrentWorkspace(self);
                },

                error(response, model) {
                    self.toggleButtonsEnabled(true);

                    if (response.error.resultType === 'WARNING') {
                        if (response.error.errorCode === 540) {
                            Dialog.custom(new DuplicateDialog({
                                model,
                                resp: response,
                                methodName: 'SAVE',
                            }));
                        } else {
                            Dialog.custom(new WarningDialog({
                                model: response,
                                methodName: 'SAVE',
                                confirms: response.error.confirms,
                            }));
                        }
                    } else {
                        const alertView = loans.getAlertView(response);
                        self.$('#alertRegion').hide();

                        $('html, body').animate({
                            scrollTop: 0,
                        }, 100);

                        self.alertRegion.show(alertView);
                        self.$('#alertRegion').fadeIn(200, () => alertView.$el.focus());
                    }
                },
            },
        );
    },

    beforeSubmit() {
        const invoicePaymentTypeSelected = this.selectedPaymentType === Constants.TYPE.INVOICE_ONLY;
        const invoiceSelected = !!this.model.get('INVOICENUM');

        if (invoicePaymentTypeSelected) {
            // if no valid invoice is selected, don't do anything here
            if (!invoiceSelected) {
                return;
            }
            for (let i = 0; i < this.amountOfPaymentOptions; i += 1) {
                this.model.removeValidator(`AMOUNT_${i}`);
                this.model.removeValidator(`SELECTION_${i}`);
            }
        } else {
            // manually set the invoice num to empty here to ensure the database is updated
            this.model.set('INVOICENUM', '');

            const enabledOption = this.enabledOptions.at(0);
            let unSelectedOptions = this.enabledOptions.pluck('key');
            // when we only have one unselectedOption, it gets removed from the model
            unSelectedOptions = unSelectedOptions.length > 1 ? unSelectedOptions : [];

            // the model adequately keeps track if there is only one payment option
            if (this.amountOfPaymentOptions !== 1) {
                /**
                 * the model does not adequately keep track of all selected payment options
                 * during form interactions. Instead, prior to submitting, they're set one by
                 * one below. this function is called here to clear them prior to them being
                 * properly set.
                 */
                this.clearPaymentDetailsOnModel();

                for (let x = 0; x < this.amountOfPaymentOptions; x += 1) {
                    if (this.typeCode === 'LOANPAY') {
                        const $container = $(`.multifield-container-${x}`);
                        const hidden = $container.hasClass('hide') || $container.length === 0;

                        // set the selected option value only if not an invoice
                        if (!hidden) {
                            const option = $(`#selection_${x}`).val();
                            this.model.set(`SELECTION_${x}`, option);
                            this.model.set(option, this.model.get(`AMOUNT_${x}`));
                            // remove the chosen option from the array
                            unSelectedOptions = util.without(unSelectedOptions, option);
                        }
                    }
                    if (this.typeCode === 'LOANDRAW' || this.amountOfPaymentOptions === 1) {
                        this.model.removeValidator(`AMOUNT_${x}`);
                    }
                }
            } else {
                this.model.set('AMOUNT_0', this.model.get(this.paymentOptions[0].key));
            }

            if (this.typeCode === 'LOANPAY') {
                if (enabledOption.get('onlyOption')) {
                    this.model.validators[enabledOption.get('key')].exists = true;
                } else {
                    delete this.model.validators[enabledOption.get('key')].exists;
                }

                if (this.selectedPaymentType === Constants.TYPE.ANY) {
                    /*
                     * unset each unSelected option leftover by the invoices to avoid an error
                     * with the total
                     */
                    util.each(unSelectedOptions, (opt) => {
                        this.model.unset(opt);
                    });
                }
            } else {
                this.model.validators.CREDIT_AMOUNT.exists = true;
                delete this.model.validators[enabledOption.get('key')].exists;
            }
        }

        if (this.model.get('DEBIT_ACCOUNT_NUMBER') === 'NONE') {
            this.model.unset('DEBIT_ACCOUNT_NUMBER');
        }
        if (this.model.get('BENE_ACCOUNT') === 'NONE') {
            this.model.unset('BENE_ACCOUNT');
        }
        if (this.ui.$saveAsTemplate.is(':checked')) {
            this.model.set('SAVEASTEMPLATE', '1');
        } else {
            this.model.unset('SAVEASTEMPLATE');
        }

        if (this.earliestDay) {
            const valueDate = Formatter.formatDateFromUserFormat(this.model.get('VALUE_DATE'), 'MM/DD/YYYY');
            const earliestDate = moment(this.earliestDay);
            if (moment(valueDate).unix() - earliestDate.unix() < 0) {
                this.model.set('VALUE_DATE', earliestDate.format(userInfo.getDateFormat()));
            }
        }
    },

    saveDraft() {
        if (this.selectedPaymentType === 'NONE') {
            this.displayNoInvoiceError();
            return;
        }
        this.beforeSubmit();

        this.disableValidators(this);
        if (this.childView) {
            this.disableValidators(this.childView);
        }
        if (!this.model.isValid()) {
            this.model.trigger('invalid');
            scroll.scrollToFirstError();
            return;
        }

        this.toggleButtonsEnabled(false);

        const self = this;

        // merge debit and credit attributes into a single object
        const accountAttributes = $.extend(
            null,
            this.accountDetails.debit,
            this.accountDetails.credit,
        );
        this.model.set(accountAttributes);

        // needs to be set after the accountAttributes
        this.model.set({
            DEBIT_ACCOUNT_NUMBER: (this.accountDetails.debit.DEBIT_ACCOUNT_NUMBER || '').trim(),
            DEBIT_SUBACCOUNT_NUM: (this.accountDetails.debit.DEBIT_SUBACCOUNT_NUM || '').trim(),
            BENE_ACCOUNT: (this.accountDetails.credit.BENE_ACCOUNT || '').trim(),
            BENE_SUBACCOUNT_NUM: (this.accountDetails.credit.BENE_SUBACCOUNT_NUM || '').trim(),
            _saveIncomplete: true,
        });

        this.model.save(
            {},
            {
                success(model, confirmResponse) {
                    store.set(`${self.contextKey}-alertMessage`, 'INSERT');
                    store.set(`${self.contextKey}-confirms`, confirmResponse);
                    workspaceHelper.returnToCurrentWorkspace(self);
                },

                error(response) {
                    self.toggleButtonsEnabled(true);

                    if (response.error.resultType === 'WARNING') {
                        Dialog.custom(new WarningDialog({
                            model: response,
                            methodName: 'SAVE',
                            confirms: response.error.confirms,
                        }));
                    } else {
                        const alertView = loans.getAlertView(response);

                        self.$('#alertRegion').hide();

                        $('html, body').animate({
                            scrollTop: 0,
                        }, 100);

                        self.alertRegion.show(alertView);
                        self.$('#alertRegion').fadeIn(200, () => alertView.$el.focus());
                    }
                },
            },
        );
    },

    saveWithWarning(model, options) {
        loans.saveWithWarning(this, options);
    },

    cancel() {
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    toggleForm() {
        const $templateCode = this.ui.$templateCodeForm;
        if ($templateCode.hasClass('hide')) {
            $templateCode.removeClass('hide');
            this.ui.$templateDescrForm.removeClass('hide');
            this.ui.$templateRestrictForm.removeClass('hide');

            this.model.validators.TEMPLATE_CODE = {
                description: locale.get('loans.template.name'),
                exists: true,
                matches: validatorPatterns.TEMPLATECODE_PATTERN,
                overrideError: 'isTemplateCode',
            };
            this.model.validators.TEMPLATE_DESCRIPTION = {
                description: locale.get('loans.template.desc'),
                exists: true,
            };
        } else {
            this.model.validators.TEMPLATE_CODE = null;
            this.model.validators.TEMPLATE_DESCRIPTION = null;
            $templateCode.addClass('hide');
            this.ui.$templateDescrForm.addClass('hide');
            this.ui.$templateRestrictForm.addClass('hide');
        }
    },

    templateHelpers() {
        const self = this;

        return {
            debitAccounts: self.getArrayOfAccounts(self.debit.toJSON(), self.model, 'debit'),
            creditAccounts: self.getArrayOfAccounts(self.credit.toJSON(), self.model, 'credit'),
            originLabel: loans.getOriginLabel(self.typeCode),
            destinationLabel: loans.getDestinationLabel(self.typeCode),

            // @TODO When there is only one option, display only the amount
            paymentOptions: self.enabledOptions.toJSON(),

            getTitle() {
                self.title = (self.typeCode === 'LOANPAY') ? locale.get('loans.new.payment') : locale.get('loans.new.drawdown');
                return self.title;
            },

            hasMultipleOptions: (self.typeCode === 'LOANPAY'),
            hasInsertTemplateEntitlement: Object.prototype.hasOwnProperty.call(self.options, 'entitlement') ? self.options.entitlement : false,
            singleOptionOnly: self.preferences.singleOnlyPaymentOptionSupported,
            getAmountText: locale.get(`loans.${self.typeCode.toLowerCase()}.amount`),
            displayInterestRate: this.showInterestRate,
        };
    },
});
