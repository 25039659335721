import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import constants from 'app/administration/constants';
import TokenModel from 'app/administration/models/token';
import $ from 'jquery';
import loadingTemplate from 'common/templates/loadingModal.hbs';
import template from './updateTokensModal.hbs';

const UpdateTokensModal = Layout.extend({
    template,
    loadingTemplate,

    regions: {
        tokenDropdownRegion: 'div[data-region="tokenDropdownRegion"]',
        userDropdownRegion: 'div[data-region="userDropdownRegion"]',
        tokenTypeDropdownRegion: 'div[data-region="tokenTypeDropdownRegion"]',
        tokenDeviceTypeDropdownRegion: 'div[data-region="tokenDeviceTypeDropdownRegion"]',
    },

    ui: {
        $deviceTypeDropdown: '[data-hook="deviceTypeDropdown"]',
    },

    isLostReplace() {
        if (this.mode === constants.TOKEN_ACTIONS.REPLACE
            || this.mode === constants.TOKEN_ACTIONS.LOST) {
            return true;
        }
        return false;
    },

    initialize() {
        this.model = new TokenModel(this.options.data);
        this.tokenSerialNumber = this.options.TOKENSERIALNUMBER;
        this.tokenTypeValue = this.options.TOKENTYPE;
        this.firstAssignFlag = true;
        this.mode = this.options.mode;
        this.dialogTitle = locale.get(`tknmgmt.ui.title.${this.mode}`);
        this.buttonSubmit = this.mode === ('email') ? locale.get('button.submit') : locale.get('common.assign');
        this.dialogButtons = [{
            text: this.buttonSubmit,
            className: 'btn btn-primary',
            callback: 'submit',
        }, {
            text: locale.get('common.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
        this.modalClass = 'modal-overflow modal-lg';
        this.createValidators();
    },

    createValidators() {
        if (this.mode === constants.TOKEN_ACTIONS.ASSIGN) {
            this.model.addTokenAssignmentValidator();
            this.model.addTokenTypeValidator();
            this.model.addTokenDeviceTypeValidator();
        } else if (this.mode === constants.TOKEN_ACTIONS.EMAIL) {
            this.model.addTokenDeviceTypeValidator();
        } else {
            this.model.addTokenLostReplaceValidator();
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            if (this.mode === constants.TOKEN_ACTIONS.ASSIGN) {
                this.initTokenTypeDropdown();
            } else if (this.mode === constants.TOKEN_ACTIONS.EMAIL) {
                this.initTokenDeviceDropdown();
            } else if (this.isLostReplace()) {
                this.initTokenTypeDropdown();
            }
        } else {
            this.loadRequiredData();
        }
    },
    /**
     * @method loadRequiredData
     * - loads the token, users, and token type data used in dropdowns
     * from inquiries
     */
    loadRequiredData() {
        if (this.mode === constants.TOKEN_ACTIONS.EMAIL) {
            this.createInquiryPromise('17279').then((data) => {
                this.deviceData = this.mapInquiryData(data);
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        } else if (this.isLostReplace()) {
            const promises = this.getTokenAndDeviceTypesPromises();
            Promise.all(promises)
                .then((results) => {
                    this.setTokenTypeAndDeviceData(results[0], results[1]);
                    this.setHasLoadedRequiredData(true);
                    this.render();
                });
        } else {
            this.createInquiryPromise('17278').then((data) => {
                this.tokenTypes = this.mapInquiryData(data);
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        }
    },

    /**
     * @method getTokenAndUserPromises
     * - Retrieves promises from inquiries (No token type)
     */
    getTokenAndUserPromises() {
        const usersPromise = this.createInquiryPromise('17275');
        const tokenDataPromise = this.createInquiryPromise('17274');
        return [
            usersPromise,
            tokenDataPromise,
        ];
    },

    getTokenAndDeviceTypesPromises() {
        const tokenTypePromise = this.createInquiryPromise('17278');
        const deviceTypePromise = this.createInquiryPromise('17279');
        return [
            tokenTypePromise,
            deviceTypePromise,
        ];
    },

    /**
     * - fetches the data for users and token serial numbers based on type
     * @method getSoftTokenAndUserPromises
     * @param {string} type - the token type used for specific inquiries
     * @return {Array} - returns the user and token promises
     */
    getSoftTokenAndUserPromises(type) {
        const usersPromise = this.createInquiryPromise('17275');
        let tokenPromise;
        let devicePromise;
        if (type === 'SOFT') {
            tokenPromise = this.createInquiryPromise('17277');
            devicePromise = this.createInquiryPromise('17279');
        } else if (type === 'HARD') {
            tokenPromise = this.createInquiryPromise('17274');
        } else {
            /**
             * Depending on how the dropdown is rendered, it could be possible to choose
             * "Select" again as an option which should not trigger any inquiries
             */
            return [];
        }

        return [
            usersPromise,
            tokenPromise,
            devicePromise,
        ];
    },

    setUserAndTokenData(userData, tokenData) {
        this.userData = this.mapInquiryData(userData);
        this.tokenData = this.mapInquiryData(tokenData);
    },

    setTokenTypeAndDeviceData(typeData, deviceData) {
        this.tokenTypes = this.mapInquiryData(typeData);
        this.deviceData = this.mapInquiryData(deviceData);
    },

    /**
     * @method setUserAndSoftTokenData
     * @param {object} userData - entries for User dropdown
     * @param {object} tokenData - entries for Token Serial Number dropdown
     * @param {object} deviceData - entries for Device Type dropdown
     * - mapping the returned inquiry data
     */
    setUserAndSoftTokenData(userData, tokenData, deviceData) {
        this.userData = this.mapInquiryData(userData);
        this.tokenData = this.mapInquiryData(tokenData);
        this.deviceData = this.mapInquiryData(deviceData);
    },

    /**
     * @method mapInquiryData
     * @param {object} data - the queryResponse from the rest service.
     * - returns an array of objects formatted for flexdropdown
     */
    mapInquiryData(data) {
        return util.map(data.queryResponse.QueryData.queryRows, rowItem => ({
            id: rowItem.name,
            name: rowItem.label,
            mapDataList: rowItem.mapDataList,
            rowId: rowItem.rowId,
        }));
    },

    /**
     * @method createInquiryPromise
     * @param {string} id - the inquiry id.
     * - returns a promise for the model
     */
    createInquiryPromise(id) {
        const self = this;
        return new Promise((resolve, reject) => {
            self.model.set('inquiryId', id);
            self.model.syncData(
                'inquiry',
                {
                    success: resolve,
                    error: reject,
                },
            );
        });
    },

    /**
     * - initialize the company dropdown after the dropdown data has been loaded
     * @method initDropdown
     */
    initDropdown() {
        const tokenDropdownOptions = {
            data: this.tokenData,
        };
        if (this.mode === constants.TOKEN_ACTIONS.ASSIGN) {
            if (this.tokenSerialNumber && this.firstAssignFlag) {
                this.model.set('TOKENSERIALNUMBER', this.tokenSerialNumber);
                tokenDropdownOptions.preSelected = [{
                    id: this.model.get('TOKENSERIALNUMBER'),
                    name: this.model.get('TOKENSERIALNUMBER'),
                }];
                this.firstAssignFlag = false;
            }
            this.userDropdown = this.createDropdown(
                'userDropdownRegion',
                {
                    data: this.userData,
                },
            );

            if (this.model.get('TOKENTYPE') === 'SOFT') {
                this.deviceDropdown = this.createDropdown('tokenDeviceTypeDropdownRegion', { data: this.deviceData });
            }
        }
        this.tokenDropdown = this.createDropdown('tokenDropdownRegion', tokenDropdownOptions);
        if (this.mode === constants.TOKEN_ACTIONS.ASSIGN) {
            this.listenTo(this.tokenDropdown, 'selectionChanged', this.setSelectedTokenValue);
            this.listenTo(this.userDropdown, 'selectionChanged', this.setSelectedUserValue);
            if (this.model.get('TOKENTYPE') === 'SOFT') {
                this.listenTo(this.deviceDropdown, 'selectionChanged', this.setSelectedDeviceType);
            }
        } else if (this.isLostReplace()) {
            if (this.model.get('TOKENTYPE') === 'SOFT') {
                this.deviceDropdown = this.createDropdown('tokenDeviceTypeDropdownRegion', { data: this.deviceData });
                this.listenTo(this.deviceDropdown, 'selectionChanged', this.setSelectedDeviceType);
            } else {
                this.ui.$deviceTypeDropdown.hide();
            }
            this.listenTo(this.tokenDropdown, 'selectionChanged', this.setSelectedTokenValue);
        } else {
            this.listenTo(this.tokenDropdown, 'selectionChanged', this.setSelectedTokenValue);
        }
    },
    /**
     * @method initTokenTypeDropdown
     * - initialize the Token type dropdown after the token type data has been loaded
     */
    initTokenTypeDropdown() {
        const tokenTypeDropdownOptions = {
            filter: false,
            data: this.tokenTypes,
        };

        if (this.tokenTypeValue) {
            this.model.set('TOKENTYPE', this.tokenTypeValue);
            tokenTypeDropdownOptions.preSelected = [{
                id: this.model.get('TOKENTYPE'),
                name: this.model.get('TOKENTYPE'),
            }];

            /**
             * NH-148685
             * Enabled dropdown option for 'lost' and disabling dropdown option only for Replace
             */
            if (this.mode === constants.TOKEN_ACTIONS.REPLACE) {
                tokenTypeDropdownOptions.disabled = true;
            }

            this.loadTokensAndUsersByType(this.tokenTypeValue);
        }

        this.tokenTypeDropdown = this.createDropdown('tokenTypeDropdownRegion', tokenTypeDropdownOptions);
        this.listenTo(this.tokenTypeDropdown, 'selectionChanged', this.setSelectedTokenType);
    },

    /**
     * @method initTokenDeviceDropdown
     * - initialize the Token device dropdown
     */
    initTokenDeviceDropdown() {
        this.deviceDropdown = this.createDropdown('tokenDeviceTypeDropdownRegion', { data: this.deviceData });
        this.listenTo(this.deviceDropdown, 'selectionChanged', this.setSelectedDeviceType);
    },

    /**
     * - create a flex dropdown
     * @method createDropdown
     * @param {string} region - the region the dropdown should be added to
     * @param {object} optionsParam - the data options object required by all Flex Dropdowns
     * @returns {object} - the dropdown
     */
    createDropdown(region, optionsParam) {
        const options = {
            multiSelect: false,
            disableMultiButton: true,
            showTooltip: false,
            filter: true,
            defaultSelectMessage: locale.get('common.select'),
            ...optionsParam,
        };

        const dropdown = new FlexDropdown(options);
        this[region].show(dropdown);

        return dropdown;
    },
    /**
     * @method loadTokensAndUsersByType
     * - load the user and token data based on the token type chosen
     * @param {string} type - The token type
     */
    loadTokensAndUsersByType(type) {
        const promises = this.getSoftTokenAndUserPromises(type);
        if (!promises.length) return;
        Promise.all(promises)
            .then(([userData, tokenData, deviceData]) => {
                if (type === 'SOFT') {
                    this.setUserAndSoftTokenData(userData, tokenData, deviceData);
                } else {
                    this.setUserAndTokenData(userData, tokenData);
                    if (this.mode === constants.TOKEN_ACTIONS.ASSIGN) {
                        this.ui.$deviceTypeDropdown.hide();
                    }
                }
                this.initDropdown();
            });
    },

    /**
     * - sets the selected dropdown value on the model
     * @method setSelectedTokenValue
     * @param {array} selected - array of selected objects (see Glu docs for details)
     */
    setSelectedTokenValue(selected) {
        if (selected[0].name !== constants.TOKEN_ADMINISTRATION.SELECT) {
            this.model.set('TOKENSERIALNUMBER', selected[0].name);
        }
    },
    /**
     * - sets the selected dropdown value on the model
     * @method setSelectedTokenType
     * @param {array} selected - array of selected objects (see Glu docs for details)
     */
    setSelectedTokenType(selected) {
        const type = selected[0].id;
        this.resetModel();
        this.model.set('TOKENTYPE', type);
        this.loadTokensAndUsersByType(type);
        if (type === 'SOFT') {
            this.ui.$deviceTypeDropdown.show();
            if (!this.model.validators.ID) {
                this.model.addTokenDeviceTypeValidator();
            }
        } else {
            this.ui.$deviceTypeDropdown.hide();
        }
    },

    /**
     * - sets the selected dropdown value on the model
     * @method setSelectedUserValue
     * @param {array} selected - array of selected objects (see Glu docs for details)
     */
    setSelectedUserValue(selected) {
        if (selected[0].name !== constants.TOKEN_ADMINISTRATION.SELECT) {
            this.model.set('USERID', selected[0].name);
        }
    },

    setSelectedDeviceType(selected) {
        if (selected[0].name !== constants.TOKEN_ADMINISTRATION.SELECT) {
            this.model.set('DEVICETYPELABEL', selected[0].name);
            this.model.set('ID', selected[0].id);
        }
    },

    cancel() {
        dialog.close();
    },

    /**
     * Reset the model to the defaults
     */
    resetModel() {
        this.model.set(this.model.defaults);
    },

    /**
     * - sets the payload for the proper mode - replace/assign
     * @method setPayload
     */
    setPayload() {
        if (this.isLostReplace()) {
            this.model.set(
                'tokenReplacement',
                {
                    oldTokenType: this.tokenTypeValue,
                    oldTokenSerialNumber: this.tokenSerialNumber,
                    newTokenSerialNumber: this.model.get('TOKENSERIALNUMBER'),
                    userId: this.options.USERID,
                    newTokenType: this.model.get('TOKENTYPE'),
                    deviceFamilyName: this.model.get('ID'),
                },
            );
        } else if (this.mode === constants.TOKEN_ACTIONS.EMAIL) {
            this.model.set(
                'tokenInfo',
                {
                    tokenType: this.options.TOKENTYPE,
                    userId: this.options.USERID,
                    tokenSerialNumber: this.options.TOKENSERIALNUMBER,
                    deviceFamilyName: this.model.has('ID') ? this.model.get('ID') : null,
                    tokenProfile: this.model.has('DEVICETYPELABEL') ? this.model.get('DEVICETYPELABEL') : null,
                },
            );
        } else {
            this.model.set(
                'assignmentList',
                [{
                    assignedToken: this.model.has('TOKENSERIALNUMBER') ? this.model.get('TOKENSERIALNUMBER') : null,
                    assignedTokenType: this.model.has('TOKENTYPE')
                        ? this.model.get('TOKENTYPE') : null,
                    assignedUserId: this.model.get('USERID'),
                    assignedDeviceName: this.model.get('TOKENTYPE') === 'SOFT' && this.model.has('ID')
                        ? this.model.get('ID') : null,
                    assignedTokenProfile: this.model.get('TOKENTYPE') === 'SOFT' && this.model.has('DEVICETYPELABEL')
                        ? this.model.get('DEVICETYPELABEL') : null,
                }],
            );
        }
    },

    submit() {
        const self = this;

        this.setPayload();

        if (this.model.get('TOKENTYPE') === 'HARD') {
            this.model.removeValidator('ID');
        }

        if (this.model.validate()) {
            this.model.trigger('invalid');
            return;
        }
        const submitButton = 'button[type="button"][data-key="0"]';
        $(submitButton).attr('aria-busy', true);
        this.model.syncData(
            this.mode,
            {
                success(result) {
                    $(submitButton).attr('aria-busy', false);
                    self.trigger('token:action:success', result);
                    dialog.close();
                },

                error(model) {
                    $(submitButton).attr('aria-busy', false);
                    self.trigger('token:action:error', model.error);
                    dialog.close();
                },
            },
        );
    },

    templateHelpers() {
        const originalTokenType = this.options.TOKENTYPE === 'HARD' ? 'hard' : 'soft';
        return {
            isAssign: this.mode === constants.TOKEN_ACTIONS.ASSIGN,
            isEmail: this.mode === constants.TOKEN_ACTIONS.EMAIL,
            isReplaceOrLost: this.isLostReplace(),
            TOKENSERIALNUMBER: this.tokenSerialNumber,
            TOKENTYPE: locale.get(`tknmgmt.type.${originalTokenType}`),
            shouldShowTokenTypeDropdown: this.mode === constants.TOKEN_ACTIONS.ASSIGN,
        };
    },
});
export default UpdateTokensModal;
