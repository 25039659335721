import React, {
    useContext,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    CheckboxRadio,
    LabelText,
} from '@glu/form-components';
import {
    useInputAPI,
    FormContext,
} from '@glu/validation-react';
import useStyles from './ValidatedCheckboxRadioGroup.styles';

const ValidatedCheckboxRadioGroup = ({
    labelText,
    name,
    itemList,
    validators,
    autoSelectSingleOption,
}) => {
    const classes = useStyles();
    const formValue = useContext(FormContext);
    const {
        value = [],
        onChange,
    } = useInputAPI({
        name,
        validators,
    });
    const { data, errors, validate } = formValue;
    const hasErrors = !!errors?.[name]?.length;

    const onCheckboxChange = (htmlValue, checkboxName, checkboxValue) => {
        if (checkboxValue) {
            onChange(name, [
                ...value,
                checkboxValue,
            ]);
        } else {
            onChange(name, value.filter(val => val !== htmlValue));
        }
        validate(name);
    };
    // If autoSelectSingleOption is true and there is only one option, select the option
    useEffect(() => {
        if (autoSelectSingleOption && itemList.length === 1 && !data[name]) {
            const [option] = itemList;
            onChange(name, [option.htmlValue]);
        }
    }, [autoSelectSingleOption, name, itemList, onChange, data]);
    return (
        <>
            <LabelText
                className={hasErrors && classes.label}
                label={labelText}
            />
            {itemList.map((option) => {
                const checked = data?.[name] || [];
                return (
                    <CheckboxRadio
                        name={option.htmlValue}
                        htmlValue={option.htmlValue}
                        labelText={option.labelText}
                        key={option.htmlValue}
                        type={option.type}
                        onChange={(checkboxName, checkboxValue) => onCheckboxChange(
                            option.htmlValue,
                            checkboxName,
                            checkboxValue,
                        )}
                        checked={checked.includes(option.htmlValue)}
                    />
                );
            })}
            {hasErrors && (
                <p className={classes.validationMessage}>{errors[name]}</p>
            )}
        </>
    );
};

ValidatedCheckboxRadioGroup.propTypes = {
    labelText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    itemList: PropTypes.arrayOf(PropTypes.shape({
        labelText: PropTypes.string.isRequired,
        htmlValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        type: PropTypes.string,
    })).isRequired,
    validators: PropTypes.shape({
        name: PropTypes.string.isRequired,
        required: PropTypes.bool,
    }),
    autoSelectSingleOption: PropTypes.bool,
};
ValidatedCheckboxRadioGroup.defaultProps = {
    validators: {},
    autoSelectSingleOption: false,
};
export default ValidatedCheckboxRadioGroup;
