import $ from 'jquery';
import Handlebars from 'handlebars';
import * as moduleSupport from 'system/moduleSupport';

import 'common/api/preload';
import 'system/keepAlive/keepAlive';
import 'common/util/input-android-monkey';

// Formerly requireDependencies
import 'etc/disableOnSync';
import 'etc/partialLoader';
import 'etc/templateHelpers';
import '@dgb/bottomline-api';
import 'common/templateHelpers/globalHelpers';
import 'common/behaviors/configuration';
import 'system/csrf';

import '@glu/core/src/core/internal/core'; // eslint-disable-line

// GLU Icons Override
import { ICONS_DATA } from '@glu/icons/src/js/GluIcons';
import iconTemplate from '@glu/icons/src/templates/iconTemplate.hbs';
import GluIcons, { getIcon, getIconByAlias, getIconHelper } from 'system/gluOverride/icons/gluIcons';

// Formerly requirePlugins
import 'select2';
import '@dgb/jquery.inputmask';
import 'jquery-mask-plugin';
import 'etc/vendor/jquery-ui-touch-punch';
import 'guillotine';

// Formerly requirePatches
import 'system/gluOverride/scheduler/scheduler';
import 'system/vendor/closest-polyfill/closest-polyfill';

// Post patches requires
import dialog from '@glu/dialog';
import ModalView from 'system/gluOverride/core/modal/modalView';

/* global Inputmask */
/* eslint global-require: 0 */
/**
 * App Bootstrap file. What *was* happening in common.js, extracted to be
 * shared between
 * RequireJS and Webpack workflows
 * "TODO" block Comment below is from common.js, kept for history and info.
 */
function requireDependencies() {
    // GLU Icons
    const gluIconsInstance = new GluIcons({ iconsData: ICONS_DATA });
    const iconHelper = getIconHelper({
        getIconFn: getIcon.bind(gluIconsInstance),
        getIconByAliasFn: getIconByAlias.bind(gluIconsInstance),
        template: iconTemplate,
    });
    Handlebars.registerHelper('customizedIcon', iconHelper.bind(gluIconsInstance));
}

/*
 * TODO
 * 1. Update Glu so that it has the numeric extensions (if it doesn't already).
 * 2. Update textSearch.js so that it calls $.mask('numeric') or whatever
 *   is appropriate instead of the custom maskNumeric function.
 * 3. Get rid of NHUX's custom copy of inputMask.
 * 4. Add the new version of dialog.warning() below to Glu.
 * 5. Remove the shim below.
 *
 * Once that's done, we should get rid of glu/core/internal/masking.js and
 * let EMEA know that $.fn.maskNumeric() is no longer available and they'll need
 * to include it within their products if they still need it.
 */

requireDependencies();
/*
 * requirePlugins();
 * requirePatches();
 */

/*
 * https://github.com/RobinHerbots/Inputmask#autounmask
 * returns an unformatted amount when val() is called on a masked input
 * this applies to masks where autoUnmask isn't explicitly set in the configuration
 */
Inputmask.extendDefaults({
    autoUnmask: true,
});

Inputmask.extendAliases({
    // this covers 'numeric', 'decimal', and 'number' masks
    numeric: {
        autoUnmask: true,
    },
});

dialog.warning = dialog.warning || function (message, title, callback) {
    let localTitle = title;
    let localCallback = callback;
    if (typeof localTitle === 'function') {
        localCallback = localTitle;
        localTitle = null;
    }

    if (typeof localTitle !== 'function' && localTitle === null) {
        localTitle = ' ';
    }

    const decisionButtons = [{
        text: 'Continue',
        className: 'btn-primary',

        callback() {
            this.close();

            if (localCallback) {
                localCallback(true);
            }
        },
    }, {
        text: 'Cancel',
        className: 'btn-link',

        callback() {
            this.close();

            if (localCallback) {
                localCallback(false);
            }
        },
    }];

    const noDecisionButtons = [{
        text: 'OK',
        className: 'btn-primary',

        callback() {
            this.close();

            if (localCallback) {
                localCallback();
            }
        },
    }];

    const popup = new ModalView({
        type: 'confirm',
        icon: 'warning',
        message,
        title: localTitle,
        buttons: localCallback && localCallback.length > 0
            ? decisionButtons : noDecisionButtons,
    });

    popup.$el.attr('role', 'alertdialog');
    this.showPopup(popup);

    return popup;
};

dialog.collisionMode = 'stack';

// make jquery available on the window
// eslint-disable-next-line
window.jQuery = window.$ = $;

// the former glu-comboBox wrapper
$.fn.comboBox = function (...args) {
    const options = args[0] || {};
    if (options.searchField === false) {
        options.minimumResultsForSearch = -1;
    }
    return $.fn.select2.apply(this, args);
};

// provide support for modules to access certain legacy UI pieces
Object.keys(moduleSupport).forEach((module) => {
    moduleSupport[module].setup();
});
