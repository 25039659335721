import ColumnHeader from 'no-override!@glu/grid/src/column/columnHeader';
import rowSelectorTemplate from 'no-override!@glu/grid/src/column/columnHeaderRowSelector.hbs';

export default ColumnHeader.extend({
    triggers: {},

    // Use the existing template and replace the string rather than making a copy.
    template(data, ...args) {
        return ColumnHeader.prototype.template.call(this, data, ...args)
            .replace('class="title-value"', `class="title-value" id="label-th-${data.columnId}"`);
    },

    // Use the column.model.id as a unique, per-instance key
    templateHelpers(...args) {
        return {
            ...ColumnHeader.prototype.templateHelpers.apply(this, args),
            columnId: this.model.cid,
        };
    },

    /*
     * Conditionally change the template based on the column type
     * TODO: ideally we should introduce tableHeader subclasses.
     */
    getTemplate() {
        return this.model.get('type') === 'rowSelector' ? rowSelectorTemplate : this.template;
    },

    events: {
        ...ColumnHeader.prototype.events,
        'click .txt': 'handleClick',
    },

    handleClick(e) {
        this.trigger('click', this, e);
        e.preventDefault();
    },

    setWidth() {
        /*
         * This is overwritten to avoid the dynamic sizing
         * that happens by default and allows this to be
         * controlled at the tableLayout.js level where
         * the various column sizing is happening in the
         * existing override. This is related to NH-171128
         * where the change was made to ensure that all
         * columns fit label and/or content while still
         * allowing them to be resized by a user.
         */
    },

});
