import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';

export default {
    filterCollection(collection, fieldName, criteria) {
        const self = this;
        const newCollection = new Collection();
        // TODO: Shouldn't perform action in a filter().
        util.filter(collection.models, (model) => {
            const filteredValue = self.getFieldValue(model, fieldName);
            if (filteredValue === criteria) {
                newCollection.add(model);
                return true;
            }
            return false;
        });

        return newCollection;
    },

    getToFieldValue(model, fieldName) {
        try {
            return util.findWhere(
                model.get('mapDataList'),
                {
                    toField: fieldName,
                },
            ).value;
        } catch (e) {
            return '';
        }
    },

    setToFieldValue(model, fieldName, fieldValue) {
        const attribute = util.findWhere(
            model.get('mapDataList'),
            {
                toField: fieldName,
            },
        );
        attribute.value = fieldValue;
    },

    getFieldValue(model, fieldName) {
        try {
            return util.findWhere(
                model.get('columns'),
                {
                    fieldName,
                },
            ).fieldValue;
        } catch (e) {
            return '';
        }
    },

    setFieldValue(model, fieldName, fieldValue) {
        const attribute = util.findWhere(
            model.get('columns'),
            {
                fieldName,
            },
        );
        attribute.fieldValue = fieldValue;
    },
};
