import React from 'react';
import PropTypes from 'prop-types';
import useNavigation from '../../hooks/useNavigation';
import useLocale from '../../hooks/useLocale';
import PageHeader from '../PageHeader/PageHeader';
import DropdownPicker from '../DropdownPicker/DropdownPicker';

const { getLocaleString } = useLocale();
const propTypes = {
    returnRoute: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
};

const payload = {
    IncludeMapData: 1,
    queryCriteria: {
        inquiryId: '28513',
        queryType: 'SearchQuery',
        action: {
            productCode: 'RTP',
            functionCode: 'REQUEST',
            typeCode: 'REQOUT',
        },
    },
    formatterType: 'Query',
};

const RfpChooseTemplate = ({
    returnRoute, submit,
}) => {
    const { cancel } = useNavigation({
        returnRoute,
    });

    return (
        <div>
            <PageHeader headingText={getLocaleString('rfp.templatePicker.heading')} />
            <DropdownPicker
                cancel={cancel}
                submit={submit}
                service="inquiry/getQueryResults"
                payload={payload}
                defaultSelectMessage={getLocaleString('option.select')}
                disableMultiButton={false}
                showTooltip={false}

            />
        </div>
    );
};

RfpChooseTemplate.propTypes = propTypes;

export default RfpChooseTemplate;
