var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/addServices\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":65},"end":{"line":13,"column":99}}}))
    + "\n                            </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                              <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/viewServices\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":100}}}))
    + "\n                            </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/addProfile\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.defaults",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":64},"end":{"line":25,"column":98}}}))
    + "\n                            </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/viewProfile\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.defaults",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":65},"end":{"line":29,"column":99}}}))
    + "\n                            </a>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr>\n                            <td>\n                                <div>\n                                    <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"widgetDescription") || (depth0 != null ? lookupProperty(depth0,"widgetDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widgetDescription","hash":{},"data":data,"loc":{"start":{"line":66,"column":43},"end":{"line":66,"column":64}}}) : helper)))
    + "</label>\n                                </div>\n                            </td>\n                            <td>\n                                <select id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widgetId") || (depth0 != null ? lookupProperty(depth0,"widgetId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widgetId","hash":{},"data":data,"loc":{"start":{"line":70,"column":44},"end":{"line":70,"column":56}}}) : helper)))
    + "-group\" class=\"form-control\">\n                                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"groups") : depths[1]),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":36},"end":{"line":74,"column":45}}})) != null ? stack1 : "")
    + "                                </select>\n                            </td>\n                        </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"groupId") || (depth0 != null ? lookupProperty(depth0,"groupId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupId","hash":{},"data":data,"loc":{"start":{"line":73,"column":55},"end":{"line":73,"column":66}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":73,"column":68},"end":{"line":73,"column":76}}}) : helper)))
    + "</option>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"btn btn-primary submit\" data-action=\"save\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":96},"end":{"line":85,"column":125}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":62}}}))
    + "</h1>\n        <div class=\"page-header\" data-region=\"header\"></div>\n    </div>\n    <section class=\"section section-container\" role=\"navigation\">\n        <div class=\"section-body external-widgets\">\n            <ul class=\"NavTabs nav nav-tabs is-open\" data-hook=\"getNavTabs\" role=\"tablist\">\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":19,"column":26}}})) != null ? stack1 : "")
    + "                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":31,"column":26}}})) != null ? stack1 : "")
    + "                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/addPaymentLimits\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":62},"end":{"line":36,"column":94}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/externalWidgets\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.bankwidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":61},"end":{"line":42,"column":98}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item is-active\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/elearning\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.elearning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":55},"end":{"line":48,"column":90}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n            <p class=\"instruction-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.elearning.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":40},"end":{"line":52,"column":75}}}))
    + "</p>\n            <div class=\"form-container\">\n                <table class=\"external-widget-table\">\n                    <thead>\n                    <tr>\n                        <th>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.elearning.widget",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":28},"end":{"line":57,"column":65}}}))
    + "</th>\n                        <th>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.elearning.contentGroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":28},"end":{"line":58,"column":71}}}))
    + "</th>\n                    </tr>\n                    </thead>\n                    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"widgets") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":78,"column":29}}})) != null ? stack1 : "")
    + "                    </tbody>\n                </table>\n            </div>\n            <div class=\"buttons-container\">\n                <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":20},"end":{"line":86,"column":27}}})) != null ? stack1 : "")
    + "                    <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":87,"column":89},"end":{"line":87,"column":120}}}))
    + "</button>\n                </div>\n            </div>\n        </div>\n    </section>\n</div>\n";
},"useData":true,"useDepths":true});