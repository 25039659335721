import locale from '@glu/locale';
import util from '@glu/core/src/util';
/**
 * When model has changed, check the value and add or
 * remove model validator accordingly
 * @param {Object} changeModel - model that has changed
 * @param {*} value - new value
 */
const amountEqualityChange = (changeModel, value) => {
    if (value === 'BETWEEN') {
        changeModel.addValidator('AMOUNT', {
            exists: true,
            description: locale.get('filterfield.amount'),
            isLessThan: 'AMOUNT2',
            otherDescription: locale.get('filterfield.amount2'),
        });
        changeModel.addValidator('AMOUNT2', {
            exists: true,
            description: locale.get('filterfield.amount2'),
            isGreaterThan: 'AMOUNT',
            otherDescription: locale.get('filterfield.amount'),
        });
    } else {
        if (changeModel.fieldData?.AMOUNT?.mandatory) {
            changeModel.removeValidatorProp('AMOUNT', 'isLessThan');
        } else {
            changeModel.removeValidator('AMOUNT');
        }
        changeModel.removeValidator('AMOUNT2');
        changeModel.validateField('AMOUNT');
        changeModel.validateField('AMOUNT2');
    }
};

/**
 * When model has changed, check the value and add or
 * remove model validator accordingly
 * @param {Model} changeModel - model that has changed
 * @param {*} value - new value
 * @param {jQuery} view - Dom element for the field
 */
const equalityChange = (changeModel, value, view) => {
    if (util.isEmpty(view.source)) {
        return;
    }

    const fieldName = view.source[0].id.split('-')[0];
    if (value === 'BETWEEN') {
        changeModel.addValidator(fieldName, {
            /**
             * To have the existing validators also present for the  field
             */
            ...changeModel.validators[`${fieldName}`],
            exists: true,
            description: locale.get(`filterfield.${fieldName.toLowerCase()}`),
            isLessThan: `${fieldName}2`,
            otherDescription: locale.get(`filterfield.${fieldName.toLowerCase()}2`),
        });
        changeModel.addValidator(`${fieldName}2`, {
            ...changeModel.validators[`${fieldName}2`],
            exists: true,
            description: locale.get(`filterfield.${fieldName.toLowerCase()}2`),
            isGreaterThan: fieldName,
            otherDescription: locale.get(`filterfield.${fieldName.toLowerCase()}`),
        });
    } else {
        changeModel.removeValidator(fieldName);
        changeModel.removeValidator(`${fieldName}2`);
        changeModel.validateField(fieldName);
        changeModel.validateField(`${fieldName}2`);
    }
};
/**
 * When the model attribute has a value, validate that attribute
 * @param {Object} model - model to evaluate
 * @param {string} field
 */
const amountFieldBlur = (model, field) => {
    if (model.get(field)) {
        model.validateField(field);
    }
};

/**
 * Validate the filter model and return validation status
 * @returns {boolean}
 */
const filterModelIsValid = (region) => {
    if (region
       && region.currentView
       && region.currentView.model) {
        return region.currentView.model.isValid();
    }
    return true;
};

export {
    amountEqualityChange, equalityChange,
    amountFieldBlur,
    filterModelIsValid,
};
