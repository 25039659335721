import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import LimitDataEntitlements from '../../collection/user/limitDataEntitlements';

export default Model.extend({

    initialize(obj) {
        this.set(this.parse(obj));
    },

    defaults() {
        return {
            availableRestrictionAtDataEntitlementLevel: [],
            dataEntitlementsWithLimits: new LimitDataEntitlements(),
            configuredDataType: '',
            functionGroup: null,
            inquiryId: 0,
            keyCol: '',
            type: '',
        };
    },

    parse(response) {
        const {
            availableRestrictionAtDataEntitlementLevel,
            dataEntitlementsWithLimits,
            inquiryId,
            keyCol,
            configuredDataType,
        } = response;
        const limits = new LimitDataEntitlements(
            dataEntitlementsWithLimits,
            {
                inquiryId,
                keyCol,
                configuredDataType,
            },
        );
        limits.availableLimits = availableRestrictionAtDataEntitlementLevel;
        return util.extend(
            {},
            response,
            { dataEntitlementsWithLimits: limits },
        );
    },
});
