var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-section=\"rapid-summary-section\" class=\"section-header\">\n    <span data-hook=\"getSummaryItems\">(1)</span>\n    <span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"appliedItemType") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":37}}}))
    + "</span>\n    <span data-hook=\"getSummaryAmount\" class=\"summary-large-text\">0.00</span>\n    <span data-hook=\"getSummaryCurrency\">USD</span>\n</div>";
},"useData":true});