var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rtp-remittance\">\n     <div class=\"row\">\n         <div data-region=\"remittanceEntryRegion\"></div>\n     </div>\n     <div class=\"rtp-remittnaceButton\">\n        <button class=\"btn btn-secondary btn-xs\" type=\"button\" name=\"close\" data-hook=\"getDoneButton\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"done",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":102},"end":{"line":6,"column":119}}}))
    + "</button>\n     </div>\n</div>\n\n";
},"useData":true});