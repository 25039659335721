var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul class=\"NavTabs nav nav-tabs\"\n                    data-hook=\"getNavTabs\"\n                    id=\"navigation-header\"\n                    role=\"tablist\">\n\n                    <li class=\"NavTabs-item is-active original-tab\" role=\"tab\">\n                        <a class=\"NavTabs-link btn btn-tertiary\"\n                            data-action=\"showRealTimeInquiry\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.realTimeInquiry",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":62},"end":{"line":15,"column":93}}}))
    + "\n                        </a>\n                    </li>\n\n                    <li class=\"NavTabs-item acctGroups-tab\" role=\"tab\">\n                        <a class=\"NavTabs-link btn btn-tertiary\"\n                            data-action=\"showHistoricalInquiry\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cm.historicalInquiry",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":64},"end":{"line":21,"column":97}}}))
    + "\n                        </a>\n                    </li>\n                </ul>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"cm.inquiry.title",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":60}}}))
    + "</h1>\n</div>\n<div class=\"page checkInquiry\">\n    <div class=\"section section-container\">\n        <div class=\"section-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showHistoryTab") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "            <div data-region=\"checkInquiryRegion\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});