import Collection from '@glu/core/src/collection';
import services from 'services';
import Model from './multiFieldElementModel';

export default Collection.extend({

    model: Model,
    url: services.generateUrl('url'),

    initialize() {
        this.sync('get');
    },

    sync(method) {
        if (method === 'get') {
            this.add(this.getModels());
        } else if (method === 'save') {
            this.saveModels();
        }
    },

    getModels() {
        // developer can extend this and define how to get data for model
    },

});
