var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page balance-and-transaction-reporting\">\n\n    <div class=\"section section-container\">\n                <div data-region=\"filter\" class=\"section-body\"></div>\n        </div>\n\n    <div class=\"section section-container\">\n                <div data-region=\"gridAlertRegion\"></div>\n        </div>\n\n        <div class=\"page transactionDetails\">\n            <div class=\"section section-container\">\n                <div class=\"section-header\">\n                    <h3 class=\"as-of-date\"></h3>\n                </div>\n                <div class=\"btn-wrapper hide\" data-hook=\"getBtnWrapper\" aria-hidden=\"true\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":63}}})) != null ? stack1 : "")
    + "\n                </div>\n                <div class=\"section-body\">\n                    <div data-region=\"transactionsGrid\" aria-live=\"polite\"></div>\n                </div>\n            </div>\n        </div>\n</div>\n";
},"useData":true});