import React, {
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { DotDotDotAshIcon } from '@glu/icons-react';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';
import {
  Button, PRIMARY, SECONDARY
} from '@glu/buttons-react';

import styles from './ActionIcon.styles';

const ActionIcon = ({
  classes,
  actionControlDefs,
  bulkActionState,
  setBulkActionState,
  selectedRows,
  data
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!containerRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleItemClick = useCallback(item => e => {
    const cb = () => {
      if (item.isBulkAction) {
        setBulkActionState(item);
      }
      setIsOpen(false);
    };
    if (item.onShow) {
      item.onShow({ event: e, data, selectedRows }).then(cb);
    }
    cb();
  }, [setBulkActionState, data, selectedRows]);

  const removeBulkActionState = useCallback(() => setBulkActionState(null), [setBulkActionState]);

  const handleSubmit = useCallback(e => {
    if (bulkActionState.onSubmit) {
      bulkActionState.onSubmit({ event: e, data, selectedRows }).then(removeBulkActionState);
    }
    removeBulkActionState();
  }, [removeBulkActionState, bulkActionState, data, selectedRows]);

  const handleHide = useCallback(e => {
    if (bulkActionState.onHide) {
      bulkActionState.onHide({ event: e, data, selectedRows }).then(removeBulkActionState);
    }
    removeBulkActionState();
  }, [removeBulkActionState, bulkActionState, data, selectedRows]);

  return (
    <div className={classes.root} ref={containerRef}>
      <DotDotDotAshIcon
        actionable
        className={`${classes.icon} ${isOpen ? classes.iconOpen : undefined}`}
        title={locale.get('actionControlTitle')}
        onClick={() => setIsOpen(o => !o)}
      />
      <div className={`${isOpen ? classes.popoverOpen : classes.popoverClosed} ${classes.popoverTop}`}>
        {actionControlDefs.map((group) => (
          <div key={`${group.label}_actionlink_container`} className={classes.actionSection}>
            <h2>{group.label}</h2>
            <ul>
              {group.items.map((item) => (
                <li key={`${item.label}_actionlink`}>
                  <button
                    type="button"
                    onClick={handleItemClick(item)}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {bulkActionState ? (
        <div className={classes.actionFooter} data-testid="actionFooter">
          <Button
            variant={PRIMARY}
            type="button"
            text={bulkActionState.bulkActionButtonLabel}
            onClick={handleSubmit}
          />
          <Button
            variant={SECONDARY}
            type="button"
            text={locale.get('cancel')}
            onClick={handleHide}
          />
        </div>
      ) : null}
    </div>
  );
};

ActionIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actionControlDefs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          onShow: PropTypes.func
        })
      )
    })
  ),
  bulkActionState: PropTypes.shape({
    bulkActionButtonLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func
  }),
  setBulkActionState: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

ActionIcon.defaultProps = {
  actionControlDefs: [],
  bulkActionState: null
};

export default withStyles(styles)(ActionIcon);
