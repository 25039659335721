import DataAPI from 'common/dynamicPages/api/data';
import PageApi from 'common/dynamicPages/api/view';
import { log } from '@glu/core';
import gridApi from 'common/dynamicPages/api/grid';
import util from '@glu/core/src/util';
import Formatter from 'system/utilities/format';
import RFPTemplateEntryView from 'app/payments/views/requestForPayments/rfpTemplateEntry';

const FREEFORMENTRY = '0';
const TEMPLATEENTRY = '1';
const ENTRYMETHOD2 = '2';

const CopyRFPTmpl = RFPTemplateEntryView.extend({
    ui: util.extend(
        {},
        RFPTemplateEntryView.prototype.ui,
        {
            $setLandingText: '[data-hook="setLandingText"]',
        },
    ),

    initialize(options, ...rest) {
        RFPTemplateEntryView.prototype.initialize.apply(this, [options, ...rest]);
        this.contextDef.createdFrom = '1';
        this.contextDef.copySource = 'request';
        this.contextDef.returnAccordionId = 'rfpTemplates';
        if (options.functionCode === 'REQUEST') {
            this.contextDef.keyOverride = 'requestTemplate_outgoingPaymentRequestTemplates';
            this.contextDef.loadFromPayments = true;
            // Pass the locale key for payments to render correct app resources
            this.contextDef.localeKeyOverride = 'requestTemplate.outgoingPaymentRequestTemplates_';
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.contextDef.serviceName = this.options.serviceName;
            this.contextDef.subType = this.options.subType;
            this.contextDef.createdFrom = '1';
            this.pageView = PageApi.page.get({
                context: this.contextDef,
                model: this.paymentModel,
                hideButtons: true,
                gridApi,
                overrideChildFunctionCode: this.targetFunction,
            });
            this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
            this.listenTo(this.pageView, 'loaded', this.getPaymentMessage);

            this.pageContent.show(this.pageView);
            this.displaySummaryTotal();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const keyListGlobal = ['TNUM', 'UPDATECOUNT__', 'FROMTEMPLATE', 'TARGETFUNCTION'];

        // Set templateServiceName if it doesn't exist.
        if (!this.contextDef.templateServiceName) {
            this.contextDef.templateServiceName = this.contextDef.serviceName;
        }

        const model = DataAPI.model.generateFromKeyList({
            context: {
                serviceName: this.contextDef.serviceName,
            },

            keyList: keyListGlobal,
        });
        this.targetFunction = 'REQTMPL';

        model.set('TNUM', this.contextDef.tnum);
        model.set('UPDATECOUNT__', this.contextDef.updateCount);
        model.set('FROMTEMPLATE', '1');
        model.set('TARGETFUNCTION', this.targetFunction);
        model.set('FUNCTION', this.contextDef.functionCode);
        // read the model
        model.fetch({
            success: (m) => {
                this.modelFetched(m);
            },
            error: (m) => {
                log.error(m);
            },
        });
    },

    /**
     * @name modelFetched
     * @description success handler when a model is fetched (read)
     * for copy as request.  after the source model is read, we need to
     * get the model that is cached for this context (or call getModelInfo if required)
     *
     * @param {object} m - model that was read.
     */
    modelFetched(m) {
        this.model = m;
        let entrymethod = this.contextDef.loadFromPayments ? FREEFORMENTRY : TEMPLATEENTRY;
        if (m.get('TEMPLATEENTRYMETHOD') && m.get('TEMPLATEENTRYMETHOD') === ENTRYMETHOD2) {
            this.model.set('ENTRYMETHOD', ENTRYMETHOD2);
            m.set('ENTRYMETHOD', ENTRYMETHOD2);
            entrymethod = ENTRYMETHOD2;
        }
        // clear template code and template description
        m.set({
            TEMPLATE_CODE: '',
            TEMPLATE_DESCRIPTION: '',
            REQUESTED_AMOUNT: '',
            FULL_AMOUNT: '',
            DISCOUNT_AMOUNT: '',
        });

        // get rfp model need for screen generation
        const modelPromise = DataAPI.model.generate({
            context: {
                functionCode: this.targetFunction,
                serviceName: this.contextDef.serviceName,
                entrymethod,
                subType: this.contextDef.subType,
                createdFrom: this.contextDef.createdFrom,
                copySource: this.contextDef.copySource,
            },
        }, false);

        modelPromise.then((paymentModelParam) => {
            this.modelPromiseCallback(paymentModelParam);

            this.setHasLoadedRequiredData(true);
            this.render();
        }, (err) => {
            // handle errors
            log.error(err.statusText);
        });
    },

    /**
     * populates the model returned from the promise (getModelInfo) with
     * values from the model being copied
     * @param {object} newModel
     */
    modelPromiseCallback(newModel) {
        const requestModel = newModel;
        util.each(this.model.attributes, (value, key) => {
            if (!util.isEmpty(this.model.get(key))) {
                if (util.contains(['REQUESTED_AMOUNT', 'FULL_AMOUNT', 'DISCOUNT_AMOUNT'], key)) {
                    requestModel.set(
                        key,
                        Formatter.formatCurrency(this.model.get(key) || 0),
                    );
                } else {
                    requestModel.set(key, this.model.get(key));
                }
            }
        });
        this.paymentModel = requestModel;
    },
});
export default CopyRFPTmpl;
