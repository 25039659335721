import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormProvider } from '@glu/validation-react';
import { withStyles } from '@glu/theming';
import { Button, PRIMARY, TERTIARY } from '@glu/buttons-react';
import { Dropdown } from '@glu/dropdown-react';
import useLocale from '../../hooks/useLocale';
import useRequest from '../../hooks/useRequest';
import FormField from '../FormField/FormField';
import ActionButtonContainer from '../ActionButtonContainer/ActionButtonContainer';
import Loader from '../Loader/Loader';
import LoadingPage from '../LoadingPage/LoadingPage';
import styles from './DropdownPicker.styles';

const propTypes = {
    cancel: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    service: PropTypes.string.isRequired,
    payload: PropTypes.shape({
        IncludeMapData: PropTypes.number,
        queryCriteria: PropTypes.shape({
            inquiryId: PropTypes.string,
            queryType: PropTypes.string,
            action: PropTypes.shape({
                productCode: PropTypes.string,
                functionCode: PropTypes.string,
            }),
        }),
        formatterType: PropTypes.string,
    }).isRequired,
    defaultSelectMessage: PropTypes.string,
    disableMultiButton: PropTypes.bool,
    showTooltip: PropTypes.bool,
};

const defaultProps = {
    defaultSelectMessage: '',
    disableMultiButton: false,
    showTooltip: true,
};

const DropdownPicker = ({
    cancel,
    submit,
    classes,
    service,
    defaultSelectMessage,
    payload,
    disableMultiButton,
    showTooltip,
}) => {
    const { getLocaleString } = useLocale();

    const [dropdownData, setDropdownData] = useState({});
    const [optionValue, setOptionValue] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const { post } = useRequest();

    useEffect(() => {
        post(service, payload).then((response) => {
            const options = response.queryResponse.QueryData.queryRows.map(item => ({
                /*
                 * Added the parsing because the dropdown was failing with multiple errors.
                 * The component expected the id to be a number.
                 */
                id: parseInt(item.name, 10),
                name: item.label,
            }));

            const message = options.length === 0 ? getLocaleString('common.no.matches') : defaultSelectMessage;

            setDropdownData({
                options,
                placeholder: message,
                multiselect: disableMultiButton,
                showTooltip,
            });

            setIsLoading(false);
        });
    }, []); // eslint-disable-line

    const onDropdownChange = (...selected) => {
        const value = selected?.[1]?.[0]?.id;
        setOptionValue(value);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        submit(optionValue);
    };

    return (
        <Loader isLoading={isLoading} LoadingComponent={LoadingPage}>
            <section className={`section section-container ${classes.dropdownPicker}`}>
                <form onSubmit={onSubmit}>
                    <fieldset>
                        <div className={`row ${classes.pickerRow}`}>
                            <FormField
                                className="col-4 col-xs-12"
                            >
                                <Dropdown
                                    label={getLocaleString('rfp.templatePicker.label')}
                                    name="Dropdown"
                                    onChange={onDropdownChange}
                                    {...dropdownData}
                                />
                            </FormField>

                            <ActionButtonContainer>
                                <Button
                                    variant={PRIMARY}
                                    type="submit"
                                    text={getLocaleString('button.continue')}
                                    disabled={false}
                                />
                                <Button
                                    className={classes.cancel}
                                    variant={TERTIARY}
                                    text={getLocaleString('button.cancel')}
                                    onClick={cancel}
                                />
                            </ActionButtonContainer>
                        </div>
                    </fieldset>
                </form>
            </section>
        </Loader>
    );
};
DropdownPicker.propTypes = propTypes;
DropdownPicker.defaultProps = defaultProps;
const StyledDropdownPicker = withStyles(styles)(DropdownPicker);
export default withFormProvider(StyledDropdownPicker);
