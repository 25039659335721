var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"thisValue") || (depth0 != null ? lookupProperty(depth0,"thisValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"thisValue","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":46}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"startDateRequired") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":63}}})) != null ? stack1 : "")
    + "\">\n    <label for=\"start-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":34}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"scheduler.startDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":68}}}))
    + " <span class=\"sr-only\">("
    + alias4(lookupProperty(helpers,"dateFormat").call(alias1,{"name":"dateFormat","hash":{},"data":data,"loc":{"start":{"line":2,"column":92},"end":{"line":2,"column":106}}}))
    + ")</span></label>\n\n    <input type=\"text\"\n           class=\"form-control js-start-datepicker\"\n           data-hook=\"getStartDatepicker\"\n           id=\"start-date-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":26},"end":{"line":7,"column":33}}}) : helper)))
    + "\"\n           "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasDate") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":11},"end":{"line":8,"column":54}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEnabled") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":59}}})) != null ? stack1 : "")
    + " />\n    <span class=\"help-block\" data-bind=\"model\" data-validate=\"starts\"></span>\n</div>\n";
},"useData":true});