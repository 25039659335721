import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import services from 'services';
import { postData } from 'common/util/services';

export default Collection.extend({

    model: Model,
    url: services.generateUrl('url'),

    /**
     * Delegate server action
     * @param  {string} action Current method for service interaction
     * @return {Promise} Retrieved server data
     */
    sync(action) {
        if (action === 'SAVE') {
            return this.saveData();
        }
        return this.getData();
    },
    /**
     * Save data to backend
     * @return {Promise} Promise with response from server
     */
    saveData() {
        const url = services.generateUrl('segmentation/limits/modifyLimits');
        const data = this.getFormattedCollectionData();

        return postData(url, data);
    },

    /**
     * Get data from backend
     * @return {Promise} Promise with response from server
     */
    getData() {
        const url = services.generateUrl('segmentation/limits/readLimits');
        const data = {
            segmentName: store.get('segmentName'),
        };

        return postData(url, data);
    },

    /**
     * Format data to save to backend
     * @return {array} array of data to save
     */
    getFormattedCollectionData() {
        let limitItems = '';
        const items = util.chain(this.models)
            .map((model) => {
                const parentKeys = util.pick(
                    model.attributes,
                    'productCode',
                    'functionCode',
                    'typeCode',
                    'subTypeCode',
                    'actionMode',
                );
                // model may not have limits as it may be a groupLimit model
                limitItems = model.has('limits') ? model.get('limits') : model.get('groupLimit');
                return limitItems.map(limitItem => util.extend({}, parentKeys, limitItem));
            })
            .flatten(true)
            .filter(limit => !util.isEmpty(limit.value))
            .map(limit => ({
                name: `${limit.productCode}~${limit.functionCode}~${limit.typeCode}~${limit.subTypeCode}~${limit.actionMode}~${limit.restrictionType}~${limit.restrictedColumn}~${limit.restrictionSetId}`,
                value: limit.value,
            }))
            .value();

        items.push({
            name: 'SEGMENTNAME',
            value: store.get('segmentName'),
        });

        return {
            item: items,
        };
    },
});
