import Formatter from 'system/utilities/format';
import Decimals from 'common/dynamicPages/api/decimals';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';

export default {
    // TODO - refactor 2 functions below
    setupInputMaskWithCurrency(view, fieldName, currencyCode) {
        const { fieldData } = view.model;
        const fractionDigits = Decimals.getNumberOfDecimalPlaces(currencyCode);
        const allowMinus = (fieldData[fieldName] && fieldData[fieldName].fieldType !== 'PAMOUNT');
        const $field = view.$(`input[name="${fieldName}"]`);
        const maxLength = $field.attr('data-maxlength');
        if (maxLength) {
            $field.inputmask(
                'number',
                {
                    integerDigits: maxLength - fractionDigits,
                    digits: fractionDigits,
                    allowMinus,
                    positionCaretOnClick: 'none',
                },
            );
        } else {
            $field.inputmask('number');
        }
    },

    setupInputMask(view, fieldName, overrideAllowMinus) {
        const { fieldData } = view.model;
        const fractionDigits = userInfo.getDecimalPlaces();
        let allowMinus;
        const $field = view.$(`input[name="${fieldName}"]`);
        /*
         * overrideAllowMinus should only be set to a valid boolean value
         * if the field is a 'virtual' field, e.g. for filter fields
         */
        if (fieldData && fieldData[fieldName] && fieldData[fieldName].fieldType === 'PAMOUNT') {
            allowMinus = false;
        } else {
            allowMinus = (overrideAllowMinus !== undefined) ? overrideAllowMinus : true;
        }
        const maxLength = $field.attr('data-maxlength');
        if (maxLength) {
            $field.inputmask(
                'number',
                {
                    integerDigits: maxLength - fractionDigits,
                    digits: fractionDigits,
                    allowMinus,
                    positionCaretOnClick: 'none',
                },
            );
        } else {
            $field.inputmask('number');
        }
    },

    setupAmountFormats(view, $alt) {
        // Support an alternate container element
        const $el = $alt || view.$el;

        const { fieldData } = view.model;

        const keypressFunc = function (e) {
            const key = e.keyCode || e.charCode;
            const isPrintableAscii = Formatter.isPrintableAsciiChar(key);
            if (!isPrintableAscii) {
                e.preventDefault();
            }
        };

        const blurFunc = (e) => {
            e.currentTarget.value = Formatter.formatAllAscii(e.currentTarget.value);
        };

        Object.keys(fieldData || {}).forEach((field) => {
            if (fieldData[field].visible
                && (fieldData[field].fieldType === 'AMOUNT' || fieldData[field].fieldType === 'PAMOUNT' || fieldData[field].fieldType === 'CURRENCY')) {
                this.setupInputMask(view, field);
                this.setupInputMask(view, `${field}2`, fieldData[field].fieldType !== 'PAMOUNT');
            } else if (fieldData[field].visible && (fieldData[field].fieldType === 'DECIMAL')) {
                $el.find(`input[name="${field}"]`).inputmask(
                    'decimal',
                    {
                        digits: +serverConfigParams.get('ExchangeRateScale'),
                    },
                );
                $el.find(`input[name="${field}2"]`).inputmask(
                    'decimal',
                    {
                        digits: +serverConfigParams.get('ExchangeRateScale'),
                    },
                );
            }

            // NACHA and NACHAA
            if (fieldData[field].visible && (fieldData[field].fieldType === 'NACHA' || fieldData[field].fieldType === 'NACHAA')) {
                const val = Formatter.formatAllAscii(view.model.get(field));
                view.model.set(field, val);

                $el.find(`[name="${field}"]`).keypress(keypressFunc);
                // covers if someone copy/paste
                $el.find(`[name="${field}"]`).blur(blurFunc);
            }
        });
    },
};
