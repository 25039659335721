import ReactDOM from 'react-dom';
import Layout from '@glu/core/src/layout';
import ItemView from '@glu/core/src/itemView';
import Model from '@glu/core/src/model';
import $ from 'jquery';
import DashboardLayout from 'dashboard/js/DashboardLayout';
import WidgetInjector from 'dashboard/js/widget/WidgetInjector';
import SurrogateWidget from 'dashboard/js/widget/surrogate/SurrogateWidget';
import FrameWidget from 'dashboard/js/widget/iframe/FrameWidget';
import ProxyDivWidget from 'common/workspaces/widgets/proxyDiv';
import TrustedIframeWidgetInterface from 'system/notifications/interfaces/trustedIframeWidget';
import workspaceHelper from 'common/workspaces/api/helper';
import { getWorkspaceComponent, asView } from 'common/util/reactUtil';
import services from 'services';
import { appBus, log } from '@glu/core';
import http from '@glu/core/src/http';
import store from 'system/utilities/cache';
import portalInterface from 'system/notifications/interfaces/portal';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import userInfo from 'etc/userInfo';

import MobileCard from 'components/WorkspaceWidgetCard/WorkspaceWidgetCard';
import mobileUtil from '../../../mobile/util/mobileUtil';
import template from './workspace.hbs';

export default Layout.extend({
    template,

    initialize(options) {
        this.workspaceId = options.id;
        this.returnRoute = options.returnRoute;
        this.widgetLib = [];

        this.setReturnRoute(this.options);

        this.isMobileGrid = mobileUtil.isMobileGridEnabled();

        this.dashboard = new DashboardLayout();
        this.configurePageScroll();

        this.setNotificationData({
            title: 'workspace',
        });
    },

    onBeforeClose() {
        if (this.isMobileGrid) {
            ReactDOM.unmountComponentAtNode(this.el);
        }
    },

    /**
     * If the return route it is in the current workspace,
     * this function scrolls the workspace view after a set duration.
     */
    configurePageScroll() {
        let useScrollYvalueInd;
        let waitTimeSetScrollY;

        if (store.get('last-workspace-route') === this.options.returnRoute) {
            waitTimeSetScrollY = serverConfigParams.get('waitTimeSetScrollY');
            if (waitTimeSetScrollY) {
                waitTimeSetScrollY = parseInt(waitTimeSetScrollY, 10);
            } else {
                // default is 1.5 seconds
                waitTimeSetScrollY = 1500;
            }
            const scrollYvalue = store.get('current-workspace-scrollY');
            useScrollYvalueInd = store.get('use-current-workspace-scrollY');
            if (scrollYvalue && useScrollYvalueInd) {
                if (window.parent === window) {
                    setTimeout(() => {
                        window.scroll(0, scrollYvalue);
                    }, waitTimeSetScrollY);
                } else {
                    setTimeout(() => {
                        portalInterface.send({
                            type: 'page:scroll',
                            scrollY: scrollYvalue,
                        });
                    }, waitTimeSetScrollY);
                }
            }
        }
        store.unset('last-workspace-route');
        store.unset('use-current-workspace-scrollY');
        store.unset('current-workspace-scrollY');
    },

    /**
     * This function sets the return route in the store if passed in to this
     * workspace view
     * If the route is not defined, the route in the store is cleared.
     * @param {object} options - containing the workspace return route
     */
    setReturnRoute(options) {
        if (options.returnRoute) {
            store.set('current-workspace-route', options.returnRoute);
        } else {
            store.unset('current-workspace-route');
        }
    },

    onRender() {
        if (this.isMobileGrid) {
            ReactDOM.render(
                getWorkspaceComponent({
                    workspaceId: this.workspaceId,
                    workspaceName: workspaceHelper.getWorkspaceTitle(this.workspaceId),
                    returnRoute: this.returnRoute,
                }),
                this.el,
            );
            return;
        }
        // grab list of widgets based on workspace id
        const listOfWidgets = workspaceHelper.getWidgetList(this.workspaceId);
        const listOfExternalWidgets = workspaceHelper.getExternalWidgetList(this.workspaceId);

        this.injector = new WidgetInjector({
            collection: workspaceHelper.getGlobalWidgetList(),
        });

        listOfExternalWidgets.each((widget) => {
            if (widget.get('locked') === true) {
                this.injectWidget(widget);
            }
        });

        listOfWidgets.each((widget) => {
            this.injectWidget(widget);
        });

        listOfExternalWidgets.each((widget) => {
            if (widget.get('locked') === false) {
                this.injectWidget(widget);
            }
        });

        this.header.show(this.injector);
        this.workbench.show(this.dashboard);

        this.listenTo(this.injector, 'add:widget', this.handleAddWidget);

        /*
         * NH-15610 - Persisting the changes to the workspace layout.
         * adding the listeners for remove (widget removed from the workspace),
         * change(widget resized) and sort(order of the widget changed).
         * add is handled in the handleAddWidget method.
         */
        if (this.dashboard && this.dashboard.collection) {
            this.listenTo(this.dashboard.collection, 'sort change remove', this.persistWorkspaceChanges);
            this.listenTo(this.dashboard, 'sort:start', this.resizePlaceholder);
            this.listenTo(appBus, 'router:navigate:before', this.saveWindowY);
        }

        this.showNotifications();
    },

    saveWindowY() {
        if (window.parent === window) {
            store.set('current-workspace-scrollY', window.scrollY);
        } else {
            portalInterface.send({
                type: 'peekPortalWindow',
                key: 'scrollY',
            });
        }
    },

    resizePlaceholder(event, ui) {
        ui.placeholder.css('width', this.currentWidgetWidth);
    },

    templateHelpers() {
        const self = this;

        return {
            title() {
                return workspaceHelper.getWorkspaceTitle(self.workspaceId);
            },
        };
    },

    handleAddWidget(model) {
        const self = this;
        const widget = model.get('widget');
        let foundIt = false;

        this.dashboard.collection.each((existingWidget) => {
            if (existingWidget.get('wid') === widget.get('widgetId') && existingWidget.get('external') === widget.get('isExternal')) {
                // already exists
                let regionId = existingWidget.get('rid');
                regionId = regionId.split('_').join('-');
                $('html, body').animate({
                    scrollTop: $(`#dashboard-${regionId}`).offset().top,
                }, {
                    duration: 300,
                    complete() {
                        self
                            .$(`#dashboard-${regionId}`)
                            .fadeIn(100)
                            .fadeOut(100)
                            .fadeIn(100)
                            // Set focus for accessibility reasons.
                            .find(':focusable')
                            .first()
                            .focus();
                    },
                });
                foundIt = true;
            }
        });

        if (!foundIt) {
            if (model.get('widget').get('widgetSize') === null) {
                model.get('widget').set('widgetSize', 'L');
            }
            // when a user 'injects' a widget on a workspace, it should be removable
            model.get('widget').set('locked', false);
            this.injectWidget(model.get('widget'));
            this.dashboard.render();
            // NH-15610 persist the workspace changes only when the widget is added.
            this.persistWorkspaceChanges();
        }
    },

    persistWorkspaceChanges(constructor) {
        const self = this;
        const widgetsInfo = [];
        const jsonData = {};
        const url = services.generateUrl('/workspaces/updateWorkspace');
        jsonData.id = self.id;
        jsonData.widgets = widgetsInfo;

        /*
         * dashboard passes either a collection, model,
         * or undefined during resize, remove, and add actions.
         */
        if (constructor && constructor.attributes) {
            const regionId = constructor.get('rid');
            // the event handler is lost after resizing. re-apply
            $(`#dashboard-${regionId}`).find('.widget-header').on('mouseover', '.widget-container > .widget-header', (ev) => {
                self.currentWidgetWidth = $(ev.currentTarget).closest('.widget-container').css('width');
            });
        }

        self.dashboard.collection.each((existingWidget) => {
            widgetsInfo.push({
                id: existingWidget.get('wid'),
                rowNum: existingWidget.get('position'),
                colNum: 1,
                size: (existingWidget.get('size') === 'large') ? 'L' : 'S',
                external: existingWidget.get('external'),
            });
        });

        http.post(url, jsonData, (result) => {
            workspaceHelper.list.reset();
            workspaceHelper.list.fetch();
            log.debug(result);
        }, (err) => {
            log.error(err);
        });
    },

    getElearningUrl(widget) {
        let ext = '';
        if (widget.get('isExternal')) {
            ext = 'ext';
        }
        return `/${window.Bottomline.appRoot
        }-portal/ELEARN/elearning/${
            widget.get('widgetId')
        }&${encodeURIComponent(userInfo.getMarketSegment())
        }&${ext}`;
    },

    /**
     * If the WorkspaceWidgetCard is to be used, inject that widget
     * @param {Object} widgetInfo - Information necessary for widget injection (destructured)
     * @param {Object} widgetInfo.surrogateWidget - Instance of widget to inject in panel
     * @param {Object} widgetInfo.surrogateWidgetModelOptions - Options used for surrogateWidget
     * @param {Object} widgetInfo.widget - Widget view
     */
    injectMobileCardWidget({
        surrogateWidget,
        surrogateWidgetModelOptions,
        widget,
    }) {
        const mobileSurrogateWidget = new SurrogateWidget({
            model: new Model(surrogateWidgetModelOptions),
        });
        const CardView = asView(MobileCard);
        const componentProps = widget.get('mobileCardProps') || {};
        const widgetName = widget.get('widgetName');
        const { location: { search } } = window;
        let startOpen = false;

        if (search) {
            startOpen = search.substr(1).split('&').reduce((acc, cur) => {
                const parts = cur.split('=');
                if (parts[0] === 'widget' && parts[1] === widgetName) {
                    return true;
                }
                return !!acc;
            }, false);
        }

        mobileSurrogateWidget.setView(new CardView({
            startOpen,
            navigationAction: {
                /*
                 * may need to change so we can define
                 * pieces in the componentProps
                 */
                panelComponent: surrogateWidget,
            },
            widgetAttributes: widget.toJSON(),
            ...componentProps,
        }));
        this.dashboard.inject(mobileSurrogateWidget);
    },

    injectWidget(widget) {
        const helpUrl = false;
        // help removed PCM-1591 start
        // const hideHelp = serverConfigParams.get('hideHelp');

        // if (hideHelp !== 'true' && widget.get('helpPage') && widget.get('helpPage') !== '') {
        // helpUrl = `${window.Bottomline.helpRoot}/${userInfo
        // .get('locale')}${widget.get('helpPage')}`;
        // }
        // if (hideHelp !== 'true' && widget.get('hasElearning')) {
        //    helpUrl = this.getElearningUrl(widget);
        // }
        // help removed PCM-1591 end

        /*
         * NH-173947 - In rare instances, we don't want to show specific widgets if a user is not
         * eligible to view them. Ex: Checkfree users of certain 'levels' are restricted to only
         * seeing allowed portions of some MDF forms.
         */
        const userAttributeToBeTested = widget.get('restrictViewBasedOnUserAttribute');
        const userAttributeValue = userInfo.get(userAttributeToBeTested);
        const restrictWhenIsNot = widget.get('restrictWhenIsNot');
        const restrictWhenIs = widget.get('restrictWhenIs');

        /*
         * If this widget contains criteria that would restrict it, evaluate those criteria
         * and return early without injecting the widget into the dashboard if applicable
         */
        if (userAttributeToBeTested) {
            if (restrictWhenIs && (userAttributeValue === restrictWhenIs)) {
                return;
            }
            if (restrictWhenIsNot && (userAttributeValue !== restrictWhenIsNot)) {
                return;
            }
        }

        if (widget.get('isExternal') === true) {
            const widgetModel = new Model({
                title: widget.get('widgetDescription'),
                did: 0,
                wid: widget.get('widgetId'),
                size: (widget.get('widgetSize') === 'L') ? 'large' : 'medium',
                helpPage: helpUrl,
                external: true,
                locked: widget.get('locked'),
            });

            if (widget.has('height') && widget.get('height') !== 0) {
                widgetModel.set('height', widget.get('height'));
            } else {
                widgetModel.set('height', 'auto');
            }

            if (widget.get('remote') === true) {
                const frameWidget = new FrameWidget({
                    model: widgetModel,
                });

                frameWidget.setSrc(widget.get('dataUrl'));

                if (widget.has('trusted') && widget.get('trusted') === true) {
                    TrustedIframeWidgetInterface.register(widget);
                }

                this.dashboard.inject(frameWidget);
            } else {
                const proxyWidget = new ProxyDivWidget({
                    model: widgetModel,
                    widgetId: widget.get('widgetId'),
                });

                proxyWidget.setSrc(widget.get('dataUrl'));

                this.dashboard.inject(proxyWidget);
            }
        } else {
            /**
             * NH-164267
             * It was observed that the widget passed into this function sometimes
             * had the widget name in widgetName attribute and sometimes in id attribute
             */
            const widgetName = widget.get('widgetName') || widget.get('id');
            const widgetDef = workspaceHelper.publishedWidgets.get(widgetName);
            const surrogateWidgetModelOptions = {
                title: widget.get('widgetDescription'),
                did: 0,
                wid: widget.get('widgetId'),
                size: (widget.get('widgetSize') === 'L') ? 'large' : 'medium',
                helpPage: helpUrl,
                external: false,
                locked: widget.get('locked'),
                hideWidgetHeader: this.isMobileGrid,
            };

            const surrogateWidget = new SurrogateWidget({
                model: new Model(surrogateWidgetModelOptions),
            });

            if (widgetDef) {
                const options = widgetDef.get('options');
                if (this.returnRoute) {
                    options.returnRoute = this.returnRoute;
                }
                if (this.stack) {
                    options.stack = this.stack;
                }
                const View = widgetDef.get('view');
                surrogateWidget.setView(new View(options));

                surrogateWidget.$el.on('mouseover', '.widget-header', (ev) => {
                    this.currentWidgetWidth = $(ev.currentTarget).closest('.widget-container').css('width');
                });

                this.dashboard.inject(surrogateWidget);
            } else {
                const UCView = ItemView.extend({
                    template() {
                        return '<div class="alert alert-warning" role="alert">Under Construction</div>';
                    },
                });
                surrogateWidget.setView(new UCView());

                surrogateWidget.$el.on('mouseover', '.widget-header', (ev) => {
                    this.currentWidgetWidth = $(ev.currentTarget).closest('.widget-container').css('width');
                });

                this.dashboard.inject(surrogateWidget);
            }
        }
    },

    showNotifications() {
        /*
         * This is needed for NH-31349
         * When the user is not entitled for the deeplink accessed,
         * nothing would be loaded into the workspace,
         * this event becomes necessory, if use has the entitlement for the deeplink
         * to access,
         * then the event for the specific deeplink would be sent,
         * so the change probably won't affect those cases.
         */
        this.notifyPageLoaded();
    },
});
