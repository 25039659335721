import { createUseStyles } from '@glu/theming';

const styles = ({ fileUploadReact }) => ({
  select: {
    margin: 0
  },
  input: {
    margin: 0
  },
  rowActions: {
    display: 'flex'
  },
  uploadButton: {
    border: 0,
    background: 'transparent',
    margin: [0, 10, 0, 0],
    padding: 0,
    width: 32,
    height: 32,
    textAlign: 'center',
    cursor: 'pointer',
    '& svg': {
      height: 16
    },
    '&:disabled': {
      cursor: 'not-allowed',
      '& svg': {
        fill: fileUploadReact.disabledColor
      }
    },
    '&:focus': {
      outlineColor: fileUploadReact.focusColor
    }
  },
  removeButton: {
    border: 0,
    background: 'transparent',
    padding: 0,
    width: 32,
    height: 32,
    textAlign: 'center',
    cursor: 'pointer',
    '& svg': {
      height: 16
    },
    '&:focus': {
      outlineColor: fileUploadReact.focusColor
    }
  },
  complete: {
    margin: [0, 10, 0, 0],
    width: 32,
    height: 32,
    '& svg': {
      height: 16,
      margin: [8, 'auto'],
      fill: fileUploadReact.complete
    }
  }
});

export default createUseStyles(styles);
