import Controller from '@glu/core/src/controller';
import services from 'services';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';

const Item = new ((Controller.extend({
    initialize() {},

    getModelJSON(model) {
        if (model instanceof Collection) {
            // we have a collection, this is a file import. let import logic handle it.
            return model.toJSON();
        }

        const json = model.toJSON();

        // ensure date conforms to server date format - MM/DD/YYYY
        if (model.has('EXPECTEDDATE')) {
            json.EXPECTEDDATE = moment(model.get('EXPECTEDDATE'), userInfo.getDateFormat()).format('MM/DD/YYYY');
        }

        if (model.has('RECURRENCEENDDATE')) {
            json.RECURRENCEENDDATE = moment(model.get('RECURRENCEENDDATE'), userInfo.getDateFormat()).format('MM/DD/YYYY');
        }

        if (json.increaseType && json.increaseType === 'percent') {
            json.INCREASEBY += '%';
            delete json.increaseType;
        }

        return json;
    },

    delete(model) {
        this.sendRequest('/cashflow/delete', model);
    },

    create(model) {
        this.sendRequest('/cashflow/save', model);
    },

    update(model) {
        this.sendRequest('/cashflow/update', model);
    },

    batch(model) {
        this.sendRequest('/cashflow/upload', model);
    },

    sendRequest(endPoint, model) {
        const self = this;
        http.post(
            services.generateUrl(endPoint),
            this.getModelJSON(model),
            (response) => {
                model.trigger(
                    'sync',
                    model,
                    response,
                    {
                        source: 'itemService',
                    },
                );
                self.trigger(
                    'sync',
                    model,
                    response,
                    {
                        endPoint,
                    },
                );
            },
            () => {
                model.trigger('error', model);
            },
        );
    },
})))();

export default Item;
