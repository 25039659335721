import $ from 'jquery';
import { util } from '@glu/core';

export default {

    /**
     * @description disables/enables the action buttons (save, cancel)
     * @param {jQuery|jQuery[]} input - jQuery object or Array of objects
     * @param {boolean} disable - true = disabled
     */
    setDisabled(input, disable) {
        const elements = Array.isArray(input) ? input : [input];
        util.compact(elements).forEach((el) => {
            $(el).prop('disabled', disable);
        });
    },
};
