import util from '@glu/core/src/util';
import $ from 'jquery';
import dialog from '@glu/dialog';
import services from 'services';
import alert from '@glu/alerts';
import http from '@glu/core/src/http';
import locale from '@glu/locale';
import constants from 'common/dynamicPages/api/constants';
import PrintExportUtil from 'common/util/printExportUtil';
import systemConfig from 'system/configuration';
import ExportPaymentView from 'app/payments/views/exportPayments';
import ExportDetail from 'app/payments/views/exportDetail';
import helpers from 'components/AsyncReporting/helpers';

const PayPrintExportUtil = util.extend(PrintExportUtil, {

    /**
     * Method is used by the payments list views to display the export options dialog.
     */
    exportPayListView() {
        const exportModel = {};
        this.prepareExportScreenInfo(exportModel);
        const rows = this.gridView.grid.getSelectedRowModels();
        exportModel.enableSel = (rows.length > 0);
        /*
         * if nothing is selected or all selected ones are not nacha or everything in
         * the grid are nacha
         */
        exportModel.enableAll = (rows.length === 0)
            || !exportModel.showDataFormatForSel
            || exportModel.showDataFormatForAll;
        if (this.totalsModel) {
            exportModel.confidentialTransactions = this.totalsModel.get('confidentialTransactions');
            exportModel.nachaTransactions = this.totalsModel.get('nachaTransactions');
        }
        exportModel.selected = rows;
        this.exportPaymentsView = new ExportPaymentView(exportModel);
        this.listenTo(this.exportPaymentsView, 'exportPayments', this.exportPay);
        dialog.open(this.exportPaymentsView);
    },

    /**
     * method gathers the information for the export options dialog
     * @param {Object} exportModelParam - holds the properties for the export dialog
     */
    prepareExportScreenInfo(exportModelParam) {
        const exportModel = exportModelParam;
        let allNachaRowsFound = true;
        let allSelectedNachaRowsFound = true;
        const allRowModels = this.gridView.wrapper.rows.models;
        const selectedRowModels = this.gridView.grid.getSelectedRowModels();

        util.each(selectedRowModels, (model) => {
            if (model.get('SUBTYPE') !== 'NACHA') {
                allSelectedNachaRowsFound = false;
            }
        });
        exportModel.showDataFormatForSel = allSelectedNachaRowsFound;

        util.each(allRowModels, (model) => {
            if (model.get('SUBTYPE') !== 'NACHA') {
                allNachaRowsFound = false;
            }
        });
        exportModel.showDataFormatForAll = allNachaRowsFound;
        if (this.options.smbEmployee) {
            exportModel.showDataFormatForSel = false;
            exportModel.showDataFormatForAll = false;
        }


        if (this.gridView && this.gridView.context && this.gridView.context.actionContext
            && (this.gridView.context.actionContext.productCode === 'RFPILIST' || this.gridView.context.actionContext.productCode === 'RTP' || this.gridView.context.actionContext.productCode === 'ADMPAY')
            && this.gridView.context.actionContext.functionCode === 'REQUEST') {
            exportModel.functioncode = ' RFPI';
        } else {
            exportModel.functioncode = ' Payments';
        }
    },

    /**
     * method invoked when the export is selected from the export options dialog
     * @param {Object} model - holds the properties selected on the export modal.
     */
    exportPay(model) {
        this.buildExportModelPay(model);
        this.doExportPay();
    },

    /**
     * method gathers the information for the export from payments list views
     * @param {Object} model - holds the properties chose on the export dialog
     */
    buildExportModelPay(model) {
        const optionsParam = {
            gridView: this.gridView,
            format: model.get('fileformat') || 'CSV',
            isPayment: true,
        };
        const isTemplate = (this.options.isPaymentView !== undefined
            && !this.options.isPaymentView);

        const exportModel = this.buildExportModel(optionsParam);
        const selectedIds = [];
        let searchFieldName = 'TNUM';
        let dataType = 'number';
        exportModel.expData = model.get('expdata');

        if (model.get('selection') && model.get('selection') !== 'all') {
            const rows = this.gridView.grid.getSelectedRows();
            if (rows.length > 0) {
                for (let i = 0; i < rows.length; i += 1) {
                    const paymodel = this.gridView.wrapper.rows.get(rows[i]);
                    if (exportModel.actionData.typeCode === 'REQIN') {
                        selectedIds.push(paymodel.get((systemConfig.isAdmin() ? 'UNIQUE_ID' : 'MESSAGE_ID')));
                    } else {
                        selectedIds.push(paymodel.get('TNUM'));
                    }
                }
            }
            if (exportModel.actionData.typeCode === 'REQIN') {
                searchFieldName = (systemConfig.isAdmin() ? 'UNIQUE_ID' : 'MESSAGE_ID');
                dataType = 'string';
            } else if (exportModel.expData === 'transaction') {
                if (exportModel.actionData.typeCode === 'REQOUT') {
                    searchFieldName = 'TNUM';
                } else {
                    searchFieldName = 'BATCH_TNUM';
                }
            }
            exportModel.searchFields.push({
                fieldName: searchFieldName,
                operator: 'IN',
                fieldValue: selectedIds,
                dataType,
            });
        }

        exportModel.searchFields = exportModel.searchFields
            .concat(this.addAdditionalSearchFields());

        if (exportModel.expData === 'transaction') {
            // columns are read from db meta data
            if (exportModel.actionData.typeCode === 'REQIN') {
                if (exportModel.actionData.productCode === 'ADMPAY') {
                    exportModel.inquiryId = constants.INQUIRY_ID_20119;
                } else {
                    exportModel.inquiryId = constants.INQUIRY_ID_20019;
                }
            } else {
                exportModel.inquiryId = isTemplate ? constants.INQUIRY_ID_20018
                    : constants.INQUIRY_ID_20017;
                /**
                 * InquiryId should not be passed in from the ui when "Transaction Details"
                 * radio button is selected for RFP Export.
                 */
                const { functionCode, productCode, typeCode } = exportModel.actionData;
                if (functionCode === 'REQUEST' && productCode === 'RTP' && typeCode === 'REQOUT') {
                    delete exportModel.inquiryId;
                }
            }
        } else {
            if (this.options.smbEmployee) {
                exportModel.inquiryId = isTemplate ? constants.INQUIRY_ID_20599
                    : constants.INQUIRY_ID_20106;
            }
            // Get Columns and Order
            const columns = [];
            this.gridView.wrapper.columns.each((m) => {
                if (m.get('condition') !== false) {
                    columns.push(m.attributes.field);
                }
            });
            exportModel.columns = columns;
        }
        if (systemConfig.isAdmin() && exportModel.actionData.typeCode === 'REQOUT') {
            if (exportModel.actionData.functionCode === 'REQUEST') {
                exportModel.inquiryId = constants.INQUIRY_ID_28601;
            } else if (exportModel.actionData.functionCode === 'REQTMPL') {
                exportModel.inquiryId = constants.INQUIRY_ID_28602;
            }
        }
        this.exportModel = exportModel;
    },

    /**
     * method shows success message for the export action
     * @param {Object} result - result of the export action
     */
    showExportSuccess(result) {
        this.alertView = alert.success(locale.get('payment.Export'), {
            details: new ExportDetail({
                exportId: result,
            }),
        });

        this.alertRegion.show(this.alertView);
    },

    /**
     * method shows error message for the export action
     */
    showExportError() {
        this.alertView = alert.negative(locale.get('payment.export.error.message'), {
            title: locale.get('payment.export.error.title'),
        });

        this.alertRegion.show(this.alertView);
    },

    /*
     * function to perform the CSV export for payments list views
     * @param {Object}  options - holds request data required but the export operation.
     */
    doExportPay() {
        /**
         * If the NACHA export file format is selected,
         * this export will be queued and available at a later time via the Downloads section
         */
        if (this.exportModel.outputFormat === 'NACHA') {
            const url = services.generateUrl(this.nachaExportListViewPath
                || this.exportListViewPath
                || '/export/listView');
            http.post(url, this.exportModel)
                .then(this.showExportSuccess.bind(this), this.showExportError.bind(this));
            return;
        }

        if (!systemConfig.isAdmin()) {
            helpers.handleExportAsyncReport({
                service: this.exportListViewPath || services.runAsyncListViewReport,
                postData: this.exportModel,
            }, dialog);
            return;
        }

        /**
         * If the CSV export file format is selected, this export will be available
         * immediately
         * as an automatic browser file download
         */
        let formMethod = 'get';

        let $exportForm = $('#listViewExportForm');
        if (this.exportListViewPath) {
            formMethod = this.exportListViewPath === '/export/listView' ? 'get' : 'post';
        }

        const url = services.generateUrl(this.exportListViewPath || '/export/listView');

        /*
         * TODO: Injecting HTML has XSS risk. Export & Download should be turned into
         * a reusable component.
         * Inject form if it is missing.
         */
        if (!$exportForm.length) {
            /*
             * Pass HTML to jQuery to turn it directly into a DOM fragment, and reuse the
             * variable for convenience
             */
            $exportForm = $(`<form id="listViewExportForm" action="${url}" method="${formMethod}" target="_self"><input id="listViewRequest" type="hidden" name="listViewRequest" ></form>`);

            this.$el.append($exportForm);
        }

        $exportForm.find('input').val(JSON.stringify(this.exportModel));
        $exportForm.submit();
    },

    getPrintOptions() {
        return {};
    },

});

export default PayPrintExportUtil;
