import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import Entitlements from 'app/administration/collection/user2/entitlements';
import groupViewTmpl from './groupView.hbs';
import { updateSelectAllState, onChildrenChange } from '../helpers';

export default Layout.extend({
    template: groupViewTmpl,
    className: 'alert-group',

    ui: {
        $selectAll: '.select-all-perms',
        selectAlert: '[data-hook="alert-checkbox"]',
    },

    events: {
        'click @ui.$selectAll': 'selectAllAlerts',
        'click @ui.selectAlert': 'selectAlert',
    },

    initialize() {
        this.readOnly = this.options.mode === constants.MODES.VIEW;

        this.entitlements = new Entitlements(this.model.get('types').map((type) => {
            const entitlement = type.get('entitlements').at(0);
            entitlement.label = locale.get(type.get('label'));
            return entitlement;
        }));

        if (this.readOnly) {
            this.entitlements
                .reset(this.entitlements.filter(entitlement => entitlement.isEntitled()));
        }
        this.boundUpdateSelectAllState = this.updateSelectAllState.bind(this);
    },

    onRender() {
        this.updateSelectAllState();
        this.onChildrenChange();
    },

    onChildrenChange() {
        onChildrenChange(this, this.getCheckboxList());
    },

    updateSelectAllState() {
        updateSelectAllState(this, this.getCheckboxList());
    },

    getCheckboxList() {
        return this.$el?.find('[data-hook="alert-checkbox"]');
    },

    // manually data bind
    selectAlert(evt) {
        this.entitlements.get(this.$(evt.target).data('id')).set({
            'actions.view.entitled': evt.target.checked,
            'actions.manage.entitled': evt.target.checked,
        });
    },

    selectAllAlerts(evt) {
        const self = this;

        this.entitlements.each((entitlement) => {
            const { checked } = evt.currentTarget;

            entitlement.set({
                'actions.view.entitled': checked,
                'actions.manage.entitled': checked,
            });
            self.$(`#alert-entitlement-${entitlement.cid}`).prop('checked', checked);
        });
        this.onChildrenChange();
    },

    templateHelpers() {
        const self = this;
        return {
            rows: this.entitlements.chain().map(rec => util.extend(
                rec.toJSON(),
                {
                    cid: rec.cid,
                },
            )).groupBy((rec, i) => Math.floor(i / 3)).values()
                .value(),

            readOnly: this.readOnly,

            alertGroup() {
                return locale.get(`uce.alertsGroup.${self.model.get('id')}`);
            },

            entitlementLabel(typeCode) {
                return locale.get(`type.${typeCode}`);
            },

            cid: this.model.cid,
        };
    },
});
