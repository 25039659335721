import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import moment from 'moment';
import dateRowViewTmpl from './dateRowView.hbs';
import PlanningConfiguration from './configuration';

const DateRowView = ItemView.extend({
    tagName: 'tr',
    template: dateRowViewTmpl,

    templateHelpers() {
        const titles = util.map(util.range(1, 13), (i) => {
            const m = PlanningConfiguration.get('fiscalYearStartMonth') + (i - 1);
            return moment(new Date()).month(m).format('MMM');
        }, this);
        return {
            months: titles,
        };
    },
});

export default DateRowView;
