import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import mobileGridDefaultBulkActions from 'common/dynamicPages/api/mobileGridDefaultBulkActions';
import dialog from '@glu/dialog';
import WarningDialog from 'common/dynamicPages/views/warningDialog';
import constants from 'common/dynamicPages/api/constants';
import RecipientGroupAssignList from 'app/administration/views/alerts/recpAssignGrpListView';
import recipientGroupAssignmentModelOverride from 'app/administration/models/recipientGroupAssignment';
import workspaceHelper from 'common/workspaces/api/helper';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';

const RecipientsGroupAssignTab = RecipientGroupAssignList.extend({

    /**
     * @method insert
     * @description override list insert to navigate to recipientGroupAssignment page
     */
    insert() {
        this.navigateTo('ADMINSTRATION/recipientGroupAssignment/insert');
    },
    id: 'recipientGroups',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'recipientGroupsTab',
    },

    /**
     * @method gridRowModify
     * @description override list modify (from context menu).  redirect to
     * recipientGroupAssignment view
     * @param {object} options - options containing the model to modify
     */
    gridRowModify(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/recipientGroupAssignment/modify');
        return Promise.resolve();
    },

    /**
     * @method gridRowSelect
     * @description override list view (from context menu).  redirect to
     * recipientGroupAssignment view
     * @param {object} options - view options containing the model to view
     */
    gridRowSelect(options) {
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('ADMINSTRATION/recipientGroupAssignment/view');
        return Promise.resolve();
    },

    /**
     * @method gridRowDelete
     * @description override list delete(from context menu).
     * @param {object} options - options containing the model to delete
     */
    gridRowDelete(options) {
        return new Promise((resolve, reject) => {
            const optionsParam = options;
            // need to have the model's sync reference the recipientGroup sync
            optionsParam.model = util.extend(
                {},
                optionsParam.model,
                recipientGroupAssignmentModelOverride,
            );

            // adding options.wait = true, will wait for server response before removal
            optionsParam.model.destroy({
                wait: 'true',
                success: (model, resp) => {
                    if (resp.resultType === 'WARNING') {
                        dialog.custom(new WarningDialog({
                            model,
                            methodName: constants.ACTION_DELETE,
                            grid: this.gridView,
                            confirms: resp.confirms,
                        }));
                    } else {
                        this.gridView.refreshGridData();

                        this.renderMessage(optionsParam.action, resp);
                    }
                    resolve({ model, result: resp });
                },
                error: (e) => {
                    this.gridView.refreshGridData();
                    this.renderMessage(optionsParam.action);
                    reject(e);
                },
            });
        });
    },

    /**
     * @method getModelForAction
     * @description returns the model for the selected item
     * override of list.js
     * @param {object} selectedItem - selected row from the grid on which to
     * perform the
     * bulk action
     */
    getModelForAction(selectedItem) {
        const model = this.gridView.wrapper.rows.get(selectedItem);
        return util.extend({}, model, recipientGroupAssignmentModelOverride);
    },

    templateHelpers() {
        const obj = RecipientGroupAssignList.prototype.templateHelpers.call(this);

        return util.extend(obj, {
            hideSubHeader: true,
        });
    },
});

let list = RecipientsGroupAssignTab;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'button.insertinto',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: mobileGridDefaultBulkActions,
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'EMAIL_GROUP_ASSIGNMENT',
    view: list,
    options: {},
});

const exportedList = list;

export default exportedList;
