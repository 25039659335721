import Controller from '@glu/core/src/controller';
import util from '@glu/core/src/util';
import validator from '@glu/core/src/core/internal/validators';

const ValidationController = Controller.extend({
    initialize(options) {
        this.whiteList = options.whiteList;
        this.model = options.model;
        const self = this;
        util.each(this.whiteList, (prop) => {
            self.listenTo(self.model, `change:${prop}`, self.handleChange);
        });
    },

    handleChange() {
        const isValid = this.isValid();

        if (this.lastValid !== isValid) {
            this.trigger('change', isValid);
        }

        this.lastValid = isValid;

        if (isValid) {
            this.trigger('valid', this);
        } else {
            this.trigger('invalid', this);
        }
    },

    isValid() {
        const self = this;
        return util.any(this.whiteList, prop => validator.exists(prop, self.model));
    },
});

export default ValidationController;
