import Collection from '@glu/core/src/collection';
import services from 'services';
import constants from 'common/dynamicPages/api/constants';
import transform from 'common/util/transform';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';

export default Collection.extend({
    /**
     * @name convertModelAttributesToJSON
     * @description transform the model attributes to an array of name values pairs
     * @param model
     * @param method - the action being performed (save, update etc..)
     * @returns {{item: array of NameValuePairs}}
     */
    convertModelAttributesToServerJSON(model, method) {
        const modelAttributes = model.attributes;

        /**
         * for create do not send blank to the server because it is not
         * respecting the default values. instead do not send the field
         * at all.
         */
        if (method === 'save') {
            util.each(modelAttributes, (value, key) => {
                if (!value) {
                    delete modelAttributes[key];
                }
            });
        }

        return {
            item: transform.hashToPairs(modelAttributes).map(field => Object.assign(
                field,
                { value: field.value?.replace?.(/&amp;/g, '&') },
            )),
        };
    },

    /**
     * @name collectData
     * @description creates the request for the multi-save service
     * @param {String} method - the action being performed (save, update etc..)
     * @param {Object} options - options to be included in the server call
     * @returns {Object {"items": [{"item": [{"name": "","value": ""}...]}]} }
     */
    collectData(method, options) {
        return {
            items: this.models.map(model => this.convertModelAttributesToServerJSON(model, method)),
            saveWithWarning: options.saveWithWarning,
            saveAsTemplate: options.saveAsTemplate,
            restrict: options.restrict,
        };
    },

    /**
     * @name save
     * @description performs the collection save (create) operation
     * @param options
     * @returns {promise}
     */
    save(options) {
        return this.sync('save', this, options);
    },

    handleSyncSuccess(options, result) {
        options.success(result);
    },

    handleSyncError(options, result) {
        options.error(result);
    },

    /**
     * @name sync
     * @description collection sync method.  Assembles the post data and then
     * makes a request to the multi-save service
     * @param method
     * @param collection
     * @param options
     */
    sync(method, collection, options) {
        if (method === 'save') {
            // post to the multi-save service
            return http.post(
                services.generateUrl(`${constants.URL_RTP_MULTISAVE}/${options.mode === 'insert' ? 'add' : 'update'}`),
                this.collectData(method, options),
                this.handleSyncSuccess.bind(this, options),
                this.handleSyncError.bind(this, options),
            );
        }
        return undefined;
    },
});
