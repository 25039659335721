var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":18}}}) : helper)))
    + "-manage\" type=\"checkbox\" name=\"manage.entitled\" data-bind=\"model\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableManage") || (depth0 != null ? lookupProperty(depth0,"disableManage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableManage","hash":{},"data":data,"loc":{"start":{"line":2,"column":84},"end":{"line":2,"column":101}}}) : helper)))
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"manageLabel") || (depth0 != null ? lookupProperty(depth0,"manageLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manageLabel","hash":{},"data":data,"loc":{"start":{"line":2,"column":114},"end":{"line":2,"column":129}}}) : helper)))
    + "\">\n<input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":18}}}) : helper)))
    + "-approve\" type=\"checkbox\" name=\"approve.entitled\" data-bind=\"model\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableApprove") || (depth0 != null ? lookupProperty(depth0,"disableApprove") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableApprove","hash":{},"data":data,"loc":{"start":{"line":3,"column":86},"end":{"line":3,"column":104}}}) : helper)))
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"approveLabel") || (depth0 != null ? lookupProperty(depth0,"approveLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"approveLabel","hash":{},"data":data,"loc":{"start":{"line":3,"column":117},"end":{"line":3,"column":133}}}) : helper)))
    + "\">\n<input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":18}}}) : helper)))
    + "-repair\" type=\"checkbox\" name=\"repair.entitled\" data-bind=\"model\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableRepair") || (depth0 != null ? lookupProperty(depth0,"disableRepair") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableRepair","hash":{},"data":data,"loc":{"start":{"line":4,"column":84},"end":{"line":4,"column":101}}}) : helper)))
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"repairLabel") || (depth0 != null ? lookupProperty(depth0,"repairLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"repairLabel","hash":{},"data":data,"loc":{"start":{"line":4,"column":114},"end":{"line":4,"column":129}}}) : helper)))
    + "\">\n<input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":18}}}) : helper)))
    + "-modify\" type=\"checkbox\" name=\"modify.entitled\" data-bind=\"model\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"disableModify") || (depth0 != null ? lookupProperty(depth0,"disableModify") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"disableModify","hash":{},"data":data,"loc":{"start":{"line":5,"column":84},"end":{"line":5,"column":101}}}) : helper)))
    + " aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"modifyLabel") || (depth0 != null ? lookupProperty(depth0,"modifyLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modifyLabel","hash":{},"data":data,"loc":{"start":{"line":5,"column":114},"end":{"line":5,"column":129}}}) : helper)))
    + "\">";
},"useData":true});