import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CheckboxRadio as Checkbox } from '@glu/form-components';
import { Loading } from '@glu/indicators-react';
import locale from '@glu/locale';
import { withStyles } from '@glu/theming';

import LoadMore from '../LoadMore/LoadMore';
import styles from './MobileGrid.styles';

const MobileGrid = ({
  primaryColumns,
  visibleRowsSize,
  classes,
  bulkActionsEnabled,
  onBulkActionSelectAll,
  isSelectAllChecked,
  pageData,
  page,
  pageSize,
  totalRows,
  isPaginating,
  children
}) => {
  const handleCheckboxSelect = useCallback((_name, isChecked, isBlurEvent) => {
    if (isBlurEvent) return;
    onBulkActionSelectAll(isChecked);
  }, [onBulkActionSelectAll]);

  return (
    <div className={classes.rendererRoot} data-qa="mobilegrid">
      <table className={classes.tableMain} role="grid">
        <thead>
          <tr>
            {bulkActionsEnabled ? (
              <th className={classes.bulkActionSvg}>
                <Checkbox
                  htmlId="bulkSelectAll"
                  name="bulkSelectAll"
                  checked={isSelectAllChecked}
                  onChange={handleCheckboxSelect}
                  labelText={locale.get('selectAll')}
                  screenReaderLabel
                />
              </th>
            ) : null}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
            {primaryColumns.map((col) => (
              <th key={`th_${col.field}`}>
                {col.headerDisplay ? col.headerDisplay({ column: col }) : col.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
      <Loading isLoading={isPaginating}>
        <LoadMore
          onLoadMore={pageData}
          page={page}
          pageSize={pageSize}
          totalRows={totalRows}
          visibleRowsSize={visibleRowsSize}
        />
      </Loading>
    </div>
  );
};

MobileGrid.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  primaryColumns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string,
    headerDisplay: PropTypes.func,
    cellDisplay: PropTypes.func,
    hide: PropTypes.bool,
    sortable: PropTypes.bool,
    filterable: PropTypes.bool,
    sortDirection: PropTypes.oneOf(['desc', 'asc']),
    primary: PropTypes.bool
  })).isRequired,
  children: PropTypes.node.isRequired,
  bulkActionsEnabled: PropTypes.bool,
  onBulkActionSelectAll: PropTypes.func,
  isSelectAllChecked: PropTypes.bool,
  pageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  isPaginating: PropTypes.bool.isRequired,
  visibleRowsSize: PropTypes.number.isRequired
};

MobileGrid.defaultProps = {
  bulkActionsEnabled: false,
  onBulkActionSelectAll: undefined,
  isSelectAllChecked: false
};

export default withStyles(styles)(MobileGrid);
