import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import moment from 'moment';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import services from 'services';
import PrintAccountUtil from 'app/balanceAndTransaction/printSingleAccountUtil';
import Constants from 'app/balanceAndTransaction/constants';
import DepositTransactionsListView from './depositAccounts/customReportDepositTransactionListView';
import PartialAccountSummaryView from './partialAccountSummary';
import PartialSummaryBodyView from './partialSummaryBody';
import accountDetailsTmpl from './accountDetails.hbs';

const AccountDetails = Layout.extend({
    template: accountDetailsTmpl,

    events: {
        'click button[data-hook="print-button"]': 'print',
        'click button[data-hook="back-button"]': 'back',
    },

    regions: {
        transactions: '[data-hook="transactions-list-region"]',
        transactionsSplit: '[data-hook="split-transactions-list-region"]',
        accountSummary: '[data-hook="account-summary-region"]',
        summaryBody: '[data-hook="summary-body-region"]',
    },

    className: 'balance-and-transaction-reporting',

    initialize(options) {
        this.filterModel = options.filterModel;
        this.accountDetailServiceName = options.serviceName;
    },

    renderBalances() {
        this.accountSummaryView.render();
        this.summaryBodyView.parseFields();
        this.summaryBodyView.render();
    },

    onRender() {
        const curDate = moment(new Date()).format('MM/DD/YYYY');
        const startDate = moment(this.filterModel.get('START_DATE'));
        const endDate = moment(this.filterModel.get('END_DATE'));
        const setRealTime = (this.model.get('ISREALTIMEACCOUNT') === '1' && ((moment(curDate).isBetween(startDate, endDate)) || moment(curDate).isSame(startDate) || moment(curDate).isSame(endDate)));

        this.summaryBodyView = new PartialSummaryBodyView({
            model: this.model,
            isLoading: true,
        });

        this.accountSummaryView = new PartialAccountSummaryView({
            model: this.model,
        });

        this.accountSummary.show(this.accountSummaryView);

        this.summaryBody.show(this.summaryBodyView);

        this.refreshModel(setRealTime);

        const searchFields = this.options.filterView.getTransactionFilters();

        searchFields.push({
            fieldName: 'AccountFilter',
            fieldValue: [this.model.get('ACCOUNTFILTER')],
            dataType: 'text',
            operator: '=',
        });

        searchFields.push({
            fieldName: 'Post_Date',

            fieldValue: [
                this.options.filterModel.get('START_DATE'),
                this.options.filterModel.get('END_DATE'),
            ],

            dataType: 'date',
            operator: 'BETWEEN',
        });

        if (this.filterModel.get('accountTransactionsView') === 'single') {
            this.transactions.show(new DepositTransactionsListView({
                stack: this.options.workspaceStack,
                serviceName: this.accountDetailServiceName,

                model: new Model({
                    accountFilter: this.model.get('ACCOUNTFILTER'),
                    accountNumber: this.model.get('ACCOUNT_NUM'),
                    isRealTime: setRealTime,
                }),

                additionalSearchFields: searchFields,
                filter: false,
                enableSavedViews: false,
            }));
        } else {
            // 'separate'
            const creditSearchFields = util.clone(searchFields);

            const debitSearchFields = util.clone(searchFields);

            creditSearchFields.push({
                fieldName: 'DR_CR',
                fieldValue: ['CR'],
                dataType: 'text',
                operator: '=',
            });
            debitSearchFields.push({
                fieldName: 'DR_CR',
                fieldValue: ['DR'],
                dataType: 'text',
                operator: '=',
            });

            this.transactions.show(new DepositTransactionsListView({
                stack: this.stack,
                serviceName: this.accountDetailServiceName,

                model: new Model({
                    accountFilter: this.model.get('ACCOUNTFILTER'),
                    accountNumber: this.model.get('ACCOUNT_NUM'),
                    isRealTime: setRealTime,
                    transactionType: 'Credit',
                }),

                additionalSearchFields: creditSearchFields,
                filter: false,
                enableSavedViews: false,
            }));

            this.transactionsSplit.show(new DepositTransactionsListView({
                stack: this.stack,
                serviceName: this.accountDetailServiceName,

                model: new Model({
                    accountFilter: this.model.get('ACCOUNTFILTER'),
                    accountNumber: this.model.get('ACCOUNT_NUM'),
                    isRealTime: setRealTime,
                    transactionType: 'Debit',
                }),

                additionalSearchFields: debitSearchFields,
                filter: false,
                enableSavedViews: false,
            }));
        }
    },

    /**
     * Function to return route to get combined ungrouped accounts.
     *
     * @this {getModelServiceUrl}
     */
    getModelServiceUrl() {
        return services.generateUrl(`${Constants.DEPOSIT_ACCTS_SERVICE_PREFIX}accountSummary/getCombinedUngroupedAccounts`);
    },

    /**
     * Call the function to refresh the model. Set the servcieUrl and set methods to call
     * when successful and when error occurs.
     *
     * @this {refreshModel}
     * @param {boolean} setRealTime set to true if the account is a real time account.
     */
    refreshModel(setRealTime) {
        if (setRealTime) {
            // call this function without the argument
            this.listenTo(
                this.appBus,
                'realTimeTransactions:responseReceived',
                () => this.refreshModel(),
            );
            return;
        }
        this.model.refresh(
            this.options.filterView,
            this.options.filterModel,
            {
                serviceUrl: this.getModelServiceUrl(),
                success: util.bind(this.renderBalances, this),
                error: util.bind(this.renderBalances, this),
            },
        );
    },

    templateHelpers() {
        const disableCombinedAccountPrint = serverConfigParams.get('DisableCombinedAccountPrint');
        return {
            filterStartDate: this.options.filterModel.get('START_DATE'),
            filterEndDate: this.options.filterModel.get('END_DATE'),
            accountName: util.unescape(this.model.get('ACCOUNT_NAME')),
            showAccountPrint: disableCombinedAccountPrint ? disableCombinedAccountPrint === 'false' : true,
        };
    },

    back() {
        this.options.filterView.updateFilters();
        this.stack.pop();
    },

    print() {
        const tranGrid = this.transactions.currentView.gridView.wrapper;
        const tranGridSplit = this.transactionsSplit.currentView ? this.transactionsSplit.currentView.gridView.wrapper : '';
        const clonedFilterModel = this.filterModel.clone();
        const sortFields = [];
        const sortFieldsDebit = [];
        const accountSelectBy = this.filterModel.get('accountSelectBy');

        /*
         * inquiry id used to key into inquiries to query balance data. accountSelectBy is the
         * account filter and can be single, group or bank code.
         */
        const inquiryId = (accountSelectBy === 'group') ? Constants.INQUIRYID_BAL_ACCOUNTGROUP : Constants.INQUIRYID_BAL_ACCOUNT;

        if (tranGrid.sortKey && tranGrid.sortOrder) {
            sortFields.push({
                fieldName: tranGrid.sortKey,
                sortOrder: tranGrid.sortOrder,
            });
        }
        if (tranGridSplit && tranGridSplit.sortKey && tranGridSplit.sortOrder) {
            sortFieldsDebit.push({
                fieldName: tranGridSplit.sortKey,
                sortOrder: tranGridSplit.sortOrder,
            });
        }

        /*
         * account transactions is always a single account lookup. Using cloneFilterModel to
         * not mess with original filter model that displays the filter on screen
         */
        clonedFilterModel.set({
            accountSelectBy: 'number',
            accountSelect: this.model.get('ACCOUNTFILTER').split(),
        });

        PrintAccountUtil.print({
            accountURL: `${Constants.BTR_REPORT_PREFIX}accountReportView`,
            filterModel: clonedFilterModel,
            searchFields: this.options.filterView.getTransactionFilters(),
            inquiryId,
            sortFields,
            sortFieldsDebit,
        });
    },
});

export default AccountDetails;
