import Formatter from '../../system/utilities/format';

const inputMasks = {
    /**
     * Setup an amount input mask on the field provided in the parameters
     * @param {JQuery Object} element - jquery input element
     * @param {Object} options - options for the mask
     */
    setupAmountMask(element, options = {}) {
        const defaultOptions = {
            ...Formatter.getCurrencyMaskOptions(options.autoUnmaskBool, options.hidePrefix),
            integerDigits: 13,
            allowMinus: false,
            positionCaretOnClick: 'none',
            ...options,
        };
        // The maxLength of the field must accomodate punctuation, so add 5
        let maxLength = defaultOptions.integerDigits + defaultOptions.digits + 5;
        // Showing the currency prefix add one more to the max length
        if (!options.hidePrefix) {
            maxLength += 1;
        }
        element.inputmask(
            'number',
            defaultOptions,
        );
        element.attr('maxLength', maxLength);
    },
};
export default inputMasks;
