var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"col-4\">\n                    <div class=\"form-group\">\n                        <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"panelmaint.usergroup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":24,"column":31},"end":{"line":24,"column":64}}}))
    + "</label>\n                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"groupId") || (depth0 != null ? lookupProperty(depth0,"groupId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"groupId","hash":{},"data":data,"loc":{"start":{"line":25,"column":55},"end":{"line":25,"column":66}}}) : helper)))
    + "</p>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp;\n            <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Manage.Approvers",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":88}}}))
    + "</span></a>\n    </div>\n\n    <h1 class=\"landing-header\">\n        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Manage.Approvers",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":54}}}))
    + "\n    </h1>\n</div>\n\n<section class=\"section section-container\">\n    <div class=\"section-body\">\n        <div data-region=\"alertRegion\"></div>\n        <div class=\"row\">\n            <div class=\"col-4\">\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"panelmaint.Update.Approver.Types",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":19},"end":{"line":17,"column":64}}}))
    + "</p>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row\">\n            <div class=\"col-3 column-heading\" id=\"display-name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"panelmaint.approverDisplayName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":64},"end":{"line":32,"column":107}}}))
    + "</div>\n        </div>\n\n        <div data-region=\"collectionRegion\"></div>\n\n    </div>\n    <div class=\"section-footer\">\n        <div class=\"row\">\n            <div class=\"col-12\">\n                <div class=\"widget-action-btn-group\">\n                    <button type=\"button\" class=\"btn btn-primary\" data-hook=\"getSaveButton\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":42,"column":92},"end":{"line":42,"column":116}}}))
    + "</button>\n                    <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"getResetButton\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"panelmaint.button_reset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":95},"end":{"line":43,"column":131}}}))
    + "</button>\n                    <button type=\"button\" class=\"btn btn-secondary\" data-hook=\"getCancelButton\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.Back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":96},"end":{"line":44,"column":117}}}))
    + "</button>\n                </div>\n            </div>\n        </div>\n\n    </div>\n</section>\n";
},"useData":true});