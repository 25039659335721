var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"text-group field-container-lg field-container\">\n                        <label for=\"Restrict\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.panelApproval.Approval.Workflow",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":46},"end":{"line":34,"column":93}}}))
    + ": </label>\n                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"PANELPROFILECODE") || (depth0 != null ? lookupProperty(depth0,"PANELPROFILECODE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"PANELPROFILECODE","hash":{},"data":data,"loc":{"start":{"line":35,"column":55},"end":{"line":35,"column":75}}}) : helper)))
    + "</p>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"savedOptions") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":24},"end":{"line":65,"column":33}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"text-group field-container-lg field-container\">\n                                <label id=\"amount-label\" for=\"credit-amount\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":62,"column":77},"end":{"line":62,"column":86}}}) : helper)))
    + ": </label>\n                                                        <p class=\"form-control-static\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"optionAmount") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":63,"column":87},"end":{"line":63,"column":110}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":63,"column":111},"end":{"line":63,"column":129}}}) : helper)))
    + "</p>\n                            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-group field-container-lg field-container\">\n                            <label for=\"credit-amount\">"
    + alias1(container.lambda(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getAmountText")), depth0))
    + ": </label>\n                                                <p class=\"form-control-static\">"
    + alias1(lookupProperty(helpers,"amount").call(alias2,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":69,"column":79},"end":{"line":69,"column":102}}}))
    + " "
    + alias1(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":69,"column":103},"end":{"line":69,"column":121}}}) : helper)))
    + "</p>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" data-action=\"cancel\" href=\"#\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":179}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">View Template - "
    + alias2(((helper = (helper = lookupProperty(helpers,"getType") || (depth0 != null ? lookupProperty(depth0,"getType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getType","hash":{},"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":58}}}) : helper)))
    + "</h1>\n\n    <div class=\"amount-wrapper\">\n        <p class=\"amount-label-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.paymentTotal",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":69}}}))
    + "</p>\n        <span class=\"amount-value-text total-payments\" data-hook=\"getAmountTotal\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":9,"column":82},"end":{"line":9,"column":105}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":9,"column":106},"end":{"line":9,"column":124}}}) : helper)))
    + "</span>\n    </div>\n</div>\n<div class=\"alert-message\">\n    <div id=\"alertRegion\"  class=\"validation-group\" role=\"alert\"></div>\n</div>\n\n<form class=\"corp-loans-page\">\n    <fieldset class=\"section section-container\">\n            <div class=\"section-header\">\n            <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateInformation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":56}}}))
    + "</legend>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"form-column col-md-6\">\n                                <div class=\"text-group field-container field-container-lg\">\n                                        <label for=\"TEMPLATE_CODE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":67},"end":{"line":25,"column":96}}}))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TEMPLATE_CODE") || (depth0 != null ? lookupProperty(depth0,"TEMPLATE_CODE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"TEMPLATE_CODE","hash":{},"data":data,"loc":{"start":{"line":26,"column":71},"end":{"line":26,"column":88}}}) : helper)))
    + "</p>\n                                </div>\n                                <div class=\"text-group field-container-lg field-container\">\n                                        <label for=\"TEMPLATE_DESCRIPTION\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":74},"end":{"line":29,"column":110}}}))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"TEMPLATE_DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"TEMPLATE_DESCRIPTION") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"TEMPLATE_DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":30,"column":71},"end":{"line":30,"column":95}}}) : helper)))
    + "</p>\n                                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPanelProfileCode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + "                                <div class=\"text-group field-container-lg field-container\">\n                                        <label for=\"Restrict\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.screentext.Restrict",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":62},"end":{"line":39,"column":98}}}))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"getRestricted") || (depth0 != null ? lookupProperty(depth0,"getRestricted") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getRestricted","hash":{},"data":data,"loc":{"start":{"line":40,"column":71},"end":{"line":40,"column":88}}}) : helper)))
    + "</p>\n                                </div>\n                        </div>\n                </div>\n\n        <div class=\"section-header\">\n            <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"gir.advice.transaction.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":16},"end":{"line":46,"column":56}}}))
    + "</h2>\n        </div>\n\n        <div class=\"row\">\n                        <div class=\"form-column col-md-12\">\n                                <div class=\"text-group field-container-lg field-container from-account\">\n                    <label for=\"debit-account\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"originLabel") || (depth0 != null ? lookupProperty(depth0,"originLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"originLabel","hash":{},"data":data,"loc":{"start":{"line":52,"column":47},"end":{"line":52,"column":62}}}) : helper)))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"maskIt")||(depth0 && lookupProperty(depth0,"maskIt"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_NUMBER") : depth0),{"name":"maskIt","hash":{},"data":data,"loc":{"start":{"line":53,"column":71},"end":{"line":53,"column":102}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"getDebitSubaccount") || (depth0 != null ? lookupProperty(depth0,"getDebitSubaccount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getDebitSubaccount","hash":{},"data":data,"loc":{"start":{"line":53,"column":103},"end":{"line":53,"column":125}}}) : helper)))
    + " - "
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_ACCOUNT_TITLE") || (depth0 != null ? lookupProperty(depth0,"DEBIT_ACCOUNT_TITLE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DEBIT_ACCOUNT_TITLE","hash":{},"data":data,"loc":{"start":{"line":53,"column":128},"end":{"line":53,"column":151}}}) : helper)))
    + "</p>\n                </div>\n                    <div class=\"text-group field-container-lg field-container to-account\">\n                        <label for=\"credit-account\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"destinationLabel") || (depth0 != null ? lookupProperty(depth0,"destinationLabel") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"destinationLabel","hash":{},"data":data,"loc":{"start":{"line":56,"column":52},"end":{"line":56,"column":72}}}) : helper)))
    + ": </label>\n                                        <p class=\"form-control-static\">"
    + alias2((lookupProperty(helpers,"maskIt")||(depth0 && lookupProperty(depth0,"maskIt"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"BENE_ACCOUNT") : depth0),{"name":"maskIt","hash":{},"data":data,"loc":{"start":{"line":57,"column":71},"end":{"line":57,"column":94}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"getBeneSubaccount") || (depth0 != null ? lookupProperty(depth0,"getBeneSubaccount") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"getBeneSubaccount","hash":{},"data":data,"loc":{"start":{"line":57,"column":95},"end":{"line":57,"column":116}}}) : helper)))
    + " - "
    + alias2(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":57,"column":119},"end":{"line":57,"column":132}}}) : helper)))
    + "</p>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleOptions") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":71,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"text-group field-container-lg field-container\">\n                            <label for=\"customer-reference\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.customerref",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":73,"column":60},"end":{"line":73,"column":90}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"CUSTOMER_REFERENCE") || (depth0 != null ? lookupProperty(depth0,"CUSTOMER_REFERENCE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CUSTOMER_REFERENCE","hash":{},"data":data,"loc":{"start":{"line":74,"column":59},"end":{"line":74,"column":81}}}) : helper)))
    + "</p>\n                    </div>\n                    <div class=\"payment-comments\">\n                        <div class=\"text-group field-container-lg field-container\">\n                            <label for=\"special-instructions\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Comments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":62},"end":{"line":78,"column":87}}}))
    + ": </label>\n                            <p class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"SPECIAL_INSTRUCTIONS") || (depth0 != null ? lookupProperty(depth0,"SPECIAL_INSTRUCTIONS") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"SPECIAL_INSTRUCTIONS","hash":{},"data":data,"loc":{"start":{"line":79,"column":59},"end":{"line":79,"column":83}}}) : helper)))
    + "</p>\n                        </div>\n                    </div>\n                </div>\n                </div>\n                 <div data-hook=\"getSchedPayRegion\"/>\n\n                <div class=\"row\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"detailAuditHistory"),depth0,{"name":"detailAuditHistory","hash":{"template":"true","containerTitle":"RTGS.TMPL.FEDWIRE.*.AUDIT"},"data":data,"indent":"           ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n    </fieldset>\n    <div class=\"section section-summary total\">\n        <div class=\"section-header\">\n            <h3 class=\"total-heading\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"loans.payment.summary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":38},"end":{"line":92,"column":72}}}))
    + "</h3><h4>\n                    <span data-hook=\"getAmountTotal\" class=\"summary-large-text\">"
    + alias2(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"DEBIT_AMOUNT") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":93,"column":80},"end":{"line":93,"column":103}}}))
    + "</span>\n                    <span data-hook=\"getCurrency\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"DEBIT_CURRENCY") || (depth0 != null ? lookupProperty(depth0,"DEBIT_CURRENCY") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DEBIT_CURRENCY","hash":{},"data":data,"loc":{"start":{"line":94,"column":50},"end":{"line":94,"column":68}}}) : helper)))
    + "</span>\n                <span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.summary.to",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":95,"column":23},"end":{"line":95,"column":54}}}))
    + " </span>\n                    <span data-hook=\"getBeneName\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"BENE_NAME") || (depth0 != null ? lookupProperty(depth0,"BENE_NAME") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"BENE_NAME","hash":{},"data":data,"loc":{"start":{"line":96,"column":50},"end":{"line":96,"column":63}}}) : helper)))
    + "</span>\n            </h4>\n        </div>\n        <div>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":100,"column":80},"end":{"line":100,"column":106}}}))
    + "</button>\n        </div>\n    </div>\n</form>\n";
},"usePartial":true,"useData":true});