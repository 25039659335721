import locale from '@glu/locale';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import util from '@glu/core/src/util';
import systemConfig from 'system/configuration';
import ViewBankReport from './viewBankReport';
import template from './viewScheduledExport.hbs';
import ViewBalanceAndTransaction from './viewBalanceAndTransaction';
import ReadScheduledExportview from './readScheduledExportView';
import ViewLegacyReport from './viewLegacyReport';
import scheduleUtil from '../util';
import useRequest from '../../../hooks/useRequest';

const REPORT_TYPES_ENDPOINT = 'export/scheduledExport/getEntitledReportTypes';
const { post } = useRequest();

export default ReadScheduledExportview.extend({
    template,
    loadingTemplate,

    /**
     * Get reporty types and export file formats and then render the view
     */
    getModelData() {
        ReadScheduledExportview.prototype.getModelData.call(this).then(() => {
            let promises = [
                post(REPORT_TYPES_ENDPOINT, {
                    userGroup: this.model.get('USERGROUP'),
                    userId: this.model.get('USERID'),
                }),
                scheduleUtil.getTransmissionProfiles(this.model.get('USERGROUP')),
            ];
            if (systemConfig.isAdmin()) {
                promises = [
                    ...promises,
                    scheduleUtil.getUser(this.model.get('USERGROUP'), this.model.get('USERID')),
                ];
            }
            Promise.all(promises).then(([
                reportTypeResp,
                transProfileData,
                userData,
            ]) => {
                this.updateUserIdDisplayName(this.model, userData);
                this.setTransmissionProfile(transProfileData);
                this.mappedReportType = reportTypeResp.reportTypeEntitlements.find(type => type.reportType === this.model.get('REPORTTYPE'));
                this.detailView = this.getDetailView(this.model.get('REPORTTYPE'));
                this.setHasLoadedRequiredData(true);
                this.render();
            });
        });
    },

    /**
     * Update the userIdDisplayName of the model from the userData passed
     * @param {Object} model
     * @param {Object} userData
     */
    updateUserIdDisplayName(model, userData = {}) {
        if (!userData.emailEntitlements) {
            return;
        }
        const [user] = userData.emailEntitlements;
        model.set('userIdDisplayName', user.name);
    },

    /**
     * Get the description for the report type if it has one and is entitled to it
     * @param {Object} reportType - selected report type
     * @param {Model} model - current model
     */
    getReportTypeDescription(reportType, model) {
        if (!reportType) {
            return `${model.get('REPORTTYPE')} - ${locale.get('GIR.No.Longer.Available')}`;
        }
        return reportType.reportDescription;
    },

    /**
     * Set the transmission profile attribute of the model
     * @param {Array} transProfileData - array of trans profile data
     */
    setTransmissionProfile(transProfileData) {
        const transProfileId = this.model.get('TRANSMISSIONPROFILEIDENTIFIER');
        if (util.isNullOrUndefined(transProfileId)) {
            return;
        }
        const transProfile = transProfileData
            .find(profile => profile.id === transProfileId.toString());
        if (transProfile) {
            this.model.set('TRANSMISSIONPROFILE', transProfile.name);
        }
    },

    /**
     * Based on the report type create the associated view
     * @param {string} reportType - bank report type
     * @returns {Object}
     */
    getDetailView(reportType) {
        let DetailView;
        switch (reportType) {
        case 'BALANCE_AND_TRANSACTION':
            DetailView = ViewBalanceAndTransaction;
            break;
        case 'LEGACY_REPORTS':
            DetailView = ViewLegacyReport;
            break;
        default:
            DetailView = ViewBankReport;
        }
        return new DetailView({
            model: this.model,
        });
    },

    onRender() {
        /*
         * The prototype ReadScheduledExportview calls render before the data is ready for
         * this view, so make sure this.detailView is available before attempting to render
         */
        if (this.hasLoadedRequiredData() && this.detailView) {
            this.detailRegion.show(this.detailView);
        }
    },

    templateHelpers() {
        return {
            ...ReadScheduledExportview.prototype.templateHelpers.call(this),
            headingText: locale.get('GIR.View.Schedule'),
            reportTypeDesc: this.getReportTypeDescription(this.mappedReportType, this.model),
            isAdmin: systemConfig.isAdmin(),
        };
    },
});
