import dialog from '@glu/dialog';
import PrintExportUtil from 'common/util/printExportUtil';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import PrintFileImportView from 'app/administration/views/fileImport/printFileImport';

const FileImportUtil = {
    isPaymentImport() {
        const { options } = this;
        const { productCode } = options;
        const { importCode } = options;
        return (productCode === 'USACH' || productCode === 'RTGS' || importCode === 'EFT' || importCode === 'USACH' || importCode === 'PAY' || importCode === 'TMPL');
    },

    printFileImport(options) {
        this.options = options;
        if (this.isPaymentImport(options)) {
            this.getFileImportSummaryOrDetail(options);
        } else {
            this.printFileImportSummary(options);
        }
    },

    printFileImportSummary() {
        const { options } = this;
        const printOptions = {
            view: options.view,
            gridView: options.gridView,
            customFilters: options.customFilters,
            inquiryId: options.inquiryId,
            title: options.title,
            format: options.format,
        };

        PrintExportUtil.print(printOptions);
    },

    getFileImportSummaryOrDetail() {
        const printModel = {};
        const self = this;

        this.printFileImportView = new PrintFileImportView(printModel);
        this.options.view.listenTo(this.printFileImportView, 'printFileImportHistory', (model) => {
            const selectedValue = model.get('expdata');
            if (selectedValue === 'summary') {
                self.printFileImportSummary();
            } else {
                self.printFileImportDetails();
            }
        });
        dialog.open(this.printFileImportView);
    },

    printFileImportDetails() {
        const { options } = this;
        const { functionCode } = options;

        const printModal = new PrintViewModal({
            exportModel: {
                outputFormat: 'PDF',
                pageType: 'LETTER',
                expData: 'transaction',
                detailReportId: (functionCode === 'TMPL' || functionCode === 'BHTMPL') ? 50100 : 50005,

                actionData: {
                    productCode: '_UTIL',
                    functionCode: 'HISTORY',
                    typeCode: 'IMPORT',
                },

                additionalParameters: [{
                    name: 'JOBID',
                    value: options.jobId,
                }, {
                    name: 'IMPORTID',
                    value: options.fileImportId,
                }],
            },
        });
        dialog.custom(printModal);
    },
};

export default FileImportUtil;
