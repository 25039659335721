var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"NavTabs-item is-active\" role=\"tab\">\n                <a class=\"NavTabs-link btn btn-tertiary\" id=\"tabItem-"
    + alias2(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":14,"column":69},"end":{"line":14,"column":79}}}) : helper)))
    + "\" tabindex=\"0\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":41}}}))
    + "\n                </a>\n            </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tabPanel\" data-region=\"tabPanelRegion-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":28,"column":62},"end":{"line":28,"column":72}}}) : helper)))
    + "\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--breadcrumbs and title-->\n<div class=\"page-header-wrapper\">\n    <h1 class=\"landing-header\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"getPageTitle") || (depth0 != null ? lookupProperty(depth0,"getPageTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"getPageTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":47}}}) : helper)))
    + "</h1>\n</div>\n\n<!--navigation and alert section-->\n<section class=\"section section-container\" role=\"navigation\">\n\n    <!--navigation tabs-->\n    <div class=\"section-body\">\n        <ul id=\"pmx-reports-tablist\" class=\"NavTabs nav nav-tabs\" data-hook=\"getNavTabs\" role=\"tablist\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tabs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":18,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n\n        <div class=\"alert-message\">\n            <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        </div>\n\n        <!--main section for tab content-->\n        <div class=\"tabPanels\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tabs") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":29,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</section>\n";
},"useData":true});