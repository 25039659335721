const Constants = {
    REPORTING_CONTEXT: 'reporting context',
    REPORTING_VIEWMODEL: 'reporting view model',
    REPORT_READY_EVENT: 'reports:reportReady',
    REPORT_RUNNING_EVENT: 'reports:running',
    REPORT_TIMEOUT_EVENT: 'reports:timeout',
    REPORT_ERROR_EVENT: 'reports:error',
    REPORT_LISTVIEW_ROUTE: 'REPORTING/reportsManagement',
    REPORT_VIEW_ROUTE: 'REPORTING/viewReport',
    LEGACY_REPORT_LISTVIEW_ROUTE: 'REPORTING/legacyReports',
    LEGACY_REPORT_VIEW_ROUTE: 'REPORTING/viewLegacyReport',
    REPORT_NOTENTITLED_ROUTE: 'REPORTING/notentitled',
    STATUS_MESSAGE: 'RPT.reportRunning',
    ERROR_MESSAGE: 'RPT.runFailed',
    STATUS_RETRIEVAL_ERROR_MESSAGE: 'RPT.statusFailed',
    RUN_REQUEST_ERROR_MESSAGE: 'RPT.runRequestFailed',
    RETRIEVAL_ERROR_MESSAGE: 'RPT.retrievalFailed',
    REPORT_READY_MESSAGE: 'RPT.reportReady',
    TIMEOUT_MESSAGE: 'RPT.reportTimeout',
    NO_REPORTS_MESSAGE: 'RPT.noReportsAvailable',
    FILTERID_ATTR: 'FILTERID',
    PROFILENUM_ATTR: 'PROFILENUM',
    RPTID_ATTR: 'ID',
    ASYNC_LOCKBOX_EXPORT_PRINT_URL: 'reporting/executeLockboxListView',
    LOCKBOX_CHECK_EXPORT_INQUIRY: 22469,
    LOCKBOX_CREDIT_EXPORT_INQUIRY: 22470,
    LOCKBOX_DOCONLY_EXPORT_INQUIRY: 22471,
    LOCKBOX_CHECK_GROUPED_EXPORT_INQUIRY: 22489,
    LOCKBOX_CREDIT_GROUPED_EXPORT_INQUIRY: 22490,
    LOCKBOX_DOCONLY_GROUPED_EXPORT_INQUIRY: 22491,
    LOCKBOX_CHECK_GROUPVIEW_EXPORT_INQUIRY: 22499,
    LOCKBOX_CREDIT_GROUPVIEW_EXPORT_INQUIRY: 23490,
    LOCKBOX_DOCONLY_GROUPVIEW_EXPORT_INQUIRY: 23491,
    LOCKBOX_PROFILE_LAST_7: '7',
    LOCKBOX_PROFILE_LAST_30: '30',
    LOCKBOX_PROFILE_LAST_60: '60',
    LOCKBOX_PROFILE_LAST_90: '90',
    LOCKBOX_PROFILE_MONTH_TO_DATE: 'MONTH',
    LOCKBOX_PROFILE_QUARTER_TO_DATE: 'QUART',
    LOCKBOX_PROFILE_YEAR_TO_DATE: 'YEAR',
    LOCKBOX_PROFILE_TODAY: 'TODAY',
    WIRE_DISCLOSURE_PREFIX: 'dynamicreports/',
    LOCKBOX_CREDIT_ENDPOINT: 'creditcardtransactions',
    LOCKBOX_CHECK_ENDPOINT: 'checktransactions',
    LOCKBOX_DOCONLY_ENDPOINT: 'doconlytransactions',
    EXPORT_FORMAT_PDF: 'PDF',
    EXPORT_FORMAT_CSV: 'CSV',
    EXPORT_FORMAT_TXT: 'TXT',
    PAYMENT_DETAIL_REPORT: '40101',
    TEMPLATE_DETAIL_REPORT: '40120',
};

export default Constants;
