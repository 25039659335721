/*
 * Based on https://github.com/mtimofiiv/promise-hash
 * npm promise-hash@1.2.0
 */

/**
 * Accept a hash of promises and return the results in the same hash arrangement if all resolve
 * @param {Object} inputHash - A hash/dictionary of promises
 * @return {Promise}
 */
const promiseHash = (inputHash) => {
    const keys = Object.keys(inputHash);
    return Promise.all(Object.values(inputHash))
        .then(resultArray => resultArray.reduce((acc, val, idx) => ({
            ...acc,
            [keys[idx]]: val,
        }), {}));
};

export default promiseHash;
