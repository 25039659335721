<div data-hook="file-upload">
	<div>
		<div class="form-group">
			<form data-hook="file-upload-data">
				<div class="file-upload">
					<div class="dropzone clickable" style="padding:20px;">
						<span class="dnd-support">
							Drag &amp; drop a file here, or <a href="#">select a file</a> to upload
						</span>
						<label for="a11y-dropzone"></label>
						<input id="a11y-dropzone" type="file" name="file">
					</div>
					<div class="status clearfix hide">
						<div class="status-cell">
							<img class="upload-gif hide" src="images/loading.gif" alt="Loading Image">
							<span class="glyphicon status-icon icon-white"></span>
							<span class="message"></span>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>