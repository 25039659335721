import ItemView from '@glu/core/src/itemView';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import scenarioSelectItemTmpl from './scenarioSelectItem.hbs';

const ScenarioSelectItem = ItemView.extend({
    template: scenarioSelectItemTmpl,
    tagName: 'div',
    className: 'scenario-item',

    attributes() {
        return {
            'data-scenario-id': this.model.cid,
        };
    },

    initialize(options) {
        this.lines = options.lines;
        this.filters = options.filters;

        this.listenTo(
            this.model,
            {
                saveChangesFromEditMode: this.saveChanges,
                change: this.render,
            },
            this,
        );

        this.listenTo(
            this.lines,
            {
                'remove change:name': this.render,
            },
            this,
        );

        this.listenTo(
            this.filters,
            {
                'add refresh remove': util.debounce(this.updateSelectedState, 25),
            },
            this,
        );
    },

    templateHelpers() {
        return {
            id: this.cid,
            preferredCurrencyFormat: userInfo.getCurrencyFormat(),
            isPositive: this.model.get('amount') > 0,
            isNegative: this.model.get('amount') < 0,
            hasTransactions: this.model.get('items') > 0,
            lines: this.lines.getScenarioUses(this.model),
        };
    },

    ui: {
        $remove: '.remove',
        $show: '.show-transactions',
        $renameInput: '.rename-scenario',
    },

    events: {
        'click @ui.$show': 'showTransactions',
        'click @ui.$remove': 'removeItem',
        dragstart: 'setWidth',
    },

    setWidth(e, ui) {
        ui.helper.width(this.$el.width());
    },

    modelEvents: {
        'change:name': 'preserveGridFilter',
        'change add remove': 'render',
    },

    showTransactions() {
        this.model.collection.trigger('show', this.model);
    },

    removeItem(e) {
        this.model.destroy();
        e.stopPropagation();
    },

    saveChanges() {
        const name = this.ui.$renameInput.val().trim();
        if (name !== this.model.get('name') && name.length > 0) {
            this.model.set({
                name,
            });
        } else {
            // reset row to original state
            this.render();
        }

        if (this.model.isNew() || this.model.hasChanged()) {
            this.model.save();
        }
    },

    onRender() {
        this.$el.draggable({
            helper: 'clone',
            appendTo: '.scenario-settings-drawer .manager-main',
        });

        this.updateSelectedState();
    },

    isFilteredInGrid(name) {
        const scenarioFilters = this.filters.where({
            field: 'CASHFLOWSCENARIO',
        });

        const filter = util.find(scenarioFilters, (scenarioFilter) => {
            let values = scenarioFilter.get('value');
            if (!util.isArray(scenarioFilter.get('value'))) {
                values = [values];
            }
            return util.contains(values, name);
        }, this);

        return !util.isUndefined(filter);
    },

    preserveGridFilter() {
        if (this.isFilteredInGrid(this.model.previous('name'))) {
            this.showTransactions();
        }
    },

    updateSelectedState() {
        const selected = this.isFilteredInGrid(this.model.get('name'));
        this.$el.toggleClass('selected', selected);
    },
});

export default ScenarioSelectItem;
