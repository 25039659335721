import ListView from 'common/dynamicPages/views/workflow/list';
import GridApi from 'common/dynamicPages/api/grid';
import constants from 'app/balanceAndTransaction/constants';
import AdditonalInfoCellView from 'app/balanceAndTransaction/views/additionalInfoCellView';
import RefreshTransactionsControl from 'app/balanceAndTransaction/views/refreshTransactionsControl';
import RealTimeTransactionPoller from 'app/balanceAndTransaction/models/realTimeTransactionPoller';
import alert from '@glu/alerts';
import store from 'system/utilities/cache';
import PrintExportUtil from 'common/util/printExportUtil';
import PrintBTRAccountsUtil from 'app/balanceAndTransaction/printBTRaccountsUtil';
import locale from '@glu/locale';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import dialog from '@glu/dialog';
import util from '@glu/core/src/util';
import $ from 'jquery';
import Model from '@glu/core/src/model';
import workspaceHelper from 'common/workspaces/api/helper';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';
import PrintTransactionAdviceDetails from 'app/balanceAndTransaction/views/depositAccounts/printTransactionAdviceDetails';
import helpPageUtil from 'common/util/helpPage';
import AccountTransactionBalanceModel from 'app/balanceAndTransaction/models/accountTransactionBalance';
import AccountTransactionBalanceView from 'app/balanceAndTransaction/views/accountTransactionBalanceDetails';
import BalanceTransactionSummaryView from 'app/balanceAndTransaction/views/balanceTransactionSummary';
import ViewAccountsDropdown from 'app/balanceAndTransaction/views/viewAccountsDropdown';
import GridExportBTRDataView from 'app/balanceAndTransaction/views/exportBTRData/gridExportBTRDataView';
import searchFieldsUtil from 'common/util/searchFieldsUtil';
import btrImageUtil from 'app/balanceAndTransaction/btrImageUtil';
import userInfo from 'etc/userInfo';
import ShortenedCell from 'common/dynamicPages/views/gridCells/shortenedCell';
import configuration from 'system/configuration';
import accountUtil from 'app/smbPayments/util/accountUtil';
import gridUtil from 'app/utilities/views/gridUtil';
import redirectToAssociatedAccountType from 'app/balanceAndTransaction/views/redirectToAssociatedAccountTypeHelper';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';
import { getMaskingProperties, maskValue } from 'common/util/maskingUtil';
import { asView } from 'common/util/reactUtil';
import CloseToaButton from 'components/BalanceAndTransaction/CloseToaButton';
import template from './transactionListView.hbs';

const isClientDeeplink = configuration.isPortal() && configuration.isClient();

const TransactionListView = ListView.extend({
    template,

    events: util.extend(
        {},
        ListView.prototype.events,
        {
            'click [data-hook="export-button"]': 'customExport',
            'click [data-hook="print-button"]': 'customPrint',
        },
    ),

    ui: {
        $refreshTransactionsControls: '[data-hook="getRefreshTransactionsControls"]',
        $exportBtn: '[data-hook="export-button"]',
        $cancelBtn: '[data-hook="cancelBtn"]',
    },

    regions: {
        ...(ListView.prototype.regions || {}),
        alertRegion: '.BalanceTransactionControls ~ [data-region="alertRegion"]',
    },

    /**
     * Returns the context object for this class
     * @abstract
     * @return {object} - the context object
     */
    getContext() {
        throw new Error('This method should be implemented by a subclass.');
    },

    initialize(options) {
        this.options = options;
        this.stack = options.stack;
        this.paginate = options.paginate;
        this.lastFragment = options.lastFragment;
        this.isSmbUser = userInfo && userInfo.isSmbUser();
        this.tabId = this.model.get('tabId');
        this.maskingProperties = getMaskingProperties();
        if (this.tabId) {
            store.set('btrTabId', this.tabId);
        }
        if (!this.accountType) {
            this.accountType = options.accountType;
        }
        this.displayTransactionTotals = applicationConfigParams.getValue('GENERAL', 'DISPLAYTRANSACTIONTOTALS') === '1';
        /*
         * DEPOSIT or LOAN are Account Type groups that categorize a set of different
         * account type codes
         */
        if (this.accountType === 'DEPOSIT') {
            this.currentAccountTypeGroup = constants.ACCOUNT_TYPE.DEPOSIT;
        } else if (this.accountType === 'CREDIT_CARD') {
            this.currentAccountTypeGroup = constants.ACCOUNT_TYPE.CREDIT_CARD;
        } else {
            this.currentAccountTypeGroup = constants.ACCOUNT_TYPE.LOAN;
        }

        // Create Poller for tracking transaction changes and information
        this.poller = new RealTimeTransactionPoller({
            accountFilter: this.model.get('accountFilter'),
            accountType: this.accountType,
            subAccountNumber: this.model.get('noteNumber'),
        });
        ListView.prototype.setListViewConfig.call(this, this.getContext());
        // since each drill down may be different reset to first page & clear filter
        this.lvc.searchRefresh();
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.setPage();
            if (this.model.get('isRealTime')) {
                this.setUpRealTimeUi();
            }
            /**
             *  create account transaction details section if this regions exists. (only
             * in general
             * account transaction list view)
             *  Not used in Tiered Loans, (Scales Reporting) Deposit Account Transaction Details
             */
            if (this.accountTransactionBalanceRegion) {
                this.setupAccountTransactionBalanceDetails();
            }

            /**
             * Create a close account btn for Time Open Accounts
             */
            if (this.closeToaRegion && this.isPriorOrCurrentTab() && this.isTOA()) {
                const CloseToaButtonView = asView(CloseToaButton);
                this.closeToaRegion.show(new CloseToaButtonView({
                    model: this.model,
                    alertRegion: this.alertRegion,
                    onToaTransferSuccess: this.handleToaTransferSuccess.bind(this),
                }));
                if (this.displayTransactionTotals) {
                    this.closeToaRegion.$el.addClass('with-totals');
                }
            }

            if (this.transactionsGrid) {
                if (mobileUtil.isMobileGridEnabled() && !this.options.tieredLoanDetails) {
                    let MobileList = ListView;
                    const mobileList = configureMobileInterface(MobileList);
                    MobileList = MobileList.extend({
                        ...mobileList,
                        gridRowAction: this.gridRowAction,
                    });
                    const listView = new MobileList({
                        ...this.options,
                        ...this.gridViewOptions,
                        prebuiltOptions: true,
                        renderMobileGridUsingTemplate: false,
                        gridView: this.gridView,
                        skipEntitlements: true,
                        disableFilters: false,
                    });
                    // NH-157793 explicility skip the alertRegion for the mobile list
                    listView.skipMobileGridAlertRegion = true;
                    this.transactionsGrid.show(listView);
                } else {
                    this.transactionsGrid.show(this.gridView);
                    this.listenTo(this.gridView, 'rowAction', this.gridRowAction);
                }
            }

            // Not used in Tiered Loans DETAILS view that extends this
            if (applicationConfigParams.getValue('GENERAL', 'DISPLAYVIEWANOTHERACCOUNT') === '1' && this.viewAccountsDropdownRegion) {
                // Setup View Accounts Dropdown, or show if it doesn't exist
                if (!this.viewAccountsDropdown) {
                    this.setupViewAccountDropdown();
                } else {
                    this.viewAccountsDropdownRegion.show(this.viewAccountsDropdown);
                }
            }

            const nonSortableColumns = constants.TRANSACTIONS_GRID_NON_SORTABLE_COLUMNS;
            this.listenTo(this.gridView, 'gridapi:loaded', this.updateTransactionAsOfDate);
            this.listenTo(this.gridView, 'gridapi:loaded', gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
            this.appBus.on(`gridapi:showMessages${this.gridView.cid}`, gridUtil.disableColumnSorting.bind(this, nonSortableColumns, this.gridView));
            ListView.prototype.setupGridAvailableListener.call(this);
            this.startGridRespAlertListeners(this.gridView.cid);
        } else {
            moveToTopCheck(this.model);
            store.unset('helpPage');
            this.loadViewRequirements();
        }
    },

    /**
     * @method handleToaTransferSuccess
     * @param {{action: string, response: object }} alertResponse - alert response object
     * Helper method to check if to display alert message on TOA transfer success
     */
    handleToaTransferSuccess(alertResponse) {
        ListView.prototype
            .renderMessage
            .call(this, alertResponse.action, alertResponse.response);
        if (this.balanceTransactionSummaryView) {
            this.balanceTransactionSummaryView.$el.find('[data-region="alertRegion"]').hide();
        }
    },

    /**
     * @method isPriorOrCurrentTab
     * @returns {boolean}
     * @description Helper method to check if the current account is a Prior / Current Day
     */
    isPriorOrCurrentTab() {
        return [
            constants.ACCOUNT_SUMMARY.TABS.PRIOR,
            constants.ACCOUNT_SUMMARY.TABS.CURRENT,
        ].includes(this.model.get('tabId') || store.get('btrTabId'));
    },

    loadViewRequirements() {
        const helpPagePromise = helpPageUtil.getHelpPagePromise({
            productCode: 'GIR',
            functionCode: 'RPT',
            typeCode: '*',
            mode: 'SELECT',
        });

        return helpPagePromise.then((results) => {
            store.set('helpPage', results.helpPage); // store the help page
            this.setHasLoadedRequiredData(true);
            this.render();
            return {};
        });
    },

    /**
     * @method getRequestParameters
     * Constructs request params object
     * @return {object} request params object to be sent in the getListView payload
     */
    getRequestParameters() {
        return {
            item: [{
                name: 'ACCOUNTTYPE',
                value: this.model.get('accountType') || this.currentAccountTypeGroup[0],
            },
            {
                name: 'SUBACCOUNT_TYPE',
                value: this.model.get('accountSubType') || this.model.get('subAccountType') || this.currentAccountTypeGroup[1],
            },
            {
                name: 'sectionId',
                value: this.model.get('sectionId'),
            },
            {
                name: 'tabId',
                value: this.model.get('tabId'),
            }],
        };
    },

    setPage() {
        const context = this.getContext();
        // Override functionCode for transactions list view, but not for type loans
        if (!this.isLoanAccount()) {
            context.overrideFunctionCode = `${this.model.get('tabId')}${this.model.get('sectionId')}`;
        }
        const options = {
            context,
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: !util.isUndefined(this.options.enableSavedViews)
                ? this.options.enableSavedViews : true,
            filter: !util.isUndefined(this.options.filter) ? this.options.filter : true,
            selector: 'rowSelector',
            additionalSearchFields: this.options.additionalSearchFields || [
                searchFieldsUtil.createSearchField(
                    'AccountFilter',
                    this.model.get('accountFilter'),
                    'uppertext',
                ),
            ],

            requestParameters: this.getRequestParameters(),

            cellViews: {
                ADDITIONAL_INFO: AdditonalInfoCellView,
                PAYMENT_DETAIL: ShortenedCell,
                PAYMENT_DETAIL_ORIG: ShortenedCell,
            },

            lvc: this.lvc,

            /**
             * @method overrideSavedViews
             * This is a temp fix for NH-153358
             * Saved views in transactions list page
             * for Credit Cards should not include "Checks Paid"
             * @param {Collection} savedViews
             * @returns {Collection}
             */
            overrideSavedViews: (savedViews) => {
                const updatedViews = savedViews;
                if (this.isCreditCardAccount()) {
                    updatedViews.models = savedViews.models
                        .filter(model => !constants.CHECKS_PAID_VIEW_ID_LIST
                            .includes(model.get('viewId')));
                }
                return updatedViews;
            },
            sectionId: this.model.get('sectionId'),
            hasRowSelector: true,
        };

        if (this.paginate === false) {
            options.paginate = false;
        }
        this.setAdditionalSearchFields(options);
        this.gridViewOptions = options;
        this.gridView = this.createGridView(options);
    },

    /**
     * @method setAdditionalSearchFields
     * adds search fields to options
     * the code is taken out from setPage function for re-using
     */
    setAdditionalSearchFields(options) {
        const noteNumber = this.model.get('noteNumber');

        if (!util.isEmpty(noteNumber)) {
            options.additionalSearchFields.push(searchFieldsUtil.createSearchField('NOTENUMBER', noteNumber, 'uppertext'));
        }
        if (applicationConfigParams.getValue('RUNBAL', 'DISPLAYRUNBALANCE') !== '1') {
            return;
        }

        const accountFilterParts = this.model.get('accountFilter').split(/-(.+)/);
        if (accountFilterParts.length > 1) {
            options.additionalSearchFields.push(
                searchFieldsUtil.createSearchField('Account_Num', accountFilterParts[1], 'uppertext'),
                searchFieldsUtil.createSearchField('Bank_Code', accountFilterParts[0], 'uppertext'),
            );
        }
    },

    renderMessage(action) {
        if (!(action === null || action === undefined || !this.alertRegion)) {
            const alertFunc = action.type === 'WARNING' ? alert.warning : alert.danger;
            if (action.message) {
                this.alertView = alertFunc(action.message);
                this.alertRegion.show(this.alertView);
            }
        }
    },

    /**
     * @method setupAccountTransactionBalanceDetails
     * Creates and mounts the view needed to show specific Account Transaction details
     * Dependent on type of account selected
     */
    setupAccountTransactionBalanceDetails() {
        /*
         * Service URL to view balance details depends on whether or not the Account
         * Type viewed is LOAN or DEPOSIT
         */
        const modelAttributes = {
            isRealTime: this.model.get('isRealTime'),
            filter: [
                searchFieldsUtil.createSearchField('AccountFilter', this.model.get('accountFilter')),
                {
                    ...this.isSmbUser ? {} : {
                        fieldName: 'TABID',
                        dataType: 'TEXT',
                        operator: 'EQ',
                        fieldValue: [this.model.get('tabId') || store.get('btrTabId')],
                    },
                },
            ],
            accountType: this.model.get('accountType'),
            lastFragment: this.lastFragment,
        };

        // attach child detail view
        if (this.displayTransactionTotals) {
            this.balanceTransactionSummaryView = new BalanceTransactionSummaryView({
                model: new AccountTransactionBalanceModel(modelAttributes),
                poller: this.poller,
                gridView: this.gridView,
                balancesHidden: this.areBalancesHidden(),
                accountType: this.model.get('accountType'),
                tabId: this.model.get('tabId') || store.get('btrTabId'),
            });
            this.accountTransactionBalanceRegion.show(this.balanceTransactionSummaryView);
        } else {
            this.accountTransactionBalanceView = new AccountTransactionBalanceView({
                model: new AccountTransactionBalanceModel(modelAttributes),
                poller: this.poller,
            });
            this.accountTransactionBalanceRegion.show(this.accountTransactionBalanceView);
        }
    },

    /**
     * Helper method that checks if an account is a loan account
     * or not
     * @returns {boolean}
     */
    isLoanAccount() {
        return accountUtil.isLoan(this.model.get('accountType'));
    },

    /**
     * Helper method that checks if an account is a credit card account
     * or not
     * @returns {boolean}
     */
    isCreditCardAccount() {
        return accountUtil.isCreditCardAccount(this.model.get('accountType'));
    },

    /**
     * Helper method that checks if an account is a time open account
     * or not
     * @returns {boolean}
     */
    isTOA() {
        return accountUtil.isTOA(this.model.get('accountSubType'));
    },

    /**
     * Helper method that checks if an account is a credit card or loan account
     * @returns {boolean}
     */
    isLoanOrCreditCardAccount() {
        return this.isLoanAccount() || this.isCreditCardAccount();
    },

    areBalancesHidden() {
        const isPrevDayTab = (this.model.get('tabId')
            || store.get('btrTabId')) === constants.ACCOUNT_SUMMARY.TABS.PRIOR;
        return (isPrevDayTab && !(this.isLoanOrCreditCardAccount()))
            || (isPrevDayTab && this.isLoanOrCreditCardAccount()
                && !this.prevDayTodayBalancesEnabled);
    },

    refreshAccountTransactionBalances() {
        if (this.accountTransactionBalanceView
            && this.accountTransactionBalanceView.model) {
            this.accountTransactionBalanceView.model.fetch();
        }
    },

    setUpRealTimeUi() {
        let errorMessage;

        this.poller.on('responseReceived', () => {
            this.gridView.refreshGridData();
            this.refreshAccountTransactionBalances();
        });

        this.poller.on('change:hasError', () => {
            if (this.poller.hasError()) {
                if (this.$('.fetch-transactions-error').length > 0) {
                    return;
                }
                errorMessage = alert.warning(locale.get('gir.realtimeTransactions.error'));
                const $errorMessage = errorMessage.render().$el;
                $errorMessage.addClass('fetch-transactions-error');
            }
        });

        // Set up Refresh Controls to trigger poller when ready
        this.refreshTransactionsControl = new RefreshTransactionsControl({
            model: this.poller,
            el: this.ui.$refreshTransactionsControls,
            parentGridView: this.gridView,
        });
        this.refreshTransactionsControl.render();

        this.poller.start();
    },

    // overridablehook for unit tests
    createGridView(options) {
        return GridApi.createServiceGridView(options);
    },

    cancel() {
        // NH-171785: Always send user to current workspace when navigate back
        this.lastFragment = store.get('current-workspace-route') || this.options.returnRoute;
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    /*
     * ---------------------------------------------/
     * View Account Dropdown
     * /---------------------------------------------
     */

    setupViewAccountDropdown() {
        this.viewAccountsDropdown = new ViewAccountsDropdown({
            tabId: this.model.get('tabId') || store.get('btrTabId'),
            initialAccount: this.model,
            accountSelectCallback: this.onViewAccountSelection.bind(this),
        });
        this.viewAccountsDropdownRegion.show(this.viewAccountsDropdown);
    },

    /**
     * @method onViewAccountSelection
     * @param {object}  acctSelected
     * Callback method that will check the account selected and if its not a tiered
     * loan selected, will rerender the page,
     * if tiered loan, will direct to tiered loans view and change history
     */
    onViewAccountSelection(acctSelected) {
        const currentAccountType = this.model.get('accountType');

        // Add the lastFragment to the account
        const updatedAccount = {
            ...acctSelected,
            lastFragment: this.lastFragment,
        };

        const tabId = this.model.get('tabId') || store.get('btrTabId');
        // store selected account
        this.storeSelectedAccount(updatedAccount);
        // if account type selected does not match current account type
        if (this.selectedDifferentAccountType(currentAccountType, updatedAccount)) {
            redirectToAssociatedAccountType.call(this, acctSelected, tabId);
        } else {
            this.poller.set({
                accountFilter: acctSelected.ACCOUNTFILTER,
                accountType: acctSelected.ACCOUNTTYPE,
                subAccountNumber: acctSelected.NOTENUMBER,
            });
            this.poller.reset();
            this.setHasLoadedRequiredData(false);
            this.render();
        }
    },

    /**
     * @method selectedDifferentAccountType
     * @param {string} currAccountType
     * @param {object} acctSelected
     * @return {boolean} is account type selected is different
     * Checks if the new account the user selected to view is of a different type
     * than the currently viewed
     */
    selectedDifferentAccountType(currAccountType, acctSelected) {
        /*
         * check if the current account type and type of selected account are under
         * the same parent account type group
         * either under DEPOSIT or LOAN
         */
        return !util.contains(this.currentAccountTypeGroup, acctSelected.ACCOUNTTYPE)
            || (acctSelected.ISTIEREDLOAN);
    },

    /**
     * @method storeSelectedAccount
     * @param {object} acctSelected
     * Stores the user selected account from the account dropdown for display use
     */
    storeSelectedAccount(acctSelected) {
        const newAccount = new Model({
            accountFilter: acctSelected.ACCOUNTFILTER,
            accountNumber: maskValue(acctSelected.ACCOUNTNUM, this.maskingProperties),
            accountName: acctSelected.ACCOUNTNAME,
            accountType: acctSelected.ACCOUNTTYPE,
            currencyCode: acctSelected.CURRENCYCODE,
            clientAccountName: acctSelected.CLIENTACCOUNTNAME,
            subAccountType: acctSelected.ACCOUNTSUBTYPE,
            isRealTime: acctSelected.ISREALTIMEACCOUNT,
            isTieredLoan: acctSelected.ISTIEREDLOAN,
            principalBalance: acctSelected.PRINCIPALBALANCE,
            availableCommitmentAmount: acctSelected.AVAILABLECOMMITMENTAMOUNT,
            accountSubType: acctSelected.ACCOUNTSUBTYPE,
            aba: acctSelected.ABA,
            bankCode: acctSelected.BANKCODE,
            sectionId: acctSelected.SECTIONID,
            tabId: acctSelected.TABID,
            lastFragment: this.lastFragment,
        });

        store.set('balanceAndTransactionAccount', newAccount);
        this.model = newAccount;
    },

    /**
     * @method updateTransactionAsOfDate
     * On retrieving "Transaction as of" data from realtimeTransactions call,
     * will need to update the AccountTransactionBalance Details childview
     */
    updateTransactionAsOfDate() {
        const transactionDateContent = this.gridView.getResponseParameter(constants.AS_OF_DATE);

        // pass Transaction As Of data to child details view, only if can successfully parse
        if (transactionDateContent) {
            try {
                const asOfDate = JSON.parse(transactionDateContent);
                if (this.displayTransactionTotals) {
                    this.balanceTransactionSummaryView.model.set('asOfDate', asOfDate);
                } else {
                    this.accountTransactionBalanceView.model.set('asOfDate', asOfDate);
                }
            } catch (e) {
                // TODO: Is there a catch action?
            }
        }
    },

    gridRowAction(options) {
        const action = options.action.toUpperCase();

        if (action === 'IMAGE') {
            btrImageUtil.getImage(options, this);
        }
        return Promise.resolve();
    },

    /**
     * Gets the value of the suppressDetailedPrintGIR flag from the server
     */
    getSuppressDetailedPrintGIR() {
        return (serverConfigParams.get('suppressDetailedPrintGIR').toUpperCase() === 'TRUE');
    },

    /**
     * @param {string} format
     * @return {object} options
     * Generate the standard options object used for standard Print and Export
     * operations
     */
    getPrintOptions(format) {
        const context = this.getContext();
        return {
            view: this,
            gridView: this.gridView,
            format,
            inquiryId: context.inquiryId,
            hasSummarySelection: this.shouldShowSummarySection(context.typeCode),
            contextData: {
                AccountNumber: this.model.get('accountNumber'),
                tabId: this.model.get('tabId'),
                sectionId: this.model.get('sectionId'),
            },

            exportURL: context.exportPrintURL,
            printSummaryLabel: locale.get('gir.transactionsListView'),
            printDetailsLabel: locale.get('gir.transactionsDetailReport'),
            showIncludeSummaryInfo: true,
            balancesHidden: this.areBalancesHidden(),
        };
    },

    /**
     * @method shouldShowSummarySection
     * Helper method to display/hide print summary section in transactions list view
     * @param {string} typeCode
     * @return {boolean}
     */
    shouldShowSummarySection(typeCode) {
        const transactionTypeCodes = [
            constants.GIRTRANS,
            constants.GIRTRA_P,
            constants.GIRTRA_I,
        ];
        return transactionTypeCodes.indexOf(typeCode) > -1
        && !this.getSuppressDetailedPrintGIR()
        && !this.isLoans()
        && !this.isCreditCardAccount();
    },

    isLoans() {
        return this.isLoan;
    },

    isTieredLoans() {
        return this.isLoans() && !util.isEmpty(this.model.get('noteNumber'));
    },

    /**
     * Print Operation callback
     */
    customPrint() {
        if (this.isTieredLoans()) {
            /*
             * Note do not use this.print as that calls the print defined in the list view
             * not the one in printExportUtil
             */
            PrintExportUtil.print(this.getPrintOptions('PDF'));
        } else {
            this.showPrintOptionsModal('PDF');
        }
    },

    /**
     * @param {object} printModel
     * This function is triggered from the dialog and forward the call to the existing
     * printTransactions function.
     */
    showPrintViewModal(printModel) {
        const options = util.extend(printModel.toJSON(), this.getPrintOptions());
        this.printTransaction(options);
    },

    /**
     * @param {object} options
     * Individual print method used for transaction list views for standard grid prints
     */
    printTransaction(options) {
        if (options.includeDetails || options.expdata === 'transaction') {
            const reportModel = new Model({
                productCode: 'GIR',
                reportId: PrintBTRAccountsUtil.getPrintReportId(),
                filterData: this.gatherFilters(options),
                viewId: this.getListViewId(),
            });

            // Only send includeSummaryInfo if showIncludeSummaryInfo is true
            if (options.showIncludeSummaryInfo) {
                const { printOptionsModal: { model } } = this;
                reportModel.set('additionalParameters', [{
                    name: 'includeSummaryInfo',
                    value: model.get('includeSummaryInfo') ? 1 : 0,
                }]);
            }
            dialog.custom(new PrintTransactionAdviceDetails({
                model: reportModel,
            }));
        } else {
            const exportModel = this.buildPrintModel(
                new Model(options),
                options.gridView,
                options.inquiryId,
            );
            exportModel.columns = this.fixExportColumns(exportModel.columns);
            const printModal = new PrintViewModal({
                exportModel,
                exportURL: options.exportURL,
            });
            dialog.custom(printModal);
        }
    },

    /**
     * Extending original buildPrintModel method used by PrintExportUtil (extended in ListView)
     * Will edit the model created to use full details columns
     */
    buildPrintModel(model, gridView, inquiryId) {
        const { buildPrintModel } = ListView.prototype;
        const printModel = buildPrintModel.call(this, model, gridView, inquiryId);
        printModel.columns = this.fixExportColumns(printModel.columns);
        return printModel;
    },

    /**
     * @method getListViewId
     * returns the listview viewId. Null otherwise
     */
    getListViewId() {
        return this.gridView.wrapper.viewId || null;
    },

    gatherFilters(options) {
        // gather up the filters
        let searchFields = [];

        if (options.selection === 'sel') {
            searchFields = searchFields.concat(this.getSearchFieldsByKeyList(options.gridView));
        }

        // add any filtering
        searchFields = searchFields.concat(options.gridView.wrapper.generateFiltersDataBlock());
        // add posted flag
        searchFields = searchFields.concat(this.getPostedFlagFilter());
        return searchFieldsUtil.convertSearchFieldsToFilterFormat(searchFields);
    },

    /**
     * Creates a posted flag filter based on
     * @return {array} - the posted flag search field or an empty array
     */
    getPostedFlagFilter() {
        if (this.getContext().typeCode === constants.GIRTRA_P) {
            return [searchFieldsUtil.createSearchField('Posted_Flag', 'P')];
        }
        if (this.getContext().typeCode === constants.GIRTRA_I) {
            return [searchFieldsUtil.createSearchField('Posted_Flag', 'I')];
        }
        return [];
    },

    /**
     * Export Operation callback
     */
    customExport() {
        if (this.isLoans()) {
            // grid export that are for LOANS
            this.export(this.getPrintOptions('CSV'));
        } else {
            this.handleExportByConfigParam('CSV', 'suppressDetailedExportGIR');
        }
    },

    /**
     * @method handleExportByConfigParam
     * @param {string} format
     * @param {string} configParam
     * Handles the export operation based on the value of the configParam sent
     */
    handleExportByConfigParam(format, configParam) {
        this.ui.$exportBtn.prop('disabled', true);

        // config param val for supressing detailed report
        const options = this.getPrintOptions(format);

        const configParamValue = serverConfigParams.get(configParam);
        const suppressDetails = configParamValue && configParamValue.toUpperCase() === 'TRUE';

        options.hasSummarySelection = !suppressDetails;
        options.accountType = this.accountType;
        if (suppressDetails) {
            this.showExportBTRView(options);
        } else {
            /*
             * if supressing detailed reports is false, then make sure to add flag t
             * Include Details (allowing more option for user on export)
             */
            this.showExportBTRView(options, true);
        }
    },

    /**
     * @param {object} optionsParam
     * Individual export method used for transaction list views for standard exports
     */
    exportTransaction(optionsParam) {
        const options = optionsParam;
        options.view = this;
        const exportModel = this.buildExportModel(options);

        // If DETAILED REPORTS is allowed, adds extra search fields to export
        if (options.includeDetails) {
            const extraSearchFields = [searchFieldsUtil.createSearchField('includeDetalis', 'true')];
            if (exportModel.searchFields) {
                exportModel.searchFields = exportModel.searchFields.concat(extraSearchFields);
            } else {
                exportModel.searchFields = extraSearchFields;
            }
        }

        exportModel.columns = this.fixExportColumns(exportModel.columns);
        options.exportModel = exportModel;
        this.doExport(options);
    },

    fixExportColumns(columns) {
        const newColumns = [...columns];
        if (columns.indexOf('DISPLAYPAYMENT_DETAIL') > -1) {
            newColumns[columns.indexOf('DISPLAYPAYMENT_DETAIL')] = 'PAYMENT_DETAIL';
        }

        return newColumns.filter(column => (column !== 'ADDITIONAL_INFO') && (column !== 'MESSAGE_STATE'));
    },

    /**
     * @param {string} key
     * @return {object} filter object
     * Go through the visible data columns in the grid and create a filter object
     * out of a common property in the grid. (used for special cases with export)
     */
    getGridExportFilterObjByKey(key) {
        const filterFieldVal = util.chain(this.gridView.grid.collection.models)
            .map(account => account.get(key))
            .uniq()
            .value();

        return {
            fieldName: key,
            fieldValue: filterFieldVal,
        };
    },

    /**
     * @param {object} options
     * @param {boolean} includeDetails
     * Show common Grid Export BTR Data view for export options
     */
    showExportBTRView(options, includeDetails) {
        const initialFilterValues = this.gridView.wrapper.generateFiltersDataBlock();
        const selectedGridRows = this.gridView.grid.getSelectedRows();
        const isLoanGrid = this.isLoans();
        const transactionDescriptionBAIFilter = util.findWhere(
            initialFilterValues,
            {
                fieldName: 'BAI_DESCRIPTION_DISPLAY',
            },
        );
        const permissionsData = store.get('btr:permissions') || {};

        /*
         * Since BAI Code filter is not allows available to user,
         * if the user filtered by Transaction (BAI) Description on the grid,
         * we will need to create a filter object for that transaction's related BAI Code value
         */
        if (transactionDescriptionBAIFilter) {
            initialFilterValues.push(this.getGridExportFilterObjByKey('BAI_CODE'));
        }

        /*
         * create export view
         * If a loan grid type:
         * - will only be given standard export option
         * - will not include detailed report
         */
        this.exportDataView = new GridExportBTRDataView({
            gridExportOptions: options,
            gridExportCallback: this.exportTransaction.bind(this),
            hasSelectedRows: selectedGridRows.length > 0,
            initialFilterValues,
            standardExportOnly: isLoanGrid,
            includeDetails: (!isLoanGrid && includeDetails),
            entitlements: permissionsData.entitlements,
            currentTab: permissionsData.currentTab,
            gridView: this.gridView,
            hasRowSelector: this.gridView.hasRowSelector,
        });

        // on export data success
        this.listenTo(this.exportDataView, 'doExport', function () {
            // Display link to download screen
            const successMessage = locale.get('GIR.output.success').replace('#', ' ');
            let url = this.isSmbUser ? '/PAYMENTS/smbAccountsCenter' : '/REPORTING/btrExport';
            url = isClientDeeplink ? '/REPORTING/download' : url;
            const docRoot = window.Bottomline.documentRoot
                ? `/${window.Bottomline.documentRoot.replace(/^\/|\/$/g, '')}` : '';
            const $message = $('<a/>').attr('href', `${docRoot}/${window.Bottomline.appRoot}${url}`).text(locale.get('GIR.download.view'));

            // Initial message does not have the link because it cannot contain markup.
            ListView.prototype.showAlertMessage.call(this, successMessage, 'success');

            // Find the alert in and update it.
            this.alertView.$el.find('p').text(successMessage).append($message);
        });

        // on export data fail
        this.listenTo(this.exportDataView, 'doExportFailed', function () {
            ListView.prototype.showAlertMessage.call(this, locale.get('payment.export.error.message'), 'danger');
        });

        this.listenToOnce(this.exportDataView, 'close', function () {
            this.ui.$exportBtn.prop('disabled', false);
        });

        dialog.open(this.exportDataView);
    },

    /**
     * @method onClose
     * @description - method that is invoked when the view is closed.
     * If we are not a batch child view, then unset the helpPage that is used for
     * the global help.
     *
     */
    onClose() {
        store.unset('helpPage'); // remove view helppage from cache
    },

    hasBackMethod() {
        return store.has('btr:initialTab')
            || !!this.lastFragment
            || !!this.options?.lastFragment;
    },

    /**
     * provides ability for displayAccountTransactionBalance to be shown or hidden on
     * Transaction drilldown
     * @method templateHelpers
     * @return {boolean} provides ability for displayAccountTransactionBalance to
     * be shown or hidden on Transaction drilldown
     */
    templateHelpers() {
        return {
            displayAccountTransactionBalance: true,

            gridUtilityOptions: {
                hasRefresh: false,
            },
        };
    },
});

export default TransactionListView;
