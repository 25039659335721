var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <h2>Export</h2>\n</div>\n\n<div class=\"alert-region\" data-region=\"alertRegion\"></div>\n<div class=\"grid-region\" data-region=\"gridRegion\"></div>\n\n<div class=\"form-group\">\n    <button type=\"submit\" data-action=\"export\" class=\"btn btn-primary\">\n        <span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":26},"end":{"line":10,"column":52}}}))
    + "</span>\n    </button>\n    <button type=\"cancel\" data-action=\"cancel\" class=\"btn btn-tertiary\">\n        <span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":26},"end":{"line":13,"column":52}}}))
    + "</span>\n    </button>\n</div>\n";
},"useData":true});