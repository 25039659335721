import http from '@glu/core/src/http';
import services from 'services';
import moment from 'moment';
import constants from 'common/dynamicPages/api/constants';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import $ from 'jquery';
import userInfo from 'etc/userInfo';
import PaymentUtil from 'common/util/paymentUtil';
import validators from 'system/gluOverride/core/internal/validators';

export default {

    PAYMENT_SUMMARY_DATE_FORMAT: 'D MMM YYYY',
    overrideDateValidator() {
        validators.matchesDatePattern = (...args) => this.dateValidation(...args);
    },

    dateValidation(key, pat, model) {
        let pattern = pat;
        if (util.isEmpty(model.get(key))) {
            return true;
        }
        if (util.isFunction(pattern)) {
            pattern = pattern(key, pattern, model).toUpperCase();
        } else if (util.isString(pattern)) {
            pattern = pattern.toUpperCase();
        } else {
            pattern = userInfo.getDateFormat();
        }

        if (model?.fieldData?.[key]?.fieldUIType === 'DATEFILTER') {
            const dateList = model.get(key).split(' - ');
            const startDate = moment(dateList[0], pattern);
            const endDate = moment(dateList[1], pattern);

            return startDate.isValid() && endDate.isValid();
        }
        const d = moment(model.get(key), pattern);
        return d.isValid();
    },
    /**
     * Updates the days logic
     * @param {Object} result - AJAX response, probably from URL_GETDATESLIST
     * @param {Object} form - Parent containing the view
     * @param {string} dateId - The DOM element ID of the related datepicker input.
     * @param {boolean} updateDate
     */
    handleBusinessDaysResponse(result, form, dateId, updateDate) {
        const { model } = form.formView;
        const userDateFormat = userInfo.getDateFormat();
        const dates = form.formView.processingDates;
        const state = form.formView.state.toUpperCase();
        const paymentType = model.get('TYPE');
        const isACH = !['RTGS', 'RTP'].includes(model.jsonData.typeInfo.productCode);
        let defaultDay = moment(result.defaultDay);

        /*
         * NH-152833
         * If defaultdate is more than the max amount of forward days for ACH types
         * then we use the ACH max date for the defaultDate
         */
        if (isACH) {
            const maxDateACH = moment(new Date()).add(result.maxForwardDays, 'days');
            defaultDay = moment(defaultDay).isAfter(maxDateACH) ? maxDateACH : defaultDay;
        }

        /*
         * Remove old values and insert new ones without replacing the array.
         * TODO - find out if this is really needed
         */
        dates.daysForward.shift();
        dates.daysForward.push(result.maxForwardDays);

        dates.processingDays.shift();
        dates.processingDays.push(result.businessDays);

        dates.cutOffTimes.shift();
        dates.cutOffTimes.push(result.cutoff);

        // display cutoff if returned and config allows
        if (result.cutoffDateTimeTz && state !== 'VIEW') {
            model.set('CUTOFF_INFO', result.cutoffDateTimeTz);
            PaymentUtil.showCutoff(
                result.cutoffDateTimeTz,
                $('.ui-datepicker-trigger'), paymentType, state, model.get('STATUS'),
            );
        }

        dates.blockedDates.splice(0, dates.blockedDates.length);
        if (result.holidays.length > 0) {
            dates.blockedDates.push(...result.holidays);
        }
        const dateFormElement = form.formView.$el.find(`#${dateId}`);
        if (dateFormElement && dateFormElement.data('daterangepicker')) {
            dateFormElement.data('daterangepicker').updateCalendars({
                blockedDates: dates.blockedDates,
                defaultDay: defaultDay.format(userDateFormat),
                minDaysForward: result.minDaysForward,
                daysForward: dates.daysForward[0],
                daysBack: (result.maxBackwardDays > 0
                    ? result.maxBackwardDays : (result.maxBackwardDays * -1)),
                isACH,
            });
        }
        if (updateDate === true) {
            form.formView.model.set(dateId, moment(result.defaultDay).format(userDateFormat));
            $(`#${dateId}`).trigger('change');
        }
    },

    /**
     * Requests date
     * @param {Object} form
     * @param {String} dateId - The DOM element ID of the related datepicker input.
     * @param {Object} postData - Data to submit ot the getDatesList endpoint
     * @returns {Promise} returns the promise of the http.post, in case we want it.
     */
    setEffectiveDate(form, dateId, postData) {
        const self = this;
        const dateService = services.generateUrl(constants.URL_GETDATESLIST);

        // Return the promise, just in case.
        return http.post(dateService, postData, (result) => {
            self.handleBusinessDaysResponse(result, form, dateId, true);
        }, () => {
            // on error do nothing for now
        });
    },

    /**
     * Requests a datepicker configuration and optionqally updates the date of a datepicker
     * @param {Object} form - Form containing the date picker to be updated
     * @param {type} dateId - DOM element id of the datepicker input
     * @param {type} postData - Data to submit to the date service.
     * @param {type} updateIfStale - If true, update the datepicker input if it is out of date,
     *                  otherwise just refresh the datepicker configuration.
     * @returns {Promise}  returns the promise of the http.post, in case we want it.
     */
    refreshEffectiveDateCalendar(form, dateId, postData, updateIfStale) {
        const self = this;
        const dateService = services.generateUrl(constants.URL_GETDATESLIST);

        // Return the promise, just in case.
        return http.post(dateService, postData, (result) => {
            let updateDate = false;

            if (updateIfStale) {
                const currentValueDate = moment(form.formView.model.get(dateId));
                const earliestDate = result.onUs
                    ? moment().startOf('day').add(result.minDaysForward, 'day')
                    : moment(result.earliestDay);
                updateDate = currentValueDate < earliestDate;
            }

            self.handleBusinessDaysResponse(result, form, dateId, updateDate);
        }, () => {
            // on error do nothing for now
        });
    },

    /**
     * Update the model
     * @param {Object} model
     * @param {jQuery} $datepicker - jQuery input for the datepicker
     * @param {Boolean} [justDates] - Optional setting to prevent using the labels
     */
    updateModelDates(model, $datepicker, justDates) {
        const { separator } = $datepicker.data('daterangepicker');

        // does the datepicker always use userInfo.getDateFormat format?
        const rangeString = $datepicker.val();

        const parts = rangeString.split(separator);
        const chosenLabel = $datepicker.data('daterangepicker').chosenLabelOrig;

        const dateKey = util.findKey(this.getDateCodes(), val => val === chosenLabel);

        // Always reset the end date.
        model.set({
            END_DATE: '',
        });

        if (!justDates && dateKey) {
            model.set({
                START_DATE: dateKey,
            });
        // Custom Range or "justDates"
        } else if (parts.length === 1) {
            model.set({
                START_DATE: parts[0],
                END_DATE: parts[0],
            });
        } else if (parts.length === 2) {
            model.set({
                START_DATE: parts[0],
                END_DATE: parts[1],
            });
        }
    },
    /**
     * Get the date codes for labels and calculation
     * @returns {Object}
     */
    getDateCodes() {
        return {
            L7D: locale.get('common.datepicker.last7Days'),
            L30D: locale.get('common.datepicker.last30Days'),
            L60D: locale.get('common.datepicker.last60Days'),
            L90D: locale.get('common.datepicker.last90Days'),
            MTD: locale.get('common.datepicker.monthToDate'),
            QTD: locale.get('common.datepicker.quarterToDate'),
            YTD: locale.get('common.datepicker.yearToDate'),
            CD: locale.get('common.datepicker.today'),
            PBD: locale.get('common.datepicker.priorDay'),
            LM: locale.get('common.datepicker.lastMonth'),
            PD: locale.get('common.datepicker.priorDay'),
            PMS: locale.get('common.datepicker.lastMonth'),
            CMS: locale.get('common.datecode.CMS'),
            CWS: locale.get('common.datecode.CWS'),
            CYS: locale.get('common.datecode.CYS'),
            ND: locale.get('common.datecode.ND'),
            PME: locale.get('common.datecode.PME'),
            PWE: locale.get('common.datecode.PWE'),
            PWS: locale.get('common.datecode.PWS'),
            PYE: locale.get('common.datecode.PYE'),
            PYS: locale.get('common.datecode.PYS'),
            PTW: locale.get('common.datecode.PTW'),
        };
    },

    /**
     * checks if date falls on weekends
     * TODO support bank holidays like PBD filter on server side
     * @param {moment} date
     * @returns {boolean} false if date falls on sat or sun, true otherwise
     */
    isWorkDay(date) {
        const day = date.toDate().getDay();

        return day !== 0 && day !== 6;
    },

    /**
     * Check a date to see if it is in the past, relative to now
     *
     * @param {moment|Date|string} date - date to check against 'now'
     * @param {moment|Date|string} [now] - used as relative 'now'
     * @returns {boolean}
     */
    isValidPastDate(date, now) {
        const first = this.getMoment(date);
        const second = this.getMoment(now);

        return first.diff(second, 'days') < 0;
    },

    /**
     *
     * @param {moment|Date|string} date - check for a moment and always return one
     * @returns {moment}
     */
    getMoment(date) {
        return moment.isMoment(date) ? date : moment(date);
    },

    /**
     * TODO support bank holidays like PBD filter on server side
     * @param {moment|Date|string} [startDate] - Optional date from which to work.
     * @returns {moment} prior day. if prior day on a weekend returns prior non weekend date
     */
    getPriorDate(startDate) {
        const fromDate = moment(startDate || new Date());
        const prevBusinessDate = fromDate.subtract(1, 'day');

        while (!this.isWorkDay(prevBusinessDate)) {
            prevBusinessDate.subtract(1, 'day');
        }
        return prevBusinessDate;
    },

    /**
     * Check for explicit date codes and return the appropriate object.
     * Otherwise, pass the input through.
     *
     * @param {string} date
     * @return {string|moment}
     */
    convertCashFlowCodesToDates(date) {
        switch (date) {
        case 'L7D':
            return moment(new Date()).subtract(7, 'day').format(userInfo.getDateFormat());
        case 'L30D':
            return moment(new Date()).subtract(30, 'day').format(userInfo.getDateFormat());
        case 'L60D':
            return moment(new Date()).subtract(60, 'day').format(userInfo.getDateFormat());
        case 'L90D':
            return moment(new Date()).subtract(90, 'day').format(userInfo.getDateFormat());
        case 'MTD':
            return moment(new Date()).startOf('month').format(userInfo.getDateFormat());
        case 'QTD':
            return moment(new Date()).startOf('quarter').format(userInfo.getDateFormat());
        case 'YTD':
            return moment(new Date()).startOf('year').format(userInfo.getDateFormat());
        case 'CD':
            return moment(new Date()).format(userInfo.getDateFormat());
        case 'PBD':
            return this.getPriorDate().format(userInfo.getDateFormat());
        default:
            // if the date didn't match any code, pass it through
            return date;
        }
    },

    /**
     * Determine a day off based on available data.
     *
     * @param {array} businessDays - seven element array of zeros and ones which indicates
     * it is a business day.
     * @param {array} holidays - array of optional holidays, formatted as moment objects
     * @param {moment} testDate - the date to check, as a moment object.
     * @return {boolean}
     */
    isDayOff(businessDays, holidays, testDate) {
        const dayOfWeek = businessDays[testDate.day()];
        const isNotWorkDay = dayOfWeek === 0 || dayOfWeek === '0';

        const isHoliday = util.some(holidays, date => testDate.isSame(date, 'day'));

        return isNotWorkDay || isHoliday;
    },

    /**
     * Convert all days in an array to moment objects
     * @param {array} dateArray
     * @return {array}
     */
    getArrayOfMoments(dateArray) {
        return util.map(dateArray, date => moment(date));
    },

    /**
     * Adjust a "max days" to compensate for non-business days.
     *
     * @param {array} businessDays - seven element array of zeros and ones which indicates
     * it is a business day.
     * @param {array} holidays - array of optional holidays, formatted as strings
     * @param {Date|moment|string} startDate - the date from which we calculate the max days.
     * @param {number} daysOut - The starting set of days out.
     * @return {number}
     */
    adjustMaxDays(businessDays, holidays, startDate, daysOut) {
        const fromDate = moment.isMoment(startDate) ? startDate : moment(startDate);
        const busDaysArray = util.isArray(businessDays) ? businessDays : businessDays.split('');
        const momentHolidays = this.getArrayOfMoments(holidays);
        const isADayOff = this.isDayOff.bind(this, busDaysArray, momentHolidays);
        let maxDays = daysOut;
        let i = 0;

        // If there are no business days in the businessDays, we would run forever, so don't try
        if (busDaysArray.indexOf(1) === -1 && busDaysArray.indexOf('1') === -1) {
            return maxDays;
        }

        for (i = 0; i < maxDays; i += 1) {
            /*
             * Wrap the fromDate in a new moment(new Date()) or you mutate it,
             * which is bad for accuracy.
             */
            if (isADayOff(moment(fromDate).add(i, 'days'))) {
                // Increase the maxDays every time we hit a non business day.
                maxDays += 1;
            }
        }

        return maxDays;
    },

    /**
     * Find the first business day, going forward or back, inclusive of the startDate.
     *
     * @param {array} businessDays - seven element array of zeros and ones which indicates
     * it is a business day.
     * @param {array} holidays - array of optional holidays, formatted as strings
     * @param {Date|moment|string} startDate - the date from which we calculate the max days.
     * @param {boolean} [decrement]
     * @return {*}
     */
    getFirstBusinessDay(businessDays, holidays, startDate, decrement) {
        // Always wrap in a new moment because we will mutate it.
        const fromDate = moment.utc(startDate);
        const momentHolidays = this.getArrayOfMoments(holidays);
        const busDaysArray = util.isArray(businessDays) ? businessDays : businessDays.split('');
        const isADayOff = this.isDayOff.bind(this, busDaysArray, momentHolidays);
        let daysTried = 0;

        while (isADayOff(fromDate) && daysTried < 45) {
            fromDate.add(decrement ? -1 : 1, 'day');
            daysTried += 1;
        }

        // If we didn't find any in 1.5 months, return the original date.
        if (daysTried === 45) {
            return startDate;
        }

        return fromDate;
    },

    /**
     * Properly process cutoff times
     * @param {string} cutoffString - The cutoff time string.
     * @param {string|Date|moment} testDate - the date to test. We only check the date of this,
     * not the time.
     * @param {string|Date|moment} [now] - Value for now. Defaults to now if not passed.
     * @return {boolean}
     */
    isAfterCutoff(cutoffString, testDate, now) {
        const currentMoment = moment(now || new Date());
        const testMoment = moment.isMoment(testDate) ? testDate : moment(testDate);

        const cutoff = (cutoffString || '').split(':').map(val => (val !== undefined ? parseInt(val, 10) : val));

        const cutoffHour = cutoff[0];
        const cutoffMinute = cutoff[1];
        const currentHour = currentMoment.hour();
        const currentMinute = currentMoment.minute();

        // If the cutoff time is 00:00, it's disabled
        const isCutoffValid = cutoffHour !== undefined
             && cutoffMinute !== undefined && (cutoffHour + cutoffMinute !== 0);

        // Cutoff only matters if we are on today.
        const isSameDay = currentMoment.isSame(testMoment, 'day');

        const isAfterCutoff = (currentHour > cutoffHour
            || (currentHour === cutoffHour && currentMinute >= cutoffMinute));

        return isCutoffValid && isSameDay && isAfterCutoff;
    },

    /**
     * return the earlier of two dates
     *
     * @param {moment|Date|string} date1
     * @param {moment|Date|string} date2
     * @returns {boolean}
     */
    getEarlierDate(date1, date2) {
        const first = this.getMoment(date1);
        const second = this.getMoment(date2);

        return first.diff(second, 'days') < 0 ? date1 : date2;
    },

    /**
     * return the last available date from the right calendar and set it like the end date
     * @param {Array} calendar
     * @param {function} isInvalidDay - validator for invalidness
     * @returns {moment}
     */
    getLastAvailableDate(calendar, isInvalidDay) {
        let lastValidDay = moment();

        for (let row = 0; row < calendar.length; row += 1) {
            for (let col = 0; col < calendar[row].length; col += 1) {
                const day = calendar[row][col];
                lastValidDay = !isInvalidDay(day) ? day : lastValidDay;
            }
        }

        return lastValidDay;
    },

    /**
     * adds a date mask to the date field
     * @param {object} view - view where date field resides
     * @param {string} dateFieldName - name of date field
     */
    maskDate: (view, dateFieldName) => {
        const selector = `[name="${dateFieldName}"]`;
        if (view.$(selector).length > 0) {
            view.$(selector).inputmask({
                mask: userInfo.getDateFormat().replace(/[^-, /]/g, '9'),
                placeholder: userInfo.getDateFormat(),
                onUnMask: (maskedValue) => {
                    if (maskedValue !== userInfo.getDateFormat()) {
                        return maskedValue;
                    }
                    return '';
                },
            });
        }
    },
};
