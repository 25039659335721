import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';
import Icon from './Icon';

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

/**
 * Renders a valid Glu modal
 * @param title {string} The text to display in the modal header
 * @param children The content of the modal body
 * @param onClose {function} The action to perform to close the modal
 * @param onOK {function} A callback to perform when the user clicks the OK button
 * @param okButtonText {string} OK button text
 * @param preventOkClose {boolean} prevent close on OK button click
 * @param loading
 * @param showCancel
 * @param cancelButtonText
 * @param noButtons
 * @returns {*}
 * @example
 *   <button onClick={this.showModal}>Show a popup</button>
 *
 *   showModal() {
 *	   this.setState({
 *		   modal: (
 *			   <Modal title="Hi" onClose={() => this.setState({ modal: null })}>Hi</Modal>
 *		   )
 *	   });
 *   }
 */
export const Modal = ({
    title, children, onClose, onOK, okButtonText, preventOkClose,
    loading, showCancel, cancelButtonText, noButtons, modalClassName,
}) => (
    <Fragment>
        <div className="modal-background" />
        <div className="modal">
            <div className={`modal-content modal-dialog glu-dialog dialog-alert ${modalClassName}`}>
                {title && (
                    <div className="modal-header">
                        <button className="icon-wrapper close" onClick={() => onClose()}>
                            <Icon name="close" />
                        </button>
                        <h4 className="modal-title">{title}</h4>
                    </div>
                )}

                <div className="modal-body">
                    {children}
                </div>

                {!noButtons && (
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary"
                            aria-busy={loading}
                            onClick={() => {
                                onOK();
                                if (!preventOkClose) {
                                    onClose();
                                }
                            }}
                        >
                            {okButtonText}
                        </button>

                        {showCancel ? (
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {cancelButtonText}
                            </button>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    </Fragment>
);

Modal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    preventOkClose: PropTypes.bool,
    showCancel: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    loading: PropTypes.bool,
    onOK: PropTypes.func,
    okButtonText: PropTypes.string,
    noButtons: PropTypes.bool,
    modalClassName: PropTypes.string,
};

Modal.defaultProps = {
    title: null,
    onOK: () => {},
    loading: false,
    okButtonText: locale.get('ok'),
    cancelButtonText: locale.get('cancel'),
    noButtons: false,
    modalClassName: '',
};

/**
 * Higher order component to handle state management for modals shown by stateless components
 * @param WrappedComponent - The component function to wrap
 * @returns {WithModals}
 * @example
 * const MyComponent = ({ showModal, hideModal }) => (
 *   <button onClick={() => showModal(<Modal title="Hi" onClose={hideModal}>Hi</Modal>)}>Show a popup</button>
 * );
 *
 * const ComponentWithModals = withModals(MyComponent);
 *
 * <ComponentWithModals />
 */
export function withModals(WrappedComponent) {
    class WithModals extends PureComponent {
        constructor(props) {
            super(props);

            this.state = { modal: null };

            this.showModal = modal => this.setState({ modal });
            this.hideModal = () => this.setState({ modal: null });
        }

        render() {
            return (
                <Fragment>
                    {this.state.modal}
                    <WrappedComponent
                        {...this.props}
                        showModal={this.showModal}
                        hideModal={this.hideModal}
                    />
                </Fragment>
            );
        }
    }

    WithModals.displayName = `WithModals(${getDisplayName(WrappedComponent)})`;

    return WithModals;
}
// TODO :)
//  @Felipe, look, we already have "withModals" from Artur. We have to refactor/simplify/reuse/etc.
