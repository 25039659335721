import http from '@glu/core/src/http';
import services from 'services';

const url = services.generateUrl('tableMaintenance/getQueryResults');

const data = {
    queryCriteria: {
        subTypeCode: '*',
        fieldName: 'LOCALE',

        action: {
            functionCode: 'MAINT',
            actionMode: 'SELECT',
            productCode: 'CMAINT',
            typeCode: 'CLUSR_TM',
        },
    },
};

export default {
    getLanguages() {
        return new Promise((resolve, reject) => {
            http.post(url, data).then((response) => {
                // send array of languges
                resolve(response.queryResponse.QueryData.queryRows);
            }, (error) => {
                reject(error.message);
            });
        });
    },
};
