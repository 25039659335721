import Layout from '@glu/core/src/layout';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import CollectionView from '@glu/core/src/collectionView';
import workflowServices from 'app/administration/views/panelApproval/workflow/workflowServices';
import services from 'services';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import formatter from 'system/utilities/format';
import actionsUtil from 'common/util/entitledActions';
import StateGrid from 'common/grid/stateGrid';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import { maskValue } from 'common/util/maskingUtil';
import ThresholdAmountGridItemView from './thresholdAmountGridItemView';
import ApprovalWorkflowModel from '../models/panelApprovalWorkflowModel';
import template from './approvalWorkflowDetail.hbs';

export default Layout.extend({
    template,
    loadingTemplate,

    ui: {
        $approveButton: '[data-hook="getApproveButton"]',
        $deleteButton: '[data-hook="getDeleteButton"]',
    },

    initialize(options) {
        this.contextKey = options.contextKey;
        this.actionModel = store.remove(`${this.contextKey}-actionModel`);
        this.model = new ApprovalWorkflowModel({
            panelProfileCode: this.actionModel.get('PANELPROFILECODE'),
            userGroup: this.actionModel.get('USERGROUP'),
        });
    },

    templateHelpers() {
        const allCurrentFutureAccounts = this.allCurrentFutureAccounts(this.model);
        return {
            headingText: this.options.headingText,
            buttons: this.getButtons(),
            allCurrentFutureAccounts,
            showAccount: allCurrentFutureAccounts
                || !util.isNullOrUndefined(this.model.get('account')),
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const templateGrid = new StateGrid(this.getTemplateGridConfig());

            this.templateListRegion.show(templateGrid);
            this.thresholdAmountListRegion.show(new CollectionView({
                collection: new Collection(this.model.get('thresholds')),
                itemView: ThresholdAmountGridItemView,
                itemViewOptions: {
                    currency: this.currency,
                    productCode: this.productCode,
                    functionCode: this.functionCode,
                    typeCode: this.typeCode,
                    actionMode: 'THRESHHOLDGRID',
                },
            }));
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const currency = workflowServices.getCurrencyRequest(this.model.get('userGroup'));
        const approvalWorkflowData = this.model.fetch();
        const entitledActions = actionsUtil
            .getEntitledActions(services.panelAprovalWorkflow);
        return Promise.all([currency, entitledActions, approvalWorkflowData])
            .then(this.receiveData.bind(this));
    },

    /**
     * Check the model attribute 'accounts' for the value '*' which
     * indicates all current and future accounts
     * @param {Object} model
     * @returns {boolean}
     */
    allCurrentFutureAccounts(model) {
        const accounts = model.get('account');
        if (util.isNullOrUndefined(accounts)) {
            return false;
        }

        return accounts[0].accountFilter === '*';
    },

    /**
     * Get a list of buttons available in the template
     * @returns {array}
     */
    getButtons() {
        const buttons = [
            {
                hidden: !this.canApprove(),
                dataAction: 'approve',
                dataHook: 'getApproveButton',
                classes: 'btn-primary',
                label: locale.get('action.approve'),
            },
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.modify,
                    this.entitledActions,
                ),
                dataAction: 'modify',
                classes: 'btn-secondary',
                label: locale.get('action.modify'),
            },
            {
                hidden: !actionsUtil.hasEntitledAction(
                    actionsUtil.ACTIONS.delete,
                    this.entitledActions,
                ),
                dataAction: 'delete',
                dataHook: 'getDeleteButton',
                classes: 'btn-secondary',
                label: locale.get('action.delete'),
            },
            {
                hidden: false,
                dataAction: 'cancel',
                classes: 'btn-secondary',
                label: locale.get('common.cancel'),
            },
        ];

        return buttons.filter(button => !button.hidden);
    },

    canApprove() {
        const hasApproval = actionsUtil.hasEntitledAction(
            actionsUtil.ACTIONS.approve,
            this.entitledActions,
        );
        return this.model.get('status') === 'EN' && hasApproval;
    },

    receiveData(response) {
        const [, entitledActions] = response;
        this.productCode = entitledActions.productCode;
        this.functionCode = entitledActions.functionCode;
        this.typeCode = entitledActions.typeCode;
        [this.currency] = response;
        this.entitledActions = entitledActions.actionModes;
        this.setHasLoadedRequiredData(true);
        this.render();
    },

    approve() {
        this.ui.$approveButton.attr('aria-busy', true);
        workflowServices.actionRequest(
            services.panelAprovalWorkflowApprove,
            this.model.get('userGroup'),
            this.model.get('profileCode'),
        ).then((response) => {
            store.set(`${this.contextKey}-alertMessage`, 'APPROVE');
            store.set(`${this.contextKey}-confirms`, response);
            this.cancel();
        });
    },

    modify() {
        store.set('panelApprovalListReturnRoute', this.options.returnRoute);
        store.set(`${this.contextKey}-actionModel`, this.actionModel);
        this.navigateTo('/PANELAPPROVAL/modify');
    },

    delete() {
        this.ui.$deleteButton.attr('aria-busy', true);
        dialog.confirm(
            locale.get('tableMaintenance.dialog.confirm.item.delete'),
            locale.get('tableMaintenance.dialog.confirm.title.delete'),
            (okay) => {
                if (okay) {
                    workflowServices.actionRequest(
                        services.panelAprovalWorkflowDelete,
                        this.model.get('userGroup'),
                        this.model.get('profileCode'),
                    ).then((response) => {
                        store.set(`${this.contextKey}-alertMessage`, 'DELETE');
                        store.set(`${this.contextKey}-confirms`, response);
                        this.cancel();
                    }).catch(() => {
                        store.set(`${this.contextKey}-alertMessage`, 'DELETE error');
                        this.cancel();
                    });
                }
                this.ui.$deleteButton.attr('aria-busy', false);
            },
        );
    },

    /**
     * Navigate to the list view
     */
    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    getTemplateGridConfig() {
        let templates = [];
        if (!util.isNullOrUndefined(this.model.get('assignTemplates'))) {
            templates = this.model.get('assignTemplates').map(this.formatTemplateAmount.bind(this));
        }

        return {
            contextConfig: {
                productCode: this.productCode,
                functionCode: this.functionCode,
                typeCode: this.typeCode,
                actionMode: 'TEMPLATEGRID',
            },
            collection: new Collection(templates),
            columns: [
                {
                    label: locale.get('PS.panelApproval.Template.Code'),
                    field: 'templateCode',
                },
                {
                    label: locale.get('PS.panelApproval.Description'),
                    field: 'templateDescription',
                },
                {
                    label: locale.get('PAY.grid.PanelCode'),
                    field: 'currentWorkflow',
                },
                {
                    label: locale.get('PS.panelApproval.From.Account'),
                    field: 'debitAccountNumber',
                },
                {
                    label: locale.get('PS.panelApproval.Beneficiary'),
                    field: 'beneficiaryName',
                },
                {
                    label: locale.get('PS.panelApproval.Template.Amount'),
                    field: 'amount',
                },
                {
                    label: locale.get('PS.panelApproval.Payment.Type'),
                    field: 'paymentDescription',
                },
            ],
        };
    },

    /**
     * Format the amount property of the template object parameter
     * @param {Object} templateObj
     * @returns {Object}
     */
    formatTemplateAmount(templateObj) {
        return {
            ...templateObj,
            amount: formatter.formatNumber(templateObj.templateAmount),
            currentWorkflow: this.model.get('profileCode'),
            debitAccountNumber: maskValue(templateObj.debitAccountNumber),
        };
    },
});
