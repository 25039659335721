export function reorderWithPrimary({
  end,
  locked,
  maxPrimary,
  otherColumns,
  primaryColumns,
  start
}) {
  const toPrimary = /primary/.test(end.droppableId);
  const fromPrimary = /primary/.test(start.droppableId);

  const startIndex = start.index;
  const endIndex = end.index;

  if (toPrimary && fromPrimary) {
    const primaryModified = [...primaryColumns];
    const [removed] = primaryModified.splice(startIndex, 1);
    primaryModified.splice(endIndex, 0, removed);
    return [...locked, ...primaryModified, ...otherColumns];
  }

  if (!toPrimary && !fromPrimary) {
    const otherModified = [...otherColumns];
    const [removed] = otherModified.splice(startIndex, 1);
    otherModified.splice(endIndex, 0, removed);
    return [...locked, ...primaryColumns, ...otherModified];
  }

  const primaryModified = [...primaryColumns];
  const otherModfied = [...otherColumns];
  if (toPrimary && !fromPrimary) {
    // We are modifying this object so copy it rather than modifying original
    const [removedOriginal] = otherModfied.splice(startIndex, 1);
    const removed = { ...removedOriginal };
    removed.primary = true;
    primaryModified.splice(endIndex, 0, removed);

    // If we are about to exceed the max allowed primary columns, bump the last one
    const toBump = [];
    if ((locked.length + primaryModified.length) > maxPrimary) {
      const [bumpedOriginal] = primaryModified.splice(
        primaryModified.length - 1,
        1
      );
      const bumped = { ...bumpedOriginal };
      bumped.primary = false;
      toBump.push(bumped);
    }
    return [...locked, ...primaryModified, ...toBump, ...otherModfied];
  }

  if (!toPrimary && fromPrimary) {
    const [removedOriginal] = primaryModified.splice(startIndex, 1);
    const removed = { ...removedOriginal };
    removed.primary = false;
    otherModfied.splice(endIndex, 0, removed);
    // If we have removed all primary columns, bump the first "secondary" item to primary
    if (!(primaryModified.length + locked.length)) {
      const bumped = { ...otherModfied.shift() };
      bumped.primary = true;
      primaryModified.push(bumped);
    }
    return [...locked, ...primaryModified, ...otherModfied];
  }
  return [...locked, ...primaryColumns, ...otherColumns];
}

export const filterColumnsByLockAndPrimary = (data) => {
  const lastLockedIndex = data
    .slice()
    .reverse()
    .findIndex((column) => column.lockPosition);
  const lockedIndex = lastLockedIndex > -1 ? data.length - lastLockedIndex - 1 : -1;
  const locked = lockedIndex > -1 ? data.slice(0, lockedIndex + 1) : [];
  const unlocked = lockedIndex > -1 ? data.slice(lockedIndex + 1) : data;
  const [primaryColumns, otherColumns] = unlocked.reduce(
    ([pCols, oCols], col) => {
      if (col.primary) {
        return [[...pCols, col], oCols];
      }
      return [pCols, [...oCols, col]];
    },
    [[], []]
  );

  return {
    locked,
    otherColumns,
    primaryColumns
  };
};

const reorder = (list, startIndex, endIndex) => {
  const lockedColumns = list.filter((col) => col.lockPosition);
  // have to add the lockedColumns.length because they are not accounted for when the
  // startIndex is determined
  const columnToMove = list[startIndex + (lockedColumns.length)];
  const unlockedColumns = list.filter(
    (col) => !col.lockPosition && col.field !== columnToMove.field
  );

  const reorderedColumns = endIndex === unlockedColumns.length
    // remove the column from the unlockedColumns and put it at the end we have to do this because
    // the reduce function won't work when the element needs to be put at the end
    ? [...unlockedColumns.filter((col) => col.field !== columnToMove.field), columnToMove]
    // Loop through the unlockedColumns and when we reach the index that matches the endIndex we
    // want to return the columnToMove as well as the current column which inserts the columnToMove
    // into it's new position
    : unlockedColumns.reduce((newCols, col, index) => (
      index === endIndex ? [...newCols, columnToMove, col] : [...newCols, col]), []);

  return [
    ...lockedColumns,
    ...reorderedColumns
  ];
};

/*  Testing drag and drop is messy. Instead, test the order
 *  change function in isolation
 */
export const getColumnsFromOrderChange = ({
  data,
  filteredColumns,
  maxPrimary,
  result
}) => {
  if (maxPrimary) {
    const { locked, otherColumns, primaryColumns } = filteredColumns;

    return reorderWithPrimary({
      end: result.destination,
      locked,
      maxPrimary,
      otherColumns,
      primaryColumns,
      start: result.source
    });
  }
  return reorder(data.columns, result.source.index, result.destination.index);
};

export default reorderWithPrimary;
