import $ from 'jquery';
import services from 'services';

export default {
    /**
     * Change the current user's password.
     */
    changePassword(data) {
        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.changePassword,
            type: 'PUT',
            dataType: 'json',
            data: serialized,
        });
    },
    /**
     * Reset the current user's password.
     * This is used when the change password is initiated at the login page
     * or from a page initiated by email link.
     */
    resetPassword(data) {
        const serialized = JSON.stringify(data);

        return $.ajax({
            contentType: 'application/json',
            url: services.resetPasswordLoginPage,
            type: 'PUT',
            dataType: 'json',
            data: serialized,
        });
    },
};
