import CheckGrid from './checkGrid';
import CONSTANTS from './constants';
import gridDataAccess from './gridDataActions';

export default CheckGrid.extend({
    // Identifies if there is only one type in a group
    singleType: true,

    /**
     * Return the bankWidgets data entitlement attribute
     * @return {string}
     */
    getEntityName() {
        return CONSTANTS.ENTITY_NAME.BANK_WIDGET;
    },

    /**
     * Return the bankWidgets data inquiry id for the service call
     * @return {string}
     */
    getEntityID() {
        return CONSTANTS.REQUEST_ENTITY.BANK_WIDGET;
    },

    /**
     * Return the bankWidgets data inquiry id for current/future for the service call
     * @return {string}
     */
    getCFEntityID() {
        return CONSTANTS.REQUEST_ENTITY.BANK_WIDGET_CF;
    },

    /**
     * Return the bankWidgets type used for localized resources
     * @return {string}
     */
    getEntityType() {
        return CONSTANTS.ENTITY_TYPE.BANK_WIDGET;
    },

    /**
     * Return the available bankWidgets types to populate the grid
     * @return {Array}
     */
    getRecords() {
        return gridDataAccess.bankWidgetRecords(this.model);
    },
});
