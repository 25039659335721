import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import userInfo from 'etc/userInfo';
import services from 'services';

const PasswordModel = Model.extend({
    initialize() {
        this.validators = {
            oldPassword: {
                description: locale.get('prompt.oldPassword'),
                exists: true,
            },

            newPassword: {
                description: locale.get('prompt.newPassword'),
                exists: true,
                otherDescription: locale.get('prompt.confirmPassword'),
                sameValue: 'confirmPassword',
            },

            confirmPassword: {
                description: locale.get('prompt.confirmPassword'),
                exists: true,
                otherDescription: locale.get('prompt.newPassword'),
                sameValue: 'newPassword',
            },
        };
    },

    sync(method, modelParam, options) {
        const model = modelParam;
        if (method === 'update' || method === 'create') {
            http.put(services.changePassword, this.generateData(), (result) => {
                options.success(result);
            }, (response) => {
                model.error = JSON.parse(response.responseText);
                options.error(model, {});
            });
        }
    },

    generateData() {
        const data = {};
        data.oldPassword = this.get('oldPassword');
        data.newPassword = this.get('newPassword');
        data.requestHeader = {
            requestId: '1',

            requestUser: {
                userId: userInfo.attributes.id,

                data: {
                    item: [{
                        name: 'USERGROUP',
                        value: userInfo.attributes.group,
                    }],
                },
            },

            channelId: '',
        };

        return data;
    },
});

export default PasswordModel;
