var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group\">\n            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"export.submission",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":19},"end":{"line":33,"column":49}}}))
    + "</label>\n            <p class=\"form-control-static\">\n                "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"submissionTime") : stack1), depth0))
    + "\n            </p>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group\">\n            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"export.completion",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":19},"end":{"line":41,"column":49}}}))
    + "</label>\n            <p class=\"form-control-static\">\n                "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"completionTime") : stack1), depth0))
    + "\n            </p>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group\">\n            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"export.size",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":49,"column":19},"end":{"line":49,"column":43}}}))
    + "</label>\n            <p class=\"form-control-static\">\n                "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"fileSize") : stack1), depth0))
    + "\n            </p>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group\">\n            <label>"
    + alias1(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"export.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":57,"column":19},"end":{"line":57,"column":46}}}))
    + "</label>\n            <p class=\"form-control-static\">\n                "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "\n            </p>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	            <button class=\"btn btn-primary\"\n	                data-action=\"dload\"\n	                data-hook=\"getDownloadButton\"\n	                name=\"dload\"\n	                type=\"button\">\n	                <span class=\"txt\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.download",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":35},"end":{"line":74,"column":63}}}))
    + "\n	            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\"\n            data-action=\"cancel\"\n            href=\"#\">\n            <span class=\"icon-arrow-left\"> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":80},"end":{"line":6,"column":116}}}))
    + "\n        </a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"baiExport.downloadExport_title-view",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":79}}}))
    + "</h1>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-body\">\n        <div class=\"form-group\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"export.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":19},"end":{"line":14,"column":43}}}))
    + "</label>\n            <p class=\"form-control-static\">\n                "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"exportType") : stack1), depth0))
    + "\n            </p>\n        </div>\n        <div class=\"form-group\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"export.status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":19},"end":{"line":20,"column":45}}}))
    + "</label>\n            <p class=\"form-control-static\">\n                "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "\n            </p>\n        </div>\n        <div class=\"form-group\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"export.filename",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":19},"end":{"line":26,"column":47}}}))
    + "</label>\n            <p class=\"form-control-static\">\n                "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"fileName") : stack1), depth0))
    + "\n            </p>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"submissionTime") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"completionTime") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"fileSize") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"message") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":8},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n<div class=\"section section-summary\">\n    <div class=\"section-body\">\n        <div class=\"widget-action-btn-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDownloadButton") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":9},"end":{"line":76,"column":16}}})) != null ? stack1 : "")
    + "            <button class=\"btn btn-secondary\"\n                data-action=\"cancel\"\n                data-hook=\"getCancelButton\"\n                name=\"cancel\"\n                type=\"button\">\n                <span class=\"txt\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.cancel.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":82,"column":34},"end":{"line":82,"column":65}}}))
    + "\n            </button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});