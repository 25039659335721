import constants from './constants';

export default {
    zeroDecimals: constants.zeroDecimals,
    threeDecimals: constants.threeDecimals,

    getNumberOfDecimalPlaces(currency) {
        let decimalPlace = 2;

        if (this.zeroDecimals.indexOf(currency) > -1) {
            decimalPlace = 0;
        }

        if (this.threeDecimals.indexOf(currency) > -1) {
            decimalPlace = 3;
        }

        return decimalPlace;
    },

    /**
     * Retrieve currency format based on specific currency type
     * @param  {string} currency currency locale
     * @return {string} currency format for locale
     */
    getCurrencyFormat(currency) {
        const precision = this.getNumberOfDecimalPlaces(currency);
        let numberFormat;

        switch (precision) {
        case 0:
            numberFormat = '0,0';
            break;
        case 3:
            numberFormat = '0,0.000';
            break;
        default:
            numberFormat = '0,0.00';
        }

        return numberFormat;
    },
};
