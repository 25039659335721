import locale from '@glu/locale';
import LocationAccountView from 'mobile/common/views/locationAccountView';
import LazyLoadList from 'mobile/common/views/lazyLoadList';
import MobileDrawer from 'mobile/common/views/mobileDrawer';
import dialog from '@glu/dialog';
import AccountView from 'mobile/common/views/accountView';
import services from 'services';
import { postData } from 'common/util/services';
import RemoteDepositBaseLayout from './remoteDepositBase';
import DepositHistory from '../models/depositHistory';
import DepositCollection from '../collections/depositCollection';
import DepositHistoryItem from './depositHistoryItem';
import DepositHistoryEmptyView from './depositHistoryEmptyView';
import CheckImageView from './checkImageView';
import template from './remoteDepositHistory.hbs';

export default RemoteDepositBaseLayout.extend({
    template,
    className: 'remote-deposit-capture',

    ui: {
        $retrieveButton: '[data-hook="getRetrieveButton"]',
        $panelHeading: '[data-hook="getPanelHeading"]',
        $formPanel: '[data-hook="getFormPanel"]',
        $panelToggle: '[data-hook="getPanelToggle"]',
        $panelTitle: '[data-hook="getPanelTitleText"]',
    },

    events: {
        'click @ui.$panelTitle': 'toggleTitleText',
        'click @ui.$retrieveButton': 'getDepositHistory',
    },

    initialize(options) {
        RemoteDepositBaseLayout.prototype.initialize.call(this, options);

        this.model = new DepositHistory();
        this.deposits = new DepositCollection();

        this.historyListView = new LazyLoadList({
            collection: this.deposits,
            itemView: DepositHistoryItem,
            emptyView: DepositHistoryEmptyView,
        });

        this.listenTo(this.historyListView, 'list:item:clicked', this.showCheckImageDrawer.bind(this));
        this.listenTo(this.model, 'change:LOCATIONNAME change:ACCOUNTNUM', this.attributeChange.bind(this));
    },

    templateHelpers() {
        return {
            cid: this.cid,
        };
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            this.ui.$panelHeading.hide();
            this.model.addLocationValidation(this.useLocationWorkflow);
            this.renderlocationAccountWorkflow();
            this.historyListRegion.show(this.historyListView);
        } else {
            this.loadRequiredData();
        }
    },

    renderlocationAccountWorkflow() {
        if (this.useLocationWorkflow) {
            this.locationAccountWorkflow = new LocationAccountView({
                accounts: this.accounts,
                locations: this.locations,
                model: this.model,
                hideAccounts: true,
            });
        } else {
            this.locationAccountWorkflow = new AccountView({
                accounts: this.accounts,
                model: this.model,
            });
        }
        this.locationAccountRegion.show(this.locationAccountWorkflow);
    },

    /**
     * When panelToggle has class 'collaped' set the text accordingly.
     */
    toggleTitleText() {
        if (this.ui.$panelToggle.hasClass('collapsed')) {
            this.ui.$panelTitle.text(locale.get('rdc.Hide.Search'));
        } else {
            this.ui.$panelTitle.text(locale.get('rdc.Modify.Search'));
        }
    },

    /**
     * Hide the form and then fetch the deposit history
     */
    getDepositHistory() {
        if (this.model.isValid()) {
            this.toggleTitleText();
            this.setButtonBusy(true);
            this.ui.$formPanel.collapse('hide');
            this.ui.$panelHeading.fadeIn();
            this.setBankCode(this.accounts, this.model);
            this.deposits.reset();
            this.deposits.fetch({
                locationName: this.model.get('LOCATIONNAME'),
                bankCode: this.model.get('BANKCODE'),
                accountNumber: this.model.get('ACCOUNTNUM'),
            }).then(this.receiveDepositHistory.bind(this))
                .catch(this.onServiceError.bind(this));
        }
    },

    /**
     * Callback for logic after deposit history has been received, specifically
     * toggle the loader on the button
     */
    receiveDepositHistory() {
        this.setButtonBusy(false);
    },

    /**
     * On attribute change, validate the changed attributes of the model
     * @param {Model} model - blur event
     */
    attributeChange(model) {
        const changedAttributes = Object.keys(model.changed);
        changedAttributes.forEach(this.validateAttribute.bind(this));
    },

    /**
     * Set aria-busy attribute of the button to the value provided
     * @param {boolean} value
     */
    setButtonBusy(value) {
        this.ui.$retrieveButton.attr('aria-busy', value);
    },

    /**
     * Toggle the retrieve button based on the model being ready for submission
     */
    enableDisableAction() {
        this.ui.$retrieveButton.prop('disabled', !this.model.isReadyForSubmission());
    },

    /**
     * Show a mobile drawer with the checkImageView inside of it
     * @param {string} event - name of event
     * @param {Object} view - view that triggered the event
     * @param {Object} model - model attached to the view that triggered the event
     */
    showCheckImageDrawer(event, view, model) {
        model.set('LOCATIONNAME', this.model.get('LOCATIONNAME'));

        this.checkImageDrawerRegion.show(new MobileDrawer({
            returnLabel: locale.get('rdc.Image.Drawer.Back'),
            ContentView: CheckImageView,
            model,
        }));
    },

    /**
     * Show alert with error message from the response
     * @param {Object} err - Error message object from the server
     */
    onFetchError(err) {
        this.setButtonBusy(false);
        dialog.error(err.responseJSON.message.join(' '));
    },

    /**
     * Make a call to close any possible sessions that exist
     * on the server.
     * @returns {Promise}
     */
    onBeforeClose() {
        const { rdcSessionData } = this.deposits;
        if (rdcSessionData) {
            return postData(
                services.generateUrl('imaging/remoteDepositCapture/rdcEndSession'),
                {
                    sessionStateId: rdcSessionData.sessionStateId,
                    sessionToken: rdcSessionData.sessionToken,
                },
            );
        }
        return Promise.resolve();
    },
});
