var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.notificationTemplateDetail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":62}}}))
    + "</h3>\n            <fieldset class=\"notification-template-fields\">\n                <div class=\"row\">\n                    <div class=\"col-md-3 notification-entry-align\">\n                        <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":86}}})) != null ? stack1 : "")
    + "\">\n                            <label for=\"notificationTemplateName\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.notificationTemplateName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":110}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTemplateCodeReadonly") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":26,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                    <div class=\"col-md-3\">\n                        <div class=\"form-group\">\n                            <label for=\"notificationTemplateDescription\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.notificationTemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":73},"end":{"line":31,"column":124}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":40,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                    <div class=\"col-md-3\">\n                        <div class=\"form-group\">\n                            <div class=\"checkbox\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":46,"column":32},"end":{"line":65,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n                </div>\n            </fieldset>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "required";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"TEMPLATECODE") || (depth0 != null ? lookupProperty(depth0,"TEMPLATECODE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TEMPLATECODE","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":51}}}) : helper)))
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                                <input type=\"text\"\n                                       class=\"form-control\"\n                                       name=\"TEMPLATECODE\"\n                                       data-bind=\"model\"\n                                       id=\"notificationTemplateCode\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"TEMPLATEDESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"TEMPLATEDESCRIPTION") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TEMPLATEDESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":33,"column":35},"end":{"line":33,"column":58}}}) : helper)))
    + "</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                                <input type=\"text\"\n                                       class=\"form-control\"\n                                       name=\"TEMPLATEDESCRIPTION\"\n                                       data-bind=\"model\"\n                                       id=\"notificationTemplateDescription\">\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isShared") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":36},"end":{"line":49,"column":43}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"textVisible":true,"locale":"common.sharedTemplate","name":"check"},"data":data,"loc":{"start":{"line":48,"column":40},"end":{"line":48,"column":109}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <label>\n                                        <input type=\"checkbox\"\n                                               data-bind=\"model\"\n                                               data-hook=\"getSharedCheckbox\"\n                                               name=\"SHARED\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.sharedTemplate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":55,"column":62},"end":{"line":55,"column":96}}}))
    + "\n                                    </label>\n                                    <button class=\"btn btn-popover\"\n                                            type=\"button\"\n                                            data-trigger=\"hover\"\n                                            data-toggle=\"popover\"\n                                            data-placement=\"right\"\n                                            data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.sharedTemplateInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":62,"column":58},"end":{"line":62,"column":96}}}))
    + "\">\n                                            "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"locale":"common.sharedTemplateInfo","name":"info"},"data":data,"loc":{"start":{"line":63,"column":44},"end":{"line":63,"column":99}}}))
    + "\n                                    </button>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"common.notificationDetail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":72,"column":16},"end":{"line":72,"column":54}}}))
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":114,"column":19}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <fieldset class=\"notification-entry-field\">\n                    <div class=\"row\">\n                        <div class=\"col-md-3 notification-entry-align\">\n                            <div class=\"form-group\">\n                                <label for=\"TEMPLATE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.copiedFromTemplate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":54},"end":{"line":78,"column":92}}}))
    + "</label>\n                                <p>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"TEMPLATECODE") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":79,"column":35},"end":{"line":79,"column":88}}})) != null ? stack1 : "")
    + "</p>\n                            </div>\n                        </div>\n                            <div class=\"col-md-3\">\n                                <div class=\"form-group\">\n                                    <label for=\"ACTIVE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PS.Status",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":84,"column":56},"end":{"line":84,"column":78}}}))
    + "</label>\n                                    <p>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":85,"column":39},"end":{"line":85,"column":138}}})) != null ? stack1 : "")
    + "</p>\n                               </div>\n                            </div>\n                    </div>\n                </fieldset>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"TEMPLATECODE") || (depth0 != null ? lookupProperty(depth0,"TEMPLATECODE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TEMPLATECODE","hash":{},"data":data,"loc":{"start":{"line":79,"column":55},"end":{"line":79,"column":71}}}) : helper)));
},"21":function(container,depth0,helpers,partials,data) {
    return "--";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.screentext.Active",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":55},"end":{"line":85,"column":88}}}));
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"PS.screentext.Archived",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":96},"end":{"line":85,"column":131}}}));
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCreationFromTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":91,"column":16},"end":{"line":113,"column":23}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <fieldset class=\"notification-entry-field\">\n                        <div class=\"row\">\n                            <div class=\"col-md-3 notification-entry-align\">\n                                <div class=\"form-group\">\n                                    <label for=\"TEMPLATE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.copiedFromTemplate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":96,"column":58},"end":{"line":96,"column":96}}}))
    + "</label>\n                                    <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"TEMPLATECODE") || (depth0 != null ? lookupProperty(depth0,"TEMPLATECODE") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"TEMPLATECODE","hash":{},"data":data,"loc":{"start":{"line":97,"column":39},"end":{"line":97,"column":55}}}) : helper)))
    + "</p>\n                                </div>\n                            </div>\n                        </div>\n                    </fieldset>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <fieldset class=\"notification-entry-field\">\n                        <div class=\"row\">\n                            <div class=\"col-md-3 notification-entry-align\">\n                                <div class=\"form-group\">\n                                    <label for=\"TEMPLATE\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.copyAnExistingTemplate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":107,"column":58},"end":{"line":107,"column":100}}}))
    + "</label>\n                                        <div data-region=\"templateRegion\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </fieldset>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.selectDistributionChannel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":116,"column":32},"end":{"line":116,"column":77}}}))
    + "</h3>";
},"34":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"populationType") || (depth0 != null ? lookupProperty(depth0,"populationType") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"populationType","hash":{},"data":data,"loc":{"start":{"line":123,"column":31},"end":{"line":123,"column":49}}}) : helper)))
    + "</p>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "                            <div data-region=\"distChannelRegion\"></div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"population") || (depth0 != null ? lookupProperty(depth0,"population") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"population","hash":{},"data":data,"loc":{"start":{"line":133,"column":31},"end":{"line":133,"column":45}}}) : helper)))
    + "</p>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"field-container field-container-lg required\">\n                                <input type=\"hidden\" id=\"companySelect\" data-filter-type=\"multi-select\" class=\"form-control type-ahead\" data-hook=\"companySelect\">\n                            </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":8},"end":{"line":144,"column":76}}})) != null ? stack1 : "")
    + "\n        <fieldset class=\"notification-entry-field\">\n            <div class=\"row\">\n                <div class=\"col-md-2 notification-entry-align date-picker\">\n                    <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":43},"end":{"line":148,"column":82}}})) != null ? stack1 : "")
    + "\">\n                        <label for=\"startDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":149,"column":46},"end":{"line":149,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.startDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":149,"column":55},"end":{"line":149,"column":84}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(47, data, 0),"data":data,"loc":{"start":{"line":150,"column":24},"end":{"line":155,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"col-md-2\">\n                    <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":43},"end":{"line":159,"column":82}}})) != null ? stack1 : "")
    + "\">\n                        <label for=\"startTime-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":160,"column":46},"end":{"line":160,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.startTime",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":160,"column":55},"end":{"line":160,"column":84}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data,"loc":{"start":{"line":161,"column":24},"end":{"line":169,"column":34}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":16},"end":{"line":183,"column":27}}})) != null ? stack1 : "")
    + "                <div class=\"col-md-2 date-picker\">\n                    <div class=\"form-group hide "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":48},"end":{"line":185,"column":87}}})) != null ? stack1 : "")
    + "\" data-hook=\"getEndDateContainer\">\n                        <label for=\"endDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":186,"column":44},"end":{"line":186,"column":51}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.endDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":186,"column":53},"end":{"line":186,"column":80}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":187,"column":24},"end":{"line":192,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"col-md-2\">\n                    <div class=\"form-group hide "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":48},"end":{"line":196,"column":88}}})) != null ? stack1 : "")
    + "\">\n                        <label for=\"endTime-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":197,"column":44},"end":{"line":197,"column":51}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.endTime",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":197,"column":53},"end":{"line":197,"column":80}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.program(66, data, 0),"data":data,"loc":{"start":{"line":198,"column":24},"end":{"line":206,"column":30}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n              </div>\n        </fieldset>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.duration",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":144,"column":32},"end":{"line":144,"column":60}}}))
    + "</h3>";
},"45":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"startDate") || (depth0 != null ? lookupProperty(depth0,"startDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"startDate","hash":{},"data":data,"loc":{"start":{"line":151,"column":31},"end":{"line":151,"column":44}}}) : helper)))
    + "</p>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <input id=\"startDate-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":153,"column":49},"end":{"line":153,"column":56}}}) : helper)))
    + "\" name=\"START_DATE\" type=\"text\" class=\"input-date form-control\" data-bind=\"model\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"START_DATE\"></span>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":162,"column":31},"end":{"line":162,"column":44}}}) : helper)))
    + "</p>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"input-group\">\n                                <input id=\"startTime-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":165,"column":53},"end":{"line":165,"column":60}}}) : helper)))
    + "\" name=\"START_TIME\" type=\"text\" class=\"form-control\"  data-bind=\"model\" data-hook=\"getTimeField\">\n                                      <span class=\"input-group-addon addon-right\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"timezoneLabel") || (depth0 != null ? lookupProperty(depth0,"timezoneLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timezoneLabel","hash":{},"data":data,"loc":{"start":{"line":166,"column":94},"end":{"line":166,"column":111}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"systemTimezone") || (depth0 != null ? lookupProperty(depth0,"systemTimezone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"systemTimezone","hash":{},"data":data,"loc":{"start":{"line":166,"column":113},"end":{"line":166,"column":131}}}) : helper)))
    + "</span>\n                             </div>\n                             <span class=\"help-block\" data-bind=\"model\" data-validate=\"START_TIME\"></span>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"col-md-2 radio\" style=\"margin-top:30px\">\n                    <div class=\"radio-inline\">\n                        <input id=\"noEndDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":175,"column":45},"end":{"line":175,"column":52}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":54},"end":{"line":175,"column":96}}})) != null ? stack1 : "")
    + " name=\"NOENDDATE\" type=\"radio\" value=\"1\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"endDateSelected") : depth0),{"name":"unless","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":155},"end":{"line":175,"column":210}}})) != null ? stack1 : "")
    + ">\n                        <label for=\"noEndDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":176,"column":46},"end":{"line":176,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.noEndDate","No End Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":176,"column":55},"end":{"line":176,"column":98}}}))
    + "</label>\n                    </div>\n                    <div class=\"radio-inline\">\n                        <input id=\"selectEndDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":179,"column":49},"end":{"line":179,"column":56}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":58},"end":{"line":179,"column":100}}})) != null ? stack1 : "")
    + " name=\"NOENDDATE\" type=\"radio\" value=\"0\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"endDateSelected") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":159},"end":{"line":179,"column":206}}})) != null ? stack1 : "")
    + " >\n                        <label for=\"selectEndDate-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":180,"column":50},"end":{"line":180,"column":57}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"common.selectEndDate","Select End Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":180,"column":59},"end":{"line":180,"column":110}}}))
    + "</label>\n                    </div>\n                </div>\n";
},"54":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"56":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"58":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p data-hook=\"getReadOnlyEndDate\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"endDate") || (depth0 != null ? lookupProperty(depth0,"endDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"endDate","hash":{},"data":data,"loc":{"start":{"line":188,"column":62},"end":{"line":188,"column":73}}}) : helper)))
    + "</p>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <input id=\"endDate-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":190,"column":47},"end":{"line":190,"column":54}}}) : helper)))
    + "\" name=\"END_DATE\" type=\"text\" class=\"input-date form-control\" data-bind=\"model\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"END_DATE\"></span>\n";
},"62":function(container,depth0,helpers,partials,data) {
    return "required ";
},"64":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p  data-hook=\"getReadOnlyEndTime\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"endTime") || (depth0 != null ? lookupProperty(depth0,"endTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"endTime","hash":{},"data":data,"loc":{"start":{"line":199,"column":63},"end":{"line":199,"column":74}}}) : helper)))
    + "</p>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"input-group\">\n                                <input id=\"endTime-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":202,"column":51},"end":{"line":202,"column":58}}}) : helper)))
    + "\" name=\"END_TIME\" type=\"text\" class=\"form-control\" data-bind=\"model\" data-hook=\"getTimeField\">\n                                       <span class=\"input-group-addon addon-right\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"timezoneLabel") || (depth0 != null ? lookupProperty(depth0,"timezoneLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timezoneLabel","hash":{},"data":data,"loc":{"start":{"line":203,"column":95},"end":{"line":203,"column":112}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"systemTimezone") || (depth0 != null ? lookupProperty(depth0,"systemTimezone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"systemTimezone","hash":{},"data":data,"loc":{"start":{"line":203,"column":114},"end":{"line":203,"column":132}}}) : helper)))
    + "</span>\n                             </div>\n                             <span class=\"help-block\" data-bind=\"model\" data-validate=\"END_TIME\"></span>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h3>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"common.features",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":214,"column":20},"end":{"line":214,"column":48}}}))
    + "</h3>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <legend class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":216,"column":31},"end":{"line":216,"column":70}}})) != null ? stack1 : "")
    + "\">\n                    <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.selectFeatures",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":217,"column":24},"end":{"line":217,"column":58}}}))
    + "</h3>\n                    <p class=\"textline-field\">One or more features must be selected.</p>\n                </legend>\n                <label class=\"notification-features-select-all\"><input type=\"checkbox\" class=\"allProductFeatures\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allFeaturesSelected") : depth0),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":114},"end":{"line":220,"column":155}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.allFeatures",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":220,"column":157},"end":{"line":220,"column":188}}}))
    + "</label>\n";
},"71":function(container,depth0,helpers,partials,data) {
    return "checked";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shouldBeHidden") : depth0),{"name":"unless","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":227,"column":28},"end":{"line":237,"column":39}}})) != null ? stack1 : "");
},"74":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li class=\"NavTabs-item\"\n                                    data-action=\"setActiveFeatureTab\"\n                                    id=\"featureTab-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":230,"column":51},"end":{"line":230,"column":57}}}) : helper)))
    + "\"\n                                    name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":231,"column":42},"end":{"line":231,"column":48}}}) : helper)))
    + "\"\n                                    role=\"tab\">\n                                    <a class=\"NavTabs-link\"\n                                        href=\"#\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":234,"column":49},"end":{"line":234,"column":65}}}))
    + "\n                                    </a>\n                                </li>\n";
},"76":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"SUBJECT") || (depth0 != null ? lookupProperty(depth0,"SUBJECT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"SUBJECT","hash":{},"data":data,"loc":{"start":{"line":253,"column":31},"end":{"line":253,"column":42}}}) : helper)))
    + "</p>\n";
},"78":function(container,depth0,helpers,partials,data) {
    return "                            <input id=\"notification-subject\" data-bind=\"model\" name=\"SUBJECT\" type=\"text\" class=\"form-control\">\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"SUBJECT\"></span>\n";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"TEXT") || (depth0 != null ? lookupProperty(depth0,"TEXT") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"TEXT","hash":{},"data":data,"loc":{"start":{"line":264,"column":28},"end":{"line":264,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"82":function(container,depth0,helpers,partials,data) {
    return "                            <textarea id=\"notification-text\" name=\"TEXT\" data-bind=\"model\"></textarea>\n                            <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEXT\"></span>\n";
},"84":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"auditTitle") || (depth0 != null ? lookupProperty(depth0,"auditTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auditTitle","hash":{},"data":data,"loc":{"start":{"line":274,"column":16},"end":{"line":274,"column":30}}}) : helper)))
    + "</h3>\n            <fieldset class=\"audit-history-fields\">\n                <div class=\"row\">\n                    <div data-region=\"auditSectionRegion\"></div>\n                </div>\n            </fieldset>\n";
},"86":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-hook=\"getSubmitBtn\" type=\"button\" class=\"btn btn-primary\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.submit",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":282,"column":83},"end":{"line":282,"column":109}}}))
    + "</button>\n";
},"88":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isModify") : depth0),{"name":"if","hash":{},"fn":container.program(89, data, 0),"inverse":container.program(91, data, 0),"data":data,"loc":{"start":{"line":284,"column":12},"end":{"line":290,"column":19}}})) != null ? stack1 : "");
},"89":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button data-hook=\"getModifyBtn\" type=\"button\" class=\"btn btn-primary\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.modify",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":285,"column":87},"end":{"line":285,"column":113}}}))
    + "</button>\n";
},"91":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(92, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":287,"column":16},"end":{"line":289,"column":25}}})) != null ? stack1 : "");
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"action") || (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data,"loc":{"start":{"line":288,"column":39},"end":{"line":288,"column":49}}}) : helper)))
    + "\" type=\"button\" class=\"btn btn-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.program(95, data, 0),"data":data,"loc":{"start":{"line":288,"column":80},"end":{"line":288,"column":124}}})) != null ? stack1 : "")
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":288,"column":126},"end":{"line":288,"column":135}}}) : helper)))
    + "</button>\n";
},"93":function(container,depth0,helpers,partials,data) {
    return "primary";
},"95":function(container,depth0,helpers,partials,data) {
    return "tertiary";
},"97":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-action=\"cancel\" type=\"button\" class=\"btn btn-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noButtons") : depth0),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.program(95, data, 0),"data":data,"loc":{"start":{"line":295,"column":70},"end":{"line":295,"column":117}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"button.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":295,"column":119},"end":{"line":295,"column":143}}}))
    + "</button>\n";
},"99":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button data-action=\"cancel\" type=\"button\" class=\"btn btn-tertiary\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":297,"column":80},"end":{"line":297,"column":106}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp;\n            <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.backToPrevious",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":42},"end":{"line":4,"column":84}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":31},"end":{"line":6,"column":40}}}) : helper)))
    + "</h1>\n</div>\n<div class=\"section section-container\">\n    <div class=\"section-body notification-entry-container\">\n        <div data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTemplateWorkflow") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":115,"column":15}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":8},"end":{"line":116,"column":93}}})) != null ? stack1 : "")
    + "\n        <fieldset class=\"notification-entry-field\">\n            <div class=\"row\">\n                <div class=\"col-md-3 notification-entry-align\">\n                    <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":43},"end":{"line":120,"column":82}}})) != null ? stack1 : "")
    + "\">\n                        <label for=\"POPULATIONTYPE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.distributionChannel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":121,"column":52},"end":{"line":121,"column":91}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":122,"column":24},"end":{"line":126,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"col-md-3\">\n                    <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":43},"end":{"line":130,"column":82}}})) != null ? stack1 : "")
    + "\">\n                        <label for=\"POPULATION\" data-hook=\"channel-pop-label\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"populationType") || (depth0 != null ? lookupProperty(depth0,"populationType") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"populationType","hash":{},"data":data,"loc":{"start":{"line":131,"column":78},"end":{"line":131,"column":96}}}) : helper)))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":132,"column":24},"end":{"line":138,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </fieldset>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayDuration") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":8},"end":{"line":211,"column":15}}})) != null ? stack1 : "")
    + "        <fieldset class=\"notification-entry-field\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.program(70, data, 0),"data":data,"loc":{"start":{"line":213,"column":12},"end":{"line":221,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"add-notification-container\" role=\"navigation\">\n                <div class=\"notification-features\">\n                    <ul id=\"notif-entry-tablist\" class=\"NavTabs nav nav-tabs\"  role=\"tablist\"\n                        data-hook=\"getNavTabs\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"featureTabs") : depth0),{"name":"each","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":226,"column":24},"end":{"line":238,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n                <div class=\"notification-types\">\n                    <div data-region=\"featureGroupsRegion\"></div>\n                </div>\n            </div>\n        </fieldset>\n        <fieldset class=\"notification-entry-field\">\n            <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.enterNotificationDetails",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":247,"column":16},"end":{"line":247,"column":60}}}))
    + "</h3>\n            <div class=\"row\">\n                <div class=\"col-md-4 notification-entry-align\">\n                    <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":250,"column":43},"end":{"line":250,"column":82}}})) != null ? stack1 : "")
    + "\">\n                        <label for=\"notification-subject\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.subject",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":251,"column":58},"end":{"line":251,"column":85}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.program(78, data, 0),"data":data,"loc":{"start":{"line":252,"column":24},"end":{"line":257,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"col-md-12 notification-entry-align\">\n                    <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":261,"column":43},"end":{"line":261,"column":82}}})) != null ? stack1 : "")
    + "\">\n                        <label for=\"notification-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":262,"column":55},"end":{"line":262,"column":82}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.program(82, data, 0),"data":data,"loc":{"start":{"line":263,"column":24},"end":{"line":268,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </fieldset>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInsert") : depth0),{"name":"unless","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":273,"column":8},"end":{"line":280,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInsert") : depth0),{"name":"if","hash":{},"fn":container.program(86, data, 0),"inverse":container.program(88, data, 0),"data":data,"loc":{"start":{"line":281,"column":8},"end":{"line":291,"column":15}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(97, data, 0),"inverse":container.program(99, data, 0),"data":data,"loc":{"start":{"line":294,"column":8},"end":{"line":298,"column":15}}})) != null ? stack1 : "")
    + "     </div>\n</div>\n";
},"useData":true});