import Population from 'app/challengeManager/collections/population';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';
import SegmentsModel from 'app/challengeManager/models/segments';

const Segments = Population.extend({
    comparator: 'NAME',
    model: SegmentsModel,

    parse(response) {
        return response.rows.map((row) => {
            const segments = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            segments.totalRows = response.totalRows;
            segments.totalPages = response.totalPages;
            segments.type = 'SEGMENTS';
            return segments;
        });
    },

    sync(method, model, options) {
        const url = services.generateUrl('/challengeManager/segment/getListView');

        if (this.fieldValue) {
            this.data.searchFields = [{
                fieldName: 'NAME',
                operator: 'IN',
                fieldValue: this.fieldValue,
                dataType: 'text',
            }];
        }

        const searchValue = this.getSearchCriteria();

        if (searchValue && searchValue.length > 0) {
            this.data.searchFields = [{
                fieldName: 'NAME',
                operator: 'CONTAINS',
                fieldValue: this.getSearchCriteria(),
                dataType: 'text',
            }];
        }
        http.post(url, this.data, (result) => {
            options.success(result);
        }, (result) => {
            options.error(result);
        });
    },
});

export default Segments;
