import ItemView from '@glu/core/src/itemView';
import segmentsTmpl from './segmentsView.hbs';

const SegmentsView = ItemView.extend({
    template: segmentsTmpl,

    events: {
        'click input': 'toggleSelected',
    },

    templateHelpers() {
        return {
            hx: 'h4',
            name: this.model.get('NAME'),
        };
    },
});

export default SegmentsView;
