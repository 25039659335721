import $ from 'jquery';
import ItemView from '@glu/core/src/itemView';
import locale from '@glu/locale';
import Dialog from '@glu/dialog';
import template from './needInfoDialog.hbs';

const NeedInfoDialog = ItemView.extend({
    template,
    grid: null,
    detailPage: null,
    store: null,
    multiSelectReject: null,

    initialize(options) {
        this.model = options.model;
        this.grid = options.grid;
        this.store = options.store;
        this.detailPage = options.detailPage;
        this.list = options.multiSelectReject;
        if (this.model) {
            this.model.isClosing = false;
        }
    },

    ui: {
        // this is needed in certain situations when the dialog doesn't provide a callback
        cancel: '[data-dismiss="modal"]',
    },

    events: {
        'click @ui.cancel': 'cancel',
    },

    attributes: {
        role: 'dialog',
        tabindex: '-1',
        'aria-hidden': 'false',
        class: 'modal',
    },

    title: locale.get('common.needinfo.reason'),

    save() {
        const needInfoReason = $('#needinfo_reason').val();
        const self = this;
        if (!needInfoReason) {
            $('#needinfo_reason_required').css('color', 'red');
            $('#needinfo_reason_required').css('visibility', 'visible');
            return;
        }
        if (this.model) {
            this.model.set('NEEDS_MORE_INFO_REASON', needInfoReason);
        }
        if (this.grid) {
            this.grid.trigger(
                'rowAction',
                {
                    action: 'needinfo',
                    model: this.model,
                },
            );
        } else if (this.list) {
            this.trigger(
                'list:multi:action:action_needinfo',
                {
                    name: 'NEEDS_MORE_INFO_REASON',
                    value: needInfoReason,
                },
            );
        } else {
            this.model.needinfo({
                success(model, resp, options) {
                    const contextKey = self.contextKey !== undefined
                        ? self.contextKey : options.detailPage.contextKey;
                    options.store.set(`${contextKey}-alertMessage`, 'NEEDINFO');
                    options.store.set(`${contextKey}-confirms`, resp);
                    options.detailPage.cancel();
                },

                error(model, resp, options) {
                    options.store.set(`${self.contextKey}-alertMessage`, 'NEEDINFO error');
                },

                store: this.store,
                detailPage: this.detailPage,
            });
        }

        // this lets anyone listening know we're closing
        if (this.model) {
            this.model.isClosing = true;
        }
        this.close();
    },

    cancel() {
        Dialog.close();
    },
});
export default NeedInfoDialog;
