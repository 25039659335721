var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                     <div class=\"radio-inline\">\n                        <label for=\"method-"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":40,"column":43},"end":{"line":40,"column":51}}}) : helper)))
    + "\">\n                            <input id=\"method-"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":41,"column":46},"end":{"line":41,"column":54}}}) : helper)))
    + "\" class=\"delivery-methods\" type=\"radio\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":41,"column":101},"end":{"line":41,"column":109}}}) : helper)))
    + "\" name=\"DELIVERYMETHOD\" data-bind=\"model\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":151},"end":{"line":41,"column":179}}})) != null ? stack1 : "")
    + "> "
    + alias4(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":181},"end":{"line":41,"column":197}}}))
    + "\n                        </label>\n                    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-header-wrapper\">\n    <div class=\"prev-page-breadcrumb\">\n        <a class=\"return-to-workspace\" href=\"#\" data-action=\"cancel\"><span class=\"icon-arrow-left\"></span> &nbsp; <span class=\"breadcrumb-text\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"reports.back.to.previous",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":144},"end":{"line":3,"column":181}}}))
    + "</span></a>\n    </div>\n    <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.reqCopyTitle",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":59}}}))
    + "</h1>\n</div>\n\n<div class=\"section section-container\">\n    <div class=\"section-header\">\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.checkInformation",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":44}}}))
    + "</h2>\n    </div>\n    <div class=\"section-body\">\n        <div class=\"col-md-2\">\n            <div class=\"form-group\">\n                <label for=\"serial-number\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.SerialNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":71}}}))
    + "</label>\n                <p id=\"serial-number\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"SERIALNUMBER") || (depth0 != null ? lookupProperty(depth0,"SERIALNUMBER") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"SERIALNUMBER","hash":{},"data":data,"loc":{"start":{"line":16,"column":66},"end":{"line":16,"column":82}}}) : helper)))
    + "</p>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"email-address\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"ACH.Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":66}}}))
    + "</label>\n                <p id=\"email-address\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"AMOUNT") || (depth0 != null ? lookupProperty(depth0,"AMOUNT") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"AMOUNT","hash":{},"data":data,"loc":{"start":{"line":20,"column":66},"end":{"line":20,"column":76}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <div class=\"col-md-4\">\n            <div class=\"form-group\">\n                <label for=\"paid-stop-date\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.paidStopDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":44},"end":{"line":25,"column":72}}}))
    + "</label>\n                <p id=\"paid-stop-date\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"PAIDDATE") || (depth0 != null ? lookupProperty(depth0,"PAIDDATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"PAIDDATE","hash":{},"data":data,"loc":{"start":{"line":26,"column":67},"end":{"line":26,"column":79}}}) : helper)))
    + "</p>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"payee-name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.PayeeName",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":40},"end":{"line":29,"column":65}}}))
    + "</label>\n                <p id=\"payee-name\" class=\"form-control-static\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"PAYEE") || (depth0 != null ? lookupProperty(depth0,"PAYEE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"PAYEE","hash":{},"data":data,"loc":{"start":{"line":30,"column":63},"end":{"line":30,"column":72}}}) : helper)))
    + "</p>\n            </div>\n        </div>\n        <hr/>\n        <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.deliveryMethod",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":34,"column":42}}}))
    + "</h3>\n        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n        <div>\n            <div class=\"radio\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"methods") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":44,"column":25}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"DELIVERYMETHOD\"></span>\n            </div>\n        </div>\n        <div class=\"method-region\" data-region=\"methodRegion\"></div>\n    </div>\n</div>\n\n";
},"useData":true});