var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.scenario",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":21},"end":{"line":11,"column":51}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"scenario") || (depth0 != null ? lookupProperty(depth0,"scenario") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"scenario","hash":{},"data":data,"loc":{"start":{"line":11,"column":59},"end":{"line":11,"column":71}}}) : helper)))
    + "</span></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"recurringFrequency") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":19,"column":23}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.recurring",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":60}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"recurringFrequency") || (depth0 != null ? lookupProperty(depth0,"recurringFrequency") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"recurringFrequency","hash":{},"data":data,"loc":{"start":{"line":16,"column":68},"end":{"line":16,"column":90}}}) : helper)))
    + "</span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.until",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":98},"end":{"line":16,"column":125}}}))
    + " <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"RECURRENCEENDDATE") || (depth0 != null ? lookupProperty(depth0,"RECURRENCEENDDATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"RECURRENCEENDDATE","hash":{},"data":data,"loc":{"start":{"line":16,"column":132},"end":{"line":16,"column":153}}}) : helper)))
    + "</span></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"cashflow.recurring.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":59}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"cmb-alert-details\">\n\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":13},"end":{"line":4,"column":46}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"DESCRIPTION") || (depth0 != null ? lookupProperty(depth0,"DESCRIPTION") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"DESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":69}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.category",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":13},"end":{"line":5,"column":43}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"categoryName") || (depth0 != null ? lookupProperty(depth0,"categoryName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"categoryName","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":67}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.type",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":39}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"CASHFLOWTYPE") || (depth0 != null ? lookupProperty(depth0,"CASHFLOWTYPE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"CASHFLOWTYPE","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":63}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":41}}}))
    + ": <span>"
    + alias2(lookupProperty(helpers,"money").call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"money","hash":{},"data":data,"loc":{"start":{"line":7,"column":49},"end":{"line":7,"column":65}}}))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":13},"end":{"line":8,"column":42}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":8,"column":50},"end":{"line":8,"column":65}}}) : helper)))
    + "</span></div>\n        <div>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":13},"end":{"line":9,"column":39}}}))
    + ": <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"EXPECTEDDATE") || (depth0 != null ? lookupProperty(depth0,"EXPECTEDDATE") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"EXPECTEDDATE","hash":{},"data":data,"loc":{"start":{"line":9,"column":47},"end":{"line":9,"column":63}}}) : helper)))
    + "</span></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"scenario") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRecurring") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});