/* eslint no-unused-vars: "off" */
import { inputPlugins } from '@glu/flex-dropdown';
import { ItemView, util } from '@glu/core';
import template from './workDay.hbs';

export default ItemView.extend({
    template,

    ui: {
        $select: '[data-hook="work-day-select"]',
    },

    onRender() {

       if (!isNaN(this.model.get('recurrence.onN')[0])) {
           this.model.set({
               'recurrence.onN': ['FIRST'],
               'onN': ['FIRST'],
           });
       }
        // A temp fix for NH-152296 & NH-152549 where views don't update
        this.$('select').val(this.model.get('recurrence.onN'));
        this.$('select').flexDropdown();
   },

});
