var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\"btn btn-tertiary print\" data-hook=\"print-transactions-button\">\n                                <span class=\"icon-print\"></span>\n                                "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":47,"column":57}}}))
    + "\n                        </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"display-print-transactions-text-combined\">\n                            <span>"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"gir.custReport.tranTotals.optional.message",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":52,"column":34},"end":{"line":52,"column":89}}}))
    + "</span>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel-heading AccountReportPanel\" id=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":70}}}) : helper)))
    + "\">\n        <div class=\"AccountReportPanel-section AccountPanelTitle\">\n                <div>\n                        <h3 class=\"panel-title\">\n                                <a role=\"button\" data-toggle=\"collapse\" href=\"#combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":96},"end":{"line":5,"column":102}}}) : helper)))
    + "\" aria-expanded=\"false\" class=\"collapsed\" aria-controls=\"combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":176},"end":{"line":5,"column":182}}}) : helper)))
    + "\">\n                                        <span class=\"indicator-icon\"></span>\n                                        <div class=\"AccountPanelTitle-Info\">\n                                                <h3 class=\"AccountPanelTitle-main\">\n                                                        <span class=\"account-number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"ACCOUNT_NUM") || (depth0 != null ? lookupProperty(depth0,"ACCOUNT_NUM") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNT_NUM","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":100}}}) : helper)))
    + "</span>\n                                                        <span class=\"account-currency\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"CURRENCY_CODE") || (depth0 != null ? lookupProperty(depth0,"CURRENCY_CODE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"CURRENCY_CODE","hash":{},"data":data,"loc":{"start":{"line":10,"column":87},"end":{"line":10,"column":104}}}) : helper)))
    + "</span>\n                                                </h3>\n                                                <span class=\"AccountPanelTitle-secondary\">\n                                                        <span class=\"account-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":13,"column":83},"end":{"line":13,"column":98}}}) : helper)))
    + "</span>\n                                                </span>\n                                        </div>\n                                </a>\n                        </h3>\n                </div>\n        </div>\n        <div class=\"AccountReportPanel-section AccountPanelStatus\">\n                <div class=\"BTRLoader hide\" data-hook=\"getLoadingState\">\n                        <p>"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"GIR.updatingAccountInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":27},"end":{"line":22,"column":63}}}))
    + "</p>\n                        <div class=\"loader\">\n                        </div>\n                </div>\n                <div class=\"BTRWarning hide\" data-hook=\"getErrorState\">\n                        <span class=\"icon-warning\"\n                        data-toggle=\"popover\"\n                        data-trigger=\"hover\"\n                        data-placement=\"top\"\n                        data-content=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"GIR.unableUpdateAccountInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":38},"end":{"line":31,"column":78}}}))
    + "\"\n                        aria-label=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"GIR.unableUpdateAccountInfo",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":36},"end":{"line":32,"column":76}}}))
    + "\">\n                        </span>\n                </div>\n        </div>\n        <div class=\"AccountReportPanel-section AccountPanelTotals\">\n                <div data-hook=\"account-summary-region\"></div>\n        </div>\n</div>\n\n<div aria-expanded=\"false\" id=\"combined-account-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":41,"column":54}}}) : helper)))
    + "\" class=\"AccountReportPanel-body panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":159},"end":{"line":41,"column":165}}}) : helper)))
    + "\">\n        <div class=\"panel-body\">\n                <div class=\"AccountReportPanel-links\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAccountPrint") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":49,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayPrintTransactionsText") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":54,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div data-hook=\"summary-body-region\"></div>\n                <section class=\"section-body\">\n                        <div data-region=\"accountTransactionBalanceRegion\"></div>\n                        <div data-region=\"transactionDetailsRegion\"></div>\n                        <div data-region=\"transactionDetailsSecondaryRegion\"></div>\n                </section>\n        </div>\n</div>\n";
},"useData":true});