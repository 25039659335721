var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lockboxItem\">\n        <div class=\"field-container-sm\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":63}}}))
    + " <br/><strong>"
    + alias2((lookupProperty(helpers,"maskIt")||(depth0 && lookupProperty(depth0,"maskIt"))||alias3).call(alias1,(depth0 != null ? lookupProperty(depth0,"ACCOUNTNUMBER") : depth0),{"name":"maskIt","hash":{},"data":data,"loc":{"start":{"line":3,"column":77},"end":{"line":3,"column":101}}}))
    + "</strong></div>\n        <div class=\"field-container-sm\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.BankRoutingCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":40},"end":{"line":4,"column":72}}}))
    + " <br/><strong>"
    + alias2(((helper = (helper = lookupProperty(helpers,"ABA") || (depth0 != null ? lookupProperty(depth0,"ABA") : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias1,{"name":"ABA","hash":{},"data":data,"loc":{"start":{"line":4,"column":86},"end":{"line":4,"column":93}}}) : helper)))
    + "</strong></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdvanced") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"icon-cancel pull-right\" data-action=\"removeAccount\"></span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lockboxNewItem\">\n        <div class=\"field-container-sm\">\n            <label for=\"ACCOUNTNUMBER\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":62}}}))
    + "</label>\n            <input id=\"ACCOUNTNUMBER\" type=\"text\" class=\"form-control\" name=\"ACCOUNTNUMBER\" maxlength=\"20\" data-bind=\"model\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTNUMBER\"></span>\n\n        </div>\n        <div class=\"field-container-sm\">\n            <label for=\"ABA\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"LBX.BankRoutingCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":29},"end":{"line":18,"column":61}}}))
    + "</label>\n            <input id=\"ABA\" type=\"text\" class=\"form-control\" data-bind=\"model\" maxlength=\"15\" name=\"ABA\">\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"ABA\"></span>\n        </div>\n        <span class=\"icon-cancel pull-right\" data-action=\"removeAccount\"></span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isExistingAccount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":24,"column":7}}})) != null ? stack1 : "");
},"useData":true});