import Model from './model';

export default Model.extend({
  initialize(attributes, options) {
    this.originalConstructorArguments = {
      attributesAsJson: JSON.stringify(attributes),
      options
    };

    this.on('change destroy', () => {
      throw new Error('An immutable model was changed. If you need to change this model, get a copy by calling yourModel.mutableCopy()');
    });
  },

  mutableCopy() {
    const attributes = JSON.parse(this.originalConstructorArguments.attributesAsJson);
    const { options } = this.originalConstructorArguments;
    return new Model(attributes, options);
  }
});

