import constants from 'app/smbInvoice/constants';
import ContextApi from 'common/dynamicPages/api/context';
import GridApi from 'common/dynamicPages/api/grid';
import enterInvoiceGridTmpl from './enterInvoiceGrid.hbs';
import GridLayout from './gridLayout';

export default GridLayout.extend({
    name: 'Enter',
    template: enterInvoiceGridTmpl,

    regions: {
        pageRegion: '.pageRegion',
        enterInvoiceRegion: '[data-region="enterInvoiceGrid"]',
    },

    initialize() {
        const options = {
            context: ContextApi.menuContext.getContext('BOS_INVOICE'),
            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            columnsToBeHidden: constants.COMMON_COLUMNSTOHIDE,
        };
        this.gridView = GridApi.createServiceGridView(options);
    },

    onShow(...args) {
        this.enterInvoiceRegion.show(this.gridView);
        GridLayout.prototype.onShow.apply(this, args);
    },

    removeActions() {
        this.gridView.grid.collection.each((rowParam) => {
            const row = rowParam;
            let VIEW = null;
            let MODIFY = null;
            for (let i = 0; i < row.buttons.length; i += 1) {
                if (row.buttons[i].action === 'SELECT') {
                    VIEW = row.buttons[i];
                } else if (row.buttons[i].action === 'MODIFY') {
                    MODIFY = row.buttons[i];
                }
            }
            row.buttons = [];
            if (MODIFY) {
                row.buttons.push(MODIFY);
            }
            row.buttons.push(VIEW);
        });
    },
});
