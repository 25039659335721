import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import styles from './ActionButtonContainer.styles';

const propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
};

const ActionButtonContainer = ({
    children,
    classes,
}) => (
    <section className={classes.section}>
        {children}
    </section>
);

ActionButtonContainer.propTypes = propTypes;

export default withStyles(styles)(ActionButtonContainer);
