import InvoiceView from 'app/smbInvoice/views/invoiceList';
import InvoiceFormView from 'app/smbInvoice/views/invoiceForm';
import StackView from 'common/stack/views/stackView';
import WorkspaceView from 'common/workspaces/views/workspace';

export default {
    /**
     * Return the default bill workspace view.
     */
    invoiceWorkspaceDummy() {
        this.showPage('', new InvoiceView());
    },

    invoiceWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'SMBINVOICE',
                    returnRoute: 'invoice/workspace',
                }),
            }));
        }
    },

    invoiceView(id) {
        this.showPage('', new InvoiceFormView({
            INVOICE_ID: id,
            readOnly: true,
        }));
    },

    invoiceModify(id) {
        this.showPage('', new InvoiceFormView({
            INVOICE_ID: id,
        }));
    },

    invoiceCreate() {
        this.showPage('', new InvoiceFormView({}));
    },
};
