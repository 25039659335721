import ItemView from '@glu/core/src/itemView';
import CollectionView from '@glu/core/src/collectionView';
import AccountView from 'app/accounts/views/accountView';
import AccountGroup from 'app/accounts/models/accountGroup';
import AccountGroupView from 'app/accounts/views/accountGroupView';
import MoveAccountsControl from 'app/accounts/views/moveAccountsControl';
import locale from '@glu/locale';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import dialog from '@glu/dialog';
import manageAccountsViewTmpl from './manageAccountsView.hbs';

const ManageAccountsView = ItemView.extend({

    initialize(options) {
        this.sections = options.sections;
        this.sectionId = options.sectionId;
        this.accounts = options.accounts;
        this.groups = options.groups;
        this.tabId = options.tabId;
        util.bindAll(this, 'onDragAndDrop');

        this.listenTo(this.groups, 'account:add', (account) => {
            this.accounts.get(account.accountFilter()).incrementGroupCount();
        });

        this.listenTo(this.groups, 'account:remove', (account) => {
            this.accounts.get(account.accountFilter()).decrementGroupCount();
        });
    },

    template: manageAccountsViewTmpl,

    className: 'manage-accounts list-builder',

    id: 'manage-accounts',

    getHeader() {
        return locale.get('gir.manageAccountGroups');
    },

    ui: {
        $sectionIdRadioButtons: 'input[name="section-id"]',
        $filterField: 'input[name="account-filter"]',
    },

    events: {
        'click [data-action=add-group]': 'createANewGroup',
        'keyup @ui.$filterField': 'applySearchFilter',
        'change @ui.$sectionIdRadioButtons': 'setSectionId',
    },

    onRender() {
        // Load up left side of list builder.
        this.sourceAccountList = new CollectionView({
            el: this.$('.source-list')[0],
            collection: this.accounts,
            itemView: AccountView,
        });

        // Load up right side of list builder.
        this.accountGroups = new CollectionView({
            el: this.$('.account-groups')[0],
            collection: this.groups,
            itemView: AccountGroupView,
        });

        // Load up dropdown for available account groups.
        this.moveAccountsControl = new MoveAccountsControl({
            el: this.$('.move-accounts-control')[0],
            groups: this.groups,
            accounts: this.accounts,
            sectionId: this.sectionId,
        });

        this.sourceAccountList.render();
        this.accountGroups.render();
        this.moveAccountsControl.render();

        this.initializeDragAndDrop();

        /**
         * TODO Use the selection from the dynamic link to show the correct button.
         * Now set the first button to active as before.
         */
        this.ui.$sectionIdRadioButtons.filter(`[value="${this.sectionId}"]`).prop('checked', true).parents('.btn').addClass('active');
        this.setSectionId();

        this.listenTo(this.accounts, 'sync', this.initializeDragAndDrop);
        this.listenTo(this.groups, 'sync', this.initializeDragAndDrop);

        this.listenTo(this.accounts, 'sync', this.updateSourceAccountListHeader);
    },

    /*
     * @method: setSectionId
     * Manipulate the listbuilder based on the button selection.
     */
    setSectionId() {
        // FIXME This should be refactored to not manipulate via css.
        this.sectionId = this.ui.$sectionIdRadioButtons.filter(':checked').val();
        this.$el.attr('data-section-id', this.sectionId);
        this.$(`.source-section li[data-section-id!="${this.sectionId}"]`).addClass('hide-account');
        this.$(`.source-section li[data-section-id="${this.sectionId}"]`).removeClass('hide-account');
        this.$(`.account-groups li[data-section-id!="${this.sectionId}"]`).addClass('hide-account');
        this.$(`.account-groups li[data-section-id="${this.sectionId}"]`).removeClass('hide-account');
        this.moveAccountsControl.options.sectionId = this.sectionId;
        this.moveAccountsControl.render();
        this.updateSourceAccountListHeader();
    },

    /*
     * @method: updateSourceAccountListHeader
     * Update the counts in the listbuilder.
     */
    updateSourceAccountListHeader() {
        const { sectionId } = this;
        const numberOfAccounts = this.accounts
            .filter(account => account.sectionId() === sectionId).length;
        const headerText = locale.get('gir.manageAccountGroups.numAccounts', numberOfAccounts);
        this.$('.source-section > h3').text(headerText);
    },

    /*
     * @method: getButtons
     * Set up buttons for the modal
     */
    getButtons() {
        const { groups } = this;
        return [{
            text: locale.get('gir.manageAccountGroups.save'),
            className: 'btn-primary',
            callback: () => {
                groups.save().then((data) => {
                    if (data.result) {
                        if (data.result !== 'noop') {
                            this.trigger('resolve', data);
                            this.appBus.trigger('accountSummary:refreshAccountAccess');
                        }
                    } else {
                        this.trigger('resolve', data);
                    }
                    dialog.close();
                }, (data) => {
                    throw new Error(data.message.concat('\n'));
                });
            },
        }, {
            text: locale.get('gir.manageAccountGroups.cancel'),
            className: 'btn-secondary',
            callback() {
                dialog.close();
            },
        }];
    },

    /*
     * @method: initializeDragAndDrop
     * Set up drag and drop
     */
    initializeDragAndDrop() {
        this.$('.list-builder-item').draggable({
            helper: 'clone',
            appendTo: '.manage-accounts',
            containment: '.manage-accounts',
            start: (event, ui) => {
                ui.helper.width(this.$(event.target).width());
                ui.helper.height(this.$(event.target).height());
            },
        });

        this.$('.account-group .list-builder-item').droppable({
            drop: (event, ui) => {
                const parentGroup = this.$(event.target).closest('.account-group');
                this.onDragAndDrop(ui.draggable, parentGroup, this.$(event.target));
            },
            accept() {
                return true;
            },
            hoverClass: 'drop-hover-position',
        });

        this.$('.account-group, .target-list').droppable({
            drop: (event, ui) => {
                this.onDragAndDrop(ui.draggable, this.$(event.target));
            },
            accept: draggable => this.isDropAccepted(draggable, this.$(this)),
            activeClass: 'ui-state-active',
            hoverClass: 'drop-hover',
            greedy: true,
        });
    },

    /*
     * @method: onDragAndDrop
     * Process drag and drop
     */
    onDragAndDrop(draggable, droppable, insertAtElement) {
        const account = this.accounts.get(draggable.data('accountFilter'));
        const fromGroupId = draggable.parents('.account-group').data('groupId');
        const toGroupId = droppable.data('groupId');
        let wasAdded;
        let indx;

        if (toGroupId) {
            const toGroupObj = this.groups.get(toGroupId);
            const fromSourceList = util.isUndefined(fromGroupId);
            wasAdded = toGroupObj.add(account.clone());

            /**
             * When the user is re-ordering the list of accounts within a group,
             * this code handles that
             */
            if (insertAtElement || (fromSourceList && wasAdded)) {
                // get the index placement for this account.
                indx = -1;
                if (insertAtElement) {
                    const insertAtAccount = this.accounts.get(insertAtElement.data('accountFilter'));

                    if (insertAtAccount) {
                        const toGroupAccts = toGroupObj.get('accounts');
                        for (let j = 0; j < toGroupAccts.models.length; j += 1) {
                            if (toGroupAccts.models[j].get('AccountFilter') === insertAtAccount.get('AccountFilter')) {
                                indx = j;
                            }
                        }
                    }
                }

                /**
                 * Remove this account from the group if it is just changing position within
                 * the same group.
                 */
                let wasRemoved = false;
                if ((fromGroupId === toGroupId || insertAtElement)
                        && this.groups.get(toGroupId).contains(account)) {
                    this.groups.get(toGroupId).remove(account);
                    wasRemoved = true;
                }

                // insert the account at the index
                if (indx >= 0) {
                    wasAdded = toGroupObj.attributes.accounts.add(account.clone(), {
                        at: indx,
                    });
                }

                // This account was only added if it didn't exist in the group already.
                wasAdded = (wasAdded && !wasRemoved);

                /*
                 * even though we are inserting the account at the index
                 * (in the previous line of code) the list of accounts within the
                 * corresponding
                 * group does not reflect that change. The following code is to address
                 * that issue. It's not the most efficient way but since the list of accounts
                 * are expected to be small it should be fine
                 */
                if (fromGroupId || insertAtElement) {
                    const arrModels = [];
                    let i = 0;
                    while (toGroupObj.get('accounts').length > 0) {
                        arrModels[i] = toGroupObj.get('accounts').pop();
                        i += 1;
                    }
                    while (i > 0) {
                        i -= 1;
                        toGroupObj.add(arrModels[i]);
                    }
                }
            }
        }

        if (fromGroupId && wasAdded) {
            this.groups.get(fromGroupId).remove(account);
        }

        this.initializeDragAndDrop();
    },

    /*
     * @method: isDropAccepted
     * @returns {boolean} - true
     * Always return true
     */
    isDropAccepted() {
        return true;
    },

    /*
     * @method: createANewGroup
     * @returns {AccountGroup} - returns the new group
     * Called when new account group button is pressed
     */
    createANewGroup() {
        const newGroup = new AccountGroup({
            NAME: `${locale.get('gir.newGroup')} ${this.groups.length}1`,
            accounts: new Collection(),
            SECTIONID: this.sectionId,
            TABID: this.tabId,
        });
        this.groups.add(newGroup);
        this.initializeDragAndDrop();
        return newGroup;
    },

    /*
     * @method: toggleFilter
     * Toggle filter field and apply filter.
     */
    toggleFilter() {
        // check whether the search field is hidden to set focus
        this.ui.$filterField.toggle();
        if (this.ui.$filterField.is(':visible')) {
            this.ui.$filterField.focus();
        }
        this.applySearchFilter();
    },

    /*
     * @method: applySearchFilter
     * Apply entered filter
     */
    applySearchFilter() {
        const searchTerm = this.ui.$filterField.is(':visible') ? this.ui.$filterField.val().trim() : '';
        this.accounts.invoke('applySearchFilter', searchTerm);
    },

    templateHelpers() {
        return {
            id: this.cid,

            // Return the dynamic sections
            sections: this.sections.toJSON(),
        };
    },

});

export default ManageAccountsView;
