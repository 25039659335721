import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';
import Formatter from 'system/utilities/format';
import lockboxProfileModel from 'app/reports/models/lockboxProfile';

export default Collection.extend({
    initialize(options) {
        if (options) {
            this.serviceType = options.type;
            this.rid = options.rid;
        }
    },

    model: lockboxProfileModel,

    parse(data) {
        if (!this.serviceType || this.serviceType === 'profile') {
            const profileArr = [];

            util.each(data, (profileParam) => {
                const profile = profileParam;
                const profileObj = {};
                // TODO: Don't need to have separate lines.
                profile.FROM_DATE = Formatter.formatDate(profile.FROM_DATE);
                profile.TO_DATE = Formatter.formatDate(profile.TO_DATE);
                profileObj.dateType = profile.DATETYPE;
                profileObj.text = Formatter.unescapeAmpersand(profile.REPORT_NAME);
                profileObj.reportId = profile.REPORT_ID;
                profileObj.toDate = profile.TO_DATE;
                profileObj.fromDate = profile.FROM_DATE;
                profileObj.canDelete = true;
                profileObj.canEdit = true;
                profileArr.push(profileObj);
            });

            return util.sortBy(profileArr, profile => profile.text);
        }

        if (this.serviceType === 'transactions') {
            const transactionArr = [];
            util.each(data, (transactionType) => {
                transactionArr.push({
                    reportId: transactionType.REPORT_ID,
                    type: transactionType.TRANSTYPE,
                });
            });
            return transactionArr;
        }

        if (this.serviceType === 'lockbox') {
            const lockboxArr = [];
            util.each(data, (lockbox) => {
                lockboxArr.push({
                    reportId: lockbox.REPORT_ID,
                    lockboxId: lockbox.LOCKBOX_ID,
                    locationId: lockbox.LOCATION_ID,
                });
            });
            return lockboxArr;
        }
        // TODO: Should this be undefined?
        return undefined;
    },

    sync(method, model, options) {
        let url;
        let req = {};

        if (this.serviceType) {
            switch (this.serviceType) {
            case 'transactions':
                url = services.generateUrl('/tableMaintenance/lockboxProfilesTran/getListView');
                break;
            case 'lockbox':
                url = services.generateUrl('/tableMaintenance/lockboxProfilesLock/getListView');
                break;
            case 'profiles':
            default:
                url = services.generateUrl('/tableMaintenance/lockboxProfiles/getListView');
            }

            if (this.rid) {
                req = {
                    searchFields: [{
                        fieldName: 'REPORT_ID',
                        fieldValue: [this.rid],
                        dataType: 'text',
                        operator: '=',
                    }],
                };
            }
        } else {
            url = services.generateUrl('/tableMaintenance/lockboxProfiles/getListView');
        }

        http.post(url, req, (result) => {
            const remitters = [];
            util.each(result.rows, (data) => {
                const remitter = {};
                for (let x = 0; x < data.columns.length; x += 1) {
                    remitter[data.columns[x].fieldName] = data.columns[x].fieldValue;
                }
                remitters.push(remitter);
            });
            options.success(remitters);
        }, (result) => {
            options.error(result);
        });
    },
});
