import integrationUtils from 'ptxReact/system/integrationUtils';

class Url {
    constructor(url) {
        this.value = url;
    }

    replace(...args) {
        return this.toString().replace(...args);
    }

    params(options) {
        if (!options) {
            return this;
        }
        // based on GLU-1278
        return new Url(this.value.replace(/\(?\/:\w+\)?/g, (match) => {
            const optionsKey = match.charAt(0) === '(' ? match.slice(3, match.length - 1) : match.slice(2);
            const option = options[optionsKey];
            return option !== undefined ? `/${option}` : '';
        }));
    }

    query(options) {
        if (!options) {
            return this;
        }
        let url = this.value;
        let separator = '?';
        Object.keys(options).forEach((option) => {
            url += `${separator}${option}=${encodeURIComponent(options[option])}`;
            separator = '&';
        });
        return new Url(url);
    }

    toString() {
        return this.value;
    }
}

export const urls = integrationUtils.applyServiceUrlProcessors({
    audit: '/api/audit',
    refs: '/api/refs',
    activity: '/api/admin/mailings',
    featureToggle: '/api/Feature',
    customers: '/api/admin/accounts',
    customerUsers: '/api/admin/accounts/:customer/users',
    adminDocumentHistory: '/api/admin/document/history',
    adminDocuments: '/api/admin/document/:searchUrl',
    customer: '/api/admin/accounts/:customer',
    adminUsers: '/api/admin/users',
    newCustomer: '/api/admin/signup',
    projectsAdmin: '/api/admin/:customer/projects',
    projectAdmin: '/api/admin/:customer/projects/:id',
    projectAdminDownload: '/api/admin/:customer/projects/:project/download',
    resendEmailToUser: '/api/admin/:customer/signup/:user/resend',
    documents: '/api/documents',
    document: '/api/documents/:id',
    documentsArchived: '/api/documents/archived',
    btsBatches: '/api/bts/batches',
    btsBatch: '/api/bts/batches/:btsBatchId',
    btsBatchDocuments: '/api/bts/batches/:btsBatchId/documents',
    btsBatchPrintFile: '/api/bts/batches/:printFileId/download-print-file',
    btsRetrieveBatchStatus: '/api/bts/batches/retrieve-batch-status',
    btsProfiles: '/api/bts/profiles',
    btsProfile: '/api/bts/profiles/:id',
    btsProfilesSimplified: '/api/bts/profiles/simple',
    btsDeleteTestBatches: '/api/bts/profiles/delete-test-batches',
    cheques: '/api/cheques',
    cheque: '/api/cheques/:id',
    chequesArchived: '/api/cheques/archived',
    voidCheque: '/api/cheques/:documentId/void',
    chequeBatches: '/api/cheques/batches',
    chequeMarkPrinted: '/api/cheques/mark-printed',
    chequeMarkNotPrinted: '/api/cheques/mark-not-printed',
    documentsBatches: '/api/batches',
    approveBatch: '/api/batches/:batchId/approve',
    needsWorkBatch: '/api/batches/:batchId/needs-work',
    createBatch: '/api/batches/create',
    printBatch: '/api/printjobs/:printerId/batch/:batchId',
    reprintFailed: '/api/batches/:batchId/reprint-failed',
    reprintFailedBulk: '/api/batches/reprint-failed',
    submitBatch: '/api/batches/:batchId/submit',
    voidBatch: '/api/batches/:batchId/void',
    markAsArchivedBatch: '/api/batches/:batchId/archive',
    updateBatchName: '/api/batches/:batchId',
    chequeBatchAudits: '/api/batches/:batchId/audit',
    reportsForBatch: '/api/batches/:batchId/reportfiles',
    batchDomLogs: '/api/batches/:batchId/dom-logs',
    downloadDomLogInputFile: '/api/batches/:batchId/dom-logs/:domLogId/input-file',
    downloadDomLogFile: '/api/batches/:batchId/dom-logs/:domLogId/log-file',
    report: '/api/batches/:batchId/reportfiles/:fileId',
    retryBatchProcessing: '/api/batches/:batchId/processing/retry',
    batchBulkSubmit: '/api/batches/submit',
    batchBulkMarkAsArchived: '/api/batches/archive',
    batchBulkRetryProcessing: '/api/batches/processing/retry',
    batchBulkPrint: '/api/printjobs/:printerId/batches',
    chequesForBatch: '/api/cheques',
    documentDownload: '/api/documents/:document/pdf',
    documentPng: '/api/documents/:document/png/:pageNumber',
    documentsSend: '/api/documents/send',
    documentsDownload: '/api/documents/download',
    documentsDownloadV2: '/api/documents/download/1.0',
    documentsMove: '/api/documents/change-inbox',
    documentHistory: '/api/documents/:document/history',
    supportingDocuments: '/api/documents/:document/supportingfiles',
    documentDiscussion: '/api/documents/:document/discussion',
    documentDiscussionClose: '/api/documents/:document/discussion/close',
    documentDiscussionOpen: '/api/documents/:document/discussion/open',
    discussionsCount: '/api/documents/discussions',
    personalInbox: '/api/documents/personal',
    apps: '/api/apps',
    logos: '/api/logos',
    singleLogo: '/api/logos/:logoId',
    deleteLogo: '/api/logos/:id',
    mailings: '/api/batches',
    domlogs: '/api/domlogs',
    domlogsPaginated: '/api/domlogs/search',
    documentMailingIds: '/api/batches/:mailing/ids',
    users: '/api/users',
    contacts: '/api/contacts',
    contact: '/api/contacts/:id',
    deleteContacts: '/api/contacts/delete',
    profileNotifications: '/api/profiles/:profileId/notifications',
    mailingProfiles: '/api/mailingprofiles',
    mailingProfile: '/api/mailingprofiles/:profileId/1.0',
    mailingProfilesSimplified: '/api/mailingprofiles/simple',
    emailTemplatePreview: '/api/mailingprofiles/emailTemplatePreview',
    mailingLogDownload: '/api/batches/:mailing/log',
    mailingInputFileDownload: '/api/batches/:mailing/inputfile',
    profileLogDownload: '/api/batches/:batchId/dom-logs/:domLogId/log-file',
    profileInputFileDownload: '/api/batches/:batchId/dom-logs/:inputFileId/input-file',
    chequebooks: '/api/cheques/cheque-books',
    chequebook: '/api/cheques/cheque-books/:id',
    projectsLayoutsApi: '/api/projects/layouts',
    jobs: '/api/jobs',
    layoutPreview: '/api/layouts/preview/:project',
    print: '/api/print/:secretId',
    account: '/api/account',
    roles: '/api/roles',
    role: '/api/roles/:id',
    login: '/api/auth/login',
    logout: '/api/auth/logout',
    resetPassword: '/api/account/reset',
    emailLogo: '/api/mailingprofiles/emaillogo',
    outboxActivity: '/api/outbox-activity',
    actionActivity: '/api/action-activity',
    watchOutbox: '/api/users/current/notifications',
    unregisteredDocumentPrint: '/api/print/:secretId',
    printers: '/api/printers',
    deleteOrphanedPrinters: '/api/printers/delete-orphaned',
    virtualTrays: '/api/printers/virtualtrays',
    printJobs: '/api/printjobs/printer/:printerId',
    printDocuments: '/api/printjobs/:printerId',
    printJobRetry: '/api/printjobs/retry',
    printJobCancel: '/api/printjobs/cancel',
    printJobsCount: '/api/printjobs/count',
    deleteStuckJobs: '/api/printjobs/deletestuckjobs',
    projects: '/api/projects',
    projectById: '/api/projects/:id',
    projectDownload: '/api/projects/:project/download',
    retryAllJobs: '/api/printers/:printer/jobs/retry',
    cancelAllJobs: '/api/printers/:printer/jobs/cancel',
    redirectAllDocuments: '/api/printers/:printer/jobs/requeueon',
    handshake: '/api/security/handshake',
    verifyDomain: '/api/verifydomain',
    domainSendToIT: '/api/verifydomain/email',
    defaultDomain: '/api/verifydomain/default',
    btsArchive: '/api/bts/batches/:batchId/archive',
    btsBatchArchive: '/api/bts/batches/archive',
    inboxRules: '/api/inbox/rules',
    inboxRule: '/api/inbox/rules/:id',

    historyEmail: '/history-email',
    historySid: '/history-sid',
    unregisteredDocument: '/document/:secretId/:secretPin',
    unregisteredDocumentDiscussions: '/document/:secretId/discussion',
    supportingDocumentsUnregistered: '/document/:secretId/supportingfiles',
    supportingDocumentUnregistered: '/document/:secretId/supportingfiles/:fileId',
    unregisteredDocumentDownload: '/document/:secretId/pdf',
    unregisteredDocumentPng: '/document/:secretId/png/:pageNumber',
    registerOnePtxUser: '/document/register-existing/1.0',
    registerNewUser: '/document/register/1.0',
    downloadApp: '/ptxlink',
    uiVersion: '/src/version.json',
    preAuth: '/api/auth/preauth',

    whyRegister: 'https://go.bottomline.com/PT-X-Connect-Unregistered',
    outboxRegistered: 'https://go.bottomline.com/PT-X-Connect-Registered',

    webFormsSettings: '/api/web-forms/settings',
    webFormsInboxPay: '/api/web-forms/pay/:documentId/:paymentType',
    webFormsUnregistered: '/api/web-forms/pay/:secretId/:secretPin/:paymentType',
    webFormsHeartbeat: '/api/web-forms/url',
    heartbeat: '/api/heartbeat',
    revokeAccess: '/api/documents/revoke-access',
    unRevokeAccess: '/api/documents/unrevoke-access',
});

export class Urls {
    constructor(urlsToUse) {
        this.urls = urlsToUse;
        this.initializeUrls();
    }

    initializeUrls() {
        const urlInitializer = (url) => { this[url] = new Url(this.urls[url]); };
        Object.keys(this.urls).forEach(urlInitializer.bind(this));
    }

    setUrlPrefix(domainUrl) {
        const urlsParser = (url) => {
            switch (url) {
            case 'register':
            case 'whyRegister':
            case 'outboxRegistered':
                return;
            default:
                break;
            }
            this[url] = new Url(domainUrl + this.urls[url]);
        };

        Object.keys(this.urls).forEach(urlsParser.bind(this));
    }

    /**
   * Parse a query string into an object.
   * @example
   * // returns { foo: 'bar', baz: 'foobar' }
   * parseQueryString('?foo=bar&baz=foobar');
   * @param {String} queryString
   */
    // eslint-disable-next-line class-methods-use-this
    parseQueryString(queryString) {
        if (!queryString) {
            return {};
        }

        const response = {};

        queryString.substring(1).split('&').forEach((part) => {
            const [key, value] = part.split('=');
            response[key] = decodeURIComponent(value);
        });

        return response;
    }
}

export default new Urls(urls);
