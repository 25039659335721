var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"section section-container\">\n    <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Export.Detail",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":42}}}))
    + "</legend>\n    <div class=\"row\">\n        <div class=\"form-group col-md-4\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.File.Format",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":5,"column":19},"end":{"line":5,"column":47}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"fileFormatDesc") || (depth0 != null ? lookupProperty(depth0,"fileFormatDesc") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"fileFormatDesc","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":33}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"form-group col-md-12\">\n            <label>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Run.Times",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":19},"end":{"line":11,"column":45}}}))
    + "</label>\n            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"runTimes") || (depth0 != null ? lookupProperty(depth0,"runTimes") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"runTimes","hash":{},"data":data,"loc":{"start":{"line":12,"column":15},"end":{"line":12,"column":27}}}) : helper)))
    + "</p>\n        </div>\n    </div>\n</fieldset>\n<fieldset class=\"section section-container\">\n    <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"GIR.Report.Criteria",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":44}}}))
    + "</legend>\n    <div class=\"row\">\n        <div class=\"form-group col-md-4\">\n            <label>"
    + alias2(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"parentDropdown") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</label>\n            <p>"
    + alias2(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"parentDropdown") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "</p>\n        </div>\n        <div class=\"form-group col-md-4\">\n            <label>"
    + alias2(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"childDropdown") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</label>\n            <p>"
    + alias2(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"childDropdown") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "</p>\n        </div>\n    </div>\n</fieldset>\n";
},"useData":true});