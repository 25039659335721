import '../themeDefaults';

export default ({
  actionableIcon: {
    hoverBackgroundColor: actionableIconHoverBackgroundColor
  },
  mobilegrid
}) => ({
  root: {
    background: mobilegrid.footerBackground,
    bottom: mobilegrid.footerBottom,
    boxShadow: mobilegrid.footerBoxShadow,
    padding: mobilegrid.footerPadding,
    position: 'fixed',
    width: '100%',
    zIndex: mobilegrid.footerZIndex,
    '& button[class*="Icon"]:not([class*="iconOpen"])': {
      background: 'transparent',
      cursor: 'pointer'
    },
    '& button[class*="Icon"]:hover': {
      background: actionableIconHoverBackgroundColor
    }
  },
  iconWrap: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around'
  }
});
