import util from '@glu/core/src/util';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import alert from '@glu/alerts';

export default {
    renderMessage(viewParam, action, confirmResponse, errorCount, defaultConfirmData) {
        const view = viewParam;
        if (view && view.alertRegion) {
            /*
             * create the confirmation view for the alert
             * skip if action is null.  This occurs when first entering the payment workspace
             * because the onRender is called.
             */
            if ((action === null || action === undefined)
                && (confirmResponse === null || confirmResponse === undefined)) {
                // TODO: Refactor out empty block
            } else if (confirmResponse === null || confirmResponse === undefined) {
                if (action.type === 'WARNING' && action.message) {
                    view.alertView = alert.warning(action.message);
                    view.alertRegion.show(view.alertView);
                }
            } else {
                const confirm = typeof confirmResponse === 'object'
                    ? confirmResponse.confirms : null;
                const successCount = confirm !== null ? confirm.totalSuccess : confirmResponse;
                const failCount = confirm !== null ? confirm.totalFail : errorCount;
                const totalCount = failCount + successCount;
                const success = successCount === totalCount;
                const alertFunc = success ? alert.success : alert.danger;
                const chainedMessages = typeof confirmResponse === 'object'
                    ? confirmResponse.message : null;
                const message = typeof confirmResponse === 'object'
                    && confirmResponse.message.length > 0
                    ? confirmResponse.message[chainedMessages.length - 1] : action;

                const confirms = new Confirms({
                    confirms: confirmResponse ? confirmResponse.confirms : null,
                });

                if (defaultConfirmData) {
                    util.each(confirmResponse.confirms.confirmResults, (confirmResultParam) => {
                        const confirmResult = confirmResultParam;
                        if (confirmResult.confirmData && confirmResult.confirmData.length
                            && !confirmResult.confirmData[0].item) {
                            confirmResult.confirmData[0].item = {};
                        }
                    });
                }

                // display notification message
                view.alertView = alertFunc(message, {
                    details: confirmResponse && confirmResponse.confirms
                        && ((confirmResponse.confirms.confirmResults[0].confirmData
                            && confirmResponse.confirms.confirmResults[0].confirmData.length
                            && confirmResponse.confirms.confirmResults[0].confirmData[0].item)
                            || (confirmResponse.confirms.confirmResults[0].messages
                            && confirmResponse.confirms.confirmResults[0].messages.length))
                        ? confirms : null,
                    canDismiss: !!confirmResponse,
                    animate: true,
                });

                if (message) {
                    view.alertRegion.show(view.alertView);
                }
            }
        }
    },
};
