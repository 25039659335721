var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/addServices\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":65},"end":{"line":15,"column":99}}}))
    + "\n                            </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/viewServices\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":66},"end":{"line":19,"column":100}}}))
    + "\n                            </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/addProfile\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.defaults",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":27,"column":64},"end":{"line":27,"column":98}}}))
    + "\n                            </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"NavTabs-link\"\n                                href=\"/SEGMENTATION/viewProfile\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.menu.defaults",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":31,"column":65},"end":{"line":31,"column":99}}}))
    + "\n                            </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary submit\" data-hook=\"getSubmitBtn\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"segment.btn.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":65,"column":98},"end":{"line":65,"column":127}}}))
    + "</button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"btn btn-primary submit\" data-hook=\"getSubmitBtn\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":98},"end":{"line":67,"column":131}}}))
    + "</button>\n                    <button type=\"button\" class=\"btn btn-secondary \" data-action=\"back\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.back",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":68,"column":88},"end":{"line":68,"column":117}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"segmentation payment-limits\">\n    <div class=\"page-header-wrapper\">\n        <h1 class=\"landing-header\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.header",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":62}}}))
    + "</h1>\n    </div>\n\n    <section class=\"section section-container\" role=\"navigation\">\n\n        <div class=\"section-body\">\n            <ul class=\"NavTabs nav nav-tabs is-open\" role=\"tablist\"\n                data-hook=\"getNavTabs\">\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":21,"column":26}}})) != null ? stack1 : "")
    + "                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":33,"column":26}}})) != null ? stack1 : "")
    + "                </li>\n                <li class=\"NavTabs-item is-active\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/addPaymentLimits\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":62},"end":{"line":38,"column":94}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/externalWidgets\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.bankwidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":61},"end":{"line":44,"column":98}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"NavTabs-item\"\n                   role=\"tab\">\n                    <a class=\"NavTabs-link\"\n                        href=\"/SEGMENTATION/elearning\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.menu.elearning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":55},"end":{"line":50,"column":90}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n\n                        <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n            <!-- PAYMENT LIMIT ITEMS -->\n            <fieldset data-region=\"paymentLimitsItemsRegion\"></fieldset>\n\n\n        </div>\n        <div class=\"row\">\n            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditMode") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":64,"column":16},"end":{"line":69,"column":23}}})) != null ? stack1 : "")
    + "\n                    <button type=\"button\" class=\"btn btn-secondary\" data-action=\"cancel\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"segment.btn.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":71,"column":89},"end":{"line":71,"column":120}}}))
    + "</button>\n            </div>\n        </div>\n    </section>\n\n</div>\n";
},"useData":true});