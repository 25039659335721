import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import Passcode from 'app/administration/models/securityPasscode';
import SecurityPasscodeCollection from 'app/administration/collection/securityPasscodes';
import Constants from 'app/administration/constants';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import AlertDetails from './alerts/passcodeAlertDetails';
import TextMessageCollectionView from './textMessaging/list';
import VoiceCollectionView from './voiceMessaging/list';
import EmailCollectionView from './emailMessaging/list';
import passcodeViewTmpl from './passcodeView.hbs';

const PasscodeSettingsLayout = Layout.extend({
    template: passcodeViewTmpl,
    loadingTemplate: loadingWidgetTmpl,

    initialize(options) {
        const self = this;
        self.initialSetup = options.initialSetup || false;
        self.model = options.model || new Model();
        this.listenTo(this.appBus, ('security:contact:default'), this.handleDefaultContact.bind(this));

        self.maxContacts = serverConfigParams.get('passcodeMaxSectionContacts') !== undefined ? serverConfigParams.get('passcodeMaxSectionContacts') : 5;
        // convert strings to boolean
        self.allowEmail = serverConfigParams.get('passcodeAllowEmail') === 'true';
        self.allowSMS = serverConfigParams.get('passcodeAllowSMS') === 'true';
        self.allowVoice = serverConfigParams.get('passcodeAllowVoice') === 'true';

        this.setNotificationData({
            title: 'PassCodeSettings',
        });
    },

    regions: {
        textMessageContactRegion: '.textmessage-contacts-region',
        voiceMessageContactRegion: '.voicemessage-contacts-region',
        emailMessageContactRegion: '.email-contacts-region',
    },

    ui: {
        addTextBtn: '[data-action=addPhoneText]',
        addVoiceBtn: '[data-action=addPhoneVoice]',
        addEmailBtn: '[data-action=addEmail]',
        alertRegion: '.alert-region',
    },

    appEvents: {
        'security:contact:default': 'handleDefaultContact',
    },

    onRender() {
        const self = this;
        if (self.hasLoadedRequiredData()) {
            self.handleAddVoiceBtn();
            self.handleAddEmailBtn();
            self.displayMessage();
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;

        self.collection = new SecurityPasscodeCollection();
        self.collection.fetch({
            success() {
                /* break out our collections for each section */
                self.textCollection = self.collection.byChannel(Constants.PASSCODE_TEXT_CHANNEL);
                self.voiceCollection = self.collection.byChannel(Constants.PASSCODE_VOICE_CHANNEL);
                self.emailCollection = self.collection.byChannel(Constants.PASSCODE_EMAIL_CHANNEL);

                self.listenTo(self.textCollection, 'change', self.handleAddTextBtn);
                self.listenTo(self.voiceCollection, 'change', self.handleAddVoiceBtn);
                self.listenTo(self.emailCollection, 'change', self.handleAddEmailBtn);

                // if the collection is empty, add one model
                self.addPhoneText();
                self.listenTo(self.textCollection, 'remove', self.createTextMessageField);
                const textContacts = new TextMessageCollectionView({
                    collection: self.textCollection,
                });

                // if the collection is empty, add one model
                self.addPhoneVoice();
                const voiceContacts = new VoiceCollectionView({
                    collection: self.voiceCollection,
                });

                // if the collection is empty, add one model
                self.addEmail();
                const emailContacts = new EmailCollectionView({
                    collection: self.emailCollection,
                });

                self.setHasLoadedRequiredData(true);
                self.render();

                if (self.allowSMS) {
                    self.textMessageContactRegion.show(textContacts);
                }
                if (self.allowVoice) {
                    self.voiceMessageContactRegion.show(voiceContacts);
                }

                if (self.allowEmail) {
                    self.emailMessageContactRegion.show(emailContacts);
                }
                self.notifyPageLoaded();
            },
        });
    },

    templateHelpers() {
        const self = this;

        return {
            allowSMS: self.allowSMS,
            allowEmail: self.allowEmail,
            allowVoice: self.allowVoice,
        };
    },

    /* If the user deletes all the text message contacts, add an empty field */
    createTextMessageField() {
        const self = this;
        if (self.textCollection.length === 0) {
            self.populateScreen(self.textCollection, false, Constants.PASSCODE_TEXT_CHANNEL);
        }
    },

    /*
     * Make sure there is at least 1 entry field in each section
     */
    populateScreen(list, defaultItem, channel, verified) {
        list.push(new Passcode({
            editable: true,
            default: defaultItem,
            channel,
            verified,
        }));
    },

    handleAddVoiceBtn() {
        const self = this;

        const currentCount = self.voiceCollection.where({
            removeRecord: false,
        }).length;
        if (currentCount >= self.maxContacts) {
            self.ui.addVoiceBtn.hide();
        } else {
            self.ui.addVoiceBtn.show();
        }
    },

    addPhoneVoice() {
        const self = this;
        const currentCount = self.voiceCollection.where({
            removeRecord: false,
        }).length;
        if (currentCount < self.maxContacts) {
            let allValid = true;
            util.each(self.voiceCollection.models, (model) => {
                if (model.isValid()) {
                    model.set('editable', false);
                } else {
                    allValid = false;
                }
            });

            if (allValid) {
                self.populateScreen(
                    self.voiceCollection,
                    false,
                    Constants.PASSCODE_VOICE_CHANNEL,
                    true,
                );
            }
        }
    },

    handleAddEmailBtn() {
        const self = this;
        const currentCount = self.emailCollection.where({
            removeRecord: false,
        }).length;

        if (currentCount >= self.maxContacts) {
            self.ui.addEmailBtn.hide();
        } else {
            self.ui.addEmailBtn.show();
        }
    },

    addEmail() {
        const self = this;
        const currentCount = self.emailCollection.where({
            removeRecord: false,
        }).length;
        if (currentCount < self.maxContacts) {
            let allValid = true;
            util.each(self.emailCollection.models, (model) => {
                if (model.isValid()) {
                    model.set('editable', false);
                } else {
                    allValid = false;
                }
            });

            if (allValid) {
                self.populateScreen(
                    self.emailCollection,
                    false,
                    Constants.PASSCODE_EMAIL_CHANNEL,
                    true,
                );
            }
        }
    },

    handleAddTextBtn() {
        const self = this;
        const currentCount = self.textCollection.where({
            removeRecord: false,
        }).length;

        if (currentCount >= self.maxContacts) {
            self.ui.addTextBtn.hide();
        } else {
            self.ui.addTextBtn.show();
        }
    },

    addPhoneText() {
        const self = this;
        const currentCount = self.textCollection.where({
            removeRecord: false,
        }).length;
        if (currentCount < self.maxContacts) {
            util.each(self.textCollection.models, (model) => {
                model.set('editable', false);
            });

            self.populateScreen(
                self.textCollection,
                false,
                Constants.PASSCODE_TEXT_CHANNEL,
                false,
            );
        }
    },

    handleDefaultContact(cid) {
        const self = this;
        let cidLocation = '';
        util.each(self.emailCollection.models, (model) => {
            if (model.get('default')) {
                model.set('default', false);
            }
            if (model.cid === cid) {
                cidLocation = 'email';
            }
        });

        util.each(self.voiceCollection.models, (model) => {
            if (model.get('default')) {
                model.set('default', false);
            }
            if (model.cid === cid) {
                cidLocation = 'voice';
            }
        });

        util.each(self.textCollection.models, (model) => {
            if (model.get('default')) {
                model.set('default', false);
            }
            if (model.cid === cid) {
                cidLocation = 'text';
            }
        });

        let tmpCollection;
        switch (cidLocation) {
        case 'email':
            tmpCollection = self.emailCollection;
            break;
        case 'voice':
            tmpCollection = self.voiceCollection;
            break;
        case 'text':
            tmpCollection = self.textCollection;
            break;
        default:
        }

        // set the default
        if (tmpCollection) {
            tmpCollection.get(cid).set('default', true);
        }
    },

    reset() {
        this.loadRequiredData();
    },

    save() {
        const self = this;
        self.ui.alertRegion.empty();

        // add all models back into the collection
        self.collection.add(self.textCollection.models);
        self.collection.add(self.voiceCollection.models);
        self.collection.add(self.emailCollection.models);

        if (self.validateData()) {
            // remove all deleted, non-verified and empty records  before saving
            self.collection.cleanData().save({
                success() {
                    const message = locale.get('passcode.save.alert');
                    store.set('admin:passcode:maint:message', message);

                    // handle workflow forcing user to setup OTP on login.
                    if (self.initialSetup === true) {
                        userInfo.setOTPSetup(true);
                        self.trigger('otp:setup:success');
                    } else {
                        self.reset();
                    }
                },

                error(response) {
                    const errors = JSON.parse(response.responseText);
                    self.displayAlert(errors.responseHeader);
                },
            });
        }
    },

    validateData() {
        const self = this;
        const message = [];
        let isValid = true;
        const validData = self.collection.cleanData();

        util.each(validData.models, (model) => {
            if (!model.isValid()) {
                if (!model.get('channel') === Constants.PASSCODE_TEXT_CHANNEL || (model.get('passcode') === '' && !model.get('verified'))) {
                    isValid = false;
                }
            } else if (model.get('channel') === Constants.PASSCODE_TEXT_CHANNEL
                    && model.get('verified') === false) {
                message.push(locale.get('administration.mfa.verify.msg', model.get('contact')));
                isValid = false;
            }
        });

        if (validData.length === 0) {
            message.push(locale.get('passcode.minimum.contact.alert'));
            isValid = false;
        }

        if (!isValid) {
            this.displayAlert({
                message,
            });
        }

        return isValid;
    },

    displayAlert(error) {
        if (error === null || error === undefined || error.message === undefined) {
            return;
        }

        const alertDetails = new AlertDetails({
            errors: error.message,
        });

        // display notification message
        this.alertView = alert.danger(
            locale.get('administration.mfa.fix.errors'),
            {
                details: alertDetails,
                animate: true,
            },
        );

        this.alertRegion.show(this.alertView);
    },

    displayMessage() {
        if (store.get('admin:passcode:maint:message')) {
            this.alertView = alert.success(
                store.get('admin:passcode:maint:message'),
                {
                    duration: 3000,
                    animate: true,
                },
            );

            this.alertRegion.show(this.alertView);

            store.unset(
                'admin:passcode:maint:message',
                {
                    silent: true,
                },
            );
        }
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'PASSCODEMAINT',
    view: PasscodeSettingsLayout,
    options: {},
});

export default PasscodeSettingsLayout;
