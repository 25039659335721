import React, { useState } from 'react';
import { CheckboxRadio } from '@glu/form-components';
import { withStyles } from '@glu/theming';
import { Button, TERTIARY } from '@glu/buttons-react';
import locale from '@glu/locale';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import '../themeDefaults';
import styles from './Snackbar.styles';

export const Snackbar = ({
  classes,
  className,
  hide,
  id,
  message,
  withShowToggle,
  actions,
  dismissMessage
}) => {
  const [doNotShow, setDoNotShow] = useState(false);

  if (hide) {
    return null;
  }

  return (
    <div
      data-qa="snackbar"
      className={classnames(className, classes.snack)}
      aria-live="polite"
      role="alert"
    >
      <p className={classes.message}>{message}</p>
      <div className={classes.action}>
        {withShowToggle
          ? (
            <CheckboxRadio
              name="doNotShow"
              checked={doNotShow}
              className={classes.toggle}
              labelText={locale.get('doNotShow')}
              onChange={(_name, newVal) => setDoNotShow(newVal)}
              dark
            />
          )
          : null}
        {actions.map(({ onClick, label, shouldNotDismiss }, i) => (
          <Button
            key={`action-button-${i}`} // eslint-disable-line react/no-array-index-key
            className={classes.actionButton}
            variant={TERTIARY}
            onClick={(e) => {
              e.preventDefault();
              if (onClick) {
                onClick(e, doNotShow);
              }
              if (shouldNotDismiss) {
                return;
              }
              dismissMessage({ id, doNotShow });
            }}
          >
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
};

Snackbar.propTypes = {
  /** function that defines action taken when CTA is clicked */
  dismissMessage: PropTypes.func,

  /** Classes provided by the theme */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,

  /** Classname wrapping the snackbar */
  className: PropTypes.string,

  /** Calls out whether or not the snackbar message is an error or not, used in styles */
  // eslint-disable-next-line react/no-unused-prop-types
  error: PropTypes.bool,

  /** Whether or not there is more than one snackbar message, used in styles */
  // eslint-disable-next-line react/no-unused-prop-types
  hasMultiple: PropTypes.bool,

  /** for manually hiding the snackbar */
  hide: PropTypes.bool,

  /** Unique id for the snackbar */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /** snackbar message */
  message: PropTypes.string.isRequired,

  /** Shows a "Do not show again" checkbox. If checked the doNotShow argument to all actions
   * that can be dismissed will be true when they are called. */
  withShowToggle: PropTypes.bool,

  /** Array of actions each with a label, a function to handle clicks
   *  and a shouldNotDismiss prop that prevents pop-ups from automatically disappearing */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      shouldNotDismiss: PropTypes.bool
    })
  )
};

Snackbar.defaultProps = {
  dismissMessage: /* istanbul ignore next */ () => null,
  className: '',
  error: false,
  hasMultiple: false,
  hide: false,
  withShowToggle: false,
  actions: []
};

const StyledSnackbar = withStyles(styles)(Snackbar);
StyledSnackbar.displayName = 'Snackbar';

export default StyledSnackbar;
