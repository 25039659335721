import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import validatorPatterns from 'system/validatorPatterns';

const Constants = {
    STORE_ACCOUNT_FILTER: 'transaction details',
    STORE_ACCOUNT_NUMBER: 'transaction account number',
    LOAN_PAYMENT_SERVICE_NAME: '/payment/loanPayment',
    LOAN_PAYMENT_TEMPLATE_ADMIN_SERVICE_NAME: '/adminTemplate/listView/templates',
    LOAN_DRAW_SERVICE_NAME: '/payment/loanDraw',

    DEFAULTS: {
        DEBIT_CURRENCY: 'NONE',
        CUSTOMER_REFERENCE: '',
        SPECIAL_INSTRUCTIONS: '',
        CREDIT_CURRENCY: 'NONE',
        CREDIT_AMOUNT: '',
        VALUE_DATE: '',
        AMOUNT_0: '',
        AMOUNT_1: '',
        AMOUNT_2: '',
        AMOUNT_3: '',
        AMOUNT_4: '',
        BENE_ACCOUNT: 'NONE',
        DEBIT_ACCOUNT_NUMBER: 'NONE',
    },

    TYPE: {
        ANY: 'PAY_ANY',
        BOTH: 'BOTH',
        INVOICE_ONLY: 'INVOICE_ONLY',
        NONE: 'NONE',
    },

    INVOICE_FIELDS: {
        STANDARD_AMOUNT: {
            name: 'STANDARD_AMOUNT',
            id: 'pmtStandardAmount',

            label() {
                return locale.get('RTGS.LOANS.OPTION.STANDARD.AMOUNT');
            },
        },

        PRINCIPAL_AMOUNT: {
            name: 'PRINCIPAL_AMOUNT',
            id: 'pmtPrincipalAmount',

            label() {
                return locale.get('RTGS.LOANS.OPTION.PRINCIPAL.AMOUNT');
            },
        },

        INTEREST_AMOUNT: {
            name: 'INTEREST_AMOUNT',
            id: 'pmtInterestAmount',

            label() {
                return locale.get('RTGS.LOANS.OPTION.INTEREST.AMOUNT');
            },
        },

        ESCROW_AMOUNT: {
            name: 'ESCROW_AMOUNT',
            id: 'pmtEscrowAmount',

            label() {
                return locale.get('RTGS.LOANS.OPTION.ESCROW.AMOUNT');
            },
        },

        OTHER_AMOUNT: {
            name: 'OTHER_AMOUNT',
            id: 'pmtOtherAmount',

            label() {
                return locale.get('RTGS.LOANS.OPTION.OTHER.AMOUNT');
            },
        },
    },

    validators() {
        /**
         * We have to return the validators in a function because the
         * locale strings are not immediately available
         */
        return {
            DEBIT_CURRENCY: {
                description: locale.get('loans.account'),
                exists: true,
                otherDescription: locale.get('RTGS.*.credit_currency'),
                sameValue: 'CREDIT_CURRENCY',
            },

            CREDIT_CURRENCY: {
                description: locale.get('loans.account'),
                exists: true,
                otherDescription: locale.get('RTGS.*.debit_currency'),
                sameValue: 'DEBIT_CURRENCY',
            },

            CREDIT_AMOUNT: {
                description: locale.get('loans.credit.amount'),
                matches: validatorPatterns.PAMOUNT_PATTERN,
                maxLength: 16,
            },

            VALUE_DATE: {
                description: locale.get('loans.value.date'),
                exists: true,
                matchesDatePattern: userInfo.getDateFormat(),
            },

            SELECTION_0: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_1: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_2: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_3: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            SELECTION_4: {
                description: locale.get('loans.payment.option'),
                isUnique: true,
            },

            STANDARD_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            PRINCIPAL_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            INTEREST_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            ESCROW_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            OTHER_AMOUNT: {
                description: locale.get('loans.amount'),
                maxLength: 20,
            },

            DEBIT_ACCOUNT_NUMBER: {
                description: locale.get('loans.account'),
                exists: true,
            },

            BENE_ACCOUNT: {
                description: locale.get('loans.account'),
                exists: true,
            },
        };
    },
};

export default Constants;
