import Layout from '@glu/core/src/layout';
import CollectionView from '@glu/core/src/collectionView';
import AccountTotalsView from 'app/reports/views/cd/accountTotals';
import Formatter from 'system/utilities/format';
import template from './accountSummary.hbs';

export default Layout.extend({
    template,
    className: 'cd-summary-totals',

    initialize(options) {
        this.accountCollectionView = new CollectionView({
            collection: this.model.get('accountCollection'),
            itemView: AccountTotalsView,

            itemViewOptions: {
                searchRequest: options.searchRequest ? options.searchRequest : false,
            },
        });
    },

    onRender() {
        this.cdAccountRegion.show(this.accountCollectionView);
    },

    templateHelpers() {
        return {
            cid: this.model.cid,
            isFirst: this.model.get('isFirst'),
            AsOfDate: Formatter.formatDateFromUserFormat(this.model.get('ASOFDATE')),
        };
    },
});
