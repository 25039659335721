import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import Handlebars from 'handlebars';
import CollectionComboBox from 'common/collectionComboBox/views/base';
import template from './combobox.hbs';

export default CollectionComboBox.extend({
    template,

    getRowString(model, rowEditState) {
        let returnString = '';

        if (rowEditState === false) {
            returnString += Handlebars.compile(this.displayStache)(model.toJSON());
            if (model.get('canEdit') === true || model.get('canDelete') === true) {
                returnString += '<span class="button-wrap">';

                if (model.get('canEdit') === true && this.canEdit === true) {
                    returnString += `<button type="button" class="btn btn-link deluxeComboBoxModifyItemButton" data-item="${model.cid}" title="${this.editButtonText}"><span class="icon-pencil"></span> </button>`;
                }

                if (model.get('canDelete') === true) {
                    returnString += `<button type="button" class="btn btn-link deluxeComboBoxDeleteItemButton" data-item="${model.cid}" title="${this.deleteButtonText}"> <span class="icon-trash"></span> </button>`;
                }
                returnString += '</span>';
            }
        } else {
            returnString += `<input type="text" value="${Handlebars.compile(this.displayStache)(model.toJSON())}" name="${model.cid}_name">`;
            returnString += '<span class="button-wrap">';
            returnString += `<button type="button" class="btn btn-link deluxeComboBoxSaveItemButton" data-item="${model.cid}"><span class="icon-tick"></span> </button>`;
            returnString += `<button type="button" class="btn btn-link deluxeComboBoxCancelItemButton" data-item="${model.cid}"> <span class="icon-cancel"></span> </button>`;
            returnString += '</span>';
        }

        return returnString;
    },

    initializeList() {
        const self = this;

        // select2 documentation can be found at http://ivaynberg.github.io/select2/
        this.ui.$selectList.comboBox({
            placeholder: self.noSelectionText,
            minimumResultsForSearch: Infinity,
            dropdownAutoWidth: true,
            formatResult(listItem) {
                if (listItem.id === 'addOption') {
                    return `<div class="form-horizontal"><div class="col-md-6" style="padding-left: 0px;"><button type="button" class="btn btn-primary btn-add" data-hook="select-list-new-entry" title="${self.addButtonText}">+ ${self.addButtonText}</button></div></div>`;
                }
                const model = self.collection.get(listItem.id);
                let returnString = `<div id="${listItem.id}">`;
                returnString += self.getRowString(model, false);
                returnString += '</div>';
                return returnString;
            },

        });
    },

    applyError() {
        $('.select2-results').find('[data-hook="getAddNewDiv"]').addClass('has-error');
    },

    setListListeners() {
        let storedSearchInputReference;

        // change event for selection
        this.ui.$selectList.on('change', (e) => {
            if (e.val) {
                this.trigger('select', this.collection.get(e.val));
            }
        });

        // change event for clearing selection
        this.ui.$selectList.on('select2-clearing', () => {
            this.trigger('select');
        });

        this.ui.$selectList.on('select2-close', () => {
            /**
             * make sure select2 search reference has a valid one
             * (for when closed and opened back up)
             */
            if (storedSearchInputReference) {
                this.ui.$selectList.data('select2').search = storedSearchInputReference;
            }
        });

        /*
         * select2 rebuilds the dropdown list each time it's opened
         * apply customizations to the list
         */
        this.ui.$selectList.on('select2-open', () => {
            const selectedModel = this.selectedModel();

            if (this.canAdd) {
                // style the add button
                $('.select2-results').find('.btn-add').closest('li').addClass('opt-add');
            }

            // add event for save new view button
            $('.opt-add').off().on('mouseup', (e) => {
                e.stopPropagation();
                e.preventDefault();

                /**
                 * TODO: The save button has no associated model, actually.
                 * So we need to make a new one with a unique cid
                 */

                /*
                 * Is it possible to re-use the other approach to return string building,
                 * given the kack of model item attached to the button??
                 */
                let returnString = '';
                returnString += '<div class="row"><div class="col-sm-9 no-pad-left js-addNewDiv"><input class="form-control" type="text" name="addNew_name"';

                if (selectedModel) {
                    if (selectedModel.get('canEdit') === true) {
                        returnString += ' value="';
                    } else {
                        returnString += ' value="" placeholder="';
                    }
                    returnString += `${selectedModel.id}"`;
                }

                returnString += '></div>';
                returnString += '<div class="col-sm-3 no-pad-left">';
                returnString += `<button type="button" class="btn btn-primary deluxeComboBoxSaveItemButton" data-hook="saveNew" title="${locale.get('listview.Go')}">${locale.get('listview.Go')}</button>`;
                returnString += '</div>';
                returnString += '</div>';

                $(e.currentTarget).html(returnString);

                $(e.currentTarget).find('.form-control').on('click', (ev) => {
                    /**
                     * prevent form interactions from bubbling to the parent el &
                     * closing the dropdown on mobile
                     */
                    ev.stopPropagation();
                    /**
                     * HACK: Due to the way select2 focuses inputs when the menu opens above
                     * and the input being off screen, we will set our own input to be search
                     * and then replace that on close back to the original input that is
                     * required when opening below. we trigger the blur because of order of
                     * execution on initial selection with select2 already having performed the
                     * lookup for search
                     */
                    if (!storedSearchInputReference) {
                        storedSearchInputReference = this.ui.$selectList.data('select2').search;
                    }
                    const searchWrapper = {
                        0: $(ev.currentTarget).find('.form-control input[type="text"]'),
                    };
                    const { 0: searchInput } = searchWrapper;
                    searchWrapper.focus = searchInput.focus.bind(searchInput);
                    searchWrapper.removeClass = searchInput.removeClass.bind(searchInput);
                    searchWrapper.val = () => (searchInput.val() ? searchInput.val : { length: 0 });
                    this.ui.$selectList.data('select2').search = searchWrapper;
                    this.ui.$selectList.trigger('blur');
                    $(ev.currentTarget).focus();
                });

                /**
                 * TODO: Instead of repeating these three listeners inside the
                 * ul.select2-results and opt-add listeners, can we abstract them out??
                 */

                $('ul.select2-results input').on('mouseup', (ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                });

                $('ul.select2-results .deluxeComboBoxSaveItemButton').on('mouseup', (ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();

                    // TODO: We need to find a way to save with a new unique cid
                    this.trigger('create', {
                        value: $('ul.select2-results input[name="addNew_name"]').val(),
                    });
                });
                /**
                 * HACK both mouseup and click events are raised when clicking/tapping
                 * the button. On mobile, the click event throws an error in select2 when
                 * trying to highlight an option that is a button. Adding this listener
                 * to prevent the click event. Doesn't impact desktop at all.
                 */
                $('ul.select2-results .deluxeComboBoxSaveItemButton').on('click', (ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                });

                $('ul.select2-results .deluxeComboBoxCancelItemButton').on('mouseup', (ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    this.ui.$selectList.comboBox('close');
                });

                this.ui.$selectList.select2('positionDropdown');
            });

            // add event for the remove button
            $('.select2-results .deluxeComboBoxDeleteItemButton').on('mouseup', (ev) => {
                ev.stopPropagation();
                this.ui.$selectList.comboBox('close');
                this.trigger('remove', this.collection.get($(ev.currentTarget).attr('data-item')));
            });

            // add delegate event for the modify button
            const savedViewsResults = $('ul.select2-results.saved-views-results');
            const select2Results =
                $(`${savedViewsResults.length ? 'ul.select2-results.saved-views-results' : 'ul.select2-results'}`);
            select2Results.off().on('mouseup', 'button.deluxeComboBoxModifyItemButton', (ev) => {
                ev.stopPropagation();

                const model = this.collection.get($(ev.currentTarget).attr('data-item'));
                const editHtml = this.getRowString(model, true);

                $(`#${model.cid}`).html(editHtml);

                $('ul.select2-results input').on('mouseup', (evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                });

                $('ul.select2-results .deluxeComboBoxSaveItemButton').on('mouseup', (evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    this.trigger('save', {
                        cid: model.cid,
                        value: $(`ul.select2-results input[name="${model.cid}_name"]`).val(),
                    });
                });

                $('ul.select2-results .deluxeComboBoxCancelItemButton').on('mouseup', (evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    this.ui.$selectList.comboBox('close');
                });
            });
        });
    },

    modelRemoved(model) {
        if (this.ui.$selectList.comboBox('val') === model.cid) {
            this.ui.$selectList.comboBox('val', '');
            this.trigger('cleared');
        }
        this.$el.find(`option[value="${model.cid}"]`).remove();
        this.ui.$selectList.comboBox('close');
    },

    modelChanged(model) {
        // Need to revisit
        this.bindUIElements();
        this.$el.find(`option[value="${model.cid}"]`).text(Handlebars.compile(this.displayStache)(model.toJSON()));
        if (this.ui.$selectList.comboBox('val') === model.cid) {
            this.ui.$selectList.val(model.cid).trigger('change');
        }
    },

    modelAdded(model) {
        this.ui.$selectList.append(`<option value="${model.cid}">${Handlebars.compile(this.displayStache)(model.toJSON())}</option>`);

        // add edit and delete capabilities for a newly created entity
        if (util.isEmpty(model.get('canEdit'))) {
            model.set('canEdit', true);
        }

        if (this.canEdit === false) {
            model.set('canEdit', 'false');
        }

        this.ui.$selectList.comboBox('val', model.cid);
    },
});
