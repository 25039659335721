import util from '@glu/core/src/util';
import services from 'services';
import AddressBookAPI from 'app/bab/service/addressBook';
import http from '@glu/core/src/http';

export default AddressBookAPI.extend({
    read(model) {
        this.id = model.get('TNUM');

        const getChildReqData = {
            item: {
                item: [{
                    name: 'TNUM',
                    value: this.id,
                }],
            },
        };
        const readReqData = {
            item: [{
                name: 'TNUM',
                value: this.id,
            }, {
                name: 'PRODUCTCODE',
                value: model.get('PRODUCT'),
            }, {
                name: 'FUNCTIONCODE',
                value: model.get('FUNCTION'),
            }, {
                name: 'TYPECODE',
                value: model.get('TYPE'),
            }],
        };
        return Promise.all([
            http.post(services.readAdministeredData, readReqData),
            http.post(services.beneAddressGetChildren, getChildReqData),
        ])
            .then(results => util.extend(results[0], results[1]));
    },
});
