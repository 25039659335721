var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item is-active\" role=\"presentation\">\n            <a  aria-controls=\"showPaymentsTab\"\n                class=\"NavTabs-link btn btn-tertiary payments\"\n                data-step=\"PAYMENTS\"\n                aria-selected=\"true\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":64}}}))
    + "\"\n                role=\"tab\"\n                id=\"permissionPaymentLink\"\n                tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.payments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":29},"end":{"line":12,"column":65}}}))
    + "\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\" role=\"presentation\">\n            <a  aria-controls=\"showReportTab\"\n                class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\"REPORTS\"\n                aria-selected=\"false\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.reporting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":65}}}))
    + "\"\n                role=\"tab\"\n                id=\"permissionReportLink\"\n                tabindex=\"-1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.reporting",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":30},"end":{"line":25,"column":67}}}))
    + "\n            </a>\n        </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\" role=\"presentation\">\n            <a  aria-controls=\"showRiskManagementTab\"\n                class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\"RISK\"\n                aria-selected=\"false\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.risk.mgmt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":65}}}))
    + "\"\n                role=\"tab\"\n                id=\"permissionRiskManagementLink\"\n                tabindex=\"-1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.risk.mgmt",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":38,"column":30},"end":{"line":38,"column":67}}}))
    + "\n            </a>\n        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\" role=\"presentation\">\n            <a  aria-controls=\"showAdministrationTab\"\n                class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\"ADMIN\"\n                aria-selected=\"false\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.admin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":61}}}))
    + "\"\n                role=\"tab\"\n                id=\"permissionAdministrationLink\"\n                tabindex=\"-1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.admin",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":51,"column":30},"end":{"line":51,"column":63}}}))
    + "\n            </a>\n        </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\" role=\"presentation\">\n            <a  aria-controls=\"showAlertTab\"\n                class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\"ALERT\"\n                aria-selected=\"false\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.alerts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":61,"column":62}}}))
    + "\"\n                role=\"tab\"\n                id=\"permissionAlertLink\"\n                tabindex=\"-1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.alerts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":30},"end":{"line":64,"column":64}}}))
    + "\n            </a>\n        </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"NavTabs-item\" role=\"presentation\">\n            <a aria-controls=\"showABSTab\"\n                class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\"ABS\"\n                aria-selected=\"false\"\n                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.ancillary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":28},"end":{"line":74,"column":65}}}))
    + "\"\n                role=\"tab\"\n                id=\"permissionABSLink\"\n                tabindex=\"-1\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.ancillary",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":77,"column":30},"end":{"line":77,"column":67}}}))
    + "\n            </a>\n        </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "         <li class=\"NavTabs-item\">\n            <a class=\"NavTabs-link btn btn-tertiary\"\n                data-step=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                role=\"tab\"\n                tabindex=\"-1\"\n                aria-label=\""
    + alias1(lookupProperty(helpers,"locale").call(alias2,(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"label"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"@root/label","hash":{},"data":data,"loc":{"start":{"line":87,"column":37},"end":{"line":87,"column":58}}}),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":87,"column":28},"end":{"line":87,"column":60}}}))
    + "\"\n                >"
    + alias1(lookupProperty(helpers,"locale").call(alias2,(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"label"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"@root/label","hash":{},"data":data,"loc":{"start":{"line":88,"column":26},"end":{"line":88,"column":47}}}),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":88,"column":17},"end":{"line":88,"column":49}}}))
    + "\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul id=\"permissions-tabslist\" class=\"NavTabs nav nav-tabs\" role=\"tablist\"\n    data-hook=\"getNavTabs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPaymentsTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReportingTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRiskTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":54,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAlertsTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":67,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAncillaryTabEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":4},"end":{"line":80,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"dynamicTabs") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":4},"end":{"line":91,"column":13}}})) != null ? stack1 : "")
    + "</ul>\n<div data-hook=\"formRegion\"></div>\n";
},"useData":true});