import Model from '@glu/core/src/model';

export default Model.extend({

    getLocaleKey() {
        try {
            let s = this.get('contextDef').serviceName;
            if (s.indexOf('/') === 0) {
                s = s.slice(1);
            }

            const token = s.split('/');
            let ret = '';
            if (token.length === 1) {
                ret = `${token[0]}.`;
            } else {
                ret = `${token[0]}.${token[1]}_`;
            }
            return ret;
        } catch (e) {
            return '';
        }
    },

    getContextKey() {
        try {
            let s = this.get('contextDef').serviceName;
            if (s.indexOf('/') === 0) {
                s = s.slice(1);
            }
            return s.split('/').join('_');
        } catch (e) {
            return '';
        }
    },

    getListUrl() {
        return this.urlConcat('list');
    },

    getInsertUrl() {
        return this.urlConcat('insert');
    },

    getModifyUrl() {
        return this.urlConcat('modify');
    },

    getRepairUrl() {
        return this.urlConcat('repair');
    },

    getReverseUrl() {
        return this.urlConcat('reverse');
    },

    getScheduleUrl() {
        return this.urlConcat('spaymt');
    },

    getViewUrl() {
        return this.urlConcat('view');
    },

    getImportUrl() {
        return this.urlConcat('import');
    },

    urlConcat(type) {
        return `/${this.get('menuCategory')}/${type}${(this.get('serviceName')) ? (`/${this.get('serviceName')}`) : ''}${(this.get('serviceFunc')) ? (`/${this.get('serviceFunc')}`) : ''}${(this.get('businessType')) ? (`/${this.get('businessType')}`) : ''}/${this.get('context')}`;
    },
});
