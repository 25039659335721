import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import { maskValue } from 'common/util/maskingUtil';
import util from '@glu/core/src/util';
import transferAlertDetailsTmpl from './transferAlertDetails.hbs';

export default Layout.extend({
    template: transferAlertDetailsTmpl,

    initialize(opts) {
        this.model = opts.model || new Model();
    },

    templateHelpers() {
        return {
            maskAccount: value => maskValue(value),
            formattedPayee: util.unescape(this.model.get('BENE_NAME')),
        };
    },
});
