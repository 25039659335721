import util from '@glu/core/src/util';
import $ from 'jquery';
import { triggerMaskedInputChanged } from 'common/dynamicPages/views/mdf/mdfUtil';
import fxPaymentUtil from 'common/util/fxPaymentUtil';
import comboUtil from 'common/util/comboUtil';

export default {
    setupComboboxes(viewParam, selector, $alt) {
        const view = viewParam;
        let localSelector = selector;
        // Support an alternate container element
        const $el = $alt || view.$el;

        if (!localSelector) {
            localSelector = '.form-select';
        }
        $el.find(localSelector).each(function (index, select2) {
            // check if collection contains map data
            const { id } = select2;

            const allCollections = view.comboCollections;
            const preLoadedCollections = view.comboBoxData;
            let currentCollection = allCollections[id];

            if (preLoadedCollections && preLoadedCollections[id]) {
                currentCollection = preLoadedCollections[id];
            }

            // NH-6811 For depends combos, the data may be null.
            if (currentCollection && currentCollection.length > 0
                && currentCollection[0].mapDataList) {
                // set up on change for mapdata event
                $(this).off('change.mdf').on('change.mdf', (e) => {
                    let selected;
                    let mapDataList;
                    const item = {};
                    const comboId = e.currentTarget.id;
                    const targetCollection = allCollections[comboId];
                    selected = util.findWhere(
                        targetCollection,
                        {
                            name: e.currentTarget.value,
                        },
                    );

                    /*
                     * did I really change?  If this combo was set from a reload model,
                     * check if its new value is the
                     * same as the old value and if it is then don't do a mapdata
                     */
                    if (view.lastComboValues) {
                        if (view.lastComboValues[comboId]) {
                            if (view.lastComboValues[comboId] === e.currentTarget.value) {
                                // remove lastComboValues is all values are empty
                                if (util.isEmpty(view.lastComboValues)) {
                                    view.lastComboValues = null;
                                }
                                return;
                            }
                        }
                    }

                    if (selected) {
                        /*
                         * NH-43897 if the combo box is protected don't process the mapping
                         * defined in the mapDataList as it is not selectable and
                         * its value can not be changed
                         */
                        ({ mapDataList } = selected);
                        /*
                         * Use '=== false' rather than ! as !view.$el.find('[name=' + id + ']')
                         * will be true for undefined, which is what occurs when the field
                         * does not exist.
                         */
                        if (mapDataList && view.$el.find(`[name=${id}]`).prop('readOnly') === false) {
                            /*
                             * For now, assume that the list is coming in order
                             * convert toField to uppercase b/c of fields on client
                             * side are upper case
                             * mapdata fields may not be set up properly
                             */
                            $.each(mapDataList, (i, mapData) => {
                                item[mapData.toField.toUpperCase()] = mapData.value;
                            });
                            item[e.currentTarget.id.toUpperCase()] = $(this).val();
                            /*
                             * HACK NH-68480 need a better solution after driver
                             * fields have been addressed
                             */
                            if (fxPaymentUtil.USD_ONLY) {
                                delete item.CREDIT_CURRENCY;
                            }
                            /*
                             * NH-105100
                             * There is a timing issue in EFT when selecting a compidname
                             * w/ valid OFFSETACCOUNTNUM vs
                             * a compidname w/o a valid OFFSETACCOUNTNUM
                             * Using a defer to circumvent the timing issue.
                             * Limit the scope to CAEFT/INSERT/OFFSETACCOUNTNUM
                             */
                            if (view.context && view.context.subType === 'CAEFT' && view.context.actionMode === 'INSERT' && e.currentTarget.id === 'OFFSETACCOUNTNUM') {
                                util.defer(() => {
                                    view.model.set(item);
                                });
                            } else {
                                view.model.set(item);
                            }

                            /**
                             * we only want to trigger the event when a maskedinput was mapped
                             * from the combo
                             */
                            if (mapDataList.length > 0) {
                                triggerMaskedInputChanged(view.model, mapDataList);
                            }
                        }
                    } else {
                        /*
                         * need to clear map data list
                         * grab the first item in the collection
                         */
                        [selected] = targetCollection;
                        // get its mapDataList
                        mapDataList = (selected ? selected.mapDataList : null);
                        // clear out the toFields
                        if (mapDataList) {
                            $.each(mapDataList, (i, mapData) => {
                                item[mapData.toField.toUpperCase()] = '';
                            });
                            item[e.currentTarget.id.toUpperCase()] = '';
                            /*
                             * NH-105100
                             * There is a timing issue in EFT when selecting a compidname
                             * w/ valid OFFSETACCOUNTNUM vs
                             * a compidname w/o a valid OFFSETACCOUNTNUM
                             * Using a defer to circumvent the timing issue.
                             * Limit the scope to CAEFT/INSERT/OFFSETACCOUNTNUM
                             */
                            if (view.context && view.context.subType === 'CAEFT' && view.context.actionMode === 'INSERT' && e.currentTarget.id === 'OFFSETACCOUNTNUM') {
                                /*  PCM- 5228
                                * commented this line due a infinite loop
                                * limit the scope to only on CAEFT/INSERT/OFFSETACCOUNTNUM
                                * and only to particular qa3 enviroment
                                * util.defer(() => {
                                *     view.model.set(item);
                                * });
                                * */
                            } else {
                                view.model.set(item);
                            }
                            /**
                             * we only want to trigger the event when a maskedinput was mapped
                             * from the combo
                             */
                            triggerMaskedInputChanged(view.model, mapDataList);
                        }
                    }
                });
            }

            const maskedInputMatcher = (params, data) => {
                // if no params, no search, display the item
                if (!params) {
                    return data;
                }
                const thisItem = currentCollection.find(item => item.label === data);
                const matchesParams = [
                    thisItem.label,
                    thisItem.name,
                ].filter(string => string.includes(params));
                return matchesParams.length ? data : null;
            };

            const maskedInputMatcherObj = {};
            const maskedFields = [
                'ACCOUNTFILTER',
                'ACCOUNTNUMBER',
            ];
            if (maskedFields.includes(this.getAttribute('id'))) {
                maskedInputMatcherObj.matcher = maskedInputMatcher;
            }

            $(this).comboBox({
                dropdownAutoWidth: 'true',
                // PCM-5691
                placeholder: view.model.fieldData[id] && view.model.fieldData[id].placeHolder,
                allowClear: true,
                ...maskedInputMatcherObj,
            });

            view.once('close', () => {
                view.$(select2).comboBox('destroy');
            });
        });

        /*
         * in some instances, enumerated list values within protected comboboxes may need to be
         * replaced with a computed value for readability
         */
        $el.find('.read-only-combobox [data-computed]').each((index, element) => {
            const name = element.dataset.computed;
            view.listenTo(view.model, `change:${name}`, (updatedModel) => {
                const comboList = view.comboCollections[name];
                const comboValue = updatedModel.get(name);
                let computedValue = '';

                if (!util.isEmpty(comboValue)) {
                    // check for instance of name within the clist
                    for (let j = 0; j < comboList.length; j += 1) {
                        if (comboList[j].name === comboValue) {
                            computedValue = comboList[j].label;
                            break;
                        }
                    }
                }

                $el.find(element).text(computedValue);
            });
        });
    },

    /**
     * @description This method will search for all combos and then call selectIfOnlyOne
     *              selectIfOnlyOne will check if the combo is mandatory and if
     * it only has one item,
     *              if so, it will select that one item
     * @param view
     * @param selector
     */
    checkForSelectIfOnlyOne(view, selector) {
        let localSelector = selector;
        if (!localSelector) {
            localSelector = '.form-select';
        }
        view.$el.find(localSelector).each((index, select2) => {
            const id = view.$(select2.parentElement).find('SELECT').attr('name');
            comboUtil.selectIfOnlyOne(view, id);
        });
    },

};
