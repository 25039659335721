import React from 'react';
import PropTypes from 'prop-types';
import CurrencyBadgeHeader from './CurrencyBadgeHeader';
import useStyles from './CurrencyBadge.styles';

const CurrencyBadge = ({
    badge: {
        count,
        currencyCode,
        field1Label,
        field1Amount,
        field2Label,
        field2Amount,
    },
}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.body} currency-${currencyCode} ${!field2Amount && classes.noAmount}`}>
            <CurrencyBadgeHeader currencyCode={currencyCode} count={count} />
            <div>
                <div className={classes.label}>{field1Label}</div>
                <div className={classes.value}>
                    {field1Amount}
                </div>
            </div>
            { field2Label && field2Amount &&
                <div>
                    <div className={classes.label}>{field2Label}</div>
                    <div className={classes.value}>
                        {field2Amount}
                    </div>
                </div>
            }
        </div>
    );
};

CurrencyBadge.propTypes = {
    badge: PropTypes.shape({
        count: PropTypes.number.isRequired,
        currencyCode: PropTypes.string.isRequired,
        field1Label: PropTypes.string.isRequired,
        field1Amount: PropTypes.string.isRequired,
        field2Label: PropTypes.string,
        field2Amount: PropTypes.string,
    }).isRequired,
};

export default CurrencyBadge;
