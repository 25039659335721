import ReactDOM from 'react-dom';
import React from 'react';
import Layout from '@glu/core/src/layout';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import template from './eSendPreviewView.hbs';
import ESendDocumentPreview from '../../../pcm/common/components/ESendDocumentPreview'; // eslint-disable-line

export default Layout.extend({
    template,
    loadingTemplate,
    className: 'esend-preview',

    // eslint-disable-next-line no-unused-vars
    initialize(options) {},

    onRender() {
        // eslint-disable-next-line react/jsx-filename-extension
        ReactDOM.render(<ESendDocumentPreview
            name="PTXESENDPREVIEW"
            eSendDocumentId={this.model.get('ESENDDOCUMENTID')}
            eSendStatus={this.model.get('ESEND_STATUS')}
        />, this.$el[0]);
    },

    loadRequiredData() {
        this.setHasLoadedRequiredData(true);
    },

});
