var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":49}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":51},"end":{"line":22,"column":67}}}))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-6 checkbox\">\n        <div class=\"checkbox-inline\">\n            <input type=\"checkbox\" name=\"checkboxOrderingParty\" id=\"checkboxOrderingParty\" data-bind=\"model\" data-nullable>\n            <span class=\"checkbox-icon\"></span>\n            <label for=\"checkboxOrderingParty\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.orderingParty.useContact",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":88}}}))
    + "</label>\n            <!-- Because this screen is not meta driven, the checkbox does not automatically translate from true/false to the meta driven on/off values set.\n                Above is the on-screen checkbox with true/false; Below is the model field with one/zero for submission. -->\n            <input type=\"text\" id=\"ALLOWORDERINGPARTYLOOKUP\" name=\"ALLOWORDERINGPARTYLOOKUP\" class=\"form-control hidden\" maxlength=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"ALLOWORDERINGPARTYLOOKUP") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\">\n        </div>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"orderingPartyForm\">\n        <fieldset>\n            <div class=\"col-4 form-group required\">\n                <label for=\"ORDERINGPARTY_ID_TYPE\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.orderingParty.idType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":51},"end":{"line":18,"column":88}}}))
    + "</label>\n                <select class=\"form-control\" name=\"ORDERINGPARTY_ID_TYPE\" data-bind=\"model\">\n                    <option value=\"\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.orderingParty.selectIdType",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":80}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"orderingPartyIdTypes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":23,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"ORDERINGPARTY_ID_TYPE\"></span>\n            </div>\n\n            <div data-region=\"maskedInputRegion\" /> \n            \n        </fieldset>\n    </div>\n</div>";
},"useData":true});