import AuditSectionWidget from 'common/uiWidgets/auditSectionWidget/auditSectionWidget';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import auditSectionWidgetTmpl from 'common/uiWidgets/auditSectionWidget/auditSectionWidget.hbs';

export default AuditSectionWidget.extend({
    template: auditSectionWidgetTmpl,

    /**
     * @function collectAuditFields
     * @description collects the audit fields from the parent model for rendering.
     * Each audit field must have a title, timestamp, usergroup, userid, and username.
     * if there is not timestamp, then this audit field will not appear.
     */
    collectAuditFields() {
        // every payment/template has an entered audit field set
        const { parentModel } = this;

        const auditFields = [{
            title: locale.get('Entered'),
            timestamp: this.getDisplayTimestamp('ENTERED_TIMESTAMP'),
            company: parentModel.get('USERGROUP'),
            userID: parentModel.get('ENTERED_BY'),
            userName: parentModel.get('ENTERED_BY_NAME'),
        }];

        this.addAuditField(auditFields, 'MODIFIED', locale.get('allReports.Last_Modified'));
        this.addAuditField(auditFields, 'REVERSAL', locale.get('PS.screentext.Reversed'));
        this.addReversalApproveAuditFields(auditFields);
        this.addAuditField(auditFields, 'REVERSAL_REJECTED', locale.get('PS.screentext.ReversalRejected'));
        this.addAuditField(auditFields, 'REVERSALEXTRACTION', locale.get('PS.screentext.ReversalExtracted'));

        this.model.set('auditFields', auditFields);
    },

    templateHelpers() {
        const parentHelpers = AuditSectionWidget.prototype.templateHelpers.call(this);
        return util.extend(
            {},
            parentHelpers,
            {
                displayHistoryLink: false,
                hasError: false,
            },
        );
    },
});
