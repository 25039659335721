import $ from 'jquery';
import util from '@glu/core/src/util';
import rtgsCommon from './rtgsCommon';

export default function (form, initialState) {
    const transactionDate = form.field('TRAN_DATE');
    const { model } = form.formView;
    const formState = form.formView.state;
    const { functionCode } = model.jsonData.typeInfo;
    const valueDate = form.field('VALUE_DATE');
    const debitAmount = form.field('DEBIT_AMOUNT');
    const creditAmount = form.field('CREDIT_AMOUNT');
    const creditDebitBtn = form.field('CREDIT_DEBIT_RADIO_BTN');
    let collections;
    let id;
    const changeableFields = ['VALUE_DATE', 'CREDIT_AMOUNT', 'DEBIT_AMOUNT', 'SPECIAL_INSTRUCTIONS'];

    const protectFields = function () {
        Object.keys(form.fields || {}).forEach((fieldName) => {
            if (changeableFields.indexOf(fieldName) === -1) {
                form.field(fieldName).shouldBeReadOnly(true);
            }
        });
        $('.lookup').addClass('hidden');
    };

    if (initialState) {
        collections = form.formView.comboCollections;
        // set up the listenTo
        form.formView.listenTo(form.formView.appBus, 'combo-collections-updated', (keys) => {
            util.each(keys, (key) => {
                if (collections[key].length) {
                    // get balance information
                    rtgsCommon.getAccountBalances(form.formView, key, 'RTGS', 'TRANSFER', 'BENE_BANK_CODE', 'CREDIT_CURRENCY');
                }
            });
        });

        rtgsCommon.getAccountBalances(form.formView, 'DEBIT_ACCOUNT_NUMBER', 'RTGS', 'TRANSFER', 'DEBIT_BANK_CODE', 'Debit_Currency');
        // for modify
        id = 'BENE_ACCOUNTENTITLEMENT';
        if (collections[id].length > 0) {
            rtgsCommon.getAccountBalances(form.formView, id, 'RTGS', 'TRANSFER', 'BENE_BANK_CODE', 'CREDIT_CURRENCY');
        }
    }

    const updateDebitOrCreditCurrency = function () {
        const creditCurrencyValue = model.get('CREDIT_CURRENCY');
        const debitCurrencyValue = model.get('DEBIT_CURRENCY');
        if (creditCurrencyValue === '' || debitCurrencyValue === '' || creditCurrencyValue === debitCurrencyValue) {
            debitAmount.shouldBeHidden();
            creditAmount.shouldBeReadOnly(false);
            // temporary hack until the radio button is fixed
            $('#CREDIT_DEBIT_RADIO_BTN-Credit').parent().hide();
            $('#CREDIT_DEBIT_RADIO_BTN-Debit').parent().hide();
            if (functionCode === 'INST') {
                form.field('EXCHANGE_RATE_CONTRACTID').shouldBeHidden();
                form.field('EXCHANGE_RATE').shouldBeHidden();
            }
            model.set('ENTERED_AMOUNT_FLAG', 'C');
        } else {
            debitAmount.shouldBeVisible();
            creditDebitBtn.shouldBeVisible();
            debitAmount.shouldBeReadOnly(true);
            creditAmount.shouldBeReadOnly(false);
            if (functionCode === 'INST') {
                form.field('EXCHANGE_RATE_CONTRACTID').shouldBeVisible();
                form.field('EXCHANGE_RATE').shouldBeVisible();
            }
            $('#CREDIT_DEBIT_RADIO_BTN-Credit').parent().show();
            $('#CREDIT_DEBIT_RADIO_BTN-Debit').parent().show();
            $('#CREDIT_DEBIT_RADIO_BTN-Credit').prop('checked', true);
            creditAmount.shouldBeRequired();
            debitAmount.shouldBeOptional();
        }
    };

    if (functionCode !== 'TMPL' && valueDate.isNotEmpty() && transactionDate.isEmpty()) {
        transactionDate.setValue(valueDate.getValue());
    }

    if (functionCode === 'TMPL' && formState === 'modify') {
        form.field('TEMPLATE_CODE').shouldBeReadOnly(true);
    }

    if (form.formView.state === 'insert') {
        // Hide the audit section
        const aFieldInAuditSection = form.field('VIEWHISTORY');
        aFieldInAuditSection.$el.closest('.section').hide();
    }

    if (initialState && form.formView.state === 'insert') {
        $('#CREDIT_DEBIT_RADIO_BTN-Credit').prop('checked', true);
    }

    if (initialState) {
        model.on('change:DEBIT_CURRENCY', () => {
            updateDebitOrCreditCurrency();
        });
        model.on('change:CREDIT_CURRENCY', () => {
            updateDebitOrCreditCurrency();
        });
    }

    // for single currency, just display the credit amount in view
    if (form.formView.state === 'view') {
        const creditCurrencyValue = model.get('CREDIT_CURRENCY');
        const debitCurrencyValue = model.get('DEBIT_CURRENCY');
        if (creditCurrencyValue === debitCurrencyValue) {
            $('#DEBIT_AMOUNT').hide();
        }
    }

    // NH-27013
    if (form.formView.state === 'modify' && !creditDebitBtn.isVisible() && model.get('ENTERED_AMOUNT_FLAG') === 'D') {
        model.set('DEBIT_AMOUNT', model.get('CREDIT_AMOUNT'));
    }

    // Cross-Currency Support
    if (creditDebitBtn.isVisible()) {
        const isDebitSelected = creditDebitBtn.$el.is(':checked');
        if (isDebitSelected) {
            debitAmount.shouldBeReadOnly(false);
            debitAmount.shouldBeRequired(true);

            creditAmount.shouldBeReadOnly(true);
            creditAmount.shouldBeOptional(true);

            model.set({
                ENTERED_AMOUNT_FLAG: 'D',
                CREDIT_AMOUNT: 0,
            });
        } else {
            creditAmount.shouldBeReadOnly(false);
            debitAmount.shouldBeRequired(true);

            debitAmount.shouldBeReadOnly(true);
            debitAmount.shouldBeOptional(true);

            model.set({
                ENTERED_AMOUNT_FLAG: 'C',
                DEBIT_AMOUNT: 0,
            });
        }
    }

    if (model.get('ENTRYMETHOD') && (model.get('ENTRYMETHOD') === '1' || model.get('ENTRYMETHOD') === '2')) {
        protectFields();
    }
}
