import { util } from '@glu/core';
import Model from 'ptx/system/models/baseModel';

export default Model.extend({
    initialize() {
        this.listenTo(this, 'change:virtualTrayId', this.normalizeVirtualTrayId);
    },

    normalizeVirtualTrayId(model, value) {
        const options = {
            // To prevent re-rendering
            silent: true,
        };

        if (value === '' || util.isNullOrUndefined(value)) {
            return this.set('virtualTrayId', null, options);
        }

        return this.set('virtualTrayId', +value, options);
    },

    getVirtualTray() {
        return this.get('virtualTrayId');
    },

    getTrayId() {
        return this.get('printerTrayId');
    },

    clearField(field) {
        this.unset(field, {
            // to prevent re-rendering
            silent: true,
        });
    },
});
