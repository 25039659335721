import React from 'react';
import PropTypes from 'prop-types';
import locale from '@glu/locale';

const headerStyle = { minWidth: 150 };

const HistoryHeader = ({ rowHeader }) => {
    const filteredRowHeader = rowHeader.filter(el => el.displayOrder > -1);

    return (
        <tr>
            {filteredRowHeader.map((el, index) => (
                <th key={index} style={headerStyle}>
                    {el.displayName || el.displayKey ? locale.get(el.displayKey) : ''}
                </th>
            ))}
        </tr>
    );
};

HistoryHeader.propTypes = {
    rowHeader: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HistoryHeader;
