var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isOFXDirectConnectEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <label for=\"altUserId\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.ofx.id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":73}}}))
    + "</label> &nbsp;<span> "
    + alias2(((helper = (helper = lookupProperty(helpers,"alternateUserIdValue") || (depth0 != null ? lookupProperty(depth0,"alternateUserIdValue") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"alternateUserIdValue","hash":{},"data":data,"loc":{"start":{"line":16,"column":95},"end":{"line":16,"column":119}}}) : helper)))
    + "</span> </br>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <fieldset>\n            <legend class=\"sr-only\">Opt-out</legend>\n            <div class=\"form-group checkbox\">\n                <input class=\"opt-check\" id=\"optOut\" type=\"checkbox\" name=\"OPTUISECURITYCHECK\" data-bind=\"model\">\n                <label for=\"optOut\">Opt-out of Trusteer Rapport</label>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"optReason\">Opt-out Reason</label>\n                <input type=\"text\" id=\"optReason\" class=\"opt-reason\" name=\"OPTUISECURITYCHECKREASON\" data-bind=\"model\">\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"OPTUISECURITYCHECKREASON\"></span>\n            </div>\n        </fieldset>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":45,"column":39},"end":{"line":45,"column":45}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":45,"column":60},"end":{"line":45,"column":69}}}) : helper)))
    + "</option>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <section class=\"radio\">\n                <span>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.Restriction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":22},"end":{"line":56,"column":53}}}))
    + "</span>\n                <div class=\"radio-inline\">\n                    <input type=\"radio\"\n                           name=\"ALLOWFXUSDUSER\"\n                           value=\"0\"\n                           data-bind=\"model\"\n                           id=\"allowFXUSDUser0\"/>\n                    <label for=\"allowFXUSDUser0\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.USD.Only",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":64,"column":49}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"radio-inline\">\n                    <input type=\"radio\"\n                           name=\"ALLOWFXUSDUSER\"\n                           value=\"1\"\n                           data-bind=\"model\"\n                           id=\"allowFXUSDUser1\"\n                           "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInsert") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":27},"end":{"line":73,"column":57}}})) != null ? stack1 : "")
    + "/>\n                    <label for=\"allowFXUSDUser1\">\n                        "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.Allow.FX",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":75,"column":49}}}))
    + "\n                    </label>\n                </div>\n                <a data-toggle=\"popover\"\n                   data-trigger=\"hover focus\"\n                   role=\"button\"\n                   data-placement=\"auto\"\n                   data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.FX.user.tooltip",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":82,"column":33},"end":{"line":82,"column":65}}}))
    + "\">\n                    <span class=\"icon-info\"></span>\n                </a>\n            </section>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "checked";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":127,"column":35},"end":{"line":127,"column":41}}}) : helper)))
    + "\" data-item=\"\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":127,"column":56},"end":{"line":127,"column":65}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"accountName") || (depth0 != null ? lookupProperty(depth0,"accountName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accountName","hash":{},"data":data,"loc":{"start":{"line":127,"column":66},"end":{"line":127,"column":81}}}) : helper)))
    + "</option>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"field-container-sm\">\n            <label for=\"dailyLimit\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.daily",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":142,"column":36},"end":{"line":142,"column":69}}}))
    + "</label>\n            <input id=\"dailyLimit\" type=\"number\" class=\"form-control daily-limit-field\" name=\"dailyLimit\">\n        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"field-container-sm\">\n            <label for=\"transactionLimit\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"administration.transaction",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":148,"column":42},"end":{"line":148,"column":81}}}))
    + "</label>\n            <input id=\"transactionLimit\" type=\"number\" class=\"form-control transaction-limit-field\"\n                   name=\"transactionLimit\">\n        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"field-container-sm\">\n            <label for=\"fileLimit\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"uce.batch",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":155,"column":35},"end":{"line":155,"column":57}}}))
    + "</label>\n            <input id=\"fileLimit\" type=\"number\" class=\"form-control file-limit-field\" name=\"fileLimit\">\n        </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <h4 class=\"form-section-border\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.additional.services",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":163,"column":44},"end":{"line":163,"column":80}}}))
    + "</h4>\n        </div>\n\n        <div>\n            <div class=\"field-container-lg assign-bank-widgets\">\n                <label for=\"bankWidgets\" class=\"optional\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.assign.bankWidget",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":169,"column":20},"end":{"line":169,"column":65}}}))
    + "\n                    <span>\n                        <input id=\"all-bankWidgets\" type=\"checkbox\" class=\"all-bank-widgets\" name=\"assignAllBankWidget\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.select.all.bankWidgets",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":171,"column":121},"end":{"line":171,"column":171}}}))
    + "\n                        <span class=\"icon-info\"  data-toggle=\"popover\" data-trigger=\"hover\" data-placement=\"top\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.select.all.bankWidgets.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":172,"column":127},"end":{"line":172,"column":182}}}))
    + "\"></span>\n                    </span>\n                </label>\n                <select id=\"bankWidgets\" multiple=\"multiple\" class=\"form-control bank-widgets-field\" data-bind=\"model\" name=\"bankWidgets\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"bankWidgets") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":20},"end":{"line":179,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"bankWidgets\"></span>\n                <strong id=\"allBankWidgetsText\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.select.all.bankWidgets.note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":182,"column":48},"end":{"line":182,"column":103}}}))
    + "</strong>\n            </div>\n        </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"panel panel-primary auditPanel\">\n        <h4 role=\"tab\" class=\"form-section-border panel-title\" id=\"auditHeading\">\n            <a role=\"button\" data-toggle=\"collapse\" href=\"#auditPanel\" aria-expanded=\"false\" aria-controls=\"auditPanel\" class=\"collapsed\">\n                <span class=\"indicator-icon\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"_ADMIN.USERS._USER.*.CONTAINER2",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":193,"column":53},"end":{"line":193,"column":97}}}))
    + "\n            </a>\n        </h4>\n        <div id=\"auditPanel\" class=\"panel-collapse collapse collapsed\" role=\"tabpanel\" aria-labelledby=\"auditHeading\">\n            <div class=\"panel-body\">\n            </div>\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert-region\"></div>\n\n<form class=\"form-container user-info\">\n    <!--\n    <div>\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.user.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":49}}}))
    + "</h4>\n    </div>\n    -->\n    <div class=\"smbDynamicFieldsRegion\"></div>\n\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModifyMode") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showOptUI") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "\n    <fieldset>\n        <legend>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.copyExistingUserPermContainer",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":37,"column":62}}}))
    + "</legend>\n\n    <div class=\"form-section\">\n            <div class=\"field-container-sm\">\n                <label for=\"COPYEXISTINGUSER\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"_ADMIN.USERS._USER.*.existingUser.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":46},"end":{"line":41,"column":98}}}))
    + "</label>\n                <select id=\"COPYEXISTINGUSER\" class=\"form-control\" data-bind=\"model\" name=\"COPYEXISTINGUSER\">\n                    <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"existingUsers") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":46,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"COPYEXISTINGUSER\"></span>\n            </div>\n    </fieldset>\n\n    <div>\n        <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.permissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":53,"column":51}}}))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAllowFXSelection") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":86,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"field-container-sm\">\n            <label class=\"checkbox-inline select-all-group\">\n                <input id=\"all-permissions\" type=\"checkbox\" name=\"all-permissions\" class=\"all-permissions\"\n                       name=\"sole-approver\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.select.all.permissions",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":90,"column":45},"end":{"line":90,"column":95}}}))
    + "\n            </label>\n        </div>\n    </div>\n\n    <div class=\"role-permissions\">\n    </div>\n\n    <div class=\"row\">\n        <div class=\"field-container field-container-md select-beneficiary\">\n            <h4>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.beneficiaries",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":101,"column":16},"end":{"line":101,"column":57}}}))
    + "</h4>\n            <label for=\"getPaymentsChk\" class=\"optional\">\n                <span>\n                    <input id=\"getPaymentsChk\" type=\"checkbox\"  data-hook=\"getPaymentsChk\"  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":92},"end":{"line":104,"column":123}}})) != null ? stack1 : "")
    + "> "
    + alias2(((helper = (helper = lookupProperty(helpers,"MustSelectBeneLabel") || (depth0 != null ? lookupProperty(depth0,"MustSelectBeneLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"MustSelectBeneLabel","hash":{},"data":data,"loc":{"start":{"line":104,"column":125},"end":{"line":104,"column":148}}}) : helper)))
    + "\n                    <span class=\"icon-info\"  data-toggle=\"popover\" data-trigger=\"hover\" data-placement=\"bottom\" data-title=\"\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.smbperm.benePay.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":105,"column":140},"end":{"line":105,"column":177}}}))
    + "\"></span>\n                </span>\n            </label>\n        </div>\n    </div>\n\n    <div>\n        <h4 class=\"form-section-border\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"uce.assignAccounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":112,"column":40},"end":{"line":112,"column":71}}}))
    + "</h4>\n    </div>\n\n    <div>\n        <div class=\"field-container-lg assign-accounts\">\n            <label for=\"accounts\" class=\"optional\">\n                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.assign.access",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":118,"column":16},"end":{"line":118,"column":57}}}))
    + "\n                <span>\n                    <input id=\"all-accounts\" type=\"checkbox\" class=\"all-accounts\" name=\"assignAllAccount\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.select.all.accounts",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":120,"column":107},"end":{"line":120,"column":154}}}))
    + "\n                    <span class=\"icon-info\"  data-toggle=\"popover\" data-trigger=\"hover\" data-placement=\"bottom\" data-title=\"All Accounts\" data-content=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.select.all.accounts.info",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":121,"column":152},"end":{"line":121,"column":204}}}))
    + "\"></span>\n                </span>\n            </label>\n            <select id=\"accounts\" multiple=\"multiple\" class=\"form-control accounts-field\" data-bind=\"model\" name=\"accounts\">\n                <option value=\"\"></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"accounts") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":16},"end":{"line":128,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"help-block\" data-bind=\"model\" data-validate=\"accounts\"></span>\n            <strong id=\"allAccountsText\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.select.all.note",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":131,"column":41},"end":{"line":131,"column":84}}}))
    + "</strong>\n        </div>\n    </div>\n\n    <div>\n        <h4 class=\"form-section-border\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"administration.limits",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":136,"column":40},"end":{"line":136,"column":74}}}))
    + "</h4>\n    </div>\n\n    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDailyLimitEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":4},"end":{"line":145,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTransactionLimitEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":4},"end":{"line":152,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFileLimitEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":4},"end":{"line":158,"column":11}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBankWidgetsEntitled") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":4},"end":{"line":185,"column":11}}})) != null ? stack1 : "")
    + "\n    <div data-region=\"locationLoginRegion\" class=\"LocationLoginWrapper\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInsert") : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":189,"column":4},"end":{"line":201,"column":15}}})) != null ? stack1 : "")
    + "</form>";
},"useData":true});