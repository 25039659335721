export default function ({ palette, typography }) {
  return ({
    sortArrow: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAkklEQVQoU9WS0Q3CMAxE7UzACIxgXRboKIzAKB2ho3SBWBmBEZggQYca1ECKxCf+9N2zfE5Ufiwd+c3M2M8553f9CJg2YP0XAMBVRO7uvnBnM+syALiIyMnd51foGONSa10J7QGaVXVKKRGS7koNKqXcKIYQznvzB8DGBtWnqKptcjvv8B0AzDS4O7N1NQS+/ZYHemJJDTPPMqkAAAAASUVORK5CYII=)',
    gridWrapper: {
      border: '1px solid #bbb',
      '& .ag-input-wrapper': {
        width: 11,
        height: 11,
        borderRadius: 2,
        overflow: 'hidden',
        '& .ag-icon-checkbox-checked': {
          color: 'unset',
          borderRadius: 2,
          overflow: 'hidden',

          '&::before': {
            display: 'block',
            fontSize: 15,
            lineHeight: '12px',
            marginLeft: '-2px'
          }
        },
        '& .ag-icon-checkbox-indeterminate': {
          '&::before': {
            display: 'block',
            fontSize: 15,
            marginLeft: '-2px'
          }
        }
      },
      '& .ag-row-odd': {
        backgroundColor: palette.highlightBackground
      },
      '& .ag-selection-checkbox': {
        marginTop: -1,
        position: 'absolute'
      },
      '& .ag-icon.ag-icon-checkbox-unchecked': {
        width: 11,
        height: 11,
        border: '1px solid #b1b1b1',
        borderRadius: 2,
        backgroundColor: '#dedede',
        boxShadow: 'inset 0px 1px 3px 0px #ebebeb, 0px 1px 0px 0px #e5e5e5',
        '&::before': {
          display: 'none'
        }
      },
      '& .ag-icon.ag-icon-checkbox-checked': {
        width: 11,
        height: 11,
        borderRadius: 2,
        backgroundSize: 12,
        margin: 0
      }
    },
    header: {
      '& .ag-header-cell': {
        borderRight: '1px solid #bbb',
        borderBottom: '1px solid #bbb',
        backgroundColor: palette.highlightBackground
      }
    },
    cellHeaderText: {
      textTransform: 'none',
      fontWeight: 'bold'
    },
    row: {
      border: 'none',
      '& .ag-cell.ag-cell-last-left-pinned.ag-cell-value': {
        border: 'none'
      },
      shaded: palette.highlightBackground
    },
    whiteBackgroundColor: '#fff', // do not delete
    actions: {
      dotFill: palette.primary.normal,
      dotHoverBackground: palette.primary.normal,
      dotHoverFill: palette.text.light,
      resetBorder: palette.primary.light,
      buttonColor: palette.text.dark,
      buttonTextDecorationColor: palette.text.dark,
      buttonHoverBackground: palette.grey.G200,
      ...typography
    },
    footer: {
      background: 'rgba(255,255,255,0.95)',
      boxShadow: '0px -3px 3px 0px rgba(0,0,0,0.2)'
    }
  });
}
