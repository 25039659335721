var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"glu-container\">\n                <div class=\"row group-plan-select-row\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.view.groupplan.select",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":55},"end":{"line":4,"column":98}}}))
    + "</div>\n                <div class=\"row groupplan-props-row\">\n                        <div class=\"pull-left\" data-hook=\"groupplan-properties-region\"></div>\n                        <div class=\"pull-right\">\n                                <button class=\"btn btn-tertiary\" data-hook=\"edit-group-plan\"><span class=\"icon-pencil\"></span> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.edit.groupplan.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":127},"end":{"line":8,"column":167}}}))
    + "</button>\n                                <button class=\"btn btn-secondary\" data-action=\"createGroupPlanDialog\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.create.new.plan.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":102},"end":{"line":9,"column":143}}}))
    + "</button>\n                        </div>\n                </div>\n\n                <div class=\"row groupplan-stats-row\" data-region=\"groupplan-stats-region\"></div>\n\n                <div class=\"row subplan-row\" data-region=\"subplan-region\"></div>\n\n                <div class=\"widget-action-button-group pull-left\">\n                        <button class=\"btn btn-primary\" data-hook=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.save.plan.btn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":73},"end":{"line":18,"column":108}}}))
    + "</button>\n                </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"glu-container no-group-plans\">\n                <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.view.groupplan.nogroupplan",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":23,"column":19},"end":{"line":23,"column":67}}}))
    + "</p>\n\n                <div class=\"widget-action-button-group\">\n                        "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"textVisible":"true","data-hook":"create-group-plan","className":"btn btn-secondary btn-lg","locale":"cashflow.groupplan.create","action":"createGroupPlanDialog","name":"plus"},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":240}}}))
    + "\n                </div>\n\n                <a href=\"#\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"cashflow.view.groupplan.whatare",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":29,"column":72}}}))
    + "</a>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasGroupPlans") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":31,"column":7}}})) != null ? stack1 : "");
},"useData":true});