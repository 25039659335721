import Controller from '@glu/core/src/controller';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import services from 'services';

const TypeaheadLookup = Controller.extend({
    initialize() {
        this.request = this.createDefault();
    },

    getFieldMap(fieldName) {
        switch (fieldName) {
        case 'ASSIGNEDTO':
            return {
                fieldName: 'ASSIGNEDTO',
                onLoadValue: 'ASSIGNED_TO_NAME',
                searchFieldName: 'USERNAME',
                idAttribute: 'USERID',
                textAttribute: 'USERNAME',
            };

        default:
            return false;
        }
    },

    setTextSearchFromValue(name, value) {
        let localValue = value;
        const fieldMap = this.getFieldMap(name);
        if (fieldMap === false) {
            return false;
        }

        localValue = this.valueFormatter(localValue, fieldMap.valueFormatter);

        this.request.fieldName = fieldMap.fieldName;
        this.request.searchFields[0].fieldName = fieldMap.idAttribute;
        this.request.searchFields[0].fieldValue = [localValue];
        this.request.searchFields[0].operator = 'EQ';
        return true;
    },

    valueFormatter(value, valueFormat) {
        if (valueFormat === 'UPPER') {
            return value.toUpperCase();
        }

        return value;
    },

    setSearch(name, str) {
        const fieldMap = this.getFieldMap(name);
        if (fieldMap === false) {
            return false;
        }

        this.request.fieldName = fieldMap.fieldName;
        this.request.searchFields[0].fieldName = fieldMap.searchFieldName;
        this.request.searchFields[0].fieldValue = [str];
        return true;
    },

    createDefault() {
        return {
            rowsPerPage: 50,
            startRow: 1,
            fieldName: 'ASSIGNEDTO',
            depends: [],
            subType: '*',

            searchFields: [{
                fieldName: 'USERNAME',
                operator: 'CONTAINS',
                fieldValue: [],
                dataType: 'text',
            }],

            requestParameters: {
                item: [{
                    name: 'PRODUCTCODE',
                    value: 'BOS',
                }, {
                    name: 'TYPECODE',
                    value: 'CASHFPL',
                }, {
                    name: 'FUNCTIONCODE',
                    value: 'MAINT',
                }],
            },
        };
    },

    getRequest() {
        return this.request;
    },

    send(name) {
        const parseFunction = util.bind(this.parseResponse, this, name);

        return http.post(services.advancedLookup, this.getRequest())
            .then(parseFunction, this.handleError);
    },

    parseResponse(name, response) {
        const fieldMap = this.getFieldMap(name);
        return util.map(response.rows, (row) => {
            // adds select2 properties
            const hash = transform.pairsToHash(row.columns, 'fieldName', 'fieldValue');
            return {
                text: hash[fieldMap.textAttribute],
                id: hash[fieldMap.idAttribute],
            };
        });
    },

    handleError() {

    },
});

export default TypeaheadLookup;
