import ItemView from '@glu/core/src/itemView';
import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import WidgetLayout from 'dashboard/js/widget/generic/WidgetLayout';
import Handlebars from 'handlebars';
import services from 'services';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import widgetIframeViewTemplate from './proxyDiv.hbs';

const DynamicWidgetContentModel = Model.extend({
    idAttribute: 'widgetId',

    sync(method, collection, options) {
        if (method === 'read') {
            const url = services.generateUrl('/cxp/externalWidget/getWidgetContent');

            http.post(url, this.toJSON(), (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
    },
});

const WidgetContentModel = Model.extend({
    sync(method, collection, options) {
        if (method === 'read') {
            const url = services.generateWidgetUrl(`${this.get('widgetId')}/index.html`);

            http.get(url, (response) => {
                options.success(response);
            }, (result) => {
                options.error(result);
            });
        }
    },

    parse(response) {
        return {
            content: response,
        };
    },
});

const FrameView = ItemView.extend({
    template: widgetIframeViewTemplate,
    className: 'full-page',
    loadingTemplate,

    initialize(options) {
        this.widgetId = options.widgetId;

        this.dynamicWidgetContent = new DynamicWidgetContentModel({
            widgetId: this.widgetId,
        });

        this.widgetContent = new WidgetContentModel({
            widgetId: this.widgetId,
        });
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const resources = util.extend({}, {
                resourceUrl: services.generateWidgetUrl(`${this.widgetId}/`),
            }, this.dynamicWidgetContent.get('htmlKeyValues'));

            let html = Handlebars.compile(this.widgetContent.get('content'))(resources);
            const widgetId = this.widgetContent.get('widgetId');

            html = html.replace(
                /\$digital-banking-proxy/g,
                services.generateServletUrl(`ProxyServlet/${widgetId}`),
            );
            html = html.replace(
                /\$digital-content-repository/g,
                services.generateWidgetUrl(widgetId.toString()),
            );

            this.$el.html(html);
        } else {
            this.loadRequiredData();
        }
    },

    loadRequiredData() {
        const self = this;

        const dynamicWidgetContentPromise = new Promise(((resolve, reject) => {
            self.dynamicWidgetContent.fetch({
                success: resolve,
                error: reject,
            });
        }));

        const widgetContentPromise = new Promise(((resolve, reject) => {
            self.widgetContent.fetch({
                success: resolve,
                error: reject,
            });
        }));

        Promise.all([dynamicWidgetContentPromise, widgetContentPromise]).then(() => {
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },
});

export default WidgetLayout.extend({
    className: 'widget',
    constructor(opts) {
        WidgetLayout.prototype.constructor.call(this, opts);

        this.frameView = new FrameView({
            model: this.model,
            widgetId: opts.widgetId,
        });
    },

    setSrc(src) {
        this.model.set('src', src);
    },

    render() {
        WidgetLayout.prototype.render.call(this);
        this.body.show(this.frameView);
    },
});
