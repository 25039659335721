var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contactIsInvalid") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":19,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"orderingPartyIsInvalid") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":29,"column":31}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsBeneInvalid",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":63}}}))
    + "</p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.beneInvalidChooseNew",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":31},"end":{"line":12,"column":68}}}))
    + "</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"contactUpdated") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":19,"column":24}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsBeneDetailsUpdated",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":32},"end":{"line":15,"column":71}}}))
    + " <br>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsBeneChooseNotToUpdate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":16,"column":74}}}))
    + "\n                            </p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsBeneUpdateContinue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":31},"end":{"line":18,"column":70}}}))
    + "</p>\n                        ";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsOrderingPartyInvalid",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":21,"column":69}}}))
    + "</p>\n                            "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsOrderingPartyChooseNew",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":71}}}))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"orderingPartyUpdated") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":29,"column":24}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsOrderingPartyDetailsUpdated",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":25,"column":80}}}))
    + " <br>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsOrderingPartyChooseNotToUpdate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":83}}}))
    + "\n                            </p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtgsOrderingPartyUpdateContinue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":28,"column":31},"end":{"line":28,"column":79}}}))
    + "</p>\n                        ";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isRFP") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":64,"column":20}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"contactIsInvalid") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":40,"column":31}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rfpBeneInvalid",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":31},"end":{"line":32,"column":62}}}))
    + "</p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.payerInvalidChooseNew",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":33,"column":31},"end":{"line":33,"column":69}}}))
    + "</p>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rfpBeneDetailsUpdated",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":32},"end":{"line":36,"column":70}}}))
    + " <br>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rfpBeneChooseNotToUpdate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":37,"column":32},"end":{"line":37,"column":73}}}))
    + "\n                            </p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rfpBeneUpdateContinue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":39,"column":31},"end":{"line":39,"column":69}}}))
    + "</p>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isRTP") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":64,"column":20}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"contactIsInvalid") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":51,"column":31}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtpBeneInvalid",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":43,"column":31},"end":{"line":43,"column":62}}}))
    + "</p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.beneInvalidChooseNew",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":44,"column":31},"end":{"line":44,"column":68}}}))
    + "</p>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtpBeneDetailsUpdated",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":47,"column":70}}}))
    + " <br>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtpBeneChooseNotToUpdate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":48,"column":32},"end":{"line":48,"column":73}}}))
    + "\n                            </p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.rtpBeneUpdateContinue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":31},"end":{"line":50,"column":69}}}))
    + "</p>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"contactIsInvalid") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":63,"column":31}}})) != null ? stack1 : "")
    + "                    ";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.achBeneInvalid",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":54,"column":31},"end":{"line":54,"column":62}}}))
    + "</p>\n                            <p>"
    + alias2(((helper = (helper = lookupProperty(helpers,"inavlidBeneficiaries") || (depth0 != null ? lookupProperty(depth0,"inavlidBeneficiaries") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"inavlidBeneficiaries","hash":{},"data":data,"loc":{"start":{"line":55,"column":31},"end":{"line":55,"column":55}}}) : helper)))
    + "</p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.beneInvalidChooseNew",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":56,"column":31},"end":{"line":56,"column":68}}}))
    + "</p>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.achBeneDetailsUpdated",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":32},"end":{"line":59,"column":70}}}))
    + " <br>\n                                "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.achBeneChooseNotToUpdate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":60,"column":32},"end":{"line":60,"column":73}}}))
    + "\n                            </p>\n                            <p>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.achBeneUpdateContinue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":62,"column":31},"end":{"line":62,"column":69}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog glu-dialog dialog-warning\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header\">\n            <h2 class=\"modal-title\"><span class=\"icon-info-solid\"></span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"modalTitle") || (depth0 != null ? lookupProperty(depth0,"modalTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"modalTitle","hash":{},"data":data,"loc":{"start":{"line":4,"column":73},"end":{"line":4,"column":87}}}) : helper)))
    + "</h2>\n        </div>\n        <div class=\"modal-body\">\n            <div class=\"form-group\">\n                <div class=\"BeneModal BeneModal--update\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRTGS") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":64,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n        <div class=\"modal-footer\">\n            <button type=\"submit\" class=\"btn btn-primary\" data-action=\"save\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.continue",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":69,"column":77},"end":{"line":69,"column":105}}}))
    + "</button>\n            <button type=\"button\" class=\"btn btn-tertiary\" data-action=\"cancel\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":70,"column":101},"end":{"line":70,"column":127}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});