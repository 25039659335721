import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import getStore from './store';

export const reduxStore = getStore();

const StoreProvider = ({ children }) => (
    <Provider store={reduxStore}>
        {children}
    </Provider>
);

StoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StoreProvider;
