import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import locale from '@glu/locale';
import PageDisplayCount from './PageDisplayCount';
import PageSelector from './PageSelector';
import { styles } from './Pagination.styles';
import '../../themeDefaults';

export function getPageRange({ page, pageSize, totalItems }) {
  const start = totalItems === 0 ? 0 : pageSize * page + 1;
  const end = start + pageSize - 1;
  const endResult = end > totalItems ? totalItems : end;
  if (start === endResult) {
    return locale.get('dataComponents.viewOf', start, totalItems);
  }
  return locale.get('dataComponents.viewOf', `${start}-${endResult}`, totalItems);
}

export function PaginationComponent({
  className,
  classes,
  data,
  hidePageCount,
  htmlIdRoot,
  onChange,
  subrowIndex,
  totalItems,
  ...rest
}) {
  const {
    range,
    wrapper
  } = classes;
  const {
    page,
    pageSize
  } = data;
  /*
   * TODO: make start and active consistent. Either 0 based or not
   */
  const numberOfPages = Math.ceil(totalItems / pageSize);
  const handleArrowChange = useCallback((direction) => () => {
    const update = direction === 'forward' ? page + 1 : page - 1;
    onChange({
      errors: {},
      name: 'page',
      value: update
    });
  }, [numberOfPages, onChange, page]);

  useEffect(() => {
    // Page from data is greater than number of pages
    if (page > Math.max(0, numberOfPages - 1)) {
      onChange({
        errors: {},
        name: 'page',
        value: numberOfPages - 1
      });
    }
  }, [numberOfPages, onChange, page]);

  const handleChange = useCallback((pageOption) => () => {
    onChange({
      errors: {},
      name: 'page',
      value: pageOption
    });
  }, [numberOfPages, onChange]);

  const handlePageSizeChange = useCallback((size) => {
    const totalNextPages = Math.ceil(totalItems / size);
    onChange({
      errors: {},
      name: 'pageSize',
      value: Number(size)
    });

    // If last page no longer exists. Restart.
    /*
     * TODO: Possibly shift to next last page
     * be sure to check the arrows.
     */
    if (page >= totalNextPages) {
      onChange({
        errors: {},
        name: 'page',
        value: 0
      });
    }
  }, [onChange, page, totalItems]);

  return (
    <div className={`${wrapper} ${className}`.trim()}>
      <div
        className={range}
        data-qa="pagination-range"
      >
        {getPageRange({ page, pageSize, totalItems: subrowIndex ? totalItems - 1 : totalItems })}
      </div>
      <div>
        {!hidePageCount && (
          <PageDisplayCount
            classes={classes}
            pageSize={pageSize}
            onChange={handlePageSizeChange}
            totalItems={totalItems}
            htmlIdRoot={htmlIdRoot}
            {...rest}
          />
        )}
        <PageSelector
          classes={classes}
          page={page}
          pageSize={pageSize}
          onArrowClick={handleArrowChange}
          onChange={handleChange}
          totalItems={totalItems}
        />
      </div>
    </div>
  );
}

PaginationComponent.propTypes = {
  /** Optional className to add to the classes applied to the root element */
  className: PropTypes.string,

  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired
  }).isRequired,
  hidePageCount: PropTypes.bool,
  htmlIdRoot: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  subrowIndex: PropTypes.number,
  /*
   * TODO: not all LOBs have total items.
   * This will need to be optional, but
   * it will affect an number of things
   * such as how many pages to list
   * how to handle the dropdown, and the
   * Viewing X of Y indicator.
   * Leaving required until a clear use
   * case arises that we can use to
   * guide development.
   */
  totalItems: PropTypes.number.isRequired
};

PaginationComponent.defaultProps = {
  className: '',
  hidePageCount: false,
  subrowIndex: 0
};

export default withStyles(styles)(PaginationComponent);
