var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"button-container DocumentWidget-buttonContainer\">\n        <button\n            class=\"btn btn-secondary\"\n            data-hook=\"getAddDocument\"\n            type=\"button\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTGS.INST.FEDWIRE.*.DOC_ADD_DOCUMENT.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":26},"end":{"line":7,"column":81}}}))
    + "</button>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-12 widget-field-container DocumentWidget\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNotView") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n    <p class=\"textline-field\" data-hook=\"getAddDocumentInfo\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(alias1,"RTGS.INST.FEDWIRE.*.ADD_DOCUMENT_INFO",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":61},"end":{"line":11,"column":111}}}))
    + "</p>\n    <div data-region=\"widgetAlertRegion\" class=\"alert-region\"></div>\n    <div data-region=\"childGridRegion\" class=\"Child-grid grid-region\"></div>\n</div>\n\n";
},"useData":true});