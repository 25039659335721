import http from '@glu/core/src/http';
import Model from '@glu/core/src/model';
import services from 'services';
import filterApi from 'common/dynamicPages/api/filters';
import util from '@glu/core/src/util';
import dateRangeUtil from '../api/dateRangeUtil';

const AccountFilterParent = Model.extend({
    reset() {
        this.set(this.defaults());
        this.updateAccountOptionList();
    },

    validators: {
        VIEWNAME: {
            description: 'View Name',
            exists: true,
        },
    },

    getTypeAheadFieldData() {
        const self = this;
        const fetchPromises = [];

        util.each(this.fieldData, (pair) => {
            const data = {
                IncludeMapData: 1,

                queryCriteria: {
                    inquiryId: pair.inquiryId,

                    action: {
                        typeCode: 'GIRACCT',
                        entryMethod: 0,
                        productCode: 'GIR',
                        actionMode: 'SELECT',
                        functionCode: 'INST',
                    },
                },

                requestHeader: {
                    queryPagesize: 0,
                    queryOffset: 0,
                },
            };

            fetchPromises.push(http.post(services.inquiryQueryResults, data, (response) => {
                const filters = util.map(response.queryResponse.QueryData.queryRows, row => ({
                    id: row.name,
                    text: row.label,
                }));

                self.set(pair.field, filters);
            }));
        });

        return Promise.all(fetchPromises);
    },

    getViewFilters() {
        const filters = [{
            paramName: 'Filters',
            paramValue: `START_DATE^START_DATE^4^GT^${this.get('START_DATE')}`,
        }, {
            paramName: 'Filters',
            paramValue: `END_DATE^END_DATE^4^LTEQ^${this.get('END_DATE')}`,
        }, {
            paramName: 'Filters',
            paramValue: `accountTransactionsView^accountTransactionsView^1^EQ^${this.get('accountTransactionsView')}`,
        }, {
            paramName: 'Filters',
            paramValue: `accountSelectBy^accountSelectBy^1^EQ^${this.get('accountSelectBy')}`,
        }, {
            paramName: 'Filters',
            paramValue: `accountSelect^accountSelect^1^IN^${this.get('accountSelect')}`,
        }];

        util.each(this.get('tranFilters'), (profileValue) => {
            const filter = {
                paramName: 'Filters',
                paramValue: profileValue,
            };
            filters.push(filter);
        });

        return filters;
    },

    getRequestObject() {
        return {
            viewID: this.get('viewId'),
        };
    },

    parse(res) {
        // sometimes the success callback will fire but the JSON will be an error message
        if (res.success === false || util.isUndefined(res.filters)) {
            return undefined;
        }

        let attrs = filterApi.filtersToPairs(res.filters);
        /*
         * NH-144471 This does not use the same filter logic that we see throughout
         * the application. Need to specifically pull out the second amount.
         */
        if (Array.isArray(attrs.AMOUNT) && attrs.AMOUNT.length > 1) {
            const [val1, val2] = attrs.AMOUNT;
            attrs = {
                ...attrs,
                AMOUNT: val1,
                AMOUNT2: val2,
            };
        }

        if (typeof attrs.accountSelect === 'string') {
            attrs.accountSelect = attrs.accountSelect.split(',');
        }
        attrs.Accounts = attrs.accountSelect;

        // convert date code to date range:

        const dates = dateRangeUtil.getDates(attrs.START_DATE, attrs.END_DATE);
        attrs.START_DATE = dates.startDate;
        attrs.END_DATE = dates.endDate;
        return attrs;
    },

    sync(method, model, options) {
        const data = this.getRequestObject('read');
        http.post(services.generateUrl('/listViewFilter/getListViewFilterDetails'), data, options.success, options.error);
    },
});

export default AccountFilterParent;
