import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import http from '@glu/core/src/http';
import services from 'services';
import store from 'system/utilities/cache';
import { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import NotificationView from './notificationList';
import TemplateView from './templateList';
import tabWrapperTmpl from './tabWrapper.hbs';

export default Layout.extend({
    template: tabWrapperTmpl,

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navItems: '[data-hook="getNavTabs"] .NavTabs-item',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
        $notificationTab: '[data-hook="getNotificationItem"]',
        $templateTab: '[data-hook="getTemplateItem"]',
        $archiveTab: '[data-hook="getArchiveItem"]',
    },
    isInitialRender: true,

    events: {
        'click @ui.$navLinks': 'switchTab',
        'click @ui.$exportBtn': 'export',
        'click @ui.$printBtn': 'print',
        'click @ui.$refreshBtn': 'refreshData',
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            this.loadDefaultTab();
            this.isInitialRender = false;
        } else {
            this.loadEntitlements();
        }
    },

    loadEntitlements() {
        const self = this;
        const url = services.generateUrl('accessService/hasAccess');

        const hasNotificationEntitlements = {
            functionCode: 'MAINT',
            actionMode: 'SELECT',
            productCode: 'CMAINT',
            typeCode: 'CGRPB_TM',
        };

        const hasTemplateEntitlements = {
            functionCode: 'MAINT',
            actionMode: 'SELECT',
            productCode: 'CMAINT',
            typeCode: 'CGRPBTMP',
        };

        const notificationEntitlementPromise = new Promise((resolve, reject) => {
            http.post(url, hasNotificationEntitlements, resolve, reject);
        });
        const templateEntitlementPromise = new Promise((resolve, reject) => {
            http.post(url, hasTemplateEntitlements, resolve, reject);
        });

        Promise.all([notificationEntitlementPromise, templateEntitlementPromise])
            .then((results) => {
                [
                    self.hasNotificationEntitlements,
                    self.hasTemplateEntitlements,
                ] = results;
                self.setHasLoadedRequiredData(true);
                self.render();
            });
    },

    loadDefaultTab() {
        const defaultTab = store.has('notification:returnTab') ? store.get('notification:returnTab') : 'notification';
        this.switchTab(defaultTab);
        store.unset('notification:returnTab');
    },

    /**
     * @method switchTab
     * @param e
     * Get the tab name from the clicked element or the passed string. Render the
     * appropriate view
     */
    switchTab(e) {
        const tab = e.currentTarget ? this.$(e.currentTarget).attr('data-step') : e;
        let View;
        this.ui.$navItems.removeClass('is-active');
        this.currentTabText = (this.$(e.currentTarget).text() || '').trim();

        switch (tab) {
        case 'notification':
            View = new NotificationView({
                serviceName: 'bannerNotification',
                navigationUrl: 'CMAINT/manageNotifications',
                returnRoute: this.options.returnRoute,
                typeCode: 'CGRPB_TM',
            });
            this.currentTabText = this.currentTabText || locale.get('header.banner');
            this.ui.$notificationTab.addClass('is-active');
            break;
        case 'template':
            View = new TemplateView({
                serviceName: 'bannerNotificationTemplate',
                navigationUrl: 'CMAINT/manageNotificationTemplates',
                returnRoute: this.options.returnRoute,
                typeCode: 'CGRPBTMP',
            });
            this.currentTabText = this.currentTabText || locale.get('ACH.Template');
            this.ui.$templateTab.addClass('is-active');
            break;
        default:
            this.currentTabText = this.currentTabText || locale.get('RTGS.rpt.archived');
            break;
        }
        this.currentTabText = this.currentTabText || locale.get('common.tabs.chooseTab');
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
        this.gridRegion.show(View);
    },

    templateHelpers() {
        return {
            hasNotificationEntitlements: this.hasNotificationEntitlements,
            hasTemplateEntitlements: this.hasTemplateEntitlements,
            hasArchiveEntitlements: false,
        };
    },
});
