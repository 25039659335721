import Model from '@glu/core/src/model';
import services from 'services';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';

const ResetPasswordCheck = Model.extend({

    getToken() {
        const queryStringArray = util.compact(window.location.search.slice(1).split('&'));

        if (util.isEmpty(queryStringArray)) {
            return false;
        }

        const tokenArray = queryStringArray.map((item) => {
            const currentItem = item.split('=');
            if (currentItem[0] === 'passwordReset') {
                return currentItem[1];
            }
            return '';
        });

        return util.compact(tokenArray).toString();
    },

    sync(token) {
        const url = services.generateUrl('/forgottenPassword/validateEmailResetPasswordRequest');

        return http.post(url, {
            emailResetPasswordKey: token,
        });
    },

});

export default new ResetPasswordCheck();
