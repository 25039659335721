import ItemView from '@glu/core/src/itemView';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import dialog from '@glu/dialog';
import exportPaymentsTmpl from './exportPayments.hbs';

export default ItemView.extend({
    template: exportPaymentsTmpl,
    modalClass: 'modal-lg',
    ALL_PAYMENT_SELECTION: 'all',
    SELECT_PAYMENT_SELECTION: 'sel',
    CSV_FILE_FORMAT: 'CSV',
    NACHA_FILE_FORMAT: 'NACHA',
    SUMMARY_EXPORTED_DATA: 'summary',
    TRANSACTION_EXPORTED_DATA: 'transaction',

    modelEvents: {
        'change:fileformat': 'render',
        'change:selection': 'render',
    },

    ui: {
        $fileformatdiv: '#fileFormatDiv',
        $fileformat: 'input[name="fileformat"]',
        $nachaSelection: '#fileformat[value="NACHA"]',
        $exportData: 'input[name="expdata"]',
    },

    initialize(options) {
        this.model = new Model();
        this.model.set({
            selection: options.enableSel
                ? this.SELECT_PAYMENT_SELECTION
                : this.ALL_PAYMENT_SELECTION,
            fileformat: this.CSV_FILE_FORMAT,
            expdata: this.SUMMARY_EXPORTED_DATA,
            enableSel: options.enableSel,
            enableAll: options.enableAll,
            showDataFormatForAll: options.showDataFormatForAll,
            showDataFormatForSel: options.showDataFormatForSel,
            confidentialTransactions: options.confidentialTransactions,
            nachaTransactions: options.nachaTransactions,
            isTemplate: options.isTemplate ? options.isTemplate : false,
            selected: new Collection(options.selected),
        });

        if (options.functioncode) {
            this.model.set('functioncode', options.functioncode);
            this.dialogTitle = locale.get('payment.export') + options.functioncode;
        }

        if (options.functioncode === ' RFPI') {
            this.dialogTitle = locale.get('rfpi.export');
        } else {
            this.dialogTitle = locale.get('payment.export.payment');
        }

        this.dialogButtons = [{
            text: locale.get('payment.export'),
            className: 'btn btn-primary',
            callback: 'export',
        }, {
            text: locale.get('payment.cancel'),
            className: 'btn btn-secondary',
            callback: 'cancel',
        }];
    },

    showOrHideDataFormat(model) {
        if (model.get('selection') === this.ALL_PAYMENT_SELECTION) {
            if (model.get('showDataFormatForAll') && this.model.get('nachaTransactions') === 'ALL') {
                this.ui.$fileformatdiv.show();
                this.ui.$nachaSelection.prop('disabled', false);
            } else {
                this.ui.$fileformatdiv.hide();
                this.ui.$nachaSelection.prop('disabled', true);
                this.model.set('fileformat', this.CSV_FILE_FORMAT);
                this.ui.$exportData.prop('disabled', false);
            }
        } else if (model.get('showDataFormatForSel')) {
            this.ui.$fileformatdiv.show();
            this.ui.$nachaSelection.prop('disabled', false);
            if (model.get('fileformat') === this.NACHA_FILE_FORMAT) {
                this.model.set('expdata', this.TRANSACTION_EXPORTED_DATA);
                this.ui.$exportData.prop('disabled', true);
            } else {
                this.ui.$exportData.prop('disabled', false);
            }
        } else {
            this.ui.$fileformatdiv.hide();
            this.ui.$nachaSelection.prop('disabled', true);
            this.model.set('fileformat', this.CSV_FILE_FORMAT);
            this.ui.$exportData.prop('disabled', false);
        }
    },

    onRender() {
        this.showOrHideDataFormat(this.model);
    },

    /**
     * @method getSelectedFunctionLabel
     * @description return the label of selected item radio btn
     * @param {String} functioncode
     * @returns
     */
    getSelectedFunctionLabel(functioncode) {
        if (functioncode === ' RFPI') {
            return locale.get('RFP.Export.SelectedRequestForPayment');
        }
        return locale.get(`payment.Selected${functioncode}`);
    },

    templateHelpers() {
        const functioncode = this.model.get('functioncode');
        return {
            getString(type) {
                return locale.get(type);
            },

            exportFunctionLabel: locale.get(`payment.Export${functioncode}`),
            allFunctionLabel: locale.get(`payment.All${functioncode}`),
            selectedFunctionLabel: this.getSelectedFunctionLabel(functioncode),

            showNacha() {
                if (this.isTemplate
                    && (this.showDataFormatForSel || this.showDataFormatForAll)) {
                    return false;
                }
                return true;
            },
            notCheckTransaction() {
                return this.functioncode !== ' Check Transactions';
            },
            showConfidential: this.hasConfidentialWarning(),

            toggleSelectionRadio(model, enableFlag) {
                if (enableFlag) {
                    return '';
                }
                return 'disabled';
            },
        };
    },

    hasConfidentialWarning() {
        if ((this.model.get('selection') === this.ALL_PAYMENT_SELECTION && this.model.get('fileformat') === this.NACHA_FILE_FORMAT && this.model.get('confidentialTransactions') === 'PARTIAL') || (this.model.get('fileformat') === this.NACHA_FILE_FORMAT && this.model.get('selection') !== this.ALL_PAYMENT_SELECTION
                && this.model.get('selected').where({
                    CONFIDENTIALFLAG: '1',
                }).length > 0)) {
            return true;
        }
        return false;
    },

    dialogTitle: 'Export Payments',

    dialogButtons: [{
        text: 'Export',
        className: 'btn btn-primary',
        callback: 'export',
    }, {
        text: 'Cancel',
        className: 'btn btn-tertiary',
        callback: 'cancel',
    }],

    export() {
        dialog.close();
        this.trigger('exportPayments', this.model);
    },

    cancel() {
        dialog.close();
    },
});
