import Model from '@glu/core/src/model';
import Accounts from 'app/accounts/collections/accounts';
import transform from 'common/util/transform';
import util from '@glu/core/src/util';

const AccountGroup = Model.extend({
    initialize(attributes, options) {
        this.listenTo(this.get('accounts'), {
            add: (addedAccount) => {
                this.trigger('account:add', addedAccount);
            },
            remove: (removedAccount) => {
                this.trigger('account:remove', removedAccount);
            },
        });

        if (options && options.parse) {
            const accounts = this.get('accounts');
            this.get('originalAccountReferences').forEach((reference) => {
                const account = options.accounts.get(reference.ACCOUNTFILTER);

                if (account) {
                    accounts.add(account.clone());
                    account.incrementGroupCount();
                }
            });
        }

        this.originalJSONString = JSON.stringify(this.toJSON());
    },

    idAttribute: 'ID',

    defaults() {
        return {
            accounts: new Accounts(),
            isMarkedForDeletion: false,
            SECTIONID: 'DEPOSITACCTS',
            TABID: 'ALLACCOUNTS',
        };
    },

    url: 'n/a',

    parse(data) {
        const attributes = transform.pairsToHash(data.item.item);
        attributes.originalAccountReferences = data.grids[0].items
            .map(item => transform.pairsToHash(item.item));
        return attributes;
    },

    toJSON() {
        const attributes = util.clone(this.attributes);
        return {
            item: {
                item: transform.hashToPairs(util.pick(attributes, 'ID', 'NAME', 'USERGROUP', 'USERID', 'SECTIONID', 'TABID')),
            },
            grids: [{
                name: 'AccountGroupSetItems',
                items: this.get('accounts').map(account => ({
                    item: transform.hashToPairs({
                        ACCOUNTFILTER: account.accountFilter(),
                        ACCOUNTNUM: account.accountNumber(),
                        BANKCODE: account.bankCode(),
                    }),
                })),
            }],
        };
    },

    add(account, indx) {
        if (account.sectionId() && this.sectionId() !== account.sectionId()) {
            return false;
        }
        if (this.get('accounts').get(account)) {
            return false;
        }
        if (indx) {
            this.get('accounts').add(account, {
                at: indx,
            });
            return true;
        }
        this.get('accounts').add(account);
        return true;
    },

    remove(account) {
        return this.get('accounts').remove(account);
    },

    contains(account) {
        return (!!this.get('accounts').get(account));
    },

    findByAccountFilter(accountFilter) {
        return this.get('accounts').get(accountFilter);
    },

    getName() {
        return this.get('NAME');
    },

    setName(value) {
        this.set('NAME', value);
    },

    hasBeenModified() {
        return JSON.stringify(this.toJSON()) !== this.originalJSONString;
    },

    markForDeletion() {
        this.set('isMarkedForDeletion', true);
        this.get('accounts').each((account) => {
            this.trigger('account:remove', account);
        });
    },

    isMarkedForDeletion() {
        return this.get('isMarkedForDeletion');
    },

    sectionId() {
        return this.get('SECTIONID');
    },
});

export default AccountGroup;
