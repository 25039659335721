import util from '@glu/core/src/util';
import locale from '@glu/locale';
import Model from '@glu/core/src/model';
import { postData } from 'common/util/services';
import services from 'services';
import store from 'system/utilities/cache';
import serverConfigParams from 'system/webseries/models/configurationParameters';

const EXCLUDEHEADER = 'EXCLUDEHEADER';
const SPLIT88RECORD = 'SPLIT88RECORD';

export default Model.extend({

    defaults() {
        return {
            NAME: '',
            FILENAME: '',
            INCREMENTALEXPORT: true,
            TIMEFREQUENCIES: [],
            EXPORTTYPECODE: '',
            CURRENTDAY: true,
        };
    },

    initialize(options) {
        this.options = options;

        this.baseServiceUrl = 'export/scheduledExport/';

        this.validators = {
            NAME: {
                description: locale.get('GIR.Name'),
                exists: true,
                maxLength: 60,
            },
            FILENAME: {
                description: locale.get('GIR.File.Name'),
                exists: true,
                maxLength: 60,
            },
            EXPORTTYPECODE: {
                description: locale.get('GIR.File.Format'),
                exists: true,
            },
            TIMEFREQUENCIES: {
                description: locale.get('GIR.Run.Time'),
                minLength: 1,
                exists: true,
            },
        };
    },

    /**
     * create object with keys and model attributes to send to server
     * @return {object} model attributes
     */
    getSaveData() {
        let data = {
            timeFrequencies: this.get('TIMEFREQUENCIES'),
            name: this.get('NAME'),
            searchFields: this.get('SEARCHFIELDS'),
            userId: this.get('USERID') ? this.get('USERID').toString() : this.get('USERID'),
            userGroup: this.get('USERGROUP'),
            incrementalExport: this.get('INCREMENTALEXPORT'),
            fileName: this.get('FILENAME'),
            exportTypeCode: this.get('EXPORTTYPECODE'),
            excludeHeader: this.get('EXCLUDEHEADER'),
            outputContent: this.get('OUTPUTCONTENT'),
            currentDay: this.get('CURRENTDAY'),
            identifier: this.get('IDENTIFIER'),
            emailRecipients: this.get('EMAILRECIPIENTS')
                ? this.extractRecipientData(this.get('EMAILRECIPIENTS')) : [],
        };
        if (serverConfigParams.get('SeparateTransDetailsMultiColumns') === 'true') {
            data = {
                ...data,
                split88Record: this.get('SPLIT88RECORD') || 'false',
            };
        }
        return data;
    },

    /**
     * Return an array of ids
     * @param {Array} emailRecipients
     * @returns {Array}
     */
    extractRecipientData(emailRecipients) {
        return emailRecipients.map(recipient => ({
            id: recipient.id,
            name: recipient.name,
        }));
    },

    /**
     * Get the id property from the attribute
     * @param {string} attribute - name of the attribute
     * @returns {string}
     */
    getIdFromDropdownValue(attribute) {
        const selection = this.get(attribute);
        if (!Array.isArray(selection)) {
            return '';
        }
        const [first] = selection;
        return first ? first.id : '';
    },

    /**
     * Save model data to server
     * @return {Promise} Returned promise with data from server
     */
    saveModel() {
        return postData(services.generateUrl(`${this.baseServiceUrl}add`), this.getSaveData());
    },

    /**
     * Get model data from server
     * @return {Promise} Returned promise with data from server
     */
    getModel() {
        const data = {
            identifier: store.get('SCHEDULED_EXPORTS:currentModelId'),
        };

        return postData(services.generateUrl(`${this.baseServiceUrl}read`), data)
            .then((response) => {
                this.set(this.formatModelData(response));
                this.formatDropdowns();
                this.extractSearchFields();
                this.updateAmountFilterAttrs();
                return response;
            });
    },

    /**
     * Set the attributes of dropdownFields array on the model in
     * a format that is expected by dropdown ie { name: '', id: ''}
     */
    formatDropdowns() {
        const dropdownFields = [
            'userGroup',
            'userId',
            'reportType',
        ];
        dropdownFields.forEach((field) => {
            const value = this.get(field.toUpperCase());
            if (Array.isArray(value)) {
                return;
            }
            this.set({
                [field]: [{
                    id: value,
                    name: value,
                }],
            });
        });
    },

    /**
     * Parse a list of attributes of the provided object
     * @param {Object} data - object containing attributes
     * @param {Array} attributes - array of attributes to parse
     * @returns {Object} - object containing parsed attributes
     */
    parseAttributes(data, attributes) {
        const parsed = attributes.reduce((accum, attr) => {
            const value = data[attr];
            if (value) {
                return {
                    ...accum,
                    [attr]: JSON.parse(value),
                };
            }
            return accum;
        }, {});
        return {
            ...data,
            ...parsed,
        };
    },

    /**
     * Format data in model to match model selectors used
     * @param  {object} result [description]
     * @return {array} Reformatted model attributes
     */
    formatModelData(result) {
        let data = util.reduce(
            result,
            (accumulator, value, key) => ({
                ...accumulator,
                [key.toUpperCase()]: value,
            }),
            {},
        );

        /*
         * HACK Server won't send boolean values for EXCLUDEHEADER or SPLIT88RECORD
         * because of report generation. Need to convert both to booleans so that
         * data binding works in the view
         */
        const shouldBeBools = [EXCLUDEHEADER, SPLIT88RECORD];
        data = this.parseAttributes(data, shouldBeBools);

        return data;
    },

    /**
     * @param {Array<string>} attributes - array of attributes for search fields
     * as expected by the server
     * @returns {Array}
     */
    createSearchFields(attributes) {
        return attributes.map((attr) => {
            const value = this.get(attr);
            return {
                operator: 'EQ',
                dataType: 'text',
                fieldValue: Array.isArray(value) ? value : [value],
                fieldName: attr,
            };
        });
    },

    /**
     * Set all relevant amount filter fields
     */
    updateAmountFilterAttrs() {
        const fieldArray = this.get('SEARCHFIELDS') || [];
        const amountField = fieldArray.find(field => field.fieldName === 'AMOUNT');
        if (!amountField) {
            return;
        }
        const amount = this.get('AMOUNT');
        let attrs = {
            'AMOUNT-equality': amountField.operator,
            AMOUNT: amount,
        };
        if (Array.isArray(amount) && amount.length > 1) {
            attrs = {
                ...attrs,
                AMOUNT: amount[0],
                AMOUNT2: amount[1],
            };
        }
        this.set(attrs);
    },

    /**
     * Get the value of a search field by the fieldName
     * @param {string} fieldName
     * @returns {Array}
     */
    getSearchFieldValue(fieldName) {
        const fieldArray = this.get('SEARCHFIELDS') || [];
        const field = fieldArray.find(item => item.fieldName === fieldName);
        if (field) {
            return field.fieldValue;
        }
        return [];
    },

    /**
     * Update the fieldValue in the SEARCHFIELDS array by field name
     * @param {String} fieldName - name of field
     * @param {*} value - new value
     */
    updateSearchFieldValue(fieldName, value) {
        const fieldArray = this.get('SEARCHFIELDS') || [];
        const remapped = fieldArray.map((field) => {
            if (field.fieldName === fieldName) {
                return {
                    ...field,
                    fieldValue: value,
                };
            }
            return field;
        });
        this.set('SEARCHFIELDS', remapped);
    },

    /**
     * Convert all of the search field on the model to model attributes using
     * the fieldName as the model attr and the fieldValue as the value. When
     * the fieldValue is an array of only one item, remove the item from
     * the array as the value. When fieldValue is an array with multiple items,
     * do not convert it.
     */
    extractSearchFields() {
        const fieldArray = this.get('SEARCHFIELDS') || [];
        const searchFields = fieldArray.reduce((accum, field) => {
            // The fieldValue should be an array, but in the even that it isn't, convert it
            const arrayValue = Array.isArray(field.fieldValue)
                ? field.fieldValue : [field.fieldValue];
            return {
                ...accum,
                [field.fieldName]: arrayValue.length > 1 ? arrayValue : arrayValue[0],
            };
        }, {});
        this.set(searchFields);
    },
});
