import constants from 'common/dynamicPages/api/constants';

export default [
    {
        label: 'button.approve',
        entitlement: constants.ACTION_APPROVE,
    },
    {
        label: 'button.unapprove',
        entitlement: constants.ACTION_UNAPPROVE,
    },
    {
        label: 'button.reject',
        entitlement: constants.ACTION_REJECT,
    },
    {
        label: 'button.delete',
        entitlement: constants.ACTION_DELETE,
    },
    {
        label: 'button.restore',
        entitlement: constants.ACTION_RESTORE,
    },
];
