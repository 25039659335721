import ItemView from '@glu/core/src/itemView';
import duplicatePaymentTmpl from './duplicatePayment.hbs';

export default ItemView.extend({
    initialize(options) {
        this.model = options.model;
        this.duplicatePaymentData = options.duplicatePaymentData;
    },

    template: duplicatePaymentTmpl,

    templateHelpers() {
        return {
            duplicatePaymentData: this.duplicatePaymentData,
        };
    },
});
