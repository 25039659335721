import util from '@glu/core/src/util';
import locale from '@glu/locale';
import constants from 'app/balanceAndTransaction/constants';
import TransactionListView from 'app/balanceAndTransaction/views/transactionListView';
import { moveToTopCheck } from 'common/util/deeplinkUtil';
import configuration from 'system/configuration';
import store from 'system/utilities/cache';
import applicationConfigParams from 'system/webseries/models/applicationConfiguration';

const isClientDeeplink = configuration.isPortal() && configuration.isClient();

const CreditCardListView = TransactionListView.extend({
    accountType: 'CREDIT_CARD',
    hideBackHeader: false,

    initialize(options) {
        this.prevDayTodayBalancesEnabled = applicationConfigParams.getValue('LNRPT', 'DISPLAYBALANCESPREVDAY') === '1';
        TransactionListView.prototype.initialize.call(this, options);
    },

    getContext() {
        return {
            functionCode: constants.INST,
            menuCatergory: 'reporting',
            menuDescription: 'Balance and Transaction Reporting',
            name: locale.get('balanceTrans.creditCardAccountsTransaction'),
            productCode: constants.GIR,
            requestMappings: 'balanceAndTransactionReporting',
            serviceName: this.getServiceName(),
            typeCode: this.getTypeCode(),
            exportPrintURL: this.getExportPrintURL(),
            inquiryId: 22210,
        };
    },
    /**
     * provides a part of The URL to hit for Credit Cards Account Summary Transactions
     * @method getServiceName - extend
     * @return {String} A part of the URL to hit for Credit Cards Account Summary
     * Transactions
     */
    getServiceName() {
        return `${constants.CREDIT_CARD_ACCTS_SERVICE_PREFIX}getTransactions`;
    },

    /**
     * provides the URL to hit for print/export on Credit Cards Account Summary Transactions
     * @method getExportPrintURL - extend
     * @return {String} the URL to hit for print/export on Credit Cards Account Summary
     * Transactions
     */
    getExportPrintURL() {
        return configuration.isAdmin() ? constants.CREDIT_CARD_TRANS_EXPORT_PRINT_URL
            : constants.ASYNC_CREDIT_CARD_TRANS_EXPORT_PRINT_URL;
    },

    getTypeCode() {
        return constants.GIRTRANS;
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            TransactionListView.prototype.onRender.call(this);
            if (isClientDeeplink && !store.has('btr:initialTab')) {
                this.ui.$cancelBtn.hide();
            }
        } else {
            const isFromContainer = store.get('btr:fromInitialContainer');
            moveToTopCheck(this.model);
            store.unset('helpPage');
            if (isClientDeeplink && !store.has('btr:initialTab') && !isFromContainer) {
                this.hideBackHeader = true;
            }
            this.loadViewRequirements();
        }
    },

    onClose() {
        store.unset('btr:fromInitialContainer');
    },

    templateHelpers() {
        const obj = TransactionListView.prototype.templateHelpers.call(this, undefined);
        return util.extend(
            obj,
            {
                hideBackHeader: () => this.hideBackHeader,
            },
        );
    },
});

export default CreditCardListView;
