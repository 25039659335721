import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import {
  Button, TERTIARY, SECONDARY, EXTRA_SMALL
} from '@glu/buttons-react';
import locale from '@glu/locale';
import PinColumn from './PinColumn';
import Filter from './Filter';
import styles from './Menu.styles';

const Menu = ({
  DatePickerProps,
  className,
  classes,
  closeMenu,
  column,
  columnApi,
  context,
  createFilterValue,
  dark,
  dataQa,
  displayFormat,
  displayName,
  enumData,
  fieldId,
  filterHint,
  filterId,
  filters,
  fixedList,
  handleChange,
  hasFilter,
  headerName,
  htmlId,
  htmlInputProps,
  key,
  label,
  numberFilterTypes,
  onChange,
  options,
  parseFilterValue,
  step,
  value,
  wrapperClassName
}) => {
  const [clearSelection, setClearSelection] = useState(false);
  const [pinned, setPinned] = useState();

  let selectedFilterValue = null;
  const getFilterData = (filterData) => { selectedFilterValue = filterData; };

  const apply = () => {
    setClearSelection(false);
    if (selectedFilterValue?.name === 'filters') {
      context.onChange(selectedFilterValue);
    }
    columnApi.setColumnPinned(column.colId, pinned);
    closeMenu();
  };

  const clear = () => {
    selectedFilterValue = null;
    setClearSelection(true);
  };

  return (
    <div className={classes.root}>
      <div
        data-qa={`menuWrap-${dataQa || column.colId}`}
        className={classes.card}
      >
        <PinColumn
          classes={classes}
          column={column}
          pinnedClear={clearSelection}
          onChange={setPinned}
        />

        <Filter
          className={className}
          createFilterValue={createFilterValue}
          dark={dark}
          displayFormat={displayFormat}
          enumData={enumData}
          fieldId={fieldId}
          filterHint={filterHint}
          filterId={filterId}
          fixedList={fixedList}
          handleChange={handleChange}
          hasFilter={hasFilter}
          headerName={headerName}
          htmlId={htmlId}
          htmlInputProps={htmlInputProps}
          key={key}
          label={label}
          numberFilterTypes={numberFilterTypes}
          onChange={onChange}
          options={options}
          parseFilterValue={parseFilterValue}
          step={step}
          value={value}
          wrapperClassName={wrapperClassName}
          clearSelection={clearSelection}
          displayName={displayName}
          setClearSelection={setClearSelection}
          getFilterData={(filterData) => { getFilterData(filterData); }}
          datePickerProps={{
            forcePositionY: true,
            ...DatePickerProps
          }}
          data={{
            ...context.data,
            filters
          }}
          records={context.records}
          filterables={context.data.columns.filter((col) => col.filterable !== false)}
          columnApi={columnApi}
          column={column}
        />

        <div className={classes.menuFooter}>
          <Button
            onClick={apply}
            className={classes.btnApply}
            text={locale.get('gridReact.headerCell.apply')}
            variant={SECONDARY}
            size={EXTRA_SMALL}
          />
          <Button
            onClick={clear}
            className={classes.btnClear}
            text={locale.get('gridReact.headerCell.clear')}
            variant={TERTIARY}
            size={EXTRA_SMALL}
          />
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  DatePickerProps: PropTypes.shape({}),
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeMenu: PropTypes.func.isRequired,
  column: PropTypes.shape({
    addEventListener: PropTypes.func,
    colId: PropTypes.string.isRequired,
    isSortAscending: PropTypes.func.isRequired,
    isSortDescending: PropTypes.func.isRequired,
    pinned: PropTypes.oneOf(['left', 'right']),
    removeEventListener: PropTypes.func,
    userProvidedColDef: PropTypes.shape({}).isRequired
  }).isRequired,
  columnApi: PropTypes.shape().isRequired,
  context: PropTypes.objectOf(PropTypes.any).isRequired,
  createFilterValue: PropTypes.func,
  dark: PropTypes.bool,
  dataQa: PropTypes.string,
  displayFormat: PropTypes.string,
  displayName: PropTypes.string,
  enumData: PropTypes.shape({}),
  fieldId: PropTypes.string,
  filterHint: PropTypes.string,
  filterId: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fixedList: PropTypes.bool,
  handleChange: PropTypes.func,
  hasFilter: PropTypes.bool,
  headerName: PropTypes.string,
  htmlId: PropTypes.string,
  htmlInputProps: PropTypes.shape({}),
  key: PropTypes.string,
  label: PropTypes.string,
  numberFilterTypes: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })
  ),
  onChange: PropTypes.func,
  options: PropTypes.shape({}),
  parseFilterValue: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.shape({}),
  wrapperClassName: PropTypes.string
};

Menu.defaultProps = {
  DatePickerProps: {},
  className: undefined,
  createFilterValue: undefined,
  dark: undefined,
  dataQa: undefined,
  displayFormat: undefined,
  displayName: '',
  enumData: undefined,
  fieldId: undefined,
  filterHint: undefined,
  filterId: undefined,
  fixedList: undefined,
  handleChange: undefined,
  hasFilter: undefined,
  headerName: undefined,
  htmlId: undefined,
  htmlInputProps: undefined,
  key: undefined,
  label: undefined,
  numberFilterTypes: undefined,
  onChange: undefined,
  options: undefined,
  parseFilterValue: undefined,
  step: undefined,
  value: undefined,
  wrapperClassName: undefined
};

export const MenuComponent = Menu;

export default withStyles(styles)(Menu);
