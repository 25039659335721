import CollectionView from '@glu/core/src/collectionView';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import ProductFeaturesSubGroup from 'app/administration/views/notificationManagement/productFeatureSubGroup';
import productFeatureTmpl from 'app/administration/views/notificationManagement/productFeature.hbs';
import ItemView from '@glu/core/src/itemView';
import productFeatureGroupsTmpl from 'app/administration/views/notificationManagement/productFeatureGroups.hbs';

const features = ItemView.extend({
    tagName: 'li',
    template: productFeatureTmpl,

    events: {
        'click @ui.$checkbox': 'selected',
    },

    ui: {
        $checkbox: 'input.product-feature',
    },

    selected(e) {
        if (this.$(e.currentTarget).prop('checked')) {
            this.model.collection.selectedCount += 1;
            this.model.set('isSelected', true);
        } else {
            if (this.model.collection.selectedCount > 0) {
                this.model.collection.selectedCount -= 1;
            }
            this.model.set('isSelected', false);
        }
    },
});

export default ItemView.extend({
    template: productFeatureGroupsTmpl,

    ui: {
        $title: '.product-feature-title',
        $selectAll: '.select-all-parent-item',
        $counter: '[data-hook="counter"]',
        $features: '.product-feature-content',
    },

    events: {
        'click @ui.$title': 'toggleFeatureGroup',
        'click @ui.$selectAll': 'selectAll',
    },

    className: 'row featureGroup',

    initialize() {
        this.$el.addClass(this.model.get('groupId'));
        this.listenTo(this.appBus, 'allFeatures', this.handleToggleAllFeatures);
    },

    onRender() {
        this.setFeatureCollection();
        this.renderCollectionView();
        this.handleSelectAllFeatures();
    },

    handleToggleAllFeatures(value) {
        this.ui.$selectAll.prop('checked', value);
        this.updateIsSelectedAttr(value);
    },

    handleSelectAllFeatures() {
        this.ui.$selectAll.prop('checked', this.options.selectAllFeatures);
        if (this.options.selectAllFeatures) {
            this.updateIsSelectedAttr(this.options.selectAllFeatures);
        }
    },

    setFeatureCollection() {
        this.featureCollection = new Collection(this.model.get('branches'));
        this.featureCollection.selectedCount = this.model.get('selectedCount');
        this.updateCounter();
        this.listenTo(this.featureCollection, 'change:isSelected', this.handleSingleFeatureChange);
    },

    handleSingleFeatureChange(model, value) {
        if (!value) {
            this.model.set('allFeaturesSelected', value);
            this.ui.$selectAll.prop('checked', value);
        }
        this.updateCounter();
        if (this.allFeaturesSelected()) {
            this.ui.$selectAll.prop('checked', true);
        }
    },

    renderCollectionView() {
        let options;
        if (this.model.get('hasAtleastOneSubGroup')) {
            options = {
                el: this.$('.product-subgroup-container'),
                tagName: 'ul',
                collection: this.featureCollection,
                itemView: ProductFeaturesSubGroup,

                itemViewOptions: {
                    mode: this.options.mode,
                    selectAllFeatures: this.options.selectAllFeatures,
                },
            };
        } else {
            options = {
                el: this.$('.product-feature-container'),
                tagName: 'ul',
                collection: this.featureCollection,
                itemView: features,

                itemViewOptions: {
                    mode: this.options.mode,
                },
            };
        }
        this.collectionView = new CollectionView(options);
        this.collectionView.render();
    },

    updateCounter(shouldGetCollectionCount) {
        if (this.options.mode !== 'INSERT' && shouldGetCollectionCount) {
            this.featureCollection.selectedCount = this.featureCollection.filter(model => model.get('isSelected')).length;
        }
        this.ui.$counter.html(this.featureCollection.selectedCount);
    },

    selectAll(e) {
        this.updateIsSelectedAttr(e.currentTarget.checked);
        this.model.set('allFeaturesSelected', e.currentTarget.checked);
        this.appBus.trigger(`allSubFeatures-${this.model.id}`, e.currentTarget.checked);
    },

    /*
     * Update this to check the set count of the FC vs the length of all childView FC if
     * this group has sub group items
     */
    allFeaturesSelected() {
        return this.featureCollection.selectedCount === this.featureCollection.length;
    },

    fetchSelections() {
        let models = [];
        if (this.allFeaturesSelected()) {
            if (this.model.get('hasAtleastOneSubGroup')) {
                this.collectionView.children.each((view) => {
                    models.push(view.featureCollection.models);
                });
            } else {
                ({ models } = this.featureCollection);
            }
            return models;
        }
        if (this.model.get('hasAtleastOneSubGroup')) {
            this.collectionView.children.each((view) => {
                models.push(view.featureCollection.where({
                    isSelected: true,
                }));
            });
            return util.flatten(models);
        }
        return this.featureCollection.where({
            isSelected: true,
        });
    },

    /**
     * When all items are selected update the property on all models and the
     * count on the collection
     * @param value
     */
    updateIsSelectedAttr(value) {
        if (this.model.get('hasAtleastOneSubGroup')) {
            this.collectionView.children.each((view) => {
                view.featureCollection.each((model) => {
                    model.set({
                        isSelected: value,
                    }, {
                        silent: true,
                    });
                });
            });
        } else {
            this.featureCollection.each((model) => {
                model.set({
                    isSelected: value,
                }, {
                    silent: true,
                });
            });
        }

        if (value) {
            this.featureCollection.selectedCount = 0;
            if (this.model.get('hasAtleastOneSubGroup')) {
                this.collectionView.children.each(function (view) {
                    this.featureCollection.selectedCount += view.featureCollection.length;
                }, this);
            } else {
                this.featureCollection.selectedCount = this.featureCollection.length;
            }

            this.collectionView.$el.find('input').prop('checked', true);
        } else {
            this.featureCollection.selectedCount = 0;
            this.collectionView.$el.find('input').prop('checked', false);
        }
        this.updateCounter();
    },

    toggleFeatureGroup(e) {
        this.$(e.currentTarget).toggleClass('active');
        this.ui.$features.slideToggle();
    },

    templateHelpers() {
        return {
            readOnly: this.options.mode === 'SELECT',
            allFeaturesSelected: this.options.selectAllFeatures,
            hasSubGroup: this.model.get('hasAtleastOneSubGroup'),
        };
    },
});
