export default ({
  event: {
    code, key, shiftKey, target
  }
}) => {
  if (code === 'Tab' || key === 'Tab') {
    // get focusable children of parent cell
    const parent = target.closest('.ag-cell') || target.closest('.ag-header-cell');
    const focusableChildren = parent.querySelectorAll(
      'button, [href], :not(.ag-hidden) > input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    if (
      !focusableChildren.length
        || (!shiftKey && target === focusableChildren[focusableChildren.length - 1])
        || (shiftKey && target === focusableChildren[0])
        || (shiftKey && target.classList.contains('ag-cell'))
    ) {
      return false; // do not suppress
    }
    return true; // suppress
  }
  return false; // do not suppress by default
};
