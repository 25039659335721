import $ from 'jquery';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import store from 'system/utilities/cache';
import systemConfig from 'system/configuration';
import entitlements from 'common/dynamicPages/api/entitlements';
import LvcConstants from 'common/util/listViewConfig/listViewConfigConstants';
import PaymentsListView from 'app/payments/views/listPayments';
import mobileUtil from 'mobile/util/mobileUtil';
import MaskToggleUtil from 'common/util/maskToggleUtil';
import constants from './constants';
import template from './listViewWidget.hbs';

export default PaymentsListView.extend(util.extend(
    {},
    {
        template,
        className: 'listview-widget',

        ui: util.extend(
            {},
            PaymentsListView.prototype.ui,
            {
                $listViewWidgetButtons: '[data-hook="getListViewWidgetButtons"] button',
            },
        ),

        initialize(options = {}) {
            // this is here to avoid a circular reference
            this.gridImportPromise = import('common/dynamicPages/api/grid').then(({ default: gridApi }) => {
                this.gridApi = gridApi;
                this.loadViewRequirements();
            });

            /*
             * pull necessary pieces from BaseWidget that can be / need to be leveraged
             * here as well
             */

            /*
             * put the options on the class earlier than usual so that the className
             * function will have access to options if needed
             */
            this.options = options;

            this.widgetID = options.widgetID;
            // Access to the passed model for the fieldName
            this.parentModel = options.model;
            /*
             * the base widget will have the same model as the parent, if other widgets
             * need their own model, then they will create it.
             */
            this.model = options.model;
            this.fieldName = options.fieldName;
            this.state = options.state.toUpperCase();
            this.context = this.verifyContext(options.context);
            this.fieldInfo = options.fieldInfo;
            this.fieldData = options.fieldData;
            this.entitlements = {};

            const widgetInitOptions = options.widgetInitOptions || {};

            /*
             * Create a default readyState
             * example for reject: Promise.reject('Promise rejected: An Error has occurred');
             */
            this.readyState = Promise.resolve(true);

            const pmxOptions = {
                suppressServicesUrlSuffix: true,
                isFileLevelWorkflow: true,
                menuContext: widgetInitOptions.menuContext || 'payment/listView/corp',
                returnRoute: systemConfig.isAdmin()
                    ? widgetInitOptions.adminReturnRoute : widgetInitOptions.returnRoute,
                serviceName: systemConfig.isAdmin()
                    ? widgetInitOptions.adminServiceName : widgetInitOptions.serviceName,
                additionalSearchFields: this.processSearchFields(widgetInitOptions),
                startRow: 0,
                dataOnly: 0,
                configContext: this.verifyContext(options.context),
            };

            PaymentsListView.prototype.initialize
                .call(this, util.extend({}, options, pmxOptions));
        },

        /**
         * @name displayAccountMaskToggle
         * @description - override method from listPayment
         * we don't want to do anything here b/c we will display the toggle when the grid has loaded
         * we need the grid data to determine if the toggle should show so we have to wait.
         */
        displayAccountMaskToggle() {
        },

        /**
         * @name
         * @returns boolean value
         * true if criteria is met to display the account masking toggle; otherwise, false
         * the toggle is only shown if the mode is VIEW and for non-confidential FILE type payments
         */
        toggleMaskCriteriaMet() {
            const confidential = this.gridView.getRows()?.models?.[0]?.get('CONFIDENTIALFLAG');
            return this.state === 'VIEW' && this.model.get('TYPE') === 'FILE' && confidential === '0';
        },

        /**
         * @name renderAccountMaskToggle
         * @description checks to see if the account masking toggle should be displayed
         * if yes, then it will call the MaskToggleUtil.showMaskToggle passing in the
         * (override) name of the region to display the toggle
         */
        renderAccountMaskToggle() {
            if (this.shouldShowMaskToggle && this.toggleMaskCriteriaMet()) {
                MaskToggleUtil.showMaskToggle(this, {
                    name: 'maskToggleBene',
                    htmlId: 'maskToggleBene',
                    labelText: locale.get('common.maskAcctNum'),
                    onChange: this.toggleMaskColumns.bind(this),
                    region: this.toggleSwitchRegionForWidget,
                });
            }
        },

        onRender() {
            if (this.hasLoadedRequiredData()) {
                // account for any transfer messages that need to be shown
                const listTransferMessage = store.get('listTransferMessage');
                if (listTransferMessage) {
                    this.renderMessage(
                        listTransferMessage.action,
                        listTransferMessage.response,
                    );
                    store.set('listTransferMessage', null);
                }
            }
            PaymentsListView.prototype.onRender.call(this);
        },

        /**
         * Verify context passed to LISTVIEWWIDGET to be sure it includes necessary props
         * @param {Object} context
         */
        verifyContext(context = {}) {
            return util.extend(
                {},
                context,
                {
                    productCode: this.parentModel.get('PRODUCT'),
                    functionCode: this.parentModel.get('FUNCTION'),

                    // make sure that each listviewwidget gets unique storage
                    typeCode: this.parentModel.get('id'),
                },
            );
        },

        /**
         * Entitlements check, if needed.
         * @param {Object} options
         * @return {Promise}
         */
        loadEntitlementRequirements(options) {
            if (systemConfig.isAdmin() || this.options.parentView.options.importView) {
                /*
                 * When in the Bank Admin or during file import view at the client,
                 * we don't need to check.
                 */
                return Promise.resolve();
            }

            const entitlementOptions = util.extend(
                {},
                options,
                {
                    entryMethod: 3,

                    overrideContext: {
                        serviceName: this.options.widgetInitOptions.entitlementsServiceName || 'payment/listView/corp',
                    },
                },
            );
            return entitlements.getEntitlements(entitlementOptions).then((results) => {
                this.entitlements.APPROVE = results.actions.APPROVE;
                this.entitlements.UNAPPROVE = results.actions.UNAPPROVE;
                this.entitlements.REJECT = results.actions.REJECT;
                this.entitlements.DELETE = results.actions.DELETE;
            });
        },

        loadViewRequirements() {
            const options = this.viewOptionRequirements();

            /*
             * When in the Bank Admin or during file import view at the client, the check
             * boxes are not needed
             */
            if (systemConfig.isAdmin() || this.options.parentView.options.importView) {
                options.selector = 'none';
            }
            // have to use full selection, can't get to right space off of this.$el
            if ($('body').hasClass('modal-open')) {
                options.gridOptions = util.extend(
                    {},
                    options.gridOptions,
                    {
                        stickyFooter: false,
                    },
                );
            }

            if (!this.gridApi) {
                return;
            }
            const gridView = this.gridApi.createServiceGridView(options);
            this.gridView = gridView;
            if (options.additionalSearchFields) {
                this.lvc.set(LvcConstants.ADDITIONALSEARCH, options.additionalSearchFields);
            }
            this.startGridRespAlertListeners(this.gridView.cid);
            this.setupGridAvailableListener();

            this.gridImportPromise.then(() => {
                this.gridView = this.gridApi.createServiceGridView(options);
                if (options.additionalSearchFields) {
                    this.lvc.set(LvcConstants.ADDITIONALSEARCH, options.additionalSearchFields);
                }
                this.startGridRespAlertListeners(this.gridView.cid);
                this.setupGridAvailableListener();
            }).then(() => this.loadEntitlementRequirements(options))
                .then(() => {
                    this.setHasLoadedRequiredData(true);
                    if (!mobileUtil.isMobileGridEnabled()) {
                        this.render();
                    }
                    this.listenTo(this.gridView, 'gridapi:loaded', this.updateMaxDaysMsg);
                    /* in view mode, set up the listener for when the grid's content has rendered
                    */
                    if (this.state === 'VIEW') {
                        this.listenTo(this.gridView, 'grid:available', () => {
                            this.listenTo(this.gridView.grid, 'grid:contentRendered', this.renderAccountMaskToggle);
                        });
                    }
                    this.listenForGridErrors('listview.savesystemviewinvalid');
                }).catch((error) => {
                    if (this.alertRegion) {
                        this.alertRegion.show(alert.negative(
                            locale.get('gir.stmt.grid.entitle.error'),
                            {
                                canDismiss: true,
                            },
                        ));
                    }
                    // log full error
                    window.console.log('error!', error);
                });
        },

        gridRowSelect(optionsParam) {
            const options = optionsParam;
            const serviceName = options.model.context.serviceName.toLowerCase();
            const mainRoute = systemConfig.isAdmin()
                ? this.options.widgetInitOptions.adminMainRoute
                : this.options.widgetInitOptions.mainRoute;
            store.set(`${this.contextKey}-listRoute`, mainRoute);
            // MDF uses workspace helper to get return route from this nvp.
            store.set('current-workspace-route', mainRoute);

            if (serviceName.indexOf('loandraw') > -1 || serviceName.indexOf('loanpay') > -1) {
                store.set('custom_view_payment_model', options.model);
                this.navigateTo('PAYMENTS/viewLoanPayment');
            } else if (options.model.get('TYPE') === 'PASSTHRU' || options.model.get('TYPE') === 'BEFTPASS') {
                store.set('passthru_view_payment_model', options.model);
                store.set('SURROGATETNUM', options.model.get('TNUM'));
                this.navigateTo('PAYMENTS/passthroughDetail');
            } else if (serviceName.indexOf('transfer') > -1) {
                options.model.contextKey = this.contextKey;
                options.model.isWidget = true;
                store.set('viewTransferModel', options.model);
                this.navigateTo('PAYMENTS/viewTransfer');
            } else {
                store.set(`${this.contextKey}-actionModel`, options.model);
                this.navigateTo(systemConfig.isAdmin()
                    ? this.options.widgetInitOptions.adminGridRowSelectRoute
                    : this.options.widgetInitOptions.gridRowSelectRoute);
            }
            return Promise.resolve();
        },

        gridRowModify(options) {
            store.set(`${this.contextKey}-listRoute`, this.options.widgetInitOptions.mainRoute);
            store.set('current-workspace-route', this.options.widgetInitOptions.mainRoute);
            if (options.model.get('TYPE') === 'TRANSFER') {
                store.set('modifyTransferModel', options.model);
                this.navigateTo('PAYMENTS/modifyTransfer');
            } else {
                store.set(`${this.contextKey}-actionModel`, options.model);
                this.navigateTo(this.options.widgetInitOptions.gridRowModifyRoute);
            }
            return Promise.resolve();
        },

        gridRowRepair(options) {
            store.set(`${this.contextKey}-listRoute`, this.options.widgetInitOptions.mainRoute);
            store.set('current-workspace-route', this.options.widgetInitOptions.mainRoute);
            if (options.model.get('TYPE') === 'TRANSFER') {
                store.set('repairTransferModel', options.model);
                this.navigateTo('PAYMENTS/repairTransfer');
            } else {
                store.set(`${this.contextKey}-actionModel`, options.model);
                this.navigateTo(this.options.widgetInitOptions.gridRowRepairRoute);
            }
            return Promise.resolve();
        },

        processSearchFields(options) {
            const additionalSearchFields = [];
            let searchCriteria;

            util.each(options.searchFields, (searchField) => {
                if (searchField.fieldValue && searchField.fieldValue.length) {
                    searchCriteria = {
                        dataType: searchField.dataType,
                        operator: searchField.operator,
                        fieldValue: [],
                        fieldName: searchField.fieldName,
                    };
                }
                if (searchCriteria.fieldValue) {
                    util.each(searchField.fieldValue, (field) => {
                        searchCriteria.fieldValue.push(this.parentModel.get(field));
                    });
                }
                additionalSearchFields.push(searchCriteria);
            });
            return additionalSearchFields;
        },

        runProcessMultiActionsAsOne() {
            return true;
        },

        toggleListViewWidgetButtons(isDisabled = false) {
            this.ui.$listViewWidgetButtons.attr('disabled', isDisabled);
        },

        handleMultiItemsSuccess(methodName, selectedArray, result) {
            this.toggleListViewWidgetButtons();
            PaymentsListView.prototype.handleMultiItemsSuccess
                .call(this, methodName, selectedArray, result);
        },

        handleMultiItemsError(methodName, result) {
            this.toggleListViewWidgetButtons();
            PaymentsListView.prototype.handleMultiItemsError.call(this, methodName, result);
        },

        processMultiActionsAsOne(methodName, selectedArray, additionalParams) {
            if (selectedArray.length <= 0) {
                this.toggleListViewWidgetButtons();
                return;
            }
            this.toggleListViewWidgetButtons(true);

            PaymentsListView.prototype.processMultiActionsAsOne
                .call(this, methodName, selectedArray, additionalParams);
        },

        /**
         * @method showAlertMessage
         * @param {string} message
         * @param {string} type - glu alert types associated to an alert creation method
         * (success, warning, danger, etc)
         * @param {object} props - object containing extra properties for glu alert
         * construction
         * Shows an alert message in the ListView. The status passed in is associated
         * to the type of
         * alert that is shown.
         */
        showAlertMessage(message, type, props) {
            const typeParam = alert[type] ? type : 'success';
            const propsParam = props || {
                canDismiss: true,
            };

            // make sure listviewwidget alerts are collapsed by default
            propsParam.collapsed = true;

            // create alert message using associated alert status type
            this.alertView = alert[typeParam](message, propsParam);

            this.appBus.trigger(
                'listview:alert:show',
                {
                    type: typeParam,
                    parentModel: this.parentModel,
                },
            );

            this.alertRegion.show(this.alertView);
        },

        templateHelpers() {
            return {
                getString: type => locale.get(this.localeKey + type),
                getButtonString: type => locale.get(`${this.localeKey.split('.')[0]}.button_${type}`),
                hasApproveEntitlement: () => this.hasEntitlement(constants.ACTION_APPROVE),
                hasUnapproveEntitlement: () => this.hasEntitlement(constants.ACTION_UNAPPROVE),
                hasRejectEntitlement: () => this.hasEntitlement(constants.ACTION_REJECT),
                hasDeleteEntitlement: () => this.hasEntitlement(constants.ACTION_DELETE),

                // return true if the user is entitled to perform bulk actions on grid rows
                hasBulkActionEntitlements: () => this.hasEntitlement(constants.ACTION_APPROVE)
                    || this.hasEntitlement(constants.ACTION_UNAPPROVE)
                    || this.hasEntitlement(constants.ACTION_REJECT)
                    || this.hasEntitlement(constants.ACTION_DELETE),
            };
        },

        /**
         * @method isReadyToRender
         * indicates whether or not the listView is ready to be displayed
         * @returns {Promise/boolean}
         */
        isReadyToRender() {
            if (this.hasLoadedRequiredData()) {
                return Promise.resolve();
            }
            return false;
        },
    },
));
