import PropTypes from 'prop-types';
import React from 'react';
import { Region } from 'backbone.marionette';

class ViewWrapper extends React.PureComponent {
  componentDidMount() {
    this.viewRegion = new Region({ el: this.wrapper });
    this.viewRegion.show(this.props.view);

    this.props.onMount(this.props.view);
  }

  componentDidUpdate(prevProps) {
    if (this.props.view.cid !== prevProps.view.cid) {
      this.props.onBeforeUnmount(prevProps.view);

      this.viewRegion.show(this.props.view);

      this.props.onMount(this.props.view);
    }
  }

  componentWillUnmount() {
    if (this.props.onBeforeUnmount) {
      this.props.onBeforeUnmount(this.props.view);
    }

    this.viewRegion.reset();
    this.viewRegion.close();
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={(wrapper) => {
          this.wrapper = wrapper;
        }}
      />
    );
  }
}

ViewWrapper.propTypes = {
  view: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  onMount: PropTypes.func,
  onBeforeUnmount: PropTypes.func
};
ViewWrapper.defaultProps = {
  className: '',
  onMount: () => {},
  onBeforeUnmount: () => {}
};

export const wrapIfView = viewOrComponent => (viewOrComponent && (
  viewOrComponent.cid ? <ViewWrapper view={viewOrComponent} /> : viewOrComponent
));

export default ViewWrapper;
