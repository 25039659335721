import util from '@glu/core/src/util';
import store from 'system/utilities/cache';
import PrintExportUtil from 'common/util/printExportUtil';
import ListView from 'common/dynamicPages/views/workflow/list';
import template from './configListView.hbs';

const ConfigListView = ListView.extend({
    template,

    events: {
        'click [data-hook="export-button"]': 'export',
        'click [data-hook="print-button"]': 'print',
        'click [data-hook="refresh-button"]': 'refreshData',
    },

    initialize(optionsParam) {
        const options = optionsParam;
        this.selectedTab = options.selectedTab;
        const superOptions = {
            menuCategory: 'SMAINT',
            serviceName: 'appConfig/listView',
            hideGridActionButtons: true,
            selector: 'none',
            returnRoute: 'SMAINT/appConfig',
            context: 'APPCONF_MAINT',
            configContext: {
                productCode: 'SMAINT',
                typeCode: 'APPCONF',
                functionCode: 'MAINT',
                actionMode: 'MODIFY',
                subType: options.selectedTab,
            },
        };

        options.customFormatters = [{
            fieldName: 'SHORTDESCRIPTION',
            format: value => util.unescape(value),
        }];

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    gridRowAction(optionsParam) {
        const options = optionsParam;
        if (this.alertRegion) {
            this.alertRegion.close();
        }

        options.model.context.actionData = {
            entryClass: `${options.model.get('CATEGORYKEY')}~${options.model.get('KEY')}`,
        };
        options.model.set('SELECTEDTAB', this.selectedTab);
        options.model.set('ACTION', options.action);
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo('SMAINT/appConfigEntryView');
        return Promise.resolve();
    },

    print() {
        this.gridView.context.actionMode = 'SELECT';
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: 29076,
            format: 'PDF',
        };

        PrintExportUtil.print(options);
    },

    export() {
        this.gridView.context.typeCode = 'PRINT';
        this.gridView.context.actionMode = 'SELECT';
        const options = {
            view: this,
            gridView: this.gridView,
            customFilters: this.customFilters,
            inquiryId: 29076,
            format: 'CSV',
        };

        PrintExportUtil.export(options);
    },
});

export default ConfigListView;
