import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import locale from '@glu/locale';
import DashboardLayout from 'dashboard/js/DashboardLayout';
import WidgetInjector from 'dashboard/js/widget/WidgetInjector';
import SurrogateWidget from 'dashboard/js/widget/surrogate/SurrogateWidget';
import TransferGrid from 'app/smbPayments/views/accounts/grid/transferGrid';
import workspaceTmpl from './workspace.hbs';

export default Layout.extend({
    template: workspaceTmpl,

    initialize() {
        this.widgetLib = [];
        this.dashboard = new DashboardLayout();
        this.injector = new WidgetInjector({
            collection: new Collection(this.widgetLib),
        });
    },

    onRender() {
        const self = this;

        const swidget = new SurrogateWidget({
            model: new Model({
                title: locale.get('smbPayments.transfer.plural'),
                did: 0,
                wid: 21,
                size: 'large',
            }),
        });

        swidget.setView(new TransferGrid());

        self.dashboard.inject(swidget);

        self.header.show(self.injector);
        self.workbench.show(self.dashboard);
    },
});
