var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"section section-container\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"section-body\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":18,"column":23}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hideExportBtn") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":17,"column":27}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"btn-group check-all-buttons\">\n                        <a aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"imageView.export",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":39},"end":{"line":10,"column":68}}}))
    + "\"\n                            class=\"btn btn-tertiary\"\n                            data-hook=\"getExportBtn\"\n                            href=\"#\">\n                            <span class=\"icon-download\"></span>\n                        </a>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"check-header\">\n                <div class=\"check-label-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"headerItems") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":32,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <hr/>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-md-4\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":30,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"row\">\n                                    <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":27,"column":43},"end":{"line":27,"column":52}}}) : helper)))
    + "</label>\n                                    <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":48}}}) : helper)))
    + "</p>\n                                </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"check-sidebar\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"thumbnails") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":47,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNoImages") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":51,"column":23}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div data-action=\"selectImage\" data-hook=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":41,"column":66},"end":{"line":41,"column":72}}}) : helper)))
    + "\">\n                            <div class=\"check-thumbnail\">\n                                <img "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":37},"end":{"line":43,"column":79}}})) != null ? stack1 : "")
    + " src=\"data:"
    + alias4(((helper = (helper = lookupProperty(helpers,"TYPE") || (depth0 != null ? lookupProperty(depth0,"TYPE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TYPE","hash":{},"data":data,"loc":{"start":{"line":43,"column":90},"end":{"line":43,"column":100}}}) : helper)))
    + ";base64,"
    + alias4(((helper = (helper = lookupProperty(helpers,"IMAGE") || (depth0 != null ? lookupProperty(depth0,"IMAGE") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"IMAGE","hash":{},"data":data,"loc":{"start":{"line":43,"column":108},"end":{"line":43,"column":119}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"LABEL") || (depth0 != null ? lookupProperty(depth0,"LABEL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"LABEL","hash":{},"data":data,"loc":{"start":{"line":43,"column":126},"end":{"line":43,"column":137}}}) : helper)))
    + "\">\n                            </div>\n                            <button class=\"btn-tertiary\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"LBX.ImageNumber",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":45,"column":57},"end":{"line":45,"column":85}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"ID") || (depth0 != null ? lookupProperty(depth0,"ID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ID","hash":{},"data":data,"loc":{"start":{"line":45,"column":86},"end":{"line":45,"column":92}}}) : helper)))
    + "</button>\n                        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "class=\"selectedImage\"";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"imageView.noImageFound",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":50,"column":59}}}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"check-container\">\n                    <div class=\"check-front\"></div>\n                    <div class=\"check-back\"></div>\n                    <div class=\"check-actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invertible") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":66,"column":31}}})) != null ? stack1 : "")
    + "                        <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.rotateClockwise",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":44},"end":{"line":67,"column":82}}}))
    + "\" class=\"btn btn-tertiary rotate\">\n                            <span class=\"icon-circle-arrow\"\n                                data-placement=\"top\"\n                                data-toggle=\"tooltip\"\n                                title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.rotateClockwise",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":71,"column":39},"end":{"line":71,"column":77}}}))
    + "\">\n                            </span>\n                        </button>\n                        <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.zoomIn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":74,"column":44},"end":{"line":74,"column":73}}}))
    + "\" class=\"btn btn-tertiary zoom-in\">\n                            <span class=\"icon-magnify-plus\"\n                                data-placement=\"top\"\n                                data-toggle=\"tooltip\"\n                                title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.zoomIn",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":39},"end":{"line":78,"column":68}}}))
    + "\">\n                            </span>\n                        </button>\n                        <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.zoomOut",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":81,"column":44},"end":{"line":81,"column":74}}}))
    + "\" class=\"btn btn-tertiary zoom-out\">\n                            <span class=\"icon-magnify-minus\"\n                                data-placement=\"top\"\n                                data-toggle=\"tooltip\"\n                                title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.zoomOut",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":85,"column":39},"end":{"line":85,"column":69}}}))
    + "\">\n                            </span>\n                        </button>\n                        <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":88,"column":44},"end":{"line":88,"column":71}}}))
    + "\" class=\"btn btn-tertiary save\">\n                            <span class=\"icon-save\"\n                                data-placement=\"top\"\n                                data-toggle=\"tooltip\"\n                                title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.save",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":92,"column":39},"end":{"line":92,"column":66}}}))
    + "\">\n                            </span>\n                        </button>\n                        <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":95,"column":44},"end":{"line":95,"column":72}}}))
    + "\" class=\"btn btn-tertiary print\">\n                            <span class=\"icon-print\"\n                                data-placement=\"top\"\n                                data-toggle=\"tooltip\"\n                                title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.print",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":99,"column":39},"end":{"line":99,"column":67}}}))
    + "\">\n                            </span>\n                        </button>\n                        <a id=\"save-image-link_"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":102,"column":47},"end":{"line":102,"column":54}}}) : helper)))
    + "\" class=\"hide\"></a>\n                    </div>\n                </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.invertColor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":59,"column":48},"end":{"line":59,"column":82}}}))
    + "\" class=\"btn btn-tertiary invert\">\n                                <span class=\"icon-eye\"\n                                    data-placement=\"top\"\n                                    data-toggle=\"tooltip\"\n                                    title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"imageView.invertColor",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":63,"column":43},"end":{"line":63,"column":77}}}))
    + "\">\n                                </span>\n                            </button>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showThumbnails") : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":16},"end":{"line":112,"column":27}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"getImgRetrievalErrorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":111,"column":27}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"getImgRetrievalErrorMessage") || (depth0 != null ? lookupProperty(depth0,"getImgRetrievalErrorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"getImgRetrievalErrorMessage","hash":{},"data":data,"loc":{"start":{"line":108,"column":24},"end":{"line":108,"column":55}}}) : helper)))
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":12},"end":{"line":120,"column":23}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    return "                <hr/>\n                <div data-region=\"imageListGrid\" class=\"grid-content\"></div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}})) != null ? stack1 : "")
    + "    <div "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":9},"end":{"line":4,"column":59}}})) != null ? stack1 : "")
    + ">\n        <div data-region=\"alertRegion\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleImages") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasHeaderItems") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"check-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showThumbnails") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":52,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSpecificImage") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":113,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleImages") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":8},"end":{"line":121,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":0},"end":{"line":125,"column":11}}})) != null ? stack1 : "");
},"useData":true});