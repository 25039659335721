var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel-title\">\n        <span class=\"account-group-display-name display-mode\" data-hook=\"getAccountGroupDisplay\">\n                <a role=\"button\" data-toggle=\"collapse\" href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"collapseId") || (depth0 != null ? lookupProperty(depth0,"collapseId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"collapseId","hash":{},"data":data,"loc":{"start":{"line":3,"column":63},"end":{"line":3,"column":77}}}) : helper)))
    + "\" aria-expanded=\"true\" aria-controls=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"collapseId") || (depth0 != null ? lookupProperty(depth0,"collapseId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"collapseId","hash":{},"data":data,"loc":{"start":{"line":3,"column":115},"end":{"line":3,"column":129}}}) : helper)))
    + "\">\n                        <span class=\"indicator-icon\"></span>\n                        <span class=\"account-group-name\" data-hook=\"getGroupName\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"NAME") || (depth0 != null ? lookupProperty(depth0,"NAME") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"NAME","hash":{},"data":data,"loc":{"start":{"line":5,"column":82},"end":{"line":5,"column":90}}}) : helper)))
    + "</span>\n                </a>\n        </span>\n        <input class=\"account-group-name-input edit-mode\" data-hook=\"getGroupNameInput\" value=\"\" maxlength=\"30\" name=\"NAME\">\n</div>\n<div class=\"group-controls\">\n        <span class=\"accounts-count\" data-hook=\"getAccountsCount\"></span>\n        "
    + alias4((lookupProperty(helpers,"customizedIcon")||(depth0 && lookupProperty(depth0,"customizedIcon"))||alias2).call(alias1,{"name":"customizedIcon","hash":{"className":"accountgroup-edit-toggle btn btn-tertiary","action":"toggleEditMode","locale":"gir.manageAccountGroups.editGroupName","name":"pencil"},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":167}}}))
    + "\n    "
    + alias4((lookupProperty(helpers,"customizedIcon")||(depth0 && lookupProperty(depth0,"customizedIcon"))||alias2).call(alias1,{"name":"customizedIcon","hash":{"className":"btn btn-tertiary","action":"delete","text":(depth0 != null ? lookupProperty(depth0,"dynamicTitle") : depth0),"name":"close"},"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":100}}}))
    + "\n</div>\n<span class=\"help-block\"  data-bind=\"model\" data-validate=\"NAME\"></span>\n";
},"useData":true});