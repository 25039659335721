import util from '@glu/core/src/util';
import ListView from 'common/dynamicPages/views/workflow/list';
import store from 'system/utilities/cache';
import NotificationListView from 'app/administration/views/notificationManagement/notificationList';

export default NotificationListView.extend({
    initialize(options) {
        const superOptions = {
            menuCategory: 'CMAINT',
            serviceName: '/bannerNotificationTemplate',
            serviceFunc: null,
            businessType: null,
            context: 'CMAINT_CGRPBTMP',
            returnRoute: 'CMAINT/viewNotifications',

            configContext: {
                productCode: 'CMAINT',
                functionCode: 'MAINT',
                typeCode: 'CGRPBTMP',
                actionMode: 'SELECT',
            },
        };
        this.isTemplateWorkflow = true;
        this.inquiryId = '29072';
        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));
    },

    addNotification() {
        store.unset('notification:success');
        store.unset('bannerNotifications:read');
        this.navigateTo('CMAINT/manageNotificationTemplates');
    },

    templateHelpers() {
        return {
            isAddEntitled: this.isEntitled,
            getLocaleString: 'common.addNotificationTemplate',
        };
    },
});
