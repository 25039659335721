import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import http from '@glu/core/src/http';
import services from 'services';
import userInfo from 'etc/userInfo';
import constants from 'common/dynamicPages/api/constants';

export default Model.extend({
    defaults() {
        const defaults = {
            BILL_ID: '',
            BATCHTYPEDESC: '',
            CLIENTCOMP_GROUPNUM: '',
            DUPLICATEREASON: '',
            EFFECTIVEDATE: '',
            USERGROUP: userInfo.get('group'),
            BANKCODE: '',
            ENTRYCLASS: '',
            ENTRYDESC: '',
            ENTRYMETHOD: '0',
            FOREIGNEXCHREFINDICATOR: 1,
            OFFSETBANKCODE: '',
            OFFSETACCOUNTNUM: '',
            ORIGADDRESS: '',
            ORIGBANKCOUNTRY: '',
            ORIGBANKNAME: '',
            ORIGBANKQUALIFIER: '',
            ORIGCITY: '',
            ORIGCOMPID: '',
            ORIGCOMPNAME: '',
            ORIGCOUNTRY: '',
            ORIGCURRENCYCODE: '',
            ORIGPOSTALCODE: '',
            ORIGPROVINCE: '',
            ORIGSTATE: '',
            BATCHCOMMENT: '',
            BATCHSEQNUM: '',
            _saveIncomplete: '',
            duplicateAccepted: '',
        };
        defaults[this.getCustomDefaultKey()] = '';
        return defaults;
    },

    /**
     * @abstract
     * @return {string}
     */
    getCustomDefaultKey() {
        throw new Error('getCustomDefaultKey not implemented');
    },

    setAttributes(method) {
        const { account } = this.options;
        const { attributes } = this.options;

        const defaultAttributes = {
            BILL_ID: attributes.BILL_ID,
            BATCHTYPEDESC: this.getAccountAttribute(account, 'BATCHTYPEDESC'),
            CLIENTCOMP_GROUPNUM: this.getAccountAttribute(account, 'ClientComp_GroupNum'),
            DUPLICATEREASON: attributes.DUPLICATEREASON,
            EFFECTIVEDATE: attributes.VALUE_DATE,
            BANKCODE: this.getAccountAttribute(account, 'BankCode'),
            ENTRYCLASS: this.getAccountAttribute(account, 'EntryClass'),
            ENTRYDESC: this.getAccountAttribute(account, 'ENTRYDESC'),
            OFFSETBANKCODE: this.getAccountAttribute(account, 'OFFSETBANKCODE'),
            OFFSETACCOUNTNUM: this.getAccountAttribute(account, 'OFFSETACCOUNTNUM'),
            ORIGBANKCOUNTRY: this.getAccountAttribute(account, 'ORIGBANKCOUNTRY'),
            ORIGBANKNAME: this.getAccountAttribute(account, 'ORIGBANKNAME'),
            ORIGCOMPID: this.getAccountAttribute(account, 'ORIGCOMPID'),
            ORIGCOMPNAME: this.getAccountAttribute(account, 'ORIGCOMPNAME'),
            ORIGCOUNTRY: this.getAccountAttribute(account, 'ORIGCOUNTRY'),
            ORIGCURRENCYCODE: this.getAccountAttribute(account, 'ORIGCURRENCYCODE'),
            ORIGACHABA: this.getAccountAttribute(account, 'ORIGACHABA'),
            ORIGADDRESS: this.getAccountAttribute(account, 'ORIGADDRESS'),
            ORIGCITY: this.getAccountAttribute(account, 'ORIGCITY'),
            ORIGSTATE: this.getAccountAttribute(account, 'ORIGSTATE'),
            ORIGPOSTALCODE: this.getAccountAttribute(account, 'ORIGPOSTALCODE'),
            ORIGPROVINCE: this.getAccountAttribute(account, 'ORIGPROVINCE'),
            BATCHCOMMENT: attributes.SPECIAL_INSTRUCTIONS,
            BATCHSEQNUM: attributes.BATCHSEQNUM,
            // eslint-disable-next-line
            _saveIncomplete: attributes._saveIncomplete,
            // eslint-disable-next-line
            _saveWithWarning: attributes._saveWithWarning,
            duplicateAccepted: attributes.duplicateAccepted,
            CONTACTHASBEENUPDATED: attributes.CONTACTHASBEENUPDATED,
        };

        defaultAttributes[this.getCustomDefaultKey()] = attributes.CREDIT_AMOUNT;
        this.set(defaultAttributes);
        if (method === 'update') {
            this.set({
                ENTRYMETHOD: attributes.ENTRYMETHOD,
                TNUM: attributes.id,
                // eslint-disable-next-line
                UPDATECOUNT__: attributes.UPDATECOUNT__,
            });
        }
    },

    initialize(attributes, options) {
        let localOptions = options;
        localOptions = localOptions || {};
        this.options = localOptions;
        this.routeBase = localOptions.routeBase;
        this.validationOnly = localOptions.validationOnly;
    },

    sync(method, model, options) {
        if (this.validationOnly) {
            // skipping validation for ACH
            options.success({});
        } else {
            let operation;
            if (method === 'create') {
                operation = constants.URL_ADD_ACTION;
            } else if (method === 'update') {
                operation = constants.URL_UPDATE_ACTION;
            }
            const route = services.generateUrl(this.routeBase + operation);
            http.post(route, this.convertModelAttributesToServerJSON(method), (result) => {
                options.success(result);
            }, (result) => {
                options.error({
                    errorCode: result.status,
                    errorMessage: result.statusText,
                    message: result.responseText,
                });
            });
        }
    },

    convertModelAttributesToServerJSON(method) {
        const jsonData = [];
        this.setAttributes(method);
        util.each(this.attributes, (value, key) => {
            jsonData.push({
                name: key,
                value,
            });
        });
        const jsonKey = {
            item: {
                item: jsonData,
            },
        };

        return jsonKey;
    },

    getAccountAttribute(account, attr) {
        const field = util.findWhere(
            account.get('mapDataList'),
            {
                toField: attr,
            },
        );
        return field ? field.value : '';
    },
});
