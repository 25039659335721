/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AccessibilityText } from '@glu/utilities-react';
import locale from '@glu/locale';
import { MagnifyAshIcon, CloseXAshIcon } from '@glu/icons-react';
import { Input } from '@glu/form-components';
import useStyles from './SearchInput.styles';

const SearchInput = (props) => {
  const {
    onClear,
    onSearch
  } = props;
  const classes = useStyles(props);
  const [inputValue, setInputValue] = useState();
  const handleSearchClick = () => {
    onSearch(inputValue);
  };
  const handleClearClick = () => {
    setInputValue(undefined);
    onClear();
  };
  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      handleSearchClick();
    }
  };
  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
  };
  return (
    <div className={`${classes.root}`}>
      <Input
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        name="grid-search-input"
        labelText={locale.get('gridReact.search.label')}
        screenReaderLabel
      />
      {inputValue
        && (
        <button className={classes.clear} type="button" onClick={handleClearClick}>
          <CloseXAshIcon />
          <AccessibilityText>
            {locale.get('gridReact.search.clear')}
          </AccessibilityText>
        </button>
        )}
      <button className={classes.button} type="button" onClick={handleSearchClick}>
        <MagnifyAshIcon />
        <AccessibilityText>
          {locale.get('gridReact.search.button')}
        </AccessibilityText>
      </button>
    </div>
  );
};

SearchInput.propTypes = {
  onClear: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default SearchInput;
