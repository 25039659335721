import dialog from '@glu/dialog';
import { appBus } from '@glu/core';
import gluLocale from '@glu/locale';
import LocaleApi from 'system/webseries/api/locale';
import StartChallengeView from 'system/mfa/views/start';

let listening = false;

export default {
    listen() {
        if (listening) {
            return;
        }
        appBus.on('mfa:challenge:start', this.startMFAChallenge);
        listening = true;
    },

    startMFAChallenge(data) {
        // Get the localized values for MFA screen
        const mfaPromise = new Promise((resolve, reject) => {
            LocaleApi.getLocales(
                ['mfa', 'passcode', 'logon', 'button', 'common', 'prompt', 'workspaces', 'CXP'],
                {
                    locale: data.locale,

                    success(resp) {
                        gluLocale.set(resp.toJSON());
                        resolve();
                    },

                    error: reject,
                },
            );
        });

        mfaPromise.then(() => {
            LocaleApi.clearFetchList();
            dialog.open(
                new StartChallengeView(data),
                {
                    showCloseButton: false,
                },
            );
        });
    },
};
