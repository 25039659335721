import EntryView from 'common/dynamicPages/views/workflow/entry';
import util from '@glu/core/src/util';
import $ from 'jquery';
import locale from '@glu/locale';
import GridApi from 'common/dynamicPages/api/grid';

const StopCancelCheckView = EntryView.extend({
    onRender() {
        EntryView.prototype.onRender.call(this);
        if (this.hasLoadedRequiredData()) {
            this.customRegion.show(this.userRoleGridView);
            $('#customRegionDiv').addClass('section section-container');
            $('#customRegionDiv').prepend(`<div class="section-header"><h3>${locale.get('CM.CheckResponseGrid')}</h3></div>`);
        } else {
            this.createUserRoleGrid();
        }
    },

    createUserRoleGrid() {
        const context = {};
        util.extend(context, this.contextDef);

        context.serviceName = '';

        const inquiryGridOptions = {
            inquirySearchCriteria: {
                inquiryId: 20685,
                searchType: 5,
            },

            additionalSearchFields: [{
                fieldName: 'TNUM',
                operator: '=',
                dataType: 'number',

                fieldValue: [
                    this.model.get('TNUM'),
                ],
            }],

            enableSavedViews: false,
            filter: false,
            selector: 'none',
            enableRowContextButtonCallbacks: false,
            context,
            hideButtons: true,
        };

        this.userRoleGridView = GridApi.createInquiryGridView(inquiryGridOptions);
    },
});

export default StopCancelCheckView;
