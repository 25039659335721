import Model from '@glu/core/src/model';
import http from '@glu/core/src/http';
import $ from 'jquery';
import services from 'services';

export default Model.extend({
    sync(method, model, options) {
        let importData;
        if (method === 'create') {
            const importService = this.has('importService') ? services.generateUrl(this.get('importService')) : services.generateUrl('/fimport/uploadFile');
            const importParameters = model.get('importParameters');
            const paymentData = model.get('paymentData');
            if (importParameters) {
                let iSize = 0;
                const parameters = {
                    item: [],
                };
                while (iSize < importParameters.length) {
                    parameters.item[iSize] = {
                        name: importParameters[iSize].name,
                        value: importParameters[iSize].value,
                    };
                    iSize += 1;
                }
                iSize = 0;
                const paymentValues = {
                    item: [],
                };
                while (iSize < paymentData.length) {
                    paymentValues.item[iSize] = {
                        name: paymentData[iSize].name,
                        value: paymentData[iSize].value,
                    };
                    iSize += 1;
                }
                importData = {
                    fileName: model.get('fileName'),
                    importType: model.get('importType'),
                    eventName: model.get('eventName'),
                    importJCode: model.get('importJCode'),
                    fileContent: model.get('fileContent'),
                    parameterMapList: parameters,
                    paymentData: paymentValues,
                };
            }

            http.post(importService, importData, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            }, {
                xhr() {
                    const myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', (event) => {
                            let percent = 0;
                            const position = event.loaded || event.position;
                            const { total } = event;
                            if (event.lengthComputable) {
                                percent = Math.ceil((position / total) * 100);
                            }
                            model.set('percentComplete', percent);
                        }, false);
                    }
                    return myXhr;
                },
            });
        }
    },
});
