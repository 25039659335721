import Layout from '@glu/core/src/layout';
import util from '@glu/core/src/util';
import setupAccessibleTabs, { createTabsToggleButton, toggleTabs, setTabButtonText } from 'common/util/a11y/tabs';
import IssuesVoidsListView from 'app/checkManagement/views/listIssues';
import ImportedFiles from 'app/checkManagement/views/importResults';
import entitlements from 'common/dynamicPages/api/entitlements';
import ContextApi from 'common/dynamicPages/api/context';
import workspaceHelper from 'common/workspaces/api/helper';
import store from 'system/utilities/cache';
import template from './checkIssueManagement.hbs';

const CheckIssueManagement = Layout.extend({
    template,
    defaultTab: 'issuesVoidsListTab',

    regions: {
        pageRegion: '.pageRegion',
    },

    ui: {
        $navTabs: '[data-hook="getNavTabs"]',
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    events: {
        'click @ui.$navLinks': 'updateView',
    },

    isInitialRender: true,

    initialize(options) {
        if (options.defaultTab) {
            this.defaultTab = options.defaultTab;
        }
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            createTabsToggleButton(this);
            const tab = store.get('cm_listView-tab');
            this.openView({
                href: tab || this.defaultTab,
                viewId: this.options.viewId,
            });
            this.isInitialRender = false;
        } else {
            this.loadViewRequirements();
        }
    },

    /*
     *  @method: setWidget
     *  @param: {View} widget
     *  Method to instantiate listeners to connect grid to widget changes
     *  Called by (higher level) SurrogateWidget when view instantiates
     */
    setWidget(widget) {
        this.listenTo(widget.model, 'change:size', this.handleWidgetSizeChange);
    },

    /*
     *  @method: handleWidgetSizeChange
     *  Listener to resize the grid when the widget is resized
     */
    handleWidgetSizeChange() {
        util.defer(() => {
            this.activeView.gridView.grid.tableView.setWidth();
        });
    },

    updateView(e) {
        const href = this.$(e.currentTarget).attr('id');
        e.preventDefault();
        this.openView({
            href,
        });
    },

    openView(options) {
        const { href } = options;
        switch (href) {
        case 'importResultsTab':
            this.activeView = new ImportedFiles({
                parent: this,
            });
            break;
        default:
            this.activeView = new IssuesVoidsListView({
                ...options,
            });
        }

        this.pageRegion.show(this.activeView);
        this.changeTab(href);
        if (this.isInitialRender) {
            this.listenToOnce(this.activeView, 'afterRender', () => {
                setupAccessibleTabs(this, this.updateView);
            });
        }
    },

    changeTab(e) {
        this.$(`a[id$=${e}]`).parent('li').addClass('is-active').siblings()
            .removeClass('is-active');
        this.currentTabText = this.$(`a[id$=${e}]`).text();
        if (this.isInitialRender) {
            setTabButtonText(this, this.currentTabText);
        } else {
            toggleTabs(this);
        }
    },

    loadViewRequirements() {
        const self = this;

        const options = {
            context: ContextApi.menuContext.getContext('CM_IV_LIST'),
        };

        const entitlementPromise = entitlements.getEntitlements(options);

        // for file import
        const optionsFimport = util.clone(options);
        optionsFimport.entryMethod = '0';
        optionsFimport.typeCode = 'CIMIMPRT';
        const entitlementFimportPromise = entitlements.getEntitlements(optionsFimport);

        Promise.all([entitlementPromise, entitlementFimportPromise]).then((results) => {
            const entResActions = results[0].actions;
            if (results[1].actions) {
                if (results[1].actions.IMPORT) {
                    entResActions.IMPORT = results[1].actions.IMPORT;
                }
                if (results[1].actions.SELECT) {
                    entResActions.IMPORTRESULTS = true;
                }
            }
            self.entitlements = entResActions;
            self.setHasLoadedRequiredData(true);
            self.render();
        });
    },

    hasEntitlement(action) {
        return (this.entitlements && this.entitlements[action] === true);
    },

    templateHelpers() {
        const self = this;
        return {
            hasImportResultsEntitlement() {
                return self.hasEntitlement('IMPORTRESULTS');
            },
        };
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CHECK_ISSUE',
    view: CheckIssueManagement,
    options: {},
    useMobileCard: true,
});

export default CheckIssueManagement;
