import $ from 'jquery';
import number from 'numeral';
// import payUtil from 'common/util/paymentUtil';
import Formatter from 'system/utilities/format';
import locale from '@glu/locale';
import dateUtil from 'common/util/dateUtil';
import Decimals from 'common/dynamicPages/api/decimals';

export default function (form, initialState) {
    const $paymentSummaryContainer = $('[data-section="payment-summary"]');
    const $amountFields = $('[data-field="summary-amount"]');
    const $currencyFields = $('[data-field="summary-currency"]');
    const $beneficiariesFields = $('[data-field="summary-beneficiaries"]');
    const summaryDate = $('[data-field="summary-date"]');
    const $summaryTotals = $('.summary-total-block');
    const { formView } = form;
    const { model } = formView;
    const formState = formView.state;
    let totalFieldName;
    let currencyFieldName;

    // Update the summary sections visibility.
    if (model.get('TYPE') !== 'FILE') {
        $paymentSummaryContainer.removeClass('hidden');
        $summaryTotals.addClass('hidden');
        $paymentSummaryContainer.filter('.section-header').children().first().addClass('hidden');
    }
    if (model.get('ENTERED_AMOUNT_FLAG') === 'D') {
        totalFieldName = 'DEBIT_AMOUNT';
        $('.debit-only').show();
        $('.credit-only').hide();
        $currencyFields.text(model.get(currencyFieldName));
        if (formState === 'view') {
            $('#TOTALAMTCREDIT').closest('fieldset').addClass('hidden');
            $('#TOTALAMTDEBIT').closest('fieldset').removeClass('hidden');
        }
    } else {
        totalFieldName = 'CREDIT_AMOUNT';
        $('.debit-only').hide();
        $('.credit-only').show();
        $currencyFields.text(model.get(currencyFieldName));
        if (formState === 'view') {
            $('#TOTALAMTDEBIT').closest('fieldset').addClass('hidden');
            $('#TOTALAMTCREDIT').closest('fieldset').removeClass('hidden');
        }
    }

    // Format the payment total.
    const totalAmount = model.get(totalFieldName) === undefined ? '' : model.get(totalFieldName).toString().trim();
    const paymentTotalFormatted = number(totalAmount || 0)
        .format(Decimals.getCurrencyFormat(model.get(currencyFieldName))).trim();
    $amountFields.text(paymentTotalFormatted);

    // Update the values of the date and beneficiary count summaries.
    $beneficiariesFields.text(`${1} ${locale.get('PAY.beneficiary')}`);

    summaryDate.text(Formatter.formatDateFromUserFormat(model.get('VALUE_DATE'), dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));

    if (initialState) {
        model.on('change:VALUE_DATE', () => {
            summaryDate.text(Formatter.formatDateFromUserFormat(model.get('VALUE_DATE'), dateUtil.PAYMENT_SUMMARY_DATE_FORMAT));
        });
    }
}
