import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@glu/indicators-react';
import { withStyles } from '@glu/theming';
import useLocale from '../../hooks/useLocale';

import RtpContext from '../RtpDrawerContainer/RtpContext';
import styles from './RtpAddToConversationButtons.styles';

const propTypes = {
    data: PropTypes.shape({
        RTPREQ: PropTypes.string,
        RTPACK: PropTypes.string,
        RFIPARTICIPANT: PropTypes.string,
        PAYMENTACKPARTICIPANT: PropTypes.string,
    }),
    currentTab: PropTypes.string.isRequired,
    initiateAck: PropTypes.func,
    initiateRFI: PropTypes.func,
    handleSend: PropTypes.func,
    handleCancel: PropTypes.func,
    isSending: PropTypes.bool,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    data: {},
    initiateAck: null,
    initiateRFI: null,
    handleSend: null,
    handleCancel: null,
    isSending: false,
};

// message constants -- TODO put in a separate file if more than one
const MAXREQUEST_MESSAGES = 20;

const AddToConversationButtons = ({
    data, currentTab, initiateAck, initiateRFI, handleSend, handleCancel, isSending,
    classes,
}) => {
    const { getLocaleString } = useLocale();
    const rtpContext = useContext(RtpContext); // get state from RtpContext
    let allowRFI = false;
    let allowACK = false;
    const allowSend = currentTab === 'submitted';

    /*
     * if the current tab is received, then
     * get the messages and determine if ack and/or rfi buttons should be displayed
     */
    if (currentTab === 'received') {
        const msgs = rtpContext.conversationItems;
        const rfiCount = msgs.reduce((acc, item) => (item.ITEM_TYPE === 'REQUEST' ? acc + 1 : acc), 0);
        const ackExists = msgs.some(item => item.ITEM_TYPE === 'ACK');

        allowRFI = data.RTPREQ === '1' && data.RFIPARTICIPANT === '1' && rfiCount < MAXREQUEST_MESSAGES;
        allowACK = data.RTPACK === '1' && data.PAYMENTACKPARTICIPANT === '1' && !ackExists;
    }

    return (
        <div className={classes.addToConversationButtons}>
            {allowRFI
                && <button onClick={initiateRFI}>{getLocaleString('rtp.requestInformationBtn')}</button>}
            {allowACK
                && <button onClick={initiateAck}>{getLocaleString('rtp.sendPaymentAcknowledgement')}</button>}
            {allowSend
            && (
                <>
                    <Loading isLoading={isSending} className={classes.spinner}>
                        <button onClick={handleSend}>{getLocaleString('rtp.sendResponse')}</button>
                    </Loading>
                    <button onClick={handleCancel}>{getLocaleString('RTP.button_cancel')}</button>
                </>
            )}
        </div>
    );
};
AddToConversationButtons.propTypes = propTypes;
AddToConversationButtons.defaultProps = defaultProps;

export default withStyles(styles)(AddToConversationButtons);
