import util from '@glu/core/src/util';
import mobileGridDefaultBulkActions from 'common/dynamicPages/api/mobileGridDefaultBulkActions';
import store from 'system/utilities/cache';
import ListView from 'common/dynamicPages/views/workflow/list';
import workspaceHelper from 'common/workspaces/api/helper';
import constants from 'common/dynamicPages/api/constants';
import alertModel from 'app/administration/models/alerts';
import mobileUtil from 'mobile/util/mobileUtil';
import configureMobileInterface from 'common/dynamicPages/views/workflow/listMobileInterface';

const AlertsList = ListView.extend({
    addUrl: 'ADMINSTRATION/alerts/addAlert',
    modifyUrl: 'ADMINSTRATION/alerts/modifyAlert',
    viewUrl: 'ADMINSTRATION/alerts/viewAlert',

    id: 'alertsList',

    attributes: {
        role: 'tabpanel',
        tabindex: '0',
        'aria-labelledby': 'alertsListTab',
    },

    initialize(options) {
        const superOptions = {
            menuCategory: 'SETUP',
            serviceName: 'alerts/listView',
            serviceFunc: null,
            businessType: null,
            context: 'ALERTLISTVW',
            enableSavedViews: false,
            menuContext: 'alerts/listView',
        };

        ListView.prototype.initialize.call(this, util.extend({}, superOptions, options));

        if (options && typeof options.returnRoute === 'undefined') {
            store.set(`${this.contextKey}-listRoute`, 'SETUP/listAlerts');
        }
    },

    setModelSync(modelParam) {
        const model = modelParam;
        model.convertModelAttributesToServerJSON = util.bind(
            alertModel.convertModelAttributesToServerJSON,
            model,
        );
        model.convertModelAttributesToKeyJSON = util.bind(
            alertModel.convertModelAttributesToKeyJSON,
            model,
        );
        model.sync = util.bind(alertModel.sync, model);
    },

    insert() {
        store.set(`${this.contextKey}-contextOverride`, this.contextDef);
        this.navigateTo(this.addUrl);
        return Promise.resolve();
    },

    /**
     * Cache contextKey
     */
    saveContextKey() {
        const savedContextKey = this.contextKey;
        this.contextKey = 'alerts_detailMaint';

        return {
            restore() {
                this.contextKey = savedContextKey;
            },
        };
    },

    gridRowModify(options) {
        const savekey = this.saveContextKey();
        store.set(`${this.contextKey}-actionModel`, options.model);
        this.navigateTo(this.modifyUrl);
        savekey.restore();
        return Promise.resolve();
    },

    gridRowSelect(options) {
        const savekey = this.saveContextKey();
        store.set(`${this.contextKey}-actionModel`, options.model);
        /* needed for delete from view */
        this.setModelSync(options.model);
        this.navigateTo(this.viewUrl);
        savekey.restore();
        return Promise.resolve();
    },

    /**
     * Delete a row in the grid
     * @param  {object} options
     */
    gridRowDelete(options) {
        return new Promise((resolve, reject) => {
            const self = this;
            this.setModelSync(options.model);

            options.model.destroy({
                success(model, resp) {
                    self.gridView.refreshGridData();
                    self.renderMessage(options.action, resp);
                    resolve({ model, result: resp });
                },

                error(e) {
                    self.gridView.refreshGridData();
                    self.renderMessage(options.action);
                    reject(e);
                },
            });
        });
    },

    /**
     * Handle print and export functions
     * @param  {string} methodName
     * @param  {array} selectedArray
     */
    bulkAction(methodName, selectedArray) {
        const self = this;
        let confirmResponse = null;
        let successMessage = null;
        let totalSuccess = 0;
        let totalFail = 0;
        let failMessage = null;
        let message = null;

        const formatConfirm = function () {
            let idx = -1;

            if (successMessage) {
                idx = successMessage.indexOf(' ');
                successMessage = idx > -1
                    ? totalSuccess + successMessage.slice(idx) : successMessage;

                // add a separator for success and failure messages
                if (failMessage) {
                    successMessage += ', ';
                }
                message = successMessage;
            }
            if (failMessage) {
                idx = failMessage.indexOf(' ');
                failMessage = idx > -1 ? totalFail + failMessage.slice(idx) : failMessage;
                if (successMessage) {
                    message += failMessage;
                } else {
                    message = failMessage;
                }
            }
            confirmResponse.message[0] = message;
            confirmResponse.confirms.totalSuccess = totalSuccess;
            confirmResponse.confirms.totalFail = totalFail;
        };

        const finishMethod = util.after(selectedArray.length, () => {
            self.gridView.refreshGridData();
            formatConfirm();
            self.renderMessage(methodName, confirmResponse);
            confirmResponse = null;
            successMessage = null;
            failMessage = null;
            message = null;
        });

        const optionsBlock = {
            success(model, resp) {
                if (confirmResponse === null) {
                    confirmResponse = resp;
                } else {
                    confirmResponse.confirms.confirmResults
                        .push(resp.confirms.confirmResults[0]);
                }
                totalSuccess += resp.confirms.totalSuccess;
                totalFail += resp.confirms.totalFail;
                if (resp.confirms.totalSuccess) {
                    [successMessage] = resp.message;
                } else {
                    [failMessage] = resp.message;
                }
                finishMethod();
            },

            error() {
                confirmResponse.confirms.totalFail += 1;
                finishMethod();
            },
        };

        util.map(selectedArray, (selected) => {
            const model = self.gridView.wrapper.rows.get(selected);
            self.setModelSync(model);

            if (methodName === constants.ACTION_DELETE) {
                model.destroy(optionsBlock);
            }
        });
    },

    /**
     * Builds model for export
     * @param  {string} format
     */
    buildExportModel(format) {
        // Alerts is an Inquiry based list view, so we need to provide this info to export
        ListView.prototype.buildExportModel.call(this, format);
        this.exportModel.actionData.productCode = '*';
        this.exportModel.actionData.functionCode = 'ALERT';
        this.exportModel.actionData.typeCode = '*';
        this.exportModel.inquiryId = 17051;
        return this.exportModel;
    },

    /**
     * Override the prototype to always process multi actions as one
     * @returns {boolean}
     */
    runProcessMultiActionsAsOne() {
        return true;
    },

    returnRoute: 'ADMINSTRATION/alerts',
});

let list = AlertsList;

if (mobileUtil.isMobileScreen()) {
    const mobileList = configureMobileInterface(list, {
        insertActions: [
            {
                label: 'alerts.button_insert',
                entitlement: 'INSERT',
                handlerMethodName: 'insert',
            },
        ],
        bulkActions: mobileGridDefaultBulkActions,
    });
    list = list.extend(mobileList);
}

workspaceHelper.publishedWidgets.add({
    id: 'ALERT_SETTINGS',
    view: list,
    options: {},
    useMobileCard: true,
});

const exportedList = list;

export default exportedList;
