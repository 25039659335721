import React from 'react';
import { ColumnsAshIcon } from '@glu/icons-react';
import locale from '@glu/locale';
import PropTypes from 'prop-types';

const ColumnIcon = ({ onClick }) => (
  <ColumnsAshIcon actionable title={locale.get('columnsTitle')} onClick={onClick} />
);

ColumnIcon.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ColumnIcon;
