/* eslint eqeqeq:0 */
import Handlebars from 'handlebars';

import date from './date';
import number from './number';
import locale from './locale';
import currencies from './currencies';
import util from './util';

// TODO require('glu/glu') here and implement templateHelperHook() with empty code, and if that function overwritten then custom helpers applied/registered.
// This is smarter, kinda _hook() as in Drupal

/**
 * Format a numeric value.  A default format will be used if a format value is not provided.
 *
 * @param value
 * @param format
 * @returns {*}
 */
function numberHelper(value, format) {
  try {
    return util.isString(format) ?
      number(value).format(format) :
      number(value).format('0,0');
  } catch (e) {
    return value;
  }
}

/**
 * Unformat a formated number value
 * @param value
 * @returns {*}
 */
function unformatNumber(value) {
  return number().unformat(value);
}

/**
 * Format a date value.  A default format will be used if a format value is not provided.
 *
 * @param value
 * @param format
 * @returns {*}
 */
function dateHelper(value, format) {
  try {
    return util.isString(format) ?
      date(value).format(format) :
      date(value).format(templateHelpers.defaultDateFormat); // eslint-disable-line no-use-before-define
  } catch (e) {
    return value;
  }
}

/**
 * Format a float value.  A default format will be used if a format value is not provided.
 *
 * @param value
 * @param format
 * @returns {*}
 */
function amountHelper(value, format) {
  try {
    return util.isString(format) ?
      numberHelper(value, format) :
      numberHelper(value, '0,0.00');
  } catch (e) {
    return value;
  }
}

/**
 * NOTE: Optional arguments seem to be set to a function of some sort.  We must test
 * the datatype of the optional argument, otherwise an error is thrown and the page will fail to render.
 */
const templateHelpers = {
  // Added this so that it's easy to globally override the default date format
  defaultDateFormat: 'DD MMM YYYY',

  /**
   * Retrieve the specified key from glu locale.  Return the defaultString if not found.
   *
   * @param key
   * @param defaultString
   * @returns {*}
   */
  locale(key, defaultString) {
    return util.isString(defaultString) ?
      locale.getDefault(key, defaultString) :
      locale.get(key);
  },

  date: dateHelper,

  number: numberHelper,

  removeNumberFormat: unformatNumber,

  time(value) {
    return dateHelper(value, 'LT');
  },

  time24(value) {
    return dateHelper(value, 'HH:mm');
  },

  amount: amountHelper,

  /**
   * Format a currency value.  Same as `amount`, but without a thousand separator. A format value may also be provided.
   *
   * @param value
   * @param format
   * @returns {*}
   */
  money(value, format) {
    return util.isString(format) ?
      amountHelper(value, format) :
      amountHelper(value, '0.00');
  },

  abbrNumber(value) {
    value = +value || 0;

    if (value > 999) {
      return numberHelper(value, '0.0a');
    }

    // Always return value as string
    return `${value}`;
  },

  isEqual(value, test, options) {
    if (value == test) {
      return options.fn(this);
    }
    return options.inverse(this);
  },

  isStrictlyEqual(value, test, options) {
    if (value === test) {
      return options.fn(this);
    }
    return options.inverse(this);
  },

  pluralize(quantity, singular, plural) {
    const digit = numberHelper(quantity);
    const singularString = singular ? locale.get(singular, digit) : digit;
    const pluralString = plural ? locale.get(plural, digit) : digit;

    return quantity === 1 ? singularString : pluralString;
  },

  currencyName(isoCode) {
    const match = currencies.get(isoCode);
    return match ? match.name : `??${isoCode}??`;
  },

  currencySymbol(isoCode) {
    const match = currencies.get(isoCode);
    return match ? match.symbol : `??${isoCode}??`;
  }
};

// Register all helpers with Handlebars
util.each(templateHelpers, (fn, name) => {
  Handlebars.registerHelper(name, fn);
});

export default templateHelpers;

