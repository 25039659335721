var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"btn btn-primary\" data-hook=\"insert-button\"> "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"BOS.EnterBill",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":85}}}))
    + "</button>\n<div class=\"billManagementGrid\" data-region=\"enterBillGrid\" aria-live=\"polite\"></div>\n";
},"useData":true});