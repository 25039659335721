var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.ACCT_INFO","Account Information",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":70}}}))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"row\">\n            <div class=\"col-2\">\n                <div class=\"form-group"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":38},"end":{"line":24,"column":78}}})) != null ? stack1 : "")
    + "\">\n                    <label for=\"templateCode\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateCode",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":65},"end":{"line":25,"column":94}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"templateCodeReadOnly") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class=\"col-4\">\n                <div class=\"form-group"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":38},"end":{"line":35,"column":78}}})) != null ? stack1 : "")
    + "\">\n                    <label for=\"templateDescription\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.TemplateDescription",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":36,"column":72},"end":{"line":36,"column":108}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":42,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"col-2\">\n\n                        <label for=\"userGroup\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.USERGROUP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":66},"end":{"line":12,"column":91}}}))
    + "</label>\n                        <p class=\"form-control-static\" data-text=\"USERGROUP\" data-bind=\"model\"></p>\n                </div>\n                <div class=\"col-4\">\n\n                        <label for=\"userGroupName\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.USERGROUPNAME",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":17,"column":70},"end":{"line":17,"column":99}}}))
    + "</label>\n                        <p class=\"form-control-static\" data-text=\"COMPANYNAME\" data-bind=\"model\"></p>\n                </div>\n             </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " required";
},"6":function(container,depth0,helpers,partials,data) {
    return "                        <p class=\"form-control-static\" data-text=\"TEMPLATE_CODE\" data-bind=\"model\"></p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                        <input id=\"templateCode\" type=\"text\" class=\"form-control\" name=\"TEMPLATE_CODE\" maxlength=\"35\" data-bind=\"model\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEMPLATE_CODE\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        <p class=\"form-control-static\" data-text=\"TEMPLATE_DESCRIPTION\" data-bind=\"model\"></p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                        <input id=\"templateDescription\" type=\"text\" class=\"form-control\" name=\"TEMPLATE_DESCRIPTION\" maxlength=\"35\" data-bind=\"model\">\n                        <span class=\"help-block\" data-bind=\"model\" data-validate=\"TEMPLATE_DESCRIPTION\"></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":62,"column":11}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"form-group col-3\">\n            <label for=\"userGroup\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.USERGROUP",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":54},"end":{"line":53,"column":79}}}))
    + "</label>\n            <p class=\"form-control-static\" data-text=\"USERGROUP\" data-bind=\"model\"></p>\n\n        </div>\n        <div class=\"form-group col-3\">\n            <label for=\"userGroupName\" class=\"main-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"CM.USERGROUPNAME",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":58,"column":58},"end":{"line":58,"column":87}}}))
    + "</label>\n            <p class=\"form-control-static\" data-text=\"COMPANYNAME\" data-bind=\"model\"></p>\n\n        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":35},"end":{"line":66,"column":75}}})) != null ? stack1 : "")
    + " col-3\">\n                <label for=\"fromAcc-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":67,"column":36},"end":{"line":67,"column":43}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.DEBIT_ACCOUNT","From Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":67,"column":45},"end":{"line":67,"column":100}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnlyOrCopyFromTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":73,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p id=\"fromAcc-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":69,"column":31},"end":{"line":69,"column":38}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"DEBIT_ACCOUNT_FORMATTED\" data-bind=\"model\" data-select-name=\"ACCOUNTFILTER\">\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"hidden\" id=\"fromAcc-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":71,"column":53},"end":{"line":71,"column":60}}}) : helper)))
    + "\" class=\"form-control\" name=\"ACCOUNTFILTER\" data-bind=\"model\" data-hook=\"getFromCombo\" data-select-name=\"ACCOUNTFILTER\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"ACCOUNTFILTER\"></span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":35},"end":{"line":77,"column":75}}})) != null ? stack1 : "")
    + " col-3\">\n                <label for=\"toAcc-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":78,"column":34},"end":{"line":78,"column":41}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.BENE_ACCOUNT","To Account",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":78,"column":43},"end":{"line":78,"column":95}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnlyOrCopyFromTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":79,"column":16},"end":{"line":84,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p id=\"toAcc-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":80,"column":29},"end":{"line":80,"column":36}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"BENE_ACCOUNT_FORMATTED\" data-bind=\"model\" data-select-name=\"BENE_ACCOUNTENTITLEMENT\">\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"hidden\" id=\"toAcc-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":82,"column":51},"end":{"line":82,"column":58}}}) : helper)))
    + "\" class=\"form-control\" name=\"BENE_ACCOUNTENTITLEMENT\" data-bind=\"model\" data-hook=\"getToCombo\" data-select-name=\"BENE_ACCOUNTENTITLEMENT\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ACCOUNTENTITLEMENT\"></span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-group date-picker "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":47},"end":{"line":88,"column":87}}})) != null ? stack1 : "")
    + " col-2\">\n                <label for=\"transferDate-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":89,"column":41},"end":{"line":89,"column":48}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_DATE","Transfer Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":89,"column":50},"end":{"line":89,"column":106}}}))
    + "</label>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":91,"column":16},"end":{"line":101,"column":23}}})) != null ? stack1 : "")
    + "\n            </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p id=\"transferDate-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":92,"column":40},"end":{"line":92,"column":47}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"VALUE_DATE\" data-bind=\"model\">\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input id=\"transferDate-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":94,"column":44},"end":{"line":94,"column":51}}}) : helper)))
    + "\" name=\"VALUE_DATE\" type=\"text\" class=\"input-date form-control\" data-bind=\"model\" autocomplete=\"off\">\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"VALUE_DATE\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOne2OneNotModify") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":20},"end":{"line":100,"column":27}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"textline-field\" data-hook=\"getDateMessage\">\n                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"RTGS.TRANSFER.TRANSFER_DATE.DEFAULT_MESSAGE",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":98,"column":24},"end":{"line":98,"column":80}}}))
    + "\n                    </span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <!--right justify and formatting-->\n            <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reqAmount") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":35},"end":{"line":107,"column":67}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleTransferOrModify") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":107,"column":68},"end":{"line":107,"column":125}}})) != null ? stack1 : "")
    + "\">\n                <label for=\"amount-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":108,"column":35},"end":{"line":108,"column":42}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.AMOUNT","Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":108,"column":44},"end":{"line":108,"column":86}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAmountLockable") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":16},"end":{"line":113,"column":23}}})) != null ? stack1 : "")
    + "\n                <div>\n                    <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lockPaymentAmount") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":117,"column":24},"end":{"line":123,"column":31}}})) != null ? stack1 : "")
    + "                        <span data-hook=\"exchangeRateAmount\" class=\"exchange-rate-amount\"></span>\n                    </div>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"CREDIT_CURRENCY\"></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isOne2One") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":20},"end":{"line":141,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <span class=\"help-block\" data-bind=\"model\" data-validate=\"ENTERED_AMOUNT\"></span>\n            </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    return "required";
},"36":function(container,depth0,helpers,partials,data) {
    return "col-4";
},"38":function(container,depth0,helpers,partials,data) {
    return "col-3";
},"40":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"mdf-input-icon-container\">\n                        <button class=\"view "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"amountLockIconClass") || (depth0 != null ? lookupProperty(depth0,"amountLockIconClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"amountLockIconClass","hash":{},"data":data,"loc":{"start":{"line":111,"column":44},"end":{"line":111,"column":67}}}) : helper)))
    + " btn-tertiary btn\" data-action=\"toggleLock\" name=\"ENTERED_AMOUNT\"></button>\n                    </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p id=\"amount-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":118,"column":42},"end":{"line":118,"column":49}}}) : helper)))
    + "\" class=\"entered-amount\" data-amount-type=\"ENTERED_AMOUNT_VIEW\" data-bind=\"model\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"enteredAmountView") : depth0),(depth0 != null ? lookupProperty(depth0,"enteredAmountFormat") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":118,"column":131},"end":{"line":118,"column":179}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"enteredCurrencyView") || (depth0 != null ? lookupProperty(depth0,"enteredCurrencyView") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"enteredCurrencyView","hash":{},"data":data,"loc":{"start":{"line":118,"column":180},"end":{"line":118,"column":203}}}) : helper)))
    + "</p>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <input class=\"form-control text-right\" name=\"ENTERED_AMOUNT\" data-bind=\"model\" data-maxlength=\"19\" id=\"amount-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":120,"column":138},"end":{"line":120,"column":145}}}) : helper)))
    + "\">\n                            <div class=\"field-container currency-type\" name=\"currencyType\" data-bind=\"model\"></div>\n                            <div class=\"currency-text hide\"></div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"templateMaxAmountFormatted") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":24},"end":{"line":136,"column":31}}})) != null ? stack1 : "")
    + "                        <p class=\"indicative-rate hide\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.INDICATIVE_RATE",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":137,"column":56},"end":{"line":137,"column":91}}}))
    + "</p>\n                        <span data-hook=\"contractID\"></span>\n                        <a href=\"#\" data-hook=\"contractIDpopUp\" class=\"add-contract-id hide\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.AddContractId",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":139,"column":93},"end":{"line":139,"column":124}}}))
    + "</a>\n                        <a href=\"#\" data-hook=\"removeContractID\" class=\"remove-contract-id hide\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"remove",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":140,"column":98},"end":{"line":140,"column":117}}}))
    + "</a>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isSingleTransfer") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":129,"column":28},"end":{"line":135,"column":35}}})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <p class=\"textline-field\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.paymentmaximum",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":130,"column":58},"end":{"line":130,"column":89}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"templateMaxAmountFormatted") || (depth0 != null ? lookupProperty(depth0,"templateMaxAmountFormatted") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateMaxAmountFormatted","hash":{},"data":data,"loc":{"start":{"line":130,"column":90},"end":{"line":130,"column":122}}}) : helper)))
    + "</p>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"templateMode") : depth0),{"name":"unless","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":32},"end":{"line":134,"column":43}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <p class=\"textline-field\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.paymentmaximum",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":133,"column":62},"end":{"line":133,"column":93}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"templateMaxAmountFormatted") || (depth0 != null ? lookupProperty(depth0,"templateMaxAmountFormatted") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateMaxAmountFormatted","hash":{},"data":data,"loc":{"start":{"line":133,"column":94},"end":{"line":133,"column":126}}}) : helper)))
    + "</p>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <label for=\"transferViewClearBtn-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":147,"column":45},"end":{"line":147,"column":52}}}) : helper)))
    + "\" style=\"visibility: hidden\">Clear Fields</label>\n            <button type=\"button\" aria-label=\""
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.clearallvalues",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":148,"column":46},"end":{"line":148,"column":80}}}))
    + "\" name=\"transferViewClearBtn-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":148,"column":109},"end":{"line":148,"column":116}}}) : helper)))
    + "\" class=\"btn btn-tertiary\" data-hook=\"clear-fields\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"button.clear",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":148,"column":168},"end":{"line":148,"column":193}}}))
    + "</button>\n            "
    + alias4(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary top-right-btn hide","action":"removeItem","locale":"RISK.MULTIPLEENTRY.REMOVE_ITEM","name":"close"},"data":data,"loc":{"start":{"line":149,"column":12},"end":{"line":149,"column":142}}}))
    + "\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasIndicativeRate") : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.program(58, data, 0),"data":data,"loc":{"start":{"line":155,"column":13},"end":{"line":194,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayCutoff") : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":15},"end":{"line":200,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPotentialDuplicate") : depth0),{"name":"if","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":201,"column":12},"end":{"line":210,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isError") : depth0),{"name":"if","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":12},"end":{"line":216,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                 <div class=\"form-group col-3\" data-hook=\"getIndicativeRate\">\n                     <label for=\"indicative-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":157,"column":44},"end":{"line":157,"column":51}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.INDICATIVE_RATE","Indicative Rate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":157,"column":53},"end":{"line":157,"column":106}}}))
    + "</label>\n                     <p id=\"indicative-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":158,"column":39},"end":{"line":158,"column":46}}}) : helper)))
    + "\" class=\"form-control-static\" data-hook=\"getIndicativeRate\" data-text=\"INDICATIVERATE\" data-bind=\"model\">\n                 </div>\n                 <div class=\"form-group col-3\" data-hook=\"getIndicativeAmount\">\n                     <label for=\"indicativeAmt-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":161,"column":47},"end":{"line":161,"column":54}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.INDICATIVEAMOUNT","Indicative Amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":161,"column":56},"end":{"line":161,"column":112}}}))
    + "</label>\n                     <p id=\"indicativeAmt-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":162,"column":42},"end":{"line":162,"column":49}}}) : helper)))
    + "\" class=\"form-control-static\" data-bind=\"model\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"indicativeAmountView") : depth0),(depth0 != null ? lookupProperty(depth0,"indicativeAmountFormat") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":162,"column":97},"end":{"line":162,"column":151}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"indicativeCurrencyView") || (depth0 != null ? lookupProperty(depth0,"indicativeCurrencyView") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"indicativeCurrencyView","hash":{},"data":data,"loc":{"start":{"line":162,"column":152},"end":{"line":162,"column":178}}}) : helper)))
    + "</p>\n                 </div>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasExchangeRate") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":13},"end":{"line":194,"column":13}}})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                 <div class=\"form-group col-3\">\n                     <label for=\"exchange-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":166,"column":42},"end":{"line":166,"column":49}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGS.Exchange_Rate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":166,"column":51},"end":{"line":166,"column":82}}}))
    + "</label>\n                     <p id=\"exchange-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":167,"column":37},"end":{"line":167,"column":44}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"EXCHANGE_RATE\" data-bind=\"model\">\n                 </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasContractId") : depth0),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":169,"column":17},"end":{"line":174,"column":24}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasCalculatedDebit") : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.program(67, data, 0),"data":data,"loc":{"start":{"line":175,"column":17},"end":{"line":193,"column":24}}})) != null ? stack1 : "")
    + "             ";
},"60":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                     <div class=\"form-group col-3\">\n                         <label for=\"contractId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":171,"column":48},"end":{"line":171,"column":55}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"RTGSFXCONTRACTS.CONTRACTID","Contract Id",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":171,"column":57},"end":{"line":171,"column":110}}}))
    + "</label>\n                         <p id=\"contractId-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":172,"column":43},"end":{"line":172,"column":50}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"EXCHANGE_RATE_CONTRACTID\" data-bind=\"model\">\n                     </div>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                     <div class=\"form-group col-3\" data-hook=\"getIndicativeAmount\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNeedsRates") : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.program(65, data, 0),"data":data,"loc":{"start":{"line":177,"column":25},"end":{"line":181,"column":32}}})) != null ? stack1 : "")
    + "                         <p id=\"debitAmt-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":182,"column":41},"end":{"line":182,"column":48}}}) : helper)))
    + "\" class=\"form-control-static\" data-bind=\"model\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"debitAmountView") : depth0),(depth0 != null ? lookupProperty(depth0,"debitAmountFormat") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":182,"column":96},"end":{"line":182,"column":140}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"debitCurrencyView") || (depth0 != null ? lookupProperty(depth0,"debitCurrencyView") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"debitCurrencyView","hash":{},"data":data,"loc":{"start":{"line":182,"column":141},"end":{"line":182,"column":162}}}) : helper)))
    + "</p>\n                     </div>\n";
},"63":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                             <label for=\"debitAmt-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":178,"column":50},"end":{"line":178,"column":57}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.INDICATIVEAMOUNT",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":178,"column":59},"end":{"line":178,"column":95}}}))
    + "</label>\n";
},"65":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                             <label for=\"debitAmt-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":180,"column":50},"end":{"line":180,"column":57}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.debit_amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":180,"column":59},"end":{"line":180,"column":91}}}))
    + "</label>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasCalculatedCredit") : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":17},"end":{"line":193,"column":17}}})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                     <div class=\"form-group col-3\" data-hook=\"getIndicativeAmount\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNeedsRates") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":186,"column":25},"end":{"line":190,"column":32}}})) != null ? stack1 : "")
    + "                         <p id=\"creditAmt-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":191,"column":42},"end":{"line":191,"column":49}}}) : helper)))
    + "\" class=\"form-control-static\" data-bind=\"model\">"
    + alias4(lookupProperty(helpers,"amount").call(alias1,(depth0 != null ? lookupProperty(depth0,"creditAmountView") : depth0),(depth0 != null ? lookupProperty(depth0,"creditAmountFormat") : depth0),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":191,"column":97},"end":{"line":191,"column":143}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"creditCurrencyView") || (depth0 != null ? lookupProperty(depth0,"creditCurrencyView") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"creditCurrencyView","hash":{},"data":data,"loc":{"start":{"line":191,"column":144},"end":{"line":191,"column":166}}}) : helper)))
    + "</p>\n                     </div>\n                 ";
},"69":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                             <label for=\"creditAmt-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":187,"column":51},"end":{"line":187,"column":58}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.INDICATIVEAMOUNT",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":187,"column":60},"end":{"line":187,"column":96}}}))
    + "</label>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                             <label for=\"creditAmt-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":189,"column":51},"end":{"line":189,"column":58}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.*.credit_amount",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":189,"column":60},"end":{"line":189,"column":93}}}))
    + "</label>\n";
},"73":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                   <div class=\"form-group col-3\">\n                       <label for=\"cutoffInfo-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":197,"column":46},"end":{"line":197,"column":53}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PAY.TransferMustBeApprovedBy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":197,"column":55},"end":{"line":197,"column":96}}}))
    + "</label>\n                       <p id=\"cutoffInfo-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":198,"column":41},"end":{"line":198,"column":48}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"CUTOFF_INFO\" data-bind=\"model\">\n                   </div>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group col-3\">\n                    <label for=\"POSSIBLEDUPLICATEFLAG-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":203,"column":54},"end":{"line":203,"column":61}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PAY.POSSIBLEDUPLICATEFLAG.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":203,"column":63},"end":{"line":203,"column":107}}}))
    + "</label>\n                    <p id=\"POSSIBLEDUPLICATEFLAG-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":204,"column":49},"end":{"line":204,"column":56}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"POSSIBLEDUPLICATEFLAG_DESCRIPTION\" data-bind=\"model\">\n                </div>\n                <div class=\"form-group col-3\">\n                    <label for=\"DUPLICATEREASON-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":207,"column":48},"end":{"line":207,"column":55}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PAY.DUPLICATEREASON.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":207,"column":57},"end":{"line":207,"column":95}}}))
    + "</label>\n                    <p id=\"DUPLICATEREASON-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":208,"column":43},"end":{"line":208,"column":50}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"DUPLICATEREASON\" data-bind=\"model\">\n                </div>\n";
},"77":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-group col-3\">\n                    <label for=\"ERRORS-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":213,"column":39},"end":{"line":213,"column":46}}}) : helper)))
    + "\">"
    + alias4(lookupProperty(helpers,"locale").call(alias1,"PAY.ERRORS.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":213,"column":48},"end":{"line":213,"column":77}}}))
    + "</label>\n                    <p id=\"ERRORS-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":214,"column":34},"end":{"line":214,"column":41}}}) : helper)))
    + "\" class=\"form-control-static\" data-text=\"ERRORS\" data-bind=\"model\">\n                </div>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row  "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSingleTransfer") : depth0),{"name":"unless","hash":{},"fn":container.program(80, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":221,"column":17},"end":{"line":221,"column":74}}})) != null ? stack1 : "")
    + "\">\n\n    <div class=\"col-3 form-group\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noReferenceAndView") : depth0),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":223,"column":34},"end":{"line":223,"column":89}}})) != null ? stack1 : "")
    + ">\n\n            <label for=\"transferRef-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":225,"column":36},"end":{"line":225,"column":43}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.transferref","Transfer Reference",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":225,"column":45},"end":{"line":225,"column":98}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.program(86, data, 0),"data":data,"loc":{"start":{"line":226,"column":12},"end":{"line":230,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"form-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noReferenceAndView") : depth0),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.program(90, data, 0),"data":data,"loc":{"start":{"line":232,"column":27},"end":{"line":232,"column":82}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noCommentsAndView") : depth0),{"name":"if","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":84},"end":{"line":232,"column":138}}})) != null ? stack1 : "")
    + ">\n            <label for=\"transferComments\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"payment.transfercomment","Comments",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":233,"column":42},"end":{"line":233,"column":89}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(92, data, 0),"inverse":container.program(94, data, 0),"data":data,"loc":{"start":{"line":234,"column":12},"end":{"line":238,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShowTime") : depth0),{"name":"if","hash":{},"fn":container.program(96, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":240,"column":4},"end":{"line":256,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"80":function(container,depth0,helpers,partials,data) {
    return " xfer-opt-fields ";
},"82":function(container,depth0,helpers,partials,data) {
    return " style=\"display:none\" ";
},"84":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p id=\"transferRef-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":227,"column":31},"end":{"line":227,"column":38}}}) : helper)))
    + "\" data-text=\"CUSTOMER_REFERENCE\" data-bind=\"model\" class=\"form-control-static\"> </p>\n";
},"86":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input id=\"transferRef-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":229,"column":35},"end":{"line":229,"column":42}}}) : helper)))
    + "\" type=\"text\" name=\"CUSTOMER_REFERENCE\" data-bind=\"model\" maxlength=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"referenceLength") || (depth0 != null ? lookupProperty(depth0,"referenceLength") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"referenceLength","hash":{},"data":data,"loc":{"start":{"line":229,"column":111},"end":{"line":229,"column":130}}}) : helper)))
    + "\" class=\"form-control\" value=\"\">\n";
},"88":function(container,depth0,helpers,partials,data) {
    return " col-3 ";
},"90":function(container,depth0,helpers,partials,data) {
    return " col-5 ";
},"92":function(container,depth0,helpers,partials,data) {
    return "            <p id=\"transferComments\" type=\"text\" data-text=\"SPECIAL_INSTRUCTIONS\" data-bind=\"model\" class=\"form-control-static\">\n";
},"94":function(container,depth0,helpers,partials,data) {
    return "            <input id=\"transferComments\" type=\"text\" name=\"SPECIAL_INSTRUCTIONS\" data-bind=\"model\" maxlength=\"104\" class=\"form-control\">\n";
},"96":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-2\">\n            <div class=\"form-group\">\n                <label for=\"startTime_"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":243,"column":38},"end":{"line":243,"column":45}}}) : helper)))
    + "\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.TRANSFER_TIME",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":243,"column":47},"end":{"line":243,"column":87}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(97, data, 0),"inverse":container.program(99, data, 0),"data":data,"loc":{"start":{"line":244,"column":16},"end":{"line":252,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"help-block\" data-bind=\"model\" data-validate=\"TRANSFER_TIME\"></span>\n            </div>\n        </div>\n";
},"97":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p><span id=\"startTime_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":245,"column":43},"end":{"line":245,"column":50}}}) : helper)))
    + "\" type=\"text\" data-text=\"TRANSFER_TIME\" data-bind=\"model\" class=\"form-control-static\"></span>\n                        <span aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":246,"column":42},"end":{"line":246,"column":58}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":246,"column":60},"end":{"line":246,"column":76}}}) : helper)))
    + "</span></p>\n";
},"99":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-group\">\n                    <input id=\"startTime_"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":249,"column":41},"end":{"line":249,"column":48}}}) : helper)))
    + "\" name=\"TRANSFER_TIME\" type=\"text\" class=\"form-control input-time\" data-bind=\"model\" data-hook=\"getTimeField\">\n                    <span class=\"input-group-addon addon-right\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":250,"column":76},"end":{"line":250,"column":92}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userTimezone") || (depth0 != null ? lookupProperty(depth0,"userTimezone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userTimezone","hash":{},"data":data,"loc":{"start":{"line":250,"column":94},"end":{"line":250,"column":110}}}) : helper)))
    + "</span>\n                </div>\n";
},"101":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "       <div class = \"row\">\n        <label for=\"singleRecur\">\n            <input type=\"checkbox\" id=\"final-approve\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":266,"column":54},"end":{"line":266,"column":96}}})) != null ? stack1 : "")
    + " name=\"singleRecur\" data-bind=\"model\" data-hook=\"single-recur-select\"> "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.singleRecur",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":266,"column":167},"end":{"line":266,"column":196}}}))
    + "\n        </label>\n       </div>\n\n       <div class=\"row\"><div data-hook=\"singleSchedulerRegion\" class=\"col-4\"></div></div>\n\n       <div class=\"businessDay-Mode\">\n         <form class=\"form-inline\">\n            <fieldset>\n                <legend class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.NON_BIZ_DAY_MESSAGE","If Transfer Date falls on a non-business day, transfer funds on:",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":275,"column":41},"end":{"line":275,"column":154}}}))
    + "</legend>\n\n                <div class=\"radio\">\n                    <div class=\"radio-inline\">\n                        <input id=\"prevRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":279,"column":46},"end":{"line":279,"column":88}}})) != null ? stack1 : "")
    + " name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"prev\" data-bind=\"model\">\n                        <label for=\"prevRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.PREV_BUSINESS_DAY","Previous business day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":280,"column":47},"end":{"line":280,"column":115}}}))
    + "</label>\n                    </div>\n                    <div class=\"radio-inline\">\n                        <input id=\"nextRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":283,"column":46},"end":{"line":283,"column":88}}})) != null ? stack1 : "")
    + " name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"next\" data-bind=\"model\">\n                        <label for=\"nextRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.NEXT_BUSINESS_DAY","Next business day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":284,"column":47},"end":{"line":284,"column":111}}}))
    + "</label>\n                    </div>\n                    <div class=\"radio-inline\">\n                        <input id=\"voidRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":287,"column":46},"end":{"line":287,"column":88}}})) != null ? stack1 : "")
    + " name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"none\" data-bind=\"model\">\n                        <label for=\"voidRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.DO_NOT_TRANSFER_FUNDS","Do not transfer funds",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":288,"column":47},"end":{"line":288,"column":119}}}))
    + "</label>\n                    </div>\n                </div>\n            </fieldset>\n         </form>\n       </div>\n\n       <div class=\"schedWarning\"><b>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"PAY.NoAutoSchedulePayWarning",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":295,"column":36},"end":{"line":295,"column":77}}}))
    + "</b></div>\n\n";
},"102":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"104":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div id=\"earliestValueDateDiv\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nextEarliestValueDate") : depth0),{"name":"if","hash":{},"fn":container.program(105, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":303,"column":12},"end":{"line":305,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n        <h2>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.RECURRENCE","Recurrence",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":308,"column":12},"end":{"line":308,"column":62}}}))
    + "</h2>\n\n        <div class=\"row\"><div data-hook=\"schedulerRegion\" class=\"col-4\"></div></div>\n\n        <form class=\"form-inline\">\n            <fieldset>\n                <legend class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.NON_BIZ_DAY_MESSAGE","If Transfer Date falls on a non-business day, transfer funds on:",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":314,"column":41},"end":{"line":314,"column":154}}}))
    + "</legend>\n\n                <div class=\"radio\">\n                    <div class=\"radio-inline\">\n                        <input id=\"prevRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":318,"column":46},"end":{"line":318,"column":88}}})) != null ? stack1 : "")
    + " name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"prev\" data-bind=\"model\">\n                        <label for=\"prevRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.PREV_BUSINESS_DAY","Previous business day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":319,"column":47},"end":{"line":319,"column":115}}}))
    + "</label>\n                    </div>\n                    <div class=\"radio-inline\">\n                        <input id=\"nextRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":322,"column":46},"end":{"line":322,"column":88}}})) != null ? stack1 : "")
    + " name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"next\" data-bind=\"model\">\n                        <label for=\"nextRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.NEXT_BUSINESS_DAY","Next business day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":323,"column":47},"end":{"line":323,"column":111}}}))
    + "</label>\n                    </div>\n                    <div class=\"radio-inline\">\n                        <input id=\"voidRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":326,"column":46},"end":{"line":326,"column":88}}})) != null ? stack1 : "")
    + " name=\"NONBUSINESSDAYMODE\" type=\"radio\" value=\"none\" data-bind=\"model\">\n                        <label for=\"voidRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.DO_NOT_TRANSFER_FUNDS","Do not transfer funds",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":327,"column":47},"end":{"line":327,"column":119}}}))
    + "</label>\n                    </div>\n                </div>\n            </fieldset>\n        </form>\n\n        <form class=\"form-inline\">\n            <fieldset>\n                <legend class=\"required\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.END_ON","End on:",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":335,"column":41},"end":{"line":335,"column":84}}}))
    + "</legend>\n\n                <div class=\"radio\">\n                    <div class=\"radio-inline\">\n                        <input id=\"neverRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":339,"column":47},"end":{"line":339,"column":89}}})) != null ? stack1 : "")
    + " name=\"ENDMODE\" type=\"radio\" value=\"noend\" data-bind=\"model\">\n                        <label for=\"neverRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.NEVER","Never",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":340,"column":48},"end":{"line":340,"column":88}}}))
    + "</label>\n                    </div>\n                    <div class=\"radio-inline\">\n                        <input id=\"effDateRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":343,"column":49},"end":{"line":343,"column":91}}})) != null ? stack1 : "")
    + " name=\"ENDMODE\" type=\"radio\" value=\"effdate\" data-bind=\"model\">\n                        <label for=\"effDateRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.END_DATE","End Date",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":344,"column":50},"end":{"line":344,"column":96}}}))
    + "</label>\n                    </div>\n                    <div class=\"radio-inline\">\n                        <input id=\"occRadio\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(102, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":347,"column":45},"end":{"line":347,"column":87}}})) != null ? stack1 : "")
    + " name=\"ENDMODE\" type=\"radio\" value=\"numoccur\" data-bind=\"model\">\n                        <label for=\"occRadio\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.TRANSFER.OCCURENCES","# of Occurences",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":348,"column":46},"end":{"line":348,"column":101}}}))
    + "</label>\n                    </div>\n                </div>\n\n                <div class=\"form-group\">\n                    <label for=\"occInput\" class=\"sr-only\">Occurences</label>\n                    <input id=\"occInput\" type=\"text\" name=\"ENDCYCLES\" data-bind=\"model\">\n                </div>\n            </fieldset>\n        </form>\n";
},"105":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h3>"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.Transfer.Schedule.NextEarliestValueDate",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":304,"column":20},"end":{"line":304,"column":77}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"nextEarliestValueDate") || (depth0 != null ? lookupProperty(depth0,"nextEarliestValueDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"nextEarliestValueDate","hash":{},"data":data,"loc":{"start":{"line":304,"column":78},"end":{"line":304,"column":103}}}) : helper)))
    + "</h3>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<fieldset>\n\n    <legend class=\"sr-only\">Transfer</legend>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRecur") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":63,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideFrom") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":4},"end":{"line":75,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideTo") : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":8},"end":{"line":86,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideDate") : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":8},"end":{"line":104,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideAmount") : depth0),{"name":"unless","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":8},"end":{"line":145,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideClose") : depth0),{"name":"unless","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":8},"end":{"line":150,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":4},"end":{"line":218,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"alwaysHideOptField") : depth0),{"name":"unless","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":0},"end":{"line":258,"column":11}}})) != null ? stack1 : "")
    + "\n\n\n    <div class = \"row\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSingleRecur") : depth0),{"name":"unless","hash":{},"fn":container.program(101, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":263,"column":5},"end":{"line":297,"column":16}}})) != null ? stack1 : "")
    + "     </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isRecur") : depth0),{"name":"if","hash":{},"fn":container.program(104, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":300,"column":4},"end":{"line":358,"column":11}}})) != null ? stack1 : "")
    + "</fieldset>\n";
},"useData":true});