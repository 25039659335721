// const { default: ItemView } = require('@glu/core/src/itemView');
import { ItemView } from '@glu/core'; // may work in devMode but not in compiled.
import template from './applicationView.hbs';

export default ItemView.extend({
    template,
    events: {
        'click input': 'toggleSelected',
    },
    templateHelpers() {
        return {
            hx: 'h4',
            name: this.model.get('DESCRIPTION'),
            applicationId: this.model.get('ID_APPLICATION'),
            checkAppGropuNum: this.model.get('CHECKAPP_GROUPNUM'),
            paymentTypeDesc: this.model.get('PAYMENTTYPEDESC'),
        };
    },
});
