export default function (
    form,
    initialState,
) {
    const { model } = form.formView;
    const formState = form.formView.state;
    const enteredTimeStampField = form.field('ENTERED_TIMESTAMP');

    if (initialState) {
        /*
         * Copy the values from the Default section of the header to beneficiary
         * if the corresponding value in the beneficiary is blank.
         * copy EFFECTIVEDATE to EFFECTIVEDATE_TRANS
         * copy TRANS_CODE_1 to TRANCODELIVE
         */
        if (form.formView && form.formView.parentModel) {
            const { parentModel } = form.formView;

            if (parentModel.get('TRANS_CODE_1') && model.get('TRANCODELIVE') === '') {
                model.set('TRANCODELIVE', parentModel.get('TRANS_CODE_1'));
            }

            if (parentModel.get('OFFSETACCOUNTNUM') && model.get('RETURNS_ACCOUNT_TRANS') === '') {
                model.set('RETURNS_ACCOUNT_TRANS', parentModel.get('OFFSETACCOUNTNUM'));
            }

            if (parentModel.get('ORIGCOMPFULLNAMETEMP') && model.get('ORIGCOMPFULLNAME') === '') {
                model.set('ORIGCOMPFULLNAME', parentModel.get('ORIGCOMPFULLNAMETEMP'));
            }

            if (parentModel.get('ORIGCOMPNAME') && model.get('ORIGCOMPNAME_TRANS') === '') {
                model.set('ORIGCOMPNAME_TRANS', parentModel.get('ORIGCOMPNAME'));
            }

            if (parentModel.get('DESTCURRENCYCODE') && model.get('DESTCURRENCYCODE') === '') {
                model.set('DESTCURRENCYCODE', parentModel.get('DESTCURRENCYCODE'));
            }
        }
        if (formState === 'insert') {
            // Hide the audit section
            enteredTimeStampField.$el.closest('.section').hide();
        }
    }
}
