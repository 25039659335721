var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "true";
},"3":function(container,depth0,helpers,partials,data) {
    return "false";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"collapsed\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "in";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"panel "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeClass") || (depth0 != null ? lookupProperty(depth0,"themeClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":32}}}) : helper)))
    + "\">\n\n<div class=\"panel-heading\" id=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelId") || (depth0 != null ? lookupProperty(depth0,"panelId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelId","hash":{},"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":56}}}) : helper)))
    + "\">\n    <h3 class=\"panel-title\">\n        <a role=\"button\" data-toggle=\"collapse\" href=\"#panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelId") || (depth0 != null ? lookupProperty(depth0,"panelId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelId","hash":{},"data":data,"loc":{"start":{"line":5,"column":61},"end":{"line":5,"column":72}}}) : helper)))
    + "\" aria-expanded=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExpanded") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":89},"end":{"line":5,"column":131}}})) != null ? stack1 : "")
    + "\" aria-controls=\"panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelId") || (depth0 != null ? lookupProperty(depth0,"panelId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelId","hash":{},"data":data,"loc":{"start":{"line":5,"column":154},"end":{"line":5,"column":165}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExpanded") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":167},"end":{"line":5,"column":217}}})) != null ? stack1 : "")
    + ">\n            <span class=\"indicator-icon\"></span> <span class=\"panel-title-inner\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":6,"column":81},"end":{"line":6,"column":90}}}) : helper)))
    + "</span>\n        </a>\n    </h3>\n</div>\n<div id=\"panel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelId") || (depth0 != null ? lookupProperty(depth0,"panelId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelId","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":26}}}) : helper)))
    + "\" class=\"panel-collapse collapse "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExpanded") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":59},"end":{"line":10,"column":86}}})) != null ? stack1 : "")
    + "\" role=\"tabpanel\" aria-labelledby=\"panel-heading-"
    + alias4(((helper = (helper = lookupProperty(helpers,"panelId") || (depth0 != null ? lookupProperty(depth0,"panelId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"panelId","hash":{},"data":data,"loc":{"start":{"line":10,"column":135},"end":{"line":10,"column":146}}}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":10,"column":159},"end":{"line":10,"column":167}}}) : helper)))
    + "\">\n    <div class=\"panel-body\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":22}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n</div>\n";
},"useData":true});