var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(lookupProperty(helpers,"icon").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{"action":"refresh-balances","icon":(depth0 != null ? lookupProperty(depth0,"refresh") : depth0),"textVisible":true,"className":"btn btn-tertiary refresh-balances-button"},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":145}}}))
    + "\n                            <span class=\"refresh-time\" data-hook=\"refresh-time\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\"\n                                data-action=\"refreshBalances\"\n                                class=\"btn btn-tertiary refresh\"\n                                data-hook=\"refresh-button\"\n                                aria-label=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":44},"end":{"line":19,"column":71}}}))
    + "\"\n                                title=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"button.refresh",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":39},"end":{"line":20,"column":66}}}))
    + "\">\n                                <span class=\"icon-refresh\"></span>\n                                <span class=\"refresh-time\"\n                                    data-hook=\"refresh-time\"></span>\n                            </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"overall-panel-description\">"
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalsWarningMsg") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":32,"column":49},"end":{"line":32,"column":76}}}))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"panelWithTotals")||(depth0 && lookupProperty(depth0,"panelWithTotals"))||alias2).call(alias1,{"name":"panelWithTotals","hash":{"displayPanelDescription":(depth0 != null ? lookupProperty(depth0,"displayPanelDescription") : depth0),"displayDropdown":(depth0 != null ? lookupProperty(depth0,"displayDropdown") : depth0),"totalsWarning":(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"getTotalsWarning"))||alias2).call(alias1,{"name":"@root/getTotalsWarning","hash":{},"data":data,"loc":{"start":{"line":42,"column":34},"end":{"line":42,"column":58}}}),"totalsId":(depth0 != null ? lookupProperty(depth0,"id") : depth0),"hasTotals":(depth0 != null ? lookupProperty(depth0,"sectionHasTotals") : depth0),"theme":"panel-primary","collapsed":false,"label":(depth0 != null ? lookupProperty(depth0,"description") : depth0)},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":47,"column":32}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div data-region=\"accountsGridRegion"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":46,"column":52},"end":{"line":46,"column":58}}}) : helper)))
    + "\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page bankAccountSummary\">\n    <div class=\"section section-container account-summary-container\">\n        <div class=\"reporting-nav-btn-container\">\n            <div class=\"row\">\n                <div class=\"col-6\">\n                    <div class=\"alerts\"></div>\n                </div>\n                <div class=\"col-6 text-right\">\n                    <div class=\"account-summary-button-row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNotMobile") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":25,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"getTotalsWarning") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":33,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sections") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":48,"column":17}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"useData":true});