export default ({ breakpoints }) => ({
    input: {
        fontWeight: 'normal',
        margin: 0,
        minWidth: '100%',
        '& p': {
            margin: 0,
        },
        [`@media (min-width: ${breakpoints.values.md}px)`]: {
            minWidth: '330px',
        },
    },
    wrapper: {
        alignItems: 'flex-start',
        display: 'block',
        [`@media (min-width: ${breakpoints.values.md}px)`]: {
            display: 'flex',
        },
    },
    helpText: {
        marginBottom: '5px',
    },
    button: {
        padding: '9px 12px',
        [`@media (max-width: ${breakpoints.values.md}px)`]: {
            width: '100%',
        },
    },
});
