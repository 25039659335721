var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"button-row\"></div>\n<div id=\"acctSummary-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":21},"end":{"line":2,"column":27}}}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\">\n        <div class=\"panel-body\">\n                <div class=\"alert-region\" data-region=\"alertRegion\"></div>\n\n                <div class=\"btn-wrapper\">\n                "
    + ((stack1 = (lookupProperty(helpers,"gridUtilitySection")||(depth0 && lookupProperty(depth0,"gridUtilitySection"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gridUtilityOptions") : depth0),{"name":"gridUtilitySection","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":59}}})) != null ? stack1 : "")
    + "\n                </div>\n                <div class=\"summary-body\">\n                        <div data-region=\"tableContent\" aria-live=\"polite\"></div>\n                </div>\n          </div>\n</div>\n";
},"useData":true});