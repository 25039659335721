var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form>\n        <fieldset>\n            <legend class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.complete.billpay.info.msg",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":78}}}))
    + "</legend>\n            <div class=\"row\">\n\n                <div class=\"col-3 required\">\n                    <label for=\"BENE_BANK_ID\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.billerLookup",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":75}}}))
    + "</label>\n                        <input id=\"BENE_BANK_ID\" type=\"text\" class=\"form-control biller-select\" name=\"BENE_BANK_ID\"  placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.billerPlaceholder",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":130},"end":{"line":9,"column":164}}}))
    + "\" maxlength=\"35\" data-bind=\"model\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_BANK_ID\"></span>\n                </div>\n                <div class=\"col-2\">\n                    "
    + alias2(lookupProperty(helpers,"icon").call(alias1,{"name":"icon","hash":{"className":"btn btn-tertiary clear-billpay-btn","action":"clearBillPayee","text":"Clear Bill Payee","name":"close"},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":136}}}))
    + "\n                </div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-3 required\">\n                    <label for=\"BENE_ACCOUNTNUMBER\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.accountWithBiller",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":52},"end":{"line":19,"column":86}}}))
    + "</label>\n                    <input id=\"BENE_ACCOUNTNUMBER\" class=\"form-control account-with-biller\" type=\"text\" name=\"BENE_ACCOUNTNUMBER\"  placeholder=\""
    + alias2(lookupProperty(helpers,"locale").call(alias1,"bab.accountPlaceholder",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":20,"column":144},"end":{"line":20,"column":179}}}))
    + "\" maxlength=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"fieldTypeData") : depth0)) != null ? lookupProperty(stack1,"BENE_ACCOUNTNUMBER") : stack1)) != null ? lookupProperty(stack1,"maxLen") : stack1), depth0))
    + "\" data-bind=\"model\"/>\n                    <span class=\"help-block\" data-bind=\"model\" data-validate=\"BENE_ACCOUNTNUMBER\"></span>\n                </div>\n                <div class=\"col-2\">\n                    <label for=\"findAcountImage\" class=\"invisible\">placeholder</label>\n                    <button id=\"findAcountImage\" type=\"button\" class=\"btn btn-link btn-tertiary account-statement-image\" data-action=\"findAcountImage\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"RTGS.INST.BPAY.*.FINDACCOUNTIMAGE.label",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":151},"end":{"line":25,"column":203}}}))
    + "</button>\n                </div>\n            </div>\n        </fieldset>\n    </form>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"panel")||(depth0 && lookupProperty(depth0,"panel"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"panel","hash":{"theme":"panel-primary","collapsed":true,"locale":"bab.billpay.info"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":30,"column":10}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});