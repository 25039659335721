import util from '@glu/core/src/util';
import PanelLayout from './validationPanel';
import personViewTmpl from './personView.hbs';

const PersonView = PanelLayout.extend({
    template: personViewTmpl,
    tagName: 'fieldset',
    additionalEmails: ['BENE_EMAIL2', 'BENE_EMAIL3', 'BENE_EMAIL4', 'BENE_EMAIL5'],

    getWhiteList() {
        return ['BENE_CONTACT_NAME', 'BENE_EMAIL1', 'BENE_EMAIL2', 'BENE_EMAIL3', 'BENE_EMAIL4', 'BENE_EMAIL5', 'BENE_PHONENUMBER', 'BENE_PHONE_MOBILE', 'BENE_FAXNUMBER'];
    },

    ui: {
        $beneEmail: '[data-hook="BENE_EMAIL"]',
        $addBeneEmailLink: 'button[data-hook="BENE_EMAIL"]',
        $beneContactName: 'input[name="BENE_CONTACT_NAME"]',
        completeIcon: '.complete-icon',
    },

    events: {
        'change @ui.$beneEmail': 'validateBeneEmail',
        'click @ui.$addBeneEmailLink': 'showAdditionalEmail',
        'change @ui.$beneContactName': 'validateBeneContactName',
    },

    validateBeneEmail(e) {
        this.model.validateField(e.target.name);
    },

    /**
     * Callback for the button link shown below the main Address field
     * As the user clicks on the 'add address line' link, the other address
     * lines are made visible (one at a time)
     * @param {event} e - The event triggered.
     *
     */
    showAdditionalEmail(e) {
        let fieldNeeded = true;
        let count = 0;
        const self = this;
        e.stopImmediatePropagation();
        /*
         * additionalAddressFields are the other Address Lines that are made
         * visible as the user clicks on the 'add address line' link
         */

        util.each(self.additionalEmails, (field) => {
            if (self.$(`[name=${field}]`).parent().hasClass('hide') && fieldNeeded) {
                self.$(`[name=${field}]`).parent().removeClass('hide');
                fieldNeeded = false;
            }
            if (self.$(`[name=${field}]`).parent().hasClass('hide')) {
                count += 1;
            }
        });

        /*
         * if there are no more address lines left to be displayed,
         * let's hide the 'add address line' link
         */
        if (count > 0) {
            this.ui.$addBeneEmailLink.parent().removeClass('hide');
        } else {
            this.ui.$addBeneEmailLink.parent().addClass('hide');
        }
    },

    validateBeneContactName() {
        this.model.validateField('BENE_CONTACT_NAME');
    },

    templateHelpers() {
        const { model } = this;
        // allowBeneEmails made true always to have the Add email link available
        // on the Contact ceter screen instead of entitling through ALLOWBENENOTIFICTIONSWIRES flag
        // const allowBeneEmails = model.get('allowBeneEmails');
        const allowBeneEmails = true;
        const hideEmail5 = !allowBeneEmails || !model.has('BENE_EMAIL5') || model.get('BENE_EMAIL5') === '';
        const hideEmail4 = hideEmail5 && (!allowBeneEmails || !model.has('BENE_EMAIL4') || model.get('BENE_EMAIL4') === '');
        const hideEmail3 = hideEmail4 && (!allowBeneEmails || !model.has('BENE_EMAIL3') || model.get('BENE_EMAIL3') === '');
        const hideEmail2 = hideEmail3 && (!allowBeneEmails || !model.has('BENE_EMAIL2') || model.get('BENE_EMAIL2') === '');

        return {
            showEmailToolTip: allowBeneEmails && this.state !== 'VIEW',
            showAddEmailLink: allowBeneEmails && hideEmail5,
            hideEmail2,
            hideEmail3,
            hideEmail4,
            hideEmail5,
            fieldTypeData: this.fieldTypeData,
        };
    },
});

export default PersonView;
