import OutOfBandModel from 'system/mfa/models/outOfBand';
import SelectContactMethodMFAView from 'system/mfa/views/oneTimePassword/selectContactMethod';
import util from '@glu/core/src/util';

export default SelectContactMethodMFAView.extend({
    initialize(options) {
        const modelAttrs = {
            challengedAction: options.challengedAction,
        };

        if (options.request && options.request.data
            && options.request.data.userId && options.request.data.userGroup) {
            util.extend(
                modelAttrs,
                {
                    userRequestingOnBehalfOf: {
                        userGroup: options.request.data.userGroup,
                        userId: options.request.data.userId,
                    },
                },
            );
        }

        this.model = new OutOfBandModel(modelAttrs);
    },

    completion() {
        // let the form know to update the checkbox
        this.appBus.trigger('adminMfa:success', this.options.request.data);
        this.closeModal();
    },

    cancel() {
        // no need to resubmit a cancellation header
        this.closeModal();
    },
});
