import Glu from '@glu/core/src/glu';
import Layout from '@glu/core/src/layout';
import dialog from '@glu/dialog';
import locale from '@glu/locale';
import PageApi from 'common/dynamicPages/api/view';
import AdminUtil from 'app/administration/common/util';
import constants from 'app/administration/constants';
import loadingWidgetTmpl from 'common/templates/loadingWidget.hbs';
import userInfo from 'etc/userInfo';
import registerFormTmpl from './registerForm.hbs';

export default Layout.extend({
    template: registerFormTmpl,
    loadingTemplate: loadingWidgetTmpl,

    regions: {
        pageRegion: '.pageRegion',
    },

    events: {
        'click [data-hook="getSubmitBtn"]': 'handleSubmit',
        'click [data-hook="getCancelBtn"]': 'handleCancel',
    },

    initialize(options) {
        this.options = options;
        let service = '/checkFree';
        if (this.options?.options?.vendorCode === 'CFSMB') {
            service = '/checkFreeSmallBusiness';
        }
        this.context = {
            functionCode: 'MAINT',
            productCode: 'SSOOUT',
            typeCode: `${this.options?.options?.vendorCode}FM`,
            entrymethod: '0',
            serviceName: service,
        };
    },

    loadViewRequirements() {
        const self = this;
        self.setHasLoadedRequiredData(true);
        self.render();
    },

    onRender() {
        const self = this;
        if (self.hasLoadedRequiredData()) {
            const options = {
                context: self.context,
                hideButtons: true,
                state: constants.MODES.INSERT,
                functionCode: 'MAINT',
                productCode: 'SSOOUT',
                typeCode: `${this.options?.options?.vendorCode}FM`,
                viewType: 'default',
                mode: constants.MODES.INSERT,
            };
            self.pageView = PageApi.page.get(options);
            self.pageRegion.show(self.pageView);
        } else {
            self.loadViewRequirements();
        }
    },

    handleCancel() {
        dialog.confirm(locale.get('SSOOUT.MAINT.checkfree.enrollment.cancel.message'), 'Warning', (ok) => {
            if (ok) {
                this.navigateTo(this.options?.currentView?.returnRoute);
            }
        });
    },

    handleSubmit() {
        const self = this;

        self.pageView.model.save(
            {},
            {
                success() {
                    if (self.context.typeCode === 'CFSMBFM') {
                        userInfo.setCheckfreeAuthLevel('Level1');
                    }
                    Glu.Backbone.history.loadUrl(Glu.Backbone.history.fragment);
                },

                error(response) {
                    AdminUtil.errorAlert(
                        self.alertRegion,
                        response.error.confirms.confirmResults[0].messages[0],
                    );
                },
            },
        );
    },
});
