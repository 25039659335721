import Layout from '@glu/core/src/layout';
import locale from '@glu/locale';
import template from './currentPriorDay.hbs';
import mobileUtil from '../../../mobile/util/mobileUtil';

const currentDayOnly = [
    'SWMT942',
];

const priorDayOnly = [
    'SWMT940',
    'QUICKBKS',
    'QUICKEN',
];

export default Layout.extend({
    template,

    ui: {
        tooltip: '[data-hook="getTooltip"]',
    },

    initialize() {
        const exportType = this.model.get('EXPORTTYPECODE');
        const outputContentType = this.model.get('OUTPUTCONTENT');
        this.listenTo(this.model, 'change:EXPORTTYPECODE', this.updateCurrentDay);
        this.listenTo(this.model, 'change:CURRENTDAY', this.updateIncrementalExport);
        this.listenTo(this.model, 'change:OUTPUTCONTENT', this.shouldDisableIncrementalExport);
        this.disableCurrentDay = this.shouldDisableCurrentDay(exportType);
        this.disableIncrementalExport = this.shouldDisableIncremental(
            exportType,
            outputContentType,
        );
        this.showIncrementalExport = this.shouldShowIncrementalExport(this.model);
    },

    /**
     * Evaluate the model to determine if the incremental export options
     * should be shown
     * @param {Object} model
     * @returns {boolean}
     */
    shouldShowIncrementalExport(model) {
        return model.get('CURRENTDAY');
    },

    /**
     * Evaluate the expertTypeCode to determine if current day selection should
     * be disabled
     * @param {string} exportTypeCode - export type code
     * @returns {boolean}
     */
    shouldDisableCurrentDay(exportTypeCode) {
        return priorDayOnly.includes(exportTypeCode) || currentDayOnly.includes(exportTypeCode);
    },

    /**
     * Disable "Incremental" option when export type is BAI and
     * output content is "Balances Only"
     * @param {string} _ - ignored
     * @param {string} outputContentType - export type code
     */
    shouldDisableIncrementalExport(_, outputContentType) {
        const exportType = this.model.get('EXPORTTYPECODE');
        this.updateIncrementalExportStatus(exportType, outputContentType);
    },

    /**
     * Update the CURRENTDAY value of the model and check if
     * the currentDay radio buttons should be disabled
     * @param {Object} model - model to evaluate
     * @param {string} value - export type code
     */
    updateCurrentDay(model, value) {
        let currentDay = true;
        const outputContentType = this.model.get('OUTPUTCONTENT');
        if (currentDayOnly.includes(value)) {
            model.set('CURRENTDAY', currentDay);
        } else if (priorDayOnly.includes(value)) {
            currentDay = false;
            model.set('CURRENTDAY', currentDay);
        } else {
            currentDay = model.get('CURRENTDAY');
        }
        this.disableCurrentDay = this.shouldDisableCurrentDay(value);
        this.updateIncrementalExport(model, currentDay);
        this.updateIncrementalExportStatus(value, outputContentType);
    },

    onRender() {
        this.ui.tooltip.popover({
            content: locale.get('GIR.Incremental.Full.Replacement.Tooltip'),
        });
    },

    /**
     * Update incremenental vs full replacement radio button status
     * and render the view
     * @param {Object} model - model to evaluate
     * @param {boolean|string} currentDay - whether current day is checked
     */
    updateIncrementalExport(model, currentDay) {
        if (!currentDay || currentDay === 'false') {
            model.set('INCREMENTALEXPORT', false);
            // can definitely set this to false so it is not shown
            this.showIncrementalExport = false;
        } else {
            this.showIncrementalExport = this.shouldShowIncrementalExport(model);
        }
        this.render();
    },

    /**
     * Update incremenental status to enable/disable the checkbox
     * and render the view
     * @param {string} exportType - export type
     * @param {string} outputContentType - output content type
     */
    updateIncrementalExportStatus(exportType, outputContentType) {
        this.disableIncrementalExport = this.shouldDisableIncremental(
            exportType,
            outputContentType,
        );
        // If incremental export checkbox is disabled, toggle the Full Replacement
        this.model.set('INCREMENTALEXPORT', !this.disableIncrementalExport);
        this.render();
    },

    /**
     * Helper method to enable/disable the incremenental checkbox
     * @param {string} exportType - export type
     * @param {string} outputContentType - output content type
     * @returns {boolean}
     */
    shouldDisableIncremental(exportType, outputContentType) {
        const balanceOnlyContentType = 'GIRBAL';
        return exportType === 'BAIEXP' && outputContentType === balanceOnlyContentType;
    },

    templateHelpers() {
        return {
            disableCurrentDay: this.disableCurrentDay,
            showIncrementalExport: this.showIncrementalExport,
            disableIncrementalExport: this.disableIncrementalExport,
            tooltipPlacement() {
                return mobileUtil.isScreenSizeOrLess(625)() ? 'left' : 'right';
            },
        };
    },
});
