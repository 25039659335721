/* eslint-disable max-len */
// File created to avoid duplication of actions from DGB/MDF based actions (src\app\payments\actions.js)
import React from 'react';
import locale from '@glu/locale';
import dialog from '@glu/dialog';
// import { util } from '@glu/core';
import ModalView from '@glu/dialog/src/views/modalView';
import { isDeepLinked } from 'common/util/deeplinkUtil';
import gluStore from '@glu/store';
import util from 'ptx/system/util';
import { navigateTo } from 'common/util/navigationUtils';
import ContentLoading from 'pcm/common/components/ContentLoading';
import { ptxController } from 'pcm/common/ptx-connect-integration/ptxConnect';
import { appendPtxCss,
    getAdminAccessTokenPromise,
    getAccessTokenPromise,
    getTemporaryAdminAccessTokenPromise,
    setPrinterTypeAccess,
    isPrinterAccessClient } from 'pcm/common/ptx-connect-integration/ptxConnectUtils';
import PrinterQueue from 'ptx/app/connect/views/printerQueue/top';
import Apps from 'ptx/app/connect/views/apps/appsLayout';
import App from 'ptx/app/connect/views/appViewer/appViewerLayout';
import Projects from 'ptx/system/views/projects/top';
import Project from 'ptx/system/views/projectViewer/top';
import Chequebooks from 'ptx/app/connect/views/chequebooks';
import Chequebook from 'ptx/app/connect/views/chequebookViewer';
import Roles from 'ptx/app/connect/views/roles/roles';
import Role from 'ptx/app/connect/views/roleViewer/role';
// import Profiles from 'ptx/app/connect/views/profiles/profilesLayout'; // old ptx profile
import ProfileLogs from 'ptx/app/connect/views/profileViewer/logs/top';
import Profile from 'ptx/app/connect/views/profileViewer/profile';
import DocumentProfiles from 'ptxReact/app/client/pages/documentProfiles/documentProfiles'; // react-screen
import DocumentProfile from 'ptxReact/app/client/pages/documentProfile/documentProfile'; // react-screen
import currentUser from 'ptxReact/system/currentUser';
import { registerDefaultsForModule } from '@glu/theming';
import legacy from 'pcm/app/ptx-connect-react/themesInput/legacyTheme';
import ash from 'pcm/app/ptx-connect-react/themesInput/ashTheme';
import Outbox from 'ptx/app/connect/views/outbox/outbox';
import StepUserWrapper from './components/StepUserWrapper';
import Printer from './views/printerViewer/top';

registerDefaultsForModule(
    'formComponents', ({ name, palette, typography }) => {
        const commonTheme = {
            error: {
                fontFamily: typography.fontFamily,
                fontSize: 12,
                color: palette.form.error,
                borderColor: palette.form.error,
                fontWeight: 'normal',
            },
            warning: {
                color: palette.error,
                borderColor: palette.error,
            },
        };

        switch (name) {
        case 'ash':
            return {
                ...commonTheme,
                ...ash({ palette, typography }),
            };
        default:
            return {
                ...commonTheme,
                ...legacy({ palette, typography }),
            };
        }
    },
);


/**
 * Error Handler - helper function, similar for all below actions.
 *
 * @param statusText
 * @param responseJSON
 * @param responseText
 */
// const errorHandler = ({ statusText, responseJSON, responseText }) => {
const errorHandler = (title) => {
    // const msg = `${statusText} - "${typeof responseJSON === 'string' ? responseJSON : responseText}"`;
    // const msg = locale.get('ptx.error.generic', title);
    navigateTo('');
    const div = `<div class="row">
            <span class="sda-warning icon-exclamation-solid left"></span>
            <span class="col-10">${locale.get('ptx.error.generic', title)}</span>
        </div>`;

    const popup = new ModalView({
        type: 'alert',
        modalClass: 'modal-lg',
        buttons: [{
            text: locale.get('ok'),
            className: 'btn-primary',
            callback() {
                this.close();
            },
        }],
    });
    dialog.custom(popup);
    popup.$el.find(popup.ui.body).append(div);
    return title;
};

const ptxConnectActions = {
    // PT-X Connect related workaround - many PT-X Connect actions redirects to forbidden()
    forbidden() {}, // at lest for "new Batch" it helps. That will be hidden in Next sprint.
    // cheques: ptxController.cheques, // In case of exact actions ONLY

    ...ptxController, // This work, if object is taken from prototype.

    outbox(queryString) {
        const title = locale.get('outbox');
        const userGroup = gluStore.get('ptxUserGroup');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise(userGroup).then(() => {
            appendPtxCss.apply();

            const queryParams = util.omit(util.parseQueryString(queryString), 'r');
            this.showPage(
                locale.get('outbox'),
                new Outbox({
                    queryParams,
                }), {
                    backButton: true,
                    defaultTitle: locale.get('outbox'),
                },
            );
        }, () => errorHandler(title));
    },

    profilesWithUser(userGroup) {
        const title = locale.get('mailingProfiles');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise(userGroup).then((token) => {
            appendPtxCss.apply();

            // this.showPage(title, new Profiles());

            currentUser.set('httpHeaders', {
                Authorization: `Bearer ${token.accessToken}`,
            });
            this.showPage(
                title,
                <DocumentProfiles />,
            );
        }, () => errorHandler(title));
    },

    profiles() {
        const title = locale.get('mailingProfiles');
        this.showPage(title, <ContentLoading />);

        appendPtxCss.apply();

        this.showPage(
            title,
            <StepUserWrapper callback={(userGroup) => {
                this.profilesWithUser(userGroup);
            }}
            />, {
                backButton: true,
                defaultTitle: title,
            },
        );
    },

    profileViewer(type, profile, tab) {
        const userGroup = gluStore.get('ptxUserGroup');
        this.showPage(locale.get('mailingProfiles'), <ContentLoading />);

        // PCM-PTX old profile creation needs this, for now leave it here until new ptx is done
        // if (profile === 'create') {
        //   profile = undefined; // eslint-disable-line
        // }

        tab = tab ? tab.replace(/\w*=\w*/, '') : tab; // eslint-disable-line

        if (tab === 'logs') {
            getAdminAccessTokenPromise(userGroup).then(() => {
                appendPtxCss.apply();

                this.showPage(
                    locale.get('profileLogs'),
                    new ProfileLogs({
                        profile,
                    }), {
                        backButton: true,
                        backUrl: '/ui-admin-portal/ADMINSTRATION/documents/profiles',
                        defaultTitle: locale.get('profileLogs'),
                    },
                );
            }, () => errorHandler(''));
            return;
        }

        let profilePage = null;

        getAdminAccessTokenPromise(userGroup).then((token) => {
            appendPtxCss.apply();
            profilePage = new Profile({
                profile,
                type,
                tab: tab !== 'undefined' ? tab : undefined,
            });

            delete profilePage.model.defaultValidators.layoutBranchId;
            delete profilePage.model.defaultValidators.logoFileId;

            currentUser.set('httpHeaders', {
                Authorization: `Bearer ${token.accessToken}`,
            });
            currentUser.documentRetentionEnabled = false;
            const Document = DocumentProfile;
            // window.history.pushState = () => {};
            window.onscroll = () => {};
            this.showPage(
                locale.get('mailingProfiles'),
                <Document match={{ params: { type, profile } }} currentUser />,
                {
                    backButton: true,
                    defaultTitle: locale.get('mailingProfiles'),
                },
            );
        }, () => errorHandler(locale.get('mailingProfiles')));
    },


    rolesWithUser(userGroup) {
        const title = locale.get('types.CFDROL');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise(userGroup).then(() => {
            appendPtxCss.apply();

            this.showPage(
                title,
                new Roles(),
            );
        }, () => errorHandler(title));
    },

    clientRoles() {
        const title = locale.get('menuitem.client.doc.permission');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise('', true).then(() => {
            appendPtxCss.apply();

            this.showPage(
                title,
                new Roles(),
            );
        }, () => errorHandler(title));
    },

    roles() {
        const title = locale.get('types.CFDROL');
        this.showPage(title, <ContentLoading />);

        appendPtxCss.apply();

        this.showPage(
            title,
            <StepUserWrapper callback={(userGroup) => {
                this.rolesWithUser(userGroup);
            }}
            />, {
                backButton: true,
                defaultTitle: title,
            },
        );
    },
    
    clientRoleViewer(id) {
        const create = id === 'create';

        if (create) {
            id = undefined; // eslint-disable-line
        }

        this.showPage(locale.get('type.DOCPERM'), <ContentLoading />);

        getAdminAccessTokenPromise('', true).then(() => {
            appendPtxCss.apply();

            this.showPage(
                locale.get('type.DOCPERM'),
                new Role({
                    id,
                }), {
                    backButton: true,
                },
            );
        }, () => errorHandler(locale.get('type.DOCPERM')));
    },

    roleViewer(id) {
        const userGroup = gluStore.get('ptxUserGroup');
        const create = id === 'create';

        if (create) {
            id = undefined; // eslint-disable-line
        }

        this.showPage(locale.get('types.CFDROL'), <ContentLoading />);

        getAdminAccessTokenPromise(userGroup).then(() => {
            appendPtxCss.apply();

            this.showPage(
                locale.get('types.CFDROL'),
                new Role({
                    id,
                }), {
                    backButton: true,
                },
            );
        }, () => errorHandler(locale.get('types.CFDROL')));
    },

    chequebooksWithUser(userGroup) {
        const title = locale.get('chequebooks');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise(userGroup).then(() => {
            appendPtxCss.apply();

            this.showPage(
                title,
                new Chequebooks(),
            );
        }, () => errorHandler(title));
    },

    chequebooks() {
        const title = locale.get('chequebooks');
        this.showPage(title, <ContentLoading />);

        appendPtxCss.apply();

        this.showPage(
            title,
            <StepUserWrapper callback={(userGroup) => {
                this.chequebooksWithUser(userGroup);
            }}
            />, {
                backButton: true,
                defaultTitle: title,
            },
        );
    },

    chequebookViewer(id) {
        const userGroup = gluStore.get('ptxUserGroup');
        const title = locale.get('chequebooks');

        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise(userGroup).then(() => {
            appendPtxCss.apply();

            this.showPage(
                title,
                new Chequebook({
                    id: id === 'create' ? undefined : id,
                }), {
                    backButton: true,
                },
            );
        }, () => errorHandler(title));
    },

    projectViewer(projectId) {
        const create = projectId === 'create';
        const userGroup = gluStore.get('ptxUserGroup');
        const title = create ? 'newProject' : 'updateProject';

        this.showPage(locale.get(title), <ContentLoading />);

        if (create) {
            projectId = undefined; // eslint-disable-line
        }

        getAdminAccessTokenPromise(userGroup).then(() => {
            appendPtxCss.apply();

            this.showPage(
                locale.get(title),
                new Project({
                    projectId,
                    isBrand: false,
                }), {
                    backButton: true,
                    defaultTitle: locale.get(title),
                },
            );
        }, () => errorHandler(locale.get('projects')));
    },

    projectsWithUser(userGroup) {
        this.showPage(locale.get('projects'), <ContentLoading />);

        getAdminAccessTokenPromise(userGroup).then(() => {
            appendPtxCss.apply();

            this.showPage(
                locale.get('projects'),
                new Projects(),
            );
        }, () => errorHandler(locale.get('projects')));
    },

    projects() {
        const title = locale.get('projects');
        this.showPage(title, <ContentLoading />);

        appendPtxCss.apply();

        this.showPage(
            title,
            <StepUserWrapper callback={(userGroup) => {
                this.projectsWithUser(userGroup);
            }}
            />, {
                backButton: true,
                defaultTitle: title,
            },
        );
    },

    appViewer(app) {
        this.showPage(locale.get('apps'), <ContentLoading />);

        const create = app === 'create';

        if (create) {
            app = undefined; // eslint-disable-line
        }

        getAdminAccessTokenPromise('', true).then(() => {
            appendPtxCss.apply();

            this.showPage(
                locale.get(create ? 'addApp' : 'updateApp'),
                new App({
                    app,
                }), {
                    backButton: true,
                    defaultTitle: locale.get('addApp'),
                    backUrl: `/${isDeepLinked() ? 'ui-portal' : 'ui'}/ADMINSTRATION/documents/apps`,
                },
            );
        }, () => errorHandler(locale.get('apps')));
    },

    apps() {
        const title = locale.get('apps');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise('', true).then((token) => {
            appendPtxCss.apply();

            this.showPage(
                title,
                new Apps(),
            );
        }, () => errorHandler(title));
    },

    clientPrinters() {
        const title = locale.get('menuitem.client.ptx.printers.queue');
        this.showPage(title, <ContentLoading />);
        getAccessTokenPromise().then(() => {
            appendPtxCss.apply();
            setPrinterTypeAccess('client');

            this.showPage(
                title,
                new PrinterQueue({
                    isAdminQueue: true,
                }),
            );
            getTemporaryAdminAccessTokenPromise('', true);
        }, () => errorHandler(title));
    },

    printers() {
        const title = locale.get('menuitem.ptx.printers.queue');
        this.showPage(title, <ContentLoading />);
        setPrinterTypeAccess('admin');

        const isPreviousAccessClient = isPrinterAccessClient();
        if (isPreviousAccessClient) {
            this.clientPrinters();
            return;
        }
        getAdminAccessTokenPromise('', true).then(() => {
            appendPtxCss.apply();
            this.showPage(
                title,
                new PrinterQueue({
                    isAdminQueue: true,
                }),
            );
        }, () => errorHandler(title));
    },

    adminPrinters() {
        const title = locale.get('menuitem.ptx.printers.queue');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise('', true).then(() => {
            appendPtxCss.apply();
            setPrinterTypeAccess('admin');

            this.showPage(
                title,
                new PrinterQueue({
                    isAdminQueue: true,
                }),
            );
        }, () => errorHandler(title));
    },

    printerQueue() {
        const title = locale.get('printerQueue');
        this.showPage(title, <ContentLoading />);

        getAdminAccessTokenPromise('', true).then(() => {
            appendPtxCss.apply();

            this.showPage(
                title,
                new PrinterQueue(),
            );
        }, () => errorHandler(title));
    },

    printerViewer(printerId) {
        this.showPage(locale.get('printer'), <ContentLoading />);

        getAdminAccessTokenPromise('', true).then(() => {
            appendPtxCss.apply();

            this.showPage(
                locale.get('printer'),
                new Printer({
                    printerId,
                }), {
                    backButton: true,
                    backUrl: `/${isDeepLinked() ? 'ui-portal' : 'ui'}/ADMINSTRATION/documents/printers`,
                    defaultTitle: locale.get('printer'),
                },
            );
        }, () => errorHandler(locale.get('printer')));
    },

};

export default ptxConnectActions;

