var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button\n                        class=\"btn btn-secondary\"\n                        data-action=\"acceptDisclosure\"\n                        type=\"button\">\n                        "
    + container.escapeExpression(lookupProperty(helpers,"locale").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.acceptDisclosure",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":60}}}))
    + "\n                    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog print-modal modal-2xlg glu-dialog\">\n    <form\n        autocomplete=\"off\"\n        class=\"modal-content print-modal-content\"\n        data-hook=\"getModalContent\">\n\n        <div class=\"modal-header\">\n            <button class=\"close\"\n                    data-action=\"close\"\n                    type=\"button\">\n                <span class=\"icon-close\"\n                      title=\"Close\">\n                </span>\n            </button>\n            <h2 class=\"modal-title\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":36},"end":{"line":15,"column":52}}}))
    + "</h2>\n        </div>\n\n        <div\n            class=\"modal-body\"\n            data-hook=\"getModalBody\">\n            <div class=\"row\">\n                <div\n                    class=\"col-md-12\"\n                    data-hook=\"getAlertRegion\"\n                    id=\"alertRegion\">\n                </div>\n            </div>\n            <div class=\"row report-row\">\n                <div data-region=\"content\" class=\"reportmodal\" aria-live=\"polite\"></div>\n            </div>\n        </div>\n        <div>\n            <div data-region=\"export\" aria-live=\"polite\"></div>\n        </div>\n\n        <div class=\"modal-footer\">\n\n            <div\n                class=\"form-group\"\n                data-hook=\"getBtnContainer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isApprovalDisclosure") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":48,"column":23}}})) != null ? stack1 : "")
    + "                <button\n                    class=\"btn btn-secondary\"\n                    data-action=\"close\"\n                    type=\"button\">\n                    "
    + alias2(lookupProperty(helpers,"locale").call(alias1,"common.cancel",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":53,"column":20},"end":{"line":53,"column":46}}}))
    + "\n                </button>\n            </div>\n\n        </div>\n    </form>\n</div>\n\n\n";
},"useData":true});