import autobooksLib from 'app/utilities/sso/autobooks';
import Layout from '@glu/core/src/layout';
import Model from '@glu/core/src/model';
import alert from '@glu/alerts';
import $ from 'jquery';
import dialog from '@glu/dialog';
import store from 'system/utilities/cache';
import ItemSelectionView from 'app/utilities/sso/itemSelectionView';
import template from './sso.hbs';

export default Layout.extend({
    template,
    className: 'dynamic-full-page',

    regions: {
        alertRegion: '#alertRegion',
    },

    initialize(options) {
        this.loginResponse = options.loginResponse;
        this.model = new Model({
            loginResponse: options.loginResponse,
            menuContext: options.menuctx,
        });
        this.listenTo(this.appBus, 'router:navigate:ssoStaticView', this.processMessage);
    },

    processMessage(evt) {
        const route = evt.data.route.split('/');
        if (route.indexOf('SSOLandingPage') !== -1) {
            this.navigateTo(`SSOLandingPage/${route[route.indexOf('SSOLandingPage') + 1]}`);
        } else {
            this.navigateTo('SSOLandingPage/noStaticSSOPageFound');
        }
    },

    onShow() {
        if (this.options.loginResponse.dialog) {
            this.itemSelectionDialog = new ItemSelectionView({
                isModal: true,
                loginResponse: this.options.loginResponse,
                menuctx: this.options.menuctx,
            });
            this.listenTo(this.itemSelectionDialog, 'itemSelected', this.retryLogin);
            dialog.open(this.itemSelectionDialog);
        } else if (!this.loginResponse.success) {
            const msg = alert.danger(this.loginResponse.errorMsg);
            this.alertRegion.show(msg);
        } else {
            $('#ssoForm').submit();
        }

        if (this.loginResponse.vendorCode) {
            this.vendorSpecificProcessing();
        }
    },

    vendorSpecificProcessing() {
        if (this.loginResponse.vendorCode === 'Autobooks') {
            autobooksLib.initAutoBooksKeepAliveConnect();
        }
    },

    retryLogin(model) {
        const params = model;
        params.push({
            name: 'mode',
            value: this.loginResponse.dialog,
        });
        store.set('ssoout_params', params);
        /*
         * We have already navigated to /PAYMENTS/ssout/IPAY, for example,
         * and it appears that controller won't let us re-navigate to the position
         * we are already in.  Hence the dummy route that will take us through the
         * same login process
         */
        this.navigateTo(`PAYMENTS/ssooutRetry/${this.options.menuctx.typeCode}`);
    },

    templateHelpers() {
        return {
            htmlContent() {
                return this.loginResponse.htmlContent;
            },

            postRedirect() {
                return this.loginResponse.postRedirect;
            },

            iframeSrc() {
                if (this.loginResponse.postRedirect) {
                    return null;
                }
                return this.loginResponse.redirectURL;
            },

            myForm() {
                let f = `<form id="ssoForm" action="${this.loginResponse.redirectURL}" method="post" target="ssoFrame">`;
                if (this.loginResponse.parameters) {
                    this.loginResponse.parameters.forEach((param) => {
                        f += `<input type="hidden" name="${param.first}" value="${param.second}">`;
                    });
                }
                f += '</form>';
                return f;
            },
        };
    },
});
