import $ from 'jquery';
import locale from '@glu/locale';
import Formatter from 'system/utilities/format';
import validatorPatterns from 'system/validatorPatterns';
import decimals from 'common/dynamicPages/api/decimals';

/**
 * Returns a rounded amount to the 100th decimal
 * @param {Number} amount - The amount to be rounded
 * @param {String} currency - The destination currency
 * @return {Number} - The rounded amount
 */
const roundAmount = (amount, currency) => {
    if (!amount || !currency) {
        return 0;
    }
    const power = 10 ** decimals.getNumberOfDecimalPlaces(currency);
    const roundedAmount = amount ? (Math.round(amount * power) / power) : amount;
    return roundedAmount;
};

export default function (form, initialState) {
    const relevantForm = form.formView.parentView || form.formView;
    const $allocationType = relevantForm.$('[name="ALLOCATION_TYPE"]');
    const $primaryAllocation = relevantForm.$('[name="ACCOUNT_ALLOCATION"]');
    const $primaryAccount = relevantForm.$('[name="ACCOUNTNUMBER"]');
    const $secondAccountAllocation = relevantForm.$('[name="SECOND_ACCOUNT_ALLOCATION"]');
    const $secondAccountAllocationCurrency = $secondAccountAllocation.siblings('.input-group-addon');
    const $secondAccountAllocationFieldContainer = $secondAccountAllocation.closest('.field-container');
    const $secondAccountAllocationHelpBlock = $secondAccountAllocationFieldContainer.find('.textline-field');
    const $secondAccountAllocationLabel = relevantForm.$('[for="SECOND_ACCOUNT_ALLOCATION"]');
    const $viewSecondAccountAllocationAmount = relevantForm.$('#SECOND_ACCOUNT_ALLOCATION >p');
    const $viewAccountAllocationAmount = relevantForm.$('#ACCOUNT_ALLOCATION >p >span');
    const $totalAmount = relevantForm.$('[name="AMOUNT"]');
    const $indvname = relevantForm.$('[name="INDVNAME"]');
    const { model } = form.formView;
    const formState = form.formView.state;
    const secondAccountAllocationFixedMaxLength = 17;
    const secondAccountAllocationPercentMaxLength = 4;
    /*
     * If a bene is created through strictly contact selection from the address book then
     * many fields will be 'protected' and its inputs will render differently when being
     * modified. These DOM references account for these values
     */
    const $protectedAccountType = $('[data-text="ACCOUNTTYPE"]');
    const $protectedSecondAccountType = $('[data-text="SECOND_ACCOUNTTYPE"]');
    const $protectedAllocationType = $('[data-text = "ALLOCATION_TYPE"]');
    const $protectedPrimaryAllocation = $('[data-text = "ACCOUNT_ALLOCATION"]');
    const $protectedSecondAccountAllocation = $('[data-text="SECOND_ACCOUNT_ALLOCATION"]');
    const $protectedSecondAccountAllocationCurrency = $protectedSecondAccountAllocation.next('.currency-field');

    let allocationType;

    /**
     * recalculates the amount to display in primary and secondary allocation
     * fields based on the allocation
     * percentage or fixed allocation amount. Also prevents over-allocating
     */
    const recalculateAllocations = (event) => {
        const totalAmountValue = event?.currentTarget?.value || Formatter.stringToNumber(model.get('AMOUNT'));
        const secondAccountAllocationValue = Formatter.stringToNumber(model.get('SECOND_ACCOUNT_ALLOCATION')) || 0;
        const $warning = $secondAccountAllocationFieldContainer.first('div').find('div.allocation-warning');
        const $warningDiv = `<div class="allocation-warning"><span class="icon-info-solid"></span> ${locale.get('PS.amountLessThanAllocation')}</div>`;

        if (model.get('PRENOTEFLAG') === '1') {
            $primaryAllocation.val(model.get('ACCOUNT_ALLOCATION'));
            return;
        }

        let primaryAllocationAmount;
        if (allocationType === 'Fixed') {
            // if the user is allocating all or more than the total to the secondary account
            primaryAllocationAmount = roundAmount((totalAmountValue - secondAccountAllocationValue), model.get('DESTCURRENCYCODE'));
            if (primaryAllocationAmount < 0) {
                primaryAllocationAmount = 0;
                if (totalAmountValue > 0) {
                    if ($warning.length === 0) {
                        $secondAccountAllocationFieldContainer.first('div').append($warningDiv);
                    }
                    $warning.show();
                } else {
                    $warning.hide();
                }
            } else if (allocationType === 'Percentage') { // the user has set a 'Percentage' allocation type
                // if the user is allocating all or more than the total to the secondary account
                $warning.hide();
                if (secondAccountAllocationValue >= 100) {
                    primaryAllocationAmount = 0;
                    $secondAccountAllocationHelpBlock.text('');
                } else {
                    const secondaryAllocationDecimal = (secondAccountAllocationValue / 100);
                    const secondaryAllocationAmount = secondaryAllocationDecimal * totalAmountValue;
                    // Round to the correct number of decimal positions.
                    const secondaryAmtRounded = Formatter.formatCurrency(secondaryAllocationAmount);
                    // Remove any commas from rounded amount to prevent NaN.
                    primaryAllocationAmount = roundAmount((totalAmountValue
                     - Formatter.unformatNumber(secondaryAmtRounded)), model.get('DESTCURRENCYCODE'));
                    $secondAccountAllocationHelpBlock.text(secondaryAllocationAmount ? `${Formatter.formatCurrency(secondaryAllocationAmount)} ${model.get('DESTCURRENCYCODE')}` : '');
                    $secondAccountAllocation.val(secondAccountAllocationValue);
                }
            } else {
                $warning.hide();
            }
        } else if (allocationType === 'Percentage') { // the user has set a 'Percentage' allocation type
            // if the user is allocating all or more than the total to the secondary account
            $warning.hide();
            if (secondAccountAllocationValue >= 100) {
                primaryAllocationAmount = 0;
                $secondAccountAllocationHelpBlock.text('');
            } else {
                const secondaryAllocationDecimal = (secondAccountAllocationValue / 100);
                const secondaryAllocationAmount = secondaryAllocationDecimal * totalAmountValue;
                // Round to the correct number of decimal positions.
                const secondaryAmtRounded = Formatter.formatCurrency(secondaryAllocationAmount);
                // Remove any commas from rounded amount to prevent NaN.
                primaryAllocationAmount = roundAmount((totalAmountValue - Formatter.unformatNumber(secondaryAmtRounded)), model.get('DESTCURRENCYCODE'));
                $secondAccountAllocationHelpBlock.text(secondaryAllocationAmount ? `${Formatter.formatCurrency(secondaryAllocationAmount)} ${model.get('DESTCURRENCYCODE')}` : '');
            }
        } else {
            return;
        }
        const uiPrimaryAllocationAmount = Formatter.formatNumberByCurrency(primaryAllocationAmount, model.get('DESTCURRENCYCODE'));
        $primaryAllocation.val(uiPrimaryAllocationAmount);
        if (this.shouldBeProtected && $protectedPrimaryAllocation.get(0)) {
            $protectedPrimaryAllocation.text($primaryAllocation.val());
        }
        /*
         * ensure the model keeps an accurate ACCOUNT_ALLOCATION
         * since it's set here rather than by the user
         * Update the model silently so that data-binding doesn't mess
         * with the input masks on mobile
         */
        model.set('ACCOUNT_ALLOCATION', primaryAllocationAmount, {
            silent: true,
        });
    };

    /**
     * Set amount in secondary account
     */
    const setSecondAccountValues = () => {
        const currentValue = model.get('SECOND_ACCOUNT_ALLOCATION') || 0;
        if (currentValue !== 0) {
            $secondAccountAllocation.val(currentValue);
        }
    };

    /**
     * Dependant upon the allocation type, kicks off recalculation and changes
     * inputs appearances/validation/masks
     */
    const handleAllocationTypeChange = (noRecalculate) => {
        // remove any currently existing validators regarding the account allocation field
        model.removeValidator('SECOND_ACCOUNT_ALLOCATION');

        // blank out second account allocation amount
        $secondAccountAllocationHelpBlock.text('');

        if ($allocationType.val() === '1' || (!$allocationType.val() && model.get('ALLOCATION_TYPE') === '1')) {
            /**
             * stores the current value, currently only applicable
             * if the form is being loaded with a preset value
             */
            allocationType = 'Percentage';
            // store the unformatted value of the allocation field
            const $secondAccountAllocationField = $secondAccountAllocation.get(0);
            const unmasked = $secondAccountAllocationField.inputmask?.unmaskedvalue()
                || $secondAccountAllocation.val();
            const unformattedValue = Formatter.stringToNumber(unmasked);

            $secondAccountAllocationLabel.text(locale.get('PAY.percentToAllocate'));
            $secondAccountAllocationCurrency.hide();
            $secondAccountAllocation.prop('maxLength', secondAccountAllocationPercentMaxLength);
            $secondAccountAllocation.inputmask({ mask: '999%', placeholder: '' });
            /*
             * this is done to prevent situations like '5.00' from becoming '500'
             *  when the input mask is changed
             */
            $secondAccountAllocation.val(unformattedValue);

            if (this.shouldBeProtected) {
                if ($protectedAllocationType.get(0)) {
                    $protectedAllocationType.text(locale.get('ACH.TMPL.payroll.Percentage'));
                }
                if ($protectedSecondAccountAllocation.get(0)) {
                    $protectedSecondAccountAllocation.text(`${model.get('SECOND_ACCOUNT_ALLOCATION')}%`);
                }
                if ($protectedSecondAccountAllocationCurrency.get(0)) {
                    $protectedSecondAccountAllocationCurrency.hide();
                }
            }

            if (!noRecalculate) {
                recalculateAllocations();
            }
            model.addValidator({
                SECOND_ACCOUNT_ALLOCATION: {
                    description: locale.get('ACH.payroll.SecondAccountAllocation'),
                    exists: true,
                    maxValue: 100,
                    minValue: 1,
                },
            });
        } else if ($allocationType.val() === '2' || (!$allocationType.val() && model.get('ALLOCATION_TYPE') === '2')) {
            allocationType = 'Fixed';
            $secondAccountAllocationLabel.text(locale.get('ACH.payroll.AccountAllocation'));
            $secondAccountAllocationCurrency.show();
            $secondAccountAllocation.addClass('amount-field');
            $secondAccountAllocation.prop('maxLength', secondAccountAllocationFixedMaxLength);
            $secondAccountAllocation.inputmask('number', { allowMinus: false });
            if (this.shouldBeProtected) {
                if ($protectedAllocationType.get(0)) {
                    $protectedAllocationType.text(locale.get('ACH.TMPL.payroll.FixedAmount'));
                }
            }

            if (!noRecalculate) {
                recalculateAllocations();
            }
            model.addValidator({
                SECOND_ACCOUNT_ALLOCATION: {
                    description: locale.get('ACH.payroll.SecondAccountAllocation'),
                    exists: true,
                    minValue: 1,
                    matches: validatorPatterns.AMOUNT_PATTERN,
                },
            });
        } else {
            $secondAccountAllocationFieldContainer.hide();
        }
    };

    if (initialState) {
        // if the formState is 'view' then appropriately format various values
        if (formState === 'view') {
            // if prenote is active, don't show account allocation
            if (model.get('PRENOTEFLAG') === '1') {
                form.field('ACCOUNT_ALLOCATION').shouldBeHidden(true);
            } else {
                $viewAccountAllocationAmount.text(`${Formatter.formatNumber(model.get('ACCOUNT_ALLOCATION'))} ${model.get('DESTCURRENCYCODE')}`);
                // display allocation as a percentage if applicable
                if (model.get('ALLOCATION_TYPE') === '1') {
                    $viewSecondAccountAllocationAmount.text(`${model.get('SECOND_ACCOUNT_ALLOCATION')} %`);
                }
            }
            if (model.get('ACCOUNTTYPEDESC') !== undefined) {
                $protectedAccountType.text(locale.getDefault(model.get('ACCOUNTTYPEDESC'), model.get('ACCOUNTTYPEDESC')));
            }
        } else {
            this.shouldBeProtected = $primaryAccount.prop('readonly');

            // if a bene created through contact book selection is being modified
            if (this.shouldBeProtected && $protectedAccountType.get(0) && model.get('ACCOUNTTYPEDESC') !== undefined) {
                $protectedAccountType.text(locale.getDefault(model.get('ACCOUNTTYPEDESC'), model.get('ACCOUNTTYPEDESC')));
            }

            if (this.shouldBeProtected && $protectedSecondAccountType.get(0) && model.get('SECOND_ACCOUNTTYPEDESC') !== undefined) {
                $protectedSecondAccountType.text(locale.getDefault(model.get('SECOND_ACCOUNTTYPEDESC'), model.get('SECOND_ACCOUNTTYPEDESC')));
            }

            // listener for secondary account interaction
            $allocationType.change(() => {
                $secondAccountAllocationFieldContainer.show();
                // reset both the second account allocation and main account_allocation fields
                $primaryAllocation.val('');
                $secondAccountAllocation.val('');
                handleAllocationTypeChange(false);
            });

            $allocationType.on('click', () => {
                model.set('SECOND_ACCOUNT_ALLOCATION', '');
            });

            $indvname.change(() => {
                setSecondAccountValues();
            });

            // listener for if a user with a secondary account is selected
            model.on('change:SECOND_BANKCODE', () => {
                if (this.shouldBeProtected) {
                    $secondAccountAllocationFieldContainer.show();
                    handleAllocationTypeChange(true);
                }
            });

            // listener for amount changes
            model.on('change:SECOND_ACCOUNT_ALLOCATION', () => {
                recalculateAllocations();
            });
            $totalAmount.change(recalculateAllocations);

            /*
             * kick off any recalculation needed in case the user already has a secondary
             * account established
             */
            handleAllocationTypeChange();

            /*
             * if this payment is being copied from a template, or is being modified and WAS copied
             * from a template at some point, then protect specific fields
             */
            if ((model.context?.copySource === 'template' || relevantForm.parentModel?.get('TEMPLATECODE'))
                && (model.context?.functionCode === 'BATCH' || model.context?.actionContext?.functionCode === 'BATCH')) {
                form.field('INDVNAME').shouldBeReadOnly(true);
                form.field('RECEIVABA').shouldBeReadOnly(true);
                form.field('ACCOUNTNUMBER').shouldBeReadOnly(true);
                form.field('ACCOUNTTYPE').shouldBeReadOnly(true);
                form.field('ALLOCATION_TYPE').shouldBeReadOnly(true);
                form.field('SECOND_BANKCODE').shouldBeReadOnly(true);
                form.field('SECOND_ACCOUNTNUMBER').shouldBeReadOnly(true);
                form.field('SECOND_ACCOUNTTYPE').shouldBeReadOnly(true);
                form.field('SECOND_ACCOUNT_ALLOCATION').shouldBeReadOnly(true);
                form.field('DISCDATA').shouldBeReadOnly(true);
                form.field('REMOVE_SECONDARY_LINK').shouldBeHidden(true);
            }
        }
    }
}

export { roundAmount };
