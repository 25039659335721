/**
 * PCM Application related appRoutes (based on Marionette.AppRouter)
 */
import achEsendManagementRoutes from './achEsendManagement/router';
import pcmAccountMaintenanceRoutes from './accountMaintenance/router';
import pcmCheckManagementRoutes from './checkManagement/router';
import ptxConnectRoutes from './ptx-connect/router';
import pcmCustomFieldsRoutes from './customFields/router';
import paymentsRoutes from './payments/router';
import workflowConfigRoutes from './workflowConfig/router';
import workflowLogRoutes from './workflowLog/router';
import messageLogRoutes from './messageLog/router';
import outsourcePrintProfileRoutes from './outsourcePrintProfile/router';
import ipLogonWhitelistRoutes from './ipLogonWhitelist/router';
import outboundScheduleConfigRoutes from './outboundScheduleConfig/router';
import checkProfileSetupRoutes from './checkProfileSetup/router';
import eventConfigRoutes from './eventConfiguration/router';
import paymentExtractConfigRoutes from './paymentExtractConfiguration/router';


export default {
    ...achEsendManagementRoutes,
    ...pcmAccountMaintenanceRoutes,
    ...pcmCheckManagementRoutes,
    ...ptxConnectRoutes,
    ...pcmCustomFieldsRoutes,
    ...paymentsRoutes,
    ...workflowConfigRoutes,
    ...workflowLogRoutes,
    ...messageLogRoutes,
    ...outsourcePrintProfileRoutes,
    ...ipLogonWhitelistRoutes,
    ...outboundScheduleConfigRoutes,
    ...checkProfileSetupRoutes,
    ...eventConfigRoutes,
    ...paymentExtractConfigRoutes,
};

