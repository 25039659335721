export const getTotalItems = (paginationTotalItems, visibleItems, records) => {
  if (paginationTotalItems !== undefined && paginationTotalItems !== null) {
    return paginationTotalItems;
  }

  if (visibleItems !== undefined && visibleItems !== null) {
    return visibleItems;
  }

  return records.length;
};

export default getTotalItems;
