export default ({ palette }) => ({
  filterHeader: {
    marginBottom: 8
  },
  menuWrap: {
    marginLeft: 'auto',
    // marginBottom: 8,
    '& +div label p': {
      margin: 0,
      padding: 0,
      fontWight: 400
    },
    '& +div': {
      fontWight: 400,
      fontSize: 0
    },
    '& section div button span': {
      color: '#f00'
    }
  },
  menuButton: {
    float: 'right',
    border: 'none',
    background: 'transparent',
    padding: 0,
    backgroundColor: 'transparent',
    borderRadius: '50%',
    width: 30,
    height: 30,
    cursor: 'pointer',
    '& svg': {
      marginTop: 4
    },

    '&:hover': {
      backgroundColor: palette.primary.normal,
      '& svg': {
        fill: '#fff'
      }
    }
  },

  menu: {
    border: `1px solid ${palette.form.border}`,
    padding: [13, 14],
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 30,
    marginTop: 30,
    backgroundColor: palette.background
  },
  menuHeader: {
    display: 'flex',
    borderBottom: `1px solid ${palette.form.border}`,
    paddingBottom: 10,
    fontSize: 16
  },
  pinIcon: {
    marginRight: 8,
    '& svg': {
      fill: palette.text.dark,
      width: 18,
      height: 18
    }
  },
  pinText: {
    fontWeight: 'normal',
    fontSize: 14,
    marginRight: 12
  },
  separator: {
    borderLeft: `1px solid ${palette.form.border}`,
    margin: [0, 10]
  },

  menuFooter: {
    marginTop: 20
  },
  btnSmall: {
    fontSize: 16
  },
  btnApply: {
    fontSize: 14
  },
  btnClear: {
    marginLeft: 18
  },

  pinned: {
    fontWeight: 'bold'
  }
});
