import Layout from '@glu/core/src/layout';
import CollectionView from '@glu/core/src/collectionView';
import locale from '@glu/locale';
import alert from '@glu/alerts';
import f1 from 'system/utilities/f1';
import passwordPolicy from 'common/policies/system/password';
import PasswordModel from 'system/password/models/password';
import scroll from 'common/util/scroll';
import PasswordRulesCollection from 'system/password/collections/rules';
import PasswordRuleView from 'common/passwordRules/rule';
import mobileUtil from 'mobile/util/mobileUtil';
import userInfo from 'etc/userInfo';
import { updateBiometricData } from 'mobile/util/biometricUtil';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import newPasswordTmpl from './newPassword.hbs';

export default Layout.extend({
    model: new PasswordModel(),
    template: newPasswordTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'change-password',

    behaviors: {
        ValidationSupplement: {},
    },

    ui: {
        $submit: '[data-hook="change-password"]',
    },

    initialize(options) {
        this.brandMode = options && options.brandMode;
        this.model.validators = {
            oldPassword: {
                description: locale.get('prompt.oldPassword'),
                exists: true,
            },

            newPassword: {
                description: locale.get('prompt.newPassword'),
                exists: true,
                otherDescription: locale.get('prompt.confirmPassword'),
                sameValue: 'confirmPassword',
                maxLength: 16,
            },

            confirmPassword: {
                description: locale.get('prompt.confirmPassword'),
                exists: true,
                otherDescription: locale.get('prompt.newPassword'),
                sameValue: 'newPassword',
                maxLength: 16,
            },
        };
        this.listenTo(this.model, 'invalid', scroll.scrollToFirstErrorOnce);
    },

    onRender() {
        if (this.hasLoadedRequiredData()) {
            const chgPasswordForm = f1.getForm(this);
            chgPasswordForm.addPolicy(passwordPolicy);
            this.passwordCollectionView = new CollectionView({
                el: '.password-rules',
                collection: this.passwordRules,
                itemView: PasswordRuleView,
            });
            this.passwordCollectionView.render();
        } else {
            this.loadRequiredData();
        }
    },

    change() {
        // validate the form
        const self = this;

        this.ui.$submit.attr('aria-busy', this.model.isValid());
        this.model.save(
            {},
            {
                success: () => {
                    // Only native app has biometric
                    if (mobileUtil.isNativeApp()) {
                        updateBiometricData({
                            userGroup: userInfo.get('group'),
                            userId: userInfo.get('id'),
                            password: this.model.get('newPassword'),
                        });
                    }
                    self.trigger('expiredPassword:reset:success');
                    self.ui.$submit.attr('aria-busy', false); // PCM-565
                },

                error(result) {
                    const message = alert.danger(result.error.responseHeader.message[0]);
                    self.ui.$submit.attr('aria-busy', false);
                    self.alertRegion.show(message);
                },
            },
        );
    },

    cancel() {
        this.navigateTo('logout');
    },

    loadRequiredData() {
        const self = this;
        this.passwordRules = new PasswordRulesCollection();
        this.passwordRules.fetch({
            success() {
                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },
});
