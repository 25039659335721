import util from '@glu/core/src/util';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import EntryView from 'common/dynamicPages/views/workflow/entry';

const DetailsView = EntryView.extend({
    initialize(options) {
        this.model = store.get('featureDetail-actionModel');
        this.dialogTitle = `${locale.get('featureDetail.title-view')}: ${options.featureName}`;
        const newOptions = {
            menuCategory: 'ADMIN',
            serviceName: '/featureDetail',
            serviceFunc: null,
            businessType: null,
            context: 'FEATUREID',
            mode: 'view',
        };
        EntryView.prototype.initialize.call(this, util.extend(options, newOptions));
    },

    getButtons() {
        return [
            {
                className: 'btn-secondary',
                text: locale.get('common.cancel'),
                callback() {
                    this.close();
                },
            },
        ];
    },

    onRender() {
        EntryView.prototype.onRender.call(this);
    },

});

export default DetailsView;
