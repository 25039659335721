var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"form-group\">\n            <label for=\"printer-description\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"description",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":69}}}))
    + "</label>\n            <p class=\"form-control-static\" data-text=\"description\" data-bind=\"model\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":100}}}) : helper)))
    + "</p>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-6\">\n    <div class=\"form-group\">\n        <label for=\"printer-name\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"name",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":51}}}))
    + "</label>\n        <p class=\"form-control-static\" data-text=\"name\" data-bind=\"model\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":74},"end":{"line":4,"column":82}}}) : helper)))
    + "</p>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"col-12\">\n    <div class=\"form-group col-3 no-padding\">\n        <label for=\"printer-host\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"host",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":34},"end":{"line":15,"column":51}}}))
    + "</label>\n        <p class=\"form-control-static\" data-text=\"host\" data-bind=\"model\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"host") || (depth0 != null ? lookupProperty(depth0,"host") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"host","hash":{},"data":data,"loc":{"start":{"line":16,"column":74},"end":{"line":16,"column":82}}}) : helper)))
    + "</p>\n    </div>\n    <div class=\"form-group col-3 no-padding\">\n        <label for=\"printer-address\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"address",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":19,"column":37},"end":{"line":19,"column":57}}}))
    + "</label>\n        <p class=\"form-control-static\" data-text=\"address\" data-bind=\"model\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"address") || (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"address","hash":{},"data":data,"loc":{"start":{"line":20,"column":77},"end":{"line":20,"column":88}}}) : helper)))
    + "</p>\n    </div>\n</div>\n<div class=\"col-12 form-section offset-inputs\">\n    <div class=\"col-12 no-padding\">\n        <label for=\"printer-host\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"pageOffset",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":57}}}))
    + "</label>\n    </div>\n    <div class=\"col-6 form-inline no-padding\">\n        <div id=\"region-page-offset-x\" class=\"offset-input\"></div>\n        <div id=\"region-page-offset-y\" class=\"offset-input\"></div>\n    </div>\n</div>";
},"useData":true});