import EntryView from 'common/dynamicPages/views/workflow/entry';
import gridApi from 'common/dynamicPages/api/grid';
import PageApi from 'app/administration/common/dynamicPages/api/view';

export default EntryView.extend({
    onRender() {
        const smbOverride = this.options.smbOverride ?? this.model;
        if (this.hasLoadedRequiredData()) {
            if (this.mode === 'insert') {
                this.pageView = PageApi.page.get({
                    context: this.contextDef,
                    state: this.mode,
                    hideButtons: true,
                    gridApi,
                    smbOverride,
                });
            } else if (this.mode === 'import') {
                this.pageView = PageApi.page.get({
                    context: this.contextDef,
                    state: this.mode,
                    hideButtons: true,
                    gridViewOptions: {
                        paginate: true,
                        sortable: true,
                    },
                    gridApi,
                });
            } else if (this.mode === 'view' && this.options.context === 'CM_IMPORTRESULTS') {
                this.pageView = PageApi.page.get({
                    context: this.contextDef,
                    model: this.storedModel,
                    hideButtons: true,
                    state: this.mode,
                    gridViewOptions: {
                        paginate: true,
                    },
                    gridApi,
                    viewType: this.viewType,
                });
            } else if (this.mode === 'view' || this.mode === 'modify' || this.mode === 'repair' || this.mode === 'spaymt') {
                this.pageView = PageApi.page.get({
                    context: this.contextDef,
                    model: this.storedModel,
                    hideButtons: true,
                    state: this.mode,
                    gridApi,
                    viewType: this.viewType,
                    smbOverride,
                });
            }
            this.listenTo(this.pageView, 'loaded', this.setPageViewListeners);
            this.pageContent.show(this.pageView);
            this.displaySummaryTotal();
        } else {
            this.loadRequiredData();
            if (this.mode === 'insert' || this.mode === 'import') {
                this.setHasLoadedRequiredData(true);
            }
        }
    },
});
