import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { asView } from 'common/util/reactUtil';
import Switch from '@glu/switch-react';

const propTypes = {
    labelText: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.bool,
    show: PropTypes.bool,
};

const defaultProps = {
    labelText: '',
    onChange: () => {},
    defaultValue: false,
    /**
     * determines if the switch is shown or not, defaults to true (to show)
     */
    show: true,
};

const StatefulSwitch = ({
    labelText,
    onChange,
    defaultValue,
    show,
    ...props
}) => {
    const [checked, toggleChecked] = useReducer(value => !value, defaultValue);
    const onSwitchChange = (name, value) => {
        onChange(name, value);
        toggleChecked(value);
    };
    if (show) {
        return (
            <Switch
                {...props}
                labelText={labelText}
                value={checked}
                onChange={onSwitchChange}
            />
        );
    }
    return null;
};
StatefulSwitch.propTypes = propTypes;
StatefulSwitch.defaultProps = defaultProps;

/**
 * Provide a utility function for leveraging this in a Backbone context
 * @param {Object} options - Options/props to be sent along to wrapped component
 */
export const createStatefulSwitchView = options => asView(StatefulSwitch, options);

export default StatefulSwitch;
