import Model from '@glu/core/src/model';
import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import moment from 'moment';
import Formatter from 'system/utilities/format';

export default Model.extend({
    initialize() {
        this.validators = {
            dateRange: {
                description: locale.get('reports.date.range'),
                exists: true,
            },

            cdMultiSelect: {
                description: locale.get('reports.an.account'),
                exists: true,
            },
        };
    },

    defaults() {
        const defaultDate = moment(new Date()).format(userInfo.getDateFormat());
        return {
            dateRange: `${defaultDate} - ${defaultDate}`,
        };
    },

    getMinDate() {
        const minDate = this.get('dateRange').replace(/ /g, '').split('-')[0];
        return Formatter.formatDateFromUserFormat(minDate, 'YYYYMMDD');
    },

    getMaxDate() {
        const maxDate = this.get('dateRange').replace(/ /g, '').split('-')[1];
        return Formatter.formatDateFromUserFormat(maxDate, 'YYYYMMDD');
    },
});
