import Layout from '@glu/core/src/layout';
import store from 'system/utilities/cache';
import $ from 'jquery';
import services from 'services';
import ContextApi from 'common/dynamicPages/api/context';
import http from '@glu/core/src/http';
import viewTmpl from './view.hbs';

export default Layout.extend({
    template: viewTmpl,

    initialize(options) {
        this.segmentName = store.get('segmentName');
        this.sophistication = store.get('segment:sophistication');

        this.widgets = options.widgets;
        this.groups = options.groups;
        this.contextDef = ContextApi.menuContext.getContext('SEGMNT_MAINT');
        this.isEditMode = store.get('segmentModify') || store.get('segmentInsert');
    },

    ui: {
        $navLinks: '[data-hook="getNavTabs"] .NavTabs-link',
    },

    onShow() {
        this.widgets.each((widget) => {
            const selectedGroup = widget.get('groupId');
            if (selectedGroup !== undefined) {
                const groupSelect = `#${widget.get('widgetId')}-group`;
                $(groupSelect).val(selectedGroup).prop('selected', true);
            }
        });

        this.ui.$navLinks.on('click', (e) => {
            e.preventDefault();
            const path = this.$(e.target).attr('href');
            this.navigateTo(path);
        });
    },

    templateHelpers() {
        return {
            widgets: this.widgets.toJSON(),
            groups: this.groups.toJSON(),
            isEditMode: this.isEditMode,
        };
    },

    findGroupName(groupId) {
        return this.groups?.models.filter(m => m.id === parseInt(groupId, 10))?.[0]?.get('name');
    },

    save() {
        const requestData = {};
        requestData.marketSegment = this.segmentName;
        requestData.sophistication = this.sophistication;
        requestData.models = [];

        this.widgets.each((widget) => {
            const groupSelect = `#${widget.get('widgetId')}-group`;
            if ($(groupSelect).val() !== undefined && $(groupSelect).val() > 0) {
                const groupId = $(groupSelect).val();
                requestData.models.push({
                    widgetId: widget.get('widgetId'),
                    widgetName: widget.get('widgetName'),
                    widgetDescription: widget.get('widgetDescription'),
                    groupId,
                    groupName: this.findGroupName(groupId),
                    marketSegment: this.segmentName,
                    external: widget.get('external'),
                });
            }
        });

        http.post(services.generateUrl('/cxp/elearning/saveWidgetGroupModels'), requestData, () => {
            this.navigateTo('/SEGMENTATION/viewSegments');
        });
    },

    cancel() {
        store.unset(
            'segmentName',
            {
                silent: true,
            },
        );
        store.unset(
            'segmentModify',
            {
                silent: true,
            },
        );
        this.navigateTo('/SEGMENTATION/viewSegments');
    },
});
