import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({
    initialize(models, options) {
        this.base = options.base;
        if (this.base === 'payments') {
            this.serviceUrl = '/payment/listView/corp/getQueryResults';
            this.functionCode = 'INST';
            this.formatterType = 'PaymentTypes';
            this.queryType = 'PaymentTypes';
        }
        if (this.base === 'templates') {
            this.serviceUrl = '/template/listView/corp/getQueryResults';
            this.functionCode = 'TMPL';
            this.formatterType = 'Query';
            this.queryType = 'Query';
        }
        this.inquiryId = 20011;
        if (models && models.functionCode) {
            this.functionCode = models.functionCode;
        }
        if (models && models.inquiryId) {
            this.inquiryId = models.inquiryId;
        }
    },

    parse(jsonData) {
        const returnArray = [];
        util.each(jsonData, (rowItem) => {
            const contextObj = {};
            let typeCode = '';
            let productCode = '';

            if (rowItem.mapDataList) {
                for (let i = 0; i < rowItem.mapDataList.length; i += 1) {
                    if (rowItem.mapDataList[i].toField.toUpperCase() === 'SERVICENAME') {
                        contextObj.serviceName = rowItem.mapDataList[i].value;
                    }
                    if (rowItem.mapDataList[i].toField.toUpperCase() === 'TYPECODE') {
                        typeCode = rowItem.mapDataList[i].value;
                    }
                    if (rowItem.mapDataList[i].toField.toUpperCase() === 'PAYMETHOD') {
                        productCode = rowItem.mapDataList[i].value;
                    }
                }
            }

            const obj = {
                id: rowItem.name,
                name: rowItem.label,
                label: rowItem.label,
                typeCode,
                productCode,
                context: contextObj,
            };

            returnArray.push(obj);
        });

        return util.sortBy(returnArray, 'label');
    },

    sync(method, model, options) {
        const url = services.generateUrl(this.serviceUrl);
        const postData = {
            requestHeader: {
                queryOffset: 0,
                queryPagesize: 0,

                requestUser: {
                    data: {
                        item: [{
                            name: '',
                            value: '',
                        }],
                    },

                    userId: '',
                },

                channelId: '',
                requestId: '',
            },

            IncludeMapData: 1,

            queryCriteria: {
                action: {
                    typeCode: '*',
                    entryMethod: 0,
                    actionMode: 'INSERT',
                    productCode: 'PAY',
                    functionCode: this.functionCode,
                },

                fieldName: 'DESCRIPTION',
                inquiryId: this.inquiryId,
                queryType: this.queryType,

                customFilters: [{
                    filterName: '',

                    filterParam: [
                        '',
                    ],
                }],
            },

            formatterType: this.formatterType,
        };
        http.post(url, postData, (result) => {
            options.success(result.queryResponse.QueryData.queryRows);
        }, (result) => {
            options.error(result);
        });
    },
});
