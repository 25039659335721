export default ({
    setupInfoTooltips(view) {
        const options = {
            trigger: 'focus hover',
        };

        // this needs to be dynamic and not rely on the ui hash
        view.$('[data-toggle="popover"]').popover(options);
    },
});
