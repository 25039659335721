import EntryView from 'app/checkManagement/views/viewBasePayDecision';
import store from 'system/utilities/cache';
import alertMessage from 'common/api/alertMessage';

const ViewPositivePayDecision = EntryView.extend({
    initialize(options) {
        this.setModel();
        this.checkViewer = options.checkViewer;
        store.set('current-workspace-route', options.returnRoute);
        EntryView.prototype.initialize.call(this, options);
    },

    onRender() {
        EntryView.prototype.onRender.call(this);

        if (store.has(`${this.contextKey}-alertMessageCV`) && this.alertRegion) {
            alertMessage.renderMessage(this, store.get(`${this.contextKey}-alertMessageCV`), store.get(`${this.contextKey}-confirmsCV`), 0);
            store.set(`${this.contextKey}-alertMessageCV`, null);
            store.set(`${this.contextKey}-confirmsCV`, null);
        }
    },

    setModel() {
        this.model = this.model || store.get('cm_listView_positivePayDecision-actionModel');
    },

    setHasLoadedRequiredData(bool) {
        this.storedModel.set('PAYISSUE_FLAG', 'P');
        this.storedModel.set('CMDECISION', 'P');
        this.storedModel.set('CLIENTRETURNREASONCODE', '');
        this.storedModel.set('CLIENTRETURNREASONDESC', '');

        this.storedModel.overloadedMethodName = 'pAdjust';
        EntryView.prototype.setHasLoadedRequiredData.call(this, bool);

        this.trigger('checkDetail:loaded');
    },

    getListUrl() {
        return this.options.returnRoute;
    },

    cancel() {
        this.navigateTo(this.options.returnRoute);
    },

    actionSuccess(confirmResponse) {
        const { contextKey } = this;

        if (this.checkViewer === undefined) {
            store.set(`${contextKey}-alertMessage`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirms`, confirmResponse);
            this.navigateTo(this.options.returnRoute);
        } else {
            store.set(`${contextKey}-alertMessageCV`, this.mode.toUpperCase());
            store.set(`${contextKey}-confirmsCV`, confirmResponse);
            this.checkViewer.nextCheck();
        }
    },
});

export default ViewPositivePayDecision;
