var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"clearfix\">\n    <p class=\"static-label\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.repeatEvery",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":62}}}))
    + "</p>\n\n    <div class=\"form-group clearfix\">\n        <div class=\"js-region-recurrence-dropdown left-column\"></div>\n        <div class=\"right-column\">\n            <label for=\"recur-every-period-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":50}}}) : helper)))
    + "\" class=\"sr-only\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.recurrencePeriod",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":7,"column":68},"end":{"line":7,"column":107}}}))
    + "</label>\n            <select id=\"recur-every-period-"
    + alias2(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":50}}}) : helper)))
    + "\" class=\"form-control js-recurrence-period\" data-bind=\"model\" name=\"type\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readOnly") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":124},"end":{"line":8,"column":157}}})) != null ? stack1 : "")
    + ">\n                <option value=\"MINUTES\" class=\"js-option-minute\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.minutes",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":9,"column":65},"end":{"line":9,"column":95}}}))
    + "</option>\n                <option value=\"HOURLY\" class=\"js-option-hour\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.hours",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":10,"column":62},"end":{"line":10,"column":90}}}))
    + "</option>\n                <option value=\"DAILY\" class=\"js-option-day\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.day",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":11,"column":60},"end":{"line":11,"column":86}}}))
    + "</option>\n                <option value=\"WEEKLY\" class=\"js-option-week\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.weeks",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":12,"column":62},"end":{"line":12,"column":90}}}))
    + "</option>\n                <option value=\"MONTHLY\" class=\"js-option-month\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.months",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":13,"column":64},"end":{"line":13,"column":93}}}))
    + "</option>\n                <option value=\"YEARLY\" class=\"js-option-year\">"
    + alias2(lookupProperty(helpers,"locale").call(alias1,"scheduler.years",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":14,"column":62},"end":{"line":14,"column":90}}}))
    + "</option>\n            </select>\n        </div>\n    </div>\n</div>\n<div class=\"form-group\">\n	<div class=\"js-region-recurrence-settings\"></div>\n	<div class=\"js-region-recurrence-description\"></div>\n</div>\n";
},"useData":true});