import Model from '@glu/core/src/model';

export default Model.extend({
    defaults() {
        return {
            accountName: '',
            accountNumber: '',
        };
    },

    accountName() {
        return this.get('accountName');
    },

    accountNumber() {
        return this.get('accountNumber');
    },
});
