import dialog from '@glu/dialog';
import EntryView from 'common/dynamicPages/views/workflow/entry';
import workspaceHelper from 'common/workspaces/api/helper';
import actionFunction from 'app/payments/api/common';
import constants from 'common/dynamicPages/api/constants';
import PrintViewModal from 'common/dynamicPages/views/workflow/printViewModal';
import store from 'system/utilities/cache';
import systemConfig from 'system/configuration';
import RefreshSummaryModel from '../models/refreshSummaryModel';

export default EntryView.extend({
    initialize(opts) {
        const fileLevelFragmentOverride = store.get('file-level-fragment-override');
        let { lastFragment } = opts;

        if (!opts.isSubPayment && fileLevelFragmentOverride) {
            lastFragment = fileLevelFragmentOverride;
        } else if (!opts.isSubPayment && !fileLevelFragmentOverride) {
            store.set('file-level-fragment-override', lastFragment);
        }

        const options = {
            menuCategory: 'PMTS',
            serviceName: 'payments/corp',
            serviceFunc: null,
            businessType: null,
            context: 'PAY_LIST_VIEW',
            mode: 'view',
            lastFragment,
        };

        if (opts.isSubPayment) {
            options.subPaymentContextKey = systemConfig.isAdmin() ? 'adminPayment_File_getListViewDetails' : 'payment_File_getListViewDetails';
        }

        EntryView.prototype.initialize.call(this, options);

        if (this.addPrintButtonToDetailPage()) {
            this.buttonList.splice(
                1,
                0,
                {
                    action: 'PRINT',
                },
            );
        }
        // go ahead and set the refreshSummaryModel
        this.refreshSummaryModel = new RefreshSummaryModel();
        // when the alert view is shown from the list view, update the summary section
        this.listenTo(this.appBus, 'listview:alert:show', (alertData) => {
            this.refreshSummaryModel.fetchNewSummary(alertData);
        });
    },

    repair() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('PAYMENTS/repairPayment');
    },

    modify() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);

        // if modifying a sub payment
        if (this.options.subPaymentContextKey) {
            this.navigateTo('PAYMENTS/modifyPayment/fileLevel');
        } else {
            this.navigateTo('PAYMENTS/modifyPayment');
        }
    },

    copyinst() {
        this.storedModel.loadFromPayments = true;
        actionFunction.makePayment(this.storedModel, this.contextKey);
        this.navigateTo('PAYMENTS/addPaymentFromTemplate');
    },

    reverse() {
        store.set(`${this.contextKey}-actionModel`, this.tempModel);
        this.navigateTo('PAYMENTS/reversePayment');
    },

    print() {
        let reportId;
        let fieldName;
        let fieldValue;
        let typeCode;
        let productCode;
        let functionCode;
        if (!this.storedModel.get('TYPE')) {
            reportId = 50105;
            fieldName = 'UPPER(INSTRUCTION_ID)';
            productCode = 'RTP';
            typeCode = '*';
            functionCode = 'INST';
            fieldValue = [this.storedModel.get('INSTRUCTION_ID')];
        } else {
            reportId = 40001;
            fieldName = 'TNUM';
            productCode = 'PAY';
            typeCode = '*';
            functionCode = 'INST';
            fieldValue = [this.storedModel.get(fieldName)];
        }
        const printModal = new PrintViewModal({
            exportModel: {
                outputFormat: 'PDF',
                pageType: 'LETTER',
                expData: 'transaction',
                detailReportId: reportId,

                actionData: {
                    productCode,
                    functionCode,
                    typeCode,
                },

                searchFields: [{
                    fieldName,
                    operator: 'IN',
                    fieldValue,
                    dataType: 'string',
                }],
            },
        });

        if (reportId === 40001 && this.storedModel.get('TYPE') && this.storedModel.get('CMB_TYPE_DESCRIPTION')) {
            printModal.exportModel.searchFields.push({
                fieldName: 'TYPE',
                operator: 'EQ',
                fieldValue: [this.storedModel.get('TYPE')],
                dataType: 'text',
            });
            printModal.exportModel.viewId = '110000';
        }

        if (this.storedModel.get('PRODUCT') === 'USACH') {
            const wrapperObject = this?.pageContent?.currentView
                ?.batchChildGridView?.gridView?.wrapper;
            if (wrapperObject?.sortKey) {
                printModal.exportModel.detailSortFields = [{
                    fieldName: wrapperObject.sortKey,
                    sortOrder: wrapperObject.sortOrder,
                }];
            }
        }

        dialog.custom(printModal);
    },

    cancel() {
        // used when user clicks on Cancel or 'Go back'
        workspaceHelper.returnToCurrentWorkspace(this);
    },

    returnToListView() {
        // used when user has submitted an action request to the server, e.g saving a payment
        workspaceHelper.returnToCurrentWorkspaceOnResponse(this);
    },

    maketmpl() {
        this.storedModel.fromRTPWorkspace = this.tempModel ? this.tempModel.fromRTPWorkspace : null;
        actionFunction.makeTemplate(this.storedModel, this.contextKey);
        this.navigateTo('TEMPLATES/addTemplateFromPayment');
    },

    /**
     * @method addPrintButtonToDetailPage
     *
     * @returns boolean
     *
     * Method determines for the given payment type code, whether the PRINT button
     * should be made available at the detail page level
     */
    addPrintButtonToDetailPage() {
        let incTypeCode;
        if (this.contextModel && this.contextModel.get('contextDef') && this.contextModel.get('contextDef').actionData) {
            incTypeCode = this.contextModel.get('contextDef').actionData.typeCode;
        } else if (this.contextDef.actionData) {
            incTypeCode = this.contextDef.actionData.typeCode;
        }
        return constants.PAYMENT_TYPES_NO_PRINT_BUTTON_DETAIL_PAGE.indexOf(incTypeCode) === -1;
    },
});
