export default {
    BENE_ADDR_TYPECODE: 'BENEADBK',
    GRID_CONTEXT: 'ALERTLISTVW',
    PASSCODE_EMAIL_CHANNEL: 'email',
    PASSCODE_TEXT_CHANNEL: 'sms',
    PASSCODE_VOICE_CHANNEL: 'voice',
    SPACE: ' ',
    SYMANTEC_VIP_CHALLENGE_METHOD: '8',
    USER_MAINT: 'USER_MAINT',
    USER_MAINT_CONTEXT: '_user users context',
    USER_MAINT_VIEWMODEL: '_user users model',
    VASCO_CHALLENGE_METHOD: '6',
    ADDRESS_BOOK_INQUIRY: 19561,
    AUDIT_INQUIRY_ADMIN_PRINT_EXP: 9046,
    AUDIT_INQUIRY_PRINT_EXP: 9041,
    COPY_EXISTING_USER_INQUIRY: 17028,
    COPY_EXISTING_USER_INQUIRY_ADMIN: 17026,
    DEFAULT_UCE2_GRID_SIZE: 10,
    IMPORTVIEW_INQUIRYID: 17254,
    MOBILE_REG_CANCEL_ERRORCODE: 22010,
    PASSTHROUGH_PAYLISTVIEW_INQUIRYID: 17248,
    RISK_INQUIRY_BMPP: 20571,
    RISK_INQUIRY_CM: 20515,
    RISK_INQUIRY_CMPP: 20576,
    RISK_INQUIRY_EBMPP: 28001,
    ROLE_MAINT: 'CMAINT_CROLE_TM',
    ROLE_MAINT_CLIENT: 'ADMIN_USER_ROLES',
    STATS_REPORT_FEATURE_TYPE_ALL_FILTER: 30500,
    STATS_REPORT_SUB_TYPE_DROPDOWN_INQ: 30502,
    STATS_REPORT_TYPE_DROPDOWN_INQ: 30501,
    COMBO_DEBOUNCE: 350,
    ROLE_BASED: '0',
    UCE_BASED: '3',
    ROLE_AUTOAPPROVE_ERROR: 152,
    USER_AUTOAPPROVE_ERROR: 153,
    CHECKFREE_WIDGET_RESTRICT_ATTRIBUTE: 'checkfreeAuthLevel',
    CHECKFREE_WIDGET_RESTRICT_LEVEL: 'Level3',

    MODES: {
        INSERT: 'insert',
        MODIFY: 'modify',
        VIEW: 'view',
        RESTORE: 'restore',
    },

    ACTIONS: {
        APPROVE: 'approve',
        AUTHRIZE: 'authrize',
        AUTHORIZE: 'authrize',
        DELETE: 'delete',
        DISABLE: 'disable',
        MODIFY: 'modify',
        REJECTFORM: 'rejectfm',
        RESETPW: 'resetpw',
        SELECT: 'select',
        CANCLREQ: 'canclreq',
        SIMULATE: 'simulate',
        COPYFROMUSER: 'copyfromuser',
        RESTORE: 'restore',
        SELFSRV: 'selfsrv',
        INSERT: 'insert',
        ROLLBACK: 'rollback',
        UPDSET: 'updset',
        EXTENDREQ: 'extendreq',

    },

    ROUTES: {
        ADD_ROLE: 'ADMINISTRATION/addRole',
        ADD_ROLE_USER: 'ADMINISTRATION/addRoleUser',
        ADD_USER: 'ADMINISTRATION/addUser',
        CLONE_ROLE: 'ADMINISTRATION/cloneRole',
        MODIFY_ROLE: 'ADMINISTRATION/modifyRole',
        MODIFY_ROLE_USER: 'ADMINISTRATION/modifyRoleUser',
        MODIFY_USER: 'ADMINISTRATION/modifyUser',
        PENDING_CHANGES: 'ADMINISTRATION/viewPendingChanges',
        UPDATE_USER_SETTING: 'ADMINISTRATION/updateUserSetting',
        USER_LISTVIEW: 'ADMINISTRATION/viewUsers',
        VIEW_ROLE_USER: 'ADMINISTRATION/viewRoleUser',
        VIEW_USER: 'ADMINISTRATION/viewUser',
        REPORTING_REQUESTFORPAYMENTMNGMT: '/REPORTING/requestForPaymentMangement',
    },

    TIMESTAMP_KEY: 'LOGTIME',
    INFO_KEY: 'INFO',

    // TODO: this linking of associated alert recipient permissions should be handled server side
    USER_MAINT_ADMIN_ALERT_RECIPIENT_SETUP: 'CEMAD_TM',

    USER_MAINT_ADMIN_ALERT_RECIPIENT_GROUP_ASSIGNMENT: 'CEMAS_TM',
    USER_MAINT_ADMIN_ALERT_RECIPIENT_GROUP_SETTINGS: 'CEMGR_TM',

    // TODO: this mapping of admin sub-groups should be handled server side
    USER_MAINT_ADMIN_GROUPS: {
        USER_ADMIN_GROUP: {
            LOCALE: 'adminGroup.userAdministration',

            TYPE_CODES: [
                '_USER',
            ],

            FUNCTION_CODES: [],
        },

        GENERAL_ADMIN_GROUP: {
            LOCALE: 'adminGroup.generalAdministration',

            TYPE_CODES: [
                'CMPAS_TM',
                'ACCTACCS',
                'CEMAD_TM',
                'BENEADBK',
                'MPREF_TM',
                'CACCT_TM',
                'ACH_DIMD',
                'CACCT_TM',
                'CONFIMP',
                'AUDIT',
            ],

            FUNCTION_CODES: [],
        },

        PAYMENT_ADMIN_GROUP: {
            LOCALE: 'adminGroup.paymentAdministration',

            TYPE_CODES: [
                'ACH_COMP',
            ],

            FUNCTION_CODES: [],
        },

        OTHER_LINKS_ADMIN_GROUP: {
            LOCALE: 'adminGroup.otherLinks',

            TYPE_CODES: [
                'ACHALERT',
                'IPAY',
                'PSRESELL',
                'PSRDC',
                'OFXDIRCN',
            ],

            PRODUCT_CODES: [
                'DYNLINK',
                'ACCTINT',
            ],
        },
    },

    SSO_ADMIN: {
        LOGIN_MASK: 1,
        LOGOUT_MASK: 2,
        REDIRECT_MASK: 4,
    },

    ARCHIVE: 'ARCHIVE',
    COPYTMPL: 'COPYTMPL',
    CREATENOTIFICATION: 'NTEMPLATE',
    CREATETEMPLATE: 'TNOTIFICATION',
    DELETE: 'DELETE',
    INSERT: 'INSERT',
    MAKETMPL: 'MAKETMPL',
    MODIFY: 'MODIFY',
    SELECT: 'SELECT',
    messageRoute: 'CMAINT/manageNotifications',
    templateRoute: 'CMAINT/manageNotificationTemplates',

    TOKEN_ACTIONS: {
        ASSIGN: 'assign',
        DISABLE: 'disable',
        ENABLE: 'enable',
        FOUND: 'found',
        EMAIL: 'email',
        LOST: 'lost',
        MISPLACED: 'misplaced',
        REPLACE: 'replace',
        UNASSIGN: 'unassign',
        UNLOCK: 'unlocked',
    },

    TOKEN_ADMINISTRATION: {
        SELECT: 'Select',
    },

    PAYMENT_PERMISSIONS: {
        BY_GROUP: 'PAYMENT_GROUP',
        BY_TYPE: 'PAYMENT_TYPE',
        GROUPING_COLUMN: 'GROUPED_PERMISSIONS_BY',
    },

    PAYMENT_GROUPS: {
        TRANSFERS: 'TRANSFER',
        LOANS: 'LOANS',
        EFT: 'EFT',
        BILLPAY: 'BILLPAY',
    },

    TRANSFER_DATA_ENTITLEMENTS: {
        FROM: 'TRANSFERBANKACCOUNT',
        TO: 'TRANSFERBANKACCOUNTCR',
    },

    RECIPIENTGROUPASSIGNMENTMODIFY_URL: 'ADMINSTRATION/recipientGroupAssignment/modify',

    USERSETTINGSMENUREQUESTCONTEXT: {
        actionMode: 'SELECT',
        functionCode: 'MAINT',
        productCode: '_ADMIN',
        typeCode: '_USERSET',
    },

    USERSETTINGSREQUESTCONTEXT: {
        actionMode: 'MODIFY',
        functionCode: 'MAINT',
        productCode: '_ADMIN',
        typeCode: '_USERSET',
    },

    ALERTREQUESTCONTEXT: {
        actionMode: 'SELECT',
        functionCode: 'ALERT',
        productCode: '*',
        typeCode: '*',
    },

    RECIPIENTREQUESTCONTEXT: {
        actionMode: 'SELECT',
        functionCode: 'MAINT',
        productCode: '_ADMIN',
        typeCode: 'CEMAD_TM',
    },

    RECIPIENTGROUPREQUESTCONTEXT: {
        actionMode: 'SELECT',
        functionCode: 'MAINT',
        productCode: '_ADMIN',
        typeCode: 'CEMAS_TM',
    },

    RDC_SETTING: {
        BANKACCOUNT: 'BankAccount',
        DELUXE: 'Deluxe',
        ITMS: 'Itms',
    },

    URL_PLACE_STOP: '/cminst/placeStop',

    RTP_LIMITS: {
        MIN_AMOUNT: 0.01,
        MAX_AMOUNT: 9999999999.99,
    },

    SMB_PERMISSIONS: {
        RFP_OUTBOUND_PERMISSION: 'requestsForPaymentOutbound',
        PAYMENT_APPROVAL_PERMISSION: 'approvePayments',
        AUTOBOOK_PERMISSION: 'AUTOBOOK',
    },

    AUDIT_GRID_NON_SORTABLE_COLUMNS: [
        'DETAILS',
    ],
};
