import CollectionView from '@glu/core/src/collectionView';
import MultiFieldElement from './multiFieldElementView';

export default CollectionView.extend({
    itemView: MultiFieldElement,

    itemViewOptions() {
        return this.options;
    },
});
