import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import util from '@glu/core/src/util';
import userInfo from 'etc/userInfo';
import services from 'services';
import Recipient from 'app/administration/models/recipient';

export default Collection.extend({

    model: Recipient,

    initialize(options) {
        this.actionMode = options.actionMode;
    },

    sync(method, collection, options) {
        const url = services.generateUrl('/alerts/detailMaint/getRecipients');
        const requestData = {
            userGroup: userInfo.get('group'),
            tenantId: userInfo.get('tenantId'),
            alertId: this.alertId,
            recipient: this.recipientName,
            groupFlag: this.groupFlag,
            actionMode: this.actionMode,
        };

        return http.post(url, requestData, options.success, options.error);
    },

    createNewContact(recipientName, contact, type, index) {
        let name;
        const newContact = {};
        if (recipientName.indexOf('.') === -1) {
            name = recipientName;
        } else {
            name = recipientName.replace(/\./g, '-').replace('@', '-');
        }
        name = name.replace(/ /g, '_');
        name += `-${type}-${index}`;
        newContact.name = name;
        newContact.value = contact.value;
        newContact.selected = contact.selected;
        if (contact.alias) {
            newContact.alias = contact.alias;
        }
        if (type === 'phone') {
            newContact.extension = contact.extension;
            newContact.pause = contact.pauseIndicator;
        }
        return newContact;
    },

    parseContactMethod(contactList, recipientName, type) {
        const contacts = [];
        util.each(contactList, (contact, index) => {
            if (contact && contact.value) {
                contacts.push(this.createNewContact(recipientName, contact, type, index));
            }
        });
        return contacts;
    },

    parse(response) {
        const allRecipients = response.recipients;
        return util.map(allRecipients, (oneRecipient) => {
            const data = {};
            data.name = oneRecipient.name;
            const recipientName = data.name;
            if (oneRecipient.emails && oneRecipient.emails.contacts) {
                data.email = this.parseContactMethod(oneRecipient.emails.contacts, recipientName, 'email');
            }
            if (oneRecipient.sms && oneRecipient.sms.contacts) {
                data.sms = this.parseContactMethod(oneRecipient.sms.contacts, recipientName, 'sms');
            }
            if (oneRecipient.phonenumbers && oneRecipient.phonenumbers.contacts) {
                data.phone = this.parseContactMethod(oneRecipient.phonenumbers.contacts, recipientName, 'phone');
            }
            if (oneRecipient.faxes && oneRecipient.faxes.contacts) {
                data.fax = this.parseContactMethod(oneRecipient.faxes.contacts, recipientName, 'fax');
            }
            if (this.groupFlag === '1') {
                data.groupName = this.recipientName;
            }
            return data;
        });
    },

});
