import util from '@glu/core/src/util';
import $ from 'jquery';
import numeral from 'numeral';
import locale from '@glu/locale';
import rcount from '../../dynamicPages/views/mdf/componentHelpers/rcount';

export default function (form, initialState) {
    const { model } = form.formView;
    const typeCode = (model.jsonData.typeInfo) ? model.jsonData.typeInfo.typeCode : '';
    const functionCode = (model.jsonData.typeInfo) ? model.jsonData.typeInfo.functionCode : '';
    const ADDENDA_MAX_LENGTH = 80;
    const ADDENDA_FORMATTED = '2';

    const setUpAddenda = () => {
        const $refCode = $('[name="REFCODE"]');
        const $message = $('[name="MESSAGE"]');
        const ADDENDA_FREEFORM_WITH_CODE_MAX_LENGTH = 71;

        if (util.contains(util.keys(model.changedAttributes()), 'REFCODE')) {
            // reset the message field
            model.set('MESSAGE', '');
            $message.val('');
            /*
             * if the refcode was change to blank in model,
             * then we must reset the refcode in the view as well
             */
            if (!model.get('REFCODE')) {
                $refCode.val('');
                $refCode.trigger('change');
            }
        }
        const addendaCodeExists = !util.isEmpty($refCode.val());
        $message.prop('maxlength', ((addendaCodeExists) ? ADDENDA_FREEFORM_WITH_CODE_MAX_LENGTH : ADDENDA_MAX_LENGTH));
        rcount.setupRCountBoxes(form.formView, '.rcount', $message.parent());

        /*
         * NH-46084 - HACK Allow Addenda combobox selection to be removed.  This
         * should be made an option for a combobox in the future.
         */
        $refCode.siblings('div').toggleClass('select2-allowclear', addendaCodeExists);
    };

    const getFieldLength = (fieldSelector, defaultLength) => {
        const val = $(fieldSelector).val();
        return util.isEmpty(val) ? defaultLength : numeral(val).format('0.00').length;
    };

    const setUpFomattedAddenda = () => {
        const refNumberLen = model.get('REFNUMBER').trim().length;
        let warningText = '';
        let $message = $('[name="PAYMENTDESCRIPTION"]');

        /**
         * NH-46081 - Formatted Addenda length.
         * addenda value => RMR*(4) + RefNumberQualifier(3) + RefNumber(<=30) + '*'' +
         * TotalInvoiceAmount + AmountOfAdj + TermsDiscount + Amount + '**' +
         * AdjReasonCode(3) + PaymentDescription + \
         * For example:
         * RMR*DP*111111111122*1200*9999999999*4444400*99500***05*098765432109876543210987\
         */
        let addendaCodeLength = 14 + refNumberLen + getFieldLength('[name="TOTALINVOICEAMOUNT"]', 4) + getFieldLength('[name="AMOUNTOFADJ"]', 4) + getFieldLength('[name="TERMSDISCOUNT"]', 4) + getFieldLength('[name="TERMSDAMOUNTISCOUNT"]', 4) + model.get('PAYMENTDESCRIPTION').length;
        if (addendaCodeLength > 80) {
            // Addenda is too long.  The RefNumber will be truncated to be 12 chars.
            if (refNumberLen > 12) {
                warningText = (functionCode === 'BATCH') ? locale.get('ACH.INST.DACHCVP.NACHA.PAYMENTDESCRIPTION.refNumInfo') : locale.get('ACH.TMPL.DACHCVP.NACHA.PAYMENTDESCRIPTION.refNumInfo');
                addendaCodeLength = (addendaCodeLength - refNumberLen) + 12;
            }
            // Addenda is still too long. The addenda will be truncated.
            if (addendaCodeLength > 80) {
                if (refNumberLen > 12) {
                    warningText = (functionCode === 'BATCH') ? locale.get('ACH.INST.DACHCVP.NACHA.PAYMENTDESCRIPTION.refNumAddendaInfo') : locale.get('ACH.TMPL.DACHCVP.NACHA.PAYMENTDESCRIPTION.refNumAddendaInfo');
                } else {
                    warningText = (functionCode === 'BATCH') ? locale.get('ACH.INST.DACHCVP.NACHA.PAYMENTDESCRIPTION.addendaInfo') : locale.get('ACH.TMPL.DACHCVP.NACHA.PAYMENTDESCRIPTION.addendaInfo');
                }
            }
        }

        if ($message.length === 2) {
            $message = $message.filter('.modal-body [name="PAYMENTDESCRIPTION"]');
        }

        $message.parent().find('.textline-field').text(warningText);
    };

    if (initialState) {
        if (typeCode === 'BDACHCVP' && model.get('ADDENDAFORMAT') === ADDENDA_FORMATTED) {
            setUpFomattedAddenda();
        } else {
            setUpAddenda();
        }
        if (typeCode === 'BDACHCTX') {
            const addendaCount = model.get('ADDENDACOUNT') > 0;
            $('[name="AMOUNTTOADJ"]').inputmask({ prefix: '-' });
            form.field('IDCODE').shouldBeRequiredWhen(addendaCount).$el.parent().toggleClass('required', addendaCount);
            form.field('REFERENCEID').shouldBeRequiredWhen(addendaCount).$el.parent().toggleClass('required', addendaCount);
        }
    }

    model.on('change:REFCODE', () => {
        setUpAddenda();
    });

    if (typeCode === 'BDACHCVP') {
        model.on('change:AMOUNT change:ADDENDAFORMAT change:TOTALINVOICEAMOUNT change:TERMSDISCOUNT change:AMOUNTOFADJ change:ADJREASONCODE change:REFNUMBER change:REFNUMBERQUALIFIER change:PAYMENTDESCRIPTION', () => {
            if (model.get('ADDENDAFORMAT') === ADDENDA_FORMATTED && !util.isUndefined(model.get('REFNUMBER'))) {
                setUpFomattedAddenda();
            }
        });
    }
}
