import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import transform from 'common/util/transform';
import services from 'services';
import http from '@glu/core/src/http';

export default Collection.extend({

    /**
     * @constant {string}
     */
    url: services.generateUrl('/tableMaint/getListView'),

    /**
     * Called when a new collection is created.
     *
     * @param {array} models - an array of models to add to this collection
     * @param {object} [options] - the options object
     * @param {string} options.productCode - the product code
     * @param {string} options.functionCode - the function code
     * @param {string} options.typeCode - the type code
     * @param {number} options.inquiryId - the inquiry id to look up
     * @param {array} [options.searchFields] - an optional array of search fields
     * to add to the inquiry
     * @param {number} [options.viewId] - the view id to include in the server call.
     */
    initialize(models, options) {
        this.productCode = options.productCode;
        this.functionCode = options.functionCode;
        this.typeCode = options.typeCode;
        this.inquiryId = options.inquiryId;
        this.searchFields = options.searchFields || [];
        this.viewId = options.viewId || '';
    },

    /**
     * Sets a search filter
     * @param {array} searchFields - an array of objects containing search field criteria
     * @example [{
     *          'fieldName': 'foo',
     *          'operator': '=',
     *          'dataType': 'number',
     *          'fieldValue': ['fooValue']
     *      }]
     */
    setSearchFields(searchFields) {
        if (searchFields) {
            this.searchFields = searchFields;
        }
    },

    /**
     * Sets the view id
     * @param {number} viewId - the view id to send to the server
     */
    setViewId(viewId) {
        if (viewId) {
            this.viewId = viewId;
        }
    },

    sync(method, model, options) {
        if (method === 'read') {
            const requestParameters = {
                inquiryId: this.inquiryId,
                productCode: this.productCode,
                functionCode: this.functionCode,
                typeCode: this.typeCode,
                viewType: '',
            };

            const postData = {
                requestParameters: {
                    item: transform.hashToPairs(requestParameters),
                },
                viewId: this.viewId,
                searchFields: this.searchFields,
            };

            http.post(this.url, postData, (result) => {
                options.success(result);
            }, (result) => {
                options.error(result);
            });
        }
    },

    parse(response) {
        if (!response) {
            return undefined;
        }
        const { rowHeader, rows } = response;
        let i;
        const totals = [];

        if (rows.length && rowHeader.length) {
            const { columns } = rows[0];
            for (i = 0; i < rowHeader.length; i += 1) {
                const { fieldValue } = columns[i];
                totals.push({
                    fieldName: columns[i].fieldName,
                    fieldValue: util.isEmpty(fieldValue) ? 0 : fieldValue,
                    displayName: rowHeader[i].displayName,
                });
            }
        }
        return totals;
    },

});
