/* istanbul ignore file */
export const styles = (theme) => {
  const {
    dataComponents: {
      itemHoverBackground,
      scrollbarBackground,
      scrollbarColor,
      textDark,
      textLight,
      textStyles
    }
  } = theme;

  return ({
    checkbox: {
      marginBottom: 2
    },
    columnActions: {
      ...textStyles,
      '& > span': {
        color: ({ dark }) => (dark ? textLight : textDark),
        display: 'block',
        margin: '5px 0',
        textTransform: 'uppercase'
      },
      '& button': {
        background: 'none',
        border: 'none',
        color: ({ dark }) => (dark ? textLight : textDark),
        cursor: 'pointer',
        fontSize: 14
      },
      padding: '0 15px 5px'
    },
    columnFilter: {
      '& input': {
        background: 'none',
        border: ({ dark }) => (dark ? `1px solid ${textLight}` : `1px solid ${textDark}`),
        color: ({ dark }) => (dark ? textLight : textDark),
        fontSize: 16,
        lineHeight: 30,
        margin: '0 0 5px',
        padding: '0 5px',
        width: '100%'
      }
    },
    columnsDroppable: {
      // Ensure there is enough height to engage a drop
      minHeight: '10px'
    },
    contentScroll: {
      '&::-webkit-scrollbar': {
        width: 10
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: scrollbarColor
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: scrollbarBackground
      },
      flex: '1',
      overflowY: 'auto',
      textStyles
    },
    itemActions: {
      '& > div': {
        display: 'inline-block'
      },
      '& svg': {
        fill: ({ dark }) => (dark ? textLight : textDark)
      },
      display: 'none'
    },
    itemNoHide: {
      paddingLeft: 16
    },
    itemPin: {
      '& > button': {
        '&:last-of-type': {
          border: 'none'
        },
        background: 'none',
        border: 'none',
        borderRight: `1px solid ${textLight}`,
        color: ({ dark }) => (dark ? textLight : textDark),
        cursor: 'pointer',
        fontSize: 16,
        padding: [0, 6]
      },
      color: ({ dark }) => (dark ? textLight : textDark),
      fontSize: 16,
      position: 'relative',
      top: -2
    },
    itemText: {
      display: 'inline-block',
      fontSize: 16,
      height: 20
    },
    itemToggle: {
      display: 'flex'
    },
    itemWrapper: {
      '& label': {
        margin: 0
      },
      '&$suppressDisplay': {
        display: 'none'
      },
      '&:hover': {
        '& $itemActions': {
          display: 'block'
        },
        backgroundColor: itemHoverBackground
      },
      borderRadius: 2,
      color: ({ dark }) => (dark ? textLight : textDark),
      cursor: 'pointer',
      display: 'flex',
      height: 40,
      justifyContent: 'space-between',
      margin: '0 7px',
      padding: 10
    },
    lockIcon: {
      '& svg': {
        fill: ({ dark }) => (dark ? textLight : textDark)
      },
      marginRight: 10
    },
    locked: {
      '&:hover': {
        background: 'inherit',
        cursor: 'inherit'
      }
    },
    maxPrimaryLabel: {
      ...textStyles,
      color: ({ dark }) => (dark ? textLight : textDark),
      fontSize: 12,
      fontStyle: 'italic',
      margin: '5px 0 0 16px'
    },
    noResults: {
      color: textLight,
      fontSize: 16,
      marginLeft: 15
    },
    nonPrimaryColumns: {
      marginLeft: '20px'
    },
    primaryColumns: {
      '& $itemWrapper': {
        margin: 0
      },
      borderBottom: '2px solid #EDEDED',
      margin: '0 7px'
    },
    showAll: {
      display: 'inline-block',
      marginRight: 5,
      verticalAlign: 'top',
      width: 110
    },
    suppressDisplay: {
    },
    wrapper: {
      ...textStyles,
      overflowY: 'auto'
    }
  });
};

export default styles;
