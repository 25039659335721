import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';

import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import adminConstants from 'app/administration/constants';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import store from 'system/utilities/cache';
import workspaceHelper from 'common/workspaces/api/helper';
import userInfo from 'etc/userInfo';

const checkfreeUserView = EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'CHECKFREE',
            serviceName: '/checkFreeSMBUsers',
            serviceFunc: null,
            businessType: null,
            context: 'CHECKFREE_SMB',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },
    loadRequiredData() {
        let model = store.get(`${this.contextKey}-actionModel`);
        if (!model) {
            const context = {
                functionCode: 'MAINT',
                productCode: 'SSOOUT',
                typeCode: 'CFSMBUSR',
                entrymethod: '0',
                serviceName: '/checkFreeSMBUsers',
                actionData: {
                    productCode: 'SSOOUT',
                    functionCode: 'MAINT',
                    typeCode: 'CFSMBUSR',
                },
            };

            model = new Model({
                retrieveLevel3UserInfo: (userInfo.getCheckfreeAuthLevel() === 'Level3'),
            });
            model.context = context;
        }

        DataApi.model.generateModelFromModel(model)
            .then((newModelParam) => {
                const newModel = newModelParam;
                if (userInfo.getCheckfreeAuthLevel() === 'Level3') {
                    newModel.key = { retrieveLevel3UserInfo: '' };
                }
                newModel.fetch({
                    success: () => {
                        this.storedModel = newModel;
                        this.setHasLoadedRequiredData(true);
                        this.render();
                    },

                    error: util.bind(errorHandlers.loading, this),
                });
            });
    },
});

workspaceHelper.publishedWidgets.add({
    id: 'CFSMBUSERINFO',
    view: checkfreeUserView,
    options: {},
    restrictViewBasedOnUserAttribute: adminConstants.CHECKFREE_WIDGET_RESTRICT_ATTRIBUTE,
    restrictWhenIsNot: adminConstants.CHECKFREE_WIDGET_RESTRICT_LEVEL,
});

export default checkfreeUserView;
