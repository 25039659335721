import { createUseStyles } from '@glu/theming';

const styles = ({ palette }) => ({
    required: {
        '&::before': {
            content: '"* "',
            color: palette.form.error,
        },
    },
});

export default createUseStyles(styles);
