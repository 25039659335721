var Handlebars = require("handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"attributeName") || (depth0 != null ? lookupProperty(depth0,"attributeName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"attributeName","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":29}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":40}}}) : helper)))
    + "</label>\n<input type=\"tel\"\n       class=\"form-control RDC-numberInput\"\n       data-hook=\"getAmount\"\n       maxlength=\"14\"\n       name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"attributeName") || (depth0 != null ? lookupProperty(depth0,"attributeName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"attributeName","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":30}}}) : helper)))
    + "\"\n       value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"attributeValue") || (depth0 != null ? lookupProperty(depth0,"attributeValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"attributeValue","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":32}}}) : helper)))
    + "\">\n<span class=\"help-block\"\n      data-bind=\"model\"\n      data-validate=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"attributeName") || (depth0 != null ? lookupProperty(depth0,"attributeName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"attributeName","hash":{},"data":data,"loc":{"start":{"line":11,"column":21},"end":{"line":11,"column":38}}}) : helper)))
    + "\"></span>\n";
},"useData":true});