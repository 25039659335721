import ImportModel from 'common/dynamicPages/models/import';
import f1 from 'system/utilities/f1';
import $ from 'jquery';
import locale from '@glu/locale';
import store from 'system/utilities/cache';
import util from '@glu/core/src/util';
import loadingTemplate from 'common/templates/loadingPage.hbs';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import domUtil from 'common/util/domUtil';
import EntryView from './entry';
import template from './templateModifyvFImport.hbs';

let form = null;
let aFieldInPaymentInformationSection = null;
let selectedRow = null;

const excludeFieldsList = ['IMPORTTYPE', 'CREATEFROM', 'CMB_TEMPLATE_CODE', 'OPTIONIMPORTMETHOD', 'OPTIONIMPORTMETHODNFI', 'error'];

const appendNonRunTimeParameter = (paramName, paramValue) => {
    let fldValue = $('#NonRunTimeParams').val();
    if (paramName && paramValue) {
        /**
         * if val is empty then simply set the paramName and value otherwise
         * add a | separator and concatenate with the old value
         */
        fldValue = (fldValue.length === 0 ? `${paramName}=${paramValue}|` : `${fldValue + paramName}=${paramValue}|`);
        $('#NonRunTimeParams').val(fldValue);
    }
};

const clearFields = (model) => {
    if (model) {
        model.set('UE_ENTRYDESC', '');
        model.set('COMPDESCDATE', '');
        model.set('BATCHCOMMENT', '');
        model.set('EFFECTIVEDATE', '');
    }
};

const getOwnerImportGridSelect = (model) => {
    const productCode = selectedRow.get('PRODUCTCODE');
    const importFunctionCode = selectedRow.get('IMPORTFUNCTIONCODE');
    const paymentTypeCode = selectedRow.get('PAYMENTTYPECODE');
    let subTypeCode = selectedRow.get('SUBTYPE');
    const paymentTypeDesc = selectedRow.get('PAYMENTTYPEDESC');
    const owner = selectedRow.get('OWNER');

    if (productCode === 'USACH') {
        if (paymentTypeDesc) {
            model.set('UE_ENTRYDESC', paymentTypeDesc.substring(0, 8).toUpperCase());
        }
        if (subTypeCode === '') {
            subTypeCode = 'NACHA';
        }
    }

    $('label[for=OPTIONIMPORTMETHODNFI]').hide();
    $('#OPTIONIMPORTMETHODNFI').hide();

    if (owner === locale.get('Client') || owner === locale.get('Bank')) {
        model.set('IMPORTTYPE', paymentTypeCode);
        model.set('SUBTYPE', subTypeCode);
        $('[name="IMPORTTYPE"]').trigger('change');

        if (productCode === 'RTGS') {
            $('#FUNCTIONCODE').one('change', () => {
                // set the type (payment or template) in the drop down, then make it readonly
                $('#FUNCTIONCODE').comboBox('val', importFunctionCode);
                $('#FUNCTIONCODE').comboBox('readonly', true);
            });

            model.set('FUNCTIONCODE', importFunctionCode);
        } else if (productCode === 'USACH') {
            $('#FUNCTIONCODE').comboBox('readonly', false);
            $('label[for=FUNCTIONCODE]').show();
            $('#FUNCTIONCODE').removeClass('hidden');
            $('#s2id_FUNCTIONCODE').show();
            $('label[for=OPTIONIMPORTMETHOD]').hide();
            $('#OPTIONIMPORTMETHOD').hide();
        }

        if (paymentTypeCode === 'BDACHTP' || paymentTypeCode === 'BDACHCSP') {
            $('label[for=UE_ENTRYDESC]').hide();
            $('[name="UE_ENTRYDESC"]').hide();
        } else {
            $('label[for=UE_ENTRYDESC]').show();
            $('[name="UE_ENTRYDESC"]').show();
        }
    } else {
        $('label[for=FUNCTIONCODE]').hide();
        $('#s2id_FUNCTIONCODE').hide();

        if (paymentTypeCode === 'BDACHNFI') {
            $('label[for=OPTIONIMPORTMETHODNFI]').show();
            $('#OPTIONIMPORTMETHODNFI').show();
            $('label[for=OPTIONIMPORTMETHOD]').hide();
            $('#OPTIONIMPORTMETHOD').hide();
        }
    }
};

const processSelectedRow = (model) => {
    const productCode = selectedRow.get('PRODUCTCODE');
    const paymentTypeCode = selectedRow.get('PAYMENTTYPECODE');
    let paymentMethod = selectedRow.get('PAYMENTMETHOD');
    const subTypeCode = selectedRow.get('SUBTYPE');
    const importFunctionCode = selectedRow.get('IMPORTFUNCTIONCODE');
    const fileEntitle = selectedRow.get('FILEPAYMENTTYPE');
    const confidentialSetting = selectedRow.get('CONFIDENTIALSETTING');
    const passThruConfidentialSetting = selectedRow.get('PASSTHRUCONFIDENTIALSETTING');
    const paymentImportMethod = selectedRow.get('PAYMENTIMPORTMETHOD');
    const importFileType = selectedRow.get('IMPORTFILETYPE');
    const optionImportMethod = model.get('OPTIONIMPORTMETHOD');
    const optionImportMethodNFI = model.get('OPTIONIMPORTMETHODNFI');
    let mapID = selectedRow.get('ID');
    const isPassThru = selectedRow.get('TYPE') === 'PASSTHRU';
    let ugImport = 0;
    let fileOnly = false;

    aFieldInPaymentInformationSection = form.field('ID');
    aFieldInPaymentInformationSection.$el.closest('.section').hide();

    clearFields(model);
    getOwnerImportGridSelect(model);

    paymentMethod = (paymentMethod ? paymentMethod.toUpperCase() : paymentMethod);

    $('#NonRunTimeParams').val('');

    $('#_productCode').val(productCode);
    $('#_typeCode').val(paymentTypeCode);
    $('#_subTypeCode').val(subTypeCode);
    $('#importType').val(paymentTypeCode);
    $('#paymentMethod').val(paymentMethod);
    $('#confidentialSetting').val(confidentialSetting);
    $('#passThruConfidentialSetting').val(passThruConfidentialSetting);

    if (productCode === 'RTGS') {
        $('#_functionCode').val('TMPL');
    } else if (productCode === 'USACH') {
        $('#_functionCode').val('BHTMPL');
    }

    // Detail import will have a value > 0
    if (mapID > 0 && productCode === 'USACH' && subTypeCode !== 'UKFP' && subTypeCode !== 'UKBACS') {
        $('#_functionCode').val('BHTMPL');
        if (paymentMethod === 'ACH') {
            appendNonRunTimeParameter('GENMAPPERCODE', mapID);
        }
    } else {
        // 0 - Payment Workflow Only, 1 - File Workflow Only, 2 - Any Workflow
        ugImport += paymentImportMethod;
        if (productCode !== 'USACH') {
            $('#_functionCode').val('TMPL');
        }
        if (mapID > 0 && productCode === 'RTGS') {
            $('#_functionCode').val(importFunctionCode);
            appendNonRunTimeParameter('GENMAPPERCODE', mapID);
        } else if (mapID > 0 && (subTypeCode === 'UKFP' || subTypeCode === 'UKBACS')) {
            $('#_functionCode').val('BHTMPL');
            if (paymentMethod === 'ACH') {
                appendNonRunTimeParameter('GENMAPPERCODE', mapID);
            }
        } else if ((paymentTypeCode === 'CIMIMPRT' || paymentTypeCode === 'STCAIMPT') && paymentMethod === locale.get('CM.Import.PayMethod').toUpperCase()) {
            mapID = selectedRow.get('GENERICMAPPERCODE');
            appendNonRunTimeParameter('GENMAPPERCODE', mapID);
        }
        if (paymentTypeCode === 'BDACHFP' && paymentTypeCode === 'BDACHCD') {
            $('#_productCode').val('USACH');
            $('#_functionCode').val('BHTMPL');
        }
        /**
         * if the user is entitle for FILE payment type only and no other payment type,
         * do not display the payment work flow radio button and default to File
         * Work flow.
         */
        if ($('#_typeCode').val() === 'FILE') {
            fileOnly = true;
            if (paymentTypeCode !== 'FILE' && (importFileType === 'BAC' || importFileType === 'CSV')) {
                fileOnly = false;
            }
        }
        if ((ugImport > 0) && (fileEntitle > 0)) {
            if (fileOnly || (ugImport === 1)) {
                $('#filePaymentType').val('1');
                $('#filePayment').val('1');
                $('#_Param_Value_1').val('1');
            } else {
                /**
                 * The payment Import Type radio button should be checked
                 * if not checked at this point
                 */
                $('#filePaymentType').val('0');
                $('#filePayment').val('0');
            }
            if (optionImportMethod === 'File' || optionImportMethodNFI === 'PassThrough') {
                $('#filePayment').val(1);
                $('#filePaymentType').val(1);
            } else {
                $('#filePayment').val(0);
                $('#filePaymentType').val(0);
            }
        } else {
            $('#filePaymentType').val('0');
        }
        if (isPassThru && $('#OPTIONIMPORTMETHODNFI').is(':visible') === false) {
            $('#filePayment').val(1);
            $('#filePaymentType').val(1);
        }
    }

    if (confidentialSetting !== '2') {
        $('label[for=CONFIDENTIALIMPORTS]').hide();
        $('#CONFIDENTIALIMPORTS').hide();
        $('#fileConfidential').val(confidentialSetting);
        model.set('CONFIDENTIALIMPORTS', confidentialSetting);
    } else {
        $('label[for=CONFIDENTIALIMPORTS]').show();
        $('#CONFIDENTIALIMPORTS').show();
        $('#fileConfidential').val('0');
        model.set('CONFIDENTIALIMPORTS', '0');
    }
};

export default EntryView.extend({
    template,
    loadingTemplate,
    className: 'full-page',

    ui: {
        $cancelButton: 'div.widget-action-btn-group button[name="cancel"]',
        $fileImport: '#FILESELECTION',
        $progressBar: '.progress-bar',
    },

    initialize(options) {
        form = f1.getForm(this);

        this.model = store.get('template_listView_corp-actionModel');
        const preFill = {
            TNUM: this.model.get('TNUM'),
            IMPORTTYPE: this.model.get('TYPE'),
            TEMPLATECODE: this.model.get('CMB_TEMPLATE_CODE'),
            TEMPLATEDESCRIPTION: this.model.get('CMB_TEMPLATE_DESCRIPTION'),
            SUBTYPE: this.model.get('SUBTYPE'),
        };
        store.set('template_listView_corp-preFill', preFill);

        this.model.set('title', `${locale.get('ACH.modify')} ${this.model.get('CMB_TYPE_DESCRIPTION')} ${locale.get('ACH.Template')}`);

        EntryView.prototype.initialize.call(this, options);

        this.buttonList = [];
        this.buttonList.push({
            action: 'FILEIMPORT',
        });
        this.buttonList.push({
            action: 'FILEIMPORTREFRESH',
        });
        this.buttonList.push({
            action: 'CANCEL',
        });

        this.import = new ImportModel();
        this.listenTo(this.import, 'change:percentComplete', this.showProgressBar);
        this.listenTo(this.appBus, 'grid:selectRow', (view) => {
            // the view.model is the selected row
            selectedRow = view.model;
            processSelectedRow(this.pageView.model);
        });
    },

    fileimport() {
        const self = this;
        const { files } = this.ui.$fileImport[0];
        const [file] = files;

        if (this.prepareToImport()) {
            this.pageView.pageForm.applyPolicies(false);
        } else {
            return;
        }

        if (files && file) {
            const reader = new FileReader();

            reader.onload = (readerEvt) => {
                // show our progress bar
                self.ui.$progressBar.parent().removeClass('hide');
                const binaryString = readerEvt.target.result;
                self.import.set('fileName', file.name);
                self.import.set('importType', $('#ImportMapHostAp').val());
                self.import.set('eventName', $('#EventName').val());
                self.import.set('importJCode', $('#ImportJCODE').val());
                self.import.set('fileContent', binaryString);
                self.import.set('importParameters', self.getImportParameters());
                self.import.set('paymentData', self.getPaymentValues());
                self.import.save({}, {
                    success(model, confirmResponse) {
                        self.actionSuccess(confirmResponse);
                    },
                    error(model) {
                        self.actionError(model);
                    },
                });
            };

            const encoding = serverConfigParams.get('importCharset');

            if (!util.isEmpty(encoding)) {
                reader.readAsText(file, encoding);
            } else {
                reader.readAsText(file);
            }
        }
    },

    mandatoryFieldsForImport() {
        const importFormatGrid = this.pageView.gridComponentViews.IMPORTFORMAT;
        const { model } = this.pageView;
        let className = '';
        const rows = importFormatGrid.grid.getSelectedRows();
        const productCode = $('#_productCode').val();
        let bErrorFlag = false;

        if (model.validators.FUNCTIONCODE && model.validators.FUNCTIONCODE.exists) {
            if (model.get('FUNCTIONCODE') === '') {
                form.field('FUNCTIONCODE').$el.first().parent().addClass('has-error');
                bErrorFlag = true;
            } else if (model.get('FUNCTIONCODE') !== '') {
                form.field('FUNCTIONCODE').$el.first().parent().removeClass('has-error');
            }
        }

        if (productCode === 'USACH') {
            if (model.validators.EFFECTIVEDATE && model.validators.EFFECTIVEDATE.exists) {
                if (model.get('EFFECTIVEDATE') === '') {
                    form.field('EFFECTIVEDATE').$el.first().parent().addClass('has-error');
                    bErrorFlag = true;
                } else if (model.get('EFFECTIVEDATE') !== '') {
                    form.field('EFFECTIVEDATE').$el.first().parent().removeClass('has-error');
                }
            }

            if (model.validators.ID && model.validators.ID.exists) {
                if (model.get('ID') === '') {
                    form.field('ID').$el.first().parent().addClass('has-error');
                    bErrorFlag = true;
                } else if (model.get('ID') !== '') {
                    form.field('ID').$el.first().parent().removeClass('has-error');
                }
            }

            if (model.validators.TEMPLATECODE && model.validators.TEMPLATECODE.exists) {
                if (model.get('TEMPLATECODE') === '') {
                    form.field('TEMPLATECODE').$el.first().parent().addClass('has-error');
                    bErrorFlag = true;
                } else if (model.get('TEMPLATECODE') !== '') {
                    form.field('TEMPLATECODE').$el.first().parent().removeClass('has-error');
                }
            }

            if (model.validators.TEMPLATEDESCRIPTION
                && model.validators.TEMPLATEDESCRIPTION.exists) {
                if (model.get('TEMPLATEDESCRIPTION') === '') {
                    form.field('TEMPLATEDESCRIPTION').$el.first().parent().addClass('has-error');
                    bErrorFlag = true;
                } else if (model.get('TEMPLATEDESCRIPTION') !== '') {
                    form.field('TEMPLATEDESCRIPTION').$el.first().parent().removeClass('has-error');
                }
            }

            if (model.validators.CMB_TEMPLATE_CODE
                && model.validators.CMB_TEMPLATE_CODE.exists) {
                if (model.get('CMB_TEMPLATE_CODE') === '') {
                    form.field('CMB_TEMPLATE_CODE').$el.first().parent().addClass('has-error');
                    bErrorFlag = true;
                } else if (model.get('CMB_TEMPLATE_CODE') !== '') {
                    form.field('CMB_TEMPLATE_CODE').$el.first().parent().removeClass('has-error');
                }
            }
        }

        if (this.contextDef.productCode !== 'CM') {
            // reset
            $('[data-validate="FILESELECTION"]').text('');
            $('[data-validate="OPTIONIMPORTMETHOD"]').text('');
            form.field('FILESELECTION').$el.first().parent().addClass('required');

            if (rows.length === 0) {
                $('[data-validate="OPTIONIMPORTMETHOD"]').text(locale.get('ACH.error.SelectFormatName'));
                bErrorFlag = true;
            }

            if (model.get('OPTIONIMPORTMETHOD') === '') {
                bErrorFlag = true;
            }

            if ((rows.length === 0 || model.get('OPTIONIMPORTMETHOD') === '') && $('#OPTIONIMPORTMETHOD').length > 0) {
                ({ className } = $('#OPTIONIMPORTMETHOD')[0].parentElement);
                className += ' has-error';
                $('#OPTIONIMPORTMETHOD')[0].parentElement.className = className;
            }
        }

        if (form.field('FILESELECTION').getValue() === '') {
            $('[data-validate="FILESELECTION"]').text(locale.get('ACH.error.SelectUploadFile'));
            form.field('FILESELECTION').$el.first().parent().addClass('has-error');
            bErrorFlag = true;
        } else {
            form.field('FILESELECTION').$el.first().parent().removeClass('has-error');
        }

        return (!bErrorFlag);
    },

    prepareToImport() {
        if (!this.mandatoryFieldsForImport()) {
            return false;
        }
        if (!this.checkFileName()) {
            return false;
        }
        return true;
    },

    checkFileName() {
        const fileNameWithPath = form.field('FILESELECTION').getValue();
        let fileNameWithoutPath = '';
        let lastIndex = -1;
        if (fileNameWithPath.indexOf('\\') !== -1) {
            lastIndex = fileNameWithPath.lastIndexOf('\\');
            fileNameWithoutPath = fileNameWithPath.substring(
                lastIndex + 1,
                fileNameWithPath.length,
            );
        }

        if (fileNameWithoutPath !== '' && fileNameWithoutPath.length > 255) {
            $('[data-validate="FILESELECTION"]').text(locale.get('ACH.UploadFileNameLength.Error'));
            let { className } = $('#FILESELECTION')[0].parentElement;
            className += ' has-error';
            $('#FILESELECTION')[0].parentElement.className = className;
            return false;
        }
        return true;
    },

    fileimportrefresh() {
        if (selectedRow) {
            processSelectedRow(this.pageView.model);
            this.pageView.gridComponentViews.IMPORTFORMAT.refreshGridData();
        }
        form.field('FILESELECTION').setValue('');
        $('label[for=FUNCTIONCODE]').hide();
        $('#FUNCTIONCODE').addClass('hidden');
    },

    disableButtons() {
        domUtil.setDisabled(this.ui.$cancelButton, true);
    },

    getPaymentValues() {
        const { model } = this.pageView;
        const payValues = [];
        util.each(model.attributes, (value, key) => {
            if (!util.contains(excludeFieldsList, key) && value) {
                payValues.push({
                    name: key,
                    value,
                });
            }
        });

        return payValues;
    },

    getImportParameters() {
        const importParameters = [];
        if (!util.isNullOrUndefined($('#_Param_Name_0').val())) {
            importParameters.push({
                name: $('#_Param_Name_0').val(),
                value: $('#_Param_Value_0').val(),
            });
        }
        if (!util.isNullOrUndefined($('#_Param_Name_1').val())) {
            importParameters.push({
                name: $('#_Param_Name_1').val(),
                value: $('#_Param_Value_1').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_2').val())) {
            importParameters.push({
                name: $('#_Param_Name_2').val(),
                value: $('#_Param_Value_2').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_3').val())) {
            importParameters.push({
                name: $('#_Param_Name_3').val(),
                value: $('#_Param_Value_3').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_4').val())) {
            importParameters.push({
                name: $('#_Param_Name_4').val(),
                value: $('#_Param_Value_4').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_5').val())) {
            importParameters.push({
                name: $('#_Param_Name_5').val(),
                value: $('#_Param_Value_5').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_6').val())) {
            importParameters.push({
                name: $('#_Param_Name_6').val(),
                value: $('#_Param_Value_6').val(),
            });
        }

        if (!util.isNullOrUndefined($('#_Param_Name_7').val())) {
            importParameters.push({
                name: $('#_Param_Name_7').val(),
                value: $('#_Param_Value_7').val(),
            });
        }
        if (!util.isNullOrUndefined($('#_Param_Name_8').val())) {
            importParameters.push({
                name: $('#_Param_Name_8').val(),
                value: $('#_Param_Value_8').val(),
            });
        }

        const nonRunTimeParams = $('#NonRunTimeParams').val();
        if (nonRunTimeParams.length > 0) {
            const nameValuePairs = nonRunTimeParams.split('|');
            for (let i = 0; i < nameValuePairs.length; i += 1) {
                const nameValuePair = nameValuePairs[i].split('=');
                if (nameValuePair.length === 2) {
                    importParameters.push({
                        name: nameValuePair[0],
                        value: nameValuePair[1],
                    });
                }
            }
        }
        return importParameters;
    },

    showProgressBar() {
        const percent = `${this.import.get('percentComplete')}%`;

        this.ui.$progressBar.attr('aria-valuenow', this.import.get('percentComplete'))
            .css('width', percent).html(percent);
    },

});
