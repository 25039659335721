export default function (
    form,
    initialState,
) {
    const formState = form.formView.state;
    const name = form.field('NAME');

    if (initialState && formState === 'modify') {
        name.shouldBeReadOnly(true);
    }
}
