import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@glu/theming';
import useLocale from '../../hooks/useLocale'; // path may change

import ConversationPaymentDetail from '../RtpConversationPaymentDetail/RtpConversationPaymentDetail';
import styles from './RtpConversationHeader.styles';

const propTypes = {
    data: PropTypes.shape({
        TOTAL_INTERBANK_SETTLE_AMT: PropTypes.string,
        TOTAL_INTERBANK_SETTLE_CCY: PropTypes.string,
        CREDITOR_NAME: PropTypes.string,
        INTERBANK_SETTLE_DATE: PropTypes.string,
        REMITTANCE_INFO: PropTypes.string,
        INSTRUCTION_ID: PropTypes.string,
    }).isRequired,
    showDetailPage: PropTypes.func.isRequired,
    currentTab: PropTypes.string,
    showDetailLink: PropTypes.bool,
    conversationStarted: PropTypes.bool,
    // Internal classes hash - do not pass
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
    currentTab: null,
    showDetailLink: true,
    conversationStarted: false,
};

// TODO app resources!

const ConversationHeader = ({
    data, showDetailPage, showDetailLink, conversationStarted, classes, currentTab,
}) => {
    const { getLocaleString } = useLocale();
    const handleShowDetailPage = () => {
        showDetailPage();
    };

    const handlePrint = () => {
        window.console.log('Print not suppported yet');
    };

    // Hide Print Button Until Ready
    const showPrint = false;

    return (
        <div className={classes.conversationHeader}>
            <div className={classes.headerButtons}>
                {showDetailLink
                    && (
                        <button type="button" className="btn btn-tertiary" aria-label={getLocaleString('rtp.conversation.paymentDetail')} title={getLocaleString('rtp.conversation.paymentDetail')} onClick={handleShowDetailPage}>
                            <span className="icon-lines-arrow-down" />
                        </button>
                    )}
                { showPrint && conversationStarted && showDetailLink
                    && (
                        <button type="button" className="btn btn-tertiary print" aria-label={getLocaleString('common.printOptions.button.print')} title={getLocaleString('common.printOptions.button.print')} onClick={handlePrint}>
                            <span className="icon-print" />
                        </button>
                    )}
            </div>
            <h2>
                {getLocaleString('rtp.conversationDrawer.heading')}
                { currentTab === 'submitted'
                    ? ` ${getLocaleString('rtp.conversationDrawer.heading.submitted')}`
                    : ` ${getLocaleString('rtp.conversationDrawer.heading.received')}`}
            </h2>
            <ConversationPaymentDetail data={data} currentTab={currentTab} />
        </div>
    );
};
ConversationHeader.propTypes = propTypes;
ConversationHeader.defaultProps = defaultProps;
export default withStyles(styles)(ConversationHeader);
