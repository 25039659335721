import Layout from '@glu/core/src/layout';
import Formatter from 'system/utilities/format';
import util from '@glu/core/src/util';
import $ from 'jquery';
import store from 'system/utilities/cache';
import paymentLimitsItemTmpl from './paymentLimitsItem.hbs';

export default Layout.extend({
    template: paymentLimitsItemTmpl,

    ui: {
        $inputs: '.form-control',
    },

    events: {
        'blur @ui $inputs': 'updateModel',
    },

    regions: {
        paymentLimitsValidationMessagesRegion: '.paymentLimitsValidationMessagesRegion',
    },

    modelEvents: {
        change: 'setValidationViewState',
    },

    onRender() {
        this.setInputMask();
    },

    /**
     * Trigger updateModel function in model
     */
    updateModel() {
        this.model.updateModel(this.getInputValues());
    },

    /**
     * Get values from fields and pass on to update the model
     * @return {[type]} [description]
     */
    getInputValues() {
        return util.map(this.ui.$inputs, (input) => {
            const $input = $(input);
            return {
                restrictionlabel: $input.data('restrictionlabel'),
                value: $input.val(),
            };
        });
    },

    /**
     * Set currency mask for field
     */
    setInputMask() {
        this.$el.find('input').inputmask('decimal', Formatter.getCurrencyMaskOptions(true, true));
    },

    /**
     * Toggle the has-error class based on whether the corresponding validation
     * object key is true or false
     */
    setValidationViewState() {
        const validations = this.model.get('validations');
        this.ui.$inputs.each((index, input) => {
            const $input = $(input);
            const restrictionLabel = $input.data('restrictionlabel').toUpperCase().replace(/\s/g, '');
            $input.closest('div').toggleClass('has-error', !validations[restrictionLabel]);
        });
    },

    templateHelpers() {
        return {
            isOverall: this.model.get('productCode') === '*',
            readOnly: !store.get('segmentModify') && !store.get('segmentInsert'),
        };
    },
});
