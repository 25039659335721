import locale from '@glu/locale';
import userInfo from 'etc/userInfo';
import util from '@glu/core/src/util';
import Model from '@glu/core/src/model';
import services from 'services';
import http from '@glu/core/src/http';
import Dialog from '@glu/dialog';
// eslint-disable-next-line import/no-unresolved
import DatePicker from '@glu/datepicker-classic';
import alert from '@glu/alerts';
import Formatter from 'system/utilities/format';
import Confirms from 'common/dynamicPages/views/workflow/confirmData';
import store from 'system/utilities/cache';
import serverConfigParams from 'system/webseries/models/configurationParameters';
import AuditHistoryView from 'app/reports/views/auditHistoryView';
import payUtil from 'common/util/paymentUtil';
import constants from 'common/dynamicPages/api/constants';
import datePickerTmpl from 'app/loans/views/datePicker.hbs';
import requestServices from 'common/util/services';

const AuditReportModel = Model.extend({});

export default {
    paymentCenter() {
        return store.get('payment_listView_corp-listRoute') || 'PAYMENTS/managePayments';
    },

    templateCenter() {
        return store.get('template_listView_corp-listRoute') || 'PAYMENTS/manageTemplates';
    },

    allPayOptions() {
        return {
            standardPaymentSupported: {
                key: 'STANDARD_AMOUNT',
                value: locale.get('RTGS.LOANS.OPTION.STANDARD'),
            },

            principalPaymentSupported: {
                key: 'PRINCIPAL_AMOUNT',
                value: locale.get('RTGS.LOANS.OPTION.PRINCIPAL'),
            },

            interestPaymentSupported: {
                key: 'INTEREST_AMOUNT',
                value: locale.get('RTGS.LOANS.OPTION.INTEREST'),
            },

            escrowPaymentSupported: {
                key: 'ESCROW_AMOUNT',
                value: locale.get('RTGS.LOANS.OPTION.ESCROW'),
            },

            otherPaymentSupported: {
                key: 'OTHER_AMOUNT',
                value: locale.get('RTGS.LOANS.OPTION.OTHER'),
            },
        };
    },

    defaultDatePickerParameters: {
        paymentType: '',
        debitBank: '',
        creditCurrency: '',
        debitCurrency: '',
        debitBankCountry: '',
        creditBankCountry: '',
    },

    /**
     *
     * Uses the typeCode to return the appropriate locale
     *
     * @param typeCode
     * @returns {*}
     */
    getOriginLabel(typeCode) {
        if (typeCode === 'LOANPAY') {
            return locale.get('common.fromAccount');
        }
        if (typeCode === 'LOANDRAW') {
            return locale.get('common.loanAccount');
        }
        return undefined;
    },

    /**
     *
     * Uses the typeCode to return the appropriate locale
     *
     * @param typeCode
     * @returns {*}
     */
    getDestinationLabel(typeCode) {
        if (typeCode === 'LOANPAY') {
            return locale.get('common.loanAccount');
        }
        if (typeCode === 'LOANDRAW') {
            return locale.get('common.toAccount');
        }
        return undefined;
    },

    /**
     *
     * Sorts through preferences. When an option is true, it is pushed into an array.
     * Handy check for the first option for rendering purposes
     *
     * @param preferences
     * @returns {Array}
     */
    getAvailablePaymentOptions(preferences = {}) {
        const allPaymentOptions = this.allPayOptions();
        return Object.keys(preferences)
            .filter(option => preferences[option] && allPaymentOptions[option])
            .map((option, index, arr) => {
                const count = arr.length;
                allPaymentOptions[option].firstOption = (index === 0);
                allPaymentOptions[option].amountLocked = !!(preferences[`${option.split('Supported')[0]}AmountLocked`]);

                if (count === 1) {
                    allPaymentOptions[option].onlyOption = true;
                } else if (count > 1) {
                    allPaymentOptions[option].moreThanOne = true;
                }
                return allPaymentOptions[option];
            });
    },

    renderDatePicker(blockedDates, cutoffTimes, options) {
        let localBlockedDates = blockedDates;
        let localCutoffTimes = cutoffTimes;
        let localOptions = options;
        const datePickerOptions = {
            tagName: 'div',
            labelText: '',
            minDate: new Date(),
            maxDate: 365,
            dateFormat: userInfo.getDateFormat(),
            rangePicker: false,
            imgAltText: locale.get('sbPayments.datePickerIconAltText'),
            template: datePickerTmpl,

            errorMsgs: {
                required: locale.get('sbPayments.datePicker.errorMsgs.required'),
                invalidDateFormat: locale.get('sbPayments.datePicker.errorMsgs.invalidDateFormat'),
                blockedDate: locale.get('sbPayments.datePicker.errorMsgs.blockedDate'),
                afterCutoff: locale.get('sbPayments.datePicker.errorMsgs.afterCutoff'),
                tooFarPast: locale.get('sbPayments.datePicker.errorMsgs.tooFarPast'),
                tooFarFuture: locale.get('sbPayments.datePicker.errorMsgs.tooFarFuture'),
            },

            customSettings: {
                closeOnSelect: true,
            },
        };

        localBlockedDates = localBlockedDates || [];
        localCutoffTimes = localCutoffTimes || {};
        localOptions = localOptions || {};

        util.extend(datePickerOptions, localOptions);

        this.datePicker = new DatePicker(datePickerOptions);

        this.datePicker.blockDisabledDates(localBlockedDates, localCutoffTimes);

        return this.datePicker;
    },

    /**
     *
     * Takes a string and inserts commas at certain positions. Polish to be added.
     *
     * @param string
     * @returns {*|string}
     */
    currencyFormatter(string) {
        if (string) {
            // return string.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            return Formatter.formatCurrency(string);
        }
        return undefined;
    },

    /**
     *
     * Uses typeCode to return locale title
     *
     * @param typeCode
     * @returns {*}
     */

    getLocaleTitle(typeCode) {
        if (typeCode === 'LOANPAY') {
            return locale.get('common.loanPayment');
        }
        if (typeCode === 'LOANDRAW') {
            return locale.get('common.loanDrawdown');
        }
        return undefined;
    },

    /**
     *
     * Sets the paymentType and returns the object
     *
     * @param typeCode
     * @returns {*}
     */

    getDatePickerParameters(typeCode) {
        this.defaultDatePickerParameters.paymentType = typeCode;
        return this.defaultDatePickerParameters;
    },

    /**
     *
     * Used to parse data returned from the createPaymentFromTemplate service
     *
     * @param data
     * @returns {{}}
     */

    parseTemplateData(data) {
        const formattedModelObjects = {};
        const { columns } = data[0];
        for (let x = 0; x < columns.length; x += 1) {
            const column = columns[x];
            formattedModelObjects[column.columnName] = util.unescape(column.columnValue);
        }
        return formattedModelObjects;
    },

    /**
     *
     * Loops through the target element. (e.g. [data-hook="getAmount"])
     * Removes invalid characters and updates the element's value if necessary
     * Returns the total
     *
     * @param amountFields
     * @returns {*}
     */
    getTotal(amountFields) {
        let paymentTotal = 0;

        util.each(amountFields, (el) => {
            const element = el;
            // remove unwanted characters before calculating total
            const amount = Formatter.removeNumberFormat(element.value);

            // Number.isNaN is safe here, as the Formatter returns a Number.
            if (!Number.isNaN(amount) && amount > 0) {
                // update the element's value
                element.value = Formatter.formatNumber(amount);
                paymentTotal += +amount;
            }
        }, this);

        return {
            formatAs: {
                number: Formatter.formatNumber(paymentTotal),
                currency: Formatter.formatCurrency(paymentTotal),
                noFormat: paymentTotal.toFixed(2),
            },
        };
    },

    /*
     *
     * Controller Functions
     */

    getPaymentPreferences() {
        const data = {
            item: [{
                name: 'ACCOUNTTYPE',
                value: '',
            }, {
                name: 'SUBACCOUNT_TYPE',
                value: '',
            }],
        };
        return new Promise((resolve, reject) => {
            http.post(services.getPreferences, data, (response) => {
                resolve(response);
            }, () => {
                reject(locale.get('loans.loading.error'));
            });
        });
    },

    /**
     * NH-159734
     * While making the loan payment when user selects the loan account,
     * We are making another call to fetch the latest preferences and
     * displaying ADD ANOTHER PAYMENT OPTION based on the value of updated preferences
     */
    getPaymentPreferencesData(model) {
        const data = {
            item: [
                {
                    name: 'ACCOUNTTYPE',
                    value: model?.get('BENE_ACCOUNT') ? model.get('BENE_ACCOUNT_TYPE') : '',
                },
                {
                    name: 'SUBACCOUNT_TYPE',
                    value: model?.get('BENE_ACCOUNT') ? model.get('BENEACCOUNT_SUBTYPE') : '',
                },
            ],
        };
        return requestServices.postData(services.getPreferences, data);
    },

    getTemplateData(tnum, typeCode, productCode) {
        return new Promise((resolve, reject) => {
            const data = {
                item: [
                    {
                        name: 'TNUM',
                        value: tnum,
                    },
                    {
                        name: 'typeCode',
                        value: typeCode,
                    },
                    {
                        name: 'productCode',
                        value: productCode,
                    },
                ],
            };
            http.post(services.createPaymentFromTemplate, data, (response) => {
                resolve(response);
            }, () => {
                reject(locale.get('loans.template.error'));
            });
        });
    },

    /**
     *
     * @param data
     * @returns {{}}
     */

    convertServerJSONToModelAttributes(data) {
        const parsedData = {};
        for (let x = 0; x < data.length; x += 1) {
            parsedData[data[x].name] = data[x].value;
        }
        return parsedData;
    },

    /**
     *
     * Accepts the response from the Rest API service and joins each string in
     * the message array
     * Return the alert view.
     *
     * @param model
     * @returns {view}
     */
    getAlertView(model) {
        const { error } = model;
        const cnfm = model.error ? model.error.confirms : model.confirms;
        const message = error ? error.message[0] : model.message[0];

        const confirms = new Confirms({
            confirms: cnfm,
        });

        return alert.danger(
            message,
            {
                details: confirms,
                canDismiss: true,
                icon: 'warning',
            },
        );
    },

    /**
     *
     * Accepts the model, scans it for keys used by the audit trail.
     * Returns an array the template helper can loop through
     *
     * @param model
     * @returns {Array}
     */
    extractApproversFromModel(model) {
        const approvers = [];
        const data = {};
        let count = 0;
        let number;

        Object.keys(model || {}).forEach((key) => {
            if (key.indexOf('APPROVED_') > -1) {
                number = key.slice(-1);
                // number is a string, so we do the Number conversion for isNaN
                if (!Number.isNaN(Number(number))) {
                    if (key.indexOf('NAME') > -1) {
                        count += 1;
                    }
                    data[key] = model[key];
                }
            }
        });

        for (let x = 1; x <= count; x += 1) {
            if (data[`APPROVED_BY_NAME_${x}`] !== '') {
                approvers.push({
                    position: x,
                    userId: data[`APPROVED_BY_${x}`],
                    name: data[`APPROVED_BY_NAME_${x}`],
                    timestamp: data[`APPROVED_TIMESTAMP_${x}`],
                    usergroup: data[`APPROVED_USERGROUP_${x}`],
                });
            }
        }

        return approvers;
    },

    getEntitlements(typeCode, payload) {
        const request = {
            functionCode: 'TMPL',
            productCode: 'RTGS',
            typeCode,
            actionMode: 'INSERT',
            ...payload,
        };

        return new Promise((resolve, reject) => {
            const url = services.generateUrl('accessService/hasAccess');
            http.post(
                url, request, (response) => {
                    resolve(response);
                },
                (e) => {
                    reject(e);
                },
            );
        });
    },

    removeOptionIcon(view, shouldHide) {
        const dom = view.$('.payment-option .icon-close');
        if (shouldHide !== undefined) {
            dom.toggle(!shouldHide);
        } else {
            dom.remove();
        }
    },

    saveWithWarning(view, options) {
        const duplicateAccepted = constants.DUPLICATE_ACCEPTED_INDICATOR;

        if (options && options.warningName && options.warningName === duplicateAccepted) {
            view.model.set({
                duplicateAccepted: 'true',
            });
        }

        view.model.set({
            _saveWithWarning: 'true',
        });
        view.submit();
    },

    openDialog(view) {
        const tNum = view.model.get('TNUM');
        const reportModel = new AuditReportModel();
        let reportKey = 'TNUM';
        if (view.model.get('PRODUCT') === 'USACH') {
            reportKey = 'BATCHTNUM';
        }

        const additionalParameters = [{
            name: 'TNUM',
            value: tNum,
        }, {
            name: 'REPORTKEY',
            value: reportKey,
        }, {
            name: 'PRODUCTCODE',
            value: view.model.get('PRODUCT'),
        }, {
            name: 'FUNCTIONCODE',
            value: view.model.get('FUNCTION'),
        }, {
            name: 'TYPECODE',
            value: view.model.get('TYPE'),
        }, {
            name: 'SUBTYPE',
            value: view.model.get('SUBTYPE'),
        }];

        let reportID = '20300';
        if (view.model.get('FUNCTION') === 'TMPL' || view.model.get('FUNCTION') === 'BHTMPL') {
            reportID = '20310';
        }
        reportModel.set('productCode', 'RTGS');
        reportModel.set('reportId', reportID);
        reportModel.set('filterData', '');
        reportModel.set('additionalParameters', additionalParameters);

        const auditHistoryView = new AuditHistoryView({
            model: reportModel,
        });

        if (Dialog.activeModal) {
            const originalModalBody = Dialog.activeModal.bodyView;
            const originalModalButtons = Dialog.activeModal.buttons;
            const restoreDialog = function () {
                Dialog.activeModal.bodyView = originalModalBody;
                Dialog.activeModal.buttons = originalModalButtons;
                Dialog.activeModal.render();
            };
            Dialog.activeModal.setBody(auditHistoryView);
            Dialog.activeModal.buttons = [{
                callback: restoreDialog,
                className: 'btn btn-primary',
                text: locale.get('button.back'),
            }];
            Dialog.activeModal.render();
            auditHistoryView.$('[data-action="cancel"]').remove();
        } else {
            Dialog.open(auditHistoryView);
        }
    },

    applyMask(view) {
        view.ui.$amount.inputmask({
            alias: 'number',
            showMaskOnHover: false,
        });
    },

    /*
     * @description common function used by loan payments and loan draw, which
     * calls to payment utility updateCutoff, to retrieve cutoff from server
     * @param options - option values including product, function, type
     * @param model - model for the page
     * @param datePicker - link to ui-datepicker-trigger for the page
     */
    updateCutoff(options, model, datepicker) {
        const { functionCode } = options;
        const type = options.typeCode;
        const product = options.productCode;
        const subType = '*';
        const debitBankCode = model.get('DEBIT_BANK_CODE');
        const ccDebitBankCode = model.get('Debit_Bank_Code');
        const debitCountry = model.get('DEBIT_COUNTRY');
        const ccDebitCountry = model.get('Debit_Country');
        if (functionCode !== 'BHTMPL' && functionCode !== 'TMPL') {
            if (!debitBankCode && ccDebitBankCode) {
                model.set('DEBIT_BANK_CODE', ccDebitBankCode);
            }
            if (!debitCountry && ccDebitCountry) {
                model.set('DEBIT_COUNTRY', ccDebitCountry);
            }
            payUtil.updateCutoff(product, functionCode, type, subType, 'insert', model, datepicker);
        }
    },

    /**
     * @description check the combo collection to see if it has only one value,
     * if so the model is set to that value
     * @param model - model for the page
     * @param collection - combo collection of items
     * @param id - id of the combo control
     */
    selectIfOnlyOne(model, collection, id) {
        const ifOne = (serverConfigParams.get('SelectIfOnlyOne') && serverConfigParams.get('SelectIfOnlyOne').toUpperCase() === 'TRUE');
        if (ifOne && collection.models.length === 1) {
            if (util.isEmpty(model.get(id))) {
                model.set(id, collection.models[0].get('name'));
            }
        }
    },
};
