import CollectionView from '@glu/core/src/collectionView';
import EntitlementView from './entitlementView';

export default CollectionView.extend({
    tagName: 'div',
    className: 'limit-set-list',
    itemView: EntitlementView,

    initialize(opts) {
        this.itemViewOptions = {
            mode: opts.mode,
        };
    },
});
