import util from '@glu/core/src/util';
import Collection from '@glu/core/src/collection';
import userInfo from 'etc/userInfo';
import InquiryApi from 'system/webseries/api/inquiry';

export default Collection.extend({
    initialize(opts) {
        this.inquiryId = opts.inquiryId;
        this.includeMapData = opts.includeMapData || InquiryApi.INCLUDE_MAP_DATA;
        this.typeCode = opts.typeCode || InquiryApi.ALL_TYPE_CODE;
        this.entryMethod = opts.entryMethod || InquiryApi.DEFAULT_ENTRY_METHOD;
        this.productCode = opts.productCode || InquiryApi.DEFAULT_PRODUCT_CODE;
        this.actionMode = opts.actionMode || InquiryApi.INSERT_ACTION_MODE;
        this.functionCode = opts.functionCode || InquiryApi.ALERT_FUNCTION_CODE;
        this.queryField = opts.queryField || undefined;
        this.queryPageSize = opts.queryPageSize || InquiryApi.DEFAULT_QUERY_PAGE_SIZE;
        this.queryOffset = opts.queryOffset || InquiryApi.DEFAULT_QUERY_OFFSET;
        this.customFilters = opts.customFilters || undefined;
        this.labelFormat = opts.labelFormat || undefined;
        this.viewId = opts.viewId || InquiryApi.DEFAULT_VIEW_ID;
        this.userId = userInfo.get('id');
        this.userGroup = userInfo.get('group');
        this.password = userInfo.get('password');
        this.allowDuplicates = opts.allowDuplicates || undefined;
    },

    sync(method, model, options) {
        const self = this;

        /*
         * Use the inquiry service to retrieve the query results.  Transform into
         * a format can be easily consumed by web components.
         */
        InquiryApi.getQueryResults({
            inquiryId: self.inquiryId,
            includeMapData: self.includeMapData,
            typeCode: self.typeCode,
            entryMethod: self.entryMethod,
            productCode: self.productCode,
            actionMode: self.actionMode,
            functionCode: self.functionCode,
            viewId: self.viewId,
            queryField: self.queryField,
            queryPageSize: self.queryPageSize,
            queryOffset: self.queryOffset,
            customFilters: self.customFilters,
            labelFormat: self.labelFormat,
            userGroup: self.userGroup,
            userId: self.userId,
            password: self.password,
            allowDuplicates: self.allowDuplicates,
        }).done((data) => {
            options.success(data);
        });
    },

    /**
     * Extract results from the response.
     *
     * @param result
     */
    parse(result) {
        try {
            // do we need to hide or sort records like inquiry header service?
            return util.map(result.queryResponse.QueryData.queryRows, function (row) {
                return util.extend(row, this.parseRow(row));
            }, this);
        } catch (err) {
            return [];
        }
    },

    /**
     * Map incoming row data.
     *
     * @param row
     * @returns {{}}
     */
    parseRow(row) {
        const extRow = {};

        extRow.id = row.name;

        // invoke the label format function if provided.
        if (util.isFunction(this.labelFormat)) {
            extRow.text = this.labelFormat(row);
        } else {
            extRow.text = row.label;
        }

        return extRow;
    },
});
