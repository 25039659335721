import Layout from '@glu/core/src/layout';
import ItemView from '@glu/core/src/itemView';
import moment from 'moment';
import userInfo from 'etc/userInfo';
import Model from '@glu/core/src/model';
import Collection from '@glu/core/src/collection';
import util from '@glu/core/src/util';
import Dialog from '@glu/dialog';
import alert from '@glu/alerts';
import locale from '@glu/locale';
import d3 from 'd3';
import c3 from 'c3';
import $ from 'jquery';
import GridApi from 'common/dynamicPages/api/grid';
import ListView from 'common/dynamicPages/views/workflow/list';
import cashFlowChartLayoutTmpl from './cashFlowChartLayout.hbs';
import ToggleButtonsView from './controls/toggleButtons';
import DateRangeScrubberView from './controls/dateRangeScrubber';
import AccountSelectView from './controls/accountSelect';
import PlanSelectView from './controls/planSelect';
import ScenarioCollection from '../collection/scenario';
import ChartViewSettings from '../model/chartViewSettings';
import LineCollection from '../collection/line';
import LineManagerView from './controls/lineManager';
import CashFlowChangeLayout from './cashFlowChangeLayout';
import CashFlowMatchLayout from './cashFlowMatchLayout';
import ManagerLayout from './controls/managerLayout';
import AccountCollection from '../collection/account';
import ChartCollection from '../collection/chart';
import CategoryCollection from '../collection/category';
import CategoryService from '../service/category';
import itemService from '../service/item';
import PlanCollection from '../collection/plan';
import ScenarioService from '../service/scenario';
import AlertDetail from './successDetail';
import ImportDetail from './importDetail';

const EmptyChart = ItemView.extend({
    template() {
        return `<h2>${locale.get('cashflow.no.account.transactions')}</h2>`;
    },
});

const SERVER_DATE_FORMAT = 'MM/DD/YY';

// TODO grid action list should only show remove when not a credit or debit

let FILTER_DATE_FORMAT = 'MM/DD/YYYY';

const TransactionsLayout = Layout.extend({
    template: cashFlowChartLayoutTmpl,
    className: 'widget-transactionsgrid-view page cashflow-forecasting',

    regions: {
        alertRegion: '[data-region="alert-region"]',
        grid: '.chart-grid',
        c3Graph: '.c3-transactions-graph',
        drawer: '.account-settings-drawer',
        scenarios: 'div[data-hook="settingsDrawer"]',
        lines: '.line-settings-drawer',
        planCtrl: '.plan-settings-drawer',
        sliderControl: '.slider-control-region',
        settingsButtons: '.settings-buttons-region',
        drawerPrimary: '.scenario-settings-drawer .primary',
        drawerSecondary: '.scenario-settings-drawer .secondary',
    },

    initialize(options) {
        Layout.prototype.initialize.call(this, options);

        // override - userInfo does not have the right date format when the module is loaded
        FILTER_DATE_FORMAT = userInfo.getDateFormat();

        this.accounts = new AccountCollection();
        this.hasTransferEntitlement = options.hasTransferEntitlement;
        this.emptyChart = new EmptyChart();
        this.c3Chart = undefined;
        this.controlsVisible = false;
        this.scenarioControlsVisible = false;
        this.lineControlsVisible = false;
        this.planControlsVisible = false;
        this.lineAutoCreated = false;
        this.scenarioCollection = new ScenarioCollection([]);

        this.categoryService = new CategoryService();
        this.plans = new PlanCollection(
            {},
            {
                categoryService: this.categoryService,
            },
        );

        this.inflowCategories = new CategoryCollection(
            [],
            {
                type: 'INFLOW',
                categoryService: this.categoryService,
            },
        );
        this.outflowCategories = new CategoryCollection(
            [],
            {
                type: 'OUTFLOW',
                categoryService: this.categoryService,
            },
        );

        this.chartViewSettings = new ChartViewSettings({});
        this.chartViewSettings.setRange(-7, 7);

        this.chartCollection = new ChartCollection(
            [],
            {
                chartViewSettings: this.chartViewSettings,
            },
        );

        this.lineCollection = new LineCollection(
            [],
            {
                scenarioCollection: this.scenarioCollection,
            },
        );

        this.collection = this.chartCollection;
        this.initViews();
    },

    initViews() {
        // results.accounts is the singleton account collection
        this.listenTo(
            this.accounts,
            {
                sync: this.updateChartCollection,
            },
        );

        this.listenTo(
            this.chartViewSettings,
            {
                'change sync': this.updateChartCollection,
                dateUpdateStart: util.partial(this.toggleChart, false),
                dateUpdateSlide: this.updateChartDateOverlay,
                dateUpdateFinish: util.partial(this.toggleChart, true),
            },
            this,
        );

        this.listenTo(
            this.chartCollection,
            {
                reset: this.rebuildChart,
                error: this.clearChart,
                'reset error': this.updateGrid,
            },
            this,
        );

        this.listenTo(
            this.scenarioCollection,
            {
                sync: this.handleScenarioSync,
                show: this.showScenarioTransactions,
            },
            this,
        );

        this.listenTo(
            this.plans,
            {
                'change:selected sync:service': this.handlePlanSelection,
            },
            this,
        );

        this.listenTo(
            this.lineCollection,
            {
                // change visibility or add/remove can be handled locally
                'add remove scenarioChange change:visible change:colorValue change:hiddenScenarios': this.rebuildChart,
            },
            this,
        );

        this.listenTo(
            itemService,
            {
                sync: this.update,
            },
            this,
        );

        this.listenTo(
            ScenarioService,
            {
                sync: this.handleScenarioSync,
            },
            this,
        );

        // this kicks off all fetches and renders
        this.scenarioCollection.fetch();
    },

    handleScenarioSync(collection, data, options) {
        if (util.isUndefined(options) || options.source !== 'update') {
            this.update();
        }
    },

    notify(model, response, options) {
        let message = '';

        const alertOptions = {
            canDismiss: true,

            details: new AlertDetail({
                model,
                accounts: this.accounts,
                inflowCategories: this.inflowCategories,
                outflowCategories: this.outflowCategories,
                scenarioCollection: this.scenarioCollection,
            }),
        };

        if (model instanceof Collection) {
            alertOptions.details = new ImportDetail({
                model,
            });
        }

        switch (options.endPoint) {
        case '/cashflow/save':
            message = locale.get('cashflow.entry.created');
            break;
        case '/cashflow/delete':
            message = locale.get('cashflow.entry.deleted');
            break;
        case '/cashflow/update':
            message = locale.get('cashflow.entry.updated');
            break;
        case '/cashflow/upload':
            message = locale.get('cashflow.entry.imported');
            break;
        default:
        }

        // alert.negative if failed
        this.alertRegion.show(alert.success(message, alertOptions));
    },

    updateGrid() {
        if (this.gridView) {
            this.gridView.refreshGridData();
        }
    },

    update(...args) {
        const [updated] = args;
        if (args.length === 3) {
            this.notify(...args);
        }

        this.updateChartCollection();

        if (updated && util.isFunction(updated.has) && updated.has('CASHFLOWSCENARIOID')) {
            this.scenarioCollection.fetch({
                source: 'update',
            });
        }

        this.updateGrid();

        this.accounts.loadAccounts({
            source: 'update',
        });
    },

    ui: {
        chartWrapper: '.chart-wrapper',
        $c3Graph: '.c3-transactions-graph',
        $btnRemainingView: '.btn-remaining-view',
        $btnInvoiceView: '.btn-invoice-view',
        $btnBillView: '.btn-bill-view',
        $btnAddCashFlow: '.add-cashflow',
        $c3EventRect: '.c3-event-rect',
        $fileCashFlowImport: '.cash-flow-import',
        $btnAccountSettings: '.btn-chart-settings',
        $btnLineSettings: '.btn-line-settings',
        $btnPlanSettings: '.btn-plan-settings',
        $btnEditLines: '.btn-edit-lines',
        $accountSettingsDrawer: '.account-settings-drawer',
        $lineSettingsDrawer: '.line-settings-drawer',
        $planSettingsDrawer: '.plan-settings-drawer',
        $overlay: '.chart-date-overlay',
        $dropOverlay: '.drop-overlay',
        $btnClose: '.scenario-settings-drawer .close',
        $linkEditPlans: '.edit-plan-link',
    },

    events: {
        'click @ui.$btnRemainingView': 'toggleRemainingChart',
        'click @ui.$btnInvoiceView': 'toggleInFlowChart',
        'click @ui.$btnBillView': 'toggleOutFlowChart',
        'click @ui.$btnAddCashFlow': 'addCashFlow',
        'click @ui.$btnAccountSettings': 'toggleChartControl',
        'click @ui.$btnEditLines': 'toggleScenarioControlViaEdit',
        'click @ui.$btnLineSettings': 'toggleLineControl',
        'click @ui.$btnPlanSettings': 'togglePlanControl',
        'click @ui.$btnClose': 'toggleScenarioControl',
        'click .c3-event-rect': 'didClickOnChart',
        'dblclick .c3-event-rect': 'didDblClickOnChart',
        'click @ui.$fileCashFlowImport': 'handleFileImport',
        'click @ui.$linkEditPlans': 'togglePlanControl',
        'click [data-hook="refresh-button"]': 'updateGrid',
    },

    handlePlanSelection(plan) {
        /*
         * because we are listening on change:selected in the collection,
         * we get a plan summary object here.
         */
        if (!plan || !plan.get('selected')) {
            return;
        }

        // this will trigger the chart to update.
        this.chartViewSettings.set('plan', plan);
        this.addPlanFilter(plan.get('multiYearPlanId'));
    },

    addPlanFilter(id) {
        // remove other cashflowplanid filters first.
        if (!this.gridView.grid) {
            // sometimes this doesn't exist yet.
            return;
        }

        this.gridView.grid.filterProc.removeFilters('MULTIYEARPLANID');

        /*
         * see common/dynamicPages/api/gridWrapper.js line 193+
         * use IN with -1 (for non plan items) + id for plan items
         */
        this.gridView.grid.filterProc.addFilter({
            type: 'number',
            field: 'MULTIYEARPLANID',

            // -1 is the view value for null in this case.
            equality: 'IN',

            value: [-1, id],
            label: locale.get('cashflow.cashFlowPlanFilter'),
        });
    },

    showScenarioTransactions(scenario) {
        const filterFunction = util.partial((model, field, value) => model.get(field) === value, util, 'CASHFLOWSCENARIOID', scenario.get('cashFlowScenarioId'));

        const label = scenario.get('name');

        this.addScenarioFilter(label, filterFunction, [scenario.get('cashFlowScenarioId')]);
    },

    addScenarioFilter(label) {
        /*
         * remove other filters to show all transactions in this scenario
         * using filterProc.clear triggers a second http request, so silently reset
         */
        this.gridView.grid.filterProc.filters.reset(
            [],
            {
                silent: true,
            },
        );

        // see common/dynamicPages/api/gridWrapper.js line 193+
        this.gridView.grid.filterProc.addFilter({
            // CASHFLOWSCENARIOID is not in table
            type: 'string',

            // 'CONTAINS' string match against name
            field: 'CASHFLOWSCENARIO',

            value: label,
            label,
        });
    },

    setDraggableHelperSize(ev, ui) {
        const $original = this.$(ev.currentTarget);
        const $helper = ui.helper;

        $helper.width($original.width());
        $helper.height($original.height());
    },

    showDroppables() {
        this.ui.$dropOverlay.show().find('div').droppable({
            scope: 'transaction',
            hoverClass: 'drop-hover',
            tolerance: 'pointer',
            drop: util.bind(this.handleDrop, this),
        });
    },

    handleDragStart(ev, ui) {
        this.setDraggableHelperSize(ev, ui);
        this.showDroppables();
    },

    handleDrop(e, ui) {
        const cid = ui.draggable.data('model-cid');
        const model = this.gridView.grid.collection.get(cid);
        const length = +this.$(e.target).data('length');
        const unit = this.$(e.target).data('unit');

        if (model && !util.isNaN(length)) {
            const currentDate = moment(model.get('EXPECTEDDATE'), FILTER_DATE_FORMAT);
            const date = moment(currentDate).add(length, unit).format(FILTER_DATE_FORMAT);
            model.set('EXPECTEDDATE', date);
            itemService.update(model);
        }
    },

    hideDroppables() {
        this.ui.$dropOverlay.fadeOut().find('div').droppable('destroy');
    },

    draggableHelper(e) {
        const $dragger = $('<div class="grid transaction-dragger"><div class="table"><table><tbody></tbody></table></div></div>');
        $dragger.find('tbody').append($(e.currentTarget).clone());
        return $dragger;
    },

    removeActions() {
        /*
         * need to be able to remove buttons from the grid actions as appriate.
         * this happens after the grid is rendered - so there is a slight moment of
         * flickering
         * on large data sets.  (note listening to gridapi:loaded doesn't help.)
         */
        const { collection } = this.gridView.grid;
        collection.each((rowParam) => {
            const row = rowParam;
            // no-one gets the view options
            let MODIFY = null;
            let REMOVE = null;
            let MATCH = null;
            /*
             * We can't be sure of the index of the buttons
             * we have to loop through them and find each based on the action name
             * Also, we don't need View because we already have Modify
             */
            for (let i = 0; i < row.buttons.length; i += 1) {
                if (row.buttons[i].action === 'MATCH') {
                    MATCH = row.buttons[i];
                } else if (row.buttons[i].action === 'MODIFY') {
                    MODIFY = row.buttons[i];
                } else if (row.buttons[i].action === 'REMOVE') {
                    REMOVE = row.buttons[i];
                }
            }

            if (row.get('CASHFLOWTYPE') === 'CREDIT' || row.get('CASHFLOWTYPE') === 'DEBIT') {
                row.buttons = [MODIFY];
            } else if (row.get('ISINSCENARIO') !== '0') {
                row.buttons = [MODIFY, REMOVE];
            } else if (row.get('DESCRIPTION').indexOf('(PLAN)') === 0) {
                row.buttons = [];
            } else {
                row.buttons = [MODIFY, REMOVE, MATCH];
            }
        });
    },

    createDraggables() {
        const { collection } = this.gridView.grid;
        this.$('tr[data-model-cid]').not(util.bind((i, el) => {
            const model = collection.get($(el).data('model-cid'));
            if (util.isUndefined(model)) {
                return false;
            }
            return model.get('CASHFLOWTYPE') === 'CREDIT' || model.get('CASHFLOWTYPE') === 'DEBIT';
        }, this)).draggable({
            helper: this.draggableHelper,
            appendTo: 'body',
            scope: 'transaction',
            start: util.bind(this.handleDragStart, this),
            stop: util.bind(this.hideDroppables, this),
        });
    },

    updateChartDateOverlay(values) {
        const sDateString = moment(new Date()).add(values[0], 'days').format(FILTER_DATE_FORMAT);
        const eDateString = moment(new Date()).add(values[1], 'days').format(FILTER_DATE_FORMAT);
        this.ui.$overlay.text(`${sDateString} - ${eDateString}`);
    },

    toggleChart(show) {
        if (show) {
            this.ui.$overlay.hide();
        } else {
            this.updateChartDateOverlay([this.chartViewSettings.get('daysBack'), this.chartViewSettings.get('daysForward')]);
            this.ui.$overlay.css('display', 'flex');
        }
    },

    updateChartCollection(model, data, options) {
        if (options && options.source === 'update') {
            return;
        }

        this.chartCollection.loadItems();
    },

    handleFileImport() {
        Dialog.custom(new CashFlowChangeLayout({
            model: new Model(),
            accountCollection: this.accounts,
            inflowCategories: this.inflowCategories,
            outflowCategories: this.outflowCategories,
            scenarioCollection: this.scenarioCollection,
            transactionCollection: this.collection,
            action: 'import',
        }));
    },

    toggleRemainingChart() {
        this.ui.$btnRemainingView.toggleClass('active');
        this.c3Chart.toggle(util.without(util.pluck(this.c3Chart.data(), 'id'), 'inFlow', 'outFlow'));
    },

    toggleInFlowChart() {
        this.ui.$btnInvoiceView.toggleClass('active');
        this.c3Chart.toggle('inFlow');
    },

    toggleOutFlowChart() {
        this.ui.$btnBillView.toggleClass('active');
        this.c3Chart.toggle('outFlow');
    },

    addCashFlow() {
        Dialog.custom(new CashFlowChangeLayout({
            accountCollection: this.accounts,
            inflowCategories: this.inflowCategories,
            outflowCategories: this.outflowCategories,
            scenarioCollection: this.scenarioCollection,
            transactionCollection: this.collection,
            action: 'create',
        }));
    },

    addCashFlowEntry(entry) {
        if (this.lineCollection.length === 0 && entry.get('CASHFLOWSCENARIOID') && !this.lineAutoCreated) {
            this.lineCollection.add({
                name: 'Line 1',
            });
            this.lineCollection.at(0).get('scenarios').add(this.scenarioCollection.get(entry.get('CASHFLOWSCENARIOID')));
            this.lineAutoCreated = true;
        }

        this.collection.add(entry);
    },

    removeCashFlowEntry(entry) {
        if (entry.get('type') === 'DEBIT' || entry.get('type') === 'CREDIT') {
            return;
        }

        /*
         * if it is in base, ask about deleting or negating it
         * if it is in a scenario, just confirm & delete it
         */
        Dialog.custom(new CashFlowChangeLayout({
            model: entry,
            accountCollection: this.accounts,
            inflowCategories: this.inflowCategories,
            outflowCategories: this.outflowCategories,
            scenarioCollection: this.scenarioCollection,
            transactionCollection: this.collection,
            action: 'delete',
        }));
    },

    modifyCashFlow(entry) {
        Dialog.custom(new CashFlowChangeLayout({
            model: entry,
            inflowCategories: this.inflowCategories,
            outflowCategories: this.outflowCategories,
            accountCollection: this.accounts,
            scenarioCollection: this.scenarioCollection,
            transactionCollection: this.collection,
            action: 'update',
        }));
    },

    matchCashFlow(entry) {
        Dialog.custom(new CashFlowMatchLayout({
            model: entry,
        }));

        this.listenToOnce(
            entry,
            {
                'match:completed': this.update,
            },
            this,
        );
    },

    changeFutureEntries(entry) {
        if (!entry.has('predictionId')) {
            return;
        }
        this.updateCollectionFutureEntries(this.collection, entry);
    },

    updateCollectionFutureEntries(collection, entry) {
        const self = this;

        if (entry.get('adjustmentType') === 'seasonal') {
            return;
        }

        /*
         * Anything else but seasonal we will adjust the future transactions from
         * this point forward
         */
        let steadyPlaceholder = 0;
        collection.each((model) => {
            if (!model.has('predictionId') || model.get('predictionId') !== entry.get('predictionId')) {
                return;
            }
            const modelDate = moment(model.get('date'), SERVER_DATE_FORMAT);
            const entryDate = moment(entry.get('date'), SERVER_DATE_FORMAT);

            // ignore past entries
            if (!modelDate.isAfter(entryDate)) {
                return;
            }

            /*
             * if there is no adjustment type, we will just increase the amount of all of
             * the entries
             */
            const simpleAdjustment = !entry.has('adjustmentType') || entry.get('adjustmentType').length < 1;
            if (simpleAdjustment) {
                self.setInFlowOutFlow(model, entry);
                return;
            }

            const steady = entry.has('adjustmentType')
                && (entry.get('adjustmentType') === 'steadyIncrease' || entry.get('adjustmentType') === 'steadyDecrease');
            if (steady) {
                /*
                 * if yearly, check if this is next year's transaction, if not, continue
                 * we only have one in the prototype for now
                 */
                if (entry.has('frequencyType')
                    && entry.get('frequencyType') === 'yearly'
                    && modelDate.diff(entryDate, 'years', true) < 1) {
                    return;
                }

                let inFlowAmount = 0;
                let outFlowAmount = 0;
                let finalAmount = 0;
                steadyPlaceholder += parseFloat(entry.get('adjustmentAmount'));
                if (entry.get('adjustmentType') === 'steadyIncrease') {
                    if (entry.has('inFlow')) {
                        inFlowAmount = parseFloat(entry.get('inFlow'));
                        model.set('inFlow', inFlowAmount + steadyPlaceholder);
                        return;
                    }
                    outFlowAmount = parseFloat(entry.get('outFlow'));
                    model.set('outFlow', outFlowAmount + steadyPlaceholder);
                    return;
                }
                if (entry.has('inFlow')) {
                    inFlowAmount = parseFloat(entry.get('inFlow'));
                    finalAmount = inFlowAmount - steadyPlaceholder;
                    if (finalAmount <= 0) {
                        finalAmount = 0;
                    }
                    model.set('inFlow', finalAmount);
                    return;
                }
                outFlowAmount = parseFloat(entry.get('outFlow'));
                finalAmount = outFlowAmount - steadyPlaceholder;
                if (finalAmount <= 0) {
                    finalAmount = 0;
                }
                model.set('outFlow', finalAmount);
            }
        });
    },

    setInFlowOutFlow(model, entry) {
        if (entry.has('inFlow')) {
            const inFlowAmount = entry.get('inFlow');
            model.set('inFlow', inFlowAmount);
            return;
        }
        const outFlowAmount = entry.get('outFlow');
        model.set('outFlow', outFlowAmount);
    },

    togglePlanControl(e) {
        /*
         * it is open and the button was clicked again
         * do nothing and let the document click listener catch the event
         */

        if (!this.ui.$planSettingsDrawer.toggleClass) {
            return;
        }

        if (!this.planControlsVisible || !e) {
            this.planControlsVisible = !this.planControlsVisible;
            this.ui.$planSettingsDrawer.toggleClass('out', this.planControlsVisible);
            this.$('.btn-plan-settings').toggleClass('active', this.planControlsVisible);

            this.ui.$planSettingsDrawer.find(':focusable').eq(0).focus();

            if (this.planControlsVisible) {
                this.attachClickOffCallback(
                    this.ui.$planSettingsDrawer,
                    util.bind(this.togglePlanControl, this),
                    e,
                );
            }
        }
    },

    toggleLineControl(e) {
        /*
         * it is open and the button was clicked again
         * do nothing and let the document click listener catch the event
         */

        if (!this.lineControlsVisible || !e) {
            this.lineControlsVisible = !this.lineControlsVisible;
            this.ui.$lineSettingsDrawer.toggleClass('out', this.lineControlsVisible);
            this.$('.btn-line-settings').toggleClass('active', this.lineControlsVisible);

            this.ui.$lineSettingsDrawer.find(':focusable').eq(0).focus();

            if (this.lineControlsVisible) {
                this.attachClickOffCallback(
                    this.ui.$lineSettingsDrawer,
                    util.bind(this.toggleLineControl, this),
                    e,
                );
            }
        }
    },

    toggleChartControl(e) {
        /*
         * it is open and the button was clicked again
         * do nothing and let the document click listener catch the event
         */

        if (!this.controlsVisible || !e) {
            this.controlsVisible = !this.controlsVisible;
            this.ui.$accountSettingsDrawer.toggleClass('out', this.controlsVisible);
            this.$('.btn-chart-settings').toggleClass('active', this.controlsVisible);

            this.ui.$accountSettingsDrawer.find(':focusable').eq(0).focus();

            if (this.controlsVisible) {
                this.attachClickOffCallback(
                    this.ui.$accountSettingsDrawer,
                    util.bind(this.toggleChartControl, this),
                    e,
                );
            }
        }
    },

    toggleScenarioControlViaEdit(e) {
        // close the edit window
        $(document).trigger('click');
        this.toggleScenarioControl(e);
    },

    toggleScenarioControl() {
        this.scenarioControlsVisible = !this.scenarioControlsVisible;
        this.scenarios.show(new ManagerLayout({
            lineCollection: this.lineCollection,
            scenarioCollection: this.scenarioCollection,
            transactionCollection: this.collection,
            filterCollection: this.gridView.grid.filterProc.filters,
        }));
    },

    attachClickOffCallback($el, callback, ev) {
        const eventName = util.uniqueId('click.');
        $(document).on(eventName, (e) => {
            if (e && ev && e.timeStamp !== ev.timeStamp
                && $el.find(e.target).length === 0 && !$el.is(e.target)) {
                $(document).off(eventName);
                callback();
            }
        });
    },

    clearChart() {
        /*
         * clear the chart.  rebuild without data will do the same thing,
         * though  we may want to override this later for a nicer story.
         */
        this.rebuildChart();
    },

    rebuildChart() {
        if (!this.lineCollection.isReady()) {
            return;
        }

        const lineGroups = this.lineCollection.getActiveLineGroups();
        this.chartData = this.chartCollection.getChartData(lineGroups);
        this.drawChart();
    },

    drawChart() {
        if (!this.c3Chart) {
            return;
        }

        if (this.chartData.length === 0) {
            // nothing to graph
            this.c3Chart.unload();
            return;
        }

        const lineIds = this.lineCollection.getActiveLineIds();
        const today = moment(new Date()).format(SERVER_DATE_FORMAT);
        let todayIndex = -1;
        const startsInFuture = moment(new Date()).isBefore(moment(
            this.chartData[0].startDate,
            SERVER_DATE_FORMAT,
        ));

        // TODO: .find() shouldn't be updating anything
        util.find(this.chartData, (day, i) => {
            if (day.dates.indexOf(today) > -1) {
                todayIndex = i;
                return true;
            }
            return false;
        });

        this.c3Chart.xgrids.remove();
        this.c3Chart.regions.remove({
            classes: ['future'],
        });

        // wait for render to complete, otherwise the line doesn't get drawn
        this.once('chartRendered', util.bind(
            this.handleChartRender,
            this,
            todayIndex,
            startsInFuture,
        ));

        this.c3Chart.load({
            unload: true,
            json: this.chartData,
            classes: this.lineCollection.getActiveLineClasses(),

            keys: {
                // it's possible to specify 'x' when category axis
                x: 'groupId',

                value: [].concat([
                    'inFlow',
                    'outFlow',
                    'scenario',
                    'remaining',
                    'predicted',
                ], lineIds),
            },
        });
    },

    handleChartRender(todayIndex, startsInFuture) {
        if (!this.c3Chart) {
            return;
        }

        this.drawTodayArea(todayIndex, startsInFuture);
        this.drawThresholds();
    },

    drawThresholds() {
        // need to make sure thresholds will be separated by > 16 px (label height)
        const domain = this.c3Chart.internal.y.domain();

        // threshold 'lanes'

        const yValueRange = domain[1] - domain[0];
        // height of the graph's content area - can't get from DOM reliably

        const height = 300;

        const laneHeight = 20;

        // group thresholds into lanes

        const laneCount = Math.floor(height / laneHeight);
        const laneValueHeight = yValueRange / laneCount;
        const groupedThresholds = {};
        util.each(this.accounts.getActiveThresholds(), (t) => {
            const laneId = Math.round(t.value / laneValueHeight);
            if (groupedThresholds[laneId]) {
                groupedThresholds[laneId].texts.push(t.text);
            } else {
                groupedThresholds[laneId] = {
                    value: laneId * laneValueHeight,
                    texts: [t.text],
                    position: 'end',
                    class: 'threshold-grid',
                };
            }
            groupedThresholds[laneId].text = groupedThresholds[laneId].texts.join(', ');
        });

        this.c3Chart.ygrids(util.flatten(groupedThresholds));
    },

    drawTodayArea(todayIndex, startsInFuture) {
        // draw yellow 'future' background
        if (startsInFuture || todayIndex > -1) {
            this.c3Chart.regions.add({
                axis: 'x',
                start: todayIndex,
                class: 'future',
            });
        }

        // draw today line
        if (todayIndex > -1) {
            this.c3Chart.xgrids.add([{
                value: todayIndex,
                text: 'Today',
            }]);
        }
    },

    buildC3Chart() {
        const self = this;

        this.c3Chart = c3.generate({
            onrendered() {
                self.trigger('chartRendered');
            },

            bindto: this.ui.$c3Graph.get(0),

            transition: {
                duration: null,
            },

            data: {
                json: [],
                type: 'line',

                types: {
                    inFlow: 'bar',
                    outFlow: 'bar',
                },

                classes: {
                    remaining: 'balance',
                    predicted: 'balance',
                },

                colors: {
                    inFlow: '#669933',
                    outFlow: '#cc6600',
                    remaining: '#3197ce',
                    predicted: '#80a1b6',
                },

                color(color, d) {
                    // d will be 'id' when called for legends
                    if (d.id === 'predicted' && d.value < 0) {
                        return 'red';
                    }
                    return d.id && d.id === 'remaining' ? d3.rgb(color).darker(d.value / 150) : color;
                },
            },

            axis: {
                x: {
                    type: 'category',
                    show: true,

                    tick: {
                        format(x) {
                            const data = self.findChartDataByXValue(Math.floor(x));
                            if (data) {
                                const groupBy = self.chartViewSettings.get('groupBy');

                                const sMoment = moment(data.startDate, SERVER_DATE_FORMAT);
                                const eMoment = moment(data.endDate, SERVER_DATE_FORMAT);

                                if (groupBy === 'month') {
                                    return sMoment.format('MMM YYYY');
                                }
                                if (groupBy === 'week') {
                                    if (sMoment.isSame(eMoment, 'month')) {
                                        return `${sMoment.format('MMM D')}-${eMoment.format('D YYYY')}`;
                                    }

                                    return `${sMoment.format('MMM D')}-${eMoment.format('MMM D YYYY')}`;
                                }
                                if (data.startDate !== data.endDate) {
                                    return `${data.startDate}-${data.endDate}`;
                                }
                                return sMoment.format(FILTER_DATE_FORMAT);
                            }
                            return '';
                        },

                        count: util.bind(self.getTickCount, self),
                        multiline: false,
                    },
                },

                y: {
                    tick: {
                        format: d3.format('s'),
                    },
                },
            },

            grid: {
                x: {
                    show: false,
                },

                y: {
                    show: true,
                    lines: [],
                },
            },

            padding: {
                right: 60,
            },

            tooltip: {
                format: {
                    name: util.bind(function (name, ratio, id) {
                        if (id === 'remaining') {
                            return locale.get('cashflow.balance');
                        }
                        if (id === 'predicted') {
                            return locale.get('cashflow.estimated');
                        }
                        if (id === 'inFlow') {
                            return locale.get('cashflow.inflow');
                        }
                        if (id === 'outFlow') {
                            return locale.get('cashflow.outflow');
                        }
                        if (this.lineCollection.get(id)) {
                            return this.lineCollection.get(id).get('name');
                        }
                        return name;
                    }, this),

                    value: d3.format('$,.2f'),
                },

                position(data, width, height, element) {
                    const svgLeft = this.getSvgLeft(true);
                    let tooltipLeft = svgLeft + this.getCurrentPaddingLeft(true)
                        + this.x(data[0].x) + 20;
                    const chartRight = (svgLeft + this.currentWidth)
                        - this.getCurrentPaddingRight();
                    let tooltipTop = this.d3.mouse(element)[1] + 15;

                    if (tooltipLeft + width > chartRight) {
                        tooltipLeft = (this.x(data[0].x) - width) + 40;
                    }

                    if (tooltipTop + height > this.currentHeight) {
                        tooltipTop -= height + 30;
                    }

                    if (tooltipTop < 0) {
                        tooltipTop = 0;
                    }

                    return {
                        top: tooltipTop,
                        left: tooltipLeft,
                    };
                },

                /*
                 *  The contents function is creating the mockup for the C3 chart
                 * line tooltip.  The tooltip needs to assign the
                 *  appropriate color swatch color for the scenarios by adding the
                 * correct css class.  The code has been copied from
                 *   the C3 chart so as not to overide the original functionality.
                 */
                contents(d, defaultTitleFormat, defaultValueFormat, color) {
                    const { config } = this;
                    const titleFormat = config.tooltip_format_title || defaultTitleFormat;
                    const nameFormat = config.tooltip_format_name || (name => name);
                    const valueFormat = config.tooltip_format_value || defaultValueFormat;
                    const classes = self.lineCollection.getActiveLineClasses();
                    let text;

                    d.forEach((val) => {
                        let title;
                        let name;
                        let bgcolor;

                        if (!(val && (val.value || val.value === 0))) {
                            return;
                        }

                        if (!text) {
                            title = titleFormat ? titleFormat(val.x) : val.x;
                            const th = title || title === 0 ? `<tr><th colspan='2'>${title}</th></tr>` : '';
                            text = `<table class='${this.CLASS.tooltip}'>${th}`;
                        }

                        const value = valueFormat(val.value, val.ratio, val.id, val.index);
                        if (value !== undefined) {
                            name = nameFormat(val.name, val.ratio, val.id, val.index);
                            bgcolor = this.levelColor
                                ? this.levelColor(val.value) : color(val.id);

                            /*
                             * If this tooltip element is for a scenario, assign the
                             * correct css class name to pull in the correct color.
                             */
                            const className = (Number.isNaN(parseInt(val.id, 10))) ? '' : `c3-tooltip-${classes[val.id]}`;
                            text += `<tr class="${this.CLASS.tooltipName}${this.getTargetSelectorSuffix(val.id)}">`;
                            if (className.length > 0) {
                                text += `<td class="name"><span class="${className}"></span>${name}</td >`;
                            } else {
                                text += `<td class="name"><span style='background-color:${bgcolor}'></span>${name}</td >`;
                            }
                            text += `<td class="value">${value} </td></tr>`;
                        }
                    });
                    return `${text}</table>`;
                },
            },

            legend: {
                show: false,
            },

            point: {
                r: 0,
                show: true,

                focus: {
                    expand: {
                        r: 6,
                        enabled: true,
                    },
                },
            },

            zoom: {
                enabled: false,
            },
        });

        // prevent x-axis labels from being cut off
        this.$('.c3-axis.c3-axis-x').removeAttr('clip-path');

        this.rebuildChart();
    },

    getTickCount() {
        if (util.isUndefined(this.chartData)) {
            return 1;
        }
        const l = this.chartData.length;

        // ensure that there is space for the text
        const MAX_TICKS = 7;
        const MIN_WIDTH_PER_TICK = 145;
        let ticks = (l < MAX_TICKS) ? l : MAX_TICKS;
        if ((this.$el.width() / ticks) < MIN_WIDTH_PER_TICK) {
            ticks = Math.floor(this.$el.width() / MIN_WIDTH_PER_TICK);
        }

        return ticks;
    },

    handleRowAction(data) {
        const { action, model } = data;

        if (action === 'modify') {
            this.modifyCashFlow(model);
            return;
        }

        if (action === 'match') {
            this.matchCashFlow(model);
        }
    },

    handleRemoveRowAction(model) {
        this.removeCashFlowEntry(model);
    },

    refreshData(obj, response, options) {
        // update the whole grid when an item in the grid triggers a 'sync' event
        if (options && options.source === 'itemService') {
            this.gridView.refreshGridData();
        }
        this.removeActions();
    },

    onShow() {
        const options = {
            context: {
                serviceName: '/tableMaintenance/type.cashFlowItemGrid',
            },

            selector: 'none',
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            enableSavedViews: true,
            dateFormat: FILTER_DATE_FORMAT,
            cashflowOverride: true,
        };

        this.gridView = GridApi.createServiceGridView(options);

        this.listenTo(
            this.gridView,
            {
                rowAction: this.handleRowAction,
            },
            this,
        );

        const removeEvent = `grid:row:action:action_remove_${this.gridView.cid}`;
        this.listenTo(this.appBus, removeEvent, this.handleRemoveRowAction, this);
        this.listenTo(
            this.gridView.getRows(),
            'sync',
            util.bind(ListView.prototype.updateRefreshTimestamp, this, this.gridView),
        );
        this.grid.show(this.gridView);

        // wait for gridView to have a grid.collection
        this.listenToOnce(
            this.gridView,
            {
                'item:rendered': this.setUpGridEvents,
            },
            this,
        );

        this.drawer.show(new AccountSelectView({
            collection: this.accounts,
            hasTransferEntitlement: this.hasTransferEntitlement,
        }));

        this.planCtrl.show(new PlanSelectView({
            collection: this.plans,
        }));

        this.sliderControl.show(new DateRangeScrubberView({
            model: this.chartViewSettings,
        }));

        this.settingsButtons.show(new ToggleButtonsView({
            // to badge the drawer toggle button
            collection: this.accounts,

            model: this.chartViewSettings,
        }));

        this.lines.show(new LineManagerView({
            manageLines: false,
            collection: this.lineCollection,
            scenarios: this.scenarioCollection,
        }));

        this.buildC3Chart();
    },

    setUpGridEvents(gridView) {
        // gridView === this.gridView
        this.listenTo(
            gridView.grid.collection,
            {
                sync: this.refreshData,
            },
            this,
        );

        this.listenTo(
            gridView.grid.tableBody,
            {
                'collection:rendered': this.createDraggables,
            },
            this,
        );

        this.createDraggables();
    },

    onClose() {
        // TODO clean up anything not handled automatically
    },

    addDateFilter(date) {
        const label = date.format(FILTER_DATE_FORMAT);
        const value = date.format(FILTER_DATE_FORMAT);

        this.gridView.grid.filterProc.addFilter({
            value,
            label,
            equality: 'EQ',
            field: 'EXPECTEDDATE',
            type: 'date',
        });
    },

    addDateRangeFilter(startDate, endDate) {
        // TODO see addDateFilter for new filter format
        const value = [
            startDate.format(FILTER_DATE_FORMAT),
            endDate.format(FILTER_DATE_FORMAT),
        ];

        const label = `${startDate.format(FILTER_DATE_FORMAT)} to ${endDate.format(FILTER_DATE_FORMAT)}`;
        this.gridView.grid.filterProc.addFilter({
            value,
            type: 'date',
            field: 'EXPECTEDDATE',
            label,
            equality: 'BETWEEN',
        });
    },

    didClickOnChart(e) {
        d3.select(e.currentTarget).datum(util.bind(function (data) {
            const chartData = this.findChartDataByXValue(data.index);
            if (!util.isUndefined(chartData)) {
                const s = moment(chartData.startDate, SERVER_DATE_FORMAT);
                const end = moment(chartData.endDate, SERVER_DATE_FORMAT);

                if (!s.isSame(end)) {
                    this.addDateRangeFilter(s, end);
                } else {
                    this.addDateFilter(s);
                }
            }
            // otherwise we strip the element's data!
            return data;
        }, this));
    },

    didDblClickOnChart(ev) {
        d3.select(ev.currentTarget).datum(util.bind(function (data) {
            const chartData = this.findChartDataByXValue(data.index);
            if (!util.isUndefined(chartData) && chartData.endDate !== chartData.startDate) {
                const s = moment(chartData.startDate, SERVER_DATE_FORMAT);
                const e = moment(chartData.endDate, SERVER_DATE_FORMAT);
                const t = moment(new Date());

                let sOffset = -7;

                // zoom as close as we can get to the double-clicked range

                let eOffset = 7;
                if (s.isBefore(t)) {
                    // zoom in to past
                    sOffset = s.diff(t, 'days');
                }

                if (e.isAfter(t)) {
                    // zoom in to future
                    eOffset = e.diff(t, 'days');
                }

                this.chartViewSettings.setRange(sOffset, eOffset);
            }
            // otherwise we strip the element's data!
            return data;
        }, this));
    },

    findChartDataByXValue(xValue) {
        if (this.chartData.length >= xValue) {
            return this.chartData[xValue];
        }
        return undefined;
    },
});

export default TransactionsLayout;
