import EntryView from 'app/administration/common/dynamicPages/views/workflow/entry';
import DataApi from 'common/dynamicPages/api/data';
import errorHandlers from 'system/error/handlers';
import util from '@glu/core/src/util';
import store from 'system/utilities/cache';

export default EntryView.extend({
    initialize() {
        const options = {
            menuCategory: 'CHECKFREE',
            serviceName: '/checkFreeRXPAccounts',
            serviceFunc: null,
            businessType: null,
            context: 'CHECKFREE_RXP',
            mode: 'view',
        };

        EntryView.prototype.initialize.call(this, options);
    },
    loadRequiredData() {
        DataApi.model.generateModelFromModel(store.get(`${this.contextKey}-actionModel`))
            .then((newModelParam) => {
                const newModel = newModelParam;
                newModel.fetch({
                    success: () => {
                        this.storedModel = newModel;
                        this.setHasLoadedRequiredData(true);
                        this.render();
                    },

                    error: util.bind(errorHandlers.loading, this),
                });
            });
    },
});
