import GridApi from 'common/dynamicPages/api/grid';
import constants from 'app/smbInvoice/constants';
import ContextApi from 'common/dynamicPages/api/context';
import GridLayout from './gridLayout';
import masterGridTmpl from './masterGrid.hbs';

export default GridLayout.extend({
    name: 'Master',
    template: masterGridTmpl,

    initialize(options) {
        const gridOptions = {
            hideGridActionButtons: true,
            enableRowContextButtonCallbacks: true,
            columnsToBeHidden: constants.COMMON_COLUMNSTOHIDE,
            context: ContextApi.menuContext.getContext('BOS_INVOICE'),
        };

        this.gridView = GridApi.createServiceGridView(gridOptions);
        this.entitlements = options.entitlements;
        this.alertRegion = options.alertRegion;
    },

    onShow(...args) {
        this.masterGrid.show(this.gridView);
        GridLayout.prototype.onShow.apply(this, args);
    },
});
