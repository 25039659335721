import Layout from '@glu/core/src/layout';
import FlexDropdown from '@glu/flex-dropdown';
import locale from '@glu/locale';
import template from './clientLocationLogin.hbs';

const ClientLocationLogin = Layout.extend({
    template,

    onShow() {
        const dropdown = new FlexDropdown({
            data: this.options.clientLocations || [],
            preSelectedIds: this.getPreselected(
                this.model,
                this.options.clientLocations,
            ),
            showTooltip: false,
            label: locale.get('uce.clientlocation'),
            disableMultiButton: true,
        });
        this.listenTo(dropdown, 'selectionChanged', this.handleClientLocationChange);
        this.clientLocationsRegion.show(dropdown);
    },

    /**
     * Check the model for client locations, then check for exactly one location. Map
     * the array for a list of ids. Otherwise, return an empty array.
     * return empty array.
     * @param {Object} model
     * @param {Array} clientLocations
     * @returns {Array}
     */
    getPreselected(model, clientLocations) {
        const locations = model.get('clientLocations');
        let preselected = [];
        if (locations) {
            preselected = locations;
        } else if (clientLocations.length === 1) {
            this.model.set('clientLocations', clientLocations);
            preselected = clientLocations;
        }
        return preselected.map(loc => loc.id);
    },

    /**
     * Update the model with the array
     * @param {Array} value
     */
    handleClientLocationChange(value) {
        this.model.set('clientLocations', value);
    },
});

export default ClientLocationLogin;
