import ItemView from '@glu/core/src/itemView';
import dialog from '@glu/dialog';
import ListBuilder from 'common/listBuilder/listBuilder';
import Grid from '@glu/grid';
import locale from '@glu/locale';
import util from '@glu/core/src/util';
import constants from 'app/administration/constants';
import loadingPageTmpl from 'common/templates/loadingPage.hbs';
import LimitDataEntitlements from 'app/administration/collection/user/limitDataEntitlements';
import infoTooltipHelper from 'common/dynamicPages/views/mdf/componentHelpers/infoTooltip';
import AccountView from './accountView';
import CompanyView from './companyView';
import ApplicationView from './applicationView'; // PCM
import LimitField from './limitField';
import ActionCheckboxes from './actionCheckboxes';
import CustomHeaderView from './customHeaderView';
import QuickLimitsView from './quickLimits';
import assignedAccountsTmpl from './assignedAccounts.hbs';

export default ItemView.extend({
    template: assignedAccountsTmpl,
    loadingTemplate: loadingPageTmpl,
    className: 'assigned-accounts',

    ui: {
        $popovers: '[data-toggle="popover"]',
        $addBankAccountsButton: '.add-bank-accounts-button',
        $addLimitsButton: '.add-limits-button',
        $addCompaniesButton: '.add-companies-button',
        $assignAllChk: '[data-hook="assign-all-checkbox"]',
        $gridSection: '[data-hook="grid-section"]',
        $assignAllMsg: '.assign-all-msg',
    },

    events: {
        'click @ui.$addBankAccountsButton': 'openAccountsDialog',
        'click @ui.$addLimitsButton': 'openLimitsDialog',
        'click @ui.$addCompaniesButton': 'openAccountsDialog',
        'click @ui.$assignAllChk': 'changeAssignAll',
    },

    initialize(options) {
        const dataEntitlementConfig = this.model.get('typeDataEntitlements').at(options.entitledIndex);

        this.dataEntitlementConfig = dataEntitlementConfig;

        this.userGroup = options.userGroup;
        this.mode = options.mode;
        this.entity = options.isRole ? 'role' : 'uce';

        this.isACH = this.options.paymentTypeGroup === 'ACH' || dataEntitlementConfig.get('configuredDataType') === 'ACHCOMP';
        this.isTransfer = this.options.paymentTypeGroup === 'Transfer';
        this.isCredit = dataEntitlementConfig.get('dataEntAttr') === 'BankAccountCR';
        this.isCHECK = this.options.paymentTypeGroup === 'CHECK';

        if (this.availableAccounts && this.availableAccounts.fetched) {
            this.setHasLoadedRequiredData(true);
        }

        this.availableAccounts = this.availableAccounts || new LimitDataEntitlements(
            [],
            {
                inquiryId: dataEntitlementConfig.get('inquiryId'),
                paymentType: dataEntitlementConfig.get('type') || this.options.entitledTypes[0],
                keyCol: dataEntitlementConfig.get('keyCol'),
                configuredDataType: dataEntitlementConfig.get('configuredDataType'),
                userGroup: this.userGroup,
                entitledTypes: this.options.entitledTypes,
            },
        );
        this.availableAccounts.unselected = true;
        this.selectedAccounts = dataEntitlementConfig.get('dataEntitlementsWithLimits');

        // when adding new accounts/companies, we need to properly default the permissions
        this.selectedAccounts.on('reset', (newList, opts) => {
            newList.each((newAccount) => {
                // only update new additions
                if (!util.contains(opts.previousModels, newAccount)) {
                    newAccount.entitleAll();
                }
            });
        });

        this.listenTo(this.model.get('detailedTypeEntitlements'), 'change', (model) => {
            this.updateAccountsForPermissions(model.collection.getEntitledActions());
        });
    },

    onRender() {
        const self = this;

        infoTooltipHelper.setupInfoTooltips(this);
        if (this.hasLoadedRequiredData()) {
            if (this.isACH) {
                let achCols = [{
                    field: 'COMPNAME',
                    label: locale.get('uce.name'),
                }, {
                    field: 'COMPANYID',
                    label: locale.get('uce.companyId'),
                }];

                if (!this.options.isRole) {
                    achCols = achCols.concat([{
                        field: 'transactionLimitAssignedLimit',
                        label: locale.get('uce.transactionLimit'),
                        cellView: LimitField,
                        mode: this.mode,
                    }, {
                        field: 'batchLimitAssignedLimit',
                        label: locale.get('uce.batchLimit'),
                        cellView: LimitField,
                        mode: this.mode,
                    }, {
                        field: 'dailyLimitAssignedLimit',
                        label: locale.get('uce.dailyLimit'),
                        cellView: LimitField,
                        mode: this.mode,
                    }, {
                        field: 'actions',
                        label: 'actions',
                        headerView: CustomHeaderView,
                        cellView: ActionCheckboxes,
                        mode: this.mode,
                        sortable: false,
                    }]);
                }

                this.grid = new Grid({
                    columns: achCols,
                    collection: this.selectedAccounts,
                    emptyViewText: locale.get('uce.emptyGrid.companies'),
                    el: this.$('.companies-grid'),
                }).render();
            } else if (this.isCHECK) {
                const checkCols = [{
                    field: 'DESCRIPTION',
                    label: locale.get('uce.application.DESCRIPTION'),
                }, {
                    field: 'PAYMENTTYPEDESC',
                    label: locale.get('uce.application.PAYMENTTYPEDESC'),
                }];

                this.grid = new Grid({
                    columns: checkCols,
                    collection: this.selectedAccounts,
                    emptyViewText: locale.get('uce.emptyGrid.applications'),
                    el: this.$('.applications-grid'),
                }).render();
            } else {
                const accountNameField = ([19015, 19019, 19026, 19027, 28025].indexOf(this.selectedAccounts.inquiryId) >= 0) ? 'CLIENTACCOUNTNAME' : 'ACCOUNTNAME';
                let cols = [{
                    field: accountNameField,
                    label: locale.get('uce.name'),
                }, {
                    field: 'ACCOUNTNUM_DISP',
                    label: locale.get('uce.accountNumber'),
                }];

                if (!this.options.isRole) {
                    cols = cols.concat([{
                        field: 'transactionLimitAssignedLimit',
                        label: locale.get('uce.transactionLimit'),
                        cellView: LimitField,
                        mode: this.mode,
                    }, {
                        field: 'dailyLimitAssignedLimit',
                        label: locale.get('uce.dailyLimit'),
                        cellView: LimitField,
                        mode: this.mode,
                    }, {
                        field: 'actions',
                        label: 'actions',
                        headerView: CustomHeaderView,
                        cellView: ActionCheckboxes,
                        mode: this.mode,
                        sortable: false,
                    }]);
                }

                this.grid = new Grid({
                    columns: cols,
                    collection: this.selectedAccounts,
                    emptyViewText: locale.get('uce.emptyGrid.bankAccounts'),
                    el: this.$('.bank-accounts-grid'),
                }).render();
            }

            this.ui.$addLimitsButton.hide();

            this.listenTo(this.selectedAccounts, 'approvalRightsChanged:account', () => {
                self.showHideLimitsButton(self.selectedAccounts);
            });

            this.listenTo(this.selectedAccounts, 'approvalRightsChanged:overall', (lostApproval) => {
                if (lostApproval) {
                    self.selectedAccounts.clearLimits();
                }
                self.showHideLimitsButton(self.selectedAccounts);
            });

            self.showHideLimitsButton(self.selectedAccounts);

            const checked = this.dataEntitlementConfig.get('assignAll');

            this.ui.$assignAllChk.prop('checked', checked);
            self.ui.$gridSection.toggle(!checked);
            self.ui.$assignAllMsg.toggle(checked);
        } else {
            this.loadRequiredData();
        }
    },

    unescapeHtml(rec) {
        // unescape the &amp; to & for display & sending to server
        rec.set({
            COMPNAME: util.unescape(rec.get('COMPNAME')),
            COMPANYID: util.unescape(rec.get('COMPANYID')),
        });
    },

    loadRequiredData() {
        const self = this;

        this.availableAccounts.fetch({
            success(records) {
                const applicableActionsArr = self.model.get('detailedTypeEntitlements').getEntitledActions();
                const toRemove = [];

                records.each((rec) => {
                    rec.enableActionsFromPermissions(applicableActionsArr);
                    self.unescapeHtml(rec);
                    const selectedRec = self.selectedAccounts.matchRecord(rec, records.keyCol);

                    if (selectedRec) {
                        toRemove.push(rec);
                        selectedRec.mergeData(rec.attributes);
                    }
                });

                // Remove the selected accounts that are not entitled anymore
                const accountsNotEntitled =
                    self.selectedAccounts.accountsNotEntitled(self.availableAccounts);
                self.selectedAccounts.remove(accountsNotEntitled);

                self.availableAccounts.remove(toRemove);
                self.availableAccounts.fetched = true;

                self.setHasLoadedRequiredData(true);
                self.render();
            },
        });
    },

    /**
     * Externalize to permit overriding of the function.
     * Returns entitled actions
     * @return {Array}
     */
    getApplicableActions() {
        return this.model.get('detailedTypeEntitlements').getEntitledActions();
    },

    /**
     * enables or disables account checkboxes when
     * permissions change in 'assigned permissions' section
     */
    updateAccountsForPermissions(types) {
        if (this.selectedAccounts.length > 0) {
            this.selectedAccounts.updateActionsByPermissions(types);
        }
    },

    /**
     * Open a list builder for a specific account type
     */
    openAccountsDialog() {
        // PCM-654
        let gridItemView;
        let resource;
        let localeResource;

        if (this.isACH) {
            gridItemView = CompanyView;
            resource = 'Companies';
            localeResource = 'Companies';
        } else if (this.isCHECK) {
            gridItemView = ApplicationView;
            resource = 'CheckApplications';
            localeResource = 'Applications';
        } else {
            gridItemView = AccountView;
            resource = 'BankAccounts';
            localeResource = 'Accounts';
        }
        // PCM-654

        const self = this;
        const pluralString = resource;
        const pluralStringLocale = localeResource;

        const listBuilder = new ListBuilder({
            id: 'uce-add-companies',
            className: 'list-builder dgb-list-builder',
            sourceCollection: this.availableAccounts,
            targetCollection: this.selectedAccounts,
            labelView: gridItemView, // PCM-654
            showBusyIndicator: true,

            text: {
                moveSelectedItems: locale.get(`uce.moveSelected${pluralStringLocale}`),
                moveAllItems: locale.get(`uce.moveAll${pluralStringLocale}`),
                removeSelectedItems: locale.get(`uce.removeSelected${pluralStringLocale}`),
                removeAllItems: locale.get(`uce.removeAll${pluralStringLocale}`),
                filter: locale.get(`uce.filter${pluralStringLocale}`),
                sourceListHeader: `uce.nAvailable${pluralStringLocale}`,
                targetListHeader: `uce.nAssigned${pluralStringLocale}`,
                title: locale.get(`uce.add${pluralString}`),
                save: locale.get(`uce.add${pluralStringLocale}`),
                cancel: locale.get('button.cancel'),
            },

            saveHandler() {
                self.showHideLimitsButton(self.selectedAccounts);
                self.updateAccountsForPermissions(self.model.get('detailedTypeEntitlements').getEntitledActions());
            },
        });

        dialog.open(listBuilder);
    },

    showHideLimitsButton(collection) {
        if (collection.hasApprove()) {
            this.ui.$addLimitsButton.show();
        } else {
            this.ui.$addLimitsButton.hide();
        }
    },

    applyQuickLimits(limitsModel) {
        this.selectedAccounts.applyQuickLimits(limitsModel);
    },

    openLimitsDialog() {
        const options = {
            isACH: this.isACH,
        };

        const quickLimitsView = new QuickLimitsView(options);

        this.listenTo(quickLimitsView, 'applyQuickLimits', this.applyQuickLimits);
        dialog.open(quickLimitsView);
    },

    changeAssignAll(e) {
        const { checked } = e.target;

        this.dataEntitlementConfig.set('assignAll', checked);

        this.ui.$gridSection.toggle(!checked);
        this.ui.$assignAllMsg.toggle(checked);
    },

    templateHelpers() {
        const self = this;
        return {
            cid: this.model.cid + this.dataEntitlementConfig.get('dataEntAttr'),

            itemsSelected() {
                return self.selectedAccounts.size();
            },

            assignAllTooltipCompanies() {
                return locale.get(`${self.entity}.assign-all-tooltip-companies`);
            },

            assignAllTooltipAccounts() {
                return locale.get(`${self.entity}.assign-all-tooltip-accounts`);
            },

            readOnly: this.mode === constants.MODES.VIEW,
            isRole: this.options.isRole,
            isACH: self.isACH,
            isCHECK: self.isCHECK,

            bankAccountHeading() {
                if (self.isTransfer) {
                    return locale.get(self.isCredit ? 'uce.Transfer.BankAccountCR' : 'uce.Transfer.BankAccount');
                }
                return locale.get('uce.bankAccounts');
            },
        };
    },
});
