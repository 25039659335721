import client from './client';

/**
 * This file is to be loaded in main.js to initialize the client when the application starts
 * Here we will also set up any common pollers for the Notification Service, so they are
 * available immediately.
 */

const startup = {
    /**
     * Common subscriptions that should always be active.
     */
    setupSubscriptions() {
        // Subject to change: USER is meant to notify user print jobs, exports, etc.
        client.subscribeTo('USER');
    },

    setupPollers() {
        // TODO: Initiate pollers. This is called in AfterAuthPromises
    },

    /**
     * Technically does not need to return a promise, as Promise.all handles it,
     *  but this allows us to later delay execution if we need to fetch setup data.
     * @return {Promise}
     */
    setup() {
        startup.setupPollers();
        startup.setupSubscriptions();

        return Promise.resolve();
    },
};

export default startup;
