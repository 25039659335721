import Collection from '@glu/core/src/collection';
import http from '@glu/core/src/http';
import services from 'services';
import cache from 'system/utilities/cache';
import dataUtil from 'common/dynamicPages/api/data';
import mobileUtil from 'mobile/util/mobileUtil';
import RequestModel from '../models/bulkUpdateRequest';

export default Collection.extend({
    /**
     * Init
     * @param {Object} [opts]
     */
    initialize(opts) {
        const options = opts || {};

        // Force the use of our model
        options.model = RequestModel;

        if (options.grid) {
            this.useGrid(options.grid);
        }

        if (options.context) {
            this.useContext(options.context);
        }

        // Call "super()"
        Collection.prototype.initialize(options);
    },

    /**
     * Store the grid object for request data & reporting error messages
     * @param {Object} grid
     */
    useGrid(grid) {
        this.grid = grid;
    },

    /**
     * Gets the context object used for updates.
     * @param {Object} context
     */
    useContext(context) {
        this.context = context;
    },

    /**
     * Store the grid object for request data & reporting error messages
     * @param {string} batchSeqNum
     */
    useBatchSeqNum(batchSeqNum) {
        this.batchSeqNum = batchSeqNum;
    },

    /**
     * Gets the gridKey used for updates
     * @return {string}
     */
    getGridName() {
        if (mobileUtil.isMobileGridEnabled()) {
            const cacheStr = dataUtil.model.getCacheName(this, true);
            return cache.get(cacheStr).childGridKey;
        }
        return this.grid.collection.at(0).childGridKey;
    },

    /**
     * Gets the entryClass used for updates
     * @return {string}
     */
    getEntryClass() {
        return this.context.actionData ? this.context.actionData.entryClass : null;
    },

    /**
     * Clears the existing request array because the server is capable of receiving
     * multiple requests.
     */
    clearRequests() {
        this.reset();
    },

    /**
     * Accept a single request object and make the server call.
     * @param {Object} requestData
     * @return {Promise}
     */
    request(requestData) {
        this.setRequest(requestData, true);
        return this.submitRequest();
    },

    /**
     * Generate and submit the request and return a promise.
     * @return {Promise}
     */
    submitRequest() {
        const url = this.buildRequestURL();
        const data = this.buildRequestObject();

        /*
         * TODO - Is this needed now that we've removed RSVP?
         *  Wrap jQuery promise
         */
        return new Promise((resolve, reject) => {
            http.post(url, data).then((result) => {
                // Turn a "happy" error into real error.
                if (result && result.result === false) {
                    reject(result);
                } else {
                    resolve(result);
                }
                return result;
            }, reject);
        });
    },

    /**
     *
     * @param {Object} requestData - formatted request data fr
     * @param {boolean} clearRequests
     */
    setRequest(requestData, clearRequests) {
        if (clearRequests) {
            this.clearRequests();
        }

        this.push(requestData);
    },

    /**
     * generate service URL
     * @return {string}
     */
    buildRequestURL() {
        return services.generateUrl(`${this.context.serviceName}/bulkChildrenUpdate`);
    },

    /**
     * Construct the request object
     * @return {{gridName: string, entryClass: string, requests: Array}}
     */
    buildRequestObject() {
        return {
            gridName: this.getGridName(),
            entryClass: this.getEntryClass(),
            requests: this.toJSON(),
            batchSeqNumber: this.batchSeqNum,
        };
    },
});
