import Model from '@glu/core/src/model';

export default Model.extend({
    idAttribute: 'TNUM',

    defaults: {
        ACCOUNT_NUMBER: '',
        VALUE_DATE: '',
        DEBIT_AMOUNT: '',
    },
});
