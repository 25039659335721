import Collection from '@glu/core/src/collection';
import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import locale from '@glu/locale';
import DataAPI from 'common/dynamicPages/api/data';
import Formatter from 'system/utilities/format';

export default Collection.extend({
    initialize(options) {
        this.context = options.context;
        this.isInquiry = options.isInquiry || false;
        this.isChild = false;
        this.additionalParse = options.additionalParse;
        this.iterator = 0;
        this.batchSeqNum = options.batchSeqNum;

        this.labels = {
            SELECT: locale.get('common.view'),
            DELETE: locale.get('common.delete'),
            MODIFY: locale.get('action.modify'),
            APPROVE: locale.get('common.approve'),
            UNAPPROV: locale.get('common.unapprove'),
            REJECT: locale.get('common.reject'),
            COPYINST: locale.get('common.copy.as.payment'),
            VIEW: locale.get('common.view'),
            FILTER: locale.get('common.filter'),
            REPAIR: locale.get('common.repair'),
            SPAYMT: locale.get('common.schedule'),
            'CREATE PROFILE': locale.get('common.filter.create.save'),
            COPYTMPL: locale.get('common.copy.as.template'),
            MAKETMPL: locale.get('common.copy.as.template'),
            // PCM
            PRINT: locale.get('payment.button_printchecks'),
            REPRINT: locale.get('payment.button_re-printchecks'),
            VOID: locale.get('common.void'),
            UPDCHKST: locale.get('payment.button_updatechecks'),
        };
    },

    processData(jsonData) {
        this.jsonData = jsonData;
        this.fetch();
    },

    sync(method, model, options) {
        options.success(this.jsonData);
    },

    /**
     * Parse newly formatted table data from the backend into a format
     * that can be easily consumed by a grid.
     */
    parse() {
        try {
            // grab other data first
            this.totalCount = this.jsonData.totalRows;
            this.rowIterator = 1;
            if (this.jsonData.context) {
                this.keyList = this.jsonData.context.keyList;
            } else {
                this.keyList = [];
            }
            if (this.additionalParse) {
                this.additionalParse(this.jsonData);
            }
            this.lastRefreshTimestamp = this.jsonData.lastRefreshTimestamp;

            return util.map(this.jsonData.rows, this.parseColumn, this);
        } catch (err) {
            return [];
        }
    },

    parseColumn(row) {
        const { columns } = row;
        let model;

        if (this.isChild) {
            model = this.childModelGenerator.model.generateFromPreload({
                context: this.context,
                isChild: true,
            });
        } else if (this.keyList.length > 0) {
            model = DataAPI.model.generateFromKeyList({
                context: this.context,
                keyList: this.keyList,
            });
        } else {
            model = new Model();
        }

        if (row.context && row.context.serviceName) {
            model.context = row.context;
        }

        if (row.mapDataList) {
            model.mapDataList = row.mapDataList;
        }

        if (row.groupKey) {
            model.groupKey = row.groupKey;
        }

        model.hasCheckImage = [];
        for (let i = 0, len = columns.length; i < len; i += 1) {
            if (columns[i].fieldName === 'ACTIONLIST') {
                model.buttons = this.parseActionList(columns[i].fieldValue);
            } else if (columns[i].fieldName === 'CMB_TRANSACTION_AMOUNT_FM') {
                if (this.isInquiry) {
                    model.set(
                        columns[i].fieldName,
                        Formatter.formatCurrency(columns[i].fieldValue),
                    );
                } else {
                    model.set(
                        columns[i].fieldName.toUpperCase(),
                        Formatter.formatCurrency(columns[i].fieldValue),
                    );
                }
            } else if (columns[i].fieldName === 'CHECKDATE') { // PCM
                model.set(
                    columns[i].fieldName,
                    Formatter.formatDate(columns[i].fieldValue),
                );
            } else if (this.isInquiry) {
                model.set(columns[i].fieldName, columns[i].fieldValue.replace(/&amp;/g, '&'));
            } else {
                model.set(columns[i].fieldName.toUpperCase(), columns[i].fieldValue.replace(/&amp;/g, '&'));
            }

            if (columns[i].hasCheckImage) {
                model.hasCheckImage.push(columns[i].fieldName);
            }

            if (columns[i].typeCode) {
                model.typeCode = columns[i].typeCode;
            }
        }

        if (this.isChild) {
            this.iterator += 1;
            model.childNumber = model.get(model.childSequenceKey.toUpperCase());
            model.set('id', model.childNumber);
            model.set('BATCHSEQNUM', this.batchSeqNum);
            if (util.isEmpty(model.id)) {
                model.set('id', this.iterator);
            }
        } else if (this.keyList.length > 0) {
            model.generateIdValue();
        } else {
            model.id = this.rowIterator;
            this.rowIterator += 1;
        }

        if (row.children) {
            model.children = row.children;
        }

        if (row.hideGroupToggle) {
            model.hideGroupToggle = true;
        }

        if (row.duplicates) {
            model.hasDuplicates = row.duplicates;
        }

        model.parentViewId = this.parentViewId;

        return model;
    },

    getLabel(action) {
        return this.labels[action] ? this.labels[action] : action;
    },

    /**
     * Convert the webseries delimited action list to an array of buttons.
     *
     * @param value
     * @returns {Array}
     */
    parseActionList(value) {
        // parse the list of available actions.
        const actions = value.split('^');

        const buttons = [];
        let actionAdd = '';
        const self = this;

        if (this.parentViewId) {
            actionAdd = `_${this.parentViewId}`;
        }

        util.each(actions, (action) => {
            const actionTokens = action.split(',');

            let label = '';
            // If the service gives us a label, use it, otherwise fall back to defaults.
            if (actionTokens.length > 1 && actionTokens[1].length > 0) {
                label = actionTokens[1].replace('&#44', ',');
            } else {
                label = self.getLabel(actionTokens[0]);
            }

            if (actionTokens[0] !== 'INSERT') {
                buttons.push({
                    value: `action_${actionTokens[0].toLowerCase()}${actionAdd}`,
                    label,
                    action: actionTokens[0],
                });
            }
        });

        return buttons;
    },

    cloneObj(obj) {
        return util.clone(obj);
    },
});
