// File created to avoid duplication
// of actions from DGB/MDF based actions (src\app\payments\routes.js)
import { ptxRouter } from 'pcm/common/ptx-connect-integration/ptxConnect';
import configuration from 'system/configuration';


// Workaround to meet routes PT-X Connect routes with PCM root "ui/PAYMENTS/documents"
// PCM "ui/PAYMENTS/documents/cheques" is replacement for PT-X Connect route "ui/documents/cheques".
const newAppRoutes = {};
Object.keys(ptxRouter.appRoutes).forEach((routeKey) => {
    if (routeKey) {
        // After PCM-1769 we got rid of "Secure Check Printing" (which used PAYMENTS/documents)
        // After PCM-1484 required to change to ADMINSTRATION/documents
        newAppRoutes[`ADMINSTRATION/documents/${routeKey}`] = ptxRouter.appRoutes[routeKey];
        newAppRoutes[`${routeKey}`] = ptxRouter.appRoutes[routeKey];
    }
});
// workaround
const ptxClientRoutes = {
    'ADMINSTRATION/documents/clientRoles': 'clientRoles',
    clientRoles: 'clientRoles',
    'ADMINSTRATION/documents/clientPrinters': 'clientPrinters',
    'ADMINSTRATION/documents/adminPrinters': 'adminPrinters',
    'ADMINSTRATION/documents/roles/:role': configuration.isClient() ? 'clientRoleViewer' : 'roleViewer',
    'roles/:role': configuration.isClient() ? 'clientRoleViewer' : 'roleViewer',
    'ADMINSTRATION/documents/roles': configuration.isClient() ? 'clientRoles' : 'roles',
    roles: configuration.isClient() ? 'clientRoles' : 'roles',
};

const ptxConnectAppRoutes = {
    ...newAppRoutes,
    ...ptxClientRoutes,
};

export default ptxConnectAppRoutes;
