import ItemView from '@glu/core/src/itemView';
import util from '@glu/core/src/util';
import numeral from 'numeral';
import userInfo from 'etc/userInfo';
import rowViewTmpl from './rowView.hbs';

const ReadOnlyRowView = ItemView.extend({
    tagName: 'tr',
    template: rowViewTmpl,

    initialize(options) {
        this.viewModel = options.viewModel;

        /*
         * this.model is the sumModel
         * this.collection is a collection of all inflows/outflows that add up to
         * this sumModel
         */
        this.listenTo(
            this.model,
            {
                change: this.handleChange,
            },
            this,
        );

        this.listenTo(
            this.viewModel,
            {
                'change:displayFactor': this.renderAndRestoreFocus,
            },
            this,
        );

        this.fiscalYearStartMonth = options.fiscalYearStartMonth;
        this.highlightQueue = {};

        this.flowType = options.flowType || '';
    },

    handleChange(model, options) {
        if (options && options.source === 'save') {
            return;
        }

        util.each(model.changedAttributes(), function (value, key) {
            const $updateTarget = this.$(`[data-attr="${key}"]`);

            if ($updateTarget.is('input')) {
                // do not re-render a field if the user is currently editing it
                if (!$updateTarget.is(document.activeElement)) {
                    $updateTarget.val(this.getFormattedNumber(value, false));
                    this.highlightChange($updateTarget, key);
                }
            } else {
                $updateTarget.text(this.getFormattedNumber(value, true));
                this.highlightChange($updateTarget.closest('td, th'), key);
            }
        }, this);
    },

    getCurrencyFormatString() {
        const displayFactor = this.viewModel.get('displayFactor');
        const decimalSeparator = userInfo.get('decimalSeparator');
        const thousandsSeparator = userInfo.get('thousandsSeparator');

        return (displayFactor > 1)
            ? `0${thousandsSeparator}0${decimalSeparator}00`
            : `0${thousandsSeparator}0`;
    },

    getFormattedNumber(value, withCurrency) {
        const currencySymbol = userInfo.get('currencySymbol');
        const format = withCurrency ? (currencySymbol + this.getCurrencyFormatString())
            : this.getCurrencyFormatString();
        return numeral(value / this.viewModel.get('displayFactor')).format(format);
    },

    /*
     * this will pile up change events and run when they stop coming
     * not exactly like debounce, because it keeps track of separate cells to update
     */
    highlightChange($target, attr) {
        const collectionDelay = 500;
        if (this.highlightQueue[attr]) {
            // an event is already queued for this cell - cancel it
            window.clearTimeout(this.highlightQueue[attr]);
        }

        // add a new event to the queue for that key
        this.highlightQueue[attr] = window.setTimeout(util.bind(
            this.showEffect,
            this,
            $target,
            attr,
        ), collectionDelay);
    },

    showEffect($target, queueKey) {
        delete this.highlightQueue[queueKey];
        $target.addClass('flash').on('animationend.flash', () => {
            $target.removeClass('flash').off('.flash');
        });
    },

    templateHelpers() {
        const cellArray = util.map(util.range(1, 13), function (i) {
            return {
                monthIndex: i,
                formattedValue: this.getFormattedNumber(this.model.get(i), true),
                value: this.getFormattedNumber(this.model.get(i), false),
            };
        }, this);

        return {
            name: this.model.get('name'),
            readOnly: true,
            id: this.cid,
            cells: cellArray,
            step: 100,
            formattedSum: this.getFormattedNumber(this.model.get('sum'), true),
        };
    },
});

export default ReadOnlyRowView;
