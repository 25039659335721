import StackView from 'common/stack/views/stackView';
import WorkspaceView from 'common/workspaces/views/workspace';

export default {
    /**
     * Return the default Admin workspace view.
     */
    smbAdminWorkspace() {
        if (!this.popPage()) {
            this.showPage('', new StackView({
                initItem: new WorkspaceView({
                    id: 'SMBADMIN',
                    returnRoute: 'smbadmin/workspace',
                }),
            }));
        }
    },
};
