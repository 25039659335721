import React from 'react';

// This component also can be used in all places, where we
// React.lazy() load some components, and using React.Suspense requires to have fallback property.

const ContentLoading = () => (
    <div className="glu-content-loading">
        <div className="loader" />
    </div>
);

export default ContentLoading;
