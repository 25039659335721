import useRequest from '../../hooks/useRequest';
import services from '../../system/servicesList';

const { post } = useRequest();
/**
 * Get user groups in the dropdown consumable format
 * @param {string} searchValue - value to search in the user group name
 * @param {string|number} inquiryId
 * @returns {Promise}
 */
const getUserGroups = (searchValue = '', inquiryId = 9070) => {
    const searchFields = searchValue ? [
        {
            fieldName: 'USERGROUP',
            operator: 'CONTAINS',
            fieldValue: [
                searchValue,
            ],
            dataType: 'text',
        },
    ] : [];
    const data = {
        rowsPerPage: 20,
        startRow: 1,
        fieldName: 'USERGROUPLOOKUP',
        requestParameters: {
            item: [
                {
                    name: 'INQUIRYID',
                    value: inquiryId,
                },
            ],
        },
        searchFields,
    };
    return post(services.tableMaintLookup, data).then((response) => {
        const remapped = response.rows.map((row) => {
            const [userGroup] = row.columns;
            return {
                id: userGroup.fieldValue,
                name: userGroup.fieldValue,
            };
        });
        return remapped;
    });
};

export default getUserGroups;
