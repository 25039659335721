import { setOpacity } from '@glu/theming';

export default ({ palette }) => ({
  button: {
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    height: 35,
    margin: 0,
    padding: 0,
    width: 35,
    '& svg': {
      height: 32,
      width: 32
    },
    '&:hover:not([disabled])': {
      border: 0,
      background: 'transparent',
      '& svg': {
        fill: palette.primary.normal
      }
    }
  },
  root: {
    background: setOpacity(palette.background, 0.9),
    bottom: 70,
    height: 35,
    paddingLeft: 10,
    position: 'fixed',
    right: 0,
    width: 45
  }
});
