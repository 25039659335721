import Model from '@glu/core/src/model';
import util from '@glu/core/src/util';
import services from 'services';
import { postData } from 'common/util/services';
import { appBus } from '@glu/core';

const RefreshSummaryModel = Model.extend({
    url: '/payment/File/REFRESH-SUMMARY-TOTALS',

    fetchNewSummary(options) {
        this.isFileLevelWorkFlow = options.parentModel.get('TYPE') === 'FILE';
        this.sync('read', options.parentModel, options);
    },

    /**
     * Format service response array into object formatted used elsewhere
     * @param {array} buttonActions - buttonActions from services response
     * @returns {array}
     */
    formatButtonActions(buttonActions = []) {
        /*
         * TODO: Refactor in Phase 3 to be more general, work with backend to get
         * buttons returned
         * file level requires Print and Cancel buttons. Make sure those are included.
         */
        if (this.isFileLevelWorkFlow) {
            if (!buttonActions.length) {
                buttonActions.push('PRINT', 'CANCEL');
            } else if (buttonActions.indexOf('PRINT') === -1) {
                // since print is dynamic, make sure it's included on refresh
                buttonActions.push('PRINT');
            }
        }
        return buttonActions.map(action => ({ action }));
    },

    sync(method, model) {
        return postData(
            services.generateUrl('/payment/File/REFRESH-DETAIL-SUMMARY-TOTALS'),
            {
                item: [{
                    name: 'TNUM',
                    value: model.get('TNUM'),
                }],
            },
        ).then((results) => {
            model.set({
                TABLES: results.tables,
                STATUS_DESCRIPTION: util.find(results.item, item => (item.name === 'STATUS_DESCRIPTION')).value,
                UPDATECOUNT__: util.find(results.item, item => (item.name === 'UPDATECOUNT__')).value,
                BUTTON_ACTIONS: this.formatButtonActions(results.buttonActions),
            });
            appBus.trigger('notifyFilePayment', model.get('UPDATECOUNT__'));
        }).catch((err) => {
            window.console.error('ViewPayment: error syncing summary data', err);
        });
    },
});

export default RefreshSummaryModel;
